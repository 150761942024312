import React, { useContext, useEffect, useState } from 'react'
import AButton from 'views/pages/manage/common/form-fields-mui/AButton'
import styles from '../styles.module.scss'
import HeaderBusinessAreaComponent from './HeaderBusinessAreaComponent'
import HeaderNameComponent from './HeaderNameComponent'
import { ReactComponent as EditIcon } from '../../../../../../assets/img/svg/edit3.svg';
import { useParams } from 'react-router'
import { editPageInitialForm } from '../common'
import { useHistory } from 'react-router'
import { checkPermission } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod'
import { RolePermissions, PermissionContext } from 'appContext'
import { pages } from 'views/pages/manage/common/constant'


const HeaderComponent = ({ 
  planData, 
  // formData, 
  // formHandler = () => { },
  createFormData,
  createFormHandler,
  updateDaysPlanHandler = () => { }
}) => {

  const userPermissions = RolePermissions(useContext(PermissionContext), pages['daysPlan']['id']);
  const history = useHistory();
  const { action, daysPlanId } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  
  // this form is used to edit the values of name businessArea and region while user is editng the days plan
  const [formData, setFromData] = useState(editPageInitialForm(planData));
  const formHandler = (value, key) => {
    setFromData(prev => ({ ...prev, [key]: value }));
  }
  
  useEffect(() => {
    setFromData(editPageInitialForm(planData))
  }, [planData])

  const updateDaysPlanHeader = () => {
    const request = {
      regions: formData?.regions?.map(item => ({regionId: item?.value})),
      businessAreas: formData?.businessAreas?.map(item => ({businessAreaId: item?.value})),
      daysPlanName: formData?.daysPlanName,
      plans: planData?.plans,
    }
    updateDaysPlanHandler(request);
  }

  return (
    <div className={`${styles?.view_header_container}`}>
      <div className={`d-flex justify-content-between align-items-center my-4`}>
        <div className='regular-body'>Masters / Days plan / Create days plan</div>
        <AButton 
          variant='link' 
          updatedStyle={{ textDecoration: 'underline', lineHeight: '24px', height: '24px' }}
          onClick={() => history.push('/admin/master/daysPlan')}
        >
          View all days plan
        </AButton>
      </div>
      <div className={`${styles?.header_grid_container} mt-1`}>
        <div className={`${styles?.header_grid_chip}`} style={{ width: '279px' }}>
          <div className='regular-small mb-2'>Name</div>
          <HeaderNameComponent
            planData={planData}
            isEdit={isEdit}
            formData={action==='create' ? createFormData : formData}
            formHandler={action==='create' ? createFormHandler : formHandler}
          />
        </div>
        <div className={`${styles?.header_grid_chip} ${isEdit ? styles?.lightBg : ''}`}>
          <div className='regular-small mb-2'>Business area</div>
          <HeaderBusinessAreaComponent
            planData={planData}
            isEdit={isEdit}
            formData={action==='create' ? createFormData : formData}
            formHandler={action==='create' ? createFormHandler : formHandler}
          />
        </div>
        {(checkPermission(userPermissions, 'C') || checkPermission(userPermissions, 'U')) && (action === 'edit' || action === 'create') && (
          isEdit ? (
            <AButton
              className='button_remove_left_margin'
              variant='outline'
              updatedStyle={{ borderWidth: '1px', minWidth: '62px', height: '62px' }}
              onClick={() => {setIsEdit(prev => !prev); action!=='create' && updateDaysPlanHeader();}}
            >
              Save changes
            </AButton>
          ) : (
            <AButton
              className='button_remove_left_margin'
              variant='outline'
              updatedStyle={{ borderWidth: '1px', minWidth: '62px', height: '62px' }}
              onClick={() => setIsEdit(prev => !prev)}
            >
              <EditIcon />
            </AButton>
          )
        )}
      </div>
    </div >
  )
}

export default HeaderComponent