import moment from "moment";
import { endpoint } from "views/pages/manage/common/constant";
import { updateRequest } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { dropdownMapping } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
export const newRow = {
  academicCareer: "",
  businessArea: "",
  term: "",
  academicGroup: "",
  courseCategory: "",
  concessionRule: "",
  effectiveDate: "",
  status: "ACTIVE",
};

export const getDropdowns = async () => {
  const dropdown = await Promise.all([
    dropdownMapping(
      endpoint.academic_career.getAllActive,
      "academicCareerDispValue",
      "id"
    ),
    dropdownMapping(
      endpoint?.businessArea?.getAllActive,
      "businessAreaDispValue",
      "id"
    ),
    dropdownMapping(endpoint?.term?.getAllActive, "termDispValue", "id"),
    dropdownMapping(
      endpoint.academic_group.getAllActive,
      "academicGroupDispValue",
      "id"
    ),
    dropdownMapping(
      endpoint.courseCategory.getAllActiveCourseCategory,
      "courseAttributeValueDispValue",
      "id"
    ),
  ]);
  return {
    academicCareer: dropdown[0],
    businessArea: dropdown[1],
    term: dropdown[2],
    academicGroup: dropdown[3],
    courseCategory: dropdown[4],
  };
};
export const restructureGetAllRes = (data, dropdown) => {
  return data?.admissionConfigDtoList
  .map((item) => ({
    ...item,
    academicCareer: item?.academicCareerMappings?.map((item) => ({
      label: item?.academicCareerDispVal,
      value: item?.academicCareerId,
    })),
    businessArea: item?.businessAreaMappings?.map((item) => ({
      label: item?.businessAreaDispVal,
      value: item?.businessAreaId,
    })),
    term: item?.termMappings?.map((item) => ({
      label: item?.termDispVal,
      value: item?.termId,
    })),
    academicGroup: item?.academicGroupMappings?.map((item) => ({
      label: item?.academicGroupDispVal,
      value: item?.academicGroupId,
    })),
    courseCategory: item?.courseCategoryMappings?.map((item) => ({
      label: item?.courseCategoryDispVal,
      value: item?.courseCategoryId,
    })),
    concessionRule: item?.concessionRule,
    effectiveDate: item?.effectiveDate,
    status: item?.status,
    updatedBy: item?.updatedBy,
    updatedOn: item?.updatedOn,
  }));
};

export const getCreateReqBody = (rowData) => {
  return updateRequest({
    academicCareerMappings: rowData?.academicCareer?.map((item) => ({
      academicCareerId: item?.value,
      academicCareerDispVal: item?.label,
    })),
    businessAreaMappings: rowData?.businessArea?.map((item) => ({
      businessAreaId: item?.value,
      businessAreaDispVal: item?.label,
    })),
    termMappings: rowData?.term?.map((item) => ({
      termId: item?.value,
      termDispVal: item?.label,
    })),
    academicGroupMappings: rowData?.academicGroup?.map((item) => ({
      academicGroupId: item?.value,
      academicGroupDispVal: item?.label,
    })),
    courseCategoryMappings: rowData?.courseCategory?.map((item) => ({
      courseCategoryId: item?.value,
      courseCategoryDispVal: item?.label,
    })),
    concessionRule: rowData?.concessionRule,
    effectiveDate: moment(rowData?.effectiveDate).format("YYYY-MM-DD"),
    status: rowData?.status,
    percentageDiscount:rowData?.concessionRule!=concessionRule[0] ?rowData?.percentageDiscount:null,
    id: rowData?.id,
  });
};
export const concessionRule = [
  "Complete concession",
  "The percentage of whatever is paid",
  "The Percentage discount",
];
