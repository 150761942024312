import React from "react";
import { useHistory } from "react-router";
import PageHeader from "./PageHeader";
import {
  DlpModuleProvider,
  useGetDlpModuleContext,
} from "../../contextApi/dlpContext";
import DispatchPendingTable from "./DispatchPendingTable";
import { dispatchedTableColumn, pendingDispatchTableColumn, onHoldTableColumn } from "./helper";
const RenderedTable = () => {
  const { selectedTab } = useGetDlpModuleContext();

  return selectedTab === "DISPATCH_PENDING" ? (
    <DispatchPendingTable
      tableHeader={pendingDispatchTableColumn}
      selectedTab={selectedTab}
    />
  ) : (
    selectedTab === "DISPATCHED"?(
    <DispatchPendingTable
      tableHeader={dispatchedTableColumn}
      selectedTab={selectedTab}
    />
    ):(
      <DispatchPendingTable
      tableHeader={onHoldTableColumn}
      selectedTab={selectedTab}
    />
    )
  );
};
const IssueStockDlpPage = () => {
  const history = useHistory();

  return (
    <DlpModuleProvider>
      <PageHeader goBackHandler={() => history.goBack()} />
      <RenderedTable />
    </DlpModuleProvider>
  );
};

export default IssueStockDlpPage;
