import React from 'react'
import {Row} from "reactstrap";

export const Error = ({refreshPage=()=>{}}) => {
  return (
    <Row style={{marginRight:'0'}}>
            <div style={{width:'100%', backgroundImage: 'linear-gradient(to right, #e3effc, #ffffff)'}}>
                <img alt={'errorPage'} src={require('assets/img/brand/group-195-copy.svg')} className={'Group-195'}/>
            </div>
            <div className="Frame">
                <div className="MiddleDiv">
                <span className="Whoops">
                    Ooops!
                </span>
                    <h3 className="There-seems-to-be-a">
                        There seems to be a problem showing this page.
                    </h3>
                    <div className="tryAgainDiv" onClick={refreshPage}>
                    <span  className="Try-Again">
                        Try Again
                    </span>
                    </div>
                </div>
            </div>
        </Row>
  )
}
