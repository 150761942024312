import React, { useContext, useState } from 'react'
import { RolePermissions, PermissionContext } from 'appContext';
import DeleteDialog from './DeleteDialog';
import { ReactComponent as EditIcon } from 'assets/img/svg/Edit2.svg';
// import { ReactComponent as DeleteIcon } from 'assets/img/svg/Delete.svg';
import { ReactComponent as CrossIcon } from 'assets/img/svg/CrossIcon.svg';
import { pages } from 'views/pages/manage/common/constant';
import { checkPermission } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import AButton from 'views/pages/manage/common/form-fields-mui/AButton';

const ActionsCellComponent = ({ params, handleEditClick, handleCancelClick, handleSaveClick, deleteHandler, rowForm }) => {

  const userPermissions = RolePermissions(useContext(PermissionContext), pages['scholarshipConfig']['id']);

  const [isDialogOpen, setIsDialogOpen] = useState(false);


  return (
    params.api.getRowMode(params?.id) === 'view' ? (
      <>
        <div className='d-flex align-items-center'>
          {checkPermission(userPermissions, 'U') && <EditIcon onClick={() => handleEditClick(params)} />}
          {/* {checkPermission(userPermissions, 'D') && <DeleteIcon className='ml-3' onClick={() => setIsDialogOpen(true)} />} */}
        </div>
        <DeleteDialog
          open={isDialogOpen}
          setOpen={setIsDialogOpen}
          data={params?.row}
          onAgree={() => deleteHandler(params?.row)}
        />
      </>
    ) : (
      <div className='d-flex align-items-center'>
        <AButton
          variant='primary_filled'
          size='xs'
          onClick={() => handleSaveClick(params)}
          disabled={!rowForm?.subCategoryId ||  !rowForm?.categoryType?.value || !rowForm?.testType?.value || !rowForm?.status || !rowForm?.examTimeCode || !rowForm?.term?.length || !rowForm?.courseCategory.length || !rowForm?.academicGroup?.length }
        >
          Save
        </AButton>
        <CrossIcon className='ml-2' onClick={() => handleCancelClick(params)} />
      </div>
    )
  )
}

export default ActionsCellComponent