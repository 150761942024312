import React from "react";
import { Card, CardHeader,Input, Container, Button, Table, Row } from "reactstrap";
import { useState } from "react"
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function'
import {TDROW ,MasterHeader } from "../../common/commonComponents/masterRow/masterRow";
import { masterServiceBaseUrl, putpost, putpost2, deleteAPI } from 'services/http';
import CustomButton from "components/CustomButton";
import { CustomToggle } from "../../common/formFeilds";
import { MasterHeaderAuto } from '../../common/commonComponents/masterHeader/masterHeader';
import moment from 'moment';

var _ = require('lodash');
  
const AcademicInstitutionTable = (props) => {
  const {userPermissions, isSaveVisible, setisSaveVisible} = props;
  const [tableDataKey, settableDataKey] = useState('tableDataKey')


  const headerList = [
    { name: 'Academic Institution Key', isRequired: true },
    { name: 'Effective Date' },
    { name: 'Description', isRequired: true },
    { name: 'Display Value', isRequired: true },
    { name: 'Status' },
    { name: 'Action' },
  ];


  const addNewClass = () =>{
    props.setclassArray([{"editMode":true,"__status":"__new","academicInstitutionKey":null,'status':'ACTIVE',"academicInstitutionDesc": null,"academicInstitutionDispValue": null, "effectiveDate": null },...props.classArray])
  }
  const saveAll = (cb) =>{
    var _tempArray = props.classArray
    var isValid = true
    _tempArray.forEach((el,index)=>{
      if(el['editMode']){
        if(el['academicInstitutionKey'] === null || !el['academicInstitutionKey'].trim()){
          isValid= false
          failureToast('Please enter the value of Academic Institution Key')
          return
        }else if(el['academicInstitutionDesc'] === null || !el['academicInstitutionDesc'].trim()){
          isValid= false
          failureToast('Please enter the value of Description')
          return
        }
        else if(el['academicInstitutionDispValue'] === null || !el['academicInstitutionDispValue'].trim()){
            isValid= false
            failureToast('Please enter the value of Display Value')
            return
          }
            if(!isValid){
              return
            }
          if (_tempArray[index]['__status'] == "__new") {
            putpost(masterServiceBaseUrl + '/academicInstitution/createAcademicInstitution', (data) => {
              successToast(data['message'])
              setisSaveVisible(false)
              props.fetchClasses(masterServiceBaseUrl + '/academicInstitution/getAllAcademicInstitution')
            }, (data) => { failureToast(data['message']) }, _tempArray[index], 'post')
          } else {
            putpost(masterServiceBaseUrl + '/academicInstitution/updateAcademicInstitution/' + _tempArray[index].id, (data) => {
              successToast(data['message'])
              setisSaveVisible(false)
              delete _tempArray[index]['editMode']
              props.setclassArray(_tempArray)
              settableDataKey(new Date().getMilliseconds())
              // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
            }, (data) => { failureToast(data['message']) }, _tempArray[index], 'put')
          }
        } else {
  
        }
      })
  }
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes")
    }
    else {
      let cb = () => {
        addNewClass()
        setisSaveVisible(true)
        // settableDataKey(new Date().getMilliseconds())
      }
      var _tempArray = props.classArray
      _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
      props.setclassArray(_tempArray)
      setisSaveVisible(false)
      cb()
    }
  }
  const editRow = (index)=>{
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes")
    }
    else {
      let cb = () => {
        var _tempArray = props.classArray
        _tempArray[index]['editMode'] = true
        props.setclassArray(_tempArray)
        setisSaveVisible(true)
      }
      var _tempArray = props.classArray
      _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
      cb()
    }
  }
  const deleteRow = (index) => {
    if (props.classArray[index]["__status"] == "__new") {
      let n = props.classArray
      n.splice(index, 1)
      props.setclassArray(n)
      setisSaveVisible(false)
      settableDataKey(new Date().getMilliseconds())
    } else {
      if (window.confirm('Are you sure you want to delete this Academic Institution?')) {
        putpost2(masterServiceBaseUrl + '/academicInstitution/deleteAcademicInstitution/' + props.classArray[index].id, (data) => {
          successToast(data['message'])
          if (props.classArray.length == 1) {
            props.previousPage()
            props.fetchClasses(masterServiceBaseUrl + '/academicInstitution/getAllAcademicInstitution')
          }
          else {
            var _tempArray = props.classArray
            if (_tempArray[index]['editMode']) {
              setisSaveVisible(false)
            }
            _tempArray.splice(index, 1)
            props.setclassArray(_tempArray)
          }
          
          settableDataKey(new Date().getMilliseconds())
        }, (data) => { failureToast(data['message']) }, 'Delete')
      }
    }
  }
  const updateKey = (index,value,key)=>{
    var _tempArray = props.classArray
    _tempArray[index][key] = value
    props.setclassArray(_tempArray)
  }
  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Academic Institution</h3>
                {/* <Button disabled={isSaveVisible} color="info" size="md" type="button" className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)} onClick={() => { newRow() }} data-testid="addNewInstitution"><i className="fas fa-plus" />&nbsp;&nbsp;New Academic Institution</Button> */}
                <CustomButton
                  disabled={isSaveVisible}
                  className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
                  data-testid="addNewInstitution"
                  content={'New Academic Institution'}
                  icon={true}
                  permissionType={'C,U'}
                  permissionSet={userPermissions}
                  onClick={() => { newRow() }}
                />
                {/* {isSaveVisible?<Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => {saveAll()}}>Save</Button>:null} */}
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout" >
                  {/* <MasterHeader type={"Academic Institution"} isSaveVisible={isSaveVisible} permissionSet={userPermissions} /> */}
                  <MasterHeaderAuto headerList={headerList} permissionSet={userPermissions} isSaveVisible={isSaveVisible}/>
                  <tbody className="list" key={tableDataKey} >
                    {props.classArray ? props.classArray.map((el, index) => 
                    !el['editMode']?(
                      <TDROW type={"academicInstitution"} index={index} el={el} editRow={editRow} deleteRow={deleteRow} isSaveVisible={isSaveVisible}
                      permissionSet={userPermissions} />
                    ):
                    (<tr key={index+'-class'}>
                      <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['academicInstitutionKey']} onChange={(e)=>{updateKey(index,e.target.value,'academicInstitutionKey')}} placeholder="Example:1" className="tdInput" data-testid='academicInstitutionKey' /></td>
                      <td className="text-center p-2 mx-1">
                        <Input disabled={true} defaultValue={el["__status"] == "__new" ? moment(new Date()).format('DD-MM-YYYY') : moment(el['eff_date']).format('DD-MM-YYYY')} placeholder="Example:01-01-2020" className="tdInput" data-testid='effectiveDate'/>
                        </td>
                      <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['academicInstitutionDesc']} onChange={(e)=>{updateKey(index,e.target.value,'academicInstitutionDesc')}} placeholder="Description" className="tdInput" data-testid='academicInstitutionDesc'/></td>
                      <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['academicInstitutionDispValue']} onChange={(e)=>{updateKey(index,e.target.value,'academicInstitutionDispValue')}} placeholder="Display Value" className="tdInput" data-testid='academicInstitutionDispValue'/></td>
                      <td className="text-center p-2 mx-1">
                        <CustomToggle
                          status={el.status}
                          data-testid="activeInactiveInput"
                          id={el.id}
                          toggleOn={"Active"}
                          toggleOff={"Inactive"}
                          onChangeHandler={(e) => {
                            settableDataKey(new Date().getMilliseconds());
                              let n = "ACTIVE";
                              if (!e.target.checked) { n = "INACTIVE"; }
                              updateKey(index, n, "status");
                            }}
                        />
                      {/* <label className="custom-toggle mx-auto ml-2">
                      <input checked={el.status == "ACTIVE" ? true : false} type="checkbox" id={el.id} onChange={(e) => { settableDataKey(new Date().getMilliseconds()); console.log(e.target.checked); let n = "ACTIVE"; if (!e.target.checked) { n = "INACTIVE" } updateKey(index, n, 'status') }}
                         data-testid='academicInstitutionStatus' />
                          <span style={{width:"72px"}} className="custom-toggle-slider rounded-circle activeToggle" data-label-off="Inactive" data-label-on="Active" />
                          </label> */}
                      </td>
                      <td className="text-center">
                        {/* <Button color="info" size="sm" type="button" onClick={() => {
                          deleteRow(index)
                        }}>  <i className="fas fa-trash" /></Button> */}
                         {isSaveVisible ? <> 
                         {/* <Button color="info" size="sm" type="button" className="mx-1" data-testid="saveAcademicInstitution" onClick={() => { saveAll() }}>Save</Button> */}
                              <CustomButton
                                className="mx-1" 
                                data-testid="saveAcademicInstitution"
                                content={'Save'}
                                permissionType={'C,U'}
                                forTable={true}
                                permissionSet={userPermissions}
                                onClick={() => {
                                  saveAll();
                                }}
                              />
                            {/* <Button color="danger" size="sm" type="button" onClick={() => {
                              let n=props.classArray
                              if(n[index]['__status']=='__new'){
                                n.splice(index,1)
                              }else{
                             // n[index]['editMode']=false
                             props.fetchClasses(masterServiceBaseUrl + '/academicInstitution/getAllAcademicInstitution')
                              }
                              props.setclassArray(n)
                              setisSaveVisible(false)
                            }}>  <i className="fas fa-times" /></Button>  */}
                              <CustomButton
                                permissionType={'cancel'}
                                icon={true}
                                forTable={true}
                                permissionSet={userPermissions}
                                onClick={() => {
                                  let n=props.classArray
                                  if(n[index]['__status']=='__new'){
                                    n.splice(index,1)
                                  }else{
                                 // n[index]['editMode']=false
                                 props.fetchClasses(masterServiceBaseUrl + '/academicInstitution/getAllAcademicInstitution')
                                  }
                                  props.setclassArray(n)
                                  setisSaveVisible(false)
                                }}
                              />
                            </>:
                              <Button color="info" size="sm" type="button" onClick={() => {
                                deleteRow(index)
                              }}>  <i className="fas fa-trash" /></Button>}
                      </td>
                    </tr>)
                    ) : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
export default AcademicInstitutionTable;