import {updateLoaderState} from '../index';
import { UPDATE_LOADER_STATE} from "../types";
const initial_state = {
    isLoading:false
}

const LoaderReducer =(state=initial_state, action=updateLoaderState)=>{
    switch(action.type){
        case UPDATE_LOADER_STATE :return {
            ...state,
            isLoading:action.isLoading
        }
        default:return initial_state
    }
}
export default LoaderReducer;