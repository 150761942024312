import React, { useContext } from 'react'
import { PermissionContext, RolePermissions } from '../../../../../../appContext';
import { pages } from '../../../common/constant';
import { DigitalModuleProvider, useGetDigitalModuleContext } from '../contextApi/digitalContext';
import { PermisionDenied } from '../../../common/commonComponents';
import ApplicationFilterLandingPage from './landingPage/ApplicationFilterLandingPage';
import MarkEligibleScreen from './markEligible/MarkEligibleScreen';

const pageStateMapping = {
  SEARCH_STUDENT: <ApplicationFilterLandingPage />,
  MARK_ELIGIBLE: <MarkEligibleScreen />,
};

const GetPageComponentByState = () => {
  const { currentPageState } = useGetDigitalModuleContext();
  return pageStateMapping[currentPageState];
};
const DigitalStockManagement = () => {
  const userPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["digitalStockAndInventory"]["id"]
  );

  return userPermissions ? (
    <DigitalModuleProvider>
      <GetPageComponentByState />
    </DigitalModuleProvider>
  ) : (
    <PermisionDenied />
  );
}

export default DigitalStockManagement