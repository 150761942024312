import React, { useState } from "react";
import { withRouter } from "react-router-dom"
import { Card, Table, CardHeader } from "reactstrap";
import { MasterHeaderAuto, Rows } from "../../../../common/commonComponents/masterHeader/masterHeader";
import { openInNewTab } from '../../../../common/utils/methods/commonMethods/utilityMethod';
import CustomButton from "components/CustomButton";
import { ConfirmationDialog } from "views/pages/manage/common/commonComponents";
import { commonMessages } from "views/pages/manage/common/constant";
import CustomDownload from "../../../../common/commonComponents/CustomDownload";
import { SkeletonLoader } from "views/pages/manage/common/commonComponents/SkeletonLoader";

const SearchTable = (props) => {
  const { data, userPermissions, isFetching, serverData, currentPage} = props;

  const headerList = [
    { name: "S.No" },
    { name: "Process ID" },
    { name: "Description" },
    { name: "File Name" },
    { name: "Status" },
    { name: "Error" },
    { name: "Actions" },
  ];

  const listToRender = [
    'sno',
    'processInstanceId',
    'description',
  ];
  return (
    <Card className="mt-4">
      <CardHeader className="border-0">
        <h3 className="mb-0 floatLeft">Search Results</h3>
      </CardHeader>
      {isFetching ? (
        <Card className="mt-4 p-4">
          <SkeletonLoader rows={6} apiLoader={isFetching}/>
        </Card>
      )
        :
        <div className="table-responsive-layout">
          <Table className="align-items-center  tableLayout">
            <MasterHeaderAuto headerList={headerList} permissionSet={userPermissions} />
            <tbody className="list">
              {data ?
                data.map((el, index) => {
                  return (
                    <Rows data={{ ...el, sno: currentPage === 1 ? index + 1 : (currentPage-1)*10 + index + 1 }} listToBeRendered={listToRender}>
                      <td className="text-center  white-breakSpace">
                        {/* <span onClick={() => window.open(`${el.filePath}`)} style={{ cursor: 'pointer' }} className="text-info">{el.fileName}</span> */}
                        <CustomDownload
                          downloadKey={el.key}
                          permissionSet={userPermissions}
                          isIcon={false}
                          text={el.fileName}
                        />
                      </td>
                      <td className="text-center  white-breakSpace">
                        {el?.fileProcessingStatus && el.fileProcessingStatus.toUpperCase()}
                      </td>
                      <td className="text-center  white-breakSpace">
                        {el.fileProcessingStatus === 'Failed' ?
                          // <Button
                          //   color="info"
                          //   size="sm"
                          //   type="button"
                          //   onClick={() => window.open(`${el.errorPath}`)}
                          // >
                          //   <i className="fas fa-download" />
                          // </Button>
                          // <CustomButton
                          //   type="download"
                          //   className={'mx-1'}
                          //   permissionType={'R'}
                          //   color="info"
                          //   icon={true}
                          //   forTable={true}
                          //   onClick={el.errorPath? () => window.open(`${el.errorPath}`): ()=>setInfoModalOpen(true)}
                          //   permissionSet={userPermissions}
                          // />
                          <CustomDownload
                            downloadKey={el.errorPath}
                            permissionSet={userPermissions}
                            isIcon={true}
                          />
                          : null
                        }
                      </td>
                      <td className="text-center  white-breakSpace">
                        {/* <div className="offset-3"> */}
                        {el.fileProcessingStatus === 'Success' ?
                          // <Button
                          //   color="info"
                          //   size="sm"
                          //   type="button"
                          //   onClick={() => openInNewTab(`/view/${el.processInstanceId}`)}
                          // >
                          //   <i className="fas fa-eye" />
                          // </Button>
                          <CustomButton
                            className={'mx-1'}
                            permissionType={'R'}
                            icon={true}
                            forTable={true}
                            onClick={() => openInNewTab(`/view/${el.processInstanceId}`)}
                            permissionSet={userPermissions}
                          />
                          : null}
                        {/* {el.fileProcessingStatus === 'Success' && !(el.dataStatus === 'Submitted') ?
                          // <Button
                          //   color="info"
                          //   size="sm"
                          //   type="button"
                          //   onClick={() => props.history.push(`/admin/processInstanceSearch/edit/${el.processInstanceId}`)}
                          // >
                          //   <i className="fas fa-edit" />
                          // </Button>
                          <CustomButton
                            className={'mx-1'}
                            permissionType={'U'}
                            icon={true}
                            forTable={true}
                            onClick={() => props.history.push(`/admin/processInstanceSearch/edit/${el.processInstanceId}`)}
                            permissionSet={userPermissions}
                          />
                          : null} */}
                        {/* </div> */}
                      </td>
                    </Rows>
                  )
                })
                : <span>No Record Found</span>}
            </tbody>
          </Table>
        </div>
      }
    </Card>
  );
};

export default withRouter(SearchTable);
