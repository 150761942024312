import React from 'react';
import { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Label, FormGroup, Form, Input, Container, Row, Col, Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { successToast, putpost, failureToast, getAPI, masterServiceBaseUrl } from 'services/http';

import { CustSelect } from 'components/InputComponent/formcomponent';
import { SimpleInput } from 'components/FormComponent/formcomponent';
// core components

const BussinessUnit = props => {
  const { nextStep = () => {}, isNew = true, formId, isViewOnly = false, action } = props;
  const { handleSubmit, register, control, formState, reset, required, errors } = useForm();
  const { isDirty, isSubmitting } = formState;
  const [apiloader, setApiLoader] = useState(false);
  const [currency, setCurrency] = useState([]);
  const [group, setgroupCode] = useState([]);
  const [region, setRegion] = useState([]);
  const [businessUnitType, setbusinessUnitType] = useState([]);
  const [companyCode, setcompanyCode] = useState([]);
  const [campusInstitution, setcampusInstitution] = useState([]);

  const [state, setState] = useState({});
  const fetchCurrency = async url => {
    getAPI(
      url,
      data => {
        let _n = data.data.map(data => {
          const { id, currencyDispValue } = data;
          return { value: id, label: currencyDispValue };
        });
        setCurrency(_n);
      },
      data => {
        failureToast(data['message']);
      }
    );
  };
  const fetchRegion = async url => {
    getAPI(
      url,
      data => {
        let _n = data.data.map(data => {
          const { id, regionDispValue } = data;
          return { value: id, label: regionDispValue };
        });
        setRegion(_n);
      },
      data => {
        failureToast(data['message']);
      }
    );
  };
  const fetchsetbusinessUnitType = async url => {
    getAPI(
      url,
      data => {
        let _n = data.data.map(data => {
          const { id, businessUnitTypeKey } = data;
          return { value: id, label: businessUnitTypeKey };
        });
        setbusinessUnitType(_n);
      },
      data => {
        failureToast(data['message']);
      }
    );
  };
  const fetchcompanyCode = async url => {
    getAPI(
      url,
      data => {
        let _n = data.data.map(data => {
          const { id, companyCodeKey } = data;
          return { value: id, label: companyCodeKey };
        });
        setcompanyCode(_n);
      },
      data => {
        failureToast(data['message']);
      }
    );
  };
  const fetchcampusInstition = async url => {
    getAPI(
      url,
      data => {
        let _n = data.data.map(data => {
          const { id, academicInstitutionKey } = data;
          return { value: id, label: academicInstitutionKey };
        });
        setcampusInstitution(_n);
      },
      data => {
        failureToast(data['message']);
      }
    );
  };
  const fetchGroupCode = async url => {
    getAPI(
      url,
      data => {
        let _n = data.data.map(data => {
          const { id, groupCodeDispValue } = data;
          return { value: id, label: groupCodeDispValue };
        });
        setgroupCode(_n);
      },
      data => {
        failureToast(data['message']);
      }
    );
  };
  const fetchBU = async url => {
    getAPI(
      url,
      data => {
        setState(data['data']);
      },
      data => {
        failureToast(data['message']);
      }
    );
  };
  

  const SaveButton = () => {
    handleSubmit(formValues => {
      setApiLoader(true);
      const businessUnit = {
        businessAreaKey: formValues.businessAreaKey,
        businessAreaDesc: formValues.description,
        businessAreaDispValue: formValues.shortDes,
        currencyId: formValues.currency,
        id: null,
        businessUnitTypeId: formValues.businessUnitTypeId,
        regionId: formValues.region1,
        groupCodeId: formValues.groupCode,
        companyCodeId: parseInt(formValues.sapComp),
        campusInstitutionId: parseInt(formValues.campus),
        tId: formValues.tId,
        jurisdiction: formValues.jurisdiction,
        serviceTaxRegistration: formValues.serviceTaxRegistration,
        gstInNumber: formValues.gstInNumber,
        cinllpNumber: formValues.cinllpNumber,
        status: formValues.status,
        referenceId: formValues.referenceId,
      };

      if (props.businessUnit.businessUnit && !isNew) {
        // /api/masterServices/businessArea/updateBusinessArea/{id}
        putpost(
          masterServiceBaseUrl + '/businessArea/updateBusinessArea/' + props.businessUnit.businessUnit,
          data => {
            successToast(data['message']);
            nextStep(data['data']['id']);
          },
          data => {
            setApiLoader(false);
            failureToast(data['message']);
          },
          businessUnit,
          'put'
        );
      }
      if (isNew) {
        putpost(
          masterServiceBaseUrl + '/businessArea/createBusinessArea',
          data => {
            successToast(data['message']);
            setApiLoader(false);
            nextStep(data['data']['id']);
          },
          data => {
            setApiLoader(false);
            failureToast(data['message']);
          },
          businessUnit,
          'post'
        );
      }
    })();
  };
  useEffect(() => {
    fetchCurrency(masterServiceBaseUrl + '/currency/getAllActiveCurrency');
    fetchGroupCode(masterServiceBaseUrl + '/groupCode/getAllActiveGroupCode');
    // let BU = JSON.parse(localStorage.getItem('BU'))
    fetchRegion(masterServiceBaseUrl + '/region/getAllActiveRegion');
    fetchsetbusinessUnitType(masterServiceBaseUrl + '/businessUnitType/getAllActiveBusinessUnitType');
    fetchcompanyCode(masterServiceBaseUrl + '/companyCode/getAllActiveCompanyCode');
    fetchcampusInstition(masterServiceBaseUrl + '/academicInstitution/getAllActiveAcademicInstitution');
    if (props.businessUnit.businessUnit) {
      // GET /api/masterServices/businessArea/getAllBusinessArea
      // console.log(props.businessUnit, props.businessUnit.businessUnit);
      fetchBU(masterServiceBaseUrl + '/businessArea/getBusinessArea/' + props.businessUnit.businessUnit);
    } else {
      setState({ id: true });
    }
  }, []);
  return (
    <>
      {state['id'] && currency.length > 0 && group.length > 0 && region.length > 0 ? (
        <Container className="mt-4" fluid>
          <Card className="mb-4">
            <CardHeader>
              <Row>
                <Col md="6">
                  <h3 className="mb-0">Business Area</h3> <span></span>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="6">
                  <SimpleInput
                    className="form-control"
                    defaultValue={state['businessAreaKey'] || null}
                    required={true}
                    error={errors}
                    reference={register({ required: true })}
                    name="businessAreaKey"
                    id="example2cols1Input"
                    placeholder="Business Unit Key"
                    title="*Business Unit Key"
                    type="text"
                  />
                </Col>
                <Col md="6">
                  <FormGroup>
                    <CustSelect
                      defaultValue={state['companyCodeId']}
                      required={true}
                      error={errors}
                      label="Company Code"
                      data={companyCode}
                      singleValue={true}
                      controlName={control}
                      search={true}
                      name="sapComp"
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-3" md="6">
                  <CustSelect
                    defaultValue={state['regionId']}
                    required={true}
                    error={errors}
                    label="*Region"
                    data={region}
                    singleValue={false}
                    controlName={control}
                    search={true}
                    name="region1"
                  />
                </Col>
                <Col md="6">
                  <CustSelect
                    defaultValue={state['businessUnitTypeId']}
                    required={true}
                    error={errors}
                    label="*Business Unit Type"
                    data={businessUnitType}
                    singleValue={false}
                    controlName={control}
                    search={true}
                    name="businessUnitTypeId"
                  />
                </Col>
                <Col className="mb-3" md="6">
                  <CustSelect
                    defaultValue={state['groupCodeId']}
                    label="*Group Code"
                    required={true}
                    error={errors}
                    singleValue={true}
                    controlName={control}
                    search={true}
                    data={group}
                    name={'groupCode'}
                  />
                </Col>
                <Col md="6">
                  <FormGroup>
                    <CustSelect
                      error={errors}
                      label={'Campus Institution'}
                      singleValue={true}
                      controlName={control}
                      defaultValue={state['campusInstitutionId'] || null}
                      className="form-control"
                      id="example2cols2Input"
                      name="campus"
                      data={campusInstitution}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <CustSelect
                      error={errors}
                      label={'Status'}
                      singleValue={true}
                      controlName={control}
                      defaultValue={state['status'] ? state['status'] : 'ACTIVE'}
                      className="form-control"
                      id="example2cols2Input"
                      name="status"
                      data={[
                        { value: 'ACTIVE', label: 'ACTIVE' },
                        { value: 'INACTIVE', label: 'INACTIVE' },
                      ]}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" className="mb-3">
                  <CustSelect
                    defaultValue={state['currencyId']}
                    label="*Currency"
                    required={true}
                    error={errors}
                    singleValue={true}
                    controlName={control}
                    search={true}
                    name={'currency'}
                    data={currency}
                  />
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="example2cols1Input">
                      Jurisdiction
                    </label>
                    <input
                      defaultValue={state['jurisdiction'] || null}
                      className="form-control"
                      ref={register}
                      name="jurisdiction"
                      id="example2cols1Input"
                      placeholder="Jurisdiction"
                      type="text"
                      maxLength="50"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="example2cols1Input">
                      Service Tax Registration
                    </label>
                    <input
                      defaultValue={state['serviceTaxRegistration'] || null}
                      className="form-control"
                      ref={register}
                      name="serviceTaxRegistration"
                      id="example2cols1Input"
                      placeholder="Service Tax Registration"
                      type="text"
                      maxLength="50"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="example2cols1Input">
                      GSTIN Number
                    </label>
                    <input
                      defaultValue={state['gstInNumber'] || null}
                      className="form-control"
                      ref={register}
                      name="gstInNumber"
                      id="example2cols1Input"
                      placeholder="GSTIN Number"
                      type="text"
                      maxLength="50"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="example2cols1Input">
                      CIN/LLP Number
                    </label>
                    <input
                      defaultValue={state['cinllpNumber'] || null}
                      className="form-control"
                      ref={register}
                      name="cinllpNumber"
                      id="example2cols1Input"
                      placeholder="CIN/LLP Number"
                      type="text"
                      maxLength="50"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="example2cols1Input">
                      TID
                    </label>
                    <input
                      defaultValue={state['tId'] || null}
                      className="form-control"
                      ref={register}
                      name="tId"
                      id="example2cols1Input"
                      placeholder="TID"
                      type="text"
                      maxLength="50"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="example2cols1Input">
                      Reference ID
                    </label>
                    <input
                      defaultValue={state['referenceId'] || null}
                      className="form-control"
                      ref={register}
                      name="referenceId"
                      id="example2cols1Input"
                      placeholder="Reference ID"
                      type="text"
                      maxLength="50"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    {/* <label
                    className="form-control-label"
                    htmlFor="example2cols1Input"
                  >
                    Short Description
										</label> */}
                    <SimpleInput
                      defaultValue={state['businessAreaDispValue'] || null}
                      className="form-control"
                      required={true}
                      error={errors}
                      reference={register({ required: true })}
                      name="shortDes"
                      id="example2cols1Input"
                      placeholder="Short Description"
                      title="*Short Description"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    {/* <label
                    className="form-control-label"
                    htmlFor="example2cols1Input"
                  >
                    *Description
										</label> */}
                    <SimpleInput
                      defaultValue={state['businessAreaDesc'] || null}
                      className="form-control"
                      id="example3cols2Input"
                      error={errors}
                      required={true}
                      reference={register({ required: true })}
                      name="description"
                      id="example2cols1Input"
                      title="*Description"
                      type="text"
                      placeholder="Description"
                    />
                  </FormGroup>
                </Col>
              </Row>
              {action === 'new' ? (
                <Row>
                  <Col md="10"></Col>
                  <Col md="2">
                    <Button
                      color="primary"
                      className="mt-4"
                      type="button"
                      onClick={() => {
                        SaveButton();
                      }}
                    >
                      Save & Next
                    </Button>
                  </Col>
                </Row>
              ) : null}
            </CardBody>
          </Card>
        </Container>
      ) : null}
    </>
  );
};
export default BussinessUnit;
