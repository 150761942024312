import React, { useEffect, useState } from 'react';
import { ImCross } from 'react-icons/im';
import { ReactComponent as Search } from "../../../../../assets/img/svg/SearchIcon.svg";
import styled, { css } from 'styled-components';
import { failureToast } from '../../common/utils/methods/toasterFunctions/function';

const crossIconStyle = {
  fontSize: "11px",
  position: "absolute",
  top: "0.6rem",
  right: "30px",
  cursor: "pointer",
}

const SeachWrapper = styled.span`
  svg {
    position: absolute;
    top: 5.5px;
    right: 5px;
    cursor: pointer;
    z-index: 3;
  } 
`

const ErrorMsg = styled.div`
  color: #DC2804 ;
  margin-top: 5px;
  margin-left: 12px;
  font-size: 12px;
`

const StyledInput = styled.input`
  padding-left: 5px !important;
  
 
  
  ${props => props.errorMsg && props.inputValue.trim().length < 11 && props.inputValue.trim().length > 0  && css`
   border : 1px solid #DC2804 ;
   color : #DC2804  ;
    :hover:not([disabled]),
    :focus:not([disabled]) {
      border-color: #DC2804 !important;
      color : #DC2804 !important ; 
    }
    &:not(:placeholder-shown) {
      color: #DC2804 !important;
    }
  `}
`;

const SearchInput = ({ searchInputValueHandler, onCancel, placeholder,errorMsg=false,val}) => {
  const [inputValue, setInputValue] = useState('')
  
  const handler = (e) => {
    if(errorMsg){
    if (e.key === 'Enter' || e.keyCode === 13) {
       
      
      if (inputValue.trim().length === 11||inputValue.trim().length === 0) {
      searchInputValueHandler(inputValue)}
      else {
        failureToast('Please enter the 11 digit PSID number')
      }
    }}
    
    else {
    if (e.key === 'Enter' || e.keyCode === 13) {
      searchInputValueHandler(inputValue)
    }
  
  }}
  


  const clickHandler = () => {
    if (inputValue !== ''){
      searchInputValueHandler(inputValue)
    }
  }

  const onChangeHandler = (value) => {
    setInputValue(value)
  }

  const handlePaste  = (e) => {
    const pastedText = e.clipboardData?.getData('text/plain')?.replace(/\D/g,'');
    setInputValue(pastedText);
    e.preventDefault();
  }

  useEffect(()=>{
    if(val){
      setInputValue(val)
    }
  },[val])
  return (
    <div style={{ position: "relative" , minHeight : "51px" }}>
      <StyledInput
        className='search-input'
        id="inputValue"
        value={inputValue}
        placeholder={placeholder}
        onChange={(e) => {
          onChangeHandler(e.target.value);
        }}
        onKeyDown={handler}
        inputValue={inputValue}
        maxLength="11"
        onPaste={handlePaste}
        onKeyPress={(e) => {
          if (
            !/^[0-9]*$/.test(
              String.fromCharCode(e.charCode)
            )
          ) {
            e.preventDefault();
            return;
          }
        }}
        errorMsg={errorMsg} 
      />
       
      {errorMsg && inputValue.length > 0 &&
        <ImCross onClick={() => {
          setInputValue('')
          onCancel();
        }} style={crossIconStyle} />
      }
      {!errorMsg &&  <ImCross onClick={() => {
          setInputValue('')
          onCancel();
        }} style={crossIconStyle} />}
      {errorMsg && inputValue.trim().length === 11 &&
      <SeachWrapper> <Search onClick={clickHandler} /></SeachWrapper>}
      {!errorMsg &&  <SeachWrapper> <Search onClick={clickHandler} /></SeachWrapper> }
      {errorMsg && inputValue.trim().length < 11 && inputValue.trim().length > 0 && <ErrorMsg>Please enter the 11 digit PSID number</ErrorMsg>}
    </div>
  )
}

export default SearchInput;

