import React from "react";
import { MdCancel } from "react-icons/md";
import { useState, useEffect } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { Dialog } from "@mui/material";
import { fetchAllBatchesByApplicationId } from "redux/studentSearch/actions";
import ALoader from "./../../common/a-loader/index";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper";

const ViewBatchDetailsDialog = ({ handleClose, open, psid }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [batchList, setBatchList] = useState([]);
  useEffect(() => {
    //console.log(psid,"psidxxx")
    if (psid) fetchBatch(psid);
  }, [psid]);

  const fetchBatch = async (applicationId) => {
    setIsLoading(true);
    const response = await fetchAllBatchesByApplicationId(applicationId);
    setBatchList(response);
    setIsLoading(false);
  };

  const closeHandler = ()=>{
    handleClose()
    setBatchList([])
    setIsLoading(false);

  }

  return (
    <Dialog onClose={closeHandler} open={open}>
      <div
        style={{
          position: "relative",
          padding: "1rem",
          minWidth: "17rem",
          minHeight: "15rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "1.4rem",
            marginBottom: "10px",
            color: "black",
            cursor: "pointer",
          }}
        >
          <IconButtonWrapper onClick={handleClose}><MdCancel  color="black" fontSize="1.4rem"/></IconButtonWrapper>
        </div>
        <div>
          {isLoading && <ALoader />}
          {batchList.map((batch) => (
            <ListGroup key={batch.id}>
              <ListGroupItem>{batch.batchName}</ListGroupItem>
            </ListGroup>
          ))}
          {batchList.length === 0 && !isLoading && (
            <ListGroup>
              <ListGroupItem>No batch found</ListGroupItem>
            </ListGroup>
          )}
          <div></div>
        </div>
      </div>
    </Dialog>
  );
};

export default ViewBatchDetailsDialog;
