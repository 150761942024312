import React, { useEffect, useState } from 'react';
import MuiTable from '../../../../manage/common/commonComponents/MuiTable.js';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../../common/utils/methods/commonMethods/utilityMethod.js';
import { endpoint } from '../../../common/constant';
import { failureToast, successToast } from '../../../common/utils/methods/toasterFunctions/function.js';
import moment from 'moment';
import { getUniqueElements } from '../../../common/utils/methods/commonMethods/utilityMethod.js';
import APopover from '../../../common/a-popover/index.jsx';
import AButton from '../../../common/form-fields-mui/AButton.jsx';
import CareerLoanSetupFilter from './CareerLoanSetupFilter.jsx';
import CustomButton from 'components/CustomButton';
import { Badge } from '@mui/material';

const getFormatedDate = (date) => {
    return date ? moment(date).format('DD-MMM-YYYY') : ''
}


const CareerBasedLoanSetup = ({ userPermissions }) => {

    const [schemes, setSchemes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [acadmicCareerData, setAcadmicCareerData] = useState(null);
    const [minimumFeeComponentData, setMinimumFeeComponentData] = useState(null);
    const [loanVendors, setLoanVendors] = useState(null);
    const [allActiveBusinessAreas, setAllActiveBusinessAreas] = useState(null);
    const [filterForm, setFilterForm] = useState({});

    const handleFilterForm=(value , key)=>{
        // console.log('form', form);
        setFilterForm(prev=>({...prev, [key] : value}))
    }

    const getAcademicCareer = async () => {
        setLoading(true);
        const acadmicCareer = await fetchAllPromisedData(endpoint.academic_career.getAllActive, false);
        if (Array.isArray(acadmicCareer)) {
            const tempData = acadmicCareer?.map(p => { return { label: p.academicCareerDispValue, value: p.id } })
            setAcadmicCareerData(tempData)
        } else {
            setAcadmicCareerData([])
        }
    }
    const getMinimumFeeComponent = async () => {
        const minimumFee = await fetchAllPromisedData(endpoint.item_type_setup.getAll, false);
        if (Array.isArray(minimumFee)) {
            const tempData = minimumFee?.map(p => { return { label: p.description, value: p.id } })
            setMinimumFeeComponentData(tempData)
        } else {
            setMinimumFeeComponentData([])
        }
    }

    const getLoanVendors = async () => {
        try {
            const resp = await fetchAllPromisedData(`${endpoint.loanScheme}/allEffective`);
            if (resp) {
              const activeLoanVendors = resp?.filter(el => el.status === 'ACTIVE') ?? [];
              setLoanVendors(getUniqueElements(activeLoanVendors.map(item => ({ label: item.vendorName, value: item.vendorName })), (el) => el.value));
            } else {
              failureToast("Couldn't fetch loan schemes.");
            }
          } catch (err) {
            failureToast("Couldn't fetch loan schemes.");
          }
    }

    const getAllActiveBusinessAreas = async()=>{
        try{
        const resp = await fetchAllPromisedData(endpoint.businessArea.getAllActive,true);
        if(resp?.code === 200){
            console.log('inside ')
            setAllActiveBusinessAreas(resp?.data?.map(item =>({...item, label : item?.businessAreaDispValue, value : item?.id})))
        }
        }catch(err){
            console.log(err);
        }
    }


    const columns = [
        { field: 'loanId', headerName: 'ID', type: 'text', width: 100,mandatory: false, editable: false, disabled: false,flex:0.7},
        { field: 'businessAreas', headerName : 'Business Area', type : 'businessArea', width : 300 , mandatory : true, editable : true, disabled : false, flex : 1.3},
        { field: 'academicCaeer', headerName: 'Academic Career', type: 'singleSelect',valueOptions:acadmicCareerData, width: 250, mandatory: true, editable: true, disabled: false,flex:1 },
        { field: 'minimumFeeComponent', headerName: 'Min. Component paid', valueOptions:minimumFeeComponentData,width: 250, type: 'singleSelect', mandatory: true, editable: true, disabled: false ,flex:1},
        {
            field: 'minimumOutstanding', headerName: 'Min. Course Fee Outstanding', width: 250, type: 'number', allowDecimalvalue: true, editable: true, disabled: false,formatter: (data) => '₹ ' + data,  maxValue: 1000000,flex:1
        },
        { field: 'loanVendor', headerName: 'Loan Vendor', type: 'singleSelect',valueOptions:loanVendors, width: 250, mandatory: true, editable: true, disabled: false,flex:1 },
        {
            field: 'lastUpdate', headerName: 'Last update', type: 'date', minDate: new Date(),
            width:220, editable: false,flex:1
        }
    ];

    function createSchemes(schemesPayload) {

        return schemesPayload.map(scheme => {

            const { id,
                academicCareerId,
                minFeeComponent,
                minOutstanding,
                updatedOn,
                updatedBy,
                loanVendor,
                loanCareerBusinessAreaMappingList,
            } = scheme;

            const businessAreaIds = loanCareerBusinessAreaMappingList.map(item=>item.businessArea);


            return {
                id: id,
                loanId: id,
                academicCaeer : acadmicCareerData?.filter(item => item.value == academicCareerId),
                minimumFeeComponent : minimumFeeComponentData?.filter(item => item.value == minFeeComponent),
                minimumOutstanding : minOutstanding,
                lastUpdate : `${updatedBy}, ${getFormatedDate(updatedOn)}`,
                fromBE: true,
                businessAreas : allActiveBusinessAreas?.filter(bu => businessAreaIds.includes(bu?.id)),
                loanVendor : loanVendors.filter(item => item.value === loanVendor)
            }
        })
    }

    async function getAllLoanSchemes(filterObj={}) {
        setLoading(true);
        try {
            const response = await fetchAllPostPromisedData(`${endpoint.loanCareerValidation.search}?offset=0`,filterObj);
            // const response = await Promise.resolve(DATA);
            // setLoading(false);
            if (response) {
                const data = createSchemes(response?.data?.loanCareerValidationList);
                setSchemes(data);
                setLoading(false);
            } else {
                failureToast("Failed to fetch Data");
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            failureToast("Failed to fetch Data");
        }
    }

    const getFilterCount = () => {
        const { businessAreas, loanVendor, academicCareer } = filterForm;
        let count = 0;

        if (businessAreas?.length) count++;
        if (!!loanVendor) count++;
        if (!!academicCareer) count++;

        return count;
    }

    useEffect(()=>{
        getAcademicCareer();
        getMinimumFeeComponent();
        getLoanVendors();
        getAllActiveBusinessAreas();
    },[])

    useEffect(() => {
        if(acadmicCareerData !== null && minimumFeeComponentData !== null && loanVendors !== null && allActiveBusinessAreas !== null){
            getAllLoanSchemes();
        }
    }, [acadmicCareerData,minimumFeeComponentData, loanVendors, allActiveBusinessAreas])

    async function deleteScheme(row) {
        // setLoading(true);
        // const p = new Promise((resolve, reject) => {
        //     fetchAllPostPromisedData(`${endpoint.loanCareerValidation.delete}/${row.id}`, null, 'delete')
        //         .then(response => {
        //             if (response && response.code === 200) {
        //                 successToast(response.message || "Request Completed Successfully");
        //                 resolve(response.message);
        //             } else {
        //                 failureToast(response.message)
        //                 reject(response.message);
        //             }
        //         })
        //         .catch(err => reject(err));
        // });
        // return p;
        setLoading(true);
        const p = new Promise((resolve, reject) => {
            fetchAllPostPromisedData(`${endpoint.loanCareerValidation.delete}/${row.id}`, {}, 'delete')
            .then(response => {
                setLoading(false);
                if(response && response.code === 200) {
                    successToast('Career based loan set-up deleted successfully!');
                    resolve(row);
                } else {
                    failureToast(response.message)
                    reject(response.message);
                }
            })
            .catch(err => {setLoading(false); reject(err);});
        });
        return p;
    }

    async function saveScheme(row) {
        setLoading(true);
        const request = {
            "academicCareerId" : row.academicCaeer?.[0]?.value,
            "academicCareerDisplayVal" : row.academicCaeer?.[0]?.label,
            "minFeeComponent" : row.minimumFeeComponent?.[0]?.value,
            "minOutstanding" : Number(row.minimumOutstanding),
            "status" : "ACTIVE",
            "loanVendor" : row.loanVendor?.[0]?.value,
            "businessArea" : row.businessAreas?.map(item => item.value)
        }
        const isRowNew = row.fromBE !== true;
        const p = new Promise((resolve, reject) => {
            fetchAllPostPromisedData(isRowNew ? `${endpoint.loanCareerValidation.create}` :`${endpoint.loanCareerValidation.update}/${row.id}`, request, isRowNew ? 'post' : 'put')
                .then(response => {
                    setLoading(false);
                    if (response && response.code === 200) {
                        successToast(`Career based loan set-up ${isRowNew ? 'added' : 'edited'} successfully!`);
                        if(isRowNew) {
                            getAllLoanSchemes();
                        }   
                        resolve(row);
                    } else {
                        failureToast(response.message)
                        reject(response.message);
                    }
                })
                .catch(err => {setLoading(false); reject(err);});
        });
        return p;
    }

    const applyFilterHanlder=()=>{
        const {businessAreas , loanVendor, academicCareer} = filterForm;
        const filterObj={
            businessArea : businessAreas?.map(bu => bu.value),
            academicCareer : academicCareer?.label,
            loanVendor : loanVendor?.value
        }

        setFilterForm(prev => ({...prev , appliedFilterCount: getFilterCount()}))

        getAllLoanSchemes(filterObj);
    }

    const removeFilterHandler =()=>{
        getAllLoanSchemes();
        setFilterForm({});
    }

    const headerMiscellaneousRightComp = (

        <APopover
        buttonComponent={onClick=>
            // <AButton
            // variant="outline"
            // onClick={onClick}
            // >
            //     Filters
            // </AButton>
            <Badge badgeContent={filterForm?.appliedFilterCount} color="primary">
            <CustomButton
                className={'regular-large outline-btn'}
                content={<span>Filter</span>
                }
                outline
                onClick={onClick}
                permissionType={'R'}
                permissionSet={userPermissions}
            />
            </Badge>
        }
        menuComponent={closePopup=>
        <CareerLoanSetupFilter
        loanVendors={loanVendors}
        businessAreaDropdown={allActiveBusinessAreas}
        academicCareerData={acadmicCareerData}
        form={filterForm}
        applyFilterHandler={applyFilterHanlder}
        handleFilterForm={handleFilterForm}
        closePopup={closePopup}
        removeFilterHandler={removeFilterHandler}
        filterCount={getFilterCount()}
        />
    }
        />
    )

    const loanSchemeHeaderComp = (<h2 style={{ fontWeight: 600 }}>Career based loan set-up</h2>);

    return (
        <MuiTable
            rows={schemes}
            columns={columns}
            headerComp={loanSchemeHeaderComp}
            userPermissions={userPermissions}
            loading={loading}
            asyncDeleteRow={deleteScheme}
            asyncSaveRow={saveScheme}
            useDeleteIconForCancel={true}
            deleteConfirmationHeader={'Confirm career based loan set-up delete'}
            deleteConfimationMsg={'Are you sure you want to delete “Career set-up name” ?'}
            minHeight={156}
            headerMiscellaneousRightComp={headerMiscellaneousRightComp}
        />
    )
}

export default CareerBasedLoanSetup;
// import React from 'react'

// const CareerBasedLoanSetup = ({userPermissions}) => {

//     return (
//         <div>Career based loan set-up</div>
//     )
// }

// export default CareerBasedLoanSetup;