import React from "react";
import { headerStyle } from "../stockTxns/style";
import { ReactComponent as BackIcon } from "assets/img/svg/BackButton.svg";
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'

const PageHeader = ({ history, isDlp, onClickHandler, title, children }) => {
  const onClick = isDlp ? history.goBack : window.close;
  return (
    <div
      className="d-flex heading-4 color-dark align-items-center justify-content-between"
      style={headerStyle}
    >
      <div className="d-flex align-items-center">
        <IconButtonWrapper onClick={onClickHandler ? onClickHandler : onClick}><BackIcon /></IconButtonWrapper>
        <div className=""> {title ? title : "Branch consumption"} </div>
      </div>
      {children}
    </div>
  );
};

export default PageHeader;
