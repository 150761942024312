import React, {useState, useEffect} from 'react';
import {Button,Card,CardHeader,Row,Col,Table} from 'reactstrap';
import {MasterHeaderAuto} from "../../../../common/commonComponents/masterHeader/masterHeader";
import {CustomTableRow, StatusButton} from "../../../../common/commonComponents/TableComponents";
import {ToExcel} from "../../../../common/commonComponents";
import {getDispValForReactSelectByOneID} from "../../../../common/utils/methods/commonMethods/utilityMethod";
import {logger} from "redux-logger";
import moment from "moment";
import CustomButton from "components/CustomButton";

const TableRowData = (props)=>{
    const {item,index,businessArea,chkCollapse,history, userPermissions} = props;
    return (
        <CustomTableRow>
            <td className="text-center p-2 mx-1 white-breakSpace">{item.studentId}</td>
            <td className="text-center p-2 mx-1 white-breakSpace">{item.studentName}</td>
            <td className="text-center p-2 mx-1 white-breakSpace">{item.rollNumber}</td>
            <td className="text-center p-2 mx-1 white-breakSpace">{item.generatedExamId}</td>
            <td className="text-center p-2 mx-1 white-breakSpace">{item.examDescription}</td>
            <td className="text-center p-2 mx-1 white-breakSpace">{item.testRecId}</td>
            <td className="text-center p-2 mx-1 white-breakSpace">{getDispValForReactSelectByOneID(item.businessAreaId ,'DispValue',businessArea)}</td>
            <td className="text-center p-2 mx-1 white-breakSpace">{item.adjustmentFlag}</td>
            <td className="text-center p-2 mx-1 white-breakSpace"> <StatusButton status={item.status}/></td>
            <td className="text-center p-2 mx-1">
                {/* <Button color="info" size="sm"
                    // className={"floatRight mx-1" +((!selectedAcademicCareer && !selectedCompanyCode && !selectedGroupCode) ? ' btn-dark' : null)}
                        onClick={ ()=>chkCollapse(item.id,index)}
                >
                    Result Details
                </Button> */}
                <CustomButton
                    forTable={true}
                    content={'Result Details'}
                    permissionType={'R'}
                    onClick={ ()=>chkCollapse(item.id,index)}
                    permissionSet={userPermissions}
                />
            </td>
            <td className="text-center p-2 mx-1">
                {/* <Button color="info" size="sm" onClick={ ()=> window.open( `${ document.URL }/edit/${item.id}`, '_blank' )}>
                    <i className="fas fa-edit" />
                </Button> */}
                <CustomButton
                    forTable={true}
                    permissionType={'U'}
                    icon={true}
                    onClick={ ()=> window.open( `${ document.URL }/edit/${item.id}`, '_blank' )}
                    permissionSet={userPermissions}
                />
            </td>
        </CustomTableRow>
    );
}

const InternalTestResultTable = (props)=>{
    const {
        internalTestData,
        businessArea,history,excelDetails,academicYear,exportData
    }=props;
    const inner_details = [
        [
            // { ExamType: "Exam Type" },
            { academicYearId: "Academic Year" },
            { testRecId: "Test Rec ID" },
            { percentage: "Percentage" },
        ],
        [
            { percentile: "Percentile" },
            { allIndRank: "AIR" },
            { examDate: "Exam Date" },
        ],
    ];


    let [isOpen, setIsOpen] = useState({});
    const headerList =[
        {name:"Student ID"},
        {name:"Student Name"},
        {name:"Roll Number"},
        {name:"Exam ID"},
        {name:"Exam Description"},
        {name:"Test Rec ID"},
        {name:"Business Area"},
        {name:"Adjustment Flag"},
        {name:"Status"},
        {name:"Detail"},
        {name:"Action"}
    ];

    const headers = [
        { label: "Business Area", key: "businessArea" },
        { label: "Academic Year", key: "academicYear" },
        { label: "Academic Phase", key: "academicPhase" },
        { label: "Batch", key: "batch" },
        { label: "Course", key: "course" },
        { label: "Exam", key: "exam" },
        { label: "Subject", key: "subject" },
        { label: "Chapter", key: "chapter" },
        { label: "Topic", key: "topic" },
        { label: "Sub - topic", key: "subTopic" },
        { label: "Status", key: "status" }
    ];

    const chkCollapse = (id,index) => {
        setIsOpen({ ...isOpen, [id]: !isOpen?.[id] ?? true });
    };

    return (
        <Row>
            <div className="col" key={'InternalTestResultTableDiv'}>
                <Card>
                    <CardHeader>
                        <ToExcel
                            data={exportData}
                            detailObj={excelDetails}
                            name={'InternalTestResult'}
                            isSaveVisible={true}
                        />
                    </CardHeader>
                    <div id="questionTable" className="table-responsive">
                        <Table className="align-items-center table-flush tableLayout">
                            <MasterHeaderAuto headerList={headerList} permissionSet={props.userPermissions}/>
                            <tbody className="list" key={'internalTestTableRow'}>
                            {
                                internalTestData?.length ?
                                    internalTestData.map((item,index)=>
                                    <>
                                        <TableRowData key={'TableRowData'+index} item={item} index={index} businessArea={businessArea} chkCollapse={chkCollapse} history={history} userPermissions={props.userPermissions}/>
                                        <tr>
                                            <td className={"hiddenRow"} colSpan={8}>
                                                <div key={`epmr-${index}`} className={ isOpen?.[`${item.id}`] ? "details details-height-auto" : "details"}>
                                                    {
                                                        inner_details.map((detail_item, index) => {
                                                            return (
                                                                <Row className="p-3 text-center  white-breakSpace ml-md-5">
                                                                    {
                                                                        detail_item.map((el, index) => {
                                                                            return (
                                                                                <Col className="text-left">
                                                                                    <div className="epmr-inner-details-header">
                                                                                        {Object.values(el)[0]}
                                                                                    </div>
                                                                                    <div>
                                                                                        {

                                                                                            Object.keys(el)[0] === 'academicYearId' ?
                                                                                                getDispValForReactSelectByOneID(item[Object.keys(el)[0]] ,'DispValue',academicYear)
                                                                                            :
                                                                                               Object.keys(el)[0] === 'examDate' ?
                                                                                                   moment(item[Object.keys(el)[0]]).format('DD-MMM-YYYY')

                                                                                                   :item[Object.keys(el)[0]]
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            )
                                                                        })
                                                                    }
                                                                </Row>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                        </>
                                    )
                                    :
                                    null
                            }
                            </tbody>
                        </Table>
                    </div>
                </Card>
            </div>
        </Row>
    )
}

export default InternalTestResultTable;