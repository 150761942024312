import React, { useEffect, useState } from "react";
import APopover from "../../../../common/a-popover";
import AButton from "../../../../common/form-fields-mui/AButton";
import { highlightedStyle } from "views/pages/manage/edpOps/stockManagement/mastersAndMapping/onBoardingMaterialIdSetup/SearchFilter";
import { getFilterCount } from "../../mastersAndMapping/onBoardingMaterialIdSetup/constant";
import { Card, CardBody } from "reactstrap";
import AAutoComplete from "../../../../common/form-fields-mui/AAutoComplete";

const dropdown = {
  type: [
    { label: "Serialized", value: "serialised" },
    { label: "Non Serialized", value: "non-serialised" },
  ],
  status: [
    { label: "Active", value: "ACTIVE" },
    { label: "Inactive", value: "INACTIVE" },
  ],
  plantId: [
    { label: "HO01", value: "HO01" },
    { label: "DP01", value: "DP01" },
    { label: "DG01", value: "DG01" },

  ],
};
const SearchFilterForm = ({
  closeForm,
  filterCount,
  filterPayload,
  removeFilterHandler,
  applyFilter = () => {},
}) => {
  const [filterForm, setFilterForm] = useState(filterPayload);
  const filterFormHandler = (value, key) =>
    setFilterForm((prev) => ({ ...prev, [key]: value }));

  useEffect(() => {
    setFilterForm(filterPayload);
  }, [filterPayload]);

  const onRemoveHandler = () => {
    removeFilterHandler();
    setFilterForm({});
    closeForm()
  };

  return (
    <Card
      className="mb-0 regular-body label-color"
      style={{ width: "330px", borderRadius: "12px" }}
    >
      <CardBody className="d-flex flex-column">
        <div className="mb-1 d-flex align-items-start justify-content-between">
          <div className="regular-large-bold">Filter by</div>
        </div>
        <div className="d-flex flex-column gap-md">
          <AAutoComplete
            label="Type"
            currentValue={filterForm?.type}
            items={dropdown?.type}
            handleChange={(value) => {
              filterFormHandler(value, "type");
            }}
            isMulti={false}
          />
          <AAutoComplete
            label="Sap Plant id"
            currentValue={filterForm?.plantId}
            items={dropdown?.plantId}
            handleChange={(value) => {
              filterFormHandler(value, "plantId");
            }}
            isMulti={false}
          />
          <AAutoComplete
            label="Status"
            currentValue={filterForm?.status}
            items={dropdown?.status}
            handleChange={(value) => {
              filterFormHandler(value, "status");
            }}
            isMulti={false}
          />
        </div>
        <div className="mt-3 d-flex flex-row-reverse justify-content-between">
          <AButton
            variant="primary_filled"
            className="button_remove_left_margin"
            onClick={() => {
              closeForm();
              applyFilter(filterForm);
            }}
            disabled={!getFilterCount(filterForm)}
          >
            Apply filter
          </AButton>
          {filterCount > 0 && (
            <AButton
              variant="outline"
              className="button_remove_left_margin"
              updatedStyle={{ border: "1px solid #CC5F5F", color: "#CC5F5F" }}
              onClick={onRemoveHandler}
            >
              Remove filter
            </AButton>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

const Filter = ({ dropdown, filterForm, applyFilter, removeFilterHandler }) => {
  const filterCount = getFilterCount({
    ...filterForm,
    applicationId: "",
    psid: "",
  });

  return (
    <div className="d-flex justify-content-end align-items-center">
      <APopover
        buttonComponent={(onClick, styles) => (
          <AButton
            className="color-dark"
            onClick={onClick}
            updatedStyle={{
              minWidth: "61px",
              ...(filterCount > 0 ? highlightedStyle : styles),
            }}
          >
            Filter {filterCount > 0 && <>( {filterCount} )</>}
          </AButton>
        )}
        menuComponent={(closeForm) => (
          <SearchFilterForm
            closeForm={closeForm}
            filterCount={filterCount}
            applyFilter={applyFilter}
            filterPayload={filterForm}
            removeFilterHandler={removeFilterHandler}
          />
        )}
        maxHeight={"calc(100vh - 250px)"}
      />
    </div>
  );
};

export default Filter;
