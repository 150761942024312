import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup, Input, Label } from 'reactstrap';
import Select from 'react-select';
import { getAPI, masterServiceBaseUrl, failureToast } from 'services/http';
import { getDispValForSelectByOneID } from '../../../common/utils/methods/commonMethods/utilityMethod';
import moment from 'moment';
import CommonInput from '../../../common/formFeilds/input/commonInput';

const HeaderInformation = (props) => {
	const {
		action,
		country,
		status,
		bankDetails,
		submitDataHandler,
		actionDisabled,
	} = props;

	useEffect(() => {

	}, [bankDetails]);
	return (
		<Row>
			<Col>
				<Row>
					<Col md={6}>
						<CommonInput
							label={'Country'}
							mandatory={true}
							type={'reactSelect'}
							data={country}
							defaultValue={bankDetails['countryCode']}
							isDisabled={actionDisabled}
							onChange={(value) => {
								submitDataHandler(value, 'countryCode');
							}}
							placeHolder={'Select Country'}
						/>
					</Col>
					<Col md={6}>
						<CommonInput
							label={'Status'}
							mandatory={true}
							type={'reactSelect'}
							data={status}
							defaultValue={bankDetails['status']}
							isDisabled={actionDisabled}
							onChange={(value) => {
								submitDataHandler(value, 'status');
							}}
							placeHolder={'Select Status'}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<CommonInput
							label={'Bank Code'}
							type={'text'}
							placeHolder={'Bank Code'}
							defaultValue={bankDetails['bankCode']}
							isDisabled={actionDisabled}
							mandatory={true}
							onChange={(value) =>
								submitDataHandler(value.toUpperCase(), 'bankCode')
							}
							restrictSpecialCharacter={true}
							maxLength={6}
						/>
					</Col>
					<Col md={6}>
						<CommonInput
							label={'Alternate Bank Code'}
							type={'text'}
							placeHolder={'Alternate Bank Code'}
							defaultValue={bankDetails['alternateBankCode']}
							isDisabled={actionDisabled}
							mandatory={true}
							onChange={(value) =>
								submitDataHandler(value.toUpperCase(), 'alternateBankCode')
							}
							restrictSpecialCharacter={true}
							maxLength={6}
						/>
					</Col>
					<Col md={6}>
						<CommonInput
							label={'Bank Name'}
							type={'text'}
							placeHolder={'Bank Name'}
							defaultValue={bankDetails['bankName']}
							isDisabled={actionDisabled}
							mandatory={true}
							onChange={(value) => submitDataHandler(value, 'bankName')}
							restrictSpecialCharacter={true}
							maxLength={100}
						/>
					</Col>
					<Col md={6}>
						<FormGroup>
							<Label className="form-control-label">Effective Date</Label>
							<Input
								data-testid="effectiveDate"
								disabled={true}
								value={
									action === 'view' || action === 'edit'
										? moment(bankDetails['effectiveDate']).format('DD-MM-YYYY')
										: null
								}
								placeholder="Example:01-01-2021"
								className="tdInput"
								
							/>
						</FormGroup>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default HeaderInformation;
