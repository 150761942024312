import { Dialog } from '@mui/material'
import React from 'react'
import AButton from 'views/pages/manage/common/form-fields-mui/AButton'
import { ReactComponent as CrossIcon } from 'assets/img/svg/CrossIcon.svg'
import { ReactComponent as QuestionIcon } from 'assets/img/svg/QuestionMarkConfirmationDialog.svg'
import AllOnlinePackage from "./AllOnlinePackage"
const ViewOnlinePackageDialog = ({ open, setOpen, data }) => {

  const handleClose = () => setOpen(false);
  return (
    <Dialog onClose={handleClose} open={open} data={data}>
      <div style={{ padding: "28px 24px", width: "500px" }}>
        <div className='d-flex justify-content-between align-items-start mb-3'>
        <div className="" style={{fontSize:24,fontWeight:"950"}}>
        Online Package ID
            </div>
          <CrossIcon width={32} height={32} onClick={handleClose} />
        </div>
        <div>
            <AllOnlinePackage data={data}/>
        </div>

      </div>
    </Dialog>
  )
}

export default ViewOnlinePackageDialog