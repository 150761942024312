import React from 'react'
import { ReactComponent as NoDataIcon } from '../../../../../../assets/img/svg/NoDataIcon.svg'
import { ReactComponent as ZikZakArrow } from '../../../../../../assets/img/svg/ZikZakArrow.svg'

const listViewStyles = {
  backgroundColor: '#FFFFFF',
  padding: '24px 24px',
  borderRadius: '12px 0px 12px 12px',
  minHeight: '90vh'

}
const zikZakStyle  = {
  width:"100%",
  display:"flex",
  flexDirection:"column",
  alignItems:"flex-start"
}
const NoLecturesDisplay = ({ listView = false }) => {
  return (
    <div className='d-flex flex-column align-items-center justify-content-center' style={{ ...(listView && listViewStyles) }}>
     <div style={zikZakStyle}>
      <ZikZakArrow/>
      </div>
      <NoDataIcon className='mb-3' />
      {listView ? (
        <span className='regular-large' style={{ color: '#545454' }}>Click on ‘Add Lecture’</span>
      ) : (
        <span className='regular-large' style={{ color: '#545454' }}>Click on ‘Add’ to start create leature plans</span>
      )}
    </div>
  )
}

export default NoLecturesDisplay