import React, { useState, useRef, useEffect } from 'react'
import { FaTimes } from 'react-icons/fa'
import { Card } from 'reactstrap';
import closeIcon from 'assets/img/svg/closeSquare.svg';
import CustomButton from '../../../../../../../components/CustomButton';
import DocPreview from '../../../concessionApproval/docPreview';
import { uploadpost } from 'services/utils'
import { putpost } from 'services/utils'
import { fetchAllPostPromisedData, fetchAllPromisedData } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod'
import { endpoint } from 'views/pages/manage/common/constant'
import { successToast, failureToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function'
import { Box } from '@mui/system';
import { IconButton, Tooltip, Divider, Dialog, Typography, Tabs, Tab } from '@mui/material';
import { regex } from 'views/pages/manage/common/constant'
import '../verifyCharges/index.scss'
import ALoader from '../../../../common/a-loader';

const MandatoryDocUploadDialog = ({
    open,
    applicationQuotationDetailsId,
    getConcessionDetails,
    concessionDetails,
    docRequiredForCustomConcession,
    allCategories,
    totalConcessionAppliedPercentage,
    customConfig,
    handleSendApproval,
    loading,
    setMandatoryDocDialogOpen
}) => {

    const fileInputRef = useRef(null);
    const [uploading, setUploading] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [preview, setPreview] = useState(null);
    const [processInsID, setProcessInsID] = useState(null);
    const [docStatus, setDocStatus] = useState([]);

    const docMandatoryForAllConcessions = totalConcessionAppliedPercentage >= customConfig?.documentUploadPercentCriteria;

    const allMandatoryDocSubmitted = () => {

        if(docMandatoryForAllConcessions){
            let pending = false;
            concessionDetails.forEach(el => {
                if (!el.concessionDocuments?.length) {
                    pending = true
                }
            })
            return !pending
        }else{
            let allowed = true;
        concessionDetails.forEach(el => {
            if (el?.concessionType === "CUSTOM" && !el.concessionDocuments?.length && el?.concessionApprovalStatus !== 'REVOKE' && !['L1_REJECT', 'L2_REJECT', 'L3_REJECT'].includes(el?.concessionApprovalStatus)) {
                if (docRequiredForCustomConcession) {
                    allowed = false
                }
            } else {
                const isDocAttached = el?.concessionDocuments?.length ? true : false;
                const isRequired = getDocUploadRequired(el) === 'Yes' ? true : false;
                if (isRequired && !isDocAttached) {
                    allowed = false
                }
            }
        })
        return allowed;
    }

    }


    const getConcessionName = (subId, subSubId) => {
        let name = ''
        allCategories.forEach(element => {
            if (subId) {
                const filteredObj = element?.subCategories.find(i => i.id === subId);
                if (filteredObj?.subCategoryDispVal) {
                    name = filteredObj?.subCategoryDispVal
                }
            } else {
                element.subCategories.forEach(el => {
                    const filteredObj = el?.subSubCategory.find(i => i.id === subSubId);
                    if (filteredObj?.subSubCategoryDisplayValue) {
                        name = filteredObj?.subSubCategoryDisplayValue
                    }
                })
            }
        })

        return name
    }

    const getDocUploadRequired = (row) => {
        const c = docStatus.find(i => (i?.subCategoryId && i?.id === row?.concessionSubSubCategoryId) || i?.id === row?.concessionSubCategoryId);
        return c?.documentUpload ? 'Yes' : 'No'
    }

    const openPopOver = (data) => {
        setProcessInsID(data?.[0]?.processInstanceId)
        setPreview(data)
    }

    const handleClose = () => {
        setPreview(null)
    }

    const getFilePath = (file) => {
        try {
            setUploading(true);
            const formData = {
                bulk: false,
                fileName: file.name,
                sourceId: 1,
                keyValue: new Date(),
                docType: file.name.split(".")[1],
                serviceName: "ENROLLMENT_CONCESSION_UPLOAD",
            };

            putpost(endpoint.fetchS3Url.getUrl,
                (data) => {
                    if (data.code === 200 && data.data) {
                        saveDataToStorage(data.data, file);
                    }
                },
                (data) => {
                    setSelectedDoc(null)
                    setUploading(false)
                    failureToast("Something went wrong please try after sometime.")
                },
                formData,
                "post"
            );
        } catch (e) {
            console.log(e);
        }
    };

    const saveDataToStorage = (res, file) => {

        // const formData = new FormData();
        // formData.append("File", file);
        // formData.append("destinationfolder", res.bucket);

        // setISUploading(true);
        uploadpost(
            res.storage,
            (data) => {
                addFileToQoutation(res, file)
            },
            (data) => {
                setSelectedDoc(null)
                setUploading(false)
                failureToast(data["message"]);
                return null
            },
            file,
            file?.type,
            file?.name
        );
    };

    const addFileToQoutation = async (data, file) => {
        const request = [{
            "applicationQuotationDetailsId": applicationQuotationDetailsId,
            "uploadedFileKey": data?.key,
            "documentType": selectedDoc?.name,
            "processInstanceId": data?.processId,
            "fileName": file?.name,
            applicationConcessionId: selectedDoc?.id
        }]

        const response = await fetchAllPostPromisedData(`${endpoint.concessionFiles}`, request, 'post');
        if (response?.code === 200) {
            setSelectedDoc(null)
            setUploading(false)
            // getApplicationDetails()
            getConcessionDetails()
            successToast(response.message);
        } else {
            setSelectedDoc(null)
            setUploading(false)
            // failureToast("some")
        }
    }

    // logic for attach


    const handleFileSelect = (name, id) => {
        setSelectedDoc({ name: name, id: id })
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        setUploading(true)
        const allowedExt = ['jpeg', 'png', 'pdf', 'jpg'];
        try {
            const currentFile = e.target.files?.[0];
            const currentFileName = currentFile.name;
            const currentFileSize = currentFile.size;
            if (!/^[^.]*\.[^.]+$/.test(currentFileName)) {
                failureToast("File name error. Please rename file without special characters and upload");
                setUploading(false)
                return
            }
            if (allowedExt.indexOf(currentFileName.split(".")[1]) > -1) {
                if (currentFile.name.length <= 100) {
                    let value = currentFileName.split(".")[0];

                    if (regex.alphaNumericWithSpaceAndUnderscore.test(value)) {
                        if (currentFileSize && (currentFileSize) / (1024 * 1024) > 10) {
                            failureToast(`File Size error. Please upload file not exceeding 10 MB size`)
                            setUploading(false)
                            return
                        } else {
                            // setSelectedFile(currentFile)
                            getFilePath(currentFile)
                        }
                    } else {
                        failureToast("File name error. Please rename file without special characters and upload")
                        setUploading(false)
                        return
                    }
                } else {
                    failureToast("File name error. Please rename file not exceeding 100 characters.")
                    setUploading(false)
                    return
                }
            } else {
                failureToast(` File Format error. Please upload file in ${allowedExt.join(', ')} format only`)
                setUploading(false)
                return
            }
        } catch (e) {
            console.log(e)
        }
        fileInputRef.current.value = '';
    };

    const getCategoryInfo = async () => {
        const calls = [];
        concessionDetails.forEach(el => {
            if (el?.isDocMandatory === undefined) {
                if (el?.concessionSubSubCategoryId) {
                    calls.push(fetchAllPromisedData(`${endpoint.subSubCategory}/${el?.concessionSubSubCategoryId}`))
                }
                if (el?.concessionSubCategoryId) {
                    calls.push(fetchAllPromisedData(`${endpoint.subCategory}/${el?.concessionSubCategoryId}`))
                }
            }
        })

        const p = await Promise.all([...calls]);
        setDocStatus(p)

    }

    useEffect(() => {
        if (concessionDetails.length && open) {
            getCategoryInfo()
        }
    }, [concessionDetails, open])

    return (
        <Dialog
            fullWidth
            maxWidth={'md'}
            open={open}
        >
             {loading && <ALoader position={"fixed"}/>}
            <Card className='verify-details w-100 mb-0'>
                <div className='d-flex justify-content-between mb-1'>
                    <h4>Document Checklist</h4>
                    <div onClick={()=>setMandatoryDocDialogOpen(false)}><img src={closeIcon} /></div>
                </div>
                <div className='document-checklist'>
                    <div className='table-wrapper'>
                        <table className='w-100'>
                            <tr className=''>
                                <th className='regular-bolder label-color' >Document type<Divider variant="middle" className='hr-style' /></th>
                                <th className='regular-bolder label-color'>Status <Divider variant="middle" className='hr-style' /></th>
                                <th className='regular-bolder label-color'>Mandatory <Divider variant="middle" className='hr-style' /></th>
                                <th className='regular-bolder label-color'>Document<Divider variant="middle" className='hr-style' /></th>
                                <th className='regular-bolder label-color'>Action <Divider variant="middle" className='hr-style' /></th>
                            </tr>
                            {
                                concessionDetails.map((conc, index) => {
                                    return (conc?.concessionType === "CUSTOM" && !['L1_REJECT', 'L2_REJECT', 'L3_REJECT'].includes(conc?.concessionApprovalStatus)) || conc?.concessionType === "DEFINED" ? <tr className='my-2' key={`${conc?.applicationQuotationDetailsId}-${index}`}>
                                        <td className='label-color'>{conc?.concessionType === "CUSTOM" ? conc?.concessionType : getConcessionName(conc?.concessionSubCategoryId, conc?.concessionSubSubCategoryId)}</td>
                                        <td><div className={`checklist-status label-color d-flex regular-small ${conc?.concessionDocuments?.length ? 'completed-status' : 'pending-status'}`}>{conc?.concessionDocuments?.length ? 'Completed' : 'Pending'}</div></td>
                                        <td ><div className='mandetory label-color regular-small'>{docMandatoryForAllConcessions 
                                        ? 'Yes' 
                                        : conc?.concessionType === "CUSTOM" 
                                        ? docRequiredForCustomConcession ? 'Yes' : 'No' 
                                        : getDocUploadRequired(conc)}
                                        </div></td>
                                        <td><span className={`regular-small ${conc?.concessionDocuments?.length ? 'pointer' : 'cursor-not-allowed'}`} onClick={conc?.concessionDocuments?.length ? () => openPopOver(conc?.concessionDocuments) : () => { }}>View</span></td>
                                        <td><div>
                                            <CustomButton
                                                className={'regular-small BTNbgChange'}
                                                content={uploading && (selectedDoc?.name === conc?.concessionType || selectedDoc?.name === getConcessionName(conc?.concessionSubCategoryId, conc?.concessionSubSubCategoryId)) ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : 'Attach'}
                                                onClick={() => { handleFileSelect(conc?.concessionType === "CUSTOM" ? "CUSTOM" : getConcessionName(conc?.concessionSubCategoryId, conc?.concessionSubSubCategoryId), conc?.id) }}
                                                forTable={true}
                                                permissionType={'C'}
                                                permissionSet={'C'}
                                                disabled={conc?.concessionDocuments?.length}
                                            />
                                        </div></td>
                                    </tr> : null
                                })
                            }
                        </table>
                    </div>
                    <div className='pt-3'>
                        {/* <CustomButton
                            className={'regular-large br-8 outline-btn btn-left-0'}
                            content={'Go Back'}
                            outline
                            onClick={() => setActiveTab(0)}
                            permissionType={'R'}
                            permissionSet={'R'}
                            disabled={loading}
                        /> */}
                        <CustomButton
                            className={'regular-bolder br-8 no-ml-btn'}
                            content={loading ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : 'Send For Approval'}
                            onClick={handleSendApproval}
                            permissionType={'R'}
                            permissionSet={'R'}
                            disabled={!allMandatoryDocSubmitted() || loading}
                        />
                    </div>
                </div>

                <div
                    style={{
                        position: "absolute",
                        left: "-99999px",
                        top: "-99999px",
                        maxWidth: "800px",
                    }}
                />
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />

                <Dialog open={preview?.length ?? false} onClose={handleClose} maxWidth={false}>
                    <Box className='concession-approval-dialog-content' width={'88.2vw'}>
                        <Box className='approval-dialog-header'>
                            <Typography variant='h6'>{'Documents'}</Typography>
                            <div onClick={handleClose}><img src={closeIcon} /></div>
                        </Box>
                        {preview?.length > 0 && <Box>
                            <Tabs value={processInsID} onChange={(e, val) => setProcessInsID(val)} variant="scrollable" scrollButtons="auto">
                                {preview?.map((file) => (<Tab key={file.processInstanceId} label={file.fileName} value={file.processInstanceId} />))}
                            </Tabs>
                            {processInsID ? <DocPreview fileType={preview.find(i => i.processInstanceId === processInsID)?.fileName?.split('.')?.[1]} uploadedKey={preview.find(i => i.processInstanceId === processInsID)?.uploadedFileKey} /> : null}
                        </Box>}
                    </Box>
                </Dialog>

            </Card>
        </Dialog>

    );
}

export default MandatoryDocUploadDialog;