import { regex } from "views/pages/manage/common/constant";
import {
  dropdownMapping,
  isFormFieldEmpty,
} from "../../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "views/pages/manage/common/constant";
import { useEffect, useState } from "react";

export const transactionTypeMap = {
  PSID_ISSUANCE: {
    RCC: "PSID_ISSUANCE",
    DLP: "DLP_PSID_ISSUANCE",
    DIGITAL: "DIGITAL_PSID_ISSUANCE",
  },
  PSID_RETURN: {
    RCC: "PSID_RETURN",
    DLP: "DLP_PSID_RETURN",
    DIGITAL: "DIGITAL_PSID_RETURN",
  },
  BRANCH_ISSUANCE: {
    RCC: "BRANCH_ISSUANCE",
    DLP: "DLP_BRANCH_ISSUANCE",
    DIGITAL: "DIGITAL_BRANCH_ISSUANCE",
  },
  PSID_REVERSAL: {
    RCC: "PSID_REVERSAL",
    DLP: "DLP_PSID_REVERSAL",
    DIGITAL: "DIGITAL_PSID_REVERSAL",
  },
  BRANCH_RETURN: {
    RCC: "BRANCH_RETURN",
    DLP: "DLP_BRANCH_RETURN",
    DIGITAL: "DIGITAL_BRANCH_RETURN",
  },
};

export const applicationSearchInitialForm = (academicCareer, dropdown) => ({
  businessArea:
    academicCareer === "DLP"
      ? dropdown?.businessAreas?.find((item) => item?.label === "DW005")
      : "",
  transactionId: "",
  psid: "",
  applicationId: "",
  studentName: "",
  term: "",
  courseId: "",
  batchId: "",
  type: transactionTypeMap["PSID_ISSUANCE"][academicCareer],
  groupBy: "Application",
});

export const useApplicationSearchInitialForm = (academicCareer, dropdown) => {
  const [form, setForm] = useState(
    applicationSearchInitialForm(academicCareer, dropdown)
  );
  useEffect(() => {
    setForm(applicationSearchInitialForm(academicCareer, dropdown));
  }, [dropdown, academicCareer]);
  return [form, setForm];
};

export const selectStyles = {
  control: { borderRadius: "4px" },
};

export const inputStyles = { borderRadius: "4px" };

export const inputMap = {
  psid: {
    label: "PSID",
    errMsg: "PSID should comprise of 11 digits only",
    validLength: 11,
    regex: regex.numeric,
  },
  applicationId: {
    label: "Application ID",
    errMsg: "Application ID should comprise of 8 digits only",
    validLength: 8,
    regex: regex.numeric,
  },
  transactionId: { label: "Transaction ID", regex: regex.stockIssueTnxId },
};

export const validateApplicationSearchForm = (checkedOption, form) => {
  return (
    isFormFieldEmpty(form?.businessArea) ||
    isFormFieldEmpty(form?.[checkedOption]) ||
    (inputMap?.[checkedOption]?.validLength &&
      form?.[checkedOption]?.length !== inputMap[checkedOption].validLength)
  );
};
export const selectStyle = {
  control: { height: "45px", fontSize: "16px" },
};

export const getDropdowns = async () => {
  const p = await Promise.all([
    dropdownMapping(
      endpoint?.studentSearch?.getAllReturnReasonsOptions,
      "reason",
      "reason"
    ),
  ]);

  return {
    returnReasons: [...p[0], { label: "Others", value: "Others" }],
  };
};

export const getBarcodeDropdown = (barcodeArray, materialDetails) => {
  return barcodeArray
    .filter(
      (item) =>
        !item?.isReturned && item?.status.toUpperCase().trim() === "ACTIVE"
    )
    .map((item) => ({
      label: item?.barcode,
      value: item?.barcode,
      barcode: item?.barcode,
      materialId: materialDetails?.materialNumber,
      materialDescription: materialDetails?.materialDesc,
      uom: materialDetails?.uom,
      serialNumberProfile: materialDetails?.serialNumberProfile,
    }));
  // return barcodeArray.map(item => ({ label: item, value: item, barcode: item, materialId: materialDetails?.materialNumber, materialDescription: materialDetails?.materialDesc }))
};

export const getUpdatedDropdown = (dropdown) => ({
  academicGroups: dropdown?.academicGroup,
  businessAreas: dropdown?.dlpActiveBusinessArea,
  terms: dropdown?.term,
  courseIds: dropdown?.dlpCourseId,
});

export const getUpdatedDigitalDropdown = (dropdown) => ({
  academicGroups: dropdown?.academicGroup,
  businessAreas: dropdown?.businessArea,
  terms: dropdown?.term,
  courseIds: dropdown?.courseId,
});

export const returnModeDropdown = [
  { label: "Courier", value: "Courier" },
  { label: "Self Drop", value: "Self Drop" },
];

export const dlpReturnReasonDropdown = [
  { label: "Receiver refused delivery", value: "Receiver refused delivery" },
  { label: "Area Non-Serviceable", value: "Area Non-Serviceable" },
  {
    label: "Consignee not Available/Contactable",
    value: "Consignee not Available/Contactable",
  },
  { label: "Wrong Address", value: "Wrong Address" },
  { label: "Damaged Items/Books", value: "Damaged Items/Books" },
  // { label: "Duplicate Item/Books", value: "Duplicate Item/Books" },
  { label: "Incorrect Items/Book", value: "Incorrect Items/Book" },
  { label: "Others", value: "Others" },
];

export const generateQuantityDropdown = (maxValue) =>
  Array.from({ length: maxValue + 1 }, (_, i) => ({ label: i, value: i }));
export const getQuantityDropdownItem = (value) =>
  value === "" ? "" : { label: value, value: value };
