import React, { useState } from 'react'
import Header from "./header";
import EPMRTable from "./table";
import AddEmpr from './addEpmr';
import response from "./epmrDetails.json";

const EPMR = () => {
    const [headers, setHeaders] = useState({
        businessGroup: null,
        companyCode: null,
        region: null,
        businessArea: null,
        epmrStatus:"",
        epmrNumber : "",
        fromDate:"",
        toDate:""
    });

    const [newEpmr,setNewEpmr] = useState(false);

    return (
        newEpmr ? <AddEmpr /> : <>
            <Header headerValues={headers} setHeaderValues={setHeaders} setNewEpmr={setNewEpmr}/>
            <EPMRTable data={response}/>
        </>
       
    )
}

export default EPMR
