import React, { useState } from "react";
import AInput from "views/pages/manage/common/formFeilds/customInput/AInput";
const SearchFilter = ({
  disabled,
  appliedFilteredString,
  setAppliedFilteredString,
}) => {
  let searchParamTitleMap = {
    psid: "PSID",
    applicationId: "application ID",
    studentName: "Name",
  };

  return (
    <div className="mb-2">
      <div>
        <AInput
          placeholder="Search student by PSID, App ID and Name"
          style={{
            height: "36px",
          }}
          value={appliedFilteredString}
          handler={(value) => {
            setAppliedFilteredString(value);
          }}
          crossIcon={true}
          rightSearchIcon={true}
          crossHandler={() => {
            setAppliedFilteredString('');
          }}
          searchIconClickHandler={() => {
            // applyFilterHandler(searchedValue, searchInputParam);
          }}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default SearchFilter;
