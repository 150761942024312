import { eraseCookie, getCookie } from '../../../views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { validateToken } from '../validateTokenHelper';
import { SomethingWentWrong, controller } from "../helpers";
import { failureToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import { LOGOUTMESSAGE } from "../../constant"

const uploadpost = async (url, _success, _failure, obj, type, name) => {
  try {
    // const validate = await validateToken();
    // if (validate?.statusCode === 401) {
    //   _failure(validate);
    //   eraseCookie('token');
    //   window.location.replace(document.URL.split('#')[0]);
    // }
    fetch(url, {
      method: 'put',
      headers: {
        'Content-Type': type,
        'Content-Disposition': `attachment; filename="${name}"`
      },
      body: obj,
      redirect: 'follow'
    })
      .then(function (response) {
        // if ((response.status === 403 || response.status === 401) && getCookie('token')) {
        //   failureToast(LOGOUTMESSAGE);
        //   eraseCookie('token');
        //   controller.abort();
        //   setTimeout(()=>window.location.replace(`${document.URL.split('#')[0]}`), 3000);
        //   return null
        // }
        if (response.ok) {
          _success(response);
          return;
        } else {
          _failure(response);
          return;
        }
      })
      .catch(error => {
        //console.log(error);
        //_failure(error)
      });
  } catch (e) {
    SomethingWentWrong();
    //console.log('Error', e);
  }
};

export { uploadpost };
