import React, { Fragment, useState } from "react";
import { Button, Card, CardBody, CardHeader, Container, Input, Row, Table } from "reactstrap";
import { MasterHeaderAuto } from "../../common/commonComponents/masterHeader/masterHeader";
import {
  masterServiceBaseUrl,
  // failureToast,
  getAPI,
  putpost,
  putpost2,
  // successToast,
} from "../../../../../services/http";
import { fetchAllData } from "../../common/utils/methods/commonMethods/utilityMethod";
import moment from "moment";
// updated imports
import { TDROW } from "../../common/commonComponents/masterRow/masterRow";
import { fetchAllPostPromisedData, fetchDeletePromisedData, getCurrentDate } from "../../common/utils/methods/commonMethods/utilityMethod";
import { failureToast, successToast } from "../../common/utils/methods/toasterFunctions/function";
import { customValidation } from "../../common/utils/methods/validations/validation";
import validationRules from './validation.json'
import { endpoint } from "../../common/constant";
import { editTableRow, newTableRow } from "../../common/utils/methods/commonMethods/tableMethods";
import CustomButton from "components/CustomButton";
import { ButtonText } from "variables/Buttons";

// const NewOrEditRow = (props) => {
//   const { index, examGroupMasterData, Update, saveRow, cancelRow } = props;
//   return (
//     <tr key={"ExamGroupNewOrEditRow"}>
//       <td className="text-center p-2 mx-1">
//         <Input
//           maxLength={2}
//           disabled={false}
//           placeholder="Exam Group Key"
//           className="tdSelect"
//           value={examGroupMasterData["examGroupKey"]}
//           onChange={(e) => {
//             Update("examGroupKey", e.target.value);
//           }}
//         />
//       </td>
//       <td className="text-center p-2 mx-1">
//         <Input
//           disabled={true}
//           placeholder=""
//           className="tdSelect"
//           Value={moment(examGroupMasterData["updatedOn"]).format("DD-MM-YYYY")}
//         />
//       </td>
//       <td className="text-center p-2 mx-1">
//         <Input
//           disabled={false}
//           placeholder="Exam Group Description"
//           className="tdSelect"
//           Value={examGroupMasterData["examGroupDesc"]}
//           onChange={(e) => {
//             Update("examGroupDesc", e.target.value);
//           }}
//           maxLength={50}
//         />
//       </td>
//       <td className="text-center p-2 mx-1">
//         <Input
//           disabled={false}
//           placeholder="Exam Group Display Value"
//           className="tdSelect"
//           Value={examGroupMasterData["examGroupDispValue"]}
//           onChange={(e) => {
//             Update("examGroupDispValue", e.target.value);
//           }}
//           maxLength={50}
//         />
//       </td>
//       <td className="text-center p-2 mx-1">
//         <label className="custom-toggle mx-auto ml-2">
//           <input
//             key={"testInput4"}
//             checked={examGroupMasterData["status"] === "ACTIVE"}
//             type="checkbox"
//             onChange={(e) => {
//               let n = "ACTIVE";
//               if (!e.target.checked) {
//                 n = "INACTIVE";
//               }
//               Update("status", n);
//             }}
//           />
//           <span
//             style={{ width: "72px" }}
//             className="custom-toggle-slider rounded-circle activeToggle"
//             data-label-off="Inactive"
//             data-label-on="Active"
//           />
//         </label>
//       </td>
//       <td className="text-center">
//         <Button
//           color="info"
//           size="sm"
//           type="button"
//           className="mx-1"
//           onClick={() => saveRow(index)}
//         >
//           Save
//         </Button>
//         <Button
//           color="danger"
//           size="sm"
//           type="button"
//           onClick={() => cancelRow(index)}
//         >
//           <i className="fas fa-times" />
//         </Button>
//       </td>
//     </tr>
//   );
// };
// const ExamGroupTable = (props) => {
//   const {
//     examGroupData,
//     setExamGroupData,
//     setIsSaveVisible,
//     isSaveVisible,
//     setData,
//   } = props;
//   const headerList = [
//     { name: "EXAM GROUP KEY" },
//     { name: "EFFECTIVE DATE" },
//     { name: "DESCRIPTION" },
//     { name: "DISPLAY VALUE" },
//     { name: "STATUS" },
//     { name: "ACTION" },
//   ];
//   const [examGroupMasterData, setExamGroupMasterData] = useState({
//     examGroupKey: null,
//     examGroupDesc: null,
//     examGroupDispValue: null,
//     status: "ACTIVE",
//     effectiveDate: moment().format("DD-MM-YYYY"),
//     isEdit: false,
//     isNew: true,
//     groupName: "T3",
//   });

//   const Update = (key, value) => {
//     try {
//       setExamGroupMasterData((preState) => ({ ...preState, [key]: value }));
//     } catch (e) {
//       console.error("Error : ", e);
//     }
//   };
//   const refreshData = () =>
//     fetchAllData(masterServiceBaseUrl + "/examGroup/getAllExamGroup", setData);
//   const resetFields = () =>
//     setExamGroupMasterData({
//       examGroupKey: null,
//       examGroupDesc: null,
//       examGroupDispValue: null,
//       status: "ACTIVE",
//       effectiveDate: moment().format("DD-MM-YYYY"),
//       isEdit: false,
//       isNew: true,
//       groupName: "T4",
//     });

//   const cancelRow = (index) => {
//     try {
//       let tempArray = examGroupData;
//       if (tempArray[index] && tempArray[index]["isEdit"]) {
//         tempArray[index]["isEdit"] = false;
//         setIsSaveVisible(false);
//         resetFields();
//       } else if (tempArray[index] && tempArray[index]["isNew"]) {
//         tempArray.splice(index, 1);
//         setExamGroupData(tempArray);
//         setIsSaveVisible(false);
//         resetFields();
//       }
//     } catch (e) {
//       console.error("Error in cancelRow-->", e);
//     }
//   };
//   const newRow = () =>
//     setExamGroupData([examGroupMasterData, ...examGroupData]);
//   const saveRow = (index) => {
//     try {
//       let tempArray = examGroupData;
//       if (!examGroupMasterData["examGroupKey"]) {
//         failureToast("Please Enter Exam Group Key.");
//       } else if (!examGroupMasterData["examGroupDesc"]) {
//         failureToast("Please Enter Exam Group Description.");
//       } else if (!examGroupMasterData["examGroupDispValue"]) {
//         failureToast("Please Enter Exam Group Display Value.");
//       } else {
//         if (tempArray[index]["isEdit"]) {
//           PostData(
//             masterServiceBaseUrl +
//               "/examGroup/updateExamGroupMaster/" +
//               tempArray[index]["id"],
//             examGroupMasterData,
//             "put"
//           );
//         } else if (tempArray[index]["isNew"]) {
//           PostData(
//             masterServiceBaseUrl + "/examGroup/createExamGroupMaster",
//             examGroupMasterData,
//             "post"
//           );
//         }
//       }
//     } catch (e) {
//       console.error("Error in saveRow", e);
//     }
//   };
//   const PostData = (url, dataObject, type) => {
//     putpost(
//       url,
//       (data) => {
//         successToast(data.message);
//         setIsSaveVisible(false);
//         refreshData();
//         resetFields();
//       },
//       (data) => {
//         failureToast(data.message);
//       },
//       dataObject,
//       type
//     );
//   };
//   const editRow = (index) => {
//     try {
//       if (isSaveVisible) {
//         failureToast("Please save or cancel previous changes");
//       } else {
//         setIsSaveVisible(true);
//         examGroupData[index]["isEdit"] = true;
//         setExamGroupMasterData(examGroupData[index]);
//       }
//     } catch (e) {
//       console.error("Error in editRow -->", e);
//     }
//   };
//   const deleteRow = (index) => {
//     try {
//       if (isSaveVisible) {
//         failureToast("Please Delete or Save current changes");
//         return;
//       } else if (
//         examGroupData &&
//         examGroupData[index] &&
//         examGroupData[index]["id"]
//       ) {
//         if (window.confirm("Are you sure you want to delete this Item Type?")) {
//           putpost2(
//             masterServiceBaseUrl +
//               "/examGroup/deleteExamGroupMaster/" +
//               examGroupData[index]["id"],
//             (data) => {
//               successToast(data.message);
//               refreshData();
//             },
//             (data) => {
//               failureToast(data.message);
//             },
//             "Delete"
//           );
//         }
//       }
//     } catch (e) {
//       console.log("Error : ", e);
//     }
//   };
//   const TDROW = (props) => {
//     const { item, index } = props;
//     return (
//       <tr key={"ExamGroupNewOrEditRow"}>
//         <td className="text-center p-2 mx-1" data-testid="examGroupKey">
//           {item["examGroupKey"]}
//         </td>
//         <td className="text-center p-2 mx-1" data-testid="updatedOn">
//           {moment(item["updatedOn"]).format("DD-MM-YYYY")}
//         </td>
//         <td className="text-center p-2 mx-1" data-testid="examGroupDesc">
//           {item["examGroupDesc"]}
//         </td>
//         <td className="text-center p-2 mx-1" data-testid="examGroupDispValue">
//           {item["examGroupDispValue"]}
//         </td>
//         <td className="text-center p-2 mx-1" data-testid="status">
//           <Button
//             data-testid="statusbtn"
//             key={"statusButton"}
//             color={item.status === "ACTIVE" ? "success" : "danger"}
//             size={"sm"}
//             disabled={true}
//           >
//             {item.status === "ACTIVE" ? "Active" : "Inactive"}
//           </Button>
//         </td>
//         <td className="text-center p-2 mx-1" data-testid="">
//           <Button
//             data-testid="editRecord"
//             color="info"
//             size="sm"
//             type="button"
//             className={" mx-1"}
//             onClick={() => editRow(index)}
//           >
//             <i className="fas fa-edit" />
//           </Button>
//           <Button
//             data-testid="deleteRecord"
//             color="info"
//             size="sm"
//             type="button"
//             className={" mx-1"}
//             onClick={() => deleteRow(index)}
//           >
//             <i className="fas fa-trash" />
//           </Button>
//         </td>
//       </tr>
//     );
//   };

//   return (
//     <Fragment>
//       <CardBody style={{ paddingBottom: 0 }}>
//         <h3 className="mb-0 floatLeft">Exam Master - Exam Group</h3>
//         <Button
//           data-testid="addNewExamGroup"
//           disabled={isSaveVisible}
//           color="info"
//           size="sm"
//           type="button"
//           className={ "floatRight mx-1" + (isSaveVisible ? " btn-dark" : null) }
//           onClick={newRow}
//         >
//           Add New Exam Group
//         </Button>
//         <br />
//         <br />
//       </CardBody>
//       <div id="questionTable" className="table-responsive">
//         <Table className="align-items-center table-flush ">
//           <MasterHeaderAuto headerList={headerList} />
//           <tbody>
//             {examGroupData &&
//               examGroupData.map((item, index) =>
//                 item["isEdit"] || item["isNew"] ? (
//                   <NewOrEditRow
//                     cancelRow={cancelRow}
//                     saveRow={saveRow}
//                     Update={Update}
//                     item={item}
//                     index={index}
//                     examGroupMasterData={examGroupMasterData}
//                   />
//                 ) : (
//                   <TDROW item={item} index={index} />
//                 )
//               )}
//           </tbody>
//         </Table>
//       </div>
//     </Fragment>
//   );
// };

const ExamGroupTable = (props) => {
  const { permissionSet, isSaveVisible, setisSaveVisible = () => { }, getAllrecords = () => { } } = props;
  const [tableDataKey, settableDataKey] = useState("tableDataKey");

  const headerList = [
    {name: "EXAM GROUP KEY", isRequired: true},
    {name: 'EFFECTIVE DATE'},
    {name: 'Description', isRequired: true},
    {name: 'DISPLAY VALUE', isRequired: true},
    {name: 'Status'},
    {name: 'Action'}
  ]

  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: "__new",
        examGroupKey: null,
        status: "ACTIVE",
        examGroupDesc: null,
        examGroupDispValue: null,
        effectiveDate: null,
      },
      ...props.classArray,
    ]);
  };

  const saveAll = async (index) => {
    let _data = props.classArray[index];
    let isValid = true;
    isValid = customValidation(_data, validationRules)
    if(!isValid) return;

    if (_data["__status"] == "__new"){
      const createRecord = await fetchAllPostPromisedData(endpoint.examGroup.create, _data, 'post')
      if (createRecord?.code === 200) {
        successToast(createRecord["message"]);
        setisSaveVisible(false);
        getAllrecords()
      }
    } else {
      const updateRecord = await fetchAllPostPromisedData(`${endpoint.examGroup.update}/${_data.id}`, _data, 'put');
      if (updateRecord?.code === 200) {
        successToast(updateRecord["message"]);
        setisSaveVisible(false);
        getAllrecords()
      }
    }
  };

  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      newTableRow(props.classArray,props.setclassArray,setisSaveVisible,addNewClass);
    }
  };

  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      editTableRow(index,props.classArray,props.setclassArray,setisSaveVisible);
    }
  };

  const deleteRow = async (index) => {
    if (window.confirm("Are you sure you want to delete this Exam Group?")) {
      const deleteRecord = await fetchDeletePromisedData(`${endpoint.examGroup.delete}/${props.classArray[index].id}`);
      if(deleteRecord?.code === 200){
        successToast(deleteRecord['message']);
        let flag=false;
        if(props.classArray.length==1){
          flag=true;
        }
        getAllrecords(flag);
        settableDataKey(new Date().getMilliseconds());
      }
    }
  };

  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
  };

  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
        <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Exam Master - Exam Group</h3>
                <CustomButton 
                  data-testid="addNewExamGroup"
                  disabled={isSaveVisible}
                  className={ "floatRight mx-1" + (isSaveVisible ? " btn-dark" : null) }
                  content={ButtonText.ExamGroup.NEW}
                  icon={true}
                  permissionType={'C'}
                  permissionSet={permissionSet}
                  onClick={() => newRow()}
                />
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout">
                  <MasterHeaderAuto headerList={headerList} isSaveVisible={isSaveVisible} permissionSet={permissionSet} />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                          !el["editMode"] ? (
                            <TDROW
                              type={"examGroup"}
                              index={index}
                              el={el}
                              editRow={editRow}
                              deleteRow={deleteRow}
                              isSaveVisible={isSaveVisible}
                              permissionSet={permissionSet}
                            />
                          ) : (
                            <tr key={index + "-class"}>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength={2}
                                  defaultValue={el["examGroupKey"]}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      "examGroupKey"
                                    );
                                  }}
                                  placeholder="Exam Group Key"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  disabled={true}
                                  defaultValue={
                                    el["__status"] == "__new"
                                      ? getCurrentDate()
                                      : moment(el["updatedOn"]).format("DD-MM-YYYY")
                                  }
                                  placeholder="Example:01-01-2020"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength={50}
                                  defaultValue={el["examGroupDesc"]}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      "examGroupDesc"
                                    );
                                  }}
                                  placeholder="Exam Group Description"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength={50}
                                  defaultValue={ el["examGroupDispValue"] }
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      "examGroupDispValue"
                                    );
                                  }}
                                  placeholder="Exam Group Display Value"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <label className="custom-toggle mx-auto ml-2">
                                  <input
                                    checked={ el.status == "ACTIVE" ? true : false }
                                    type="checkbox"
                                    id={el.id}
                                    onChange={(e) => {
                                      settableDataKey(
                                        new Date().getMilliseconds()
                                      );
                                      let n = "ACTIVE";
                                      if (!e.target.checked) {
                                        n = "INACTIVE";
                                      }
                                      updateKey(index, n, "status");
                                    }}
                                  />
                                  <span
                                    style={{ width: "72px" }}
                                    className="custom-toggle-slider rounded-circle activeToggle"
                                    data-label-off="Inactive"
                                    data-label-on="Active"
                                  />
                                </label>
                              </td>
                              <td className="text-center">
                                {isSaveVisible ? (
                                  <>
                                    <CustomButton
                                      className={'mx-1'}
                                      content={ButtonText.ExamGroup.UPDATE}
                                      forTable={true}
                                      permissionType={'C,U'}
                                      permissionSet={permissionSet}
                                      onClick={() => { saveAll(index); }} 
                                    />
                                    <CustomButton 
                                      className={'mx-1'}
                                      permissionType={'cancel'}
                                      forTable={true}
                                      icon={true}
                                      permissionSet={permissionSet}
                                      onClick={() => {
                                        let n = props.classArray;
                                        if (n[index]["__status"] == "__new") {
                                          n.splice(index, 1);
                                        } else {
                                          // n[index]['editMode']=false
                                          getAllrecords();
                                        }
                                        setisSaveVisible(false);
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
export default ExamGroupTable;
