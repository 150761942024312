import React, { useState, useEffect, useContext } from 'react';
import SearchInput from './SearchInput';
import BankAccountList from './BankAccountList';
import { endpoint } from 'views/pages/manage/common/constant';
import { fetchAllPromisedData } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { Box, Typography, Link } from '@mui/material';
import { Col, Row } from 'reactstrap';
import { ReactComponent as ClickArrowIcon } from 'assets/img/svg/ClickArrow.svg';
import { ReactComponent as HeaderIcon } from 'assets/img/svg/DepositHeadingIcon.svg';
import { ReactComponent as QrCodeIcon } from 'assets/img/svg/QrCodeIcon.svg';
import { RolePermissions, PermissionContext } from 'appContext';
import { pages } from 'views/pages/manage/common/constant';

const BankAccount = () => {
  const houseBankPermissions = RolePermissions(
    useContext(PermissionContext),
    pages['houseBank']['id']
  );
  const depositSlipSetupPermissions = RolePermissions(
    useContext(PermissionContext),
    pages['depositSlip']['id']
  );
  const qrCodeManagementPermissions = RolePermissions(
    useContext(PermissionContext),
    pages['qrCodeManagement']['id']
  );
  const paymentGatewayConfigurationPermissions = RolePermissions(
    useContext(PermissionContext),
    pages['paymentGatewayConfiguration']['id']
  );
  const [buDispVal, setBuDispVal] = useState(null);
  const [businessAreaDesc, setBusinessAreaDesc] = useState('');
  const [businessAreaDropdown, setBusinessAreaDropdown] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const data = await fetchAllPromisedData(
      `${endpoint.businessArea.getAllActive}`,
      false
    );
    setBusinessAreaDropdown(
      data.map(obj => ({
        label: `${obj.businessAreaDispValue} | ${obj.businessAreaDesc}`,
        value: {
          businessAreaKey: obj.businessAreaKey,
          businessAreaDesc: obj.businessAreaDesc,
        },
      }))
    );
  };
  return (
    <>
      {buDispVal ? (
        <BankAccountList
          buDispVal={buDispVal}
          setBuDispVal={setBuDispVal}
          businessAreaDesc={businessAreaDesc}
        />
      ) : (
        <div
          className='d-flex flex-column remove-container-top-space'
          style={{ minHeight: 'calc(100vh - 79px)' }}
        >
          <div className='p-4' style={{ background: '#CCEFFD' }}>
            <div
              style={{
                fontFamily: 'sans-serif',
                color: '#004662',
                fontSize: '28px',
                fontWeight: '600',
              }}
              className='heading-4 mb-20 mt-2 label'
            >
              Search business area to view, edit or add new account details
            </div>
            <SearchInput
              setBuDispVal={setBuDispVal}
              setBusinessAreaDesc={setBusinessAreaDesc}
              businessAreaDropdown={businessAreaDropdown}
            />
            {/* <div style={{marginTop:'33px'}}>
                    <AButton
                    className="button_remove_left_margin mb-10"
                    updatedStyle={{ fontSize: "16px" }}
                    onClick={() => {}}
                    variant="primary_filled"
                    >
                    Go to Bank Accounts
                    </AButton>
                </div> */}
          </div>
          <Box className='hd-container'>
            <Row>
              {houseBankPermissions && (
                <Col md={4}>
                  <Box className='hd-card-wrapper'>
                    <HeaderIcon style={{ float: 'right', marginTop: '4px' }} />
                    <Typography variant='h6' className='card-title'>
                      House bank master
                    </Typography>
                    <Typography variant='body2'>
                      Return issued stock that has been issued
                    </Typography>

                    <Box className='card-link'>
                      <Link
                        href='/#/admin/bankAccount/houseBankMaster'
                        underline='none'
                      >
                        Click here
                        <ClickArrowIcon style={{ marginLeft: '10px' }} />
                      </Link>
                    </Box>
                  </Box>
                </Col>
              )}
              {depositSlipSetupPermissions && (
                <Col md={4}>
                  <Box className='hd-card-wrapper'>
                    <HeaderIcon style={{ float: 'right', marginTop: '4px' }} />
                    <Typography variant='h6' className='card-title'>
                      Deposit slip set-up
                    </Typography>
                    <Typography variant='body2'>
                      View transaction history of all issue
                    </Typography>
                    <Box style={{ marginTop: '20px' }}>
                      <Link
                        href='/#/admin/bankAccount/slipDetailForm'
                        underline='none'
                        style={{
                          fontWeight: '700',
                          fontSize: '18px',
                          color: '#00B0F5',
                        }}
                      >
                        Click here
                        <ClickArrowIcon style={{ marginLeft: '10px' }} />
                      </Link>
                    </Box>
                  </Box>
                </Col>
              )}
              {qrCodeManagementPermissions && (
                <Col md={4}>
                  <Box className='hd-card-wrapper'>
                    <QrCodeIcon style={{ float: 'right', marginTop: '4px' }} />
                    <Typography variant='h6' className='card-title'>
                      QR Code management
                    </Typography>
                    <Typography variant='body2'>Manage QR code</Typography>

                    <Box className='card-link'>
                      <Link
                        href='/#/admin/bankAccount/manageQrCode'
                        underline='none'
                      >
                        Click here
                        <ClickArrowIcon style={{ marginLeft: '10px' }} />
                      </Link>
                    </Box>
                  </Box>
                </Col>
              )}
            </Row>
            <Row className='mt-4'>
              {paymentGatewayConfigurationPermissions && (
                <Col md={4}>
                  <Box className='hd-card-wrapper'>
                    <HeaderIcon style={{ float: 'right', marginTop: '4px' }} />
                    <Typography variant='h6' className='card-title'>
                      Payment gateways configuration
                    </Typography>
                    <Typography variant='body2'>
                      Manage payment gateways
                    </Typography>
                    <Box style={{ marginTop: '20px' }}>
                      <Link
                        href='/#/admin/bankAccount/paymentGatewayConfiguration'
                        underline='none'
                        style={{
                          fontWeight: '700',
                          fontSize: '18px',
                          color: '#00B0F5',
                        }}
                      >
                        Click here
                        <ClickArrowIcon style={{ marginLeft: '10px' }} />
                      </Link>
                    </Box>
                  </Box>
                </Col>
              )}
            </Row>
          </Box>
        </div>
      )}
    </>
  );
};

export default BankAccount;
