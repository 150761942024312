import React from 'react'
import { TbLogout } from 'react-icons/tb'
import AButton from '../../common/form-fields-mui/AButton'


const containerStyles = {
  display: 'flex',
  width: '100%',
  paddingTop: '15px',
  justifyContent: 'space-between',
  alignItems: 'center'
}

const textStyles = {
  fontSize: '12px',
  marginBottom: '0'
}

const MultiSelectFooter = ({ selectionModel, setSelectionModel = () => { }, setIsMultiDeallocate, setOpenDeallocationDialog }) => {
  return (
    <div style={containerStyles}>
      <div className='d-flex' style={{ alignItems: 'center' }}>
        <div>
          <h4 className='mb-0'>{selectionModel.length} Students selected</h4>
          <p style={textStyles}>What do you want to do next?</p>
        </div>
        <AButton
          updatedStyle={{
            background: "#FFFFFF",
            border: "1px solid #CC5F5F",
            borderRadius: "12px",
            color: "#CC5F5F",
          }}
          onClick={() => {
            setIsMultiDeallocate(true);
            setOpenDeallocationDialog(true);
          }}
        >
          Deallocate all <TbLogout style={{ marginLeft: '5px' }} />
        </AButton>
        {/* <AButton
          updatedStyle={{
            background: "#FFFFFF",
            border: "1px solid #00B0F5",
            borderRadius: "12px",
            color: "#00B0F5",
          }}
          onClick={() => {
            // setIsMultiDeallocate(true)
            setDialogType('idCard');
            setOpenDialog(true);
          }}
        >
          Issue ID Cards
        </AButton> */}
      </div>
      <div>
        <AButton
          onClick={() => setSelectionModel([])}
        >
          Deselect all
        </AButton>
      </div>
    </div>
  )
}

export default MultiSelectFooter