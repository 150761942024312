import React from 'react'

const style = {
  display: 'flex',
  marginLeft: '3px',
  height: '20px',
  background: 'rgba(50, 147, 111, 0.16)',
  borderRadius: '8px',
  padding: "1px 5px",
  fontSize: '12px',
  fontWeight: 400,
  minWidth:'fit-content',
  marginRight:'8px'
}

const textColor = {
  success: "#519C66",
  failure: "#CC5F5F",
  warning: "#eba338"
}
const bgColor = {
  success: "rgba(50, 147, 111, 0.16)",
  failure: "rgba(245, 126, 119, 0.2)",
  warning: "rgba(245, 186, 98, 0.2)"
}

const CapacityChip = ({ capacity, currentStrength }) => {
  const percent = ((capacity - currentStrength) / capacity) * 100;
  const variant = percent > 50 ? "success" : percent > 5 ? "warning" : "failure"
  
  return (
    <div
      style={{
        ...style,
        color: textColor[variant],
        background: bgColor[variant],
      }}
    >
      {capacity - currentStrength} seats availabe
    </div>
  )
}

export default CapacityChip