import moment from "moment"
import { endpoint } from "views/pages/manage/common/constant";
import { fetchAllPostPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { fetchAllPromisedData } from "../../views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { 
  START_LOADER, 
  STOP_LOADER, 
  STUDENT_LIST_CLEAR, 
  STUDENT_LIST_FAILURE, 
  STUDENT_LIST_REQUEST, 
  STUDENT_LIST_SUCCESS, 
  UPDATE_STUDENT_LIST_DROPDOWNS,
  REQUEST_DROPDOWNS,
  UPDATE_DROPDOWNS
} from "./actionTypes";
import { updateRequest, getIdsArray } from 'views/pages/manage/edpOps/constant/common';
import { getBusinessAreaAndRegionDropdowns } from "views/pages/manage/edpOps/constant/common";
import { getAPI } from 'services/http';
import { getDropdowns } from "redux/studentCharges/actions";



export const startLoader = () => {
    return {
      type: START_LOADER
    }
  }
  export const stopLoader = () => {
    return {
      type: STOP_LOADER
    }
  }
  export const studentListSuccess = (studentList, rowCount, currentPage) => {
    return {
      studentList,
      rowCount,
      currentPage,
      type: STUDENT_LIST_SUCCESS,
    };
  };
  export const studentListFailure = () => {
    return {
      type: STUDENT_LIST_FAILURE
    };
  };
  export const getStudentList = (data = {}, offSet = 0, setPageNumber, setIsLastPage) => {
    return async (dispatch, getState) => {
      dispatch({ type: STUDENT_LIST_REQUEST })
  
      try {
        // handle if user try to submit empty business area because api send all data in that case
        let request = { ...data }
        if (data.businessArea && data.businessArea.length === 0) {
          request = { ...data, businessArea: getIdsArray(getState().studentSearch.dropdowns.businessAreas) }
        }
       
        const response = await fetchAllPostPromisedData(
          `${endpoint.getStudentChargesList}?offset=${offSet}`,
          { ...updateRequest(request) },
          "post"
        );
        if (response?.code === 200) {
          dispatch(
            studentListSuccess(
              // response?.data?.studentApplicationDetailsSearchResponseDTOList,
              response.data.studentApplicationDetailsSearchResponseDTOList,
              response.data.totalRecords,
              response.data.currentPage
            )
          );
          setPageNumber && setPageNumber(offSet);
          setIsLastPage && setIsLastPage(!response.data.studentApplicationDetailsSearchResponseDTOList?.length>0);
        } else {
          dispatch(studentListFailure())
        }
        // dispatch(
        //   studentListSuccess(
        //     // response?.data?.studentApplicationDetailsSearchResponseDTOList,
        //     studentApplicationDetailsSearchResponseDTOList,
        //     1,
        //     1
        //   )
        // );
      
      } catch (error) {
        dispatch(studentListFailure())
  
      }
        
    };
  };

  export const searchStudentClear = () => {
    return {
      type: STUDENT_LIST_CLEAR
    };
  };

  export const requestDropdowns = () => {
    return {
      type: REQUEST_DROPDOWNS
    }
  }
  
  export const updateDropdowns = (data) => {
    return {
      type: UPDATE_DROPDOWNS,
      dropdowns: data
    }
  }

  export const loadDropdowns = (cb) => {

    return async (dispatch) => {
      dispatch(requestDropdowns())
      const response = await getDropdowns();
      if (response) {
        dispatch(updateDropdowns(response));
        if (cb)
          cb(response)
      }
      else {
        dispatch(updateDropdowns({}))
      }
    };
  };