import React from 'react'
import { Menu, MenuItem, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';

const MenuItems = ({ anchorEl, handleClose, menuItems, batchId, batchData }) => {
  //  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const mainDropdowns = useSelector(state => state?.batch?.mainDropdowns)
  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menuItems.map(item => {
          // <MenuItem onClick={() => handleClose(`${item.url}${batchId}`)}>{item.name}</MenuItem>
          if (item?.name === 'Create/Edit Timetable') {
            return batchData?.batchType === 'SECONDARY' && batchData?.batchStatus === 'ACTIVE' && <MenuItem onClick={() => handleClose(`${item.url}${batchId}`)}>{item.name}</MenuItem>
          }
          else if (item?.name === 'View Timetable') {
            return batchData?.batchType === 'SECONDARY'  && <MenuItem onClick={() => handleClose(`${item.url}${batchId}`)}>{item.name}</MenuItem>
          }
           else {
            return <MenuItem key = {item.id} onClick={() => handleClose(`${item.url}${batchId}`)}>{item.name}</MenuItem>
          }
        })}
      </Menu>
    </div>
  )
}

export default MenuItems