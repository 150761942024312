import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import AButton from "../../../common/form-fields-mui/AButton";
import consessionObject from './slabFilter.json';
import { FormGenerator } from '../../../common/commonComponents/formGenerator';
import { constants, endpoint } from '../../../common/constant';
import { fetchAllPromisedData } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { fetchAllPostPromisedData } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { statusDropdown } from '../../../masters/studentFinancials/paymentGatewaysConfiguration/common';



export const modalStyle = {
    position: "absolute" /* Stay in place */,
    zIndex: "75" /* Sit on top */,
    top: "2rem",
    right: "5rem",
    width: "60%" /* Full width */,
    height: "100%" /* Full height */,
};


const buttonStyle = {
    display: "flex", justifyContent: "flex-end"
};

export const SlabFilter = (props) => {
    const { submitHandler = () => { },
        filterStatus,
        setFilterStatus = () => { },
        removeFilterHandler,
        isShowRemoveFilter,
        onClose = () => { },
        filters,
        setFilters = () => { } } = props;

        const[careerData,setCareerData] = useState({fetching:true,data:[]})
        const[acadData,setAcadData] = useState({fetching:false,data:[]})
        const[courseCategoryData,setcourseCategory] = useState({fetching:false,data:[]})
        const[termData,setTermData] = useState({fetching:false,data:[]})
        const[businessAreaData,setbusinessAreaData] = useState({fetching:false,data:[]})
        const[courseIdData,setcourseIdData] = useState({fetching:false,data:[]})


        const getCareerData = async () => {
            const response = await fetchAllPromisedData(`${endpoint.academic_career.getAllActive}`, true);
            if (response?.code == 200) {
                const res = response.data.map(item=> {return {label: item.academicCareerDispValue, value: item.id}})
                setCareerData({fetching:false,data: res})
    
            } else {
                setCareerData({fetching:false,data: []})
            }
        }

        const getAcadData = async () => {
            const response = await fetchAllPromisedData(`${endpoint.academic_group.getAllActive}`, true);
            if (response?.code == 200) {
                const res = response.data.map(item=> {return {label: item.academicGroupDispValue, value: item.id}})
                setAcadData({fetching:false,data: res})
    
            } else {
                setAcadData({fetching:false,data: []})
            }
        }

        const getCourseCategoryData = async () => {
            const response = await fetchAllPromisedData(`${endpoint?.courseCategory?.getAllActiveCourseCategory}`, true);
            if (response?.code == 200) {
                const res = response.data.map(item=> {return {label: item.courseAttributeValueDispValue, value: item.id}})
                setcourseCategory({fetching:false,data: res})
    
            } else {
                setcourseCategory({fetching:false,data: []})
            }
        }

        const getTermData = async () => {
            const response = await fetchAllPromisedData(`${endpoint.term.getAllActive}`, true);
            if (response?.code == 200) {
                const res = response.data.map(item=> {return {label: item.termDispValue, value: item.id}})
                setTermData({fetching:false,data: res})
    
            } else {
                setTermData({fetching:false,data: []})
            }
        }

        const getBusinessAreaData = async () => {
            const ids = filters.career.map(a => a.value)
            const response = await fetchAllPostPromisedData(`${endpoint.businessArea.getByAcademicCareerList}`, {id : ids}, 'post');
            if (response?.code == 200) {
                const res = response.data.map(item=> {return {label: item.businessAreaDispValue, value: item.id}})
                setbusinessAreaData({fetching:false,data: res})
    
            } else {
                setbusinessAreaData({fetching:false,data: []})
            }
        }

        const getCourseIdData = async () => {
            const ids = filters.courseCategory.map(a => a.value)
            const response = await fetchAllPostPromisedData(`${endpoint.course_details.courseIdByCourseAttribute}`, {id : ids}, 'post');
            if (response?.code == 200) {
                const res = response.data.map(item=> {return {label: item.courseId, value: item.courseId}})
                setcourseIdData({fetching:false,data: res})
    
            } else {
                setcourseIdData({fetching:false,data: []})
            }
        }

        useEffect(()=>{
            getCareerData();
            getAcadData();
            getCourseCategoryData();
            getTermData();
        },[])


        useEffect(()=>{
            if(filters.career?.length){
                getBusinessAreaData();
            }
        },[filters.career])

        useEffect(()=>{
            if(filters.courseCategory?.length){
                getCourseIdData();
            }
        },[filters.courseCategory])

    return (
        <div className='p-4'>
            <Row>
                <FormGenerator
                    fields={Object.keys(consessionObject)}
                    fieldsObject={consessionObject}
                    values={filters}
                    setValues={setFilters}
                    dataObjects={{ 
                        careerData:  careerData, 
                        acadData: acadData, 
                        categoryData: courseCategoryData, 
                        termData: termData, 
                        businessAreaData: businessAreaData, 
                        courseData: courseIdData, 
                        statusData: {fetching :false , data : statusDropdown}
                    }}
                // isPreviewEnable={previewOnly}
                // disabled={submitting || recordSaved || nextPage || (!editHeader && action !== 'new')}
                // isEditMode={action === 'edit'}
                />
            </Row>
            <Row>
                <Col md="6" style={buttonStyle}>

                </Col>
                <Col md="6" style={buttonStyle} >
                    {filterStatus.applied && (
                        <AButton
                            onClick={removeFilterHandler}
                            updatedStyle={{
                                border: "1px solid #CC5F5F",
                                borderRadius: "8px",
                                color: "#CC5F5F",
                                margin: ".3rem"

                            }}
                        >
                            Remove Filter
                        </AButton>
                    )}
                    <AButton
                        onClick={submitHandler}
                        updatedStyle={{
                            background: "#00B0F5",
                            border: "1px solid #00B0F5",
                            borderRadius: "8px",
                            color: "#fff",
                            margin: ".4rem"

                        }}
                    >
                        {filterStatus.applying  ? < i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : 'Apply Filter'}
                </AButton>
            </Col>
        </Row>
        </div>
    )
};


