import React from "react";
import { Table } from "reactstrap";
import { HouseBankHeader, HOUSEBANKTHROW } from "../../common/commonComponents/masterRow/masterRow";
import { GetPagination } from "../../common/pagination";
import { masterServiceBaseUrl, failureToast, deleteAPI, successToast } from "services/http";
import { useHistory } from "react-router-dom";
import { useState } from "react";
const HouseBank = (props) => {
  let history = useHistory();
  const {
    searchDataList,
    setSearchDataList,
    getDisplayValue,
    houseBankArray,
    setHouseBankArray,
    fetchSearchList,
    previousPage,
    lastPage,
    firstPage,
    nextPage,
    setPagination,
    pagination,
  } = props;
  const [change, setChange] = useState(false);

  const getIndex = (dataList, id) => {
    try {
      let index = dataList.findIndex((item) => item["id"] === id);
      return index >= 0 ? index : "";
    } catch (e) {
      console.log("Error", e);
    }
  };

  const deleteRecordFromState = (id) => {
    try {
      let indexForHouseArray = getIndex(houseBankArray, id);
      let indexForSearchDataArray = getIndex(searchDataList, id);
      let temp = houseBankArray;
      let __temp = searchDataList;
      temp.splice(indexForHouseArray, 1);
      __temp.splice(indexForSearchDataArray, 1);
      setHouseBankArray(temp);
      setSearchDataList(__temp);
      setChange(!change);
    } catch (e) {
      console.log("Error", e);
    }
  };

  const deleteRecord = (id) => {
    try {
      if (
        window.confirm(
          "Are you sure you want to delete this House Bank Record ?"
        )
      ) {
        deleteAPI(
          masterServiceBaseUrl + `/houseBank/deleteHouseBank/${id}`,
          (data) => {
            deleteRecordFromState(id);
            successToast(data["message"]);
          },
          (data) => {
            failureToast(data["message"]);
          },
          "Delete"
        );
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  return (
    <div id="questionTable" className="table-responsive">
      <Table className="align-items-center table-flush">
        <HouseBankHeader />
        <tbody className="list" key={"1"}>
          {houseBankArray &&
            houseBankArray.map((item, index) => (
              <HOUSEBANKTHROW
                key={index}
                index={index}
                getDisplayValue={getDisplayValue}
                item={item}
                history={history}
                deleteRecord={deleteRecord}
              />
            ))}
        </tbody>
      </Table>
      {pagination.totalPage > 1 ? (
        <GetPagination
          fetchClasses={fetchSearchList}
          pagination={pagination}
          previousPage={previousPage}
          firstPage={firstPage}
          lastPage={lastPage}
          nextPage={nextPage}
          setPagination={setPagination}
        />
      ) : null}
    </div>
  );
};

export default HouseBank;
