export const getUpdatedChargeStatus = (chargeStatusArr) => {

    let chargeStatus = [...chargeStatusArr]

    if (chargeStatus?.includes("MODY")) {
        chargeStatus = [...chargeStatus?.filter(status => status !== 'MODY'), "MCONC", "MVERY"];
    } else {
        if (chargeStatus?.includes("VERY")) {
            chargeStatus = [...chargeStatus, "MVERY"]
        }
        if (chargeStatus?.includes("CONC")) {
            chargeStatus = [...chargeStatus, "MCONC"]
        }
    }

    return chargeStatus;
}