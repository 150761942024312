import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import StepperBar from "../../issuanceIDcard/printIdCardAndMapRfid/StepperBar";
import { ReactComponent as BackIcon } from "assets/img/svg/BackButton.svg";
import { IssuanceIDCardContext, PAGE_STATE } from "../contextApi";
import IssueStockTable from "./issueStock/IssueStockTable";
import SelectMaterial from "./issueStock/SelectMaterial";
import ALoader from "views/pages/manage/common/a-loader";
import SapPeriodAndSelectSapPostingDate from "../sapPeriodComponent/SapPeriodAndSelectSapPostingDate";
import {
  fetchGroupMaterials,
  fetchMaterialBarcodes,
  groupMaterialByName,
  isAllGroupMaterialSelected,
  isMaterialGroupMappingExists,
  isDuplicationAllowed,
  studentsEligibleForReIssue,
  multipleIssueMaterialMap,
} from "../service";
import StockAlertBadgeMessage from "./issueStock/StockAlertBadgeMessage";
import { failureToast } from "../../../common/utils/methods/toasterFunctions/function";
import { useGetBusinessAreaType } from "../customhooks";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx";
import { isGrp1MaterialSelected, getGrp2Material } from "./constant";

const SelectMaterialAndStockIssue = ({ initialStep }) => {
  const [step, setStep] = useState(initialStep);

  useEffect(() => {
    setStep(initialStep);
  }, [initialStep]);

  useEffect(() => {
    step === 1 && setCurrentPageState(PAGE_STATE?.SELECT_MATERIAL);
    step === 2 && setCurrentPageState(PAGE_STATE?.ISSUE_STOCK);
    setIsSelectedMaterialFromGp1(false);
  }, [step]);

  const [barcodesLoader, setBarcodesLoader] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [isMaterialQtyChecked, setMaterialQtyChecked] = useState(false);
  const [isProcessedBtnDisabled, setIsProcessedBtnDisabled] = useState(false);
  const [isCheckQuantity, setIsCheckQuantity] = useState(true);
  const [isSelectedMaterialFromGp1, setIsSelectedMaterialFromGp1] =
    useState(false);
  const [materialGroups, setMaterialGroups] = useState([]);

  const {
    setCurrentPageState,
    getApplications,
    setApplications,
    filterForm,
    setFilterForm,
    setPage,
    setBuAndAcdGroup,
    setSelectedBusinessArea,
    setSelectedMaterials,
    selectedMaterials,
    materialAndBarcodeMapping,
    globalFilter,
    isApplicationLoading,
    selectedBusinessArea,
    setMaterialAndBarcodeMapping,
    setSelectedStudentsForIssueStock,
    setCanReIssueMaterialList,
    setGlobalFilter,
    BuAndAcdGroup,
    getBusinessUnitType,
    setTShirtGroupMaterials,
    tShirtGroupMaterials,
    setAlreadyIssuedTshirtMaterial,
  } = useContext(IssuanceIDCardContext);

  const buOwnedType = useGetBusinessAreaType(
    filterForm?.businessArea?.businessUnitTypeId ||
      getBusinessUnitType(BuAndAcdGroup)
  );

  const goBack = async () => {
    setGlobalFilter((prev) => ({
      ...prev,
      applicationId: prev?.typeOfCheckbox === "psid" ? "" : prev?.applicationId,
    }));
    setCurrentPageState(PAGE_STATE?.SEARCH_STUDENT);
    setBuAndAcdGroup("");
    setSelectedBusinessArea({});
    setSelectedMaterials([]);
  };

  const getBarcodes = async (plantId, materialIds) => {
    setBarcodesLoader(true);
    const businessAreaId =
      filterForm?.businessArea?.value || BuAndAcdGroup?.businessAreaId;
    const res = await fetchMaterialBarcodes(
      plantId,
      materialIds,
      businessAreaId
    );
    updateMaterialQty(res, tShirtGroupMaterials);
    setMaterialAndBarcodeMapping(res);
    setBarcodesLoader(false);
    setIsProcessedBtnDisabled(true);
    setIsCheckQuantity(false);
  };

  const updateMaterialQty = (res, tShirtGroupMaterials = []) => {
    const materialMap = res?.materialCount || {};
    const updatedMaterials = selectedMaterials.map((material) => {
      const materialCount = materialMap[material?.id] || 0;
      return {
        ...material,
        qty: materialCount,
      };
    });
    setSelectedMaterials(updatedMaterials);
    setMaterialQtyChecked(true);
  };

  const updateTShirtGroupFlag = (
    selectedMaterialsByUser,
    tShirtGroupMaterials
  ) => {
    return selectedMaterialsByUser.map((material) => {
      return {
        ...material,
        isTShirtGroupMaterial: tShirtGroupMaterials.includes(material?.id),
      };
    });
  };

  const afterSelectionStockNextHandler = (materialIds, grp2Materials) => {
    setPage(0); // to reset the pagination page
    getApplications(
      0,
      {
        ...filterForm,
        materialIds: materialIds,
        ...globalFilter,
      },
      (fetchList) => {
        setSelectedStudentsForIssueStock({});

        // how many application not application for issue then check for reissue exist or not
        studentsEligibleForReIssue(
          fetchList,
          { ...filterForm, materialIds, BuAndAcdGroup },
          setApplications
        );

        // store this map for disable enable flag
        // setSelectedMaterials(materialsWihQty);
        multipleIssueMaterialMap(
          fetchList,
          { ...filterForm, materialIds, BuAndAcdGroup },
          setCanReIssueMaterialList,
          setApiLoader,
          () => setCurrentPageState(PAGE_STATE?.ISSUE_STOCK),
          grp2Materials,
          setAlreadyIssuedTshirtMaterial
        );
      }
    );
    //
    // update tShirt flag into materials
    const materialsWihQty = updateTShirtGroupFlag(
      selectedMaterials,
      grp2Materials
    ).filter((item) => item?.qty > 0);
    // check selected material second time issue allowed
    if (buOwnedType)
      isDuplicationAllowed(
        { materialIds: materialsWihQty.map((item) => item?.materialId) },
        setSelectedMaterials,
        materialsWihQty
      );

    // getBarcodes(selectedBusinessArea?.sapPlantId, materialIds);
    // reset ids
  };

  const isMaterialExistInGroupOne = async () => {
    const materialIds = selectedMaterials.map((material) => material?.id);
    // if (!buOwnedType) return afterSelectionStockNextHandler(materialIds);
    if (isSelectedMaterialFromGp1) {
      const isAtLeastOneSelectFromEach = isAllGroupMaterialSelected(
        materialIds,
        materialGroups
      );
      if (isAtLeastOneSelectFromEach) {
        afterSelectionStockNextHandler(materialIds, tShirtGroupMaterials);
      } else {
        return failureToast(
          "Please select at least one material from each group!"
        );
      }
    }
    setBarcodesLoader(true);
    const response = await isMaterialGroupMappingExists({
      materialNumberList: materialIds,
    });
    const grp2Materials = getGrp2Material(response?.data?.groupMaterialMapping);
    setTShirtGroupMaterials(grp2Materials);

    if (
      response?.code === 200 &&
      isGrp1MaterialSelected(response?.data?.groupMaterialMapping)
    ) {
      setIsSelectedMaterialFromGp1(true);
      const res = await fetchGroupMaterials();
      if (res?.code === 200) {
        const groupsMaterial = groupMaterialByName(
          res?.data?.groupTypeMaterialMapList
        );
        setMaterialGroups(groupsMaterial);
        setBarcodesLoader(false);
      }
    } else {
      afterSelectionStockNextHandler(materialIds, grp2Materials);
      setBarcodesLoader(false);
    }
  };

  const checkQtyHandler = () => {
    const materialIds = selectedMaterials.map((material) => material?.id);
    getBarcodes(selectedBusinessArea?.sapPlantId, materialIds);
  };

  const showStockAlert = () => {
    return selectedMaterials
      ?.map((item) =>
        materialAndBarcodeMapping?.["materialCount"]?.[item?.materialId]
          ? materialAndBarcodeMapping?.["materialCount"]?.[item?.materialId] <
            materialAndBarcodeMapping?.threshold
          : true
      )
      ?.includes(true);
  };

  return (
    <>
      <div className={`remove-container-top-space ${styles?.top_bar}`}>
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <IconButtonWrapper onClick={() => goBack()}>
              <BackIcon />
            </IconButtonWrapper>
            <div className="ml-2 heading-4 color-black-60">Issue stock</div>
          </div>
          {step === 2 && (
            <SapPeriodAndSelectSapPostingDate
              sapPostingDate={filterForm?.sapPostingDate}
              setSapPostingDate={(date) =>
                setFilterForm((prev) => ({ ...prev, sapPostingDate: date }))
              }
            />
          )}
        </div>
      </div>
      <div className={`${styles?.main_container} color-grey-90`}>
        {(isApplicationLoading || barcodesLoader || apiLoader) && (
          <ALoader position="fixed" />
        )}

        <StepperBar
          step={step}
          setStep={setStep}
          metaData={{
            stepOneText: "Select material",
            stepTwoText: "Issue stock",
          }}
          confirmationCheck={() => true}
        />

        {step === 1 && (
          <div className={`${styles?.content_container}`}>
            <SelectMaterial
              nextHandler={isMaterialExistInGroupOne}
              checkQtyHandler={checkQtyHandler}
              isMaterialQtyChecked={isMaterialQtyChecked}
              setIsProcessedBtnDisabled={setIsProcessedBtnDisabled}
              isProcessedBtnDisabled={isProcessedBtnDisabled}
              isCheckQuantity={isCheckQuantity}
              setIsCheckQuantity={setIsCheckQuantity}
              materialGroups={materialGroups}
              isSelectedMaterialFromGp1={isSelectedMaterialFromGp1}
            />
          </div>
        )}
        {step === 2 && (
          <div>
            {showStockAlert() && <StockAlertBadgeMessage />}
            <div className={`${styles?.content_container}`}>
              <IssueStockTable />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SelectMaterialAndStockIssue;
