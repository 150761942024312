import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { openInNewTab } from "../../utils/methods/commonMethods/utilityMethod";
import {
  getDispValForSelectByOneID,
  getDispValForReactSelectByOneID,
} from "../../utils/methods/commonMethods/utilityMethod";
import Select2 from "react-select2-wrapper";
import { getRequiredSign } from "../../utils/methods/commonMethods/utilityMethod";
import Tooltip from "components/Tooltip";
import { Tooltip as MaterialTooltip } from "@mui/material";
import ViewButton from "../../../../../../components/FormComponent/Button/View";
import EditButton from "../../../../../../components/FormComponent/Button/Edit";
import CreateButton from "components/FormComponent/Button/New/index";
import DeleteButton from "components/FormComponent/Button/Delete/index";
import UpdateButton from "components/FormComponent/Button/Update/index";
import { ButtonText } from "variables/Buttons";
import CustomButton from "../../../../../../components/CustomButton";
import CustomToggle from "views/pages/manage/common/formFeilds/customToggle";
import { Edit } from "assets/img/icons/common/CommonIcons";
import EditIcon from "assets/img/svg/edit.svg";
import { Popover } from "@mui/material";
import DetailsModal from "views/pages/manage/masters/studentFinancials/paymentManagement/setUpBankAccount/DetailsModal";
import AButton from "../../form-fields-mui/AButton";
import { ReactComponent as ActiveControl } from "../../../../../../assets/img/svg/ActiveControl.svg";
import { ReactComponent as InactiveControl } from "../../../../../../assets/img/svg/InactiveControl.svg";

const GetListLabels = ({ ids, list }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? crypto.randomUUID() : undefined;
  if (ids?.[0] === 0) {
    return <>All</>;
  } else {
    const allLabels = [];
    {
      ids.map((id) => {
        list.filter((item) => {
          if (item.value === id) {
            allLabels.push(item.label);
          }
        });
      });
    }

    return (
      <>
        {allLabels?.[0] ?? "-"}
        {allLabels.length - 1 > 0 ? (
          <div style={{ cursor: "pointer" }} onClick={handleClick}>
            +{allLabels.length - 1}, more
          </div>
        ) : null}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div
            style={{
              displex: "flex",
              flexDirection: "column",
              padding: "5px 15px",
            }}
          >
            {allLabels.map((label) => {
              return <div style={{ padding: 5, fontSize: 12 }}>{label}</div>;
            })}
          </div>
        </Popover>
      </>
    );
  }
};

const TDROW = (props) => {
  let _history = useHistory();
  const {
    countryList = [],
    stateList = [],
    districtList = [],
    groupCodeList = [],
    cityList = [],
    companyCodeData = [],
    taxCodeType = [],
    history,
    permissions = {},
    processData = [],
    roleData = [],
  } = props;

  const getNameById = (id, list) => {
    const item = list.filter((item) => {
      return item && item.id === id;
    });
    const name = item.length ? item[0].text : "Not Found";
    return name;
  };

  const getNameByIdFromSelect = (id, list) => {
    const item = list.filter((item) => {
      return item && item.value === id;
    });
    const name = item.length ? item[0].label : "Not Found";
    return name;
  };
  return (
    <>
      <tr key={props.index + "-class"}>
        {props.type === "academicGroup" ? (
          <td className="text-center  white-breakSpace">
            {getNameById(
              props.el["companyCodeMasterId"],
              props.allCompanyCodeData
            )}
          </td>
        ) : null}
        {props.type === "academicCareer" ? (
          <td className="text-center  white-breakSpace">
            {getNameById(props.el["companyCodeMasterId"], props.companyCode)}
          </td>
        ) : null}
        {props.type === "state" ||
        props.type === "district" ||
        props.type === "pincode" ||
        props.type === "currency" ||
        props.type === "city" ? (
          <td className="text-center  white-breakSpace">
            {getNameById(props.el["countryId"], countryList)}
          </td>
        ) : null}
        {props.type === "paymentMode" ? (
          <>
            <td className="text-center white-breakSpace">
              {getNameByIdFromSelect(props.el["groupCodeId"], groupCodeList)}
            </td>
            <td className="text-center white-breakSpace">
              {getNameByIdFromSelect(
                props.el["companyCodeId"],
                companyCodeData
              )}
            </td>
          </>
        ) : null}
        {props.type === "district" ||
        props.type === "pincode" ||
        props.type === "city" ? (
          <td className="text-center  white-breakSpace">
            {getNameById(props.el["stateId"], stateList)}
          </td>
        ) : null}
        {props.type === "pincode" || props.type === "city" ? (
          <td className="text-center  white-breakSpace">
            {getNameById(props.el["districtId"], districtList)}
          </td>
        ) : null}
        {props.type === "pincode" ? (
          <td className="text-center  white-breakSpace">
            {getNameById(props.el["cityId"], cityList)}
          </td>
        ) : null}
        {props.type === "companyCode" ? (
          <td className="text-center  white-breakSpace">
            {getNameById(props.el["groupCodeId"], groupCodeList)}
          </td>
        ) : null}
        {props.itemType === "classification" ? (
          <td className="text-center white-breakSpace item-table-cell">
            {props.el.itemTypeClassificationKey}
          </td>
        ) : (
          props.type !== "academicYear" &&
          props.type !== "BankAccountID" && (
            <td className="text-center white-breakSpace" data-testid="type">
              {props.el[props.type + "Key"]}
            </td>
          )
        )}

        {props.type === "taxCodeId" ? (
          <>
            <td
              data-testid="groupCodeId"
              className="text-center white-breakSpace"
            >
              {getNameById(props.el["groupCodeId"], groupCodeList)}
            </td>
            <td
              data-testid="companyCodeId"
              className="text-center white-breakSpace"
            >
              {getNameById(props.el["companyCodeId"], companyCodeData)}
            </td>
            <td
              data-testid="taxCodeTypeId"
              className="text-center white-breakSpace"
            >
              {getNameById(props.el["taxCodeTypeId"], taxCodeType)}
            </td>
          </>
        ) : null}
        {props.type === "BankAccountID" && (
          <td className="text-center white-breakSpace">
            {props.pinelabsPaymentModeIds.find(
              (item) => item.id === Number(props.el.pinelabsPaymentModeId)
            )
              ? props.pinelabsPaymentModeIds.find(
                  (item) => item.id === Number(props.el.pinelabsPaymentModeId)
                ).text
              : ""}
          </td>
        )}
        {props.type === "BankAccountID" && (
          <td className="text-center white-breakSpace">
            {props.el.houseBankMaster.bankAccountId}
          </td>
        )}
        {props.type === "BankAccountID" && (
          <td className="text-center white-breakSpace">
            {props.el.houseBankMaster.bankAccountNumber}
          </td>
        )}
        {props.type === "BankAccountID" && (
          <td className="text-center white-breakSpace">
            {props.el.houseBankMaster.accountDr}
          </td>
        )}
        {props.type === "BankAccountID" && (
          <td className="text-center white-breakSpace">
            {props.el.houseBankMaster.accountCr}
          </td>
        )}
        {props.type === "BankAccountID" && (
          <td className="text-center white-breakSpace">
            {props.businessUnitType.find(
              (item) => item.id === Number(props.el.businessUnitTypeId)
            )
              ? props.businessUnitType.find(
                  (item) => item.id === Number(props.el.businessUnitTypeId)
                ).text
              : ""}
          </td>
        )}
        {props.type === "cashierOffice" && (
          <td className="text-center white-breakSpace">
            {props.el.description}
          </td>
        )}
        {props.type === "cashierOffice" && (
          <td className="text-center white-breakSpace">
            {props.currency.find((item) => item.id === props.el.currencyId)
              ? props.currency.find((item) => item.id === props.el.currencyId)
                  .text
              : ""}
          </td>
        )}

        {props.type === "taxCodeType" && (
          <td className="text-center white-breakSpace">
            <button
              disabled={true}
              type="button"
              className={`btn btn-sm ${
                props.el?.taxRegine === "Y" ? "btn-success" : "btn-warning"
              }`}
            >
              {props.el?.taxRegine}
            </button>
          </td>
        )}
        {/* {props.type === 'cashierOffice' && (
          <td className="text-center white-breakSpace">
            {props.businessArea.find(
              (item) => item.id === props.el.businessAreaId
            )
              ? props.businessArea.find(
                  (item) => item.id === props.el.businessAreaId
                ).text
              : ''}
          </td>
        )} */}
        {props.type === "cashierOffice" ? (
          <td className="text-center white-breakSpace">
            {moment(props.el["updatedOn"]).format("DD-MM-YYYY")}
          </td>
        ) : null}
        {props.type === "academicYear" && (
          <td
            className="text-center white-breakSpace"
            data-testid="academicYearKey"
          >
            {props.el["id"]}
          </td>
        )}
        {props.type !== "cashierOffice" && props.type !== "companyCode" ? (
          <td
            className="text-center white-breakSpace"
            data-testid="effectiveDate"
          >
            {moment(props.el["effectiveDate"]).format("DD-MM-YYYY")}
          </td>
        ) : null}
        {props.type === "academicYear" && (
          <td
            className="text-center white-breakSpace"
            data-testid="academicYearDescription"
          >
            {props.el["academicYearDescription"]}
          </td>
        )}
        {props.type === "academicYear" && (
          <td
            className="text-center white-breakSpace"
            data-testid="academicYearDisplayValue"
          >
            {props.el["academicYearDisplayValue"]}
          </td>
        )}
        {props.type === "academicYear" && (
          <td
            className="text-center white-breakSpace"
            data-testid="academicYearStartdate"
          >
            {moment(props.el["academicYearStartdate"]).format("DD-MM-YYYY")}
          </td>
        )}
        {props.type === "academicYear" && (
          <td
            className="text-center white-breakSpace"
            data-testid="academicYearEndDate"
          >
            {moment(props.el["academicYearEndDate"]).format("DD-MM-YYYY")}
          </td>
        )}
        {props.type !== "cashierOffice" && props.type === "companyCode" ? (
          <td className="text-center white-breakSpace">
            {moment(props.el["updatedOn"]).format("DD-MM-YYYY")}
          </td>
        ) : null}
        {props.type !== "cashierOffice" &&
        props.itemType === "classification" ? (
          <td className="text-center white-breakSpace item-table-cell">
            {props.el.itemTypeClassificationDesc}
          </td>
        ) : (
          props.type !== "BankAccountID" &&
          props.type !== "academicYear" &&
          props.type !== "cashierOffice" && (
            <td
              data-testid="description"
              className={
                props.type === "itemTypeClassification"
                  ? "text-center white-breakSpace item-table-cell"
                  : "text-center white-breakSpace"
              }
            >
              {props.el[props.type + "Desc"]}
            </td>
          )
        )}
        {props.type !== "cashierOffice" &&
        props.type !== "classification" &&
        props.itemType === "classification" ? (
          <td className="text-center white-breakSpace item-table-cell">
            {props.el.itemTypeClassificationDispValue}
          </td>
        ) : (
          props.type !== "BankAccountID" &&
          props.type !== "academicYear" &&
          props.type !== "cashierOffice" && (
            <td
              data-testid="displayValue"
              className={
                props.type === "itemTypeClassification"
                  ? "text-center white-breakSpace item-table-cell"
                  : "text-center white-breakSpace"
              }
            >
              {props.el[props.type + "DispValue"]}
            </td>
          )
        )}
        {props.type === "country" ? (
          <td className="text-center white-breakSpace">
            {props.el[props.type + "Code"]}
          </td>
        ) : null}
        {props.type === "state" ? (
          <td className="text-center white-breakSpace">
            {props.el[props.type + "GstCode"]}
          </td>
        ) : null}
        {props.type === "district" ? (
          <td className="text-center white-breakSpace">
            {props.el[props.type + "GstCode"]}
          </td>
        ) : null}
        {props.type === "pincode" ? (
          <td className="text-center white-breakSpace">
            {props.el[props.type + "GstCode"]}
          </td>
        ) : null}
        {props.type === "currency" ? (
          <td className="text-center white-breakSpace">
            {props.el["currencySymbol"]}
          </td>
        ) : null}
        {props.type === "addressProperty" ? (
          <td className="text-center white-breakSpace">
            {props.el["addressPropertyType"]}
          </td>
        ) : null}
        {props.type === "city" ? (
          <td className="text-center white-breakSpace">
            {props.el["cityCode"]}
          </td>
        ) : null}
        {props.type === "academicCareer" ? (
          <td className="text-center white-breakSpace">
            {props.el["sapAcademicCareerSequence"]}
          </td>
        ) : null}
        {props.type === "academicCareer" ? (
          <td className="text-center white-breakSpace">
            {props.el["sapAcademicCareer"]}
          </td>
        ) : null}
        {props.type === "academicCareer" ? (
          <td className="text-center white-breakSpace">
            {props.el["sapAcademicTypeSequence"]}
          </td>
        ) : null}
        {props.type === "academicCareer" ? (
          <td className="text-center white-breakSpace">
            {props.el["sapAcademicType"]}
          </td>
        ) : null}
        {props.type === "academicCareer" ? (
          <td className="text-center white-breakSpace">
            {props.el["academicCareerCategoryCode"]}
          </td>
        ) : null}

        {props.type === "academicGroup" ? (
          <td className="text-center white-breakSpace">
            {props.el["sapSegmentTypeSequence"]}
          </td>
        ) : null}

        {props.type === "academicGroup" ? (
          <td className="text-center white-breakSpace">
            {props.el["academicGroupCategoryCode"]}
          </td>
        ) : null}
        {props.type === "academicGroup" ? (
          <td className="text-center white-breakSpace">
            {props.el["sapSegmentType"]}
          </td>
        ) : null}
        {props.type === "businessUnitType" ? (
          <td className="text-center white-breakSpace">
            {props.el["sapBranchTypeSequence"]}
          </td>
        ) : null}
        {props.type === "businessUnitType" ? (
          <td className="text-center white-breakSpace">
            {props.el["sapBranchType"]}
          </td>
        ) : null}
        {props.type === "taxCodeId" ? (
          <>
            <td className="text-center ">
              <Button
                onClick={() =>
                  history.push(`/admin/taxCodeId/taxbreakup/${props.el["id"]}`)
                }
                color={props.el["totalTaxValue"] ? "info" : "primary"}
                style={{
                  height: "30px",
                  width: "30px",
                  padding: "initial",
                  paddingBottom: "3px",
                }}
              >
                <img src={EditIcon} alt="edit" />
              </Button>

              {/* <EditButton
                onClick={() => history.push(`/admin/taxCodeId/taxbreakup/${props.el['id']}`)}
                color={props.el['totalTaxValue'] ? 'info' : 'primary'}
              /> */}
            </td>
            {/* <td className="text-center ">
              <Button
                onClick={() => history.push(`/admin/taxCodeId/itemType/${props.el['id']}`)}
                color={props.el['totalItemTypeValue'] ? 'info' : 'primary'}
                size="sm"
                type="button"
                style={{
                  "display": "flex",
                  "alignItems": "center", "height": "30px"
                }}
              >
                 <i className="fas fa-edit" />
                {Edit}
                Add Item Types
              </Button>
            </td> */}
          </>
        ) : null}

        {props.type === "paymentMode" ? (
          <td className="text-center">{props.el["sapPaymode"]}</td>
        ) : null}
        {props.type === "paymentMode" ? (
          <td className="text-center">{props.el["paymodeSequence"]}</td>
        ) : null}
        {props.type === "paymentMode" ? (
          <td className="text-center">
            <GetListLabels
              ids={props.el["processTagTypeMasterIds"]}
              list={processData}
            />
          </td>
        ) : null}
        {props.type === "paymentMode" ? (
          <td className="text-center">
            <GetListLabels ids={props.el["roleIds"]} list={roleData} />
          </td>
        ) : null}
        {props.type === "paymentMode" ? (
          <td className="text-center">{props.el["paymodeType"]}</td>
        ) : null}
        {props.type === "paymentMode" && (
          <td className="text-center ">
            <Button
              onClick={() => {
                _history.push(
                  `/admin/paymentMode/addReceiptAccEntry/${props.el["id"]}`
                );
              }}
              color={props.el["totalReceiptCount"] ? "primary" : "info"}
              size="sm"
              type="button"
            >
              <i className="fas fa-edit" />
              Add Receipt A/C Entry
            </Button>
          </td>
        )}
        {props.type === "paymentMode" && (
          <td className="text-center ">
            <Button
              onClick={() => {
                _history.push(
                  `/admin/paymentMode/addRefundAccEntry/${props.el["id"]}`
                );
              }}
              color={props.el["totalRefundCount"] ? "primary" : "info"}
              size="sm"
              type="button"
            >
              <i className="fas fa-edit" />
              Add Refund A/C Entry
            </Button>
          </td>
        )}
        {props.type === "itemTypeElement" ? (
          <td className="text-center">
            {!props.el.itemTypeDefaultConcession ? (
              <button
                disabled={true}
                type="button"
                className="btn btn-sm btn-warning"
              >
                NO
              </button>
            ) : (
              <button
                type="button"
                disabled={true}
                className="btn btn-sm btn-success"
              >
                YES
              </button>
            )}
          </td>
        ) : null}
        {props.type === "itemTypeClassification" && (
          <td className="text-center item-table-cell">
            {props.el.itemTypeModeType == "PAYMENT" ? (
              <button
                disabled={true}
                type="button"
                className="btn btn-sm btn-warning"
              >
                Payment
              </button>
            ) : (
              <button
                type="button"
                disabled={true}
                className="btn btn-sm btn-success"
              >
                Charges
              </button>
            )}
          </td>
        )}
        <td
          data-testid="statusDetails"
          className={
            props.type === "itemTypeClassification"
              ? "text-center white-breakSpace item-table-cell"
              : "text-center white-breakSpace"
          }
        >
          {props.el.status == "INACTIVE" ? (
            <button
              data-testid="statusInActive"
              disabled={true}
              type="button"
              className="btn btn-sm btn-warning"
            >
              Inactive
            </button>
          ) : (
            <button
              data-testid="statusActive"
              type="button"
              disabled={true}
              className="btn btn-sm btn-success"
            >
              Active
            </button>
          )}
        </td>
        {props.type !== "cashierOffice" &&
        props.type !== "BankAccountID" &&
        props.permissionSet &&
        (props.permissionSet.includes("U") ||
          props.permissionSet.includes("D")) ? (
          <td
            style={{ paddingLeft: "5px", paddingRight: "5px" }}
            className={
              props.type === "itemTypeClassification"
                ? "text-center white-breakSpace item-table-cell"
                : "text-center"
            }
            colSpan={
              props.type === "companyCode" || props.type === "pincode" ? 2 : 1
            }
          >
            <CustomButton
              content={""}
              permissionType={"U"}
              icon={true}
              forTable={true}
              onClick={() => {
                props.editRow(props.index);
              }}
              permissionSet={props.permissionSet}
            />
            {!(props.type === "testMode") &&
            // !(props.type === 'academicYear') &&
            !(props.type === "documentSource") &&
            !(props.type === "meetingType") ? (
              <CustomButton
                content={""}
                permissionType={"D"}
                icon={true}
                forTable={true}
                onClick={() => {
                  props.deleteRow(props.index);
                }}
                permissionSet={props.permissionSet}
              />
            ) : null}
            {props.type === "companyCode" ? (
              <Button
                color="info"
                size="sm"
                type="button"
                onClick={() => {
                  history.push(`/admin/companyCode/${props.el["id"]}`);
                }}
              >
                {" "}
                View Address
              </Button>
            ) : null}
          </td>
        ) : props.permissionSet &&
          (props.permissionSet.includes("U") ||
            props.permissionSet.includes("D")) ? (
          <td
            className={"text-center white-breakSpace item-table-cell"}
            style={{ paddingLeft: "5px", paddingRight: "5px" }}
          >
            <CustomButton
              content={""}
              permissionType={"U"}
              icon={true}
              forTable={true}
              onClick={() => {
                props.editRow(props.index);
              }}
              permissionSet={props?.permissionSet}
            />
            <CustomButton
              content={""}
              permissionType={"D"}
              icon={true}
              forTable={true}
              onClick={() => {
                props.deleteRow(props.index);
              }}
              permissionSet={props?.permissionSet}
            />
          </td>
        ) : props.isSaveVisible ? (
          <td colspan={props.colspan ? props.colspan : 1}></td>
        ) : null}
      </tr>
    </>
  );
};
const MasterHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          {/* <th className="text-center white-breakSpace">Id</th> */}
          {props.type === "State" ||
          props.type === "District" ||
          props.type === "Pincode" ||
          props.type === "Currency" ||
          props.type === "City" ? (
            <th className="text-center white-breakSpace">Country Name</th>
          ) : null}
          {props.type === "District" ||
          props.type === "Pincode" ||
          props.type === "City" ? (
            <th className="text-center white-breakSpace">State Name</th>
          ) : null}
          {props.type === "Academic Group" ? (
            <th className="text-center white-breakSpace">COMPANY CODE</th>
          ) : null}
          {props.type === "Pincode" || props.type === "City" ? (
            <th className="text-center white-breakSpace">District Name</th>
          ) : null}
          {props.type === "Pincode" ? (
            <th className="text-center white-breakSpace">City Name</th>
          ) : null}
          {props.type === "CompanyCode" ? (
            <th className="text-center white-breakSpace">Group Code</th>
          ) : null}
          {props.type === "Payment Mode" ? (
            <th className="text-center white-breakSpace">GROUP CODE</th>
          ) : null}
          {props.type === "Payment Mode" ? (
            <th className="text-center white-breakSpace">COMPANY CODE</th>
          ) : null}
          {props.type === "Item Type Element" ? (
            <th className="text-center white-breakSpace">Element No.</th>
          ) : (
            <th className="text-center  item-table-cell" data-testid="key">
              {props.type} key {props.isRequired && getRequiredSign()}
              {props.keyTooltip ? (
                <>
                  {getRequiredSign()}
                  <Tooltip content={props.keyTooltip} placement={"top"}>
                    <span
                      style={{ marginLeft: "4px", color: "#8898aa" }}
                      id={"key"}
                      className={"key"}
                    >
                      <svg
                        version="1.1"
                        viewBox="0 0 512 512"
                        width="13px"
                        height="13px"
                      >
                        <path
                          fill="currentColor"
                          d={`M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z`}
                        />
                      </svg>
                    </span>
                  </Tooltip>
                </>
              ) : null}
            </th>
          )}
          {props.type === "Tax Code Type" && (
            <th className="text-center">Tax Regime</th>
          )}
          {props.type !== "CompanyCode" ? (
            <th className="text-center " data-testid="effectiveDateHeader">
              Effective Date{" "}
            </th>
          ) : null}
          {props.type === "CompanyCode" ? (
            <th className="text-center white-breakSpace">Updated Date </th>
          ) : null}
          <th className="text-center" data-testid="descriptionHeader">
            Description {getRequiredSign()}
            {props.descriptionTooltip ? (
              <Tooltip content={props.descriptionTooltip} placement={"top"}>
                <span
                  style={{ marginLeft: "4px", color: "#8898aa" }}
                  id={"description"}
                  className={"description"}
                >
                  <svg
                    version="1.1"
                    viewBox="0 0 512 512"
                    width="13px"
                    height="13px"
                  >
                    <path
                      fill="currentColor"
                      d={`M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z`}
                    />
                  </svg>
                </span>
              </Tooltip>
            ) : null}
          </th>
          <th className="text-center" data-testid="displayValueHeader">
            Display value {getRequiredSign()}
            {props.displayValueTooltip ? (
              <Tooltip content={props.displayValueTooltip} placement={"top"}>
                <span
                  style={{ marginLeft: "4px", color: "#8898aa" }}
                  id={"dispValue"}
                  className={"dispValue"}
                >
                  <svg
                    version="1.1"
                    viewBox="0 0 512 512"
                    width="13px"
                    height="13px"
                  >
                    <path
                      fill="currentColor"
                      d={`M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z`}
                    />
                  </svg>
                </span>
              </Tooltip>
            ) : null}
          </th>
          {props.type === "Academic Year" ? (
            <th className="text-center white-breakSpace required-field">
              Start Date
            </th>
          ) : null}
          {props.type === "Academic Year" ? (
            <th className="text-center white-breakSpace required-field">
              End Date
            </th>
          ) : null}
          {props.type === "Country" ? (
            <th className="text-center white-breakSpace">Country Code</th>
          ) : null}
          {props.type === "State" ? (
            <th className="text-center white-breakSpace">State Gst Code</th>
          ) : null}
          {props.type === "District" ? (
            <th className="text-center white-breakSpace">District Code</th>
          ) : null}
          {props.type === "Pincode" ? (
            <th className="text-center white-breakSpace">Pincode</th>
          ) : null}
          {props.type === "Currency" ? (
            <th className="text-center white-breakSpace">Currency Symbol</th>
          ) : null}
          {props.type === "Address Property" ? (
            <th className="text-center white-breakSpace">Type</th>
          ) : null}
          {props.itemType === "classification" && (
            <th className="text-center">TYPE(Charges/Payment)</th>
          )}
          {props.type === "Item Type Element" ? (
            <th className="text-center white-breakSpace">Default Concession</th>
          ) : null}
          {props.type === "City" ? (
            <th className="text-center white-breakSpace">City Code</th>
          ) : null}
          {props.type === "Academic Career" ? (
            <th className="text-center white-breakSpace">
              SAP ACADEMIC CAREER SEQUENCE
            </th>
          ) : null}
          {props.type === "Academic Career" ? (
            <th className="text-center white-breakSpace">
              SAP ACADEMIC CAREER
            </th>
          ) : null}
          {props.type === "Academic Career" ? (
            <th className="text-center white-breakSpace">
              SAP CAREER TYPE SEQUENCE
            </th>
          ) : null}
          {props.type === "Academic Career" ? (
            <th className="text-center white-breakSpace">SAP CAREER TYPE</th>
          ) : null}
          {props.type === "Academic Career" ? (
            <th className="text-center white-breakSpace">CATEGORY CODE</th>
          ) : null}
          {props.type === "Academic Group" ? (
            <th className="text-center white-breakSpace">
              SAP SEGMENT TYPE SEQUENCE
            </th>
          ) : null}
          {props.type === "Academic Group" ? (
            <th className="text-center white-breakSpace">CATEGORY CODE</th>
          ) : null}
          {props.type === "Academic Group" ? (
            <th className="text-center white-breakSpace">SAP SEGMENT TYPE</th>
          ) : null}
          {props.type === "Business Unit Type" ? (
            <th className="text-center white-breakSpace">
              SAP BRANCH TYPE SEQUENCE
            </th>
          ) : null}
          {props.type === "Business Unit Type" ? (
            <th className="text-center white-breakSpace">SAP BRANCH TYPE</th>
          ) : null}
          {props.type === "Payment Mode" ? (
            <th className="text-center ">SAP PAYMODE</th>
          ) : null}
          {props.type === "Payment Mode" ? (
            <th className="text-center "> PAYMODE SEQUENCE</th>
          ) : null}
          {props.type === "Payment Mode" ? (
            <th className="text-center ">RECEIPTS A/C ENTRY</th>
          ) : null}
          {props.type === "Payment Mode" ? (
            <th className="text-center">REFUND A/C ENTRY</th>
          ) : null}
          <th
            className="text-center white-breakSpace"
            data-testid="statusHeader"
          >
            Status
          </th>
          {(props.type === "CompanyCode" ||
            props.type === "Pincode" ||
            props.type === "businessType") &&
          ((props.permissionSet &&
            (props.permissionSet.includes("D") ||
              props.permissionSet.includes("U"))) ||
            (props.permissionSet.includes("C") && props.isSaveVisible)) ? (
            <th className="text-center white-breakSpace" colSpan={2}>
              Action
            </th>
          ) : props.isSaveVisible ||
            (props.permissionSet &&
              (props.permissionSet.includes("D") ||
                props.permissionSet.includes("U"))) ? (
            <th className="text-center white-breakSpace" data-testid="action">
              Action
            </th>
          ) : null}
        </tr>
      </thead>
    </>
  );
};

const MasterHeaderCashier = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center white-breakSpace">
            {props.type + " Key"}
          </th>
          <th className="text-center white-breakSpace">Description</th>
          <th className="text-center white-breakSpace">Currency</th>
          {/* <th className="text-center white-breakSpace">Business Area</th> */}
          <th className="text-center white-breakSpace">Updated Date </th>
          <th className="text-center white-breakSpace">Status</th>
          {!props.isDisabled ? (
            <th className="text-center white-breakSpace">Action</th>
          ) : null}
        </tr>
      </thead>
    </>
  );
};

const MasterHeaderPineLabPaymode = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center white-breakSpace">
            {props.type + " Key"}
          </th>
          <th className="text-center white-breakSpace">EFFECTIVE DATE</th>
          <th className="text-center white-breakSpace">DESCRIPTION</th>
          <th className="text-center white-breakSpace">DISPLAY VALUE</th>
          <th className="text-center white-breakSpace">STATUS</th>
          <th className="text-center white-breakSpace">ACTION</th>
        </tr>
      </thead>
    </>
  );
};

const MasterHeaderBankAccount = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center white-breakSpace">
            Pine Lab Payment Mode
          </th>
          <th className="text-center white-breakSpace">
            {props.type + " Key"}
          </th>
          <th className="text-center white-breakSpace">BANK ACCOUNT NUMBER</th>
          <th className="text-center white-breakSpace">DEBIT ACCOUNT</th>
          <th className="text-center white-breakSpace">CREDIT ACCOUNT</th>
          <th className="text-center white-breakSpace">BUSINESS AREA TYPE</th>
          <th className="text-center white-breakSpace">UPDATED DATE</th>
          <th className="text-center white-breakSpace">STATUS</th>
          {!props.isDisabled ? (
            <th className="text-center white-breakSpace">ACTION</th>
          ) : null}
        </tr>
      </thead>
    </>
  );
};

const FollowUpHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center white-breakSpace">S. No.</th>
          <th className="text-center white-breakSpace">Description</th>
          <th className="text-center white-breakSpace">Display Value</th>
          <th className="text-center white-breakSpace">Effective Date</th>
          <th className="text-center white-breakSpace">Status</th>
          {!props.isDisabled ? (
            <th className="text-center white-breakSpace">ACTION</th>
          ) : null}
        </tr>
      </thead>
    </>
  );
};

const FollowUpData = (props) => {
  return (
    <>
      <tr key={props.index + "-class"}>
        <td className="text-center" style={{ whiteSpace: "initial" }}>
          {props.el.followUpSerialNo}
        </td>
        <td className="text-center" style={{ whiteSpace: "initial" }}>
          {props.el.followUpStatusDesc}
        </td>
        <td className="text-center" style={{ whiteSpace: "initial" }}>
          {props.el.followUpStatusDispValue}
        </td>
        <td className="text-center" style={{ whiteSpace: "initial" }}>
          {moment(props.el["effectiveDate"]).format("DD-MM-YYYY")}
        </td>
        <td className="text-center">
          {props.el.status == "INACTIVE" ? (
            <button
              disabled={true}
              type="button"
              className="btn btn-sm btn-warning"
            >
              Inactive
            </button>
          ) : (
            <button
              type="button"
              disabled={true}
              className="btn btn-sm btn-success"
            >
              Active
            </button>
          )}
        </td>
        <td className="text-center">
          <Button
            color="info"
            size="sm"
            type="button"
            onClick={() => {
              props.editRow(props.index);
            }}
          >
            {" "}
            <i className="fas fa-edit" />
          </Button>
        </td>
      </tr>
    </>
  );
};

const AdjustmentFlagData = (props) => {
  return (
    <>
      <tr key={props.index + "-class"}>
        <td className="text-center" style={{ whiteSpace: "initial" }}>
          {props.el.key}
        </td>
        <td className="text-center" style={{ whiteSpace: "initial" }}>
          {moment(props.el["effectiveDate"]).format("DD-MM-YYYY")}
        </td>
        <td className="text-center" style={{ whiteSpace: "initial" }}>
          {props.el.description}
        </td>
        <td className="text-center" style={{ whiteSpace: "initial" }}>
          {props.el.displayValue}
        </td>
        <td className="text-center">
          {props.el.status == "INACTIVE" ? (
            <button
              disabled={true}
              type="button"
              className="btn btn-sm btn-warning"
            >
              Inactive
            </button>
          ) : (
            <button
              type="button"
              disabled={true}
              className="btn btn-sm btn-success"
            >
              Active
            </button>
          )}
        </td>
        <td className="text-center">
          {/* <Button
            color="info"
            size="sm"
            type="button"
            onClick={() => {
              props.editRow(props.index);
            }}
          >
            {' '}
            <i className="fas fa-edit" />
          </Button> */}
          <CustomButton
            content={""}
            icon={true}
            forTable={true}
            onClick={() => {
              props.editRow(props.index);
            }}
            permissionType={"U"}
            permissionSet={props.permissionSet}
            data-testid="editBtn"
          />
        </td>
      </tr>
    </>
  );
};

const BankAccountHeaders = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center white-breakSpace">Business Area</th>
          <th className="text-center white-breakSpace">Payment method</th>
          <th className="text-center white-breakSpace">Status</th>
          <th className="text-center white-breakSpace">Accounts</th>
          <th style={{ width: "25%" }} className="text-center white-breakSpace">
            Last updated on
          </th>
          <th className="text-center white-breakSpace">Actions</th>
        </tr>
      </thead>
    </>
  );
};

const BankAccountDataRow = (props) => {
  return (
    <>
      {/* <tr key={props.index + '-class'}> */}
      <td className="text-center" style={{ whiteSpace: "initial" }}>
        {props.el.businessAreaDispVal}
      </td>
      <td className="text-center" style={{ whiteSpace: "initial" }}>
        {props.el.paymentModeDispVal}
      </td>
      <td className="text-center">
        {/* <label className="custom-toggle mx-auto ml-2">
                                <input
                                  checked={props.el.status == "ACTIVE" ? true : false}
                                  type="checkbox"
                                  id={props.el.id}
                                  onChange={(e) => {
                                  }}
                                  data-testid="bankAccountStatus"
                                />
                                <span
                                  style={{ width: "72px" }}
                                  className="custom-toggle-slider rounded-circle activeToggle"
                                  data-label-off="Inactive"
                                  data-label-on="Active"
                                />
                              </label> */}
        <span>
          {props.el.status === "ACTIVE" ? (
            <ActiveControl />
          ) : (
            <InactiveControl />
          )}
          <span
            style={{ fontSize: "13px", marginLeft: "4px", color: "#525F7F" }}
          >
            {props.el.status === "ACTIVE" ? "Active" : "Inactive"}
          </span>
        </span>
      </td>
      <td className="text-center">
        <span
          onClick={() => {
            props.viewDetailsHandler();
            props.setOpenedIndex(props?.index);
            // console.log('View Details Clicked')
          }}
          style={{
            whiteSpace: "initial",
            color: "#00B0F5",
            fontSize: "12px",
            fontWeight: 600,
            cursor: "pointer",
          }}
        >
          View Details
        </span>
        {props?.openedIndex === props?.index && (
          <DetailsModal
            isDetailsModalOpen={props?.isDetailsModalOpen}
            setDetailsModalOpen={props?.setDetailsModalOpen}
            index={props?.index}
            updateKey={props?.updateKey}
            accountsArray={props?.accountsArray}
            el={props?.el}
            accNoDropdownModalData={props?.accNoDropdownModalData}
            isRowEdited={props?.isRowEdited}
            goBackWithDataLost={props?.goBackWithDataLost}
            viewDetails={props?.viewDetails}
            setViewDetails={props?.setViewDetails}
            buDispVal={props?.buDispVal}
            sapAccountDropdown={props?.sapAccountDropdown}
          />
        )}
      </td>
      <td className="text-center" style={{ whiteSpace: "initial" }}>
        <span>
          {moment(props.el["updatedOn"]).format("DD-MMM-YYYY,hh:mm A")}
        </span>
        <span
          style={{
            color: "#00B0F5",
            fontSize: "12px",
            fontWeight: 600,
            marginLeft: "10px",
          }}
        ></span>
      </td>

      <td className="text-center">
        {/* <Button
            color="info"
            size="sm"
            type="button"
            onClick={() => {
              props.editRow(props.index);
            }}
          >
            {' '}
            <i className="fas fa-edit" />
          </Button> */}
        {/* <CustomButton
            content={'Edit'}
            // icon={true}
            forTable={true}
            onClick={() => {
              props.editRow(props.index);
            }}
            permissionType={'U'}
            permissionSet={props.permissionSet}
            // data-testid="editBtn"
          /> */}
        <AButton
          onClick={() => {
            props.editRow(props.index);
          }}
          variant="primary_filled"
          size="xs"
        >
          Edit
        </AButton>
      </td>
      {/* </tr> */}
    </>
  );
};

const TDROW2 = (props) => {
  return (
    <>
      <tr key={props.index + "-class"}>
        <td className="text-center">{props.el.value}</td>
        <td className="text-center">
          {moment(props.el["eff_date"]).format("DD-MM-YYYY")}
        </td>
        <td className="text-center">{props.el.longName}</td>
        <td className="text-center">{props.el.shortName}</td>
        <td className="text-center">
          {props.el.status == "INACTIVE" ? (
            <button
              disabled={true}
              type="button"
              className="btn btn-sm btn-warning"
            >
              Inactive
            </button>
          ) : (
            <button
              type="button"
              disabled={true}
              className="btn btn-sm btn-success"
            >
              Active
            </button>
          )}
        </td>
        <td className="text-center">
          <Button
            color="info"
            size="sm"
            type="button"
            onClick={() => {
              props.editRow(props.index);
            }}
          >
            {" "}
            <i className="fas fa-edit" />
          </Button>
          <Button
            color="info"
            size="sm"
            type="button"
            onClick={() => {
              props.deleteRow(props.index);
            }}
          >
            {" "}
            <i className="fas fa-trash" />
          </Button>
        </td>
      </tr>
    </>
  );
};

const PAYMODEPINELABROW = (props) => {
  // console.log(props, 'props');
  return (
    <>
      <tr key={props.index + "-class"}>
        <td className="text-center" style={{ whiteSpace: "initial" }}>
          {props.el.pinelabsPaymentModeKey}
        </td>
        <td className="text-center">
          {moment(props.el.effectiveDate).format("DD-MM-YYYY")}
        </td>
        <td className="text-center" style={{ whiteSpace: "initial" }}>
          {props.el.pinelabsPaymentModeDescription}
        </td>
        <td className="text-center" style={{ whiteSpace: "initial" }}>
          {props.el.pinelabsPaymentModeDisplayValue}
        </td>
        <td className="text-center">
          {props.el.status == "INACTIVE" ? (
            <button
              disabled={true}
              type="button"
              className="btn btn-sm btn-warning"
            >
              Inactive
            </button>
          ) : (
            <button
              type="button"
              disabled={true}
              className="btn btn-sm btn-success"
            >
              Active
            </button>
          )}
        </td>
        <td className="text-center">
          {/* <EditButton
            onClick={() => {
              props.editRow(props.index);
            }}
          /> */}
          <CustomButton
            className={"mx-1"}
            forTable={true}
            icon={true}
            permissionType={"U"}
            permissionSet={props.permissionSet}
            onClick={() => {
              props.editRow(props.index);
            }}
          />
          {/* <DeleteButton
            onClick={() => {
              props.deleteRow(props.index);
            }}
          /> */}
          <CustomButton
            className={"mx-1"}
            forTable={true}
            icon={true}
            permissionType={"D"}
            permissionSet={props.permissionSet}
            onClick={() => {
              props.deleteRow(props.index);
            }}
          />
        </td>
      </tr>
    </>
  );
};

const MasterHeader2 = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center">Value</th>
          <th className="text-center">Effective Date </th>
          <th className="text-center">Long Name</th>
          <th className="text-center">Short Name</th>
          <th className="text-center">Status</th>
          <th className="text-center">Action</th>
        </tr>
      </thead>
    </>
  );
};

const MasterHeader3 = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center">Address Category Type</th>
          <th className="text-center">Status</th>
          {props.isSaveVisible ||
          (props.permissionSet &&
            (props.permissionSet.includes("U") ||
              props.permissionSet.includes("D"))) ? (
            <th className="text-center">Action</th>
          ) : null}
        </tr>
      </thead>
    </>
  );
};

const TermMasterHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center" data-testid="academicCareer">
            Academic Career
          </th>
          <th className="text-center" data-testid="term">
            Term
          </th>
          <th className="text-center" data-testid="updatedDate">
            Updated Date
          </th>
          {props.isSaveVisible ||
          (props.permissionSet &&
            (props.permissionSet.includes("R") ||
              props.permissionSet.includes("U") ||
              props.permissionSet.includes("D"))) ? (
            <th className="text-center" data-testid="action">
              Action
            </th>
          ) : null}
        </tr>
      </thead>
    </>
  );
};

const CourseAttrSessionHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center">Attribute Value</th>
          <th className="text-center">Attribute Value Description</th>
          <th className="text-center">Status</th>
          {!props.isViewOnly &&
          (props.isSaveVisible ||
            (props.permissionSet &&
              (props.permissionSet.includes("U") ||
                props.permissionSet.includes("D")))) ? (
            <th className="text-center">Action</th>
          ) : null}
        </tr>
      </thead>
    </>
  );
};

const PricingZoneHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center required-field">Business Area</th>
          <th className="text-center">Business Area Description</th>
          <th className="text-center">Status</th>
          {!props.isViewOnly ? <th className="text-center">Action</th> : null}
        </tr>
      </thead>
    </>
  );
};

const ProgramReasonSessionHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center">Action Reason</th>
          <th className="text-center">Action Reason Description</th>
          <th className="text-center">Status</th>
          <th className="text-center">Action</th>
        </tr>
      </thead>
    </>
  );
};

const TermSessionHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center">Session</th>
          <th className="text-center">Start Date</th>
          <th className="text-center">End Date</th>
          {!props.isViewOnly &&
          (props.isSaveVisible ||
            (props.permissionSet &&
              (props.permissionSet.includes("U") ||
                props.permissionSet.includes("D")))) ? (
            <th className="text-center">Action</th>
          ) : null}
        </tr>
      </thead>
    </>
  );
};

const TDROW3 = (props) => {
  return (
    <>
      <tr key={props.index + "-class"}>
        <td data-testid="type" className="text-center">
          {props.el.addressCategoryType}
        </td>
        <td data-testid="statusDetails" className="text-center">
          {props.el.status == "INACTIVE" ? (
            <button
              data-testid="statusInActive"
              disabled={true}
              type="button"
              className="btn btn-sm btn-warning"
            >
              Inactive
            </button>
          ) : (
            <button
              data-testid="statusActive"
              type="button"
              disabled={true}
              className="btn btn-sm btn-success"
            >
              Active
            </button>
          )}
        </td>
        {props.permissionSet &&
        (props.permissionSet.includes("U") ||
          props.permissionSet.includes("D")) ? (
          <td className="text-center">
            <CustomButton
              content={""}
              permissionType={"U"}
              icon={true}
              forTable={true}
              onClick={() => {
                props.editRow(props.index);
              }}
              permissionSet={props.permissionSet}
              data-testid="editBtn"
            />
            <CustomButton
              content={""}
              permissionType={"D"}
              icon={true}
              forTable={true}
              onClick={() => {
                props.deleteRow(props.index);
              }}
              permissionSet={props.permissionSet}
              data-testid="deleteBtn"
            />
          </td>
        ) : props.isSaveVisible ? (
          <td></td>
        ) : null}
      </tr>
    </>
  );
};

const THIRDPARTYSYSTEMTDROW = (props) => {
  return (
    <>
      <tr key={props.index + "-class"}>
        <td data-testid="type" className="text-center white-breakSpace">
          {props.el["thirdPartySystemKey"]}
        </td>
        <td
          data-testid="effectiveDate"
          className="text-center white-breakSpace"
        >
          {moment(props.el["effectiveDate"]).format("DD-MM-YYYY")}
        </td>
        <td data-testid="description" className="text-center white-breakSpace">
          {props.el["thirdPartyDesc"]}
        </td>
        <td data-testid="displayValue" className="text-center white-breakSpace">
          {props.el["thirdPartyDispValue"]}
        </td>
        <td
          data-testid="statusDetails"
          className="text-center white-breakSpace"
        >
          {props.el.status == "INACTIVE" ? (
            <button
              data-testid="statusInActive"
              disabled={true}
              type="button"
              className="btn btn-sm btn-warning"
            >
              Inactive
            </button>
          ) : (
            <button
              data-testid="statusActive"
              type="button"
              disabled={true}
              className="btn btn-sm btn-success"
            >
              Active
            </button>
          )}
        </td>
        <td className="text-center">
          <EditButton
            onClick={() => {
              props.editRow(props.index);
            }}
            data-testid="editButton"
          />
          <DeleteButton
            onClick={() => {
              props.deleteRow(props.index);
            }}
            data-testid="deletebtn"
          />
        </td>
      </tr>
    </>
  );
};
const RECEIPTLABELTDROW = (props) => {
  return (
    <>
      <tr key={props.index + "-class"}>
        <td className="text-center white-breakSpace">
          {props.el["receiptLabelKey"]}
        </td>
        <td className="text-center white-breakSpace">
          {moment(props.el["effectiveDate"]).format("DD-MM-YYYY")}
        </td>

        <td className="text-center white-breakSpace">
          {props.el["receiptLabelDescription"]}
        </td>
        <td className="text-center white-breakSpace">
          {props.el["receiptLabelDisplayValue"]}
        </td>
        <td className="text-center white-breakSpace">
          {props.el.status == "INACTIVE" ? (
            <button
              disabled={true}
              type="button"
              className="btn btn-sm btn-warning"
            >
              Inactive
            </button>
          ) : (
            <button
              type="button"
              disabled={true}
              className="btn btn-sm btn-success"
            >
              Active
            </button>
          )}
        </td>
        <td className="text-center">
          <EditButton
            onClick={() => {
              props.editRow(props.index);
            }}
            data-testid="editButton"
          />
          <DeleteButton
            onClick={() => {
              props.deleteRow(props.index);
            }}
          />
        </td>
      </tr>
    </>
  );
};

const COURSETDROW = (props) => {
  const {
    businessGroupData = [],
    companyCodeData = [],
    courseAttrData = [],
  } = props;

  const getNameById = (id, list, n) => {
    // console.log('User Id', id);
    // console.log('List_data', list);
    // console.log('title', n);
    const item = list.filter((item) => {
      return item && item.id === id;
    });
    // console.log('Item_List', item);
    const name = item.length ? item[0][`${n}DispValue`] : "Not Found";
    // console.log('Name', name);
    return name;
  };

  return (
    <>
      <tr key={props.index + "-class"}>
        <td className="text-center">
          {getNameById(props.el.groupCodeId, businessGroupData, "groupCode")}
        </td>
        <td className="text-center">
          {getNameById(props.el.companyCodeId, companyCodeData, "companyCode")}
        </td>
        <td className="text-center">
          {getNameById(
            props.el.courseAttributeId,
            courseAttrData,
            "courseAttribute"
          )}
        </td>
        {props.isSaveVisible ||
        (props.permissionSet &&
          (props.permissionSet.includes("R") ||
            props.permissionSet.includes("U") ||
            props.permissionSet.includes("D"))) ? (
          <td className="text-center">
            <CustomButton
              permissionType={"R"}
              icon={true}
              forTable={true}
              permissionSet={props.permissionSet}
              onClick={() => {
                props.viewRow();
              }}
            />
            <CustomButton
              permissionType={"U"}
              icon={true}
              forTable={true}
              permissionSet={props.permissionSet}
              onClick={() => {
                props.editRow();
              }}
            />
            <CustomButton
              permissionType={"D"}
              icon={true}
              forTable={true}
              permissionSet={props.permissionSet}
              onClick={() => {
                props.deleteRow(props.index);
              }}
            />
          </td>
        ) : null}
      </tr>
    </>
  );
};

const PROGRAMACTION = (props) => {
  let history = useHistory();
  const {
    programActionAllData = [],
    activeReasonAllData = [],
    classArrayAll = [],
  } = props;

  useEffect(() => {
    // console.log('Props******', props);
  }, [props]);

  // console.log('programActionAllData__COMP', programActionAllData);
  // console.log('activeReasonAllData__COMP', activeReasonAllData);
  // console.log('classArrayAll__COMP', classArrayAll);

  const getNameById = (id, list, n) => {
    // console.log('User Id', id);
    // console.log('List_data', list);
    // console.log('title', n);
    const item = list.filter((item) => {
      return item && item.id === id;
    });
    // console.log('Item_List', item);
    const name = item.length ? item[0][`${n}DispValue`] : "Not Found";
    // console.log('Name', name);
    return name;
  };

  return (
    <>
      <tr
        key={props.index + "-class"}
        onClick={() => {
          history.push(
            `/admin/programActions/${props.el.programActionMappingId}`
          );
        }}
        style={{ cursor: "pointer" }}
      >
        <td className="text-center">
          {getNameById(
            props.el.programActionId,
            programActionAllData,
            "programAction"
          )}
        </td>
        <td className="text-center">
          {getNameById(
            props.el.actionReasonId,
            activeReasonAllData,
            "actionReason"
          )}
        </td>
        <td className="text-center">{props.el.status}</td>
      </tr>
    </>
  );
};

const CourseCatalogDetails = (props) => {
  const { allAcademicCareer, permissionSet } = props;
  return (
    <>
      <tr key={props.index + "-class"}>
        <td className="text-center p-2 mx-1 white-breakSpace">
          {props.el["courseId"]}
        </td>
        <td className="text-center p-2 mx-1 white-breakSpace">
          {/* {' '}
          {props.el['courseName'] && props.el['courseName'].length < 30 ? (
            props.el['courseName']
          ) : (
            <>
              {props.el['courseName'].substring(0, 30)}
              <Tooltip content={props.el['courseName']} placement={'top'}>
                <span style={{ marginLeft: '4px', color: '#8898aa' }} id={'courseDesc'} className={'courseDesc'}>
                  <b>...</b>
                </span>
              </Tooltip>
            </>
          )} */}
          {props.el["courseName"]}
        </td>
        <td className="text-center p-2 mx-1 white-breakSpace">
          {props.el["courseIdStatus"]}
        </td>
        {/* <td className="text-center p-2 mx-1 white-breakSpace">
          {props.el['prospectusCode'] ? props.el['prospectusCode'] : 'NOT FOUND'}
        </td> */}
        <td className="text-center p-2 mx-1 white-breakSpace">
          {getDispValForReactSelectByOneID(
            props.el["academicCareerId"],
            "DispValue",
            allAcademicCareer
          )}
        </td>
        {permissionSet &&
        (permissionSet.includes("R") || permissionSet.includes("U")) ? (
          <td className="text-center">
            <CustomButton
              permissionType={"R"}
              icon={true}
              forTable={true}
              permissionSet={permissionSet}
              onClick={() => props.viewRow()}
            />
            {/*<ViewButton onClick={() => {props.viewRow();}}/>*/}
            <CustomButton
              permissionType={"U"}
              icon={true}
              forTable={true}
              permissionSet={permissionSet}
              onClick={() => props.editRow()}
            />
            {/*<EditButton permission={{edit:false}} onClick={() => {props.editRow();}}/>*/}
          </td>
        ) : null}
      </tr>
    </>
  );
};

const BusinessAreaSearchHeader = () => {
  return (
    <thead className="thead-light">
      <tr>
        <th className="text-center ">Sr.</th>
        <th className="text-center ">Business Area</th>
        <th className="text-center ">BA Description</th>
        <th className="text-center ">Business Area Type</th>
        <th className="text-center ">Group Code</th>
        <th className="text-center ">Company Code</th>
        <th className="text-center ">State</th>
        <th className="text-center ">Status</th>
        <th className="text-center ">Updated On</th>
        <th className="text-center ">Actions</th>
      </tr>
    </thead>
  );
};

const BusinessAreaDetails = (props) => {
  const {
    businessAreaDetailsAll = [],
    group = [],
    companyCode = [],
    businessUnitType = [],
    allStateName = [],
    userPermissions,
  } = props;

  // console.log('businessAreaDetailsAll__COMP', businessAreaDetailsAll);
  // console.log('group__COMP', group);
  // console.log('companyCode__COMP', companyCode);
  // console.log('businessUnitType__COMP', businessUnitType);
  // console.log('allStateName__COMP', allStateName);

  const getNameById = (id, list, n) => {
    // console.log('User Id', id);
    // console.log('List_data', list);
    // console.log('title', n);
    const item = list.filter((item) => {
      return item && item.id === id;
    });
    // console.log('Item_List', item);
    const name = item.length ? item[0][`${n}DispValue`] : "Not Found";
    // console.log('Name', name);
    return name;
  };

  return (
    <>
      <tr key={props.index + "-class"}>
        <td className="text-center ">{props.index + 1}</td>
        <td className="text-center white-breakSpace">
          {props.el["businessAreaKey"]}
        </td>
        <td className="text-center">{props.el["businessAreaDesc"]}</td>
        <td className="text-center white-breakSpace">
          {getNameById(
            props.el.businessUnitTypeId,
            businessUnitType,
            "businessUnitType"
          )}
        </td>
        <td className="text-center white-breakSpace">
          {getNameById(props.el.groupCodeId, group, "groupCode")}
        </td>
        <td className="text-center white-breakSpace">
          {getNameById(props.el.companyCodeId, companyCode, "companyCode")}
        </td>

        {/* {props.el.addressMasterDTO ? ( */}
        <td className="text-center">{`${
          allStateName?.find((state) => state?.id === props.el.stateCode)
            ?.stateDispValue
        }`}</td>
        {/* )  */}
        {/* : (
          <td className="text-center white-breakSpace">{'NOT FOUND'}</td>
        )} */}

        <td className="text-center">{props.el["status"]}</td>
        <td className="text-center">
          {moment(props.el["updatedOn"]).format("DD-MM-YYYY hh:mm:ss")}
        </td>
        <td className="text-center">
          {/* <Button
						color="info"
						size="sm"
						type="button"
						onClick={() => {
							props.viewRow();
						}}
					>
						<i className="fas fa-eye" />
					</Button>

					<Button
						color="info"
						size="sm"
						type="button"
						onClick={() => {
							props.editRow();
						}}
					>
						<i className="fas fa-edit" />
					</Button>
					<Button
						color="info"
						size="sm"
						type="button"
						onClick={() => {
							props.deleteRow(props.index);
						}}
					>
						<i className="fas fa-trash" />
					</Button> */}
          <CustomButton
            forTable={true}
            permissionType={"R"}
            icon={true}
            onClick={() => props.viewRow()}
            permissionSet={userPermissions}
            // disabled={true}
          />
          <CustomButton
            forTable={true}
            permissionType={"U"}
            icon={true}
            onClick={() => props.editRow()}
            permissionSet={userPermissions}
            // disabled={true}
          />
          <CustomButton
            forTable={true}
            permissionType={"D"}
            icon={true}
            onClick={() => props.deleteRow(props.index)}
            permissionSet={userPermissions}
          />
        </td>
      </tr>
    </>
  );
};

const BusinessAreaPlanHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center ">SAP PLANT ID</th>
          <th className="text-center ">SAP BUSINESS AREA</th>
          <th className="text-center ">SAP BUSINESS PLACE</th>
          <th className="text-center ">ACADEMIC CAREER</th>
          <th className="text-center ">DIFFERENT BUSINESS AREA</th>
          <th className="text-center ">UPDATED DATE</th>
          <th className="text-center ">STATUS</th>
          {!props.isDisabled ? <th className="text-center ">ACTION</th> : null}
        </tr>
      </thead>
    </>
  );
};
const BUSINESSAREAPLANTHROW = (props) => {
  const {
    item,
    editRecord,
    index,
    deleteRecord,
    getDisplayValue,
    action,
    isDisabled,
  } = props;

  return (
    <tr key={index}>
      <td className="text-center ">{item.sapPlantId}</td>
      <td className="text-center ">{item.sapBusinessArea}</td>
      <td className="text-center ">{item.sapBusinessPlace}</td>
      <td className="text-center ">
        {getDisplayValue(item.academicCareerId, "academicCareerDispValue")}
      </td>
      <td className="text-center ">{item.defermentBusinessArea}</td>
      <td className="text-center ">
        {moment(item.effectiveDate).format("DD-MM-YYYY")}
      </td>
      <td className="text-center ">
        {item.status === "ACTIVE" ? (
          <button
            disabled={true}
            type="button"
            className="btn btn-sm btn-success"
          >
            ACTIVE
          </button>
        ) : (
          <button
            type="button"
            disabled={true}
            className="btn btn-sm btn-warning"
          >
            INACTIVE
          </button>
        )}
      </td>
      {!isDisabled ? (
        <td className="text-center">
          <Button size="sm" color="info" onClick={() => editRecord(index)}>
            <i className="fas fa-edit" />
          </Button>
          <Button size="sm" color="danger" onClick={() => deleteRecord(index)}>
            <i className="fas fa-trash" />
          </Button>
        </td>
      ) : null}
    </tr>
  );
};

const ProfitCostCenterHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center ">SAP PROFIT CENTER</th>
          <th className="text-center ">PROFIT CENTER DESCRIPTION</th>
          <th className="text-center ">ACADEMIC CAREER</th>
          <th className="text-center ">ACADEMIC GROUP</th>
          <th className="text-center ">SAP COST CENTER</th>
          <th className="text-center ">COST CENTER DESCRIPTION</th>
          <th className="text-center ">UPDATED DATE</th>
          <th className="text-center ">STATUS</th>
          {!props.isDisabled ? <th className="text-center ">ACTION</th> : null}
        </tr>
      </thead>
    </>
  );
};
const PROFITCOSTCENTERTHROW = (props) => {
  const {
    item,
    editRecord,
    index,
    deleteRecord,
    getDisplayValue,
    action,
    isDisabled,
  } = props;

  return (
    <tr key={index}>
      <td className="text-center ">
        {getDisplayValue(item.sapProfitCenterId, "profitCenterDispValue")}
      </td>
      <td className="text-center ">
        {getDisplayValue(item.sapProfitCenterId, "profitCenterDescription")}
      </td>
      <td className="text-center ">
        {getDisplayValue(
          item.sapProfitCenterId,
          "profitCenterAcademicCareerDispValue"
        )}
      </td>
      <td className="text-center ">
        {getDisplayValue(
          item.sapProfitCenterId,
          "profitCenterAcademicGroupDispValue"
        )}
      </td>
      <td className="text-center ">{item.sapCostCenter}</td>
      <td className="text-center ">{item.costCenterDescription}</td>
      <td className="text-center ">
        {moment(item.effectiveDate).format("DD-MM-YYYY")}
      </td>
      <td className="text-center ">
        {item.status === "ACTIVE" ? (
          <button
            disabled={true}
            type="button"
            className="btn btn-sm btn-success"
          >
            ACTIVE
          </button>
        ) : (
          <button
            type="button"
            disabled={true}
            className="btn btn-sm btn-warning"
          >
            INACTIVE
          </button>
        )}
      </td>
      {!isDisabled ? (
        <td className="text-center">
          <Button size="sm" color="info" onClick={() => editRecord(index)}>
            <i className="fas fa-edit" />
          </Button>
          <Button
            size="sm"
            color="danger"
            onClick={() => deleteRecord(item["id"], index)}
          >
            <i className="fas fa-trash" />
          </Button>
        </td>
      ) : null}
    </tr>
  );
};
const OtherSystemHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center ">THIRD PARTY SYSTEM KEY</th>
          <th className="text-center ">RESPONSE KEY</th>
          <th className="text-center ">SYNC STATUS </th>
          <th className="text-center ">UPDATED BY</th>
          <th className="text-center ">CREATED BY</th>
          <th className="text-center ">UPDATED DATE</th>
          <th className="text-center ">STATUS</th>
          {!props.isDisabled ? <th className="text-center ">ACTION</th> : null}
        </tr>
      </thead>
    </>
  );
};
const OTHERSYSTEMTHROW = (props) => {
  const {
    item,
    editRecord,
    index,
    deleteRecord,
    getDisplayValue,
    action,
    isDisabled,
  } = props;

  return (
    <tr key={index}>
      <td className="text-center ">{item.thirdPartySysId}</td>
      <td className="text-center ">{item.responseKey}</td>
      <td className="text-center">
        {item.sfSync === "NO" ? (
          <button
            disabled={true}
            type="button"
            className="btn btn-sm btn-warning"
          >
            FAIL
          </button>
        ) : (
          <button
            type="button"
            disabled={true}
            className="btn btn-sm btn-success"
          >
            SUCCESS
          </button>
        )}
      </td>
      <td className="text-center ">{item.updatedBy}</td>
      <td className="text-center ">
        {moment(item.createdOn).format("DD-MM-YYYY")}
      </td>
      <td className="text-center ">
        {" "}
        {moment(item.createdOn).format("DD-MM-YYYY")}
      </td>
      <td className="text-center ">
        {item.status === "ACTIVE" ? (
          <button
            disabled={true}
            type="button"
            className="btn btn-sm btn-success"
          >
            ACTIVE
          </button>
        ) : (
          <button
            type="button"
            disabled={true}
            className="btn btn-sm btn-warning"
          >
            INACTIVE
          </button>
        )}
      </td>
      {!isDisabled ? (
        <td className="text-center">
          <Button size="sm" color="info" onClick={() => editRecord(index)}>
            <i className="fas fa-edit" />
          </Button>
          <Button size="sm" color="danger" onClick={() => deleteRecord(index)}>
            <i className="fas fa-trash" />
          </Button>
        </td>
      ) : null}
    </tr>
  );
};

const InvoiceNumberSettingHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center ">CODE</th>
          <th className="text-center ">YEAR</th>
          <th className="text-center ">LABEL</th>
          <th className="text-center ">REG POS INV</th>
          <th className="text-center ">REG NEG INV</th>
          <th className="text-center ">REG TRAN ID</th>
          <th className="text-center ">EXP POS INV</th>
          <th className="text-center ">EXP NEG INV</th>
          <th className="text-center ">EXP TRAN IND</th>
          <th className="text-center ">UPDATED DATE</th>
          <th className="text-center ">STATUS</th>
          {!props.isDisabled ? <th className="text-center ">ACTION</th> : null}
        </tr>
      </thead>
    </>
  );
};
const INVOICENUMBERSETTING = (props) => {
  const {
    dataChange,
    isSaveVisible,
    item,
    editRecord,
    index,
    deleteRecord,
    getDisplayValue,
    action,
    isDisabled,
  } = props;

  return (
    <tr key={index}>
      <td className="text-center ">{item.code}</td>
      <td className="text-center ">{item.year}</td>
      <td className="text-center ">
        {getDisplayValue(item.label, "ReceiptLabelDispValue")}
      </td>
      <td className="text-center ">{item.regPosInv}</td>
      <td className="text-center ">{item.regNegInv}</td>
      <td className="text-center ">{item.regTranId}</td>
      <td className="text-center ">{item.expPosInv}</td>
      <td className="text-center ">{item.expNegInv}</td>
      <td className="text-center ">{item.expTranId}</td>
      <td className="text-center ">
        {moment(item.effectiveDate).format("DD-MM-YYYY")}
      </td>
      <td className="text-center ">
        {item.status === "ACTIVE" ? (
          <button
            disabled={true}
            type="button"
            className="btn btn-sm btn-success"
          >
            ACTIVE
          </button>
        ) : (
          <button
            type="button"
            disabled={true}
            className="btn btn-sm btn-warning"
          >
            INACTIVE
          </button>
        )}
      </td>
      {!isDisabled ? (
        <td className="text-center">
          <Button size="sm" color="info" onClick={() => editRecord(index)}>
            <i className="fas fa-edit" />
          </Button>
          <Button size="sm" color="danger" onClick={() => deleteRecord(index)}>
            <i className="fas fa-trash" />
          </Button>
        </td>
      ) : null}
    </tr>
  );
};

const ReceiptNumberSettingHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center ">CODE</th>
          <th className="text-center ">YEAR</th>
          <th className="text-center ">LABEL</th>
          <th className="text-center ">RECEIPT NO</th>
          <th className="text-center ">TRANSACTION NO</th>
          <th className="text-center ">LAST RECEIPT NO</th>
          <th className="text-center ">UPDATED DATE</th>
          <th className="text-center ">STATUS</th>
          {!props.isDisabled ? <th className="text-center ">ACTION</th> : null}
        </tr>
      </thead>
    </>
  );
};
const RECEIPTNUMBERSETTING = (props) => {
  const {
    item,
    editRecord,
    index,
    deleteRecord,
    getDisplayValue,
    action,
    isDisabled,
  } = props;

  return (
    <tr key={index}>
      <td className="text-center ">{item.code}</td>
      <td className="text-center ">{item.year}</td>
      <td className="text-center ">
        {getDisplayValue(item.label, "ReceiptLabelDispValue")}
      </td>
      <td className="text-center ">{item.receiptNumber}</td>
      <td className="text-center ">{item.transactionNumber}</td>
      <td className="text-center ">{item.lastReceiptNumber}</td>
      <td className="text-center ">
        {moment(item.effectiveDate).format("DD-MM-YYYY")}
      </td>
      <td className="text-center ">
        {item.status === "ACTIVE" ? (
          <button
            disabled={true}
            type="button"
            className="btn btn-sm btn-success"
          >
            ACTIVE
          </button>
        ) : (
          <button
            type="button"
            disabled={true}
            className="btn btn-sm btn-warning"
          >
            INACTIVE
          </button>
        )}
      </td>
      {!isDisabled ? (
        <td className="text-center">
          <Button size="sm" color="info" onClick={() => editRecord(index)}>
            <i className="fas fa-edit" />
          </Button>
          <Button size="sm" color="danger" onClick={() => deleteRecord(index)}>
            <i className="fas fa-trash" />
          </Button>
        </td>
      ) : null}
    </tr>
  );
};

const RefundNumberSettingHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center ">CODE</th>
          <th className="text-center ">YEAR</th>
          <th className="text-center ">LABEL</th>
          <th className="text-center ">RECEIPT NO</th>
          <th className="text-center ">TRANSACTION NO</th>
          <th className="text-center ">LAST RECEIPT NO</th>
          <th className="text-center ">UPDATED DATE</th>
          <th className="text-center ">STATUS</th>
          {!props.isDisabled ? <th className="text-center ">ACTION</th> : null}
        </tr>
      </thead>
    </>
  );
};
const REFUNDNUMBERSETTING = (props) => {
  const {
    item,
    editRecord,
    index,
    deleteRecord,
    getDisplayValue,
    action,
    isDisabled,
  } = props;

  return (
    <tr key={index}>
      <td className="text-center ">{item.code}</td>
      <td className="text-center ">{item.year}</td>
      <td className="text-center ">
        {getDisplayValue(item.label, "ReceiptLabelDispValue")}
      </td>
      <td className="text-center ">{item.receiptNumber}</td>
      <td className="text-center ">{item.transactionNumber}</td>
      <td className="text-center ">{item.lastReceiptNumber}</td>
      <td className="text-center ">
        {moment(item.effectiveDate).format("DD-MM-YYYY")}
      </td>
      <td className="text-center ">
        {item.status === "ACTIVE" ? (
          <button
            disabled={true}
            type="button"
            className="btn btn-sm btn-success"
          >
            ACTIVE
          </button>
        ) : (
          <button
            type="button"
            disabled={true}
            className="btn btn-sm btn-warning"
          >
            INACTIVE
          </button>
        )}
      </td>
      {!isDisabled ? (
        <td className="text-center">
          <Button size="sm" color="info" onClick={() => editRecord(index)}>
            <i className="fas fa-edit" />
          </Button>
          <Button size="sm" color="danger" onClick={() => deleteRecord(index)}>
            <i className="fas fa-trash" />
          </Button>
        </td>
      ) : null}
    </tr>
  );
};

const NonTaxableReceiptRefundNoSettingHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center ">CODE</th>
          <th className="text-center ">YEAR</th>
          <th className="text-center ">LABEL</th>
          <th className="text-center ">RECEIPT NO</th>
          <th className="text-center ">TRANSACTION NO</th>
          <th className="text-center ">LAST RECEIPT NO</th>
          <th className="text-center ">UPDATED DATE</th>
          <th className="text-center ">STATUS</th>
          {!props.isDisabled ? <th className="text-center ">ACTION</th> : null}
        </tr>
      </thead>
    </>
  );
};
const NONTAXABLERECEIPTREFUNDNOSETTING = (props) => {
  const {
    item,
    editRecord,
    index,
    deleteRecord,
    getDisplayValue,
    action,
    isDisabled,
  } = props;

  return (
    <tr key={index}>
      <td className="text-center ">{item.code}</td>
      <td className="text-center ">{item.year}</td>
      <td className="text-center ">
        {getDisplayValue(item.label, "ReceiptLabelDispValue")}
      </td>
      <td className="text-center ">{item.receiptNumber}</td>
      <td className="text-center ">{item.transactionNumber}</td>
      <td className="text-center ">{item.lastReceiptNumber}</td>
      <td className="text-center ">
        {moment(item.effectiveDate).format("DD-MM-YYYY")}
      </td>
      <td className="text-center ">
        {item.status === "ACTIVE" ? (
          <button
            disabled={true}
            type="button"
            className="btn btn-sm btn-success"
          >
            ACTIVE
          </button>
        ) : (
          <button
            type="button"
            disabled={true}
            className="btn btn-sm btn-warning"
          >
            INACTIVE
          </button>
        )}
      </td>
      {!isDisabled ? (
        <td className="text-center">
          <Button size="sm" color="info" onClick={() => editRecord(index)}>
            <i className="fas fa-edit" />
          </Button>
          <Button size="sm" color="danger" onClick={() => deleteRecord(index)}>
            <i className="fas fa-trash" />
          </Button>
        </td>
      ) : null}
    </tr>
  );
};

const ScholarshipTransactionSetupHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center ">CODE</th>
          <th className="text-center ">YEAR</th>
          <th className="text-center ">LABEL</th>
          <th className="text-center ">APPROVAL ID</th>
          <th className="text-center ">TRANSACTION NO</th>
          <th className="text-center ">UPDATED DATE</th>
          <th className="text-center ">STATUS</th>
          {!props.isDisabled ? <th className="text-center ">ACTION</th> : null}
        </tr>
      </thead>
    </>
  );
};
const SCHOLARSHIPTRANSACSETUP = (props) => {
  const {
    item,
    editRecord,
    index,
    deleteRecord,
    getDisplayValue,
    action,
    isDisabled,
  } = props;

  return (
    <tr key={index}>
      <td className="text-center ">{item.code}</td>
      <td className="text-center ">{item.year}</td>
      <td className="text-center ">
        {getDisplayValue(item.label, "ReceiptLabelDispValue")}
      </td>
      <td className="text-center ">{item.approvalId}</td>
      <td className="text-center ">{item.transactionNo}</td>
      <td className="text-center ">
        {moment(item.effectiveDate).format("DD-MM-YYYY")}
      </td>
      <td className="text-center ">
        {item.status === "ACTIVE" ? (
          <button
            disabled={true}
            type="button"
            className="btn btn-sm btn-success"
          >
            ACTIVE
          </button>
        ) : (
          <button
            type="button"
            disabled={true}
            className="btn btn-sm btn-warning"
          >
            INACTIVE
          </button>
        )}
      </td>
      {!isDisabled ? (
        <td className="text-center">
          <Button size="sm" color="info" onClick={() => editRecord(index)}>
            <i className="fas fa-edit" />
          </Button>
          <Button size="sm" color="danger" onClick={() => deleteRecord(index)}>
            <i className="fas fa-trash" />
          </Button>
        </td>
      ) : null}
    </tr>
  );
};

const ControlPaymodeFunctionHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          {props.type === "paymentMethod" ? (
            <th className="text-center ">PAYMENT METHOD</th>
          ) : null}
          {props.type === "functionalityControl" ? (
            <th className="text-center ">FUNCTIONALITY CODE</th>
          ) : null}
          <th className="text-center ">DESCRIPTION</th>
          <th className="text-center ">ALLOW FOR RECEIPTING</th>
          <th className="text-center ">UPDATED DATE</th>
          <th className="text-center ">STATUS</th>
          <th className="text-center ">ACTION</th>
        </tr>
      </thead>
    </>
  );
};
const CONTROLPAYMODEFUNCTIONTHROW = (props) => {
  const { item, editRecord, index, deleteRecord, getDisplayValue, action } =
    props;

  return (
    <tr key={index}>
      {props.type === "paymentMethod" ? (
        <td className="text-center white-breakSpace">
          {getDisplayValue(item.paymentMethodId, "paymentMethodDispValue")}
        </td>
      ) : null}
      {props.type === "functionalityControl" ? (
        <td className="text-center white-breakSpace">
          {getDisplayValue(item.functionalityCodeId, "functionalityDispValue")}
        </td>
      ) : null}
      {props.type === "paymentMethod" ? (
        <td className="text-center white-breakSpace">
          {getDisplayValue(item.paymentMethodId, "paymentMethodDescription")}
        </td>
      ) : null}
      {props.type === "functionalityControl" ? (
        <td className="text-center white-breakSpace">
          {getDisplayValue(
            item.functionalityCodeId,
            "functionalityDescription"
          )}
        </td>
      ) : null}
      <td className="text-center">
        {item.allowReceipting === false ? (
          <button
            disabled={true}
            type="button"
            className="btn btn-sm btn-warning"
          >
            NO
          </button>
        ) : (
          <button
            type="button"
            disabled={true}
            className="btn btn-sm btn-success"
          >
            YES
          </button>
        )}
      </td>
      <td className="text-center ">
        {moment(item.updatedOn).format("DD-MM-YYYY")}
      </td>
      <td className="text-center ">
        {item.status === "ACTIVE" ? (
          <button
            disabled={true}
            type="button"
            className="btn btn-sm btn-success"
          >
            ACTIVE
          </button>
        ) : (
          <button
            type="button"
            disabled={true}
            className="btn btn-sm btn-warning"
          >
            INACTIVE
          </button>
        )}
      </td>
      {!(action === "view") ? (
        <td className="text-center">
          <Button size="sm" color="info" onClick={() => editRecord(index)}>
            <i className="fas fa-edit" />
          </Button>
          <Button size="sm" color="danger" onClick={() => deleteRecord(index)}>
            <i className="fas fa-trash" />
          </Button>
        </td>
      ) : null}
    </tr>
  );
};

const CourseForBUAndSecurityHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center ">COURSE KEY</th>
          <th className="text-center ">COURSE DESCRIPTION</th>
          <th className="text-center ">UPDATED DATE</th>
          <th className="text-center ">STATUS</th>
          <th className="text-center ">ACTION</th>
        </tr>
      </thead>
    </>
  );
};

const COURSEFORBUSECURITYTHROW = (props) => {
  const { item, editRecord, index, deleteRecord, action, isDisabled } = props;

  return (
    <tr key={index}>
      <td className="text-center ">
        {getDispValForReactSelectByOneID(
          item.courseId,
          "DispValue",
          props.course
        )}
      </td>
      <td className="text-center ">
        {getDispValForReactSelectByOneID(
          item.courseId,
          "DescValue",
          props.course
        )}
      </td>
      <td className="text-center ">
        {moment(item.effectiveDate).format("DD-MM-YYYY")}
      </td>
      <td className="text-center ">
        {item.status === "ACTIVE" ? (
          <button
            disabled={true}
            type="button"
            className="btn btn-sm btn-success"
          >
            ACTIVE
          </button>
        ) : (
          <button
            type="button"
            disabled={true}
            className="btn btn-sm btn-warning"
          >
            INACTIVE
          </button>
        )}
      </td>
      {!isDisabled ? (
        <td className="text-center">
          <Button size="sm" color="info" onClick={() => editRecord(index)}>
            <i className="fas fa-edit" />
          </Button>
          <Button size="sm" color="danger" onClick={() => deleteRecord(index)}>
            <i className="fas fa-trash" />
          </Button>
        </td>
      ) : null}
    </tr>
  );
};

const SUBSUBCATEGORYTHROW = (props) => {
  let history = useHistory();
  const { item, editRecord, index, deleteRecord, action, isDisabled } = props;
  return (
    <tr key={index}>
      <td className="text-center ">
        {getDispValForReactSelectByOneID(
          item.subSubCategoryMasterId,
          "DispValue",
          props.subSubCategoryData
        )}
      </td>
      <td className="text-center ">
        {getDispValForReactSelectByOneID(
          item.subSubCategoryMasterId,
          "DescValue",
          props.subSubCategoryData
        )}
      </td>
      <td className="text-center">
        <Button
          onClick={() => {
            history.push(
              `/admin/scholarship/subCategory/subSubCategory/addCourseSecurity/${item["id"]}`
            );
          }}
          color={item["buAndCourseSecurityCount"] ? "primary" : "info"}
          size="sm"
          type="button"
        >
          <i className="fas fa-edit" />
          Setup Course Security
        </Button>
      </td>
      <td className="text-center ">
        {moment(item.effectiveDate).format("DD-MM-YYYY")}
      </td>
      <td className="text-center ">
        {item.status === "ACTIVE" ? (
          <button
            disabled={true}
            type="button"
            className="btn btn-sm btn-success"
          >
            ACTIVE
          </button>
        ) : (
          <button
            type="button"
            disabled={true}
            className="btn btn-sm btn-warning"
          >
            INACTIVE
          </button>
        )}
      </td>
      {!isDisabled ? (
        <td className="text-center">
          <Button size="sm" color="info" onClick={() => editRecord(index)}>
            <i className="fas fa-edit" />
          </Button>
          <Button size="sm" color="danger" onClick={() => deleteRecord(index)}>
            <i className="fas fa-trash" />
          </Button>
        </td>
      ) : null}
    </tr>
  );
};

const TERMTDROW = (props) => {
  const { academicData = [], termData = [] } = props;
  const getNameById = (id, list, n) => {
    const item = list.filter((item) => {
      return item && item.id === id;
    });
    const name = item.length ? item[0][`${n}DispValue`] : "Not Found";
    return name;
  };
  return (
    <>
      <tr key={props.index + "-class"}>
        <td className="text-center" data-testid="academicCareerData">
          {getNameById(
            props.el.academicCareerId,
            academicData,
            "academicCareer"
          )}
        </td>
        <td className="text-center" data-testid="termData">
          {getNameById(props.el.termId, termData, "term")}
        </td>
        <td className="text-center" data-testid="updatedOnData">
          {moment(props.el.updatedOn).format("DD-MM-YYYY")}
        </td>
        {props.isSaveVisible ||
        (props.permissionSet &&
          (props.permissionSet.includes("R") ||
            props.permissionSet.includes("U") ||
            props.permissionSet.includes("D"))) ? (
          <td className="text-center">
            <CustomButton
              permissionType={"R"}
              icon={true}
              forTable={true}
              permissionSet={props.permissionSet}
              onClick={() => {
                props.viewRow();
              }}
            />
            <CustomButton
              permissionType={"U"}
              icon={true}
              forTable={true}
              permissionSet={props.permissionSet}
              onClick={() => {
                props.editRow();
              }}
            />
            <CustomButton
              permissionType={"D"}
              icon={true}
              forTable={true}
              permissionSet={props.permissionSet}
              onClick={() => {
                props.deleteRow(props.index);
              }}
            />
          </td>
        ) : null}
      </tr>
    </>
  );
};

const TERMSESSIONROW = (props) => {
  const { sessionData = [] } = props;
  const getNameById = (id) => {
    const item = sessionData.filter((item) => {
      return item && item.value === id;
    });
    const name = item.length ? item[0][`label`] : "Not Found";
    return name;
  };
  return (
    <>
      <tr key={props.index + "-class"}>
        <td className="text-center">{getNameById(props.el.sessionId)}</td>
        <td className="text-center">
          {moment(props.el.startDate).format("DD-MM-YYYY")}
        </td>
        <td className="text-center">
          {moment(props.el.endDate).format("DD-MM-YYYY")}
        </td>
        {!props.isViewOnly &&
        (props.isSaveVisible ||
          (props.permissionSet && props.permissionSet.includes("U")) ||
          props.permissionSet.includes("D")) ? (
          <td className="text-center">
            <CustomButton
              permissionType={"U"}
              icon={true}
              forTable={true}
              permissionSet={props.permissionSet}
              onClick={() => {
                props.editRow(props.index);
              }}
            />
            <CustomButton
              permissionType={"D"}
              icon={true}
              forTable={true}
              permissionSet={props.permissionSet}
              onClick={() => {
                props.deleteRow(props.index);
              }}
            />
          </td>
        ) : null}
      </tr>
    </>
  );
};

const COURSEATTRSESSIONTABLE = (props) => {
  const { attributeData = [] } = props;
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    const { attributeData = [] } = props;
    const item = attributeData.filter((item) => {
      return item && item.value === props.el.courseAttributeValueId;
    });
    const name = item.length ? item[0][`desc`] : "Not Found";
    setDescription(name);
    setName(item.length ? item[0][`label`] : "Not Found");
  }, [props]);

  return (
    <>
      <tr key={props.index + "-class"}>
        <td data-testid="key" className="text-center">
          {name}
        </td>
        <td data-testid="description" className="text-center">
          {description}
        </td>
        {/* <td className="text-center p-2 mx-1">
					<CustomToggle
						status={props.el.status}
						data-testid="activeInactiveInput"
						id={props.el.id}
						toggleOn="Active"
						toggleOff="Inactive"
					/>
				</td> */}
        <td className="text-center">
          {props.status === "INACTIVE" ? (
            <Button
              data-testid="editButton"
              size="sm"
              type="button"
              className="btn btn-sm btn-warning"
              disabled={true}
            >
              Inactive
            </Button>
          ) : (
            <Button
              data-testid="statusActive"
              type="button"
              disabled={true}
              className="btn btn-sm btn-success"
            >
              Active
            </Button>
          )}
        </td>

        {!props.isViewOnly &&
        (props.isSaveVisible ||
          (props.permissionSet &&
            (props.permissionSet.includes("D") ||
              props.permissionSet.includes("U")))) ? (
          <td className="text-center">
            <CustomButton
              permissionType={"U"}
              icon={true}
              forTable={true}
              permissionSet={props.permissionSet}
              onClick={() => {
                props.editRow(props.index);
              }}
            />
            <CustomButton
              permissionType={"D"}
              icon={true}
              forTable={true}
              permissionSet={props.permissionSet}
              onClick={() => {
                props.deleteRow(props.index);
              }}
            />
          </td>
        ) : null}
      </tr>
    </>
  );
};

const PRICINGZONETABLE = (props) => {
  const { item, businessArea, attributeData = [] } = props;

  // const getNameById = (id) => {
  // 	const item = props.businessArea.filter((item) => {
  // 		return item && item.value === id;
  // 	});
  // 	const name = item.length ? item[0][`label`] : 'Not Found';
  // 	return name;
  // };

  // const getDescById = (id) => {
  // 	const item = props.businessArea.filter((item) => {
  // 		return item && item.value === id;
  // 	});
  // 	const name = item.length ? item[0][`desc`] : 'Not Found';
  // 	return name;
  // };

  // const [description, setDescription] = useState('');
  // const [name, setName] = useState('');

  // useEffect(() => {
  // 	const { attributeData = [] } = props;
  // 	const item = attributeData.filter((item) => {
  // 		return item && item.value === props.el.courseAttributeValueId;
  // 	});
  // 	const name = item.length ? item[0][`desc`] : 'Not Found';
  // 	setDescription(name);
  // 	setName(item.length ? item[0][`label`] : 'Not Found');
  // }, [props]);

  return (
    <>
      <tr key={props.index + "-class"}>
        <td className="text-center">
          {getDispValForSelectByOneID(
            item.businessAreaId,
            "DispValue",
            businessArea
          )}
        </td>
        <td className="text-center">
          {getDispValForSelectByOneID(
            item.businessAreaId,
            "DescValue",
            businessArea
          )}
        </td>
        <td className="text-center p-2 mx-1">
          {item.status === "ACTIVE" ? (
            <button
              disabled={true}
              type="button"
              className="btn btn-sm btn-success"
            >
              ACTIVE
            </button>
          ) : (
            <button
              type="button"
              disabled={true}
              className="btn btn-sm btn-warning"
            >
              INACTIVE
            </button>
          )}
        </td>
        {!props.isViewOnly ? (
          <td className="text-center">
            <Button
              color="info"
              size="sm"
              type="button"
              onClick={() => {
                props.editRow(props.index);
              }}
            >
              <i className="fas fa-edit" />
            </Button>
            {/* <Button
							color="info"
							size="sm"
							type="button"
							onClick={() => {
								props.deleteRow(props.index);
							}}
						>
							{' '}
							<i className="fas fa-trash" />
						</Button> */}
          </td>
        ) : null}
      </tr>
    </>
  );
};

const ACTIONREASONSESSIONTABLE = (props) => {
  const { actionReasonData = [] } = props;

  // console.log('actionReasonData____MR', actionReasonData);

  const getNameById = (id) => {
    const item = actionReasonData.filter((item) => {
      return item && item.value === id;
    });
    const name = item.length ? item[0][`label`] : "Not Found";
    return name;
  };

  const [description, setDescription] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    const { actionReasonData = [] } = props;
    // console.log('actionReasonData___2', actionReasonData);
    // console.log('props.el.actionReasonId---', props.el.actionReasonId);
    const item = actionReasonData.filter((item) => {
      return item && item.value === props.el.actionReasonId;
    });
    const name = item.length ? item[0][`desc`] : "Not Found";
    // console.log('name---', name);
    setDescription(name);
    setName(item.length ? item[0][`label`] : "Not Found");
  }, [props]);

  return (
    <>
      <tr key={props.index + "-class"}>
        {/* <td className="text-center">{getNameById(props.el.sessionId)}</td> */}
        <td className="text-center">{name}</td>
        <td className="text-center">{description}</td>
        <td className="text-center p-2 mx-1">
          <label className="custom-toggle mx-auto ml-2">
            <input
              checked={props.el.status == "ACTIVE" ? true : false}
              type="checkbox"
              id={props.el.id}
            />
            <span
              style={{ width: "72px" }}
              className="custom-toggle-slider rounded-circle activeToggle"
              data-label-off="Inactive"
              data-label-on="Active"
            />
          </label>
        </td>
        <td className="text-center">
          <Button
            color="info"
            size="sm"
            type="button"
            onClick={() => {
              props.editRow(props.index);
            }}
          >
            <i className="fas fa-edit" />
          </Button>
          <Button
            color="info"
            size="sm"
            type="button"
            onClick={() => {
              props.deleteRow(props.index);
            }}
          >
            {" "}
            <i className="fas fa-trash" />
          </Button>
        </td>
      </tr>
    </>
  );
};

const ExternalOrganizationHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center">External Org ID</th>
          <th className="text-center">Status </th>
          <th className="text-center">Associated BU</th>
          <th className="text-center">Organisation Type</th>
          <th className="text-center">organisation Name</th>
          <th className="text-center">Action</th>
        </tr>
      </thead>
    </>
  );
};

const SeatingCapacityOrganizationHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center">Room Number</th>
          <th className="text-center">Room Capacity</th>
          <th className="text-center">Floor Number</th>
          <th className="text-center">Action</th>
        </tr>
      </thead>
    </>
  );
};

const AffiliationTableHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center">Affiliation Number</th>
          <th className="text-center">Affiliated Board</th>
          <th className="text-center">Action</th>
        </tr>
      </thead>
    </>
  );
};

const AffiliationTableTDRow = (props) => {
  // console.log('props.affiliationBoardList', props.affiliationBoardList);

  const {
    affiliationBoardList,
    editAffiliationDetails,
    deleteRow,
    isDisabled,
    index,
    tabletd,
  } = props;
  const getAffiliationBoardDisp = (id) => {
    const Index =
      affiliationBoardList &&
      affiliationBoardList.findIndex((item) => {
        return item.value == id;
      });
    return Index >= 0 ? affiliationBoardList[Index]["label"] : "NOT FOUND";
  };

  return (
    <tr key={props.index}>
      <td className="text-center  white-breakSpace">
        {tabletd.affliationNumber}
      </td>
      <td className="text-center  white-breakSpace">
        {getAffiliationBoardDisp(tabletd.affliateBoard)}
      </td>
      <td className="text-center">
        <Button
          data-testid={`editAffilationDetails-${index}`}
          color="info"
          size="sm"
          type="button"
          disabled={isDisabled}
          onClick={() => {
            editAffiliationDetails(index);
          }}
        >
          <i className="fas fa-edit" />
        </Button>
        <Button
          data-testid={`deleteAffilationDetails-${index}`}
          disabled={isDisabled}
          color="info"
          size="sm"
          type="button"
          onClick={() => {
            deleteRow(index);
          }}
        >
          <i className="fas fa-trash" />
        </Button>
      </td>
    </tr>
  );
};

const SeatingCapacityTableTDRow = (props) => {
  return (
    <tr key={props.index}>
      <td className="text-center  white-breakSpace">{props.item.roomNumber}</td>
      <td className="text-center  white-breakSpace">
        {props.item.roomCapacity}
      </td>
      <td className="text-center  white-breakSpace">
        {props.item.floorNumber}
      </td>
      <td
        className="text-center"
        colSpan={props.type === "companyCode" ? 2 : 1}
      >
        <Button
          color="info"
          size="sm"
          type="button"
          onClick={() => {
            props.editAffiliationDetails(props.index);
          }}
        >
          <i className="fas fa-edit" />
        </Button>
        <Button
          color="info"
          size="sm"
          type="button"
          // onClick={() => {
          //   props.deleteRow(props.index);
          // }}
        >
          <i className="fas fa-trash" />
        </Button>
      </td>
    </tr>
  );
};

const OrganizationTableThrow = (props) => {
  let history = useHistory();
  // console.log('props', props.index);

  const { organizationType, el, index } = props;

  const getOrganizationTpyeDisp = (id) => {
    const Index =
      organizationType &&
      organizationType.findIndex((item) => {
        return item.value == id;
      });
    return Index >= 0 ? organizationType[Index]["label"] : "NOT FOUND";
  };

  return (
    <tr key={index}>
      <td className="text-center  white-breakSpace">{el.id}</td>
      <td className="text-center  white-breakSpace">{el.status}</td>
      <td className="text-center  white-breakSpace">{el.businessAreaId}</td>
      <td className="text-center  white-breakSpace">
        {getOrganizationTpyeDisp(el.organizationType)}
      </td>
      <td className="text-center  white-breakSpace">{el.organizationName}</td>
      <td className="text-center">
        <Button
          color="info"
          size="sm"
          type="button"
          onClick={() => {
            history.push({
              pathname: "/admin/externalOrg/viewMode",
              state: el.id,
            });
          }}
        >
          <i class="fas fa-eye" />
        </Button>
        <Button
          color="info"
          size="sm"
          type="button"
          onClick={() => {
            history.push({
              pathname: "/admin/externalOrg/editMode",
              state: el.id,
            });
          }}
        >
          <i className="fas fa-edit" />
        </Button>
        <Button
          color="info"
          size="sm"
          type="button"
          onClick={() => {
            props.deleteOrganizationForm(el.id, index);
          }}
        >
          <i className="fas fa-trash" />
        </Button>
      </td>
    </tr>
  );
};

const ExternalOrgSearchListHeader = () => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center">External Org Id</th>
          <th className="text-center">Status</th>
          <th className="text-center">Associated BU</th>
          <th className="text-center">Organization Type</th>
          <th className="text-center">Organization Name</th>
          <th className="text-center">Action</th>
        </tr>
      </thead>
    </>
  );
};

const SeatingCapacityThrow = (props) => {
  // console.log('SeatingCapacityThrow', props);
  return (
    <tr key={props.index}>
      <td className="text-center  white-breakSpace">{props.el.roomNumber}</td>
      <td className="text-center  white-breakSpace">{props.el.roomCapacity}</td>
      <td className="text-center  white-breakSpace">{props.el.floorNumber}</td>
      <td
        className="text-center"
        colSpan={props.type === "companyCode" ? 2 : 1}
      >
        <>
          <Button
            data-testid={`editRecord-${props.index}`}
            color="info"
            size="sm"
            type="button"
            onClick={() => {
              props.editModeEnable(props.index);
            }}
          >
            <i className="fas fa-edit" />
          </Button>
          <Button
            data-testid={`deleteRecord-${props.index}`}
            color="info"
            size="sm"
            type="button"
            onClick={() => {
              props.deleteRow(props.index);
            }}
          >
            <i className="fas fa-trash" />
          </Button>
        </>
      </td>
    </tr>
  );
};

const ItemTypeSetup = () => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center ">GROUP CODE</th>
          <th className="text-center ">COMPANY CODE</th>
          <th className="text-center ">CLASSIFICATION</th>
          <th className="text-center ">INST.NO.</th>
          <th className="text-center ">ELEMENT</th>
          <th className="text-center ">ITEM TYPE</th>
          <th className="text-center ">TYPE</th>
          <th className="text-center ">UPDATE DATE</th>
          <th className="text-center ">DESCRIPTION</th>
          <th className="text-center ">SHORT DESCR</th>
          <th className="text-center white-breakSpace">HSN/SAC TAX CODE</th>
          <th className="text-center ">SETUP INV A/C ENTRY</th>
          <th className="text-center ">SF SYNC</th>
          <th className="text-center ">STATUS</th>
          <th className="text-center ">ACTION</th>
        </tr>
      </thead>
    </>
  );
};

const TDROWITEMTYPESETUP = (props) => {
  const {
    classificationTypeList,
    categoryTypeList,
    elementTypeList,
    companyCodeList,
    groupCodeList,
    getDisplayValue,
  } = props;

  return (
    <>
      <tr key={props.index + "-class"}>
        <td className="text-center  white-breakSpace">
          {getDisplayValue(
            props.el.groupCodeId,
            groupCodeList.data,
            "groupCode"
          )}
        </td>
        <td className="text-center  white-breakSpace">
          {getDisplayValue(
            props.el.companyCodeId,
            companyCodeList.data,
            "companyCode"
          )}
        </td>
        <td className="text-center  white-breakSpace">
          {getDisplayValue(
            props.el.itemTypeClassificationId,
            classificationTypeList.data,
            "classification"
          )}
        </td>
        <td className="text-center  white-breakSpace">
          {getDisplayValue(
            props.el.itemTypeCategoryId,
            categoryTypeList.data,
            "category"
          )}
        </td>
        <td className="text-center  white-breakSpace">
          {getDisplayValue(
            props.el.itemTypeElementId,
            elementTypeList.data,
            "element"
          )}
        </td>
        <td className="text-center  white-breakSpace">{props.el.itemTypeId}</td>
        <td className="text-center  white-breakSpace">{props.el.type}</td>
        <td className="text-center  white-breakSpace">
          {moment(props.el.updatedOn).format("DD-MM-YYYY")}
        </td>
        <td className="text-center  white-breakSpace">
          {props.el.description}
        </td>
        <td className="text-center  white-breakSpace">
          {props.el.shortDescription}
        </td>
        <td className="text-center  white-breakSpace">{props.el.taxCode}</td>
        <td className="text-center ">
          <Button color="info" size="sm" type="button">
            <i className="fas fa-edit" />
            Add Invoice A/C Entry
          </Button>
        </td>
        <td className="text-center">
          {props.el.sfSync === "NO" ? (
            <button
              disabled={true}
              type="button"
              className="btn btn-sm btn-warning"
            >
              NO
            </button>
          ) : (
            <button
              type="button"
              disabled={true}
              className="btn btn-sm btn-success"
            >
              YES
            </button>
          )}
        </td>
        <td className="text-center">
          {props.el.status === "ACTIVE" ? (
            <button
              disabled={true}
              type="button"
              className="btn btn-sm btn-success"
            >
              ACTIVE
            </button>
          ) : (
            <button
              type="button"
              disabled={true}
              className="btn btn-sm btn-warning"
            >
              INACTIVE
            </button>
          )}
        </td>
        <td
          className="text-center"
          colSpan={props.type === "companyCode" ? 2 : 1}
        >
          <Button
            color="info"
            size="sm"
            type="button"
            onClick={() => {
              props.editRow(props.index, props.el.groupCodeId);
            }}
          >
            {" "}
            <i className="fas fa-edit" />
          </Button>
          <Button
            color="info"
            size="sm"
            type="button"
            onClick={() => {
              props.deleteRow(props.index);
            }}
          >
            {" "}
            <i className="fas fa-trash" />
          </Button>
        </td>
      </tr>
    </>
  );
};

const CatalogAssociatedAttributeHeader = (props) => {
  const { permissionSet } = props;
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center ">Course Attribute</th>
          <th className="text-center">Course Attribute Description</th>
          <th className="text-center">Attribute Value</th>
          {/* <th className="text-center">Attribute Value Description</th> */}
          {!(props.action === "view") &&
          ((permissionSet && permissionSet.includes("U")) ||
            permissionSet.includes("C")) ? (
            <th className="text-center">Action</th>
          ) : null}
        </tr>
      </thead>
    </>
  );
};

const THROWASSOCIATEDATTRIBUTE = (props) => {
  const {
    index,
    getDisplayvalue,
    setAttributeDispValue,
    attributeDispValue,
    item,
    deleteRow,
    editData,
    action,
    updateData,
    attributeValueArray,
    setAttributeValueArray,
    dropdownForAttributeValue,
    submitHandler,
    check,
    setUserDataList,
    userDataList,
    selectedId,
    setSelectedId,
  } = props;
  // console.log('Item----dropdownForAttributeValue--------->');
  const [displayValue, setDisplayValue] = useState([]);
  // console.log('Item=================>', item);
  useEffect(() => {
    // console.log('Selected Id ==========>', selectedId);
  }, [selectedId]);
  let temp;
  const [rowIndex, setRowIndex] = useState();
  const [array, setArray] = useState([]);
  let arr = [];
  // console.log('Array====================>', array);
  return (
    <tr key={index}>
      <td
        className="text-center white-breakSpace"
        style={{ wordBreak: "break-all" }}
      >
        {getDisplayvalue(item.courseAttributeId, "courseAttributeDisp")}
      </td>
      <td
        className="text-center  white-breakSpace"
        style={{ wordBreak: "break-all" }}
      >
        {getDisplayvalue(item.courseAttributeId, "courseAttributeDesc")}
      </td>
      <td
        className="text-center  white-breakSpace"
        style={{ wordBreak: "break-all" }}
      >
        {item.dataFromApi && !check ? (
          <Select2
            options={{ placeholder: "Attribute Value" }}
            data={item.dropdown}
            // value={item.courseAttributeValueId ?  item.courseAttributeValueId :array[index]}
            // value={array[index] && array[index] !== '' ? array[index] : selectedId}
            // defaultValue={array[index]}
            defaultValue={
              item.courseAttributeValueId
                ? item.courseAttributeValueId
                : array[index]
            }
            onChange={(e) => {
              if (e.target.value) {
                if (e.target.value !== item.courseAttributeValueId) {
                  // console.log('Update Associate attribute Value---->', e.target.value);
                  setSelectedId(e.target.value);
                  temp = e.target.value;
                  // let arr = [...array];
                  // console.log('Index=========>', index);
                  let arr = array;
                  arr[index] = e.target.value;
                  // console.log('Arr---->', arr);
                  // setAttributeValueArray(arr);
                  setArray(arr);
                  // setRowIndex(index);
                  updateData(index, "courseAttributeValueId", temp);
                  let a = [...attributeDispValue];
                  a[index] = getDisplayvalue(
                    e.target.value,
                    "courseAttributeDescValue"
                  );
                  setAttributeDispValue(a);
                  // setAttributeValueArray(a);
                }
              }
            }}
          />
        ) : (
          getDisplayvalue(
            item.courseAttributeValueId,
            "courseAttributeDispValue"
          )
        )}
      </td>
      <td
        className="text-center  white-breakSpace"
        style={{ wordBreak: "break-all" }}
      >
        {item.dataFromApi && !check
          ? selectedId
            ? // displayValue[index]
              item.courseAttributeValueId === ""
              ? attributeDispValue[index]
              : getDisplayvalue(
                  item.courseAttributeValueId,
                  "courseAttributeDescValue"
                )
            : // getDisplayvalue(item.courseAttributeValueId ? item.courseAttributeValueId : selectedId,'courseAttributeDescValue')
              ""
          : getDisplayvalue(
              item.courseAttributeValueId,
              "courseAttributeDescValue"
            )}
      </td>
      {!(action === "view") ? (
        item.dataFromApi ? null : (
          // <td className="text-center  white-breakSpace">
          //   {
          //     !item.isValid && !check ?
          //           <Button color="info" size="sm" type="button" className="mx-1" onClick={() => {
          //                 if(!selectedId){
          //                   failureToast('Please Selec Attribute value.');
          //                 }else{
          //                   updateData(index,'courseAttributeValueId',selectedId);
          //                 }
          //           }}
          //           >
          //             Save
          //           </Button>
          //         :
          //         <Button color="info" size="sm" type="button" onClick={() => editData(index)}>
          //           <i className="fas fa-edit" />
          //         </Button>
          //   }
          // </td>
          <td className="text-center  white-breakSpace">
            <Button
              color="info"
              size="sm"
              type="button"
              onClick={() => editData(index)}
            >
              <i className="fas fa-edit" />
            </Button>
            {/*{(!item.status || !item.courseId)  ? (*/}
            {item.isNew && !item.courseId ? (
              <Button
                color="info"
                size="sm"
                type="button"
                onClick={() => deleteRow(index)}
              >
                <i className="fas fa-trash" />
              </Button>
            ) : (
              ""
            )}
          </td>
        )
      ) : null}
    </tr>
  );
};

const CourseDetailsTableHeader = () => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center "> Course Attribute</th>
          <th className="text-center "> Attribute Description</th>
          <th className="text-center ">Attribute Value</th>
          <th className="text-center ">Course ID</th>
          <th className="text-center ">Minimum Number</th>
          <th className="text-center ">Maximum Number</th>
          <th className="text-center ">Status</th>
          <th className="text-center "> Action </th>
        </tr>
      </thead>
    </>
  );
};

const COURSETABLETHROW = (props) => {
  const {
    item,
    deleteRow,
    editRow,
    index,
    courseAttribute,
    courseAttributeValueList,
  } = props;

  const getDisplayValue = (id, type) => {
    switch (type) {
      case "courseId":
        const IndexAttribute =
          courseAttribute &&
          courseAttribute.findIndex((item) => {
            return item.id == id;
          });
        return IndexAttribute >= 0
          ? courseAttribute[IndexAttribute]["text"]
          : "NOT FOUND";
        break;
      case "courseIdDesc":
        const IndexAttributeDesc =
          courseAttribute &&
          courseAttribute.findIndex((item) => {
            return item.id == id;
          });
        return IndexAttributeDesc >= 0
          ? courseAttribute[IndexAttributeDesc]["desc"]
          : "NOT FOUND";
        break;
      case "courseIdValue":
        const IndexAttributeValue =
          courseAttributeValueList &&
          courseAttributeValueList.findIndex((item) => {
            return item.id == id;
          });
        return IndexAttributeValue >= 0
          ? courseAttributeValueList[IndexAttributeValue]["label"]
          : "NOT FOUND";
        break;
      default:
        break;
    }
  };

  // console.log('item data is here : ', item);
  return (
    <>
      <tr key={index + "class"}>
        <td className="text-center white-breakSpace">
          {getDisplayValue(item.courseAttributeId, "courseId")}
        </td>
        <td className="text-center white-breakSpace">
          {getDisplayValue(item.courseAttributeId, "courseIdDesc")}
        </td>
        <td className="text-center white-breakSpace">
          {getDisplayValue(item.courseAttributeValueId, "courseIdValue")}
        </td>
        <td className="text-center white-breakSpace">
          {item.courseIdSequence}
        </td>
        <td className="text-center white-breakSpace">{item.minimumNumber}</td>
        <td className="text-center white-breakSpace">{item.maximumNumber}</td>
        <td className="text-center white-breakSpace">{item.status}</td>
        <td
          className="text-center white-breakSpace"
          style={{ display: "flex" }}
        >
          <Button
            color="info"
            size="sm"
            type="button"
            onClick={() => editRow(index)}
          >
            <i className="fas fa-edit" />
          </Button>
          <Button
            color="danger"
            size="sm"
            type="button"
            onClick={() => deleteRow(item.id)}
          >
            <i className="fas fa-trash" />
          </Button>
        </td>
      </tr>
    </>
  );
};

const TaxCodeIdMasterHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center ">Tax Code Id Key</th>
          <th className="text-center ">Business Group</th>
          <th className="text-center ">Comapany Code</th>
          <th className="text-center ">Tax Regime</th>
          <th className="text-center ">Effective Date</th>
          <th className="text-center ">Description</th>
          <th className="text-center ">Display Value</th>
          <th className="text-center ">Add Tax Breakup</th>
          {/* <th className="text-center ">Add Item Types</th> */}
          <th className="text-center ">Status</th>
          {props.isSaveVisible ||
          (props.permissionSet &&
            (props.permissionSet.includes("U") ||
              props.permissionSet.includes("D"))) ? (
            <th className="text-center "> Action </th>
          ) : null}
        </tr>
      </thead>
    </>
  );
};
const SetupDatesHeader = () => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center ">COURSE YEAR</th>
          <th className="text-center ">START DATE</th>
          <th className="text-center ">END DATE</th>
          <th className="text-center ">SETUP INSTALLMENTS</th>
          <th className="text-center ">DISPATCH SCHEDULE</th>
          <th className="text-center ">TOTAL INSTALLMENT</th>
          <th className="text-center ">STATUS</th>
          <th className="text-center ">UPDATED DATE</th>
          <th className="text-center ">ACTION</th>
        </tr>
      </thead>
    </>
  );
};
const SetupBuAndCourseSecuiryHeader = () => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center ">TERM</th>
          <th className="text-center ">ALL COURSES</th>
          <th className="text-center ">ADD COURSES</th>
          <th className="text-center ">COURSE COUNT</th>
          {/*<th className="text-center ">ALL BUSINESS AREA</th>*/}
          {/*<th className="text-center ">ADD BUSINESS AREA</th>*/}
          {/*<th className="text-center ">BUSINESS AREA COUNT</th>*/}
          <th className="text-center ">STATUS</th>
          <th className="text-center ">UPDATED DATE</th>
          <th className="text-center ">ACTION</th>
        </tr>
      </thead>
    </>
  );
};

const SetupInstallmentsHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center ">COURSE YEAR</th>
          <th className="text-center ">DUE DATE</th>
          <th className="text-center ">ITEM TYPE</th>
          <th className="text-center ">ITEM TYPE DESCRIPTION</th>
          <th className="text-center ">BASE FEES</th>
          <th className="text-center ">TAX</th>
          <th className="text-center ">TOTAL AMOUNT</th>
          <th className="text-center ">CASH ALLOWED</th>
          <th className="text-center ">STATUS</th>
          <th className="text-center ">UPDATED DATE</th>
          <th className="text-center ">ACTION</th>
        </tr>
      </thead>
    </>
  );
};
const HouseBankHeader = () => {
  return (
    <thead className="thead-light">
      <tr>
        <th className="text-center ">
          SR.<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">
          GROUP CODE<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">
          COMPANY CODE<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">
          BANK ACCOUNT ID KEY<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">
          BANK SHORT KEY<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">
          BANK NAME<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">
          BANK ACCOUNT NUMBER<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">
          UPDATED ON<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">ACTIONS</th>
      </tr>
    </thead>
  );
};

const HOUSEBANKTHROW = (props) => {
  const { item, getDisplayValue, history, deleteRecord, index } = props;

  return (
    <tr>
      <td className="text-center ">{index + 1}</td>
      <td className="text-center ">
        {getDisplayValue(item["groupCodeId"], "GroupCodeID")}
      </td>
      <td className="text-center ">
        {getDisplayValue(item["companyCodeId"], "CompanyCodeID")}
      </td>
      <td className="text-center ">{item["bankAccountId"]}</td>
      <td className="text-center ">{item["bankShortKey"]}</td>
      <td className="text-center ">{item["bankId"]}</td>
      <td className="text-center ">{item["bankAccountNumber"]}</td>
      <td className="text-center ">
        {moment(item["updatedOn"]).format("DD-MM-YYY hh:mm:ss")}
      </td>
      <td className="text-center ">
        <Button
          color="info"
          size="sm"
          type="button"
          onClick={() => history.push(`/admin/houseBank/view/${item["id"]}`)}
        >
          <i className="fas fa-eye" />
        </Button>
        <Button
          color="info"
          size="sm"
          type="button"
          onClick={() => history.push(`/admin/houseBank/edit/${item["id"]}`)}
        >
          <i className="fas fa-edit" />
        </Button>
        <Button
          color="danger"
          size="sm"
          type="button"
          onClick={() => deleteRecord(item["id"])}
        >
          <i className="fas fa-trash" />
        </Button>
      </td>
    </tr>
  );
};
const TaxCodeComponent = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center ">Tax Code Type Key</th>
          <th className="text-center ">Description</th>
          <th className="text-center ">Display Value</th>
          <th className="text-center ">Zero Tax</th>
          <th className="text-center ">Tax Percentage</th>
          <th className="text-center ">Status</th>
          {props.isSaveVisible ||
          (props.permissionSet &&
            (props.permissionSet.includes("U") ||
              props.permissionSet.includes("D"))) ? (
            <th className="text-center "> Action </th>
          ) : null}
        </tr>
      </thead>
    </>
  );
};

const TaxReceiptHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center ">TAX CODE TYPE KEY</th>
          <th className="text-center ">DESCRIPTION</th>
          <th className="text-center ">B2B TAX</th>
          <th className="text-center ">B2C TAX</th>
          <th className="text-center ">IGST B2B TAX</th>
          <th className="text-center ">IGST B2C TAX</th>
          <th className="text-center ">STATUS</th>
          {!props.isDisabled ? <th className="text-center ">ACTION</th> : null}
        </tr>
      </thead>
    </>
  );
};
const TAXRECEIPTSTHROW = (props) => {
  const { item, editRecord, index, deleteRecord, getDisplayValue, isDisabled } =
    props;

  return (
    <tr key={index}>
      <td className="text-center ">
        {getDisplayValue(item.taxCodeTypeKey, "taxCodeTypeDisplayValue")}
      </td>
      <td className="text-center ">
        {getDisplayValue(item.taxCodeTypeKey, "taxCodeTypeDesc")}
      </td>
      <td className="text-center ">
        {getDisplayValue(item.b2bTaxId, "taxCodeIdDisplayValue")}
      </td>
      <td className="text-center ">
        {getDisplayValue(item.b2cTaxId, "taxCodeIdDisplayValue")}
      </td>
      <td className="text-center ">
        {getDisplayValue(item.igstB2bTaxId, "taxCodeIdDisplayValue")}
      </td>
      <td className="text-center ">
        {getDisplayValue(item.igstB2cTaxId, "taxCodeIdDisplayValue")}
      </td>
      <td className="text-center ">
        {item.status === "ACTIVE" ? (
          <button
            disabled={true}
            type="button"
            className="btn btn-sm btn-success"
          >
            ACTIVE
          </button>
        ) : (
          <button
            type="button"
            disabled={true}
            className="btn btn-sm btn-warning"
          >
            INACTIVE
          </button>
        )}
      </td>
      {!isDisabled ? (
        <td className="text-center">
          <Button size="sm" color="info" onClick={() => editRecord(index)}>
            <i className="fas fa-edit" />
          </Button>
          <Button
            size="sm"
            color="danger"
            onClick={() => deleteRecord(item["id"], index)}
          >
            <i className="fas fa-trash" />
          </Button>
        </td>
      ) : null}
    </tr>
  );
};

const TaxRefundHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center ">TAX CODE TYPE KEY</th>
          <th className="text-center ">DESCRIPTION</th>
          <th className="text-center ">B2B TAX</th>
          <th className="text-center ">B2C TAX</th>
          <th className="text-center ">IGST B2B TAX</th>
          <th className="text-center ">IGST B2C TAX</th>
          <th className="text-center ">STATUS</th>
          {!props.isDisabled ? <th className="text-center ">ACTION</th> : null}
        </tr>
      </thead>
    </>
  );
};

const TAXREFUNDTHROW = (props) => {
  const { item, editRecord, index, deleteRecord, getDisplayValue, isDisabled } =
    props;

  return (
    <tr key={index}>
      <td className="text-center ">
        {getDisplayValue(item.taxCodeTypeKey, "taxCodeTypeDisplayValue")}
      </td>
      <td className="text-center ">
        {getDisplayValue(item.taxCodeTypeKey, "taxCodeTypeDesc")}
      </td>
      <td className="text-center ">
        {getDisplayValue(item.b2bTaxId, "taxCodeIdDisplayValue")}
      </td>
      <td className="text-center ">
        {getDisplayValue(item.b2cTaxId, "taxCodeIdDisplayValue")}
      </td>
      <td className="text-center ">
        {getDisplayValue(item.igstB2bTaxId, "taxCodeIdDisplayValue")}
      </td>
      <td className="text-center ">
        {getDisplayValue(item.igstB2cTaxId, "taxCodeIdDisplayValue")}
      </td>
      <td className="text-center ">
        {item.status === "ACTIVE" ? (
          <button
            disabled={true}
            type="button"
            className="btn btn-sm btn-success"
          >
            ACTIVE
          </button>
        ) : (
          <button
            type="button"
            disabled={true}
            className="btn btn-sm btn-warning"
          >
            INACTIVE
          </button>
        )}
      </td>
      {!isDisabled ? (
        <td className="text-center">
          <Button size="sm" color="info" onClick={() => editRecord(index)}>
            <i className="fas fa-edit" />
          </Button>
          <Button
            size="sm"
            color="danger"
            onClick={() => deleteRecord(item["id"], index)}
          >
            <i className="fas fa-trash" />
          </Button>
        </td>
      ) : null}
    </tr>
  );
};

const CommissionFranchisesOnlyHeader = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center ">ACADEMIC GROUP KEY</th>
          <th className="text-center ">DESCRIPTION</th>
          <th className="text-center ">UPDATED DATE</th>
          <th className="text-center ">COMMISSION % </th>
          <th className="text-center ">TDS %</th>
          <th className="text-center ">UPLOAD AGREEMENT</th>
          <th className="text-center ">STATUS</th>
          {!props.isDisabled ? <th className="text-center ">ACTION</th> : null}
        </tr>
      </thead>
    </>
  );
};

const COMMISSIONFRANCHISESONLYTHROW = (props) => {
  const { item, editRecord, index, deleteRecord, getDisplayValue, isDisabled } =
    props;

  // console.log(item);
  return (
    <tr key={index}>
      <td className="text-center ">
        {getDisplayValue(item.academicGroupKeyId, "academicGroupKeyIdDisp")}
      </td>
      <td className="text-center ">
        {getDisplayValue(item.academicGroupKeyId, "academicGroupKeyIdDesc")}
      </td>
      <td className="text-center ">
        {moment(item.updatedOn).format("DD-MM-YYYY")}
      </td>
      <td className="text-center ">{item.commissionPercent}</td>
      <td className="text-center ">{item.tdsPercent}</td>
      <td className="text-center ">{item.uploadAgreementUrl}</td>
      <td className="text-center ">
        {item.status === "ACTIVE" ? (
          <button
            disabled={true}
            type="button"
            className="btn btn-sm btn-success"
          >
            ACTIVE
          </button>
        ) : (
          <button
            type="button"
            disabled={true}
            className="btn btn-sm btn-warning"
          >
            INACTIVE
          </button>
        )}
      </td>
      {!isDisabled ? (
        <td className="text-center">
          <Button size="sm" color="info" onClick={() => editRecord(index)}>
            <i className="fas fa-edit" />
          </Button>
          <Button
            size="sm"
            color="danger"
            onClick={() => deleteRecord(item["id"], index)}
          >
            <i className="fas fa-trash" />
          </Button>
        </td>
      ) : null}
    </tr>
  );
};

const TaxItemType = (props) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center ">Item Type</th>
          <th className="text-center ">Type</th>
          <th className="text-center ">Updated Date</th>
          <th className="text-center ">Description</th>
          <th className="text-center ">Status</th>
          {props.isSaveVisible ||
          (props.permissionSet &&
            (props.permissionSet.includes("U") ||
              props.permissionSet.includes("D"))) ? (
            <th className="text-center "> Action </th>
          ) : null}
        </tr>
      </thead>
    </>
  );
};

const TaxItemTypeRow = (props) => {
  const { itemTypeData = [] } = props;
  const getNameById = (id, list) => {
    const item =
      list &&
      list.filter((item) => {
        return item.id == id;
      });
    const name = item && item.length ? item[0] : null;
    return name;
  };
  return (
    <>
      <tr key={props.index + "-class"}>
        <td className="text-center">
          {getNameById(props.el.itemTypeId, itemTypeData) &&
            getNameById(props.el.itemTypeId, itemTypeData)["itemTypeId"]}
        </td>
        <td className="text-center">
          {getNameById(props.el.itemTypeId, itemTypeData) &&
            getNameById(props.el.itemTypeId, itemTypeData)["type"]}
        </td>
        <td className="text-center">
          {moment(props.el["updatedOn"]).format("DD-MM-YYYY")}
        </td>
        <td className="text-center">
          {getNameById(props.el.itemTypeId, itemTypeData) &&
            getNameById(props.el.itemTypeId, itemTypeData)["description"]}
        </td>
        <td className="text-center">
          {props.el.status == "INACTIVE" ? (
            <button
              disabled={true}
              type="button"
              className="btn btn-sm btn-warning"
            >
              Inactive
            </button>
          ) : (
            <button
              type="button"
              disabled={true}
              className="btn btn-sm btn-success"
            >
              Active
            </button>
          )}
        </td>
        {props.isSaveVisible ||
        (props.permissionSet &&
          (props.permissionSet.includes("U") ||
            props.permissionSet.includes("D"))) ? (
          <td className="text-center">
            <CustomButton
              permissionType={"U"}
              icon={true}
              forTable={true}
              permissionSet={props.permissionSet}
              onClick={() => {
                props.editRow(props.index);
              }}
            />
            <CustomButton
              permissionType={"D"}
              icon={true}
              forTable={true}
              permissionSet={props.permissionSet}
              onClick={() => {
                props.deleteRow(props.index);
              }}
            />
          </td>
        ) : null}
      </tr>
    </>
  );
};

const TaxComponentRow = (props) => {
  const { taxCodeData = [] } = props;
  const getNameById = (id, list, key) => {
    const item =
      list &&
      list.filter((item) => {
        return item.id == id;
      });
    const name = item && item.length ? item[0][key] : null;
    return name;
  };
  return (
    <>
      <tr key={props.index + "-class"}>
        <td className="text-center">
          {getNameById(
            props.el["taxCodeTypeId"],
            taxCodeData,
            "taxCodeTypeKey"
          )}
        </td>
        <td
          className="text-center ellipsis-text-container"
          style={{ maxWidth: "150px" }}
        >
          <MaterialTooltip
            title={getNameById(
              props.el["taxCodeTypeId"],
              taxCodeData,
              "taxCodeTypeDesc"
            )}
          >
            {getNameById(
              props.el["taxCodeTypeId"],
              taxCodeData,
              "taxCodeTypeDesc"
            )}
          </MaterialTooltip>
        </td>
        <td className="text-center">
          {getNameById(
            props.el["taxCodeTypeId"],
            taxCodeData,
            "taxCodeTypeDispValue"
          )}
        </td>
        <td className="text-center">
          {props.el.isZeroTax === false ? (
            <button
              disabled={true}
              type="button"
              className="btn btn-sm btn-warning"
            >
              NO
            </button>
          ) : (
            <button
              type="button"
              disabled={true}
              className="btn btn-sm btn-success"
            >
              YES
            </button>
          )}
        </td>
        <td className="text-center">{props.el["taxPercentage"]}</td>
        <td className="text-center">
          {props.el.status == "INACTIVE" ? (
            <button
              disabled={true}
              type="button"
              className="btn btn-sm btn-warning"
            >
              Inactive
            </button>
          ) : (
            <button
              type="button"
              disabled={true}
              className="btn btn-sm btn-success"
            >
              Active
            </button>
          )}
        </td>
        {props.isSaveVisible ||
        (props.permissionSet &&
          (props.permissionSet.includes("U") ||
            props.permissionSet.includes("D"))) ? (
          <td className="text-center">
            <CustomButton
              permissionType={"U"}
              icon={true}
              forTable={true}
              permissionSet={props.permissionSet}
              onClick={() => {
                props.editRow(props.index);
              }}
            />
            <CustomButton
              permissionType={"D"}
              icon={true}
              forTable={true}
              permissionSet={props.permissionSet}
              onClick={() => {
                props.deleteRow(props.index);
              }}
            />
          </td>
        ) : null}
      </tr>
    </>
  );
};

const BankCodeHeader = () => {
  return (
    <thead className="thead-light">
      <tr>
        <th className="text-center ">
          SR.<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">
          COUNTRY<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">
          BANK CODE<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">
          ALTERNATE BANK CODE<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">
          BANK NAME<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">
          STATUS<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">
          UPDATED ON<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">ACTIONS</th>
      </tr>
    </thead>
  );
};

const BANKCODETHROW = (props) => {
  const { item, country, history, deleteRecord, index } = props;

  return (
    <tr>
      <td className="text-center ">{index + 1}</td>
      <td className="text-center ">
        {getDispValForSelectByOneID(item["countryCode"], "DispValue", country)}
      </td>
      <td className="text-center ">{item["bankCode"]}</td>
      <td className="text-center ">{item["alternateBankCode"]}</td>
      <td className="text-center ">{item["bankName"]}</td>
      <td className="text-center ">{item["status"]}</td>
      <td className="text-center ">
        {moment(item["updatedOn"]).format("DD-MM-YYY hh:mm:ss")}
      </td>
      <td className="text-center ">
        <Button
          color="info"
          size="sm"
          type="button"
          // onClick={() => openInNewTab(`/view/${item['id']}`)}
          onClick={() => history.push(`/admin/bank/view/${item["id"]}`)}
        >
          <i className="fas fa-eye" />
        </Button>
        <Button
          color="info"
          size="sm"
          type="button"
          onClick={() => history.push(`/admin/bank/edit/${item["id"]}`)}
        >
          <i className="fas fa-edit" />
        </Button>
        <Button
          color="danger"
          size="sm"
          type="button"
          onClick={() => deleteRecord(item["id"])}
        >
          <i className="fas fa-trash" />
        </Button>
      </td>
    </tr>
  );
};
const ProfitHeader = () => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th className="text-center ">GROUP CODE</th>
          <th className="text-center ">COMPANY CODE</th>
          <th className="text-center ">SAP BRANCH TYPE</th>
          <th className="text-center ">SAP CAREER TYPE</th>
          <th className="text-center ">SAP ACAD CAREER</th>
          <th className="text-center "> SAP SEGMENT </th>
          <th className="text-center ">SEQ NO</th>
          <th className="text-center ">SAP PROFIT CENTER</th>
          <th className="text-center ">ACADEMIC CAREER</th>
          <th className="text-center ">ACADEMIC GROUP</th>
          <th className="text-center ">UPDATED DATE</th>
          <th className="text-center ">DESCRIPTION</th>
          <th className="text-center ">SHORT DESCR</th>
          <th className="text-center ">STATUS</th>
          <th className="text-center ">ACTION</th>
        </tr>
      </thead>
    </>
  );
};

const PROFITCENTERMAPPINGTHROW = (props) => {
  const { item, index, editRow, deleteRow, getDisplayValue } = props;

  return (
    <>
      <tr key={index}>
        <td data-testid="groupCodeId" className="text-center">
          {getDisplayValue(item.groupCodeID, "GroupCodeID")}
        </td>
        <td data-testid="companyCodeId" className="text-center">
          {getDisplayValue(item.companyCodeId, "CompanyCodeID")}
        </td>
        <td data-testid="branchType" className="text-center">
          {getDisplayValue(item.businessUnitTypeId, "BranchType")}
        </td>
        <td data-testid="careerType" className="text-center">
          {getDisplayValue(item.academicCareerId, "CareerType")}
        </td>
        <td data-testid="academicType" className="text-center">
          {getDisplayValue(item.academicCareerId, "AcademicType")}
        </td>
        <td data-testid="segmentType" className="text-center">
          {getDisplayValue(item.academicGroupId, "SegmentType")}
        </td>
        <td data-testid="sequenceNumber" className="text-center">
          {item["sequenceNumber"]}
        </td>
        <td data-testid="sapProfitCenterId" className="text-center">
          {item["sapProfitCenterId"]}
        </td>
        <td data-testid="AcademicCareerId" className="text-center">
          {getDisplayValue(item["academicCareerId"], "AcademicCareerID")}
        </td>
        <td data-testid="AcademicGroupId" className="text-center">
          {getDisplayValue(item["academicGroupId"], "AcademicGroupID")}
        </td>
        <td data-testid="effectiveDate" className="text-center">
          {moment(item["updatedOn"]).format("DD-MM-YYYY")}
        </td>
        <td data-testid="description" className="text-center">
          {item["description"]}
        </td>
        <td data-testid="shortDescription" className="text-center">
          {item["shortDescription"]}
        </td>
        <td data-testid="statusDetails" className="text-center">
          {item.status === "ACTIVE" ? (
            <button
              data-testid="statusActive"
              disabled={true}
              type="button"
              className="btn btn-sm btn-success"
            >
              ACTIVE
            </button>
          ) : (
            <button
              data-testid="statusInActive"
              type="button"
              disabled={true}
              className="btn btn-sm btn-warning"
            >
              INACTIVE
            </button>
          )}
        </td>
        <td className="text-center">
          <EditButton data-testid="editButton" onClick={() => editRow(index)} />
          <DeleteButton
            data-testid="deletebtn"
            onClick={() => deleteRow(index)}
          />
        </td>
      </tr>
    </>
  );
};

const GLCodeHeader = () => {
  return (
    <thead className="thead-light">
      <tr>
        <th className="text-center ">
          SR.<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">
          GROUP CODE<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">
          COMPANY CODE<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">
          GL CODE<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">
          DESCRIPTION<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">
          REFERENCE ID<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">
          COUNTRY<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">
          CURRENCY<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">
          UPDATED ON<i className="fas fa-sort"></i>
        </th>
        <th className="text-center ">ACTIONS</th>
      </tr>
    </thead>
  );
};

const GLCODETHROW = (props) => {
  const { item, getDisplayValue, history, deleteRecord, index } = props;

  return (
    <tr>
      <td className="text-center ">{index + 1}</td>
      <td className="text-center ">
        {getDisplayValue(item["groupCodeId"], "GroupCodeID")}
      </td>
      <td className="text-center ">
        {getDisplayValue(item["companyCodeId"], "CompanyCodeID")}
      </td>
      <td className="text-center ">{item["glCode"]}</td>
      <td className="text-center ">{item["glDescription"]}</td>
      <td className="text-center ">{item["referenceId"]}</td>
      <td className="text-center ">
        {getDisplayValue(item["country"], "CountryID")}
      </td>
      <td className="text-center ">
        {getDisplayValue(item["currency"], "CurrencyID")}
      </td>
      <td className="text-center ">
        {moment(item["updatedOn"]).format("DD-MM-YYY")}
      </td>
      <td className="text-center ">
        <Button
          color="info"
          size="sm"
          type="button"
          onClick={() => openInNewTab(`/view/${item["id"]}`)}
        >
          <i className="fas fa-eye" />
        </Button>
        <Button
          color="info"
          size="sm"
          type="button"
          onClick={() => openInNewTab(`/edit/${item["id"]}`)}
        >
          <i className="fas fa-edit" />
        </Button>
        <Button
          color="danger"
          size="sm"
          type="button"
          onClick={() => deleteRecord(item["id"])}
        >
          <i className="fas fa-trash" />
        </Button>
      </td>
    </tr>
  );
};

const SetupDateRow = (props) => {
  // console.log('data------>', props.courseFeesYear);
  return (
    <tr>
      {/*<td>{props.courseYear}</td>*/}
      <td>{props.courseFeesYear.courseYear}</td>
      <td>{props.courseFeesYear.startDate}</td>
      <td>
        <Button
          color="info"
          size="sm"
          type="button"
          className="mx-1"
          onClick={() => {}}
        >
          Setup Installments
        </Button>
      </td>
      <td>
        <Button
          color="info"
          size="sm"
          type="button"
          className="mx-1"
          onClick={() => {}}
        >
          Dispatch Schedule
        </Button>
      </td>
      <td>{props.courseFeesYear.totalInstallments}</td>
      <td>
        <label>
          <input
            checked={true}
            type="checkbox"
            id={"temp"}
            //        onChange={(e) => {
            //   setStatus(status === 'ACTIVE'?'INACTIVE':'ACTIVE')
            // }}
          />
          <span
            style={{ width: "72px" }}
            className="custom-toggle-slider rounded-circle activeToggle"
            data-label-off="Inactive"
            data-label-on="Active"
          />
        </label>
      </td>
      <td>{props.courseFeesYear.updatedDate}</td>
      <td>
        <Button color="info" size="sm" type="button" onClick={() => {}}>
          <i className="fas fa-trash" />
        </Button>
      </td>
    </tr>
  );
};

const DocumentManagementHeader = (props) => {
  const { selectedPersonType } = props;

  return (
    <thead className="thead-light">
      <tr>
        <th className="text-center">{selectedPersonType}</th>
        {selectedPersonType?.toUpperCase() === "STUDENT" ? (
          <th className="text-center ">Application ID</th>
        ) : null}
        <th className="text-center ">ACTIONS</th>
      </tr>
    </thead>
  );
};

const MasterHeaderLessonPlan = (props) => {
  return (
    <>
      <thead className="thead-light table-header">
        <tr>
          <th className="text-center pl-5 pr-5 required-field">Chapter</th>
          <th className="text-center pl-5 pr-5 required-field">Topic</th>
          <th className="text-center pl-5 pr-5 required-field">Sub-topic</th>
          <th className="text-center pl-5 pr-5 required-field">
            Sub-sub-topic
          </th>
          <th className="text-center required-field column-width-hours">
            No. of
            <br />
            Hours
          </th>
          <th className="text-center required-field column-width-sequence">
            Sequence
          </th>
          <th className="text-center pl-5 pr-5 required-field">Meeting Type</th>
          <th className="text-center pl-5 pr-5 required-field">Mode</th>
          <th className="text-center required-field">HO Plan start Date</th>
          <th className="text-center required-field">HO Plan end Date</th>
          <th className="text-center required-field">Status</th>
          {!props.isDisabled ? <th className="text-center">Action</th> : null}
        </tr>
      </thead>
    </>
  );
};

const LESSONPLAN = (props) => {
  const getNameById = (id, list) => {
    const item =
      list &&
      list.filter((item) => {
        return item && item.id === id;
      });
    const name = item && item.length ? item[0].text : "Not Found";
    return name;
  };
  return (
    <>
      <tr key={props.index + "-class"}>
        <td className="text-center">
          {getNameById(props.el.chapterId, props.chapterData)}
        </td>
        <td className="text-center">
          {getNameById(props.el.topicId, props.topicData)}
        </td>
        <td className="text-center">
          {getNameById(props.el.subTopicId, props.subTopicData)}
        </td>
        <td className="text-center">
          {getNameById(props.el.subSubTopicId, props.subSubTopicData)}
        </td>
        <td className="text-center">{props.el.noOfHours}</td>
        <td className="text-center">{props.el.sequence}</td>
        <td className="text-center">
          {getNameById(props.el.meetingTypeId, props.meetingData)}
        </td>
        <td className="text-center">
          {getNameById(props.el.periodTypeId, props.testMode)}
        </td>
        <td className="text-center">
          {moment(props.el["hoPlanStartDate"]).format("DD-MM-YYYY")}
        </td>
        <td className="text-center">
          {moment(props.el["hoPlanEndDate"]).format("DD-MM-YYYY")}
        </td>
        <td className="text-center">
          {props.el.status == "INACTIVE" ? (
            <button
              disabled={true}
              type="button"
              className="btn btn-sm btn-warning"
            >
              Inactive
            </button>
          ) : (
            <button
              type="button"
              disabled={true}
              className="btn btn-sm btn-success"
            >
              Active
            </button>
          )}
        </td>
        <td className="text-center">
          <EditButton
            onClick={() => {
              props.editRow(props.index);
            }}
          />
          {/* <Button
						color="info"
						size="sm"
						type="button"
						onClick={() => {
							props.editRow(props.index);
						}}
					>
						{' '}
						<i className="fas fa-edit" />
					</Button> */}
          <Button
            color="info"
            size="sm"
            type="button"
            onClick={() => {
              props.deleteRow(props.index);
            }}
          >
            {" "}
            <i className="fas fa-trash" />
          </Button>
        </td>
      </tr>
    </>
  );
};

export {
  TDROW,
  MasterHeader,
  MasterHeaderCashier,
  MasterHeaderPineLabPaymode,
  MasterHeaderBankAccount,
  TDROW2,
  PAYMODEPINELABROW,
  MasterHeader2,
  MasterHeader3,
  TDROW3,
  TERMTDROW,
  COURSETDROW,
  PROGRAMACTION,
  TermSessionHeader,
  TermMasterHeader,
  TERMSESSIONROW,
  CourseAttrSessionHeader,
  COURSEATTRSESSIONTABLE,
  PricingZoneHeader,
  PRICINGZONETABLE,
  ProgramReasonSessionHeader,
  ACTIONREASONSESSIONTABLE,
  ExternalOrganizationHeader,
  SeatingCapacityOrganizationHeader,
  AffiliationTableHeader,
  AffiliationTableTDRow,
  SeatingCapacityTableTDRow,
  SeatingCapacityThrow,
  ExternalOrgSearchListHeader,
  OrganizationTableThrow,
  ItemTypeSetup,
  TDROWITEMTYPESETUP,
  CatalogAssociatedAttributeHeader,
  THROWASSOCIATEDATTRIBUTE,
  CourseDetailsTableHeader,
  COURSETABLETHROW,
  CourseCatalogDetails,
  TaxCodeIdMasterHeader,
  HouseBankHeader,
  HOUSEBANKTHROW,
  TaxCodeComponent,
  BusinessAreaDetails,
  BusinessAreaSearchHeader,
  TaxItemType,
  TaxItemTypeRow,
  TaxComponentRow,
  TaxReceiptHeader,
  TaxRefundHeader,
  CommissionFranchisesOnlyHeader,
  TAXREFUNDTHROW,
  TAXRECEIPTSTHROW,
  COMMISSIONFRANCHISESONLYTHROW,
  THIRDPARTYSYSTEMTDROW,
  BusinessAreaPlanHeader,
  BUSINESSAREAPLANTHROW,
  ProfitCostCenterHeader,
  PROFITCOSTCENTERTHROW,
  OtherSystemHeader,
  OTHERSYSTEMTHROW,
  ProfitHeader,
  PROFITCENTERMAPPINGTHROW,
  GLCODETHROW,
  GLCodeHeader,
  RECEIPTLABELTDROW,
  SetupDatesHeader,
  SetupDateRow,
  InvoiceNumberSettingHeader,
  INVOICENUMBERSETTING,
  ReceiptNumberSettingHeader,
  RECEIPTNUMBERSETTING,
  RefundNumberSettingHeader,
  REFUNDNUMBERSETTING,
  NonTaxableReceiptRefundNoSettingHeader,
  NONTAXABLERECEIPTREFUNDNOSETTING,
  ScholarshipTransactionSetupHeader,
  SCHOLARSHIPTRANSACSETUP,
  ControlPaymodeFunctionHeader,
  CONTROLPAYMODEFUNCTIONTHROW,
  SetupInstallmentsHeader,
  SetupBuAndCourseSecuiryHeader,
  CourseForBUAndSecurityHeader,
  COURSEFORBUSECURITYTHROW,
  SUBSUBCATEGORYTHROW,
  DocumentManagementHeader,
  BankCodeHeader,
  BANKCODETHROW,
  FollowUpHeader,
  FollowUpData,
  MasterHeaderLessonPlan,
  LESSONPLAN,
  AdjustmentFlagData,
  BankAccountHeaders,
  BankAccountDataRow,
};
