import { endpoint } from "../../../../common/constant"
import { dropdownMapping, updateRequest } from "../../../../common/utils/methods/commonMethods/utilityMethod"
import { dropdownMapping as dropdownMapping2 } from "../../../constant/batch-search"

export const newRow = {
  courseId: '',
  materials: [],
  businessAreas: [],
  status: 'ACTIVE',
}

export const getDropdowns = async () => {
  const p = await Promise.all([
    dropdownMapping(endpoint?.businessArea?.getAllActive, 'businessAreaDispValue', 'id'),
    dropdownMapping2(`${endpoint?.courseAttribute?.getMiscCourses}`, 'courseIds')
  ])

  return {
    businessArea: p[0],
    courseId: p[1],
  }
}

export const restructureGetAllRes = (data, dropdown) => {
  return data.map(item => ({
    ...item,
    courseId: { label: item?.courseId, value: item?.courseId },
    courseDescription: item?.courseDescription,
    businessAreas: item?.businessAreaMappingList?.map(item => ({ label: item?.businessAreaDispValue, value: +item?.businessArea })),
    materials: item?.materialMappingList?.map(item => ({
      ...item,
      label: `${item?.materialNumber} | ${item?.materialDescription}`,
      value: item?.materialNumber,
      materialDescription: item?.materialDescription,
    })),
    status: item?.status,
    updatedBy: item?.updatedBy,
    updatedOn: item?.updatedOn,
  }));
}

export const getCreateReqBody = (rowData) => {
  return updateRequest({
    courseId: rowData?.courseId,
    status: rowData?.status,
    businessAreas: rowData?.businessAreas?.map(item => ({ businessArea: item?.value, businessAreaDispValue: item?.label })),
    materials: rowData?.materials?.map(item => ({ materialNumber: item?.value, materialDescription: item?.materialDescription, serialNumberProfile: item?.serialNumberProfile })),
  })
}

export const createSuccessMsg = 'Misc. course ID item mapped successfully.';
// export const updateSuccessMsg = 'Misc. course ID item mapped successfully.';
// export const deleteSuccessMsg = 'Misc. course ID item mapped successfully.';