import React from "react";
import { Dialog } from "@mui/material";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import { ReactComponent as Warning } from "assets/img/svg/yellowWarning.svg";
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'
import { AGridColDef } from "../../../../common/data-grid/ADataGridColDef";
import ADataGrid from "../../../../common/data-grid/ADataGrid";
import {
  gridStyles,
  gridComponents,
} from "views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant.js";
import CustomChip from "views/pages/manage/common/customChip/CustomChip.jsx";
import AButton from "../../../../common/form-fields-mui/AButton";
import { getChipBgColor, getChipTxtColor } from "../constant";
import "../styles.module.scss";

const StockAlertModal = ({
  open,
  setOpen,
  selectedMaterials,
  getMaterialCount,
  isStockAlert = false,
}) => {
  const handleClose = () => setOpen(false);

  const columns = [
    new AGridColDef("value", "Material description", false).setFlex(1.5),
    new AGridColDef("materialId", "Quantity left", false)
      .setMinWidth(90)
      .setFlex(0.8)
      .columnAlign("center")
      .renderCellComponent((params) => (
        <CustomChip
          bgColor={getChipBgColor(getMaterialCount(params?.value))}
          textColor={getChipTxtColor(getMaterialCount(params?.value))}
          text={`${getMaterialCount(params?.value)} Left`}
        />
      )),
  ];

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{ sx: { width: "100%", maxWidth: "650px !important" } }}
    >
      <div style={{ padding: "28px 24px" }}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="stock-alert-icon flex-grow-1 heading-4">
            <Warning width = "35px" /> Insufficient material quantity!
          </div>
          <IconButtonWrapper onClick={handleClose}><CrossIcon width={24} height={24} /></IconButtonWrapper>
        </div>
        {isStockAlert && (
          <div className="large-semi-bold color-dark">
            Following material can not be issued further.
          </div>
        )}
        <div className="mt-3">
          <ADataGrid
            removeWrapperContainer
            rows={selectedMaterials || []}
            columns={columns}
            rowId={(row) => row.id}
            rowHeight={50}
            loading={false}
            serverPagination={false}
            autoHeight
            hidePagination
            sx={gridStyles}
          />
        </div>
        <AButton
          className="mt-3 btn-left-0"
          variant="primary_filled"
          onClick={() => handleClose()}
        >
          Done
        </AButton>
      </div>
    </Dialog>
  );
};

export default StockAlertModal;
