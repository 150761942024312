import React ,{useState} from "react";
import { useSelector } from "react-redux";
import APopover from "../../common/pop-hover/APophover";
import LeftSideFilterForm from "./LeftSideFilterForm";
import DisplayRegion from "../batch-serach/DisplayRegion";
import {
  getDisplayValue,
} from "../constant/batch-search";



const LeftSideFilter = ({ filterForm, inputHandler, searchHandler }) => {

  const studentDetails = useSelector((state) => state.studentSearch);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLabel = (count)=> count>1?"Students":"Student" ;
  return (
    <div style={{ position: "relative", minWidth: "4rem" }}>
      <h4 style={{color:"black"}}> {studentDetails && studentDetails.rowCount} {getLabel(studentDetails && studentDetails.rowCount)}</h4>
      <div>
        <DisplayRegion
          items={(
            filterForm.region
          )}
          type="Regions"
        />
        <span>
          |
          <DisplayRegion
            items={(filterForm.businessArea)}
            type="Business Areas"
          />
        </span>
        <span
          onClick={handleClick}
          aria-describedby={"demo"}
          style={{
          color: " #00B0F5",
          cursor:"pointer",
        }}
        >
          View/Edit
        </span>
      </div>

      <APopover handleClose={handleClose} id="info-batch" anchorEl={anchorEl}>
        <LeftSideFilterForm
          filterForm={filterForm}
          inputHandler={inputHandler}
          searchHandler={() => {
            searchHandler();
            handleClose();
          }}
          clrBusinessAreaHandler = {()=>inputHandler([],"businessArea")}
        />
      </APopover>
    </div>
  );
};

export default LeftSideFilter;
