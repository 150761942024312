import React, { useState } from "react";
import { useHistory } from "react-router";
import EmptyListPlaceholder from "./EmptyListPlaceholder";
import { Tooltip } from "@mui/material";
import ALoader from "views/pages/manage/common/a-loader";
import { gridComponents } from "views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant";
import { gridStyles } from "views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant";
import { AGridColDef } from "views/pages/manage/common/data-grid/ADataGridColDef";
import ADataGrid from "views/pages/manage/common/data-grid/ADataGrid";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import CustomPaginationWithoutCount from "components/CustomPaginationWithoutCount/CustomPaginationWithoutCount";
import moment from "moment";
import { CourseDetailsPopupById } from "views/pages/manage/unAssigned/studentCharges/common";


const StudentList = ({
  userPermissions,
  rowsData,
  rowCount,
  nextButtonHandler,
  currentPage,
  isLastPage,
  businessAreaMap
}) => {
  const history = useHistory();

  const BatchListHeader = [
    new AGridColDef("psid", "PSID",false)
    .setMinWidth(120)
    .setFlex(0.5)
    .renderCellComponent((params) => (
      <span
        onClick={() => window.open(history.createHref({ pathname: `/admin/edp/studentDetails/${params.value}` }), '_blank')}
        style={{ color: "#00B0F5", fontSize: "14px", cursor: "pointer" }}
      >
        {params.value}
      </span>
    )),
    new AGridColDef("applicationId", "App. ID",false)
    .setMinWidth(100)
    .setFlex(0.5)
    .renderCellComponent((params) => <span>{params?.value}</span>),,
    new AGridColDef("studentName", "Student Name",false)
    .setMinWidth(150)
    .setFlex(0.5)
    .renderCellComponent((params) => <span>{params?.value}</span>),,    
    new AGridColDef("businessAreaDispVal", "BU",false)
    .setMinWidth(90)
    .setFlex(0.5)
    .renderCellComponent((params=>
      <span>{params.value}</span> 
       )),
    new AGridColDef("courseId", "Course ID",false)
    .setMinWidth(100)
    .setFlex(0.5)
    .renderCellComponent((params) => <><span style={{marginRight:"2px"}}>{params?.value}</span>  <CourseDetailsPopupById courseId={params?.value} placement='bottom' maxWidth='250px'/></>),
    new AGridColDef("term", "Term",false)
    .setMinWidth(80)
    .setFlex(0.5)
    .renderCellComponent((params=>
      <span>{params?.value?.dispValue}</span> 
       )),
       new AGridColDef("registrationDate", "Registration Date",false)
       .setMinWidth(130)
       .renderCellComponent((params=>
        <span>{params?.value?moment(params?.value).format('DD-MM-YYYY'):'-'}</span> 
         )),
       new AGridColDef("chargesStatus", "Latest Charge Status",false)
       .setMinWidth(120)
       .setFlex(0.5)
       .renderCellComponent((params=>
        <span>{params?.value ? params?.value:'-'}</span> 
         )),
       ,
    new AGridColDef("programAction", "Program Action",false)
    .setMinWidth(120)
    .setFlex(0.5)
    .renderCellComponent((params)=>(
      params?.value?params?.value:'-'
    )),
    new AGridColDef("Actions", "Action", false)
      .setMinWidth(100)
      .setFlex(0.5)
      .renderCellComponent((params) => (
       < AButton
        onClick={() => {}}
        size="xs"
        variant="primary_filled"
        className="btn-left-0"
      >
        Select
      </AButton>
      )),
  ];

  return (
    <>
     
      <ADataGrid
        rows={rowsData}
        columns={BatchListHeader}
        rowId={row => JSON.stringify(row)}
        loading={rowsData.length === 0}
        emptyRowPlaceholder={() => <EmptyListPlaceholder />}
        sortColumnName='psid'
        components={{
          ...gridComponents,
        }}
        sx={{
          ...gridStyles,
        }}
        hidePagination={true}
      />
      <CustomPaginationWithoutCount
        currentPage={currentPage}
        onPageChange={nextButtonHandler}
        isLastPage={isLastPage}
        noOfCurrentPageRecords={rowsData.length}
      />
    </>
  );
};

export default StudentList;
