import React, { useEffect, useState } from "react";
import NoBatchesCard from "./batchDetails/NoBatchesCard";
import TestDetailsAccordion from "./TestDetailsAccordion";
import { fetchAllPromisedData } from "../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "../../common/constant";
import { useParams } from "react-router";
import CustomLoader from "../../common/commonComponents/Loader/loader";

const Results = () => {
  const { psid } = useParams();

  const [expandedResultAccordion, setExpandedResultAccordion] = useState(false);
  const [testResults, setTestResults] = useState([]);
  const [apiLoader, setApiLoader] = useState(false);

  const handleResultAccordionExpansionChange =
    index => (event, newExpanded) => {
      setExpandedResultAccordion(newExpanded ? index : false);
    };

  const fetchTestResults = async () => {
    setApiLoader(true);
    try {
      const resp = await fetchAllPromisedData(
        `${endpoint.getScoreByPsidExam}?psid=${psid}`,
        true
      );
      if (resp?.code === 200) {
        setTestResults(resp?.data);
      }
      setApiLoader(false);
    } catch (err) {
      setApiLoader(false);
      console.log(err);
    }
  };

  useEffect(() => {
    // console.log('Results mounted')
    fetchTestResults();

    return () => {
      // console.log('Results unmounted')
    };
  }, [psid]);

  return apiLoader ? (
    <CustomLoader />
  ) : testResults?.length ? (
    testResults?.map((el, idx) => (
      <TestDetailsAccordion
        key={idx}
        testData={el}
        idx={idx}
        expandedResultAccordion={expandedResultAccordion}
        handleChange={handleResultAccordionExpansionChange}
      />
    ))
  ) : (
    <NoBatchesCard message={"No Test results found for this student"} />
  );
};

export default Results;
