import React from 'react';
import {
  Card,
  CardHeader,
  Input,
  Container,
  Button,
  Table,
  Row,
} from 'reactstrap';
import { useState } from 'react';
import { successToast, failureToast } from '../../common/utils/methods/toasterFunctions/function';
import { TDROW, MasterHeader } from '../../common/commonComponents/masterRow/masterRow';
import { masterServiceBaseUrl, putpost, putpost2 } from 'services/http';
import moment from 'moment';
import { ButtonText } from "variables/Buttons"
import CustomButton from "../../../../../components/CustomButton";
import CommonInput from "../../common/formFeilds/input/commonInput";
import CustomToggle from "../../common/formFeilds/customToggle";

var _ = require('lodash');

const SubjectAreaTable = (props) => {
  const { permissionSet, isSaveVisible, setisSaveVisible = () => { } } = props;
  const [tableDataKey, settableDataKey] = useState('tableDataKey');
  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: '__new',
        subjectAreaKey: null,
        status: 'ACTIVE',
        subjectAreaDesc: null,
        subjectAreaDispValue: null,
        effectiveDate: null,
      },
      ...props.classArray,
    ]);
  };
  const saveAll = (cb) => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if (el['editMode']) {
        if (el['subjectAreaKey'] == null || !el['subjectAreaKey'].trim()) {
          isValid = false;
          failureToast('Please enter the value of Subject Area Key');
          return;
        } else if (
          el['subjectAreaDesc'] == null ||
          !el['subjectAreaDesc'].trim()
        ) {
          isValid = false;
          failureToast('Please enter the value of Description');
          return;
        } else if (
          el['subjectAreaDispValue'] == null ||
          !el['subjectAreaDispValue'].trim()
        ) {
          isValid = false;
          failureToast('Please enter the value of Display Value');
          return;
        }
        if (!isValid) {
          return;
        }
        if (_tempArray[index]['__status'] == '__new') {
          putpost(
            masterServiceBaseUrl + '/subjectArea/createSubjectArea',
            (data) => {
              successToast(data['message']);
              setisSaveVisible(false);
              props.fetchClasses(masterServiceBaseUrl + '/subjectArea/getAllSubjectArea');
            },
            (data) => {
              failureToast(data['message']);
            },
            _tempArray[index],
            'post'
          );
        } else {
          putpost(
            masterServiceBaseUrl + '/subjectArea/updateSubjectArea/' + _tempArray[index].id,
            (data) => {
              successToast(data['message']);
              setisSaveVisible(false);
              delete _tempArray[index]['editMode'];
              props.setclassArray(_tempArray);
              settableDataKey(new Date().getMilliseconds());
              // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
            },
            (data) => {
              failureToast(data['message']);
            },
            _tempArray[index],
            'put'
          );
        }
      } else {
      }
    });
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
        // settableDataKey(new Date().getMilliseconds())
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      cb();
    }
  };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]['editMode'] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };
  const deleteRow = (index) => {
    if (props.classArray[index]['__status'] == '__new') {
      let n = props.classArray;
      n.splice(index, 1);
      props.setclassArray(n);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
    } else {
      if (
        window.confirm('Are you sure you want to delete this Subject Area?')
      ) {
        putpost2(
          masterServiceBaseUrl +
          '/subjectArea/deleteSubjectArea/' +
          props.classArray[index].id,
          (data) => {
            successToast(data['message']);
            if (props.classArray.length == 1) {
              props.previousPage();
              props.fetchClasses(masterServiceBaseUrl + '/subjectArea/getAllSubjectArea');
            } else {
              var _tempArray = props.classArray;
              if (_tempArray[index]['editMode']) {
                setisSaveVisible(false);
              }
              _tempArray.splice(index, 1);
              props.setclassArray(_tempArray);
            }
            settableDataKey(new Date().getMilliseconds());
          },
          (data) => {
            failureToast(data['message']);
          },
          'PUT'
        );
      }
    }
  };
  const updateKey = (index, value, key) => {
    var _tempArray = [...props.classArray];
    _tempArray[index][key] = value;
    props.setclassArray([..._tempArray]);
  };
  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Subject Area</h3>
                {/*<CreateButton*/}
                {/*    text={ButtonText.SubjectArea.addNew}*/}
                {/*    disabled={isSaveVisible}*/}
                {/*    className={"floatRight mx-1"+(isSaveVisible?" btn-dark":null)} */}
                {/*    onClick={() => {newRow()}}*/}
                {/*    data-testid="addNewRow"*/}
                {/*  />*/}
                <CustomButton
                  data-testId="addNewRow"
                  disabled={isSaveVisible}
                  className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
                  content={ButtonText.SubjectArea.addNew}
                  permissionType={'C'}
                  icon={true}
                  permissionSet={permissionSet}
                  onClick={() => newRow()}
                />
                {/* {isSaveVisible?<Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => {saveAll()}}>Save</Button>:null} */}
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout">
                  <MasterHeader type={'Subject Area'} isSaveVisible={isSaveVisible} permissionSet={permissionSet} />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                        !el['editMode'] ? (
                          <TDROW
                            type={'subjectArea'}
                            index={index}
                            el={el}
                            editRow={editRow}
                            deleteRow={deleteRow}
                            isSaveVisible={isSaveVisible}
                            permissionSet={permissionSet}
                          />
                        ) : (
                          <tr key={index + '-class'}>
                            <td className="text-center p-2 mx-1">
                              <CommonInput
                                data-testid="subjectAreaKey"
                                forTable={true}
                                type={'text'}
                                maxLength={50}
                                placeHolder={'Example:1'}
                                defaultValue={el['subjectAreaKey']}
                                onChange={(value) => { updateKey(index, value, 'subjectAreaKey'); }}
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <CommonInput
                                data-testid="effectiveDate"
                                forTable={true}
                                type={'text'}
                                isDisabled={true}
                                placeHolder={'Example:01-01-2020'}
                                defaultValue={el['__status'] === '__new' ? null : moment(el['effectiveDate']).format('DD-MM-YYYY')}
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <CommonInput
                                data-testid="subjectAreaDesc"
                                forTable={true}
                                type={'text'}
                                maxLength={50}
                                placeHolder={'Description'}
                                defaultValue={el['subjectAreaDesc']}
                                onChange={(value) => { updateKey(index, value, 'subjectAreaDesc'); }}
                              />
                              {/*<Input*/}
                              {/*  data-testid="subjectAreaDesc"*/}
                              {/*  maxLength={50}*/}
                              {/*  defaultValue={el['subjectAreaDesc']}*/}
                              {/*  onChange={(e) => {*/}
                              {/*    updateKey(*/}
                              {/*      index,*/}
                              {/*      e.target.value,*/}
                              {/*      'subjectAreaDesc'*/}
                              {/*    );*/}
                              {/*  }}*/}
                              {/*  placeholder="Description"*/}
                              {/*  className="tdInput"*/}
                              {/*/>*/}
                            </td>
                            <td className="text-center p-2 mx-1">
                              <CommonInput
                                data-testid="subjectAreaDispValue"
                                forTable={true}
                                type={'text'}
                                maxLength={50}
                                placeHolder={'Display Value'}
                                defaultValue={el['subjectAreaDispValue']}
                                onChange={(value) => { updateKey(index, value, 'subjectAreaDispValue'); }}
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <CustomToggle
                                status={el.status}
                                data-testid="activeInactiveInput"
                                toggleOff={'Inactive'}
                                toggleOn={'Active'}
                                id={el.id}
                                onChangeHandler={(e) => {
                                  settableDataKey(
                                    new Date().getMilliseconds()
                                  );
                                  let n = 'ACTIVE';
                                  if (!e.target.checked) {
                                    n = 'INACTIVE';
                                  }
                                  updateKey(index, n, 'status');
                                }}
                              />
                            </td>
                            <td className="text-center">
                              {/* <Button color="info" size="sm" type="button" onClick={() => {
                          deleteRow(index)
                        }}>  <i className="fas fa-trash" /></Button> */}
                              {isSaveVisible ? (
                                <>
                                  {' '}
                                  {/*<UpdateButton text={ButtonText.SubjectArea.save} onClick={() => { saveAll() }} data-testid="saveBtn"/>*/}
                                  <CustomButton
                                    data-testId="saveBtn"
                                    className={'mx-1'}
                                    content={ButtonText.SubjectArea.save}
                                    permissionType={'C,U'}
                                    forTable={true}
                                    permissionSet={permissionSet}
                                    onClick={() => saveAll()}
                                  />
                                  {/*<Button*/}
                                  {/*  data-testid="closeBtn"*/}
                                  {/*  color="danger"*/}
                                  {/*  size="sm"*/}
                                  {/*  type="button"*/}
                                  {/*  onClick={() => {*/}
                                  {/*    let n = props.classArray;*/}
                                  {/*    if (n[index]['__status'] == '__new') {*/}
                                  {/*      n.splice(index, 1);*/}
                                  {/*    } else {*/}
                                  {/*      // n[index]['editMode']=false*/}
                                  {/*      props.fetchClasses(*/}
                                  {/*        masterServiceBaseUrl +*/}
                                  {/*          '/subjectArea/getAllSubjectArea'*/}
                                  {/*      );*/}
                                  {/*    }*/}
                                  {/*    props.setclassArray(n);*/}
                                  {/*    setisSaveVisible(false);*/}
                                  {/*  }}*/}
                                  {/*>*/}
                                  {/*  {' '}*/}
                                  {/*  <i className="fas fa-times" />*/}
                                  {/*</Button>{' '}*/}
                                  <CustomButton
                                    data-testId="closeBtn"
                                    className={'mx-1'}
                                    permissionType={'cancel'}
                                    forTable={true}
                                    icon={true}
                                    permissionSet={permissionSet}
                                    onClick={() => {
                                      let n = props.classArray;
                                      if (n[index]['__status'] == '__new') {
                                        n.splice(index, 1);
                                      } else {
                                        // n[index]['editMode']=false
                                        props.fetchClasses(
                                          masterServiceBaseUrl +
                                          '/subjectArea/getAllSubjectArea'
                                        );
                                      }
                                      props.setclassArray(n);
                                      setisSaveVisible(false);
                                    }}
                                  />
                                </>
                              ) : (
                                <Button
                                  color="info"
                                  size="sm"
                                  type="button"
                                  onClick={() => {
                                    deleteRow(index);
                                  }}
                                >
                                  {' '}
                                  <i className="fas fa-trash" />
                                </Button>
                              )}
                            </td>
                          </tr>
                        )
                      )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default SubjectAreaTable;
