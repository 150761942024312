import React from "react";
import { ReactComponent as ViewIcon } from "../../../studentCharges/studentChargesDetails/feeBreakup/eye.svg";
import { HtmlTooltip } from "../../../studentCharges/studentChargesDetails/feeBreakup";

const taxTypes = ["cgst", "sgst", "igst", "utgst"];

const TaxTooltip = ({ otherChargesRow , tax}) => {
  return (
    <HtmlTooltip
      placement="bottom"
      arrow
      title={
        <React.Fragment>
          {taxTypes.map((tax, i) => {
            return otherChargesRow[tax] ? (
              <div
                key={tax}
                style={{ padding: "10px" }}
                className="d-flex flex-row"
              >
                <div style={{ width: 70 }}>{tax.toLocaleUpperCase()}</div>
                <span>{` :  ₹${parseFloat(otherChargesRow[tax]).toFixed(
                  2
                )}`}</span>
              </div>
            ) : null;
          })}
        </React.Fragment>
      }
    >
     {tax>0 ? <span className="pl-1">
        <ViewIcon style={{ cursor: "pointer" }} />
      </span> : <span></span>}
    </HtmlTooltip>
  );
};

export default TaxTooltip;
