import React from 'react'
import { ReactComponent as Folder } from '../../../../../assets/img/svg/Folder2.svg';

const NoRowOverlay = () => {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center' style={{ width: '100%', height: '100%' }}>
      <Folder className='mb-2' />
      <span >No data found</span>
    </div>
  )
}

export default NoRowOverlay