import React from 'react'
import AAccordion from '../../../common/a-accordion/AAccordion'
import styles from '../styles.module.scss'
import CustomCopyToClipboard from '../../../common/ACopyToClipboard/CustomCopyToClipboard'
import { Tooltip } from '@mui/material'
import CustomLoader from '../../../common/commonComponents/Loader/loader';



const StudentDetails = ({ applicationDetails, loader, doInformation }) => {

  const { businessAreaValue, psid, studentName, termValue, courseId, courseDetails, applicationId, applicationQuotationDetailsId } = applicationDetails;
  const { loanTransactionId } = doInformation;

  const detailsMap = [
    { header: 'Business area', content: <div>{businessAreaValue || '-'}</div> },
    { header: 'PSID', content: <div className='d-flex'>{psid || '-'} {psid && <CustomCopyToClipboard textToCopy={psid} />}</div> },
    { header: 'Name', content: <div className='ellipsis-text-container'>{studentName || '-'}</div> },
    { header: 'Term', content: <div>{termValue || '-'}</div> },
    { header: 'Course ID', content: (
      <Tooltip title={courseDetails?.courseName}>
        <div className='ellipsis-text-container'>{courseId} | {courseDetails?.courseName}</div>
      </Tooltip> 
    )
    },
    { header: 'Application no.', content: <div className='d-flex'>{applicationId || '-'}{applicationId && <CustomCopyToClipboard textToCopy={applicationId} />}</div> },
    { header: 'Quotation ID', content: <div>{applicationQuotationDetailsId || '-'}</div> },
    { header: 'Loan transaction ID', content: <div>{loanTransactionId || '-'}</div> },
  ]

  const Summary = (
    <div className='heading-4 color-dark'>
      Student details
    </div>
  )

  const Details = (
    loader ? <CustomLoader /> : (
      <div className={`${styles['student-details-grid']}`}>
        {detailsMap.map(item => (
          <div className='d-flex flex-column'>
            <div className='label-color regular-small'>{item.header}</div>
            <div className='large-semi-bold color-dark'>{item.content}</div>
          </div>
        ))}
      </div>
    )
  )

  return (
    <AAccordion
      // initialOpen
      summary={Summary}
      details={Details}
    />
  )
}

export default StudentDetails