import React, { useEffect, useState } from "react";
import { Dialog, Tooltip } from "@mui/material";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import ALoader from "views/pages/manage/common/a-loader";
import ADataGrid from "../../../common/data-grid/ADataGrid";
import { AGridColDef } from "../../../common/data-grid/ADataGridColDef";
import AButton from "../../../common/form-fields-mui/AButton";
import NoRowOverlay from "../../../masters/holidayMaster/NoRowOverlay";
import {
  gridStyles,
  gridComponents,
} from "views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant.js";
import ReturnDisabledAlertBadge from "./ReturnDisabledAlertBadge";
import { useHistory } from "react-router";
import { fetchAllPromisedData } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "../../../common/constant";
import CourseDetailsWithEndDate from "./CouseDetailsWithEndDate";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx";

const ActionsCellComponent = ({
  params,
  setShowAlert,
  setApiLoader,
  checkedOption,
  academicCareer,
}) => {
  const history = useHistory();
  const {
    businessArea,
    businessAreaValue,
    transactionId,
    applicationId,
    sapPlantId,
    courseId,
  } = params?.row;
  const commonUrl = `?academicCareer=${academicCareer}&businessArea=${businessArea}&businessAreaDispValue=${businessAreaValue}&plantId=${sapPlantId}&applicationId=${applicationId}`;
  const urlSearchQuery =
    checkedOption === "transactionId"
      ? `${commonUrl}&transactionId=${transactionId}`
      : commonUrl;

  const [isDisabled, setIsDisabled] = useState(false);

  const checkEligibility = async (applicationId, courseId) => {
    const res = await fetchAllPromisedData(
      `${endpoint?.studentSearch?.checkStockReturnEligibility}/${applicationId}?issuanceCourseId=${courseId}`,
      true
    );
    if (res?.code === 200) {
      return {
        isEligible: res?.data?.isEligibleForStockReturn,
        message: res?.message,
      };
    }
    return false;
  };
  const viewTxnDetailsClickHandler = async () => {
    setApiLoader(true);
    const response = await checkEligibility(applicationId, courseId);
    setApiLoader(false);
    if (response.isEligible) {
      window.open(
        history.createHref({
          pathname: `/admin/edp/stockManagement/stockReturn${urlSearchQuery}`,
        }),
        "_blank"
      );
    } else {
      setIsDisabled(true);
      setShowAlert(response.message);
    }
  };

  return (
    <AButton
      variant="primary_filled"
      size="xs"
      className="btn-left-0"
      disabled={isDisabled}
      onClick={viewTxnDetailsClickHandler}
    >
      View transaction details
    </AButton>
  );
};

const ApplicationTableDialog = ({
  open,
  setOpen,
  loader,
  currentPage,
  rows,
  rowCount,
  onPageChange,
  checkedOption,
  academicCareer,
}) => {
  const handleClose = () => setOpen(false);
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    setShowAlert(false);
  }, [rows]);

  const [apiLoader, setApiLoader] = useState(false);

  const columns = [
    new AGridColDef("psid", "PSID", false)
      .setMinWidth(110)
      .renderCellComponent((params) => (
        <Tooltip title={params?.value}>
          <div className="ellipsis-text-container">{params?.value}</div>
        </Tooltip>
      )),
    new AGridColDef("applicationId", "Application no.", false)
      .setMinWidth(120)
      .renderCellComponent((params) => (
        <Tooltip title={params?.value}>
          <div className="ellipsis-text-container">{params?.value}</div>
        </Tooltip>
      )),
    new AGridColDef("studentName", "Student name", false)
      .setMinWidth(150)
      .renderCellComponent((params) => (
        <Tooltip title={params?.value}>
          <div className="ellipsis-text-container">{params?.value}</div>
        </Tooltip>
      )),
    new AGridColDef("programAction", "Program plan", false)
      .setMinWidth(120)
      .setFlex(0.6)
      .renderCellComponent((params) => (
        <Tooltip title={params?.value}>
          <div className="ellipsis-text-container">{params?.value}</div>
        </Tooltip>
      )),
    new AGridColDef("termValue", "Term", false)
      .setMinWidth(60)
      .setFlex(0.6)
      .renderCellComponent((params) => (
        <Tooltip title={params?.value}>
          <div className="ellipsis-text-container">{params?.value}</div>
        </Tooltip>
      )),
    new AGridColDef("courseId", "Course ID", false)
      .setMinWidth(100)
      .setFlex(0.6)
      .renderCellComponent((params) => (
        <div className="d-flex align-items-center">
          {params?.value}
          {/* <CourseDetails
            className='color-primary'
            courseId={params?.row?.courseId}
            applicationId={params?.row?.applicationId}
          /> */}
          <CourseDetailsWithEndDate rowData={params?.row} />
        </div>
      )),
    new AGridColDef("actions", "Actions", false)
      .setMinWidth(210)
      .columnAlign("right")
      .setFlex(0.6)
      .renderCellComponent((params) => (
        <ActionsCellComponent
          params={params}
          setShowAlert={setShowAlert}
          setApiLoader={setApiLoader}
          checkedOption={checkedOption}
          academicCareer={academicCareer}
        />
      )),
  ];

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{ sx: { maxWidth: "unset", borderRadius: "12px" } }}
    >
      {(loader || apiLoader) && <ALoader />}
      <div style={{ padding: "28px 24px", width: "920px" }}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="heading-4 color-black">
            Confirm application ID to proceed
          </div>
          <IconButtonWrapper onClick={handleClose}>
            <CrossIcon width={24} height={24} />
          </IconButtonWrapper>
        </div>

        <div className="mt-4">
          <ADataGrid
            removeWrapperContainer
            rows={rows || []}
            columns={columns}
            rowId={(row) => JSON.stringify(row)}
            rowHeight={50}
            loading={false}
            rowCount={rowCount}
            nextButtonHandler={onPageChange}
            currentPage={currentPage}
            components={{
              ...gridComponents,
              NoRowsOverlay: () => <NoRowOverlay />,
            }}
            hidePagination={rowCount <= 50 ? true : false}
            sx={{
              ".MuiDataGrid-main": {
                height: `${60 + rows?.length * 50}px`,
                minHeight: `${110}px`,
                maxHeight: "calc(100vh - 300px)",
              },
              ...gridStyles,
            }}
          />
        </div>

        {showAlert && <ReturnDisabledAlertBadge msg={showAlert} />}
      </div>
    </Dialog>
  );
};

export default ApplicationTableDialog;
