import React, {useState, useEffect, Fragment} from 'react';
import { RiUploadCloudFill } from "react-icons/ri";
import Header from './searchComponent/header';
import RFIdTable from './searchComponent/rfIdTable';
import {Container, Card, CardHeader, CardBody, Button} from 'reactstrap';
import {GetPagination} from "../../common/commonComponents/pagination/pagination";
import {failureToast, getAPI, masterServiceBaseUrl, putpost, successToast} from "services/http";
import CoreMaster from "../../common/commonComponents/coreMaster";
import DocumentUpload from "./rfIdUpload";
import { GetTemplate } from '../../common/commonComponents';
import { endpoint } from '../../common/constant';

const RfIdMaster = () => {

    const [rfIdArray, setRfIdArray] = useState([]);
    const [apiloader, setApiloader] = useState(false);
    const [state, setstate] = useState([]);
    const [isSaveVisible, setIsSaveVisible] = useState(false);
    const [groupCode, setGroupCode] = useState([]);
    const [companyCode, setCompanyCode] = useState([]);
    const [zone, setZone] = useState([]);
    const [region, setRegion] = useState([]);
    const [businessArea, setBusinessArea] = useState([]);
    const [academicYear, setAcademicYear] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [isModalVisible, setModalVisible] = useState(false);
    const [searchValue, setSearchValue] = useState({});
    const [searchValueDisplay, setSearchValueDisplay] = useState({});
    const [pagination, setPagination] = useState({
        currentPage: 1,
        nextPage: null,
        previousPage: null,
        totalPage: null,
    });

    const pageSize = 10;
    const fetchDetails = async (url, type) => {
        try {
            setApiloader(true);
            getAPI(
                url,
                (data) => {
                    fetchDataHandler(data?.data, type);
                },
                (data) => {
                    failureToast(data['message']);
                    setApiloader(false);
                }
            );
        } catch (err) {
            console.log('Error :', err)
        }
    };


    const fetchDataHandler = (data, type) => {
        try {
            switch (type) {
                case 'RFID_DETAILS':
                    setstate(data);
                    data.length > 0 && setInitialPagination(data);
                    setShowTable(true)
                    break;
                case 'GROUP_CODE':
                    setGroupCode(data);
                    break;
                case 'COMPANY_CODE':
                    setCompanyCode(data);
                    break;
                case 'ZONE':
                    setZone(data);
                    break;
                case 'REGION':
                    setRegion(data);
                    break;
                case 'BUSINESS_AREA':
                    setBusinessArea(data)
                    break;
                case 'ACADEMIC_YEAR':
                    setAcademicYear(data);
                    break;
                default:
                    break;

            }
        } catch (e) {
            console.log('Error', e)
        }
    }

    const setInitialPagination = (data) => {
        let n = pagination;
        if (data.length > pageSize) {
            n['nextPage'] = n.currentPage + 1;
        }
        if (n.currentPage > 1) {
            n.previousPage = n.currentPage - 1;
        }
        n['totalPage'] = Math.floor(data.length / pageSize);
        if (data.length % pageSize != 0) {
            n['totalPage'] += 1;
        }
        setPagination(n);
        setRfIdArray(
            data.slice(
                pagination.currentPage * pageSize - pageSize,
                pagination.currentPage * pageSize
            ) || []
        );
        setApiloader(false);
    };

    const previousPage = () => {
        if (pagination.currentPage != 1) {
            setRfIdArray(
                state.slice(
                    (pagination.currentPage - 1) * pageSize - pageSize,
                    (pagination.currentPage - 1) * pageSize
                )
            );
            let n = pagination;
            n['currentPage'] = n['currentPage'] - 1;
            if (n['currentPage'] == 1) {
                n['previousPage'] = null;
            } else {
                n['previousPage'] = n.currentPage;
            }
            setPagination(n);
        }
    };

    const fetchSearchData = (searchValue) => {
        putpost(
            masterServiceBaseUrl +
            '/rfidMaster/searchRfidMaster',
            (data) => {
                successToast(data['message']);
                setIsSaveVisible(false);
                fetchDataHandler(data.data, 'RFID_DETAILS')
            },
            (data) => {
                failureToast(data['message']);
            },
            searchValue,
            'post'
        );
    }

    const findDisplayValue = (id, arr, property) => {
        try {
            const value = arr.find((item) => item.id == id);
            return value ? value[property] : '';
        } catch (e) {
            console.log('Error ', e);
        }
    };

    const searchHandler = () => {
        try {

            if (!searchValue.groupCodeId) {
                return failureToast('Please Select Group Code');
            } else if (!searchValue.companyCodeId) {
                return failureToast('Please Select Company Code');
            } else if (!searchValue.zoneId) {
                return failureToast('Please Select Zone');
            } else if (!searchValue.regionId) {
                return failureToast('Please Select Region');
            } else if (!searchValue.businessAreaId) {
                return failureToast('Please Select Business Area');
            } else if (!searchValue.academicYearId) {
                return failureToast('Please Select Academic Year');
            }
            setSearchValueDisplay({
                businessGroup: findDisplayValue(searchValue.groupCodeId, groupCode, 'groupCodeDispValue'),
                companyCode: findDisplayValue(searchValue.companyCodeId, companyCode, 'companyCodeDispValue'),
                zone: findDisplayValue(searchValue.zoneId, zone, 'operationalZoneDispValue'),
                region: findDisplayValue(searchValue.regionId, region, 'regionDispValue'),
                businessArea: findDisplayValue(searchValue.businessAreaId, businessArea, 'businessAreaDispValue'),
                academicYear: findDisplayValue(searchValue.academicYearId, academicYear, 'academicYearDisplayValue')
            })
            fetchSearchData(searchValue);

        } catch (err) {
            console.log('Error', err);
        }
    }

    useEffect(() => {
        fetchDetails(`${masterServiceBaseUrl}/groupCode/getAllGroupCode`, 'GROUP_CODE');
        fetchDetails(`${masterServiceBaseUrl}/companyCode/getAllCompanyCode`, 'COMPANY_CODE');
        fetchDetails(`${masterServiceBaseUrl}/operationalZone/getAllOperationalZone`, 'ZONE');
        fetchDetails(`${masterServiceBaseUrl}/region/getAllRegion`, 'REGION');
        fetchDetails(`${masterServiceBaseUrl}/businessArea/getAllBusinessArea`, 'BUSINESS_AREA');
        fetchDetails(`${masterServiceBaseUrl}/academicYear/all`, 'ACADEMIC_YEAR');
    }, []);

    return (
        <Container className={'mt-5'} fluid>
            <DocumentUpload
                isModalVisible={isModalVisible}
                setModalVisible={setModalVisible}
                getAllData={() => {
                    setShowTable(false);
                    setSearchValue({});
                }
                }
            />
            <Card>
                <CardHeader>
                    <div className={'d-flex justify-content-between'}>
                        <h2>RFID Master</h2>
                        <div className={'d-flex align-items-center'}>
                            <CoreMaster
                                master={'rfid'}
                                masterDisplayValue={'RFID'}
                            />
                             <GetTemplate url={endpoint.rfidMasterTemplate} isSaveVisible={isSaveVisible}/>
                            <Button color={'info'} size={'sm'} className={'mx-3'} onClick={() => setModalVisible(true)}>
                                <RiUploadCloudFill className="pr-1" size={18} />
                                Upload CSV
                            </Button>
                        </div>
                    </div>
                </CardHeader>
                <CardBody>
                    <Header
                        groupCode={groupCode}
                        companyCode={companyCode}
                        zone={zone}
                        region={region}
                        businessArea={businessArea}
                        academicYear={academicYear}
                        setSearchValue={setSearchValue}
                        searchValue={searchValue}
                        searchHandler={searchHandler}
                        showTable={showTable}
                        setShowTable={setShowTable}
                    />
                    {showTable ?
                        <Fragment>
                            <RFIdTable
                                state={state}
                                setModalVisible={setModalVisible}
                                previousPage={previousPage}
                                fetchDetails={fetchDetails}
                                setRfIdArray={setRfIdArray}
                                rfIdArray={rfIdArray}
                                isSaveVisible={isSaveVisible}
                                setIsSaveVisible={setIsSaveVisible}
                                searchValue={searchValue}
                                searchDisplayValue={searchValueDisplay}
                                fetchSearchData={fetchSearchData}
                            />
                            {pagination.totalPage > 1 ? (
                                <GetPagination
                                    isSaveVisible={isSaveVisible}
                                    fetchClasses={fetchDetails}
                                    setclassArray={setRfIdArray}
                                    pagination={pagination}
                                    setpagination={setPagination}
                                    pageSize={pageSize}
                                    state={state}
                                />
                            ) : null}
                        </Fragment> : null}
                </CardBody>
            </Card>
        </Container>
    )
}

export default RfIdMaster;
