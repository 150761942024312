import React from 'react';
import {Button, Card, Table} from "reactstrap";
import {getDispValForReactSelectByOneID} from "../../../common/utils/methods/commonMethods/utilityMethod";
import {masterServiceBaseUrl, putpost2} from "services/http";
import {failureToast,successToast} from "../../../common/utils/methods/toasterFunctions/function";
import {useHistory} from "react-router-dom";
import {MasterHeaderAuto} from "../../../common/commonComponents/masterHeader/masterHeader";
import {ButtonText} from "../../../../../../variables/Buttons";
import CustomButton from "../../../../../../components/CustomButton";
const CourseCatalogSequenceTable =(props)=>{
    const headerList =[{name:"BUSINESS GROUP"}, {name:"COMPANY CODE"}, {name:"ACADEMIC CAREER"},{name:"STATUS"}, {name:"ACTION"}]
    const {courseCatalogSequenceData,groupCode,companyCode,academicCareer,setCourseCatalogData,permissionSet} = props;
    const deleteRow =(index)=>{
        try {
            let id = courseCatalogSequenceData[index]['id']
            if (window.confirm('Are you sure you want to delete this Course Catelog?')) {
                putpost2(masterServiceBaseUrl + '/courseAttributeMapping/deleteCourseCatalogSequenceSetup/' + id, (data) => {
                    successToast(data.message);
                    setCourseCatalogData([])
                }, (data) => {
                    failureToast(data.message);
                }, 'Delete');
            }
        }catch(e){
            console.log('Error in deleteRow-->',e);
        }
    }
    const TableData =(props)=>{
        const {item,index} = props;
        return (
            <tr>
                <td className="text-center p-2 mx-1">{getDispValForReactSelectByOneID(item.groupCodeId, 'DispValue', groupCode)}</td>
                <td className="text-center p-2 mx-1">{getDispValForReactSelectByOneID(item.companyCodeId, 'DispValue', companyCode)}</td>
                <td className="text-center p-2 mx-1">{getDispValForReactSelectByOneID(item.academicCareerId, 'DispValue', academicCareer)}</td>
                <td className="text-center p-2 mx-1">{item.status === 'ACTIVE' ? <Button color="success" size={'sm'} disabled={true} >Active</Button> : <Button color="danger" size={'sm'} disabled={true} >Inactive</Button>}</td>
                {
                    permissionSet && permissionSet.includes('R') || permissionSet.includes('U') || permissionSet.includes('D') ?
                        <td className="text-center">
                            {/*<Button key={'ViewButton'} color="info" size="sm" type="button" onClick={() => {*/}
                            {/*    window.open(`${document.URL}/view/${item.id}`, '_blank');*/}
                            {/*}}><i className="fas fa-eye"/></Button>*/}
                            <CustomButton
                                className={'mx-1'}
                                permissionType={'R'}
                                icon={true}
                                forTable={true}
                                onClick={() => {
                                    window.open(`${document.URL}/view/${item.id}`, '_blank');
                                }}
                                permissionSet={permissionSet}
                            />
                            {/*<Button key={'EditButton'} color="info" size="sm" type="button" onClick={() => {*/}
                            {/*    window.open(`${document.URL}/edit/${item.id}`, '_blank');*/}
                            {/*}}><i className="fas fa-edit"/></Button>*/}
                            <CustomButton
                                className={'mx-1'}
                                permissionType={'U'}
                                icon={true}
                                forTable={true}
                                onClick={() => {
                                    window.open(`${document.URL}/edit/${item.id}`, '_blank');
                                }}
                                permissionSet={permissionSet}
                            />
                            {/*<Button key={'DeleteButton'} color="info" size="sm" type="button"*/}
                            {/*        onClick={() => deleteRow(index)}><i className="fas fa-trash"/></Button>*/}
                            <CustomButton
                                className={'mx-1'}
                                permissionType={'D'}
                                icon={true}
                                forTable={true}
                                onClick={() => deleteRow(index)}
                                permissionSet={permissionSet}
                            />
                        </td> : null}
            </tr>
        )
    }

    return(
        <Card>
            <div id="questionTable" className="table-responsive" >
                <Table className="align-items-center table-flush ">
                    <MasterHeaderAuto headerList={permissionSet.includes('R')|| permissionSet.includes('U')|| permissionSet.includes('D')? headerList :headerList.slice(0,4)} permissionSet={permissionSet}/>
                    <tbody className="list" key={'courseCatalogSequenceSearch'}>
                    {
                        courseCatalogSequenceData && courseCatalogSequenceData.map((item,index)=>
                            <TableData  item={item} index={index} key={index}/>
                        )
                    }
                    </tbody>
                </Table>
            </div>
        </Card>
    )
}
export default CourseCatalogSequenceTable;