import React from 'react';
import CustomTooltip from '../../../common/customTooltip/CustomTooltip';
import {
  gridComponents,
  gridStyles,
} from '../../issuanceIDcard/printIdCardAndMapRfid/constant';
import NoRowOverlay from '../../../masters/holidayMaster/NoRowOverlay';
import { AGridColDef } from '../../../common/data-grid/ADataGridColDef';
import { isBarcodedMaterial } from '../selectMeterialAndStock/constant';
import ADataGrid from '../../../common/data-grid/ADataGrid';

const Details = ({ rows }) => {
  const columns = [
    new AGridColDef('materialNumber', 'Material details', false)
      .setFlex(1.7)
      .renderCellComponent(params => (
        <CustomTooltip
          title={`${params?.row?.materialNumber} | ${params?.row?.materialDesc}`}
        >
          <div className='ellipsis-text-container'>
            {params?.row?.materialNumber} | {params?.row?.materialDesc}
          </div>
        </CustomTooltip>
      )),
    new AGridColDef('barcode', 'Barcode', false)
      .setValueGetter(params => params?.value?.trim())
      .renderCellComponent(params => {
        const isBarcoded = isBarcodedMaterial(params?.row);
        return (
          <CustomTooltip title={isBarcoded && params?.value}>
            <div className='ellipsis-text-container'>
              {isBarcoded && params?.value ? params?.value : '-'}
            </div>
          </CustomTooltip>
        );
      }),
  ];

  return (
    <div
      className='p-2 overflow-auto'
      style={{ width: '500px' }}
    >
      <ADataGrid
        removeWrapperContainer
        rows={rows || []}
        columns={columns}
        rowId={row => JSON.stringify(row)}
        rowHeight={50}
        loading={false}
        serverPagination={false}
        components={{
          ...gridComponents,
          NoRowsOverlay: () => <NoRowOverlay />,
        }}
        hidePagination={rows?.length <= 50 ? true : false}
        sx={{
          ...gridStyles,
        }}
        maxHeight={'300px'}
      />
    </div>
  );
};
const MaterialDetailsCellComponent = ({ rows }) => {
  const displayString = rows?.[0]?.materialNumber
    ? `${rows?.[0]?.materialDesc} | ${rows?.[0]?.materialNumber}`
    : '-';
  return (
    <div className='d-flex align-items-center gap-md ellipsis-text-container'>
      <div className='ellipsis-text-container'>{displayString}</div>
      <CustomTooltip
        showOnClick
        placement='bottom-end'
        title={<Details rows={rows} />}
      >
        <div className='pointer'>View details</div>
      </CustomTooltip>
    </div>
  );
};

export default MaterialDetailsCellComponent;
