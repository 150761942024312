import React from "react";
import { Accordion } from "../../../common/commonComponents";
import { parse2Digit } from "../../../unAssigned/otherCharges/otherChargeDetails/OtherChargeList";
const rowStyle = { display: "grid", gridTemplateColumns: "1.4fr 2fr" };

export const numberWithCommas = (x) => {
  if (!x) return "0.00";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const RowItem = ({ label, value }) => {
  return (
    <div className="mb-1" style={rowStyle}>
      <div className="label-color global-font-size text-bold">{label}</div>
      <div className="text-bold label-color global-font-size">{value}</div>
    </div>
  );
};
const renderRowItem = (label, value) => {
  return (
    <RowItem label={label} value={value}>
      {value}
    </RowItem>
  );
};

const ConsolidatedFeeDetails = ({ paymentDetails }) => {
  return (
    <div>
      <Accordion title="Consolidated fee details" titleColor="#262626">
        <div className="mt-3 ">
          {renderRowItem(
            "Gross amount",
            `₹ ${numberWithCommas(
              parse2Digit(paymentDetails?.grossAmount || 0)
            )}`
          )}
          {renderRowItem(
            "Concession amount",
            `${numberWithCommas(
              parse2Digit(paymentDetails?.concessionAmount || 0)
            )}`
          )}
          {renderRowItem(
            "Concession %",
            `${(paymentDetails?.concessionPercentage || 0)}%`
          )}
          {renderRowItem(
            "Net payable",
            `${numberWithCommas(parse2Digit(paymentDetails?.netPayable || 0))}`
          )}
          {renderRowItem(
            "Net payable (Inc taxes)(A)",
            `${numberWithCommas(
              parse2Digit(paymentDetails?.netPayableInclTaxes || 0)
            )}`
          )}
          {renderRowItem(
            "Total Paid inc. PDCs (Inc taxes)(B)",
            `${numberWithCommas(parse2Digit(paymentDetails?.totalPaid || 0))}`
          )}
          {renderRowItem(
            "Total Outstanding (A-B)",
            `${numberWithCommas(
              parse2Digit(paymentDetails?.totalOutstanding || 0)
            )}`
          )}
          {renderRowItem(
            "Total Paid Realized only (Inc. taxes) (C)",
            `${numberWithCommas(
              parse2Digit(paymentDetails?.totalPaidRealized || 0)
            )}`
          )}
          {renderRowItem(
            "Total Outstanding based on Realized (A-C)",
            `${numberWithCommas(
              parse2Digit(paymentDetails?.totalOutstandingBasedOnRealized || 0)
            )}`
          )}
        </div>
      </Accordion>
    </div>
  );
};

export default ConsolidatedFeeDetails;
