import React from 'react'
import { ReactComponent as NoRecordFound } from "../../../../../assets/img/svg/NoFileFound.svg"

const NoApprovalRequestsOverlay = () => {
    return (

        <div className='d-flex justify-content-center align-items-center w-100 vh-100'>

            <div className='d-flex flex-column'>

                <span
                    className='d-flex justify-content-center align-items-center'
                    style={{
                        height: '140px',
                        width: '140px',
                        backgroundColor: '#E2E2E2',
                        borderRadius: '500px',
                        padding: '34px'
                    }}>
                    <NoRecordFound style={{ height: '72px', width: '72px' }} />
                </span>

                <h3 className='mt-2'>No Records Found !</h3>

            </div>
        </div>

    );
}

export default NoApprovalRequestsOverlay;