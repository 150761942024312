export const getInitialFilterForm = () => {
  return {
    facultyNameOrfacultyId: '',
    academicGroup: [],
    academicCareer: [],
    businessArea: [],
    subject: []
  }
}

export const rosterFilter  = {
    facultyMasterId : '',
    day : '',
    endDate: '',
    startDate: '',
    startTime: '',
    endTime: '',
    onLoad: '',
    status: ''
}

export const publishStatusDropdownData = [
  {label: 'Published', value: 'P'},
  {label: 'Unpublished', value: 'U'}
]

export const publishStatusMap = {
  'P': 'Published',
  'U': 'Unpublished'
}

const formInfo = {
  subject: 'Array',
  businessArea: 'Array',
  academicCareer: 'Array',
  academicGroup: 'Array',
  facultyNameOrfacultyId: 'String',
}

export const getFilterCount = (dataSet) => {
  let cnt = 0;
  for (let item in dataSet) {
    if (formInfo[item] === 'String') {
      if (dataSet[item]) cnt++;
    }
    if (formInfo[item] === 'Array') {
      if (dataSet[item].length) cnt++;
    }
  }

 

  return cnt;
}

export const getTabRequest = (label, dataArray) => {
  if(label==='All') {
    const ids = dataArray?.map(item => item?.value)
    return {academicGroup: ids}
  }
  else {
    const id = dataArray.find(item => item['label']===label)?.value
    return { academicGroup: id ? [id] : [] };
  }
}


export const compareDates = (a,b) => {
  const da = new Date(a.rosterDate);
  const db = new Date(b.rosterDate);

  if(da < db) {
    return -1
  }

  if(da > db) {
    return 1
  }

  const daysOfWeek = ["Sunday","Monday","TuesDay","Wednesday","Thursday","Friday","Saturday"];
  const dayA = daysOfWeek.indexOf(a.day);
  const dayB = daysOfWeek.indexOf(b.day);

  return dayA - dayB;
} 


export const isFilterApplied = (filters) => {
  let count = 0;
  Object.keys(filters).map(item => {
      if (filters[item] !== '') {
         count +=1
      }
  })
  return count;
}