import React from 'react';
import { Card, CardHeader, Input, Container, Table, Row, } from 'reactstrap';
import { useState } from 'react';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import { TDROW } from '../../common/commonComponents/masterRow/masterRow';
import moment from 'moment';
import IsCoreMaster from '../../common/commonComponents/coreMaster';
import { ButtonText } from "variables/Buttons";
import { customValidation } from '../../common/utils/methods/validations/validation';
import validationRules from './validation.json';
import { fetchAllPostPromisedData, fetchDeletePromisedData, getCurrentDate } from '../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../common/constant';
import { editTableRow, newTableRow } from '../../common/utils/methods/commonMethods/tableMethods';
import CustomButton from 'components/CustomButton';
import { MasterHeaderAuto } from '../../common/commonComponents/masterHeader/masterHeader';


const TestModeTable = (props) => {
	const { permissionSet, isSaveVisible, setisSaveVisible = () => { }, getAllrecords = () => { } } = props;
	const [tableDataKey, settableDataKey] = useState('tableDataKey');

	const headerList = [
    {name: "TEST MODE KEY"},
    {name: 'EFFECTIVE DATE'},
    {name: 'Description', isRequired: true},
    {name: 'DISPLAY VALUE', isRequired: true},
    {name: 'Status'},
    {name: 'Action'}
  ]

	const addNewClass = () => {
		props.setclassArray([
			{
				editMode: true,
				__status: '__new',
				testModeKey: null,
				status: 'ACTIVE',
				testModeDesc: null,
				testModeDispValue: null,
				effectiveDate: null,
			},
			...props.classArray,
		]);
	};
	// const saveAll = (cb) => {
	// 	var _tempArray = props.classArray;
	// 	var isValid = true;
	// 	_tempArray.forEach((el, index) => {
	// 		if (el['editMode']) {
	// 			if (el['testModeDesc'] === null || !el['testModeDesc'].trim()) {
	// 				isValid = false;
	// 				failureToast('Please enter the value of Description');
	// 				return;
	// 			} else if (
	// 				el['testModeDispValue'] === null ||
	// 				!el['testModeDispValue'].trim()
	// 			) {
	// 				isValid = false;
	// 				failureToast('Please enter the value of Display Value');
	// 				return;
	// 			}
	// 			if (!isValid) {
	// 				return;
	// 			}
	// 			if (_tempArray[index]['__status'] == '__new') {
	// 				putpost(
	// 					masterServiceBaseUrl + '/testMode/createTestMode',
	// 					(data) => {
	// 						successToast(data['message']);
	// 						setisSaveVisible(false);
	// 						props.fetchClasses(
	// 							masterServiceBaseUrl + '/testMode/getAllTestMode'
	// 						);
	// 					},
	// 					(data) => {
	// 						failureToast(data['message']);
	// 					},
	// 					_tempArray[index],
	// 					'post'
	// 				);
	// 			} else {
	// 				putpost(
	// 					masterServiceBaseUrl +
	// 						'/testMode/updateTestMode/' +
	// 						_tempArray[index].id,
	// 					(data) => {
	// 						successToast(data['message']);
	// 						setisSaveVisible(false);
	// 						delete _tempArray[index]['editMode'];
	// 						props.setclassArray(_tempArray);
	// 						settableDataKey(new Date().getMilliseconds());
	// 						// props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
	// 					},
	// 					(data) => {
	// 						failureToast(data['message']);
	// 					},
	// 					_tempArray[index],
	// 					'put'
	// 				);
	// 			}
	// 		} else {
	// 		}
	// 	});
	// };
	const saveAll = async (index) => {
		let _data = props.classArray[index];
    let isValid = true;
    isValid = customValidation(_data, validationRules)
    if(!isValid) return;

    if (_data["__status"] == "__new"){
      const createRecord = await fetchAllPostPromisedData(endpoint.testMode.create, _data, 'post')
      if (createRecord?.code === 200) {
        successToast(createRecord["message"]);
        setisSaveVisible(false);
        getAllrecords()
      }
    } else {
      const updateRecord = await fetchAllPostPromisedData(`${endpoint.testMode.update}/${_data.id}`, _data, 'put');
      if (updateRecord?.code === 200) {
        successToast(updateRecord["message"]);
        setisSaveVisible(false);
        getAllrecords()
      }
    }
	}
	// const newRow = () => {
	// 	if (isSaveVisible) {
	// 		failureToast('Please Delete or Save current changes');
	// 	} else {
	// 		let cb = () => {
	// 			addNewClass();
	// 			setisSaveVisible(true);
	// 			// settableDataKey(new Date().getMilliseconds())
	// 		};
	// 		var _tempArray = props.classArray;
	// 		_tempArray = _.sortBy(_tempArray, [
	// 			function (o) {
	// 				return o.value;
	// 			},
	// 		]);
	// 		console.log(_tempArray);
	// 		props.setclassArray(_tempArray);
	// 		setisSaveVisible(false);
	// 		cb();
	// 	}
	// };
	const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      newTableRow(props.classArray,props.setclassArray,setisSaveVisible,addNewClass);
    }
  };
	// const editRow = (index) => {
	// 	if (isSaveVisible) {
	// 		failureToast('Please Delete or Save current changes');
	// 	} else {
	// 		let cb = () => {
	// 			var _tempArray = props.classArray;
	// 			_tempArray[index]['editMode'] = true;
	// 			props.setclassArray(_tempArray);
	// 			setisSaveVisible(true);
	// 		};
	// 		var _tempArray = props.classArray;
	// 		_tempArray = _.sortBy(_tempArray, [
	// 			function (o) {
	// 				return o.value;
	// 			},
	// 		]);
	// 		cb();
	// 	}
	// };
	const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      editTableRow(index,props.classArray,props.setclassArray,setisSaveVisible);
    }
  };
	// const deleteRow = (index) => {
	// 	if (props.classArray[index]['__status'] == '__new') {
	// 		let n = props.classArray;
	// 		n.splice(index, 1);
	// 		props.setclassArray(n);
	// 		setisSaveVisible(false);
	// 		settableDataKey(new Date().getMilliseconds());
	// 	} else {
	// 		if (window.confirm('Are you sure you want to delete this Test Mode?')) {
	// 			putpost2(
	// 				masterServiceBaseUrl +
	// 					'/testMode/deleteTestMode/' +
	// 					props.classArray[index].id,
	// 				(data) => {
	// 					successToast(data['message']);
	// 					if (props.classArray.length == 1) {
	// 						props.previousPage();
	// 						props.fetchClasses(
	// 							masterServiceBaseUrl + '/testMode/getAllTestMode'
	// 						);
	// 					} else {
	// 						var _tempArray = props.classArray;
	// 						if (_tempArray[index]['editMode']) {
	// 							setisSaveVisible(false);
	// 						}
	// 						_tempArray.splice(index, 1);
	// 						props.setclassArray(_tempArray);
	// 					}
	// 					settableDataKey(new Date().getMilliseconds());
	// 				},
	// 				(data) => {
	// 					failureToast(data['message']);
	// 				},
	// 				'Delete'
	// 			);
	// 		}
	// 	}
	// };
	const deleteRow = async (index) => {
    if (window.confirm("Are you sure you want to delete this Test Mode?")) {
      const deleteRecord = await fetchDeletePromisedData(`${endpoint.testMode.delete}/${props.classArray[index].id}`);
      if(deleteRecord?.code === 200){
        successToast(deleteRecord['message']);
        let flag=false;
        if(props.classArray.length==1){
          flag=true;
        }
        getAllrecords(flag);
        settableDataKey(new Date().getMilliseconds());
      }
    }
  };
	const updateKey = (index, value, key) => {
		var _tempArray = props.classArray;
		_tempArray[index][key] = value;
		props.setclassArray(_tempArray);
	};
	return (
		<>
			<Container className="mt-3" fluid>
				<Row>
					<div className="col">
						<Card>
							<CardHeader className="border-0">
								<h3 className="mb-0 floatLeft">Master - Test Mode</h3>
								<IsCoreMaster
									master={'testMode'}
									masterDisplayValue={'Test Mode'}
								/>
								{/* <CreateButton
									text={ButtonText.TestMode.addNew}
									disabled={isSaveVisible}
									className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
									onClick={() => { newRow() }}
								/> */}
								<CustomButton
									disabled={isSaveVisible}
									className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
									content={ButtonText.TestMode.NEW}
									icon={true}
									permissionType={'C'}
									permissionSet={permissionSet}
									onClick={() => newRow()}
								/>
								{/* {isSaveVisible?<Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => {saveAll()}}>Save</Button>:null} */}
							</CardHeader>
							<div id="questionTable" className="table-responsive">
								<Table className="align-items-center table-flush tableLayout">
									{/* <MasterHeader type={'Test Mode'} isRequired={true} /> */}
									<MasterHeaderAuto headerList={headerList} isSaveVisible={isSaveVisible} permissionSet={permissionSet} />
									<tbody className="list" key={tableDataKey}>
										{props.classArray
											? props.classArray.map((el, index) =>
												!el['editMode'] ? (
													<TDROW
														type={'testMode'}
														index={index}
														el={el}
														editRow={editRow}
														deleteRow={deleteRow}
														isSaveVisible={isSaveVisible}
														permissionSet={permissionSet}
													/>
												) : (
													<tr key={index + '-class'}>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="50"
																disabled={true}
																defaultValue={el['testModeKey']}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'testModeKey'
																	);
																}}
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																disabled={true}
																defaultValue={
																	el['__status'] == '__new'
																		? getCurrentDate()
																		: moment(el['effectiveDate']).format(
																			'DD-MM-YYYY'
																		)
																}
																placeholder="Example:01-01-2020"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="50"
																defaultValue={el['testModeDesc']}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'testModeDesc'
																	);
																}}
																placeholder="Description"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="50"
																defaultValue={el['testModeDispValue']}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'testModeDispValue'
																	);
																}}
																placeholder="Display Value"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<label className="custom-toggle mx-auto ml-2">
																<input
																	checked={
																		el.status == 'ACTIVE' ? true : false
																	}
																	type="checkbox"
																	id={el.id}
																	onChange={(e) => {
																		settableDataKey(
																			new Date().getMilliseconds()
																		);
																		let n = 'ACTIVE';
																		if (!e.target.checked) {
																			n = 'INACTIVE';
																		}
																		updateKey(index, n, 'status');
																	}}
																/>
																<span
																	style={{ width: '72px' }}
																	className="custom-toggle-slider rounded-circle activeToggle"
																	data-label-off="Inactive"
																	data-label-on="Active"
																/>
															</label>
														</td>
														<td className="text-center">
															{/* <Button color="info" size="sm" type="button" onClick={() => {
                          deleteRow(index)
                        }}>  <i className="fas fa-trash" /></Button> */}
															{isSaveVisible ? (
																<>
																	{/* <UpdateButton text={ButtonText.TestMethod.save} onClick={() => { saveAll() }} /> */}
																	<CustomButton
																		className={'mx-1'}
																		content={ButtonText.TestMode.UPDATE}
																		forTable={true}
																		permissionType={'C,U'}
																		permissionSet={permissionSet}
																		onClick={() => { saveAll(index); }}
																	/>
																	{/* <Button
																			color="info"
																			size="sm"
																			type="button"
																			className="mx-1"
																			onClick={() => {
																				saveAll();
																			}}
																		>
																			Save
																		</Button> */}
																	{/* <Button
																		color="danger"
																		size="sm"
																		type="button"
																		onClick={() => {
																			let n = props.classArray;
																			if (n[index]['__status'] == '__new') {
																				n.splice(index, 1);
																			} else {
																				// n[index]['editMode']=false
																				props.fetchClasses(
																					masterServiceBaseUrl +
																					'/testMode/getAllTestMode'
																				);
																			}
																			props.setclassArray(n);
																			setisSaveVisible(false);
																		}}
																	>
																		<i className="fas fa-times" />
																	</Button> */}
																	<CustomButton
																		className={'mx-1'}
																		permissionType={'cancel'}
																		forTable={true}
																		icon={true}
																		permissionSet={permissionSet}
																		onClick={() => {
																			let n = props.classArray;
																			if (n[index]["__status"] == "__new") {
																				n.splice(index, 1);
																			} else {
																				getAllrecords();
																			}
																			setisSaveVisible(false);
																		}}
																	/>
																</>
															) : (
																// <Button
																// 	color="info"
																// 	size="sm"
																// 	type="button"
																// 	onClick={() => {
																// 		deleteRow(index);
																// 	}}
																// >
																// 	{' '}
																// 	<i className="fas fa-trash" />
																// </Button>
																<></>
															)}
														</td>
													</tr>
												)
											) : null
										}
									</tbody>
								</Table>
							</div>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};
export default TestModeTable;
