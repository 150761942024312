import React, { useState, useEffect } from "react";
import { Input, Col } from "reactstrap";
import {
  getUniqueKey,
  getRequiredSign
} from "../../../../../../common/utils/methods/commonMethods/utilityMethod";
import Select from "react-select";
import { AsyncSelectSearch } from "../../../../../../common/commonComponents";
import {
  getAPI,
  enrollmentServiceBaseUrl,
  masterServiceBaseUrl,
} from "../../../../../../../../../services/http";
import { commonMessages } from "../../../../../../common/constant"
import { failureToast, successToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';


const _ = require("lodash");

const StudentForm = (props) => {
  const { studentData, setStudentData = () => {}, isViewOnly = false,action1, isFirstRecordSaved } = props;
  const [isOptionLoading, setOptionLoading] = useState(false);
  const [option, setOption] = useState([]);
  const [term, setTerm] = useState("");
  const [businessArea, setBusinessArea] = useState("");

  const filterOption = (data) => {
    return data?.map((data) => {
      return Object.assign(
        {},
        {
          label: data?.studentId,
          value: data?.studentId,
          studentName: `${data?.studentFirstName} ${data?.studentLastName}`,
        }
      );
    });
  };

  const promiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      if (inputValue && inputValue.length < 3) {
        return resolve([]);
      }
      // alert("I m in api call")
      getAPI(
        `${enrollmentServiceBaseUrl}/studentDetails/getAllStudentBySmartSearch/${inputValue}`,
        (data) => {
          resolve(filterOption(data?.data));
        },
        (data) => {
          failureToast(data["message"]);
        }
      );
      //   setTimeout(() => {

      //   }, 100);
    });
  };

  const getOtherData = (url, name, parentName, method) => {
    getAPI(
      url,
      (data) => {
        method(data.data[parentName]);
      },
      (data) => {
        failureToast(data["message"]);
        return "";
      }
    );
  };

  const getApplicationIds = (sid) => {
    setOptionLoading(true);
    getAPI(
      `${enrollmentServiceBaseUrl}/studentApplication/getStudentApplicationIdByStudentId/${sid}`,
      (data) => {
        const idArr = data?.data?.map((data) => {
          return {
            label: data.applicationId,
            value: data.applicationId,
            businessArea: data.businessArea,
            term: data.term,
            opportunityId: data.opportunityId,
          };
        });
        setOption(idArr);
        setOptionLoading(false);
      },
      (data) => {
        setOption([]);
        setOptionLoading(false);
        failureToast(data["message"]);
        return [];
      }
    );
  };

  const getAllPendingValue = (te, or, ba) => {
    getOtherData(
      `${masterServiceBaseUrl}/term/getTerm/${te}`,
      "term",
      "termDispValue",
      setTerm
    );
    // getOtherData(`${masterServiceBaseUrl}/opportunityType/getOpportunityType/${or}`,'opp','opportunityTypeDispValue',setOpportunityId)
    getOtherData(
      `${masterServiceBaseUrl}/businessArea/getBusinessArea/${ba}`,
      "businessArea",
      "businessAreaDispValue",
      setBusinessArea
    );
  };

  useEffect(() => {
    if(studentData.businessArea && studentData.term){
      getAllPendingValue(studentData.term,'',studentData.businessArea)
    }
  }, [studentData])

  return (
    <div>
      <div className="form-row mt-4" key={getUniqueKey()}>
        <Col md={4}>
          <label className="form-control-label">Student ID{getRequiredSign()}(<span className={"text-danger field-info"} >{commonMessages.getStudentId}</span>)</label>
          {isViewOnly || action1 ? (
            <Input
              value={studentData?.studentId?.value}
              disabled
              className="tdInput"
            />
          ) : (
            <AsyncSelectSearch
              value={studentData.studentId}
              placeholder={"Select Student"}
              onChangeHandler={(sData) => {
                setStudentData({
                  ...studentData,
                  studentId: sData,
                  studentName: sData.studentName,
                  applicationId: null,
                  term: '',
                  opportunityId: '',
                  businessArea: '',
                });
                setTerm('')
                setBusinessArea('') 
                getApplicationIds(sData.value);
              }}
              promiseHandler={promiseOptions}
              isDisabled={isFirstRecordSaved}
            />
          )}
        </Col>
        <Col md={4}>
          <label className="form-control-label">Student Name</label>
          <Input value={studentData.studentName} disabled className="tdInput" />
        </Col>
        <Col md={4}>
          <label className="form-control-label">Application ID{getRequiredSign()}(<span className={"text-danger field-info"} >{commonMessages.getApplicationIdOnStudentId}</span>)</label>
          {isViewOnly || action1 ? (
            <Input
              value={studentData.applicationId?.value}
              disabled
              className="tdInput"
            />
          ) : (
            <Select
              value={studentData.applicationId}
              options={option}
              placeholder={"Select Application ID"}
              onChange={(sData) => {
                setStudentData({
                  ...studentData,
                  applicationId: sData,
                  term: sData.term,
                  opportunityId: sData.opportunityId,
                  businessArea: sData.businessArea,
                });
                getAllPendingValue(
                  sData.term,
                  sData.opportunityId,
                  sData.businessArea
                );
              }}
              isDisabled={isFirstRecordSaved}
              isLoading={isOptionLoading}
            />
          )}
        </Col>
      </div>
      <div className="form-row mt-4" key={getUniqueKey()}>
        <Col md={4}>
          <label className="form-control-label">Term</label>
          <Input value={term} disabled className="tdInput" />
        </Col>
        <Col md={4}>
          <label className="form-control-label">Opportunity ID</label>
          <Input
            value={studentData.opportunityId}
            disabled
            className="tdInput"
          />
        </Col>
        <Col md={4}>
          <label className="form-control-label">Business Area</label>
          <Input value={businessArea} disabled className="tdInput" />
        </Col>
      </div>
    </div>
  );
};

export default StudentForm;
