import React, { useEffect, useState, useContext } from 'react';
import {
	Container,
	Card,
	CardBody,
	CardHeader,
} from 'reactstrap';
import {
	masterServiceBaseUrl,
	getAPI
} from 'services/http';
import CustomLoader from '../../../common/commonComponents/Loader/loader';
import {
	fetchAllData,
	fetchDetails,
	getDispValForReactSelectByOneID,
} from '../../../common/utils/methods/commonMethods/utilityMethod';
import { BsFillForwardFill } from 'react-icons/bs';
import { updateData } from '../../../../../../redux';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CommonHeader from '../../../common/commonComponents/commonHeader/commonHeader';
import RefundAccEntryTable from './refundAccEntryTable';
import './refundAccEntry.scss';
import { failureToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import { PermissionContext, RolePermissions } from "../../../../../../appContext";
import { pages } from "../../../common/constant";

const RefundAccEntry = (props) => {
	const { id } = props.match.params;


	const permissions = useContext(PermissionContext);
	const userPermissions = RolePermissions(
	  permissions,
	  pages['paymentMode']['id']
	);

	const dispatch = useDispatch();
	const { action, isNew = true, formId, isViewOnly = false } = props;
	const [apiLoader, setApiLoader] = useState(false);
	const [paymentModeData, setPaymentModeData] = useState([]);
	const [groupCodeList, setGroupCodeList] = useState([]);
	const [companyCodeList, setCompanyCodeList] = useState([]);
	const [accountTypeList, setAccountTypeList] = useState([]);
	const [accountEntryTypeList, setAccountEntryTypeList] = useState([]);
	const [gLAccountList, setGLAccountList] = useState([]);
	const [invoiceStatusList, setInvoiceStatusList] = useState([]);
	const [refundAccEntry, setRefundAccEntry] = useState([]);

	// const id = 34;

	useEffect(() => {
		getPaymentModeData();
	}, [id]);

	useEffect(() => {
		fetchDetails(
			`${masterServiceBaseUrl}/groupCode/getAllGroupCode`,
			setGroupCodeList,
			'groupCode',
			'reactSelect'
		);
		fetchDetails(
			`${masterServiceBaseUrl}/companyCode/getAllCompanyCode`,
			setCompanyCodeList,
			'companyCode',
			'reactSelect'
		);
		fetchDetails(
			masterServiceBaseUrl + '/accountType/active',
			setAccountTypeList,
			'accountType',
			'reactSelect'
		);
		fetchDetails(
			masterServiceBaseUrl + '/accountingEntryType/active',
			setAccountEntryTypeList,
			'accountEntryType',
			'reactSelect'
		);
		fetchDetails(
			masterServiceBaseUrl + '/invoiceStatus/active',
			setInvoiceStatusList,
			'invoiceStatus',
			'reactSelect'
		);
	}, []);

	const getPaymentModeData = async () => {
		try {
			setApiLoader(true);
			getAPI(
				`${masterServiceBaseUrl}/paymentMode/getPaymentMode/${id}`,
				(data) => {
					setPaymentModeData(data.data);
					fetchDetails(
						masterServiceBaseUrl +
							`/glcode/getAllGlCodeByGroupCodeAndCompanyCode?groupCode=${data.data.groupCodeId}&companyCode=${data.data.companyCodeId}`,
						setGLAccountList,
						'gl',
						'glCodeKeyDesc'
					);
					setApiLoader(false);
				},
				(data) => {
					failureToast(data['message']);
					setApiLoader(false);
				}
			);
		} catch (e) {
			console.log('Error', e);
		}
	};

	const headerData = [
		{
			label: 'Business Group',
			value: `${
				paymentModeData &&
				groupCodeList &&
				getDispValForReactSelectByOneID(
					paymentModeData.groupCodeId,
					'DispValue',
					groupCodeList
				)
			}`,
		},
		{
			label: 'Company Code',
			value: `${
				paymentModeData &&
				companyCodeList &&
				getDispValForReactSelectByOneID(
					paymentModeData.companyCodeId,
					'DispValue',
					companyCodeList
				)
			}`,
		},
		{
			label: 'Payment Method - Description',
			value: `${paymentModeData && paymentModeData.paymentModeDispValue} - ${
				paymentModeData && paymentModeData.paymentModeDesc
			}`,
		},
		{
			label: 'SAP Paymode',
			value: paymentModeData.sapPaymode,
		},
	];

	return (
		<>
			<Container className="mt-4" fluid>
				<Card>
					<CardHeader>
						<h2 className="mb-0 floatLeft">
							<span
								onClick={() => props.history.goBack()}
								style={{ cursor: 'pointer', color: '#11CDEF' }}
							>
								Payment Mode
							</span>
							&nbsp;
							{<BsFillForwardFill style={{ fontSize: '30px' }} />}&nbsp;Refund
							A/C Entry
						</h2>
					</CardHeader>
				</Card>
				{apiLoader ? (
					<div
						className="mx-auto text-center py-5 my-5"
						style={{ height: '100vh' }}
					>
						<CustomLoader
							apiLoader={apiLoader}
							loaderHeight={'200px'}
							loaderWidth={'100%'}
						/>
					</div>
				) : (
					<div>
						<Card>
							<CardBody>
								<CommonHeader
									headerData={headerData}
									lastUpdatedDate={
										paymentModeData && paymentModeData['updatedOn']
									}
									countLabel={'Total Entries '}
									countValue={
										(paymentModeData && paymentModeData['totalRefundCount']) ||
										'00'
									}
								/>
							</CardBody>
						</Card>
						<Card>
							<CardBody>
								<RefundAccEntryTable
									paymentModeID={id}
									accountTypeList={accountTypeList}
									accountEntryTypeList={accountEntryTypeList}
									gLAccountList={gLAccountList}
									invoiceStatusList={invoiceStatusList}
									refundAccEntry={refundAccEntry}
									setRefundAccEntry={setRefundAccEntry}
									getPaymentModeData={getPaymentModeData}
									userPermissions={userPermissions}
								/>
							</CardBody>
						</Card>
					</div>
				)}
			</Container>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		headerData: state.globalData.data,
	};
};

export default connect(mapStateToProps)(RefundAccEntry);
