import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router";
import { CardHeader, Card, Container, Input, Col, Row, Table,Button } from "reactstrap";
import {
  getAPI,
  pricingServiceBaseUrl
} from "services/http";
import moment from "moment";
import { PermissionContext, RolePermissions } from "appContext";
import { PermisionDenied } from "../../../common/commonComponents";
import { pages } from "../../../common/constant";
import CourseForm from "./courseInformation/courseForm";
import FeeBreakUpTable from "./courseInformation/feeBreakUpTable";
import CustomLoader from "../../../common/commonComponents/Loader/loader";
import { endpoint } from "../../../common/constant";
import { fetchAllData, isEqual } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { constants } from '../../../common/constant';
import { Accordion } from "views/pages/manage/common/commonComponents";
import { failureToast } from "../../../common/utils/methods/toasterFunctions/function";
import CustomButton from "components/CustomButton";
// import SupportingDocument from "../courseFee/CourseFee/supportingDocument";
import SupportingDocView from "./manualCourseSetup/supportingDocument/supportingDocView";
import { Tooltip} from "@mui/material" ;
import ViewOnlinePackageDialog from "views/pages/manage/courseInformation/pricingService/courseFeeInfoSearch/ViewOnlinePackageDialog";



const modifiedCourseFeeStartYear = {
  ...constants.course_fee_year_start_date_objs,
  4: "forthYearStartDate"
}

const modifiedCourseFeeEndYear = {
  ...constants.course_fee_year_end_date_objs,
  4: "forthYearEndDate"
}

const useOnlinePackage = true;

const CourseDetails = (props) => {
  const { id } = props.match.params;

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["courseFeeInformation"]['id']);

  const [isFetching, setIsFetching] = useState(true);
  const [feeBreakData, setFeeBreakData] = useState(null)
  const [isDialogOpen,setIsDialogOpen]=useState(false);

  const [value, setValues] = useState({
    businessGrp: {},
    companyCode: {},
    courseFeeStatus: {},
    transactionId: {},
    pricingZoneName: {},
    pricingZoneCode: "",
    academicCareer: "",
    academicGrp: "",
    academicTerm: "",
    courseId: "",
    courseName: "",
    courseComponent: "",
    businessArea: "",
    businessAreaDesc: "",
    businessAreaType: "",
    totalBaseFee: "",
    totalTaxAmount: "",
    toalCourseFee: "",
    totalNumberOfYears: "",
    courseStartDate: "",
    courseEndDate: "",
    associatedSecurityCourseId: "",
    feeEffectiveForm: "",
    feeEffectiveUpTo: "",
    totalNumberOfInstallment: ""
  });

  const [dates, setDates] = useState(null)
  const [supportingDocs, setSupportingDocs] = useState(null)

  const [busGrp, setBusGrp] = useState(null);
  const [cmyCode, setCmyCode] = useState(null);
  const [academicCareer, setAcademicCareer] = useState(null);
  const [academicGroup, setAcademicGroup] = useState(null);
  const [term, setTerm] = useState(null);
  const [allCourseData, setAllCourseIdData] = useState(null);
  const [pricingZoneData, setPricingZoneData] = useState(null);
  const [allBusinessAreaData, setAllBusinessAreaData] = useState(null);
  const [allBUnitType, setAllBUnitType] = useState(null);
  const [itemTypeData, setItemTypeData] = useState(null);
  const [courseComponentData, setCourseComponentData] = useState(null)
  const [onlinePackageData, setOnlinePackageDate] = useState(null);


  const getBusinessUnitType = () => {
    let typeStr = allBusinessAreaData?.businessUnitTypeResponseDTOS?.reduce((a, v) => {
      return `${a ? `${a},` : ''}` + `${v.businessUnitType}`
    }, ""
    )
    return typeStr;
  };

  const getOnlinePackages = (v) => {     
    const idArr = v?.split("_").map(Number) ?? [];
    const temp = onlinePackageData?.filter(i => idArr.indexOf(i.id) > -1)?.map(i =>`${i.id}-${i.onlinePackageName}`)?.join("| ") ?? ''

    return temp ?? ''
  }


  const listToRender = [
    {
      label: "Business Group",
      value: busGrp?.groupCodeDispValue ?? 'Not Found',
    },
    {
      label: "Company Code",
      value: cmyCode?.companyCodeDispValue ?? 'Not Found',
    },
    {
      label: "Transaction ID",
      value: value.transactionId,
    },
    {
      label: "Status",
      value: value?.status ?? "",
    },
    {
      label: "Course ID",
      value: value.courseId
    },
    {
      label: "Course Name",
      value: allCourseData?.courseName ?? 'Not Found',
    },
    {
      label: "Course Type",
      value: allCourseData?.categoryType ?? 'Not Found',
    },
    {
      label: "Course Fee Status",
      value: value.courseFeeStatus,
    },

    // {
    //   label: "Pricing Zone Code",
    //   value: pricingZoneData?.pricingZoneName ?? 'Not Found',
    // },
    {
      label: "Pricing Zone Name",
      value: pricingZoneData?.pricingZoneName ?? 'Not Found',
    },
    {
      label: "Business Area",
      value: allBusinessAreaData?.businessArea ?? 'Not Found',
    },
    {
      label: "Business Area Description",
      value: allBusinessAreaData?.businessAreaDescription ?? 'Not Found',
    },
    {
      label: "Business Area Type",
      value: getBusinessUnitType(),
    },
    {
      label: "Academic Career",
      value: allCourseData?.academicCareer ?? 'Not Found',
    },
    {
      label: "Academic Group",
      value: allCourseData?.academicGroup ?? 'Not Found',
    },
    {
      label: "Academic Term",
      value: term?.termDispValue ?? 'Not Found',
    },
    {
      label: "Associated Security Course ID",
      value: value.associatedSecurityCourseId,
    },
    {
      label: "Franchisee Commission",
      value: value?.franchiseeCommission ?? "",
    },
    {
      label: "Recorded Video Lecture ID",
      value: value?.recordedVideoLectureId //value: getOnlinePackages(value?.recordedVideoLectureId) ?? "Not Found",
    },
    {
      label: "Total Number of installments",
      value: value?.totalNumberOfInstallment ?? "Not Found",
    },
    // this is commented only 
   
  ];

  const buttonStyle = {
    position: 'absolute',
    top: 1,
    right: 3,
    bottom: 1,
    padding: '0.375rem 0.75rem',
    margin: '3px',
    border: 'none',
    borderRadius: '0 0.2rem 0.2rem 0',
    backgroundColor: '#FFFFFE',
    color: '#00B0F5',
    borderRadius:'6px',
    textAlign: 'center',  
    fontSize: '12px', 
    fontFamily: 'Inter',
    fontWeight: '600',
     wordWrap: 'break-word'
  }; 

  const extraComponent = [
    {
      label: "Online Package ID",
      value: getOnlinePackages(value?.onlinePackageId) ?? "Not Found" ,
      customComponent: <><div style={{ position: 'relative' ,border:'1px solid',borderColor:'#DEE2E6',borderRadius:"6px",height: "39px",backgroundColor: '#F2FBFE'}}>
         <Tooltip title={getOnlinePackages(value?.onlinePackageId)?.replaceAll('|',',')}  placement='top' arrow><div><Input type="text" className="mb-3 ellipsis-text-container" value={getOnlinePackages(value?.onlinePackageId)?.replaceAll('|',',')} style={{ height: "39px" ,border: 'none',backgroundColor: '#F2FBFE',maxWidth:"370px"}} disabled/></div></Tooltip>
         {getOnlinePackages(value?.onlinePackageId) && <Button style={buttonStyle} onClick={()=>setIsDialogOpen(true)}>View All</Button>}
        </div>
        <ViewOnlinePackageDialog
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        data={getOnlinePackages(value?.onlinePackageId).split('|')}
        />
        </>
    },
  ]
  const datesDetails = [
    {
      label: "Total Number of Years",
      value: value.totalNumberOfYears,
    },
    {
      label: "Course Start Date",
      value: moment(value.courseStartDate).format("DD/MM/YYYY"),
    },
    {
      label: "Course End Date",
      value: moment(value.courseEndDate).format("DD/MM/YYYY"),
    },

    {
      label: "Fee Effective Form",
      value:
        (value.feeEffectiveForm &&
          moment(value.feeEffectiveForm).format("DD/MM/YYYY")) ??
        "",
    },
    {
      label: "Fee Effective Up TO",
      value:
        (value.feeEffectiveFromDate &&
          moment(value.feeEffectiveFromDate).format("DD/MM/YYYY")) ??
        "",
    },
  ]

  const feeDetailsData = [
    {
      label: "Total Base Fee",
      value: value.totalBaseFee,
    },
    {
      label: "Total Tax Amount",
      value: value.totalTaxAmount,
    },
    {
      label: "Total Course Fee",
      value: value.toalCourseFee,
    },
  ]

  const getSubDataFromId = async (data) => {
    const onlinePackageCall = useOnlinePackage ? fetchAllData(endpoint.onlinePackage.getAll, setOnlinePackageDate) : Promise.resolve()
    await Promise.all([
      fetchAllData(`${endpoint.groupCode.getById}/${data.businessGroup}`, setBusGrp),
      fetchAllData(`${endpoint.companyCode.getById}/${data.companyCode}`, setCmyCode),
      fetchAllData(`${endpoint.course_details.getCourseDetailsByCourseId}/${data.courseId}`, setAllCourseIdData),
      fetchAllData(`${endpoint.businessArea.getBusinessUnitType}/${data.businessAreaId}`, setAllBusinessAreaData),
      fetchAllData(`${endpoint.term.getById}/${data.academicTerm}`, setTerm),
      fetchAllData(`${endpoint.pricing_zone.getById}/${data.pricingZone}`, setPricingZoneData),
      onlinePackageCall,
      // fetchAllData(endpoint.onlinePackage.getAll, setOnlinePackageDate)
    ]);
    // All fetch calls are done now
    // console.log(this.state);

    setValues({
      businessGrp: data?.businessGroup,
      companyCode: data?.companyCode,
      courseFeeStatus: data?.courseFeeStatus ?? '',
      transactionId: data?.transactionId ?? '',
      courseId: data?.courseId,
      totalBaseFee: data?.baseAmount,
      totalTaxAmount: data?.tax,
      toalCourseFee: data?.finalAmount,
      totalNumberOfYears: data?.totalNumberOfYears,
      courseStartDate: data?.courseStartDate,
      courseEndDate: data?.courseEndDate,
      associatedSecurityCourseId: data?.securityCourseId && !isEqual(data?.securityCourseId, 0) ? data?.securityCourseId : '',
      feeEffectiveForm: data?.feeEffectiveFromDate,
      feeEffectiveUpTo: data?.feeEffectiveToDate,
      franchiseeCommission: data?.franchiseeCommission,
      recordedVideoLectureId: data?.recordedVideoLectureId,
      status: data?.status,
      totalNumberOfInstallment: data?.totalNumberOfInstallment,
      onlinePackageId: data?.onlinePackageId,
      feeEffectiveFromDate : data?.feeEffectiveFromDate

    });

    let dateObj = {};
    [...Array(data?.totalNumberOfYears)].forEach((_, i) => {
      // alert(data?.[constants.course_fee_year_end_date_objs[i+1]])
      const startDateLabel = modifiedCourseFeeStartYear[i + 1];
      const endDateLabel = modifiedCourseFeeEndYear[i + 1];
      dateObj[startDateLabel] = data[startDateLabel] ?? '';
      dateObj[endDateLabel] = data[endDateLabel] ?? '';
    })


    setDates({ ...dateObj });

    // setSupportingDocs


    setIsFetching(false);
  }

  const getData = () => {
    getAPI(
      `${pricingServiceBaseUrl}/courseFeeManualProcessing/getCourseFeeManualById/${id}`,
      (data) => {
        setFeeBreakData(data.data?.normalFeeBreakupDTO);
        setSupportingDocs(data.data.courseFeeSupportingDocumentDtoList)
        getSubDataFromId(data.data.courseFeeManualHeaderResponseDTO)
      },
      (data) => {
        failureToast(data["message"]);
        setIsFetching(false);
      }
    );
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  // useEffect(() => {
  //   fetchAllData(`${masterServiceBaseUrl}/groupCode/getAllGroupCode`, setBusGrp);
  //   fetchAllData(`${masterServiceBaseUrl}/companyCode/getAllCompanyCode`, setCmyCode);
  //   fetchAllData(
  //     `${masterServiceBaseUrl}/academicCareer/getAllAcademicCareer`,
  //     setAcademicCareer
  //   );
  //   fetchAllData(
  //     `${masterServiceBaseUrl}/academicGroup/getAllAcademicGroup`,
  //     setAcademicGroup
  //   );
  //   fetchAllData(`${masterServiceBaseUrl}/term/getAllTerm`, setTerm);
  //   fetchAllData(
  //     `${masterServiceBaseUrl}/courseDetail/getAllCourseDetail`,
  //     setAllCourseIdData
  //   );
  //   fetchAllData(
  //     `${masterServiceBaseUrl}/pricingZone/getAllPricingZone`,
  //     setPricingZoneData
  //   );
  //   fetchAllData(
  //     `${masterServiceBaseUrl}/businessArea/getAllBusinessArea`,
  //     setAllBusinessAreaData
  //   );
  //   fetchAllData(
  //     `${masterServiceBaseUrl}/businessUnitType/getAllBusinessUnitType`,
  //     setAllBUnitType
  //   );
  //   fetchAllData(
  //     `${masterServiceBaseUrl}/itemTypeSetup/getAllItemTypeSetup`,
  //     setItemTypeData
  //   );
  //   fetchAllData(
  //     `${masterServiceBaseUrl}/courseComponent/getAllCourseComponent`,
  //     setCourseComponentData
  //   );
  // }, []);

  return (
    userPermissions ?
      <Container fluid className="mt-4">
        {isFetching ? (
          <div
            className="mx-auto text-center py-5 my-5"
            style={{ height: "100vh" }}
          >
            <CustomLoader apiLoader={isFetching} />
          </div>
        ) : (<>
          <Accordion
            title="Course Information"
            isFormOpen={true}
          >
            <CourseForm data={useOnlinePackage ? [...listToRender,...extraComponent] : listToRender } />
          </Accordion>
          <Accordion
            title="Date Information"
            isFormOpen={true}
          >
            <CourseForm data={datesDetails} />
            <Row className="pt-4">
              {dates && value?.totalNumberOfYears && [...Array(value.totalNumberOfYears)].map((_, i) => (
                <Col md="3">
                  <div className="year-box-View">
                    <label className="form-control-label p-2">{`${constants.course_fee_years[i]} Year`}</label>
                    <hr />
                    <div className="p-2 d-flex flex-column">
                      <div>
                        <label className="form-control-label">{`Start Date`}</label>
                        <Input
                          value={moment(dates[modifiedCourseFeeStartYear[i + 1]]).format("DD-MM-YYYY") ?? ''}
                          disabled
                          style={{ height: "39px" }}
                        />
                      </div>
                      <div>
                        <label className="form-control-label">{`End Date`}</label>
                        <Input
                          value={moment(dates[modifiedCourseFeeEndYear[i + 1]]).format("DD-MM-YYYY") ?? ''}
                          disabled
                          style={{ height: "39px" }}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion>
          <Accordion
            title="Fee Break Up"
            isFormOpen={true}
          >
            <FeeBreakUpTable data={feeBreakData} itemTypeData={itemTypeData} />
          </Accordion>
          <Accordion
            title="Fee Information"
            isFormOpen={true}
          >
            <CourseForm data={feeDetailsData} />
          </Accordion>
          <Accordion
            title="Supporting Document"
            isFormOpen={true}
          >
            {supportingDocs && <SupportingDocView data={supportingDocs} userPermissions={userPermissions} />}
          </Accordion>

          <Card className="p-3">
            <Row className="justify-content-end flex-row pr-4">
              {/* <Button
              color="info"
              size="sm"
              type="button"
              className="my-2"
              onClick={() => {
                props.history.push("/admin/courseFeeInformation");
              }}
            >
              Close
            </Button> */}
              <CustomButton
                type="normal"
                color="info"
                className={'mx-1'}
                content={"Close"}
                permissionType={'R'}
                onClick={() => {
                  props.history.push("/admin/courseFeeInformation");
                }}
                permissionSet={userPermissions}
              />
            </Row>
          </Card>
        </>)}
      </Container > : <PermisionDenied />
  );
};

export default withRouter(CourseDetails);

