import React, { useState } from "react";
import {
  Card,
  CardHeader,
  Input,
  Container,
  Button,
  Table,
  Row,
  Col,
} from "reactstrap";
import moment from 'moment'


const CommonHeader = (props) => {
  const {comapnyCodeData, groupCodeData,itemCount,taxCodeData,component} = props;
  const [businessGrp, setBusinessGrp] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [taxCodeTypeData, setTaxTypeData] = useState(null);
  const [companyCodeData, setCompanyCodeData] = useState(null);
  const getNameById = (id, list) => {
    const item = list.filter((item) => {
      return item && item.id === id;
    });
    const name = item.length ? item[0].text : 'Not Found';
    return name;
  };
  
  React.useEffect(() => {
    if(groupCodeData && taxCodeData){
      setBusinessGrp(getNameById(taxCodeData.groupCodeId,groupCodeData))
    }
    if(comapnyCodeData && taxCodeData){
      setCompanyCode(getNameById(taxCodeData.companyCodeId,comapnyCodeData))
    }
  }, [groupCodeData,comapnyCodeData,taxCodeData])
  let  formattedTime
  if(taxCodeData){
  const dateObject = new Date(taxCodeData.updatedOn);
  const hours = String(dateObject.getHours()).padStart(2, '0');
  const minutes = String(dateObject.getMinutes()).padStart(2, '0');
   formattedTime = `${hours}:${minutes}`;
  }
  return (
    <Container fluid className={'mt-3'}>
    <Row>
    <Col>
      <Card className={'p-3'}>
        <h4>{props.title}</h4>
      </Card>
      <Card className={'p-3'}>
        <div className="form-row mb-3">
          <Col className="mb-3" md="6">
            <label className="form-control-label">Business Group</label>
            <Input
              value={businessGrp}
              type="text"
              disabled
              maxLength="50"
              style={{ height: "39px" }}
            />
          </Col>
          <Col className="mb-3" md="6">
            <label className="form-control-label">Company Code</label>
            <Input
              value={companyCode}
              type="text"
              maxLength="50"
              disabled
              style={{ height: "39px" }}
            />
            <div className="invalid-feedback"></div>
          </Col>
        </div>
        <div className="form-row mb-3">
          <Col className="mb-3" md="6">
            <label className="form-control-label">Tax Code</label>
            <Input
              value={taxCodeData && taxCodeData.taxCodeIdDispValue}
              type="text"
              maxLength="50"
              style={{ height: "39px" }}
              disabled
            />
          </Col>
          <Col className="mb-3" md="6">
            <label className="form-control-label">Tax Code Description</label>
            <Input
              value={taxCodeData && taxCodeData.taxCodeIdDesc}
              type="text"
              maxLength="50"
              style={{ height: "39px" }}
              disabled
            />
            <div className="invalid-feedback"></div>
          </Col>
        </div>
        <div className="form-row mb-3">
          <Col className="mb-3 d-flex flex-row" md="6">
            <span className="form-control-label">Last Updated Date : </span>
            <span style={{ marginLeft: '4px' }}> { taxCodeData && moment(taxCodeData.updatedOn).format('DD-MM-YYYY')}</span>
            <span className="ml-2">{taxCodeData && formattedTime}</span>
          </Col>
          <Col className="mb-3 d-felx flex-row" md="6">
            <span className="form-control-label">{component === 'tax' ? 'Total Tax Value : ': 'Total Item Type Count : ' } </span>
            <span>{itemCount}{component === 'tax' ? ' %': '' }</span>
          </Col>
          <Col className="mb-3 d-flex flex-row" md="6">
             <span className="form-control-label">Last Updated By : </span> 
            <span style={{ marginLeft: '4px'}}>{ taxCodeData && taxCodeData.updatedBy}</span>
          </Col>
        </div>
        {/* <div className="form-row mb-3">
        <Button
          color="primary"
          type="button"
          className={`ml-auto`}
          onClick={submitHandler}
        >
          Next
        </Button>
      </div> */}
      </Card>
    </Col>
    </Row>
    </Container>
  );
};

export default CommonHeader;
