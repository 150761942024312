import React, { useState, useEffect } from "react";
import PineLabsTable from './pineLabs';
import Loader from 'react-loader-spinner'
import { masterServiceBaseUrl,failureToast, getAPI } from 'services/http';
import { Card, CardHeader, Container, Row ,Col,Button} from "reactstrap";
import { GetPagination } from '../../../common/pagination';

const PineLabsSetup = (props) => {const {
  nextStep = () => {},
  previousStep = () => { },
  pineLabsTableData,
  pineLabsKeyData,
  data,
  action,
  isNew,
  appendAllStepData=()=>{},
          isPrevious,
          saveStepsCompleted=()=>{}
} = props;

  const [isNextDisable, setIsNextDisable] = useState(false);
  const [machineType, setMachineType] = useState([]);
  const [pinelabsPaymentModeIds, setPinelabsPaymentModeIds] = useState([]);
  const [classArray, setclassArray] = useState([]);
  const [pineLabSetupData, setPineLabSetupData] = useState({});
  const [pineLabSetupDataExist, setPineLabSetupDataExist] = useState(false);
  const [ isDisabled, setIsDisabled ] = useState(false);
  const [apiloader, setapiloader] = useState(false);
  const [state, setstate] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null
  })
  const pageSize = 10
  const fetchClasses = async (url) => {
    setapiloader(true)
    getAPI(url, (data) => {
      setstate(data['data'])
      data['data'].length > 0 ? setIsNextDisable(true) :  setIsNextDisable(false)
      setTableData(data['data']);
      let n = pagination
      if (data['data'].length > pageSize) {
        n['nextPage'] = n.currentPage + 1
      }
      if (n.currentPage > 1) {
        n.previousPage = n.currentPage - 1
      }
      n['totalPage'] = Math.floor(data['data'].length / pageSize)
      if (data['data'].length % pageSize != 0) {
        n['totalPage'] += 1
      }
      setpagination(n)
      setapiloader(false)
      setclassArray(data['data'].slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || [])
    }, (data) => { failureToast(data['message']) })
  }
  const nextPage = () => {
    setclassArray(state.slice((pagination.currentPage + 1) * (pageSize) - pageSize, (pagination.currentPage + 1) * pageSize))
    let n = pagination
    n['currentPage'] = n['currentPage'] + 1
    n['previousPage'] = n.currentPage
    setpagination(n)
  }
  const firstPage = () => {
    setclassArray(state.slice(1 * (pageSize) - pageSize, 1 * pageSize))
    let n = pagination
    n['currentPage'] = 1
    n['previousPage'] = null
    setpagination(n)
  }
  const lastPage = () => {
    setclassArray(state.slice((pagination.totalPage) * (pageSize) - pageSize, (pagination.totalPage) * pageSize))
    let n = pagination
    n['currentPage'] = n.totalPage
    n['previousPage'] = n.totalPage - 1
    setpagination(n)
  }
  const previousPage = () => {
    if (pagination.currentPage != 1) {
      setclassArray(state.slice((pagination.currentPage - 1) * (pageSize) - pageSize, (pagination.currentPage - 1) * pageSize))
      let n = pagination
      n['currentPage'] = n['currentPage'] - 1
      if (n['currentPage'] == 1) {
        n['previousPage'] = null
      } else {
        n['previousPage'] = n.currentPage
      }
      setpagination(n)
    }
  }


  const fetchData = (url, stateMethod, name) => {
    setapiloader(true);
    getAPI(
      url,
      (data) => {
        let list = [];
        //this is for dropdown fields only
        const keyName = `${name}DisplayValue`;
        const key = `${name}Description`;
        const key1 = `${name}Key`;
        list = data.data.map((data) => {
          return {
            value: data.id,
            label: data[keyName],
            // desc: data[key],
            // key: data[key1],
          };
        });
        stateMethod(list);
        setapiloader(false);
      },
      (data) => {
        setapiloader(false);
        failureToast(data['message']);
      }
    );
  };

  const fetchMachineType = async (url) => {
    setapiloader(true)
    getAPI(url, (data) => {
      const machineType = data.data.map(
        data => {
          const {
            id,
            machineTypeDispValue,
            status
          } = data;
          if (status === 'ACTIVE') {
            return {
              id: id,
              text: machineTypeDispValue
            };
          }
        }
      );
      setMachineType(machineType)
    }, (data) => { failureToast(data['message']) })
  }


  useEffect(() => {
    if(pineLabsTableData && isPrevious){
      performPaginationForData();
      setPineLabSetupData( pineLabsKeyData)
    }
  }, [pineLabsTableData,isPrevious])


  const toNextStep = () => {
    !isPrevious && props.isNew && saveStepsCompleted(10)
    // appendAllStepData({pineLabsTableData: classArray,pineLabsKeyData:pineLabSetupData },'thenthForm')
    props.nextStep()
  }

  useEffect(() => {
    if(props.isPrevious && props.formId){
      setapiloader(true)
      fetchClasses(`${masterServiceBaseUrl}/pinelabs/setup/businessArea/${props.formId}`)
   }
  }, [props.isSaveVisibleisPrevious,props.formId])

  useEffect(()=>{
    return()=>{
      if(state.length)
      !props.isPrevious && props.isNew && props.saveStepsCompleted(10)
    }    
  },[state])

  const fetchPineLabSetupData = async (url) => {
    setapiloader(true)
    getAPI(url, (data) => {
      setPineLabSetupData(data.data);
      if(Object.keys(data.data).length === 0 && data.data.constructor === Object){
        setPineLabSetupDataExist(false)
      }else{
        setPineLabSetupDataExist(true)
      }
      setapiloader(false)
    }, (data) => { failureToast(data['message']) })
  }

  const performPaginationForData = () => {
    try {
      
        setapiloader(true)
          setstate( pineLabsTableData );
          setTableData(pineLabsTableData);
          let n = pagination
          if (pineLabsTableData.length > pageSize) {
            n['nextPage'] = n.currentPage + 1
          }
          if (n.currentPage > 1) {
            n.previousPage = n.currentPage - 1
          }
          n['totalPage'] = Math.floor(pineLabsTableData.length / pageSize)
          if (pineLabsTableData.length % pageSize != 0) {
            n['totalPage'] += 1
          }
          setpagination(n)
          setapiloader(false)
          setIsNextDisable(true)
          setclassArray(pineLabsTableData.slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || [])
    } catch ( e ) {
      console.log('Error ::',e)
    }
  }

  useEffect(() => {
    fetchClasses(masterServiceBaseUrl + '/pinelabs/posimei/mapping/businessArea/'+ props.businessAreaId);
    fetchData(
      masterServiceBaseUrl + '/pinelabs/payment/modes',
      setPinelabsPaymentModeIds,
      'pinelabsPaymentMode' 
    );
    fetchPineLabSetupData(masterServiceBaseUrl + '/pinelabs/setup/businessArea/'+ props.businessAreaId);
    fetchMachineType( masterServiceBaseUrl + '/machineType/getAllMachineType' )
    if ( action === 'edit' || action === 'view' ) {
      performPaginationForData();
      setIsDisabled(true);
      setPineLabSetupData( pineLabsKeyData)
    }
  }, [])
  return (
    <>
      {apiloader ? <div className="mx-auto text-center py-5 my-5" style={{ height: "100vh" }}><Loader
        type="Rings"
        color="#00BFFF"
        height={70}
        width={70}
        timeout={10000} /></div> : <div></div>
      }
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card className='p-0 mb-0'>
              { action === 'new' ?
                <CardHeader className='pl-3'>
                <h3 className="mb-0 floatLeft">Pine Labs Setup</h3>
              </CardHeader>:null}
              <PineLabsTable previousPage={previousPage} fetchClasses={fetchClasses} setclassArray={setclassArray} classArray={classArray} 
                tableData={tableData} pagination={pagination} setstate={setstate}  
                businessAreaId={props.businessAreaId}
                pineLabSetupData={pineLabSetupData}
                setPineLabSetupData={setPineLabSetupData}
                pineLabSetupDataExist={pineLabSetupDataExist}
                fetchPineLabSetupData={fetchPineLabSetupData}
                pinelabsPaymentModeIds={pinelabsPaymentModeIds}
                machineType={machineType}
                setIsNextDisable={ setIsNextDisable }
                isDisabled={ isDisabled }
                setIsDisabled={setIsDisabled}
                setpagination={ setpagination }
                action={action}
                isNew={props.isNew}
                isPrevious={props.isPrevious}
                saveStepsCompleted={ () => props.saveStepsCompleted(10)}
                />
              {pagination.totalPage > 1 ?
                <GetPagination fetchClasses={fetchClasses} pagination={pagination} previousPage={previousPage} firstPage={firstPage}
                  lastPage={lastPage} nextPage={nextPage} setpagination={setpagination} /> : null
              }
            </Card>
            <Card style={{marginTop:'20px'}}>
              { (action !== 'edit' && action !== 'view') ?
                <Row>
                  <Col md={6}>
                      <Button
                          color="primary"
                          type="button"
                          onClick={() => {
                            previousStep();
                          }}
                        >
                            Previous
                      </Button>
                  </Col>
                  <Col md={6}>
                      <Button
                          disabled={!isNextDisable}
                          color="primary"
                          className={'floatRight ml-auto'}
                          type="button"
                          onClick={() => {
                            toNextStep();
                          }}
                        >
                            Next
                      </Button>
                  </Col>
                </Row>:null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
export default PineLabsSetup;
