import React, { createContext, useEffect, useMemo, useState } from 'react';
import { endpoint } from 'views/pages/manage/common/constant';
import { fetchDeletePromisedData } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { fetchAllPostPromisedData } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { generateRandomId } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { fetchAllPromisedData } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';

export const UserFavouritesContext = createContext();
const UserFavouritesContextProvider = ({
  children,
  permissions,
  ...restProps
}) => {
  const [favourites, setFavourites] = useState([]);
  const [loader, setLoader] = useState(false);

  const rollbackFakeUpdate = prevState => {
    setFavourites(prevState);
  };
  const getFavourites = async () => {
    setLoader(true);
    try {
      const res = await fetchAllPromisedData(
        endpoint.userFavourites.getAll,
        true
      );
      // console.log(res);
      if (res?.code === 200) {
        setFavourites(res?.data);
        setFavourites(res?.data);
        // localStorage.setItem('userFavourites', JSON.stringify(res?.data));
      } else {
        setFavourites([]);
        setFavourites([]);
        // localStorage.setItem('userFavourites', []);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  const addFavourite = async (screenName, pathname, screenId) => {
    setLoader(true);
    const prevState = [...favourites];
    const newFav = {
      id: generateRandomId(),
      screenId,
      screenName,
      pathUrl: pathname,
      status: 'ACTIVE',
    };
    setFavourites(prev => [...prev, newFav]);
    try {
      const res = await fetchAllPostPromisedData(
        endpoint.userFavourites.create,
        newFav
      );
      // console.log(res, newFav);
      if (res?.code === 200) {
        await getFavourites();
      } else {
        rollbackFakeUpdate(prevState);
      }
    } catch (error) {
      rollbackFakeUpdate(prevState);
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  const deleteFavourite = async id => {
    setLoader(true);
    const prevState = [...favourites];
    setFavourites(prev => [...prev?.filter(item => item?.id !== id)]);
    try {
      const res = await fetchDeletePromisedData(
        `${endpoint.userFavourites.delete}?ids=${id}`
      );
      // console.log(res);
      if (res?.code === 200) {
        await getFavourites();
      } else {
        rollbackFakeUpdate(prevState);
      }
    } catch (error) {
      rollbackFakeUpdate(prevState);
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  // const onStorageChange = e => {
  //   const {key, newValue} = e;
  //   // console.log('storage changed', favourites, e)
  //   if(key==='userFavourites') {
  //     setFavourites(JSON.parse(newValue));
  //   }
  // }

  useEffect(() => {
    getFavourites();
    // window.addEventListener('storage', onStorageChange);

    return () => {
      // window.removeEventListener('storage', onStorageChange);
    }
  }, []);

  return (
    <UserFavouritesContext.Provider
      value={{
        loader,
        favourites,
        getFavourites,
        addFavourite,
        deleteFavourite,
      }}
    >
      {children}
    </UserFavouritesContext.Provider>
  );
};

export default UserFavouritesContextProvider;
