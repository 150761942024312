import * as React from 'react';
import Popover from '@mui/material/Popover';

export default function APopover({children , anchorEl , handleClose, id, customSx={}}) {


  const open = Boolean(anchorEl);

  return (
    <div>
      <Popover
        id={id}
        open={open}
         anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={customSx}
      >
        {children}
      </Popover>
    </div>
  );
}