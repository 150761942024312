import {Button} from "reactstrap";
import React,{Fragment} from "react";
import {Delete} from "../../../../assets/img/icons/common/CommonIcons";
const DeleteButton =(props)=>{
    const {text,permissions={update:true,read:true,delete:true}} = props;
    return (
        <Fragment>
            {
                permissions.delete ?
                <Button type="button" color="danger" size="sm" {...props}>
                        {Delete}{text ? "nbsp;" + text : null}
                </Button>
            :
                    null
            }
        </Fragment>
    )
}
export default DeleteButton;