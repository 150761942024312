import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import ADataGrid from "views/pages/manage/common/data-grid/ADataGrid";
import { AGridColDef } from "views/pages/manage/common/data-grid/ADataGridColDef";
import NoRowOverlay from "../../../../masters/holidayMaster/NoRowOverlay";
import AAutoComplete from "../../../../common/form-fields-mui/AAutoComplete";
import {
  editCellContainerStyle,
  selectStyles,
  newRow,
  typeDropdown,
} from "./constant";
import StatusCellComponent from "./StatusCellComponent";
import { failureToast } from "../../../../common/utils/methods/toasterFunctions/function";
import {
  generateRandomId,
  isObjEmpty,
} from "../../../../common/utils/methods/commonMethods/utilityMethod";
import { GridRowModes } from "@mui/x-data-grid";
import ActionsCellComponent from "./ActionsCellComponent";
import {
  gridStyles,
  gridComponents,
} from "views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant.js";
import { Tooltip } from "@mui/material";
import SelectMaterialElasticSearch from "./SelectMaterialElasticSearch";
import { saveOrDeleteMsg } from "../sapProfitCostCentreMapping/constant";

const ListComponent = forwardRef(
  (
    {
      rows,
      groupTypeDropdown,
      loader,
      currentPage,
      nextButtonHandler,
      rowCount,
      createHandler,
      updateHandler,
      toggleStatusHandler,
      deleteHandler,
    },
    ref
  ) => {
    const [rowModesModel, setRowModesModel] = useState({});
    const [rowData, setRowData] = useState(rows);
    useEffect(() => {
      setRowData(rows);
    }, [rows]);
    const [rowForm, setRowForm] = useState({});
    const rowFormHandler = (value, key) =>
      setRowForm((prev) => ({
        ...prev,
        [key]: value,
        fieldToFocus: "groupTypeId",
      }));

    const startEditing = (id, form) => {
      setRowModesModel((oldModel) => ({ [id]: { mode: GridRowModes.Edit } }));
      setRowForm(form);
    };

    const stopEditing = () => {
      const currForm = { ...rowForm };
      setRowModesModel((oldModel) => ({
        [currForm?.id]: { mode: GridRowModes.View, ignoreModifications: true },
      }));
      setRowForm({});
    };

    const handleEditClick = (params) => {
      if (!isObjEmpty(rowForm)) {
        failureToast(saveOrDeleteMsg);
      } else {
        startEditing(params?.id, params?.row);
      }
    };

    const handleCancelClick = async (params) => {
      if (params?.row?.isNew) {
        setRowData(rows);
        setRowForm({});
      } else {
        stopEditing(params?.id);
      }
    };

    const handleSaveClick = async (params) => {
      if (rowForm?.isNew) {
        await createHandler(rowForm);
      } else {
        await updateHandler(rowForm);
      }
    };

    const addNewRow = async () => {
      if (!isObjEmpty(rowForm)) {
        failureToast(saveOrDeleteMsg);
      } else {
        let randomId = generateRandomId();
        let emptyRow = { ...newRow, isNew: true, id: randomId };
        startEditing(randomId, emptyRow);
        setRowData((prev) => [emptyRow, ...prev]);
      }
    };

    useImperativeHandle(ref, () => ({
      addNewRow: addNewRow,
      startEditing: startEditing,
      stopEditing: stopEditing,
    }));

    const column = [
      new AGridColDef("groupType", "Group type", false)
        .setMinWidth(150)
        .editable(true)
        .renderCellComponent((params) => (
          <div>{params?.row?.groupTypeId?.label?.split(" | ")?.[0]}</div>
        ))
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            <AAutoComplete
              currentValue={rowForm?.groupTypeId}
              handleChange={(value) => {
                rowFormHandler(value, "groupTypeId");
                rowFormHandler("", "materialNumber");
              }}
              items={groupTypeDropdown}
              isMulti={false}
              style={selectStyles}
              autoFocus={params?.hasFocus}
            />
          </div>
        )),
      new AGridColDef("groupTypeDesc", "Description", false)
        .setMinWidth(150)
        .editable(true)
        .renderCellComponent((params) => <div>{params?.value || "-"}</div>)
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            {rowForm?.groupTypeId?.shortName || "-"}
          </div>
        )),
      new AGridColDef("materialNumber", "Material number", false)
        .setMinWidth(150)
        .editable(true)
        .renderCellComponent((params) => (
          <div>{params?.value?.value || "-"}</div>
        ))
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            <SelectMaterialElasticSearch
              currentValue={rowForm?.materialNumber}
              handleChange={(value) => rowFormHandler(value, "materialNumber")}
              isMulti={false}
              style={selectStyles}
              dropdownMappingParams={[
                "materialIdAndDescription",
                "materialId",
                "baseUnitOfMeasure",
                "materialDescription",
              ]}
            />
          </div>
        )),
      new AGridColDef("materialDesc", "Material description", false)
        .setMinWidth(150)
        .editable(true)
        .renderCellComponent((params) => (
          <Tooltip title={params?.value}>
            <div className="ellipsis-text-container">
              {params?.value || "-"}
            </div>
          </Tooltip>
        ))
        .renderEditCellComponent((params) => (
          <div
            style={editCellContainerStyle}
            className="ellipsis-text-container"
          >
            {rowForm?.materialNumber?.label?.split(" | ")?.[1] || "-"}
          </div>
        )),
      new AGridColDef("type", "Type", false)
        .setMinWidth(120)
        .editable(true)
        .renderCellComponent((params) => (
          <div>{params?.row?.type?.label || "-"}</div>
        ))
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            <AAutoComplete
              currentValue={rowForm?.type}
              handleChange={(value) => rowFormHandler(value, "type")}
              items={typeDropdown}
              isMulti={false}
              style={selectStyles}
              autoFocus={params?.hasFocus}
            />
          </div>
        )),
      new AGridColDef("status", "Status", false)
        .setMinWidth(120)
        .renderCellComponent((params) => (
          <StatusCellComponent
            params={params}
            toggleStatusHandler={toggleStatusHandler}
            rowForm={rowForm}
            rowFormHandler={rowFormHandler}
          />
        )),
      // .renderEditCellComponent(params => <div style={editCellContainerStyle}><StatusEditCellComponent rowForm={rowForm} rowFormHandler={rowFormHandler} /></div>),
      new AGridColDef("actions", "Actions", false)
        .setMinWidth(150)
        .columnAlign("right")
        .setFlex(0.5)
        .renderCellComponent((params) => (
          <ActionsCellComponent
            params={params}
            handleEditClick={handleEditClick}
            handleCancelClick={handleCancelClick}
            handleSaveClick={handleSaveClick}
            rowForm={rowForm}
            deleteHandler={deleteHandler}
          />
        )),
    ];

    return (
      <div className="mt-3">
        <ADataGrid
          removeWrapperContainer
          rowModesModel={rowModesModel}
          rows={rowData || []}
          columns={column}
          rowId={(row) => row.id}
          rowHeight={50}
          loading={false}
          rowCount={rowCount}
          nextButtonHandler={nextButtonHandler}
          currentPage={currentPage}
          components={{
            ...gridComponents,
            NoRowsOverlay: () => (
              <NoRowOverlay message={loader ? "Loading..." : null} />
            ),
          }}
          sx={{
            ".MuiDataGrid-main": {
              height: `${60 + rowData?.length * 50}px`,
              minHeight: `${60 + 50}px`,
              maxHeight: `${60 + 50 * 50}px`,
            },
            ...gridStyles,
          }}
        />
      </div>
    );
  }
);

export default ListComponent;
