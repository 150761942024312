import { useGridApiContext } from '@mui/x-data-grid';
import React, { useContext, useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { Card, CardBody, Modal, Row, Col } from 'reactstrap';
import { FEE_COMPONENTS } from '.';
import CustomButton from '../../../../../../../components/CustomButton';
import ConsessionTreeClassification from '../../../../common/commonComponents/treeView';
import { endpoint } from '../../../../common/constant';
import { fetchAllPromisedData } from '../../../../common/utils/methods/commonMethods/utilityMethod';
import { failureToast, successToast } from '../../../../common/utils/methods/toasterFunctions/function';
import CommonInput from '../../../../common/formFeilds/input/commonInput';
import CustomLoader from '../../../../common/commonComponents/Loader/loader';
import { getCurrentYear } from '../common';
import moment from 'moment';
import AInput from "views/pages/manage/common/formFeilds/customInput/AInput";

const lumpsumCategory = ['full first year', 'full upfront', 'year on year'];

const CategorySelector = (params) => {
    const { id, value, field, row, applicationDetails, allCategories, userPermissions, prospectusSlabs, isCategorySelectorDisabled = () => { } } = params;
    const apiRef = useGridApiContext();
     console.log(allCategories, "allCategories")
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(value);
    const [concessionSlabData, setConcessionSlabData] = useState([]);
    const [concessionDetails, setConcessionDetails] = useState(null);
    const [inputVal, setInputVal] = useState(row.scholarshipCriteriaValue);
    const [loading, setLoading] = useState(false);
    const [concessionCategory, setConcessionCategory] = useState('');
    // this all data contain categories, subCategory details
    const [allData, setAllData] = useState(allCategories);
    const [filteredData, setFilteredData] = useState(allCategories);
    const [searchedStr, setSelectedStr] = useState('')

    useEffect(() => {
        if (selected) {
            getCategoryDetails(selected);
            console.log("selected", selected)
        }
    }, [selected]);

    useEffect(()=>{
        const consolidatedData = allCategories.filter(c => c.categoryCode === row.code?.[0]?.value)
        setAllData(consolidatedData)
        setFilteredData(consolidatedData)
        setSelectedStr('');
     },[row, allCategories])


    useEffect(()=>{
       if(searchedStr){
        applyFilter()
       }else{
        setFilteredData(allData)
       }
    },[searchedStr])

    const applyFilter = ()=>{
         // filter in sub sub categories
         // filter in sun categories
         // filter in categories
         const filteredResult = filterData(allData, searchedStr);
         setFilteredData(filteredResult)
    }

    function filterData(data, searchValue) {
        const filteredData = [];
      
        data.forEach(category => {
          if (category.categoryDispVal.toLowerCase().includes(searchValue.toLowerCase())) {
            filteredData.push(category);
            return;
          }
      
          const subcategories = [];
      
          category.subCategories.forEach(subcategory => {
            if (subcategory.subCategoryDispVal.toLowerCase().includes(searchValue.toLowerCase())) {
              subcategories.push(subcategory);
              return;
            }
      
            const subsubcategories = [];
      
            subcategory.subSubCategory.forEach(subsubcategory => {
              if (subsubcategory.subSubCategoryDisplayValue.toLowerCase().includes(searchValue.toLowerCase())) {
                subsubcategories.push(subsubcategory);
              }
            });
      
            if (subsubcategories.length > 0) {
              subcategory.subSubCategory = subsubcategories;
              subcategories.push(subcategory);
            }
          });
      
          if (subcategories.length > 0) {
            category.subCategories = subcategories;
            filteredData.push(category);
          }
        });
      
        return filteredData;
      }

    useEffect(() => {
        if (value) {
            setSelected(value);
            setConcessionCategory(params?.row?.categoryDetails);
        } else {
            setSelected(null);
            setConcessionDetails(null);
            setInputVal('');
            setConcessionCategory('');
        }
    }, [value]);

    const getCategoryDetails = async (category) => {
        setLoading(true);
        console.log(category)
        try {
            let concessionsResp = await fetchAllPromisedData(
                category.isSubSubCategory ? `${endpoint.getConcessionSlabBySubSubCategory}/${category.id}?status=ACTIVE` :
                    `${endpoint.getConcessionSlabBySubCategory}/${category.id}?status=ACTIVE`);

            const detailsResp = await fetchAllPromisedData(category.isSubSubCategory ? `${endpoint.subSubCategory}/${category.id}` :
                `${endpoint.subCategory}/${category.id}`);
            setLoading(false);
            if (concessionsResp && detailsResp) {
                if(open && detailsResp?.availability === 'AUTO'){
                    failureToast("This is an auto-apply concession. Please use the auto concession feature to apply it.");
                    setSelected(null);
                    return;
                }
                setConcessionSlabData(concessionsResp);
                setConcessionDetails(detailsResp);
            } else {
                failureToast("Failed to fetch Data");
            }
        } catch (error) {
            failureToast("Failed to fetch Data");
            setLoading(false);
        }
    }

    const onSubCategoryClick = (c, sc) => {
        if (sc.subCategoryExternalId === selected?.subCategoryExternalId) {
            setSelected(null);
            return
        }
        if (prospectusSlabs?.find(i => i.categoryId === c.id && i.id !== row.id)) {
            failureToast("This concession category is already added.");
            return
        }
        if (sc.subSubCategory.length === 0) {
            setConcessionCategory(c);
            setSelected({ ...sc, isSubSubCategory: false });
            setInputVal('');
        }
    }

    const onSubSubCategoryClick = async (c, sc, ssc, sname) => {
          
        if (ssc.subSubCategoryExternalId === selected?.subSubCategoryExternalId) {
            setSelected(null);
            return
        }
        if (prospectusSlabs?.find(i => i.categoryId === c.id && i.id !== row.id)) {
            failureToast("This concession category is already added.");
            return
        }
        setConcessionCategory(c);
    
        const concessionType = sname?.split("_")?.[0] ?? '';

        if (concessionType === 'LUMPSUM') {
            const year = await getCurrentYear(applicationDetails);
            if (!year) {
                failureToast("Student is not eligible for the selected concession.");
                return
            }
            if (year === 1 && sname.toLowerCase() === 'lumpsum_year_on_year') {
                failureToast("Student is not eligible for the selected concession.");
                return
            }
            if (year > 1 && (sname === 'LUMPSUM_First_Year' || sname === 'LUMPSUM_Full_Course')) {
                failureToast("Student is not eligible for the selected concession.");
                return
            }
        }

        setSelected({ ...ssc, isSubSubCategory: true, concessionMethodType: sname ? sname : '', isLumpsum: sname && concessionType === 'LUMPSUM' ? true : false });
        setInputVal('');
    }

    const onlumsumClick = (lumpsum) => {
        // setSelected({ ...lumpsum, isLumpsum: true });
        // setInputVal('');
    }

    const getSuitedSlab = () => {
        let suitedSlab = null;
        if (concessionDetails?.scholarshipCriteria === 'QUALIFIED') {
            // console.log(concessionSlabData?.[0])
            // suitedSlab = concessionSlabData?.[0];
            suitedSlab = concessionSlabData?.find(slab => isPastOrFutureDate(slab?.effectiveFrom , slab?.effectiveTo));
        } else {
            suitedSlab = concessionSlabData.find(item => (+inputVal >= +item.rangeFrom && +inputVal <= +item.rangeTo));
        }

        switch (false) {
            case (!!suitedSlab):
                return { suitedSlab, message: "No slab with the given criteria found." }
            case (suitedSlab.academicCareerId[0] === 0 || suitedSlab.academicCareerId.includes(applicationDetails.academicCareerId)):
                return { suitedSlab, message: "Academic Career does not match." }
            case (suitedSlab.businessAreaId[0] === 0 || suitedSlab.businessAreaId.includes(applicationDetails.businessAreaId)):
                return { suitedSlab, message: "Business Area does not match." }
            case (suitedSlab.courseCategoryId[0] === 0 || suitedSlab.courseCategoryId.includes(applicationDetails.courseCategoryId)):
                return { suitedSlab, message: "Course Category does not match." }
            case (suitedSlab.courseId[0] === 0 || suitedSlab.courseId.includes(applicationDetails.courseId)):
                return { suitedSlab, message: "Course Id does not match." }
            case (suitedSlab.termId[0] === 0 || suitedSlab.termId.includes(applicationDetails.termId)):
                return { suitedSlab, message: "Term Id does not match." }
            default:
                return { suitedSlab, message: "" }
        }
    }

    const isStudentEligibleExAakashian = async () => {
        try {
            const resp = await fetchAllPromisedData(`${endpoint.configExAakashian.validate}/${applicationDetails?.psid}`, true);
            if (resp?.code === 200) {
                if (resp?.data) {
                    successToast(`${applicationDetails?.psid} is eligible for ex-aakashian concession.`)
                    return true;
                } else {
                    failureToast("Student Doesn't meet the Ex-aakashian criteria for applying this Concession")
                    return false;
                }
            }
            return false;
        } catch (err) {
            console.log(err);
        }
    }

    const exAakashianConcAlreadyApplied = (exAakashianCategoryId, rowId) => {
        // console.log('rowId---->>>', rowId);
        let isExAakashianConcAlreadyApplied = false;
        Array.isArray(prospectusSlabs) && prospectusSlabs.forEach((slab) => {
            if (slab?.categoryId === exAakashianCategoryId && slab?.id !== rowId)
                isExAakashianConcAlreadyApplied = true;
        })
        return isExAakashianConcAlreadyApplied;
    }

    const isPastOrFutureDate = (sDate,eDate) => {
        const today = moment(new Date());
        return moment(sDate).startOf('day') <= today && today <= moment(eDate).endOf('day');
    }

    const handleSave = async () => {
     
        const { suitedSlab, message } = getSuitedSlab();
        debugger
        if (concessionCategory?.categoryDispVal?.toLowerCase() === 'ex-aakashian') {
            // console.log('category---->>>',concessionCategory);
            if (exAakashianConcAlreadyApplied(concessionCategory?.id, params?.row?.id)) {
                failureToast("Ex-aakashian concession category already added");
                return;
            }
            const isEligible = await isStudentEligibleExAakashian()
            if (!isEligible) return;
        }

        const isValidSlab = isPastOrFutureDate(suitedSlab?.effectiveFrom, suitedSlab?.effectiveTo);
        if(!isValidSlab) {
            failureToast("The Applied concession is expired, try contacting your administrator or try applying for another concession.")
            return
        }

        if (suitedSlab && message === "") {
            apiRef.current.setEditCellValue({ id, field, value: selected?.isLumpsum ? { ...selected, feeComponent: concessionDetails?.feeComponent ?? [] } : selected });
            apiRef.current.setEditCellValue({ id, field: 'scholarshipCriteriaValue', value: selected?.isLumpsum ? 0 : +inputVal });
            apiRef.current.setEditCellValue({ id, field: 'scholarshipCriteria', value: concessionDetails?.scholarshipCriteria });
            apiRef.current.setEditCellValue({ id, field: 'concessionSlabId', value: suitedSlab?.id || null });
            apiRef.current.setEditCellValue({ id, field: 'adminFeeConcession', value: suitedSlab?.admissionFee || 0 });
            apiRef.current.setEditCellValue({ id, field: 'regFeeConcession', value: suitedSlab?.regFee || 0 });
            apiRef.current.setEditCellValue({ id, field: 'classFeeConcession', value: suitedSlab?.classFee || 0 });
            apiRef.current.setEditCellValue({ id, field: 'techFeeConcession', value: suitedSlab?.techFee || 0 });
            apiRef.current.setEditCellValue({ id, field: 'tutionFeeConcession', value: suitedSlab?.tuitionFee || 0 });
            apiRef.current.setEditCellValue({ id, field: 'concessionParameter', value: concessionDetails?.concessionParameter });
            apiRef.current.setEditCellValue({ id, field: 'categoryId', value: concessionCategory?.id ?? params?.row?.categoryId });
            setOpen(false);
        } else {
            failureToast(message);
        }
    }

    const onClose = () => {
        if (!inputVal) {
            setSelected(null)
            setConcessionDetails(null)
            apiRef.current.setEditCellValue({ id, field, value: null });
        } else {
            apiRef.current.setEditCellValue({ id, field, value: value });
        }
        setOpen(false);
    }

    const onInputChange = (val) => {
        let maxVal = 9999999;
        switch (concessionDetails?.scholarshipCriteria) {
            case 'PERCENTAGE':
            case 'PERCENTILE': maxVal = 100;
                break;
            case 'RANK': maxVal = 9999999;
                break;
            case 'MARKS':
            case 'GRADES': maxVal = 9999;
                break;
            default:
                maxVal = 9999999;
        }
        if (val <= maxVal) {
            setInputVal(val);
        }
    }

    const isDisabled = () => {
        if (concessionDetails?.scholarshipCriteria === "QUALIFIED" || selected?.isLumpsum) {
            return false;
        } else {
            if (selected === null || inputVal === '') {
                return true;
            } else {
                return false;
            }
        }
    }

    const content = <span style={{ overflow: 'hidden', display: 'block', textOverflow: 'ellipsis', maxWidth: '85%' }}>{params.value?.subSubCategoryDisplayValue ?? params.value?.subCategoryDispVal ?? params.value?.displayVal ?? 'Select'}</span>
    return (
        <>
            <CustomButton
                content={content}
                permissionType={'R'}
                forTable={false}
                permissionSet={userPermissions}
                onClick={() => row.code.length !== 0 && setOpen(true)}
                outline={true}
                className="w-100 bg-light-blue"
                disabled={isCategorySelectorDisabled(row)}
            />
            <Modal
                isOpen={open}
                centered={true}
            >
                <Card className='mb-0'>
                    <CardBody>
                        <Row className="d-flex justify-content-between align-items-center pl-3 pr-3 mb-3">
                            <h2>Select category</h2>
                            <IoClose size={20} style={{ cursor: "pointer" }} onClick={onClose} />
                        </Row>
                        <Row className="mb-3 w-100 p-2 ">
                            <Col md ="12">
                            <AInput placeholder ="Search by category, sub-category or sub-sub-category" value = {searchedStr} handler = {(e)=>setSelectedStr(e)}/>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <ConsessionTreeClassification
                                classificationData={filteredData}
                                fetching={false}
                                isFilterApplied={false}
                                onCategoryClick={(c) => { }}
                                onSubCategoryClick={onSubCategoryClick}
                                onSubSubCategoryClick={onSubSubCategoryClick}
                                onlumsumClick={onlumsumClick}
                                showSelectBtn={true}
                                showSelectBtnForConcessionOnly={true}
                                selected={selected}
                                applicationDetails={applicationDetails}
                                forStudentCharges={true}
                            // expandedArr={[`${selected?.categoryId}-c`, `${selected?.isSubCategory ? selected?.id : selected?.subCategoryId}-sc`, `${selected?.isSubSubCategory ? selected?.id : ''}-ssc`]}
                            />
                        </Row>
                        {loading ? <CustomLoader loaderHeight={'129px'} /> :
                            <>
                                {selected && concessionDetails?.scholarshipCriteria !== "QUALIFIED" && concessionDetails?.scholarshipCriteria && !selected?.isLumpsum && !lumpsumCategory.includes(params.value?.subSubCategoryDisplayValue?.toLowerCase()) &&
                                    <>
                                        <div>Enter student's {concessionDetails?.scholarshipCriteria.toLowerCase()}</div>
                                        <CommonInput
                                            type={'text'}
                                            defaultValue={inputVal}
                                            placeHolder={''}
                                            maxLength={400}
                                            onChange={onInputChange}
                                            forTable={false}
                                            mandatory={true}
                                        />
                                    </>
                                }
                                <CustomButton
                                    content={'Save'}
                                    permissionType={'C,U'}
                                    forTable={false}
                                    permissionSet={userPermissions}
                                    onClick={handleSave}
                                    disabled={isDisabled()}
                                />
                            </>}
                    </CardBody>
                </Card>
            </Modal>
        </>
    )
}

export default CategorySelector;