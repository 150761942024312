import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router';
import { Card, CardHeader, Container, Row, Table } from 'reactstrap'
import { CustomContainer, Accordion, Dialog } from "views/pages/manage/common/commonComponents";
import CustomButton from '../../../../../components/CustomButton';
import { PermissionContext, RolePermissions } from "appContext";
import { ConfirmationDialog, PermisionDenied } from "../../common/commonComponents";
import { constants, endpoint, pages, regex } from "../../common/constant";
import { FieldsRenderer } from './component'
import fieldObjects from './form.json'
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import ProjectAndRoleTable from './projectAndRoleTable';
import {
    putpost,
} from 'services/http';
import Preview from './preview';
import CustomLoader from '../../common/commonComponents/Loader/loader';
import moment from 'moment';
import { MasterHeaderAuto } from '../../common/commonComponents/masterHeader/masterHeader';
import CreateSecurityGroup from '../../userSecurity/securityGroup/CreateForm';

const projectObj = {
    project: '',
    role: [],
    userId: '',
    newUserNotification: false,
    status: 'ACTIVE',
    userIdIsEmailId: '',
    // userIdIsEmailId:false
}

const headerList = [
    { name: 'Serial Number' },
    { name: 'Ticket ID' },
    { name: 'Ticket Date' },
    { name: 'Ticket Description' },
    { name: 'User Id'},
    { name: 'Name'}
]

const CreateProfile = (props) => {
    const history = useHistory();
    const permissions = useContext(PermissionContext);
    const userPermissions = RolePermissions(permissions, pages["createUserProfile"]['id']);

    const action = props.match.params.action;
    const id = props.match.params.id;
    // const project = props.match.params.project;

    const [personData, setPersonData] = useState({ fetching: false, data: [] });
    const [status] = useState({ fetching: false, data: [{ label: 'ACTIVE', value: 'ACTIVE' }, { label: 'SEPARATED', value: 'SEPARATED' }] })
    const [employeeData, setEmployeeData] = useState({ fetching: false, data: [] });
    const [designationData, setDestinationData] = useState({ data: [], fetching: true });
    const [departmentData, setDepartmentData] = useState({ data: [], fetching: true });

    const [isTicketDetailValid, setTicketDetailValid] = useState(false)
    const [isUserDetailValid, setUserDetailValid] = useState(false)
    const [isRoleValid, setRoleValid] = useState(false)
    const [ticketDetails, setTicketDetails] = useState({
        ticketId: '',
        ticketDate: null,
        ticketDesc: ''
    })
    const [userDetails, setUserDetails] = useState({
        userProfileTypeId: null,
        accountStatus: { label: 'ACTIVE', value: 'ACTIVE' },
        employeeId: null
    })
    const [projectDetails, setProjectDetails] = useState([{ ...projectObj }])
    const [isVendor, setVendor] = useState(false)
    const [userDetailObject, setUserDetailObject] = useState(fieldObjects.userDetails)
    const [isPreviewEnable, setIsPreviewEnable] = useState(false)

    const [isFormSubmiting, setFormSubmitting] = useState(false)
    const [confirmationDialog, setConfirmationDialog] = useState(false)
    const [confirmationSucess, setConfirmationSucess] = useState(false)

    const [rolesData, setRolesData] = useState([])
    const [detailsData, setDetailsData] = useState({})
    const [projectData, setProjectData] = useState([])
    const [isFetching, setFetching] = useState(true)
    const [isReadOnly, setReadOnly] = useState(false)

    const [isDailogHistoryOpen, setDialogHistoryOpen] = useState(false);
    const [ticketHistory, setTicketHistory] = useState([])

    const [isEmployeeDetails, setEmployeeDetails] = useState(false)
    const [isEmplyeeSaving, setEmployeeSaving] = useState(false)

    const [isUserSeperated, setUserSeperated] = useState(false);


    const [securityGroup, setSecurityGroup] = useState({
        securityGroup: ''
    })
    const [securityGroupData, setSecurityGroupData] = useState({ fetching: false, data: [] })

    const [viewSecurity,setViewSecuirty] = useState(false)

    const [roleAndProjectObject,setRoleAndProjectObject] = useState({})

    const [isEmpIdVerified,setEmpIdVerified] = useState(false)


    const addNewProjectMapping = () => {
        setProjectDetails([
            ...projectDetails,
            { ...projectObj }
        ])
    }

    //ticket details handler
    const handleTicketDetails = () => {
        if (!ticketDetails.ticketId) {
            failureToast("Please enter ticket ID")
            return
        }
        if (ticketDetails.ticketId && ticketDetails.ticketId.toString().length < 5) {
            failureToast("Please enter valid ticket ID")
            return
        }

        if (!ticketDetails.ticketDesc || !ticketDetails.ticketDesc.toString().trim()) {
            failureToast("Please enter ticket description")
            return
        }

        if (ticketHistory && ticketDetails.ticketId) {
            let valid = true;
            ticketHistory.every((item, i) => {
                if (item?.[0] === ticketDetails.ticketId) {
                    valid = false
                    return false
                } else {
                    return true
                }
            })
            if (!valid) {
                failureToast("Ticket ID should be unique")
                return
            }
        }

        if (!ticketDetails.ticketDate) {
            failureToast("Please enter ticket Date")
            return
        }
        setTicketDetailValid(true)
    }

    //user details handler
    const handleUserDetails = () => {
        if (!userDetails.userProfileTypeId?.value) {
            failureToast("Please select user profile type")
            return
        }
        if (!userDetails.accountStatus?.value) {
            failureToast("Please select account type")
            return
        }
        if (!isVendor && !userDetails.employeeId?.value) {
            failureToast("Please select employee ID")
            return
        }

        if (!isVendor && !userDetails.employeeId?.employeeName) {
            failureToast("Please select employee ID with valid name")
            return
        }

        if (!isVendor && !userDetails?.employeeId?.designation) {
            failureToast("Please select employee ID with valid designation")
            return
        }

        if (!isVendor && !userDetails?.employeeId?.department) {
            failureToast("Please select employee ID with valid department")
            return
        }

        // if (!isVendor && !userDetails?.employeeId?.phoneNumber) {
        //     failureToast("Please select employee ID with valid phone number")
        //     return
        // }

        if (!isVendor && !userDetails?.employeeId?.email) {
            failureToast("Please select employee ID with valid email")
            return
        }

        if (isVendor && !userDetails.employeeId) {
            failureToast("Please enter employee ID")
            return
        }

        if (isVendor && userDetails.employeeId.toString()?.length < 5) {
            failureToast("Please enter valid employee ID")
            return
        }

        if (isVendor && (!userDetails.employeeName || !userDetails.employeeName.toString().trim())) {
            failureToast("Please enter user name")
            return
        }

        if (isVendor && userDetails.employeeName.toString().trim()?.length < 2) {
            failureToast("Please enter valid user name")
            return
        }


        if (isVendor && userDetails.employeeName.split(" ").length > 4) {
            failureToast("Only three whitespace are allowed in employee name")
            return
        }

        if (isVendor && !userDetails?.designation?.value) {
            failureToast("Please select designation")
            return
        }

        if (isVendor && !userDetails?.department?.value) {
            failureToast("Please select department")
            return
        }

        if (isVendor && userDetails.phoneNumber && userDetails.phoneNumber.toString().length !== 10) {
            failureToast("Please enter valid phone number")
            return
        }

        if (isVendor && !regex.email.test(userDetails.email)) {
            failureToast("Please enter valid email")
            return
        }

        // setProjectDetails([{ ...projectObj }])

        setUserDetailValid(true)
    }


    const getPersonData = async () => {
        setPersonData({ ...personData, fetching: true })
        const persons = await fetchAllPromisedData(endpoint.personType.getAllActive, true);
        if (persons?.code === 200) {
            const tempArr = persons?.data.map(person => {
                return { label: person.personTypeDispValue, value: person.id }
            })
            setPersonData({ fetching: false, data: tempArr ?? [] })
        } else {
            setPersonData({ fetching: false, data: [] })
        }
    }

    const getAllEmployee = async () => {
        setEmployeeData({ ...employeeData, fetching: true })
        const employees = await fetchAllPromisedData(endpoint.getUser);

        if (Array.isArray(employees)) {
            const tempArr = employees?.map(employee => {
                return {
                    label: employee.employeeId,
                    value: employee.employeeId,
                    employeeName: employee.employeeName,
                    phoneNumber: employee.mobileNumber,
                    email: employee.email_id,
                    designation: employee.designation, //designationData?.data.filter(item => item.label == employee.designation)?.[0]?.['label'] ?? '',
                    department: employee.department,//departmentData?.data.filter(item => item.label == employee.department)?.[0]?.['label'] ?? '',
                    designationId: designationData?.data.filter(item => item.label == employee.designation)?.[0]?.['value'] ?? '',
                    departmentId: departmentData?.data.filter(item => item.label == employee.department)?.[0]?.['value'] ?? '',
                }
            })
            setEmployeeData({ fetching: false, data: tempArr ?? [] })
        } else {
            setEmployeeData({ fetching: false, data: [] })
        }
    }

    const getProject = async () => {
        const project = await fetchAllPromisedData(endpoint.projectDetails.getAllActiveProject, false);
        if (Array.isArray(project)) {
            const tempData = project?.map(p => { return { label: p.projectDispValue, value: p.id, projectKey: p.projectKey, isMultipleRoles: p.isMultipleRoles, isUseridEmail: p.isUseridEmail, isNewUser: p.isNewUser } })
            setProjectData(tempData)
        }
    }
    const  verifyEmpId = async() => {
        if((typeof userDetails?.employeeId)=='object'&&!userDetails?.employeeId?.value|| (typeof userDetails?.employeeId)!='object'&&!userDetails?.employeeId){
                failureToast("Please enter employee id");
                return;
        }
        const url = (typeof userDetails?.employeeId)=='object'?`${endpoint.getEmployeeDetails}/${userDetails?.employeeId?.value}` :`${endpoint.getEmployeeDetails}/${userDetails?.employeeId}`;
        const details = await fetchAllPromisedData(url, true);
        if(details?.code==200){
        if(details?.data?.status=="INACTIVE"){
            failureToast("Employee Id is inactive")
            return;
        }
        if(details?.data?.isUserProfileCreated){
            failureToast("User profile already exist")
            return;
        }
        setEmpIdVerified(true)
        setUserDetails({
            ...userDetails,
            employeeId:{
            value: details?.data?.employeeId,
            employeeName: details?.data?.employeeName,
            phoneNumber: details?.data?.mobileNumber,
            email: details?.data?.email_id,
            designation: details?.data?.designation,
            department: details?.data?.department,
        },
        accountStatus: { label: details?.data?.status, value: details?.data?.status }})
    }
      };

    useMemo(() => {
        if (userDetails?.userProfileTypeId?.value && action !== 'edit' && action !== 'view') {
            if (userDetails?.userProfileTypeId?.label === 'Employee-AESL') {
                // getAllEmployee()
                setUserDetailObject(fieldObjects.userDetails)
                setVendor(false)
            } else if (userDetails?.userProfileTypeId?.label === 'Employee-Vendor') {
                setVendor(true)
                const temObject = { ...fieldObjects.userDetails }
                // delete temObject['designation']
                // delete temObject['department']
                setUserDetailObject({ ...temObject, ...fieldObjects.forVendor })
            } else {
                failureToast("Please select valid option")
            }
            setEmpIdVerified(false)

            setUserDetails({
                ...userDetails,
                employeeId: '',
                employeeName: '',
                designation: '',
                department: '',
                phoneNumber: '',
                email: '',
            })

            // setProjectDetails([])
        }
    }, [userDetails.userProfileTypeId])


    const getInitailData = async () => {
        const department = await fetchAllPromisedData(endpoint.departmentType.getAll);
        const designation = await fetchAllPromisedData(endpoint.jobTitle.getAll);
        getPersonData()
        getProject()
        getSecurityGroupData();

      

        if (Array.isArray(department)) {
            const tempArr = department.map(d => { return { label: d.departmentTypeDispValue, value: d.id } })
            setDepartmentData({ data: tempArr, fetching: false })
        }
        if (Array.isArray(designation)) {
            const tempArr = designation.map(d => { return { label: d.jobTitleDispValue, value: d.id } })
            setDestinationData({ data: tempArr, fetching: false })
        }
    }


    const getSecurityGroupData = async() => {
        const securityGroup = await fetchAllPromisedData(`${endpoint.securityGroup.common}?fetchMappings=true`);

        if (Array.isArray(securityGroup)) {
            let tempArr = securityGroup.map(d => { 
                if(action === 'edit' || action === 'view'){
                    return { label: d.securityGroupName, value: d.id, status : d.status } 
                }
                if(action === 'new' && d.status === 'ACTIVE'){
                    return { label: d.securityGroupName, value: d.id, status : d.status } 
                }

            })
            tempArr = tempArr.filter(item => item)
            setSecurityGroupData({ data: tempArr, fetching: false })
        }
    }


    //get Ticket Hsitory

    const geTicketHistory = async () => {
        const ticketHistory = await fetchAllPromisedData(`${endpoint.getTicketHistory}/${id}`, true);
        if (ticketHistory && ticketHistory?.code == 200) {
            setFetching(false)
            setTicketHistory(ticketHistory?.data)
        } else {
            setFetching(false)
            setTicketHistory([])
        }

    }

    //edit logics
    const getDetails = async (id) => {
        // setIsFetching(true)
        try {
            setFetching(true)
            const details = await fetchAllPromisedData(`${endpoint.getUserByEmployeeId}/${id}`, true);
            if (details && details?.code == 200) {

                if (action !== 'edit') {
                    setTicketDetails({
                        ticketId: details?.data?.userProfile?.ticket_id ?? '',
                        ticketDate: details?.data?.userProfile?.ticket_date ?? '',
                        ticketDesc: details?.data?.userProfile?.ticketDesc ?? ''
                    })
                    setTicketDetailValid(true)
                }


                if (details?.data?.userProfile?.employeeProfile) {

                    const personType = personData?.data?.filter(item => item.label === details?.data?.userProfile?.employeeProfile);
                    if (personType?.length > 0) {
                        setVendor(true)
                        const temObject = { ...fieldObjects.userDetails }
                        setUserDetailObject({ ...temObject, ...fieldObjects.forVendor })
                        // alert(JSON.stringify(personType))
                        if (details?.data?.userProfile?.status === 'SEPARATED') {
                            setUserSeperated(true);
                            if (action === 'edit') {
                                failureToast("Sorry, user cannot be updated");
                                history.push('/admin/userProfile')
                            }
                        }

                        setUserDetails({
                            userProfileTypeId: personType?.[0] ?? '',
                            accountStatus: { label: details?.data?.userProfile?.status, value: details?.data?.userProfile?.status },
                            employeeId: details?.data?.userProfile?.employeeId,
                            employeeName: `${details?.data?.userProfile?.firstName ?? ''} ${details?.data?.userProfile?.lastName ?? ''}`,
                            phoneNumber: details?.data?.userProfile?.mobileNumber,
                            email: details?.data?.userProfile?.email,
                            designation: designationData?.data?.filter(item => item.value == details?.data?.userProfile?.designationId)?.[0] ?? '',
                            department: departmentData?.data?.filter(item => item.value == details?.data?.userProfile?.departmentId)?.[0] ?? '',
                            designationId: designationData?.data?.filter(item => item.value == details?.data?.userProfile?.designationId)?.[0]?.value ?? '',
                            departmentId: departmentData?.data?.filter(item => item.value == details?.data?.userProfile?.departmentId)?.[0]?.value ?? '',

                        })
                    }
                    setUserDetailValid(true)
                }

                if (details?.data?.userProfile?.status !== 'SEPARATED') {
                    const tempProject = details?.data?.userIdProjectRoleDtoList?.map((el => {
                        // alert(JSON.stringify(projectData.filter(item => item.label === el.clientId)))
                        const roles = el?.roleInformationDtoList?.map(rl => { return { label: rl?.roleName, value: rl?.roleId } })
                        return {
                            project: projectData.filter(item => item.projectKey === el.clientId)?.[0] ?? '',
                            role: roles,
                            status: el?.status ?? 'ACTIVE',
                            isNotDeleteAble: true,
                            tempRoleDto : action === 'view' && !el.tempRoleDto?.tempRoleId ? el.tempRoleDto?.previousTempRole :   el.tempRoleDto,
                            userId: el.userId
                        }
                    }))

                    setProjectDetails(tempProject)
                    if (!!tempProject) {
                        setRoleValid(true)
                        const obj = { label: details?.data?.userIdProjectRoleDtoList?.[0]?.securityGroup?.securityGroupName, value: details?.data?.userIdProjectRoleDtoList?.[0]?.securityGroup?.id }
                        setSecurityGroup({
                            securityGroup: obj ?? ''
                        })
                    }
                }

                if (action === 'view') {
                    setReadOnly(true)
                }
                geTicketHistory()

                // setIsFetching(false)
            } else {
                history.push('/admin/userProfile')
            }
        } catch (e) {
            console.log(e)
        }
    }


    //get Emplyee details
    const getEmployeeDetails = async (id) => {
        try {
            setFetching(true)
            const details = await fetchAllPromisedData(`${endpoint.getEmployeeDetails}/${id}`, true);
            if (details && details?.code == 200) {

                if (action !== 'edit') {
                    setTicketDetails({
                        ticketId: details?.data?.userProfile?.ticket_id ?? '',
                        ticketDate: details?.data?.userProfile?.ticket_date ?? '',
                        ticketDesc: details?.data?.userProfile?.ticketDesc ?? ''
                    })
                    setTicketDetailValid(true)
                }


                if (details?.data) {
                    setVendor(true)
                    const temObject = { ...fieldObjects.userDetails }
                    setUserDetailObject({ ...temObject, ...fieldObjects.forVendor })
                    // alert(JSON.stringify(personType))
                    const tempDesign = designationData?.data?.filter(item => item.label == details?.data?.designation)?.[0];
                    const tempDepart = departmentData?.data?.filter(item => item.label == details?.data?.department)?.[0];

                    const department = !!tempDepart ? tempDepart : { label: details?.data?.department, value: details?.data?.department }
                    const designation = !!tempDesign ? tempDesign : { label: details?.data?.designation, value: details?.data?.designation }

                    setUserDetails({
                        userProfileTypeId: { value: '', label: '' },
                        accountStatus: { value: '', label: '' },
                        employeeId: details?.data?.employeeId,
                        employeeName: `${details?.data?.employeeName ?? ''}`,
                        phoneNumber: details?.data?.mobileNumber,
                        email: details?.data?.email_id,
                        designation: designation ?? '',
                        department: department ?? '',
                        id: details?.data?.id
                        // designationId: designationData?.data?.filter(item => item.value == details?.data?.userProfile?.designationId)?.[0]?.value ?? '',
                        // departmentId: departmentData?.data?.filter(item => item.value == details?.data?.userProfile?.departmentId)?.[0]?.value ?? '',

                    })

                    setUserDetailValid(true)
                }
                // setProjectDetails(tempProject)


                if (action === 'view') {
                    setReadOnly(true)
                }
                // geTicketHistory()
                setFetching(false)
            } else {
                history.push('/admin/userProfile')
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        getInitailData();
    }, [])

    useEffect(() => {
        const profileType = document.URL.split('#')?.[1].split('/')?.[2];

        // if(action){
        //     getSecurityGroupData();
        // }

        if (action === 'new') {
            setFetching(false);
        }

        if ((action === 'edit' || action === 'view') && profileType === 'userProfile' && id && !personData?.fetching && personData?.data?.length > 0 && projectData?.length > 0 && !departmentData?.fetching && !designationData?.fetching) {
            getDetails(id)
        }


        if ((action === 'edit' || action === 'view') && profileType === 'empProfile' && id && !personData?.fetching && personData?.data?.length > 0 && projectData?.length > 0 && !departmentData?.fetching && !designationData?.fetching) {
            setEmployeeDetails(true)
            getEmployeeDetails(id)
        }

        if (action !== 'new' && action !== 'edit' && action !== 'view') {
            history.push('/admin/userProfile')
        }
    }, [action, id, personData, projectData, departmentData, designationData])


    // useEffect(()=>{
    //     const profileType = document.URL.split('#')?.[1].split('/')?.[2];

    // },[id,action])


    const handlePreview = () => {



        if (!securityGroup.securityGroup || !securityGroup.securityGroup.toString().trim()) {
            failureToast("Please select security group")
            return
        }

        const deatils = {
            "Employee ID": isVendor ? userDetails?.employeeId : userDetails?.employeeId?.value,
            "Employee Name": isVendor ? userDetails?.employeeName.toString().trim() : userDetails?.employeeId?.employeeName,
            "Designation": isVendor ? userDetails?.designation?.label : userDetails?.employeeId?.designation,
            "Account Status": userDetails.accountStatus.label,
            "Phone Number": isVendor ? userDetails?.phoneNumber : userDetails?.employeeId?.phoneNumber,
            "User Profile Type": userDetails.userProfileTypeId?.label ?? '',
            "Department": isVendor ? userDetails?.department?.label : userDetails?.employeeId?.department,
            "Email": isVendor ? userDetails?.email : userDetails?.employeeId?.email,
        }

        const tempArr = projectDetails.map(el => {
            return {
                project: el.project?.label,
                roles: el.role.filter(r => { return r.value !== '*' })?.map(o => { return o.label }) ?? [],
                // tempRole: el?.tempRole ? el.tempRole?.roleId?.lable : el?.tempRoleDto ? el.tempRoleDto?.tempRoleId : null
                tempRole: el?.tempRole ? el.tempRole?.roleId?.label : null

            }
        })

        const roles = [...tempArr]

        setRolesData(roles)
        setDetailsData(deatils)


        setIsPreviewEnable(true)
    }

    //update User data
    const submitHandler = () => {

        if (!ticketDetails.ticketId) {
            failureToast("Please enter ticket ID")
            return
        }
        if (ticketDetails.ticketId && ticketDetails.ticketId.toString().length < 5) {
            failureToast("Please enter valid ticket ID")
            return
        }
        if (!ticketDetails.ticketDate) {
            failureToast("Please enter ticket Date")
            return
        }

        setFormSubmitting(true)

        const tempArr = projectDetails.map(el => {

            return {
                projectId: el.project?.projectKey ?? el.project?.label,
                userIdEmailId: el.project?.isUseridEmail,
                roles: el.role.filter(r => { return r.value !== '*' })?.map(o => { return o.value }) ?? [],
                status: el.status,
                isNewUser: el.project?.isNewUser,
                tempRole: el?.tempRole?.roleId?.value ? {
                    "userName": el.userId,
                    "projectName": el.project?.projectKey,
                    "roleId": el.role.filter(r => { return r.value !== '*' })?.map(o => { return o.value })?.[0],
                    "tempRoleId": el.tempRole?.roleId?.value,
                    "tempRoleStartDate": moment(el.tempRole?.startDate).format('YYYY-MM-DD'),
                    "tempRoleEndDate": moment(el.tempRole?.endDate).format('YYYY-MM-DD'),
                    "status": el.tempRole?.status
                } : el?.tempRoleDto ? {
                    ...el.tempRoleDto
                    // "userName": el.userId,
                    // "projectName": el.project?.projectKey,
                    // "roleId": el.role.filter(r => { return r.value !== '*' })?.map(o => { return o.value })?.[0],
                    // "tempRoleId": el.tempRole?.tempRoleId ?? '',
                    // "tempRoleStartDate": el.tempRole?.tempRoleStartDate ? moment(el.tempRole?.tempRoleStartDate).format('YYYY-MM-DD') : null,
                    // "tempRoleEndDate":el.tempRole?.tempRoleEndDate ? moment(el.tempRole?.tempRoleEndDate).format('YYYY-MM-DD') : null,
                    // "status": el.tempRole?.status
                } : null
            }
        })

        const request = {
            "employeeId": isVendor ? userDetails?.employeeId.toString().toUpperCase() : userDetails?.employeeId?.value,
            "employeeName": isVendor ? userDetails?.employeeName : userDetails?.employeeId?.employeeName,
            "email": isVendor ? userDetails?.email : userDetails?.employeeId?.email,
            "phoneNo": isVendor ? userDetails?.phoneNumber !== '' ? userDetails?.phoneNumber  :null : userDetails?.employeeId?.phoneNumber!=='' ? userDetails?.employeeId?.phoneNumber : null,
            "ticket_id": ticketDetails?.ticketId,
            "designationId": isVendor ? userDetails?.designation?.label : userDetails?.employeeId?.designation,
            "ticket_date": ticketDetails?.ticketDate,
            "departmentId": isVendor ? userDetails?.department?.label : userDetails?.employeeId?.department,
            "active": userDetails.accountStatus?.value,
            "projectRole": tempArr,
            "employeeProfile": userDetails.userProfileTypeId?.label ?? '',
            "ticketDesc": ticketDetails?.ticketDesc,
            "securityGroupId": securityGroup.securityGroup?.value
        }

        const url = action === 'edit' ? endpoint.updateUser : endpoint.createUser;

        putpost(
            url,
            (data) => {
                if (data?.code == 200) {
                    setConfirmationSucess(true)
                }
            },
            (data) => {
                failureToast(data['message']);
                setFormSubmitting(false)
                setFormSubmitting(false)
            },
            request,
            'post'
        );

    }

    const resetAll = () => {
        setTicketDetailValid(false)
        setUserDetailValid(false)
        setTicketDetails({
            ticketId: '',
            ticketDate: null
        })
        setUserDetails({
            userProfileTypeId: null,
            accountStatus: { label: 'ACTIVE', value: 'ACTIVE' },
            employeeId: null
        })
        setProjectDetails([{ ...projectObj }])
        setVendor(false)
        setUserDetailObject(fieldObjects.userDetails)
        setIsPreviewEnable(false)
        setFormSubmitting(false)
        setConfirmationSucess(false)
        setConfirmationDialog(false)
        setRolesData([])
        setDetailsData({})
        setEmployeeDetails(false)
    }

    //update Employee Data
    const postEmployeeDetails = () => {

        if ((!userDetails.employeeName || !userDetails.employeeName.toString().trim())) {
            failureToast("Please enter user name")
            return
        }

        if (userDetails.employeeName.toString().trim()?.length < 2) {
            failureToast("Please enter valid user name")
            return
        }


        if (userDetails.employeeName.split(" ").length > 4) {
            failureToast("Only three whitespace are allowed in employee name")
            return
        }

        if (!userDetails?.designation?.value) {
            failureToast("Please select department")
            return
        }

        if (userDetails?.designation?.value && isNaN(userDetails?.designation?.value)) {
            failureToast("Please select valid designation")
            return
        }

        if (!userDetails?.department?.value) {
            failureToast("Please select department")
            return
        }

        if (userDetails?.designation?.value && isNaN(userDetails?.designation?.value)) {
            failureToast("Please select valid designation")
            return
        }

        if ((userDetails.phoneNumber && userDetails.phoneNumber.toString().length !== 10)) {
            failureToast("Please enter valid phone number")
            return
        }

        if (isVendor && !regex.email.test(userDetails.email)) {
            failureToast("Please enter valid email")
            return
        }



        setEmployeeSaving(true)


        const request = {
            "id": userDetails.id,
            "employeeId": userDetails.employeeId,
            "employeeName": userDetails?.employeeName,
            "mobileNumber": userDetails?.phoneNumber,
            "email": userDetails?.email,
            "designation": userDetails?.designation?.label,
            "department": userDetails?.department?.label,
            "ticket_id": ticketDetails?.ticketId,
            "ticket_date": ticketDetails?.ticketDate,
            "ticketDesc": ticketDetails?.ticketDesc
        }

        putpost(
            endpoint.updateEmployee,
            (data) => {
                if (data?.code == 200) {
                    successToast(data?.message)
                    setEmployeeSaving(false)
                    history.push('/admin/userProfile')
                }
            },
            (data) => {
                failureToast(data['message']);
                setEmployeeSaving(false)
            },
            request,
            'post'
        );

    }

    //role validation
    const handleRoleValidation = () => {
        let projectTemp = [];
        let valid = true;

        if (!projectDetails.length) {
            failureToast(`Please provide project and roles`)
            valid = false
            return
        }

        if (valid) {
            projectDetails.every((item, ind) => {

                if (!item.project?.value) {
                    failureToast(`Please select project for row ${ind + 1}`)
                    valid = false
                    return false
                } else if (projectTemp?.indexOf(item.project?.value) > -1) {
                    failureToast(`Project must be unique`)
                    valid = false
                    return false
                } else if (!item.role.length) {
                    failureToast(`Please select role for row ${ind + 1}`)
                    valid = false
                    return false
                }
                else {
                    projectTemp.push(item.project?.value)
                    valid = true
                    return true
                }
            })
        }

        if (!valid) {
            return
        }
        setRoleValid(true)
    }

    return (
        <Container fluid>
            {isPreviewEnable ?
                <Preview
                    ticketDetails={ticketDetails}
                    rolesData={rolesData}
                    detailsData={detailsData}
                    userPermissions={userPermissions}
                    isFormSubmiting={isFormSubmiting}
                    setIsPreviewEnable={setIsPreviewEnable}
                    submitHandler={submitHandler}
                    securityGroup={securityGroup.securityGroup}
                /> :
                isFetching || !action ? <div className="col">
                    <Card className=" w-100">
                        <div
                            className="mx-auto text-center py-5 my-5 "
                            style={{ height: '100vh' }}
                        >
                            <CustomLoader apiLoader={isFetching} />
                        </div>
                    </Card>
                </div> :
                    <>
                        <Accordion
                            title="Ticket Details"
                            isFormOpen={!isTicketDetailValid || isPreviewEnable || isReadOnly}
                            rightSideInfo={
                                action !== 'new' ? <CustomButton
                                    className={'mx-1'}
                                    content={'View Ticket History'}
                                    permissionType={'R'}
                                    onClick={() => { setDialogHistoryOpen(true) }}
                                    forTable={true}
                                    permissionSet={userPermissions}
                                    disabled={!ticketHistory?.length}
                                /> : null
                                // dateValues.totalYears ? (

                                // ) : null
                            }
                        >
                            <Row className="pl-4 pr-4 pb-2 pt-2 course-fee-header">
                                <FieldsRenderer
                                    fields={Object.keys(fieldObjects.ticketDetails)}
                                    fieldsObject={fieldObjects.ticketDetails}
                                    values={ticketDetails}
                                    setValues={setTicketDetails}
                                    isPreviewEnable={isPreviewEnable}
                                    disabled={isTicketDetailValid || isReadOnly}

                                />
                            </Row>
                            <Row className="justify-content-end pl-4 pr-4 pb-2">
                                {isTicketDetailValid && !isPreviewEnable && !isReadOnly && <CustomButton
                                    type="edit"
                                    color="info"
                                    content={"Edit"}
                                    permissionType={'C,U'}
                                    permissionSet={userPermissions}
                                    disabled={false}
                                    onClick={() => setTicketDetailValid(false)}
                                />}
                                {!isTicketDetailValid && !isPreviewEnable && !isReadOnly && <CustomButton
                                    content={"Next"}
                                    permissionType={'C,U'}
                                    permissionSet={userPermissions}
                                    onClick={handleTicketDetails}
                                    disabled={false}
                                />}
                            </Row>
                        </Accordion>
                        {isTicketDetailValid && <Accordion title="User Details" isFormOpen={!isUserDetailValid || isPreviewEnable || isReadOnly || action === 'edit'}>
                            <Row className="p-4 course-fee-header">
                                <FieldsRenderer
                                    fields={Object.keys(userDetailObject)}
                                    fieldsObject={userDetailObject}
                                    values={userDetails}
                                    setValues={setUserDetails}
                                    dataObjects={{ personData: personData, status: status, employeeData: employeeData, departmentData: departmentData, jobData: designationData }}
                                    isPreviewEnable={isPreviewEnable}
                                    disabled={isUserDetailValid || isReadOnly}
                                    isEditMode={action === 'edit' ? true : false}
                                    isEmployee={isEmployeeDetails}
                                    size={4}
                                    verifyEmpId={verifyEmpId}
                                    setEmpIdVerified={setEmpIdVerified}
                                    isEmpIdVerified={isEmpIdVerified}
                                />
                            </Row>
                            <Row className="justify-content-end pl-4 pr-4 pb-4">

                                {isUserDetailValid && !isPreviewEnable && !isReadOnly && <CustomButton
                                    type="edit"
                                    color="info"
                                    content={"Edit"}
                                    permissionType={'C,U'}
                                    permissionSet={userPermissions}
                                    disabled={false}
                                    onClick={() => setUserDetailValid(false)}
                                />}
                                {!isUserDetailValid && !isPreviewEnable && !isReadOnly && <CustomButton
                                    content={isEmployeeDetails ? isEmplyeeSaving ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : "Save" : "Next"}
                                    permissionType={'C,U'}
                                    permissionSet={userPermissions}
                                    onClick={isEmployeeDetails ? postEmployeeDetails : handleUserDetails}
                                    disabled={isEmplyeeSaving}
                                />}

                                {isEmployeeDetails && !isPreviewEnable && !isReadOnly && <CustomButton
                                    content={'Close'}
                                    className={'reset-button mx-1'}
                                    permissionType={'R'}
                                    onClick={() => { !isUserDetailValid ? setConfirmationDialog(true) : history.push('/admin/userProfile') }}
                                    // forTable={true}
                                    permissionSet={userPermissions}
                                    disabled={isEmplyeeSaving}
                                />}

                            </Row>

                        </Accordion>}


                        {!isEmployeeDetails && !isUserSeperated && <>
                            {isUserDetailValid && isTicketDetailValid && <Card>
                                <CardHeader>
                                    <h3>
                                        Projects and role
                                    </h3>
                                    {!isPreviewEnable && !isReadOnly && <CustomButton
                                        content={"Add New Mapping"}
                                        permissionType={'C,U'}
                                        permissionSet={userPermissions}
                                        onClick={addNewProjectMapping}
                                        disabled={isPreviewEnable || isRoleValid || isReadOnly}
                                        icon={true}
                                    />}
                                </CardHeader>
                                <Row className="p-4 course-fee-header">
                                    <ProjectAndRoleTable
                                        projectDetails={projectDetails}
                                        setProjectDetails={setProjectDetails}
                                        isPreviewEnable={isPreviewEnable || action === 'view' || isRoleValid}
                                        userDetails={userDetails}
                                        isVendor={isVendor}
                                        projectData={projectData}
                                        isEditMode={action === 'edit' ? true : false}
                                        userPermissions={userPermissions}
                                        action = {action}
                                        setRoleAndProjectObject={setRoleAndProjectObject}
                                        roleAndProjectObject={roleAndProjectObject}
                                    />
                                </Row>
                                <Row className="justify-content-end pl-4 pr-4 pb-2">
                                    {isRoleValid && !isPreviewEnable && !isReadOnly && <CustomButton
                                        type="edit"
                                        color="info"
                                        content={"Edit"}
                                        permissionType={'C,U'}
                                        permissionSet={userPermissions}
                                        disabled={false}
                                        onClick={() => setRoleValid(false)}
                                    />}
                                    {!isRoleValid && !isPreviewEnable && !isReadOnly && <CustomButton
                                        content={"Next"}
                                        permissionType={'C,U'}
                                        permissionSet={userPermissions}
                                        onClick={handleRoleValidation}
                                        disabled={false}
                                    />}
                                </Row>

                            </Card>}


                            {isUserDetailValid && isTicketDetailValid && isRoleValid &&
                                <Card>
                                    <CardHeader>
                                        <h3>Security Group</h3>
                                    </CardHeader>
                                    <Row className="p-4 course-fee-header">
                                        <FieldsRenderer
                                            fields={Object.keys(fieldObjects.securityGroup)}
                                            fieldsObject={fieldObjects.securityGroup}
                                            values={securityGroup}
                                            setValues={setSecurityGroup}
                                            dataObjects={{ securityGroupData: securityGroupData}}
                                            isPreviewEnable={isPreviewEnable}
                                            disabled={isReadOnly}
                                            isEditMode={action === 'edit' ? true : false}
                                        />

                                    </Row>
                                    {securityGroup?.securityGroup?.value && <div style={{
                                        marginTop: '-60px',
                                        fontSize: '12px',
                                        marginLeft: '24px',
                                        color:'#00aeef',
                                        cursor:'pointer',
                                        zIndex:10
                                    }}><span onClick={()=>setViewSecuirty(true)} >View Details</span></div>}
                                    <Row className="justify-content-end pl-4 pr-4 pb-4">
                                        {!isPreviewEnable && !isReadOnly &&
                                            <>
                                                <CustomButton
                                                    content={"Preview"}
                                                    permissionType={'C,U'}
                                                    permissionSet={userPermissions}
                                                    onClick={handlePreview}
                                                />
                                                <CustomButton
                                                    className={'mx-1'}
                                                    content={'Cancel'}
                                                    permissionType={'cancel'}
                                                    onClick={() => { setConfirmationDialog(true) }}
                                                    forTable={true}
                                                    permissionSet={userPermissions}
                                                />
                                            </>
                                        }
                                    </Row>
                                </Card>
                            }

                        </>}


                        {isReadOnly && <Row className="justify-content-end pl-4 pr-4 pb-4">
                            <CustomButton
                                content={'Close'}
                                className={'reset-button mx-1'}
                                permissionType={'R'}
                                onClick={() => { history.push('/admin/userProfile') }}
                                // forTable={true}
                                permissionSet={userPermissions}
                            />
                        </Row>}
                    </>}

            <ConfirmationDialog isOpen={confirmationDialog} onAgree={() => { resetAll(); history.push('/admin/userProfile') }} setIsOpen={setConfirmationDialog} />
            <ConfirmationDialog
                isOpen={confirmationSucess}
                onAgree={() => { resetAll(); history.push('/admin/userProfile') }}
                setIsOpen={setConfirmationSucess}
                type={'info'}
                headerMsg={'Success'}
                msg={'Record saved successfully.'}
                popupSymbol={<img src={require("../../../../../assets/img/svg/resetSuccess.svg")} className="m-5 p-2" width={'100%'} />}
            />

            <Dialog
                isModalVisible={isDailogHistoryOpen}
                isCloseButtonRequired={true}
                title={'Ticket History'}
                toggle={() => setDialogHistoryOpen(false)}
                headerWithBg={true}
            >

                <Table className="align-items-center tableLayout">
                    <MasterHeaderAuto headerList={headerList} permissionSet={userPermissions} isSaveVisible={false} />
                    <tbody className="list" key={'ticket-history'}>
                        {ticketHistory.map((item, idx) => {
                            return <tr>
                                <td className="text-center  white-breakSpace">{idx + 1}</td>
                                <td className="text-center  white-breakSpace">{item?.[0]}</td>
                                <td className="text-center  white-breakSpace">{moment(item?.[1]).format("DD-MM-YYYY")}</td>
                                <td className="text-center  white-breakSpace">{item?.[2]}</td>
                                <td className="text-center  white-breakSpace">{item?.[3] ?? "-"}</td>
                                <td className="text-center  white-breakSpace">{item?.[4] ?? "-"}</td>
                            </tr>
                        })}
                    </tbody>
                </Table>
            </Dialog>


            <Dialog
                isModalVisible={viewSecurity}
                isCloseButtonRequired={true}
                title={'Security Details'}
                toggle={() => setViewSecuirty(false)}
                headerWithBg={true}
                size={'xl'}
            >

                <CreateSecurityGroup isView={true} viewId={securityGroup?.securityGroup?.value}/>
            </Dialog>

        </Container>
    )
}

export default CreateProfile