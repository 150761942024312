import React from 'react'
import { ReactComponent as FolderSVG } from '../../../../../assets/img/svg/Folder2.svg'
import AButton from '../../common/form-fields-mui/AButton'

const EmptyState = ({ setIsFormOpen }) => {
  return (
    <div
      className='d-flex flex-column justify-content-center align-items-center'
      style={{ padding: '25px 0' }}
    >
      <FolderSVG />
      <span style={{ fontSize: '20px', fontWeight: 400, color: '#000000', marginTop: '1rem' }}>No test has been scheduled yet</span>
      <span style={{ fontSize: '14px', fontWeight: 400, color: '#8B8D97' }}>Add Test here</span>
      <AButton className='button_remove_left_margin mt-3' variant='primary_filled' onClick={() => setIsFormOpen(true)}>
        Add Test
      </AButton>
    </div>
  )
}

export default EmptyState