import { constants } from "../../views/pages/manage/common/constant";

export const setGroupedRequests = (allActiveConfigs, allApprovalRequests, approverType, roleId) => {
    if (allActiveConfigs?.length && allApprovalRequests.length && approverType && roleId) {
        let _rows = [];

        allApprovalRequests.forEach(req => {
            const _config = allActiveConfigs.find(config => {
                let flag = false;
                if (approverType.value === constants.APPROVER_TYPE.L1) {
                    flag = config.l1ApprovalRoles.includes(roleId);
                } else if (approverType.value === constants.APPROVER_TYPE.L2) {
                    flag = config.l2ApprovalRoles.includes(roleId);
                } else if (approverType.value === constants.APPROVER_TYPE.L3) {
                    flag = config.l3ApprovalRoles.includes(roleId);
                }
                return flag && config.businessAreas.includes(req.applicationBusinessArea) && config.courseCategories.includes(req.courseCategoryId);
            })

            if (_config) {
                _rows.push({
                    ...req,
                    concessionDocuments: [
                        {
                          "id": 92,
                          "applicationQuotationDetailsId": 2,
                          "applicationConcessionId": 119,
                          "processInstanceId": 34317,
                          "fileName": "mr_robot.png",
                          "uploadedFileKey": "1685694566302",
                          "fileStatus": "SUCCESS",
                          "createdBy": "PORAON",
                          "updatedBy": "PORAON",
                          "createdOn": "2023-06-02T08:21:12.190+00:00",
                          "updatedOn": "2023-06-02T08:21:12.190+00:00"
                        }
                      ],
                    totalDefinedConcession: {
                        amount: req.totalDefinedConcessionAmount,
                        percentage: req.totalDefinedConcessionPercentage
                    },
                    totalCustomConcession: {
                        amount: req.totalCustomConcessionAmount,
                        percentage: req.totalCustomConcessionPercentage
                    },
                    totalConcessionApplied: {
                        amount: req.totalConcessionAppliedAmount,
                        percentage: req.totalConcessionAppliedPercentage
                    }
                });
            }
        })

        const r = _rows.reduce((acc, curr) => {
            return { ...acc, [curr.applicationBusinessArea]: [...(acc[curr.applicationBusinessArea] ?? []), curr] };
        }, {});
        return r;
    }
    return {};
}

export const getSelectedRowsArr = (selectedRowIds, allApprovalRequests) => {
    const _selectedRows = [];
    Object.values(selectedRowIds).forEach(ids => {
        ids.forEach(id => {
            const row = allApprovalRequests.find(req => req.applicationQuotationDetailsId === id);
            if(row) {
                _selectedRows.push(row);
            }
        })
    });
    return _selectedRows;
}


export const otherChargesGroup = (approvalData) => {
    const r = approvalData.reduce((acc, curr) => {
        return { ...acc, [curr.businessArea]: [...(acc[curr.businessArea] ?? []), curr] };
    }, {});
    return r;
}