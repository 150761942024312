import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { Card, CardHeader, Col, Container, Button, Input, Row } from "reactstrap";
import FeeTable from "./feeTable";
import ApprovalLogs from "./approvalLogs";
import {
  masterServiceBaseUrl,
  pricingServiceBaseUrl,
  getAPI,
  putpost,
} from "services/http";
// import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import Select2 from "react-select2-wrapper";
import { successToast, failureToast } from "../../../../common/utils/methods/toasterFunctions/function";
import {
  fetchAllData,
  // fetchDetails,
  getValueBySearch,
  isEqual,
  fetchAllPromisedData,
  fetchAllPostPromisedData
} from "../../../../common/utils/methods/commonMethods/utilityMethod";
import SupportingDocument from "./supportingDocument";
// import { CSVLink } from "react-csv";
// import headers from "./header.json";
import moment from "moment";
import CustomLoader from '../../../../common/commonComponents/Loader/loader'
import { PermissionContext, RolePermissions } from "appContext";
import { PermisionDenied } from "../../../../common/commonComponents";
import { pages, endpoint } from "../../../../common/constant";
import CustomButton from "components/CustomButton";
import CustomAsyncMultiSelect from "../../../../common/commonComponents/FormElements/customAsyncMultiSelect";
import { getDownloadUrl } from "./utils";


const chkDataForUniquness = (id, list) => {
  return list.filter((e) => e.id === id).length ? true : false;
};


let page = 0;
let totalNumberOfRecords = 0;
let serverTotalPage = 0;
let serverCurrentPage = 0;
let isNextCallPossible = true;

const FeeMaster = (props) => {

  const permissions = useContext(PermissionContext);
  let userPermissions = null;

  const { pid, approvalRole, action, approval } = props.match.params;
  const pageSize = 10;


  if (approval === "level1Approval") {
    userPermissions = RolePermissions(permissions, pages["level1Approval"]['id']);
    // console.log("level1Approval", userPermissions)
  }
  if (approval === "level2Approval") {
    userPermissions = RolePermissions(permissions, pages["level2Approval"]['id']);
    // console.log("level2Approval", userPermissions)
  }

  const [groupCode, setGroupCode] = useState([]);
  const [companyCode, setCompanyCode] = useState([]);
  const [academicCareer, setAcademicCareer] = useState([]);
  const [term, setTerm] = useState([]);
  const [pricingZone, setPricingZone] = useState([]);
  const [CourseData, setCourseData] = useState([]);

  const [serverData, setServerData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [processIdsList, setPriceIdsList] = useState([]);
  const [isChange, setIsChange] = useState(false);
  const [comment, setComment] = useState(null);
  const [selectForPid, setSelectForPid] = useState(true);
  const [selectedPid, setSelectedPid] = useState(null);
  const [otherData, setOtherData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isFetchingOptions,setIsFetchingOption] = useState(false);
  const [isAllSelected,setAllSelected] = useState(false);
  const [downloading,setDownloading] = useState(false);
  const [selectedTId, setSelectedTid] = useState([]);



  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });

  const setPagination = (paramsdata) => {
    let n = pagination;
    if (paramsdata.length > pageSize) {
      n["nextPage"] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n["totalPage"] = Math.floor(paramsdata.length / pageSize);
    if (paramsdata.length % pageSize != 0) {
      n["totalPage"] += 1;
    }
    setpagination(n);
    setTableData(
      paramsdata.slice(
        pagination.currentPage * pageSize - pageSize,
        pagination.currentPage * pageSize
      ) || []
    );
  };


  useEffect(() => {
    fetchAllData(
      masterServiceBaseUrl + "/academicCareer/getAllAcademicCareer",
      setAcademicCareer
    );
    fetchAllData(masterServiceBaseUrl + "/companyCode/getAllCompanyCode", setCompanyCode);
    fetchAllData(masterServiceBaseUrl + "/pricingZone/getAllPricingZone", setPricingZone);
    fetchAllData(masterServiceBaseUrl + "/term/getAllTerm", setTerm);
    fetchAllData(masterServiceBaseUrl + "/groupCode/getAllGroupCode", setGroupCode);
    // fetchAllData(masterServiceBaseUrl + '/academicCareer/getAllActiveAcademicCareer', setAcademicCareer);
  }, []);

  const getData = (pid) => {
    setIsFetching(true)
    getAPI(
      `${pricingServiceBaseUrl}/getCourseFeeApprovalDataForPIDForUser?pid=${pid}&level=${approvalRole == 10002 ? 2 : 1}&offSet=${page}&action=${action.toString().toUpperCase()}`,
      (data) => {
        let tempArray = [];
        let tempArrayForTID = [];
        if (data.data.courseFeeProcessingApprovalDto && data.data.courseFeeProcessingApprovalDto.length > 0) {
          ////console.log("Dat-------------->", data.data);
          data.data.courseFeeProcessingApprovalDto.map((item, index) => {
            if (!chkDataForUniquness(item.transactionId, tempArrayForTID)) {
              tempArrayForTID.push({
                id: item.transactionId,
                value: item.transactionId,
                label: item.transactionId,
              });
            }
          });
          setOptions([{ label: "All", value: "*" }, ...tempArrayForTID]);
          // setOptions([...tempArrayForTID])
        }
        setServerData(data.data.courseFeeProcessingApprovalDto);
        setPagination(data.data.courseFeeProcessingApprovalDto);
        // totalNumberOfRecords = data?.data?.totalPages > 1 ? data?.data?.totalPages * 50 : data.data.courseFeeProcessingApprovalDto.length
        totalNumberOfRecords = data?.data?.totalPages * 50 ?? 0
        serverTotalPage = data?.data?.totalPages;
        serverCurrentPage = data?.data?.currentPage + 1;
        isNextCallPossible = data.data.courseFeeProcessingApprovalDto.length == 50 ? true : false;
        // if (approval !== "level2Approval") {
        //   setOtherData(data.data.courseFeeTemp)
        // }
        setIsFetching(false);
      },
      (data) => {
        failureToast(data["message"]);
        setIsFetching(false);
      }
    );
  };

  // /////////

  const promiseOptions = async() => {
    // alert("i m here")
    if (isNextCallPossible) {
      // return new Promise(async (resolve) => {
        setIsFetchingOption(true)
        page = page + 1;
        const newData = await fetchAllPromisedData(`${pricingServiceBaseUrl}/getCourseFeeApprovalDataForPIDForUser?pid=${pid}&level=${approvalRole == 10002 ? 2 : 1}&offSet=${page}&action=${action.toString().toUpperCase()}`)
        if (newData && newData?.courseFeeProcessingApprovalDto && newData?.courseFeeProcessingApprovalDto?.length > 0) {
          setServerData([...serverData, ...newData.courseFeeProcessingApprovalDto]);
          // setPagination([...serverData, ...newData.courseFeeProcessingApprovalDto])
          totalNumberOfRecords = newData?.totalPages * 50 ?? 0
          setPagination([...serverData, ...newData.courseFeeProcessingApprovalDto]);
          let tempArrayForTID = [];
          newData.courseFeeProcessingApprovalDto.map((item, index) => {
            if (!chkDataForUniquness(item.transactionId, tempArrayForTID)) {
              tempArrayForTID.push({
                id: item.transactionId,
                value: item.transactionId,
                label: item.transactionId,
              });
            }
          });
          setOptions([...options, ...tempArrayForTID]);
          // resolve(tempArrayForTID);
          isNextCallPossible = newData.courseFeeProcessingApprovalDto.length == 50 ? true : false;
          setIsFetchingOption(false)
        } else {
          isNextCallPossible = false;
          setIsFetchingOption(false)
        }
  }
  };

  ////////////


  useEffect(() => {
    if (pid) {
      setSelectedPid(pid);
      setSelectForPid(true);
    }
    //const data = response;
    if (pid && approvalRole) {
      getData(pid);
    }

    if (approvalRole && !pid) {
      setSelectForPid(false);
      if (action !== 'view') {
        getAPI(
          `${pricingServiceBaseUrl}/getPIDByRole/${approvalRole}`,
          (data) => {
            ////console.log("Process Ids---->", data.data);
            // getData(pid);
            const pidList = data.data.courseFeeProcessingApprovalDto.map((data) => {
              return {
                id: data,
                text: data,
              };
            });

            setPriceIdsList(pidList);
          },
          (data) => {
            ////console.log("Failure------>", data.message);
          }
        );
      }
    }
  }, [pid, approvalRole]);

  // useEffect(() => {
  //   setIsFetching(true)
  //   if (pid && approval === "level2Approval") {
  //     getAPI(
  //       `${endpoint.l2_page.getRecordsForL2}/${pid}`,
  //       (data) => {
  //         setOtherData(data.data)
  //         setIsFetching(false)
  //       },
  //       (data) => {
  //         console.log("Failure------>", data.message);
  //         setIsFetching(false)
  //       }
  //     );
  //   }
  // }, [pid, approval])



  const fetchNext = async () => {
    if (isNextCallPossible) {
      setIsFetching(true)
      page = page + 1;
      const newData = await fetchAllPromisedData(`${pricingServiceBaseUrl}/getCourseFeeApprovalDataForPIDForUser?pid=${pid}&level=${approvalRole == 10002 ? 2 : 1}&offSet=${page}&action=${action.toString().toUpperCase()}`)
      if (newData && newData?.courseFeeProcessingApprovalDto && newData?.courseFeeProcessingApprovalDto?.length > 0) {
        setServerData([...serverData, ...newData.courseFeeProcessingApprovalDto]);
        // setPagination([...serverData, ...newData.courseFeeProcessingApprovalDto])
        totalNumberOfRecords = newData?.totalPages * 50 ?? 0
        setPagination([...serverData, ...newData.courseFeeProcessingApprovalDto]);
        let tempArrayForTID = [];
        newData.courseFeeProcessingApprovalDto.map((item, index) => {
          if (!chkDataForUniquness(item.transactionId, tempArrayForTID)) {
            tempArrayForTID.push({
              id: item.transactionId,
              value: item.transactionId,
              label: item.transactionId,
            });
          }
        });
        setOptions([...options, ...tempArrayForTID]);
        isNextCallPossible = newData.courseFeeProcessingApprovalDto.length == 50 ? true : false;
      } else {
        isNextCallPossible = false;
      }
      setIsFetching(false)
    }
  }


  const approve = (flag, setApproveLoading = () => { }) => {
    if ((selectedTId && selectedTId?.length > 0) || serverData?.[0]?.isManual) {
      setApproveLoading(true);
      let tempArray = [];
      if (serverData?.[0]?.isManual) {
        tempArray = [serverData?.[0]?.transactionId]
      } else {
        if(isAllSelected){
          tempArray.push("ALL");
        }else{
          selectedTId.map((item) => {
            if (item.value !== "*") {
              tempArray.push(item.id);
            }
          })
        }
      }

      let tempObject = {
        approved: flag === 1,
        processInstanceId: selectedPid,
        remarks: comment,
        role: approvalRole,
        transactionList: tempArray,
      };
      ////console.log("Data for APi----->", tempObject);
      putpost(
        `${pricingServiceBaseUrl}/approve`,
        (data) => {
          setApproveLoading(false);
          successToast(data.message);
          setSelectedTid([]);
          //getData(pid);
          // if()
          // if(approval ==='level1Approval'){
          //   props.history.push(
          //       `/admin/level1Approval`
          //   );
          // }else if(approval === 'level2Approval'){
          //   props.history.push(
          //       `/admin/level2Approval`
          //   );
          // }
          // pid && props.history.push(
          //   `/admin/coursefee/approval/${approvalRole}/${pid}`
          // );
          !pid && getData(selectedPid)
          setServerData([]);
          setOptions([]);
          // props.history.goBack();
          if (approval === 'level1Approval') {
            props.history.push(
              `/admin/level1Approval`
            );
          }

          if (approval === 'level2Approval') {
            props.history.push(
              `/admin/level2Approval`
            );
          }
        },
        (data) => {
          setApproveLoading(false);
          failureToast(data.message);
        },
        tempObject,
        "POST"
      );
    } else {
      failureToast("Please Select transaction Ids.");
    }
  };

  const [options, setOptions] = useState([]);

  const fetchTransactionId = (id) => {
    // setIsChange(true);
    if (id) {
      getData(id);
      setSelectedPid(id);
    }

  };

  const getFormatedDate = (date) => {
    return date ? moment(date).format("DD/MM/YYYY") : "";
  };

  useEffect(()=>{
    if(isAllSelected){
      setSelectedTid(options)
    }
  },[isAllSelected,options])



  const selectId = (sData) => {
    if(sData?.length){
      if(selectedTId && selectedTId?.find(option => option.value === "*") && !sData?.find(option => option.value === "*")){
        setAllSelected(false)
        setSelectedTid([])
      }else if(selectedTId && (selectedTId?.length > sData?.length && sData?.find(option => option.value === "*"))){
        const indexOfAll = sData?.findIndex(item => item.value === '*');
        sData.splice(indexOfAll,1)
        setSelectedTid(sData);
        setAllSelected(false)
      }else if(sData.find(option => option.value === "*")){
        setSelectedTid(options)
        setAllSelected(true)
      }else{
        setSelectedTid(sData)
        setAllSelected(false)
      }
    }else{
      setSelectedTid([])
      setAllSelected(false)
    }
  };



  return (
    userPermissions ?
      <Container fluid className="mt-4">
        {selectedPid ? (
          isFetching ? (
            <div
              className="mx-auto text-center py-5 my-5"
              style={{ height: "100vh" }}
            >
              <CustomLoader apiLoader={isFetching} />
            </div>
          ) : (
            <>
              <Card style={{overflow:'auto'}}>
                <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                  {/* <h3 className="mb-0 ">Fee Master</h3> */}
                  <h3 className="mb-0 ">Fee Details</h3>
                  {!serverData?.[0]?.isManual && <div
                    className="d-flex flex-row justify-content-end align-items-center"
                  // style={{ width: "70%" }}
                  >
                    <div className="mr-2">
                      {pid ?
                        <Input value={pid} disabled={true} style={{ height: "39px" }} />
                        :
                        <Select2
                          defaultValue={selectedPid}
                          options={{ placeholder: " Select Process Id" }}
                          data={processIdsList}
                          onChange={(e) => {
                            // fetchAllData(`${pricingServiceBaseUrl}/getPIDByRole/${e.target.value}`,setPriceIdsList)
                            fetchTransactionId(e.target.value);
                          }}
                        />}
                    </div>
                    {action === 'view'
                      ? null
                      :
                      <div className="mr-2">
                        {/* <ReactMultiSelectCheckboxes
                          options={options}
                          // options={[...options]}
                          placeholderButtonLabel="Select Transaction Ids"
                          getDropdownButtonLabel={getDropdownButtonLabel}
                          value={selectedTId}
                          onChange={selectId}
                        /> */}
                        <CustomAsyncMultiSelect
                          placeholder="Select Transaction Ids"
                          defaultOptions={options}
                          onChangeHandler={selectId}
                          // loadOptions={promiseOptions}
                          // promiseHandler={promiseOptions}
                          value={selectedTId}
                          onMenuScrollToBottom={promiseOptions}
                          isLoading={isFetchingOptions}
                        />
                      </div>
                    }
                    {serverData && serverData.length && action !== "view" ? (
                      <div md={2}>
                        {/* <Button
                          color="info"
                          size="sm"
                          type="button"
                          className={"text-white"}
                        >
                          <CSVLink
                            data={dataToDownload}
                            headers={headers.header}
                            className={"text-white"}
                            filename={`${serverData[0].processInstanceId}.csv`}
                          >
                            Download
                          </CSVLink>
                        </Button> */}
                         <CustomButton
                          type="download"
                          className={'mx-1'}
                          content={downloading ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i>  : 'Download'}
                          color="info"
                          permissionType={'R'}
                          // onClick={action === 'view' ? () => window.close() : () => props.history.goBack()}
                          permissionSet={userPermissions}
                          onClick={() => {getDownloadUrl(pid,approvalRole == 10002 ? 2 : 1,setDownloading) }}
                          // disabled={approvalRole == 10002 ? true : false}
                        />
                      </div>
                    ) : null}
                  </div>}
                </CardHeader>
                <FeeTable
                  // approvalRole={approvalRole}
                  companyCode={companyCode}
                  academicCareer={academicCareer}
                  term={term}
                  pricingZone={pricingZone}
                  CourseData={CourseData}
                  state={serverData}
                  pageSize={pageSize}
                  isManual={(serverData && serverData?.[0]?.isManual) ?? false}
                  userPermissions={userPermissions}
                  fetchNext={fetchNext}
                  pagination={pagination}
                  setpagination={setpagination}
                  tableData={tableData}
                  setTableData={setTableData}
                  isFetching={isFetching}
                  isNextCallPossible={isNextCallPossible}
                  approvalRole={approvalRole == 10002 ? 'L2' : 'L1'}
                />
              </Card>

              <SupportingDocument
                isTitleRequired={true}
                action={action}
                pid={selectedPid}
                isDisabled={!serverData || !(serverData && serverData.length) || isEqual(action, 'view')}
                userPermissions={userPermissions}
              />


              <ApprovalLogs
                comment={comment}
                setComment={setComment}
                approvalRole={approvalRole}
                approve={approve}
                approval={approval}
                selectedTId={selectedTId}
                isDisabled={!serverData || !(serverData && serverData.length) || action === 'view'}
                userPermissions={userPermissions}
                action={action}
                serverData={tableData}
                isManualRecord={serverData?.[0]?.isManual}
                l1Remarks={serverData?.[0]?.l1Remarks ?? ''}
                l1Status={serverData?.[0]?.l1Status ?? ''}
                l2Remarks={serverData?.[0]?.l2Remarks ?? ''}
                l2Status={serverData?.[0]?.l2Status ?? ''}
              />

              <Card className="p-3">
                <Row className="justify-content-end flex-row pr-4">
                  {/* <Button
                    color="info"
                    size="sm"
                    type="button"
                    className="my-2"
                    onClick={action === 'view' ? () => window.close() : () => props.history.goBack()}
                  >
                    Close
                  </Button> */}
                  <CustomButton
                    type="close"
                    className={'mx-1'}
                    content={"Close"}
                    color="info"
                    permissionType={'R'}
                    onClick={action === 'view' ? () => window.close() : approval === "level1Approval" ? () => props.history.push('/admin/level1Approval') : () => props.history.push('/admin/level2Approval')}
                    permissionSet={userPermissions}
                  />
                </Row>
              </Card>

            </>
          )
        ) : <Card className="p-4"><span>Please select a Process Instance ID</span></Card>}
      </Container> : <PermisionDenied />
  );
}

export default withRouter(FeeMaster);
