import React, { useEffect, useState } from "react"
import { Modal, ModalBody, Row, Col } from "reactstrap"
import { ReactComponent as CloseIcon } from "../../../../../assets/img/svg/CloseIcon.svg"
import AAutoComplete from "../../common/form-fields-mui/AAutoComplete";
import CommonInput from "../../common/formFeilds/input/commonInput";
import { ReactComponent as Upload } from "../../../../../assets/img/svg/upload.svg";
import AButton from "../../common/form-fields-mui/AButton";
import { fetchBusinessAreasByRegions } from "../../academicPlanAndTest/constant/common";
import { fetchAllPostPromisedData, getDropdownIdsFromSelectArray } from "../../common/utils/methods/commonMethods/utilityMethod";
import DocUploadModal from "../studentCharges/studentChargesDetails/paymentMode/DocUploadModal";
import { endpoint } from "../../common/constant";
import { successToast } from "../../common/utils/methods/toasterFunctions/function";
import { ReactComponent as InfoIcon } from "../../../../../assets/img/svg/fi_info.svg";
import ALoader from "../../common/a-loader";
import { ReactComponent as ActiveControl } from "../../../../../assets/img/svg/ActiveControl.svg"
import { ReactComponent as InactiveControl } from "../../../../../assets/img/svg/InactiveControl.svg"

const AddQrModal = ({
    open,
    setOpen,
    form,
    regionData,
    formHandler,
    currentPage,
    fetchQrCodes,
    fetchBusinessArea,
    businessAreaData,
    businessAreaLoader,
    setBusinessAreaData,
    setBusinessAreaLoader,
    isSaveDisabled
}) => {

    const labelStyles = {
        marginBottom: '4px',
        color: '#525f7f',
        fontWeight: 600,
        fontSize: '0.875rem'
    }

    const selectStyles = {
        control: { height: '40px', borderRadius: '4px' },
        valueContainer: { fontSize: '14px' }
    }

    // useEffect(()=>{
    //     console.log('regionData-------->>>>',regionData );
    // },[])


    const [qrUploadModalOpen, setQrUploadModalOpen] = useState(false)




    const saveHandler = async () => {
        isSaveDisabled.current = true;
        console.log('form', form)
        const reqObj = {
            qrCode: form?.qrCodeS3Key,
            effectiveDate: new Date(form?.effectiveDate),
            businessAreaId: getDropdownIdsFromSelectArray(form?.businessAreas),
            regionAreaId: getDropdownIdsFromSelectArray(form?.regions),
            status: form?.status
        }
        if (form?.id) {
            const resp = await fetchAllPostPromisedData(`${endpoint.qrCode.updateQr}/${form?.id}`, reqObj, 'post');
            if (resp?.code === 200) {
                successToast('Qr Code updated successfully');
                setOpen(false);
                fetchQrCodes(currentPage);
            } else {
                isSaveDisabled.current = false;
            }
        } else {
            const resp = await fetchAllPostPromisedData(`${endpoint.qrCode.saveQr}`, reqObj, 'post');
            if (resp?.code === 200) {
                successToast('Qr Code saved successfully');
                setOpen(false);
                fetchQrCodes(currentPage);
            } else {
                isSaveDisabled.current = false;
            }
        }
    }

    return (
        <Modal size="xl" className="top-20" isOpen={open}>
            <ModalBody>
                <div className="d-flex justify-content-between">
                    <span className="manage-qr-hdr">{form?.id ? "Edit Qr Code" : "Add QR Code"}</span>
                    <CloseIcon style={{ cursor: "pointer" }} onClick={() => setOpen(false)} />
                </div>
                <div className="qr-modal-box">
                    <Row className="d-flex justify-content-between">
                        <Col>
                            <div style={labelStyles}>Region</div>
                            <AAutoComplete
                                style={selectStyles}
                                items={regionData}
                                handleChange={(values) => {
                                    console.log('regionValues---->>>', values);
                                    formHandler(values, 'regions');
                                    fetchBusinessArea(values, setBusinessAreaLoader, setBusinessAreaData);
                                }}
                                currentValue={form?.regions}
                                isShowCount={true}
                                placeholder={"Select region"}
                            />
                        </Col>

                        <Col>
                            <div style={labelStyles}>Business Area</div>
                            <AAutoComplete
                                style={selectStyles}
                                items={businessAreaData}
                                isLoading={businessAreaLoader}
                                currentValue={form?.businessAreas}
                                isShowCount={true}
                                handleChange={(values) => {
                                    formHandler(values, 'businessAreas')
                                }}
                                placeholder={"Select business area"}

                            />
                        </Col>

                        <Col >
                            <div style={labelStyles}>Effective Date</div>
                            <CommonInput
                                type="datePicker"
                                // label={"Effective Date"}
                                value={form?.effectiveDate || new Date()}
                                onChange={(val) => formHandler(val, 'effectiveDate')}
                                placeHolder={"Select effective date"}
                            />
                        </Col>

                        <Col style={{flexGrow: form?.qrCodeS3Key ? 1 : 0.6}}>
                            <div style={labelStyles} className="mb-2">Status</div>
                            {form?.status === "ACTIVE" ?
                                <span onClick={() => formHandler("INACTIVE", "status")}><ActiveControl /> Active</span> :
                                <span onClick={() => formHandler("ACTIVE", "status")}><InactiveControl /> Inactive</span>
                            }
                        </Col>

                        <Col>
                            <div style={{ ...labelStyles, marginBottom: form?.qrCodeS3Key ? '8px' : '4px' }}>QR Code</div>
                            {form?.qrCodeS3Key ?
                                <span style={{ cursor: "pointer" }} onClick={() => setQrUploadModalOpen(true)}>
                                    <img src={require('../../../../../assets/img/DocumentLarge.jpg')} style={{ height: '24px', width: '24px', marginRight: '10px' }} />
                                    <span style={{ fontSize: '16px', fontWeight: 600, color: '#262626' }}>{'QR Code'}</span>
                                    {/* <Upload className='ml-2' onClick={() => setQrUploadModalOpen(true)} /> */}

                                </span> :
                                <AButton
                                    variant="outline"
                                    className="no-ml-btn"
                                    children={<><Upload className='mr-2' /> Upload </>}
                                    updatedStyle={{ width: '100%' }}
                                    onClick={() => { setQrUploadModalOpen(true) }}
                                />
                            }
                        </Col>

                    </Row>
                </div>
                <div className="d-flex justify-content-between">
                    <span>
                        <InfoIcon />
                        <span className="info-msg">All fields are mandatory</span>
                    </span>
                    <AButton
                        children={'Save'}
                        variant="primary_filled"
                        onClick={() => {
                            if (!isSaveDisabled.current) saveHandler();
                        }}
                        className="float-right"
                        disabled={!form?.businessAreas?.length || !form?.regions?.length || !form?.qrCodeS3Key || !form?.isDirty}
                    />
                </div>

                <DocUploadModal
                    open={qrUploadModalOpen}
                    setOpen={setQrUploadModalOpen}
                    saveHandler={(s3Key) => {
                        setQrUploadModalOpen(false);
                        formHandler(s3Key, "qrCodeS3Key")
                    }}
                    acceptedFileTypes={[".jpg", ".jpeg", ".png"]}
                    maxUploadFileSize={5}
                    serviceName={'QR_CODE_IMG'}
                    uniqueKeyParameter={"qrCodeImage"}
                    uploadedFileS3Key={form?.qrCodeS3Key}
                    uploadedFileName={"QRImage"}
                    modalTitle={"Upload QR Code"}
                    viewUploadedImage={true}
                />

            </ModalBody>
        </Modal>
    );
}

export default AddQrModal;