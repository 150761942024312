import React, { useState, useEffect, useContext } from 'react';
import ProjectDetailsTable from './projectDetailTable';
import { PermissionContext, RolePermissions } from "../../../../../appContext";
import { constants, endpoint, pages } from "../../common/constant";
import { PermisionDenied } from "../../common/commonComponents";
import CustomLoader from "../../common/commonComponents/Loader/loader";
import { GetPagination } from "../../common/commonComponents/pagination/pagination";
import { fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import { getPaginatedData } from '../../common/utils/methods/commonMethods/paginationMethod';


const ProjectDetails = () => {
  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(
    permissions,
    pages['projectDetails']['id']
  );

  const [classArray, setclassArray] = useState([]);
  const [apiloader, setapiloader] = useState(false);
  const [state, setstate] = useState([]);
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [pagination, setpagination] = useState(constants.pagination);
  const pageSize = 10;

  const fetchClasses = async url => {
    setapiloader(true);
    const syncData = await fetchAllPromisedData(url,true);
    if (syncData?.code === 200) {
      setstate(syncData.data);
      getPaginatedData(syncData.data, pageSize, pagination, setpagination, setapiloader, setclassArray)
    }else{
      setstate([]);
      setclassArray([]);
      setapiloader(true);
    }
  };

  const getAllrecords = (toPreviousPage = false) => {
    if(toPreviousPage){
      let n = pagination;
      n.currentPage=n.currentPage-1;
      setpagination(n)
    }
    fetchClasses(endpoint.projectDetails.getAllProject);
  }

  useEffect(() => {
    getAllrecords()
  }, []);

  return (
    userPermissions ?
      (
        <>
          {apiloader ? (
            <CustomLoader
              apiLoader={apiloader}
              loaderHeight={'200px'}
              loaderWidth={'100% '}
            />
          ) : (
            <>
              <ProjectDetailsTable
                getAllrecords={getAllrecords}
                setclassArray={setclassArray}
                classArray={classArray}
                permissionSet={userPermissions}
                isSaveVisible={isSaveVisible}
                setisSaveVisible={setisSaveVisible}
              />
              {pagination.totalPage > 1 ? (
                <GetPagination
                  isSaveVisible={isSaveVisible}
                  fetchClasses={fetchClasses}
                  setclassArray={setclassArray}
                  pagination={pagination}
                  setpagination={setpagination}
                  pageSize={pageSize}
                  state={state}
                />
              )
                : null}
            </>
          )}
        </>)
      :
      <PermisionDenied />
  );
};
export default ProjectDetails;
