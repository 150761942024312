import {Button} from "reactstrap";
import React,{Fragment} from "react";
// const getPermissions

const CreateButton =(props)=>{
    const {text='Add New',permissions={write:true,read:true}} = props;
    return (
        <Fragment>
            {
                permissions.write && permissions.read ?
                    <Button type="button" color="info" size="md" {...props}>
                        <i className="fas fa-plus" /> {'  '}&nbsp;{text}
                    </Button>
                    : null
            }
        </Fragment>
    )
}
export default CreateButton;