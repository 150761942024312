import React, { useContext, useEffect, useState } from 'react'
import { PermissionContext, RolePermissions } from 'appContext';
import { endpoint, pages } from '../../common/constant';
import { PermisionDenied } from '../../common/commonComponents';
import { Card, CardBody, CardHeader, Container, Row, Col } from 'reactstrap';
import CommonInput from '../../common/formFeilds/input/commonInput';
import CustomButton from 'components/CustomButton';
import { useHistory } from 'react-router';
import { ButtonText } from 'variables/Buttons';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import CustomLoader from '../../common/commonComponents/Loader/loader';
import { successToast } from '../../common/utils/methods/toasterFunctions/function';
import validationRules from './validation.json';
import { customValidation } from '../../common/utils/methods/validations/validation';


function FormToRender(props) {
  let history = useHistory();
  const {id, userPermissions, formData, setFormData, isNew, setApiLoader} = props;
  const secTypeData = [
    {label: 'PRIMARY', value: 'PRIMARY'}, 
    {label: 'SECONDARY', value: 'SECONDARY'}
  ]
  const statusData = [
    { value: 'ACTIVE', label: 'ACTIVE' },
		{ value: 'INACTIVE', label: 'INACTIVE' },
  ]

  const saveHandler = async () => {
    let isValid = true;
    isValid = customValidation(formData, validationRules);
    if(!isValid) return;
    
    if(isNew){
      setApiLoader(true);
      const createData = await fetchAllPostPromisedData(endpoint.classSection.create, formData, 'post');
      if(createData?.code===200){
        setApiLoader(false);
        successToast(createData['message']);
        history.push('/admin/edp/classSection');
      }
      else{
        setApiLoader(false);
      }
    } else {
      setApiLoader(true);
      const updateData = await fetchAllPostPromisedData(`${endpoint.classSection.update}/${id}`, formData, 'put');
      if(updateData?.code===200){
        setApiLoader(false);
        successToast(updateData['message']);
        history.push('/admin/edp/classSection');
      }
      else{
        setApiLoader(false);
      }
    }
  }

  return (
    <CardBody>
      <Row>
        <Col className='mb-4' md='6'>
          <CommonInput
            mandatory={true}
            type={'text'}
            defaultValue={formData?.classSectionDesc}
            label={'Description'}
            placeHolder={'Description'}
            maxLength={400}
            restrictSpecialCharacter={true}
            allowedSomeSpecialCharacters={true}
            regEx={/[^a-zA-Z0-9\-_.()&\s]/g}
            lengthNotifier={true}
            onChange={value => 
              setFormData({
                ...formData,
                classSectionDesc: value,
              })
            }
          />
        </Col>
        <Col className='mb-4' md='6'>
          <CommonInput
            mandatory={true}
            type={'text'}
            defaultValue={formData?.classSectionDispValue}
            label={'Display Value'}
            placeHolder={'Display Value'}
            maxLength={4}
            regEx={/^([a-zA-Z0-9])*$/}
            lengthNotifier={true}
            onChange={value =>
              setFormData({
                ...formData,
                classSectionDispValue: value.toUpperCase(),
              })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col className='mb-4' md='6'>
          <CommonInput 
            mandatory={true}
            label={'Section Type'}
            type={'select'}
            placeHolder={'Select Section Type'}
            defaultValue={formData?.classSectionType ? {label: formData.classSectionType, value: formData.classSectionType} : null}
            data={secTypeData}
            onChange={(e) => setFormData({ ...formData, classSectionType: e.value })}
          />
        </Col>
        <Col className='mb-4' md='6'>
          <CommonInput
            mandatory={true}
            label={'Status'}
            placeHolder={'Status'}
            type={'select'}
            data={statusData}
            defaultValue={formData?.status ? {
              value: formData['status'],
              label: formData['status'],
            } : null}
            onChange={(e) => {
              setFormData({ ...formData, status: e.value });
            }}
          />
        </Col>
      </Row>
      <Row className={`d-flex pr-3 justify-content-end`}>
        <CustomButton 
          className={'mr-3'}
          content={ButtonText.COMMON.UPDATE}
          permissionType={'C,U'}
          permissionSet={userPermissions}
          onClick={saveHandler}
        />
        <CustomButton
          content={'Cancel'}
          permissionType={'cancel'}
          permissionSet={userPermissions}
          onClick={() => { history.push('/admin/edp/classSection'); }}
        />
      </Row>
    </CardBody>
  )
};

function NewClassSection(props) {
  const { action, id } = props.match.params;
  const [apiLoader, setApiLoader] = useState(false);
  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages['classSection']['id']);
  const [customRoutePermission, setCustomRoutePermission] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [headerTitle, setHeaderTitle] = useState('New');
  const [formData, setFormData] = useState({
    classSectionDesc: '',
    classSectionDispValue: '',
    classSectionType: '',
    status: "ACTIVE"
  });

  const fetchData = async (id) => {
    setApiLoader(true);
    const classSectionData = await fetchAllPromisedData(`${endpoint.classSection.getClassSection}/${id}`, true);
    if(classSectionData?.code===200){
      setFormData({
        classSectionDesc: classSectionData.data.classSectionDesc,
        classSectionDispValue: classSectionData.data.classSectionDispValue,
        classSectionType: classSectionData.data.classSectionType,
        status: classSectionData.data.status,
      });
      setApiLoader(false);
    }
    else{
      setFormData(null);
      setApiLoader(false);
    }
  }

  useEffect(() => {
    if(action === 'new'){
      setIsNew(true);
      userPermissions && userPermissions.includes('C') ? setCustomRoutePermission(true) : setCustomRoutePermission(false);
    } else if(action === 'edit' && id){
      fetchData(id);
      userPermissions && userPermissions.includes('U') ? setCustomRoutePermission(true) : setCustomRoutePermission(false);
      setHeaderTitle('Edit')
    }
  },[id, action])
  
  return (
    customRoutePermission ? (
      <Container fluid className="mt-3">
        <Row>
          <div className='col'>
            <Card>
              <CardHeader className='d-flex justify-content-between'>
                <h3 className="mb-0">{headerTitle} Class Section</h3>
              </CardHeader>
              {apiLoader ? (
                <CardBody>
                  <CustomLoader apiLoader={apiLoader} />
                </CardBody>
              ) : (
                <FormToRender
                  userPermissions={userPermissions}
                  formData={formData}
                  setFormData={setFormData}
                  isNew={isNew}
                  id={id}
                  apiLoader={apiLoader}
                  setApiLoader={setApiLoader}
                />
              )}
            </Card>
          </div>
        </Row>
      </Container>
    ) : (
      <PermisionDenied />
    )
  )
}

export default NewClassSection