import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Card, CardHeader, CardBody, Button } from "reactstrap";
import Loader from "react-loader-spinner";
import { Accordion } from "../../../../common/commonComponents/Accordion/accordion";
import {
  StudentDetailsForm,
  ContactDetailsForm,
  OtherDetailsForm,
  GstDetailsForm,
  BankDetailsForm,
  LogDetailsForm,
} from "./form";
import {
  masterServiceBaseUrl,
  enrollmentServiceBaseUrl,
  failureToast,
  getAPI,
  putpost,
  putpost2,
  successToast,
} from "services/http";
import { fetchDetails } from "../../../../common/utils/methods/commonMethods/utilityMethod";
import moment from "moment";

const CreateNewStudent = (props) => {
  const [values, setValues] = useState({
    studentLastName: "",
    studentFirstName: "",
    gender: "",
    schoolStudingIn: "",
    bloodGroup: "",
    dob: "01-01-2015",
    category: "",
    studentIdStatus: [],
    file: null,
    primaryMobileNumber: "",
    studentMobileNumber: "",
    whatsAppNumber: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    studentEmailId: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    addressLine4: "",
    fatherName: "",
    fatherMobileNumber: "",
    fatherEmailId: "",
    motherName: "",
    motherMobileNumber: "",
    motherEmailId: "",
    studentMotherOccupationId:"",
    studentFatherOccupationId:'',
    gstCategory: "",
    companyName: "",
    effectiveDate: "",
    gstin: "",
    bankId: "",
    bankAccountNumber: "",
    ifscCode: "",
    accountHolderName: "",
    updatedOn: moment(new Date()).format("YYYY-MM-DD"),
    updatedBy: "",
    createdBy: "",
    createdOn: moment(new Date()).format("YYYY-MM-DD"),
    district: "",
  });

  const [imagePath, setImagePath] = useState(null);
  const [country, setCountry] = useState([]);
  const [stateName, setStateName] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [city, setCity] = useState([]);
  const [district, setDistrict] = useState([]);
  const [bloodGrp, setBloodGrp] = useState([]);
  const [gender, setGender] = useState([]);
  const [phone, setPhone] = useState([]);
  const [email, setEmail] = useState([]);
  const [gstCategory, setGstCategory] = useState([]);
  const [parentsOcc, setParentOcc] = useState([]);
  const [category, setCategory] = useState([]);
  const [studentIdStatus, setStudentIdStatus] = useState([]);
  const [externalOrganisation, setExternalOrganisation] = useState([]);
  const [apiLoader, setApiLoader] = useState(false);
  const [bankData, setBankData] = useState([]);

  const fetchData = (url, stateMethod, name) => {
    getAPI(
      url,
      (data) => {
        let list = [];

        if (name === "externalOrganizationId") {
          list = data.data.map((data) => {
            return {
              value: data.id,
              label: data.organizationName,
            };
          });
        } else {
          //this is for dropdown fields only
          let keyName = `${name}DispValue`;

          list = data.data.map((data) => {
            return {
              value: data.id,
              label: data[keyName],
            };
          });
        }
        stateMethod(list);
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  };

  useEffect(() => {
    //matser apis
    fetchData(
      masterServiceBaseUrl + "/country/getAllActiveCountry",
      setCountry,
      "country"
    );
    fetchData(
      masterServiceBaseUrl + "/state/getAllActiveState",
      setStateName,
      "state"
    );
    fetchData(
      masterServiceBaseUrl + "/pincode/getAllActivePincode",
      setPincode,
      "pincode"
    );
    fetchData(masterServiceBaseUrl + "/city/getAllActiveCity", setCity, "city");
    fetchData(
      masterServiceBaseUrl + "/district/getAllActiveDistrict",
      setDistrict,
      "district"
    );
    fetchData(
      masterServiceBaseUrl + "/gender/getActiveGender",
      setGender,
      "gender"
    );
    fetchData(
      masterServiceBaseUrl + "/bloodGroup/getAllActiveBloodGroup",
      setBloodGrp,
      "bloodGroup"
    );
    fetchData(
      masterServiceBaseUrl + "/phoneType/getAllActivePhoneType",
      setPhone,
      "phoneType"
    );
    fetchData(
      masterServiceBaseUrl + "/emailType/getAllActiveEmailType",
      setEmail,
      "emailType"
    );
    fetchData(
      masterServiceBaseUrl + "/gstinCategory/getAllActiveGstinCategory",
      setGstCategory,
      "gstinCategory"
    );
    fetchData(
      masterServiceBaseUrl + "/parentsOccupation/getActiveParentsOccupation",
      setParentOcc,
      "parentsOccupation"
    );
    fetchData(
      masterServiceBaseUrl + "/category/getActiveCategory",
      setCategory,
      "category"
    );
    fetchData(
      masterServiceBaseUrl + "/studentIdStatus/getAllActiveStudentIdStatus",
      setStudentIdStatus,
      "studentIdStatus"
    );
    fetchData(
      masterServiceBaseUrl +
        "/externalOrganizationId/getAllExternalOrganizationId",
      setExternalOrganisation,
      "externalOrganizationId"
    );
    fetchDetails(
      `${masterServiceBaseUrl}/bank/getActiveBanks`,
      setBankData,
      "bank",
      "bankDetails"
    );
    onDropdownChange({value: 6, label: 'Active'},'studentIdStatus');
  }, []);

  const onchange = (e) => {
    // if (e.target.name === "fatherName") {
    //   if(values.fatherName==='')

    //   if (values.fatherName !== "" && e.target.value) {
    //     setValues({ ...values, [e.target.name]: e.target.value });
    //   } else {
    //     setValues({ ...values, [e.target.name]: `Mr. ${e.target.value}` });
    //   }
    // } else {
    setValues({ ...values, [e.target.name]: e.target.value });
    // }
  };

  const onDropdownChange = (obj, name) => {
    setValues({ ...values, [name]: obj });
  };

  const fileHandler = (e) => {
    if (e.target.files[0] !== undefined) {
      setValues({ ...values, file: e.target.files[0] });
      setImagePath(URL.createObjectURL(e.target.files[0]));
    } else {
      setValues({ ...values, file: null });
      setImagePath(null);
    }
  };

  const validate = (type, value) => {
    const email = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/; //^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;///^[A-Za-z0-9+_.-]+@(.+)$/;
    const mobileReg = /^([0-9])\1*$/;

    if (type === "email") {
      if (value === "" || email.test(value)) {
        return true;
      }
    }

    if (type === "number") {
      if (value === "" || mobileReg.test(value)) {
        return true;
      }
    }
  };

  const getSelectedValue = (id, listName) => {
    const val = listName.filter((item) => {
      return item.value === id;
    });
    return val.length ? val[0] : "";
  };

  const fetchPincodeById = (obj) => {
    if (!obj) {
      return;
    }
    getAPI(
      `${masterServiceBaseUrl}/pincode/getPincode/${Number(obj.value)}`,
      (data) => {
        // onDropdownChange(sData, "pincode")
        setValues({
          ...values,
          pincode: obj,
          city: getSelectedValue(data.data.cityId, city),
          state: getSelectedValue(data.data.stateId, stateName),
          country: getSelectedValue(data.data.countryId, country),
          district: getSelectedValue(data.data.districtId, district),
        });
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  };

  const onSubmit = () => {
    if (!values.studentIdStatus) {
      failureToast("Please Select Student ID Status in Student Details");
      return;
    }
    if (!values.studentFirstName) {
      failureToast("Please Enter Student First Name in Student Details");
      return;
    }
    if (!values.studentLastName) {
      failureToast("Please Enter Student Last Name in Student Details");
      return;
    }
    if (!values.dob) {
      failureToast("please enter valid DOB");
      return;
    }
    if (!moment(values.dob).isAfter('1990-01-01') || !moment(values.dob).isBefore(moment(new Date).format('YYYY-MM-DD'))) {
      failureToast("please enter valid DOB");
      return;
    }
    if (!values.primaryMobileNumber) {
      failureToast("Please Enter Primary Mobile Number in Contact Details");
      return;
    }
    if (values.primaryMobileNumber) {
      if (
        validate(
          "number",
          values.primaryMobileNumber || values.primaryMobileNumber.length < 10
        )
      ) {
        failureToast(
          "Please Enter Valid Primary Mobile Number in Contact Details"
        );
        return;
      }
    }

    if (values.whatsAppNumber) {
      if (
        validate(
          "number",
          values.whatsAppNumber || values.whatsAppNumber.length < 10
        )
      ) {
        failureToast("Please Enter Valid Whatsapp Number in Contact Details");
        return;
      }
    }

    if (values.studentMobileNumber) {
      if (
        validate(
          "number",
          values.studentMobileNumber || values.studentMobileNumber.length < 10
        )
      ) {
        failureToast(
          "Please Enter Valid Student Mobile Number in Contact Details"
        );
        return;
      }
    }

    if (!values.studentEmailId) {
      failureToast("Please Enter Email ID in Contact Details");
      return;
    }

    if (values.studentEmailId) {
      if (!validate("email", values.studentEmailId)) {
        failureToast("Please Enter Valid Email ID in Contact Details");
        return;
      }
    }

    // if(values.addressLine1 && values.addressLine1.split(" ").length > 2){
    //   failureToast("Only Single Space is Allowed in  Address Line 1 in Contact Details");
    //   return;
    // }

    // if(values.addressLine2 && values.addressLine2.split(" ").length > 2){
    //   failureToast("Only Single Space is Allowed in  Address Line 2 in Contact Details");
    //   return;
    // }

    if (!values.pincode) {
      failureToast("Please Select Pincode in Contact Details");
      return;
    }

    if (!values.city) {
      failureToast("Please Select City in Contact Details");
      return;
    }

    if (!values.state) {
      failureToast("Please Select State in Contact Details");
      return;
    }

    if (!values.country) {
      failureToast("Please Select Country in Contact Details");
      return;
    }

    if (!values.fatherName) {
      failureToast("Please Enter Father Name");
      return;
    }

    if (values.fatherMobileNumber) {
      if (
        validate(
          "number",
          values.fatherMobileNumber || values.fatherMobileNumber.length < 10
        )
      ) {
        failureToast(
          "Please Enter Valid Father's Mobile Number in Others Details"
        );
        return;
      }
    }

    if (values.fatherEmailId) {
      if (!validate("email", values.fatherEmailId)) {
        failureToast("Please Enter Valid Father's Email ID in Others Details");
        return;
      }
    }

    if (!values.motherName) {
      failureToast("Please Enter Mother Name");
      return;
    }

    if (values.motherMobileNumber) {
      if (
        validate(
          "number",
          values.motherMobileNumber || values.motherMobileNumber.length < 10
        )
      ) {
        failureToast(
          "Please Enter Valid Mothers's Mobile Number in Others Details"
        );
        return;
      }
    }

    if (values.motherEmailId) {
      if (!validate("email", values.motherEmailId)) {
        failureToast("Please Enter Valid Mothers's Email ID in Others Details");
        return;
      }
    }

    if (values.gstCategory) {
      if (!values.companyName) {
        failureToast("Please Enter GST Sponsor Name in Gst Details");
        return;
      }
      if (!values.effectiveDate) {
        failureToast("Please Enter GST Effective Date in Gst Details");
        return;
      }
      if (!values.gstin) {
        failureToast("Please Enter GSTIN in Gst Details");
        return;
      }
      if (values.gstin && values.gstin.length < 15) {
        failureToast("Please Enter Valid GSTIN in Gst Details");
        return;
      }
    }

    if (values.bankId?.value) {
      if (!values.bankAccountNumber) {
        failureToast("Please Enter Bank Account Number in Bank Details");
        return;
      }

      if (values.bankAccountNumber && values.bankAccountNumber.length < 16) {
        failureToast("Please Enter Valid Bank Account Number in Bank Details");
        return;
      }

      if (!values.ifscCode) {
        failureToast("Please Enter IFSC Code in Bank Details");
        return;
      }

      if (values.ifscCode && values.ifscCode.length < 11) {
        failureToast("Please Enter Valid IFSC Code in Bank Details");
        return;
      }

      if (!values.accountHolderName) {
        failureToast("Please Enter Account Holder's Name in Bank Details");
        return;
      }
    }

    setApiLoader(true);

    const studentData = {
      studentDetailsDTO: {
        studentFirstName: values.studentFirstName,
        studentLastName: values.studentLastName,
        studentPhotoUrl: "www.testurl.com",
        status: "ACTIVE",
        studentIdStatus: values && values.studentIdStatus.value,
        studentMobileNumber: values.primaryMobileNumber,
        studentMothersMobileNumber: values.motherMobileNumber,
        studentFathersPhoneTypeId: 19,
        studentFatherName: values.fatherName.trim(),
        studentFathersEmailId: values.fatherEmailId,
        studentMotherName: values.motherName.trim(),
        studentMothersEmailId: values.motherEmailId,
        studentMotherOccupationId : values && values.studentMotherOccupationId.value,
        studentFatherOccupationId : values && values.studentFatherOccupationId.value,
        studentCategoryId: values && values.category.value,
        dob: values.dob,
        studentMothersPhoneTypeId: 19,
        studentFathersMobileNumber: values.fatherMobileNumber,
        bloodGroupId: values && values.bloodGroup.value,
        schoolId: values && values.schoolStudingIn.value,
        genderId: values && values.gender.value,
      },
      studentContactDetailsDTO: {
        phoneNumberList: [
          {
            phoneKey: `p${values.primaryMobileNumber}`,
            phoneDispValue: `p${values.primaryMobileNumber}`,
            phoneDesc: `p${values.primaryMobileNumber}`,
            phone: values.primaryMobileNumber,
            prefix: "+91",
            phoneTypeId: "21",
            referenceType: "STUDENT_ENROLLMENT",
            verifiedStatus: true,
            referredStatus: true,
            otpStatus: true,
            optinStatus: true,
            referenceId: "-1",
          },
          {
            phoneKey: `w${values.whatsAppNumber}`,
            phoneDispValue: `w${values.whatsAppNumber}`,
            phoneDesc: `w${values.whatsAppNumber}`,
            phone: values.whatsAppNumber,
            prefix: "+91",
            phoneTypeId: "22",
            referenceType: "STUDENT_ENROLLMENT",
            verifiedStatus: true,
            referredStatus: true,
            otpStatus: true,
            optinStatus: true,
            referenceId: -1,
          },
        ],
        cityId: values && values.city.value,
        email: values.studentEmailId,
        emailTypeId: 42,
        countryId: values && values.country.value,
        addressKey: values.studentFirstName + values.primaryMobileNumber,
        addressDispValue: values.studentFirstName + values.primaryMobileNumber,
        addressDesc: values.studentFirstName + values.primaryMobileNumber,
        building: values.studentFirstName + values.primaryMobileNumber,
        addressLine1: values.addressLine1.trim(),
        addressLine2: values.addressLine2.trim(),
        addressLine3: "addr9",
        addressLine4: "addr9",
        pincodeId: values && values.pincode.value,
        stateId: values && values.state.value,
        districtId: values && values.district.value,
        addressCategoryId: 1,
        addressReferenceType: "STUDENT_ENROLLMENT",
        referenceId: -1,
      },
      studentGstinDetailsDTO: values.gstCategory
        ? {
            gstinCategoryMasterId:
              values.gstCategory && values.gstCategory.value,
            gstinCompanyName: values.companyName,
            gstEffectiveDate: values.effectiveDate,
            gstinGstNo: values.gstin,
            status: "ACTIVE",
          }
        : null,
      studentBankDetailsDto: {
        accountHolderName: values.accountHolderName,
        bankAccountNumber: values.bankAccountNumber,
        bankId: values.bankId?.value,
        ifscCode: values.ifscCode,
      },
    };

    if (values.studentMobileNumber) {
      studentData.studentContactDetailsDTO.phoneNumberList.push({
        phoneKey: `s${values.studentMobileNumber}`,
        phoneDispValue: `s${values.studentMobileNumber}`,
        phoneDesc: `s${values.studentMobileNumber}`,
        phone: values.studentMobileNumber,
        prefix: "+91",
        phoneTypeId: "18",
        referenceType: "STUDENT_ENROLLMENT",
        verifiedStatus: true,
        referredStatus: true,
        otpStatus: true,
        optinStatus: true,
        referenceId: -1,
      });
    }

    // const studentData = {
    //   studentDetailsDTO: {
    //     studentFirstName: values.studentFirstName.trim(),
    //     studentLastName: values.studentLastName.trim(),
    //     studentPhotoUrl: "www.testurl.com",
    //     status: "ACTIVE",
    //     studentIdStatus: values.studentIdStatus && values.studentIdStatus.value,
    //     studentMobileNumber: values.primaryMobileNumber,
    //     studentMothersMobileNumber: values.motherMobileNumber,
    //     studentFathersPhoneTypeId: 19,
    //     studentFatherName: `Mr. ${values.fatherName.trim()}`,
    //     studentFathersEmailId: values.fatherEmailId.trim(),
    //     studentMotherName: values.motherName.trim(),
    //     studentMothersEmailId: values.motherEmailId.trim(),
    //     studentParentOccupationId:
    //       values.parentOccupation && values.parentOccupation.value,
    //     studentCategoryId: values.category && values.category.value,
    //     dob: values.dob,
    //     studentMothersPhoneTypeId: 19,
    //     studentFathersMobileNumber: values.fatherMobileNumber,
    //     bloodGroupId: values.bloodGroup==='' ? null :values.bloodGroup && values.bloodGroup.value,
    //     schoolId: values.schoolStudingIn && values.schoolStudingIn.value,
    //     genderId: values.gender && values.gender.value,
    //   },
    //   studentContactDetailsDTO: {
    //     phoneNumberList: [
    //       {
    //         "phoneKey": `p${values.primaryMobileNumber}`,
    //         "phoneDispValue": `p${values.primaryMobileNumber}`,
    //         "phoneDesc": `p${values.primaryMobileNumber}`,
    //         "phone": values.primaryMobileNumber,
    //         "prefix": "+91",
    //         "phoneTypeId": "18",
    //         "referenceType": "STUDENT_ENROLLMENT",
    //         "verifiedStatus": true,
    //         "referredStatus": true,
    //         "otpStatus": true,
    //         "optinStatus": true,
    //         "referenceId": "-1",
    //       },
    //       {
    //         "phoneKey": `w${values.whatsAppNumber}`,
    //         "phoneDispValue": `w${values.whatsAppNumber}`,
    //         "phoneDesc": `w${values.whatsAppNumber}`,
    //         "phone": values.whatsAppNumber,
    //         "prefix": "+91",
    //         "phoneTypeId": "22",
    //         "referenceType": "STUDENT_ENROLLMENT",
    //         "verifiedStatus": true,
    //         "referredStatus": true,
    //         "otpStatus": true,
    //         "optinStatus": true,
    //         "referenceId": -1,
    //       },
    //       values.studentMobileNumber
    //         ? {
    //             "phoneKey": `s${values.studentMobileNumber}`,
    //             "phoneDispValue": `s${values.studentMobileNumber}`,
    //             "phoneDesc": `s${values.studentMobileNumber}`,
    //             "phone": values.studentMobileNumber,
    //             "prefix": "+91",
    //             "phoneTypeId": "21",
    //             "referenceType": "STUDENT_ENROLLMENT",
    //             "verifiedStatus": true,
    //             "referredStatus": true,
    //             "otpStatus": true,
    //             "optinStatus": true,
    //             "referenceId": -1,
    //           }
    //         : null,
    //     ],
    //     cityId: values.city && values.city.value,
    //     email: values.studentEmailId.trim(),
    //     emailTypeId: 42,
    //     countryId: values.country && values.country.value,
    //     addressKey: values.studentFirstName + values.primaryMobileNumber,
    //     addressDispValue: values.studentFirstName + values.primaryMobileNumber,
    //     addressDesc: values.studentFirstName + values.primaryMobileNumber,
    //     building: values.studentFirstName + values.primaryMobileNumber,
    //     addressLine1: values.addressLine1,
    //     addressLine2: values.addressLine2,
    //     addressLine3: null,
    //     addressLine4: null,
    //     pincodeId: values.pincode && values.pincode.value,
    //     stateId: values.state && values.state.value,
    //     districtId: values.district && values.district.value,
    //     addressCategoryId: 1,
    //     addressReferenceType: "STUDENT_ENROLLMENT",
    //     referenceId: -1,
    //     createdOn: values.createdOn,
    //     createdBy: values.createdBy,
    //     updatedOn: values.updatedOn,
    //     updatedBy: values.updatedBy,
    //   },
    //   studentGstinDetailsDTO: values.gstinCategoryMasterId ? {
    //     gstinCategoryMasterId: values.gstCategory && values.gstCategory.value,
    //     gstinCompanyName: values.companyName,
    //     gstEffectiveDate: values.effectiveDate,
    //     gstinGstNo: values.gstin,
    //     status: "ACTIVE",
    //   }:null,
    // };

    putpost(
      `${enrollmentServiceBaseUrl}/student/createStudent`,
      (data) => {
        successToast(data["message"]);
        setValues({
          studentLastName: "",
          studentFirstName: "",
          gender: "",
          schoolStudingIn: "",
          bloodGroup: "",
          dob: "",
          category: "",
          studentIdStatus: [{}],
          file: null,
          primaryMobileNumber: "",
          studentMobileNumber: "",
          whatsAppNumber: "",
          country: "",
          state: "",
          city: "",
          pincode: "",
          studentEmailId: "",
          addressLine1: "",
          addressLine2: "",
          addressLine3: "",
          addressLine4: "",
          fatherName: "",
          fatherMobileNumber: "",
          fatherEmailId: "",
          motherName: "",
          motherMobileNumber: "",
          motherEmailId: "",
          studentMotherOccupationId :"",
          studentFatherOccupationId:"",
          gstCategory: "",
          companyName: "",
          effectiveDate: "",
          gstin: "",
          bankId: "",
          bankAccountNumber: "",
          ifscCode: "",
          accountHolderName: "",
          updatedOn: moment(new Date()).format("YYYY-MM-DD"),
          updatedBy: "",
          createdBy: "",
          createdOn: moment(new Date()).format("YYYY-MM-DD"),
          district: "",
        });
        setApiLoader(false);
        props.history.push("/admin/studentInformation/" + data.data.studentId);
      },
      (data) => {
        setApiLoader(false);
        failureToast(data["message"]);
      },
      studentData,
      "post"
    );
  };
    return (
    <Card>
      <CardHeader><h2>New Student Information</h2></CardHeader>
      <CardBody>
        {apiLoader ? (
          <div
            className="mx-auto text-center py-5 my-5"
            style={{ height: "100vh" }}
          >
            <Loader
              type="Rings"
              color="#00BFFF"
              height={100}
              width={100}
              // timeout={10000}
            />
          </div>
        ) : (
          <>
            <Accordion
              title={`Student Details`}
              key={"student_details"}
              isFormOpen={true}
            >
              <StudentDetailsForm
                studentLastName={values.studentLastName}
                studentFirstName={values.studentFirstName}
                gender={values.gender}
                schoolStudingIn={values.schoolStudingIn}
                bloodGroup={values.bloodGroup}
                dob={values.dob}
                category={values.category}
                studentIdStatus={values.studentIdStatus}
                imagePath={imagePath}
                genderData={gender}
                bloodGroupData={bloodGrp}
                schoolStudingInData={externalOrganisation}
                categoryData={category}
                studentIdStatusData={studentIdStatus}
                fileHandler={(e) => fileHandler(e)}
                onchange={(e) => onchange(e)}
                setValue={setValues}
                values={values}
                onDropdownChange={(obj, name) => onDropdownChange(obj, name)}
              />
            </Accordion>

            <Accordion
              title={`Contact Details`}
              key={"contact_details"}
              isFormOpen={true}
            >
              <ContactDetailsForm
                primaryMobileNumber={values.primaryMobileNumber}
                studentMobileNumber={values.studentMobileNumber}
                whatsAppNumber={values.whatsAppNumber}
                country={values.country}
                state={values.state}
                city={values.city}
                pincode={values.pincode}
                studentEmailId={values.studentEmailId}
                addressLine1={values.addressLine1}
                addressLine2={values.addressLine2}
                addressLine3={values.addressLine3}
                addressLine4={values.addressLine4}
                countryData={country}
                stateData={stateName}
                cityData={city}
                pincodeData={pincode}
                onchange={(e) => onchange(e)}
                onDropdownChange={(obj, name) => onDropdownChange(obj, name)}
                fetchPincodeById={(data) => fetchPincodeById(data)}
              />
            </Accordion>

            <Accordion
              title={`Other Details`}
              key={"other_details"}
              isFormOpen={true}
            >
              <OtherDetailsForm
                fatherName={values.fatherName}
                fatherMobileNumber={values.fatherMobileNumber}
                fatherEmailId={values.fatherEmailId}
                motherName={values.motherName}
                motherMobileNumber={values.motherMobileNumber}
                motherEmailId={values.motherEmailId}
                motherOccupation={values.studentMotherOccupationId}
                fatherOccupation={values.studentFatherOccupationId}
                parentOccupation={values.parentOccupation}
                parentsOcc={parentsOcc}
                onchange={(e) => onchange(e)}
                onDropdownChange={(obj, name) => onDropdownChange(obj, name)}
              />
            </Accordion>

            {/* <Accordion
              title={`Gst Details`}
              key={"gst_details"}
              isFormOpen={true}
            >
              <GstDetailsForm
                gstCategory={values.gstCategory}
                companyName={values.companyName}
                effectiveDate={values.effectiveDate}
                gstin={values.gstin}
                gstCategoryData={gstCategory}
                onchange={(e) => onchange(e)}
                onDropdownChange={(obj, name) => onDropdownChange(obj, name)}
              />
            </Accordion> */}

            <Accordion
              title={`Bank Details`}
              key={"bank_details"}
              isFormOpen={true}
            >
              <BankDetailsForm
                bankId={values.bankId}
                bankAccountNumber={values.bankAccountNumber}
                ifscCode={values.ifscCode}
                accountHolderName={values.accountHolderName}
                bankIdData={bankData}
                onchange={(e) => onchange(e)}
                onDropdownChange={(obj, name) => onDropdownChange(obj, name)}
              />
            </Accordion>

            <Accordion
              title={`Log Details`}
              key={"log_details"}
              isFormOpen={true}
            >
              <LogDetailsForm
                updatedOn={values.updatedOn}
                updatedBy={values.updatedBy}
                createdBy={values.createdBy}
                createdOn={values.createdOn}
              />
            </Accordion>
            <Button
              className="btn-vimeo btn-icon float-right"
              color="primary"
              type="button"
              onClick={onSubmit}
            >
              <span className="btn-inner--text">Save</span>
            </Button>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default withRouter(CreateNewStudent);
