import { dropdownMapping } from "views/pages/manage/edpOps/constant/common";
import { endpoint } from "views/pages/manage/common/constant";


export const getClassesByAcademicGroupId = async (groupId) => {
  const url = `${endpoint.classes.getClassesByGroupId}/${groupId}`;
  const response = await dropdownMapping(url, 'classDispValue');
  return response
}

export const validateForm = (currentStep, form) => {
  if(currentStep===1 && ( !form?.academicGroup || !form?.classes || !form?.board || !form?.academicYear || !form?.packageId )) return true;
  
  else if(currentStep===2 && ( !form?.academicCareer || !form?.academicPhase || !form?.classType )) return true

  else if(currentStep===3 && ( !form?.regions?.length || !form?.businessAreas?.length )) return true

  return false
}