import React from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";
import AButton from "../../common/form-fields-mui/AButton";
import CustomChip from "../batch-serach/CustomChip";
import AAutoComplete from './../../common/form-fields-mui/AAutoComplete';
const chipContainerStyle = {
  maxHeight: "120px",
  overflowY: "scroll",
  display: "flex",
  flexWrap: "wrap",
  gap: "2px",
  marginTop: "3px",
  //  justifyContent:"space-between"
};
const deleteHandler = (id, ids) => {
  return ids.filter((item) => item.value !== id);
};
const LeftSideFilterForm = ({ filterForm, inputHandler, searchHandler }) => {
  const dropdowns = useSelector((state) => state.studentSearch?.dropdowns);
  
 
  return (
    <div>
      <Card style={{ width: "375px", marginBottom: "0px" }}>
        <CardBody>
          <Row>
            <Col md="12">
              <h4 style = {{color:"black"}}>Branch details</h4>
            </Col>
            <Col md="12">
              <AAutoComplete
                label="Region"
                currentValue={filterForm.region}
                items={dropdowns && dropdowns.regions}
                handleChange={(value) => inputHandler(value,'region')}
              />
              <div style={chipContainerStyle}>
                {(filterForm?.region).map(
                  (item) => (
                    <CustomChip
                      key={item.value}
                      text={item.label}
                      variant="outlined"
                      onDelete={() =>
                        inputHandler(
                          deleteHandler(item.value, filterForm?.region),
                          "region"
                        )
                      }
                    />
                  )
                )}
              </div>
            </Col>
            <Col md={12}>
              <AAutoComplete
                label="Business area"
                currentValue={filterForm.businessArea}
                items={dropdowns && dropdowns.businessAreas}
                handleChange={(value) => inputHandler(value, "businessArea")}
              />
              <div style={chipContainerStyle}>
                {(
                  filterForm?.businessArea
                ).map((item) => (
                  <CustomChip
                    key={item.value}
                    text={item.label}
                    variant="outlined"
                    onDelete={() =>
                      inputHandler(
                        deleteHandler(item.value, filterForm?.businessArea),
                        "businessArea"
                      )
                    }                  />
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mt-2">
              <AButton
                updatedStyle={{
                  width: "90%",
                  color: "#fff",
                  background: "#00B0F5",
                  margin: "0 !important",
                }}

                onClick={searchHandler}
              >
                Submit
              </AButton>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default LeftSideFilterForm;
