import React, { useEffect, useState } from "react";
import APopover from "views/pages/manage/common/a-popover";
import { IssuedMaterialData } from "../selectMeterialAndStock/issueStock/IssuedStockList";
import { fetchTransactionsData, searchStockTransactions } from "../service";
import ALoader from "../../../common/a-loader";
import { transactionTypeMap } from "../stockReturn/constant";

const ViewMaterials = ({ material, isReturned = false, academicType }) => {
  const [rows, setRows] = useState([]);
  // console.log(rows);
  const [loader, setApiLoader] = useState(false);
  const onClickHandler = async (e, fn) => {
    // console.log(fn, material);
    setApiLoader(true);
    fn(e);
    fetchTxnList();
  };
  const fetchTxnList = async () => {
    try {
      const { businessArea, transactionId } = material;
      const response = await fetchTransactionsData(
        businessArea,
        transactionId,
        `&transactionType=${transactionTypeMap.PSID_RETURN[academicType]}`
      );
      setApiLoader(false);
      if (response?.data) setRows(response?.data[0]?.materialDetails);
    } catch (error) {
      setApiLoader(false);
    }
  };

  return (
    <APopover
      buttonComponent={(onClick, styles) => (
        <span
          style={{ color: "#008DC4", cursor: "pointer", marginLeft: "10px" }}
          onClick={(e) => onClickHandler(e, onClick)}
        >
          View Detail
        </span>
      )}
      menuComponent={(closeForm) => (
        <>
          {loader && <ALoader />}
          <IssuedMaterialData isReturned={isReturned} dataRows={rows} />
        </>
      )}
    />
  );
};

export default ViewMaterials;
