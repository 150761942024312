import React, { useEffect } from 'react';
import {
  Card,
  CardHeader,
  Input,
  Container,
  Button,
  Table,
  Row,
} from 'reactstrap';
import { useState } from 'react';
// import "views/pages/manage/addressType/node_modules/react-sliding-pane/dist/react-sliding-pane.css";
import { failureToast, successToast} from '../../common/utils/methods/toasterFunctions/function';
import { MasterHeaderAuto, Rows } from '../../common/commonComponents/masterHeader/masterHeader';
import {
  documentServiceBaseUrl,
  putpost,
  putpost2,
} from 'services/http';
import moment from 'moment';
import { ButtonText } from 'variables/Buttons';
import CustomButton from "components/CustomButton";
import { CustomToggle } from '../../common/formFeilds';
import { PermisionDenied } from "../../common/commonComponents";


var _ = require('lodash');

const DocumentFormatTable = (props) => {
  const {
    serverData,
    setSearch,
    resetSearch,
    isSaveVisible,
    setisSaveVisible,
    action,
    userPermissions
  } = props;
  const [tableDataKey, settableDataKey] = useState('tableDataKey');
  const [updatedKey, setUpdateKey] = useState(new Date());
  const [searchStr, setSearchStr] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: '__new',
        status: 'ACTIVE',
        documentFormatDesc: null,
        documentFormatShortDesc: null,
        documentFormatDispValue: null,
        updatedDate: null,
      },
      ...props.classArray,
    ]);
  };

  const headerList = [
    { name: 'Document Format Key' },
    { name: 'Effective Date' },
    { name: 'Description' },
    { name: 'Display Value' },
    { name: 'Status' },
    { name: 'Action' },
  ];
  const listToRender = [
    'documentFormatKey',
    'effectiveDate',
    'documentFormatDesc',
    'documentFormatDispValue',
    'status',
  ];

  const saveAll = (cb) => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if (el['editMode']) {
        if (!el['documentFormatKey'] || !el['documentFormatKey'].trim()) {
          isValid = false;
          failureToast('Please Enter Document Format Key');
          return;
        }
        else if (!el['documentFormatDesc'] || !el['documentFormatDesc'].trim()) {
          isValid = false;
          failureToast('Please Enter Description');
          return;
        }
        // else if (
        //   !el['documentFormatShortDesc'] ||
        //   !el['documentFormatShortDesc'].trim()
        // ) {
        //   isValid = false;
        //   failureToast('Please Enter Short Description');
        //   return;
        // } 
        else if (
          !el['documentFormatDispValue'] ||
          !el['documentFormatDispValue'].trim()
        ) {
          isValid = false;
          failureToast('Please Enter Display Value');
          return;
        }
        if (!isValid) {
          return;
        }
        if (_tempArray[index]['__status'] == '__new') {
          putpost(
            documentServiceBaseUrl + '/documentFormat/createDocumentFormat',
            (data) => {
              successToast(data['message']);
              setisSaveVisible(false);
              setUpdateKey(new Date());
              props.fetchClasses(`${documentServiceBaseUrl}/documentFormat/getAllDocumentFormats`);
            },
            (data) => {
              failureToast(data['message']);
            },
            _tempArray[index],
            'post'
          );
        } else {
          putpost(
            documentServiceBaseUrl + '/documentFormat/updateDocumentFormat/' + _tempArray[index].id,
            (data) => {
              successToast(data['message']);
              setisSaveVisible(false);
              // delete _tempArray[index]['editMode']
              // props.setclassArray(_tempArray)
              props.fetchClasses(`${documentServiceBaseUrl}/documentFormat/getAllDocumentFormats`);
              settableDataKey(new Date().getMilliseconds());
              // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
            },
            (data) => {
              failureToast(data['message']);
            },
            _tempArray[index],
            'put'
          );
        }
      } else {
      }
    });
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
        // settableDataKey(new Date().getMilliseconds())
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      cb();
    }
  };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]['editMode'] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };
  const deleteRow = (index) => {
    if (props.classArray[index]['__status'] == '__new') {
      let n = props.classArray;
      n.splice(index, 1);
      props.setclassArray(n);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
    } else {
      if (
        window.confirm('Are you sure you want to delete this document format type?')
      ) {
        putpost2(
          documentServiceBaseUrl +
          '/documentFormat/deleteDocumentFormat/' +
          props.classArray[index].id,
          (data) => {
            successToast(data['message']);
            if (props.classArray.length == 1) {

              props.fetchClasses(`${documentServiceBaseUrl}/documentFormat/getAllDocumentFormats`);
            } else {
              var _tempArray = props.classArray;
              if (_tempArray[index]['editMode']) {
                setisSaveVisible(false);
              }
              _tempArray.splice(index, 1);
              props.setclassArray(_tempArray);
            }
            settableDataKey(new Date().getMilliseconds());
            props.fetchClasses(`${documentServiceBaseUrl}/documentFormat/getAllDocumentFormats`);
          },
          (data) => {
            failureToast(data['message']);
          },
          'PUT'
        );
      }
    }
  };

  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
    setUpdateKey(new Date());
  };

  const filterData = (str) => {
    const newData =
      serverData &&
      serverData.filter((obj) =>
        Object.values(obj).some(
          (val) =>
            val && val.toString().toLowerCase().includes(str.toLowerCase())
        )
      );
    setSearch(newData);
  };

  useEffect(() => {
    if (!searchStr || !searchStr.trim()) {
      resetSearch();
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
      filterData(searchStr);
    }
  }, [searchStr]);

  return (
    userPermissions ?
      <>
        <Container className="mt-3" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader className="border-0 d-flex justify-content-between">
                  <h3 className="mb-0 ">
                    Master - Document Format
                  </h3>

                  {/* <Button
                    disabled={isSaveVisible || isDisabled}
                    color="info"
                    size="sm"
                    type="button"
                    className={
                      'floatRight' +
                      (isSaveVisible || isDisabled ? ' btn-dark' : null)
                    }
                    onClick={() => {
                      newRow();
                    }}
                    data-testid="addNewDocumentFormat"
                  >
                    New Document Format
                  </Button> */}
                  <CustomButton
                    disabled={isSaveVisible || isDisabled}
                    className={'floatRight mx-1' + (isSaveVisible ? ' btn-dark cursor-gray' : null)}
                    content={ButtonText.DOCUMENTFORMAT.NEW}
                    permissionType={'C'}
                    icon={true}
                    onClick={() => { newRow(); }}
                    permissionSet={userPermissions}
                  />

                </CardHeader>
                <div id="questionTable" className="table-responsive">
                  <Table className="align-items-center table-flush tableLayout">
                    <MasterHeaderAuto headerList={headerList} permissionSet={userPermissions} isSaveVisible={isSaveVisible}/>
                    <tbody className="list" key={tableDataKey}>
                      {props.classArray
                        ? props.classArray.map((el, index) =>
                          !el['editMode'] ? (
                            //
                            <>
                              <Rows data={el} listToBeRendered={listToRender}>
                                {userPermissions && (userPermissions.includes('D') || userPermissions.includes('U')) && <td className="text-center" >
                                  {/* <Button
                                    color="info"
                                    size="sm"
                                    type="button"
                                    disabled={props.isViewOnly}
                                    onClick={() => editRow(index)}
                                  // data-testid="editButton"
                                  >
                                    <i className="fas fa-edit" />
                                  </Button>
                                  <Button
                                    color="info"
                                    size="sm"
                                    type="button"
                                    disabled={props.isViewOnly}
                                    onClick={() => deleteRow(index)}
                                  // data-testid="deletebtn"
                                  >
                                    <i className="fas fa-trash" />
                                  </Button> */}
                                  <CustomButton
                                    // content={ButtonText.DOCUMENTTYPE.UPDATE}
                                    permissionType={'U'}
                                    icon={true}
                                    forTable={true}
                                    onClick={() => editRow(index)}
                                    disabled={props.isViewOnly}
                                    permissionSet={userPermissions}
                                  />
                                  <CustomButton
                                    // content={ButtonText.DOCUMENTTYPE.DELETE}
                                    permissionType={'D'}
                                    icon={true}
                                    forTable={true}
                                    onClick={() => deleteRow(index)}
                                    disabled={props.isViewOnly}
                                    permissionSet={userPermissions}
                                  />
                                </td>}
                              </Rows>
                            </>
                          ) : (
                            <tr key={index + '-class'}>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength="50"
                                  data-testid='documentFormatKey'
                                  placeholder="Document Format Key"
                                  className="tdInput"
                                  defaultValue={el['documentFormatKey']}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      'documentFormatKey',
                                      true
                                    );
                                  }}
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  defaultValue={moment(el['effectiveDate']).format('DD-MM-YYYY')}
                                  disabled
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength="50"
                                  defaultValue={el['documentFormatDesc']}
                                  data-testid='documentFormatDesc'
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      'documentFormatDesc',
                                      true
                                    );
                                  }}
                                  placeholder="Description"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength="50"
                                  defaultValue={el['documentFormatDispValue']}
                                  data-testid='documentFormatDispValue'
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      'documentFormatDispValue',
                                      true
                                    );
                                  }}
                                  placeholder="Display value"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <label className="custom-toggle mx-auto ml-2">
                                  {/* <input
                                    checked={
                                      el.status == 'ACTIVE' ? true : false
                                    }
                                    type="checkbox"
                                    id={el.id}
                                    onChange={(e) => {
                                      settableDataKey(
                                        new Date().getMilliseconds()
                                      );
                                      console.log(e.target.checked);
                                      let n = 'ACTIVE';
                                      if (!e.target.checked) {
                                        n = 'INACTIVE';
                                      }
                                      updateKey(index, n, 'status');
                                    }}
                                    data-testid='documentFormatStatus'
                                  />
                                  <span
                                    style={{ width: '72px' }}
                                    className="custom-toggle-slider rounded-circle activeToggle"
                                    data-label-off="Inactive"
                                    data-label-on="Active"
                                  /> */}
                                    <CustomToggle
                                      status={el.status}
                                      data-testid="'documentFormatStatus'"
                                      id={el.id}
                                      toggleOn={"Active"}
                                      toggleOff={"Inactive"}
                                      onChangeHandler={(e) => {
                                        settableDataKey(
                                          new Date().getMilliseconds()
                                        );
                                        let n = 'ACTIVE';
                                        if (!e.target.checked) {
                                          n = 'INACTIVE';
                                        }
                                        updateKey(index, n, 'status');
                                      }}
                                    />
                                </label>
                              </td>
                              <td className="text-center" data-testid="saveConcessionDocumentFormat">
                                {isSaveVisible ? (
                                  <>

                                    {/* <Button
                                      color="info"
                                      size="sm"
                                      type="button"
                                      className="mx-1"
                                      onClick={() => {
                                        saveAll();
                                      }}
                                    >
                                      Save
                                    </Button>
                                    <Button
                                      color="danger"
                                      size="sm"
                                      type="button"
                                      onClick={() => {
                                        let n = props.classArray;
                                        if (n[index]['__status'] == '__new') {
                                          n.splice(index, 1);
                                        } else {
                                          // n[index]['editMode'] = false
                                          props.fetchClasses(
                                            `${documentServiceBaseUrl}/documentFormat/getAllDocumentFormats`
                                          );
                                        }
                                        props.setclassArray(n);
                                        setisSaveVisible(false);
                                      }}
                                    >

                                      <i className="fas fa-times" />
                                    </Button> */}
                                    <CustomButton
                                      content={ButtonText.DOCUMENTFORMAT.UPDATE}
                                      forTable={true}
                                      permissionType={'C'}
                                      icon={false}
                                      onClick={() => saveAll()}
                                      permissionSet={userPermissions}
                                    />

                                    <CustomButton
                                      forTable={true}
                                      permissionType={'cancel'}
                                      icon={true}
                                      onClick={() => {
                                        let n = props.classArray;
                                        if (n[index]['__status'] == '__new') {
                                          n.splice(index, 1);
                                        } else {
                                          // n[index]['editMode'] = false
                                          props.fetchClasses(
                                            `${documentServiceBaseUrl}/documentFormat/getAllDocumentFormats`
                                          );
                                        }
                                        props.setclassArray(n);
                                        setisSaveVisible(false);
                                      }}
                                      permissionSet={userPermissions}
                                    />
                                  </>
                                ) : (
                                  // <Button
                                  //   color="info"
                                  //   size="sm"
                                  //   type="button"
                                  //   onClick={() => {
                                  //     deleteRow(index);
                                  //   }}
                                  // >

                                  //   <i className="fas fa-trash" />
                                  // </Button>
                                  <CustomButton
                                    content={''}
                                    permissionType={'D'}
                                    icon={true}
                                    forTable={true}
                                    onClick={() => deleteRow(index)}
                                    permissionSet={userPermissions}
                                  />
                                )}
                              </td>
                            </tr>
                          )
                        )
                        : null}
                    </tbody>
                  </Table>
                </div>
                {action === 'edit' ? (
                  <Button
                    className={'floatRight ml-auto m-3'}
                    color="primary"
                    size="md"
                    type="button"
                    onClick={() => {
                      setIsDisabled(!isDisabled);
                    }}
                  >
                    {isDisabled ? 'Enable Form' : 'Disabled Form'}
                  </Button>
                ) : null}
              </Card>
            </div>
          </Row>
        </Container>
      </> : <PermisionDenied />
  );
};
export default DocumentFormatTable;
