export const dummyDropDown = [
    { label: 'exam registration', value: 'exam registration' },
    { label: 'Misc. Charges', value: 'Misc. Charges' },
    { label: 'security', value: 'security' },
  ]
  export const dummyDropDown2 = [
    { label: '743516', value: '743516' },
    { label: '812341', value: '812341' },
    { label: '567430', value: '567430' },
  ]
 
 export const options = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
  ];
  export const selectStyles = {
  
    borderRadius: '12px',
    height: '30px',
    color: '#B0B0B0',
    fontSize: '14px',
    fontFamily: 'Inter',
    lineHeight: '20px',
    
  }