import React, {useState} from 'react';
import {Button, Card,  CardHeader, Col} from "reactstrap";
import CustomDragAndDrop from "../../../../common/commonComponents/DragAndDrop";

const DocumentTable =(props)=>{
    const {setFile,progressBarStatus,handleFile,showModel,isUploading,chkFile} = props;
    const [isVisible,setIsVisible] = useState(false);
    return (
        <Card  className="card-plain" style={{margin:'20px 15px'}}>
            <CardHeader className="d-flex justify-content-between">
                <h3 className="mb-0 ">Related Document</h3>
                <Button
                    // color={ "info"}
                    color={isVisible  ? "secondary" : "info"}
                    size="sm"
                    type="button"
                    className={"floatRight"}
                    disabled={isVisible}
                    onClick={() => setIsVisible(true)}
                >
                    Add
                </Button>
            </CardHeader>
                {
                    isVisible
                        ?
                        <div className="form-row pl-4 pr-4 pb-4 pt-4 flex-column">
                            <Col className="mb-3" xs="12">
                                <label className="form-control-label">{`Document`}</label>
                                <CustomDragAndDrop
                                    setFile={setFile}
                                    progressBarStatus={progressBarStatus}
                                    handleFile={handleFile}
                                    isUploading={isUploading}
                                    changeIconColor={showModel}
                                />
                            </Col>
                            <Col className="d-flex justify-content-end align-items-center " xs="12">
                                <Button
                                    color="info"
                                    size="sm"
                                    type="button"
                                    // className={"floatRight"}
                                    onClick={chkFile}
                                >
                                    Submit
                                </Button>
                                <Button
                                    color="info"
                                    size="sm"
                                    type="button"
                                    //className={"floatRight"}
                                    onClick={() => {
                                        // setFile(null);
                                        setIsVisible(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </div>
                        :
                        null
                }
        </Card>
    )
}
export default DocumentTable;
