import React from 'react'
import { ReactComponent as NoBatchWarning } from '../../../../../../assets/img/svg/NoBatchWarning.svg'

const cardStyles = {
  borderRadius: '8px',
  backgroundColor: '#FFF6E3',
  color: '#262626',
  alignItems: 'center',
  justifyContent: 'center',
  height: '142px'
}

const NoBatchesCard = ({message}) => {
  return (
    <div className='d-flex flex-column' style={cardStyles}>
      <NoBatchWarning />
      <span>{message}</span>
    </div>
  )
}

export default NoBatchesCard