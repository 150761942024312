import React, { useContext, useMemo, useState } from 'react';
import styles from './homepage.module.scss';
import Favorites from './favourites/Favorites';
import HomePageCountCards from 'views/pages/manage/homepage/countCards/HomePageCountCads';
import Notifications from 'views/pages/manage/homepage/notifications/Notifications';
import useFetchBusinessAreas from './customHooks/useFetchBusinessAreas';
import { PermissionContext } from 'appContext';
import { getScreenIds } from './constant';
import ALoader from '../common/a-loader';
// this is new Homepage
// once complete dev is done old index.js file shall be deleted and this file sholud be served from custom routes

const Homepage = () => {
  const [businessAreas, fetchingBusinessAreas] = useFetchBusinessAreas();
  const screenIds = getScreenIds(useContext(PermissionContext));

  return (
    <div className='remove-container-top-space min-page-height d-flex'>
      {fetchingBusinessAreas && <ALoader position={'fixed'} />}
      <div className={`${styles?.homepageContainer}`}>
        <Favorites />
        {businessAreas?.length<=5 && <HomePageCountCards businessAreas={businessAreas} fetchingBusinessAreas={fetchingBusinessAreas} screenIds={screenIds} />}
      </div>
      <Notifications businessAreas={businessAreas} fetchingBusinessAreas={fetchingBusinessAreas} screenIds={screenIds} />
    </div>
  );
};

export default Homepage;
