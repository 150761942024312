import React from "react";
import { useHistory } from "react-router";
import {
  DigitalModuleProvider,
  useGetDigitalModuleContext,
} from "../../contextApi/digitalContext";
import {
  dispatchedTableColumn,
  pendingDispatchTableColumn,
  onHoldTableColumn,
} from "../../dlpStockIssuance/issueStock/helper";

import PageHeader from "./PageHeader";
import DispatchPendingTable from "./DispatchPendingTable";

const RenderedTable = () => {
  const { selectedTab } = useGetDigitalModuleContext();

  return selectedTab === "DISPATCH_PENDING" ? (
    <DispatchPendingTable
      tableHeader={pendingDispatchTableColumn}
      selectedTab={selectedTab}
    />
  ) : selectedTab === "DISPATCHED" ? (
    <DispatchPendingTable
      tableHeader={dispatchedTableColumn}
      selectedTab={selectedTab}
    />
  ) : (
    <DispatchPendingTable
      tableHeader={onHoldTableColumn}
      selectedTab={selectedTab}
    />
  );
};

const IssueStockDispatchAndHold = () => {
  const history = useHistory();

  return (
    <DigitalModuleProvider>
      <PageHeader goBackHandler={() => history.goBack()} />
      <RenderedTable />
    </DigitalModuleProvider>
  );
};

export default IssueStockDispatchAndHold;
