import { RolePermissions } from 'appContext'
import { PermissionContext } from 'appContext'
import React, { useContext, useEffect, useState } from 'react'
import { BiArrowBack } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Card, CardBody } from 'reactstrap'
import { deallocateStudentsDownloadCsv } from 'redux/deallocateStudents/deallocate-students-action'
import { getStudentList, getBatchDetailsById, fetchDropdownsAction } from 'redux/deallocateStudents/deallocate-students-action'
import ALoader from '../../common/a-loader'
import { PermisionDenied } from '../../common/commonComponents'
import { endpoint, pages } from '../../common/constant'
import { fetchAllPostPromisedData, removeKeysFromObject } from '../../common/utils/methods/commonMethods/utilityMethod'
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function'
import { BACK_ICON_STYLE } from '../constant/common'
import { getFilterCount, getFilterPayload } from '../constant/deallocate-students'
import DeallocateStudentSearchFilter from './DeallocateStudentSearchFilter'
import StudentList from './StudentList'
import { ReactComponent as BackIcon } from "../../../../../assets/img/svg/BackButton.svg"
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper'

const DeallocateStudents = (props) => {

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages['searchBatch']['id']);

  const deallocateStudentsState = useSelector(state => state.deallocateStudents);
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = props.match.params;
  const [page, setPage] = useState(0);
  const [payloadData, setPayloadData] = useState(getFilterPayload(id));
  const [formData, setFormData] = useState(getFilterPayload(id));

  const [filterCount, setFilterCount] = useState(0);
  const [deallocateLoader, setDeallocateLoader] = useState(false);

  const onPageChange = (currentPage) => {
    setPage(currentPage);
    dispatch(getStudentList(payloadData, currentPage));
  }

  const inputHandler = (value, inputKey) => {
    setFormData(prev => ({ ...prev, [inputKey]: value }));
  }

  const applyFilterHandler = () => {
    setPage(0);
    setFilterCount(getFilterCount(formData, deallocateStudentsState.batchDetails.batchType));
    const updatedPayload = { ...payloadData, ...formData }
    setPayloadData(updatedPayload);
    dispatch(getStudentList(updatedPayload));
  }

  const removeFilter = () => {
    setPage(0);
    setFilterCount(0);
    const updatedPayload = { ...payloadData, ...getFilterPayload(id) };
    setFormData(getFilterPayload(id));
    setPayloadData(updatedPayload);
    dispatch(getStudentList(updatedPayload));
  }

  const applyNameFilterHandler = (inputForm) => {
    setPage(0);
    const updatedPayload = { ...removeKeysFromObject(payloadData, ['psid', 'studentName', 'applicationId']), ...inputForm }
    setPayloadData(updatedPayload);
    dispatch(getStudentList(updatedPayload));
  }

  const removeNameFilterHandler = () => {
    setPage(0);
    const updatedPayload = { ...removeKeysFromObject(payloadData, ['psid', 'applicationId', 'studentName']) };
    setPayloadData(updatedPayload);
    dispatch(getStudentList(updatedPayload))
  }

  const deallocateStudentHandler = async (applicationIds) => {
    setDeallocateLoader(true);
    const reqBody = {
      applicationIds: applicationIds,
      batchId: id,
      action: 'DEALLOCATE'
    }

    const response = await fetchAllPostPromisedData(endpoint.allocateStudents.allocateDeallocate, reqBody, 'post');

    if (response?.code === 200) {
      if (response?.data?.length) {
        failureToast(response.message);
      }
      else successToast(response.message);
      setDeallocateLoader(false);
      setPage(0);
      dispatch(getBatchDetailsById(id));
      dispatch(getStudentList(payloadData));
    } else {
      setDeallocateLoader(false);
    }
  }

  useEffect(() => {
    dispatch(fetchDropdownsAction());
    dispatch(getBatchDetailsById(id));
    dispatch(getStudentList(payloadData));
  }, []);

  const downloadCsvHandler = () => {
    dispatch(deallocateStudentsDownloadCsv(payloadData))
  }


  return (
    userPermissions ? (
      <div style={{ position: 'relative', display: 'flex', flexFlow: 'column', height: 'calc(100vh - 120px)' }}>
        {(deallocateStudentsState.batchDetailsLoading || deallocateStudentsState.studentListLoading || deallocateLoader) && <ALoader />}
        <h3 className='mx-3 mb-2' style={{ fontSize: '16px' }}>{deallocateStudentsState.batchDetails.batchName} / Students</h3>
        <Card className='mx-3 mb-2' style={{ flexGrow: 1 }}>
          <CardBody style={{ display: 'flex', flexFlow: 'column' }}>
            <div className='d-flex justify-content-between align-items-start'>
              <div className='d-flex align-items-center'>
                <div className='mr-2'><IconButtonWrapper onClick={() => history.goBack()}><BackIcon /></IconButtonWrapper></div>
                <div style={{ fontSize: '20px', fontWeight: 700, color: '#45464E' }}>{deallocateStudentsState.studentListRowCount} Students</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", position: "relative" }}>
                <DeallocateStudentSearchFilter
                  batchDetails={deallocateStudentsState.batchDetails}
                  data={deallocateStudentsState.studentList}
                  inputHandler={inputHandler}
                  applyFilterHandler={applyFilterHandler}
                  removeFilter={removeFilter}
                  form={formData}
                  filterCount={filterCount}
                  downloadCsvHandler={downloadCsvHandler}
                  applyNameFilterHandler={applyNameFilterHandler}
                  removeNameFilterHandler={removeNameFilterHandler}
                />
              </div>
            </div>
            <StudentList
              rowsData={deallocateStudentsState.studentList || []}
              rowCount={deallocateStudentsState?.studentListRowCount}
              page={page}
              onPageChange={onPageChange}
              deallocateStudentHandler={deallocateStudentHandler}
            // availableForSelection={deallocateStudentsState?.batchDetails?.batchCapacity - deallocateStudentsState?.batchDetails?.currentStrength}
              batchName={deallocateStudentsState?.batchDetails?.batchName}
            />
          </CardBody>
        </Card>
      </div>
    ) : <PermisionDenied />
  )
}

export default DeallocateStudents