import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import { masterServiceBaseUrl, failureToast, getAPI } from "services/http";
import FranchiseeForm from "./franchisee";
import { useParams } from "react-router-dom";
const Franchisee = (props) => {
  const {
    businessUnit,
    setFranchisesSetupId,
    nextStep = () => {},
    franchiseeSetupData,
    franchiseeAddressData,
    previousStep = () => {},
    action,
    data,
    formId,
    isNew,
    isPrevious,
    appendAllStepData = () => {},
    updateAllStepData = () => {},
  } = props;

  const [country, setCountry] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [stateName, setStateName] = useState([]);
  const [city, setCity] = useState([]);
  const [district, setDistrict] = useState([]);
  const [addressCategory, setAddressCategory] = useState([]);
  const [savedFranchisee, setSavedFranchisee] = useState(null);
  const [apiloader, setapiloader] = useState(true);
  const [businessType, setBusinessType] = useState([]);
  const statusData = [{ label: "ACTIVE", value: "ACTIVE" }]; //,{label:'INACTIVE',value:'INACTIVE'}

  const fetchData = (url, stateMethod, name) => {
    getAPI(
      url,
      (data) => {
        //console.log("Drop down value",data)
        let list = [];
        if (typeof name !== "undefined" && name !== "") {
          //this is for dropdown fields only
          let keyName = `${name}DispValue`;
          if (name === "addressCategoryType") {
            keyName = name;
          }
          list = data.data.map((data) => {
            return {
              value: data.id,
              label: data[keyName],
            };
          });
          stateMethod(list);
          //console.log(list);
        }
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  };

  const getSavedAddress = () => {
    getAPI(
      `${masterServiceBaseUrl}/franchisee/getFranchiseeAndItsAddressByBusinessArea/${formId}`,
      (data) => {
        const franData = data.data
        const address = data.data.addressMasterList && data.data.addressMasterList[0]
        franData['addressMasterList'] = null
        setSavedFranchisee({
          franData : franData,
          address : address
        });
        setapiloader(false);
      },
      (data) => {
        failureToast(data["message"]);
        setapiloader(false);
      }
    );
  };

  useEffect(() => {
    fetchData(masterServiceBaseUrl + "/country/getAllCountry", setCountry, "country");
    fetchData(masterServiceBaseUrl + "/state/getAllState", setStateName, "state");
    fetchData(masterServiceBaseUrl + "/city/getAllCity", setCity, "city");
    fetchData(
      masterServiceBaseUrl + "/district/getAllActiveDistrict",
      setDistrict,
      "district"
    );
    fetchData(masterServiceBaseUrl + "/pincode/getAllPincode", setPincode, "pincode");
    fetchData(
      masterServiceBaseUrl + "/addressCategory/getAllActiveAddressCategory",
      setAddressCategory,
      "addressCategoryType"
    );
    fetchData(
      masterServiceBaseUrl + "/businessType/getAllBusinessType",
      setBusinessType,
      "businessType"
    );
    if (isPrevious) {
      getSavedAddress();
    }
    !isPrevious &&  setapiloader(false);
  }, [businessUnit, formId,isPrevious]);

  const appendData = (data) => {
    props.saveStepsCompleted(4);
  };

  const updateData = (data) => {
    updateAllStepData(data, "forthFom");
  };

  return (
    <>
      {apiloader ? (
        <div className="mx-auto text-center py-5 my-5">
          <Loader
            type="Rings"
            color="#00BFFF"
            height={70}
            width={70}
            timeout={10000}
          />
        </div>
      ) : (
        <>
          <FranchiseeForm
            countryData={country}
            stateData={stateName}
            pincodeData={pincode}
            districtData={district}
            cityData={city}
            statusData={statusData}
            addressCategoryData={addressCategory}
            bussinessUnitId={
              action === "view" || action === "edit"
                ? franchiseeSetupData["businessAreaId"]
                : businessUnit
            }
            businessTypeData={businessType}
            savedFranchisee={savedFranchisee}
            nextStep={nextStep}
            previousStep={previousStep}
            setFranchisesSetupId={setFranchisesSetupId}
            franchiseeAddressData={franchiseeAddressData}
            franchiseeSetupData={franchiseeSetupData}
            action={action}
            data={savedFranchisee}
            isPrevious={isPrevious}
            appendData={appendData}
            updateData={updateData}
            isNew={isNew}
          />
        </>
      )}
    </>
  );
};

export default Franchisee;
