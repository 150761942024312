import React, { useEffect } from 'react';
import {
	Card,
	CardHeader,
	Input,
	Container,
	Button,
	Table,
	Row,
} from 'reactstrap';
import { useState } from 'react';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import {
	TDROW,
	MasterHeader,
} from '../../common/commonComponents/masterRow/masterRow';
import {
	documentServiceBaseUrl,
	putpost,
	putpost2,
} from 'services/http';
import moment from 'moment';
import CommonInput from '../../common/formFeilds/input/commonInput';
import CustomButton from 'components/CustomButton';
import { CustomToggle } from '../../common/formFeilds';

var _ = require('lodash');

const DocumentSourceTable = (props) => {
	const { userPermissions } = props;
	const [isSaveVisible, setisSaveVisible] = useState(false);
	const [tableDataKey, settableDataKey] = useState('tableDataKey');
	const [documentSourceKey, setDocumentSourceKey] = useState('');
	const [desc, setDesc] = useState('');
	const [dispValue, setDispValue] = useState('');
	const addNewClass = () => {
		setDispValue('');
		setDesc('');
		setDocumentSourceKey('');
		props.setclassArray([
			{
				editMode: true,
				__status: '__new',
				documentSourceKey: null,
				status: 'ACTIVE',
				documentSourceDesc: null,
				documentSourceDispValue: null,
				effectiveDate: null,
			},
			...props.classArray,
		]);
	};
	const saveAll = (cb) => {
		let pattern_1 = /^[a-zA-z0-9 .-\s]*$/;
		var _tempArray = props.classArray;
		var isValid = true;
		_tempArray.forEach((el, index) => {
			if (el['editMode']) {
				if (documentSourceKey === '' || documentSourceKey.trim() === '') {
					isValid = false;
					failureToast('Please enter the value of Document Source Key');
					return;
				} else if (desc === '' || desc.trim() === '') {
					isValid = false;
					failureToast('Please enter the value of Description');
					return;
				} else if (dispValue === '' || dispValue.trim() === '') {
					isValid = false;
					failureToast('Please enter the value of Display Value');
					return;
				} else if (!pattern_1.test(el['documentSourceDispValue'])) {
					isValid = false;
					failureToast(
						'Display Value Contains Alpha-numeric with these special character . , -  are only allowed.'
					);
					return;
				}
				if (!isValid) {
					return;
				}

				_tempArray[index]['documentSourceDesc'] = desc
					.replace(/\s+/g, ' ')
					.trim();
				_tempArray[index]['documentSourceDispValue'] = dispValue
					.replace(/\s+/g, ' ')
					.trim();
				_tempArray[index]['documentSourceKey'] = documentSourceKey;
				if (_tempArray[index]['__status'] == '__new') {
					putpost(
						documentServiceBaseUrl + '/DocumentSource/createDocumentSource',
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							props.fetchClasses(
								documentServiceBaseUrl + '/DocumentSource/getAllDocumentSource'
							);
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'post'
					);
				} else {
					putpost(
						documentServiceBaseUrl +
							'/DocumentSource/updateDocumentSource/' +
							_tempArray[index].id,
						(data) => {
							successToast(data['message']);
							props.fetchClasses(
								documentServiceBaseUrl + '/DocumentSource/getAllDocumentSource'
							);
							setisSaveVisible(false);
							delete _tempArray[index]['editMode'];
							props.setclassArray(_tempArray);
							settableDataKey(new Date().getMilliseconds());
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'put'
					);
				}
			}
		});
	};
	const newRow = () => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				addNewClass();
				setisSaveVisible(true);
				// settableDataKey(new Date().getMilliseconds())
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			props.setclassArray(_tempArray);
			setisSaveVisible(false);
			cb();
		}
	};
	const editRow = (index) => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				var _tempArray = props.classArray;

				setDesc(_tempArray[index]['documentSourceDesc']);
				setDispValue(_tempArray[index]['documentSourceDispValue']);
				setDocumentSourceKey(_tempArray[index]['documentSourceKey']);
				_tempArray[index]['editMode'] = true;
				props.setclassArray(_tempArray);
				setisSaveVisible(true);
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			cb();
		}
	};
	const deleteRow = (index) => {
		if (props.classArray[index]['__status'] == '__new') {
			let n = props.classArray;
			n.splice(index, 1);
			props.setclassArray(n);
			setisSaveVisible(false);
			settableDataKey(new Date().getMilliseconds());
		} else {
			if (
				window.confirm(
					'Are you sure you want to delete this Document Source Detail?'
				)
			) {
				putpost2(
					documentServiceBaseUrl +
						'/DocumentSource/deleteDocumentSource/' +
						props.classArray[index].id,
					(data) => {
						successToast(data['message']);
						if (props.classArray.length == 1) {
							props.previousPage();
							props.fetchClasses(
								documentServiceBaseUrl + '/DocumentSource/getAllDocumentSource'
							);
						} else {
							var _tempArray = props.classArray;
							if (_tempArray[index]['editMode']) {
								setisSaveVisible(false);
							}
							_tempArray.splice(index, 1);
							props.setclassArray(_tempArray);
						}
						settableDataKey(new Date().getMilliseconds());
					},
					(data) => {
						failureToast(data['message']);
					},
					'Delete'
				);
			}
		}
	};
	const updateKey = (index, value, key) => {
		var _tempArray = props.classArray;
		_tempArray[index][key] = value;
		props.setclassArray(_tempArray);
	};
	return (
		<>
			<Container className="mt-3" fluid>
				<Row>
					<div className="col">
						<Card>
							<CardHeader className="border-0">
								<h3 className="mb-0 floatLeft">Master - Document Source</h3>
								{/* <Button
									data-testid="addNewRow"
									disabled={isSaveVisible}
									color="info"
									size="sm"
									type="button"
									className={
										'floatRight mx-1' + (isSaveVisible ? ' btn-dark' : null)
									}
									onClick={() => {
										newRow();
									}}
								>
									Add New Document Source
								</Button> */}
								<CustomButton
									data-testid="addNewRow"
									disabled={isSaveVisible}
									className={
										'floatRight mx-1' + (isSaveVisible ? ' btn-dark' : null)
									}
									content={'Add New Document Source'}
									icon={true}
									permissionType={'C'}
									permissionSet={userPermissions}
									onClick={() => {
										newRow();
									}}
								/>
							</CardHeader>
							<div id="questionTable" className="table-responsive">
								<Table className="align-items-center table-flush tableLayout">
									<MasterHeader type={'Document Source'} isRequired={true} permissionSet={userPermissions} isSaveVisible={isSaveVisible} />
									<tbody className="list" key={tableDataKey}>
										{props.classArray
											? props.classArray.map((el, index) =>
													!el['editMode'] ? (
														<TDROW
															type={'documentSource'}
															index={index}
															el={el}
															editRow={editRow}
															deleteRow={deleteRow}
															permissionSet={userPermissions}
														/>
													) : (
														<tr key={index + '-class'}>
															<td className="text-center p-2 mx-1">
																<CommonInput
																	forTable={true}
																	type={'text'}
																	placeHolder={'Document Source'}
																	defaultValue={documentSourceKey}
																	isDisabled={false}
																	maxLength={2}
																	restrictSpecialCharacter={true}
																	onChange={(value) => {
																		setDocumentSourceKey(value.toUpperCase());
																	}}
																/>
															</td>
															<td className="text-center p-2 mx-1">
																<Input
																	data-testid="effectiveDate"
																	disabled={true}
																	defaultValue={
																		el['__status'] == '__new'
																			? null
																			: moment(el['effectiveDate']).format(
																					'DD-MM-YYYY'
																			  )
																	}
																	placeholder="Example:01-01-2020"
																	className="tdInput"
																/>
															</td>
															<td className="text-center p-2 mx-1">
																<CommonInput
																	forTable={true}
																	type={'text'}
																	placeHolder={'Description'}
																	defaultValue={desc}
																	isDisabled={false}
																	maxLength={50}
																	restrictSpecialCharacter={true}
																	onChange={(value) => {
																		setDesc(value);
																	}}
																/>
															</td>
															<td className="text-center p-2 mx-1">
																<CommonInput
																	forTable={true}
																	type={'text'}
																	placeHolder={'Display Value'}
																	defaultValue={dispValue}
																	isDisabled={false}
																	regEx={/[^0-9a-zA-Z .-]/g}
																	maxLength={20}
																	allowedSomeSpecialCharacters={true}
																	restrictSpecialCharacter={true}
																	onChange={(value) => {
																		setDispValue(value.toUpperCase());
																	}}
																/>
															</td>
															<td className="text-center p-2 mx-1">
																<label className="custom-toggle mx-auto ml-2">
																	{/* <input
																		data-testid="activeInactiveInput"
																		checked={
																			el.status == 'ACTIVE' ? true : false
																		}
																		type="checkbox"
																		id={el.id}
																		onChange={(e) => {
																			settableDataKey(
																				new Date().getMilliseconds()
																			);
																			let n = 'ACTIVE';
																			if (!e.target.checked) {
																				n = 'INACTIVE';
																			}
																			updateKey(index, n, 'status');
																		}}
																	/> */}
																	{/* <span
																		data-testid="activeInactiveLabel"
																		style={{ width: '72px' }}
																		className="custom-toggle-slider rounded-circle activeToggle"
																		data-label-off="Inactive"
																		data-label-on="Active"
																	/> */}
																	<CustomToggle
																		status={el.status}
																		data-testid="activeInactiveInput"
																		id={el.id}
																		toggleOn={"Active"}
																		toggleOff={"Inactive"}
																		onChangeHandler={(e) => {
																			settableDataKey(new Date().getMilliseconds());
																			let n = "ACTIVE";
																			if (!e.target.checked) { n = "INACTIVE"; }
																			updateKey(index, n, "status");
																		}}
																	/>
																</label>
															</td>
															<td className="text-center">
																{isSaveVisible ? (
																	<>
																		{/* <Button
																			data-testid="saveBtn"
																			color="info"
																			size="sm"
																			type="button"
																			className="mx-1"
																			onClick={() => {
																				saveAll();
																			}}
																		>
																			Save
																		</Button> */}
																		<CustomButton
																			className="mx-1"
																			data-testid="saveBtn"
																			content={'Save'}
																			permissionType={'C,U'}
																			forTable={true}
																			permissionSet={userPermissions}
																			onClick={() => {
																				saveAll();
																			}}
																		/>
																		{/* <Button
																			data-testid="closeBtn"
																			color="danger"
																			size="sm"
																			type="button"
																			onClick={() => {
																				let n = props.classArray;
																				if (n[index]['__status'] == '__new') {
																					n.splice(index, 1);
																				} else {
																					// n[index]['editMode']=false
																					props.fetchClasses(
																						documentServiceBaseUrl +
																							'/DocumentSource/getAllDocumentSource'
																					);
																				}
																				props.setclassArray(n);
																				setisSaveVisible(false);
																			}}
																		>
																			<i className="fas fa-times" />
																		</Button>{' '} */}
																		<CustomButton
																			permissionType={'cancel'}
																			icon={true}
																			forTable={true}
																			permissionSet={userPermissions}
																			onClick={() => {
																				let n = props.classArray;
																				if (n[index]['__status'] == '__new') {
																					n.splice(index, 1);
																				} else {
																					// n[index]['editMode']=false
																					props.fetchClasses(
																						documentServiceBaseUrl +
																							'/DocumentSource/getAllDocumentSource'
																					);
																				}
																				props.setclassArray(n);
																				setisSaveVisible(false);
																			}}
																		/>
																	</>
																) : (
																	// <Button
																	// 	color="info"
																	// 	size="sm"
																	// 	type="button"
																	// 	onClick={() => {
																	// 		deleteRow(index);
																	// 	}}
																	// >
																	// 	<i className="fas fa-trash" />
																	// </Button>
																	<CustomButton
																		permissionType={'D'}
																		icon={true}
																		forTable={true}
																		permissionSet={userPermissions}
																		onClick={() => deleteRow(index)}
																	/>
																)}
															</td>
														</tr>
													)
											  )
											: null}
									</tbody>
								</Table>
							</div>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};
export default DocumentSourceTable;
