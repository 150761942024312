import React, { useState } from 'react'
import { Checkbox, Dialog, FormControlLabel } from '@mui/material'
import { ReactComponent as CrossIcon } from 'assets/img/svg/CrossIcon.svg'
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";
import ALoader from "views/pages/manage/common/a-loader";
import { primaryCheckboxStyles } from '../../issuanceIDcard/printIdCardAndMapRfid/constant';
import AButton from '../../../common/form-fields-mui/AButton';
import { applicationSearchInitialForm, inputMap, inputStyles, selectStyles, useApplicationSearchInitialForm, validateApplicationSearchForm } from './constant';
import AInput from '../../../common/formFeilds/customInput/AInput';
import SearchByNameForm from './SearchByNameForm';
import ApplicationTableDialog from './ApplicationTableDialog';
import { fetchAllPostPromisedData, updateRequest } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../../common/constant';
import { failureToast } from '../../../common/utils/methods/toasterFunctions/function';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'


const StockReturnApplicationSearchDialog = ({ open, setOpen, dropdown, academicCareer = 'RCC' }) => {

  const handleClose = () => {
    setOpen(false);
    setForm(applicationSearchInitialForm(academicCareer, dropdown));
    setCheckedOption('psid');
  }
  const [isTableDialogOpen, setIsTableDialogOpen] = useState(false);

  const [checkedOption, setCheckedOption] = useState('psid'); // psid, applicationId, studentName, transactionId
  // const [form, setForm] = useState(applicationSearchInitialForm(academicCareer, dropdown));
  const [form, setForm] = useApplicationSearchInitialForm(academicCareer, dropdown);
  const [inputErrFlag, setInputErrFlag] = useState(false);
  const [listLoader, setListLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const handleCheckboxChange = option => {
    setInputErrFlag(false);
    setCheckedOption(option);
    setForm(applicationSearchInitialForm(academicCareer, dropdown)); // reset the form
  }
  const formHandler = (value, key) => setForm(prev => ({ ...prev, [key]: value }));

  const checkboxOptions = [
    { title: 'Search by PSID', onChange: (e) => { e.target.checked && handleCheckboxChange('psid') }, checked: checkedOption === 'psid' },
    { title: 'Search by Application ID', onChange: (e) => { e.target.checked && handleCheckboxChange('applicationId') }, checked: checkedOption === 'applicationId' },
    { title: 'Search by Name', onChange: (e) => { e.target.checked && handleCheckboxChange('studentName') }, checked: checkedOption === 'studentName' },
    { title: 'Search by Transaction ID', onChange: (e) => { e.target.checked && handleCheckboxChange('transactionId') }, checked: checkedOption === 'transactionId' },
  ]

  const fetchList = async (page = 0) => {
    setListLoader(true);
    
    // const reqBody = { ...form, groupBy: checkedOption==='transactionId' ? null : 'Application' }
    const res = await fetchAllPostPromisedData(`${endpoint.studentSearch.searchStockTransactions}?offset=${page}`, updateRequest(form), 'post');

    if (res?.code === 200) {
      setRowCount(res?.data?.totalRecords);
      setRows(res?.data?.stockTransactionAudList?.map(item => ({ ...item, sapPlantId: form?.businessArea?.sapPlantId})))
    } else {
      setRowCount(0);
      setRows([]);
    }
    setListLoader(false);
    return res;
  }
  const searchApplicationsHandler = async () => {
    setPage(0);
    const res = await fetchList();
    if (res?.code === 200) {
      if (res?.data?.stockTransactionAudList?.length) {
        setIsTableDialogOpen(true);
      }
      else {
        failureToast('No issuance record found against the entered combination');
      }
    }
  }
  const onPageChange = async (newPage) => {
    setPage(newPage);
    await fetchList(newPage);
  }

  return (
    <>
      <Dialog onClose={handleClose} open={open} PaperProps={{ sx: { maxWidth: 'unset', borderRadius: '12px' } }}>
        {listLoader && <ALoader />}
        <div style={{ padding: "28px 24px", width: "765px" }}>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='heading-4 color-black'>Stock return</div>
            <IconButtonWrapper onClick={handleClose}><CrossIcon width={24} height={24} /></IconButtonWrapper>
          </div>

          <div className='d-flex mt-4' style={{ gap: '8px' }}>
            {checkboxOptions?.map(item => (
              <FormControlLabel
                key={JSON.stringify(item)}
                disableTypography
                className='flex-grow-1'
                sx={{ margin: '0px', ...primaryCheckboxStyles }}
                control={
                  <Checkbox
                    sx={{ padding: '3px' }}
                    onChange={item?.onChange}
                    checked={item?.checked}
                  />
                }
                label={<div className={`semi-bold ${item?.checked ? 'label-color' : 'color-grey-60'}`}>{item?.title}</div>}
              />
            ))}
          </div>

          <div className='mt-3'>
            {checkedOption === 'studentName' ? (
              <SearchByNameForm
                dropdown={dropdown}
                form={form}
                formHandler={formHandler}
                academicCareer={academicCareer}
              />
            ) : (
              <div className='d-flex' style={{ gap: '28px' }}>
                <div style={{ flex: 0.4 }}>
                  <AAutoComplete
                    label="Business area*"
                    placeholder="Select"
                    isMulti={false}
                    items={dropdown?.businessAreas}
                    currentValue={form?.businessArea}
                    handleChange={(value) => {
                      formHandler(value, 'businessArea');
                    }}
                    style={selectStyles}
                  />
                </div>
                <div className='flex-grow-1 position-relative'>
                  <AInput
                    label={`${inputMap?.[checkedOption].label}*`}
                    placeholder={inputMap?.[checkedOption]?.placeholder || `Enter`}
                    style={inputStyles}
                    value={form?.[checkedOption]}
                    regex={inputMap?.[checkedOption]?.regex}
                    handler={(value) => {
                      if (inputMap?.[checkedOption].validLength) {
                        value?.length && value?.length < inputMap[checkedOption].validLength ? setInputErrFlag(true) : setInputErrFlag(false);
                        value?.length <= inputMap[checkedOption].validLength && formHandler(value?.toUpperCase(), checkedOption);
                      } else {
                        formHandler(value?.toUpperCase(), checkedOption);
                      }
                    }}
                    crossIcon={true}
                    crossHandler={() => formHandler('', checkedOption)}
                  />
                  {inputErrFlag && <span className='tiny-semi-bold color-red-40 position-absolute top-100'>{inputMap?.[checkedOption]?.errMsg || 'Error'}</span>}
                </div>
              </div>
            )}
          </div>

          <div className='mt-4'>
            <AButton
              className='btn-left-0'
              variant='primary_filled'
              onClick={searchApplicationsHandler}
              disabled={validateApplicationSearchForm(checkedOption, form)}
            >
              Continue
            </AButton>
          </div>
        </div>
      </Dialog>

      <ApplicationTableDialog
        open={isTableDialogOpen}
        setOpen={setIsTableDialogOpen}
        loader={listLoader}
        currentPage={page}
        rows={rows}
        rowCount={rowCount}
        onPageChange={onPageChange}
        dropdown={dropdown}
        checkedOption={checkedOption}
        academicCareer={academicCareer}
      />
    </>
  )
}

export default StockReturnApplicationSearchDialog