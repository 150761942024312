import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as Downarrow } from "../../../../../../assets/img/svg/downarrow.svg";
import CustomChip from "../../../common/customChip/CustomChip";
import CustomCopyToClipboard from "../../../common/ACopyToClipboard/CustomCopyToClipboard";
import timetableTestStyles from "../academicTimetableListView/styles.module.css";
import moment from "moment";
import TestSyllabus from "../../testsListView/TestSyllabus";
import TestCard from "../academicTimetableListView/TestCard";
import { getDateFormateDate } from "../../testsListView/TestCard";
import { isTestPassed } from "../helper";

const labelStyle = {};

const ReviewTestCard = ({
  index,
  testData,
  expandedSyllabusId,
  testUpdateHandler,
  setExpandedSyllabusId,
  setExpandedIndex,
  mode,
  planData,
  roomsDropdown,
  timeTableEndOn
}) => {
  const [expanded, setExpanded] = useState(false);
  // hide test card top header
  const isHideTopHeader = true
  const toggleAccordion = () => setExpanded((prev) => !prev);
  
  const AccordionStyles = {
    my: 1,
    position: "relative",
    boxShadow: "none",
    "&:before": { display: "none" },
    "&.Mui-expanded": { margin: "8px 0" },
    ".MuiAccordionSummary-root:hover:not(.Mui-disabled)": { cursor: "auto" },
    ".MuiAccordionSummary-root": {
      height: "52px",
      color: "#FFFFFF",
      backgroundColor: isTestPassed(testData)?"#B0B0B0":"#45239A",
      padding: "0 20px",
      // width:"98%",
      ...(expanded
        ? { borderRadius: "12px 12px 0px 0px" }
        : { borderRadius: "12px" }),
      "&.Mui-expanded": { minHeight: 0 },
    },
    ".MuiCollapse-root": {
      backgroundColor: "#F5F2FD",
      color: "#0F0F0F",
      borderRadius: "0 0 12px 12px",
    },
    ".MuiAccordionDetails-root": { padding: "0px" },
  };

  return (
    <Accordion expanded={expanded} sx={AccordionStyles}>
      <AccordionSummary
        expandIcon={<Downarrow onClick={toggleAccordion} />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{ cursor: "auto" }}
      >
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex align-items-center">
            <div className="heading-4">{testData?.testType}</div>
            <div className="heading-4 ml-2" > | { getDateFormateDate(testData?.testDate)}</div>
            <div className="heading-4"> {testData?.testStartTime
                  ? `, ${
                      testData?.testStartTime &&
                       new moment(testData?.testStartTime).format("HH:mm")
                    } - ${
                      testData?.testEndTime &&
                      new moment(testData?.testEndTime).format("HH:mm")
                    }`
                  : ``}</div>


            <CustomChip
              textColor="#262626"
              bgColor={"#F5F5F5"}
              style={{ marginLeft: "1rem" }}
            >
              <span>{testData?.testName}</span>
              <CustomCopyToClipboard textToCopy={testData?.testName} />
            </CustomChip>
            {testData?.isTimeTableEvent && (
              <CustomChip
                text="Branch test"
                textColor="#183DD3"
                bgColor="#CCD9FC"
                style={{ marginLeft: "1rem" }}
              />
            )}
          </div>
          <div className={`d-flex mr-2 semi-bold`}>
            {expanded && (
              <div
                className="cursor"
                onClick={() =>
                  expandedSyllabusId === index
                    ? setExpandedSyllabusId(-1)
                    : setExpandedSyllabusId(index)
                }
              >
                {expandedSyllabusId === index
                  ? `Hide test syllabus`
                  : `View test syllabus`}
              </div>
            )}
            <div className="ml-3 cursor" onClick={toggleAccordion}>
              {expanded ? "Hide details" : "View details"}
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails >

        <TestCard
         {...{
          planData,
          isHideTopHeader,
          testData,
          index,
          expandedIndex :expandedSyllabusId,
          setExpandedIndex:setExpandedSyllabusId,
          roomsDropdown,
          testUpdateHandler,
          mode,
          // deleteTimetableTest,
          timeTableEndOn:planData?.endOn
        }}
        reviewCard={true}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default ReviewTestCard;
