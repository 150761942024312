import React, { useState } from "react";
import { Row, Col, FormGroup, Input, Label } from "reactstrap";
import Select from "react-select";
const BankInformation = (props) => {
  const {
    activeCurrency,
    activeCountry,
    submitDataHandler,
    glCodeDetails,
    getDisplayValue,
    actionDisabled,
  } = props;
  const [status, setStatus] = useState([
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "INACTIVE", label: "INACTIVE" },
  ]);

  return (
    <Row>
      <Col>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="form-control-label">
                <span className="custom-span-color">*</span>Reference ID
              </Label>
              <Input
              data-testid='referenceId'
                className="customInput"
                maxLength={50}
                disabled={actionDisabled}
                placeholder="Enter Reference ID"
                value={glCodeDetails["referenceId"]}
                onChange={(e) =>
                  submitDataHandler(e.target.value, "referenceId")
                }
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="form-control-label">Status</Label>
              <Select
                isDisabled={actionDisabled}
                value={{
                  value: glCodeDetails["status"],
                  label: glCodeDetails["status"] ? (
                    glCodeDetails["status"]
                  ) : (
                    <span className="customSpan">Select Status</span>
                  ),
                }}
                options={status}
                onChange={(e) => submitDataHandler(e.value, "status")}
              ></Select>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="form-control-label">Country</Label>
              <Select
                isDisabled={actionDisabled}
                value={{
                  value: glCodeDetails["country"],
                  label: glCodeDetails["country"] ? (
                    getDisplayValue(glCodeDetails["country"], "CountryID")
                  ) : (
                    <span className="customSpan">Select Country</span>
                  ),
                }}
                options={activeCountry}
                onChange={(e) => submitDataHandler(e.value, "country")}
              ></Select>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="form-control-label">Currency</Label>
              <Select
                isDisabled={actionDisabled}
                value={{
                  value: glCodeDetails["currency"],
                  label: glCodeDetails["currency"] ? (
                    getDisplayValue(glCodeDetails["currency"], "CurrencyID")
                  ) : (
                    <span className="customSpan">Select Currency</span>
                  ),
                }}
                options={activeCurrency}
                onChange={(e) => submitDataHandler(e.value, "currency")}
              ></Select>
            </FormGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BankInformation;
