import React from "react";
import { Card, CardHeader, Input, Container, Table, Row, } from "reactstrap";
import { useState } from "react";
import { failureToast, successToast } from "../../common/utils/methods/toasterFunctions/function";
import { TDROW } from "../../common/commonComponents/masterRow/masterRow";
import moment from "moment";
import CustomButton from "components/CustomButton";
import { ButtonText } from "variables/Buttons";
import { MasterHeaderAuto } from "../../common/commonComponents/masterHeader/masterHeader";
import { fetchAllPostPromisedData, fetchDeletePromisedData, getCurrentDate } from "../../common/utils/methods/commonMethods/utilityMethod";
import { customValidation } from "../../common/utils/methods/validations/validation";
import validationRules from './validation.json'
import { endpoint } from "../../common/constant";
import { editTableRow, newTableRow } from "../../common/utils/methods/commonMethods/tableMethods";

var _ = require("lodash");

const EmailTypeTable = (props) => {
  const { permissionSet, isSaveVisible, setisSaveVisible = () => { }, getAllrecords = () => { } } = props;
  const [tableDataKey, settableDataKey] = useState("tableDataKey");

  const headerList = [
    {name: "EMAIL TYPE KEY", isRequired: true},
    {name: 'EFFECTIVE DATE'},
    {name: 'Description', isRequired: true},
    {name: 'DISPLAY VALUE', isRequired: true},
    {name: 'Status'},
    {name: 'Action'}
  ]

  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: "__new",
        emailTypeKey: null,
        "emailTypeDispValue'": null,
        effectiveDate: null,
        status: "ACTIVE",
        emailTypeDesc: null,
      },
      ...props.classArray,
    ]);
  };

  // const saveAll = (cb) => {
  //   var _tempArray = props.classArray;
  //   var isValid = true;
  //   _tempArray.forEach((el, index) => {
  //     if (el["editMode"]) {
  //       if (el["emailTypeKey"] == null || !el["emailTypeKey"].trim()) {
  //         isValid = false;
  //         failureToast("Please enter the value of Email Type Key");
  //         return;
  //       } else if (el["emailTypeDesc"] == null || !el["emailTypeDesc"].trim()) {
  //         isValid = false;
  //         failureToast("Please enter the value of Description");
  //         return;
  //       } else if (
  //         el["emailTypeDispValue"] == null ||
  //         !el["emailTypeDispValue"].trim()
  //       ) {
  //         isValid = false;
  //         failureToast("Please enter the value of Display Value");
  //         return;
  //       }
  //       if (!isValid) {
  //         return;
  //       }
  //       if (_tempArray[index]["__status"] == "__new") {
  //         props.setapiloader(true);

  //         putpost(
  //           masterServiceBaseUrl + "/emailType/createEmailType",
  //           (data) => {
  //             successToast(data["message"]);
  //             props.setapiloader(false);
  //             setisSaveVisible(false);
  //             props.fetchClasses(masterServiceBaseUrl + "/emailType/getAllEmailType");
  //           },
  //           (data) => {
  //             props.setapiloader(false);
  //             failureToast(data["message"]);
  //           },
  //           _tempArray[index],
  //           "post"
  //         );
  //       } else {
  //         props.setapiloader(true);
  //         putpost(
  //           masterServiceBaseUrl + "/emailType/updateEmailType/" + _tempArray[index].id,
  //           (data) => {
  //             successToast(data["message"]);
  //             setisSaveVisible(false);
  //             delete _tempArray[index]["editMode"];
  //             console.log(_tempArray[index]);
  //             props.setclassArray(_tempArray);
  //             props.setapiloader(false);
  //             settableDataKey(new Date().getMilliseconds());
  //           },
  //           (data) => {
  //             props.setapiloader(false);
  //             failureToast(data["message"]);
  //           },
  //           _tempArray[index],
  //           "put"
  //         );
  //       }
  //     }
  //   });
  // };
  const saveAll = async (index) => {
    let _data = props.classArray[index];
    let isValid = true;
    isValid = customValidation(_data, validationRules)
    if(!isValid) return;

    if (_data["__status"] == "__new"){
      const createRecord = await fetchAllPostPromisedData(endpoint.emailType.create, _data, 'post')
      if (createRecord?.code === 200) {
        successToast(createRecord["message"]);
        setisSaveVisible(false);
        getAllrecords()
      }
    } else {
      const updateRecord = await fetchAllPostPromisedData(`${endpoint.emailType.update}/${_data.id}`, _data, 'put');
      if (updateRecord?.code === 200) {
        successToast(updateRecord["message"]);
        setisSaveVisible(false);
        getAllrecords()
      }
    }
  }
  // const newRow = () => {
  //   if (isSaveVisible) {
  //     failureToast("Please Delete or Save current changes");
  //   } else {
  //     let cb = () => {
  //       addNewClass();
  //       setisSaveVisible(true);
  //     };
  //     var _tempArray = props.classArray;
  //     _tempArray = _.sortBy(_tempArray, [
  //       function (o) {
  //         return o.value;
  //       },
  //     ]);
  //     props.setclassArray(_tempArray);
  //     setisSaveVisible(false);
  //     settableDataKey(new Date().getMilliseconds());
  //     cb();
  //   }
  // };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      newTableRow(props.classArray,props.setclassArray,setisSaveVisible,addNewClass);
    }
  };
  // const editRow = (index) => {
  //   if (isSaveVisible) {
  //     failureToast("Please Delete or Save current changes");
  //   } else {
  //     let cb = () => {
  //       var _tempArray = props.classArray;
  //       _tempArray[index]["editMode"] = true;
  //       props.setclassArray(_tempArray);
  //       setisSaveVisible(true);
  //     };
  //     cb();
  //   }
  // };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      editTableRow(index,props.classArray,props.setclassArray,setisSaveVisible);
    }
  };
  // const deleteRow = (index) => {
  //   console.log("index", index);

  //   if (props.classArray[index]["__status"] == "__new") {
  //     let n = props.classArray;
  //     n.splice(index, 1);
  //     props.setclassArray(n);
  //     setisSaveVisible(false);
  //     settableDataKey(new Date().getMilliseconds());
  //   } else {
  //     if (window.confirm("Are you sure you want to delete this Email Type?")) {
  //       props.setapiloader(true);
  //       putpost2(
  //         masterServiceBaseUrl + "/emailType/deleteEmailType/" + props.classArray[index].id,
  //         (data) => {
  //           successToast(data["message"]);
  //           props.setapiloader(false);
  //           if (props.classArray.length == 1) {
  //             props.previousPage();
  //             props.fetchClasses(masterServiceBaseUrl + "/emailType/getAllEmailType");
  //           } else {
  //             var _tempArray = props.classArray;
  //             if (_tempArray[index]["editMode"]) {
  //               setisSaveVisible(false);
  //             }
  //             _tempArray.splice(index, 1);
  //             props.setclassArray(_tempArray);
  //           }
  //           settableDataKey(new Date().getMilliseconds());
  //         },
  //         (data) => {
  //           failureToast(data["message"]);
  //           props.setapiloader(false);
  //         },
  //         "PUT"
  //       );
  //     }
  //   }
  // };
  const deleteRow = async (index) => {
    if (window.confirm("Are you sure you want to delete this Email Type?")) {
      const deleteRecord = await fetchDeletePromisedData(`${endpoint.emailType.delete}/${props.classArray[index].id}`);
      if(deleteRecord?.code === 200){
        successToast(deleteRecord['message']);
        let flag=false;
        if(props.classArray.length==1){
          flag=true;
        }
        getAllrecords(flag);
        settableDataKey(new Date().getMilliseconds());
      }
    }
  };
  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
  };
  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Email Types</h3>
                {/* <Button
                  data-testid="addNewEmailType"
                  disabled={isSaveVisible}
                  color="info"
                  size="sm"
                  type="button"
                  className={ "floatRight mx-1" + (isSaveVisible ? " btn-dark" : null) }
                  onClick={() => { newRow(); }}
                >
                  New Email Type
                </Button> */}
                <CustomButton 
                  data-testid="addNewEmailType"
                  disabled={isSaveVisible}
                  className={ "floatRight mx-1" + (isSaveVisible ? " btn-dark" : null) }
                  content={ButtonText.EmailType.NEW}
                  icon={true}
                  permissionType={'C'}
                  permissionSet={permissionSet}
                  onClick={() => newRow()}
                />
                {/* {isSaveVisible?<Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => {saveAll()}}>Save</Button>:null} */}
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout">
                  {/* <MasterHeader type={"Email Type"} isSaveVisible={isSaveVisible} permissionSet={permissionSet} /> */}
                  <MasterHeaderAuto headerList={headerList} isSaveVisible={isSaveVisible} permissionSet={permissionSet} />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                          !el["editMode"] ? (
                            <TDROW
                              type={"emailType"}
                              index={index}
                              el={el}
                              editRow={editRow}
                              deleteRow={deleteRow}
                              isSaveVisible={isSaveVisible} 
                              permissionSet={permissionSet}
                            />
                          ) : (
                            <tr key={index + "-class"}>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="emailTypeKey"
                                  maxLength={50}
                                  type="text"
                                  defaultValue={el["emailTypeKey"]}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      "emailTypeKey"
                                    );
                                  }}
                                  placeholder="Example:Home"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="effectiveDate"
                                  disabled={true}
                                  defaultValue={
                                    el["__status"] == "__new"
                                      ? getCurrentDate()
                                      : moment(el["effectiveDate"]).format(
                                          "DD-MM-YYYY"
                                        )
                                  }
                                  placeholder="Example:01-01-2020"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="emailTypeDesc"
                                  maxLength={50}
                                  type="text"
                                  defaultValue={el["emailTypeDesc"]}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      "emailTypeDesc"
                                    );
                                  }}
                                  placeholder="Description"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="emailTypeDispValue"
                                  maxLength={50}
                                  type="text"
                                  defaultValue={el["emailTypeDispValue"]}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      "emailTypeDispValue"
                                    );
                                  }}
                                  placeholder="Display value"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <label className="custom-toggle mx-auto ml-2">
                                  <input
                                    data-testid="status"
                                    checked={
                                      el.status == "ACTIVE" ? true : false
                                    }
                                    type="checkbox"
                                    id={el.id}
                                    onChange={(e) => {
                                      settableDataKey(
                                        new Date().getMilliseconds()
                                      );
                                      let n = "ACTIVE";
                                      if (!e.target.checked) {
                                        n = "INACTIVE";
                                      }
                                      updateKey(index, n, "status");
                                    }}
                                  />
                                  <span
                                    style={{ width: "72px" }}
                                    className="custom-toggle-slider rounded-circle activeToggle"
                                    data-label-off="Inactive"
                                    data-label-on="Active"
                                  />
                                </label>
                              </td>
                              <td className="text-center">
                                {isSaveVisible ? (
                                  <>
                                    {/* <Button
                                      data-testid="saveData"
                                      color="info"
                                      size="sm"
                                      type="button"
                                      className="mx-1"
                                      onClick={() => {
                                        saveAll();
                                      }}
                                    >
                                      Save
                                    </Button> */}
                                    <CustomButton
                                      data-testId="saveData"
                                      className={'mx-1'}
                                      content={ButtonText.EmailType.UPDATE}
                                      forTable={true}
                                      permissionType={'C,U'}
                                      permissionSet={permissionSet}
                                      onClick={() => { saveAll(index); }} 
                                    />
                                    {/* <Button
                                      data-testid="cancelBtn"
                                      color="danger"
                                      size="sm"
                                      type="button"
                                      onClick={() => {
                                        let n = props.classArray;
                                        if (n[index]["__status"] == "__new") {
                                          n.splice(index, 1);
                                        } else {
                                          // n[index]['editMode']=false
                                          props.fetchClasses(
                                            masterServiceBaseUrl +
                                              "/emailType/getAllEmailType"
                                          );
                                        }
                                        props.setclassArray(n);
                                        setisSaveVisible(false);
                                      }}
                                    >
                                      <i className="fas fa-times" />
                                    </Button> */}
                                    <CustomButton 
                                      data-testId="cancelBtn"
                                      className={'mx-1'}
                                      permissionType={'cancel'}
                                      forTable={true}
                                      icon={true}
                                      permissionSet={permissionSet}
                                      onClick={() => {
                                        let n = props.classArray;
                                        if (n[index]["__status"] == "__new") {
                                          n.splice(index, 1);
                                        } else {
                                          // n[index]['editMode']=false
                                          getAllrecords();
                                        }
                                        setisSaveVisible(false);
                                      }}
                                    />
                                  </>
                                ) : (
                                  // <Button
                                  //   data-testid="deleteBtn"
                                  //   color="info"
                                  //   size="sm"
                                  //   type="button"
                                  //   onClick={() => {
                                  //     deleteRow(index);
                                  //   }}
                                  // >
                                  //   <i className="fas fa-trash" />
                                  // </Button>
                                  <></>
                                )}
                              </td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default EmailTypeTable;
