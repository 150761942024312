import React, { useEffect, useState } from 'react'
import AInput from '../../../../common/formFeilds/customInput/AInput'
import AButton from '../../../../common/form-fields-mui/AButton'
import APopover from '../../../../common/a-popover';
import SearchFilterForm from './SearchFilterForm';


export const highlightedStyle = {
  border: "1px solid #00B0F5",
  color: "#00B0F5",
};
export const normalStyle = {
  border: "1px solid #CCCCCC",
};

const SearchFilter = ({
  listRef,
  filterCount,
  filterPayload,
  nameFilterPayload,
  groupTypeDropdown,
  applyFilter,
  applyNameFilter,
}) => {

  const [userInput, setUserInput] = useState(nameFilterPayload);
  useEffect(() => {
    setUserInput(nameFilterPayload);
  }, [nameFilterPayload])


  const onKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      applyNameFilter(userInput)
    }
  }

  return (
    <div className='d-flex align-items-center'>
      <AInput
        type='text'
        placeholder={'Search by material number'}
        value={userInput}
        rightSearchIcon
        crossIcon
        handler={(value) => setUserInput(value)}
        onKeyDown={onKeyDown}
        crossHandler={() => { setUserInput(''); applyNameFilter(''); }}
        searchIconClickHandler={() => applyNameFilter(userInput)}
        className={'pr-5'}
        style={{ width: '210px', borderRadius: '4px', height: '29px', ...normalStyle }}
      />
      <APopover
        buttonComponent={(onClick, styles) => (
          <AButton
            className='tiny-semi-bold color-dark'
            onClick={onClick}
            updatedStyle={{ minWidth: '61px', ...(filterCount > 0 ? highlightedStyle : styles) }}
          >
            Filter {filterCount > 0 && <>( {filterCount} )</>}
          </AButton>
        )}
        menuComponent={(closeForm) =>
          <SearchFilterForm
            closeForm={closeForm}
            filterCount={filterCount}
            filterPayload={filterPayload}
            groupTypeDropdown={groupTypeDropdown}
            applyFilter={applyFilter}
          />
        }
      />
      <AButton className='tiny-semi-bold color-dark' updatedStyle={{ width: '84px' }} onClick={() => listRef?.current?.addNewRow()}>Add new</AButton>
    </div>
  )
}

export default SearchFilter