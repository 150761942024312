import {createStore,applyMiddleware} from "redux";
import rootReducer from "./rootReducer";
import {logger} from 'redux-logger';
import thunk from 'redux-thunk';

import { composeWithDevTools } from "redux-devtools-extension";
const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if(serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (e) {
        console.log('Error in loadState()----->',e);
        return undefined;
    }
};
const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
        // console.log('Saved State---------->',serializedState);
    } catch (e) {
        console.log('Error in save state--->',e);
    }
};
const peristedState = loadState();
const store = createStore(rootReducer,peristedState,composeWithDevTools(applyMiddleware(...[thunk,logger])));
store.subscribe(() => {
    saveState(store.getState());
});
export default store;