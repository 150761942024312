import React, {useState, useEffect} from 'react';
import {
  Card,
  CardHeader,
  Input,
  Container,
  Button,
  Table,
  Row,
  Col,
} from 'reactstrap';
import Select from 'react-select2-wrapper';
import {failureToast, successToast} from '../../common/utils/methods/toasterFunctions/function';
import {MasterHeaderAuto} from '../../common/commonComponents/masterHeader/masterHeader';
import ExportToExcel from '../../common/commonComponents/ToExcel';
import CustomDatePicker from '../../common/commonComponents/CustomDatePicker/index'
import {NewRow} from '../../common/utils/methods/commonMethods/utilityMethod';
import {
  masterServiceBaseUrl,
  putpost,
  putpost2,
  getAPI,
} from 'services/http';
import moment from 'moment';

let _ = require('lodash');
const AcademicPhaseMappingTable = (props) => {
  const {
    isSaveVisible,
    setisSaveVisible,
    term,
    academicYear,
    courseAttribute,
    attributeValue,
    academicPhase,
    serverData,
    groupCode,
    allCompanyCode,
    academicCareer,
  } = props;
  const [tableDataKey, settableDataKey] = useState('tableDataKey');
  const [mappedCourseAttributeValue, setMappedCourseAttributeValue] = useState(
    []
  );
  const [updatedKey, setUpdateKey] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [companyCode, setCompanyCode] = useState([]);
  const [termValue, setTermValue] = useState([]);
  const [showAcademicYear, setShowAcademicYear] = useState([]);
  const [academicCareerShow, setAcademicCareerShow] = useState();
  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: '__new',
        status: 'ACTIVE',
        academicPhaseId: null,
        academicYearId: null,
        attributeValueId: null,
        academicCareerId: null,
        courseAttributeId: courseAttribute[0].id,
        termId: null,
        companyCodeId: null,
        groupCodeId: null,
        startDate: null,
        endDate: null,
      },
      ...props.classArray,
    ]);
  };

  const itemToBeRenderFromMaster = [
    'groupCodeId',
    'companyCodeId',
    'academicCareerId',
    'termId',
    'academicYearId',
    'courseAttributeId',
    'attributeValueId',
    'academicPhaseId',
  ];

  const arrList = [
    groupCode,
    allCompanyCode,
    academicCareer,
    term,
    academicYear,
    courseAttribute,
    attributeValue,
    academicPhase,
    null,
    null,
    null,
  ];

  const propertyList = [
    'text',
    'companyCodeDispValue',
    'academicCareerDispValue',
    'text',
    'text',
    'text',
    'courseAttributeValueDispValue',
    'text',
    null,
    null,
    null,
  ];

  const headerList = [
    {name: 'Group Code', isRequired: true},
    {name: 'Company Code', isRequired: true},
    {name: 'Academic Career', isRequired: true},
    {name: 'Term', isRequired: true},
    {name: 'Academic Year', isRequired: true},
    {name: 'Course Attribute', isRequired: true},
    {name: 'Attribute Value', isRequired: true},
    {name: 'Academic Phase', isRequired: true},
    {name: 'Start Date', isRequired: true},
    {name: 'End Date', isRequired: true},
    {name: 'Status'},
    {name: 'Action'},
  ];
  const listToRender = [
    'groupCodeId',
    'companyCodeId',
    'academicCareerId',
    'termId',
    'academicYearId',
    'courseAttributeId',
    'attributeValueId',
    'academicPhaseId',
    'startDate',
    'endDate',
    'status',
  ];

  const saveAll = (cb) => {
    try {
      let _tempArray = props.classArray;
      let isValid = true;
      _tempArray.forEach((el, index) => {
        if (el['editMode']) {
          if (!el['groupCodeId']) {
            isValid = false;
            failureToast('Please Select Group Code');
            return;
          } else if (!el['companyCodeId']) {
            isValid = false;
            failureToast('Please Select Company Code');
            return;
          } else if (!el['academicCareerId']) {
            isValid = false;
            failureToast('Please Select Academic Career');
            return;
          } else if (!el['termId']) {
            isValid = false;
            failureToast('Please Select Term');
            return;
          } else if (!el['academicYearId']) {
            isValid = false;
            failureToast('Please Select Academic Year');
            return;
          } else if (!el['courseAttributeId']) {
            isValid = false;
            failureToast('Please Select Course Attribute');
            return;
          } else if (!el['attributeValueId']) {
            isValid = false;
            failureToast('Please Select Attribute Value');
            return;
          } else if (!el['academicPhaseId']) {
            isValid = false;
            failureToast('Please Select Academic Phase');
            return;
          } else if (!el['startDate']) {
            isValid = false;
            failureToast('Please Select Start Date');
            return;
          } else if (!el['endDate']) {
            isValid = false;
            failureToast('Please Select End Date');
            return;
          }

          if (!isValid) {
            return;
          }
          if (_tempArray[index]['__status'] == '__new') {
            putpost(
              masterServiceBaseUrl +
              '/academicPhaseMappingMaster/createAcademicPhaseMappingMaster',
              (data) => {
                successToast(data['message']);
                setisSaveVisible(false);
                setUpdateKey(!updateKey);
                props.fetchClasses(
                  `${masterServiceBaseUrl}/academicPhaseMappingMaster/getAllAcademicPhaseMappingMaster`,
                  'TABLE_RECORDS'
                );
              },
              (data) => {
                failureToast(data['message']);
              },
              _tempArray[index],
              'post'
            );
          } else {
            putpost(
              masterServiceBaseUrl +
              '/academicPhaseMappingMaster/updateAcademicPhaseMappingMaster/' +
              _tempArray[index].id,
              (data) => {
                successToast(data['message']);
                setisSaveVisible(false);
                props.fetchClasses(
                  `${masterServiceBaseUrl}/academicPhaseMappingMaster/getAllAcademicPhaseMappingMaster`,
                  'TABLE_RECORDS'
                );
                settableDataKey(new Date().getMilliseconds());
              },
              (data) => {
                failureToast(data['message']);
              },
              _tempArray[index],
              'put'
            );
          }
        } else {
        }
      });
    } catch (e) {
      console.log('Error ', e);
    }
  };

  const newRow = () => {
    try {
      if (isSaveVisible) {
        failureToast('Please Delete or Save current changes');
      } else {
        let cb = () => {
          addNewClass();
          setisSaveVisible(true);
        };
        let _tempArray = props.classArray;
        _tempArray = _.sortBy(_tempArray, [
          function (o) {
            return o.value;
          },
        ]);
        props.setclassArray(_tempArray);
        setisSaveVisible(false);
        cb();
      }
    } catch (e) {
      console.log('Error ', e);
    }
  };

  const editRow = (index) => {
    try {
      if (isSaveVisible) {
        failureToast('Please Delete or Save current changes');
      } else {
        let cb = () => {
          let _tempArray = props.classArray;
          _tempArray[index]['editMode'] = true;
          getMappedValue(_tempArray[0].courseAttributeId);
          props.setclassArray(_tempArray);
          setisSaveVisible(true);
          getMappedValue(
            `${masterServiceBaseUrl}/companyCode/getCompanyCodeByGroup/${_tempArray[index].groupCodeId}`,
            'COMPANY_CODE'
          );
          getMappedValue(
            `${masterServiceBaseUrl}/termMapping/getAllTermMappingByGroupCodeAndCompanyCode?groupCode=${_tempArray[index].groupCodeId}&companyCode=${_tempArray[index].companyCodeId}`,
            'TERM_VALUE'
          );
          // getMappedValue(
          //   `${masterServiceBaseUrl}/courseAttributeValue/getCourseAttributeValueFromAttributeMappingForCourseAttributeIdAndGroupCodeAndCompanyCode?courseAttributeId=${_tempArray[index].courseAttributeId}&groupCodeId=${_tempArray[index].groupCodeId}&companyCodeId=${_tempArray[index].companyCodeId}`,
          //   'ATTRIBUTE_VALUE'
          // );

          getMappedValue(
            `${masterServiceBaseUrl}/courseAttributeValueMapping/getCourseAttributeValueByCourseAttributeIdAndGroupCodeIdAndCompanyCodeId/?groupCodeid=${_tempArray[index].groupCodeId}&companyCodeId=${_tempArray[index].companyCodeId}&courseAttributeId=${_tempArray[index].courseAttributeId}`,
            'ATTRIBUTE_VALUE'
          );
        };
        cb();
      }
    } catch (e) {
      console.log('Error ', e);
    }
  };
  const deleteRow = (index) => {
    try {
      if (props.classArray[index]['__status'] == '__new') {
        let n = props.classArray;
        n.splice(index, 1);
        props.setclassArray(n);
        setisSaveVisible(false);
        settableDataKey(new Date().getMilliseconds());
      } else {
        if (
          window.confirm(
            'Are you sure you want to delete this Acadamic Phase ?'
          )
        ) {
          putpost2(
            masterServiceBaseUrl +
            '/academicPhaseMappingMaster/' +
            props.classArray[index].id,
            (data) => {
              successToast(data['message']);
              if (props.classArray.length == 1) {
                props.previousPage();
                props.fetchClasses(
                  `${masterServiceBaseUrl}/academicPhaseMappingMaster/getAllAcademicPhaseMappingMaster`,
                  'TABLE_RECORDS'
                );
              } else {
                let _tempArray = props.classArray;
                if (_tempArray[index]['editMode']) {
                  setisSaveVisible(false);
                }
                _tempArray.splice(index, 1);
                props.setclassArray(_tempArray);
              }
              settableDataKey(new Date().getMilliseconds());
            },
            (data) => {
              failureToast(data['message']);
            },
            'PUT'
          );
        }
      }
    } catch (e) {
      console.log('Error ', e);
    }
  };

  const updateKey = (index, value, key) => {
    try {
      let _tempArray = props.classArray;
      _tempArray[index][key] = value;
      props.setclassArray(_tempArray);
    } catch (e) {
      console.log('Error ', e);
    }
  };

  const getMappedValue = (url, type) => {
    try {
      getAPI(
        url,
        (data) => {
          mappedDataHandler(data?.data, type);
        },
        (data) => {
          failureToast(data['message']);
        }
      );
    } catch (e) {
      console.log('Error ', e);
    }
  };

  const mappedDataHandler = (data, type) => {
    try {
      switch (type) {
        case 'COMPANY_CODE':
          let comapnyCodeTemp = [];
          data &&
          data.forEach((item) => {
            comapnyCodeTemp.push({
              id: item?.id,
              text: item?.companyCodeDispValue,
            });
          });
          setCompanyCode(comapnyCodeTemp);
          break;
        case 'TERM_VALUE':
          let termTemp =
            data &&
            data.map((item) => {
              return term.find((value) => value?.id === item?.termId);
            });

          setTermValue(termTemp);
          break;
        case 'ATTRIBUTE_VALUE':
          let atttributeTemp = [];
          data &&
          data.forEach((item) => {
            atttributeTemp.push({
              id: item.courseAttributeValueId,
              text: findDisplayValue(
                item.courseAttributeValueId,
                attributeValue,
                'courseAttributeValueDispValue'
              ),
            });
          });
          setMappedCourseAttributeValue(atttributeTemp);
          setUpdateKey(!updateKey);
          break;
        case 'ACADEMIC_CAREER':
          let academicCareerTemp = [];
          data &&
          data.forEach((item) => {
            academicCareerTemp.push({
              id: item.id,
              text: item.academicCareerDispValue,
            });
          });
          setAcademicCareerShow(academicCareerTemp);
          break;
        case 'ACADEMIC_YEAR':
          let academicYearTemp = [];
          data.forEach((item) => {
            academicYearTemp.push({
              id: item.id,
              text: item.academicYearDisplayValue
            })
          });
          setShowAcademicYear(academicYearTemp);
          break;
        default:
          break;
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  const findDisplayValue = (id, arr, property) => {
    try {
      const value = arr.find((item) => item.id === id);
      return value ? value[property] : '';
    } catch (e) {
      console.log('Error ', e);
    }
  };

  const excelDetails = {
    'S.NO': 'S.NO',
    'Group Code': 'GroupCode',
    'Company Code': 'CompanyCode',
    'Academic Career': 'academicCarrer',
    Term: 'TermYear',
    'Academic Year': 'AcademicYear',
    'Course Attribute': 'CourseAttribute',
    'Attribute Value': 'AttributeValue',
    'Academic Phase': 'AcademicPhase',
    'Start Date': 'StartDate',
    'End Date': 'EndDate',
    Status: 'Status',
  };
  const exportData = serverData?.map((item, index) => {
    return {
      ...item,
      'S.NO': index + 1,
      GroupCode: findDisplayValue(item?.groupCodeId, groupCode, 'text'),
      CompanyCode: findDisplayValue(
        item?.companyCodeId,
        allCompanyCode,
        'companyCodeDispValue'
      ),
      academicCarrer: findDisplayValue(
        item?.academicCareerId,
        academicCareer,
        'academicCareerDispValue'
      ),
      TermYear: findDisplayValue(item?.termId, term, 'text'),
      AcademicYear: findDisplayValue(
        item?.academicYearId,
        academicYear,
        'text'
      ),
      CourseAttribute: findDisplayValue(
        item?.courseAttributeId,
        courseAttribute,
        'text'
      ),
      AttributeValue: findDisplayValue(
        item?.attributeValueId,
        attributeValue,
        'courseAttributeValueDispValue'
      ),
      AcademicPhase: findDisplayValue(
        item?.academicPhaseId,
        academicPhase,
        'text'
      ),
      StartDate: moment(item?.startDate).format('DD-MM-YYYY'),
      EndDate: moment(item?.endDate).format('DD-MM-YYYY'),
      Status: item?.status,
    };
  });

  const fetchSearchData = (url, obj, type) => {
    putpost(url,
      (data) => {
        mappedDataHandler(data.data, type)
      },
      (data) => {
      failureToast(data['message'])
        if(type === 'ACADEMIC_YEAR'){
          setShowAcademicYear([]);
        }
      },
      obj,
      'post'
    );
  }

  const requestHandler = (dataObj, type) => {
    try {
      switch (type) {
        case 'GroupCompanyCourseAttribute':
          getMappedValue(
            `${masterServiceBaseUrl}/courseAttributeValueMapping/getCourseAttributeValueByCourseAttributeIdAndGroupCodeIdAndCompanyCodeId/?groupCodeid=${dataObj?.groupCodeId}&companyCodeId=${dataObj?.companyCode}&courseAttributeId=${dataObj?.courseAttributeId}`,
            'ATTRIBUTE_VALUE'
          );
          break;

        case 'GroupCompanyAcademic':
          getMappedValue(
            `${masterServiceBaseUrl}/termMapping/getTermCodeByGroupCodeAndAcademicCareerAndCompanyCode?groupCode=${dataObj?.groupCodeId}&academicCareer=${dataObj?.academicCareerId}&companyCode=${dataObj?.companyCodeId}`,
            'TERM_VALUE'
          );
          break;
        case 'GroupCompanyAcademicTerm':
          fetchSearchData(
            `${masterServiceBaseUrl}/academicYear/getAcademicYearByGroupAndCompanyAndAcademicCareerAndTermId`, dataObj, 'ACADEMIC_YEAR'
          );
          break;
        default:
          break;
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  return (
    <>
      <Container className='mt-3' fluid>
        <Row>
          <div className='col'>
            <Card>
              <CardHeader className='border-0 d-flex justify-content-between'>
                <h3 className='mb-0 '>Academic Phase Mapping</h3>
                <span className='d-flex'>
                  <ExportToExcel
                    data={exportData}
                    detailObj={excelDetails}
                    name='Attribute Mapping Details'
                  />
                  <Button
                    data-testid='addNewAcccount'
                    disabled={isSaveVisible || isDisabled}
                    color='info'
                    size='sm'
                    type='button'
                    className={
                      'floatRight ml-4 ' +
                      (isSaveVisible || isDisabled ? ' btn-dark' : null)
                    }
                    onClick={() => {
                      newRow();
                    }}
                  >
                    Add New Mapping
                  </Button>
                </span>
              </CardHeader>
              <div
                id='questionTable'
                className='table-responsive'
                style={{overflow: 'auto'}}
              >
                <Table className='align-items-center table-flush '>
                  <MasterHeaderAuto headerList={headerList}/>
                  <tbody className='list' key={tableDataKey}>
                  {props.classArray
                    ? props.classArray.map((el, index) =>
                      !el['editMode'] ? (
                        <>
                          <NewRow
                            data={el}
                            listToBeRendered={listToRender}
                            itemToBeRenderFromMaster={
                              itemToBeRenderFromMaster
                            }
                            propertyList={propertyList}
                            arrList={arrList}
                          >
                            <td
                              className='text-center  white-breakSpace'
                              style={{width: '200px'}}
                            >
                              <Button
                                color='info'
                                size='sm'
                                type='button'
                                disabled={props.isViewOnly}
                                onClick={() => editRow(index)}
                              >
                                <i className='fas fa-edit'/>
                              </Button>
                              <Button
                                color='info'
                                size='sm'
                                type='button'
                                className={' mx-1'}
                                onClick={() => deleteRow(index)}
                              >
                                <i className='fas fa-trash'/>
                              </Button>
                            </td>
                          </NewRow>
                        </>
                      ) : (
                        <tr key={index + '-class'}>
                          <td
                            className='text-center p-2 mx-1'
                            style={{width: '200px'}}
                          >
                            <Select
                              options={{placeholder: 'Select'}}
                              data={groupCode}
                              defaultValue={el['groupCodeId']}
                              onChange={(e) => {
                                let value = e.target.value;
                                value &&
                                el['companyCodeId'] &&
                                el['courseAttributeId'] &&
                                requestHandler(
                                  {
                                    groupCodeId: value,
                                    courseAttributeId:
                                      el['courseAttributeId'],
                                    companyCode: el['companyCodeId'],
                                  },
                                  'GroupCompanyCourseAttribute'
                                );
                                el['academicCareerId'] &&
                                el['companyCodeId'] &&
                                value &&
                                requestHandler(
                                  {
                                    groupCodeId: value,
                                    companyCodeId: el['companyCodeId'],
                                    academicCareerId:
                                      el['academicCareerId'],
                                  },
                                  'GroupCompanyAcademic'
                                );
                                value &&
                                el['companyCodeId'] &&
                                el['academicCareerId'] &&
                                el['termId'] &&
                                requestHandler(
                                  {
                                    groupCodeId: value,
                                    companyCodeId: el['companyCodeId'],
                                    academicCareerId: el['academicCareerId'],
                                    termId: el['termId']
                                  },
                                  'GroupCompanyAcademicTerm'
                                );
                                value &&
                                getMappedValue(
                                  `${masterServiceBaseUrl}/companyCode/getCompanyCodeByGroup/${value}`,
                                  'COMPANY_CODE'
                                );
                                value &&
                                updateKey(
                                  index,
                                  value,
                                  'groupCodeId',
                                  true
                                );
                              }}
                            />
                          </td>
                          <td
                            className='text-center p-2 mx-1'
                            style={{width: '200px'}}
                          >
                            <Select
                              options={{placeholder: 'Select'}}
                              data={companyCode}
                              defaultValue={el['companyCodeId']}
                              onChange={(e) => {
                                let value = e.target.value;
                                value &&
                                el['groupCodeId'] &&
                                el['courseAttributeId'] &&
                                requestHandler(
                                  {
                                    groupCodeId: el['groupCodeId'],
                                    courseAttributeId:
                                      el['courseAttributeId'],
                                    companyCode: value,
                                  },
                                  'GroupCompanyCourseAttribute'
                                );
                                el['academicCareerId'] &&
                                el['groupCodeId'] &&
                                value &&
                                requestHandler(
                                  {
                                    groupCodeId: el['groupCodeId'],
                                    companyCodeId: value,
                                    academicCareerId:
                                      el['academicCareerId'],
                                  },
                                  'GroupCompanyAcademic'
                                );
                                value &&
                                getMappedValue(
                                  `${masterServiceBaseUrl}/academicCareer/getAcademicCareerByCompanyCode/${value}`,
                                  'ACADEMIC_CAREER'
                                );
                                value &&
                                el['groupCodeId'] &&
                                el['academicCareerId'] &&
                                el['termId'] &&
                                requestHandler(
                                  {
                                    groupCodeId: el['groupCodeId'],
                                    companyCodeId: value,
                                    academicCareerId: el['academicCareerId'],
                                    termId: el['termId']
                                  },
                                  'GroupCompanyAcademicTerm'
                                );
                                value &&
                                updateKey(
                                  index,
                                  value,
                                  'companyCodeId',
                                  true
                                );
                              }}
                            />
                          </td>
                          <td
                            className='text-center p-2 mx-1'
                            style={{width: '200px'}}
                          >
                            <Select
                              options={{placeholder: 'Select'}}
                              data={academicCareerShow}
                              defaultValue={el['academicCareerId']}
                              onChange={(e) => {
                                let value = e.target.value;

                                value &&
                                el['groupCodeId'] &&
                                el['companyCodeId'] &&
                                requestHandler(
                                  {
                                    groupCodeId: el['groupCodeId'],
                                    companyCodeId: el['companyCodeId'],
                                    academicCareerId: value,
                                  },
                                  'GroupCompanyAcademic'
                                );
                                value &&
                                el['groupCodeId'] &&
                                el['companyCodeId'] &&
                                el['termId'] &&
                                requestHandler(
                                  {
                                    groupCodeId: el['groupCodeId'],
                                    companyCodeId: el['companyCodeId'],
                                    academicCareerId: value,
                                    termId: el['termId']
                                  },
                                  'GroupCompanyAcademicTerm'
                                );
                                value &&
                                updateKey(
                                  index,
                                  value,
                                  'academicCareerId',
                                  true
                                );
                              }}
                            />
                          </td>
                          <td
                            className='text-center p-2 mx-1'
                            style={{width: '200px'}}
                          >
                            <Select
                              options={{placeholder: 'Select'}}
                              data={termValue}
                              defaultValue={el['termId']}
                              onChange={(e) => {
                                let value = e.target.value;
                                value &&
                                el['groupCodeId'] &&
                                el['companyCodeId'] &&
                                el['academicCareerId'] &&
                                requestHandler(
                                  {
                                    groupCodeId: el['groupCodeId'],
                                    companyCodeId: el['companyCodeId'],
                                    academicCareerId: el['academicCareerId'],
                                    termId: value
                                  },
                                  'GroupCompanyAcademicTerm'
                                );
                                value &&
                                updateKey(index, value, 'termId', true);
                              }}
                            />
                          </td>
                          <td
                            className='text-center p-2 mx-1'
                            style={{width: '200px'}}
                          >
                            <Select
                              options={{placeholder: 'Select'}}
                              defaultValue={el['academicYearId']}
                              data={showAcademicYear}
                              onChange={(e) => {
                                let value = e.target.value;
                                value &&
                                updateKey(
                                  index,
                                  value,
                                  'academicYearId',
                                  true
                                );
                              }}
                            />
                          </td>
                          <td
                            className='text-center p-2 mx-1'
                            style={{width: '200px'}}
                          >
                            <Select
                              disabled
                              options={{placeholder: 'Select'}}
                              defaultValue={el['courseAttributeId']}
                              data={courseAttribute}
                            />
                          </td>
                          <td
                            className='text-center p-2 mx-1'
                            style={{width: '200px'}}
                          >
                            <Select
                              options={{placeholder: 'Select'}}
                              data={mappedCourseAttributeValue}
                              value={el['attributeValueId']}
                              onChange={(e) => {
                                let value = e.target.value;
                                value &&
                                updateKey(
                                  index,
                                  value,
                                  'attributeValueId',
                                  true
                                );
                              }}
                            />
                          </td>
                          <td
                            className='text-center p-2 mx-1'
                            style={{width: '200px'}}
                          >
                            <Select
                              options={{placeholder: 'Select'}}
                              data={academicPhase}
                              defaultValue={el['academicPhaseId']}
                              onChange={(e) => {
                                let value = e.target.value;
                                value &&
                                updateKey(
                                  index,
                                  value,
                                  'academicPhaseId',
                                  true
                                );
                              }}
                            />
                          </td>
                          <td
                            className='text-center p-2 mx-1'
                            style={{width: '170px'}}
                          >
                            <CustomDatePicker 
                              value={
                                el.startDate?moment(el['startDate']).toDate():''
                              }
                              index={index}
                              handler={updateKey}
                              keyName='startDate'
                              rangeFrom={1950}
                              rangeTo={2099}
                              placeholder="Example:01-01-2020"
                          />
                            {/* <Input
                              type='date'
                              className='tdInput'
                              defaultValue={moment(el['startDate']).format(
                                'YYYY-MM-DD'
                              )}
                              onChange={(e) => {
                                updateKey(
                                  index,
                                  e.target.value,
                                  'startDate',
                                  true
                                );
                              }}
                            /> */}
                          </td>
                          <td
                            className='text-center p-2 mx-1'
                            style={{width: '170px'}}
                          >
                              <CustomDatePicker 
                                value={
                                  el.endDate?moment(el['endDate']).toDate():''
                                }
                                index={index}
                                handler={updateKey}
                                keyName='endDate'
                                rangeFrom={1950}
                                rangeTo={2099}
                                placeholder="Example:01-01-2020"
                            />
                            {/* <Input
                              type='date'
                              className='tdInput'
                              defaultValue={moment(el['endDate']).format(
                                'YYYY-MM-DD'
                              )}
                              onChange={(e) => {
                                updateKey(
                                  index,
                                  e.target.value,
                                  'endDate',
                                  true
                                );
                              }}
                            /> */}
                          </td>
                          <td className='text-center p-2 mx-1'>
                            <label className='custom-toggle mx-auto ml-2'>
                              <input
                                data-testid='activeInactive'
                                checked={
                                  el.status == 'ACTIVE' ? true : false
                                }
                                type='checkbox'
                                onChange={(e) => {
                                  settableDataKey(
                                    new Date().getMilliseconds()
                                  );
                                  let n = 'ACTIVE';
                                  if (!e.target.checked) {
                                    n = 'INACTIVE';
                                  }
                                  updateKey(index, n, 'status');
                                }}
                              />
                              <span
                                style={{width: '72px'}}
                                className='custom-toggle-slider rounded-circle activeToggle'
                                data-label-off='Inactive'
                                data-label-on='Active'
                              />
                            </label>
                          </td>
                          <td className='text-center'>
                            {isSaveVisible ? (
                              <>
                                {' '}
                                <Button
                                  data-testid='saveRow'
                                  color='info'
                                  size='sm'
                                  type='button'
                                  className='mx-1'
                                  onClick={() => {
                                    saveAll();
                                  }}
                                >
                                  Save
                                </Button>
                                <Button
                                  data-testid='closeRow'
                                  color='danger'
                                  size='sm'
                                  type='button'
                                  onClick={() => {
                                    let n = props.classArray;
                                    if (n[index]['__status'] == '__new') {
                                      n.splice(index, 1);
                                    } else {
                                      props.fetchClasses(
                                        `${masterServiceBaseUrl}/academicPhaseMappingMaster/getAllAcademicPhaseMappingMaster`,
                                        'TABLE_RECORDS'
                                      );
                                    }
                                    props.setclassArray(n);
                                    setisSaveVisible(false);
                                  }}
                                >
                                  {' '}
                                  <i className='fas fa-times'/>
                                </Button>{' '}
                              </>
                            ) : (
                              <Button
                                data-testid='deleteRow'
                                color='info'
                                size='sm'
                                type='button'
                                onClick={() => {
                                  deleteRow(index);
                                }}
                              >
                                {' '}
                                <i className='fas fa-trash'/>
                              </Button>
                            )}
                          </td>
                        </tr>
                      )
                    )
                    : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default AcademicPhaseMappingTable;
