import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';

export const LoginDetails = (props) => {
  const { externalOrganzationObj } = props;

  useEffect(() => {
  }, [externalOrganzationObj]);

  return (
    <div className="container-fluid">
      <Row style={{ fontSize: '15px' }}>
        <Col md={6} xs={12}>
          <Row className="mb-2">
            <Col md={6} xs={6}>
              <strong>Created Date</strong>
            </Col>
            :
            <Col md={5} xs={5}>
              {externalOrganzationObj['lastUpdatedOPRID']}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={6} xs={6}>
              <strong>Last Updated Date</strong>
            </Col>
            :
            <Col md={5} xs={5}>
              {externalOrganzationObj['lastUpdatedDTTM']}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
