import { Box, Typography } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import CustomButton from '../../../../../components/CustomButton';
import CommonInput from '../../common/formFeilds/input/commonInput';
import APopover from "../../common/pop-hover/APophover";
import closeIcon from 'assets/img/svg/closeSquare.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getApprovalRequests, getOtherChargesForApproval } from '../../../../../redux/concessionApproval/actions';
import LeftSideFilterForm from "../../edpOps/studentSerach/LeftSideFilterForm";
import { useRegionAndBusinessArea } from "../../common/utils/customHooks";
import AButton from "../../common/form-fields-mui/AButton";
import { fetchAllPostPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../common/constant';
import { getFileUrl } from './common';

const outlineButtonStyle = {
    border: "1px solid #dee2e6",
    height: "40px",
    fontSize: "14px",
    fontWeight: "400",
    padding: "17px 8px",
    borderRadius: "4px",
    color: '#8898aa',
    margin: '0 0 18px 0 !important',
    width: '100%',
    justifyContent: 'flex-start'
}

const filledButtonStyle = {
    ...outlineButtonStyle,
    color: '#00B0F5',
    border: "1px solid #00B0F5",
}

const ApprovalFilter = ({ userPermissions, stage, setAppliedFilters, isOtherChargesListing,appliedFilters ,setAppliedPsid }, ref) => {
    const [anchorFilterEl, setAnchorFilterEl] = useState(null);
    const [courseCategory, setCourseCategory] = useState([]);
    const [requestedOnTo, setRequestedOnTo] = useState(null);
    const [requestedOnFrom, setRequestedOnFrom] = useState(null);
    const [regions, setRegions] = useState([]);
    const [businessArea, setbusinessArea] = useState([]);

    const resetFilters = () => {
        setCourseCategory([]);
        setRequestedOnTo(null);
        setRequestedOnFrom(null);
        setRegions([]);
        setbusinessArea([]);
    }

    useImperativeHandle(ref, ()=>({
        resetFilters : resetFilters
    }))

    const dispatch = useDispatch();

    const approvalState = useSelector(state => state.concessionApproval);

    const applyFilter = () => {
        if (isOtherChargesListing) {
            const req = {
                "psid": "",
                "approvalStatus": stage,
                "requestedOnTo": requestedOnTo,
                "requestedOnFrom": requestedOnFrom,

                "businessArea": businessArea?.length 
                ? businessArea.map(item => item.label).filter(ba => ba !== 'Select All') 
                : regions?.length 
                ? getBusinessareaOption().map(item => item.label).filter(ba => ba !== 'Select All') 
                : approvalState.allBusinessAreas.map(i => i.label),

                "chargeType": courseCategory.map(item => item.value)
            }
            setAppliedFilters(req)
            setAppliedPsid('');
            dispatch(getOtherChargesForApproval(req, 0))
            setAnchorFilterEl(null);
        } else {
            const req = {
                // roleId: approvalState.approverType.value,
                approvalStatus: `${approvalState.approverType.value}_${stage.toString()?.toUpperCase()}`,

                businessAreaValue: businessArea?.length 
                ? businessArea.map(item => item.label).filter(ba => ba !== 'Select All')
                : regions?.length 
                ? getBusinessareaOption().map(item => item.label).filter(ba => ba !== 'Select All')
                : [],

                courseCategoryValue: courseCategory.map(item => item.label),
                requestedOnTo,
                requestedOnFrom
            }
            setAppliedFilters(req)
            setAppliedPsid('');
            dispatch(getApprovalRequests(req, 0));
            setAnchorFilterEl(null);
        }

    }

    const removeFilter = () => {
        if (isOtherChargesListing) {
            const req = {
                "psid": "",
                "approvalStatus": stage,
                // "requestedOnTo": requestedOnTo,
                // "requestedOnFrom": requestedOnFrom,
                "businessArea": approvalState.allBusinessAreas.map(i => i.label),
                // "chargeType": courseCategory.map(item => item.value)
            }
            setAppliedFilters(req)
            dispatch(getOtherChargesForApproval(req, 0))
            setAnchorFilterEl(null);
        } else {
            const req = {
                // roleId: approvalState.approverType.value,
                approvalStatus: `${approvalState.approverType.value}_${stage.toString()?.toUpperCase()}`,
                // businessAreaValue: businessArea.map(item => item.label).filter(ba => ba !== 'Select All'),
                businessAreaValue: approvalState.allBusinessAreas.map(i => i.label)
                // courseCategoryValue: courseCategory.map(item => item.label),
                // requestedOnTo,
                // requestedOnFrom
            }
            setAppliedFilters(req)
            dispatch(getApprovalRequests(req, 0));
            setAnchorFilterEl(null);
        }
        setbusinessArea([])
        setRegions([])
        setRequestedOnFrom(null);
        setCourseCategory([]);
        setRequestedOnTo(null)
    }

    const getBusinessareaOption = () => {
        if (regions?.length > 0) {
            const regionList = regions.map(i => i.value)
            let matchedBusinessArea = [];
            approvalState.allBusinessAreas.map((item) => {
                if (regionList?.filter(value => item?.regionId?.includes(value)).length) {
                    matchedBusinessArea.push(item)
                }
            })
            return [{ label: 'Select All', value: "*" }, ...matchedBusinessArea];

        } else {
            return [];
        }
    }

    const onExport = async () => {
        const request = {
            roleId: approvalState.approverType.value,
            status: stage,
            businessArea: businessArea.map(item => item.value).filter(ba => ba !== '*'),
            courseCategoryId: courseCategory.map(item => item.value),
            requestedOnTo,
            requestedOnFrom
        }

        try {
            const response = await fetchAllPostPromisedData(`${endpoint.approval.export}`, request, 'post');
            if (response.code === 200) {
                const downloaded = await getFileUrl(response?.data?.['Template Url']);
                window.open(downloaded, '_BLANK')
            } else {
            }
        } catch (err) {
        }
    }

    const isDisabled = regions.length === 0 && businessArea.length === 0 && courseCategory.length === 0 && requestedOnTo === null && requestedOnFrom === null;

    return (
        <>
            <Box onClick={(event) => {
                setAnchorFilterEl(event.currentTarget);
            }}>
                <CustomButton
                    className=''
                    content={'Filter'}
                    permissionType={'R'}
                    permissionSet={userPermissions}
                    forTable={true}
                    outline
                    onClick={() => { }}
                    disabled={false}
                />
            </Box>
            {/* {!isOtherChargesListing ?  <CustomButton
                type={"download2"}
                className={`mx-1`}
                permissionType={'R'}
                color="info"
                icon={true}
                forTable={true}
                content={'Export'}
                outline={true}
                onClick={onExport}
                permissionSet={userPermissions}
            /> : null} */}


            {/** Filter Popover */}


            <APopover
                handleClose={() => setAnchorFilterEl(null)}
                id="concession-approver-screen-filter-1"
                anchorEl={anchorFilterEl}
            >
                <Box className='concession-approval-dialog-content approval-filter-dialog' width={350}>
                    <Box className='approval-dialog-header'>
                        <Typography variant='h6'>Filter</Typography>
                        <div onClick={() => setAnchorFilterEl(null)}><img src={closeIcon} /></div>
                    </Box>
                    <CommonInput
                        placeHolder={'Select regions'}
                        type={"multiSelectWithCheckboxes"}
                        label={"Region"}
                        defaultValue={regions}
                        onChange={(val) => { setRegions(val); setbusinessArea([]); }}
                        data={approvalState.allRegions}
                    />
                    <CommonInput
                        type={"multiSelectWithCheckboxes"}
                        label={"Business area"}
                        placeHolder={"Select business area"}
                        defaultValue={businessArea}
                        onChange={(val) => { setbusinessArea(val); }}
                        data={getBusinessareaOption()}
                    />
                    <CommonInput
                        placeHolder={'Select course category'}
                        type={'multiSelectWithCheckboxes'}
                        data={approvalState.allCourseCategories}
                        defaultValue={courseCategory}
                        label={'Course category'}
                        onChange={(val) => {
                            setCourseCategory(val);
                        }}
                    />
                    <Box className='approval-requested-date'>
                        <CommonInput
                            className='mr-1'
                            placeHolder={'Date'}
                            type={'datePicker'}
                            data={approvalState.allCourseCategories}
                            defaultValue={requestedOnFrom}
                            label={'From Requested on'}
                            onChange={(val) => {
                                setRequestedOnFrom(val);
                            }}
                        />
                        <CommonInput
                            placeHolder={'Date'}
                            type={'datePicker'}
                            data={approvalState.allCourseCategories}
                            defaultValue={requestedOnTo}
                            label={'To Requested on'}
                            onChange={(val) => {
                                setRequestedOnTo(val);
                            }}
                        />
                    </Box>
                    {/* <CustomButton
                        className='approval-dialog-action-button'
                        type={'C'}
                        content={'Apply'}
                        permissionType={'C'}
                        permissionSet={userPermissions}
                        onClick={applyFilter}
                        disabled={isDisabled}
                    /> */}
                    <Box className='approval-requested-date'>
                        <AButton
                            onClick={removeFilter}
                            updatedStyle={{
                                background: "#CC5F5F",
                                border: "1px solid #CC5F5F",
                                borderRadius: "2px",
                                color: "#FFFFFF",
                                marginTop: ".3rem",
                                marginRight: ".4rem",
                                marginLeft: "0rem",
                                width: '100%',
                                cursor: !Object.keys(appliedFilters)?.length ? 'not-allowed' : 'pointer'
                            }}
                            disabled={!Object.keys(appliedFilters)?.length}
                            className="no-ml-btn"
                        >
                            Remove
                        </AButton>
                        <AButton
                            onClick={applyFilter}
                            updatedStyle={{
                                background: "#00B0F5",
                                border: "1px solid #00B0F5",
                                borderRadius: "2px",
                                color: "#fff",
                                marginTop: ".3rem",
                                width: '100%',
                                cursor: isDisabled ? 'not-allowed' : 'pointer'
                            }}
                            disabled={isDisabled}
                        >
                            Apply
                        </AButton>
                        {/* <CustomButton
                            className='approval-dialog-action-button'
                            type={'R'}
                            content={'Remove'}
                            permissionType={'R'}
                            permissionSet={userPermissions}
                            onClick={applyFilter}
                            disabled={isDisabled}
                        />
                        <CustomButton
                            className='approval-dialog-action-button'
                            type={'R'}
                            content={'Apply'}
                            permissionType={'R'}
                            permissionSet={userPermissions}
                            onClick={applyFilter}
                            disabled={isDisabled}
                        /> */}
                    </Box>
                </Box>
            </APopover>

            {/** BA and region popup */}

            {/* <APopover handleClose={() => setAnchorRegionEl(null)} id="approver-screen-ba-region" anchorEl={anchorRegionEl}>
                <LeftSideFilterForm
                    filterForm={regionAndBusiness}
                    inputHandler={inputHandler}
                    searchHandler={() => setAnchorRegionEl(null)}
                    clrBusinessAreaHandler = {()=>inputHandler([],"businessArea")}
                />
            </APopover> */}
        </>
    )
}

export default forwardRef(ApprovalFilter);