import React from 'react'
import { useHistory } from 'react-router'
import { Card, CardBody } from 'reactstrap'
import AButton from '../../common/form-fields-mui/AButton'
import { courseCardHeadingStyles } from './utils'
import ShowLectureCount from './ShowLectureCount';

const GridViewCourseCard = ({ courseIcon, courseData = {}, viewAddLink = `` ,lectureCountByDuration }) => {

  const history = useHistory();
  let  subject = courseData['subjectName'].toUpperCase()
  let  lectureCount =  lectureCountByDuration[subject]
  return (
    <Card className='mb-0'>
      <CardBody>
        <div className='d-flex justify-content-between'>
          <div>
            <h3 style={courseCardHeadingStyles}>{courseData?.subjectName}</h3>
            <p style={{ fontSize: '14px', color: '#757575' }}>Add lecture here.</p>
          </div>
          <span>{courseIcon}</span>
        </div>
        <ShowLectureCount lectureDuration = {lectureCount} />
       
        <div>
          <AButton
            variant='link'
            updatedStyle={{ height: '24px' }}
            className='button_remove_left_margin'
            onClick={() => history.push(viewAddLink)}
          >
            View/Add lectures
          </AButton>
        </div>
      </CardBody>
    </Card>
  )
}

export default GridViewCourseCard