import React, {Fragment, useState} from 'react';
import { Card, CardBody} from "reactstrap";
import RelatedDocumentHeader from "./relatedDocumentHeader";
import {connect} from "react-redux";
import DocumentTable from "./documentTable";
import {failureToast} from "../../../../../../../services/http";

const RelatedDocumentApplicationId =(props)=>{
    const [isUploading, setISUploading] = useState(false);
    const [showModel, setModel] = useState(false);
    const [file, setFile] = useState(null);
    const [progressBarStatus, setProgressBarStatus] = useState(0);
    const handleFile = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };



    const chkFile = () => {
        if (!file) {
            setProgressBarStatus(0);
            failureToast('Please select file');
            return;
        }
        setISUploading(true);
        setProgressBarStatus(10);
        // getAPI(
        //     `${pricingServiceBaseUrl}/validateFile?filename=${file.name}`,
        //     (data) => {
        //         if (data.code === 200) {
        //             checkForLocation();
        //             setProgressBarStatus(25);
        //         }
        //         // submitOnAws()
        //     },
        //     (data) => {
        //         failureToast(
        //             'Please, modify the file name and upload again as a file with same name is already exist in DB.'
        //         );
        //         setISUploading(false);
        //         setProgressBarStatus(0);
        //     }
        // );
    };


    return(
        <Fragment>
            <Card className="card-plain" style={{margin:'20px 15px'}}>
                <CardBody>
                    <RelatedDocumentHeader data={props.headerData}/>
                </CardBody>
            </Card>
            <DocumentTable chkFile={chkFile} isUploading={isUploading} file={file} setFile={setFile} progressBarStatus={progressBarStatus} handleFile={handleFile} showModel={showModel}/>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        headerData: state.globalData.data,
    };
};
export default connect(mapStateToProps)(RelatedDocumentApplicationId);
