import React, { useState } from "react";
import { Button, Card, CardBody, CardHeader, Input, Table } from "reactstrap";
import Select1 from "react-select";
import MasterHeader from "./tableHeader";
import NoDataFound from "../../../common/noDataFound";
import ExportToExcel from "../../../common/commonComponents/ToExcel";
import moment from "moment";
import Style from "./tableHeader.module.scss";
import { failureToast, successToast } from "../../../common/utils/methods/toasterFunctions/function";
import TableList from "../../../common/tableList";
import { regex } from "../../../common/constant";
import { enrollmentServiceBaseUrl, examControllerServiceBaseUrl, masterServiceBaseUrl, putpost } from "services/http";
import { findObjectById, getUniqueKey } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { CustomInput, ReactSelectDropdown } from "../../../common/formFeilds";
import { CustomUpload, Dialog, GetTemplate } from "../../../common/commonComponents";
import Loader from "react-loader-spinner";
import CustomDatePicker from "../../../common/commonComponents/CustomDatePicker";
import { endpoint } from "../../../common/constant";

var _ = require("lodash");

const TableComponent = (props) => {
  const {
    fetchDetails,
    studentResultTable,
    setStudentResultTable,
    examBoard,
    examYear,
    category,
    allRecords,
    searchResult,
    setSearchResult,
    searchValues,
    isSaveVisible,
    setIsSaveVisible,
    searchApiHandler,
    state,
  } = props;
  const [studentName, setStudentName] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);
  const [isFileValid, setISFileValid] = useState(false);
  const [filename, setFileName] = useState("Drag & Drop to Upload");
  const [file, setFile] = useState(null);
  const [progressBarStatus, setProgressBarStatus] = useState(0);
  const [isUploading, setISUploading] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);

  const addNewClass = () => {
    setSearchResult([
      {
        editMode: true,
        __status: "__new",
        studentId: null,
        examBoard: null,
        examYear: null,
        examDate: null,
        examRollNumber: null,
        category: null,
        totalScore: null,
        air: null,
        stateRank: null,
        categoryRank: null,
        stateCategoryRank: null,
        status: "ACTIVE",
      },
      ...searchResult,
    ]);
  };

  const listDetails = {
    studentId: {
      normal: true,
    },
    studentName: {
      normal: true,
    },
    examBoard: {
      normal: false,
      master: allRecords?.examBoard,
      displayValue: "examBoardTypeDispValue",
      idName: "examBoard",
    },
    examYear: {
      normal: false,
      master: allRecords?.examYear,
      displayValue: "academicYearDisplayValue",
      idName: "examYear",
    },
    examDate: {
      normal: true,
      master: searchResult,
      displayValue: "examDate",
      type: "date",
      format: "DD-MM-YYYY",
    },
    examRollNumber: {
      normal: true,
      master: searchResult,
    },
    category: {
      normal: false,
      master: allRecords?.category,
      displayValue: "categoryDispValue",
      idName: "category",
    },
    totalScore: {
      normal: true,
      master: searchResult,
    },
    air: {
      normal: true,
      master: searchResult,
    },
    stateRank: {
      normal: true,
      master: searchResult,
    },
    categoryRank: {
      normal: true,
      master: searchResult,
    },
    stateCategoryRank: {
      normal: true,
      master: searchResult,
    },
    effectiveDate: {
      normal: true,
      master: searchResult,
      displayValue: "effectiveDate",
      type: "date",
      format: "DD-MM-YYYY",
    },
  };

  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        addNewClass();
        setIsSaveVisible(true);
      };
      var _tempArray = searchResult;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      setSearchResult(_tempArray);
      setIsSaveVisible(false);
      cb();
    }
  };

  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        var _tempArray = searchResult;
        _tempArray[index]["editMode"] = true;
        setSearchResult(_tempArray);
        setIsSaveVisible(true);
      };
      var _tempArray = searchResult;
      selectHandler(_tempArray[index]["studentId"]);
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };

  // const deleteRow = (index) => {
  //   try {
  //     let _tempArray = searchResult;
  //     _tempArray.splice(index, 1);
  //     setSearchResult(_tempArray);
  //     setIsChanged(!isChanged);
  //   } catch (e) {
  //     console.log("Error : ", e);
  //   }
  // };

  const handleFile = (e) => {
    try {
      if (e.name.split(".")[1] === "csv") {
        setFileName(e.name);
        setFile(e);
        setISFileValid(true);
      } else {
        failureToast("Only csv files are allowed");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const dataToServer = {
    bulk: true,
    fileName: "External Test Result",
    docType: "csv",
    keyValue: `extTest_${getUniqueKey()}`,
    serviceName: "MASTER",
    subProcessType:"EXTERNALTEST"
  };

  const saveFileAndReturnData = (data) => {
    putpost(
      endpoint.externalTestResult.bulkUpload,
      (data) => {
        setProgressBarStatus(100);
        successToast("File Uploaded Successfully");
        setModalVisible(!isModalVisible);
        reset();
      },
      (data) => {
        failureToast("Uploading Failed");
        setModalVisible(!isModalVisible);
        reset();
      },
      {
        csvUrl: data.Location,
        processInstanceId: data.processId,
      },
      "post"
    );
  };

  const reset = () => {
    setISFileValid(false);
    setFileName("Drag & Drop to Upload");
    setFile(null);
    setProgressBarStatus(0);
    setISUploading(false);
  };

  const saveAll = (index) => {
    try {
      const _temp = searchResult;
      _temp.forEach((element, index) => {
        if (element["editMode"]) {
          // console.log("Element: ", element);
          if (!element?.studentId) return failureToast("Please Select Student ID");
          if (!element?.examBoard) return failureToast("Please Select Exam Board");
          if (!element?.examYear) return failureToast("Please Select Exam Year");
          if (!element?.examDate) return failureToast("Please Select Exam Date");
          if (!element?.examRollNumber) return failureToast("Please Enter Exam Roll No.");
          if (!element?.examDate) return failureToast("Please Select Exam Date");
          if (!element?.examRollNumber) return failureToast("Please Enter Roll No.");
          if (!element?.category) return failureToast("Please Select Category");
          if (!element?.totalScore) return failureToast("Please Enter Total Score");
          if (!element?.air) return failureToast("Please Enter AIR");
          if (!element?.stateRank) return failureToast("Please Enter State Rank");
          if (!element?.categoryRank) return failureToast("Please Enter Category Rank");
          if (!element?.stateCategoryRank) return failureToast("Please Enter State Category Rank");

          setSaveClicked(true);

          if (_temp[index]["__status"] === "__new") {
            putpost(
              examControllerServiceBaseUrl + `/externalTestResult/createExternalTestResultDetails`,
              (data) => {
                setStudentResultTable([]);
                setStudentName("");
                setIsSaveVisible(false);
                if (searchValues?.examBoard) {
                  searchApiHandler();
                } else {
                  setSearchResult([]);
                }
                setSaveClicked(false);
                successToast(data["message"]);
              },
              (data) => {
                failureToast(data["message"]);
                setSaveClicked(false);
              },
              _temp[index],
              "post"
            );
          } else {
            putpost(
              examControllerServiceBaseUrl + `/externalTestResult/updateExternalTestResultDetails/` + _temp[index].id,
              (data) => {
                setStudentResultTable([]);
                setStudentName("");
                searchValues?.examBoard && searchApiHandler();
                setIsSaveVisible(false);
                successToast(data["message"]);
                setSaveClicked(false);
              },
              (data) => {
                failureToast(data["message"]);
                setSaveClicked(false);
              },
              _temp[index],
              "put"
            );
          }
        }
      });
      // console.log("Save value for the server", _temp[index]);
    } catch (e) {
      console.log("Error", e);
    }
  };

  const updateKey = (index, value, key) => {
    const _tempArray = searchResult;
    _tempArray[index][key] = value;
    setSearchResult(_tempArray);
  };

  const selectHandler = (studentID) => {
    try {
      const studentDetail = findObjectById(allRecords.studentDetails, "studentId", studentID);
      setStudentName(studentDetail.studentFirstName + " " + studentDetail.studentLastName);
    } catch (e) {
      console.log("Error", e);
    }
  };

  const excelHandler = (arr, keyName, id, displayValue) => {
    const value = findObjectById(arr, keyName, id);
    return value ? value[displayValue] : "";
  };

  const excelDetails = {
    "Student ID": "studentId",
    "Student Name": "studentName",
    "Exam Board": "examBoard",
    "Exam Year": "examYear",
    "Exam Date": "examDate",
    "Exam Roll Number": "examRollNumber",
    Category: "category",
    "Total Score": "totalScore",
    AIR: "air",
    "State Rank": "stateRank",
    "Category Rank": "categoryRank",
    "State Category Rank": "stateCategoryRank",
    "Effective Date": "effectiveDate",
  };

  const exportData = state?.map((element, index) => {
    return {
      ...element,
      studentId: element?.studentId,
      studentName: element?.studentName,
      examBoard: excelHandler(allRecords?.examBoard, "id", element?.examBoard, "examBoardTypeDispValue"),
      examYear: excelHandler(allRecords?.examYear, "id", element?.examYear, "academicYearDisplayValue"),
      examDate: element?.examDate ? moment(element?.examDate).format("DD-MM-YYYY") : "",
      examRollNumber: element?.examRollNumber,
      category: excelHandler(allRecords?.category, "id", element?.category, "categoryDispValue"),
      totalScore: element?.totalScore,
      air: element?.air,
      stateRank: element?.stateRank,
      categoryRank: element?.categoryRank,
      stateCategoryRank: element?.stateCategoryRank,
      effectiveDate: moment(element?.effectiveDate).format("DD-MM-YYYY"),
      status: element?.status,
    };
  });

  return (
    <>
      <Dialog
        title="External Test Result Upload"
        isModalVisible={isModalVisible}
        toggle={() => {
          setModalVisible(!isModalVisible);
          reset();
        }}
      >
        <CustomUpload
          documentType="External Test Result"
          isFileValid={isFileValid}
          filename={filename}
          file={file}
          progressBarStatus={progressBarStatus}
          setISFileValid={setISFileValid}
          setFileName={setFileName}
          setFile={setFile}
          setProgressBarStatus={setProgressBarStatus}
          isUploading={isUploading}
          setISUploading={setISUploading}
          handleFile={handleFile}
          dataToServer={dataToServer}
          saveHandler={saveFileAndReturnData}
        />
      </Dialog>
      <Card>
        <CardHeader className=" d-flex justify-content-between">
          <h2>Test Details</h2>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className={"mx-2"}>
              <ExportToExcel data={exportData} detailObj={excelDetails} name="Test Result" />
            </div>
            <GetTemplate
            url={endpoint.externalTestTemplate}
            />
            <Button color="info" size="sm" type="button" className={"floatRight"} onClick={() => setModalVisible(true)}>
              <i className="fas fa-upload" />
              &nbsp;&nbsp;Upload CSV
            </Button>
            <Button color="info" size="sm" type="button" className={"floatRight"} onClick={() => newRow()}>
              <i className="fas fa-plus" />
              &nbsp;&nbsp;Add New
            </Button>
          </div>
        </CardHeader>
        <CardBody className={"p-0"}>
          <div id="questionTable" className="table-responsive" style={{ overflow: "auto", minHeight: "300px" }}>
            <Table className={`align-items-center table-flush ${Style.table__behaviour}`}>
              <MasterHeader />
              <tbody className="list">
                {searchResult && searchResult.length > 0 ? (
                  searchResult.map((element, index) =>
                    !element["editMode"] ? (
                      <>
                        <TableList item={element} listDetails={listDetails} isStatus={false}>
                          <td className="text-center" style={{ width: "200px" }}>
                            <Button color="info" size="sm" type="button" className={"mx-1"} onClick={() => editRow(index)}>
                              <i className="fas fa-edit" />
                            </Button>
                          </td>
                        </TableList>
                      </>
                    ) : (
                      <>
                        <tr key={index + "-class"} className={`${Style.table__row}`}>
                          <td>
                            {element.hasOwnProperty("__status") ? (
                              <Select1
                                isDisabled={saveClicked}
                                id="studentDetail"
                                options={studentResultTable}
                                placeholder="Enter Student ID"
                                onInputChange={(e) => {
                                  e &&
                                    e.length > 2 &&
                                    fetchDetails(
                                      `${enrollmentServiceBaseUrl}/studentDetails/getAllStudentBySmartSearch/${e}`,
                                      "STUDENT_RESULT_TABLE"
                                    );
                                }}
                                onChange={(e) => {
                                  selectHandler(e.value);
                                  updateKey(index, e.value, "studentId");
                                }}
                              />
                            ) : (
                              <Input disabled value={element?.studentId} />
                            )}
                          </td>
                          <td>
                            {element.hasOwnProperty("__status") ? (
                              <CustomInput isDisabled={true} defaultValue={studentName} index={index} keyName={"studentName"} handler={updateKey} />
                            ) : (
                              <CustomInput
                                isDisabled={true}
                                defaultValue={element?.studentName}
                                index={index}
                                keyName={"studentName"}
                                handler={updateKey}
                              />
                            )}
                          </td>
                          <td>
                            <ReactSelectDropdown
                              disabled={saveClicked}
                              options={examBoard}
                              placeholder="Select Exam Board"
                              id={element?.examBoard}
                              dropdownHandler={updateKey}
                              keyName={"examBoard"}
                              index={index}
                            />
                          </td>
                          <td>
                            <ReactSelectDropdown
                              disabled={saveClicked}
                              options={examYear}
                              placeholder="Select Exam Year"
                              id={element?.examYear}
                              dropdownHandler={updateKey}
                              keyName={"examYear"}
                              index={index}
                            />
                          </td>
                          <td>
                            <CustomDatePicker
                              disabled={saveClicked}
                              placeholder={"Select Exam Date"}
                              handler={updateKey}
                              keyName={"examDate"}
                              index={index}
                              value={element["__status"] == "__new" ? "" : moment(element?.examDate).toDate()}
                              rangeFrom={1950}
                              rangeTo={2099}
                            />
                          </td>
                          <td>
                            <CustomInput
                              defaultValue={element?.examRollNumber}
                              maxLength={25}
                              minLength={1}
                              index={index}
                              placeHolder={"Enter Roll Number"}
                              regex={regex.alphaNumeric}
                              regValidation={true}
                              keyName={"examRollNumber"}
                              handler={updateKey}
                              isDisabled={saveClicked}
                            />
                          </td>
                          <td>
                            <ReactSelectDropdown
                              options={category}
                              placeholder="Select Category"
                              id={element?.category}
                              dropdownHandler={updateKey}
                              keyName={"category"}
                              index={index}
                              disabled={saveClicked}
                            />
                          </td>
                          <td>
                            <CustomInput
                              defaultValue={element?.totalScore}
                              type={"text"}
                              maxLength={10}
                              minLength={1}
                              index={index}
                              placeHolder={"Enter Total Score"}
                              regex={regex.numeric}
                              regValidation={true}
                              keyName={"totalScore"}
                              handler={updateKey}
                              isDisabled={saveClicked}
                            />
                          </td>
                          <td>
                            <CustomInput
                              defaultValue={element?.air}
                              type={"text"}
                              maxLength={10}
                              minLength={1}
                              index={index}
                              placeHolder={"Enter AIR"}
                              regex={regex.numeric}
                              regValidation={true}
                              keyName={"air"}
                              handler={updateKey}
                              isDisabled={saveClicked}
                            />
                          </td>
                          <td>
                            <CustomInput
                              defaultValue={element?.stateRank}
                              type={"text"}
                              maxLength={10}
                              minLength={1}
                              index={index}
                              placeHolder={"Enter State Rank"}
                              regex={regex.numeric}
                              regValidation={true}
                              keyName={"stateRank"}
                              handler={updateKey}
                              isDisabled={saveClicked}
                            />
                          </td>
                          <td>
                            <CustomInput
                              defaultValue={element?.categoryRank}
                              type={"text"}
                              maxLength={10}
                              minLength={1}
                              index={index}
                              placeHolder={"Enter Category Rank"}
                              regex={regex.numeric}
                              regValidation={true}
                              keyName={"categoryRank"}
                              handler={updateKey}
                              isDisabled={saveClicked}
                            />
                          </td>
                          <td>
                            <CustomInput
                              defaultValue={element?.stateCategoryRank}
                              type={"text"}
                              maxLength={10}
                              minLength={1}
                              index={index}
                              placeHolder={"Enter State Category Rank"}
                              regex={regex.numeric}
                              regValidation={true}
                              keyName={"stateCategoryRank"}
                              handler={updateKey}
                              isDisabled={saveClicked}
                            />
                          </td>
                          <td>
                            <CustomInput type={"text"} isDisabled={true} defaultValue={moment().format("DD-MM-YYYY")} />
                          </td>
                          {/*<td>*/}
                          {/*  <CustomToggle*/}
                          {/*    status={element?.status}*/}
                          {/*    updateKey={updateKey}*/}
                          {/*    index={index}*/}
                          {/*    setActive={setIsActive}*/}
                          {/*    isActive={isActive}*/}
                          {/*    keyName={"status"}*/}
                          {/*    handler={updateKey}*/}
                          {/*  />*/}
                          {/*</td>*/}
                          <td className="text-center">
                            <>
                              {" "}
                              <Button
                                data-testid="saveRow"
                                color="info"
                                size="sm"
                                type="button"
                                className="mx-1"
                                onClick={() => {
                                  setIsSaveVisible(true);
                                  saveAll(index);
                                }}
                              >
                                {saveClicked ? <Loader type="TailSpin" width={30} height={20} color={"#ffffff"} /> : <span>Save</span>}
                              </Button>
                              <Button
                                data-testid="closeBtn"
                                color="danger"
                                size="sm"
                                type="button"
                                onClick={() => {
                                  let n = searchResult;
                                  if (n[index]["__status"] == "__new") {
                                    n.splice(index, 1);
                                  } else {
                                    searchValues?.examBoard && searchApiHandler();
                                  }
                                  setSearchResult(n);
                                  setStudentName("");
                                  setStudentResultTable([]);
                                  setIsSaveVisible(false);
                                }}
                              >
                                {" "}
                                <i className="fas fa-times" />
                              </Button>{" "}
                            </>
                          </td>
                        </tr>
                      </>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan="15">
                      <NoDataFound />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {/* {searchResult.length !== 0 && !isSaveVisible ? (
          <div className={"text-right p-4"}>
            <Button color={"info"}>Save</Button>
            <Button color={"info"}>Cancel</Button>
          </div>
        ) : null} */}
        </CardBody>
      </Card>
    </>
  );
};

export default TableComponent;
