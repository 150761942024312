import React, { useContext } from "react";
import {
  useGetDlpModuleContext,
  DlpModuleProvider,
} from "../contextApi/dlpContext";
import { pages } from "../../../common/constant";
import { PermissionContext, RolePermissions } from "appContext";
import { PermisionDenied } from "../../../common/commonComponents";
import ApplicationFilterLandingPage from "./ApplicationFilterLandingPage";
import MarkEligibleScreen from './markEligible/MarkEligibleScreen'

const pageStateMapping = {
  SEARCH_STUDENT: <ApplicationFilterLandingPage />,
  MARK_ELIGIBLE: <MarkEligibleScreen />,
};

const GetPageComponentByState = () => {
  const { currentPageState } = useGetDlpModuleContext();
  return pageStateMapping[currentPageState];
};
const DlpIssueStockManagementPage = () => {
  const userPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["dlpStockAndInventory"]["id"]
  );

  return userPermissions ? (
    <DlpModuleProvider>
      <GetPageComponentByState />
    </DlpModuleProvider>
  ) : (
    <PermisionDenied />
  );
};

export default DlpIssueStockManagementPage;
