import React, { useState, useEffect } from 'react';
import {
  ProfitCostCenterHeader,
  PROFITCOSTCENTERTHROW,
} from '../../../common/commonComponents/masterRow/masterRow';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Table,
  Input,
  Label,
} from 'reactstrap';
import Select2 from 'react-select2-wrapper';
import { GetPagination } from '../../../common/pagination';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import CustomDatePicker from '../../../common/commonComponents/CustomDatePicker/index';

import {
  getAPI,
  masterServiceBaseUrl,
  successToast,
  failureToast,
  putpost,
  deleteAPI,
} from 'services/http';

const ProfitAndCostCenter = (props) => {
  const {
    getDisplayValue,
    action,
    businessUnit,
    profitCenter,
    profitAndCostCenter,
    setProfitAndCostCenter,
    isDisabled,
    isPrevious
  } = props;
  const [isSaveVisible, setIsSaveVisible] = useState();
  const [apiLoader, setApiLoader] = useState(false);
  const [classArray, setClassArray] = useState([]);
  const [dataChange, setDataChange] = useState(false);
  const [profitCenterDescription, setProfitCenterDescription] = useState('');
  const [academicCareerDispValue, setAcademicCareerDispValue] = useState('');
  const [academicGroupDispValue, setAcademicGroupDispValue] = useState('');
  const [pagination, setPagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const pageSize = 10;

  useEffect( () => {
    if ( action === 'edit' || action === 'view' || isPrevious) {
      performPaginationForData();
    }
  }, [profitAndCostCenter] )
  
  const performPaginationForData = () => {
    try {
        setApiLoader(true);  
        setProfitAndCostCenter(profitAndCostCenter);
        let n = pagination;
        if (profitAndCostCenter.length > pageSize) {
          n['nextPage'] = n.currentPage + 1;
        }
        if (n.currentPage > 1) {
          n.previousPage = n.currentPage - 1;
        }
        n['totalPage'] = Math.floor(profitAndCostCenter.length / pageSize);
        if (profitAndCostCenter.length % pageSize != 0) {
          n['totalPage'] += 1;
        }
        setPagination(n);
        setClassArray(
          profitAndCostCenter.slice(
            pagination.currentPage * pageSize - pageSize,
            pagination.currentPage * pageSize
          ) || []
        );
        setApiLoader(false);
    } catch ( e ) {
      console.log('Error :: ',e)
    }
       
  }

  const fetchClasses = async (url) => {
    setApiLoader(true);
    getAPI(
      url,
      (data) => {
        setProfitAndCostCenter(data['data']);
        let n = pagination;
        if (data['data'].length > pageSize) {
          n['nextPage'] = n.currentPage + 1;
        }
        if (n.currentPage > 1) {
          n.previousPage = n.currentPage - 1;
        }
        n['totalPage'] = Math.floor(data['data'].length / pageSize);
        if (data['data'].length % pageSize != 0) {
          n['totalPage'] += 1;
        }
        setPagination(n);
        setClassArray(
          data['data'].slice(
            pagination.currentPage * pageSize - pageSize,
            pagination.currentPage * pageSize
          ) || []
        );
        setApiLoader(false);
      },
      (data) => {
        failureToast(data['message']);
        setApiLoader(false);
      }
    );
  };

  const nextPage = () => {
    setClassArray(
      profitAndCostCenter.slice(
        (pagination.currentPage + 1) * pageSize - pageSize,
        (pagination.currentPage + 1) * pageSize
      )
    );
    let n = pagination;
    n['currentPage'] = n['currentPage'] + 1;
    n['previousPage'] = n.currentPage;
    setPagination(n);
  };
  const firstPage = () => {
    setClassArray(
      profitAndCostCenter.slice(1 * pageSize - pageSize, 1 * pageSize)
    );
    let n = pagination;
    n['currentPage'] = 1;
    n['previousPage'] = null;
    setPagination(n);
  };
  const lastPage = () => {
    setClassArray(
      profitAndCostCenter.slice(
        pagination.totalPage * pageSize - pageSize,
        pagination.totalPage * pageSize
      )
    );
    let n = pagination;
    n['currentPage'] = n.totalPage;
    n['previousPage'] = n.totalPage - 1;
    setPagination(n);
  };
  const previousPage = () => {
    setClassArray(
      profitAndCostCenter.slice(
        (pagination.currentPage - 1) * pageSize - pageSize,
        (pagination.currentPage - 1) * pageSize
      )
    );
    let n = pagination;
    n['currentPage'] = n['currentPage'] - 1;
    if (n['currentPage'] == 1) {
      n['previousPage'] = null;
    } else {
      n['previousPage'] = n.currentPage;
    }
    setPagination(n);
  };

  const addNewRow = () => {
    try {
      setClassArray([
        {
          __status: 'new',
          editMode: true,
          businessAreaId: businessUnit,
          sapProfitCenterId: null,
          sapCostCenter: null,
          costCenterDescription: null,
          effectiveDate: null,
          status: 'ACTIVE',
        },
        ...classArray,
      ]);
      setIsSaveVisible(true);
    } catch (e) {
      console.log('Error', e);
    }
  };

  const saveAll = (index) => {
    try {
      if (!classArray[index]['businessAreaId'])
        return failureToast('Business Area ID not found');
      if (!classArray[index]['sapProfitCenterId'])
        return failureToast('Please Select SAP Profit Center');
      if (
        classArray[index]['sapCostCenter'] === null ||
        !classArray[index]['sapCostCenter'].trim()
      )
        return failureToast('Please Select SAP Cost Center');
      if (
        classArray[index]['costCenterDescription'] === null ||
        !classArray[index]['costCenterDescription'].trim()
      )
        return failureToast('Please Select Cost Center Description');
      if (classArray[index]['__status'] === 'new') {
        setApiLoader(true);
        putpost(
          masterServiceBaseUrl + '/profitAndCostMaster/createProfitAndCostCenter',
          (data) => {
            successToast(data['message']);
            // fetchClasses(
            //   masterServiceBaseUrl + `/profitAndCostMaster/getAllProfitAndCostCenter`
            // );
            fetchClasses(
              masterServiceBaseUrl +
                `/profitAndCostMaster/getProfitAndCostCenterByBusinessAreaId/${businessUnit}`
            );
            setIsSaveVisible(false);
            setProfitCenterDescription('');
            setAcademicCareerDispValue('');
            setAcademicGroupDispValue('');
            setApiLoader(false);
          },
          (data) => {
            failureToast(data['message']);
            setApiLoader(false);
          },
          classArray[index],
          'post'
        );
      } else {
        setApiLoader(true);
        putpost(
          masterServiceBaseUrl +
            `/profitAndCostMaster/updateProfitAndCostCenter/${profitAndCostCenter[index]['id']}`,
          (data) => {
            successToast(data['message']);
            // fetchClasses(
            //   masterServiceBaseUrl + `/profitAndCostMaster/getAllProfitAndCostCenter`
            // );
            fetchClasses(
              masterServiceBaseUrl +
                `/profitAndCostMaster/getProfitAndCostCenterByBusinessAreaId/${businessUnit}`
            );
            setIsSaveVisible(false);
            setProfitCenterDescription('');
            setAcademicCareerDispValue('');
            setAcademicGroupDispValue('');
            setApiLoader(false);
          },
          (data) => {
            failureToast(data['message']);
            setApiLoader(false);
          },
          classArray[index],
          'put'
        );
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  const updateRecord = (index, value, property) => {
    try {
      setApiLoader(true);
      let temp = classArray;
      temp[index][property] = value;
      if (property === 'sapProfitCenterId') {
        setProfitCenterDescription(
          getDisplayValue(value, 'profitCenterDescription')
        );
        setAcademicCareerDispValue(
          getDisplayValue(value, 'profitCenterAcademicCareerDispValue')
        );
        setAcademicGroupDispValue(
          getDisplayValue(value, 'profitCenterAcademicGroupDispValue')
        );
      }
      setClassArray(temp);
      setApiLoader(false);
    } catch (e) {
      console.log('Error', e);
    }
  };

  const editRecord = (index) => {
    try {
      let temp = profitAndCostCenter;
      temp[index]['editMode'] = true;
      setProfitCenterDescription(
        getDisplayValue(
          temp[index]['sapProfitCenterId'],
          'profitCenterDescription'
        )
      );
      setAcademicCareerDispValue(
        getDisplayValue(
          temp[index]['sapProfitCenterId'],
          'profitCenterAcademicCareerDispValue'
        )
      );
      setAcademicGroupDispValue(
        getDisplayValue(
          temp[index]['sapProfitCenterId'],
          'profitCenterAcademicGroupDispValue'
        )
      );
      setProfitAndCostCenter(temp);
      setIsSaveVisible(true);
    } catch (e) {
      console.log('Error : ', e);
    }
  };

  useEffect(() => {
    if(props.isPrevious && props.formId){
      setApiLoader(true)
      fetchClasses(`${masterServiceBaseUrl}/profitAndCostMaster/getProfitAndCostCenterByBusinessAreaId/${props.formId}`)
   }
  }, [props.isPrevious,props.formId])

  useEffect(()=>{
    return()=>{
      if(profitAndCostCenter.length){
        !props.isPrevious && props.isNew && props.saveStepsCompleted()
      }      
    }    
  },[profitAndCostCenter])

  const deleteRecord = (id, index) => {
    try {
      if (
        window.confirm(
          'Are you sure you want to delete this Profit & Cost Center ?'
        )
      ) {
        deleteAPI(
          masterServiceBaseUrl + `/profitAndCostMaster/deleteProfitAndCostCenter/${id}`,
          (data) => {
            successToast(data['message']);
            let _tempArray = profitAndCostCenter;
            let __tempArray = classArray;
            _tempArray.splice(
              index +
                (pagination.currentPage > 1
                  ? pagination.currentPage - 1 * 10
                  : index),
              1
            );
            __tempArray.splice(index, 1);
            setProfitAndCostCenter(_tempArray);
            setClassArray(__tempArray);
            setDataChange(!dataChange);
          },
          (data) => {
            failureToast(data['message']);
          }
        );
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  const closeRecord = (index) => {
    try {
      setApiLoader(true);
      let temp = classArray;
      if (temp[index]['__status'] === 'new') {
        temp.splice(index, 1);
      } else {
        delete temp[index]['editMode'];
      }
      setClassArray(temp);
      setProfitCenterDescription('');
      setAcademicCareerDispValue('');
      setAcademicGroupDispValue('');
      setIsSaveVisible(false);
      setApiLoader(false);
    } catch (e) {
      console.log('Error', e);
    }
  };
  return (
    <Container className="mt-3" fluid>
      <Row>
        <Col className={`mb-3 ${action === 'new' ? '' :'mt-3'}`}>
          {!isDisabled ? (
            <Button
              disabled={isSaveVisible}
              color="info"
              size="sm"
              type="button"
              className={
                'floatRight mx-1' + (isSaveVisible ? ' btn-dark' : null)
              }
              onClick={() => {
                addNewRow();
              }}
            >
              Add SAP Profit Center
            </Button>
          ) : null}
        </Col>
      </Row>
      {apiLoader ? (
        <div className="mx-auto text-center" style={{ height: '100px' }}>
          <Loader type="Rings" color="#00BFFF" height={70} width={70} />
        </div>
      ) : (
        <div id="questionTable" className="table-responsive">
          <Table className="align-items-center table-flush">
            {classArray.length > 0 ? (
              <ProfitCostCenterHeader isDisabled={isDisabled} action={action} />
            ) : (
              ''
            )}
            <tbody className="list">
              {classArray
                ? classArray.map((item, index) =>
                    !item['editMode'] ? (
                      <PROFITCOSTCENTERTHROW
                        key={index + '_class'}
                        action={action}
                        deleteRecord={deleteRecord}
                        getDisplayValue={getDisplayValue}
                        item={item}
                        editRecord={editRecord}
                        index={index}
                      closeRecord={ closeRecord }
                      isDisabled={isDisabled}
                      />
                    ) : (
                      <tr key={index + '-class'}>
                        <td className="text-center p-2 mx-1">
                          <Select2
                            className="input-height"
                            options={{ placeholder: 'Select...' }}
                            value={item.sapProfitCenterId}
                            onChange={(e) => {
                              let n = e.target.value;
                              updateRecord(index, n, 'sapProfitCenterId');
                            }}
                            data={profitCenter}
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Label className="customLabel">
                            {profitCenterDescription}
                          </Label>
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Label className="customLabel">
                            {academicCareerDispValue}
                          </Label>
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Label className="customLabel">
                            {academicGroupDispValue}
                          </Label>
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={50}
                            type="text"
                            defaultValue={item['sapCostCenter']}
                            onChange={(e) => {
                              updateRecord(
                                index,
                                e.target.value,
                                'sapCostCenter'
                              );
                            }}
                            placeholder="SAP Cost Center"
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={50}
                            type="text"
                            defaultValue={item['costCenterDescription']}
                            onChange={(e) => {
                              updateRecord(
                                index,
                                e.target.value,
                                'costCenterDescription'
                              );
                            }}
                            placeholder="Cost Center Description"
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <CustomDatePicker 
                              disabled={true}
                              value={ classArray && classArray.effectiveDate ? moment(classArray.effectiveDate).toDate() : ''}
                              // value={
                              //     item["__status"] == "__new"
                              //     ? null
                              //     : moment(item["effectiveDate"]).toDate()
                              // }
                              placeholder="Example:01-01-2020"
                          />
                          {/* <Input
                            disabled={true}
                            defaultValue={
                              item['__status'] == 'new'
                                ? null
                                : moment(item['effectiveDate']).format(
                                    'DD-MM-YYYY'
                                  )
                            }
                            placeholder="Example:01-01-2020"
                            className="tdInput"
                          /> */}
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Label className="custom-toggle mx-auto ml-2">
                            <Input
                              checked={item.status == 'ACTIVE' ? true : false}
                              type="checkbox"
                              onChange={(e) => {
                                let n = 'ACTIVE';
                                if (!e.target.checked) {
                                  n = 'INACTIVE';
                                }
                                updateRecord(index, n, 'status');
                                setDataChange(!dataChange);
                              }}
                            />
                            <span
                              style={{ width: '72px' }}
                              className="custom-toggle-slider rounded-circle activeToggle"
                              data-label-off="Inactive"
                              data-label-on="Active"
                            />
                          </Label>
                        </td>
                        <td>
                          <Button
                            size="sm"
                            color="info"
                            onClick={() => saveAll(index)}
                          >
                            Save
                          </Button>
                          <Button
                            size="sm"
                            color="danger"
                            onClick={() => closeRecord(index)}
                          >
                            <i className="fas fa-times" />
                          </Button>
                        </td>
                      </tr>
                    )
                  )
                : null}
            </tbody>
          </Table>

          {pagination.totalPage > 1 ? (
            <GetPagination
              fetchClasses={profitAndCostCenter}
              pagination={pagination}
              previousPage={previousPage}
              firstPage={firstPage}
              lastPage={lastPage}
              nextPage={nextPage}
              setpagination={setPagination}
            />
          ) : null}
        </div>
      )}
    </Container>
  );
};

export default ProfitAndCostCenter;
