import React, { useState } from "react";
import { useParams } from "react-router-dom";
import AButton from "../../../../common/form-fields-mui/AButton";
import AInput from "../../../../common/formFeilds/customInput/AInput";
import SelectMaterialElasticSearch from "../../mastersAndMapping/onBoardingMaterialIdSetup/SelectMaterialElasticSearch";
import MainPopover from "views/pages/manage/common/a-popover";
import { selectStyles } from "../../stockReturn/constant";
import { fetchMaterialBarcodes } from "../../service";
import ALoader from "../../../../common/a-loader";
import BarcodePopup from "./BarcodePopup";
import { ReactComponent as DeleteIcon } from "assets/img/svg/Delete.svg";
import { failureToast } from "../../../../common/utils/methods/toasterFunctions/function";
import { regex } from "views/pages/manage/common/constant";
import "../style.scss";
import { Card, CardBody } from "reactstrap";
import { MESSAGES_STOCK } from "./helper";
import { Tooltip } from "@mui/material";
import AAutoComplete from "../../../../common/form-fields-mui/AAutoComplete";
import { validateBarcode } from "../../dlpStockIssuance/issueStock/BarcodeMappingTable";
import { useGetContext } from "../../contextApi";
import { isBarcodedMaterial } from "../../selectMeterialAndStock/constant";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx";

export const AddMaterialForm = ({
  row,
  indexNo,
  saveHandler,
  businessArea,
  deleteHandler,
  rows,
  cancelHandler = () => {},
}) => {
  const [form, setForm] = useState({
    ...row,
    qty: row?.qty,
    availableCount: row.availableCount,
    materialId: row?.materialId ? row : "",
  });
  const [barcodeList, setBarCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const params = useParams();

  const saveHandlerWrapper = async () => {
    if (row?.mode === "EDIT") {
      await fetchBarcode(form?.materialId?.materialId, saveHandler);
    } else {
      saveHandler(form, barcodeList, indexNo);
    }
  };

  const fetchBarcode = async (materialId, cb = () => {}) => {
    setLoading(true);
    const res = await fetchMaterialBarcodes(
      businessArea["sapPlantId"],
      [materialId],
      businessArea["value"]
    );
    if (res && res["materialIds"]) {
      setBarCodes(res?.materialIds[materialId] || []);
      cb(form, res?.materialIds[materialId] || [], indexNo);
      setForm((prev) => ({
        ...prev,
        availableCount:
          prev?.materialId || prev?.materialId?.materialId
            ? res?.materialCount[materialId] || 0
            : "",
      }));
    }
    setLoading(false);
  };
  const isAllReadyAdded = (value, index) => {
    return rows.some(
      (item, i) => item?.materialId === value?.materialId && i !== index
    );
  };
  const rowFormHandler = (value, key) => {
    if (
      (key === "qty" && value > form?.availableCount) ||
      (key === "qty" && value > 50) ||
      (key === "qty" && value < 1)
    ) {
      let errorMessage = MESSAGES_STOCK.QTY_GRT_AVL;

      if (value < 1) errorMessage = MESSAGES_STOCK.MINIMUM_QTY;
      if (value > 50) errorMessage = MESSAGES_STOCK.MAX_QTY;
      if (value !== "") return failureToast(errorMessage);
    }
    if (key === "materialId" && isAllReadyAdded(value, indexNo))
      return failureToast(MESSAGES_STOCK.DUPLICATE_MATERIAL);
    setForm((prev) => ({ ...prev, [key]: value }));
    if (key === "materialId") {
      fetchBarcode(value?.materialId || form?.materialId?.materialId);
      setForm((prev) => ({ ...prev, qty: "" }));
    }
  };
  const getAvlCount = (obj) => {
    if (obj?.availableCount === 0) return 0;
    return obj?.availableCount || "-";
  };
  return (
    <div className="add-material-table">
      {loading && <ALoader />}

      <div style={{ minWidth: "200px" }}>
        <SelectMaterialElasticSearch
          currentValue={form?.materialId}
          handleChange={(value) => rowFormHandler(value, "materialId")}
          isMulti={false}
          style={selectStyles}
          placeholder={"Select"}
          height="41px"
          noOptionsMessage={MESSAGES_STOCK.NO_RECORDS}
          isDlpSearch={params.id ? params.id.toUpperCase() === "DLP" : false}
          isDigitalSearch={
            params.id ? params.id.toUpperCase() === "DIGITAL" : false
          }
        />
      </div>
      <div>{form?.materialId?.materialDescription || "-"}</div>
      <div>{form?.materialId?.uom || "-"}</div>
      <div>{getAvlCount(form)}</div>

      <div>
        <AInput
          value={form?.qty}
          placeholder={"Enter"}
          regex={regex.numeric}
          style={{ height: "41px", borderRadius: "4px" }}
          handler={(value) => rowFormHandler(value, "qty")}
          disabled={!form?.materialId?.materialId && !form?.materialId}
        />
      </div>
      <div> -</div>
      <div>
        <AButton
          variant="primary_filled"
          className="ml-0"
          onClick={() => saveHandlerWrapper(form, barcodeList, indexNo)}
          size="xs"
          disabled={form?.qty === "" || form?.materialId === ""}
        >
          Save
        </AButton>
        {row?.mode === "EDIT" ? (
          <IconButtonWrapper
            className="ml-2"
            onClick={() => cancelHandler(indexNo)}
          >
            <CrossIcon />
          </IconButtonWrapper>
        ) : (
          <IconButtonWrapper
            className="ml-2"
            onClick={() => deleteHandler(indexNo)}
          >
            <DeleteIcon />
          </IconButtonWrapper>
        )}
      </div>
    </div>
  );
};

export const AddMaterialFormMisc = ({
  row,
  saveHandler,
  businessArea,
  setRow,
  setError,
  courseName,
  materialDropdown,
  disableAllButtons,
  loading,
  setLoading,
}) => {
  const [barcode, setBarcode] = useState(row?.barcode);
  // const [loading, setLoading] = useState(false);
  const { applicationMisc } = useGetContext();

  const saveHandlerWrapper = async () => {
    setLoading(true);
    let isInvalid = false;
    if (row?.validBarcode !== barcode) {
      isInvalid = await validateBarcode(
        applicationMisc?.businessArea,
        barcode,
        row
      );
    }
    if (isInvalid) {
      setError({ isError: true, errorMgs: isInvalid });
      saveHandler(barcode, true);
    } else {
      setError({ isError: false });
      saveHandler(barcode);
    }
    setLoading(false);
  };

  const fetchBarcode = async (materialId) => {
    setLoading(true);
    const res = await fetchMaterialBarcodes(
      applicationMisc?.sapPlantId,
      [materialId],
      applicationMisc?.businessArea
    );
    setLoading(false);
    if (res && res["materialIds"]) {
      const availableCount = res?.materialCount[materialId] || 0;
      if (availableCount) {
        const barcode = res?.materialIds[materialId][0]["barcode"];
        // console.log(validateBarcode, 'valid barcode')
        setRow((prev) => ({
          ...prev,
          validBarcode: barcode,
          barcode,
          availableCount,
          qty: 1,
        }));
      } else {
        setRow((prev) => ({ ...prev, barcode: "", qty: "-", availableCount }));
        setError({
          isError: true,
          errorMgs: MESSAGES_STOCK.ZERO_BARCODE,
        });
      }
    }
  };

  const rowFormHandler = (value, key) => {
    setRow({ ...row, ...value });
    setError({
      isError: false,
      errorMgs: "",
    });
    if (key === "materialId") {
      fetchBarcode(value?.materialId);
    }
  };
  const barcodeHandler = (value) => {
    setRow({ ...row, barcode: value });
  };

  return (
    <div className="issue-stock-misc">
      {/* {loading && <ALoader />} */}
      <div>{row?.courseId}</div>
      <div>
        <Tooltip title={courseName}>
          <div className={`ellipsis-text-container`}>{courseName}</div>
        </Tooltip>
      </div>
      <div className="w-100">
        <AAutoComplete
          handleChange={(value) => rowFormHandler(value, "materialId")}
          isMulti={false}
          items={materialDropdown}
          currentValue={row?.label ? row : null}
          style={{ control: { borderRadius: "5px" } }}
          noOptionsMessage={MESSAGES_STOCK.NO_RECORDS}
          placeholder={"Select"}
          height="41px"
        />
      </div>
      <Tooltip title={row?.materialDescription || "-"}>
        <div className="ellipsis-text-container">
          {row?.materialDescription || "-"}
        </div>
      </Tooltip>
      <div>{row?.availableCount}</div>
      <div>{row?.qty}</div>
      <div>
        {row?.barcode === "" ? (
          "-"
        ) : isBarcodedMaterial(row) ? (
          <AInput
            value={barcode}
            placeholder={"Enter"}
            type="number"
            regex={regex.numeric}
            style={{ height: "41px", borderRadius: "4px" }}
            handler={(value) => setBarcode(value)}
          />
        ) : (
          <>-</>
        )}
      </div>
      <div>
        <AButton
          variant="primary_filled"
          onClick={() => saveHandlerWrapper(barcode)}
          size="xs"
          disabled={barcode === ""}
          className="btn-left-0"
        >
          Save
        </AButton>
      </div>
    </div>
  );
};
export const DisplayRowData = ({
  row,
  index,
  editHandler,
  deleteHandler,
  updateBarcodeListForMaterial,
  employeeDetailsForm,
}) => {
  return (
    <div className="add-material-table">
      <div>{row?.materialId}</div>
      <div>{row?.materialDescription}</div>
      <div>{row?.uom}</div>
      <div>{row?.availableCount}</div>
      <div>{row?.quantity}</div>
      <div>
        {isBarcodedMaterial(row) ? (
          <BarcodePopup
            rowData={row}
            materialRowIndex={index}
            count={row?.availableCount}
            updateBarcodeListForMaterial={updateBarcodeListForMaterial}
            employeeDetailsForm={employeeDetailsForm}
          />
        ) : (
          "-"
        )}
      </div>
      <div>
        <AButton
          size="xs"
          variant="outline"
          onClick={() => editHandler(index, "edit")}
          className="btn-left-0"
        >
          Edit
        </AButton>
        <IconButtonWrapper
          className="ml-2"
          onClick={() => deleteHandler(index)}
        >
          <DeleteIcon />
        </IconButtonWrapper>
      </div>
    </div>
  );
};

const getBarCodes = (row, isReturned) => {
  return (
    row?.barCodes
      // .filter((item) =>
      //   isReturned ? item?.returnTransactionId : true
      // )
      .map((item) => item.barcode)
  );
};

export const ConsumptionHistoryHeader = ({ isReturnedBarcode }) => {
  return (
    <div className="header-column consumption-history-table">
      <div>Material details</div>
      <div>Barcode</div>
      <div>Quantity {isReturnedBarcode ? "returned" : "issued"}</div>
      <div>UOM</div>
    </div>
  );
};
export const ConsumptionHistoryRow = ({ row, isReturnedBarcode = false }) => {
  // const barCodesWillRender = isReturnedBarcode
  //   ? getBarCodes(row)
  //   : getBarCodes(row);
  const barCodesWillRender = getBarCodes(row);
  // console.log(barCodesWillRender, 'barcodes', isReturnedBarcode, row)

  return (
    <div className="consumption-history-table">
      <div>
        {row?.materialId} | {row?.materialDescription}
      </div>
      <div>
        {isBarcodedMaterial(row) ? (
          <ViewBarcode barCodes={barCodesWillRender} />
        ) : (
          "-"
        )}
      </div>
      <div>{barCodesWillRender.length}</div>
      <div>{row?.uom}</div>
    </div>
  );
};

export const ViewBarcode = ({ barCodes }) => {
  if (!barCodes?.length) return "-";
  if (barCodes.length === 1) return barCodes[0];

  return (
    <MainPopover
      buttonComponent={(onClick, styles) => (
        <span>
          <span>{barCodes[0]}</span>
          <span onClick={onClick} className="ml-1 btn-link cursor">
            View all
          </span>
        </span>
      )}
      menuComponent={(closeForm) => (
        <>
          <Card className="mb-0">
            <CardBody>
              <div className="heading-3 color-dark">Barcode</div>
              {barCodes
                .sort((a, b) => a - b)
                .map((item) => (
                  <div key={JSON.stringify(item)} className="mb-1">
                    {item}
                  </div>
                ))}
            </CardBody>
          </Card>
        </>
      )}
    />
  );
};
