import React, {Fragment, useEffect, useState} from 'react';
import {Row, Col, FormGroup, Label, Button} from "reactstrap";
import Select from "react-select2-wrapper";
import {failureToast, getAPI,masterServiceBaseUrl} from "../../../../../../services/http";


const RfIdHeader = (props) => {
  const [showGroupCode, setShowGroupCode] = useState();
  const [showCompanyCode, setShowCompanyCode] = useState();
  const [showZone, setShowZone] = useState();
  const [showRegion, setShowRegion] = useState();
  const [showBusinessArea, setShowBusinessArea] = useState();
  const [showAcademicYear, setShowAcademicYear] = useState();
  const {
    groupCode,
    zone,
    region,
    academicYear,
    searchValue,
    setSearchValue,
    searchHandler,
    setShowTable,
    showTable
  } = props

  const dropDownDataHandler = (data, type) => {
    try {
      switch (type) {
        case 'SHOW_GROUP_CODE':
          const showGroup = getDropDownValue(data, 'id', 'groupCodeDispValue');
          setShowGroupCode(showGroup);
          break;
        case 'SHOW_COMPANY_CODE':
          const showCompany = getDropDownValue(data,'id','companyCodeDispValue');
          setShowCompanyCode(showCompany);
          break;
        case 'SHOW_ZONE_CODE':
          const showZone = getDropDownValue(data, 'id', 'operationalZoneDispValue');
          setShowZone(showZone);
          break;
        case 'SHOW_REGION_CODE':
          const showRegion = getDropDownValue(data, 'id', 'regionDispValue');
          setShowRegion(showRegion);
          break;
        case 'SHOW_BUSINESS_AREA_CODE':
          const showBusiness =  getDropDownValue(data, 'id', 'businessAreaDispValue');
          setShowBusinessArea(showBusiness);
          break;
        case 'SHOW_ACADEMIC_YEAR_CODE':
          const showAcademic = getDropDownValue(data, 'id', 'academicYearDisplayValue');
          setShowAcademicYear(showAcademic);
          break;
        default:
          break
      }
    } catch (e) {
      console.log('Error', e);
    }
  }

  const getDropDownValue = (data, id, displayValue) => {
    try {
      const temp = [];
      data.map(item => {
        if (item.status.toUpperCase() === 'ACTIVE') {
          temp.push({
            id: item[id],
            text: item[displayValue]
          })
        }
      });
      return temp;
    } catch (err) {
      console.log('Error :: ', err);
    }
  }

  const dropDownOnChangeHandler = (property,value) => {
    try {
      setSearchValue((pre)=>({
        ...pre,
        [property]: value
      }))
    } catch (err) {
      console.log('Error : ', err)
    }
  }



  const getMappedValue = (url,type) => {
    try{
      getAPI(
        url,
        (data) => {
          dropDownDataHandler(data?.data,type);
        },
        (data) => {
          failureToast(data['message']);
        }
      );
    }catch (err){
      console.log('Error', err)
    }
  }

  useEffect(() => {
    dropDownDataHandler(groupCode, 'SHOW_GROUP_CODE');
    dropDownDataHandler(zone, 'SHOW_ZONE_CODE');
    dropDownDataHandler(region, 'SHOW_REGION_CODE');
    dropDownDataHandler(academicYear, 'SHOW_ACADEMIC_YEAR_CODE');
  }, [props])
  return (
    <Fragment>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label className="form-control-label">
              Business Group
            </Label>
            <Select
              options={{placeholder: 'Select Business Group'}}
              data={showGroupCode}
              defaultValue={searchValue?.groupCodeId}
              onChange={(e) => {
                let value = e.target.value;
                value && dropDownOnChangeHandler('groupCodeId', value);
                value && getMappedValue(`${masterServiceBaseUrl}/companyCode/getCompanyCodeByGroup/${value}`,'SHOW_COMPANY_CODE')
                value && searchValue.companyCodeId && getMappedValue(`${masterServiceBaseUrl}/businessArea/getBusinessAreaByCompanyGroupCode?companyCode=${searchValue.companyCodeId}&groupCode=${value}`,'SHOW_BUSINESS_AREA_CODE')
              }}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label className="form-control-label">
              Company Code
            </Label>
            <Select
              options={{placeholder: 'Select Company Code'}}
              data={showCompanyCode}
              defaultValue={searchValue?.companyCodeId}
              onChange={(e) => {
                let value = e.target.value;
                value && dropDownOnChangeHandler('companyCodeId', value);
                value && searchValue.groupCodeId && getMappedValue(`${masterServiceBaseUrl}/businessArea/getBusinessAreaByCompanyGroupCode?companyCode=${value}&groupCode=${searchValue.groupCodeId}`,'SHOW_BUSINESS_AREA_CODE')
              }}
            />
          </FormGroup>
        </Col><Col md={4}>
        <FormGroup>
          <Label className="form-control-label">
            Zone
          </Label>
          <Select
            options={{placeholder: 'Select Zone'}}
            data={showZone}
            defaultValue={searchValue?.zoneId}
            onChange={(e) => {
              let value = e.target.value;
              value && dropDownOnChangeHandler('zoneId', value)
            }}
          />
        </FormGroup>
      </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label className="form-control-label">
              Region
            </Label>
            <Select
              options={{placeholder: 'Select Region'}}
              data={showRegion}
              defaultValue={searchValue?.regionId}
              onChange={(e) => {
                let value = e.target.value;
                value && dropDownOnChangeHandler('regionId', value)
              }}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label className="form-control-label">
              Business Area
            </Label>
            <Select
              options={{placeholder: 'Select Business Area'}}
              data={showBusinessArea}
              defaultValue={searchValue?.businessAreaId}
              onChange={(e) => {
                let value = e.target.value;
                value && dropDownOnChangeHandler('businessAreaId', value)
              }}
            />
          </FormGroup>
        </Col><Col md={4}>
        <FormGroup>
          <Label className="form-control-label">
            Academic Year
          </Label>
          <Select
            options={{placeholder: 'Select Academic Year'}}
            data={showAcademicYear}
            defaultValue={searchValue?.academicYearId}
            onChange={(e) => {
              let value = e.target.value;
              value && dropDownOnChangeHandler('academicYearId', value)
            }}
          />
        </FormGroup>
      </Col>
      </Row>
      <Row>
        <Col md={12} className={'d-flex justify-content-end'}>
          {showTable ?
            <Button color={'info'} onClick={() => {
            setSearchValue({});
            setShowTable(false);
          }}>Reset</Button>:
            <Button color={'info'} onClick={() => searchHandler()}>Search</Button>}
        </Col>
      </Row>
    </Fragment>
  )
}

export default RfIdHeader;
