import { Box, Card, Dialog, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react';
import './index.scss';
import CustomButton from 'components/CustomButton';
import APopover from '../../common/a-popover';
import ApprovalDialogContent from './dialogContent';
import { constants } from "../../common/constant/constants/index";
import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedRowsIds, getApprovalRequests, getOtherChargesForApproval, setSelectedRowIds } from '../../../../../redux/concessionApproval/actions';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import { getApprovedBy, getFormatedDate, getSuccessMsg, getUpdatedRow, getUserRemarks } from './common';
import { AiOutlineEye, AiOutlineInfoCircle } from 'react-icons/ai';
import { LightTooltip } from '../studentCharges/studentChargesDetails/concessionDetails/prospectus';
import moment from 'moment';
import { fetchAllPostPromisedData, getCookie } from '../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../common/constant';
import closeIcon from 'assets/img/svg/closeSquare.svg'
import userProfile from 'assets/img/svg/userProfile.svg';
import { DiscretionarySupportingDocUploader } from '../studentCharges/studentChargesDetails/concessionDetails/upload';
import DocumentVerification from './documentVerification';
import { useHistory } from 'react-router';
import { PSIDTooltipTitle } from './approvalBranch';
import { CourseDetails } from '../../edpOps/stockManagement/selectMeterialAndStock/issueStock/TableColumn';

const DecoratedCell = props => {
    return (
        <Box class={props?.islink ? 'approval-cell link' : 'approval-cell'}>
            {props.children}
        </Box>
    )
}

const FeesCell = props => {
    return (
        <div className='fees-cell-container'>
            <span className='cell-text'>
                <DecoratedCell>{props.value}</DecoratedCell>
            </span>
            <LightTooltip title={props.value}>
                <span className='cell-tooltip'>
                    <AiOutlineInfoCircle color='#00B0F5' size={16} />
                </span>
            </LightTooltip>
        </div>
    )
}

const OtherchargesApprovalBranch = props => {



    const { stage, businessAreaId, rowsData, userPermissions, userDetails, appliedFilters } = props;
    const { APPROVAL_ACTIONS, APPROVAL_STAGE } = constants;

    const [statusUpdating,setStatusUpdating] = useState({id: null , status : null})


    const dispatch = useDispatch();
    const history = useHistory();
    const approvalState = useSelector(state => state.concessionApproval);

    const [remarkedRow, setRemarkedRow] = useState(null);
    const [open, setOpen] = useState(false);

    const onActionClick = async (approvalStatus, row) => {
        setStatusUpdating({id : row.id, status: approvalState})
        const payload = {
            "approvalStatus": approvalStatus,
            "approverUserId": userDetails?.employeeId || "",
            "approverName": userDetails ? `${userDetails.firstName} ${userDetails.lastName}` : ""
        }
        try {
            const response = await fetchAllPostPromisedData(`${endpoint.otherChargesConcession.updateStatus}/${row.id}`, payload, 'put');
            if (response?.code === 200) {
                successToast(getSuccessMsg(approvalStatus));
                dispatch((clearSelectedRowsIds()));
                let page = approvalState?.currentPage;
                if (approvalState?.otherChargesRequests?.length === 1 && page > 0) {
                    page = approvalState?.currentPage - 1
                }
                dispatch(getOtherChargesForApproval({ ...appliedFilters, approvalStatus: stage, businessArea: appliedFilters?.businessArea?.length ? appliedFilters?.businessArea : approvalState.allBusinessAreas.map(i => i.label) }, page));
                // setapprovalState?.currentPage(page)
                setStatusUpdating({id : null, status: null})
            } else {
                setStatusUpdating({id : null, status: null})
                failureToast("Details not saved.");
            }
        } catch (err) {
            failureToast("Details not saved.");
        }
    }

    const showAction = stage === APPROVAL_STAGE.PENDING;
    const nonPendingHeader = stage === APPROVAL_STAGE.APPROVED ?
        'Approved on' :
        stage === APPROVAL_STAGE.REJECTED ?
            'Rejected on' :
            'Sent for recheck on';

    const columns = [
        {
            field: 'psid', headerName: 'PSID', minWidth: 120, flex: 120, sortable: false,
            renderCell: params => {
                // const course = approvalState.allCourses.find(item => item.value == params.row.courseId);
                const courseCategory = approvalState.allCourseCategories.find(item => item.value == params.row.chargeTypeId);
                // const title = <span><strong>{`PSID- ${params.value}`}</strong>{` | ${course?.value || ""}, ${course?.label || ""}, ${courseCategory?.label || ""}`}</span>;
                return (
                    <LightTooltip title={<PSIDTooltipTitle courseCategory={courseCategory} courseId={params.row.courseId} psid={params.value}/>}>
                        <div style={{ cursor: 'pointer' }} onClick={() => history.push(`/admin/studentOtherCharges/details/${params.row.applicationId}/view`)}>
                            <DecoratedCell islink={true}>{params.value}</DecoratedCell>
                        </div>
                    </LightTooltip>
                )
            }
        },
        {
            field: 'courseId', headerName: 'Course ID', minWidth: 130, flex: 130, sortable: false,
            renderCell: params => {
                const course = approvalState.allCourses.find(item => item.value == params.value)?.label;
                return <div className='d-flex'>
                    <span className='cell-text pr-1'>
                        {params.value}
                    </span>
                    <CourseDetails courseId={params.value} />
                    {/* <LightTooltip title={course ?? params.value}>
                        <span className='cell-tooltip'>
                            <AiOutlineInfoCircle color='#00B0F5' size={16} />
                        </span>
                    </LightTooltip> */}
                </div>
            }
        },
        {
            field: 'netPayableAmount', headerName: 'Course Fee ( MRP)', minWidth: 140, flex: 140, sortable: false,
            renderCell: params => {
                return <span>
                    ₹ {params.value}
                </span>
            }
        },
        {
            field: 'waveOffAmount', headerName: 'Concession Amount', minWidth: 100, flex: 100, sortable: false,
            renderCell: params => <span>
                ₹ {params.value}
            </span>

        },
        // {
        //     field: 'remarks', headerName: 'Remarks', minWidth: 80, flex: 91, sortable: false, align: 'center', headerAlign: 'center',
        //     renderCell: (params) => <div className='w-100 .ellipse-text'>
        //         <LightTooltip title={`${params.value}`}>
        //             <div style={{ cursor: 'pointer' }}>
        //                 <Typography variant='body2'>{params.value}</Typography>
        //             </div>
        //         </LightTooltip>
        //     </div>
        // },
        {
            field: 'cashierUserId', headerName: 'User', minWidth: 80, flex: 91, sortable: false,
            renderCell: (params) => <div>
                <LightTooltip title={`${params.row.cashierName}`}>
                    <div style={{ cursor: 'pointer' }}>
                        <Typography variant='body2'>{params.value}</Typography>
                    </div>
                </LightTooltip>
            </div>
        },
        // {
        //     field: 'createdBy', headerName: 'User', minWidth: 100, flex: 100, sortable: false, renderCell: (params) => {
        //         return (
        //             <>
        //                 <Typography variant='body2'>{params.value}</Typography>
        //                 <LightTooltip title={`${params.value} | ${params?.row?.createdByName ?? ''}`}>
        //                     <div style={{ cursor: 'pointer' }}>
        //                         <AiOutlineEye className='ml-1' size={16} color='#11cdef' />
        //                     </div>
        //                 </LightTooltip>
        //             </>
        //         )
        //     }
        // },
        {
            field: 'createdOn', headerName: 'Requested on', minWidth: 130, flex: 110, sortable: false, renderCell: params => {
                // getFormatedDate(params.value) 
                return (
                    <>
                        <LightTooltip title={getFormatedDate(params.value, true)}>
                            <div style={{ cursor: 'pointer' }}>
                                <Typography variant='body2'>{getFormatedDate(params.value)}</Typography>
                            </div>
                        </LightTooltip>
                    </>
                )
            }
        },

        // {
        //     field: 'totalConcessionApplied', headerName: 'Total Conc.', minWidth: 135, flex: 135, sortable: false,
        //     renderCell: params => {
        //         const value = params.value ? `₹ ${params.value.amount} (${params.value.percentage.toFixed(2)}%)` : '';
        //         return <FeesCell value={value} />
        //     }
        // },
        (showAction === true ?
            {
                field: 'actions', headerName: 'Actions', type: 'actions', minWidth: 200, flex: 1, filterable: false, sortable: false,
                renderCell: params => {
                    const isDisabled = approvalState.selectedRowIds?.[businessAreaId] && approvalState.selectedRowIds[businessAreaId].includes(params.id) ? true : false;
                    const onButtonClick = (e, onClick) => {
                        if (!isDisabled) {
                            onClick(e);
                        }
                    }
                    return (
                        <Box className='approval-action-buttons'>
                            <CustomButton
                                className='approval-action-approve-button'
                                content={statusUpdating?.id == params.row.id && statusUpdating.status =="APPROVED" ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : "Approve"}
                                // forTable={true}
                                permissionType={'A'}
                                permissionSet={userPermissions}
                                onClick={() => onActionClick('APPROVED', params.row)}
                                disabled={statusUpdating?.id !== null ? true : false}
                            />
                            <CustomButton
                                className='approval-action-reject-button bg-red-40'
                                // forTable={true}
                                type="cancel"
                                content={statusUpdating?.id == params.row.id && statusUpdating.status == "REJECTED" ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : "Reject"}
                                permissionType={'A'}
                                permissionSet={userPermissions}
                                onClick={() => onActionClick('REJECTED', params.row)}
                                disabled={statusUpdating?.id !== null ? true : false}
                            />
                        </Box>
                    )
                }
            } :
            {
                field: 'nonPendingAction', headerName: nonPendingHeader, minWidth: 258, flex: 258, sortable: false,
                renderCell: params => <span>
                    {params.row.approvalStatusUpdatedOn ? `${moment(params.row.approvalStatusUpdatedOn).format("DD-MM-YYYY")} By ${params.row.approverName}` : ''}
                </span>
            }
        ),
    ];

    const totalAmount = rowsData.reduce((acc, curr) => acc + curr.waveOffAmount, 0);
    // const createdByName = rowsData.length && rowsData[0].createdByName;
    const businessArea = approvalState.allBusinessAreas.find(item => item.label === businessAreaId);
    // const selectionModel = approvalState.selectedRowIds?.[businessAreaId] || [];

    return (
        <Card className="concession-approval-card">
            <Box className='concession-approval-card-header'>
                <Typography variant="body1" className='concession-approval-cashier-detail'>
                    {`Requested By ${businessArea?.label || ""} | ${businessArea?.desc || ''}`}
                </Typography>
                <Typography variant="body1" className='concession-approval-cashier-detail'>
                    Total amount: ₹ {totalAmount.toFixed(3)}
                </Typography>
            </Box>
            <Box sx={{ width: '100%', height: 'auto', overflow: "auto" }}>
                <DataGrid
                    rows={rowsData}
                    columns={columns}
                    getRowId={(row) => row.id}
                    // checkboxSelection={stage === APPROVAL_STAGE.PENDING}
                    // density={"compact"}
                    rowHeight={52}
                    // getRowHeight={(params) => 52}
                    autoHeight
                    headerHeight={52}
                    // pagination={false}
                    // pageSize={100}
                    // rowsPerPageOptions={[100]}
                    // paginationMode={"server"}
                    // page={page}
                    // onPageChange={(e) => onPageChange(e)}
                    disableColumnMenu
                    // components={{
                    // ColumnSortedDescendingIcon: SortDesc,
                    // ColumnSortedAscendingIcon: Sort,
                    // // LoadingOverlay: () => <ALoader />
                    // NoRowsOverlay : () => <NoRowsDeallocation batchName={batchName} />
                    // }}
                    // onRowClick={() => console.log('row')}
                    // isRowSelectable={isRowSelectable}
                    disableSelectionOnClick
                    // selectionModel={selectionModel}
                    onSelectionModelChange={(newSelectionModel) => {
                        // if (newSelectionModel.length > availableForSelection) {
                        //   setSelectionModel(newSelectionModel.slice(0, availableForSelection))
                        //   return;
                        // }
                        // setSelectionModel(newSelectionModel)
                        dispatch(setSelectedRowIds(businessAreaId, newSelectionModel))
                        // setSelectedRowIds(prev => ({ ...prev, [businessAreaId]: newSelectionModel }));
                    }}
                // rowCount={rowCount}
                // rowBuffer={10}
                // hideFooterSelectedRowCount={true}
                // components={{
                //     BaseCheckbox: ApprovalCheckBox
                // }}
                />
            </Box>
            <Dialog open={open}>
                <Box className='concession-approval-dialog-content' width={516}>
                    <Box className='approval-dialog-header'>
                        <Typography variant='h6'>Remarks</Typography>
                        <div onClick={() => setOpen(false)}><img src={closeIcon} /></div>
                    </Box>
                    {getUserRemarks(remarkedRow, approvalState?.approverType?.value, stage).map(user => (
                        <Box className='user-remarks-details'>
                            <img src={userProfile} />
                            <Box className='user-remarks'>
                                <Typography variant='body1'>{user.header}</Typography>
                                <Typography variant='body2'>{user.remarks}</Typography>
                                <Typography variant='caption'>{`${user.name}, ${businessArea?.label || ""} | ${getFormatedDate(user.date)}`}</Typography>
                            </Box>

                        </Box>
                    ))}
                    <CustomButton
                        className='approval-dialog-action-button'
                        type={'C'}
                        content={'Ok'}
                        permissionType={'C'}
                        permissionSet={userPermissions}
                        onClick={() => setOpen(false)}
                    />
                </Box>
            </Dialog>
        </Card>
    )
}

export default OtherchargesApprovalBranch;
