import React from 'react';
import { useState, useEffect } from "react"
import {Card, CardHeader, Button, Table, Row, Col, CardBody,} from "reactstrap";
import CommonInput from "../../../common/formFeilds/input/commonInput";
import { MasterHeaderAuto } from 'views/pages/manage/common/commonComponents/masterHeader/masterHeader';
import { masterServiceBaseUrl, failureToast, getAPI } from "services/http";
import {DropDownButton, getSelectedIds} from "../examSyllabusForm";
import {StatusButton} from "../../../common/commonComponents/TableComponents";
import {getDispValForReactSelectByOneID} from "../../../common/utils/methods/commonMethods/utilityMethod";
import {minWidth} from "@material-ui/system";

const headerList = [
    { name: "Business Group"},
    { name: "Company Code"},
    { name: "Academic Career"},
    { name: "Academic Group"},
    { name: "Academic Phase"},
    { name: "Batch"},
    { name: "Status"},
    { name: "Action"},

];


const ExamSyllabusSearchTable = (props) => {
    const {
        tableData,viewExamSyllabus,innerTable
    } = props;
    let [isOpen, setIsOpen] = useState({});
    const chkCollapse = (id,index) => {
        setIsOpen({ ...isOpen, [id]: !isOpen?.[id] ?? true });
    };
    const headerListSubTable = [
        { name: "Subject",width:'16%'},
        { name: "Chapter",width:'16%'},
        { name: "Topic",width:'16%'},
        { name: "Sub topic",width:'16%'},
        { name: "Sub Sub topic",width:'16%'},
        { name: "Status",width:'8%'},
    ];
    return (
        <Card>
            <CardHeader className={'d-flex justify-content-between'}>
                <h3 className="mb-0">Exam Syllabus</h3>
            </CardHeader>
            {/*<div className="table-responsive">*/}
            <CardBody style={{padding:'0px'}}>
                <Table style={{minWidth:'1150px'}} className="align-items-center table-flush tableLayout" size="sm" >
                    <MasterHeaderAuto headerList={headerList} />
                    <tbody>
                    {tableData ? tableData.map((item, index) =>
                        <>
                            <tr className="" key={index}>
                                <td className="text-center p-2 mx-1">
                                    {item.businessGroupIds}
                                </td>
                                <td className="text-center p-2 mx-1">
                                    {item.companyCodeIds}
                                </td>
                                <td className="p-2 mx-1">
                                    {/*/!*{item.businessGroup}*!/*/}
                                    {/*{getDispValForReactSelectByOneID(item.businessGroupIds[0].id, 'DispValue', businessGroup)}*/}
                                    <DropDownButton direction={'up'} data={item.academicCareerIds}/>
                                </td>
                                <td className="p-2 mx-1">
                                    <DropDownButton direction={'up'} data={item.academicGroupIds}/>
                                </td>
                                <td className="p-2 mx-1">
                                    <DropDownButton direction={'up'} data={item.academicPhaseIds}/>
                                </td>
                                <td className="text-center p-2 mx-1">
                                    {item.batchIds}
                                </td>
                                <td className="text-center p-2 mx-1">
                                    <StatusButton status={item.status}/>
                                </td>
                                <td className="text-center p-2 mx-1">
                                    <Button color="info" size="sm" type="button"
                                            onClick={() => {window.open( `${ document.URL }/edit/${item.id}`, '_blank' );}}
                                    >
                                        <i className="fas fa-edit" />
                                    </Button>
                                    <Button color="info" size="sm" type="button"
                                            // onClick={() => {viewExamSyllabus(item.id,index);chkCollapse(item.id,index)} }
                                            onClick={() => {window.open( `${ document.URL }/view/${item.id}`, '_blank' );}}
                                    >
                                        <i className="fas fa-eye" />
                                    </Button>
                                </td>
                            </tr>
                            {/*<tr>*/}
                            {/*    <td className={"hiddenRow"} colSpan={8}>*/}
                            {/*        <div style={{padding:'0 10px'}} key={`epmr-${index}`} className={ isOpen?.[`${item.id}`] ? "details details-height-auto" : "details"}>*/}
                            {/*            <Table className="align-items-center table-flush tableLayout" size="sm">*/}
                            {/*                <MasterHeaderAuto headerList={headerListSubTable}/>*/}
                            {/*                /!*{*!/*/}
                            {/*                /!*    innerTable && innerTable.length > 0 &&*!/*/}
                            {/*                /!*    // innerTable.map('*!/*/}
                            {/*                /!*}*!/*/}
                            {/*            </Table>*/}
                            {/*        </div>*/}
                            {/*    </td>*/}
                            {/*</tr>*/}
                        </>
                        )
                        : null}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
}

export default ExamSyllabusSearchTable;