import { endpoint } from "../../common/constant";
import { fetchAllPostPromisedData } from "../../common/utils/methods/commonMethods/utilityMethod";
import { successToast } from "../../common/utils/methods/toasterFunctions/function";

export const eligibleProgramActionForSupplementaryId = ['MATR', 'BTCG', 'RADM'];

export const isEligibleForSupplementaryId = rowData => eligibleProgramActionForSupplementaryId?.includes(rowData?.programAction);

export const requestSupplementaryId = async applications => {
  const reqBody = {
    supplementaryRequestDto: applications.map(application => {
      const applicationData = JSON.parse(application);
      return {
        studentName: applicationData?.studentName,
        psid: applicationData?.psid,
        applicationId: applicationData?.applicationId,
        businessAreaValue: applicationData?.businessAreaDispVal,
        courseId: applicationData?.courseId,
        programAction: applicationData?.programAction,
        classes: applicationData?.classes,
      };
    }),
  };

  const res = await fetchAllPostPromisedData(endpoint.studentSupplementary.requestSupplementaryId, reqBody, 'post');
  if(res?.code===200) {
    successToast(res?.message);
  }

  return res;
}