import {
  ACADEMIC_PLAN_LIST_CLEAR,
  ACADEMIC_PLAN_LIST_FAILURE,
  ACADEMIC_PLAN_LIST_REQUEST,
  ACADEMIC_PLAN_LIST_SUCCESS,
  REQUEST_DROPDOWNS,
  UPDATE_DROPDOWNS,

} from "./actionTypes";

const defaultState = {
  currentPage: 0,
  dropdowns: {},
  dropdownLoader: false,
  searchAcademicPlanLoader: false,
  academicPlanList: [],
  searchAcademicPlanRowCount: 0,
  searchAcademicPlanTotalServerPages: 0,
  searchAcademicPlanDropdowns: {},
};

export const academicPlanReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_DROPDOWNS:
      return {
        ...state,
        dropdowns: action.dropdowns,
        dropdownLoader: false
      };
    case REQUEST_DROPDOWNS:
      return {
        ...state,
        dropdownLoader: true
      }
    case ACADEMIC_PLAN_LIST_REQUEST:
      return {
        ...state,
        searchAcademicPlanLoader: true,
      };
    case ACADEMIC_PLAN_LIST_SUCCESS:
      return {
        ...state,
        academicPlanList: action.payload,
        searchAcademicPlanRowCount: action.rowCount,
        searchAcademicPlanTotalServerPages: action.totalServerPages,
        searchAcademicPlanLoader: false,
      };
    case ACADEMIC_PLAN_LIST_FAILURE:
      return {
        ...state,
        searchAcademicPlanLoader: false,
        academicPlanList: [],
        searchAcademicPlanRowCount: 0,
        searchAcademicPlanTotalServerPages: 0,
      };
    case ACADEMIC_PLAN_LIST_CLEAR:
      return {
        ...state,
        searchAcademicPlanLoader: false,
        academicPlanList: [],
        searchAcademicPlanRowCount: 0,
        searchAcademicPlanTotalServerPages: 0,
      };
    
    default:
      return state;
  }
};
