import React from 'react'
import { Card, Table } from 'reactstrap'
import CustomButton from "components/CustomButton";
import { MasterHeaderAuto } from "../../../../../common/commonComponents/masterHeader/masterHeader";
import CustomDownload from "../../../../../common/commonComponents/CustomDownload"

const headerList = [
    { name: "S.NO" },
    { name: "File Name" },
    { name: "Action" },
];


const SupportingDocView = ({ data, userPermissions }) => {
    return (
        <Card className="mt-4">
            <div className="p-4">
                <Table className="align-items-center table-flush">
                    <MasterHeaderAuto headerList={headerList} />
                    <tbody className="list">
                        {data &&
                            data.map((item, index) => {
                                return (
                                    <tr>
                                        <td className="text-center  white-breakSpace">
                                            {index + 1}
                                        </td>
                                        <td className="text-center  white-breakSpace">
                                            {item.fileName}
                                        </td>
                                        <td className="text-center  white-breakSpace">
                                            {/* <CustomButton
                                                type="download"
                                                className={'mx-1'}
                                                permissionType={'R'}
                                                color="info"
                                                icon={true}
                                                forTable={true}
                                                onClick={() => window.open(`${item.filePath}`)}
                                                permissionSet={userPermissions}
                                            /> */}
                                            <CustomDownload
                                                downloadKey={item.key}
                                                permissionSet={userPermissions}
                                                isIcon={true}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </Table>
            </div>
        </Card>
    )
}

export default SupportingDocView