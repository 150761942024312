import React, { useEffect } from "react";
import {
  Card,
  CardHeader,
  Input,
  Container,
  Button,
  Table,
  Row,
} from "reactstrap";
import { useState } from "react";
// import "views/pages/manage/addressType/node_modules/react-sliding-pane/dist/react-sliding-pane.css";
import { failureToast } from "../../common/utils/methods/toasterFunctions/function";
import { MasterHeaderAuto, Rows } from "../../common/commonComponents/masterHeader/masterHeader";

import {
  getAPI,
  masterServiceBaseUrl,
  successToast,
  putpost,
  putpost2,
} from "services/http";
import moment from "moment";
var _ = require("lodash");

const AccountTypeTable = (props) => {
  const {
    serverData,
    setSearch,
    resetSearch,
    isSaveVisible,
    setisSaveVisible,
    action,
  } = props;
  const [tableDataKey, settableDataKey] = useState("tableDataKey");
  const [updatedKey, setUpdateKey] = useState(new Date());
  const [searchStr, setSearchStr] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: "__new",
        status: "ACTIVE",
        accountTypeKey: null,
        accountTypeDesc: null,
        accountTypeDispValue: null,
        effectiveDate: null,
      },
      ...props.classArray,
    ]);
  };

  const headerList = [
    { name: "Account Type Key" },
    { name: "Description" },
    { name: "Display Value" },
    { name: "Effective Date" },
    { name: "Status" },
    { name: "Action" },
  ];
  const listToRender = [
    "accountTypeKey",
    "accountTypeDesc",
    "accountTypeDispValue",
    "effectiveDate",
    "status",
  ];

  const saveAll = (cb) => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if (el["editMode"]) {
        if (!el["accountTypeKey"] || !el["accountTypeKey"].trim()) {
          isValid = false;
          failureToast("Please Enter Account Type Key");
          return;
        } else if (!el["accountTypeDesc"] || !el["accountTypeDesc"].trim()) {
          isValid = false;
          failureToast("Please Enter Description");
          return;
        } else if (
          !el["accountTypeDispValue"] ||
          !el["accountTypeDispValue"].trim()
        ) {
          isValid = false;
          failureToast("Please Enter Display Value");
          return;
        }
        if (!isValid) {
          return;
        }
        if (_tempArray[index]["__status"] == "__new") {
          putpost(
            masterServiceBaseUrl + "/accountType/create",
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              setUpdateKey(new Date());
              props.fetchClasses(`${masterServiceBaseUrl}/accountType`);
            },
            (data) => {
              failureToast(data["message"]);
            },
            _tempArray[index],
            "post"
          );
        } else {
          putpost(
            masterServiceBaseUrl + "/accountType/update/" + _tempArray[index].id,
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              // delete _tempArray[index]['editMode']
              // props.setclassArray(_tempArray)
              props.fetchClasses(`${masterServiceBaseUrl}/accountType`);
              settableDataKey(new Date().getMilliseconds());
              // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
            },
            (data) => {
              failureToast(data["message"]);
            },
            _tempArray[index],
            "put"
          );
        }
      } else {
      }
    });
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
        // settableDataKey(new Date().getMilliseconds())
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      cb();
    }
  };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]["editMode"] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };
  const deleteRow = (index) => {
    if (props.classArray[index]["__status"] == "__new") {
      let n = props.classArray;
      n.splice(index, 1);
      props.setclassArray(n);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
    } else {
      if (
        window.confirm("Are you sure you want to delete this Account Type ?")
      ) {
        putpost2(
          masterServiceBaseUrl + "/accountType/" + props.classArray[index].id,
          (data) => {
            successToast(data["message"]);
            if (props.classArray.length == 1) {
              props.previousPage();
              props.fetchClasses(`${masterServiceBaseUrl}/accountType`);
            } else {
              var _tempArray = props.classArray;
              if (_tempArray[index]["editMode"]) {
                setisSaveVisible(false);
              }
              _tempArray.splice(index, 1);
              props.setclassArray(_tempArray);
            }
            settableDataKey(new Date().getMilliseconds());
          },
          (data) => {
            failureToast(data["message"]);
          },
          "PUT"
        );
      }
    }
  };

  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
    setUpdateKey(new Date());
  };

  const filterData = (str) => {
    const newData =
      serverData &&
      serverData.filter((obj) =>
        Object.values(obj).some(
          (val) =>
            val && val.toString().toLowerCase().includes(str.toLowerCase())
        )
      );
    setSearch(newData);
  };

  useEffect(() => {
    if (!searchStr || !searchStr.trim()) {
      resetSearch();
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
      filterData(searchStr);
    }
  }, [searchStr]);

  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0 d-flex justify-content-between">
                <h3 className="mb-0 ">Account Type</h3>

                <Button
                  data-testid="addNewAcccount"
                  disabled={isSaveVisible || isDisabled}
                  color="info"
                  size="sm"
                  type="button"
                  className={
                    "floatRight" +
                    (isSaveVisible || isDisabled ? " btn-dark" : null)
                  }
                  onClick={() => {
                    newRow();
                  }}
                >
                  New Account Type
                </Button>
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush ">
                  <MasterHeaderAuto headerList={headerList} />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                          !el["editMode"] ? (
                            //
                            <>
                              <Rows data={el} listToBeRendered={listToRender}>
                                <td className="text-center  white-breakSpace">
                                  <Button
                                    data-testid="editRow"
                                    color="info"
                                    size="sm"
                                    type="button"
                                    disabled={props.isViewOnly}
                                    onClick={() => editRow(index)}
                                  >
                                    <i className="fas fa-edit" />
                                  </Button>
                                  <Button
                                    data-testid="deleteRow"
                                    color="info"
                                    size="sm"
                                    type="button"
                                    disabled={props.isViewOnly}
                                    onClick={() => deleteRow(index)}
                                  >
                                    <i className="fas fa-trash" />
                                  </Button>
                                </td>
                              </Rows>
                            </>
                          ) : (
                            <tr key={index + "-class"}>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="accountTypeKey"
                                  maxLength="50"
                                  defaultValue={el["accountTypeKey"]}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      "accountTypeKey",
                                      true
                                    );
                                  }}
                                  placeholder="Account Type Key"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="accountTypeDesc"
                                  maxLength="50"
                                  defaultValue={el["accountTypeDesc"]}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      "accountTypeDesc",
                                      true
                                    );
                                  }}
                                  placeholder="Description"
                                  className="tdInput"
                                />
                              </td>
                              {/* <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength="10"
                                  defaultValue={el['accountTypeShortDesc']}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      'Desc',
                                      true
                                    );
                                  }}
                                  placeholder="Short description"
                                  className="tdInput"
                                />
                              </td> */}
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="accountTypeDispValue"
                                  maxLength="50"
                                  defaultValue={el["accountTypeDispValue"]}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      "accountTypeDispValue",
                                      true
                                    );
                                  }}
                                  placeholder="Display value"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="updatedOn"
                                  placeholder="Updated date"
                                  disabled
                                  className="tdInput"
                                  defaultValue={moment(el["updatedOn"]).format(
                                    "DD-MM-YYYY"
                                  )}
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <label className="custom-toggle mx-auto ml-2">
                                  <input
                                    data-testid="activeInactive"
                                    checked={
                                      el.status == "ACTIVE" ? true : false
                                    }
                                    type="checkbox"
                                    id={el.id}
                                    onChange={(e) => {
                                      settableDataKey(
                                        new Date().getMilliseconds()
                                      );
                                      let n = "ACTIVE";
                                      if (!e.target.checked) {
                                        n = "INACTIVE";
                                      }
                                      updateKey(index, n, "status");
                                    }}
                                  />
                                  <span
                                    style={{ width: "72px" }}
                                    className="custom-toggle-slider rounded-circle activeToggle"
                                    data-label-off="Inactive"
                                    data-label-on="Active"
                                  />
                                </label>
                              </td>
                              <td className="text-center">
                                {isSaveVisible ? (
                                  <>
                                    {" "}
                                    <Button
                                      data-testid="saveRow"
                                      color="info"
                                      size="sm"
                                      type="button"
                                      className="mx-1"
                                      onClick={() => {
                                        saveAll();
                                      }}
                                    >
                                      Save
                                    </Button>
                                    <Button
                                      data-testid="closeRow"
                                      color="danger"
                                      size="sm"
                                      type="button"
                                      onClick={() => {
                                        let n = props.classArray;
                                        if (n[index]["__status"] == "__new") {
                                          n.splice(index, 1);
                                        } else {
                                          // n[index]['editMode'] = false
                                          props.fetchClasses(
                                            `${masterServiceBaseUrl}/accountType`
                                          );
                                        }
                                        props.setclassArray(n);
                                        setisSaveVisible(false);
                                      }}
                                    >
                                      {" "}
                                      <i className="fas fa-times" />
                                    </Button>{" "}
                                  </>
                                ) : (
                                  <Button
                                    data-testid="deleteRow"
                                    color="info"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                      deleteRow(index);
                                    }}
                                  >
                                    {" "}
                                    <i className="fas fa-trash" />
                                  </Button>
                                )}
                              </td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
              {action === "edit" ? (
                <Button
                  data-testid="disabledBtn"
                  className={"floatRight ml-auto m-3"}
                  color="primary"
                  size="md"
                  type="button"
                  onClick={() => {
                    setIsDisabled(!isDisabled);
                  }}
                >
                  {isDisabled ? "Enable Form" : "Disabled Form"}
                </Button>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default AccountTypeTable;
