import React, { useContext, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { ReactComponent as BackIcon } from "assets/img/svg/BackButton.svg";
import { useHistory } from 'react-router';
import ListComponent from './ListComponent';
import { RolePermissions, PermissionContext } from 'appContext';
import { endpoint, pages } from '../../../../common/constant';
import { PermisionDenied } from '../../../../common/commonComponents';
import ALoader from '../../../../common/a-loader';
import { fetchAllPromisedData } from '../../../../common/utils/methods/commonMethods/utilityMethod';
import moment from 'moment';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'

const SAPPeriodStatus = () => {

  const history = useHistory();
  const userPermissions = RolePermissions(useContext(PermissionContext), pages['sapPeriodStatus']['id']);

  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);

  const restructureData = (data) => {
    let updatedData = {};

    data.forEach(item => {
      if (item?.sapPeriodStatus === 'OPEN') {
        updatedData = { ...updatedData, fromOpenPeriod: item?.fromCalenderYearMonth, toOpenPeriod: item?.toCalenderYearMonth, updatedOn: item?.updatedOn, companyCode: item?.companyCode }
      }
    })
    updatedData = {
      ...updatedData,
      rows: data,
    }

    return updatedData;
  }

  const fetchData = async () => {
    setLoader(true);

    const res = await fetchAllPromisedData(endpoint?.sapPeriodStatus?.getAllPeriodStatus, true);
    if (res?.code === 200) {
      setData(restructureData(res?.data || []));
    } else {
      setData({});
    }

    setLoader(false);
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    userPermissions ? (
      <div className={`${styles.page_container} remove-container-top-space`}>
        {loader && <ALoader position='fixed' />}
        <div className='d-flex align-items-center heading-4 color-dark'>
          <IconButtonWrapper onClick={() => history.goBack()}><BackIcon /></IconButtonWrapper>
          <div className='ml-2'>SAP period status</div>
        </div>
        <div className={`${styles.cards_container} color-dark`}>
          <div>
            <div className='semi-bold mb-2'>Company code</div>
            <div className='heading-4'>{data?.companyCode || '-'}</div>
          </div>
          <div>
            <div className='semi-bold mb-2'>Open period</div>
            <div className='heading-4 d-flex'>
              <div className='flex-grow-1'>From: {data?.fromOpenPeriod || '-'}</div>
              <div className='flex-grow-1'>To: {data?.toOpenPeriod || '-'}</div>
            </div>
          </div>
          <div>
            <div className='semi-bold mb-2'>Last updated</div>
            <div className='heading-4'>{data?.updatedOn ? moment(data?.updatedOn).format('DD-MMM-YYYY, hh:mm:ss A') : '-'}</div>
          </div>
        </div>
        <ListComponent
          rows={data?.rows}
        />
      </div>
    ) : (
      <PermisionDenied />
    )
  )
}

export default SAPPeriodStatus