import React, { useEffect, useState } from "react";
import { fetchAllPromisedData } from "../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "../../common/constant";
import { Card, CardBody } from "reactstrap";
import CustomLoader from "../../common/commonComponents/Loader/loader";
import { getCategory } from "../studentCharges/studentChargesDetails/concessionDetails";
import { LightTooltip } from "../studentCharges/studentChargesDetails/concessionDetails/prospectus";

const FeeBreakupCard = ({ applicationId , concessionType="DEFINED" , allCategories}) => {

    const FEES = ['Category', 'Tuition Fee', 'Class Fee', 'Registration Fee', 'Tech Fee','Admission Fee'];
    const FIELDS = ['headerDispVal', 'tutionFeeConcession', 'classFeeConcession', 'regFeeConcession', 'techFeeConcession', 'adminFeeConcession'];
    const rowNumbers = [0, 1, 2, 3, 4,5];

    const [concessionData, setConcessionData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData(applicationId);
    }, [applicationId])

    const fetchData = async (applicationId) => {
        setLoading(true);
        try {
            const data = await fetchAllPromisedData(endpoint.studentChargesConcessionDetailsConcession.getByApplicationId + `/${applicationId}`, false);
            // console.log('concessionDetails',data);
            const filteredData = data.filter(el=>el.concessionType===concessionType);
            const tableData = filteredData?.map(item => {
                const categoryInfo = getCategory(allCategories, item.concessionSubCategoryId, item.concessionSubSubCategoryId, item?.isLumpSumCategory)[1];
                return {
                ...item,
                headerDispVal : concessionType === 'DEFINED' ? 
                categoryInfo?.subSubCategoryDisplayValue ?? categoryInfo?.subCategoryDispVal ?? categoryInfo?.displayVal
                : 'Custom'
                }
            })
            setConcessionData(tableData);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const cellStyles = {
        fontSize: '14px',
        // whiteSpace:'initial'
        paddingTop:'8px',
        width:'60px',
        textOverflow : 'ellipsis',
        whiteSpace : 'nowrap',
        overflow : 'hidden',
    }

    const rowStyles = {
        height: '35px',
        display: 'flex',
        justifyContent: 'space-between'
    }

    const getRow = (rowNo) => {
        let total = 0;
        let suffix;
        let prefix;
        return (
            <tr style={{ ...rowStyles, borderBottom: !rowNo ? '1px solid #E3E3E3' : 'none' }}>
                <th style={{...cellStyles, width:'120px', marginRight:'7px'}}>{FEES[rowNo]}</th>
                {
                    concessionData.map(el => {
                        suffix = el?.concessionParameter === 'PERCENTAGE' ? '%' : '';
                        prefix = el?.concessionParameter === 'AMOUNT' ? '₹' : '';
                        let cellValue = el?.[FIELDS[rowNo]];
                        if (rowNo !== 0) {
                            total += +cellValue
                            return (
                                <td style={cellStyles}>{cellValue ? prefix + cellValue + suffix : ' -'}</td>
                            )
                        } else return <th style={cellStyles}>
                            <LightTooltip title={cellValue || " -"}>
                            <span>{cellValue || ' -'}</span>
                            </LightTooltip>
                            </th>
                    })
                }
                {/* <th style={cellStyles}>{rowNo === 0 ? 'Total' : (total ? total + unit : " -")}</th> */}
            </tr>
        )
    }
    return (
        loading ? (<CustomLoader />) : (
            <Card style={{ minWidth: "400px", marginBottom: "0px" }}>
                <CardBody style={{ padding: '16px' }}>
                    <table style={{ width: '100%' }}>
                        {rowNumbers.map((rowNo) => getRow(rowNo))}
                    </table>
                </CardBody>
            </Card>
        )

    );
}

export default FeeBreakupCard;