import React, { useEffect, useCallback, useState, useRef } from "react";
import { Checkbox, FormControlLabel } from '@mui/material'
import AInput from "../../common/formFeilds/customInput/AInput";
import { Card, CardBody } from "reactstrap";
import { fetchAllPostPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint, regex } from '../../common/constant'
import './index.scss'
import { failureToast } from "../../common/utils/methods/toasterFunctions/function";

const _ = require('lodash');

const checkBoxStyle = { borderRadius: "6px", padding: '0 5px' };
const formControlLabeStyle = {marginBottom: 0, marginRight: 0 };

const customSearchIconStyle = {
  right: '10px',
  top: 0,
  bottom: 0,
  margin: 'auto',
  inset: 'none'
}

const useOutsideClick = (ref, callback) => {
  useEffect(() => {
      const handleClickOutside = (evt) => {
          if (ref.current && !ref.current.contains(evt.target)) {
              callback(); //Do what you want to handle in the callback
          }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  });
};

const GetAddForm = ({ dropdown,
  setSelectedFaculty }) => {

  const wrapperRef = useRef(null);

  const [value, setValues] = useState('');
  const [inputHaveFocus, setInputHaveFocus] = useState(false);
  const [options, setOption] = useState([]);
  const [isFacultyId,setFacultyId] = useState(false)
  const [fetching,setFetching] = useState(false)
  const [visible,setVisible] = useState(false)

  useOutsideClick(wrapperRef,() => setVisible(false));

  const getFaculties = async (searchStr) => {

    if(isFacultyId && searchStr.length < 3){
      failureToast("Please enter atleast three character")
      return
    }

    if(isFacultyId && !/[A-Za-z-]*\d{3,}/.test(searchStr)){
      failureToast("Please enter atleast three numeric characters")
      return
    }

    if(!isFacultyId && searchStr.length < 4){
      failureToast("Please enter atleast four character")
      return
    }

    setFetching(true)
    const request = {
      facultyNameOrfacultyId: searchStr,
      isFacultyId : isFacultyId,
      businessArea : dropdown.businessArea.map(i => i.value)
    }

    const response = await fetchAllPostPromisedData(`${endpoint.faculty.filter}?offset=0`, request, 'post');
    if (response.code === 200) {

      const revisedOptions = response.data.searchFacultyMasterDtoList.map(item => {
        return {
          ...item,
          subject: (dropdown && dropdown.subject.filter(sub => item?.subject.indexOf(sub.value) > -1).map(filtedSub => filtedSub.label)) ?? ''
        }
      })
      setFetching(false)
      setOption(revisedOptions)
      setVisible(true)
    } else {
      setFetching(false)
      setOption([])
    }

  }

  // const changeHandler = value => {
  //   if (value?.length > 3) {
  //     getFaculties(value)
  //   } else {
  //     setOption([]);
  //   }
  //   setValues(value)
  // };
  // const debouncedChangeHandler = useCallback(_.debounce(changeHandler, 300), []);
  const onKeyDown = (e) => {
    if (((isFacultyId && value.length > 2) || (!isFacultyId && value.length > 3)) && (e.key === 'Enter' || e.keyCode === 13)) {
      getFaculties(value)
    } 
  }

  const onKeyPress= (e) => {
    if (
      !regex.alphabetsWithSpace.test(
        String.fromCharCode(e.charCode)
      ) && !isFacultyId
    ) {
      e.preventDefault();
      return;
    }
  }


  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <span className="regular-bold color-dark">Search By</span>
        <div>
        <FormControlLabel
          sx={{...formControlLabeStyle}}
          control={
            <Checkbox
              // onChange={(e) => e.target.checked && setSearchInputParam("applicationId")}
              onClick={()=>{setFacultyId(false);setOption([]);setValues('')}}
              checked={!isFacultyId}
              sx={checkBoxStyle}
            />
          }
          label={<span style={{ fontSize: '12px' }}>Faculty Name</span>}
        />
        <FormControlLabel
          sx={{...formControlLabeStyle, paddingLeft:5}}
          control={
            <Checkbox
              // onChange={(e) => e.target.checked && setSearchInputParam("applicationId")}
              onClick={()=>{setFacultyId(true);setOption([]);setValues('')}}
              checked={isFacultyId}
              sx={checkBoxStyle}
            />
          }
          label={<span style={{ fontSize: '12px' }}>Faculty Id</span>}
        />
        </div>
      </div>
      <AInput
        style={{ height: 40 }}
        label=''//{isFacultyId ? "Faculty Id" : "Faculty name"}
        value={value}
        rightSearchIcon={isFacultyId && value?.length > 2 ? true : value?.length > 3 ? true : false }
        crossIcon
        handler={(val) => setValues(val)}
        onFous={() => setInputHaveFocus(true)}
        placeholder={fetching ? 'Searcing...' : isFacultyId ? "Faculty Id" : "Faculty name"}
        searchIconClickHandler={()=>getFaculties(value)}
        crossHandler={()=>{setValues('');setOption([]);setSelectedFaculty(null)}}
        loading={fetching}
        onFocus={()=>setVisible(true)}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        // customSearchIconStyle={customSearchIconStyle}
      />
     
      {options?.length && visible ? <Card style={{zIndex : 1000}}>
        <CardBody
          className="input-suggetion-wrapper"
        >
          <div className="input-suggetions" ref={wrapperRef}>
            {
              options.map(option => {
                return <div className="input-suggetion-items" onClick={() => { setValues(`${option.facultyName} | ${option.facultyId} | ${option.subject.join(", ")}`); setSelectedFaculty(option); setOption([]) }}>
                  {`${option.facultyName} | ${option.facultyId} | ${option.subject.join(", ")}`}
                </div>
              })
            }
            {/* <div className="input-suggetion-items">Pankaj Singh | A1007 | Phy, Che, Bio, Mat, & Zol</div>
            <div className="input-suggetion-items">Pankaj Verma | A9904 | Phy, Che, Bio, Mat, & Zol</div>
            <div className="input-suggetion-items">Pankaj Verma | A9904 | Mat</div> */}
          </div>
        </CardBody>
      </Card> : null}
      <div><span className="regular-small">{isFacultyId ? 'Please provide atleast 3 digits *' : 'Please provide atleast 4 characters *'}</span></div>
    </div>

  );
};

export default GetAddForm;
