import React from "react";
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";

const FormBySubjects = ({ subjects, form, formHandler,subjectFacultyDropdown }) => {
  return (
    <div className="mb-3">
      {subjects.map((subject) => (
        <AAutoComplete
          key = {subject?.courseId}
          height="40px"
          currentValue={form?.[subject["subjectName"]]||[]}
          handleChange={(value) => formHandler(value, subject["subjectName"])}
          fontSize="12px"
          isMulti={false}
          label={`${subject["subjectName"]} faculty`}
          labelStyle = {{fontWeight:"600" , color: "#262626"}}
          placeholder={`Select ${subject["subjectName"]} faculty `}
          items={subjectFacultyDropdown[subject["subjectName"]]||[]}
          isShowCount = {true}
        />
      ))}
    </div>
  );
};

export default FormBySubjects;
