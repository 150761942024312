import React, { useEffect, useState } from "react";
import { Card, CardHeader, Col, Row } from "reactstrap";
import CommonInput from "../../common/formFeilds/input/commonInput";
import arrowLeft from "../../../../../assets/arrowLeft.png";
import CustomLoader from "../../common/commonComponents/Loader/loader";
import { endpoint, pages } from "../../common/constant";
import { dropdownMapping } from "../../unAssigned/studentCharges/common.js";
import {
  fetchAllPostPromisedData,
  fetchAllPromisedData,
} from "../../common/utils/methods/commonMethods/utilityMethod.js";
import CustomButton from "../../../../../components/CustomButton";
import "./index.scss";
import {
  failureToast,
  successToast,
} from "../../common/utils/methods/toasterFunctions/function";
import { useHistory, useParams } from "react-router";
import moment from "moment";
import { putpost } from "../../../../../services/utils";
import ConfirmationBackDialog from "../../common/commonComponents/ConfirmationDialog/backConfirmation";
import { RouterPrompt } from "../../common/commonComponents/ConfirmationDialog/routerPrompt";
import StatusDialog from "views/pages/manage/masters/onlinePackage/StatusDialog";

const OnlinePackageForm = () => {
  const history = useHistory();
  const { id, action } = useParams();

  const [values, setValues] = useState({
    onlinePackageName: "",
    academicGroupMasterId: "",
    classMasterId: "",
    examBoardTypeMasterId: "",
    academicYearId: "",
    validFrom: "",
    validTo: "",
    status: "ACTIVE",
    cmsPackage: [],
    academicCareerId: [],
    feature: [],
  });
  const [dropdown, setDropdown] = useState({
    fetching: true,
    data: {
      career: [],
      academicGroup: [],
      academicYear: [],
      class: [],
      academicBoard: [],
      features: [],
    },
  });
  const [cmspackage, setCmsPackage] = useState({ fetching: false, data: [] });
  const [cmsCourseMap, setCourseMap] = useState({});
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataRetrived, setDataRetrived] = useState(false);
  const [isViewOnly, setViewOnly] = useState(false);
  const [touched, setTouched] = useState(false);
  const [backConfirmation, setBackConfirmation] = useState(false);
  const [savedOrUpdated, setSavedOrUpdated] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState({
    active: false,
    inactive: false,
    selectedValue: "",
  });
  const getInitailData = async () => {
    const p = await Promise.all([
      dropdownMapping(
        endpoint.academic_group.getAllActive,
        "academicGroupDispValue"
      ),
      dropdownMapping(
        endpoint.academic_career.getAllActive,
        "academicCareerDispValue"
      ),
      // dropdownMapping(endpoint.academicPhase.getAllActive, 'academicPhaseDisplayValue'),
      dropdownMapping(
        endpoint.academic_year.getAllActive,
        "academicYearDisplayValue"
      ),
      dropdownMapping(
        endpoint.examBoardType.getAllActive,
        "examBoardTypeDispValue"
      ),
      // dropdownMapping(endpoint.languageMaster.getActiveLanguageMaster, 'languageDispValue'),
      // dropdownMapping(endpoint.classType.getAllActive, 'classTypeDispValue'),
      // dropdownMapping(endpoint.region.getAllActive, 'regionDispValue'),
      dropdownMapping(endpoint.features.all, "feature"),
      dropdownMapping(endpoint.classes.getAllActive, "classDispValue"),
    ]);

    // con

    const selectAllCheckboxInFeatures = p?.[4]
      ? [{ label: "Select All", value: "*" }, ...p[4]]
      : [];

    setDropdown({
      fetching: false,
      data: {
        career: p?.[1] ?? [],
        academicGroup: p?.[0] ?? [],
        academicYear: p?.[2] ?? [],
        class: p?.[5] ?? [],
        academicBoard: p?.[3] ?? [],
        features: selectAllCheckboxInFeatures,
      },
    });
  };

  const fetchCmsPackage = async () => {
    // setCmsPackageLoader(true);
    setCmsPackage({ fetching: true, data: [] });
    const request = {
      streamId: values.academicGroupMasterId?.value,
      classId: values.classMasterId?.value,
      boardId: values.examBoardTypeMasterId?.value,
      academicYearId: values.academicYearId?.value,
    };
    // console.log(request)
    const res = await fetchAllPostPromisedData(
      endpoint?.academicPlanTests?.getPackagesByCourseOrPackageId,
      request,
      "post"
    );
    // console.log(res);
    if (res?.code === 200) {
      //   setCmsPackageDropdown(getCmsPackageDropdownMapping(res?.data?.packages));
      const cmsPackage = [];
      const cmsCourseData = {};
      res.data.packages.map((item) => {
        cmsPackage.push({ label: item.packageExtId, value: item.packageId });
        cmsCourseData[item.packageId] = item.courses.map((i) => {
          return { label: i.subjectName, value: Number(i.courseId) };
        });
      });
      setCmsPackage({ fetching: false, data: cmsPackage });
      setCourseMap(cmsCourseData);
    } else {
      //   setCmsPackageDropdown([]);
      setCmsPackage({ fetching: false, data: [] });
      setCourseMap({});
      failureToast("Unable to fetch CMS Packages please try again");
    }
    // setCmsPackageLoader(false);
  };

  const addNewRow = () => {
    const currentPackages = [...values.cmsPackage];
    const newPackage = {
      cmsPackage: "",
      cmsCourse: [],
    };
    setValues({ ...values, cmsPackage: [newPackage, ...currentPackages] });
  };

  const handleCMSChange = (index, val, key) => {
    let cp = [...values.cmsPackage];
    let tempObj = cp[index];
    tempObj[key] = val;
    if (key === "cmsPackage") {
      tempObj["cmsCourse"] = [];
    }
    cp[index] = tempObj;
    setValues({ ...values, cmsPackage: [...cp] });
  };

  const handleSave = () => {
    setSaving(true);
    if (!values.onlinePackageName) {
      failureToast("Please enter online package name");
      setSaving(false);
      return;
    }

    if (!values.academicCareerId?.length) {
      failureToast("Please select atleast one academic career");
      setSaving(false);
      return;
    }

    if (!values.academicGroupMasterId) {
      failureToast("Please select academic group");
      setSaving(false);
      return;
    }

    if (!values.classMasterId) {
      failureToast("Please select class");
      setSaving(false);
      return;
    }

    if (!values.examBoardTypeMasterId) {
      failureToast("Please select academic board");
      setSaving(false);
      return;
    }

    if (!values.academicYearId) {
      failureToast("Please select academic year");
      setSaving(false);
      return;
    }

    if (!values.feature.length) {
      failureToast("Please select atleast one feature");
      setSaving(false);
      return;
    }

    if (!values.validFrom) {
      failureToast("Please select valid from date");
      setSaving(false);
      return;
    }

    if (!values.validTo) {
      failureToast("Please select valid to date");
      setSaving(false);
      return;
    }

    if (!values.cmsPackage.length) {
      failureToast("Please select atlease one cms package");
      setSaving(false);
      return;
    }

    let isValid = true;

    values.cmsPackage.every((i, index) => {
      if (!i.cmsPackage?.value) {
        failureToast(`Please select cms package in row ${index + 1}`);
        isValid = false;
        return false;
      } else if (!i.cmsCourse?.length) {
        failureToast(
          `Please select atleast one cms course in row ${index + 1}`
        );
        isValid = false;
        return false;
      } else {
        return true;
      }
    });

    setSaving(true);

    const request = {
      onlinePackageName: values.onlinePackageName,
      academicGroupMasterId: values.academicGroupMasterId?.value,
      classMasterId: values.classMasterId?.value,
      examBoardTypeMasterId: values.examBoardTypeMasterId?.value,
      academicYearId: values.academicYearId?.value,
      validFrom: values.validFrom,
      validTo: values.validTo,
      status: action === "edit" ? values.status?.value : "ACTIVE",
      cmsPackage: values.cmsPackage.map((i) => {
        return {
          cmsPackageId: i.cmsPackage?.value,
          cmsPackageName: i.cmsPackage?.label,
          cmsCourse: i.cmsCourse
            .filter((c) => c.value !== "*")
            .map((c) => {
              return {
                cmsCourseId: c.value,
                cmsSubjectName: c.label?.toString().includes("_")
                  ? c.label?.toString().split("_")[0]
                  : c.label?.toString(),
              };
            }),
        };
      }),
      academicCareerId: values.academicCareerId.map((i) => i.value),
      feature: values.feature
        .filter((i) => i.value !== "*")
        .map((i) => i.value),
    };

    const url =
      action === "edit"
        ? `${endpoint.onlinePackage.package}/${id}`
        : endpoint.onlinePackage.package;
    const method = action === "edit" ? "put" : "post";

    putpost(
      url,
      (data) => {
        setSaving(false);
        successToast(data.message);
        setSavedOrUpdated(true);
        history.push("/admin/onlinePackage");
      },
      (data) => {
        failureToast(data.message);
        setSaving(false);
      },
      request,
      method
    );
  };

  const getCmsDropdownOption = () => {
    const usedIds = values.cmsPackage.map((i) => Number(i.cmsPackage?.value));
    const nonSelectedOptions = cmspackage.data
      .filter((i) => usedIds.indexOf(Number(i.value)) === -1)
      .map((p) => {
        return { ...p, label: `${p?.label} - ${p?.value}` };
      });
    return nonSelectedOptions ?? [];
  };

  const fetchSaveRecord = async (id) => {
    const res = await fetchAllPromisedData(
      `${endpoint.onlinePackage.package}/${id}`,
      true
    );
    if (res?.code === 200) {
      const temp = [];
      res.data.cmsPackage.map((i) => {
        const newPackage = {
          cmsPackage: { label: i.cmsPackageName, value: i.cmsPackageId },
          cmsCourse: i.cmsCourse.map((c) => {
            return { value: Number(c.cmsCourseId), label: c.cmsSubjectName };
          }),
        };
        temp.push(newPackage);
      });
      const response = {
        onlinePackageName: res.data?.onlinePackageName ?? "",
        academicGroupMasterId:
          dropdown.data.academicGroup.filter(
            (i) => i.value === res.data?.academicGroupMasterId
          )?.[0] ?? "",
        classMasterId:
          dropdown.data.class.filter(
            (i) => i.value === res.data?.classMasterId
          )?.[0] ?? "",
        examBoardTypeMasterId:
          dropdown.data.academicBoard.filter(
            (i) => i.value === res.data?.examBoardTypeMasterId
          )?.[0] ?? "",
        academicYearId:
          dropdown.data.academicYear.filter(
            (i) => i.value === res.data?.academicYearId
          )?.[0] ?? "",
        validFrom: res.data.validFrom,
        validTo: res.data.validTo,
        status: { label: res.data.status, value: res.data.status },
        cmsPackage: temp,
        academicCareerId:
          dropdown.data.career.filter(
            (i) => res.data?.academicCareerId.indexOf(i.value) > -1
          ) ?? [],
        feature: res.data?.feature.map((i) => {
          return { label: i, value: i };
        }),
      };

      setValues({ ...response });
      setDataRetrived(true);
      getCmsDropdownOption();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (action === "create" || action === "edit" || action === "view") {
      if (id && action === "edit" && !dropdown.fetching && !dataRetrived) {
        setLoading(true);
        fetchSaveRecord(id);
      }
      if (id && action === "view" && !dropdown.fetching && !dataRetrived) {
        setLoading(true);
        setViewOnly(true);
        fetchSaveRecord(id);
      }
    } else {
      history.goBack();
    }
  }, [action, id, dropdown]);

  useEffect(() => {
    if (
      values.academicGroupMasterId?.value &&
      values.academicYearId?.value &&
      values.classMasterId?.value &&
      values.examBoardTypeMasterId?.value
    ) {
      fetchCmsPackage();
    }
  }, [
    values.academicGroupMasterId,
    values.academicYearId,
    values.classMasterId,
    values.examBoardTypeMasterId,
  ]);

  useEffect(() => {
    getInitailData();
  }, []);

  // useEffect(()=>{
  //     if(history.action === 'pop'){
  //         history.block();
  //         setBackConfirmation(true)
  //     }
  // },[history])

  const removePackage = (index) => {
    const temData = [...values.cmsPackage];
    temData.splice(index, 1);
    setValues({ ...values, cmsPackage: [...temData] });
  };

  return (
    <>
      {/* <RouterPrompt when={touched && !savedOrUpdated} onOK={() => true}
        onCancel={() => false}/> */}

      <div className="pb-4 online-package">
        <div
          style={{ margin: "20px", gap: 20 }}
          className="d-flex align-items-center"
        >
          <img
            width={20}
            src={arrowLeft}
            className="cursor"
            onClick={
              touched
                ? () => setBackConfirmation(true)
                : () => history.push("/admin/onlinePackage")
            }
          />
          <div style={{ color: "#0F0F0F" }} className="regular-large-bold">
            Create online package
          </div>
        </div>
        {dropdown.fetching || loading ? (
          <CustomLoader
            apiLoader={dropdown.fetching || loading}
            loaderHeight={"200px"}
            loaderWidth={"100% "}
          />
        ) : (
          <>
            <Card className="p-4 m-3">
              <Row>
                <Col md="3">
                  {/* <div className='label-text label-color'>Name*</div> */}
                  <CommonInput
                    type="text"
                    mandatory={true}
                    placeHolder="Enter name"
                    label="Name"
                    defaultValue={values.onlinePackageName}
                    onChange={(value) => {
                      setValues({ ...values, onlinePackageName: value });
                      setTouched(true);
                    }}
                    isDisabled={isViewOnly}
                    spellcheck="false"
                  />
                </Col>
                <Col md="3">
                  <CommonInput
                    label="Career"
                    type={"multiSelectWithCheckboxes"}
                    mandatory={true}
                    placeHolder="Select Career"
                    defaultValue={values.academicCareerId}
                    onChange={(value) => {
                      setValues({ ...values, academicCareerId: value });
                      setTouched(true);
                    }}
                    data={dropdown.data.career}
                    isDisabled={isViewOnly || action === "edit"}
                  />
                </Col>
                <Col md="3">
                  <CommonInput
                    label="Academic group"
                    type={"select"}
                    mandatory={true}
                    placeHolder="Select academic group"
                    defaultValue={values.academicGroupMasterId}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        academicGroupMasterId: value,
                        cmsPackage: [],
                      });
                      setTouched(true);
                    }}
                    data={dropdown.data.academicGroup}
                    isDisabled={isViewOnly || action === "edit"}
                  />
                </Col>
                <Col md="3">
                  {/* <div className='label-text label-color'>CMS courses*</div> */}
                  <CommonInput
                    label="Class"
                    type={"select"}
                    mandatory={true}
                    placeHolder="Select class"
                    defaultValue={values.classMasterId}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        classMasterId: value,
                        cmsPackage: [],
                      });
                      setTouched(true);
                    }}
                    data={dropdown.data.class}
                    isDisabled={isViewOnly || action === "edit"}
                  />
                </Col>
                <Col md="3">
                  {/* <div className='label-text label-color'>CMS courses*</div> */}
                  <CommonInput
                    label="Academic Board"
                    type={"select"}
                    mandatory={true}
                    placeHolder="Select academic board"
                    defaultValue={values.examBoardTypeMasterId}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        examBoardTypeMasterId: value,
                        cmsPackage: [],
                      });
                      setTouched(true);
                    }}
                    data={dropdown.data.academicBoard}
                    isDisabled={isViewOnly || action === "edit"}
                  />
                </Col>
                <Col md="3">
                  {/* <div className='label-text label-color'>CMS courses*</div> */}
                  <CommonInput
                    label="Academic Year"
                    type={"select"}
                    mandatory={true}
                    placeHolder="Select academic year"
                    defaultValue={values.academicYearId}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        academicYearId: value,
                        cmsPackage: [],
                      });
                      setTouched(true);
                    }}
                    data={dropdown.data.academicYear}
                    isDisabled={isViewOnly || action === "edit"}
                  />
                </Col>
                <Col md="3">
                  {/* <div className='label-text label-color'>Features*</div> */}
                  <CommonInput
                    label="Feature"
                    type={"multiSelectWithCheckboxes"}
                    mandatory={true}
                    placeHolder="Select feature"
                    defaultValue={values.feature}
                    onChange={(value) => {
                      setValues({ ...values, feature: value });
                      setTouched(true);
                    }}
                    data={dropdown.data.features}
                    isDisabled={isViewOnly}
                  />
                </Col>
                <Col md="3">
                  {/* <div className='label-text label-color'>Valid from*</div> */}
                  <CommonInput
                    label="Valid from"
                    type={"datePickerV2"}
                    mandatory={true}
                    placeHolder="Select Valid from"
                    defaultValue={values.validFrom}
                    onChange={(value) => {
                      setValues({ ...values, validFrom: value, validTo: "" });
                      setTouched(true);
                    }}
                    isDisabled={isViewOnly || action === "edit"}
                    minDate={new Date()}
                  />
                </Col>
                <Col md="3">
                  {/* <div className='label-text label-color'>Valid to*</div> */}
                  <CommonInput
                    label="Valid to"
                    type={"datePickerV2"}
                    mandatory={true}
                    placeHolder="Select Valid to"
                    defaultValue={values.validTo}
                    onChange={(value) => {
                      setValues({ ...values, validTo: value });
                      setTouched(true);
                    }}
                    isDisabled={isViewOnly}
                    minDate={
                      values.validFrom > new Date()
                        ? values.validFrom
                        : new Date()
                    }
                  />
                </Col>
                {action === "edit" && (
                  <Col md="3">
                    <CommonInput
                      label="Status"
                      type={"select"}
                      mandatory={true}
                      placeHolder="Select Status"
                      defaultValue={values.status}
                      onChange={(value) => {
                        if (values.status.value != value.value) {
                          if (value.value === "INACTIVE") {
                            setIsDialogOpen({
                              ...isDialogOpen,
                              inactive: true,
                              selectedValue: value,
                            });
                          } else {
                            setIsDialogOpen({
                              ...isDialogOpen,
                              active: true,
                              selectedValue: value,
                            });
                          }
                        }
                        setTouched(true);
                      }}
                      data={[
                        { label: "ACTIVE", value: "ACTIVE" },
                        { label: "INACTIVE", value: "INACTIVE" },
                      ]}
                    />
                  </Col>
                )}
              </Row>
            </Card>
            <StatusDialog
              open={isDialogOpen}
              setOpen={setIsDialogOpen}
              values={values}
              setValues={setValues}
            />
            <Card className="px-4 py-2 m-3">
              <div className="d-flex justify-content-between align-items-center">
                <h4>Add CMS Package</h4>
                {!isViewOnly ? (
                  <CustomButton
                    content={"New Row"}
                    permissionType={"C"}
                    permissionSet={"C"}
                    onClick={() => addNewRow()}
                    disabled={!cmspackage.data?.length}
                  />
                ) : null}
              </div>
            </Card>
            {/* <Card className="px-4 py-2 m-3 cms-packages mb-2"> */}
            {values?.cmsPackage.map((p, index) => {
              return (
                <div
                  className="d-flex px-2 py-2 m-3 cms-packages align-items-center"
                  style={{
                    background: "#fff",
                    boxShadow: "0 0 2rem 0 rgba(13)",
                  }}
                >
                  <Col md="5">
                    <CommonInput
                      label="CMS Package"
                      type={"select"}
                      mandatory={true}
                      placeHolder="Select CMS Package"
                      defaultValue={
                        values.cmsPackage[index].cmsPackage.label
                          ? {
                              ...values.cmsPackage[index].cmsPackage,
                              label: `${values.cmsPackage[index].cmsPackage.label} - ${values.cmsPackage[index].cmsPackage.value}`,
                            }
                          : ""
                      }
                      onChange={(value) => {
                        const val = value.label.split(" - ");
                        val.pop();
                        const newLabel = val.join("");
                        handleCMSChange(
                          index,
                          { ...value, label: newLabel },
                          "cmsPackage"
                        );
                        setTouched(true);
                      }}
                      data={loading ? [] : getCmsDropdownOption()}
                      isDisabled={isViewOnly}
                    />
                  </Col>
                  <Col md="5">
                    <CommonInput
                      label="CMS Course Subject"
                      type={"multiSelectWithCheckboxes"}
                      mandatory={true}
                      placeHolder="Select CMS Course Subject"
                      defaultValue={values.cmsPackage[index].cmsCourse}
                      onChange={(value) => {
                        handleCMSChange(index, value, "cmsCourse");
                        setTouched(true);
                      }}
                      data={
                        values.cmsPackage[index].cmsPackage?.value
                          ? [
                              { label: "Select All", value: "*" },
                              ...cmsCourseMap[
                                values.cmsPackage[index].cmsPackage?.value
                              ],
                            ]
                          : []
                      }
                      isDisabled={isViewOnly}
                    />
                  </Col>
                  <Col
                    md="2"
                    className="d-flex justify-content-end align-items-center"
                  >
                    {!isViewOnly ? (
                      <CustomButton
                        icon={true}
                        permissionType={"D"}
                        permissionSet={"D"}
                        onClick={() => {
                          removePackage(index);
                        }}
                      />
                    ) : null}
                  </Col>
                </div>
              );
            })}
            {/* </Card> */}
            {!isViewOnly ? (
              <div
                className="d-flex justify-content-end px-4 py-2 m-3 "
                style={{ background: "#fff", boxShadow: "0 0 2rem 0 rgba(13)" }}
              >
                <CustomButton
                  content={
                    saving ? (
                      <i
                        className="fas fa-spinner fa-spin"
                        aria-hidden="true"
                      ></i>
                    ) : action === "edit" ? (
                      "Update"
                    ) : (
                      "Save"
                    )
                  }
                  permissionType={"C"}
                  permissionSet={"C"}
                  onClick={handleSave}
                />
              </div>
            ) : null}
          </>
        )}
        <ConfirmationBackDialog
          isOpen={backConfirmation}
          onAgree={() => {
            history.goBack();
          }}
          onDisagree={() => setBackConfirmation(false)}
        />
      </div>
    </>
  );
};

export default OnlinePackageForm;
