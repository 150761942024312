import React from "react";
import { Card, CardHeader, Input, Container, Button, Table, Row } from "reactstrap";
import { useState } from "react"
// import "views/pages/manage/addressType/node_modules/react-sliding-pane/dist/react-sliding-pane.css";
import { failureToast } from '../../../common/utils/methods/toasterFunctions/function'
import { TDROW } from "../../../common/commonComponents/masterRow/masterRow";
import {MasterHeader , Rows} from "../../../common/commonComponents/masterHeader/masterHeader";
import Select2 from "react-select2-wrapper";
import { getAPI,masterServiceBaseUrl, successToast, putpost, putpost2 } from 'services/http'
import moment from 'moment';
import CustomDatePicker from '../../../common/commonComponents/CustomDatePicker/index';

var _ = require('lodash');

const ApprovalMatricTable = ( props ) => {
  const {setIsDisabled,isDisabled,action } = props;
  const [isSaveVisible, setisSaveVisible] = useState(false)
  const [tableDataKey, settableDataKey] = useState('tableDataKey')
  const [updatedKey,setUpdateKey] = useState(new Date())
  const addNewClass = () => {
    props.setclassArray([{ "editMode": true, "__status": "__new", 'status': 'ACTIVE',"desc":null, "courseAttributeId": null, "attributeValueId": null,"academicCareerId":null,"termId":null, "updatedDate": null }, ...props.classArray])
  }
 
  const itemsFormMaster = ['courseAttributeId','courseAttributeDesc','attributeValueId','academicCareerId','termId']
  const arraylist = [props.courseAttributeList,props.courseAttributeList,props.attributeList,props.acadmicList,props.termList]
  const listToRender = ['courseAttributeId','courseAttributeDesc','attributeValueId','academicCareerId','termId','updatedOn','status']

  const saveAll = (cb) => {
    var _tempArray = props.classArray
    var isValid = true
    _tempArray.forEach((el, index) => {
      if (el['editMode']) {
        if (el['courseAttributeId'] == null ) {
          isValid = false
          failureToast('Please Select Course Attribute')
          return
        } else if (el['attributeValueId'] == null ) {
          isValid = false
          failureToast('Please Select Attribute Value')
          return
        }
        else if (el['academicCareerId'] == null ) {
          isValid = false
          failureToast('Please Select Academic Career')
          return
        }else if (el['termId'] == null ) {
          isValid = false
          failureToast('Please Select Term')
          return
        }
        if (!isValid) {
          return
        }
        if (_tempArray[index]['__status'] == "__new") {
          _tempArray[index]["businessAreaId"] = props.formId
          putpost(masterServiceBaseUrl + '/concessionApprovalSetup/createConcessionApprovalSetup', (data) => {
            successToast(data['message'])
            setisSaveVisible( false );
            props.fetchClasses(`${masterServiceBaseUrl}/concessionApprovalSetup/getConcessionApprovalSetupByBusinessAreaId/${props.formId}`)
            setUpdateKey(new Date())
          }, (data) => { failureToast(data['message']) }, _tempArray[index], 'post')
        } else {
          putpost(masterServiceBaseUrl + '/concessionApprovalSetup/updateConcessionApprovalSetup/' + _tempArray[index].id, (data) => {
            successToast(data['message'])
            setisSaveVisible(false)
            // delete _tempArray[index]['editMode']
            // props.setclassArray(_tempArray)
            props.fetchClasses(`${masterServiceBaseUrl}/concessionApprovalSetup/getConcessionApprovalSetupByBusinessAreaId/${props.formId}`)
            settableDataKey(new Date().getMilliseconds())
            // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
          }, (data) => { failureToast(data['message']) }, _tempArray[index], 'put')
        }
      } else {

      }
    })
  }
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes")
    }
    else {
      let cb = () => {
        addNewClass()
        setisSaveVisible(true)
        // settableDataKey(new Date().getMilliseconds())
      }
      var _tempArray = props.classArray
      _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
      console.log(_tempArray)
      props.setclassArray(_tempArray)
      setisSaveVisible(false)
      cb()
    }
  }
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes")
    }
    else {
      let cb = () => {
        var _tempArray = props.classArray
        _tempArray[index]['editMode'] = true
        props.setclassArray(_tempArray)
        setisSaveVisible(true)
      }
      var _tempArray = props.classArray
      _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
      cb()
      getData(`${masterServiceBaseUrl}/courseAttributeValueMapping/getCourseAttributeValueByCourseAttributeIdAndGroupCodeIdAndCompanyCodeId/?groupCodeid=${props.groupCode}&companyCodeId=${props.companyCode}&courseAttributeId=${ _tempArray[index]['courseAttributeId']}`)
    }
  }
  const deleteRow = (index) => {
    if (props.classArray[index]["__status"] == "__new") {
      let n = props.classArray
      n.splice(index, 1)
      props.setclassArray(n)
      setisSaveVisible(false)
      settableDataKey(new Date().getMilliseconds())
    } else {
      if (window.confirm('Are you sure you want to delete this Approval Matric?')) {
        putpost2(masterServiceBaseUrl + '/concessionApprovalSetup/deleteConcessionApprovalSetup/' + props.classArray[index].id, (data) => {
          successToast(data['message'])
          if (props.classArray.length == 1) {
            props.previousPage()
            props.fetchClasses(`${masterServiceBaseUrl}/concessionApprovalSetup/getConcessionApprovalSetupByBusinessAreaId/${props.formId}`)
          }
          else {
            var _tempArray = props.classArray
            if (_tempArray[index]['editMode']) {
              setisSaveVisible(false)
            }
            _tempArray.splice(index, 1)
            props.setclassArray(_tempArray)
          }
          settableDataKey(new Date().getMilliseconds())
        }, (data) => { failureToast(data['message']) }, 'PUT')
      }
    }
  }

  const [attibuteList,setAttributeList] =React.useState([])

  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray
    _tempArray[index][key] = value
    if(key==='courseAttributeId'){
      _tempArray[index]['desc'] = getDesc(value,props.ca)
    }
    props.setclassArray(_tempArray)
    setUpdateKey(new Date())
    if(key==='courseAttributeId' && value!==null && value!=='') {     
      setAttributeList([])
      getData(`${masterServiceBaseUrl}/courseAttributeValueMapping/getCourseAttributeValueByCourseAttributeIdAndGroupCodeIdAndCompanyCodeId/?groupCodeid=${props.groupCode}&companyCodeId=${props.companyCode}&courseAttributeId=${value}`)
    }
  }

  const getData=(url)=>{
    getAPI(url, 
    (data) => {
      let dropList = []
      
      dropList = data.data.map( item => {
        return [...dropList,getDesc(item.courseAttributeValueId,props.attributeList,'sort')];
      })
      console.log(dropList)
      setAttributeList(...dropList)       
    },
    (data) => { 
      // failureToast(data['message']); 
    }
  )
  }
  
  const getDesc = (id,list,role)=>{
    const i =  list.filter(item=>{return item.id == id})
    if(role){
      console.log(i)
      return i.length ? i[0] : null
    }else{
      return i.length ? i[0]['courseAttributeDesc'] : ''
    }  
    
  }
  
  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
                <CardHeader className="border-0">
                  { action === 'new' ?
                    <h3 className="mb-0 floatLeft">Master - Approval Matrix</h3> : null }
                  { !isDisabled ?
                    <Button
                      disabled={ isSaveVisible || props.isViewOnly }
                      color="info"
                      size="sm"
                      type="button"
                      className={ "floatRight mx-1" + ( isSaveVisible ? " btn-dark" : null ) }
                      onClick={ () => { newRow() } }
                    >New Approval Matrix
                </Button> : null }
                  {/* {isSaveVisible?<Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => {saveAll()}}>Save</Button>:null} */ }
                </CardHeader> 
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush " >
                  <MasterHeader isDisabled={ isDisabled}/>
                  <tbody className="list" key={tableDataKey} >
                    {props.classArray ? props.classArray.map((el, index) =>
                      !el['editMode'] ? (
                        // 
                        <>
                          <Rows  data={el} functionItemsFromList={itemsFormMaster} listArray={arraylist} listToBeRendered={listToRender} ismodifiedList={true}>
                            <td>
                              <Button disabled={isDisabled} color={el['totalApprovalLevel'] ? 'info' : 'primary'} size="sm" type="button" onClick={()=>{props.setApprovals(el)}} >
                                <i className="fas fa-edit" />Add Approval
                              </Button>
                            </td>
                            { !isDisabled ?
                              <td>
                              <Button color="info" size="sm" type="button" disabled={props.isViewOnly} onClick={()=>editRow(index)}><i className="fas fa-edit" /></Button>
                              <Button color="info" size="sm" type="button" disabled={props.isViewOnly} onClick={()=>deleteRow(index)}><i className="fas fa-trash" /></Button>
                            </td>:null}
                          </Rows>
                        </>
                      ) : 
                        (<tr key={index + '-class'}>
                          <td className="text-center p-2 mx-1"><Select2 defaultValue={el['courseAttributeId']} data={props.courseAttributeList} onChange={(e)=>{updateKey(index,e.target.value,'courseAttributeId',true)}} options={{placeholder: "Select Course Attribute"}} className="tdSelect" /></td>
                          <td className="text-center p-2 mx-1"><Input  defaultValue={props.ca && getDesc(el['courseAttributeId'],props.ca)} disabled className="tdInput" /></td>
                          <td className="text-center p-2 mx-1"><Select2 defaultValue={el['attributeValueId']} data={attibuteList} onChange={(e)=>{updateKey(index,e.target.value,'attributeValueId',true)}} options={{placeholder: "Select Attribute Value"}} className="tdSelect" /></td>
                          <td className="text-center p-2 mx-1"><Select2 defaultValue={el['academicCareerId']} data={props.acadmicList} onChange={(e)=>{updateKey(index,e.target.value,'academicCareerId',true)}} options={{placeholder: "Select Academic Career"}} className="tdSelect" /></td>
                          <td className="text-center p-2 mx-1"><Select2 defaultValue={el['termId']} data={props.termList} onChange={(e)=>{updateKey(index,e.target.value,'termId',true)}} options={{placeholder: "Select Term"}} className="tdSelect" /></td>
                          
                          <td className="text-center p-2 mx-1">
                            <CustomDatePicker 
                                  disabled={true}
                                  value={
                                      el["__status"] == "__new"
                                      ? null
                                      : moment(el["updatedOn"]).toDate()
                                  }
                                  placeholder="Example:01-01-2020"
                              />
                            {/* <Input disabled={true} defaultValue={el["__status"] == "__new" ? null : moment(el['updatedDate']).format('DD-MM-YYYY')}  className="tdInput" /> */}
                          </td>
                          <td className="text-center p-2 mx-1">
                            <label className="custom-toggle mx-auto ml-2">
                              <input checked={el.status == "ACTIVE" ? true : false} type="checkbox" id={el.id} onChange={(e) => { settableDataKey(new Date().getMilliseconds()); console.log(e.target.checked); let n = "ACTIVE"; if (!e.target.checked) { n = "INACTIVE" } updateKey(index, n, 'status') }} />
                              <span style={{ width: "72px" }} className="custom-toggle-slider rounded-circle activeToggle" data-label-off="Inactive" data-label-on="Active" />
                            </label>
                          </td>
                          <td><Button disabled={isSaveVisible}  color="info" size="sm" type="button" className={""+(isSaveVisible?" btn-dark":null)}>Add Approvals</Button></td>
                          <td className="text-center">
                            {isSaveVisible ? <> <Button color="info" size="sm" type="button" className="mx-1" onClick={() => { saveAll() }}>Save</Button>
                              <Button color="danger" size="sm" type="button" onClick={() => {
                                let n = props.classArray
                                if (n[index]['__status'] == '__new') {
                                  n.splice(index, 1)
                                } else {
                                  // n[index]['editMode'] = false
                              props.fetchClasses(`${masterServiceBaseUrl}/concessionApprovalSetup/getConcessionApprovalSetupByBusinessAreaId/${props.formId}`)
                                }
                                props.setclassArray(n)
                                setisSaveVisible(false)
                              }}>  <i className="fas fa-times" /></Button> </> :
                              <Button color="info" size="sm" type="button" onClick={() => {
                                deleteRow(index)
                              }}>  <i className="fas fa-trash" /></Button>}
                          </td>
                        </tr>
                        )
                    ) : null}
                  </tbody>
                </Table>
              </div>
              { action === 'edit' ?
                <Button
                className={ 'floatRight ml-auto m-3' }
                color="primary"
                size="md"
                type="button"
                onClick={()=>{setIsDisabled(!isDisabled)}}
              >
                {isDisabled ? 'Enable Form' : 'Disabled Form'}
              </Button>:null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
export default ApprovalMatricTable;
