import { Autocomplete, FormControl, CircularProgress, MenuItem, Select, styled, TextField } from '@mui/material';
import React from 'react';

const StyledAutocomplete = styled(Autocomplete)`
  .MuiInputBase-input {
    height: 34px;
    font-size: 15px;
    padding-left : 12px !important;
    color : rgba(0, 0, 0, 0.6) !important;
  }

  .MuiFormLabel-root {
      background-color: rgb(245, 251, 254);
  }
`;

const MUISelect = ({
    label = "Select",
    placeholder = null,
    inputKey,
    currentValue,
    items = [],
    isFetching = false,
    disabled = false,
    required = false,
    handleChange = () => { }
}) => {
    return (
        <StyledAutocomplete
            disablePortal
            fullWidth
            key={inputKey}
            id="fee-cal-combo-box"
            options={items}
            value={currentValue}
            loading={isFetching}
            disabled={disabled}
            clearIcon={false}
            renderInput={(params) => <TextField {...params} label={placeholder || label} key={placeholder} variant="outlined"  InputProps={{
                ...params.InputProps,
                endAdornment: (
                    <React.Fragment>
                        {isFetching ? <CircularProgress color="inherit" size={20} style={{marginRight: '30px'}}/> : null}
                        {params.InputProps.endAdornment}
                    </React.Fragment>
                ),
            }} />}
            onChange={(event, newValue) => { handleChange(newValue, inputKey) }}
        />
    )
}

export default MUISelect