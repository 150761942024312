import { endpoint } from "views/pages/manage/common/constant";
import { dropdownMapping } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { updateRequest } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
export const getDropdowns = async () => {
    const dropdown = await Promise.all([
      dropdownMapping(
        endpoint.academic_career.getAllActive,
        "academicCareerDispValue",
        "id"
      ),
      dropdownMapping(
        endpoint.groupCode.getAllActive,
        "groupCodeDispValue",
        "id"
      ),
      dropdownMapping(
        endpoint.companyCode.getAllActive,
        "companyCodeDispValue",
        "id"
      )
    
    ]);
    return {
      academicCareer: dropdown[0],
      businessGroup: dropdown[1],
      companyCode: dropdown[2],
      mode: [{label:'OFFLINE',value:'OFFLINE'},{label:'ONLINE',value:'ONLINE'}]
    };
}

export const getCreateReqBody = (rowData) => {
  console.log("rowData",rowData)
    return updateRequest({
      companyCode: rowData?.companyCode?.label,
      businessGroup: rowData?.businessGroup?.label,
      status: rowData?.status,
      academicCareer: rowData?.academicCareer?.label,
      itemType: rowData?.itemType,
      mode: rowData?.mode?.label,
      id: rowData?.id,
    });
  };
  export const newRow = {
    businessGroup: "",
    companyCode: "",
    academicCareer: "",
    itemType: "",
    mode: "",
    status: "ACTIVE",
  };