import React, { useState } from "react";
import ConsolidatedFeeDetails from "./ConsolidatedFeeDetails";
import StudentFeeSummary from "./StudentFeeSummary";
import FeeBreakupSummary from "./FeeBreakupSummary";
import { useFetchPaymentDetails } from "./service";
import ALoader from "../../../common/a-loader";

const PaymentSummary = ({ currentApplicationDetails }) => {
  const [feeSummaryDialog, setFeeSummaryDialog] = useState({
    isOpen: false,
    data: null,
  });
  const [paymentDetails, isLoading] = useFetchPaymentDetails(
    currentApplicationDetails
  );

  const openSummaryDialog = (data) => {
    setFeeSummaryDialog({ isOpen: true, data });
  };

  return (
    <div>
      {isLoading && <ALoader position="fixed" />}
      <ConsolidatedFeeDetails paymentDetails={paymentDetails} />
      <StudentFeeSummary
        paymentDetails={paymentDetails}
        openSummaryDialog={openSummaryDialog}
      />
      <FeeBreakupSummary
        setFeeSummaryDialog={setFeeSummaryDialog}
        feeSummaryDialog={feeSummaryDialog}
      />
    </div>
  );
};

export default PaymentSummary;
