import React from 'react';
import { Tooltip } from '@mui/material';
import { AGridColDef } from '../common/data-grid/ADataGridColDef';
import moment from 'moment';

export const pendingDispatchTableColumn = [
  new AGridColDef('psId', 'PSID')
    .setMinWidth(150)
    .renderCellComponent(params => (
      <Tooltip title={params?.value}>
        <div>{params?.value}</div>
      </Tooltip>
    )),

  new AGridColDef('name', 'Full name')
    .setMinWidth(200)
    .renderCellComponent(params => (
      <Tooltip title={params?.value}>
        <div className={`ellipsis-text-container`}>{params?.value}</div>
      </Tooltip>
    )),
  new AGridColDef('term', 'Term').setMinWidth(150),
  new AGridColDef('punchDate', 'Date', false).setMinWidth(150),
  new AGridColDef('status', 'Status').setMinWidth(150),
  new AGridColDef('punchTime', 'Punch Time').setMinWidth(150).setValueGetter(params => params?.value ? moment(params?.value, 'hh:mm A') : '')
    .setValueFormatter(params => params?.value ? moment(params?.value)?.format('hh:mm A') : '-'),
  new AGridColDef('remarks', 'Remarks').setMinWidth(220).setValueGetter(params => params?.value || '-').renderCellComponent(params => (
    <Tooltip title={params?.value}>
      <div className={`ellipsis-text-container`}>{params?.value}</div>
    </Tooltip>
  )),
  new AGridColDef('applicationId', 'Application no.')
    .setMinWidth(150)
    .renderCellComponent(params => (
      <Tooltip title={params?.value}>
        <div className={`ellipsis-text-container`}>{params?.value}</div>
      </Tooltip>
    )),
  new AGridColDef('rollNumber', 'Roll no.')
    .setMinWidth(150)
    .renderCellComponent(params => (
      <Tooltip title={params?.value}>
        <div className={`ellipsis-text-container`}>{params?.value}</div>
      </Tooltip>
    )),
  new AGridColDef('batchName', 'Batch Name')
    .setMinWidth(150)
    .renderCellComponent(params => (
      <Tooltip title={params?.value}>
        <div className={`ellipsis-text-container`}>{params?.value}</div>
      </Tooltip>
    )),
];
