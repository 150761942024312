import React from 'react';
import { Table } from 'reactstrap';
import {
	BankCodeHeader,
	BANKCODETHROW,
} from '../../common/commonComponents/masterRow/masterRow';
import { GetPagination } from '../../common/pagination';
import {
	masterServiceBaseUrl,
	failureToast,
	deleteAPI,
	successToast,
} from 'services/http';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

const Bank = (props) => {
	let history = useHistory();
	const {
		country,
		bankCodeArray,
		setBankCodeArray,
		searchDataList,
		setSearchDataList,
		fetchSearchList,
		previousPage,
		lastPage,
		firstPage,
		nextPage,
		setPagination,
		pagination,
	} = props;
	const [change, setChange] = useState(false);

	const getIndex = (dataList, id) => {
		try {
			let index = dataList.findIndex((item) => item['id'] === id);
			return index >= 0 ? index : '';
		} catch (e) {
			console.log('Error', e);
		}
	};

	const deleteRecordFromState = (id) => {
		try {
			let indexForHouseArray = getIndex(bankCodeArray, id);
			let indexForSearchDataArray = getIndex(searchDataList, id);
			let temp = bankCodeArray;
			let __temp = searchDataList;
			temp.splice(indexForHouseArray, 1);
			__temp.splice(indexForSearchDataArray, 1);
			setBankCodeArray(temp);
			setSearchDataList(__temp);
			setChange(!change);
		} catch (e) {
			console.log('Error', e);
		}
	};

	const deleteRecord = (id) => {
		try {
			if (
				window.confirm('Are you sure you want to delete this Bank Record ?')
			) {
				deleteAPI(
					masterServiceBaseUrl + `/bank/deleteBank/${id}`,
					(data) => {
						deleteRecordFromState(id);
						successToast(data['message']);
					},
					(data) => {
						failureToast(data['message']);
					},
					'Delete'
				);
			}
		} catch (e) {
			console.log('Error', e);
		}
	};

	return (
		<div id="questionTable" className="table-responsive">
			<Table className="align-items-center table-flush">
				<BankCodeHeader />
				<tbody className="list" key={'1'}>
					{bankCodeArray &&
						bankCodeArray.map((item, index) => (
							<BANKCODETHROW
								key={index}
								index={index}
								country={country}
								item={item}
								history={history}
								deleteRecord={deleteRecord}
							/>
						))}
				</tbody>
			</Table>
			{pagination.totalPage > 1 ? (
				<GetPagination
					fetchClasses={fetchSearchList}
					pagination={pagination}
					previousPage={previousPage}
					firstPage={firstPage}
					lastPage={lastPage}
					nextPage={nextPage}
					setPagination={setPagination}
				/>
			) : null}
		</div>
	);
};

export default Bank;
