import React, {Fragment, useContext, useEffect, useState} from "react";
import {Button, Card, CardBody, CardHeader, Container} from "reactstrap";
import {useHistory} from "react-router-dom";
import FeeSetupRulesSearchForm from "./feeSetupRulesSearchForm";
import FeeSetupRulesSearchTable from "./feeSetupRulesSearchTable";
import {fetchAllData, fetchDetails} from "../../../common/utils/methods/commonMethods/utilityMethod";
import {getAPI, masterServiceBaseUrl, pricingServiceBaseUrl} from "services/http";
import {
    successToast,
    failureToast,
} from '../../../common/utils/methods/toasterFunctions/function';
import {GetPagination} from "../../../common/commonComponents/pagination/pagination";
import {PermissionContext, RolePermissions} from "../../../../../../appContext";
import {pages} from "../../../common/constant";
import {PermisionDenied} from "../../../common/commonComponents";
import {ButtonText} from "../../../../../../variables/Buttons";
import CustomButton from "../../../../../../components/CustomButton";
import {button} from "./properties";
import CustomLoader from "../../../common/commonComponents/Loader/loader";

const FeeSetupRuleSearch = ()=>{
    let history = useHistory();

    const {label,placeHolder,message,headerTitle,searchHeaderTitle,searchHeaderList,headerList,button} = require('./properties/index.json');
    const [groupCode,setGroupCode] = useState([]);
    const [companyCode,setCompanyCode] = useState([]);
    const [academicCareer,setAcademicCareer] = useState([]);
    const [term,setTerm] = useState([]);
    const [searchData,setSearchData] = useState({
        groupCodeId:'',
        companyCodeId:'',
        academicCareerId:null,
        termId:null
    })
    const [pagination, setPagination] = useState({
        currentPage: 1,
        nextPage: null,
        previousPage: null,
        totalPage: null,
    });
    const pageSize = 10;
    const [tableData,setTableData] = useState([]);
    const [allTableData, setAllTableData] = useState([]);
    const [allAcademicCareerData,setAllAcademicCareerData] = useState([]);
    const [allTermData,setAllTermData] = useState([]);
    const [apiLoader,setApiLoader] = useState(true);

    const permissions = useContext(PermissionContext);

    const userPermissions = RolePermissions(
        permissions,
        pages['feeSetupRules']['id']
    );

    const UpdatePagination = (data) => {
        // console.log("data.....",data);
        let n = pagination;
        if (data.length > pageSize) {
            n["nextPage"] = n.currentPage + 1;
        }
        if (n.currentPage > 1) {
            n.previousPage = n.currentPage - 1;
        }
        n["totalPage"] = Math.floor(data.length / pageSize);
        if (data.length % pageSize !== 0) {
            n["totalPage"] += 1;
        }
        setPagination(n);
        setTableData(
            data.slice(
                pagination.currentPage * pageSize - pageSize,
                pagination.currentPage * pageSize
            ) || []
        );
        // setApiLoader(false);
    }
    const searchHandler = ()=>{
        try{
            if(searchData.groupCodeId === ''){
                failureToast(message.failure.businessGroup);
            }else if(searchData.companyCodeId === ''){
                failureToast(message.failure.companyCode);
            }else{
                setTableData([]);
                setPagination({currentPage: 1, nextPage: null, previousPage: null, totalPage: null,} );
                let url = `${pricingServiceBaseUrl}/searchFeeSetupRuleValue/${searchData.companyCodeId},${searchData.groupCodeId},${searchData.academicCareerId},${searchData.termId}`
                getAPI(
                    url,
                    (data) => {
                        if(data.data.length) {
                            setAllTableData([...data.data]);
                            UpdatePagination([...data.data]);
                        }
                        else if(data.data.length === 0){
                            failureToast("No Values Found.");
                        }
                    },
                    (data) => {
                        failureToast(data["message"]);
                    }
                );
            }
        }catch(e){
            console.error('Error in Search Handler in Fee Setup Rule Search.',e)
        }
    }

    const resetHandler = ()=>{
        setCompanyCode([])
        setSearchData({
            groupCodeId:'',
            companyCodeId:'',
            academicCareerId:null,
            termId:null
        })
        setTableData([]);
        setPagination({currentPage: 1, nextPage: null, previousPage: null, totalPage: null,} );
    }
    const updateSearchData =(key,value)=>setSearchData(preState => ({...preState, [key]: value}));
    const fetchRequiredData = () =>{
        fetchDetails(masterServiceBaseUrl + `/groupCode/getAllActiveGroupCode`, setGroupCode, 'groupCode', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + `/academicCareer/getAllActiveAcademicCareer`, setAcademicCareer, 'academicCareer', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + `/academicCareer/getAllAcademicCareer`, setAllAcademicCareerData, 'academicCareer', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + `/term/getAllActiveTerm`, setTerm, 'term', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + `/term/getAllTerm`, setAllTermData, 'term', 'reactSelect');
    }
    useEffect(() => {
        fetchRequiredData();
    },[])
    useEffect(() => {
        if(groupCode.length > 0 && academicCareer.length > 0 ){
            setApiLoader(false);
        }
    },[groupCode, academicCareer])
    const checkDisabled =()=>{
        return  searchData.groupCodeId === '' && searchData.companyCodeId === '' && searchData.academicCareerId === null && searchData.termId === null
    }

   return (
       apiLoader
           ?
           <CustomLoader apiLoader={apiLoader} loaderHeight={'200px'} loaderWidth={'100% '}/>
           :
        <Fragment>
            {
                userPermissions ? (
                        <Container fluid={true} className="p-4">
                            <Card>
                                <CardHeader>
                                    <h2 className="mb-0 floatLeft">{searchHeaderTitle}</h2>
                                    {/*<Button color="info" size="md" className="floatRight mx-1"*/}
                                    {/*        onClick={() => history.push(`/admin/feeSetupRules/new`)}>*/}
                                    {/*    {button.addNewFeeSetupRule}*/}
                                    {/*</Button>*/}
                                    <CustomButton
                                        className={'floatRight mx-1'}
                                        content={button.addNewFeeSetupRule}
                                        permissionType={'C'}
                                        icon={true}
                                        permissionSet={userPermissions}
                                        onClick={() => history.push(`/admin/feeSetupRules/new`)}
                                    />
                                </CardHeader>
                                <CardBody>
                                    <FeeSetupRulesSearchForm
                                        groupCode={groupCode}
                                        companyCode={companyCode}
                                        academicCareer={academicCareer}
                                        term={term}
                                        setCompanyCode={setCompanyCode}
                                        searchData={searchData}
                                        updateSearchData={updateSearchData}
                                        label={label}
                                        placeholder={placeHolder}
                                    />
                                    {/*<Button size="md" className="reset-button floatRight mx-1" onClick={resetHandler}*/}
                                    {/*        disabled={searchData.groupCodeId === '' && searchData.companyCodeId === '' && searchData.academicCareerId === null && searchData.termId === null}>*/}
                                    {/*    Reset*/}
                                    {/*</Button>*/}
                                    <CustomButton
                                        disabled={searchData.groupCodeId === '' && searchData.companyCodeId === '' && searchData.academicCareerId === null && searchData.termId === null}
                                        className={'reset-button floatRight mx-1'}
                                        content={'Reset'}
                                        type={'reset'}
                                        permissionType={'R'}
                                        permissionSet={userPermissions}
                                        onClick={resetHandler}
                                    />
                                    {/*<Button color="info" size="md" className="floatRight mx-1" onClick={searchHandler}>*/}
                                    {/*    Search*/}
                                    {/*</Button>*/}
                                    <CustomButton
                                        className={'floatRight mx-1'}
                                        content={'Search'}
                                        icon={true}
                                        permissionType={'S'}
                                        permissionSet={userPermissions}
                                        onClick={searchHandler}
                                    />
                                </CardBody>
                            </Card>
                            {
                                tableData && tableData.length > 0
                                    ?
                                    <FeeSetupRulesSearchTable
                                        tableData={tableData}
                                        allAcademicCareerData={allAcademicCareerData}
                                        allTermData={allTermData}
                                        searchHeaderList={searchHeaderList}
                                        permissionSet={userPermissions}/>
                                    :
                                    null
                            }
                            {
                                pagination.totalPage > 1
                                    ?
                                    <GetPagination
                                        setclassArray={setTableData}
                                        pagination={pagination}
                                        setpagination={setPagination}
                                        pageSize={pageSize}
                                        state={allTableData}
                                    />
                                    :
                                    null
                            }
                        </Container>
                    ) : (
                        <PermisionDenied/>
                    )}
        </Fragment>
    )
}

export default FeeSetupRuleSearch;