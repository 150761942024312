import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { IoMdArrowDropdown } from "react-icons/io";
import { Dialog } from "@mui/material";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import { Label, FormGroup, Input } from "reactstrap";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import { concessionRule } from "./constant";
import AInput from "views/pages/manage/common/formFeilds/customInput/AInput";

const ConcessionRuleSelectDialog = ({ params, rowFormHandler, rowForm }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedConcessionRule, setSelectedConcessionRule] = useState(
    rowForm?.concessionRule
  );
  const [percentage,setPercentage]= useState(
  {
    'rule2':concessionRule[1]==rowForm?.concessionRule? rowForm?.percentageDiscount:'',
    'rule3':concessionRule[2]==rowForm?.concessionRule? rowForm?.percentageDiscount:''
  })
  useEffect(() => {
    setSelectedConcessionRule(rowForm?.concessionRule);
    setPercentage( {
      'rule2':concessionRule[1]==rowForm?.concessionRule? rowForm?.percentageDiscount:'',
      'rule3':concessionRule[2]==rowForm?.concessionRule? rowForm?.percentageDiscount:''
    })
  }, [isOpen]);

  const saveHandler = () => {
    const res = selectedConcessionRule;
    rowFormHandler(res, "concessionRule");
    concessionRule[1]==res && rowFormHandler(percentage?.rule2,"percentageDiscount")
    concessionRule[2]==res && rowFormHandler(percentage?.rule3,"percentageDiscount")
    setIsOpen(false);
  };

  return (
    <>
      <div
        className={`${styles['select-concession-cell']}`}
        onClick={() => setIsOpen(true)}
      >
        <div className="regular-small ellipsis-text-container">
          {rowForm?.concessionRule ? rowForm?.concessionRule : 'Select'}
        </div>
        <IoMdArrowDropdown size={24} />
      </div>
      <Dialog
        open={isOpen}
        PaperProps={{ sx: { maxWidth: 'unset', borderRadius: '12px' } }}
      >
        <div className={`${styles['select-concession-popup']}`}>
          <div className="d-flex align-item-center justify-content-between">
            <div className="heading-4 color-dark">Set concession rule </div>
            <CrossIcon
              width={24}
              height={24}
              onClick={() => setIsOpen(false)}
            />
          </div>

          <div className={`${styles['added-concession']}`}>
            <FormGroup className="d-flex align-item-center">
              <div className="ml-4">
                <Label className="mr-5" check>
                  <Input
                    type="radio"
                    value={concessionRule[0]}
                    name="concessionType"
                    style={{ width: 20, height: 20 }}
                    checked={selectedConcessionRule == concessionRule[0]}
                    onChange={val => {
                      setSelectedConcessionRule(concessionRule[0]);
                      setPercentage({'rule2':'','rule3':''})
                    }}
                  />
                  <div className="ml-2 h-100 w-100 flex-column justify-content-center gap-4 d-inline-flex align-items-flex-start">
                    <div className="large-semi-bold label-color">
                      Complete concession
                    </div>
                    <div className="regular-body color-grey-60">
                      Complete Concession on Reg or Adm or both paid,
                      irrespective of how much paid
                    </div>
                  </div>
                </Label>
                <Label className="mr-5 mt-4" check>
                  <Input
                    type="radio"
                    value={concessionRule[1]}
                    name="concessionType"
                    style={{ width: 20, height: 20 }}
                    checked={selectedConcessionRule == concessionRule[1]}
                    onChange={val => {
                      setSelectedConcessionRule(concessionRule[1]);
                      setPercentage({...percentage,'rule3':''})
                    }}
                  />
                  <div
                    style={{
                      alignItems: 'flex-start',
                    }}
                    className="ml-2 h-100 w-100 flex-column justify-content-center gap-4 d-inline-flex"
                  >
                    <div className="large-semi-bold label-color">
                      The percentage of whatever is paid
                    </div>
                    <div className="regular-body color-grey-60">
                      The percentage of whatever is paid will be discounted on
                      Reg or Adm or Both components. Percentage filed will be
                      enabled to enter the %age value up to 100%
                    </div>
                    <div className="tiny-semi-bold label-color m-2">
                      Set percentage
                    </div>
                    <AInput
                      placeholder="Enter"
                      value={
                        percentage?.rule2
                      }
                      handler={value => {
                        if (!value || (parseFloat(value) >= 0 && parseFloat(value) <= 100))
                          setPercentage({...percentage,
                           'rule2':value
                              .replace(/[^0-9.]/g, '')
                              .replace(/(\..*)\./g, '$1')
                              .replace(/(\.\d{1,2}).*/g, '$1')
            
                      });
                      }}
                      customIcon={<div style={{ fontSize: '20px' }}>%</div>}
                      customIconStyle={{ right: '8px' }}
                      disabled={selectedConcessionRule != concessionRule[1]}
                      style={{
                        height: '45px',
                        width: '300px',
                        paddingRight: '20px',
                        borderRadius: '8px',
                        fontSize: '17px',
                      }}
                    />
                  </div>
                </Label>
                <Label className="mr-5 mt-4" check>
                  <Input
                    type="radio"
                    value={concessionRule[2]}
                    name="concessionType"
                    style={{ width: 20, height: 20 }}
                    checked={selectedConcessionRule == concessionRule[2]}
                    onChange={val => {
                      setSelectedConcessionRule(concessionRule[2]);
                      setPercentage({...percentage,'rule2':''})
                    }}
                  />
                  <div
                    style={{ alignItems: 'flex-start' }}
                    className="d-inline-flex  flex-column  justify-content-center w-100 h-100 gap-4 ml-2 "
                  >
                    <div className="large-semi-bold label-color">
                      The Percentage discount
                    </div>
                    <div className="regular-body color-grey-60">
                      The Percentage discount on Reg or Adm or Both components.
                      Percentage filed will be enabled to enter the %age value
                      up to 100%
                    </div>
                    <div className="tiny-semi-bold label-color m-2">
                      Set percentage
                    </div>
                    <AInput
                      placeholder="Enter"
                      value={
                        percentage?.rule3
                      }
                      handler={value => {
                        if (!value || (parseFloat(value) >= 0 && parseFloat(value) <= 100))
                          setPercentage({...percentage,
                           'rule3': value
                              .replace(/[^0-9.]/g, '')
                              .replace(/(\..*)\./g, '$1')
                              .replace(/(\.\d{1,2}).*/g, '$1')         
                      });
                      }}
                      customIcon={<div style={{ fontSize: '20px' }}>%</div>}
                      customIconStyle={{ right: '8px' }}
                      style={{
                        height: '45px',
                        width: '300px',
                        paddingRight: '20px',
                        borderRadius: '8px',
                        fontSize: '17px',
                      }}
                      disabled={selectedConcessionRule != concessionRule[2]}
                    />
                  </div>
                </Label>
              </div>
            </FormGroup>
          </div>

          <div>
            <AButton
              className="btn-left-0"
              variant="primary_filled"
              onClick={saveHandler}
              disabled={
                !selectedConcessionRule ||
                (selectedConcessionRule != concessionRule[0] &&
                  !(percentage?.rule2 || percentage?.rule3))
              }
            >
              Save
            </AButton>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ConcessionRuleSelectDialog;
