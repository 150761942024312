import CustomButton from "components/CustomButton";
import React, { useState } from 'react';
import {
  Card,
  CardHeader, Container, Input, Row, Table
} from 'reactstrap';
import {
  masterServiceBaseUrl, putpost,
  putpost2
} from 'services/http';
import { ButtonText } from "variables/Buttons";
import { MasterHeaderAuto } from "../../common/commonComponents/masterHeader/masterHeader";
import { MasterHeader3, TDROW3 } from '../../common/commonComponents/masterRow/masterRow';
import { endpoint } from "../../common/constant";
import { editTableRow, newTableRow } from "../../common/utils/methods/commonMethods/tableMethods";
import { fetchAllPostPromisedData, fetchDeletePromisedData } from "../../common/utils/methods/commonMethods/utilityMethod";
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import { customValidation } from "../../common/utils/methods/validations/validation";
import validationRules from './validation.json'

var _ = require('lodash');

const AddressCategoryTable = (props) => {

  const { permissionSet, isSaveVisible, setisSaveVisible = () => { }, getAllrecords = () => { } } = props;
  const [tableDataKey, settableDataKey] = useState('tableDataKey');

  const headerList = [
    {name: "ADDRESS CATEGORY TYPE", isRequired: true},
    // {name: 'EFFECTIVE DATE'},
    // {name: 'Description', isRequired: true},
    // {name: 'DISPLAY VALUE', isRequired: true},
    {name: 'Status'},
    {name: 'Action'}
  ]

  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: '__new',
        addressCategoryType: null,
        status: 'ACTIVE',
      },
      ...props.classArray,
    ]);
  };
  // const saveAll = (cb) => {
  //   var _tempArray = props.classArray;
  //   var isValid = true;
  //   _tempArray.forEach((el, index) => {
  //     if (el['editMode']) {
  //       if (
  //         el['addressCategoryType'] === null ||
  //         !el['addressCategoryType'].trim()
  //       ) {
  //         isValid = false;
  //         failureToast('Please enter the value of Address Category Type');
  //         return;
  //       }

  //       if (!isValid) {
  //         return;
  //       }
  //       if (_tempArray[index]['__status'] == '__new') {
  //         putpost(
  //           masterServiceBaseUrl + '/addressCategory/createAddressCategory',
  //           (data) => {
  //             successToast(data['message']);
  //             setisSaveVisible(false);
  //             props.fetchClasses(
  //               masterServiceBaseUrl + '/addressCategory/getAllAddressCategory'
  //             );
  //           },
  //           (data) => {
  //             failureToast(data['message']);
  //           },
  //           _tempArray[index],
  //           'post'
  //         );
  //       } else {
  //         putpost(
  //           masterServiceBaseUrl +
  //             '/addressCategory/updateAddressCategory/' +
  //             _tempArray[index].id,
  //           (data) => {
  //             successToast(data['message']);
  //             setisSaveVisible(false);
  //             delete _tempArray[index]['editMode'];
  //             props.setclassArray(_tempArray);
  //             settableDataKey(new Date().getMilliseconds());
  //             // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
  //           },
  //           (data) => {
  //             failureToast(data['message']);
  //           },
  //           _tempArray[index],
  //           'put'
  //         );
  //       }
  //     } else {
  //     }
  //   });
  // };
  const saveAll = async (index) => {
    let _data = props.classArray[index];
    let isValid = true;
    isValid = customValidation(_data, validationRules)
    if(!isValid) return;

    if (_data["__status"] == "__new"){
      const createRecord = await fetchAllPostPromisedData(endpoint.addressCategory.create, _data, 'post')
      if (createRecord?.code === 200) {
        successToast(createRecord["message"]);
        setisSaveVisible(false);
        getAllrecords()
      }
    } else {
      const updateRecord = await fetchAllPostPromisedData(`${endpoint.addressCategory.update}/${_data.id}`, _data, 'put');
      if (updateRecord?.code === 200) {
        successToast(updateRecord["message"]);
        setisSaveVisible(false);
        getAllrecords()
      }
    }
  }
  // const newRow = () => {
  //   if (isSaveVisible) {
  //     failureToast('Please Delete or Save current changes');
  //   } else {
  //     let cb = () => {
  //       addNewClass();
  //       setisSaveVisible(true);
  //       // settableDataKey(new Date().getMilliseconds())
  //     };
  //     var _tempArray = props.classArray;
  //     _tempArray = _.sortBy(_tempArray, [
  //       function (o) {
  //         return o.value;
  //       },
  //     ]);
  //     props.setclassArray(_tempArray);
  //     setisSaveVisible(false);
  //     cb();
  //   }
  // };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      newTableRow(props.classArray,props.setclassArray,setisSaveVisible,addNewClass);
    }
  };
  // const editRow = (index) => {
  //   if (isSaveVisible) {
  //     failureToast('Please Delete or Save current changes');
  //   } else {
  //     let cb = () => {
  //       var _tempArray = props.classArray;
  //       _tempArray[index]['editMode'] = true;
  //       props.setclassArray(_tempArray);
  //       setisSaveVisible(true);
  //     };
  //     var _tempArray = props.classArray;
  //     _tempArray = _.sortBy(_tempArray, [
  //       function (o) {
  //         return o.value;
  //       },
  //     ]);
  //     cb();
  //   }
  // };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      editTableRow(index,props.classArray,props.setclassArray,setisSaveVisible);
    }
  };
  // const deleteRow = (index) => {
  //   if (props.classArray[index]['__status'] == '__new') {
  //     let n = props.classArray;
  //     n.splice(index, 1);
  //     props.setclassArray(n);
  //     setisSaveVisible(false);
  //     settableDataKey(new Date().getMilliseconds());
  //   } else {
  //     if (
  //       window.confirm(
  //         'Are you sure you want to delete this Address Category ?'
  //       )
  //     ) {
  //       putpost2(
  //         masterServiceBaseUrl +
  //           '/addressCategory/deleteAddressCategory/' +
  //           props.classArray[index].id,
  //         (data) => {
  //           successToast(data['message']);
  //           if (props.classArray.length == 1) {
  //             props.previousPage();
  //             props.fetchClasses(
  //               masterServiceBaseUrl + '/addressCategory/getAllAddressCategory'
  //             );
  //           } else {
  //             var _tempArray = props.classArray;
  //             if (_tempArray[index]['editMode']) {
  //               setisSaveVisible(false);
  //             }
  //             _tempArray.splice(index, 1);
  //             props.setclassArray(_tempArray);
  //           }
  //           settableDataKey(new Date().getMilliseconds());
  //         },
  //         (data) => {
  //           failureToast(data['message']);
  //         },
  //         'Delete'
  //       );
  //     }
  //   }
  // };
  const deleteRow = async (index) => {
    if (window.confirm("Are you sure you want to delete this Address Category ?")) {
      const deleteRecord = await fetchDeletePromisedData(`${endpoint.addressCategory.delete}/${props.classArray[index].id}`);
      if(deleteRecord?.code === 200){
        successToast(deleteRecord['message']);
        let flag=false;
        if(props.classArray.length==1){
          flag=true;
        }
        getAllrecords(flag);
        settableDataKey(new Date().getMilliseconds());
      }
    }
  };
  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
  };
  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Address Category</h3>
                <CustomButton
                  data-testid="addNewRow"
                  disabled={isSaveVisible}
                  className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
                  content={ButtonText.AddressCategory.NEW}
                  icon={true}
                  permissionType={'C'}
                  permissionSet={permissionSet}
                  onClick={() => newRow()}
                />
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout">
                  {/* <MasterHeader3 type={'Address Category'} permissionSet={userPermissions} isSaveVisible={isSaveVisible} /> */}
                  <MasterHeaderAuto headerList={headerList} permissionSet={permissionSet} isSaveVisible={isSaveVisible} />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                        !el['editMode'] ? (
                          <TDROW3
                            type={'addressCategory'}
                            index={index}
                            el={el}
                            editRow={editRow}
                            deleteRow={deleteRow}
                            permissionSet={permissionSet}
                            isSaveVisible={isSaveVisible}
                          />
                        ) : (
                          <tr key={index + '-class'}>
                            <td className="text-center p-2 mx-1">
                              <Input
                                data-testid="addressCategoryType"
                                maxLength="50"
                                defaultValue={el['addressCategoryType']}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    e.target.value,
                                    'addressCategoryType'
                                  );
                                }}
                                placeholder="Address Category Type"
                                className="tdInput"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <label className="custom-toggle mx-auto ml-2">
                                <input
                                  data-testid="activeInactiveInput"
                                  checked={
                                    el.status == 'ACTIVE' ? true : false
                                  }
                                  type="checkbox"
                                  id={el.id}
                                  onChange={(e) => {
                                    settableDataKey(
                                      new Date().getMilliseconds()
                                    );
                                    let n = 'ACTIVE';
                                    if (!e.target.checked) {
                                      n = 'INACTIVE';
                                    }
                                    updateKey(index, n, 'status');
                                  }}
                                />
                                <span
                                  data-testid="activeInactiveLabel"
                                  style={{ width: '72px' }}
                                  className="custom-toggle-slider rounded-circle activeToggle"
                                  data-label-off="Inactive"
                                  data-label-on="Active"
                                />
                              </label>
                            </td>
                            <td className="text-center">
                              {isSaveVisible ? (
                                <>
                                  <CustomButton
                                    data-testId="saveBtn"
                                    className={'mx-1'}
                                    content={ButtonText.AddressCategory.UPDATE}
                                    forTable={true}
                                    permissionType={'C,U'}
                                    permissionSet={permissionSet}
                                    onClick={() => { saveAll(index); }}
                                  />
                                  <CustomButton
                                    data-testId="closeBtn"
                                    className={'mx-1'}
                                    permissionType={'cancel'}
                                    forTable={true}
                                    icon={true}
                                    permissionSet={permissionSet}
                                    onClick={() => {
                                      let n = props.classArray;
                                      if (n[index]["__status"] == "__new") {
                                        n.splice(index, 1);
                                      } else {
                                        // n[index]['editMode']=false
                                        getAllrecords();
                                      }
                                      setisSaveVisible(false);
                                    }}
                                  />
                                </>
                              ) : (
                                // <CustomButton
                                //   content={''}
                                //   permissionType={'D'}
                                //   icon={true}
                                //   forTable={true}
                                //   onClick={() => deleteRow(index)}
                                //   permissionSet={permissionSet}
                                // />
                                <></>
                              )}
                            </td>
                          </tr>
                        )
                      )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default AddressCategoryTable;
