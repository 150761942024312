import React, { useState, useEffect } from "react";
import {
	Card,
	CardHeader,
	Input,
	Container,
	Table,
	Row,
} from "reactstrap";
import { failureToast, successToast } from "../../common/utils/methods/toasterFunctions/function";
import { TDROW } from "../../common/commonComponents/masterRow/masterRow";
import moment from "moment";
import { ButtonText } from "variables/Buttons";
import CustomButton from "../../../../../components/CustomButton";
import { endpoint, constants } from "../../common/constant";
import { fetchAllPostPromisedData, fetchAllPromisedData, fetchDeletePromisedData } from "../../common/utils/methods/commonMethods/utilityMethod";
import validationRules from './validationRules.json'
import { customValidation } from "../../common/utils/methods/validations/validation";
import { editTableRow, newTableRow } from "../../common/utils/methods/commonMethods/tableMethods";
import { MasterHeaderAuto } from "../../common/commonComponents/masterHeader/masterHeader";
import { fetchDetails } from '../../common/utils/methods/commonMethods/utilityMethod';
import CommonInput from "../../common/formFeilds/input/commonInput";

var _ = require("lodash");

const PaymentModeTable = (props) => {
	const { groupCodeData, permissionSet, isSaveVisible, setisSaveVisible = () => { }, getAllrecords = () => { } } = props;
	const [tableDataKey, settableDataKey] = useState("tableDataKey");
	const [companyCodeList, setCompanyCodeList] = useState([]);
	const [companyCodeData, setCompanyCodeData] = useState([]);
	const [selectedGroupCode, setSelectedGroupCodeId] = useState('');
	const [paymentSequence, setPaymentSequence] = useState('');
	const [selectedCompanyCode,setSelectedCompanyCode] = useState('');
	const [selectedProcess,setSelectedProcess] = useState([]);
	const [selectedRoles,setSelectedRoles] = useState([]);
	const [paymentType,setPaymentType] = useState({label : 'UPFRONT', value: 'UPFRONT'});

	const headerList = [
		{ name: 'Group Code', isRequired: true },
		{ name: 'Company Code', isRequired: true },
		{ name: 'Key', isRequired: true },
		{ name: 'Effective Date' },
		{ name: 'Description', isRequired: true },
		{ name: 'Display Value', isRequired: true },
		{ name: 'Sap Paymode', isRequired: true },
		{ name: "Paymode Sequence", isRequired: true },
		{ name: "Process", isRequired: true },
		{ name: "Roles", isRequired: true },
		{ name: "Paymode Type", isRequired: true },
		{ name: "Receipt A/C Entry" },
		{ name: "Refund A/C Entry" },
		{ name: "Status" },
		{ name: 'ACTION' }
	]

	const [processData,setProcessData] = useState([]);
	const [roleData,setRoleData] = useState([]);



	const addNewClass = () => {
		props.setclassArray([
			{
				companyCode: null,
				groupCode: null,
				editMode: true,
				__status: '__new',
				paymentModeKey: null,
				status: 'ACTIVE',
				paymentModeDesc: null,
				paymentModeDispValue: null,
				sapPaymode: null,
				paymodeSequence: null,
				effectiveDate: null,
				paymodeType:null,
				role : [],
				process : []
			},
			...props.classArray,
		]);
	};
	const saveAll = async (index) => {
		let _data = props.classArray[index];
		let isValid = true;

		if(!selectedGroupCode?.value){
			failureToast('Please select Group Code');
			return
		}
		if(!selectedCompanyCode?.value){
			failureToast('Please select Company Code');
			return
		}

		isValid = customValidation(_data, validationRules);
		if (!isValid) {
			return;
		}
		if(!selectedProcess?.length){
			failureToast('Please select Process Type Tag');
			return
		}
		if(!selectedRoles?.length){
			failureToast('Please select roles');
			return
		}

		
		_data = {
			..._data,
			roleIds : selectedRoles.filter(item => item.value === '*')?.length ? [0] : selectedRoles.map(item => item.value),
			processTagTypeMasterIds :  selectedProcess.filter(item => item.value === '*')?.length ? [0] : selectedProcess.map(item => item.value),
			paymodeType : paymentType?.value
		}

		if (_data["__status"] == "__new") {
			const createRecord = await fetchAllPostPromisedData(endpoint.paymentMode.create, _data, 'post')
			if (createRecord?.code === 200) {
				successToast(createRecord["message"]);
				setisSaveVisible(false);
				getAllrecords()
			}
		} else {
			const createRecord = await fetchAllPostPromisedData(`${endpoint.paymentMode.update}/${_data.id}`, _data, 'put')
			if (createRecord?.code === 200) {
				successToast(createRecord["message"]);
				setisSaveVisible(false);
				getAllrecords()
			}
		}
	};
	const newRow = () => {
		if (isSaveVisible) {
			failureToast("Please Delete or Save current changes");
		} else {
			newTableRow(props.classArray, props.setclassArray, setisSaveVisible, addNewClass);
		}
	};
	const editRow = (index) => {
		if (isSaveVisible) {
			failureToast("Please Delete or Save current changes");
		} else {
			const _tempArr = [...props.classArray];
			const _tempData = _tempArr[index];

			setSelectedCompanyCode(companyCodeList?.filter(c => c.value === _tempData['companyCodeId'])?.[0] ?? '')
			setSelectedGroupCodeId(groupCodeData?.filter(c => c.value === _tempData['groupCodeId'])?.[0] ?? '')
			setPaymentSequence(_tempData['paymodeSequence'])

			const processSelected = _tempData['processTagTypeMasterIds']?.[0] === 0 ? [{value : '*',label : 'ALL' }, ...processData] : processData?.filter(p => _tempData['processTagTypeMasterIds'].indexOf(p.value) > -1);
			const roleSelected = _tempData['roleIds']?.[0] === 0 ? [{value : '*',label : 'ALL' },...roleData] : roleData?.filter(p => _tempData['roleIds'].indexOf(p.value) > -1);
			setSelectedProcess(processSelected)
			setSelectedRoles(roleSelected)
			setPaymentType({label :  _tempData?.paymodeType ,value: _tempData?.paymodeType})
			editTableRow(index, props.classArray, props.setclassArray, setisSaveVisible);
		}
	};
	const deleteRow = async (index) => {
		if (
			window.confirm("Are you sure you want to delete this Website Sync?")
		) {
			const deleteRecords = await fetchDeletePromisedData(`${endpoint.paymentMode.delete}/${props.classArray[index].id}`);
			if (deleteRecords?.code === 200) {
				let flag = false;
				if (props.classArray.length == 1) {
					flag = true;
				}
				getAllrecords(flag);
				settableDataKey(new Date().getMilliseconds());
			}
		}
		// }
	};
	const updateKey = (index, value, key) => {
		var _tempArray = props.classArray;
		_tempArray[index][key] = value;
		props.setclassArray(_tempArray);
	};

	const validate = (type, value) => {
		const numberValidation = /^[0-9\b]+$/;
		if (type === 'number') {
			if (value === '' || numberValidation.test(value)) {
				return true;
			}
		}
	};

	const getProcessData = async () => {
        const processData = await fetchAllPromisedData(endpoint.process_Tag.getAllActive);
        if (Array.isArray(processData)) {
            const tempArr = processData?.map(d => { return { label: d.tagTypeDispValue, value: d.id } })

            setProcessData(tempArr?.length ? [{value : '*',label : 'ALL' },...tempArr] : [] )
        } else {
            setProcessData([])
        }
    }

	const getRoleData = async () => {
        const roleData = await fetchAllPromisedData(endpoint.getRole.allActiveRoles);
        if (Array.isArray(roleData)) {
            const tempArr = roleData?.map(d => { return { label: d.roleName, value: d.id } })
            setRoleData(tempArr?.length ? [{value : '*',label : 'ALL' },...tempArr] : [] )
        } else {
            setRoleData([])
        }
    }


	useEffect(() => {
		fetchDetails(
			endpoint.companyCode.getAllActive,
			setCompanyCodeList,
			'companyCode',
			'select'
		);
		getProcessData();
		getRoleData();
	}, []);

	return (
		<>
			<Container className="mt-3" fluid>
				<Row>
					<div className="col">
						<Card>
							<CardHeader className="border-0">
								<h3 className="mb-0 floatLeft">Master - Payment Mode</h3>
								<CustomButton
									data-testId="addNewWebsiteSync"
									disabled={isSaveVisible}
									className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
									content={'New Payment Mode'}
									permissionType={'C'}
									icon={true}
									permissionSet={permissionSet}
									onClick={() => newRow()}
								/>
								{/* {isSaveVisible?<Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => {saveAll()}}>Save</Button>:null} */}
							</CardHeader>
							<div id="questionTable" className="table-role-responsive-layout">
								<Table className="align-items-center table-flush ">
									{/* <MasterHeader type={"Website Sync"} isSaveVisible={isSaveVisible} permissionSet={permissionSet} /> */}
									<MasterHeaderAuto headerList={headerList} isSaveVisible={isSaveVisible} permissionSet={permissionSet} />
									<tbody className="list" key={tableDataKey}>
										{props.classArray
											? props.classArray.map((el, index) =>
												!el["editMode"] ? (
													<TDROW
														companyCodeData={companyCodeList}
														groupCodeList={groupCodeData}
														type={'paymentMode'}
														index={index}
														el={el}
														editRow={editRow}
														deleteRow={deleteRow}
														isSaveVisible={isSaveVisible}
														permissionSet={permissionSet}
														processData={processData}
														roleData={roleData}
													/>
												) : (
													<tr key={index + "-class"}>
														<td className="text-center p-2 mx-1">
															<CommonInput
																type="select"
																defaultValue={selectedGroupCode}
																data={groupCodeData}
																data-testid="groupCodeId"
																forTable={true}
																onChange={(e) => {
																	setSelectedGroupCodeId(e);
																	updateKey(
																		index,
																		e.value,
																		'groupCodeId'
																	);
																	if (e) {
																		fetchDetails(
																			`${endpoint.companyCode.getAllActiveByGroupCode}/${e?.value}`,
																			setCompanyCodeData,
																			'companyCode',
																			'select'
																		);
																	}
																}}
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<CommonInput
																type="select"
																defaultValue={selectedCompanyCode}
																data-testid="companyCodeId"
																data={companyCodeData}
																forTable={true}
																// disabled={data['isEdit']}
																onChange={(e) => {
																	setSelectedCompanyCode(e)
																	updateKey(
																		index,
																		e.value,
																		'companyCodeId'
																	);
																}}
															// options={{
															// 	placeholder: 'Company Code',
															// }}
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="50"
																defaultValue={el['paymentModeKey']}
																data-testid="paymentModeKey"
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'paymentModeKey'
																	);
																}}
																placeholder="Key"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																disabled={true}
																defaultValue={
																	el['__status'] == '__new'
																		? null
																		: moment(el['eff_date']).format(
																			'DD-MM-YYYY'
																		)
																}
																placeholder="Example:01-01-2020"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="50"
																data-testid="paymentModeDesc"
																defaultValue={el['paymentModeDesc']}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'paymentModeDesc'
																	);
																}}
																placeholder="Description"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="50"
																data-testid="paymentModeDispValue"
																defaultValue={el['paymentModeDispValue']}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'paymentModeDispValue'
																	);
																}}
																placeholder="Display Value"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="2"
																data-testid="sapPaymode"
																defaultValue={el['sapPaymode']}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'sapPaymode'
																	);
																}}
																placeholder="Sap Paymode"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="2"
																data-testid="paymodeSequence"
																value={paymentSequence}
																onChange={(e) => {
																	let n = e.target.value;
																	if (validate('number', n)) {
																		setPaymentSequence(n);
																		updateKey(index, n, 'paymodeSequence');
																	}
																}}
																placeholder="Paymode Sequence"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<CommonInput
																type="multiSelectWithCheckboxes"
																defaultValue={selectedProcess}
																data={processData}
																forTable={true}
																// disabled={data['isEdit']}
																onChange={(e) => {
																	setSelectedProcess(e)
																	updateKey(
																		index,
																		e,
																		'process'
																	);
																}}
																placeHolder="Select Process"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<CommonInput
																type="multiSelectWithCheckboxes"
																defaultValue={selectedRoles}
																data={roleData}
																forTable={true}
																// disabled={data['isEdit']}
																onChange={(e) => {
																	setSelectedRoles(e)
																	updateKey(
																		index,
																		e,
																		'role'
																	);
																}}
																placeHolder="Select Roles"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<CommonInput
																type="select"
																defaultValue={paymentType}
																data={constants.paymentType}
																forTable={true}
																// disabled={data['isEdit']}
																onChange={(e) => {
																	setPaymentType(e)
																	updateKey(
																		index,
																		e,
																		'paymentType'
																	);
																}}
																placeHolder="Select Paymode Type"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<CustomButton
																data-testid="saveWebsiteSync"
																className={'mx-1'}
																content={"Add Receipt A/C Entry"}
																permissionType={'C,U'}
																forTable={true}
																permissionSet={permissionSet}
																onClick={() => {  }}
																disabled={isSaveVisible}
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<CustomButton
																data-testid="saveWebsiteSync"
																className={'mx-1'}
																content={"Add Refund A/C Entry"}
																permissionType={'C,U'}
																forTable={true}
																permissionSet={permissionSet}
																onClick={() => {  }}
																disabled={isSaveVisible}
															/>
														</td>

														<td className="text-center p-2 mx-1">
															<label className="custom-toggle mx-auto ml-2">
																<input
																	data-testid="activeInactiveWebsiteSync"
																	checked={
																		el.status == "ACTIVE" ? true : false
																	}
																	type="checkbox"
																	id={el.id}
																	onChange={(e) => {
																		settableDataKey(
																			new Date().getMilliseconds()
																		);
																		let n = "ACTIVE";
																		if (!e.target.checked) {
																			n = "INACTIVE";
																		}
																		updateKey(index, n, "status");
																	}}
																/>
																<span
																	data-testid="aciveInactiveLabelWebsiteSync"
																	style={{ width: "72px" }}
																	className="custom-toggle-slider rounded-circle activeToggle"
																	data-label-off="Inactive"
																	data-label-on="Active"
																/>
															</label>
														</td>
														<td className="text-center p-2 mx-1">
															{isSaveVisible ? (
																<>
																	<CustomButton
																		data-testid="saveWebsiteSync"
																		className={'mx-1'}
																		content={'Save'}
																		permissionType={'C,U'}
																		forTable={true}
																		permissionSet={permissionSet}
																		onClick={() => { saveAll(index) }}
																	/>
																	<CustomButton
																		data-testId="closeWebsiteSync"
																		className={'mx-1'}
																		permissionType={'cancel'}
																		forTable={true}
																		icon={true}
																		permissionSet={permissionSet}
																		onClick={() => {
																			let n = props.classArray;
																			if (n[index]["__status"] == "__new") {
																				n.splice(index, 1);
																			} else {
																				// n[index]['editMode']=false
																				props.getAllrecords();
																			}
																			// props.setclassArray(n);
																			setisSaveVisible(false);
																		}}
																	/>
																</>
															) : (
																<></>
															)}
														</td>
													</tr>
												)
											)
											: null}
									</tbody>
								</Table>
							</div>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};
export default PaymentModeTable;

// import React, { useEffect } from 'react';
// import {
// 	Card,
// 	CardHeader,
// 	Input,
// 	Container,
// 	Button,
// 	Table,
// 	Row,
// } from 'reactstrap';
// import { useState } from 'react';
// // import { failureToast } from '../../common/utils/methods/toasterFunctions/function';
// import {
// 	TDROW,
// 	MasterHeader,
// } from '../../common/commonComponents/masterRow/masterRow';
// import {
// 	masterServiceBaseUrl,
// 	successToast,
// 	putpost,
// 	putpost2,
// 	deleteAPI,
// } from 'services/http';
// import moment from 'moment';
// import Select2 from 'react-select2-wrapper';
// import { fetchDetails } from '../../common/utils/methods/commonMethods/utilityMethod';
// var _ = require('lodash');

// const PaymentModeTable = (props) => {
// 	const { groupCodeData } = props;
// 	const [companyCodeList, setCompanyCodeList] = useState();
// 	const [isSaveVisible, setisSaveVisible] = useState(false);
// 	const [paymentSequence, setPaymentSequence] = useState('');
// 	const [tableDataKey, settableDataKey] = useState('tableDataKey');
// 	const addNewClass = () => {
// 		props.setclassArray([
// 			{
// 				companyCode: null,
// 				groupCode: null,
// 				editMode: true,
// 				__status: '__new',
// 				paymentModeKey: null,
// 				status: 'ACTIVE',
// 				paymentModeDesc: null,
// 				paymentModeDispValue: null,
// 				sapPaymode: null,
// 				paymodeSequence: null,
// 				effectiveDate: null,
// 			},
// 			...props.classArray,
// 		]);
// 	};
// 	const saveAll = (cb) => {
// 		var _tempArray = props.classArray;
// 		var isValid = true;
// 		_tempArray.forEach((el, index) => {
// 			if (el['editMode']) {
// 				if (el['paymentModeKey'] === null || !el['paymentModeKey'].trim()) {
// 					isValid = false;
// 					failureToast('Please enter the value of Payment Mode Key');
// 					return;
// 				} else if (
// 					el['paymentModeDesc'] === null ||
// 					!el['paymentModeDesc'].trim()
// 				) {
// 					isValid = false;
// 					failureToast('Please enter the value of Description');
// 					return;
// 				} else if (
// 					el['paymentModeDispValue'] === null ||
// 					!el['paymentModeDispValue'].trim()
// 				) {
// 					isValid = false;
// 					failureToast('Please enter the value of Display Value');
// 					return;
// 				}
// 				if (!isValid) {
// 					return;
// 				} else {
// 					// _tempArray['groupCode'] = selectedGroupCode;
// 				}
// 				if (_tempArray[index]['__status'] == '__new') {
// 					putpost(
// 						masterServiceBaseUrl + '/paymentMode/createPaymentMode',
// 						(data) => {
// 							successToast(data['message']);
// 							setSelectedGroupCodeId(null);
// 							setisSaveVisible(false);
// 							setPaymentSequence('');
// 							props.fetchClasses(masterServiceBaseUrl + '/paymentMode/getAllPaymentMode');
// 							fetchDetails(
// 								masterServiceBaseUrl + '/companyCode/getAllActiveCompanyCode',
// 								setCompanyCodeList,
// 								'companyCode',
// 								'reactSelect'
// 							);
// 						},
// 						(data) => {
// 							failureToast(data['message']);
// 						},
// 						_tempArray[index],
// 						'post'
// 					);
// 				} else {
// 					putpost(
// 						masterServiceBaseUrl + '/paymentMode/updatePaymentMode/' + _tempArray[index].id,
// 						(data) => {
// 							successToast(data['message']);
// 							setisSaveVisible(false);
// 							setSelectedGroupCodeId(null);
// 							delete _tempArray[index]['editMode'];
// 							props.setclassArray(_tempArray);
// 							settableDataKey(new Date().getMilliseconds());
// 							props.fetchClasses(masterServiceBaseUrl + '/paymentMode/getAllPaymentMode');
// 							fetchDetails(
// 								masterServiceBaseUrl + '/companyCode/getAllActiveCompanyCode',
// 								setCompanyCodeList,
// 								'companyCode',
// 								'reactSelect'
// 							);
// 							setPaymentSequence('');
// 						},
// 						(data) => {
// 							failureToast(data['message']);
// 						},
// 						_tempArray[index],
// 						'put'
// 					);
// 				}
// 			} else {
// 			}
// 		});
// 	};

// 	useEffect(() => {}, [props.classArray]);
// 	const newRow = () => {
// 		if (isSaveVisible) {
// 			failureToast('Please Delete or Save current changes');
// 		} else {
// 			let cb = () => {
// 				setSelectedGroupCodeId(null);
// 				addNewClass();
// 				setisSaveVisible(true);
// 				// settableDataKey(new Date().getMilliseconds())
// 			};
// 			var _tempArray = props.classArray;
// 			_tempArray = _.sortBy(_tempArray, [
// 				function (o) {
// 					return o.value;
// 				},
// 			]);
// 			props.setclassArray(_tempArray);
// 			setisSaveVisible(false);
// 			cb();
// 		}
// 	};
// 	const editRow = (index) => {
// 		if (isSaveVisible) {
// 			failureToast('Please Delete or Save current changes');
// 		} else {
// 			let cb = () => {
// 				var _tempArray = props.classArray;
// 				_tempArray[index]['editMode'] = true;
// 				setPaymentSequence(_tempArray[index]['paymodeSequence']);
// 				setSelectedGroupCodeId(_tempArray[index]['groupCodeId']);
// 				props.setclassArray(_tempArray);
// 				fetchDetails(
// 					masterServiceBaseUrl +
// 						'/companyCode/getCompanyCodeByGroup/' +
// 						_tempArray[index]['groupCodeId'],
// 					setCompanyCodeData,
// 					'companyCode',
// 					'reactSelect'
// 				);
// 				setisSaveVisible(true);
// 			};
// 			var _tempArray = props.classArray;
// 			_tempArray = _.sortBy(_tempArray, [
// 				function (o) {
// 					return o.value;
// 				},
// 			]);
// 			cb();
// 		}
// 	};
// 	const deleteRow = (index) => {
// 		if (props.classArray[index]['__status'] == '__new') {
// 			let n = props.classArray;
// 			n.splice(index, 1);
// 			props.setclassArray(n);
// 			setisSaveVisible(false);
// 			settableDataKey(new Date().getMilliseconds());
// 		} else {
// 			if (
// 				window.confirm('Are you sure you want to delete this Payment Mode?')
// 			) {
// 				putpost2(
// 					masterServiceBaseUrl +
// 						'/paymentMode/deletePaymentMode/' +
// 						props.classArray[index].id,
// 					(data) => {
// 						successToast(data['message']);
// 						if (props.classArray.length == 1) {
// 							props.previousPage();
// 							props.fetchClasses(masterServiceBaseUrl + '/paymentMode/getAllPaymentMode');
// 						} else {
// 							var _tempArray = props.classArray;
// 							if (_tempArray[index]['editMode']) {
// 								setisSaveVisible(false);
// 							}
// 							_tempArray.splice(index, 1);
// 							props.setclassArray(_tempArray);
// 						}

// 						settableDataKey(new Date().getMilliseconds());
// 					},
// 					(data) => {
// 						failureToast(data['message']);
// 					},
// 					'Delete'
// 				);
// 			}
// 		}
// 	};
// 	const validate = (type, value) => {
// 		const numberValidation = /^[0-9\b]+$/;
// 		if (type === 'number') {
// 			if (value === '' || numberValidation.test(value)) {
// 				return true;
// 			}
// 		}
// 	};
// 	const updateKey = (index, value, key) => {
// 		var _tempArray = props.classArray;
// 		_tempArray[index][key] = value;
// 		props.setclassArray(_tempArray);
// 	};
// 	const [companyCodeData, setCompanyCodeData] = useState();
// 	useEffect(() => {}, [companyCodeData]);
// 	useEffect(() => {
// 		fetchDetails(
// 			masterServiceBaseUrl + '/companyCode/getAllActiveCompanyCode',
// 			setCompanyCodeList,
// 			'companyCode',
// 			'reactSelect'
// 		);
// 	}, []);
// 	const [selectedGroupCode, setSelectedGroupCodeId] = useState();
// 	useEffect(() => {
// 		if (selectedGroupCode) {
// 			// fetchDetails(masterServiceBaseUrl + '/companyCode/getCompanyCodeByGroup/' + selectedGroupCode, setCompanyCodeData, 'companyCode', 'reactSelect');
// 		}
// 	}, [selectedGroupCode]);
// 	useEffect(() => {}, [companyCodeData]);
// 	return (
// 		<>
// 			<Container className="mt-3" fluid>
// 				<Row>
// 					<div className="col">
// 						<Card>
// 							<CardHeader className="border-0">
// 								<h3 className="mb-0 floatLeft">Master - Payment Mode</h3>
// 								<Button
// 									disabled={isSaveVisible}
// 									color="info"
// 									size="sm"
// 									type="button"
// 									className={
// 										'floatRight mx-1' + (isSaveVisible ? ' btn-dark' : null)
// 									}
// 									data-testid="addNewPaymentMode"
// 									onClick={() => {
// 										newRow();
// 									}}
// 								>
// 									New Payment Mode
// 								</Button>
// 								{/* {isSaveVisible?<Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => {saveAll()}}>Save</Button>:null} */}
// 							</CardHeader>
// 							<div id="questionTable" className="table-responsive">
// 								<Table className="align-items-center table-flush tableLayout">
{/* <MasterHeader type={'Payment Mode'} /> */ }
// 									<tbody className="list" key={tableDataKey}>
// 										{props.classArray
// 											? props.classArray.map((el, index) =>
// 													!el['editMode'] ? (
// 														<TDROW
// 															companyCodeData={companyCodeList}
// 															groupCodeList={groupCodeData}
// 															type={'paymentMode'}
// 															index={index}
// 															el={el}
// 															editRow={editRow}
// 															deleteRow={deleteRow}
// 														/>
// 													) : (
// 														<tr key={index + '-class'}>
// 															<td className="text-center p-2 mx-1">
// 																<Select2
// 																	defaultValue={selectedGroupCode}
// 																	data={groupCodeData}
// 																	data-testid="groupCodeId"
// 																	onChange={(e) => {
// 																		setSelectedGroupCodeId(e.target.value);
// 																		updateKey(
// 																			index,
// 																			e.target.value,
// 																			'groupCodeId'
// 																		);
// 																		if (e.target.value) {
// 																			fetchDetails(
// 																				masterServiceBaseUrl +
// 																					'/companyCode/getCompanyCodeByGroup/' +
// 																				e.target.value,
// 																				setCompanyCodeData,
// 																				'companyCode',
// 																				'reactSelect'
// 																			);
// 																		}
// 																		// fetchDetails(masterServiceBaseUrl + '/companyCode/getCompanyCodeByGroup/'+e.target.value, setCompanyCodeData, 'companyCode', 'reactSelect');
// 																	}}
// 																	options={{
// 																		placeholder: 'Group Code',
// 																	}}
// 																/>
// 															</td>
// 															<td className="text-center p-2 mx-1">
// 																<Select2
// 																	defaultValue={el['companyCodeId']}
// 																	data-testid="companyCodeId"
// 																	data={companyCodeData}
// 																	// disabled={data['isEdit']}
// 																	onChange={(e) => {
// 																		updateKey(
// 																			index,
// 																			e.target.value,
// 																			'companyCodeId'
// 																		);
// 																	}}
// 																	options={{
// 																		placeholder: 'Company Code',
// 																	}}
// 																/>
// 															</td>
// 															<td className="text-center p-2 mx-1">
// 																<Input
// 																	maxLength="50"
// 																	defaultValue={el['paymentModeKey']}
// 																	data-testid="paymentModeKey"
// 																	onChange={(e) => {
// 																		updateKey(
// 																			index,
// 																			e.target.value,
// 																			'paymentModeKey'
// 																		);
// 																	}}
// 																	placeholder="Example:1"
// 																	className="tdInput"
// 																/>
// 															</td>
// 															<td className="text-center p-2 mx-1">
// 																<Input
// 																	disabled={true}
// 																	data-testid="=["
// 																	defaultValue={
// 																		el['__status'] == '__new'
// 																			? null
// 																			: moment(el['eff_date']).format(
// 																					'DD-MM-YYYY'
// 																			  )
// 																	}
// 																	placeholder="Example:01-01-2020"
// 																	className="tdInput"
// 																/>
// 															</td>
// 															<td className="text-center p-2 mx-1">
// 																<Input
// 																	maxLength="50"
// 																	data-testid="paymentModeDesc"
// 																	defaultValue={el['paymentModeDesc']}
// 																	onChange={(e) => {
// 																		updateKey(
// 																			index,
// 																			e.target.value,
// 																			'paymentModeDesc'
// 																		);
// 																	}}
// 																	placeholder="Description"
// 																	className="tdInput"
// 																/>
// 															</td>
// 															<td className="text-center p-2 mx-1">
// 																<Input
// 																	maxLength="50"
// 																	data-testid="paymentModeDispValue"
// 																	defaultValue={el['paymentModeDispValue']}
// 																	onChange={(e) => {
// 																		updateKey(
// 																			index,
// 																			e.target.value,
// 																			'paymentModeDispValue'
// 																		);
// 																	}}
// 																	placeholder="Display Value"
// 																	className="tdInput"
// 																/>
// 															</td>
// 															<td className="text-center p-2 mx-1">
// 																<Input
// 																	maxLength="2"
// 																	data-testid="sapPaymode"
// 																	defaultValue={el['sapPaymode']}
// 																	onChange={(e) => {
// 																		updateKey(
// 																			index,
// 																			e.target.value,
// 																			'sapPaymode'
// 																		);
// 																	}}
// 																	placeholder="Sap Paymode"
// 																	className="tdInput"
// 																/>
// 															</td>
// 															<td className="text-center p-2 mx-1">
// 																<Input
// 																	maxLength="2"
// 																	data-testid="paymodeSequence"
// 																	value={paymentSequence}
// 																	onChange={(e) => {
// 																		let n = e.target.value;
// 																		if (validate('number', n)) {
// 																			setPaymentSequence(n);
// 																			updateKey(index, n, 'paymodeSequence');
// 																		}
// 																	}}
// 																	placeholder="Paymode Sequence"
// 																	className="tdInput"
// 																/>
// 															</td>
// 															<td className="text-center ">
// 																<Button
// 																	disabled={isSaveVisible}
// 																	className={
// 																		'' + (isSaveVisible ? ' btn-dark' : null)
// 																	}
// 																	color={'info'}
// 																	size="sm"
// 																	type="button"
// 																>
// 																	<i className="fas fa-edit" />
// 																	Add Receipt A/C Entry
// 																</Button>
// 															</td>
// 															<td className="text-center ">
// 																<Button
// 																	disabled={isSaveVisible}
// 																	className={
// 																		'' + (isSaveVisible ? ' btn-dark' : null)
// 																	}
// 																	color={'info'}
// 																	size="sm"
// 																	type="button"
// 																>
// 																	<i className="fas fa-edit" />
// 																	Add Refund A/C Entry
// 																</Button>
// 															</td>
// 															<td className="text-center p-2 mx-1">
// 																<label className="custom-toggle mx-auto ml-2">
// 																	<input
// 																		checked={
// 																			el.status == 'ACTIVE' ? true : false
// 																		}
// 																		data-testid="paymodeStatus"
// 																		type="checkbox"
// 																		id={el.id}
// 																		onChange={(e) => {
// 																			settableDataKey(
// 																				new Date().getMilliseconds()
// 																			);
// 		 																	let n = 'ACTIVE';
// 																			if (!e.target.checked) {
// 																				n = 'INACTIVE';
// 																			}
// 																			updateKey(index, n, 'status');
// 																		}}
// 																	/>
// 																	<span
// 																		style={{ width: '72px' }}
// 																		className="custom-toggle-slider rounded-circle activeToggle"
// 																		data-label-off="Inactive"
// 																		data-label-on="Active"
// 																	/>
// 																</label>
// 															</td>
// 															<td className="text-center">
// 																{/* <Button color="info" size="sm" type="button" onClick={() => {
//                           deleteRow(index)
//                         }}>  <i className="fas fa-trash" /></Button> */}
// 																{isSaveVisible ? (
// 																	<>
// 																		{' '}
// 																		<Button
// 																			color="info"
// 																			size="sm"
// 																			type="button"
// 																			className="mx-1"
// 																			data-testid="savePaymentMode"
// 																			onClick={() => {
// 																				saveAll();
// 																			}}
// 																		>
// 																			Save
// 																		</Button>
// 																		<Button
// 																			color="danger"
// 																			size="sm"
// 																			type="button"
// 																			onClick={() => {
// 																				let n = props.classArray;
// 																				setSelectedGroupCodeId(null);
// 																				if (n[index]['__status'] == '__new') {
// 																					n.splice(index, 1);
// 																				} else {
// 																					setCompanyCodeData([]);
// 																					// n[index]['editMode']=false
// 																					props.fetchClasses(
// 																						masterServiceBaseUrl +
// 																							'/paymentMode/getAllPaymentMode'
// 																					);
// 																				}
// 																				props.setclassArray(n);
// 																				setisSaveVisible(false);
// 																			}}
// 																		>
// 																			{' '}
// 																			<i className="fas fa-times" />
// 																		</Button>{' '}
// 																	</>
// 																) : (
// 																	<Button
// 																		color="info"
// 																		size="sm"
// 																		type="button"
// 																		onClick={() => {
// 																			deleteRow(index);
// 																		}}
// 																	>
// 																		{' '}
// 																		<i className="fas fa-trash" />
// 																	</Button>
// 																)}
// 															</td>
// 														</tr>
// 													)
// 											  )
// 											: null}
// 									</tbody>
// 								</Table>
// 							</div>
// 						</Card>
// 					</div>
// 				</Row>
// 			</Container>
// 		</>
// 	);
// };
// export default PaymentModeTable;