import { UPDATE_MASTER_DATE} from "../types";
import {updateMasterData} from "./MasterDataAction";
const initial_state = {
    isLoading:false
}

const MasterDataReducer =(state=initial_state, action=updateMasterData)=>{
    switch(action.type){
        case UPDATE_MASTER_DATE :return{
            ...state,
            masterData : action.masterData
        }
        default:return state
    }
}
export default MasterDataReducer;