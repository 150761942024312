import React, { useState, useEffect, useContext } from "react";
import { ToastContainer } from "react-toastify";
import AdmissionAgentTypeTable from './admissionAgentTypeTable';
import Loader from 'react-loader-spinner'
import {masterServiceBaseUrl,failureToast,getAPI} from 'services/http';
import { CardFooter, Pagination } from "reactstrap";
import { PaginationNode, PaginationPage } from '../../common/pagination';
import { GetPagination } from '../../common/commonComponents/pagination/pagination';
import { PermissionContext, RolePermissions } from "../../../../../appContext";
import { pages } from "../../common/constant";
import { getPaginatedData } from "../../common/utils/methods/commonMethods/paginationMethod";

const AdmissionAgentType = () => {
  const permissions = useContext(PermissionContext);
	const userPermissions = RolePermissions(
		permissions,
		pages['admissionAgentType']['id']
	);
	
  const [classArray, setclassArray] = useState([])
  const [apiloader, setapiloader] = useState(false)
  const [state, setstate] = useState([])
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null
  })
  const pageSize=10
  const fetchClasses = async (url) => {
    setapiloader(true)
    getAPI(url, (data) => {
      setstate(data['data'])
      getPaginatedData(data['data'], pageSize, pagination, setpagination, setapiloader, setclassArray);
    }, (data) => { failureToast(data['message']) })
  }

  const getAllRecords = (toPreviousPage=false) => {
    if(toPreviousPage){
      let n = pagination;
      n.currentPage=n.currentPage-1;
      setpagination(n)
    }
    fetchClasses(masterServiceBaseUrl+'/admissionAgentType/getAllAdmissionAgentType');
  }
  useEffect(()=>{
    fetchClasses(masterServiceBaseUrl+'/admissionAgentType/getAllAdmissionAgentType')
  },[])
  return (
    <>
			{userPermissions ? (
        <>
          {apiloader ? <div className="mx-auto text-center py-5 my-5" style={{ height: "100vh" }}><Loader
            type="Rings"
            color="#00BFFF"
            height={70}
            width={70}
            timeout={10000} /></div> : <div></div>}
          <AdmissionAgentTypeTable getAllRecords={getAllRecords} setclassArray={setclassArray} classArray={classArray} userPermissions={userPermissions} />
          {pagination.totalPage>1?
            <GetPagination
              isSaveVisible={false}
              setclassArray={setclassArray}
              pageSize={pageSize}
              pagination={pagination}
              setpagination={setpagination}
              state={state}
              isPaginationFromServer={false}
              isFetching={apiloader}
            />
          : null
          }
        </>
      ) : null}
    </>
  );
}

export default AdmissionAgentType;
