import React from 'react';
import {
  Card,
  CardHeader,
  Input,
  Container,
  Button,
  Table,
  Row,
} from 'reactstrap';
import { useState, useEffect } from 'react';
import { failureToast } from '../../common/utils/methods/toasterFunctions/function';
import {
  masterServiceBaseUrl,
  successToast,
  putpost,
  putpost2,
  deleteAPI,
  getAPI,
} from 'services/http';
import Select2 from 'react-select2-wrapper';
import CustomButton from "../../../../../components/CustomButton";
import { ButtonText } from "variables/Buttons"
import { customValidation } from "../../common/utils/methods/validations/validation";
import validationRules from './validation.json'
import { fetchAllPostPromisedData, fetchDeletePromisedData, getCurrentDate } from "../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "../../common/constant";
import { editTableRow, newTableRow } from "../../common/utils/methods/commonMethods/tableMethods";
import { MasterHeaderAuto, Rows } from '../../common/commonComponents/masterHeader/masterHeader';
var _ = require('lodash');

const PaymentMethodTable = (props) => {
  const { permissionSet, isSaveVisible, setisSaveVisible = () => { }, getAllrecords = () => { } } = props;
  const [paymentArray, setPaymentArray] = useState([]);
  const [businessArray, setBusinessArray] = useState([]);
  const [tableDataKey, settableDataKey] = useState('tableDataKey');

  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: '__new',
        paymentMethodId: null,
        businessAreaId: null,
        allowForReceiptiong: true,
        status: 'ACTIVE',
      },
      ...props.classArray,
    ]);
  };



  const headerList = [
    { name: 'PAYMENT METHOD' },
    { name: 'BUSINESS AREA' },
    { name: 'RECEIPT ENTRY' },
    { name: 'REFUND ENTRY' },
    { name: 'ALLOW FOR RECEIPTING?' },
    { name: 'Status' },
    { name: 'Action' },
  ];

  const saveAll = async (index) => {
    let _data = props.classArray[index];
    // let alphanumericSpecialChar = /^[ A-Za-z0-9_@./#&+-]*$/
    let isValid = true;
    isValid = customValidation(_data, validationRules)

    if (!isValid) return;

    if (_data["__status"] == "__new") {
      const createRecord = await fetchAllPostPromisedData(endpoint.departmentType.create, _data, 'post')
      if (createRecord?.code === 200) {
        successToast(createRecord["message"]);
        setisSaveVisible(false);
        getAllrecords()
      }
    } else {
      const updateRecord = await fetchAllPostPromisedData(`${endpoint.departmentType.update}/${_data.id}`, _data, 'put');
      if (updateRecord?.code === 200) {
        successToast(updateRecord["message"]);
        setisSaveVisible(false);
        getAllrecords()
      }
    }
  }

  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      newTableRow(props.classArray, props.setclassArray, setisSaveVisible, addNewClass);
    }
  };

  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      editTableRow(index, props.classArray, props.setclassArray, setisSaveVisible);
    }
  };

  const deleteRow = async (index) => {
    if (window.confirm("Are you sure you want to delete this Department Type?")) {
      const deleteRecord = await fetchDeletePromisedData(`${endpoint.departmentType.delete}/${props.classArray[index].id}`);
      if (deleteRecord?.code === 200) {
        successToast(deleteRecord['message']);
        let flag = false;
        if (props.classArray.length == 1) {
          flag = true;
        }
        getAllrecords(flag);
        settableDataKey(new Date().getMilliseconds());
      }
    }
  };



  const getpaymentMethods = async (url) => {
    getAPI(
      url,
      (data) => {
        let n = [];
        data['data'].map((m) => {
          n.push({ text: m.paymentMethodDispValue, id: m.id });
        });
        // console.log(n);
        setPaymentArray(n);
        settableDataKey(new Date().getMilliseconds());
      },
      (data) => {
        failureToast(data['message']);
      }
    );
  };
  const getbusinessArea = async (url) => {
    getAPI(
      url,
      (data) => {
        let n = [];
        data['data'].map((m) => {
          n.push({ text: m.businessAreaDispValue, id: m.id });
        });
        // console.log(n);
        setBusinessArray(n);
        settableDataKey(new Date().getMilliseconds());
      },
      (data) => {
        failureToast(data['message']);
      }
    );
  };

  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
  };
  const getOptionIndex = (x, array) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i]['id'] == x) {
        return array[i].text;
      }
    }
  };
  useEffect(() => {
    getpaymentMethods(masterServiceBaseUrl + '/paymentMethod/getAllActivePaymentMethod');
    getbusinessArea(masterServiceBaseUrl + '/businessArea/getAllActiveBusinessArea');
    //
  }, []);
  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Payments Method</h3>
                <CustomButton
                  data-testid="addNewDepartmentType"
                  disabled={isSaveVisible}
                  className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
                  content={ButtonText.PaymentMethod.NEW}
                  icon={true}
                  permissionType={'C'}
                  permissionSet={permissionSet}
                  onClick={() => newRow()}
                />
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout">
                  {/* <thead className="thead-light">
                    <tr>
                      <th className="text-center white-breakSpace">
                        Payment Method
                      </th>
                      <th className="text-center white-breakSpace">
                        Business Area
                      </th>
                      <th className="text-center white-breakSpace">
                        Receipt Entry
                      </th>
                      <th className="text-center white-breakSpace">
                        refund Entry
                      </th>
                      <th className="text-center white-breakSpace">
                        Allow for Receipting?
                      </th>
                      <th className="text-center white-breakSpace">Status</th>
                      <th className="text-center white-breakSpace">Actions</th>
                    </tr>
                  </thead> */}
                  <MasterHeaderAuto headerList={headerList} permissionSet={permissionSet} isSaveVisible={isSaveVisible} />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                        !el['editMode'] ? (
                          <tr key={index + '-class-'}>
                            <td className="text-center white-breakSpace">
                              {getOptionIndex(
                                el['paymentMethodId'],
                                paymentArray
                              )}
                            </td>
                            <td className="text-center white-breakSpace">
                              {getOptionIndex(
                                el['businessAreaId'],
                                businessArray
                              )}
                            </td>
                            <td className="text-center white-breakSpace">
                              {
                                <a href="javascript:void(0)">
                                  View Receipt A/C Entry
                                </a>
                              }
                            </td>
                            <td className="text-center white-breakSpace">
                              {
                                <a href="javascript:void(0)">
                                  View Refund A/C Entry
                                </a>
                              }
                            </td>
                            <td className="text-center white-breakSpace">
                              {el['allowForReceipting'] ? 'Yes' : 'No'}
                            </td>
                            <td className="text-center">
                              {el.status == 'INACTIVE' ? (
                                <button
                                  disabled={true}
                                  type="button"
                                  className="btn btn-sm btn-warning"
                                >
                                  Inactive
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  disabled={true}
                                  className="btn btn-sm btn-success"
                                >
                                  Active
                                </button>
                              )}
                            </td>
                            {(permissionSet && (permissionSet.includes('D') || permissionSet.includes('U'))) &&
                              <td className="text-center">

                                <CustomButton
                                  content={''}
                                  permissionType={'U'}
                                  icon={true}
                                  forTable={true}
                                  onClick={() => {
                                    editRow(index);
                                  }}
                                  permissionSet={permissionSet}
                                />
                                <CustomButton
                                  content={''}
                                  permissionType={'D'}
                                  icon={true}
                                  forTable={true}
                                  onClick={() => {
                                    deleteRow(index);
                                  }}
                                  permissionSet={permissionSet}
                                />

                              </td>}
                          </tr>


                        ) : (
                          <tr key={index + '-class'}>
                            <td className="text-center p-2 mx-1">
                              <Select2
                                defaultValue={el['paymentMethodId']}
                                data={paymentArray}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    e.target.value,
                                    'paymentMethodId'
                                  );
                                }}
                                options={{
                                  placeholder: 'Select Payment Method',
                                }}
                                className="tdSelect"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <Select2
                                defaultValue={el['businessAreaId']}
                                data={businessArray}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    e.target.value,
                                    'businessAreaId'
                                  );
                                }}
                                options={{
                                  placeholder: 'Select Business Area',
                                }}
                                className="tdSelect"
                              />
                            </td>
                            {/* <td className="text-center p-2 mx-1"><Input maxLength="50" onChange={(e) => { updateKey(index, e.target.value, 'description') }} defaultValue={el["description"]} placeholder="Description" className="tdInput" /></td> */}
                            <td className="text-center p-2 mx-1">
                              <Input
                                maxLength="50"
                                disabled={true}
                                defaultValue={el['receipt_entry']}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    e.target.value,
                                    'Freceipt_entry'
                                  );
                                }}
                                placeholder="Receipt Entry"
                                className="tdInput"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <Input
                                maxLength="50"
                                disabled={true}
                                defaultValue={el['refund_entry']}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    e.target.value,
                                    'refund_entry'
                                  );
                                }}
                                placeholder="Refund Entry"
                                className="tdInput"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <Input
                                defaultChecked={el['allowForReceipting']}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    !el['allowForReceipting'],
                                    'allowForReceipting'
                                  );
                                }}
                                type="checkBox"
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  marginLeft: '100px',
                                }}
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <label className="custom-toggle mx-auto ml-2">
                                <input
                                  checked={
                                    el.status == 'ACTIVE' ? true : false
                                  }
                                  type="checkbox"
                                  id={el.id}
                                  onChange={(e) => {
                                    settableDataKey(
                                      new Date().getMilliseconds()
                                    );
                                    // console.log(e.target.checked);
                                    let n = 'ACTIVE';
                                    if (!e.target.checked) {
                                      n = 'INACTIVE';
                                    }
                                    updateKey(index, n, 'status');
                                  }}
                                />
                                <span
                                  style={{ width: '72px' }}
                                  className="custom-toggle-slider rounded-circle activeToggle"
                                  data-label-off="Inactive"
                                  data-label-on="Active"
                                />
                              </label>
                            </td>
                            <td className="text-center">
                              {isSaveVisible ?
                                <>
                                  <CustomButton
                                    data-testId="saveDepartmentType"
                                    className={'mx-1'}
                                    content={ButtonText.DepartmentType.UPDATE}
                                    forTable={true}
                                    permissionType={'C,U'}
                                    permissionSet={permissionSet}
                                    onClick={() => { saveAll(index); }}
                                  />

                                  <CustomButton
                                    data-testId="closedDepartmentType"
                                    className={'mx-1'}
                                    permissionType={'cancel'}
                                    forTable={true}
                                    icon={true}
                                    permissionSet={permissionSet}
                                    onClick={() => {
                                      let n = props.classArray;
                                      if (n[index]["__status"] == "__new") {
                                        n.splice(index, 1);
                                      } else {
                                        // n[index]['editMode']=false
                                        getAllrecords();
                                      }
                                      setisSaveVisible(false);
                                    }}
                                  />
                                </>
                                :
                                // <Button color="info" size="sm" type="button" onClick={() => {
                                //   deleteRow(index)
                                // }}>  <i className="fas fa-trash" /></Button>
                                <></>
                              }
                            </td>
                          </tr>
                        )
                      )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default PaymentMethodTable;
