import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import moment from 'moment';

const InputField = (props) => {
	const { item, index, tableData, setTableData, saveClicked } = props;
	const [value, setValue] = useState('');
	useEffect(() => {

		// console.log("tableData",tableData)
		if (tableData[index]['__status'] === '__new' && item.defaultValue) {
			setValue(item.defaultValue);
		} else {
			const value = item.format
				? moment(tableData?.[index]?.[item.propertyName]).format(item.format)
				: tableData?.[index]?.[item.propertyName];
			setValue(value);
		}
	}, []);
	return (
		<Input
			className="tdInput"
			key={'inputField' + index + item?.propertyName}
			placeholder={item.placeHolder}
			value={value}
			disabled={
				saveClicked ||
				(item?.disabledOnEdit && tableData[index]['__status'] !== '__new') ||
				item.isDisabled
			}
			minLength={item?.minLength ?? 1}
			maxLength={item?.maxLength ?? 50}
			onKeyPress={(e) => {
				if (!item.regx.test(String.fromCharCode(e.charCode))) {
					e.preventDefault();
					return;
				}
			}}
			onChange={(e) => {
				let value = item?.toUpperCase
					? e.target.value.toString().toUpperCase()
					: e.target.value;
				let tempArr = tableData;
				setValue(value);
				tempArr[index][item.propertyName] = value
					.toString()
					.replace(/\s+/g, ' ')
					.trim();
				setTableData(tempArr);
			}}
		/>
	);
};

export default InputField;
