import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import ADataGrid from "../../../../common/data-grid/ADataGrid";
import {
  gridStyles,
  gridComponents,
} from "../../../issuanceIDcard/printIdCardAndMapRfid/constant";
import NoRowOverlay from "../../../../masters/holidayMaster/NoRowOverlay";
import { AGridColDef } from "../../../../common/data-grid/ADataGridColDef";
import AAutoComplete from "../../../../common/form-fields-mui/AAutoComplete";
import {
  getCourseDetails,
  getTermDropdown,
  getYear,
  newRow,
  useGetDigitalCourseIdsByBu,
  useGetDlpCourseIdsByBu,
} from "./constant";
import {
  editCellContainerStyle,
  inputStyles,
  selectStyles,
} from "../../mastersAndMapping/onBoardingMaterialIdSetup/constant";
import { GridRowModes } from "@mui/x-data-grid";
import {
  generateRandomId,
  isObjEmpty,
} from "../../../../common/utils/methods/commonMethods/utilityMethod";
import { failureToast } from "../../../../common/utils/methods/toasterFunctions/function";
import { saveOrDeleteMsg } from "../../mastersAndMapping/sapProfitCostCentreMapping/constant";
import CustomDatePicker from "../../../../common/commonComponents/CustomDatePicker";
import moment from "moment";
import AInput from "../../../../common/formFeilds/customInput/AInput";
import MaterialDetailsCellComponent, {
  MaterialDetailsViewCellComponent,
} from "./MaterialDetailsCellComponent";
import StatusCellComponent from "./StatusCellComponent";
import ActionsCellComponent from "./ActionsCellComponent";
import { Tooltip } from "@mui/material";
import { IoEyeOutline } from "react-icons/io5";
import ALoader from "../../../../common/a-loader";
import { useParams } from "react-router";

const ListComponent = forwardRef(
  (
    {
      rows,
      rowCount,
      dropdown,
      loader,
      currentPage,
      onPageChange,
      createHandler,
      updateHandler,
      toggleStatusHandler,
      deleteHandler,
    },
    ref
  ) => {
    const { academicCareer } = useParams();
    const [rowModesModel, setRowModesModel] = useState({});
    const [rowData, setRowData] = useState(rows);
    const [rowForm, setRowForm] = useState({});
    const [courseIdsDropdown, isCourseFetching] = academicCareer==='DLP' ? useGetDlpCourseIdsByBu(rowForm) : useGetDigitalCourseIdsByBu(rowForm);

    const rowFormHandler = (value, key) =>
      setRowForm((prev) => ({ ...prev, [key]: value }));
    const [termDropdown, setTermDropdown] = useState([]);
    const [courseDetails, setCourseDetails] = useState({});

    useEffect(() => {
      setRowData(rows);
    }, [rows]);

    useEffect(() => {
      if (termDropdown.length > 0 && rowForm?.isNew) {
        setRowForm((prev) => ({
          ...prev,
          term: termDropdown?.length === 1 ? termDropdown?.[0] : "",
        }));
        if (termDropdown.length === 1)
          getCourseDetails(
            { ...rowForm, term: termDropdown?.[0] },
            setCourseDetails,
            (value) => rowFormHandler(value, "academicGroup"),
            dropdown
          );
      }
    }, [termDropdown]);

    const startEditing = (id, form) => {
      setRowModesModel((oldModel) => ({
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "dispatchId" },
      }));
      setRowForm(form);
      populateDependentDropdowns(form);
    };

    const stopEditing = () => {
      const currForm = { ...rowForm };
      setRowModesModel((oldModel) => ({
        [currForm?.id]: { mode: GridRowModes.View, ignoreModifications: true },
      }));
      setRowForm({});
      setTermDropdown([]);
      setCourseDetails({});
    };

    const handleEditClick = (params) => {
      if (!isObjEmpty(rowForm)) {
        failureToast(saveOrDeleteMsg);
      } else {
        startEditing(params?.id, params?.row);
      }
    };

    const handleCancelClick = async (params) => {
      if (params?.row?.isNew) {
        setRowData(rows);
        setRowForm({});
      } else {
        stopEditing(params?.id);
      }
    };

    const handleSaveClick = async (params) => {
      if (rowForm?.isNew) {
        await createHandler(rowForm);
      } else {
        await updateHandler(rowForm);
      }
    };

    const addNewRow = async () => {
      if (!isObjEmpty(rowForm)) {
        failureToast(saveOrDeleteMsg);
      } else {
        let randomId = generateRandomId();
        let emptyRow = {
          ...newRow(dropdown, termDropdown),
          isNew: true,
          id: randomId,
        };
        startEditing(randomId, emptyRow);
        setRowData((prev) => [emptyRow, ...prev]);
      }
    };

    useImperativeHandle(ref, () => ({
      addNewRow: addNewRow,
      startEditing: startEditing,
      stopEditing: stopEditing,
    }));

    const populateDependentDropdowns = (rowData) => {
      // call this method when a row is edited
      if (!rowData?.isNew) {
        getCourseDetails(
          rowData,
          setCourseDetails,
          (value) => rowFormHandler(value, "academicGroup"),
          dropdown
        );
        getTermDropdown(rowData?.courseId?.value, setTermDropdown, dropdown);
      }
    };

    const columns = [
      new AGridColDef('dispatchId', 'Dispatch ID')
        .setMinWidth(150)
        .setFlex(0.5)
        .editable(true)
        .setSortComparator((v1, v2) => (+v1?.split('-')?.[1] || '-1') - (+v2?.split('-')?.[1] || '-1'))
        .setValueGetter(params => params?.value?.label)
        .renderEditCellComponent(params => (
          <div style={editCellContainerStyle}>
            <AAutoComplete
              currentValue={rowForm?.dispatchId}
              handleChange={value => rowFormHandler(value, 'dispatchId')}
              items={dropdown?.dispatchIdDropdown}
              isMulti={false}
              style={selectStyles}
              autoFocus={params?.hasFocus}
            />
          </div>
        )),
      new AGridColDef('businessArea', 'Busi. area')
        .setMinWidth(110)
        .setFlex(0.5)
        .editable(true)
        .setValueGetter(params => params?.value?.label)
        .renderEditCellComponent(params => (
          <div style={editCellContainerStyle}>
            <AAutoComplete
              currentValue={rowForm?.businessArea}
              handleChange={value => {
                rowFormHandler(value, 'businessArea');
                rowFormHandler('', 'dispatchDate');
                rowFormHandler('', 'courseId');
                getCourseDetails(
                  { ...rowForm, businessArea: value },
                  setCourseDetails,
                  value => rowFormHandler(value, 'academicGroup'),
                  dropdown
                );
              }}
              items={dropdown?.businessArea}
              isMulti={false}
              style={selectStyles}
              autoFocus={params?.hasFocus}
            />
          </div>
        )),
      new AGridColDef('courseId', 'Course')
        .setMinWidth(110)
        .setFlex(0.5)
        .editable(true)
        .setValueGetter(params => params?.value?.label)
        .renderEditCellComponent(params => (
          <div style={editCellContainerStyle}>
            <AAutoComplete
              currentValue={rowForm?.courseId}
              handleChange={value => {
                rowFormHandler(value, 'courseId');
                rowFormHandler('', 'term');
                rowFormHandler('', 'dispatchDate');
                getTermDropdown(value?.value, setTermDropdown, dropdown);
                getCourseDetails(
                  { ...rowForm, courseId: value, term: '' },
                  setCourseDetails,
                  value => rowFormHandler(value, 'academicGroup'),
                  dropdown
                );
              }}
              items={courseIdsDropdown}
              isMulti={false}
              style={{ ...selectStyles, menuPortal: { minWidth: '100px' } }}
              autoFocus={params?.hasFocus}
            />
          </div>
        )),
      new AGridColDef('term', 'Term')
        .setMinWidth(90)
        .setFlex(0.5)
        .editable(true)
        .setValueGetter(params => params?.value?.label)
        .renderEditCellComponent(params => (
          <div style={editCellContainerStyle}>
            <AAutoComplete
              currentValue={rowForm?.term}
              handleChange={value => {
                rowFormHandler(value, 'term');
                rowFormHandler('', 'dispatchDate');
                getCourseDetails(
                  { ...rowForm, term: value },
                  setCourseDetails,
                  value => rowFormHandler(value, 'academicGroup'),
                  dropdown
                );
              }}
              items={termDropdown}
              isDisabled={!rowForm?.courseId}
              isMulti={false}
              style={selectStyles}
              autoFocus={params?.hasFocus}
            />
          </div>
        )),
      new AGridColDef('academicGroup', 'Acad. group')
        .setMinWidth(120)
        .setFlex(0.5)
        .editable(true)
        .setValueGetter(params => params?.value?.label)
        .renderEditCellComponent(params => (
          <div style={editCellContainerStyle}>
            <AInput
              placeholder=""
              value={rowForm?.academicGroup?.label}
              disabled
              style={inputStyles}
            />
          </div>
        )),
      new AGridColDef('dispatchDate', 'Dispatch date')
        .setMinWidth(135)
        .setFlex(0.5)
        .editable(true)
        .renderCellComponent(params => (
          <div>{moment(params?.value).format('DD-MMM-YYYY')}</div>
        ))
        .renderEditCellComponent(params => (
          <div style={editCellContainerStyle}>
            <CustomDatePicker
              placeHolder="Select"
              minDate={moment(courseDetails?.courseStartDate)}
              maxDate={moment(courseDetails?.courseEndDate)}
              value={
                rowForm?.dispatchDate ? moment(rowForm?.dispatchDate) : null
              }
              handler={(index, date, keyName) => {
                rowFormHandler(date, 'dispatchDate');
                rowFormHandler(getYear(date, courseDetails), 'year');
              }}
              disabled={
                !rowForm?.term || !rowForm?.courseId || !rowForm?.businessArea
              }
            />
          </div>
        )),
      new AGridColDef('year', 'Year')
        .setMinWidth(70)
        .setFlex(0.5)
        .editable(true)
        .renderCellComponent(params => <div>{params?.value}</div>)
        .renderEditCellComponent(params => (
          <div style={editCellContainerStyle}>
            <AInput
              placeholder=""
              value={rowForm?.year}
              disabled
              style={inputStyles}
            />
          </div>
        )),
      new AGridColDef('materials', 'Material details', false)
        .setMinWidth(150)
        .editable(true)
        .renderCellComponent(params => (
          <MaterialDetailsViewCellComponent params={params} />
        ))
        .renderEditCellComponent(params => (
          <div style={editCellContainerStyle}>
            <MaterialDetailsCellComponent
              params={params}
              rowFormHandler={rowFormHandler}
              rowForm={rowForm}
            />
          </div>
        )),
      new AGridColDef('status', 'Status')
        .setMinWidth(110)
        .setFlex(0.5)
        .renderCellComponent(params => (
          <StatusCellComponent
            params={params}
            toggleStatusHandler={toggleStatusHandler}
            rowForm={rowForm}
            rowFormHandler={rowFormHandler}
          />
        )),
      new AGridColDef('updatedOn', 'Updated on/by')
        .setMinWidth(130)
        .setFlex(0.5)
        .renderCellComponent(params =>
          params?.value ? (
            <div className="d-flex align-items-center">
              {moment(params?.value)?.format('DD-MMM-YYYY')}
              <Tooltip
                title={`${moment(params?.value)?.format(
                  'DD-MMM-YYYY, hh:mm A'
                )} by ${params?.row?.updatedBy}`}
              >
                <span className="d-flex">
                  <IoEyeOutline className="ml-2 pointer" size={18} />
                </span>
              </Tooltip>
            </div>
          ) : (
            <div>-</div>
          )
        ),
      new AGridColDef('actions', 'Actions', false)
        .setMinWidth(140)
        .columnAlign('right')
        .setFlex(0.5)
        .pinColumnLast(true)
        .renderCellComponent(params => (
          <ActionsCellComponent
            params={params}
            handleEditClick={handleEditClick}
            handleCancelClick={handleCancelClick}
            handleSaveClick={handleSaveClick}
            rowForm={rowForm}
            deleteHandler={deleteHandler}
          />
        )),
    ];

    return (
      <div className="mt-3">
        {isCourseFetching && <ALoader position="fixed" />}
        <ADataGrid
          removeWrapperContainer
          rowModesModel={rowModesModel}
          rows={rowData || []}
          columns={columns}
          rowId={(row) => row.id}
          rowHeight={50}
          loading={false}
          rowCount={rowCount}
          nextButtonHandler={onPageChange}
          currentPage={currentPage}
          components={{
            ...gridComponents,
            NoRowsOverlay: () => (
              <NoRowOverlay message={loader ? "Loading..." : null} />
            ),
          }}
          sx={gridStyles}
          autoHeight
          pinLastColumn
        />
      </div>
    );
  }
);

export default ListComponent;
