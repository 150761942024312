import React, { useState, useEffect, useContext } from "react";
import DocumentProcessTagTable from "./documentProcessTagTable";
import CustomLoader from "../../common/commonComponents/Loader/loader";
import { masterServiceBaseUrl, documentServiceBaseUrl, getAPI } from "services/http";
import { Container, Button } from "reactstrap";
import { GetPagination } from "../../common/commonComponents/pagination/pagination";
import { fetchDetails } from "../../common/utils/methods/commonMethods/utilityMethod";
import { PermissionContext, RolePermissions } from "appContext";
import { PermisionDenied } from "../../common/commonComponents";
import { pages } from "../../common/constant";
import { failureToast, successToast} from '../../common/utils/methods/toasterFunctions/function'

const DocumentProcessTag = (props) => {

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["processTagType"]['id']);


  const { action = "new", id } = props.match.params;

  const [classArray, setclassArray] = useState([]);
  const [apiloader, setapiloader] = useState(false);
  const [state, setstate] = useState([]);
  const [serverData, setServerData] = useState([]);
  const [isSaveVisible, setisSaveVisible] = useState(false);

  const [personTypeActiveData, setPersonTypeActiveData] = useState([]);
  const [processTypeActiveData, setProcessTypeActiveData] = useState([]);
  const [documentStatusActiveData, setDocumentStatusActiveData] =
    useState([]);

  const [personTypeData, setPersonTypeData] = useState(null);
  const [processTypeData, setProcessTypeData] = useState(null);
  const [documentStatusData, setDocumentStatusData] = useState(null);
  const [openNewRow, setOpenNewRow] = useState(false)

  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const pageSize = 10;
  const fetchClasses = async (url) => {
    setapiloader(true);
    getAPI(
      url,
      (data) => {
        setstate(data["data"]);
        setPagination(data["data"]);
      },
      (data) => {
        failureToast(data["message"]);
        setapiloader(false);
      }
    );
  };



  const setPagination = (paramsdata) => {
    let n = pagination;
    if (paramsdata.length > pageSize) {
      n["nextPage"] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n["totalPage"] = Math.floor(paramsdata.length / pageSize);
    if (paramsdata.length % pageSize != 0) {
      n["totalPage"] += 1;
    }
    setpagination(n);
    setclassArray(
      paramsdata.slice(
        pagination.currentPage * pageSize - pageSize,
        pagination.currentPage * pageSize
      ) || []
    );
    setapiloader(false);
  };

  const previousPage = () => {
    if (pagination.currentPage != 1) {
      setclassArray(
        state.slice(
          (pagination.currentPage - 1) * pageSize - pageSize,
          (pagination.currentPage - 1) * pageSize
        )
      );
      // console.log(
      //   state.slice(
      //     (pagination.currentPage - 1) * pageSize - pageSize,
      //     (pagination.currentPage - 1) * pageSize
      //   )
      // );
      let n = pagination;
      n["currentPage"] = n["currentPage"] - 1;
      if (n["currentPage"] == 1) {
        n["previousPage"] = null;
      } else {
        n["previousPage"] = n.currentPage;
      }
      setpagination(n);
    }
  };

  useEffect(() => {

    fetchDetails(
      `${masterServiceBaseUrl}/personType/getAllActivePersonType`,
      setPersonTypeActiveData,
      "personType",
      "reactSelect"
    );
    fetchDetails(
      `${documentServiceBaseUrl}/documentType/getAllActiveDocumentType`,
      setDocumentStatusActiveData,
      "documentType",
      "reactSelect"
    );
    fetchDetails(
      `${masterServiceBaseUrl}/processTag/getAllActiveProcessTag`,
      setProcessTypeActiveData,
      "tagType",
      "reactSelect"
    );
    fetchDetails(
      `${masterServiceBaseUrl}/personType/getAllPersonType`,
      setPersonTypeData,
      "personType",
      "reactSelect"
    );
    fetchDetails(
      `${documentServiceBaseUrl}/documentType/getAllDocumentType`,
      setDocumentStatusData,
      "documentType",
      "reactSelect"
    );
    fetchDetails(
      `${masterServiceBaseUrl}/processTag/getAllProcessTag`,
      setProcessTypeData,
      "tagType",
      "reactSelect"
    );
  }, []);

  useEffect(() => {
    if (action === 'new') {
      fetchClasses(
        `${documentServiceBaseUrl}/DocumentProcessTag/getAllDocumentProcessTag`
      );
      setOpenNewRow(true)
    } else {
      id && fetchClasses(
        `${documentServiceBaseUrl}/DocumentProcessTag/getDocumentProcessTagByDocumentTypeId/${id}`
      );
    }
  }, [action, id])
  return (
    userPermissions ?
      <>
        {apiloader ||
          !personTypeData ||
          !documentStatusData ||
          !processTypeData ? (
          <div
            className="mx-auto text-center py-5 my-5"
            style={{ height: "100vh" }}
          >
            <CustomLoader apiLoader={apiloader} />
          </div>
        ) : (
          <>
            <DocumentProcessTagTable
              previousPage={previousPage}
              fetchClasses={fetchClasses}
              setclassArray={setclassArray}
              classArray={classArray}
              isSaveVisible={isSaveVisible}
              setisSaveVisible={setisSaveVisible}
              personTypeData={personTypeData}
              documentStatusData={documentStatusData}
              processTypeData={processTypeData}
              personTypeActiveData={personTypeActiveData}
              processTypeActiveData={processTypeActiveData}
              documentStatusActiveData={documentStatusActiveData}
              action={action}
              id={id}
              openNewRow={openNewRow}
              setOpenNewRow={setOpenNewRow}
              userPermissions={userPermissions}
            />
            {pagination.totalPage > 1 ? (
              <GetPagination
                isSaveVisible={isSaveVisible}
                fetchClasses={fetchClasses}
                setclassArray={setclassArray}
                pagination={pagination}
                setpagination={setpagination}
                pageSize={pageSize}
                state={state}
              />
            ) : null}
          </>
        )}
      </> : <PermisionDenied />
  );
};

export default DocumentProcessTag;
