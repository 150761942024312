import React from "react";
import CustomColoredChip from "views/pages/manage/common/customChip/CustomChip";
import CustomDatePickerV2 from "views/pages/manage/common/commonComponents/CustomDatePicker-V2";
import { useFetchOpenSapPeriod } from "../customhooks";
import moment from "moment";

const formateDateForUser = (timeStamp) => {
  if (!timeStamp) return null;
  return `${moment(timeStamp).format("MMM")}-${moment(timeStamp).format(
    "YYYY"
  )}`;
};

const formateDateForValidation = (timeStamp) => {
  if (!timeStamp) return null;
  return moment(timeStamp);
};

const SapPeriodAndSelectSapPostingDate = ({
  sapPostingDate,
  setSapPostingDate,
}) => {
  
  const [openPeriod, isLoading] = useFetchOpenSapPeriod();

  if(isLoading) return null;

  const lastDayOfMonth = moment(
    formateDateForValidation(openPeriod?.toCalenderYearMonth)
  ).endOf("month");

  const allowedDate = new Date(lastDayOfMonth) < new Date()?lastDayOfMonth:new Date();



  
  return (
    <div className="d-flex gap-md mr-3">
      <div style={{ minWidth: "310px" }}>
        <CustomColoredChip
          style={{ height: "41px" }}
          text={`SAP open period : ${formateDateForUser(
            openPeriod?.fromCalenderYearMonth
          )} to ${formateDateForUser(openPeriod?.toCalenderYearMonth)}`}
        />
      </div>
      <CustomDatePickerV2
        placeHolder="Sap posting date"
        value={sapPostingDate}
        style={{ input: { fontWeight: 800 } }}
        handler={(i, date) => setSapPostingDate(date)}
        minDate ={allowedDate}
        maxDate ={allowedDate}
      />{" "}
    </div>
  );
};

export default SapPeriodAndSelectSapPostingDate;
