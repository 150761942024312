import { Dialog } from '@mui/material';
import React from 'react'
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { MdCancel } from 'react-icons/md'
import { ReactComponent as CrossIcon } from '../../../../../assets/img/svg/CrossIcon.svg'
import AButton from '../../common/form-fields-mui/AButton'
import { capitalize } from '../../common/utils/methods/commonMethods/utilityMethod';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper';

const buttonStyles = {
  width: "9rem",
  borderRadius: '12px',
  fontSize: '16px',
  height: '44px'
}

const TestStatusChangeConfirmationDialog = ({ open, setOpen, testData, onAgree = () => { } }) => {

  const status = testData?.active;
  const displayStatus = testData?.active ? 'INACTIVE' : 'ACTIVE';

  const handleClose = () => setOpen(false);

  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={{ padding: "1.5rem", width: "24rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "2rem",
            marginBottom: "10px",
            color: "black",
          }}
        >
          <AiOutlineQuestionCircle />
          {/* <MdCancel onClick={handleClose} /> */}
         <IconButtonWrapper onClick={handleClose}><CrossIcon  /></IconButtonWrapper> 
        </div>
        <div>
          <div
            style={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "24px",
              color: "#000000",
              marginBottom: ".6rem",
            }}
          >
            Are you sure you want to {testData?.active ? 'deactive' : 'activate'} this test?
          </div>
          <div
            style={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#414141",
              marginBottom: ".6rem",
            }}
          >
            {/* This will {testData?.active ? 'no' : ''} longer be visible in the timetable of relevant batches */}
            This test will be {testData?.active ? 'removed from' : 'added to'} the timetable of relevant batches
          </div>
        </div>

        <div className="mt-2 d-flex align-items-center justify-content-between">
          <AButton
            className={'button_remove_left_margin'}
            updatedStyle={{
              ...buttonStyles,
              border: '1px solid #00B0F5',
              color: '#00B0F5',
            }}
            onClick={handleClose}
          >
            No
          </AButton>
          <AButton
            updatedStyle={{
              ...buttonStyles,
              background: displayStatus === 'ACTIVE' ? "#00B0F5" : "#CC5F5F",
              color: "#fff",
            }}
            onClick={() => {onAgree(displayStatus, testData); handleClose();}} 
          >
            Yes, {testData?.active ? 'Deactivate' : 'Activate'}
          </AButton>
        </div>
      </div>
    </Dialog>
  )
}

export default TestStatusChangeConfirmationDialog