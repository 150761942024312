import React, { useState, useEffect } from "react";
import AttendanceMachineTable from "./attendanceMachineTable";
import CustomLoader from "../../../common/commonComponents/Loader/loader";
import { fetchDetails } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { GetPagination } from "../../../common/commonComponents/pagination/pagination";
import { endpoint } from '../../../common/constant';

const AttendanceMachine = (props) => {
  const [classArray, setclassArray] = useState([]);
  const [apiloader, setapiloader] = useState(false);
  const [state, setstate] = useState([]);
  const [serverData, setServerData] = useState([]);
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });

  const [activeBuildingData, setActiveBuildingData] = useState([]);
  const [buildingData, setBuildingData] = useState([]);
  const [machineTypeData, setMachineTypeData] = useState([]);
  const [activeMachineTypeData, setActiveMachineTypeData] = useState([]);

  const pageSize = 10;

  const fetchClasses = async (url) => {
    setstate(props.data);
    setServerData(props.data);
    setPagination(props.data);
  };

  const setPagination = (paramsdata) => {
    let n = pagination;
    if (paramsdata.length > pageSize) {
      n["nextPage"] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n["totalPage"] = Math.floor(paramsdata.length / pageSize);
    if (paramsdata.length % pageSize != 0) {
      n["totalPage"] += 1;
    }
    setpagination(n);
    setclassArray(
      paramsdata.slice(
        pagination.currentPage * pageSize - pageSize,
        pagination.currentPage * pageSize
      ) || []
    );
    setapiloader(false);
  };

  useEffect(() => {
    fetchClasses();
  }, [props.data]);

  useState(()=>{
    if(props.headers.businessArea?.value && props.headers.region?.value && props.headers.zone?.value){
      fetchDetails(`${endpoint.building.getAllBuildingByBusinessGroupCompanyCodeBusinessAreaRegionZone}?groupCodeId=${props.headers.businessGroup?.value}&companyCodeId=${props.headers.companyCode?.value}&zoneId=${props.headers.zone?.value}&regionId=${props.headers.region?.value}&businessAreaId=${props.headers.businessArea?.value}`,setBuildingData,"building","building");
      fetchDetails(endpoint.machine_type.getAll,setMachineTypeData,"machineType","select");
      fetchDetails(`${endpoint.building.getActiveBuildingByBusinessGroupCompanyCodeBusinessAreaRegionZone}?groupCodeId=${props.headers.businessGroup?.value}&companyCodeId=${props.headers.companyCode?.value}&zoneId=${props.headers.zone?.value}&regionId=${props.headers.region?.value}&businessAreaId=${props.headers.businessArea?.value}`,setActiveBuildingData,"building","building");
      fetchDetails(endpoint.machine_type.getAllActive,setActiveMachineTypeData,"machineType","select");
    }
  },[props.headers.businessArea,props.headers.region,props.headers.zone])

  const refreshPage=()=>{
    props.searchAttendanceMachine()
  }

  return (
    <>
      {apiloader ? (
        <div
          className="mx-auto text-center py-5 my-5"
          style={{ height: "100vh" }}
        >
          <CustomLoader apiLoader={apiloader} />
        </div>
      ) : (
        <>
          <AttendanceMachineTable
            fetchClasses={refreshPage}
            setclassArray={setclassArray}
            classArray={classArray}
            isSaveVisible={isSaveVisible}
            setIsSaveVisible={setisSaveVisible}
            serverData={serverData}
            headers={props.headers}
            buildingData={buildingData}
            machineTypeData={machineTypeData}
            activeBuildingData={activeBuildingData}
            activeMachineTypeData={activeMachineTypeData}
          />
          {pagination.totalPage > 1 ? (
            <GetPagination
              isSaveVisible={isSaveVisible}
              fetchClasses={refreshPage}
              setclassArray={setclassArray}
              pagination={pagination}
              setpagination={setpagination}
              pageSize={pageSize}
              state={state}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default AttendanceMachine;
