import React from "react";
import CommonInput from "../common/formFeilds/input/commonInput";
import {
  Card,
  Row,
  Button,
  CardBody,
  Col,
} from "reactstrap";
import {
  fetchAllPostPromisedData,
} from "../common/utils/methods/commonMethods/utilityMethod";
import {
  failureToast,
  successToast,
} from "../common/utils/methods/toasterFunctions/function";
import { endpoint } from "../common/constant";
import moment from "moment";
const BulkSelectionFooter = ({
  selectionModel,
  setSelectionModel = () => {},
  setStudentList,
  studentList,
  errorFields,
  values,
  setValues,
  setIsFetching,
  setFilterData,
  setTableData,
  fetchFilterData,
  isFlow1,
  fetchFilter,
  globalFilter,
}) => {
  const saveButton = async () => {
    const result = createPayload(studentList);
    let attendancePayload = {
      AttendancePunch: result,
    };
    try {
      setIsFetching(true);
      let saveAttendance = await fetchAllPostPromisedData(
        endpoint.attendanceDetails.save,
        attendancePayload,
        "post"
      );
      if (saveAttendance?.code === 200) {
        isFlow1 ? await fetchFilter() : await fetchFilterData();
        setValues({
          ...values,
          remarks: "",
          punchTime: "",
        });
        setSelectionModel([]);
        setStudentList([]);
        setIsFetching(false);
        attendancePayload.AttendancePunch = {};
        successToast("Successfully Updated");
        // getPaginatedData(saveAttendance.data, pageSize, constants.pagination, setpagination, setIsFetching, setTableData)
      } else {
        setIsFetching(false);
        setTableData([]);
        setFilterData([]);
        failureToast(saveAttendance["message"]);
      }
    } catch (e) {
      setIsFetching(false);
      failureToast("Something Went Wrong. Please try later");
    }
  };
  const postSaveDisabled =
    selectionModel.length == 0 ||
    values.remarks == "" ||
    values.punchTime == "";
  function createPayload(arr) {
    let val;
    let payloadArr = [];
    arr.forEach((el, i) => {
      val = {
        PSID: el.psId,
        RFCODE: el.rfCode,
        PUNCHDATE: isFlow1
          ? moment(globalFilter?.date1).format("YYYY-MM-DD")
          : moment(values.date).format("YYYY-MM-DD"),
        PUNCHTIME: values.punchTime,
        PUNCHREMARKS: values.remarks.value,
        UPDATEDBY: el.updatedBy,
        BRANCHCODE: values.businessArea.value
          ? values.businessArea.value.toString()
          : "",
        REGION: values.region.value ? values.region.value.toString() : "",
        TERM: values.term.value ? values.term.value.toString() : "",
        COURSEID: values.courseId.value ? values.courseId.value.toString() : "",
        BATCHID: values.batch.value ? values.batch.value.toString() : "",
      };
      payloadArr.push(val);
    });

    return payloadArr;
  }
  return (
    selectionModel?.length > 0 && (
      <Card
        className="p-0 mb-0 cardPopper"
        style={{
          boxShadow: "0px -16px 8px rgba(0, 0, 0, 0.08)",
          background: "#004662",
          position: "fixed",
          bottom: 0,
        }}
      >
        <CardBody className="px-1 py-2">
          <Row>
            <Col
              md={3}
              className="d-flex align-center justify-content-start pl-5"
            >
              <div>
                <h4 style={{ fontFamily: "Gilroy-Bold", color: "white" }}>
                  {selectionModel.length} Students Selected
                </h4>
              </div>
            </Col>
            <Col md={3} className="align-self-center">
              <CommonInput
                label={"Punch Time"}
                type={"timeField"}
                mandatory={true}
                defaultValue={values?.punchTime || ""}
                menuPlacement={"top"}
                // data={data?.dateData}
                // isDisabled={!values?.academicPhase?.length}
                onChange={(e) => {
                  setValues({
                    ...values,
                    punchTime: e,
                  });
                  errorFields.punchTimeErr = false;
                }}
                error={errorFields.punchTimeErr ? true : false}
              />
            </Col>
            <Col md={3} className="align-self-center">
              <CommonInput
                defaultValue={values?.remarks || ""}
                required={true}
                mandatory={true}
                type={"select"}
                menuPlacement={"top"}
                label="Remarks"
                data={[
                  { label: "Card Punched", value: "Card punched" },
                  { label: "School Batch", value: "School batch" },
                  { label: "Forgot Card", value: "Forgot Card" },
                  { label: "Faulty card", value: "Faulty card" },
                  { label: "ID card Not Issued", value: "ID card Not Issued" },
                  { label: "ID card Not Working/Damage", value: "ID card Not Working/Damage" },
                ]}
                singleValue={true}
                name="remarks"
                onChange={(e) => {
                  setValues({
                    ...values,
                    remarks: e,
                  });
                  errorFields.remarksErr = false;
                }}
                error={errorFields.remarksErr ? true : false}
              />
            </Col>
            <Col md={3} className="d-flex align-items-end">
              <Button
                onClick={saveButton}
                disabled={postSaveDisabled}
                color="info"
                size="md"
                style={{ width: "80%" }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  );
};

export default BulkSelectionFooter;
