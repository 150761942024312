import React, { useState, useEffect } from 'react';
import MultiDocumentUpload from '../../../../common/commonComponents/DragAndDrop';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import Select from 'react-select';
import { getRequiredSign } from '../../../../common/utils/methods/commonMethods/utilityMethod';
import {
  masterServiceBaseUrl,
  documentServiceBaseUrl,
  enrollmentServiceBaseUrl,
  getAPI,
  putpost,
  uploadpost,
} from 'services/http';
import moment from 'moment';
import Styles from './uploadDocument.module.scss';
import { failureToast, successToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';

const UploadDocuments = () => {
  const [files, setFiles] = useState();
  const [personType, setPersonType] = useState([]);
  const [proccessType, setProccessType] = useState([]);
  const [selectedPersonType, setSelectedPersonType] = useState();
  const [selectedProccessType, setSelectedProccessType] = useState();
  const [progressValue, setProgressValue] = useState(0);
  const [documentFomate, setDocumentFormate] = useState();
  const [documentData, setDocumentData] = useState();
  const [documentType, setDocumentType] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [processInstanceId, setProcessInstanceId] = useState();
  const [documentUploadResponse, setDocumentUploadResponse] = useState();
  const [fileName, setFileName] = useState();
  const [processStatus, setProcessStatus] = useState('');

  useEffect(() => {
    fetchDetailsHandler(
      `${masterServiceBaseUrl}/documentType/getAllDocumentType`,
      'DOCUMENT_DATA'
    );
    fetchDetailsHandler(
      `${masterServiceBaseUrl}/documentFormat/getAllDocumentFormats`,
      'DOCUMENT_FORMAT'
    );
    fetchDetailsHandler(
      `${masterServiceBaseUrl}/personType/getAllPersonType`,
      'PERSON_TYPE'
    );
    fetchDetailsHandler(
      `${masterServiceBaseUrl}/processTag/getAllProcessTag`,
      'PROCESS_TYPE'
    );
  }, []);

  useEffect(() => {
    if (documentFomate && documentData) {
      createDocumentObject();
    }
  }, [documentFomate, documentData]);

  const createDocumentObject = () => {
    try {
      const docObj = {};
      documentData.map((item) => {
        let temp = [];
        documentFomate.map((val) =>
          item.documentFormatIds.includes(val.id)
            ? temp.push(val.documentFormatKey.toUpperCase())
            : ''
        );
        if (temp?.length > 0) {
          let property = item['documentTypeKey'].toUpperCase();
          docObj[property] = temp;
        }
      });
      setDocumentType(docObj);
    } catch (e) {
      console.log('Error ', e);
      setIsUploading(false);
    }
  };

  const fetchDetailsHandler = (url, type) => {
    try {
      getAPI(
        url,
        (data) => {
          dataHandler(data?.data, type);
        },
        (data) => {
          failureToast(data['message']);
        }
      );
    } catch (e) {
      console.log('Error', e);
    }
  };

  const uploadFileRequestHandler = (url, obj, type) => {
    try {
      putpost(
        url,
        (data) => {
          if ('GET_PROCESS_STATUS' === type) {
            setDocumentUploadResponse(data?.data);
            setFileName(Object.keys(data?.data?.bulkResponse));
            setProgressValue(70);
          }
          data.previousRequestObj = obj;
          uploadFile(data, type);
        },
        (data) => {
          failureToast(data['message']);
          setIsUploading(false);
        },
        obj,
        'post'
      );
    } catch (e) {
      console.log('Error', e);
      setIsUploading(false);
    }
  };

  const uploadFileHandler = (url, obj, processId) => {
    try {
      uploadpost(
        url,
        (data) => {
          let dvalues = {
            data: data,
            processId: processId,
          };
          setProgressValue(50);
          uploadFile(dvalues, 'SEND_FILE_DETAILS');
        },
        (data) => {
          failureToast(data['message']);
          setIsUploading(false);
        },
        obj
      );
    } catch (e) {
      console.log('Error', e);
      setIsUploading(false);
    }
  };

  const dataHandler = (data, type) => {
    try {
      switch (type) {
        case 'DOCUMENT_DATA':
          setDocumentData(data);
          break;
        case 'DOCUMENT_FORMAT':
          setDocumentFormate(data);
          break;
        case 'PERSON_TYPE':
          dropDownDataHandler(data, setPersonType, 'personTypeDispValue');
          break;
        case 'PROCESS_TYPE':
          dropDownDataHandler(data, setProccessType, 'tagTypeDispValue');
          break;
        case 'Process_Status':
          setProcessStatus(data?.processIdStatus);
          setProgressValue(100);
          break;
        default:
          break;
      }
    } catch (e) {
      console.log('Error ', e);
    }
  };

  const uploadFile = (data, type) => {
    try {
      switch (type) {
        case 'GET_FILE_URL':
          setIsUploading(true);
          let obj = {
            bulk: true,
            fileName: files.name,
            docType: 'zip',
            serviceName: 'STUDENT',
          };
          uploadFileRequestHandler(
            `${documentServiceBaseUrl}/documentManagement/getUploadUrl`,
            obj,
            'UPLOAD_FILE'
          );
          break;
        case 'UPLOAD_FILE':
          setProgressValue(30);
          setProcessInstanceId(data.data.processId);
          let formDat = new FormData();
          formDat.append('destinationfolder', `student/${data.data.processId}`);
          formDat.append('File', files);
          uploadFileHandler(data.data.storage, formDat, data.data.processId);
          break;
        case 'SEND_FILE_DETAILS':
          let details = {
            bucketName: data.data.response.Bucket,
            errorCode: '',
            etag: data.data.response.ETag,
            processId: data.processId,
            s3Url: data.data.response.Location,
            serviceName: 'Student',
          };
          uploadFileRequestHandler(
            `${documentServiceBaseUrl}/documentManagement/s3UploadedResponse`,
            details,
            'SEND_FILES_TO_ENROLLEMENT'
          );
          break;
        case 'SEND_FILES_TO_ENROLLEMENT':
          setProgressValue(50);
          let SEND_FILES_obj = {
            downloadLink: data.previousRequestObj.s3Url,
            personTypeId: selectedPersonType.value,
            processTypeId: selectedProccessType.value,
            processInstanceId : data.previousRequestObj.processId
          };
          uploadFileRequestHandler(
            `${enrollmentServiceBaseUrl}/userDocumentDetails/processBulkUserDocumentDetails`,
            SEND_FILES_obj,
            'GET_PROCESS_STATUS'
          );
          break;
        case'GET_PROCESS_STATUS':
          setProgressValue(85);
          fetchDetailsHandler(`${documentServiceBaseUrl}/documentManagement/getDocumentDetailsbyPID/${data.previousRequestObj.processInstanceId}`, 'Process_Status');
          break;
        default:
          break;
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  const dropDownDataHandler = (data, stateMethod, property) => {
    try {
      let temp = [];
      data.map((item) => {
        temp.push({
          value: item['id'],
          label: item[property],
        });
      });
      stateMethod(temp);
    } catch (e) {}
  };

  const handleFile = (e) => {};

  return (
    <Container className='mt-4' fluid>
      <Card>
        <CardHeader>
          <h3 className='mb-0 floatLeft'>Bulk Document Upload</h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label className='form-control-label'>
                  Person Type {getRequiredSign()}
                </Label>
                <Select
                  placeholder='Select Person Type'
                  isDisabled={isUploading}
                  value={selectedPersonType}
                  options={personType}
                  onChange={(e) => {
                    setSelectedPersonType(e);
                    console.log(e);
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label className='form-control-label'>
                  Process Type {getRequiredSign()}
                </Label>
                <Select
                  isDisabled={isUploading}
                  placeholder='Select Process Type'
                  value={selectedProccessType}
                  options={proccessType}
                  onChange={(e) => setSelectedProccessType(e)}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label className='form-control-label'>Effective Date</Label>
                <Input
                  className='tdInput'
                  disabled={true}
                  value={moment().format('DD-MM-YYYY')}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label className='form-control-label'>Process ID</Label>
                <Input className='tdInput' disabled={true} value={processInstanceId} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label className='form-control-label'>Process ID Status</Label>
                <Input className='tdInput' disabled={true} value={processStatus}/>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              {progressValue !== 100 ? (
                <Button
                  color={`${progressValue < 90 ? 'info' : 'success'}`}
                  size='md'
                  disabled={!files || isUploading}
                  type='button'
                  className='float-right mb-2'
                  onClick={() => uploadFile('', 'GET_FILE_URL')}
                >
                  <i className='fas fa-upload' />&nbsp;&nbsp;Upload
                </Button>
              ) : (
                <Button
                  color={'info'}
                  size='md'
                  type='button'
                  className='float-right mb-2'
                  onClick={() => {
                    setProgressValue(0);
                    setIsUploading(false);
                    setDocumentUploadResponse('');
                    setFileName('');
                    setSelectedPersonType('');
                    setSelectedProccessType('');
                    setFiles('');
                  }}
                >
                  Reset
                </Button>
              )}
            </Col>
          </Row>
          {selectedProccessType && selectedPersonType ? (
            <MultiDocumentUpload
              setFile={setFiles}
              validFiles={['zip']}
              message={'Please select .zip file only'}
              name={'File'}
              handleFile={handleFile}
              isUploading={isUploading}
              progressBarStatus={progressValue}
            />
          ) : null}

          {fileName &&
            fileName.map((item) => (
              <div
                className={`shadow-sm p-2 my-2 bg-white rounded d-flex justify-content-between ${Styles.fileName}`}
              >
                <span className={Styles.file_name}>{item}</span>
                <span
                  style={{
                    color: `${
                      documentUploadResponse?.['bulkResponse'][
                        item
                      ].toUpperCase() === 'SUCCESS'
                        ? 'green'
                        : 'red'
                    }`,
                  }}
                >
                  {documentUploadResponse?.['bulkResponse'][item]}
                </span>
              </div>
            ))}

          {fileName?.length > 0 ? (
            <div className='d-flex'>
              <h3>Final Status</h3> :{' '}
              <p className={`${Styles.final_status}`}>
                {documentUploadResponse.finalStatus}
              </p>
            </div>
          ) : null}
        </CardBody>
      </Card>

    </Container>
  );
};

export default UploadDocuments;
