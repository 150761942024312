import { publishedStatusDropdownData } from './search-academic-plan'
import { fetchAllPostPromisedData, fetchAllPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "views/pages/manage/common/constant";

// export const dropdownMapping = async (url, displayLabel, idLabel) => {
//   const responseData = await fetchAllPromisedData(url, false);
//   if (displayLabel==='courseIds'){
//     const result = responseData.map((o) => {
//       return {label: o, value: o}
//     }) 
//     return result;
//   }
//   if (Array.isArray(responseData)) {
//     const result = responseData?.map((o) => {
//       return { label: o[displayLabel], value: o[idLabel] }
//     })
//     return result;
//   }
//   else return [];
// }

export const outlineButtonStyles = {
  border: '2px solid #00B0F5',
  borderRadius: '8px',
  padding: '17px 16px',
  backgroundColor: 'inherit',
  color: '#00B0F5',
  fontSize: '16px',
  fontWeight: '600',
  letterSpacing: '1px'
}

export const fetchBusinessAreasByRegions = async (regionArray) => {
  const response = await fetchAllPostPromisedData(endpoint.businessArea.getAllActiveBusinessAreaByRegions, { regionId: regionArray });
  if (response?.code === 200) {
    return response?.data?.map(item => { return { label: item['businessAreaDispValue'], value: item['id'] } })
  } else {
    return [];
  }
}

export const modeDropdown = [
  {label: 'Online', value: 'Online'},
  {label: 'Offline', value: 'Offline'},
  {label: 'Both - Online & Offline', value: 'Both'},
];

export const formatDropdown = [
  {label: 'Objective', value: 'Objective'},
  {label: 'Subjective', value: 'Subjective'},
];