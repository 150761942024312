import React, { useEffect, useState } from 'react';
import {
	masterServiceBaseUrl,
	getAPI,
	putpost,
	putpost2,
} from 'services/http';
import { successToast, failureToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import {
	Card,
	CardHeader,
	CardBody,
	Label,
	FormGroup,
	Form,
	Input,
	Container,
	Row,
	Col,
	Button,
} from 'reactstrap';
import { fetchDetails } from '../../../../common/utils/methods/commonMethods/utilityMethod';
import CustomDatePicker from '../../../../common/commonComponents/CustomDatePicker/index';
import Select from 'react-select';
import moment from 'moment';
import CustomLoader from '../../../../common/commonComponents/Loader/loader';
import CustomButton from 'components/CustomButton';

export const TermDateMaster = (props) => {
	const {
		termId,
		nextStep = () => { },
		isNew = true,
		setDate = () => { },
		isViewOnly = false,
		userPermissions,
	} = props;

	const [value, setValues] = useState({
		startDate: '',
		endDate: '',
		openRegDate: '',
		closeRegDate: '',
		sixtyPointOfTime: '',
		academic: [],
	});

	const [arrayState, setArrayState] = useState({
		academicYearArray: [],
	});

	const [apiloader, setApiLoader] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [termDateId, setTermDateId] = useState('');
	const [isEmpty, setIsEmpty] = useState(false);
	const [academicYear, setAcademicYear] = useState([]);

	useEffect(() => {
		fetchDetails(
			masterServiceBaseUrl + '/academicYear/active',
			setAcademicYear,
			'academicYear',
			'select'
		);
	}, []);


	const multiSelectArrayMapping = () => {
		let x = arrayState && arrayState.academicYearArray;
		value &&
			value.academic &&
			value.academic.map((data) => {
				x.push(data.value);
				setArrayState({
					...arrayState,
					academicYearArray: x,
				});
			});
	};
	const onSubmit = () => {
		setArrayState({ academicYearArray: [] });

		if (value.startDate === '') {
			failureToast('Please Enter Term Start Date');
			return;
		} else if (value.endDate === '') {
			failureToast('Please Enter Term End Date');
			return;
		} else if (value.openRegDate === '') {
			failureToast('Please Enter Open Registration Date');
			return;
		} else if (value.closeRegDate === '') {
			failureToast('Please Enter Close Registration Date ');
			return;
		} else if (value.sixtyPointOfTime === '') {
			failureToast('Please Enter Sixty Percent Point In Time ');
			return;
		} else if (value.academic == '' || value.academic === null) {
			failureToast('Please Select Academic Year ');
			return;
		}

		multiSelectArrayMapping();

		setApiLoader(true);
		const obj = {
			termMappingId: termId,
			termStartDate: value.startDate,
			termEndDate: value.endDate,
			openRegistrationDate: value.openRegDate,
			closeRegistrationDate: value.closeRegDate,
			sixtyPercentPointInTime: value.sixtyPointOfTime,
			academicYear: arrayState.academicYearArray,
			sfSyncDone: 'true',
		};

		if (isNew) {
			putpost(
				`${masterServiceBaseUrl}/termDateMaster/createTermDate`,
				(data) => {
					successToast(data['message']);
					setDate({ startDate: value.startDate, endDate: value.endDate });
					setValues({
						startDate: '',
						endDate: '',
						openRegDate: '',
						closeRegDate: '',
						sixtyPointOfTime: '',
						academic: [],
					});
					setArrayState({ academicYearArray: [] });
					setApiLoader(false);
					nextStep();
				},
				(data) => {
					setApiLoader(false);
					failureToast(data['message']);
					setArrayState({ academicYearArray: [] });
				},
				obj,
				'post'
			);
		}

		if (!isNew && isEmpty) {
			putpost(
				`${masterServiceBaseUrl}/termDateMaster/createTermDate`,
				(data) => {
					successToast(data['message']);
					setDate({ startDate: value.startDate, endDate: value.endDate });
					setValues({
						startDate: '',
						endDate: '',
						openRegDate: '',
						closeRegDate: '',
						sixtyPointOfTime: '',
						academic: [],
					});
					setArrayState({ academicYearArray: [] });
					// setApiLoader(false);
					document.location.reload();
					getTermDateMaster();
				},
				(data) => {
					setApiLoader(false);
					failureToast(data['message']);
					setArrayState({ academicYearArray: [] });
				},
				obj,
				'post'
			);
		}

		if (!isNew && !isEmpty) {
			putpost(
				`${masterServiceBaseUrl}/termDateMaster/updateTermDate/${termDateId}`,
				(data) => {
					successToast(data['message']);
					setDate({ startDate: value.startDate, endDate: value.endDate });
					setValues({
						startDate: '',
						endDate: '',
						openRegDate: '',
						closeRegDate: '',
						sixtyPointOfTime: '',
						academic: [],
					});
					setArrayState({ academicYearArray: [] });
					document.location.reload();
					getTermDateMaster();
				},
				(data) => {
					setApiLoader(false);
					failureToast(data['message']);
					setArrayState({ academicYearArray: [] });
				},
				obj,
				'put'
			);
		}
	};

	// useEffect(()=>{
	//   setValues({...value,endDate:'',openRegDate:'',closeRegDate:'',sixtyPointOfTime:''})
	// },[value.startDate])

	// useEffect(()=>{
	//   if(value.closeRegDate){
	//     setValues({...value,closeRegDate:''})
	//   }
	// },[value.openRegDate])

	const getTermDateMaster = () => {
		setApiLoader(true);
		// setValues({
		// 	academic: [],
		// });
		// setArrayState({ academicYearArray: [] });
		// console.log(
		// 	'valueAcademic_academicYearArray___',
		// 	value.academic,
		// 	arrayState.academicYearArray
		// );
		getAPI(
			`${masterServiceBaseUrl}/termDateMaster/getTermDateByTermMapping/${termId}`,
			(data) => {
				if (data.data.length) {
					let x = value.academic;
					data.data[0] &&
						data.data[0].academicYear &&
						data.data[0].academicYear.map((element) => {
							x.push(getSelectedObj(element, academicYear));
							setValues({
								...value,
								academic: x,
							});
						});
					setValues({
						...value,
						closeRegDate: moment(data.data[0].closeRegistrationDate).format(
							'YYYY-MM-DD'
						),
						startDate: moment(data.data[0].termStartDate).format('YYYY-MM-DD'),
						endDate: moment(data.data[0].termEndDate).format('YYYY-MM-DD'),
						openRegDate: moment(data.data[0].openRegistrationDate).format(
							'YYYY-MM-DD'
						),
						sixtyPointOfTime: moment(
							data.data[0].sixtyPercentPointInTime
						).format('YYYY-MM-DD'),
					});
					setArrayState({ academicYearArray: [] });
					setDate({
						startDate: moment(data.data[0].termStartDate).format('YYYY-MM-DD'),
						endDate: moment(data.data[0].termEndDate).format('YYYY-MM-DD'),
					});
					setTermDateId(data.data[0].id);
					//setValues({...value,closeRegDate: moment(data.data[0].closeRegistrationDate).format("YYYY-MM-DD")})
					setIsDisabled(true);
					setIsEmpty(false);

				}

				if (data.data.length < 1) {
					setIsEmpty(true);
				}
				setApiLoader(false);
				// setSession(getSessionObj(data.sessionId))
				// setStartDate(moment(data.startDate).format('YYYY-MM-DD'))
				// setEndDate(moment(data.endDate).format('YYYY-MM-DD'))
				// setApiLoader(false);
			},
			(data) => {
				failureToast(data['message']);
				setApiLoader(false);
			}
		);
	};

	const getSelectedObj = (id, arr) => {
		return arr.filter((item) => {
			return item.value === id;
		})[0];
	};

	React.useEffect(() => {
		if (!isNew && academicYear.length) {
			getTermDateMaster();
		}
	}, [isNew, academicYear]);

	const discard = () => {
		document.location.reload();
		setValues({
			academic: [],
		});
		setArrayState({ academicYearArray: [] });
		getTermDateMaster();
	};


	return (
		<Card>
			<CardHeader>
				<h4 className={`text-dark`}>Term / Dates</h4>
			</CardHeader>
			<CardBody>
				{apiloader ? (
					<CustomLoader
						apiLoader={apiloader}
						loaderHeight={'200px'}
						loaderWidth={'100% '}
					/>
				) : (
					<>
						<Row>
							<Col md="6">
								<FormGroup>
									<label
										className="form-control-label"
										htmlFor="example2cols1Input"
									>
										Term Start Date
									</label>
									<CustomDatePicker
										disabled={isDisabled || isViewOnly}
										value={
											value.startDate ? moment(value.startDate).toDate() : ''
										}
										index={0}
										handler={(index, date, keyName) => {
											setValues({
												...value,
												startDate: date,
												endDate: '',
												openRegDate: '',
												closeRegDate: '',
												sixtyPointOfTime: '',
											});
										}}
										keyName="startDate"
										minDate={'1950-01-01'}
										maxDate={'2099-12-31'}
										rangeFrom={1950}
										rangeTo={2099}
										placeholder="Example:01-01-2020"
									/>
									{/* <input
										value={value.startDate}
										className="form-control addresslocationInput"
										type="date"
										min={'1950-01-01'}
										max={'2099-12-31'}
										onKeyDown={(e) => e.preventDefault()}
										disabled={isDisabled}
										onChange={(e) =>
											setValues({
												...value,
												startDate: e.target.value,
												endDate: '',
												openRegDate: '',
												closeRegDate: '',
												sixtyPointOfTime: '',
											})
										}
										style={{ height: '38px' }}
										data-testid="startDate"
									/> */}
								</FormGroup>
							</Col>
							<Col md="6">
								<FormGroup>
									<label
										className="form-control-label"
										htmlFor="example2cols1Input"
									>
										Term End Date
									</label>
									<CustomDatePicker
										data-testid="endDate"
										disabled={!value.startDate || isDisabled || isViewOnly}
										minDate={value.startDate}
										value={value.endDate ? moment(value.endDate).toDate() : ''}
										index={1}
										handler={(index, date, keyName) => {
											setValues({
												...value,
												endDate: date,
												openRegDate: '',
												closeRegDate: '',
												sixtyPointOfTime: '',
											});
										}}
										keyName="endDate"
										rangeFrom={1950}
										rangeTo={2099}
										placeholder="Example:01-01-2020"
									/>
									{/* <input
										disabled={!value.startDate || isDisabled}
										min={value.startDate}
										value={value.endDate}
										onKeyDown={(e) => e.preventDefault()}
										className="form-control addresslocationInpu"
										type="Date"
										onChange={(e) =>
											setValues({
												...value,
												endDate: e.target.value,
												openRegDate: '',
												closeRegDate: '',
												sixtyPointOfTime: '',
											})
										}
										style={{ height: '38px' }}
										data-testid="endDate"
									/> */}
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col md="6">
								<FormGroup>
									<label
										className="form-control-label"
										htmlFor="example2cols1Input"
									>
										Open Registration Date
									</label>
									<CustomDatePicker
										data-testid="openRegDate"
										disabled={isDisabled || isViewOnly}
										value={
											value.openRegDate
												? moment(value.openRegDate).toDate()
												: ''
										}
										index={2}
										handler={(index, date, keyName) => {
											setValues({
												...value,
												openRegDate: date,
												closeRegDate: '',
											});
										}}
										keyName="openRegDate"
										minDate={value.startDate}
										maxDate={value.endDate}
										rangeFrom={1950}
										rangeTo={2099}
										placeholder="Example:01-01-2020"
									/>
									{/* <input
										disabled={isDisabled}
										value={value.openRegDate}
										min={value.startDate}
										max={value.endDate}
										className="form-control addresslocationInput"
										type="date"
										onKeyDown={(e) => e.preventDefault()}
										onChange={(e) =>
											setValues({
												...value,
												openRegDate: e.target.value,
												closeRegDate: '',
											})
										}
										style={{ height: '38px' }}
										data-testid="openRegDate"
									/> */}
								</FormGroup>
							</Col>
							<Col md="6">
								<FormGroup>
									<label
										className="form-control-label"
										htmlFor="example2cols1Input"
									>
										Close Registration Date
									</label>
									<CustomDatePicker
										data-testid="closeRegDate"
										disabled={!value.openRegDate || isDisabled || isViewOnly}
										value={
											value.closeRegDate
												? moment(value.closeRegDate).toDate()
												: ''
										}
										index={3}
										handler={(index, date, keyName) => {
											setValues({
												...value,
												closeRegDate: date,
											});
										}}
										keyName="closeRegDate"
										minDate={value.openRegDate}
										maxDate={value.endDate}
										rangeFrom={1950}
										rangeTo={2099}
										placeholder="Example:01-01-2020"
									/>
									{/* <input
										value={value.closeRegDate}
										disabled={!value.openRegDate || isDisabled}
										min={value.openRegDate}
										max={value.endDate}
										onKeyDown={(e) => e.preventDefault()}
										className="form-control addresslocationInpu"
										type="Date"
										onChange={(e) =>
											setValues({ ...value, closeRegDate: e.target.value })
										}
										style={{ height: '38px' }}
										data-testid="closeRegDate"
									/> */}
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col md="6">
								<FormGroup>
									<label
										className="form-control-label"
										htmlFor="example2cols1Input"
									>
										Sixty Percent Point In Time
									</label>
									<CustomDatePicker
										data-testid="sixtyPointOfTime"
										disabled={isDisabled || isViewOnly} 
										value={
											value.sixtyPointOfTime
												? moment(value.sixtyPointOfTime).toDate()
												: ''
										}
										index={4}
										handler={(index, date, keyName) => {
											setValues({
												...value,
												sixtyPointOfTime: date,
											});
										}}
										keyName="sixtyPointOfTime"
										minDate={value.startDate}
										maxDate={value.endDate}
										rangeFrom={1950}
										rangeTo={2099}
										placeholder="Example:01-01-2020"
									/>
									{/* <input
										disabled={isDisabled}
										value={value.sixtyPointOfTime}
										min={value.startDate}
										max={value.endDate}
										className="form-control addresslocationInput"
										onKeyDown={(e) => e.preventDefault()}
										type="date"
										onChange={(e) =>
											setValues({ ...value, sixtyPointOfTime: e.target.value })
										}
										style={{ height: '38px' }}
										data-testid="sixtyPointOfTime"
									/> */}
								</FormGroup>
							</Col>
							<Col md="6">
								<FormGroup>
									<label
										className="form-control-label"
										htmlFor="example2cols1Input"
									>
										Academic Year
									</label>
									{/* <input
										disabled={isDisabled}
										value={value.academic}
										className="form-control addresslocationInput"
										type="text"
										maxLength="20"
										onChange={(e) =>
											setValues({ ...value, academic: e.target.value })
										}
										style={{ height: '38px' }}
										data-testid="academic"
									/> */}
									<Select
										value={
											value.academic
												? value.academic.map((item) => {
													if (item == '') {
														return (
															setValues((preState) => ({
																...preState,
																academic: [],
															})),
															setArrayState((preState) => ({
																...preState,
																academicYearArray: [],
															}))
														);
													} else {
														return item;
													}
												})
												: ''
										}
										placeholder="Select Academic Year"
										id="example1cols1Input"
										isMulti
										options={academicYear}
										isDisabled={isDisabled || isViewOnly}
										required
										onChange={(sData) =>
											setValues({ ...value, academic: sData })
										}
									/>
								</FormGroup>

							</Col>
						</Row>
						<Row>
							<Col md="6">
								{/* <FormGroup>
									<label
										className="form-control-label"
										htmlFor="example2cols1Input"
									>
										SF Sync Done
									</label>
									<input
										disabled
										className="form-control addresslocationInput"
										type="text"
										defaultValue="Not Available"
										style={{ height: '38px' }}
									/>
								</FormGroup> */}
							</Col>
							<Col className={`d-flex align-items-center justify-content-end`}>
								{isNew ? (
									<CustomButton
										className={`ml-auto`}
										content={'Next'}
										permissionType={'C,U'}
										forTable={true}
										permissionSet={userPermissions}
										onClick={() => {
											onSubmit();
										}}
									/>
								) : !isNew & !isDisabled & !isEmpty ? (
									<>
										<Row className={`d-flex pr-3 justify-content-end`}>
											<CustomButton
												className={`mr-3`}
												content={'Save'}
												permissionType={'C,U'}
												forTable={true}
												onClick={() => {
													onSubmit();
												}}
												permissionSet={userPermissions}
											/>
											<CustomButton
												className={`mr-3`}
												content={'Cancel'}
												permissionType={'cancel'}
												forTable={true}
												permissionSet={userPermissions}
												onClick={() => {
													discard();
												}}
											/>
										</Row>
									</>
								) : !isNew & !isDisabled & isEmpty & !isViewOnly? (
									<Row className={`d-flex pr-3 justify-content-end`}>
										<CustomButton
											className={`mr-3 ml-auto`}
											content={'Save'}
											permissionType={'C,U'}
											forTable={true}
											onClick={() => {
												onSubmit();
											}}
											permissionSet={userPermissions}
										/>
									</Row>
								) : !isViewOnly ? (
									<CustomButton
										className={`ml-auto`}
										content={'Edit'}
										permissionType={'U'}
										forTable={true}
										permissionSet={userPermissions}
										onClick={() => {
											setIsDisabled(false);
										}}
									/>
								) : null}
							</Col>
						</Row>
					</>
				)}
			</CardBody>
		</Card>
	);
};
