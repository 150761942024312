import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { updateBatchStatus } from "redux/batch/batch-actions";
import { loadDropdowns, fetchMainDropdowns } from "redux/batch/batch-actions";
import { getBatchList } from "redux/batch/batch-actions";
import ALoader from "../../common/a-loader";
import {
  getAdvFilterModal,
  getAFilterModal,
  getBusinessAreas,
  reasonAndBusinessAreaModal,
} from "../constant/batch-search";
import BatchInfo from "./BatchInfo";
import BatchList from "./BatchList";
import BatchSearchFilter from "./BatchSearchFilter";
import "./batch-style.scss";
import { batchListClear } from "redux/batch/batch-actions";
import { downloadCsv, startLoader, stopLoader } from './../../../../../redux/batch/batch-actions';
import { useRegionAndBusinessArea } from "../../common/utils/customHooks";
import { useHistory } from "react-router";
import { getUrlSearchParams } from "../../common/utils/methods/commonMethods/utilityMethod";

// ids
export const Batch = () => {
  const history = useHistory();

  const preFiltersString = getUrlSearchParams(history?.location?.search)?.preFilters;
  const preFilters = preFiltersString ? JSON.parse(decodeURIComponent(getUrlSearchParams(history?.location?.search)?.preFilters)) : {};

  const dispatch = useDispatch();
  const batch = useSelector((state) => state.batch);
  const [filterForm, setFilterForm] = useState(getAFilterModal(preFilters?.ids, preFilters?.academicPlanIds));
  const [advanceFilterForm, setAdvanceFilterForm] = useState(
    getAdvFilterModal()
  );
  const [regionAndBusiness, setRegionAndBusiness] = useRegionAndBusinessArea(() => dispatch(startLoader()), () => dispatch(stopLoader()))
  const [currentPageNumber, setPageNumber] = useState(0);
  const [isShowAdvanceFilter, setAdvanceFilter] = useState(false);
  const [isShowFilter, setFilter] = useState(false);
  const [isRemoveFilter, setIsRemoveFilter] = useState(0);

  const inputHandler = (value, inputName) => {
    let currentData = { ...filterForm };
    if (inputName === "businessAreaClear") {
      currentData = { ...filterForm, businessArea: [] };
      currentData["region"] = value;
      setFilterForm(currentData);
    } else {
      currentData[inputName] = value;
      setFilterForm(currentData);
    }
  };

  useEffect(() => {
    dispatch(fetchMainDropdowns(fetchInitialList));
    dispatch(loadDropdowns());
    return ()=>dispatch(batchListClear())
  }, []);
 
  // useEffect(()=>{
  //    (updateBA(regionAndBusiness.region))
  // },[regionAndBusiness.region])

  // const updateBA = async (regions) =>{
  //    if(regions.length === 0){
  //     setRegionAndBusiness({...regionAndBusiness, businessArea:[]})
  //     return
  //    }
  //  const response = await getBusinessAreas(regions);
   
  //  setRegionAndBusiness({...regionAndBusiness, businessArea:response})
  // }

  const fetchInitialList = (data)=>{
       
       let tempRequest = ({
         businessArea: (data?.businessAreas||[]),
         region: (data?.regions || []),
        });
       setRegionAndBusiness(tempRequest)
       dispatch(
         getBatchList(
           { ...tempRequest, ...filterForm, ...advanceFilterForm },
           0
         )
       );       
  }


  const searchHandler = () => {
    setPageNumber(0);
    dispatch(
      getBatchList(
        { ...regionAndBusiness, ...filterForm, ...advanceFilterForm },
        0
      )
    );
  };

  const deActiveBatchHandler = (request, id) => {
    dispatch(
      updateBatchStatus(request, id, {
        ...regionAndBusiness,
        ...filterForm,
        ...advanceFilterForm,
      })
    );
  };

  const nextButtonHandler = (pageNumber) => {
    
    dispatch(
      getBatchList(
        { ...regionAndBusiness, ...filterForm, ...advanceFilterForm },
        pageNumber
      )
    );
    setPageNumber(pageNumber);
  };

  
  const applyFilterHandler = () => {
    setPageNumber(0);
    dispatch(
      getBatchList(
        { ...regionAndBusiness, ...filterForm, ...advanceFilterForm },
        0
      )
    );
  };
   
  const searchBatchNameHandler = (name) => {
    inputHandler(name, 'batchName')
    dispatch(getBatchList(
      { ...regionAndBusiness, ...filterForm, ...advanceFilterForm, batchName:name },
      0
    )) 
  };

  const removeFilterHandler = (dataSet) => {
    setPageNumber(0);
    dispatch(getBatchList({ ...regionAndBusiness, ...dataSet }, 0));
  };
  const leftSideFormInputHandler = (value, inputName) => {
    setRegionAndBusiness({ ...regionAndBusiness, [inputName]: value });
  };

  const downloadCSVHandler = ()=>{
    dispatch(downloadCsv({ ...regionAndBusiness, ...filterForm, ...advanceFilterForm }))
  }
  return (
    <>
      <div
        style={{
          position: "relative",
          display: "flex",
          flexFlow: "column",
          height: "calc(100vh - 120px)",
        }}
      >
        {batch.loading && <ALoader></ALoader>}
        <Card className="mx-3 mb-2" style={{ flexGrow: 1 }}>
          <CardBody style={{ display: "flex", flexFlow: "column" }}>
            <div className="search-match-container">
              <BatchInfo
                filterForm={regionAndBusiness}
                inputHandler={leftSideFormInputHandler}
                searchHandler={searchHandler}
              />
              <BatchSearchFilter
                // data={batch.batchList || []}
                normalFilterForm={filterForm}
                setNormalFilterForm={setFilterForm}
                advanceFilterForm={advanceFilterForm}
                setAdvanceFilterForm={setAdvanceFilterForm}
                inputHandler={inputHandler}
                applyFilterHandler={applyFilterHandler}
                isShowAdvanceFilter={isShowAdvanceFilter}
                setAdvanceFilter={setAdvanceFilter}
                isShowFilter={isShowFilter}
                setFilter={setFilter}
                isRemoveFilter={isRemoveFilter}
                setIsRemoveFilter={setIsRemoveFilter}
                removeFilterHandler={removeFilterHandler}
                downloadCSVHandler = {downloadCSVHandler}
                searchBatchNameHandler = {searchBatchNameHandler}
              />
            </div>
            <BatchList
              rowsData={batch.batchList || []}
              rowCount={batch.rowCount || 0}
              deActiveBatchHandler={deActiveBatchHandler}
              nextButtonHandler={nextButtonHandler}
              currentPage={currentPageNumber}
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
};
