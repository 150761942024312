import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter,ModalHeader } from 'reactstrap';
import  style from './model.module.scss';
import ClipboardIcon from 'react-clipboard-icon'
import {message} from "antd";

const styles = { fill: 'grey' }

const CustomModel = ({content,redirectHandler,copyIconVisible=false,textToCopy,modelTitle}) => {

    const [modal, setModal] = useState(true);

    const toggle = () => {
        setModal(!modal);
        redirectHandler();
    }
    const copyToClipBoard =(textToCopy)=>{
        navigator.permissions.query({name: "clipboard-write"}).then(result => {
            navigator.clipboard.writeText(textToCopy).then(() => {
                message.success({content: "ID Copied to Clipboard"});
            }, () => {
                message.error({
                    content: "There was an error while copying. Please try again!"
                });
            });
        });
    }

    return (
        <div>
          <Modal isOpen={modal} toggle={toggle} className="custom_model" centered  backdrop={'static'}>   
          {modelTitle ? <ModalHeader className={style.model_title}>{modelTitle}</ModalHeader>:null}         
            <ModalBody className={style.model_body}>
                <span style={{marginRight: '10px'}}>{content}</span>
                {copyIconVisible ?<span style={{float: 'right'}}>
                    <ClipboardIcon
                        size={23}
                        style={styles}
                        onClick={() => {
                            copyToClipBoard(textToCopy);
                        }}
                    />
                </span>:null}
            </ModalBody>
            <ModalFooter className={style.model_footer}>
              <Button color="info" onClick={toggle} style={{width:'50px'}} size='sm'>ok</Button>
            </ModalFooter>
          </Modal>
        </div>
      );
}

export default CustomModel;