import React, { Fragment, useState, useEffect } from 'react';
import {
	Container,
	Row,
	Button,
	Col,
	Card,
	CardHeader,
	CardBody,
	Form,
	FormGroup,
	Label,
	Input,
} from 'reactstrap';
import Select from 'react-select';
import BankDetails from './bank';
import {
	getAPI,
	masterServiceBaseUrl,
	putpost,
	successToast,
	failureToast,
} from 'services/http';
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import CustomLoader from '../../common/commonComponents/Loader/loader';
import {
	fetchDetails,
	getDispValForSelectByOneID,
} from '../../common/utils/methods/commonMethods/utilityMethod';



const SearchHouseBank = () => {
	let history = useHistory();
	const [apiLoader, setApiLoader] = useState(false);
	const [country, setCountry] = useState([]);
	const [bankCodeArray, setBankCodeArray] = useState([]);
	const [searchDataList, setSearchDataList] = useState([]);
	const [status, setStatus] = useState([
		{ value: 'ACTIVE', label: 'ACTIVE' },
		{ value: 'INACTIVE', label: 'INACTIVE' },
	]);

	const [pagination, setPagination] = useState({
		currentPage: 1,
		nextPage: null,
		previousPage: null,
		totalPage: null,
	});
	const [search, setSearch] = useState({
		bankCode: null,
		countryCode: null,
		status: 'ACTIVE',
	});
	const header = [
		{ label: "SR.", key: "sNo" },
		{ label: "Coutry", key: "country" },
		{ label: "Bank Code", key: "bankCode" },
		{ label: "Alternate Bank Code", key: "alternateBankCode" },
		{ label: "Bank Name", key: "bankName" },
		{ label: "Status", key: "status" },
		{ label: "Updated On", key: "updatedOn" },
	]
	const [downloadData,setDownloadData] = useState([])

	useEffect(() => {
		fetchDetails(
			masterServiceBaseUrl + '/country/getAllActiveCountry',
			setCountry,
			'country',
			'select'
		);
	}, []);

	const pageSize = 10;
	const fetchSearchList = async (url) => {
		try {
			if (!search['countryCode']) return failureToast('Please select Country');
			if (!search['status']) return failureToast('Please select Status');
			setApiLoader(true);
			putpost(
				url,
				(data) => {
					setSearchDataList(data['data']);
					let tempArray = [];
					if(data?.data.length > 0){
						data.data.map((item,index)=>{
							tempArray.push({
								sNo:index + 1,
								country:getDispValForSelectByOneID(item['countryCode'], 'DispValue', country),
								bankCode : item.bankCode,
								alternateBankCode : item.alternateBankCode,
								bankName : item.bankName,
								status : item.status,
								updatedOn : item.updatedOn
							})
						})
					}
					setDownloadData(tempArray);
					let n = pagination;
					if (data['data'].length > pageSize) {
						n['nextPage'] = n.currentPage + 1;
					}
					if (n.currentPage > 1) {
						n.previousPage = n.currentPage - 1;
					}
					n['totalPage'] = Math.floor(data['data'].length / pageSize);
					if (data['data'].length % pageSize != 0) {
						n['totalPage'] += 1;
					}
					setPagination(n);
					setBankCodeArray(
						data['data'].slice(
							pagination.currentPage * pageSize - pageSize,
							pagination.currentPage * pageSize
						) || []
					);
					setApiLoader(false);
				},
				(data) => {
					failureToast(data['message']);
					setApiLoader(false);
				},
				search,
				'post'
			);
		} catch (e) {
			console.log('Error', e);
		}
	};
	const nextPage = () => {
		try {
			setBankCodeArray(
				searchDataList.slice(
					(pagination.currentPage + 1) * pageSize - pageSize,
					(pagination.currentPage + 1) * pageSize
				)
			);
			let n = pagination;
			n['currentPage'] = n['currentPage'] + 1;
			n['previousPage'] = n.currentPage;
			setPagination(n);
		} catch (e) {
			console.log('Error', e);
		}
	};
	const firstPage = () => {
		try {
			setBankCodeArray(
				searchDataList.slice(1 * pageSize - pageSize, 1 * pageSize)
			);
			let n = pagination;
			n['currentPage'] = 1;
			n['previousPage'] = null;
			setPagination(n);
		} catch (e) {
			console.log('Error', e);
		}
	};
	const lastPage = () => {
		try {
			setBankCodeArray(
				searchDataList.slice(
					pagination.totalPage * pageSize - pageSize,
					pagination.totalPage * pageSize
				)
			);
			let n = pagination;
			n['currentPage'] = n.totalPage;
			n['previousPage'] = n.totalPage - 1;
			setPagination(n);
		} catch (e) {
			console.log('Error', e);
		}
	};
	const previousPage = () => {
		try {
			setBankCodeArray(
				searchDataList.slice(
					(pagination.currentPage - 1) * pageSize - pageSize,
					(pagination.currentPage - 1) * pageSize
				)
			);
			let n = pagination;
			n['currentPage'] = n['currentPage'] - 1;
			if (n['currentPage'] == 1) {
				n['previousPage'] = null;
			} else {
				n['previousPage'] = n.currentPage;
			}
			setPagination(n);
		} catch (e) {
			console.log('Error', e);
		}
	};

	const searchData = (value, property) => {
		setSearch({
			...search,
			[property]: value,
		});
	};

	const resetSearch = () => {
		try {
			setSearchDataList([]);
			setSearch({
				bankCode: '',
				status: 'ACTIVE',
				countryCode: null,
			});
		} catch (e) {
			console.log(e);
		}
	};


	return (
		<Fragment>
			<Container className="mt-3" fluid={true}>
				<Row>
					<Col>
						<Card>
							<CardHeader className="border-0">
								<h3 className="mb-0 floatLeft headingColor">
									Search Bank Code
								</h3>
								<Button
									color="info"
									size="sm"
									type="button"
									className="floatRight"
									onClick={() => history.push('/admin/bank/new')}
								>
									Add New Bank Code
								</Button>
							</CardHeader>
							<hr />
							<CardBody>
								<Row>
									<Col md={6}>
										<FormGroup>
											<Label className="form-control-label">
												<span className="custom-span-color">*</span>Country
											</Label>
											<Select
												value={{
													value: search['countryCode'],
													label: search['countryCode'] ? (
														getDispValForSelectByOneID(
															search['countryCode'],
															'DispValue',
															country
														)
													) : (
														<span className="customSpan">Select Country</span>
													),
												}}
												options={country}
												onChange={(e) => {
													searchData(e.value, 'countryCode');
												}}
											></Select>
										</FormGroup>
									</Col>
									<Col md={6}>
										<FormGroup>
											<Label className="form-control-label">
												<span className="custom-span-color">*</span>Status
											</Label>
											<Select
												value={{
													value: search['status'],
													label: search['status'],
												}}
												options={status}
												placeholder="Status"
												onChange={(e) => searchData(e.value, 'status')}
											></Select>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col md={6}>
										<FormGroup>
											<Label className="form-control-label">Bank Code</Label>
											<Input
												maxLength={50}
												value={search['bankCode']}
												className="customInput"
												onChange={(e) => searchData(e.target.value, 'bankCode')}
												placeholder="Enter Bank Code"
											/>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className="floatRight">
											<Button
												data-testid="searchDataBank"
												size="md"
												type="button"
												color="info"
												onClick={() =>
													fetchSearchList(
														masterServiceBaseUrl +
															'/bank/searchBankByCountryAndStatusAndBankCode'
													)
												}
											>
												<i className="fas fa-search"></i> Search
											</Button>
											<Button
												size="md"
												type="button"
												color="secondary"
												style={{ background: 'gray' }}
												onClick={() => resetSearch()}
											>
												Reset Filters
											</Button>
										</div>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			{apiLoader ? (
				<CustomLoader
					apiLoader={apiLoader}
					loaderHeight={'200px'}
					loaderWidth={'100% '}
				/>
			) : !(searchDataList.length === 0) ? (
				<Container fluid={true}>
					<Row>
						<Col>
							<Card>
								<CardHeader className="border-0">
									<h3 className="mb-0 floatLeft headingColor">Bank Code</h3>
									<div className="floatRight">
										<CSVLink
											data={downloadData}
											headers={header}
											filename={"Bank_Code_Search.csv"}
											className="mr-3"
										>
											<Button color="info" size="sm" type="button">
												Export as excel
											</Button>
										</CSVLink>
									</div>
								</CardHeader>
								<hr />
								<CardBody className="customCardBody">
									<BankDetails
										country={country}
										search={search}
										setSearch={setSearch}
										previousPage={previousPage}
										lastPage={lastPage}
										firstPage={firstPage}
										nextPage={nextPage}
										pagination={pagination}
										setPagination={setPagination}
										bankCodeArray={bankCodeArray}
										setBankCodeArray={setBankCodeArray}
										fetchSearchList={fetchSearchList}
										searchDataList={searchDataList}
										setSearchDataList={setSearchDataList}
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			) : null}
		</Fragment>
	);
};

export default SearchHouseBank;
