import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormGroup } from "@mui/material";
 export const InputStyle = {
  padding: "7px 8px",
  height: "31px",
  fontSize:"12px",
  background: "#FFFFFF",
  borderRadius: "8px",
  width:"100%",
  maxHeight:"15rem",
};
 export const InputLabelStyle = {
  fontFamily: "Gilroy-Regular",
  fontSize:"12px",
  lineHeight: "14px",
  color: "#414141",
  marginTop:"10px"
};
const dropdownsListStyle = {
    fontSize:"12px"
}

const getByLabelText = (selected, dropdownsValues =[]) => {
  const labels = [];
  dropdownsValues.map((item) => {
    if (selected === item.value) 
      labels.push(item.label);
  });
  return labels.join(", ");
};
export const ASelect = ({ items,inputKey, currentValue, label, handleChange, placeholder, updatedStyle}) => {
  return (
    <FormGroup>
      {label &&<InputLabel sx={InputLabelStyle}>{label}</InputLabel>}
      <Select
        sx={{...InputStyle, ...updatedStyle}}
        value={currentValue}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        onChange = {(e)=>handleChange(e.target.value, inputKey)}
        renderValue={(selected) => {
          if (!selected || selected.length === 0) {
            return <div>{placeholder||"Select"}</div>;
          }
          return getByLabelText(selected, items)
        }}
       
      >
        <MenuItem  value=""  sx ={dropdownsListStyle}>
          <em>Select</em>
        </MenuItem>
        
        { items && items.map((item) => (
          <MenuItem 
          sx ={dropdownsListStyle} 
          key={item.value}
          value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>

    </FormGroup>
  );
};
