import React, {Fragment, useEffect, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Form, ModalFooter, Row, Table} from "reactstrap";
import CommonInput from "../../../common/formFeilds/input/commonInput";
import {MasterHeaderAuto} from "../../../common/commonComponents/masterHeader/masterHeader";
import CustomButton from "../../../../../../components/CustomButton";
import  TemplateHelpers from '../utils/templateHelpers';

const NewTableRow =(props)=>{
    const attributes =  TemplateHelpers.getTemplateTableFieldAttributes(props);
    return (
        <tr key={'templateFields' + props.index}>
            <td className=" p-2 mx-1" >
                <span style={{paddingLeft:'28px'}}>
                    {(props.index + 1)}
                </span>
            </td>
            <td className=" p-2 mx-1">
                <CommonInput {...attributes.fieldName}/>
            </td>
            <td className="p-2 mx-1">
                <CommonInput {...attributes.fieldType}/>
            </td>
            <td className="p-2 mx-1">
                <CommonInput {...attributes.required_message}/>
            </td>
            <td className="p-2 mx-1">
                <CommonInput {...attributes.error}/>
            </td>
            <td className="p-2 mx-1">
                <CommonInput {...attributes.mandatory}/>
            </td>
            <td className=" p-2 mx-1">
                <CommonInput {...attributes.fieldLength}/>
            </td>
            <td className=" p-2 mx-1">
                <Button color="danger"  size='sm' onClick={()=>props.deleteRowHandler(props.index)}>
                    <i className="fas fa-trash"/>
                </Button>
            </td>
        </tr>
    )
}

const ReadOnlyRow =(props)=>{
   return (
       <tr key={'templateFields' + props.index}>
            <td className="p-2 mx-1" >
                <span style={{paddingLeft:'28px'}}>
                    {props.index >=0 ? props.index + 1: ''}
                </span>
            </td>
           <td className=" p-2 mx-1">
               {props.item?.columnName ?? ''}
           </td>
           <td className="p-2 mx-1">
               {props.item?.templateDatatypeMasterId ? props.fieldTypes[props.item?.templateDatatypeMasterId] : ''}
           </td>
           <td className="p-2 mx-1">
                {props.item?.required_message ?? ''}
           </td>
           <td className="p-2 mx-1">
               {props.item?.error ?? ''}
           </td>
           <td className=" p-2 mx-1">
               {props.item?.required ? 'Yes' : 'No'}
           </td>
           <td className="p-2 mx-1">
               {props.item?.columnDataLength ?? ''}
           </td>
           {
               props.action === 'view' || !(props.userPermissions && (props.userPermissions.includes('U'))) ? null :
               <td className=" p-2 mx-1">
               <CustomButton
                   permissionType={'U'}
                   icon={true}
                   forTable={true}
                   onClick={() => {
                       props.editRowHandler(props.index)
                   }}
                   permissionSet={props.userPermissions}
               />
           </td>}
        </tr>
   )
}

const FieldsTableHeader =(props)=> {
    return (
        <thead className="thead-light">
            <tr>
                {
                    props.headerList.map((headerItem, index) =>
                        <th
                            style={{
                                ...headerItem.styles ? headerItem.styles :'',
                                width: `${headerItem.hasOwnProperty("width") ? headerItem.width : ""}`,
                            }}
                            className={"text-uppercase white-breakSpace column-heading"}
                            rowSpan={headerItem.rowSpan || "1"}
                            colSpan={headerItem.colSpan || "1"}
                            key={headerItem.name + index}
                        >
                            {headerItem.name}
                        </th>)
                }
            </tr>
        </thead>
    )
}

const FieldsTable =(props)=>{
    return (
        !props.isTableVisible
            ?
            null
            :
            <div style={{marginTop: '15px'}} className="col">
                <Card >
                    <CardHeader>
                        <h3 style={{display : 'inline'}}>{'Template Fields'}</h3>
                        {
                            (
                                props.action === 'view')
                                ?
                                null
                                :
                                <CustomButton
                                    className={'floatRight mx-1'}
                                    content={'Add Fields'}
                                    permissionType={'C'}
                                    icon={true}
                                    onClick={() => props.setIsAddNewModalVisible(true)}
                                    permissionSet={props.userPermissions}
                                />
                        }
                    </CardHeader>
                    <Table className="align-items-center table-flush">
                        <FieldsTableHeader headerList={props.action === 'view' || !(props.userPermissions.includes('U')) ? TemplateHelpers.FIELD_CONFIGURATION_TABLE_HEADER.slice(0,7) : TemplateHelpers.FIELD_CONFIGURATION_TABLE_HEADER}/>
                        <tbody className="list" key={'templateConfigurationTableBody'} id={'questionTable'}>
                            {
                                props?.fieldsTableData &&
                                props.fieldsTableData.map((item,index)=>
                                (item.isNew || item.isEdit)?
                                    <NewTableRow key={'templateConfigurationTableRow'+index} index={index} item={item} {...props}/>
                                     :
                                    <ReadOnlyRow key={'templateConfigurationViewOnlyRow'+index} index={index} item={item} userPermissions={props.userPermissions} {...props} />
                                )
                            }
                        </tbody>
                    </Table>
                    {
                        props?.fieldsTableData && props?.fieldsTableData.length > 0 && (props.action === 'edit' || props.action === 'new') &&
                        <CardBody>
                            <Row>
                                <Col md={12}>
                                    <CustomButton
                                        className={'floatRight mx-1'}
                                        content={'Submit'}
                                        permissionType={'C,U'}
                                        onClick={() => props.submitFieldsHandler()}
                                        permissionSet={props.userPermissions}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    }
                </Card>
            </div>
    )
}

export default FieldsTable;
