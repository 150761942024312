import React, { useState, useContext } from 'react';
import { Card, CardHeader, Container } from 'reactstrap';

import { TermMapping } from './termMapping';
import { TermDateMaster } from './termDateMaster';
import TermSession from './termSession';
import { PermissionContext, RolePermissions } from 'appContext';
import { pages } from '../../../../common/constant';
import { PermisionDenied } from '../../../../common/commonComponents';

const NewTerm = (props) => {
	const { id, action } = props.match.params;
	const [step, setStep] = useState(0);
	const [termId, setTermId] = useState(null);
	const [isNew, setIsNew] = useState(true);
	const [isViewOnly, setIsViewOnly] = useState(false);
	const [dates, setDates] = useState(null);

	const permissions = useContext(PermissionContext);

	const userPermissions = RolePermissions(
		permissions,
		pages['termFormTable']['id']
	);

	React.useEffect(() => {
		if (action === 'view' && id) {
			setTermId(id);
			setIsNew(false);
			setIsViewOnly(true);
		}
		if (action === 'edit' && id) {
			setTermId(id);
			setIsNew(false);
			setIsViewOnly(false);
		}
	}, [id]);

	const nextStep = (id) => {
		if (id) {
			setTermId(id);
		}
		setStep(step + 1);
	};

	const handleDateChange = (date) => {
		setDates(date);
	};

	let pageToRender = null;

	switch (step) {
		case 0:
			pageToRender = (
				<TermMapping
					nextStep={nextStep}
					isNew={isNew}
					termId={termId}
					userPermissions={userPermissions}
				/>
			);
			break;
		case 1:
			pageToRender = (
				<TermDateMaster
					termId={termId}
					nextStep={nextStep}
					isNew={isNew}
					setDate={handleDateChange}
					userPermissions={userPermissions}
				/>
			);
			break;
		case 2:
			pageToRender = (
				<TermSession
					termId={termId}
					nextStep={nextStep}
					isNew={isNew}
					initialDate={dates}
					userPermissions={userPermissions}
				/>
			);
			break;
		default:
			pageToRender = <TermMapping />;
	}

	return (
		<>
			{userPermissions ? (
				<Container className="mt-3" fluid>
					<Card className="mb-4">
						<CardHeader>
							<h3>Term Table</h3>
						</CardHeader>
					</Card>
					{isNew ? (
						pageToRender
					) : (
						<>
							<TermMapping
								isNew={isNew}
								termId={termId}
								isViewOnly={isViewOnly}
								userPermissions={userPermissions}
							/>
							<TermDateMaster
								termId={termId}
								isNew={isNew}
								setDate={handleDateChange}
								isViewOnly={isViewOnly}
								userPermissions={userPermissions}
							/>
							<TermSession
								termId={termId}
								isNew={isNew}
								initialDate={dates}
								isViewOnly={isViewOnly}
								userPermissions={userPermissions}
							/>
						</>
					)}
				</Container>
			) : (
				<PermisionDenied />
			)}
		</>
	);
};

export default NewTerm;
