import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import AAutoComplete from "../../../../common/form-fields-mui/AAutoComplete";
import AButton from "../../../../common/form-fields-mui/AButton";
import { InputLabel } from "@mui/material";
import { InputLabelStyle } from "../../../../common/form-fields-mui/ASelect";
import CustomDatePicker from "../../../../common/commonComponents/CustomDatePicker";
import { getFilterCount } from "../../mastersAndMapping/onBoardingMaterialIdSetup/constant";

import { dispatchIdDropdown } from "../../dlpStockIssuance/dispatchSchedule/constant";
import {
  DISPATCHED_FILTER,
  DISPATCH_PENDING_FILTER,
  statusDispatchedDropdown,
} from "../../dlpStockIssuance/issueStock/helper";
import { useGetDigitalModuleContext } from "../../contextApi/digitalContext";

const statusDropdown = [
  { label: "Dispatch Pending", value: "DISPATCH_PENDING" },
  { label: "Stock Issued", value: "STOCK_ISSUED" },
  { label: "Returned", value: "RETURNED" },
];

const FromToInput = ({
  labelFrom,
  labelTo,
  filterForm,
  fieldFrom,
  fieldTo,
  filterFormHandler,
}) => {
  return (
    <div>
      <Row>
        <Col sm="6" className="d-flex flex-column">
          <InputLabel style={InputLabelStyle}>{labelFrom}</InputLabel>
          <CustomDatePicker
            placeHolder="Select"
            maxDate={moment()}
            value={filterForm[fieldFrom] ? moment(filterForm[fieldFrom]) : null}
            handler={(index, date, keyName) => {
              filterFormHandler(date, fieldFrom);
            }}
          />
        </Col>
        <Col sm="6" className="d-flex flex-column">
          <InputLabel style={InputLabelStyle}>{labelTo}</InputLabel>
          <CustomDatePicker
            placeHolder="Select"
            value={filterForm[fieldTo] ? moment(filterForm[fieldTo]) : null}
            handler={(index, date, keyName) => {
              filterFormHandler(moment(date).endOf("day"), fieldTo);
            }}
            maxDate={moment()}
            minDate={moment(filterForm?.[fieldFrom] || null)}
            disabled={!filterForm?.[fieldFrom]}
          />
        </Col>
      </Row>
    </div>
  );
};

const SearchFilterForm = ({
  dropdown,
  closeForm,
  filterPayload,
  applyFilter = () => {},
  filterCount,
}) => {
  const [filterForm, setFilterForm] = useState(filterPayload);
  const { selectedTab } = useGetDigitalModuleContext();
  const filterFormHandler = (value, key) =>
    setFilterForm((prev) => ({ ...prev, [key]: value }));

  useEffect(() => {
    setFilterForm(filterPayload);
  }, [filterPayload]);

  const GetDispatchedTabFilter = () => {
    return (
      <div className="d-flex flex-column gap-md">
        <AAutoComplete
          label="Business area"
          currentValue={filterForm?.businessArea}
          items={dropdown?.businessArea}
          handleChange={(value) => {
            filterFormHandler(value, "businessArea");
          }}
          isMulti={false}
        />

        <AAutoComplete
          label="Course ID"
          currentValue={filterForm?.courseId}
          items={dropdown?.courseId}
          handleChange={(value) => {
            filterFormHandler(value, "courseId");
          }}
          isMulti={false}
        />
        <AAutoComplete
          label="Term"
          currentValue={filterForm?.term}
          items={dropdown?.term}
          handleChange={(value) => {
            filterFormHandler(value, "term");
          }}
          isMulti={false}
        />
        <AAutoComplete
          label="Dispatch ID"
          currentValue={filterForm?.dispatchId}
          items={dispatchIdDropdown}
          handleChange={(value) => {
            filterFormHandler(value, "dispatchId");
          }}
          isMulti={false}
        />
        <AAutoComplete
          label="Status"
          currentValue={filterForm?.status}
          items={statusDispatchedDropdown}
          handleChange={(value) => {
            filterFormHandler(value, "status");
          }}
          isMulti={false}
        />
        {FromToInput({
          labelFrom: "Dispatch date from",
          labelTo: "To",
          fieldFrom: "dispatchDateFrom",
          fieldTo: "dispatchDateTo",
          filterFormHandler,
          filterForm,
        })}
      </div>
    );
  };

  const getDispatchPendingTabDropdown = () => {
    return (
      <div className="d-flex flex-column gap-md">
        <AAutoComplete
          label="Business area"
          currentValue={filterForm?.businessArea}
          items={dropdown?.businessArea}
          handleChange={(value) => {
            filterFormHandler(value, "businessArea");
          }}
          isMulti={false}
        />

        <AAutoComplete
          label="Course ID"
          currentValue={filterForm?.courseId}
          items={dropdown?.courseId}
          handleChange={(value) => {
            filterFormHandler(value, "courseId");
          }}
          isMulti={false}
        />
        <AAutoComplete
          label="Term"
          currentValue={filterForm?.term}
          items={dropdown?.term}
          handleChange={(value) => {
            filterFormHandler(value, "term");
          }}
          isMulti={false}
        />
        <AAutoComplete
          label="Dispatch ID"
          currentValue={filterForm?.dispatchId}
          // items={dispatchScheduleDropdown}
          items={dispatchIdDropdown}
          handleChange={(value) => {
            filterFormHandler(value, "dispatchId");
          }}
          isMulti={false}
        />
        {FromToInput({
          labelFrom: "Eligible date - From",
          labelTo: "To",
          fieldFrom: "eligibleDateFrom",
          fieldTo: "eligibleDateTo",
          filterFormHandler,
          filterForm,
        })}
        {FromToInput({
          labelFrom: "Stock issue - From ",
          labelTo: "To",
          fieldFrom: "issueDateFrom",
          fieldTo: "issueDateTo",
          filterFormHandler,
          filterForm,
        })}
        <AAutoComplete
          label="Status"
          currentValue={filterForm?.status}
          items={statusDropdown}
          handleChange={(value) => {
            filterFormHandler(value, "status");
          }}
          isMulti={false}
        />
      </div>
    );
  };

  const getOnHoldFilter = () => {
    return (
      <div className="d-flex flex-column gap-md">
        <AAutoComplete
          label="Business area"
          currentValue={filterForm?.businessArea}
          items={dropdown?.businessArea}
          handleChange={(value) => {
            filterFormHandler(value, "businessArea");
          }}
          isMulti={false}
        />

        <AAutoComplete
          label="Course ID"
          currentValue={filterForm?.courseId}
          items={dropdown?.courseId}
          handleChange={(value) => {
            filterFormHandler(value, "courseId");
          }}
          isMulti={false}
        />
        <AAutoComplete
          label="Term"
          currentValue={filterForm?.term}
          items={dropdown?.term}
          handleChange={(value) => {
            filterFormHandler(value, "term");
          }}
          isMulti={false}
        />
        <AAutoComplete
          label="Dispatch ID"
          currentValue={filterForm?.dispatchId}
          // items={dispatchScheduleDropdown}
          items={dispatchIdDropdown}
          handleChange={(value) => {
            filterFormHandler(value, "dispatchId");
          }}
          isMulti={false}
        />
        {FromToInput({
          labelFrom: "On-Hold - From",
          labelTo: "To",
          fieldFrom: "updateDateFrom",
          fieldTo: "updateDateTo",
          filterFormHandler,
          filterForm,
        })}
        {/* {FromToInput({
          labelFrom: 'Stock issue - From ',
          labelTo: 'To',
          fieldFrom: 'issueDateFrom',
          fieldTo: 'issueDateTo',
          filterFormHandler,
          filterForm,
        })} */}
        {/* <AAutoComplete
          label="Status"
          currentValue={filterForm?.status}
          items={statusDropdown}
          handleChange={value => {
            filterFormHandler(value, 'status');
          }}
          isMulti={false}
        /> */}
      </div>
    );
  };

  return (
    <Card
      className="mb-0 regular-body label-color"
      style={{ width: "330px", borderRadius: "12px" }}
    >
      <CardBody className="d-flex flex-column">
        <div className="mb-1 d-flex align-items-start justify-content-between">
          <div className="regular-large-bold">Filter by</div>
        </div>

        {selectedTab === "DISPATCH_PENDING"
          ? getDispatchPendingTabDropdown()
          : selectedTab == "DISPATCHED"
          ? GetDispatchedTabFilter()
          : getOnHoldFilter()}
        <div className="mt-3 d-flex flex-row-reverse justify-content-between">
          <AButton
            variant="primary_filled"
            className="button_remove_left_margin"
            onClick={() => {
              closeForm();
              applyFilter(filterForm);
            }}
            disabled={!getFilterCount(filterForm)}
          >
            Apply filter
          </AButton>
          {filterCount > 0 && (
            <AButton
              variant="outline"
              className="button_remove_left_margin"
              updatedStyle={{ border: "1px solid #CC5F5F", color: "#CC5F5F" }}
              onClick={() => {
                closeForm();
                applyFilter(
                  selectedTab === "DISPATCH_PENDING"
                    ? DISPATCH_PENDING_FILTER
                    : DISPATCHED_FILTER
                );
              }}
            >
              Remove filter
            </AButton>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default SearchFilterForm;
