import { Box, Card, Dialog, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import './index.scss';
import CustomButton from 'components/CustomButton';
import APopover from '../../common/a-popover';
import ApprovalDialogContent from './dialogContent';
import { constants } from "../../common/constant/constants/index";
import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedRowsIds, getApprovalRequests, setSelectedRowIds } from '../../../../../redux/concessionApproval/actions';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import { getApprovedBy, getFormatedDate, getSuccessMsg, getUpdatedRow, getUserRemarks } from './common';
import { AiOutlineEye, AiOutlineInfoCircle } from 'react-icons/ai';
import { LightTooltip } from '../studentCharges/studentChargesDetails/concessionDetails/prospectus';
import moment from 'moment';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../common/constant';
import closeIcon from 'assets/img/svg/closeSquare.svg'
import { ReactComponent as UserProfile } from 'assets/img/svg/userProfile.svg';
import { DiscretionarySupportingDocUploader } from '../studentCharges/studentChargesDetails/concessionDetails/upload';
import DocumentVerification from './documentVerification';
import { useHistory } from 'react-router';
import FeeBreakupCard from './FeeBreakupCard';
import ConsessionTreeClassification from '../../common/commonComponents/treeView';
import RemarksDialogue, { fetchRemarks } from './RemarksDialogue';
import { getFeeBreakup } from '../studentCharges/studentChargesDetails/finalizeCharges';
import { getCourseFeeSummary } from '../studentCharges/studentChargesDetails/templates/mapping';
import { getEligibleComponentList, getBreakUp, createBreakUpData, getConsessionDetails, getFileUploadUrls, createBreakUpDataOnUI } from '../studentCharges/studentChargesDetails/feeBreakup/common'
import { updateQuestionStatus } from '../studentCharges/studentChargesDetails/common';
import { getCurrentYear } from '../studentCharges/studentChargesDetails/common';
import { formatPaidItemType, getPaidItemListApi } from '../studentCharges/studentChargesDetails/concessionDetails/common';
import { CMPR_FLAG_VALUES } from '../studentCharges/studentChargesDetails/concessionDetails/ApplyCmprDialog';

const DecoratedCell = props => {
    return (
        <Box style={{ color: '#262626' }} class={props?.islink ? 'approval-cell link' : 'approval-cell'}>
            {props.children}
        </Box>
    )
}

const FeesCell = props => {
    return (
        <div className='fees-cell-container'>
            <LightTooltip title={props?.title ? props?.value : null} >
                <span className='cell-text'>
                    <DecoratedCell>{props.value}</DecoratedCell>
                </span>
            </LightTooltip>
            <LightTooltip title={props?.title ? props?.title : props?.value}>
                <span className='cell-tooltip'>
                    <AiOutlineInfoCircle color='#00B0F5' size={16} />
                </span>
            </LightTooltip>
        </div>
    )
}

export const getCourseDetailsByCourseId = async (courseId) => {
    const resp = await fetchAllPromisedData(`${endpoint.course_details.getByCourseId}/${courseId}`, true);
    if (resp?.code === 200) {
        return ({ label: resp?.data[0]?.courseName, value: resp?.data[0]?.courseId });
    }
}

export const PSIDTooltipTitle = ({ courseCategory, courseId, psid }) => {
    const [course, setCourse] = useState();
    useEffect(() => {
        const fetchCourse = async () => {
            const course = await getCourseDetailsByCourseId(courseId);
            setCourse(course);
        }

        fetchCourse();
    }, [courseId])
    return (
        course
            ? <span><strong>{`PSID- ${psid}`}</strong>{` | ${course?.value || ""}, ${course?.label || ""}, ${courseCategory?.label || ""}`}</span>
            : <span>Loading...</span>
    )

}

const isFirstInstallmentPaid = (paidList) => {
    return paidList.some(item => item?.itemType?.slice(-4, -2) === "01")
}

const ApprovalBranch = props => {
    const { stage, businessAreaId, rowsData, userPermissions, userDetails, appliedFilters, setApproving, appliedPsid, allCategories } = props;
    const { APPROVAL_ACTIONS, APPROVAL_STAGE } = constants;

    const dispatch = useDispatch();
    const history = useHistory();
    const approvalState = useSelector(state => state.concessionApproval);

    const [remarksData, setRemarksData] = useState(null);
    const [open, setOpen] = useState(false);
    const [saving, setSaving] = useState(false);


    const onActionClick = async (approvalStatus, remarks, row, closeForm) => {
        setSaving(true)
        const approvalType = approvalState.approverType?.value;
        const payload = [getUpdatedRow(row, approvalType, approvalStatus, remarks, userDetails)];
        setApproving(true)
        try {
            const response = await fetchAllPostPromisedData(endpoint.approval.status, payload, 'put');
            if (response?.code === 200) {
                if (response.data[0]?.chargeStatus === 'PAPP' && response.data?.[0]?.concessionDetailsDtoList?.[0]?.isApproved) {
                    const p = Promise.all([
                        await fetchAllPromisedData(`${endpoint.getStudentChargesApplicationDetails}/${row.applicationId}/STUDENT_CHARGES`),
                        await fetchAllPromisedData(endpoint.item_type_setup.getAll, true),
                        await fetchAllPromisedData(`${endpoint.treeView}`, false),
                        await fetchAllPromisedData(`${endpoint.applicationQuotationDetails}/${row.applicationId}`, false),

                    ]).then(async (values) => {

                        let paidItemList = []
                        let cmprFlag = values[0]?.cmpr;
                        const currentDbFlag = values[0]?.cmpr;

                        if (cmprFlag === CMPR_FLAG_VALUES.CMPR || cmprFlag === null || cmprFlag === CMPR_FLAG_VALUES.BYPASS_CMPR) {
                            const response = await getPaidItemListApi(values[0])
                            paidItemList = (formatPaidItemType(response))
                            cmprFlag = CMPR_FLAG_VALUES?.CMPR;
                            if (currentDbFlag === null && !isFirstInstallmentPaid(paidItemList)) {
                                cmprFlag = CMPR_FLAG_VALUES.BYPASS_CMPR
                            }
                            if (currentDbFlag === CMPR_FLAG_VALUES.BYPASS_CMPR) {
                                cmprFlag = CMPR_FLAG_VALUES.BYPASS_CMPR
                            }
                        }
                        const course = [await getCourseDetailsByCourseId(row.courseId)];
                        const breakup = await fetchAllPromisedData(`${endpoint.feeBreakfromPricingByApplicationId}/${row.applicationId}?quotationId=${row?.applicationQuotationDetailsId}`, true);
                        const { groupedData, itemTypeLabelMapping, total } = await getBreakUp(breakup, values?.[1]);
                        const studentCurrentYear = await getCurrentYear(values?.[0]);
                        const { totalConsession, concessionAppliedList } = await getConsessionDetails(response.data?.[0]?.concessionDetailsDtoList, total, studentCurrentYear);
                        // paid list , cmpr flag,
                        // const {tempData,totalTempData, percentPendingForApproval} = createBreakUpData(groupedData,totalConsession,{}, concessionAppliedList, studentCurrentYear,response.data?.[0]?.concessionDetailsDtoList);
                        const { tempData, totalTempData, percentPendingForApproval } = createBreakUpDataOnUI(
                            groupedData, totalConsession, {}, concessionAppliedList,
                            studentCurrentYear, response.data?.[0]?.concessionDetailsDtoList,
                            { paidItems: paidItemList, pastIdSelected: false, cmpFlag: cmprFlag }
                        );
                        const feeBreakup = getFeeBreakup({ data: tempData, total: totalTempData }, row?.applicationQuotationDetailsId, true)
                        const csummary = getCourseFeeSummary(values[0], { data: tempData, total: totalTempData }, response.data?.[0]?.concessionDetailsDtoList, approvalState.allBusinessAreas, course, values?.[2], values[0]?.loanSchemeMasterId);
                        await updateQuestionStatus('APPL', {}, csummary, {}, feeBreakup, null, values[0]?.applicationQuotationDetailsId, (data) => { }, () => { }, course, true, (qid) => getEligibleComponentList(qid, approvalState.allBusinessAreas.filter(i => i.value === row.applicationBusinessArea), course?.filter(i => i.value == row.courseId), values[0], response.data?.[0]?.concessionDetailsDtoList, studentCurrentYear, false), (...rest) => getFileUploadUrls(...rest, false))
                        successToast(getSuccessMsg(approvalStatus));
                        dispatch((clearSelectedRowsIds()));
                        dispatch(getApprovalRequests({
                            // roleId: approvalState.approverType.value,
                            approvalStatus: `${approvalState.approverType.value}_${stage.toString()?.toUpperCase()}`,
                            businessAreaValue: appliedFilters?.businessArea?.length ? appliedFilters?.businessArea : approvalState.allBusinessAreas.map(i => i.label)
                        }, approvalState.currentPage));
                        closeForm();
                        setApproving(false);
                    })
                } else {
                    successToast(getSuccessMsg(approvalStatus));
                    dispatch((clearSelectedRowsIds()));
                    dispatch(getApprovalRequests({
                        // roleId: approvalState.approverType.value,
                        approvalStatus: `${approvalState.approverType.value}_${stage.toString()?.toUpperCase()}`,
                        businessAreaValue: appliedFilters?.businessArea?.length ? appliedFilters?.businessArea : approvalState.allBusinessAreas.map(i => i.label)
                    }, approvalState.currentPage));
                    closeForm();
                    setApproving(false);
                }
            } else {
                failureToast("Details not saved.");
                setApproving(false);
            }
            setSaving(false)
        } catch (err) {
            setSaving(false)
            setApproving(false);
            failureToast("Details not saved.");
        }
    }

    const showAction = stage === APPROVAL_STAGE.PENDING;
    const nonPendingHeader = stage === APPROVAL_STAGE.APPROVED ?
        'Approved on' :
        stage === APPROVAL_STAGE.REJECTED ?
            'Rejected on' :
            'Sent for recheck on';

    const columns = [
        {
            field: 'psid', headerName: 'PSID', minWidth: 120, flex: 120, sortable: false,
            renderCell: params => {
                // const course = approvalState.allCourses.find(item => item.value === params.row.courseId);
                const courseCategory = approvalState.allCourseCategories.find(item => +item.value === +params.row.courseCategoryId);
                // const title = <span><strong>{`PSID- ${params.value}`}</strong>{` | ${course?.value || ""}, ${course?.label || ""}, ${courseCategory?.label || ""}`}</span>;
                return (
                    <LightTooltip title={<PSIDTooltipTitle courseCategory={courseCategory} courseId={params.row.courseId} psid={params.value} />}>
                        <div style={{ cursor: 'pointer' }} onClick={() => history.replace(`/admin/studentCharges/details/${params.row.applicationId}/view`)}>
                            <DecoratedCell islink={true}>{params.value}</DecoratedCell>
                        </div>
                    </LightTooltip>
                )
            }
        },
        {
            field: 'courseCategoryId', headerName: 'CRCT', minWidth: 65, flex: 65, sortable: false,
            renderCell: params => {
                const courseCategory = approvalState.allCourseCategories.find(item => +item.value === +params.value);
                return <DecoratedCell>{courseCategory?.label || ""}</DecoratedCell>;
            }
        },
        {
            field: 'totalDefinedConcession', headerName: 'Defined  Conc.', minWidth: 134, flex: 134, sortable: false,
            renderCell: params => {
                // const value = params.value ? `₹ ${params.value?.amount} (${params.value.percentage?.toFixed(2)}%)` : '';
                const value = params.value ? `₹ ${params.value?.amount}` : '';
                return <FeesCell value={value} title={<FeeBreakupCard applicationId={params.row.applicationId} concessionType='DEFINED' allCategories={allCategories} />} />
            }

        },
        {
            field: 'totalCustomConcession', headerName: 'Custom Conc.', minWidth: 140, flex: 140, sortable: false,
            renderCell: params => {
                const value = params.value ? `₹ ${params.value?.amount} (${params.value.percentage?.toFixed(2)}%)` : '';
                return <FeesCell value={value} title={<FeeBreakupCard applicationId={params.row.applicationId} concessionType='CUSTOM' allCategories={allCategories} />} />
            }
        },
        {
            field: 'concessionRemarks', headerName: 'Remarks & Logs', minWidth: 140, flex: 91, sortable: false, align: 'center', headerAlign: 'center',
            renderCell: params => <Typography variant='body2' className="approval-screen-view-button" onClick={() => { fetchRemarks(params?.row?.applicationId, setRemarksData, setOpen) }}>View</Typography>

        },
        {
            field: 'concessionDocuments', headerName: 'Doc.', minWidth: 80, flex: 91, sortable: false, align: 'center', headerAlign: 'center',
            renderCell: (params) => <DocumentVerification documentAttached={params.row.isDocumentAttached} applicationQuotationDetailsId={params.row.applicationQuotationDetailsId} applicationId={params.row.applicationId} userPermissions={userPermissions} appliedFilters={appliedFilters} appliedPsid={appliedPsid} />,
        },

        {
            field: 'totalConcessionApplied', headerName: 'Total Conc.', minWidth: 175, flex: 155, sortable: false,
            renderCell: params => {
                const value = params.value ? `₹ ${params.value.amount} (${params.value.percentage?.toFixed(2)}%)` : '';
                return <FeesCell value={value} />
            }
        },
        {
            field: 'updatedOn', headerName: 'Updated On', minWidth: 80, flex: 91, sortable: false, align: 'center', headerAlign: 'center',
            renderCell: (params) => {
                const value = moment(params.value).format('DD-MMM-YY hh:mm A');
                return (
                    <div className='fees-cell-container'>
                        <LightTooltip title={value}>
                            <span className='cell-text'><DecoratedCell>{value}</DecoratedCell></span>
                        </LightTooltip>
                    </div>
                )
            }
        },
        (showAction === true &&
        {
            field: 'actions', headerName: 'Actions', type: 'actions', minWidth: 240, flex: 240, filterable: false, sortable: false,
            renderCell: params => {
                const isDisabled = approvalState.selectedRowIds?.[businessAreaId] && approvalState.selectedRowIds[businessAreaId].includes(params.id) ? true : false;
                const onButtonClick = (e, onClick) => {
                    if (!isDisabled) {
                        onClick(e);
                    }
                }
                return (
                    <Box className='approval-action-buttons'>
                        <APopover
                            buttonComponent={(onClick, styles) => <Box onClick={e => onButtonClick(e, onClick)}><CustomButton
                                className='approval-action-approve-button'
                                content={"Approve"}
                                // forTable={true}
                                permissionType={'A'}
                                permissionSet={userPermissions}
                                onClick={() => { }}
                                disabled={isDisabled}
                            /></Box>}
                            menuComponent={(closeForm) => (
                                <ApprovalDialogContent row={params.row} documentAttached={params.row.isDocumentAttached} applicationId={params.row.applicationId} userPermissions={userPermissions} saving={saving} handleClose={closeForm} onActionClick={(remarks) => { onActionClick(APPROVAL_STAGE.APPROVED, remarks, params.row, closeForm) }} width={324} action={APPROVAL_ACTIONS.APPROVE} applicationQuotationDetailsId={params.row.applicationQuotationDetailsId} appliedFilters={appliedFilters} appliedPsid={appliedPsid} />
                            )}
                        />
                        <APopover
                            buttonComponent={(onClick, styles) => <Box onClick={e => onButtonClick(e, onClick)}><CustomButton
                                className='approval-action-reject-button bg-red-40'
                                // forTable={true}
                                type="cancel"
                                content={'Reject'}
                                permissionType={'A'}
                                permissionSet={userPermissions}
                                onClick={() => { }}
                                disabled={isDisabled}
                            /></Box>}
                            menuComponent={(closeForm) => (
                                <ApprovalDialogContent row={params.row} userPermissions={userPermissions} handleClose={closeForm} onActionClick={(remarks) => { onActionClick(APPROVAL_STAGE.REJECTED, remarks, params.row, closeForm) }} width={324} action={APPROVAL_ACTIONS.REJECT} saving={saving} />
                            )}
                        />
                        <APopover
                            buttonComponent={(onClick, styles) => userPermissions.includes('A') ? <Typography
                                className='approval-action-recheck-button'
                                variant="caption"
                                display="block"
                                color={isDisabled ? '#AFAFAF' : '#00B0F5'}
                                onClick={e => onButtonClick(e, onClick)}
                            >
                                Recheck
                            </Typography> : null}
                            menuComponent={(closeForm) => (
                                <ApprovalDialogContent row={params.row} userPermissions={userPermissions} handleClose={closeForm} onActionClick={(remarks) => { onActionClick(APPROVAL_STAGE.RECHECK, remarks, params.row, closeForm) }} width={324} action={APPROVAL_ACTIONS.RECHECK} saving={saving} />
                            )}
                        />
                    </Box>
                )
            }
        }
            // {
            //     field: 'nonPendingAction', headerName: nonPendingHeader, minWidth: 258, flex: 258, sortable: false,
            //     renderCell: params => getApprovedBy(params?.row, approvalState?.approverType?.value)
            // }
        ),
    ];

    const totalAmount = rowsData.reduce((acc, curr) => acc + curr.totalConcessionAppliedAmount, 0);
    const createdByName = rowsData.length && rowsData[0].createdByName;
    const businessArea = approvalState.allBusinessAreas.find(item => +item.value === +businessAreaId);
    const selectionModel = approvalState.selectedRowIds?.[businessAreaId] || [];


    return (
        <Card className="concession-approval-card">
            <Box className='concession-approval-card-header'>
                <Typography variant="body1" className='concession-approval-cashier-detail'>
                    {`Requested By ${businessArea?.label || ""} | ${businessArea?.desc || ''}`}
                </Typography>
                <Typography variant="body1" className='concession-approval-cashier-detail'>
                    Total amount: ₹ {totalAmount?.toFixed(2)}
                </Typography>
            </Box>
            <Box sx={{ width: '100%', height: 'auto', overflow: "auto" }}>
                <DataGrid
                    rows={rowsData}
                    columns={columns}
                    getRowId={(row) => row.applicationQuotationDetailsId}
                    // checkboxSelection={stage === APPROVAL_STAGE.PENDING}
                    checkboxSelection={false}
                    // density={"compact"}
                    rowHeight={52}
                    // getRowHeight={(params) => 52}
                    autoHeight
                    headerHeight={52}
                    disableColumnMenu
                    // components={{
                    // ColumnSortedDescendingIcon: SortDesc,
                    // ColumnSortedAscendingIcon: Sort,
                    // // LoadingOverlay: () => <ALoader />
                    // NoRowsOverlay : () => <NoRowsDeallocation batchName={batchName} />
                    // }}
                    // onRowClick={() => console.log('row')}
                    // isRowSelectable={isRowSelectable}
                    disableSelectionOnClick
                    selectionModel={selectionModel}
                    onSelectionModelChange={(newSelectionModel) => {
                        // if (newSelectionModel.length > availableForSelection) {
                        //   setSelectionModel(newSelectionModel.slice(0, availableForSelection))
                        //   return;
                        // }
                        // setSelectionModel(newSelectionModel)
                        dispatch(setSelectedRowIds(businessAreaId, newSelectionModel))
                        // setSelectedRowIds(prev => ({ ...prev, [businessAreaId]: newSelectionModel }));
                    }}
                />
            </Box>
            <RemarksDialogue remarksData={remarksData} open={open} setOpen={setOpen} setRemarksData={setRemarksData} />

        </Card>
    )
}

export default ApprovalBranch;