import moment from "moment";
import { endpoint } from "views/pages/manage/common/constant";
import { fetchAllPostPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { updateRequest } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";

export const getStudentApplications = (request, pageNo) => {
    return fetchAllPostPromisedData(
      `${endpoint.allocateStudents.searchStudentApplications}?offset=${pageNo}`,
      updateRequest(request),
      "post"
    );
  }; 
  
 export function isNumber(str) {
    for (var i = 0; i < str.length; i++) {
        var charCode = str.charCodeAt(i);
        if (charCode < 48 || charCode > 57) {
            return false;
        }
    }
    return true;
}

export function isAlphabet(str) {
    for (var i = 0; i < str.length; i++) {
        var charCode = str.charCodeAt(i);
        if (!(charCode >= 65 && charCode <= 90) && !(charCode >= 97 && charCode <= 122) && !(charCode===32)) {
            return false;
        }
    }
    return true;
}

export const academicCardInfoLabels = () => ([
    { title: 'Term', objKey: 'termDispVal' },
    { title: 'Academic Group', objKey: 'academicGroupDispValue' },
    { title: 'Registration Date', objKey: 'registrationDate', valueGetter: (o) => o?.registrationDate ? new moment(o?.registrationDate).format('DD-MM-YYYY') : '-' },
    { title: 'Course', objKey: 'courseId' },
    { title: 'Class', objKey: 'classes' },
    { title: 'Mobile number', objKey: 'maskedPrimaryMobileNumber' },
    { title: 'Branch', objKey: 'businessAreaDesc' }
   
  ])

  export const formateResponse = (item) => ({
    id: item?.id, 
    businessAreaDispValue: item?.businessAreaDispValue, 
    businessAreaDesc: item?.businessAreaDesc,
    value: `${item?.businessAreaDispValue} | ${item?.businessAreaDesc}`,
  });

  

