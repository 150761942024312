import React, { useState, useEffect, useContext } from "react";
import { RolePermissions } from "appContext";
import { PermissionContext } from "appContext";
import CustomButton from "components/CustomButton";
import { useHistory } from "react-router-dom";
import { Container, Card, CardHeader, Col, Row, Table, CardFooter } from "reactstrap"
import { ButtonText } from "variables/Buttons";
import { PermisionDenied } from "../../common/commonComponents";
import { constants, endpoint, pages } from "../../common/constant";
import CommonInput from "../../common/formFeilds/input/commonInput";
import CustomLoader from "../../common/commonComponents/Loader/loader";
import { failureToast, successToast } from "../../common/utils/methods/toasterFunctions/function";
import { MasterHeaderAuto, Rows } from "../../common/commonComponents/masterHeader/masterHeader";
import { GetPagination } from "../../common/commonComponents/pagination/pagination";
import { getPaginatedData } from "../../common/utils/methods/commonMethods/paginationMethod";
import { fetchAllPostPromisedData, fetchDeletePromisedData } from "../../common/utils/methods/commonMethods/utilityMethod";
import { editTableRow } from "../../common/utils/methods/commonMethods/tableMethods";
import {ImSpinner2} from 'react-icons/im'
import ServerPagination from './../../common/commonComponents/pagination/ServerPagination';
import ALoader from './../../common/a-loader/index';

const ClassSection = () => {
  let history = useHistory();
  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages['classSection']['id']);

  const headerList = [
    {name: 'Description'},
    {name: 'Display Value'},
    {name: 'Section Type'},
    {name: 'Status'},
    {name: 'Action'},
  ]
  const listToRender = ['classSectionDesc', 'classSectionDispValue', 'classSectionType'];

  const [serverData, setServerData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [apiLoader, setApiLoader] = useState(false);
  const [activeUpdateCall, setActiveUpdateCall] = useState(false);
  let [pagination, setpagination] = useState(constants.pagination);
  const pageSize = 10;

  const [saveClicked, setSaveClicked] = useState(false);

  const emptySearchParams = {
    classSectionDispValue: '',
    classSectionType: null
  }
  const [searchParams, setSearchParams] = useState(emptySearchParams);
  const [totalPages, setTotalPages] = useState(0) ;
  const [currentPage, setCurrentPage] = useState(1);
  const [isDataLoading, setIsDataLoading] = useState(false);



  const secTypeData = [
    {label: 'PRIMARY', value: 'PRIMARY'}, 
    {label: 'SECONDARY', value: 'SECONDARY'}
  ]

  const validateSearchParams = () => {
    if(!searchParams.classSectionDispValue && !searchParams.classSectionDispValue.trim() && !searchParams.classSectionType?.value){
      failureToast('Select atleast one value for search');
      return false;
    }
    return true;
  }

  const nextPageHandler = (offSet)=>{
    nextPageApiHandler(offSet)
    setCurrentPage(offSet)
  }

  let searchData = {};
  const submitHandler = async (offSet= 0 ) => {
    if(validateSearchParams()){
      searchData = {
        classSectionType: searchParams?.classSectionType?.value,
        classSectionDispValue: searchParams?.classSectionDispValue
      }
      setApiLoader(true);
      debugger
      const searchedData = await fetchAllPostPromisedData(`${endpoint.classSection.search}?offSet=${offSet}`, searchData);
      if(searchedData?.code === 200){
        setCurrentPage(offSet + 1);
        setTableData(searchedData.data.classSectionMasterDTOList);
        setTotalPages(searchedData.data.totalPages)
        // console.log(searchedData.data.totalPages)
        setApiLoader(false);

        // let pg = preservePreviousPagination ? pagination : constants.pagination;
        // // getPaginatedData(searchedData.data, pageSize, constants.pagination, setpagination, setApiLoader, setTableData);
        // getPaginatedData(searchedData.data, pageSize, pg, setpagination, setApiLoader, setTableData);
      }
      else{
        // reset();
        setCurrentPage(1);
        setServerData([]);
        setTableData([]);
        setApiLoader(false);
      }
    }
  }

  const nextPageApiHandler = async (offSet= 0 ) => {
    if(validateSearchParams()){
      searchData = {
        classSectionType: searchParams?.classSectionType?.value,
        classSectionDispValue: searchParams?.classSectionDispValue
      }
      setIsDataLoading(true);
      const searchedData = await fetchAllPostPromisedData(`${endpoint.classSection.search}?offSet=${offSet-1}`, searchData);
      if(searchedData?.code === 200){
        setTableData(searchedData.data.classSectionMasterDTOList);
        setTotalPages(searchedData.data.totalPages)
        setIsDataLoading(false);
      }
      else{
        // reset();
        setServerData([]);
        setTableData([]);
        setIsDataLoading(false);
      }
    }
  }
  const reset = () => {
    setCurrentPage(1);
    setSearchParams(emptySearchParams);
    setServerData([]);
    setTableData([]);
  }

  // const deleteRow = async (id) => {
  //   if (window.confirm("Are you sure you want to delete this Class Section?")) {
  //     const deleteRecord = await fetchDeletePromisedData(`${endpoint.classSection.delete}/${id}`)
  //     if(deleteRecord?.code===200){
  //       successToast(deleteRecord['message']);
  //       // submitHandler();
  //       reset();
  //     }
  //   }
  // }
  const editRow = (index) => {
    if (activeUpdateCall) {
      failureToast("Please Delete or Save current changes");
    } else {
      editTableRow(index,tableData,setTableData,setActiveUpdateCall);
    }
  };
  const updateKey = (index,value)=>{
    const newState = tableData.map((el, idx) => {
      if(idx===index) return {...el, 'status': value}
      return el;
    })
    setTableData(newState);
  }

  const updateStatus = async (el, index) => {
    setSaveClicked(true);
    const updateData = await fetchAllPostPromisedData(`${endpoint.classSection.update}/${el.id}`, el, 'put');
    if (updateData?.code === 200) {
      successToast(updateData['message']);
      // let newData = serverData;
      // newData[(pagination.currentPage - 1) * pageSize + index] = updateData?.data;
      // setServerData(newData);
      // const newState = tableData.map((el, idx) => {
      //   if (idx === index) return { ...updateData?.data };
      //   return el;
      // })
      submitHandler(0);
      setSaveClicked(false);
      // setTableData(newState);
      setActiveUpdateCall(false);
    }
    else {
      setSaveClicked(false);
      setApiLoader(false);
      setActiveUpdateCall(false);
    }
  }

  return (
    userPermissions ? (
      <Container fluid className='mt-3'>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className='d-flex justify-content-between'>
                <h3 className="mb-0">Master - Class Section</h3>
                <CustomButton
                  className={'floatRight mx-1'}
                  content={ButtonText.CLASSSECTION.NEW}
                  permissionType={'C'}
                  icon={true}
                  permissionSet={userPermissions}
                  onClick={() => history.push(`/admin/edp/classSection/new`)}
                />
              </CardHeader>
              <Row className="p-4">
                <Col className="mb-3" md="6">
                  <CommonInput
                    type={'text'}
                    defaultValue={searchParams.classSectionDispValue}
                    label={'Display Value'}
                    placeHolder={'Display Value'}
                    maxLength={4}
                    regEx={/^([A-Z0-9])*$/}
                    lengthNotifier={true}
                    onChange={value => {
                      setSearchParams({
                        ...searchParams,
                        classSectionDispValue: value,
                      })
                    }}
                  />
                </Col>
                <Col className="mb-3" md="6">
                  <CommonInput label={'Section Type'}
                    type={'select'}
                    placeHolder={'Select Section Type'}
                    defaultValue={searchParams.classSectionType}
                    data={secTypeData}
                    onChange={(e) => setSearchParams({ ...searchParams, classSectionType: e })}
                  />
                </Col>
              </Row>
              <Row className={'justify-content-end pr-5 pb-4'}>
                <CustomButton
                  className={'floatRight mx-1'}
                  content={'Search'}
                  permissionType={'S'}
                  icon={true}
                  permissionSet={userPermissions}
                  onClick={()=>submitHandler()}
                />
                <CustomButton
                  type="reset"
                  className={'floatRight mx-1'}
                  content={'Clear All'}
                  permissionType={'R'}
                  permissionSet={userPermissions}
                  icon={true}
                  onClick={reset}
                />
              </Row>
            </Card>
            {isDataLoading && <ALoader></ALoader> }
            {apiLoader ? (
              <Card>
                <CustomLoader apiLoader={apiLoader} />
              </Card>
            ) : (
              tableData?.length > 0 ? (
                <Card className="mt-4">
                  <CardHeader className="d-flex justify-content-between align-items-center">
                    <h3>Search Results</h3>
                  </CardHeader>

                  <div className="table-responsive">
                    {tableData ? (
                      <Table className="align-items-center table-flush " >
                        <MasterHeaderAuto headerList={headerList} permissionSet={userPermissions} />
                        <tbody className="list">
                          {tableData && tableData.length > 0 && (
                            tableData.map((el, index) => {
                              return (
                                !el?.editMode ? (
                                  <Rows data={el} listToBeRendered={[...listToRender, 'status']} key={el.id} >
                                    <td className="text-center white-breakSpace">
                                      <CustomButton
                                        permissionType={'U'}
                                        icon={true}
                                        forTable={true}
                                        permissionSet={userPermissions}
                                        onClick={() =>
                                          editRow(index)
                                        }
                                      />
                                    </td>
                                  </Rows>
                                ) : (
                                  <Rows data={el} listToBeRendered={listToRender} key={el.id} >
                                    <td className="text-center p-2">
                                      <label className="custom-toggle mx-auto ml-2">
                                        <input
                                          checked={el.status === "ACTIVE" ? true : false}
                                          type="checkbox" id={el.id}
                                          onChange={(e) => {
                                            let n = "ACTIVE";
                                            if (!e.target.checked) { n = "INACTIVE" }
                                            updateKey(index, n)
                                          }}
                                        />
                                        <span style={{ width: "72px" }} className="custom-toggle-slider rounded-circle activeToggle" data-label-off="Inactive" data-label-on="Active" />
                                      </label>
                                    </td>
                                    <td className="text-center">
                                      <CustomButton
                                        className={'mx-1'}
                                        // content={saveClicked ? (<Loader loaderHeight={'20'} loaderWidth={'20'} color={'#ffffff'} />) : (ButtonText.CLASSSECTION.UPDATE)}
                                        content={saveClicked ? (<ImSpinner2/>) : (ButtonText.CLASSSECTION.UPDATE)}
                                        loading={true}
                                        forTable={true}
                                        permissionType={'C,U'}
                                        permissionSet={userPermissions}
                                        onClick={() => {
                                          updateStatus(el,index);
                                        }}
                                      />
                                      <CustomButton
                                        className={'mx-1'}
                                        permissionType={'cancel'}
                                        forTable={true}
                                        icon={true}
                                        permissionSet={userPermissions}
                                        onClick={() => {
                                          submitHandler(currentPage - 1);
                                          setActiveUpdateCall(false);
                                        }}
                                      />
                                    </td>
                                  </Rows>
                                )
                              )
                            })
                          )}
                        </tbody>
                      </Table>
                    ) :
                      null
                    }
                    <div>
                      <CardFooter>
                     <ServerPagination currentPage = {currentPage} totalPages ={totalPages} nextHandler = {nextPageHandler}/>   
                     </CardFooter>                 </div>
                  </div>
                </Card>
              ) : null
            )}
          </div>
        </Row>
      </Container>
    ) : (
      <PermisionDenied />
    )
  )
};

export default ClassSection;