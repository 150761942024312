import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { endpoint } from "../../common/constant";
import { failureToast, getAPI, masterServiceBaseUrl, enrollmentServiceBaseUrl } from "../../../../../services/http";
import SearchComponent from "./searchComponent";
import TableComponent from "./tableComponent";
import { findObjectById } from "../../common/utils/methods/commonMethods/utilityMethod";
import { GetPagination } from "../../common/commonComponents/pagination/pagination";
import { debounce } from "lodash";

const ExternalTestResult = () => {
  const [state, setState] = useState([]);
  const [isSaveVisible, setIsSaveVisible] = useState(false);
  const [studentDetails, setStudentDetails] = useState([]);
  const [examBoard, setExamBoard] = useState([]);
  const [studentResult, setStudentResult] = useState([]);
  const [studentResultTable, setStudentResultTable] = useState([]);
  const [examYear, setExamYear] = useState([]);
  const [category, setCategory] = useState([]);
  const [allRecords, setAllRecords] = useState({});
  const [searchValues, setSearchValues] = useState({});
  const [searchResult, setSearchResult] = useState([]);
  const [dropdownExamBoard, setDropdownExamBoard] = useState([]);
  const [dropdownYear, setDropdownYear] = useState([]);
  const [searchButtonClicked, setSearchButtonClicked] = useState(false);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });

  const pageSize = 10;

  const fetchDetails = async (url, type) => {
    getAPI(
      url,
      (data) => {
        dataHandler(data, type);
        setSearchButtonClicked(false);
      },
      (data) => {
        setSearchButtonClicked(false);
        if (type === "SEARCH_RESULT") {
          setSearchResult([]);
          setState([]);
        }
        failureToast(data["message"]);
      }
    );
  };

  const recordsHandler = (key, data) => {
    try {
      setAllRecords((pre) => ({
        ...pre,
        [key]: data,
      }));
    } catch (e) {
      console.log("Error", e);
    }
  };

  const dataHandler = (data, type) => {
    try {
      switch (type) {
        case "STUDENT_DETAILS":
          recordsHandler("studentDetails", data?.data);
          setStudentDetails(data?.data);
          break;
        case "EXAM_BOARD":
          recordsHandler("examBoard", data?.data);
          const tempBoard = [];
          data?.data.length > 0 &&
            data.data.forEach((item) => {
              if (item?.status?.toUpperCase() === "ACTIVE")
                tempBoard.push({
                  value: item.id,
                  label: item.examBoardTypeDispValue,
                });
            });
          setExamBoard(tempBoard);
          break;
        case "STUDENT_RESULT":
          recordsHandler("studentResult", data?.data);
          const tempResult = [];
          data?.data.length > 0 &&
            data.data.forEach((item) => {
              tempResult.push({
                value: item.studentId,
                label: item.studentId,
              });
            });
          setStudentResult(tempResult);
          break;
        case "STUDENT_RESULT_TABLE":
          recordsHandler("studentResultTable", data?.data);
          const tempTableResult = [];
          data?.data.length > 0 &&
            data.data.forEach((item) => {
              tempTableResult.push({
                value: item.studentId,
                label: item.studentId,
              });
            });
          setStudentResultTable(tempTableResult);
          break;
        case "EXAM_YEAR":
          recordsHandler("examYear", data?.data);
          const tempYear = [];
          data?.data.length > 0 &&
            data.data.forEach((item) => {
              tempYear.push({
                value: item.id,
                label: item.academicYearDisplayValue,
              });
            });
          setExamYear(tempYear);
          break;
        case "CATEGORY":
          recordsHandler("category", data?.data);
          const tempCategory = [];
          data?.data.length > 0 &&
            data.data.forEach((item) => {
              tempCategory.push({
                value: item.id,
                label: item.categoryDispValue,
              });
            });
          setCategory(tempCategory);
          break;
        case "SEARCH_RESULT":
          setState(data?.data);
          initialPagination(data?.data);
          // console.log("state", state);
          break;
        case "DROPDOWN_EXAM_BOARD":
          const examBoardTemp = [];
          data &&
            data.data.length > 0 &&
            data.data.forEach((item) => {
              const examTemp = findObjectById(allRecords?.examBoard, "id", item);
              examBoardTemp.push({
                label: examTemp?.examBoardTypeDispValue,
                value: item,
              });
            });
          setDropdownExamBoard(examBoardTemp);
          break;
        case "DROPDOWN_EXAM_DATE":
          const tempExamYear = [];
          data &&
            data.data &&
            data.data.forEach((item) => {
              const yearTemp = findObjectById(allRecords?.examYear, "id", item);
              tempExamYear.push({
                value: item,
                label: yearTemp?.academicYearDisplayValue,
              });
            });
          setDropdownYear(tempExamYear);
          break;
        default:
          break;
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const initialPagination = (paramsdata) => {
    // console.log("Params data", paramsdata);
    let n = pagination;
    if (paramsdata.length > pageSize) {
      n["nextPage"] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n["totalPage"] = Math.floor(paramsdata.length / pageSize);
    if (paramsdata.length % pageSize != 0) {
      n["totalPage"] += 1;
    }
    setpagination(n);
    setSearchResult(paramsdata.slice(pagination.currentPage * pageSize - pageSize, pagination.currentPage * pageSize) || []);
    // console.log("searchResult", searchResult);
  };

  const previousPage = () => {
    if (pagination.currentPage != 1) {
      setSearchResult(state.slice((pagination.currentPage - 1) * pageSize - pageSize, (pagination.currentPage - 1) * pageSize));
      let n = pagination;
      n["currentPage"] = n["currentPage"] - 1;
      if (n["currentPage"] == 1) {
        n["previousPage"] = null;
      } else {
        n["previousPage"] = n.currentPage;
      }
      setpagination(n);
    }
  };

  const searchApiHandler = () => {
    try {
      fetchDetails(
        endpoint.externalTestResult.search +
          `?studentId=${searchValues?.studentId ? searchValues?.studentId?.value : null}&examBoard=${searchValues?.examBoard}&examYear=${
            searchValues?.examYear ? searchValues.examYear : null
          }`,
        "SEARCH_RESULT"
      );
    } catch (e) {
      console.log("Error", e);
    }
  };

  useEffect(() => {
    fetchDetails(`${enrollmentServiceBaseUrl}/studentDetails/getAllStudent`, "STUDENT_DETAILS");
    fetchDetails(`${masterServiceBaseUrl}/examBoardType/getAllExamBoardType`, "EXAM_BOARD");
    fetchDetails(`${masterServiceBaseUrl}/academicYear/all`, "EXAM_YEAR");
    fetchDetails(`${masterServiceBaseUrl}/category/getAllCategory`, "CATEGORY");
  }, []);

  useEffect(() => {
    allRecords?.examYear?.length > 0 && fetchDetails(endpoint.externalTestResult.getExamBoard, "DROPDOWN_EXAM_BOARD");
    allRecords?.examBoard?.length > 0 && fetchDetails(endpoint.externalTestResult.getExamYear, "DROPDOWN_EXAM_DATE");
  }, [allRecords]);

  return (
    <>
      <Container className={"mt-5"} fluid>
        <SearchComponent
          searchValues={searchValues}
          setSearchValues={setSearchValues}
          fetchDetails={fetchDetails}
          dropdownExamBoard={dropdownExamBoard}
          dropdownYear={dropdownYear}
          studentResult={studentResult}
          setStudentResult={setStudentResult}
          searchButtonClicked={searchButtonClicked}
          setSearchButtonClicked={setSearchButtonClicked}
          setSearchResult={setSearchResult}
          setpagination={setpagination}
          setState={setState}
          searchApiHandler={searchApiHandler}
          setIsSaveVisible={setIsSaveVisible}
        />
        <TableComponent
          fetchDetails={fetchDetails}
          searchValues={searchValues}
          allRecords={allRecords}
          examBoard={examBoard}
          examYear={examYear}
          category={category}
          studentResultTable={studentResultTable}
          setStudentResultTable={setStudentResultTable}
          setStudentDetails={setStudentDetails}
          searchResult={searchResult}
          setSearchResult={setSearchResult}
          isSaveVisible={isSaveVisible}
          setIsSaveVisible={setIsSaveVisible}
          searchApiHandler={searchApiHandler}
          state={state}
        />
        {pagination.totalPage > 1 ? (
          <GetPagination
            isSaveVisible={isSaveVisible}
            fetchClasses={fetchDetails}
            setclassArray={setSearchResult}
            pagination={pagination}
            setpagination={setpagination}
            pageSize={pageSize}
            state={state}
          />
        ) : null}
      </Container>
    </>
  );
};

export default ExternalTestResult;
