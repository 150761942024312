import React, { useRef, useState, useEffect, useContext } from "react";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import styles from "./styles.module.scss";
import ListComponent from "./ListComponent";
import { getDropdowns, restructureGetAllRes, initialFilterForm, getSearchReqBody } from "./constant";
import { endpoint } from "views/pages/manage/common/constant";
import { fetchAllPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import ALoader from "views/pages/manage/common/a-loader";
import { ReactComponent as BackIcon } from "assets/img/svg/BackButton.svg";
import { useHistory } from "react-router";
import { fetchAllPostPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { fetchDeletePromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { successToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import { getCreateReqBody } from "./constant";
import { MdKeyboardArrowDown } from "react-icons/md";
import { RolePermissions } from "appContext";
import { PermissionContext } from "appContext";
import { pages } from "views/pages/manage/common/constant";
import { PermisionDenied } from "views/pages/manage/common/commonComponents";
import { checkPermission } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import SearchFilter from "./SearchFilter";
import { getFilterCount } from "views/pages/manage/edpOps/stockManagement/mastersAndMapping/onBoardingMaterialIdSetup/constant";
const ScholarshipConfig = () => {
  const listRef = useRef();
  const [page, setPage] = useState(0);
  const [dropdownLoader, setDropdownLoader] = useState(false);
  const [dropdown, setDropDownData] = useState();
  const [listLoader, setListLoader] = useState();
  const [apiLoader, setApiLoader] = useState();
  const [rows, setRows] = useState();
  const [rowCount, setRowCount] = useState(0);
  const [allCategories, setAllCategories] = useState();
  const [filterPayload, setFilterPayload] = useState(initialFilterForm);
  const [filterCount, setFilterCount] = useState(0);
  const history = useHistory();
  const userPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["scholarshipConfig"]["id"]
  );

  const onPageChange = async (newPage) => {
    setPage(newPage);
    const reqBody = filterPayload;
    await fetchList(newPage,dropdown,allCategories, reqBody);
  }
  const fetchList = async (
    page=0,
    dropdownData = dropdown,
    categoryData = allCategories,
    reqBody = {...initialFilterForm }
  ) => {
    setListLoader(true);
    const res = await fetchAllPostPromisedData(
      `${endpoint.scholarshipConfig.search}?offset=${page}`,
      getSearchReqBody(reqBody),
      'post'
    );
    if (res?.code === 200) {
      const dataRows = restructureGetAllRes(
        res?.data,
        dropdownData,
        categoryData
      );
      setRowCount(res?.data?.totalRecords);
      setRows(dataRows);
      listRef.current.stopEditing();
    } else {
      setRowCount(0);
      setRows([]);
    }
    setListLoader(false);
  };
  const createHandler = async (rowData) => {
    setApiLoader(true);
    const reqBody = getCreateReqBody(rowData);
    const res = await fetchAllPostPromisedData(
      endpoint.scholarshipConfig.create,
      reqBody,
      "post"
    );
    if (res?.code === 200) {
      successToast("Saved Successfully");
      fetchList(0);
      setPage(0);
      setFilterCount(0);
      setFilterPayload(initialFilterForm)
    } else {
      failureToast(res?.message);
    }
    setApiLoader(false);
  };
  const updateHandler = async (rowData) => {
    setApiLoader(true);

    const reqBody = getCreateReqBody(rowData);
    const res = await fetchAllPostPromisedData(
      `${endpoint.scholarshipConfig.update}/${rowData?.id}`,
      { id: rowData?.id, ...reqBody },
      "put"
    );
    if (res?.code === 200) {
      successToast(res?.message);
      fetchList(page);
      setFilterCount(0);
      setFilterPayload(initialFilterForm)
    } else {
      failureToast(res?.message);
    }
    setApiLoader(false);
  };
  const toggleStatusHandler = async (rowData) => {
    setApiLoader(true);
    const reqBody = {
      ...getCreateReqBody(rowData),
      status: rowData?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
    };
    const res = await fetchAllPostPromisedData(
      `${endpoint.scholarshipConfig.update}/${rowData?.id}`,
      reqBody,
      "put"
    );
    if (res?.code === 200) {
      successToast(res?.message);
      fetchList(page);
      setFilterCount(0);
      setFilterPayload(initialFilterForm)
    } else {
      failureToast(res?.message);
    }
    setApiLoader(false);
  };
  const deleteHandler = async (rowData) => {
    setApiLoader(true);
    const res = await fetchDeletePromisedData(
      `${endpoint.scholarshipConfig.delete}/${rowData?.id}`
    );
    if (res?.code === 200) {
      successToast(res?.message);
      fetchList(page);
      setFilterCount(0);
      setFilterPayload(initialFilterForm)
    } else {
      failureToast(res?.message);
    }
    setApiLoader(false);
  };
  const getDropdown = async () => {
    setDropdownLoader(true);
    const data = await Promise.all([getDropdowns(), getClassificationData()]);
    setDropDownData(data[0]);
    const categoryData = data[1];
    setDropdownLoader(false);
    fetchList(0,data[0], categoryData);
  };
  useEffect(() => {
    getDropdown();
  }, []);
  const getClassificationData = async () => {
    try {
      const treeData = await fetchAllPromisedData(
        `${endpoint.treeView}`,
        false
      );
      if (treeData && Array.isArray(treeData)) {
        setAllCategories(treeData);
        return treeData;
      } else {
        failureToast("Couldn't fetch categories!");
      }
    } catch (err) {
      failureToast("Couldn't fetch categories!");
    }
  };
  const applyFilter = async (filterForm) => {
    listRef.current.stopEditing();
    setFilterPayload(filterForm);
    setFilterCount(getFilterCount(filterForm));
    const reqBody = {
      ...filterForm
    }
    setPage(0);
    await fetchList(0,dropdown,allCategories, reqBody);
  }
  return userPermissions ? (
    <div className={`remove-container-top-space`}>
      {(dropdownLoader || listLoader || apiLoader) && (
        <ALoader position="fixed" />
      )}
      <div className={`${styles['header-bar']}`}>
        <BackIcon onClick={() => history.goBack()} />
        <div className="ml-3 heading-4 color-dark">Scholarship config.</div>
      </div>
      <div className={`${styles['page-container']}`}>
        <div className="d-flex justify-content-between align-items-start">
          <h3
            style={{ color: '#00AEEF', fontWeight: 'bolder' }}
            className={`mr-auto`}
          >
            Category mapping <MdKeyboardArrowDown size={26} />
          </h3>
          <div className="d-flex justify-content-end align-items-center">
          <SearchFilter
            listRef={listRef}
            filterCount={filterCount}
            filterPayload={filterPayload}
            dropdown={dropdown}
            applyFilter={applyFilter}
          />
          {checkPermission(userPermissions, 'C') && (
            <AButton
              updatedStyle={{ fontWeight: 600 }}
              onClick={() => listRef?.current?.addNewRow()}
            >
              Add new row
            </AButton>
          )}
        </div>
        </div>
        <ListComponent
          page={page}
          onPageChange={onPageChange}
          rowCount={rowCount}
          ref={listRef}
          rows={rows}
          loader={dropdownLoader || listLoader}
          dropdown={dropdown}
          createHandler={createHandler}
          updateHandler={updateHandler}
          toggleStatusHandler={toggleStatusHandler}
          deleteHandler={deleteHandler}
          allCategories={allCategories}
        />
      </div>
    </div>
  ) : (
    <PermisionDenied />
  );
};
export default ScholarshipConfig;
