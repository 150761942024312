import React, { useState } from 'react'
import { ImCross } from 'react-icons/im';
import { ReactComponent as Search } from "../../../../../assets/img/svg/SearchIcon.svg";
// import { MdOutlineTune } from 'react-icons/md';

const crossIconStyle = {
  fontSize: "11px",
  position: "absolute",
  top: "0.6rem",
  right: "0.4rem",
  cursor: "pointer",
}
// const tuneIconStyle = {
//   fontSize: "13px",
//   position: "absolute",
//   top: "0.525rem",
//   right: "0.3rem",
//   cursor: "pointer",
// }


const SearchInput = ({ searchInputValueHandler, onCancel, placeholder, /*containerRef, tuneOnClick,*/ ...props }) => {
  const [inputValue, setInputValue] = useState('')
  const handler = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      searchInputValueHandler(inputValue)
    }
  }
  const clickHandler = () => {
    if (inputValue !== '')
      searchInputValueHandler(inputValue)

  }
  const onChangeHandler = (value) => {
    setInputValue(value)
    //  if(value.trim().length>=4){
    //   setError(false)
    //  }else{
    //   setError(true)
    //  }
  }

  return (
    <div style={{ position: "relative" }} /*ref={containerRef}*/>
      <span className='search-input-wrapper'> <Search onClick={clickHandler} /></span>
      <input
        className='search-input'
        id="inputValue"
        value={inputValue}
        placeholder={placeholder}
        onChange={(e) => {
          onChangeHandler(e.target.value);
        }}
        onKeyDown={handler}
        style={{ minWidth: "15rem", border: "1px solid #00aeef", paddingRight: '1.5rem' }}
        {...props}
      />
      {inputValue.length > 0 &&
        <ImCross onClick={() => {
          setInputValue('')
          onCancel();
        }} style={crossIconStyle} />
      }
      {/* <MdOutlineTune style={tuneIconStyle} onClick={tuneOnClick} /> */}
    </div>


  )
}

export default SearchInput