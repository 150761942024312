import React, { useContext, useEffect, useState } from 'react'
import { PermissionContext, RolePermissions } from 'appContext';
import { constants, endpoint, pages } from '../../common/constant';
import { PermisionDenied } from '../../common/commonComponents';
import { Card, CardBody, CardHeader, Col, Container, Row, Table } from 'reactstrap';
import CommonInput from '../../common/formFeilds/input/commonInput';
import CustomButton from 'components/CustomButton';
import { fetchAllPromisedData, fetchDetails } from '../../common/utils/methods/commonMethods/utilityMethod';
import CustomLoader from '../../common/commonComponents/Loader/loader';
import { GetPagination } from '../../common/commonComponents/pagination/pagination';
import { MasterHeaderAuto, Rows } from '../../common/commonComponents/masterHeader/masterHeader';
import { failureToast } from '../../common/utils/methods/toasterFunctions/function';
import { getPaginatedData } from '../../common/utils/methods/commonMethods/paginationMethod';

function PrimaryBatchSequence() {
  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages['primaryBatchSequence']['id']);

  const headerList = [
    {name: 'Batch Sequence'},
    {name: 'Minimum Value'},
    {name: 'Status'}
  ]
  const listToRender = ['sequenceTemplate', 'startSequence', 'status'];

  const [serverData, setServerData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [apiLoader, setApiLoader] = useState(false);
  let [pagination, setpagination] = useState(constants.pagination);
  const pageSize = 10;

  const [term, setTerm] = useState('');
  const [termData, setTermData] = useState([]);

  useEffect(() => {
    fetchDetails(endpoint.term.getAllActive, setTermData, 'term', 'select');
  }, []);

  const submitHandler = async () => {
    if(!term && !term.trim()){
      failureToast('Please select Term');
      return;
    }
    
    setApiLoader(true);
    const searchedData = await fetchAllPromisedData(`${endpoint.primaryBatchSequence.searchByTerm}/${term?.label}`, true);
    
    if(searchedData?.code===200){
      setServerData(searchedData?.data);
      getPaginatedData(searchedData?.data, pageSize, constants.pagination, setpagination, setApiLoader, setTableData);
    }
    else{
      setServerData([]);
      setTableData([]);
      setApiLoader(false);
    }
  }

  const reset = () => {
    setTerm('');
    setServerData([]);
    setTableData([]);
    setApiLoader(false);
  }

  return (
    userPermissions ? (
      <Container fluid className='mt-3'>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className='d-flex justify-content-between'>
                <h3 className="mb-0">Search Primary Batch Sequence Master</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="mb-3" md="3">
                    <CommonInput
                      label={'Term'}
                      mandatory={true}
                      type={'select'}
                      placeHolder={'Select Term'}
                      defaultValue={term}
                      data={termData}
                      onChange={(e) => setTerm(e)}
                    />
                  </Col>
                </Row>
                <Row className={'justify-content-end pr-5 pb-4'}>
                  <CustomButton
                    className={'floatRight mx-1'}
                    content={'Search'}
                    permissionType={'S'}
                    icon={true}
                    permissionSet={userPermissions}
                    onClick={submitHandler}
                  />
                  <CustomButton
                    type="reset"
                    className={'floatRight mx-1'}
                    content={'Reset'}
                    permissionType={'R'}
                    permissionSet={userPermissions}
                    icon={true}
                    onClick={reset}
                  />
                </Row>
              </CardBody>
            </Card>
            {apiLoader ? (
              <Card>
                <CustomLoader apiLoader={apiLoader} />
              </Card>
            ) : tableData?.length > 0 ? (
              <Card className="mt-4">
                <CardHeader className="d-flex justify-content-between align-items-center">
                  <h3 className="mb-0">Search Results</h3>
                </CardHeader>

                <div className="table-responsive">
                  {tableData ? (
                    <Table className="align-items-center table-flush" >
                      <MasterHeaderAuto headerList={headerList} permissionSet={userPermissions} />
                      <tbody className="list">
                        {tableData && tableData.length > 0 && (
                          tableData.map(el => {
                            return (
                              <Rows data={el} listToBeRendered={listToRender} key={el.id} />
                            )
                          })
                        )}
                      </tbody>
                    </Table>
                  ) : (
                    null
                  )}
                  <div>
                    {pagination.totalPage > 1 ? (
                      <GetPagination
                        setclassArray={setTableData}
                        pagination={pagination}
                        setpagination={setpagination}
                        pageSize={pageSize}
                        state={serverData}
                      />
                    ) :
                      null
                    }
                  </div>
                </div>
              </Card>
            ) : (
              null
            )}
          </div>
        </Row>
      </Container>
    ) : (
      <PermisionDenied />
    )
  )
}

export default PrimaryBatchSequence;