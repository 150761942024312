import React from 'react';
import Style from "./permission.module.scss";

const PermisionDenied = ({ msg }) => {
  return (
    <>
      <div className={Style.permision_denied}>
        <i className={`${Style.permission_lock_icon} fas fa-lock`}></i>
        <label className={Style.No_Data_Found_Label}>
          {msg || "You don't have permission to access this page."}
        </label>
      </div>
    </>
  );
};

export default PermisionDenied;