import React, { useState } from "react";
import { Checkbox, Dialog, FormControlLabel } from "@mui/material";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import { ReactComponent as Checked } from "assets/img/svg/fi_check.svg";
import AButton from "../../../../common/form-fields-mui/AButton";
import { primaryCheckboxStyles } from "../../selectMeterialAndStock/constant";
import { notifyCourierDetails, notifyDlpStock, sendNotificationApiCall } from "../../service";
import { failureToast } from "../../../../common/utils/methods/toasterFunctions/function";
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'

const ActiveNotifications = ({
  handleCheckboxChange,
  enabledNotifications,
}) => {
  const checkboxOptions = [
    {
      title: "SMS",
      onChange: handleCheckboxChange,
      name: "SMS",
    },
    {
      title: "Email",
      onChange: handleCheckboxChange,
      name: "EMAIL",
    },
    {
      title: "Whatsapp",
      onChange: handleCheckboxChange,
      name: "WHATSAPP",
    },
  ];
  return (
    <>
      {checkboxOptions?.map((item, i) => (
        <FormControlLabel
          key={JSON.stringify(item)}
          disableTypography
          className="flex-grow-1"
          sx={{ margin: "0px", ...primaryCheckboxStyles }}
          control={
            <Checkbox
              sx={{ padding: "3px", marginLeft: i !== 0 ? "10px" : "" }}
              onChange={item?.onChange}
              checked={enabledNotifications[item?.name]}
              name={item?.name}
            />
          }
          label={
            <div
              className={`semi-bold color-black `
            }
            >
              {item?.title}
            </div>
          }
        />
      ))}
    </>
  );
};

const sendNotificationApiHandler = async (request, handleClose) => {
  handleClose();
};
const SendNotificationPopup = ({
  handleClose,
  open,
  transactionId,
  notificationInfo,
  selectedTab
}) => {
  const [enabledNotifications, setEnabledNotifications] = useState({
    EMAIL: true,
    SMS: true,
    WHATSAPP: true,
  });

  // const notifyCourierDetailsHandler = async () => {
  //   const notifyChannel = selectedChannel();
  //   const psid = id;
  //   const request = await notifyCourierDetails({ notifyChannel, psid });
  //   handleClose();
  // };

  const  notifyHandler = async () =>{
    const reqObj ={
      applicationId : notificationInfo?.applicationIds ,
      dispatchNotifyChannel : selectedChannel(),
      dlpStatus : [notificationInfo?. nextStatus],
      request : []
    }
    const resp= await notifyDlpStock(reqObj);
    if(resp?.code===200) handleClose();
  }

  const selectedChannel = () => {
    return Object.keys(enabledNotifications).filter(
      (item) => enabledNotifications[item]
    );
  };

  const checkBoxHandler = (e) => {
    const { checked, name } = e.target;
    setEnabledNotifications((prev) => ({ ...prev, [name]: checked }));
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={{ padding: "1.5rem", width: "33rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            fontSize: "1.4rem",
            marginBottom: "10px",
            color: "black",
          }}
        >
          {/* <span>Transaction ID: {transactionId} </span> */}
            <IconButtonWrapper className="ml-0 mb-1" onClick={handleClose}><CrossIcon /></IconButtonWrapper>
        </div>
        <div className="d-flex flex-column gap-md mt-3 justify-content-between align-items-center">
          <Checked />
          {selectedTab==="DISPATCHED" ?
          <div className=" heading-3 color-dark">Dispatch details saved successfully</div>
            :
          <>
          <div className=" heading-3 color-dark">
            Dispatch details saved and moved to dispatched{" "}
          </div>
          <div className=" heading-3 color-dark"> tab successfully</div>
          </> 
         }
          

          <div className="heading-4 color-dark mt-4">
            Send notification to students
          </div>
          <div className="d-flex">
            <ActiveNotifications
              enabledNotifications={enabledNotifications}
              handleCheckboxChange={checkBoxHandler}
            />
          </div>
          <div className=" my-4">
            <AButton
              onClick={() => notifyHandler()}
              variant="primary_filled"
              className="button_remove_left_margin"
              disabled={selectedChannel().length === 0}
            >
              Send notification
            </AButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SendNotificationPopup;
