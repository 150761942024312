import React, { useState } from "react";
import { ReactComponent as ActiveControl } from "../../../../../../assets/img/svg/ActiveControl.svg";
import { ReactComponent as InactiveControl } from "../../../../../../assets/img/svg/InactiveControl.svg";
import ActiveConfirmation from "./ActiveConfirmation";

// style={{ position: "absolute", right: "53px", top: "31%" }}
const EditButton = ({
  lecture,
  lectureContent,
  onEditHandler,
  toggleChange,
  counter,
}) => {
  const [isActiveConfirmation, setIsActiveConfirmation] = useState(false);
  const [isDeactivate, setIsDeactivate] = useState(false);

  const toggleHandleChange = (flag) => {
    setIsDeactivate(flag);
    setIsActiveConfirmation(true);
  };

  const onAgree = () => {
    toggleChange(lectureContent, !isDeactivate);
  };

  return (
    <div className="d-inline-flex">
      {lecture?.isActive ? (
        <ActiveControl onClick={() => toggleHandleChange(true)} />
      ) : (
        <InactiveControl onClick={() => toggleHandleChange(false)} />
      )}
      <span className="ml-2" style={{ fontSize: "14px" }}>
        {lecture?.isActive ? "Active" : "Inactive"}
      </span>
      <span
        className="regular-large-bold color-primary ml-2 cursor"
        onClick={onEditHandler}
      >
        Edit
      </span>
      {isActiveConfirmation && (
        <ActiveConfirmation
          isDeactivate={isDeactivate}
          onSaveAgree={onAgree}
          counter={counter}
          open={isActiveConfirmation}
          setOpen={setIsActiveConfirmation}
        />
      )}
    </div>
  );
};

export default EditButton;
