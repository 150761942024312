import {getAPI} from "services/http";
import {failureToast} from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import {endpoint} from "views/pages/manage/common/constant";

const getFormattedTemplateDataType =(arr=[])=>{
    if(arr && arr.length){
        let formattedArray = [];
        arr.forEach((item)=>{
            let obj = {
                value : item.id,
                label : item.templateDatatypeMasterDispVal,
                ...item
            }
            formattedArray.push(obj);
        });
        return formattedArray;
    }
}

const getTemplateDataType = async ()=>{
    let  url = endpoint.templateDataTypeMaster.getAllActive
    return new Promise(async(resolve,reject)=>{
        let result = {
            error : false,
            data : []
        };
        await getAPI(
            url,
            (data) => {
                result['data'] = getFormattedTemplateDataType(data.data);
                resolve(result);
            },
            (data) => {
                reject({error:true,message:data?.message})
                failureToast(data["message"]);
            }
        );
    })
}

export default getTemplateDataType;