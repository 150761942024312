import { Chip } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, Col, Row } from 'reactstrap'
import APopover from '../../common/a-popover'
import { getLabels } from '../constant/allocate-students'
import CapacityChip from './CapacityChip'

const linkButtonStyles = {
  color: '#00B0F5',
  fontWeight: 'bold',
  cursor: 'pointer',
  marginLeft: '5px'
}

const chipStyles = {
  margin: '5px 5px 5px 0',
}

const detailsStyles = {
  fontWeight: 400,
  fontSize: '14px'
}

const BatchDetailsPanel = ({ batchDetails }) => {
  return (
    <Card className='mb-0'>
      <CardBody>
        <h6 style={{ color: '#414141', fontSize: '16px', fontFamily: 'Gilroy-Medium' }}>Batch Details</h6>
        <Row>
          <Col md='12'>
            <div>Business Area</div>
            <div className='d-flex flex-wrap'>
              <Chip sx={chipStyles} label={batchDetails.businessArea} />
            </div>
          </Col>
          <Col md='12'>
            <div>Term</div>
            <div className='d-flex flex-wrap'>
              {batchDetails.term && batchDetails.term.map((el) => <Chip sx={chipStyles} label={el} key={el} />)}
            </div>
          </Col>
          <Col md='12'>
            <div>Academic Phase</div>
            <div className='d-flex flex-wrap'>
              {batchDetails.academicPhase && batchDetails.academicPhase.map((el) => <Chip sx={chipStyles} label={el} key={el} />)}
            </div>
          </Col>
          <Col md='12'>
            <div>Course ID</div>
            <div className='d-flex flex-wrap'>
              {batchDetails.courseId && batchDetails.courseId.map((el) => <Chip sx={chipStyles} label={el} key={el} />)}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

const BatchInfo = ({ batchDetails }) => {
  const dropdowns = useSelector(state => state.allocateStudents.dropdowns)
  const showButton = batchDetails?.term?.length > 1 || batchDetails?.academicPhase?.length > 1 || batchDetails?.courseId?.length > 1;

  const batchDetailsLabel = {
    businessArea: getLabels(dropdowns?.businessArea?.all, batchDetails?.businessArea) ?? "",
    term: getLabels(dropdowns?.term?.all, batchDetails?.term, true) ?? [],
    academicPhase: getLabels(dropdowns?.academicPhase?.all, batchDetails?.academicPhase, true) ?? [],
    courseId: batchDetails?.courseId
  }

  const textToShow = showButton
    ? `${batchDetailsLabel?.businessArea} | ${batchDetailsLabel?.term && batchDetailsLabel?.term[0]} +${batchDetailsLabel?.term?.length - 1} | ${batchDetailsLabel?.academicPhase && batchDetailsLabel?.academicPhase[0]} +${batchDetailsLabel?.academicPhase?.length - 1} | ${batchDetailsLabel?.courseId && batchDetailsLabel?.courseId[0]} +${batchDetailsLabel?.courseId?.length - 1}`
    : `${batchDetailsLabel?.businessArea} | ${batchDetailsLabel?.term && batchDetailsLabel?.term[0]} | ${batchDetailsLabel?.academicPhase && batchDetailsLabel?.academicPhase[0]} | ${batchDetailsLabel?.courseId && batchDetailsLabel?.courseId[0]}`

  return (
    <div className='d-flex flex-column'>
      <div style={{ display: 'flex', fontWeight: 700, fontSize: '20px', color: '#45464E', alignItems: "center" }}>
        {batchDetails.batchName}
        <CapacityChip capacity={batchDetails.batchCapacity} currentStrength={batchDetails.currentStrength} /></div>
      <APopover
        buttonComponent={(onClick, styles) => <><span style={detailsStyles}>{textToShow}</span> {showButton && <span onClick={onClick} style={linkButtonStyles}>View</span>}</>}
        menuComponent={() => <BatchDetailsPanel batchDetails={batchDetailsLabel} />}
        width={'429px'}
        maxHeight={'calc(100% - 200px)'}
        anchorOriginVertical='bottom'
        anchorOriginHorizontal='right'
        transformOriginVertical='top'
        transformOriginHorizontal='left'
      />
    </div>
  )
}

export default BatchInfo