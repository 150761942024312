import React from "react";
import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import { ReactComponent as UserProfile } from 'assets/img/svg/userProfile.svg';
import AButton from "../../common/form-fields-mui/AButton";
import closeIcon from 'assets/img/svg/closeSquare.svg'
import { endpoint } from "../../common/constant";
import { fetchAllPromisedData } from "../../common/utils/methods/commonMethods/utilityMethod";
import { getFormatedDate } from "./common";
import CustomChip from "../../common/customChip/CustomChip";
import CustomLoader from "../../common/commonComponents/Loader/loader";

export const fetchRemarks = async (quotationId, setRemarks, setOpen, applicationConcessionId) => {
    setOpen(true);
    try {
        const resp = await fetchAllPromisedData(`${endpoint.getStudentChargesAuditByQuotation}/${quotationId}${applicationConcessionId ? `?applicationConcessionId=${applicationConcessionId}` : ''}`, true);
        if (resp?.code === 200) {
            setRemarks(resp?.data);

        }
    } catch (err) {
        console.log(err);
    }
}


const getApprover = (action) => {
    switch (true) {
      
        case action?.includes("L1"):
            return "L1 approver";
        case action?.includes("L2"):
            return "L2 approver";
        case action?.includes("L3"):
            return "L3 approver";
        default:
                return "Cashier";    }
};

const getChip = (action) => {
    let chipData = { text: '', color: '', bgColor: '', style: { fontSize: '12px', fontWeight: 600 } }

    if (action.includes('APPROVED')) chipData = { ...chipData, text: 'Approved', color: '#0B442D', bgColor: '#BBE2CE' }
    else if (action.includes('RECHECK')) chipData = { ...chipData, text: 'Recheck', color: '#705010', bgColor: '#FFEDC8' }
    else if (action.includes('REJECT')) chipData = { ...chipData, text: 'Rejected', color: '#B32306', bgColor: '#F9D9D7' }
    // else if (action?.includes("-")) chipData={...chipData, text:'Concession on Extra Fee Components requested.', color: '#FFF', bgColor:'#6E42E5', style:{fontSize:'14px', fontWeight:400}}

    if (chipData?.text) return <CustomChip text={chipData?.text} textColor={chipData?.color} bgColor={chipData?.bgColor} style={chipData?.style} />
    return null

}

const RemarksDialogue = ({ remarksData, open, setOpen , setRemarksData}) => {

    const closeHandler=()=>{
        setOpen(false); 
        setRemarksData(null);
    }
    return (
        <Dialog open={open}>
            <DialogContent style={{ padding:'0px', overflowX:'hidden' }} >
            <Box className='concession-approval-dialog-content' width={500}>
                <Box className='approval-dialog-header'>
                    {/* <Typography variant='h6'>Status</Typography> */}
                    <div style={{fontWeight:600, fontSize:'20px'}}>Status</div>
                    <div onClick={closeHandler}><img src={closeIcon} /></div>
                </Box>
                {!remarksData &&  <CustomLoader />}
                {
                    remarksData && remarksData?.map(el => {
                        let approver=getApprover(el?.action);
                        return approver? (
                            <Box className='user-remarks-details d-flex'>
                                {/* <img src={userProfile} /> */}
                                <UserProfile style={{flexShrink:0, marginRight: '3px' }} />
                                <Box className='user-remarks'>
                                    <div style={{ fontSize: '16px', fontWeight: 600, color: "#0F0F0F", marginBottom: '3px' }}>{`${approver} ${approver==='Cashier' ? `- ${el?.action}` : ''}`}</div>
                                    <div style={{ fontSize: '14px', fontWeight: 400, color: "#0F0F0F", marginBottom: '3px',wordBreak:'break-word', paddingRight:'3px'}}>{el.remark || "-"}</div>
                                    <div style={{ fontSize: '12px', fontWeight: 400, color: "#757575", marginBottom: '3px' }}>{`${el.updatedBy}| ${getFormatedDate(el?.updatedOn, true)}`}</div>
                                    {getChip(el?.action)}
                                </Box>

                            </Box>) : null
                    })}
                <AButton
                    className='approval-dialog-action-button'
                    onClick={closeHandler}
                    variant='primary_filled'
                >Ok</AButton>
            </Box>
            </DialogContent>
        </Dialog>
    )
}

export default RemarksDialogue;