import React, { useEffect, useState } from "react";
import Select from "react-select";

const AutoComplete = props => {
    const [val, setVal] = useState("");
    const [val2, setVal2] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        props.onChange(val);
    }, [val])
  
    return (
      <>
        <Select
          components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
          noOptionsMessage={(e) => e.inputValue ? 'No options' : null}
          isClearable={true}
          isSearchable={true}
          styles={props.styles}
          name="color"
          options={props.options}
          placeholder={props.placeholder}
          closeMenuOnSelect={false}
          inputValue={val}
          value={val2}
          menuIsOpen={menuOpen}
          onBlur={() => setMenuOpen(false)}
          onChange={(newValue) => {
            setVal(newValue?.label || "");
            setVal2(null);
            setMenuOpen(false);
          }}
          onInputChange={(newValue, action) => {
            if (action.action === "input-change") {
              setVal(newValue);
              setMenuOpen(true);
            }
          }}
        />
      </>
    );
};

export default AutoComplete;