import React from "react";
import { useState ,useEffect} from "react";
import { FormGroup, Label, Card, CardHeader, Input, Button, Table, Row, Col } from "reactstrap";
import Select2 from "react-select2-wrapper";
import { failureToast } from '../../../common/utils/methods/toasterFunctions/function';
import { PinLabHeader, Rows } from "../../../common/commonComponents/masterHeader/masterHeader";
import { masterServiceBaseUrl, successToast, putpost, putpost2 } from 'services/http'
import moment from 'moment';
import Select from 'react-select';
import CustomDatePicker from '../../../common/commonComponents/CustomDatePicker/index';


import './index.css'

var _ = require('lodash');

const PineLabsTable = (props) => {
    const { setclassArray, pineLabSetupData,pinelabsPaymentModeIds,pineLabSetupDataExist ,setPineLabSetupData,isDisabled,setIsDisabled,action} = props;
    const [isSaveVisible, setisSaveVisible] = useState(false);
    const [pinelabSetupForm, setPinelabSetupForm] = useState({
        merchantId:'',
        paymentMode:'',
        posName:'',
    });


    const headersList = [{ name: 'EMPLOYEE ID' }, { name: 'EMPLOYEE NAME' }, { name: 'POS NAME' }, { name: 'IMEI' }, { name: 'MACHINE TYPE' }, { name: 'UPDATED DATE' }, { name: 'STATUS' }, { name: 'ACTION' }]
    const itemsFormMaster = ['machineType']
    const arraylist = [props.machineType]
    const listToRender = ['employeeId', 'employeeName', 'posName', 'imei', 'machineType', 'updatedOn', 'status']

    const [tableDataKey, settableDataKey] = useState('tableDataKey');
    const addNewClass = () => {
        setclassArray([{
            "editMode": true,
            "__status": "__new",
            'status': 'ACTIVE',
            "effectiveDate": null,
        },
        ...props.classArray])
    }

    useEffect(() => {
        if(pineLabSetupData && pineLabSetupData.merchantId){
            setPinelabSetupForm({
                merchantId:pineLabSetupData.merchantId,
                paymentMode:pineLabSetupData.paymentMode,
                posName:pineLabSetupData.posName,
            })
        }
    }, [pineLabSetupData])

    useEffect(()=>{
        return()=>{
          if(pinelabSetupForm && pinelabSetupForm.merchantId)
          !props.isPrevious && props.isNew && props.saveStepsCompleted()
        }    
      },[pinelabSetupForm])

    const saveAll = (cb) => {
        const testNumeric = /^[0-9]*$/;
        const testAlphaNumeric = /^[a-zA-Z0-9\-\s]+$/;
        var _tempArray = props.classArray
        var isValid = true
        _tempArray.forEach((el, index) => {
            if (el['editMode']) {
                if (el['employeeId'] == null || !el['employeeId'].toString().trim()) {
                    isValid = false
                    failureToast('Please enter the value of Employee Id')
                    return
                }
                else if (testAlphaNumeric.test(el['employeeId']) === false) {
                    isValid = false
                    failureToast('Please Enter Alpha Numeric value of Employee Id')
                    return
                }
                else if (el['employeeName'] == null || !el['employeeName'].toString().trim()) {
                    isValid = false
                    failureToast('Please enter the value of Employee Name')
                    return
                } 
                else if (testAlphaNumeric.test(el['employeeName']) === false) {
                    isValid = false
                    failureToast('Please Enter Alpha Numeric value of Employee Name')
                    return
                }
                else if (el['posName'] == null || !el['posName'].toString().trim()) {
                    isValid = false
                    failureToast('Please enter the value of POS Name')
                    return
                }
                else if (testAlphaNumeric.test(el['posName']) === false) {
                    isValid = false
                    failureToast('Please Enter Alpha Numeric value of POS Name')
                    return
                }
                else if (el['imei'] == null || !el['imei'].toString().trim()) {
                    isValid = false
                    failureToast('Please select the value of IMEI')
                    return
                }
                else if (testNumeric.test(el['imei']) === false) {
                    isValid = false
                    failureToast('Please Enter Numeric value of IMEI')
                    return
                }
                else if (el['machineType'] == null || !el['machineType'].toString().trim()) {
                    isValid = false
                    failureToast('Please select the value of Machine Type')
                    return
                }
                if (!isValid) {
                    return
                }
                let requestBody = {
                    employeeId: _tempArray[index]['employeeId'],
                    employeeName: _tempArray[index]['employeeName'],
                    posName: _tempArray[index]['posName'],
                    imei: _tempArray[index]['imei'],
                    machineType: _tempArray[index]['machineType'],
                    businessAreaId: props.businessAreaId,
                    status: _tempArray[index]['status']
                }
                if (_tempArray[index]['__status'] == "__new") {
                    putpost(masterServiceBaseUrl + '/pinelabs/posimei/mapping/', (data) => {
                        successToast(data['message'])
                        setisSaveVisible(false)
                        props.fetchClasses(masterServiceBaseUrl + '/pinelabs/posimei/mapping/businessArea/' + props.businessAreaId);
                    }, (data) => { failureToast(data['message']) }, requestBody, 'post')
                } else {
                    putpost(masterServiceBaseUrl + '/pinelabs/posimei/mapping/' + _tempArray[index].id, (data) => {
                        successToast(data['message'])
                        setisSaveVisible(false)
                        delete _tempArray[index]['editMode']
                        setclassArray(_tempArray)
                        settableDataKey(new Date().getMilliseconds());
                        props.fetchClasses(masterServiceBaseUrl + '/pinelabs/posimei/mapping/businessArea/' + props.businessAreaId);
                    }, (data) => { failureToast(data['message']) }, requestBody, 'put')
                }
            } else {
            }
        })
    }

    const savePinelabSetup = () => {
        const testNumeric = /^[0-9]*$/;
        const testAlphaNumeric = /^[a-zA-Z0-9\-\s]+$/;

        var isValid = true
            if (pinelabSetupForm.merchantId == null || !pinelabSetupForm.merchantId.toString().trim()) {
                isValid = false
                failureToast('Please Enter the value of Merchant Id')
                return
            }
            else if (testNumeric.test(pinelabSetupForm.merchantId) === false) {
                isValid = false
                // failureToast('Please Enter Numeric value of Merchant Id')
                return
            }
            else if (pinelabSetupForm.paymentMode == null || !pinelabSetupForm.paymentMode.toString().trim()) {
                isValid = false
                failureToast('Please Enter a valid value of Payment Mode')
                return
            } 
            else if (pinelabSetupForm.posName == null || !pinelabSetupForm.posName.trim()) {
                isValid = false
                failureToast('Please Enter a valid value of POS Name')
                return
            }
            else if (testAlphaNumeric.test(pinelabSetupForm.posName) === false) {
                isValid = false
                // failureToast('Please Enter Alpha Numeric value of POS Name')
                return
            }
            if (!isValid) {
                return
            }
            let requestBody = {
                merchantId: pinelabSetupForm.merchantId,
                paymentMode: pinelabSetupForm.paymentMode,
                posName: pinelabSetupForm.posName,
                businessAreaId: props.businessAreaId,
            }
            
            if (!pineLabSetupDataExist) {
                putpost(masterServiceBaseUrl + '/pinelabs/setup', (data) => {
                    successToast(data['message'])
                    props.fetchPineLabSetupData(masterServiceBaseUrl + '/pinelabs/setup/businessArea/' + props.businessAreaId);
                }, (data) => { failureToast(data['message']) }, requestBody, 'post')
            } else {
                putpost(masterServiceBaseUrl + '/pinelabs/setup/' + pineLabSetupData.id, (data) => {
                    successToast(data['message'])
                    props.fetchPineLabSetupData(masterServiceBaseUrl + '/pinelabs/setup/businessArea/' + props.businessAreaId);
                }, (data) => { failureToast(data['message']) }, requestBody, 'put')
            }       
    }

    const updatePineLabSetupKey = (value, key) => {
        var isValid = true;
        const testNumeric = /^[0-9]*$/;
        const testAlphaNumeric = /^[a-zA-Z0-9\-\s]+$/;

         if (key === 'merchantId' && testNumeric.test(value) === false) {
            isValid = testNumeric.test(value)
            // failureToast('Please Enter Numeric value of Merchant Id');
            return 
        }
        else if (key === 'posName' && testAlphaNumeric.test(value) === false) {
            isValid = testAlphaNumeric.test(value)
            // failureToast('Please Enter Alpha Numeric value of POS Name')
            return 
        }
        
        if(isValid){
            setPinelabSetupForm(prev =>({...prev,[key]:value}))
        }
    }

    const newRow = () => {
        if (isSaveVisible) {
            failureToast("Please Delete or Save current changes")
        }
        else {
            let cb = () => {
                addNewClass()
                setisSaveVisible(true)
            }
            var _tempArray = props.classArray
            _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
            setclassArray(_tempArray)
            setisSaveVisible(false)
            cb()
        }
    }
    const editRow = (index) => {
        if (isSaveVisible) {
            failureToast("Please Delete or Save current changes")
        }
        else {
            let cb = () => {
                var _tempArray = props.classArray
                _tempArray[index]['editMode'] = true
                setclassArray(_tempArray);
                setisSaveVisible(true)
            }
            var _tempArray = props.classArray
            _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
            cb()
        }
    }
    const deleteRow = (index) => {
        if (props.classArray[index]["__status"] == "__new") {
            let n = props.classArray
            n.splice(index, 1)
            setclassArray(n)
            setisSaveVisible(false)
            settableDataKey(new Date().getMilliseconds())
        } else {
            if (window.confirm('Are you sure you want to delete this IMEI Mapping?')) {
                putpost2(masterServiceBaseUrl + '/pinelabs/posimei/mapping/' + props.classArray[index].id, (data) => {
                    successToast(data['message'])
                    if (props.classArray.length == 1) {
                        props.previousPage();
                        props.fetchClasses(masterServiceBaseUrl + '/pinelabs/posimei/mapping/businessArea/' + props.businessAreaId);
                    } else {
                        var _tempArray = props.classArray
                        if (_tempArray[index]['editMode']) {
                            setisSaveVisible(false)
                        }
                        _tempArray.splice(index, 1)
                        setclassArray(_tempArray);
                        props.fetchClasses(masterServiceBaseUrl + '/pinelabs/posimei/mapping/businessArea/' + props.businessAreaId);
                    }
                    settableDataKey(new Date().getMilliseconds())
                }, (data) => { failureToast(data['message']) }, 'PUT')
            }
        }
    }
    const updateKey = (index, value, key) => {
        var _tempArray = props.classArray
        _tempArray[index][key] = value
        setclassArray(_tempArray)
    }

    const getOptionIndex = (x, array) => {
        for (var i = 0; i < array.length; i++) {
          if (array[i]['value'] == x) {
            return array[i];
          }
        }
    };


    return (
        <>
            <Card className='mb-2' style={{ boxShadow: 'none' }}>
                <CardHeader>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label
                                    className="form-control-label"
                                    htmlFor="example3cols2Input"
                                >
                                    Merchant ID
                                </Label>
                                <Input 
                                    maxLength="30"
                                    value={pinelabSetupForm.merchantId}
                                    name="atrributeDesc"
                                    type="text"
                                    placeholder="Merchant ID"
                                    onChange={(e) => { updatePineLabSetupKey(e.target.value, 'merchantId') }}
                                    style={ { height: '39px' } }
                                    disabled={isDisabled}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label
                                    className="form-control-label"
                                    htmlFor="example3cols2Input"
                                >
                                    Pine Labs Payment Mode
                                </Label>
                                <Select
                                    value={getOptionIndex(pinelabSetupForm.paymentMode, pinelabsPaymentModeIds)}
                                    id="example1cols1Input"
                                    options={pinelabsPaymentModeIds}
                                    onChange={(sData) => { updatePineLabSetupKey(sData.value, 'paymentMode');setPineLabSetupData({...pineLabSetupData,'paymentMode':sData.value}) }}
                                    required
                                    isDisabled={isDisabled}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label
                                    className="form-control-label"
                                    htmlFor="example3cols2Input"
                                >
                                    POS Name
                                </Label>
                                <Input
                                    maxLength="30"
                                    defaultValue={pinelabSetupForm.posName}
                                    name="atrributeDesc"
                                    type="text"
                                    placeholder="POS Name"
                                    onChange={(e) => { updatePineLabSetupKey(e.target.value, 'posName') }}
                                    style={ { height: '39px' } }
                                    disabled={isDisabled}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label
                                    className="form-control-label"
                                    htmlFor="example3cols2Input"
                                >
                                    Effective Date
                                </Label>
                                <CustomDatePicker 
                                    data-testid='effectiveDate'
                                    disabled={true}
                                    value={ pineLabSetupData && pineLabSetupData.effectiveDate ? moment(pineLabSetupData.effectiveDate).toDate() : ''}
                                    // value={
                                    //     el["__status"] == "__new" ? null : moment(el['effectiveDate']).toDate()
                                    // }
                                    placeholder="Example:01-01-2020"
                                />
                                {/* <Input
                                    defaultValue={ pineLabSetupData && pineLabSetupData.effectiveDate ? moment(pineLabSetupData.effectiveDate).format('DD-MM-YYYY') : ''}
                                    type="text"
                                    disabled={true}
                                    style={ { height: '39px' } }
                                    
                                /> */}
                            </FormGroup>
                        </Col>
                    </Row>
                    { !isDisabled ?
                        <Row>
                        <Col md={6}>

                        </Col>
                        <Col md={6} className='floatRight'>
                            {!pineLabSetupDataExist ?
                                <Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => { savePinelabSetup() }}>Save</Button> :
                                <Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => { savePinelabSetup() }}>Update</Button>}
                        </Col>
                    </Row>:null}
                </CardHeader>
            </Card>
            <Card style={{ boxShadow: 'none' }} >
                { !isDisabled ?
                    <CardHeader style={ { paddingTop: '0px', paddingBottom: '7px' } }>
                    <Button disabled={isSaveVisible} color="info" size="sm" type="button" className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)} onClick={() => { newRow() }}>Add POS Mapping</Button>
                    </CardHeader> : null
                }
                <div id="questionTable" className="table-responsive" style={{ marginBottom: '3.5rem' }}>
                    <Table className="align-items-center table-flush tableLayout" >
                        <PinLabHeader isDisabled={ isDisabled }/>
                        <tbody className="list" key={tableDataKey} >
                            {props.classArray ? props.classArray.map((el, index) =>
                                !el['editMode'] ? (
                                    <>
                                        <Rows data={el} functionItemsFromList={itemsFormMaster} listArray={arraylist} listToBeRendered={listToRender}>
                                            { !isDisabled ?
                                                <td>
                                                <Button color="info" size="sm" type="button" onClick={() => editRow(index)}><i className="fas fa-edit" /></Button>
                                                <Button color="info" size="sm" type="button" onClick={() => deleteRow(index)}><i className="fas fa-trash" /></Button>
                                            </td>:null}
                                        </Rows>
                                    </>
                                ) :
                                    (<tr key={index + '-class'}>
                                        <td className="text-center p-2 mx-1">
                                            <Input maxLength={20} defaultValue={el['employeeId']}
                                                onChange={(e) => { updateKey(index, e.target.value, 'employeeId') }}
                                                placeholder="Employee ID" className="tdInput" />
                                        </td>
                                        <td className="text-center p-2 mx-1">
                                            <Input maxLength={50} defaultValue={el['employeeName']}
                                                onChange={(e) => { updateKey(index, e.target.value, 'employeeName') }}
                                                placeholder="Employee Name" className="tdInput" />
                                        </td>
                                        <td className="text-center p-2 mx-1">
                                            <Input maxLength={20} defaultValue={el['posName']}
                                                onChange={(e) => { updateKey(index, e.target.value, 'posName') }}
                                                placeholder="POS Name" className="tdInput" />
                                        </td>
                                        <td className="text-center p-2 mx-1">
                                            <Input maxLength={20} defaultValue={el['imei']}
                                                onChange={(e) => { updateKey(index, e.target.value, 'imei') }}
                                                placeholder="IMEI" className="tdInput" />
                                        </td>
                                        <td className="text-center p-2 mx-1">
                                            <Select2
                                                defaultValue={el['machineType']}
                                                data={props.machineType}
                                                id='1'
                                                onChange={(e) => {
                                                    updateKey(index, e.target.value, 'machineType');
                                                }}
                                                options={{ placeholder: "Select Machine Type" }}
                                                className="tdSelect" />
                                        </td>
                                        <td className="text-center p-2 mx-1">
                                        <CustomDatePicker 
                                            data-testid='effectiveDate'
                                            disabled={true}
                                            value={ pineLabSetupData && pineLabSetupData.effectiveDate ? moment(pineLabSetupData.effectiveDate).toDate() : ''}
                                            // value={
                                            //     el["__status"] == "__new" ? null : moment(el['effectiveDate']).toDate()
                                            // }
                                            placeholder="Example:01-01-2020"
                                        />
                                        {/* <Input disabled={true} defaultValue={el["__status"] == "__new" ? null : moment(el['updatedOn']).format('DD-MM-YYYY')} placeholder="Example:01-01-2020" className="tdInput" /> */}
                                        </td>
                                        <td className="text-center p-2 mx-1">
                                            <label className="custom-toggle mx-auto ml-2">
                                                <input
                                                    checked={el.status == "ACTIVE" ? true : false}
                                                    type="checkbox" id={el.id}
                                                    onChange={(e) => {
                                                        settableDataKey(new Date().getMilliseconds());
                                                        let n = "ACTIVE"; if (!e.target.checked) { n = "INACTIVE" }
                                                        updateKey(index, n, 'status')
                                                    }}
                                                />
                                                <span style={{ width: "72px", margin: '0 auto' }} className="custom-toggle-slider rounded-circle activeToggle"
                                                    data-label-off="Inactive" data-label-on="Active" />
                                            </label>
                                        </td>
                                        <td className="text-center">
                                            {isSaveVisible ? <> <Button color="info" size="sm" type="button" className="mx-1" onClick={() => { saveAll() }}>Save</Button>
                                                <Button color="danger" size="sm" type="button" onClick={() => {
                                                    let n = props.classArray
                                                    if (n[index]['__status'] == '__new') {
                                                        n.splice(index, 1)
                                                    } else {
                                                        n[index]['editMode'] = false
                                                        props.fetchClasses(masterServiceBaseUrl + '/pinelabs/posimei/mapping/businessArea/' + props.businessAreaId);
                                                    }
                                                    setclassArray(n)
                                                    setisSaveVisible(false)
                                                }}>  <i className="fas fa-times" /></Button> </> :
                                                <Button color="info" size="sm" type="button" onClick={() => {
                                                    deleteRow(index)
                                                }}>  <i className="fas fa-trash" /></Button>}
                                        </td>
                                    </tr>)
                            ) : null}
                        </tbody>
                    </Table>
                </div>
               { action === 'edit' ?
                    <Row>
                    <Col>
                        <Button
                            className={ 'floatRight ml-auto mr-4 mb-4' }
                            color="primary"
                            size="md"
                            type="button"
                            onClick={()=>setIsDisabled(!isDisabled)}
                        >
                            {isDisabled ?'Enable Form':'Disable Form'}
                        </Button>
                    </Col>
                </Row>:null}
            </Card>
        </>
    )
}
export default PineLabsTable;