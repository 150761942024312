import React, { useState } from "react";
import { ReactComponent as BulletPoint } from "assets/img/svg/mc-timeline-bullets.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import CustomCopyToClipboard from "../../../../common/ACopyToClipboard/CustomCopyToClipboard";
import { Card, CardBody } from "reactstrap";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";

const linkStyle = {
  cursor: 'pointer',
  color: "#008DC4",
  marginLeft: "12rem",
  position: "absolute",
  right: "7px",
  top: "8px",
};

const MaterialList = ({ materialList = [] }) => {
  const [isShowList, setShowList] = useState(false);

  return (
    <>
      <span style={linkStyle} onClick={() => setShowList((prev) => !prev)}>
        {isShowList ? (
          <span>
            Hide list{" "}
            <MdOutlineKeyboardArrowUp
              style={{ color: "#008DC4", pointer: "cursor" }}
            />{" "}
          </span>
        ) : (
          <span>
            Show list{" "}
            <MdOutlineKeyboardArrowDown
              style={{ color: "#008DC4", pointer: "cursor" }}
            />{" "}
          </span>
        )}
      </span>
      {isShowList && (
        <ul className="mt-1 mb-1">
          {materialList.map((item) => (
            <li
              key={item.materialNumber}
              className="mb-2 global-font-size d-flex"
            >
              {item.materialNumber} | {item?.materialDescription}
              <CustomCopyToClipboard textToCopy={item.materialNumber} />
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
export const ViewGroupMaterials = ({ materialGroups }) => {
  return (
    <div>
      <Card>
        <CardBody>
          <div className="heading-4 color-dark">
            Onboarding material selected
          </div>
          <span className="mt-2">
            Since you have selected material from a Group 1, you need to select
            1 material from each of the following groups:
          </span>
          {materialGroups?.filter(item=>!item?.groupName.includes("Group - 01"))?.map((item, index) => (
            <div
              key={item?.groupName}
              className="mt-2"
              style={{
                background: "#F5F5F5",
                borderRadius: "8px",
                position: "relative",
                minHeight: "41px",
                transition: ".3s all ease",
                paddingLeft: "10px",
                // padding:"10px"
              }}
            >
              <span
                style={{
                  marginTop: "10px",
                  display: "inline-block",
                }}
              >
                <BulletPoint />
                <span> {item.groupName}</span>
              </span>
              <MaterialList materialList={item.materials} />
            </div>
          ))}
        </CardBody>
      </Card>
    </div>
  );
};
