import React, { useState } from "react";
import ADataGrid from "../../common/data-grid/ADataGrid";
import { AGridColDef } from "../../common/data-grid/ADataGridColDef";
import { useHistory } from "react-router";
import EmptyListPlaceholder from "../../edpOps/studentSerach/EmptyListPlaceholder";
import { Tooltip } from "@mui/material";
// import ViewBatchDetailsDialog from "./ViewBatchDetailsDialog";
import { EyeIcon } from "../../unAssigned/studentCharges/common";
import moment from "moment";
import { ReactComponent as EditIcon } from "../../../../../assets/img/svg/Edit2.svg";
import { ReactComponent as Eye } from 'assets/img/svg/eye_open.svg';
import { gridComponents } from "views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant";
import { gridStyles } from "views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant";


const commonStyle = {
    fontSize: '12px',
    color: '#414141',
    display: 'flex'
}

const headerStyle = {
    //styleName: Body/Semibold;
    fontFamily: 'Inter-Bold',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0em',
    textSlign: 'left'
}

const PackageList = ({
    rowsData,
    rowCount,
    nextButtonHandler,
    currentPage,
    loading
}) => {
    const history = useHistory();
    const [openDialog, setOpenDialog] = useState(false);
    const [openedPsid, setOpenedPsid] = useState("");

    const OnlinePackageListHeader = [
        new AGridColDef("packageId", "ID").setMinWidth(70).setFlex(0.3).renderCellComponent((params) => (
            <span style={commonStyle}>{params.value ?? ''}</span>
        )),
        new AGridColDef("onlinePackageName", "Name").setMinWidth(150).renderCellComponent((params) => (
            <Tooltip title={params?.value} componentsProps={{tooltip :{sx : {bgcolor: '#ffffff',color:'#262626', boxShadow: '0px 4px 32px rgba(61, 70, 112, 0.08)'}}}}>
                <div className="ellipsis-text-container d-block" style={commonStyle}>{params.value ?? ''}</div>
            </Tooltip>
        )),
        new AGridColDef("cmsPackage", "CMS package").setMinWidth(170).renderCellComponent((params =>
            <div className="d-flex align-items-center ellipsis-text-container"><div className="d-block" style={{ ...commonStyle, width: 100, textOverflow:'ellipsis', overflow:'hidden' }}>{params.value?.map(i => i.label).join(', ')}&nbsp;</div>
                {params.value?.length > 0 ? <Tooltip title={params.value?.map(i => `${i.label} - ${i.value}`).join(', ') ?? ''} componentsProps={{tooltip :{sx : {bgcolor: '#ffffff',color:'#262626', boxShadow: '0px 4px 32px rgba(61, 70, 112, 0.08)'}}}}>
                    <div className="cursor pl-2">
                        <EyeIcon />
                    </div>
                </Tooltip> : null}
            </div>
        )),
        new AGridColDef("academicCareerId", "Career").setMinWidth(150).renderCellComponent((params =>
            <div className="d-flex align-items-center"><span style={{ ...commonStyle, width: 100, textOverflow:'ellipsis', overflow:'hidden' }}>{params.value?.map(i => i.label).join(', ')}&nbsp;</span>
                {params.value?.length > 2 ? <Tooltip title={params.value?.map(i => i.label).join(', ') ?? ''} componentsProps={{tooltip :{sx : {bgcolor: '#ffffff',color:'#262626', boxShadow: '0px 4px 32px rgba(61, 70, 112, 0.08)'}}}} >
                    <div className="cursor pl-2">
                        <EyeIcon />
                    </div>
                </Tooltip> : null}
            </div>
        )),
        new AGridColDef("cmsCourse", "CMS course").setMinWidth(200).renderCellComponent((params =>
            <div className="d-flex align-items-center ellipsis-text-container">
                <div className="ellipsis-text-container" style={{ ...commonStyle, width: 120, textOverflow:'ellipsis', overflow:'hidden' }}>{params.value?.map(i => i.label).join(', ')}&nbsp;</div>
                {params.value?.length > 0 ? <Tooltip title={params.value?.map(i => i.label).join(', ') ?? ''} componentsProps={{tooltip :{sx : {bgcolor: '#ffffff',color:'#262626', boxShadow: '0px 4px 32px rgba(61, 70, 112, 0.08)'}}}}>
                    <div className="cursor pl-2">
                        <EyeIcon />
                    </div>
                </Tooltip> : null}
            </div>
        )),
        new AGridColDef("feature", "Features").setMinWidth(150).renderCellComponent((params =>
            <div className="d-flex align-items-center"><span style={{ ...commonStyle, width: 100, textOverflow:'ellipsis', overflow:'hidden' }}>{params.value?.map(i => i.label).join(', ')}&nbsp;</span>
                {params.value?.length > 0 ? <Tooltip title={params.value?.map(i => i.label).join(', ') ?? ''} componentsProps={{tooltip :{sx : {bgcolor: '#ffffff',color:'#262626', boxShadow: '0px 4px 32px rgba(61, 70, 112, 0.08)'}}}}>
                    <div className="cursor pl-2">
                        <EyeIcon />
                    </div>
                </Tooltip> : null}
            </div>
        )),
        // new AGridColDef("courseId", "Course ID").renderCellComponent((params =>
        //   <div style={commonStyle}>{params.value}&nbsp;
        //     <Tooltip title={courseIdDropdown.filter(item => item.value == params.value)?.[0]?.name ?? ''}>
        //       <div style={{ cursor: 'pointer' }}>
        //         {/* <AiOutlineEye className='ml-1' size={16} color='#11cdef' /> */}
        //         <EyeIcon />
        //       </div>
        //     </Tooltip>
        //   </div>
        // )),
        new AGridColDef("validFrom", "Valid from").renderCellComponent((params =>
            <span style={commonStyle}>{params.value ? moment(params.value).format("DD-MMM-YYYY") : '-'}</span>
        )),
        new AGridColDef("validTo", "Valid to").renderCellComponent((params =>
            <span style={commonStyle}>{params.value ? moment(params.value).format("DD-MMM-YYYY") : '-'}</span>
        )),
        new AGridColDef("status", "Status").renderCellComponent((params =>
            <span style={commonStyle}>{params.value}</span>
        )),
        // new AGridColDef("chargesStatus", "Charge").renderCellComponent((params =>
        //   <span style={chargesStatus?.[params.value] ?? {}}>{params.value ?? '-'}</span>
        // )),
        // new AGridColDef("paymentStatus", "Payment").renderCellComponent((params =>
        //   <span style={paymentStatus?.[params.value?.toLowerCase()] ?? {}}>{params.value ?? '-'}</span>
        // )),
        new AGridColDef("Actions", "Action", false)
            .setWidth(150)
            .renderCellComponent((params) => {
                return <>
                    <Tooltip title="Edit" componentsProps={{tooltip :{sx : {bgcolor: '#ffffff',color:'#262626', boxShadow: '0px 4px 32px rgba(61, 70, 112, 0.08)'}}}}>
                        <span
                            onClick={() => { history.push(`/admin/onlinePackage/edit/${params.row.id}`)}}
                            className="p-1 cursor"
                        >
                            <EditIcon />
                        </span>
                    </Tooltip>
                    <Tooltip title="View" componentsProps={{tooltip :{sx : {bgcolor: '#ffffff',color:'#262626', boxShadow: '0px 4px 32px rgba(61, 70, 112, 0.08)'}}}}>
                        <span
                            onClick={() => { history.push(`/admin/onlinePackage/view/${params.row.id}`)}}
                            className="p-1 cursor"
                        >
                            <Eye />
                        </span>
                    </Tooltip>
                </>
            }),
    ];

    return (
        <>
        <div className="heading-4 color-dark ml-2">{rowCount} packages </div>
            <ADataGrid
                rows={rowsData}
                columns={OnlinePackageListHeader}
                rowId={(row) => row.id}
                loading={loading}
                rowCount={rowCount}
                nextButtonHandler={nextButtonHandler}
                currentPage={currentPage}
                emptyRowPlaceholder={() => <div className="d-flex justify-content-center align-items-center h-100">No rows</div>}
                components={gridComponents}
                sx={{
                    ...gridStyles,
                  }}
                sortColumnName="psid"
                pageSize={10}
            />
        </>
    );
};

export default PackageList;
