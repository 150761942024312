import React, { useEffect, useState } from 'react';
import AButton from '../../../common/form-fields-mui/AButton';
import AAutoComplete from 'views/pages/manage/common/form-fields-mui/AAutoComplete';
import styles from './styles.module.scss';
import { InputLabel } from '@mui/material';
import { InputLabelStyle } from '../../../common/form-fields-mui/ASelect';
import CustomDatePickerV2 from '../../../common/commonComponents/CustomDatePicker-V2';
import { fetchStockTransactions, selectStyles } from './constant';
import { useGetCourseIdsForBUAndTerm } from '../customhooks';
import { useFetchBatches } from '../../issuanceIDcard/customhooks';
import ADataGrid from '../../../common/data-grid/ADataGrid';
import { AGridColDef } from '../../../common/data-grid/ADataGridColDef';
import {
  gridComponents,
  gridStyles,
} from '../../issuanceIDcard/printIdCardAndMapRfid/constant';
import NoRowOverlay from '../../../masters/holidayMaster/NoRowOverlay';
import moment from 'moment';
import { BiInfoCircle } from 'react-icons/bi';
import CustomTooltip from 'views/pages/manage/common/customTooltip/CustomTooltip';
import CustomChip from 'views/pages/manage/common/customChip/CustomChip';
import { capitalize } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';

const TransactionTable = ({
  rows,
  rowCount,
  onPageChange,
  currentPage,
  redirectToReversal,
}) => {
  const columns = [
    new AGridColDef('issueDate', 'Issue details', false)
      .setValueGetter(params => moment(params?.value)?.format('DD-MMM-YYYY'))
      .renderCellComponent(params => (
        <div className='d-flex align-items-center gap-md ellipsis-text-contianer'>
          <div className='ellipsis-text-container'>{params?.value}</div>
          <CustomTooltip
            placement='bottom-start'
            title={`${params?.value}, ${moment(params?.row?.issueDate)?.format(
              'hh:mm A'
            )} by ${params?.row?.createdBy}`}
          >
            <div className='d-flex cursor'>
              <BiInfoCircle size={'1rem'} />
            </div>
          </CustomTooltip>
        </div>
      )),
    new AGridColDef('transactionId', 'Transaction ID', false),
    new AGridColDef('sapDocId', 'SAP doc number', false),
    new AGridColDef('businessAreaValue', 'Business area', false),
    new AGridColDef('termValue', 'Term', false),
    new AGridColDef('status', 'Status', false).renderCellComponent(params => (
      <CustomChip
        bgColor='#BBE2CE'
        textColor='#0B442D'
        style={{ fontSize: '12px' }}
      >
        {capitalize(params?.value)}
      </CustomChip>
    )),
    new AGridColDef('actions', 'Actions', false)
      .columnAlign('right')
      .renderCellComponent(params => {
        const {transactionId, businessArea, businessAreaValue} = params?.row;
        return (
          <div>
            <AButton
              size='xs'
              variant='primary_filled'
              className='ml-0'
              onClick={() => redirectToReversal(transactionId, businessArea, businessAreaValue)}
            >
              Continue
            </AButton>
          </div>
        );
      }),
  ];

  return (
    <div className='mt-4'>
      <div className='large-semi-bold'>{rowCount} transactions found</div>
      <div className={`mt-2 ${styles['other-detail__gridContainer']}`}>
        <ADataGrid
          removeWrapperContainer
          rows={rows || []}
          columns={columns}
          rowId={row => JSON.stringify(row)}
          rowHeight={50}
          loading={false}
          rowCount={rowCount}
          nextButtonHandler={onPageChange}
          currentPage={currentPage}
          components={{
            ...gridComponents,
            NoRowsOverlay: () => <NoRowOverlay />,
          }}
          hidePagination={rowCount <= 50 ? true : false}
          sx={{
            ...gridStyles,
          }}
          maxHeight={'36vh'}
        />
      </div>
    </div>
  );
};
const SearchByOtherDetails = ({
  checkedOption,
  academicCareer,
  form,
  formHandler,
  dropdown,
  setListLoader,
  redirectToReversal,
}) => {
  const [courseIdDropdown, isFetchingCourseId] = useGetCourseIdsForBUAndTerm(form);
  const [batchDropdown, isFetchingBatches] = useFetchBatches(
    form?.businessArea,
    form?.term,
    form?.courseId
  );

  const [showTable, setShowTable] = useState(false);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  useEffect(() => {
    setShowTable(false);
    setPage(0);
    setRows([]);
    setRowCount(0);
  }, [checkedOption]);

  const fetchList = async page => {
    setListLoader(true);
    const res = await fetchStockTransactions(page, form);
    if (res?.code === 200) {
      setRows(
        res?.data?.stockTransactionAudList?.map(item => ({
          ...item,
          sapPlantId: form?.businessArea?.sapPlantId,
        }))
      );
      setRowCount(res?.data?.totalRecords);
    } else {
      setRows([]);
      setRowCount(0);
    }
    setShowTable(true);
    setListLoader(false);
  };
  const handleOtherSearch = async () => {
    fetchList(page);
  };

  const onPageChange = newPage => {
    setPage(newPage);
    fetchList(newPage);
  };

  return (
    <div className='w-100'>
      <div className={`${styles['other-detail__dropdowns']}`}>
        <div>
          <AAutoComplete
            label='Business area*'
            placeholder='Select'
            isMulti={false}
            items={dropdown?.businessAreas}
            currentValue={form?.businessArea}
            handleChange={value => {
              formHandler(value, 'businessArea');
            }}
            style={selectStyles}
          />
        </div>
        <div>
          <AAutoComplete
            label='Term*'
            placeholder='Select'
            isMulti={false}
            items={dropdown?.terms}
            currentValue={form?.term}
            handleChange={value => {
              formHandler(value, 'term');
            }}
            style={selectStyles}
          />
        </div>
        <div>
          <AAutoComplete
            label='Course ID'
            placeholder='Select'
            isMulti={false}
            items={courseIdDropdown}
            isLoading={isFetchingCourseId}
            currentValue={form?.courseId}
            handleChange={value => {
              formHandler(value, 'courseId');
            }}
            style={selectStyles}
            noOptionsMessage={
              (!form?.businessArea && 'Select business area first') ||
              (!form?.term && 'Select term first') ||
              null
            }
          />
        </div>
        <div>
          <AAutoComplete
            label='Batch'
            placeholder='Select'
            isMulti={false}
            items={batchDropdown}
            currentValue={form?.batchId}
            handleChange={value => {
              formHandler(value, 'batchId');
            }}
            isLoading={isFetchingBatches}
            style={{ ...selectStyles, menuPortal: { minWidth: '160px' } }}
          />
        </div>
        <div className='d-flex flex-column'>
          <InputLabel sx={InputLabelStyle}>Issuance date</InputLabel>
          <div className='d-flex gap-md'>
            <div className=''>
              <CustomDatePickerV2
                value={form?.issuanceDateFrom}
                handler={(i, date) => {
                  formHandler(date, 'issuanceDateFrom');
                }}
                maxDate={new Date()}
              />
            </div>
            <div className=''>
              <CustomDatePickerV2
                value={form?.issuanceDateTo}
                handler={(i, date) => {
                  formHandler(date, 'issuanceDateTo');
                }}
                maxDate={new Date()}
                minDate={form?.issuanceDateFrom}
                disabled={!form?.issuanceDateFrom}
              />
            </div>
          </div>
        </div>
        <div className={`align-self-end`}>
          <AButton
            variant='primary_filled'
            className='ml-0'
            onClick={handleOtherSearch}
            disabled={(!form?.businessArea || !form?.term)}
          >
            View details
          </AButton>
        </div>
      </div>
      {showTable && (
        <TransactionTable
          rows={rows}
          rowCount={rowCount}
          currentPage={page}
          onPageChange={onPageChange}
          redirectToReversal={redirectToReversal}
        />
      )}
    </div>
  );
};

export default SearchByOtherDetails;
