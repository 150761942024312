import React, { useState, useEffect, useContext } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router';
import CareerBasedLoanSetup from './careerBasedLoanSetup';
import LoanSchemeMaster from './loanSchemeMaster';
import { PermissionContext, RolePermissions } from "../../../../../appContext";
import { pages } from '../../common/constant';
import { PermisionDenied } from '../../common/commonComponents';

const TABS = [
    { label: 'Loan scheme master', value: 'loanSchemeMaster', component: LoanSchemeMaster },
    { label: 'Career based loan set-up', value: 'careerBasedLoanSetup', component: CareerBasedLoanSetup }
];

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const initialTab = 0;

export default function LoanSchemeSetup(props) {
    const permissions = useContext(PermissionContext);
    const userPermissions = RolePermissions(permissions, pages["loanSchemeMaster"]['id']);

    const history = useHistory();
    const [value, setValue] = useState(initialTab);

    const { tabIndex } = props.match.params;

    useEffect(() => {
        if (tabIndex) {
            setValue(+tabIndex);
        } else {
            history.push(`/admin/loanSchemeSetup/tab/${initialTab}`);
        }
    }, [tabIndex]);

    const handleChange = (event, newValue) => {
        history.push(`/admin/loanSchemeSetup/tab/${newValue}`);
    };

    const TabContent = TABS[value]?.component;

    return (
        userPermissions ? (
            <>
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        backgroundColor: '#FFFFFF',
                        '& .MuiTab-root': {
                            color: '#757575',
                            fontSize: '16px',
                            height: '68px',
                            textTransform: 'none',
                            fontFamily: 'Gilroy-Regular, sans-serif'
                        },
                        '& .MuiTab-root.Mui-selected': {
                            color: '#00B0F5',
                            borderBottom: '3px solid #00B0F5'
                        },
                        '& .MuiTabs-indicator': {
                            display: 'none'
                        }
                    }}
                >
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                        {TABS.map((tab, index) => (<Tab key={tab.value} label={tab.label} value={index} {...a11yProps(index)} />))}
                    </Tabs>
                </Box>
                <Box sx={{ p: 3, m: 3, background: "#fff" }}>
                    {TabContent && <TabContent userPermissions={userPermissions} />}
                </Box>
            </>
        ) : (
            <PermisionDenied />
        )
    );
}