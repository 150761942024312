import React from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Badge, Tab } from '@mui/material'
import { useState } from 'react'
import AcademicPlansSearchFilter from './AcademicPlansSearchFilter'
import { Card, CardBody } from 'reactstrap'
import AcademicPlansList from './AcademicPlansList'
import { useSelector } from 'react-redux'
import ALoader from '../../common/a-loader/index'
import TestSyllabus from '../testsListView/TestSyllabus'

// const CountBadge = ({count}) => {
//   return (
//     <div style={{ borderRadius: '50%', padding: '0 3px', backgroundColor: '#00B0F5', color: '#FFFFFF', lineHeight: '1.5rem' }}>
//       {count}
//     </div>
//   )
// }

const TabsComponent = ({ 
  onTabChange, 
  filterCount, 
  filterForm, 
  inputHandler, 
  applyNameFilterHandler, 
  clearNameFilterHandler, 
  applyFilterHandler, 
  removeFilterHandler, 
  page, 
  onPageChange,
  activePlanData,
  setActivePlanData = () => { },
  updateApiLoader,
  updatePublishStatusHandler,
  updateBranchesHandler,
  businessAreaData,
  setBusinessAreaData
}) => {

  const state = useSelector(state => state?.academicPlan)
  const [value, setValue] = useState('All')
  const handleTabChange = (e, newValue) => {
    setValue(newValue);
    onTabChange(newValue);
  }
  // const getTabLabel = (tabValue) => (value===tabValue ? {label: <BiSearch />} : {label: tabValue})
  // const getTabIcon = (tabValue) => (value===tabValue ? {icon: <CountBadge count={200} />, iconPosition: 'end'} : {})

  return (
    <div style={{ flex: 1, display: 'flex', flexFlow: 'column', position: 'relative' }}>
      {(state?.dropdownLoader || state?.searchAcademicPlanLoader || updateApiLoader) && <ALoader />}
      <TabContext value={value} >
        <TabList
          onChange={handleTabChange}
          centered
          sx={{
            backgroundColor: '#FFFFFF',
            '& .MuiTab-root': {
              color: '#757575',
              fontSize: '16px',
              // fontWeight: 600,
              height: '60px',
              width: '170px',
              textTransform: 'none'
            },
            '& .MuiTab-root.Mui-selected': {
              color: '#00B0F5',
              borderBottom: '3px solid #00B0F5'
            },
            '& .MuiTabs-indicator': {
              display: 'none'
            }
          }}
        >
          <Tab label='All' value='All' />
          <Tab label='SOE' value='SOE' />
          <Tab label='SOM' value='SOM' />
          <Tab label='SOF' value='SOF' />
        </TabList>

        <TabPanel value={value} style={{ flex: 1 }}>
          <Card style={{ marginBottom: 0, height: '100%' }}>
            <CardBody style={{ display: 'flex', flexFlow: 'column' }}>
              <div style={{ display: "flex", justifyContent: "space-between", flex: 1, /* position: "relative" */ }}>
                <div style={{ fontSize: '20px', fontWeight: 600, color: '#0F0F0F' }}>{value} Academic Plans</div>
                <AcademicPlansSearchFilter 
                  filterCount={filterCount}
                  filterForm={filterForm} 
                  inputHandler={inputHandler} 
                  applyNameFilterHandler={applyNameFilterHandler}
                  clearNameFilterHandler={clearNameFilterHandler}
                  applyFilterHandler={applyFilterHandler}
                  removeFilterHandler={removeFilterHandler}
                  businessAreaData={businessAreaData}
                  setBusinessAreaData={setBusinessAreaData}
                />
              </div>
              <div style={{ flexGrow: 1, display: 'flex' }}>
                <AcademicPlansList
                  page={page}
                  onPageChange={onPageChange}
                  planData={activePlanData}
                  setPlanData={setActivePlanData}
                  updatePublishStatusHandler={updatePublishStatusHandler}
                  updateBranchesHandler={updateBranchesHandler}
                />
              </div>
            </CardBody>
          </Card>
        </TabPanel>
      </TabContext>
    </div>
  )
}

export default TabsComponent