import React, { useEffect, useState } from 'react';
import Header from './header';
import { Table, Card, CardHeader, Button, Container } from 'reactstrap';
import {
	getAPI,
	putpost2,
} from '../../../../../../services/http';
import TableList from './tableList';
import NoDataFound from '../../noDataFound';
import TableEditField from './tableEditField';
import { GetPagination } from './pagination';
import CreateButton from "components/FormComponent/Button/New/index";
import CustomButton from "../../../../../../components/CustomButton";
import { failureToast, successToast } from '../../utils/methods/toasterFunctions/function';


const CustomTable = (props) => {
	const { tableDetails, tableHeader, validationRules, addButtonText, saveButtonText, permissionSet } = props;
	const [state, setState] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [loader, setLoader] = useState(false);
	const [loaderDelete, setLoaderDelete] = useState();
	const [actionExist, setActionExist] = useState(false);
	const [pagination, setPagination] = useState({
		currentPage: 1,
		nextPage: null,
		previousPage: null,
		totalPage: null,
	});
	const pageSize = 10;
	const newRecord = {};
	const addNewHandler = () => {
		try {
			addNewFieldHandler();
			if (actionExist) {
				return failureToast('Please close the Action');
			}
			setTableData([newRecord, ...tableData]);
			setActionExist(true);
		} catch (e) {
			console.log('Error', e);
		}
	};

	const fetchDetails = async (url) => {
		try {
			getAPI(
				url,
				(data) => {
					initialPaginationHandler(data?.data);
					setState(data?.data);
				},
				(data) => {
					failureToast(data['message']);
				}
			);
		} catch (err) {
			console.log('Error :', err);
		}
	};

	const deleteHandler = (index) => {
		try {
			setActionExist(true);
			if (tableData[index]['__status'] === '__new') {
				let n = tableData;
				n.splice(index, 1);
				setTableData(n);
				setActionExist(false);
			} else {
				if (window.confirm('Are you sure you want to delete this record?')) {
					setLoaderDelete({
						loader: true,
						index: index,
						type: 'DELETE',
					});
					putpost2(
						tableDetails?.apiDetails?.delete + '/' + tableData[index].id,
						(data) => {
							successToast(data['message']);
							if (tableData.length <= 1) {
								previousPage();
							} else {
								const _tempArray = tableData;
								_tempArray.splice(index, 1);
								setTableData(_tempArray);
							}
							setLoaderDelete({});
							setActionExist(false);
							fetchDetails(tableDetails?.apiDetails?.getAll);
						},
						(data) => {
							failureToast(data['message']);
						},
						'PUT'
					);
				} else {
					setActionExist(false);
				}
			}
		} catch (e) {
			console.log('Error', e);
		}
	};

	const editHandler = (index) => {
		try {
			const tempRecord = tableData;
			tempRecord[index].editMode = true;
			setTableData(tempRecord);
			setLoader(true);
			setActionExist(true);
		} catch (e) {
			console.log('Error', e);
		}
	};

	const actionHandler = (type, index) => {
		try {
			switch (type) {
				case 'EDIT':
					editHandler(index);
					break;
				case 'DELETE':
					deleteHandler(index);
					break;
				default:
					break;
			}
		} catch (e) {
			console.log('Error', e);
		}
	};

	const addNewFieldHandler = () => {
		try {
			newRecord.__status = '__new';
			newRecord.editMode = true;
			tableDetails?.fieldDetails !== undefined &&
				tableDetails?.fieldDetails !== null &&
				tableDetails?.fieldDetails.length > 0 &&
				tableDetails.fieldDetails.forEach((item) => {
					if (item.hasOwnProperty('propertyName')) {
						newRecord[item.propertyName] =
							item.propertyName === 'status' ? 'ACTIVE' : null;
					}
				});
		} catch (e) {
			console.log('Error ', e);
		}
	};

	const initialPaginationHandler = (data) => {
		const n = pagination;
		if (data.length > pageSize) {
			n['nextPage'] = n.currentPage + 1;
		}
		if (n.currentPage > 1) {
			n.previousPage = n.currentPage - 1;
		}
		n['totalPage'] = Math.floor(data.length / pageSize);
		if (data.length % pageSize !== 0) {
			n['totalPage'] += 1;
		}
		setPagination(n);
		setTableData(
			data.slice(
				pagination.currentPage * pageSize - pageSize,
				pagination.currentPage * pageSize
			) || []
		);
	};

	const previousPage = () => {
		if (pagination.currentPage !== 1) {
			setTableData(
				state.slice(
					(pagination.currentPage - 1) * pageSize - pageSize,
					(pagination.currentPage - 1) * pageSize
				)
			);
			let n = pagination;
			n['currentPage'] = n['currentPage'] - 1;
			if (n['currentPage'] === 1) {
				n['previousPage'] = null;
			} else {
				n['previousPage'] = n.currentPage;
			}
			setPagination(n);
		}
	};

	useEffect(() => {
		if (!tableDetails.isSearch) {
			fetchDetails(tableDetails.apiDetails.getAll);
		}
	}, []);

	return (
		<Container fluid>
			<Card>
				<CardHeader className="border-0 d-flex justify-content-between">
					{tableHeader()}
					{tableDetails?.isNew ? (
						// <CreateButton 
						// 	text={addButtonText}
						// 	disabled={actionExist}
						// 	onClick={addNewHandler}
						// 	style={{ width: '143px'}}
						// />
						// <Button
						// 	disabled={actionExist}
						// 	color={'info'}
						// 	size={'sm'}
						// 	style={{ width: '100px', marginLeft: '10px' }}
						// 	onClick={addNewHandler}
						// >
						// <i className="fas fa-plus" />&nbsp;&nbsp;Add New
						// </Button>
						<CustomButton
							disabled={actionExist}
							// className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
							content={addButtonText}
							permissionType={'C'}
							icon={true}
							permissionSet={permissionSet}
							onClick={addNewHandler}
					  	/>
					) : null}
				</CardHeader>
				<div id="questionTable" className="table-responsive">
					<Table className="align-items-center table-flush tableLayout">
						<Header
							header={tableDetails?.headerDetails}
							fieldDetails={tableDetails?.fieldDetails}
							permissionSet={permissionSet}
							actionExist={actionExist}
						/>
						<tbody className="list" key={'tableDataKey'}>
							{tableData.length > 0 &&
								tableData.map((item, index) =>
									!item['editMode'] ? (
										<TableList
											key={'tableRecordDetails' + index}
											item={item}
											listDetails={tableDetails?.listDetails}
											actionHandler={actionHandler}
											index={index}
											loaderDelete={loaderDelete}
											actionExist={actionExist}
											permissionSet={permissionSet}
										/>
									) : (
										<TableEditField
											fieldDetails={tableDetails?.fieldDetails}
											rowIndex={index}
											tableData={tableData}
											validationRules={validationRules}
											setTableData={setTableData}
											fetchDetails={fetchDetails}
											apiDetails={tableDetails?.apiDetails}
											setActionExist={setActionExist}
											deleteHandler={deleteHandler}
											key={'tableEditField' + index}
											saveButtonText={saveButtonText}
											permissionSet={permissionSet}
											actionExist={actionExist}
										/>
									)
								)}
							{tableData.length === 0 ? (
								<tr key={'notFound'}>
									<td colSpan={tableDetails?.headerDetails.length-1}>
										<NoDataFound />
									</td>
								</tr>
							) : null}
						</tbody>
					</Table>
				</div>
				{pagination.totalPage > 1 ? (
					<GetPagination
						isSaveVisible={actionExist}
						setTableData={setTableData}
						pagination={pagination}
						setPagination={setPagination}
						pageSize={pageSize}
						state={state}
						previousPage={previousPage}
					/>
				) : null}
			</Card>
		</Container>
	);
};

export default CustomTable;
