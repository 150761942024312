import React, { useContext, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Divider } from "@mui/material";
import { ReactComponent as Uparrow } from "../../../../../../assets/img/svg/upwardArrow.svg";
import { ReactComponent as Downarrow } from "../../../../../../assets/img/svg/downarrow.svg";
import TreeViewByList from "./../treeView/TreeViewList";
import EditButton from "./EditButton";
import ChipWithName from './ChipWithName';
import ALoader from './../../../common/a-loader/index';
import { getLectureById } from "../helper";
import { pages } from "views/pages/manage/common/constant";
import { PermissionContext, RolePermissions } from 'appContext'
import { checkPermission } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";

const AccordionStyles = {
  mb: 1, borderRadius: "12px 0px 12px 12px", position: "relative",
  '& .MuiCollapse-root': { marginBottom: '0.5rem' },
  '& .MuiButtonBase-root:hover:not(.Mui-disabled), .MuiAccordionSummary-root:hover:not(.Mui-disabled)': { cursor: 'default' },
  '& .MuiAccordionSummary-root': { alignItems: 'start' },
  '& .MuiAccordionSummary-expandIconWrapper': { margin: '25px 0', transition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': { margin: '25px 0', },
}
const LectureListView = ({
  formModal,
  testContentFormHandler,
  addChapterHandler,
  lectureData,
  counter,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false);
  const [lecture, setLecture] = useState({});
  const [lectureLoading, setLectureLoading] = useState(true)
  const userPermissions = RolePermissions(useContext(PermissionContext), pages['searchAcademicPlan']['id']);

  const toggleAccordion = (e) => {
    setExpanded(prev => !prev)
    if (!lecture?.subject)
      fetchLecture(lectureData.id)
  }

  function onChangeHandler(event) {
    setExpanded(!expanded);
    if (!lecture?.subject)
      fetchLecture(lectureData.id)
  }

  const fetchLecture = (lectureId) => {
    setLectureLoading(true)
    getLectureById(lectureId)
      .then(res => {
        if (res)
          setLecture(res);
        setLectureLoading(false)
      })

      .catch(error => {
        setLectureLoading(false)
      })
  }

  // this use effect helps to initialise the lecture state after it is edited from UI
  useEffect(() => {
    setExpanded(false);
    setLecture({});
  }, [lectureData])

  return (
    <div>
      <Accordion
        expanded={expanded}
        // onChange={onChangeHandler}
        sx={AccordionStyles}
      >
        <AccordionSummary
          expandIcon={<Downarrow onClick={toggleAccordion} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div
            className="d-flex justify-content-between"
            style={{ width: "98%" }}
          >
            <div className="d-flex flex-column">
              <span className="heading-4 color-dark"> {counter}. Lecture</span>
              <ChipWithName
               lecture={lectureData} />
            </div>
            {expanded &&  checkPermission(userPermissions, 'C') && (
             
             <EditButton 
                onEditHandler={() => {
                  props.onEditHandler(lecture, counter)
                  window.scrollTo({ top: 100, left: 0, behavior: 'smooth' });
                }} 
                toggleChange = {props.toggleChange}
                lecture={lectureData} 
                lectureContent = {lecture}
                counter = {counter}
              />
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {lectureLoading && <ALoader />}
          <Divider className='mb-3' style={{borderStyle: 'dashed'}} />
          <TreeViewByList counter={counter} subjectChapters={lecture} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default LectureListView;
