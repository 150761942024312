import { endpoint } from "../../common/constant"
import { dropdownMapping } from "../../common/utils/methods/commonMethods/utilityMethod"

export const getDropdowns = async () => {
  const p = await Promise.all([
    dropdownMapping(endpoint.region.getAllActive, 'regionDispValue', 'id'),
    dropdownMapping(endpoint.businessArea.getAllActive, 'businessAreaDispValue', 'id'),
  ])

  return {
    region: p[0],
    businessArea: p[1],
  }
}