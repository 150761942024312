import moment from "moment";
import { getCoursePricingDetails } from "./service";
import { failureToast } from "../../common/utils/methods/toasterFunctions/function";

export const COURSE_END_DATE_BUFFER_ISSUANCE = 30;
export const checkCourseEndDatePassed = async (application) => {
  const { businessArea, courseId, term } = application || {};
  const res = await getCoursePricingDetails(courseId, businessArea, term);

  if (
    moment(res?.courseEndDate)
      .startOf("day")
      .isBefore(
        moment()
          .subtract(COURSE_END_DATE_BUFFER_ISSUANCE, "days")
          .startOf("day")
      ) ||
    moment(res?.courseStartDate).isAfter(new Date())
  ) {
    failureToast(
      "Issuance against a course can be made only between course start date and end date"
    );
    return true;
  }

  return false;
};
