import React from 'react'
import { Dialog } from '@mui/material'
import { ReactComponent as CrossIcon } from 'assets/img/svg/CrossIcon.svg'
import AButton from '../../../../common/form-fields-mui/AButton';
import { useHistory } from 'react-router';



const AddDoDetailsDialog = ({ open, setOpen, applicationId, applicationQuotationDetailsId,isOldQuotation  }) => {
  const handleClose = () => setOpen(false);
  const history = useHistory();
  let isOldQuotationOpen = isOldQuotation?"old":"new"
  const searchQuery = `?applicationId=${applicationId}&applicationQuotationDetailsId=${applicationQuotationDetailsId}&isOldQuotation=${isOldQuotationOpen}`

  return (
    <Dialog onClose={handleClose} open={open} PaperProps={{ sx: { maxWidth: 'unset', borderRadius: '12px' } }}>
      <div className='' style={{ padding: '28px 24px', width: '600px' }}>
        <div className='d-flex justify-content-between align-items-start mb-4'>
          <div className='heading-4 color-black'>Please add DO details to finalize the charges</div>
          <CrossIcon width={24} height={24} onClick={handleClose} />
        </div>
        <AButton
          variant='primary_filled'
          className='btn-left-0'
          onClick={() => history.push(`/admin/doDetails${searchQuery}`)}
        >
          Add DO details
        </AButton>
      </div>
    </Dialog>
  )
}

export default AddDoDetailsDialog