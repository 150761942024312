import React, { useState, useEffect, memo } from 'react';
import { Button, Card, CardBody, CardHeader, Input, Table } from 'reactstrap';
import {
	fetchAllData,
	fetchDetails,
	getDispValForReactSelectByOneID,
} from '../../../common/utils/methods/commonMethods/utilityMethod';
import {
	masterServiceBaseUrl,
	getAPI,
	putpost,
	putpost2,
} from 'services/http';
import { failureToast, successToast} from '../../../common/utils/methods/toasterFunctions/function';
import Select2 from 'react-select2-wrapper';
import CustomButton from "../../../../../../components/CustomButton";
import CommonInput from "../../../common/formFeilds/input/commonInput";

const ViewAndEditTableHeader = (props) => {
	const { action,permissionSet } = props;
	return (
		<thead className="thead-light">
			<tr>
				<th className="text-center ">COURSE ATTRIBUTE</th>
				<th className="text-center ">ATTRIBUTE DESCRIPTION</th>
				<th className="text-center ">ATTRIBUTE VALUE</th>
				<th className="text-center ">SEQUENCE ID</th>
				<th className="text-center ">MINIMUM NUMBER</th>
				<th className="text-center ">MAXIMUM NUMBER</th>
				<th className="text-center ">STATUS</th>
				{action !== 'view' &&
				(permissionSet.includes('U') ||
					permissionSet.includes('D') ||
					permissionSet.includes('C')) ? (
					<th className="text-center ">ACTION</th>
				) : null}
			</tr>
		</thead>
	);
};
const NewOrEditRow = (props) => {
	const {
		index,
		item,
		courseAttri,
		courseAttribute,
		setCourseAttri,
		setAttributeDescription,
		attributeDescription,
		attributeValue,
		courseAttributeValueList,
		setAttributeValue,
		minimumNumber,
		setMinimumNumber,
		setMaximumNumber,
		maximumNumber,
		saveRow,
		cancelRow,
		status,
		setStatus,
		courseId,
		setCourseId,
		setCourseAttributeValueList,
		companyCodeId,
		groupCodeId,
		permissionSet,
		courseAttributeDropDown
	} = props;
	const [tempList, setTempList] = useState([]);
	const getCourseAttributeValue = (id) => {
		try {
			groupCodeId &&
				companyCodeId &&
				id &&
				getAPI(
					masterServiceBaseUrl +
						`/courseAttributeValueMapping/getCourseAttributeValueByCourseAttributeIdAndGroupCodeIdAndCompanyCodeId/?groupCodeid=${groupCodeId}&companyCodeId=${companyCodeId}&courseAttributeId=${id}`,
					(data) => {
						let temp = [];
						data &&
							data.data &&
							data.data.map((item) => {
								if (item.status === 'ACTIVE') {
									temp.push({
										id: item.courseAttributeValueId,
										text: getDispValForReactSelectByOneID(
											item.courseAttributeValueId,
											'DispValue',
											courseAttributeValueList
										),
										desc: getDispValForReactSelectByOneID(
											item.courseAttributeValueId,
											'DescValue',
											courseAttributeValueList
										),
									});
								}
							});
						setTempList(temp);
					},
					(data) => {}
				);
		} catch (e) {
			console.error('Error in getting Course Attribute Value---->', e);
		}
	};
	useEffect(() => {
		if (courseAttri) {
			getCourseAttributeValue(courseAttri);
		}
	}, [courseAttri]);

	return (
		<tr key={'NewOrEditRow'}>
			<td className="text-center p-2 mx-1">
				{/*<Select2*/}
				{/*	defaultValue={courseAttri}*/}
				{/*	data={courseAttribute}*/}
				{/*	disabled={item['isEdit']}*/}
				{/*	className="tdSelect"*/}
				{/*	options={{ placeholder: 'Select Course Attribute' }}*/}
				{/*	onChange={(e) => {*/}
				{/*		let courseIdSelected = e.target.value;*/}
				{/*		setCourseAttri(courseIdSelected);*/}
				{/*		setAttributeDescription(*/}
				{/*			getDispValForReactSelectByOneID(*/}
				{/*				courseIdSelected,*/}
				{/*				'DescValue',*/}
				{/*				courseAttribute*/}
				{/*			)*/}
				{/*		);*/}
				{/*	}}*/}
				{/*/>*/}
				<CommonInput
					forTable={true}
					type={item['isEdit'] ? 'text' : 'reactSelect'}
					data={courseAttribute}
					defaultValue={item['isEdit'] ? getDispValForReactSelectByOneID(courseAttri, 'DispValue', courseAttributeDropDown) :courseAttri}
					isDisabled={item['isEdit']}
					className={"tdSelect"}
					placeHolder={'Select Course Attribute'}
					onChange={(value) => {
						let courseIdSelected = value;
						setCourseAttri(courseIdSelected);
						setAttributeDescription(
							getDispValForReactSelectByOneID(
								courseIdSelected,
								'DescValue',
								courseAttribute
							)
						);
					}}
				/>
			</td>
			<td className="text-center p-2 mx-1">
				<Input
					data-testId="attributeDesc"
					disabled={true}
					placeholder="Attribute Description"
					className="tdSelect"
					Value={attributeDescription}
				/>
			</td>
			<td className="text-center p-2 mx-1">
				{/*<Select2*/}
				{/*	defaultValue={attributeValue}*/}
				{/*	className="tdSelect"*/}
				{/*	// data={ courseAttributeValueList }*/}
				{/*	data={tempList}*/}
				{/*	options={{ placeholder: 'Select Course Attribute Value ' }}*/}
				{/*	disabled={item['isEdit']}*/}
				{/*	onChange={(e) => {*/}
				{/*		console.log('Selected attribute value id--->', e.target.value);*/}
				{/*		setAttributeValue(e.target.value);*/}
				{/*	}}*/}
				{/*/>*/}
				<CommonInput
					forTable={true}
					type={item['isEdit'] ? 'text' : 'reactSelect'}
					data={tempList}
					defaultValue={item['isEdit'] ? getDispValForReactSelectByOneID(attributeValue, 'DispValue', courseAttributeValueList) :attributeValue}
					isDisabled={item['isEdit']}
					className={"tdSelect"}
					placeHolder={'Select Course Attribute Value'}
					onChange={(value) => {
						setAttributeValue(value);
					}}
				/>
			</td>
			<td className="text-center p-2 mx-1">
				{/*<Input*/}
				{/*	data-testId="CourseId"*/}
				{/*	type={'number'}*/}
				{/*	name={'courseId'}*/}
				{/*	placeholder="Course ID"*/}
				{/*	className="tdInput"*/}
				{/*	value={courseId}*/}
				{/*	disabled={item['isEdit']}*/}
				{/*	onChange={(e) => {*/}
				{/*		if (e.target.value.length <= 3) setCourseId(e.target.value);*/}
				{/*	}}*/}
				{/*/>*/}
				<CommonInput
					data-testId="CourseId"
					type={'number'}
					forTable={true}
					placeHolder={'Course ID'}
					className={"tdInput"}
					defaultValue={courseId}
					isDisabled={item['isEdit']}
					onChange={(value) => {
						if (value.length <= 3) {
							setCourseId(value);
							setMinimumNumber(value);
						}
					}}
				/>
			</td>
			<td className="text-center p-2 mx-1">
				{/*<Input*/}
				{/*	data-testId="MinNo"*/}
				{/*	name={'minimumNumber'}*/}
				{/*	disabled={item['isEdit']}*/}
				{/*	key={'testInput2'}*/}
				{/*	type={'number'}*/}
				{/*	placeholder="Enter Minimum Number"*/}
				{/*	className="tdInput"*/}
				{/*	value={minimumNumber}*/}
				{/*	onChange={(e) => {*/}
				{/*		if (e.target.value.length <= 7) setMinimumNumber(e.target.value);*/}
				{/*	}}*/}
				{/*/>*/}
				<CommonInput
					data-testId="MinNo"
					type={'number'}
					forTable={true}
					placeHolder={'Enter Minimum Number'}
					className={"tdInput"}
					defaultValue={minimumNumber}
					isDisabled={item['isEdit'] || !courseId}
					onChange={(value) => {
						if (value.length <= 7) setMinimumNumber(value);
					}}
				/>
			</td>
			<td className="text-center p-2 mx-1">
				<Input
					data-testId="MaxNo"
					defaultValue={maximumNumber}
					disabled
					placeholder="Enter Maximum Number"
					className="tdInput"
					key={'testInput3'}
					onChange={(e) => {
						if (e.target.value.length <= 7) setMaximumNumber(e.target.value);
					}}
				/>
			</td>
			<td className="text-center p-2 mx-1">
				<label className="custom-toggle mx-auto ml-2">
					<input
						data-testId="activeInactiveInput"
						key={'testInput4'}
						checked={status === 'ACTIVE'}
						type="checkbox"
						onChange={(e) => {
							let n = 'ACTIVE';
							if (!e.target.checked) {
								n = 'INACTIVE';
							}
							setStatus(n);
						}}
					/>
					<span
						style={{ width: '72px' }}
						className="custom-toggle-slider rounded-circle activeToggle"
						data-label-off="Inactive"
						data-label-on="Active"
					/>
				</label>
			</td>
			<td className="text-center">
				{/*<Button*/}
				{/*	data-testId="saveBtn"*/}
				{/*	color="info"*/}
				{/*	size="sm"*/}
				{/*	type="button"*/}
				{/*	className="mx-1"*/}
				{/*	onClick={() => saveRow(index)}*/}
				{/*>*/}
				{/*	Save*/}
				{/*</Button>*/}
				<CustomButton
					data-testId="saveBtn"
					className={'mx-1'}
					permissionType={'C,U'}
					content={'Save'}
					forTable={true}
					permissionSet={permissionSet}
					onClick={() => saveRow(index)}
				/>
				{/*<Button*/}
				{/*	data-testId="closeBtn"*/}
				{/*	color="danger"*/}
				{/*	size="sm"*/}
				{/*	type="button"*/}
				{/*	onClick={() => cancelRow(index)}*/}
				{/*>*/}
				{/*	<i className="fas fa-times" />*/}
				{/*</Button>*/}
				<CustomButton
					data-testId="closeBtn"
					permissionType={'cancel'}
					forTable={true}
					icon={true}
					permissionSet={permissionSet}
					onClick={() => cancelRow(index)}
				/>
			</td>
		</tr>
	);
};

const TDROW = (props) => {
	const {
		item,
		index,
		courseAttribute,
		courseAttributeValueList,
		action,
		editRow,
		deleteRow,
		courseAttributeDropDown,
		permissionSet
	} = props;
	return (
		<tr>
			<td className="text-center p-2 mx-1">
				{getDispValForReactSelectByOneID(
					item.courseAttributeId,
					'DispValue',
					courseAttributeDropDown
				)}
			</td>
			<td className="text-center p-2 mx-1">
				{getDispValForReactSelectByOneID(
					item.courseAttributeId,
					'DescValue',
					courseAttributeDropDown
				)}
			</td>
			<td className="text-center p-2 mx-1">
				{getDispValForReactSelectByOneID(
					item.courseAttributeValueId,
					'DispValue',
					courseAttributeValueList
				)}
			</td>
			<td className="text-center p-2 mx-1">{item.courseIdSequence}</td>
			<td className="text-center p-2 mx-1">{item.minimumNumber}</td>
			<td className="text-center p-2 mx-1">{item.maximumNumber}</td>
			<td className="text-center p-2 mx-1">
				<Button
					key={'statusButton'}
					color={item.status === 'ACTIVE' ? 'success' : 'danger'}
					size={'sm'}
					disabled={true}
				>
					{item.status === 'ACTIVE' ? 'Active' : 'Inactive'}
				</Button>
			</td>
			{action !== 'view' && permissionSet && (permissionSet.includes('U') || permissionSet.includes('D')) ? (
				<td className="text-center p-2 mx-1">
					{/*<Button*/}
					{/*	color="info"*/}
					{/*	size="sm"*/}
					{/*	type="button"*/}
					{/*	className={' mx-1'}*/}
					{/*	onClick={() => editRow(index)}*/}
					{/*>*/}
					{/*	<i className="fas fa-edit" />*/}
					{/*</Button>*/}
					<CustomButton
						className={'mx-1'}
						permissionType={'U'}
						icon={true}
						forTable={true}
						permissionSet={permissionSet}
						onClick={() => editRow(index)}
					/>
					{/*<Button*/}
					{/*	color="info"*/}
					{/*	size="sm"*/}
					{/*	type="button"*/}
					{/*	className={' mx-1'}*/}
					{/*	onClick={() => deleteRow(index)}*/}
					{/*>*/}
					{/*	<i className="fas fa-trash" />*/}
					{/*</Button>*/}
					<CustomButton
						className={'mx-1'}
						permissionType={'D'}
						icon={true}
						forTable={true}
						permissionSet={permissionSet}
						onClick={() => deleteRow(index)}
					/>
				</td>
			) : null}
		</tr>
	);
};

// Main Component
const ViewAndEditTable = memo((props) => {
	const {
		id,
		courseDetailData,
		setCourseDetailData,
		courseAttributeValueList,
		courseAttribute,
		courseAttributeDropDown,
		action,
		setCourseAttributeValueList,
		companyCodeId,
		groupCodeId,
		permissionSet
	} = props;
	const [status, setStatus] = useState('ACTIVE');
	const [courseId, setCourseId] = useState(null);
	const [minimumNumber, setMinimumNumber] = useState(null);
	const [maximumNumber, setMaximumNumber] = useState(null);
	const [attributeValue, setAttributeValue] = useState(null);
	const [attributeDescription, setAttributeDescription] = useState(null);
	const [courseAttri, setCourseAttri] = useState(null);
	const [isEdit, setIsEdit] = useState(false);
	const editRow = (index) => {
		try {
			if (isEdit) {
				failureToast('Please save or cancel previous changes');
			} else {
				setIsEdit(true);
				let tempArray = courseDetailData;
				tempArray[index]['isEdit'] = true;
				setCourseAttri(tempArray[index]['courseAttributeId']);
				setAttributeDescription(
					getDispValForReactSelectByOneID(
						tempArray[index]['courseAttributeId'],
						'DescValue',
						courseAttribute
					)
				);
				setAttributeValue(tempArray[index]['courseAttributeValueId']);
				setStatus(tempArray[index]['status']);
				setCourseId(tempArray[index]['courseIdSequence']);
				setMinimumNumber(tempArray[index]['minimumNumber']);
				setMaximumNumber(tempArray[index]['maximumNumber']);
				setCourseDetailData(tempArray);
			}
		} catch (e) {
			console.log('Error in editRow-->', e);
		}
	};
	const resetFields = () => {
		setCourseAttri(null);
		setAttributeValue(null);
		setStatus(null);
		setCourseId(null);
		setMinimumNumber(null);
		setMaximumNumber(null);
	};
	const refreshData = () => {
		try {
			fetchAllData(
				masterServiceBaseUrl +
					'/courseCatalogSequenceDetail/getCourseCatalogSequenceDetailByCourseCatalogSetupId/' +
					id,
				setCourseDetailData
			);
		} catch (e) {
			console.log('Error in refresh data-->', e);
		}
	};
	const cancelRow = (index) => {
		try {
			let tempArray = courseDetailData;
			if (tempArray[index] && tempArray[index]['isEdit']) {
				tempArray[index]['isEdit'] = false;
				setIsEdit(false);
				resetFields();
			} else if (tempArray[index] && tempArray[index]['isNew']) {
				tempArray.splice(index, 1);
				setCourseDetailData(tempArray);
				setIsEdit(false);
				resetFields();
			}
		} catch (e) {
			console.log('Error in cancelRow-->', e);
		}
	};
	const saveRow = (index) => {
		try {
			let tempArray = courseDetailData;
			let isValid = true;
			if (!courseAttri) {
				failureToast('Please Select Course Attribute.');
				isValid = false;
			} else if (!attributeValue) {
				failureToast('Please Select Attribute Value.');
				isValid = false;
			} else if (!courseId) {
				failureToast('Please Enter Course ID.');
				isValid = false;
			} else if (!minimumNumber) {
				failureToast('Please Enter Minimum Number.');
				isValid = false;
			} else if (courseId && minimumNumber && minimumNumber.toString().substring(0,courseId.toString().length) !== courseId.toString()) {
				failureToast('Minimum Number Should Be Started From Sequence ID.');
				isValid = false;
			}
			if (!isValid) {
				return;
			} else {
				if (tempArray[index]['isEdit']) {
					let tempObject = {
						id: tempArray[index]['id'],
						courseAttributeId: courseAttri,
						courseAttributeValueId: attributeValue,
						courseIdSequence: courseId,
						minimumNumber: minimumNumber,
						maximumNumber: maximumNumber,
						status: status,
						courseCatalogSequenceSetupId:
							tempArray[index]['courseCatalogSequenceSetupId'],
					};
					PostData(
						masterServiceBaseUrl +
							'/courseCatalogSequenceDetail/updateCourseCatalogSequenceDetail/' +
							tempArray[index]['id'],
						tempObject,
						'put'
					);
				} else if (tempArray[index]['isNew']) {
					let tempObject = {
						courseAttributeId: courseAttri,
						courseAttributeValueId: attributeValue,
						courseIdSequence: courseId,
						minimumNumber: minimumNumber,
						maximumNumber: maximumNumber,
						status: status,
						courseCatalogSequenceSetupId: id,
					};
					PostData(
						masterServiceBaseUrl +
							'/courseCatalogSequenceDetail/createCourseCatalogSequenceDetail',
						tempObject,
						'post'
					);
				}
			}
		} catch (e) {
			console.log('Error in saveRow', e);
		}
	};
	const PostData = (url, dataObject, type) => {
		putpost(
			url,
			(data) => {
				successToast(data.message);
				setIsEdit(false);
				refreshData();
			},
			(data) => {
				failureToast(data.message);
			},
			dataObject,
			type
		);
	};

	const addNewRow = () => {
		setCourseDetailData([
			{
				courseCatalogSequenceSetupId: null,
				courseAttributeId: null,
				courseAttributeValueId: null,
				courseIdSequence: null,
				minimumNumber: null,
				maximumNumber: null,
				status: 'ACTIVE',
				isNew: true,
				isEdit: false,
			},
			...courseDetailData,
		]);
	};

	const newRow = () => {
		let cb = () => {
			addNewRow();
		};
		setIsEdit(true);
		setCourseAttri('');
		setAttributeDescription(null);
		setAttributeValue(null);
		setCourseId(null);
		setMinimumNumber('');
		setStatus('ACTIVE');
		setMaximumNumber(null);
		cb();
	};
	const deleteRow = (index) => {
		if (isEdit) {
			failureToast('Please Delete or Save current changes');
			return;
		}
		let tempArray = courseDetailData;
		let id = tempArray[index]['id'];
		if (window.confirm('Are you sure you want to delete this Item Type?')) {
			putpost2(
				masterServiceBaseUrl +
					'/courseCatalogSequenceDetail/deleteCourseCatalogSequenceDetail/' +
					id,
				(data) => {
					successToast(data.message);
					refreshData();
				},
				(data) => {
					failureToast(data.message);
				},
				'Delete'
			);
		}
	};
	return (
		<>
			<Card
				key={'SetupBUTable'}
				style={{
					marginBottom: '12px',
					marginLeft: '20px',
					marginRight: '20px',
				}}
			>
				<CardHeader>
					<h2 className="mb-0 floatLeft">Course Detail</h2>
					{action !== 'view' ? (
						// <Button
						// 	id="buttonToggler"
						// 	disabled={isEdit}
						// 	color="info"
						// 	size="sm"
						// 	type="button"
						// 	className={'floatRight mx-1' + (isEdit ? ' btn-dark' : null)}
						// 	onClick={() => {
						// 		newRow();
						// 	}}
						// >
						// 	Add New Course
						// </Button>
						<CustomButton
							data-testId="buttonToggler"
							disabled={isEdit}
							className={'floatRight mx-1' + (isEdit ? ' btn-dark' : null)}
							content={'Add New Course'}
							permissionType={'C'}
							icon={true}
							permissionSet={permissionSet}
							onClick={() => newRow()}
						/>
					) : null}
				</CardHeader>
				<div id="questionTable" className="table-responsive">
					<Table className="align-items-center table-flush ">
						<ViewAndEditTableHeader action={action} permissionSet={permissionSet}/>
						<tbody>
							{courseDetailData &&
								courseDetailData.map((item, index) =>
									item['isEdit'] || item['isNew'] ? (
										<NewOrEditRow
											companyCodeId={companyCodeId}
											groupCodeId={groupCodeId}
											setCourseAttributeValueList={setCourseAttributeValueList}
											setStatus={setStatus}
											courseId={courseId}
											setCourseId={setCourseId}
											status={status}
											cancelRow={cancelRow}
											saveRow={saveRow}
											maximumNumber={maximumNumber}
											setMaximumNumber={setMaximumNumber}
											setMinimumNumber={setMinimumNumber}
											minimumNumber={minimumNumber}
											setAttributeValue={setAttributeValue}
											courseAttributeValueList={courseAttributeValueList}
											attributeDescription={attributeDescription}
											attributeValue={attributeValue}
											setAttributeDescription={setAttributeDescription}
											setCourseAttri={setCourseAttri}
											courseAttribute={courseAttribute}
											courseAttri={courseAttri}
											index={index}
											item={item}
											courseAttributeDropDown={courseAttributeDropDown}
											permissionSet={permissionSet}
										/>
									) : (
										<TDROW
											courseAttributeDropDown={courseAttributeDropDown}
											deleteRow={deleteRow}
											action={action}
											courseAttributeValueList={courseAttributeValueList}
											courseAttribute={courseAttribute}
											editRow={editRow}
											item={item}
											index={index}
											permissionSet={permissionSet}
										/>
									)
								)}
						</tbody>
					</Table>
				</div>
			</Card>
		</>
	);
});
export default ViewAndEditTable;
