import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom" 
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from "react-toastify";
import FloorTable from "./floorTable";
import CustomLoader from "../../../common/commonComponents/Loader/loader";
import { masterServiceBaseUrl, getAPI } from "services/http";
import { Container, Button } from "reactstrap";
import { GetPagination } from "../../../common/commonComponents/pagination/pagination";
import { updateData } from '../../../../../../redux/globalData/globalDataAction';
import HeaderInfo from '../header/headerInfo'
import { getBusinessAreas } from 'views/pages/manage/edpOps/constant/batch-search';
import { failureToast } from "../../../common/utils/methods/toasterFunctions/function";

const Floor = (props) => {

  const { id } = props.match.params;
  const dispatch = useDispatch();
  const buildingHeraderData =  useSelector(state => state.globalData.data) 
  const [businessArea, setBusinessArea]  = useState('')
  // console.log(businessArea);
  // console.log(buildingHeraderData);
  const [classArray, setclassArray] = useState([]);
  const [apiloader, setapiloader] = useState(false);
  const [state, setstate] = useState([]);
  const [serverData, setServerData] = useState([]);
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const pageSize = 10;

  const fetchClasses = async (url) => {
    setapiloader(true);
    getAPI(
      url,
      (data) => {
        setstate(data["data"]);
        setServerData(data["data"]);
        setPagination(data["data"]);
      },
      (data) => {
        failureToast(data["message"]);
        setapiloader(false);
      }
    );
  };

  const setPagination = (paramsdata) => {
    let n = pagination;
    if (paramsdata.length > pageSize) {
      n["nextPage"] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n["totalPage"] = Math.floor(paramsdata.length / pageSize);
    if (paramsdata.length % pageSize != 0) {
      n["totalPage"] += 1;
    }
    setpagination(n);
    setclassArray(
      paramsdata.slice(
        pagination.currentPage * pageSize - pageSize,
        pagination.currentPage * pageSize
      ) || []
    );
    setapiloader(false);
  };

  useEffect(() => {
    id && fetchClasses(`${masterServiceBaseUrl}/floorMaster/getFloorMasterByBuildingMasterId/${id}`);
  }, [id]);

  useEffect(()=>{
    
    if(buildingHeraderData.regionId){
        
      getBusinessAreas([{value:buildingHeraderData.regionId}])
      .then(res=>{
         if(res){
            
           let {businessAreaId}  = buildingHeraderData
           let currentBusinessArea = res.filter(item=>item.value ===businessAreaId ) ;
             if(currentBusinessArea[0])
             setBusinessArea(currentBusinessArea[0]?.label)
         }
    })
     }

  },[buildingHeraderData.regionId])
  const setHeaderDataForRoom = (data) => {
    dispatch(
      updateData({
          ...buildingHeraderData,
          ...data,
          'Business Area':businessArea
      }))
  }

  return (
    <>
      {apiloader ? (
        <div
          className="mx-auto text-center py-5 my-5"
          style={{ height: "100vh" }}
        >
          <CustomLoader apiLoader={apiloader} />
        </div>
      ) : (
        <>
        <HeaderInfo data = {{...buildingHeraderData, 'Business Area':businessArea}} isFloor = {true} onBack={()=>props.history.push("/admin/buildingmaster")}/>
          <FloorTable
            fetchClasses={fetchClasses}
            setclassArray={setclassArray}
            classArray={classArray}
            isSaveVisible={isSaveVisible}
            setisSaveVisible={setisSaveVisible}
            serverData={serverData}
            buildingMasterId = { id }
            setHeaderDataForRoom={setHeaderDataForRoom}
            buildingHeraderData= {{...buildingHeraderData, 'Business Area':businessArea}}
          />
          {pagination.totalPage > 1 ? (
            <GetPagination
              isSaveVisible={isSaveVisible}
              fetchClasses={fetchClasses}
              setclassArray={setclassArray}
              pagination={pagination}
              setpagination={setpagination}
              pageSize={pageSize}
              state={state}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default withRouter(Floor);
