import React, { useState } from 'react'
import { withRouter } from "react-router-dom";
import './_index.scss'

const SessionInfo = (props) => {
    const [seconds, setSeconds] = React.useState(5);

    React.useEffect(() => {
      if (seconds > 0) {
        setTimeout(() => setSeconds(seconds - 1), 1000);
      } else {
        props.history.push("/")
      }
    });
    return (
        <div className="session_expire_info_page d-flex justify-content-center align-items-center">
            <div className="inner-container d-flex justify-content-center align-items-center flex-column">
                <h1>Your session has expired!</h1>
                <h5><span onClick={()=>props.history.push("/")}>Click Here</span> to go back to Login Page.</h5>
                <h5>{`You will be automatically redirected after ${seconds} seconds...`}</h5>
            </div>
        </div>
    )
}

export default withRouter(SessionInfo)
