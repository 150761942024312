import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import AAutoComplete from "../../common/form-fields-mui/AAutoComplete";
import AButton from "../../common/form-fields-mui/AButton";
import AMultiSelect from "../../common/form-fields-mui/AMultiSelect";

export const modalStyle = {
  position: "absolute" /* Stay in place */,
  zIndex: "75" /* Sit on top */,
  top: "2rem",
  right: "5rem",
  width: "60%" /* Full width */,
  height: "100%" /* Full height */,
};

export const RightSideFilterForm = ({
  formModal,
  inputHandler,
  applyFilterHandler,
  removeFilterHandler,
  isShowRemoveFilter,
  disabled,
  businessAreaData = () => {},
  regionData,
  academicGroupData,
  academicCareerData,
  subjectData,
  setFilterForm = () => {}
}) => {
  const columnSize = 12;

  return (
    <div >
      <Card style={{ width: "300px", marginBottom: "0px" }}>
        <CardBody style={{ paddingTop: ".5rem", paddingBottom: ".5rem" }}>
          <h4 style={{ color: "black" }}> Filter </h4>
          <Row>
            <Col md={columnSize}>
              {/* <AMultiSelect
                label="Region"
                currentValue={formModal.region}
                items={regionData.data}
                inputKey="region"
                handleChange={inputHandler}
                disabled={disabled}
              /> */}
              <AAutoComplete
                label='Region'
                currentValue={formModal.region}
                items={regionData.data}
                handleChange={(value) => {setFilterForm({...formModal,region : value, businessArea : []})}}
                isShowCount={true}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col md={columnSize}>
              {/* <AMultiSelect
                label="Business area"
                currentValue={formModal.businessArea}
                items={businessAreaData.data}
                inputKey="businessArea"
                handleChange={inputHandler}
                disabled={disabled}
              /> */}
              <AAutoComplete
                label='Business area'
                currentValue={formModal.businessArea}
                items={businessAreaData()}
                handleChange={(value) => inputHandler(value, "businessArea")}
                isShowCount={true}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col md={columnSize}>
              {/* <AMultiSelect
                label="Academic career"
                currentValue={formModal.academicCareer}
                items={academicCareerData.data}
                inputKey="academicCareer"
                handleChange={inputHandler}
                disabled={disabled}
              /> */}
              <AAutoComplete
                label='Academic career'
                currentValue={formModal.academicCareer}
                items={academicCareerData.data}
                handleChange={(value) => inputHandler(value, "academicCareer")}
                isShowCount={true}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col md={columnSize}>
              {/* <AMultiSelect
                label="Aacademic Group"
                currentValue={formModal.academicGroup}
                items={academicGroupData.data}
                inputKey="academicGroup"
                handleChange={inputHandler}
                disabled={disabled}
              /> */}
               <AAutoComplete
                label='Aacademic Group'
                currentValue={formModal.academicGroup}
                items={academicGroupData.data}
                handleChange={(value) => inputHandler(value, "academicGroup")}
                isShowCount={true}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col md={columnSize}>
              {/* <AMultiSelect
                label="Subject"
                currentValue={formModal.subject}
                items={subjectData.data}
                inputKey="subject"
                handleChange={inputHandler}
                disabled={disabled}
              /> */}
              <AAutoComplete
                label='Subject'
                currentValue={formModal.subject}
                items={subjectData.data}
                handleChange={(value) => inputHandler(value, "subject")}
                isShowCount={true}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col md="6" className="pl-1">
              {isShowRemoveFilter && (
                <AButton
                  onClick={removeFilterHandler}
                  updatedStyle={{
                    border: "1px solid #CC5F5F",
                    borderRadius: "8px",
                    color: "#CC5F5F",
                    margin: ".4rem"
                  }}
                >
                  Remove Filter
                </AButton>
              )}
            </Col>
            <Col md="6" style={{ display: "flex", justifyContent: "flex-end" }}>
              <AButton
                onClick={applyFilterHandler}
                updatedStyle={{
                  background: "#00B0F5",
                  border: "1px solid #00B0F5",
                  borderRadius: "8px",
                  color: "#fff",
                  margin: ".4rem"

                }}
                disabled={disabled}
              >
                Apply Filter
              </AButton>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
