import {Button, Col, FormGroup,  Row} from "reactstrap";
import React from "react";
import moment from "moment";
import {useHistory} from "react-router-dom";
const CommonLogDetails = (props)=>{
    const {formData,logDetails,action='view',closeHandler=()=>{}} = props;
    return (
        <div>
            <Row style={{ fontSize: "15px" }} >
                <Col md={5}>
                    <Row className="pl-5 pr-5">
                        <Col md={12}>
                            <FormGroup row>
                                <Col md={5}>
                                    <label className="form-control-label">Created Date </label>
                                </Col>
                                <Col md={7}>
                                    : <label className="pl-3">{logDetails ? moment(logDetails['updatedOn']).format('DD/MM/YYYY') : 'NA'}</label>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col md={5}>
                    <Row  className="pl-5 pr-5">
                        <Col md={12}>
                            <FormGroup row>
                                <Col md={5}>
                                    <label className="form-control-label">Last Updated Date</label>
                                </Col>
                                <Col md={7}>
                                    : <label className="pl-3">{logDetails ? moment(logDetails['updatedOn']).format('DD/MM/YYYY') : 'NA'}</label>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col md={2}>
                    <Button color="danger" size="sm" className="floatRight mx-1" onClick={closeHandler}>
                        Close
                    </Button>
                </Col>
            </Row>
            <Row style={{fontSize: "15px"}}>
                <Col md={5}>
                    <Row className="pl-5 pr-5">
                        <Col md={12}>
                            <FormGroup row>
                                <Col md={5}>
                                    <label className="form-control-label">Created By </label>
                                </Col>
                                <Col md={7}>
                                    : <label className="pl-3">{logDetails && logDetails['createdBy'] ? logDetails['createdBy'] :'NA'}</label>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col md={5}>
                    <Row  className="pl-5 pr-5">
                        <Col md={12}>
                            <FormGroup row>
                                <Col md={5}>
                                    <label className="form-control-label">Last Updated By</label>
                                </Col>
                                <Col md={7}>
                                    : <label className="pl-3">{logDetails && logDetails['updatedBy'] ? logDetails['updatedBy'] : 'NA'}</label>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default CommonLogDetails;