export const UPDATE_DROPDOWNS = 'UPDATE_DROPDOWNS'
export const REQUEST_DROPDOWNS = 'REQUEST_DROPDOWNS'


// academic plans listing page action types from here
export const ACADEMIC_PLAN_LIST_REQUEST = 'ACADEMIC_PLAN_LIST_REQUEST'
export const ACADEMIC_PLAN_LIST_SUCCESS = 'ACADEMIC_PLAN_LIST_SUCCESS'
export const ACADEMIC_PLAN_LIST_FAILURE = 'ACADEMIC_PLAN_LIST_FAILURE'
export const ACADEMIC_PLAN_LIST_CLEAR = 'ACADEMIC_PLAN_LIST_CLEAR'
export const SEARCH_ACADEMIC_PLAN_DROPDOWNS_REQUEST = 'SEARCH_ACADEMIC_PLAN_DROPDOWNS_REQUEST'
export const SEARCH_ACADEMIC_PLAN_DROPDOWNS_SUCCESS = 'SEARCH_ACADEMIC_PLAN_DROPDOWNS_SUCCESS'
export const SEARCH_ACADEMIC_PLAN_DROPDOWNS_FAILURE = 'SEARCH_ACADEMIC_PLAN_DROPDOWNS_FAILURE'