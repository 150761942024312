import React, { useState } from 'react';
import { AiOutlineCopy, AiFillCopy } from 'react-icons/ai';
import CopyToClipboard from 'react-copy-to-clipboard';

const ACopyToClipboard = ({preCopyComp = null, postCopyComp = null , text, color='#00B0F5', size=18, onCopy = () => {} }) => {

  const [isCopied, setIsCopied] = useState(false);

  return (
    isCopied ? (postCopyComp ?? <AiFillCopy size={size} color={color} /> ) :
    (<CopyToClipboard
      text={text}
      onCopy={() => {
        onCopy();
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1000);
      }}
    >
        {preCopyComp ?? <AiOutlineCopy size={size} color={color} />}
    </CopyToClipboard>)
  )
}

export default ACopyToClipboard;