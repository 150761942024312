import React from 'react';
import Style from './logout.module.scss';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Dialog } from 'views/pages/manage/common/commonComponents';
const LogoutModel = ({ isLogout, logout, setIsLogout }) => {
  return (
    <Modal isOpen={isLogout} className={Style.mode_container} centered>
      <ModalBody className={Style.body_content}>
        <div className={Style.icon}>?</div>
        <p className={Style.confirmation_message}>Are you sure?</p>
        <p className={Style.signOut_message}>You want to sign out.</p>
        <div>
          <Button className={Style.btn_yes} onClick={() => logout()}>
            YES
          </Button>
          <Button className={Style.btn_no} onClick={() => setIsLogout(false)}>
            NO
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default LogoutModel;
