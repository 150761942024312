import React, { useState } from "react";
import AButton from "../../../../common/form-fields-mui/AButton";
import { Dialog } from "@mui/material";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
const headingStyle = {
  fontWeight: 700,
  fontSize: "22px",
  lineHeight: "24px",
  color: "#000000",
  marginBottom: "1rem",
};
const materialChipStyle = {
  border: "2px solid #545454",
  minHeight: "44px",
  display: "flex",
  borderRadius: "8px",
  padding: "4px 10px",
  fontSize: "16px",
  maxWidth: "361px",
  color: "#545454",
};
const activeChipStyle = {
  background: "#333333",
  color: "#FFFFFF",
}

const SelectTShirtSizeMaterialDialog = ({
  open,
  issueStockCheckBoxHandler,
  selectedMaterials,
  setTShirtSelection,
  tShirtMaterialSelection
}) => {
  const [selectedTShirtMaterial, setSelectedTShirtMaterial] =
    useState(undefined);
    
  const handleCancel = () => {
    setTShirtSelection({isOpen:false})
  };
  
  const applyHandler = ()=>{
    const {
      isChecked,
      student,
      isAll,
    }  = tShirtMaterialSelection?.nextFnParams ;
    issueStockCheckBoxHandler(isChecked, student , isAll, selectedTShirtMaterial)
    setTShirtSelection(prev =>({...prev, isOpen:false}))
  }



  return (
    <Dialog onClose={handleCancel} open={open}>
      <div style={{ padding: "28px 24px", width: "536px" }}>
        <div className="d-flex justify-content-between align-items-start mb-3">
          <span style={headingStyle}>Multiple T-shirt size found</span>
          <CrossIcon width={32} height={32} onClick={handleCancel} />
        </div>
        <>
          <div className="text-bold  heading-3 mb-3">
            Select one size for all students
          </div>
          <section>
            {selectedMaterials
              .filter((item) => item?.isTShirtGroupMaterial)
              .map((item) => (
                <div
                  key={item?.materialId}
                  style={{
                    ...materialChipStyle,
                    ...(selectedTShirtMaterial?.materialId === item.materialId
                      ? activeChipStyle
                      : {}),
                  }}
                  className="cursor mb-3"
                  onClick={() => setSelectedTShirtMaterial(item)}
                >{`${item?.materialId} | ${item?.materialDescription}`}</div>
              ))}
          </section>
        </>
        <div className="d-flex justify-content-between mb-4">
          <AButton
            variant="primary_filled"
            className="button_remove_left_margin"
            disabled={!selectedTShirtMaterial}
            onClick={applyHandler}
          >
            Apply
          </AButton>
        </div>
        <span className="text-bold"> Note:</span> You will still have option to
        update T-shirt size against a PSID under “Material to be issued” column.
      </div>
    </Dialog>
  );
};

export default SelectTShirtSizeMaterialDialog;
