import React from 'react';
import ReactDOM from 'react-dom';
// react library for routing
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
// test
// bootstrap rtl for rtl support page
import 'assets/vendor/bootstrap-rtl/bootstrap-rtl.scss';
// plugins styles from node_modules
import 'react-notification-alert/dist/animate.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
// plugins styles downloaded
import 'assets/vendor/fullcalendar/dist/fullcalendar.min.css';
import 'assets/vendor/sweetalert2/dist/sweetalert2.min.css';
import 'assets/vendor/select2/dist/css/select2.min.css';
import 'assets/vendor/quill/dist/quill.core.css';
import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import 'react-toastify/dist/ReactToastify.css';
// core styles
import 'assets/scss/argon-dashboard-pro-react.scss?v1.1.0';
import './style.scss';
import AdminLayout from 'layouts/Admin.js';
import AuthLayout from 'layouts/Auth.js';
import store from './redux/store';
import { Provider } from 'react-redux';
import { SomethingWentWrongProvider } from './contexts/SomethingWentWrong';
import { pdfjs } from 'react-pdf';
import { ThemeProvider, createTheme } from '@mui/material';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

// import {UserProvider} from "./contexts/User";
// import { getCookie } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';

// import ConfigRefresh from './views/pages/manage/admin/configRefresh'

ReactDOM.render(
  <ThemeProvider
    theme={createTheme({
      typography: {
        fontFamily: 'inherit',
      },
    })}
  >
    <Provider store={store}>
      <SomethingWentWrongProvider>
        <HashRouter>
          <Switch>
            <Route path='/admin' render={props => <AdminLayout {...props} />} />
            <Route path='/' render={props => <AuthLayout {...props} />} />
            <Redirect from='*' to='/' />
          </Switch>
        </HashRouter>
      </SomethingWentWrongProvider>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);
