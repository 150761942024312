import React, {useEffect} from 'react';
import {Button,Col,FormGroup, Input, Label, Row} from "reactstrap";
import Select2 from "react-select2-wrapper";
import {masterServiceBaseUrl, examControllerServiceBaseUrl, failureToast, getAPI} from "../../../../../../../services/http";
import {fetchData, getExamDates} from "../applicationIdFunctions";
import {RedAstric} from "../../../../examService/examIdGeneration/examIdSearchForm";
import {fetchDetails} from "../../../../common/utils/methods/commonMethods/utilityMethod";
import ButtonWithLoader from "../../../../common/commonComponents/buttonWithLoader";

const ExamDetailsForm =(props)=>{
    const {action,isEdit,isLoading,formData,setCourseIds,UpdateFormData,term,examIds,submitHandler,examDate, businessArea,courseIds,setExamDate, setLoader,setTerm,viewExamDetails,label,placeholder} = props;
    return(
        <div className='container-fluid'>
            <Row>
                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.businessArea} <RedAstric/></Label>
                        <Select2 options={{placeholder: placeholder.businessArea,}}
                                data={businessArea}
                                     disabled={action !=='new'}
                                     defaultValue={formData['businessArea']}
                                onChange={(e) => {
                                    if(e.target.value) {
                                        UpdateFormData(3, 'businessArea', parseInt(e.target.value));
                                        UpdateFormData(3, 'examCenterBusinessArea', parseInt(e.target.value));
                                        if(e.target.value && formData.term !== '' && action ==='new') {
                                            setCourseIds([])
                                            fetchData(masterServiceBaseUrl + `/courseCatalogMapping/getAllCourseIdWithCRTYAndAttributeValueMAINAndADDC/${e.target.value}/${formData.term}?isForExam=true`, setCourseIds, 'courseId')
                                        }
                                        if(formData['term'] !=='' && formData['examId'] !=='' && action === 'new'){
                                            // UpdateFormData(3, 'examDate','');
                                            UpdateFormData(3, 'courseId', '');
                                            UpdateFormData(3, 'examCourseName', '');
                                            setExamDate([])
                                            getExamDates(examControllerServiceBaseUrl+`/examDateDetails/getExamDateDetailByBusinessAreaTermAndExamId?businessAreaId=${e.target.value}&termId=${formData['term']}&examId=${formData['examId']}`,setExamDate)
                                        }
                                    }
                                }}
                            />

                    </FormGroup>
                </Col>

                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.examId} <RedAstric/></Label>
                        <Select2 options={{placeholder: placeholder.examId,}}
                                 data={examIds}
                                 disabled={action !=='new'}
                                 defaultValue={formData['examId']}
                                 onChange={(e) => {
                                     if(e.target.value) {
                                         UpdateFormData(3, 'examId', parseInt(e.target.value));
                                         // UpdateFormData(3, 'examDate','');
                                         setExamDate([])

                                         fetchDetails(examControllerServiceBaseUrl + `/examDateMapping/findTermIdsMappingWithBusinessAreaAndExamIdAndActive?businessArea=${formData['businessArea']}&examId=${e.target.value}`, setTerm, 'term', 'reactSelect');
                                         // if(formData[])
                                         // getExamDates(examControllerServiceBaseUrl+`/examDateDetails/getExamDateDetailByBusinessAreaTermAndExamId?businessAreaId=${formData['businessArea']}&termId=${formData['termId']}&examId=${e.target.value}`,setExamDate)
                                         // getExamDates(examControllerServiceBaseUrl+`/examDateDetails/getExamDateDetailByBusinessAreaTermAndExamId?businessAreaId=${formData['businessArea']}&termId=${formData['term']}&examId=${e.target.value}`,setExamDate)
                                         // getExamDates(examControllerServiceBaseUrl+`/examDateDetails/getExamDateDetailByBusinessAreaTermAndExamId?businessAreaId=57&termId=3&examId=1664655`,setExamDate)
                                     }
                                 }}
                        />
                    </FormGroup>
                </Col>

                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.examTerm} <RedAstric/></Label>
                        <Select2 options={{placeholder: placeholder.examTerm,}} data={term}
                                     disabled={action !=='new'}
                                     defaultValue={formData['term']}
                                     onChange={(e) => {
                                        if(e.target.value) {
                                            UpdateFormData(3, 'term', parseInt(e.target.value));
                                            if(e.target.value && formData.businessArea !== '' && action ==='new') {
                                                setCourseIds([])
                                                UpdateFormData(3, 'courseId', '');
                                                UpdateFormData(3, 'examCourseName', '');
                                                fetchData(masterServiceBaseUrl + `/courseCatalogMapping/getAllCourseIdWithCRTYAndAttributeValueMAINAndADDC/${formData.businessArea}/${e.target.value}?isForExam=true`, setCourseIds, 'courseId')
                                            }
                                            if(formData['businessArea'] !=='' && formData['examId'] !==''){
                                                // UpdateFormData(3, 'examDate','');
                                                setExamDate([]);

                                                // getExamDates(examControllerServiceBaseUrl+`/examDateDetails/getExamDateDetailByBusinessAreaTermAndExamId?businessAreaId=${formData['businessArea']}&termId=${e.target.value}&examId=${formData['examId']}`,setExamDate)
                                                getExamDates(examControllerServiceBaseUrl+`/examDateDetails/getExamDateDetailByBusinessAreaTermAndExamId?businessAreaId=${formData['businessArea']}&termId=${e.target.value}&examId=${formData['examId']}`,setExamDate)
                                            }
                                        }
                                    }}
                            />

                    </FormGroup>
                </Col>

                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.examDate} <RedAstric/></Label>
                        <Select2 options={{placeholder: placeholder.examDate,}}
                                 data={examDate}
                                 disabled={action !=='new'}
                                 defaultValue={formData['examDate']}
                                 onChange={(e) => {
                                     if(e.target.value) {
                                         UpdateFormData(3, 'examDate',parseInt(e.target.value));
                                     }
                                 }}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.examCourseId} <RedAstric/></Label>
                        <Select2 options={{placeholder: placeholder.examCourseId,}} data={courseIds}
                                disabled={ (formData['businessArea'] === '' || formData['term'] ==='') || action !== 'new'}
                                defaultValue={formData['courseId']}
                                onChange={(e) => {
                                    if(e.target.value) {
                                        UpdateFormData(3, 'courseId', parseInt(e.target.value));
                                        setLoader(true)
                                        // console.log('Check Value---->',e.target.value);
                                        getAPI(masterServiceBaseUrl+`/courseDetail/getCourseDetailByCourseId/${e.target.value}`,
                                            (data)=>{
                                                if(data.data && data.data.length > 0){
                                                    UpdateFormData(3, 'examCourseName', data.data[0]['courseName']);
                                                    setLoader(false)
                                                }
                                            },(data)=>{
                                                setLoader(false)
                                                failureToast(data.message);
                                            }
                                        )
                                    }
                                }}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.examCourseName} <RedAstric/></Label>
                        <Input disabled={true} className="tdSelect" placeholder={placeholder.examCourseName} value={ formData['examCourseName']}/>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.examCenter} <RedAstric/> </Label>
                        <Select2 options={{placeholder: placeholder.examCenter,}}
                            data={businessArea}
                                 disabled={action === 'view'}
                                 defaultValue={formData['examCenterBusinessArea']}
                                 onChange={(e) => {
                                     if(e.target.value) {
                                         UpdateFormData(3, 'examCenterBusinessArea', parseInt(e.target.value));
                                     }
                                 }}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.examRollNumber} <RedAstric/></Label>
                        <Input disabled={action !=='new'} type={'text'
                        } className="tdSelect" placeholder={placeholder.examRollNumber} value={ formData['rollNumber']} onChange={(e) => UpdateFormData(3, 'rollNumber', e.target.value.replace(/[^a-zA-Z0-9]/g, ""))}/>
                    </FormGroup>
                </Col>
            </Row>
            {
                action ==='new'  || action === 'edit'
                ?
                (
                    <Row>
                        <Col xs={12}>
                            <Button color="info" size="sm" className="floatRight mx-1" disabled={true}>
                                Pay Exam Fee
                            </Button>
                            {
                                action === 'new' ?
                                <ButtonWithLoader loading={isLoading} color="info" size="sm" className="floatRight mx-1"
                                         onClick={() => submitHandler('exam')}>
                                    Submit
                                </ButtonWithLoader>
                                    :
                                    null
                            }
                        </Col>
                    </Row>
                )
                :
                action === 'view' && isEdit
                ?
                    <Row>
                        <Col xs={12}>
                            <Button color="info" size="sm" className="floatRight mx-1" disabled={true}>
                                Pay Exam Fee
                            </Button>
                        </Col>
                    </Row>
                    :
                    null
            }
        </div>
    )
}
export default ExamDetailsForm;
