import React, { useEffect, useState, useContext } from 'react';
import { Card, CardBody, Col, Row } from "reactstrap";
import AAutoComplete from "../../../common/form-fields-mui/AAutoComplete";
import AInput from "views/pages/manage/common/formFeilds/customInput/AInput";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import PhoneNoDetailsForm from "./PhoneNoDetailsForm";
import { labelStyle, selectStyles } from './LocationDetailsForm';
import { ReactComponent as ActiveControl } from "../../../../../../assets/img/svg/ActiveControl.svg";
import { ReactComponent as InactiveControl } from "../../../../../../assets/img/svg/InactiveControl.svg";
// import { endpoint } from 'views/pages/manage/common/constant';
import { endpoint, pages } from '../../../common/constant';
import { fetchAllPostPromisedData } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { successToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import moment from 'moment';
import { failureToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import CustomLoader from 'views/pages/manage/common/commonComponents/Loader/loader';
import GrievanceInfoForm from './GrievanceInfoForm';
import CommonInput from '../../../common/formFeilds/input/commonInput';
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { timepickerStyle } from '../../../academicPlanAndTest/academicTimetable/lectureModule/AddLectureModalForm';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { PermissionContext, RolePermissions } from "../../../../../../appContext";
import { checkPermission } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { PermisionDenied } from "../../../common/commonComponents";


var _ = require('lodash');

const grievanceLevels = ["L1", "L2", "L3"];

const weekdays = [
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
    { label: "Sunday", value: "Sunday" }
];

const ContactDetailsForm = ({ selectedTabValue, buId, action, contactDetails, fetchLocationAndContactDetails = () => { }, contactDetailsFetching }) => {

    const EMPTY_FORM = {
        id: '',
        contactName: '',
        emailId: '',
        urlAddress: '',
        status: 'ACTIVE',
        phoneNumbers: [{}],
        startTime: '',
        endTime: '',
        weekOffDays: [],
        grievanceInfo: {
            "L1": {},
            "L2": {},
            "L3": {}
        }
    }

    const permissions = useContext(PermissionContext);
    const userPermissions = RolePermissions(permissions, pages['businessAreaContactDetails']['id']);
    const isUpdatePermission = checkPermission(userPermissions, 'U');
    const isCreatePermission = checkPermission(userPermissions, 'C');

    const [form, setForm] = useState(EMPTY_FORM);
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    const [inValidDataInfo, setInValidDataInfo] = useState({});


    const formHandler = (value, key) => {
        setForm(prev => ({ ...prev, [key]: value }));
    }

    const removePhoneNoHandler = (index) => {
        console.log('phoneNoHandlerCalled', index);
        setForm(prev => {
            const phoneNumbers = [...prev.phoneNumbers];
            phoneNumbers.splice(index, 1);
            return {
                ...prev,
                phoneNumbers: phoneNumbers
            };
        })
    }

    const addNumberClickHandler = () => {
        setForm(prev => ({ ...prev, phoneNumbers: [...prev.phoneNumbers, {}] }))
    }

    const phoneNoChangeHandler = (index, value, key) => {
        setForm(prev => {
            const phoneNumbers = [...prev.phoneNumbers];
            phoneNumbers[index][key] = value;
            return ({
                ...prev,
                phoneNumbers: phoneNumbers
            })
        })
    }

    const grievanceInfoChangeHandler = (level, value, key) => {

        setForm(prev => {
            const grievanceObj = { ...prev?.grievanceInfo[level] }
            grievanceObj[key] = value;
            return {
                ...prev,
                grievanceInfo: {
                    ...prev.grievanceInfo,
                    [level]: grievanceObj
                }
            }
        })
    }

    useEffect(() => {

        if (action === 'edit' || action === 'view') {
            setIsFormDisabled(true);
            setInValidDataInfo({});
            const {
                contactId,
                contactName,
                emailId,
                urlAddress,
                status,
                phoneNumber,
                startTime,
                endTime,
                weekOffDays,
                grievanceMappingDTOList
            } = contactDetails ?? {};

            setForm({
                ...EMPTY_FORM,
                id: contactId,
                contactName,
                emailId,
                urlAddress,
                status,
                startTime,
                endTime,
                phoneNumbers: phoneNumber?.phoneNumberList?.map(item => ({ ...item, type: { label: item.type, value: item.type } })) ?? [],
                weekOffDays: weekOffDays?.split(',')?.map(el => ({ label: el, value: el })) ?? [],
                grievanceInfo: getGrievanceInfoObj(grievanceMappingDTOList ?? [])
            })
        }

    }, [contactDetails])


    const getGrievanceInfoArr = () => {
        const grievanceInfoFormObj = form?.grievanceInfo;
        const arr = Object.keys(grievanceInfoFormObj).map(level => ({
            grievanceType: level,
            ...grievanceInfoFormObj[level]
        }))

        return arr;
    }

    const getGrievanceInfoObj = (grievanceMappingDTOList) => {
        const obj = EMPTY_FORM?.grievanceInfo;
        grievanceMappingDTOList.forEach(el => {
            obj[el?.grievanceType] = el;
        })
        return obj;
    }

    const saveHandler = async () => {
        const employeeIdRegex = new RegExp(/^AESL-[AF]\d{1,6}$/);
        const emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
        const mobNoRegex = new RegExp(/^\d{10}$/);
        const stateCodeAndExtensionRegex = new RegExp(/^\d{6}$/);

        if (!employeeIdRegex.test(form?.id.trim())) {
            failureToast("Please Enter Valid Employee ID!");
            setInValidDataInfo({ field: 'id' })
            return;
        }

        if (!emailRegex.test(form.emailId.trim())) {
            failureToast("Please Enter Valid Email ID!");
            setInValidDataInfo({ field: 'emailId' })
            return;
        }

        for (let i = 0; i < form.phoneNumbers.length; i++) {

            const phoneNoDetails = form.phoneNumbers[i];
            const stateCode = phoneNoDetails?.code ?? '';
            const phoneNo = phoneNoDetails?.number;
            const extension = phoneNoDetails?.extension ?? '';

            if (stateCode.trim().length > 0 && !stateCodeAndExtensionRegex.test(stateCode)) {
                setInValidDataInfo({ idx: i, field: 'code' });
                failureToast("Please Enter Valid State code!");
                return;
            }
            if (!mobNoRegex.test(phoneNo)) {
                setInValidDataInfo({ idx: i, field: 'number' });
                failureToast("Please Enter Valid Phone no!");
                return;
            }
            if (extension.trim().length > 0 && !stateCodeAndExtensionRegex.test(extension)) {
                setInValidDataInfo({ idx: i, field: 'extension' });
                failureToast("Please Enter Valid Extension!");
                return;
            }

        }

        let isGrievanceInfoInvalid = false;

        Object.keys(form?.grievanceInfo).forEach(level => {
            const grievanceObj = form?.grievanceInfo[level];
            const emailId = grievanceObj?.emailId;
            const contactNumber = grievanceObj?.contactNumber;

            if (emailId && !emailRegex.test(emailId?.trim())) {
                failureToast("Please Enter Valid Email ID!");
                setInValidDataInfo({ level: level, field: 'emailId' })
                isGrievanceInfoInvalid = true;
                return;
            }
            if (contactNumber && !mobNoRegex.test(contactNumber?.trim())) {
                failureToast("Please Enter Valid Contact Number!");
                setInValidDataInfo({ level: level, field: 'contactNumber' });
                isGrievanceInfoInvalid = true;
                return;
            }


        })

        if (isGrievanceInfoInvalid) return;


        setInValidDataInfo({})

        const reqObj = {
            contactName: form?.contactName,
            contactId: form?.id,
            businessAreaId: buId,
            status: form?.status || 'ACTIVE',
            emailId: form?.emailId,
            urlAddress: form?.urlAddress,
            phoneNumber: {
                phoneNumberList: form?.phoneNumbers.map(item => ({
                    ...item,
                    type: item.type.value,
                    code: item?.code ?? '',
                    extension: item?.extension ?? ''
                }))
            },
            startTime: form?.startTime,
            endTime: form?.endTime,
            weekOffDays: form?.weekOffDays?.map(el => el?.value).join(","),
            grievanceMappingDTOList: getGrievanceInfoArr()

        }

        let url = endpoint.contactDetails.create;
        let method = 'post';
        if (contactDetails?.id) {
            url = `${endpoint.contactDetails.update}/${contactDetails?.id}`;
            method = 'put'
        }

        const resp = await fetchAllPostPromisedData(url, reqObj, method);
        if (resp?.code === 200) {
            fetchLocationAndContactDetails();
            successToast('Request completed successfully!');
            // setIsFormDisabled(true);
        }
    }

    function allMandatoryFieldsEntered() {

        const hasEmptyField = (field) => !(_.isObject(field) ? !!field?.value?.trim() : !!field?.trim());

        const topLevelMandatoryFields = ['id', 'contactName', 'emailId','startTime', 'endTime'];
        if (topLevelMandatoryFields.some(field => hasEmptyField(form[field]))) {
            return false;
        }

        // if (form?.weekOffDays?.length === 0) return false;

        const phoneNumberMandatoryFields = ['type', 'number'];
        if (form.phoneNumbers.some(phoneNumber => phoneNumberMandatoryFields.some(field => hasEmptyField(phoneNumber[field])))) {
            return false;
        }

        let grievanceInfoMandatoryFieldsEntered = true

        Object.keys(form?.grievanceInfo).forEach(level => {
            const grievanceObj = form?.grievanceInfo[level];
            if (!grievanceObj?.roleName?.trim()) {
                grievanceInfoMandatoryFieldsEntered = false;
                return false;
            }

        })

        if (!grievanceInfoMandatoryFieldsEntered) return false;




        return true;
    }

    if (selectedTabValue !== 'three') return null;

    return (

        userPermissions ? 

        <LocalizationProvider dateAdapter={AdapterMoment}>
            <div className='w-100' onClick={() => setInValidDataInfo({})}>
                {contactDetailsFetching ? <CustomLoader /> :

                    <>

                        <div className='d-flex justify-content-end'>
                            <h4 className='mr-2'>Status : </h4>
                            {form?.status === "ACTIVE" ?
                                <span onClick={() => !isFormDisabled && formHandler("INACTIVE", "status")}><ActiveControl /> Active</span> :
                                <span onClick={() => !isFormDisabled && formHandler("ACTIVE", "status")}><InactiveControl /> Inactive</span>
                            }
                        </div>


                        <Row>
                            <Col md="6">
                                <AInput
                                    label="Employee ID*"
                                    labelStyle={labelStyle}
                                    style={{
                                        ...selectStyles.control,
                                        borderColor: '#CFD3D4',
                                        ...(inValidDataInfo?.field === 'id') && ({ borderColor: '#B32306' })
                                    }}
                                    placeholder="Enter ID"
                                    handler={val => formHandler(val, 'id')}
                                    value={form.id}
                                    disabled={isFormDisabled}
                                />
                            </Col>
                            <Col md="6">
                                <AInput
                                    label="Contact Name*"
                                    labelStyle={labelStyle}
                                    style={selectStyles.control}
                                    placeholder="Enter Contact Name"
                                    handler={val => formHandler(val, 'contactName')}
                                    value={form.contactName}
                                    disabled={isFormDisabled}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6">
                                <AInput
                                    label="Email ID*"
                                    labelStyle={labelStyle}
                                    style={{
                                        ...selectStyles.control,
                                        borderColor: '#CFD3D4',
                                        ...(inValidDataInfo?.field === 'emailId') && ({ borderColor: '#B32306' })
                                    }}
                                    placeholder="Enter Email ID"
                                    handler={val => formHandler(val, 'emailId')}
                                    value={form.emailId}
                                    disabled={isFormDisabled}
                                />
                            </Col>
                            <Col md="6">
                                <AInput
                                    label="URL address"
                                    labelStyle={labelStyle}
                                    style={selectStyles.control}
                                    placeholder="Enter URL address"
                                    handler={val => formHandler(val, 'urlAddress')}
                                    value={form.urlAddress}
                                    disabled={isFormDisabled}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6">
                                <div className='mt-2 mb-1' style={labelStyle}>Opening hours-Start time*</div>
                                <DesktopTimePicker
                                    ampm={false}
                                    value={
                                        form?.startTime ? moment(form?.startTime, "HH:mm") : null
                                    }
                                    onChange={(value) =>
                                        formHandler(moment(value)?.format("HH:mm"), "startTime")
                                    }
                                    disabled={isFormDisabled}
                                    sx={{
                                        ...timepickerStyle,
                                        width: '100%',
                                        //   backgroundColor: isFormDisabled ? "#EDEDED !important" : "#FFFFFF",
                                    }}
                                />

                            </Col>

                            <Col md="6">
                                <div className='mt-2 mb-1' style={labelStyle}>Closing hours- End time*</div>
                                <DesktopTimePicker
                                    ampm={false}
                                    value={
                                        form?.endTime ? moment(form?.endTime, "HH:mm") : null
                                    }
                                    onChange={(value) =>
                                        formHandler(moment(value)?.format("HH:mm"), "endTime")
                                    }
                                    disabled={isFormDisabled}
                                    sx={{
                                        ...timepickerStyle,
                                        width: '100%',
                                        // backgroundColor: isFormDisabled ? "#EDEDED !important" : "#FFFFFF",
                                        // "& :disabled": { backgroundColor: '#EDEDED' }
                                    }}
                                />

                            </Col>
                        </Row>

                        <Row>
                            <Col md="6">
                                <AAutoComplete
                                    label='Week Off'
                                    labelStyle={labelStyle}
                                    style={selectStyles}
                                    items={weekdays}
                                    handleChange={val => formHandler(val, 'weekOffDays')}
                                    currentValue={form.weekOffDays}
                                    isShowCount={true}
                                    isDisabled={isFormDisabled}
                                />
                            </Col>
                        </Row>

                        {form.phoneNumbers?.map((item, index) =>
                            <PhoneNoDetailsForm
                                key={index}
                                index={index}
                                inValidDataInfo={inValidDataInfo}
                                item={item}
                                removePhoneNoHandler={removePhoneNoHandler}
                                phoneNoChangeHandler={phoneNoChangeHandler}
                                isFormDisabled={isFormDisabled}
                            />
                        )}
                        {!isFormDisabled && <AButton
                            variant="outline"
                            onClick={addNumberClickHandler}
                            className="mt-3 no-ml-btn"
                        >
                            Add Number
                        </AButton>}

                        {grievanceLevels.map(level =>
                            <GrievanceInfoForm
                                level={level}
                                formObj={form.grievanceInfo[level]}
                                changeHandler={grievanceInfoChangeHandler}
                                inValidDataInfo={inValidDataInfo}
                                isFormDisabled={isFormDisabled}
                            />
                        )}


                        <Row className="m-2 d-flex justify-content-between">

                            {((action === 'view' || action === 'edit') && contactDetails)
                                ? <h4 className="mt-2">Last Updated by  {contactDetails?.updatedBy}  at {moment(contactDetails?.updatedOn).format("hh:mm a")} on {moment(contactDetails?.updatedOn).format('DD/MM/YYYY')}</h4>
                                : <div></div>
                            }

                            <div>
                                {(isFormDisabled && action === 'edit') &&
                                    <AButton
                                        variant="primary_filled"
                                        onClick={() => setIsFormDisabled(false)}
                                        disabled={!isUpdatePermission}
                                    >Update
                                    </AButton>
                                }

                                {!isFormDisabled &&
                                    <>
                                        <AButton
                                            variant="outline"
                                            onClick={() => setForm(EMPTY_FORM)}
                                            disabled={(action === 'new' && !isCreatePermission)}
                                        >
                                            Reset
                                        </AButton>

                                        <AButton
                                            variant="primary_filled"
                                            onClick={e => {
                                                e.stopPropagation();
                                                saveHandler();
                                            }}
                                            disabled={(action === 'new' && !isCreatePermission) || !allMandatoryFieldsEntered()}
                                        >
                                            Save
                                        </AButton>
                                    </>
                                }
                            </div>
                        </Row>
                    </>

                }

            </div>
        </LocalizationProvider>

        : <PermisionDenied/>

    );
}

export default ContactDetailsForm;