import React, { useState, useEffect } from "react";
import { Box, Typography, Link } from "@mui/material";
import { Col, Row } from "reactstrap";
import { ReactComponent as ClickArrowIcon } from 'assets/img/svg/ClickArrow.svg';
import { ReactComponent as HeaderIcon } from 'assets/img/svg/DepositHeadingIcon.svg';
import CustomButton from 'components/CustomButton';
import SearchInput from '../studentCharges/SearchInput';

const DepositSlip = () => {
    return(
        <>
        <Box className="hd-header">
            <Typography variant="h5">Search business area to view, edit or add new account details</Typography>
            <Box class="search-container">
                    <input 
                        type="text" 
                        class="search-input" 
                        placeholder="Search by business area code or name" 
                        onChange={() =>{ }}
                        style={{paddingLeft:'8px', borderRadius:'8px'}}
                        /> 
                </Box>
            <CustomButton
                className='approval-dialog-action-button mt-20 mb-10 document-review-approve-button'
                content={'Go to bank account'}  
                permissionType={'C'}
                permissionSet={'C'}
                onClick={() => { }}
             />
        </Box>
        <Box className='hd-container'>
            <Row>
                <Col md={6}>
                    <Box className='hd-card-wrapper'>
                    <HeaderIcon style={{ float:'right', marginTop:'4px' }}/>
                        <Typography
                            variant="h6"
                            className='card-title'>
                            House bank master
                        </Typography>
                        <Typography variant="body2">Return issued stock that has been issued</Typography>
                       
                        <Box className='card-link'>
                            <Link
                                href="/#/admin/depositSlip/houseBankMaster"
                                underline="none">
                                 Click here
                                <ClickArrowIcon style={{marginLeft:'10px'}}/>
                            </Link> 
                        </Box>
                    </Box>
                </Col>
                <Col md={6}>
                    <Box className='hd-card-wrapper'>
                    <HeaderIcon style={{ float:'right', marginTop:'4px' }}/>
                        <Typography
                            variant="h6"
                            className='card-title'>
                            Deposit slip set-up
                        </Typography>
                        <Typography variant="body2">
                            View transaction history of all issue
                        </Typography>
                        <Box style={{ marginTop: "20px" }}>
                            <Link
                                href="/#/admin/depositSlip/slipDetailForm"
                                underline="none"
                                style={{fontWeight: '700',
                                        fontSize: '18px',    
                                        color:'#00B0F5',
                                    }}
                            >
                                Click here
                                <ClickArrowIcon style={{marginLeft:'10px'}}/>
                            </Link>
                        </Box>
                    </Box>
                </Col>
            </Row>
        </Box>
    </>
    )   
}

export default DepositSlip;