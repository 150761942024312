import React, { useState } from "react";
import { FormGroup, Label, Input, UncontrolledTooltip } from "reactstrap";
import { BiFilterAlt } from "react-icons/bi";
import { AiFillCloseCircle } from "react-icons/ai";
import { FaSort } from "react-icons/fa";
import moment from "moment";
import { getRequiredSign } from "../../utils/methods/commonMethods/utilityMethod";
import CustmCheckbox from '../../../common/formFeilds/customCheckbox'
import "./masterHeader.scss";

const FilterColunm = ({ filterFun = () => { }, filterVal }) => {
  return (
    <div className="filterContainer">
      <FormGroup className="mb-2 me-sm-2 mb-sm-0 filter-input">
        {/* <Label className="me-sm-2" for="exampleEmail">
          Filter
        </Label> */}
        <Input
          value={filterVal}
          id="sub_sub_topic_search_str"
          name="searchStr"
          placeholder="Search"
          type="email"
          className="filterInput"
          onChange={(e) => filterFun(e.target.value)}
        />
        {filterVal && (
          <AiFillCloseCircle
            size={25}
            className="close-icon"
            onClick={() => {
              filterFun("");
            }}
          />
        )}
      </FormGroup>
    </div>
  );
};

export const MasterHeaderAuto = ({ headerList = [], permissionSet, isSaveVisible, isSelectAllAllowed = false, isAllSelected = false,
  handleSelectAll = () => {}, isAnyDeselected, disabled }) => {
  const [isFilterVisible, setFilterVisible] = useState(false);

  return (
    <thead className="thead-light">

      <tr>
        {isSelectAllAllowed && <th style={{
          padding: "12px 4px "
        }} className="text-uppercase text-center white-breakSpace select-all-filter">
          <CustmCheckbox onClick={(e) => { handleSelectAll(e) }} onChangeHandler={() => { }} defaultChecked={isAllSelected && !isAnyDeselected ? true : false} forTable={true} isDisabled={disabled}/>
        </th>}
        {headerList.map((headerItem, index) => {
          if ((headerItem.name === 'Action' || headerItem.name === 'ACTION') && permissionSet) {
            if (permissionSet && (permissionSet.includes('D') || permissionSet.includes('U')) || (permissionSet.includes('C') && isSaveVisible)) {
              return <th
                style={{
                  padding: "12px 4px ",
                  width: `${headerItem.hasOwnProperty("width") ? headerItem.width : ""
                    }`,
                }}
                className="text-uppercase text-center white-breakSpace column-heading"
                rowSpan={headerItem.rowSpan || "1"}
                colSpan={headerItem.colSpan || "1"}
                key={headerItem.name + index}
              >
                {headerItem.name}</th>
            }
            return null
          }
          return (
            <th
              style={{
                padding: "10px 4px ",
                width: `${headerItem.hasOwnProperty("width") ? headerItem.width : ""
                  }`,
              }}
              className="text-uppercase text-center white-breakSpace column-heading"
              rowSpan={headerItem.rowSpan || "1"}
              colSpan={headerItem.colSpan || "1"}
              key={headerItem.name + index}
            >
              {headerItem.name}
              {headerItem.isRequired && getRequiredSign()}
              {headerItem.filterable ? (
                <>
                  <BiFilterAlt
                    id="filter_icon_header"
                    size={15}
                    className="ml-3 filter-icon"
                    onClick={() => setFilterVisible(!isFilterVisible)}
                  />
                  <UncontrolledTooltip delay={0} placement="top" target={"filter_icon_header"} hideArrow={true} style={{ maxWidth: 'max-content', marginLeft: "100px", marginTop: '-60px' }}>
                    Filter
                  </UncontrolledTooltip>
                </>
              ) : null}
              {headerItem.isSortable && headerItem.keyName ? (
                <FaSort
                  size={15}
                  className="ml-3 filter-icon"
                  onClick={
                    !headerItem?.isSaveVisible
                      ? () => headerItem.sortFun(headerItem.keyName)
                      : null
                  }
                />
              ) : null}
            </th>
          );
        })}
      </tr>
      {isFilterVisible && (
        <tr>
          {headerList.map((headerItem, index) => {
            return (
              <th
                style={{ padding: "12px 4px " }}
                className="text-uppercase text-center white-breakSpace"
                rowSpan={headerItem.rowSpan || "1"}
                colSpan={headerItem.colSpan || "1"}
                key={headerItem.name + index}
              >
                {headerItem.filterable ? (
                  <FilterColunm
                    filterFun={headerItem.filterFun}
                    filterVal={headerItem.filterVal}
                  />
                ) : null}
              </th>
            );
          })}
        </tr>
      )}
    </thead>
  );
};

export const MasterHeader = (props) => {
  return (
    <thead className="thead-light">
      <tr>
        <th className="text-center text-uppercase">Course Attribute</th>
        <th className="text-center text-uppercase">Attribute description</th>
        <th className="text-center text-uppercase">Attribute value</th>
        <th className="text-center text-uppercase">Academic Career</th>
        <th className="text-center text-uppercase">Term</th>
        <th className="text-center text-uppercase">Update date</th>
        <th className="text-center text-uppercase">Status</th>
        <th className="text-center text-uppercase">Add Approvals</th>
        {!props.isDisabled ? (
          <th className="text-center text-uppercase">Action</th>
        ) : null}
      </tr>
    </thead>
  );
};

export const PinLabHeader = (props) => {
  return (
    <thead className="thead-light">
      <tr>
        <th className="text-center text-uppercase">EMPLOYEE ID</th>
        <th className="text-center text-uppercase">EMPLOYEE NAME</th>
        <th className="text-center text-uppercase">POS NAME</th>
        <th className="text-center text-uppercase">IMEI</th>
        <th className="text-center text-uppercase">MACHINE TYPE</th>
        <th className="text-center text-uppercase">UPDATED DATE</th>
        <th className="text-center text-uppercase">STATUS</th>
        {!props.isDisabled ? (
          <th className="text-center text-uppercase">ACTION</th>
        ) : null}
      </tr>
    </thead>
  );
};

export const Rows = ({
  children,
  data,
  functionItemsFromList = [],
  listArray = [],
  listToBeRendered = [],
  businessAreaData,
  termData,
  ismodifiedList = false,
}) => {
  const getNameById = (id, list) => {
    const item =
      list &&
      list.filter((item) => {
        return item && item.id === id;
      });
    const name = item && item.length ? item[0].text : "Not Found";
    return name;
  };

  const getTermDisplayValue = (value) => {
    return (
      termData &&
      termData.length > 0 &&
      termData.find((item) => item.id === value) &&
      termData.find((item) => item.id === value).termDispValue
    );
  };

  let groupCodeId =
    businessAreaData &&
    businessAreaData.find((item) => item.id === data.businessAreaId) &&
    businessAreaData.find((item) => item.id === data.businessAreaId)
      .groupCodeId;

  let companyCodeId =
    businessAreaData &&
    businessAreaData.find((item) => item.id === data.businessAreaId) &&
    businessAreaData.find((item) => item.id === data.businessAreaId)
      .companyCodeId;

  return (
    <tr>
      {data &&
        listToBeRendered.map((item, i) => {
          if (item === "courseAttributeDesc") {
            return (
              <td className="text-center  white-breakSpace">
                {getNameById(
                  data["courseAttributeId"],
                  listArray[functionItemsFromList.indexOf(item)]
                )}
              </td>
            );
          } else if (item === "groupCodeFromBusinessArea") {
            return (
              <td className="text-center  white-breakSpace">
                {getNameById(
                  groupCodeId,
                  listArray[functionItemsFromList.indexOf(item)]
                )}
              </td>
            );
          } else if (item === "termId") {
            if (ismodifiedList) {
              return (
                <td className="text-center  white-breakSpace">
                  {getNameById(
                    data["termId"],
                    listArray[functionItemsFromList.indexOf(item)]
                  )}
                </td>
              );
            } else {
              return (
                <td className="text-center  white-breakSpace">
                  {getTermDisplayValue(data["termId"])}
                </td>
              );
            }
          } else if (item === "companyCodeFromBusinessArea") {
            return (
              <td className="text-center  white-breakSpace">
                {getNameById(
                  companyCodeId,
                  listArray[functionItemsFromList.indexOf(item)]
                )}
              </td>
            );
          } else if (functionItemsFromList.indexOf(item) > -1) {
            return (
              <td className="text-center  white-breakSpace">
                {getNameById(
                  data[item],
                  listArray[functionItemsFromList.indexOf(item)]
                )}
              </td>
            );
          } else if (item === "updatedOn" || item === "effectiveDate") {
            return (
              <td className="text-center">
                {moment(data[item]).format("DD-MM-YYYY")}
              </td>
            );
          } else if (item === "courseStartDate" || item === "courseEndDate") {
            return (
              <td className="text-center  white-breakSpace">
                {moment(data[item]).format("DD-MM-YYYY")}
              </td>
            );
          } else if (item === "percentageFrom" || item === "percentageTo") {
            return (
              <td className="text-center  white-breakSpace">{data[item]}%</td>
            );
          } else if (item === "status") {
            return (
              <td className="text-center  white-breakSpace">
                {data["status"] === "INACTIVE" ? (
                  <button
                    disabled={true}
                    type="button"
                    className="btn btn-sm btn-warning"
                  >
                    Inactive
                  </button>
                ) : (
                  <button
                    type="button"
                    disabled={true}
                    className="btn btn-sm btn-success"
                  >
                    Active
                  </button>
                )}
              </td>
            );
          } else if (item === "feeStatus") {
            return (
              <td className="text-center  white-breakSpace">
                {data["status"]}
              </td>
            );
          } else {
            return (
              <td className="text-center  white-breakSpace" key={i}>
                {data[item]}
              </td>
            );
          }
        })}
      {data ? children : null}
    </tr>
  );
};
