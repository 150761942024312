import React, { useState } from 'react'
import { ImCross } from 'react-icons/im';
// import { ReactComponent as Search } from "../../../../../../assets/img/svg/SearchIcon.svg";
import {ReactComponent as Search} from "../../../../assets/img/svg/SearchIcon.svg";
// import './report-style.scss';
const crossIconStyle = {
  fontSize: "11px",
  position: "absolute",
  top: "0.6rem",
  right: "0.7rem",
  cursor: "pointer",
}
const errorStyle = {
  position: "absolute",
  left: "0px",
  top: "30px",
  fontSize: "12px",
  color: "red",
  opacity: ".8"

}
const ReportSearchInput = ({
   searchInputValueHandler,
   placeHolder, 
   onCancel, 
   setTable1Loading,
   searchInputValue,
   setSearchInputValue,
   setFilterForm,
   setChannel
  }) => {
  // const [isError, setError] = useState(false);
  const clickHandler = (e) => {
    if(searchInputValue.length!==0){ searchInputValueHandler(searchInputValue);
    setTable1Loading(true);
    setChannel('');
    }
  }
  const onChangeHandler = (value) => {
    setSearchInputValue(value)
    setFilterForm({
      startDate: '',
      endDate: '',
      channelName: '',

    })
    // if(value.trim().length>=4){
    //  setError(false)
    //  }else{
    //   setError(true)
    //  }
  }
  // const onBlurHandler = ()=>{
  //   if(reportName.length === 0){
  //     setError(false)
  //   }
  //  }

  return (
    <div style={{ position: "relative" }} >
      <span className='search-input-wrapper'> <Search onClick={clickHandler} /></span>
      <input
        className='search-input'
        id="searchInputValue"
        value={searchInputValue}
        placeholder={placeHolder}
        onChange={(e) => {
          onChangeHandler(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter' || e.keyCode === 13) {
            clickHandler()
          } else if (
            !/^[0-9]*$/.test(
              String.fromCharCode(e.charCode)
            )
          ) {
            e.preventDefault();
            return;
          }
        }}
        // onBlur = {onBlurHandler}
        style={{ minWidth: "15rem", border: "1px solid #00aeef" }}
      />
      {/* {isError && <span style={errorStyle}>At least 4 characters are required</span>} */}
      {searchInputValue.length > 0 &&
        <ImCross onClick={() => {
          setSearchInputValue('');
          onCancel();
        }} style={crossIconStyle} />
      }
    </div>

  );
}

export default ReportSearchInput;