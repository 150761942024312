import React from 'react';
import {Button} from 'reactstrap';
import {openInNewTab} from '../../common/utils/methods/commonMethods/utilityMethod';
const CustomButton = ({details, rowDetails}) => {

  return (
    <Button
      color={details.color}
      onClick={()=>{
        if(details.openInTab){
          let url = details.url;
          if(details.queryParams && details.queryParams.length > 0){
           details.queryParams.forEach((item,index)=>{
             if(item.value){
               if(index === 0){
                 url=  url+`?${item.name+'='+item.id}`;
               }else{
                 url=  url+`&${item.name+'='+rowDetails[item.id]}`;
               }
             }else{
               if(index === 0){
                 url=  url+`?${item.name+'='+item.id}`;
               }else{
                 url=  url+`&${item.name+'='+rowDetails[item.id]}`;
               }
             }
           })
          }
         return  openInNewTab(url)
        }else{
         return  details.url
        }
      }}
    >
      {details.name}
    </Button>
  )
}

export default CustomButton;
