import React from "react";
import AsyncSelect from "react-select/async";
import { components } from "react-select";

// const NoOptionsMessage = props => {
//   return (
//     <components.NoOptionsMessage {...props}>
//       <span className="custom-css-class">{"Enter Atleast 3 Words To Search"}</span> 
//     </components.NoOptionsMessage>
//   );
// };

/* Please take refrence from below code for promise resolver 

  const filterOption = (inputValue) => {
      return filteredArray
    };
    
  const promiseOptions = inputValue =>
      new Promise(resolve => {
        setTimeout(() => {
          resolve(filterColors(inputValue));
      }, 1000);
  });
*/

// Required props
/*
onChangeHandler
promiseResolver
defaultOptions (if any)
value
*/

const AsyncSelectSearch = (props) => {
  const { value = null, onChangeHandler = () => {}, promiseHandler = () => {}, isDisabled = false,placeholder="Select..."} = props;
  return (
    <AsyncSelect loadOptions={promiseHandler} value={value} onChange={onChangeHandler} cacheOptions={true} isDisabled={isDisabled} placeholder={placeholder} />
  );
};

export default AsyncSelectSearch;
