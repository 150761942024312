import React from "react";
import { Tooltip } from "@mui/material";
import { ReactComponent as IoEyeOutline } from "assets/img/svg/fi_info.svg";
import { AGridColDef } from "../../../../common/data-grid/ADataGridColDef";
import AButton from "../../../../common/form-fields-mui/AButton";
import { getFormattedDate } from "../../../constant/batch-search";
import AInput from "../../../../common/formFeilds/customInput/AInput";
import { ReactComponent as EditIcon } from "assets/img/svg/Edit2.svg";
import CustomChip from "views/pages/manage/common/customChip/CustomChip";
import { ViewSchedularDetails } from "./TableHelperComponent";
import moment from "moment";
import MarkEligibleCourseDetailsPopup from "../markEligible/MarkEligibleCourseDetailsPopup";
import { CourseDetails } from "../../selectMeterialAndStock/issueStock/TableColumn";
import CustomColoredChip from "views/pages/manage/common/customChip/CustomChip";
import {
  getChipBgColor,
  getChipTxtColor,
} from "../../selectMeterialAndStock/constant";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx";

export const statusChip = {
  STOCK_ISSUED: {
    text: "Stock issued",
    textColor: "#006A93",
    bgColor: "#CCEFFD",
  },
  FAILED: { text: "Failed", textColor: "#B32306", bgColor: "#F9D9D7" },
  ON_HOLD: {
    text: "On-hold",
    textColor: "#705010",
    bgColor: "#FFEDC8",
  },
  DISPATCH_PENDING: {
    text: "Dispatch pending",
    textColor: "#705010",
    bgColor: "#FFEDC8",
  },
  RETURNED: {
    text: "Returned",
    textColor: "#705010",
    bgColor: "#FFEDC8",
  },
  DISPATCHED: {
    text: "Dispatched",
    textColor: "#006A93",
    bgColor: "#CCEFFD",
  },
  DELIVERED: {
    text: "Delivered",
    textColor: "#0B442D",
    bgColor: "#BBE2CE",
  },
  IN_TRANSIT: {
    text: "In transit",
    textColor: "#705010",
    bgColor: "#FFEDC8",
  },
  RE_DISPATCHED: {
    text: "Re-dispatched",
    textColor: "#705010",
    bgColor: "#FFEDC8",
  },
};

const COMMON_HEADER_COLUMN = [
  new AGridColDef("psid", "PSID")
    .setMinWidth(120)
    .setFlex(0.5)
    .renderCellComponent((params) => (
      <Tooltip title={params?.value}>
        <div>{params?.value}</div>
      </Tooltip>
    )),
  new AGridColDef("applicationId", "Application no.")
    .setMinWidth(120)
    .setFlex(0.5)
    .renderCellComponent((params) => (
      <>
        <Tooltip title={params?.value}>
          <div className={`ellipsis-text-container`}>{params?.value}</div>
        </Tooltip>
        {/* <CourseDetails courseId={params.row?.courseId} /> */}
        <MarkEligibleCourseDetailsPopup applicationData={params.row} />
      </>
    )),
  new AGridColDef("studentName", "Student name")
    .setMinWidth(120)
    // .setFlex(0.5)
    .renderCellComponent((params) => (
      <Tooltip title={params?.value}>
        <div className={`ellipsis-text-container`}>{params?.value}</div>
      </Tooltip>
    )),
  new AGridColDef("dispatchScheduleId", "Materials to be issued")
    .setMinWidth(185)
    .setFlex(0.5)
    .renderCellComponent((params) => (
      <div className="d-flex">
        {params?.row?.dispatchId}
        <span className="color-primary ml-1">
          <ViewSchedularDetails
            dispatchId={params.row.dispatchId}
            dispatchScheduleId={params?.value}
            businessAreaId={params.row.businessArea}
            psid={params?.row?.psid}
          />
        </span>
      </div>
    )),
];

export const getViewBarcodeColumns = (dispatchId) => {
  return [
    new AGridColDef("dispatchId", "Dispatch ID")
      .setMinWidth(120)
      .setFlex(0.5)
      .renderCellComponent((params) => (
        <Tooltip title={dispatchId}>
          <div>{dispatchId}</div>
        </Tooltip>
      )),
    new AGridColDef("materialNumber", "Materials to be issued")
      .setMinWidth(120)
      .setFlex(0.5)
      .renderCellComponent((params) => (
        <>
          <Tooltip title={`${params?.value} || ${params?.row?.materialDesc}`}>
            <div className={`ellipsis-text-container`}>
              {`${params?.value} || ${params?.row?.materialDesc}`}
            </div>
          </Tooltip>
        </>
      )),
    new AGridColDef("barcode", "Barcode number")
      .setMinWidth(120)
      .setFlex(0.5)
      .renderCellComponent((params) => (
        <Tooltip title={params?.value}>
          <div className={`ellipsis-text-container`}>{params?.value}</div>
        </Tooltip>
      )),
  ];
};

export const pendingDispatchTableColumn = ({
  addBarcodeHandler,
  addDispatchDetailsHandler,
  viewBarcodeHandler,
}) => [
  ...COMMON_HEADER_COLUMN,
  new AGridColDef("createdOn", "Eligible marked on")
    .setMinWidth(140)
    .renderCellComponent((params) => (
      <div>{getFormattedDate(params?.value)}</div>
    )),
  new AGridColDef("status", "Status")
    .setMinWidth(170)
    .renderCellComponent((params) => {
      const statusChipColor = statusChip[params.value];
      return (
        <CustomChip
          bgColor={statusChipColor?.["bgColor"]}
          textColor={statusChipColor?.["textColor"]}
          text={statusChipColor?.["text"]}
        >
          {params.value}
        </CustomChip>
      );
    }),
  new AGridColDef("barcodeAdd", "Barcode", false)
    .setMinWidth(100)
    .renderCellComponent((params) =>
      params?.row?.status === "STOCK_ISSUED" ? (
        <div
          onClick={() => viewBarcodeHandler(params?.row)}
          className="pointer text-bold cursor"
        >
          View
        </div>
      ) : (
        <AButton
          className="button_remove_left_margin"
          size="xs"
          variant="primary_filled"
          params={params}
          onClick={() => addBarcodeHandler(params?.row)}
        >
          Add
        </AButton>
      )
    ),
  new AGridColDef("id", "Dispatch details", false)
    .setMinWidth(120)
    .renderCellComponent((params) => (
      <AButton
        onClick={() =>
          addDispatchDetailsHandler(params?.value, {
            ...params?.row,
            nextStatus: "DISPATCHED",
          })
        }
        className="button_remove_left_margin"
        size="xs"
        variant="primary_filled"
        params={params}
        disabled={params?.row?.status !== "STOCK_ISSUED"}
      >
        Add
      </AButton>
    )),
];

export const dispatchedTableColumn = ({
  viewBarcodeHandler,
  addDispatchDetailsHandler,
  updateStatusHandler,
}) => [
  ...COMMON_HEADER_COLUMN,
  new AGridColDef("barcodeAdd", "Barcode", false)
    .setMinWidth(70)
    .setFlex(0.5)
    .renderCellComponent((params) => (
      <div
        className="color-primary text-bold cursor"
        onClick={() => viewBarcodeHandler(params?.row)}
      >
        View
      </div>
    )),
  new AGridColDef("id", "Dispatch details", false)
    .setMinWidth(100)
    .setFlex(0.5)
    .renderCellComponent((params) => (
      <div
        className="color-primary text-bold cursor "
        onClick={() => addDispatchDetailsHandler(params?.row, params?.row)}
      >
        View
      </div>
    )),
  new AGridColDef("updatedOn", "Last update on/by", false)
    .setMinWidth(150)
    .setFlex(0.5)
    .renderCellComponent((params) => (
      <div className="">
        {moment(params?.value)?.format("DD-MMM-YYYY")}
        <Tooltip
          title={`${moment(params?.value)?.format("DD-MMM-YYYY, hh:mm A")} by ${
            params?.row?.updatedBy
          }`}
        >
          <span>
            <IoEyeOutline className="ml-2 pointer" size={18} />
          </span>
        </Tooltip>
      </div>
    )),

  new AGridColDef("status", "Current status", false)
    .setMinWidth(230)
    .renderCellComponent((params) => {
      const statusChipColor = statusChip[params.value];

      return (
        <div>
          <CustomChip
            bgColor={statusChipColor?.["bgColor"]}
            textColor={statusChipColor?.["textColor"]}
            text={statusChipColor?.["text"]}
            style={{ width: "125px" }}
          >
            {params.value}
          </CustomChip>
          {params?.row?.status.toLowerCase() !== "delivered" && (
            <span
              onClick={() => updateStatusHandler(params?.row)}
              className="color-primary ml-2 text-bold cursor"
            >
              Update
            </span>
          )}
        </div>
      );
    }),
];

export const onHoldTableColumn = ({ unholdHandler }) => [
  ...COMMON_HEADER_COLUMN,
  new AGridColDef("status", "Current status", false)
    .setMinWidth(230)
    .renderCellComponent((params) => {
      const statusChipColor = statusChip[params.value];

      return (
        <div>
          <CustomChip
            bgColor={statusChipColor?.["bgColor"]}
            textColor={statusChipColor?.["textColor"]}
            text={statusChipColor?.["text"]}
            style={{ width: "125px" }}
          >
            {params.value}
          </CustomChip>
          <span
            onClick={() => unholdHandler(params?.row?.id, { status: "UNHOLD" })}
            className="color-primary ml-2 text-bold cursor"
          >
            Unhold
          </span>
        </div>
      );
    }),

  new AGridColDef("onHoldReason", "Reason")
    .setMinWidth(200)
    .setFlex(0.5)
    .renderCellComponent((params) => (
      <Tooltip title={params?.value}>
        <div className={`ellipsis-text-container`}>{params?.value}</div>
      </Tooltip>
    )),
  new AGridColDef("updatedOn", "Last update on/by", false)
    .setMinWidth(150)
    .setFlex(0.5)
    .renderCellComponent((params) => (
      <div className="">
        {moment(params?.value)?.format("DD-MMM-YYYY")}
        <Tooltip
          title={`${moment(params?.value)?.format("DD-MMM-YYYY, hh:mm A")} by ${
            params?.row?.updatedBy
          }`}
        >
          <span>
            <IoEyeOutline className="ml-2 pointer" size={18} />
          </span>
        </Tooltip>
      </div>
    )),
];

export const getMapBarcodeColumn = ({
  rowForm,
  handleSaveClick,
  rowFormHandler,
  handleEditClick,
  dispatchId,
}) => {
  return [
    new AGridColDef("dispatchId", "Dispatch Id")
      .setMinWidth(120)
      .renderCellComponent((params) => (
        <div className={`ellipsis-text-container`}>{dispatchId}</div>
      )),
    new AGridColDef("materialDescription", "Materials to be issued")
      .setMinWidth(250)
      .renderCellComponent((params) => (
        <Tooltip title={params?.value}>
          <div className={`ellipsis-text-container`}>
            {`${params?.row?.materialId} || ${params?.value}`}
          </div>
        </Tooltip>
      )),
    new AGridColDef("barcodeCount", "Qty left")
      .setMinWidth(120)
      .renderCellComponent((params) => (
        <CustomColoredChip
          text={`${params.value} Left`}
          bgColor={getChipBgColor(params.value)}
          textColor={getChipTxtColor(params.value)}
          style={{ fontSize: "12px" }}
        />
      )),
    new AGridColDef("barcode", "Barcode number")
      .setMinWidth(180)
      .editable(true)
      .renderCellComponent((params) => (
        <div className="d-flex align-items-center ellipsis-text-container">
          <Tooltip title={params?.value}>
            <div className="ellipsis-text-container">
              {params?.value ?? "-"}
            </div>
          </Tooltip>
        </div>
      ))
      .renderEditCellComponent((params) => (
        <div className="data-grid-edit-cell-container">
          <AInput
            autoFocus={params?.hasFocus}
            placeholder="Enter barcode"
            value={rowForm?.barcode}
            handler={(value) =>
              rowFormHandler(value.replace(/[^0-9]/g, ""), "barcode")
            }
            style={{ borderRadius: "4px", height: "32px" }}
            onKeyDown={(e) => {
              if (
                (e.key === "Enter" || e.keyCode === 13) &&
                rowForm?.barcode?.length > 0
              ) {
                handleSaveClick(params);
              }
            }}
          />
        </div>
      )),
    new AGridColDef("id", "Actions", false)
      .setMinWidth(180)
      .columnAlign("right")
      .renderCellComponent((params) => (
        <ActionsCellComponent
          params={params}
          handleEditClick={handleEditClick}
          handleSaveClick={handleSaveClick}
          index={params?.value - 1}
        />
      )),
  ];
};

const ActionsCellComponent = ({
  params,
  handleEditClick = () => {},
  handleSaveClick = () => {},
  index,
}) => {
  return params.api.getRowMode(params?.id) === "view" ? (
    <div className="d-flex align-items-center">
      <IconButtonWrapper onClick={() => handleEditClick(params)}>
        <EditIcon />
      </IconButtonWrapper>
    </div>
  ) : (
    <div className="d-flex align-items-center">
      <AButton
        variant="primary_filled"
        size="xs"
        onClick={() => handleSaveClick(index)}
      >
        Save
      </AButton>
    </div>
  );
};

export const initialFilterForm = {
  businessArea: "",
  courseId: "",
  term: "",
  academicGroup: "",
  materials: "",
  scheduleDateFrom: "",
  scheduleDateTo: "",
  issueDateFrom: "",
  issueDateTo: "",
  eligibleDateFrom: "",
  eligibleDateTo: "",
  status: ["DISPATCH_PENDING", "STOCK_ISSUED"],
};

export const DISPATCH_PENDING_FILTER = {
  businessArea: "",
  courseId: "",
  term: "",
  issueDateFrom: "",
  issueDateTo: "",
  eligibleDateFrom: "",
  eligibleDateTo: "",
  status: null,
  applicationId: null,
  psid: null,
  dispatchScheduleId: null,
  dispatchId: "",
};

export const statusDispatchedDropdown = [
  { label: "Dispatched", value: "DISPATCHED" },
  { label: "In transit", value: "IN_TRANSIT" },
  { label: "Delivered", value: "DELIVERED" },
];

export const statusMap = {
  DISPATCHED: "Dispatched",
  IN_TRANSIT: "In transit",
  DELIVERED: "Delivered",
  RE_DISPATCHED: "Re-dispatched",
};

export const dispatchPendingTabStatus = [
  "DISPATCH_PENDING",
  "STOCK_ISSUED",
  "RETURNED",
];
export const onHoldStatus = ["ON_HOLD"];
export const dispatchedTabStatus = [
  "DISPATCHED",
  "IN_TRANSIT",
  "DELIVERED",
  "RE_DISPATCHED",
];

export const DISPATCHED_FILTER = {
  businessArea: "",
  courseId: "",
  term: "",
  dispatchDate: "",
  dispatchDateTo: "",
  dispatchScheduleId: "",
  dispatchId: "",
  psid: null,
  status: null,
};

export const SECOND_TIME_RE_DISPATCH_MGS =
  "Re-dispatch not allowed for this transaction, Please return the stock and reprocess the dispatch.";
