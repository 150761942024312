import moment from 'moment';
import React, { useState } from "react";
import Select2 from "react-select2-wrapper";
import { Card, CardHeader, Container, Input, Row, Table } from "reactstrap";
import { masterServiceBaseUrl, putpost, putpost2 } from 'services/http';
import { MasterHeader, TDROW } from "../../common/commonComponents/masterRow/masterRow";
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import CustomButton from "components/CustomButton";
var _ = require('lodash');

const CurrencyTable = (props) => {

  const { userPermissions, isSaveVisible, setisSaveVisible=()=>{} } = props;
  const [tableDataKey, settableDataKey] = useState('tableDataKey');

  const addNewClass = () => {
    props.setclassArray([{ "editMode": true, "__status": "__new", "currencyKey": null, 'status': 'ACTIVE', "currencyDesc": null, "currencyDispValue": null, "effectiveDate": null, "currencySymbol": null, countryId: null }, ...props.classArray])
  }
  const saveAll = (cb) => {
    var _tempArray = props.classArray
    var isValid = true
    _tempArray.forEach((el, index) => {
      if (el['editMode']) {
        if (el['currencyKey'] === null || !el['currencyKey'].trim()) {
          isValid = false
          failureToast('Please enter the value of Currency Key')
          return
        } else if (el['currencyDesc'] === null || !el['currencyDesc'].trim()) {
          isValid = false
          failureToast('Please enter the value of Description')
          return
        }
        else if (el['currencyDispValue'] === null || !el['currencyDispValue'].trim()) {
          isValid = false
          failureToast('Please enter the value of Display Value')
          return
        }
        else if (el['currencySymbol'] === null || !el['currencySymbol'].trim()) {
          isValid = false
          failureToast('Please enter the value of Currency Symbol')
          return
        }
        else if (!el['countryId']) {
          isValid = false
          failureToast('Please enter the value of Country')
          return
        }
        if (!isValid) {
          return
        }
        if (_tempArray[index]['__status'] == "__new") {
          putpost(masterServiceBaseUrl + '/currency/createCurrency', (data) => {
            successToast(data['message'])
            setisSaveVisible(false)
            props.fetchClasses(masterServiceBaseUrl + '/currency/getAllCurrency')
          }, (data) => { failureToast(data['message']) }, _tempArray[index], 'post')
        } else {
          putpost(masterServiceBaseUrl + '/currency/updateCurrency/' + _tempArray[index].id, (data) => {
            successToast(data['message'])
            setisSaveVisible(false)
            delete _tempArray[index]['editMode']
            props.setclassArray(_tempArray)
            settableDataKey(new Date().getMilliseconds())
            // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
          }, (data) => { failureToast(data['message']) }, _tempArray[index], 'put')
        }
      } else {

      }
    })
  }
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes")
    }
    else {
      let cb = () => {
        addNewClass()
        setisSaveVisible(true)
        // settableDataKey(new Date().getMilliseconds())
      }
      var _tempArray = props.classArray
      _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
      props.setclassArray(_tempArray)
      setisSaveVisible(false)
      cb()
    }
  }
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes")
    }
    else {
      let cb = () => {
        var _tempArray = props.classArray
        _tempArray[index]['editMode'] = true
        props.setclassArray(_tempArray)
        setisSaveVisible(true)
      }
      var _tempArray = props.classArray
      _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
      cb()
    }
  }
  const deleteRow = (index) => {
    if (props.classArray[index]["__status"] == "__new") {
      let n = props.classArray
      n.splice(index, 1)
      props.setclassArray(n)
      setisSaveVisible(false)
      settableDataKey(new Date().getMilliseconds())
    } else {
      if (window.confirm('Are you sure you want to delete this Currency?')) {
        putpost2(masterServiceBaseUrl + '/currency/deleteCurrency/' + props.classArray[index].id, (data) => {
          successToast(data['message'])
          var _tempArray = props.classArray
          if (_tempArray[index]['editMode']) {
            setisSaveVisible(false)
          }
          _tempArray.splice(index, 1)
          props.setclassArray(_tempArray)
          settableDataKey(new Date().getMilliseconds())
        }, (data) => { failureToast(data['message']) }, 'Delete')
      }
    }
  }
  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray
    _tempArray[index][key] = value
    props.setclassArray(_tempArray)
  }
  return (
    <>
        <Container className="mt-3" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Currency</h3>
                  <CustomButton
                    disabled={isSaveVisible}
                    className={'floatRight mx-1' + (isSaveVisible ? ' btn-dark cursor-gray' : null)}
                    content={'New Currency'}
                    permissionType={'C'}
                    icon={true}
                    onClick={() => { newRow(); }}
                    permissionSet={userPermissions}
                  />
                </CardHeader>
                <div id="questionTable" className="table-responsive">
                  <Table className="align-items-center table-flush" >
                  <MasterHeader type={"Currency"} permissionSet={userPermissions} isSaveVisible={isSaveVisible} />
                    <tbody className="list" key={tableDataKey} >
                      {props.classArray ? props.classArray.map((el, index) =>
                        !el['editMode'] ? (
                          <TDROW type={"currency"} index={index} el={el} editRow={editRow} deleteRow={deleteRow} countryList={props.countryList} permissionSet={userPermissions} isSaveVisible={isSaveVisible} />
                        ) :
                          (<tr key={index + '-class'}>
                            <td className="text-center p-2 mx-1"><Select2 defaultValue={el['countryId']} data={props.countryList} onChange={(e) => { updateKey(index, Number(e.target.value), 'countryId') }} options={{ placeholder: "Select Country" }} className="tdSelect" /></td>
                            <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['currencyKey']} onChange={(e) => { updateKey(index, e.target.value, 'currencyKey') }} placeholder="Example:1" className="tdInput" /></td>
                            <td className="text-center p-2 mx-1"><Input maxLength="50" disabled={true} defaultValue={el["__status"] == "__new" ? null : moment(el['eff_date']).format('DD-MM-YYYY')} placeholder="Example:01-01-2020" className="tdInput" /></td>
                            <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['currencyDesc']} onChange={(e) => { updateKey(index, e.target.value, 'currencyDesc') }} placeholder="Description" className="tdInput" /></td>
                            <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['currencyDispValue']} onChange={(e) => { updateKey(index, e.target.value, 'currencyDispValue') }} placeholder="Display Value" className="tdInput" /></td>
                            <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['currencySymbol']} onChange={(e) => { updateKey(index, e.target.value, 'currencySymbol') }} placeholder="Currency Symbol" className="tdInput" /></td>
                            <td className="text-center p-2 mx-1">
                              <label className="custom-toggle mx-auto ml-2">
                                <input checked={el.status == "ACTIVE" ? true : false} type="checkbox" id={el.id} onChange={(e) => { settableDataKey(new Date().getMilliseconds());  let n = "ACTIVE"; if (!e.target.checked) { n = "INACTIVE" } updateKey(index, n, 'status') }} />
                                <span style={{ width: "72px" }} className="custom-toggle-slider rounded-circle activeToggle" data-label-off="Inactive" data-label-on="Active" />
                              </label>
                            </td>
                            <td className="text-center">
                              {isSaveVisible ? <>
                                <CustomButton
                                  content={'Save'}
                                  permissionType={'C,U'}
                                  icon={false}
                                  forTable={true}
                                  onClick={() => { saveAll() }}
                                  permissionSet={userPermissions}
                                />
                                <CustomButton
                                  content={''}
                                  permissionType={'cancel'}
                                  icon={true}
                                  forTable={true}
                                  onClick={() => {
                                    let n = props.classArray
                                    if (n[index]['__status'] == '__new') {
                                      n.splice(index, 1)
                                    } else {
                                      props.fetchClasses(masterServiceBaseUrl + '/currency/getAllCurrency')
                                    }
                                    props.setclassArray(n)
                                    setisSaveVisible(false)
                                  }}
                                  permissionSet={userPermissions}
                                /> </>
                                :
                                <CustomButton
                                  content={''}
                                  permissionType={'D'}
                                  icon={true}
                                  forTable={true}
                                  onClick={() => deleteRow(index)}
                                  permissionSet={userPermissions}
                                />
                              }
                            </td>
                          </tr>)
                      ) : null}
                    </tbody>
                  </Table>
                </div>
              </Card>
            </div>
          </Row>
      </Container> 
    </>
  )
}
export default CurrencyTable;