import { eraseCookie, setCookie, getCookie } from '../../../views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { get_request_params } from '../../properties/headers';
import {SomethingWentWrong, controller} from "../helpers";
import { failureToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import { LOGOUTMESSAGE } from "../../constant"

const trimObj = (obj) => {
	const keys = Object.keys(obj);
	keys.forEach(item => {
		if (typeof obj[item] === "string") {
			obj[item] = obj[item].trim();
		}
	});
}

const putpost = async (url, _success, _failure, obj, type, header, id='') => {
  try {
    const validObj = trimObj(obj);
    const request_params = get_request_params(type, JSON.stringify(obj), header,id);
    const lastLocation = document.URL.split('#')[1];
    if(lastLocation !== '/auth'){
      setCookie('lastKnowPath', lastLocation);
    }
    fetch(url, request_params)
      .then(function (response) {
        
        if ((response.status === 403 || response.status === 401) && getCookie('token')) {
          failureToast(LOGOUTMESSAGE);
          eraseCookie('token');
          controller.abort();
          setTimeout(()=>window.location.replace(`${document.URL.split('#')[0]}`), 3000);
          return null
        }
        if(response.status === 412 ){
            return response.json()
        }
        if (response.ok) {
          return response.json();
        } else {
          response
            .json()
            .then(er => {
              _failure(er);
            })
            .catch(error => { });
        }
      })
      .then(function (data) {
        if (data && data['code'] == '400') {
          _failure(data);
        } else {
          _success(data);
        }
      })
      .catch(error => {
        console.log(error);
        // _failure(error)
      });
  } catch (e) {
      SomethingWentWrong();
    //console.log('Error', e);
  }
};
export { putpost };
