import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, CardHeader, Container, Row, Label, UncontrolledTooltip } from 'reactstrap'
import { GetTemplate } from "views/pages/manage/common/commonComponents";
import { PermissionContext, RolePermissions } from "appContext";
import { ConfirmationDialog, CustomUpload, Dialog, PermisionDenied } from "../../common/commonComponents";
import CustomButton from "components/CustomButton";
import { pages, endpoint, regex, commonMessages } from '../../common/constant';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import {
    putpost
} from 'services/http';
import { fetchAllPromisedData, getUniqueKey } from '../../common/utils/methods/commonMethods/utilityMethod';
import CustmCheckbox from '../../common/formFeilds/customCheckbox'

import CopyToClipboard from 'react-copy-to-clipboard';

const EmployeeDataUpload = () => {
    const permissions = useContext(PermissionContext);
    const userPermissions = RolePermissions(permissions, pages["employeeManagement"]['id']);
    const [isUploadUIVisible, setUploadUIVisible] = useState(false);
    const [isFileValid, setISFileValid] = useState(false);
    const [filename, setFileName] = useState("");
    const [file, setFile] = useState(null);
    const [progressBarStatus, setProgressBarStatus] = useState(0);
    const [processId, setProcessId] = useState(null);
    const [isUploading, setISUploading] = useState(false);

    const [employeeType, setEmployTypee] = useState('new');
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
    const [processIdCopied, setProcessIdCopied] = useState(false);
    const [confirmationDialog, setConfirmationDialog] = useState(false);
    const [validationRule, setValidationRule] = useState({
        new: null,
        exit: null
    })

    const dataToServer = {
        bulk: true,
        fileName: null,
        docType: "xlsx",
        keyValue: `pricing_${getUniqueKey()}`,
        serviceName: 'AUTH_EMP_NEW',
    };

    const reset = () => {
        setISFileValid(false)
        setFileName('')
        setFile(null)
        setProgressBarStatus(0)
        setProcessId(null)
        setISUploading(false)
        setUploadUIVisible(false)
        setConfirmationDialog(false)
    }

    const getDocumentValidationInfo = async () => {
        if (employeeType && (!validationRule.new || !validationRule.exit)) {
            const docInformation = await fetchAllPromisedData(`${endpoint.getDocMapping}?processTagTypeId=${employeeType === 'new' ? 13 : 14}`, true);
            if (docInformation?.code == 200) {
                setValidationRule({
                    ...validationRule,
                    [employeeType]: { size: docInformation?.data?.max_size, extension: docInformation?.data?.extension.split('.')[1] }
                })
            }
        }
    }


    useEffect(() => {
        getDocumentValidationInfo()
    }, [employeeType])

    const handleFile = (e) => {
        const ext = employeeType === 'new' ? validationRule?.new?.extension ?? 'xlsx' : validationRule?.exit?.extension ?? 'xlsx'
        const size = employeeType === 'new' ? validationRule?.new?.size ?? 10 : validationRule?.exit?.size ?? 10

        try {
            if (e.name.split(".")[1] === ext) {
                if (e.name.length <= 100) {
                    let value = e.name.split(".")[0];

                    if (regex.alphaNumericWithSpaceAndUnderscore.test(value)) {
                        setFileName(value);
                        setFile(e);
                        setISFileValid(true);

                        if (e?.size && (e?.size) / (1024 * 1024) > size) {
                            failureToast(`File Size error. Please upload file not exceeding ${size} MB size`)
                            setFileName("");
                            setFile(null);
                            setISFileValid(false);
                        }
                    } else {
                        failureToast("File name error. Please rename file without special characters and upload")
                        setFileName("");
                        setFile(null);
                        setISFileValid(false);
                    }
                } else {
                    failureToast("File name error. Please rename file not exceeding 100 characters.")
                    setFileName("");
                    setFile(null);
                    setISFileValid(false);
                }
            } else {
                failureToast(` File Format error. Please upload file in ${ext} format only`)
                setFileName("");
                setFile(null);
                setISFileValid(false);
            }
        } catch (e) {
            console.log(e)
        }
    };

    const saveFileAndReturnData = (data) => {

        const request = {
            "isProcess": true,
            "processType": "EMPLOYEE_DATA_UPLOAD",
            "serviceName": "AUTH",
            "processId": data.processId,
            "status": "SUCCESS",
            "documentType": "xlsx",
            "extId": getUniqueKey(),
            "signedUrl": getUniqueKey(),
            "processTagTypeId": employeeType === 'new' ? 13 : 14
        }

        putpost(
            endpoint.fetchS3Url.commonUploadDms,
            (res) => {
                if (res.code === 200 && res.data) {
                    setProgressBarStatus(100);
                    setProcessId(data.processId)
                    setIsSuccessModalVisible(true);
                }
            },
            (res) => {
                setISUploading(false);
                setProgressBarStatus(0);
                failureToast(res["message"]);
            },
            request,
            "post"
        );
    };

    const handleCancel = () => {
        if (file) {
            setConfirmationDialog(true)
        } else {
            reset()

        }
    }

    return (
        <Container className="mt-3" fluid>
            <Card>
                <CardHeader>
                    <h3>Upload Employee Data</h3>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <div className='pr-5 d-flex'>
                            <CustmCheckbox onClick={() => setEmployTypee('new')} onChangeHandler={() => setEmployTypee('new')} defaultChecked={employeeType === 'new' ? true : false} isDisabled={isUploadUIVisible} />
                            <span className='pl-3'>New Joiner</span>
                        </div>
                        <div className='pr-5 d-flex'>
                            <CustmCheckbox onClick={() => setEmployTypee('old')} onChangeHandler={() => setEmployTypee('old')} defaultChecked={employeeType === 'old' ? true : false} isDisabled={isUploadUIVisible} />
                            <span className='pl-3'>Exit Employee</span>
                        </div>
                        <GetTemplate url={employeeType === 'new' ? endpoint.newEmployeeTemplate
                            : endpoint.oldEmployeeTemplate} isSaveVisible={false} userPermissions={userPermissions} />
                        <CustomButton
                            type="upload"
                            className={'ml-5 upload-button floatRight mx-1'}
                            content={'Upload Template'}
                            permissionType={'C'}
                            icon={true}
                            onClick={() => { setUploadUIVisible(true) }}
                            permissionSet={userPermissions}
                        />
                    </div>
                </CardHeader>
                {isUploadUIVisible && <div className='p-4'>
                    <CustomUpload
                        // documentTitle="Upload Supporting Document"
                        isFileValid={isFileValid}
                        filename={filename}
                        file={file}
                        progressBarStatus={progressBarStatus}
                        setISFileValid={setISFileValid}
                        setFileName={setFileName}
                        setFile={setFile}
                        setProgressBarStatus={setProgressBarStatus}
                        isUploading={isUploading}
                        setISUploading={setISUploading}
                        handleFile={handleFile}
                        dataToServer={{...dataToServer,serviceName:employeeType === 'new' ? 'AUTH_EMP_NEW' : 'AUTH_EMP_EXIT'}}
                        saveHandler={saveFileAndReturnData}
                        userPermissions={userPermissions}
                        goBack={() => handleCancel()}
                        documentTitle={'Upload Employee Details Template'}
                        description={getUniqueKey()}
                        typeAllowed={employeeType === 'new' ? validationRule?.new?.extension ?? 'xlsx' : validationRule?.exit?.extension ?? 'xlsx'}
                    />
                </div>}
            </Card>

            <Dialog isModalVisible={isSuccessModalVisible} toggle={() => { }} title="" size="md">
                <Row>
                    <h4 className="text-success alert mt-n4">
                        {commonMessages.pricingUploadSuccessfullMessage}
                    </h4>
                </Row>
                <Row className="flex-row">
                    <span className="text-info alert mt-n4 " style={{ width: '100%' }}>
                        {`Process ID`}
                        <CopyToClipboard
                            text={processId}
                            onCopy={() => setProcessIdCopied(processId)}
                        >
                            <button
                                className="btn-icon-clipboard"
                                data-clipboard-text={processId}
                                id="tooltip932383034"
                                type="button"
                            >
                                <div>
                                    <span>{processId}</span>
                                </div>
                            </button>
                        </CopyToClipboard>
                        <UncontrolledTooltip
                            delay={0}
                            trigger="hover focus"
                            target="tooltip932383034"
                        >
                            {processIdCopied === processId
                                ? "Copied"
                                : "Copy To Clipbord"}
                        </UncontrolledTooltip>
                    </span>
                </Row>
                <Row className="justify-content-center flex-row">
                    <Button
                        color="info"
                        size="sm"
                        type="button"
                        className="my-2"
                        onClick={() => {
                            setProcessId(null);
                            setProcessIdCopied(null);
                            setUploadUIVisible(false)
                            reset()
                            setIsSuccessModalVisible(false);
                        }}
                    >
                        Close
                    </Button>
                </Row>
            </Dialog>
            <ConfirmationDialog isOpen={confirmationDialog} onAgree={reset} setIsOpen={setConfirmationDialog} />
        </Container>
    )
}

export default EmployeeDataUpload