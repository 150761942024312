import { Dialog } from '@mui/material';
import React, { useState } from 'react';
import { ReactComponent as CrossIcon } from 'assets/img/svg/CrossIcon.svg';
import { ReactComponent as QuestionIcon } from 'assets/img/svg/QuestionMarkConfirmationDialog.svg';
import AButton from '../../form-fields-mui/AButton';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'

const useCustomConfirmationPrompt = () => {
  const [promise, setPromise] = useState(null);
  const confirm = () =>
    new Promise((resolve, reject) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise.resolve(false);
    handleClose();
  };

  const ConfirmationPrompt = ({
    isQuestionIconRequired = true,
    headerMsg,
    msg,
    dialogStyles = {},
    containerStyles = {},
    containerClassName = "",
    width = "500px",
    customCancelBtn,
    customConfirmBtn,
    cancelBtnProps = {},
    confirmBtnProps = {},
    cancelBtnTxt,
    confirmBtnTxt,
  }) => (
    <Dialog
      onClose={handleCancel}
      open={promise !== null}
      PaperProps={{
        sx: { maxWidth: "unset", borderRadius: "12px", ...dialogStyles },
      }}
    >
      <div
        className={containerClassName}
        style={{ padding: "28px 24px", width: width, ...containerStyles }}
      >
        <div className="d-flex justify-content-between align-items-start mb-3">
          {isQuestionIconRequired ? (
            <QuestionIcon color="#414141" />
          ) : (
            <div className="large-bolder color-black">{headerMsg}</div>
          )}
          <IconButtonWrapper
            className="ml-3 flex-shrink-0"
            onClick={handleCancel}
          >
            <CrossIcon width={32} height={32} />
          </IconButtonWrapper>
        </div>

        {isQuestionIconRequired && (
          <div className="large-bolder mb-3 color-black">{headerMsg}</div>
        )}
        {msg && <div className="regular-large label-color mb-3">{msg}</div>}

        <div className="d-flex justify-content-between">
          {customCancelBtn || (
            <AButton
              variant="outline_danger"
              className="button_remove_left_margin"
              updatedStyle={{ minWidth: "180px" }}
              onClick={handleCancel}
              {...cancelBtnProps}
            >
              {cancelBtnTxt || "No"}
            </AButton>
          )}
          {customConfirmBtn || (
            <AButton
              variant="outline"
              updatedStyle={{ minWidth: "180px" }}
              onClick={handleConfirm}
              {...confirmBtnProps}
            >
              {confirmBtnTxt || "Yes"}
            </AButton>
          )}
        </div>
      </div>
    </Dialog>
  );

  return [ConfirmationPrompt, confirm];
};

export default useCustomConfirmationPrompt;
