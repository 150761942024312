import React from 'react'

const TooltipContent = () => {
  return (
    <div>
      Ms teams Password guideline: a student's ms teams password should contain 9 characters in the format: “First 2 characters of the first Name” followed by “@” and DOB (“DDMMYY”)
      <br />
      <br />
      For example: If Name - Rohit Kumar, DOB: 08/Jun/1998, then password-: Ro@080698
    </div>
  )
}

export default TooltipContent
