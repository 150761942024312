import React, { useEffect, useState, useContext, useMemo } from 'react';
import MuiTable from '../../../manage/common/commonComponents/MuiTable.js';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod.js';
import { constants, endpoint, regex } from '../../common/constant';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function.js';
import { Container } from 'reactstrap';
import { PermissionContext, RolePermissions } from "../../../../../appContext";
import { pages } from '../../common/constant';
import { ConfirmationDialog, PermisionDenied } from '../../common/commonComponents';
import './index.scss';
import CustomButton from '../../../../../components/CustomButton/index.js';
import CommonTable from './table.js';
import { dropdownMapping } from '../../unAssigned/studentCharges/common.js';
import Filter from './Filter';
import { useHistory } from 'react-router';
import CustomLoader from '../../common/commonComponents/Loader/loader.js';
import useConfirm from '../../common/commonComponents/ConfirmationDialog/confirmationPrompt.jsx';

// import { TransitionTimeouts } from 'reactstrap/lib/utils';

const initialFilter = {
    region: [],
    businessArea: [],
    academicGroup: [],
    academicCareer: [],
    subject: []
}

const isFilterApplied = (filters) => {
    let count = 0;
    Object.keys(filters).map(item => {
        if (Array.isArray(filters[item])) {
            if (filters[item].length !== 0) {
                count++;
            }
        }
    })
    return count;
}

const FacultyMaster = () => {
    const history = useHistory();
    const permissions = useContext(PermissionContext);
    const userPermissions = RolePermissions(permissions, pages["faculty"]['id']);
    const userPermissionsRoster = RolePermissions(permissions, pages["facultyRoaster"]['id']);
    const [ConfirmationDialog, confirm] = useConfirm();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({
        pageSize: 50,
        page: 0,
        pageCount: 0,
    })
    const [filterForm, setFilterForm] = useState({
        region: [],
        businessArea: [],
        academicGroup: [],
        academicCareer: [],
        subject: [],
        facultyNameOrfacultyId: ''
    });
    const [appliedFilterCount, setAppliedFilterCount] = useState(0);
    const [isSaveVisible, setAddVisible] = useState(true)


    const [businessAreaData, setBusinessAreaData] = useState({ fetching: true, data: [] })
    const [regionData, setRegionData] = useState({ fetching: true, data: [] });
    const [academicGroupData, setAcademicGroupData] = useState({ fetching: true, data: [] });
    const [acadmicCareerData, setAcadmicCareerData] = useState({ fetching: true, data: [] });
    const [subjectData, setSubjectData] = useState({ fetching: true, data: [] });

    const getBusinessareaOption = (rowValue) => {
        if (rowValue?.region?.length > 0 && !businessAreaData.fetching) {
            const regionList = rowValue?.region.map(i => i.value)
            let matchedBusinessArea = [];
            businessAreaData.data.map((item) => {
                if (regionList?.filter(value => item?.regionId?.includes(value)).length) {
                    matchedBusinessArea.push(item)
                }
            })
            return matchedBusinessArea

        } else {
            return []
        }
    }

    const columns = [
        { field: 'facultyId', headerName: 'Faculty ID', type: 'text', placeholder: 'Faculty ID', width: 150, mandatory: true, editable: true, disabled: false, sortable: true, disabledInEdit: true, regEx: regex.alphaNumeric, maxLength: 15 },
        { field: 'facultyName', headerName: 'Faculty name', type: 'text', placeholder: 'Faculty Name', width: 150, mandatory: true, editable: true, disabled: false, sortable: true, disabledInEdit: true },
        { field: 'facultyEmailId', headerName: 'Faculty Email Id', type: 'text', placeholder: 'Email Id', width: 200, mandatory: true, editable: true, disabled: false, disabledInEdit: true, maxLength: 60 },
        // { field: 'msTeamFacultyId', headerName: 'MS Team Faculty ID', type: 'text', placeholder: 'MS Team Faculty ID', width: 190, mandatory: true, editable: true, disabled: false, disabledInEdit: false, sortable: false, isCopyIconRequired: true },
        { field: 'msTeamFacultyId', headerName: 'MS Team Faculty ID', type: 'text', placeholder: 'MS Team Faculty ID', width: 190, mandatory: true, editable: true, disabled: true, disabledInEdit: true, sortable: false, isCopyIconRequired: true },
        { field: 'role', headerName: 'Role', type: 'multiSelect', valueOptions: constants.facultyRole, width: 100, mandatory: true, editable: true, disabled: false },
        { field: 'region', headerName: 'Region', type: 'multiSelect', valueOptions: regionData.data, width: 100, mandatory: true, editable: true, disabled: false },
        { field: 'businessArea', headerName: 'Business area', type: 'multiSelect', popoverView: true, valueOptionsFunction: getBusinessareaOption, width: 150, mandatory: true, editable: true, disabled: false },
        { field: 'academicCareer', headerName: 'Acad career', type: 'multiSelect', valueOptions: acadmicCareerData.data, width: 150, mandatory: true, editable: true, disabled: false },
        { field: 'academicGroup', headerName: 'Acad group', type: 'multiSelect', valueOptions: academicGroupData.data, width: 150, mandatory: true, editable: true, disabled: false },
        { field: 'subject', headerName: 'Subject', type: 'multiSelect', valueOptions: subjectData.data, popoverView: true, width: 110, mandatory: true, editable: true, disabled: false },
    ];

    const handleBlockActions = (bool) => {
        setAddVisible(bool)
    }

    const getAllInitailList = async () => {
        const p = await Promise.all([
            dropdownMapping(endpoint?.region.getAllActive, 'regionDispValue'),
            dropdownMapping(endpoint?.businessArea.getAllActive, 'businessAreaDispValue'),
            dropdownMapping(`${endpoint?.academic_career.getAllActive}`, 'academicCareerDispValue'),
            dropdownMapping(endpoint?.academic_group.getAllActive, 'academicGroupDispValue'),
            dropdownMapping(`${endpoint?.subject.getAllActive}`, 'subjectDispValue'),
        ])

        setRegionData({ fetching: false, data: p[0] });
        setBusinessAreaData({ fetching: false, data: p[1] });
        setAcadmicCareerData({ fetching: false, data: p[2] });
        setAcademicGroupData({ fetching: false, data: p[3] });
        setSubjectData({ fetching: false, data: p[4] });
        // getFilteredList(initialFilter,0);
    }


    const getFilteredList = async (req, p) => {
        setLoading(true);
        try {
            const request = req ? {
                ...req,
                businessArea: req.businessArea?.length ? filterForm.businessArea.map(i => i?.value) : businessAreaData.data.map(i => i?.value),
                "academicGroup": req.academicGroup.map(i => i?.value),
                "academicCareer": req.academicCareer.map(i => i?.value),
                "subject": req.subject.map(i => i?.value),
                "region": req.region.map(i => i?.value),
            } : {
                // "role": filterForm.region.map(o=>o.value),
                "region": filterForm.region.map(i => i?.value),
                "businessArea": filterForm.businessArea?.length ? filterForm.businessArea.map(i => i?.value) : businessAreaData.data.map(i => i?.value),
                "academicGroup": filterForm.academicGroup.map(i => i?.value),
                "academicCareer": filterForm.academicCareer.map(i => i?.value),
                "subject": filterForm.subject.map(i => i?.value),
                "facultyNameOrfacultyId": filterForm.facultyNameOrfacultyId
            }
            const response = await fetchAllPostPromisedData(`${endpoint.faculty.filter}?offset=${p ?? pagination.page}`, request, 'post');

            if (response.code === 200) {
                const data = createSchemes(response.data.searchFacultyMasterDtoList);
                setRows(data);
                setPagination({
                    ...pagination,
                    page: p ?? 0,
                    pageCount: response.data.totalPage,
                })
                setLoading(false);
            } else {
                failureToast("Failed to fetch Data");
                setLoading(false);
            }
        } catch (e) {
            console.log(e)
            failureToast("Failed to fetch Data");
            setLoading(false);
        }
    }

    const inputHandler = (value, inputName) => {
        let currentData = { ...filterForm };
        if (inputName === "businessAreaClear") {
            currentData = { ...filterForm, businessArea: [] };
            setFilterForm(currentData);
        } else {
            currentData[inputName] = value;
            setFilterForm(currentData);
        }
    };

    const filterHandler = (req) => {
        setPagination({ ...pagination, page: 0 });
        getFilteredList(req, 0);
        setAppliedFilterCount(isFilterApplied(filterForm))
    }

    const applyFilterHandler = async (req) => {
        // const canContinue = await confirm();

        // if (canContinue) {
        //     filterHandler(req);
        //     setAddVisible(true);
        // } else {
        //     return
        // }
        filterHandler(req);
    };

    const removeFilterHandler = () => {
        setPagination({ ...pagination, page: 0 });
        setAppliedFilterCount(0)
        setFilterForm({
            ...filterForm,
            region: [],
            businessArea: [],
            academicGroup: [],
            academicCareer: [],
            subject: []
        })

        if (filterForm.facultyNameOrfacultyId) {
            getFilteredList();
        } else {
            getFilteredList(initialFilter, 0);
        }
    };

    const crossInputHandler = () => {
        if (!isSaveVisible) {
            setFilterForm({ ...filterForm, facultyNameOrfacultyId: '' })
        } else {
            setPagination({ ...pagination, page: 0 });
            setFilterForm({ ...filterForm, facultyNameOrfacultyId: '' })
            if (appliedFilterCount) {
                getFilteredList({ ...filterForm, facultyNameOrfacultyId: '' });
            } else {
                getFilteredList(initialFilter, 0);
            }
        }

    };

    const createSchemes = (schemesPayload) => {

        return schemesPayload.map(scheme => {

            const { id,
                facultyId,
                facultyName,
                facultyEmailId,
                msTeamFacultyId,
                role,
                region,
                facultyMasterBusinessAreasResponseList,
                academicCareer,
                academicGroup,
                subject } = scheme;

            const regionObject = regionData.data.filter(reg => region.indexOf(reg.value) > -1)
            const academicGroupObject = academicGroupData.data.filter(group => academicGroup.indexOf(group.value) > -1)
            const academicCareerObject = acadmicCareerData.data.filter(career => academicCareer.indexOf(career.value) > -1)
            const subjectObject = subjectData.data.filter(sub => subject.indexOf(sub.value) > -1)
            const businessAreaObject = facultyMasterBusinessAreasResponseList.map(bus => { return { label: bus.businessAreaValue, value: bus.businessAreaKey } }) ?? []
            const roleObject = constants.facultyRole.filter(item => role.indexOf(item.value) !== -1)


            return {
                id: id,
                facultyId,
                facultyName,
                facultyEmailId,
                msTeamFacultyId,
                role: roleObject,
                roleForEdit: roleObject,
                region: regionObject,
                regionForEdit: regionObject,
                businessArea: businessAreaObject,
                businessAreaForEdit: businessAreaObject,
                academicCareer: academicCareerObject,
                academicCareerForEdit: academicCareerObject,
                academicGroup: academicGroupObject,
                academicGroupForEdit: academicGroupObject,
                subject: subjectObject,
                subjectForEdit: subjectObject,
                fromBE: true
            }
        })
    }

    const getAllFacultyList = async (p) => {
        setLoading(true);
        try {
            const response = await fetchAllPromisedData(`${endpoint.faculty.getAll}?offset=${p ?? pagination.page}`, true);
            setLoading(false);
            if (response.code === 200) {
                const data = createSchemes(response.data.facultyMasterListDTO);
                setRows(data);
                setPagination({
                    ...pagination,
                    page: p ?? 0,
                    pageCount: response.data.totalPages,
                })
                setLoading(false);
            } else {
                failureToast("Failed to fetch Data");
                setLoading(false);
            }
        } catch (error) {
            console.log(error)
            setLoading(false);
            failureToast("Failed to fetch Data");
        }
    }

    const saveScheme = async (row) => {
        const isRowNew = row.isNewRow ? true : false;
        
        if (!row.facultyId) {
            failureToast("Please enter faculty ID")
            return
        }

        if (!row.facultyName?.trim()) {
            failureToast("Please enter faculty name")
            return
        }

        if (isRowNew && !row.facultyEmailId?.trim()) {
            failureToast("Please enter Faculty Email ID")
            return
        }

        // if (!row.msTeamFacultyId?.trim()) {
        //     failureToast("Please enter Ms Team Faculty ID")
        //     return
        // }

        if (!row.role?.length) {
            failureToast("Please atleast one role")
            return
        }

        if (!row.region?.length) {
            failureToast("Please select atleast one region")
            return
        }

        // if(!row.businessArea?.value){
        //     failureToast("Please select business area")
        //     return
        // }

        if (!row.businessArea?.length) {
            failureToast("Please select business area")
            return
        }

        if (!row.academicCareer?.length) {
            failureToast("Please select atleast one academic career")
            return
        }


        if (!row.academicGroup?.length) {
            failureToast("Please select atleast one academic group")
            return
        }

        if (!row.subject?.length) {
            failureToast("Please select atleast one subject")
            return
        }

        setLoading(true);
        const request = {
            "facultyName": row.facultyName,
            "facultyId": row.facultyId,
            "facultyEmailId": row.facultyEmailId,
            // "msTeamFacultyId": row.msTeamFacultyId,
            "region": row.region.map(o => o.value),
            "role": row.role.map(o => o.value),
            "businessArea": row.businessArea.map(o => o.value),
            "academicCareer": row.academicCareer.map(o => o.value),
            "academicGroup": row.academicGroup.map(o => o.value),
            "subject": row.subject.map(o => o.value),
            "status": 'ACTIVE'
        }
        const p = new Promise((resolve, reject) => {
            fetchAllPostPromisedData(isRowNew ? `${endpoint.faculty.create}` : `${endpoint.faculty.update}/${row.id}`, request, isRowNew ? 'post' : 'put')
                .then(response => {
                    setLoading(false);
                    if (response && response.code === 200) {
                        successToast(`Faculty ${isRowNew ? 'added' : 'updated'} successfully!`);
                        getFilteredList(filterForm, 0);
                        setAddVisible(true);
                    } else {
                        failureToast(response.message);
                        setAddVisible(true);
                        // reject(response.message);
                    }
                })
                .catch(err => { setLoading(false) });
        });
        // return p;
    }

    const deleteScheme = async (row) => {
        setLoading(true);
        const p = new Promise((resolve, reject) => {
            fetchAllPostPromisedData(`${endpoint.faculty.delete}/${row.id}`, {}, 'delete')
                .then(response => {
                    setLoading(false);
                    if (response && response.code === 200) {
                        successToast('Faculty deleted successfully!');
                        getFilteredList(initialFilter, 0);
                        setAddVisible(true)
                    } else {
                        failureToast(response.message)
                    }
                })
                .catch(err => { setLoading(false); });
        });
        // return p;
    }


    const FilterComp = (
        <Filter
            filterForm={filterForm}
            // regionAndBusiness = {regionAndBusiness}
            setNormalFilterForm={setFilterForm}
            inputHandler={inputHandler}
            applyFilterHandler={applyFilterHandler}
            setAppliedFilterCount={setAppliedFilterCount}
            appliedFilterCount={appliedFilterCount}
            removeFilterHandler={removeFilterHandler}
            crossInputHandler={crossInputHandler}
            businessAreaData={businessAreaData}
            regionData={regionData}
            academicGroupData={academicGroupData}
            academicCareerData={acadmicCareerData}
            subjectData={subjectData}
            setFilterForm={setFilterForm}
            isSaveVisible={isSaveVisible}
        />
    )

    const addNewRow = () => {
        const newObject = {
            facultyId: '',
            facultyName: '',
            facultyEmailId: '',
            role: [],
            region: [],
            businessArea: [],
            academicCareer: [],
            academicGroup: [],
            subject: [],
            isNewRow: true
        };

        setRows([newObject, ...rows]);
        setAddVisible(false)
    }

    const onNewRowCancel = (index) => {
        const _temArr = [...rows]
        _temArr.splice(index, 1)
        setRows([..._temArr])
        setAddVisible(false)
    }

    const pageChangeHandler = async(p) => {
        if(!isSaveVisible){
            const canContinue = await confirm();
            if (canContinue) {
                setAddVisible(true);
                if (appliedFilterCount || filterForm.facultyNameOrfacultyId) {
                    getFilteredList(null, p)
                } else {
                    getFilteredList(initialFilter, p)
                }
            } else {
                return
            }
        }else{
            if (appliedFilterCount || filterForm.facultyNameOrfacultyId) {
                getFilteredList(null, p)
            } else {
                getFilteredList(initialFilter, p)
            }
        }
        
    }

    useEffect(() => {
        getAllInitailList();
    }, [])

    useEffect(() => {
        if (!regionData.fetching && !businessAreaData.fetching && !academicGroupData.fetching && !acadmicCareerData.fetching && !subjectData.fetching) {
            getFilteredList(initialFilter, 0);
        }
    }, [regionData, businessAreaData, academicGroupData, acadmicCareerData, subjectData])

    return (
        userPermissions ? (<Container fluid>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='h-100 d-flex align-items-center'>
                    <h2 style={{ fontWeight: 600 }}>Faculty master</h2>
                </div>
                <div className='d-flex align-items-center'>
                    {userPermissionsRoster && userPermissionsRoster.includes('C') && <span style={{ color: 'rgba(0, 176, 245, 1)', fontSize: 16, cursor: 'pointer' }} onClick={() => history.push('/admin/facultyRoaster/newRoaster')}>Faculty roster</span>}
                    <CustomButton
                        className={'m-4 floatRight'}
                        content={"Add new faculty"}
                        outline
                        permissionType={'C'}
                        permissionSet={userPermissions}
                        onClick={() => addNewRow()}
                        disabled={loading || !isSaveVisible}
                    />
                </div>
            </div>
            <CommonTable
                userPermissions={userPermissions}
                rows={rows}
                columns={columns}
                viewOnly={false}
                loading={loading}
                setAddVisible={handleBlockActions}
                pagination={pagination}
                setPagination={setPagination}
                FilterComp={FilterComp}
                saveScheme={saveScheme}
                deleteScheme={deleteScheme}
                onNewRowCancel={onNewRowCancel}
                pageChangeHandler={pageChangeHandler}
                wariningRequired={true}
                isSaveVisible={isSaveVisible}
                deleteConfirmationHeader={'Confirm faculty delete'}
                deleteConfimationMsg={'This Faculty/Invigilator will be removed from all upcoming planned time tables of batches across relevant branches. Are you sure you want to proceed?'}
            />

            {/* <ConfirmationDialog
                isOpen={isConfimationDialogOpen}
                onAgree={() => { setAddVisible(true);filterHandler(filterForm);setConfimationDialog(false) }}
                setIsOpen={setConfimationDialog}
                headerMsg={'Confirm continue'}
                msg={'Are you sure you want to continue all you unsaved changes will be lost?'}
            /> */}
            <ConfirmationDialog />

        </Container>) : <PermisionDenied />
    )
}

export default FacultyMaster;