import React, { useState } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { getFormattedDate } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { getBusinessAreaDropdowns } from "../../issuanceIDcard/service";
import { useEffect } from "react";
import {
  useFetchDropdown,
  useFetchEmployeeDetails,
} from "../../issuanceIDcard/customhooks";
import ALoader from "views/pages/manage/common/a-loader";

const EmployeeDetailsCards = ({ data = {}, label }) => {
  const [dropdown, loader] = useFetchDropdown("RCC");
  const [businessAreaDes, setBusinessAreaDes] = useState("");
  const empDetails = useFetchEmployeeDetails(data?.psid);

  useEffect(() => {
    if (dropdown?.businessAreas && data?.businessArea) {
      const list = dropdown?.businessAreas?.filter(
        (item) => item?.value === data?.businessArea
      );
      // console.log(list, list[0]?.businessAreaDesc);
      if (list?.length > 0) setBusinessAreaDes(list[0]?.businessAreaDesc);
    }
  }, [dropdown, data?.businessAreaValue]);

  const getColumn = (label, value) => {
    return (
      <Col md="3" className="p-0">
        <div>
          <div className="label" style={{ fontSize: "12px" }}>
            {label}
          </div>
          <div className="heading-3 color-dark">{value || "-"}</div>
        </div>
      </Col>
    );
  };
  const { materialDetails } = data;
  const { transactionType, costCenter, reason, remark } =
    materialDetails && materialDetails[0] ? materialDetails[0] : {};

  return (
    <div>
      <Card className="m-3">
        {loader && <ALoader />}
        <CardBody>
          <Row className="heading-4 color-dark ml-0">
            {label ? label : "Employee and Transaction details"}
          </Row>
          <Row className="px-0 ml-0">
            {getColumn(
              "Business area",
              `${data?.businessAreaValue} | ${businessAreaDes}`
            )}
            {getColumn("Employee ID", `${data?.psid} | ${empDetails?.userProfile?.firstName||''} ${empDetails?.userProfile?.lastName||''}`)}
            {getColumn("Transaction type", transactionType)}
            {getColumn("Reason", reason)}
          </Row>
          <Row className="px-0 ml-0">
            {getColumn("Cost center", costCenter)}
            {getColumn("SAP Doc no.", data?.sapDocId)}
            {getColumn("Consumption ID", data?.transactionId)}
            {getColumn(
              "Transaction date (Consumption)",
              getFormattedDate(data?.issueDate)
            )}
          </Row>
          {data?.remark && (
            <Row className="px-0 ml-0">
              {getColumn("Reason for return ", data?.remark)}
            </Row>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default EmployeeDetailsCards;
