import React, { useState, useEffect } from 'react';
import {
	CourseForBUAndSecurityHeader,
	COURSEFORBUSECURITYTHROW,
} from '../../../../common/commonComponents/masterRow/masterRow';
import {
	Container,
	Row,
	Col,
	Button,
	Card,
	CardBody,
	CardHeader,
	Table,
	Input,
	Label,
} from 'reactstrap';
import Select2 from 'react-select2-wrapper';
import { GetPagination } from '../../../../common/pagination';
import Loader from 'react-loader-spinner';
import CustomLoader from '../../../../common/commonComponents/Loader/loader';
import {
	fetchAllData,
	fetchDetails,
	getDispValForReactSelectByOneID,
} from '../../../../common/utils/methods/commonMethods/utilityMethod';
import moment from 'moment';

import {
	getAPI,
	concessionServiceBaseUrl,
	successToast,
	failureToast,
	putpost,
	putpost2,
	deleteAPI,
} from 'services/http';

var _ = require('lodash');

const AddCourseForSecurity = (props) => {
	const {
		getBUAndSecurityData,
		action,
		buAndCourseSecurityId,
		course,
		addCourse,
		setAddCourse,
	} = props;
	const [isSaveVisible, setIsSaveVisible] = useState(false);
	const [apiLoader, setApiLoader] = useState(false);
	const [state, setState] = useState([]);
	const [dataChange, setDataChange] = useState(false);
	const [courseDescription, setCourseDescription] = useState('');
	const [tableDataKey, settableDataKey] = useState('tableDataKey');
	const [isEdit, setIsEdit] = useState(false);
	const [pagination, setPagination] = useState({
		currentPage: 1,
		nextPage: null,
		previousPage: null,
		totalPage: null,
	});
	const pageSize = 10;

	useEffect(() => {
		fetchClasses(
			concessionServiceBaseUrl +
				`/course/getCourseByBuAndCourseSecurityId/${buAndCourseSecurityId}`
		);
	}, [buAndCourseSecurityId]);

	const fetchClasses = async (url) => {
		setApiLoader(true);
		getAPI(
			url,
			(data) => {
				setState(data['data']);
				let n = pagination;
				if (data['data'].length > pageSize) {
					n['nextPage'] = n.currentPage + 1;
				}
				if (n.currentPage > 1) {
					n.previousPage = n.currentPage - 1;
				}
				n['totalPage'] = Math.floor(data['data'].length / pageSize);
				if (data['data'].length % pageSize != 0) {
					n['totalPage'] += 1;
				}
				setPagination(n);
				setApiLoader(false);
				setAddCourse(
					data['data'].slice(
						pagination.currentPage * pageSize - pageSize,
						pagination.currentPage * pageSize
					) || []
				);
			},
			(data) => {
				failureToast(data['message']);
				setApiLoader(false);
			}
		);
	};

	const nextPage = () => {
		setAddCourse(
			state.slice(
				(pagination.currentPage + 1) * pageSize - pageSize,
				(pagination.currentPage + 1) * pageSize
			)
		);
		let n = pagination;
		n['currentPage'] = n['currentPage'] + 1;
		n['previousPage'] = n.currentPage;
		setPagination(n);
	};
	const firstPage = () => {
		setAddCourse(state.slice(1 * pageSize - pageSize, 1 * pageSize));
		let n = pagination;
		n['currentPage'] = 1;
		n['previousPage'] = null;
		setPagination(n);
	};
	const lastPage = () => {
		setAddCourse(
			state.slice(
				pagination.totalPage * pageSize - pageSize,
				pagination.totalPage * pageSize
			)
		);
		let n = pagination;
		n['currentPage'] = n.totalPage;
		n['previousPage'] = n.totalPage - 1;
		setPagination(n);
	};
	const previousPage = () => {
		if (pagination.currentPage != 1) {
			setAddCourse(
				state.slice(
					(pagination.currentPage - 1) * pageSize - pageSize,
					(pagination.currentPage - 1) * pageSize
				)
			);
			let n = pagination;
			n['currentPage'] = n['currentPage'] - 1;
			if (n['currentPage'] == 1) {
				n['previousPage'] = null;
			} else {
				n['previousPage'] = n.currentPage;
			}
			setPagination(n);
		}
	};

	const addNewClass = () => {
		setAddCourse([
			{
				editMode: true,
				__status: 'new',
				buAndCourseSecurityId: buAndCourseSecurityId,
				courseId: null,
				effectiveDate: null,
				status: 'ACTIVE',
			},
			...addCourse,
		]);
	};

	const saveAll = (cb) => {
		try {
			var _tempArray = addCourse;
			var isValid = true;
			_tempArray.forEach((el, index) => {
				if (el['editMode']) {
					if (el['buAndCourseSecurityId'] === null) {
						isValid = false;
						failureToast('BU And Course Security ID not found');
						return;
					} else if (el['courseId'] === null) {
						isValid = false;
						failureToast('Please Select Course ID');
						return;
					}

					if (!isValid) {
						return;
					}

					if (_tempArray[index]['__status'] == 'new') {
						setApiLoader(true);
						putpost(
							concessionServiceBaseUrl + '/course',
							(data) => {
								successToast(data['message']);
								settableDataKey(new Date().getMilliseconds());
								getBUAndSecurityData();
								fetchClasses(
									concessionServiceBaseUrl +
										`/course/getCourseByBuAndCourseSecurityId/${buAndCourseSecurityId}`
								);
								setIsSaveVisible(false);
								setCourseDescription('');
								setApiLoader(false);
							},
							(data) => {
								failureToast(data['message']);
								setApiLoader(false);
							},
							_tempArray[index],
							'post'
						);
					} else {
						setApiLoader(true);
						putpost(
							concessionServiceBaseUrl + '/course/' + _tempArray[index].id,
							(data) => {
								successToast(data['message']);
								getBUAndSecurityData();
								fetchClasses(
									concessionServiceBaseUrl +
										`/course/getCourseByBuAndCourseSecurityId/${buAndCourseSecurityId}`
								);
								setIsSaveVisible(false);
								setCourseDescription('');
								delete _tempArray[index]['editMode'];
								setAddCourse(_tempArray);
								settableDataKey(new Date().getMilliseconds());
								setApiLoader(false);
							},
							(data) => {
								failureToast(data['message']);
								setApiLoader(false);
							},
							_tempArray[index],
							'put'
						);
					}
				}
			});
		} catch (e) {
			console.log('Error', e);
		}
	};

	const newRow = () => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				addNewClass();
				setIsSaveVisible(true);
			};

			setIsEdit(false);
			var _tempArray = addCourse;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			setAddCourse(_tempArray);
			setIsSaveVisible(false);
			cb();
		}
	};

	const updateRecord = (index, value, property) => {
		try {
			let _tempArray = addCourse;
			_tempArray[index][property] = value;
			if (property === 'courseId') {
				setCourseDescription(
					getDispValForReactSelectByOneID(value, 'DescValue', course)
				);
			}
			setAddCourse(_tempArray);
		} catch (e) {
			console.log('Error', e);
		}
	};

	const editRecord = (index) => {
		try {
			if (isSaveVisible) {
				failureToast('Please Delete or Save current changes');
			} else {
				setIsEdit(true);
				let cb = () => {
					var _tempArray = addCourse;
					_tempArray[index]['editMode'] = true;
					setCourseDescription(
						getDispValForReactSelectByOneID(
							_tempArray[index]['courseId'],
							'DescValue',
							course
						)
					);
					setAddCourse(_tempArray);
					setIsSaveVisible(true);
				};
				var _tempArray = addCourse;
				_tempArray = _.sortBy(_tempArray, [
					function (o) {
						return o.value;
					},
				]);
				cb();
			}
		} catch (e) {
			console.log('Error : ', e);
		}
	};

	const deleteRecord = (index) => {
		try {
			if (addCourse[index]['__status'] == 'new') {
				let n = addCourse;
				n.splice(index, 1);
				setAddCourse(n);
				setIsSaveVisible(false);
				settableDataKey(new Date().getMilliseconds());
			} else {
				if (
					window.confirm('Are you sure you want to delete this Course Details?')
				) {
					putpost2(
						concessionServiceBaseUrl + '/course/' + addCourse[index].id,
						(data) => {
							successToast(data['message']);
							getBUAndSecurityData();
							if (addCourse.length == 1) {
								previousPage();
								fetchClasses(
									concessionServiceBaseUrl +
										`/course/getCourseByBuAndCourseSecurityId/${buAndCourseSecurityId}`
								);
							} else {
								var _tempArray = addCourse;
								if (_tempArray[index]['editMode']) {
									setIsSaveVisible(false);
								}
								_tempArray.splice(index, 1);
								setAddCourse(_tempArray);
							}
							settableDataKey(new Date().getMilliseconds());
						},
						(data) => {
							failureToast(data['message']);
						},
						'Delete'
					);
				}
			}
		} catch (e) {
			console.log('Error', e);
		}
	};

	const closeRecord = (index) => {
		try {
			let n = addCourse;
			if (n[index]['__status'] == 'new') {
				n.splice(index, 1);
			} else {
				// n[index]['editMode']=false
				fetchClasses(
					concessionServiceBaseUrl +
						`/course/getCourseByBuAndCourseSecurityId/${buAndCourseSecurityId}`
				);
			}
			setAddCourse(n);
			setCourseDescription('');
			setIsSaveVisible(false);
		} catch (e) {
			console.log('Error', e);
		}
	};

	return (
		<Container className="mt-3" fluid>
			<Row>
				<Col className="mb-3">
					<div
						className="floatRight d-flex flex-row justify-content-between align-items-center"
						style={{ width: '35%' }}
					>
						<h3 className="text-muted">Search :</h3>
						<Input className="tdInput" style={{ width: '40%' }} />

						{!(action === 'view') ? (
							<Button
								disabled={isSaveVisible}
								color="info"
								size="sm"
								type="button"
								className={
									'floatRight mx-1' + (isSaveVisible ? ' btn-dark' : null)
								}
								onClick={() => {
									newRow();
								}}
							>
								Add Course
							</Button>
						) : null}
					</div>
				</Col>
			</Row>
			{apiLoader ? (
				<div className="mx-auto text-center" style={{ height: '100px' }}>
					<CustomLoader
						apiLoader={apiLoader}
						loaderHeight={'200px'}
						loaderWidth={'100% '}
					/>
				</div>
			) : (
				<div id="questionTable" className="table-responsive">
					<Table className="align-items-center table-flush">
						{addCourse.length > 0 ? (
							<CourseForBUAndSecurityHeader
								action={action}
								type={'paymentMethod'}
							/>
						) : (
							''
						)}
						<tbody className="list" key={tableDataKey}>
							{addCourse
								? addCourse.map((item, index) =>
										!item['editMode'] ? (
											<COURSEFORBUSECURITYTHROW
												key={index + '_class'}
												type={'addCourseForSecurity'}
												action={action}
												deleteRecord={deleteRecord}
												course={course}
												item={item}
												editRecord={editRecord}
												index={index}
												closeRecord={closeRecord}
											/>
										) : (
											<tr key={index + '-class'}>
												<td className="text-center p-2 mx-1">
													<Select2
														data={course}
														className="tdSelect"
														options={{ placeholder: 'Select...' }}
														value={item.courseId}
														disabled={isEdit}
														onChange={(e) => {
															let n = e.target.value;
															updateRecord(index, n, 'courseId');
														}}
													/>
												</td>
												<td className="text-center p-2 mx-1">
													<Label className="customLabel">
														{courseDescription}
													</Label>
												</td>
												<td className="text-center p-2 mx-1">
													<Input
														disabled={true}
														defaultValue={
															item['__status'] == 'new'
																? null
																: moment(item['effectiveDate']).format(
																		'DD-MM-YYYY'
																  )
														}
														placeholder="Example:01-01-2020"
														className="tdInput"
													/>
												</td>
												<td className="text-center p-2 mx-1">
													<Label className="custom-toggle mx-auto ml-2">
														<Input
															checked={item.status == 'ACTIVE' ? true : false}
															type="checkbox"
															onChange={(e) => {
																settableDataKey(new Date().getMilliseconds());
																let n = 'ACTIVE';
																if (!e.target.checked) {
																	n = 'INACTIVE';
																}
																updateRecord(index, n, 'status');
																setDataChange(!dataChange);
															}}
														/>
														<span
															style={{ width: '72px' }}
															className="custom-toggle-slider rounded-circle activeToggle"
															data-label-off="Inactive"
															data-label-on="Active"
														/>
													</Label>
												</td>
												<td className="text-center p-2 mx-1">
													{isSaveVisible ? (
														<>
															<Button
																size="sm"
																color="info"
																onClick={() => saveAll()}
															>
																Save
															</Button>
															<Button
																size="sm"
																color="danger"
																onClick={() => closeRecord(index)}
															>
																<i className="fas fa-times" />
															</Button>
														</>
													) : (
														<Button
															color="info"
															size="sm"
															type="button"
															onClick={() => {
																deleteRecord(index);
															}}
														>
															<i className="fas fa-trash" />
														</Button>
													)}
												</td>
											</tr>
										)
								  )
								: null}
						</tbody>
					</Table>

					{pagination.totalPage > 1 ? (
						<GetPagination
							fetchClasses={fetchClasses}
							pagination={pagination}
							previousPage={previousPage}
							firstPage={firstPage}
							lastPage={lastPage}
							nextPage={nextPage}
							setpagination={setPagination}
						/>
					) : null}
				</div>
			)}
		</Container>
	);
};

export default AddCourseForSecurity;
