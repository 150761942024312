import React, { useContext, useState } from "react";
import { Checkbox, FormControlLabel, TablePagination } from "@mui/material";
import {
  issueStockPaginationStyle,
  pageDropdownStyles,
  primaryCheckboxStyles,
} from "../constant";
import BulkSelectionFooter from "./BulkSelectionFooter";
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";

import { IssuanceIDCardContext } from "../../contextApi";
import ALoader from "views/pages/manage/common/a-loader";
import StockAlertModal from "./StockAlertModal";
import IssueStockCustomTable from "./IssueStockCustomTable";
import IssueStockConfirmation from "./IssueStockConfirmation";
import SelectTShirtSizeMaterialDialog from "./SelectTShirtSizeMaterialDialog";
import {
  getAvailableStock,
  freeMaterialBarcode,
  fetchMaterialBarcodes,
  updateElErrorBorder,
  isErrorExist,
  getIssuedBarcodeByMaterialId,
  getBatchAloKey,
  studentsEligibleForReIssue,
  multipleIssueMaterialMap,
} from "../../service";
import { getDownloadFileUrl } from "../../stockConsumption/form/helper";
import IssueStockSuccessPrompt from "./IssueStockSuccessPrompt";
import { failureToast } from "../../../../common/utils/methods/toasterFunctions/function";
import DownloadFiles from "./DownloadFiles";
import { useGetBusinessAreaType } from "../../customhooks";

const getValidTShirtMaterialExist = (
  student,
  issuedStockToStudent,
  tShirtGroupMaterials
) => {
  const isExist = false;
  if (tShirtGroupMaterials?.length === 0 || !student) return isExist;
  const validMaterialList =
    issuedStockToStudent.filter(
      (item) => item?.applicationId === student?.applicationId
    )[0]["validMaterialNumberList"] || [];
  return tShirtGroupMaterials.some((item) => validMaterialList.includes(item));
};

const GetNextPrevIcon = ({ type, isDisabled, onClickHandler }) => {
  const props = {
    size: 15,
    width: 20,
    color: isDisabled ? "transparent" : "#000000",
    onClick: isDisabled ? () => {} : onClickHandler,
  };
  return type === "next" ? <GrNext {...props} /> : <GrPrevious {...props} />;
};
const IssueStockTable = () => {
  const {
    loading,
    setLoading,
    getApplications,
    isApplicationLoading,
    studentApplications,
    setApplications,
    page,
    setPage,
    totalRecords,
    totalPage,
    filterForm,
    BuAndAcdGroup,
    issueStockToApplication,
    globalFilter,

    selectedMaterials,
    materialAndBarcodeMapping,
    setMaterialAndBarcodeMapping,
    selectedBusinessArea,
    issuedStockToStudent,

    selectedStudentsForIssueStock,
    setSelectedStudentsForIssueStock,
    setCanReIssueMaterialList,
    canReIssueMaterialList,
    selectedFilter,
    getBusinessUnitType,
    tShirtGroupMaterials,
    alreadyIssuedTshirtMaterial,
    setAlreadyIssuedTshirtMaterial,
  } = useContext(IssuanceIDCardContext);

  const [tShirtMaterialSelection, setTShirtSelection] = useState({
    isOpen: false,
    selectedMaterial: {},
    nextFnParams: {},
  });
  const [issuedSuccess, setIssuedSuccess] = useState(false);
  const [successResponse, setSuccessResponse] = useState({});
  const [apiLoader, setApiLoader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [stockAlert, setStockAlert] = useState({ isOpen: false, data: {} });
  const [issueStockConfirmation, setIssueStockConfirmation] = useState(false);
  const buOwnedType = useGetBusinessAreaType(
    filterForm?.businessArea?.businessUnitTypeId ||
      getBusinessUnitType(BuAndAcdGroup)
  );
  const [showOnlyEligible, setShowOnlyEligible] = useState(false);

  const getMaterialCount = (materialId) =>
    materialAndBarcodeMapping?.["materialCount"]?.[materialId] || 0;

  const onPageChange = (newPage) => {
    setPage(newPage);
    const materialIds = selectedMaterials.map((material) => material?.id);
    getApplications(
      newPage,
      {
        ...filterForm,
        materialIds: materialIds,
        ...globalFilter,
      },
      (list) => refreshIsEligibleFlag(list, materialIds),
      rowsPerPage
    );
  };

  const refreshIsEligibleFlag = (fetchList, materialIds) => {
    studentsEligibleForReIssue(
      fetchList,
      { ...filterForm, materialIds, BuAndAcdGroup },
      setApplications
    );
    multipleIssueMaterialMap(
      fetchList,
      { ...filterForm, materialIds, BuAndAcdGroup },
      setCanReIssueMaterialList,
      setApiLoader,
      () => {},
      [],
      setAlreadyIssuedTshirtMaterial
    );
  };
  const selectedStudentIds = Object.values(
    selectedStudentsForIssueStock
  ).filter(Boolean);

  const bulkIssueStockHandler = () => {
    issueStockToApplication(
      null,
      cleanUpCallback,
      onlyFetchNewBarcode,
      buOwnedType
    );
  };

  const singleIssueStockHandler = (student, index) => {
    if (
      !student?.stock ||
      (Array.isArray(student?.stock) && student?.stock.length === 0)
    ) {
      updateElErrorBorder(index, studentApplications, setApplications, true);
      return failureToast("Please select material to issue stock");
    }
    setIssueStockConfirmation(true);
  };

  const cleanUpCallback = (response) => {
    setIssuedSuccess(true);
    setSuccessResponse(response);
    setSelectedStudentsForIssueStock({});
    setPage(0);
    const materialIds = selectedMaterials.map((material) => material?.id);
    getApplications(
      0,
      {
        ...filterForm,
        materialIds: materialIds,
        ...globalFilter,
      },
      (list) => refreshIsEligibleFlag(list, materialIds)
    );
    getBarcodes(selectedBusinessArea?.sapPlantId, materialIds);
  };

  const onlyFetchNewBarcode = () => {
    const materialIds = selectedMaterials.map((material) => material?.id);
    getBarcodes(selectedBusinessArea?.sapPlantId, materialIds);
  };

  const getMaterialToBeIssue = (tShirtSizeMaterial) => {
    let materialsToBeIssued = [];
    materialsToBeIssued = [];
    selectedMaterials.forEach((material) => {
      if (!material?.isTShirtGroupMaterial) materialsToBeIssued.push(material);

      if (
        material?.isTShirtGroupMaterial &&
        material?.materialId === tShirtSizeMaterial?.materialId
      )
        materialsToBeIssued.push(material);
    });
    return materialsToBeIssued;
  };

  const issueStockCheckBoxHandler = async (
    isChecked,
    student,
    isAll = false,
    tShirtSizeMaterial
  ) => {
    // open popup select materials
    // handlingAllCase
    if (isChecked && !isAll)
      var isValidTShirtMaterialExist = getValidTShirtMaterialExist(
        student,
        canReIssueMaterialList,
        tShirtGroupMaterials
      );

    if (isChecked && tShirtGroupMaterials.length > 0 && !tShirtSizeMaterial) {
      if (isAll) {
        const isAtLestOneApplicationForTShirt = studentApplications.some(
          (studentRow) =>
            getValidTShirtMaterialExist(
              studentRow,
              canReIssueMaterialList,
              tShirtGroupMaterials
            )
        );
        if (isAtLestOneApplicationForTShirt) {
          setTShirtSelection((prev) => ({
            ...prev,
            isOpen: true,
            nextFnParams: {
              isChecked,
              student,
              isAll,
            },
          }));
          return;
        }
      } else {
        // is single select and no valid t-shirt available for issue then skip popup for selection
        // if(student , )
        if (isValidTShirtMaterialExist) {
          setTShirtSelection((prev) => ({
            ...prev,
            isOpen: true,
            nextFnParams: {
              isChecked,
              student,
              isAll,
            },
          }));
          return;
        }
      }
    }
    let materialsToBeIssued =
      tShirtGroupMaterials.length > 0 && tShirtSizeMaterial
        ? getMaterialToBeIssue(tShirtSizeMaterial)
        : selectedMaterials;
    // if tShirtSizeMaterial then ignore other tShirtSize and skip material

    const tempApplication = [...studentApplications];
    if (isAll) {
      if (isChecked) {
        const tempIds = {};
        tempApplication.forEach((item) => {
          if (item?.isEligibleForStockIssuance) {
            const stocks = getAvailableStock(
              item,
              materialsToBeIssued,
              issuedStockToStudent,
              selectedBusinessArea,
              setMaterialAndBarcodeMapping,
              materialAndBarcodeMapping,
              item.applicationId,
              canReIssueMaterialList
            );
            item["isError"] = !isErrorExist(
              item,
              issuedStockToStudent,
              materialsToBeIssued,
              stocks
            );
            console.log(item);
            if (stocks.length > 0) {
              tempIds[item.applicationId] = true;
              item.stock = stocks;
            }
          }
        });
        // show alter which material count is zero
        showStockAlert();
        setApplications(tempApplication);
        setSelectedStudentsForIssueStock(tempIds);
      } else {
        // free stock and and clean it
        tempApplication.forEach((item) => {
          freeMaterialBarcode(
            item?.stock,
            setMaterialAndBarcodeMapping,
            materialAndBarcodeMapping
          );

          item.stock = [];
          item["isError"] = false;
        });
        setSelectedStudentsForIssueStock({});
      }
      return;
    }
    if (!student?.isEligibleForStockIssuance) return;
    // find student index in application set stock [ available stocks]
    // setApplication
    // single student case ;
    const tempIds = { ...selectedStudentsForIssueStock };
    let stocks = [];
    tempApplication.forEach((item) => {
      if (item.applicationId === student.applicationId) {
        //tempIds[item.applicationId] = true;
        if (isChecked) {
          stocks = getAvailableStock(
            item,
            materialsToBeIssued,
            issuedStockToStudent,
            selectedBusinessArea,
            setMaterialAndBarcodeMapping,
            materialAndBarcodeMapping,
            item.applicationId,
            canReIssueMaterialList
          );
          item.stock = stocks;
          item["isError"] = !isErrorExist(
            student,
            issuedStockToStudent,
            materialsToBeIssued,
            stocks
          );
          //  console.log(isErrorExist(student,issuedStockToStudent, selectedMaterials, stocks ))
        } else {
          // free barcode
          freeMaterialBarcode(
            item?.stock,
            setMaterialAndBarcodeMapping,
            materialAndBarcodeMapping
          );
          item.stock = [];
          item["isError"] = false;
        }
      }
    });
    if (isChecked) showStockAlert();
    if (stocks.length === 0 && isChecked) {
      // show alter which material count is zero
      return;
    }

    setApplications(tempApplication);
    tempIds[student?.applicationId] = isChecked;
    setSelectedStudentsForIssueStock(tempIds);
  };

  const getBuAndAcdGroup = () => {
    return `${filterForm?.businessArea?.label || BuAndAcdGroup?.businessArea}-${
      filterForm?.academicGroup?.label || BuAndAcdGroup?.academicGroup
    }-${filterForm?.term?.[0]?.label || BuAndAcdGroup?.termDispValue}`;
  };
  // refresh barcodes after issued
  const getBarcodes = async (plantId, materialIds) => {
    setLoading(true);
    const businessAreaId =
      filterForm?.businessArea?.value || BuAndAcdGroup?.businessAreaId;
    const res = await fetchMaterialBarcodes(
      plantId,
      materialIds,
      businessAreaId
    );
    setMaterialAndBarcodeMapping(res);
    setLoading(false);
  };

  const deSelectAllHandler = () => {
    issueStockCheckBoxHandler(false, {}, true);
  };

  const showStockAlert = () => {
    const materialCountMapping = {};
    selectedMaterials.forEach((item) => {
      const avlBarcodeCount =
        getMaterialCount(item?.id) -
        getIssuedBarcodeByMaterialId(materialAndBarcodeMapping, item?.id);
      if (avlBarcodeCount === 0) materialCountMapping[item?.id] = item;
    });
    // all stock check
    const outOfStockList = Object.keys(materialCountMapping);
    //  console.log(outOfStockList);
    if (outOfStockList.length > 0) {
      const metaData = {
        isOpen: true,
        data: Object.values(materialCountMapping),
      };
      setStockAlert({ ...metaData });
    }
  };

  const downloadAllocatedInBatch = async (type) => {
    setLoading(true);
    const response = await getBatchAloKey(
      filterForm,
      type,
      page,
      selectedMaterials.map((material) => material?.id)
    );
    if (response?.code === 200) {
      getDownloadFileUrl(response?.data.key);
    }

    setLoading(false);
  };

  const handleChangePageSize = (e) => {
    setRowsPerPage(e?.target?.value);
    setPage(0);
    const materialIds = selectedMaterials.map((material) => material?.id);
    getApplications(
      0,
      {
        ...filterForm,
        materialIds: materialIds,
        ...globalFilter,
      },
      (list) => refreshIsEligibleFlag(list, materialIds),
      e?.target?.value
    );
  };

  return (
    <>
      <div className="d-flex flex-column">
        {(isApplicationLoading || loading || apiLoader) && (
          <ALoader position="fixed" />
        )}
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="heading-4 color-black-60 flex-grow-1">
            Issue Stock | {getBuAndAcdGroup()}
          </div>
          <FormControlLabel
            disableTypography
            sx={{ marginBottom: "0px", ...primaryCheckboxStyles }}
            control={
              <Checkbox
                onChange={(e) => {
                  setShowOnlyEligible(e?.target?.checked);
                }}
                checked={showOnlyEligible}
                disabled={selectedStudentIds?.length > 1}
              />
            }
            label={
              <div className="semi-bold">Show only eligible student(s)</div>
            }
          />

          {selectedFilter?.toLowerCase() === "bulk" && (
            <DownloadFiles downloadHandler={downloadAllocatedInBatch} />
          )}
        </div>
        <>
          <div style={{ overflowX: "auto" }}>
            <div style={{ minWidth: "100%", width: "max-content" }}>
              <IssueStockCustomTable
                students={
                  showOnlyEligible
                    ? studentApplications.filter(
                        (student) => student.isEligibleForStockIssuance
                      )
                    : studentApplications
                }
                {...{
                  issueStockToApplication: singleIssueStockHandler,
                  issueStockCheckBoxHandler,
                  selectedStudentsForIssueStock,
                  selectedStudentIds,
                  setSelectedStudentsForIssueStock,
                  alreadyIssuedTshirtMaterial,
                }}
              />
            </div>
          </div>
          <TablePagination
            component={"div"}
            page={page}
            onPageChange={(e, newPage) => onPageChange(newPage)}
            count={totalRecords}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangePageSize}
            rowsPerPageOptions={[25, 50, 75, 100]}
            sx={issueStockPaginationStyle}
            ActionsComponent={() => (
              <div
                className="d-flex gap-md"
                style={{
                  fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
                }}
              >
                <select
                  style={pageDropdownStyles}
                  value={page}
                  onChange={(e) => onPageChange(e.target.value)}
                >
                  {[...Array(totalPage).keys()].map((pageNumber) => (
                    <option key={pageNumber} value={pageNumber}>
                      {pageNumber + 1}
                    </option>
                  ))}
                </select>
                <span>of {totalPage} pages </span>
                <span className="cursor d-flex gap-md align-items-center ">
                  <GetNextPrevIcon
                    isDisabled={page === 0}
                    onClickHandler={() => onPageChange(page - 1)}
                  />
                  <GetNextPrevIcon
                    type="next"
                    isDisabled={page + 1 === totalPage}
                    onClickHandler={() => onPageChange(page + 1)}
                  />
                </span>
              </div>
            )}
          />
        </>
      </div>
      {tShirtMaterialSelection?.isOpen && (
        <SelectTShirtSizeMaterialDialog
          selectedMaterials={selectedMaterials}
          open={tShirtMaterialSelection?.isOpen}
          issueStockCheckBoxHandler={issueStockCheckBoxHandler}
          setTShirtSelection={setTShirtSelection}
          tShirtMaterialSelection={tShirtMaterialSelection}
        />
      )}
      <IssueStockSuccessPrompt
        isOpen={issuedSuccess}
        setIsOpen={setIssuedSuccess}
        responseData={successResponse}
      />
      <StockAlertModal
        open={stockAlert?.isOpen}
        setOpen={() => setStockAlert({ isOpen: false, data: {} })}
        selectedMaterials={stockAlert?.data}
        getMaterialCount={() => 0}
        isStockAlert={true}
      />
      <IssueStockConfirmation
        open={issueStockConfirmation}
        setOpen={setIssueStockConfirmation}
        onAgree={() => {
          issueStockToApplication(
            null,
            cleanUpCallback,
            onlyFetchNewBarcode,
            buOwnedType
          );
          setIssueStockConfirmation(false);
        }}
      />

      {selectedStudentIds.length > 1 && (
        <BulkSelectionFooter
          selectionModel={selectedStudentIds}
          setSelectionModel={setSelectedStudentsForIssueStock}
          bulkIssueStockHandler={bulkIssueStockHandler}
          deSelectAllHandler={deSelectAllHandler}
        />
      )}
    </>
  );
};

export default IssueStockTable;
