import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import AddApprovalsTable from "./addApprovalsTable";
import Loader from "react-loader-spinner";
import { masterServiceBaseUrl, failureToast, getAPI } from "services/http";
import { CardFooter, Pagination } from "reactstrap";
import {
  PaginationNode,
  PaginationPage,
  GetPagination,
} from "../../../../common/commonComponents/pagination/pagination";

const AddApprovals = (props) => {
  const { id } = props;
  const [classArray, setclassArray] = useState([]);
  const [apiloader, setapiloader] = useState(false);
  const [state, setstate] = useState([]);
  const [levelData,setLevelData] = useState(null)

  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const pageSize = 10;
  const fetchClasses = async (url) => {
    setapiloader(true);
    getAPI(
      url,
      (data) => {
        SetDataFromServer(data["data"]);
        // props.getAddApprovalData()
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  };

  const SetDataFromServer = (data) => {
    setstate(data);
    let n = pagination;
    if (data.length > pageSize) {
      n["nextPage"] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n["totalPage"] = Math.floor(data.length / pageSize);
    if (data.length % pageSize != 0) {
      n["totalPage"] += 1;
    }
    setpagination(n);
    setapiloader(false);
    // console.log(n);
    setclassArray(
      data.slice(
        pagination.currentPage * pageSize - pageSize,
        pagination.currentPage * pageSize
      ) || []
    );
  };

  const previousPage = () => {
    if (pagination.currentPage != 1) {
      setclassArray(
        state.slice(
          (pagination.currentPage - 1) * pageSize - pageSize,
          (pagination.currentPage - 1) * pageSize
        )
      );
      // console.log(
      //   state.slice(
      //     (pagination.currentPage - 1) * pageSize - pageSize,
      //     (pagination.currentPage - 1) * pageSize
      //   )
      // );
      let n = pagination;
      n["currentPage"] = n["currentPage"] - 1;
      if (n["currentPage"] == 1) {
        n["previousPage"] = null;
      } else {
        n["previousPage"] = n.currentPage;
      }
      setpagination(n);
    }
  };

  const fetchDropdown = async () => {
    setapiloader(true);
    getAPI(
      `${masterServiceBaseUrl}/approvalLevel/getAllApprovalLevel`,
      (data) => {
          const dropList = data.data.map((data) => {
            return { id: data.id, text: data[`approvalLevelDispValue`] };
          });
        
        setLevelData(dropList);
        // console.log(dropList)
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  };

  const getTaxCodeData = async () => {
    fetchClasses(`${masterServiceBaseUrl}/addConcessionApproval/getAddConcessionApprovalByConcessionApprovalSetupId/${id}`)
  };

  useEffect(() => {
    fetchDropdown()
  }, []);

  useEffect(() => {
    getTaxCodeData();
  }, [id]);

  const decrementCount = () =>{
    props.setTotalLevel(props.totallevel-1)
  }

  const incrementCount = () =>{
    props.setTotalLevel(props.totallevel+1)
  }
  

  return (
    <>
      {!levelData && apiloader &&
      id ? (
        <div
          className="mx-auto text-center py-5 my-5"
          style={{ height: "100vh" }}
        >
          <Loader type="Rings" color="#00BFFF" height={200} width={200} />
        </div>
      ) : (
        <div>
          <AddApprovalsTable
            previousPage={previousPage}
            fetchClasses={fetchClasses}
            setclassArray={setclassArray}
            classArray={classArray}
            levelData={levelData}
            id={id}
            setstate={setstate}
            state={state}
            pagination={pagination}
            setpagination={setpagination}
            SetDataFromServer={SetDataFromServer}
            decrementCount={decrementCount}
            incrementCount={incrementCount}
          />
          {pagination.totalPage > 1 ? (
            <GetPagination
              fetchClasses={fetchClasses}
              setclassArray={setclassArray}
              pagination={pagination}
              setpagination={setpagination}
              pageSize={pageSize}
              state={state}
            />
          ) : null}
        </div>
      )}
    </>
  );
};

export default AddApprovals;
