import { masterServiceBaseUrl } from 'services/http';
import DepositSlip from 'views/pages/manage/unAssigned/depositSlip';
export const masterServiceEndpoints = {
	//business Area
	businessArea: {
		search: `${masterServiceBaseUrl}/businessArea/getBusinessAreaSearch`,
		getAllActiveByGroupCodeAndComapnyCode: `${masterServiceBaseUrl}/businessArea/getBusinessAreaByCompanyGroupCode`,
		getBusinessUnitType: `${masterServiceBaseUrl}/businessArea/getBusinessAreaBusinessUnitTypeByBusinessAreaId`,
		getAllActiveBusinessAreaByRegions: `${masterServiceBaseUrl}/businessArea/getAllActiveBusinessAreaByRegions`,
		getAll: `${masterServiceBaseUrl}/businessArea/getAllBusinessArea`,
		getBusinessAreaById: `${masterServiceBaseUrl}/businessArea/getBusinessArea`,
		getAllActive: `${masterServiceBaseUrl}/businessArea/getAllActiveBusinessArea`,
		getByCriteria: `${masterServiceBaseUrl}/businessArea/getBusinessAreaByMultiCriteria`,
		exportExcel: `${masterServiceBaseUrl}/businessArea/exportExcelBusinessAreaSearch`,
		getAllCached: `${masterServiceBaseUrl}/businessArea/getAllActiveBusinessAreaCache`,
		getByAcademicCareerList : `${masterServiceBaseUrl}/businessArea/getBusinessAreaByAcademicCareerList`,
		getAllDlpActiveBusinessArea: `${masterServiceBaseUrl}/businessArea/getAllDLPActiveBusinessArea`,
		getBusinessAreaContactDetailsLocation : `${masterServiceBaseUrl}/businessArea/getBusinessAreaContactDetailsLocation`,
		getAllDigitalBusinessArea: `${masterServiceBaseUrl}/businessArea/getAllDigitalBusinessArea`,
	},
	jobTitle: {
		create: `${masterServiceBaseUrl}/jobTitle/createJobTitle`,
		delete: `${masterServiceBaseUrl}/jobTitle/deleteJobTitle`,
		getAllActive: `${masterServiceBaseUrl}/jobTitle/getAllActiveJobTitle`,
		getAll: `${masterServiceBaseUrl}/jobTitle/getAllJobTitle`,
		getById: `${masterServiceBaseUrl}/jobTitle/getJobTitle`,
		update: `${masterServiceBaseUrl}/jobTitle/updateJobTitle`,
	},
	//curriculum_management_endpoints
	subject: {
		getAll: `${masterServiceBaseUrl}/subject/getAllSubject`,
		getAllActive: `${masterServiceBaseUrl}/subject/getAllActiveSubject`,
	},
	operationalZone: {
		getAllActive: `${masterServiceBaseUrl}/operationalZone/getAllActiveOperationalZone`,
		getAllByRegionIds: `${masterServiceBaseUrl}/operationalZone/getOperationalZoneByRegionIn`
	},
	building: {
		getActiveBuildingByBusinessGroupCompanyCodeBusinessAreaRegionZone: `${masterServiceBaseUrl}/buildingMaster/getAllActiveBuildingMasterByBusinessArea`,
		getAllBuildingByBusinessGroupCompanyCodeBusinessAreaRegionZone: `${masterServiceBaseUrl}/buildingMaster/getAllBuildingMasterByBusinessArea`,
		getActiveBuildingMaster: `${masterServiceBaseUrl}/buildingMaster/getActiveBuildingMaster`,
		getAllBuildingMaster: `${masterServiceBaseUrl}/buildingMaster/getAllBuildingMaster`,
		getAllBuildingByBusinessAreaWithSecurityGroup: `${masterServiceBaseUrl}/buildingMaster/getAllBuildingByBusinessAreaWithSecurityGroup`,
		updateBuilding: `${masterServiceBaseUrl}/buildingMaster/updateBuildingMaster`,
		searchBuilding: `${masterServiceBaseUrl}/buildingMaster/searchBuildingMaster`
	},
	attendance_machine: {
		search: `${masterServiceBaseUrl}/attendanceMachineMaster/search/`,
		create: `${masterServiceBaseUrl}/attendanceMachineMaster/createAttendanceMachineMaster/`,
		update: `${masterServiceBaseUrl}/attendanceMachineMaster/updateAttendanceMachineMaster/`,
		delete: `${masterServiceBaseUrl}/attendanceMachineMaster/deleteAttendanceMachine/`,
		bulkUpload: `${masterServiceBaseUrl}/attendanceMachineMaster/createBulkAttendanceMachineMaster`,
	},
	subSubTopicMaster: {
		search: `${masterServiceBaseUrl}/subSubTopicMaster/searchSubSubTopic`,
		getAll: `${masterServiceBaseUrl}/subSubTopicMaster/getAllSubSubTopic`,
		create: `${masterServiceBaseUrl}/subSubTopicMaster/createSubSubTopic`,
		update: `${masterServiceBaseUrl}/subSubTopicMaster/updateSubSubTopic`,
		delete: `${masterServiceBaseUrl}/subSubTopicMaster/deleteSubSubTopic/`,
		bulkUpload: `${masterServiceBaseUrl}/subSubTopicMaster/createBulkSubSubTopic`,
	},

	rfId: {
		create: `${masterServiceBaseUrl}/rfidMaster/createRfidMaster`,
		createBulk: `${masterServiceBaseUrl}/rfidMaster/createBulkRfidMaster`,
		delete: `${masterServiceBaseUrl}/rfidMaster/deleteRfidMaster`,
		getAll: `${masterServiceBaseUrl}/rfidMaster/getAllRfidMaster`,
		getAllActive: `${masterServiceBaseUrl}/rfidMaster/getAllActiveRfidMaster`,
		getById: `${masterServiceBaseUrl}/rfidMaster/getRfidMaster`,
		search: `${masterServiceBaseUrl}/rfidMaster/searchRfidMaster`,
		update: `${masterServiceBaseUrl}/rfidMaster/updateRfidMaster`,
	},
	// //role
	// role_Name: {
	//   search: `${masterServiceBaseUrl}/roleMaster/searchRole`,
	//   create: `${masterServiceBaseUrl}/roleMaster/createRole`,
	//   update: `${masterServiceBaseUrl}/roleMaster/updateRole`,
	//   delete: `${masterServiceBaseUrl}/roleMaster/deleteRole`,
	//   getAll: `${masterServiceBaseUrl}/roleMaster/getAllRole`,
	//   getAllActive: `${masterServiceBaseUrl}/roleMaster/getAllActiveRole`,
	//   getRoleByID: `${masterServiceBaseUrl}/roleMaster/getRole`,
	// },
	role_Merge: {
		create: `${masterServiceBaseUrl}/roleMerge/createRoleMerge`,
		update: `${masterServiceBaseUrl}/roleMerge/updateRoleMerge`,
		delete: `${masterServiceBaseUrl}/roleMerge/deleteRoleMerge`,
		getAll: `${masterServiceBaseUrl}/roleMerge/getAllRoleMerge`,
		getAllActive: `${masterServiceBaseUrl}/roleMerge/getAllActiveRoleMerge`,
		getRoleMergeByID: `${masterServiceBaseUrl}/roleMerge/getRoleMerge`,
		getRoleMergeByNewRoleID: `${masterServiceBaseUrl}/roleMerge/getRoleMergeByNewRoleId`,
	},

	//role to process detail
	role_To_Process: {
		create: `${masterServiceBaseUrl}/roleToProcessDetail/createRoleToProcessDetail`,
		createPermission: `${masterServiceBaseUrl}/roleToProcessDetail/createRoleToProcessDetailRolePermission`,
		deleteRole: `${masterServiceBaseUrl}/roleToProcessDetail/deleteRoleToProcessDetailById`,
		getAll: `${masterServiceBaseUrl}/roleToProcessDetail/getAllRoleToProcessDetails`,
		getById: `${masterServiceBaseUrl}/roleToProcessDetail/getRoleToProcessDetailById`,
		searchByRoleMasterId: `${masterServiceBaseUrl}/roleToProcessDetail/searchRoleToProcessDetailByRoleMasterId`,
		searchByProcessIdAndRoleId: `${masterServiceBaseUrl}/roleToProcessDetail/searchRoleToProcessDetailRolePermissionByRoleMasterIdAndProcessTagTypeMasterId`,
	},

	//sf_master
	groupCode: {
		getAllActive: `${masterServiceBaseUrl}/groupCode/getAllActiveGroupCode`,
		getById: `${masterServiceBaseUrl}/groupCode/getGroupCode`,
	},
	companyCode: {
		getAllActiveByGroupCode: `${masterServiceBaseUrl}/companyCode/getCompanyCodeByGroup`,
		getById: `${masterServiceBaseUrl}/companyCode/getCompanyCode`,
		getAllActive: `${masterServiceBaseUrl}/companyCode/getAllActiveCompanyCode`,
		getByGrpCodeIds: `${masterServiceBaseUrl}/companyCode/getCompanyCodeByGroupIn`
	},
	region: {
		getAllActive: `${masterServiceBaseUrl}/region/getAllActiveRegion`,
		getAll: `${masterServiceBaseUrl}/region/getAllRegion`,
		getById: `${masterServiceBaseUrl}/region/getRegion`,
	},

	machine_type: {
		getAll: `${masterServiceBaseUrl}/machineType/getAllMachineType`,
		getAllActive: `${masterServiceBaseUrl}/machineType/getAllActiveMachineType`,
	},

	//process tag
	process_Tag: {
		create: `${masterServiceBaseUrl}/processTag/createProcessTag`,
		delete: `${masterServiceBaseUrl}/processTag/deleteProcessTag`,
		getAllActive: `${masterServiceBaseUrl}/processTag/getAllActiveProcessTag`,
		getAll: `${masterServiceBaseUrl}/processTag/getAllProcessTag`,
		getById: `${masterServiceBaseUrl}/processTag/getProcessTag`,
		update: `${masterServiceBaseUrl}/processTag/updateProcessTag`,
		search: `${masterServiceBaseUrl}/processTag/searchProcess`,
		getTemplatesProcessTypes: `${masterServiceBaseUrl}/processTag/getAllProcessTagDownloadTemplate`,
	},

	//user role
	processToPage: {
		getAllByProcessTagId: `${masterServiceBaseUrl}/processPage/getProcessPageBYProcessId`,
		create: `${masterServiceBaseUrl}/processPage/createProcessPage`,
		update: `${masterServiceBaseUrl}/processPage/updateProcessPage`,
		delete: `${masterServiceBaseUrl}/processPage/deleteProcessPage`,
	},

	//topic
	topic: {
		getAll: `${masterServiceBaseUrl}/topicMaster/getAllTopicMaster`,
		createBulk: `${masterServiceBaseUrl}/topicMaster/createBulkTopicMaster`,
		create: `${masterServiceBaseUrl}/topicMaster/createTopicMaster`,
		update: `${masterServiceBaseUrl}/topicMaster/updateTopicMaster`,
	},

	//chapter
	chapter: {
		getAll: `${masterServiceBaseUrl}/chapterMaster/getAllChapterMaster`,
		createBulk: `${masterServiceBaseUrl}/chapterMaster/createBulkChapterMaster`,
		create: `${masterServiceBaseUrl}/chapterMaster/createChapterMaster`,
		update: `${masterServiceBaseUrl}/chapterMaster/updateChapterMaster`,
	},

	//approval status
	approvalStatus: {
		create: `${masterServiceBaseUrl}/approvalStatus/createApprovalStatusMaster`,
		delete: `${masterServiceBaseUrl}/approvalStatus/deleteApprovalStatusMaster`,
		getAll: `${masterServiceBaseUrl}/approvalStatus/getAllApprovalStatusMaster`,
		getAllActive: `${masterServiceBaseUrl}/approvalStatus/getAllActiveApprovalStatusMaster`,
		update: `${masterServiceBaseUrl}/approvalStatus/updateApprovalStatusMaster`,
	},

	//course catelog header
	courseCatelogHeader: {
		getByCompanyCode: `${masterServiceBaseUrl}/courseCatalogCourseHeader/getCourseCatalogCourseHeaderByCompanyCode`,
		getByGroupCodeAndCompanyCode: `${masterServiceBaseUrl}/courseCatalogCourseHeader/getCourseCatalogCourseHeaderByGroupCodeAndCompanyCode`,
		getByGroupCodeAndCompanyCodeAndSecurity: `${masterServiceBaseUrl}/courseCatalogCourseHeader/getCourseCatalogCourseHeaderByCompanyCodeAndGroupCode`,
	},

	courseCatelogMapping: {
		getAllActiveCourseIds: `${masterServiceBaseUrl}/courseCatalogMapping/getActiveCourseIds`,
	},

	//term
	term: {
		getAllActive: `${masterServiceBaseUrl}/term/getAllActiveTerm`,
		getAllTermMappingOnGroupCodeCompanyCodeAndAcademicCareer: `${masterServiceBaseUrl}/termMapping/getTermCodeByGroupCodeAndAcademicCareerAndCompanyCode`,
		getTermDate: `${masterServiceBaseUrl}/termDateMaster/getTermDateByTermAndTermMapping`,
		getById: `${masterServiceBaseUrl}/term/getTerm`,
		getAllTerm: `${masterServiceBaseUrl}/term/getAllTerm`,
		getAllActiveUpcomingTerm:  `${masterServiceBaseUrl}/term/getAllTermInCurrentDuration`
	},

	//academic Group
	academic_group: {
		getById: `${masterServiceBaseUrl}/academicGroup/getAcademicGroup`,
		getAllActive: `${masterServiceBaseUrl}/academicGroup/getAllActiveAcademicGroup`,
		getAllByCompanyCodeIds: `${masterServiceBaseUrl}/academicGroup/getAcademicGroupByCompanyCodeIn`
	},

	//academic Career
	academic_career: {
		getById: `${masterServiceBaseUrl}/academicCareer/getAcademicCareer`,
		getAllActive: `${masterServiceBaseUrl}/academicCareer/getAllActiveAcademicCareer`,
		getAll: `${masterServiceBaseUrl}/academicCareer/getAllAcademicCareer`,
		getAllByCompanyCodeIds: `${masterServiceBaseUrl}/academicCareer/getAcademicCareerByCompanyCodeIn`
	},

	//course component
	course_component: {
		getById: `${masterServiceBaseUrl}/courseComponent/getCourseComponent`,
	},

	// course category
	courseCategory: {
		getAllActiveCourseCategory: `${masterServiceBaseUrl}/courseAttributeValue/getAllActiveCourseCategory`,
		getAllCourseCategory: `${masterServiceBaseUrl}/courseAttributeValue/getAllCourseCategory`,
		
	},

	courseCategoryOtherCharges : {
		getAllActiveCourseCategoryOtherCharges: `${masterServiceBaseUrl}/courseAttributeValue/getAllCourseCategoryForOtherCharges`,
	},

	//course details
	course_details: {
		getAll: `${masterServiceBaseUrl}/courseDetail/getAllCourseDetail`,
		getByCourseId: `${masterServiceBaseUrl}/courseDetail/getCourseDetailByCourseId`,
		getCourseDetailsByCourseId: `${masterServiceBaseUrl}/courseDetail/getCourseDetailMasterByCourseId`,
		getCourseIdByAcademicCareerAndAcademicGroup: `${masterServiceBaseUrl}/courseDetail/getCourseDetailByAcademicCareerAndAcademicGroup?`,
		getCachedRecords: `${masterServiceBaseUrl}/courseDetail/getAllCourseDetailCache`,
		courseAttribute :  `${masterServiceBaseUrl}/courseAttributeValue/getAllActiveCourseAttributeValue`,
		courseIdByCourseAttribute : `${masterServiceBaseUrl}/associatedAttribute/getCourseIdFromAttributeValueIdList`,
		courseIdsByCourseType: `${masterServiceBaseUrl}/courseDetail/getAllCourseDetailForOtherChargeCourseCategory/courseAttributeValueId`,
		courseTypes : `${masterServiceBaseUrl}/courseAttributeValue/getAllCourseCategoryForOtherCharges`
	},

	//pricing Zone
	pricing_zone: {
		search: `${masterServiceBaseUrl}/pricingZone/searchPricingZone`,
		create: `${masterServiceBaseUrl}/pricingZone/createPricingZone`,
		update: `${masterServiceBaseUrl}/pricingZone/updatePricingZone`,
		delete: `${masterServiceBaseUrl}/pricingZone/deletePricingZone`,
		getAll: `${masterServiceBaseUrl}/pricingZone/getAllPricingZone`,
		getAllActive: `${masterServiceBaseUrl}/pricingZone/getAllActivePricingZone`,
		getById: `${masterServiceBaseUrl}/pricingZone/getPricingZone`,
		getByGroupCodeAndCompanyCode: `${masterServiceBaseUrl}/pricingZone/getPricingZoneByGroupIdAndCompanyId`,
	},

	//pricingZone_BusinessArea
	pricingZone_BusinessArea: {
		create: `${masterServiceBaseUrl}/pricingZoneBusinessArea/createPricingZoneBusinessArea`,
		update: `${masterServiceBaseUrl}/pricingZoneBusinessArea/updatePricingZoneBusinessArea`,
		delete: `${masterServiceBaseUrl}/pricingZoneBusinessArea/deletePricingZoneBusinessArea`,
		getAll: `${masterServiceBaseUrl}/pricingZoneBusinessArea/getAllPricingZoneBusinessArea`,
		getAllActive: `${masterServiceBaseUrl}/pricingZoneBusinessArea/getAllActivePricingZoneBusinessArea`,
		getPricingZoneBusinessAreaById: `${masterServiceBaseUrl}/pricingZoneBusinessArea/getPricingZoneBusinessArea`,
		getBusinessAreaByPricingId: `${masterServiceBaseUrl}/pricingZoneBusinessArea/getBusinessAreaByPricingZoneId`,
		getBusinessAreaListByPricingId: `${masterServiceBaseUrl}/pricingZoneBusinessArea/getBusinessAreaByPricingZoneId/list`,
	},

	//academic year
	academic_year: {
		getAcademicCareers: `${masterServiceBaseUrl}/academicYear/getAcademicYearByGroupAndCompanyAndAcademicCareerAndTermId`,
		getActiveAcademicCareers: `${masterServiceBaseUrl}/academicYear/getActiveAcademicYearByGroupAndCompanyAndAcademicCareerAndTermId`,
		getAllActive: `${masterServiceBaseUrl}/academicYear/active`,
		getById: `${masterServiceBaseUrl}/academicYear`,
	},

	//itemTypeSetup
	item_type_setup: {
		getAll: `${masterServiceBaseUrl}/itemTypeSetup/getAllItemTypeSetup`,
		getAllActive: `${masterServiceBaseUrl}/itemTypeSetup/getActiveItemTypeSetup`,
	},

	//item Type Sequence
	item_type_sequence: {
		itemTypeSequenceSetupDetailsWithTaxByGroupCodeAndCompanyCode: `${masterServiceBaseUrl}/itemTypeSequenceSetupDetail/getAllItemTypeSequenceSetupDetailsWithTaxByGroupCodeAndCompanyCode`,
	},

	//Language Master
	languageMaster: {
		create: `${masterServiceBaseUrl}/languageMaster/createLanguageMaster`,
		update: `${masterServiceBaseUrl}/languageMaster/updateLanguageMaster`,
		delete: `${masterServiceBaseUrl}/languageMaster/deleteLanguageMaster`,
		getAll: `${masterServiceBaseUrl}/languageMaster/getAllLanguageMaster`,
		getActiveLanguageMaster: `${masterServiceBaseUrl}/languageMaster/getActiveLanguageMaster`,
		getLanguageMasterById: `${masterServiceBaseUrl}/languageMaster/getLanguageMasterById`,
		searchLanguageMaster: `${masterServiceBaseUrl}/languageMaster/searchLanguageMaster`,
	},

	courseAttribute: {
		getCRTYId: `${masterServiceBaseUrl}/courseAttribute/getCourseAttributeByDisplayValue?displayValue=`,
		getAllActive: `${masterServiceBaseUrl}/courseAttribute/getAllActiveCourseAttribute`,
		getMiscCourses: `${masterServiceBaseUrl}/courseAttribute/getCoursesByAttributeKeyValue?attributeKey=CRCT&attributeValue=MSCR`,
	},

	//website Sync
	websiteSync: {
		getAll: `${masterServiceBaseUrl}/websiteSync/getAllWebsiteSync`,
		create: `${masterServiceBaseUrl}/websiteSync/createWebsiteSync`,
		update: `${masterServiceBaseUrl}/websiteSync/updateWebsiteSync`,
		delete: `${masterServiceBaseUrl}/websiteSync/deleteWebsiteSync`,
	},

	//parentOccupation
	parentOccupation: {
		getAll: `${masterServiceBaseUrl}/parentsOccupation/getAllParentsOccupation`,
		create: `${masterServiceBaseUrl}/parentsOccupation/createParentsOccupation`,
		update: `${masterServiceBaseUrl}/parentsOccupation/updateParentsOccupation`,
		delete: `${masterServiceBaseUrl}/parentsOccupation/deleteParentsOccupation`,
	},
	// phone type
	phoneType: {
		getAll: `${masterServiceBaseUrl}/phoneType/getAllPhoneType`,
		create: `${masterServiceBaseUrl}/phoneType/createPhoneType`,
		update: `${masterServiceBaseUrl}/phoneType/updatePhoneType`,
		delete: `${masterServiceBaseUrl}/phoneType/deletePhoneType`,
	},

	// Address Type
	addressType: {
		getAll: `${masterServiceBaseUrl}/addressType/getAllAddressType`,
		create: `${masterServiceBaseUrl}/addressType/createAddressType`,
		update: `${masterServiceBaseUrl}/addressType/updateAddressType`,
		delete: `${masterServiceBaseUrl}/addressType/deleteAddressType`,
	},

	// Email Type
	emailType: {
		getAll: `${masterServiceBaseUrl}/emailType/getAllEmailType`,
		create: `${masterServiceBaseUrl}/emailType/createEmailType`,
		update: `${masterServiceBaseUrl}/emailType/updateEmailType`,
		delete: `${masterServiceBaseUrl}/emailType/deleteEmailType`,
	},

	// Admission Type
	admissionType: {
		getAll: `${masterServiceBaseUrl}/admissionType/getAllAdmissionType`,
		create: `${masterServiceBaseUrl}/admissionType/createAdmissionType`,
		update: `${masterServiceBaseUrl}/admissionType/updateAdmissionType`,
		delete: `${masterServiceBaseUrl}/admissionType/deleteAdmissionType`,
	},

	// Category
	category: {
		getAll: `${masterServiceBaseUrl}/category/getAllCategory`,
		create: `${masterServiceBaseUrl}/category/createCategory`,
		update: `${masterServiceBaseUrl}/category/updateCategory`,
		delete: `${masterServiceBaseUrl}/category/deleteCategory`,
	},

	// Student Id Status
	studentIdStatus: {
		getAll: `${masterServiceBaseUrl}/studentIdStatus/getAllStudentIdStatus`,
		create: `${masterServiceBaseUrl}/studentIdStatus/createStudentIdStatus`,
		update: `${masterServiceBaseUrl}/studentIdStatus/updateStudentIdStatus`,
		delete: `${masterServiceBaseUrl}/studentIdStatus/deleteStudentIdStatus`,
	},

	// Blood Group
	bloodGroup: {
		getAll: `${masterServiceBaseUrl}/bloodGroup/getAllBloodGroup`,
		create: `${masterServiceBaseUrl}/bloodGroup/createBloodGroup`,
		update: `${masterServiceBaseUrl}/bloodGroup/updateBloodGroup`,
		delete: `${masterServiceBaseUrl}/bloodGroup/deleteBloodGroup`,
	},

	// Sale Type
	saleType: {
		getAll: `${masterServiceBaseUrl}/saleType/getAllSaleType`,
		create: `${masterServiceBaseUrl}/saleType/createSaleType`,
		update: `${masterServiceBaseUrl}/saleType/updateSaleType`,
		delete: `${masterServiceBaseUrl}/saleType/deleteSaleType`,
	},

	// Exam Group
	examGroup: {
		getAll: `${masterServiceBaseUrl}/examGroup/getAllExamGroup`,
		create: `${masterServiceBaseUrl}/examGroup/createExamGroupMaster`,
		update: `${masterServiceBaseUrl}/examGroup/updateExamGroupMaster`,
		delete: `${masterServiceBaseUrl}/examGroup/deleteExamGroupMaster`,
	},

	// Exam Pattern
	examPattern: {
		getAll: `${masterServiceBaseUrl}/examPattern/getAllExamPattern`,
		create: `${masterServiceBaseUrl}/examPattern/createExamPattern`,
		update: `${masterServiceBaseUrl}/examPattern/updateExamPattern`,
		delete: `${masterServiceBaseUrl}/examPattern/deleteExamPattern`,
	},

	// Exam Board Type
	examBoardType: {
		getAll: `${masterServiceBaseUrl}/examBoardType/getAllExamBoardType`,
		create: `${masterServiceBaseUrl}/examBoardType/createExamBoardType`,
		update: `${masterServiceBaseUrl}/examBoardType/updateExamBoardType`,
		delete: `${masterServiceBaseUrl}/examBoardType/deleteExamBoardType`,
		getAllActive: `${masterServiceBaseUrl}/examBoardType/getAllActiveExamBoardType`,
		getExamBoardTypeById: `${masterServiceBaseUrl}/examBoardType/getExamBoardType`,
	},

	// Test type
	testType: {
		getAll: `${masterServiceBaseUrl}/testType/getAllTestType`,
		getAllActive: `${masterServiceBaseUrl}/testType/getAllActiveTestType`,
		create: `${masterServiceBaseUrl}/testType/createTestType`,
		update: `${masterServiceBaseUrl}/testType/updateTestType`,
		delete: `${masterServiceBaseUrl}/testType/deleteTestType`,
	},

	// Test mode
	testMode: {
		getAll: `${masterServiceBaseUrl}/testMode/getAllTestMode`,
		create: `${masterServiceBaseUrl}/testMode/createTestMode`,
		update: `${masterServiceBaseUrl}/testMode/updateTestMode`,
		delete: `${masterServiceBaseUrl}/testMode/deleteTestMode`,
	},

	// Address Category
	addressCategory: {
		getAll: `${masterServiceBaseUrl}/addressCategory/getAllAddressCategory`,
		create: `${masterServiceBaseUrl}/addressCategory/createAddressCategory`,
		update: `${masterServiceBaseUrl}/addressCategory/updateAddressCategory`,
		delete: `${masterServiceBaseUrl}/addressCategory/deleteAddressCategory`,
	},

	// Meeting Type
	meetingType: {
		getAll: `${masterServiceBaseUrl}/meetingType/getAllMeetingType`,
		create: `${masterServiceBaseUrl}/meetingType/createMeetingType`,
		update: `${masterServiceBaseUrl}/meetingType/updateMeetingType`,
		delete: `${masterServiceBaseUrl}/meetingType/deleteMeetingType`,
	},

	// Adjustment Flag
	adjustmentFlag: {
		getAll: `${masterServiceBaseUrl}/adjustmentFlagMaster/getAllAdjustmentFlagMasters`,
		create: `${masterServiceBaseUrl}/adjustmentFlagMaster/createAdjustmentFlagMaster`,
		update: `${masterServiceBaseUrl}/adjustmentFlagMaster/updateAdjustmentFlagMaster`,
		delete: `${masterServiceBaseUrl}/adjustmentFlagMaster/deleteAdjustmentFlagMaster`,
	},

	// Gender
	gender: {
		getAll: `${masterServiceBaseUrl}/gender/getAllGender`,
		create: `${masterServiceBaseUrl}/gender/createGender`,
		update: `${masterServiceBaseUrl}/gender/updateGender`,
		delete: `${masterServiceBaseUrl}/gender/deleteGender`,
	},

	//depae=rtment type
	departmentType: {
		getAll: `${masterServiceBaseUrl}/departmentType/getAllDepartmentType`,
		create: `${masterServiceBaseUrl}/departmentType/createDepartmentType`,
		update: `${masterServiceBaseUrl}/departmentType/updateDepartmentType`,
		delete: `${masterServiceBaseUrl}/departmentType/deleteDepartmentType`,
	},

	//job title
	// jobTitle: {
	// 	getAll: `${masterServiceBaseUrl}/jobTitle/getAllJobTitle`,
	// 	create: `${masterServiceBaseUrl}/jobTitle/createJobTitle`,
	// 	update: `${masterServiceBaseUrl}/jobTitle/updateJobTitle`,
	// 	delete: `${masterServiceBaseUrl}/jobTitle/deleteJobTitle`,
	// },

	//Payment Methods
	paymentMethod: {
		getAll: `${masterServiceBaseUrl}/BusinessAreaPaymentMethod/getAllBusinessAreaPaymentMethod`,
		create: `${masterServiceBaseUrl}/paymentMethod/createPaymentMethod`,
		update: `${masterServiceBaseUrl}/paymentMethod/updatePaymentMethod`,
		delete: `${masterServiceBaseUrl}/paymentMethod/deletePaymentMethod`,
	},

	// Academic Phase
	academicPhase: {
		getAllActive: `${masterServiceBaseUrl}/academicPhase/active`,
		getAll: `${masterServiceBaseUrl}/academicPhase/all`,
	},
	// class Section Master
	classSection: {
		create: `${masterServiceBaseUrl}/classSection/createClassSection`,
		update: `${masterServiceBaseUrl}/classSection/updateClassSection`,
		delete: `${masterServiceBaseUrl}/classSection/deleteClassSection`,
		search: `${masterServiceBaseUrl}/classSection/search`,
		getClassSection: `${masterServiceBaseUrl}/classSection/getClassSection`,
		getAllClassSections: `${masterServiceBaseUrl}/classSection/getAllClassSections`,
		getAllActiveClassSections: `${masterServiceBaseUrl}/classSection/getAllActiveClassSections`,
		getAllClassSectionsByClassSectionType: `${masterServiceBaseUrl}/classSection/getAllClassSectionsByClassSectionType?ClassSectionType=`,
	},

	// Class Type Master
	classType: {
		getAllActive: `${masterServiceBaseUrl}/classType/getAllActiveClassTypes`,
		getClassTypeById: `${masterServiceBaseUrl}/classType/getClassType`,
	},

	//prgram action
	programAction: {
		getAll: `${masterServiceBaseUrl}/programAction/getAllProgramAction`,
		create: `${masterServiceBaseUrl}/programAction/createProgramAction`,
		update: `${masterServiceBaseUrl}/programAction/updateProgramAction`,
		delete: `${masterServiceBaseUrl}/programAction/deleteProgramAction`,
		getAllActive: `${masterServiceBaseUrl}/programAction/getAllActiveProgramAction`,
	},
	actionReason: {
		getAll: `${masterServiceBaseUrl}/actionReason/getAllActionReason`,
		create: `${masterServiceBaseUrl}/actionReason/createActionReason`,
		update: `${masterServiceBaseUrl}/actionReason/updateActionReason`,
		delete: `${masterServiceBaseUrl}/actionReason/deleteActionReason`,
	},
	//person type master
	personType: {
		getAllActive: `${masterServiceBaseUrl}/personType/getAllActivePersonType`,
	},
	//Course Attribute Value 
	courseAttributeValue: {
		getAllCourseAttributeValue: `${masterServiceBaseUrl}/courseAttributeValue/getAllCourseAttributeValue`,
	},
	//businessType
	businessType: {
		getAllActive: `${masterServiceBaseUrl}/businessType/getAllActiveBusinessType`,
		getAllActiveUnitType: `${masterServiceBaseUrl}/businessUnitType/getAllActiveBusinessUnitType`
	},
	//cashierOffice
	cashierOffice: {
		getAllActive: `${masterServiceBaseUrl}/cashierOffice/getActiveCashierOffice`
	},
	//class master
	classes: {
		getAll: `${masterServiceBaseUrl}/class/getAllClass`,
		create: `${masterServiceBaseUrl}/class/createClass`,
		update: `${masterServiceBaseUrl}/class/updateClass`,
		delete: `${masterServiceBaseUrl}/class/deleteClass`,
		getClassesByGroupId :`${masterServiceBaseUrl}/class/getActiveClassesByAcademicGroup`,
		getAllActive: `${masterServiceBaseUrl}/class/getAllActiveClass`,
	},

	///course Attribute Mapping
	courseAttributeMapping: {
		getAll: `${masterServiceBaseUrl}/courseAttributeMapping/getAllCourseAttributeMapping`,
		// getAllByParams: courseAttributeMapping/getAllCourseAttributeMapping/0?
	},

	// Academic Group-Subject Mapping on Master.
	academicSubMapping:{
		getAll : `${masterServiceBaseUrl}/academicGroupSubjectMapping/getAllAcademicGroupSubjectMapping`,
		create : `${masterServiceBaseUrl}/academicGroupSubjectMapping/createAcademicGroupSubjectMapping`,
		update : `${masterServiceBaseUrl}/academicGroupSubjectMapping/updateAcademicGroupSubjectMapping`,
		delete : `${masterServiceBaseUrl}/academicGroupSubjectMapping/deleteAcademicGroupSubjectMapping`
	},


	//calculator type

	calculatorType : {
		getAll : `${masterServiceBaseUrl}/calculatorType/getAllCalculatorType`
	},

	//paymentMode
	paymentMode : {
		getAll : `${masterServiceBaseUrl}/paymentMode/getAllPaymentMode`,
		create : `${masterServiceBaseUrl}/paymentMode/createPaymentMode`,
		update : `${masterServiceBaseUrl}/paymentMode/updatePaymentMode`,
		delete : `${masterServiceBaseUrl}/paymentMode/deletePaymentMode`,
		getActive:`${masterServiceBaseUrl}/paymentMode/getActivePaymentMode`
	},
	room:{
		getAllActiveRoomByBusinessArea:`${masterServiceBaseUrl}/buildingMaster/getAllActiveRoomsByBusinessAreaId`
	},
	faculty: {
		getAllActive : `${masterServiceBaseUrl}/facultyMaster/getAllActiveFaculty`,
		getAll : `${masterServiceBaseUrl}/facultyMaster/getAllFaculty`,
		create : `${masterServiceBaseUrl}/facultyMaster/addFaculty`,
		update : `${masterServiceBaseUrl}/facultyMaster/updateFaculty`,
		delete : `${masterServiceBaseUrl}/facultyMaster/deleteFaculty`,
		filter : `${masterServiceBaseUrl}/facultyMaster/searchFacultyMaster`,
		getById: `${masterServiceBaseUrl}/facultyMaster/getFaculty`,
		getByBusinessAreaId: `${masterServiceBaseUrl}/facultyMaster/getAllFacultyByBusinessArea`,

		getFacultyWithRoaster: `${masterServiceBaseUrl}/facultyMaster/getAllFacultyHavingFacultyRoaster`
	},
	facultyRoaster : {
		getAll : `${masterServiceBaseUrl}/facultyRoaster/getAllFacultyRoaster`,
		getById: `${masterServiceBaseUrl}/facultyRoster/getFacultyRosterByFacultyMasterId`,
		create  :  `${masterServiceBaseUrl}/facultyRoster`,
		createAll : `${masterServiceBaseUrl}/facultyRoster/all`,
		delete: `${masterServiceBaseUrl}/facultyRoster`,
		deleteAll: `${masterServiceBaseUrl}/facultyRoster/all`,
		search: `${masterServiceBaseUrl}/facultyRoster/search`,
	},
	// days plan master- shifted to testScheduleService
	// daysPlan: {
	// 	getById: `${masterServiceBaseUrl}/daysPlan`,
	// 	create: `${masterServiceBaseUrl}/daysPlan`,
	// 	delete: `${masterServiceBaseUrl}/daysPlan`,
	// 	update: `${masterServiceBaseUrl}/daysPlan`,
	// 	search: `${masterServiceBaseUrl}/daysPlan/search`,
	// 	validateDaysPlanName: `${masterServiceBaseUrl}/daysPlan/duplicateDaysPlanMaster`
	// },
	// courseCategory: {
	// 	getAllActive : `${masterServiceBaseUrl}/associatedAttribute/getAllActiveCourseCategory`
	// }

	// State master
	state: {
		getAll: `${masterServiceBaseUrl}/state/getAllState`,
		getAllActive: `${masterServiceBaseUrl}/state/getAllActiveState`,
	},

	// City master
	city: {
		getAll: `${masterServiceBaseUrl}/city/getAllCity`,
		getAllActive: `${masterServiceBaseUrl}/city/getAllActiveCity`,
	},
	
	holidayMaster: {
		create: `${masterServiceBaseUrl}/holiday/createHoliday`,
		search :`${masterServiceBaseUrl}/holiday/searchHoliday`,
		delete : `${masterServiceBaseUrl}/holiday/deleteHoliday`,
		update : `${masterServiceBaseUrl}/holiday/updateHoliday`
	},

	depositSlip: {
		getAll: `${masterServiceBaseUrl}/depositSlip/getDepositSlip`,
		create: `${masterServiceBaseUrl}/depositSlip/createAndUpdateSlip`,
		delete : `${masterServiceBaseUrl}/depositSlip/deleteSlip`
	},

	autoComplete:{
		search: `${masterServiceBaseUrl}/autoComplete/search`,
	},
	courierVendorMaster: {
		create: `${masterServiceBaseUrl}/courierPartner/createCourierPartner`,
		update: `${masterServiceBaseUrl}/courierPartner/updateCourierPartner`,
		getAll: `${masterServiceBaseUrl}/courierPartner/getAllCourierPartner`,
		delete: `${masterServiceBaseUrl}/courierPartner/deleteCourierPartner`,
	}, 

	contactDetails: {
		create : `${masterServiceBaseUrl}/contactDetails/createContactDetails`,
		update : `${masterServiceBaseUrl}/contactDetails/updateContactDetails`
	},

	locationDetails: {
		create : `${masterServiceBaseUrl}/locationDetails/createLocationDetails`,
		update : `${masterServiceBaseUrl}/locationDetails/updateLocationDetails`
	}

};
