import React, { useEffect, useState } from "react";
import moment from "moment";
import { Col, Table } from "reactstrap";
import { BsFillArrowLeftSquareFill, BsFillArrowRightSquareFill } from "react-icons/bs"
import CommonInput from "../../../../common/formFeilds/input/commonInput";
import {
  CustomCard,
  Accordion,
} from "views/pages/manage/common/commonComponents";
import { MasterHeaderAuto } from "../../../../common/commonComponents/masterHeader/masterHeader";
import { isEqual } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { constants } from "../../../../common/constant";

//input renderer
export const FieldsRenderer = ({
  action,
  breakPoint,
  list,
  properties,
  values,
  dataObjects,
  setValues = () => { },
  isCourseIdSecurityCourseId,
  fetchingIsHeaderValid,
  isDisabled,
}) => {

  const [page, setPage] = useState(0);

  const validateCourseSecurityId = (item) => {
    if (isEqual(item, 'associatedSecurityCourseId') && isCourseIdSecurityCourseId) {
      return true
    } else {
      return false
    }
  }

  return (
    list &&
    list.map((item, index) => {
      if (item === "businessArea") {
        return (
          <Col md="8" lg={6} className="pricing_business_area_grid">
            <label />
            <Accordion label="Business Area" disabled={!values[item].length || values['businessAreaFetching']}>
              {values[item] && (
                <Table style={{ width: "100%" }}>
                  <MasterHeaderAuto
                    headerList={[
                      { name: "Business Area" },
                      { name: "Bussiness Area Name" },
                      { name: "Business Area Type" },
                    ]}
                  />
                  <tbody>
                    {values[item].slice(page*5, (page+1)*5).map((el) => (
                      <tr key={el.businessAreaDesc}>
                        <td className="text-center">
                          {el.businessAreaDispValue}
                        </td>
                        <td className="text-center">{el.businessAreaDesc}</td>
                        <td className="text-center">{el.types}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
              {values[item].length > 5 && <div className="d-flex justify-content-between align-items-center">
                <span className="pl-3 tfooter-text">{`${page + 1} of ${Math.ceil(values[item].length / 5)} pages`}</span>
                <span className="p-3 tfooter-text">
                  <BsFillArrowLeftSquareFill onClick={page!== 0 ? ()=>setPage(page-1) : ()=>{}} size={25} color={page!== 0 ? '#11A2EF'  :'#d0d0d0'} type='button'  className="mr-1" disabled={!(page > 0)}/>
                  <BsFillArrowRightSquareFill onClick={page+1 < Math.ceil(values[item].length/5) ? ()=> setPage(page+1) : ()=> {}} size={25} color={page+1 < Math.ceil(values[item].length/5) ? '#11A2EF' :'#d0d0d0'} className="ml-1" type='button' disabled={!(page+1 < Math.ceil(values[item].length/5))}/>                  
                </span>
              </div>}
            </Accordion>
          </Col>
        );
      }
      if (item === "recordedVideoLectureId") {
        return (
          <Col md={properties?.[item]?.size ?? 6} lg={properties?.[item]?.size ?? 6} className="pricing_lecture_id_grid">
            <CommonInput
              id={`manual-fee-setup-${properties?.[item]?.label}`}
              key={`manual-fee-setup-${properties?.[item]?.label}`}
              label={properties?.[item]?.label ?? ""}
              defaultValue={values[item]}
              style={{ height: "39px" }}
              type={properties?.[item]?.type ?? "text"}
              mandatory={properties?.[item]?.mandatory ?? false}
              isDisabled={properties?.[item]?.disabled || isDisabled || validateCourseSecurityId(item) ? true : false || (isEqual(action, 'edit') && !properties?.[item]?.isEditable)}
              data={dataObjects?.[properties?.[item]?.data] ?? []}
              placeHolder={properties?.[item]?.label ?? ""}
              onChange={(val) => {
                setValues({ ...values, [item]: val });
              }}
              loading={
                properties?.[item]?.dependent &&
                values[properties?.[item]?.dependedOn]?.value &&
                !dataObjects?.[properties?.[item]?.data]
              }
              maxLength={properties?.[item]?.maxLength}
              regEx={/^[A-Za-z0-9 _/-]*$/}
            />
          </Col>
        );
      }
      else
        return (
          <Col className="mb-3" sm={6} xs={12} md={4} lg={breakPoint ?? 3} key={index}>
            <CommonInput
              id={`manual-fee-setup-${properties?.[item]?.label}`}
              key={`manual-fee-setup-${properties?.[item]?.label}`}
              label={properties?.[item]?.label ?? ""}
              defaultValue={values[item]}
              style={{ height: "39px" }}
              type={properties?.[item]?.type ?? "text"}
              mandatory={properties?.[item]?.mandatory ?? false}
              isDisabled={properties?.[item]?.disabled || isDisabled || validateCourseSecurityId(item) ? true : false || (isEqual(action, 'edit') && !properties?.[item]?.isEditable)}
              data={dataObjects?.[properties?.[item]?.data] ?? []}
              placeHolder={properties?.[item]?.label ?? ""}
              onChange={(val) => {
                // alert(JSON.stringify(val))
                setValues({ ...values, [item]: val });
              }}
              loading={
                properties?.[item]?.dependent &&
                values[properties?.[item]?.dependedOn]?.value &&
                !dataObjects?.[properties?.[item]?.data]
              }
              minDate={
                item === "feeEffectiveDateTo" ? new Date(values["feeEffectiveDateFrom"]) :
                properties?.[item]?.type === "datePicker"
                  ? isEqual(item, 'courseStartDate') ? new Date(values["courseMinDate"]) : new Date(values["courseStartDate"])
                  : ""
              }
              maxDate={
                item === "feeEffectiveDateTo" || item === "feeEffectiveDateFrom" ? new Date(values["courseEndDate"]) :
                properties?.[item]?.type === "datePicker"
                  ? item ==='courseStartDate' 
                    ? 
                      moment(values["courseMinDate"]).add(Number(values["courseMaxStartDays"] - 1), "days").toDate() 
                    : 
                      moment(values["courseStartDate"]).add(Number(values["courseMaxDays"] - 1), "days").toDate()
                  : ""
              }
              maxLength={properties?.[item]?.maxLength}
            />
          </Col>
        );
    })
  );
};

///year grids
export const YearGrid = ({
  title,
  dateValues,
  position,
  setDateValues = () => { },
  isDisabled
}) => {
  const {
    course_fee_year_start_date_objs: startDate,
    course_fee_year_end_date_objs: endDate,
  } = constants;

  useEffect(() => {
    dateValues?.totalYears &&
      dateValues?.courseEndDate &&
      setDateValues({
        ...dateValues,
        [endDate[dateValues?.totalYears]]: dateValues.courseEndDate,
      });
  }, [dateValues?.courseEndDate]);

  const getTomorrowsDate = (e) => {
    return moment(e).add(1, "day").format("YYYY-MM-DD");
  };

  const getPreviousDate = (e) => {
    return moment(e).subtract(1, "day").toDate();
  }

  return (
    <Col sm={6} xs={12} md={6} lg={3} className="year-box">
      <CustomCard title={title}>
        <div className="p-2">
          <CommonInput
            label="Start"
            type="datePicker"
            defaultValue={dateValues[startDate[position]] ? dateValues[startDate[position]] : ''}
            isDisabled={true || isDisabled}
            placeHolder={`${constants.course_fee_years[position - 1]} Year Start Date`}
          />
          <CommonInput
            label="End"
            type="datePicker"
            defaultValue={dateValues[endDate[position]] ? dateValues[endDate[position]] : ''}
            isDisabled={isEqual(position, dateValues?.totalYears) || isDisabled}
            minDate={moment(dateValues[startDate[position]]).toDate()}
            maxDate={getPreviousDate(dateValues.courseEndDate)}
            onChange={(e) =>
              setDateValues({
                ...dateValues,
                [endDate[position]]: e,
                [startDate[position + 1]]: getTomorrowsDate(e),
              })
            }
            placeHolder={`${constants.course_fee_years[position - 1]} Year End Date`}
          />
        </div>
      </CustomCard>
    </Col>
  );
};
