import React, { useEffect, useRef, useState } from 'react';
import { Accordion } from '../../../../common/commonComponents';
import { Card, CardBody, Col, Row } from 'reactstrap';
import CustomButton from '../../../../../../../components/CustomButton';
import { fetchAllPromisedData, fetchAllPostPromisedData, getCookie } from '../../../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../../../common/constant';
import { failureToast, successToast } from '../../../../common/utils/methods/toasterFunctions/function';
import moment from 'moment';
import { chargesStatus } from '../../studentList';
// import TemplateToPDF from '../templates/templateToPdf';
import { getCourseFeeSummary, getOrdinal, getProvisionalSummary } from '../templates/mapping';
import { LOAN_APPLICATION_ERROR_MGS, getBusinessAreaQR, updateQuestionStatus } from '../common';
import useConfirm from '../../../../common/commonComponents/ConfirmationDialog/confirmationPrompt.jsx';
import ViewLastUpdatedBy from './ViewLastUpdatedBy'
import ConfirmationDialogSuccess from '../../../../common/commonComponents/ConfirmationDialog'
import { BsCheck2 } from 'react-icons/bs';
import DODetailsButton from './DODetailsButton';
import AddDoDetailsDialog from './AddDoDetailsDialog'
import DepositSlipWrapper from '../templates/depositSlipWrapper'
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { uploadpost } from 'services/utils';
import DepositSlipDialog from './depositSlipDislog';
import { getFileUploadUrls, parseFloatTillDigit } from '../feeBreakup/common';
import { Box, Popover } from '@mui/material';
import RemarksDialogue, { fetchRemarks } from '../../../concessionApproval/RemarksDialogue';
import { IoInformation } from "react-icons/io5";



export const getFeeBreakup = (finalizeCharges, applicationQuotationDetailsId, isApproved = false) => {
    try {
        // setLoading(true);
        let tempArr = [];
        Object.values(finalizeCharges?.data).map(item => {
            tempArr = [...tempArr, ...item]
        })

        const breakup = tempArr.map(item => {
            const gstBreakup = {
                cgst: 0,
                sgst: 0,
                utgst: 0,
                igst: 0
            };
            item?.taxBreakupResDtos && item.taxBreakupResDtos.map(i => {
                return gstBreakup[i.taxCodeTypeDispVal.toLowerCase()] = parseFloat(((i.taxPercentage * item.payableAmt) / 100).toFixed(2))
            })
            const payableAmountPostApproval = item.concApprovalPending ? item.payableAmt - item.concApprovalPending : 0;
            const taxPostApproval = (payableAmountPostApproval * item.totalTaxPercentage) / 100;
            return {
                "applicationQuotationDetailsId": applicationQuotationDetailsId,
                "id":item?.id||null,
                "itemType": item.itemType,
                "dueDate": item.dueDate,
                "grossAmount": item.baseAmount,
                "concessionApplied": isApproved && item.concApprovalPending ? item.consession + item.concApprovalPending : item.consession,
                "payableAmount": item.payableAmt,
                "taxes": item.tax,
                "netPayable": item.netPayable,
                "concessionApprovalPending": !isApproved ? item?.concApprovalPending ?? 0 : 0,
                "payableAmountPostApproval": !isApproved && item.concApprovalPending ? item.payableAmt - item.concApprovalPending : 0,
                "status": "ACTIVE",
                installmentNo: Number(item?.installmentNo),
                year: item?.year,
                taxPostApproval: parseFloat(taxPostApproval.toFixed(4)),
                netPayablePostApproval: parseFloat((taxPostApproval + payableAmountPostApproval).toFixed(4)),
                concessionIds: item?.concessionIds ?? [],
                ...gstBreakup
            }
        });

        const request = {
            "applicationQuotationDetailsId": applicationQuotationDetailsId,
            "studentChargesFeeBreakup": breakup,
            "totalGrossAmount": Math.round(Object.keys(finalizeCharges.total).reduce((a, b) => a + finalizeCharges.total[b]['totalGross'], 0) * 100) / 100,
            "totalConcessionApplied": Math.round(Object.keys(finalizeCharges.total).reduce((a, b) => a + finalizeCharges.total[b]['conAmt'], 0) * 100) / 100,
            "totalPayable": Math.round(Object.keys(finalizeCharges.total).reduce((a, b) => a + finalizeCharges.total[b]['totalPayAmt'], 0) * 100) / 100,
            "totalTaxes": Math.round(Object.keys(finalizeCharges.total).reduce((a, b) => a + finalizeCharges.total[b]['totalTax'], 0) * 100) / 100,
            "totalNetPayable": Math.round(Object.keys(finalizeCharges.total).reduce((a, b) => a + finalizeCharges.total[b]['netAmt'], 0) * 100) / 100,
            "totalConcessionApprovalPending": Object.keys(finalizeCharges.total).reduce((a, b) => a + Number(finalizeCharges.total[b]['concApprovalPendingTotal']), 0),
            "totalPayableAmountPostApproval": Object.keys(finalizeCharges.total).reduce((a, b) => a + Number(finalizeCharges.total[b]['netPayablePostApprovalTotal']), 0),
        }

        return request
    } catch (e) {
        //console.log(e);
        return []
        // setLoading(false);
        // failureToast("Couldn't save final charges");
    }
}


const FinalizeCharges = (props) => {

    const { userPermissions,
        isOpen,
        setIsOpen,
        finalizeCharges,
        applicationQuotationDetailsId,
        applicationStatus,
        updatedBy,
        applicationDetails,
        paymentDetails,
        concessionDetails,
        disableActions,
        updatedOn,
        isChargesFinializes,
        finalBreakupData,
        setAllowChargesModification,
        allowChargesModification,
        isChargesCancelled,
        viewOnly,
        getApplicationDetails,
        handleVerifyChargesDialog,
        getPaymentDetails,
        businessAreas,
        courses,
        allCategories,
        doExist,
        checkPaymentModeForDo,
        pastIdSelected,
        selectedQoutationId,
        setAsyncLoader,
        studentCurrentYear,
        applicationId,
        setModificationType,
        setCmprAllowed,
        setLowestNetPayable,
        isDefinedConcessionDirty,
        customSlab,
        activePaymentModes
    } = props;

    const captureRef = useRef(null);
    const [values, setValues] = useState({});
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [ConfirmationDialog, confirm] = useConfirm();
    const [ProvisonalConfirmationDialog, confirmProceed] = useConfirm();
    const [isInfoModalOpen, setInfoModalOpen] = useState(false);
    const [isProvisnalInfoModalOpen, setProvisnalInformationModal] = useState(false);
    const [isAddDoDialogOpen, setIsAddDoDialogOpen] = useState(false);
    const [sendEmailNotification, setSendEmailNotification] = useState(true);
    const [emailNotificationMsgDialogOpen, setEmailNotificationMsgDialog] = useState(false);
    const [isDepositSlipDislogOpen, setDepositSlipDialogOpen] = useState(false);

    const [remarks, setRemarks] = useState(null);
    const [openRemarksDialogue, setOpenRemarksDialogue] = useState(false);
    const [transferApplicationDialogOpen, setTransferApplicationDialogOpen] = useState(false);



    useEffect(() => {
        if (isChargesFinializes && finalBreakupData && !allowChargesModification) {

            //console.log("finalBreakupData", finalBreakupData?.studentChargesFeeBreakup)

            const val = {
                netAmt: finalBreakupData?.totalNetPayable ?? '',
                grossAmt: finalBreakupData?.totalGrossAmount ?? '',
                concAmt: finalBreakupData?.totalConcessionApplied ?? '',
                paybleAmt: finalBreakupData?.totalPayable ?? '',
                taxAmt: finalBreakupData?.totalTaxes ?? '',
                cgst: finalBreakupData?.studentChargesFeeBreakup ? (Math.round(Object.keys(finalBreakupData.studentChargesFeeBreakup).reduce((a, b) => a + (finalBreakupData?.studentChargesFeeBreakup?.[b]?.['cgst'] ?? 0), 0) * 100) / 100) : 0,
                igst: finalBreakupData?.studentChargesFeeBreakup ? (Math.round(Object.keys(finalBreakupData.studentChargesFeeBreakup).reduce((a, b) => a + (finalBreakupData?.studentChargesFeeBreakup?.[b]?.['igst'] ?? 0), 0) * 100) / 100) : 0,
                sgst: finalBreakupData?.studentChargesFeeBreakup ? (Math.round(Object.keys(finalBreakupData.studentChargesFeeBreakup).reduce((a, b) => a + (finalBreakupData?.studentChargesFeeBreakup?.[b]?.['sgst'] ?? 0), 0) * 100) / 100) : 0,
                utgst: finalBreakupData?.studentChargesFeeBreakup ? (Math.round(Object.keys(finalBreakupData.studentChargesFeeBreakup).reduce((a, b) => a + (finalBreakupData?.studentChargesFeeBreakup?.[b]?.['utgst'] ?? 0), 0) * 100) / 100) : 0,
            }

            setValues(val)
        } else {
            //console.log(finalizeCharges)
            const val = {
                netAmt: (finalizeCharges?.total && Math.round(Object.keys(finalizeCharges.total).reduce((a, b) => a + finalizeCharges.total[b]['netAmt'], 0) * 100) / 100) ?? '',
                grossAmt: (finalizeCharges?.total && Math.round(Object.keys(finalizeCharges.total).reduce((a, b) => a + finalizeCharges.total[b]['totalGross'], 0) * 100) / 100) ?? '',
                concAmt: (finalizeCharges?.total && Math.round(Object.keys(finalizeCharges.total).reduce((a, b) => a + finalizeCharges.total[b]['conAmt'], 0) * 100) / 100) ?? '',
                paybleAmt: (finalizeCharges?.total && Math.round(Object.keys(finalizeCharges.total).reduce((a, b) => a + finalizeCharges.total[b]['totalPayAmt'], 0) * 100) / 100) ?? '',
                taxAmt: (finalizeCharges?.total && Math.round(Object.keys(finalizeCharges.total).reduce((a, b) => a + finalizeCharges.total[b]['totalTax'], 0) * 100) / 100) ?? '',

                cgst: (finalizeCharges?.total && Math.round(Object.keys(finalizeCharges.total).reduce((a, b) => a + finalizeCharges.total[b]['cgst'], 0) * 100) / 100) ?? '',
                igst: (finalizeCharges?.total && Math.round(Object.keys(finalizeCharges.total).reduce((a, b) => a + finalizeCharges.total[b]['igst'], 0) * 100) / 100) ?? '',
                sgst: (finalizeCharges?.total && Math.round(Object.keys(finalizeCharges.total).reduce((a, b) => a + finalizeCharges.total[b]['sgst'], 0) * 100) / 100) ?? '',
                utgst: (finalizeCharges?.total && Math.round(Object.keys(finalizeCharges.total).reduce((a, b) => a + finalizeCharges.total[b]['utgst'], 0) * 100) / 100) ?? '',
            }
            setValues(val)
        }

    }, [finalizeCharges, finalBreakupData, isChargesFinializes, allowChargesModification])

    const getFileUrl = async (key) => {
        // alert(key)
        const templateUrl = await fetchAllPostPromisedData(`${endpoint.fetchS3Url.getDownloadUrl}?key=${key}`, {})
        if (templateUrl?.code === 200 && templateUrl?.data) {
            window.open(`${templateUrl?.data?.url}`)
            setDownloading(false);
        } else {
            setDownloading(false);
            failureToast("Something went wrong please try after sometime.")
        }
    }

    const onModification = async () => {

        if (!allowChargesModification && applicationDetails?.transferApplicationId !== null) {
            setTransferApplicationDialogOpen(true);
            return;
        }
        
        if (applicationDetails?.studentType !== 'BYJUS') {
            setAsyncLoader(true)
            const response = await fetchAllPromisedData(`${endpoint.checkModifyAllowed}/${applicationId}?quotationId=${applicationQuotationDetailsId}`, true, true);
            if (response) {
                
                if(!response?.data?.chargeModificationAllowed){
                    failureToast(response?.message);
                    setAsyncLoader(false);
                    return;
                }
                if (allowChargesModification) {
                    setAllowChargesModification(false);
                } else {
                    setAllowChargesModification(true);
                    setLowestNetPayable(response?.data?.lowestNetPayable)
                    setCmprAllowed(response?.data?.paymentDone)

                    setIsOpen({
                        detailsTab: false,
                        paymentTab: true,
                        concessionsTab: false,
                        finalTab: false
                    })
                }
            }
            else {
            }
            setAsyncLoader(false)
        } else {
            failureToast(`This student belongs to BYJU, the modify charges functionality is not available for this ${applicationDetails.psid}  (PSID)`)
        }
    }

   const getDepositReferenceNumber = (item)=>{
      if(item[0]) return item[0]?.depositSlipReferenceNumber
      return ''
   }

    const getEligibleComponentList = async (qid, returnResult = false, failureToastRequired=true) => {

        try {
            const components = await fetchAllPromisedData(`${endpoint.eligibleDepositSlips}/${qid}`, {}, failureToastRequired);
            const feeBreak = {
                clientCode: '',
                erpRef: '',
                businessAreasCode: businessAreas?.[0]?.label,
                businessAreasName: businessAreas?.[0]?.desc,
                psid: applicationDetails?.psid ?? '',
                studentName: applicationDetails?.studentName ?? '',
                contactNumber: applicationDetails?.phoneNo ?? '',
                term: applicationDetails?.termValue,
                dueDate: '',
                regFee: 0,
                adminFee: 0,
                securityFee: 0,
                tutionFee: 0,
                techFee: 0,
                classFee: 0,
                name: '',
                course: courses?.[0]?.value,
                businessAreasId: businessAreas?.[0]?.value
            }

            const depositeData = []
             
            if (components?.code === 200 && components?.data) {
                let eligibilityComponent = components?.data?.eligibleDepositSlipResponseDTOList;
                const dateAndItemMapping = {};

                // security Fee
                if (components?.data?.securityAttributeDepositSlip?.netPayableAmount) {
                    let termInfo = {};
                    if (components?.data?.securityAttributeDepositSlip?.securityFeeTermId) {
                        termInfo = await fetchAllPromisedData(`${endpoint.term.getById}/${components?.data?.securityAttributeDepositSlip?.securityFeeTermId}`)
                    }
                    depositeData.push({ ...feeBreak, course: components?.data?.securityAttributeDepositSlip?.courseId, 
                        erpRef: components?.data?.securityAttributeDepositSlip?.id,
                         depositSlipReferenceNumber:components?.data?.securityAttributeDepositSlip?.depositSlipReferenceNumber,
                         term: termInfo?.termDispValue ?? applicationDetails?.termValue, clientCode: components?.data?.clientCode, securityFee: components?.data?.securityAttributeDepositSlip?.finalPayable, dispName: 'Security', name: 'Security', id: components?.data?.securityAttributeDepositSlip?.id, dueDate: moment(new Date()).format("DD-MMM-YYYY")?.toUpperCase() });
                }

                const lumpsumValues = ['LUMPSUM_First_Year', 'LUMPSUM_Year_On_Year', 'LUMPSUM_Full_Course']
                const isLumpsum = concessionDetails?.find(i => lumpsumValues.includes(i.concessionMethodType));
                const lumpsumYear = isLumpsum?.concessionMethodType === 'LUMPSUM_First_Year' ? 1 : isLumpsum?.concessionMethodType === 'LUMPSUM_Year_On_Year' ? studentCurrentYear : isLumpsum?.concessionMethodType === 'LUMPSUM_Full_Course' ? "*" : null;
                if (eligibilityComponent?.length) {

                    if (isLumpsum && lumpsumYear) {
                        if (lumpsumYear === "*") {
                            const ids = eligibilityComponent.map(comp => comp.itemType);
                            const techFeeAmt = parseFloat(eligibilityComponent.filter(comp => comp.itemType.slice(-2) === '04').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            const tuitionFeeAmt = parseFloat(eligibilityComponent.filter(comp => comp.itemType.slice(-2) === '01').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            const classFeeAmt = parseFloat(eligibilityComponent.filter(comp => comp.itemType.slice(-2) === '05').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            const adminFee = parseFloat(eligibilityComponent.filter(comp => comp.itemType.slice(-2) === '30').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            const regFee = parseFloat(eligibilityComponent.filter(comp => comp.itemType.slice(-2) === '29').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            depositeData.push({ ...feeBreak, erpRef: eligibilityComponent?.[0]?.id, depositSlipReferenceNumber: getDepositReferenceNumber(eligibilityComponent),
                                 clientCode: components?.data?.clientCode, regFee: regFee, adminFee: adminFee, tutionFee: tuitionFeeAmt, techFee: techFeeAmt, classFee: classFeeAmt, dispName: 'Lumpsum', name: 'Lumpsum', dueDate: 'On Admission', feeBreakupId: [eligibilityComponent?.[0]?.id] });
                            dateAndItemMapping['Lumpsum'] = ids;
                        } else {
                            if (lumpsumYear !== 1) {
                                // Reg fee component logic
                                const regFeeComponent = eligibilityComponent?.filter(comp => comp.itemType.slice(-2) === '29');
                                if (regFeeComponent?.length) {
                                    const regFeeAmt = regFeeComponent.reduce((acc, a) => acc + a.netPayable, 0);
                                    depositeData.push({ ...feeBreak,
                                          erpRef: regFeeComponent?.[0]?.id,
                                          depositSlipReferenceNumber:getDepositReferenceNumber(regFeeComponent),
                                         clientCode: components?.data?.clientCode, regFee: regFeeAmt,
                                          dispName: 'Reg Fee', name: 'Registration', dueDate: 'On Admission', feeBreakupId: [regFeeComponent?.[0]?.id] });
                                    dateAndItemMapping['Registration'] = [regFeeComponent?.[0]?.itemType];
                                }


                                // admin fee component logic
                                const adminFeeComponent = eligibilityComponent?.filter(comp => comp.itemType.slice(-2) === '30');
                                if (adminFeeComponent?.length) {
                                    const adminFeeAmt = adminFeeComponent.reduce((acc, a) => acc + a.netPayable, 0);
                                    depositeData.push({ ...feeBreak, erpRef: adminFeeComponent?.[0]?.id,depositSlipReferenceNumber: getDepositReferenceNumber(adminFeeComponent),
                                         clientCode: components?.data?.clientCode, adminFee: adminFeeAmt, dispName: 'Adm Kit', name: 'Admission', dueDate: 'On Admission', feeBreakupId: [adminFeeComponent?.[0]?.id] });
                                    dateAndItemMapping['Admission'] = [adminFeeComponent?.[0]?.itemType];
                                }
                            }
                            const year = eligibilityComponent.filter(comp => comp.year === lumpsumYear);
                            if (year?.length) {
                                const ids = year.map(comp => comp.itemType);
                                const techFeeAmt = parseFloat(year.filter(comp => comp.itemType.slice(-2) === '04').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                                const tuitionFeeAmt = parseFloat(year.filter(comp => comp.itemType.slice(-2) === '01').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                                const classFeeAmt = parseFloat(year.filter(comp => comp.itemType.slice(-2) === '05').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                                const adminFee = parseFloat(year.filter(comp => comp.itemType.slice(-2) === '30').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                                const regFee = parseFloat(year.filter(comp => comp.itemType.slice(-2) === '29').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                                depositeData.push({ ...feeBreak, erpRef: year?.[0]?.id, 
                                    depositSlipReferenceNumber:getDepositReferenceNumber(year),
            
                                     clientCode: components?.data?.clientCode, regFee: regFee, adminFee: adminFee, tutionFee: tuitionFeeAmt, techFee: techFeeAmt, classFee: classFeeAmt, dispName: 'Lumpsum', name: 'Lumpsum', feeBreakupId: [year?.[0]?.id], dueDate: year === 1 ? 'On Admission' : moment(year?.[0]?.dueDate).format("DD-MMM-YYYY")?.toUpperCase() });
                                dateAndItemMapping['Lumpsum'] = ids;
                            }

                            const others = eligibilityComponent.filter(comp => comp.year !== lumpsumYear);
                            const groupedData = others && others?.reduce((result, current) => {
                                const dueDate = current.itemType?.slice(-4, -2);
                                if (!result[dueDate]) {
                                    result[dueDate] = [];
                                }

                                result[dueDate].push({ ...current });

                                return result;
                            }, {});
                         
                            Object.keys(groupedData).forEach((installmentData, i) => {
                                const techFeeAmt = parseFloat(groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                                const tuitionFeeAmt = parseFloat(groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '01').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                                const classFeeAmt = parseFloat(groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '05').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                                if (techFeeAmt || tuitionFeeAmt || classFeeAmt) {
                                    const erpRef = Math.max(...groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(obj => obj.id));
                                    const depositSlipReferenceNumber = Math.max(...groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(obj => obj.depositSlipReferenceNumber));

                                    const ids = groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(i => i.itemType);
                                    const feeBreakupId = groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(i => i.id);
                                    depositeData.push({ ...feeBreak, erpRef: erpRef, depositSlipReferenceNumber,  clientCode: components?.data?.clientCode, dispName: `INST#${ids?.[0]?.slice(-4, -2)}`, name: `${getOrdinal(ids?.[0]?.slice(-4, -2))} Installment`, dueDate: ids?.[0]?.slice(-4, -2) == '01' ? 'On Admission'  :moment(groupedData[installmentData]?.[0]?.dueDate).format("DD-MMM-YYYY")?.toUpperCase(), tutionFee: tuitionFeeAmt, techFee: techFeeAmt, classFee: classFeeAmt, feeBreakupId: feeBreakupId });
                                    dateAndItemMapping[`${getOrdinal(ids?.[0]?.slice(-4, -2))}_Installment`] = ids;
                                }
                            })
                        }

                    } else {
                        // Reg fee component logic
                        const regFeeComponent = eligibilityComponent?.filter(comp => comp.itemType.slice(-2) === '29');
                        if (regFeeComponent?.length) {
                            const regFeeAmt = regFeeComponent.reduce((acc, a) => acc + a.netPayable, 0);
                            depositeData.push({ ...feeBreak, erpRef: regFeeComponent?.[0]?.id,
                                  depositSlipReferenceNumber:getDepositReferenceNumber(regFeeComponent),
                                 clientCode: components?.data?.clientCode, regFee: regFeeAmt, dispName: 'Reg Fee', name: 'Registration', dueDate: 'On Admission', feeBreakupId: [regFeeComponent?.[0]?.id] });
                            dateAndItemMapping['Registration'] = [regFeeComponent?.[0]?.itemType];
                        }


                        // admin fee component logic
                        const adminFeeComponent = eligibilityComponent?.filter(comp => comp.itemType.slice(-2) === '30');
                        if (adminFeeComponent?.length) {
                            const adminFeeAmt = adminFeeComponent.reduce((acc, a) => acc + a.netPayable, 0);
                            depositeData.push({ ...feeBreak, erpRef: adminFeeComponent?.[0]?.id,
                                  depositSlipReferenceNumber:getDepositReferenceNumber(adminFeeComponent),
                                 clientCode: components?.data?.clientCode, adminFee: adminFeeAmt, dispName: 'Adm Kit', name: 'Admission', dueDate: 'On Admission', feeBreakupId: [adminFeeComponent?.[0]?.id] });
                            dateAndItemMapping['Admission'] = [adminFeeComponent?.[0]?.itemType];
                        }

                        const groupedData = eligibilityComponent && eligibilityComponent?.reduce((result, current) => {
                            const dueDate = current.itemType?.slice(-4, -2);
                            if (!result[dueDate]) {
                                result[dueDate] = [];
                            }

                            result[dueDate].push({ ...current });

                            return result;
                        }, {});

                        Object.keys(groupedData).forEach((installmentData, i) => {
                            console.log("installmentData",groupedData[installmentData]?.[0]?.dueDate)
                            const techFeeAmt = parseFloat(groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            const tuitionFeeAmt = parseFloat(groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '01').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            const classFeeAmt = parseFloat(groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '05').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            if (techFeeAmt || tuitionFeeAmt || classFeeAmt) {
                                const erpRef = Math.max(...groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(obj => obj.id));
                                const depositSlipReferenceNumber = Math.max(...groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(obj => obj.depositSlipReferenceNumber));

                                const ids = groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(i => i.itemType);
                                const feeBreakupId = groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(i => i.id);
                                depositeData.push({ ...feeBreak, erpRef: erpRef, depositSlipReferenceNumber, clientCode: components?.data?.clientCode, dispName: `INST#${ids?.[0]?.slice(-4, -2)}`, name: `${getOrdinal(ids?.[0]?.slice(-4, -2))} Installment`, dueDate: ids?.[0]?.slice(-4, -2) === '01' ? 'On Admission'  : moment(groupedData[installmentData]?.[0]?.dueDate).format("DD-MMM-YYYY")?.toUpperCase(), tutionFee: tuitionFeeAmt, techFee: techFeeAmt, classFee: classFeeAmt, feeBreakupId: feeBreakupId });
                                dateAndItemMapping[`${getOrdinal(ids?.[0]?.slice(-4, -2))}_Installment`] = ids;
                            }
                        })
                    }
                }

                if (returnResult) {
                    //console.log(depositeData)
                    console.log("depositeData",depositeData)

                    return { depositeData, dateAndItemMapping };
                } else {
                    const fileNameArr = depositeData.map(i => i.name.split(' ').join('_') + '.pdf');
                    const qrCode = await getBusinessAreaQR(businessAreas?.[0]?.value);
                    getFileUploadUrls(fileNameArr, depositeData, dateAndItemMapping, qrCode ? qrCode : null);
                }

            } else {
                //console.log('failed tp generate deposite slip no component eligible');
                // setLoading(false);
                // setAsyncLoader(false)
                return { depositeData: [], dateAndItemMapping: {} };
            }
        } catch (e) {
            //console.log(e)
            // setLoading(false);
            // setAsyncLoader(false)
            return { depositeData: [], dateAndItemMapping: {} };
        }

    }



    // deposite slip logic ends



    const handleVerificationDailog = async () => {
        const isApprovalRequired = concessionDetails.find(item => item.concessionType === 'CUSTOM') || (concessionDetails?.[0]?.extraFeeComponents !== '' && concessionDetails?.[0]?.extraFeeComponents !== undefined) ? true : false;
        const isApprovalPending = ['Pending', 'L1_APPROVED', 'L2_APPROVED', 'L3_APPROVED'].includes(concessionDetails?.[0]?.concessionApprovalStatus);
        const isApproved = concessionDetails?.[0]?.isApproved;

        if (!isApprovalRequired || isApproved || !concessionDetails?.length) {
            handleVerifyChargesDialog(true)
            return
        }

        const canContinue = await confirmProceed();
        if (!canContinue) return

        if (isApprovalRequired && isApprovalPending && !isApproved) {
            handleVerifyChargesDialog(true)

        }
    }

    const keepVerifyDiable = () => {
        const isApprovalRequired = concessionDetails.find(item => item.concessionType === 'CUSTOM') || (concessionDetails?.[0]?.extraFeeComponents !== '' && concessionDetails?.[0]?.extraFeeComponents !== undefined) ? true : false;
        const isApprovalPending = ['Pending', 'L1_APPROVED', 'L2_APPROVED', 'L3_APPROVED'].includes(concessionDetails?.[0]?.concessionApprovalStatus);
        const isApproved = concessionDetails?.[0]?.isApproved;

        return isApprovalRequired && !isApprovalPending && !isApproved && concessionDetails?.length ? true : false;

    }

    const isApproved = () => {
        
        const isApprovalRequired = concessionDetails.find(item => item.concessionType === 'CUSTOM') || (concessionDetails?.[0]?.extraFeeComponents !== '' && concessionDetails?.[0]?.extraFeeComponents !== undefined) ? true : false;
        // const isApprovalPending = ['Pending', 'L1_APPROVED', 'L2_APPROVED', 'L3_APPROVED'].includes(concessionDetails?.[0]?.concessionApprovalStatus);
        const isRejected = ['L1_REJECTED', 'L2_REJECTED', 'L3_REJECTED'].includes(concessionDetails?.[0]?.concessionApprovalStatus);
        const isApproved = concessionDetails?.[0]?.isApproved;
        return isApproved || isRejected || !isApprovalRequired ? true : false;
    }

    const resetSendEmailNotificationFlag = () => {
        setSendEmailNotification(true);
    }

    const courseSummary = async () => {

        if (checkPaymentModeForDo(paymentDetails?.paymentModeMasterId) && !doExist) {
            setIsAddDoDialogOpen(true);
            return;
        }

        const canContinue = await confirm();
        if (!canContinue) return

        const feeBreakup = getFeeBreakup(finalizeCharges, applicationQuotationDetailsId)
        setSendEmailNotification(canContinue === "Checked")

        if (isApproved()) {
            const csummary = getCourseFeeSummary(applicationDetails, finalizeCharges, concessionDetails, businessAreas, courses, allCategories, paymentDetails?.loanSchemeMasterId);
            updateQuestionStatus('APPL', {}, csummary, {}, feeBreakup, captureRef, applicationQuotationDetailsId, (data) => { getApplicationDetails(); getPaymentDetails(); setInfoModalOpen(data?.otherChargesMessage ? { data: data, fn: () => { } } : null); setEmailNotificationMsgDialog(true); }, () => { setLoading(false);
                 setAsyncLoader(false);
                 setIsOpen(state => ({ ...state, finalTab: true })) 
                 }, courses, canContinue === "Checked", (qid) => getEligibleComponentList(qid, true, false), getFileUploadUrls)

        } else {
            // setSendEmailNotification(false);
            const psummary = getProvisionalSummary(applicationDetails, finalizeCharges, concessionDetails, businessAreas, courses, allCategories, paymentDetails?.loanSchemeMasterId);
            updateQuestionStatus('PAPP', {}, {}, psummary, feeBreakup, captureRef, applicationQuotationDetailsId, (data) => { getApplicationDetails(); getPaymentDetails(); setInfoModalOpen(data?.otherChargesMessage ? { data: data, fn: () => { setProvisnalInformationModal(true) } } : null); }, () => {
                
                setLoading(false); setAsyncLoader(false);
                setIsOpen(state => ({ ...state, finalTab: true })) 
            }, courses, null, (qid) => getEligibleComponentList(qid, true), getFileUploadUrls)
        }
        setLoading(true);
        setAsyncLoader(true);
    }

    const isLoanPaymentSelect = ()=>{
       const loanItem = activePaymentModes.filter(item=>item.paymentModeKey ==="Loan") ;
       if(Array.isArray(loanItem) && loanItem?.length>0){
         const loadMasterId = loanItem[0]?.id;
         return loadMasterId === paymentDetails?.paymentModeMasterId
       }
       return false  
    }

    // if loan selected and not Approved then return false

    const isShowErrorMessage = ()=>{
        if(isLoanPaymentSelect() && !isApproved())
           return true;
        return false;
    }

    const defaultViewComp = (
        values?.grossAmt && applicationQuotationDetailsId && applicationStatus && (finalBreakupData || finalizeCharges) ?
            <div className='pb-2'>
                <div className='d-flex flex-column'>
                    <span className='regular-small label-color'>Net payable amount</span>
                    
                    <span className='regular-large-bold color-dark pt-1'>₹ {parseFloatTillDigit(values?.netAmt, 0)} .00</span>
                    {!viewOnly ? <div className='defaultViewComp_btn pt-3'>
                        {/* <CustomButton
                            content={'Collect now'}
                            permissionType={'C,U'}
                            permissionSet={userPermissions}
                            onClick={() => { }}
                        /> */}
                        {/* <span className="ml-3" /> */}
                        {applicationStatus === 'CONC' || applicationStatus === 'PROV' ? <><CustomButton
                            content={'Verify charges'}
                            permissionType={'C,U'}
                            permissionSet={userPermissions}
                            onClick={() => { handleVerificationDailog() }}
                            disabled={disableActions || keepVerifyDiable() || isDefinedConcessionDirty || customSlab?.isDirty}
                        /><span className="ml-3" /></> : null}
                        {(applicationStatus === 'PAPP' || applicationStatus === 'VERY') && !viewOnly ? <CustomButton
                            // outline={true}
                            content={loading ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : 'Finalize charges'}
                            permissionType={'C,U'}
                            permissionSet={userPermissions}
                            onClick={()=>{
                                isShowErrorMessage()?failureToast(LOAN_APPLICATION_ERROR_MGS):courseSummary()
                            }}
                            disabled={isChargesCancelled || (applicationStatus === 'PAPP' && !isApproved()) || disableActions || loading}
                            className="disable-btn-clss"
                        /> : null}
                        {applicationStatus === 'APPL' && !viewOnly ? <CustomButton
                            outline={true}
                            content={allowChargesModification ? 'Cancel' : 'Modify charges'}
                            permissionType={'C,U'}
                            permissionSet={userPermissions}
                            onClick={onModification}
                            disabled={disableActions||isChargesCancelled}
                            className="outline-btn"
                        /> : null}
                    </div> : null}
                  
                    <ViewLastUpdatedBy
                        applicationStatus ={applicationStatus}
                        updatedBy = {updatedBy}
                        updatedOn = {updatedOn}
                        concessionDetails = {concessionDetails}
                        paymentDetails = {paymentDetails}
                    />
                </div>
            </div>
            : null
    )
    const checkForSendEmailCheckbox = (checkFor) => {
        const customConc = concessionDetails?.find(item => item.concessionType === 'CUSTOM')
        const onlyDefinedConcPresent = !customConc && concessionDetails?.length > 0;
        const approvedCustomConcPresent = ['L1_APPROVED', 'L2_APPROVED', 'L3_APPROVED'].includes(customConc?.concessionApprovalStatus) && customConc?.isApproved
        if (checkFor === "visible") return !(customConc && !approvedCustomConcPresent);
        else if (checkFor === "disabled") return onlyDefinedConcPresent || approvedCustomConcPresent

    }


    // useEffect(()=>{
    //     if(businessAreas?.[0]?.value){
    //         getBusinessAreaQR(businessAreas?.[0]?.value)
    //     }
    // },[businessAreas])
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <>
            <Accordion
                isFormOpen={isOpen.finalTab}
                title={`Finalize charges`}
                key={'finalize_charges'}
                defaultViewComp={defaultViewComp}
                isEditFormOpen={(open) => {
                    open === false ?
                        setIsOpen(state => ({ ...state, finalTab: false })) :
                        setIsOpen({
                            detailsTab: false,
                            paymentTab: false,
                            concessionsTab: false,
                            finalTab: true
                        })
                }}
            >
                {values?.grossAmt && applicationQuotationDetailsId && applicationStatus && (finalBreakupData || finalizeCharges) ? <Card className='finalize-charges-card'>
                    <CardBody>
                        {values?.grossAmt ?
                            <div className='py-2' style={{ backgroundColor: '#fff' }}>
                                <div className='d-flex flex-row justify-content-between'>
                                    <span className='regular-large-bold color-dark'>Final fees schedule</span>
                                    <div className='d-flex justify-content-end flex-wrap' style={{ rowGap: '20px', columnGap: '10px' }}>
                                        <div>
                                            <span style={{ padding: "10px 16px", border: "1px solid #CCCCCC", borderRadius: "4px", fontSize: '12px', cursor: "pointer", color: '#0f0f0f' }} onClick={() => fetchRemarks(applicationId, setRemarks, setOpenRemarksDialogue)}>History</span>
                                        </div>

                                        {["APPL", "MODY"].includes(applicationStatus) ? <div>
                                            {paymentDetails?.courseFeeSummaryKey || paymentDetails?.provisionalSummaryKey ? <span style={{ padding: "10px 16px", border: "1px solid #CCCCCC", borderRadius: "4px", fontSize: '12px', cursor: "pointer", color: '#0f0f0f' }} onClick={() => { setDepositSlipDialogOpen(true) }}>Deposit Slip</span> : null}
                                        </div> : null}
                                        {["VERY", "PAPP", "APPL", "MODY"].includes(applicationStatus) ? <>
                                            {paymentDetails?.courseFeeSummaryKey || paymentDetails?.provisionalSummaryKey || paymentDetails?.draftSummaryKey ? <div> <span className='cursor' style={{ padding: "10px 16px", border: "1px solid #CCCCCC", borderRadius: "4px", cursor: "pointer", fontSize: '12px', color: '#0f0f0f' }} onClick={() => { getFileUrl(applicationStatus === 'APPL' ? paymentDetails?.courseFeeSummaryKey : applicationStatus === 'PAPP' ? paymentDetails?.provisionalSummaryKey : applicationStatus === 'MODY' ? paymentDetails?.courseFeeSummaryKey || paymentDetails?.provisionalSummaryKey || paymentDetails?.draftSummaryKey : paymentDetails?.draftSummaryKey) }}>{downloading ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : applicationStatus === 'APPL' ? 'Download course fee summary' : applicationStatus === 'PAPP' ? 'Download provisional summary' : applicationStatus === 'MODY' ? paymentDetails?.courseFeeSummaryKey ? 'Download course fee summary' : paymentDetails?.provisionalSummaryKey ? 'Download provisional summary' : 'Download draft summary' : 'Download draft summary'}</span> </div> : null}
                                            {paymentDetails?.courseFeeSummaryKey || paymentDetails?.provisionalSummaryKey || paymentDetails?.draftSummaryKey ? <div><span style={{ padding: "10px 16px", border: "1px solid #CCCCCC", borderRadius: "4px", fontSize: '12px', cursor: "pointer", color: '#0f0f0f' }}>{applicationStatus === 'APPL' ? 'E-mail course fee summary' : applicationStatus === 'PAPP' ? 'E-mail provisional summary' : 'E-mail draft summary'}</span> </div> : null}
                                            {/* <span 
                                            onClick={() => //console.log('take to do details page')} 
                                            style={{ padding: "10px 16px", border: "1px solid #CCCCCC", borderRadius: "4px", marginLeft: "10px", fontSize: '12px', cursor: "pointer", color: '#0f0f0f' }}
                                        >
                                            DO details
                                        </span> */}
                                            {checkPaymentModeForDo(paymentDetails?.paymentModeMasterId) && (
                                                <div><DODetailsButton
                                                    applicationId={applicationDetails?.applicationId}
                                                    applicationQuotationDetailsId={selectedQoutationId}
                                                    doExist={doExist}
                                                    viewOnly={viewOnly}
                                                    pastIdSelected = {pastIdSelected}
                                                    chargeStatus={applicationDetails?.chargeStatus}
                                                /></div>
                                            )}
                                        </> : null}
                                    </div>
                                </div>
                                <Row className="pt-3">
                                    <Col md="4" className="d-flex justify-content-between align-items-center">
                                        <span className='regular-small label-color'>Total fees :</span>
                                        <span className='regular-large-bold color-dark pt-1'>₹ {values?.grossAmt ?? ''}</span>
                                    </Col>
                                </Row>
                                <Row className="pt-2">
                                    <Col md="4" sm="4" className="d-flex justify-content-between align-items-center" >
                                        <span className='regular-small label-color'>Concession applied :</span>
                                        <span className='regular-large-bold color-dark pt-1'>₹ {values?.concAmt ?? ''}</span>
                                    </Col>
                                    <Col md={"2"} sm="2" className="d-flex align-items-center pt-1" style={{ paddingLeft: '0px' }}>
                                        {applicationStatus === 'CONC' && <span style={chargesStatus?.[applicationStatus] ?? {}}>{applicationStatus ?? ''}</span>}
                                    </Col>
                                </Row>
                                <Row className="pt-2">
                                    <Col md="4" className="d-flex justify-content-between align-items-center">
                                        <span className='regular-small label-color'>Payable amount :</span>
                                        <span className='regular-large-bold color-dark pt-1'>₹ {values?.paybleAmt ?? ''}</span>
                                    </Col>
                                </Row>
                                <Row className="pt-2">
                                    <Col md="4" className="d-flex justify-content-between align-items-center">
                                        <span className='regular-small label-color'>Taxes :</span>
                                        <div className='d-flex align-items-center'>
                                            <span className='regular-large-bold color-dark pt-1'>₹ {values?.taxAmt ?? ''}</span>
                                        </div>
                                    </Col>
                                    <Col md={"1"} sm="1" className="d-flex align-items-start pb-1 ml-n2" style={{ paddingLeft: '0px' }}>
                                        <span className='regular-large-bold color-dark pt-1 pl-1'>
                                            <img onClick={handleClick} src={require('./info.svg')} style={{ width: 16, height: 16, cursor: 'pointer' }} />

                                            <Popover
                                                style={{ borderRadius: 15 }}
                                                anchorOrigin={{
                                                    vertical: 'center',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                arrow
                                                PaperProps={{
                                                    style: {
                                                        left: '173px !important',
                                                        top: '625px !important'
                                                    }
                                                }}

                                            >
                                                <Box sx={{
                                                    position: 'relative',
                                                    mt: "10px",
                                                    "&::before": {
                                                        backgroundColor: "white",
                                                        content: '""',
                                                        display: "block",
                                                        position: "absolute",
                                                        width: 12,
                                                        height: 12,
                                                        top: -6,
                                                        transform: "rotate(45deg)",
                                                        left: "-3px",

                                                    }
                                                }} />
                                                {
                                                    [{ label: 'CGST', value: values?.cgst }, { label: 'SGST', value: values?.sgst }, { label: 'IGST', value: values?.igst }, { label: 'UTGST', value: values?.utgst }].map(i => {
                                                        return i.value ? <div key={i.label}  className='d-flex flex-row py-2 px-4'>
                                                            <div style={{ width: 80 }}>{i.label}</div>
                                                            <span>{` :  ₹${i.value}`}</span>
                                                        </div> : null
                                                    })
                                                }
                                            </Popover>
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="pt-2">
                                    <Col md="4" className="d-flex justify-content-between align-items-center">
                                        <span className='regular-small label-color'>Net payable amount :</span>
                                        <span className='regular-large-bold color-dark pt-1'>₹ {
                                        parseFloatTillDigit(values?.netAmt, 0) ?? ''}.00</span>
                                    </Col>
                                </Row>
                                {!viewOnly ? <div className='defaultViewComp_btn pt-3'>
                                    {/* <CustomButton
                                        content={'Collect now'}
                                        permissionType={'C,U'}
                                        permissionSet={userPermissions}
                                        onClick={() => { }}
                                    /> */}
                                    {/* <span className="ml-3" /> */}
                                    {applicationStatus === 'CONC' || applicationStatus === 'PROV' ? <><CustomButton
                                        content={'Verify charges'}
                                        permissionType={'C,U'}
                                        permissionSet={userPermissions}
                                        onClick={() => { handleVerificationDailog() }}
                                        disabled={disableActions || keepVerifyDiable()}
                                    /> <span className="ml-3" /></> : null}

                                    {(applicationStatus === 'PAPP' || applicationStatus === 'VERY') && !viewOnly ? <CustomButton
                                        // outline={true}
                                        content={loading ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : 'Finalize charges'}
                                        permissionType={'C,U'}
                                        permissionSet={userPermissions}
                                        // onClick={onSaveAndNextClick}
                                        onClick={()=>{
                                            isShowErrorMessage()?failureToast(LOAN_APPLICATION_ERROR_MGS):courseSummary()
                                        }}                                        disabled={isChargesCancelled || (applicationStatus === 'PAPP' && !isApproved()) || disableActions || loading}
                                        className="disable-btn-clss"
                                    /> : null}
                                    {applicationStatus === 'APPL' && !viewOnly ? <CustomButton
                                        outline={true}
                                        content={allowChargesModification ? 'Cancel' : 'Modify charges'}
                                        permissionType={'C,U'}
                                        permissionSet={userPermissions}
                                        onClick={onModification}
                                        disabled={isChargesCancelled || disableActions}
                                        className="outline-btn"
                                    /> : null}
                                </div> : null}
                     <ViewLastUpdatedBy
                        applicationStatus ={applicationStatus}
                        updatedBy = {updatedBy}
                        updatedOn = {updatedOn}
                        concessionDetails = {concessionDetails}
                        paymentDetails = {paymentDetails}
                    />

                            </div> : null}
                    </CardBody>
                </Card> : null}
            </Accordion>
            <div
                ref={captureRef}
                style={{
                    position: "absolute",
                    left: "-99999px",
                    top: "-99999px",
                    maxWidth: "800px",
                }}
            />
            <ConfirmationDialog
                message={"Are you sure you want to finalize charges ?"}
                title={"Confirm Charge Finalization"}
                nextButtonText={"Yes, Continue"}
                backButtonText={"No"}
                leftAlign={true}
                checkBoxDetails={
                    {
                        isVisible: checkForSendEmailCheckbox("visible"),
                        // checked: sendEmailNotification,
                        label: "Notify parent",
                        disabled: checkForSendEmailCheckbox("disabled"),
                        handleChange: () => { }
                    }
                }
            />
            {/* <ConfirmationDialogSuccess
                type="info"
                msg={`Thanks for verifying the Charges for the Student ${applicationDetails.studentName} (${applicationDetails.psid})`}
                setIsOpen={setInfoModalOpen}
                isOpen={isInfoModalOpen}
                headerMsg={"Info"}
                popupSymbol={<BsCheck2 />}
            /> */}

            <ConfirmationDialogSuccess
                type="info"
                msg={isInfoModalOpen?.data?.otherChargesMessage}
                setIsOpen={setInfoModalOpen}
                isOpen={isInfoModalOpen?.data?.otherChargesMessage}
                onAgree={isInfoModalOpen?.fn}
                headerMsg={"Info"}
                popupSymbol={<BsCheck2 />}
            />

            <ConfirmationDialogSuccess
                type="info"
                msg={sendEmailNotification ? "Successfully notified the Parent/Student about the Charges finalization." : "Notification for the Charges finalization has not been sent to the Parent/Student"}
                setIsOpen={setEmailNotificationMsgDialog}
                isOpen={emailNotificationMsgDialogOpen}
                onAgree={resetSendEmailNotificationFlag}
                headerMsg={"Info"}
                popupSymbol={<BsCheck2 />}
            />

            <ConfirmationDialogSuccess
                type="info"
                msg={'Provisional Charges finalized successfully, The Parent has not been notified, however, you can still download the Provisional Charge summary, and continue with the payments ( Provisional Receipt will be generated)'}
                setIsOpen={setProvisnalInformationModal}
                isOpen={isProvisnalInfoModalOpen}
                headerMsg={"Info"}
                popupSymbol={<BsCheck2 />}
            />

            <ConfirmationDialogSuccess
                type="info"
                msg={"This is a Transfer in/out Application, Please continue the Charge application on the PSERP."}
                setIsOpen={setTransferApplicationDialogOpen}
                isOpen={transferApplicationDialogOpen}
                onAgree={() => setTransferApplicationDialogOpen(false)}
                headerMsg={"Info"}
                popupSymbol={<IoInformation />}
                crossIconRequired={true}
            />

            <ProvisonalConfirmationDialog message={"The concession is still under the approval process, please confirm if you would like to proceed with verification of charges."} title={"Confirm verify charges"} nextButtonText={"Yes, Continue"} backButtonText={"No"} leftAlign={true} />

            <AddDoDetailsDialog
                open={isAddDoDialogOpen}
                setOpen={setIsAddDoDialogOpen}
                applicationId={applicationDetails?.applicationId}
                applicationQuotationDetailsId={applicationQuotationDetailsId}
                isOldQuotation = {pastIdSelected}
            />

            {isDepositSlipDislogOpen ? <DepositSlipDialog
                open={isDepositSlipDislogOpen}
                setOpen={setDepositSlipDialogOpen}
                applicationQuotationDetailsId={pastIdSelected ? selectedQoutationId : applicationQuotationDetailsId}
                psid={applicationDetails?.psid}
                pastIdSelected={pastIdSelected}
                getEligibleComponentList={getEligibleComponentList}
                getFileUploadUrls={getFileUploadUrls}
                concessionDetails={concessionDetails}
                studentCurrentYear={studentCurrentYear}
            /> : null}

            <RemarksDialogue
                remarksData={remarks}
                open={openRemarksDialogue}
                setOpen={setOpenRemarksDialogue}
                setRemarksData={setRemarks}
            />

        </>
    )
}

export default FinalizeCharges