import React from 'react';
import { useState, useEffect , useContext} from 'react';
import Loader from 'react-loader-spinner';
import { Card, CardHeader, CardBody, Label, FormGroup, Input, Container, Row, Col } from 'reactstrap';
import { putpost, getAPI, masterServiceBaseUrl } from 'services/http';
import Select from 'react-select';
import { useHistory, useParams } from 'react-router-dom';
import IsCoreMaster from 'views/pages/manage/common/commonComponents/coreMaster';
import { failureToast, successToast } from '../../../common/utils/methods/toasterFunctions/function';
import { PermisionDenied } from '../../../common/commonComponents';
import CustomButton from 'components/CustomButton';
import { endpoint, regex , pages} from '../../../common/constant';
import { fetchDetails } from '../../../common/utils/methods/commonMethods/utilityMethod';
import useConfirm from '../../../common/commonComponents/ConfirmationDialog/confirmationPrompt.jsx';
import { PermissionContext , RolePermissions} from '../../../../../../appContext';


const BusinessUnit_2 = props => {
  let history = useHistory();
  const {
    nextStep = () => { },
    isNew = true,
    formId,
    isViewOnly = false,
    setFranchiseDetails,
    isPrevious,
    data,
    updateAllStepData = () => { },
    appendAllStepData = () => { },
    action,
    businessAreaList,
    isCopied,
    // userPermissions,
    selectedTabValue,
    setSelectedTabValue,
    setBuId,
  } = props;

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages['businessAreaBasicDetails']['id']);



  const [apiloader, setApiLoader] = useState(false);
  const [currency, setCurrency] = useState([]);
  const [group, setgroupCode] = useState([]);
  const [region, setRegion] = useState([]);
  const [businessUnitType, setbusinessUnitType] = useState([]);
  const [companyCode, setcompanyCode] = useState([]);
  const [showCompanyCode, setShowCompanyCode] = useState([]);
  const [campusInstitution, setcampusInstitution] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [randomKey, setRandomKey] = useState('randomKey');
  const [isUpdated, setIsUpdated] = useState(false);
  const [academicCareerData, setAcademicCareerData] = useState([]);
  const [stateData,setStateData] = useState([])

  const [academicGroupData , setAcademicGroupData] = useState([]);
  const [academicClassData , setAcademicClassData] = useState([]);

  const [value, setValue] = useState({
    businessAreaKey: '',
    businessAreaDispValue: '',
    businessAreaDesc: '',
    region: [],
    currency: '',
    groupCode: '',
    companyCode: '',
    campusInstitutin: '',
    jurisdiction: '',
    serviceTaxRegistration: '',
    // gstInNumber: '',
    stateCode: '',
    cinllpNumber: '',
    tid: '',
    reference: '',
    branchDisplayName : '',
    businessUnitType: [],
    status: '',
    updatedOn: '',
    effectiveDate: '',
    acadOrg: '',
    academicCareer: [],
    academicGroup : [],
    academicClass : [],
    sapPlantId : '',
    sapBusinessArea: '',
    sapDescription: '',
    businessPlaceCode: '',
    defermentBusinessArea: '',
    gstInNumber: ''
  });

  const [arrayState, setArrayState] = useState({
    regionArray: [],
    businessUnitTypeArray: [],
    academicCareerArray: [],
    academicGroupArray : [],
    academicClassArray : [],
  });
  const [ConfirmationDialog, confirm] = useConfirm();


  // const fetchBusinessAreaData = () => {
  //   setApiLoader(true);
  //   getAPI(
  //     `${masterServiceBaseUrl}/businessArea/getBusinessArea/${formId}`,
  //     (data) => {
  //       //////console.log('data from the server : ', data);
  //       let x = value.region;
  //       data.data.regionId &&
  //         data.data.regionId.map((data) => {
  //           x.push(getSelectedObj(data, region));
  //           setValue({
  //             ...value,
  //             region: x,
  //           });
  //         });

  //       let y = value.businessUnitType;
  //       data.data.businessUnitTypeId &&
  //         data.data.businessUnitTypeId.map((data) => {
  //           y.push(getSelectedObj(data, businessUnitType));
  //           setValue({
  //             ...value,
  //             businessUnitType: y,
  //           });
  //         });
  //       setValue({
  //         ...value,
  //         businessAreaKey: data.data.businessAreaKey,
  //         companyCode: getSelectedObj(data.data.companyCodeId, companyCode),
  //         groupCode: getSelectedObj(data.data.groupCodeId, group),
  //         campusInstitution: getSelectedObj(
  //           data.data.campusInstitutionId,
  //           campusInstitution
  //         ),
  //         status: data.data.status,
  //         currency: getSelectedObj(data.data.currencyId, currency),
  //         jurisdiction: data.data.jurisdiction,
  //         serviceTaxRegistration: data.data.serviceTaxRegistration,
  //         gstInNumber: data.data.gstInNumber,
  //         cinllpNumber: data.data.cinllpNumber,
  //         tid: data.data.tid,
  //         reference: data.data.referenceId,
  //         businessAreaDesc: data.data.businessAreaDesc,
  //         businessAreaDispValue: data.data.businessAreaDispValue,
  //       });
  //       setArrayState({ regionArray: [], businessUnitTypeArray: [] });
  //       setIsDisabled(true);
  //       setApiLoader(false);
  //     },
  //     (data) => {
  //       failureToast(data['message']);
  //       setApiLoader(false);
  //     }
  //   );
  // };

  const insertData = data => {
    //////console.log("inside the insert data section",data)
    resetValues();
    let x = value.region;
    data &&
      data['regionId'] &&
      data['regionId'].map(data => {
        x.push(getSelectedObj(data, region));
        setValue({
          ...value,
          region: x,
        });
      });

    let y = value.businessUnitType;
    data &&
      data['businessUnitTypeId'] &&
      data['businessUnitTypeId'].map(data => {
        y.push(getSelectedObj(data, businessUnitType));
        setValue({
          ...value,
          businessUnitType: y,
        });
      });

    let z = value.academicCareer;
    data &&
      data['academicCareerId'] &&
      data['academicCareerId'].map(data => {
        z.push(getSelectedObj(data, academicCareerData));
        setValue({
          ...value,
          academicCareer: z,
        });
      });

    let p = value.academicGroup;
    data &&
    data['academicStreamId'] &&
    data['academicStreamId'].map(data => {
      p.push(getSelectedObj(data, academicGroupData));
      setValue({
        ...value,
        academicGroup: p,
      });
    });

    let q = value.academicClass;

    data &&
    data['classMasterId'] &&
    data['classMasterId'].map(data => {
      q.push(getSelectedObj(data, academicClassData));
      setValue({
        ...value,
        academicGroup: q,
      });
    });

    

    setValue({
      ...value,
      businessAreaKey: data && data['businessAreaKey'],
      companyCode: getSelectedObj(data && data['companyCodeId'], companyCode),
      groupCode: getSelectedObj(data && data['groupCodeId'], group),
      campusInstitutin: getSelectedObj(data && data['campusInstitutionId'], campusInstitution),
      status: data && data['status'],
      currency: getSelectedObj(data && data['currencyId'], currency),
      jurisdiction: data && data['jurisdiction'],
      serviceTaxRegistration: data && data['serviceTaxRegistration'],
      gstInNumber: data && data['gstInNumber'],
      cinllpNumber: data && data['cinllpNumber'],
      tid: data && data['tid'],
      reference: data && data['referenceId'],
      branchDisplayName : data && data ['branchDisplayName'],
      businessAreaDesc: data && data['businessAreaDesc'],
      businessAreaDispValue: data && data['businessAreaDispValue'],
      acadOrg: data && data['acadOrg'],

      sapPlantId :  data?.['sapPlantId'] ?? '',
      sapBusinessArea: data && data['sapBusinessArea'],
      sapDescription: data?.['sapDescription'] ?? '',
      businessPlaceCode: data?.['businessPlaceCode'] ?? '',
      defermentBusinessArea: data?.['defermentBusinessArea'] ?? '',
      // gstInNumber: data?.['gstInNumber'] ?? '',
      stateCode: data?.stateCode ? stateData.find(i => i.stateCode === +(data?.stateCode)) : ''
    });
    setArrayState({ regionArray: [], businessUnitTypeArray: [], academicCareerArray: [] , academicGroupArray : [], academicClassArray : []});
  };

  const getSelectedObj = (id, arr) => {
    return arr.filter(item => {
      return item.value === id;
    })[0];
  };

  useEffect(() => {
    ////console.log("is Copied",isCopied)

    if (action === 'view' || action === 'edit') {
      //console.log('inside the edit section')
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }

    if (
      data &&
      !isPrevious &&
      isCopied &&
      group.length > 0 &&
      companyCode.length > 0 &&
      region.length > 0 &&
      businessUnitType.length > 0 &&
      currency.length > 0 &&
      campusInstitution.length > 0 &&
      academicCareerData.length > 0 && 
      academicGroupData.length > 0 && 
      academicClassData.length > 0 && 
      stateData?.length > 0
    ) {
      insertData(data);
    }
  }, [data, isCopied, isNew, group, companyCode, region, businessUnitType, currency, campusInstitution, academicCareerData,stateData,academicGroupData,academicClassData]);

  useEffect(() => {
    if (
      isPrevious &&
      formId &&
      group.length > 0 &&
      companyCode.length > 0 &&
      region.length > 0 &&
      businessUnitType.length > 0 &&
      currency.length > 0 &&
      campusInstitution.length > 0 &&
      academicCareerData.length > 0 &&
      academicGroupData.length > 0 &&
      academicClassData.length > 0 &&
      stateData?.length > 0
    ) {
      setApiLoader(true);
      getAPI(
        `${masterServiceBaseUrl}/businessArea/getBusinessArea/${formId}`,
        data => {
          ////console.log(data.data)
          insertData(data.data);
          setApiLoader(false);
        },
        data => {
          setApiLoader(false);
          failureToast(data['message']);
        }
      );
    }
  }, [isPrevious, formId, group, companyCode, region, businessUnitType, currency, campusInstitution, academicCareerData,stateData,academicGroupData,academicClassData]);

  const fetchData = (url, stateMethod, name) => {
    setApiLoader(true);
    getAPI(
      url,
      data => {
        let list = [];
        //this is for dropdown fields only
        const keyName = `${name}DispValue`;
        const key = `${name}Desc`;
        const key1 = `${name}Key`;
        list = data.data.map(data => {
          return {
            value: data.id,
            label: data[keyName],
            desc: data[key],
            key: data[key1],
          };
        });
        stateMethod(list);
        setApiLoader(false);
      },
      data => {
        setApiLoader(false);
        failureToast(data['message']);
      }
    );
  };

  const resetValues = () => {
    setValue({
      businessAreaKey: '',
      businessAreaDispValue: '',
      businessAreaDesc: '',
      region: [],
      currency: '',
      groupCode: '',
      companyCode: '',
      campusInstitution: '',
      jurisdiction: '',
      serviceTaxRegistration: '',
      gstInNumber: '',
      stateCode:'',
      cinllpNumber: '',
      tid: '',
      reference: '',
      branchDisplayName: '',
      businessUnitType: [],
      status: '',
      updatedOn: '',
      effectiveDate: '',
      acadOrg: '',
      academicCareer: [],
      academicGroup : [],
      academicClass : [],
      sapPlantId: '',
      sapBusinessArea: '',
      sapDescription: '',
      businessPlaceCode: '',
      defermentBusinessArea: '',
    });
    setArrayState({ regionArray: [], businessUnitTypeArray: [], academicCareerArray: [] , academicGroupArray : [], academicClassArray : []});
  };

  const getCompanyCode = value => {
    try {
      getAPI(
        masterServiceBaseUrl + `/companyCode/getCompanyCodeByGroup/${value}`,
        data => {
          let tempCompanyCode = [];
          data &&
            data.data.map(item => {
              tempCompanyCode.push({
                value: item.id,
                label: item.companyCodeDispValue,
                key: item.companyCodeKey,
                desc: item.companyCodeDesc,
              });
            });
          setValue(preState => ({
            ...preState,
            companyCode: '',
          }));
          setShowCompanyCode(tempCompanyCode);
        },
        data => {
          setValue(preState => ({
            ...preState,
            companyCode: '',
          }));
          setShowCompanyCode([]);
          failureToast(data['message']);
        }
      );
    } catch (e) {
      //////console.log('Error', e);
    }
  };
  // //////console.log('formId', formId);
  // //////console.log('ValuesObject___', value);

  const multiSelectArrayMapping = () => {
    let x = arrayState && arrayState.regionArray;
    value &&
      value.region &&
      value.region.map(data => {
        x.push(data.value);
        setArrayState({
          ...arrayState,
          regionArray: x,
        });
      });
    let y = arrayState && arrayState.businessUnitTypeArray;
    value &&
      value.businessUnitType &&
      value.businessUnitType.map(data => {
        y.push(data.value);
        setArrayState({
          ...arrayState,
          businessUnitTypeArray: y,
        });
      });

    let z = arrayState && arrayState.academicCareerArray;
    value &&
      value.academicCareer &&
      value.academicCareer.map(data => {
        z.push(data.value);
        setArrayState({
          ...arrayState,
          academicCareerArray: z,
        });
      });

    let p = arrayState && arrayState.academicGroupArray;
    console.log('p', p);
    console.log('value.academicGroup', value.academicGroup);
    value &&
      value.academicGroup &&
      value.academicGroup.map(data => {
        p.push(data.value);
        setArrayState({
          ...arrayState,
          academicGroupArray: p,
        });
      });

    let q = arrayState && arrayState.academicClassArray;
    value &&
      value.academicClass &&
      value.academicClass.map(data => {
        q.push(data.value);
        setArrayState({
          ...arrayState,
          academicClassArray: q,
        });
      });
      
  };

  // //////console.log('arrayState__', arrayState);

  const SaveButton = async() => {
    //console.log('Values is here ',value)
    setApiLoader(true);

    setArrayState({ regionArray: [], businessUnitTypeArray: [], academicCareerArray: [] , academicGroupArray : [], academicClassArray : []});
    // //////console.log('arrayState__', arrayState);

    if (value.businessAreaKey === '') {
      failureToast('Please Enter Business Area Key');
      setApiLoader(false);
      return;
    } else if (value.businessAreaKey && value.businessAreaKey.length < 5) {
      failureToast('Business Area Key must have atleast 5 characters');
      setApiLoader(false);
      return;
    } else if (!value.groupCode || value.groupCode === '') {
      failureToast('Please Select Group Code');
      setApiLoader(false);
      return;
    } else if (value.region === null || value.region == '') {
      failureToast('Please Select Region');
      setApiLoader(false);
      return;
    } else if (!value.businessUnitType || value.businessUnitType == '') {
      failureToast('Please Select Business Area Type');
      setApiLoader(false);
      return;
    } else if (!value.companyCode || value.companyCode === '') {
      failureToast('Please Select Company Code');
      setApiLoader(false);
      return;
    } else if (!value.campusInstitutin || value.campusInstitutin === '') {
      failureToast('Please Select Campus Institution');
      setApiLoader(false);
      return;
    } else if (!value.currency || value.currency === '') {
      failureToast('Please Select Currency');
      setApiLoader(false);
      return;
    }else if (value.stateCode === null || value.stateCode === '' || !value.stateCode?.value){
      failureToast('Please Select State code');
      setApiLoader(false);
      return
    }else if (!value.businessAreaDispValue || value.businessAreaDispValue === '') {
      failureToast('Please Enter Display Value');
      setApiLoader(false);
      return;
    } else if (!value.businessAreaDesc || value.businessAreaDesc === '') {
      failureToast('Please Enter Description');
      setApiLoader(false);
      return;
    } else if (!value.acadOrg || value.acadOrg === '') {
      failureToast('Please Enter Academic Organisation');
      setApiLoader(false);
      return;
    } else if (value.academicCareer === null || value.academicCareer === '' || !value.academicCareer?.length) {
      failureToast('Please Enter Academic Career');
      setApiLoader(false);
      return;
    } else if (value.academicGroup === null || value.academicGroup === '' || !value.academicGroup?.length) {
      failureToast('Please Enter Academic Group');
      setApiLoader(false);
      return;
    } else if (value.academicClass === null || value.academicClass === '' || !value.academicClass?.length) {
      failureToast('Please Enter Academic Class');
      setApiLoader(false);
      return;
    }else if (value.sapPlantId === null || value.sapPlantId === ''){
      failureToast('Please Enter SAP plant ID');
      setApiLoader(false);
      return
    }else if (value.sapPlantId && value.sapPlantId?.length < 4){
      failureToast('Please Enter Valid SAP plant ID');
      setApiLoader(false);
      return
    }else if (value.sapBusinessArea === null || value.sapBusinessArea === ''){
      failureToast('Please Enter SAP Business Area');
      setApiLoader(false);
      return
    }else if (value.sapBusinessArea && value.sapBusinessArea?.length < 4){
      failureToast('Please Enter Valid SAP Business Area');
      setApiLoader(false);
      return
    }else if (value.sapDescription === null || value.sapDescription === ''){
      failureToast('Please Enter Description in SAP');
      setApiLoader(false);
      return
    }else if (value.businessPlaceCode === null || value.businessPlaceCode === ''){
      failureToast('Please Enter Business place code');
      setApiLoader(false);
      return
    }else if (value.businessPlaceCode && value.businessPlaceCode?.length < 4){
      failureToast('Please Enter Valid Business place code');
      setApiLoader(false);
      return
    }else if (value.defermentBusinessArea === null || value.defermentBusinessArea === ''){
      failureToast('Please Enter Deferment business area');
      setApiLoader(false);
      return
    }else if (value.defermentBusinessArea && value.defermentBusinessArea?.length < 4){
      failureToast('Please Enter Valid Deferment business area');
      setApiLoader(false);
      return
    }else if (value.gstInNumber === null || value.gstInNumber === ''){
      failureToast('Please Enter GSTIN number');
      setApiLoader(false);
      return
    }else if (value.gstInNumber && !value.gstInNumber.startsWith(value.stateCode.gstCode)){
      failureToast('Please Enter Valid GSTIN number');
      setApiLoader(false);
      return
    }else if (value.gstInNumber && value.gstInNumber?.length < 15){
      failureToast('Please Enter Valid GSTIN number');
      setApiLoader(false);
      return
    }
    
    // if (action === 'new') {

    let canContinue = true;
    if(!isNew || isPrevious){
      canContinue = await confirm();
    }
    if(!canContinue) {
      setApiLoader(false);
      return
    }

    // }
    multiSelectArrayMapping();

    let obj = {
      businessAreaKey: value.businessAreaKey,
      regionId: arrayState.regionArray,
      currencyId: value.currency.value,
      groupCodeId: value.groupCode.value,
      companyCodeId: value.companyCode.value,
      campusInstitutionId: value.campusInstitutin.value,
      jurisdiction: value.jurisdiction,
      serviceTaxRegistration: value.serviceTaxRegistration,
      gstInNumber: value.gstInNumber,
      cinllpNumber: value.cinllpNumber,
      tid: value.tid,
      referenceId: value.reference,
      branchDisplayName : value.branchDisplayName,
      businessUnitTypeId: arrayState.businessUnitTypeArray,
      businessAreaDispValue: value.businessAreaDispValue,
      businessAreaDesc: value.businessAreaDesc,
      status: value.status,
      acadOrg: value.acadOrg,
      academicCareerId: arrayState.academicCareerArray,
      academicStreamId: arrayState.academicGroupArray,
      classMasterId: arrayState.academicClassArray,
      sapPlantId: value.sapPlantId,
      sapBusinessArea: value.sapBusinessArea,
      sapDescription: value.sapDescription,
      businessPlaceCode: value.businessPlaceCode,
      defermentBusinessArea: value.defermentBusinessArea,
      stateCode: value.stateCode?.value
    };

    if (action != 'view' && action != 'edit') {
      props.setGroupCode(value.groupCode.value);
      props.setCompanyCode(value.companyCode.value);
    }

    let firstFormData = {
      businessUnit: null,
      companyCode: value.companyCode.value,
      companyCodeDispValue: value.companyCode.label,
      groupCode: value.groupCode.value,
      groupCodeKey: value.groupCode.key,
      groupCodeDesc: value.groupCode.desc,
      companyCodeKey: value.companyCode.key,
      businessUnitType: value.businessUnitType,
      businessKey: value.businessAreaKey,
      businessDesc: value.businessAreaDesc,
      companyCodeDesc: value.companyCode.desc,
    };

    if (isNew && !isPrevious) {

      putpost(
        masterServiceBaseUrl + '/businessArea/createBusinessArea',
        data => {
          // successToast(data['message']);
          props.setBusinessAreaId(data['data']['id']);
          setBuId(data['data']['id']);
          firstFormData['businessUnit'] = data['data']['id'];
          props.saveStepsCompleted(1);
          //appendAllStepData(obj,'firstForm')
          // setValue({
          //   businessAreaKey: '',
          //   businessAreaDispValue: '',
          //   businessAreaDesc: '',
          //   region: [],
          //   currency: '',
          //   groupCode: '',
          //   companyCode: '',
          //   campusInstitution: '',
          //   jurisdiction: '',
          //   serviceTaxRegistration: '',
          //   gstInNumber: '',
          //   cinllpNumber: '',
          //   tid: '',
          //   reference: '',
          //   businessUnitType: [],
          //   status: '',
          //   updatedOn: '',
          //   effectiveDate: '',
          //   acadOrg: '',
          //   academicCareer: []
          // });
          setArrayState({ regionArray: [], businessUnitTypeArray: [], academicCareerArray: [], academicGroupArray : [], academicClassArray : [] });
          setApiLoader(false);
          //have commented this as need some more info from here
          // nextStep(data['data']['id']);

          //The Next line is for whole flow of bussiness are but for prod we are rolling out oly first page thats why commenting this
          //nextStep(firstFormData);
          //the next line have to be removed after 1st prod deployment
          successToast("Business Area information has been recorded");
          setSelectedTabValue('two');
          // history.push("/admin/businessUnit")
          //for first prod deployment
          setRandomKey(new Date().getMilliseconds());
        },
        data => {
          setApiLoader(false);
          setArrayState({ regionArray: [], businessUnitTypeArray: [], academicCareerArray: [] , academicGroupArray : [], academicClassArray : []});
          failureToast(data['message']);
        },
        obj,
        'post'
      );
    }

    if (!isNew || isPrevious) {
      putpost(
        masterServiceBaseUrl + '/businessArea/updateBusinessArea/' + formId,
        data => {
          successToast(data['message']);
          // firstFormData['businessUnit']  = data['data']['id']
          // updateAllStepData(obj,'firstForm')
          setArrayState({ regionArray: [], businessUnitTypeArray: [], academicCareerArray: [], academicGroupArray : [], academicClassArray : [] });
          // fetchBusinessAreaData();
          if (action != 'view' && action != 'edit') {
            props.setBusinessAreaId(data['data']['id']);
            // setRandomKey(new Date().getMilliseconds());
            nextStep();
          }
          setSelectedTabValue('two');
          setApiLoader(false);
          setIsUpdated(false);
          setIsDisabled(true);
        },
        data => {
          setApiLoader(false);
          setArrayState({ regionArray: [], businessUnitTypeArray: [], academicCareerArray: [] , academicGroupArray : [], academicClassArray : []});
          failureToast(data['message']);
        },
        obj,
        'put'
      );
    }
  };

  useEffect(() => {
    fetchData(masterServiceBaseUrl + '/companyCode/getAllActiveCompanyCode', setcompanyCode, 'companyCode');
    fetchData(masterServiceBaseUrl + '/region/getAllActiveRegion', setRegion, 'region');
    fetchData(
      masterServiceBaseUrl + '/businessUnitType/getAllActiveBusinessUnitType',
      setbusinessUnitType,
      'businessUnitType'
    );
    fetchData(masterServiceBaseUrl + '/groupCode/getAllActiveGroupCode', setgroupCode, 'groupCode');
    fetchData(
      masterServiceBaseUrl + '/academicInstitution/getAllActiveAcademicInstitution',
      setcampusInstitution,
      'academicInstitution'
    );
    fetchData(masterServiceBaseUrl + '/currency/getAllActiveCurrency', setCurrency, 'currency');
    fetchData(masterServiceBaseUrl + '/academicCareer/getAllActiveAcademicCareer', setAcademicCareerData, 'academicCareer');
    fetchDetails(endpoint.state.getAllActive,setStateData, 'state','state');
    fetchData(endpoint.academic_group.getAllActive, setAcademicGroupData, 'academicGroup');
    fetchData(endpoint.classes.getAllActive, setAcademicClassData, 'class');
  }, []);


  const onKeyPress =(e) => {
    if (
      !regex.alphaNumeric.test(
      String.fromCharCode(e.charCode)
      )
    ) {
      e.preventDefault();
      return;
    }
  }

  if(selectedTabValue !=='one') return null;

  return userPermissions ? (
    <>
      {apiloader ? (
        <div className="mx-auto text-center py-5 my-5" style={{ height: '50vh' }}>
          <Loader type="Rings" color="#00BFFF" height={70} width={70} timeout={10000} />
        </div>
      ) : (
        // <Container className={'mt-4'} fluid={true} key={randomKey}>
        //   <Card className="mb-4">
        //     {action != 'view' && action != 'edit' ? (
        //       <CardHeader>
        //         <Row>
        //           <Col md="6">
        //             <h3 className="mb-0 floatLeft">Business Area</h3>
        //             <IsCoreMaster master={'businessArea'} masterDisplayValue={'Business Area'} />
        //           </Col>
        //           <Col md="6">
        //             <div>
        //               <h5>Copy From BU</h5>
        //               {businessAreaList ? (
        //                 <Select
        //                   value={props.copyBusinessArea}
        //                   id="example1cols1Input"
        //                   options={businessAreaList}
        //                   isDisabled={isPrevious}
        //                   onChange={sData => {
        //                     resetValues();
        //                     props.setCopyBusinessArea(sData);
        //                   }}
        //                 //   onChange={ ( sData ) => {
        //                 //   // //////console.log(sData.value)
        //                 //     setSelectedCode( preState => ( { ...preState, groupCodeId: sData.value } ) );
        //                 //   getCompanyCode(sData.value);
        //                 //   setValue({ ...value, groupCode: sData });
        //                 // }}
        //                 />
        //               ) : (
        //                 <span>Loading List ...</span>
        //               )}
        //             </div>
        //           </Col>
        //         </Row>
        //       </CardHeader>
        //     ) : null}
        //     <CardBody>
              <div>
                <>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example3cols2Input">
                        *Business Area Key
                      </Label>
                      <Input
                        maxLength="50"
                        value={value.businessAreaKey}
                        name="atrributeDesc"
                        disabled={isDisabled || isPrevious}
                        type="text"
                        placeholder={isDisabled ? '' : 'Business Area Key'}
                        onChange={sData => {
                          let x = sData.target.value;
                          setValue({ ...value, businessAreaKey: x });
                        }}
                        style={{ height: '39px' }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example3cols2Input">
                        *Group Code
                      </Label>
                      <Select
                        value={value.groupCode}
                        id="example1cols1Input"
                        options={group}
                        isDisabled={action === 'new' && !isPrevious ? isDisabled : true}
                        required
                        onChange={sData => {
                          // ////console.log(sData.value)
                          getCompanyCode(sData.value);
                          setValue({ ...value, groupCode: sData });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example3cols2Input">
                        *Company Code
                      </Label>
                      <Select
                        value={value.companyCode}
                        id="example1cols1Input"
                        options={showCompanyCode}
                        isDisabled={action === 'new' && !isPrevious ? isDisabled : true}
                        required
                        onChange={sData => {
                          setValue({ ...value, companyCode: sData });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example3cols2Input">
                        *Business Area Type
                      </Label>
                      <Select
                        value={
                          value.businessUnitType
                            ? value.businessUnitType.map(item => {
                              if (item == '') {
                                return (
                                  setValue(preState => ({
                                    ...preState,
                                    businessUnitType: [],
                                  })),
                                  setArrayState(preState => ({
                                    ...preState,
                                    businessUnitTypeArray: [],
                                  }))
                                );
                              } else {
                                return item;
                              }
                            })
                            : ''
                        }
                        id="example1cols1Input"
                        isMulti
                        options={businessUnitType}
                        isDisabled={action === 'new' && !isPrevious ? isDisabled : true}
                        required
                        onChange={sData => {
                          // //////console.log( "Selected S data", sData );
                          setFranchiseDetails(sData);
                          setValue({ ...value, businessUnitType: sData });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example3cols2Input">
                        *Region
                      </Label>
                      <Select
                        value={
                          value.region
                            ? value.region.map(item => {
                              if (item == '') {
                                return (
                                  setValue(preState => ({
                                    ...preState,
                                    region: [],
                                  })),
                                  setArrayState(preState => ({
                                    ...preState,
                                    regionArray: [],
                                  }))
                                );
                              } else {
                                return item;
                              }
                            })
                            : ''
                        }
                        id="example1cols1Input"
                        isMulti
                        options={region}
                        isDisabled={isDisabled}
                        required
                        onChange={sData => setValue({ ...value, region: sData })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example3cols2Input">
                        *Campus Institution
                      </Label>
                      <Select
                        value={value.campusInstitutin}
                        id="example1cols1Input"
                        options={campusInstitution}
                        isDisabled={isDisabled}
                        required
                        onChange={sData => setValue({ ...value, campusInstitutin: sData })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example3cols2Input">
                        *Status
                      </Label>
                      <Select
                        value={{ value: value.status, label: value.status }}
                        id="example1cols1Input"
                        options={[
                          { value: 'ACTIVE', label: 'ACTIVE' },
                          { value: 'INACTIVE', label: 'INACTIVE' },
                        ]}
                        isDisabled={isDisabled}
                        required
                        onChange={sData => setValue({ ...value, status: sData.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example3cols2Input">
                        *Currency
                      </Label>
                      <Select
                        value={value.currency}
                        id="example1cols1Input"
                        options={currency}
                        isDisabled={isDisabled}
                        required
                        onChange={sData => setValue({ ...value, currency: sData })}
                        currency
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example2cols1Input">
                        Jurisdiction
                      </Label>
                      <Input
                        value={value.jurisdiction}
                        name="atrributeDesc"
                        disabled={isDisabled}
                        placeholder={isDisabled ? '' : 'Jurisdiction'}
                        type="text"
                        maxLength="50"
                        onChange={sData => {
                          let x = sData.target.value;
                          setValue({ ...value, jurisdiction: x });
                        }}
                        style={{ height: '39px' }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example2cols1Input">
                        Service Tax Registration
                      </Label>
                      <Input
                        value={value.serviceTaxRegistration}
                        name="atrributeDesc"
                        disabled={isDisabled}
                        placeholder={isDisabled ? '' : 'Service Tax Registration'}
                        type="text"
                        maxLength="50"
                        onChange={sData => {
                          let x = sData.target.value;
                          setValue({
                            ...value,
                            serviceTaxRegistration: x,
                          });
                        }}
                        style={{ height: '39px' }}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example3cols2Input">
                        *State code
                      </Label>
                      <Select
                        value={value.stateCode}
                        id="example1cols1Input"
                        options={stateData}
                        isDisabled={isDisabled}
                        required
                        onChange={sData => setValue({ ...value, stateCode: sData })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example2cols1Input">
                        CIN/LLP Number
                      </Label>
                      <Input
                        value={value.cinllpNumber}
                        name="atrributeDesc"
                        disabled={isDisabled}
                        placeholder={isDisabled ? '' : 'CIN/LLP Number'}
                        type="text"
                        maxLength="50"
                        onChange={sData => {
                          let x = sData.target.value;
                          setValue({
                            ...value,
                            cinllpNumber: x,
                          });
                        }}
                        style={{ height: '39px' }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example2cols1Input">
                        TID
                      </Label>
                      <Input
                        value={value.tid}
                        name="atrributeDesc"
                        disabled={isDisabled}
                        placeholder={isDisabled ? '' : 'TID'}
                        type="text"
                        maxLength="50"
                        onChange={sData => {
                          let x = sData.target.value;
                          setValue({
                            ...value,
                            tid: x,
                          });
                        }}
                        style={{ height: '39px' }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example2cols1Input">
                        Reference ID
                      </Label>
                      <Input
                        value={value.reference}
                        name="atrributeDesc"
                        disabled={isDisabled}
                        placeholder={isDisabled ? '' : 'Reference ID'}
                        type="text"
                        maxLength="50"
                        onChange={sData => {
                          let x = sData.target.value;
                          setValue({
                            ...value,
                            reference: x,
                          });
                        }}
                        style={{ height: '39px' }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example2cols9Input">
                        Branch display name
                      </Label>
                      <Input
                        value={value.branchDisplayName}
                        name="atrributeDesc"
                        disabled={isDisabled}
                        placeholder={isDisabled ? '' : 'Enter name (Ex: Mumbai-Powai)'}
                        type="text"
                        maxLength="100"
                        onChange={sData => {
                          let x = sData.target.value;
                          setValue({
                            ...value,
                            branchDisplayName: x,
                          });
                        }}
                        style={{ height: '39px' }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example3cols2Input">
                        *Display Value(BU Code)
                      </Label>
                      <Input
                        value={value.businessAreaDispValue}
                        name="atrributeDesc"
                        disabled={isDisabled || isPrevious} // input to be disabled in view/edit mode
                        placeholder="Display Value"
                        type="text"
                        maxLength="50"
                        onChange={sData => {
                          let x = sData.target.value;
                          setValue({
                            ...value,
                            businessAreaDispValue: x,
                          });
                        }}
                        style={{ height: '39px' }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example3cols2Input">
                        *Branch display name (Center)
                      </Label>
                      <Input
                        value={value.businessAreaDesc}
                        name="atrributeDesc"
                        disabled={isDisabled}
                        placeholder="Ex: AAKASH INSTITUTE, SOUTH EXTENSION FOUNDATION WING"
                        type="text"
                        maxLength="50"
                        onChange={sData => {
                          let x = sData.target.value;
                          setValue({
                            ...value,
                            businessAreaDesc: x,
                          });
                        }}
                        style={{ height: '39px' }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example3cols2Input">
                        *Academic Organisation
                      </Label>
                      <Input
                        value={value.acadOrg}
                        name="acadOrg"
                        disabled={isDisabled}
                        placeholder="Academic Organisation"
                        type="text"
                        maxLength="10"
                        onChange={sData => {
                          let x = sData.target.value;
                          setValue({
                            ...value,
                            acadOrg: x,
                          });
                        }}
                        style={{ height: '39px' }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example3cols2Input">
                        *Academic Career
                      </Label>
                      <Select
                        value={
                          value.academicCareer
                            ? value.academicCareer.map(item => {
                              if (item == '') {
                                return (
                                  setValue(preState => ({
                                    ...preState,
                                    academicCareer: [],
                                  })),
                                  setArrayState(preState => ({
                                    ...preState,
                                    academicCareerArray: [],
                                  }))
                                );
                              } else {
                                return item;
                              }
                            })
                            : ''
                        }
                        id="example1cols1Input"
                        placeholder="Academic Career"
                        isMulti
                        options={academicCareerData}
                        isDisabled={isDisabled}
                        required
                        onChange={sData => setValue({ ...value, academicCareer: sData })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example3cols2Input">
                        *Academic Stream
                      </Label>
                      <Select
                        value={
                          value.academicGroup
                            ? value.academicGroup.map(item => {
                              if (item == '') {
                                return (
                                  setValue(preState => ({
                                    ...preState,
                                    academicGroup: [],
                                  })),
                                  setArrayState(preState => ({
                                    ...preState,
                                    academicGroupArray: [],
                                  }))
                                );
                              } else {
                                return item;
                              }
                            })
                            : ''
                        }
                        id="example1cols1Input"
                        placeholder="Academic Group"
                        isMulti
                        options={academicGroupData}
                        isDisabled={isDisabled}
                        required
                        onChange={sData => setValue({ ...value, academicGroup: sData })}
                      />
                    </FormGroup>
                    </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example3cols2Input">
                        *Academic Class
                      </Label>
                      <Select
                        value={
                          value.academicClass
                            ? value.academicClass.map(item => {
                              if (item == '') {
                                return (
                                  setValue(preState => ({
                                    ...preState,
                                    academicClass: [],
                                  })),
                                  setArrayState(preState => ({
                                    ...preState,
                                    academicClassArray: [],
                                  }))
                                );
                              } else {
                                return item;
                              }
                            })
                            : ''
                        }
                        id="example1cols1Input"
                        placeholder="Academic Class"
                        isMulti
                        options={academicClassData}
                        isDisabled={isDisabled}
                        required
                        onChange={sData => setValue({ ...value, academicClass: sData })}
                      />
                    </FormGroup>
                  </Col>

                </Row>
                <hr />
                <div className='py-4'>
                  <span className='regular-large-bold color-dark'>SAP Plant code Mapping</span>
                </div>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example3cols2Input">
                        *SAP Plant ID
                      </Label>
                      <Input
                        value={value.sapPlantId}
                        name="acadOrg"
                        disabled={isDisabled}
                        placeholder="Enter SAP plant ID"
                        type="text"
                        maxLength="6"
                        onChange={sData => {
                          let x = sData.target.value;
                          setValue({
                            ...value,
                            sapPlantId: x,
                          });
                        }}
                        style={{ height: '39px' }}
                        onKeyPress={onKeyPress}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example3cols2Input">
                        *SAP business area
                      </Label>
                      <Input
                        value={value.sapBusinessArea}
                        name="acadOrg"
                        disabled={isDisabled}
                        placeholder="Enter SAP business area"
                        type="text"
                        maxLength="6"
                        onChange={sData => {
                          let x = sData.target.value;
                          setValue({
                            ...value,
                            sapBusinessArea: x,
                          });
                        }}
                        style={{ height: '39px' }}
                        onKeyPress={onKeyPress}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example3cols2Input">
                        *Description in SAP
                      </Label>
                      <Input
                        value={value.sapDescription}
                        name="acadOrg"
                        disabled={isDisabled}
                        placeholder="Enter description in SAP"
                        type="text"
                        maxLength="50"
                        onChange={sData => {
                          let x = sData.target.value;
                          setValue({
                            ...value,
                            sapDescription: x,
                          });
                        }}
                        style={{ height: '39px' }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example3cols2Input">
                        *Business place code
                      </Label>
                      <Input
                        value={value.businessPlaceCode}
                        name="acadOrg"
                        disabled={isDisabled}
                        placeholder="Enter Business place code"
                        type="text"
                        maxLength="6"
                        onChange={sData => {
                          let x = sData.target.value;
                          setValue({
                            ...value,
                            businessPlaceCode: x,
                          });
                        }}
                        style={{ height: '39px' }}
                        onKeyPress={onKeyPress}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example3cols2Input">
                        *Deferment business area
                      </Label>
                      <Input
                        value={value.defermentBusinessArea}
                        name="acadOrg"
                        disabled={isDisabled}
                        placeholder="Enter deferment business area"
                        type="text"
                        maxLength="6"
                        onChange={sData => {
                          let x = sData.target.value;
                          setValue({
                            ...value,
                            defermentBusinessArea: x,
                          });
                        }}
                        style={{ height: '39px' }}
                        onKeyPress={onKeyPress}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="example2cols1Input">
                        *GSTIN Number
                      </Label>
                      <Input
                        value={value.gstInNumber}
                        name="atrributeDesc"
                        disabled={isDisabled}
                        placeholder="Enter GSTIN number"
                        type="text"
                        maxLength="15"
                        onChange={sData => {
                          let x = sData.target.value;
                          setValue({
                            ...value,
                            gstInNumber: x,
                          });
                        }}
                        style={{ height: '39px' }}
                        onKeyPress={onKeyPress}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </>

              {!isViewOnly ? (
                isNew ? (
                  <Row>
                    <Col md="12">
                      <Col>
                        {(action !== 'edit' && action !== 'view') || isUpdated ? (
                          //for first prod deployment removing 'Save & Next' from button text and this should be changed 
                          <CustomButton
                            className={'floatRight mt-4'}
                            content={(action === 'edit' || action === 'view') ? 'Update' : 'Save'}
                            permissionType={action === 'edit' ? 'U' :'C'}
                            // icon={true}
                            onClick={() => {
                              SaveButton();
                            }}
                            permissionSet={userPermissions}
                          />
                        ) : action === 'edit' ? (
                          <CustomButton
                            className={'floatRight mt-4'}
                            content={'Enable Form'}
                            permissionType={'U'}
                            // icon={true}
                            onClick={() => {
                              setIsDisabled(false);
                              setIsUpdated(true);
                            }}
                            permissionSet={userPermissions}
                          />
                        ) : null}
                      </Col>
                    </Col>
                  </Row>
                ) : formId && !isDisabled ? (
                  <Row>
                    <CustomButton
                      className={'floatRight mt-4'}
                      content={'Save'}
                      permissionType={'C'}
                      // icon={true}
                      onClick={() => {
                        SaveButton();
                      }}
                      permissionSet={userPermissions}
                    />
                  </Row>

                ) : (
                  setIsDisabled(false)
                )

              ) : null}
              </div>

        //     </CardBody>
        //   </Card>
        // </Container>
      )}
          <ConfirmationDialog message={"Changing SAP details will impact Profit and Cost Centres, Are you sure you want to continue ?"}/>

    </>
  ) : (
    <PermisionDenied />
  );
};
export default BusinessUnit_2;
