import React, { useState } from "react";
import { useGetDigitalModuleContext } from "../../contextApi/digitalContext";
import { useGetDispatchSchedular } from "../../customhooks";
import ALoader from "../../../../common/a-loader";
import AInput from "../../../../common/formFeilds/customInput/AInput";
import { Checkbox, FormControlLabel, TablePagination } from "@mui/material";
import ViewDispatchSchedule from "../../dlpStockIssuance/markEligible/ViewDispatchSchedule";
import BulkSelectionFooter from "../../selectMeterialAndStock/issueStock/BulkSelectionFooter";
import { primaryCheckboxStyles } from "../../selectMeterialAndStock/constant";
import MarkEligibleTableHelper from "../../selectMeterialAndStock/issueStock/MarkEligibleTableHelper";

const MarkEligibleTable = () => {
  const {
    loading,
    getApplications,
    isApplicationLoading,
    studentApplications,
    setApplications,
    issuedStockToStudent,
    page,
    setPage,
    totalRecords,
    filterForm,
    markEligibleHandler,
    globalFilter,
    selectedStudentsForMarkEligible,
    setSelectedStudentsForMarkEligible,
  } = useGetDigitalModuleContext();

  const [showOnlyEligible, setShowOnlyEligible] = useState(false);
  const [applicationId, setApplicationId] = useState("");
  const [dispatchSchedular, isFetchingData] = useGetDispatchSchedular(
    filterForm?.dispatchScheduleId?.value
  );
  const selectedStudentIds = Object.values(
    selectedStudentsForMarkEligible
  ).filter(Boolean);

  const bulkIssueStockHandler = (bulk = "", reason = "") => {
    markEligibleHandler(
      selectedStudentsForMarkEligible,
      bulk == "onHold",
      { reason },
      cleanUpCallback
    );
  };

  const singleMarkEligibleHandler = (student, isOnhold = false) => {
    markEligibleHandler(
      { [student?.applicationId]: true },
      isOnhold,
      "",
      cleanUpCallback
    );
  };

  const onPageChange = (newPage) => {
    setPage(newPage);
    getApplications(newPage, {
      ...filterForm,
      ...globalFilter,
      academicCareer: "DIGITAL",
    });
  };

  const cleanUpCallback = (response) => {
    setSelectedStudentsForMarkEligible({});
    setPage(0);
    getApplications(0, {
      ...filterForm,
      ...globalFilter,
      academicCareer: "DIGITAL",
    });
  };

  const applyFilterHandler = () => {
    setSelectedStudentsForMarkEligible({});
    setPage(0);
    getApplications(0, {
      ...filterForm,
      ...globalFilter,
      academicCareer: "DIGITAL",
      applicationId,
    });
  };
  const onKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      applyFilterHandler();
    }
  };

  const checkBoxHandler = (isChecked, student, isAll = false) => {
    // handlingAllCase

    const tempApplication = [...studentApplications];
    if (isAll) {
      if (isChecked) {
        const tempIds = {};
        tempApplication.forEach((item) => {
          if (item?.isDlpMarkStockIssuanceEligible) {
            tempIds[item?.applicationId] = isChecked;
          }
        });
        setSelectedStudentsForMarkEligible(tempIds);
      } else {
        setSelectedStudentsForMarkEligible({});
      }
      return;
    }
    // if (!student?.isEligibleForStockIssuance) return;

    const tempIds = { ...selectedStudentsForMarkEligible };
    tempIds[student?.applicationId] = isChecked;
    setSelectedStudentsForMarkEligible(tempIds);
  };

  const deSelectAllHandler = () => {
    checkBoxHandler(false, {}, true);
  };

  return (
    <>
      <div className="d-flex flex-column">
        {(isApplicationLoading || loading || isFetchingData) && (
          <ALoader position="fixed" />
        )}
        <div className="d-flex align-items-center justify-content-end mb-2">
          <div className="heading-4 color-black-60  mr-3">
            <AInput
              placeholder="Search by application ID"
              style={{ minWidth: "250px" }}
              rightSearchIcon
              crossIcon
              value={applicationId}
              handler={(value) => {
                setApplicationId(value);
              }}
              onKeyDown={(e) => onKeyDown(e)}
              crossHandler={() => {
                cleanUpCallback();
                setApplicationId("");
              }}
              searchIconClickHandler={() => applyFilterHandler()}
            />
          </div>
          <FormControlLabel
            disableTypography
            sx={{ marginBottom: "0px", ...primaryCheckboxStyles }}
            control={
              <Checkbox
                onChange={(e) => {
                  setShowOnlyEligible(e?.target?.checked);
                }}
                checked={showOnlyEligible}
                disabled={selectedStudentIds?.length > 1}
              />
            }
            label={
              <div className="semi-bold">Show only eligible student(s)</div>
            }
          />
          <ViewDispatchSchedule
            filterForm={filterForm}
            dispatchSchedular={dispatchSchedular}
            academicCareer="ICON"
          />
        </div>
        <>
          <div style={{ overflowX: "auto" }}>
            <div style={{ minWidth: "100%", width: "max-content" }}>
              <MarkEligibleTableHelper
                students={
                  showOnlyEligible
                    ? studentApplications.filter(
                        (student) => student.isDlpMarkStockIssuanceEligible
                      )
                    : studentApplications
                }
                {...{
                  actionBtnHandler: singleMarkEligibleHandler,
                  checkBoxHandler,
                  selectedStudentsForAction: selectedStudentsForMarkEligible,
                  selectedStudentIds,
                  dispatchSchedular,
                  issuedStockToStudent,
                }}
                setApplications={setApplications}
              />
            </div>
          </div>
          <TablePagination
            component={"div"}
            page={page}
            onPageChange={(e, newPage) => onPageChange(newPage)}
            count={totalRecords}
            rowsPerPage={50}
            rowsPerPageOptions={[50]}
            sx={{
              // position: 'sticky', left: 0, right: 0,
              borderTop: "1px solid rgba(224, 224, 224, 1)",
            }}
          />
        </>
      </div>

      {selectedStudentIds.length > 1 && (
        <BulkSelectionFooter
          selectionModel={selectedStudentIds}
          setSelectionModel={setSelectedStudentsForMarkEligible}
          bulkIssueStockHandler={bulkIssueStockHandler}
          deSelectAllHandler={deSelectAllHandler}
          btnText="Mark eligible to issue stock"
          isOnHold={true}
          selectedStudentsForMarkEligible={selectedStudentsForMarkEligible}
          students={
            showOnlyEligible
              ? studentApplications.filter(
                  (student) => student.isDlpMarkStockIssuanceEligible
                )
              : studentApplications
          }
        />
      )}
    </>
  );
};

export default MarkEligibleTable;
