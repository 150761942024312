import React, { useEffect, useState , useContext} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { endpoint } from "../../common/constant";
import { useRegionAndBusinessArea } from "../../common/utils/customHooks";
import ALoader from "../../common/a-loader";
import { getStudentList, loadDropdowns, searchStudentClear, startLoader, stopLoader } from "../../../../../redux/otherCharges/actions";
import { dropdownMapping, filterFormModalOtherCharge, getDataSetMap } from "../studentCharges/common";
import StudentList from "./studentList";
import Filter from "../studentCharges/Filter";
import LeftSideFilter from "../studentCharges/LeftSideFilter";
import { OtherChargesContext } from "./otherChargesContext";
import { PAGE_STATE, FILTER__FORM_FIELDS_GLOBAL } from "../studentCharges/contextApi";
import { ReactComponent as BackIcon } from "assets/img/svg/BackButton.svg";
import { emptySearchFields } from "../studentCharges/studentListWrapper";


const OtherChargesList = () => {

  const { globalFilter, setGlobalFilter,setCurrentPageState } = useContext(OtherChargesContext);


  const dispatch = useDispatch();
  const otherCharges = useSelector((state) => state.otherCharges);
  const [appliedFilterCount, setAppliedFilterCount] = useState(0);
  const [currentPageNumber, setPageNumber] = useState(0);
  const [isLastPage, setIsLastPage]=useState(false);
  const [businessAreaMap, setBusinessAreaMap] = useState({ region: otherCharges?.dropdowns?.region ?? [], businessArea: globalFilter?.businessArea ? globalFilter?.businessArea : otherCharges?.dropdowns?.businessArea ?? [] })

  const setFilterForm = (f) => {
    setGlobalFilter({ ...f })
  }


  useEffect(() => {
    setAppliedFilterCount(isFilterApplied(globalFilter))
    return () => dispatch(searchStudentClear())
  }, []);


  const isFilterApplied = (filters) => {
    let count = 0;
    Object.keys(globalFilter).map(item => {
      if(!['businessArea','region','psid','searchField','paymentStatus', 'chargesStatus'].includes(item)){
        if (Array.isArray(filters[item])) {
          if (filters[item].length !== 0) {
            count++;
          }
        } else if (filters[item] !== '' && filters[item] !== null) {
          count++;
        }
      }
    })
    return count;
  }
  // const crossInputHandler = () => {
  //   setPageNumber(0);
  //   if(!isFilterApplied){
  //     setCurrentPageState(PAGE_STATE.SEARCH_STUDENT)
  //   }else{
  //   setFilterForm({ ...globalFilter, psid: '' });
  //   dispatch(getStudentList({ ...globalFilter, psid: '' }, 0));
  //   }
  // };

  const crossInputHandler =()=>{
    setPageNumber(0);
      setFilterForm({ ...globalFilter, ...emptySearchFields});
      dispatch(getStudentList({ ...globalFilter,  ...emptySearchFields}, 0));
  }

  const searchInputValueHandler = (name) => {
    setPageNumber(0);
    dispatch(getStudentList({ ...globalFilter}, 0));
    // setFilterForm({ ...filterForm, psid: name });
  };

  const applyFilterHandler = () => {
    setPageNumber(0);
    dispatch(getStudentList({ ...globalFilter, courseId: globalFilter.courseId.map(i => i.value), term: globalFilter.term.map(i => i.value), chargesStatus: globalFilter?.chargesStatus?.map(i => i.value) ?? [], paymentStatus: globalFilter?.paymentStatus?.map(i => i.value) ?? []  }, 0));
    setAppliedFilterCount(isFilterApplied(globalFilter))
  };
  const nextButtonHandler = (pageNumber) => {
    dispatch(getStudentList({ ...globalFilter, courseId: globalFilter.courseId.map(i => i.value), term: globalFilter.term.map(i => i.value), chargesStatus: globalFilter?.chargesStatus?.map(i => i.value) ?? [], paymentStatus: globalFilter?.paymentStatus?.map(i => i.value) ?? [] }, pageNumber, setPageNumber, setIsLastPage));
    // setPageNumber(pageNumber);
  };

  const searchHandler = (f) => {
    setPageNumber(0);
    dispatch(getStudentList({...f}));
  };

  const removeFilterHandler = () => {
    setFilterForm({
      ...FILTER__FORM_FIELDS_GLOBAL
    })
    setCurrentPageState(PAGE_STATE.SEARCH_STUDENT)
  };


  const inputHandler = (value, inputName) => {
    let currentData = { ...globalFilter };
    if (inputName === "businessAreaClear") {
      currentData = { ...globalFilter, businessArea: [] };
      currentData["region"] = value;
      setFilterForm(currentData);
    } else {
      currentData[inputName] = value;
      setFilterForm(currentData);
    }
  };

  return (
    <>
    <div className={`remove-container-top-space d-flex px-3 py-4 align-items-center`}>
        <BackIcon className="mr-2 large-text" onClick={()=>{setGlobalFilter(prev=>({...prev, searchField : 'psid', ...emptySearchFields})) ; setCurrentPageState(PAGE_STATE.SEARCH_STUDENT)}}/>
        <div className="heading-4 color-black-60 large-text">
          Student List
        </div>
      </div>

      <div
        style={{
          position: "relative",
          display: "flex",
          flexFlow: "column",
          height: "calc(100vh - 120px)",
        }}
      >


        {otherCharges.loading && <ALoader />}
        <Card className="mx-3 mb-2" style={{ flexGrow: 1 }}>
          <CardBody style={{ display: "flex", flexFlow: "column" }}>
            <div className="search-match-container">
              <LeftSideFilter
                filterForm={globalFilter}
                searchHandler={searchHandler}
                setFilterForm={setFilterForm}
                businessAreaMap={businessAreaMap}
                setBusinessAreaMap={setBusinessAreaMap}
              />
              <Filter
                filterForm={globalFilter}
                setNormalFilterForm={setFilterForm}
                inputHandler={inputHandler}
                applyFilterHandler={applyFilterHandler}
                setAppliedFilterCount={setAppliedFilterCount}
                appliedFilterCount={appliedFilterCount}
                removeFilterHandler={removeFilterHandler}
                crossInputHandler={crossInputHandler}
                courseIdDropdown={otherCharges?.dropdowns?.course ?? []}
                termDropdown={otherCharges?.dropdowns?.term ?? []}
                setFilterForm={setFilterForm}
                businessAreaDropdown={businessAreaMap}
                otherCharge={true}
                setPageNumber={setPageNumber}
                setGlobalFilter={setGlobalFilter}
                searchInputValueHandler={searchInputValueHandler}
              />
            </div>
            <StudentList
              rowsData={otherCharges.studentList || []}
              rowCount={otherCharges.rowCount || 0}
              nextButtonHandler={nextButtonHandler}
              currentPage={currentPageNumber}
              isLastPage={isLastPage}
              businessAreaMap={businessAreaMap}
              courseIdDropdown={otherCharges?.dropdowns?.course ?? []}
            />
          </CardBody>
        </Card>




      </div>
    </>
  );
};


export default OtherChargesList