import { endpoint } from "../../common/constant"
import { fetchAllPromisedData } from "../../common/utils/methods/commonMethods/utilityMethod"

export const GRID_PAGE_SIZE = 50;

export const DEALLOCATE_STUDENTS_TABLE_HEADERS = {
  PSID: "PSID",
  APPLICATION_NO: "Application No.",
  STUDENT_NAME: "Student Name",
  REGISTRATION_DATE: "Registration Date",
  TERM: "Term",
  COURSE: "Course",
  BUSINESS_AREA: "Business Area",
  PROGRAM_PLAN: "Program Plan",
}

export const getFilterPayload = (id) => {
  return {
    // studentName: "",
    // psid: "",
    // applicationId: "",
    courseId: [],
    businessArea: [],
    startDate: "",
    endDate: "",
    batchId: id,
    allocationStatus: 'ALLOCATE'
  }
}


const dropdownMapping = async (url, displayLabel, idLabel) => {
  const responseData = await fetchAllPromisedData(url, false);
  if(displayLabel==='courseIds') {
    const result = responseData?.map((o) => {
      return {label: o, value: o}
    })
    return result;
  }
  if (Array.isArray(responseData)) {
    const result = responseData?.map((o) => {
      return { label: o[displayLabel], value: o[idLabel] }
    })
    return result;
  }
  else return [];
}

export const fetchAllDropdowns = async () => {
  const p = await Promise.all([
    dropdownMapping(endpoint.businessArea.getAllActive, 'businessAreaDispValue', 'id'),
    dropdownMapping(endpoint.businessArea.getAll, 'businessAreaDispValue', 'id'),
    dropdownMapping(endpoint.courseCatelogMapping.getAllActiveCourseIds, 'courseIds')
  ])

  return {
    businessArea: {
      allActive: p[0],
      all: p[1],
    },
    courseId: {
      allActive:p[2],
    }
  }
}

const formInfo = {
  PRIMARY: {
    // psid: 'string',
    // applicationId: 'string',
    startDate: 'string',
    endDate: 'string'
  },
  SECONDARY: {
    // psid: 'string',
    // applicationId: 'string',
    businessArea: 'Array',
    courseId: 'Array',
    startDate: 'string',
    endDate: 'string'
  }
}

export const getFilterCount = (dataSet, batchType) => {
  let cnt = 0;
  for (let item in dataSet) {
    if (formInfo[batchType][item] === 'string') {
      if (dataSet[item]) cnt++;
    }
    if (formInfo[batchType][item] === 'Array') {
      if (dataSet[item].length) cnt++;
    }
  }
  return cnt;
}

export const getLabels = (dropdownArray, idArray, isMulti = false) => {
  let result = [];
  if (isMulti) {
    result = idArray && idArray.map((id) => dropdownArray?.find((el) => el.value == id)?.label)
  }
  else result = dropdownArray?.find((el) => el.value == idArray)?.label;
  return result;
}