import React, { useState } from 'react';
import Stepper from 'react-stepper-horizontal';
import { Row, Col, Container } from 'reactstrap';
import CompanyCodeAddForm from './companyCodeAddForm';
import CompanyCodeAddressInitiator from './companyCodeAddInitiator';

const CompanyCodeStepper = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [codeId, setCodeId] = useState('');

    const toNextStep = id => {
        setCodeId(id);
        setActiveStep(activeStep + 1);
    };
    const toPreviousStep = () => {
        setActiveStep(activeStep - 1);
    };

    let formToRender = null;
    switch (activeStep) {
        case 0:
            formToRender = <CompanyCodeAddForm nextStep={toNextStep} />;
            break;
        case 1:
            formToRender = <CompanyCodeAddressInitiator id={codeId} isNew={true} />;
            break;
        default:
            formToRender = null;
    }

    return (
        <Container fluid>
            <div>
                <Stepper steps={[{ title: 'Add Company Code' }, { title: 'Link Address' }]} activeStep={activeStep} />
            </div>
            <Row className={`mt-4`}>
                <Col md={10} className={`m-auto p-3`} style={{ backgroundColor: 'white' }}>
                    {formToRender}
                </Col>
            </Row>
        </Container>
    );
};

export default CompanyCodeStepper;
