import React from "react";
import { Row, Col } from "reactstrap";
import { Divider } from "@mui/material";
import { ListItem } from "./ListItem";
import { tabLabelStyle } from "./style";

// this will show list
const TestContentDataTempList = ({ list, ...props }) => {
  return (
    <div>
      {list && list.length > 0 && (
        <Row>
          <Col md={3} style={tabLabelStyle}>
            Chapter
          </Col>
          <Col md={4} style={tabLabelStyle}>
            Topic
          </Col>
          <Col md={4} style={tabLabelStyle}>
            Sub-topic
          </Col>
          <Col md={1} style={tabLabelStyle}>
            Actions
          </Col>
        </Row>
      )}
      <Divider />
      {list.map((item, index) => (
        <ListItem
          {...props}
          currentIndex={index}
          currentForm={item}
          key={index * 2}
          item={item}
          
        />
      ))}
    </div>
  );
};

export default TestContentDataTempList;
