import React, { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { RiArrowDownSLine } from 'react-icons/ri';
import ADataGrid from '../../../common/data-grid/ADataGrid';
import { getColumns, getPayload } from './constant';
import { useParams } from 'react-router';
import NoRowOverlay from '../../../masters/holidayMaster/NoRowOverlay';
import {
  gridStyles,
  gridComponents,
} from 'views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant.js';
import { fetchAllPostPromisedData } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../../common/constant';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx';
import ALoader from 'views/pages/manage/common/a-loader/index.jsx'

const AccordionDetails = ({ expanded, activeTab, academicCareer, year }) => {
  const { psid } = useParams();
  const columns = useMemo(() => getColumns(activeTab, academicCareer), [activeTab, academicCareer]);
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);

  async function fetchTransactions(page = 0) {
    setLoader(true);
    const res = await fetchAllPostPromisedData(
      `${endpoint.studentSearch.searchStockTransactions}?offset=${page}`,
      getPayload(psid, year, activeTab, academicCareer),
      'post'
    );
    if (res?.code === 200) {
      setRows(res?.data?.stockTransactionAudList || []);
      setRowCount(res?.data?.totalRecords);
    } else {
      setRows([]);
      setRowCount(0);
    }
    setLoader(false);
  }

  const nextButtonHandler = newPage => {
    setPage(newPage);
    fetchTransactions(newPage);
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  return (
    <div
      className={`position-relative ${styles['details']} ${expanded ? styles['expanded'] : ''}`}
    >
      <div className='heading-4 color-dark'>History</div>
      <div className={`${styles['grid-wrapper']}`}>
        {loader && <ALoader />}
        <ADataGrid
          removeWrapperContainer
          rows={rows}
          columns={columns}
          rowId={row => JSON.stringify(row)}
          maxHeight={'400px'}
          currentPage={page}
          rowCount={rowCount}
          nextButtonHandler={nextButtonHandler}
          components={{
            ...gridComponents,
            NoRowsOverlay: () => (
              <NoRowOverlay message={loader ? 'Loading...' : null} />
            ),
          }}
          sx={gridStyles}
          hidePagination={rowCount <= 50}
        />
      </div>
    </div>
  );
};

const TransactionAccordion = ({
  year,
  expanded,
  toggle = () => {},
  activeTab,
  academicCareer,
}) => {
  return (
    <div className={`${styles['accordion']}`}>
      <div
        className={`large-semi-bold ${styles['summary']} ${
          expanded ? styles['expanded'] : ''
        }`}
        onClick={toggle}
      >
        <div>FY {year}</div>
        <IconButtonWrapper>
          <RiArrowDownSLine size={'36px'} />
        </IconButtonWrapper>
      </div>
      {expanded && (
        <AccordionDetails
          expanded={expanded}
          activeTab={activeTab}
          academicCareer={academicCareer}
          year={year}
        />
      )}
    </div>
  );
};

export default TransactionAccordion;
