import React, { useContext, useState } from 'react'
import { ReactComponent as EditIcon } from '../../../../../../assets/img/svg/Edit2.svg'
import { ReactComponent as DeleteIcon } from '../../../../../../assets/img/svg/Delete.svg'
import AButton from 'views/pages/manage/common/form-fields-mui/AButton'
import DeleteDialog from './DeleteDialog';
import { RolePermissions } from 'appContext';
import { PermissionContext } from 'appContext';
import { pages } from '../../../common/constant';
import { checkPermission } from '../../../common/utils/methods/commonMethods/utilityMethod';

const ActionsCellComponent = ({ params, handleEditClick, handleDeleteClick, handleSaveClick, deleteConfigHandler, rowForm }) => {
  const userPermissions = RolePermissions(useContext(PermissionContext), pages['exAakashianConfig']['id']);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    params.api.getRowMode(params?.id) === 'view' ? (
      <>
        <div className='d-flex align-items-center'>
          {checkPermission(userPermissions, 'U') && <EditIcon onClick={() => handleEditClick(params)} />}
          {checkPermission(userPermissions, 'D') && <DeleteIcon className='ml-3' onClick={() => setIsDialogOpen(true)} />}
        </div>
        <DeleteDialog 
          open={isDialogOpen}
          setOpen={setIsDialogOpen}
          onAgree={() => deleteConfigHandler(params?.id)}
        />
      </>
    ) : (
      <div className='d-flex align-items-center'>
        <AButton
          variant='primary_filled'
          updatedStyle={{ height: '28px', fontWeight: 600, fontSize: '12px', lineHeight: '16px', borderRadius: '4px' }}
          onClick={() => handleSaveClick(params)}
          disabled={!rowForm?.academicCareerId || !rowForm?.termId || !rowForm?.courseCategoryId?.length || !rowForm?.minimumCourseValue || !rowForm?.payableAmount || !rowForm?.programStatus?.length}
        >
          Save
        </AButton>
        <DeleteIcon className='ml-3' onClick={() => handleDeleteClick(params)} />
      </div>
    )
  )
}

export default ActionsCellComponent