import React, { useState, useEffect } from 'react';
import {
  ControlPaymodeFunctionHeader,
  CONTROLPAYMODEFUNCTIONTHROW,
} from '../../../common/commonComponents/masterRow/masterRow';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Table,
  Input,
  Label,
} from 'reactstrap';
import Select2 from 'react-select2-wrapper';
import { GetPagination } from '../../../common/pagination';
import Loader from 'react-loader-spinner';
import CustomLoader from '../../../common/commonComponents/Loader/loader';
import moment from 'moment';

import {
  getAPI,
  masterServiceBaseUrl,
  successToast,
  failureToast,
  putpost,
  putpost2,
} from 'services/http';

var _ = require('lodash');

const PaymodeControl = (props) => {
  const {
    getDisplayValue,
    getPaymentScheduleData,
    action,
    PaymentScheduleId,
    paymentMethod,
    paymodeControl,
    setPaymodeControl,
  } = props;
  const [isSaveVisible, setIsSaveVisible] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [state, setState] = useState([]);
  const [dataChange, setDataChange] = useState(false);
  const [paymentMethodDescription, setPaymentMethodDescription] = useState('');
  const [tableDataKey, settableDataKey] = useState('tableDataKey');

  const [pagination, setPagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const pageSize = 10;

  // console.log('PaymentScheduleId__', PaymentScheduleId);

  useEffect(() => {
    fetchClasses(
      masterServiceBaseUrl + `/payment/control/paymentSchedule/${PaymentScheduleId}`
    );
  }, [PaymentScheduleId]);

  const fetchClasses = async (url) => {
    setApiLoader(true);
    getAPI(
      url,
      (data) => {
        // console.log(data['data']);
        setState(data['data']);
        let n = pagination;
        if (data['data'].length > pageSize) {
          n['nextPage'] = n.currentPage + 1;
        }
        if (n.currentPage > 1) {
          n.previousPage = n.currentPage - 1;
        }
        n['totalPage'] = Math.floor(data['data'].length / pageSize);
        if (data['data'].length % pageSize != 0) {
          n['totalPage'] += 1;
        }
        setPagination(n);
        setApiLoader(false);
        // console.log(n);
        setPaymodeControl(
          data['data'].slice(
            pagination.currentPage * pageSize - pageSize,
            pagination.currentPage * pageSize
          ) || []
        );
      },
      (data) => {
        failureToast(data['message']);
        setApiLoader(false);
      }
    );
  };

  const nextPage = () => {
    setPaymodeControl(
      state.slice(
        (pagination.currentPage + 1) * pageSize - pageSize,
        (pagination.currentPage + 1) * pageSize
      )
    );
    // console.log(pagination);
    let n = pagination;
    n['currentPage'] = n['currentPage'] + 1;
    n['previousPage'] = n.currentPage;
    setPagination(n);
  };
  const firstPage = () => {
    setPaymodeControl(state.slice(1 * pageSize - pageSize, 1 * pageSize));
    // console.log(pagination);
    let n = pagination;
    n['currentPage'] = 1;
    n['previousPage'] = null;
    setPagination(n);
  };
  const lastPage = () => {
    setPaymodeControl(
      state.slice(
        pagination.totalPage * pageSize - pageSize,
        pagination.totalPage * pageSize
      )
    );
    // console.log(pagination);
    let n = pagination;
    n['currentPage'] = n.totalPage;
    n['previousPage'] = n.totalPage - 1;
    setPagination(n);
  };
  const previousPage = () => {
    if (pagination.currentPage != 1) {
      setPaymodeControl(
        state.slice(
          (pagination.currentPage - 1) * pageSize - pageSize,
          (pagination.currentPage - 1) * pageSize
        )
      );
      // console.log(pagination);
      let n = pagination;
      n['currentPage'] = n['currentPage'] - 1;
      if (n['currentPage'] == 1) {
        n['previousPage'] = null;
      } else {
        n['previousPage'] = n.currentPage;
      }
      setPagination(n);
    }
  };

  const addNewClass = () => {
    setPaymodeControl([
      {
        editMode: true,
        __status: 'new',
        paymentScheduleId: PaymentScheduleId,
        paymentMethodId: null,
        allowReceipting: true,
        updatedOn: null,
        status: 'ACTIVE',
      },
      ...paymodeControl,
    ]);
  };

  // console.log('paymodeControl__ClassArray', paymodeControl);
  const saveAll = (cb) => {
    try {
      var _tempArray = paymodeControl;
      var isValid = true;
      _tempArray.forEach((el, index) => {
        if (el['editMode']) {
          if (el['paymentScheduleId'] === null) {
            isValid = false;
            failureToast('Payment Schedule ID not found');
            return;
          } else if (el['paymentMethodId'] === null) {
            isValid = false;
            failureToast('Please Select Payment Method');
            return;
          }

          if (!isValid) {
            return;
          }

          if (_tempArray[index]['__status'] == 'new') {
            setApiLoader(true);
            putpost(
              masterServiceBaseUrl + '/payment/control',
              (data) => {
                successToast(data['message']);
                settableDataKey(new Date().getMilliseconds());
                getPaymentScheduleData();
                fetchClasses(
                  masterServiceBaseUrl +
                    `/payment/control/paymentSchedule/${PaymentScheduleId}`
                );
                setIsSaveVisible(false);
                setPaymentMethodDescription('');
                setApiLoader(false);
              },
              (data) => {
                failureToast(data['message']);
                setApiLoader(false);
              },
              _tempArray[index],
              'post'
            );

            // console.log('paymodeControl[ index ]', paymodeControl[index]);
          } else {
            setApiLoader(true);
            putpost(
              masterServiceBaseUrl + '/payment/control/' + _tempArray[index].id,
              (data) => {
                successToast(data['message']);
                getPaymentScheduleData();
                fetchClasses(
                  masterServiceBaseUrl +
                    `/payment/control/paymentSchedule/${PaymentScheduleId}`
                );
                setIsSaveVisible(false);
                setPaymentMethodDescription('');
                delete _tempArray[index]['editMode'];
                setPaymodeControl(_tempArray);
                settableDataKey(new Date().getMilliseconds());
                setApiLoader(false);
              },
              (data) => {
                failureToast(data['message']);
                setApiLoader(false);
              },
              _tempArray[index],
              'put'
            );
          }
        }
      });
    } catch (e) {
      console.log('Error', e);
    }
  };

  const newRow = () => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        addNewClass();
        setIsSaveVisible(true);
      };
      var _tempArray = paymodeControl;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      // console.log(_tempArray);
      setPaymodeControl(_tempArray);
      setIsSaveVisible(false);
      cb();
    }
  };

  const updateRecord = (index, value, property) => {
    try {
      let _tempArray = paymodeControl;
      _tempArray[index][property] = value;
      if (property === 'paymentMethodId') {
        setPaymentMethodDescription(
          getDisplayValue(value, 'paymentMethodDescription')
        );
      }
      setPaymodeControl(_tempArray);
    } catch (e) {
      console.log('Error', e);
    }
  };

  const editRecord = (index) => {
    try {
      if (isSaveVisible) {
        failureToast('Please Delete or Save current changes');
      } else {
        let cb = () => {
          var _tempArray = paymodeControl;
          _tempArray[index]['editMode'] = true;
          setPaymentMethodDescription(
            getDisplayValue(
              _tempArray[index]['paymentMethodId'],
              'paymentMethodDescription'
            )
          );
          setPaymodeControl(_tempArray);
          setIsSaveVisible(true);
        };
        var _tempArray = paymodeControl;
        _tempArray = _.sortBy(_tempArray, [
          function (o) {
            return o.value;
          },
        ]);
        cb();
      }
    } catch (e) {
      console.log('Error : ', e);
    }
  };

  const deleteRecord = (index) => {
    try {
      if (paymodeControl[index]['__status'] == 'new') {
        let n = paymodeControl;
        n.splice(index, 1);
        setPaymodeControl(n);
        setIsSaveVisible(false);
        settableDataKey(new Date().getMilliseconds());
      } else {
        if (
          window.confirm(
            'Are you sure you want to delete this Paymode Control Details ?'
          )
        ) {
          putpost2(
            masterServiceBaseUrl + '/payment/control/' + paymodeControl[index].id,
            (data) => {
              successToast(data['message']);
              getPaymentScheduleData();
              if (paymodeControl.length == 1) {
                previousPage();
                fetchClasses(
                  masterServiceBaseUrl +
                    `/payment/control/paymentSchedule/${PaymentScheduleId}`
                );
              } else {
                var _tempArray = paymodeControl;
                if (_tempArray[index]['editMode']) {
                  setIsSaveVisible(false);
                }
                _tempArray.splice(index, 1);
                setPaymodeControl(_tempArray);
              }
              settableDataKey(new Date().getMilliseconds());
            },
            (data) => {
              failureToast(data['message']);
            },
            'Delete'
          );
        }
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  const closeRecord = (index) => {
    try {
      let n = paymodeControl;
      if (n[index]['__status'] == 'new') {
        n.splice(index, 1);
      } else {
        // n[index]['editMode']=false
        fetchClasses(
          masterServiceBaseUrl + `/payment/control/paymentSchedule/${PaymentScheduleId}`
        );
      }
      setPaymodeControl(n);
      setPaymentMethodDescription('');
      setIsSaveVisible(false);
    } catch (e) {
      console.log('Error', e);
    }
  };

  return (
    <Container className="mt-3" fluid>
      <Row>
        <Col className="mb-3">
          {!(action === 'view') ? (
            <Button
              disabled={isSaveVisible}
              color="info"
              size="sm"
              type="button"
              className={
                'floatRight mx-1' + (isSaveVisible ? ' btn-dark' : null)
              }
              onClick={() => {
                newRow();
              }}
            >
              Add Paymode
            </Button>
          ) : null}
        </Col>
      </Row>
      {apiLoader ? (
        <div className="mx-auto text-center" style={{ height: '100px' }}>
          <CustomLoader
            apiLoader={apiLoader}
            loaderHeight={'200px'}
            loaderWidth={'100% '}
          />
        </div>
      ) : (
        <div id="questionTable" className="table-responsive">
          <Table className="align-items-center table-flush">
            {paymodeControl.length > 0 ? (
              <ControlPaymodeFunctionHeader
                action={action}
                type={'paymentMethod'}
              />
            ) : (
              ''
            )}
            <tbody className="list" key={tableDataKey}>
              {paymodeControl
                ? paymodeControl.map((item, index) =>
                    !item['editMode'] ? (
                      <CONTROLPAYMODEFUNCTIONTHROW
                        key={index + '_class'}
                        type={'paymentMethod'}
                        action={action}
                        deleteRecord={deleteRecord}
                        getDisplayValue={getDisplayValue}
                        item={item}
                        editRecord={editRecord}
                        index={index}
                        closeRecord={closeRecord}
                      />
                    ) : (
                      <tr key={index + '-class'}>
                        <td className="text-center p-2 mx-1">
                          <Select2
                            data={paymentMethod}
                            className="tdSelect"
                            options={{ placeholder: 'Select...' }}
                            value={item.paymentMethodId}
                            onChange={(e) => {
                              let n = e.target.value;
                              updateRecord(index, n, 'paymentMethodId');
                            }}
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Label className="customLabel">
                            {paymentMethodDescription}
                          </Label>
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            checked={
                              item.allowReceipting === true ? true : false
                            }
                            type="checkBox"
                            // defaultChecked={item.allowReceipting}
                            onChange={(e) => {
                              settableDataKey(new Date().getMilliseconds());
                              let n = true;
                              if (!e.target.checked) {
                                n = false;
                              }
                              updateRecord(index, n, 'allowReceipting');
                              setDataChange(!dataChange);
                            }}
                            style={{
                              width: '30px',
                              height: '30px',
                              marginLeft: '108px',
                            }}
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            disabled={true}
                            defaultValue={
                              item['__status'] == 'new'
                                ? null
                                : moment(item['updatedOn']).format('DD-MM-YYYY')
                            }
                            placeholder="Example:01-01-2020"
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Label className="custom-toggle mx-auto ml-2">
                            <Input
                              checked={item.status == 'ACTIVE' ? true : false}
                              type="checkbox"
                              onChange={(e) => {
                                settableDataKey(new Date().getMilliseconds());
                                let n = 'ACTIVE';
                                if (!e.target.checked) {
                                  n = 'INACTIVE';
                                }
                                updateRecord(index, n, 'status');
                                setDataChange(!dataChange);
                              }}
                            />
                            <span
                              style={{ width: '72px' }}
                              className="custom-toggle-slider rounded-circle activeToggle"
                              data-label-off="Inactive"
                              data-label-on="Active"
                            />
                          </Label>
                        </td>
                        <td className="text-center p-2 mx-1">
                          {isSaveVisible ? (
                            <>
                              <Button
                                size="sm"
                                color="info"
                                onClick={() => saveAll()}
                              >
                                Save
                              </Button>
                              <Button
                                size="sm"
                                color="danger"
                                onClick={() => closeRecord(index)}
                              >
                                <i className="fas fa-times" />
                              </Button>
                            </>
                          ) : (
                            <Button
                              color="info"
                              size="sm"
                              type="button"
                              onClick={() => {
                                deleteRecord(index);
                              }}
                            >
                              <i className="fas fa-trash" />
                            </Button>
                          )}
                        </td>
                      </tr>
                    )
                  )
                : null}
            </tbody>
          </Table>

          {pagination.totalPage > 1 ? (
            <GetPagination
              fetchClasses={fetchClasses}
              pagination={pagination}
              previousPage={previousPage}
              firstPage={firstPage}
              lastPage={lastPage}
              nextPage={nextPage}
              setpagination={setPagination}
            />
          ) : null}
        </div>
      )}
    </Container>
  );
};

export default PaymodeControl;
