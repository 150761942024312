import React from 'react'
import ADataGrid from 'views/pages/manage/common/data-grid/ADataGrid'
import { AGridColDef } from 'views/pages/manage/common/data-grid/ADataGridColDef'
import ActionsCellComponent from './ActionsCellComponent'
import BusinessAreaCellComponent from './BusinessAreaCellComponent'
import StatusCellComponent from './StatusCellComponent'
import NoRowOverlay from 'views/pages/manage/academicPlanAndTest/searchAcademicPlan/NoRowOverlay'

const DaysPlanList = ({ 
  page,
  onPageChange,
  rowData,
  rowCount,
  togglePlanStatusHandler,
  deletePlanHandler,
}) => {

  const daysPlanListHeader = [
    new AGridColDef('daysPlanName', 'Days plan name').setMinWidth(200),
    new AGridColDef('businessAreas', 'Business area').setMinWidth(250).renderCellComponent(params => <BusinessAreaCellComponent params={params} />),
    new AGridColDef('status', 'Status').setMinWidth(150).setFlex(0.5)
      .renderCellComponent(params => <StatusCellComponent params={params} togglePlanStatusHandler={togglePlanStatusHandler} />),
    new AGridColDef('Actions', 'Actions', false).setMinWidth(150).columnAlign('right').setFlex(0.5)
      .renderCellComponent(params => <ActionsCellComponent params={params} deletePlanHandler={deletePlanHandler} />),
  ]

  return (
    <>
      <ADataGrid 
        rows={rowData || []}
        columns={daysPlanListHeader}
        rowId={(row) => row.id}
        loading={false}
        rowCount={rowCount || 0}
        currentPage={page}
        nextButtonHandler={onPageChange}
        emptyRowPlaceholder={() => <NoRowOverlay />}
      />
    </>
  )
}

export default DaysPlanList