import React, {useEffect,Fragment} from 'react';
import {
    Card,
    CardHeader,
    Input,
    Container,
    Button,
    Table,
    Row,
} from 'reactstrap';
import {useState} from 'react';
// import "views/pages/manage/addressType/node_modules/react-sliding-pane/dist/react-sliding-pane.css";
import {failureToast} from '../../common/utils/methods/toasterFunctions/function';
import {MasterHeaderAuto} from '../../common/commonComponents/masterHeader/masterHeader';
import {
    fetchDetails,
    getDispValForReactSelectByOneID,
    NewRow
} from '../../common/utils/methods/commonMethods/utilityMethod'
import {
    getAPI,
    masterServiceBaseUrl,
    successToast,
    putpost,
    putpost2,
} from 'services/http';
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import moment from 'moment';
import IsCoreMaster from "../../common/commonComponents/coreMaster";
import CommonInput from "../../common/formFeilds/input/commonInput";

var _ = require('lodash');

const CalculatorTypeTable = (props) => {
    const {
        serverData,
        setSearch,
        resetSearch,
        isSaveVisible,
        setisSaveVisible,
        action,
        pagination,
        setApiloader
    } = props;
    const [tableDataKey, settableDataKey] = useState('tableDataKey');
    const [updatedKey, setUpdateKey] = useState(new Date());
    const [searchStr, setSearchStr] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [regionDropDownData, setRegionDropDownData] = useState([]);
    const [desc, setDesc] = useState('');
    const [dispValue, setDispValue] = useState('');

    const fetchRegionData = (url) => {
        getAPI(
            url,
            (data) => {
                let tempArray = [];
                if (data.data.length > 0) {
                    data.data.map((item) => {
                        tempArray.push({
                            label: item.regionDispValue,
                            value: item.id
                        })
                    })
                    setRegionDropDownData(tempArray);
                }
            },
            (data) => {
                failureToast(data['message']);
            }
        );
    }
    const [regionMasterData,setRegionMasterData] = useState([])
    useEffect(() => {
        fetchRegionData(masterServiceBaseUrl + '/region/getAllActiveRegion')
        fetchDetails(masterServiceBaseUrl + `/region/getAllRegion`, setRegionMasterData, 'region', 'reactSelect');
        // fetchAllData(getApiUrl,setState)
    }, [])
    const addNewClass = () => {
        setDispValue('');
        setDesc('');
        props.setclassArray([
            {
                editMode: true,
                __status: '__new',
                status: 'ACTIVE',
                calculatorTypeDesc: null,
                calculatorTypeDispValue: null,
                effectiveDate: moment().format('DD-MM-YYYY')
            },
            ...props.classArray,
        ]);
    };

    const headerList = [
        {name: 'S. No.'},
        {name: 'Effective Date'},
        {name: 'Description',isRequired:true},
        {name: 'Display Value',isRequired:true},
        {name: 'Status'},
        {name: 'Action'},
    ];
    const listToRender = [
        'calculatorTypeKey',
        'effectiveDate',
        'calculatorTypeDesc',
        'calculatorTypeDispValue',
        'status'
    ];

    const saveAll = (cb) => {
        var _tempArray = props.classArray;
        var isValid = true;
        _tempArray.forEach((el, index) => {
            if (el['editMode']) {
                if (!el['calculatorTypeDesc'] || !el['calculatorTypeDesc'].trim()) {
                    isValid = false;
                    failureToast('Please Enter Description');
                    return;
                } else if (
                    !el['calculatorTypeDispValue'] ||
                    !el['calculatorTypeDispValue'].trim()
                ) {
                    isValid = false;
                    failureToast('Please Enter Display Value');
                    return;
                }
                if (!isValid) {
                    return;
                }
                if (_tempArray[index]['__status'] == '__new') {
                    let rowData = _tempArray[index];
                    let tempObject = {
                        calculatorTypeDesc: rowData.calculatorTypeDesc.trim(),
                        calculatorTypeDispValue: rowData.calculatorTypeDispValue.trim(),
                        status: rowData.status,
                    }
                    putpost(
                        masterServiceBaseUrl + '/calculatorType/createCalculatorType',
                        (data) => {
                            successToast(data['message']);
                            setisSaveVisible(false);
                            setUpdateKey(new Date());
                            props.fetchClasses(`${masterServiceBaseUrl}/calculatorType/getAllCalculatorType`);
                        },
                        (data) => {
                            failureToast(data['message']);
                        },
                        tempObject,
                        'post'
                    );
                } else {
                    let rowData = _tempArray[index];
                    let tempObject = {
                        id: rowData.id,
                        calculatorTypeDesc: rowData.calculatorTypeDesc.trim(),
                        calculatorTypeDispValue: rowData.calculatorTypeDispValue.trim(),
                        status: rowData.status,
                        calculatorTypeKey : rowData.calculatorTypeKey,
                    }
                    putpost(
                        masterServiceBaseUrl + '/calculatorType/updateCalculatorType/' + _tempArray[index].id,
                        (data) => {
                            successToast(data['message']);
                            setisSaveVisible(false);
                            // delete _tempArray[index]['editMode']
                            // props.setclassArray(_tempArray)
                            props.fetchClasses(`${masterServiceBaseUrl}/calculatorType/getAllCalculatorType`);
                            settableDataKey(new Date().getMilliseconds());
                        },
                        (data) => {
                            failureToast(data['message']);
                        },
                        tempObject,
                        'put'
                    );
                }
            } else {
            }
        });
    };
    const newRow = () => {
        if (isSaveVisible) {
            failureToast('Please Delete or Save current changes');
        } else {
            let cb = () => {
                addNewClass();
                setisSaveVisible(true);
                // settableDataKey(new Date().getMilliseconds())
            };
            var _tempArray = props.classArray;
            _tempArray = _.sortBy(_tempArray, [
                function (o) {
                    return o.value;
                },
            ]);
            props.setclassArray(_tempArray);
            setisSaveVisible(false);
            cb();
        }
    };
    const editRow = (index) => {
        if (isSaveVisible) {
            failureToast('Please Delete or Save current changes');
        } else {
            let cb = () => {
                var _tempArray = props.classArray;

                setDesc(_tempArray[index]['calculatorTypeDesc']);
                setDispValue(_tempArray[index]['calculatorTypeDispValue']);
                _tempArray[index]['editMode'] = true;
                props.setclassArray(_tempArray);
                setisSaveVisible(true);
            };
            var _tempArray = props.classArray;
            _tempArray = _.sortBy(_tempArray, [
                function (o) {
                    return o.value;
                },
            ]);
            cb();
        }
    };
    const deleteRow = (index) => {
        if (props.classArray[index]['__status'] == '__new') {
            let n = props.classArray;
            n.splice(index, 1);
            props.setclassArray(n);
            setisSaveVisible(false);
            settableDataKey(new Date().getMilliseconds());
        } else {
            if (
                window.confirm('Are you sure you want to delete this person type?')
            ) {
                putpost2(
                    masterServiceBaseUrl +
                    '/calculatorType/deleteCalculatorType/' +
                    props.classArray[index].id,
                    (data) => {
                        successToast(data['message']);
                        if (props.classArray.length == 1) {
                            // props.previousPage();
                            props.fetchClasses(`${masterServiceBaseUrl}/calculatorType/getAllCalculatorType`);
                        } else {
                            var _tempArray = props.classArray;
                            if (_tempArray[index]['editMode']) {
                                setisSaveVisible(false);
                            }
                            _tempArray.splice(index, 1);
                            props.setclassArray(_tempArray);
                        }
                        settableDataKey(new Date().getMilliseconds());
                        props.fetchClasses(`${masterServiceBaseUrl}/calculatorType/getAllCalculatorType`);
                    },
                    (data) => {
                        failureToast(data['message']);
                    },
                    'PUT'
                );
            }
        }
    };

    // const updateKey = (index, value, key) => {
    //     var _tempArray = props.classArray;
    //     _tempArray[index][key] = value;
    //     props.setclassArray(_tempArray);
    //     setUpdateKey(new Date());
    // };

    const updateKey = (index,value,key)=>{
        var _tempArray = props.classArray
        _tempArray[index][key] = value
        props.setclassArray(_tempArray)
      }

    const filterData = (str) => {
        const newData =
            serverData &&
            serverData.filter((obj) =>
                Object.values(obj).some(
                    (val) =>
                        val && val.toString().toLowerCase().includes(str.toLowerCase())
                )
            );
        setSearch(newData);
    };

    useEffect(() => {
        if (!searchStr || !searchStr.trim()) {
            resetSearch();
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
            filterData(searchStr);
        }
    }, [searchStr]);
    const [regionValue, setRegionValue] = useState([])
    return (
        <>
            <Container className="mt-3" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                <h3 className="mb-0 floatLeft">
                                    Calculator Type
                                </h3>
                                <IsCoreMaster master={'calculatorType'} masterDisplayValue={'Calculator Type'}  setApiLoader={setApiloader}/>

                                <Button
                                    disabled={isSaveVisible || isDisabled}
                                    color="info"
                                    size="sm"
                                    type="button"
                                    className={
                                        'floatRight mx-1 ' +
                                        (isSaveVisible || isDisabled ? ' btn-dark' : null)
                                    }
                                    onClick={() => {
                                        newRow();
                                    }}
                                    data-testid="addNewPersonType"
                                >
                                    New Calculator Type
                                </Button>

                            </CardHeader>
                            <div id="questionTable" className="table-responsive">
                                <Table className="align-items-center table-flush tableLayout">
                                    <MasterHeaderAuto headerList={headerList}/>
                                    <tbody className="list" key={tableDataKey}>
                                    {props.classArray
                                        ? props.classArray.map((el, index) =>
                                            !el['editMode'] ? (
                                                //
                                                <Fragment key={index+'calculatorTypeRow'}>

                                                    <NewRow data={el} listToBeRendered={listToRender} index={index}
                                                            currentPage={pagination.currentPage}
                                                            regionMasterData={regionMasterData}
                                                    >
                                                        <td className="text-center ">
                                                            <Button
                                                                color="info"
                                                                size="sm"
                                                                type="button"
                                                                disabled={props.isViewOnly}
                                                                onClick={() => editRow(index)}
                                                            >
                                                                <i className="fas fa-edit"/>
                                                            </Button>
                                                            {/*<Button*/}
                                                            {/*    color="info"*/}
                                                            {/*    size="sm"*/}
                                                            {/*    type="button"*/}
                                                            {/*    disabled={props.isViewOnly}*/}
                                                            {/*    onClick={() => deleteRow(index)}*/}
                                                            {/*    // data-testid="deletebtn"*/}
                                                            {/*>*/}
                                                            {/*    <i className="fas fa-trash"/>*/}
                                                            {/*</Button>*/}
                                                        </td>
                                                    </NewRow>
                                                </Fragment>
                                            ) : (
                                                <tr key={index + '-class'}>
                                                    <td className="text-center p-2 mx-1">

                                                        {
                                                            el.id ?
                                                            el['calculatorTypeKey']
                                                                :
                                                            <Input
                                                            disabled={true}
                                                            defaultValue={el['calculatorTypeKey']}
                                                            data-testid='calculatorTypeKey'
                                                            placeholder="S. No"
                                                            className="tdInput"
                                                        />}
                                                    </td>
                                                    <td className="text-center p-2 mx-1">
                                                        {el.id ?
                                                            moment(el['effectiveDate']).format('DD-MM-YYYY')
                                                            :
                                                            <Input
                                                                maxLength="50"
                                                                disabled={true}
                                                                defaultValue={el['effectiveDate']}
                                                                data-testid='calculatorTypeEffectiveDate'
                                                                placeholder="Effective Date"
                                                                className="tdInput"
                                                            />}
                                                    </td>
                                                    <td className="text-center p-2 mx-1">

                                                        <CommonInput
                                                            type={'text'}
                                                            maxLength={30}
                                                            restrictSpecialCharacter={true}
                                                            allowedSomeSpecialCharacters={true}
                                                            regEx={/[^0-9a-zA-Z /_-]/g}
                                                            forTable={true}
                                                            placeHolder={'Description value'}
                                                            defaultValue={desc}
                                                            onChange={(value) => {
                                                                setDesc(value);
                                                                updateKey(index, value, 'calculatorTypeDesc');
                                                            }}
                                                        />
                                                    </td>

                                                    <td className="text-center p-2 mx-1">
                                                        <CommonInput
                                                            type={'text'}
                                                            maxLength={30}
                                                            restrictSpecialCharacter={true}
                                                            allowedSomeSpecialCharacters={true}
                                                            regEx={/[^0-9a-zA-Z /_-]/g}
                                                            forTable={true}
                                                            placeHolder={'Display value'}
                                                            defaultValue={dispValue}
                                                            onChange={(value) => {
                                                                setDispValue(value);
                                                                updateKey(index, value, 'calculatorTypeDispValue');
                                                            }}
                                                        />
                                                    </td>

                                                    <td className="text-center p-2 mx-1">
                                                        <label className="custom-toggle mx-auto ml-2">
                                                            <input
                                                                checked={
                                                                    el.status == 'ACTIVE' ? true : false
                                                                }
                                                                type="checkbox"
                                                                id={el.id}
                                                                onChange={(e) => {
                                                                    settableDataKey(
                                                                        new Date().getMilliseconds()
                                                                    );
                                                                    let n = 'ACTIVE';
                                                                    if (!e.target.checked) {
                                                                        n = 'INACTIVE';
                                                                    }
                                                                    updateKey(index, n, 'status');
                                                                }}
                                                                data-testid='calculatorTypeStatus'
                                                            />
                                                            <span
                                                                style={{width: '72px'}}
                                                                className="custom-toggle-slider rounded-circle activeToggle"
                                                                data-label-off="Inactive"
                                                                data-label-on="Active"
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="text-center" data-testid="saveConcessionPersonType">
                                                        {isSaveVisible ? (
                                                            <>
                                                                {' '}
                                                                <Button
                                                                    color="info"
                                                                    size="sm"
                                                                    type="button"
                                                                    className="mx-1"
                                                                    onClick={() => {
                                                                        saveAll();
                                                                    }}
                                                                >
                                                                    Save
                                                                </Button>
                                                                <Button
                                                                    color="danger"
                                                                    size="sm"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        let n = props.classArray;
                                                                        if (n[index]['__status'] == '__new') {
                                                                            n.splice(index, 1);
                                                                        } else {
                                                                            // n[index]['editMode'] = false
                                                                            props.fetchClasses(
                                                                                `${masterServiceBaseUrl}/calculatorType/getAllCalculatorType`
                                                                            );
                                                                        }
                                                                        props.setclassArray(n);
                                                                        setisSaveVisible(false);
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <i className="fas fa-times"/>
                                                                </Button>{' '}
                                                            </>
                                                        ) : (
                                                            <Button
                                                                color="info"
                                                                size="sm"
                                                                type="button"
                                                                onClick={() => {
                                                                    deleteRow(index);
                                                                }}
                                                            >
                                                                {' '}
                                                                <i className="fas fa-trash"/>
                                                            </Button>
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        )
                                        : null}
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};
export default CalculatorTypeTable;
