import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { PermissionContext, RolePermissions } from 'appContext'
import { Card, CardHeader, Container, Row } from 'reactstrap'
import { Accordion, ConfirmationDialog } from '../common/commonComponents'
import { FormGenerator } from '../common/commonComponents/formGenerator'
import searchForm from './form.json'
import { constants, endpoint, pages } from '../common/constant'
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../common/utils/methods/commonMethods/utilityMethod'
import CustomButton from 'components/CustomButton'
import { failureToast, successToast } from '../common/utils/methods/toasterFunctions/function'
import CustomLoader from '../common/commonComponents/Loader/loader'
import Permission from './permission'


const CreateRole = (props) => {
    const { action, id } = props.match.params
    const history = useHistory();
    const permissions = useContext(PermissionContext);
    const userPermissions = RolePermissions(permissions, pages["userRolePage"]['id']);
    const [status] = useState({ fetching: false, data: constants.status })
    const [projectData, setProjectData] = useState({ fetching: false, data: [] })
    const [submitting, setSubmitting] = useState(false)
    const [previewOnly, setPreviewOnly] = useState(false)
    const [isFetching, setFetching] = useState(true)
    const [confirmationDialog, setConfirmationDialog] = useState(false)
    const [recordSaved, setRecordSaved] = useState(false);
    const [nextPage, setNextPage] = useState(false);
    const [roleData, setRoleData] = useState(null);
    const [savedPermission, setSavedPermission] = useState(null);
    const [anyDataChanged, setDataChanged] = useState(false);


    const [editHeader, setEditHeader] = useState(false)
    const [editPermission, setEditPermission] = useState(false)

    const [values, setValues] = useState({
        roleName: '',
        roleDescription: '',
        defaultDelegationDays: '',
        projectId: '',
        status: { label: 'ACTIVE', value: 'ACTIVE' }
    })

    const getProject = async () => {
        const project = await fetchAllPromisedData(endpoint.projectDetails.getAllActiveProject, false);
        if (Array.isArray(project)) {
            const tempData = project?.map(p => { return { label: p.projectDispValue, value: p.id } })
            setProjectData({ fetching: false, data: tempData })
        } else {
            setProjectData({ fetching: false, data: [] })
        }
    }

    useEffect(() => {
        getProject()
    }, [])

    const reset = () => {
        setValues({
            roleName: '',
            roleDescription: '',
            defaultDelegationDays: '',
            projectId: '',
            status: ''
        })
    }

    const getProjectLabel = (id) => {
        const filteredObj = projectData?.data?.filter(item => item.value == id)
        return filteredObj?.[0] ? filteredObj?.[0]['label'] : ''
    }


    const getPermissionData = async (id, projectId) => {
        const permisionData = await fetchAllPromisedData(`${endpoint.permission.gwtPermissionByRoleAndProjectId}/${id}/${projectId}`, true);
        if (permisionData?.code == 200) {
            setSavedPermission(permisionData?.data?.screenPermissionsDtoList)
            setFetching(false)
            setDataChanged(false)

        } else {
            setFetching(false)
        }
    }

    const getDataByRoleId = async () => {
        const roleData = await fetchAllPromisedData(`${endpoint.getRole.getRoleById}/${id}`, true);
        if (roleData?.code == 200 && roleData?.data) {
            setValues({
                roleName: roleData?.data?.roleName,
                roleDescription: roleData?.data?.roleDescription,
                defaultDelegationDays: roleData?.data?.defaultDelegationDays,
                projectId: { label: getProjectLabel(roleData?.data?.projectId), value: roleData?.data?.projectId },
                status: { label: roleData?.data?.status, value: roleData?.data?.status },
                // id : roleData?.data?.id
            })
            getPermissionData(roleData?.data?.roleId, roleData?.data?.projectId)
            setRoleData({
                "projectId": roleData?.data?.projectId,
                "roleId": roleData?.data?.roleId,
            })
        } else {
            setFetching(false)
            failureToast("Sorry no role found for provided id");
            history.push('/admin/userRolePage')
        }
    }

    useEffect(() => {
        if (action == 'view' && id && projectData?.data?.length) {
            setPreviewOnly(true)
            getDataByRoleId()
        }

        if (action == 'edit' && id && projectData?.data?.length) {
            getDataByRoleId()
        }

        if (action == 'new' && projectData?.data?.length) {
            setFetching(false)
        }
    }, [action, id, projectData?.data])

    useEffect(() => {
        setDataChanged(true)
    }, [values])

    const submitHandler = async () => {

        if (!values.roleName || !values.roleDescription || !values.projectId?.value || !values?.defaultDelegationDays) {
            failureToast("Select atleast one search criteria")
            return
        }

        if (!values?.roleName) {
            failureToast("Please enter role name")
            return
        }
        if (!values?.roleDescription || !values.roleDescription?.trim()) {
            failureToast("Please enter role descriptiom")
            return
        }
        if (!values?.defaultDelegationDays) {
            failureToast("Please enter default delegation days")
            return
        }
        if (!values?.projectId?.value) {
            failureToast("Please project")
            return
        }

        setSubmitting(true)
        const url = action === 'edit' ? `${endpoint.getRole.updateRole}/${id}` : endpoint.getRole.create;
        const method = action === 'edit' ? 'put' : 'post';
        const response = await fetchAllPostPromisedData(url, { ...values, roleName: values.roleName.toString().toUpperCase(), roleDescription: values?.roleDescription?.toString()?.trim(), projectId: values.projectId?.value, status: values.status?.value }, method)
        if (response?.code === 200) {
            setSubmitting(false)
            successToast(response['message'])
            if (action === 'new') {
                setRecordSaved(true)
                setRoleData(response?.data)
            }
            if (action === 'edit') {
                setEditHeader(false)
            }

        } else {
            setSubmitting(false)
            // failureToast(response['message']);
        }
    }

    const handleConfirm = () => {
        submitHandler()
        setConfirmationDialog(false)
    }

    //console..log("hello", anyDataChanged)

    return (
        <Container fluid className="pb-4">
            {isFetching ? (
                <div className="col">
                    <Card className=" w-100">
                        <div
                            className="mx-auto text-center py-5 my-5 "
                            style={{ height: '100vh' }}
                        >
                            <CustomLoader apiLoader={isFetching} />
                        </div>
                    </Card>
                </div>
            ) :
                <>
                    <Card >
                        <CardHeader>
                            <h3>Define Role</h3>
                        </CardHeader>
                        <Row className="pl-4 pr-4 pb-2 pt-2 course-fee-header">
                            <FormGenerator
                                fields={Object.keys(searchForm.create)}
                                fieldsObject={searchForm.create}
                                values={values}
                                setValues={setValues}
                                dataObjects={{ statusData: status, projectData: projectData }}
                                isPreviewEnable={previewOnly}
                                disabled={submitting || recordSaved || nextPage || (!editHeader && action !== 'new')}
                                isEditMode={action === 'edit'}
                            />
                        </Row>
                        <Row className="justify-content-end mr-2 pl-4 pr-4 pb-2">
                            {(!previewOnly && !recordSaved && action === 'new') && <CustomButton
                                content={submitting ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : "Save"}
                                permissionType={'C,U'}
                                permissionSet={userPermissions}
                                onClick={action === 'edit' ? () => setConfirmationDialog(true) : () => submitHandler()}

                            />}
                            {action === 'edit' && <>

                                {editHeader && <><CustomButton
                                    content={submitting ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : "Save"}
                                    permissionType={'C,U'}
                                    permissionSet={userPermissions}
                                    onClick={action === 'edit' ? () => setConfirmationDialog(true) : () => submitHandler()}

                                />
                                    <CustomButton
                                        content={'Cancel'}
                                        type={'close'}
                                        permissionType={'R'}
                                        permissionSet={userPermissions}
                                        onClick={() => setEditHeader(false)}
                                    /></>}

                                {!editHeader && <CustomButton
                                    content={'Edit'}
                                    permissionType={'U'}
                                    permissionSet={userPermissions}
                                    onClick={editPermission ? () => { failureToast("Please save unsaved data first") } : () => setEditHeader(true)}
                                />}

                            </>}
                        </Row>

                    </Card>
                    <Row className="justify-content-end mr-2">
                        {(recordSaved && !nextPage) && <CustomButton
                            content={'Next'}
                            permissionType={'C'}
                            permissionSet={userPermissions}
                            onClick={() => { setNextPage(true) }}
                        />}
                        {(recordSaved && action === 'new' && !nextPage) && <CustomButton
                            content={'Close'}
                            type={'close'}
                            permissionType={'R'}
                            permissionSet={userPermissions}
                            onClick={() => history.push('/admin/userRolePage')}
                        />}
                    </Row>
                    {(nextPage || action === 'edit' || action === 'view') && <Permission userPermissions={userPermissions} roleData={roleData} savedPermission={savedPermission} action={action} previewOnly={previewOnly} editPermission={editPermission}
                        setEditPermission={setEditPermission} editHeader={editHeader}
                    />}
                </>}
            <ConfirmationDialog
                isOpen={confirmationDialog}
                onAgree={() => { handleConfirm() }}
                setIsOpen={setConfirmationDialog}
                headerMsg={'Save Changes ?'}
                msg={'Are you sure to save the changes'}
                popupSymbol={""}
                type="CONFIRMSAVE"
            />
        </Container>
    )
}

export default CreateRole