import React, { useContext, useState } from "react";
import { RolePermissions, PermissionContext } from "appContext";
import { ReactComponent as EditIcon } from "assets/img/svg/Edit2.svg";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import { pages } from "views/pages/manage/common/constant";
import { checkPermission } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";


const ActionsCellComponent = ({
  params,
  handleEditClick,
  handleCancelClick,
  handleSaveClick,
  // deleteHandler,
  rowForm,
}) => {
    console.log("params",params.api.getRowMode(params?.id))
    const userPermissions = RolePermissions(useContext(PermissionContext), pages['paymentCollectionMasterSdSequencing']['id'])
  return params.api.getRowMode(params?.id) === "view" ? (
    <>
      <div className="d-flex align-items-center">
        
      {checkPermission(userPermissions, 'U') && <EditIcon onClick={() => handleEditClick(params)}/>}
      </div>
    </>
  ) : (
    <div className="d-flex align-items-center">
      <AButton
        variant="primary_filled"
        size="xs"
        onClick={() => handleSaveClick(params)}
        disabled={
          !rowForm?.mode ||
          !rowForm?.status ||
          !rowForm?.academicCareer||
          !rowForm?.itemType||
          !rowForm?.businessGroup||
          !rowForm?.companyCode
        }
      >
        Save
      </AButton>
        <CrossIcon className='ml-2' onClick={() => handleCancelClick(params)} />
    </div>
  );
};

export default ActionsCellComponent;
