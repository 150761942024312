import React, { useState, useEffect, useContext } from "react";
import TemplateManagerTable from "./table";
import CustomLoader from "../../common/commonComponents/Loader/loader";
import { failureToast, getAPI } from "services/http";
import { GetPagination } from "../../common/commonComponents/pagination/pagination";
import { PermissionContext, RolePermissions } from "appContext";
import { PermisionDenied } from "../../common/commonComponents";
import { endpoint, pages } from "../../common/constant";


const TemplateManager = (props) => {

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["templateUploadManager"]['id']);

  const [classArray, setclassArray] = useState([]);
  const [apiloader, setapiloader] = useState(false);
  const [state, setstate] = useState([]);
  const [serverData, setServerData] = useState([]);
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [processData, setProcessData] = useState([])
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const pageSize = 10;
  const fetchClasses = async (url) => {
    setapiloader(true);
    getAPI(
      url,
      (data) => {
        setstate(data["data"]);
        setServerData(data["data"]);
        setPagination(data["data"]);
        setapiloader(false)
      },
      (data) => {
        failureToast(data["message"]);
        setapiloader(false);
      }
    );
  };

  const setPagination = (paramsdata) => {
    let n = pagination;
    if (paramsdata.length > pageSize) {
      n["nextPage"] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n["totalPage"] = Math.floor(paramsdata.length / pageSize);
    if (paramsdata.length % pageSize != 0) {
      n["totalPage"] += 1;
    }
    setpagination(n);
    setclassArray(
      paramsdata.slice(
        pagination.currentPage * pageSize - pageSize,
        pagination.currentPage * pageSize
      ) || []
    );
  };



  useEffect(() => {
    fetchClasses(endpoint.templateUpload.getAll);
    getProcessTypeValues()
  }, []);

  const getProcessTypeValues = () => {
    getAPI(
      endpoint.process_Tag.getTemplatesProcessTypes,
      (data) => {
        const list = data?.data.map((data) => {
          return { ...data, label: data.tagTypeKey, value: data.id };
        });
        setProcessData(list);
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  }

  return (
    userPermissions ?
      <>
        {apiloader ? (
          <div
            className="mx-auto text-center py-5 my-5"
            style={{ height: "100vh" }}
          >
            <CustomLoader apiLoader={apiloader} />
          </div>
        ) : (
          <>
            <TemplateManagerTable
              fetchClasses={fetchClasses}
              setclassArray={setclassArray}
              classArray={classArray}
              isSaveVisible={isSaveVisible}
              setisSaveVisible={setisSaveVisible}
              serverData={serverData}
              processData={processData}
              currentPage={pagination.currentPage}
              userPermissions={userPermissions}
            />
            {pagination.totalPage > 1 ? (
              <GetPagination
                isSaveVisible={isSaveVisible}
                fetchClasses={fetchClasses}
                setclassArray={setclassArray}
                pagination={pagination}
                setpagination={setpagination}
                pageSize={pageSize}
                state={state}
              />
            ) : null}
          </>
        )}
      </> : <PermisionDenied />
  );
};

export default TemplateManager;
