import moment from "moment"
import { capitalize } from "../../common/utils/methods/commonMethods/utilityMethod"

export const detailsRowStyles = { fontSize: '14px', color: '#0F0F0F', marginBottom: '1rem' }
export const linkButtonStyles = { fontSize: '16px', color: '#00B0F5', fontWeight: '600', cursor: 'pointer', marginBottom: '0.5rem' }
export const iconStyles = { cursor: 'pointer', color: '#000000' }
export const disabledIconStyles = { color: "#00000042" }

export const academicCardInfoLabels = (requireApplicationId) => ([
  ...(requireApplicationId ? [{ title: 'Application ID', objKey: 'applicationId' }] : []),
  { title: 'Term', objKey: 'termDispVal' },
  { title: 'Program Action', objKey: 'programActions' },
  { title: 'Academic Group', objKey: 'academicGroup' },
  { title: 'Registration Date', objKey: 'registrationDate', valueGetter: (o) => o?.registrationDate ? new moment(o?.registrationDate).format('DD-MM-YYYY') : '-' },
  { title: 'Course', objKey: 'courseId' },
  // {title:'Roll Number' , objKey: 'rollNumber'},
  { title: 'Class', objKey: 'classes' },
  { title: 'Business Area', objKey: 'businessAreaDispValue' },
 
])

export const batchCardInfoLables = {
  current: [
    { title: 'Application ID', objKey: 'applicationId' },
    { title: 'Enrollment', objKey: 'enrollmentStatus', valueGetter: (o) => capitalize(o?.['enrollmentStatus']) },
    { title: 'Allocated Date', objKey: 'allocationDate', valueGetter: (o) => o?.allocationDate ? new moment(o?.allocationDate).format('DD-MMM-YYYY') : null },
    { title: 'Current Strength/Total Capacity', objKey: 'currentStrength', valueGetter: (o) => `${o?.['currentStrength']}/${o?.['totalCapacity']}` },
  ],
  past: [
    { title: 'Application ID', objKey: 'applicationId' },
    { title: 'Enrollment', objKey: 'enrollmentStatus', valueGetter: (o) => capitalize(o?.['enrollmentStatus']) },
    { title: 'Allocated Date', objKey: 'allocationDate', valueGetter: (o) => o?.allocationDate ? new moment(o?.allocationDate).format('DD-MMM-YYYY') : null },
    { title: 'Deallocated Date', objKey: 'deallocationDate', valueGetter: (o) => o?.deallocationDate ? new moment(o?.deallocationDate).format('DD-MMM-YYYY') : null },
    // { title: 'Deallocation Reason', objKey: 'deallocationReason' },
    { title: 'Current Strength/Total Capacity', objKey: 'currentStrength', valueGetter: (o) => `${o?.['currentStrength']}/${o?.['totalCapacity']}` },
  ]
}