import { InputLabel } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ImCross } from "react-icons/im";
import { InputLabelStyle, InputStyle } from "../../form-fields-mui/ASelect";
import { ReactComponent as SearchIcon } from "../../../../../../assets/img/svg/SearchIcon.svg";
import styles from "./styles.module.scss";
import { regexValidate } from "../../utils/methods/commonMethods/utilityMethod";

const AInput = ({
  className,
  style,
  innerRef,
  customSearchIconStyle,
  customCrossIconStyle,
  label,
  value,
  handler = () => {},
  placeholder,
  onKeyDown,
  crossHandler = () => {},
  type = "text",
  crossIcon = false,
  searchIcon = false,
  rightSearchIcon = false,
  searchIconClickHandler = () => {},
  customIcon,
  customIconStyle = {},
  rightCustomIcon,
  loading = false,
  onKeyPress,
  regex,
  error = false,
  labelStyle = {},
  ...props
}) => {
  const [cursor, setCursor] = useState(null);
  const ref = useRef(null);
  useEffect(() => {
    const input = ref.current;
    if (input) type === "text" && input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, value, type]);

  const crossIconStyle = {
    fontSize: "11px",
    position: "absolute",
    top: "0",
    bottom: "0",
    margin: "auto 0",
    right: rightSearchIcon ? "2rem" : "0.7rem",
    cursor: props?.disabled ? "default" : "pointer",
    ...customCrossIconStyle,
  };
  const searchIconStyle = {
    fontSize: "11px",
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "5px",
    margin: "auto 0",
    cursor: props?.disabled ? "default" : "pointer",
    ...customSearchIconStyle,
  };
  const rightSearchIconStyle = {
    fontSize: "11px",
    position: "absolute",
    top: "0",
    bottom: "0",
    right: "5px",
    margin: "auto",
    cursor: props?.disabled ? "default" : "pointer",
  };

  const loadingStyle = {
    fontSize: "20px",
    position: "absolute",
    top: "10px",
    right: "5px",
    margin: "auto",
    cursor: "pointer",
  };

  const customIconStyles = {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    top: "0",
    bottom: "0",
    margin: "auto 0",
    ...customIconStyle,
  };

  const paddingCross = crossIcon ? { paddingRight: "25px" } : {};
  const paddingSearch = searchIcon ? { paddingLeft: "27px" } : {};

  return (
    <>
      {label && (
        <InputLabel sx={{ ...InputLabelStyle, ...labelStyle }}>
          {label}
        </InputLabel>
      )}
      <div
        style={{ position: "relative" }}
        className={`${styles["custom-ainput"]} ${
          error ? styles["custom-input-error"] : ""
        }`}
      >
        {searchIcon && (
          <SearchIcon
            className="input_search_icon"
            onClick={props?.disabled ? () => {} : searchIconClickHandler}
            style={searchIconStyle}
          />
        )}
        {customIcon && <span style={customIconStyles}>{customIcon}</span>}
        <input
          value={value || ""}
          onChange={(e) => {
            if (regexValidate(regex, e.target.value)) {
              handler(e.target.value);
              setCursor(e.target.selectionStart);
            }
          }}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          type={type}
          className={className}
          style={{
            ...InputStyle,
            border: "1px solid #CFD3D4",
            ...paddingCross,
            ...paddingSearch,
            ...style,
          }}
          key="random1"
          ref={innerRef || ref}
          {...props}
        />
        {rightCustomIcon && <span>{rightCustomIcon}</span>}
        {crossIcon && value?.length > 0 && (
          <ImCross
            onClick={props?.disabled ? () => {} : crossHandler}
            style={crossIconStyle}
          />
        )}
        {!loading && rightSearchIcon && (
          <SearchIcon
            className="input_search_icon"
            onClick={props?.disabled ? () => {} : searchIconClickHandler}
            style={rightSearchIconStyle}
          />
        )}
        {loading && (
          <i
            className="fas fa-circle-notch fa-spin"
            aria-hidden="true"
            style={loadingStyle}
          ></i>
        )}
      </div>
    </>
  );
};

export default AInput;
