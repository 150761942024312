import { authServiceUrl } from "services/http";

export const authServiceEndpoints = {
    resetPassword : `${authServiceUrl}/reset`,
    generateOtp: `${authServiceUrl}/generateOtp`,
    validateOtp: `${authServiceUrl}/validateOtp`,
    resetPasswordWithOtp: `${authServiceUrl}/reset/password/otp`,

    getUser: `${authServiceUrl}/employeeData/getAllActiveEmployeeData`,
    createUser: `${authServiceUrl}/users/register`,
    updateUser: `${authServiceUrl}/users/updateUser`,
    searchProfile: `${authServiceUrl}/searchUserProfile`,
    getProfileById: `${authServiceUrl}/getUserProfileDetails`,
    getUserByEmployeeId: `${authServiceUrl}/getUserProfileDetailsByEmployeeId`,
    getTicketHistory: `${authServiceUrl}/users/ticket/getAllTickets`,
    requestResetPassword: `${authServiceUrl}/user/resetPassword/ticket`,
    getEmployeeDetails: `${authServiceUrl}/employeeData/getEmployeeDetailsByEmployeeId`,
    updateEmployee: `${authServiceUrl}/employeeData/updateEmployeeDetails`,
    exportExcel: `${authServiceUrl}/exportExcel`,
};
