import React, { useEffect, useState } from 'react';
import MuiTable from '../../../../manage/common/commonComponents/MuiTable.js';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../../common/utils/methods/commonMethods/utilityMethod.js';
import { endpoint } from '../../../common/constant';
import { failureToast, successToast } from '../../../common/utils/methods/toasterFunctions/function.js';
import moment from 'moment';
import { AiOutlineEye } from "react-icons/ai";
import Tooltip from '@mui/material/Tooltip';

const getFormatedDate = (date) => {
    return date ? moment(date).format('DD-MMM-YYYY') : ''
}

const columns = [
    { field: 'loanId', headerName: 'ID', type: 'text', minWidth: 60, mandatory: false, editable: false, disabled: false,flex:1 },
    { 
        field: 'loanValue', headerName: 'Value', minWidth: 140, type: 'text', mandatory: true,
        editable: true, disabled: false,
        restrictSpecialCharacter: false, regEx: /^$|[0-9\/]+$/
    },
    { field: 'vendorName', headerName: 'Vendor name', minWidth: 140, type: 'text', mandatory: true, editable: true, disabled: false, flex:1 },
    {
        field: 'title', headerName: 'Title', minWidth: 140, mandatory: true, editable: true, disabled: false,flex:1,
        renderCell: (params) => (
            <div className='w-100 d-flex'>
                <span style={{overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '85%'}}>{params.value}</span>
                {params.value && 
                    <Tooltip title={params.value}>
                        <div style={{cursor: 'pointer'}}>
                            <AiOutlineEye className='ml-1' size={16} color='#11cdef'/>
                        </div>
                    </Tooltip>
                }
            </div>
        )
    },
    {
        field: 'doIdLength', headerName: 'DO ID length', minWidth: 145, type: 'number', mandatory: true, editable: true, disabled: false,flex:1,
        renderCell: params => (
            <div>{params?.value || '-'}</div>
        )
    },
    {
        field: 'effectiveFrom', headerName: 'Effective date', type: 'date', mandatory: true, minDate: new Date(),flex:1,
        minWidth: 130, editable: true, disabled: false, formatter: getFormatedDate
    },
    {
        field: 'effectiveTo', headerName: 'Expiry date', type: 'date', mandatory: true, minDate: new Date(),flex:1,
        minWidth: 130, editable: true, disabled: false, formatter: getFormatedDate
    },
    { field: 'processingFees', headerName: 'Processing fee', minWidth: 140, type: 'number', allowDecimalvalue: true, mandatory: true,flex:1,
        editable: true, disabled: false, formatter: (data) => '₹ ' + data
    },
    { field: 'minimumAmount', headerName: 'Min. loan amt.', minWidth: 140, type: 'number', allowDecimalvalue: true, mandatory: true,flex:1,
        editable: true, disabled: false, renderCell: params => (<div>{`₹ ${params?.value || '-'}`}</div>)
    },
    { field: 'subventionPercentage', headerName: 'Subvention %', minWidth: 130, type: 'number', allowDecimalvalue: true, mandatory: true,flex:1,
        editable: true, disabled: false, maxValue: 100, formatter: (data) => data + ' %'
    },
    {
        field: 'noOfYears', headerName: 'No. of years', type: 'multiSelect',flex:1,
        valueOptions: [{label: 'FIRST', value: 'FIRST'}, {label: 'SECOND', value: 'SECOND'}, {label: 'THIRD', value: 'THIRD'}],
        minWidth: 150, editable: true, disabled: false
    },
    { field: 'lastUpdate', headerName: 'Last update', minWidth: 160, type: 'text', mandatory: false, editable: false, disabled: false,flex:1, },
    { field: 'status', headerName: 'Status', minWidth: 160, type: 'toggle', mandatory: true, editable: true, disabled: false,flex:1,
        dataLabelOn: "ACTIVE", dataLabelOff: "INACTIVE"},
];

const LoanSchemeMaster = ({ userPermissions }) => {

    const [schemes, setSchemes] = useState([]);
    const [loading, setLoading] = useState(false);

    function createSchemes(schemesPayload) {

        return schemesPayload.map(scheme => {

            const {id, value, vendorName, title, effectiveFrom, effectiveTo, processingFees, subventionPercentage, noOfYears, updatedOn, updatedBy, status, doIdLength, minimumAmount} = scheme;

            return {
                id: id,
                loanId: id,
                loanValue: value,
                vendorName,
                title,
                effectiveFrom,
                effectiveTo,
                processingFees,
                subventionPercentage,
                noOfYears: noOfYears.map(item => ({label: item, value: item })),
                lastUpdate: `${updatedBy}, ${getFormatedDate(updatedOn)}`,
                status,
                fromBE: true,
                doIdLength: doIdLength,
                minimumAmount: minimumAmount,
            }
        })
    }

    async function getAllLoanSchemes() {
        setLoading(true);
        try {
            const response = await fetchAllPromisedData(`${endpoint.loanScheme}/all`);
            setLoading(false);
            if (response) {
                const data = createSchemes(response);
                setSchemes(data);
            } else {
                failureToast("Failed to fetch Data");
            }
        } catch (error) {
            setLoading(false);
            failureToast("Failed to fetch Data");
        }
    }

    useEffect(() => {
        getAllLoanSchemes();
    }, [])

    async function deleteScheme(row) {
        setLoading(true);
        const p = new Promise((resolve, reject) => {
            fetchAllPostPromisedData(`${endpoint.loanScheme}/${row.id}`, {}, 'delete')
            .then(response => {
                setLoading(false);
                if(response && response.code === 200) {
                    successToast('Loan scheme master deleted successfully!');
                    resolve(row);
                } else {
                    failureToast(response.message)
                    reject(response.message);
                }
            })
            .catch(err => {setLoading(false); reject(err);});
        });
        return p;
    }

    function createSchemesPayload(row) {

        function createValues(arr) {
            const data = [...arr];
            if (arr[0]?.value === "*") {
                data.shift();
            }
            return data.map(item => item.value);
        }

        const request = {
            value: row.loanValue,
            vendorName: row.vendorName,
            title: row.title,
            effectiveFrom: row.effectiveFrom,
            effectiveTo: row.effectiveTo,
            processingFees: +row.processingFees,
            subventionPercentage: +row.subventionPercentage,
            noOfYears: createValues(row.noOfYears),
            status: row.status,
            doIdLength: row.doIdLength,
            minimumAmount: row.minimumAmount,
        }
        return request;
    }
    async function saveScheme(row) {
        setLoading(true);
        
        const request = createSchemesPayload(row);
        const isRowNew = row.fromBE !== true;
        
        const p = new Promise((resolve, reject) => {
            fetchAllPostPromisedData(`${endpoint.loanScheme}${isRowNew ? '' : '/'+row.id}`, request, isRowNew ? 'post' : 'put')
            .then(response => {
                setLoading(false);
                if(response && response.code === 200) {
                    successToast(`Loan scheme master ${isRowNew ? 'added' : 'edited'} successfully!`);
                    if(isRowNew) {
                        getAllLoanSchemes();
                    }   
                    resolve(row);
                } else {
                    failureToast(response.message)
                    reject(response.message);
                }
            })
            .catch(err => {setLoading(false); reject(err);});
        });
        return p;
    }

    const loanSchemeHeaderComp = (
    <div className='h-100 d-flex align-items-center'>
        <h2 style={{ fontWeight: 600 }}>Loan scheme master</h2>
    </div>);

    return (
        <MuiTable 
            rows={schemes}
            columns={columns}
            headerComp={loanSchemeHeaderComp}
            userPermissions={userPermissions}
            loading={loading}
            asyncDeleteRow={deleteScheme}
            asyncSaveRow={saveScheme}
            useDeleteIconForCancel={true}
            minHeight={156}
            disableVirtualization={true}
        />
    )
}

export default LoanSchemeMaster;