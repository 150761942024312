import React, { useContext, useEffect, useState } from 'react'
import HeaderComponent from '../HeaderComponent'
import { PermissionContext, RolePermissions } from "appContext";
import { endpoint, pages } from '../../../common/constant';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../../common/utils/methods/commonMethods/utilityMethod.js';
import { getDropdowns } from '../../../../../../redux/facultyRoaster/actions.js';
import ALoader from 'views/pages/manage/common/a-loader'
import arrowleft from '../../../../../../assets/img/svg/Arrow-left.svg'
import { useHistory } from 'react-router';
import styles from '../common/facultySchedule.module.scss'
import ScheduleHeaderRow from './ScheduleHeaderRow';
import SearchFilter from './SearchFilter';
import ScheduleContentRow from './ScheduleContentRow';
import moment from 'moment';
import NoRowOverlay from 'views/pages/manage/masters/holidayMaster/NoRowOverlay'
import { dayFilter, getBatchDropdown, getFilterCount, initialFilterForm, resturctureFacultyScheduleData, statusFilter, timeRangeFilter } from './constant';
import { PermisionDenied } from '../../../common/commonComponents';


const FacultySchedule = (props) => {

  const userPermissions = RolePermissions(useContext(PermissionContext), pages["facultyRoaster"]['id']);
  const { id } = props.match.params ?? { id: '', action: '' };
  const history = useHistory();

  const [dropdowns, setDropdowns] = useState({});
  const [dropdownLoader, setDropdownLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scheduleApiLoader, setScheduleApiLoader] = useState(false);
  const [facultyDetails, setFacultyDetails] = useState({});
  const [scheduleRawData, setScheduleRawData] = useState([]);
  const [filteredScheduleData, setFilteredScheduleData] = useState({}) // data will be rendered using this object

  // filter states here
  const [filterCount, setFilterCount] = useState(0);
  const [filterForm, setFilterForm] = useState(initialFilterForm)
  const filterFormHandler = (value, key) => setFilterForm(prev => ({ ...prev, [key]: value }))
  const [nameFilterInput, setNameFilterInput] = useState('');

  const [batchDropdownData, setBatchDropdownData] = useState([]);

  const fetchFacultyDetails = async () => {
    setLoading(true);
    const p = await Promise.all([
      fetchAllPromisedData(`${endpoint?.faculty.getById}/${id}`)
    ])
    setFacultyDetails(p[0]);
    setLoading(false);
  }

  const fetchDropdowns = async (cb = () => { }) => {
    setDropdownLoader(true);
    const res = await getDropdowns();
    setDropdowns(res);
    setDropdownLoader(false);
    cb(res);
  }

  const fetchFacultySchedule = async (businessArea, startDate, endDate) => {
    // api call for getting faculty schedule
    const request = {
      facultyMasterId: id,
      businessAreaIds: businessArea?.map(item => item?.value),
      startDate: startDate || moment()?.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      endDate: endDate ? moment(endDate)?.set({ hour: '23', minute: '59', second: '59', millisecond: 0 }) : moment()?.set({ hour: '23', minute: '59', second: '59', millisecond: 0 })?.add(30, 'days'),
    }
    const res = await fetchAllPostPromisedData(endpoint?.facultyAvailability, request, 'post')
    setBatchDropdownData(getBatchDropdown(res?.data?.facultyAvailabilityResponseList));
    return res;
  }

  // this wrapper method is written to reuse the fetchFacultySchedule method while applying filters
  const fetchInitialFacutlySchedule = async (dropdownData) => {
    setScheduleApiLoader(true);
    const res = await fetchFacultySchedule(dropdownData?.businessArea);
    if (res?.code === 200) {
      setScheduleRawData(res?.data?.facultyAvailabilityResponseList || []);
      setFilteredScheduleData(resturctureFacultyScheduleData(res?.data?.facultyAvailabilityResponseList || []));
    }
    setScheduleApiLoader(false);
  }

  useEffect(() => {
    fetchFacultyDetails();
    fetchDropdowns(fetchInitialFacutlySchedule);
  }, [id])

  const applyFilterHandler = async (form) => {

    setScheduleApiLoader(true);

    const ba = form?.businessArea?.length > 0 ? form?.businessArea : dropdowns?.businessArea;
    const res = await fetchFacultySchedule(ba, form?.startDate, form?.endDate);
    let scheduleData = res?.data?.facultyAvailabilityResponseList || [];

    // UI filtering logic here based on scheduleData variable
    const filteredRes = [];

    scheduleData.map(item => {
      if (
        dayFilter(form?.day, item) &&
        timeRangeFilter(form?.startTime, form?.endTime, item) &&
        statusFilter(form, item)
      ) {
        filteredRes.push(item);
      }
    })

    // after filtering data filtered data to be restructured and set to the state filteredScheduleData
    setFilterCount(getFilterCount(form));
    setScheduleRawData(filteredRes);
    setFilteredScheduleData(resturctureFacultyScheduleData(filteredRes));
    setNameFilterInput('');

    setScheduleApiLoader(false);
  }

  const removeFilterHandler = async () => {
    setScheduleApiLoader(true);

    await fetchInitialFacutlySchedule(dropdowns);
    setFilterCount(0);
    setFilterForm(initialFilterForm);
    setNameFilterInput('');

    setScheduleApiLoader(false);
  }

  const applyNameFilterHandler = async () => {
    setScheduleApiLoader(true);

    let filteredRes = [];
    nameFilterInput 
      ? scheduleRawData.map(item => item?.eventName?.toLowerCase()?.includes(nameFilterInput?.toLowerCase()) && filteredRes?.push(item))
      : filteredRes = scheduleRawData

    setFilteredScheduleData(resturctureFacultyScheduleData(filteredRes));

    setScheduleApiLoader(false);
  }
  const clearNameFilterHandler = () => {
    setScheduleApiLoader(true);

    setNameFilterInput('');
    setFilteredScheduleData(resturctureFacultyScheduleData(scheduleRawData));

    setScheduleApiLoader(false);
  }

  return (
    userPermissions ? (
      <div className='remove-container-top-space d-flex flex-column' style={{ minHeight: 'calc(100vh - 79px)' }}>
        {(loading || dropdownLoader || scheduleApiLoader) && <ALoader position='fixed' />}
        <HeaderComponent
          isInformationAvailable={true}
          facultyDetail={facultyDetails}
          dropdowns={dropdowns}
          loading={loading}
          userPermissions={userPermissions}
          headerTitle={`Faculty roster / ${facultyDetails?.facultyName} roster / schedule`}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 20, backgroundColor: 'white' }}>
          <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
            {/* <img src={arrowleft} alt='' width={20} height={20} className='cursor' onClick={() => history?.goBack()} /> */}
            <div style={{ fontSize: 16, fontWeight: 600 }}>{`${facultyDetails?.facultyName} schedule`}</div>
          </div>
          <SearchFilter
            dropdowns={dropdowns}
            filterCount={filterCount}
            filterForm={filterForm}
            filterFormHandler={filterFormHandler}
            applyFilterHandler={applyFilterHandler}
            removeFilterHandler={removeFilterHandler}
            batchDropdownData={batchDropdownData}
            applyNameFilterHandler={applyNameFilterHandler}
            clearNameFilterHandler={clearNameFilterHandler}
            nameFilterInput={nameFilterInput}
            setNameFilterInput={setNameFilterInput}
          />
        </div>
        <div className={`${styles?.content_card}`}>
          <ScheduleHeaderRow />
          {!Object.entries(filteredScheduleData)?.length && <NoRowOverlay message={(dropdownLoader | scheduleApiLoader) ? 'Loading...' : ''} />}
          {Object.entries(filteredScheduleData)?.map(el => (
            el?.[1]?.length && <div key={el[0]} style={{ marginBottom: '10px' }}>
              <div className='large-semi-bold color-black'>{moment(el[0], 'DD-MM-YYYY')?.format("ddd, DD MMM YYYY")}</div>
              {el?.[1]?.map(rowData => <ScheduleContentRow key={`${rowData?.startTime} - ${rowData?.endTime}`} rowData={rowData} />)}
            </div>
          ))}
        </div>
      </div>
    ) : (
      <PermisionDenied />
    )
  )
}

export default FacultySchedule