import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { CardHeader, Input, Table, Row } from 'reactstrap';
import { useState } from 'react';
import {
  COURSEATTRSESSIONTABLE,
  CourseAttrSessionHeader,
} from '../../../../../common/commonComponents/masterRow/masterRow';
import { masterServiceBaseUrl, putpost, putpost2, deleteAPI } from 'services/http';
import Select from 'react-select';
import moment from 'moment';
import { getDispValForReactSelectByOneID } from '../../../../../common/utils/methods/commonMethods/utilityMethod';
import CustomToggle from 'views/pages/manage/common/formFeilds/customToggle';
import { failureToast, successToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import CustomButton from 'components/CustomButton';

var _ = require('lodash');

const CourseAttrSessionTable = props => {
  let history = useHistory();
  const { userPermissions } = props;
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [dispValue, setDispValue] = useState('');
  const [tableDataKey, settableDataKey] = useState('tableDataKey');
  const [statusActive, setStatusActive] = useState(false);
  const [attributeValue, setAttributeValue] = useState({
    attributeId: '',
    attributedesc: '',
  });

  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: '__new',
        courseAttributeValueId: null,
        descriptionValue: null,
        status: 'ACTIVE',
      },
      ...props.classArray,
    ]);
  };

  const saveAll = cb => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if (el['editMode']) {
        if (el['courseAttributeValueId'] === null) {
          isValid = false;
          failureToast('Please Select Attribute Value');
          return;
        } else if (el['status'] === null) {
          isValid = false;
          failureToast('Please select Status');
          return;
        }
        if (!isValid) {
          return;
        }
        if (_tempArray[index]['__status'] == '__new') {
          _tempArray[index]['courseAttributeMappingId'] = props.formId;
          putpost(
            masterServiceBaseUrl + `/courseAttributeValueMapping/createCourseAttributeValueMapping`,
            data => {
              successToast(data['message']);
              setisSaveVisible(false);
              props.fetchClasses();
            },
            data => {
              failureToast(data['message']);
            },
            _tempArray[index],
            'post'
          );
        } else {
          putpost(
            masterServiceBaseUrl +
              '/courseAttributeValueMapping/updateCourseAttributeValueMapping/' +
              _tempArray[index].id,
            data => {
              successToast(data['message']);
              setisSaveVisible(false);
              delete _tempArray[index]['editMode'];
              props.setclassArray(_tempArray);
              settableDataKey(new Date().getMilliseconds());
            },
            data => {
              failureToast(data['message']);
            },
            _tempArray[index],
            'put'
          );
        }
      } else {
      }
    });
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      setAttributeValue({
        attributedesc: '',
      });
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      cb();
    }
  };
  const editRow = index => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]['editMode'] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };
  const deleteRow = index => {
    if (props.classArray[index]['__status'] == '__new') {
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
    } else {
      if (window.confirm('Are you sure you want to delete this Course Attribute?')) {
        putpost2(
          masterServiceBaseUrl +
            '/courseAttributeValueMapping/deleteCourseAttributeValueMapping/' +
            props.classArray[index].id,
          data => {
            successToast(data['message']);
            if (props.classArray.length == 1) {
              props.fetchClasses();
            } else {
              var _tempArray = props.classArray;
              if (_tempArray[index]['editMode']) {
                setisSaveVisible(false);
              }
              _tempArray.splice(index, 1);
              props.setclassArray(_tempArray);
            }
            setAttributeValue({
              attributedesc: '',
            });
            settableDataKey(new Date().getMilliseconds());
          },
          data => {
            failureToast(data['message']);
          },
          'Delete'
        );
      }
    }
  };
  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
  };

  const getAttributeValue = id => {
    return props.attributeData.filter(item => {
      return item.value === id;
    })[0];
  };

  return (
    <Fragment>
      <CardHeader className="border-0">
        <h3 className="mb-0 floatLeft">Course Attribute Value Mapping</h3>
        {!props.isViewOnly ? (
          <CustomButton
            disabled={isSaveVisible}
            className={'floatRight mx-1' + (isSaveVisible ? ' btn-dark cursor-gray' : null)}
            content={'Add New Course Attribute'}
            permissionType={'C'}
            icon={true}
            forTable={false}
            permissionSet={userPermissions}
            onClick={() => {
              newRow();
            }}
          />
        ) : null}
      </CardHeader>
      <div id="questionTable">
        <Table className="align-items-center table-flush tableLayout">
          <CourseAttrSessionHeader
            type={'CourseAttrSession'}
            isViewOnly={props.isViewOnly}
            isSaveVisible={isSaveVisible}
            permissionSet={userPermissions}
          />
          <tbody className="list" key={tableDataKey}>
            {props.classArray
              ? props.classArray.map((el, index) =>
                  !el['editMode'] ? (
                    <COURSEATTRSESSIONTABLE
                      type={'testMode'}
                      index={index}
                      el={el}
                      status={el?.status}
                      isViewOnly={props.isViewOnly}
                      editRow={editRow}
                      deleteRow={deleteRow}
                      attributeData={props.attributeData}
                      isSaveVisible={isSaveVisible}
                      permissionSet={userPermissions}
                    />
                  ) : (
                    <tr key={index + '-class'}>
                      <td className="text-center p-2 mx-1">
                        <Select
                          options={props.attributeData}
                          value={getAttributeValue(el['courseAttributeValueId'])}
                          onChange={data => {
                            setAttributeValue({
                              attributeId: data.value,
                              attributedesc: data.desc,
                            });
                            updateKey(index, data.value, 'courseAttributeValueId');
                          }}
                          placeholder="Attribute Value"
                          required
                        />
                      </td>
                      <td className="text-center p-2 mx-1">
                        <Input
                          data-testid="attributedesc"
                          type="text"
                          value={attributeValue.attributedesc}
                          className="tdInput"
                          // value={dispValue}
                          placeholder="Description"
                          disabled={true}
                          onChange={sData => {
                            setDispValue(sData);
                          }}
                        />
                      </td>

                      <td className="text-center p-2 mx-1">
                        <CustomToggle
                          status={el.status}
                          data-testid="activeInactiveInput"
                          id={el.id}
                          toggleOn={'Active'}
                          toggleOff={'Inactive'}
                          onChangeHandler={e => {
                            settableDataKey(new Date().getMilliseconds());
                            setStatusActive(e.target.checked);
                            let n = 'ACTIVE';
                            if (!e.target.checked) {
                              n = 'INACTIVE';
                            }
                            updateKey(index, n, 'status');
                          }}
                        />
                      </td>
                      <td className="text-center">
                        {isSaveVisible ? (
                          <Fragment>
                            <CustomButton
                              className={'mx-1'}
                              content={'Save'}
                              permissionType={'C,U'}
                              forTable={true}
                              permissionSet={userPermissions}
                              onClick={() => {
                                saveAll();
                              }}
                            />
                            <CustomButton
                              permissionType={'cancel'}
                              icon={true}
                              forTable={true}
                              permissionSet={userPermissions}
                              onClick={() => {
                                let n = props.classArray;
                                if (n[index]['__status'] == '__new') {
                                  n.splice(index, 1);
                                } else {
                                  props.fetchClasses();
                                }
                                props.setclassArray(n);
                                setisSaveVisible(false);
                              }}
                            />
                          </Fragment>
                        ) : (
                          <CustomButton
                            permissionType={'D'}
                            icon={true}
                            forTable={true}
                            onClick={() => deleteRow(index)}
                            permissionSet={userPermissions}
                          />
                        )}
                      </td>
                    </tr>
                  )
                )
              : null}
          </tbody>
        </Table>
        <Row className="mt-2 pr-3 pt-2 pb-3 mr-2 pl-5 floatRight">
          {props.classArray.length < 1 ? <span> Please Add Course Attribute From Above </span> : null}
          <CustomButton
            disabled={!props.classArray.length}
            className={`floatRight ml-auto`}
            content={'Close'}
            permissionType={'cancel'}
            forTable={true}
            onClick={() => {
              history.push('/admin/courseAttributesMapping');
            }}
            permissionSet={userPermissions}
          />
        </Row>
      </div>
    </Fragment>
  );
};
export default CourseAttrSessionTable;
