import React, { useState, useEffect } from 'react';
import { Card, CardHeader, Container } from 'reactstrap';

import FormFirstAllSection from './formFirstAllSection';
import ActionRow from './actionRow';
import LoginDetails from './logDetails';

const NewProgramActions = (props) => {
  const { id } = props.match.params;
  const [step, setStep] = useState(0);
  const [formId, setFormId] = useState(null);
  const [isNew, setIsNew] = useState(true);

  React.useEffect(() => {
    if (id !== 'new') {
      setFormId(id);
      setIsNew(false);
    }
  }, [id]);

  const nextStep = (id) => {
    if (id) {
      setFormId(id);
    }
    setStep(step + 1);
  };

  let pageToRender = null;

  switch (step) {
    case 0:
      pageToRender = (
        <FormFirstAllSection
          nextStep={nextStep}
          isNew={isNew}
          formId={formId}
        />
      );
      break;
    case 1:
      pageToRender = (
        <ActionRow formId={formId} nextStep={nextStep} isNew={isNew} />
      );
      break;
    default:
      pageToRender = <FormFirstAllSection />;
  }


  return (
    <>
      <Container className="mt-3" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3>Program Action Mapping</h3>
          </CardHeader>
        </Card>
        {isNew ? (
          pageToRender
        ) : (
          <>
            <FormFirstAllSection isNew={isNew} formId={formId} />
            <ActionRow formId={formId} isNew={isNew} />
            <LoginDetails formId={formId} isNew={isNew} />
          </>
        )}
      </Container>
    </>
  );
};

export default NewProgramActions;
