import React from "react";
import { getDisplayValueByKey } from "./helper";

const BatchElement = ({ label, value }) => {
  return (
    <span className="d-flex justify-content-between">
      <span className="regular-bold">{label || "Region"}</span>
      <span className="semi-bold color-dark">{value}</span>
    </span>
  );
};
const DisplayBatchDetails = ({batchDetails}) => {
  return (
    <div>
      <div className="gap d-flex flex-column">
        <div className="semi-bold">Batch</div>
        <div className="batch-name regular-large mb-2 align-center">{batchDetails?.batchName}</div>
        <div className="batch-details d-flex flex-column gap">
          <BatchElement label="Region" value= {getDisplayValueByKey(batchDetails,'region')} />
          <BatchElement label="Business Area" value= {getDisplayValueByKey(batchDetails,'businessArea')} />
          <BatchElement label="Academic term" value= {getDisplayValueByKey (batchDetails,'term')} />
          <BatchElement label="Academic career" value= {getDisplayValueByKey(batchDetails,'academicCareer')} /> 
          <BatchElement label="Academic group" value= {getDisplayValueByKey(batchDetails,'academicGroup')} />
          <BatchElement label="Academic year" value= {getDisplayValueByKey(batchDetails,'academicYear')} />
          <BatchElement label="Academic phase" value= {getDisplayValueByKey(batchDetails,'academicPhase')} />

        </div>
      </div>
    </div>
  );
};

export default DisplayBatchDetails;
