import React, { Fragment } from "react";
import CustomTable from "../../common/commonComponents/customTable";
import { endpoint, regex } from "../../common/constant";
import { Button } from "reactstrap";
import { CustomContainer } from "../../common/commonComponents";

const ApprovalStatus = () => {
  const tableDetails = {
    headerDetails: [
      { name: "Key", required: true },
      { name: "Description", required: true },
      { name: "Display Value", required: true },
      { name: "Status", required: true },
      { name: "Action", required: true },
    ],
    apiDetails: {
      create: endpoint.approvalStatus.create,
      update: endpoint.approvalStatus.update,
      // delete: endpoint.approvalStatus.delete,
      getAll: endpoint.approvalStatus.getAll,
    },
    listDetails: {
      approvalStatusKey: {
        normal: true,
      },
      approvalStatusDesc: {
        normal: true,
      },
      approvalStatusDispValue: {
        normal: true,
      },
      status: {
        normal: false,
        type: "TOGGLE",
      },
      action: {
        type: "ACTION",
        button: [
          {
            icon: "fas fa-edit",
            color: "info",
            actionType: "EDIT",
          },
          // {
          //   icon: "fas fa-trash",
          //   color: "info",
          //   actionType: "DELETE",
          // },
        ],
      },
    },
    fieldDetails: [
      {
        type: "text",
        minLength: "1",
        maxLength: "1",
        regx: /^[A-Z]*$/i,
        regxValidation: true,
        placeHolder: "Key",
        propertyName: "approvalStatusKey",
        toUpperCase :true,
        disabledOnEdit : true
      },
      {
        type: "text",
        minLength: "1",
        maxLength: "50",
        regx: regex.alphaNumericWithSpaceAndUnderscore,
        regxValidation: true,
        placeHolder: "Description",
        propertyName: "approvalStatusDesc",
      },
      {
        type: "text",
        minLength: "1",
        maxLength: "50",
        regx: regex.alphaNumericWithSpaceAndUnderscore,
        regxValidation: true,
        placeHolder: "Display Value",
        propertyName: "approvalStatusDispValue",
      },
      {
        type: "Status",
        propertyName: "status",
      },
    ],
    isSearch: false,
    isNew : true
  };

  const TableHeader = () => {
    return (
      <div className={"d-flex justify-content-between w-100"}>
        <h3 className="mb-0 floatLeft">Approval Status</h3>
      </div>
    );
  };

  return (
    <CustomContainer>
      <CustomTable tableHeader={TableHeader} tableDetails={tableDetails} addTitle="Add Approval Status"/>
    </CustomContainer>
  );
};

export default ApprovalStatus;
