
import React, { Fragment, useState, useRef } from 'react';
import { Progress, Card, CardBody } from 'reactstrap';
import { failureToast, successToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import style from './dragAndDrop.module.scss';

const CustomDragAndDrop = ({
  setFile,
  progressBarStatus,
  handleFile,
  isUploading,
  changeIconColor,
  validFiles,
  message,
  name,
}) => {
  const [isFileValid, setISFileValid] = useState(false);
  const [filename, setFileName] = useState(
    `Upload Your ${name ? name : 'Template'}`
  );
  const inputRef = useRef(null);

  const uploadDocumentHandler = (e) => {
    // console.log(e.target.files)
    try {
      if (e.target.files[0].name.length > 100) {
        failureToast('File name should not be more than 100 characters');
        inputRef.current.value = '';
        return;
      }
      if (e.target.files[0].name.split('.').length > 2) {
        failureToast('File name should be valid.');
        inputRef.current.value = '';
        return;
      }
      const regex = /^[A-Za-z0-9 _:/()-]*$/
      if (!regex.test(e.target.files[0].name.split('.')[0])) {
        failureToast('Only these special characters are allowed in a file name:  _:/()-');
        inputRef.current.value = '';
        return;
      }
      showFile(e.target.files[0], e);
    } catch (e) {
      console.log('Error', e);
    }
  };

  const dragOverHandler = (e) => {
    try {
      e.preventDefault();
      setFileName(`Release to Upload ${name ? name : 'Template'}`);
    } catch (e) {
      //console.log('Error', e);
    }
  };

  const dragLeaveHandler = (e) => {
    try {
      setFileName(`Upload Your ${name ? name : 'Template'}`);
    } catch (e) {
      console.log('Error', e);
    }
  };

  const dropHandler = (e) => {
    try {
      e.preventDefault();
      showFile(e.dataTransfer.files[0], e);
    } catch (e) {
      console.log('Error', e);
    }
  };

  const showFile = (file, e) => {
    // console.log('inside the validation section');
    try {
      let fileType = file.name.split('.');
      let validExtensions =
      validFiles && validFiles.length > 0 ? validFiles : ['csv', 'xlsx', 'xls'];
      let fileExtension = fileType[fileType.length - 1];

      if (validExtensions.includes(fileExtension)) {
        setFileName(file.name);
        setISFileValid(true);
        setFile(file);
        handleFile(e);
      } else {
        setISFileValid(false);
        setFile(null);
        failureToast(
          message
            ? message
            : 'You may only upload .csv files.Please ensure your file format is one of these.'
        );
        setFileName(`Upload Your ${name ? name : 'Template'}`);
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  const removeImageHandler = () => {
    try {
      setISFileValid(false);
      setFile('');
      document.getElementById('uploadDoc').value = null;
      setFileName(`Upload Your ${name ? name : 'Template'}`);
    } catch (e) {
      console.log('Error', e);
    }
  };

  return (
    <Card>
      <CardBody>
      <div
        className={style.drag_area}
        onDragOver={(e) => dragOverHandler(e)}
        onDragLeave={(e) => dragLeaveHandler(e)}
        onDrop={(e) => dropHandler(e)}
        id='dropArea'
      >
        {isFileValid && progressBarStatus !== 100 ? (
          <i
            class={`fas fa-trash-alt ${style.removeImage}`}
            onClick={() => !isUploading && removeImageHandler()}
          ></i>
        ) : null}

          <div className={style.upload__drag__area__icon}>
          {!isFileValid ? (
              // <i class='fas fa-cloud-upload-alt'></i>
              <img src={require('assets/img/theme/upload-placeholder.png')} alt="Upload" style={{ "-webkit-user-drag": "none", width: "100px", marginBottom: "15px" }} />
          ) : (
            <i
              className={`${
                validFiles && validFiles[0] && validFiles[0] === 'zip'
                  ? 'fas fa-file-archive'
                  : 'fas fa-file-csv'
              } ${
                !changeIconColor
                  ? style.drag_area_icon
                  : style.drag_area_icon_success
              }`}
            ></i>
          )}
        </div>

          <h1 className={style.drag__and__drop__file__name} id='dragger-text'>
          {filename}
          </h1>

        {!isFileValid ? (
          <>
              <p className={style.drag_drop_text}>Drag & drop to Upload your file here</p>
              <button className={style.upload__file__button}
              onClick={() => document.getElementById('uploadDoc').click()}
            >
                {/* Upload {name ? name : 'Template'} */}
                <i className="fas fa-plus" />&nbsp;&nbsp;Add
            </button>
          </>
        ) : isUploading ? (
          <Progress
            animated
            color='info'
            className={style.customProgressBar}
            value={progressBarStatus}
          >
            {progressBarStatus} %
          </Progress>
        ) : null}

        <input
          onChange={(e) => uploadDocumentHandler(e)}
          type='file'
          hidden
          id='uploadDoc'
          accept={validFiles && validFiles[0] ? '.' + validFiles[0] : '.csv'}
          ref={inputRef}
        />
      </div>
      </CardBody>
    </Card>
  );
};

export default CustomDragAndDrop;
