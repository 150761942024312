import React from 'react'
import { Tooltip } from '@mui/material'
import { BsThreeDotsVertical } from 'react-icons/bs'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { allocationNotAllowedTooltip, getBatchCreationTimeDiff } from '../constant/allocate-students'
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper'

const LoginSVG = ({ onClick = () => { } }) => {
  return (
    <svg onClick={onClick} style={{ cursor: 'pointer' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <path d="M15.8125 12.0217H3.77148" stroke="#00B0F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.8848 9.10571L15.8128 12.0217L12.8848 14.9377" stroke="#00B0F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.50439 7.38897V6.45597C8.50439 4.42097 10.1534 2.77197 12.1894 2.77197H17.0734C19.1034 2.77197 20.7484 4.41697 20.7484 6.44697V17.587C20.7484 19.622 19.0984 21.272 17.0634 21.272H12.1784C10.1494 21.272 8.50439 19.626 8.50439 17.597V16.655" stroke="#00B0F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

const DisabledLoginSVG = () => {
  return (
    <svg style={{ cursor: 'not-allowed' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.8125 12.0217H3.77148" stroke="#CCCCCC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.8848 9.10571L15.8128 12.0217L12.8848 14.9377" stroke="#CCCCCC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.50391 7.38897V6.45597C8.50391 4.42097 10.1529 2.77197 12.1889 2.77197H17.0729C19.1029 2.77197 20.7479 4.41697 20.7479 6.44697V17.587C20.7479 19.622 19.0979 21.272 17.0629 21.272H12.1779C10.1489 21.272 8.50391 19.626 8.50391 17.597V16.655" stroke="#CCCCCC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

const AllocateStudentActions = ({ row, setOpenDialog, setId }) => {

  const createdOn = useSelector(state => state?.allocateStudents?.batchDetails?.createdOn);

  return (
    <div>
      {
        getBatchCreationTimeDiff(createdOn) > 5 ? (
          <Tooltip title="Allocate Student">
            <span>
              {row?.isEligibleProgramActionForAllocation 
              ? (
                <IconButtonWrapper
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenDialog(true);
                  setId(row.applicationId)
                }}>
                   <LoginSVG
                  
                />
                </IconButtonWrapper>
              ) : (
                <IconButtonWrapper >
                  <DisabledLoginSVG />
                </IconButtonWrapper>
                
              )
              }
            </span>
          </Tooltip>
        ) : (
          <Tooltip title={allocationNotAllowedTooltip}>
            <span>
              {row?.isEligibleProgramActionForAllocation ? (
                <IconButtonWrapper>
                  <LoginSVG style={{cursor: 'not-allowed'}} />
                </IconButtonWrapper>
                
              ) : (
                <IconButtonWrapper>
                  <DisabledLoginSVG />
                </IconButtonWrapper>
                
              )}
            </span>
          </Tooltip>
        )}
      {/* <Tooltip title="More Options">
        <span><BsThreeDotsVertical style={REACT_ICONS_STYLE} /></span>
      </Tooltip> */}
    </div>
  )
}

export default AllocateStudentActions