import React from "react";
import { ReactComponent as Refresh } from "assets/img/svg/Group 29.svg";

import { Card, CardBody } from "reactstrap";
import AButton from "../../../common/form-fields-mui/AButton";
import { MdArrowForwardIos } from "react-icons/md";

// this method is also used in masters and mapping landing screen
export const getScreenCards = (cards = []) => {
  return cards.map((card, index) => (
    <Card key={index} style={{ marginBottom: "0px" }}>
      <CardBody className="d-flex flex-column justify-content-between">
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <span className="heading-4 semi-bold color-black">
              {card.heading}
            </span>
            <span>{card.subHeading}</span>
          </div>
          <div>{card.icon}</div>
        </div>
        <AButton
          variant="link"
          className="btn-left-0 mt-2 d-flex align-self-start align-items-center cursor"
          onClick={() => card?.action()}
          disabled={card?.disabled}
          {...card?.buttonProps}
        >
          <span className="heading-4 mr-2">Click Here</span>
          <MdArrowForwardIos size={22} />
        </AButton>
      </CardBody>
    </Card>
  ));
};
const SearchCard = ({
  disabledModulesDetails,
  stockReturnClickHandler,
  stockReversalClickHandler,
  stockTxnHandler,
  stockConsumptionHandler,
  stockConsumptionReturnHandler,
  downloadReportsClickHandler,
  cardsData = [],
  issueStockMiscHandler,
  openFailedTransationsList,
}) => {
  const cards = [
    {
      heading: "Stock return",
      subHeading: "Return stock that has been issued",
      icon: <Refresh />,
      action: stockReturnClickHandler,
      disabled: disabledModulesDetails?.["RETURN"],
    },
    {
      heading: "Transaction history",
      subHeading: "View transaction history of all issue",
      icon: <Refresh />,
      action: stockTxnHandler,
      disabled: disabledModulesDetails?.["HISTORY"],
    },
    {
      heading: "Download reports",
      subHeading: "Ageing report, Current stock availability etc.",
      icon: <Refresh />,
      action: downloadReportsClickHandler,
    },
    {
      heading: "Stock reversal",
      subHeading: "Reverse the stock issued by branch",
      icon: <Refresh />,
      action: stockReversalClickHandler,
    },
    {
      heading: "Branch consumption",
      subHeading: "Issue stock to employee",
      icon: <Refresh />,
      action: stockConsumptionHandler,
      disabled: disabledModulesDetails?.["BRANCH_CONSUMPTION"],
    },
    {
      heading: "Branch consumption return",
      subHeading: "Return the stock issued to employee",
      icon: <Refresh />,
      action: stockConsumptionReturnHandler,
      disabled: disabledModulesDetails?.["BRANCH_CONSUMPTION_RETURN"],
    },
    {
      heading: "Issue stock for misc. course ID",
      subHeading: "Issue stock against a misc. course ID",
      icon: <Refresh />,
      action: issueStockMiscHandler,
    },
    // {
    //   heading: "Barcode reconciliation",
    //   subHeading: "Upload file to reconcile the barcode",
    //   icon: <LIST_ICON />,
    //   action: openFailedTransationsList,
    // },
  ];
  return (
    <div className="screen-cards-container">
      {getScreenCards(cardsData?.length === 0 ? cards : cardsData)}
    </div>
  );
};

export default SearchCard;
