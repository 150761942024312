import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Table,
  Button,
} from "reactstrap";
import { DocumentManagementHeader } from "../../../common/commonComponents/masterRow/masterRow";
import { openInNewTab } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { GetPagination } from "../../../common/commonComponents/pagination/pagination";
import { PermisionDenied } from "../../../common/commonComponents";
import CustomButton from "components/CustomButton";


const DocumentManagementTable = (props) => {
  const { documentArr, selectedPersonType, userPermissions } = props;
  const [showData, setShowData] = useState();
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: 2,
  });
  const pageSize = 10;

  const documentUploadHandler = (data) => {
    let n = pagination;
    if (data.length > pageSize) {
      n["nextPage"] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n["totalPage"] = Math.floor(data.length / pageSize);
    if (data.length % pageSize != 0) {
      n["totalPage"] += 1;
    }
    setpagination(n);
    console.log(n);
    setShowData(
      data.slice(
        pagination.currentPage * pageSize - pageSize,
        pagination.currentPage * pageSize
      ) || []
    );
  };

  useEffect(() => {
    documentUploadHandler(documentArr);
  }, []);

  return (
    userPermissions ?
      <Container fluid={true}>
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft" data-testid="glHeading">
                  Document Upload
                </h3>
              </CardHeader>
              <hr />
              <CardBody className="p-0">
                <div id="questionTable" className="table-responsive">
                  <Table className="align-items-center table-flush tableLayout">
                    <DocumentManagementHeader
                      selectedPersonType={selectedPersonType}
                    />
                    <tbody className="list">
                      {showData?.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">
                            {item["documentUserId"]}
                          </td>
                          {selectedPersonType?.toUpperCase() === "STUDENT" ? (
                            <td className="text-center">
                              {item["applicationId"]}
                            </td>
                          ) : null}
                          <td className="text-center">
                            {/* <Button
                              color="info"
                              size="sm"
                              type="button"
                              onClick={() =>
                                openInNewTab(`/view/${item["documentUserId"]}/${item["applicationId"]}`)
                              }
                            >
                              <i className="fas fa-eye"></i>
                            </Button>
                            <Button
                              color="info"
                              size="sm"
                              type="button"
                              onClick={() =>
                                openInNewTab(`/edit/${item["documentUserId"]}/${item["applicationId"]}`)
                              }
                            >
                              <i className="fas fa-edit" />
                            </Button> */}
                            <CustomButton
                              className={'mx-1'}
                              forTable={true}
                              permissionType={'R'}
                              icon={true}
                              onClick={() => openInNewTab(`/view/${item["documentUserId"]}/${item["applicationId"]}`)}
                              permissionSet={userPermissions}
                            />

                            <CustomButton
                              forTable={true}
                              className={'mx-1'}
                              permissionType={'U'}
                              icon={true}
                              onClick={() => openInNewTab(`/edit/${item["documentUserId"]}/${item["applicationId"]}`)}
                              permissionSet={userPermissions}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {pagination.totalPage > 1 ? (
                    <GetPagination
                      setclassArray={setShowData}
                      pagination={pagination}
                      setpagination={setpagination}
                      pageSize={pageSize}
                      state={documentArr}
                    />
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container> : <PermisionDenied />
  );
};

export default DocumentManagementTable;
