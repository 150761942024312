import axios from "./axios";
import moment from "moment";
import { endpoint } from "../../common/constant";
import {
  fetchAllPostPromisedData,
  fetchAllPromisedData,
} from "../../common/utils/methods/commonMethods/utilityMethod";
import { getIdsArray } from "views/pages/manage/edpOps/constant/common";

export const SEARCH_BATCH_TABLE_HEADERS = {
  ID: "ID",
  BATCH_NAME: "Batch Name",
  CAPACITY: "Strength/Capacity",
  TYPE: "Type",
  ENROLLMENT: "Enrollment",
  MS_TEAM_SEQUENCE: "MS Teams Sequence",
  START_DATE: "Start Date",
  END_DATE: "End Date",
  STATUS: "Status",
};
export const ENROLLMENT_DROPDOWN = [
  { label: "Open", value: "OPEN" },
  { label: "Close", value: "CLOSED" },
];

export const STATUS_DROPDOWN = [
  { label: "Active", value: "ACTIVE" },
  { label: "Inactive", value: "INACTIVE" },
];
//
export const KEY_TO_DISPLAY = {
  OPEN: "Open",
  CLOSED: "Close",
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  PRIMARY: "Primary",
  SECONDARY: "Secondary",
};

export const REACT_ICONS_STYLE = {
  color: "#00B0F5",
  height: "1.2rem",
  width: "1.2rem",
  cursor: "pointer",
  margin: ".5rem .5rem",
};

export const dropdownMapping = async (url, displayLabel) => {
  const responseData = await fetchAllPromisedData(url, false);
  if (displayLabel == "courseIds") {
    const result = responseData?.map((o) => {
      return { label: o, value: o };
    });
    return result;
  }
  if (displayLabel == "buAndTermCourse") {
    const result = responseData?.courseIds?.map((o) => {
      return { label: o, value: o };
    });
    return result;
  }
  if (displayLabel == "examBoardTypeDispValue") {
    const result = responseData?.map((o) => {
      return {
        label: `${o[displayLabel]} - ${o["examBoardTypeDesc"]}`,
        value: o["id"],
      };
    });
    return result;
  }
  if (Array.isArray(responseData)) {
    const result = responseData?.map((o) => {
      return { label: o[displayLabel], value: o["id"] };
    });
    return result;
  } else return [];
};

export const getBusinessAreas = async (ids) => {
  const url = endpoint.businessArea.getAllActiveBusinessAreaByRegions;
  const responseData = await fetchAllPostPromisedData(url, {
    regionId: getIdsArray(ids),
  });
  if (Array.isArray(responseData?.data)) {
    const result = responseData.data?.map((o) => {
      return { label: o["businessAreaDispValue"], value: o["id"] };
    });
    return result;
  }
  return [];
};
export const getDropdowns = async () => {
  const p = await Promise.all([
    dropdownMapping(endpoint.term.getAllActive, "termDispValue"),
    dropdownMapping(
      endpoint.academic_career.getAllActive,
      "academicCareerDispValue"
    ),
    dropdownMapping(
      endpoint.academic_group.getAllActive,
      "academicGroupDispValue"
    ),
    // dropdownMapping(endpoint.region.getAllActive, 'regionDispValue'),
    dropdownMapping(
      endpoint.academicPhase.getAllActive,
      "academicPhaseDisplayValue"
    ),
    dropdownMapping(
      endpoint.academic_year.getAllActive,
      "academicYearDisplayValue"
    ),
    dropdownMapping(
      endpoint.examBoardType.getAllActive,
      "examBoardTypeDispValue"
    ),
    dropdownMapping(endpoint.classType.getAllActive, "classTypeDispValue"),
    dropdownMapping(
      endpoint.languageMaster.getActiveLanguageMaster,
      "languageDispValue"
    ),
    dropdownMapping(
      `${
        endpoint.classSection.getAllClassSectionsByClassSectionType
      }${"PRIMARY"}`,
      "classSectionDispValue"
    ),
    dropdownMapping(
      `${
        endpoint.classSection.getAllClassSectionsByClassSectionType
      }${"SECONDARY"}`,
      "classSectionDispValue"
    ),
    dropdownMapping(
      endpoint.courseCatelogMapping.getAllActiveCourseIds,
      "courseIds"
    ),
    dropdownMapping(
      endpoint.businessArea.getAllActive,
      "businessAreaDispValue"
    ),
  ]);
  return {
    termData: p[0],
    academicCareerData: p[1],
    academicGroupData: p[2],
    academicPhaseData: p[3],
    academicYearData: p[4],
    schoolBoardData: p[5],
    classTypeData: p[6],
    mediumData: p[7],
    classSectionData: [...p[8], ...p[9]],
    courseIdData: p[10],
    businessAreas: p[11],
    enrollment: ENROLLMENT_DROPDOWN,
    status: STATUS_DROPDOWN,
  };
};

export const reasonAndBusinessAreaModal = {
  region: [],
  businessArea: [],
};

export const getAFilterModal = (batchIds = [], academicPlanIds = []) => {
  return {
    batchEnrollmentStatus: "",
    batchName: "",
    term: [],
    batchStatus: "",
    classSection: "",
    courseId: [],
    academicCareer: "",
    academicGroup: "",
    academicPhase: [],
    academicYear: "",
    batchClass: [],
    batchType: "",
    medium: "",
    schoolBoard: "",
    classType: "",
    batchStartFromDate: "",
    batchStartToDate: "",
    ids: batchIds || [],
    academicPlanIds: academicPlanIds || [],
  };
};

export const getAdvFilterModal = () => {
  return {
    classSection: "",
    academicCareer: "",
    academicGroup: "",
    academicPhase: [],
    academicYear: "",
    batchClass: [],
  };
};

export const isFilterApplied = (dataSet, skipKeys = []) => {
  let count = 0;
  for (let item in dataSet) {
    if (!skipKeys.includes(item) && dataSet[item].toString().length > 0) {
      if (!["businessArea", "region", "batchName"].includes(item)) count++;
    }
  }

  return count;
};

export const getFormattedDate = (date) => {
  return moment(date).format("DD-MMM-YY");
};

export const businessAreaDropdowns = (items) => {
  return items.map((item) => {
    return { value: item.id, label: item.businessAreaKey };
  });
};

export const CSV_HEADERS = [
  {
    label: "Batch Type",
    key: "batchType",
  },
  {
    label: "Enrollment",
    key: "enrollmentStatus",
  },
  {
    label: "Status",
    key: "status",
  },
  {
    label: "Start Date",
    key: "startDate",
  },
  {
    label: "End Date",
    key: "endDate",
  },
];

export const getDisplayValue = (ids, dropdowns, dropdownKey) => {
  const displayValue = [];
  if (Array.isArray(ids)) {
    ids.map((id) => {
      if (dropdownKey) {
        dropdowns[dropdownKey].map((item) => {
          if (item.value === id) {
            displayValue.push(item.label);
          }
        });
      }
    });
  }
  if (Array.isArray(dropdowns) && Array.isArray(ids)) {
    ids.map((id) => {
      dropdowns.map((item) => {
        if (item.value === id) {
          displayValue.push(item.label);
        }
      });
    });
  }

  return displayValue;
};

export const getAcademicPhaseValue = (data, isPhaseOnly = false) => {
   if(isPhaseOnly)
   return data?.filter(item=>(item.label).startsWith('Phase'))

   return data?.filter(item=>!(item.label).startsWith('Phase'))
};
