import React from "react";
import { Col } from "reactstrap";
import CommonInput from "../../common/formFeilds/input/commonInput";
import { regex } from "../../common/constant";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import CustomChip from "views/pages/manage/common/customChip/CustomChip";
// import { MasterHeaderAuto } from "../../../../common/commonComponents/masterHeader/masterHeader";
// import { isEqual } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
// import { constants } from "../../../../common/constant";

export const FieldsRenderer = ({
    fields,
    fieldsObject,
    values,
    dataObjects,
    isPreviewEnable,
    disabled,
    isEditMode = false,
    setValues = () => {},
    isEmployee,
    size,
    verifyEmpId,
    isEmpIdVerified,
    setEmpIdVerified
}) => {
    return (
        fields.map((field,index) => {
            return (
              <Col
                className="mb-3"
                sm={6}
                xs={12}
                md={size ?? 4}
                lg={size ?? 3}
                key={index}
              >
                {fieldsObject[field]["type"] === "button" ? (
                  <>
                    {!isEmpIdVerified && (
                      <AButton
                        variant="outline"
                        size="xs"
                        onClick={() => verifyEmpId()}
                        className="ml-0"
                        disabled={values?.userProfileTypeId?.value != 2}
                        updatedStyle={{marginTop:'35px'}}
                      >
                        Verify ID
                      </AButton>
                    )}

                    {isEmpIdVerified && (
                      <span >
                        <CustomChip
                          bgColor={"#BBE2CE"}
                          textColor={"#0B442D"}
                          text="Verified"
                          style={{marginTop:'35px'}}
                        />
                      </span>
                    )}
                  </>
                ) : (
                  <CommonInput
                    type={fieldsObject[field]["type"]}
                    id={`user-profile-${field}`}
                    placeHolder={
                      isEmployee && fieldsObject[field]["disabledForEmployee"]
                        ? ""
                        : fieldsObject[field]["placeholder"] ?? ""
                    }
                    key={`manual-fee-setup-${field}`}
                    label={fieldsObject[field]["label"] ?? ""}
                    mandatory={
                      isEmployee && fieldsObject[field]["disabledForEmployee"]
                        ? false
                        : fieldsObject[field]["mandatory"]
                    }
                    defaultValue={
                      fieldsObject[field]["dependedValue"] &&
                      values[fieldsObject[field]["dependedValue"]]
                        ? values[fieldsObject[field]["dependedValue"]][field]
                        : typeof values[field] == "object" &&
                          field === "employeeId"
                        ? values[field]?.value
                        : values[field]
                    }
                    onChange={(val) => {
                      setValues({
                        ...values,
                        [field]: fieldsObject[field]["inCaps"]
                          ? val.toString().toUpperCase()
                          : val,
                      });
                      field == "employeeId" && setEmpIdVerified(false);
                    }}
                    isDisabled={
                      (fieldsObject[field]["disabledForEmployee"] &&
                        isEmployee) ||
                      fieldsObject[field]["disabled"] ||
                      isPreviewEnable ||
                      disabled ||
                      (fieldsObject[field]["dependedOn"] &&
                      !values[fieldsObject[field]["dependedOn"]]
                        ? true
                        : false) ||
                      (fieldsObject[field]["disabledInEdit"] && isEditMode
                        ? true
                        : false)
                    }
                    data={
                      dataObjects?.[fieldsObject?.[field]?.["data"]]?.["data"]
                    }
                    loading={
                      fieldsObject[field]["type"] === "select" &&
                      dataObjects[fieldsObject[field]["data"]]?.["fetching"]
                    }
                    maxLength={fieldsObject[field]["max"] ?? 50}
                    onKeyPress={
                      fieldsObject[field]["regex"]
                        ? (e) => {
                            if (
                              !regex[fieldsObject[field]["regex"]].test(
                                String.fromCharCode(e.charCode)
                              )
                            ) {
                              e.preventDefault();
                              return;
                            }
                          }
                        : () => {}
                    }
                    maxDate={new Date()}
                    minDate={new Date("04-01-2022")}
                    // style={fieldsObject[field]['inCaps'] ? {textTransform : 'uppercase'} : {}}
                  />
                )}
              </Col>
            );
        })
    )
}