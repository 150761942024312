import React, {useEffect, useState } from 'react'
import { endpoint } from '../common/constant';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { fetchAllPostPromisedData,fetchAllPromisedData } from '../common/utils/methods/commonMethods/utilityMethod';
import CustomLoader from '../common/commonComponents/Loader/loader';
import { useHistory } from "react-router";
import AAutoComplete from '../common/form-fields-mui/AAutoComplete';
import './report-styles.scss'
const ReportCategory = () => {
    const [category, setCategory] = useState('');
    const history=useHistory();
    
     // all 
    const [tableData, setTableData] = useState([]);
    // filtered data
    const [reportCategories, setReportCategories] = useState([
     
         { report: 'BULK NOTIFICATION REPORTS', id: 1 },
        //  { report: 'MARKETING CAMPAIGN PERFORMANCE REPORT', id: 2 },
        //  { report: 'FINANCIAL STATEMENTS REPORT', id: 3 },
        //  { report: 'CUSTOMER SATISFACTION SURVEY REPORT', id: 4 },
        //  { report: 'INVENTORY MANAGEMENT REPORT', id: 5 }
       
    ]);

    const [categoryData, setCategoryData] = useState([
       
    ]);

  useEffect(() => {
    // fetchReportCategories();
    // fetchTableData(1);
  });

    const fetchReportCategories=async()=>{
      try{
        const response=await fetchAllPromisedData(endpoint?.getAllReportCategory,true);
    // return response;
    if(response?.code===200){
      const datA = response.data;
      setCategoryData(datA.map(item => ({
      id: item.id,
      label: item.templateCategoryType
      })));
    }
      }catch (error) {
        console.error('Error:', error);
    }
  }

    const fetchTableData = async (id) => {
      try {
        const response = await fetchAllPostPromisedData(endpoint?.reportCategory,{"categoryId":id}) 
      } catch (error) {
        console.error('Error:', error);
      }
    };

   

    
    const [apiLoader, setApiLoader] = useState(false);
      const reset = () => {
        setCategory('');
        setTableData([]);
        setApiLoader(false);
      }
    return (  
            <Container fluid className='mt-3'>
              <Row>
                <div className="col">
                  {/* <Card>
                    <CardHeader className='d-flex justify-content-between'>
                      <h3 className="mb-0">Reports</h3>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="mb-3" md="3">
                          <AAutoComplete
                            label={'Category'}
                            isMulti = {false}
                            placeHolder={'Select Category'}
                            currentValue = {category}
                            items={categoryData}
                            labelStyle={{color: "#00B0F5", fontSize: "14px", fontWeight:'800px !important',padding:'2px'}}
                            handleChange={(e) => {
                               let filteredData = reportCategories.filter(item=>e.id === item.id)
                              setTableData(filteredData)
                              console.log(e, categoryData);
                              setCategory(e);
                            }
                          }
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card> */}
                  {apiLoader ? (
                    <Card>
                      <CustomLoader apiLoader={apiLoader} />
                    </Card>
              
               ) :(
                <Card className="mt-4">
    
      
        
        <CardHeader className="d-flex justify-content-between align-items-center">
          <h3 className="mb-0">Reports</h3>
        </CardHeader>
        
        {
         reportCategories.map(item=>(
          <div
          key={item.id} 
          onClick={()=>history.push(`/admin/reports/reportlist/${item.id}/${item.report}`)}
          className='report-category-table'
          >
            {item.report}
          </div>
        ))
      }
      
      
      </Card>
            )}
            </div>
            </Row>
            </Container>
          
    );
}

 
export default ReportCategory;