import moment from "moment";
import { endpoint } from "../../common/constant";
import { failureToast } from "../../common/utils/methods/toasterFunctions/function";

const { fetchAllPromisedData, getSelectArrayFromApiResArray, fetchAllPostPromisedData, updateRequest, fetchDeletePromisedData } = require("../../common/utils/methods/commonMethods/utilityMethod")

export const getMomentObjFromTimeString = (timeString) => timeString ? moment(`${timeString}`, 'HH:mm:ss') : null;

export const dropdownMapping = async (url, dispLabel, idLabel) => {
  const res = await fetchAllPromisedData(url, false);
  if (Array.isArray(res)) {
    return res?.map(item => ({ label: item?.[dispLabel], value: item?.[idLabel] }));
  }
  else {
    return [];
  }
}

export const daysDropdownData = [
  { label: 'MONDAY', value: 'MONDAY' },
  { label: 'TUESDAY', value: 'TUESDAY' },
  { label: 'WEDNESDAY', value: 'WEDNESDAY' },
  { label: 'THURSDAY', value: 'THURSDAY' },
  { label: 'FRIDAY', value: 'FRIDAY' },
  { label: 'SATURDAY', value: 'SATURDAY' },
  { label: 'SUNDAY', value: 'SUNDAY' },
]

export const createInitialForm = {
  daysPlanName: '',
  regions: [],
  businessAreas: [],
  plans: [],
  status: "ACTIVE",
}

export const planRowInitialForm = {
  subject: '',
  planDay: null,
  sessionName: '',
  startTime: '',
  endTime: '',
  status: 'ACTIVE',
}

export const searchFilterInitialForm = {
  daysPlanName: '',
  startTime: '',
  endTime: '',
  regions: [],
  businessAreas: [],
  active: false,
  inActive: false,
}

export const editPageInitialForm = (planData) => ({
  daysPlanName: planData?.daysPlanName,
  regions: getSelectArrayFromApiResArray(planData?.regions),
  businessAreas: getSelectArrayFromApiResArray(planData?.businessAreas),
})

export const createDaysPlan = (requestPayload) => fetchAllPostPromisedData(`${endpoint?.daysPlan?.create}`, updateRequest(requestPayload), 'post');

export const updateDaysPlan = (requestPayload, daysPlanId) => fetchAllPostPromisedData(`${endpoint?.daysPlan?.update}/${daysPlanId}`, updateRequest(requestPayload), 'put');

export const getDaysPlanById = (daysPlanId) => fetchAllPromisedData(`${endpoint?.daysPlan?.getById}/${daysPlanId}?requireDispValue=true`, true);

export const deleteDaysPlan = daysPlanId => fetchDeletePromisedData(`${endpoint?.daysPlan?.delete}/${daysPlanId}`);

export const getDaysPlanList = (requestPayload, page = 0) => fetchAllPostPromisedData(`${endpoint?.daysPlan?.search}/${page}/?requireDispValue=true`, updateRequest(requestPayload), 'post');

const formInfo = {
  regions: 'Array',
  businessAreas: 'Array',
  startTime: 'String',
  endTime: 'String',
  active: 'Boolean',
  inActive: 'Boolean',
}

export const getFilterCount = (dataSet) => {
  let cnt = 0;
  for (let item in dataSet) {
    if (formInfo[item] === 'String') {
      if (dataSet[item]) cnt++;
    }
    if (formInfo[item] === 'Array') {
      if (dataSet[item].length) cnt++;
    }
    if (formInfo[item] === 'Boolean') {
      if (dataSet[item]) cnt++;
    }
  }
  return cnt;
}

const validInterval = (existingStartTime, existingEndTime, startTime, endTime) => {
  if (getMomentObjFromTimeString(startTime)?.isSameOrBefore(getMomentObjFromTimeString(existingStartTime)) && getMomentObjFromTimeString(endTime)?.isAfter(getMomentObjFromTimeString(existingStartTime))) {
    return false;
  } else if (getMomentObjFromTimeString(endTime)?.isSameOrAfter(getMomentObjFromTimeString(existingEndTime)) && getMomentObjFromTimeString(startTime)?.isBefore(getMomentObjFromTimeString(existingEndTime))) {
    return false;
  } else if (getMomentObjFromTimeString(startTime)?.isBetween(getMomentObjFromTimeString(existingStartTime), getMomentObjFromTimeString(existingEndTime)) && getMomentObjFromTimeString(endTime)?.isBetween(getMomentObjFromTimeString(existingStartTime), getMomentObjFromTimeString(existingEndTime))) {
    return false;
  }
  return true;
}

export const validateRowData = (plans = [], rowData) => {
  // start time and end time validation for row
  if (getMomentObjFromTimeString(rowData?.startTime)?.isSameOrAfter(getMomentObjFromTimeString(rowData?.endTime))) {
    failureToast('Session start time should be less than end time');
    return false;
  }
  // overlapping interval check here
  for (let i = 0; i < plans?.length; i++) {
    let plan = plans?.[i];
    if (rowData?.id !== plan?.id && plan?.planDay == rowData?.planDay && !validInterval(plan?.startTime, plan?.endTime, rowData?.startTime, rowData?.endTime)) {
      failureToast('Selected start time and end time interval coincides with existing intervals. Please select valid time interval to proceed.')
      return false;
    }
  }
  return true;
}