import React, { useEffect} from "react";
import { Button, Input } from "reactstrap";
import Select2 from "react-select2-wrapper";
import {
    masterServiceBaseUrl,
    failureToast,
    getAPI,
} from "../../../../../../services/http";
import UpdateButton from "components/FormComponent/Button/Update/index";
import { ButtonText } from "variables/Buttons";

const BuildingForm = (props) => {
  const { data, msData, setMsData = () => {}, index, saveRow = () => {}, cancelRow = () => {}, isSaveVisible, coreMasterData = [] } = props;

  // const getPincodeInfo = (id) => {
  //   if(id){
  //       getAPI(`${masterServiceBaseUrl}/pincode/getPincodeByCity/${id}`,
  //       (data) => {
  //           setTimeout(3000)
  //           setMsData({ ...msData, stateId : data.data?.[0]?.stateId, pincode : data.data?.[0]?.id, country : data.data?.[0]?.countryId })
  //       },
  //       (data) => { failureToast(data['message']) })
  //   }
  // }

  return (
    <tr key={data["id"] + "-class"}>
      <td className="text-center p-2 mx-1">
        <Input defaultValue={msData.sequence} onChange={(e) => setMsData({ ...msData, sequence: e.target.value.trim() })} placeholder="Sequence" id="ms_group_sequence_sequence" style={{ height: "38px" }} maxLength="50" />
      </td>
      <td className="text-center p-2 mx-1">
        <Select2
          defaultValue={msData.coreMasterId}
          data={coreMasterData}
          onChange={(e) => {
            setMsData({ ...msData, coreMasterId: e.target.value });
          }}
          options={{ placeholder: "Please Select Core Master" }}
          id="ms_group_sequence_core_master_id"
        />
      </td>

      <td className="text-center p-2 mx-1">
        <UpdateButton text={ButtonText.MSGroupSequence.save} onClick={() => saveRow(index)} />
        <Button
          color="danger"
          size="sm"
          type="button"
          onClick={() => {
            cancelRow(index);
          }}
        >
          <i className="fas fa-times" />
        </Button>
      </td>
    </tr>
  );
};

export default BuildingForm;
