import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { ReactComponent as CloseIcon } from "../../../../../../assets/img/svg/CloseIcon.svg";
import { ReactComponent as ActiveControl } from "../../../../../../assets/img/svg/ActiveControl.svg";
import { ReactComponent as InactiveControl } from "../../../../../../assets/img/svg/InactiveControl.svg";
import CustomDatePickerV2 from "../../../common/commonComponents/CustomDatePicker-V2";
import moment from "moment";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import AAutoComplete from "../../../common/form-fields-mui/AAutoComplete";
import {
  DUPLICATE_LECTURE_ERROR_MESSAGE,
  checkDuplicateLecture,
  getDuplicateErrorMessage,
  maxDate31Days,
  uuidValidateV4,
} from "../helper";
import AButton from "../../../common/form-fields-mui/AButton";
import { saveLectureValidator, subjectDropdown } from "./EditLectureForm";
import { useFetchSubjectLectureDropdown } from "../hooks";
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import SelectLecturePopover from "./selectLecture/SelectLecturePopover";

export const classTypeDropdown = [
  {
    value: "LECTURE",
    label: "Lecture",
  },
  {
    label: "Doubt class",
    value: "DOUBT CLASS",
  },
  {
    label: "Extra Class",
    value: "EXTRA CLASS",
  },
];
export const timepickerStyle = {
  "& .MuiInputBase-root": {
    height: "40px",
    // borderRadius: '8px'
  },
  "& .MuiInputBase-input": {
    padding: "7px 8px",
    fontSize: "12px",
  },
  "& .MuiInputAdornment-root": {
    display: "none",
  },
};

const resetFormFields = {
  date: "",
  startTime: "",
  endTime: "",
  classType: "",
  subject: "",
  faculty: "",
  lecture: "",
  room: "",
};

export const getSubjectFaculty = (
  selectedFaculty,
  facultyDropdown,
  subject
) => {
  const selectedFacultyParsed = selectedFaculty
    ? JSON.parse(selectedFaculty)
    : "";
  if (!selectedFacultyParsed || !subject) return "";
  const facultyId = selectedFacultyParsed[subject];
  const facultyList = facultyDropdown?.filter(
    (item) => item?.value === facultyId
  );

  return facultyList.length > 0 ? facultyList[0] : "";
};

const getClassTypeObj = (formType) => {
  if (formType.toLowerCase() === "doubt class") {
    return classTypeDropdown[1];
  }
  if (formType.toLowerCase() === "extra class") {
    return classTypeDropdown[2];
  }

  return classTypeDropdown[0];
};
const AddLectureModalForm = ({
  isOpen,
  onClose,
  batchDetails,
  planSubjects,
  roomsDropdown,
  subjectFacultyDropdown,
  formData,
  setLectures,
  openMode,
  setIsNeedToPublish,
  isEnabledMsTeamsSync,
  lectures,
  saveOnEdit = () => {},
  formType,
  selectedFaculty,
  ...props
}) => {
  const FormField = {
    date: formData?.date || "",
    startTime: formData?.startTime || "",
    endTime: formData?.endTime || "",
    classType: "",
    subject: "",
    faculty: "",
    lecture: "",
    room: "",
  };

  const subjectsDropdown = subjectDropdown(planSubjects);

  const [toggleState, setToggleState] = useState(
    formData?.isMSteams ? formData.isMSteams : isEnabledMsTeamsSync
  );
  const [form, setForm] = useState({
    ...FormField,
    classType: getClassTypeObj(formType),
  });
  const lecturesDropdown = useFetchSubjectLectureDropdown(
    formData?.plan?.value,
    formData?.duration?.value,
    form?.subject?.label
  );

  useEffect(() => {
    if (formData.subject) {
      const prefilledSubject = subjectsDropdown.filter(
        (item) => item.label.toLowerCase() === formData.subject.toLowerCase()
      )[0];

      setTimeout(() => {
        // setForm((prev) => ({ ...prev, subject: prefilledSubject }));
        formHandler(prefilledSubject, "subject");
      }, 1500);
    }
  }, [formData.subject]);

  useEffect(() => {
    if (formData.lecture) {
      const prefilledLecture = lecturesDropdown.filter(
        (item) => item.value === formData.lecture
      )[0];

      if (prefilledLecture) {
        setForm({
          ...form,
          lecture: prefilledLecture,
          faculty: getSubjectFaculty(
            JSON.stringify(formData.faculty),
            subjectFacultyDropdown[form.subject.label],
            form.subject.label,
            formData.faculty.value
          ),
          room: formData.room,
        });
      }
    }
  }, [lecturesDropdown]);

  useEffect(() => {
    setForm({ ...FormField, classType: getClassTypeObj(formType) });
  }, [isOpen]);

  const formHandler = (value, key) => {
    if (key === "subject") {
      setForm((prev) => ({ ...prev, [key]: value }));
      setForm((prev) => ({ ...prev, ["lecture"]: "" }));
      setForm((prev) => ({
        ...prev,
        ["faculty"]: getSubjectFaculty(
          selectedFaculty,
          subjectFacultyDropdown[value?.label],
          value?.label
        ),
      }));
    } else {
      setForm((prev) => ({ ...prev, [key]: value }));
    }
  };

  const getLectureRequest = (id) => {
    return {
      ...form,
      timeTableClassType: form?.classType?.value,
      classType: form?.classType?.value,
      subject: form?.subject?.label,
      roomName: form?.room?.label,
      roomId: form?.room?.value,
      lectureId: form?.lecture?.value,
      lectureName: form?.lecture?.lectureName || form?.lecture?.label,
      lectureCode: form?.lecture?.lectureCode,
      facultyName: form?.faculty?.label,
      facultyId: form?.faculty?.value,
      msTeam: {
        value: toggleState ? "Yes" : "No",
        label: toggleState ? "Yes" : "No",
      },
      isEdit: false,
      isNew: true,
      id: id || uuidv4(),
      isTimeTableEvent: true,
      status: "ACTIVE",
    };
  };

  const editLecture = () => {
    const editedRow = lectures[formData?.selectedIndex];

    props.updateLectures(
      formData?.selectedIndex,
      {
        ...editedRow,
        ...form,

        timeTableClassType: form?.classType?.value,
        classType: form?.classType?.value,
        subject: form?.subject?.label,
        roomName: form?.room?.label,
        roomId: form?.room?.value,
        lectureId: form?.lecture?.value,
        lectureName: form?.lecture?.lectureName || form?.lecture?.label,
        lectureCode: form?.lecture?.lectureCode,
        facultyName: form?.faculty?.label,
        facultyId: form?.faculty?.value,
        msTeam: {
          value: toggleState ? "Yes" : "No",
          label: toggleState ? "Yes" : "No",
        },
        isItemEdited: true,
      },
      true
    );

    if (openMode === "EDIT") {
      setIsNeedToPublish(true);
    }
    onCloseReset();
  };

  const editPublishedEvents = (selectedIndex = -1, id) => {
    const lecture = getLectureRequest(id);
    const tempLectures = [...lectures];
    tempLectures[selectedIndex] = {
      ...lecture,
      id,
    };
    setLectures(tempLectures);
    if (id)
      saveOnEdit(
        [{ ...lecture, id, isItemEdited: true }],
        true,
        false,
        undefined,
        []
      );
    onCloseReset();
  };

  const saveEvent = () => {
    if (!saveLectureValidator(form)) return false;
    if (form?.room === "") {
      failureToast("Please select a room");
      return;
    }

    if (uuidValidateV4(formData?.id)) {
      // if event made via calendar-> modify lectures array and not call API
      editLecture();
      return;
    } else if (formData?.id && !uuidValidateV4(formData?.id)) {
      //if event is already published & modified -> call API
      editPublishedEvents(formData.selectedIndex, formData.id);
      return;
    }

    const duplicateItem = checkDuplicateLecture(
      lectures,
      form,
      formData?.selectedIndex || -1
    );

    if (duplicateItem) {
      failureToast(getDuplicateErrorMessage(duplicateItem?.classType));
      return;
    }

    let lecture = getLectureRequest();
    setLectures((lectures) => [...lectures, lecture]);

    if (openMode === "EDIT") {
      setIsNeedToPublish(true);
    }
    onCloseReset();
  };

  const onCloseReset = () => {
    onClose();
    setForm(resetFormFields);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Modal
        size="lg"
        isOpen={isOpen}
        toggle={onCloseReset}
        style={{ width: "510px" }}
      >
        <ModalBody>
          <div className="d-flex justify-content-between align-items-start mb-4">
            <div className="heading-4 label-color text-bold">
              {formData.subject ? "Edit" : "Add"}{" "}
              {form?.classType?.value?.toLowerCase()}
            </div>
            <CloseIcon onClick={() => onCloseReset()} />
          </div>
          <div className="regular-large-bold color-dark mb-4">
            Batch: {batchDetails?.batchName}
          </div>
          <Row className="mb-2">
            <Col sm="4">
              <div className="d-flex flex-column">
                <div className="regular-large-bold color-dark mb-2">Date</div>
                <CustomDatePickerV2
                  placeHolder="Date"
                  value={form?.date}
                  minDate={moment()}
                  handler={(index, date) => formHandler(date, "date")}
                  height="32px"
                  maxDate={maxDate31Days()}
                />
              </div>
            </Col>
            <Col sm="4">
              <div className="d-flex flex-column">
                <div className="regular-large-bold color-dark mb-2">
                  Start time
                </div>
                <DesktopTimePicker
                  ampm={false}
                  value={
                    form?.startTime ? moment(form?.startTime, "HH:mm") : null
                  }
                  onChange={(value) =>
                    formHandler(moment(value)?.format("HH:mm"), "startTime")
                  }
                  sx={timepickerStyle}
                />
              </div>
            </Col>
            <Col sm="4">
              <div className="d-flex flex-column">
                <div className="regular-large-bold color-dark mb-2">
                  End time
                </div>
                <DesktopTimePicker
                  ampm={false}
                  value={form?.endTime ? moment(form?.endTime, "HH:mm") : null}
                  onChange={(value) =>
                    formHandler(moment(value)?.format("HH:mm"), "endTime")
                  }
                  sx={timepickerStyle}
                />
              </div>
            </Col>
          </Row>
          <div className="mb-2 d-flex flex-column">
            <div className="mb-2 regular-large-bold color-dark">Class type</div>
            <AAutoComplete
              placeholder={"Select"}
              items={classTypeDropdown}
              currentValue={form?.classType}
              handleChange={(value) => formHandler(value, "classType")}
              isMulti={false}
              isDisabled={true}
              style={{ control: { height: "40px" } }}
            />
          </div>
          <div className="mb-2 d-flex flex-column">
            <div className="mb-2 regular-large-bold color-dark">Subject</div>
            <AAutoComplete
              placeholder={"Select"}
              items={subjectsDropdown}
              currentValue={form?.subject}
              handleChange={(value) => formHandler(value, "subject")}
              isMulti={false}
              style={{ control: { height: "40px" } }}
            />
          </div>
          <div className="mb-2 d-flex flex-column">
            <div className="mb-2 regular-large-bold color-dark">
              Lecture name
            </div>
            <SelectLecturePopover
              {...{
                editForm: form,
                lecturesDropdown,
                editFormHandler: formHandler,
              }}
            />

            {/* <AAutoComplete
              placeholder={"Select"}
              items={lecturesDropdown}
              currentValue={form?.lecture}
              handleChange={(value) => formHandler(value, "lecture")}
              isMulti={false}
              style={{ control: { height: "40px" } }}
            /> */}
          </div>
          <div className="mb-2 d-flex flex-column">
            <div className="mb-2 regular-large-bold color-dark">Faculty</div>
            <AAutoComplete
              placeholder={"Select"}
              items={subjectFacultyDropdown?.[form?.subject?.label]}
              currentValue={form?.faculty}
              handleChange={(value) => formHandler(value, "faculty")}
              isMulti={false}
              style={{ control: { height: "40px" } }}
              isDisabled={form?.subject === ""}
            />
          </div>
          <div className="mb-2 d-flex flex-column">
            <div className="mb-2 regular-large-bold color-dark">Room</div>
            <AAutoComplete
              placeholder={"Select"}
              items={roomsDropdown}
              currentValue={form?.room}
              handleChange={(value) => formHandler(value, "room")}
              isMulti={false}
              style={{ control: { height: "40px" } }}
            />
          </div>
          {isEnabledMsTeamsSync && (
            <div className="d-flex align-items-center mb-4">
              <div className="regular-large-bold color-dark mr-3">MS teams</div>
              {toggleState ? (
                <>
                  <ActiveControl onClick={() => setToggleState(false)} />
                  <span className="ml-2">Yes</span>
                </>
              ) : (
                <>
                  <InactiveControl onClick={() => setToggleState(true)} />
                  <span className="ml-2">No</span>
                </>
              )}
            </div>
          )}
          <div>
            <AButton
              className="btn-left-0"
              variant="primary_filled"
              onClick={saveEvent}
            >
              Save event
            </AButton>
          </div>
        </ModalBody>
      </Modal>
    </LocalizationProvider>
  );
};

export default AddLectureModalForm;
