import { Checkbox, FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import { primaryCheckboxStyles } from '../../../issuanceIDcard/printIdCardAndMapRfid/constant'
import AAutoComplete from '../../../../common/form-fields-mui/AAutoComplete';
import AButton from '../../../../common/form-fields-mui/AButton';
import { getFilterCount, initialFilterForm } from './constant';

const SearchFilterForm = ({
  groupTypeDropdown,
  closeForm,
  filterCount,
  filterPayload,
  applyFilter = () => { },
}) => {

  const [filterForm, setFilterForm] = useState(filterPayload);
  const filterFormHandler = (value, key) => setFilterForm(prev => ({ ...prev, [key]: value }));
  useEffect(() => {
    setFilterForm(filterPayload);
  }, [filterPayload])

  return (
    <Card className='mb-0 regular-body label-color' style={{ width: '330px', borderRadius: '12px' }}>
      <CardBody className='d-flex flex-column'>
        <div className='mb-1 d-flex align-items-start justify-content-between'>
          <div className='regular-large-bold'>Filter by</div>
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <FormControlLabel
            className='flex-grow-1'
            disableTypography
            sx={{ marginBottom: "0px", ...primaryCheckboxStyles }}
            control={
              <Checkbox
                onChange={(e) => {
                  filterFormHandler(e?.target?.checked ? 'ACTIVE' : null, 'status');
                }}
                checked={filterForm?.status === 'ACTIVE'}
              />
            }
            label={<div>Active</div>}
          />
          <FormControlLabel
            className='flex-grow-1'
            disableTypography
            sx={{ marginBottom: "0px", ...primaryCheckboxStyles }}
            control={
              <Checkbox
                onChange={(e) => {
                  filterFormHandler(e?.target?.checked ? 'INACTIVE' : null, 'status');
                }}
                checked={filterForm?.status === 'INACTIVE'}
              />
            }
            label={<div>Inactive</div>}
          />
        </div>
        <AAutoComplete
          label="Group type"
          currentValue={filterForm?.groupTypeIds}
          items={groupTypeDropdown}
          handleChange={(value) => {
            filterFormHandler(value, 'groupTypeIds');
          }}
          isShowCount={true}
        />
        <div className='mt-3 d-flex flex-row-reverse justify-content-between'>
          <AButton
            variant='primary_filled'
            className='button_remove_left_margin'
            onClick={() => { closeForm(); applyFilter(filterForm); }}
            disabled={!getFilterCount(filterForm)}
          >
            Apply
          </AButton>
          {filterCount > 0 && (
            <AButton
              variant='outline'
              className='button_remove_left_margin'
              updatedStyle={{ border: "1px solid #CC5F5F", color: '#CC5F5F' }}
              onClick={() => { closeForm(); applyFilter(initialFilterForm); }}
            >
              Remove filter
            </AButton>
          )}
        </div>
      </CardBody>
    </Card>
  )
}

export default SearchFilterForm