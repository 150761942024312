import React,{ useState } from "react";
import { Input, Col } from "reactstrap";

const VendorForm = () => {
  const [vendorData, setVendorData] = useState({
    vendorType: { label: "Vendor Type", value: "" },
    vendorId: { label: "Vendor ID", value: "" },
    vendorName: { label: "Vendor Name", value: "" },
  });

  return (
    <div className="form-row mt-4">
      <Col>
        <label className="form-control-label">Vendor Type</label>
        <Input value={""} disabled className="tdInput" />
      </Col>
      <Col>
        <label className="form-control-label">Vendor ID</label>
        <Input value={""} disabled className="tdInput" />
      </Col>
      <Col>
        <label className="form-control-label">Vendor Name</label>
        <Input value={""} disabled className="tdInput" />
      </Col>
    </div>
  );
};

export default VendorForm;
