import React, { useContext } from 'react'
import { ReactComponent as EditIcon } from '../../../../../assets/img/svg/Edit2.svg'
import APopover from '../../common/a-popover/index'
import EditBranchesCommonComponent from '../../common/branchesCommonComponent/EditBranchesCommonComponent'
import ViewBranchesCommonComponent from '../../common/branchesCommonComponent/ViewBranchesCommonComponent'
import { pages } from '../../common/constant'
import { PermissionContext, RolePermissions } from 'appContext'
import { checkPermission } from '../../common/utils/methods/commonMethods/utilityMethod'
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper'

const editIconStyles = { height: '15px', width: '15px', cursor: 'pointer', marginLeft: '5px' }

const BranchesCellComponent = ({ params, planData, updateHandler }) => {
  const userPermissions = RolePermissions(useContext(PermissionContext), pages['searchAcademicPlan']['id']);

  const businessAreas = planData?.businessAreas
  const regions = planData?.regions

  return (
    <div>
      <span>
        {businessAreas?.[0]?.['dispValue']} <span style={{ color: '#00B0F5', marginLeft: '1px' }}>
          {businessAreas?.length > 1 ? (
            <APopover 
              buttonComponent={(onClick, styles) => <span onClick={onClick} style={{ cursor: 'pointer' }}>{`+${businessAreas?.length - 1} more`}</span>}
              menuComponent={(closeForm) => <ViewBranchesCommonComponent regions={regions} businessAreas={businessAreas} closeForm={closeForm} />}
            />
          ) : (
            ''
          )}
        </span>
        
        {checkPermission(userPermissions, 'U') && businessAreas?.length > 0 && (
          <APopover
            buttonComponent={(onClick, styles) => <IconButtonWrapper onClick={onClick}><EditIcon  style={editIconStyles} /></IconButtonWrapper>}
            menuComponent={(closeForm) =>
              <EditBranchesCommonComponent 
                closeForm={closeForm}
                regions={regions?.map(item => ({ label: item?.dispValue, value: item?.id }))}
                businessAreas={businessAreas?.map(item => ({ label: item?.dispValue, value: item?.id }))}
                updateHandler={(updatedForm) => updateHandler(planData, updatedForm)}
              />
            }
          />
        )}
      </span>
    </div>
  )
}

export default BranchesCellComponent