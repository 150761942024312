import React, {useState, useEffect, useContext} from 'react';
import { withRouter } from 'react-router-dom';
import {
	Card,
	CardHeader,
	Input,
	Container,
	Button,
	Table,
	Row,
	Col,
	UncontrolledCollapse,
	CardBody,
	Form,
	FormGroup,
} from 'reactstrap';
import { SimpleInput } from 'components/FormComponent/formcomponent';
import { useForm } from 'react-hook-form';
import {
	masterServiceBaseUrl,
	putpost,
	getAPI,
} from 'services/http';
import {
	successToast,
	failureToast,
} from '../../../common/utils/methods/toasterFunctions/function';
import { useHistory } from 'react-router-dom';
import { GetPagination } from '../../../common/pagination';
import { CourseCatalogDetails } from '../../../common/commonComponents/masterRow/masterRow';
import CommonInput from "../../../common/formFeilds/input/commonInput";
import {PermisionDenied, ToExcel} from "../../../common/commonComponents";
import {fetchDetails, getDispValForReactSelectByOneID} from "../../../common/utils/methods/commonMethods/utilityMethod";
import {ButtonText} from "variables/Buttons";
import {PermissionContext, RolePermissions} from "../../../../../../appContext";
import {pages} from "../../../common/constant";
import CustomLoader from "../../../common/commonComponents/Loader/loader";
import CustomButton from "../../../../../../components/CustomButton/index";
import moment from "moment";
const CourseCatalogFilter = (props) => {

	// let permissions = getPermissions('courseCatalog')
	const [courseDetails, setCourseDetails] = useState([]);
	const [apiloader, setapiloader] = useState(false);
	const [state, setstate] = useState([]);
	const [pagination, setpagination] = useState({
		currentPage: 1,
		nextPage: null,
		previousPage: null,
		totalPage: null,
	});
	const pageSize = 10;

	const permissions = useContext(PermissionContext);

	const userPermissions = RolePermissions(
		permissions,
		pages['courseCatalog']['id']
	);

	const fetchClasses = async (url) => {
		setapiloader(true);
		getAPI(
			url,
			(data) => {
				setstate(data['data']);
				let n = pagination;
				if (data['data'].length > pageSize) {
					n['nextPage'] = n.currentPage + 1;
				}
				if (n.currentPage > 1) {
					n.previousPage = n.currentPage - 1;
				}
				n['totalPage'] = Math.floor(data['data'].length / pageSize);
				if (data['data'].length % pageSize != 0) {
					n['totalPage'] += 1;
				}
				setpagination(n);
				setapiloader(false);
				setCourseDetails(
					data['data'].slice(
						pagination.currentPage * pageSize - pageSize,
						pagination.currentPage * pageSize
					) || []
				);
			},
			(data) => {
				failureToast(data['message']);
			}
		);
	};
	const nextPage = () => {
		setCourseDetails(
			state.slice(
				(pagination.currentPage + 1) * pageSize - pageSize,
				(pagination.currentPage + 1) * pageSize
			)
		);
		let n = pagination;
		n['previousPage'] = n.currentPage;
		n['currentPage'] = n['currentPage'] + 1;
		n['nextPage'] =
			n['currentPage'] === n['totalPage'] ? null : n['currentPage'] + 1;

		setpagination(n);
	};
	const firstPage = () => {
		setCourseDetails(state.slice(1 * pageSize - pageSize, 1 * pageSize));
		let n = pagination;
		n['currentPage'] = 1;
		n['previousPage'] = null;
		setpagination(n);
	};
	const lastPage = () => {
		setCourseDetails(
			state.slice(
				pagination.totalPage * pageSize - pageSize,
				pagination.totalPage * pageSize
			)
		);
		let n = pagination;
		n['currentPage'] = n.totalPage;
		n['previousPage'] = n.totalPage - 1;
		setpagination(n);
	};
	const previousPage = () => {
		if (pagination.currentPage != 1) {
			setCourseDetails(
				state.slice(
					(pagination.currentPage - 1) * pageSize - pageSize,
					(pagination.currentPage - 1) * pageSize
				)
			);
			let n = pagination;
			n['currentPage'] = n['currentPage'] - 1;
			if (n['currentPage'] == 1) {
				n['previousPage'] = null;
			} else {
				n['previousPage'] = n.currentPage;
			}
			setpagination(n);
		}
	};
	return (
		<>

			{
				apiloader
				?
				<CustomLoader apiLoader={apiloader} loaderHeight={'200px'} loaderWidth={'100% '}/>
				:
				(
				<>
					{
						userPermissions ? (
							<>
								<Filter
									setstate={setstate}
									setCourseDetails={setCourseDetails}
									setapiloader={setapiloader}
									apiloader={apiloader}
									pageSize={pageSize}
									pagination={pagination}
									setpagination={setpagination}
									history={props.history}
									permissionSet={userPermissions}
								/>
								{state && state.length ?
									<>
										<CourseDetailsTable
											state={state}
											setstate={setstate}
											setCourseDetails={setCourseDetails}
											courseDetails={courseDetails}
											apiloader={apiloader}
											setpagination={setpagination}
											permissionSet={userPermissions}
										/>
										{
											pagination.totalPage > 1 && !apiloader ? (
												<GetPagination
													fetchClasses={fetchClasses}
													pagination={pagination}
													previousPage={previousPage}
													firstPage={firstPage}
													lastPage={lastPage}
													nextPage={nextPage}
													setpagination={setpagination}
												/>
											) : null
										}
									</>
									: null
								}
							</>
						) : (
							<PermisionDenied/>
						)}
				</>
			)}
		</>
	);
};
const CourseDetailsTable = (props) => {
	let history = useHistory();
	const { state, setapiloader ,permissionSet} = props;
	const [isUpdated, setIsUpdated] = useState(false);
	const [tableDataKey, settableDataKey] = useState('tableDataKey');
	const [allAcademicCareer, setAllAcademicCareer] = useState([]);

	const findIndexToRemove = (id) => {
		try {
			let a = state.findIndex((item) => item.courseId === id);

			state.splice(a, 1);
		} catch (error) {
			console.log('Error', error);
		}
	};
	useEffect(()=>{
		fetchDetails(masterServiceBaseUrl + `/academicCareer/getAllAcademicCareer`, setAllAcademicCareer, 'academicCareer', 'reactSelect');
	},[])
	const excelDetails = {
		"Course ID" : "courseId",
		"Course Name" : "courseName",
		"Course ID Status": "courseIdStatus",
		"Prospectus Code": "prospectusCode",
		"Academic Career" : "academicCareerId",
	};
	const dataToExport = props.state?.map((item) => {
		return{
				...item,
				courseId : item.courseId,
				courseName : item.courseName,
				courseIdStatus: item.courseIdStatus,
				prospectusCode: item.prospectusCode,
				academicCareerId: getDispValForReactSelectByOneID(item.academicCareerId,'DispValue', allAcademicCareer)
			};
		});
// let fileName = `phx_CourseID_${moment().valueOf()}`
	return (
		<>
			<Container className={props.apiloader ? 'd-none' : 'mt-3'} fluid>
				<Row>
					<div className="col">
						<Card>
							<CardHeader className="border-0">
								<h3 className="mb-0 floatLeft">Course Catalog</h3>
								<div className="ml-auto"><ToExcel data={dataToExport} detailObj={excelDetails} filename={`phx_CourseID_${moment().valueOf()}`} name={`phx_CourseID_${moment().valueOf()}`} isSaveVisible={true}/></div>
							</CardHeader>
							<div id="questionTable" className="table-responsive">
								{props.courseDetails.length > 0 ? (
									<Table className="align-items-center table-flush tableLayout">
										<thead className="thead-light">
											<tr>
												<th className="text-center white-breakSpace">
													Course ID
												</th>
												<th className="text-center white-breakSpace">
													Course Name
												</th>
												<th className="text-center white-breakSpace">
													Course ID Status
												</th>
												{/* <th className="text-center white-breakSpace">
													Prospectus Code
												</th> */}
												<th className="text-center white-breakSpace">
													Academic Career
												</th>
												{
													permissionSet && (permissionSet.includes('U') || permissionSet.includes('R')) ?
														<th className="text-center white-breakSpace">Action</th> : null
												}
											</tr>
										</thead>
										<tbody className="list" key={tableDataKey}>
											{props.courseDetails
												? props.courseDetails.map((el, index) => (
														<CourseCatalogDetails
															permissionSet={permissionSet}
															allAcademicCareer={allAcademicCareer}
															type={'CourseCatalogDetails'}
															index={index}
															el={el}
															editRow={() => window.open(`${document.URL}/edit/${el.courseId}`, '_blank')}
															viewRow={() => window.open(`${document.URL}/view/${el.courseId}`, '_blank')}
															courseDetailsAll={props.courseDetails}
														/>
												  ))
												: null}
										</tbody>
									</Table>
								) : null}
							</div>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};
const Filter = (props) => {
	const { setstate,permissionSet } = props;

	const { handleSubmit,register } = useForm();
	const [randomkey, setrandomKey] = useState('randomKey');
	const [isSearchLoading,setIsSearchLoading] = useState(false);
	const SaveButton = () => {
		handleSubmit((formValues) => {
			if (formValues['courseName']) {
				formValues['courseName'] = formValues['courseName'].trim();
			}
			if (courseId) {
				formValues['courseId'] = courseId;
			}
			if (academicCareerId){
				formValues['academicCareerId'] = academicCareerId.value;
			}
			if (formValues['courseName'] && formValues['courseName'] === '' && formValues['courseName'] == null) {
				failureToast('Please Enter Course Name');
				return;
			}
			if (courseId && courseId.length > 7) {
				failureToast('Course Id Can Be Of 7 Digits');
				return;
			}

			const ret = [];
			for (var key in formValues) {
				if (formValues.hasOwnProperty(key)) {
					if (formValues[key] != null && formValues[key] != '') {
						ret.push(
							encodeURIComponent(key) +
								'=' +
								encodeURIComponent(formValues[key])
						);
					} else {
						formValues[key] = null;
					}
				}
			}
			if (ret.length > 0) {
				// props.setapiloader(true);
				props.setCourseDetails([]);
				setstate([]);
				setIsSearchLoading(true);

				putpost(
					masterServiceBaseUrl + '/courseCatalogSearch/getCourseCatalogSearch',
					(data) => {
						let n = props.pagination;
						n['currentPage'] = 1;
						if (data['data'].length > props.pageSize) {
							n['nextPage'] = n.currentPage + 1;
						}
						if (n.currentPage > 1) {
							n.previousPage = n.currentPage - 1;
						}
						n['totalPage'] = Math.floor(data['data'].length / props.pageSize);
						if (data['data'].length % props.pageSize != 0) {
							n['totalPage'] += 1;
						}
						props.setpagination(n);
						setIsSearchLoading(false);
						// props.setapiloader(false);
						props.setCourseDetails(
							data['data'].slice(
								props.pagination.currentPage * props.pageSize - props.pageSize,
								props.pagination.currentPage * props.pageSize
							) || []
						);
						setstate(data.data);
					},
					(data) => {
						setIsSearchLoading(false);
						// props.setapiloader(false);
						props.setCourseDetails([]);
						failureToast(data['message']);
					},
					formValues,
					'post'
				);
			} else {
				failureToast('Please Choose At Least One Course Catalog Filter');
			}
		})();
	};

	const [courseId, setCourseId] = React.useState('');
	const [academicCareer, setAcademicCareer] = useState([]);
	const [academicCareerId, setAcademicCareerId] = useState([]);



	useEffect(()=> {
		fetchDetails(masterServiceBaseUrl + '/academicCareer/getAllActiveAcademicCareer', setAcademicCareer, 'academicCareer', 'select');
	},[])
	return (
		<>
				<Container className={props.apiloader ? 'd-none' : ('mt-3', 'fadeInDown')} key={randomkey} fluid>
					<Row>
						<div className="col">
							<Card className="mb-0">
								<CardHeader className={'d-flex justify-content-between'}>
									<h3  style={{color:'#00AEEF',paddingTop:'7px'}} className="mb-0">Course Catalog Search</h3>
									<CustomButton
										disabled={false}
										className={'floatRight mx-1'}
										content={ButtonText.CourseCatalog.new}
										permissionType={'C'}
										icon={true}
										permissionSet={permissionSet}
										onClick={() => props.history.push('/admin/courseHeader/new')}
									/>
									{/*<CreateButton										// permissions={{write:true,read:true}}*/}
									{/*	className={'floatRight mx-1'}*/}
									{/*	onClick={() => props.history.push('/admin/courseHeader/new')}*/}
									{/*	text={ButtonText.CourseCatalog.new}*/}
									{/*/>*/}
								</CardHeader>
								<CardBody>
									<Form id="filterQuestion">
										<Row>
											<Col md="4">
												<FormGroup>
													<label className="form-control-label">
														Course ID
													</label>
													<input
														value={courseId}
														className="form-control"
														type="text"
														autocomplete="off"
														style={{height:'40px'}}
														placeholder={'Course ID'}
														onChange={(e) => {
															const re = /^[0-9\b]+$/;
															if (
																e.target.value === '' ||
																re.test(e.target.value)
															) {
																setCourseId(e.target.value);
															}
														}}
														maxLength={7}
													/>
												</FormGroup>
											</Col>

											<Col md="4">
												<SimpleInput
													placeholder={'Course Name'}
													maxLength={250}
													reference={register}
													title={'Course Name'}
													name={'courseName'}
												/>
											</Col>
											<Col md={'4'}>
												<CommonInput
													data={academicCareer}
													label="Academic Career"
													placeHolder={'Academic Career'}
													type="select"
													defaultValue={academicCareerId}
													onChange={(value)=> setAcademicCareerId(value)}
												/>
											</Col>
											<Col>
												<CustomButton
													disabled={isSearchLoading}
													type="reset"
													className={'floatRight mx-1 reset-button'}
													content={ButtonText.CourseCatalog.reset}
													permissionType={'R'}
													onClick={() => {
														setrandomKey(new Date().getMilliseconds());
														props.setCourseDetails([]);
														setCourseId('');
														setAcademicCareerId('');
														setstate([]);
														setIsSearchLoading(false);
														props.setpagination({
															currentPage: 1,
															nextPage: null,
															previousPage: null,
															totalPage: null,
														});
													}}
													permissionSet={permissionSet}
												/>
												<CustomButton
													className={'floatRight mx-1'}
													content={ButtonText.CourseCatalog.search}
													permissionType={'S'}
													icon={true}
													onClick={() => SaveButton()}
													permissionSet={permissionSet}
												/>
											</Col>
										</Row>
									</Form>
								</CardBody>
							</Card>
						</div>
					</Row>
				</Container>
		</>
	);
};
export default withRouter(CourseCatalogFilter);
