import React from 'react';
import AButton from '../../common/form-fields-mui/AButton';
import APopover from '../../common/a-popover';
import EditBranchesCommonComponent from 'views/pages/manage/common/branchesCommonComponent/EditBranchesCommonComponent.jsx';
import SearchInput from '../studentSerach/SearchInput';
import SelectSearchInputParam from '../studentSerach/SelectSearchInputParam';
import { searchParamTitleMap } from './constant';
import SearchFilterForm from './SearchFilterForm';
import { CiFilter } from 'react-icons/ci';

export const highlightedStyle = {
  border: '1px solid #00B0F5',
  color: '#00B0F5',
};

const Filter = ({
  sgFilterForm,
  totalRecords,
  updateSgFilterHandler = () => {},
  searchInputHandler = () => {},
  searchInputParam,
  setSearchInputParam = () => {},
  filterCount,
  filterPayload,
  applyFilter = () => {},
  dropdowns,
}) => {
  return (
    <div className='d-flex'>
      <div className='flex-grow-1'>
        <div className='heading-4'>{totalRecords} Students</div>
        <div className='d-flex align-items-center'>
          <div>{`${sgFilterForm?.region?.[0]?.label || '-'} ${
            sgFilterForm?.region?.length > 1
              ? `+${sgFilterForm?.region?.length - 1} more`
              : ''
          } | ${sgFilterForm?.businessArea?.[0]?.label || '-'} ${
            sgFilterForm?.businessArea?.length > 1
              ? `+${sgFilterForm?.businessArea?.length - 1} more`
              : ''
          } `}</div>

          <APopover
            buttonComponent={(onClick, styles) => (
              <AButton variant='link' size='sm' onClick={onClick}>
                View/Edit
              </AButton>
            )}
            menuComponent={closeForm => (
              <EditBranchesCommonComponent
                closeForm={closeForm}
                regions={sgFilterForm?.region}
                businessAreas={sgFilterForm?.businessArea}
                updateHandler={updatedForm =>
                  updateSgFilterHandler(updatedForm)
                }
              />
            )}
            anchorOriginHorizontal='left'
            transformOriginHorizontal='left'
          />
        </div>
      </div>
      <div className='d-flex align-items-start'>
        <div className='d-flex flex-column'>
          <SearchInput
            onCancel={() => searchInputHandler('')}
            searchInputValueHandler={searchInputHandler}
            placeholder={`Search by ${searchParamTitleMap[searchInputParam]}`}
          />
          <SelectSearchInputParam
            searchInputParam={searchInputParam}
            setSearchInputParam={setSearchInputParam}
          />
        </div>
        <APopover
          buttonComponent={(onClick, styles) => (
            <AButton
              className='tiny-semi-bold color-dark'
              onClick={onClick}
              updatedStyle={{
                minWidth: '61px',
                ...(filterCount > 0 ? highlightedStyle : styles),
              }}
            >
              <CiFilter className='mr-1' />
              Filter {filterCount > 0 && <>( {filterCount} )</>}
            </AButton>
          )}
          menuComponent={closeForm => (
            <SearchFilterForm
              closeForm={closeForm}
              filterCount={filterCount}
              filterPayload={filterPayload}
              applyFilter={applyFilter}
              dropdowns={dropdowns}
            />
          )}
        />
      </div>
    </div>
  );
};

export default Filter;
