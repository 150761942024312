import React from "react";
import { Card, CardBody } from "reactstrap";
import styles from "../styles.module.scss";
import moment from "moment";
import ALoader from "views/pages/manage/common/a-loader";
import AButton from "../../../../common/form-fields-mui/AButton";
import { Tooltip } from "@mui/material";
import { isBarcodedMaterial } from "../constant";
import { statusChip } from "../../dlpStockIssuance/issueStock/helper";

const IssuedStockList = ({
  selectedMaterials,
  matchingCount,
  issuedStockToStudent = [],
  closeHandler,
}) => {
  return (
    <Card style={{ minWidth: "520px", marginBottom: "0px" }}>
      <CardBody>
        <div className="heading-4 color-black semi-bold my-2">
          {matchingCount}/{selectedMaterials.length || 0} material has already
          been issued
        </div>
        <div className={`${styles["all-ready-issue-table"]}`}>
          <div className="heading-3 color-black semi-bold my-2">
            Material details
          </div>
          <div className="heading-3 color-black semi-bold my-2">
            {" "}
            Issue date
          </div>

          <div className="heading-3 color-black semi-bold my-2">Branch</div>
        </div>
        {issuedStockToStudent.map((item, index) => (
          <div
            className={`${styles["all-ready-issue-table"]}`}
            key={`${item?.materialId} -${index}`}
          >
            <div>
              {item?.materialId} | {item?.materialDescription}{" "}
              {item?.isReIssued && <span style={{ color: "red" }}>*</span>}
            </div>
            <div>{moment(item?.issueDate).format("DD-MMM-YYYY")}</div>
            <div>{item?.businessAreaDispValue}</div>
          </div>
        ))}
        <AButton
          className="button_remove_left_margin mt-1 mb-2"
          size="xs"
          variant="primary_filled"
          onClick={closeHandler}
        >
          Ok, got it!
        </AButton>
        {issuedStockToStudent.some((item) => item.isReIssued) && (
          <div>
            <span className="text-bold semi-bold color-black">Note : </span>
            <sup>*</sup>The material can be issued multiple times. Once every
            year
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export const IssuedMaterialData = ({
  dataRows = [],
  hideBarcode = false,
  closeHandler,
  isReturned = false,
}) => {
  const tableClass = isReturned
    ? styles["all-ready-issue-table-return"]
    : styles["all-ready-issue-table"];

  return (
    <Card style={{ minWidth: "540px", marginBottom: "0px" }}>
      <CardBody>
        <div className={tableClass}>
          <div className="heading-3 color-black semi-bold my-2">
            Material details
          </div>
          <div className="heading-3 color-black semi-bold my-2"> Barcode </div>

          {!hideBarcode && (
            <div className="heading-3 color-black semi-bold my-2">
              Qty issued
            </div>
          )}

          {isReturned && (
            <div className="heading-3 color-black semi-bold my-2">
              Qty. returned
            </div>
          )}

          {isReturned && (
            <div className="heading-3 color-black semi-bold my-2">
              Ret. Barcode
            </div>
          )}
        </div>
        {dataRows.map((item, index) => (
          <div
            className={tableClass}
            key={`${item?.barcode} -${index}`}
            style={{ fontSize: "14px ", marginBottom: "10px" }}
          >
            <Tooltip
              title={`${item?.materialNumber || "-"} | ${
                item?.materialDesc || "-"
              }`}
            >
              <div className="ellipsis-text-container">
                {item?.materialNumber || "-"} | {item?.materialDesc || "-"}
              </div>
            </Tooltip>
            <Tooltip title={item?.barcode}>
              <div className="ellipsis-text-container">
                {isBarcodedMaterial(item) ? item?.barcode || "-" : "-"}
              </div>
            </Tooltip>
            {!hideBarcode && <div>1</div>}
            {isReturned && <div>1</div>}
            {isReturned && (
              <Tooltip title={item?.barcode}>
                <div className="ellipsis-text-container">
                  {isBarcodedMaterial(item) ? item?.barcode || "-" : "-"}
                </div>
              </Tooltip>
            )}
          </div>
        ))}
      </CardBody>
    </Card>
  );
};

export const CourseDetailsDisplay = ({ course, applicationId, isLoading }) => {
  return (
    <Card style={{ minWidth: "540px", marginBottom: "0px" }}>
      <CardBody>
        {isLoading && <ALoader />}
        {/* <div className="heading-4">Application no. {applicationId}</div> */}
        <div className={`${styles["course-details-table"]}`}>
          <div className="heading-4 color-black semi-bold my-2">
            Course name
          </div>
          {/* <div className="heading-4 color-black semi-bold my-2">
            End date
          </div> */}
        </div>
        <div className={`${styles["course-details-table"]}`}>
          <div>{course?.courseName || "-"}</div>
          {/* <div>{course?.effectiveTo}</div> */}
        </div>
      </CardBody>
    </Card>
  );
};

export const IssuedMaterialsList = ({
  selectedMaterials,
  matchingCount,
  issuedStockToStudent = [],
  dispatchId,
  closePopOver,
}) => {
  return (
    <Card style={{ minWidth: "640px", marginBottom: "0px" }}>
      <CardBody>
        <div className="heading-4 color-black semi-bold my-2">
          {matchingCount} material has already been issued
        </div>
        <div className={`${styles["all-ready-issue-material-table"]}`}>
          <div className="heading-3 color-black semi-bold my-2">
            Dispatch ID{" "}
          </div>
          <div className="heading-3 color-black semi-bold my-2">
            Material details
          </div>

          <div className="heading-3 color-black semi-bold my-2">Qty</div>
          <div className="heading-3 color-black semi-bold my-2">
            {" "}
            Issue date
          </div>
          <div className="heading-3 color-black semi-bold my-2">
            Delivery status
          </div>
        </div>
        {issuedStockToStudent.map((item, index) => (
          <div
            className={`${styles["all-ready-issue-material-table"]}`}
            key={`${item?.materialId} -${index}`}
          >
            <div>{dispatchId}</div>
            <div>
              <Tooltip
                title={`${item?.materialId} | ${item?.materialDescription}`}
                placement="top-start"
              >
                <div className="ellipsis-text-container">
                  {`${item?.materialId} | ${item?.materialDescription}`}{" "}
                </div>
              </Tooltip>
            </div>
            <div>1</div>
            <div>{moment(item?.issueDate).format("DD-MMM-YYYY")}</div>
            <div>{statusChip[item.status]?.text ?? "-"}</div>
          </div>
        ))}
        <AButton
          className="button_remove_left_margin"
          size="xs"
          variant="primary_filled"
          onClick={closePopOver}
        >
          Ok, got it!
        </AButton>
      </CardBody>
    </Card>
  );
};

export default IssuedStockList;
