import React, { useEffect, useState } from 'react';
import { masterServiceBaseUrl, getAPI, putpost } from 'services/http';
import { Card, CardBody, FormGroup, Input, Row, Col, Button } from 'reactstrap';
import Select from 'react-select';
import Loader from 'react-loader-spinner';
import CustomCheckbox from 'views/pages/manage/common/formFeilds/customCheckbox';
import CustomLoader from '../../../../../common/commonComponents/Loader/loader';
import CustomButton from 'components/CustomButton';
import { failureToast, successToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';

const FormFirstAll = props => {
  const { nextStep = () => { }, isNew = true, formId, isViewOnly = false, userPermissions } = props;

  const [apiloader, setApiLoader] = useState(false);
  const [companyCodeData, setCompanyCodeData] = useState([]);
  const [courseAttributeData, setCourseAttributeData] = useState([]);
  const [idNo, setIdNo] = useState('');
  const [businessData, setBusinessData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const [value, setValues] = useState({
    businessgrp: '',
    courseAttribute: '',
    comapanyCode: '',
    attributeDesc: '',
    allowForReceipting: false,
    markMultipleRows: false,
    numberOfRowsAllowed: 1,
    status: 'ACTIVE',
  });

  const fetchCourseAttrMappingData = () => {
    setApiLoader(true);
    getAPI(
      `${masterServiceBaseUrl}/courseAttributeMapping/getCourseAttributeMapping/${formId}`,
      data => {
        setValues({
          ...value,
          businessgrp: getSelectedObj(data.data.groupCodeId, businessData),
          courseAttribute: getSelectedObj(data.data.courseAttributeId, courseAttributeData),
          comapanyCode: getSelectedObj(data.data.companyCodeId, companyCodeData),
          attributeDesc: selectedOption,
          allowForReceipting: data.data.addCourseCatalog,
          markMultipleRows: data.data.markMultipleRows,
          numberOfRowsAllowed: data.data.numberOfRowsAllowed
        });
        setIsDisabled(true);
        setApiLoader(false);
      },
      data => {
        failureToast(data['message']);
        setApiLoader(false);
      }
    );
  };

  const getSelectedObj = (id, arr) => {
    return arr.filter(item => {
      return item.value === id;
    })[0];
  };

  React.useEffect(() => {
    if (!isNew && businessData.length && companyCodeData.length && courseAttributeData.length) {
      fetchCourseAttrMappingData();
    }
  }, [isNew, courseAttributeData, businessData, companyCodeData]);

  const fetchData = (url, stateMethod, name) => {
    setApiLoader(true);
    getAPI(
      url,
      data => {
        let list = [];
        //this is for dropdown fields only
        let keyName = `${name}DispValue`;

        list = data.data.map(data => {
          // if (data.status === "ACTIVE") {
          return {
            value: data.id,
            label: data[keyName],
            desc: data.courseAttributeDesc,
          };
          // }
        });
        stateMethod(list);
        setApiLoader(false);
      },
      data => {
        setApiLoader(false);
        failureToast(data['message']);
      }
    );
  };

  useEffect(() => {
    fetchData(masterServiceBaseUrl + '/groupCode/getAllActiveGroupCode', setBusinessData, 'groupCode');
    fetchData(masterServiceBaseUrl + '/companyCode/getAllActiveCompanyCode', setCompanyCodeData, 'companyCode');
    fetchData(
      masterServiceBaseUrl + '/courseAttribute/getAllActiveCourseAttribute',
      setCourseAttributeData,
      'courseAttribute'
    );
  }, []);

  const saveHandler = () => {
    if (value.businessgrp === '') {
      failureToast('Please Select Business Group');
      return;
    } else if (value.courseAttribute === '') {
      failureToast('Please Select Course Attribute');
      return;
    } else if (value.comapanyCode === '') {
      failureToast('Please Select Company Code');
      return;
    }else if (value.markMultipleRows && (!value.numberOfRowsAllowed || value.numberOfRowsAllowed > 10 || value.numberOfRowsAllowed < 2)) {
      failureToast('Please Provide Valid Numbers Of Rows between 2 - 10');
      return;
    }
    setApiLoader(true);

    const obj = {
      groupCodeId: value.businessgrp.value,
      courseAttributeId: value.courseAttribute.value,
      companyCodeId: value.comapanyCode.value,
      addCourseCatalog: value.allowForReceipting,
      status: 'ACTIVE',
      markMultipleRows: value.markMultipleRows,
      numberOfRowsAllowed: value.numberOfRowsAllowed
    };

    if (isNew) {
      putpost(
        `${masterServiceBaseUrl}/courseAttributeMapping/createCourseAttributeMapping`,
        data => {
          successToast(data['message']);
          setValues({
            businessgrp: '',
            courseAttribute: '',
            comapanyCode: '',
          });
          setIdNo(data.data['id']);
          nextStep(data.data.id);
          setApiLoader(false);
        },
        data => {
          setApiLoader(false);
          failureToast(data['message']);
        },
        obj,
        'post'
      );
    }

    if (!isNew) {
      putpost(
        `${masterServiceBaseUrl}/courseAttributeMapping/updateCourseAttributeMapping/${formId}`,
        data => {
          successToast(data['message']);
          fetchCourseAttrMappingData();
        },
        data => {
          setApiLoader(false);
          failureToast(data['message']);
        },
        obj,
        'put'
      );
    }
  };

  const discard = () => {
    fetchCourseAttrMappingData();
    setIsDisabled(true);
  };

  return (
    <Card className="mb-4">
      <CardBody>
        {apiloader ? (
          <CustomLoader apiLoader={apiloader} loaderHeight={'200px'} loaderWidth={'100% '} />
        ) : (
          <div>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="example4cols2Input">
                    Business Group
                  </label>
                  <Select
                    value={value.businessgrp}
                    id="example1cols1Input"
                    options={businessData}
                    isDisabled={isDisabled}
                    required
                    onChange={sData => {
                      setValues({ ...value, businessgrp: sData, comapanyCode: null })
                      fetchData(masterServiceBaseUrl + '/companyCode/getCompanyCodeByGroup/' + sData.value, setCompanyCodeData, 'companyCode');
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="example4cols2Input">
                    Company Code
                  </label>
                  <Select
                    value={value.comapanyCode}
                    id="example3cols2Input"
                    options={companyCodeData}
                    isDisabled={isDisabled}
                    required
                    onChange={sData => setValues({ ...value, comapanyCode: sData })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="example4cols2Input">
                    Course Attribute
                  </label>
                  <Select
                    value={value.courseAttribute}
                    id="example3cols2Input"
                    options={courseAttributeData}
                    isDisabled={isDisabled}
                    required
                    onChange={sData => {
                      setSelectedOption(sData.label);
                      setValues({ ...value, courseAttribute: sData });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="example4cols2Input">
                    Attribute Description
                  </label>
                  <Input
                    data-testid="attributeDesc"
                    value={value?.courseAttribute?.desc ?? ''}
                    name="atrributeDesc"
                    placeholder="Attribute Description"
                    id="example4cols2Input"
                    className="tdSelect"
                    disabled={true}
                    onChange={sData => setValues({ ...value, attributeDesc: sData })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Row className={`mb-2`}>
                    <Col md={4} className="ml-n3 d-flex flex-row">
                      <Col md={9}>
                        <label className="form-control-label" htmlFor="example4cols2Input">
                          Essential to add in course catalog
                        </label>
                      </Col>
                      :
                      <Col md={1}>
                        <CustomCheckbox
                          defaultChecked={value.allowForReceipting}
                          // isDisabled={isDisabled}
                          onClick={isDisabled ? (e) => { e.preventDefault(); return; } : () => { }}
                          onChangeHandler={isDisabled ? () => { } : sData => {
                            if (sData.target.checked) {
                              setValues(preState => ({
                                ...preState,
                                allowForReceipting: true,
                              }));
                            } else {
                              setValues(preState => ({
                                ...preState,
                                allowForReceipting: false,
                              }));
                            }
                          }}
                        />
                        {/* <Input
												data-testid="allowForReceipting"
												defaultChecked={value.allowForReceipting}
												disabled={isDisabled}
												onChange={(sData) => {
													if (sData.target.checked) {
														setValues((preState) => ({
															...preState,
															allowForReceipting: true,
														}));
													} else {
														setValues((preState) => ({
															...preState,
															allowForReceipting: false,
														}));
													}
												}}
												type="checkBox"
												style={{
													width: '20px',
													height: '20px',
													marginLeft: '30px',
												}}
											/> */}
                      </Col>
                    </Col>
                    <Col md={4} className="ml-n3 d-flex flex-row ">
                      <Col md={9} className="d-flex align-items-center">
                        <label className="form-control-label" htmlFor="example4cols2Input">
                          Map multiple rows in course catalog
                        </label>
                      </Col>
                      :
                      <Col md={1}>
                        <CustomCheckbox
                          defaultChecked={value.markMultipleRows}
                          // isDisabled={isDisabled}
                          onClick={isDisabled ? (e) => { e.preventDefault(); return; } : () => { }}
                          onChangeHandler={isDisabled ? () => { } : sData => {
                            if (sData.target.checked) {
                              setValues(preState => ({
                                ...preState,
                                markMultipleRows: true,
                              }));
                            } else {
                              setValues(preState => ({
                                ...preState,
                                markMultipleRows: false,
                                numberOfRowsAllowed: 1
                              }));
                            }
                          }}
                        />
                      </Col>
                    </Col>
                    <Col md={4} className="ml-n3 d-flex flex-row align-items-center">
                      <Col md={7} className="d-flex align-items-center">
                        <label className="form-control-label" htmlFor="example4cols2Input">
                          Number of rows allowed
                        </label>
                      </Col>
                      <Col md={5}>
                      <Input
                        type="text"
                        data-testid="rowAllowed"
                        value={value?.numberOfRowsAllowed}
                        name="rowAllowed"
                        placeholder="Number of rows allowed"
                        id="example4cols2Input"
                        className="tdSelect"
                        disabled={value.markMultipleRows && !isViewOnly && !isDisabled? false : true}
                        onKeyPress={(e)=>{
                          if (!/^[0-9]+$/.test(String.fromCharCode(e.charCode))) {
                            e.preventDefault();
                            return;
                          }
                        }}
                        maxLength="2"
                        onChange={e => setValues({ ...value, numberOfRowsAllowed: e.target.value })}
                      />
                      </Col>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
            {isNew ? (
              <Row className={`d-flex pr-3 justify-content-end`}>
                <CustomButton
                  className={`ml-auto`}
                  content={'Next'}
                  permissionType={'C,U'}
                  forTable={false}
                  icon={false}
                  permissionSet={userPermissions}
                  onClick={saveHandler}
                />
              </Row>
            ) : !isNew && !isDisabled ? (
              <>
                <Row className={`d-flex pr-3 justify-content-end`}>
                  <CustomButton
                    className={`mr-3`}
                    content={'Save'}
                    permissionType={'C,U'}
                    forTable={true}
                    onClick={() => {
                      saveHandler();
                    }}
                    permissionSet={userPermissions}
                  />
                  <CustomButton
                    className={`mr-3`}
                    content={'Cancel'}
                    permissionType={'cancel'}
                    forTable={true}
                    permissionSet={userPermissions}
                    onClick={() => {
                      discard();
                    }}
                  />
                </Row>
              </>
            ) : !isViewOnly ? (
              <Row className={`d-flex pr-3 justify-content-end`}>
                <CustomButton
                  className={`ml-auto`}
                  content={'Edit'}
                  permissionType={'U'}
                  forTable={true}
                  permissionSet={userPermissions}
                  onClick={() => {
                    setIsDisabled(false);
                  }}
                />
              </Row>
            ) : null}
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default FormFirstAll;
