export const UPDATE_DROPDOWNS = 'UPDATE_DROPDOWNS'
export const REQUEST_DROPDOWNS = 'REQUEST_DROPDOWNS'


// academic plans listing page action types from here
export const FACULTY_ROASTER_LIST_REQUEST = 'FACULTY_ROASTER_LIST_REQUEST'
export const FACULTY_ROASTER_LIST_SUCCESS = 'FACULTY_ROASTER_LIST_SUCCESS'
export const FACULTY_ROASTER_LIST_FAILURE = 'FACULTY_ROASTER_LIST_FAILURE'
export const FACULTY_ROASTER_LIST_CLEAR = 'FACULTY_ROASTER_LIST_CLEAR'
export const SEARCH_FACULTY_ROASTER_DROPDOWNS_REQUEST = 'SEARCH_FACULTY_ROASTER_DROPDOWNS_REQUEST'
export const SEARCH_FACULTY_ROASTER_DROPDOWNS_SUCCESS = 'SEARCH_FACULTY_ROASTER_DROPDOWNS_SUCCESS'
export const SEARCH_FACULTY_ROASTER_DROPDOWNS_FAILURE = 'SEARCH_FACULTY_ROASTER_DROPDOWNS_FAILURE'