import React, { useEffect, useState } from 'react';
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  Button,
  Row,
  Col,
} from 'reactstrap';
import { Accordion } from '../../../common/commonComponents/Accordion/accordion';
import ProfitAndCostCenter from './profitCenter&CostCenter';
import BusinessAreaPlan from './businessArea&Plan';
import OtherSystems from './otherSystems';
import { masterServiceBaseUrl, getAPI, failureToast } from 'services/http';
import './sapDetails.scss';

const AddSAPDetails = (props) => {
  const {
    action,
    previousStep,
    businessUnit,
    nextStep = () => {},
    isNew = true,
    formId,
    isViewOnly = false,
    sapDetails,
    appendAllStepData=()=>{},
    isPrevious,
    saveStepsCompleted=()=>{}
  } = props;
  const [academicCareer, setAcademicCareer] = useState([]);
  const [academicGroup, setAcademicGroup] = useState([]);
  const [profitCenter, setProfitCenter] = useState([]);
  const [businessAreaAndPlan, setBusinessAreaAndPlan] = useState([]);
  const [profitAndCostCenter, setProfitAndCostCenter] = useState([]);
  const [ otherSystems, setOtherSystems ] = useState( [] );
  const [ isDisabled, setIdDisabled ] = useState( false );

  useEffect(() => {
    fetchDetails(
      masterServiceBaseUrl + '/academicCareer/getAllActiveAcademicCareer',
      setAcademicCareer,
      'academicCareer'
    );
    fetchDetails(
      masterServiceBaseUrl + '/profitCenterMappingPage/getAllActiveProfitCenterMappingPage',
      setProfitCenter,
      'sapProfitCenter'
    );
    fetchDetails(
      masterServiceBaseUrl + '/academicGroup/getAllActiveAcademicGroup',
      setAcademicGroup,
      'academicGroup'
    );

    if ( action === 'edit' || action === 'view' ) {
      setProfitAndCostCenter(sapDetails && sapDetails['profitAndCostMasters']);
      setOtherSystems( sapDetails && sapDetails[ 'otherSystemsMasters' ] );
      setIdDisabled(true);
    }
    // console.log('businessUnit1_3', businessUnit.businessUnit);
  }, []);


  const fetchDetails = (url, stateMethod, type) => {
    try {
      getAPI(
        url,
        (data) => {
          refactorData(data['data'], stateMethod, type);
        },
        (data) => {
          failureToast(data['message']);
        }
      );
    } catch (e) {
      console.log('Error', e);
    }
  };

  const refactorData = (data, stateMethod, type) => {
    try {
      switch (type) {
        case 'academicCareer':
          iterateData(
            data,
            'id',
            'academicCareerDispValue',
            'academicCareerDesc',
            stateMethod
          );
          break;
        case 'sapProfitCenter':
          iterateData(
            data,
            'id',
            'sapProfitCenterId',
            'description',
            stateMethod
          );
          break;
        case 'academicGroup':
          iterateData(
            data,
            'id',
            'academicGroupDispValue',
            'academicGroupDesc',
            stateMethod
          );
          break;
        default:
          break;
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  const iterateData = (data, id, property, propertyDesc, stateMethod) => {
    try {
      let temp = [];
      if (property === 'sapProfitCenterId') {
        data.map((item) => {
          temp.push({
            id: item[id],
            text: item[property],
            desc: item[propertyDesc],
            academicCareerId: item.academicCareerId,
            academicGroupId: item.academicGroupId,
          });
        });
      } else {
        data.map((item) => {
          temp.push({
            id: item[id],
            text: item[property],
            desc: item[propertyDesc],
          });
        });
      }
      stateMethod(temp);
    } catch (e) {
      console.log('Error', e);
    }
  };

  const getDisplayValue = (id, type) => {
    try {
      switch (type) {
        case 'academicCareerDispValue':
          const idIndex = findIndexForDisplay(id, academicCareer);
          return academicCareer[idIndex]['text'];
          break;
        case 'profitCenterDispValue':
          const profitCenterId = findIndexForDisplay(id, profitCenter);
          return profitCenter[profitCenterId]['text'];
          break;
        case 'profitCenterDescription':
          const profitDescIndex = findIndexForDisplay(id, profitCenter);
          return profitCenter[profitDescIndex]['desc'];
          break;
        case 'profitCenterAcademicCareerDispValue':
          const profitA_CareerId = findIndexForDisplay(id, profitCenter);
          // console.log('profitA_CareerId__', profitA_CareerId);
          const careerId = profitCenter[profitA_CareerId]['academicCareerId'];
          const acdemicData = findDisplayValue(academicCareer, careerId);
          return acdemicData;
          break;
        case 'profitCenterAcademicGroupDispValue':
          const profitA_GroupId = findIndexForDisplay(id, profitCenter);
          const groupId = profitCenter[profitA_GroupId]['academicGroupId'];
          const acdemicCareerData = findDisplayValue(academicGroup, groupId);
          return acdemicCareerData;
          break;
        default:
          break;
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  const findIndexForDisplay = (id, datalist) => {
    const index = datalist.findIndex((item) => item['id'] == id);
    return index;
  };

  useEffect(() => {
    if(sapDetails && isPrevious){
      setBusinessAreaAndPlan(sapDetails && sapDetails.businessAreaAndPlanMasters)
      setProfitAndCostCenter(sapDetails && sapDetails.profitAndCostMasters)
      setOtherSystems(sapDetails && sapDetails.otherSystemsMasters)
    }
  }, [sapDetails,isPrevious])


  const toNextStep = () => {
    // debugger
    !isPrevious && isNew &&  saveStepsCompleted(9)
    // appendAllStepData({businessAreaAndPlanMasters: businessAreaAndPlan,otherSystemsMasters: otherSystems,profitAndCostMasters:profitAndCostCenter},'ninethForm')
    props.nextStep()
  }

  const findDisplayValue = (datalist, id) => {
    try {
      const findIndex = datalist.findIndex((item) => item['id'] == id);
      return findIndex >= 0 ? datalist[findIndex]['text'] : 'NOT FOUND';
    } catch (e) {
      console.log('Error', e);
    }
  };

  const moveToNextStep = () => {
    if (businessAreaAndPlan.length === 0)
      return failureToast(
        'Please fill the SAP - Business Area & Business Plan Details'
      );
    if (profitAndCostCenter.length === 0)
      return failureToast(
        'Please fill the SAP - Profit Center & Cost Center Details'
      );
    // if (otherSystems.length === 0)
    //   return failureToast('Please fill the Other Systems Details');
    toNextStep();
  };

  return (
    <Container className="mt-4" fluid>
        <Card>
        { action === 'new' ?
          <CardHeader className="border-0">
            <h3 className="mb-0 floatLeft">Add SAP Details</h3>
          </CardHeader> :null}
          <hr />
          <CardBody className="sapCardBody">
            <Accordion
              isFormOpen={action === 'new' ? true : false}
              removePadding={ true }
              title={`SAP - Business Area & Business Plan`}
              key={'sap_business_area_&_business_plan'}
            >
            <BusinessAreaPlan
                businessPlanData ={ sapDetails && sapDetails['businessAreaAndPlanMasters']}
                businessUnit={businessUnit && businessUnit['businessUnit']}
                businessAreaAndPlan={businessAreaAndPlan}
                setBusinessAreaAndPlan={setBusinessAreaAndPlan}
                academicCareer={academicCareer}
                getDisplayValue={ getDisplayValue }
                isDisabled={isDisabled}
                action={action}
                isPrevious={isPrevious}
                saveStepsCompleted={()=>saveStepsCompleted(9)}
                formId={props.formId}
                isNew={isNew}
              />
            </Accordion>
            <Accordion
              isFormOpen={action === 'new' ? true : false}
              removePadding={ true }
              title={`SAP - Profit Center & Cost Center`}
              key={'sap_profit_center_&_cost_center'}
            >
              <ProfitAndCostCenter
                businessUnit={businessUnit && businessUnit['businessUnit']}
                profitCenter={profitCenter}
                profitAndCostCenter={profitAndCostCenter}
                setProfitAndCostCenter={setProfitAndCostCenter}
                getDisplayValue={ getDisplayValue }
                isDisabled={isDisabled}
                action ={action}
                isPrevious={isPrevious}
                saveStepsCompleted={()=>saveStepsCompleted(9)}
                formId={props.formId}
                isNew={isNew}
              />
            </Accordion>
            <Accordion
              isFormOpen={action === 'new' ? true : false}
              removePadding={ true }
              title={`Other Systems`}
              key={'other_systems'}
            >
              <OtherSystems
                businessUnit={businessUnit && businessUnit['businessUnit']}
                otherSystems={otherSystems}
                setOtherSystems={setOtherSystems}
                getDisplayValue={ getDisplayValue }
                action={ action }
                isDisabled={isDisabled}
                isPrevious={isPrevious}
                saveStepsCompleted={()=>saveStepsCompleted(9)}
                formId={props.formId}
                isNew={isNew}
              />
            </Accordion>
          </CardBody>
          {(action !== 'edit' && action !== 'view') ? <Row>
            <Col>
              <Button
                className="floatLeft ml-4 mb-3"
                color="primary"
                type="button"
                onClick={() => {
                  previousStep();
                }}
              >
                Previous
              </Button>
              <Button
                color="primary"
                className="floatRight mr-4 mb-3"
                type="button"
                onClick={() => moveToNextStep()}
              >
                Save & Next
              </Button>
            </Col>
          </Row> :
          <Row>
            { action === 'edit' ?
              <Col>
              <Button
              color="primary"
              className="floatRight mr-4 mb-3"
              type="button"
              onClick={() => setIdDisabled(!isDisabled)}
            >
              { isDisabled ? 'Enable Form' : 'Disable Form'}
            </Button>
          </Col>:null}
            </Row>}
        </Card>
    </Container>
  );
};

export default AddSAPDetails;
