import { concessionServiceBaseUrl } from "services/http";

export const consessionServiceEndpoints = {
  treeView: `${concessionServiceBaseUrl}/category/tree`,
  category: `${concessionServiceBaseUrl}/category`,
  subCategory: `${concessionServiceBaseUrl}/subCategory`,
  subSubCategory: `${concessionServiceBaseUrl}/subSubCategory`,
  masterFilter: `${concessionServiceBaseUrl}/category/tree/search`,
  concessionSlab: `${concessionServiceBaseUrl}/concession-slab`,
  getConcessionSlabBySubCategory: `${concessionServiceBaseUrl}/concession-slab/subCategory`,
  getConcessionSlabBySubSubCategory: `${concessionServiceBaseUrl}/concession-slab/subSubCategory`,
  allConsessionslabs: `${concessionServiceBaseUrl}/concession-slab/all`,
  exportConsessionSlab: `${concessionServiceBaseUrl}/concession-slab/exportToCsv`,
  getAllConcessionUploadDetails: `${concessionServiceBaseUrl}/getAllConcessionUploadDetails`,
  getMasterMapping: `${concessionServiceBaseUrl}/category/getCategoryMasterConfig`,
  getHouseBankList: `${concessionServiceBaseUrl}/getAllHouseBank`,
  bankAccount: `${concessionServiceBaseUrl}/bankAccount`,
  sapPeriodStatus: {
    getAllPeriodStatus: `${concessionServiceBaseUrl}/sapPeriodStatus/getAllPeriodStatus`,
  },
  sapProfitCostCentreMapping: {
    getAll: `${concessionServiceBaseUrl}/getAllProfitCostCenter`,
    createProfitCostCenter: `${concessionServiceBaseUrl}/createProfitCostCenter`,
    updateProfitCostCenter: `${concessionServiceBaseUrl}/updateProfitCostCenter`,
    deleteProfitCostCenter: `${concessionServiceBaseUrl}/deleteProfitCostCenter`,
    getAllProfitCenter: `${concessionServiceBaseUrl}/profitCenter/getAllProfitCenter`,
    searchProfitCostCenter: `${concessionServiceBaseUrl}/searchProfitCostCenter`,
  },
  findSlabByParams:`${concessionServiceBaseUrl}/concession-slab/findSlabByParams`,
   //migrated
  otherChargesConcession:{
    getAllOtherCharegesById : `${concessionServiceBaseUrl}/applicationOtherChargesDetails/applicationId`,
    create : `${concessionServiceBaseUrl}/applicationOtherChargesDetails`,
    updateStatus: `${concessionServiceBaseUrl}/applicationOtherChargesDetails/status`,
  },
  updateStudentChargeSummary : `${concessionServiceBaseUrl}/studentApplicationCharges/updateSummaryKey`,
  updateStudentQuotationStatus : `${concessionServiceBaseUrl}/studentApplicationCharges/applicationQuotation/changeStatus`,
  applicationQuotationDetails: `${concessionServiceBaseUrl}/studentApplicationCharges/applicationQuotationDetails`,
  studentChargesConcessionDetailsConcession:{
    getByApplicationId: `${concessionServiceBaseUrl}/studentApplicationCharges/concessionDetails/applicationId`,
    post: `${concessionServiceBaseUrl}/studentApplicationCharges/concessionDetails/save`,
  },
  savedBreakup : `${concessionServiceBaseUrl}/studentChargesFeeBreakup/applicationQuotationDetails`,
  concessionConfigConc:{
    getAll : `${concessionServiceBaseUrl}/customConcessionConfig/getAllActiveConfig`,
  },
  categoryCode: `${concessionServiceBaseUrl}/categoryCode`,
  loanScheme: `${concessionServiceBaseUrl}/loanSchemeMaster`,
  concessionFiles: `${concessionServiceBaseUrl}/studentApplicationCharges/concessionDocument`,
  studentDetailsConc:{
    countByChargeStatus: `${concessionServiceBaseUrl}/studentApplicationCharges/getCountByChargeStatus`
  },
  approvalConcession:{
    getAllApprovalRequests: `${concessionServiceBaseUrl}/studentApplicationCharges/concessionDetails/search/approvalRequests`,
  },
  lumpSumConcession: {
    getAll: `${concessionServiceBaseUrl}/lumpSumConcession/getAllActiveLumpSumConcession`,
    create: `${concessionServiceBaseUrl}/lumpSumConcession/createLumpSumConcession`,
    delete: `${concessionServiceBaseUrl}/lumpSumConcession/deleteLumpSumConcession`,
    update: `${concessionServiceBaseUrl}/lumpSumConcession/updateLumpSumConcession`,
    getByCareerId: `${concessionServiceBaseUrl}/lumpSumConcession/getLumpSumConcessionByCareer`
  },
  configExAakashian : {
    getById: `${concessionServiceBaseUrl}/config/exAakashian`,
    getAll: `${concessionServiceBaseUrl}/config/exAakashian/all`,
    delete: `${concessionServiceBaseUrl}/config/exAakashian`,
    update: `${concessionServiceBaseUrl}/config/exAakashian`,
    create: `${concessionServiceBaseUrl}/config/exAakashian`,
    validate: `${concessionServiceBaseUrl}/config/exAakashian/validate`,
  },
  concessionConfig: {
    create: `${concessionServiceBaseUrl}/customConcessionConfig/createConfig`,
    update: `${concessionServiceBaseUrl}/customConcessionConfig/updateConfig`,
    delete: `${concessionServiceBaseUrl}/customConcessionConfig/deleteConfig`,
    search : `${concessionServiceBaseUrl}/customConcessionConfig/searchConfig`
  },
  leftOutAdmissionConfig:{
    getById:`${concessionServiceBaseUrl}/leftOutAdmissionConfig/getLeftOutAdmissionConfigById`,
    getAll:`${concessionServiceBaseUrl}/leftOutAdmissionConfig/getAllLeftOutAdmissionConfig`,
    create:`${concessionServiceBaseUrl}/leftOutAdmissionConfig/create`,
    update:`${concessionServiceBaseUrl}/leftOutAdmissionConfig/update`,
    delete:`${concessionServiceBaseUrl}/leftOutAdmissionConfig/delete`,
    search: `${concessionServiceBaseUrl}/leftOutAdmissionConfig/searchLeftOutAdmissionConfigs`
  },
  scholarshipConfig: {
    getById:`${concessionServiceBaseUrl}/scholarshipConfig/getScholarshipConfigById`,
    getAll:`${concessionServiceBaseUrl}/scholarshipConfig/getAllScholarshipConfig`,
    create:`${concessionServiceBaseUrl}/scholarshipConfig/create`,
    update:`${concessionServiceBaseUrl}/scholarshipConfig/update`,
    delete:`${concessionServiceBaseUrl}/scholarshipConfig/delete`,
    search:`${concessionServiceBaseUrl}/scholarshipConfig/searchScholarshipConfigs`
  },
  // 
  studentChargesConcessionDetails: {
    sendForApproval: `${concessionServiceBaseUrl}/studentApplicationCharges/concessionDetails/saveAndSendForApproval`,
    post: `${concessionServiceBaseUrl}/studentApplicationCharges/concessionDetails/save`,
    send: `${concessionServiceBaseUrl}/studentApplicationCharges/concessionDetails/send`,
    revoke: `${concessionServiceBaseUrl}/studentApplicationCharges/concessionDetails/revoke`
  },
  getConssessionDetails: `${concessionServiceBaseUrl}/studentApplicationCharges/concessionDetails/applicationQuotationDetailsId`,
  getConssessionDetailsByApplicationId: `${concessionServiceBaseUrl}/studentApplicationCharges/concessionDetails/applicationId`,
  finalizeCharges : `${concessionServiceBaseUrl}/studentChargesFeeBreakup`,
 
  approval: {
    status: `${concessionServiceBaseUrl}/studentApplicationCharges/concessionDetails/updateApprovalStatus`,
    export: `${concessionServiceBaseUrl}/applicationApprovalRequests/export`
  },
  otherCharges: {
    getOtherCharges  : `${concessionServiceBaseUrl}/studentCharges/otherCharges/applicationId`,
    getAll : `${concessionServiceBaseUrl}/applicationOtherChargesDetails/applicationId`,
    otherChargesForApproval : `${concessionServiceBaseUrl}/applicationOtherChargesDetails/search/approvalRequests`
  },
  getDocumentsByapplicationConcessionId:  `${concessionServiceBaseUrl}/studentApplicationCharges/concessionDocument/concession`,
  verifyDocuments: `${concessionServiceBaseUrl}/studentApplicationCharges/concessionDocument/verify`,
  loanCareerValidation: {
    create: `${concessionServiceBaseUrl}/loan-career-validation/createLoanCareerValidation`,
    update: `${concessionServiceBaseUrl}/loan-career-validation/updateLoanCareerValidation`,
    getAll: `${concessionServiceBaseUrl}/loan-career-validation/getAllLoanCareerValidation`,
    delete: `${concessionServiceBaseUrl}/loan-career-validation/deleteLoanCareerValidation`,
    search: `${concessionServiceBaseUrl}/loan-career-validation/searchLoanCareerSetup`,
    getActiveLoanSetupByBUAndAcadCareer : `${concessionServiceBaseUrl}/loan-career-validation/getActiveLoanSetupByBUAndAcadCareer`,
  },
  getStudentChargesAuditByQuotation:`${concessionServiceBaseUrl}/studentCharges/getStudentChargesAuditByApplication`,
  deliveryOrder: {
    deliveryOrderDetails: `${concessionServiceBaseUrl}/deliveryOrderDetails`, // same endpoint for create and edit as well
  },
  eligibleDepositSlips: `${concessionServiceBaseUrl}/studentChargesFeeBreakup/getEligibleDepositSlipByQuotationId`,
  allDepositeSlip: `${concessionServiceBaseUrl}/studentChargesFeeBreakup/getDepositSlipsByQuotation`,
  qrCode :{
    searchQr: `${concessionServiceBaseUrl}/qrCode/searchQR`,
    saveQr: `${concessionServiceBaseUrl}/qrCode/saveQR`,
    updateQr:`${concessionServiceBaseUrl}/qrCode/updateQR`,
    deleteQr:`${concessionServiceBaseUrl}/qrCode/deleteQR`,
  },
  saveDepositSlip : `${concessionServiceBaseUrl}/studentChargesFeeBreakup/saveDepositSlip`,
  allQuotations : `${concessionServiceBaseUrl}/studentApplicationCharges/getAllQuotationByApplication`,
  notifyDepositSlip: `${concessionServiceBaseUrl}/notifyDepositSlip`,
  feeBreakfromPricingByApplicationId: `${concessionServiceBaseUrl}/studentApplicationCharges/getFeeBreakupWithTaxByApplicationIdAndQuotationId`,
  getChargesWithFeeBreakupAndGstDetails : `${concessionServiceBaseUrl}/studentApplicationCharges/getChargesWithFeeBreakupAndGstDetails`,
  checkModifyAllowed: `${concessionServiceBaseUrl}/studentCharges/chargeModificationEligibility`,
  paidItemTypeList: `${concessionServiceBaseUrl}/studentCharges/getApplicationItemTypePaymentDetail`,

  getAutoConcessions: `${concessionServiceBaseUrl}/studentApplicationCharges/concessionDetails/getAllAutoConcession`,
  getScoreByPsidExam: `${concessionServiceBaseUrl}/testPlayer/getScoreByPsidExam`,

  downloadApprovalRequests : {
    downloadChargesRequests : `${concessionServiceBaseUrl}/studentApplicationCharges/concessionDetails/downloadApplicationApprovalRequests`,
    downloadOtherChargesRequests : `${concessionServiceBaseUrl}/studentApplicationCharges/concessionDetails/downloadApplicationOtherChargesApprovalRequests`
  },

  paymentGatewayConfig : {
    search : `${concessionServiceBaseUrl}/paymentGatewayConfig/searchPaymentGatewayConfig`,
    create : `${concessionServiceBaseUrl}/paymentGatewayConfig/create`, 
    update : `${concessionServiceBaseUrl}/paymentGatewayConfig/update`, 
  },
  sdChargePayment:{
    search : `${concessionServiceBaseUrl}/payment/sdCharge/searchSecurityDepositConfiguration`,
    create : `${concessionServiceBaseUrl}/payment/sdCharge/createSecurityDepositConfiguration`, 
    update : `${concessionServiceBaseUrl}/payment/sdCharge/updateSecurityDepositConfiguration`, 
  },
  getFeeBreakupWithPaymentDetails:`${concessionServiceBaseUrl}/studentApplicationCharges/getFeeBreakupWithPaymentDetails`,

  bankMaster : {
    search : `${concessionServiceBaseUrl}/payment/bankMaster/searchBank`,
    create : `${concessionServiceBaseUrl}/payment/bankMaster/createBank`, 
    update : `${concessionServiceBaseUrl}/payment/bankMaster/updateBank`, 
  },

  posMapping : {
    search : `${concessionServiceBaseUrl}/payment/posMapping/searchPosMapping`,
    create : `${concessionServiceBaseUrl}/payment/posMapping/create`, 
    update : `${concessionServiceBaseUrl}/payment/posMapping/update`,
  }

};

