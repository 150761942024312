import React from 'react';
import {useState, useEffect} from "react"
import {
    Card,
    CardHeader,
    Input,
    Container,
    Button,
    Table,
    Row,
    Col,
    UncontrolledCollapse,
    CardBody,
    Form,
    FormGroup,
    Label,
} from "reactstrap";
import {
    fetchDetails,
    fetchGlobalMasterData, getDispValForReactSelectByOneID,
    iterateData
} from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import CommonInput from "../../../common/formFeilds/input/commonInput";
import ExamSyllabusTable from '../examSyllabusTable';
import {examControllerServiceBaseUrl, masterServiceBaseUrl, putpost, successToast} from 'services/http';
import {getAPI, failureToast} from 'services/http';
// import {useDispatch, useSelector} from "react-redux";
// import {updateLoaderState, updateMasterData} from "../../../../../../redux";
import CustomLoader from "../../../common/commonComponents/Loader/loader";
import { MasterServiceUrls } from 'variables/masterServiceUrls';
import {useHistory} from "react-router-dom";
import ExamSyllabusSearchTable from "./searchTable";
import ButtonWithLoader from "../../../common/commonComponents/buttonWithLoader";
import {formatData, formatExamSyllabusData} from "../examSyllabusForm";
import {GetPagination} from "../../../common/commonComponents/pagination/pagination";

// const fetchGlobalMasterData = async (listOfMaster = [])=>{
//     try{
//         return new Promise((resolve, reject) => {
//             if (listOfMaster && listOfMaster.length > 0) {
//                 let flag = true;
//                 listOfMaster.forEach(async (item) => {
//                     let FilterMaster = MasterServiceUrls.filter(el=>el.name === item.name );
//                     if(FilterMaster.length){
//                         let url = '';
//                         if(item.all){
//                             url = FilterMaster[0]?.getAll;
//                         }else{
//                             url = FilterMaster[0]?.getAllActive;
//                         }
//                         let flag = await getAPI(
//                            masterServiceBaseUrl + url,
//                             (data) => {
//                                 iterateData(data["data"],item.stateMethod, item.name, item.type );
//                             },
//                             (data) => {
//                                 failureToast(data["message"]);
//                             }
//                         );
//                         console.log('Url------>',url);
//                     }else{
//                         console.error(item.name+' url not found in MasterURls Array.');
//                     }
//                 })
//                 resolve(true);
//             }
//         });
//     }catch(e){
//         console.error('Error in fetchGlobalMasterData-->',e);
//     }
// }

const SearchContext = (props) => {
    // const {setApiLoader} = props;

    let history = useHistory();
    const [apiLoader,setApiLoader] = useState(false);
    const [tableVisible, setTableVisible] = useState(false);
    const [tableData,setTableData] = useState([]);
    const [searchData, setSearchData] = useState({
        businessGroup: '',
        companyCode: '',
        zone: '',
        region: '',
        businessArea: '',
        academicCareer: '',
        academicGroup: '',
        course: '',
        term: '',
        academicYear: '',
        academicPhase: '',
        batch: '',
        examId: '',
        isLoading : false
    });
    const [allGroupCode,setAllGroupCode] = useState([]);
    const [allCompanyCode,setAllCompanyCode] = useState([]);
    const [allSubject,setAllSubject] = useState([]);
    const [businessGroup, setBusinessGroup] = useState([]);
    const [companyCodeId, setCompanyCodeId] = useState([]);
    const [operationalZone, setOperationalZone] = useState([]);
    const [region, setRegion] = useState([]);
    const [businessArea, setBusinessArea] = useState([]);
    const [academicCareer, setAcademicCareer] = useState([]);
    const [academicGroup, setAcademicGroup] = useState([]);
    const [CourseType, setCourseType] = useState([]);
    const [Term, setTerm] = useState([]);
    const [academicYear, setAcademicYear] = useState([]);
    const [academicPhase, setAcademicPhase] = useState([]);
    const [batch, setBatch] = useState('Demo');
    const [examName, setExamName] = useState([]);
    const [allSearchedData, setAllSearchedData] = useState([]);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        nextPage: null,
        previousPage: null,
        totalPage: null,
    });
    const pageSize = 10;
    const fetchRequiredData = async ()=>{
        setApiLoader(true)
        fetchDetails(masterServiceBaseUrl + `/groupCode/getAllActiveGroupCode`, setBusinessGroup, 'groupCode', 'select');
        fetchDetails(masterServiceBaseUrl + `/groupCode/getAllGroupCode`, setAllGroupCode, 'groupCode', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + `/companyCode/getAllCompanyCode`, setAllCompanyCode, 'companyCode', 'reactSelect')
        fetchDetails(masterServiceBaseUrl + `/subject/getAllSubject`, setAllSubject, 'subject', 'reactSelect')
        fetchDetails(masterServiceBaseUrl + `/region/getAllActiveRegion`, setRegion, 'region', 'select');
        fetchDetails(masterServiceBaseUrl + '/term/getAllActiveTerm', setTerm, 'term', 'select');
        fetchDetails(masterServiceBaseUrl + '/academicPhase/active', setAcademicPhase, 'academicPhase', 'select');
        fetchDetails(masterServiceBaseUrl + '/academicYear/active', setAcademicYear, 'academicYear', 'select');
        fetchExamIds(examControllerServiceBaseUrl + '/exam/getAllActiveExamId');
        fetchDetails(masterServiceBaseUrl + '/operationalZone/getAllActiveOperationalZone', setOperationalZone, 'operationalZone', 'select');
        fetchItemType(masterServiceBaseUrl + '/courseDetail/getAllCourseDetail', setCourseType, 'courseDetail');
        setApiLoader(false)
    }

    const fetchExamIds = (url)=>{
        getAPI(
            url,
            (data) => {
                let tempArray = [];
                data.data.map(item=>{
                    tempArray.push({
                        value : item.examId,
                        label : item.examId,
                        id : item.id
                    })
                });
                setExamName(tempArray);
            },
            (data) => {
                failureToast(data["message"]);
            });
    }
    useEffect(() => {
        fetchRequiredData().then((res)=>{});
    }, [])

    const handleClick = () => {
        searchHandler();
        setTableVisible(true);
    }
    const paginationUpdate = (data) => {
        let n = pagination;
        if (data.length > pageSize) {
            n["nextPage"] = n.currentPage + 1;
        }
        if (n.currentPage > 1) {
            n.previousPage = n.currentPage - 1;
        }
        n["totalPage"] = Math.floor(data.length / pageSize);
        if (data.length % pageSize !== 0) {
            n["totalPage"] += 1;
        }
        setPagination(n);
        setTableData(
            data.slice(
                pagination.currentPage * pageSize - pageSize,
                pagination.currentPage * pageSize
            ) || []
        );
        setApiLoader(false);
    }
    const searchHandler =()=>{
        let tempObj = searchData;
        if(searchData.businessGroup === '' || !searchData.businessGroup){
            failureToast('Business Group is Mandatory.')
        } else if (searchData.companyCode === '' || !searchData.companyCode){
            failureToast('Company Code is Mandatory.')
        } else if (searchData.course === '' || !searchData.course){
            failureToast('Course is Mandatory.')
        } else if (searchData.term === '' || !searchData.term){
            failureToast('Term is Mandatory.')
        } else{
            tempObj ={
                ...tempObj,
                isLoading:true
            }
            setSearchData(tempObj)
            let payload = {
                academicCareer:searchData.academicCareer?.value ,
                academicGroup: searchData.academicGroup?.value,
                academicPhase: searchData.academicPhase?.value,
                academicYear: searchData.academicYear?.value,
                batch: searchData.batch,
                businessArea: searchData.businessArea?.value,
                businessGroup: searchData.businessGroup?.value,
                companyCode: searchData.companyCode?.value,
                course: searchData.course?.value,
                examId: searchData.examId?.value,
                region: searchData.region?.value,
                term: searchData.term?.value,
                zone: searchData.zone?.value
            };
            let url = masterServiceBaseUrl + '/examSyllabusMasterHeader/searchExamSyllabusMasterHeader';
            putpost(url,
                (data)=>{
                    successToast(data.message);
                    let tempArray = [];
                    data.data.map(item => {
                        tempArray.push({
                            academicCareerIds: formatData(item.academicCareerIds ,'academicCareerDispValue'),
                            academicGroupIds : formatData(item.academicGroupIds ,'academicGroupDispValue'),
                            academicPhaseIds : formatData(item.academicPhaseIds ,'academicPhaseDisplayValue'),
                            businessGroupIds: item.businessGroupIds[0].groupCodeDispValue,
                            companyCodeIds: item.companyCodeIds[0].companyCodeDispValue,
                            batchIds:item.batch,
                            id:item.id,
                            status: item.status
                        })
                    });
                    setAllSearchedData([...tempArray]);
                    paginationUpdate([...tempArray]);
                    let tempObj = searchData;
                    tempObj = {
                        ...tempObj,
                        isLoading: false
                    }
                    setSearchData(tempObj);
                },
                (data)=>{
                    failureToast(data.message);
                },
                payload,'POST').then((res)=>{
            })
        }
    }

    const fetchItemType = (url, setMethod, type) => {
        getAPI(
            url,
            (data) => {
                if (type === 'courseDetail') {
                    if (data.data.length > 0) {
                        let tempArray = []
                        data.data.map(item => {
                            tempArray.push({
                                value: item.courseId,
                                label: item.courseId + ' - ' + item.courseName,
                                text: item.courseDescription,
                            })
                        })
                        setMethod(tempArray);
                    }
                }
            },
            (data) => {
                failureToast(data["message"]);
            }
        );
    }
    const updateSearchData = (key, value) => setSearchData(preState => ({...preState, [key]: value}));
    const newRowHandler=()=>{
        history.push('/admin/examSyllabus/new');
    }
    const resetHandler = ()=>{
        setSearchData({
            businessGroup: '',
            companyCode: '',
            zone: '',
            region: '',
            businessArea: '',
            academicCareer: '',
            academicGroup: '',
            course: '',
            term: '',
            academicYear: '',
            academicPhase: '',
            batch: '',
            examId: ''
        });
        setTableData([]);
      setPagination({currentPage: 1, nextPage: null, previousPage: null, totalPage: null,} );
    };

    const fetchExamSyllabusDataByHeaderId = (id=null)=>{
        if(id){
            let url = masterServiceBaseUrl + `/examSyllabusMasterDetail/getExamSyllabusMasterDetailByHeaderId/${id}`
            getAPI(
                url,
                (data) => {
                    let tempArray = formatExamSyllabusData(data.data,);
                    return tempArray;
                },
                (data) => {
                    failureToast(data["message"]);
                    return [];
                }
            );
        }
    }

    const [innerTable,setInnerTable] = useState([]);
    const viewExamSyllabus = (id=null)=>{
        if(id){
            let innerTableData = fetchExamSyllabusDataByHeaderId(id);
            setInnerTable(innerTableData);
            // window.open( document.URL +'/view/'+id, '_blank' );
        }
    }
    return (
            apiLoader
            ?
                <div className="mx-auto text-center py-5 my-5" style={{height: "100vh"}}>
                    <CustomLoader apiLoader={apiLoader}/>
                </div>
            :
            <Container fluid={true} className="p-4">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader className={'d-flex justify-content-between'}>
                                <h3 className="mb-0">Search Context</h3>
                                <Button
                                    color="info"
                                    size="sm"
                                    type="button"
                                    className={"floatRight mx-1"}
                                    onClick={newRowHandler}
                                    data-testid="addNewExamSyllabus"
                                >
                                    Add New
                                </Button>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={3}>
                                        <CommonInput label={'Business Group'}
                                                     data={businessGroup}
                                                     mandatory={true}
                                                     type={'select'}
                                                     placeHolder={'Business Group'}
                                                     defaultValue={searchData["businessGroup"]}
                                                     onChange={(value) => {
                                                         if (value) {
                                                             updateSearchData('businessGroup', value);
                                                             setCompanyCodeId([])
                                                             setBusinessArea([]);
                                                             setAcademicCareer([]);
                                                             setAcademicGroup([]);
                                                             fetchDetails(masterServiceBaseUrl + `/companyCode/getCompanyCodeByGroup/${value.value}`, setCompanyCodeId, 'companyCode', 'select');

                                                         }
                                                     }}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <CommonInput label={'Company Code'}
                                                     data={companyCodeId}
                                                     mandatory={true}
                                                     type={'select'}
                                                     defaultValue={searchData["companyCode"]}
                                                     placeHolder={'Company Code'}
                                                     onChange={(value) => {
                                                         if (value) {
                                                             updateSearchData('companyCode', value);
                                                             setBusinessArea([]);
                                                             setAcademicCareer([]);
                                                             setAcademicGroup([]);
                                                             let companyCodeId = value.value;
                                                             fetchDetails(masterServiceBaseUrl + `/businessArea/getBusinessAreaByCompanyCode/${companyCodeId}`, setBusinessArea, 'businessArea', 'select');
                                                             fetchDetails(masterServiceBaseUrl + `/academicCareer/getAcademicCareerByCompanyCode/${companyCodeId}`, setAcademicCareer, 'academicCareer', 'select');
                                                             fetchDetails(masterServiceBaseUrl + `/academicGroup/getAcademicGroupByCompanyCode/${companyCodeId}`, setAcademicGroup, 'academicGroup', 'select');
                                                         }
                                                     }}
                                        />
                                    </Col>
                                    <Col md={3}>

                                        <CommonInput label={'Course'}
                                                     defaultValue={searchData["course"]}
                                                     data={CourseType}
                                                     mandatory={true}
                                                     type={'select'}
                                                     placeHolder={'Course'}
                                                     onChange={(value) => {
                                                         if (value) {
                                                             updateSearchData('course', value);
                                                         }
                                                     }}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <CommonInput label={'Term'}
                                                     defaultValue={searchData["term"]}
                                                     data={Term}
                                                     mandatory={true}
                                                     type={'select'}
                                                     placeHolder={'Term'}
                                                     onChange={(value) => {
                                                         if (value) {
                                                             updateSearchData('term', value);

                                                         }
                                                     }}
                                        />
                                    </Col>

                                {/*</Row>*/}
                                {/*<Row>*/}
                                    <Col md={3}>
                                        <CommonInput label={'Exam ID'}
                                                     defaultValue={searchData["examId"]}
                                                     data={examName}
                                                     type={'select'}
                                                     mandatory = {true}
                                                     placeHolder={'Exam ID'}
                                                     onChange={(value) => {
                                                         if (value) {
                                                             updateSearchData('examId', value);

                                                         }
                                                     }}
                                        />
                                    </Col>
                                    <Col md={3}>

                                        <CommonInput label={'Zone'}
                                                     data={operationalZone}
                                                     defaultValue={searchData["zone"]}
                                                     type={'select'}
                                                     placeHolder={' Zone'}
                                                     onChange={(value) => {
                                                         if (value) {
                                                             updateSearchData('zone', value);

                                                         }
                                                     }}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <CommonInput label={'Region'}
                                                     defaultValue={searchData["region"]}
                                                     data={region}
                                                     type={'select'}
                                                     placeHolder={'Region'}
                                                     onChange={(value) => {
                                                         if (value) {
                                                             updateSearchData('region', value);

                                                         }
                                                     }}
                                        />
                                    </Col>
                                    <Col md={3}>

                                        <CommonInput label={'Business Area'}
                                                     type={'select'}
                                                     placeHolder={'Business Area'}
                                                     defaultValue={searchData["businessArea"]}
                                                     data={businessArea}
                                                     onChange={(value) => {
                                                         if (value) {
                                                             updateSearchData('businessArea', value);

                                                         }
                                                     }}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <CommonInput label={'Academic Career'}
                                                     defaultValue={searchData["academicCareer"]}
                                                     data={academicCareer}
                                                     type={'select'}
                                                     placeHolder={'Academic Career'}
                                                     defaultValue={searchData["academicCareer"]}
                                                     data={academicCareer}
                                                     onChange={(value) => {
                                                         if (value) {
                                                             updateSearchData('academicCareer', value);

                                                         }
                                                     }}
                                        />
                                    </Col>

                                    <Col md={3}>
                                        <CommonInput label={'Academic Group'}
                                                     defaultValue={searchData["academicGroup"]}
                                                     data={academicGroup}
                                                     type={'select'}
                                                     placeHolder={'Academic Group'}
                                                     onChange={(value) => {
                                                         if (value) {
                                                             updateSearchData('academicGroup', value);

                                                         }
                                                     }}
                                        />
                                    </Col>
                                    <Col md={3}>

                                        <CommonInput label={'Academic Year'}
                                                     defaultValue={searchData["academicYear"]}
                                                     data={academicYear}
                                                     type={'select'}
                                                     placeHolder={'Academic Year'}
                                                     onChange={(value) => {
                                                         if (value) {
                                                             updateSearchData('academicYear', value);

                                                         }
                                                     }}
                                        />
                                    </Col>

                                    <Col md={3}>
                                        <CommonInput label={'Academic Phase'}
                                                     defaultValue={searchData["academicPhase"]}
                                                     data={academicPhase}
                                                     type={'select'}
                                                     placeHolder={'Academic Phase'}
                                                     onChange={(value) => {
                                                         if (value) {
                                                             updateSearchData('academicPhase', value);

                                                         }
                                                     }}
                                        />
                                    </Col>
                                {/*</Row>*/}
                                {/*<Row>*/}
                                    <Col md={3}>
                                        <CommonInput label={'Batch'}
                                                     defaultValue={searchData["batch"]}
                                                     data={batch}
                                                     type={'text'}
                                                     placeHolder={'Batch'}
                                                     onChange={(value) => {
                                                         if (value) {
                                                             updateSearchData('batch', value);

                                                         }
                                                     }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        {/*<FormGroup>*/}
                                            <Button style={{'margin-top': '25px', 'margin-left': '5px'}} color="info" size="sm"
                                                    className="floatRight btn-vimeo btn-icon w-30"
                                                    onClick={resetHandler}>
                                                Reset
                                            </Button>
                                            <ButtonWithLoader loading={searchData.isLoading}  style={{'margin-top': '25px'}} color="info" size="sm"
                                                    className="floatRight btn-vimeo btn-icon w-30"
                                                    onClick={handleClick}>
                                                Search
                                            </ButtonWithLoader>
                                        {/*</FormGroup>*/}


                                    </Col>
                                </Row>


                            </CardBody>

                        </Card>

                    </Col>
                </Row>
                {
                    tableData && tableData.length ?
                        <ExamSyllabusSearchTable
                            businessGroup ={allGroupCode}
                            allCompanyCode = {allCompanyCode}
                            allSubject = {allSubject}
                            setclassArray={setTableData}
                            tableData={tableData}
                            academicYear={academicYear}
                            academicPhase={academicPhase}
                            batch={batch}
                            course={CourseType}
                            exam={examName}
                            viewExamSyllabus={viewExamSyllabus}
                            innerTable={innerTable}
                        />
                        :
                        null
                }
                {
                    pagination.totalPage > 1
                        ?
                        <GetPagination
                            isSaveVisible={false}
                            setclassArray={setTableData}
                            pagination={pagination}
                            setpagination={setPagination}
                            pageSize={pageSize}
                            state={allSearchedData}
                        />
                        :
                        null
                }

            </Container>

    );
}

export default SearchContext;