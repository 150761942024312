import React, { useState } from "react";
import { CiFilter } from "react-icons/ci";
import AButton from "../../common/form-fields-mui/AButton";
import { RightSideFilterForm } from "./RightSideFilterForm";
import APopover from "../../common/pop-hover/APophover";
import SearchInput from './SearchInput';
import { failureToast } from '../../common/utils/methods/toasterFunctions/function.js';


// this is for filter button style
const highLightedStyle = {
  border: "1px solid #00B0F5",
  color: "#00B0F5",
};
const NormalStyle = {
  border: "1px solid #E9ECEF",
};
const Filter = ({
  data,
  filterForm,
  inputHandler,
  removeFilterHandler,
  crossInputHandler,
  businessAreaData,
  regionData,
  academicGroupData,
  academicCareerData,
  subjectData,
  setNormalFilterForm,
  setFilterForm,
  isSaveVisible,
  ...props
}) => {
  const [anchorFilterEl, setAnchorFilterEl] = useState(null);
  const [anchorAdvFilterEl, setAnchorAdvFilterEl] = useState(null);
  const getBusinessareaOption = () => {
    if (filterForm?.region?.length > 0 && !businessAreaData.fetching) {
      const regionList = filterForm?.region.map(i => i.value)
      let matchedBusinessArea = [];
      businessAreaData.data.map((item) => {
        if (regionList?.filter(value => item?.regionId?.includes(value)).length) {
          matchedBusinessArea.push(item)
        }
      })
      return matchedBusinessArea
    } else {
      return []
    }
  }

  const onSearchNameCancel = () => {
    crossInputHandler();
  };
  const searchPsidHandler = (name) => {
    if (name === '') {
      onSearchNameCancel()
      return
    }
    if (name.length < 4) {
      failureToast("Please provide atleast 4 characters.")
      return
    }
    setFilterForm({ ...filterForm, facultyNameOrfacultyId: name })
    props.applyFilterHandler({ ...filterForm, facultyNameOrfacultyId: name });
  };

  const removerFilterWithClose = () => {
    removeFilterHandler()
    setAnchorFilterEl(null)
  }
  const applyFilterHandler = () => {
    props.applyFilterHandler();
    setAnchorFilterEl(null)
  }
  return (
    <div className="d-flex justify-content-end mb-1 p-3">
      <SearchInput
        onCancel={onSearchNameCancel}
        searchInputValueHandler={searchPsidHandler}
        placeholder="Search by name or ID"
        disabled={!isSaveVisible}
      />
      <AButton
        onClick={!isSaveVisible ? () => {} : (event) => {
          setAnchorFilterEl(event.currentTarget);
        }}
        updatedStyle={
          anchorFilterEl || props.appliedFilterCount > 0 ? {...highLightedStyle, cursor: !isSaveVisible ? 'not-allowed' : 'pointer'} : {...NormalStyle,cursor: !isSaveVisible ? 'not-allowed' : 'pointer'}
        }
      >
        <CiFilter />
        Filter
        {props.appliedFilterCount > 0 && <span>( {props.appliedFilterCount} )</span>}
      </AButton>
      <APopover
        handleClose={() => setAnchorFilterEl(null)}
        id="info-batch-filter-1"
        anchorEl={anchorFilterEl}
      >
        <RightSideFilterForm
          formModal={filterForm}
          inputHandler={inputHandler}
          applyFilterHandler={applyFilterHandler}
          removeFilterHandler={removerFilterWithClose}
          isShowRemoveFilter={!!props.appliedFilterCount}
          businessAreaData={getBusinessareaOption}
          regionData={regionData}
          academicGroupData={academicGroupData}
          academicCareerData={academicCareerData}
          subjectData={subjectData}
          setFilterForm={setFilterForm}
        // disabled={showAllStudent}
        />
      </APopover>
      <APopover
        handleClose={() => setAnchorAdvFilterEl(null)}
        id="info-batch-filter"
        anchorEl={anchorAdvFilterEl}
      ></APopover>
      {/* <AButton>
			<MdOutlineSimCardDownload />
			<CSVLink
				data={data}
				headers={CSV_HEADERS}
				filename={`${Date.now()}-${"batches.csv"}`}
			>
				Download CSV
			</CSVLink>
			;
			</AButton> */}
    </div>
  );
};
export default Filter;