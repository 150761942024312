import React, { Fragment, useContext, useEffect, useState } from "react";
import { getAPI, masterServiceBaseUrl } from 'services/http';
import { GetPagination } from "../../common/commonComponents/pagination/pagination";
import CurrencyTable from './currencyTable';
import { PermissionContext, RolePermissions } from "appContext";
import { PermisionDenied } from "../../common/commonComponents";
import CustomLoader from "../../common/commonComponents/Loader/loader";
import { pages } from "../../common/constant";
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';

const CurrencyCode = () => {
  const [classArray, setclassArray] = useState([])
  const [apiloader, setapiloader] = useState(false)
  const [state, setstate] = useState([])
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [countries, setCountries] = useState([])
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null
  });

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["Currency"]['id']);

  const pageSize = 10;
  const fetchClasses = async (url) => {
    setapiloader(true)
    getAPI(url, (data) => {
      setstate(data['data'])
      let n = pagination
      if (data['data'].length > pageSize) {
        n['nextPage'] = n.currentPage + 1
      }
      if (n.currentPage > 1) {
        n.previousPage = n.currentPage - 1
      }
      n['totalPage'] = Math.floor(data['data'].length / pageSize)
      if (data['data'].length % pageSize != 0) {
        n['totalPage'] += 1
      }
      setpagination(n)
      setapiloader(false)
      setclassArray(data['data'].slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || [])
    }, (data) => { failureToast(data['message']) })
  }
 

  const fetchCountry = async (url) => {
    setapiloader(true)
    getAPI(url, (data) => {
      const coutryList = data.data.map(
        data => {
          const {
            id,
            countryDispValue,
            status
          } = data;
          if (status === 'ACTIVE') {
            return {
              id: id,
              text: countryDispValue
            };
          }
        }
      );
      setCountries(coutryList)
    }, (data) => { failureToast(data['message']) })
  }

  useEffect(() => {
    fetchClasses(masterServiceBaseUrl + '/currency/getAllCurrency')
    fetchCountry(masterServiceBaseUrl + '/country/getAllActiveCountry')
  }, [])

  return (
    <Fragment>
      {apiloader ? <CustomLoader
        apiLoader={apiloader}
        loaderHeight={'200px'}
        loaderWidth={'100% '}
      /> :
        <Fragment>
          {userPermissions ? <>
            <CurrencyTable
              userPermissions={userPermissions}
              fetchClasses={fetchClasses}
              setclassArray={setclassArray}
              classArray={classArray}
              countryList={countries}
              isSaveVisible={isSaveVisible}
              setisSaveVisible={setisSaveVisible}
            />
            {pagination.totalPage > 1 ?
              <GetPagination
                isSaveVisible={isSaveVisible}
                fetchClasses={fetchClasses}
                setclassArray={setclassArray}
                pagination={pagination}
                setpagination={setpagination}
                pageSize={pageSize}
                state={state}
              /> : null}
          </>
            : <PermisionDenied />}
        </Fragment>
      }
    </Fragment>
  );
}
export default CurrencyCode;