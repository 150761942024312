import React, {useEffect, useState} from 'react';
import {getAPI, masterServiceBaseUrl, putpost} from "../../../../../../services/http";
import {failureToast, successToast} from "../../utils/methods/toasterFunctions/function";

const styles = {
    width: '20px',
    height: '20px',
    marginLeft: '10px',
    marginTop: '6px',
    display: 'inline'
}
const IsCoreMaster = (props)=>{
    const {
        master=null,
        masterDisplayValue=null,
        setApiLoader=()=>{},
        floatRight=false
    } = props;

    const [coreMaster, setCoreMaster] = useState({
        enableGlobal: false,
        master:master ,
        masterDisplayValue: masterDisplayValue,
    });

    const saveCoreMasterData = async (url, value) => {
        try {
            setApiLoader(true);
            let obj = {
                enableGlobal: value,
                master: coreMaster.master,
                masterDisplayValue: coreMaster.masterDisplayValue,
            };
            if(!master){
                failureToast('Pleas enter master value for core master.');
            }else if(!masterDisplayValue ){
                failureToast('Pleas enter display value for master for core master.');
            }else {
                await putpost(
                    url,
                    (data) => {
                        successToast(data['message']);
                        setCoreMaster({...coreMaster, enableGlobal: data.data?.enableGlobal,});
                        setApiLoader(false);
                        fetchCoreMasterData();
                    },
                    (data) => {
                        failureToast(data['message']);
                        setApiLoader(false);
                    },
                    obj,
                    'post'
                );
            }
        } catch (e) {
            console.error('Error in Core Master Common Component--->', e);
        }
    };

    const fetchCoreMasterData = () => {
        try{
            if(!master){
                failureToast('Please enter master value for core master checkbox.')
            }else if(!masterDisplayValue){
                failureToast('Please Enter Display value for master in core master checkbox.')
            }else {
                setApiLoader(true);
                getAPI(masterServiceBaseUrl +
                    `/coreMaster/getCoreMasterByMaster?master=${master}`,
                    (data) => {
                        setCoreMaster({...coreMaster, enableGlobal: data.data?.enableGlobal,});
                        setApiLoader(false);
                    },
                    (data) => {
                        failureToast(data['message']);
                        setApiLoader(false);
                    }
                );
            }
        }catch(e){
            console.error('Error in Core Master Common Component---->',e)
        }
    };
    useEffect(() => {
        fetchCoreMasterData();
    }, []);

    return (
        // <div >
            <div className={ floatRight ? 'floatRight' :''}
                 style={{
                     marginLeft : floatRight ? '':'20px',  display:'inline-block',
                     paddingTop: '9px',
                     paddingBottom: '5px',
                     paddingLeft: '5px',
                     paddingRight: '5px',
                     border:'1px solid  #00AEEF',
                     borderRadius :'8px'
                }}>
                <h4 className={`mb-0  floatLeft mr-2`}>
                    Core Master :{' '}
                </h4>
                <>
                    <input
                        onChange={(e) => {
                            saveCoreMasterData(masterServiceBaseUrl + '/coreMaster/createOrUpdateCoreMaster', e.target.checked).then(data=>{console.log('Data Saved')});
                        }}
                        checked={coreMaster.enableGlobal }
                        className="react-switch-checkbox"
                        id={`react-switch-new`}
                        type="checkbox"
                    />
                    <label
                        style={{ background: (coreMaster.enableGlobal) ? '#00AEEF' : '#e9ecef' }}
                        className="react-switch-label"
                        htmlFor={`react-switch-new`}
                    >
                        <span className={`react-switch-button`} />
                    </label>
                </>
                {/*<ToggleButton*/}
                {/*    value={coreMaster.enableGlobal}*/}
                {/*    inactiveLabel={'No'}*/}
                {/*    activeLabel={'Yes'}*/}
                {/*    thumbStyle={{background:'red'}}*/}
                {/*    activeLabelStyle={{*/}
                {/*        background:'red'*/}
                {/*    }}*/}
                {/*    onToggle={(value) => {*/}
                {/*        saveCoreMasterData(masterServiceBaseUrl + '/coreMaster/createOrUpdateCoreMaster', !value).then(data=>{console.log('Data Saved')});*/}

                {/*    }}*/}
                {/*    // onToggle={(value) => {*/}
                {/*    //     self.setState({*/}
                {/*    //         value: !value,*/}
                {/*    //     })*/}
                {/*    //}}*/}
                {/*/>*/}
                {/*<Input*/}
                {/*    checked={coreMaster.enableGlobal}*/}
                {/*    type="checkBox"*/}
                {/*    onChange={(e) => {*/}
                {/*        saveCoreMasterData(masterServiceBaseUrl + '/coreMaster/createOrUpdateCoreMaster', e.target.checked).then(data=>{console.log('Data Saved')});*/}
                {/*    }}*/}
                {/*    style={styles}*/}
                {/*/>*/}
            </div>
        // </div>
    )
}
export default IsCoreMaster