import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as BackArrow } from "../../../../../assets/img/svg/BackArrow.svg"
import { useHistory } from "react-router-dom"
import AButton from "../../common/form-fields-mui/AButton";
import "./qrCodeStyles.scss"
import EmptyQrListPlaceholder from "./EmptyQrListPlaceholder";
import AddQrModal from "./AddQrModal";
import { ReactComponent as EditIcon } from "../../../../../assets/img/svg/Edit2.svg"
import { ReactComponent as DeleteIcon } from "../../../../../assets/img/svg/Delete.svg"
import { fetchAllPostPromisedData, fetchAllPromisedData, fetchDeletePromisedData } from "../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "../../common/constant";
import moment from "moment";
import { AGridColDef } from "../../common/data-grid/ADataGridColDef";
import DocUploadModal from "../studentCharges/studentChargesDetails/paymentMode/DocUploadModal";
import ADataGrid from "../../common/data-grid/ADataGrid";
import { gridComponents, gridStyles } from "../../edpOps/issuanceIDcard/printIdCardAndMapRfid/constant";
import { Card } from "reactstrap";
import { successToast, failureToast } from "../../common/utils/methods/toasterFunctions/function";
import { getDropdownIdsFromSelectArray } from "../../common/utils/methods/commonMethods/utilityMethod";
import { fetchBusinessAreasByRegions } from "../../academicPlanAndTest/constant/common";
import { LightTooltip } from "../studentCharges/studentChargesDetails/concessionDetails/prospectus";
import { getDropdowns } from "./constant";
import CustomLoader from "../../common/commonComponents/Loader/loader";
import useConfirm from "../../common/commonComponents/ConfirmationDialog/confirmationPrompt";
import {ReactComponent as ActiveControl} from "../../../../../assets/img/svg/ActiveControl.svg"
import {ReactComponent as InactiveControl} from "../../../../../assets/img/svg/InactiveControl.svg";
import MultiSelectCellComponentWithSearch from "../../common/commonComponents/SearchFilterView/MultiSelectCellComponentWithSearch";

const EMPTY_FORM = {
    id: null,
    qrCodeS3Key: null,
    regions: [],
    businessAreas: [],
    effectiveDate: new Date(),
    status:"ACTIVE",
    isDirty: false,
}


const QRCode = () => {

    const history = useHistory();

    const [qrCodeData, setQrCodeData] = useState([]);

    const [addQrModalOpen, setAddQrModalOpen] = useState(false);

    const [dropdown, setDropdown] = useState({});

    const [form, setForm] = useState({});

    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    const [businessAreaData, setBusinessAreaData] = useState([]);
    const [businessAreaLoader, setBusinessAreaLoader] = useState(false);

    const [qrCodesLoading, setQrcodesLoading] = useState(true);

    const [viewQrModalOpen, setViewQrModalOpen]=useState(false);
    const [viewQrS3Key, setViewQrS3Key]=useState(null);

    const isSaveDisabled=useRef(false);
    const [DeleteConfirmationDialog, confirm]=useConfirm();

    // const fetchData = async (url, setData, isDropdownData = false, labelField, valueField) => {
    //     try {
    //         const data = await fetchAllPromisedData(url, true);
    //         if (data?.code === 200) {
    //             if (isDropdownData) {
    //                 setData(data?.data?.map(el => ({ label: el[labelField], value: el[valueField] })));
    //             } else {
    //                 setData(data?.data);
    //             }

    //         } else {
    //             setData([]);
    //         }
    //     } catch (err) {
    //         console.log(err);
    //     }
    // }

    const fetchQrCodes = async (page, dropdownData = dropdown) => {
        const reqObj = {
            regionId: getDropdownIdsFromSelectArray(dropdownData?.region),
            businessAreaId: getDropdownIdsFromSelectArray(dropdownData?.businessArea),
        }
        setQrcodesLoading(true);
        try {
            const resp = await fetchAllPostPromisedData(`${endpoint.qrCode.searchQr}?offset=${page}`, reqObj, 'post');
            console.log('resp------->>>', resp)
            if (resp?.code === 200) {
                // setQrCodeData(resp?.data?.qrCodeResponseDtoList?.map(el => ({
                //     ...el,
                //     regionAreaId: allActiveRegions?.filter(region => el?.regionAreaId?.includes(region?.value)),
                //     businessAreaId: allActiveBusinessAreas?.filter(bu => el?.businessAreaId?.includes(bu?.value))
                // })))
                setTotalRecords(resp?.data?.totalRecords);
                setQrCodeData(resp?.data?.qrCodeResponseDtoList?.map(el => ({
                    ...el,
                    regionAreaId: dropdownData?.region?.filter(region => el?.regionAreaId?.includes(region?.value)),
                    businessAreaId: dropdownData?.businessArea?.filter(bu => el?.businessAreaId?.includes(bu?.value))
                })))
            }
        } catch (err) {
            console.log(err);
        }
        setQrcodesLoading(false);
    }

    const init = async () => {
        const dropdownRes = await getDropdowns();
        setDropdown(dropdownRes);
        fetchQrCodes(0, dropdownRes);
    }

    useEffect(() => {
        // fetchData(endpoint.region.getAllActive, setAllActiveRegions, true, 'regionDispValue', 'id');
        // fetchData(endpoint.businessArea.getAllActive, setAllActiveBusinessAreas, true, 'businessAreaDispValue', 'id');
        // fetchData(`${endpoint.qrCode.searchQr}?offset=0`, setQrCodeData);
        init();
    }, []);

    const handleAddQr = () => {
        console.log('add QR clicked');
        console.log('open', addQrModalOpen)
        isSaveDisabled.current=false;
        setAddQrModalOpen(true);
        setForm(EMPTY_FORM);
    }

    const formHandler = (value, key) => {
        console.log('form Handler called', value, "----", key);
        setForm(prev => ({ ...prev, [key]: value, isDirty: true }))
    }

    // const handleDelete = async (id) => {
    //     const canContinue= await confirm();
    //     if(!canContinue) return;

    //     const resp = await fetchDeletePromisedData(`${endpoint.qrCode.deleteQr}/${id}`);
    //     if (resp?.code === 200) {
    //         successToast("Qr Code Deleted successfully");
    //         fetchQrCodes(currentPage);
    //     }
    // }

    const handleEditQr = (row) => {
        console.log('row------->>>', row)
        setForm(prev => ({
            ...prev,
            id: row?.id,
            qrCodeS3Key: row?.qrCode,
            effectiveDate: row?.effectiveDate,
            regions: row?.regionAreaId,
            businessAreas: row?.businessAreaId,
            status: row?.status,
            isDirty: false

        }))
        isSaveDisabled.current=false;
        setAddQrModalOpen(true);
        fetchBusinessArea(row?.regionAreaId, setBusinessAreaLoader, setBusinessAreaData, false);
    }

    const nextButtonHandler = (newPage) => {
        setCurrentPage(newPage);
        fetchQrCodes(newPage);
    }

    const downLoadFile = async (s3Key) => {
        // const templateUrl = await fetchAllPostPromisedData(`${endpoint.fetchS3Url.getDownloadUrl}?key=${s3Key}`, {})
        // if (templateUrl?.code === 200 && templateUrl?.data) {
        //     window.open(`${templateUrl?.data?.url}`)
        // } else {
        //     failureToast("Something went wrong please try after sometime.")
        // }
        setViewQrS3Key(s3Key);
        setViewQrModalOpen(true);
    };

    const fetchBusinessArea = async (regionsArray, setBusinessAreaLoader, setBusinessAreaData, editForm = true) => {
        if (regionsArray?.length > 0) {
            setBusinessAreaLoader(true);
            const responseData = await fetchBusinessAreasByRegions(getDropdownIdsFromSelectArray(regionsArray));
            setBusinessAreaData(responseData);
            console.log('responseData---->>', responseData);
            if (editForm) {
                let filteredBusinessAreas = form.businessAreas?.filter(el => responseData?.some(bu => bu?.value === el?.value));
                formHandler(filteredBusinessAreas, 'businessAreas');
            }
            setBusinessAreaLoader(false);
        } else {
            setBusinessAreaData([]);
            formHandler([], 'businessAreas');
        }
    }

    // useEffect(() => {
    //     fetchQrCodes(0);
    // }, [allActiveRegions, allActiveBusinessAreas]);

    const headers = [

        new AGridColDef("regionAreaId", "Region",false).setWidth(300)
            .renderCellComponent((params) => {

                return (
                    <MultiSelectCellComponentWithSearch
                    title={"Region"}
                    data={params?.value}
                    eyeIcon
                  />
                )
            }),

        new AGridColDef("businessAreaId", "Business Area",false).setWidth(300)
            .renderCellComponent((params) => {
                return (
                    <MultiSelectCellComponentWithSearch
                    title={"Business Area"}
                    data={params?.value}
                    eyeIcon
                  />
                )
            }),

        new AGridColDef("qrCode", "QR code",false).setWidth(50).setFlex(0.7)
            .renderCellComponent((params) => (
                <span className="view-qr" onClick={() => downLoadFile(params?.value)}>{'View'}</span>
            )),

        new AGridColDef("effectiveDate", "Effective Date",false).setWidth(100)
            .renderCellComponent((params) => (
                <div>{moment(params.value).format("DD-MMM-yyyy")}</div>
            )),

        new AGridColDef("status", "Status",false).setWidth(100)
            .renderCellComponent((params) => (
                params?.value==="ACTIVE" ?
                <span ><ActiveControl/> Active</span> :
                <span ><InactiveControl/> Inactive</span>
            )),

        new AGridColDef("id", "Actions",false).setWidth(100).setFlex(0.50)
            .renderCellComponent((params) => {
                return (
                        // {/* <EditIcon className="mr-3" onClick={() => handleEditQr(params.row)} /> */}
                        // {/* <DeleteIcon onClick={() => handleDelete(params.value)} /> */}
                        <AButton
                        variant="primary_filled"
                        size="xs"
                        onClick={()=>handleEditQr(params?.row)}
                        >
                            Edit
                        </AButton>

                )
            }),
    ]

    return (
        <>
            <div className="d-flex justify-content-between px-3">

                <div>
                    <BackArrow className="mr-3 mb-1" style={{ cursor: 'pointer' }} onClick={() => history.goBack()} />
                    <span className="manage-qr-hdr">QR code management</span>
                </div>

                <AButton
                    variant="primary_filled"
                    children={'Add QR'}
                    onClick={handleAddQr}
                />
            </div>

            {qrCodesLoading ? <CustomLoader /> :
                !qrCodeData?.length ?
                    <EmptyQrListPlaceholder
                        className='h-100'
                        setAddQrModalOpen={setAddQrModalOpen}
                        handleAddQr={handleAddQr}
                    /> :
                    <Card className="m-4 p-2">
                        <ADataGrid
                            rows={qrCodeData || []}
                            columns={headers}
                            rowId={(row) => JSON?.stringify(row)}
                            rowCount={totalRecords}
                            nextButtonHandler={newPage => nextButtonHandler(newPage)}
                            currentPage={currentPage}
                            rowHeight={50}
                            height={100}
                            loading={false}
                            // getRowClassName={(params) => isRowSelectable(params) ? '' : styles?.disabled_row}
                            components={{ ...gridComponents, ColumnUnsortedIcon: () => null }}
                            sx={{
                                '.MuiDataGrid-main': {
                                    height: `${60 + qrCodeData?.length * 50}px`,
                                    minHeight: `${60 + 50}px`,
                                    maxHeight: `${60 + 50 * 50}px`
                                },
                                ...gridStyles
                            }}
                            hideFooterSelectedRowCount={true}
                            // checkboxSelection={true}
                            disableSelectionOnClick
                        // selectionModel={selectionModel}
                        // onSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel)}
                        // isRowSelectable={isRowSelectable}
                        />
                    </Card>
            }
            <AddQrModal
                open={addQrModalOpen}
                setOpen={setAddQrModalOpen}
                form={form}
                regionData={dropdown?.region}
                formHandler={formHandler}
                currentPage={currentPage}
                fetchQrCodes={fetchQrCodes}
                fetchBusinessArea={fetchBusinessArea}
                businessAreaData={businessAreaData}
                businessAreaLoader={businessAreaLoader}
                setBusinessAreaData={setBusinessAreaData}
                setBusinessAreaLoader={setBusinessAreaLoader}
                isSaveDisabled={isSaveDisabled}
            />

            <DocUploadModal  
            open={viewQrModalOpen}
            setOpen={setViewQrModalOpen}
            acceptedFileTypes={[".jpg", ".jpeg", ".png"]}
            maxUploadFileSize={5}
            serviceName={'QR_CODE_IMG'}
            uniqueKeyParameter={"qrCodeImage"}
            uploadedFileS3Key={viewQrS3Key}
            uploadedFileName={"QRImage"}
            modalTitle={"View QR Code"}
            viewUploadedImage={true}
            viewMode={true}
            key={viewQrS3Key}
            />

            {/* <DeleteConfirmationDialog
            title={"Are you sure you want to delete?"}
            crossIconRequired={true}
            leftAlign={true}
            nextButtonText={"Yes, Delete"}
            backButtonText={"No"}
            message={" "}
            /> */}
        </>
    );
}

export default QRCode;