import React, { useState, useContext } from "react";
import { Button, Row, FormGroup, Label, Col, Input } from "reactstrap";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  putpost,
} from "services/http";
import { CustomContainer, CustomCard } from "../../../common/commonComponents";
import CustomLoader from '../../../common/commonComponents/Loader/loader'
import SearchTable from "./searchTable";
import { documentServiceBaseUrl } from "services/http";
import { PermissionContext, RolePermissions } from "appContext";
import { PermisionDenied } from "../../../common/commonComponents";
import { endpoint, pages } from "../../../common/constant";
import CustomButton from "components/CustomButton";
import { ButtonText } from 'variables/Buttons';
import { failureToast, successToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';

const HeaderElement = ({ history, userPermissions }) => {
  return (
    <>
      <h3>Bulk Document Upload Search</h3>
      {/* <Button
        size="md"
        type="button"
        color="info"
        onClick={() => history.push("/admin/bulkUpload/new")}
      >
        Upload Documents
      </Button> */}
      <CustomButton
        className={'floatRight mx-1 '}
        content={ButtonText.BULKUPLOAD.NEW}
        permissionType={'C'}
        icon={true}
        onClick={() => history.push("/admin/bulkUpload/new")}
        permissionSet={userPermissions}
        type="upload"
      />
    </>
  );
};

const statusList = [
  { label: "UPLOADED", value: "UPLOADED" },
  { label: "SUCCESS", value: "SUCCESS" },
  { label: "FAILED", value: "FAILED" },
];

const DocumentBulkUpload = () => {

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["bulkUpload"]['id']);

  const history = useHistory();
  const [processId, setProcessId] = useState("");
  const [status, setStatus] = useState(null);
  const [description, setDescription] = useState("");

  const [serverData, setServerData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const getSearchResult = () => {
    if (
      (!processId || !processId.trim()) &&
      !status?.value &&
      (!description || !description.trim())
    ) {
      failureToast(
        "Please enter atleast one of the following  : Process Id , Status or Description"
      );
      return;
    }

    setIsFetching(true);

    const searchData = {
      description: description,
      processId: processId,
      status: status?.value ?? "",
    };

    putpost(
      `${documentServiceBaseUrl}/documentManagement/searchBulkUpload`,
      (data) => {
        successToast(data.message);
        setServerData(data.data);
        setIsFetching(false);
      },
      (data) => {
        setIsFetching(false);
        failureToast(data.message);
      },
      searchData,
      "post"
    );
  };

  const resetFields = () => {
    setProcessId("");
    setStatus(null);
    setDescription("");
    setServerData(null);
  };

  return (
    userPermissions ?
      <CustomContainer>
        <CustomCard HeaderElement={<HeaderElement history={history} userPermissions={userPermissions} />}>
          <Row className="p-4">
            <Col md={4}>
              <FormGroup>
                <Label className="form-control-label">Process ID</Label>
                <Input
                  value={processId}
                  className="tdInput"
                  onChange={(e) => { if (!isNaN(e.target.value)) { setProcessId(e.target.value) } }}
                  placeholder={"Enter Process ID"}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label className="form-control-label">Status</Label>
                <Select
                  value={status}
                  options={statusList}
                  onChange={(e) => {
                    setStatus(e);
                  }}
                  placeholder={"Select Status"}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label className="form-control-label">Description</Label>
                <Input
                  value={description}
                  className="tdInput"
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder={"Enter Description"}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="d-flex justify-content-end pl-4 pr-4 pb-4">
            {/* <Button
            color={"info"}
            size="sm"
            type="button"
            onClick={getSearchResult}
          >
            Search
          </Button>
          <Button color={"info"} size="sm" type="button" onClick={resetFields}>
            Reset
          </Button> */}
            <CustomButton
              className={'mx-1'}
              content={ButtonText.BULKUPLOAD.SEARCH}
              permissionType={'S'}
              icon={true}
              onClick={getSearchResult}
              permissionSet={userPermissions}
            />

            <CustomButton
              type="reset"
              className={'mx-1 reset-button'}
              content={ButtonText.BULKUPLOAD.RESET}
              permissionType={'R'}
              // icon={true}
              onClick={resetFields}
              permissionSet={userPermissions}
            />
          </div>
        </CustomCard>
        {isFetching ? (
          <div
            className="mx-auto text-center py-5 my-5"
            style={{ height: "100vh" }}
          >
            <CustomLoader
              apiLoader={isFetching}
              loaderHeight={"200px"}
              loaderWidth={"100%"}
            />
          </div>
        ) : (
          serverData && (
            <CustomCard noTitle={true}>
              <SearchTable data={serverData} userPermissions={userPermissions} />
            </CustomCard>
          )
        )}
      </CustomContainer> : <PermisionDenied />
  );

  // return (
  //     <Button
  //     size="md"
  //     type="button"
  //     color="info"
  //     onClick={() => history.push("/admin/bulkUpload/new")}
  //   >
  //     Upload Documents
  //   </Button>
  // )
};

export default DocumentBulkUpload;
