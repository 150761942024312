import React, { useState, useEffect } from "react";
import { Card, Table, CardHeader, Button, Col, Input } from "reactstrap";
import { MasterHeaderAuto } from "../../../../common/commonComponents/masterHeader/masterHeader";
import {
  getAPI,
  pricingServiceBaseUrl,
  masterServiceBaseUrl,
  putpost,
  putpost2
} from "services/http";
import CustomButton from "components/CustomButton";
import { successToast, failureToast } from "../../../../common/utils/methods/toasterFunctions/function"
import CustomDownload from "../../../../common/commonComponents/CustomDownload" 

const SupportingDocument = (props) => {
  const { pid, isDisabled=false,action,userPermissions, isTitleRequired } = props;
  const [data, setData] = useState(null);
  const [progressBarStatus, setProgressBarStatus] = useState(0);
  const [showModel, setModel] = useState(false);
  const headerList = [
    { name: "S.NO" },
    { name: "File Name" },
    { name: "Action" },
  ];
  const [isFetching, setIsFetching] = useState(true);

  // {
  // "bucket": "string",
  // "etag": "string",
  // "filename": "string",
  // "filepath": "string",
  // "key": "string",
  // "processInstanceId": 0,
  // "uploaderId": "string"
  //}

  const getSupportedDataById = () => {
    getAPI(
      `${pricingServiceBaseUrl}/getSupportingDocsByCSVPID/${pid}`,
      (data) => {
        setData(data.data);
        setIsFetching(false);
      },
      (data) => {
        failureToast(data["message"]);
        setIsFetching(false);
      }
    );
  };

  const deleteDocument = (id) => {
    if (window.confirm('Are you sure you want to delete this Document?')) {
      putpost2(`${pricingServiceBaseUrl}/deleteSupportingDoc/${id}`, (data) => {
        successToast(data['message'])
        getSupportedDataById();
      }, (data) => { failureToast(data['message']) }, 'DELETE')
    }
  }

  useEffect(() => {
    getSupportedDataById();
  }, [pid]);

  const [isUploading, setISUploading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const [file, setFile] = useState(null);

  const handleFile = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };


  const chkFile = () => {
	
		if (!file) {
			setProgressBarStatus(0);
			failureToast('Please select file');
			return;
		}
		setISUploading(true);
		setProgressBarStatus(10);
		getAPI(
			`${pricingServiceBaseUrl}/validateFile?filename=${file.name}`,
			(data) => {
				if (data.code === 200) {
					checkForLocation();
					setProgressBarStatus(25);
				}
				// submitOnAws()
			},
			(data) => {
				failureToast(
					'Please, modify the file name and upload again as a file with same name is already exist in DB.'
				);
				setISUploading(false);
				setProgressBarStatus(0);
			}
		);
	};

  const checkForLocation = () => {
    getAPI(
      `${pricingServiceBaseUrl}/checkFileStorageLocation`,
      (data) => {
        if (data.code === 200 && data.data) {
          
          setProgressBarStatus(70);
          
          let fileSize = 0;

          if(data.data.sizeIn === 'MB'){
            fileSize = file.size / 1024 / 1024;
          }

          if(data.data.sizeIn === 'KB'){
            fileSize = file.size
          }

          if (fileSize > Number(data.data.size)) {
            failureToast('File size is less than 2 mb');
            setFile(null);
            return;
          }else{
            onSubmit(data.data.url);
          }
        }
        // submitOnAws()
      },
      (data) => {
        failureToast(data['message']);
        setISUploading(false);
        setProgressBarStatus(0);
      }
    );
  };

  const onSubmit = (url) => {
   

  

    const fileData = new FormData();
    fileData.append("File", file);
    fileData.append("destinationfolder", "image");

    const requestOptions = {
      method: "POST",
      body: fileData,
      Headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    fetch(url
     ,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => sendToServer(data))
      .catch((err) => {
        failureToast(err.message);
        setISUploading(false);
      });
  };

  const sendToServer = (data) => {
    const dataToServe = {
      key: data.response.Key,
      bucket: data.response.Bucket,
      etag: data.response.ETag,
      filename: file.name,
      filepath: data.response.Location,
      processInstanceId: pid,
      remark: "test",
      // status : 'ACTIVE'
    };
    putpost(
      `${pricingServiceBaseUrl}/uploadSupportingDocs`,
      (data) => {
        successToast(data["message"]);
        setIsVisible(false);
        setISUploading(false);
        setFile(null);
        getSupportedDataById();
      },
      (data) => {
        failureToast(data["message"]);
        setISUploading(false);
      },
      dataToServe,
      "post"
    );
  };

  return (
    data && data?.length ?
      <Card  className="mt-4">
        {isTitleRequired && <CardHeader className="d-flex justify-content-between">
          <h3 className="mb-0 ">Supporting Document</h3>
          {/* {action!=='view'?<Button
              color={isVisible || isDisabled ? "secondary" : "info"}
              size="sm"
              type="button"
              className={"floatRight"}
              disabled={isVisible || isDisabled}
              onClick={() => setIsVisible(true)}
          >
            Add
          </Button>:null} */}
        </CardHeader>}
        {/* {isVisible ? (
          <>
            
            <div className="form-row pl-4 pr-4 pb-4 pt-4 flex-column">
              <Col className="mb-3" xs="12">
                <label className="form-control-label">{`Document`}</label>
                <CustomDragAndDrop
                  setFile={setFile}
                  progressBarStatus={progressBarStatus}
                  handleFile={handleFile}
                  isUploading={isUploading}
                  changeIconColor={showModel}                
                />
              </Col>
              <Col className="d-flex justify-content-end align-items-center " xs="12">
                <Button
                  color="info"
                  size="sm"
                  type="button"
                  // className={"floatRight"}
                  onClick={chkFile}
                >
                  Submit
                </Button>
                <Button
                  color="info"
                  size="sm"
                  type="button"
                  //className={"floatRight"}
                  onClick={() => {
                    setFile(null);
                    setIsVisible(false);
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </div>
          </>
        ) : null} */}
        <div className="p-4">
          {isFetching}
          <Table className="align-items-center table-flush">
            <MasterHeaderAuto headerList={headerList}/>
            <tbody className="list">
              {data &&
                data.map((item, index) => {
                  return (
                    <tr>
                      <td className="text-center  white-breakSpace">
                        {index + 1}
                      </td>
                      <td className="text-center  white-breakSpace">
                        {item.filename}
                      </td>
                      <td className="text-center  white-breakSpace">
                        {/* <Button
                          color="info"
                          size="sm"
                          type="button"
                          onClick={()=> window.open(item.filepath, "_blank")}
                        >
                          <i className="fas fa-download" />
                        </Button> */}
                        {/* <CustomButton
                          type="download"
                          className={'mx-1'}
                          permissionType={'R'}
                          color="info"
                          icon={true}
                          forTable={true}
                          onClick={() => window.open(`${item.filepath}`)}
                          permissionSet={userPermissions}
                        /> */}
                         <CustomDownload 
                          downloadKey={item.key}
                          permissionSet={userPermissions}
                          isIcon={true}
                        />

                        {/* {action ==='view'?null:
                            <Button
                             // disabled={isDisabled}
                              color="info"
                              size="sm"
                              type="button"
                              onClick={() => deleteDocument(item.id)}
                            >
                              <i className="fas fa-trash"/>
                            </Button>} */}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </Card> : null
   
  );
};

export default SupportingDocument;
