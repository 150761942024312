import React, { useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import AAutoComplete from '../../../../common/form-fields-mui/AAutoComplete';
import AButton from '../../../../common/form-fields-mui/AButton';
import { getFilterCount, initialFilterForm } from './constant';

const SearchFilterForm = ({
  dropdowns,
  closeForm,
  filterCount,
  filterPayload,
  applyFilter = () => { },
}) => {

  const [filterForm, setFilterForm] = useState(filterPayload);
  const filterFormHandler = (value, key) => setFilterForm(prev => ({ ...prev, [key]: value }));
  useEffect(() => {
    setFilterForm(filterPayload);
  }, [filterPayload])

  return (
    <Card className='mb-0 regular-body label-color' style={{ width: '330px', borderRadius: '12px' }}>
      <CardBody className='d-flex flex-column'>
        <div className='mb-1 d-flex align-items-start justify-content-between'>
          <div className='regular-large-bold'>Filter by</div>
        </div>
        <div className='mt-2'>
          <AAutoComplete
            label="SAP business area"
            currentValue={filterForm?.sapBusinessArea}
            items={dropdowns?.sapBusinessArea}
            handleChange={(value) => filterFormHandler(value, 'sapBusinessArea')}
            isMulti={false}
          />
          <AAutoComplete
            label="Academic career"
            currentValue={filterForm?.academicCareer}
            items={dropdowns?.academicCareer}
            handleChange={(value) => filterFormHandler(value, 'academicCareer')}
            isMulti={false}
          />
          <AAutoComplete
            label="Academic group"
            currentValue={filterForm?.academicGroup}
            items={dropdowns?.academicGroup}
            handleChange={(value) => filterFormHandler(value, 'academicGroup')}
            isMulti={false}
          />
          <AAutoComplete
            label="Cost centre status"
            currentValue={filterForm?.costCenterStatus}
            items={[{ label: 'ACTIVE', value: 'ACTIVE' }, { label: 'INACTIVE', value: 'INACTIVE' }]}
            handleChange={(value) => filterFormHandler(value, 'costCenterStatus')}
            isMulti={false}
          />
        </div>
        <div className='mt-3 d-flex flex-row-reverse justify-content-between'>
          <AButton
            variant='primary_filled'
            className='button_remove_left_margin'
            onClick={() => { closeForm(); applyFilter(filterForm); }}
            disabled={!getFilterCount(filterForm)}
          >
            Apply filter
          </AButton>
          {filterCount > 0 && (
            <AButton
              variant='outline'
              className='button_remove_left_margin'
              updatedStyle={{ border: "1px solid #CC5F5F", color: '#CC5F5F' }}
              onClick={() => { applyFilter(initialFilterForm); }}
            >
              Remove filter
            </AButton>
          )}
        </div>
      </CardBody>
    </Card>
  )
}

export default SearchFilterForm