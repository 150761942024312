import { InputLabel } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import AButton from '../../common/form-fields-mui/AButton';
import AMultiSelect from '../../common/form-fields-mui/AMultiSelect';
import AMultiText from '../../common/form-fields-mui/AMultiText';
import { InputLabelStyle } from '../../common/form-fields-mui/ASelect';
import AInput from '../../common/formFeilds/customInput/AInput';
import CommonInput from '../../common/formFeilds/input/commonInput';
import AAutoComplete from '../../common/form-fields-mui/AAutoComplete'

const DeallocateStudentFilterForm = ({
  batchType,
  inputHandler,
  applyFilterHandler,
  removeFilter,
  form,
}) => {

  const dropdowns = useSelector(state => state.deallocateStudents.dropdowns);

  return (
    <Card className='mb-0'>
      <CardBody>
        <div style={{ color: '#414141', fontSize: '16px', fontFamily: 'Gilroy-Medium' }}>Filter</div>
        <Row>
          {/* <Col className='mb-2' md='12'>
            <AInput
              label="PSID"
              value={form.psid}
              handler={(value) => inputHandler(value, 'psid')}
            />
          </Col>
          <Col className='mb-2' md='12'>
            <AInput
              label="Application ID"
              value={form.applicationId}
              handler={(value) => inputHandler(value, 'applicationId')}
            />
          </Col> */}
          {batchType === 'SECONDARY' && <Col className='mb-2' md='12'>
            {/* <AMultiSelect
              label="Business Area"
              currentValue={form.businessArea}
              items={dropdowns && dropdowns.businessArea.allActive}
              inputKey="businessArea"
              handleChange={inputHandler}
            /> */}
            <AAutoComplete 
              label="Business Area"
              currentValue={form.businessArea}
              items={dropdowns && dropdowns.businessArea.allActive}
              handleChange={(value) => inputHandler(value, "businessArea")}
              isShowCount={true}
            />
          </Col>}
          {batchType === 'SECONDARY' && <Col className='mb-2' md='12'>
            {/* <AMultiText
              label="Course ID"
              type="number"
              values={form.courseId}
              inputHandler={inputHandler}
              inputKey={'courseId'}
            /> */}
            <AAutoComplete 
              label='Course ID'
              currentValue={form?.courseId}
              items={dropdowns && dropdowns?.courseId?.allActive}
              handleChange={(value) => inputHandler(value, 'courseId')}
              isShowCount={true}
            />
          </Col>}
          <Col className='mb-2' md='12'>
            <InputLabel style={InputLabelStyle}>Registration Date </InputLabel>
            <Row>
              <Col md='6'>
                <CommonInput
                  type={"datePicker"}
                  mandatory={true}
                  placeHolder="From"
                  value={form.startDate}
                  onChange={(value) =>
                    inputHandler(new Date(value), "startDate")
                  }
                />
              </Col>
              <Col md='6'>
                <CommonInput
                  type={"datePicker"}
                  mandatory={true}
                  placeHolder="To"
                  value={form.endDate}
                  onChange={(value) =>
                    inputHandler(new Date(value), "endDate")
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <AButton
              onClick={removeFilter}
              updatedStyle={{
                border: "1px solid #CC5F5F",
                borderRadius: "8px",
                color: "#CC5F5F",
              }}
            >
              Remove Filter
            </AButton>
          </Col>
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <AButton
              onClick={applyFilterHandler}
              updatedStyle={{
                background: "#00B0F5",
                border: "1px solid #00B0F5",
                borderRadius: "8px",
                color: "#fff"
              }}
            >
              Apply Filter
            </AButton>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default DeallocateStudentFilterForm