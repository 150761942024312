import React from 'react';
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import CommonInput from "../../../common/formFeilds/input/commonInput";
import TemplateHelpers from '../utils/templateHelpers'
import CustomButton from "../../../../../../components/CustomButton";

const NewTemplateHeader =(props)=>{
    let attributes = TemplateHelpers.getTemplateHeaderFieldsAttributes(props);
    return (
        <div style={{marginTop: '15px'}} className="col">
            <Card >
                <CardHeader>
                    <h3>{'Template Configurations'}</h3>
                </CardHeader>
                <CardBody>
                    <Row>
                        {
                            Object.entries(attributes).map((item,index)=>
                                <Col key={`input_colmn_${index}`} md={item[1]?.col}>
                                    <CommonInput  {...attributes[item[0]]}/>
                                </Col>
                            )
                        }
                    </Row>
                    {
                        (props.action === 'edit' && !props.isHeaderInEditMode )
                            ?
                            <Row>
                                <Col md={12}>
                                    <CustomButton className={'floatRight mx-1'} content={'Edit'} permissionType={'U'} onClick={() => {props.editHeaderHandler()}} permissionSet={props.userPermissions}/>
                                </Col>
                            </Row>
                            :
                            props.action === 'view' ? null :
                            <Row>
                                <Col md={12}>
                                    {
                                        props.action === 'edit' ?
                                            <Button disabled={props.isLoading}  color='danger' className='floatRight mx-1' size='md' onClick={() => {props.cancelHandler()}}>
                                                {'Cancel'}
                                            </Button>
                                            :
                                            null
                                    }
                                    <Button disabled={props.isLoading} color='info' className='floatRight mx-1' size='md' onClick={() => {props.submitHandler()}}>
                                        {
                                            props.isLoading ?
                                                <i className="fas fa-spinner fa-spin" aria-hidden="true">{''}</i>
                                                : null
                                        }
                                        <span >
                                            {'Submit'}
                                        </span>
                                    </Button>
                                </Col>
                            </Row>
                    }
                </CardBody>
            </Card>
        </div>
    );
}
export default NewTemplateHeader;