import React, {useState, Fragment,useEffect} from 'react';
import {ToastContainer} from 'react-toastify';
import {
    masterServiceBaseUrl,
    failureToast,
    getAPI,
    enrollmentServiceBaseUrl,
    examControllerServiceBaseUrl, putpost, successToast
} from 'services/http';
import {Container, Button,Card,CardHeader,Row,Col,CardBody,Form} from 'reactstrap';
import CommonInput from "../../../../common/formFeilds/input/commonInput";
import {
    fetchDetails,
    getDispValForReactSelectByOneID
} from "../../../../common/utils/methods/commonMethods/utilityMethod";
import {AsyncSelectSearch} from "../../../../common/commonComponents";
import {fetchData, getExamDates} from "../../../studentAdmission/applicationIdGeneration/applicationIdFunctions";
import {useHistory} from "react-router-dom";
import CustomLoader from "../../../../common/commonComponents/Loader/loader";
import moment from "moment";
import CustomDatePicker from "../../../../common/commonComponents/CustomDatePicker";
const apiEndPoints = {
    // getById : 'http://10.10.2.223:8593/api/masterServices/internalTestResult/getInternalTestResultById',
    getById : `${examControllerServiceBaseUrl}/internalTestResult/getInternalTestResultById`,
    // getAll : `${examControllerServiceBaseUrl}/internalTestResult/getAllActiveInternalTestResult`,
    getAll : `http://10.10.2.223:8593/api/examService/internalTestResult/getAllActiveInternalTestResult`,
    // delete : `${masterServiceBaseUrl}/epmrStatus/deleteEpmrStatus/`,
    update : `${examControllerServiceBaseUrl}/internalTestResult/updateInternalTestResult`,
    // create : `${masterServiceBaseUrl}/epmrStatus/createEpmrStatus`
}


export const PostData = async (url,data,method)=>{
    return new Promise((resolve,reject)=>{
        putpost(url,(data)=>{
            resolve(data);
        },(data)=>{
            resolve(data)
        },data,method);
    });
}
// const getExamIdDispValue = (data,id)=>{
//     let dispValue = data.filter(item => parseInt(item.id) === parseInt(id));
//     if(dispValue.length){
//         return dispValue[0]?.text;
//     } else return 'Not Found';
// }
const InternalTestResult = (props)=>{
    let history = useHistory();
    const { action='new' , id=null } = props.match.params;

    const [formData,setFormData] = useState({
        studentId:'',
        studentName:'',
        businessAreaId:'',
        rollNumber :'',
        examId : '',
        examDescription : '',
        testRecId : '',
        adjustmentFlag : '',
        examType : '',
        academicYearId : '',
        percentage : '',
        percentile : '',
        allIndRank : '',
        examDate : '',
        status : 'ACTIVE'
    });

    const statusData = [{id:'ACTIVE',text:'ACTIVE'},{id:'INACTIVE',text:'INACTIVE'}]
    const [apiLoader,setApiLoader] = useState(false);
    const [businessArea,setBusinessArea] = useState([]);
    const [examIds,setExamIds] = useState([]);
    const [ academicYear, setAcademicYear ] = useState([] );
    // const [ examDate,setExamDate ] = useState([]);
    const [ examType,setExamType ] = useState([]);

    useEffect(()=>{
        if(businessArea.length && examIds.length && academicYear.length){
            setApiLoader(false);
        }
    },[businessArea,examIds,academicYear])
    const   fetchRequiredData =async()=>{
        if(action === 'new' || action === 'edit'){
            fetchData(examControllerServiceBaseUrl+'/exam/getAllActiveExamId',setExamIds,'exam');
            fetchData(masterServiceBaseUrl + '/academicYear/all',setAcademicYear,'academicYear');
            // getExamDates(examControllerServiceBaseUrl+'/examDateDetails/getAllExamDateDetails',setExamDate)
            fetchDetails(masterServiceBaseUrl + `/testType/getAllTestType`, setExamType, 'testType', 'reactSelect');
        }
        if(action === 'edit'){
            await getAPI(
                `${apiEndPoints.getById}/${id}`,
                ({data})=>{
                    setFormData({
                        id : data.id,
                        studentId: {value:data.studentId,label:data.studentId},
                        studentName : data.studentName,
                        rollNumber : data.rollNumber,
                        businessAreaId : data.businessAreaId,
                        examId : data.examId,
                        examDescription : data.examDescription,
                        testRecId : data.testRecId,
                        adjustmentFlag : data.adjustmentFlag,
                        examType : data.examType,
                        academicYearId : data.academicYearId,
                        percentage : data.percentage,
                        percentile : data.percentile,
                        allIndRank : data.allIndRank,
                        examDate : data.examDate,
                        status : data.status,
                        generatedExamId:data.generatedExamId
                    })
                    // setApiLoader(false);
                },
                (data)=>{

                });
        }
        fetchDetails(masterServiceBaseUrl + `/businessArea/getAllBusinessArea`, setBusinessArea, 'businessArea', 'reactSelect');
    }

    useEffect(()=>{
        fetchRequiredData().then();
    },[]);


    const updateData = (key,value)=>{
        if (key === 'studentId') {
            setFormData(preState => ({
                ...preState,
                [key]: value,
                studentName: value.studentName
            }));
        } else if ( key === 'examId' ) {
            if(examIds.length > 0){
                let selectedExamId = examIds.filter( item => parseInt( item.id ) === parseInt( value ));
                if ( selectedExamId.length > 0 ) {
                    setFormData(preState => ( {
                        ...preState,
                        [key]: value,
                        examDescription: selectedExamId[0]['desc']
                    } ));
                }
            }
        }else if (key === 'examDate'){
            setFormData(preState => ({...preState, [key]: moment(value).format('YYYY-MM-DD')}));
        } else{
            setFormData(preState => ({...preState, [key]: value,}));
        }
    }

    const checkValidations = ()=>{
        if ( formData.studentId === '' ) {
            failureToast('Please Enter Student Id.');
            return false;
        } else if ( formData.rollNumber === '' ) {
            failureToast('Please Enter Roll Number.');
            return false;
        } else if ( formData.businessAreaId === '' ) {
            failureToast('Please Select Business Area');
            return false;
        } else if ( formData.examId === '' ) {
            failureToast('Please Select Exam Id.');
            return false;
        } else if ( formData.testRecId === ''  ) {
            failureToast('Please Enter Test Record ID.');
            return false;
        } else if ( formData.adjustmentFlag ==='' ) {
            failureToast('Please Enter Adjustment Flag.');
            return false;
        } else if ( formData.examType === '' ) {
            failureToast('Please Select Exam Type.');
            return false;
        } else if ( formData.academicYearId === '' ) {
            failureToast('Please Select Academic Year.');
            return false;
        } else if ( formData.percentage === '' ) {
            failureToast('Please Enter Percentage.');
            return false;
        } else if ( formData.percentile === ''  ) {
            failureToast('Please Enter Percentile.');
            return false;
        } else if ( formData.allIndRank === '' ) {
            failureToast('Please Enter All India Rank.');
            return false;
        } else if ( formData.examDate === '' ) {
            failureToast('Please Select Exam Date.')
            return false;
        } else {
            return true;
        }
    }


    const submitHandler =()=>{
            // let isValid = checkValidations();
            let isValid = true;
            let tempObject = formData;
            tempObject = {
                ...tempObject,
                studentId : tempObject.studentId.value
            }
            if(isValid){
                if( action === 'edit' ){
                    let url = apiEndPoints.update+'/' + formData.id;
                    let method = 'PUT';
                    PostData(url,tempObject,method).then((data)=>{
                        if(data.code === 200){
                            successToast(data.message);
                            history.push(`/admin/internalTestResult/view/${formData.id}`);
                        }else if(data.code === 400){
                            failureToast(data.message);
                        }
                    });
                } else if( action === 'new' ) {
                    let url = '';
                    let method = 'POST';
                }
            }
    }
    // const handleClick =()=> {
    //     submitHandler()
    //     window.open( `${ document.URL }/view/${formData.id}` );
    // }

    const filterOption = (data) => {
        return data?.map((data) => {
            return Object.assign(
                {},
                {
                    label: data?.studentId,
                    value: data?.studentId,
                    studentName: `${data?.studentFirstName} ${data?.studentLastName}`,
                }
            );
        });
    };


    const promiseOptions = (inputValue) => {
        return new Promise((resolve) => {
            if (inputValue && inputValue.length < 10) {
                return resolve([]);
            }
            getAPI(
                `${enrollmentServiceBaseUrl}/studentDetails/getAllStudentBySmartSearch/${inputValue}`,
                (data) => {
                    resolve(filterOption(data?.data));
                },
                (data) => {
                    // failureToast(data["message"]);
                }
            );
        });
    };



    return(
        apiLoader
            ?
            <div className="mx-auto text-center py-5 my-5" style={{ height: "100vh" }}>
                <CustomLoader apiLoader={apiLoader}/>
            </div>
            :
        <Fragment>
            <div style={{marginTop: '15px'}} className="col">
                <div className="card-wrapper">
                    <Card>
                        <CardHeader>
                            <h3 className="mb-0">Internal Test Result</h3>
                        </CardHeader>
                        <CardBody>
                            <Row>

                                <Col md={3}>
                                    <CommonInput label={'Student ID'}
                                                 // data={studentIds}
                                                 type={'smartSearchSelect'}
                                                 placeHolder={'Student ID'}
                                                 onChange={(data)=>{
                                                     updateData('studentId',data)
                                                 }}
                                                 // defaultValue={{value :formData.studentId,label:formData.studentId}}
                                                 defaultValue={formData.studentId}
                                                 isDisabled={action === 'view' ? true : false}
                                                 promiseHandler={promiseOptions}
                                    />
                                </Col>

                                <Col md={3}>
                                    <CommonInput label={'Student Name'}
                                                 type={'text'}
                                                 placeHolder={'Student Name'}
                                        // onChange={(value)=>{updateSearchData('studentId',value)}}
                                                 defaultValue={formData.studentName}
                                                 isDisabled={true}
                                    />
                                </Col>

                                <Col md={3}>
                                    <CommonInput label={'Roll Number'}
                                                 type={'text'}
                                                 maxLength={15}
                                                 allowedSomeSpecialCharacters={true}
                                                 regEx={/[^0-9a-zA-Z_]/g}
                                                 restrictSpecialCharacter={true}
                                                 placeHolder={'Roll Number'}
                                                 onChange={(data)=>{
                                                     updateData('rollNumber',data);
                                                 }}
                                                 defaultValue={formData.rollNumber}
                                                 isDisabled={ action === 'view' ? true : false }
                                    />
                                </Col>

                                <Col md={3}>
                                    <CommonInput label={'Business Area'}
                                                 data={businessArea}
                                                 type={action=== 'edit' ? 'text' : 'reactSelect'}
                                                 placeHolder={'Business Area'}
                                                 onChange={(value)=>{
                                                     updateData('businessAreaId',value)
                                                 }}
                                                 defaultValue={action === 'edit' ? getDispValForReactSelectByOneID(formData["businessAreaId"] ,'DispValue',businessArea):formData["businessAreaId"]}
                                                 isDisabled={action === 'edit' || action === 'view'}
                                    />
                                </Col>

                                <Col md={3}>
                                    <CommonInput label={'Exam ID'}
                                                 data={examIds}
                                                 type={ action ==='edit' ? 'text' : 'reactSelect'}
                                                 // type={ 'reactSelect'}
                                                 placeHolder={'Exam ID'}
                                                 onChange={(value)=>{updateData('examId',value)}}
                                                 // defaultValue={ action === 'edit' ? getExamIdDispValue(examIds,formData.examId) : formData.examId}
                                                 defaultValue={ action === 'edit' || action === 'view' ? formData.generatedExamId : formData.examId}
                                                 isDisabled={action === 'edit' || action === 'view'}
                                    />
                                </Col>

                                <Col md={3}>
                                    <CommonInput label={'Exam Description'} type={'text'} placeHolder={'Exam Description'} defaultValue={formData["examDescription"]} isDisabled={true}/>
                                </Col>

                                <Col md={3}>
                                    <CommonInput label={'Test Rec ID'}
                                                 type={'text'}
                                                 maxLength={10}
                                                 allowedSomeSpecialCharacters={true}
                                                 regEx={/[^0-9a-zA-Z_]/g}
                                                 restrictSpecialCharacter={true}
                                                 placeHolder={'Test Rec ID'}
                                                 onChange={(value)=>{updateData('testRecId',value)}}
                                                 defaultValue={formData["testRecId"]}
                                                 isDisabled={ action === 'edit' || action === 'view' }
                                    />
                                </Col>

                                <Col md={3}>
                                    <CommonInput label={'Adjustment Flag'}
                                                 type={'text'}
                                                 maxLength={1}
                                                 placeHolder={'Adjustment Flag'}
                                                 onChange={(value)=>{updateData('adjustmentFlag',value?.toUpperCase())}}
                                                 defaultValue={formData["adjustmentFlag"]}
                                                 isDisabled={ action === 'view' ? true : false}
                                    />
                                </Col>

                                {/*<Col md={3}>*/}
                                {/*    <CommonInput label={'Exam Type'}*/}
                                {/*                 data={examType}*/}
                                {/*                 type={ action ==='edit' ? 'text' : 'reactSelect'}*/}
                                {/*                 placeHolder={'Exam Type'}*/}
                                {/*                 onChange={(value)=>{updateData('examType',value)}}*/}
                                {/*                 defaultValue={formData["examType"]}*/}
                                {/*                 isDisabled={ action ==='edit' }*/}
                                {/*    />*/}
                                {/*</Col>*/}

                                <Col md={3}>
                                    <CommonInput label={'Academic Year'}
                                                 data={academicYear}
                                                 type={'reactSelect'}
                                                 placeHolder={'Academic Year'}
                                                 isDisabled={ action ==='view' }
                                                 onChange={(value)=>{updateData('academicYearId',value)}}
                                                 defaultValue={formData["academicYearId"]}
                                    />
                                </Col>

                                <Col md={3}>
                                    <CommonInput label={'Percentage'}
                                                maxLength={3}
                                                 type={'number'}
                                                 placeHolder={'Percentage'}
                                                 isDisabled={ action === 'view' ? true : false }
                                                 onChange={(value)=>{updateData('percentage',value)}}
                                                 defaultValue={formData["percentage"]}
                                    />
                                </Col>

                                <Col md={3}>
                                    <CommonInput label={'Percentile'}
                                                 maxLength={3}
                                                 type={'number'}
                                                 placeHolder={'Percentile'}
                                                 onChange={(value)=>{updateData('percentile',value)}}
                                                 defaultValue={formData["percentile"]}
                                                 isDisabled={ action === 'view' ? true : false }
                                    />
                                </Col>

                                <Col md={3}>
                                    <CommonInput label={'AIR'}
                                                 maxLength={6}
                                                 type={'number'}
                                                 placeHolder={'AIR'}
                                                 onChange={(value)=>{updateData('allIndRank',value)}}
                                                 defaultValue={formData["allIndRank"]}
                                                 isDisabled={ action ==='edit' || action === 'view' }
                                    />
                                </Col>

                                <Col md={3}>
                                    <CommonInput label={ 'Exam Date' }
                                                 type={ 'datePicker' }
                                                 placeHolder={ 'Exam Date' }
                                                 defaultValue={ formData["examDate"] }
                                                 isDisabled={ action === 'view' ? true : false }
                                                 onChange={(value)=>{updateData('examDate',value)}}
                                    />
                                </Col>

                                <Col md={3}>
                                    <CommonInput label={'Status'}
                                        data={statusData}
                                        type={'reactSelect'}
                                        placeHolder={'Status'}
                                        onChange={(value)=>{updateData('status',value)}}
                                        defaultValue={formData["status"]}
                                        isDisabled={action === 'view' ? true : false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {action === 'edit' ? (<Button color="info" size="sm"
                                        // className={"floatRight mx-1" +((!selectedAcademicCareer && !selectedCompanyCode && !selectedGroupCode) ? ' btn-dark' : null)}
                                             className={"floatRight mx-1"} onClick={() => submitHandler()}
                                    >
                                        Submit
                                    </Button>) : (<Button color="info" size="sm"
                                        // className={"floatRight mx-1" +((!selectedAcademicCareer && !selectedCompanyCode && !selectedGroupCode) ? ' btn-dark' : null)}
                                                        className={"floatRight mx-1"} onClick={() => history.push('/admin/internalTestResult')}
                                    >
                                        Close
                                    </Button>)}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </Fragment>
    )
}
export default InternalTestResult;