import React from "react";
import { ModalFooter } from "reactstrap";
import { ReactComponent as FiInfo } from "../../../../../assets/img/svg/fi_info.svg";
import { isBasicFormFilled } from "./style";
import AButton from "./../../common/form-fields-mui/AButton";

const FormFooter = ({
  onClose,
  basicForm,
  nextButtonHandler,
  isSecondFormOpen,
  saveTestFormHandler,
  isTestDetailsUpdate,
  isResultDatePassed,
  saveOnEdit
}) => {
  return (
    <ModalFooter className="justify-content-between">
      <span>
        <FiInfo /> All fields are mandatory
      </span>
      <span>
        {!isSecondFormOpen && !isResultDatePassed  &&<AButton
          disabled={!isBasicFormFilled(basicForm)}
          onClick={nextButtonHandler}
          variant="primary_filled"
          updatedStyle={{
            width: "140px",
            background: !isBasicFormFilled(basicForm)
              ? "#00000066"
              : " #00B0F5",
          }}
        >
          Next
        </AButton>
       }
        {(isSecondFormOpen ) &&  (
          <AButton
            disabled={!isBasicFormFilled(basicForm) || !isTestDetailsUpdate}
            onClick={saveTestFormHandler}
            variant="primary_filled"
            updatedStyle={{
              width: "140px",
              background: !isBasicFormFilled(basicForm)
                ? "#00000066"
                : " #00B0F5",
            }}
          >
            Save Test
          </AButton>
        )}
         {isResultDatePassed  &&  (
          <AButton
            disabled={!isBasicFormFilled(basicForm)}
            onClick={saveOnEdit}
            variant="primary_filled"
            updatedStyle={{
              width: "140px",
              background: !isBasicFormFilled(basicForm)
                ? "#00000066"
                : " #00B0F5",
            }}
          >
            Save Test
          </AButton>
        )}

        
      </span>
    </ModalFooter>
  );
};

export default FormFooter;
