import React, {Fragment, useState} from 'react';
import {CardBody, CardHeader, Card, Button, Table} from "reactstrap";
import {CustomTableRow} from "../../../common/commonComponents/TableComponents";
import {MasterHeaderAuto} from "../../../common/commonComponents/masterHeader/masterHeader";
import {useSelector} from "react-redux";
import moment from "moment";
import CustomButton from "components/CustomButton";

const ItemTypeDetailsAndDescriptionTable = (props) => {
    const itemTypeData = useSelector((state) => state.globalData.data);
    const TableData =(props)=>{
        const {item,index} = props;

        return(
            <CustomTableRow>
                <td className="text-center p-2 mx-1">{item?.itemTypeId}</td>
                <td className="text-center p-2 mx-1">{item?.itemTypeDescription}</td>
                <td className="text-center p-2 mx-1">{moment(item.dueDate).format('DD-MM-YYYY')}</td>
                <td className="text-center p-2 mx-1">{item?.grossAmount}</td>
                <td className="text-center p-2 mx-1">{item?.concessionAmount}</td>
                <td className="text-center p-2 mx-1">{item?.lumpsumAmount}</td>
                <td className="text-center p-2 mx-1">{item?.netPayable}</td>
                <td className="text-center p-2 mx-1">{item?.taxAmount}</td>
                <td className="text-center p-2 mx-1">{item?.finalPayableAmount}</td>
            </CustomTableRow>
        )
    }

    const headerList=[
        {name:'Item Type'},
        {name:'Item Type Description'},
        {name:'Due Date'},
        {name:'Gross amount'},
        {name:'Concession Amount'},
        {name:'Lumpsum Concession Amount'},
        {name:'Net Payable'},
        {name:'Taxes'},
        {name:'Net Payable(Incl. Taxes)'},
    ];
    return(
        <Fragment>

            <div style={{marginTop: '15px'}} className="col">
                <Card >
                        <CardHeader>
                            <h3>Item Type Details And Description</h3>
                        </CardHeader>
                        <div className="table-responsive">
                            <Table className="align-items-center table-flush ">
                                <MasterHeaderAuto headerList={headerList} permissionSet={props.userPermissions}/>
                                <tbody className="list" key={'itemTypeDetailsAndDescription'}>
                                    {
                                        itemTypeData?.feeCalculatorItemTypeMapping &&
                                        itemTypeData?.feeCalculatorItemTypeMapping.length > 0 &&
                                        itemTypeData.feeCalculatorItemTypeMapping.map((item, index) => <TableData item={item} index={index}/>)
                                    }
                                    <tr>
                                        <td className="text-center p-2 mx-1">{''}  </td>
                                        <td className="text-center p-2 mx-1">{''}</td>
                                        <td className="text-center p-2 mx-1"> <h4>Grand Total :  </h4> </td>
                                        <td className="text-center p-2 mx-1"> <h4>{itemTypeData?.feeCalculatorGrandTotalDetailsDto?.totalGrossAmount}</h4> </td>
                                        <td className="text-center p-2 mx-1"> <h4>{itemTypeData?.feeCalculatorGrandTotalDetailsDto?.totalConcessionAmount}</h4> </td>
                                        <td className="text-center p-2 mx-1"> <h4>{itemTypeData?.feeCalculatorGrandTotalDetailsDto?.totalLumpsumAmount}</h4> </td>
                                        <td className="text-center p-2 mx-1"> <h4>{itemTypeData?.feeCalculatorGrandTotalDetailsDto?.netPayabletotalAmount}</h4> </td>
                                        <td className="text-center p-2 mx-1"> <h4>{itemTypeData?.feeCalculatorGrandTotalDetailsDto?.totalTaxAmount}</h4> </td>
                                        <td className="text-center p-2 mx-1"> <h4>{itemTypeData?.feeCalculatorGrandTotalDetailsDto?.totalFinalPayableAmount}</h4> </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    <CardBody>
                        {/* <Button className={'floatRight'} onClick={()=>window.close()} color='info' size='sm'>Close</Button></CardBody> */}
                        <CustomButton
                            className={`floatRight`}
                            content={'Close'}
                            permissionType={'cancel'}
                            // forTable={true}
                            onClick={()=>window.close()}
                            permissionSet={props.userPermissions}
                        />
                    </CardBody>
                    </Card>
            </div>

        </Fragment>

    )
}

export default ItemTypeDetailsAndDescriptionTable;
