import { PermissionContext, RolePermissions } from "appContext";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { getAPI, masterServiceBaseUrl } from 'services/http';
import { PermisionDenied } from "../../common/commonComponents";
import CustomLoader from "../../common/commonComponents/Loader/loader";
import { pages } from "../../common/constant";
import { GetPagination } from "../../common/commonComponents/pagination/pagination";
import ItemTypeCategoryTable from './itemTypeCategoryTable';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';

const ItemTypeCategory = () => {
  const [itemTypeCategoryArray, setitemTypeCategoryArray] = useState([])
  const [apiloader, setapiloader] = useState(false)
  const [state, setstate] = useState([])
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null
  });

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["ItemTypeCategory"]['id']);

  const pageSize = 10
  const fetchItemTypeCategory = async (url) => {
    setapiloader(true)
    getAPI(url, (data) => {
      setstate(data['data'])
      let n = pagination
      if (data['data'].length > pageSize) {
        n['nextPage'] = n.currentPage + 1
      }
      if (n.currentPage > 1) {
        n.previousPage = n.currentPage - 1
      }
      n['totalPage'] = Math.floor(data['data'].length / pageSize)
      if (data['data'].length % pageSize != 0) {
        n['totalPage'] += 1
      }
      setpagination(n)
      setapiloader(false)
      setitemTypeCategoryArray(data['data'].slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || [])
    }, (data) => { failureToast(data['message']) })
  }
  const nextPage = () => {
    setitemTypeCategoryArray(state.slice((pagination.currentPage + 1) * (pageSize) - pageSize, (pagination.currentPage + 1) * pageSize))
    let n = pagination
    n['currentPage'] = n['currentPage'] + 1
    n['previousPage'] = n.currentPage
    setpagination(n)
  }
  const firstPage = () => {
    setitemTypeCategoryArray(state.slice(1 * (pageSize) - pageSize, 1 * pageSize))
    let n = pagination
    n['currentPage'] = 1
    n['previousPage'] = null
    setpagination(n)
  }
  const lastPage = () => {
    setitemTypeCategoryArray(state.slice((pagination.totalPage) * (pageSize) - pageSize, (pagination.totalPage) * pageSize))
    let n = pagination
    n['currentPage'] = n.totalPage
    n['previousPage'] = n.totalPage - 1
    setpagination(n)
  }
  const previousPage = () => {
    if (pagination.currentPage != 1) {
      setitemTypeCategoryArray(state.slice((pagination.currentPage - 1) * (pageSize) - pageSize, (pagination.currentPage - 1) * pageSize))
      let n = pagination
      n['currentPage'] = n['currentPage'] - 1
      if (n['currentPage'] == 1) {
        n['previousPage'] = null
      } else {
        n['previousPage'] = n.currentPage
      }
      setpagination(n)
    }
  }
  useEffect(() => {
    fetchItemTypeCategory(masterServiceBaseUrl + '/itemTypeCategory/getAllItemTypeCategory')
  }, [])
  return (
    <>
      {apiloader ? <CustomLoader
        apiLoader={apiloader}
        loaderHeight={'200px'}
        loaderWidth={'100% '}
      /> :
        <Fragment>
          {userPermissions ? <>
            <ItemTypeCategoryTable
              userPermissions={userPermissions}
              previousPage={previousPage}
              fetchItemTypeCategory={fetchItemTypeCategory}
              setitemTypeCategoryArray={setitemTypeCategoryArray}
              itemTypeCategoryArray={itemTypeCategoryArray}
              isSaveVisible={isSaveVisible}
              setisSaveVisible={setisSaveVisible}
            />
            {pagination.totalPage > 1 ?
              <GetPagination
                isSaveVisible={isSaveVisible}
                fetchClasses={fetchItemTypeCategory}
                setclassArray={setitemTypeCategoryArray}
                pagination={pagination}
                setpagination={setpagination}
                pageSize={pageSize}
                state={state}
              />
              : null}
          </>
            : <PermisionDenied />}
        </Fragment>
      }
    </>
  );
}

export default ItemTypeCategory;