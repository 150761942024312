import React from 'react'
import { AGridColDef } from '../../../../common/data-grid/ADataGridColDef'
import ADataGrid from '../../../../common/data-grid/ADataGrid'
import styles from './styles.module.scss'
import { gridStyles, gridComponents } from 'views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant.js'
import NoRowOverlay from '../../../../masters/holidayMaster/NoRowOverlay'
import moment from 'moment'


const ListComponent = ({ loader, rows }) => {

  const columns = [
    new AGridColDef('accountType', 'Account type', false).setMinWidth(90).setFlex(0.6),
    new AGridColDef('fromFinancialYearMonth', 'From financial year-month', false),
    new AGridColDef('toFinancialYearMonth', 'To financial year-month', false),
    new AGridColDef('fromCalenderYearMonth', 'From calendar year-month', false),
    new AGridColDef('toCalenderYearMonth', 'To calendar year-month', false),
    new AGridColDef('updatedOn', 'Last updated on', false).setFlex(0.7)
      .setValueGetter(params => moment(params?.value)?.format('DD-MMM-YYYY')),
    new AGridColDef('updatedBy', 'Last updated by', false).setFlex(0.7),
  ]

  return (
    <div className={`mt-4 ${styles.grid_container}`}>
      <ADataGrid 
        removeWrapperContainer
        rows={rows || []}
        columns={columns}
        rowId={(row) => row.id}
        rowHeight={50}
        loading={false}
        serverPagination={false}
        components={{ ...gridComponents, NoRowsOverlay: () => <NoRowOverlay message={loader ? 'Loading...' : null} /> }}
        sx={{
          ...gridStyles
        }}
        autoHeight={true}
      />
    </div>
  )
}

export default ListComponent