import React from 'react' ;
import { ReactComponent as LiftSideImage } from "../../../../../assets/img/svg/academic-bg.svg";
import AddTimeTable from './AddTimeTable';

const TimetableForm = ({param, ...props}) => {
  return (
    <div className=" remove-container-top-space time-table-container academic-plan-container">

        <LiftSideImage/>
         <div>
          <AddTimeTable 
           batchId = {param.id}
           {...props}
           />
         </div>
    </div>
  )
}

export default TimetableForm