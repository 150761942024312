import React, { useState, useEffect, useContext } from 'react';
import CompanyCodeTable from './companyCodeTable';
import { getAPI, masterServiceBaseUrl } from 'services/http';
import Loader from 'react-loader-spinner';
import { PermissionContext, RolePermissions } from 'appContext';
import { pages } from '../../common/constant';
import { PermisionDenied } from '../../common/commonComponents';
import { GetPagination } from '../../common/pagination';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';

const CompanyCode = () => {
	const [classArray, setclassArray] = useState([]);
	const [apiloader, setapiloader] = useState(false);
	const [companyCode, setcompanyCode] = useState([]);
	const [grp, setGrp] = useState([]);

	const [pagination, setpagination] = useState({
		currentPage: 1,
		nextPage: null,
		previousPage: null,
		totalPage: null,
	});
	const pageSize = 10;

	const permissions = useContext(PermissionContext);

	const userPermissions = RolePermissions(
		permissions,
		pages['companyCode']['id']
	);

	const fetchClasses = async (url) => {
		setapiloader(true);
		getAPI(
			url,
			(data) => {
				setcompanyCode(data['data']);
				let n = pagination;
				if (data['data'].length > pageSize) {
					n['nextPage'] = n.currentPage + 1;
				}
				if (n.currentPage > 1) {
					n.previousPage = n.currentPage - 1;
				}
				n['totalPage'] = Math.floor(data['data'].length / pageSize);
				if (data['data'].length % pageSize != 0) {
					n['totalPage'] += 1;
				}
				setpagination(n);
				setapiloader(false);
				setclassArray(
					data['data'].slice(
						pagination.currentPage * pageSize - pageSize,
						pagination.currentPage * pageSize
					) || []
				);
			},
			(data) => {
				failureToast(data['message']);
			}
		);
	};
	const nextPage = () => {
		setclassArray(
			companyCode.slice(
				(pagination.currentPage + 1) * pageSize - pageSize,
				(pagination.currentPage + 1) * pageSize
			)
		);
		let n = pagination;
		n['currentPage'] = n['currentPage'] + 1;
		n['previousPage'] = n.currentPage;
		setpagination(n);
	};
	const firstPage = () => {
		setclassArray(companyCode.slice(1 * pageSize - pageSize, 1 * pageSize));
		let n = pagination;
		n['currentPage'] = 1;
		n['previousPage'] = null;
		setpagination(n);
	};
	const lastPage = () => {
		setclassArray(
			companyCode.slice(
				pagination.totalPage * pageSize - pageSize,
				pagination.totalPage * pageSize
			)
		);
		let n = pagination;
		n['currentPage'] = n.totalPage;
		n['previousPage'] = n.totalPage - 1;
		setpagination(n);
	};
	const previousPage = () => {
		setclassArray(
			companyCode.slice(
				(pagination.currentPage - 1) * pageSize - pageSize,
				(pagination.currentPage - 1) * pageSize
			)
		);
		// console.log(
		// 	companyCode.slice(
		// 		(pagination.currentPage - 1) * pageSize - pageSize,
		// 		(pagination.currentPage - 1) * pageSize
		// 	)
		// );
		let n = pagination;
		n['currentPage'] = n['currentPage'] - 1;
		if (n['currentPage'] == 1) {
			n['previousPage'] = null;
		} else {
			n['previousPage'] = n.currentPage;
		}
		setpagination(n);
	};

	const fetchGrpCode = async (url) => {
		setapiloader(true);
		getAPI(
			url,
			(data) => {
				const grpCodeList = data.data.map((data) => {
					const { id, groupCodeDispValue, status } = data;
					if (status === 'ACTIVE') {
						return {
							id: id,
							text: groupCodeDispValue,
						};
					}
				});
				setGrp(grpCodeList);
			},
			(data) => {
				failureToast(data['message']);
			}
		);
	};

	useEffect(() => {
		fetchClasses(masterServiceBaseUrl + '/companyCode/getAllCompanyCode');
		fetchGrpCode(masterServiceBaseUrl + '/groupCode/getAllGroupCode');
	}, []);
	return (
		<>
			{apiloader ? (
				<div
					className="mx-auto text-center py-5 my-5"
					style={{ height: '100vh' }}
				>
					<Loader
						type="Rings"
						color="#00BFFF"
						height={70}
						width={70}
						timeout={10000}
					/>
				</div>
			) : (
				<>
					{userPermissions ? (
						<>
							<CompanyCodeTable
								fetchClasses={fetchClasses}
								setclassArray={setclassArray}
								classArray={classArray}
								data={grp}
								userPermissions={userPermissions}
							/>
							{pagination.totalPage > 1 ? (
								<GetPagination
									fetchClasses={fetchClasses}
									pagination={pagination}
									previousPage={previousPage}
									firstPage={firstPage}
									lastPage={lastPage}
									nextPage={nextPage}
									setpagination={setpagination}
								/>
							) : null}
						</>
					) : (
						<PermisionDenied />
					)}
				</>
			)}
		</>
	);
};
export default CompanyCode;
