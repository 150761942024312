import React from 'react'
import styles from './viewAcademicPlan.module.css'
import { Card, CardBody } from 'reactstrap'
import { ReactComponent as TestIcon } from '../../../../../assets/img/svg/TestIcon.svg'
import CustomChip from '../../common/customChip/CustomChip'
import AButton from '../../common/form-fields-mui/AButton'
import { MISSING_MESSAGE, courseCardHeadingStyles, courseIconMapping } from './utils'
import GridViewCourseCard from './GridViewCourseCard'
import { useParams } from 'react-router'
import {ReactComponent as WarningSign} from '../../../../../assets/img/svg/DangerWarning.svg'


const GridView = ({ viewTestClickHandler, testCountObj, testCount, courses, subjectLecturesCount }) => {
  const { id } = useParams();

  return (
    <div className={`${styles.view_container} ${styles.grid_view_container}`}>
      <Card className='mb-0'>
        <CardBody>
          <div className='d-flex justify-content-between'>
            <div>
              <h3 style={courseCardHeadingStyles}>Test</h3>
              <p style={{ fontSize: '14px', color: '#757575' }}>Schedule all your tests here.</p>
            </div>
            <span><TestIcon /></span>
          </div>
          {testCount > 0 ? (
            <CustomChip
              bgColor={'#BBE2CE'}
              textColor={'#0B442D'}
              text={testCount > 1 ? `${testCount} tests scheduled` : `${testCount} test scheduled`}
              style={{ fontSize: '12px', marginBottom: '1rem' }}
            />
          ) : (
            <CustomChip
              bgColor={'#F9D9D7'}
              textColor={'#B32306'}
              children={ <span className='d-flex justify-content-between'><span style={{marginRight:'5px'}}><WarningSign style={{height:'16px', width:'16px'}}/></span>{MISSING_MESSAGE} </span>}
              // text='No test scheduled'
              style={{ fontSize: '12px', marginBottom: '1rem' }}
            />
          )}
          <div>
            <AButton variant='link' updatedStyle={{ height: '24px' }} className='button_remove_left_margin' onClick={viewTestClickHandler}>View/Add tests</AButton>
          </div>
        </CardBody>
      </Card>
      {courses && courses?.map(item => (
        <GridViewCourseCard
          key={item?.courseId}
          courseData={item}
          lectureCountByDuration = {subjectLecturesCount}
          courseIcon={courseIconMapping(false)?.[item?.subjectName]}
          viewAddLink={`/admin/testScheduling/academicPlan/${id}/lecture?courseId=${item?.courseId}&subjectName=${item?.subjectName}`}
        />
      ))}
    </div>
  )
}

export default GridView