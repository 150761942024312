import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";
import CommonInput from "../common/formFeilds/input/commonInput";

export const BasicDetails = ({
  regionData,
  businessArea,
  courseCategoryData,
  values,
  setValues,
}) => {
  useEffect(() => {
    updateBusinessAreaDropdown(values?.region);
  }, [values?.region]);

  const [businessAreaDropdown, setBusinessAreaDropdown] = useState([]);

  const handleRegionChange = (selectedRegions) => {
    setValues((prev) => ({ ...prev, region: selectedRegions }));
  };

  const updateBusinessAreaDropdown = (selectedRegions) => {
    let matchedBusinessArea = [];
    if (selectedRegions?.length > 0 && !businessArea.fetching) {
      const regionList = selectedRegions.map((i) => i.value);
      businessArea.data.map((item) => {
        if (
          regionList?.filter((value) => item?.regionId?.includes(value)).length
        ) {
          matchedBusinessArea.push(item);
        }
      });

      setBusinessAreaDropdown([
        ...(matchedBusinessArea.length
          ? [{ label: "Select All", value: "*" }]
          : []),
        ...matchedBusinessArea,
      ]);
    } else {
      setBusinessAreaDropdown([]);
    }
    let newSelectedBusinessAreas = values.businessAreas.filter((bu) =>
      matchedBusinessArea.some((i) => i.value === bu.value)
    );
    // console.log('newSelectedBusinessAreas', newSelectedBusinessAreas);
    setValues((prev) => ({ ...prev, businessAreas: newSelectedBusinessAreas }));
  };

  return (
    <div
      style={{
        border: "1px solid #EDEDED",
        padding: "16px 24px",
        borderRadius: "12px",
      }}
    >
      <div className="semi-bold label-color">1. Basic details</div>
      <div className="d-flex justify-content-between pt-2">
        <Col className="pl-0">
          <CommonInput
            type={"multiSelectWithCheckboxes"}
            mandatory={true}
            label={"Region"}
            // placeHolder=""
            defaultValue={values?.region}
            onChange={(val) => handleRegionChange(val)}
            data={regionData?.data}
          />
        </Col>
        <Col className="pl-0">
          <CommonInput
            type={"multiSelectWithCheckboxes"}
            mandatory={true}
            label={"Business area"}
            // placeHolder="From"
            defaultValue={values?.businessAreas}
            onChange={(val) =>
              setValues((prev) => ({ ...prev, businessAreas: val }))
            }
            data={businessAreaDropdown}
          />
        </Col>
        <Col className="pl-0">
          <CommonInput
            type={"multiSelectWithCheckboxes"}
            mandatory={true}
            label={"Course category"}
            defaultValue={values?.courseCategories}
            onChange={(val) =>
              setValues((prev) => ({ ...prev, courseCategories: val }))
            }
            data={courseCategoryData.data}
          />
        </Col>
      </div>
    </div>
  );
};
