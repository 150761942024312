import React, { useState, useEffect } from "react";
import { CiFilter } from "react-icons/ci";
import { useDispatch } from "react-redux";
import AButton from "../../common/form-fields-mui/AButton";

import { RightSideFilterForm } from "./RightSideFilterForm";
import APopover from "../../common/pop-hover/APophover";
// import { CSVLink } from "react-csv";
import SearchInput from './SearchInput';
import { getStudentList } from './../../../../../redux/studentCharges/actions';
import CustmCheckbox from '../../common/formFeilds/customCheckbox'
import { failureToast } from "../../common/utils/methods/toasterFunctions/function";


// this is for filter button style
const highLightedStyle = {
  border: "1px solid #00B0F5",
  color: "#00B0F5",
};

const NormalStyle = {
  border: "1px solid #E9ECEF",
};
const Filter = ({
  data,
  filterForm,
  inputHandler,
  removeFilterHandler,
  crossInputHandler,
  courseIdDropdown,
  termDropdown,
  setNormalFilterForm,
  setFilterForm,
  otherCharge = false,
  setPageNumber, 
  setGlobalFilter,
  // emptySearchFields,
  searchInputValueHandler,
  ...props
}) => {

  const dispatch = useDispatch();
  const [anchorFilterEl, setAnchorFilterEl] = useState(null);
  const [anchorAdvFilterEl, setAnchorAdvFilterEl] = useState(null);
  const [showAllStudent, setShowAllStudent] = useState(false);


  // const onSearchNameCancel = () => {
  //   crossInputHandler();
  // };


  const removerFilterWithClose = () => {
    removeFilterHandler()
    setAnchorFilterEl(null)
  }
  const applyFilterHandler = () => {
    if (filterForm.applicationId && !(filterForm.applicationId.toString().length >= 8)) {
      failureToast("Please provide valid application ID");
      return
    }

    if (filterForm.opportunityId && !(filterForm.opportunityId.toString().length >= 8)) {
      failureToast("Please provide valid opportunity ID");
      return
    }
    props.applyFilterHandler();
    setAnchorFilterEl(null)
  }

  const handleApplyPendingChagesCheckbox = () => {
    setFilterForm({ ...filterForm, paymentStatus: [{ label: "Not Paid", value: 'Not Paid' }, { label: "Partially Paid", value: 'Partially Paid' }] });
    setShowAllStudent(false)
    dispatch(
      getStudentList(
        { ...filterForm, paymentStatus: ["Not Paid", "Partially Paid"] },
        0
      )
    );
  }

  const handleRemovePendingChagesCheckbox = () => {
    setFilterForm({ ...filterForm, paymentStatus: [] });
    dispatch(
      getStudentList(
        { ...filterForm, paymentStatus: [] },
        0
      )
    );
  }

  const RemoveAllFilers = () => {
    setShowAllStudent(true)
    setNormalFilterForm({
      ...filterForm,
      courseId: [],
      // businessArea: businessAreaDropdown.map(b=>b.value), 
      term: [],
      paymentStatus: [],
      chargesStatus: [],
      studentName: '',
      psid: '',
      applicationId: '',
      opportunityId: '',
    })
    dispatch(
      getStudentList(
        { ...filterForm, courseId: [], term: [], paymentStatus: [], chargesStatus: [] },
        0
      )
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <SearchInput
        onCancel={crossInputHandler}
        searchInputValueHandler={searchInputValueHandler}
        placeholder="Enter the 11 digit PSID number"
        errorMsg={true}
        val={filterForm?.psid ?? ''}
        globalFilter = {filterForm}
        setGlobalFilter = {setGlobalFilter}
        // emptySearchFields={emptySearchFields}
      />
      {/* <AButton
        onClick={(event) => {
          setAnchorFilterEl(event.currentTarget);
        }}
        updatedStyle={
          anchorFilterEl || props.appliedFilterCount > 0 ? highLightedStyle : NormalStyle
        }
      >
        <CiFilter />
        <span style={{ fontSize: '12px', fontWeight: '600', color: '#0F0F0F' }}>Filter</span>
        {props.appliedFilterCount > 0 && <span style={{ width: 'max-content', paddingLeft: '5px' }}>( {props.appliedFilterCount} )</span>}
      </AButton>

      <APopover
        handleClose={() => setAnchorFilterEl(null)}
        id="info-batch-filter-1"
        anchorEl={anchorFilterEl}
      >
        <RightSideFilterForm
          formModal={filterForm}
          inputHandler={inputHandler}
          applyFilterHandler={applyFilterHandler}
          removeFilterHandler={removerFilterWithClose}
          isShowRemoveFilter={!!props.appliedFilterCount}
          courseIdDropdown={courseIdDropdown}
          termDropdown={termDropdown}
          // businessAreaDropdown={businessAreaDropdown}
          disabled={showAllStudent}
          otherCharge={otherCharge}
        />
      </APopover>

      <APopover
        handleClose={() => setAnchorAdvFilterEl(null)}
        id="info-batch-filter"
        anchorEl={anchorAdvFilterEl}
      ></APopover> */}
      {/* <AButton>
			<MdOutlineSimCardDownload />
			<CSVLink
				data={data}
				headers={CSV_HEADERS}
				filename={`${Date.now()}-${"batches.csv"}`}
			>
				Download CSV
			</CSVLink>
			;
			</AButton> */}
    </div>
  );
};

export default Filter;
