import React, { useContext } from 'react';
import CustomTable from '../../common/commonComponents/customTable';
import { endpoint } from '../../common/constant';
import moment from 'moment';
import validationRules from './validationRule.json';
import { ButtonText } from "variables/Buttons";
import { PermissionContext, RolePermissions } from "../../../../../appContext";
import { pages } from "../../common/constant";
import { PermisionDenied } from "../../common/commonComponents";

const PermissionDetails = () => {
	const permissions = useContext(PermissionContext);

	const userPermissions = RolePermissions(
	  permissions,
	  pages['permissionDetails']['id']
	);
	const tableDetails = {
		headerDetails: [
			{ name: 'Key', required: true },
			{ name: 'Sequence number', required: true },
			{ name: 'Description', required: true },
			{ name: 'Display Value', required: true },
			{ name: 'Effective Date', required: false },
			{ name: 'Status', required: true },
			{ name: 'Action', required: false },
		],
		apiDetails: {
			create: endpoint.permissionDetails.create,
			update: endpoint.permissionDetails.update,
			delete: endpoint.permissionDetails.delete,
			getAll: endpoint.permissionDetails.getAllPermission,
		},
		listDetails: {
			permissionKey: {
				normal: true,
			},
			sequenceNumber: {
				normal: true,
			},
			permissionDesc: {
				normal: true,
			},
			permissionDispValue: {
				normal: true,
			},
			effectiveDate: {
				normal: false,
				type: 'DATE',
				format: 'DD-MM-YYYY',
			},
			status: {
				normal: false,
				type: 'TOGGLE',
			},
			action: {
				type: 'ACTION',
				button: [
					{
						icon: 'fas fa-edit',
						color: 'info',
						actionType: 'EDIT',
						permisionRequired : 'U'
					},
					{
						icon: 'fas fa-trash',
						color: 'info',
						actionType: 'DELETE',
						permisionRequired : 'D'
					},
				],
			},
		},
		fieldDetails: [
			{
				type: 'text',
				minLength: '1',
				maxLength: '1',
				regx: /^[A-Z]*$/i,
				regxValidation: true,
				placeHolder: 'Permission Key',
				propertyName: 'permissionKey',
			},
			{
				type: 'text',
				minLength: '1',
				maxLength: '2',
				regx: /^\d{1,2}$/,
				regxValidation: true,
				placeHolder: 'Sequence Number',
				propertyName: 'sequenceNumber',
			},
			{
				type: 'text',
				minLength: '1',
				maxLength: '50',
				regx: /^[A-Za-z0-9 _-]*$/,
				regxValidation: true,
				placeHolder: 'Permission Description',
				propertyName: 'permissionDesc',
				// isFilter: true,
			},
			{
				type: 'text',
				minLength: '1',
				maxLength: '25',
				regx: /^[A-Za-z0-9 _-]*$/,
				regxValidation: true,
				placeHolder: 'Permission Display Value',
				propertyName: 'permissionDispValue',
			},
			{
				type: 'text',
				placeHolder: 'Effective Date',
				propertyName: 'effectiveDate',
				isDisabled: true,
				defaultValue: moment().format('DD-MM-YYYY'),
				format: 'DD-MM-YYYY',
			},
			{
				type: 'Status',
				propertyName: 'status',
			},
		],
		isSearch: false,
		isNew: true,
	};

	const TableHeader = () => {
		return (
			<div className={'d-flex justify-content-between'}>
				<h3 className="mb-0 floatLeft">Permission Details</h3>
			</div>
		);
	};

	return (
		userPermissions ? 
		<CustomTable
			tableHeader={TableHeader}
			tableDetails={tableDetails}
			validationRules={validationRules}
			addButtonText={ButtonText.PermissionDetails.addNew}
			saveButtonText={ButtonText.PermissionDetails.save}
			permissionSet={userPermissions}
		/>
		: <PermisionDenied />
	);
};

export default PermissionDetails;
