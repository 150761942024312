import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import CustomTextArea from "views/pages/manage/common/formFeilds/customTextarea";
import { getRequiredSign } from "../../../../../common/utils/methods/commonMethods/utilityMethod";
import { regex } from "../../../../../common/constant"



const Description = ({isUploading, setDescription = () => { }, description}) => {
    return (
        <Card>
            <CardBody>
                <Row className="mt-3">
                    <Col className="mb-3" xs="12">
                        <label className="form-control-label">
                            {`Description`} {getRequiredSign()}
                        </label>
                        <CustomTextArea
                            isDisabled={isUploading}
                            type="textarea"
                            defaultValue={description}
                            placeHolder={`Description`}
                            maxLength="50"
                            handler={(index, value, keyName) => setDescription(value)}
                            style={{ height: "39px" }}
                            regex={regex.pricingUploadDescription}
                            regValidation={true}
                        />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default Description