import React from "react";
import Select, { components } from "react-select";
import { customStylesSelect } from "../../common/formFeilds/input/commonInput";

const customStyles = {
    // customStylesSelect,
    option: (base, state) => ({
        ...base,
        margin: "6px",
        //   borderRight: "1px solid #E3E3E3",
        padding: '4px 6px',
        backgroundColor: state.isSelected ? "none" : "",
        color: "#333333",
        background: '#E9ECEF',
        border: '1px solid #CCCCCC',
        borderRadius: '8px',
        // "&:hover": {
        //     ...base,
        //     backgroundColor: "none",
        //     color: "#333333"
        // }
    }),
    menuList: (base) => ({
        display: "flex",
        flexWrap: "wrap",
        maxHeight: "225px",
        padding: "6px",
        overflow:'auto',
        "div": {
            marginRight : '6px',
        }
    }),
    group: (base) => ({
        ...base,
        "div": {
            display: 'flex',
            flexWrap: "wrap",
        }

    }),
    menu: (base) => ({
        ...base,
        margin: "0",
        borderRadius: "0 0 8px 8px",
        background: "#FFFFFF",
        boxShadow: "0 -6px 0 #FFFFFF, 0 4px 32px rgba(61, 70, 112, 0.08)",
        position: "relative"
    })
};

const controlStyles = {
    padding: "16px",
    paddingBottom: "10px",
    background: "white",
    zIndex: 1,
    borderRadius: "8px 8px 0 0",
    boxShadow: "0 6px 0 #fff, 0 4px 32px rgba(61, 70, 112, 0.08)"
};

const getLength = options => {
    return options.reduce((acc, curr) => {
        if ("options" in curr) return acc + getLength(curr.options);
        return acc + 1;
    }, 0);
}

export default (props) => {

    const Option = (base) => {
        return (
            <div style={{ fontSize: "12px" }}>
                <components.Option {...base} />
            </div>
        );
    };

    const ControlComponent = (base) => (
        <div style={controlStyles}>
            <h3 style={{ margin: 0, marginBottom: "20px" }}>{props.label}</h3>
            <components.Control {...base} />
        </div>
    );

    const Menu = (
        base
    ) => {
        const optionsLength = getLength(base.options);
        return (
            <>
                <components.Menu
                    {...base}
                >
                    {base.children}
                    <div style={{ padding: "20px 0 20px 14px" }}>
                        <span
                            style={{
                                margin: "5px 0",
                                padding: "5px 10px",
                                backgroundColor: "#F5F5F5",
                                borderRadius: "10px",
                                fontSize: "12px"
                            }}
                        >
                            Total {optionsLength} results
                        </span>
                    </div>
                </components.Menu>
            </>
        );
    };

    const DropdownIndicator = (
        base
    ) => {
        return (
            <components.DropdownIndicator {...base}>
                <i className="fas fa-search" />
            </components.DropdownIndicator>
        );
    };

    return (
        <div style={{ display: 'block', width: '300px' }}>
            <Select
                closeMenuOnSelect={false}
                components={{
                    Option,
                    Menu,
                    Control: ControlComponent,
                    DropdownIndicator,
                    IndicatorSeparator: () => null
                }}
                menuIsOpen={true}
                styles={customStyles}
                options={props.options}
                isClearable={true}
                value={null}
                placeholder="Search"
            />
        </div>
    )
};
