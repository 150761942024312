import React, { useContext, useState } from 'react';
import { Dialog, Tooltip } from '@mui/material';
import AButton from 'views/pages/manage/common/form-fields-mui/AButton';
import { ReactComponent as CrossIcon } from 'assets/img/svg/CrossIcon.svg';
import { ReactComponent as DangerIcon } from 'assets/img/svg/WarningRed.svg';
import { ReactComponent as EditIcon } from 'assets/img/svg/Edit2.svg';
import styles from '../styles.module.scss';
import { AGridColDef } from '../../../../common/data-grid/ADataGridColDef';
import ADataGrid from '../../../../common/data-grid/ADataGrid';
import { gridStyles } from 'views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant.js';
import CustomChip from 'views/pages/manage/common/customChip/CustomChip.jsx';
import NoRowOverlay from 'views/pages/manage/masters/holidayMaster/NoRowOverlay.jsx';
import { IssuanceIDCardContext } from '../../contextApi';
import { getChipBgColor, getChipTxtColor } from '../constant';
import { GridRowModes } from '@mui/x-data-grid';
import { isObjEmpty } from '../../../../common/utils/methods/commonMethods/utilityMethod';
import { failureToast } from '../../../../common/utils/methods/toasterFunctions/function';
import { saveOrDeleteMsg } from '../../mastersAndMapping/sapProfitCostCentreMapping/constant';
import {
  editCellContainerStyle,
  inputStyles,
} from '../../mastersAndMapping/onBoardingMaterialIdSetup/constant';
import AInput from '../../../../common/formFeilds/customInput/AInput';
import useCustomConfirmationPrompt from '../../../../common/commonComponents/ConfirmationDialog/CustomConfirmationPrompt';
import ALoader from '../../../../common/a-loader';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'
import { regex } from '../../../../common/constant';


const BarcodeDialog = ({ open, setOpen, rowData }) => {
  // console.log(rowData, 'current row data');
  const handleClose = () => {
    setOpen(false);
    stopEditing();
  }
  const [errMsg, setErrMsg] = useState('');

  const [EditConfirmDialog, editConfirm] = useCustomConfirmationPrompt();
  const [ForceChangeBarcode, forceChangeBarcodeConfirm] = useCustomConfirmationPrompt();
  const [dialogData, setDialogData] = useState({});
  const [loader, setLoader] = useState(false);

  const { materialAndBarcodeMapping, editBarcodeForStudent } = useContext(
    IssuanceIDCardContext
  );
  // console.log(materialAndBarcodeMapping, 'materialBarcodeMapping')
  const getMaterialCount = materialId =>
    materialAndBarcodeMapping?.['materialCount']?.[materialId] || 0;

  const [rowModesModel, setRowModesModel] = useState({});
  const [rowForm, setRowForm] = useState({});
  const rowFormHandler = (value, key) =>
    setRowForm(prev => ({ ...prev, [key]: value }));

  const startEditing = (id, form) => {
    setRowModesModel(oldModel => ({ [id]: { mode: GridRowModes.Edit, fieldToFocus: "barcode" } }));
    setRowForm(form);
  };

  const stopEditing = () => {
    const currForm = { ...rowForm };
    setRowModesModel(oldModel => ({
      [currForm?.id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));
    setRowForm({});
    setErrMsg('');
  };
  const handleEditClick = async params => {
    if (!isObjEmpty(rowForm)) {
      failureToast(saveOrDeleteMsg);
    } else {
      const canContinue = await editConfirm();
      if (canContinue) startEditing(params?.id, params?.row);
    }
  };
  const handleCancelClick = async params => {
    stopEditing();
  };
  const handleSaveClick = async params => {
    // console.log('save clicked');
    setLoader(true);
    const res = await editBarcodeForStudent(rowData, params?.row, rowForm?.barcode, setDialogData, forceChangeBarcodeConfirm); // his will return err msg
    if (res) setErrMsg(res);
    else {
      stopEditing();
      setErrMsg(''); // cleanup errMsg
    }
    setDialogData({}); // cleanup dialog data
    setLoader(false);
  };

  const columns = [
    new AGridColDef("materialId", "Material already issued", false)
      .setFlex(2)
      .setValueGetter(
        params => `${params?.value} | ${params?.row?.materialDescription}`
      )
      .renderCellComponent(params => (
        <div
          className="d-flex align-items-center ellipsis-text-container"
          style={{ gap: "12px" }}
        >
          <Tooltip title={params?.value}>
            <div className="ellipsis-text-container">{params?.value}</div>
          </Tooltip>
          {/* <CustomChip
            bgColor={getChipBgColor(getMaterialCount(params?.row?.materialId))}
            textColor={getChipTxtColor(getMaterialCount(params?.row?.materialId))}
            text={`${getMaterialCount(params?.row?.materialId) || 0} Left`}
          /> */}
        </div>
      )),
    new AGridColDef("barcode", "Barcode number", false)
      .setFlex(1)
      .editable(true)
      .renderCellComponent(params => (
        <div className="ellipsis-text-container">{params?.value}</div>
      ))
      .renderEditCellComponent(params => (
        <div style={editCellContainerStyle}>
          <AInput
            autoFocus={params?.hasFocus}
            placeholder="Enter"
            regex={regex.alphaNumeric}
            value={rowForm?.barcode}
            handler={value => rowFormHandler(value, "barcode")}
            error={errMsg}
            style={inputStyles}
          />
        </div>
      )),
    new AGridColDef("actions", "Actions", false)
      .setFlex(0.5)
      .columnAlign("right")
      .setMinWidth(130)
      .editable(true)
      .renderCellComponent(params => (
        <div className="d-flex align-items-center">
          <IconButtonWrapper onClick={() => handleEditClick(params)}>
            <EditIcon />
          </IconButtonWrapper>
        </div>
      ))
      .renderEditCellComponent(params => (
        <div
          style={{ padding: "0 10px" }}
          className="d-flex align-items-center"
        >
          <AButton
            variant="primary_filled"
            size="xs"
            onClick={() => handleSaveClick(params)}
          >
            Save
          </AButton>
          <IconButtonWrapper
            className="ml-2"
            onClick={() => handleCancelClick(params)}
          >
            <CrossIcon />
          </IconButtonWrapper>
        </div>
      )),
  ];
// console.log(rowData, 'row');
  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{ sx: { width: '100%', maxWidth: '750px !important' } }}
      >
        <div style={{ padding: '28px 24px' }}>
          {loader && <ALoader />}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="color-dark heading-4">
              Materials and mapped barcodes
            </div>
            <IconButtonWrapper onClick={handleClose}><CrossIcon width={30} height={30} /></IconButtonWrapper>
          </div>

          <div className={`${styles?.barcode_dialog_table_container}`}>
            <ADataGrid
              removeWrapperContainer
              rows={((rowData?.stock || [])?.filter(item => item?.serialNumberProfile?.toLowerCase()!=='na') || [])}
              rowModesModel={rowModesModel}
              columns={columns}
              rowId={row => row.id}
              rowHeight={50}
              loading={false}
              serverPagination={false}
              autoHeight
              hidePagination
              sx={gridStyles}
              components={{
                NoRowsOverlay: () => (
                  <NoRowOverlay message="Please select material to be issued" />
                ),
              }}
            />
          </div>
          <div>
            {errMsg && (
              <div className="mt-3 d-flex align-items-center">
                <DangerIcon width={24} height={24} className="mr-2" />
                <span className="color-red-60 regular-body">
                  {errMsg}
                </span>
              </div>
            )}
            <AButton
              className="mt-3 btn-left-0"
              variant="primary_filled"
              onClick={() => handleClose()}
            >
              Done
            </AButton>
          </div>
        </div>
      </Dialog>
      <EditConfirmDialog
        isQuestionIconRequired={false}
        headerMsg={'Changing the barcode can have implications on inventory'}
        msg={'Do you want to continue?'}
        cancelBtnProps={{ variant: 'outline' }}
        confirmBtnProps={{ variant: 'outline_danger' }}
      />
      <ForceChangeBarcode 
        isQuestionIconRequired={false}
        headerMsg={`Barcode is currently mapped to PSID ${dialogData?.psid} on Issue Stock Screen`}
        msg={'If you proceed then the barcode will be updated here and you will be required to re-select the material/barcode for the mentioned PSID'}
        cancelBtnProps={{ variant: 'outline' }}
        confirmBtnProps={{ variant: 'outline_danger' }}
        cancelBtnTxt={'Cancel'}
        confirmBtnTxt={'Yes Proceed'}
      />
    </>
  );
};

export default BarcodeDialog;
