import React from 'react'
import { Row } from 'react-day-picker'
import { Col, Container } from 'reactstrap'
import HeaderComponent from './header'

const PaymentManagement = () => {
    return (
        <div className='d-flex flex-column justify-content-between remove-container-top-space' style={{ height: 'calc(100vh - 100px)' }}>
            <HeaderComponent />
            <Container fluid> 
            <Row>
            
                <Col> </Col>

            </Row>
             </Container>
        </div>
    )
}

export default PaymentManagement