const _ = require("lodash");

export const editTableRow = (index,data,setData,setisSaveVisible) => {
    let cb = () => {
        let _tempArray = data;
        _tempArray[index]["editMode"] = true;
        setData(_tempArray);
        setisSaveVisible(true);
    };
    let _tempArray = data;
    _tempArray = _.sortBy(_tempArray, [
        function (o) {
            return o.value;
        },
    ]);
    cb();
}

export const newTableRow = (data,setData,setisSaveVisible,addNewClass) => {
    let cb = () => {
        addNewClass();
        setisSaveVisible(true);
        // settableDataKey(new Date().getMilliseconds())
      };
      let _tempArray = data;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      setData(_tempArray);
      setisSaveVisible(false);
      cb();
}