import React from 'react'
import './batch-style.scss';
import { ReactComponent as Folder } from '../../../../../assets/img/svg/Folder.svg';
import CreateBatchButton from './CreateBatchButton';
const EmptyBatchPlaceholder = () => {
  return (
    <div className='empty-batch-placeholder'>
      <div className='folder-icon-wrapper'>
        <Folder/>
      </div>
      <span >No batches found</span>
      {/* <span className =''>Add a new batch to see batches here.</span> */}
      {/* <CreateBatchButton/> */}
    </div>
  )
}

export default EmptyBatchPlaceholder