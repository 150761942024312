import React, { useState, useEffect } from 'react';
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  Button,
} from 'reactstrap';
import { Accordion } from '../../../../common/commonComponents/Accordion/accordion';
import { ExternalOrg } from './externalOrg';
import OrganizationContactDetails from './organizationContactDetails';
import  AffiliationDetails  from './affiliationDetails';
import  SeatingCapacityOfOrganization from './seatingCapacityOfOrganization';
import { LoginDetails } from './loginDetails';
import {
  masterServiceBaseUrl,
  failureToast,
  getAPI,
  putpost,
  successToast,
} from 'services/http';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';

const ExternalOrganization = (props) => {
  let history = useHistory();
  let location = useLocation();
  let formMode = useParams();

  const [apiLoader, setApiLoader] = useState(false);
  const [enableEditMode, setEnableEditMode] = useState(false);
  const [organizationType, setOrganizationType] = useState([]);
  const [orgIdStatus, setOrgIdStatus] = useState([
    { value: 'ACTIVE', label: 'ACTIVE' },
    { value: 'INACTIVE', label: 'INACTIVE' },
  ]);
  const [assocaitedAeslCampus, setAssociatedAeslCampus] = useState([]);
  const [contactTitle, setContactTitle] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [affiliationBoardList, setAffiliationBoardList] = useState([]);
  const [affiliationDetails, setAffiliationDetails] = useState({
    affliationNumber: '',
    affliateBoard: '',
  });
  const [externalOrganzationObj, setExternalOrganzationObj] = useState({
    id: '',
    status: 'ACTIVE',
    organizationType: '',
    organizationName: '',
    organizationShortName: '',
    proprietership: '',
    businessAreaId: '',
    contractSigningDate: '',
    contractExpiryDate: '',
    contactPersonName: '',
    contactTitle: '',
    email: [],
    phoneNumber: [],
    country: '',
    state: '',
    city: '',
    addressLine1: '',
    addressLine2: '',
    pincode: '',
    affliationDetailsList: [],
    seatingCapacity: [],
    lastUpdatedOPRID: moment().format('DD-MM-YYYY'),
    lastUpdatedDTTM: moment().format('DD-MM-YYYY'),
    effectiveDate: '',
  });

  useEffect(() => {

      if (!(formMode.id === 'new') && location && location.state )  fetchSingleUserDetails( location && location.state);
       fetchOrganizationDetails();
       fetchOrganizationContactDetails();
       fetchAffiliationDetails();
   
  }, []);

  const fetchSingleUserDetails = async (value) => {
    try {
      setApiLoader(true);
      await getAPI(
        masterServiceBaseUrl + `/externalOrganizationId/getExternalOrganizationId/${value}`,
        (data) => {
          setExternalOrganzationObj({
            id: data && data['data'] && data['data']['id'],
            status: data && data['data'] && data['data']['status'],
            organizationType:
              data && data['data'] && data['data']['organizationType'],
            organizationName:
              data && data['data'] && data['data']['organizationName'],
            organizationShortName:
              data && data['data'] && data['data']['organizationShortName'],
            proprietership:
              data && data['data'] && data['data']['proprietership'],
            businessAreaId:
              data && data['data'] && data['data']['businessAreaId'],
            contractSigningDate:
              data && data['data'] && data['data']['contractSigningDate'],
            contractExpiryDate:
              data && data['data'] && data['data']['contractExpiryDate'],
            contactPersonName:
              data && data['data'] && data['data']['contactPersonName'],
            contactTitle: data && data['data'] && data['data']['contactTitle'],
            email: data && data['data'] && data['data']['email'],
            phoneNumber: data && data['data'] && data['data']['phoneNumber'],
            country: data && data['data'] && data['data']['country'],
            state: data && data['data'] && data['data']['state'],
            city: data && data['data'] && data['data']['city'],
            addressLine1: data && data['data'] && data['data']['addressLine1'],
            addressLine2: data && data['data'] && data['data']['addressLine2'],
            pincode: data && data['data'] && data['data']['pincode'],
            affliationDetailsList:
              data && data['data'] && data['data']['affliationDetailsList'],
            seatingCapacity:
              data && data['data'] && data['data']['seatingCapacity'],
            lastUpdatedOPRID: moment().format('DD-MM-YYYY'),
            lastUpdatedDTTM: moment().format('DD-MM-YYYY'),
            effectiveDate:
              data && data['data'] && data['data']['effectiveDate'],
          });
        }
      );
    } catch (error) {
      console.log('Error', error);
    }
  };

  const fetchOrganizationDetails = async () => {
    try {
      getAPI(masterServiceBaseUrl + '/externalOrgType/getAllExternalOrgType', (data) => {
        let organizationTypeTemp = [];
        data.data.map((item) => {
          organizationTypeTemp.push({
            label: item.externalOrgTypeDispValue,
            value: item.id,
          });
        });
        setOrganizationType(organizationTypeTemp);
      });

      getAPI(masterServiceBaseUrl + '/businessArea/getAllActiveBusinessArea', (data) => {
        let businessArea = [];
        data.data.map((item) => {
          businessArea.push({
            label: item.businessAreaDispValue,
            value: item.id,
          });
        });
        setAssociatedAeslCampus(businessArea);
      });
    } catch (error) {
      console.log('Error', error);
    }
  };

  const fetchOrganizationContactDetails = () => {
    try {
      getAPI(masterServiceBaseUrl + '/contactTitle/getAllActiveContactTitle', (data) => {
        let contactTemp = [];
        data.data.map((item) => {
          contactTemp.push({
            value: item.id,
            label: item.contactTitleDispValue,
          });
        });
        setContactTitle(contactTemp);
      });
      getAPI(masterServiceBaseUrl + '/pincode/getAllActivePincode', (data) => {
        let pincodeTemp = [];
        data.data.map((item) => {
          pincodeTemp.push({
            value: item.id,
            label: item.pincodeDispValue,
          });
        });
        setPincode(pincodeTemp);
      });
    } catch (error) {
      console.log('Error', error);
    }
  };

  const fetchAffiliationDetails = () => {
    try {
      getAPI(masterServiceBaseUrl + '/examBoardType/getAllExamBoardType', (data) => {
        let AffiliationBoardTemp = [];
        data.data.map((item) => {
          AffiliationBoardTemp.push({
            value: item.id,
            label: item.examBoardTypeDispValue,
          });
        });
        setAffiliationBoardList(AffiliationBoardTemp);
      });
    } catch (error) {
      console.log('Error', error);
    }
  };

  const SaveHandler = async () => {
    try {
      let pattern = /^[\s*A-Z\s*a-z\s*0-9\s*]+$/;
      let OrgNamePattern = /^[\s*A-Z\s*a-z\s*]+$/;

      if (
        !(externalOrganzationObj['id'] === '') &&
        externalOrganzationObj['id'].length <= 2
      )
        return failureToast('Please Enter Id greater than 2 character');
      if (externalOrganzationObj['effectiveDate'] === '')
        return failureToast('Please Select Effective Date');
      if (externalOrganzationObj['organizationType'] === '')
        return failureToast('Please Select Organization Type');
      if (externalOrganzationObj['status'] === '')
        return failureToast('Please Select Org Id Status');
      if (
        externalOrganzationObj['organizationName'] === undefined ||
        externalOrganzationObj['organizationName'] === null ||
        externalOrganzationObj['organizationName'].trim() == ''
      )
        return failureToast('Please Enter Organization Name');
      if (!OrgNamePattern.test(externalOrganzationObj['organizationName']))
        return failureToast('Please enter valid organization name');
      if (
        !(externalOrganzationObj['organizationShortName'] === '') &&
        !OrgNamePattern.test(externalOrganzationObj['organizationShortName'])
      )
        return failureToast('Please enter valid organization short name');
      if (externalOrganzationObj['organizationType']) {
        const Index =
          organizationType &&
          organizationType.findIndex((item) => {
            return item.value == externalOrganzationObj['organizationType'];
          });
        const value = Index >= 0 ? organizationType[Index]['label'] : '';
        if (
          value.toUpperCase() === 'SCHOOL TIE UP' &&
          externalOrganzationObj['contractSigningDate'] === ''
        )
          return failureToast('Please Select the contract signing date');
      }
      if (externalOrganzationObj['businessAreaId'] === '')
        return failureToast('Please Select Associated AESL Campus');
      if (
        !(externalOrganzationObj['contractSigningDate'] === '') &&
        externalOrganzationObj['contractExpiryDate'] === ''
      )
        return failureToast('Please Select the contract expiry date');
      if (
        !(externalOrganzationObj['contactPersonName'] === '') &&
        !OrgNamePattern.test(externalOrganzationObj['contactPersonName'])
      )
        return failureToast('Please enter valid contact person name');
      if (externalOrganzationObj['addressLine1'] === '')
        return failureToast('Please Enter Address 1');
      if (!pattern.test(externalOrganzationObj['addressLine1']))
        return failureToast('Please Enter valid Address 1');
      if (externalOrganzationObj['addressLine2'] === '')
        return failureToast('Please Enter Address 2');
      if (!pattern.test(externalOrganzationObj['addressLine2']))
        return failureToast('Please Enter valid Address 2');
      if (externalOrganzationObj['pincode'] === '')
        return failureToast('Please Select Pin Code');
      if (externalOrganzationObj['city'] === '')
        return failureToast('Please Select City');
      if (externalOrganzationObj['state'] === '')
        return failureToast('Please Select State');
      if (externalOrganzationObj['country'] === '')
        return failureToast('Please Select Country');
      if (externalOrganzationObj['affliationDetailsList'].length < 1)
        return failureToast('Please Enter the Affiliation Details');

      await putpost(
        `${masterServiceBaseUrl}/externalOrganizationId/createExternalOrganization`,
        (data) => {
          successToast(`Id Generated ${data['data']['id']}`);
          history.push('/admin/organization');
        },
        (data) => {
          failureToast(data['message']);
        },
        externalOrganzationObj,
        'post'
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  const updateOrganizationData = async () => {
    try {
      let pattern = /^[\s*A-Z\s*a-z\s*0-9\s*]+$/;
      let OrgNamePattern = /^[\s*A-Z\s*a-z\s*]+$/;

      if (externalOrganzationObj['effectiveDate'] === '')
        return failureToast('Please Select Effective Date');
      if (externalOrganzationObj['organizationType'] === '')
        return failureToast('Please Select Organization Type');
      if (externalOrganzationObj['status'] === '')
        return failureToast('Please Select Org Id Status');
      if (
        externalOrganzationObj['organizationName'].trim() === '' ||
        externalOrganzationObj['organizationName'] === null
      )
        return failureToast('Please Enter Organization Name');
      if (!OrgNamePattern.test(externalOrganzationObj['organizationName']))
        return failureToast('Please enter valid organization name');
      if (externalOrganzationObj['organizationType']) {
        const Index =
          organizationType &&
          organizationType.findIndex((item) => {
            return item.value == externalOrganzationObj['organizationType'];
          });
        const value = Index >= 0 ? organizationType[Index]['label'] : '';
        if (
          value.toUpperCase() === 'SCHOOL TIE UP' &&
          (externalOrganzationObj['contractSigningDate'] === '' ||
            externalOrganzationObj['contractSigningDate'] === null)
        )
          return failureToast('Please Select the contract signing date');
      }
      if (externalOrganzationObj['businessAreaId'] === '')
        return failureToast('Please Select Associated AESL Campus');
      if (
        !(
          externalOrganzationObj['contractSigningDate'] === '' ||
          externalOrganzationObj['contractSigningDate'] === null
        ) &&
        externalOrganzationObj['contractExpiryDate'] === ''
      )
        return failureToast('Please Select the contract expiry date');
      if (
        !(externalOrganzationObj['contactPersonName'] === '') &&
        !OrgNamePattern.test(externalOrganzationObj['contactPersonName'])
      )
        return failureToast('Please enter valid contact person name');
      if (externalOrganzationObj['addressLine1'] === '')
        return failureToast('Please Enter Address 1');
      if (!pattern.test(externalOrganzationObj['addressLine1']))
        return failureToast('Please Enter valid Address 1');
      if (externalOrganzationObj['addressLine2'] === '')
        return failureToast('Please Enter Address 2');
      if (!pattern.test(externalOrganzationObj['addressLine2']))
        return failureToast('Please Enter valid Address 2');
      if (externalOrganzationObj['pincode'] === '')
        return failureToast('Please Select Pin Code');
      if (externalOrganzationObj['city'] === '')
        return failureToast('Please Select City');
      if (externalOrganzationObj['state'] === '')
        return failureToast('Please Select State');
      if (externalOrganzationObj['country'] === '')
        return failureToast('Please Select Country');
      if (externalOrganzationObj['affliationDetailsList'].length < 1)
        return failureToast('Please Enter the Affiliation Details');

      await putpost(
        masterServiceBaseUrl +
          `/externalOrganizationId/updateExternalOrganizationId/${externalOrganzationObj['id']}`,
        (data) => {
          successToast(data['message']);
          history.push('/admin/organization');
        },
        (data) => {
          failureToast(data['message']);
        },
        externalOrganzationObj,
        'put'
      );
    } catch (error) {
      console.log('Error', error);
    }
  };

  return (
    <Container className={`mt-3`}>
      <Card>
        <CardHeader>
          <h3>External Organization</h3>
        </CardHeader>
        <CardBody>
          <Accordion title={`External Org`} key={'External Org'}>
            <ExternalOrg
              setExternalOrganzationObj={setExternalOrganzationObj}
              externalOrganzationObj={externalOrganzationObj}
              organizationType={organizationType}
              formMode={formMode.id}
              orgIdStatus={orgIdStatus}
              assocaitedAeslCampus={assocaitedAeslCampus}
              enableEditMode={enableEditMode}
            />
          </Accordion>
          <Accordion
            title={`Organization Contact Details`}
            key={'organization_contact_details'}
          >
            <OrganizationContactDetails
              formMode={formMode.id}
              setExternalOrganzationObj={setExternalOrganzationObj}
              externalOrganzationObj={externalOrganzationObj}
              contactTitle={contactTitle}
              enableEditMode={enableEditMode}
              pincode={pincode}
            />
          </Accordion>
          <Accordion title={`Affiliation Details`} key={'affiliation_details'}>
            <AffiliationDetails
              formMode={formMode.id}
              setExternalOrganzationObj={setExternalOrganzationObj}
              externalOrganzationObj={externalOrganzationObj}
              affiliationDetails={affiliationDetails}
              setAffiliationDetails={setAffiliationDetails}
              affiliationBoardList={affiliationBoardList}
              enableEditMode={enableEditMode}
            />
          </Accordion>
          <Accordion
            title={`Seating Capacity Details`}
            key={'seating_capacity_details'}
          >
            <SeatingCapacityOfOrganization
              formMode={formMode.id}
              enableEditMode={enableEditMode}
              setExternalOrganzationObj={setExternalOrganzationObj}
              externalOrganzationObj={externalOrganzationObj}
            />
          </Accordion>
          <Accordion title={`Log Details`} key={'log_details'}>
            <LoginDetails externalOrganzationObj={externalOrganzationObj} />
          </Accordion>
          {formMode.id === 'new' ? (
            <Button
              data-testid="saveDataOrg"
              color="info"
              size="sm"
              type="button"
              className="floatRight mx-1"
              onClick={() => SaveHandler()}
            >
              Save
            </Button>
          ) : formMode.id === 'editMode' ? (
            <>
              <Button
                data-testid="closeByAdmin"
                color="info"
                size="sm"
                type="button"
                className="floatRight mx-1"
                onClick={() => history.push('/admin/organization')}
              >
                close
              </Button>
              {enableEditMode ? (
                <Button
                data-testid="updateMode"
                  color="info"
                  size="sm"
                  type="button"
                  className="floatRight mx-1"
                  onClick={() => updateOrganizationData()}
                >
                  Update
                </Button>
              ) : (
                <Button
                data-testid="editModeBtn"
                  color="info"
                  size="sm"
                  type="button"
                  className="floatRight mx-1"
                  onClick={() => setEnableEditMode(true)}
                >
                  {'Enable Edit'}
                </Button>
              )}
            </>
          ) : (
            <Button
              data-testid="closeAdmin"
              color="info"
              size="sm"
              type="button"
              className="floatRight mx-1"
              onClick={() => history.push('/admin/organization')}
            >
              close
            </Button>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default ExternalOrganization;
