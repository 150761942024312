import React, { useState, useEffect ,useContext } from 'react';
import { Card, CardHeader, Container } from 'reactstrap';

import FormFirstAll from './firstFormPricingZone';
import AssociatedBuAreaTable from './associatedBuAreaTable';
import { RolePermissions, PermissionContext} from 'appContext';
import { pages } from 'views/pages/manage/common/constant';
import { PermisionDenied } from 'views/pages/manage/common/commonComponents';

const NewPricingZone = (props) => {
	const { id, action } = props.match.params;
	const [step, setStep] = useState(0);
	const [formId, setFormId] = useState(null);
	const [isNew, setIsNew] = useState(true);
	const [isViewOnly, setIsViewOnly] = useState(false);
	const [customRoutePermission, setCustomRoutePermission] = useState(false);
	const [businessGroupCode, setBusinessGroupCode] = useState('');
	const [companyCode, setCompanyCode] = useState('');

	const permissions = useContext(PermissionContext);

	const userPermissions = RolePermissions(
		permissions,
		pages['pricingZone']['id']
	);

	React.useEffect(() => {
		if (isNew === true) {
			userPermissions !== false && userPermissions.includes('C')
				? setCustomRoutePermission(true)
				: setCustomRoutePermission(false);
		}
		if (action === 'view' && id) {
			setFormId(id);
			setIsNew(false);
			setIsViewOnly(true);
			action === 'view' && userPermissions !== false &&  userPermissions.includes('R')
				? setCustomRoutePermission(true)
				: setCustomRoutePermission(false);
		}
		if (action === 'edit' && id) {
			setFormId(id);
			setIsNew(false);
			setIsViewOnly(false);
			action === 'edit' && userPermissions !== false &&  userPermissions.includes('U')
				? setCustomRoutePermission(true)
				: setCustomRoutePermission(false);
		}
	}, [id]);

	const nextStep = (id) => {
		if (id) {
			setFormId(id);
			setIsNew(false);
		}
		setStep(step + 1);
	};

	let pageToRender = null;

	switch (step) {
		case 0:
			pageToRender = (
				<FormFirstAll
					nextStep={nextStep}
					isNew={isNew}
					formId={formId}
					setBusinessGroupCode={(item) => setBusinessGroupCode(item)}
					setCompanyCode={(item) => setCompanyCode(item)}
					userPermissions={userPermissions}
				/>
			);
			break;
		case 1:
			pageToRender = (
				<>
					<FormFirstAll
						nextStep={nextStep}
						isNew={isNew}
						formId={formId}
						isViewOnly={isViewOnly}
						setBusinessGroupCode={(item) => setBusinessGroupCode(item)}
						setCompanyCode={(item) => setCompanyCode(item)}
						userPermissions={userPermissions}
					/>
					<AssociatedBuAreaTable
						formId={formId}
						nextStep={nextStep}
						isNew={isNew}
						isViewOnly={isViewOnly}
						businessGroupCode={businessGroupCode}
						companyCode={companyCode}
						userPermissions={userPermissions}
					/>
				</>
			);
			break;
		default:
			pageToRender = <FormFirstAll />;
	}

	return (
		<>
			{userPermissions ? (
				<Container className="mt-3" fluid>
					<Card className="mb-4">
						<CardHeader>
							<h3>Pricing Zone</h3>
						</CardHeader>
					</Card>
					{isNew ? (
						pageToRender
					) : (
						<>
							<FormFirstAll
								isNew={isNew}
								formId={formId}
								isViewOnly={isViewOnly}
								setBusinessGroupCode={(item) => setBusinessGroupCode(item)}
								setCompanyCode={(item) => setCompanyCode(item)}
								userPermissions={userPermissions}
							/>
							<AssociatedBuAreaTable
								formId={formId}
								isNew={isNew}
								isViewOnly={isViewOnly}
								businessGroupCode={businessGroupCode}
								companyCode={companyCode}
								userPermissions={userPermissions}
							/>
						</>
					)}
				</Container>
			) : (
				<PermisionDenied />
			)}
		</>
	);
};

export default NewPricingZone;
