import React, { useEffect, useState } from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import moment from 'moment';

const LogDetails = ( props ) =>
{
  const {
    updatedOn,
    updatedBy,
    createdBy,
    createdOn,
    courseDetailData,
    action
  } = props;

  const [latestRecord,setLatestRecord] = useState(null);


  const getLatestRecord = (data) => {
    return (data && data.length > 0 && data.reduce((a, b) => a.updatedOn > b.updatedOn ? a : b)) ?? {};
  }


  useEffect(()=>{
    if(action !== 'new'){
      setLatestRecord(getLatestRecord(courseDetailData));
    }
  },[action,courseDetailData])

  return (
    <div>
      <Row style={{ fontSize: "15px" }} >
        <Col md={6}>
          <Row className="pl-5 pr-5">
            <Col md={12}>
              <FormGroup row>
                <Col md={4}>
                 <label className="form-control-label">Created Date</label>
                </Col>
                <Col md={8}>
                   : <label className="pl-3">{createdOn}</label>
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row  className="pl-5 pr-5">
            <Col md={12}>
              <FormGroup row>
                <Col md={4}>
                 <label className="form-control-label">Created By</label>
                </Col>
                <Col md={8}>
                   : <label className="pl-3">{createdBy}</label>
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <Row  className="pl-5 pr-5">
           <Col md={12}>
              <FormGroup row>
                <Col md={4}>
                 <label className="form-control-label">Last Updated Date</label>
                </Col>
                <Col md={8}>
                   : <label className="pl-3">{(latestRecord?.updatedOn && moment.utc(latestRecord?.updatedOn).format("DD-MM-YYYY")) ?? updatedOn}</label>
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row  className="pl-5 pr-5">
           <Col md={12}>
              <FormGroup row>
                <Col md={4}>
                 <label className="form-control-label">Last Updated By</label>
                </Col>
                <Col md={8}>
                   : <label className="pl-3">{latestRecord?.updatedBy ?? createdBy}</label>
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default LogDetails