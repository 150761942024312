export const autoCompleterReqBody = (isDlpSearch, isDigitalSearch) => ({
  tenant: "PHOENIX",
  category: "MaterialMaster",
  value: '',
  oprId: "test123",
  params: {
    plantId: isDlpSearch ? 'DP01' : isDigitalSearch ? 'DG01' : 'HO01'
  }
})

export const initialFilterForm = {
  status: null,
  groupTypeIds: [],
}

export const getFilterCount = (filterForm, ignoreKeys = []) => {
  // changing this method can cause impact on multiple places, please check impact before changing anything
  let cnt = 0;
  for (let key in filterForm) {
    if (!ignoreKeys?.includes(key) && filterForm[key]?.toString().length) cnt++;
  }
  return cnt;
}

export const selectStyles = {
  control: {
    borderRadius: '4px',
    height: '40px'
  }
}
export const inputStyles = {
  borderRadius: '4px',
  height: '40px'
}

export const editCellContainerStyle = {
  width: '100%',
  padding: '0 10px'
}

export const newRow = {
  groupTypeId: '',
  materialNumber: '',
  status: 'ACTIVE',
}

export const typeDropdown = [
  {label: 'Onboarding', value: 'ONBOARDING'},
  {label: 'Multiple', value: 'MULTIPLE'},
  {label: 'Both', value: 'BOTH'},
]