import React, { useState, useRef, useEffect } from 'react';
import { Accordion } from '../../../../common/commonComponents';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import CommonInput from '../../../../common/formFeilds/input/commonInput';
import RadioButtons from '../../components/RadioButtons';
import VerticalTabs from '../../components/VerticalTabs';
import { Box, Backdrop, Tooltip } from '@mui/material';
import { IoClose } from 'react-icons/io5';
import CustomButton from '../../../../../../../components/CustomButton';
import CustomLoader from '../../../../common/commonComponents/Loader/loader';
import { fetchAllPromisedData, fetchAllPostPromisedData, getUniqueElements } from '../../../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../../../common/constant';
import { failureToast, successToast } from '../../../../common/utils/methods/toasterFunctions/function';
import { customValidation2 } from '../../../../common/utils/methods/validations/validation';
import CustmCheckbox from '../../../../common/formFeilds/customCheckbox';
import ACopyToClipboard from '../../../../common/ACopyToClipboard/ACopyToClipboard';
import { useParams } from 'react-router';
import DocUploadModal from './DocUploadModal';
import { ReactComponent as InfoIcon } from "../../../../../../../assets/img/svg/fi_info.svg"
import { ReactComponent as GSTFormat } from "../../../../../../../assets/img/svg/GSTFormat.svg"
import { ReactComponent as Upload } from "../../../../../../../assets/img/svg/upload.svg"
import { LightTooltip } from '../concessionDetails/prospectus';
import AButton from 'views/pages/manage/common/form-fields-mui/AButton';
import { masterServiceBaseUrl } from 'services/http';
import moment from 'moment';
// import { ReactComponent as File } from "../../../../../../../assets/img/DocumentLarge.jpg"


const VALIDATION_RULES = [
  { key: "paymentMode", isRequired: true, type: "id", "message": "Please provide Payment Mode", isObject: true },
  {
    key: "gstDetails", isRequired: false, isObject: true, dependencies: [
      { key: "companyName", isRequired: true, type: "string", "message": "Please provide GST Company Name", isObject: false },
      { key: "gstNo", isRequired: true, type: "string", "message": "Please provide GST No.", isObject: false },
      // {key : "gstNo", isRequired: true, minLength: 15, type : "string", "message" : "GST No. should be of 15 digits!",isObject : false},
      { key: "date", isRequired: true, type: "string", "message": "Please provide GST Date", isObject: false },
      { key: "type", isRequired: true, type: "id", "message": "Please provide GST Type", isObject: true },
      { key: "address1", isRequired: true, type: "string", "message": "Please provide address", isObject: false },
      { key: "address2", isRequired: false, type: "string", "message": "Please provide address", isObject: false },
      { key: "pincode", isRequired: true, type: "id", "message": "Please provide pincode", isObject: true },
      { key: "city", isRequired: true, type: "id", "message": "Please provide city", isObject: true },
      { key: "country", isRequired: true, type: "id", "message": "Please provide country", isObject: true },
      { key: "gstDocumentS3Key", isRequired: true, type: "string", "message": "Please upload GST certificate", isObject: false },
      { key: "gstCertificateName", isRequired: true, type: "string", "message": "Please provide GST certificate", isObject: false },
      { key: "remarks", isRequired: false, type: "string", "message": "Please provide remarks", isObject: false },
    ]
  }
];

const gstTypes = [
  { value: 'B2C', label: 'B2C' },
  { value: 'B2B', label: 'B2B' },
];

const NULL_OBJ = {
  paymentMode: { label: '', value: '' },
  vendorName: { label: '', value: '' },
  schemeName: { label: '', value: '', id: '' },
  uniqueID: '',
  gstDetails: {
    isRequired: true,
    companyName: '',
    gstNo: '',
    date: new Date(),
    type: { label: '', value: '' },
    address1: '',
    address2: '',
    pincode: { label: '', value: '' },
    city: { label: '', value: '' },
    state: { label: '', value: '' },
    country: { label: '', value: '' },
    gstCertificateName: '',
    gstDocumentS3Key: '',
    remarks: ''
  }
}

const PaymentMode = (props) => {

  const { userPermissions, applicationDetails, paymentDetails, paymentLoading, getPaymentDetails, isOpen, setIsOpen, courseCategory, disableActions, setAutoConcession, upfrontPaymentModes, setUpfrontPaymentModes, advancePaymentModes, setAdvancePaymentModes, selectedQoutationId, pastIdSelected, isConcessionUnderApproval, concessionDetails, paymentModificationAllowed, getApplicationDetails, modificationType, setCmprAllowed, setModificationType } = props;
  const [values, setValues] = useState(NULL_OBJ);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const prevPaymentMode = useRef(values.paymentMode);

  // const [upfrontPaymentModes, setUpfrontPaymentModes] = useState([]);
  // const [advancePaymentModes, setAdvancePaymentModes] = useState([]);
  const [allLoanSchemes, setAllLoanSchemes] = useState([]);

  const [pincodeData, setPincodeData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [gstDetailsOpen, setGstDetailsOpen] = useState(false);

  //** for loanScheme backdrop modal */
  const [vendorName, setVendorName] = useState(values.vendorName);
  const [schemeName, setSchemeName] = useState(values.schemeName);
  const [uniqueID, setUniqueID] = useState(values.uniqueID);

  const [validationRules, setValidationRules] = useState(VALIDATION_RULES);

  const [schemeOptions, setSchemeOptions] = useState(() => {
    return allLoanSchemes.filter(item => item.vendorName === vendorName.value).map(item => ({ ...item, id: item.id, label: item.value }));
  });

  const [gstDocModalOpen, setGstDocModalOpen] = useState(false);

  const [viewMode, setViewMode] = useState(false);

  const [loanVendorsDropdown , setLoanVendorsDropdown] = useState([]);

  const getLoanVendorsDropdown = async()=>{
    const req = {
         businessAreaId : applicationDetails?.businessAreaId,
         academicCareer : applicationDetails?.academicCareerValue
    }
    try{
      const resp = await fetchAllPostPromisedData(endpoint.loanCareerValidation.getActiveLoanSetupByBUAndAcadCareer, req , 'post', false);
      if(resp?.code === 200){
        const distinctLoanVendors=getUniqueElements(resp?.data?.map(item => ({...item, label: item.loanVendor, value: item.loanVendor })), (el) => el.value);
        setLoanVendorsDropdown(distinctLoanVendors)
      }
    }catch(err){
      console.log(err);
    }
  }

  useEffect(()=>{
    applicationDetails?.businessAreaId && getLoanVendorsDropdown();
  }, [applicationDetails?.businessAreaId])

  // useEffect(() => {
  //     setSchemeName(null);
  //     setSchemeOptions(allLoanSchemes.filter(item => item.vendorName === vendorName.value).map(item => ({...item, id: item.id, label: item.value})));
  // }, [vendorName]);

  useEffect(() => {
    setUniqueID(schemeName?.id)
  }, [schemeName]);

  useEffect(() => {
    setVendorName(values.vendorName);
    setSchemeName(values.schemeName);
    setUniqueID(values.uniqueID);
    setSchemeOptions(allLoanSchemes.filter(item => item.vendorName === values?.vendorName?.value).map(item => ({ ...item, id: item.id, label: item.value })));
  }, [values]);


  let { id, action } = useParams();

  async function getAllPaymentModes() {
    setLoading(true);
    try {
      const resp = await fetchAllPromisedData(endpoint.paymentMode.getActive);
      setLoading(false);

      if (resp) {
        const _paymentModes = resp.map(item => ({ ...item, label: item.paymentModeDispValue, value: item.paymentModeKey }));
        const upfrontModes = _paymentModes.filter(item => item.paymodeType === 'UPFRONT');
        const advenceModes = _paymentModes.filter(item => item.paymodeType === 'ADVANCE');

        setUpfrontPaymentModes(upfrontModes);
        setAdvancePaymentModes(advenceModes);
      } else {
        failureToast("Couldn't fetch payment methods");
      }
    } catch (err) {
      setLoading(false);
      failureToast("Couldn't fetch payment methods");
    }
  }

  async function getAllLoanSchemes() {
    setLoading(true);
    try {
      const resp = await fetchAllPromisedData(`${endpoint.loanScheme}/allEffective `);
      setLoading(false);

      if (resp) {
        setAllLoanSchemes(resp?.filter(el => el.status === 'ACTIVE'));
      } else {
        failureToast("Couldn't fetch loan schemes.");
      }
    } catch (err) {
      setLoading(false);
      failureToast("Couldn't fetch loan schemes.");
    }
  }

  const fetchData = async (url, setData, name) => {
    const data = await fetchAllPromisedData(url, true);
    try {
      if (data?.code === 200) {
        setData(data.data?.filter(el => el.status === 'ACTIVE')?.map(el => ({
          ...el,
          label: name === 'stateDispValue' ? `${el[`${name}`]} | ${el['stateKey']}` : el[`${name}`],
          value: el[`${name}`]
        })))
      }
    } catch (err) {
      console.log(err);
    }

  }

  useEffect(() => {
    getAllPaymentModes();
    getAllLoanSchemes();
  }, []);


  useEffect(() => {

    const { paymentModeMasterId, loanSchemeMasterId, gstDetailsDto, organizationType, gstEffectiveDate } = paymentDetails ?? {};
    const _paymentMode = [...upfrontPaymentModes, ...advancePaymentModes].find(item => item.id === paymentModeMasterId) || NULL_OBJ.paymentMode;
    const _vendorName = allLoanSchemes.find(item => item.id === loanSchemeMasterId) || NULL_OBJ.vendorName;
    const _schemeName = allLoanSchemes.find(item => item.id === loanSchemeMasterId) || NULL_OBJ.schemeName;

    gstTypeChangeHandler(organizationType);

    const val = {
      paymentMode: _paymentMode,
      // vendorName: { label: _vendorName.vendorName, value: _vendorName.vendorName },
      vendorName: loanVendorsDropdown?.find(el => el.value === _vendorName.vendorName),
      schemeName: { ..._schemeName, id: _schemeName.id, label: _schemeName.value },
      uniqueID: _schemeName.id,
      gstDetails: {
        // isRequired: !!(gstDetailsDto?.companyName || gstDetailsDto?.gstinNo || gstDetailsDto?.effectiveDate || (organizationType === 'B2C' || organizationType === 'B2B')||true),
        isRequired: true,
        companyName: gstDetailsDto?.companyName || NULL_OBJ.gstDetails.companyName,
        gstNo: gstDetailsDto?.gstinNo || NULL_OBJ.gstDetails.gstNo,
        date: gstEffectiveDate || new Date(),
        type: gstTypes.find(item => item.value === organizationType) || gstTypes[0],
        address1: gstDetailsDto?.address1 || NULL_OBJ.gstDetails.address1,
        address2: gstDetailsDto?.address2 || NULL_OBJ.gstDetails.address2,
        pincode: gstDetailsDto?.pincode ? { label: gstDetailsDto?.pincode, value: gstDetailsDto?.pincode } : NULL_OBJ.gstDetails.pincode,
        city: gstDetailsDto?.city ? { label: gstDetailsDto?.city, value: gstDetailsDto?.city } : NULL_OBJ.gstDetails.city,
        state: gstDetailsDto?.state ? { label: gstDetailsDto?.state, value: gstDetailsDto?.state } : NULL_OBJ.gstDetails.state,
        country: gstDetailsDto?.country ? { label: gstDetailsDto?.country, value: gstDetailsDto?.country } : NULL_OBJ.gstDetails.country,
        gstCertificateName: gstDetailsDto?.gstCertificate || NULL_OBJ.gstDetails.gstCertificateName,
        gstDocumentS3Key: gstDetailsDto?.gstDocumentS3Key || NULL_OBJ.gstDetails.gstDocumentS3Key,
        remarks: gstDetailsDto?.remarks || NULL_OBJ.gstDetails.remarks
      }
    }

    setValues(val);
  }, [paymentDetails, upfrontPaymentModes, advancePaymentModes, allLoanSchemes, loanVendorsDropdown]);

  const getPaymentTabIndex = (paymentMode) => {
    switch (true) {
      case (!!upfrontPaymentModes?.find(item => item.value === paymentMode?.value)): return 0;
      case (!!advancePaymentModes?.find(item => item.value === paymentMode?.value)): return 1;
      default:
        return 0;
    }
  }

  const handlePaymentModeChange = (val) => {
    const paymodAlreadyAdded = concessionDetails?.filter(i => i.concessionMethodType?.split("_")?.[1] === 'PAYMENT');
    if(paymodAlreadyAdded?.length){
      failureToast("Pyament mode concession is already availed, Please remove the previous one to update the payment mode.")
      return
    }

    
    if (!(values?.vendorName?.value && values?.schemeName?.value && values?.uniqueID) && val?.value === 'Loan') {
      if(loanVendorsDropdown?.length===0) failureToast('This branch and Academic Career combination is not allowed to provide loan.')
      else setOpen(true);
    } else {
      setValues({ ...values, paymentMode: val });
      prevPaymentMode.current = val;
    }
  }

  const onSaveLoanScheme = () => {
    setValues({ ...values, paymentMode: advancePaymentModes.find(item => item.value === 'Loan'), vendorName, schemeName, uniqueID });
    setOpen(false);
  }

  const isGSTNoValid = (gstNo) => {
    let regex = new RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/);

    if (gstNo == null) return false;

    if (regex.test(gstNo) == true) return true;
    return false;
  }

  const gstDocSaveHandler = (s3Key, fileName) => {
    setValues({
      ...values, gstDetails: {
        ...values.gstDetails,
        gstDocumentS3Key: s3Key,
        gstCertificateName: fileName
      }
    })
    setGstDocModalOpen(false);
  }

  const onSaveAndNextClick = async () => {
    console.log('gstType--->>', values.gstDetails.type.value)
    if (!customValidation2(values, validationRules)) return;
    if (values.gstDetails.gstNo && values.gstDetails.type.value === 'B2B' && !isGSTNoValid(values.gstDetails.gstNo)) {
      failureToast('GST no. entered does not follow the format. Please refer the format to proceed.');
      return
    }
    const requestPayload = {
      applicationId: id,
      psid: applicationDetails.psid,
      paymentModeMasterId: values.paymentMode?.id || null,
      loanSchemeMasterId: values.paymentMode.paymentModeDispValue === "Loan" ? values.schemeName?.id : null,
      chargeStatus: applicationDetails.chargeStatus,
      paymentStatus: applicationDetails.paymentStatus,
      courseCategoryId: applicationDetails.courseCategoryId,
      applicationBusinessArea: applicationDetails.businessAreaId,
      courseCategoryValue: applicationDetails?.courseCategoryValue ?? '',
      organizationType: values.gstDetails.type.value,
      gstEffectiveDate: values.gstDetails.date,
      gstDetailsDto: {
        companyName: values.gstDetails.companyName || null,
        gstinNo: values.gstDetails.gstNo || null,
        address1: values.gstDetails.address1 || null,
        address2: values.gstDetails.address2 || null,
        pincode: values.gstDetails.pincode?.value || null,
        city: values.gstDetails.city?.value || null,
        state: values.gstDetails.state?.value || null,
        country: values.gstDetails.country?.value || null,
        gstCertificate: values.gstDetails.gstCertificateName || null,
        gstDocumentS3Key: values.gstDetails.gstDocumentS3Key || null,
        remarks: values.gstDetails.remarks || null,
      },
      cmpr: modificationType,
      documentAttached: paymentDetails?.documentAttached ?? false,
      totalNetPayable : applicationDetails?.totalCourseFees,
      ...(values?.paymentMode?.value === 'Loan' && {loanCareerSetupId : vendorName?.id })
    }

    try {
      setLoading(true);
      const resp = await fetchAllPostPromisedData(`${endpoint.applicationQuotationDetails}${action === "add" && !paymentDetails?.id ? '' : '/' + id}`,
        requestPayload, action === "add" && !paymentDetails?.id ? 'post' : 'put');
      setLoading(false);
      if (resp && resp.code === 200) {
        successToast("Payment Details saved successfully");
        getApplicationDetails();
        getPaymentDetails();
        // checkForAutoConcession();
        setViewMode(true);
        setCmprAllowed(false)
        setModificationType(null)
        setIsOpen({
          detailsTab: false,
          paymentTab: false,
          concessionsTab: true,
          finalTab: false
        });
      } 
      // else {
      //    failureToast("Couldn't save payment details");
      // }
    } catch (err) {
      setLoading(false);
      // failureToast("Couldn't save payment details");
    }
  }

  const defaultViewComp = values.paymentMode?.value || values.gstDetails.companyName || values.gstDetails.gstNo || values.gstDetails.date ?
    <Row>
      {values.paymentMode?.value && <Col md="3">
        <CommonInput
          type="text"
          defaultValue={values.paymentMode?.value}
          label={upfrontPaymentModes?.find(item => item.value === values.paymentMode?.value) ? 'Upfront mode' : 'Advance mode' || 'Payment mode'}
          readOnly={true}
        />
      </Col>}
      
      {paymentDetails?.organizationType && <Col md="3">
        <CommonInput
          type="text"
          defaultValue={paymentDetails.organizationType}
          label ="GST Type"
          readOnly={true}
        />
      </Col>}
      
      {values.paymentMode?.value === 'Loan' &&
        <>
          <Col md="3">
            <CommonInput
              type="select"
              defaultValue={values?.vendorName?.label ?? '-'}
              label="Vendor"
              readOnly={true}
            />
          </Col>
          <Col md="3">
            <CommonInput
              type="select"
              defaultValue={values?.schemeName}
              label="Scheme"
              readOnly={true}
            />
          </Col>
          <Col md="3">
            <CommonInput
              type="text"
              label="Reference ID"
              defaultValue={values.uniqueID||'-'}
              readOnly={true}
            />
          </Col>
        </>
      }
      {values.gstDetails.companyName && <Col md="3">
        <CommonInput
          type="text"
          defaultValue={values.gstDetails.companyName}
          label="Company name"
          readOnly={true}
        />
      </Col>}
      {values.gstDetails.gstNo && <Col md="3">
        <CommonInput
          type="text"
          defaultValue={values.gstDetails.gstNo || "-"}
          label="GST no."
          readOnly={true}
          readOnlyComp={
            <h3>
              {values.gstDetails.gstNo || "-"}
              {values.gstDetails.gstNo && <ACopyToClipboard text={values.gstDetails.gstNo} />}
            </h3>
          }
        />
      </Col>}
      {values.gstDetails.date && <Col md="3">
        <CommonInput
          type="datePicker"
          label="Date"
          defaultValue={values.gstDetails.date}
          readOnly={true}
        />
      </Col>}
    </Row> : null;
  const paymentModes = [
    (upfrontPaymentModes?.length > 0 && {
      id: 1, label: "Upfront Modes", tabPanel:
        <Box className='upfront-mode-tabpanel'>
          <RadioButtons readOnly={action === 'view' || disableActions || viewMode || isConcessionUnderApproval} formLabel={"Select preferred payment mode"} options={upfrontPaymentModes} value={values.paymentMode} onChange={val => { handlePaymentModeChange(val) }} />
        </Box>
    }),
    (advancePaymentModes?.length > 0 && {
      id: 2, label: "Advance", tabPanel:
        <Box className='advance-tabpanel'>
          <RadioButtons readOnly={action === 'view' || disableActions || viewMode || isConcessionUnderApproval} formLabel={"Select preferred payment mode"} options={advancePaymentModes} value={values.paymentMode} onChange={val => { handlePaymentModeChange(val) }} />
          {values.paymentMode?.value === 'Loan' &&
            <>
              <Row className='loan-scheme-details'>
                <Col md='3'>
                  <CommonInput
                    type="select"
                    mandatory={false}
                    defaultValue={values?.vendorName}
                    label="Vendor name"
                    readOnly={true}
                  />
                </Col>
                <Col md='3'>
                  <CommonInput
                    type="select"
                    mandatory={false}
                    defaultValue={values?.schemeName}
                    label="Scheme"
                    readOnly={true}
                  />
                </Col>
                <Col md='3'>
                  <CommonInput
                    type="number"
                    mandatory={false}
                    defaultValue={values?.uniqueID}
                    label="Reference ID"
                    readOnly={true}
                  />
                </Col>
              </Row>
              {(action === 'add' || action === 'edit') && <h3 className='change-loan-scheme-button' onClick={() => setOpen(true)}>Change loan scheme</h3>}
            </>}
        </Box>
    }),
  ];


  const labelStyles = {
    marginBottom: '4px',
    color: '#525f7f',
    fontWeight: 600,
    fontSize: '0.875rem'
  }

  const pincodeChangeHandler = (val) => {
    setValues({
      ...values,
      gstDetails: {
        ...values.gstDetails,
        pincode: val,
        city: cityData.find(city => city.id === val.cityId),
        state: stateData.find(state => state.id === val.stateId) || null,
        country: countryData.find(country => country.id === val.countryId),
      }
    })
  }

  const fetchLocationData = () => {
    fetchData(`${masterServiceBaseUrl}/pincode/getAllPincode`, setPincodeData, "pincodeGstCode");
    fetchData(`${masterServiceBaseUrl}/city/getAllCity`, setCityData, 'cityDispValue');
    fetchData(`${masterServiceBaseUrl}/state/getAllState`, setStateData, 'stateDispValue');
    fetchData(`${masterServiceBaseUrl}/country/getAllCountry`, setCountryData, 'countryDispValue');
  }

  const gstTypeChangeHandler = (val) => {
    if (val === 'B2B') {
      setValidationRules(rules => ([rules[0], { ...rules[1], isRequired: true }]));
      if (!(pincodeData.length || stateData.length || cityData.length || countryData.length))
        fetchLocationData();
    } else {
      // setValues(values=>({...values, gstDetails: NULL_OBJ.gstDetails}));
      setValidationRules(rules => ([rules[0], { ...rules[1], isRequired: false }]));
    }
    setValues({ ...values, gstDetails: { ...values.gstDetails, type: gstTypes.find(el => el.value === val) } })
  }

  const allGSTDetailsEntered = validationRules[1]?.isRequired &&
    (!values.gstDetails.companyName ||
      !values.gstDetails.gstNo ||
      !values.gstDetails.address1 ||
      !values.gstDetails.pincode.value ||
      !values.gstDetails.gstCertificateName)

  return (
    <>
      <Accordion
        isFormOpen={isOpen.paymentTab}
        title={`Payment mode`}
        key={'Payment_mode'}
        defaultViewComp={defaultViewComp}
        isEditFormOpen={(open) => {
          open === false ?
            setIsOpen(state => ({ ...state, paymentTab: false })) :
            setIsOpen({
              detailsTab: false,
              paymentTab: true,
              concessionsTab: false,
              finalTab: false
            })
        }}
      >
        <Card className='payment-mode-card'>
          <CardBody>
            {(loading || paymentLoading) ? <CustomLoader /> : <VerticalTabs options={paymentModes} defaultValue={getPaymentTabIndex(values.paymentMode)} />}
          </CardBody>
        </Card>
        <Accordion
          isFormOpen={gstDetailsOpen}
          title={'GST Details'}
          key={'Gst Details'}
          isEditFormOpen={(open) => {
            if (open) {
              setGstDetailsOpen(true);

            }
            setGstDetailsOpen(false)
          }}
        >
          <Card className='gst-details-card'>
            <CardBody>
              {(loading || paymentLoading) ? <CustomLoader /> : <>
                {values.gstDetails.isRequired && <Row style={{ padding: '10px' }}>
                  <Col md="3" className="p-0">
                    <div style={labelStyles}>Type</div>
                    <RadioButtons readOnly={action === 'view' || values.gstDetails.isRequired === false || disableActions || viewMode || isConcessionUnderApproval} options={gstTypes} value={values.gstDetails.type} onChange={(val) => gstTypeChangeHandler(val.value)} />
                  </Col>

                  <Col md="3">
                    <CommonInput
                      type="text"
                      mandatory={false}
                      defaultValue={moment(values.gstDetails.date).format("DD-MMM-YYYY")}
                      onChange={(val) => setValues({ ...values, gstDetails: { ...values.gstDetails, date: val } })}
                      label="Effective Date"
                      readOnly={action === 'view' || viewMode}
                      isDisabled
                      placeHolder={'Select date'}
                    />
                  </Col>
                  {values.gstDetails.type.label === 'B2B' &&
                    <>
                      <Col md="3">
                        <CommonInput
                          type="text"
                          mandatory={false}
                          defaultValue={values?.gstDetails?.companyName}
                          onChange={(val) => setValues({ ...values, gstDetails: { ...values.gstDetails, companyName: val } })}
                          label="Company name*"
                          readOnly={action === 'view' || viewMode}
                          isDisabled={values.gstDetails.isRequired === false || disableActions}
                          placeHolder={'Enter company name'}
                        />
                      </Col>
                      <Col md="3">
                        <div style={labelStyles}>
                          GST no*
                          <LightTooltip title={<img src={require('../../../../../../../assets/img/svg/GSTFormat.svg')} style={{ width: '300px', height: '200px' }} />} placement="top" arrow>
                            <InfoIcon />
                          </LightTooltip>
                        </div>
                        <CommonInput
                          type="text"
                          mandatory={false}
                          restrictSpecialCharacter={true}
                          maxLength={15}
                          defaultValue={values.gstDetails.gstNo}
                          onChange={(val) => setValues({ ...values, gstDetails: { ...values.gstDetails, gstNo: val.toUpperCase() } })}
                          readOnly={action === 'view' || viewMode}
                          isDisabled={values.gstDetails.isRequired === false || disableActions}
                          placeHolder={'Enter GST No.'}
                        />
                      </Col>

                      <Col md="6">
                        <CommonInput
                          type="text"
                          mandatory={false}
                          defaultValue={values.gstDetails.address1}
                          onChange={(val) => setValues({ ...values, gstDetails: { ...values.gstDetails, address1: val } })}
                          label="Address line 1*"
                          readOnly={action === 'view' || viewMode}
                          isDisabled={values.gstDetails.isRequired === false || disableActions}
                          placeHolder={'Enter adddress line 1'}
                        />
                      </Col>
                      <Col md="6">
                        <CommonInput
                          type="text"
                          mandatory={false}
                          defaultValue={values.gstDetails.address2}
                          onChange={(val) => setValues({ ...values, gstDetails: { ...values.gstDetails, address2: val } })}
                          label="Address line 2"
                          readOnly={action === 'view' || viewMode}
                          isDisabled={values.gstDetails.isRequired === false || disableActions}
                          placeHolder={'Enter address line 2'}
                        />
                      </Col>

                      <Col md="3">
                        <CommonInput
                          type="select"
                          mandatory={false}
                          restrictSpecialCharacter={true}
                          maxLength={6}
                          defaultValue={values.gstDetails.pincode}
                          onChange={(val) => pincodeChangeHandler(val)}
                          label="Pincode*"
                          readOnly={action === 'view' || viewMode}
                          data={pincodeData}
                          isDisabled={values.gstDetails.isRequired === false || disableActions}
                          placeHolder={'Pincode'}
                        />
                      </Col>
                      <Col md="3">
                        <CommonInput
                          type="select"
                          mandatory={false}
                          restrictSpecialCharacter={true}
                          maxLength={15}
                          defaultValue={values.gstDetails.city}
                          label="City"
                          readOnly={action === 'view' || viewMode}
                          isDisabled
                          placeHolder={"City"}
                        />
                      </Col>
                      <Col md="3">
                        <CommonInput
                          type="select"
                          mandatory={false}
                          restrictSpecialCharacter={true}
                          maxLength={15}
                          defaultValue={values.gstDetails.state}
                          label="State"
                          readOnly={action === 'view' || viewMode}
                          isDisabled
                          placeHolder={"State"}
                        />
                      </Col>
                      <Col md="3">
                        <CommonInput
                          type="select"
                          mandatory={false}
                          restrictSpecialCharacter={true}
                          maxLength={15}
                          defaultValue={values.gstDetails.country}
                          label="Country"
                          readOnly={action === 'view' || viewMode}
                          isDisabled
                          placeHolder={"Country"}
                        />
                      </Col>
                      <Col md="4">
                        <div style={labelStyles}>GST Certificate *</div>
                        {values.gstDetails.gstDocumentS3Key ?
                          <>
                            <img src={require('../../../../../../../assets/img/DocumentLarge.jpg')} style={{ height: '24px', width: '24px', marginRight: '10px' }} />
                            <span style={{ fontSize: '16px', fontWeight: 600, color: '#262626' }}>{values.gstDetails.gstCertificateName}</span>
                            {!(action === "view" || values.gstDetails.isRequired === false || disableActions || viewMode) && <Upload className='ml-2' onClick={() => setGstDocModalOpen(true)} />}
                          </> :
                          <AButton
                            variant="outline"
                            children={<><Upload className='mr-2' /> Upload </>}
                            updatedStyle={{ width: '100%' }}
                            onClick={() => setGstDocModalOpen(true)}
                          />}
                      </Col>
                      <Col md="8">
                        <CommonInput
                          type="text"
                          mandatory={false}
                          defaultValue={values.gstDetails.remarks}
                          onChange={(val) => setValues({ ...values, gstDetails: { ...values.gstDetails, remarks: val } })}
                          label="Remarks"
                          readOnly={action === 'view' || viewMode}
                          isDisabled={values.gstDetails.isRequired === false || disableActions}
                          placeHolder={"Add remarks"}
                        />
                      </Col>
                      <DocUploadModal
                        open={gstDocModalOpen}
                        setOpen={setGstDocModalOpen}
                        values={values}
                        setValues={setValues}
                        saveHandler={gstDocSaveHandler}
                        acceptedFileTypes={[".pdf", ".jpg", ".jpeg", ".png"]}
                        maxUploadFileSize={5}
                        serviceName={'STUDENT_GST_CERTIFICATE'}
                        uniqueKeyParameter={"consession"}
                        uploadedFileS3Key={values?.gstDetails?.gstDocumentS3Key}
                        uploadedFileName={values?.gstDetails?.gstCertificateName}
                        modalTitle={"Upload GST Document"}
                      />

                    </>
                  }
                </Row>}
              </>}
            </CardBody>
          </Card>
        </Accordion>
        {(action === 'add' || action === 'edit') && <CustomButton
          className='mb-3'
          content={viewMode ? 'Edit' : 'Save & Next'}
          permissionType={'C,U'}
          permissionSet={userPermissions}
          onClick={viewMode ? () => setViewMode(false) : onSaveAndNextClick}
          disabled={disableActions || allGSTDetailsEntered || isConcessionUnderApproval || !paymentModificationAllowed}
        />}
      </Accordion>


      {/** Loan Scheme Selection Modal */}

      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={() => { }}
      >
        <Card className="loan-scheme-backdrop">
          <CardBody>
            <Row className="d-flex justify-content-between align-items-center pl-3 pr-3 my-3">
              <h2>Select loan scheme</h2>
              <IoClose size={20} style={{ cursor: "pointer" }} onClick={() => setOpen(false)} />
            </Row>
            <CommonInput
              type="select"
              mandatory={false}
              data={loanVendorsDropdown}
              defaultValue={vendorName}
              onChange={(val) => {
                setVendorName(val)
                setSchemeName(null);
                setSchemeOptions(allLoanSchemes.filter(item => item.vendorName === val.value).map(item => ({ ...item, id: item.id, label: item.value })));
              }}
              label="Vendor name"
              isDisabled={disableActions}
            />
            <CommonInput
              type="select"
              mandatory={false}
              data={schemeOptions}
              defaultValue={schemeName}
              onChange={(val) => setSchemeName(val)}
              label="Scheme name"
              isDisabled={vendorName === null || disableActions}
            />
            <CustomButton
              className='my-3'
              content={'Save'}
              permissionType={'C,U'}
              permissionSet={userPermissions}
              onClick={onSaveLoanScheme}
              disabled={!vendorName?.value || !schemeName?.value || disableActions}
            />
          </CardBody>
        </Card>
      </Backdrop>

      {/** Loan Scheme Selection Modal ends */}

    </>
  )
}

export default PaymentMode