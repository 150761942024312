import React, { useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import AAutoComplete from 'views/pages/manage/common/form-fields-mui/AAutoComplete'
import AButton from 'views/pages/manage/common/form-fields-mui/AButton'
import { ReactComponent as CloseIcon } from '../../../../../../assets/img/svg/CloseIcon.svg'
import styles from '../styles.module.scss'
import { getDropdownIdsFromSelectArray } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod'
import { fetchBusinessAreasByRegions } from 'views/pages/manage/academicPlanAndTest/constant/common'
import { Checkbox, FormControlLabel } from '@mui/material'
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import moment from 'moment'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { getMomentObjFromTimeString } from '../common'
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper'

const timepickerStyle = {
  '& .MuiInputBase-root': {
    height: '31px',
  },
  '& .MuiInputBase': {
    borderRadius: '8px',
  },
  '& .MuiInputBase-input': {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px'
  },
  '& .MuiInputAdornment-root': {
    display: 'none',
  }
}

const DaysPlanSearchFilterForm = ({
  closeForm,
  removeFilterHandler = () => { },
  applyFilterHandler = () => { },
  filterFormHandler = () => { },
  filterForm,
  filterCount,
  regionData,
  businessAreaData,
  setBusinessAreaData,
}) => {

  const [businessAreaLoader, setBusinessAreaLoader] = useState(false);
  const fetchBusinessArea = async (regionsArray) => {
    if (regionsArray?.length > 0) {
      setBusinessAreaLoader(true);
      const responseData = await fetchBusinessAreasByRegions(getDropdownIdsFromSelectArray(regionsArray));
      setBusinessAreaData(responseData);
      setBusinessAreaLoader(false);
    } else {
      setBusinessAreaData([]);
    }
  }

  return (
    <Card className='mb-0' style={{ width: '324px' }}>
      <CardBody className='d-flex flex-column'>
        <div className='mb-4 d-flex align-items-start justify-content-between'>
          <div className='heading-4' style={{ color: '#262626' }}>Filter</div>
         <IconButtonWrapper onClick={closeForm}>  <CloseIcon /> </IconButtonWrapper>
        </div>
        <div>
          <Row>
            <Col md={6}>
              <FormControlLabel
                sx={{ marginBottom: "0px" }}
                control={
                  <Checkbox
                    onChange={(e) => filterFormHandler(e?.target?.checked, 'active')}
                    checked={filterForm?.active}
                  // sx={checkBoxStyle}
                  />
                }
                label={<span style={{ fontSize: '12px' }}>Active</span>}
              />
            </Col>
            <Col md={6}>
              <FormControlLabel
                sx={{ marginBottom: "0px" }}
                control={
                  <Checkbox
                    onChange={(e) => filterFormHandler(e?.target?.checked, 'inActive')}
                    checked={filterForm?.inActive}
                  // sx={checkBoxStyle}
                  />
                }
                label={<span style={{ fontSize: '12px' }}>Inactive</span>}
              />
            </Col>
          </Row>
          <Row>
            <Col className='mb-2' sm='12'>
              <AAutoComplete
                label="Region"
                currentValue={filterForm?.regions}
                items={regionData}
                handleChange={(value) => {
                  filterFormHandler(value, 'regions');
                  filterFormHandler([], 'businessAreas');
                  fetchBusinessArea(value);
                }}
                isShowCount={true}
              />
            </Col>
            <Col className='mb-3' sm='12'>
              <AAutoComplete
                label="Business area"
                currentValue={filterForm?.businessAreas}
                items={businessAreaData}
                handleChange={(value) => filterFormHandler(value, 'businessAreas')}
                isDisabled={!filterForm?.regions?.length}
                isShowCount={true}
                isLoading={businessAreaLoader}
              />
            </Col>

            <Col className='mb-2' sm='12'>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Row>
                  <Col className='mb-2' sm='6'>
                    <div className='mb-1 regular-small' >Start time</div>
                    <div>
                      <DesktopTimePicker
                        ampm={false}
                        value={getMomentObjFromTimeString(filterForm?.startTime)}
                        onChange={(value) => filterFormHandler(moment(value).seconds(0).format('HH:mm:ss'), 'startTime')}
                        sx={timepickerStyle}
                      />
                    </div>
                  </Col>
                  <Col className='mb-2' sm='6'>
                    <div className='mb-1 regular-small'>End time</div>
                    <div>
                      <DesktopTimePicker
                        ampm={false}
                        value={getMomentObjFromTimeString(filterForm?.endTime)}
                        onChange={(value) => filterFormHandler(moment(value).seconds(0).format('HH:mm:ss'), 'endTime')}
                        sx={timepickerStyle}
                      />
                    </div>
                  </Col>
                </Row>
              </LocalizationProvider>
            </Col>
          </Row>
        </div>
        <div className='mt-2 d-flex justify-content-between' style={{ marginTop: 'auto' }}>
          <AButton
            variant='primary_filled'
            className='button_remove_left_margin'
            onClick={() => { closeForm(); applyFilterHandler(); }}
          >
            Apply
          </AButton>
          {filterCount > 0 && (
            <AButton
              variant='outline'
              className='button_remove_left_margin'
              updatedStyle={{ border: "1px solid #CC5F5F", color: '#CC5F5F' }}
              onClick={() => { closeForm(); removeFilterHandler(); }}
            >
              Remove filter
            </AButton>
          )}
        </div>
      </CardBody>
    </Card>
  )
}

export default DaysPlanSearchFilterForm