import React from 'react'
import styles from './common/facultyRoasterList.module.css'
import commonStyles from './common/commonStyles.module.css'
import { ReactComponent as TimeTableHeaderImage } from '../../../../../assets/img/svg/TimeTableHeaderImage.svg'
import { useHistory } from 'react-router'
import './index.scss';
import edit from '../../../../../assets/img/svg/edit3.svg'


const HeaderComponent = ({ isInformationAvailable = false, facultyDetail, dropdowns, loading, userPermissions, headerTitle }) => {
  const history = useHistory();

  const clickHandler = (e) => {
    if (e?.ctrlKey) {
      window.open(history.createHref({ pathname: `/admin/facultyRoaster/newRoaster` }), '_blank')
    } else {
      history.push(`/admin/facultyRoaster/newRoaster`)
    }
  }

  const getLabel = (list) => {
    return list?.length > 1 ? `${list?.[0]} +${list?.length -1} More` : list?.length ? list?.[0] : ' '
  }

  return (
    <div className={styles.header_div}>
      {isInformationAvailable && !loading ? <div className='d-flex flex-column align-items-start ' style={{ flex: 1, height: '100%', gap: 30 }}>
        <div>{headerTitle ?? `Faculty roster / ${facultyDetail?.facultyName} roster`}</div>
        <div className='faculty-detail-labels-wrapper'>
          <div className='faculty-detail-labels'>
            <div className='faculty-details-label-heading'>Faculty name & ID</div>
            <div className='faculty-details-label-content-text'>{`${facultyDetail?.facultyName} | ${facultyDetail?.facultyId}`}</div>
          </div>
          <div className='faculty-detail-labels'>
            <div className='faculty-details-label-heading'>Subject</div>
            <div className='faculty-details-label-content-text'>{getLabel(dropdowns?.subject?.filter(sub => facultyDetail?.subject?.indexOf(sub.value) > -1)?.map(filtedSub => filtedSub.label))}</div>
          </div>
          <div className='faculty-detail-labels'>
            <div className='faculty-details-label-heading'>Business area</div>
            <div className='faculty-details-label-content-text'>{getLabel(facultyDetail?.facultyMasterBusinessAreasResponseList?.map(ba => ba.businessAreaValue))}</div>
          </div>
          <div className='faculty-detail-labels'>
            <div className='faculty-details-label-heading'>Academic career</div>
            <div className='faculty-details-label-content-text'>{getLabel(dropdowns?.academicCareer?.filter(sub => facultyDetail?.academicCareer?.indexOf(sub.value) > -1)?.map(filtedSub => filtedSub.label))}</div>
          </div>
          <div className='faculty-detail-labels'>
            <div className='faculty-details-label-heading'>Academic group</div>
            <div className='faculty-details-label-content-text'>{getLabel(dropdowns?.academicGroup?.filter(sub => facultyDetail?.academicGroup?.indexOf(sub.value) > -1)?.map(filtedSub => filtedSub.label))}</div>
          </div>
          {/* <div className='faculty-detail-labels-edit-btn'>
            <img src={edit} alt='' width={20} height={20} style={{ color: '#00B0F5' }} />
          </div> */}
        </div>
      </div> :
        <>
          <div className='d-flex flex-column align-items-start justify-content-between' style={{ flex: 1, height: '100%' }}>
            <div>
              <h3 style={{ fontSize: '28px', fontWeight: 700, color: 'inherit' }}>Faculty Roster</h3>
              <span style={{ color: '#CCEFFD' }}>Here you can find all faculty roster.</span>
            </div>
            {userPermissions && userPermissions.includes('C') && (<span className={commonStyles.primary_filled_button} onClick={clickHandler}>Create Roster</span>)}
          </div>
          <TimeTableHeaderImage />
        </>
      }

    </div>
  )
}

export default HeaderComponent