import React, { useContext, useEffect, useState } from 'react'
import { endpoint, pages } from '../../common/constant'
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod'
import HeaderComponent from './HeaderComponent'
import PlanView from './PlanView'
import PageLoadingAnimation from 'views/pages/manage/common/commonComponents/PageLoadingAnimation';
import { PermissionContext, RolePermissions } from 'appContext'
import { PermisionDenied } from '../../common/commonComponents'
import PlanStatusChangeDialog from './PlanStatusChangeDialog'
import { successToast } from '../../common/utils/methods/toasterFunctions/function'
import { getCoursesByPackageId } from '../academicTest/style'
import { groupBy } from './utils'

const ViewAcademicPlan = (props) => {

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages['searchAcademicPlan']['id']);

  const { id } = props.match.params
  const [planData, setPlanData] = useState({});
  const [apiLoader, setApiLoader] = useState(false);
  const [planApiLoader, setPlanApiLoader] = useState(false);
  const [testApiLoader, setTestApiLoader] = useState(false);
  const [lectureApiLoader, setLectureApiLoader] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [testCountObj, setTestCountObj] = useState({});
  const [testCount, setTestCount] = useState(0);
  const [courses, setCourses] = useState([]);
  const [subjectLecturesCount, setSubjectCount] = useState({})
  // const searchParams = getUrlSearchParams(props?.location?.search)

  const fetchData = async (id) => {
    setPlanApiLoader(true);
    const response = await fetchAllPromisedData(`${endpoint.viewAcademicPlan.getPlanById}/${id}?requireDispValue=true`, true)
    // console.log(response)
    if (response?.data) {
      setPlanData(response?.data);
      const coursesResponse = await getCoursesByPackageId(response?.data?.packageId);
      setCourses(coursesResponse?.data?.packages?.[0]?.courses);
    } else {
      setPlanData({});
    }
    setPlanApiLoader(false);
  }

  const fetchCourseLecture = () => {
    setLectureApiLoader(true);
    fetchAllPromisedData(`${endpoint.lecture.getLectures}/${id}`)
      .then(res => {
        if (res) {
          let groupBySubject = (groupBy(res['lectureAcademicPlanMappingList'], 'subject'))
          for (let subject in groupBySubject) {
            groupBySubject[subject] = groupBy(groupBySubject[subject], 'durationInMinutes')
          }
          setSubjectCount(groupBySubject)
        }
        setLectureApiLoader(false);
      })
  }

  const fetchTests = async () => {
    setTestApiLoader(true);
    let count = {};
    const getCount = (item) => {
      if (count.hasOwnProperty(item?.testType)) {
        count[item?.testType]++
      } else {
        count[item?.testType] = 1;
      }
    }
    const res = await fetchAllPromisedData(`${endpoint?.academicPlanTests?.getAllTestScheduleByAcademicPlanId}/${id}`, true)
    if (res?.code === 200) {
      const temp = res?.data?.forEach(getCount)
    }
    setTestCountObj(count);
    setTestCount(res?.data?.length);
    setTestApiLoader(false);
  }

  useEffect(() => {
    if (id) {
      fetchData(id);
      fetchTests();
      fetchCourseLecture()
    }
  }, [id])

  const publishClickHandler = () => {
    setOpenDialog(true);
  }

  const updatePublishStatusHandler = async (displayStatus) => {
    setApiLoader(true);
    const request = {
      publishStatus: displayStatus === 'publish' ? 'P' : 'U'
    }

    const response = await fetchAllPostPromisedData(`${endpoint.viewAcademicPlan.updatePlan}/${id}`, request, 'put')
    if (response?.code === 200) {
      successToast(response?.message)
      await fetchData(id);
    }
    setApiLoader(false);
  }

  return (
    (apiLoader || planApiLoader || testApiLoader || lectureApiLoader) ? (
      <PageLoadingAnimation />
    ) : (
      userPermissions ? (
        <>
          <div className='remove-container-top-space' style={{ position: 'relative' }}>
            <HeaderComponent
              planData={planData}
              fetchPlanData={fetchData}
              setApiLoader={setPlanApiLoader}
              userPermissions={userPermissions}
            />
            <PlanView
              planData={planData}
              publishClickHandler={publishClickHandler}
              testCountObj={testCountObj}
              testCount={testCount}
              courses={courses}
              subjectLecturesCount={subjectLecturesCount}
            />
          </div>
          <PlanStatusChangeDialog open={openDialog} setOpen={setOpenDialog} planData={planData} onAgree={updatePublishStatusHandler} />
        </>
      ) : (
        <PermisionDenied />
      )
    )
  )
}

export default ViewAcademicPlan