import React from "react";
import './index.css'
// reactstrap components
import {
  Button,
  Modal,
} from "reactstrap";

class TemplateModal extends React.Component {
  state = {
    exampleModal: false,
    templateData:'',
    purpose:''
  };
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.templateModal !== prevProps.templateModal) {
        this.setState({
            exampleModal:this.props.templateModal,
            templateData:this.props.templateData,
            purpose:this.props.purpose
        });
    }
  }

  handleTemplateDelete = (data) => {
    this.props.sureToDeleteTemplate(data);
    this.setState({exampleModal:false});
    this.props.setTemplateModal(false);
  }

  getContent = (templateData) => {
    let newState = templateData;
    let firstCheck = newState.template.replace(/\\n/g, '<br />').replace(/(?:\r\n|\r|\n)/g, '<br>')
    let secondCheck='';
     newState.templateVariables.length > 0 ? newState.templateVariables.forEach((item) => { 
         if(item.variableMapping){
             secondCheck = firstCheck.replace(`{{${item.variableName}}}`,`<span id='underlined'>${item.variableMapping}</span>`)
             firstCheck = secondCheck
         }else{
             secondCheck = firstCheck
         }
     }):
     secondCheck = firstCheck
     return  {
        __html:`<p>${secondCheck}</p>`
     }
}
  

  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.exampleModal}
          // toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-header">
            <h2 className="modal-title heading" id="exampleModalLabel">
              {this.state.purpose === 'showPreviewOnly' && this.state.templateData.templateName}
              {this.props.templateName || ''}
            </h2>
            {/* <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {this.setState({exampleModal:false});this.props.setTemplateModal(false)}}
            >
              <span aria-hidden={true}>×</span>
            </button> */}
          </div>
          <div className={this.state.purpose === 'showPreviewOnly' ? 
              "modal-body modalTemplate":"modal-body"}>
              <p>
                {this.state.purpose === 'showPreviewOnly' && 
                 <div
                 id="preview"
                 className='preview break'
                 dangerouslySetInnerHTML={this.getContent(this.state.templateData)}
                />
                }
                {this.state.purpose === 'askToDeleteTemplate' && 'Are you sure you want to delete ?'}
              </p>
          </div>
          <div className="modal-footer">
          {this.state.purpose === 'showPreviewOnly' &&
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => {this.setState({exampleModal:false});this.props.setTemplateModal(false)}}
            >
              Close
            </Button>}
            {this.state.purpose === 'askToDeleteTemplate' &&
            <>
              <Button color="primary" type="button" onClick={() => this.handleTemplateDelete(this.state.templateData)}>
                Yes
              </Button>
              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={() => {this.setState({exampleModal:false});this.props.setTemplateModal(false)}}
              >
                Cancel
              </Button>
            </>}
          </div>
        </Modal>
      </>
    );
  }
}

export default TemplateModal;