import React, { useContext, useEffect, useState } from 'react'
import { PermissionContext, RolePermissions } from 'appContext';
import { endpoint, pages } from '../../common/constant';
import { ConfirmationDialog, PermisionDenied } from '../../common/commonComponents';
import { Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import CommonInput from '../../common/formFeilds/input/commonInput';
import moment from 'moment';
import { ButtonText } from '../../../../../variables/Buttons';
import CustomButton from '../../../../../components/CustomButton';
import { useHistory } from 'react-router';
import { BsCheck2 } from 'react-icons/bs';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import { customValidation } from '../../common/utils/methods/validations/validation';
import validationRules from './validation.json';
import CustomLoader from '../../common/commonComponents/Loader/loader';
import { getAcademicPhaseValue } from '../constant/batch-search';
import AddToFavourite from '../../homepage/favourites/AddToFavourite';
// import CustomClassSectionDropdown from './CustomClassSectionDropdown';

const CreateBatch = (props) => {
  const { id } = props.match.params;

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages['createBatch']['id']);
  const history = useHistory();
  const [isFetching, setIsFetching] = useState(false);

  const initialValues = {
    batchType: 'PRIMARY',
    term: [],
    classSection: '',
    academicCareer: '',
    academicGroup: '',
    courseId: [],
    region: '',
    businessArea: '',
    academicPhase: [],
    academicYear: '',
    schoolBoard: '',
    classType: '',
    medium: '',
    isEnabledMsTeamsSync: false,
    batchCapacity: '',
    batchEnrollmentStatus: 'OPEN',
    batchStatus: 'ACTIVE',
    batchStartDate: '',
    batchEndDate: '',
    batchClass: [],
  }

  // initial values for all the dropdowns
  const [values, setValues] = useState(initialValues)
  // select component array data
  const [data, setData] = useState({
    termData: [],
    classSectionData: [],
    classTypeData: [],
    schoolBoardData: [],
    mediumData: [],
    classData: [],
    academicCareerData: [],
    academicGroupData: [],
    courseIdData: [],
    academicYearData: [],
    academicPhaseData: [],
    regionData: [],
    businessAreaData: [],
  });

  // confirmation modal state
  const [batchId, setBatchId] = useState('');
  const [batchName, setBatchName] = useState('');
  const msg = `Your batch has been created successfully with Batch Name ${batchName}`;
  const [isOpen, setIsOpen] = useState(false);
  const headerMsg = 'Success';

  const getIdArray = (arr) => {
    const temp = arr.map((el) => {
      return el?.value;
    })
    return temp;
  }

  const getIdsObj = (arr) => {
    const temp = arr.map((el) => {

      return ({id: el?.value, dispValue:el?.label});
    })
    return temp;
  }

 const getKeyValObj = (obj, key)=>{
 return ({id:obj[key]?.value, dispValue:obj[key]?.label})
 }
  const saveHandler = async () => {
    // prepare request body from values then pass it in API call
    const _data = {
      // submit payload data
      batchType: values?.batchType,
      term: getIdsObj(values?.term),
      termDisplayValue: values?.term?.[0]?.label,
      classSection: getKeyValObj(values, 'classSection'),
      classSectionDisplayValue: values?.classSection?.label,
      academicCareer: getKeyValObj(values, 'academicCareer'),
      academicCareerDisplayValue: values?.academicCareer?.label,
      academicGroup: getKeyValObj(values, 'academicGroup'),
      academicGroupDisplayValue: values?.academicGroup?.label,
      courseId: getIdArray(values?.courseId),
      region: getKeyValObj(values, 'region'),
      businessArea: getKeyValObj(values, 'businessArea'),
      businessAreaDisplayValue: values?.businessArea?.label,
      academicPhase: getIdsObj(values?.academicPhase),
      academicPhaseDisplayValue: values?.academicPhase?.[0]?.label,
      academicYear: getKeyValObj(values, 'academicYear'),
      academicYearDisplayValue: values?.academicYear?.label,
      schoolBoard: getKeyValObj(values, 'schoolBoard'),
      classType: getKeyValObj(values, 'classType'),
      medium: getKeyValObj(values, 'medium'),
      isEnabledMsTeamsSync: values?.isEnabledMsTeamsSync,
      batchCapacity: values?.batchCapacity,
      batchEnrollmentStatus: values?.batchEnrollmentStatus,
      batchStatus: values?.batchStatus,
      batchStartDate: values?.batchStartDate,
      batchEndDate: values?.batchEndDate,
      batchClass: getIdsObj(values.batchClass, 'batchClass'),
    }
    // console.log(_data)
    // add validation here
    let isValid = true;
    isValid = customValidation(_data, validationRules);
    if (!isValid) return;

    setIsFetching(true);
    // await api call and get the response 
    const createRes = await fetchAllPostPromisedData(endpoint.createBatch.create, _data, 'post')
    if (createRes?.code === 200) {
      setIsFetching(false);
      setBatchName(createRes?.data?.batchName);  // to be done after api res
      setBatchId(createRes?.data?.id);
      setIsOpen(true);
    }
    else {
      setIsFetching(false);
    }
  }

  const reset = () => {
    setValues({ ...initialValues, schoolBoard: getDefaultSelect(data?.schoolBoardData, 'cbse', 'label', 'examBoard'), medium: getDefaultSelect(data?.mediumData, 'english', 'label') });
  }
  const getDefaultSelect = (dataArray, defaultValue, type, master = '') => {
    let obj;
    if (type === 'label') {
      if (master === 'examBoard') {
        obj = dataArray.find(o => o.label.toLowerCase().includes(defaultValue))
      } else {
        obj = dataArray.find(o => o.label.toLowerCase() === defaultValue);
      }
    } else if (type === 'id') {
      obj = dataArray.find(o => o.value == defaultValue);
    }
    return obj;
  }

  const getDefaultMultiSelect = (dataArray, defaultIdArray) => {
    const temp = [];
    defaultIdArray.map((dId) => {
      dataArray.map((o) => {
        if (o.value == dId) temp.push(o);
      })
    })
    return temp;
  }

  const fetchDetailsPost = async (url, requestObject, method, dispLabel) => {
    const businessAreaSearchedData = await fetchAllPostPromisedData(url, requestObject, method);
    if (businessAreaSearchedData?.code === 200 && Array.isArray(businessAreaSearchedData?.data)) {
      const result = businessAreaSearchedData.data?.map((o) => {
        return { label: o[dispLabel], value: o['id'] }
      })
      return result;
    }
    else return [];
  }

  const fetchDetailsCustom = async (url, dispLabel) => {
    const responseData = await fetchAllPromisedData(url, false);
    if (Array.isArray(responseData)) {
      if (dispLabel == 'courseId') {
        const result = responseData?.map((o) => {
          return { label: o[dispLabel], value: o[dispLabel] }
        })
        return result;
      }
      if (dispLabel == 'examBoardTypeDispValue') {
        const result = responseData?.map((o) => {
          return { label: `${o[dispLabel]} - ${o['examBoardTypeDesc']}`, value: o['id'] }
        }) 
        return result;
      }
      const result = responseData?.map((o) => {
        return { label: o[dispLabel], value: o['id'] }
      })
      // console.log(result)
      return result;
    }
    else return [];
  }

  const fetchData = async () => {
    setIsFetching(true);
    const p = await Promise.all([
      fetchDetailsCustom(endpoint.term.getAllActive, 'termDispValue'),
      fetchDetailsCustom(endpoint.academic_career.getAllActive, 'academicCareerDispValue'),
      fetchDetailsCustom(endpoint.academic_group.getAllActive, 'academicGroupDispValue'),
      fetchDetailsCustom(endpoint.region.getAllActive, 'regionDispValue'),
      fetchDetailsCustom(endpoint.academicPhase.getAllActive, 'academicPhaseDisplayValue'),
      fetchDetailsCustom(endpoint.academic_year.getAllActive, 'academicYearDisplayValue'),
      fetchDetailsCustom(endpoint.examBoardType.getAllActive, 'examBoardTypeDispValue'),
      fetchDetailsCustom(endpoint.classType.getAllActive, 'classTypeDispValue'),
      fetchDetailsCustom(endpoint.languageMaster.getActiveLanguageMaster, 'languageDispValue'),
      fetchDetailsCustom(`${endpoint.classSection.getAllClassSectionsByClassSectionType}${values?.batchType}`, 'classSectionDispValue'),
      fetchDetailsCustom(endpoint.classes.getAllActive, 'classDispValue')
    ])

    setData({
      ...data,
      termData: p[0],
      academicCareerData: p[1],
      academicGroupData: p[2],
      regionData: p[3],
      academicPhaseData: p[4],
      academicYearData: p[5],
      schoolBoardData: p[6],
      classTypeData: p[7],
      mediumData: p[8],
      classSectionData: p[9],
      classData: p[10]
    })

    setValues({ ...values, schoolBoard: getDefaultSelect(p[6], 'cbse', 'label', 'examBoard'), medium: getDefaultSelect(p[8], 'english', 'label') });
    setIsFetching(false);
  };

  const getBatchDetailsById = async () => {
    setIsFetching(true);
    const batchDetails = await fetchAllPromisedData(`${endpoint.viewEditBatch.getBatchById}/${id}`, true);
    if (batchDetails?.code === 200) {
      // fetch dependent dropdowns data
      const p = await Promise.all([
        fetchDetailsCustom(`${endpoint.classSection.getAllClassSectionsByClassSectionType}${batchDetails?.data?.batchType}`, 'classSectionDispValue'),
        fetchDetailsCustom(`${endpoint.course_details.getCourseIdByAcademicCareerAndAcademicGroup}academicCareerId=${batchDetails?.data?.academicCareer}&academicGroupId=${batchDetails?.data?.academicGroup}`, 'courseId'),
        fetchDetailsPost(endpoint.businessArea.getAllActiveBusinessAreaByRegions, { regionId: [batchDetails?.data?.region] }, 'post', 'businessAreaDispValue'),
        fetchDetailsCustom(endpoint.term.getAllActive, 'termDispValue'),
        fetchDetailsCustom(endpoint.academic_career.getAllActive, 'academicCareerDispValue'),
        fetchDetailsCustom(endpoint.academic_group.getAllActive, 'academicGroupDispValue'),
        fetchDetailsCustom(endpoint.region.getAllActive, 'regionDispValue'),
        fetchDetailsCustom(endpoint.academicPhase.getAllActive, 'academicPhaseDisplayValue'),
        fetchDetailsCustom(endpoint.academic_year.getAllActive, 'academicYearDisplayValue'),
        fetchDetailsCustom(endpoint.examBoardType.getAllActive, 'examBoardTypeDispValue'),
        fetchDetailsCustom(endpoint.classType.getAllActive, 'classTypeDispValue'),
        fetchDetailsCustom(endpoint.languageMaster.getActiveLanguageMaster, 'languageDispValue'),
        fetchDetailsCustom(endpoint.classes.getAllActive, 'classDispValue')
      ])

      setData({
        ...data,
        classSectionData: p[0], 
        courseIdData: p[1],
        businessAreaData: p[2],
        termData: p[3],
        academicCareerData: p[4],
        academicGroupData: p[5],
        regionData: p[6],
        academicPhaseData: p[7],
        academicYearData: p[8],
        schoolBoardData: p[9],
        classTypeData: p[10],
        mediumData: p[11],
        classData: p[12],
      });

      // set values
      setValues({
        ...values,
        batchType: batchDetails?.data?.batchType,
        term: getDefaultMultiSelect(p[3], batchDetails?.data?.term),
        classSection: getDefaultSelect(p[0], batchDetails?.data?.classSection, 'id'),
        classType: getDefaultSelect(p[10], batchDetails?.data?.classType, 'id'),
        schoolBoard: getDefaultSelect(p[9], batchDetails?.data?.schoolBoard, 'id'),
        medium: getDefaultSelect(p[11], batchDetails?.data?.medium, 'id'),
        academicCareer: getDefaultSelect(p[4], batchDetails?.data?.academicCareer, 'id'),
        academicGroup: getDefaultSelect(p[5], batchDetails?.data?.academicGroup, 'id'),
        courseId: getDefaultMultiSelect(p[1], batchDetails?.data?.courseId),
        academicYear: getDefaultSelect(p[8], batchDetails?.data?.academicYear, 'id'),
        academicPhase: getDefaultMultiSelect(p[7], batchDetails?.data?.academicPhase),
        region: getDefaultSelect(p[6], batchDetails?.data?.region, 'id'),
        businessArea: getDefaultSelect(p[2], batchDetails?.data?.businessArea, 'id'),
        batchStatus: batchDetails?.data?.batchStatus,
        batchEnrollmentStatus: batchDetails?.data?.batchEnrollmentStatus,
        isEnabledMsTeamsSync: batchDetails?.data?.isEnabledMsTeamsSync,
        batchCapacity: batchDetails?.data?.batchCapacity,
        batchClass: getDefaultMultiSelect(p[12], batchDetails?.data?.batchClass),
      });

      setIsFetching(false);
    }
    else {
      setIsFetching(false);
      setValues(initialValues);
    }
  }

  const checkValues = () => {
    return (!values?.term?.length || !values?.classSection || !values?.classType || !values?.schoolBoard || !values?.medium || !values?.batchClass?.length || !values?.academicCareer || !values?.academicGroup || !values?.courseId?.length || !values?.academicYear || !values?.academicPhase?.length || !values?.region || !values?.businessArea || !values?.batchCapacity || !values?.batchStartDate || !values?.batchEndDate);
  }

  // useEffect(() => {
  //   fetchData();
  // },[]);

  useEffect(() => {
    if (id) {
      getBatchDetailsById();
    } else {
      fetchData();
    }
  }, [id]);

  return (
    userPermissions ? (
      <Container fluid className='mt-3'>
        {isFetching ? (
          <Card>
            <CustomLoader apiLoader={isFetching} />
          </Card>
        ) : (
          <Row>
            <div className='col'>
              <ConfirmationDialog
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                type={'info'}
                msg={msg}
                headerMsg={headerMsg}
                popupSymbol={<BsCheck2 />}
                onAgree={() => history.push(`/admin/edp/batch/view/${batchId}`)}  // take to batch search page
              />
              <Card className='mb-2'>
                <CardHeader className='d-flex justify-content-between align-items-center'>
                  <h3 style={{ fontSize: '28px' }} className="mb-0 card-header-black">Batch Creation</h3>
                  <AddToFavourite screenName='Create batch' screenId={pages['createBatch']['id']} />
                </CardHeader>
                <CardBody>
                  <h3 className='mb-4' style={{ fontSize: '22px', fontWeight: 'bold' }}>Batch Details</h3>
                  <Row>
                    <Col className='batch-display-field'>
                      <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                        <Label className='mb-0 mr-2'>Batch Type : </Label>
                        <div
                          className='ml-4'
                          onChange={(e) => {
                            fetchDetailsCustom(`${endpoint.classSection.getAllClassSectionsByClassSectionType}${e.target.value}`, 'classSectionDispValue').then((pp) => {setData({...data, classSectionData: pp})});
                            setValues({
                              ...initialValues, batchType: e?.target?.value, schoolBoard: getDefaultSelect(data?.schoolBoardData, 'cbse', 'label', 'examBoard'), medium: getDefaultSelect(data?.mediumData, 'english', 'label')
                            })
                          }}
                        >
                          <Label className='mr-5' check>
                            <Input type='radio' value='PRIMARY' name='batchType' checked={values?.batchType === 'PRIMARY'} />
                            {'Primary'}
                          </Label>
                          <Label check>
                            <Input type='radio' value='SECONDARY' name='batchType' checked={values?.batchType === 'SECONDARY'} />
                            {'Secondary'}
                          </Label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='' md='4'>
                      <CommonInput
                        label={'Term'}
                        type={values?.batchType === 'SECONDARY' ? 'select' : 'select'}
                        mandatory={true}
                        placeHolder={'Select Term'}
                        defaultValue={values?.term}
                        data={data?.termData}
                        onChange={(e) => {
                          let termConditional = values.batchType === 'SECONDARY' ? [e] : [e];
                          setValues({
                            ...values, term: termConditional, classSection: '', classType: '', schoolBoard: getDefaultSelect(data?.schoolBoardData, 'cbse', 'label', 'examBoard'), medium: getDefaultSelect(data?.mediumData, 'english', 'label'), batchClass: [], academicCareer: '', academicGroup: '', courseId: [], academicYear: '', academicPhase: [], region: '', businessArea: ''
                          })
                        }}
                      />
                    </Col>
                    <Col className='' md='4'>
                      <CommonInput
                        label={'Class Section'}
                        type={'select'}
                        mandatory={true}
                        placeHolder={'Select Class Section'}
                        defaultValue={values?.classSection}
                        data={data?.classSectionData}
                        isDisabled={!values?.term?.length}
                        onChange={(e) => {
                          setValues({ 
                            ...values, classSection: e, classType: '', schoolBoard: getDefaultSelect(data?.schoolBoardData, 'cbse', 'label', 'examBoard'), medium: getDefaultSelect(data?.mediumData, 'english', 'label'), batchClass: [], academicCareer: '', academicGroup: '', courseId: [], academicYear: '', academicPhase: [], region: '', businessArea: '' 
                          })
                        }}
                      />
                    </Col>
                    {/* <Col className='' md='4'>
                      <CustomClassSectionDropdown
                        batchType={values?.batchType}
                        label={'Class Section'}
                        mandatory={true}
                        placeHolder={'Select Class Section'}
                        defaultValue={values?.classSection}
                        isDisabled={!values?.term?.length}
                        onChange={(e) => {
                          setValues({
                            ...values, classSection: e, classType: '', schoolBoard: getDefaultSelect(data?.schoolBoardData, 'cbse', 'label', 'examBoard'), medium: getDefaultSelect(data?.mediumData, 'english', 'label'), academicCareer: '', academicGroup: '', courseId: [], academicYear: '', academicPhase: [], region: '', businessArea: ''
                          })
                        }}
                      />
                    </Col> */}
                    <Col className='' md='4'>
                      <CommonInput
                        label={'Class Type'}
                        type={'select'}
                        mandatory={true}
                        placeHolder={'Select Class Type'}
                        defaultValue={values?.classType}
                        data={data?.classTypeData}
                        isDisabled={!values?.classSection}
                        onChange={(e) => setValues({
                          ...values, classType: e, schoolBoard: getDefaultSelect(data?.schoolBoardData, 'cbse', 'label', 'examBoard'), medium: getDefaultSelect(data?.mediumData, 'english', 'label'), batchClass: [], academicCareer: '', academicGroup: '', courseId: [], academicYear: '', academicPhase: [], region: '', businessArea: ''
                        })}
                      />
                    </Col>
                    <Col className='' md='4'>
                      <CommonInput
                        label={'School Board'}
                        type={'select'}
                        mandatory={true}
                        placeHolder={'Select School Board'}
                        defaultValue={values?.schoolBoard}
                        data={data?.schoolBoardData}
                        isDisabled={!values?.classType}
                        onChange={(e) => setValues({
                          ...values, schoolBoard: e, medium: getDefaultSelect(data?.mediumData, 'english', 'label'), batchClass: [],
                          academicCareer: '', academicGroup: '', courseId: [], academicYear: '', academicPhase: [], region: '', businessArea: ''
                        })}
                      />
                    </Col>
                    <Col className='' md='4'>
                      <CommonInput
                        label={'Medium'}
                        type={'select'}
                        mandatory={true}
                        placeHolder={'Select Medium'}
                        defaultValue={values?.medium}
                        data={data?.mediumData}
                        isDisabled={!values?.schoolBoard}
                        onChange={(e) => setValues({
                          ...values, medium: e, batchClass: [], academicCareer: '', academicGroup: '', courseId: [], academicYear: '', academicPhase: [], region: '', businessArea: ''
                        })}
                      />
                    </Col>
                    <Col className='' md='4'>
                      <CommonInput
                        label={'Batch Class'}
                        type={'select'}
                        mandatory={true}
                        placeHolder={'Select Batch Class'}
                        defaultValue={values?.batchClass}
                        data={data?.classData}
                        isDisabled={!values?.medium}
                        onChange={(e) => setValues({
                          ...values, batchClass: [e], academicCareer: '', academicGroup: '', courseId: [], academicYear: '', academicPhase: [], region: '', businessArea: ''
                        })}
                      />
                    </Col>
                  </Row>
                  <hr />

                  <h3 className='my-4' style={{ fontSize: '22px', fontWeight: 'bold' }}>Academic Details</h3>
                  <Row>
                    <Col className='' md='4'>
                      <CommonInput
                        label={'Academic Career'}
                        type={'select'}
                        mandatory={true}
                        placeHolder={'Select Academic Career'}
                        defaultValue={values?.academicCareer}
                        data={data?.academicCareerData}
                        isDisabled={!values?.batchClass?.length}
                        onChange={(e) => {
                          // if(values?.academicGroup) fetchDetailsCustom(`${endpoint.course_details.getCourseIdByAcademicCareerAndAcademicGroup}academicCareerId=${e?.value}&academicGroupId=${values?.academicGroup?.value}`, 'courseId').then((pp) => {setData({...data, courseIdData: pp})});
                          setValues({
                            ...values, academicCareer: e, academicGroup: '', courseId: [], academicYear: '', academicPhase: [], region: '', businessArea: ''
                          })
                        }}
                      />
                    </Col>
                    <Col className='' md='4'>
                      <CommonInput
                        label={'Academic Group'}
                        type={'select'}
                        mandatory={true}
                        placeHolder={'Select Academic Group'}
                        defaultValue={values?.academicGroup}
                        data={data?.academicGroupData}
                        isDisabled={!values?.academicCareer}
                        onChange={(e) => {
                          setData({ ...data, courseIdData: [] })
                          fetchDetailsCustom(`${endpoint.course_details.getCourseIdByAcademicCareerAndAcademicGroup}academicCareerId=${values?.academicCareer?.value}&academicGroupId=${e?.value}`, 'courseId').then((pp) => { setData({ ...data, courseIdData: pp }) });
                          setValues({
                            ...values, academicGroup: e, courseId: [], academicYear: '', academicPhase: [], region: '', businessArea: ''
                          })
                        }}
                      />
                    </Col>
                    <Col className='' md='4'>
                      <CommonInput
                        label={'Course ID'}
                        type={!values.academicGroup ? 'select' : values?.batchType === 'SECONDARY' ? 'select' : 'select'}
                        mandatory={true}
                        placeHolder={'Select Course ID'}
                        defaultValue={values?.courseId}
                        data={data?.courseIdData}
                        isDisabled={!values?.academicGroup}
                        onChange={(e) => {
                          let courseIdConditional = values.batchType === 'SECONDARY' ? [e] : [e];
                          setValues({
                            ...values, courseId: courseIdConditional, academicYear: '', academicPhase: [], region: '', businessArea: ''
                          });
                        }}
                      />
                    </Col>
                    <Col className='' md='4'>
                      <CommonInput
                        label={'Academic Year'}
                        type={'select'}
                        mandatory={true}
                        placeHolder={'Select Academic Year'}
                        defaultValue={values?.academicYear}
                        data={data?.academicYearData}
                        isDisabled={!values?.courseId?.length}
                        onChange={(e) => setValues({
                          ...values, academicYear: e, academicPhase: [], region: '', businessArea: ''
                        })}
                      />
                    </Col>
                    <Col className='' md='4'>
                      <CommonInput
                        label={'Academic Phase'}
                        type={!values.academicYear ? 'select' : values.batchType === 'SECONDARY' ? 'select' : 'select'}
                        mandatory={true}
                        placeHolder={'Select Academic Phase'}
                        defaultValue={values?.academicPhase}
                        data={getAcademicPhaseValue(data?.academicPhaseData, values.batchType === 'SECONDARY')}
                        isDisabled={!values?.academicYear}
                        onChange={(e) => {
                          let academicPhaseConditional = values.batchType === 'SECONDARY' ? [e] : [e];
                          setValues({
                            ...values, academicPhase: academicPhaseConditional, region: '', businessArea: ''
                          })
                        }}
                      />
                    </Col>
                  </Row>
                  <hr />
                 {console.log(data?.academicPhaseData)}
                  <h3 className='my-4' style={{ fontSize: '22px', fontWeight: 'bold' }}>Branch Details</h3>
                  <Row>
                    <Col className='' md='4'>
                      <CommonInput
                        label={'Region'}
                        type={'select'}
                        mandatory={true}
                        placeHolder={'Select Region'}
                        defaultValue={values?.region}
                        data={data?.regionData}
                        isDisabled={!values?.academicPhase?.length}
                        onChange={(e) => {
                          if (e?.value) fetchDetailsPost(endpoint.businessArea.getAllActiveBusinessAreaByRegions, { regionId: [e?.value] }, 'post', 'businessAreaDispValue').then((pp) => { setData({ ...data, businessAreaData: pp }) });
                          setValues({
                            ...values, region: e, businessArea: ''
                          })
                        }}
                      />
                    </Col>
                    <Col className='' md='4'>
                      <CommonInput
                        label={'Business Area'}
                        type={'select'}
                        mandatory={true}
                        placeHolder={'Select Business Area'}
                        defaultValue={values?.businessArea}
                        data={data?.businessAreaData}
                        isDisabled={!values?.region}
                        onChange={(e) => {
                          setValues({ ...values, businessArea: e })
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h3 className='mb-4' style={{ fontSize: '22px', fontWeight: 'bold' }}>Operational Details</h3>
                  <Row>
                    <Col md='4' className='batch-display-field'>
                      <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                        <Label className='mb-0 mr-2'>Batch Status : </Label>
                        <span>
                          <label className="custom-toggle mx-auto ml-2">
                            <input checked={values?.batchStatus === "ACTIVE" ? true : false} type="checkbox" onChange={(e) => { let n = "ACTIVE"; if (!e.target.checked) { n = "INACTIVE" } setValues({ ...values, batchStatus: n }) }} />
                            <span style={{ width: "72px" }} className="custom-toggle-slider rounded-circle activeToggle" data-label-off="Inactive" data-label-on="Active" />
                          </label>
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md='4' className='batch-display-field'>
                      <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                        <Label className='mb-0 mr-2'>Enrollment Status : </Label>
                        <div className='ml-4' onChange={(e) => { setValues({ ...values, batchEnrollmentStatus: e.target.value }) }}>
                          <Label className='mr-4' check>
                            <Input type='radio' value='OPEN' name='enrollmentStatus' checked={values?.batchEnrollmentStatus === 'OPEN'} />
                            {'Open'}
                          </Label>
                          <Label check>
                            <Input type='radio' value='CLOSED' name='enrollmentStatus' checked={values?.batchEnrollmentStatus === 'CLOSED'} />
                            {'Closed'}
                          </Label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className='batch-display-field' md='4'>
                      <FormGroup className='' check>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={values.isEnabledMsTeamsSync}
                            onChange={(e) => setValues({ ...values, isEnabledMsTeamsSync: e?.target?.checked })}
                          />{' '}Sync with MS Teams
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col className='' md='4'>
                      <CommonInput
                        label={'Batch Capacity'}
                        type={'number'}
                        mandatory={true}
                        placeHolder={'Ex: 50, 100, 200'}
                        defaultValue={values?.batchCapacity}
                        onChange={(e) => { setValues({ ...values, batchCapacity: e }) }}
                      />
                    </Col>
                    <Col className='' md='4'>
                      <CommonInput
                        label={'Start Date'}
                        type={'datePicker'}
                        mandatory={true}
                        minDate={moment(new Date())}
                        defaultValue={values?.batchStartDate}
                        onChange={(date) => {
                          setValues({ ...values, batchStartDate: date })
                        }}
                      />
                    </Col>
                    <Col className='' md='4'>
                      <CommonInput
                        label={'End Date'}
                        type={'datePicker'}
                        mandatory={true}
                        isDisabled={!values?.batchStartDate}
                        minDate={moment(new Date(values?.batchStartDate)).add(1, "days")}
                        defaultValue={values?.batchEndDate}
                        onChange={(date) => {
                          setValues({ ...values, batchEndDate: date })
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className={`d-flex pr-3 justify-content-end`}>
                    <CustomButton
                      className={'mr-3'}
                      content={ButtonText.CREATEBATCH.UPDATE}
                      disabled={checkValues()}
                      permissionType={'C,U'}
                      permissionSet={userPermissions}
                      onClick={saveHandler}
                    />
                    {!id && <CustomButton
                      type="reset"
                      className={'mx-1'}
                      content={ButtonText.CREATEBATCH.CLEAR}
                      permissionType={'R'}
                      permissionSet={userPermissions}
                      icon={true}
                      onClick={reset}
                    />}
                    {id && <CustomButton
                      type="close"
                      className={'mx-1'}
                      content={ButtonText.CREATEBATCH.CLOSE}
                      permissionType={'R'}
                      permissionSet={userPermissions}
                      icon={true}
                      onClick={() => history.goBack()}
                    />}
                  </Row>

                </CardBody>
              </Card>
            </div>
          </Row>
        )}
      </Container>
    ) : (
      <PermisionDenied />
    )
  )
}

export default CreateBatch;