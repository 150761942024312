import React, {Fragment, useEffect, useState} from "react";
import {Button, Card, CardBody, CardHeader, Container} from "reactstrap";
import {useHistory} from "react-router-dom";
import ExamIdTable from "./examIdTable";
import ExamIdSearchForm from "./examIdSearchForm";
import {fetchDetails} from "../../common/utils/methods/commonMethods/utilityMethod";
import {masterServiceBaseUrl,examControllerServiceBaseUrl,failureToast, getAPI, putpost} from "../../../../../services/http";
import {GetPagination} from "../../common/commonComponents/pagination/pagination";
const ExamIdSearch =(props)=>{
    let history = useHistory();
    const {label,placeHolder,searchHeaderTitle,message,searchHeaderList,button} = require('./properties/index.json');
    const [academicCareer, setAcademicCareer] = useState([]);
    const [academicGroup, setAcademicGroup] = useState([]);
    const [term,setTerm] = useState([]);
    const [examType,setExamType] = useState([]);
    const [examIdData,setExamIdData] = useState([]);
    const [allExamIdData, setAllExamIdData] = useState([]);
    const [searchData,setSearchData] = useState({
        examId: null,
        academicCareerId: null,
        termId: null,
        academicGroupId: null,
        examTypeId: null
    });
    const [pagination, setPagination] = useState({
        currentPage: 1,
        nextPage: null,
        previousPage: null,
        totalPage: null
    })
    const pageSize=10;
    const [apiLoader,setApiLoader] = useState(true);
    const resetFields = () => {
        setSearchData({
            examId: null,
            academicCareerId: null,
            termId: null,
            academicGroupId: null,
            examTypeId: null
        })
        setExamIdData([]);
    }
    const paginationUpdate =(data) =>{
        let n = pagination;
        if (data.length > pageSize) {
            n["nextPage"] = n.currentPage + 1;
        }
        if (n.currentPage > 1) {
            n.previousPage = n.currentPage - 1;
        }
        n["totalPage"] = Math.floor(data.length / pageSize);
        if (data.length % pageSize !== 0) {
            n["totalPage"] += 1;
        }
        setPagination(n);
        setExamIdData(
            data.slice(
                pagination.currentPage * pageSize - pageSize,
                pagination.currentPage * pageSize
            ) || []
        );
    }
    const searchHandler = () => {
        if (!searchData['academicCareerId']) {
            failureToast(message.failure.academicCareer);
        } else if (!searchData['termId']) {
            failureToast(message.failure.term);
        } else {
            setExamIdData([]);
            let payload = {
                examId: searchData.examId? searchData.examId:null,
                academicCareerId: searchData.academicCareerId? searchData.academicCareerId:null,
                examTypeId: searchData.examTypeId?searchData.examTypeId:null,
                academicGroupId: searchData.academicGroupId?searchData.academicGroupId:null,
                termId: searchData.termId?searchData.termId:null
            }
            putpost(examControllerServiceBaseUrl+'/exam/search',(data)=>{
                setAllExamIdData([...data.data]);
                paginationUpdate([...data.data]);
            },(data)=>{
                failureToast(data.message);
            },payload,'POST')
        }
    }
    useEffect(() => {
        fetchDetails(masterServiceBaseUrl + '/academicGroup/getAllActiveAcademicGroup', setAcademicGroup, 'academicGroup', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + '/academicCareer/getAllActiveAcademicCareer', setAcademicCareer, 'academicCareer', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + '/term/getAllActiveTerm', setTerm, 'term', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + '/testType/getAllTestType', setExamType, 'testType', 'reactSelect');
    },[]);
    useEffect(() => {
        // console.log('Exam Type----->',examIdData);
    },[examIdData])
    return (
            <Fragment>
                <Container fluid={ true } className="p-4">
                    <Card>
                        <CardHeader>
                            <h2 className="mb-0 floatLeft">{searchHeaderTitle}</h2>
                            <Button color="info"  size="sm" className="floatRight mx-1"
                                    onClick={()=>history.push(`/admin/examIdGenerationForm/new`)}
                            >
                                {button.addNewExamId}
                            </Button>
                        </CardHeader>
                        <CardBody>
                            <ExamIdSearchForm searchData={searchData} setSearchData={setSearchData} academicCareer={academicCareer} academicGroup={academicGroup} term={term} examType={examType} label={label} placeholder={placeHolder} message={message} resetFields={()=>resetFields()} searchHandler={()=>searchHandler()} />
                        </CardBody>
                    </Card>
                    {
                        examIdData && examIdData.length>0
                            ?
                            (<ExamIdTable examType={examType} examIdData={examIdData} searchHeaderList={searchHeaderList} />)
                            :
                            null
                    }
                    {
                        pagination.totalPage>1 ?
                            <GetPagination
                                setclassArray={setExamIdData}
                                pagination={pagination}
                                setpagination={setPagination}
                                pageSize={pageSize}
                                state={allExamIdData}
                            />
                        :
                            null
                    }
                </Container>
            </Fragment>
    )
}
export default ExamIdSearch;