import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom"
import Stepper from 'react-stepper-horizontal'
import { Row } from 'reactstrap';
import CopyToClipboard from "react-copy-to-clipboard";
import { Dialog, CustomUpload, CustomContainer, GetTemplate, CustomCard, Accordion } from "../../../../../common/commonComponents";
import {
  putpost,
} from "services/http";
import {
  successToast,
  failureToast,
} from '../../../../../common/utils/methods/toasterFunctions/function';
import { getUniqueKey, getRequiredSign } from "../../../../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint, constants, commonMessages } from "../../../../../common/constant";
// import style from "./bluckUpload.module.scss";
import { PermissionContext, RolePermissions } from "appContext";
import { PermisionDenied, ConfirmationDialog } from "../../../../../common/commonComponents";
import { pages, permissionType } from "../../../../../common/constant";
import { isPermissionExists } from "../../../../../common/utils/methods/commonMethods/utilityMethod";
import CustomButton from "components/CustomButton";
import { ButtonText } from "variables/Buttons";



import Description from "./description";
import PricingTemplate from "./pricingTemplate";
import SupportingDocument from "./supportingDoc";
import documentTypeResponse from './documentTypeResponse'
import { fetchAllPromisedData } from "../../../../../common/utils/methods/commonMethods/utilityMethod";


const newRecord = {
  documentTypeDispValue: "",
  __isNew: true
}

const PricingUpload = (props) => {

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["processInstanceSearch"]['id']);

  //stepper states
  const [activeStep, setActiveStep] = useState(0);
  const [description, setDescription] = useState('');
  const [isSupportingDocValid, setSupportingDocValid] = useState(false);
  const [isUploading, setISUploading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  const [records, setRecords] = useState([]);
  const [allRecords, setAllRecords] = useState([])
  const [isSaveVisible, setIsSaveVisible] = useState(false);
  const [finalData, setFinalData] = useState({});

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isSupportingDocEditable,setSupportingDocEditable] = useState(true)


  //for supporting document
  const getFilteredListed = (doc) => {
    const mandDoc = [];
    const nonMandDoc = [];
    doc.forEach(element => {
      const formats = element?.documentFormatMaster.map(item => { return item.documentFormatDispValue }) ?? []
      if (element?.documentFormatMaster?.length !== 0) {
        if (element?.documentProcessTag?.mandatory) {
          mandDoc.push({
            // id: element?.documentTypeMaster?.id,
            documentTypeDispValue: element?.documentTypeMaster?.documentTypeDispValue,
            documentTypeDesc: element?.documentTypeMaster?.documentTypeDesc,
            documentFormats: formats.join(),
            documentSize: element?.documentTypeMaster?.documentSize,
            isMandatory: element?.documentProcessTag?.mandatory
          })
        } else {
          nonMandDoc.push({
            label: element?.documentTypeMaster?.documentTypeDispValue,
            value: element?.documentTypeMaster?.id,
            // id: element?.documentTypeMaster?.id,
            documentTypeDesc: element?.documentTypeMaster?.documentTypeDesc,
            documentTypeDispValue: element?.documentTypeMaster?.documentTypeDispValue,
            documentFormats: formats.join(),
            documentSize: element?.documentTypeMaster?.documentSize,
            isMandatory: element?.documentProcessTag?.mandatory
          })
        }
      }
    });
    setRecords(mandDoc)
    setAllRecords(nonMandDoc)
    setIsFetching(false)
  }

  const getDocuments = async () => {
    const docs = await fetchAllPromisedData(`${endpoint.documentProcessTag.getAllDocumentByProcessName}/${constants.PRICING_SERVICE_SUPPORTING_DOC_PROCESS_TAG}`);
    if (Array.isArray(docs)) {
      getFilteredListed(docs);
    } else {
      setRecords([])
      setAllRecords([])
      setIsFetching(false)
    }
  }


  useEffect(() => {
    getDocuments()
  }, [])


  const newDocument = () => {
    setRecords([...records, newRecord]);
    setIsSaveVisible(true)
  }

  //supporting Document
  const addDataBeforeNext = (key, data) => {
    // alert(key,data)
    setFinalData({ ...finalData, [key]: data })
    toNextStep()
  }



  const toNextStep = (id) => {
    let isValid = true;
    if (activeStep == 0) {
      if (!description || !description.toString().trim()) {
        failureToast("Please enter description")
        isValid = false;
      }
    }

    if (!isValid) {
      return
    }

    setActiveStep(activeStep + 1)
  }

  return (
    isPermissionExists(userPermissions, permissionType.CREATE) ?
      <CustomContainer backIcon={true} goBack={() => props.history.goBack()} title={"Course Fee Upload"}>
        <Accordion title="Description" isFormOpen={true}>
          <Description isUploading={isUploading} setDescription={setDescription} description={description} userPermissions={userPermissions} />

          {
            activeStep == 0 && <Row className="justify-content-end align-items-center mb-3">
              <CustomButton
                content={ButtonText.UPLOADPAGE.NEXT}
                type="submit"
                className={'reset-button mx-1'}
                permissionType={'C'}
                // icon={true}
                onClick={() => addDataBeforeNext("description", description)}
                permissionSet={userPermissions}
              // disabled={!isFileValid || isUploading}
              />
              {/* <Button color={isFileValid ? "info" : "secondary"} disabled={!isFileValid || isUploading} onClick={getFilePath}>
            Submit
          </Button> */}
              <CustomButton
                type="close"
                className={'reset-button mx-1'}
                content={ButtonText.UPLOADPAGE.CLOSE}
                permissionType={'C'}
                // icon={true}
                onClick={description ? () => setIsConfirmationOpen(true) : () => props.history.push("/admin/processInstanceSearch")}
                permissionSet={userPermissions}
                disabled={isUploading}
              />
            </Row>
          }
        </Accordion>

        {
          activeStep > 0 &&
          <Accordion
            title="Supporting Document"
            isFormOpen={activeStep === 1}
            rightSideInfo={
              isSupportingDocEditable ?
              <CustomButton
                content={ButtonText.UPLOADPAGE.ADD}
                // className={'floatRight mx-1' + (!allRecords.length || isSaveVisible ? ' btn-dark' : null)}
                color={!allRecords.length ? 'danger' : 'info'}
                permissionType={'C'}
                icon={true}
                forTable={true}
                onClick={newDocument}
                permissionSet={userPermissions}
                disabled={isUploading || isSaveVisible || !allRecords.length}
              /> :
              <CustomButton
                content={'Edit'}
                // className={'floatRight mx-1' + (!allRecords.length || isSaveVisible ? ' btn-dark' : null)}
                color={!allRecords.length ? 'danger' : 'info'}
                permissionType={'C'}
                // icon={true}
                forTable={true}
                onClick={()=>{setSupportingDocEditable(true);setActiveStep(1)}}
                permissionSet={userPermissions}
                // disabled={isUploading || isSaveVisible || !allRecords.length}
            />

            }
          >
            <SupportingDocument
              userPermissions={userPermissions}
              addDataBeforeNext={addDataBeforeNext}
              activeStep={activeStep}
              goBack={() => { props.history.push("/admin/processInstanceSearch") }}
              description={description}
              records={records}
              allRecords={allRecords}
              setRecords={setRecords}
              setAllRecords={setAllRecords}
              isSaveVisible={isSaveVisible}
              setIsSaveVisible={setIsSaveVisible}
              isFetching={isFetching}
              isUploading={isUploading}
              setISUploading={setISUploading}
              setIsConfirmationOpen={setIsConfirmationOpen}
              isSupportingDocEditable={isSupportingDocEditable}
              setSupportingDocEditable={setSupportingDocEditable}
            />
          </Accordion>
        }

        {
          activeStep === 2 && !isSupportingDocEditable &&  <Accordion title="Pricing Template Upload" isFormOpen={true}>
            <PricingTemplate
              userPermissions={userPermissions}
              goBack={() => { props.history.push("/admin/processInstanceSearch") }}
              setIsConfirmationOpen={setIsConfirmationOpen}
              description={description}
              finalData={finalData}
              isUploading={isUploading}
              setISUploading={setISUploading}
              history={props.history}
            />
          </Accordion>
        }
        <ConfirmationDialog isOpen={isConfirmationOpen} onAgree={() => props.history.push("/admin/processInstanceSearch")} setIsOpen={setIsConfirmationOpen} msg={commonMessages.pricingUploadCloseConfirmation} />
      </CustomContainer> : <PermisionDenied />
  );
};

export default withRouter(PricingUpload);