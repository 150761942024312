import { v4 as uuidv4 } from "uuid";
import React, { useContext, useEffect, useState } from "react";

import { PermissionContext, RolePermissions } from "appContext";

import { Col, Modal, ModalBody, Row } from "reactstrap";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ReactComponent as CloseIcon } from "assets/img/svg/CloseIcon.svg";
import { ReactComponent as ActiveControl } from "assets/img/svg/ActiveControl.svg";
import { ReactComponent as InactiveControl } from "assets/img/svg/InactiveControl.svg";
import { ReactComponent as EyeIcon } from "assets/img/svg/eyeIcon.svg";
import moment from "moment";
import { timepickerStyle } from "./AddLectureModalForm";
import CustomDatePickerV2 from "views/pages/manage/common/commonComponents/CustomDatePicker-V2";
import AAutoComplete from "../../../common/form-fields-mui/AAutoComplete";
import AButton from "../../../common/form-fields-mui/AButton";
import UploadForTimetable from "../../../common/commonComponents/CustomUpload/UploadForTimetable";
import { savePTMValidator } from "./EditLectureForm";
import ALoader from "views/pages/manage/common/a-loader";
import {
  checkDuplicateLecture,
  getDuplicateErrorMessage,
  getFacultyDropdown,
  getUniqueFaculty,
  maxDate31Days,
  uuidValidateV4,
} from "../helper";
import { useHistory } from "react-router";
import { Tooltip } from "@mui/material";
import { failureToast } from "../../../common/utils/methods/toasterFunctions/function";
import PTMUpdatePsid from "./selectLecture/ptm/PTMUpdatePsid";
import { useFetchMappedStudent } from "../hooks";
import { pages } from "../../../common/constant";
import { checkPermission } from "../../../common/utils/methods/commonMethods/utilityMethod";

const createFormObject = (rowData) => {
  const formObj = {
    faculty: { label: rowData.facultyName, value: rowData.facultyId },
    room: { label: rowData.roomName, value: rowData.roomId },
    date: rowData.lectureDate || rowData?.date,
    startTime: rowData?.startTime,
    endTime: rowData?.endTime,
  };
  return formObj;
};

const resetFormFields = {
  lecture: "",
  faculty: "",
  date: "",
  startTime: "",
  endTime: "",
  room: "",
  fileUrl: "",
};

const getMsTeamFlag = (ptmRowData) => {
  if (ptmRowData?.msTeam?.value) {
    return ptmRowData?.msTeam?.value === "Yes";
  }
  return true;
};
const AddPTMModalForm = ({
  type,
  isOpen,
  onClose,
  batchDetails,
  roomsDropdown,
  openMode,
  lectures,
  setLectures,
  setIsNeedToPublish,
  subjectFacultyDropdown,
  isEnabledMsTeamsSync,
  ptmRowData,
  saveOnEdit,
  academicCareer,
  formData,
  updateLectures = () => {},
}) => {
  const FormField = {
    lecture: "",
    faculty: formData?.faculty
      ? {
          label: formData?.faculty.name,
          value: "" + formData?.faculty.value,
        }
      : "",
    date: formData?.date || "",
    startTime: formData?.startTime || "",
    endTime: formData?.endTime || "",
    room: formData?.room || "",
    fileUrl: "",
  };

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(
    permissions,
    pages["searchBatch"]["id"]
  );
  const isCreatePermission = checkPermission(userPermissions, "C");
  const history = useHistory();
  const [selectedStudents, setSelectedStudents] = useState(
    formData?.bachTimeTablePsidMapping || []
  );
  const [mappedStudentList, isFetching] = useFetchMappedStudent(ptmRowData?.id);
  const [file, setFile] = useState(null);
  const [inputForm, setInputForm] = useState(FormField);
  const [toggleState, setToggleState] = useState(getMsTeamFlag(ptmRowData));
  const [s3Key, setS3Key] = useState(null);

  const inputFormHandler = (value, key) => {
    setInputForm((prev) => ({ ...prev, [key]: value }));
  };

  const allFacultyDropdown = getUniqueFaculty(
    getFacultyDropdown(subjectFacultyDropdown)
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (ptmRowData) {
      setInputForm(createFormObject(ptmRowData));
      if (ptmRowData?.documentS3Key) {
        setFile({ fileName: "PTM batch", fileUrl: ptmRowData?.documentS3Key });
        setS3Key(ptmRowData?.documentS3Key);
      }
    }
  }, [ptmRowData]);

  const deleteFileHandler = () => {
    setS3Key("");
    setFile("");
  };

  const editEvent = () => {
    const editedRow = lectures[formData?.selectedIndex];

    updateLectures(
      formData?.selectedIndex,
      {
        ...editedRow,
        ...inputForm,

        roomName: inputForm?.room?.label,
        roomId: inputForm?.room?.value,
        facultyName: inputForm?.faculty?.label,
        facultyId: inputForm?.faculty?.value || 0,
        msTeam: {
          value: toggleState ? "Yes" : "No",
          label: toggleState ? "Yes" : "No",
        },
        bachTimeTablePsidMapping: selectedStudents,
        isItemEdited: true,
      },
      true
    );

    if (openMode === "EDIT") {
      setIsNeedToPublish(true);
    }
    onCloseReset();
  };

  const getLectureRequest = (id) => {
    const lecture = {
      ...inputForm,
      timeTableClassType: type === "PTM" ? "PTM" : "Orientation",
      classType: type === "PTM" ? "PTM" : "Orientation",
      subject: null,
      roomName: inputForm?.room?.label,
      roomId: inputForm?.room?.value,
      lectureId: 0,
      lectureName: null,
      lectureCode: null,
      facultyName: inputForm?.faculty?.label,
      facultyId: inputForm?.faculty?.value || 0,
      msTeam: {
        value: toggleState ? "Yes" : "No",
        label: toggleState ? "Yes" : "No",
      },
      isEdit: false,
      isNew: true,
      id: id || uuidv4(),
      isTimeTableEvent: true,
      status: "ACTIVE",
      documentS3Key: s3Key,
      bachTimeTablePsidMapping: selectedStudents,
    };
    return lecture;
  };
  const saveEvent = () => {
    if (!savePTMValidator(inputForm)) return false;
    const duplicateItem = checkDuplicateLecture(
      lectures,
      inputForm,
      formData?.selectedIndex || -1
    );

    if (uuidValidateV4(formData?.id)) {
      // if event made via calendar-> modify lectures array and not call API
      editEvent();
      return;
    } else if (formData?.id && !uuidValidateV4(formData?.id)) {
      //if event is already published & modified -> call savePTM()
      savePTM(formData.selectedIndex, formData.id);
      return;
    }

    if (duplicateItem) {
      failureToast(getDuplicateErrorMessage(duplicateItem?.classType));
      return;
    }
    if (academicCareer?.dispValue === "ICON" && !inputForm?.faculty)
      return failureToast("Faculty is required for ICON batch");

    const lecture = getLectureRequest();
    setLectures((lectures) => [...lectures, lecture]);
    if (openMode === "EDIT") {
      setIsNeedToPublish(true);
    }
    onCloseReset();
  };

  const savePTM = (index = -1, id = ptmRowData?.id) => {
    // if (!savePTMValidator(inputForm)) return false;
    // const duplicateItem = checkDuplicateLecture(lectures, inputForm);
    // if (duplicateItem) {
    //   failureToast(getDuplicateErrorMessage(duplicateItem?.classType));
    //   return;
    // }
    const selectedIndex =
      index !== -1 ? index : ptmRowData?.index || ptmRowData?.rowIndex;
    const lecture = getLectureRequest(id);
    const tempLectures = [...lectures];
    tempLectures[selectedIndex] = {
      ...lecture,
      id,
    };
    setLectures(tempLectures);
    if (id)
      saveOnEdit(
        [{ ...lecture, id, isItemEdited: true }],
        true,
        false,
        undefined,
        []
      );
    onCloseReset();
  };

  const saveFileHandler = (e) => {
    setS3Key(e.key);
  };

  const onCloseReset = () => {
    onClose();
    setInputForm(resetFormFields);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Modal
        size="lg"
        isOpen={isOpen}
        toggle={onCloseReset}
        style={{ width: "510px" }}
      >
        <ModalBody>
          <div className="d-flex justify-content-between align-items-start mb-4">
            {loading && <ALoader />}
            <div className="heading-3 label-color">
              {formData?.selectedIndex ? "Edit" : "Add"}{" "}
              {type === "PTM" ? "PTM" : "Orientation"}
            </div>
            <CloseIcon onClick={() => onCloseReset()} />
          </div>
          <div className="d-flex align-items-center mb-4">
            <div className="regular-large-bold color-dark ">
              Batch: {batchDetails?.batchName}
            </div>
            <Tooltip title="View batch">
              <EyeIcon
                width="16px"
                height="16px"
                className="ml-1 cursor"
                onClick={() =>
                  window.open(
                    history.createHref({
                      pathname: `admin/edp/batch/view/${batchDetails?.id}`,
                    })
                  )
                }
              />
            </Tooltip>
          </div>

          <Row className="mb-2">
            <Col sm="4">
              <div className="d-flex flex-column">
                <div className="regular-large-bold color-dark mb-2">Date</div>
                <CustomDatePickerV2
                  placeHolder="Date"
                  value={inputForm?.date}
                  minDate={moment()}
                  handler={(index, date) => inputFormHandler(date, "date")}
                  height="32px"
                  disabled={!isCreatePermission}
                  maxDate={maxDate31Days()}
                />
              </div>
            </Col>
            <Col sm="4">
              <div className="d-flex flex-column">
                <div className="regular-large-bold color-dark mb-2">
                  Start time
                </div>
                <DesktopTimePicker
                  ampm={false}
                  value={
                    inputForm?.startTime
                      ? moment(inputForm?.startTime, "HH:mm")
                      : null
                  }
                  onChange={(value) =>
                    inputFormHandler(
                      moment(value)?.format("HH:mm"),
                      "startTime"
                    )
                  }
                  sx={timepickerStyle}
                  disabled={!isCreatePermission}
                />
              </div>
            </Col>
            <Col sm="4">
              <div className="d-flex flex-column">
                <div className="regular-large-bold color-dark mb-2">
                  End time
                </div>
                <DesktopTimePicker
                  ampm={false}
                  value={
                    inputForm?.endTime
                      ? moment(inputForm?.endTime, "HH:mm")
                      : null
                  }
                  onChange={(value) =>
                    inputFormHandler(moment(value)?.format("HH:mm"), "endTime")
                  }
                  sx={timepickerStyle}
                  disabled={!isCreatePermission}
                />
              </div>
            </Col>
          </Row>

          <div className="mb-2 d-flex flex-column">
            <div className="mb-2 regular-large-bold color-dark">Room</div>
            <AAutoComplete
              placeholder={"Select"}
              items={roomsDropdown}
              currentValue={inputForm?.room}
              handleChange={(value) => inputFormHandler(value, "room")}
              isMulti={false}
              style={{ control: { height: "40px" } }}
              isDisabled={!isCreatePermission}
            />
          </div>

          {type === "PTM" && (
            <div className="mb-2 d-flex flex-column">
              <div className="mb-2 regular-large-bold color-dark">Students</div>
              <PTMUpdatePsid
                batchId={batchDetails.id}
                selectedStudents={selectedStudents}
                setSelectedStudents={setSelectedStudents}
                ptmId={ptmRowData?.id}
                mappedStudentList={mappedStudentList}
                bachTimeTablePsidMapping={ptmRowData?.bachTimeTablePsidMapping}
                isEdit={ptmRowData?.mode === "EDIT"}
                isDisabled={!isCreatePermission}
              />
            </div>
          )}
          <div className="mb-2 d-flex flex-column">
            <div className="mb-2 regular-large-bold color-dark">
              Faculty 
             {academicCareer?.dispValue !== "ICON" && ` (Optional)`}
            </div>
            <AAutoComplete
              placeholder={"Select"}
              items={allFacultyDropdown}
              currentValue={inputForm?.faculty}
              handleChange={(value) => inputFormHandler(value, "faculty")}
              isMulti={false}
              isDisabled={!isCreatePermission}
              style={{ control: { height: "40px" } }}
            />
          </div>
          {isEnabledMsTeamsSync && isCreatePermission && (
            <div className="d-flex align-items-center mb-4">
              <div className="regular-large-bold color-dark mr-3">MS teams</div>
              {toggleState ? (
                <>
                  <ActiveControl onClick={() => setToggleState(false)} />
                  <span className="ml-2">Yes</span>
                </>
              ) : (
                <>
                  <InactiveControl onClick={() => setToggleState(true)} />
                  <span className="ml-2">No</span>
                </>
              )}
            </div>
          )}

          <UploadForTimetable
            saveHandler={saveFileHandler}
            setLoading={setLoading}
            uploadedFile={file}
            deleteFileHandler={() => {
              setFile(null);
              deleteFileHandler();
            }}
            isEditAccess={isCreatePermission}
          />

          <div>
            {ptmRowData?.isEdit ? (
              <AButton
                className="btn-left-0"
                variant="primary_filled"
                onClick={savePTM}
                disabled={!isCreatePermission}
              >
                {" "}
                Save PTM
              </AButton>
            ) : (
              <AButton
                className="btn-left-0"
                variant="primary_filled"
                onClick={saveEvent}
                disabled={!isCreatePermission}
              >
                Save event
              </AButton>
            )}
          </div>
        </ModalBody>
      </Modal>
    </LocalizationProvider>
  );
};

export default AddPTMModalForm;
