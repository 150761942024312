import React, { useState, useEffect } from "react";
import Select from "react-select";
import {withRouter} from 'react-router-dom'
import { Container, Card, CardHeader, Col, Input, Button } from "reactstrap";
import { fetchDetails, fetchAllData,getRequiredSign } from "../../../common/utils/methods/commonMethods/utilityMethod";
import {
  masterServiceBaseUrl,
  failureToast,
  putpost,
  successToast,
  examControllerServiceBaseUrl,
} from "services/http";
import ExamConfigDetails from "../configrationDetails";
import CustomLoader from "../../../common/commonComponents/Loader/loader";
import moment from 'moment'

const ConfigrationHeader = (props) => {
  //action value 'new' for new , 'edit' for edit and 'view' for view
  const { action = "new", id } = props.match.params;

  const [values, setValues] = useState({
    businessGroup: { label: "", value: "" },
    comapnyCode: { label: "", value: "" },
    businessArea: { label: "", value: "" },
    courseId: { label: "", value: "" },
    academicCareer: { label: "", value: "" },
    term: { label: "", value: "" },
    status: { label: "ACTIVE", value: "ACTIVE" },
    createOn: "",
  });

  const [isView,setIsView] = useState(false)

  const [groupCodeData, setGroupCodeData] = useState([]);
  const [companyCodeData, setCompanyCodeData] = useState([]);
  const [businessAreaData, setBusinessAreaData] = useState([]);
  const [courseIdData, setCourseIdData] = useState([]);
  const [termData, setTermData] = useState([]);
  const [academicCareerData, setAcademicCareerData] = useState([]);

  const [groupCodeObject, setGroupCodeObject] = useState(null);
  const [companyCodeObject, setCompanyCodeObject] = useState(null);
  const [businessAreaObject, setBusinessAreaObject] = useState(null);
  const [courseIdObject, setCourseIdObject] = useState(null);
  const [termObject, setTermObject] = useState(null);
  const [academicCareerObject, setAcademicCareerObject] = useState(null);


  const [statusData, setStatusData] = useState([
    { label: "ACTIVE", value: "ACTIVE" },
    { label: "INACTIVE", value: "INACTIVE" },
  ]);
  const [headerDetail, setHeaderDetails] = useState(null);

  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    fetchDetails(
      masterServiceBaseUrl + `/groupCode/getAllActiveGroupCode`,
      setGroupCodeData,
      "groupCode",
      "select"
    );
    // fetchDetails(
    //   masterServiceBaseUrl + `/companyCode/getAllActiveCompanyCode`,
    //   setCompanyCodeData,
    //   "comapnyCode",
    //   "select"
    // );
    fetchDetails(
      masterServiceBaseUrl + `/term/getAllActiveTerm`,
      setTermData,
      "term",
      "select"
    );
    // fetchDetails(masterServiceBaseUrl + `academicCareer/getAcademicCareerByCompanyCode/${id}`, setAcademicCareerData, 'academicCareer', 'select');
    // fetchDetails(masterServiceBaseUrl + `/groupCode/getAllActiveGroupCode`, setGroupCodeData, 'groupCode', 'select');
    // fetchDetails(masterServiceBaseUrl + `/groupCode/getAllActiveGroupCode`, setGroupCodeData, 'groupCode', 'select');
  }, []);

  const submitData = () => {
    if (!values.businessGroup.value) {
      failureToast("Please select Business Group");
      return;
    }
    if (!values.comapnyCode.value) {
      failureToast("Please select Company Code");
      return;
    }
    if (!values.businessArea.value) {
      failureToast("Please select Business Area");
      return;
    }    
    if (!values.academicCareer.value) {
      failureToast("Please select academic Career");
      return;
    }
    if (!values.term.value) {
      failureToast("Please select term");
      return;
    }
    if (!values.courseId.value) {
      failureToast("Please select course id");
      return;
    }
    if (!values.status.value) {
      failureToast("Please select status");
      return;
    }
    setIsFetching(true);

    const serverData = {
      academicCareerId: values.academicCareer.value,
      businessAreaId: values.businessArea.value,
      companyCodeId: values.comapnyCode.value,
      courseId: values.courseId.value,
      // courseId :'11720',
      groupCode: values.businessGroup.value,
      status: values.status.value,
      termId: values.term.value,
    };

    putpost(
      examControllerServiceBaseUrl + "/examDateHeader/createExamDateHeader",
      (data) => {
        successToast(data["message"]);
        setIsFetching(false);
        props.history.push("/admin/examDateConfig/new/"+data.data.id)
        // props.history.push("/admin/examConfig/new/"+data.data.id)
      },
      (data) => {
        failureToast(data["message"]);
        setIsFetching(false);
      },
      serverData,
      "post"
    );
  };

  useEffect(() => {
    if(action && action==='new' && !id){
      setIsFetching(false);
    }
    if (action && id) {
      fetchAllData(
        `${examControllerServiceBaseUrl}/examDateHeader/getExamDateHeaderById/${id}`,
        setHeaderDetails
      );
      if(action === 'view'){
        setIsView(true)
      }
    }
  }, [action]);

  useEffect(() => {
   if(headerDetail){
    fetchAllData(`${masterServiceBaseUrl}/groupCode/getGroupCode/${headerDetail.groupCode}`,setGroupCodeObject)
     fetchAllData(`${masterServiceBaseUrl}/companyCode/getCompanyCode/${headerDetail.companyCodeId}`,setCompanyCodeObject)
     fetchAllData(`${masterServiceBaseUrl}/academicCareer/getAcademicCareer/${headerDetail.academicCareerId}`,setAcademicCareerObject)
     fetchAllData(`${masterServiceBaseUrl}/term/getTerm/${headerDetail.termId}`,setTermObject)
     fetchAllData(`${masterServiceBaseUrl}/businessArea/getBusinessArea/${headerDetail.businessAreaId}`,setBusinessAreaObject)
    //  fetchAllData(`${masterServiceBaseUrl}`)
   }
  }, [headerDetail])

  useEffect(() => {
    if(action && id &&
    groupCodeObject &&
    companyCodeObject &&
    businessAreaObject &&
    termObject &&
    academicCareerObject){
      setIsFetching(false)
    }
  }, [action,groupCodeObject,
    companyCodeObject,
    businessAreaObject,
    courseIdObject,
    termObject,
    academicCareerObject,])

  return (
    <Container fluid className="mt-3">
      <Card>
        <CardHeader>
          <h3>Exam Date configuration</h3>
        </CardHeader>
      </Card>
      <Card className="mt-4">
        <CardHeader>
          <h3>Exam Date Header</h3>
        </CardHeader>
        {isFetching ? (
          <div
            className="mx-auto text-center py-5 my-5"
            style={{ height: "100vh" }}
          >
            <CustomLoader apiLoader={isFetching} />
          </div>
        ) : (
          <div className={"p-4"}>
            <div className="form-row mb-3">
              <Col className="mb-3" md="6">
                <label className="form-control-label">Business Group{getRequiredSign()}</label>
                {action === 'new' && !id ? <Select
                  value={values.businessGroup}
                  id="group_examConfig"
                  options={groupCodeData}
                  onChange={(sdata) => {
                    setValues({
                      ...values,
                      businessGroup: sdata,
                      comapnyCode: { label: "", value: "" },
                      businessArea: { label: "", value: "" },
                      academicCareer : { label: "", value: "" },
                      courseId: { label: "", value: "" },
                      term: { label: "", value: "" }
                    });
                    setCompanyCodeData([])
                    fetchDetails(
                      masterServiceBaseUrl +
                        `/companyCode/getCompanyCodeByGroup/${sdata.value}`,
                      setCompanyCodeData,
                      "companyCode",
                      "select"
                    );
                    setBusinessAreaData([])
                    setAcademicCareerData([])
                  }}
                /> : <Input value={groupCodeObject.groupCodeDispValue} disabled={true} style={{ height: "39px" }} /> }
              </Col>
              <Col className="mb-3" md="6">
                <label className="form-control-label">Company code{getRequiredSign()}</label>
                {action === 'new' && !id ?<Select
                  value={values.comapnyCode}
                  id="company_examConfig"
                  isDisabled={!values.businessGroup.value || !(action==='new')}
                  options={companyCodeData}
                  onChange={(sdata) => {
                    setValues({
                      ...values,
                      comapnyCode: sdata,
                      businessArea: { label: "", value: "" },
                      academicCareer : { label: "", value: "" },
                      courseId: { label: "", value: "" },
                      term: { label: "", value: "" }
                    });
                    setBusinessAreaData([])
                    setAcademicCareerData([])
                    fetchDetails(
                      masterServiceBaseUrl +
                        `/businessArea/getBusinessAreaByCompanyGroupCode/?companyCode=${sdata.value}&groupCode=${values.businessGroup.value}`,
                      setBusinessAreaData,
                      "businessArea",
                      "select"
                    );
                    fetchDetails(
                      masterServiceBaseUrl +
                        `/academicCareer/getAcademicCareerByCompanyCode/${sdata.value}`,
                      setAcademicCareerData,
                      "academicCareer",
                      "select"
                    );
                    setCourseIdData([])
                  }}
                />: <Input value={companyCodeObject.companyCodeDispValue} disabled={true} style={{ height: "39px" }} /> }
              </Col>
            </div>
            <div className="form-row mb-3">
              <Col className="mb-3" md="6">
                <label className="form-control-label">Business Area{getRequiredSign()}</label>
                {action === 'new' && !id ?<Select
                  value={values.businessArea}
                  id="business_examConfig"
                  options={businessAreaData}
                  isDisabled={!values.comapnyCode.value || !(action==='new')}
                  onChange={(sdata) =>
                    setValues({ ...values, businessArea: sdata })
                  }
                />: <Input value={businessAreaObject.businessAreaDispValue} disabled={true} style={{ height: "39px" }} /> }
              </Col> 
              <Col className="mb-3" md="6">
                <label className="form-control-label">Academic Career{getRequiredSign()}</label>
                {action === 'new' && !id ?<Select
                  value={values.academicCareer}
                  id="academic_examConfig"
                  options={academicCareerData}
                  isDisabled={!values.businessArea.value || !(action==='new')}
                  onChange={(sdata) => {
                    setValues({
                      ...values,
                      academicCareer: sdata,
                      courseId: { label: "", value: "" },
                      term: { label: "", value: "" }
                    });
                    fetchDetails(
                      masterServiceBaseUrl +
                        `/courseCatalogMapping/getAllCourseIdWithCRTYAndExamAndAcademicIdAndCompanyCode?companyCodeId=${values.comapnyCode.value}&academicCareerId=${sdata.value}`,
                      setCourseIdData,
                      "",
                      "courseId"
                    );
                    setCourseIdData([])
                  }}
                />: <Input value={academicCareerObject.academicCareerDispValue} disabled={true} style={{ height: "39px" }} /> }
              </Col>
            </div>
            <div className="form-row mb-3">
              <Col className="mb-3" md="6">
                <label className="form-control-label">Term{getRequiredSign()}</label>
                {action === 'new'  && !id ?<Select
                  value={values.term}
                  id="term_examConfig"
                  isDisabled={!values.academicCareer.value || !(action==='new')}
                  options={termData}
                  onChange={(sdata) =>
                    setValues({
                      ...values,
                      term: sdata,
                    })
                  }
                />: <Input value={termObject.termDispValue} disabled={true} style={{ height: "39px" }} /> }
              </Col>
              <Col className="mb-3" md="6">
                <label className="form-control-label">Course ID{getRequiredSign()}</label>
                {action === 'new' && !id ?<Select
                  value={values.courseId}
                  id="course_examConfig"
                  isDisabled={!values.term.value || !(action==='new')}
                  options={courseIdData}
                  onChange={(sdata) =>
                    setValues({ ...values, courseId: sdata })
                  }
                />: <Input value={headerDetail.courseId} disabled={true} style={{ height: "39px" }} /> }
              </Col>
            </div>
            <div className="form-row mb-3">
              <Col className="mb-3" md="6">
                <label className="form-control-label">Created Date</label>
                <Input
                  disabled={true}
                  className="tdSelect"
                  value={action === 'new' && !id? values.createOn : moment(headerDetail.createdOn).format("DD-MM-YYYY")}
                />
              </Col>
              <Col className="mb-3" md="6">
                <label className="form-control-label">Status{getRequiredSign()}</label>
                {action === 'new' && !id ?<Select
                  value={values.status}
                  id="status_examConfig"
                  isDisabled={!(action==='new')}
                  options={statusData}
                  onChange={(sdata) => setValues({ ...values, status: sdata })}
                />: <Input value={headerDetail.status} disabled={true} style={{ height: "39px" }} /> }
              </Col>
            </div>
            {action==='new' && !id &&<div className="form-row mb-3">
              <Col md={6} />
              <Col className="mb-3 d-flex justify-content-end" md="6">
                <Button
                  color="info"
                  size="md"
                  type="button"
                  onClick={submitData}
                >
                  Save & Next
                </Button>
              </Col>
            </div>}
          </div>
        )}
      </Card>
      {action && id && headerDetail ? <ExamConfigDetails action={action} isView={isView} comapnyCode={headerDetail && headerDetail.companyCodeId} academicCareer={ headerDetail && headerDetail.academicCareerId} term={headerDetail && headerDetail.termId} id={id}/> : null}
      {/* {values.comapnyCode.value && values.academicCareer.value && values.term.value && <ExamConfigDetails comapnyCode={values.comapnyCode.value} academicCareer={values.academicCareer.value} term={values.term.value}/>} */}
    </Container>
  );
};

export default withRouter(ConfigrationHeader);
