import React, { useEffect, useState, Fragment } from 'react';
import { Alert, Button, Card, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { getCookie, setCookie, eraseCookie } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import BrandLogo1 from '../../../assets/img/brand/aakashNewLogo.svg';
import BrandLogo2 from '../../../assets/img/brand/aakashNewLogo.svg';
import BrandLogo3 from '../../../assets/img/brand/aakashNewLogo.svg';
import Banner from '../../../assets/img/brand/login_banner.svg';
// import { setUserLogedOut } from '../manage/common/services';
import Styles from './login.module.scss';
import CloseEye from 'assets/img/svg/eye_open.svg';
import CloseOpen from 'assets/img/svg/eye_close.svg';
import Eye from 'assets/img/svg/eye.svg';
import { authServiceUrl, validateToken } from 'services/http';
import LoginWithOTP from './loginWithOTP';
import ResetPassword from './resetPassword';
import {Error} from 'components/ErrorPage/ErrorPage'
import useNetworkStatus from 'components/InternetConnection/netwrokStatus';
import InternetConnection from 'components/InternetConnection';
import { failureToast } from '../manage/common/utils/methods/toasterFunctions/function';

// import { successToast, failureToast } from '../../../views/pages/manage/common/utils/methods/toasterFunctions/function';

const loginMethods = ['normal', 'otp'];

const Login = prop => {
  const [viewPassword, setViewPassword] = useState(false);
  const [userCredentials, setUserCredentials] = useState({});
  const [userIdError, setUserIdError] = useState(false);
  const [userPasswordError, setUserPasswordError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorType, setErrorType] = useState('');

  const [loginMethod, setLoginMethod] = useState(loginMethods[0]);
  const [isFirstTimeLogin, setFirstTimeLogin] = useState(false);
  const [isVpnError,setVpnError] = useState(false);
  const [forgotPassword,setForgotPassword] = useState(false);
  const [isPasswordExpired,setPasswordExpired] = useState(false);
  const [chking,setChking] = useState(false)

  let [status] = useNetworkStatus();

  const fetchDetails = async url => {
    try {
      setIsLoading(true);
      const login = await fetch(`${authServiceUrl}/loginUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Cookie: 'JSESSIONID=2F08271B986D66F695F1FAD72F7E9B29',
        },
        body: JSON.stringify({
          accessKey: userCredentials.username,
          secretKey: userCredentials.password,
          clientId: 'PHX',
        }),
      })
        .then(function (response) {
          if (response.ok) {
            setIsError(false);
            return response.json();
          } else {
            setIsError(true);
            return response.json();
          }
        })
        .then(function (data) {
          return data;
        })
        .catch(error => {
          setVpnError(true);
          setIsLoading(false);
        });

      

      if (login?.data?.statusCode === 200 && !login?.data?.resetPasswordEnabled && !login?.data?.userPasswordExpired) {
        if (getCookie('userId') !== userCredentials.username) {
          eraseCookie('lastKnowPath')
        }
        setCookie('userId', userCredentials.username);
        setCookie('token', login?.data?.jwtToken);
        const validate = await validateToken();
        if (validate?.data?.statusCode === 200) {
          if (window.location.toString().split('?')[1] === 'redirect=exam-management') {
            prop.history.replace('/admin/exam-management/admin-utility');
          }else{
            getCookie('lastKnowPath')
            ? prop.history.replace(getCookie('lastKnowPath'))
            : prop.history.replace('/admin/dashboard');
            setErrorType('');
            setErrorType('');
          }
        } else {
          setIsLoading(false);
          setErrorType(validate?.message ?? 'Invalid Username or Password');
        }
      }else if(login?.data?.statusCode === 200 && login?.data?.resetPasswordEnabled){
        setIsLoading(false);
        setFirstTimeLogin(true);
      }else if(login?.data?.statusCode === 200 && login?.data?.userPasswordExpired){
        setIsLoading(false);
        setPasswordExpired(true);
      } else {
        setIsLoading(false);
        setErrorType(login?.message ?? 'INVALID_CREDENTIALS');
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleAuthentication = () => {
    setUserPasswordError(false);
    setUserIdError(false);
    setIsError(false);
    if (!userCredentials?.password && !userCredentials?.username) {
      setUserIdError(true);
      setUserPasswordError(true);
      return;
    }
    if (!userCredentials.username) {
      setUserIdError(true);
      return;
    }
    if (!userCredentials.password) {
      setUserPasswordError(true);
      return;
    }

    fetchDetails();
  };

  const validateInformation = async () => {
    const validate = await validateToken();
    if (validate?.data?.statusCode === 200) {
      getCookie('lastKnowPath')
        ? prop.history.replace(getCookie('lastKnowPath'))
        : prop.history.replace('/admin/dashboard');
    }
  };

  useEffect(() => {
    // console.log('Navigator details', navigator);
    // const navigator_info = window.navigator;
    // const screen_info = window.screen;
    // let uid = navigator_info.mimeTypes.length;
    // uid += navigator_info.userAgent.replace(/\D+/g, '');
    // uid += navigator_info.plugins.length;
    // uid += screen_info.height || '';
    // uid += screen_info.width || '';
    // uid += screen_info.pixelDepth || '';
    // console.log(uid);
    if (getCookie('token')) {
      validateInformation();
    }
  }, []);

  if (!status) {
    return <InternetConnection />;
  }

  const checkUserId = async() => {
    try {
      setChking(true);
      const validUser = await fetch(`${authServiceUrl}/checkIsValidUser/${userCredentials.username}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            return response.json();
          }
        })
        .then(function (data) {
          return data;
        })
        .catch(error => {
          setVpnError(true);
          setChking(false);
        });


        if (validUser?.code === 200){
          setForgotPassword(true)
          setChking(false);
        }else{
          failureToast(validUser['message'])
          setChking(false);
        }
      }catch(e){
        console.log(e)
      }
  }

  return (
    <Fragment>
      {isVpnError ?
        <Error refreshPage={()=>setVpnError(false)}/>
      :isFirstTimeLogin || forgotPassword || isPasswordExpired ?
        <ResetPassword isFirstTimeLogin={isFirstTimeLogin} isPasswordExpired={isPasswordExpired} forgotPassword={forgotPassword} setForgotPassword={setForgotPassword} setPasswordExpired={setPasswordExpired} userId={userCredentials.username} oldPassword={userCredentials.password} setFirstTimeLogin={setFirstTimeLogin} setUserCredentials={setUserCredentials}/>
        :
        <div className={Styles.login__page__container}>
          <div className="text-center">
            <Row className="justify-content-center" style={{ margin: '0' }}>
              <Col xs={12} sm={12} md={6} lg={6} style={{ padding: 0, margin: 0 }}>
                <Card className={Styles.login__page__left}>
                  <div>
                    <Row className={Styles.login__banner__content} style={{ margin: '0' }}>
                      <h1 className={Styles.login__banner__heading}>
                        Welcome to the Phoenix - ERP 2.0
                        <br />
                        <span className={Styles.login__banner__span}>Login to explore new experience.</span>
                      </h1>
                    </Row>
                    <Row style={{ margin: '0' }}>
                      <img src={Banner} alt="" className={Styles.login__banner__image} />
                    </Row>
                  </div>
                </Card>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} style={{ padding: 0, margin: 0, backgroundColor: '#ffffff !important' }}>
                {loginMethod === loginMethods[1] ?
                  <LoginWithOTP />
                  :
                  <div className={Styles.login__page__right}>
                    <div className={Styles.logo__brand__aakash__block}>
                      <img
                        src={BrandLogo1}
                        srcSet={`${BrandLogo2}, ${BrandLogo3}`}
                        className={Styles.brand__logo__aakash}
                        alt="Brand"
                      />
                    </div>
                    <div className={Styles.form__control}>
                      <div className={Styles.welcome__header__block}>
                        <h2 className={Styles.welcome__header}>
                          Welcome!
                          <br />
                          <span>Login to your account</span>
                        </h2>
                      </div>
                      <div className="mt-4">
                        <FormGroup>
                          <Label className={`${Styles.login__input__label} form-control-label fw-bold`}>User ID</Label>
                          <div
                            className={Styles.login__input__box}
                            style={userIdError ? { borderColor: '#f5365c' } : { borderColor: '#a1aeb7' }}
                          >
                            <Input
                              disabled={isLoading}
                              type="text"
                              maxLength={70}
                              id={Styles.login__user__id}
                              onChange={e => {
                                const val = e.target.value?.toString()?.toUpperCase() ?? e.target.value;
                                setUserCredentials({ ...userCredentials, username: val })
                              }}
                              className={`${Styles.login__text__box} ${userIdError || isError ? Styles.error : ''} ${Styles.upperCase}`}
                              invalid={userIdError === true}
                              valid={userIdError === false}
                              required
                              placeholder={'User ID'}
                              onFocus={() => { setUserIdError(false); setIsError(false) }}
                              autoComplete="nope"
                            />
                          </div>
                          {userIdError ? (
                            <div className={Styles.login__input__error}><span>Please enter a correct user id.</span></div>
                          ) : null}

                          <Label className={`${Styles.login__input__label} form-control-label fw-bold mt-4`}>
                            Password
                          </Label>
                          <div
                            className={Styles.login__input__box}
                            style={userPasswordError ? { borderColor: '#f5365c' } : { borderColor: '#a1aeb7' }}
                          >
                            <Input
                              disabled={isLoading}
                              type={viewPassword ? 'text' : 'password'}
                              onChange={e => setUserCredentials({ ...userCredentials, password: e.target.value })}
                              maxLength={100}
                              className={`${Styles.login__text__box} ${userPasswordError || isError ? Styles.error : ''}`}
                              invalid={userPasswordError}
                              valid={userPasswordError}
                              placeholder={'Password'}
                              required
                              onFocus={() => { setUserPasswordError(false); setIsError(false) }}
                              autoComplete="new-password"
                            />
                            <span className={Styles.login__input__icons}>
                              <Button
                                onClick={() => setViewPassword(!viewPassword)}
                                className={Styles.login__input__eye__button}
                                style={viewPassword ? { color: '#00b0f5' } : { color: '#a1aeb7' }}
                              >
                                <img src={viewPassword ? CloseOpen : userCredentials.password ? CloseEye : Eye} />
                              </Button>
                            </span>
                          </div>
                          {userPasswordError && !isError ? (
                            <div className={Styles.login__input__error}><span>Please enter a correct password.</span></div>
                          ) : isError ? (
                            <div className={Styles.login__input__error}><span>{errorType}</span></div>
                          ) : null}

                          <Button
                            className={Styles.login__button}
                            disabled={isLoading}
                            onClick={e => {
                              handleAuthentication();
                              // document.body.classList.remove("bg-default");
                              // prop.history.push('/admin')
                            }}
                          >
                            {!isLoading ? (
                              <span>Login</span>
                            ) : (
                              <i className="fas fa-spinner fa-spin" aria-hidden="true"></i>
                            )}
                          </Button>
                          {errorType === 'INVALID_TOKEN' && (
                            <div className={Styles.login__input__error}> Please try to login again.</div>
                          )}
                          {/* login with otp */}
                          {/* <div className={Styles.or}><span>OR</span></div>
                        <Button
                          className={Styles.login__button_mt}
                          disabled={isLoading}
                          onClick={e => {
                           setLoginMethod(loginMethods[1])
                            // document.body.classList.remove("bg-default");
                            // prop.history.push('/admin')
                          }}
                        >
                          <span>
                            Login With OTP
                          </span>
                        </Button> */}
                        <div className={Styles.formgot_password} onClick={!chking ? ()=>{userCredentials.username ? checkUserId() : failureToast("Please provide UserID")} : ()=> {}}>
                          {chking && <i className="fas fa-spinner fa-spin" aria-hidden="true"></i>} &nbsp;Forgot Password
                        </div>
                        </FormGroup>
                      </div>
                    </div>
                  </div>}
              </Col>
            </Row>
          </div>
        </div>}
    </Fragment>
  );
};

export default Login;
