import React from "react";
import { AGridColDef } from "../../../common/data-grid/ADataGridColDef";
import {
  IssuedDetailsInfo,
  MaterialDetails,
  getMaterialLabel,
  statusChip,
} from "../selectMeterialAndStock/issueStock/TableColumn";
import CustomChip from "views/pages/manage/common/customChip/CustomChip";
import moment from "moment";
import ViewMaterials from "./ViewMaterials";

export const getStockReturnTableHeader = (
  otherDetails = false,
  academicType
) => {
  return [
    new AGridColDef("createdOn", "Return details")
      .setMinWidth(150)
      .renderCellComponent((params) => (
        <div className={`ellipsis-text-container d-flex`}>
          {moment(params.value).format("DD-MMM-YYYY")}
          <IssuedDetailsInfo stock={params.row} />
        </div>
      )),
    new AGridColDef("sapDocId", "SAP doc number")
      .setMinWidth(120)
      .setValueGetter((params) => params?.value || "-"),
    new AGridColDef("transactionId", "Return ID")
      .setMinWidth(110)
      .renderCellComponent((params) => (
        <div className={`ellipsis-text-container`}>{params?.value}</div>
      )),
    new AGridColDef("psid", "PSID").setMinWidth(120),
    new AGridColDef("applicationId", "App. no.").setMinWidth(100),
    new AGridColDef("studentName", "Name").setMinWidth(100),
    new AGridColDef("termValue", "Term").setMinWidth(100),
    new AGridColDef("courseId", "Course ID").setMinWidth(100),

    new AGridColDef("status", "Status")
      .setMinWidth(120)
      .renderCellComponent((params) => {
        const statusChipColor = statusChip[params.value.toUpperCase()];
        return (
          <CustomChip
            bgColor={statusChipColor?.["bgColor"]}
            textColor={statusChipColor?.["textColor"]}
            text={statusChipColor?.["text"]}
          >
            {params.value}
          </CustomChip>
        );
      }),
    new AGridColDef("materialDetails", "Material details")
      .setMinWidth(250)
      .renderCellComponent((params) => (
        <div className={`ellipsis-text-container d-flex`}>
          {getMaterialLabel(params?.value || params?.row)}
          {otherDetails ? (
            <ViewMaterials
              isReturned={true}
              material={params?.row}
              academicType={academicType}
            />
          ) : (
            <MaterialDetails
              isReturned={true}
              dataRows={params?.value}
              academicType={academicType}
            />
          )}
        </div>
      )),
  ];
};
