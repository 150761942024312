import React from 'react';
import {Button, Card, Table} from "reactstrap";
import {MasterHeaderAuto} from "../../../common/commonComponents/masterHeader/masterHeader";
import {getDispValForReactSelectByOneID} from "../../../common/utils/methods/commonMethods/utilityMethod";
import {CustomTableRow} from "../../../common/commonComponents/TableComponents";
import CustomButton from "../../../../../../components/CustomButton";

const TableData =(props)=>{
    const {item,index,term,courseType,academicCareer,permissionSet} = props;
    return(
        <CustomTableRow>
            <td className="text-center p-2 mx-1">
                {getDispValForReactSelectByOneID(item.termId,'DispValue',term)}
            </td>
            <td className="text-center p-2 mx-1">
                {getDispValForReactSelectByOneID(item.courseType,'DispValue',courseType)}
            </td>
            <td className="text-center p-2 mx-1">{getDispValForReactSelectByOneID(item.courseType, 'DescValue', courseType)}</td>
            <td className="text-center p-2 mx-1">
                {getDispValForReactSelectByOneID(item.academicCareerId, 'DispValue', academicCareer)}
            </td>
            <td className="text-center p-2 mx-1">
                {item["status"] === "ACTIVE" ? (
                    <button disabled={true} type="button" className="btn btn-sm btn-success">
                        Active
                    </button>
                ) : (
                    <button type="button" disabled={true} className="btn btn-sm btn-warning">
                        Inactive
                    </button>
                )}
            </td>
            {
                permissionSet && permissionSet.includes('R')|| permissionSet.includes('U') ?
                    <td className="text-center p-2 mx-1">
                        <CustomButton
                            key={'ViewButton'}
                            permissionType={'R'}
                            icon={true}
                            forTable={true}
                            onClick={() => {
                                window.open( document.URL +'/view/'+item.id, '_blank' );
                            }}
                            permissionSet={permissionSet}
                        />
                        <CustomButton
                            key={'EditButton'}
                            permissionType={'U'}
                            icon={true}
                            forTable={true}
                            onClick={() => {
                                window.open( `${ document.URL }/edit/${item.id}`, '_blank' );
                            }}
                            permissionSet={permissionSet}
                        />
                    </td>
                    :null
            }
        </CustomTableRow>
    )
}
const ItemTypeSequenceSearchTable =(props)=>{
    const {data,term,courseType,academicCareer,searchHeaderList,permissionSet} = props;
    return(
        <Card>
            <div id="questionTable" className="table-responsive" >
                <Table className="align-items-center table-flush ">
                    <MasterHeaderAuto  headerList={permissionSet.includes('R')|| permissionSet.includes('U')? searchHeaderList :searchHeaderList.slice(0, searchHeaderList.length - 1)} />
                    {/* <MasterHeaderAuto permissionSet={permissionSet}   headerList={ searchHeaderList} /> */}
                    <tbody className="list" key={'courseCatalogSequenceSearch'}>
                    {
                        data && data.length>0 && data.map((item,index)=>
                            <TableData item={item} index={index} term={term} courseType={courseType} academicCareer={academicCareer} permissionSet={permissionSet}/>
                        )
                    }
                    </tbody>
                </Table>
            </div>
        </Card>
    );
}

export default ItemTypeSequenceSearchTable;