import React from 'react'
import styles from './styles.module.scss'

const titleStyle = {
  fontWeight: 400,
  fontSize: '12px',
}

const dataStyle = {
  fontWeight: 600,
  fontSize: '15px',
}

const DataCell = ({children, title, data}) => {
  return (
    <div className={styles.test_card_data_cell}>
      <div style={titleStyle}>{title}</div>
      {children || <div style={dataStyle}>{data}</div>}
    </div>
  )
}

export default DataCell