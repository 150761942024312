import React from "react";
import { ReactComponent as OneDarkBullet } from "../../../../../assets/img/svg/1-dark-bullet.svg";
import { ReactComponent as TwoFadeBullet } from "../../../../../assets/img/svg/2-fade-bullet.svg";
import { ReactComponent as TwoDarkBullet } from "../../../../../assets/img/svg/2-dark-bullet.svg";
import { ReactComponent as ThreeFadeBullet } from "../../../../../assets/img/svg/3-fade-bullet.svg";
import { ReactComponent as ThreeDarkBullet } from "../../../../../assets/img/svg/3-dark-bullet.svg";
import { ReactComponent as DoneBullet } from "../../../../../assets/img/svg/done-bullet.svg";
import { ReactComponent as Stroke } from "../../../../../assets/img/svg/Stroke.svg";
import { ReactComponent as StrokeDark } from "../../../../../assets/img/svg/StrokeDark.svg";
import "./style.scss";
import { minWidth } from "@material-ui/system";

const style = {
  position: "relative",
  minHeight: "60px",
  display: "inline-block",
};

const textStyle = {
  position: "absolute",
  left: "-25px",
  bottom: "2px",
  fontSize: "12px",
  fontWeight: "bold",
};
const lastLabelTextStyle = {
  left: "-50px",
  display: "inline-block",
  minWidth: "100px",
};
const firstLabelPosition = {
  left: "-5px",
};
const handIcon = {
  cursor: "pointer",
};

const GetProgressBar = ({ step, setStep, isTimeTable = false }) => {
  switch (step) {
    case 1:
      return (
        <div className="mt-1">
          <span style={style}>
            <OneDarkBullet />
            <Stroke />
            {!isTimeTable && <span className="bottom-label-first active-label">CMS package mapping</span>}
            {isTimeTable && <span className="bottom-label-first active-label">Batch</span>}
          </span>
          <span style={style}>
            <TwoFadeBullet />
            <Stroke />
            {isTimeTable && <span className="bottom-label">Academic plan</span>}
            {!isTimeTable && <span className="bottom-label bottom-label-plan">Batch Mapping</span>}
          </span>
          <span style={style}>
            <ThreeFadeBullet />
            {isTimeTable && <span className="bottom-label-last">Timetable date</span>}
            {!isTimeTable && <span className="bottom-label">Branch(es)</span>}
          </span>
        </div>
      );
    case 2:
      return (
        <div className="mt-1">
          <span style={style}>
            <DoneBullet style={handIcon} onClick={() => setStep(1)} />
            <StrokeDark />
            {!isTimeTable && <span className="bottom-label-first">CMS package mapping</span>}
            {isTimeTable && <span className="bottom-label-first">Batch</span>}
          </span>
          <span style={style}>
            <TwoDarkBullet />
            <Stroke />
            {isTimeTable && <span className="bottom-label active-label">Academic plan</span>}
            {!isTimeTable && <span className="bottom-label active-label bottom-label-plan">Batch mapping</span>}
          </span>
          <span style={style}>
            <ThreeFadeBullet />
            {isTimeTable && <span className="bottom-label-last">Timetable date</span>}
            {!isTimeTable && <span className="bottom-label">Branch(es)</span>}

          </span>
        </div>
      );
    case 3:
      return (
        <div className="mt-1">
          <span style={style}>
            <DoneBullet style={handIcon} onClick={() => setStep(1)} />
            <StrokeDark />
            {!isTimeTable && <span className="bottom-label-first">CMS package mapping</span>}
            {isTimeTable && <span className="bottom-label-first">Batch</span>}
          </span>
          <span style={style}>
            <DoneBullet style={handIcon} onClick={() => setStep(2)} />
            {isTimeTable && <span className="bottom-label">Academic plan</span>}
            {!isTimeTable && <span className="bottom-label  bottom-label-plan">Batch mapping</span>}
            <StrokeDark />
          </span>
          <span style={style}>
            <ThreeDarkBullet />
            {isTimeTable && <span className="bottom-label-last active-label">Timetable date</span>}
            {!isTimeTable && <span className="bottom-label active-label">Branch(es)</span>}

          </span>
        </div>
      );

    default:
      break;
  }
};

export default GetProgressBar;
