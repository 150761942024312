import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import ADataGrid from 'views/pages/manage/common/data-grid/ADataGrid'
import { AGridColDef } from 'views/pages/manage/common/data-grid/ADataGridColDef'
import ActionsCellComponent from './ActionsCellComponent'
import moment from 'moment'
import NoRowOverlay from './NoRowOverlay';
import AAutoComplete from 'views/pages/manage/common/form-fields-mui/AAutoComplete'
import AInput from 'views/pages/manage/common/formFeilds/customInput/AInput'
import { GridRowModes } from '@mui/x-data-grid';
import { failureToast } from '../../common/utils/methods/toasterFunctions/function'
import { holidayRowInitialForm, holidayTypeDropdownData, inputStyles, selectStyles } from './common'
import { capitalize, generateRandomId, getDropdownIdsFromSelectArray, isObjEmpty } from '../../common/utils/methods/commonMethods/utilityMethod'
import CustomDatePickerV3 from '../../common/commonComponents/CustomDatePicker-V3'
import MultiSelectCellComponentWithSearch from '../../common/commonComponents/SearchFilterView/MultiSelectCellComponentWithSearch'
import { fetchBusinessAreasByRegions } from 'views/pages/manage/academicPlanAndTest/constant/common'
import { Tooltip } from '@mui/material'

export const cellContainerStyle = {
  width: '100%',
  padding: '0 10px'
}
const ListComponent = forwardRef(({ rows, dropdowns, deleteHandler = () => { }, updateHandler = () => { }, createHandler = () => { } ,form,setForm}, ref) => {

  const [businessAreaData, setBusinessAreaData] = useState([]);
  const [businessAreaLoader, setBusinessAreaLoader] = useState(false);
  const fetchBusinessArea = async (regionsArray) => {
    if (regionsArray?.length > 0) {
      setBusinessAreaLoader(true);
      const responseData = await fetchBusinessAreasByRegions(getDropdownIdsFromSelectArray(regionsArray));
      setBusinessAreaData(responseData);
      setBusinessAreaLoader(false);
    } else {
      setBusinessAreaData([]);
    }
  }

  const [rowModesModel, setRowModesModel] = useState({});

  const formHandler = (value, key) => setForm(prev => ({ ...prev, [key]: value }))

  const [rowData, setRowData] = useState(rows);
  useEffect(() => {
    setRowData(rows);
  }, [rows])

  const startEditing = (id, rowData) => {
    setRowModesModel(oldModel => ({ [id]: { mode: GridRowModes.Edit } }))
    setForm(rowData);
    if (rowData?.regions?.length > 0) fetchBusinessArea(rowData?.regions);
  }

  const stopEditing = (id) => {
    const currForm = { ...form }
    setRowModesModel(oldModel => ({ [currForm?.id]: { mode: GridRowModes.View, ignoreModifications: true } }))
    setForm({});
  }

  const handleEditClick = (params) => {
    if (!isObjEmpty(form)) {
      failureToast('Please save or delete current changes');
    } else {
      startEditing(params?.id, params?.row);
    }
  }

  const handleDeleteClick = (params) => {
    if (params?.row?.isNew) {
      setRowData(rows)
      setForm({});
    }
    else {
      stopEditing(params?.id)
    }
  }

  const handleSaveClick = async (params) => {
    if (form?.isNew) {
      const res = await createHandler(form)
      if (res?.code === 200) {
        stopEditing()
      }
    } else {
      const res = await updateHandler(form)
      if (res?.code === 200) {
        stopEditing(params?.id)
      }
    }
  }

  const addNewRow = async () => {
    
    if (!isObjEmpty(form)) {
      failureToast('Please save or delete current changes')
    } else {
      let randomId = generateRandomId();
      let emptyRow = { ...holidayRowInitialForm, isNew: true, id: randomId }
      await startEditing(randomId, emptyRow);
      setRowData(prev => [
        emptyRow,
        ...prev
      ])
    }
  }

  useImperativeHandle(ref, () => ({
    addNewRow: addNewRow,
    startEditing: startEditing,
    stopEditing: stopEditing
  }))

  const HolidayListHeader = [
    new AGridColDef('holidayName', 'Holiday name').setMinWidth(150).editable(true)
      .renderCellComponent(params => <Tooltip title={params?.value}><div className='ellipsis-text-container'>{params?.value}</div></Tooltip>)
      .renderEditCellComponent(params => (
        <div style={cellContainerStyle}>
          <AInput
            placeholder='Holiday name'
            value={form?.holidayName}
            handler={(value) => formHandler(value, 'holidayName')}
            style={inputStyles}
          />
        </div>
      )),
    new AGridColDef('holidayType', 'Holiday type').setMinWidth(150).editable(true)
      .renderCellComponent(params => <div>{params?.value?.label}</div>)
      .renderEditCellComponent(params => (
        <div style={cellContainerStyle}>
          <AAutoComplete
            currentValue={form?.holidayType}
            handleChange={value => formHandler(value, 'holidayType')}
            items={holidayTypeDropdownData}
            isMulti={false}
            style={selectStyles}
          />
        </div>
      )),
    new AGridColDef('holidayDate', 'Date').setMinWidth(150).editable(true).renderCellComponent(params => <span>{params?.value ? moment(params?.value)?.format('DD/MM/YYYY') : '-'}</span>)
      .renderEditCellComponent(params => (
        <div style={cellContainerStyle}>
          <CustomDatePickerV3
            placeHolder="Date"
            minDate={moment()}
            value={form?.holidayDate ? moment(form?.holidayDate) : null}
            handler={(index, date, keyName) => {
              formHandler(moment(date), 'holidayDate')
              // form?.holidayDate ? moment(form?.holidayDate)?.format('dddd') : ''
              formHandler(moment(date)?.format('dddd'), 'day')
            }}
          />
        </div>
      )),
    new AGridColDef('day', 'Day').setMinWidth(110).editable(true)
      .setValueGetter(params => capitalize(params?.value))
      .renderEditCellComponent(params => (
        <div style={cellContainerStyle}>
          <AInput
            value={capitalize(form?.day)}
            disabled={true}
            style={inputStyles}
          />
        </div>
      )),
    new AGridColDef('regions', 'Region', false).setMinWidth(150).editable(true)
      .renderCellComponent(params => <MultiSelectCellComponentWithSearch title={'Region'} data={params?.value} eyeIcon />)
      .renderEditCellComponent(params => (
        <div style={cellContainerStyle}>
          <AAutoComplete
            currentValue={form?.regions}
            handleChange={value => {
              formHandler(value, 'regions')
              formHandler([], 'businessAreas');
              fetchBusinessArea(value);
            }}
            items={dropdowns?.region}
            isMulti={true}
            isShowCount={true}
            style={selectStyles}
          />
        </div>
      )),
    new AGridColDef('businessAreas', 'Business Area', false).setMinWidth(140).editable(true)
      .renderCellComponent(params => <MultiSelectCellComponentWithSearch title={'Business area'} data={params?.value} eyeIcon />)
      .renderEditCellComponent(params => (
        <div style={cellContainerStyle}>
          <AAutoComplete
            currentValue={form?.businessAreas}
            handleChange={value => formHandler(value, 'businessAreas')}
            items={businessAreaData}
            isMulti={true}
            isShowCount={true}
            isDisabled={!form?.regions?.length}
            isLoading={businessAreaLoader}
            style={selectStyles}
          />
        </div>
      )),
    new AGridColDef('states', 'State', false).setMinWidth(150).editable(true)
      .renderCellComponent(params => <MultiSelectCellComponentWithSearch title={'State'} data={params?.value} eyeIcon />)
      .renderEditCellComponent(params => (
        <div style={cellContainerStyle}>
          <AAutoComplete
            currentValue={form?.states}
            handleChange={value => formHandler(value, 'states')}
            items={dropdowns?.state}
            isMulti={true}
            isShowCount={true}
            style={selectStyles}
          />
        </div>
      )),
    new AGridColDef('city', 'City', false).setMinWidth(120).editable(true)
      .renderCellComponent(params => <MultiSelectCellComponentWithSearch title={'City'} data={params?.value} eyeIcon />)
      .renderEditCellComponent(params => (
        <div style={cellContainerStyle}>
          <AAutoComplete
            currentValue={form?.city}
            handleChange={value => formHandler(value, 'city')}
            items={dropdowns?.city}
            isMulti={true}
            isShowCount={true}
            style={selectStyles}
          />
        </div>
      )),
    new AGridColDef('Actions', 'Actions', false).setMinWidth(150).columnAlign('right').setFlex(0.5)
      .renderCellComponent(params => (
        <ActionsCellComponent 
          params={params} 
          handleEditClick={handleEditClick} 
          handleDeleteClick={handleDeleteClick} 
          handleSaveClick={handleSaveClick} 
          deleteHandler={deleteHandler} 
          rowForm={form} 
        />
      )),
  ]

  return (
    <>
      <ADataGrid
        rowModesModel={rowModesModel}
        rows={rowData || []}
        columns={HolidayListHeader}
        rowId={(row) => row.id}
        rowHeight={50}
        height={100}
        loading={false}
        serverPagination={false}
        emptyRowPlaceholder={() => <NoRowOverlay />}
        sx={{
          borderTop: 'none !important',
          '.MuiDataGrid-columnHeaderTitle': {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
          },
        }}
        autoHeight
      />
    </>
  )
})

export default ListComponent