import React from 'react';
import { withRouter } from 'react-router-dom';
import {
	Card,
	CardHeader,
	Input,
	Container,
	Button,
	Table,
	Row,
} from 'reactstrap';
import { useState } from 'react';
import { successToast, failureToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import CustomDatePicker from '../../../../common/commonComponents/CustomDatePicker/index';
import {
	TERMSESSIONROW,
	TermSessionHeader,
} from '../../../../common/commonComponents/masterRow/masterRow';
import {
	masterServiceBaseUrl,
	putpost,
	putpost2,
	deleteAPI,
} from 'services/http';
import Select from 'react-select';
import moment from 'moment';
import CustomButton from 'components/CustomButton';

var _ = require('lodash');

const TermSessionTable = (props) => {
	const { userPermissions, isViewOnly } = props;
	const [isSaveVisible, setisSaveVisible] = useState(false);
	const [isEndDateEnable, setEndDateEnable] = useState(false);
	const [tableDataKey, settableDataKey] = useState('tableDataKey');
	const addNewClass = () => {
		props.setclassArray([
			{
				editMode: true,
				__status: '__new',
				sessionId: null,
				startDate: null,
				endDate: null,
			},
			...props.classArray,
		]);
	};
	const saveAll = (cb) => {
		var _tempArray = props.classArray;
		var isValid = true;
		_tempArray.forEach((el, index) => {
			if (el['editMode']) {
				if (el['sessionId'] === null) {
					isValid = false;
					failureToast('Please Select The Session');
					return;
				} else if (el['startDate'] === null) {
					isValid = false;
					failureToast('Please Enter The Start Date');
					return;
				} else if (el['endDate'] === null) {
					isValid = false;
					failureToast('Please Enter The End Date');
					return;
				} else if (new Date(el['endDate']) < new Date(el['startDate'])) {
					isValid = false;
					failureToast('End Date Cannot Be Smaller Than Start Date');
					return;
				}
				if (!isValid) {
					return;
				}
				if (_tempArray[index]['__status'] == '__new') {
					_tempArray[index]['termMappingId'] = props.termId;
					putpost(
						masterServiceBaseUrl + '/termSessionMaster/createTermSession',
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							// setEndDateEnable(false)
							props.fetchClasses();
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'post'
					);
				} else {
					putpost(
						masterServiceBaseUrl +
						'/termSessionMaster/updateTermSession/' +
						_tempArray[index].id,
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							// setEndDateEnable(false)
							delete _tempArray[index]['editMode'];
							props.setclassArray(_tempArray);
							settableDataKey(new Date().getMilliseconds());
							// props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'put'
					);
				}
			} else {
			}
		});
	};
	const newRow = () => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				addNewClass();
				setisSaveVisible(true);
				// settableDataKey(new Date().getMilliseconds())
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			props.setclassArray(_tempArray);
			setisSaveVisible(false);
			cb();
		}
	};
	const editRow = (index) => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			// props.classArray[index]['editMode'] = false
			// setEndDateEnable(true)
			let cb = () => {
				var _tempArray = props.classArray;
				_tempArray[index]['editMode'] = true;
				props.setclassArray(_tempArray);
				setisSaveVisible(true);
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			cb();
		}
	};
	const deleteRow = (index) => {
		if (props.classArray[index]['__status'] == '__new') {
			// let n = props.classArray
			// n.splice(index, 1)
			// props.setclassArray(n)
			setisSaveVisible(false);
			settableDataKey(new Date().getMilliseconds());
		} else {
			if (window.confirm('Are you sure you want to delete this Session?')) {
				putpost2(
					masterServiceBaseUrl +
					'/termSessionMaster/deleteTermSession/' +
					props.classArray[index].id,
					(data) => {
						successToast(data['message']);
						if (props.classArray.length == 1) {
							props.fetchClasses();
						} else {
							var _tempArray = props.classArray;
							if (_tempArray[index]['editMode']) {
								setisSaveVisible(false);
							}
							_tempArray.splice(index, 1);
							props.setclassArray(_tempArray);
						}
						settableDataKey(new Date().getMilliseconds());
					},
					(data) => {
						failureToast(data['message']);
					},
					'Delete'
				);
			}
		}
	};
	const updateKey = (index, value, key) => {
		var _tempArray = props.classArray;
		_tempArray[index][key] = value;
		if(key === 'startDate'){
			_tempArray[index]['endDate'] = ''
		}
		props.setclassArray(_tempArray);
		settableDataKey(new Date())
		if (key === 'startDate') {
			setEndDateEnable(true);
		}
	};

	const getSessionValue = (id) => {
		return props.sessionData.filter((item) => {
			return item && item.value === id;
		})[0];
	};

	return (
		<>
			{/* <Container className="mt-3" fluid> */}
			{/* <Row>
          <div> */}
			<Card>
				<CardHeader className="border-0">
					<h3 className="mb-0 floatLeft">Term / Session</h3>
					{!props.isViewOnly ? (
						<div className='d-flex justify-content-end'>
							<CustomButton
								disabled={isSaveVisible || props.initialDate === null ||isViewOnly}
								className={
									'floatRight mx-1' +
									(isSaveVisible ? ' btn-dark cursor-gray' : null)
								}
								content={'Add Session'}
								permissionType={'C'}
								icon={true}
								forTable={true}
								permissionSet={userPermissions}
								onClick={() => {
									newRow();
								}}
							/>
						</div>
					) : null}
				</CardHeader>
				<div id="questionTable">
					<Table className="align-items-center table-flush tableLayout">
						<TermSessionHeader
							type={'TermSession'}
							isViewOnly={props.isViewOnly}
							isSaveVisible={isSaveVisible}
							permissionSet={userPermissions}
						/>
						<tbody className="list" key={tableDataKey}>
							{props.classArray
								? props.classArray.map((el, index) =>
									!el['editMode'] ? (
										<TERMSESSIONROW
											type={'testMode'}
											index={index}
											el={el}
											editRow={editRow}
											deleteRow={deleteRow}
											sessionData={props.sessionData}
											isViewOnly={props.isViewOnly}
											isSaveVisible={isSaveVisible}
											permissionSet={userPermissions}
										/>
									) : (
										<tr key={index + '-class'}>
											<td className="text-left p-2 mx-1">
												<Select
													aria-label="sessionId"
													options={props.sessionData}
													value={getSessionValue(el['sessionId'])}
													onChange={(data) => {
														updateKey(index, data.value, 'sessionId');
													}}
													placeholder="Select Session"
												/>
											</td>
											<td className="text-center p-2 mx-1">
												<CustomDatePicker
													value={
														el['startDate']
															? moment(el['startDate']).toDate()
															: ''
													}
													index={0}
													minDate={moment(
														props.initialDate.startDate
													).toDate()}
													maxDate={moment(props.initialDate.endDate).toDate()}
													handler={(index, date, keyName) => {
														updateKey(index, date, 'startDate');
													}}
													keyName="startDate"
													rangeFrom={1950}
													rangeTo={2099}
													placeholder="Example:01-01-2020"
												/>
												{/* <Input
														type="date"
														aria-label="startDate"
														min={moment(props.initialDate.startDate).format(
															'YYYY-MM-DD'
														)}
														max={moment(props.initialDate.endDate).format(
															'YYYY-MM-DD'
														)}
														defaultValue={moment(el['startDate']).format(
															'YYYY-MM-DD'
														)}
														onChange={(e) => {
															updateKey(index, e.target.value, 'startDate');
														}}
														className="tdInput"
													/> */}
											</td>
											<td className="text-center p-2 mx-1">
												<CustomDatePicker
													value={
														el['endDate']
															? moment(el['endDate']).toDate()
															: ''
													}
													index={0}
													minDate={el['startDate']}
													maxDate={moment(props.initialDate.endDate).toDate()}
													handler={(index, date, keyName) => {
														updateKey(index, date, 'endDate');
													}}
													keyName="endDate"
													rangeFrom={1950}
													rangeTo={2099}
													placeholder="Example:01-01-2020"
													disabled={!el['startDate']}
												/>
												{/* <Input
														type="date"
														aria-label="endDate"
														min={moment(props.initialDate.startDate).format(
															'YYYY-MM-DD'
														)}
														max={moment(props.initialDate.endDate).format(
															'YYYY-MM-DD'
														)}
														defaultValue={moment(el['endDate']).format(
															'YYYY-MM-DD'
														)}
														onChange={(e) => {
															updateKey(index, e.target.value, 'endDate');
														}}
														className="tdInput"
													/> */}
											</td>
											<td className="text-center">
												{isSaveVisible ? (
													<>
														<CustomButton
															className={'mx-1'}
															content={'Save'}
															permissionType={'C,U'}
															forTable={true}
															permissionSet={userPermissions}
															onClick={() => {
																saveAll();
															}}
														/>
														<CustomButton
															permissionType={'cancel'}
															icon={true}
															forTable={true}
															permissionSet={userPermissions}
															onClick={() => {
																let n = props.classArray;
																if (n[index]['__status'] == '__new') {
																	n.splice(index, 1);
																} else {
																	// n[index]['editMode']=false
																	props.fetchClasses();
																}
																props.setclassArray(n);
																setisSaveVisible(false);
															}}
														/>
													</>
												) : (
													<CustomButton
														permissionType={'D'}
														icon={true}
														forTable={true}
														onClick={() => deleteRow(index)}
														permissionSet={userPermissions}
														disabled={isViewOnly}
													/>
												)}
											</td>
										</tr>
									)
								)
								: null}
						</tbody>
					</Table>
					<Row className="mt-2 pr-3 pt-2 mr-2 floatRight" style={{ marginBottom: '1.6rem' }}>
						{!props.isViewOnly && props.classArray.length < 1 ? (
							<span style={{ position: 'absolute', left: '0', marginLeft: '20px' }}> Please Add Session From Above </span>
						) : null}
						{
							!props.isViewOnly ?
							<CustomButton
								disabled={!props.classArray.length}
								className={`floatRight ml-auto`}
								content={'Close'}
								permissionType={'cancel'}
								forTable={true}
								onClick={() => {
									props.history.push('/admin/termForm');
								}}
								permissionSet={userPermissions}
							/> : null}
					</Row>
				</div>
			</Card>
		</>
	);
};
export default withRouter(TermSessionTable);
