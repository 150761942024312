import { endpoint } from './../../common/constant/endpoints/index';
import { fetchAllPromisedData } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { fetchAllPostPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import { failureToast } from '../../common/utils/methods/toasterFunctions/function';

export const getIdsArray = (dropdownList) => {
  let result = []
  if (Array.isArray(dropdownList)) {
    result = dropdownList.map(item => item.value)
  }
  return result
}

export const getByLabelIDMapping = (selected = [], dropdownsValues = []) => {

  const labels = [];
  dropdownsValues.map((item) => {
    if (selected.includes(item.value))
      labels.push(item);
  });
  return labels
};
export const getDataSetMap = (items) => {
  let result = {}
  items.map(item => result[item.value] = item.label)
  return result;
}


export const BACK_ICON_STYLE = { color: "#0F0F0F", height: "1.2rem", width: "1.4rem", cursor: "pointer", marginRight: '.5rem' }

const getDropdownIds = (items) =>
  items.map(item => (item.value));

// if request any key contain array of object{label:"", value:""} then it will update array of ids 
export const updateRequest = (request) => {
  let temp = { ...request }
  for (const key in request) {
    if (Array.isArray(request[key]) && request[key].length > 0 && request[key][0].label) {
      temp[key] = getDropdownIds(request[key])
    }
    if(request[key].label){
      temp[key] = request[key]['value']
    }
  }
  return temp;
}

export const dropdownMapping = async (url, displayLabel) => {
  const responseData = await fetchAllPromisedData(url, false);
  if (Array.isArray(responseData)) {

    const result = responseData?.map((o) => {
      return { label: o[displayLabel], value: o['id'] }
    })
    return result;
  }
  else return [];
}

export const getBusinessAreaAndRegionDropdowns = async () => {
  const p = await Promise.all([
    dropdownMapping(`${endpoint.businessArea.getAllActive}`, 'businessAreaDispValue'),
    dropdownMapping(`${endpoint.region.getAllActive}`, 'regionDispValue'),

  ])
  return {
    businessAreas: p[0],
    regions: p[1],
  }
};

export const programActionDropdown = [
  { label: "APPL", value: "APPL" },
  { label: "BTCG", value: "BTCG" },
  { label: "CRCG", value: "CRCG" },
  { label: "DISA", value: "DISA" },
  { label: "DISC", value: "DISC" },
  { label: "FOLO", value: "FOLO" },
  { label: "IBTR", value: "IBTR" },
  { label: "MATR", value: "MATR" },
  { label: "RADM", value: "RADM" },
  { label: "STCG", value: "STCG" },
  { label: "MLBA", value: "MLBA" },
]

export const classDropdown = [
  
  
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  
  
]

export const allocationStatusDropdown = [
  { label: "Allocated", value: "ALLOCATE" },
  { label: "Unallocated", value: "DEALLOCATE" },


]

export const getDownloadFileUrl = async (key) => {
  const templateUrl = await fetchAllPostPromisedData(`${endpoint.fetchS3Url.getDownloadUrl}?key=${key}`, {})
  if (templateUrl?.code === 200 && templateUrl?.data) {
    window.open(`${templateUrl?.data?.url}`)
  } else {
    failureToast("Something went wrong please try after sometime.")
  }
}