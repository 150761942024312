import {Button} from "reactstrap";
import React,{Fragment} from "react";
const EditButton =(props)=>{
    const {text='',permissions={update:true,read:true}} = props;
    return (
        permissions.update && permissions.read ?
        <Fragment>
            <Button type="button" color="info" size="sm" {...props}>
                    <i className="fas fa-edit" />{text ? "nbsp;" + text : null}
            </Button>
        </Fragment>
            : null
    )

}
export default EditButton;