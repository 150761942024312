import React from 'react';
import { toast } from 'react-toastify';
import './index.scss';

const SuccessMessage = ({ message }) => (
	<div>
		<i className="fas fa-thumbs-up" />
		<span className="success_toast_msg">{message}</span>
	</div>
);

const FailureMessage = ({ message }) => (
	<div>
		{/* <i className="fas fa-thumbs-up" /> */}
		<i className="fas fa-exclamation" />
		<span className="warn_toast_msg">{message}</span>
	</div>
);

// const successToast = (msg, position) => {
// 	toast.info(msg, {
// 		position: 'bottom-center',
// 		autoClose: 2500,
// 		hideProgressBar: false,
// 		closeOnClick: true,
// 		pauseOnHover: true,
// 		draggable: true,
// 		progress: undefined,
// 	});
// };
// const failureToast = (msg) => {
// 	toast.dismiss();
// 	toast.warn(msg, {
// 		position: 'bottom-center',
// 		autoClose: 2500,
// 		hideProgressBar: false,
// 		closeOnClick: true,
// 		pauseOnHover: true,
// 		draggable: true,
// 		progress: undefined,
// 	});
// };

const successToast = (msg,autoClose = 4500) => {
	toast.info(<SuccessMessage message={msg} />, {
		className: 'success_toast',
		autoClose: autoClose,
		hideProgressBar: false,
		closeOnClick: !!autoClose,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
};

const failureToast = (msg) => {
	toast.dismiss();
	toast.warn(<FailureMessage message={msg} />, {
		className: 'warn_toast',
		autoClose: 4500,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
};

export { failureToast, successToast };
