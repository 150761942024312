import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { failureToast, successToast } from '../../../../../pages/manage/common/utils/methods/toasterFunctions/function';
import { Col, Row } from 'reactstrap';
import { Tooltip, Typography } from '@mui/material';
import CustomButton from '../../../../../../components/CustomButton';
import CommonInput from '../../../common/formFeilds/input/commonInput';
import { BsInfoCircle, BsThreeDotsVertical } from 'react-icons/bs';
import validationRules from './validationRules.json'
import { customValidation } from '../../../common/utils/methods/validations/validation';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../../common/constant';
import CustomLoader from 'views/pages/manage/common/commonComponents/Loader/loader';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Prompt } from 'react-router-dom';
import { ConfirmationDialog } from 'views/pages/manage/common/commonComponents'
import { deleteAPI } from 'services/http';
import './index.scss';
import AddConcessionSlab from '../addConcessionSlab';
import { ConcessionSlabContext } from '..';
import { masterServiceBaseUrl } from 'services/http';
import moment from 'moment';
import { getUniqueElements } from '../../../common/utils/methods/commonMethods/utilityMethod';

const availablityData = [
    { label: 'Manual', value: 'MANUAL' },
    { label: 'Auto', value: 'AUTO' },
    { label: 'Both', value: 'BOTH'}
]

const applicableOnData = [
    { label: 'REGISTRATION_DATE', value: 'REGISTRATION_DATE' },
    { label: 'SYSTEM_DATE', value: 'SYSTEM_DATE' },
]

const ConcessionParameter = [
    { label: 'PERCENTAGE', value: 'PERCENTAGE' },
    { label: 'AMOUNT', value: 'AMOUNT' },
]

const scholarshipCriteriaData = [
    { label: 'RANK', value: 'RANK' },
    { label: 'PERCENTAGE', value: 'PERCENTAGE' },
    { label: 'PERCENTILE', value: 'PERCENTILE' },
    { label: 'GRADES', value: 'GRADES' },
    { label: 'MARKS', value: 'MARKS' },
    { label: 'QUALIFIED', value: 'QUALIFIED' }
];

const FEE_COMPONENTS = [
    { label: 'REG_FEE', value: 'REG_FEE', isDisabled: false },
    { label: 'ADMISSION_FEE', value: 'ADMISSION_FEE', isDisabled: false },
    { label: 'TUITION_FEE', value: 'TUITION_FEE', isDisabled: false },
    { label: 'CLASS_FEE', value: 'CLASS_FEE', isDisabled: false },
    { label: 'TECH_FEE', value: 'TECH_FEE', isDisabled: false },
];

const blankData = {
    parentCategoryId: '',
    subCategoryId: '',
    subSubCategoryId: '',
    subSubCategoryTitle: '',
    subSubCategoryDescription: '',
    status: 'ACTIVE',
    scholarshipRange: '',
    applicabilityMode: '',
    applicableOn: '',
    effectiveDate: '',
    expiryDate: '',
    consessionParameter: '',
    feeComponent: [],
    upload: false,
    masterType: '',
    masterDispVal: ''
};

const ITEM_HEIGHT = 48;

const SubSubCategory = (props) => {
    const history = useHistory();

    const { concessionSlabData, setConcessionSlabData } = useContext(ConcessionSlabContext);

    const { mode, subSubCategoryId, subCategoryId, categoryId, classificationData, getClassifaicationData = () => { }, userPermissions } = props;

    const [isReadOnly, setReadOnly] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [currCategory, setCurrCategory] = useState(null);
    const [currSubCategory, setCurrSubCategory] = useState(null);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    // for mapping and sub mapping
    const [mapping, setMapping] = useState({ fetching: true, data: [] });
    const [subMapping, setSubMapping] = useState({ fetching: true, data: [] });
    const [masterData, setMasterData] = useState({})

    const [values, setValues] = useState(blankData);

    const [saving, setSaving] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const saveHandler = async () => {
        let isValid = true;
        isValid = customValidation(values, validationRules);
        if (!isValid) { return }
        if(values.masterType?.value && !values.masterDispVal?.value){
            failureToast("Please select sub master heading")
            return
        }

        setSaving(true)

        const request = {
            subSubCategoryExternalId: values.subSubCategoryId,
            categoryId: categoryId,
            subCategoryId: subCategoryId,
            subSubCategoryDisplayValue: values.subSubCategoryTitle,
            subSubCategoryDesc: values.subSubCategoryDescription,
            status: values.status,
            effectiveFrom: values.effectiveDate ?? null,
            effectiveTo: values.expiryDate ?? null,
            availability: values.applicabilityMode?.value,
            applicableOn: values.applicableOn?.value,
            concessionParameter: values.consessionParameter?.value,
            scholarshipCriteria: values.scholarshipRange?.value,
            feeComponent: values.feeComponent.map(item => item.value),
            documentUpload: values.upload === "YES" ? true : false,
            masterType: values?.masterType?.value ?? null,
            masterDispVal: values?.masterDispVal?.value ?? null
        }

        try {
            const response = await fetchAllPostPromisedData(
                `${endpoint.subSubCategory}${mode === 'new' ? '' : `/${subSubCategoryId}`}`,
                { ...request },
                mode === 'new' ? "post" : "put"
            );
            setSaving(false);
            if (response && response.code === 200) {
                setIsFormDirty(false);
                if (mode === 'new') {
                    history.push(`/admin/consession/sscs/view/${categoryId}/${subCategoryId}/${response.data.id}`)
                }

                if (mode === 'edit') {
                    history.push(`/admin/consession/sscs/view/${categoryId}/${subCategoryId}/${subSubCategoryId}`)
                }
                getClassifaicationData()
                successToast("Sub sub Category saved successfully!");
            } else {
                failureToast("Details not saved. Please retry again");
            }
        } catch (error) {
            failureToast("Details not saved. Please retry again");
        }
    }

    const getSubMappingData = async (val, cb, recordedData) => {
        try {
            if (val.value && masterData[val.value]) {
                setSubMapping({ fetching: true, data: [] })
                if (masterData[val.value]?.includes(',')) {
                    const o = masterData[val.value]?.split(",")?.map(i => { return { label: i, value: i } });
                    setSubMapping({ fetching: false, data: o })

                    if (recordedData) {
                        const submappingVal = cb ? o.filter(i => i.value == cb)?.[0] ?? '' : '';
                        setValues({ ...recordedData, masterDispVal: submappingVal })
                        setFetching(false)
                    }

                } else {
                    const response = await fetchAllPromisedData(`${endpoint.baseUrl}${masterData[val.value]}`, true)
                    if (response?.code === 200) {
                        let d;
                        if(val.value === 'Loan'){
                            const activeLoanVendors = response?.data?.filter(el => el.status === 'ACTIVE') ?? [];
                            d = getUniqueElements(activeLoanVendors.map(item => ({ label: item.vendorName, value: item.vendorName })), (el) => el.value);
                            setSubMapping({fetching : false , data : d});
                        }else{
                        const key = Object.keys(response?.data?.[0]).filter(i => i.includes('DispValue') || i.includes('DisplayValue') || i.includes('title'));
                         d = response?.data.map(i => { return { label: i?.[key], value: i.id } })
                        setSubMapping({ fetching: false, data: key == 'paymentModeDispValue' ? d.filter(i => i.label?.toLowerCase() !== 'loan') : d })
                        
                    }
                    if (recordedData) {
                        const submappingVal = cb ? d.filter(i => i.value == cb)?.[0] ?? '' : '';
                        setValues({ ...recordedData, masterDispVal: submappingVal })
                        setFetching(false)
                        
                    }
                    } else {
                        setSubMapping({ fetching: true, data: [] })
                        if (recordedData) {
                            setValues({ ...recordedData })
                            setFetching(false)
                        }
                    }
                }
            } else {
                setValues({ ...recordedData })
                setFetching(false)
            }
        } catch (e) {
            setSubMapping({ fetching: true, data: [] })
            if (recordedData) {
                setValues({ ...recordedData })
                setFetching(false)
            }
            console.log(e)
        }
    }


    const getSubSubCategoryDetails = async () => {
        try {

            const response = await fetchAllPromisedData(
                `${endpoint.subSubCategory}/${subSubCategoryId}`,
                true
            );

            if (response && response.code === 200) {
                const recordedData = {
                    id: response.data.id,
                    parentCategoryId: currCategory?.categoryExternalId,
                    subCategoryId: currSubCategory?.subCategoryExternalId,
                    subSubCategoryId: response.data.subSubCategoryExternalId,
                    subSubCategoryTitle: response.data.subSubCategoryDisplayValue,
                    subSubCategoryDescription: response.data.subSubCategoryDesc,
                    status: response.data.status,
                    scholarshipRange: { label: response.data?.scholarshipCriteria, value: response.data?.scholarshipCriteria },
                    applicabilityMode: { label: response.data?.availability, value: response.data?.availability },
                    applicableOn: { label: response.data?.applicableOn, value: response.data?.applicableOn },
                    consessionParameter: { label: response.data?.concessionParameter, value: response.data?.concessionParameter },
                    effectiveDate: response.data.effectiveFrom,
                    expiryDate: response.data.effectiveTo,
                    feeComponent: response.data.feeComponent.map(item => ({ label: item, value: item })),
                    upload: response.data.documentUpload ? "YES" : "NO",
                    masterType: response.data.masterType ? { label: response.data.masterType, value: response.data.masterType } : '',
                    masterDispVal : '',
                    updatedBy : response.data. updatedBy,
                    updatedOn : response.data.updatedOn
                }
                getSubMappingData({ value: response.data.masterType }, response.data.masterDispVal, recordedData)
            } else {
                failureToast("Failed to fetch Data");
            }
        } catch (error) {
            console.log("error", error)
            failureToast("Details not saved. Please retry again");
        }
    }

    useEffect(() => {

        setIsFormDirty(false);
        if ((mode === 'edit' || mode === 'view') && subSubCategoryId && currSubCategory && currCategory && !mapping.fetching) {
            getSubSubCategoryDetails();
            if (mode === 'view') {
                setReadOnly(true);
            }
        }

        if (mode === 'new' && currCategory && currSubCategory) {
            setFetching(false);
            setReadOnly(false);
            // setValues(blankData);
        }

    }, [mode, subSubCategoryId, currSubCategory, currCategory, mapping]);

    useEffect(() => {
        if (classificationData.length) {
            if (categoryId) {
                const currCategory = classificationData?.find(item => item?.id == categoryId);
                setCurrCategory(currCategory);
                setValues(values => ({ ...values, parentCategoryId: currCategory?.categoryExternalId }))
                if (subCategoryId) {
                    const currSubCategory = currCategory?.subCategories?.find(item => item?.id == subCategoryId);
                    setCurrSubCategory(currSubCategory);
                    setValues(values => ({ ...values, subCategoryId: currSubCategory?.subCategoryExternalId }))
                }
            }
        }
    }, [classificationData, categoryId, subCategoryId]);

    const onChange = (key, val) => {
        setValues({ ...values, [key]: val });
        setIsFormDirty(true);
    }

    const deleteSubSubCategory = () => {
        try {
            deleteAPI(
                `${endpoint.subSubCategory}/${subSubCategoryId}`,
                (data) => {
                    setOpenDeleteDialog(false);
                    successToast(data["message"]);
                    history.replace(`/admin/consession`);
                },
                (data) => {
                    failureToast(data["message"]);
                },
                "Delete"
            );
        } catch (e) {
            console.log("Error", e);
        }
    };


    const getMapping = async () => {
        const response = await fetchAllPromisedData(endpoint.getMasterMapping, true)
        if (response?.code === 200) {
            setMasterData(response.data)
            // console.log(Object.keys(response.data).map(i => { return { label: i, value: i } }))
            const data = Object.keys(response.data).map(i => { return { label: i, value: i } })
            setMapping({ fetching: false, data: data })
        } else {
            setMasterData({})
            setMapping({ fetching: false, data: [] })
        }
    }

    useEffect(() => {
        getMapping()
    }, [])



    return (
        fetching ? (
            <div className="col">
                <div
                    className="mx-auto text-center py-5 my-5 "
                    style={{ height: '100%' }}
                >
                    <CustomLoader apiLoader={fetching} />
                </div>

            </div>
        ) :
            <div className='sub-sub-category-module'>
                <Prompt when={(mode === 'edit' || mode === "new") && isFormDirty} message={"All information will be lost. Are you sure you want to leave this page?"} />
                <div className='d-flex justify-content-between'>
                    <Typography style={{ fontSize: 18, color: 'black', marginBottom: 25 }}>{`${currCategory?.categoryCode}/${currCategory?.categoryDispVal} / ${currSubCategory?.subCategoryDispVal} / ${mode === 'new' ? 'Sub sub category' : values.subSubCategoryTitle}`}</Typography>
                    {mode === 'view' && <div>
                    <Tooltip title="Menu" placement="top-start">
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <BsThreeDotsVertical />
                        </IconButton>
                        </Tooltip>
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '7ch',
                                },
                            }}
                        >
                            <MenuItem key={'Edit'} onClick={() => { setReadOnly(false); handleClose(); history.push(`/admin/consession/sscs/edit/${categoryId}/${subCategoryId}/${subSubCategoryId}`) }}>
                                Edit
                            </MenuItem>
                            {/* <MenuItem key={'Delete'} className="text-danger" onClick={() => { handleClose(); setOpenDeleteDialog(true); }}>
                                Delete
                            </MenuItem> */}
                        </Menu>
                    </div>}
                </div>
                <Row>
                    <Col md="3">
                        <CommonInput
                            type="number"
                            mandatory={true}
                            defaultValue={values.parentCategoryId}
                            onChange={(val) => { onChange("parentCategoryId", val) }}
                            label="Parent category ID"
                            readOnly={isReadOnly}
                            isDisabled={true}
                        />
                    </Col>
                    <Col md="3">
                        <CommonInput
                            type="number"
                            mandatory={true}
                            defaultValue={values.subCategoryId}
                            onChange={(val) => { onChange("subCategoryId", val) }}
                            label="Sub category ID"
                            readOnly={isReadOnly}
                            isDisabled={true}
                        />
                    </Col>
                    <Col md="3">
                        <CommonInput
                            type="number"
                            mandatory={true}
                            defaultValue={values.subSubCategoryId}
                            onChange={(val) => { onChange("subSubCategoryId", val) }}
                            label="Sub sub category ID"
                            readOnly={isReadOnly}
                        />
                    </Col>
                    <Col md="3" className={`d-flex align-item-center ${mode !== 'view' ? 'sub-sub-cat-toggle' : 'sub-sub-cat-toggle-ml0 '}`}>
                        <CommonInput
                            type="toggleV3"
                            dataLabelOff='INACTIVE'
                            dataLabelOn='ACTIVE'
                            defaultValue={values.status}
                            placeHolder="Enter status"
                            onChange={(val) => { onChange("status", val) }}
                            label="Status"
                            readOnly={isReadOnly}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="3">
                        <CommonInput
                            type="text"
                            mandatory={true}
                            defaultValue={values.subSubCategoryTitle}
                            onChange={(val) => { onChange("subSubCategoryTitle", val) }}
                            placeHolder="Enter sub-sub-category title"
                            label="Sub sub category title"
                            readOnly={isReadOnly}
                        />
                    </Col>
                    <Col md="8">
                        <CommonInput
                            type="text"
                            mandatory={true}
                            defaultValue={values.subSubCategoryDescription}
                            placeHolder="Enter sub-sub-category description"
                            onChange={(val) => { onChange("subSubCategoryDescription", val) }}
                            label="Description"
                            readOnly={isReadOnly}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="3">
                        <CommonInput
                            type="select"
                            mandatory={true}
                            label="Scholarship range/Criteria"
                            defaultValue={values.scholarshipRange}
                            onChange={(val) => { onChange("scholarshipRange", val) }}
                            data={scholarshipCriteriaData}
                            readOnly={isReadOnly}
                        />
                    </Col>
                    <Col md="3">
                        <CommonInput
                            type="select"
                            mandatory={true}
                            defaultValue={values.applicabilityMode}
                            onChange={(val) => { onChange("applicabilityMode", val) }}
                            label="Applicability mode"
                            data={availablityData}
                            readOnly={isReadOnly}
                        />
                    </Col>
                    <Col md="3">
                        <CommonInput
                            type="select"
                            mandatory={true}
                            defaultValue={values.applicableOn}
                            onChange={(val) => { onChange("applicableOn", val) }}
                            label="Applicable on"
                            data={applicableOnData}
                            readOnly={isReadOnly}
                        />
                    </Col>
                    <Col md="3">
                        <CommonInput
                            type="select"
                            mandatory={true}
                            defaultValue={values.consessionParameter}
                            onChange={(val) => { onChange("consessionParameter", val) }}
                            label="Consession Parameter"
                            data={ConcessionParameter}
                            readOnly={isReadOnly}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="3">
                        <CommonInput
                            type="datePicker"
                            // mandatory={true}
                            label="Effective date"
                            defaultValue={values.effectiveDate}
                            onChange={(val) => { onChange("effectiveDate", val) }}
                            readOnly={isReadOnly}
                            placeHolder=" "
                            minDate={new Date(currSubCategory.effectiveFrom) > new Date().setHours(0, 0, 0, 0) ? new Date(currSubCategory.effectiveFrom) : new Date().setHours(0, 0, 0, 0)}
                            maxDate={currSubCategory.effectiveTo}
                        />
                    </Col>
                    <Col md="3">
                        <CommonInput
                            type="datePicker"
                            defaultValue={values.expiryDate}
                            onChange={(val) => { onChange("expiryDate", val) }}
                            label="Expiry date"
                            readOnly={isReadOnly}
                            placeHolder=" "
                            minDate={new Date(currSubCategory.effectiveFrom) > new Date().setHours(0, 0, 0, 0) ? new Date(currSubCategory.effectiveFrom) : new Date().setHours(0, 0, 0, 0)}
                            maxDate={currSubCategory.effectiveTo}
                        />
                    </Col>
                    <Col md="3">
                        <CommonInput
                            type={'multiSelectWithCheckboxes'}
                            label="Fee Components"
                            defaultValue={values.feeComponent}
                            data={FEE_COMPONENTS.map(item => ({ ...item, isDisabled: mode === 'view' || (mode === 'edit' && concessionSlabData?.length > 0) }))}
                            onChange={(value) => {
                                onChange("feeComponent", value);
                            }}
                            isDisabled={isReadOnly}
                        />
                    </Col>
                    <Col md="3" className={`d-flex align-item-center ${mode !== 'view' ? 'sub-cat-toggle' : 'sub-cat-toggle-ml0'}`}>
                        <CommonInput
                            type="toggleV3"
                            dataLabelOff='NO'
                            dataLabelOn='YES'
                            defaultValue={values?.upload}
                            // placeHolder="Enter status"
                            onChange={(val) => { onChange("upload", val) }}
                            label="Document Upload"
                            readOnly={isReadOnly}
                            secondaryLabel="Mandatory"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="3">
                        <CommonInput
                            type="select"
                            // mandatory={true}
                            label="Master mapping"
                            defaultValue={values.masterType || '-'}
                            onChange={(val) => { 
                                setValues({...values, masterType: val,masterDispVal:''})
                                if(val?.value){
                                    getSubMappingData(val) 
                                }
                                
                            }}
                            data={mapping.data}
                            placeHolder="Select"
                            readOnly={isReadOnly}
                            loading={mapping.fetching}
                            clearable={true}
                        />
                    </Col>
                    <Col md="3">
                        <CommonInput
                            type="select"
                            // mandatory={true}
                            label="Sub master mapping"
                            defaultValue={values.masterDispVal || '-'}
                            onChange={(val) => { onChange("masterDispVal", val) }}
                            readOnly={isReadOnly}
                            placeHolder="Select"
                            loading={subMapping.fetching}
                            data={values.masterType ? subMapping.data :[]}
                            clearable={true}
                        />
                    </Col>

                </Row>



                {(mode === 'new' || mode === 'edit') && <Row className="d-flex align-items-center justify-content-between">
                    <div><CustomButton
                        className="px-5"
                        content={saving ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : "Save"}
                        permissionType={'C,U'}
                        permissionSet={userPermissions}
                        onClick={saveHandler}
                    />
                        <span className='ml-3'>
                            <BsInfoCircle className='mr-1' />
                            <span> * Fields are mandatory</span>
                        </span>
                    </div>
                    {mode === 'edit' && <CustomButton
                        className="px-5"
                        content={'Cancel'}
                        permissionType={'U'}
                        permissionSet={userPermissions}
                        onClick={() => { history.push(`/admin/consession/sscs/view/${categoryId}/${subCategoryId}/${subSubCategoryId}`) }}
                    />}

                </Row>

                }
                 {mode !== 'new' && <Row className='mt-2'>
                <Col md={12}>
                 <h5 className='float-right'>Last updated by {values?.updatedBy} at {moment(values?.updatedOn).format("hh:mm a")} on {moment(values?.updatedOn).format('DD/MM/YYYY')}.</h5>
                </Col>
                </Row>}
                {mode === 'view' && values?.id && <AddConcessionSlab category={currCategory} subCategory={currSubCategory} subSubCategory={values} forSubSubCategory={true} />}
                <ConfirmationDialog
                    isOpen={openDeleteDialog}
                    onAgree={() => { deleteSubSubCategory() }}
                    setIsOpen={setOpenDeleteDialog}
                    headerMsg={`Are you sure you want to delete ${values?.subSubCategoryTitle}?`}
                    msg={'This sub sub category will be deleted permanently.'}
                />
            </div>
    )
}

export default SubSubCategory;