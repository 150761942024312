import { isFirstDayOfMonth } from "date-fns/esm";
import { useState, useEffect } from "react";
import {
  getCoursesByPackageId,
  getSyllabusByCourseId,
  getTestTypes,
  formDataSet,
} from "./style";
import { endpoint } from "../../common/constant";
import {
  fetchAllPostPromisedData,
  fetchAllPromisedData,
} from "../../common/utils/methods/commonMethods/utilityMethod";

const updateToDropDownFormate = (testTypes) => {
  if (Array.isArray(testTypes))
    return testTypes.map((type) => ({
      value: type.testTypeId,
      label: type.testType,
    }));
  return [];
};

export function useFetchTestTypes() {
  const [testTypes, setTestTypes] = useState([]);
  useEffect(() => {
    getTestTypes().then((res) => setTestTypes(updateToDropDownFormate(res)));
  }, []);

  return testTypes;
}
const getCoursesArray = (response) => response.map((item) => item.subjectName);

export function useGetCoursesByPackageId(
  packageId,
  setCurrentSelected,
  setFormModal,
  academicPlan
) {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    if (academicPlan?.isImportedTest) {
      // fetch  its own package id
      fetchAcademicPlan(academicPlan);
    } else {
      fetchCourseIds(packageId);
    }
  }, [academicPlan?.academicPlanId]);

  const fetchCourseIds = (packageId) => {
    getCoursesByPackageId(packageId).then((res) => {
      if (res?.data) {
        let courses = res.data?.packages[0]?.courses;
        setCourses(courses);
        if (courses) {
          if (setCurrentSelected) {
            setCurrentSelected(courses[0]);
            let tempFormModal = {};
            courses.forEach((item) => {
              tempFormModal[item["subjectName"]] = [formDataSet];
            });
            setFormModal(tempFormModal);
          }
        }
      } else {
      }
    });
  };
  const fetchAcademicPlan = async ({ academicPlanId, testName }) => {
    const response = await fetchAllPromisedData(
      `${endpoint.viewAcademicPlan.getPlanById}/${academicPlanId}?requireDispValue=false`,
      true
    );
    if (response?.data) {
      fetchCourseIds(response?.data?.packageId);
    } 
  };
  return [courses, setCourses];
}

export const getDropdownModal = (item, isChildren = false) => {
  if (isChildren) {
    return {
      key: item.key,
      value: item.key,
      label: item.title,
      parentNode: item.parentNode,
      children: item.children,
    };
  } else {
    return {
      key: item.key,
      value: item.key,
      label: item.title,
      parentNode: item.parentNode,
    };
  }
};
const formateCourseRequest = (request) => {
  let chapter = [];
  let mapDropdown = {};

  request.forEach((item) => {
    chapter.push(getDropdownModal(item));
    mapDropdown[item.key] = item.children;
  });
  return { chapter, allData: mapDropdown };
};

export const formateIntoDropdown = (request, isChildren = false) => {
  if (Array.isArray(request))
    return request.map((item) => getDropdownModal(item, isChildren));
  return [];
};

export function useGetSyllabusByCourseId(courseId, setLoading) {
  const [dropdown, setDropdown] = useState({});
  useEffect(() => {
    if (courseId) {
      if (setLoading) setLoading(true);
      getSyllabusByCourseId(courseId).then((res) => {
        res && setDropdown(formateCourseRequest(res));
        if (setLoading) setLoading(false);
      });
    }
  }, [courseId]);

  return dropdown;
}
