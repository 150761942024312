import React, { useState, useEffect } from 'react';
import {
	Container,
	Card,
	CardHeader,
	CardBody,
	Row,
	Col,
	Button,
} from 'reactstrap';
import {
	fetchAllData,
	fetchDetails,
} from '../../../common/utils/methods/commonMethods/utilityMethod';
import CustomLoader from '../../../common/commonComponents/Loader/loader';
import HeaderInformation from './headerInformation';
import {
	putpost,
	masterServiceBaseUrl,
	successToast,
	failureToast,
} from 'services/http';
import { useHistory, useParams } from 'react-router-dom';

const AddNewBank = () => {
	let history = useHistory();
	const { id, action } = useParams();
	const [apiLoader, setApiLoader] = useState(false);
	const [country, setCountry] = useState([]);
	const [actionDisabled, setActionDisabled] = useState(false);
	const [status, setStatus] = useState([
		{ id: 'ACTIVE', text: 'ACTIVE' },
		{ id: 'INACTIVE', text: 'INACTIVE' },
	]);

	const [bankDetails, setBankDetails] = useState({
		countryCode: '',
		bankCode: '',
		alternateBankCode: '',
		bankName: '',
		effectiveDate: '',
		status: 'ACTIVE',
	});

	useEffect(() => {
		try {
			if (action) {
				action === 'view' ? setActionDisabled(true) : setActionDisabled(false);
				fetchAllData(
					masterServiceBaseUrl + `/bank/getBankById/${id}`,
					setBankDetails
				);
			}
			fetchDetails(
				masterServiceBaseUrl + '/country/getAllActiveCountry',
				setCountry,
				'country',
				'reactSelect'
			);
		} catch (e) {
			console.log('Error', e);
		}
	}, []);

	const submitDataHandler = (value, property) => {
		try {
			setBankDetails((preState) => ({
				...preState,
				[property]: value,
			}));
		} catch (e) {
			console.log('Error', e);
		}
	};

	const saveHandler = () => {
		try {
			if (!bankDetails['countryCode'])
				return failureToast('Select Country Code');
			if (!bankDetails['status']) return failureToast('Select Status');
			if (!bankDetails['bankCode']) return failureToast('Enter Bank Code');
			if (!bankDetails['alternateBankCode'])
				return failureToast('Enter Alternate Bank Code');
			if (!bankDetails['bankName']) return failureToast('Enter Bank Name');

			if (action === 'edit') {
				setApiLoader(true);
				putpost(
					masterServiceBaseUrl + `/bank/updateBank/${bankDetails['id']}`,
					(data) => {
						successToast(data['message']);
						setApiLoader(false);
						history.push('/admin/searchBank');
					},
					(data) => {
						setApiLoader(false);
						failureToast(data['message']);
					},
					bankDetails,
					'put'
				);
			} else {
				setApiLoader(true);
				putpost(
					`${masterServiceBaseUrl}/bank/createBank`,
					(data) => {
						successToast('Bank Code Created successfully');
						setApiLoader(false);
						history.push('/admin/searchBank');
					},
					(data) => {
						setApiLoader(false);
						failureToast(data['message']);
					},
					bankDetails,
					'post'
				);
			}
		} catch (e) {
			console.log('Error', e);
		}
	};

	return (
		<Container className="mt-3" fluid>
			<Card>
				{apiLoader ? (
					<CustomLoader
						apiLoader={apiLoader}
						loaderHeight={'200px'}
						loaderWidth={'100% '}
					/>
				) : (
					<div>
						<CardHeader>
							<h3>Bank Code</h3>
						</CardHeader>
						<hr />
						<CardBody>
							<HeaderInformation
								action={action}
								bankDetails={bankDetails}
								country={country}
								status={status}
								submitDataHandler={submitDataHandler}
								actionDisabled={actionDisabled}
							/>
							<Row>
								<Col>
									{!(action === 'view') ? (
										<Button
											data-testid="saveBtnHandler"
											size="md"
											type="button"
											color="info"
											className="floatRight"
											onClick={() => saveHandler()}
										>
											{action === 'edit' ? 'Update' : 'Save'}
										</Button>
									) : (
										<Button
											size="md"
											type="button"
											color="info"
											className="floatRight"
											onClick={() => {
												history.push('/admin/searchBank');
											}}
										>
											Close
										</Button>
									)}
								</Col>
							</Row>
						</CardBody>
					</div>
				)}
			</Card>
		</Container>
	);
};

export default AddNewBank;
