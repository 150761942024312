import React, { useRef, useState } from "react";
import { Card, CardBody, Row } from "reactstrap";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import UploadAttachments from "./UploadAttachments";
import { failureToast } from "../../../../common/utils/methods/toasterFunctions/function";
import AddMaterialTableV2 from "./AddMaterialTableV2";


const SelectMaterialForm = ({
  selectedMaterials,
  setSelectedMaterials,
  form,
  setFormHandler,
  issueStockHandler,
  loading,
  setLoading,
  isAllTransactionDetailsFilled,
  isDlp,
}) => {
  const listRef = useRef();
  // console.log({ isAllTransactionDetailsFilled });
  const isMaterialSelected = () => {
    if (selectedMaterials.length === 0) return true;
    if (selectedMaterials.length > 0) {
      const material = selectedMaterials[0];
      if (!material?.materialId || !material?.quantity) return true;
      return false;
    }
  };

  const saveRowHandler = (data, barCodes = [], index) => {
    const tempRows = [...selectedMaterials];
    const { id, materialId, qty, availableCount } = data;
    const barcodeList = barCodes.slice(0, qty);
    tempRows[index] = {
      ...materialId,
      id,
      barcodeList,
      quantity: qty,
      availableCount,
      qty: qty,
      openForm: false,
    };
    setSelectedMaterials(tempRows);
  };

  const updateRowHandler = (data, barCodes = []) => {
    const { id, materialId, quantity, availableCount } = data;
    const barcodeList = barCodes.slice(0, quantity);
    // find material id index
    const index = selectedMaterials.findIndex(
      (item) => item?.materialId === materialId
    );
    if (index === -1) return false;
    const tempMaterials = [...selectedMaterials];
    tempMaterials[index] = {
      ...materialId,
      id,
      barcodeList,
      quantity,
      qty: barCodes.length,
      availableCount,
    };
    setSelectedMaterials(tempMaterials);
  };

  const deleteHandler = (rowData) => {
    setSelectedMaterials(
      selectedMaterials.filter(
        (item) => item?.materialId !== rowData?.materialId
      )
    );
  };
  const isAnyFormOpen = () => {
    const isAnyFormOpen = selectedMaterials.some((item) => item?.openForm);
    if (isAnyFormOpen) {
      failureToast("Please save current open form");
    }
    return isAnyFormOpen;
  };
  const addNewRowHandler = () => {
    if (form?.businessArea === "")
      return failureToast(
        "Please select business area and other details first"
      );

    if (isAnyFormOpen()) return;

    setSelectedMaterials((prev) => [
      {
        openForm: true,
        qty: "",
        materialId: "",
      },
      ...prev,
    ]);
  };

  const updateBarcodeListForMaterial = (materialRowIndex, newBarcodeList) => {
    // newBarcodeList will be of the form [{barcode: 123, isIssued: false}]
    const updatedMaterials = [...selectedMaterials];
    updatedMaterials[materialRowIndex].barcodeList = newBarcodeList;
    setSelectedMaterials(updatedMaterials);
  }

  return (
    <>
      <Card className="m-3 border-radius-lg">
        <CardBody>
          <div className="d-flex align-items-center justify-content-between">
            <span className="heading-4 color-dark">
              Select material that you want to issue
            </span>
            <AButton
              onClick={addNewRowHandler}
              size="xs"
              variant="primary_filled"
              disabled={!isAllTransactionDetailsFilled}
            >
              Add more material
            </AButton>
          </div>
          <div className="table-border-material">
            <AddMaterialTableV2
              rows={selectedMaterials}
              setRows={setSelectedMaterials}
              saveRowHandler={saveRowHandler}
              ref={listRef}
              businessArea={form?.businessArea}
              deleteHandler={deleteHandler}
              updateRowHandler={updateRowHandler}
              loading={loading}
              setLoading={setLoading}
              updateBarcodeListForMaterial={updateBarcodeListForMaterial}
              employeeDetailsForm={form}
            />
          </div>
          <div className="d-flex">
            <div
              className="heading-4 color-dark mb-2"
              style={{ fontSize: "16px" }}
            >
              {isDlp ? "Approval & Supporting Documents*" : "Attachment*"}
            </div>
          </div>
          <div className="d-flex align-items-center">
            <UploadAttachments
              disabledBtn={!isAllTransactionDetailsFilled}
              uploadFileHeader={
                isDlp
                  ? "Upload Approval & Supporting Documents"
                  : "Upload supporting document"
              }
              form={form}
              setFormHandler={setFormHandler}
              uploadButtonProps={{ className: "ml-0 mb-3" }}
            />
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <AButton
              className="ml-0"
              variant="primary_filled"
              onClick={issueStockHandler}
              disabled={isMaterialSelected(selectedMaterials)}
            >
              Issue Stock
            </AButton>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default SelectMaterialForm;
