import React, { useState, useEffect } from "react";
import { failureToast } from "../common/utils/methods/toasterFunctions/function";
import ALoader from "views/pages/manage/common/a-loader";
import ManualAttendanceListHeader from "./ManualAttendaceListHeader";
import ManualAttendanceForm from "./ManualAttendanceForm";
import {
  fetchAllPostPromisedData,
} from "../common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "../common/constant";
import moment from "moment";
import ManualAttendanceTableContainer from "./ManualAttendanceTableContainer";
import {
  useFetchDropdown,
} from "../edpOps/issuanceIDcard/customhooks";
import {
  FILTER__FORM_FIELDS_GLOBAL,
} from "../edpOps/issuanceIDcard/contextApi";
const ManualAttendanceIndex = () => {

  const nextButtonHandler = (newPage) => {
    setCurrentPage(newPage);
    fetchFilterData(newPage);
  };
  const [rowCount, setRowCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [serverData, setServerData] = useState(null);
  const [batchesLoading, setBatchesLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [toggleCollapse, setToggleCollapse] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [batchesData, setBatchData] = useState([]);
  const [filterSwitch, setfilterSwitch] = useState({
    all: true,
    filtered: false,
  });
  const [errorFields, setErrorFields] = useState({
    businessAreaErr: false,
    termErr: false,
    dateErr: false,
  });
  const initialValues = {
    psid: "",
    batch: "",
    term: "",
    date1: "",
    courseId: "",
    region: "",
    businessArea: "",
    date: "",
    punchTime: "",
    remarks: "",
    applicationId: "",
    studentName: "",
  };
  const [values, setValues] = useState(initialValues);
  const [data, setData] = useState([
    {
      termData: [],
      regionData: [],
      businessAreaData: [],
      courseIdData: [],
      batchesData: [],
    },
  ]);
  const [dropdownData, isDropdownLoader] = useFetchDropdown();
  const [globalFilter, setGlobalFilter] = useState({
    ...FILTER__FORM_FIELDS_GLOBAL,
    date1: "",
  });
  const [isFlow1, setIsFlow1] = useState(false);

  const fetchFilterData = async (page = 0) => {
    setIsFlow1(false);
    if (!page) setCurrentPage(0);
    let ret = [];
    let searchData = {};
    try {
      setIsFetching(true);
      let finalPayload = {
        BUSINESS_AREA:
          values.businessArea &&
          values.businessArea.map((val) => val.value).toString(),
        TERM: values.term && values.term.map((val) => val.value),
        batchId: values.batch ? values.batch.map((val) => val.value) : [],
        date: globalFilter.date1
          ? moment(globalFilter.date1).format("YYYY-MM-DD")
          : values.date
          ? moment(values.date).format("YYYY-MM-DD")
          : "",
      };

      for (var key in finalPayload) {
        if (finalPayload.hasOwnProperty(key)) {
          if (finalPayload[key] != null) {
            ret.push(
              encodeURIComponent(key) +
                "=" +
                encodeURIComponent(finalPayload[key])
            );
          }
        }
      }
      if (ret.length > 0) {
        searchData = await fetchAllPostPromisedData(
          `${endpoint.attendanceDetails.getAll}?offset=${page}`,
          finalPayload,
          "post"
        );
      }
      if (searchData?.code === 200) {
        const resList = searchData?.data?.attendanceResponses || [];
        resList.map((dataVal, index) => {
          resList[index].term = dropdownData?.terms
            .filter((val) => val.value == dataVal.term)
            .map((val) => val.label);
          const batchName =
            data.batchesData.length > 0 &&
            data.batchesData
              .filter((val) => val.value == dataVal?.secondaryBatchId)
              .map((val) => val.label)
              .toString();
          resList[index]["batchName"] = batchName;
        });
        setTableData(resList);
        setFilterData(resList);
        setRowCount(searchData?.data?.totalRecords);
        setToggleCollapse(false);
        setIsFetching(false);
      } else {
        setIsFetching(false);
        setTableData([]);
        setFilterData([]);
        failureToast(searchData["message"]);
      }
    } catch (e) {
      failureToast("Something Went Wrong. Please try later");
      setIsFetching(false);
    }
  };
  const fetchFilter = async () => {
    setIsFlow1(true);
    let ret = [];
    let searchData = {};
    try {
      setIsFetching(true);
      let finalPayload = {
        psid: globalFilter.psid && globalFilter.psid.toString(),
        date: globalFilter.date1
          ? moment(globalFilter.date1).format("YYYY-MM-DD")
          : values.date
          ? moment(values.date).format("YYYY-MM-DD")
          : "",
        applicationId:
          globalFilter.applicationId && globalFilter.applicationId.toString(),
      };

      for (var key in finalPayload) {
        if (finalPayload.hasOwnProperty(key)) {
          if (finalPayload[key] != null) {
            ret.push(
              encodeURIComponent(key) +
                "=" +
                encodeURIComponent(finalPayload[key])
            );
          }
        }
      }
      if (ret.length > 0) {
        searchData = await fetchAllPostPromisedData(
          `${endpoint.attendanceDetails.getAll}/search`,
          finalPayload,
          "post"
        );
      }
      if (searchData?.code === 200) {
        searchData.data.map((dataVal, index) => {
          searchData.data[index].term = dropdownData?.terms
            .filter((val) => val.value == dataVal.term)
            .map((val) => val.label);
          const batchName =
            batchesData?.find(
              (item) => item?.value == dataVal?.secondaryBatchId
            )?.label || "-";
          searchData.data[index]["batchName"] = batchName;
        });
        setTableData(searchData.data);
        setFilterData(searchData.data);
        setToggleCollapse(false);
        setIsFetching(false);
      } else {
        setIsFetching(false);
        setTableData([]);
        setFilterData([]);
        failureToast(searchData["message"]);
      }
    } catch (e) {
      failureToast("Something Went Wrong. Please try later");
      setIsFetching(false);
    }
  };
  const fetchAllBatches = async () => {
    setBatchesLoading(true);
    const res = await fetchDetailsPost(
      `${endpoint.batchList.fetchAllBatches}=-1`,
      { batchType: "SECONDARY"},
      "post",
      "batchesDispValue"
    );
    setBatchData(res);
    setBatchesLoading(false);
  };
  useEffect(() => {
    fetchAllBatches();
  }, []);
  const fetchDetailsPost = async (url, requestObject, method, dispLabel) => {
    const businessAreaSearchedData = await fetchAllPostPromisedData(
      url,
      requestObject,
      method
    );
    if (
      businessAreaSearchedData?.code == 200 &&
      businessAreaSearchedData.data.batchSearchResponseDTOList
    ) {
      let batchList = businessAreaSearchedData.data.batchSearchResponseDTOList;
      const result = batchList.map((o) => {
        return { label: o["batchName"], value: o["id"] };
      });
      return result;
    } else if (
      businessAreaSearchedData?.code === 200 &&
      Array.isArray(businessAreaSearchedData?.data)
    ) {
      const result = businessAreaSearchedData.data?.map((o) => {
        return { label: o[dispLabel], value: o["id"] };
      });
      return result;
    } else return [];
  };

  return (
    <>
      <div className="d-flex flex-column remove-container-top-space">
        <div className="p-3 student-filer-container-printId">
          {batchesLoading && <ALoader position={"fixed"} />}
          <ManualAttendanceListHeader />
          <ManualAttendanceForm
            errorFields={errorFields}
            setErrorFields={setErrorFields}
            values={values}
            setValues={setValues}
            data={data}
            setData={setData}
            serverData={serverData}
            setServerData={setServerData}
            isFetching={isFetching}
            setIsFetching={setIsFetching}
            toggleCollapse={toggleCollapse}
            filterData={filterData}
            setFilterData={setFilterData}
            tableData={tableData}
            setTableData={setTableData}
            fetchFilterData={fetchFilterData}
            filterSwitch={filterSwitch}
            setfilterSwitch={setfilterSwitch}
            dropdownData={dropdownData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            fetchFilter={fetchFilter}
            isFlow1={isFlow1}
            setIsFlow1={setIsFlow1}
            fetchDetailsPost={fetchDetailsPost}
          />
        </div>
      </div>
      <ManualAttendanceTableContainer
        errorFields={errorFields}
        setErrorFields={setErrorFields}
        values={values}
        setValues={setValues}
        data={data}
        setData={setData}
        serverData={serverData}
        setServerData={setServerData}
        isFetching={isFetching}
        setIsFetching={setIsFetching}
        toggleCollapse={toggleCollapse}
        filterData={filterData}
        setFilterData={setFilterData}
        tableData={tableData}
        setTableData={setTableData}
        fetchFilterData={fetchFilterData}
        filterSwitch={filterSwitch}
        setfilterSwitch={setfilterSwitch}
        isFlow1={isFlow1}
        setIsFlow1={setIsFlow1}
        fetchFilter={fetchFilter}
        globalFilter={globalFilter}
        currentPage={currentPage}
        nextButtonHandler={nextButtonHandler}
        rowCount={rowCount}
      />
    </>
  );
};

export default ManualAttendanceIndex;
