import React from 'react';
import Style from './popup.module.scss';
import { Modal, ModalBody, Button } from 'reactstrap';

const ConfirmationDeleteDialog = ({ isOpen, onAgree = () => { }, onDisagree = () => { }, setIsOpen = () => { }, msg, headerMsg, popupSymbol, type, headerStyle }) => {
    return (
        <Modal isOpen={isOpen} className={Style.mode_container} centered zIndex={2000}>
            <ModalBody className={Style.secondary_body_content}>
                <div className={Style.secondary_icon}>{'?'}</div>
                <p className={`${Style.secondary_confirmation_message}`}>{headerMsg ?? 'Are you sure?'}</p>
                <p className={Style.secondary_signOut_message}>{msg ?? 'All your changes will be lost'}</p>
                <div>
                    <Button className={Style.btn_delete_no} onClick={() => { setIsOpen(false); onDisagree(); }}>
                        NO
                    </Button>
                    <Button className={Style.btn_delete_yes} onClick={() => onAgree()}>
                        Yes, Delete
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ConfirmationDeleteDialog;

