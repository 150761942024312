import React from 'react'
import { Col } from 'reactstrap'
import CommonInput from '../common/formFeilds/input/commonInput'

export const DocumentUpload = ({
    values,
    setValues,
}) => {
    return (
        <div style={{
            border: '1px solid #EDEDED', padding: '16px 24px',
            borderRadius: '12px', marginTop: 10
        }}>
            <div className='semi-bold label-color'>3. Document upload</div>

            <div className='d-flex justify-content-between pt-2'>
                <Col className="pl-0">
                    <CommonInput
                       type={"number"}
                       allowDecimalvalue={true}
                       mandatory={true}
                       defaultValue={values.documentUploadPercentCriteria}
                       placeHolder="Enter"
                       label={"Concessions limit for mandatory document Upload."}
                    //    onChange={(val) => setValues({...values, documentUploadPercentCriteria: val})}
                       onChange={value => {
                        if (value > 100) {
                          return;
                        } else {
                            setValues({ ...values, documentUploadPercentCriteria: value })
                        }
                      }}
                      adornmentText="%"
                    />
                </Col>
                <Col />
                <Col/>
            </div>
        </div>
    )
}
