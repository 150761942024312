import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import SeatingCapacityOfOrganizationTable from './seatingCapacityOfOrganizationTable';
import {getAPI,putpost,masterServiceBaseUrl,successToast,failureToast} from 'services/http';
import Loader from 'react-loader-spinner'

 const SeatingCapacityOfOrganization = (props) => {
  const {formMode,setExternalOrganzationObj,externalOrganzationObj ,enableEditMode} = props;
  const [apiloader, setapiloader] = useState(false)
  const [ editMode, setEditMode ] = useState( {
    editMode: undefined,
    index:""
  } ) 

  return (
    <>
     {apiloader ? <div className="mx-auto text-center py-5 my-5" style={{ height: "100vh" }}><Loader
        type="Rings"
        color="#00BFFF"
        height={70}
        width={70}
        timeout={10000} /></div> : <div></div>}
      <SeatingCapacityOfOrganizationTable
        setEditMode={ setEditMode } editMode={ editMode }
        setExternalOrganzationObj={ setExternalOrganzationObj } externalOrganzationObj={ externalOrganzationObj } formMode={ formMode } enableEditMode={ enableEditMode}
       />
     
 </>
  );
}

export default SeatingCapacityOfOrganization;