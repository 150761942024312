import React from 'react';
import {
  gridComponents,
  gridStyles,
} from '../../issuanceIDcard/printIdCardAndMapRfid/constant';
import NoRowOverlay from '../../../masters/holidayMaster/NoRowOverlay';
import ADataGrid from '../../../common/data-grid/ADataGrid';
import { AGridColDef } from '../../../common/data-grid/ADataGridColDef';
import MaterialDetailsCellComponent from './MaterialDetailsCellComponent';
import CustomTooltip from '../../../common/customTooltip/CustomTooltip';

const StockReversalTable = ({ rows }) => {
  const columns = [
    new AGridColDef('psid', 'PSID', false).renderCellComponent(params => (
      <CustomTooltip title={params?.value}>
        <div className='ellipsis-text-container'>{params?.value}</div>
      </CustomTooltip>
    )),
    new AGridColDef('studentName', 'Name', false)
      .setFlex(1.5)
      .renderCellComponent(params => (
        <CustomTooltip title={params?.value}>
          <div className='ellipsis-text-container'>{params?.value}</div>
        </CustomTooltip>
      )),
    new AGridColDef(
      'applicationId',
      'Application no.',
      false
    ).renderCellComponent(params => (
      <CustomTooltip title={params?.value}>
        <div className='ellipsis-text-container'>{params?.value}</div>
      </CustomTooltip>
    )),
    new AGridColDef('courseId', 'Course ID', false),
    new AGridColDef('termValue', 'Term', false),
    new AGridColDef(
      'materialDetails',
      'Material details',
      false
    ).renderCellComponent(params => (
      <MaterialDetailsCellComponent rows={params?.value} />
    )),
  ];

  return (
    <ADataGrid
      removeWrapperContainer
      rows={rows || []}
      columns={columns}
      rowId={row => JSON.stringify(row)}
      rowHeight={50}
      loading={false}
      serverPagination={false}
      components={{
        ...gridComponents,
        NoRowsOverlay: () => <NoRowOverlay />,
      }}
      hidePagination={rows?.length <= 50 ? true : false}
      sx={{
        ...gridStyles,
      }}
      autoHeight
    />
  );
};

export default StockReversalTable;
