import React, {Fragment} from 'react';

const CustomToggle = ({details, rowDetails}) => {

  return (
    <button
      disabled={true}
      type="button"
      className={`btn btn-sm ${rowDetails?.["status"].toUpperCase().trim() === 'INACTIVE' ? 'btn-warning': 'btn-success'}`}
    >
      {rowDetails?.["status"].toUpperCase().trim() === 'INACTIVE' ?'Inactive' : 'Active'}
    </button>
  )
}

export default CustomToggle;
