import React, { useEffect, useState } from 'react';
// import { animated, useSpring } from '@react-spring/web'
// import { endpoint } from '../../common/constant';
import CustomLoader from './Loader/loader';
import SvgIcon from '@mui/material/SvgIcon';
import { alpha, styled } from '@mui/material/styles';
import clsx from 'clsx';


import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import CustmCheckbox from '../formFeilds/customCheckbox';
import { fetchAllPromisedData } from '../utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../constant';
import { failureToast } from '../utils/methods/toasterFunctions/function';
import {
    TreeItem,
    useTreeItem,
    TreeView,
} from "views/pages/manage/common/commonComponents/tree";
// import './index.scss';

// import response from './response.json';

function MinusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function EndSquareIcon(props) {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <circle cx="12" cy="12" r="4" fill="#757575" />
    </svg>
}

function findParentIds(data, targetId, isSubCategory, isSubSubCategory) {
    for (const category of data) {
        for (const subCategory of category.subCategories) {
            if (subCategory.id === targetId && isSubCategory) {
                return category.id
            }

            if (isSubSubCategory) {
                const subSubCategory = subCategory.subSubCategory.find(sub => sub.id === targetId);
                if (subSubCategory) {
                    return category.id;
                }
            }
        }
    }

    return null;
}


const CustomContent = React.forwardRef(function CustomContent(props, ref) {
    const {
        classes,
        className,
        label,
        nodeId,
        icon: iconProp,
        expansionIcon,
        displayIcon,
        onClick = () => { }
    } = props;

    const {
        disabled,
        expanded,
        selected,
        focused,
        handleExpansion,
        handleSelection,
        preventSelection,
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event) => {
        preventSelection(event);
    };

    const handleExpansionClick = (event) => {
        handleExpansion(event);
    };

    const handleSelectionClick = (event) => {
        handleSelection(event);
        onClick();
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                [classes.focused]: focused,
                [classes.disabled]: disabled,
            })}
            onMouseDown={handleMouseDown}
            ref={ref}
        >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div onClick={handleExpansionClick} className={classes.iconContainer}>
                {icon}
            </div>
            <Typography
                onClick={handleSelectionClick}
                component="div"
                className={classes.label}
            >
                {label}
            </Typography>
        </div>
    );
});


function CustomTreeItem(props) {
    return <TreeItem ContentComponent={CustomContent} {...props} />;
}

function TransitionComponent(props) {
    // const style = useSpring({
    //     from: {
    //         opacity: 0,
    //         transform: 'translate3d(20px,0,0)',
    //     },
    //     to: {
    //         opacity: props.in ? 1 : 0,
    //         transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    //     },
    // });

    return (
        // <animated.div style={style}>
        <Collapse {...props} />
        // </animated.div>
    );
}

const StyledTreeItemRoot = styled((props) => (
    <TreeItem {...props} ContentComponent={CustomContent} TransitionComponent={TransitionComponent} />
))(({ theme, fontSize, fontWeight }) => ({
    '& .MuiTreeItem-content': {
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
    '& .MuiTreeItem-focused': {
        backgroundColor: 'transparent !important',
    },
    '& .MuiTreeItem-selected': {
        backgroundColor: 'transparent !important',
        '& .MuiTreeItem-label': {
            '.MuiTypography-root': {
                fontWeight: '600 !important',
                textDecoration: 'underline',
            },
        },
    },
    '& .MuiTreeItem-iconContainer': {
        '& .close': {
            opacity: 0.3,
        },
    },
    '& .MuiTreeItem-group': {
        marginLeft: 15,
        marginBottom: 10,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
    '& .MuiTreeItem-label': {
        '.MuiTypography-root': {
            fontWeight: `${fontWeight} !important`,
            fontSize: `${fontSize} !important`,
            lineHeight: '1.7 !important',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
}));

function StyledTreeItem(props) {
    const {
        label,
        showButton,
        isSelected,
        ...other
    } = props;


    return (
        <StyledTreeItemRoot
            label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2"
                        sx={{
                            fontWeight: 'inherit',
                            flexGrow: 1,
                            // fontWeight: `${isSelected ? 600 : fontWeight} !important`, 
                            // fontSize: `${fontSize} !important`, 
                            // lineHeight: `1.7 !important`,
                            // '&:hover': {
                            //     textDecoration: 'underline',
                            // },
                        }}
                    >
                        {label}
                    </Typography>
                    {showButton && <CustmCheckbox styles={{ checkboxBlock: { marginBottom: '20px' } }} onClick={(e) => { }} defaultChecked={isSelected} />}
                </Box>
            }
            {...other}
        />
    );
}


const ConsessionTreeClassification = ({
    classificationData,
    fetching,
    getClassifaicationData = () => { },
    isFilterApplied,
    onCategoryClick = (c) => { },
    onSubCategoryClick = (c, sc) => { },
    onSubSubCategoryClick = (c, sc, ssc) => { },
    showSelectBtn = false,
    showSelectBtnForConcessionOnly = false,
    selected = null,
    applicationDetails,
    onlumsumClick = (l) => { },
    forStudentCharges = false,
    expandedArr = [],
    editTree = false
}) => {

    const [lumpsumChild, setLumpsumChild] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [subCategoryId, setSubCategoryId] = useState(null);
    const [expandedNodes, setExpandedNodes] = useState([]);

    const handleToggle = (event, nodeIds) => {
        // console.log('nodeIds',nodeIds)
        setExpandedNodes(nodeIds)
    }

    const getLumpsumData = async () => {
        try {
            setLoading(true)
            const lumpsumDetails = await fetchAllPromisedData(`${endpoint.lumpSumConcession.getByCareerId}/${applicationDetails.academicCareerId}`, true);
            if (lumpsumDetails?.code === 200) {
                if (lumpsumDetails?.data?.courseCategories.includes(applicationDetails.courseCategoryId) && lumpsumDetails?.data?.terms.includes(applicationDetails.termId)) {
                    setSubCategoryId(lumpsumDetails?.data?.concessionSubCategoryId)
                    const _temp = [
                        {
                            displayVal: 'Full First Year',
                            consession: lumpsumDetails?.data?.fullFirstYear ?? 0,
                            dispKey: 'fullFirstYear'
                        },
                        {
                            displayVal: 'Full Upfront',
                            consession: lumpsumDetails?.data?.fullUpFront ?? 0,
                            dispKey: 'fullUpFront'
                        },
                        {
                            displayVal: 'Year On Year',
                            consession: lumpsumDetails?.data?.yearOnYear ?? 0,
                            dispKey: 'yearOnYear'
                        }
                    ]
                    setLumpsumChild(_temp);
                }
            } else {
                setLumpsumChild(null)
            }

            // setChildData(data);
            // setExpanded(nodeIds);
            setLoading(false);
        } catch (error) {
            // Handle API call errors
            console.error('Error fetching child data:', error);
            setLoading(false);
        }
    }

    useEffect(() => {
        getClassifaicationData();
    }, [])

   

    useEffect(() => {
        if (editTree) setExpandedNodes(expandedArr)
    }, [classificationData, expandedArr])


    useEffect(() => {
        if (selected?.id) {
            const categoryId = findParentIds(classificationData, selected?.id, selected?.isSubCategory ?? false, selected?.isSubSubCategory);
            if (!selected?.isSubSubCategory) {
                setExpandedNodes([`${selected?.categoryId}-c`, `${selected?.id}-sc`]);
            }

            if (selected?.isSubSubCategory) {
                setExpandedNodes([`${categoryId}-c`, `${selected?.subCategoryId}-sc`, `${selected?.id}-ssc`])
            }
        }
    }, [selected])

    return (
        fetching || isLoading ? (
            <div className="col">

                <div
                    className="mx-auto text-center py-5 my-5 "
                    style={{ height: '100%' }}
                >
                    <CustomLoader apiLoader={fetching || isLoading} />
                </div>

            </div>
        ) :
            isFilterApplied && !classificationData.length ? <span style={{ color: '#757575', fontSize: 14, marginTop: 5, letterSpacing: 1 }}>
                No records found, Please reset filter to add/view data.
            </span> :
                <TreeView
                    aria-label="icon expansion"
                    defaultCollapseIcon={<MinusSquare />}
                    defaultExpandIcon={<PlusSquare />}
                    defaultEndIcon={<EndSquareIcon />}
                    expanded={expandedNodes}
                    onNodeToggle={handleToggle}
                    sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto', fontWeight: 600, paddingRight: '2px' }}
                >
                    {
                        (forStudentCharges ? classificationData?.filter(i => i && i?.status === 'ACTIVE') : classificationData)?.map((item, i) => {
                            return (
                                <StyledTreeItem fontSize={'1rem'}
                                    showButton={showSelectBtn && (showSelectBtnForConcessionOnly ? false : true)}
                                    isSelected={item.categoryExternalId === selected?.categoryExternalId}
                                    fontWeight={600}
                                    nodeId={`${item?.id}-c`}
                                    label={item.categoryDispVal}
                                    onClick={() => onCategoryClick(item)}
                                    key={`${item?.id}-c`}
                                >
                                    {(forStudentCharges ? item.subCategories?.filter(i => i && i.status === 'ACTIVE') : item.subCategories)?.map((sc, k) => {
                                        return (
                                            <StyledTreeItem fontSize={'0.875rem'}
                                                showButton={showSelectBtn && (showSelectBtnForConcessionOnly ? sc.subSubCategory.length === 0 : true)}
                                                isSelected={sc.subCategoryExternalId === selected?.subCategoryExternalId}
                                                fontWeight={400}
                                                nodeId={`${sc?.id}-sc`}
                                                label={sc.subCategoryDispVal}
                                                onClick={() => onSubCategoryClick(item, sc)}
                                                key={`${sc?.id}-sc`}
                                            >
                                                {(forStudentCharges ? sc.subSubCategory?.filter(i => i && i.status === 'ACTIVE') : sc.subSubCategory)?.map((ssc, j) => {
                                                    return (
                                                        <StyledTreeItem fontSize={'0.875rem'}
                                                            isSelected={ssc.subSubCategoryExternalId === selected?.subSubCategoryExternalId}
                                                            showButton={showSelectBtn}
                                                            fontWeight={400}
                                                            nodeId={`${ssc?.id}-ssc`}
                                                            label={ssc.subSubCategoryDisplayValue}
                                                            onClick={() => onSubSubCategoryClick(item, sc, ssc, item?.categoryDispVal === 'Lumpsum' && sc.subCategoryDispVal === 'Lumpsum' ? `LUMPSUM_${ssc.subSubCategoryDisplayValue?.split(' ')?.join('_')}` : '')}
                                                            key={`${ssc?.id}-ssc`}
                                                        />
                                                    )
                                                })}
                                            </StyledTreeItem>
                                        )

                                    })}
                                </StyledTreeItem>
                            )
                            // }
                        })
                    }
                </TreeView>

    )
}

export default ConsessionTreeClassification