import React from 'react'
import { Card, CardBody } from 'reactstrap'
import styles from './viewAcademicPlan.module.css'
import { ReactComponent as TestIcon } from '../../../../../assets/img/svg/TestIcon.svg'
import CustomChip from '../../common/customChip/CustomChip'
import { MISSING_MESSAGE, courseCardHeadingStyles, courseIconMapping, listCourseCardIconStyles } from './utils'
import AButton from '../../common/form-fields-mui/AButton'
import ListViewCourseCard from './ListViewCourseCard'
import { useParams } from 'react-router'
import {ReactComponent as WarningSign} from '../../../../../assets/img/svg/DangerWarning.svg'

const ListView = ({ viewTestClickHandler, testCountObj, testCount, courses, subjectLecturesCount }) => {

  const { id } = useParams();
  return (
    <div className={`${styles.view_container} ${styles.list_view_container}`}>
      <Card className='mb-4'>
        <CardBody className='d-flex justify-content-between'>
          <div className='d-flex align-items-center'>
            <TestIcon style={listCourseCardIconStyles} />
            <h3 style={{ ...courseCardHeadingStyles, marginBottom: 0, marginLeft: '1rem' }}>Test</h3>
          </div>
          <div className='d-flex align-items-center'>
            {testCount > 0 ? (
              <CustomChip
                bgColor={'#BBE2CE'}
                textColor={'#0B442D'}
                text={testCount > 1 ? `${testCount} tests scheduled` : `${testCount} test scheduled`}
                style={{ fontSize: '12px' }}
              />
            ) : (
              <CustomChip
              bgColor={'#F9D9D7'}
              textColor={'#B32306'}
              children={ <span><WarningSign style={{height:'16px', width:'16px',marginBottom:'3px'}}/> {MISSING_MESSAGE}</span>}
              // text='No test scheduled'
              style={{ fontSize: '12px'}}
            />
            )}
            <AButton variant='link' updatedStyle={{ height: '24px' }} onClick={viewTestClickHandler}>View/Add tests</AButton>
          </div>
        </CardBody>
      </Card>
      {courses && courses?.map(item => (
        <ListViewCourseCard
          key={item?.courseId}
          lectureCountByDuration = {subjectLecturesCount}
          courseData={item}
          courseIcon={courseIconMapping(true)?.[item?.subjectName]}
          viewAddLink={`/admin/testScheduling/academicPlan/${id}/lecture?courseId=${item?.courseId}&subjectName=${item?.subjectName}`}
        />
      ))}
    </div>
  )
}

export default ListView