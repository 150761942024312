import React, { useState, useEffect } from "react";
import Select from 'react-select';
import moment from "moment";
import DatePicker from 'react-datepicker';
import styled from "styled-components";
import timeIcon from "./time.svg";
import './index.scss'

const CustomizedTimePicker = styled.div`
    .react-datepicker-wrapper {
        display : block !important;
    }
    .react-datepicker .react-datepicker__header {
        display : none !important;
    }
    .react-datepicker__time-container {
        width : 150px;
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
        width : 100% !important;
        text-align : left !important;
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
        background: none !important;
        color: #00B0F5 !important;
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
        display: none !important;
    }
`


const CustomTimePicker = ({
    onChange = () => { },
    defaultValue = '',
    forFilter,
    placeHolder,
    disabled = false
}) => {

    const [selectedTime,setSelectedTime] = useState(null)

    const Input = ({ value, disabled, ...props }) => (
        <div className={`${forFilter ? 'filter-time-picker' : ''}`}>
            {!forFilter && <img style={{ position: 'absolute', marginTop: 12, marginLeft: 5 }} src={timeIcon} />}
            <input
                type="text"
                placeholder="Select time"
                value={value}
                className={disabled || !value || value === '' ? '' : ''}
                style={!forFilter ? {paddingLeft:'30px'} : {}}
                {...props}
                onKeyDown={(e)=>{e.preventDefault(); return}}
                onKeyPress={(e)=>{e.preventDefault(); return}}
                disabled={disabled}
            />
            {forFilter && <img style={{ position: 'absolute', marginTop: '-23px', right: 5 }} src={timeIcon} />}
            {/* <CalendarIcon isActive={props.value ?true :false} className="calendar-icon" onClick={props.onClick} disabled={disabled}/> */}
        </div>
    )


    useEffect(()=>{
        if(defaultValue !== '' && typeof(defaultValue) === 'string'){
            const dateTime = moment(new Date(`${moment(new Date()).format('ddd MMM DD YYYY')}, ${defaultValue} `)).toDate();
            setSelectedTime(dateTime)
        }
        if(defaultValue === ''){
            setSelectedTime(null)
        }
    },[defaultValue])

    return (
        <CustomizedTimePicker>
            <DatePicker
                selected={selectedTime}
                onChange={(date)=>{
                    setSelectedTime(date);
                    onChange(date);
                }}
                customInput={<Input />}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="hh:mm a"
                portalId="root-portal-timePickr-v1"
                placeholderText={placeHolder}
                disabled={disabled}
                minTime={new Date(new Date().setHours(5,30))}
                maxTime={new Date(new Date().setHours(23,0))}
                
                // disabled={disabled}
            />
        </CustomizedTimePicker>
    )
}

export default CustomTimePicker