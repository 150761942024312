import React from "react";
import { Dialog } from "@mui/material";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";

const UnfreezeConfirmationPopup = ({
  openConfirmationPopup,
  handleSave,
  handleCloseDialog,
}) => {
  return (
    <Dialog onClose={handleCloseDialog} open={openConfirmationPopup.isOpen}>
      <div style={{ padding: "28px 24px", width: "500px" }}>
        <div
          style={{
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "24px",
            color: "#000000",
            marginBottom: "2rem",
          }}
        >
          Do you want to unfreeze the entry with Transaction ID:{" "}
          {openConfirmationPopup.entry.transactionId} ?
        </div>

        <div className="d-flex justify-content-around">
          <AButton
            variant="outline"
            className="button_remove_left_margin"
            updatedStyle={{
              border: "1px solid #414141",
              color: "#414141",
              width: "180px",
            }}
            onClick={handleCloseDialog}
          >
            Cancel
          </AButton>

          <AButton
            variant="primary_filled"
            updatedStyle={{ width: "180px" }}
            onClick={handleSave}
          >
            Yes
          </AButton>
        </div>
      </div>
    </Dialog>
  );
};

export default UnfreezeConfirmationPopup;
