import React from 'react'
import styles from '../styles.module.scss'
import AAccordion from 'views/pages/manage/common/a-accordion/AAccordion'
import { Col, Row } from 'reactstrap'
import CustomDatePicker from 'views/pages/manage/common/commonComponents/CustomDatePicker'
import AInput from 'views/pages/manage/common/formFeilds/customInput/AInput';
import AButton from 'views/pages/manage/common/form-fields-mui/AButton.jsx';
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";
import { getFormattedAmount } from '../../../common/utils/methods/commonMethods/utilityMethod';
import UploadAttachments from '../../../edpOps/stockManagement/stockConsumption/form/UploadAttachments';
import CustomLoader from '../../../common/commonComponents/Loader/loader'
import { getAdvanceEmi, getFileForm, getGrossLoanAmount, getLoanNotApproved, getNetDisbursement, getProcessingFee, getTotalUpfrontPayment } from './constant'
import moment from 'moment'
import { Divider } from '@mui/material'



const inputStyle = { height: '40px', borderRadius: '4px' }
const selectStyle = { control: { borderRadius: '4px', minWidth: '200px' } }
const amountInputProps = {
  customIcon: <div>₹</div>,
  customIconStyle: { left: '8px' },
  style: { paddingLeft: '20px', borderRadius: '4px', minWidth: '120px' },
}

export const CustomLabel = ({ title, children }) => {
  return (
    title ? (
      <div className='tiny-semi-bold label-color' style={{ marginBottom: '10px' }}>{title}</div>
    ) : (
      children
    )
  )
}

const Details = ({ loader, doInformation, doInfoHandler, saveHandler, allLoanSchemes, mode, setMode, readOnly }) => {

  const editDisabled = readOnly ? true : mode === 'view' ? true : false;

  return loader ? <CustomLoader /> : (
    <div>
      <Row>
        <Col md={8}>
          <div className={`${styles['do-info-box']}`} style={{ gap: '12px' }}>
            <div className='large-semi-bold color-dark mb-1'>Student’s loan calculation</div>

            <div className='d-flex align-items-center justify-content-between'>
              <div className='regular-body label-color'>Loan partner name</div>
              <div className='large-semi-bold color-dark'>: {doInformation?.loanVendorName}</div>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='regular-body label-color'>Loan scheme</div>
              <div>
                <AAutoComplete
                  isMulti={false}
                  currentValue={doInformation?.loanSchemeMasterId}
                  items={allLoanSchemes?.filter(item => item?.vendorName === doInformation?.loanVendorName)}
                  handleChange={(value) => {
                    doInfoHandler(value, 'loanSchemeMasterId');
                    doInfoHandler(getLoanNotApproved({ ...doInformation, loanSchemeMasterId: value }), 'parentPayingNow');
                  }}
                  style={selectStyle}
                  isDisabled={editDisabled}
                />
              </div>
            </div>
            <Divider />

            <div className='d-flex align-items-center justify-content-between'>
              <div className='regular-body label-color'>Final course fee payable (A)</div>
              <div className='large-semi-bold color-dark'>: {getFormattedAmount(doInformation?.finalCourseFee)}</div>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='regular-body label-color'>Net loan amount as per DO (B)</div>
              <div>
                <AInput
                  placeholder='Enter amount in Rs'
                  value={doInformation?.netLoanAmount}
                  handler={value => {
                    if (!value || (parseInt(value) >= 0 && parseFloat(value) < 1000000000)) {
                      const updatedValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1").replace(/(\.\d{1,2}).*/g, "$1")
                      doInfoHandler(updatedValue, 'netLoanAmount');
                      doInfoHandler(getLoanNotApproved({ ...doInformation, netLoanAmount: updatedValue }), 'parentPayingNow');
                    }
                  }}
                  disabled={editDisabled}
                  {...amountInputProps}
                />
              </div>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='regular-body label-color'>Gross loan amount as per DO (C)</div>
              <div className='large-semi-bold color-dark'>: {getFormattedAmount(getGrossLoanAmount(doInformation))}</div>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='regular-body label-color'>Advance EMI (D)</div>
              <div className='large-semi-bold color-dark'>: {getFormattedAmount(getAdvanceEmi(doInformation))}</div>
            </div>
            <Divider />

            <div className='d-flex align-items-center justify-content-between'>
              <div className='regular-body label-color'>Loan not approved (E=A-B-D)</div>
              <div className='large-semi-bold color-dark'>: {getFormattedAmount(getLoanNotApproved(doInformation))}</div>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='regular-body label-color'>Processing charges (F)</div>
              <div className='large-semi-bold color-dark'>: {getFormattedAmount(getProcessingFee(doInformation))}</div>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='regular-body label-color'>Net disbursement as per DO (B-F)(L)</div>
              <div className='large-semi-bold color-dark'>: {getFormattedAmount(getNetDisbursement(doInformation))}</div>
            </div>
            <Divider />

            <div className='d-flex align-items-center justify-content-between'>
              <div className='regular-body label-color'>Total charges already paid (G)</div>
              <div className='large-semi-bold color-dark'>: {getFormattedAmount(0)}</div>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='regular-body label-color'>Balance payable (H=D-G)</div>
              <div className='large-semi-bold color-dark'>: {getFormattedAmount(getAdvanceEmi(doInformation))}</div>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='regular-body label-color'>Parent paying now (I)</div>
              <div>
                <AInput
                  placeholder='Enter amount in Rs'
                  value={doInformation?.parentPayingNow}
                  handler={value => {
                    if (!value || (parseInt(value) >= 0 && parseFloat(value) < 1000000000)) {
                      doInfoHandler(value.replace(/[^0-9.]/g, "")
                        .replace(/(\..*)\./g, "$1")
                        .replace(/(\.\d{1,2}).*/g, "$1"), 'parentPayingNow');
                    }
                  }}
                  disabled={editDisabled}
                  {...amountInputProps}
                />
              </div>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='regular-body label-color'>Total upfront payment (H+F+I) (M)</div>
              <div className='large-semi-bold color-dark'>: {getFormattedAmount(getTotalUpfrontPayment(doInformation))}</div>
            </div>

          </div>
        </Col>
        <Col md={4}>
          <div className={`${styles['do-info-box']}`} style={{ gap: '16px' }}>
            <div className='large-semi-bold color-dark'>DO details</div>
            <div>
              <CustomLabel title={'DO date'} />
              <CustomDatePicker
                maxDate={moment()}
                value={doInformation?.doDate ? moment(doInformation?.doDate) : null}
                handler={(index, date, keyName) => {
                  doInfoHandler(date, 'doDate')
                }}
                disabled={editDisabled}
              />
            </div>
            <div>
              <CustomLabel title={'DO ID'} />
              <AInput
                placeholder={'Enter DO ID'}
                style={inputStyle}
                disabled={editDisabled}
                value={doInformation?.doId}
                handler={value => doInfoHandler(value, 'doId')}
              />
            </div>
            <UploadAttachments
              disableUpload={editDisabled}
              form={getFileForm(doInformation)}
              setFormHandler={(value, key) => {
                if (key === 's3key') doInfoHandler(value, 'doDocKey')
                if (key === 'fileName') doInfoHandler(value, 'doFileName')
              }}
              maxAllowedFileSize={5}
              supportedFileTypes={['pdf']}
              serviceName='STUDENT_DO_DETAILS'
              uploadButtonProps={{ className: 'w-100 btn-left-0', disabled: editDisabled }}
              uploadFileHeader='Upload DO document'
            />
          </div>
        </Col>
      </Row>

      <div className='mt-3 d-flex align-items-center justify-content-between'>
        {mode === 'view' ? (
          !readOnly && <AButton
            variant='outline'
            className='btn-left-0'
            updatedStyle={{ width: '160px' }}
            onClick={() => setMode('edit')}
            disabled={readOnly}
          >
            Edit details
          </AButton>
        ) : (
          <AButton
            variant='primary_filled'
            className='btn-left-0'
            updatedStyle={{ width: '160px' }}
            onClick={saveHandler}
            disabled={!doInformation?.netLoanAmount || !doInformation?.doDate || !doInformation?.doId || !doInformation?.doDocKey}
          >
            Save
          </AButton>
        )}
        {doInformation?.updatedOn && <div>
          <span>
            <span className='regular-body label-color'>Last updated by: </span>
            <span className='large-semi-bold color-dark'>{doInformation?.updatedBy || '-'}</span>
          </span>
          <span className='ml-2'>
            <span className='regular-body label-color'>Last updated date/time: </span>
            <span className='large-semi-bold color-dark'>{doInformation?.updatedOn ? moment(doInformation?.updatedOn).format('DD-MMM-YYYY, hh:mm A') : '-'}</span>
          </span>
        </div>}
      </div>
    </div>
  )
}


const DOInformation = (props) => {
  
  const Summary = (
    <div className='heading-4 color-dark'>
      DO information
    </div>
  )

  return (
    <AAccordion
      initialOpen
      summary={Summary}
      details={
        <Details
          {...props}
        />
      }
    />
  )
}

export default DOInformation