import React from 'react'
import { TbStar, TbStarFilled } from 'react-icons/tb'

const FavouriteIcon = ({ checked, ...restProps }) => {
  return (
    checked ? (
      <TbStarFilled color='#D29822' {...restProps} />
    ) : (
      <TbStar {...restProps} />
    )
  )
}

export default FavouriteIcon