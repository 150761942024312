import React, { useState } from "react";
import { RightSideFilterForm } from "./RightSideFilterForm";
import APopover from "../common/pop-hover/APophover";

// this is for filter button style
const highLightedStyle = {
  border: "1px solid #00B0F5",
  color: "#00B0F5",
};

const NormalStyle = {
  border: "1px solid #E9ECEF",
};
const Filter = ({
  businessAreaData,
  roleData,
  courseCategoryData,
  getFilteredData,
  getAllData,
  filter,
  setFilter
}) => {

  const [anchorFilterEl, setAnchorFilterEl] = useState(null);
  const [isShowRemoveFilter, setShowRemoveFilter] = useState(false);

  const removeFilter = () => {
    setShowRemoveFilter(false);
    setFilter({
      "businessAreas": [],
      "courseCategories": [],
      "roles": []
    })
    getAllData();
  }

  const getFilterCount = () => {
    let count = 0;
    Object.keys(filter).map(f => {
      if (filter[f]?.length) {
        count = count + 1;
      }
    })
    return count;
  }

  const applyFilter = () => {
    setShowRemoveFilter(true);
    getFilteredData()
  }


  return (
    <div className="d-flex justify-content-end">
      <span style={{
        padding: '10px 16px',
        border: '2px solid #CCCCCC',
        borderRadius: '8px',
        fontSize: '16px',
        fontWeight: 600,
        cursor: 'pointer',
        color: '#0F0F0F'
      }}
        onClick={(event) => {
          setAnchorFilterEl(event.currentTarget);
        }}
      >
        Filter
        {getFilterCount() > 0 && <span>( {getFilterCount()} )</span>}
      </span>

      <APopover
        handleClose={() => setAnchorFilterEl(null)}
        id="info-batch-filter-1"
        anchorEl={anchorFilterEl}
      >
        <RightSideFilterForm
          businessAreaData={businessAreaData}
          roleData={roleData}
          courseCategoryData={courseCategoryData}
          filter={filter}
          setFilter={setFilter}
          getFilterCount={getFilterCount}
          removeFilter={removeFilter}
          applyFilter={applyFilter}
          isShowRemoveFilter={isShowRemoveFilter}
        // disabled={showAllStudent}
        />
      </APopover>
    </div>
  );
};

export default Filter;
