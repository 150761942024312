import React, { useState } from "react";
import { withRouter } from "react-router";
import { Button, Card, CardBody, Input, Table } from "reactstrap";

import moment from "moment";
import {
  masterServiceBaseUrl,
  failureToast,
  putpost,
  putpost2,
  successToast,
} from "../../../../../../services/http";
import { MasterHeaderAuto } from "../../../common/commonComponents/masterHeader/masterHeader";
import {
  CustomCard,
  CustomContainer,
  ToExcel,
} from "views/pages/manage/common/commonComponents";

import MsGroupSequenceForm from "./msGroupSequenceForm";
import MsGroupSequenceRow from "./msGroupSequenceRow";
import CreateButton from "components/FormComponent/Button/New/index";
import { ButtonText } from "variables/Buttons";

const Cardheader = ({ isSaveVisible = false, newRow = () => {}, setIsSaveVisible = () => {} }) => {
  return (
    <>
      <h3>MS Team Group Sequence</h3>
      <div className="d-flex flex-row  justify-content-between">
        <CreateButton
          text={ButtonText.MSGroupSequence.addNew}
          disabled={isSaveVisible}
          className={"floatRight mx-1 ml-2" + (isSaveVisible ? " btn-dark" : null)}
          onClick={() => {
            newRow();
            setIsSaveVisible(true);
          }}
        />
      </div>
    </>
  );
};

var _ = require("lodash");
const BuildingTable = (props) => {
  const {
    fetchClasses,
    setclassArray,
    classArray,
    // isSaveVisible,
    // setIsSaveVisible = () => {},
    coreMasterData = [],
  } = props;

  const [msData, setMsData] = useState({
    sequence: "",
    coreMasterId: "",
  });

  const [isSaveVisible, setIsSaveVisible] = useState(false);

  const addNewClass = () => {
    setclassArray([
      {
        sequence: "",
        coreMasterId: "",
        isEdit: false,
        isNew: true,
      },
      ...classArray,
    ]);
  };

  const headerList = [
    { name: "Sequence", isRequired: true },
    { name: "Core Master", isRequired: true },
    { name: "Actions" },
  ];

  const newRow = () => {
    setIsSaveVisible(true);
    let cb = () => {
      addNewClass();
      // setIsSaveVisible(true);
      setIsSaveVisible(true);
    };
    let _tempArray = classArray;
    _tempArray = _.sortBy(_tempArray, [
      function (o) {
        return o.value;
      },
    ]);
    setclassArray(_tempArray);
    // setIsSaveVisible(false);
    setIsSaveVisible(false);
    cb();
  };

  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      setIsSaveVisible(true);
      let tempData = classArray;
      tempData[index]["isEdit"] = true;
      setMsData({
        sequence: tempData[index]["sequence"],
        coureMasterId: tempData[index]["coureMasterId"],
      });
    }
  };

  const deleteRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let tempData = classArray;
      let id = tempData[index]["id"];

      // id is used when api ready

      if (window.confirm("Are you sure you want to delete this Building?")) {
        putpost2(
          masterServiceBaseUrl + "/buildingMaster/deleteBuildingMaster/" + id,
          (data) => {
            successToast(data.message);
            props.searchBuilding();
            resetFields();
          },
          (data) => {
            failureToast(data.message);
          },
          "Delete"
        );
      }
    }
  };

  const saveRow = (index) => {
    let tempData = classArray;
    let isValid = true;
    if (!msData.buildingName || !msData.buildingName.trim()) {
      failureToast("Please Enter Building Name");
      return;
    } else if (!msData.address || !msData.address.trim()) {
      failureToast("Please Enter Building Address");
      return;
    } else if (!msData.pincode) {
      failureToast("Please Select Pincode");
      return;
    } else {
      if (tempData[index]["isEdit"]) {
        let tempObject = {          
          sequence: msData?.sequence,
          coreMasterId: msData.coreMasterId?.value,
        };
        tempData[index] = tempObject;
        let id = tempData[index]["buildingMasterDto"]["id"];

        putpost(
          `${masterServiceBaseUrl}/buildingMaster/updateBuildingMaster/${id}`,
          (data) => {
            successToast(data.message);
            props.searchBuilding();
            setIsSaveVisible(false);
            resetFields();
          },
          (data) => {
            failureToast(data.message);
          },
          tempObject,
          "put"
        );
      } else if (tempData[index]["isNew"]) {
        // putPostApi(`${concessionServiceBaseUrl}/addSubCategory`,tempObject,'post');
        // setIsSaveVisible(false);
        // resetFields()
        let tempObject = {
         sequence: msData?.sequence,
          coreMasterId: msData.coreMasterId?.value,
          isEdit: false,
          isNew: false,
        };

        putpost(
          `${masterServiceBaseUrl}/buildingMaster/createBuildingMaster`,
          (data) => {
            successToast(data.message);
            props.searchBuilding();
            setIsSaveVisible(false);
            resetFields();
          },
          (data) => {
            failureToast(data.message);
          },
          tempObject,
          "post"
        );

        // putPostApi(`${concessionServiceBaseUrl}/addSubCategory`,tempObject,'post');
      }
    }
  };

  const cancelRow = (index) => {
    let tempData = classArray;
    if (tempData[index]["isEdit"]) tempData[index]["isEdit"] = false;
    else if (tempData[index]["isNew"]) tempData.splice(index, 1);
    setclassArray(tempData);
    setIsSaveVisible(false);
    resetFields();
  };

  const resetFields = () => {
    setMsData({
      sequence: "",
      coreMasterId: "",
    });
  };

  const getDisplayVal = (id, list) => {
    const i =
      id &&
      list?.filter((item) => {
        return item.id == id;
      });
    return i && i?.length ? i[0] : "";
  };

  // const getShortDescription = (id) => {
  //     const i = classArrayList.filter(item=> {return item.id == id})
  //     return i && i.length ? i[0] : null
  // }

  return (
    <CustomContainer>
      <CustomCard
        HeaderElement={
          <Cardheader
            isSaveVisible={isSaveVisible}
            setIsSaveVisible={setIsSaveVisible}
            newRow={newRow}
          />
        }
      >
        <div id="questionTable" className="table-responsive p-3">
          <Table className="align-items-center table-flush table-layout">
            <MasterHeaderAuto headerList={headerList} />
            <tbody>
              {classArray.map((data, index) =>
                data["isEdit"] || data["isNew"] ? (
                  <MsGroupSequenceForm
                    key={"NewOrEditRow" + index}
                    msData={msData}
                    setMsData={setMsData}
                    data={data}
                    index={index}
                    saveRow={saveRow}
                    cancelRow={cancelRow}
                    getDisplayVal={getDisplayVal}
                    isSaveVisible={isSaveVisible}
                    setIsSaveVisible={setIsSaveVisible}
                    {...props}
                  />
                ) : (
                  <MsGroupSequenceRow
                    key={"TDROW" + index}
                    data={data}
                    index={index}
                    history={props.history}
                    deleteRow={deleteRow}
                    editRow={editRow}
                    isSaveVisible={isSaveVisible}
                    getDisplayVal={getDisplayVal}
                    {...props}
                  />
                )
              )}
            </tbody>
          </Table>
        </div>
      </CustomCard>
    </CustomContainer>
  );
};

export default withRouter(BuildingTable);
