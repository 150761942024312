import {Button, Col, FormGroup, Input, Label, Row} from "reactstrap";
import React, {useEffect} from "react";
import Select2 from "react-select2-wrapper";
import {masterServiceBaseUrl, failureToast} from "../../../../../../services/http";
import {
    fetchAllData,
    fetchDetails,
    getDispValForReactSelectByOneID
} from "../../../common/utils/methods/commonMethods/utilityMethod";
import {RedAstric} from "../examIdSearchForm";

const ExamHeader = (props) => {
    const {
        formData, setFormData, Update, groupCode, companyCode, status,nextHandler,action,
        setIsCollapseOneOpen, setIsCollapseTwoOpen, setIsCollapseThreeOpen, setCompanyCode,setAcademicCareer,setAcademicGroup,
        setTermMapping,setTermDropDown,setTermMappingData,label,placeholder,button
    } = props;
    return (
        <div className='container-fluid'>
            <Row>
                <Col md={action ==='new'?4:3}>
                    <FormGroup>
                        <Label for="examId" className="form-control-label">{label.businessGroup} {action ==='new' ? <RedAstric/>:null}</Label>
                        {
                            action !== 'view' ?
                                <Select2 options={{placeholder: placeholder.businessGroup,}}
                                         data={groupCode}
                                         disabled={action ==='edit'}
                                         defaultValue={formData['groupCode']}
                                         onChange={(e) => {
                                             if (e.target.value) {
                                                 Update('groupCode', e.target.value);
                                                 setCompanyCode([]);
                                                 if(formData['companyCodeId'] && action === 'new'){
                                                     setTermMapping([])
                                                     setTermDropDown([])
                                                     fetchAllData(masterServiceBaseUrl+`/termMapping/getAllTermsByGroupCodeAndCompanyCode?groupCode=${e.target.value}&companyCode=${formData['groupCode']}`,setTermMappingData)
                                                     // fetchAllData(masterServiceBaseUrl +`/termMapping/getAllTermMappingByGroupCodeAndCompanyCode?groupCode=${e.target.value}&companyCode=${formData['groupCode']}` , setTermMapping);
                                                 }
                                                 fetchDetails(masterServiceBaseUrl + `/companyCode/getCompanyCodeByGroup/${e.target.value}`, setCompanyCode, 'companyCode', 'reactSelect');
                                             }
                                         }}
                                />
                                :
                                <Input disabled={true} className="tdSelect" value={ formData.groupCode ? getDispValForReactSelectByOneID(formData['groupCode'],'DispValue',groupCode) :'Not Found'}/>
                        }
                    </FormGroup>
                </Col>
                <Col md={action ==='new'?4:3}>
                    <FormGroup>
                        <Label for="examId" className="form-control-label">{label.companyCode}  {action ==='new' ? <RedAstric/>:null}</Label>
                        {
                            action === 'new' ?
                                <Select2 options={{placeholder: placeholder.companyCode,}}
                                         data={companyCode}
                                         defaultValue={formData['companyCodeId']}
                                         disabled={action ==='edit'}
                                         onChange={(e) => {
                                             if (e.target.value) {
                                                 Update('companyCodeId', e.target.value);
                                                 fetchDetails(masterServiceBaseUrl + '/academicCareer/getAcademicCareerByCompanyCode/'+e.target.value, setAcademicCareer, 'academicCareer', 'reactSelect');
                                                 fetchDetails(masterServiceBaseUrl + '/academicGroup/getAcademicGroupByCompanyCode/'+e.target.value, setAcademicGroup, 'academicGroup', 'reactSelect');
                                                 if(formData['groupCode'] && action === 'new'){
                                                     setTermMapping([])
                                                     setTermDropDown([])

                                                     fetchAllData(masterServiceBaseUrl+`/termMapping/getAllTermsByGroupCodeAndCompanyCode?groupCode=${formData['groupCode']}&companyCode=${e.target.value}`,setTermMappingData)
                                                     // fetchAllData(masterServiceBaseUrl +`/termMapping/getAllTermMappingByGroupCodeAndCompanyCode?groupCode=${formData['groupCode']}&companyCode=${e.target.value}` , setTermMapping);
                                                 }
                                             }
                                         }}
                                />
                                :
                                <Input disabled={true} className="tdSelect" value={ formData.companyCodeId ? getDispValForReactSelectByOneID(formData['companyCodeId'],'DispValue',companyCode) :'Not Found'}/>
                        }
                    </FormGroup>
                </Col>
                <Col md={action ==='new'?4:3}>
                    <FormGroup>
                        <Label for="examType" className="form-control-label">{label.status} {action === 'new' ? <RedAstric/> : null}</Label>
                        {
                            action !=='view' ?
                                <Select2 options={{placeholder: placeholder.status,}}
                                         data={status}
                                         defaultValue={formData['status']}
                                         disabled={action ==='view'}
                                         onChange={(e) => {
                                             if (e.target.value) {
                                                 Update('status', e.target.value);
                                             }
                                         }}
                                />
                                :
                                <Input disabled={true} className="tdSelect" value={ formData.status ? formData['status'] :'Not Found'}/>
                        }
                    </FormGroup>
                </Col>
                {action!=='new'?(<Col md={3}>
                    <FormGroup>
                        <Label for="examType" className="form-control-label">{label.examId} </Label>
                        <Input maxLength={10} disabled={true} placeholder={placeholder.examId} className="tdSelect" value={formData['examId']} onChange={(e) => Update('examId', e.target.value)}/>
                    </FormGroup>
                </Col>):null}
            </Row>
            {action ==='new'?
               ( <Row>
                    <Col xs={12}>
                        <Button color="info" size="sm" className="floatRight mx-1" onClick={()=>nextHandler(1)}>{button.next}</Button>
                    </Col>
                </Row>)
                :null}
        </div>
    )
}
export default ExamHeader;
