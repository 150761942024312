import React from "react";
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";
import AInput from "views/pages/manage/common/formFeilds/customInput/AInput";

const PhoneNoDetailsForm = ({ item, index, removePhoneNoHandler, phoneNoChangeHandler, isFormDisabled, inValidDataInfo }) => {

    const selectStyles = { height: '40px', borderRadius: '4px' }

    const labelStyle = {
        fontSize: "14px",
        fontWeight: 600,
        color: "#525F7F"
    }

    const phoneNoTypes = [
        { label: 'Mobile', value: 'Mobile' },
        { label: 'Landline', value: 'Landline' }
    ]

    const { field: inValidField, idx: inValidIndex } = inValidDataInfo;

    return (
        <div className="my-2">

            <div style={labelStyle}>Phone no*</div>
            <div className="w-60 d-flex" style={{ gap: "10px" }}>

                <AAutoComplete
                    style={{ control: { ...selectStyles, width: "150px" } }}
                    items={phoneNoTypes}
                    isMulti={false}
                    currentValue={item.type}
                    handleChange={val => phoneNoChangeHandler(index, val, 'type')}
                    isDisabled={isFormDisabled}
                />
                <AInput
                    placeholder="State Code"
                    style={{
                        ...selectStyles,
                        borderColor: '#CFD3D4',
                        ...(inValidIndex === index && inValidField === 'code') && ({ borderColor: '#B32306' })
                    }}
                    value={item.code}
                    handler={val => phoneNoChangeHandler(index, val, 'code')}
                    disabled={isFormDisabled}
                    regex={new RegExp(/^[0-9]*$/)}
                    maxLength={6}
                />

                <AInput
                    placeholder="Number*"
                    style={{
                        ...selectStyles,
                        borderColor: '#CFD3D4',
                        ...(inValidIndex === index && inValidField === 'number') && ({ borderColor: '#B32306' })
                    }}
                    value={item.number}
                    handler={val => phoneNoChangeHandler(index, val, 'number')}
                    disabled={isFormDisabled}
                    maxLength={10}
                    regex={new RegExp(/^[0-9]*$/)}
                />

                <AInput
                    placeholder="Extension"
                    style={{
                        ...selectStyles,
                        width: "100px",
                        borderColor: '#CFD3D4',
                        ...(inValidIndex === index && inValidField === 'extension') && ({ borderColor: '#B32306' })
                    }}
                    value={item.extension}
                    handler={val => phoneNoChangeHandler(index, val, 'extension')}
                    disabled={isFormDisabled}
                    regex={new RegExp(/^[0-9]*$/)}
                    maxLength={6}
                />

                <span
                    onClick={() => !isFormDisabled && removePhoneNoHandler(index)}
                    style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#B32306",
                        cursor: "pointer",
                        paddingTop: "15px"
                    }}>
                    Remove
                </span>
            </div>
        </div>
    );
}

export default PhoneNoDetailsForm;