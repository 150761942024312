import React from "react";
import { Dialog } from "@mui/material";
import { ReactComponent as Warning } from "../../../../../../assets/img/svg/DangerWarning.svg";
import { ReactComponent as CrossIcon } from "../../../../../../assets/img/svg/CrossIcon.svg";

import AButton from "../../../common/form-fields-mui/AButton";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper";

const ResetTimetableCnf = ({ handleClose, open, restTimetableHandler }) => {
  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={{ padding: "1.5rem", width: "30rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "2rem",
            marginBottom: "10px",
            color: "black",
          }}
        >
          <Warning />
          <IconButtonWrapper onClick={handleClose}>
            <CrossIcon  />
          </IconButtonWrapper>
        </div>
        <div>
          <div
            style={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "32px",
              color: "#000000",
              marginBottom: ".6rem",
            }}
          >
            All upcoming Tests and Lectures will be deleted. Are you sure want
            to proceed?
          </div>
          <div
            style={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#414141",
              marginBottom: ".6rem",
              //   padding: "10px",
            }}
          >
            Note: Any Tests/Lectures of the past will not be impacted and will
            still be part of this batch's timetable.
          </div>
        </div>

        <div className="mt-2 d-flex align-items-center">
          <AButton
            onClick={handleClose}
            className="mt-2 button_remove_left_margin"
            variant="outline"
          >
            Go back
          </AButton>
          <AButton
            updatedStyle={{ minWidth: "130px" }}
            onClick={restTimetableHandler}
            className="mt-2"
            variant="danger_filled"
          >
            Reset{" "}
          </AButton>
        </div>
      </div>
    </Dialog>
  );
};

export default ResetTimetableCnf;
