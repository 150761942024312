import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router';
import searchForm from './serachForm.json'
import { FormGenerator } from '../../common/commonComponents/formGenerator';
import { fetchAllPostPromisedData, fetchAllPromisedData, NewRow } from '../../common/utils/methods/commonMethods/utilityMethod';
import { Card, CardHeader, Container, Row, Table } from 'reactstrap';
import CustomButton from '../../../../../components/CustomButton';
import { PermissionContext, RolePermissions } from "appContext";
import { constants, endpoint, pages } from "../../common/constant";
import { getPaginatedData } from '../../common/utils/methods/commonMethods/paginationMethod';
import { failureToast } from '../../common/utils/methods/toasterFunctions/function';
import CustomLoader from "../../common/commonComponents/Loader/loader";
import { MasterHeaderAuto } from '../../common/commonComponents/masterHeader/masterHeader';
import { GetPagination } from '../../common/commonComponents/pagination/pagination';


let page = 0;
let isNextCallPossible = true;
const pageSize = 10;
const headerList = [
  { name: 'Security Group Name' },
  { name: 'Security Group Description' },
  { name: 'Status' },
  { name: 'Action' }
]

const listToRender = [
  'securityGroupName',
  'securityGroupDescription',
  'status'
]



const SecurityGroupSearch  = () => {

  const history = useHistory();

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["securityGroup"]['id']);


  const [values, setValues] = useState({
    securityGroupName: '',
    securityGroupDescription: ''
  })
  const [project, setProject] = useState([]);
  const [isFetching, setIsFetching] = useState(false)
  let [pagination, setpagination] = useState(constants.pagination);
  const [tableData, setTableData] = useState([]);
  const [serverData, setServerData] = useState(null);
  const [paginationFromServer,setPaginationFromServer] = useState();


  // const []
  // const getInitailData = async () => {
  //   const project = await fetchAllPromisedData(endpoint.projectDetails.getAllProject);

  //   if (Array.isArray(project)) {
  //     const tempArr = project.map(d => { return { label: d.projectDispValue, value: d.id } })
  //     setProject({ data: tempArr })
  //   }
  // }

  // useEffect(() => {
  //   getInitailData();
  // }, [])


  const searchHandler = async () => {
    try {

      // if (!values.securityGroupName && !values.securityGroupDescription) {
      //   failureToast("Select atleast one search criteria")
      //   return
      // }

      setIsFetching(true);

      let searchData = {}
      if(!values.securityGroupName && !values.securityGroupDescription){
        searchData = await fetchAllPromisedData(`${endpoint.securityGroup.common}?pageNo=${page}&fetchMappings=false`, true);
        setPaginationFromServer(true)
      }

      if(values.securityGroupName || values.securityGroupDescription){
        searchData = await fetchAllPromisedData(`${endpoint.securityGroup.search}?securityGroupName=${values.securityGroupName.toString().toUpperCase() ?? ''}&securityGroupDescription=${values.securityGroupDescription ?? ''}`, true)
        setPaginationFromServer(false)
      }
      if (searchData?.code === 200) {
        setServerData(searchData.data);
        getPaginatedData(searchData.data, pageSize, constants.pagination, setpagination, setIsFetching, setTableData)
      } else {
        setIsFetching(false);
        setServerData(null);
        setTableData([])
        // failureToast(templateData['message']);
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  const fetchNext = async () => {
    setIsFetching(true);
    page = page + 1;
    const searchData = await fetchAllPromisedData(`${endpoint.securityGroup.common}?pageNo=${page}&fetchMappings=false`, true)
    if (searchData?.code === 200 && searchData?.data?.length > 0) {
      const updatedData = [...serverData, ...searchData.data]
      setServerData(updatedData);
      getPaginatedData(updatedData, pageSize, pagination, setpagination, setIsFetching, setTableData)
      isNextCallPossible = searchData?.data?.length == 50 ? true : false;
    } else {
      isNextCallPossible = false;
      setIsFetching(false);
    }
  }

  const reset = () => {
    setValues({
      securityGroupName: '',
      securityGroupDescription: ''
    })
    setServerData(null);
    setTableData([])  
  }

  useEffect(()=>{
    if(!values.securityGroupDescription && !values.securityGroupName){
      setServerData(null);
    }
  },[values])


  useEffect(()=>{
    if(!serverData){
      searchHandler()
    }
  },[serverData])

  // useEffect(()=>{
  //   searchHandler()
  // },[])


  return (
    <Container fluid>
      <Card>
        <CardHeader>
          <h3>Security Group Search</h3>
          <CustomButton
            className={'floatRight mx-1 '}
            content={"Add New Security Group"}
            permissionType={'C'}
            icon={true}
            onClick={() => history.push("/admin/securityGroup/new")}
            // onClick={()=>{}}
            permissionSet={userPermissions}
          />
        </CardHeader>
        <Row className="pl-4 pr-4 pb-2 pt-2">
          <FormGenerator
            fields={Object.keys(searchForm)}
            fieldsObject={searchForm}
            values={values}
            setValues={setValues}
            dataObjects={{
              project: project
            }}
          // isPreviewEnable={isPreviewEnable}
          // disabled={isTicketDetailValid}
          />
        </Row>
        <Row className="justify-content-end pr-5 pb-4">

          <CustomButton
            disabled={isFetching}
            content={'Search'}
            permissionType={'S'}
            icon={true}
            permissionSet={userPermissions}
            onClick={searchHandler}
          />
          <CustomButton
            disabled={isFetching || (!values.securityGroupName && !values.securityGroupDescription) ? true : false}
            content={'Reset'}
            type={'reset'}
            permissionType={'R'}
            permissionSet={userPermissions}
            onClick={reset}
          />

        </Row>
      </Card>

      <Row className="mt-n2">
        {isFetching ? (
          <div className="col">
            <Card className=" w-100">
              <div
                className="mx-auto text-center py-5 my-5 "
                style={{ height: '100vh' }}
              >
                <CustomLoader apiLoader={isFetching} />
              </div>
            </Card>
          </div>
        ) : (
          <>

            {serverData?.length && <div className="col">
              <Card className="overflow-auto">
                <CardHeader className="border-0">
                  <h3 className="mb-0 floatLeft">Search Results</h3>
                </CardHeader>
                <Table className="align-items-center tableLayout">
                  <MasterHeaderAuto headerList={headerList} permissionSet={userPermissions} isSaveVisible={false} />
                  <tbody className="list" key={'table-message-template'}>
                    {tableData.map((el) => {
                      return <NewRow
                        data={{ ...el }}
                        listToBeRendered={listToRender}
                      >
                        <td className="text-center  white-breakSpace">

                          <CustomButton
                            permissionType={'R'}
                            icon={true}
                            forTable={true}
                            permissionSet={userPermissions}
                            // onClick={() => { history.push(`/admin/securityGroup/view/${el.id}`) }}
                            onClick={()=>{window.open(`${document.URL.split('#')[0]}#/admin/securityGroup/view/${el.id}`,'_Blank')}}

                            // onClick={()=>{}}
                          />

                          <CustomButton
                            permissionType={'U'}
                            icon={true}
                            forTable={true}
                            permissionSet={userPermissions}
                            onClick={() => { history.push(`/admin/securityGroup/edit/${el.id}`) }}
                          />

                        </td>
                      </NewRow>

                    })}
                  </tbody>
                </Table>
                {pagination.totalPage > 1 ? (
                  <GetPagination
                    isSaveVisible={false}
                    setclassArray={setTableData}
                    pagination={pagination}
                    setpagination={setpagination}
                    pageSize={pageSize}
                    state={serverData}
                    isPaginationFromServer={paginationFromServer}
                    isFetching={isFetching}
                    fetchNext={fetchNext}
                  />
                ) : null}
              </Card>
            </div>}
          </>
        )}
      </Row>
    </Container>
  )
}

export default SecurityGroupSearch 