import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Accordion } from '../../common/commonComponents/Accordion/accordion';
import Loader from 'react-loader-spinner';
import {
	masterServiceBaseUrl,
	deleteAPI,
	getAPI,
} from 'services/http';
import { Container, Card, CardBody, CardHeader, Button, Row } from 'reactstrap';
import { PermissionContext, RolePermissions } from "appContext";
import { endpoint, pages } from "../../common/constant";
import BusinessUnitNewUI from '../component/FirstForm/businessUnitNewUI';
import AddAddress from '../component/LocationAddress';
import ContactHistory from '../component/thirdForm';
import TaxDetails from '../component/taxDetails';
import Franchisee from '../component/Franchisee';
import CashierOffice from '../component/cashierOffice';
import BranchAccount from '../component/branchAccount';
import ApprovalMatric from '../component/approvalMatrix/approvalMatric';
import SAPDetails from '../component/SAP_BusinessArea&Plan';
import PineLabsSetup from '../component/pineLabsSetup';
import LastNumberGenerated from '../component/LastNumberGenerated';
import { useHistory, useParams } from 'react-router-dom';
import { fetchAllPostPromisedData, fetchAllPromisedData, getValueBySearch } from '../../common/utils/methods/commonMethods/utilityMethod';
import './editview.scss';
import IsCoreMaster from '../../common/commonComponents/coreMaster';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import SapPlantMapping from '../component/FirstForm/sapPlantMapping';
import BusinessUnitFormTabs from '../component/FirstForm';


function BusinessUnitEditViewForm(props) {
	let history = useHistory();

	const permissions = useContext(PermissionContext);
	const userPermissions = RolePermissions(permissions, pages["businessAreaSearch"]['id']);

	const { id, action } = useParams();
	const [isNew, setIsNew] = useState(true);
	const [apiLoader, setApiLoader] = useState(false);
	const [businessAreaDetails, setBusinessAreaDetails] = useState();

	const [groupCodeId, setGroupCodeId] = useState();
	const [groupCodeList, setGroupCodeList] = useState();
	const [groupCodeValue, setGroupCodeValue] = useState();
	const [companyCodeId, setCompanyCodeId] = useState();
	const [companyCodeList, setCompanyCodeList] = useState();
	const [companyCodeValue, setCompanyCodeValue] = useState();
	const [businessTypeId, setBusinessTypeId] = useState();
	const [businessTypeList, setBusinessTypeList] = useState();
	const [businessAreaName, setBusinessAreaName] = useState('');
	const [businessAreaType, setBusinessAreaType] = useState([]);

	const [locationAndContactDetailsData, setLocationAndContactDetailsData] = useState({fetching : false, data : []});

	useEffect(() => {
		fetchData(
			masterServiceBaseUrl + `/groupCode/getAllGroupCode`,
			'GroupCode',
			setGroupCodeList
		);
		fetchData(
			masterServiceBaseUrl + `/companyCode/getAllCompanyCode`,
			'CompanyCode',
			setCompanyCodeList
		);
		fetchData(
			masterServiceBaseUrl + `/businessUnitType/getAllBusinessUnitType`,
			'businessType',
			setBusinessTypeList
		);

		// fetchLocationAndContactDetails();
	}, []);

	const fetchBusinessAreaDetails =()=>{
		fetchData(
			masterServiceBaseUrl + `/businessArea/getBusinessAreaDetails/${id}`,
			'userData',
			setBusinessAreaDetails
		);
	}

	const fetchLocationAndContactDetails=async()=>{
		try{
			setLocationAndContactDetailsData({fetching : true, data : []})

		const resp = await fetchAllPromisedData(`${endpoint.businessArea.getBusinessAreaContactDetailsLocation}/${id}`, true);
		if(resp?.code === 200) {
			const data = [resp?.data?.locationDetailsDto, resp?.data?.contactDetailsMasterDTO];
			setLocationAndContactDetailsData({fetching : false , data : data})
		}else {
			setLocationAndContactDetailsData({fetching : false, data : []})
		}
	}catch(err){
		console.log(err);
	}
	}

	const fetchData = (url, type, setState) => {
		try {
			setApiLoader(true);
			getAPI(
				url,
				async (data) => {
					if (type === 'userData') {
						setState(data['data']);
						setBusinessAreaName(
							data['data']['businessAreaMasterDTO']['businessAreaDispValue']
						);
						setCompanyCodeId(
							data['data']['businessAreaMasterDTO']['companyCodeId']
						);
						setGroupCodeId(
							data['data']['businessAreaMasterDTO']['groupCodeId']
						);
						setBusinessTypeId(
							data['data']['businessAreaMasterDTO']['businessUnitTypeId']
						);
						setApiLoader(false);
					}
					 else {
						setState(data['data']);
					}
				},
				(data) => {
					setApiLoader(false);
					failureToast(data['message']);
				}
			);
		} catch (e) {
			console.log('Error', e);
		}
	};

	const getDisplayCompanyGroupCode = (groupCodeId, companyCodeId) => {
		const groupCodeValue =
			groupCodeId &&
			groupCodeList &&
			getValueBySearch(groupCodeId, groupCodeList, 'groupCodeDispValue');

		const companyCodeValue =
			companyCodeId &&
			companyCodeList &&
			getValueBySearch(companyCodeId, companyCodeList, 'companyCodeDispValue');

		let temp = [];
		const businessUnitValue =
			businessTypeId &&
			businessTypeList &&
			businessTypeId.map((id) => {
				const businessType = getValueBySearch(
					id,
					businessTypeList,
					'businessUnitTypeDispValue'
				);
				businessType && temp.push(' ' + businessType);
			});

		setBusinessAreaType(temp);

		groupCodeValue && setGroupCodeValue(groupCodeValue);
		companyCodeValue && setCompanyCodeValue(companyCodeValue);
	};

	useMemo(() => {
		getDisplayCompanyGroupCode(groupCodeId, companyCodeId);
	}, [
		groupCodeList,
		companyCodeList,
		groupCodeId,
		companyCodeId,
		businessTypeId,
		businessTypeList,
	]);

	return (
		<>
			{/* {
			apiLoader ? (
				<div
					className="mx-auto text-center py-5 my-5"
					style={{ height: '100vh' }}
				>
					<Loader type="Rings" color="#00BFFF" height={100} width={100} />{' '}
				</div>
			) : ( */}
				<Container className={`mt-3 customContainer`} fluid>
					<Card className="customCardBody">
						<CardHeader className="fixedHeader">
							<h2>Business Area</h2>
							<div className="floatRight mr-auto">
								<IsCoreMaster
									master={'businessArea'}
									masterDisplayValue={'Business Area'}
								/>
							</div>
							<div className="right_heading">
								<div>
									<h4>Business Area : </h4> <span>{businessAreaName}</span>
								</div>
								<div>
									<h4>Business Area Type :</h4> <span>{businessAreaType}</span>
								</div>
							</div>
						</CardHeader>
						{/* <CardBody className="customCardBody"> */}
						<div>
							{/* {businessAreaDetails &&
								businessAreaDetails['businessAreaMasterDTO'] ? ( */}
								<>
									{/* <Accordion
										title={`Basic Information`}
										key={'basic_information'}
										isFormOpen={false}
										removePadding={true}
									>
										<BusinessUnitNewUI
											isNew={isNew}
											isPrevious={true}
											action={action}
											formId={id}
											data={businessAreaDetails['businessAreaMasterDTO']}
											userPermissions={userPermissions}
										/>

									</Accordion> */}

									<BusinessUnitFormTabs
									isNew={isNew}
									isPrevious={true}
									action={action}
									formId={id}
									data={businessAreaDetails?.businessAreaMasterDTO}
									userPermissions={userPermissions}
									locationAndContactDetailsData={locationAndContactDetailsData}
									fetchLocationAndContactDetails={fetchLocationAndContactDetails}
									fetchBusinessAreaDetails={fetchBusinessAreaDetails}
									/>

								</>
							{/* {businessAreaDetails &&
								businessAreaDetails['businessAreaMasterDTO'] ? (
								<>
									<Accordion
										title={`SAP Plant Mapping`}
										key={'sap_plant_mapping'}
										isFormOpen={false}
										removePadding={true}
									>
										<SapPlantMapping
											action={action}
											formId={id}
											userPermissions={userPermissions}
											data={businessAreaDetails['businessAreaMasterDTO']}
										/>

									</Accordion>
								</>
							) : null} */}

							{/* this is begin commented for first release only please dont delete */}
							{/* {businessAreaDetails && businessAreaDetails['addressMaster'] ? (
								<Accordion
									title={`Address`}
									key={'add_address'}
									isFormOpen={false}
									removePadding={true}
								>
									<AddAddress
										addAddressData={
											businessAreaDetails &&
											businessAreaDetails['addressMaster']
										}
										action={action}
									/>
								</Accordion>
							) : null}
							{businessAreaDetails &&
							businessAreaDetails['contactHistory'].length > 0 ? (
								<Accordion
									title={`Contact History`}
									key={'add_contact_history'}
									isFormOpen={false}
									removePadding={true}
								>
									<ContactHistory
										data={businessAreaDetails['contactHistory']}
										isPrevious={true}
										action={action}
										formId={id}
									/>
								</Accordion>
							) : null}
							{businessAreaDetails &&
							businessAreaDetails['franchiseeSetupMaster']['id'] &&
							businessAreaDetails['franchiseeAddress']['id'] ? (
								<Accordion
									title={`Franchisee Setup`}
									key={'Franchisee Setup'}
									isFormOpen={false}
									removePadding={true}
								>
									<Franchisee
										franchiseeSetupData={
											businessAreaDetails &&
											businessAreaDetails['franchiseeSetupMaster']
										}
										franchiseeAddressData={
											businessAreaDetails &&
											businessAreaDetails['franchiseeAddress']
										}
										action={action}
									/>
								</Accordion>
							) : null}
							{(businessAreaDetails &&
								businessAreaDetails['businessAreaTaxDetail'][
									'businessAreaBusinessUnitTaxSetupReceipts'
								]) ||
							(businessAreaDetails &&
								businessAreaDetails['businessAreaTaxDetail'][
									'businessAreaBusinessUnitTaxSetupRefunds'
								]) ||
							(businessAreaDetails &&
								businessAreaDetails['businessAreaTaxDetail'][
									'businessAreaAndUnitFranchiseeCommissionTdsSetups'
								]) ? (
								<Accordion
									title={`Tax Details`}
									key={'Tax Details'}
									isFormOpen={false}
									removePadding={true}
								>
									<TaxDetails
										groupCode={groupCodeId}
										companyCode={companyCodeId}
										taxReceiptsDetails={
											businessAreaDetails['businessAreaTaxDetail'][
												'businessAreaBusinessUnitTaxSetupReceipts'
											]
										}
										taxRefundDetails={
											businessAreaDetails['businessAreaTaxDetail'][
												'businessAreaBusinessUnitTaxSetupRefunds'
											]
										}
										taxCommissionDetails={
											businessAreaDetails['businessAreaTaxDetail'][
												'businessAreaAndUnitFranchiseeCommissionTdsSetups'
											]
										}
										franchisesSetupId={
											// businessAreaDetails["businessAreaTaxDetail"][
											//   "businessAreaAndUnitFranchiseeCommissionTdsSetups"
											// ][0] &&
											// businessAreaDetails["businessAreaTaxDetail"][
											//   "businessAreaAndUnitFranchiseeCommissionTdsSetups"
											// ][0]["franchiseeSetupMasterId"]
											businessAreaDetails['franchiseeSetupMaster'] &&
											businessAreaDetails['franchiseeSetupMaster']['id']
										}
										formId={
											businessAreaDetails['businessAreaMasterDTO'] &&
											businessAreaDetails['businessAreaMasterDTO']['id']
										}
										action={action}
									/>
								</Accordion>
							) : null}
							{businessAreaDetails &&
							businessAreaDetails['cashierOfficeMasters'] ? (
								<Accordion
									title={`Cashier Office`}
									key={'cashier_office'}
									isFormOpen={false}
									removePadding={true}
								>
									<CashierOffice
										cashierOfficeData={
											businessAreaDetails['cashierOfficeMasters']
										}
										action={action}
										id={id}
									/>
								</Accordion>
							) : null}
							{businessAreaDetails?.['houseBankAndHouseBankSetupDTO']?.length >
							0 ? (
								<Accordion
									title={`Branch Account`}
									key={'branch_account'}
									isFormOpen={false}
									removePadding={true}
								>
									<BranchAccount
										action={action}
										groupCode={groupCodeId}
										companyCode={companyCodeId}
										houseBankMastersData={
											businessAreaDetails['houseBankAndHouseBankSetupDTO']
										}
										businessAreaId={id}
									/>
								</Accordion>
							) : null}
							{businessAreaDetails?.['concessionApprovalSetups'] ? (
								<Accordion
									title={`Concession Approval Setup`}
									key={'concession_approval_setup'}
									isFormOpen={false}
									removePadding={true}
								>
									<ApprovalMatric
										groupCodekey={groupCodeValue}
										companyCodeKey={companyCodeValue}
										action={action}
										groupCode={groupCodeId}
										companyCode={companyCodeId}
										approvalSetupsData={
											businessAreaDetails?.['concessionApprovalSetups'] || []
										}
										formId={id}
										areaName={businessAreaName}
									/>
								</Accordion>
							) : null}
							{businessAreaDetails && businessAreaDetails['sapDetails'] ? (
								<Accordion
									title={`SAP Details`}
									key={'sap_details'}
									isFormOpen={false}
									removePadding={true}
								>
									<SAPDetails
										businessUnit={{ businessUnit: id }}
										sapDetails={businessAreaDetails['sapDetails']}
										action={action}
									/>
								</Accordion>
							) : null}

							{businessAreaDetails && businessAreaDetails['pineLabs'] ? (
								<Accordion
									title={`Pine Labs Setup`}
									key={'pine_labs_setup'}
									isFormOpen={false}
									removePadding={true}
								>
									<PineLabsSetup
										pineLabsTableData={
											businessAreaDetails['pineLabs'] &&
											businessAreaDetails['pineLabs']['pineLabsPOSIMEIMappings']
										}
										pineLabsKeyData={
											businessAreaDetails['pineLabs'] &&
											businessAreaDetails['pineLabs']['pineLabsSetup']
										}
										action={action}
										businessAreaId={id}
									/>
								</Accordion>
							) : null}
							{businessAreaDetails &&
							businessAreaDetails['lastNumberGeneratedDTO'] ? (
								<Accordion
									title={`Last Number Generated`}
									key={'last_number_generated'}
									isFormOpen={false}
									removePadding={true}
								>
									<LastNumberGenerated
										action={action}
										lastNumberGeneratedData={
											businessAreaDetails['lastNumberGeneratedDTO']
										}
										businessUnit={{ businessUnit: id }}
										businessKey={
											businessAreaDetails &&
											businessAreaDetails['businessAreaMasterDTO'] &&
											businessAreaDetails['businessAreaMasterDTO'][
												'businessAreaKey'
											]
										}
									/>
								</Accordion>
							) : null} */}

							<Button
								color="danger"
								size="md"
								type="button"
								className={'floatRight ml-auto'}
								onClick={() => window.close()}
							>
								Close
							</Button>
						{/* </CardBody> */}
						</div>
					</Card>
				</Container>
			{/* )} */}
		</>
	);
}

export default BusinessUnitEditViewForm;
