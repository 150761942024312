import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom"
import { Container, Card, CardHeader, Input, Col, Button } from "reactstrap";
import Select from "react-select";
import {
  pricingServiceBaseUrl,
  putpost,
  successToast,
  failureToast,
} from "services/http";
import SearchTable from "./searchTable";
import response from "./response.json";
import CustomLoader from "../../../common/commonComponents/Loader/loader";
import { fetchAllData } from "../../../common/utils/methods/commonMethods/utilityMethod"
import { GetTemplate } from "../../../common/commonComponents";
import { endpoint } from "../../../common/constant";

const FeeSearch = (props) => {
  const [serverData, setServerData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const [searchParams, setSearchParam] = useState({
    processId: "",
    status: null,
    description: "",
  });

  const statusData = [
    { label: "UPLOADED", value: "uploaded" },
    { label: "PROCESSING", value: "processing" },
    { label: "SUCCESS", value: "success" },
    { label: "FAILED", value: "failed" },
  ];

  const submitHandler = () => {
    
    if (
      !searchParams.processId &&
      !searchParams.processId.trim() &&
      !searchParams.description &&
      !searchParams.description.trim() &&
      !searchParams.status?.value
    ) {
      failureToast("Please provide atleast one search parameter from above");
      return;
    }

    setIsFetching(true)
    const searchData = {
      description: searchParams.description,
      processInstanceId: searchParams.processId || null,
      status: searchParams?.status?.value,
    };

    // getAPI(
    //   `${pricingServiceBaseUrl}/search`,
    //   (data) => {
    //     console.log(data);
    //     setServerData(data.data);
    //   },
    //   (data) => {
    //     failureToast(data["message"]);
    //   }
    // );
    putpost(
      `${pricingServiceBaseUrl}/search`,
      (data) => {
        successToast(data['message']);
        setServerData(data.data);
        setIsFetching(false);
      },
      (data) => {
        setServerData([])
        failureToast(data["message"]);
        setIsFetching(false);
      },
      searchData,
      'post'
    );
  };

  const reset = () => {
    setSearchParam({
      processId: "",
      status: null,
      description: "",
    });
    setServerData(null)
    getLatestedData()
  };

  const getLatestedData = () => {
    fetchAllData(`${pricingServiceBaseUrl}/getAllCourseFeeDetail`,setServerData)
  }

  useEffect(() => {
    getLatestedData()
  }, [])

  // useEffect(() => {
  //   if(searchParams.status===null){
  //     setSearchParam({ ...searchParams, status: { label: "", value: "" } })
  //   }   
  // }, [searchParams.status])

  return (
    <Container fluid className="mt-4">
      <Card>
        <CardHeader className=" d-flex justify-content-between">
          <h3 className="mb-0 ">Exam ID - Bulk Upload & Approval</h3>
          <div className="d-flex flex-row justify-content-between align-items-center">
            
            {/* <Button
              color="info"
              size="sm"
              type="button"
              className={"floatRight"}
              onClick={()=> window.open(`${pricingServiceBaseUrl}/downloadTemplate`, "_blank")}
            >
              Download Blank Sheet
            </Button> */}
            <GetTemplate url={endpoint.examServiceTemplate} isSaveVisible={false}/>

            <Button
              color="info"
              size="sm"
              type="button"
              className={"floatRight"}
              onClick={()=>props.history.push('/admin/uploadExamIdBulkDocument')}
            >
              Upload Filled Sheet
            </Button>

            <Button
              color="info"
              size="sm"
              type="button"
              className={"floatRight"}
              // onClick={()=>props.history.push('/admin/uploadPricingDocument')}
            >
              Download Final Sheet
            </Button>
            
            <Button
              color="info"
              size="sm"
              type="button"
              className={"floatRight"}
              // onClick={()=> window.open(`${pricingServiceBaseUrl}/downloadTemplate`, "_blank")}
            >
              Download Filled Sheet
            </Button>
           
            <Button
              color="info"
              size="sm"
              type="button"
              className={"floatRight"}
              // onClick={()=>props.history.push('/admin/uploadPricingDocument')}
            >
              Upload Approval Sheet
            </Button>
          </div>
        </CardHeader>

        <div className="p-4">
          <div className="form-row">
            <Col className="mb-3" md="3">
              <label className="form-control-label">{`Process Instance ID`}</label>
              <Input
                id="feedetailSearch_processID"
                value={searchParams.processId}
                placeholder={`Process Instance ID`}
                type="text"
                maxLength="6"
                style={{ height: "39px" }}
                onChange={(e) =>
                  !isNaN(e.target.value) ? 
                  setSearchParam({ ...searchParams, processId: e.target.value })
                  : setSearchParam({ ...searchParams})
                }
              />
            </Col>
            <Col className="mb-3" md="3">
              <label className="form-control-label">{`Status`}</label>
              <Select
                value={searchParams.status}
                id="feedetailSearch_status"
                options={statusData}
                // isDisabled={isDisabled}
                placeholder="Status"
                // isClearable={true}
                onChange={(sData) =>
                  setSearchParam({ ...searchParams, status: sData })
                }
              />
            </Col>
            <Col className="mb-3" md="6">
              <label className="form-control-label">{`Description`}</label>
              <Input
                value={searchParams.description}
                placeholder={`Description`}
                id="feedetailSearch_description"
                type="text"
                maxLength="50"
                style={{ height: "39px" }}
                onChange={(e) =>
                  setSearchParam({
                    ...searchParams,
                    description: e.target.value,
                  })
                }
              />
            </Col>
          </div>
          {/* <div className="d-flex justify-content-between ">
            <Button
              color="info"
              size="sm"
              type="button"
              className={""}
              onClick={reset}
            >
              Clear All
            </Button>
            <Button
              color="info"
              size="sm"
              type="button"
              className={""}
              onClick={submitHandler}
            >
              Search
            </Button>
          </div> */}
          <div className='mt-3'>
             <h5 className="mb-0 ">* Final Sheet Uploaded Should be in .csv format with unique file name</h5>
          </div>
        </div>
      </Card>
      {isFetching || !serverData? (
        <Card className="mt-4">
        <div
          className="mx-auto text-center py-5 my-5"
          style={{ height: "100vh" }}
        >
          <CustomLoader apiLoader={isFetching} />
        </div>
        </Card>
      ) : serverData ? (
        <SearchTable  
        data={serverData}
        />
      ) : null}
      {/* <SearchTable data={serverData} /> */}
    </Container>
  );
};

export default withRouter(FeeSearch);
