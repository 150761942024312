import React, {Fragment, useContext, useEffect, useState} from "react";
import {withRouter} from "react-router";
import {getAPI, putpost} from "services/http";
import {endpoint, pages} from "../../common/constant";
import {PermissionContext, RolePermissions} from "appContext";
import {PermisionDenied} from "../../common/commonComponents";
import NewTemplateHeader from "./header";
import FieldsTable from "./FieldsTable";
import AddNewModel from "../../courseInformation/pricingService/itemTypeSequenceSetup/model/addNewModel";
import {failureToast, successToast} from "../../common/utils/methods/toasterFunctions/function";
import TemplateHelpers from './utils/templateHelpers'
import DocumentServiceHelpers from 'helpers/document_service/index';
import MasterServiceHelpers from 'helpers/master_service/index';
import CustomLoader from "../../common/commonComponents/Loader/loader";
import {SomethingWentWrongDispatch} from "../../../../../contexts/SomethingWentWrong";
import {useHistory} from "react-router-dom";

const TemplateUploadForm = (props) => {
    const permissions = useContext(PermissionContext);
    const userPermissions = RolePermissions(permissions, pages["templateUploadManager"]['id']);
    let history = useHistory();
    const { id = null, action = '' } = props.match.params;
    const setIsSomethingWentWrong = React.useContext(SomethingWentWrongDispatch);
    const [isTableVisible, setIsTableVisible] = useState(false);
    const [num, setNum] = useState(1);
    const [isAddNetModalVisible, setIsAddNewModalVisible] = useState(false)
    const [templateHeaderData,setTemplateHeaderData] = useState(TemplateHelpers.TEMPLATE_HEADER_OBJECT)
    const STATUS_DROPDOWN = [{value:'ACTIVE',label:'ACTIVE'},{value:'INACTIVE',label:'INACTIVE'}]
    const [MasterData,setMasterData] = useState({
        processType : [],
        documentFormat : [],
        templateDataType : [],
        processTagTypeData : [],
        status :STATUS_DROPDOWN,
    });
    const [apiLoader,setApiLoader] = useState(true);
    const [fieldsTableData,setFieldsTableData] = useState([])
    const [templateId,setTemplateId] = useState(null);
    const [isLoading,setIsLoading] = useState(false);
    const [fieldTypes,setFieldTypes] = useState({});
    const [isHeaderInEditMode,setIsHeaderInEditMode] = useState(false);
    const updateDataHandler = (key,value,role,index)=>{
        try{
            if (role === 'header') {
                setTemplateHeaderData(preState => ({...preState, [key]: value}))
            } else if (role === 'fieldsTable') {
                let tempArray = [...fieldsTableData];
                let tempObject = {
                    ...tempArray[index],
                    [key]: value
                };
                tempArray[index] = tempObject;
                setFieldsTableData([...tempArray]);
            }
        }catch(error){
            console.error({error});
        }
    }
    const submitHandler = async () => {
        try{
            let isTemplateDetailsValid = TemplateHelpers.validateTemplateDetails(templateHeaderData)
            if (isTemplateDetailsValid) {
                setIsLoading(true);
                let payload = null
                if(action === 'new'){
                    payload = TemplateHelpers.getFormattedCreateTemplateDTO(templateHeaderData)
                    await TemplateHelpers.CreateTemplateHeader(payload)
                        .then(res => {
                            if (res && res.code === 200) {
                                successToast(res.message)
                                let headerId = res.data?.id;
                                let headerDTO = res.data;
                                let templateDTO = TemplateHelpers.getFormattedTemplateDTO(headerDTO, MasterData.documentFormat,MasterData.processTagType);
                                setTemplateHeaderData(templateDTO);
                                setTemplateId(headerId);
                                history.replace('/admin/template/edit/' + templateDTO.id);
                                setIsTableVisible(true);
                                setIsLoading(false)
                            }
                        }).catch((err) => {
                            console.error({err});
                        });
                } else if(action === 'edit'){
                    payload = {
                        status:templateHeaderData.status.value,
                        documentFormatId:templateHeaderData.documentFormatId.value,
                        processTagTypeId:templateHeaderData.processTagTypeId,
                        maxRows: Number(templateHeaderData.maxRows),
                        maxSize: Number(templateHeaderData.maxSize)
                    }
                    await TemplateHelpers.UpdateTemplateHeader(payload,templateHeaderData.id)
                        .then(res => {
                            if (res && res.code === 200) {
                                successToast(res.message)
                                let headerId = res.data?.id;
                                let headerDTO = res.data;
                                let templateDTO = TemplateHelpers.getFormattedTemplateDTO(headerDTO, MasterData.documentFormat);
                                setTemplateHeaderData(templateDTO);
                                setTemplateId(headerId);
                                setIsLoading(false);
                                setIsHeaderInEditMode(false);
                            }else{
                                setIsLoading(false);
                            }
                        }).catch((err) => {
                            console.error({err});
                        });
                }
            }
        }catch(error){
            console.error({error});
        }
    }

    const addNewRow = () => {
        try{
            if (num <= 15 && num > 0) {
                let newTableData = TemplateHelpers.addNewRowsInTemplateTable(fieldsTableData, num);
                setFieldsTableData(newTableData)
                setNum(1)
                setIsAddNewModalVisible(false);
            } else if (num === 0) {
                failureToast('Please enter value greater than 0.');
            } else {
                failureToast('Please add 15 or less Fields.');
            }
        }catch(error){
            console.error({error})
        }
    }

    const deleteRowHandler =(index)=>{
        try{
            if (window.confirm('Are you sure want to delete this row?')) {
                let tempArray = [...fieldsTableData];
                tempArray.splice(index, 1);
                setFieldsTableData([...tempArray])
            }
        }catch(error){
            console.error({error});
        }
    }

    const PostTemplateMetaData = async (requestPayload) =>{
        let url = endpoint.templateMetaData.create || null;
        if(url) {
            return new Promise((resolve,reject)=>{
                putpost(url, (data) => {
                    resolve(data)
                }, (data) => {
                    if(data){
                        if(data.message){
                            failureToast(data.message);
                        }
                        reject(data);
                    }
                }, requestPayload, 'post').then(res => {});
            })
        }
    }

    const submitFieldsHandler = async ()=>{
        try{
            let isValid = TemplateHelpers.validateColumns(fieldsTableData);
            if (isValid) {
                let headerId = ''
                if (templateId) {
                    let requestDTO = TemplateHelpers.getFormatRequestDTO(fieldsTableData, templateId);
                    await PostTemplateMetaData(requestDTO).then((res) => {
                        if(res && res.code === 200){
                            successToast('Request Complete Successfully.');
                            history.replace(`/admin/template/view/${res.data.documentTemplateId}`)
                            getRequiredData(res.data.documentTemplateId).then(res=>{});
                        }
                    }).catch((err)=>{
                    });
                } else {
                    failureToast('Template Id does not exist.');
                }
            }
        }catch(error){
            console.error({error});
        }
    }

    const getTemplateData = async (templateId = null)=>{
        let url = `${endpoint.templateUpload.getById}/${templateId ? templateId :id}`;
        return new Promise(async(resolve,reject)=>{
            await getAPI(
                url,
                (data) => {
                    resolve(data);
                },
                (data) => {
                    reject(data)
                }
            );
        })
    }

    const getRequiredData = async (templateId = null)=>{
        try{
            setApiLoader(true);
            const {getDocumentFormats, getTemplateDataType, getProcessTagType} = DocumentServiceHelpers;
            const {getProcessType} = MasterServiceHelpers;
            await Promise.all([
                getDocumentFormats(),
                getProcessType(),
                getTemplateDataType(),
                getProcessTagType()
            ]).then( async(result) => {
                let documentFormat = result[0]?.data || [];
                let processType = result[1]?.data || [];
                let templateDataType = result[2]?.data || [];
                let processTagType  = result[3]?.data || []
                setMasterData({
                    ...MasterData,
                    documentFormat: documentFormat,
                    processType: processType,
                    templateDataType: templateDataType,
                    processTagType: processTagType
                });
                if (action === 'view' || action === 'edit' || templateId) {
                    await getTemplateData(templateId).then(res => {
                        if (res.code === 200) {
                            let headerData = res;
                            const headerDTO = res.data?.documentTemplateDTO;
                            setTemplateId(headerDTO.id);
                            const templateDTO = TemplateHelpers.getFormattedTemplateDTO(headerDTO,documentFormat)
                            setTemplateHeaderData(templateDTO)
                            let templateMetaData = headerData?.data?.documentTemplateMetaDataDTOSList || [];
                            let types = {};
                            templateDataType.forEach((item,index)=>{
                                types = {
                                    ...types,
                                    [`${item.id}`] : item.label
                                }
                            });
                            setFieldTypes(types)
                            setFieldsTableData(templateMetaData)
                            setApiLoader(false)
                            setIsTableVisible(true);
                        }
                    })
                } else {
                    setApiLoader(false);
                }
            }).catch((err)=>{
                setIsSomethingWentWrong(true);
                console.error({err})
            });
        }catch(error){
            console.error({error})
        }
    }

    const editRowHandler =(index)=>{
        try{
            let tempArray = [...fieldsTableData];
            tempArray[index] = {
                ...tempArray[index],
                isEdit: true
            };
            if (MasterData && MasterData.templateDataType) {
                tempArray[index] = {
                    ...tempArray[index],
                    templateDatatypeMasterId: MasterData.templateDataType.filter(item => item.id === tempArray[index].templateDatatypeMasterId)[0],
                    isMandatory: TemplateHelpers.getIsMandatory(tempArray[index].required)
                }
            }
            setFieldsTableData(tempArray);
        }catch(error){
            console.error({error});
        }
    }

    const editHeaderHandler =()=>{
        localStorage.setItem("headerData", JSON.stringify(templateHeaderData));
        setIsHeaderInEditMode(true);
    }

    const cancelHandler =()=>{
        let headerData = JSON.parse(localStorage.getItem("headerData"));
        setTemplateHeaderData(headerData);
        setIsHeaderInEditMode(false);
    }

    useEffect(() => {
        getRequiredData().then(res=>{});
    }, []);


    return (
        apiLoader
            ?
            <CustomLoader apiLoader={apiLoader} loaderHeight={'200px'} loaderWidth={'100% '}/>
            :
        userPermissions ?
            <Fragment>
                <NewTemplateHeader
                    key={'TemplateConfigurationsTableHeader'}
                    templateHeaderData={templateHeaderData}
                    MasterData={MasterData}
                    submitHandler={submitHandler}
                    updateHeader={updateDataHandler}
                    isLoading={isLoading}
                    action={action}
                    userPermissions={userPermissions}
                    editHeaderHandler={editHeaderHandler}
                    cancelHandler={cancelHandler}
                    isHeaderInEditMode={isHeaderInEditMode}
                />
                <FieldsTable
                    key={'DynamicTemplateFieldTable'}
                    isTableVisible={isTableVisible}
                    userPermissions={userPermissions}
                    setIsAddNewModalVisible={setIsAddNewModalVisible}
                    fieldsTableData={fieldsTableData}
                    updateDataHandler={updateDataHandler}
                    MasterData={MasterData}
                    deleteRowHandler={deleteRowHandler}
                    submitFieldsHandler={submitFieldsHandler}
                    action={action}
                    editRowHandler={editRowHandler}
                    fieldTypes={fieldTypes}
                />
                <AddNewModel key={'AddNewModelTemplateConfigurations'} num={num} setNum={setNum} isVisible={isAddNetModalVisible} addNewRow={addNewRow} setIsVisible={setIsAddNewModalVisible} onSubmit={addNewRow}/>
            </Fragment>
            :
            <PermisionDenied/>
    );
};

export default withRouter(TemplateUploadForm);
