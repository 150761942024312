import React, { useContext } from "react";
import AInput from "views/pages/manage/common/formFeilds/customInput/AInput";
import SelectSearchInputParam from "../../studentSerach/SelectSearchInputParam";
import { FILTER__FORM_FIELDS, IssuanceIDCardContext, PAGE_STATE } from "../contextApi";
import StudentFilterForm from "./StudentFilterForm";

const SearchInput = () => {
  const { globalFilter, setGlobalFilter, getApplications, setCurrentPageState, setPage, resetStudentApplications, setFilterForm } = useContext(IssuanceIDCardContext)

  const callBack = () => {
    setCurrentPageState(PAGE_STATE.PRINT_ID_CARD);
    StudentFilterForm(FILTER__FORM_FIELDS)
  }
  const onKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      applyFilterHandler();
    }
  }

  const applyFilterHandler = () => {
    //reset other filter
    setFilterForm(FILTER__FORM_FIELDS)
    setPage(0);
    getApplications(0, { ...FILTER__FORM_FIELDS, ...globalFilter }, callBack);
    
  }

  let searchParamTitleMap = { psid: 'PSID', studentName: 'student name', applicationId: 'application ID' }

  return (
    <div className="mb-2">
      <div>
        <SelectSearchInputParam
          searchInputParam={globalFilter?.typeOfCheckbox}
          setSearchInputParam={(value) => setGlobalFilter(prev => ({ ...prev, 'typeOfCheckbox': value }))}
        />
      </div>
      <div style={{ width: "40%" }}>
        <AInput
          placeholder={`Search by ${searchParamTitleMap[globalFilter?.typeOfCheckbox]}`}
          style={{ height: "42px" }}
          value={globalFilter?.userInput}
          handler={(value) => setGlobalFilter(prev => ({ ...prev, 'userInput': value }))}
          onKeyDown={(e) => onKeyDown(e)}
          crossIcon={true}
          rightSearchIcon={true}
          crossHandler={async () => {
            setGlobalFilter(prev => ({ ...prev, 'userInput': '' }));
            setPage(0);
            resetStudentApplications();
          }}
          searchIconClickHandler={() => {
            applyFilterHandler();
          }}
        />
      </div>
    </div>
  );
};

export default SearchInput;
