import moment from "moment";
import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";

import AButton from "../../../../common/form-fields-mui/AButton";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import ALoader from "../../../../common/a-loader";
import {
  SECOND_TIME_RE_DISPATCH_MGS,
  statusDispatchedDropdown,
  statusMap,
} from "./helper";
import AAutoComplete from "../../../../common/form-fields-mui/AAutoComplete";
import { isObjEmpty } from "../../../../common/utils/methods/commonMethods/utilityMethod";
import { updateDispatchStatusApi } from "../../service";
import {
  failureToast,
  successToast,
} from "../../../../common/utils/methods/toasterFunctions/function";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx";
import CustomDatePickerV2 from "views/pages/manage/common/commonComponents/CustomDatePicker-V2";

import AlertIcon from "assets/Danger.png";

function UpdateStatusPopup({
  open,
  setOpen,
  // apiLoader,
  data,
  // setApiLoader,
  refreshTableData,
  addDispatchDetailsHandler,
  setIsSendNotification,
}) {
  const [form, setForm] = useState({});
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [isShowDeliveryDate, setIsShowDeliveryDate] = useState(false);

  const [apiLoader, setApiLoader] = useState(false);
  const DROPDOWN = data?.isBulk
    ? statusDispatchedDropdown
    : [
        ...statusDispatchedDropdown,
        { label: "Re-dispatched", value: "RE_DISPATCHED" },
      ];

  useEffect(() => {
    if (!isObjEmpty(data))
      setForm({
        value: data?.status,
        label: statusMap[data?.status],
      });
  }, [data]);

  useEffect(() => {
    // show/ hide Date-picker when user selects 'Delivered' status
    if (form?.value && form?.value.toLowerCase() === "delivered")
      setIsShowDeliveryDate(true);
    else setIsShowDeliveryDate(false);
  }, [form]);

  // console.log(form, data?.status);
  const closeHandler = () => {
    setOpen({
      open: false,
      data: {},
    });
    setDeliveryDate(new Date());
  };

  const updateStatusApiCall = async () => {
    if (!form?.label) return failureToast("Please select status!");
    let ids;
    let applicationIds;

    if (data?.isBulk) {
      ids = data?.applications?.map((item) => JSON.parse(item)?.id);
      applicationIds = data?.applications?.map(
        (item) => JSON.parse(item)?.applicationId
      );
    } else {
      ids = [data?.id];
      applicationIds = [data?.applicationId];
    }
    if (form?.value === "RE_DISPATCHED" && data?.status === "RE_DISPATCHED")
      return failureToast(SECOND_TIME_RE_DISPATCH_MGS);

    if (form?.value === "RE_DISPATCHED") {
      addDispatchDetailsHandler(ids, {
        isReDispatched: true,
        ...data,
        nextStatus: "DISPATCHED",
      });
      closeHandler();
      return;
    }

    setApiLoader(true);
    const response = await updateDispatchStatusApi({
      ids,
      status: form?.value,
      // add key for deliveryDate
      // .set({ hour: 0, minute: 0, second: 0 }) }),
      ...(form?.value.toLowerCase() === "delivered" && {
        deliveryDate: moment(deliveryDate).toISOString(),
      }),
    });
    if (response?.code === 200) {
      successToast(response?.message);
      if (["RE_DISPATCHED", "DELIVERED"]?.includes(form?.value))
        setIsSendNotification({
          applicationIds: applicationIds,
          nextStatus: form?.value,
        });
      refreshTableData();
      closeHandler();
    }

    setDeliveryDate(new Date());
    setApiLoader(false);
  };

  return (
    <>
      <Dialog onClose={closeHandler} open={open} maxWidth="lg">
        <div style={{ padding: "28px 24px", minWidth: "31rem" }}>
          {apiLoader && <ALoader position="fixed" />}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="color-dark heading-4">Update status </div>
            <IconButtonWrapper onClick={closeHandler}>
              <CrossIcon width={32} height={32} />
            </IconButtonWrapper>
          </div>
          <AAutoComplete
            label="Status"
            currentValue={form}
            items={DROPDOWN}
            handleChange={(value) => {
              setForm(value);
            }}
            height="41px"
            isMulti={false}
          />

          {/* add Date-picker & Alert msg */}
          {isShowDeliveryDate && (
            <>
              <div className="my-3">
                <CustomDatePickerV2
                  placeHolder="Select Delivered Date"
                  value={deliveryDate}
                  handler={(index, date) => setDeliveryDate(date)}
                  height="41px"
                  minDate={moment().subtract(15, "day")}
                  maxDate={moment()} // today's date
                />
              </div>

              <div className="d-flex">
                <img
                  src={AlertIcon}
                  alt="red-alert-icon"
                  width={"18px"}
                  height={"16px"}
                />
                <p
                  className="text-red text-sm mb-0 ml-2"
                  style={{
                    width: "28rem",
                    lineHeight: "normal",
                    fontSize: "13px",
                  }}
                >
                  This action of saving status as delivered cannot be changed
                  once marked. Are you sure you want to proceed?
                </p>
              </div>
            </>
          )}
          <AButton
            className="mt-4 button_remove_left_margin"
            updatedStyle={{ width: "100%" }}
            size="sm"
            variant="primary_filled"
            onClick={updateStatusApiCall}
          >
            Submit
          </AButton>
        </div>
      </Dialog>
    </>
  );
}

export default UpdateStatusPopup;
