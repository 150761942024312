import React from 'react'
import 'views/pages/manage/edpOps/batch-serach/batch-style.scss';
import { ReactComponent as Folder } from 'assets/img/svg/Folder.svg';

const EmptyListPlaceholder = () => {
  return (
    <div className='empty-batch-placeholder'>
      <div className='folder-icon-wrapper'>
        <Folder/>
      </div>
      <span className='empty-list-placeholder'>No students found</span>
    </div>
  )
}

export default EmptyListPlaceholder