
/* eslint-disable */
import { fetchAllPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "views/pages/manage/common/constant";
import { fetchAllPostPromisedData } from "../../common/utils/methods/commonMethods/utilityMethod";

// services
export const createLecture = (request, isEdit = false, state) => {
    let url = endpoint.lecture.createLecture;
    let method = 'post'
    if(isEdit){
      url = `${endpoint.lecture.editLecture}/${request.id}` ;
      method = 'put'
    }
    return fetchAllPostPromisedData(url, request, method,true, { state: state });
};

  
export const getLectures =  (planId, subject,duration )=>{
    let URL = ''
      if(subject){
         URL = `${endpoint.lecture.getLectures}/${planId}?subject=${subject}&duration=${duration}`

      }else{
         URL = `${endpoint.lecture.getLectures}/${planId}?duration=${duration}`

      }
  return fetchAllPromisedData(URL)
}

export const getSubjectCourse = (request)=>{
   
                 
      const {academicPlanId, duration,subjectId} = request ;     
  let url = `${endpoint.lecture.getSubjectCourse}?academicPlanId=${academicPlanId}&duration=${duration}&subjectId=${subjectId}`;
  return fetchAllPromisedData(url, request);
}
export const getLectureById = (lectureId) =>{
  const URL = `${endpoint.lecture.getLecture}/${lectureId}`
  return fetchAllPromisedData(URL)
}

export const updatedIsAddedFlag = (data) => {

  let temp = { ...data };
  temp?.tableOfContent?.forEach((item) => {
    item['isAdded'] = false;

    {
      item?.children?.forEach((topic => {
        topic['isAdded'] = false;
        {
          topic?.children?.forEach((subtopic => {
            subtopic['isAdded'] = false
          }))
        }
      }))
    }
  })

  return temp
}

export const IsAddedFlagSetTrue = (chapter, setExpended, expended = []) => {

  let temp = { ...chapter };
  temp['isAdded'] = true;
  let tempExpended = [temp.key.toString()]
  {
    temp?.children?.forEach((topic => {
      topic['isAdded'] = true;
      tempExpended.push(topic.key.toString())
      {
        topic?.children?.forEach((subtopic => {
          subtopic['isAdded'] = true;
          tempExpended.push(subtopic.key.toString())

        }))
      }
    }))
  }
  if (setExpended)
    setExpended([...expended, ...tempExpended])

  return temp
}

export const setChapterIsAddedFalse = (chapter) => {
  let temp = { ...chapter };
  temp['isAdded'] = false;
  temp?.children?.forEach(topic => {
    topic['isAdded'] = false;
    topic?.children?.forEach(subtopic => {
      subtopic['isAdded'] = false;
    })
  })
  return temp
}
export const getParentNode = (list, node) => {
  for (let item of list) {
    if (item.key === node.parentNode) {
      return item
    }
  }
  return {}
}

