import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Col, Button, Input, Container, Card, Row, CardHeader, CardBody } from "reactstrap";
import CustomLoader from "views/pages/manage/common/commonComponents/Loader/loader";
import { masterServiceBaseUrl } from "services/http";
import { CustomContainer } from "views/pages/manage/common/commonComponents";
import { CustomCard } from "views/pages/manage/common/commonComponents";
import { fetchDetails, getRequiredSign } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { failureToast } from "services/http";
import { putpost } from "services/http";
import { GetTemplate } from "views/pages/manage/common/commonComponents";
import { CustomUpload } from "views/pages/manage/common/commonComponents";
import { Dialog } from "views/pages/manage/common/commonComponents";
import { successToast } from "services/http";
import { getUniqueKey } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "views/pages/manage/common/constant";


const Header = (props) => {
  const {
    groupCodeData,
    setGroupCode,
    companyCodeData,
    setCompanyCodeData,
    zoneData,
    setZoneData,
    regionData,
    setRegionData,
    businessArea,
    setBusinessArea,
    academicCareer,
    setAcademicCareer,
    academicGroup,
    setAcademicGroup,
    course,
    setCourse,
    term,
    setTerm,
    academicYear,
    setAcademicYear,
    academicPhase,
    setAcademicPhase,
    board,
    setBoard,
    subject,
    setSubject,
    headerValues,
    setHeaderValues,
    searchLessonPlan,
    isView = false,
    resetHeader,
  } = props;
  // const [groupCodeData, setGroupCode] = useState(null);
  // const [companyCodeData, setCompanyCodeData] = useState(null);
  // const [zoneData, setZoneData] = useState(null);
  // const [regionData, setRegionData] = useState(null);
  // const [businessArea, setBusinessArea] = useState(null);
  // const [academicCareer, setAcademicCareer] = useState(null);
  // const [academicGroup, setAcademicGroup] = useState(null);
  // const [course, setCourse] = useState(null);
  // const [term, setTerm] = useState(null);
  // const [academicYear, setAcademicYear] = useState(null);
  // const [academicPhase, setAcademicPhase] = useState(null);
  // const [board, setBoard] = useState(null);
  // const [subject, setSubject] = useState(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isFileValid, setISFileValid] = useState(false);
  const [filename, setFileName] = useState("Drag & Drop to Upload");
  const [file, setFile] = useState(null);
  const [progressBarStatus, setProgressBarStatus] = useState(0);
  const [isUploading, setISUploading] = useState(false);


  const search = () => {
    if (!headerValues?.businessGroup?.value) {
      failureToast("Please Select Business Group");
      return;
    }
    if (!headerValues?.companyCode?.value) {
      failureToast("Please Select Comapny Code");
      return;
    }
    if (!headerValues?.zone?.value) {
      failureToast("Please Select Zone");
      return;
    }
    if (!headerValues?.region?.value) {
      failureToast("Please Select Region");
      return;
    }
    if (!headerValues?.businessArea?.value) {
      failureToast("Please Select Business Area");
      return;
    }
    if (!headerValues?.academicCareer?.value) {
      failureToast("Please Select Academic Career");
      return;
    }
    if (!headerValues?.academicGroup?.value) {
      failureToast("Please Select Academic Group");
      return;
    }
    if (!headerValues?.course?.value) {
      failureToast("Please Select Course");
      return;
    }
    if (!headerValues?.term?.value) {
      failureToast("Please Select Term");
      return;
    }
    if (!headerValues?.academicYear?.value) {
      failureToast("Please Select Academic Year");
      return;
    }
    if (!headerValues?.academicPhase?.value) {
      failureToast("Please Select Academic Phase");
      return;
    }
    if (!headerValues?.board?.value) {
      failureToast("Please Select Board");
      return;
    }
    if (!headerValues?.subject?.value) {
      failureToast("Please Select Subject");
      return;
    }

    searchLessonPlan();
  };

  useEffect(() => {
    fetchDetails(`${masterServiceBaseUrl}/groupCode/getAllActiveGroupCode`, setGroupCode, "groupCode", "select");
    fetchDetails(`${masterServiceBaseUrl}/operationalZone/getAllActiveOperationalZone`, setZoneData, "operationalZone", "select");
    fetchDetails(`${masterServiceBaseUrl}/region/getAllActiveRegion`, setRegionData, "region", "select");
    fetchDetails(`${masterServiceBaseUrl}/courseAttributeValueMapping/getAllCourseAttributeValueMapping`, setCourse, "course", "select");

    fetchDetails(`${masterServiceBaseUrl}/academicPhase/active`, setAcademicPhase, "academicPhase", "select");
    fetchDetails(`${masterServiceBaseUrl}/examBoardType/getAllExamBoardType`, setBoard, "examBoardType", "select");
    fetchDetails(`${masterServiceBaseUrl}/subject/getAllActiveSubject`, setSubject, "subject", "select");
  }, []);

  const getCompanyCode = (companyCode) => {
    fetchDetails(`${masterServiceBaseUrl}/companyCode/getCompanyCodeByGroup/${companyCode}`, setCompanyCodeData, "companyCode", "select");
  };

  const getCourseData = (groupCodeId, companyCodeId, academicCareerId, academicGroupId) => {
    fetchDetails(
      `${masterServiceBaseUrl}/courseDetail/getLatestEffectiveCourseIdWithCourseNameByGroupCodeAndCompanyCodeAndAcademicCareerAndAcademicGroup?groupCodeId=${groupCodeId}&companyCodeId=${companyCodeId}&academicCareerId=${academicCareerId}&academicGroupId=${academicGroupId}`,
      setCourse,
      "course",
      "select"
    );
  };

  const getTermData = (groupCodeId, companyCodeId, academicCareerId) => {
    fetchDetails(
      `${masterServiceBaseUrl}/term/getTermByGroupCodeAndAcademicCareerAndCompanyCode?groupCode=${groupCodeId}&companyCode=${companyCodeId}&academicCareer=${academicCareerId}`,
      setTerm,
      "term",
      "select"
    );
  };
  const getAcademicYear = (groupCodeId, companyCodeId, academicCareerId, termId) => {
    const reqBody = {
      groupCodeId: groupCodeId.value,
      companyCodeId: companyCodeId.value,
      academicCareerId: academicCareerId.value,
      termId: termId,
    };
    let temp = [];
    putpost(
      `${masterServiceBaseUrl}/academicYear/getAcademicYearByGroupAndCompanyAndAcademicCareerAndTermId`,
      (data) => {
        data.data.map((item) =>
          temp.push({
            value: item.id,
            label: item.academicYearDisplayValue,
            desc: item.academicYearDescription,
          })
        );
        setAcademicYear(temp);
      },
      (data) => {
        failureToast(data["message"]);
      },
      reqBody,
      "post"
    );
  };

  const getBusinessArea = (companyCodeId) => {
    fetchDetails(
      `${masterServiceBaseUrl}/businessArea/getBusinessAreaByCompanyGroupCode?companyCode=${companyCodeId}&groupCode=${headerValues?.businessGroup?.value}`,
      setBusinessArea,
      "businessArea",
      "select"
    );
  };

  const getAcademicCareer = (companyCodeId) => {
    fetchDetails(
      `${masterServiceBaseUrl}/academicCareer/getAcademicCareerByCompanyCode/${companyCodeId}`,
      setAcademicCareer,
      "academicCareer",
      "select"
    );
  };

  const getAcademicGroup = (companyCodeId) => {
    fetchDetails(`${masterServiceBaseUrl}/academicGroup/getAcademicGroupByCompanyCode/${companyCodeId}`, setAcademicGroup, "academicGroup", "select");
  };

  const handleFile = (e) => {
    try {
      if (e.name.split(".")[1] === "csv") {
        setFileName(e.name);
        setFile(e);
        setISFileValid(true);
      } else {
        failureToast("Only csv files are allowed");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const dataToServer = {
    bulk: true,
    fileName: "Lesson Plan Data",
    docType: "csv",
    keyValue: `lessonplan_${getUniqueKey()}`,
    serviceName: "MASTER",
    subProcessType: "LESSONPLAN"
  };

  const saveFileAndReturnData = (data) => {
    putpost(
      `${masterServiceBaseUrl}/lessonPlanMaster/createBulkLessonPlanMaster?csvUrl=${data?.Location}&processInstanceId=${data?.processId}`,
      (data) => {
        setProgressBarStatus(100);
        successToast("File Uploaded Successfully");
        setModalVisible(!isModalVisible);
        reset();
      },
      (data) => {
        failureToast("Uploading Failed");
      },
      {},
      "post"
    );
  };

  const reset = () => {
    setISFileValid(false);
    setFileName("Drag & Drop to Upload");
    setFile(null);
    setProgressBarStatus(0);
    setISUploading(false);
  };

  return (
    <Container className="mt-3 mb-4" fluid>
      <Dialog
          title="Lesson Plan Bulk Upload"
          isModalVisible={isModalVisible}
          toggle={() => {
            setModalVisible(!isModalVisible);
            reset();
          }}
        >
          <CustomUpload
            documentType="Lesson Plan"
            isFileValid={isFileValid}
            filename={filename}
            file={file}
            progressBarStatus={progressBarStatus}
            setISFileValid={setISFileValid}
            setFileName={setFileName}
            setFile={setFile}
            setProgressBarStatus={setProgressBarStatus}
            isUploading={isUploading}
            setISUploading={setISUploading}
            handleFile={handleFile}
            dataToServer={dataToServer}
            saveHandler={saveFileAndReturnData}
          />
        </Dialog>
          <Card className="mb-0">
            {!groupCodeData || !zoneData || !regionData ? (
              <div className="mx-auto text-center py-5 my-5" style={{ height: "100vh" }}>
                <CustomLoader apiLoader={!groupCodeData || !zoneData || !regionData} />
              </div>
            ) : (
              <>
                <CardHeader>
                  <div className={'d-flex justify-content-between'}>
                    <h3 className="mb-0 "><b>Search Context</b></h3>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                      <GetTemplate url={endpoint.lessonPlanTemplate} />
                      <Button color="info" size="sm" type="button" className={"floatRight"} onClick={() => setModalVisible(true)} >
                        <i className="fas fa-upload" />&nbsp;&nbsp;Upload CSV
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="mb-0">
                  <div className="form-row mb-3">
                    <Col className="mb-3" md="3">
                      <label className="form-control-label">Business Group{getRequiredSign()}</label>
                      <Select
                        value={headerValues.businessGroup}
                        id="building_header_business_grp"
                        options={groupCodeData}
                        isDisabled={isView}
                        placeholder="Select Business Group"
                        onChange={(sData) => {
                          setHeaderValues({
                            ...headerValues,
                            businessGroup: sData,
                            companyCode: null,
                            businessArea: null,
                            academicCareer: null,
                            academicGroup: null,
                            course: null,
                            term: null,
                            academicYear: null,
                          });
                          setCompanyCodeData(null);
                          setBusinessArea(null);
                          setAcademicGroup(null);
                          setCourse(null);
                          setTerm(null);
                          setAcademicYear(null);
                          getCompanyCode(sData.value);
                        }}
                      />
                    </Col>
                    <Col className="mb-3" md="3">
                      <label className="form-control-label">Company Code{getRequiredSign()}</label>
                      <Select
                        value={headerValues.companyCode}
                        id="building_header_company_code"
                        options={companyCodeData}
                        isDisabled={isView}
                        placeholder={"Select Company Code"}
                        onChange={(sData) => {
                          setHeaderValues({
                            ...headerValues,
                            companyCode: sData,
                            businessArea: null,
                            academicCareer: null,
                            academicGroup: null,
                            course: null,
                            term: null,
                            academicYear: null,
                          });
                          setBusinessArea(null);
                          setAcademicGroup(null);
                          setAcademicGroup(null);
                          setCourse(null);
                          setTerm(null);
                          setAcademicYear(null);
                          getBusinessArea(sData.value);
                          getAcademicCareer(sData.value);
                          getAcademicGroup(sData.value);
                        }}
                        isLoading={headerValues.businessGroup && !companyCodeData}
                      />
                    </Col>
                    <Col className="mb-3" md="3">
                      <label className="form-control-label">Zone{getRequiredSign()}</label>
                      <Select
                        value={headerValues.zone}
                        id="building_header_zone"
                        options={zoneData}
                        isDisabled={isView}
                        placeholder="Select Zone"
                        onChange={(sData) => {
                          setHeaderValues({ ...headerValues, zone: sData });
                        }}
                      />
                    </Col>
                    <Col className="mb-3" md="3">
                      <label className="form-control-label">Region{getRequiredSign()}</label>
                      <Select
                        value={headerValues.region}
                        id="building_header_region"
                        options={regionData}
                        isDisabled={isView}
                        placeholder="Select Region"
                        onChange={(sData) => {
                          setHeaderValues({ ...headerValues, region: sData });
                        }}
                      />
                    </Col>
                  </div>
                  <div className="form-row mb-3">
                    <Col className="mb-3" md="3">
                      <label className="form-control-label">Business Area{getRequiredSign()}</label>
                      <Select
                        value={headerValues.businessArea}
                        id="building_header_business_area"
                        options={businessArea}
                        isDisabled={isView}
                        placeholder="Select Business Area"
                        // placeholder={
                        //   headerValues?.businessGroup?.value &&
                        //   headerValues?.companyCode?.value
                        //     ? "Select Business Area"
                        //     : "Select  Bussiness Group & Company Code"
                        // }
                        onChange={(sData) => {
                          setHeaderValues({ ...headerValues, businessArea: sData });
                        }}
                        isLoading={headerValues?.companyCode?.value && !businessArea}
                      />
                    </Col>
                    <Col className="mb-3" md="3">
                      <label className="form-control-label">Academic Career{getRequiredSign()}</label>
                      <Select
                        value={headerValues.academicCareer}
                        id="building_header_business_area"
                        options={academicCareer}
                        isDisabled={isView}
                        placeholder="Select Academic Career"
                        // placeholder={
                        //   headerValues?.businessGroup?.value &&
                        //   headerValues?.companyCode?.value
                        //     ? "Select Business Area"
                        //     : "Select  Bussiness Group & Company Code"
                        // }
                        onChange={(sData) => {
                          setHeaderValues({
                            ...headerValues,
                            academicCareer: sData,
                            course: null,
                            term: null,
                            academicYear: null,
                          });
                          setTerm(null);
                          setCourse(null);
                          setAcademicYear(null);
                          headerValues?.businessGroup?.value &&
                            headerValues?.companyCode?.value &&
                            getTermData(headerValues?.businessGroup?.value, headerValues?.companyCode?.value, sData.value);
                        }}
                        isLoading={headerValues?.businessGrp?.value && headerValues?.companyCode?.value && !academicCareer}
                      />
                    </Col>
                    <Col className="mb-3" md="3">
                      <label className="form-control-label">Academic Group{getRequiredSign()}</label>
                      <Select
                        value={headerValues.academicGroup}
                        id="building_header_business_area"
                        options={academicGroup}
                        isDisabled={isView}
                        placeholder="Select Academic Group"
                        // placeholder={
                        //   headerValues?.businessGroup?.value &&
                        //   headerValues?.companyCode?.value
                        //     ? "Select Business Area"
                        //     : "Select  Bussiness Group & Company Code"
                        // }
                        onChange={(sData) => {
                          setHeaderValues({
                            ...headerValues,
                            academicGroup: sData,
                            course: null,
                          });
                          setCourse(null);
                          getCourseData(headerValues.businessGroup.value, headerValues.companyCode.value, headerValues.academicCareer.value, sData.value);
                        }}
                        isLoading={headerValues?.businessGrp?.value && headerValues?.companyCode?.value && !academicGroup}
                        />
                    </Col>
                    <Col className="mb-3" md="3">
                      <label className="form-control-label">Course ID{getRequiredSign()}</label>
                      <Select
                        value={headerValues?.course}
                        id="courseId"
                        options={course}
                        isDisabled={isView}
                        placeholder="Select Course ID"
                        onChange={(sData) => {
                          setHeaderValues({ ...headerValues, course: sData });
                        }}
                      />
                    </Col>
                  </div>
                  <div className="form-row mb-3">
                    <Col className="mb-3" md="3">
                      <label className="form-control-label">Course Name{getRequiredSign()}</label>
                      <Input
                        className="disabledInput"
                        placeholder={"Course Name"}
                        value={headerValues?.course?.desc.substring(headerValues?.course?.desc.indexOf("-") + 1)}
                        disabled={true}
                        style={{ height: "38px" }}
                      />
                    </Col>

                    <Col className="mb-3" md="3">
                      <label className="form-control-label">Term{getRequiredSign()}</label>
                      <Select
                        value={headerValues.term}
                        id="building_header_business_area"
                        options={term}
                        isDisabled={isView}
                        placeholder="Select Term"
                        // placeholder={
                        //   headerValues?.businessGroup?.value &&
                        //   headerValues?.companyCode?.value
                        //     ? "Select Business Area"
                        //     : "Select  Bussiness Group & Company Code"
                        // }
                        onChange={(sData) => {
                          setHeaderValues({
                            ...headerValues,
                            term: sData,
                            academicYear: null,
                          });
                          setAcademicYear(null);
                          headerValues.academicCareer &&
                            headerValues.companyCode &&
                            headerValues.businessGroup &&
                            sData.value &&
                            getAcademicYear(headerValues.businessGroup, headerValues.companyCode, headerValues.academicCareer, sData.value);
                        }}
                        // isLoading={
                        //   headerValues?.businessGrp?.value &&
                        //   headerValues?.companyCode?.value &&
                        //   !businessData
                        // }
                      />
                    </Col>
                    <Col className="mb-3" md="3">
                      <label className="form-control-label">Academic Year{getRequiredSign()}</label>
                      <Select
                        value={headerValues.academicYear}
                        id="building_header_business_area"
                        options={academicYear}
                        isDisabled={isView}
                        placeholder="Select Academic Year"
                        // placeholder={
                        //   headerValues?.businessGroup?.value &&
                        //   headerValues?.companyCode?.value
                        //     ? "Select Business Area"
                        //     : "Select  Bussiness Group & Company Code"
                        // }
                        onChange={(sData) => {
                          setHeaderValues({ ...headerValues, academicYear: sData });
                        }}
                        // isLoading={
                        //   headerValues?.businessGrp?.value &&
                        //   headerValues?.companyCode?.value &&
                        //   !businessData
                        // }
                      />
                    </Col>
                    <Col className="mb-3" md="3">
                      <label className="form-control-label">Academic Phase{getRequiredSign()}</label>
                      <Select
                        value={headerValues.academicPhase}
                        id="building_header_business_area"
                        options={academicPhase}
                        isDisabled={isView}
                        placeholder="Select Academic Phase"
                        // placeholder={
                        //   headerValues?.businessGroup?.value &&
                        //   headerValues?.companyCode?.value
                        //     ? "Select Business Area"
                        //     : "Select  Bussiness Group & Company Code"
                        // }
                        onChange={(sData) => {
                          setHeaderValues({ ...headerValues, academicPhase: sData });
                        }}
                        // isLoading={
                        //   headerValues?.businessGrp?.value &&
                        //   headerValues?.companyCode?.value &&
                        //   !businessData
                        // }
                      />
                    </Col>
                  </div>
                  <div className="form-row mb-3">
                    <Col className="mb-3" md="3">
                      <label className="form-control-label">Board{getRequiredSign()}</label>
                      <Select
                        value={headerValues.board}
                        id="building_header_business_area"
                        options={board}
                        isDisabled={isView}
                        placeholder="Select Board"
                        // placeholder={
                        //   headerValues?.businessGroup?.value &&
                        //   headerValues?.companyCode?.value
                        //     ? "Select Business Area"
                        //     : "Select  Bussiness Group & Company Code"
                        // }
                        onChange={(sData) => {
                          setHeaderValues({ ...headerValues, board: sData });
                        }}
                        // isLoading={
                        //   headerValues?.businessGrp?.value &&
                        //   headerValues?.companyCode?.value &&
                        //   !businessData
                        // }
                      />
                    </Col>
                    <Col className="mb-3" md="3">
                      <label className="form-control-label">Subject{getRequiredSign()}</label>
                      <Select
                        value={headerValues.subject}
                        id="building_header_business_area"
                        options={subject}
                        isDisabled={isView}
                        placeholder="Select Subject"
                        // placeholder={
                        //   headerValues?.businessGroup?.value &&
                        //   headerValues?.companyCode?.value
                        //     ? "Select Business Area"
                        //     : "Select  Bussiness Group & Company Code"
                        // }
                        onChange={(sData) => {
                          setHeaderValues({ ...headerValues, subject: sData });
                        }}
                        // isLoading={
                        //   headerValues?.businessGrp?.value &&
                        //   headerValues?.companyCode?.value &&
                        //   !businessData
                        // }
                      />
                    </Col>
                  </div>
                  <div className="form-row mb-3">
                    <Col md="12" className="d-flex justify-content-end align-items-end">
                      <Button color={"info"} style={{ padding: "8px 16px" }} type="button" onClick={search}>
                        Search
                      </Button>
                    </Col>
                  </div>
                </CardBody>
              </>
            )} 
          </Card>
    </Container>
  );
};

export default Header;
