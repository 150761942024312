import moment from "moment";
import { endpoint } from "../../../common/constant";
import { fetchAllPromisedData, updateRequest } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { failureToast } from "../../../common/utils/methods/toasterFunctions/function";

export const getSchemesDropdown = (arr) => {
  return arr.map(item => ({
    ...item,
    schemeValue: item?.value,
    label: item?.value,
    value: item?.id
  }));
}

export const fetchData = async (applicationId, quotationDetailsId, schemes, setLoader, isOldQuotationId = false) => {
  setLoader(true);
  debugger
  const p = await Promise.all([
    fetchAllPromisedData(`${endpoint.applicationQuotationDetails}/${applicationId}`),
    fetchAllPromisedData(`${endpoint.deliveryOrder.deliveryOrderDetails}/${quotationDetailsId}`, false, false)
  ])
  setLoader(false);
  const selectedScheme = schemes?.find(item => item?.id === p[0]?.loanSchemeMasterId);
 const finalFinalCourseFee =  isOldQuotationId ==="old"? p[1]?.finalCourseFee: p[0]?.totalNetPayable

  return {
    quotationDetailsId: quotationDetailsId,
    loanSchemeMasterId: selectedScheme,
    loanVendorName: selectedScheme?.vendorName,
    finalCourseFee: finalFinalCourseFee,
    netLoanAmount: p[1]?.netLoanAmount ?? '',
    parentPayingNow: p[1]?.parentPayingNow ?? '',
    totalChargesAlreadyPaid: 0,
    doDate: p[1]?.doDate ?? '',
    doId: p[1]?.doId ?? '',
    doDocKey: p[1]?.doDocKey ?? '',
    updatedBy: p[1]?.updatedBy ?? '',
    updatedOn: p[1]?.updatedOn ?? '',
    createdBy: p[1]?.createdBy ?? '',
    createdOn: p[1]?.createdOn ?? '',
    isNew: !p[1],
    loanTransactionId: p[1]?.id,
  }
}

export const getFileName = ({ updatedOn }) => `DO_Details_${moment(updatedOn).format('DD-MM-YYYY_HH:MM')}`

const getPQ = loanSchemeMasterId => {
  const t = loanSchemeMasterId?.schemeValue?.split('/');
  return { P: t?.[0], Q: t?.[1] };
}

export const getGrossLoanAmount = ({ finalCourseFee, netLoanAmount, loanSchemeMasterId, parentPayingNow, totalChargesAlreadyPaid }) => {
  const { P, Q } = getPQ(loanSchemeMasterId);
  return ((+netLoanAmount * P) / (P - Q)).toFixed(2);
}

export const getAdvanceEmi = ({ finalCourseFee, netLoanAmount, loanSchemeMasterId, parentPayingNow, totalChargesAlreadyPaid }) => {
  const { P, Q } = getPQ(loanSchemeMasterId);
  // console.log(P, 'p', Q, 'Q')
  return ((+netLoanAmount * Q) / (P - Q)).toFixed(2);
}

export const getLoanNotApproved = ({ finalCourseFee, netLoanAmount, loanSchemeMasterId, parentPayingNow, totalChargesAlreadyPaid }) => {
  const { P, Q } = getPQ(loanSchemeMasterId);
  // console.log('A', finalCourseFee, 'B', +netLoanAmount, 'D', (+netLoanAmount * Q) / (P - Q))
  // console.log((finalCourseFee - (+netLoanAmount) - ((+netLoanAmount * Q) / (P - Q))).toFixed(2))
  if(!netLoanAmount) return 0;
  return (finalCourseFee - (+netLoanAmount) - (+netLoanAmount * Q) / (P - Q)).toFixed(2);
}

export const getProcessingFee = ({ finalCourseFee, netLoanAmount, loanSchemeMasterId, parentPayingNow, totalChargesAlreadyPaid }) => {
  return loanSchemeMasterId?.processingFees;
}

export const getNetDisbursement = ({ finalCourseFee, netLoanAmount, loanSchemeMasterId, parentPayingNow, totalChargesAlreadyPaid }) => {
  if(!netLoanAmount) return 0;
  return ((+netLoanAmount) - loanSchemeMasterId?.processingFees).toFixed(2);
}

export const getTotalUpfrontPayment = ({ finalCourseFee, netLoanAmount, loanSchemeMasterId, parentPayingNow, totalChargesAlreadyPaid }) => {
  const { P, Q } = getPQ(loanSchemeMasterId);
  if(!netLoanAmount) return 0;
  return (((+netLoanAmount * Q) / (P - Q)) + (loanSchemeMasterId?.processingFees) + (+parentPayingNow)).toFixed(2);
}

export const getCreateReqBody = (doInformation, applicationQuotationDetailsId) => {
  return updateRequest({
    quotationDetailsId: applicationQuotationDetailsId,
    loanSchemeMasterId: doInformation?.loanSchemeMasterId,
    finalCourseFee: doInformation?.finalCourseFee,
    netLoanAmount: doInformation?.netLoanAmount,
    grossLoanAmount: getGrossLoanAmount(doInformation),
    advanceEmi: getAdvanceEmi(doInformation),
    loanNotApproved: getLoanNotApproved(doInformation),
    processingCharges: getProcessingFee(doInformation),
    netDisbursement: getNetDisbursement(doInformation),
    totalChargesAlreadyPaid: 0,
    balancePayable: getAdvanceEmi(doInformation),
    parentPayingNow: doInformation?.parentPayingNow,
    totalUpfrontPaymentByParent: getTotalUpfrontPayment(doInformation),
    doDate: doInformation?.doDate,
    doId: doInformation?.doId,
    doDocKey: doInformation?.doDocKey,
    status: 'ACTIVE',
  })
}

export const isInvalidReqBody = (doInformation) => {
  const { finalCourseFee, netLoanAmount, loanSchemeMasterId, parentPayingNow } = doInformation;
  const { minimumAmount, vendorName, doIdLength } = loanSchemeMasterId;
  const loanNotApproved = getLoanNotApproved(doInformation);

  if (netLoanAmount > finalCourseFee * 0.8) {
    failureToast('Loan amount cannot be greater than 80% course fee amount')
    return true;
  }

  else if(netLoanAmount < minimumAmount) {
    failureToast(`Minimum amount of Rs ${minimumAmount} is required to avail loan for ${vendorName}`)
    return true;
  }

  else if (+parentPayingNow > loanNotApproved) {
    failureToast('Please enter an amount less than Loan Not Approved')
    return true;
  }

  else if(doInformation?.doId?.length < doIdLength) {
    failureToast(`Please enter the DO id of minimum length ${doIdLength}`)
    return true;
  }

  return false;
}

export const getFileForm = doInformation => {
  return doInformation?.doDocKey ? { fileName: doInformation?.doFileName || getFileName(doInformation), s3key: doInformation?.doDocKey } : {}
}

export const getFeeSummaryRow = (item, itemTypes, prevTotal) => {

  const res = {
    description: itemTypes?.find(o => o?.itemTypeId===item?.itemType)?.description || '-',
    baseFee: item?.grossAmount,
    concessionApplied: item?.concessionApplied,
    amountTotal: item?.netPayable,
    totalTaxes: item?.taxes,
    taxableAmount: item?.payableAmount,
    loanAmount: '',
    eligibilty: '',
    prevTotal: prevTotal,
  }

  return res;
}