import React from 'react';
import {
	Card,
	CardHeader,
	Input,
	Container,
	Button,
	Table,
	Row,
} from 'reactstrap';
import { useState } from 'react';
import Loader from 'react-loader-spinner';
import Select2 from 'react-select2-wrapper';
import { masterServiceBaseUrl, failureToast, getAPI } from 'services/http';
import {
	TaxItemTypeRow,
	TaxItemType,
} from '../../../../common/commonComponents/masterRow/masterRow';
import { successToast, putpost, putpost2 } from 'services/http';
import moment from 'moment';
import CustomButton from 'components/CustomButton';

var _ = require('lodash');

const ItemTypeTable = (props) => {
	const { userPermissions, isSaveVisible, setisSaveVisible=()=>{} } = props;
	// const [isSaveVisible, setisSaveVisible] = useState(false);
	const [tableDataKey, settableDataKey] = useState('tableDataKey');
	const [type, setType] = useState('');
	const [description, setDescription] = useState('');
	const addNewClass = () => {
		props.setclassArray([
			{
				editMode: true,
				__status: '__new',
				status: 'ACTIVE',
				taxCodeId: null,
				type: null,
				description: null,
				itemTypeId: null,
			},
			...props.classArray,
		]);
	};
	const saveAll = (cb) => {
		var _tempArray = props.classArray;
		var isValid = true;
		_tempArray.forEach((el, index) => {
			if (el['editMode']) {
				if (el['itemTypeId'] == null) {
					isValid = false;
					failureToast('Please select Item Type');
					return;
				}
				if (!isValid) {
					return;
				}
				if (_tempArray[index]['__status'] == '__new') {
					_tempArray[index]['taxCodeId'] = props.taxCodeId;
					putpost(
						masterServiceBaseUrl +
							'/itemTypeTaxComponent/createItemTypeTaxComponent',
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							props.incrementCount();
							props.fetchClasses(
								`${masterServiceBaseUrl}/itemTypeTaxComponent/getItemTypeTaxComponentByTaxCodeId/${props.taxCodeId}`
							);
							setType('');
							setDescription('');
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'post'
					);
				} else {
					setLoader(true);
					putpost(
						masterServiceBaseUrl +
							'/itemTypeTaxComponent/updateItemTypeTaxComponent/' +
							_tempArray[index].id,
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							delete _tempArray[index]['editMode'];
							// props.fetchClasses(masterServiceBaseUrl + '/itemTypeTaxComponent/getAllItemTypeTaxComponent')
							props.setclassArray(_tempArray);
							settableDataKey(new Date().getMilliseconds());
							setLoader(false);
							// props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
						},
						(data) => {
							failureToast(data['message']);
							setLoader(false);
						},
						_tempArray[index],
						'put'
					);
				}
			} else {
			}
		});
	};
	const newRow = () => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				addNewClass();
				setisSaveVisible(true);
				// settableDataKey(new Date().getMilliseconds())
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			props.setclassArray(_tempArray);
			setisSaveVisible(false);
			cb();
		}
	};
	const editRow = (index) => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				var _tempArray = props.classArray;
				_tempArray[index]['editMode'] = true;
				props.setclassArray(_tempArray);
				setisSaveVisible(true);
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			cb();
		}
	};
	const deleteRow = (index) => {
		if (props.classArray[index]['__status'] == '__new') {
			let n = props.classArray;
			n.splice(index, 1);
			props.setclassArray(n);
			setisSaveVisible(false);
			settableDataKey(new Date().getMilliseconds());
		} else {
			if (window.confirm('Are you sure you want to delete this Item Type ?')) {
				putpost2(
					masterServiceBaseUrl +
						'/itemTypeTaxComponent/deleteItemTypeTaxComponent/' +
						props.classArray[index].id,
					(data) => {
						successToast(data['message']);
						if (props.classArray.length == 1) {
							props.previousPage();
							props.fetchClasses(
								`${masterServiceBaseUrl}/itemTypeTaxComponent/getItemTypeTaxComponentByTaxCodeId/${props.taxCodeId}`
							);
						} else {
							var _tempArray = props.classArray;
							if (_tempArray[index]['editMode']) {
								setisSaveVisible(false);
							}
							_tempArray.splice(index, 1);
							props.setclassArray(_tempArray);
						}
						settableDataKey(new Date().getMilliseconds());
						props.decrementCount();
					},
					(data) => {
						failureToast(data['message']);
					},
					'PUT'
				);
			}
		}
	};
	const updateKey = (index, value, key) => {
		setType('');
		var _tempArray = props.classArray;
		_tempArray[index][key] = value;
		setType(getNameById(value, props.unSortedList, 'type'));
		setDescription(getNameById(value, props.unSortedList, 'description'));
		props.setclassArray(_tempArray);
	};

	const getNameById = (id, list, key) => {
		const item = list.filter((item) => {
			return item.id == id;
		});
		const name = item.length ? item[0][key] : null;
		return name;
	};

	const [loader, setLoader] = useState(false);
	const [searchStr, setSearchStr] = useState('');
	const [emptymsg, setemptymsg] = useState('');

	const fetchSearchResult = async (str) => {
		setLoader(true);
		getAPI(
			`${masterServiceBaseUrl}/itemTypeTaxComponent/searchItemTypeTaxComponent/${props.taxCodeId}/${str}`,
			(data) => {
				props.SetDataFromServer(data['data']);
				if (data['data'].length === 0) {
					setemptymsg('No Records Found');
				}
				setLoader(false);
			},
			(data) => {
				failureToast(data['message']);
				setLoader(false);
			}
		);
	};

	return (
		<>
			<Container className="mt-3" fluid>
				<Row className="mb-3">
					<div className="col">
						<Card className="p-3">
							<div className="d-flex flex-colunm">
								<div className="d-flex flex-row">
									<Input
										placeholder={`Search`}
										type="text"
										value={searchStr}
										onChange={(e) => setSearchStr(e.target.value)}
									/>
								</div>
								<Button
									color="primary"
									className={'ml-2'}
									disabled={searchStr && searchStr.length > 2 ? false : true}
									onClick={() => fetchSearchResult(searchStr)}
								>
									Search
								</Button>
								<Button
									color="secondary"
									onClick={() => {
										props.fetchClasses(
											`${masterServiceBaseUrl}/itemTypeTaxComponent/getItemTypeTaxComponentByTaxCodeId/${props.taxCodeId}`
										);
										setSearchStr('');
										setemptymsg('');
									}}
								>
									Clear
								</Button>
							</div>
							{searchStr && searchStr.length < 3 ? (
								<span style={{ fontSize: '12px', paddingTop: '5px' }}>
									Please Enter Atleast Three Characters
								</span>
							) : null}
						</Card>
					</div>
				</Row>
				<Row>
					<div className="col">
						<Card>
							<CardHeader className="border-0">
								<CustomButton
									disabled={isSaveVisible || searchStr !== ''}
									className={
										'floatRight mx-1' +
										(isSaveVisible || searchStr !== ''
											? ' btn-dark cursor-gray'
											: null)
									}
									content={'New Item Type'}
									permissionType={'C'}
									icon={true}
									forTable={true}
									permissionSet={userPermissions}
									onClick={() => {
										newRow();
									}}
								/>
							</CardHeader>
							<div id="questionTable">
								<Table className="align-items-center table-flush tableLayout">
									<TaxItemType
										type={'Tax Component'}
										isSaveVisible={isSaveVisible}
										permissionSet={userPermissions}
									/>
									{loader ? (
										<div style={{ backgroundColor: 'white', height: '100px' }}>
											<div style={{ position: 'absolute', left: '40%' }}>
												<Loader
													type="Rings"
													color="#00BFFF"
													height={70}
													width={70}
												/>
											</div>
										</div>
									) : (
										<tbody className="list" key={tableDataKey}>
											{props.classArray
												? props.classArray.map((el, index) =>
														!el['editMode'] ? (
															<TaxItemTypeRow
																type={'itemTypeTaxComponent'}
																index={index}
																el={el}
																editRow={() => {
																	editRow(index);
																	setDescription(
																		getNameById(
																			el['itemTypeId'],
																			props.unSortedList,
																			'description'
																		)
																	);
																}}
																deleteRow={deleteRow}
																itemTypeData={props.unSortedList}
																isSaveVisible={isSaveVisible}
																permissionSet={userPermissions}
															/>
														) : (
															<tr key={index + '-class'}>
																<td className="text-center p-2 mx-1">
																	<Select2
																		defaultValue={el['itemTypeId']}
																		data={props.itemTypeData}
																		onChange={(e) => {
																			updateKey(
																				index,
																				e.target.value,
																				'itemTypeId'
																			);
																		}}
																		options={{
																			placeholder: 'Select Item Type',
																		}}
																		className="tdSelect"
																	/>
																</td>

																<td className="text-center p-2 mx-1">
																	<Input
																		disabled={true}
																		value={getNameById(
																			el['itemTypeId'],
																			props.unSortedList,
																			'type'
																		)}
																		placeholder="Type"
																		className="tdInput"
																	/>
																</td>

																<td className="text-center p-2 mx-1">
																	<Input
																		disabled={true}
																		defaultValue={
																			el['__status'] == '__new'
																				? null
																				: moment(el['effectiveDate']).format(
																						'DD-MM-YYYY'
																				  )
																		}
																		placeholder="Example:01-01-2020"
																		className="tdInput"
																	/>
																</td>

																<td className="text-center p-2 mx-1">
																	<Input
																		disabled={true}
																		value={getNameById(
																			el['itemTypeId'],
																			props.unSortedList,
																			'description'
																		)}
																		placeholder="Description"
																		className="tdInput"
																	/>
																</td>
																<td className="text-center p-2 mx-1">
																	<label className="custom-toggle mx-auto ml-2">
																		<input
																			checked={
																				el.status == 'ACTIVE' ? true : false
																			}
																			type="checkbox"
																			id={el.id}
																			onChange={(e) => {
																				settableDataKey(
																					new Date().getMilliseconds()
																				);
																				let n = 'ACTIVE';
																				if (!e.target.checked) {
																					n = 'INACTIVE';
																				}
																				updateKey(index, n, 'status');
																			}}
																		/>
																		<span
																			style={{ width: '72px' }}
																			className="custom-toggle-slider rounded-circle activeToggle"
																			data-label-off="Inactive"
																			data-label-on="Active"
																		/>
																	</label>
																</td>
																<td className="text-center">
																	{isSaveVisible ? (
																		<>
																			<CustomButton
																				content={'Save'}
																				permissionType={'C,U'}
																				forTable={true}
																				permissionSet={userPermissions}
																				onClick={() => {
																					saveAll();
																				}}
																			/>
																			<CustomButton
																				permissionType={'cancel'}
																				icon={true}
																				forTable={true}
																				permissionSet={userPermissions}
																				onClick={() => {
																					let n = props.classArray;
																					if (n[index]['__status'] == '__new') {
																						n.splice(index, 1);
																					} else {
																						// n[index]['editMode']=false
																						props.fetchClasses(
																							`${masterServiceBaseUrl}/itemTypeTaxComponent/getItemTypeTaxComponentByTaxCodeId/${props.taxCodeId}`
																						);
																					}
																					props.setclassArray(n);
																					setisSaveVisible(false);
																					setType('');
																					setDescription('');
																				}}
																			/>
																		</>
																	) : (
																		<CustomButton
																			permissionType={'D'}
																			icon={true}
																			forTable={true}
																			permissionSet={userPermissions}
																			onClick={() => {
																				deleteRow(index);
																				setType('');
																				setDescription('');
																			}}
																		/>
																	)}
																</td>
															</tr>
														)
												  )
												: null}
											{emptymsg && searchStr ? (
												<div className={'mt-3 ml-3 mb-3 w-100'}>
													{' '}
													<span>{emptymsg}</span>
												</div>
											) : null}
										</tbody>
									)}
								</Table>
							</div>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};
export default ItemTypeTable;
