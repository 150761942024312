import React from "react";
import { Input, Button, Table, Label, FormGroup } from "reactstrap";
import { useState } from "react";
// import "views/pages/manage/addressType/node_modules/react-sliding-pane/dist/react-sliding-pane.css";
import { failureToast } from "../../common/utils/methods/toasterFunctions/function";
import Select2 from "react-select2-wrapper";
import { MasterHeaderAuto } from "../../common/commonComponents/masterHeader/masterHeader";
import { NewRow } from "../../common/utils/methods/commonMethods/utilityMethod";

import {
  putpost2,
  putpost,
  successToast,
  masterServiceBaseUrl,
  baseUrl,
} from "services/http";
import moment from "moment";
import {
  CustomCard,
  CustomContainer
} from "../../common/commonComponents";


const HeaderElement = ({
  data,
  newRow,
  isSaveVisible,

}) => {
  
 
  return (
    <>
      <h3>Master - Attendance Device Type</h3>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Button
          disabled={isSaveVisible}
          color="info"
          size="sm"
          type="button"
          className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
          onClick={newRow}
        >
          
          Add New
        </Button>
      </div>
    </>
  );
};

var _ = require("lodash");

const AttendanceDeviceTypeTable = (props) => {
  const {
    isSaveVisible,
    setisSaveVisible = () => {},
    pagination,
  } = props;

  const [tableDataKey, settableDataKey] = useState("tableDataKey");
  const [isNewDocument,setIsNewDocument] = useState(false)

  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: "__new",
        attendanceDeviceTypeKey: "",
        description: "",
        displayValue: "",
        status: "ACTIVE",
      },
      ...props.classArray,
    ]);
  };

  const headerList = [
    { name: "S.No" },
    { name: "Description", isRequired: true },
    { name: "Display Value", isRequired: true },
    { name: "Effective Date" },
    { name: "Status", isRequired: true },
    { name: "Actions" },
  ];

  const listToBeRendered = [
    "sno",
    "description",
    "displayValue",
    "effectiveDate",
    "status",
  ];


  const saveAll = (cb) => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if (el["editMode"]) {
        if (!el["description"] || !el["description"].trim()) {
          isValid = false;
          failureToast("Please Enter Description");
          return;
        } else if (!el["displayValue"] || !el["displayValue"].trim()) {
          isValid = false;
          failureToast("Please Enter Display Value");
          return;
        }
        if (!isValid) {
          return;
        }
        if (_tempArray[index]["__status"] == "__new") {
          putpost(
            masterServiceBaseUrl + "/attendanceDeviceType/createAttendanceDeviceType",
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              props.fetchClasses(
                masterServiceBaseUrl + "/attendanceDeviceType/getAllAttendanceDeviceType"
              );
            },
            (data) => {
              failureToast(data["message"]);
            },
            {
              ..._tempArray[index],
              displayValue: _tempArray[index]["displayValue"].toUpperCase(),
            },
            "post"
          );
        } else {
          const updatedValues = _tempArray[index];
          delete updatedValues.attendanceDeviceTypeKey;

          putpost(
            masterServiceBaseUrl +
              "/attendanceDeviceType/updateAttendanceDeviceType/" +
              _tempArray[index].id,
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              props.fetchClasses(
                masterServiceBaseUrl + "/attendanceDeviceType/getAllAttendanceDeviceType"
              );
              // delete _tempArray[index]["editMode"];
              // console.log(_tempArray[index]);
              // props.setclassArray(_tempArray);
              settableDataKey(new Date().getMilliseconds());
            },
            (data) => {
              failureToast(data["message"]);
            },
            {
              ...updatedValues,
              displayValue: updatedValues["displayValue"].toUpperCase(),
            },
            "put"
          );
        }
      }
    });
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
        setIsNewDocument(true)
        // settableDataKey(new Date().getMilliseconds())
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
      cb();
    }
  };

  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]["editMode"] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
      };
      cb();
    }
  };
  const deleteRow = (index) => {};

  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
  };

  return (
    <CustomContainer>
      <CustomCard
        HeaderElement={
          <HeaderElement
            isSaveVisible={isSaveVisible}
            newRow={newRow}
          />
        }
      >
        <div id="questionTable" className="table-responsive">
          <Table className="align-items-center table-flush tableLayout">
            <MasterHeaderAuto headerList={headerList} />
            <tbody className="list" key={tableDataKey}>
              {props.classArray
                ? props.classArray.map((el, index) =>
                    !el["editMode"] ? (
                      <NewRow
                        data={{
                          ...el,
                          sno: isSaveVisible && isNewDocument ? index + (pagination.currentPage - 1) * 10 : index + 1 + (pagination.currentPage - 1) * 10,
                        }}
                        listToBeRendered={listToBeRendered}
                      >
                        <td className="text-center  white-breakSpace">
                          <Button
                            color="info"
                            size="sm"
                            type="button"
                            disabled={props.isViewOnly}
                            onClick={() => editRow(index)}
                            // data-testid="editButton"
                          >
                            <i className="fas fa-edit" />
                          </Button>
                        </td>
                      </NewRow>
                    ) : (
                      <tr key={index + "-class"}>
                        <td className="text-center p-2 mx-1">
                          <Input
                            value={el["__status"] !== "__new" ? index + 1 + (pagination.currentPage - 1) * 10 : ""}
                            placeholder="S.No."
                            className="tdInput"
                            disabled
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={50}
                            defaultValue={el["description"]}
                            onKeyPress={(e) => {
                              if (
                                !/^[a-zA-Z0-9, /\\_-]*$/.test(
                                  String.fromCharCode(e.charCode)
                                )
                              ) {
                                e.preventDefault();
                                return;
                              }
                            }}
                            onChange={(e) => {
                              updateKey(
                                index,
                                e.target.value.trim(),
                                "description"
                              );
                            }}
                            placeholder="Description"
                            className="tdInput"
                            
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={10}
                            defaultValue={el["displayValue"]}
                            onKeyPress={(e) => {
                              if (
                                !/^[a-zA-z0-9 _]+$/.test(
                                  String.fromCharCode(e.charCode)
                                )
                              ) {
                                e.preventDefault();
                                return;
                              }
                            }}
                            onChange={(e) => {
                              updateKey(index, e.target.value, "displayValue");
                            }}
                            placeholder="Display Value"
                            className="tdInput"
                            
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            defaultValue={moment(el["effectiveDate"]).format(
                              "DD-MM-YYYY"
                            )}
                            disabled
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <label className="custom-toggle mx-auto ml-2">
                            <input
                              checked={el.status == "ACTIVE" ? true : false}
                              type="checkbox"
                              id={el.id}
                              onChange={(e) => {
                                settableDataKey(new Date().getMilliseconds());
                                let n = "ACTIVE";
                                if (!e.target.checked) {
                                  n = "INACTIVE";
                                }
                                updateKey(index, n, "status");
                              }}
                            />
                            <span
                              style={{ width: "72px" }}
                              className="custom-toggle-slider rounded-circle activeToggle"
                              data-label-off="Inactive"
                              data-label-on="Active"
                            />
                          </label>
                        </td>
                        <td className="text-center">
                          {" "}
                          <Button
                            color="info"
                            size="sm"
                            type="button"
                            className="mx-1"
                            onClick={() => {
                              saveAll();
                            }}
                          >
                            Save
                          </Button>
                          <Button
                            color="danger"
                            size="sm"
                            type="button"
                            onClick={() => {
                              let n = props.classArray;
                              if (n[index]["__status"] == "__new") {
                                n.splice(index, 1);
                              } else {
                                // n[index]['editMode']=false

                                props.fetchClasses(
                                  masterServiceBaseUrl +
                                    "/attendanceDeviceType/getAllAttendanceDeviceType"
                                );
                              }
                              props.setclassArray(n);
                              setisSaveVisible(false);
                              setIsNewDocument(false)
                            }}
                          >
                            {" "}
                            <i className="fas fa-times" />
                          </Button>{" "}
                        </td>
                      </tr>
                    )
                  )
                : null}
            </tbody>
          </Table>
        </div>
      </CustomCard>
    </CustomContainer>
  );
};
export default AttendanceDeviceTypeTable;
