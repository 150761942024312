import React, { useState, useEffect, useContext } from 'react';
import { Button, Card, CardContent, CardHeader, Container, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { endpoint, pages } from '../../common/constant'
import MUISelect from './component/select';
import { fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import { PermissionContext, RolePermissions } from 'appContext'
import CustomButton from 'components/CustomButton';
import { failureToast } from '../../common/utils/methods/toasterFunctions/function';
import CustomLoader from '../../common/commonComponents/Loader/loader';
import { BreakupGrid } from './component/breakupGrid';
import moment from 'moment';


const HorizonalFormControl = styled(FormControl)({
    flexDirection: 'row',
    alignItems: 'center',
    '& .MuiFormLabel-root': {
        paddingRight: 10
    }
});


const FeeCalculator = () => {

    const permissions = useContext(PermissionContext);
    const userPermissions = RolePermissions(permissions, pages["feeCalculator"]['id']);

    const [values, setValues] = useState({
        term: '',
        course: '',
        businessUnit: ''
    })

    const [selectedCalType, setSelectedCalType] = useState('New Addmission');
    const [consessionType, setConsessionType] = useState('Enter Manually');
    const [term, setTerm] = useState({ fetching: true, data: [] });
    const [course, setCourse] = useState({ fetching: false, data: [] });
    const [businessUnit, setBusinessUnit] = useState({ fetching: false, data: [] });
    const [businessAreaData, setBusinessAreaData] = useState({ fetching: true, data: [] });
    const [allCourseData, setAllCourseData] = useState({ fetching: true, data: [] });
    const [itemTypes, setItemTypes] = useState([]);
    const [calculatorType, setCalculatorType] = useState({ fetching: true, data: [] });
    const [uniqueBreakup, setUniqueBreakupData] = useState([]);
    const [groupedBreakUp, setGroupedBreakup] = useState([]);
    const [breakupData, setBreakupData] = useState({ fetching: true, data: [] });
    const [consession, setConsession] = useState({})
    const [fetchingBreakUp, setFetchingBreakUp] = useState(false)

    const [processedData, setProcessedData] = useState({});
    const [totals, setTotals] = useState({});
    const [showBreakupGris, setShowBreakupGrid] = useState(false);

    const getCalculatorTypes = async () => {
        const cal = await fetchAllPromisedData(endpoint.calculatorType.getAll, false);
        if (Array.isArray(cal)) {
            const tempData = cal?.map(p => { return { label: p.calculatorTypeDispValue, value: p.id } })
            setCalculatorType({ fetching: false, data: tempData })
        } else {
            setCalculatorType({ fetching: false, data: [] })
        }
    }

    const getTermData = async () => {
        const academicTerm = await fetchAllPromisedData(endpoint.term.getAllActive, false);
        if (Array.isArray(academicTerm)) {
            const tempData = academicTerm?.map(p => { return { label: p.termDispValue, value: p.id } })
            setTerm({ fetching: false, data: tempData })
        } else {
            setTerm({ fetching: false, data: [] })
        }
    }

    const getItemTypeData = async () => {
        const itemType = await fetchAllPromisedData(endpoint.item_type_setup.getAll, false);
        if (Array.isArray(itemType)) {
            // const tempData = itemType?.map(p => { return { label: p.description, value: p.id } })
            setItemTypes({ fetching: false, data: itemType })
        } else {
            setItemTypes({ fetching: false, data: [] })
        }
    }

    const getBusinessAreaData = async () => {
        setBusinessAreaData({ fetching: true, data: [] })
        const bussiness = await fetchAllPromisedData(endpoint.businessArea.getAllCached, false);
        if (Array.isArray(bussiness)) {
            const tempData = bussiness?.map(p => { return { label: `${p.businessAreaDispValue} (${p.businessAreaDesc})`, value: p.id } })
            setBusinessAreaData({ fetching: false, data: tempData })
        } else {
            setBusinessAreaData({ fetching: false, data: [] })
        }
    }

    const getAllCourseIds = async () => {
        setCourse({ fetching: true, data: [] })
        const courseRes = await fetchAllPromisedData(`${endpoint.course.courseIds}/${values.term?.value}`, false);
        if (Array.isArray(courseRes)) {
            const tempData = courseRes?.map(p => {
                const cDetails = allCourseData.data.filter(c => c.courseId == p)
                return { label: `${p} ${cDetails?.length ? `(${cDetails?.[0]?.courseName})` : ''}`, value: p }
            })
            setCourse({ fetching: false, data: tempData })
        } else {
            setCourse({ fetching: false, data: [] })
        }
    }

    const getAllBusinessUnit = async () => {
        setBusinessUnit({ fetching: true, data: [] })
        const businessUnitRes = await fetchAllPromisedData(`${endpoint.pricingBusinessArea.businessAreas}/${values.term?.value}/${values.course?.value}`, false);
        if (Array.isArray(businessUnitRes)) {
            const tempData = businessAreaData?.data?.filter(p => businessUnitRes.indexOf(p.value) > -1)
            setBusinessUnit({ fetching: false, data: tempData })
        } else {
            setBusinessUnit({ fetching: false, data: [] })
        }
    }

    const getCourseDetails = async () => {
        setAllCourseData({ fetching: true, data: [] })
        const courseDetails = await fetchAllPromisedData(`${endpoint.course_details.getCachedRecords}`, false);
        if (Array.isArray(courseDetails)) {
            setAllCourseData({ fetching: false, data: courseDetails })
        } else {
            setAllCourseData({ fetching: false, data: [] })
        }
    }

    const getBreakUp = async () => {

        if (!values.term) {
            failureToast("Please select term");
            return
        }

        if (!values.course) {
            failureToast("Please select course");
            return
        }

        if (!values.businessUnit) {
            failureToast("Please select business area");
            return
        }

        setFetchingBreakUp(true)

        setBreakupData({ fetching: true, data: [] })
        const breakup = await fetchAllPromisedData(`${endpoint.breakup.getAll}/${values.term?.value}/${values.course?.value}/${values.businessUnit?.value}`, true);

        if (breakup?.code === 200) {

            const uniqueElemets = [];

            breakup.data.forEach(element => {
                const elementType = element?.itemType?.slice(-2);
                if (uniqueElemets.indexOf(elementType) === -1) {
                    uniqueElemets.push(elementType)
                }
            });

            let itemTypeLabelMapping = {};
            uniqueElemets.map(item => {
                const des = itemTypes.data.find(i => i.itemTypeId?.slice(-2) === item);
                itemTypeLabelMapping = { ...itemTypeLabelMapping, [item]: des?.description?.split("-")?.[0] ?? '' }
            })

            const tempData = breakup?.data?.map(item => {
                const des = itemTypes.data.find(i => i.itemTypeId === item.itemType);
                return { ...item, desc: des.description }
            })

            const groupedData = tempData?.reduce(function (r, a) {
                r[moment(a.dueDate).format("DD MMM YYYY")] = r[moment(a.dueDate).format("DD MMM YYYY")] || [];
                r[moment(a.dueDate).format("DD MMM YYYY")].push(a);
                return r;
            }, Object.create(null));

            setGroupedBreakup(groupedData)
            setUniqueBreakupData(itemTypeLabelMapping)
            setBreakupData({ fetching: false, data: breakup?.data })
            setFetchingBreakUp(false)
            getCourseDetails()
        } else {
            setBreakupData({ fetching: false, data: [] })
            setFetchingBreakUp(false)
        }
    }

    const handleSearchValuesChange = (value, key) => {
        setValues({ ...values, [key]: value })
    }

    useEffect(() => {
        getBusinessAreaData()
        getCourseDetails()
        getCalculatorTypes()
        getTermData()
        getItemTypeData()
    }, [])

    useEffect(() => {
        if (values.term?.value) {
            getAllCourseIds()
            setValues({
                ...values,
                course: '',
                businessUnit: ''
            })
        }
    }, [values.term])

    useEffect(() => {
        if (values.course?.value) {
            getAllBusinessUnit()
            setValues({
                ...values,
                businessUnit: ''
            })
        }
    }, [values.course])


    const reset = () => {
        setValues({
            term: '',
            course: '',
            businessUnit: ''
        })
        setBreakupData({ fetching: true, data: [] });
        setConsession({});
    }


    const createBreakUpData = () => {
        let valid = true;
        Object.keys(consession).every(item => {
            if (consession[item] > 100) {
                failureToast("Maximum concession can be 100");
                valid = false
                return false
            } else if (consession[item].split(".")?.[1]?.length && consession[item].split(".")?.[1]?.length > 2) {
                failureToast("Maximum two decimals are allowed");
                valid = false
                return false
            } else {
                return true
            }
        })
        if (!valid) {
            return
        }

        let tempData = {};
        let totalTempData = {};
        Object.keys(groupedBreakUp).map(year => {
            const procArray = []
            let conessionTotal = 0;
            let netPayableTotal = 0;
            let payableTotal = 0;
            let grossTotal = 0;
            let taxTotal = 0;
            groupedBreakUp[year].map(item => {
                const taxPercent = item['baseAmount'] ? Math.round((item['tax'] / item['baseAmount']) * 100) : 0;
                const consessionAmt = consession?.[item.itemType?.slice(-2)] ? (consession[item.itemType?.slice(-2)] * item['baseAmount']) / 100 : 0;
                const payableAmt = item['baseAmount'] - consessionAmt;
                const tax = (payableAmt * taxPercent)/100;
                const netPayable = taxPercent ? Math.round(payableAmt* 100) / 100 + Math.round(tax* 100) / 100 : Math.round(payableAmt);
                const tempObj = {
                    ...item,
                    consession: Math.round(consessionAmt* 100) / 100,
                    payableAmt: Math.round(payableAmt* 100) / 100,
                    netPayable: Math.round(netPayable* 100) / 100,
                    tax: Math.round(tax* 100) / 100
                }
                conessionTotal = conessionTotal + Math.round(consessionAmt* 100) / 100;
                netPayableTotal = netPayableTotal +  Math.round(netPayable* 100) / 100;
                payableTotal = payableTotal + Math.round(payableAmt* 100) / 100;
                grossTotal = grossTotal + Math.round(item.baseAmount* 100) / 100;
                taxTotal = taxTotal + Math.round(tax* 100) / 100;
                procArray.push(tempObj);
            })

            tempData = { ...tempData, [year]: procArray }
            totalTempData = {
                ...totalTempData,
                [year]: {
                    totalGross: Math.round(grossTotal* 100) / 100,
                    conAmt: Math.round(conessionTotal* 100) / 100,
                    totalPayAmt: Math.round(payableTotal* 100) / 100,
                    totalTax: Math.round(taxTotal* 100) / 100,
                    netAmt: Math.round(netPayableTotal* 100) / 100,
                }
            }
        })

        setProcessedData({ ...tempData });
        setTotals({ ...totalTempData })
        setShowBreakupGrid(true)
    }


    return (
        <Container maxWidth="xl">
            <Card>
                {
                    businessAreaData?.fetching || allCourseData?.fetching ?
                        <div>
                            <div
                                className="mx-auto text-center py-5 my-5 "
                                style={{ height: '100vh', width: '100%' }}
                            >
                                <CustomLoader apiLoader={businessAreaData?.fetching || allCourseData?.fetching} />
                            </div>
                        </div> : <>
                            <CardHeader title='Fee Calculator' />
                            <hr />
                            <CardContent>
                                {!showBreakupGris && <>
                                    <Typography variant="h6" component="h3">
                                        Basic Details
                                    </Typography>
                                    <Grid spacing={2} sx={{ mb: 3 }}>
                                        <Grid xs={12} sm={12} md={12} sx={{ mt: 1 }}>
                                            <HorizonalFormControl>
                                                <FormLabel id="demo-row-radio-buttons-group-label"> Calculator Type : {" "}</FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    onChange={e => setSelectedCalType(e.target.value)}
                                                    value={selectedCalType}
                                                >
                                                    <FormControlLabel control={<Radio />} value={'New Addmission'} label="New Addmission" />
                                                    {/* this should be unhidden in future */}
                                                    {/* {
                                                        calculatorType.data.map(item => {
                                                            return <FormControlLabel value={item.label} control={<Radio />} label={item.label} />
                                                        })
                                                    } */}
                                                </RadioGroup>
                                            </HorizonalFormControl>
                                        </Grid>
                                        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Grid xs={12} sm={6} md={3} sx={{ m: 2 }}>
                                                <MUISelect
                                                    label={'Select Term'}
                                                    placeholder={'Select Term'}
                                                    inputKey={'term'}
                                                    currentValue={values.term}
                                                    items={term.data}
                                                    isFetching={term.fetching}
                                                    handleChange={handleSearchValuesChange}
                                                    required={true}
                                                    disabled={breakupData?.data?.length > 0}
                                                />
                                            </Grid>
                                            <Grid xs={12} sm={6} md={3} sx={{ m: 2 }}>
                                                <MUISelect
                                                    label={'Select Course'}
                                                    placeholder={'Select Course'}
                                                    inputKey={'course'}
                                                    currentValue={values.course}
                                                    items={course.data}
                                                    isFetching={course.fetching}
                                                    handleChange={handleSearchValuesChange}
                                                    disabled={!values.term || breakupData?.data?.length > 0}
                                                    required={true}
                                                />
                                            </Grid>
                                            <Grid xs={12} sm={6} md={3} sx={{ m: 2 }}>
                                                <MUISelect
                                                    label={'Select Business Area'}
                                                    placeholder={'Select Business Area'}
                                                    inputKey={'businessUnit'}
                                                    currentValue={values.businessUnit}
                                                    items={businessUnit.data}
                                                    isFetching={businessUnit.fetching || (values.course && businessAreaData?.fetching)}
                                                    handleChange={handleSearchValuesChange}
                                                    disabled={!values.course || breakupData?.data?.length > 0 || (values.course && businessAreaData?.fetching)}
                                                    required={true}
                                                />
                                            </Grid>
                                            <Grid xs={12} sm={6} md={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                {/* <Button variant="contained" className='mt-4' onClick={getBreakUp}>Next</Button> */}
                                                {breakupData?.data?.length == 0 && <CustomButton
                                                    content={fetchingBreakUp ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : "Next"}
                                                    permissionType={'C'}
                                                    permissionSet={userPermissions}
                                                    onClick={getBreakUp}

                                                />}

                                                <CustomButton
                                                    content={"Reset"}
                                                    permissionType={'C'}
                                                    permissionSet={userPermissions}
                                                    onClick={reset}
                                                    disabled={!values?.term?.value}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {fetchingBreakUp ? (
                                        <>
                                            <hr />
                                            <div>
                                                <div
                                                    className="mx-auto text-center py-5 my-5 "
                                                    style={{ height: '100vh', width: '100%' }}
                                                >
                                                    <CustomLoader apiLoader={fetchingBreakUp} />
                                                </div>
                                            </div>
                                        </>
                                    ) : breakupData?.data?.length > 0 ? <>
                                        <hr />
                                        <Typography variant="h6" component="h3" sx={{ mt: 2 }}>
                                            Concession Details
                                        </Typography>
                                        <Grid rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                            <Grid xs={12} sm={12} md={12} sx={{ mt: 1 }}>
                                                <HorizonalFormControl>
                                                    <FormLabel id="demo-row-radio-buttons-group-label"> Concession Type : {" "}</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        value={consessionType}
                                                        onChange={e => setConsessionType(e.target.value)}
                                                    >
                                                        <FormControlLabel value="Enter Manually" control={<Radio />} label="Enter Manually" />
                                                        {/* <FormControlLabel value="Pick from prospectus" control={<Radio />} label="Pick from prospectus" /> */}
                                                    </RadioGroup>
                                                </HorizonalFormControl>
                                            </Grid>

                                            <Typography variant="body2" sx={{ mb: 3 }}>
                                                Enter the concession percentage for different components in the boxes given below. Leave the component blank for no concession on that component.
                                            </Typography>

                                            <Grid container spacing={2} sx={{ mt: 2, display: 'flex', alignItems: 'baseline' }}>
                                                {
                                                    Object.keys(uniqueBreakup)?.map(item => {
                                                        return <Grid xs={12} sm={6} md={2} sx={{ m: 2 }}>
                                                            <FormControl sx={{ mt: 3, mr: 1 }} fullWidth required>
                                                                <TextField
                                                                    type="number"
                                                                    id="outlined-basic"
                                                                    label={uniqueBreakup[item]}
                                                                    variant="outlined"
                                                                    value={consession?.[item] ?? ''}
                                                                    inputProps={{
                                                                        maxlength: 3
                                                                    }}
                                                                    onChange={e => setConsession({ ...consession, [item]: e.target.value })}
                                                                    onKeyPress={(e) => {
                                                                        if (!/^[0-9.]+$/.test(String.fromCharCode(e.charCode)) || e.target.value > 100 || e.target.value.toString().split('.')?.[1]?.length > 1) {
                                                                            e.preventDefault();
                                                                            return;
                                                                        }
                                                                        // if(!/^(?:100|\d{1,2})(?:\.\d{1,2})?$/.test(e.target.value.toString())){
                                                                        //     e.preventDefault();
                                                                        //     return;
                                                                        // }
                                                                    }}
                                                                    error={consession?.[item] ? !/^(?:100|\d{1,2})(?:\.\d{1,2})?$/.test(consession?.[item]) || consession?.[item] > 100 ? true : false : false}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    })
                                                }
                                            </Grid>
                                            <Grid container spacing={2} sx={{ mt: 2, pr: 3, pl: 3, display: 'flex', alignItems: 'baseline', justifyContent: 'flex-end' }}>

                                                <CustomButton
                                                    content={"Calculate Fee"}
                                                    permissionType={'C'}
                                                    permissionSet={userPermissions}
                                                    onClick={createBreakUpData}

                                                />
                                            </Grid>
                                        </Grid>
                                    </> : null}
                                </>}

                                {showBreakupGris && <>
                                    <div>
                                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                            Calculator Type : {selectedCalType}
                                        </Typography>
                                    </div>
                                    <div className='d-flex justfiy-content-start'>
                                        <Typography variant="subtitle2" sx={{ mb: 1, mr: 5 }}>
                                            Term : {values.term?.label ?? ''}
                                        </Typography>
                                        <Typography variant="subtitle2" sx={{ mb: 1, mr: 5 }}>
                                            Course : {values.course?.label ?? ''}
                                        </Typography>
                                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                            Business Area : {values.businessUnit?.label ?? ''}
                                        </Typography>
                                    </div>
                                    <div className='d-flex justify-content-start '>
                                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                            Consession Type : {consessionType}
                                        </Typography>
                                    </div>
                                    <Grid container sx={{ diplay: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                        <Grid container xs={12} sm={12} md={9} sx={{ mt: 1 }}>
                                            {
                                                Object.keys(uniqueBreakup)?.map(item => {
                                                    return consession?.[item] ? <Typography variant="subtitle2" sx={{ mb: 1, mr: 5 }}>
                                                        {uniqueBreakup[item]} : {`${consession[item]} %`}
                                                    </Typography> : null
                                                })
                                            }
                                        </Grid>
                                        <Grid xs={12} sm={12} md={3} sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end', height: 'fit-content' }}>
                                            <CustomButton
                                                content={"Changes Value"}
                                                permissionType={'C'}
                                                permissionSet={userPermissions}
                                                onClick={() => setShowBreakupGrid(false)}

                                            />
                                        </Grid>
                                    </Grid>
                                    <hr />
                                    <div className='d-flex justify-content-between mt-5'>
                                        <Typography variant="h6" component="h3" sx={{ mb: 3 }}>
                                            Fee Breakup
                                        </Typography>
                                        {/* <div>
                                            <span style={{ border: '1px solid #d0d0d0', padding: '10px', borderRadius: '4px', marginRight: '10px', cursor: 'pointer' }} role="button"><i class="fas fa-arrow-down"></i> PDF</span>
                                            <span style={{ border: '1px solid #d0d0d0', padding: '10px', borderRadius: '4px', cursor: 'pointer' }} role="button"> <i class="fas fa-arrow-down"></i> CSV</span>
                                        </div> */}
                                    </div>

                                    <BreakupGrid processedData={processedData}
                                        totals={totals} itemTypeLabel={uniqueBreakup} />
                                </>}
                            </CardContent></>
                }
            </Card>
        </Container>
    )
}

export default FeeCalculator
