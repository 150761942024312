import React, { useState, useEffect } from "react";
import SubSubTopicTable from "./subSubTopicTable";
import CustomLoader from "../../common/commonComponents/Loader/loader";
import { masterServiceBaseUrl, failureToast, getAPI } from "services/http";
import { fetchDetails } from "../../common/utils/methods/commonMethods/utilityMethod";
import { GetPagination } from "../../common/commonComponents/pagination/pagination";
import { endpoint } from "../../common/constant";
const _ = require("lodash");

const SubSubTopic = (props) => {
  const [classArray, setclassArray] = useState([]);
  const [apiloader, setapiloader] = useState(false);
  const [state, setstate] = useState([]);
  const [serverData, setServerData] = useState([]);
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [searchStr, setSearchStr] = useState("");

  const [pagePagination, setPagePagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });

  const [activeSubjectData, setActiveSubjectData] = useState([]);
  const [allSubjectData, setAllSubjectData] = useState([]);

  const pageSize = 10;

  const fetchClasses = (url) => {
    getAPI(
      url,
      (data) => {
        setstate(data.data);
        setServerData(data.data);
        setPagination(data.data);
      },
      (data) => {
        failureToast(data["message"]);
        setapiloader(false);
      }
    );
  };

  const setPagination = (paramsdata) => {
    let n = pagePagination;
    if(searchStr!==""){
      n.currentPage = 1;
    }
    if (paramsdata.length > pageSize) {
      n["nextPage"] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n["totalPage"] = Math.floor(paramsdata.length / pageSize);
    if (paramsdata.length % pageSize != 0) {
      n["totalPage"] += 1;
    }
    setclassArray(
      paramsdata.slice(
        pagePagination.currentPage * pageSize - pageSize,
        pagePagination.currentPage * pageSize
      ) || []
    );
    setPagePagination(n);
    setapiloader(false);
  };

  useEffect(() => {
    fetchDetails(
      endpoint.subject.getAllActive,
      setActiveSubjectData,
      "subject",
      "reactSelect"
    );
    fetchDetails(
      endpoint.subject.getAll,
      setAllSubjectData,
      "subject",
      "reactSelect"
    );
  }, []);

  const searchSubSubTopic = () => {
    try {
      if (!searchStr) {
        setapiloader(true);
        fetchClasses(endpoint.subSubTopicMaster.getAll);
      } 
      if(searchStr) {
        fetchClasses(`${endpoint.subSubTopicMaster.search}?search=${searchStr}`);
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  

  useEffect(() => {
    searchSubSubTopic()
  }, [searchStr]);

  return (
    <>
      {apiloader ? (
        <div
          className="mx-auto text-center py-5 my-5"
          style={{ height: "100vh" }}
        >
          <CustomLoader apiLoader={apiloader} />
        </div>
      ) : (
        <>
          <SubSubTopicTable
            setclassArray={setclassArray}
            classArray={classArray}
            isSaveVisible={isSaveVisible}
            setisSaveVisible={setisSaveVisible}
            activeSubjectData={activeSubjectData}
            allSubjectData={allSubjectData}
            pagination={pagePagination}
            fetchClasses={searchSubSubTopic}
            serverData={serverData}
            setSearchStr={setSearchStr}
            searchStr={searchStr}
            
          />
          {pagePagination.totalPage > 1 ? (
            <GetPagination
              isSaveVisible={isSaveVisible}
              setclassArray={setclassArray}
              pagination={pagePagination}
              setpagination={setPagePagination}
              pageSize={pageSize}
              state={state}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default SubSubTopic;
