import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Col, Button, Input } from "reactstrap";
import { masterServiceBaseUrl } from "services/http";
import { CustomContainer } from "views/pages/manage/common/commonComponents";
import { CustomCard } from "views/pages/manage/common/commonComponents";
import {
  fetchDetails,
  getRequiredSign,
} from "../../../common/utils/methods/commonMethods/utilityMethod";
import Building from "./../building/building";
import Select2 from "react-select2-wrapper";
import BuildingForm from "./../../msTeam/msGroupSequence/msGroupSequenceForm";
import ALoader from '../../../common/a-loader/index'


const Header = (props) => {
  const {
    headerValues,
    setHeaderValues = () => { },
    searchBuildingData = () => { },
    setBuildingData = () => { },
    isView = false,
    resetHeader = () => { },
    buildingForm,
    countryData,
    cityData,
    stateData,
    addBuildingFormHandler,
    getDisplayVal,
    createBuildingMasterHandler,
    regionData,
    businessData,
    createApiLoader,
    setVisibleCard = () => { },
  } = props;


  const pinCodeDropdownMapping = (pinCodes) => pinCodes.map(pinCode => ({ ...pinCode, value: pinCode?.id, label: pinCode?.text }))
  return (
    <CustomContainer>
      <CustomCard
        // title="Create Building Master"
        HeaderElement={
          <div className="w-100 d-flex justify-content-between align-items-center color-primary">
            <h3>Create Building Master</h3>
            <span className="cursor" onClick={() => setVisibleCard('search')}>Search building master</span>
          </div>
        }
      >
        {createApiLoader && <ALoader position={'fixed'} />}
        <div className="form-row p-4">
          <Col className="mb-3" md="3">
            <label className="form-control-label">
              Region{getRequiredSign()}
            </label>
            <Select
              value={buildingForm?.region}
              id="building_header_region"
              options={regionData}
              isDisabled={isView}
              placeholder="Select Region"
              onChange={(sData) => {
                addBuildingFormHandler(sData, "region");
                // setBuildingData(null);
              }}
            />
          </Col>
          <Col className="mb-3" md="3">
            <label className="form-control-label">
              Business Area{getRequiredSign()}
            </label>
            <Select
              value={buildingForm?.businessArea}
              id="building_header_business_area"
              options={businessData}
              isDisabled={isView || !buildingForm?.region}
              placeholder="Select Business Area"
              onChange={(sData) => {
                addBuildingFormHandler(sData, "businessArea");
                // setBuildingData(null);
              }}
              isLoading={
                headerValues?.businessGrp?.value &&
                headerValues?.companyCode?.value &&
                !businessData
              }
            />
          </Col>
          <Col className="mb-3" md="3">
            <label className="form-control-label">
              Building Name{getRequiredSign()}
            </label>
            <Input
              defaultValue={buildingForm?.buildingName}
              value={buildingForm?.buildingName}
              onChange={(e) =>
                addBuildingFormHandler(
                  e.target.value,
                  "buildingName"
                )
              }
              placeholder="Building Name"
              id="building_master_building_name"
              style={{ height: "38px" }}
              maxLength="50"
            />
          </Col>
          <Col className="mb-3" md="3">
            <label className="form-control-label">
              Address{getRequiredSign()}
            </label>
            <Input
              defaultValue={buildingForm?.address}
              value={buildingForm?.address}
              onChange={(e) =>
                addBuildingFormHandler(
                  e.target.value,
                  "address"
                )
              }
              placeholder="Address"
              id="building_master_building_name"
              style={{ height: "38px" }}
              maxLength="50"
            />
          </Col>
          <Col md="3">
            <label className="form-control-label">
              Pin code{getRequiredSign()}
            </label>
            <Select
              defaultValue={''}
              options={pinCodeDropdownMapping(props.pincodeData)}
              value={buildingForm?.pincode}
              onChange={(e) => {
                addBuildingFormHandler(e, "pincode");
              }}
              // options={{ placeholder: "Please Select Pincode" }}
              id="building_master_building_pincode"
              placeholder="Select Pincode"

            />
          </Col>
          <Col md="3">
            <label className="form-control-label">
              City{getRequiredSign()}
            </label>
            <Input
              value={
                getDisplayVal(buildingForm.city, cityData)?.text ??
                ""
              }
              disabled
              id="building_master_building_country"
              style={{ height: "38px" }}
            />
          </Col>
          <Col md="3">
            <label className="form-control-label">
              State{getRequiredSign()}
            </label>
            <Input
              value={
                getDisplayVal(buildingForm.state, stateData)?.text ??
                ""
              }
              disabled
              id="building_master_building_country"
              style={{ height: "38px" }}
            />
          </Col>
          <Col md="3">
            <label className="form-control-label">
              Country{getRequiredSign()}
            </label>
            <Input
              value={
                getDisplayVal(buildingForm.country, countryData)?.text ??
                ""
              }
              disabled
              id="building_master_building_country"
              style={{ height: "38px" }}
            />
          </Col>
          {/* <Col md="3">
            <Button
              color="info"
              size="sm"
              type="button"
              className="ml-3 mt-3 btn-left-0"
              onClick={createBuildingMasterHandler}
            >
              Add Building
            </Button>
          </Col> */}
        </div>
        <div className='p-4 d-flex justify-content-end'>
          <Button
            color="info"
            // size="sm"
            // type="button"
            // className="ml-3 mt-3 btn-left-0"
            onClick={createBuildingMasterHandler}
          >
            Add Building
          </Button>
        </div>
      </CustomCard>
    </CustomContainer>
  );
};

export default Header;
