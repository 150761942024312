import React, { useState } from 'react';
import { ReactComponent as ArrowUpRightIcon } from 'assets/img/svg/fi-arrow-up-right.svg';
import styles from '../homepage.module.scss';
import CustomTooltip from '../../common/customTooltip/CustomTooltip';
import { useHistory } from 'react-router';
import { getSearchParams } from '../constant';
import BUSelectorDialog from './BUSelectorDialog';


const CountCard = ({ count = 0, description = '', countData, redirectPathname = () => { }, requireBuSelection }) => {
  const history = useHistory();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClick = (clickedBu) => window.open(history.createHref({ pathname: `${redirectPathname}${getSearchParams(clickedBu)}` }), '_blank');

  return (
    count > 0 && (
      <>
        <div
          className={`${styles['homepage__count-card']} ${
            redirectPathname ? 'cursor' : ''
          }`}
          onClick={() =>
            requireBuSelection
              ? countData?.length > 1
                ? setIsDialogOpen(true)
                : handleClick(countData?.[0])
              : handleClick()
          }
        >
          <div className='d-flex align-items-center justify-content-between gap-xs'>
            <CustomTooltip title={count}>
              <div
                className='color-aqua-40 ellipsis-text-container'
                style={{
                  fontSize: 40,
                  fontWeight: '800',
                  lineHeight: '40px',
                }}
              >
                {count}
              </div>
            </CustomTooltip>
            <ArrowUpRightIcon className='flex-shrink-0' />
          </div>
          <div className='regular-small color-grey-54'>{description}</div>
        </div>
        <BUSelectorDialog
          open={isDialogOpen}
          setOpen={setIsDialogOpen}
          data={countData}
          onAgree={handleClick}
        />
      </>
    )
  );
};
export default CountCard;
