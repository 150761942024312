import React, { useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import { endpoint } from '../../common/constant'
import AAutoComplete from '../../common/form-fields-mui/AAutoComplete'
import AButton from '../../common/form-fields-mui/AButton'
import { fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod'

const AttachTestForm = ({ 
  closeForm, 
  testData, 
  planData, 
  updateAttachTestHandler = () => { } 
}) => {

  const [attachTestForm, setAttachTestForm] = useState((testData?.attachTestId === null || testData?.attachTestId === undefined) ? null : { label: testData?.attachTestShortCode, value: testData?.attachTestId });
  const [testIdDropdown, setTestIdDropdown] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const getTestDropdown = async () => {
    setIsFetching(true);
    const res = await fetchAllPromisedData(`${endpoint?.academicPlanTests?.getTestIdsFromCMS}?packageId=${planData?.packageId}&testCollectionId=${planData?.testCollectionId}&testType=${testData?.testType}`, true);
    if(res?.code===200){
      setTestIdDropdown(res?.data?.map(item => ({ label: item?.testShortCode, value: item?.id })))
    }
    setIsFetching(false);
  }

  useEffect(() => {
    getTestDropdown();
  }, [])

  return (
    <Card className='mb-0' style={{ width: '370px' }}>
      <CardBody>
        <h4 style={{ fontSize: '20px', fontWeight: 600, color: '#0F0F0F' }}>Test Paper</h4>
        <div>
          <AAutoComplete
            label='Test ID'
            placeholder='Select'
            currentValue={attachTestForm}
            items={testIdDropdown}
            handleChange={(value) => setAttachTestForm(value)}
            isMulti={false}
            isLoading={isFetching}
          />
          <div className='d-flex justify-content-end mt-3'>
            <AButton 
              variant='outline' 
              updatedStyle={{height: '28px', fontSize: '12px', fontWeight: 400, borderWidth: '1px'}}
              onClick={closeForm}
            >
              Cancel
            </AButton>
            <AButton 
              variant='primary_filled' 
              updatedStyle={{height: '28px', fontSize: '12px', fontWeight: 400}}
              disabled={!attachTestForm?.label}
              onClick={() => { updateAttachTestHandler(attachTestForm, testData); closeForm(); }}
            >
              Attach
            </AButton>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default AttachTestForm