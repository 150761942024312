import React, { useEffect, useState } from 'react';
import { ImCross } from 'react-icons/im';
import { ReactComponent as Search } from "../../../../../assets/img/svg/SearchIcon.svg";
import styled, { css } from 'styled-components';
import { failureToast } from '../../common/utils/methods/toasterFunctions/function';
import SelectSearchInputParam from './studentFiltersandwidget/SelectSearchInputParam';
import { emptySearchFields } from './studentListWrapper';

let searchParamTitleMap = {
   psid: {
    dispValue :'PSID',
    validLength : 11 ,
    placeholder : 'Please Enter 11 digit PSID'
  },

  applicationId : {
    dispValue : 'Application Id',
    validLength : 8 ,
    placeholder : 'Please Enter 8 digit Application ID'
  },

  // opportunityId : {
  //   dispValue : 'Opportunity Id',
  //   validLength : 8,
  //   placeholder : 'Please Enter 8 digit Opportunity ID'
  // },

  studentName : {
    dispValue : 'Student Name',
    placeholder : 'Please Enter Student Name'
  }


}


const crossIconStyle = {
  fontSize: "11px",
  position: "absolute",
  top: "0.6rem",
  right: "30px",
  cursor: "pointer",
}

const SeachWrapper = styled.span`
  svg {
    position: absolute;
    top: 5.5px;
    right: 5px;
    cursor: pointer;
    z-index: 3;
  } 
`

const ErrorMsg = styled.div`
  color: #DC2804 ;
  margin-top: 5px;
  margin-left: 12px;
  font-size: 12px;
`

const StyledInput = styled.input`
  padding-left: 5px !important;
  
 
  
  ${props => props.errorMsg && props.searchInputValue?.trim()?.length < props.selectedSearchFieldProperties?.validLength && props.searchInputValue?.trim()?.length > 0  && css`
   border : 1px solid #DC2804 ;
   color : #DC2804  ;
    :hover:not([disabled]),
    :focus:not([disabled]) {
      border-color: #DC2804 !important;
      color : #DC2804 !important ; 
    }
    &:not(:placeholder-shown) {
      color: #DC2804 !important;
    }
  `}
`;

const SearchInput = ({ 
  searchInputValueHandler, 
  onCancel, 
  placeholder,
  errorMsg=false,
  val,
  globalFilter,
  setGlobalFilter,
  // emptySearchFields
}) => {
  const [inputValue, setInputValue] = useState('')

  const selectedSearchFieldProperties = searchParamTitleMap[globalFilter?.searchField];
  const searchInputValue = globalFilter?.[globalFilter?.searchField];
  
  const handler = (e) => {
    // if(errorMsg){
    // if (e.key === 'Enter' || e.keyCode === 13) {
       
      
    //   if (inputValue.trim().length === 11||inputValue.trim().length === 0) {
    //   searchInputValueHandler(inputValue)}
    //   else {
    //     failureToast('Please enter the 11 digit PSID number')
    //   }
    // }}
    
    // else {
    if (e.key === 'Enter' || e.keyCode === 13) {
      searchHandler();
    }
  
  }
  


  const clickHandler = () => {
    if (inputValue !== ''){
      searchInputValueHandler(inputValue)
    }
  }

  const searchHandler = () =>{
    if(globalFilter?.searchField === 'studentName' || searchInputValue?.length === selectedSearchFieldProperties?.validLength ){
      searchInputValueHandler();
    }
  }

  const onChangeHandler = (value) => {
    // setInputValue(value)
    setGlobalFilter(prev=>({...prev, [prev?.searchField] : value}))
  }

  const handlePaste  = (e) => {
    let pastedText = e.clipboardData?.getData('text/plain');
    // setInputValue(pastedText);
    if(['psid', 'applicationId', 'opportunityId'].includes(globalFilter?.searchField)){
      pastedText = pastedText?.replace(/\D/g,'');
    }
    setGlobalFilter(prev=>({...prev, [prev?.searchField] : pastedText}));
    e.preventDefault();
  }

  // useEffect(()=>{
  //   if(val){
  //     setInputValue(val)
  //   }
  // },[val])
  return (
    <div className='d-flex flex-column'>
    <div style={{ position: "relative" , minHeight : "35px" }}>
      <StyledInput
        className='search-input'
        id="inputValue"
        value={searchInputValue}
        placeholder={selectedSearchFieldProperties?.placeholder}
        onChange={(e) => {
          onChangeHandler(e.target.value);
        }}
        onKeyDown={handler}
        inputValue={inputValue}
        maxLength={selectedSearchFieldProperties?.validLength}
        onPaste={handlePaste}
        onKeyPress={(e) => {
          if (
            ['psid', 'applicationId', 'opportunityId'].includes(globalFilter?.searchField) && 
            !/^[0-9]*$/.test(String.fromCharCode(e.charCode))
          ) {
            e.preventDefault();
            return;
          }
        }}
        errorMsg={errorMsg} 
        searchInputValue={searchInputValue}
        selectedSearchFieldProperties={selectedSearchFieldProperties}
      />
       
      {errorMsg && searchInputValue?.length > 0 &&
        <ImCross onClick={() => {
          // setInputValue('')
          onCancel();
        }} style={crossIconStyle} />
      }
      {!errorMsg &&  <ImCross onClick={() => {
          setInputValue('')
          onCancel();
        }} style={crossIconStyle} />}
      {errorMsg && searchInputValue?.trim()?.length>0 &&
      <SeachWrapper> <Search onClick={searchHandler} /></SeachWrapper>}
      {!errorMsg &&  <SeachWrapper> <Search onClick={clickHandler} /></SeachWrapper> }
      {errorMsg && searchInputValue?.trim()?.length < selectedSearchFieldProperties?.validLength && searchInputValue?.trim()?.length > 0 && <ErrorMsg>Please enter the {selectedSearchFieldProperties?.validLength} digit {selectedSearchFieldProperties.dispValue}</ErrorMsg>}
    </div>

     <SelectSearchInputParam
     searchInputParam={globalFilter?.searchField ?? 'psid'}
     setSearchInputParam={(value) => setGlobalFilter({ ...globalFilter,'searchField': value,...emptySearchFields})}
     searchParamTitleMap={searchParamTitleMap}
     styles={{width : '320px'}}
   />
   </div>
  )
}

export default SearchInput;

