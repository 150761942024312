import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { IoClose } from "react-icons/io5";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import Style from "./model.module.scss";
const Dialog = (props) => {
  const {
    isModalVisible = false,
    title = "Document Upload",
    toggle = () => { },
    children,
    headerBorder,
    size = "lg",
    backdrop = false,
    isCloseButtonRequired = false,
    headerWithBg
  } = props;
  return (
    <Modal
      isOpen={isModalVisible}
      modalTransition={{ timeout: 700 }}
      size={size}
      centered
      // backdropTransition={{ ti }}
      toggle={toggle}
      backdrop={!backdrop ? "static" : backdrop}
      // className={className}
      style={{ zIndex: 9999 }}
    >
      {title && (
        <ModalHeader
          // toggle={toggle}
          className={headerBorder ? Style.header_border : headerWithBg ? Style.header_bg : ""}
        >
          <div className="d-flex justify-content-between">
            <span>
              {title}
            </span>
            <Tooltip title="Close" placement='top-start'>
              <IconButton onClick={toggle}>
                <IoClose size={24} style={{ cursor: "pointer" }} />
              </IconButton>
            </Tooltip>
          </div>
        </ModalHeader>
      )}
      <ModalBody>{children}</ModalBody>
      {
        isCloseButtonRequired &&
        <div className="d-flex justify-content-end mb-4 mr-4">
          <Button onClick={toggle}>
            Close
          </Button>
        </div>
      }
    </Modal>
  );
};

export default Dialog;
