import React from 'react';
import { Card, CardHeader, Container, Table, Row } from 'reactstrap';
import { useState } from 'react';
import { COURSETDROW } from '../../../common/commonComponents/masterRow/masterRow';
import { masterServiceBaseUrl, putpost2 } from 'services/http';
import { withRouter } from 'react-router-dom';
import CustomButton from 'components/CustomButton';
import { openInNewTab } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { failureToast, successToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import { MasterHeaderAuto } from '../../../common/commonComponents/masterHeader/masterHeader';
import CustomLoader from '../../../common/commonComponents/Loader/loader';

var _ = require('lodash');

const CourseAttributeTable = withRouter((props) => {
	const { apiloader, permissionSet, isSaveVisible, setisSaveVisible = () => { }, getAllrecords = () => { }, setSearchStr = () => { }, searchStr } = props;
	// const [isSaveVisible, setisSaveVisible] = useState(false);
	const [tableDataKey, settableDataKey] = useState('tableDataKey');

	const headerList = [
		{ name: "Business Group" },
		{ name: "Company Code" },
		{
			name: "Course Attribute",
			filterable: true,
			filterFun: setSearchStr,
			filterVal: searchStr,
		},
		{ name: "Actions" },
	];

	const deleteRow = (index) => {
		if (
			window.confirm('Are you sure you want to delete this Course Attribute?')
		) {
			putpost2(
				masterServiceBaseUrl +
				'/courseAttributeMapping/deleteCourseAttributeMapping/' +
				props.classArray[index].id,
				(data) => {
					successToast(data['message']);
					if (props.classArray.length == 1) {
						props.previousPage();
						props.fetchClasses(
							masterServiceBaseUrl +
							'/courseAttributeMapping/getAllCourseAttributeMapping'
						);
					} else {
						var _tempArray = props.classArray;
						if (_tempArray[index]['editMode']) {
							setisSaveVisible(false);
						}
						_tempArray.splice(index, 1);
						props.setclassArray(_tempArray);
					}
					settableDataKey(new Date().getMilliseconds());
				},
				(data) => {
					failureToast(data['message']);
				},
				'Delete'
			);
		}
	};

	return (
		<>
			<Container className="mt-3" fluid>
				<Row>
					<div className="col">
						<Card>
							<CardHeader className="border-0">
								<h3 className="mb-0 floatLeft">Course Attribute Mapping</h3>
								<CustomButton
									disabled={isSaveVisible}
									className={
										'floatRight mx-1' +
										(isSaveVisible ? ' btn-dark cursor-gray' : null)
									}
									content={'New Course Attribute Mapping'}
									permissionType={'C'}
									icon={true}
									permissionSet={permissionSet}
									onClick={() => {
										props.history.push('/admin/courseAttributes/new');
									}}
								/>
							</CardHeader>
							<div id="questionTable" className="table-responsive">
								<Table className="align-items-center table-flush tableLayout">
									{/* <thead className="thead-light">
										<tr>
											<th className="text-center white-breakSpace">
												Business Group
											</th>
											<th className="text-center white-breakSpace">
												Company Code
											</th>
											<th className="text-center white-breakSpace">
												Course Attribute
											</th>
											{isSaveVisible ||
												(permissionSet &&
													(permissionSet.includes('R') ||
														permissionSet.includes('U') ||
														permissionSet.includes('D'))) ? (
												<th className="text-center white-breakSpace">Action</th>
											) : null}
										</tr>
									</thead> */}
									<MasterHeaderAuto headerList={headerList} isSaveVisible={isSaveVisible} permissionSet={permissionSet} />
									<tbody className="list" key={tableDataKey}>
										{!apiloader && props.classArray && props.classArray.length > 0
											? props.classArray.map((el, index) =>
												!el['editMode'] ? (
													<COURSETDROW
														type={'courseAttribute'}
														index={index}
														el={el}
														deleteRow={deleteRow}
														editRow={() =>
															props.history.push(
																`/admin/courseAttributes/edit/${el.id}`
															)
														}
														viewRow={() =>
															props.history.push(
																`/admin/courseAttributes/view/${el.id}`
															)
														}
														businessGroupData={props.fetchAllBusinessGroup}
														companyCodeData={props.fetchAllCompanyCode}
														courseAttrData={props.fetchAllCourseAttr}
														isSaveVisible={isSaveVisible}
														permissionSet={permissionSet}
													/>
												) : null
											)
											: null}
									</tbody>
								</Table>
								{apiloader && (
									<CustomLoader
										apiLoader={apiloader}
										loaderHeight={'200px'}
										loaderWidth={'100% '}
									/>)}

							</div>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
});
export default CourseAttributeTable;
