import React from 'react';
import {
	Card,
	CardHeader,
	Input,
	Container,
	Button,
	Table,
	Row,
} from 'reactstrap';
import { useState } from 'react';
import { failureToast , successToast } from '../../common/utils/methods/toasterFunctions/function';
import {
	TDROW,
	MasterHeader,
} from '../../common/commonComponents/masterRow/masterRow';
import {
	masterServiceBaseUrl,
	putpost,
	putpost2,
} from 'services/http';
import moment from 'moment';
import IsCoreMaster from '../../common/commonComponents/coreMaster';
import CustomButton from 'components/CustomButton';
import CustomDatePicker from '../../common/commonComponents/CustomDatePicker';

var _ = require('lodash');

const AcademicYearTable = (props) => {
	const { userPermissions, isSaveVisible, setisSaveVisible=()=>{} } = props;
	const [tableDataKey, settableDataKey] = useState('tableDataKey');
	const [validationDate, setValidationDate] = useState(null);
	const [saving,setSaving] = useState(false)
	const addNewClass = () => {
		props.setclassArray([
			{
				editMode: true,
				__status: '__new',
				academicYearKey: null,
				status: 'ACTIVE',
				academicYearDesc: null,
				academicYearDisplayValue: null,
				effectiveDate: null,
				academicYearStartdate: null,
				academicYearEndDate: null,
			},
			...props.classArray,
		]);
	};

	const saveAll = (cb) => {
		setSaving(true)
		var _tempArray = props.classArray;
		var isValid = true;
		_tempArray.forEach((el, index) => {
			if (el['editMode']) {
				if (
					el['academicYearDescription'] === null ||
					!el['academicYearDescription']?.trim()
				) {
					isValid = false;
					failureToast('Please enter the value of Description');
					return;
				}
				if (
					!/^[-._/+\w\s]*$/.test(_tempArray[index]['academicYearDescription'])
				) {
					const isVerified = /^[-._/+\w\s]*$/.test(
						_tempArray[index]['academicYearDescription']
					);
					isValid = false && isVerified;
					failureToast('Please enter valid Academic Year description');
					return;
				} else if (
					el['academicYearDisplayValue'] === null ||
					!el['academicYearDisplayValue'].trim()
				) {
					isValid = false;
					failureToast('Please enter the value of Display Value');
					return;
				}
				if (
					!/^[0-9\.\-\/]+$/.test(_tempArray[index]['academicYearDisplayValue'])
				) {
					isValid = false;
					failureToast('Only numeric  digits are allowed in display value');
					return;
				} else if (
					el['academicYearStartdate'] === null
				) {
					isValid = false;
					failureToast('Please enter the value of Start Date');
					return;
				} else if (
					el['academicYearEndDate'] === null
				) {
					isValid = false;
					failureToast('Please enter the value of End Date');
					return;
				}
				if (!isValid) {
					setSaving(false)
					return;
				}
				if (_tempArray[index]['__status'] == '__new') {
					putpost(
						masterServiceBaseUrl + '/academicYear',
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							setSaving(false)
							props.fetchClasses(masterServiceBaseUrl + '/academicYear/all');
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'post'
					);
				} else {
					putpost(
						masterServiceBaseUrl + '/academicYear/' + _tempArray[index].id,
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							setSaving(false)
							delete _tempArray[index]['editMode'];
							props.setclassArray(_tempArray);
							settableDataKey(new Date().getMilliseconds());
							// props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'put'
					);
				}
			} else {
			}
		});
	};
	const newRow = () => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				addNewClass();
				setisSaveVisible(true);
				// settableDataKey(new Date().getMilliseconds())
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			props.setclassArray(_tempArray);
			setisSaveVisible(false);
			cb();
		}
	};
	const editRow = (index) => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				var _tempArray = props.classArray;
				_tempArray[index]['editMode'] = true;
				props.setclassArray(_tempArray);
				setisSaveVisible(true);
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			cb();
		}
	};
	const deleteRow = (index) => {
		if (props.classArray[index]['__status'] == '__new') {
			let n = props.classArray;
			n.splice(index, 1);
			props.setclassArray(n);
			setisSaveVisible(false);
			settableDataKey(new Date().getMilliseconds());
		} else {
			if (
				window.confirm('Are you sure you want to delete this Academic Year?')
			) {
				putpost2(
					masterServiceBaseUrl + '/academicYear/' + props.classArray[index].id,
					(data) => {
						successToast(data['message']);
						if (props.classArray.length == 1) {
							props.previousPage();
							props.fetchClasses(masterServiceBaseUrl + '/academicYear/all');
						} else {
							var _tempArray = props.classArray;
							if (_tempArray[index]['editMode']) {
								setisSaveVisible(false);
							}
							_tempArray.splice(index, 1);
							props.setclassArray(_tempArray);
						}

						settableDataKey(new Date().getMilliseconds());
					},
					(data) => {
						failureToast(data['message']);
					},
					'Delete'
				);
			}
		}
	};
	const updateKey = (index, value, key) => {
		var _tempArray = props.classArray;
		_tempArray[index][key] = value;
		if (key === 'academicYearStartdate') {
			setValidationDate(value);
			_tempArray[index]['academicYearEndDate'] = null;
			settableDataKey(new Date().getMilliseconds())
		}
		if (
			key === 'academicYearStartdate' &&
			value > _tempArray[index]['academicYearEndDate']
		) {
			_tempArray[index]['academicYearEndDate'] = null;
		}
		props.setclassArray(_tempArray);
		// settableDataKey(new Date().getMilliseconds())
	};

	return (
		<>
			<Container className="mt-3" fluid>
				<Row>
					<div className="col">
						<Card>
							<CardHeader className="border-0">
								<h3 className="mb-0 floatLeft">Master - Academic Year</h3>
								<IsCoreMaster
									master={'academicYear'}
									masterDisplayValue={'Academic Year'}
								/>
								<CustomButton
									disabled={isSaveVisible}
									className={
										'floatRight mx-1' +
										(isSaveVisible ? ' btn-dark cursor-gray' : null)
									}
									content={'New Academic Year'}
									permissionType={'C'}
									icon={true}
									permissionSet={userPermissions}
									onClick={() => {
										newRow();
									}}
								/>
							</CardHeader>
							<div id="questionTable" className="table-responsive">
								<Table className="align-items-center table-flush tableLayout">
									<MasterHeader
										type={'Academic Year'}
										isSaveVisible={isSaveVisible}
										permissionSet={userPermissions}
									/>
									<tbody className="list" key={tableDataKey}>
										{props.classArray
											? props.classArray.map((el, index) =>
													!el['editMode'] ? (
														<TDROW
															type={'academicYear'}
															index={index}
															el={el}
															editRow={editRow}
															deleteRow={deleteRow}
															isSaveVisible={isSaveVisible}
															permissionSet={userPermissions}
														/>
													) : (
														<tr key={index + '-class'}>
															<td className="text-center p-2 mx-1">
																<Input
																	disabled={true}
																	maxLength="50"
																	defaultValue={
																		el['__status'] == '__new' ? null : el['id']
																	}
																	onChange={(e) => {
																		updateKey(
																			index,
																			e.target.value,
																			'academicYearKey'
																		);
																	}}
																	placeholder="Example:1"
																	className="tdInput"
																	data-testid="academicYearKey"
																/>
															</td>
															<td className="text-center p-2 mx-1">
																<Input
																	disabled={true}
																	defaultValue={
																		el['__status'] == '__new'
																			? null
																			: moment(el['eff_date']).format(
																					'DD-MM-YYYY'
																			  )
																	}
																	placeholder="Example:01-01-2020"
																	className="tdInput"
																	data-testid="effectiveDate"
																/>
															</td>
															<td className="text-center p-2 mx-1">
																<Input
																	maxLength="50"
																	defaultValue={el['academicYearDescription']}
																	onChange={(e) => {
																		updateKey(
																			index,
																			e.target.value,
																			'academicYearDescription'
																		);
																	}}
																	placeholder="Description"
																	className="tdInput"
																	data-testid="academicYearDesc"
																/>
															</td>
															<td className="text-center p-2 mx-1">
																<Input
																	maxLength="4"
																	defaultValue={el['academicYearDisplayValue']}
																	onChange={(e) => {
																		updateKey(
																			index,
																			e.target.value,
																			'academicYearDisplayValue'
																		);
																	}}
																	placeholder="Display Value"
																	className="tdInput"
																	data-testid="academicYearDisplayValue"
																	disabled = {!!el['id']}
																/>
															</td>
															<td className="text-center white-breakSpace p-2 mx-1">
															{/* <Input
																	type="date"
																	aria-label="startDate"
																	defaultValue={moment(
																		el['academicYearStartdate']
																	).format('YYYY-MM-DD')}
																	onChange={(e) => {
																		updateKey(
																			index,
																			e.target.value,
																			'academicYearStartdate'
																		);
																	}}
																	data-testid="academicYearStartdate"
																	className="tdInput"
																/> */}
															<CustomDatePicker
																aria-label="startDate"
																value={el['academicYearStartdate']}
																placeholder="Example:01-01-2020"
																minDate={new Date()}
																handler={updateKey}
																index={index}
																rangeFrom={1950}
																rangeTo={2099}
																keyName='academicYearStartdate'
																forTable={true}
																data-testid="academicYearStartdate"
															/>
														</td>
														<td className="text-center white-breakSpace p-2 mx-1">
															{/* <Input
																	type="date"
																	aria-label="endDate"
																	min={moment(validationDate).format(
																		'YYYY-MM-DD'
																	)}
																	defaultValue={moment(
																		el['academicYearEndDate']
																	).format('YYYY-MM-DD')}
																	data-testid="academicYearEndDate"
																	onChange={(e) => {
																		updateKey(
																			index,
																			e.target.value,
																			'academicYearEndDate'
																		);
																	}}
																	className="tdInput"
																/> */}
															<CustomDatePicker
																aria-label="endDate"
																value={el['academicYearEndDate']}
																placeholder="Example:01-01-2020"
																handler={updateKey}
																index={index}
																rangeFrom={1950}
																rangeTo={2099}
																keyName='academicYearEndDate'
																forTable={true}
																data-testid="academicYearEndDate"
																minDate={el['academicYearStartdate']}
																disabled={!el['academicYearStartdate']}
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<label className="custom-toggle mx-auto ml-2">
																<input
																	checked={
																		el.status == 'ACTIVE' ? true : false
																	}
																	type="checkbox"
																	id={el.id}
																		onChange={(e) => {
																			settableDataKey(
																				new Date().getMilliseconds()
																			);
																			let n = 'ACTIVE';
																			if (!e.target.checked) {
																				n = 'INACTIVE';
																			}
																			updateKey(index, n, 'status');
																		}}
																		data-testid="academicYearStatus"
																	/>
																	<span
																		style={{ width: '72px' }}
																		className="custom-toggle-slider rounded-circle activeToggle"
																		data-label-off="Inactive"
																		data-label-on="Active"
																	/>
																</label>
															</td>
															<td className="text-center">
																{isSaveVisible ? (
																	<>
																		<CustomButton
																			content={saving ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : 'Save'}
																			permissionType={'C,U'}
																			forTable={true}
																			permissionSet={userPermissions}
																			onClick={() => {
																				saveAll();
																			}}
																			disabled={saving}
																		/>
																		<CustomButton
																			permissionType={'cancel'}
																			icon={true}
																			forTable={true}
																			permissionSet={userPermissions}
																			onClick={() => {
																				let n = props.classArray;
																				if (n[index]['__status'] == '__new') {
																					n.splice(index, 1);
																				} else {
																					props.fetchClasses(
																						masterServiceBaseUrl +
																							'/academicYear/all'
																					);
																				}
																				props.setclassArray(n);
																				setisSaveVisible(false);
																			}}
																		/>
																	</>
																) : (
																	<CustomButton
																		permissionType={'D'}
																		icon={true}
																		forTable={true}
																		onClick={() => deleteRow(index)}
																		permissionSet={userPermissions}
																	/>
																)}
															</td>
														</tr>
													)
											  )
											: null}
									</tbody>
								</Table>
							</div>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};
export default AcademicYearTable;
