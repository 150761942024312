export const regionAndBusinessModal = {
    region:[],
    businessArea:[]
}

export const filterFormModal = {
     courseId:[],
    //  psid:'',
     classes:[],
     programAction:[],
     studentName:"",
    //  applicationId :"",
     allocationStatus:''
}

export const searchInputFormModal = {
    studentName: '',
    psid: '',
    applicationId: ''
}




