import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap';
import CustomButton from '../../../../../components/CustomButton';
import { Dialog } from '../../common/commonComponents';
import CommonInput from '../../common/formFeilds/input/commonInput';
import Status from '../../common/formFeilds/status';
import { failureToast } from '../../common/utils/methods/toasterFunctions/function';

const TempRole = ({ tempRoleModal, userPermissions, rowData, roleData, action, handleValueChange = () => { }, setTempRoleModalOpen = () => { }, resetTempRole,
setRestTempRole =()=>{} }) => {

    const [values, setValues] = useState({
        roleId: '',
        startDate: '',
        endDate: '',
        status: 'ACTIVE'
    })

    const [isSavedRecord, setSavedRecord] = useState(false);


    const handleSubmit = () => {
        if (!values.roleId?.value) {
            failureToast("Please select role id")
            return
        }
        if (!values.startDate) {
            failureToast("Please enter start date")
            return
        }
        if (!values.endDate) {
            failureToast("Please enter end date")
            return
        }

        handleValueChange('tempRole', {
            ...values
        })

        setTempRoleModalOpen(false)

    }

    const resetData = () => {
        if (rowData?.tempRole) {
            setValues({
                roleId: rowData?.tempRole?.roleId,
                startDate: rowData?.tempRole?.startDate,
                endDate: rowData?.tempRole?.endDate,
                status: rowData?.tempRole?.status
            })
        } else if (rowData?.tempRoleDto?.tempRoleId) {
            setValues({
                roleId: roleData.filter(item => item.value === rowData?.tempRoleDto?.tempRoleId)?.[0] ?? '',
                startDate: rowData?.tempRoleDto?.tempRoleStartDate,
                endDate: rowData?.tempRoleDto?.tempRoleEndDate,
                status: rowData?.tempRoleDto?.status
            })
        } else {
            setValues({
                roleId: '',
                startDate: '',
                endDate: '',
                status: 'ACTIVE'
            })
        }
        setTempRoleModalOpen(false)
    }

    useEffect(() => {
        if (rowData?.tempRoleDto?.tempRoleId && roleData && !rowData?.tempRole) {
            setValues({
                roleId: roleData.filter(item => item.value === rowData?.tempRoleDto?.tempRoleId)?.[0] ?? '',
                startDate: rowData?.tempRoleDto?.tempRoleStartDate,
                endDate: rowData?.tempRoleDto?.tempRoleEndDate,
                status: rowData?.tempRoleDto?.status
            })
            setSavedRecord(true);
        }else if(rowData?.tempRole){
            setValues({
                roleId: rowData?.tempRole?.roleId,
                startDate: rowData?.tempRole?.startDate,
                endDate: rowData?.tempRole?.endDate,
                status: rowData?.tempRole?.status
            })
        }else{
            setValues({
                roleId: '',
                startDate: '',
                endDate: '',
                status: 'ACTIVE'
            })
        }
    }, [rowData, roleData])


    useEffect(()=>{
        if(resetTempRole){
            setValues({
                roleId: '',
                startDate: '',
                endDate: '',
                status: 'ACTIVE'
            })
            setRestTempRole(false)
        }
    },[resetTempRole])


   const getRemainingRole = () => {
       const arr = rowData.role?.map(o => { return o.value })
       const roles = roleData.filter(item => arr.indexOf(item.value) === -1)
       return roles
   }

    return (
        <Dialog
            isModalVisible={tempRoleModal}
            // isCloseButtonRequired={true}
            title={'Temporary Role Details'}
            toggle={() => { setTempRoleModalOpen(false); }}
            headerWithBg={true}
            size={"lg"}
        >
            <Row className="pb-2 pt-2 ">
                <Col md={4}>
                    <CommonInput
                        label={"Temporary Role Name"}
                        type={'select'}
                        // forTable={true}
                        id="user-profile-role"
                        placeHolder={'Select Temporary Role'}
                        defaultValue={values.roleId}
                        data={getRemainingRole()}
                        onChange={(value) => {
                            setValues({ ...values, roleId: value , startDate : '', endDate: ''})
                        }}
                        isDisabled={isSavedRecord || action === 'view'}
                    />
                </Col>

                <Col md={3}>
                    <CommonInput
                        label={"Start Date"}
                        type={'datePicker'}
                        // forTable={true}
                        id="user-profile-start-date"
                        placeHolder={'Select Start Date'}
                        defaultValue={values.startDate}
                        onChange={(value) => {
                            setValues({ ...values, startDate: value,  endDate: '' })
                        }}
                        isDisabled={isSavedRecord || action === 'view'}
                        minDate={moment(new Date()).add(1,'day')}
                    />
                </Col>
                <Col md={3}>
                    <CommonInput
                        label={"End Date"}
                        type={'datePicker'}
                        // forTable={true}
                        id="user-profile-end-date"
                        placeHolder={'Select End Date'}
                        defaultValue={values.endDate}
                        onChange={(value) => {
                            setValues({ ...values, endDate: value })
                        }}
                        isDisabled={action === 'view' || !values.startDate}
                        minDate={moment(values.startDate).add(1,'day')}
                        maxDate={moment(values.startDate).add(values.roleId?.delegationDays - 1,'days')}
                    />
                </Col>
                <Col md={2}>
                    {/* <Status value={rowData.status} onChange={(n) => { handleValueChange('status', n) }} /> */}
                    <CommonInput
                        label={"Status"}
                        type={'toggle'}
                        // forTable={true}
                        id="user-profile-toogle"
                        defaultValue={values.status}
                        dataLabelOff='INACTIVE'
                        dataLabelOn='ACTIVE'
                        onChange={(value) => {
                            setValues({ ...values, status: value })
                        }}
                        isDisabled={action === 'view'}
                    />
                </Col>
            </Row>
            <Row className="justify-content-center mt-4 pl-4 pr-4 pb-2">
                {action !== 'view' && <><CustomButton
                    content={'Save'}
                    permissionType={'C,U'}
                    permissionSet={userPermissions}
                    onClick={() => { handleSubmit() }}
                />
                    <CustomButton
                        content={'Cancel'}
                        type={'close'}
                        permissionType={'R'}
                        permissionSet={userPermissions}
                        onClick={resetData}
                    /></>}
                {action === 'view' && <CustomButton
                    content={'Close'}
                    type={'close'}
                    permissionType={'R'}
                    permissionSet={userPermissions}
                    onClick={() => setTempRoleModalOpen(false)}
                />}
            </Row>
        </Dialog>
    )
}

export default TempRole