import React, { useState } from "react";
import ActionsCellComponent, {
  ActionsCellComponentMarkEligible,
} from "./ActionsCellComponent";

import {
  PsidDisplay,
  ValueWithTooltip,
  IssuedColumn,
  CheckBoxColumn,
  ViewEditBarCode,
  ValueWithOutTooltip,
  CourseDetails,
  MaterialAlreadyIssued,
} from "./TableColumn";
import styles from "../styles.module.scss";
import NoRowOverlay from "../../../../masters/holidayMaster/NoRowOverlay";
import { ReactComponent as EyeIcon } from "assets/img/svg/eye_open.svg";
import MarkEligibleCourseDetailsPopup from "../../dlpStockIssuance/markEligible/MarkEligibleCourseDetailsPopup";
import CustomDatePicker from "views/pages/manage/common/commonComponents/CustomDatePicker";
import moment from "moment";
import { MdOutlineCalendarMonth as Calendar } from "react-icons/md";
const getIssuedStockToPsid  =(psid , list = [])=>{
   for(let i = 0 ; i<list.length ; i++){
     if(list[i]?.psid === psid){ 
      return list[i]?.stock ;
     }
   }

}
const TableHeader = ({
  checkBoxHandler,
  selectedStudentIds,
  columns = [
    "PSID",
    "Application ID",
    "Student Name",
    "Program Status",
    "Payment Date",
    "Material Already Issued",
  ],
}) => {
 
  return (
    <div
      className={`${styles["custom-table-parent-mark-eligible"]} ${styles["header-column"]}`}
    >
      <div>
        <CheckBoxColumn
          {...{
            issueStockCheckBoxHandler: checkBoxHandler,
            isAll: true,
            isChecked: selectedStudentIds?.length > 0,
          }}
        />
      </div>
      {columns.map((item) => (
        <div key={item}>{item}</div>
      ))}
      <div style={{ marginLeft: "auto" }}>Action</div>
    </div>
  );
};

const MarkEligibleTableHelper = ({
  students,
  checkBoxHandler,
  selectedStudentsForAction,
  selectedStudentIds,
  actionBtnHandler = () => {},
  dispatchSchedular,
  issuedStockToStudent,
  setApplications
}) => {

  if (students.length === 0) {
    return (
      <>
        <TableHeader
          {...{
            checkBoxHandler,
            selectedStudentIds,
          }}
        />
        <NoRowOverlay />
      </>
    );
  }
  const paymentDateHandler =(index,date)=>{
    let currStudents=[...students];
    currStudents[index]['paymentDate']=date;
      setApplications(
    currStudents
      )
  }
  return (
    <>
      <TableHeader {...{ checkBoxHandler, selectedStudentIds }} />
      {students.map((student, index) => {
        return (
          <div
            key={student.applicationId}
            className={`${styles["custom-table-parent-mark-eligible"]} ${styles["table-body"]}`}
          >
            <div>
              <CheckBoxColumn
                {...{
                  issueStockCheckBoxHandler: checkBoxHandler,
                  student,
                  isChecked:
                    selectedStudentsForAction[student.applicationId] || false,
                  disabled: !student?.isDlpMarkStockIssuanceEligible,
                }}
              />
            </div>
            <div>
              <PsidDisplay student={student} />
            </div>

            <div className="d-flex">
              <ValueWithTooltip
                student={student}
                value={student.applicationId}
              />
              <MarkEligibleCourseDetailsPopup applicationData={student} />
              {/* <CourseDetails
                courseId={student.courseId}
                applicationId={student.applicationId}
              /> */}
            </div>

            <div>
              <ValueWithTooltip student={student} value={student.studentName} />
            </div>

            <div>
              {" "}
              <ValueWithOutTooltip
                student={student}
                value={student.programAction}
              />
            </div>
            <div>
              {student?.isDlpMarkStockIssuanceEligible ? (
                <CustomDatePicker
                  value={
                    student?.paymentDate
                      ? moment(student?.paymentDate).format("DD-MMM-YYYY")
                      : moment(student?.registrationDate).format("DD-MMM-YYYY")
                  }
                  placeholder="Example:01-01-2020"
                  handler={(index, date, keyName) => {
                    paymentDateHandler(index,moment(date).format('YYYY-MM-DD'))
                  }}
                  maxDate={new Date()}
                  index={index}
                  rangeFrom={1950}
                  rangeTo={2099}
                  keyName={index}
                />
              ) : student?.registrationDate ? (
               <>{moment(student?.registrationDate).format("DD-MMM-YYYY")} <Calendar size={'1rem'}/></>
              ) : (
                "-"
              )}
            </div>

            {/* <div>Online</div> */}

            <div>
              <MaterialAlreadyIssued
                issuedStockToStudent={getIssuedStockToPsid(
                  student?.psid,
                  issuedStockToStudent
                )}
                dispatchSchedular={dispatchSchedular}
              />
            </div>
            <div style={{ marginLeft: "auto" }}>
              <ActionsCellComponentMarkEligible
                student={student}
                students={students}
                btnHandler={actionBtnHandler}
                selectedStudentIds={selectedStudentIds}
                index={index}
                setApplications={setApplications}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default MarkEligibleTableHelper;
