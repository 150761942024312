import React from "react";

import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import AButton from "../../common/form-fields-mui/AButton";
import { ASelect } from "../../common/form-fields-mui/ASelect";
import AMultiSelect from "../../common/form-fields-mui/AMultiSelect";
import { useSelector } from "react-redux";
import AAutoComplete from './../../common/form-fields-mui/AAutoComplete';

export const modalStyle = {
  position: "absolute" /* Stay in place */,
  zIndex: "75" /* Sit on top */,
  top: "2rem",
  right: "5rem",
  width: "60%" /* Full width */,
  height: "100%" /* Full height */,
};


const buttonStyle = {
   display: "flex", justifyContent: "flex-end" 
};

export const BatchAdvFilterForm = ({
  isAdvance,
  formModal,
  inputHandler,
  applyFilterHandler,
  removeFilterHandler,
  isShowRemoveFilter,
}) => {
  const dropdowns = useSelector((state) => state.batch.dropdowns);
 
  return (
    <div>
      <Card style={{ width: "720px", marginBottom: "0px" }}>
        <CardBody>
          <h4 style = {{color:"black"}}>Advance Filter</h4>
          {/* <Row>
            <Col md="12">Type</Col>
            <Col md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) =>
                      inputHandler(
                        e.target.checked ? "PRIMARY" : "",
                        "batchType"
                      )
                    }
                    checked={formModal.batchType === "PRIMARY"}
                  />
                }
                label="Primary"
              />
            </Col>

            <Col md={6}>
              {" "}
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) =>
                      inputHandler(
                        e.target.checked ? "SECONDARY" : "",
                        "batchType"
                      )
                    }
                    checked={formModal.batchType === "SECONDARY"}
                    sx={checkBoxStyle}
                  />
                }
                label="Secondary"
              />
            </Col>
          </Row> */}
          <Row>
            {/* <Col md={columnSize}>
              <ASelect
                label="Enrollment"
                currentValue={formModal.batchEnrollmentStatus}
                items={dropdowns && dropdowns.enrollment}
                inputKey="batchEnrollmentStatus"
                handleChange={inputHandler}
              />
            </Col> */}
            {isAdvance && (
              <Col md="4">
                <ASelect
                  label="Academic career"
                  items={dropdowns && dropdowns.academicCareerData}
                  currentValue={formModal.academicCareer}
                  inputKey="academicCareer"
                  handleChange={inputHandler}
                />
              </Col>
            )}
            {isAdvance && (
              <Col md="4">
                <ASelect
                  label="Academic group"
                  items={dropdowns && dropdowns.academicGroupData}
                  currentValue={formModal.academicGroup}
                  inputKey="academicGroup"
                  handleChange={inputHandler}
                />
              </Col>
            )}
            {isAdvance && (
              <Col md="4">
                <ASelect
                  label="Medium"
                  currentValue={formModal.medium}
                  items={dropdowns && dropdowns.mediumData}
                  inputKey="medium"
                  handleChange={inputHandler}
                />
              </Col>
            )}
            {/* {isAdvance && (
              <Col md="4">
                <ASelect
                  label="Batch class"
                  currentValue={formModal.batchClass}
                  items={dropdowns && dropdowns.classTypeData}
                  inputKey="batchClass"
                  handleChange={inputHandler}
                  multiple ={true}
                />
              </Col>
            )} */}
          </Row>

          <Row>
            {/* <Col md={columnSize}>
              <ASelect
                label="Status"
                currentValue={formModal.batchStatus}
                items={dropdowns && dropdowns.status}
                inputKey="batchStatus"
                handleChange={inputHandler}
              />
            </Col> */}
            
          </Row>

          <Row>
            
            {isAdvance && (
              <Col md="4">
                <ASelect
                  label="Academic year"
                  currentValue={formModal.academicYear}
                  items={dropdowns && dropdowns.academicYearData}
                  inputKey="academicYear"
                  handleChange={inputHandler}
                />
              </Col>
            )}
            {isAdvance && (
              <Col md="4">
                <ASelect
                  label="School board"
                  currentValue={formModal.schoolBoard}
                  items={dropdowns && dropdowns.schoolBoardData}
                  inputKey="schoolBoard"
                  handleChange={inputHandler}
                />
              </Col>
            )}
           
              <Col md="4">
                <AMultiSelect
                  label="Academic phase"
                  items={dropdowns && dropdowns.academicPhaseData}
                  currentValue={formModal.academicPhase}
                  inputKey="academicPhase"
                  handleChange={inputHandler}
                />

              </Col>
          </Row>
          <Row>
            {/* <Col md={columnSize}>
              <ASelect
                label="Term"
                currentValue={formModal.term}
                items={dropdowns && dropdowns.termData}
                inputKey="term"
                handleChange={inputHandler}
                multiple={true}
              />
            </Col> */}
            
            {isAdvance && (
              <Col md="4">
                <ASelect
                  label="Class type"
                  currentValue={formModal.classType}
                  items={dropdowns && dropdowns.classTypeData}
                  inputKey="classType"
                  handleChange={inputHandler}
                />
              </Col>
            )}
            <Col md="4">
               
                <AAutoComplete
                  label="Class section"
                  isMulti = {false}
                  currentValue={formModal.classSection}
                  items={dropdowns && dropdowns.classSectionData}
                  inputKey="classSection"
                  handleChange={(value)=>inputHandler(value,"classSection")}
                />
              </Col>
          </Row>
          <Row>
            {/* <Col md="12">
              <InputLabel style={InputLabelStyle}>Batch Start date </InputLabel>
            </Col>
            <Col md={columnSize/2}>
              <CommonInput
                type={"datePicker"}
                mandatory={true}
                placeHolder="From"
                value = {formModal.batchStartFromDate}
                onChange={(value) =>
                  inputHandler(new Date(value), "batchStartFromDate")
                }
              />
            </Col>
            <Col md={columnSize/2}>
              <CommonInput
                type={"datePicker"}
                mandatory={true}
                placeHolder="To"
                value = {formModal.batchStartToDate}
                onChange={(value) =>
                  inputHandler(new Date(value), "batchStartToDate")
                }
              />
            </Col> */}


          </Row>
          <Row>
            <Col md="6" style = {buttonStyle}>
            
            </Col>
            <Col md="6" style = {buttonStyle} >
            {isShowRemoveFilter && (
                <AButton
                  onClick={removeFilterHandler}
                  updatedStyle={{
                    border: "1px solid #CC5F5F",
                    borderRadius: "8px",
                    color: "#CC5F5F",
                    margin:".3rem"

                  }}
                >
                  Remove Filter
                </AButton>
              )}
              <AButton
                onClick={applyFilterHandler}
                updatedStyle={{
                  background: "#00B0F5",
                  border: "1px solid #00B0F5",
                  borderRadius: "8px",
                  color: "#fff",
                  margin:".4rem"

                }}
              >
                Apply Filter
              </AButton>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
