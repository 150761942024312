import React, { useEffect, useState } from 'react'
import { Col } from "reactstrap";
import CommonInput from "../../common/formFeilds/input/commonInput";
import CustomButton from 'components/CustomButton';
import { endpoint } from '../../common/constant';
import { fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';

const channelOptions = [
    { label: 'SMS', value: 'SMS' },
    { label: 'EMAIL', value: 'EMAIL' },
    { label: 'WHATSAPP', value: 'WHATSAPP' },
    { label: 'FCM', value: 'FCM' }
]

const NotificationTemplateSearchForm = ({ searchParams, setSearchParms, userPermissions, reset = () => { }, search = () => { }, isFetching, categoryData }) => {

    

    

    return (
        <div className="p-4">
            <div className="form-row">
                <Col className="mb-3" md="3">
                    <label className="form-control-label">{`Template Category`}</label>
                    <CommonInput
                        type="select"
                        defaultValue={searchParams.templateCategoryId}
                        id="fnotification_tempalte_temaplate_category"
                        placeHolder="Template Category"
                        onChange={(e) => setSearchParms({ ...searchParams, templateCategoryId: e })}
                        data={categoryData?.data ?? []}
                        loading={categoryData.fetching}
                    />
                </Col>
                <Col className="mb-3" md="3">
                    <label className="form-control-label">{`Template Channel`}</label>
                    <CommonInput
                        type="select"
                        defaultValue={searchParams.channel}
                        id="fnotification_tempalte_temaplate_channel"
                        placeHolder="Template Channel"
                        onChange={(e) => setSearchParms({ ...searchParams, channel: e })}
                        data={channelOptions}
                    />
                </Col>
                <Col className="mb-3" md="3">
                    <label className="form-control-label">{`Template ID`}</label>
                    <CommonInput
                        type="number"
                        defaultValue={searchParams.templateId}
                        id="notification_tempalte_temaplate_id"
                        placeHolder="Template ID"
                        onKeyPress={(e) => {
                            if (!/^[0-9]+$/.test(String.fromCharCode(e.charCode))) {
                                e.preventDefault();
                                return;
                            }
                        }}
                        onChange={(e) => setSearchParms({ ...searchParams, templateId: e })}
                    />
                </Col>
                <Col className="mb-3" md="3">
                    <label className="form-control-label">{`Template Name`}</label>
                    <CommonInput
                        type="text"
                        defaultValue={searchParams.templateName}
                        id="notification_tempalte_temaplate_name"
                        placeHolder="Template Name"
                        onChange={(e) => setSearchParms({ ...searchParams, templateName: e })}
                    />
                </Col>

            </div>
            <div className="d-flex float-right mt-1">
                <CustomButton
                    disabled={isFetching || (!searchParams.channel && !searchParams.templateId && !searchParams.templateName && !searchParams.templateCategoryId) ? true : false}
                    content={'Search'}
                    permissionType={'R'}
                    icon={true}
                    permissionSet={userPermissions}
                    onClick={search}
                />
                <CustomButton
                    disabled={!searchParams.channel && !searchParams.templateId && !searchParams.templateName  && !searchParams.templateCategoryId ? true : false}
                    content={'Clear All'}
                    type={'reset'}
                    permissionType={'R'}
                    permissionSet={userPermissions}
                    onClick={reset}
                />
            </div>
        </div>
    )
}

export default NotificationTemplateSearchForm