import React, { useEffect, useState } from 'react'
import AInput from '../../../../common/formFeilds/customInput/AInput'


const highlightedStyle = {
  border: "1px solid #00B0F5",
  color: "#00B0F5",
};
const normalStyle = {
  border: "1px solid #CCCCCC",
};

const SearchFilter = ({ nameFilterPayload, applyNameFilter }) => {

  const [userInput, setUserInput] = useState(nameFilterPayload);
  useEffect(() => {
    setUserInput(nameFilterPayload);
  }, [nameFilterPayload])

  const onKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      applyNameFilter(userInput);
    }
  }

  return (
    <div>
      <AInput
        type='text'
        placeholder={'Search here'}
        value={userInput}
        rightSearchIcon
        crossIcon
        handler={(value) => setUserInput(value)}
        onKeyDown={onKeyDown}
        crossHandler={() => { setUserInput(''); applyNameFilter(''); }}
        searchIconClickHandler={() => applyNameFilter(userInput)}
        style={{ width: '170px', borderRadius: '4px', height: '29px', ...normalStyle }}
      />
    </div>
  )
}

export default SearchFilter