import {Button, Col, FormGroup,  Row} from "reactstrap";
import React from "react";
import moment from "moment";
const LogDetailsExamId = (props)=>{
    const {formData,label,button} = props;
    return (
        <div>
            <Row style={{ fontSize: "15px" }} >
                <Col md={5}>
                    <Row className="pl-5 pr-5">
                        <Col md={12}>
                            <FormGroup row>
                                <Col md={5}>
                                    <label className="form-control-label">{label.lastUpdated}</label>
                                </Col>
                                <Col md={7}>
                                    : <label className="pl-3">{formData ? moment(formData['updatedOn']).format('DD/MM/YYYY hh:mm:ss') : 'Not Found'}</label>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col md={5}>
                    <Row  className="pl-5 pr-5">
                        <Col md={12}>
                            <FormGroup row>
                                <Col md={5}>
                                    <label className="form-control-label">{label.lastUpdatedUser}</label>
                                </Col>
                                <Col md={7}>
                                    : <label className="pl-3">{formData ? formData['updatedBy'] : 'Not Found'}</label>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col md={2}>
                    <Button color="info" size="sm" className="floatRight mx-1" onClick={()=>window.close()}>
                        {button.close}
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default LogDetailsExamId;