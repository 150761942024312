import React, { useContext } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegCalendarAlt, FaUserCircle } from "react-icons/fa";
import { ReactComponent as Calender } from '../../../../../assets/img/svg/Calendar.svg';
import { ReactComponent as CalenderRed } from '../../../../../assets/img/svg/Calendar-red.svg';
import { REACT_ICONS_STYLE } from "../constant/batch-search";
import Tooltip from "@mui/material/Tooltip";
import { useHistory } from "react-router";
import MenuItems from "./MenuItems";
import { getTimetable } from "../../academicPlanAndTest/academicTimetable/helper";
import { failureToast } from "../../common/utils/methods/toasterFunctions/function";
import { PermissionContext, RolePermissions } from "../../../../../appContext";
import { pages } from "../../common/constant";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper";

export const BatchListActions = ({ batchData }) => {
  const navigate = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const timetableAccess = RolePermissions(useContext(PermissionContext), pages['createAcademicPlan']['id']);

  const viewCalender = (params) => {
    if(params?.batchType !=="SECONDARY") return <span style={{display:"inline-block", minWidth:"40px"}}></span>
    const { timeTableStatus } = batchData ;
    const mode = timeTableStatus === "P" ?"edit":"create"
    return timeTableStatus === "P" ? (
      <Tooltip title="View/Edit Timetable">
        <span>
          <IconButtonWrapper
            onClick={() =>
              navigate.push(
                `/admin/testScheduling/timetable/${mode}/${batchData.id}`
              )
            }
          >
            <Calender style={{ ...REACT_ICONS_STYLE, stroke: "red" }} />
          </IconButtonWrapper>
        </span>
      </Tooltip>
    ) : (
      <Tooltip title="Create Timetable">
        <span>
          <IconButtonWrapper
            onClick={() =>
              navigate.push(
                `/admin/testScheduling/timetable/create/${batchData.id}`
              )
            }
          >
            <CalenderRed style={{ ...REACT_ICONS_STYLE, stroke: "red" }} />
          </IconButtonWrapper>
        </span>
      </Tooltip>
    );
  };

  // if timetable is not created try to view then show error message if trying to edit/create and timetable is not create then navigate create screen or edit if exist
  const getTimetableAndNavigate = (url, reDirectionScreen) => {
    const str = url.split("/");
    let batchId = str[str.length - 1];
    getTimetable(batchId).then((res) => {
      if (res) {
        navigate.push(
          `/admin/testScheduling/timetable/${reDirectionScreen}/${batchId}`
        );
      } else {
        if (reDirectionScreen === "view") {
          failureToast(
            "Timetable not created for this batch. Please create the batch’s timetable first"
          );
        } else {
          navigate.push(url);
        }
      }
    });
  };

  const handleClose = (url) => {
    setAnchorEl(null);
    if (typeof url === "string" && url.includes("timetable/create")) {
      getTimetableAndNavigate(url, "edit");
    } else if (typeof url === "string" && url.includes("timetable/view")) {
      getTimetableAndNavigate(url, "view");
    } else {
      navigate.push(url);
    }
  };

  const menuItems = [
    { id: 22, name: "View Batch", url: "/admin/edp/batch/view/" },
    { id: 33, name: "Edit Batch", url: "/admin/edp/batch/edit/" },
    { id: 88, name: "Allocate Students", url: "/admin/edp/allocateStudents/" },
    {
      id: 44,
      name: "Create/Edit Timetable",
      url: "/admin/testScheduling/timetable/create/",
      onlySecondary: true,
    },
    {
      id: 33,
      name: "View Timetable",
      url: "/admin/testScheduling/timetable/view/",
      onlySecondary: true,
    },
  ];
  return (
    <div>
      {viewCalender(batchData)}
      <Tooltip title="View Students">
        <span>
          <IconButtonWrapper
            onClick={() =>
              navigate.push(`/admin/edp/deallocateStudents/${batchData.id}`)
            }
          >
            <FaUserCircle style={REACT_ICONS_STYLE} />
          </IconButtonWrapper>
        </span>
      </Tooltip>
      {/* <Tooltip title = "View Timetable">
         <span><FaRegCalendarAlt style={ REACT_ICONS_STYLE}/></span>
       </Tooltip> */}
      <Tooltip title="More Actions">
        <span
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <IconButtonWrapper><BsThreeDotsVertical style={REACT_ICONS_STYLE} /></IconButtonWrapper>
          
        </span>
      </Tooltip>
      <MenuItems
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleClose={handleClose}
        menuItems={menuItems}
        batchId={batchData.id}
        batchData={batchData}
      />
    </div>
  );
};
