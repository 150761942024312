import React, { useEffect, useState, Fragment } from 'react';
import Loader from 'react-loader-spinner';
import {
	Card,
	CardHeader,
	Container,
	Row,
	Col,
	CardBody,
	Input,
	Button,
	Label,
	FormGroup,
	Form,
	FormFeedback,
} from 'reactstrap';
import { validate } from '../../common/utils/methods/validations/validation';
import {
	getAPI,
	masterServiceBaseUrl,
	sfServiceBaseUrl,
	putpost,
	successToast,
	failureToast,
} from 'services/http';
import ShowDataShow from './serviceDataShow';

const SearchService = () => {
	const [psIdData, setPsIdData] = useState();
	const [psId, setPsId] = useState('');
	const [searchPsID, setSearchPsId] = useState('');
	const [isDisabled, setIsDisabled] = useState(false);
	const [isMessage, setIsMessage] = useState(false);

	const submitHandler = (type) => {
		try {
			if (!psId) return failureToast('Please Enter Student ID');
			setIsDisabled(true);
			putpost(
				`${sfServiceBaseUrl}/getListServiceTickets`,
				(data) => {
					setIsDisabled(false);
					if (!data['data']['Records']) {
						return failureToast('No Record Exist For Student ID');
					}
					if (type === 'searchInside') {
						setPsId(searchPsID);
						setSearchPsId('');
					}
					setPsIdData(data['data']['Records']);
				},
				(data) => {
					failureToast('Please Enter Valid Student ID');
					setIsDisabled(false);
				},
				{ psid: type === 'searchInside' ? searchPsID : psId },
				'post'
			);
		} catch (e) {
			console.log('Error', e);
			setIsDisabled(false);
		}
	};

	const getSearchData = (id, type) => {
		try {
			if (!id) return failureToast('Please Enter Student ID');
			submitHandler('searchInside');
		} catch (e) {
			console.log('Error ::', e);
		}
	};

	return (
		<Container className="mt-3 student_service_container" fluid>
			<Card>
				{!psIdData ? (
					<Fragment>
						<CardHeader>
							<h2 className="heading_color">Student Service Request</h2>
						</CardHeader>
						<CardBody>
							<Row className="d-flex align-items-center justify-content-center">
								<Col sm="6">
									<Form>
										<FormGroup>
											<Label className="form-control-label">
												Enter Student ID
											</Label>
											<Input
												value={psId}
												disabled={isDisabled}
												maxLength="12"
												placeholder="Enter Student ID"
												name="psid"
												onChange={(e) => {
													let value = e.target.value;
													if (validate('numericValue', value)) {
														setPsId(value);
													}
												}}
											/>
											<Button
												disabled={isDisabled}
												onClick={() => submitHandler('searchOutside')}
												color="info"
												className="w-100 mt-3"
											>
												Submit
												{isDisabled ? (
													<Loader
														className="button-Loader"
														type="Oval"
														color="#ffffff"
														height={30}
														width={30}
													/>
												) : null}
											</Button>
										</FormGroup>
									</Form>
								</Col>
							</Row>{' '}
						</CardBody>
					</Fragment>
				) : (
					<ShowDataShow
						psIdData={psIdData}
						psId={psId}
						isDisabled={isDisabled}
						getSearchData={getSearchData}
						setSearchPsId={setSearchPsId}
						searchPsID={searchPsID}
					/>
				)}
			</Card>
		</Container>
	);
};

export default SearchService;
