import { Box, Divider, Typography, Tooltip } from "@mui/material";
import moment from "moment";
import React, { useState, useMemo, useEffect } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { useParams } from "react-router";
import { Row } from "reactstrap";
// import { endpoint } from 'views/pages/manage/common/constant';
import CommonInput from "views/pages/manage/common/formFeilds/input/commonInput";
// import { fetchAllPromisedData } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import { FEE_COMPONENTS, NULL_CUSTOM_OBJ } from ".";
import CustomButton from "../../../../../../../components/CustomButton";
import { LightTooltip } from "./prospectus";
import { DiscretionarySupportingDocUploader } from "./upload";
import { APPROVAL_STATUS, NEW_APPROVAL_STATUS, STATUS_DATA } from "./constants";
import { roundTo2Decimal } from "../../common";
import CustomLoader from "views/pages/manage/common/commonComponents/Loader/loader";
import { useHistory } from "react-router-dom";

import useConfirm from "../../../../common/commonComponents/ConfirmationDialog/confirmationPrompt.jsx";
import RemarksDialogue, {
  fetchRemarks,
} from "../../../concessionApproval/RemarksDialogue";
import deleteIcon from "assets/img/svg/Delete.svg";
import editIcon from "assets/img/svg/Edit2.svg";
import { regex } from "../../../../common/constant";

const getFormatedDate = (date) => {
  return date ? moment(date).format("DD-MMM-YYYY") : "";
};

export const getFormatedDateTime = (date) => {
  return date ? moment(date).format("DD-MMM-YYYY, hh:mm A") : "";
};

// const getApprovalStatus = (currentStatus, isApproved) => {
//     let status = isApproved ? 'Approved' : 'In Progress';
//     if (!isApproved) {

//         status = NEW_APPROVAL_STATUS?.[currentStatus] ?? ''
//     }
//     return status
// }

const DiscretionaryConcession = (props) => {
  const {
    userPermissions,
    customSlab,
    setCustomSlab,
    regFee,
    adminFee,
    classFee,
    techFee,
    tutionFee,
    customConfig,
    open,
    setOpen,
    allCategories,
    saveAndSend,
    applicationDetails,
    isViewOnly,
    categoryFeeComps,
    fetching,
    disableActions,
    extraComponent,
    setEditFormOpen,
    isCustomEditFormEnable,
    handleCustomFeeComponentInGlobleSelector,
    freezeAction,
    deleteCusFeeCompInGlobleSelector,
    feeComps,
    setComponentInCustom,
    paymentDetails,
    selectedQoutationId,
    viewMode,
    setViewMode
  } = props;
  const history = useHistory();
  // const [viewMode, setViewMode] = useState(false);
  const [dataOpen, setDataOpen] = useState(false);
  const [historyData, setHistoryData] = useState(null);
  const [ConfirmationDialog, confirm] = useConfirm();
  const [prevStatus, setPreviouStatus] = useState("");
  // const [ConfirmationDialog, confirm, inputValue] = useConfirm();

  // let prevStatus = null;

  // console.log("freezeAction",freezeAction)
  // console.log("disableActions",disableActions)

  const statusCheck = true;
  let { id, action } = useParams();

  useEffect(() => {
    const { status } = customSlab;
    if (!isCustomEditFormEnable) {
      if (
        !customSlab?.fromBE &&
        customSlab?.action !== "CREATE" &&
        (status === APPROVAL_STATUS.NA ||
          status === null ||
          [
            "L1_RECHECK",
            "L2_RECHECK",
            "L3_RECHECK",
            "REVOKE",
            "MODIFY",
          ].includes(status))
      ) {
        // setEditFormOpen(true)
        setViewMode(false);
      } else {
        setViewMode(true);
      }
    }
  }, [customSlab]);

  const getComponents = (initial = [], extra = []) => {
    return [...new Set([...initial, ...extra])] ?? [];
  };

  const onChange = (key, val, isDirty) => {
    if (key === "scholarshipPercentage") {
      setCustomSlab((prev) => ({
        ...prev,
        isDirty: isDirty === false ? isDirty : true,
        [key]: val,
        scholarshipValue: getValueFromPercentage(
          val,
          getComponents(
            categoryFeeComps,
            FEE_COMPONENTS.filter((i) => extraComponent.includes(i.value))
          )
        ),
      }));
    } else if (key === "scholarshipValue") {
      setCustomSlab((prev) => ({
        ...prev,
        isDirty: isDirty === false ? isDirty : true,
        [key]: val,
        scholarshipPercentage: getPercentageFromValue(
          val,
          getComponents(
            categoryFeeComps,
            FEE_COMPONENTS.filter((i) => extraComponent.includes(i.value))
          )
        ),
      }));
    } else if (key === "feeComp") {
      setCustomSlab((prev) => ({
        ...prev,
        isDirty: true,
        [key]: val,
        scholarshipValue: "",
        scholarshipPercentage: "",
      }));
    } else {
      setCustomSlab((prev) => ({ ...prev, isDirty: true, [key]: val }));
    }
  };

  const handleSave = () => {
    if (
      customSlab?.scholarshipValue === "" ||
      !customSlab?.scholarshipValue ||
      !customSlab?.scholarshipPercentage ||
      customSlab?.scholarshipPercentage === ""
    ) {
      failureToast("Please Enter Mandatory Values");
      return;
    }

    if (customSlab?.fromBE) {
      setEditFormOpen(false);
      setCustomSlab((prev) => ({ ...prev, action: "UPDATE", isDirty: true }));
      // setViewMode(true);
      setComponentInCustom(categoryFeeComps.map((i) => i.value));
    } else {
      setEditFormOpen(false);
      setCustomSlab((prev) => ({ ...prev, action: "CREATE", isDirty: true }));
      setComponentInCustom(categoryFeeComps.map((i) => i.value));
    }
  };

  const getTotalFee = (feeComps) => {
    const totalFee = feeComps.reduce((acc, curr) => {
      switch (curr.value) {
        case "REG_FEE":
          return acc + regFee;
        case "ADMIN_FEE":
          return acc + adminFee;
        case "TUITION_FEE":
          return acc + tutionFee;
        case "CLASS_FEE":
          return acc + classFee;
        case "TECH_FEE":
          return acc + techFee;
        default:
          return acc;
      }
    }, 0);
    console.log("totalFee", totalFee);
    return roundTo2Decimal(totalFee);
  };

  const getPercentageFromValue = (val, feeComps) => {
    if (val === "") return "";
    const totalFee = getTotalFee(feeComps);
    if (totalFee === 0) return 0;
    return roundTo2Decimal((+val * 100) / totalFee);
  };

  const getValueFromPercentage = (percentage, feeComps) => {
    if (percentage === "") return "";
    const totalFee = getTotalFee(feeComps);
    return roundTo2Decimal((+percentage * totalFee) / 100);
  };
  const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
  };

  const handleSubmitWithPrompt = async (status) => {
    setPreviouStatus(
      `Are you sure want to ${
        status === "REVOKE" ? "revoke" : "modify"
      } the charges ?`
    );
    const canContinue = await confirm();
    if (!canContinue) return;
    saveAndSend(true, status);
    setPreviouStatus(null);
  };

  useMemo(() => {
    if (
      (customSlab?.fromBE || customSlab?.action === "CREATE") &&
      customSlab?.isDirty
    ) {
      onChange(
        "scholarshipPercentage",
        customSlab?.scholarshipPercentage,
        customSlab?.isDirty ?? false
      );
    }
  }, [extraComponent]);

  const columns = useMemo(() => {
    return [
      {
        field: "code",
        headerName: "Code",
        flex: 0.5,
        minWidth: 69,
        type: "singleSelect",
        mandatory: true,
        editable: false,
        disabled: false,
        value: customSlab.code,
        renderCell: (params) => {
          return (
            <div className="custom-concession-cell">{customSlab.code}</div>
          );
        },
      },
      {
        field: "feeComp",
        headerName: "Fee Comp.",
        flex: 1,
        minWidth: 128,
        type: "multiSelect",
        mandatory: true,
        editable: true,
        disabled: false,
        valueOptions: categoryFeeComps,
        placeHolder: "",
        value: customSlab.feeComp,
        renderCell: (params) => {
          return (
            <div className="d-flex w-100 custom-concession-cell">
              <span
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "85%",
                  whiteSpace: "nowrap",
                }}
              >
                {getComponents(
                  categoryFeeComps,
                  FEE_COMPONENTS.filter((i) => extraComponent.includes(i.value))
                )
                  .map((item) => item.value)
                  .join(",")}
              </span>
              {[...categoryFeeComps, ...extraComponent].length > 0 && (
                <LightTooltip
                  title={getComponents(
                    categoryFeeComps,
                    FEE_COMPONENTS.filter((i) =>
                      extraComponent.includes(i.value)
                    )
                  )
                    .map((item) => item.value)
                    .join(",")}
                >
                  <div style={{ cursor: "pointer" }}>
                    <AiOutlineEye className="ml-1" size={16} color="#11cdef" />
                  </div>
                </LightTooltip>
              )}
            </div>
          );
        },
        renderEditCell: (params) => {
          return (
            <div className="d-flex w-100 custom-concession-cell">
              <span
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "85%",
                  whiteSpace: "nowrap",
                }}
              >
                {getComponents(
                  categoryFeeComps,
                  FEE_COMPONENTS.filter((i) => extraComponent.includes(i.value))
                )
                  .map((item) => item.value)
                  .join(",")}
              </span>
              {getComponents(
                categoryFeeComps,
                FEE_COMPONENTS.filter((i) => extraComponent.includes(i.value))
              ).length > 0 && (
                <LightTooltip
                  title={getComponents(
                    categoryFeeComps,
                    FEE_COMPONENTS.filter((i) =>
                      extraComponent.includes(i.value)
                    )
                  )
                    .map((item) => item.value)
                    .join(",")}
                >
                  <div style={{ cursor: "pointer" }}>
                    <AiOutlineEye className="ml-1" size={16} color="#11cdef" />
                  </div>
                </LightTooltip>
              )}
            </div>
          );
        },
      },
      {
        field: "scholarship",
        headerName: "Scholarship",
        flex: 1,
        minWidth: viewMode ? 125 : 234,
        mandatory: true,
        editable: true,
        renderCell: (params) => {
          return (
            <div className="custom-concession-cell">{`₹ ${customSlab?.scholarshipValue} (${customSlab?.scholarshipPercentage}%)`}</div>
          );
        },
        renderEditCell: (params) => {
          return (
            <div className="custom-concession-cell">
              <div className="scholarship-percentage">
                <CommonInput
                  type={"text"}
                  regEx={regex.numericDecimal}
                  maxLength={100}
                  forTable={true}
                  allowDecimalvalue={true}
                  defaultValue={customSlab.scholarshipPercentage}
                  placeHolder={"-"}
                  onChange={(val) => {
                    if (+val > 100 || String(val).split(".")[1]?.length > 2) {
                      return;
                    } else {
                      onChange("scholarshipPercentage", val);
                    }
                  }}
                  isDisabled={freezeAction || disableActions}
                />
              </div>
              <div className="scholarship-value">
                <CommonInput
                  type={"text"}
                  regEx={regex.numericDecimal}
                  maxLength={100}
                  forTable={true}
                  allowDecimalvalue={true}
                  defaultValue={customSlab.scholarshipValue}
                  placeHolder={"Enter value"}
                  onChange={(val) => {
                    // debugger
                    if (
                      +val >
                        getTotalFee([
                          ...categoryFeeComps,
                          ...FEE_COMPONENTS.filter((i) =>
                            extraComponent.includes(i.value)
                          ),
                        ]) ||
                      String(val).split(".")[1]?.length > 2
                    ) {
                      return;
                    } else {
                      onChange("scholarshipValue", val);
                    }
                  }}
                  isDisabled={freezeAction || disableActions}
                />
              </div>
            </div>
          );
        },
        value: customSlab.scholarship,
      },
      // { field: 'adminFeeConcession', headerName: 'Admission Fee', width: 50, mandatory: false, editable: true, hide: true },
      // { field: 'regFeeConcession', headerName: 'Reg Fee', width: 50, mandatory: false, editable: true, hide: true },
      // { field: 'classFeeConcession', headerName: 'Class Fee', width: 50, mandatory: false, editable: true, hide: true },
      // { field: 'techFeeConcession', headerName: 'Tech Fee', width: 50, mandatory: false, editable: true, hide: true },
      // { field: 'tutionFeeConcession', headerName: 'Tution Fee', width: 50, mandatory: false, editable: true, hide: true },
      // { field: 'concessionParameter', headerName: 'Concession Parameter', width: 50, mandatory: false, editable: true, hide: true },
      {
        field: "remarks",
        headerName: "Remarks",
        flex: 1,
        minWidth: 127,
        type: "text",
        mandatory: false,
        editable: true,
        disabled: false,
        value: customSlab.remarks,
        renderCell: (params) => (
          <div
            className="w-100 d-flex custom-concession-cell"
            style={{ justifyContent: "center" }}
          >
            {/* <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '85%', whiteSpace: 'nowrap' }}>{customSlab.remarks}</span> */}

            {
              // <div style={{ cursor: 'pointer' }} onClick={() => { setDataOpen(true);getApprovalData()}}>
              <div
                style={{ cursor: "pointer", color: '#00B0F5' }}
                onClick={() => {
                  fetchRemarks(
                     applicationDetails?.applicationId,
                    setHistoryData,
                    setDataOpen,
                    customSlab?.id
                  );
                }}
              >
                {/* <AiOutlineEye className="ml-1" size={16} color="#11cdef" /> */}
              View
              </div>
            }
          </div>
        ),
        renderEditCell: (params) => {
          return (
            <div className="custom-concession-cell">
              <CommonInput
                type={"text"}
                defaultValue={customSlab.remarks}
                placeHolder={"Add remarks"}
                maxLength={400}
                // restrictSpecialCharacter={col.restrictSpecialCharacter ?? true}
                onChange={(val) => onChange("remarks", val)}
                forTable={true}
                isDisabled={freezeAction || disableActions}
                // regEx={col.regEx}
              />
            </div>
          );
        },
      },
      {
        field: "concessionDocuments",
        headerName: "Documents",
        flex: 1,
        minWidth: 100,
        mandatory: false,
        editable: true,
        disabled: false,
        sortable: false,
        initialValue: [],
        renderCell: (params) => (
          <DiscretionarySupportingDocUploader
            userPermissions={userPermissions}
            viewOnly={true}
            onChange={onChange}
            defaultvalue={customSlab.concessionDocuments}
            viewText={true}
          />
        ),
        renderEditCell: (params) => (
          <DiscretionarySupportingDocUploader
            userPermissions={userPermissions}
            viewOnly={false || disableActions}
            onChange={onChange}
            defaultvalue={customSlab.concessionDocuments}
            // viewText={true}
          />
        ),
      },
      {
        field: "updatedOn",
        headerName: "Updated on",
        flex: 1,
        minWidth: 130,
        mandatory: false,
        editable: false,
        disabled: false,
        value: customSlab.updatedOn,
        renderCell: (params) => (
          <div className="custom-concession-cell">
            <LightTooltip title={getFormatedDateTime(customSlab.updatedOn)}>
              <div style={{ cursor: "pointer" }}>
                <span>{getFormatedDate(customSlab.updatedOn)}</span>
              </div>
            </LightTooltip>
          </div>
        ),
        renderEditCell: (params) => (
          <div className="custom-concession-cell">-</div>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        minWidth: 130,
        mandatory: false,
        editable: false,
        disabled: false,
        renderCell: (params) => {
          const getApprPercentage = (status) => {
            switch (status) {
              case "L1_APPROVED":
                return customConfig?.l1ApprovalPercentage || "";
              case "L2_APPROVED":
                return customConfig?.l2ApprovalPercentage || "";
              default:
                return "";
            }
          };

          const percent = customSlab.isApproved
            ? 0
            : getApprPercentage(customSlab.status);
          const val = customSlab.isApproved
            ? "Approved"
            : `${percent ? percent + "% " : ""}${
                NEW_APPROVAL_STATUS?.[customSlab?.status] ?? ""
              }`;
          return (
            <div className="w-100 d-flex custom-concession-cell">
              <LightTooltip title={val}>
                <div
                  className="d-flex"
                  style={{ marginTop: "0px", width: "90%" }}
                >
                  <span
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100%",
                      color : '#262626',
                      whiteSpace: "nowrap",
                      padding: ["Approved", "Rejected", "Recheck"].includes(val)
                        ? "2px 10px"
                        : "2px 10px",
                      borderRadius: "4px",
                      backgroundColor: STATUS_DATA[val]?.BCOLOR ?? (val ? '#CCEFFD' : ''),
                    }}
                  >
                    {val}
                  </span>
                </div>
              </LightTooltip>
            </div>
          );
        },
        renderEditCell: (params) => (
          <div className="custom-concession-cell">-</div>
        ),
        value: customSlab.status,
      },
      {
        field: "action",
        headerName: "Actions",
        flex: 1,
        minWidth: 120,
        mandatory: false,
        editable: false,
        disabled: false,
        hide:
          disableActions || freezeAction || action === "view" ? true : false,
        renderCell: (params) => {
          return (
            <div className="w-100 d-flex custom-concession-cell action-column">
              {viewMode ? (
                <>
                  <CustomButton
                    className="muiTable-button-edit btn-transparent"
                    icon={false}
                    content={
                      <Tooltip title="Edit" placement="top-start">
                        <img src={editIcon} />
                      </Tooltip>
                    }
                    forTable={true}
                    permissionType={"U"}
                    permissionSet={userPermissions}
                    onClick={() => {
                      setViewMode(false);
                      setEditFormOpen(true);
                    }}
                    disabled={freezeAction || disableActions}
                  />
                  ,
                  <CustomButton
                    className="muiTable-button-delete btn-transparent"
                    icon={false}
                    content={
                      <Tooltip title="Delete" placement="top-start">
                        <img src={deleteIcon} />
                      </Tooltip>
                    }
                    forTable={true}
                    permissionType={"D"}
                    permissionSet={userPermissions}
                    onClick={() => {
                      setCustomSlab({
                        ...NULL_CUSTOM_OBJ,
                        id: customSlab.id,
                        fromBE: customSlab.fromBE,
                        isDirty: true,
                        action: customSlab.fromBE ? "DELETE" : "",
                      });
                      setViewMode(false);
                      setEditFormOpen(true);
                      setComponentInCustom([]);
                    }}
                    disabled={freezeAction || disableActions}
                  />
                </>
              ) : (
                <>
                  <CustomButton
                    content={"Save"}
                    forTable={true}
                    permissionType={"C"}
                    permissionSet={userPermissions}
                    onClick={handleSave}
                    disabled={
                      userPermissions?.findIndex((e) => e === "A") === -1 ||
                      disableActions ||
                      freezeAction
                    }
                  />
                  <CustomButton
                    icon={true}
                    type={"cancel"}
                    // content={'Clear'}
                    forTable={true}
                    permissionType={"cancel"}
                    permissionSet={userPermissions}
                    onClick={
                      customSlab?.action !== "DELETE"
                        ? () => {
                            setCustomSlab({
                              ...NULL_CUSTOM_OBJ,
                              id: customSlab.id,
                              fromBE: customSlab.fromBE,
                              isDirty: true,
                            });
                          }
                        : () => {}
                    }
                    disabled={disableActions || freezeAction}
                  />
                </>
              )}
            </div>
          );
        },
        value: customSlab.status,
      },
    ];
  }, [
    customSlab,
    categoryFeeComps,
    extraComponent,
    feeComps,
    viewMode,
    freezeAction,
    disableActions,
  ]);

  const headerComp = (
    <Row onClick={()=>setOpen(prev=>({...prev , custom : !prev.custom}))} className="custom-concession-header cursor">
      <div className="custom-concession-header-title">
        <h3>Custom concession</h3>
      </div>
      {action !== "view" && (
        <div className="custom-concession-button-group">
          {/* <CustomButton
            permissionType={"R"}
            outline={true}
            content={"View all document"}
            onClick={() => {}}
            permissionSet={userPermissions}
          /> */}
          <MdKeyboardArrowUp
            className="ml-2"
            onClick={e => {e.stopPropagation(); setOpen((open) => ({ ...open, custom: false }))}}
            size={26}
          />
        </div>
      )}
    </Row>
  );

  const handleNavigate = () => {
    history.push({
      pathname: "/admin/concessionApproval/pending",
      state: {
        psid: applicationDetails.psid,
        roleId:
          customSlab.status === "Pending"
            ? "L1"
            : customSlab.status === "L1_APPROVED"
            ? "L2"
            : "L3",
        status: "pending",
      },
    });
  };

  return (
    <>
      <div className="custom-concession">
        {open ? (
          <>
            {headerComp}
            <Box
              className="custom-concession-table-container"
              sx={{
                display: "flex",
                overflowX: "hidden",
                paddingBottom: "14px",
              }}
            >
              {fetching ? (
                <div className="w-100 d-flex justify-content-center">
                  <CustomLoader />
                </div>
              ) : (
                columns
                  .filter((col) => col.hide !== true)
                  .map((col) => {
                    return (
                      <Box
                        data-field={col.field}
                        key={col.field}
                        sx={{
                          minWidth: col.minWidth,
                          padding: "0 10px",
                          flex: col.flex,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.875rem",
                            fontWeight: 500,
                            color: "#262626",
                            fontFamily: "Gilroy-Medium",
                            display: "block",
                            margin: "0 0 16px 0",
                            height: "24px",
                          }}
                        >
                          {col.headerName}
                          {col.mandatory && (
                            <Typography
                              sx={{ color: "red", display: "inline" }}
                            >
                              *
                            </Typography>
                          )}
                        </Typography>
                        <Divider light />

                        {viewMode || col.editable === false
                          ? col.renderCell()
                          : col.renderEditCell()}

                        {/* {(viewMode || col.editable === false) ? col.renderCell() : col.renderEditCell()} */}
                      </Box>
                    );
                  })
              )}
            </Box>
            {/* <GetDisplayData
                            customSlab={customSlab} />
                        {!customSlab.isApproved && ['Pending', 'L1_APPROVED', 'L2_APPROVED'].includes(customSlab.status) && isViewOnly && userPermissions?.includes('A') ? <div className='p-2'><Stack height="64px" alignItems="center" sx={{
                            background: '#E5F5FF',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            padding: '0px 10px',
                            borderRadius: '10px'
                        }}>
                            <span className='regular-bolder'>{`${customSlab?.createdBy}`}</span>
                            <CustomButton
                                className='regular-bolder'
                                content={<Tooltip title="Click to take action on the concession" placement='top-start'><span>Go to Approval dashboard</span></Tooltip>}
                                permissionType={'A'}
                                // forTable={true}
                                permissionSet={userPermissions}
                                onClick={() => { handleNavigate() }}
                            />
                        </Stack> </div> : null} */}
          </>
        ) : (
          <Row onClick={()=>setOpen(prev=>({...prev , custom : !prev.custom}))} className="custom-concession-header-collapsed py-3 cursor">
            <div className="custom-concession-header-title">
              <h3>Custom concession</h3>
            </div>
            <MdKeyboardArrowDown
              size={26}
              onClick={e => {e.stopPropagation(); setOpen(prev => ({ ...prev ,custom: true }))}}
            />
          </Row>
        )}
      </div>

      {
        <RemarksDialogue
          remarksData={historyData}
          open={dataOpen}
          setOpen={setDataOpen}
          setRemarksData={setHistoryData}
        />
      }

      <ConfirmationDialog
        message={"Are you sure you want to delete the selected row?"}
        title={"Confirm row delete"}
        nextButtonText={""}
        backButtonText={"No"}
        leftAlign={true}
      />
    </>
  );
};

export default DiscretionaryConcession;
