import React, { useState, useEffect } from "react";
import "./style.scss";
import SelectPhotoModal from "./UpdatePhotoModal";
import { getFileUrl } from "./common";
const StudentPhoto = ({ photoKey, fetchApplications, psid,isUpdate=true}) => {
  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const isPhotoKeyExist =
    photoKey === "Absent" || photoKey === null ? false : true;

  useEffect(() => {
    if (isPhotoKeyExist) getImgSrc();
  }, [photoKey, isPhotoKeyExist]);

  const getImgSrc = async () => {
    const imgSrc = await getFileUrl(photoKey);
    setImageSrc(imgSrc);
  };

  return (
    <div className="photo-container ">
      {open && (
        <SelectPhotoModal
          open={open}
          setOpen={setOpen}
          fetchApplications={fetchApplications}
          psid={psid}
        />
      )}
      {imageSrc ? (
        <img height={"96px"} width={"96px"} src={imageSrc}></img>
      ) : (
        <div className="camera-icon">
          <i className="fas fa-camera"></i>
        </div>
      )}
      { isUpdate && <div onClick={() => setOpen(true)} className=" update-photo-btn ">
        {isPhotoKeyExist ? "Update " : "Upload"}
      </div>
      }
    </div>
  );
};

export default StudentPhoto;
