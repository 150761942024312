import React, { useEffect, useState } from "react";
import { getBusinessAreas, reasonAndBusinessAreaModal } from "../../edpOps/constant/batch-search";

export const useOutsideClick = (ref, callback) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export const useRegionAndBusinessArea = (startLoader = () => { }, stopLoader = () => { }) => {

  const [regionAndBusiness, setRegionAndBusiness] = useState(
    reasonAndBusinessAreaModal
  );
    

  useEffect(() => {
    (updateBA(regionAndBusiness.region))
  }, [regionAndBusiness.region])

  const updateBA = async (regions) => {
    startLoader();
    if (regions.length === 0) {
      setRegionAndBusiness({ ...regionAndBusiness, businessArea: [] })
      stopLoader();
      return
    }
    else {
      const response = await getBusinessAreas(regions);
      setRegionAndBusiness({ ...regionAndBusiness, businessArea: response })
      stopLoader();
    }
  }
  return [regionAndBusiness, setRegionAndBusiness]
}