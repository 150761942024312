import { Box, Typography } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { useHistory } from 'react-router';
import { PermissionContext, RolePermissions } from '../../../../../../appContext';
import MuiTable from '../../../../manage/common/commonComponents/MuiTable.js';
import { endpoint, pages } from '../../../common/constant';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { failureToast, successToast } from '../../../common/utils/methods/toasterFunctions/function';
import './index.scss';
import Select from "react-select";
import { customStylesSelect, stylesForFilledSelect } from '../../../common/formFeilds/input/commonInput';
import {LightTooltip} from '../../studentCharges/studentChargesDetails/concessionDetails/prospectus';
import { AiOutlineEye, AiOutlineInfoCircle } from 'react-icons/ai';

const getFormatedDate = (date) => {
    return date ? moment(date).format('HH:mm, DD-MMM-YYYY') : ''
}

const COLUMNS = [
    { field: 'codeID', headerName: 'ID', type: 'number', flex: 111, mandatory: false, editable: false, disabled: false, sortable: false },
    {
        field: 'career', headerName: 'Career', type: 'singleSelect', flex: 240, mandatory: true, editable: true, disabled: false,
        valueOptions: [], sortable: false, placeHolder: 'Select'
    },
    {
        field: 'term', headerName: 'Term', type: 'multiSelect', flex: 130, mandatory: true, editable: true, disabled: false,
        valueOptions: [], sortable: false, placeHolder: 'Select'
    },
    {
        field: 'courseCategory', headerName: 'Course category', type: 'multiSelect', flex: 190, mandatory: true, editable: true, disabled: false,
        valueOptions: [], sortable: false, placeHolder: 'Select'
    },
    {
        field: 'fullFirstYear', headerName: 'Full 1st year', flex: 129, type: 'number', allowDecimalvalue: true, mandatory: true,
        editable: true, disabled: false, maxValue: 100, formatter: (data) => data + ' %', sortable: false, placeHolder: '-'
    },
    {
        field: 'yearOnYear', headerName: 'Year on year', flex: 129, type: 'number', allowDecimalvalue: true, mandatory: true,
        editable: true, disabled: false, maxValue: 100, formatter: (data) => data + ' %', sortable: false, placeHolder: '-'
    },
    {
        field: 'fullUpFront', headerName: 'Full upfront', flex: 129, type: 'number', allowDecimalvalue: true, mandatory: true,
        editable: true, disabled: false, maxValue: 100, formatter: (data) => data + ' %', sortable: false, placeHolder: '-'
    },
    {
        field: 'lastUpdated', headerName: 'Last update', flex: 164, type: 'text', mandatory: false, editable: false, disabled: false,
        sortable: false,
        renderCell: (params) => {
            const date = getFormatedDate(params.value);
            return (
                <div className='w-100 d-flex'>
                    <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '85%', whiteSpace: 'nowrap' }}>{date}</span>
                    {params.value &&
                        <LightTooltip title={date}>
                            <div style={{ cursor: 'pointer' }}>
                                <AiOutlineInfoCircle className='ml-1' size={16} color='#0F0F0F' />
                            </div>
                        </LightTooltip>
                    }
                </div>
            );
        },
    },
];

// const CareerComp = params => {

//     const { id, field, value, row, slabs, careerIds } = params;
//     const apiRef = useGridApiContext();

//     const handleChange = (newValue) => {
//       apiRef.current.setEditCellValue({ id, field, value: [newValue] });
//     }

//     const options = careerIds.filter(item => item.value === row.career ? true : slabs.findIndex(slab => slab.career === item.value) === -1);

//     const val = value?.[0] ? options.find(item => item.value === value[0].value) : null;

//     return (
//       <div style={{ width: '100%' }}>
//         <Select
//           style={{ height: '40px !important' }}
//           styles={val ? stylesForFilledSelect: customStylesSelect}
//           placeholder={"Select"}
//           value={val}
//           isDisabled={false}
//           options={options}
//           onChange={(e) => {
//             handleChange(e);
//           }}
//           menuPortalTarget={document.body}
//         />
//       </div>
  
//     )
// }

const Lumpsum = () => {

    const permissions = useContext(PermissionContext);
    const userPermissions = RolePermissions(permissions, pages["consession"]['id']);
    const history = useHistory();

    const [academicCareerIds, setAcademicCareerIds] = useState(null);
    const [termIds, setTermIds] = useState(null);
    const [courseAttributeIds, setCourseAttributeIds] = useState(null);

    const FORM_FIELDS = [
        { field: 'career', name: 'academicCareer', state: academicCareerIds, stateMethod: setAcademicCareerIds, url: endpoint.academic_career.getAllActive },
        { field: 'courseCategory', name: "courseAttributeValue", state: courseAttributeIds, stateMethod: setCourseAttributeIds, url: endpoint?.courseCategory?.getAllActiveCourseCategory },
        { field: 'term', name: "term", stateMethod: setTermIds, state: termIds, url: endpoint.term.getAllActive },
    ];

    const [loading, setLoading] = useState(false);
    const [slabs, setSlabs] = useState([]);
    const [cols, setCols] = useState(COLUMNS);

    useEffect(() => {
        (function init() {
            const getAllCalls = [];
            for (let i = 0; i < FORM_FIELDS.length; i++) {
                const { url, name, stateMethod } = FORM_FIELDS[i];
                getAllCalls.push(fetchAllData(url, name, stateMethod));
            }
            setLoading(true);
            Promise.all(getAllCalls).then(() => setLoading(false)).catch(err => console.log(err));
        })();
    }, []);

    useEffect(() => {
        if (academicCareerIds && termIds && courseAttributeIds) {
            getAllSlabs();
            const updatedCols = [...cols];
            for (let i = 0; i < FORM_FIELDS.length; i++) {
                const currCol = updatedCols.find(col => col.field === FORM_FIELDS[i].field);
                currCol.valueOptions = FORM_FIELDS[i].state;
            }
        }
    }, [academicCareerIds, termIds, courseAttributeIds]);

    const getModifiedSlabs = (response) => {
        function createValues(arr, superArr) {
            const values = [];
            // if (arr.length === 0 || arr[0] === 0) return [{ label: "All", value: "*" }, ...superArr];
            arr.forEach(item => {
                const el = superArr.find(data => data.value === item);
                if (el) values.push(el);
            })
            return values;
        }

        return response.map(slab => {
            const { id, academicCareer, terms, courseCategories, fullFirstYear, yearOnYear, fullUpFront, status, updatedOn } = slab;
            return {
                id,
                codeID: id,
                career: [academicCareerIds.find(item => item.value === academicCareer)],
                courseCategory: createValues(courseCategories, courseAttributeIds),
                term: createValues(terms, termIds),
                fullFirstYear,
                yearOnYear,
                fullUpFront,
                lastUpdated: updatedOn,
                status,
                fromBE: true,
            }
        })
    }

    const getAllSlabs = async () => {
        setLoading(true);
        try {
            const response = await fetchAllPromisedData(`${endpoint.lumpSumConcession.getAll}`);
            if (response) {
                const _slabs = getModifiedSlabs(response);
                setSlabs(_slabs);
                setLoading(false);
            } else {
                failureToast("Failed to fetch Data");
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            failureToast("Failed to fetch Data");
        }
    }

    async function fetchAllData(url, name, stateMethod) {
        try {
            let response = await fetchAllPromisedData(url);
            if (response) {
                const list = response.map(item => ({
                    label: item[`${name}DispValue`],
                    value: item.id
                })
                )
                stateMethod(list);
            } else {
                failureToast("Could not fetch data!");
            }
        } catch (err) {
            failureToast("Could not fetch data!");
        }
    }

    function createSlabsPayload(row, isRowNew) {
        function getIds(arr) {
            let tempArr = [];
            const [first, ...rest] = arr;
            if(first?.value === "*") {
                tempArr = rest;
            } else {
                tempArr = arr;
            }

            return tempArr.map(item => item.value);
        }
        const request = {
            ...(!isRowNew && {id: row.id}),
            academicCareer: row.career[0].value,
            termId: getIds(row.term),
            courseCategoryId: getIds(row.courseCategory),
            fullFirstYear: row.fullFirstYear,
            yearOnYear: row.yearOnYear,
            fullUpFront: row.fullUpFront,
            status: row.status ?? "ACTIVE"
        }
        return request;
    }

    async function saveSlab(row) {
        setLoading(true);

        const isRowNew = row.fromBE !== true;
        const request = createSlabsPayload(row, isRowNew);

        const p = new Promise((resolve, reject) => {
            fetchAllPostPromisedData(`${isRowNew ? endpoint.lumpSumConcession.create : endpoint.lumpSumConcession.update+"/"+row.id}`, request, isRowNew ? 'post' : 'put')
                .then(response => {
                    setLoading(false);
                    if (response && response.code === 200) {
                        successToast(`Lump sum pay benefit configurations saved successfully!`);
                        // if (isRowNew) {
                            getAllSlabs();
                        // }
                        resolve(row);
                    } else {
                        failureToast(response.message)
                        reject(response.message);
                    }
                })
                .catch(err => { setLoading(false); reject(err); });
        });
        return p;
    }

    const deleteSlab = (row) => {
        setLoading(true);
        const p = new Promise((resolve, reject) => {
            fetchAllPostPromisedData(`${endpoint.lumpSumConcession.delete}/${row.id}`, {}, 'delete')
                .then(response => {
                    setLoading(false);
                    if (response && response.code === 200) {
                        successToast('Lump sum pay benefit configurations deleted successfully!');
                        resolve(row);
                    } else {
                        failureToast(response.message)
                        reject(response.message);
                    }
                })
                .catch(err => { setLoading(false); reject(err); });
        });
        return p;
    }

    return (
        <Box className='lumpsum-config-screen'>
            <Box className='lumpsum-header'>
                <BiArrowBack onClick={() => history.push('/admin/consession')} />
                <Typography variant='h6'>Lump sum pay benefit config.</Typography>
            </Box>
            <Box className='lumpsum-table' id='lumpsum-table'>
                <MuiTable
                    rows={slabs}
                    columns={cols}
                    headerComp={<div></div>}
                    userPermissions={userPermissions}
                    loading={loading}
                    asyncDeleteRow={deleteSlab}
                    asyncSaveRow={saveSlab}
                    useDeleteIconForCancel={true}
                    extraHeight={110}
                    disableVirtualization={true}
                    pageSize={100}
                    minHeight={156}
                />
            </Box>
        </Box>
    )
}

export default Lumpsum;