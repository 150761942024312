import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { ReactComponent as CloseIcon } from '../../../../../assets/img/svg/CrossIcon.svg'
import ALoader from '../a-loader';
import { endpoint } from '../constant';
import AAutoComplete from '../form-fields-mui/AAutoComplete';
import AButton from '../form-fields-mui/AButton';
import { fetchAllPostPromisedData, fetchAllPromisedData, getDropdownIdsFromSelectArray, updateRequest } from '../utils/methods/commonMethods/utilityMethod';
import RegionBusinessAreaCustomChip from '../../academicPlanAndTest/common/RegionBusinessAreaCustomChip'
import IconButtonWrapper from '../form-fields-mui/IconButtonWrapper';

export const chipContainerStyle = {
  maxHeight: "120px",
  overflowY: "auto",
  display: "flex",
  flexWrap: "wrap",
  gap: "2px",
  marginTop: "3px",
};

// this component is used for editing branches. 
// It accepts pre-selected regions array and pre-selected businessAreas array and returns updated regionBusinessAreaForm to update handler
// regions and businessAreas arrays passed to component need to be array of objects of type {label: 'abc', value: 123}
// parameter received by updated handler will be of type {region: [{label: '', value: ''}], businessArea: [{label: '', value: ''}]}
const EditBranchesCommonComponent = ({
  closeForm = () => { },
  title="Edit Branches",
  // regionBusinessAreaForm, 
  // setRegionBusinessAreaForm, 
  // inputHandler, 
  updateHandler = () => { },
  businessAreas = [],
  regions = [],
}) => {

  const [dropdownLoader, setDropdownLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [businessAreaRegionMap, setBusinessAreaRegionMap] = useState({});
  const [allBusinessAreas, setAllBusinessAreas] = useState([]);
  const [regionDropdown, setRegionDropdown] = useState([]);
  const [businessAreaDropdown, setBusinessAreaDropdown] = useState([]);

  const [regionBusinessAreaForm, setRegionBusinessAreaForm] = useState({
    // region: regions?.map(item => ({label: item?.dispValue, value: item?.id})),
    // businessArea: businessAreas?.map(item => ({label: item?.dispValue, value: item?.id})),
    region: regions,
    businessArea: businessAreas,
  })
  const inputHandler = (value, key) => {
    setRegionBusinessAreaForm(prev => ({ ...prev, [key]: value }))
  }

  // this function is taking new added Ids
  const addBA = (regionIdArray = []) => {
    // console.log('addBA', regionIdArray);
    const addedBA = [];
    const temp1 = regionIdArray?.forEach((regionId) => {
      const temp = allBusinessAreas?.map(item => {
        if (item?.['regionId']?.[0] === regionId) {
          addedBA.push({ label: item?.businessAreaDispValue, value: item?.id })
          return;
        }
      })
    })
    // console.log(addedBA)
    setRegionBusinessAreaForm(prev => ({ ...prev, businessArea: [...prev?.businessArea, ...addedBA] }))
  }
  const deleteBA = (regionIdArray = []) => {
    let updatedBA = [];
    const deletedBA = [];
    const temp1 = regionIdArray?.forEach((regionId) => {
      const temp = regionBusinessAreaForm?.businessArea?.map(item => {
        if (businessAreaRegionMap?.[item?.label] === regionId) {
          deletedBA.push(item);
        }
      })
    })
    updatedBA = regionBusinessAreaForm?.businessArea?.filter(item => !deletedBA?.includes(item))
    setRegionBusinessAreaForm(prev => ({ ...prev, businessArea: [...updatedBA] }))
  }
  const deleteHandler = (value, array) => {
    const res = [];
    const temp = array?.map(item => {
      if (item?.value !== value) {
        res.push(item);
      }
    })
    // console.log(temp);
    return res;
  }
  const filterDeletedIdArray = (currentArray, prevArray) => {
    // console.log('filter', currentArray, prevArray);
    const temp = prevArray.filter((item) => {
      // console.log('filter loop', !currentArray.includes(o => o?.value===item?.value));
      return !currentArray?.map(p => p?.value).includes(item?.value);
    })
    // console.log('filter', temp);
    return temp;
  }

  const getDropdowns = async () => {
    setDropdownLoader(true);
    const businessAreas = await fetchAllPromisedData(endpoint?.businessArea?.getAllActive);
    const regions = await fetchAllPromisedData(endpoint?.region?.getAllActive);

    const tempMap = {};
    const tempMapping = businessAreas?.map(item => {
      tempMap[item?.businessAreaDispValue] = item?.regionId?.[0];
    })
    setBusinessAreaRegionMap(tempMap);

    setAllBusinessAreas(businessAreas);
    const regionTemp = regions?.map(item => { return { label: item?.['regionDispValue'], value: item?.['id'] } });
    setRegionDropdown(regionTemp);
    // if (setAllDefault) inputHandler(regionTemp, 'region');
    // console.log(businessAreas, regions)
    setDropdownLoader(false);
  }

  useEffect(() => {
    getDropdowns();
  }, [])

  const getBusinessAreaByRegions = async () => {
    setLoader(true);
    const res = await fetchAllPostPromisedData(endpoint?.businessArea?.getAllActiveBusinessAreaByRegions, updateRequest({ regionId: regionBusinessAreaForm?.region }), 'post')
    // console.log(res);
    if (res?.code === 200) {
      const tempBA = res?.data?.map(item => { return { label: item?.['businessAreaDispValue'], value: item?.['id'], regionId: item?.['regionId']?.[0] } });
      setBusinessAreaDropdown(tempBA);
    }
    else {
      setBusinessAreaDropdown([]);
    }
    setLoader(false);
  }

  useEffect(() => {
    getBusinessAreaByRegions();
  }, [regionBusinessAreaForm?.region])

  return (
    <Card className='mb-0' style={{ position: 'relative', width: '370px' }}>
      <CardBody className='p-3'>
        {(loader || dropdownLoader) && <ALoader />}
        <div className='d-flex justify-content-between align-items-center'>
          <div className='heading-4' style={{ color: '#414141' }}>{title}</div>
          <IconButtonWrapper onClick={closeForm}><CloseIcon /></IconButtonWrapper>
        </div>
        <div>
          <Row>
            <Col md="12">
              <AAutoComplete
                label="Region"
                currentValue={regionBusinessAreaForm?.region}
                items={regionDropdown}
                handleChange={(value) => {
                  // console.log(value?.[value?.length - 1]);
                  if (value?.length > regionBusinessAreaForm?.region?.length) {
                    // addBA(value?.[value?.length - 1]?.value);
                    addBA(getDropdownIdsFromSelectArray(filterDeletedIdArray(regionBusinessAreaForm?.region, value)));
                  }
                  else if (value?.length < regionBusinessAreaForm?.region?.length) {
                    // deleteBA(filterDeletedId(value, regionBusinessAreaForm?.region));
                    deleteBA(getDropdownIdsFromSelectArray(filterDeletedIdArray(value, regionBusinessAreaForm?.region)));
                  }
                  inputHandler(value, 'region')
                }}
                selectAllMenuButton={true}
              />
              <div style={chipContainerStyle}>
                {(regionBusinessAreaForm?.region)?.map(
                  (item) => (
                    <RegionBusinessAreaCustomChip
                      key={item?.value}
                      text={item?.label}
                      variant="outlined"
                      onDelete={() => {
                        inputHandler(
                          deleteHandler(item.value, regionBusinessAreaForm?.region),
                          "region"
                        )
                        deleteBA([item?.value])
                      }}
                    />
                  )
                )}
              </div>
            </Col>
            <Col md={12}>
              <AAutoComplete
                label="Business area"
                currentValue={regionBusinessAreaForm?.businessArea}
                items={businessAreaDropdown}
                handleChange={(value) => inputHandler(value, "businessArea")}
                selectAllMenuButton={true}
              />
              <div style={chipContainerStyle}>
                {(regionBusinessAreaForm?.businessArea)?.map((item) => (
                  <RegionBusinessAreaCustomChip
                    key={item.value}
                    text={item.label}
                    variant="outlined"
                    onDelete={() =>
                      inputHandler(
                        deleteHandler(item.value, regionBusinessAreaForm?.businessArea),
                        "businessArea"
                      )
                    }
                  />
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mt-2">
              <AButton
                className='button_remove_left_margin'
                variant='primary_filled'
                updatedStyle={{
                  width: "100%",
                  height: '30px',
                  borderRadius: '4px',
                }}
                disabled={!regionBusinessAreaForm?.region?.length || !regionBusinessAreaForm?.businessArea?.length}
                onClick={() => { updateHandler(regionBusinessAreaForm); closeForm(); }}
              >
                Submit
              </AButton>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  )
}

export default EditBranchesCommonComponent