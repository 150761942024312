import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { failureToast, successToast } from '../../../../../pages/manage/common/utils/methods/toasterFunctions/function';
import { Col, Row } from 'reactstrap';
import { Tooltip, Typography } from '@mui/material';
import CustomButton from '../../../../../../components/CustomButton';
import CommonInput from '../../../common/formFeilds/input/commonInput';
import { BsInfoCircle, BsThreeDotsVertical } from 'react-icons/bs';
import validationRules from './validationRules.json'
import { customValidation } from '../../../common/utils/methods/validations/validation';
import { fetchAllPostPromisedData, fetchAllPromisedData, getRequiredSign } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../../common/constant';
import CustomLoader from 'views/pages/manage/common/commonComponents/Loader/loader';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Prompt } from 'react-router-dom';
import { ConfirmationDialog } from 'views/pages/manage/common/commonComponents'
import { deleteAPI } from 'services/http';
import './index.scss'
import moment from 'moment';

const blankData = {
    categoryCode: '',
    categoryId: '',
    categoryTitle: '',
    categoryDescription: '',
    status: 'ACTIVE',
    effectiveDate: '',
    expiryDate: '',
};

const ITEM_HEIGHT = 48;


const Category = (props) => {
    const history = useHistory();

    const { mode, categoryId, classificationData, getClassifaicationData = () => { }, userPermissions, categoryCodeData } = props;

    const [isReadOnly, setReadOnly] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [values, setValues] = useState(blankData);

    const [saving, setSaving] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const saveHandler = async () => {
        let isValid = true;
        isValid = customValidation(values, validationRules);
        if (!isValid) { return }

        setSaving(true)
        const request = {
            categoryCode: values.categoryCode.value,
            categoryExternalId: values.categoryId,
            categoryDispVal: values.categoryTitle,
            categoryDesc: values.categoryDescription,
            status: values.status,
            effectiveFrom: values.effectiveDate ?? null,
            effectiveTo: values.expiryDate ?? null
        }

        try {
            const response = await fetchAllPostPromisedData(
                `${endpoint.category}${mode === 'new' ? '' : `/${categoryId}`}`,
                { ...request },
                mode === 'new' ? "post" : "put"
            );
            setSaving(false);
            if (response && response.code === 200) {
                setIsFormDirty(false);
                if (mode === 'new') {
                    history.push(`/admin/consession/cs/view/${response.data.id}`)
                }

                if (mode === 'edit') {
                    history.push(`/admin/consession/cs/view/${categoryId}`)
                }
                getClassifaicationData()
                successToast("Category saved successfully!");
            } else {
                failureToast("Details not saved. Please retry again");
            }
        } catch (error) {
            failureToast("Details not saved. Please retry again");
        }
    }


    const getCategoryDetails = async () => {
        try {
            const response = await fetchAllPromisedData(
                `${endpoint.category}/${categoryId}`,
                true
            );
            if (response && response.code === 200) {
                const recordedData = {
                    id: response.data.id,
                    categoryCode: { label: response.data?.categoryCode, value: response.data?.categoryCode },
                    categoryId: response.data.categoryExternalId,
                    categoryTitle: response.data.categoryDispVal,
                    categoryDescription: response.data.categoryDesc,
                    status: response.data.status,
                    effectiveDate: response.data.effectiveFrom,
                    expiryDate: response.data.effectiveTo,
                    updatedBy : response.data. updatedBy,
                    updatedOn : response.data.updatedOn
                }
                setValues({ ...recordedData })
                setFetching(false)
            } else {
                failureToast("Failed to fetch Data");
            }
        } catch (error) {
            failureToast("Details not saved. Please retry again");
        }
    }

    useEffect(() => {

        setIsFormDirty(false);
        if ((mode === 'edit' || mode === 'view') && categoryId) {
            getCategoryDetails();
            if (mode === 'view') {
                setReadOnly(true);
            }
        }

        if (mode === 'new') {
            setFetching(false);
            setReadOnly(false);
            setValues(blankData);
        }

    }, [mode, categoryId])

    const onChange = (key, val) => {
        setValues({ ...values, [key]: val });
        setIsFormDirty(true);
    }

    const deleteCategory = () => {
        try {
            deleteAPI(
                `${endpoint.category}/${categoryId}`,
                (data) => {
                    history.push(`/admin/consession`);
                    setOpenDeleteDialog(false);
                    successToast(data["message"]);
                },
                (data) => {
                    failureToast(data["message"]);
                },
                "Delete"
            );
        } catch (e) {
            console.log("Error", e);
        }
    };

    return (
        fetching ? (
            <div className="col">
                <div
                    className="mx-auto text-center py-5 my-5 "
                    style={{ height: '100%' }}
                >
                    <CustomLoader apiLoader={fetching} />
                </div>

            </div>
        ) :
            <div className='category-module'>
                <Prompt when={(mode === 'edit' || mode === "new") && isFormDirty} message={"All information will be lost. Are you sure you want to leave this page?"} />
                <div className='d-flex justify-content-between'>
                    <Typography style={{ fontSize: 18, color: 'black', marginBottom: 25 }}>{`${mode === 'new' ? 'Add new category' : values.categoryTitle}`}</Typography>
                    {mode === 'view' && <div>
                        <Tooltip title="Menu" placement="top-start">
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? 'long-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                            >
                                <BsThreeDotsVertical />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '7ch',
                                },
                            }}
                        >
                            <MenuItem key={'Edit'} onClick={() => { setReadOnly(false); handleClose(); history.push(`/admin/consession/cs/edit/${categoryId}`) }}>
                                Edit
                            </MenuItem>
                            {/* <MenuItem key={'Delete'} className="text-danger" onClick={() => { handleClose(); setOpenDeleteDialog(true); }}>
                                Delete
                            </MenuItem> */}
                        </Menu>
                    </div>}
                </div>
                <Row>
                    <Col md="4">
                        <CommonInput
                            type="select"
                            mandatory={true}
                            label="Category code"
                            defaultValue={values.categoryCode}
                            onChange={(val) => { onChange("categoryCode", val) }}
                            data={categoryCodeData}
                            readOnly={isReadOnly}
                        />
                    </Col>
                    <Col md="4">
                        <CommonInput
                            type="number"
                            mandatory={true}
                            defaultValue={values.categoryId}
                            onChange={(val) => { onChange("categoryId", val) }}
                            label="ID"
                            readOnly={isReadOnly}
                        />
                    </Col>
                    <Col md="4" className={`d-flex align-item-center ${mode !== 'view' ? 'cat-toggle' : 'cat-toggle-ml0'}`}>
                        <CommonInput
                            type="toggleV3"
                            dataLabelOff='INACTIVE'
                            dataLabelOn='ACTIVE'
                            defaultValue={values.status}
                            placeHolder="Enter status"
                            onChange={(val) => { onChange("status", val) }}
                            label="Status"
                            readOnly={isReadOnly}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <CommonInput
                            type="text"
                            mandatory={true}
                            defaultValue={values.categoryTitle}
                            onChange={(val) => { onChange("categoryTitle", val) }}
                            placeHolder="Category title"
                            label="Category title"
                            readOnly={isReadOnly}
                        />
                    </Col>
                    <Col md="6">
                        <CommonInput
                            type="text"
                            mandatory={true}
                            defaultValue={values.categoryDescription}
                            placeHolder="Category description"
                            onChange={(val) => { onChange("categoryDescription", val) }}
                            label="Description"
                            readOnly={isReadOnly}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <CommonInput
                            type="datePicker"
                            label="Effective date"
                            defaultValue={values.effectiveDate}
                            onChange={(val) => { onChange("effectiveDate", val) }}
                            readOnly={isReadOnly}
                            placeHolder=" "
                            minDate={new Date().setHours(0,0,0,0)}
                        />
                    </Col>
                    <Col md="4">
                        <CommonInput
                            type="datePicker"
                            defaultValue={values.expiryDate}
                            onChange={(val) => { onChange("expiryDate", val) }}
                            label="Expiry date"
                            readOnly={isReadOnly}
                            placeHolder=" "
                            minDate={new Date().setHours(0,0,0,0)}
                        />
                    </Col>

                </Row>
                {(mode === 'new' || mode === 'edit') && <Row className="d-flex align-items-center justify-content-between">
                    <div><CustomButton
                        className="px-5"
                        content={saving ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : "Save"}
                        permissionType={'C,U'}
                        permissionSet={userPermissions}
                        onClick={saveHandler}
                    />
                        <span className='ml-3'>
                            <BsInfoCircle className='mr-1' />
                            <span>{getRequiredSign()} Fields are mandatory</span>
                        </span>
                    </div>
                    {mode === 'edit' && <CustomButton
                        className="px-5"
                        content={'Cancel'}
                        permissionType={'U'}
                        permissionSet={userPermissions}
                        onClick={() => { history.push(`/admin/consession/cs/view/${categoryId}`) }}
                    />}
                </Row>}

                {mode === 'view' && <Row className="d-flex align-items-center">
                    {/* <Button onClick={() => saveHandler()} variant="contained">Save</Button> */}
                    <CustomButton
                        className="px-5"
                        content={"Add Sub Category"}
                        permissionType={'C,U'}
                        permissionSet={userPermissions}
                        onClick={() => { history.push(`/admin/consession/scs/new/${categoryId}`) }}
                    />
                </Row>}
                {mode !== 'new' && <h5 className='float-right mt-2'>Last updated by {values?.updatedBy} at {moment(values?.updatedOn).format("hh:mm a")} on {moment(values?.updatedOn).format('DD/MM/YYYY')}.</h5>}
                <ConfirmationDialog
                    isOpen={openDeleteDialog}
                    onAgree={() => { deleteCategory() }}
                    setIsOpen={setOpenDeleteDialog}
                    headerMsg={`Are you sure you want to delete ${values?.categoryTitle}?`}
                    msg={'All sub and sub sub categories attached to this thread will be deleted automatically.'}
                />
            </div>
    )
}

export default Category;