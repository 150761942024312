const examService = process.env.REACT_APP_EXAM_SERVICE;

export const examServiceEndpoints = {
  externalTestResult: {
    search: `${examService}/externalTestResult/searchExternalTestResult`,
    create: `${examService}/externalTestResult/createExternalTestResultDetails`,
    update: `${examService}/externalTestResult/updateExternalTestResultDetails`,
    getExamBoard: `${examService}/externalTestResult/getAllExamBoard`,
    getExamYear: `${examService}/externalTestResult/getAllExamYear`,
    getAll: `${examService}/externalTestResult/getAllExternalTestResult`,
    bulkUpload: `${examService}/externalTestResult/createBulkExternalTestResultDetails`,
  },
};
