import React, { useState } from 'react';
import ADataGrid from '../../common/data-grid/ADataGrid';
import {
  gridComponents,
  gridStyles,
} from '../issuanceIDcard/printIdCardAndMapRfid/constant';
import NoRowOverlay from '../../masters/holidayMaster/NoRowOverlay';
import { AGridColDef } from '../../common/data-grid/ADataGridColDef';
import { useHistory } from 'react-router';
import AButton from '../../common/form-fields-mui/AButton';
import CustomTooltip from 'views/pages/manage/common/customTooltip/CustomTooltip.jsx';
import { checkPermission } from '../../common/utils/methods/commonMethods/utilityMethod';
import BulkSelectionFooter from './BulkSelectionFooter';
import { isEligibleForSupplementaryId } from '../studentSerach/constant';

const ListComponent = ({ rows, page, onPageChange, totalRecords, handleApproval, userPermissions }) => {
  const history = useHistory();
  const [selectionModel, setSelectionModel] = useState([]);
  
  const isRowSelectable = (params) => isEligibleForSupplementaryId(params?.row);
  const handlePsidClick = psid => {
    window.open(
      history.createHref({
        pathname: `/admin/edp/studentDetails/${psid}`,
      }),
      '_blank'
    );
  };


  const columns = [
    new AGridColDef('psid', 'PSID')
      .setMinWidth(100)
      .renderCellComponent(params => (
        <div className='pointer' onClick={() => handlePsidClick(params?.value)}>
          {params?.value}
        </div>
      )),
    new AGridColDef('applicationId', 'Application no.').setMinWidth(100),
    new AGridColDef('studentName', 'Student name')
      .setMinWidth(120)
      .setFlex(1.2)
      .renderCellComponent(params => (
        <CustomTooltip title={params?.value}>
          <div className='ellipsis-text-container'>{params?.value}</div>
        </CustomTooltip>
      )),
    new AGridColDef('courseId', 'Course').setMinWidth(120).setFlex(0.6),
    new AGridColDef('businessAreaValue', 'Business area').setMinWidth(100),
    new AGridColDef('classes', 'Class')
      .setFlex(0.4)
      .setMinWidth(90)
      .setValueGetter(params => params?.value || '-'),
    new AGridColDef('programAction', 'Program action')
      .setFlex(0.6)
      .setMinWidth(150)
      .setValueGetter(params => params?.value || '-'),
    new AGridColDef('action', 'Action', false)
      .setFlex(0.6)
      .setMinWidth(135)
      .columnAlign('right')
      .renderCellComponent(params => (
        <div>
          <AButton
            className='ml-0'
            variant='link'
            size='xs'
            updatedStyle={{ fontSize: '14px' }}
            disabled={
              !isEligibleForSupplementaryId(params?.row) ||
              selectionModel?.length > 0 ||
              !checkPermission(userPermissions, 'A')
            }
            onClick={() => handleApproval(params?.id, setSelectionModel)}
          >
            Approve
          </AButton>
          <AButton
            className='ml-3'
            variant='link'
            size='xs'
            updatedStyle={{ fontSize: '14px', color: '#B32306' }}
            disabled={selectionModel?.length > 0}
            onClick={() =>
              handleApproval(params?.id, setSelectionModel, 'DENIED')
            }
          >
            Deny
          </AButton>
        </div>
      )),
  ];
  return (
    <>
      <div className='mt-2 flex-grow-1 d-flex flex-column'>
        <ADataGrid
          removeWrapperContainer
          rows={rows || []}
          columns={columns}
          rowId={row => JSON.stringify(row)}
          rowHeight={50}
          loading={false}
          rowCount={totalRecords}
          nextButtonHandler={onPageChange}
          currentPage={page}
          components={{
            ...gridComponents,
            NoRowsOverlay: () => <NoRowOverlay />,
          }}
          sx={{
            ...gridStyles,
          }}
          hideFooterSelectedRowCount={true}
          checkboxSelection
          disableSelectionOnClick
          selectionModel={selectionModel}
          onSelectionModelChange={newSelectionModel =>
            setSelectionModel(newSelectionModel)
          }
          isRowSelectable={isRowSelectable}
        />
      </div>
      <BulkSelectionFooter selectionModel={selectionModel} setSelectionModel={setSelectionModel} handleApproval={handleApproval} />
    </>
  );
};

export default ListComponent;
