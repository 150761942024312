import React from 'react';
import {
	Card,
	CardHeader,
	Input,
	Container,
	Button,
	Table,
	Row,
} from 'reactstrap';
import { useState } from 'react';
import { TDROW, MasterHeader } from '../../common/commonComponents/masterRow/masterRow';
import {
	masterServiceBaseUrl,
	putpost,
	putpost2,
	deleteAPI,
} from 'services/http';
import moment from 'moment';
import { failureToast, successToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import CustomToggle from "views/pages/manage/common/formFeilds/customToggle";
import CustomButton from 'components/CustomButton';

var _ = require('lodash');

const CourseAttributeValueTable = (props) => {
	const { userPermissions, isSaveVisible, setisSaveVisible = () => { } } = props;
	const [tableDataKey, settableDataKey] = useState('tableDataKey');
	const addNewClass = () => {
		props.setclassArray([
			{
				editMode: true,
				__status: '__new',
				courseAttributeValueKey: null,
				status: 'ACTIVE',
				courseAttributeValueDesc: null,
				courseAttributeValueDispValue: null,
				effectiveDate: null,
			},
			...props.classArray,
		]);
	};
	const saveAll = (cb) => {
		var _tempArray = props.classArray;
		var isValid = true;
		_tempArray.forEach((el, index) => {
			if (el['editMode']) {
				if (
					el['courseAttributeValueKey'] === null ||
					!el['courseAttributeValueKey'].trim()
				) {
					isValid = false;
					failureToast('Please enter the value of Course Attribute Value Key');
					return;
				} else if (
					el['courseAttributeValueDesc'] === null ||
					!el['courseAttributeValueDesc'].trim()
				) {
					isValid = false;
					failureToast('Please enter the value of Description');
					return;
				} else if (
					el['courseAttributeValueDispValue'] === null ||
					!el['courseAttributeValueDispValue'].trim()
				) {
					isValid = false;
					failureToast('Please enter the value of Display Value');
					return;
				}
				if (!isValid) {
					return;
				}
				if (_tempArray[index]['__status'] == '__new') {
					putpost(
						masterServiceBaseUrl + '/courseAttributeValue/createCourseAttributeValue',
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							props.fetchClasses(
								masterServiceBaseUrl + '/courseAttributeValue/getAllCourseAttributeValue'
							);
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'post'
					);
				} else {
					putpost(
						masterServiceBaseUrl +
						'/courseAttributeValue/updateCourseAttributeValue/' +
						_tempArray[index].id,
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							delete _tempArray[index]['editMode'];
							props.setclassArray(_tempArray);
							settableDataKey(new Date().getMilliseconds());
							// props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'put'
					);
				}
			} else {
			}
		});
	};
	const newRow = () => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				addNewClass();
				setisSaveVisible(true);
				// settableDataKey(new Date().getMilliseconds())
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			props.setclassArray(_tempArray);
			setisSaveVisible(false);
			cb();
		}
	};
	const editRow = (index) => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				var _tempArray = props.classArray;
				_tempArray[index]['editMode'] = true;
				props.setclassArray(_tempArray);
				setisSaveVisible(true);
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			cb();
		}
	};
	const deleteRow = (index) => {
		if (props.classArray[index]['__status'] == '__new') {
			let n = props.classArray;
			n.splice(index, 1);
			props.setclassArray(n);
			setisSaveVisible(false);
			settableDataKey(new Date().getMilliseconds());
		} else {
			if (
				window.confirm(
					'Are you sure you want to delete this Course Attribute Value?'
				)
			) {
				putpost2(
					masterServiceBaseUrl +
					'/courseAttributeValue/deleteCourseAttributeValue/' +
					props.classArray[index].id,
					(data) => {
						successToast(data['message']);
						var _tempArray = props.classArray;
						if (_tempArray[index]['editMode']) {
							setisSaveVisible(false);
						}
						_tempArray.splice(index, 1);
						props.setclassArray(_tempArray);
						settableDataKey(new Date().getMilliseconds());
					},
					(data) => {
						failureToast(data['message']);
					},
					'Delete'
				);
			}
		}
	};
	const updateKey = (index, value, key) => {
		var _tempArray = props.classArray;
		_tempArray[index][key] = value;
		props.setclassArray(_tempArray);
	};
	return (
		<>
			<Container className="mt-3" fluid>
				<Row>
					<div className="col">
						<Card>
							<CardHeader className="border-0">
								<h3 className="mb-0 floatLeft">
									Master - Course Attribute Value
								</h3>
								<CustomButton
									disabled={isSaveVisible}
									className={'floatRight mx-1' + (isSaveVisible ? ' btn-dark cursor-gray' : null)}
									content={'New Course Attribute Value'}
									permissionType={'C'}
									icon={true}
									onClick={() => { newRow(); }}
									permissionSet={userPermissions}
								/>
								{/* {isSaveVisible?<Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => {saveAll()}}>Save</Button>:null} */}
							</CardHeader>
							<div id="questionTable" className="table-responsive">
								<Table className="align-items-center table-flush tableLayout">
									<MasterHeader type={'Course Attribute Value'} permissionSet={userPermissions} isSaveVisible={isSaveVisible} />
									<tbody className="list" key={tableDataKey}>
										{props.classArray
											? props.classArray.map((el, index) =>
												!el['editMode'] ? (
													<TDROW
														type={'courseAttributeValue'}
														index={index}
														el={el}
														editRow={editRow}
														deleteRow={deleteRow}
														permissionSet={userPermissions} isSaveVisible={isSaveVisible}
													/>
												) : (
													<tr key={index + '-class'}>
														<td className="text-center p-2 mx-1">
															<Input
																data-testid="courseAttributeValueKey"
																maxLength="10"
																defaultValue={el['courseAttributeValueKey']}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'courseAttributeValueKey'
																	);
																}}
																placeholder="Example:1"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																data-testid="effectiveDate"
																maxLength="50"
																disabled={true}
																defaultValue={
																	el['__status'] == '__new'
																		? null
																		: moment(el['eff_date']).format(
																			'DD-MM-YYYY'
																		)
																}
																placeholder="Example:01-01-2020"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																data-testid="courseAttributeValueDesc"
																maxLength="50"
																defaultValue={el['courseAttributeValueDesc']}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'courseAttributeValueDesc'
																	);
																}}
																placeholder="Description"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																data-testid="courseAttributeValueDispValue"
																maxLength="50"
																defaultValue={
																	el['courseAttributeValueDispValue']
																}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'courseAttributeValueDispValue'
																	);
																}}
																placeholder="Display Value"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">

															<CustomToggle
																status={el.status}
																data-testid="activeInactiveInput"
																id={el.id}
																toggleOn={"Active"}
																toggleOff={"Inactive"}
																onChangeHandler={(e) => {
																	settableDataKey(
																		new Date().getMilliseconds()
																	);
																	let n = 'ACTIVE';
																	if (!e.target.checked) {
																		n = 'INACTIVE';
																	}
																	updateKey(index, n, 'status');
																}}
															/>
														</td>
														<td className="text-center">
															{/* <Button color="info" size="sm" type="button" onClick={() => {
                          deleteRow(index)
                        }}>  <i className="fas fa-trash" /></Button> */}
															{isSaveVisible ? (
																<>
																	<CustomButton
																		content={'Save'}
																		permissionType={'C,U'}
																		forTable={true}
																		onClick={() => {
																			saveAll();
																		}}
																		permissionSet={userPermissions}
																	/>
																	<CustomButton
																		permissionType={'cancel'}
																		icon={true}
																		forTable={true}
																		onClick={() => {
																			let n = props.classArray;
																			if (n[index]['__status'] == '__new') {
																				n.splice(index, 1);
																			} else {
																				// n[index]['editMode']=false
																				props.fetchClasses(
																					masterServiceBaseUrl +
																					'/courseAttributeValue/getAllCourseAttributeValue'
																				);
																			}
																			props.setclassArray(n);
																			setisSaveVisible(false);
																		}}
																		permissionSet={userPermissions}
																	/>
																</>
															) : (
																<CustomButton
																	permissionType={'D'}
																	icon={true}
																	forTable={true}
																	onClick={() => deleteRow(index)}
																	permissionSet={userPermissions}
																/>
															)}
														</td>
													</tr>
												)
											)
											: null}
									</tbody>
								</Table>
							</div>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};
export default CourseAttributeValueTable;
