import React from "react";
import EmployeeDetailsCards from "./EmployeeDetailsCards";
import { ReactComponent as Document } from "assets/img/svg/DocumentLight.svg";

import { Header } from "./ConsumptionReturnPage";
import {
  ConsumptionHistoryHeader,
  ConsumptionHistoryRow,
} from "../stockConsumption/form/tableComponents";
import { Card, CardBody } from "reactstrap";
import { mapMaterials } from "./SelectMaterialCards";
import AButton from "../../../common/form-fields-mui/AButton";
import { getDownloadFileUrl } from "../stockConsumption/form/helper";

const tableBorder = {
  border: "1px solid #CBCBCB",
  padding: "10px",
  borderRadius: "8px",
  margin: "15px 0",
};

const BranchConsumptionHistory = ({
  data,
  transactionDetails,
  isReturnedBarcode,
  failedReason,
}) => {
  const materials = mapMaterials(data?.materialDetails) || [];

  const RenderTable = ({ tableRows = [] }) => {
    if (tableRows.length === 0)
      return (
        <div className="tableBorder">
          <ConsumptionHistoryHeader isReturnedBarcode={isReturnedBarcode} />
        </div>
      );
    return (
      <div style={tableBorder}>
        <ConsumptionHistoryHeader isReturnedBarcode={isReturnedBarcode} />
        {tableRows.map((item) => (
          <ConsumptionHistoryRow
            key={JSON.stringify(item)}
            isReturnedBarcode={isReturnedBarcode}
            row={item}
          />
        ))}
      </div>
    );
  };

  return (
    <div>
      <Header
        isReturnedBarcode={isReturnedBarcode}
        transactionDetails={data}
        showLeftBar={true}
        failedReason = {failedReason}
      />
      <EmployeeDetailsCards label="Basic details" data={data} />
      <Card className="m-3">
        <CardBody>
          <RenderTable tableRows={materials} />
          {!isReturnedBarcode && (
            <div>
              <div className="heading-3 color-dark mb-3">Attachment</div>
              <div className="d-flex barcode-consumption-history">
                <Document />
                <span className="heading-3 color-dark ml-2">
                  Supporting Document
                </span>
                <AButton
                  onClick={() => getDownloadFileUrl(data?.attachmentKey)}
                  variant="outline"
                  size="xs"
                  disabled={
                    !data?.attachmentKey ||
                    data?.attachmentKey?.toLowerCase() === "absent"
                  }
                >
                  View
                </AButton>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default BranchConsumptionHistory;
