import React, { useEffect, useState } from 'react'
import styles from '../styles.module.scss'
import { ReactComponent as BackIcon } from "assets/img/svg/BackButton.svg";
import { useHistory } from 'react-router';
import AButton from 'views/pages/manage/common/form-fields-mui/AButton.jsx'
import StudentDetails from './StudentDetails';
import DOInformation from './DOInformation';
import StudentFeeSummary from './StudentFeeSummary';
import { fetchAllPostPromisedData, fetchAllPromisedData, getUrlSearchParams } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../../common/constant';
import { failureToast, successToast } from '../../../common/utils/methods/toasterFunctions/function';
import { getCourseDetailsV2 } from '../../../edpOps/stockManagement/service';
import ALoader from '../../../common/a-loader';
import { fetchData, getCreateReqBody, getSchemesDropdown, isInvalidReqBody } from './constant';

const DODetails = () => {
  const history = useHistory();
  const [mode, setMode] = useState('view'); // view, edit
  const { applicationId, applicationQuotationDetailsId, readOnly, isOldQuotation } = getUrlSearchParams(history?.location?.search);
  const [apiLoader, setApiLoader] = useState(false);
  const [applicationDetails, setApplicationDetails] = useState({});
  const [applicationDetailsLoader, setApplicationDetailsLoader] = useState(false);
  const [dataLoader, setDataLoader] = useState(false);
  const [allLoanSchemes, setAllLoanSchemes] = useState([]);
  const [schemeLoading, setSchemeLoading] = useState([]);
  const [doInformation, setDoInformation] = useState({}); // get this from do details api
  const doInfoHandler = (value, key) => setDoInformation(prev => ({ ...prev, [key]: value }));

  const getAllData = async (applicationId, applicationQuotationDetailsId, schemes = allLoanSchemes, setDataLoader) => {
    const data = await fetchData(applicationId, applicationQuotationDetailsId, schemes, setDataLoader, isOldQuotation);
    if(data?.isNew) {
      setMode('edit');
    } else {
      setMode('view');
    }
    setDoInformation(data);
  }
  const getApplicationDetails = async () => {
    setApplicationDetailsLoader(true);

    try {
      const res = await fetchAllPromisedData(`${endpoint.getStudentChargesApplicationDetails}/${applicationId}/STUDENT_CHARGES`, true); //STUDENT_CHARGES
      if (res?.code === 200) {
        const courseRes = await getCourseDetailsV2(res?.data?.courseId);
        setApplicationDetails({ ...res?.data, courseDetails: courseRes, applicationQuotationDetailsId: applicationQuotationDetailsId });
      } else {
        setApplicationDetails({});
        failureToast("Couldn't fetch application details.");
      }
      setApplicationDetailsLoader(false);
    } catch (err) {
      failureToast("Couldn't fetch application details.");
      setApplicationDetailsLoader(false);
    }
  };
  
  const getAllLoanSchemes = async () => {
    setSchemeLoading(true);
    try {
      const resp = await fetchAllPromisedData(`${endpoint.loanScheme}/all`);
      setSchemeLoading(false);

      if (resp) {
        const schemes = getSchemesDropdown(resp?.filter(el => el.status === 'ACTIVE'));
        setAllLoanSchemes(schemes);
        // const data = await fetchData(applicationId, applicationQuotationDetailsId, schemes, setDataLoader);
        // setDoInformation(data);
        await getAllData(applicationId, applicationQuotationDetailsId, schemes, setDataLoader);
      } else {
        failureToast("Couldn't fetch loan schemes.");
      }
    } catch (err) {
      setSchemeLoading(false);
      failureToast("Couldn't fetch loan schemes.");
    }
  }
  
  const saveHandler = async () => {
    setApiLoader(true);
    const reqType = doInformation?.isNew ? 'post' : 'put';
    
    if (isInvalidReqBody(doInformation)) { 
      setApiLoader(false);
      return;
    }
    const reqBody = getCreateReqBody(doInformation, applicationQuotationDetailsId);

    const res = await fetchAllPostPromisedData(endpoint.deliveryOrder.deliveryOrderDetails, reqBody, reqType);

    setApiLoader(false);
    if (res?.code === 200) {
      successToast(res?.message);
      getAllData(applicationId, applicationQuotationDetailsId, allLoanSchemes, setDataLoader);
    }
  }
  
  useEffect(() => {
    if (!applicationId || !applicationQuotationDetailsId) {
      history.replace('/admin/pageNotFound')
    } else {
      getApplicationDetails();
      getAllLoanSchemes();
    }
  }, [applicationId, applicationQuotationDetailsId])

  return (
    <div className={`${styles['page-container']} remove-container-top-space`}>
      {(apiLoader) && <ALoader position='fixed' />}
      <div className='d-flex align-items-center' style={{ gap: '12px' }}>
        <BackIcon onClick={() => history.goBack()} />
        <div className='flex-grow-1 heading-4 color-dark'>{applicationDetails?.studentName}</div>
        <AButton
          className='flex-shrink-0 btn-left-0'
          variant='outline'
          onClick={() => history.push(`/admin/studentCharges/details/${applicationId}/view`)}
        >
          View charges details
        </AButton>
      </div>

      <StudentDetails
        applicationDetails={applicationDetails}
        loader={applicationDetailsLoader || schemeLoading || dataLoader}
        doInformation={doInformation}
      />

      <DOInformation
        mode={mode}
        setMode={setMode}
        allLoanSchemes={allLoanSchemes}
        loader={schemeLoading || dataLoader}
        doInformation={doInformation}
        doInfoHandler={doInfoHandler}
        saveHandler={saveHandler}
        readOnly={readOnly || applicationDetails?.chargeStatus === 'APPL'}
      />

      <StudentFeeSummary 
        applicationQuotationDetailsId={applicationQuotationDetailsId}
        mode={mode}
        doInformation={doInformation}
      />
    </div>
  )
}

export default DODetails