import {masterServiceBaseUrl} from "../../../services/http";

const URLS = {
    "process_tag": {
        "create": `${masterServiceBaseUrl}/processTag/createProcessTag`,
        "delete": `${masterServiceBaseUrl}/processTag/deleteProcessTag`,
        "getAllActive": `${masterServiceBaseUrl}/processTag/getAllActiveProcessTag`,
        "getAll": `${masterServiceBaseUrl}/processTag/getAllProcessTag`,
        "getById": `${masterServiceBaseUrl}/processTag/getProcessTag`,
        "update": `${masterServiceBaseUrl}/processTag/updateProcessTag`,
        "search": `${masterServiceBaseUrl}/processTag/searchProcess`,
        "getTemplatesProcessTypes": `${masterServiceBaseUrl}/processTag/getAllProcessTagDownloadTemplate`,
    },
    "academic_institution" : {
        "getAll" : `${masterServiceBaseUrl}/academicInstitution/getAllAcademicInstitution`,
        "getAllActive" : `${masterServiceBaseUrl}/academicInstitution/getAllActiveAcademicInstitution`
    }
}

export {
    URLS
}