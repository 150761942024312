import axios from "axios";
import { getCookie } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";


//axios.defaults.headers.common["x-request"] = "1234567890";
axios.defaults.headers.common["Authorization"] = getCookie('token')



export default axios;
