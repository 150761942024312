import React, { useContext, useEffect, useRef, useState } from 'react'
import { ReactComponent as BackIcon } from '../../../../../../assets/img/svg/BackButton.svg'
import styles from './styles.module.scss'
import AButton from '../../../common/form-fields-mui/AButton'
import ListComponent from './ListComponent'
import ALoader from '../../../common/a-loader/index'
import { createConfig, deleteConfig, getAllConfig, getDropdowns, getMultiSelectDefault, getSingleSelectDefault, updateConfig } from './common'
import { useHistory } from 'react-router'
import { checkPermission, isObjEmpty } from '../../../common/utils/methods/commonMethods/utilityMethod'
import { successToast } from '../../../common/utils/methods/toasterFunctions/function'
import { RolePermissions } from 'appContext'
import { PermissionContext } from 'appContext'
import { pages } from '../../../common/constant'
import { PermisionDenied } from '../../../common/commonComponents'

const ExAakashianConfig = () => {

  const userPermissions = RolePermissions(useContext(PermissionContext), pages['exAakashianConfig']['id']);
  const history = useHistory()
  const listRef = useRef();

  const [rows, setRows] = useState([]);
  const [dropdowns, setDropdowns] = useState({});
  const [dropdownLoader, setDropdownLoader] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);

  const fetchDropdownData = async () => {
    setDropdownLoader(true);
    const res = await getDropdowns();
    await setDropdowns(res);
    setDropdownLoader(false);
  }

  const fetchAllConfig = async () => {
    setApiLoader(true);
    const res = await getAllConfig();
    if (res?.code === 200) {
      const updatedRes = res?.data?.map(item => ({
        ...item,
        'courseCategoryId': getMultiSelectDefault(item?.courseCategoryId, dropdowns?.allCourseCategory),
        'programStatus': getMultiSelectDefault(item?.programStatus, dropdowns?.programStatus),
        'academicCareerId': getSingleSelectDefault(item?.academicCareerId, dropdowns?.allAcademicCareer),
        'termId': getSingleSelectDefault(item?.termId, dropdowns?.allTerm),
      }))
      setRows(updatedRes);
    } else {
      setRows([]);
    }
    setApiLoader(false);
  }

  const updateConfigHandler = async (id, request) => {
    setApiLoader(true);
    const updatedRequest = {
      academicCareerId: request?.academicCareerId,
      termId: request?.termId,
      courseCategoryId: request?.courseCategoryId,
      minimumCourseValue: request?.minimumCourseValue,
      payableAmount: request?.payableAmount,
      programStatus: request?.programStatus,
      status: request?.status,
    }
    const res = await updateConfig(id, updatedRequest);
    if (res?.code === 200) {
      successToast(res?.message);
      await fetchAllConfig();
    }
    setApiLoader(false);
    return res;
  }
  const deleteConfigHandler = async (id) => {
    setApiLoader(true);
    const res = await deleteConfig(id);
    if (res?.code === 200) {
      successToast(res?.message);
      await fetchAllConfig();
    }
    setApiLoader(false);
    return res;
  }

  const createConfigHandler = async (request) => {
    setApiLoader(true);
    const res = await createConfig({ ...request, 'id': null, 'status': 'ACTIVE' });
    if (res?.code === 200) {
      successToast(res?.message);
      await fetchAllConfig();
    }
    setApiLoader(false);
    return res;
  }

  useEffect(() => {
    fetchDropdownData();
  }, [])

  useEffect(() => {
    if (!isObjEmpty(dropdowns)) {
      fetchAllConfig();
    }
  }, [dropdowns])

  return (
    userPermissions ? (
      <div className={`remove-container-top-space ${styles?.main_container}`}>
        <div className='d-flex align-items-center' style={{ padding: '20px 0 20px', marginBottom: '24px' }}>
          <BackIcon onClick={() => history?.goBack()} />
          <div className='color-dark heading-4' style={{ marginLeft: '24px' }}>Ex-Aakashian Configurations</div>
        </div>
        <div className={`${styles?.content_container}`}>
          {(apiLoader || dropdownLoader) && <ALoader />}
          <div className='mb-1 d-flex justify-content-end'>
            {checkPermission(userPermissions, 'C') && <AButton updatedStyle={{ fontWeight: 600 }} onClick={() => listRef?.current?.addNewRow()}>
              Add new row
            </AButton>}
          </div>
          {/* <div className={`d-flex ${styles?.list_container}`}> */}
            <ListComponent
              ref={listRef}
              rows={rows}
              dropdowns={dropdowns}
              createConfigHandler={createConfigHandler}
              updateConfigHandler={updateConfigHandler}
              deleteConfigHandler={deleteConfigHandler}
            />
          {/* </div> */}
        </div>
      </div>
    ) : (
      <PermisionDenied />
    )
  )
}

export default ExAakashianConfig