import { endpoint } from "../../views/pages/manage/common/constant";
import { SET_APPROVER_TYPE, ALL_ACTIVE_CONFIG_FAILURE, ALL_ACTIVE_CONFIG_REQUEST, ALL_ACTIVE_CONFIG_SUCCESS, APPROVAL_REQ_FAILURE, APPROVAL_REQ_REQUEST, APPROVAL_REQ_SUCCESS, ALL_BUSINESS_AREAS_REQUEST, ALL_BUSINESS_AREAS_SUCCESS, ALL_BUSINESS_AREAS_FAILURE, ALL_COURSE_CATEGORIES_REQUEST, ALL_COURSE_CATEGORIES_SUCCESS, ALL_COURSE_CATEGORIES_FAILURE, ROLE_ID_REQUEST, ROLE_ID_SUCCESS, ROLE_ID_FAILURE, SET_SELECTED_ROW_IDS, CLEAR_APPROVAL_DATA, CLEAR_SELECTED_ROW_IDS, ALL_COURSE_IDS_REQUEST, ALL_COURSE_IDS_SUCCESS, ALL_COURSE_IDS_FAILURE, ALL_REGIONS_REQUEST, ALL_REGIONS_SUCCESS, ALL_REGIONS_FAILURE, DELETE_VERIFICATION_ROW, SET_VERIFICATION_ROWS,OTHER_CHARGES_APPROVAL_REQ_REQUEST,
    OTHER_CHARGES_APPROVAL_REQ_SUCCESS,
    OTHER_CHARGES_APPROVAL_REQ_FAILURE, } from "./actionTypes";
import { fetchAllPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { fetchAllPostPromisedData, setCookie } from "../../views/pages/manage/common/utils/methods/commonMethods/utilityMethod";

export const getAllActiveConfigs = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: ALL_ACTIVE_CONFIG_REQUEST
        });

        const response = await fetchAllPromisedData(endpoint.concessionConfigConc.getAll, true);

        if (response?.code === 200) {
            dispatch({
                type: ALL_ACTIVE_CONFIG_SUCCESS,
                payload: response?.data
            })
        } else {
            dispatch({
                type: ALL_ACTIVE_CONFIG_FAILURE,
                payload: response?.message
            })
        }
    }
}

export const getAllBusinessAreas = () => {
    return async (dispatch, getState) => {

        dispatch({
            type: ALL_BUSINESS_AREAS_REQUEST
        })

        const response = await fetchAllPromisedData(endpoint.businessArea.getAllCached, true);
        if (response?.code === 200) {

            const list = response?.data?.map(item => ({
                label: item['businessAreaDispValue'],
                value: item.id,
                regionId: item.regionId,
                desc : item?.['businessAreaDesc'] ?? ''
            }))
            dispatch({
                type: ALL_BUSINESS_AREAS_SUCCESS,
                payload: list
            })
        } else {
            dispatch({
                type: ALL_BUSINESS_AREAS_FAILURE,
                payload: response?.message
            })
        }
    }
}

export const getAllActiveRegions = () => {
    return async (dispatch, getState) => {

        dispatch({
            type: ALL_REGIONS_REQUEST
        })

        const response = await fetchAllPromisedData(endpoint?.region.getAllActive, true);
        if (response?.code === 200) {

            const list = response?.data?.map(item => ({
                label: item['regionDispValue'],
                value: item.id
            }))
            dispatch({
                type: ALL_REGIONS_SUCCESS,
                payload: list
            })
        } else {
            dispatch({
                type: ALL_REGIONS_FAILURE,
                payload: response?.message
            })
        }
    }
}

export const getAllActiveCourseCategory = () => {
    return async (dispatch, getState) => {

        dispatch({
            type: ALL_COURSE_CATEGORIES_REQUEST
        })

        const response = await fetchAllPromisedData(endpoint?.courseCategory?.getAllActiveCourseCategory, true);
        if (response?.code === 200) {

            const list = response?.data?.map(item => ({
                label: item['courseAttributeValueDispValue'],
                value: item.id
            }))
            dispatch({
                type: ALL_COURSE_CATEGORIES_SUCCESS,
                payload: list
            })
        } else {
            dispatch({
                type: ALL_COURSE_CATEGORIES_FAILURE,
                payload: response?.message
            })
        }
    }
}

export const getAllActiveCourseIds = () => {
    return async (dispatch, getState) => {

        dispatch({
            type: ALL_COURSE_IDS_REQUEST
        })

        const response = await fetchAllPromisedData(endpoint?.course_details?.getCachedRecords, true);
        if (response?.code === 200) {

            const list = response?.data?.map(item => ({
                label: item.courseName,
                value: +item.courseId,
            }))
            dispatch({
                type: ALL_COURSE_IDS_SUCCESS,
                payload: list
            })
        } else {
            dispatch({
                type: ALL_COURSE_IDS_FAILURE,
                payload: response?.message
            })
        }
    }
}

export const setApproverType = (approverType) => {
    return (dispatch, getState) => {
        dispatch({
            type: SET_APPROVER_TYPE,
            payload: approverType
        })
    }
}

export const getApprovalRequests = (reqObj,page) => {
    return async (dispatch, getState) => {
        dispatch({
            type: APPROVAL_REQ_REQUEST
        });

        const response = await fetchAllPostPromisedData(`${endpoint.approvalConcession.getAllApprovalRequests}?offset=${page}`, reqObj, 'post');

        if (response?.code === 200) {
            dispatch({
                type: APPROVAL_REQ_SUCCESS,
                payload: response?.data,
                currentPage: page
            })
        } else {
            dispatch({
                type: APPROVAL_REQ_FAILURE,
                payload: response?.message,
                currentPage: page
            })
        }
    }
}

export const setRole = (roleType) => {
    return async (dispatch, getState) => {
        dispatch({
            type: ROLE_ID_REQUEST
        });

        const response = await fetchAllPromisedData(endpoint.role_Name.getAllActive, true);

        if (response?.code === 200) {
            const role = response?.data?.find(item => item.roleName === roleType);
            setCookie('roleId',JSON.stringify(role));

            dispatch({
                type: ROLE_ID_SUCCESS,
                payload: role
            })
        } else {
            dispatch({
                type: ROLE_ID_FAILURE,
                payload: response?.message
            })
        }
    }
}

export const setSelectedRowIds = (createdBy, ids) => {
    return (dispatch, getState) => {
        dispatch({
            type: SET_SELECTED_ROW_IDS,
            payload: {
                key: createdBy,
                value: ids
            }
        })
    }
}

export const clearSelectedRowsIds = () => {
    return (dispatch, getState) => {
        dispatch({
            type: CLEAR_SELECTED_ROW_IDS
        })
    }
}

export const clearApprovalData = () => {
    return (dispatch, getState) => {
        dispatch({
            type: CLEAR_APPROVAL_DATA
        })
    }
}

export const setVerificationRows = () => {
    return (dispatch, getState) => {
        dispatch({
            type: SET_VERIFICATION_ROWS,
        })
    }
} 

export const deleteVerificationRow = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: DELETE_VERIFICATION_ROW,
            payload: {id}
        })
    }
}

export const getOtherChargesForApproval = (filter,page) => {
    const updatedFilter = {
        ...filter,
        ...(filter?.approvalStatus === 'reject' && {approvalStatus : 'REJECTED'})
    }
    return async (dispatch, getState) => {
        dispatch({
            type: OTHER_CHARGES_APPROVAL_REQ_REQUEST
        });

        const response = await fetchAllPostPromisedData(`${endpoint.otherCharges.otherChargesForApproval}?offset=${page}`, updatedFilter, 'post');

        if (response?.code === 200) {
            dispatch({
                type: OTHER_CHARGES_APPROVAL_REQ_SUCCESS,
                payload: response?.data,
                currentPage: page
            })
        } else {
            dispatch({
                type: OTHER_CHARGES_APPROVAL_REQ_FAILURE,
                payload: response?.message,
                currentPage: 0
            })
        }
    }
}