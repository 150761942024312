/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 AESL (https://www.creative-tim.com)

* Coded by AESL

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// react library for routing
import { NavLink as NavLinkRRD, Link } from 'react-router-dom';
// nodejs library that concatenates classes
import classnames from 'classnames';
// nodejs library to set properties for components
import { PropTypes } from 'prop-types';
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from 'react-perfect-scrollbar';
// reactstrap components
import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav } from 'reactstrap';
import { connect } from 'react-redux';
import { updatePermissions } from '../../redux';
import AakashLogo from '../../assets/img/icons/sidebar/aakashgLogo/aakashNewLogo.svg';

// import Style from './index.scss';
class Sidebar extends React.Component {
	// dispatch = useDispatch();
	constructor(props) {
		super(props);
		this.state = {
			collapseOpen: true,
			// mouseOverVerticalNavBar: false,
			...this.getCollapseStates(props.routes)
		};
	}
	// verifies if routeName is the one active (in browser input)
	activeRoute = routeName => {
		// return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
		return this.props.location.pathname === routeName ? 'active' : '';
	};
	// makes the sidenav normal on hover (actually when mouse enters on it)
	onMouseEnterSidenav = () => {
		if (window.screen.availWidth > 1000 && this.props.mouseEventAllowed) {
			this.props.toggleMouseOver(true);
			if (!document.body.classList.contains('g-sidenav-pinned')) {
				document.body.classList.add('g-sidenav-pinned');
				document.body.classList.remove('g-sidenav-hidden');
			}
		}
	};
	// makes the sidenav mini on hover (actually when mouse leaves from it)
	onMouseLeaveSidenav = () => {
		if (window.screen.availWidth > 1000 && this.props.mouseEventAllowed) {
			this.props.toggleMouseOver(false)
			if (document.body.classList.contains('g-sidenav-pinned')) {
				document.body.classList.remove('g-sidenav-pinned');
				document.body.classList.add('g-sidenav-hidden');
			}
		}
		// this.setState({
		// 	mouseOverVerticalNavBar: false
		// });
	};
	// toggles collapse between opened and closed (true/false)
	toggleCollapse = () => {
		this.setState({
			collapseOpen: !this.state.collapseOpen,
		});
	};
	// closes the collapse
	closeCollapse = () => {
		this.setState({
			collapseOpen: false,
		});
	};
	// this creates the intial state of this component based on the collapse routes
	// that it gets through this.props.routes
	getCollapseStates = routes => {
		let initialState = {};
		routes.map((prop, key) => {
			if (prop.collapse) {
				initialState = {
					[prop.state]: this.getCollapseInitialState(prop.views),
					...this.getCollapseStates(prop.views),
					...initialState,
				};
			}
			return null;
		});
		return initialState;
	};
	// this verifies if any of the collapses should be default opened on a rerender of this component
	// for example, on the refresh of the page,
	// while on the src/views/forms/RegularForms.js - route /admin/regular-forms
	getCollapseInitialState(routes) {
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
				return true;
			} else if (window.location.href.indexOf(routes[i].path) !== -1) {
				return true;
			}
		}
		return false;
	}
	// this is used on mobile devices, when a user navigates
	// the sidebar will autoclose
	closeSidenav = () => {
		if (window.innerWidth < 1200) {
			this.props.toggleSidenav();
		}
	};
	// dispatch = useDispatch();
	// this function creates the links and collapses that appear in the sidebar (left menu)
	updateCurrentScreen = screenId => {
		// const currentScreenPermissions = getPermissions(this.props.permissions,screenId)
		// console.log('CurrentScreenPermissions------>',currentScreenPermissions);
		// this.props.updatePermissions({
		//       currentScreenId : screenId,
		//       currentScreenPermissions : currentScreenPermissions,
		//       ...this.props.permissions,
		// });
	};
    isSearchTxtPresent = (prop, searchTxt) => {
		// if searchTxt is empty, simply return
		if(!searchTxt) return false;
		if (prop?.views) {
			// If prop has views, recursively check each view
			for (const view of prop.views) {
				if (this.isSearchTxtPresent(view, searchTxt)) {
					// If the text is found in any view, return true immediately
					return true;
				}
			}
		} else if (
			searchTxt === prop?.name || // Check for an exact match
			(prop.name && prop.name.toLowerCase().includes(searchTxt.toLowerCase())) // Check for a case-insensitive partial match
		) {
			return true;
		}
		
		// If the text is not found in any view or prop, return false
		return false;
	};
	
	createLinks = routes => {
		// console.log('routes', routes);
		return (
			routes &&
			routes.map((prop, key) => {
				if (prop.redirect) {
					return null;
				}
				 const isSearchTxtPresent=this.isSearchTxtPresent(prop,this.props.searchTxt);
				if (prop.collapse) {
					var st = {};
					st[prop['state']] = !this.state[prop.state];
					return (
						<NavItem key={key}>
							<NavLink
								href="#pablo"
								data-toggle="collapse"
								aria-expanded={
									// this.props.searchTxt ? true : this.state[prop.state]
									(this.props.searchTxt ? isSearchTxtPresent : false)||this.state[prop.state]
									
								}
								className={prop.icon ? classnames({
									active: this.getCollapseInitialState(prop.views),
								}) : ''}
								onClick={(e) => {
									e.preventDefault();
									this.setState(st);
								}}
							>
								{prop.icon ? (
									<>
										<i className={prop.icon} />
										<span className="nav-link-text">{prop.name}</span>
									</>
								) : prop.miniName ? (
									<>
										<span className="sidenav-mini-icon"> {prop.miniName} </span>
										<span className="sidenav-normal"> {prop.name} </span>
									</>
								) : (
									<span className="sidenav-normal"> {prop.name} </span>
								)}
							</NavLink>
							<Collapse
								isOpen={
									(!this.props.mouseOverVerticalNavBar && !this.props.sidenavOpen)
										? false
										: ((this.props.searchTxt
											// ? true
											? isSearchTxtPresent
											:false)||this.state[prop.state])
								}
							>
								<Nav className="nav-sm flex-column">
									{this.createLinks(prop.views)}
								</Nav>
							</Collapse>
						</NavItem>
					);
				}
				return this.props.searchTxt && (
					this.props.searchTxt === prop.name ||
						prop.name
							.toLowerCase()
							.includes(this.props.searchTxt.toLowerCase()) )? ( //|| prop.name.toLowerCase().includes(this.props.searchTxt.toLowerCase())
						<NavItem
							className={this.activeRoute(prop.layout + prop.path)}
							key={key}
						>
							<NavLink
								to={prop.layout + prop.path}
								activeClassName=""
								onClick={() => {
									this.updateCurrentScreen(prop.screenId);
									this.closeSidenav();
								}}
								tag={NavLinkRRD}
							>
								{prop.icon !== undefined ? (
									<>
										<i className={prop.icon} />
										<span
											className="nav-link-text"
											style={{ color: '#00b0f5', fontWeight: '900' }}
										>
											{prop.name}
										</span>
									</>
								) : prop.miniName !== undefined ? (
									<>
										{/* <span className="sidenav-mini-icon"> {prop.miniName} </span> */}
										<span
											className="sidenav-normal"
											style={{ color: '#00b0f5', fontWeight: '900' }}
										>
											{' '}
											{prop.name}{' '}
										</span>
									</>
								) : (
									<span
										className="sidenav-normal"
										style={{ color: '#00b0f5', fontWeight: '900' }}
									>
										{' '}
										{prop.name}{' '}
									</span>
								)}
							</NavLink>
						</NavItem>
					) : (
					<NavItem
						className={this.activeRoute(prop.layout + prop.path)}
						key={key}
					>
						<NavLink
							to={prop.layout + prop.path}
							activeClassName=""
							onClick={() => {
								this.updateCurrentScreen(prop.screenId);
								this.closeSidenav();
							}}
							tag={NavLinkRRD}
						>
							{prop.icon !== undefined ? (
								<>
									<i className={prop.icon} />
									<span className="nav-link-text">{prop.name}</span>
								</>
							) : prop.miniName !== undefined ? (
								<>
									{/* <span className="sidenav-mini-icon"> {prop.miniName} </span> */}
									<span className="sidenav-normal"> {prop.name} </span>
								</>
							) : (
								<span className="sidenav-normal"> {prop.name} </span>
							)}
						</NavLink>
						<Collapse
							isOpen={
								(!this.props.mouseOverVerticalNavBar && !this.props.sidenavOpen)
									? false
									: ((this.props.searchTxt
										// ? true
										? isSearchTxtPresent
										: false)||this.state[prop.state])
							}
						>
							<Nav className="nav-sm flex-column">
								{this.createLinks(prop.views)}
							</Nav>
						</Collapse>
					</NavItem>
				);
			})
		);
	};
	render() {
		const { routes, logo } = this.props;
		let navbarBrandProps;
		if (logo && logo.innerLink) {
			navbarBrandProps = {
				to: logo.innerLink,
				tag: Link,
			};
		} else if (logo && logo.outterLink) {
			navbarBrandProps = {
				href: logo.outterLink,
				target: '_blank',
			};
		}

		const scrollBarInner = (
			<div className="scrollbar-inner">
				<div className="sidenav-header d-flex align-items-center">
					{logo ? (
						<NavbarBrand>
							<img
								alt={logo.imgAlt}
								// style={{ width: '100px' }}
								className="navbar-brand-img"
								// src={'https://dcx0p3on5z8dw.cloudfront.net/themes/classroom/logo.svg'}
								src={AakashLogo}
							/>
						</NavbarBrand>
					) : null}
					<div className="ml-auto">
						<div
							className={classnames('sidenav-toggler d-none d-xl-block', {
								active: this.props.sidenavOpen,
							})}
							onClick={() => {
								if (this.props.sidenavOpen) {
									// document.body.classList.remove("g-sidenav-show");
									// this.onMouseLeaveSidenav()
									// this.props.toggleMouseOver()
								}
								this.props.toggleSidenav();

								// this.props.setMouseOverVerticalNavBar(false);
							}}
						>
							<div className="sidenav-toggler-inner">
								<i className="sidenav-toggler-line" />
								<i className="sidenav-toggler-line" />
								<i className="sidenav-toggler-line" />
							</div>
						</div>
					</div>
				</div>
				<div className="navbar-inner">
					<Collapse navbar isOpen={true}>
						<Nav navbar>{this.createLinks(routes)}</Nav>
						{/* <hr className="my-3" /> */}
					</Collapse>
				</div>
			</div>
		);
		return (
			<Navbar
				className={
					'sidenav navbar-vertical navbar-expand-xs navbar-light bg-white ' + (this.props.rtlActive ? '' : 'fixed-left')
				}
				onMouseEnter={this.onMouseEnterSidenav}
				onMouseLeave={this.onMouseLeaveSidenav}
			>
				{navigator.platform.indexOf('Win') > -1 ? (
					<PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
				) : (
					scrollBarInner
				)}
			</Navbar>
		);
	}
}

Sidebar.defaultProps = {
	routes: [{}],
	toggleSidenav: () => { },
	sidenavOpen: false,
	rtlActive: false,
};

Sidebar.propTypes = {
	// function used to make sidenav mini or normal
	toggleSidenav: PropTypes.func,
	// prop to know if the sidenav is mini or normal
	sidenavOpen: PropTypes.bool,
	// links that will be displayed inside the component
	routes: PropTypes.arrayOf(PropTypes.object),
	// logo
	logo: PropTypes.shape({
		// innerLink is for links that will direct the user within the app
		// it will be rendered as <Link to="...">...</Link> tag
		innerLink: PropTypes.string,
		// outterLink is for links that will direct the user outside the app
		// it will be rendered as simple <a href="...">...</a> tag
		outterLink: PropTypes.string,
		// the image src of the logo
		imgSrc: PropTypes.string.isRequired,
		// the alt for the img
		imgAlt: PropTypes.string.isRequired,
	}),
	// rtl active, this will make the sidebar to stay on the right side
	rtlActive: PropTypes.bool,
};

const mapStateToProps = state => {
	return {
		permissions: state.permissions,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		updatePermissions: data => dispatch(updatePermissions(data)),
	};
};
// export default Sidebar;
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
