import React, { useState } from 'react'
import AButton from '../../../common/form-fields-mui/AButton';
import { Dialog } from '@mui/material';
import { ReactComponent as CrossIcon } from 'assets/img/svg/CrossIcon.svg'
import { ReactComponent as QuestionIcon } from 'assets/img/svg/QuestionMarkConfirmationDialog.svg'

const useInactivePrompt = (tableType) => {
  const [promise, setPromise] = useState(null);

  const confirm = () => new Promise((resolve, reject) => {
    setPromise({ resolve });
  });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise.resolve(false);
    handleClose();
  };

  const inactivePrompt = () => (
    <Dialog onClose={handleCancel} open={promise !== null}>
      <div style={{ padding: "28px 24px", width: "500px" }}>
        <div className='d-flex justify-content-between align-items-start mb-3'>
          <QuestionIcon color='#414141' />
          <CrossIcon width={32} height={32} onClick={handleCancel} />
        </div>

        <>
          <div
            style={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "24px",
              color: "#000000",
              marginBottom: "1rem",
            }}
          >
            Inactivating this {tableType} will remove the room details from the time tables of relevant batches.
          </div>
          <div
            style={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#414141",
              marginBottom: "28px",
            }}
          >
            Are you sure want to proceed?
          </div>
        </>

        <div className='d-flex justify-content-between'>
          <AButton variant='outline' className='button_remove_left_margin' updatedStyle={{ border: '1px solid #414141', color: '#414141', width: '180px' }} onClick={handleCancel}>No</AButton>
          <AButton variant='danger_filled' updatedStyle={{ backgroundColor: '#CC5F5F', width: '180px' }} onClick={handleConfirm}>Yes, Inactive</AButton>
        </div>
      </div>
    </Dialog>
  )

  return [inactivePrompt, confirm];
}

export default useInactivePrompt