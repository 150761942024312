import React from 'react';
import Style from './pageLoaderAniamtion.module.scss';

const PageLoadingAnimation = () => {
  return (
    <div className={Style.loader_container}>
      <div className={Style.container}>
        <div className={Style.loader_wrapper}>
          <div className={Style.loader}>
            <div className={`${Style.loader} ${Style.loader_inner}`}></div>
          </div>
        </div>
        <p className={Style.loader_text}>Loading...</p>
      </div>
    </div>
  );
};

export default PageLoadingAnimation;
