import React, { useState, useEffect } from "react";
import Select from 'react-select';
import moment from "moment";
import DatePicker from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';
import range from "lodash/range";
import "react-datepicker/dist/react-datepicker.css";
import './index.css';
import { customStylesSelect, customTheme } from "../../formFeilds/input/commonInput";
import styled from 'styled-components';
import { CalendarIcon } from "../../../../../../assets/img/svg/calendarIcon";

const CustomizedDatePicker = styled(DatePicker)`
     & .react-datepicker__input-container{
        color : red !important;
     }
     & .react-datepicker__input-container input {
             color : red !!important;
     }
     
     input{
        color:red !important;
     }   
    //  .react-datepicker-wrapper,
    // .react-datepicker__input-container,
    // .react-datepicker__input-container input {
    //   color:red !important;
    // } 
`
const CustomInput = styled.input`
    color:red !important;
`



const datePickerSelect = {
    menu: (styles) => {
        return {
            ...styles,
            width: '130px !important'
        }
    },

    indicatorContainer: (styles) => {
        return {
            ...styles,
            padding: '0px 8px 0px 8px'
        }
    },
    indicatorSeparator: (defaultStyles) => {
        return {
            ...defaultStyles,
            display: 'none'
        }
    },
    valueContainer: (defaultStyles) => {
        return {
            ...defaultStyles,
            fontSize: '14px'
        }
    },
    dropdownIndicator: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#515151 !important',
        }

    },
    control: (defaultStyles, state) => {
        return {
            ...defaultStyles,
            borderColor: '#dee2e6',
            height: '40px',
            "&:hover": {
                borderColor: "#f0f0f0",
                // color: '#00AEEF'
            },
            border: 0,
            borderRadius: '0',
            // borderRight:'1px solid #f0f0f0',
            boxShadow: '0',
            width: '115px'
        }
    },
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#515151',
        }
    },
    svg: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: 'red'
        }
    },
    container: (defaultStyles) => {
        return {
            ...defaultStyles,
            width: '105px !important',
            minWidth: '105px !important',
        }
    }
}

//usage

// handler={updateKey}
// index={index}
// keyName='effectiveDate'
// rangeFrom={1950}
// rangeTo={2099}
// minDate={new Date('1950/01/01')}
// maxDate={new Date('2099/12/31')}

//
// rangeFrom={1990}
// rangeTo={2016}
// minDate={new Date('1990/01/01')}
// maxDate={new Date('2015/12/31')}

const dateFormat = (e) => {
    try {
        const value = e.target.value;
        const inputValue = value ? value.toString() : "";
        const number = inputValue.replace(/[^0-9]/g, "");
        if (number.length === 4) {
            const numberFormat = number.replace(/(\d{3})/, "($1) ");
            // form.setFieldsValue({phone: numberFormat});
        } else if (number.length === 7) {
            const numberFormat = number.replace(/(\d{3})(\d{3})/, "($1) $2-");
            // form.setFieldsValue({phone: numberFormat});
        } else if (number.length === 10) {
            const numberFormat = number.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
            // form.setFieldsValue({phone: numberFormat});
        }
    } catch (error) {
        console.log("error", error);
    }
}


const CustomDatePicker = (props) => {
    const {
        index,
        value = '',
        keyName = 'datePicker' + moment().format('DD-MM-YYYY: hh:mm:ss'),
        handler = () => { },
        minDate = moment('01-01-1950').toDate(),
        maxDate = moment().add(78, 'years').toDate(),
        disabled = false,
        dataTestId = 'TestDatePicker',
        placeHolder = 'Example 01-Jan-2022',
        rangeFrom = 1950,
        rangeTo = 2099,
        action = 'new'
    } = props;
    // console.log('Value-------------->', value);
    const [selectedDate, setSelectedDate] = useState(null);
    const [years, setYears] = useState();

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];
    const monthsDropDown = [
        { label: 'January', value: 'January' },
        { label: 'February', value: 'February' },
        { label: 'March', value: 'March' },
        { label: 'April', value: 'April' },
        { label: 'May', value: 'May' },
        { label: 'June', value: 'June' },
        { label: 'July', value: 'July' },
        { label: 'August', value: 'August' },
        { label: 'September', value: 'September' },
        { label: 'October', value: 'October' },
        { label: 'November', value: 'November' },
        { label: 'December', value: 'December' },
    ]

    useEffect(() => {
        // console.log(props, 'props')
        if (props.value)
            props.value && setSelectedDate(moment(value).toDate());
        else setSelectedDate(null)
        if (rangeFrom && rangeTo) {
            const yearOptions = range(rangeFrom, rangeTo + 1).map(option => {
                return { label: option.toString(), value: option }
            })
            setYears(yearOptions)
        }
    }, [props.value]);
    const buttonStyles = {
        border: 0,
        backgroundColor: "#fff",
        borderRight: '1px solid #f0f0f0',
        padding: '0 10px',
        borderTopLeftRadius: '10px'
    }
    const buttonStyleRight = {
        border: 0,
        backgroundColor: "#fff",
        borderLeft: '1px solid #f0f0f0',
        padding: '0 10px',
        borderTopRightRadius: '10px'
    }
    const CustomHeader = ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
    }) => {
        // console.log('Date-------->', date)

        const onMenuOpen = () => {
            setTimeout(() => {
                const selectedEl = document.getElementsByClassName("date_year_picker__option--is-selected")[0];
                if (selectedEl) {
                    selectedEl.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
                }
            }, 15);
        };

        return (
            <div style={{ display: "flex", justifyContent: "center", borderBottom: '1px solid #f0f0f0' }}>
                <button style={buttonStyles} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                    <i className='fas fa-angle-left' />
                </button>
                <div>
                    <Select styles={datePickerSelect}
                        id="date_month_picker"

                        theme={customTheme}
                        value={{ value: months[getMonth(date)], label: months[getMonth(date)] }}
                        onChange={(sData) => changeMonth(months.indexOf(sData.value))}
                        options={monthsDropDown.map((options, i) => {
                            if (getYear(date) === (new Date(minDate).getFullYear())) {
                                if (i < new Date(minDate).getMonth()) {
                                    return { ...options, isDisabled: true }
                                }
                                return options
                            }
                            return options
                        })}
                    />
                </div>
                {'  '}
                <Select styles={datePickerSelect}
                    id="date_year_picker"
                    classNamePrefix="date_year_picker"
                    theme={customTheme}
                    value={{ value: getYear(date), label: getYear(date).toString() }}
                    onChange={(sData) => changeYear(sData.value)}
                    onMenuOpen={onMenuOpen}
                    options={years.map(option => {
                        if (minDate && option?.value < (new Date(minDate).getFullYear())) {
                            return { ...option, isDisabled: true }
                        }
                        if (maxDate && option?.value > (new Date(maxDate).getFullYear())) {
                            return { ...option, isDisabled: true }
                        }
                        return option
                    })}
                />
                <button
                    style={buttonStyleRight}
                    onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                >
                    <i className='fas fa-angle-right' />
                </button>
            </div>
        )
    }
    const Input = (props) => (
        <div>
            <input
                type="text"
                placeholder={placeHolder}
                style={{ paddingRight: '30px', fontSize: '12px' }}
                className={disabled || !value  ? '' : 'custom_input'}
                {...props}
            />
            <CalendarIcon isActive={props.value ?true :false} className="calendar-icon" onClick={props.onClick} disabled={disabled}/>
        </div>
    )


    // useEffect(() => {
    //     console.log('selectedDate------->', selectedDate);
    // }, [selectedDate])
    return (
        <CustomizedDatePicker
            renderCustomHeader={CustomHeader}
            selected={selectedDate}
            onChange={(date) => {
                setSelectedDate(moment(date).toDate());
                handler && handler(index, date, keyName);
            }}
            customInput={<Input />}
            disabledKeyboardNavigation
            showMonthDropdown
            showYearDropdown
            scrollableYearDropdown={true}
            useShortMonthInDropdown
            key={"InputField" + index}
            dropdownMode="select"
            dateFormat="dd-MMM-yyyy"
            placeholderText={placeHolder}
            data-testid={dataTestId}
            disabled={disabled}
            // minDate={minDate}
            minDate={moment(minDate).toDate()}
            maxDate={moment(maxDate).toDate()}

            onKeyDown={(e) => {
                e.preventDefault();
            }}
            portalId="root"
        />
    );
};

export default CustomDatePicker;
