import React, { useEffect } from 'react';
import {
  Card,
  CardHeader,
  Input,
  Container,
  Button,
  Table,
  Row,
} from 'reactstrap';
import { useState } from 'react';
// import "views/pages/manage/addressType/node_modules/react-sliding-pane/dist/react-sliding-pane.css";
import { failureToast } from '../../common/utils/methods/toasterFunctions/function';
import { MasterHeaderAuto, Rows } from '../../common/commonComponents/masterHeader/masterHeader';
import {
  getAPI,
  masterServiceBaseUrl,
  successToast,
  putpost,
  putpost2,
} from 'services/http';
import moment from 'moment';
var _ = require('lodash');

const ConcessionSubCategoryTable = (props) => {
  const {
    serverData,
    setSearch,
    resetSearch,
    isSaveVisible,
    setisSaveVisible,
    action,
  } = props;
  const [tableDataKey, settableDataKey] = useState('tableDataKey');
  const [updatedKey, setUpdateKey] = useState(new Date());
  const [searchStr, setSearchStr] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: '__new',
        status: 'ACTIVE',
        subCategoryDesc: null,
        subCategoryShortDesc: null,
        subCategoryDispValue: null,
        updatedDate: null,
      },
      ...props.classArray,
    ]);
  };

  const headerList = [
    { name: 'Sub Category Code Key' },
    { name: 'Description' },
    { name: 'Short Description' },
    { name: 'Display Value' },
    { name: 'Last Updated Date' },
    { name: 'Status' },
    { name: 'Action' },
  ];
  const listToRender = [
    'subCategoryKey',
    'subCategoryDesc',
    'subCategoryShortDesc',
    'subCategoryDispValue',
    'updatedOn',
    'status',
  ];

  const saveAll = (cb) => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if (el['editMode']) {
        if (!el['subCategoryDesc'] || !el['subCategoryDesc'].trim()) {
          isValid = false;
          failureToast('Please Enter Description');
          return;
        } 
        // else if (
        //   !el['subCategoryShortDesc'] ||
        //   !el['subCategoryShortDesc'].trim()
        // ) {
        //   isValid = false;
        //   failureToast('Please Enter Short Description');
        //   return;
        // } 
        else if (
          !el['subCategoryDispValue'] ||
          !el['subCategoryDispValue'].trim()
        ) {
          isValid = false;
          failureToast('Please Enter Display Value');
          return;
        }
        if (!isValid) {
          return;
        }
        if (_tempArray[index]['__status'] == '__new') {
          putpost(
            masterServiceBaseUrl + '/subCategory/createSubCategory',
            (data) => {
              successToast(data['message']);
              setisSaveVisible(false);
              setUpdateKey(new Date());
              props.fetchClasses(`${masterServiceBaseUrl}/subCategory/getAllSubCategory`);
            },
            (data) => {
              failureToast(data['message']);
            },
            _tempArray[index],
            'post'
          );
        } else {
          putpost(
            masterServiceBaseUrl + '/subCategory/update/' + _tempArray[index].id,
            (data) => {
              successToast(data['message']);
              setisSaveVisible(false);
              // delete _tempArray[index]['editMode']
              // props.setclassArray(_tempArray)
              props.fetchClasses(`${masterServiceBaseUrl}/subCategory/getAllSubCategory`);
              settableDataKey(new Date().getMilliseconds());
              // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
            },
            (data) => {
              failureToast(data['message']);
            },
            _tempArray[index],
            'put'
          );
        }
      } else {
      }
    });
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
        // settableDataKey(new Date().getMilliseconds())
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      cb();
    }
  };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]['editMode'] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };
  const deleteRow = (index) => {
    if (props.classArray[index]['__status'] == '__new') {
      let n = props.classArray;
      n.splice(index, 1);
      props.setclassArray(n);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
    } else {
      if (
        window.confirm('Are you sure you want to delete this Sub Category?')
      ) {
        putpost2(
          masterServiceBaseUrl +
            '/subCategory/deleteSubCategory/' +
            props.classArray[index].id,
          (data) => {
            successToast(data['message']);
            if (props.classArray.length == 1) {
              props.previousPage();
              props.fetchClasses(`${masterServiceBaseUrl}/subCategory/getAllSubCategory`);
            } else {
              var _tempArray = props.classArray;
              if (_tempArray[index]['editMode']) {
                setisSaveVisible(false);
              }
              _tempArray.splice(index, 1);
              props.setclassArray(_tempArray);
            }
            settableDataKey(new Date().getMilliseconds());
          },
          (data) => {
            failureToast(data['message']);
          },
          'PUT'
        );
      }
    }
  };

  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
    setUpdateKey(new Date());
  };

  const filterData = (str) => {
    const newData =
      serverData &&
      serverData.filter((obj) =>
        Object.values(obj).some(
          (val) =>
            val && val.toString().toLowerCase().includes(str.toLowerCase())
        )
      );
    setSearch(newData);
  };

  useEffect(() => {
    if (!searchStr || !searchStr.trim()) {
      resetSearch();
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
      filterData(searchStr);
    }
  }, [searchStr]);

  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0 d-flex justify-content-between">
                <h3 className="mb-0 ">
                  Scholarship Master : Define Sub Category
                </h3>
                <div
                  className="d-flex flex-row justify-content-between align-items-center"
                  style={{ width: '35%' }}
                >
                  <h3 className="text-muted">Search :</h3>
                  <Input
                    className="tdInput"
                    style={{ width: '40%' }}
                    defaultValue={searchStr}
                    onChange={(e) => setSearchStr(e.target.value)}
                  />
                  <Button
                    disabled={isSaveVisible || isDisabled}
                    color="info"
                    size="sm"
                    type="button"
                    className={
                      'floatRight' +
                      (isSaveVisible || isDisabled ? ' btn-dark' : null)
                    }
                    onClick={() => {
                      newRow();
                    }}
                    data-testid="addNewSubCategory"
                  >
                    New Sub Category
                  </Button>
                </div>
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush ">
                  <MasterHeaderAuto headerList={headerList} />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                          !el['editMode'] ? (
                            //
                            <>
                              <Rows data={el} listToBeRendered={listToRender}>
                                <td className="text-center  white-breakSpace" >
                                  <Button
                                    color="info"
                                    size="sm"
                                    type="button"
                                    disabled={props.isViewOnly}
                                    onClick={() => editRow(index)}
                                    // data-testid="editButton"
                                  >
                                    <i className="fas fa-edit" />
                                  </Button>
                                  <Button
                                    color="info"
                                    size="sm"
                                    type="button"
                                    disabled={props.isViewOnly}
                                    onClick={() => deleteRow(index)}
                                    // data-testid="deletebtn"
                                  >
                                    <i className="fas fa-trash" />
                                  </Button>
                                </td>
                              </Rows>
                            </>
                          ) : (
                            <tr key={index + '-class'}>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid='subCategoryKey'
                                  placeholder="Auto genrated"
                                  disabled
                                  className="tdInput"
                                  defaultValue={el['subCategoryKey']}
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength="50"
                                  defaultValue={el['subCategoryDesc']}
                                  data-testid='subCategoryDesc'
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      'subCategoryDesc',
                                      true
                                    );
                                  }}
                                  placeholder="Description"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength="10"
                                  defaultValue={el['subCategoryShortDesc']}
                                  data-testid='subCategoryShortDesc'
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      'subCategoryShortDesc',
                                      true
                                    );
                                  }}
                                  placeholder="Short description"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength="10"
                                  defaultValue={el['subCategoryDispValue']}
                                  data-testid='subCategoryDispValue'
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      'subCategoryDispValue',
                                      true
                                    );
                                  }}
                                  placeholder="Display value"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  placeholder="Updated date"
                                  disabled
                                  className="tdInput"
                                  data-testid='updatedOn'
                                  defaultValue={moment(el['updatedOn']).format(
                                    'DD-MM-YYYY'
                                  )}
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <label className="custom-toggle mx-auto ml-2">
                                  <input
                                    checked={
                                      el.status == 'ACTIVE' ? true : false
                                    }
                                    type="checkbox"
                                    id={el.id}
                                    onChange={(e) => {
                                      settableDataKey(
                                        new Date().getMilliseconds()
                                      );
                                      let n = 'ACTIVE';
                                      if (!e.target.checked) {
                                        n = 'INACTIVE';
                                      }
                                      updateKey(index, n, 'status');
                                    }}
                                    data-testid='subCategoryStatus'
                                  />
                                  <span
                                    style={{ width: '72px' }}
                                    className="custom-toggle-slider rounded-circle activeToggle"
                                    data-label-off="Inactive"
                                    data-label-on="Active"
                                  />
                                </label>
                              </td>
                              <td className="text-center" data-testid="saveConcessionSubCategory">
                                {isSaveVisible ? (
                                  <>
                                    {' '}
                                    <Button
                                      color="info"
                                      size="sm"
                                      type="button"
                                      className="mx-1"
                                      onClick={() => {
                                        saveAll();
                                      }}
                                    >
                                      Save
                                    </Button>
                                    <Button
                                      color="danger"
                                      size="sm"
                                      type="button"
                                      onClick={() => {
                                        let n = props.classArray;
                                        if (n[index]['__status'] == '__new') {
                                          n.splice(index, 1);
                                        } else {
                                          // n[index]['editMode'] = false
                                          props.fetchClasses(
                                            `${masterServiceBaseUrl}/subCategory/getAllSubCategory`
                                          );
                                        }
                                        props.setclassArray(n);
                                        setisSaveVisible(false);
                                      }}
                                    >
                                      {' '}
                                      <i className="fas fa-times" />
                                    </Button>{' '}
                                  </>
                                ) : (
                                  <Button
                                    color="info"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                      deleteRow(index);
                                    }}
                                  >
                                    {' '}
                                    <i className="fas fa-trash" />
                                  </Button>
                                )}
                              </td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
              {action === 'edit' ? (
                <Button
                  className={'floatRight ml-auto m-3'}
                  color="primary"
                  size="md"
                  type="button"
                  onClick={() => {
                    setIsDisabled(!isDisabled);
                  }}
                >
                  {isDisabled ? 'Enable Form' : 'Disabled Form'}
                </Button>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default ConcessionSubCategoryTable;
