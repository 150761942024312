import React from "react";
import { Dialog } from "@mui/material";
import GetFrom from "../GetFrom";
import { ReactComponent as CrossIcon } from "../../../../../../assets/img/svg/CrossIcon.svg";
import AButton from "./../../../common/form-fields-mui/AButton";

const PlanNameDropdownPopup = ({ open = false, setOpen, plansDropdown, form, formHandler, planChangeHandler }) => {
  const handleClose = () => {
    setOpen(false);
    planChangeHandler()
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={{ padding: "1.5rem", width: "557px" }}>
        <div className="d-flex justify-content-between">
          <span className="heading-4 color-dark">Change academic plan</span>
          <CrossIcon onClick={handleClose} />
        </div>
        <div className="mt-4">
          <div>
            <GetFrom
             step={2}
             plansDropdown={plansDropdown} 
             form = {form}
             formHandler = {(value=>formHandler({plan:value}))}
            />
            <AButton 
            variant="primary_filled"
            onClick={handleClose}
            updatedStyle =  {{width:"130px", marginTop:"20px"}}
            >Submit</AButton>

          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PlanNameDropdownPopup;
