import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import CustomLoader from '../../common/commonComponents/Loader/loader';
import BusinessAreaTable from './searchBuTable';
import { PermissionContext, RolePermissions } from "appContext";
import { PermisionDenied, ReactSelectAll, AutoComplete } from "../../common/commonComponents";
import { endpoint, pages } from "../../common/constant";
import CustomButton from "components/CustomButton";
import { ButtonText } from 'variables/Buttons';
import CustomDownload from "../../common/commonComponents/CustomDownload";
import { fetchAllPostPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import CommonInput from "../../common/formFeilds/input/commonInput";
import { getPaginatedData } from "../../common/utils/methods/commonMethods/paginationMethod";

import {
  Card,
  CardHeader,
  Input,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Label
} from 'reactstrap';
import {
  masterServiceBaseUrl,
  putpost,
  getAPI
} from 'services/http';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';


const BusinessAreaSearch = () => {

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["businessAreaSearch"]['id']);

  let history = useHistory();
  const [businessAreaDetails, setBusinessAreaDetails] = useState([]);
  const [state, setState] = useState([]);
  const [apiLoader, setApiLoader] = useState(false);
  const [activeGroupCode, setActiveGroupCode] = useState([]);
  const [activeCompanyCode, setActiveCompanyCode] = useState([]);
  const [businessAreas, setBusinessAreas] = useState([]);
  const [region, setRegion] = useState([]);
  const [businessUnitType, setBusinessUnitType] = useState([]);
  const [allGroupCode, setAllGroupCode] = useState([]);
  const [allCompanyCode, setAllCompanyCode] = useState([]);
  const [allBusinessUnitType, setAllBusinessUnitType] = useState([]);
  const [allStateName, setAllStateName] = useState([]);
  const [showCompanyCode, setShowCompanyCode] = useState([]);
  const [randomKey, setRandomKey] = useState('randomKey');
  const [exportDataKey, setExportDataKey] = useState(null);
  const [descriptionArr, setDescriptionArr] = useState([]);

  const pageSize = 10;
  const [pagination, setPagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });

  const [search, setSearch] = useState({
    regionId: [],
    businessUnitTypeId: [],
    businessAreaDescription: null,
    businessAreaId: []
  });

  const [arrayState, setArrayState] = useState({
    regionArray: [],
    businessUnitTypeArray: [],
    businessAreaArray: []
  });

  useEffect(() => {
    setDescriptionArr(businessAreas.map(item => ({label: item.description, value: item.description})))
  }, [businessAreas])

  const multiSelectArrayMapping = () => {
    let x = arrayState.regionArray;
    search.regionId &&
      search.regionId.map((data) => {
        x.push(data.value);
        setArrayState({
          ...arrayState,
          regionArray: x,
        });
      });
    let y = arrayState.businessUnitTypeArray;
    search.businessUnitTypeId &&
      search.businessUnitTypeId.map((data) => {
        y.push(data.value);
        setArrayState({
          ...arrayState,
          businessUnitTypeArray: y,
        });
      });
    let z = arrayState.businessAreaArray;
    search.businessAreaId &&
      search.businessAreaId.map((data) => {
        z.push(data.value);
        setArrayState({
          ...arrayState,
          businessAreaArray: z,
        });
      });
  };

  const fetchSearchList = async (url) => {
    try {
      
      setBusinessAreaDetails([]);
      setPagination({
        currentPage: 1,
        nextPage: null,
        previousPage: null,
        totalPage: null,
      });
      setState([]);
      
      if (
        search['regionId'].length > 0 ||
        search['businessAreaId'].length > 0 ||
        search['businessUnitTypeId'].length > 0 || search['businessAreaDescription']
      ) {
        multiSelectArrayMapping();
        setApiLoader(true);

        let obj = {
          businessAreaId: arrayState.businessAreaArray,
          regionId: arrayState.regionArray,
          businessUnitTypeId: arrayState.businessUnitTypeArray,
          businessAreaDescription: search.businessAreaDescription,
        };

        putpost(
          url,
          (data) => {
            setState(data['data']);
            getPaginatedData(data.data, pageSize, pagination, setPagination, setApiLoader, setBusinessAreaDetails);
            setArrayState({ regionArray: [], businessUnitTypeArray: [], businessAreaArray: [] });
            setApiLoader(false);
          },
          (data) => {
            setArrayState({ regionArray: [], businessUnitTypeArray: [], businessAreaArray: []});
            setBusinessAreaDetails([]);
            failureToast(data['message']);
            setApiLoader(false);
          },
          obj,
          'post'
        );
     // removing this due to heavy load time 
        // callExportApi(obj);
      } else {
        failureToast('Select any one search parameters to move ahead.');
      }
    } catch (error) {
      console.log('Error', error);
    }
  };

  const callExportApi = async (values) => {
    try {
      const exportKeyData = await fetchAllPostPromisedData(`${endpoint.businessArea.exportExcel}`, values);

      if (exportKeyData.code === 200) {
        setExportDataKey(exportKeyData.data);
      } else {
        failureToast(exportKeyData.message);
      }
    } catch (e) {
      console.log('Error', e);
    }
  }

  const fetchAllData = (url, stateMethod) => {
    getAPI(
      url,
      (data) => {
        stateMethod(data.data);
      },
      (data) => {
        failureToast(data['message']);
      }
    );
  };

  const fetchData = (url, stateMethod, name) => {
    setApiLoader(true);
    getAPI(
      url,
      (data) => {
        let list = [];
        //this is for dropdown fields only
        let keyName = `${name}DispValue`;
        let desc = `${name}Desc`;

        list = data.data.map((data) => {
          // if (data.status === "ACTIVE") {
          return {
            value: data.id,
            label: data[keyName],
            description: data[desc]
          };
          // }
        });
        stateMethod(list);
        setApiLoader(false);
      },
      (data) => {
        setApiLoader(false);
        failureToast(data['message']);
      }
    );
  };

  const getDisplayValue = (id, type) => {
    try {
      switch (type) {
        case 'GroupCodeID':
          return findDisplayValue(activeGroupCode, id);
          break;
        case 'CompanyCodeID':
          return findDisplayValue(activeCompanyCode, id);
          break;
        default:
          break;
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  const findDisplayValue = (datalist, id) => {
    try {
      const findIndex = datalist.findIndex((item) => item['value'] === id);
      return findIndex >= 0 ? datalist[findIndex]['label'] : 'NOT FOUND';
    } catch (e) {
      console.log('Error', e);
    }
  };

  const getCompanyCode = (value) => {
    try {
      getAPI(
        masterServiceBaseUrl + `/companyCode/getCompanyCodeByGroup/${value}`,
        (data) => {
          let tempCompanyCode = [];
          data &&
            data.data.map((item) => {
              tempCompanyCode.push({
                value: item.id,
                label: item.companyCodeDispValue,
              });
            });
          setSearch((preState) => ({
            ...preState,
            companyCodeId: '',
          }));
          setShowCompanyCode(tempCompanyCode);
        },
        (data) => {
          setSearch((preState) => ({
            ...preState,
            companyCodeId: '',
          }));
          setShowCompanyCode([]);
          failureToast(data['message']);
        }
      );
    } catch (e) {
      console.log('Error', e);
    }
  };

  useEffect(() => {
    // document.getElementById('buttonToggler').click();

    fetchData(endpoint.businessArea.getAll, setBusinessAreas, 'businessArea')
    fetchData(masterServiceBaseUrl + '/region/getAllActiveRegion', setRegion, 'region');
    fetchData(
      masterServiceBaseUrl + '/businessUnitType/getAllActiveBusinessUnitType',
      setBusinessUnitType,
      'businessUnitType'
    );
    fetchAllData(
      masterServiceBaseUrl + '/groupCode/getAllActiveGroupCode',
      setAllGroupCode
    );
    fetchAllData(
      masterServiceBaseUrl + '/companyCode/getAllActiveCompanyCode',
      setAllCompanyCode
    );
    fetchAllData(
      masterServiceBaseUrl + '/businessUnitType/getAllActiveBusinessUnitType',
      setAllBusinessUnitType
    );
    fetchAllData(masterServiceBaseUrl + '/state/getAllActiveState', setAllStateName);
  }, []);

  const searchData = (value, property) => {
    setSearch({
      ...search,
      [property]: value,
    });
  };

  const resetSearch = () => {
    try {
      setRandomKey(new Date().getMilliseconds());
      setSearch({
        regionId: [],
        businessUnitTypeId: [],
        businessAreaId: [],
        businessAreaDescription: null,
      });
      setState([]);
      setBusinessAreaDetails([]);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    userPermissions ?
      <>
        <Container className="mt-3" fluid={true} key={randomKey}>
          <Row>
            <Col>
              <Card>
                <CardHeader className={'d-flex justify-content-between'}>
                  <h3 className="mb-0">Business Area</h3>
                  {/* <Button
                  color="info"
                  size="md"
                  type="button"
                  className={'floatRight mx-1 '}
                  onClick={() => history.push('/admin/businessUnit/new')}
                >
                  Add New Business Area
                </Button> */}
                  <CustomButton
                    className={'floatRight mx-1 '}
                    content={ButtonText.BUSINESSAREASEARCH.NEW}
                    permissionType={'C'}
                    icon={true}
                    onClick={() => history.push('/admin/businessUnit/new')}
                    permissionSet={userPermissions}
                  />
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label className="form-control-label">
                          Region
                        </Label>
                        <CommonInput
                          id="example1cols1Input"
													data={region}
													placeHolder="Region"
													type="reactSelectAll"
													onChange={(e) => searchData(e, 'regionId')}
												/>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label className="form-control-label">
                          Business Area Type
                        </Label>
                        <CommonInput
                          id="example1cols1Input"
													data={businessUnitType}
													placeHolder="Business Area Type"
													type="reactSelectAll"
													onChange={(e) => searchData(e, 'businessUnitTypeId')}
												/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label className="form-control-label">
                          Business Area
                        </Label>
                        <CommonInput
                          id="example1cols1Input"
													data={businessAreas}
													placeHolder="Business Area"
													type="reactSelectAll"
													onChange={(e) => searchData(e, 'businessAreaId')}
												/>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label className="form-control-label">
                          Description
                        </Label>
                        <CommonInput
													data={descriptionArr}
													placeHolder="Description"
													type="autoComplete"
													onChange={(val) =>
                            searchData(val, 'businessAreaDescription')
                          }
												/>
                      </FormGroup>
                    </Col>
                    <Col md="12" style={{ 'margin-top': '25px' }} className="d-flex justify-content-end align-items-center">
                      {/* <Button
                </Button>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="form-control-label">
                        Group Code
                      </Label>
                      <Select
                        value={{
                          value: search['groupCodeId'],
                          label: search['groupCodeId'] ? (
                            getDisplayValue(
                              search['groupCodeId'],
                              'GroupCodeID'
                            )
                          ) : (
                            <span className="customSpan">
                              Select Group Code
                            </span>
                          ),
                        }}
                        id="example1cols1Input"
                        options={activeGroupCode}
                        onChange={(e) => {
                          getCompanyCode(e.value);
                          searchData(e.value, 'groupCodeId');
                        }}
                      ></Select>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="form-control-label">
                        Company Code
                      </Label>
                      <Select
                        value={{
                          value: search['companyCodeId'],
                          label: search['companyCodeId'] ? (
                            getDisplayValue(
                              search['companyCodeId'],
                              'CompanyCodeID'
                            )
                          ) : (
                            <span className="customSpan">
                              Select Company Code
                            </span>
                          ),
                        }}
                        id="example1cols1Input"
                        options={showCompanyCode}
                        onChange={(e) => searchData(e.value, 'companyCodeId')}
                      ></Select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="form-control-label">
                        Region
                      </Label>
                      <Select
                        id="example1cols1Input"
                        isMulti
                        options={region}
                        onChange={(e) => searchData(e, 'regionId')}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="form-control-label">
                        Business
                        Area Type
                      </Label>
                      <Select
                        id="example1cols1Input"
                        isMulti
                        options={businessUnitType}
                        onChange={(e) => searchData(e, 'businessUnitTypeId')}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="form-control-label">
                        Business Area Key
                      </Label>
                      <Input
                        maxLength={50}
                        value={search['businessAreaDescription']}
                        className="customInput"
                        onChange={(e) =>
                          searchData(e.target.value, 'businessAreaDescription')
                        }
                        placeholder="Enter Business Area Key"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3" style={{ 'margin-top': '25px' }}>
                    <Button
                      className="btn-vimeo btn-icon w-100"
                      color="info"
                      type="button"
                      onClick={() =>
                        fetchSearchList(
                          masterServiceBaseUrl + '/businessArea/getBusinessAreaSearch'
                        )
                      }
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-search" />
                      </span>
                      <span className="btn-inner--text">Search</span>
                    </Button> */}
                      <CustomButton
                        className={'mx-1'}
                        content={ButtonText.BUSINESSAREASEARCH.SEARCH}
                        permissionType={'S'}
                        icon={true}
                        onClick={() => { fetchSearchList(endpoint.businessArea.search) }}
                        permissionSet={userPermissions}
                      />

                      <CustomButton
                        type="reset"
                        className={'mx-1 reset-button'}
                        content={ButtonText.BUSINESSAREASEARCH.RESET}
                        permissionType={'R'}
                        icon={true}
                        onClick={resetSearch}
                        permissionSet={userPermissions}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {apiLoader ? (
          <div
            className="mx-auto text-center py-5 my-5"
            style={{ height: '100vh' }}
          >
            <CustomLoader
              apiLoader={apiLoader}
              loaderHeight={'200px'}
              loaderWidth={'100%'}
            />
          </div>
        ) : !(state.length === 0) ? (
          <Container fluid={true}>
            <Row>
              <Col>
                <Card>
                  <CardHeader className="border-0">
                    <h3 className="mb-0 floatLeft">Manage Business Area</h3>
                    {exportDataKey && <CustomDownload
                      downloadKey={exportDataKey}
                      downloadUrl={endpoint.fetchS3Url.getDownloadUrl}
                      type={'exportToExcel'}
                      content={"Export to Excel"}
                      permissionSet={userPermissions}
                      outline
                      color="success"
                      isIcon={true}
                    />}
                  </CardHeader>
                  <hr />
                  <CardBody className="customCardBody">
                    <BusinessAreaTable
                      state={state}
                      setState={setState}
                      fetchSearchList={fetchSearchList}
                      pagination={pagination}
                      setPagination={setPagination}
                      businessAreaDetails={businessAreaDetails}
                      setBusinessAreaDetails={setBusinessAreaDetails}
                      allGroupCode={allGroupCode}
                      allCompanyCode={allCompanyCode}
                      allBusinessUnitType={allBusinessUnitType}
                      allStateName={allStateName}
                      userPermissions={userPermissions}
                      currentPage={pagination.currentPage}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : null}
      </> : <PermisionDenied />
  );
};

export default withRouter(BusinessAreaSearch);
