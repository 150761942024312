import React from "react";
import { BiSortAlt2 } from "react-icons/bi";
import NoRowOverlay from "views/pages/manage/masters/holidayMaster/NoRowOverlay.jsx";

export const getPsidApplicationIdObjFromRowId = (rowString) => {
  const rowData = JSON?.parse(rowString);
  return {
    psid: rowData?.psid,
    applicationId: rowData?.applicationId,
  };
};

export const gridComponents = {
  ColumnUnsortedIcon: () => <BiSortAlt2 />,
  NoRowsOverlay: () => <NoRowOverlay />,
};

export const primaryCheckboxStyles = {
  ".MuiCheckbox-root": {
    // padding: '3px',
    ":not(.Mui-disabled)": {
      color: "#00B0F5",
    },
  },
};

export const gridStyles = {
  border: "none !important",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  ".MuiDataGrid-columnHeaders": {
    // border: "none",
  },
  ".MuiDataGrid-columnHeaderTitle": {
    fontFamily: "Gilroy-Medium",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
  },
  ".MuiDataGrid-columnHeaderTitleContainer": {
    // borderBottom: "1px solid #E3E3E3",
  },
  ".MuiDataGrid-iconButtonContainer": {
    visibility: "visible",
    float: "right",
    button: {
      marginLeft: "0 !important",
    },
  },
  ".MuiDataGrid-sortIcon": {
    opacity: "inherit !important",
  },
  ...primaryCheckboxStyles,
  // '.MuiCheckbox-root:not(.Mui-disabled)': {
  //   color: '#00B0F5'
  // },
  // '.MuiDataGrid-footerContainer': {
  //   border: 'none'
  // }
};

export const pendingRfIdFilter = {
  sortingField: "REGISTRATION_DATE",
  batchType: "PRIMARY",
  rfidMappedApplication: false,
};

export const isValidPhotoKey = (photoKey) => (photoKey && photoKey?.toLowerCase() !== 'absent')