import { getCookie } from '../../views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { signal } from '../utils/helpers'

const clientId = 'PHX';
// const headers = {
//   'Content-Type': 'application/json',
//   Authorization: getCookie('token'),
//   // "clientId": clientId
// };
const get_request_params = (type = null, payload = null, headers = null,id='attendance') => {
  let requestParams = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCookie('token'),
    },
    signal
  };
  if (type) {
    requestParams['method'] = type;
  }
  if (payload) {
    requestParams['body'] = payload;
  }
  if(headers){
    requestParams['headers'] = id=='attendance'?{...headers,Authorization: getCookie('token')}:{...requestParams['headers'] , ...headers};
  }
  return requestParams;
};

export { get_request_params };
