import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
	Card,
	CardHeader,
	Input,
	Container,
	Button,
	Table,
	Row,
	Col,
} from 'reactstrap';
import {
	masterServiceBaseUrl,
	successToast,
	putpost,
	putpost2,
	getAPI,
	deleteAPI,
} from 'services/http';
import { failureToast } from '../../../../../common/utils/methods/toasterFunctions/function';
import { useHistory } from 'react-router-dom';

const LoginDetails = (props) => {
	let history = useHistory();
	const { formId, isNew } = props;
	const [apiloader, setApiLoader] = useState(false);
	const [allProgramActionReasonMapping, setProgramActionReasonMapping] =
		useState([]);

	const fetchData = (url, stateMethod) => {
		setApiLoader(true);
		getAPI(
			url,
			(data) => {
				stateMethod(data.data);
				setApiLoader(false);
			},
			(data) => {
				failureToast(data['message']);
				setApiLoader(false);
			}
		);
	};

	useEffect(() => {
		fetchData(
			masterServiceBaseUrl + '/programActionReasonMapping/getAllProgramActionReasonMapping',
			setProgramActionReasonMapping
		);
	}, []);

	const getNameById = (formId, list, n) => {

		const item = list.filter((item) => {
			return item && item.programActionMappingId == formId;
		});
		const name = item.length ? item[0][`${n}On`] : 'Not Found';;
		return moment(name).format('DD-MM-YYYY');
	};
	return (
		<Card>
			<CardHeader className="border-0">
				<h3 className="mb-0 floatLeft">Log Details</h3>
			</CardHeader>
			<div className="container-fluid">
				<Row style={{ fontSize: '15px' }}>
					<Col md={6} xs={12}>
						<Row className="mb-2">
							<Col md={6} xs={6}>
								<strong>Created Date</strong>
							</Col>
							:
							<Col data-testid="createdOn" md={5} xs={5}>
								{getNameById(formId, allProgramActionReasonMapping, 'created')}
							</Col>
						</Row>
					</Col>
					<Col md={6} xs={12}>
						<Row className="mb-2">
							<Col md={6} xs={6}>
								<strong>Last Updated Date</strong>
							</Col>
							:
							<Col data-testid="updatedOn" md={5} xs={5}>
								{getNameById(formId, allProgramActionReasonMapping, 'updated')}
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className="mt-2 pr-3 pt-2 pb-3 mr-2 pl-5">
					<Button
						color="info"
						size="sm"
						type="button"
						className={'floatRight ml-auto'}
						onClick={() => history.push('/admin/programActions')}
					>
						Close
					</Button>
				</Row>
			</div>
		</Card>
	);
};

export default LoginDetails;
