import React, { useEffect, useState } from 'react';
import { fetchAllPromisedData } from "../../common/utils/methods/commonMethods/utilityMethod";
import { getCourseDetailsByCourseId } from '../concessionApproval/approvalBranch';
import CustomTooltip from '../../common/customTooltip/CustomTooltip';

export const regionAndBusinessModal = {
  region: [],
  businessArea: []
}

export const filterFormModal = {
  studentName: '',
  psid: '',
  applicationId: '',
  opportunityId: '',
  courseId: [],
  paymentStatus: [],
  chargesStatus: [],
  term: [],
  businessArea: [],
  region: []
}
//imported in otherCharges
export const filterFormModalOtherCharge = {
  studentName: '',
  psid: '',
  applicationId: '',
  opportunityId: '',
  courseId: [],
  term: [],
  businessArea: [],
  region: []
}
//imported in otherCharges
export const getDataSetMap = (items) => {
  let result = {}
  items.map(item => result[item.value] = item.label)
  return result;
}
//imported in otherCharges
export const dropdownMapping = async (url, displayLabel, isCourse) => {
  const responseData = await fetchAllPromisedData(url, false);

  if (!displayLabel) {
    return responseData
  } else if (Array.isArray(responseData) && displayLabel === 'feature') {
    const result = responseData?.map((o) => {
      return { label: o, value: o }
    })
    return result;
  } else if (Array.isArray(responseData) && displayLabel === 'cmsCourse') {
    const result = responseData?.map((o) => {
      return { label: o.cmsSubjectName, value: o.cmsCourseId }
    })
    return result;
  } else if (Array.isArray(responseData) && displayLabel === 'businessAreaDispValue') {
    const result = responseData?.map((o) => {
      return { label: displayLabel ? o[displayLabel] : o, value: displayLabel ? o['id'] : o, regionId: o?.['regionId'] ?? [] }
    })
    return result;
  } else if (Array.isArray(responseData) && !isCourse) {
    const result = responseData?.map((o) => {
      return { label: displayLabel ? o[displayLabel] : o, value: displayLabel ? o['id'] : o }
    })
    return result;
  } else if (Array.isArray(responseData) && isCourse) {
    const result = responseData?.map((o) => {
      return { label: o['courseId'], value: Number(o['courseId']), name: o['courseName'] }
    })
    return result;
  }
  else return [];
}



export const chargesStatueDropdown = [
  { label: "PROV", value: "PROV" },
  { label: "CONC", value: "CONC" },
  { label: "VERY", value: "VERY" },
  { label: "PAPP", value: "PAPP" },
  { label: "APPL", value: "APPL" },
  { label: "MODY", value: "MODY" },
  { label: "CANC", value: "CANC" }
]

export const chargeStatusMapping = {
  PROV: "New Applications",
  CONC: "Concession application",
  VERY: "Verified",
  PAPP: "Provisionally Finalized",
  APPL: "Finalized",
  MODY: "Modified",
  CANC: "Cancelled"
}

export const paymentStatusDropdown = [
  { label: "Not Paid", value: "Not Paid" },
  { label: "Partially Paid", value: "Partially Paid" },
  { label: "Fully Paid", value: "Fully Paid" },
]

//imported in otherCharges
export const EyeIcon = ({ color }) => {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" cursor="pointer">
    <path d="M0.666992 7.99999C0.666992 7.99999 3.33366 2.66666 8.00033 2.66666C12.667 2.66666 15.3337 7.99999 15.3337 7.99999C15.3337 7.99999 12.667 13.3333 8.00033 13.3333C3.33366 13.3333 0.666992 7.99999 0.666992 7.99999Z" stroke={color ? color : "#0F0F0F"} stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" stroke={color ? color : "#0F0F0F"} stroke-linecap="round" stroke-linejoin="round" />
  </svg>
}

export const roundTo2Decimal = (num) => {
  // console.log(num)
  return parseFloat((Math.round(num * 10000) / 10000).toFixed(4));
};

export const Studenttiles = [
  {
    id: 1,
    title: "New Applications (PROV)",
    subtitle: "View all the new applications here",
    searchKey: "chargesStatus",
    searchVal: "PROV",
    countKey: "provStatusApplicationCount"
  },
  {
    id: 2,
    title: "Concession in progress",
    subtitle: "View all the application where Concession Application is progress",
    searchKey: "chargesStatus",
    searchVal: "CONC",
    countKey: "concessionStatusPendingCount"
  },
  // {
  //   id:3,
  //   title: "Pending for finalization",
  //   subtitle: "View applications that are awaiting completion",
  //   searchKey: "chargesStatus",
  //   searchVal: "",
  //   countKey: "pendingForFinalizationCount"
  // },
  {
    id: 3,
    title: "Provisionally finalised",
    subtitle: "View all the applications which are provisionally finalized",
    searchKey: "chargesStatus",
    searchVal: "PAPP",
    countKey: "provisionallyFinalisedCount"
  },
  {
    id: 4,
    title: "MODY/CMPR Applications",
    subtitle: "View all the application in MODY/CMPR status",
    searchKey: "chargesStatus",
    searchVal: "MODY",
    countKey: "modyCmprStatusCount"
  },
  {
    id: 5,
    title: "Verified Applications (VERY)",
    subtitle: "View all the verified application here",
    searchKey: "chargesStatus",
    searchVal: "VERY",
    countKey: ""
  },

  // {
  //   id:6,
  //   title: "Expiring applications",
  //   subtitle: "expire today and within the next two days",
  //   searchKey: "chargesStatus",
  //   searchVal: "",
  //   countKey: "expiringApplicationCount"
  // },
  // {
  //   id:7,
  //   title: "Action pending",
  //   subtitle: "View all the action pending items",
  //   searchKey: "chargesStatus",
  //   searchVal: "",
  //   countKey: "actionPendngCount"
  // },

]

export const CourseDetailsPopupTitle = ({ courseId }) => {

  const [courseDetails, setCourseDetails] = useState(null);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      setCourseDetails(await getCourseDetailsByCourseId(courseId));
    }

    fetchCourseDetails();
  }, [])
  return (
    courseDetails
      ? <div className='p-2 d-flex' style={{ maxWidth: '500px' }}>
        <div className='semi-bold d-flex flex-column mr-3 flex-shrink-0'>
          <div>Course ID</div>
          <div className='mt-2'>{courseDetails?.value || '-'}</div>
        </div>
        <div className='semi-bold d-flex flex-column'>
          <div >Course name</div>
          <div className='mt-2'>{courseDetails?.label || '-'}</div>
        </div>
      </div>
      : <div className='semi-bold'>Loading...</div>
  )

}


export const CourseDetailsPopupById = ({ courseId ,placement='bottom-start',maxWidth='none' }) => {

  return (
    <CustomTooltip
      title={<CourseDetailsPopupTitle courseId={courseId} />}
      showOnClick={true}
      placement = {placement}
      maxWidth = {maxWidth}
    >
      <span><EyeIcon /></span>
    </CustomTooltip>
  )

}