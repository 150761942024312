import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';

export const defaultSelectedStyles = {
    color: "#000000",
    backgroundColor: "#FFFFFF",
    border: '1px solid #000000 !important',
};

export const defaultButtonStyles = {
    color: "#0F0F0F",
    backgroundColor: "#EEEEEE",
    border: 'none',
    margin: '0px !important',
    textTransform: 'none',
    fontWeight: 600,
    height: '28px',
    fontSize: '12px',
    lineHeight: '16px',
};

export const CustomToggleButton = styled(({ customSelectedStyles, customButtonStyles, ...props }) => (
    <ToggleButton {...props} />
))(({ theme, customSelectedStyles, customButtonStyles }) => ({
    "&.Mui-selected": {
        ...defaultSelectedStyles,
        ...customSelectedStyles,
    },
    ...defaultButtonStyles,
    ...customButtonStyles,
}));

export default function AToggleButtonGroup({
    defaultSelected,
    changeHandler=()=>{},
    options,
    containerProps,
    buttonProps,
    customSelectedStyles,
    customButtonStyles,
    allowDeselect = true
}) {

    const [selected, setSelected] = React.useState(defaultSelected);

    const handleChange = (event, newValue) => {
        if(!allowDeselect && newValue === null) return;
        setSelected(newValue);
        changeHandler(newValue);
    
    };

    return (
            <ToggleButtonGroup
                value={selected}
                exclusive
                onChange={handleChange}
                aria-label='toggle button group'
                {...containerProps}
            >
                {options?.map(option =>
                    <CustomToggleButton
                        key={option.value}
                        value={option.value}
                        aria-label={option.label}
                        customSelectedStyles={customSelectedStyles}
                        customButtonStyles={customButtonStyles}
                        {...buttonProps}
                    >
                        {option.label}
                    </CustomToggleButton>
                )}
            </ToggleButtonGroup>
    );
}
