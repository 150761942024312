import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom"
import { Container, Card, CardHeader, Input, Col, Button } from "reactstrap";
import {
  pricingServiceBaseUrl,
  putpost,
} from "services/http";
import {
  successToast,
  failureToast,
} from '../../../../common/utils/methods/toasterFunctions/function';
import SearchTable from "./searchTable";
import response from "./response.json";
import CustomLoader from "../../../../common/commonComponents/Loader/loader";
import { fetchAllData, fetchAllPromisedData } from "../../../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint, pages } from "../../../../common/constant"
import { GetTemplate } from "views/pages/manage/common/commonComponents";
import { PermissionContext, RolePermissions } from "appContext";
import { PermisionDenied } from "../../../../common/commonComponents";
import CustomButton from "components/CustomButton";
import CommonInput from "../../../../common/formFeilds/input/commonInput"
import { ButtonText } from "variables/Buttons";
import {
  GetPagination,
} from "../../../../common/commonComponents/pagination/pagination";
import { SkeletonLoader } from "../../../../common/commonComponents/SkeletonLoader"


let page = 0;
let totalNumberOfRecords = 0;
let isNextCallPossible = true;

const FeeSearch = (props) => {
  const pageSize = 10;

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["processInstanceSearch"]['id']);

  const [serverData, setServerData] = useState([]);
  const [tableData, setTableData] = useState([])
  const [isFetching, setIsFetching] = useState(true);
  let [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });

  const [searchParams, setSearchParam] = useState({
    processId: "",
    status: null,
    description: "",
  });

  const statusData = [
    { label: "UPLOADED", value: "uploaded" },
    { label: "PROCESSING", value: "processing" },
    { label: "SUCCESS", value: "success" },
    { label: "FAILED", value: "failed" },
  ];

  const submitHandler = () => {
    page = 0
    if (
      !searchParams.processId &&
      !searchParams.processId.trim() &&
      !searchParams.description &&
      !searchParams.description.trim() &&
      !searchParams.status?.value
    ) {
      failureToast("Please provide atleast one search parameter from above");
      return;
    }

    setIsFetching(true)
    const searchData = {
      description: searchParams.description,
      processInstanceId: searchParams.processId || null,
      status: searchParams?.status?.value,
    };

    putpost(
      `${pricingServiceBaseUrl}/search`,
      (data) => {
        // successToast(data['message']);
        setServerData(data.data);
        setPagination(data.data);
        setIsFetching(false);
      },
      (data) => {
        setServerData([])
        failureToast(data["message"]);
        setIsFetching(false);
      },
      searchData,
      'post'
    );
  };

  const reset = () => {
    setSearchParam({
      processId: "",
      status: null,
      description: "",
    });
    page = 0;
    setServerData(null)
    getLatestedData()
  };

  const resetPagination = () => {
    pagination = {
      currentPage: 1,
      nextPage: null,
      previousPage: null,
      totalPage: null,
    }
    setpagination(pagination)
    submitHandler()
  }

  const setPagination = (data) => {
    let n = pagination;
    if (data.length > pageSize) {
      n["nextPage"] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n["totalPage"] = Math.floor(data.length / pageSize);
    if (data.length % pageSize != 0) {
      n["totalPage"] += 1;
    }
    setpagination(n);
    setTableData(
      data.slice(
        pagination.currentPage * pageSize - pageSize,
        pagination.currentPage * pageSize
      ) || []
    );
    setIsFetching(false);
    // console.log("i m state in pagination" + serverData)
  }

  const getLatestedData = async () => {
    // alert("i m called")
    // fetchAllData(`${pricingServiceBaseUrl}/getAllCourseFeeDetail/${page}`, setServerData)
    setIsFetching(true)
    const newData = await fetchAllPromisedData(`${pricingServiceBaseUrl}/getAllCourseFeeDetail/${page}`)
    if (newData && newData?.courseFeesProcessingDTO) {
      setServerData([...newData.courseFeesProcessingDTO]);
      setPagination([...newData.courseFeesProcessingDTO])
      totalNumberOfRecords = newData?.totalPages*50 ?? 0
      isNextCallPossible = newData?.courseFeeProcessingApprovalDto?.length == 50 ? true : false;
    }
    setIsFetching(false)
  }

  useEffect(() => {
    setServerData(null)
    reset()
  }, [])

  const fetchNext = async () => {
    // alert("i am called")
    setIsFetching(true)
    page = page + 1;
    const newData = await fetchAllPromisedData(`${pricingServiceBaseUrl}/getAllCourseFeeDetail/${page}`)
    if (newData && newData?.courseFeeProcessingApprovalDto && newData?.courseFeeProcessingApprovalDto?.length > 0) {
      setServerData([...serverData, ...newData.courseFeesProcessingDTO]);
      setPagination([...serverData, ...newData.courseFeesProcessingDTO])
      totalNumberOfRecords = newData?.totalPages*50 ?? 0
      isNextCallPossible = newData?.courseFeeProcessingApprovalDto.length == 50 ? true : false;
    }else{
      isNextCallPossible = false;
    }
    setIsFetching(false)
  }

  return (
    userPermissions ?
      <Container fluid className="mt-4">
        <Card>
          <CardHeader className=" d-flex justify-content-between">
            <h3 className="mb-0 ">Course Fee Upload</h3>
            <div className="d-flex flex-row justify-content-between align-items-center">
              <GetTemplate url={endpoint.pricingServiceTemplate} isSaveVisible={false} userPermissions={userPermissions} />
              <CustomButton
                type="upload"
                className={'ml-5 upload-button floatRight mx-1'}
                content={ButtonText.PROCESSINSTANCESEARCH.NEW}
                permissionType={'C'}
                icon={true}
                onClick={() => { props.history.push('/admin/uploadPricingDocument') }}
                permissionSet={userPermissions}
              />
            </div>
          </CardHeader>

          <div className="p-4">
            <div className="form-row">
              <Col className="mb-3" md="4">
                <label className="form-control-label">{`Process ID`}</label>
                <CommonInput
                  id="feedetailSearch_processID"
                  defaultValue={searchParams.processId}
                  placeHolder={`Process ID`}
                  type="number"
                  maxLength="20"
                  restrictSpecialCharacter={true}
                  style={{ height: "39px" }}
                  onChange={(e) =>
                    !isNaN(e) ?
                      setSearchParam({ ...searchParams, processId: e })
                      : setSearchParam({ ...searchParams })
                  }
                />
              </Col>
              <Col className="mb-3" md="4">
                <label className="form-control-label">{`Status`}</label>
                <CommonInput
                  type="select"
                  defaultValue={searchParams.status}
                  id="feedetailSearch_status"
                  data={statusData}
                  // isDisabled={isDisabled}
                  placeHolder="Status"
                  // isClearable={true}
                  onChange={(sData) =>
                    setSearchParam({ ...searchParams, status: sData })
                  }
                />
              </Col>
              <Col className="mb-3" md="4">
                <label className="form-control-label">{`Description`}</label>
                <CommonInput
                  defaultValue={searchParams.description}
                  placeHolder={`Description`}
                  id="feedetailSearch_description"
                  type="text"
                  maxLength="50"
                  style={{ height: "39px" }}
                  onChange={(e) => {
                    let value = e;
                    const regex = /^[A-Za-z0-9 _:/()-]*$/
                    if (regex.test(value)) {
                      setSearchParam({
                        ...searchParams,
                        description: value,
                      })
                    }
                  }
                  }
                />
              </Col>
            </div>
            <div className="d-flex justify-content-end ">
              <CustomButton
                className={'mx-1'}
                content={ButtonText.DOCUMENTMANAGEMENT.SEARCH}
                permissionType={'S'}
                icon={true}
                onClick={resetPagination}
                permissionSet={userPermissions}
              />

              <CustomButton
                type="reset"
                className={'mx-1 reset-button'}
                content={ButtonText.DOCUMENTMANAGEMENT.RESET}
                permissionType={'R'}
                icon={true}
                onClick={reset}
                permissionSet={userPermissions}
              />
            </div>
          </div>
        </Card>
        <>
          <SearchTable data={tableData} userPermissions={userPermissions} isFetching={isFetching} serverData={serverData} currentPage={pagination.currentPage} />
          {pagination.totalPage > 1 ? (
            <GetPagination
              isSaveVisible={false}
              setclassArray={setTableData}
              pagination={pagination}
              setpagination={setpagination}
              pageSize={pageSize}
              state={serverData}
              isPaginationFromServer={true}
              fetchNext={fetchNext}
              isFetching={isFetching}
              currentPage = {tableData}
              totalNumberOfRecords={totalNumberOfRecords}
              isNextCallPossible={isNextCallPossible}
            />
          ) : null}
        </>

        {/* <SearchTable data={serverData} /> */}

        {/* <span onClick = {()=>fetchNext()}>Click</span> */}

      </Container> : <PermisionDenied />
  );
};

export default withRouter(FeeSearch);
