import { reportServiceBaseUrl } from "services/http";

export const reportServiceEndpoints={
    bulkNotification:{
        getAllReports:`${reportServiceBaseUrl}/bulk-notifications/userId`,
        getChannelReports:`${reportServiceBaseUrl}/bulk-notifications`,

    },
    getAllReportCategory:`${reportServiceBaseUrl}/getAllReportCategory`,
    reportCategory:`${reportServiceBaseUrl}/reportCategory`

}