
const checkDataForRedirect = (data) => {
   //the next line is only for first prod release should be commented after that

   return true

   //only commented for first prod release
    // if ( data &&
    //     data[ 'lastNumberGeneratedDTO' ] &&
    //     data['lastNumberGeneratedDTO']['invoiceNumberSetting'].length > 0 &&
    //     data['lastNumberGeneratedDTO']['nonTaxableCourseReceiptNumberSetting'].length > 0 &&
    //     data['lastNumberGeneratedDTO']['nonTaxableCourseRefundNumberSetting'].length > 0  &&
    //     data['lastNumberGeneratedDTO']['scholarshipTransactionIDSetup'].length > 0 &&
    //     data['lastNumberGeneratedDTO']['taxableCourseReceiptNumberSetting'].length > 0 &&
    //     data['lastNumberGeneratedDTO']['taxableCourseRefundNumberSetting'].length > 0
    //     ) {
    //         console.log('redirect to old form');    
    //     return true;
    // }
    // else {
    //     console.log("redirected to new form")
    //     return false;
    // }
}

export {
    checkDataForRedirect
}