import React, {useEffect, useState} from "react";
import { Card, CardBody, CardHeader} from "reactstrap";
import moment from "moment";
import {masterServiceBaseUrl, getAPI,} from "../../../../../../services/http";
import { BsFillForwardFill } from "react-icons/bs";
import {GetPagination} from "../../../common/pagination";
import CommonnHeader from "../CommonHeader";
import SetupInstallmentsTable from "./setupInstallmentsTable";
import {fetchDetailsForHeader} from "../utils-methods";
import CustomLoader from "../../../common/commonComponents/Loader/loader";

import {connect} from "react-redux";
const SetupInstallments =(props) =>{
    // console.log('Props data in setupInstallments----------->',props)
    const {id,setupDatesId,year,courseYearId} = props.match.params;
    const [isSaveVisible,setIsSaveVisible] = useState(false);
    const [apiLoader,setApiLoader] = useState(false);
    const [previousData ,setPreviousData] = useState({
        paymentScheduleId:null,
        businessGroup :null,
        companyCode : null,
        businessAreaId : null,
        cashierOffice : null,
        courseId :null,
        courseName : null,
        term : null,
        courseStartDate : null,
        courseEndDate : null,
        lastUpdatedDate:null,
        courseDuration:null,
        courseYears:null,
        cashierOfficeKey:null
    })

    const [pagination, setpagination] = useState({
        currentPage: 1,
        nextPage: null,
        previousPage: null,
        totalPage: null
    })

    const [itemTypeDropDown,setItemTypeDropDown] = useState();
    const [businessAreaData,setBusinessAreaData] = useState();
    const pageSize=10;
    const [setupInstallmentsData,setSetupInstallmentsData] = useState()
    const [setupDatesData,setSetupDatesData] = useState({lastUpdatedDate :null, startDate:null, endDate:null, totalInstallments:null, totalAmount:null})
    const fetchSetupDates =(url,StateFunction)=>{
        getAPI(url,(data)=>{
            // console.log('Setup Dates Data------------->',data.data);
            StateFunction({
                lastUpdatedDate :moment(data.data.updatedOn).format('DD-MM-YYYY'),
                startDate:moment(data.data.startDate).format('DD-MM-YYYY'),
                endDate:moment(data.data.endDate).format('DD-MM-YYYY'),
                totalAmount:data.data.totalInstalment,
                totalInstallments:data.data.totalInstalmentCount
            })
        },(data)=>{
            console.log('Error in fething setup dates----->',data.message);
        })
    }

    const nextPage = () => {
        setSetupInstallmentsData(setupInstallmentsData.slice((pagination.currentPage + 1) * (pageSize) - pageSize, (pagination.currentPage + 1) * pageSize))
        let n = pagination;
        n['currentPage'] = n['currentPage'] + 1;
        n['previousPage'] = n.currentPage;
        setpagination(n);
    }

    const previousPage = () => {
        setSetupInstallmentsData(setupInstallmentsData.slice((pagination.currentPage-1) * (pageSize) - pageSize, (pagination.currentPage-1) *pageSize  ))
        let n = pagination
        n['currentPage'] = n['currentPage'] -1
        if(n['currentPage'] === 1){
            n['previousPage'] = null
        }else{
            n['previousPage'] = n.currentPage
        }
        setpagination(n)
    }

    const lastPage = () => {
        setApiLoader(true);
        setSetupInstallmentsData(setupInstallmentsData.slice((pagination.totalPage) * (pageSize) - pageSize, (pagination.totalPage) * pageSize))
        // console.log(pagination)
        let n = pagination
        n['currentPage'] = n.totalPage
        n['previousPage'] = n.totalPage-1
        setpagination(n)
        setApiLoader(false);
    }

    const firstPage = () => {
        setApiLoader(true);
        setSetupInstallmentsData(setupInstallmentsData.slice(1 * (pageSize) - pageSize, 1 * pageSize))
        // console.log(pagination)
        let n = pagination
        n['currentPage'] = 1
        n['previousPage'] = null
        setpagination(n)
        setApiLoader(false);
    }

    useEffect(()=>{
        fetchDetailsForHeader(masterServiceBaseUrl+'/payment/schedule/'+id,setPreviousData,'paymentSchedule');
        fetchSetupDates(masterServiceBaseUrl + '/setup/dates/'+setupDatesId,setSetupDatesData);
        fetchSetupInstallments(masterServiceBaseUrl+`/setupInstalments/getByCourseFeeYearIdAndCourseYearId?courseFeeYearId=${setupDatesId}&courseYearId=${courseYearId}`);
    },[])

    const fetchSetupInstallments =(url)=>{
        getAPI(url,(data)=>{
            if(data.data){
                // console.log('Setup Installments data------------>',data.data)
                setSetupInstallmentsData(data.data);
                fetchSetupDates(masterServiceBaseUrl + '/setup/dates/'+setupDatesId,setSetupDatesData);
            }
        },(data)=>{
            console.log('Error in fetching data----->',data.message);
        })
    }

    useEffect(()=>{
        setApiLoader(true);
        // console.log('BusinessArea Data----->',businessAreaData);
        if(businessAreaData) {
            if(businessAreaData.companyCodeId && businessAreaData.groupCodeId){
                // console.log('Group Code---------------->', businessAreaData.groupCodeId,'   ',businessAreaData.companyCodeId )
                fetchDetailsForHeader(masterServiceBaseUrl+`/itemTypeSetup/getByCompanyCodeAndGroupCode?companyCodeId=${businessAreaData.companyCodeId}&groupCodeId=${businessAreaData.groupCodeId}`,setItemTypeData,'itemTypeData')
                //all active item type
                // fetchDetailsForHeader(masterServiceBaseUrl+`/itemTypeSetup/getActiveItemTypeSetup`,setItemTypeData,'itemTypeData')
            }
        }
    },[businessAreaData])
    const [itemTypeData,setItemTypeData] = useState();
    useEffect(()=>{
        try{
            if(previousData.businessAreaId){
                fetchDetailsForHeader(masterServiceBaseUrl + '/businessArea/getBusinessArea/'+previousData.businessAreaId,setBusinessAreaData ,'businessAreaData');
            }
        }catch(e){
            console.log('Error in UseEffect----->',e)
        }
    },[previousData])

    useEffect(()=>{
        try{
            if(itemTypeData){
                let tempArray =[];
                itemTypeData.map((item)=>{
                    tempArray.push(item.itemTypeId)
                })
                setItemTypeDropDown(tempArray);
                setApiLoader(false);
            }
        }catch (e) {
            console.log('Error ----->',e);
        }
    },[itemTypeData])

    return (
        <>
            {apiLoader ?
                <div><CustomLoader apiLoader={apiLoader} loaderHeight={'200px'} loaderWidth={'100% '}/></div>
                :
             <>
                 <Card style={{margin:'15px 20px'}}>
                <CardHeader><h2 className="mb-0 floatLeft">Payment Schedule {<BsFillForwardFill style={{fontSize:'30px'}}/>} Setup Dates {<BsFillForwardFill style={{fontSize:'30px'}}/>} Setup Installments</h2></CardHeader>
            </Card>
            <Card style={{marginBottom:'12px',marginLeft:'20px',marginRight:'20px'}}>
                <CardHeader className="border-0"><h3 className="mb-0 floatLeft">Setup Installments</h3></CardHeader>
                <CardBody>
                    <CommonnHeader
                        cashier={props.headerData && props.headerData.cashierOffice ?  props.headerData.cashierOffice: 'Not Found'}
                        groupCode={props.headerData && props.headerData.groupCode ? props.headerData.groupCode:'Not Found'}
                        companyCode={props.headerData && props.headerData.companyCode? props.headerData.companyCode:'Not Found'}
                        previousData={props.headerData.previousData ? props.headerData.previousData :'' }
                        setupDatesData={setupDatesData}
                        businessArea={businessAreaData && businessAreaData.businessAreaDispValue ? businessAreaData.businessAreaDispValue:'Not Found'}
                        year={year}
                        term={props.headerData && props.headerData.term ? props.headerData.term : 'Not Found'}
                        type={'setupInstallments'}
                        courseName={props.headerData && props.headerData.previousData && props.headerData.previousData.courseName ? props.headerData.previousData.courseName :'Not Found' }
                    />
                </CardBody>
            </Card>
                <SetupInstallmentsTable
                    year={year}
                    courseYearId={courseYearId}
                    setupDatesData={setupDatesData}
                    isSaveVisible={isSaveVisible}
                    setIsSaveVisible={setIsSaveVisible}
                    previousData={previousData}
                    paymentScheduleid={id}
                    setupDatesId={setupDatesId}
                    setupInstallmentsData={setupInstallmentsData}
                    setSetupInstallmentsData={setSetupInstallmentsData}
                    itemTypeData={itemTypeData}
                    itemTypeDropDown={itemTypeDropDown}
                    fetchSetupInstallments={fetchSetupInstallments}
                />
            {pagination.totalPage>1 ?
                <GetPagination
                    pagination={pagination}
                    previousPage={previousPage}
                    firstPage={firstPage}
                    lastPage={lastPage}
                    nextPage={nextPage}
                />
                :null}</>}
        </>
    )
}
const mapStateToProps = (state) =>{
    return {
        headerData : state.globalData.data
    }
}
export default connect(mapStateToProps)(SetupInstallments);

