import React from "react";
import { TbLoader } from "react-icons/tb";

const style = {
  // position: "absolute",
  // left: "51.36%",
  // right: "45.25%",
  // top: "48.11%",
  // bottom: "47.13%",
  fontSize: "3rem",
  animation: "spin 2s linear infinite",

  /* primary_blue */

  color: "#00B0F5",
  zIndex: 100,
};
const ALoader = ({position}) => {
  return (
    <div style={{position: position || 'absolute', top: 0, left: 0, height: '100%', width: '100%', zIndex: 49, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <TbLoader style={style} />
    </div>
  );
};

export default ALoader;
