import React  from "react";
import { Dialog } from "@mui/material";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'

const IssueStockSuccessPrompt = ({
  isOpen,
  setIsOpen,
  responseData ={},
  isReturn=false,
  message,
  headerMessage,
}) => {
  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <Dialog onClose={handleCancel} open={isOpen} maxWidth="md">
      <div style={{ padding: "28px 24px", width: "800px" }}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div
            style={{
              fontWeight: 700,
              fontSize: "22px",
              lineHeight: "24px",
              color: "#000000",
              // marginBottom: "1rem",
            }}
          >
            {headerMessage ? headerMessage : isReturn ? `Stock returned successfully` : `Stock issued successfully`}
          </div>
          <IconButtonWrapper onClick={handleCancel}><CrossIcon width={30} height={30} /></IconButtonWrapper>
        </div>
        <div>{message?message:'Your transaction ID'} : {responseData?.transactionId} and SAP document ID : {responseData?.documentId}</div>
      </div>
    </Dialog>
  );
};

export default IssueStockSuccessPrompt;
