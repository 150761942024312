import { Dialog } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import CustomDatePickerV2 from 'views/pages/manage/common/commonComponents/CustomDatePicker-V2';
import AButton from 'views/pages/manage/common/form-fields-mui/AButton';
import { ReactComponent as CrossIcon } from "../../../../../../assets/img/svg/CrossIcon.svg";
import { maxDate31Days } from '../helper';
import IconButtonWrapper from '../../../common/form-fields-mui/IconButtonWrapper';

const EndDateSelectionDialog = ({ open, setOpen, setNewEndDate, formData }) => {

  const handleClose = () => setOpen(false);
  const [selectedDate, setSelectedDate] = useState('');

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm'>
      <div style={{ padding: "1.5rem", width: "370px" }} >
        <div className="d-flex justify-content-between align-items-center mb-3">
          <span className="heading-4 color-dark">Select date</span>
          <IconButtonWrapper onClick={handleClose}><CrossIcon /></IconButtonWrapper>
        </div>
        <CustomDatePickerV2
          placeHolder="Select date"
          value={selectedDate}
          handler={(i, date) => setSelectedDate(date)}
          minDate = {new Date()}
          maxDate = {maxDate31Days()}
        />
        {moment(selectedDate).isSame(moment(formData?.endOn)) && (
          <span style={{ color: 'red', fontSize: '10px' }}>New end date cannot be same as previously selected end date</span>
        )}
        <AButton 
          className='button_remove_left_margin mt-3' 
          updatedStyle={{ width: '100%' }} 
          variant='primary_filled' 
          onClick={() => { setNewEndDate(selectedDate); handleClose(); }}
          disabled={moment(selectedDate).isSame(moment(formData?.endOn))}
        >
          Save
        </AButton>
      </div>
    </Dialog>
  )
}

export default EndDateSelectionDialog