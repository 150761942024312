import React , { useState,useEffect ,useMemo} from 'react';
import { CommissionFranchisesOnlyHeader,COMMISSIONFRANCHISESONLYTHROW } from '../../../common/commonComponents/masterRow/masterRow';
import { Container, Row, Col, Button, Card, CardBody, CardHeader, Table,Input,Label} from 'reactstrap';
import Select2 from 'react-select2-wrapper';
import { GetPagination } from '../../../common/pagination';
import Loader from 'react-loader-spinner';

import { getAPI, masterServiceBaseUrl, successToast,failureToast, putpost,deleteAPI } from 'services/http'
import moment from 'moment';

const TaxRefund = (props) => {
    const {
        academicGroup,
        getDisplayValue,
        businessUnit,
        franchisesSetupId,
        taxFranchises,
        setTaxFranchises,
        action,
        isDisabled,
        isFranchise,
        taxCommissionData
    } = props;
    const [ isSaveVisible, setIsSaveVisible ] = useState(false);
    const [ dataChange, setDataChange ] = useState( false );
    const [academicGroupDescription,setAcademicGroupDescription] = useState( '' );
    const [ apiLoader, setApiLoader ] = useState( false );
    const [ commission, setCommission ] = useState( '' );
    const [ tdsPercent, setTdsPercent ] = useState( '' );
    const [pagination, setPagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null
    })
    const pageSize = 10;

    useEffect( () => {
        // console.log( "taxRefundData", taxCommissionData,action );
        if ( action && !( action === 'new' ) ) {
            // console.log('inside the use Effect')
            setTaxFranchises( taxCommissionData );           
        }
        // console.log('actionaction',action,isDisabled,isFranchise,isSaveVisible)
        //fetchTaxFranchises( masterServiceBaseUrl + `/businessAreaAndUnitFranchiseeCommissionTdsSetup/getBusinessAreaAndUnitFranchiseeCommissionTdsSetupByBusinessArea/${ businessUnit }` );
    }, [action] );

    
    const fetchTaxFranchises = async ( url ) => {
        setApiLoader( true );
        getAPI(url, (data) => {
        // console.log("data from server",data['data'])
        setTaxFranchises( data[ 'data' ] )
        let n = pagination
        if (data['data'].length > pageSize) {
            n['nextPage'] = n.currentPage + 1
        }
        if (n.currentPage > 1) {
            n.previousPage = n.currentPage - 1
        }
        n['totalPage'] =Math.floor( data['data'].length / pageSize)
        if(data['data'].length%pageSize!=0){
            n['totalPage']+=1
        }
        setPagination(n)
        // console.log(n)
        setTaxFranchises(data['data'].slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || [])
        setApiLoader( false );
    }, ( data ) => { failureToast( data[ 'message' ] );setApiLoader( false );} )
    }
 
    const nextPage = () => {
        setTaxFranchises(taxFranchises.slice((pagination.currentPage + 1) * (pageSize) - pageSize, (pagination.currentPage + 1) * pageSize))
        // console.log(pagination)
        let n = pagination
        n['currentPage'] = n['currentPage'] + 1
        n['previousPage'] = n.currentPage
        setPagination(n)
    }
    const firstPage = () => {
        setTaxFranchises(taxFranchises.slice(1 * (pageSize) - pageSize, 1 * pageSize))
        // console.log(pagination)
        let n = pagination
        n['currentPage'] = 1
        n['previousPage'] = null
        setPagination(n)
    }
    const lastPage = () => {
        setTaxFranchises(taxFranchises.slice((pagination.totalPage) * (pageSize) - pageSize, (pagination.totalPage) * pageSize))
        // console.log(pagination)
        let n = pagination
        n['currentPage'] = n.totalPage
        n['previousPage'] = n.totalPage-1
        setPagination(n)
    }
    const previousPage = () => {
        setTaxFranchises(taxFranchises.slice((pagination.currentPage-1) * (pageSize) - pageSize, (pagination.currentPage-1) *pageSize  ))
        // console.log(pagination)
        let n = pagination
        n['currentPage'] = n['currentPage'] -1
        if(n['currentPage']==1){
        n['previousPage'] = null
        }else{
        n['previousPage'] = n.currentPage
        }
        setPagination(n)
    }
    
    const addNewRow = () => {
        // console.log("franchisesSetupId",taxFranchises,action)
        try {            
            if(taxFranchises){
            setTaxFranchises([                
                {
                    __status: "new",
                    editMode: true,
                    franchiseeSetupMasterId: franchisesSetupId,
                    academicGroupKeyId: null,
                    commissionPercent: null,
                    tdsPercent: null,
                    uploadAgreementUrl: null,
                    status: "ACTIVE"
                },...taxFranchises] );
            }else{
                setTaxFranchises([                
                    {
                        __status: "new",
                        editMode: true,
                        franchiseeSetupMasterId: franchisesSetupId,
                        academicGroupKeyId: null,
                        commissionPercent: null,
                        tdsPercent: null,
                        uploadAgreementUrl: null,
                        status: "ACTIVE"
                    }] );
            }
            setIsSaveVisible( true );
            

        } catch ( e ) {
            console.log("Error",e)
        }
    }

   

    const saveAll = (index) => {
        try {
            if ( !taxFranchises[ index ]['franchiseeSetupMasterId'] )  return failureToast("Franchisee Setup Master Id not found")
            if ( !taxFranchises[ index ]['academicGroupKeyId'] )  return failureToast("Please Select Academic Group Key")
            if ( !taxFranchises[ index ]['commissionPercent'] )  return failureToast("Please Enter Commission Percent")
            if ( !taxFranchises[ index ]['tdsPercent'] )  return failureToast("Please Select Tds Percent")
           
            if ( taxFranchises[ index ][ '__status' ] === "new" ) {
                setApiLoader(true)
                putpost(masterServiceBaseUrl + '/businessAreaAndUnitFranchiseeCommissionTdsSetup/createBusinessAreaAndUnitFranchiseeCommissionTdsSetup', (data) => {
                    successToast( data[ 'message' ] );
                    fetchTaxFranchises( masterServiceBaseUrl + `/businessAreaAndUnitFranchiseeCommissionTdsSetup/getBusinessAreaAndUnitFranchiseeCommissionTdsSetupByBusinessArea/${businessUnit}` );
                    setIsSaveVisible( false );
                    setAcademicGroupDescription( '' );
                    setApiLoader( false );
                    setCommission( '' );
                    setTdsPercent('');
                }, ( data ) => {
                    failureToast( data[ 'message' ] );
                    setApiLoader( false );
                }, taxFranchises[ index ], 'post' )
               
            } else {
                setApiLoader(true)
                 putpost(masterServiceBaseUrl + `/businessAreaAndUnitFranchiseeCommissionTdsSetup/updateBusinessAreaAndUnitFranchiseeCommissionTdsSetup/${taxFranchises[index]['id']}`, (data) => {
                     successToast( data[ 'message' ] );
                     fetchTaxFranchises( masterServiceBaseUrl + `/businessAreaAndUnitFranchiseeCommissionTdsSetup/getBusinessAreaAndUnitFranchiseeCommissionTdsSetupByBusinessArea/${businessUnit}` );
                     setIsSaveVisible( false );
                     setAcademicGroupDescription( '' );
                     setApiLoader( false );
                     setCommission( '' );
                     setTdsPercent('');
                }, ( data ) => {
                     failureToast( data[ 'message' ] );
                     setApiLoader( false );
                }, taxFranchises[ index ], 'put' )
               
            }

            
        } catch ( e ) {
            console.log("Error",e)
        }
    }

    const updateRecord=( value,index,property )=>{
        try {
            // console.log(value)
            setApiLoader( true );
            let temp = taxFranchises;
            temp[ index ][ property ] = value;
            if ( property === "academicGroupKeyId" ) {                
                setAcademicGroupDescription( getDisplayValue( value, "academicGroupKeyIdDesc" ) );
            }
            setTaxFranchises( temp );
            setApiLoader( false );
        } catch ( e ) {
            console.log( "Error", e );
        }
    }

    const closeRecord = ( index ) => {
        try {
            setApiLoader( true );
            let temp = taxFranchises;
            if ( temp[ index ][ '__status' ] === "new" ) {
                temp.splice( index, 1 )
            } else {
                delete temp[index]['editMode']
            }
            setTaxFranchises( temp );
            setIsSaveVisible( false );
            setApiLoader( false );
        } catch ( e ) {
            console.log( "Error", e );
        }
        
    }

    const editRecord = (index) => {
        try {
            let temp = taxFranchises;
            temp[ index ][ 'editMode' ] = true;
            setCommission( temp[ index ][ 'commissionPercent' ] );
            setTdsPercent(temp[ index ][ 'tdsPercent' ] )
            setAcademicGroupDescription( getDisplayValue( temp[ index ]['academicGroupKeyId'], "academicGroupKeyIdDesc" ) );
            setTaxFranchises( temp );
            setIsSaveVisible( true );
        } catch ( e ) {
            console.log("Error : ",e)
        }
    }

    const deleteRecord = (id,index) => {
        try {            
             if (window.confirm('Are you sure you want to delete this Commission Franchises ?')) {
            deleteAPI(masterServiceBaseUrl + `/businessAreaAndUnitFranchiseeCommissionTdsSetup/deleteBusinessAreaAndUnitFranchiseeCommissionTdsSetup/${id}`, (data) => {
            successToast( data[ 'message' ] )
            // let _tempArray = taxFranchises;
            // let __tempArray = taxFranchises;
            // _tempArray.splice( index + ( pagination.currentPage > 1 ? pagination.currentPage - 1 * 10: index), 1 );
            // __tempArray.splice( index, 1 );
            // setTaxFranchises( _tempArray );
            // setTaxFranchises( __tempArray );
            setDataChange( !dataChange );
            fetchTaxFranchises(`${masterServiceBaseUrl}/businessAreaAndUnitFranchiseeCommissionTdsSetup/getBusinessAreaAndUnitFranchiseeCommissionTdsSetupByBusinessArea/${props.formId}`)
          }, (data) => { failureToast(data['message']) })}
        } catch ( e ) {
            console.log("Error",e)
        }
    }
    
    const validate = (type, value) => {        
        if ( type === "tax" ) {      
        if ( value === "" || !isNaN(value) && !/^\d+\.\d{3,}$/.test( value ) ) {           
            return true;
            }
        }

        
    };

    useEffect(()=>{
        if(props.isPrevious && props.formId && props.isFranchise){
            // console.log("i m here this is commision")
            setApiLoader(true)
            fetchTaxFranchises(`${masterServiceBaseUrl}/businessAreaAndUnitFranchiseeCommissionTdsSetup/getBusinessAreaAndUnitFranchiseeCommissionTdsSetupByBusinessArea/${props.formId}`)
            // getAPI(
            //     `${masterServiceBaseUrl}/businessAreaAndUnitFranchiseeCommissionTdsSetup/getBusinessAreaAndUnitFranchiseeCommissionTdsSetup/${props.formId}`,
            //     (data) => {
            //         setTaxFranchises([...data.data]);   
            //         setApiLoader(false)   
            //     },
            //     (data) => {
            //       failureToast(data['message']);
            //       setApiLoader(false)   
            //     }
            //   );
        }
    },[props.isPrevious,props.formId,props.isFranchise])

    useEffect(()=>{
        return()=>{
            if(taxFranchises.length){
                !props.isPrevious && props.isNew && props.saveStepsCompleted()
            }          
        }    
      },[taxCommissionData])

    return (
       <Container className="mt-3 customContainer">
            <Row>
                <Col className={action === 'edit' ? 'mb-3 mt-3' :'mb-3'}>
                    { ( action === 'new' ) || !isDisabled ? <Button
                        disabled={ ( isSaveVisible || !isFranchise ) }
                        color="info" size="sm" type="button" className={ "floatRight mx-1 mr-4" + ( isSaveVisible  ? " btn-dark" : null ) } onClick={ () => { addNewRow() } } >Add TDS % Setup</Button> : null }
                </Col>
            </Row>
                { apiLoader?
                    <div className="mx-auto text-center"  style={ { height: "100px" } }>
                            <Loader type="Rings" color="#00BFFF" height={ 70 } width={ 70 } />
                    </div> :
                    <div id="questionTable" className="table-responsive">                        
                        <Table className="align-items-center table-flush tableLayout" style={{minWidth:'1200px'}} >
                        { taxFranchises && taxFranchises.length > 0 ? <CommissionFranchisesOnlyHeader isDisabled={ isDisabled }/> :'' }
                            <tbody className="list" >
                                {
                                  taxFranchises &&  taxFranchises.map( ( item, index ) =>
                                        item[ 'editMode' ] ? (
                                            <tr key={ index }>
                                                <td className="text-center p-2 mx-1">
                                                    <Select2 className="input-height"
                                                        options={ { placeholder: "Select..." } }
                                                        value={ item.academicGroupKeyId }
                                                        onChange={ ( e ) => {
                                                            let n = e.target.value;
                                                            updateRecord( n, index, "academicGroupKeyId" )
                                                        } }
                                                        data={ academicGroup }
                                                    />
                                                </td>
                                                <td className="text-center p-2 mx-1">
                                                    <Label className="customLabel  white-breakSpace" style={{height: '100%'}}>{ academicGroupDescription }</Label>
                                                </td>
                                                <td className="text-center p-2 mx-1">
                                                    <Input  className="tdInput" disabled={true} value={moment().format('DD-MM-YYYY')} />
                                                </td>
                                                <td className="text-center p-2 mx-1">
                                                    <Input className="tdInput"
                                                        value={ commission }
                                                        maxLength={6}
                                                        onChange={ ( e ) => {
                                                            let n = e.target.value;
                                                            if ( validate( "tax", n ) ) {
                                                                setCommission(n);
                                                                updateRecord( n, index, "commissionPercent" );                                                              
                                                            }                                                         
                                                        }}
                                                    />
                                                </td>
                                                <td className="text-center p-2 mx-1">
                                                    <Input className="tdInput"
                                                         maxLength={6}
                                                        value={tdsPercent}
                                                        onChange={ (e) => {
                                                            let n = e.target.value;
                                                            if ( validate( "tax", n ) ) {
                                                                setTdsPercent(n);
                                                                updateRecord( n, index, "tdsPercent" )
                                                            }
                                                        }}
                                                   />
                                                </td>
                                                <td className="text-center p-2 mx-1">
                                                    <Button size="sm" color="info"> Upload Document</Button>
                                                </td>
                                                <td className="text-center p-2 mx-1">
                                                    <Label className="custom-toggle mx-auto ml-2">
                                                        <Input checked={ item.status == "ACTIVE" ? true : false } type="checkbox"
                                                            onChange={ ( e ) => {
                                                                let n = "ACTIVE";
                                                                if ( !e.target.checked ) {
                                                                    n = "INACTIVE"
                                                                }
                                                                updateRecord( n, index, "status" )
                                                                setDataChange( !dataChange );
                                                            } } />
                                                        <span style={ { width: "72px" } } className="custom-toggle-slider rounded-circle activeToggle" data-label-off="Inactive" data-label-on="Active" />
                                                    </Label>
                                                </td>
                                                <td>
                                                    <Button size="sm" color="info" onClick={ () => saveAll( index ) }>Save</Button>
                                                    <Button size="sm" color="danger" onClick={ () => closeRecord( index ) }><i className="fas fa-times" /></Button>
                                                </td>
                                            </tr>
                                        ) : ( <COMMISSIONFRANCHISESONLYTHROW isDisabled={ isDisabled } key={ index + "_class" } deleteRecord={ deleteRecord } getDisplayValue={ getDisplayValue } item={ item } editRecord={ editRecord } index={ index } closeRecord={ closeRecord } /> )
                                    )
                                }
                            </tbody>
                        
                        </Table>
                    
                    {pagination.totalPage>1?<GetPagination fetchClasses={taxFranchises} pagination={pagination} previousPage={previousPage} firstPage={firstPage} lastPage={lastPage} nextPage={nextPage} setpagination={setPagination} />:null}
                </div>
                }
                
        </Container>
    )
}

export default TaxRefund;