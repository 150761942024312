export const STUDENT_LIST_REQUEST = 'STUDENT_LIST_REQUEST';
export const STUDENT_LIST_SUCCESS = 'STUDENT_LIST_SUCCESS';
export const STUDENT_LIST_FAILURE = 'STUDENT_LIST_FAILURE';
export const BATCH_DETAILS_REQUEST = 'BATCH_DETAILS_REQUEST';
export const BATCH_DETAILS_SUCCESS = 'BATCH_DETAILS_SUCCESS';
export const BATCH_DETAILS_FAILURE = 'BATCH_DETAILS_FAILURE';
export const FETCH_DROPDOWN_SUCCESS = 'FETCH_DROPDOWN_SUCCESS';
export const FETCH_DROPDOWN_FAILURE = 'FETCH_DROPDOWN_FAILURE';
export const CLEAR_BATCH_DETAILS_STATE = 'CLEAR_BATCH_DETAILS_STATE';
export const START_DOWNLOAD_LOADER = 'START_DOWNLOAD_LOADER';
export const STOP_DOWNLOAD_LOADER = 'STOP_DOWNLOAD_LOADER';