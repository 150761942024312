import React from "react";
import { ReactComponent as Cross } from "../../../../../assets/img/svg/Union.svg";
import { Tooltip } from "@mui/material";

const CustomChip = ({ text, onDelete, className='' }) => {
  return (
    <div className={`student-details__chip ${className}`}>
      {text}
      <Cross onClick={onDelete} />
    </div>
  );
};

export const CustomChipWithDot = ({ text, onDelete }) => {
  return (
    <div className="student-details__chip mb-2">
      <div
        className="ellipsis-text-container"
      >
          {text}
      </div>
      <Cross onClick={onDelete} />
    </div>
  );
};

export default CustomChip;
