import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, Col, Row } from 'reactstrap'
import AButton from '../../common/form-fields-mui/AButton'
// import { ASelect } from '../../common/form-fields-mui/ASelect'
import styles from './common/facultyRoasterList.module.css'
import AAutoComplete from '../../common/form-fields-mui/AAutoComplete'
// import { getFilterCount } from '../constant/search-academic-plan'
// import { fetchAllPostPromisedData, fetchAllPromisedData, getDropdownIdsFromSelectArray } from '../../common/utils/methods/commonMethods/utilityMethod'
// import { endpoint } from '../../common/constant'
// import { fetchBusinessAreasByRegions } from '../constant/common'

const FacultyRoasterFilterForm = ({ 
  closeFilterForm, 
  removeFilterHandler = () => { }, 
  applyFilterHandler = () => { }, 
  inputHandler = () => { }, 
  filterForm, 
  filterCount
}) => {

  const dropdownData = useSelector(state => state?.facultyRoaster?.dropdowns)

  return (
    <Card className='mb-0' style={{ width: '35vw' }}>
      <CardBody>
        <div style={{ color: '#414141', fontSize: '16px', fontFamily: 'Gilroy-Medium' }}>Filter</div>
        <Row>
          <Col className='mb-2' sm='6'>
            <AAutoComplete
              label='Business area'
              currentValue={filterForm?.businessArea}
              items={dropdownData?.businessArea}
              handleChange={(value) => inputHandler(value,"businessArea")}
              isShowCount={true}
            />
          </Col>
          <Col className='mb-2' sm='6'>
            <AAutoComplete
              label='Academic career'
              currentValue={filterForm?.academicCareer}
              items={dropdownData?.academicCareer}
              handleChange={(value) => inputHandler(value, "academicCareer")}
              isShowCount={true}
            />
          </Col>
          <Col className='mb-2' sm='6'>
            <AAutoComplete
              label='Academic group'
              currentValue={filterForm?.academicGroup}
              items={dropdownData?.academicGroup}
              handleChange={(value) => inputHandler(value, "academicGroup")}
              isShowCount={true}
            />
          </Col>
          <Col className='mb-2' sm='6'>
            <AAutoComplete
              label='Subject'
              currentValue={filterForm?.subject}
              items={dropdownData?.subject}
              handleChange={(value) => inputHandler(value, "subject")}
              isShowCount={true}
            />
          </Col>
          {/* <Col className='mb-2' sm='6'>
            <AAutoComplete
              label='Phase'
              currentValue={filterForm?.academicPhase}
              items={dropdownData?.academicPhase}
              handleChange={(value) => inputHandler(value, "academicPhase")}
              isShowCount={true}
            />
          </Col>
          <Col className='mb-2' sm='6'>
            <AAutoComplete
              label='Class type'
              currentValue={filterForm?.classType}
              items={dropdownData?.classType}
              // handleChange={(value) => inputHandler(value, "classType")}
              isShowCount={true}
            />
          </Col>
          <Col className='mb-2' sm='6'>
            <AAutoComplete
              label='Region'
              currentValue={filterForm?.region}
              items={dropdownData?.region}
              // handleChange={(value) => { inputHandler(value, "region"); fetchBusinessArea(value); inputHandler([], "businessArea") }}
              isShowCount={true}
            />
          </Col>
          <Col className='mb-2' sm='6'>
            <AAutoComplete
              label='Business area'
              currentValue={filterForm?.businessArea}
              items={businessAreaData}
              handleChange={(value) => inputHandler(value, "businessArea")}
              isShowCount={true}
              isLoading={businessAreaLoader}
            />
          </Col>
          <Col className='mb-2' sm='6'>
            <AAutoComplete
              label='Published status'
              currentValue={filterForm?.publishStatus}
              items={dropdownData?.publishStatus}
              handleChange={(value) => inputHandler(value, "publishStatus")}
              isShowCount={true}
            />
          </Col> */}
        </Row>
        <Row>
          {filterCount>0 && (
            <Col>
              <AButton
                className={styles.buttonCustomStyles}
                onClick={removeFilterHandler}
                updatedStyle={{
                  border: "1px solid #CC5F5F",
                  borderRadius: "12px",
                  color: "#CC5F5F",
                }}
              >
                Remove Filter
              </AButton>
            </Col>
          )}
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <AButton
              onClick={() => { applyFilterHandler(); closeFilterForm(); }}
              updatedStyle={{
                background: "#00B0F5",
                border: "1px solid #00B0F5",
                borderRadius: "12px",
                color: "#fff"
              }}
            >
              Apply Filter
            </AButton>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default FacultyRoasterFilterForm