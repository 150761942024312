import React, { useState } from 'react'
import APopover from '../../a-popover/index'
import { ReactComponent as CloseIcon } from '../../../../../../assets/img/svg/CrossIcon.svg'
import { Card, CardBody } from 'reactstrap';
import AInput from 'views/pages/manage/common/formFeilds/customInput/AInput'
import CustomChip from 'views/pages/manage/common/customChip/CustomChip'
import { Tooltip } from '@mui/material';
import { AiOutlineEye } from "react-icons/ai";
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'

const ViewMoreForm = ({ data, title, closeForm, width }) => {

  const containerStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    overflowY: 'auto'
  }

  const [userInput, setUserInput] = useState('');
  const filteredData = data?.filter(item => (item?.label?.toLowerCase()?.includes(userInput?.toLowerCase())))

  return (
    <Card className='mb-0' style={{ position: 'relative', width: width || '350px', maxHeight: '350px' }}>
      <CardBody className='d-flex flex-column'>
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <div className='heading-4' style={{ color: '#414141' }}>{title}</div>
          <IconButtonWrapper onClick={closeForm}><CloseIcon /></IconButtonWrapper>
        </div>
        <div className={`mb-3`}>
          <AInput
            className=''
            value={userInput}
            handler={(value) => setUserInput(value)}
            crossIcon={false}
            searchIcon={true}
            searchIconClickHandler={() => { }}
            placeholder={'Search'}
            style={{ borderRadius: '8px', padding: '8px 16px', height: '45px', borderColor: '#CCCCCC', fontSize: '16px', paddingLeft: '16px', paddingRight: '36px' }}
            customSearchIconStyle={{ right: '16px', left: 'unset' }}
          />
        </div>
        {!filteredData?.length && <div className='color-grey-60 regular-bold'>No results found</div>}
        <div style={containerStyles}>
          {filteredData?.map(data => <CustomChip key={data?.value} text={data?.label} bgColor='#e9ecef' />)}
        </div>
        <div className='mt-3 color-grey-60 regular-bold'>Total {data?.length} results</div>
      </CardBody>
    </Card>
  )
}

const MultiSelectCellComponentWithSearch = ({ data, title, eyeIcon = false, width }) => {
  return (
    !data?.length ? (
      <div>-</div>
    ) : (
      <div className='d-flex ellipsis-text-container align-items-center'>
        <Tooltip title={data?.[0]?.label}>
          <div className='ellipsis-text-container'>{data?.[0]?.label}</div>
        </Tooltip>
        {data?.length > 1 && (
          <APopover
            buttonComponent={(onClick) => eyeIcon ? (
              <div className='ml-1 d-flex align-items-center'>{`+ ${data?.length - 1}`}<AiOutlineEye onClick={onClick} className='pointer color-primary ml-1' size={'1.2em'} /></div>
            ) : (
              <span className='color-primary pointer ml-1' onClick={onClick}>+ {data?.length - 1} more</span>
            )}
            menuComponent={(closeForm) => <ViewMoreForm data={data} title={title} closeForm={closeForm} width={width} />}
          />
        )}
      </div >
    )
  )
}

export default MultiSelectCellComponentWithSearch