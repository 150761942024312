import React, {useState, useEffect} from "react";
import {
  Container,
  Row,
  Button,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select";
import GlCodeDetails from "./GlCode";
import {
  getAPI,
  masterServiceBaseUrl,
  putpost,
  failureToast,
} from "services/http";
import {useHistory} from "react-router-dom";
import Loader from "react-loader-spinner";

const SearchHouseBank = () => {
  let history = useHistory();
  const [apiLoader, setApiLoader] = useState(false);
  const [glCodeArray, setGlCodeArray] = useState([]);
  const [activeGroupCode, setActiveGroupCode] = useState([]);
  const [activeCompanyCode, setActiveCompanyCode] = useState([]);
  const [showCompanyCode, setShowCompanyCode] = useState([]);
  const [activeCountry, setActiveCountry] = useState([]);
  const [searchDataList, setSearchDataList] = useState([]);
  const [activeCurrency, setActiveCurrency] = useState([]);
  const [glCodeValue, setGlCodeValue] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const [search, setSearch] = useState({
    companyCodeId: null,
    glCode: null,
    glDescription: "",
    groupCodeId: null,
  });

  useEffect(() => {
    fetchHouseBankDetails(
      masterServiceBaseUrl + "/groupCode/getAllActiveGroupCode",
      setActiveGroupCode,
      "GroupCode"
    );
    fetchHouseBankDetails(
      masterServiceBaseUrl + "/companyCode/getAllActiveCompanyCode",
      setActiveCompanyCode,
      "CompanyCode"
    );
    fetchHouseBankDetails(
      masterServiceBaseUrl + "/currency/getAllActiveCurrency",
      setActiveCurrency,
      "CurrencyID"
    );
    fetchHouseBankDetails(
      masterServiceBaseUrl + "/country/getAllActiveCountry",
      setActiveCountry,
      "Country"
    );
  }, []);

  const pageSize = 10;
  const fetchSearchList = async (url) => {
    try {
      if (!search["groupCodeId"]) return failureToast("Select Group Code");
      if (!search["companyCodeId"]) return failureToast("Select Company Code");
      setApiLoader(true);
      putpost(
        url,
        (data) => {
          setSearchDataList(data["data"]);
          let n = pagination;
          if (data["data"].length > pageSize) {
            n["nextPage"] = n.currentPage + 1;
          }
          if (n.currentPage > 1) {
            n.previousPage = n.currentPage - 1;
          }
          n["totalPage"] = Math.floor(data["data"].length / pageSize);
          if (data["data"].length % pageSize != 0) {
            n["totalPage"] += 1;
          }
          setPagination(n);
          setGlCodeArray(
            data["data"].slice(
              pagination.currentPage * pageSize - pageSize,
              pagination.currentPage * pageSize
            ) || []
          );
          setApiLoader(false);
        },
        (data) => {
          failureToast(data["message"]);
          setApiLoader(false);
          setSearchDataList("");
        },
        search,
        "post"
      );
    } catch (e) {
      console.log("Error", e);
    }
  };
  const nextPage = () => {
    try {
      setGlCodeArray(
        searchDataList.slice(
          (pagination.currentPage + 1) * pageSize - pageSize,
          (pagination.currentPage + 1) * pageSize
        )
      );
      let n = pagination;
      n["currentPage"] = n["currentPage"] + 1;
      n["previousPage"] = n.currentPage;
      setPagination(n);
    } catch (e) {
      console.log("Error", e);
    }
  };
  const firstPage = () => {
    try {
      setGlCodeArray(
        searchDataList.slice(1 * pageSize - pageSize, 1 * pageSize)
      );
      let n = pagination;
      n["currentPage"] = 1;
      n["previousPage"] = null;
      setPagination(n);
    } catch (e) {
      console.log("Error", e);
    }
  };
  const lastPage = () => {
    try {
      setGlCodeArray(
        searchDataList.slice(
          pagination.totalPage * pageSize - pageSize,
          pagination.totalPage * pageSize
        )
      );
      let n = pagination;
      n["currentPage"] = n.totalPage;
      n["previousPage"] = n.totalPage - 1;
      setPagination(n);
    } catch (e) {
      console.log("Error", e);
    }
  };
  const previousPage = () => {
    try {
      setGlCodeArray(
        searchDataList.slice(
          (pagination.currentPage - 1) * pageSize - pageSize,
          (pagination.currentPage - 1) * pageSize
        )
      );
      let n = pagination;
      n["currentPage"] = n["currentPage"] - 1;
      if (n["currentPage"] == 1) {
        n["previousPage"] = null;
      } else {
        n["previousPage"] = n.currentPage;
      }
      setPagination(n);
    } catch (e) {
      console.log("Error", e);
    }
  };

  const fetchHouseBankDetails = (url, stateMethod, type) => {
    try {
      getAPI(
        url,
        (data) => {
          refactorData(data["data"], stateMethod, type);
        },
        (data) => {
          failureToast(data["message"]);
        }
      );
    } catch (e) {
      console.log("Error", e);
    }
  };

  const refactorData = (data, stateMethod, type) => {
    try {
      switch (type) {
        case "GroupCode":
          iterateData(data, "id", "groupCodeDispValue", stateMethod);
          break;
        case "CompanyCode":
          iterateData(data, "id", "companyCodeDispValue", stateMethod);
          break;
        case "CurrencyID":
          iterateData(data, "id", "currencyDispValue", stateMethod);
          break;
        case "Country":
          iterateData(data, "id", "countryDispValue", stateMethod);
          break;
        case "HouseBank":
          stateMethod(data);
          break;
        default:
          break;
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const iterateData = (data, id, property, stateMethod) => {
    try {
      let temp = [];
      data.map((item) => {
        temp.push({
          value: item[id],
          label: item[property],
        });
      });
      stateMethod(temp);
    } catch (e) {
      console.log("Error", e);
    }
  };

  const getDisplayValue = (id, type) => {
    try {
      switch (type) {
        case "GroupCodeID":
          return findDisplayValue(activeGroupCode, id);
          break;
        case "CompanyCodeID":
          return findDisplayValue(activeCompanyCode, id);
          break;
        case "CurrencyID":
          return findDisplayValue(activeCurrency, id);
          break;
        case "CountryID":
          return findDisplayValue(activeCountry, id);
          break;
        default:
          break;
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const findDisplayValue = (datalist, id) => {
    try {
      const findIndex = datalist.findIndex((item) => item["value"] === id);
      return findIndex >= 0 ? datalist[findIndex]["label"] : "Not Exist";
    } catch (e) {
      console.log("Error", e);
    }
  };

  const searchData = (value, property) => {
    setSearch({
      ...search,
      [property]: value,
    });
  };

  const resetSearch = () => {
    try {
      setSearch({
        companyCodeId: null,
        glCode: null,
        glDescription: "",
        groupCodeId: null,
      });
      setGlCodeValue("");
      setSearchDataList([]);
    } catch (e) {
      console.log(e);
    }
  };

  const getCompanyCode = (value) => {
    try {
      getAPI(
        masterServiceBaseUrl + `/companyCode/getCompanyCodeByGroup/${value}`,
        (data) => {
          let tempCompanyCode = [];
          data &&
          data.data.map((item) => {
            tempCompanyCode.push({
              value: item.id,
              label: item.companyCodeDispValue,
            });
          });
          setSearch((preState) => ({
            ...preState,
            companyCodeId: "",
          }));
          setShowCompanyCode(tempCompanyCode);
        },
        (data) => {
          setSearch((preState) => ({
            ...preState,
            companyCodeId: "",
          }));
          setShowCompanyCode([]);
          failureToast(data["message"]);
        }
      );
    } catch (e) {
      console.log("Error", e);
    }
  };

  const validate = (type, value) => {
    const numberValidation = /^[0-9\b]+$/;
    if (type === "number") {
      if (value === "" || numberValidation.test(value)) {
        return true;
      }
    }
  };
  return (
    <form data-testid="form">
      <Container className="mt-3" fluid={true}>
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-0">
                <h3
                  className="mb-0 floatLeft headingColor"
                  data-testid="glHeading"
                >
                  Search GL Code
                </h3>
                <Button
                  data-testid="addNewGL"
                  color="info"
                  size="sm"
                  type="button"
                  className="floatRight"
                  onClick={() => history.push("/admin/GLCode/new")}
                >
                  Add New GL Code
                </Button>
              </CardHeader>
              <hr/>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="groupCodeId" data-testid="code" className="form-control-label">
                        Group Code
                      </Label>
                      <Select
                        name="groupCodeId"
                        inputId="groupCodeId"
                        data-testid="groupCodeId"
                        value={{
                          value: search["groupCodeId"],
                          label: search["groupCodeId"] ? (
                            getDisplayValue(
                              search["groupCodeId"],
                              "GroupCodeID"
                            )
                          ) : (
                            <span className="customSpan">
                              Select Group Code
                            </span>
                          ),
                        }}
                        options={activeGroupCode}
                        onChange={(e) => {
                          getCompanyCode(e.value);
                          searchData(e.value, "groupCodeId");
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="companyCodeId" className="form-control-label">
                        <span className="custom-span-color">*</span>Company Code
                      </Label>
                      <Select
                        name="companyCodeId"
                        inputId="companyCodeId"
                        value={{
                          value: search["companyCodeId"],
                          label: search["companyCodeId"] ? (
                            getDisplayValue(
                              search["companyCodeId"],
                              "CompanyCodeID"
                            )
                          ) : (
                            <span className="customSpan">
                              Select Company Code
                            </span>
                          ),
                        }}
                        options={showCompanyCode}
                        onChange={(e) => searchData(e.value, "companyCodeId")}
                      ></Select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="form-control-label">GL Code</Label>
                      <Input
                        data-testid="glCode"
                        maxLength={50}
                        value={glCodeValue}
                        className="customInput"
                        onChange={(e) => {
                          let n = e.target.value;
                          if (validate("number", n)) {
                            setGlCodeValue(n);
                            searchData(parseInt(n), "glCode");
                          }
                        }}
                        placeholder="Enter GL Code"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="form-control-label">
                        GL Description
                      </Label>
                      <Input
                        data-testid="glDescription"
                        maxLength={50}
                        value={search["glDescription"]}
                        className="customInput"
                        placeholder="Enter GL Description"
                        onChange={(e) =>
                          searchData(e.target.value, "glDescription")
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="floatRight">
                      <Button
                        data-testid="searchGL"
                        size="sm"
                        type="button"
                        color="info"
                        onClick={() =>
                          fetchSearchList(masterServiceBaseUrl + "/glcode/search")
                        }
                      >
                        {" "}
                        <i className="fas fa-search"></i> Search
                      </Button>
                      <Button
                        data-testid="resetGL"
                        size="sm"
                        type="button"
                        color="info"
                        onClick={() => resetSearch()}
                      >
                        Reset Filters
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {apiLoader ? (
        <div
          className="mx-auto text-center py-5 my-5"
          style={{height: "100vh"}}
        >
          <Loader type="Rings" color="#00BFFF" height={70} width={70}/>{" "}
        </div>
      ) : !(searchDataList.length === 0) ? (
        <Container fluid={true}>
          <Row>
            <Col>
              <Card>
                <CardHeader className="border-0">
                  <h3 className="mb-0 floatLeft headingColor" data-testid="glHeading">
                    Manage GL Code
                  </h3>
                  <div className="floatRight">
                    <Button data-testid="glHeading" color="info" size="sm" type="button">
                      Download
                    </Button>

                    <Button data-testid="glHeading" color="info" size="sm" type="button">
                      Filter
                    </Button>
                  </div>
                </CardHeader>
                <hr/>
                <CardBody className="customCardBody">
                  <GlCodeDetails
                    activeGroupCode={activeGroupCode}
                    activeCompanyCode={activeCompanyCode}
                    searchDataList={searchDataList}
                    fetchSearchList={fetchSearchList}
                    previousPage={previousPage}
                    lastPage={lastPage}
                    firstPage={firstPage}
                    nextPage={nextPage}
                    pagination={pagination}
                    setPagination={setPagination}
                    glCodeArray={glCodeArray}
                    setGlCodeArray={setGlCodeArray}
                    searchDataList={searchDataList}
                    setSearchDataList={setSearchDataList}
                    getDisplayValue={getDisplayValue}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : null}
    </form>
  );
};

export default SearchHouseBank;
