import React, { useState } from 'react'
import useInactivePrompt from './useInactivePrompt';

const StatusCellComponent = ({ el, index, updateKey, settableDataKey, tableType = '' }) => {

  const [ConfirmationPrompt, confirmPromise] = useInactivePrompt(tableType);

  const onChangeHandler = async (e) => {
    if (el?.status === 'ACTIVE') {
      const canContinue = await confirmPromise();
      if(!canContinue) return;
    }

    settableDataKey(
      new Date().getMilliseconds()
    );
    const n = el?.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
    updateKey(index, n, "status");
  }

  return (
    <>
      <label className="custom-toggle mx-auto ml-2">
        <input
          checked={
            el.status == "ACTIVE" ? true : false
          }
          type="checkbox"
          id={el.id}
          onChange={onChangeHandler}
          data-testid="roomStatus"
        />
        <span
          style={{ width: "72px" }}
          className="custom-toggle-slider rounded-circle activeToggle"
          data-label-off="Inactive"
          data-label-on="Active"
        />
      </label>
      <ConfirmationPrompt />
    </>
  )
}

export default StatusCellComponent