import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import AButton from "../../common/form-fields-mui/AButton";
import { ASelect, InputLabelStyle } from "../../common/form-fields-mui/ASelect";
import { useSelector } from "react-redux";
import { InputLabel } from "@mui/material";
import CommonInput from "../../common/formFeilds/input/commonInput";
import AMultiSelect from "../../common/form-fields-mui/AMultiSelect";
import APaginatedReactSelect from './../../common/form-fields-mui/APaginatedReactSelect';
import { endpoint } from "../../common/constant";
import AAutoComplete from "../../common/form-fields-mui/AAutoComplete";

export const modalStyle = {
  position: "absolute" /* Stay in place */,
  zIndex: "75" /* Sit on top */,
  top: "2rem",
  right: "5rem",
  width: "60%" /* Full width */,
  height: "100%" /* Full height */,
};


const checkBoxStyle = {
  borderRadius: "6px",
  fontSize:"12px"
};
export const BatchFilterForm = ({
  isAdvance,
  formModal,
  inputHandler,
  applyFilterHandler,
  removeFilterHandler,
  isShowRemoveFilter,
}) => {
  const dropdowns = useSelector((state) => state.batch.dropdowns);
  const columnSize = isAdvance ? 4 : 12;

  return (
    <div>
      <Card style={{ width: "342px", marginBottom: "0px" }}>
        <CardBody style = {{paddingTop:".5rem", paddingBottom:".5rem"}}>
          <h4 style = {{color:"black"}}>{isAdvance ? "Advance" : ""} Filter </h4>
          <Row>
            <Col md="12">Type</Col>
            <Col md={6}>
              <FormControlLabel
                sx={{ marginBottom: "0px" }}
                control={
                  <Checkbox
                    onChange={(e) =>
                      inputHandler(
                        e.target.checked ? "PRIMARY" : "",
                        "batchType"
                      )
                    }
                    checked={formModal.batchType === "PRIMARY"}
                  />
                }
                label="Primary"
              />
            </Col>

            <Col md={6}>
              {" "}
              <FormControlLabel
                sx={{ marginBottom: "0px" }}
                control={
                  <Checkbox
                    onChange={(e) =>
                      inputHandler(
                        e.target.checked ? "SECONDARY" : "",
                        "batchType"
                      )
                    }
                    checked={formModal.batchType === "SECONDARY"}
                    sx={checkBoxStyle}
                  />
                }
                label="Secondary"
              />
            </Col>
          </Row>
          <Row>
            <Col md={columnSize}>
              {/* <ASelect
                label="Enrollment"
                currentValue={formModal.batchEnrollmentStatus}
                items={dropdowns && dropdowns.enrollment}
                inputKey="batchEnrollmentStatus"
                handleChange={inputHandler}
              /> */}
              <AAutoComplete 
                label="Enrollment"
                currentValue={formModal.batchEnrollmentStatus}
                items={dropdowns && dropdowns.enrollment}
                handleChange={(value) => inputHandler(value, "batchEnrollmentStatus")}
                isMulti={false}
              />
            </Col>
            {isAdvance && (
              <Col md="4">
                <ASelect
                  label="Academic career"
                  items={dropdowns && dropdowns.academicCareerData}
                  currentValue={formModal.academicCareer}
                  inputKey="academicCareer"
                  handleChange={inputHandler}
                />
              </Col>
            )}
            {isAdvance && (
              <Col md="4">
                <ASelect
                  label="Batch class"
                  currentValue={formModal.batchClass}
                  items={dropdowns && dropdowns.classTypeData}
                  inputKey="batchClass"
                  handleChange={inputHandler}
                  multiple={true}
                />
              </Col>
            )}
          </Row>

          <Row>
            <Col md={columnSize}>
              {/* <ASelect
                label="Status"
                currentValue={formModal.batchStatus}
                items={dropdowns && dropdowns.status}
                inputKey="batchStatus"
                handleChange={inputHandler}
              /> */}
              <AAutoComplete
                label='Status'
                currentValue={formModal?.batchStatus}
                items={dropdowns && dropdowns.status}
                handleChange={(value) => inputHandler(value, 'batchStatus')}
                isMulti={false}
              />
            </Col>
            {isAdvance && (
              <Col md="4">
                <ASelect
                  label="Academic group"
                  items={dropdowns && dropdowns.academicGroupData}
                  currentValue={formModal.academicGroup}
                  inputKey="academicGroup"
                  handleChange={inputHandler}
                />
              </Col>
            )}
            {isAdvance && (
              <Col md="4">
                <ASelect
                  label="Medium"
                  currentValue={formModal.medium}
                  items={dropdowns && dropdowns.mediumData}
                  inputKey="medium"
                  handleChange={inputHandler}
                />
              </Col>
            )}
          </Row>

          <Row>
            <Col md={columnSize}>
              {/* <ASelect
                label="Course ID"
                currentValue={formModal.courseId}
                items={dropdowns && dropdowns.courseId}
                inputKey="courseId"
                handleChange={inputHandler}
              /> */}
              {/* <APaginatedReactSelect
                label="Course ID"
                url={endpoint.courseCatelogMapping.getAllActiveCourseIds}
                values={formModal.courseId}
                onChangeHandler={(value) => inputHandler(value, 'courseId')}
                isMulti={true}
              /> */}
              <AAutoComplete 
                label="Course ID"
                currentValue={formModal?.courseId}
                items={dropdowns && dropdowns?.courseIdData}
                handleChange={(value) => inputHandler(value, 'courseId')}
                isShowCount={true}
              />
            </Col>
            {isAdvance && (
              <Col md="4">
                <ASelect
                  label="Academic year"
                  currentValue={formModal.academicYear}
                  items={dropdowns && dropdowns.academicYearData}
                  inputKey="academicYear"
                  handleChange={inputHandler}
                />
              </Col>
            )}
            {isAdvance && (
              <Col md="4">
                <ASelect
                  label="School board"
                  currentValue={formModal.schoolBoard}
                  items={dropdowns && dropdowns.schoolBoardData}
                  inputKey="schoolBoard"
                  handleChange={inputHandler}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col md={columnSize}>
              {/* <AMultiSelect
                label="Term"
                currentValue={formModal.term}
                items={dropdowns && dropdowns.termData}
                inputKey="term"
                handleChange={inputHandler}
                multiple={true}
                placeHolder="Select"
              /> */}
              <AAutoComplete 
                label="Term"
                currentValue={formModal.term}
                items={dropdowns && dropdowns.termData}
                handleChange={(value) => inputHandler(value, "term")}
                isShowCount={true}
              />
            </Col>
            {isAdvance && (
              <Col md="4">
                <ASelect
                  label="Academic phase"
                  items={dropdowns && dropdowns.academicPhaseData}
                  currentValue={formModal.academicPhase}
                  inputKey="academicPhase"
                  handleChange={inputHandler}
                />
              </Col>
            )}

            {isAdvance && (
              <Col md="4">
                <ASelect
                  label="Class type"
                  currentValue={formModal.classType}
                  items={dropdowns && dropdowns.classTypeData}
                  inputKey="classType"
                  handleChange={inputHandler}
                />
              </Col>
            )}
          </Row>
          <Row></Row>
          <Row>
            <Col md="12">
              <InputLabel style={InputLabelStyle}>Batch start date </InputLabel>
            </Col>
            <Col md={columnSize / 2}>
              <CommonInput
                type={"datePicker"}
                mandatory={true}
                placeHolder="From"
                value={formModal.batchStartFromDate}
                onChange={(value) =>
                  inputHandler(new Date(value), "batchStartFromDate")
                }
                
              />
            </Col>
            <Col md={columnSize / 2}>
              <CommonInput
                type={"datePicker"}
                mandatory={true}
                placeHolder="To"
                value={formModal.batchStartToDate}
                onChange={(value) =>
                  inputHandler(new Date(value), "batchStartToDate")
                }
                style={{ marginBottom: "0px" }}
              />
            </Col>

            {isAdvance && (
              <Col md="4" style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: "-20px",
                    fontSize: "12px",
                  }}
                >
                  Class section
                </div>
                <ASelect
                  // label="Class section"
                  currentValue={formModal.classSection}
                  items={dropdowns && dropdowns.classSectionData}
                  inputKey="classSection"
                  handleChange={inputHandler}
                />
              </Col>
            )}
          </Row>
          <Row style ={{marginTop:"-1rem"}}>
            <Col md="12 tiny-semi-bold">Timetable status</Col>
            <Col md={6}>
              <FormControlLabel
                sx={{ marginBottom: "0px" }}
                control={
                  <Checkbox
                    onChange={(e) =>
                      inputHandler(
                        e.target.checked ? "P" : "",
                        "timeTableStatus"
                      )
                    }
                    checked={formModal.timeTableStatus === "P"}
                  />
                }
                label="Created"
              />
            </Col>

            <Col md={6}>
              {" "}
              <FormControlLabel
                sx={{ marginBottom: "0px" }}
                control={
                  <Checkbox
                    onChange={(e) =>
                      inputHandler(
                        e.target.checked ? "U" : "",
                        "timeTableStatus"
                      )
                    }
                    checked={formModal.timeTableStatus === "U"}
                    sx={checkBoxStyle}
                  />
                }
                label="Not created"
              />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              {isShowRemoveFilter && (
                <AButton
                  onClick={removeFilterHandler}
                  updatedStyle={{
                    border: "1px solid #CC5F5F",
                    borderRadius: "8px",
                    color: "#CC5F5F",
                    margin: ".3rem",
                  }}
                >
                  Remove Filter
                </AButton>
              )}
            </Col>
            <Col md="6" style={{ display: "flex", justifyContent: "flex-end" }}>
              <AButton
                onClick={applyFilterHandler}
                updatedStyle={{
                  background: "#00B0F5",
                  border: "1px solid #00B0F5",
                  borderRadius: "8px",
                  color: "#fff",
                  margin: ".4rem",
                }}
              >
                Apply Filter
              </AButton>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
