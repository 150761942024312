import React, { useState, useEffect, useContext } from "react";
import { connect } from 'react-redux';
import { masterServiceBaseUrl, documentServiceBaseUrl } from "services/http";
import { CustomContainer } from "../../../../common/commonComponents";
import { CustomCard } from "../../../../common/commonComponents";
import Header from "./docuementHeader";
import { fetchDetails, fetchAllData } from "../../../../common/utils/methods/commonMethods/utilityMethod";
import CustomLoader from "views/pages/manage/common/commonComponents/Loader/loader";
import { PermissionContext, RolePermissions } from "appContext";
import { PermisionDenied } from "../../../../common/commonComponents";
import { pages } from "../../../../common/constant";

const UploadProcess = (props) => {

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["documentManagementSearch"]['id']);

  const { action = "new", aid, pid, action1 } = props.match.params;
  const [personTypeData, setPersonTypeData] = useState(null);
  const [processTypeData, setProcessTypeData] = useState(null);
  const [mappedPersonData, setMappedPersonData] = useState(null);
  useEffect(() => {
    fetchDetails(
      `${masterServiceBaseUrl}/personType/getAllActivePersonType`,
      setPersonTypeData,
      "personType",
      "select"
    );
    fetchDetails(
      `${masterServiceBaseUrl}/processTag/getAllProcessTag`,
      setProcessTypeData,
      "tagType",
      "select"
    );
    fetchAllData(
      `${documentServiceBaseUrl}/DocumentProcessTag/getDistinctPersonType`,
      setMappedPersonData,
    );
  }, []);

  return (
    userPermissions ?
      <CustomContainer>
        <CustomCard title={"Document Upload"}>
          {!personTypeData || !mappedPersonData || !processTypeData ? (
            <div
              className="mx-auto text-center py-5 my-5"
              style={{ height: "100vh" }}
            >
              <CustomLoader apiLoader={!personTypeData || !processTypeData || !mappedPersonData} />
            </div>
          ) : (
            <Header
              headerData={props.headerData}
              personTypeData={personTypeData}
              processTypeData={processTypeData}
              mappedPersonData={mappedPersonData}
              action={action}
              action1={action1}
              pid={pid ?? null}
              aid={aid ?? null}
            />
          )}
        </CustomCard>
      </CustomContainer> : <PermisionDenied />
  );
};
const mapStateToProps = (state) => {
  return {
    headerData: state.globalData.data,
  };
};
export default connect(mapStateToProps)(UploadProcess);
