import { endpoint } from "../../common/constant";
import { dropdownMapping, fetchAllPostPromisedData, updateRequest } from "../../common/utils/methods/commonMethods/utilityMethod";
import { dropdownMapping as dropdownMapping2 } from "../constant/allocate-students";


export const getSgDropdowns = async () => {
  const p = await Promise.all([
    dropdownMapping(endpoint.businessArea.getAllActive, 'businessAreaDispValue', 'id'),
    dropdownMapping(endpoint.region.getAllActive, 'regionDispValue', 'id'),
  ]);
  return {
    businessArea: p[0],
    region: p[1],
  };
};

export const getDropdowns = async () => {
  const p = await Promise.all([
    dropdownMapping2(endpoint.courseCatelogMapping.getAllActiveCourseIds, 'courseIds'),
  ]);
  return {
    courseId: p[0],
  };
};

const filterLabel = dropdown => dropdown?.map(item => item?.label)

export const fetchListHelper = async (page, sgFilter, payload) => {
  const reqBody = updateRequest({
    businessAreas: filterLabel(sgFilter?.businessArea),
    status: ['PENDING'],
    ...payload,
  })
  const res = await fetchAllPostPromisedData(`${endpoint.studentSupplementary.search}?offset=${page}`, reqBody, 'post');
  if(res?.code===200) {
    return {
      rows: res?.data?.supplementaryIdDetailsDtoList || [],
      totalRecords: res?.data?.totalRecords,
    }
  }

  return {
    rows: [],
    totalRecords: 0,
  };
}

export const getApprovalPayload = (student, status) => {
  const details = JSON.parse(student);

  return {
    id: details?.id,
    status: status,
  };
}

export const searchParamTitleMap = {
  psid: 'PSID',
  applicationId: 'application ID',
  studentName: 'student name'
}

export const initialFilterForm = {
  courseIds: [],
  classes: [],
  programAction: [],
}