import React, {useState, useEffect, useMemo, useContext} from 'react';
import ClassesTable from './setupTable';
import { getAPI, masterServiceBaseUrl } from 'services/http';
import CustomLoader from '../../common/commonComponents/Loader/loader';
import { GetPagination } from '../../common/commonComponents/pagination/pagination';
import {
  fetchDetails,
  getObjectById,
} from '../../common/utils/methods/commonMethods/utilityMethod';
import moment from 'moment';
import AcademicCareerTable from "../academicCareer/academicCareerTable";
import {PermisionDenied} from "../../common/commonComponents";
import {PermissionContext, RolePermissions} from "../../../../../appContext";
import {pages} from "../../common/constant";
import { failureToast, successToast} from '../../common/utils/methods/toasterFunctions/function'


const _ = require('lodash');

const Classes = () => {
  const [classArray, setclassArray] = useState([]);
  const [apiloader, setapiloader] = useState(true);
  const [state, setstate] = useState([]);
  const [serverMappedData, setServerMappedData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [isSaveVisible, setisSaveVisible] = useState(false);

  const [classificationType, setClassificationType] = useState(null);
  const [categoryType, setCategoryType] = useState(null);
  const [elementType, setElementType] = useState(null);
  const [companyCode, setCompanyCode] = useState(null);
  const [groupCode, setGroupCode] = useState(null);
  const [searchStr, setSearchStr] = useState(null);

  const [dataSorted, setDataSorted] = useState(false);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });

  const pageSize = 10;

  const permissions = useContext(PermissionContext);

  const userPermissions = RolePermissions(
      permissions,
      pages['itemTypeSetup']['id']
  );

  const fetchClasses = async (url) => {
    setapiloader(true);
    getAPI(
      url,
      (data) => {
        setstate(data['data']);
        setapiloader(false);
      },
      (data) => {
        setapiloader(false);
        failureToast(data['message']);
      }
    );
  };

  const getPaginatedData = (data) => {
    let n = pagination;
    if (data.length > pageSize) {
      n['nextPage'] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n['totalPage'] = Math.floor(data.length / pageSize);
    if (data.length % pageSize != 0) {
      n['totalPage'] += 1;
    }
    setpagination(n);
    setapiloader(false);
    setclassArray(
      data.slice(
        pagination.currentPage * pageSize - pageSize,
        pagination.currentPage * pageSize
      ) || []
    );
  };

  const sortData = (field) => {
    if (dataSorted === field) {
      const ReversedData = searchStr
        ? tableData.reverse()
        : serverMappedData.reverse();
      setTableData(ReversedData);
      getPaginatedData(ReversedData);
    } else {
      var sortedObjs = _.sortBy(
        searchStr ? tableData : serverMappedData,
        field
      );
      setstate(sortedObjs);
      setDataSorted(field);
      getPaginatedData(sortedObjs);
    }
  };

  useEffect(() => {
    fetchClasses(
      masterServiceBaseUrl + '/itemTypeSetup/getActiveItemTypeSetup'
    );
    fetchDetails(
      `${masterServiceBaseUrl}/itemTypeClassification/getAllActiveItemTypeClassification`,
      setClassificationType,
      'itemTypeClassification',
      'reactSelect'
    );
    fetchDetails(
      `${masterServiceBaseUrl}/itemTypeCategory/getAllActiveItemTypeCategory`,
      setCategoryType,
      'itemTypeCategory',
      'reactSelect'
    );
    fetchDetails(
      `${masterServiceBaseUrl}/itemTypeElement/getAllActiveItemTypeElement`,
      setElementType,
      'itemTypeElement',
      'reactSelect'
    );
    fetchDetails(
      `${masterServiceBaseUrl}/companyCode/getAllActiveCompanyCode`,
      setCompanyCode,
      'companyCode',
      'reactSelect'
    );
    fetchDetails(
      `${masterServiceBaseUrl}/groupCode/getAllActiveGroupCode`,
      setGroupCode,
      'groupCode',
      'reactSelect'
    );
  }, []);

  const mapItemTypeSetUpDataWithMaster = () => {
    const mappedData =
      state &&
      state.map((item) => {
        return {
          ...item,
          classification:
            getObjectById(item.itemTypeClassificationId, classificationType)[
              'text'
            ] ?? '-',
          element:
            getObjectById(item.itemTypeElementId, elementType)['text'] ?? '-',
          instNo:
            getObjectById(item.itemTypeCategoryId, categoryType)['text'] ?? '-',
          groupCode: getObjectById(item.groupCodeId, groupCode)['text'] ?? '-',
          companyCode:
            getObjectById(item.companyCodeId, companyCode)['text'] ?? '-',
          updatedon: moment(item.updatedOn).format('DD-MM-YYYY'),
        };
      });

    setTableData(mappedData);
    setServerMappedData(mappedData);
    getPaginatedData(mappedData);
    setapiloader(false);
  };

  useMemo(() => {
    if (
      state &&
      classificationType &&
      categoryType &&
      elementType &&
      companyCode &&
      groupCode
    ) {
      mapItemTypeSetUpDataWithMaster();
    }
  }, [
    state,
    classificationType,
    categoryType,
    elementType,
    companyCode,
    groupCode,
  ]);

  const resetSearch = () => {
    getPaginatedData(serverMappedData);
    setTableData(serverMappedData);
  };

  const setSearch = (data) => {
    setTableData(data);
    getPaginatedData(data);
    setpagination({
      currentPage: 1,
      nextPage: null,
      previousPage: null,
      totalPage: null,
    });
  };

  return (
      <>
        {apiloader ? (
            <CustomLoader
                apiLoader={apiloader}
                loaderHeight={'200px'}
                loaderWidth={'100% '}
            />
        ) : (
            <>
              {
                userPermissions ? (
                    <>
                      <ClassesTable
                          fetchClasses={fetchClasses}
                          setclassArray={setclassArray}
                          classArray={classArray}
                          classificationType={classificationType}
                          categoryType={categoryType}
                          elementType={elementType}
                          companyCode={companyCode}
                          groupCode={groupCode}
                          sortData={sortData}
                          isSaveVisible={isSaveVisible}
                          setisSaveVisible={setisSaveVisible}
                          serverData={serverMappedData}
                          searchStr={searchStr}
                          setSearchStr={setSearchStr}
                          resetSearch={resetSearch}
                          setSearch={setSearch}
                          tableData={tableData}
                          permissionSet={userPermissions}
                      />
                      {pagination.totalPage > 1 ? (
                          <GetPagination
                              isSaveVisible={isSaveVisible}
                              fetchClasses={fetchClasses}
                              setclassArray={setclassArray}
                              pagination={pagination}
                              setpagination={setpagination}
                              pageSize={pageSize}
                              state={tableData}
                          />
                      ) : null}
                    </>
                ) : (
                    <PermisionDenied/>
                )}
            </>
        )}
      </>
  );
};

export default Classes;
