import React, { useState } from "react";
import { AGridColDef } from "views/pages/manage/common/data-grid/ADataGridColDef";
import ADataGrid from "views/pages/manage/common/data-grid/ADataGrid";
import { Col } from "reactstrap";
import { Button } from "reactstrap";
import ReportSearchInput from "./ReportSearchInput";
import { CSVLink } from "react-csv";
import { GoDesktopDownload } from "react-icons/go";
import moment from "moment";
import ALoader from "../common/a-loader";
import './report-styles.scss'
import EmptyReportPlaceholder from "./EmptyReportPlaceholder";
// import { Tooltip } from "@material-ui/core";
import Tooltip from '@mui/material/Tooltip';
const getDisplayText = (text)=>{
  if(text && text.length>8)
  return  text.substring(0, 8) + '...';
  return text ;

}

const ReportList = ({
  table1Data,
  table2Data,
  getTable2Data,
  channel = '',
  table1Loading,
  table2Loading,
  setTable2Loading,
  totalNotification,
  successfulNotification,
  failedNotification,
}) => {
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0,
  });
  const getCellValue=(value)=>{
    if(value===null) return '  --';
    else return value;
  }
  const getFormattedDate = (date)=>{
    return   moment(date).format("DD-MMM-YY");
  }
  const [selectedPID, setSelectedPID] = useState('');

  const table1Headers = [
    new AGridColDef("processId", 'PID')
      .setWidth(50)
      .renderCellComponent((params) => (
        <span
          onClick={() => {
            setSelectedPID(params.value);
            getTable2Data(params.value);
            setTable2Loading(true);
          }
          }
          style={{ color: "#00B0F5", fontSize: "14px", cursor: "pointer" }}>
          {params.value}
        </span>
      )),
    new AGridColDef("channelName", 'Channel')
      .setWidth(50)
      .renderCellComponent((params) => (
        <span>{params.value||' --'}</span>
      )),
    new AGridColDef("processStatus", 'PID Status')
      .setWidth(200)
      .renderCellComponent((params) => (
        <span>
          {params.value||' --'}
        </span>
      )),
    ,
    new AGridColDef("uploadedFileName", 'Uploaded File Name')
      .setWidth(300)
      .renderCellComponent((params) => (
        <Tooltip title={params.value}>
        <span>{getDisplayText(params.value)||' --'}</span>
        </Tooltip>
      )),

    new AGridColDef("uploadedFileStatus", 'Uploaded File Status')
      .setWidth(200)
      .renderCellComponent((params) => (
        <span>{params.value||' --'}</span>
      )),

    new AGridColDef("aakashMessageId", 'Aakash Message Id')
      .setWidth(200)
      .renderCellComponent((params) =>
      (
        <Tooltip title={params.value}>
        <span>{getDisplayText(params.value)||' --'}</span>
        </Tooltip>
      )),

    new AGridColDef("createdOn", 'Triggered')
      .setWidth(150)
      .renderCellComponent((params) => (
        <span>{getCellValue(getFormattedDate(params.value))}</span>
      )),
    new AGridColDef("remarks", 'Remark')
      .setWidth(150)
      .renderCellComponent((params) => (
        <span>{params.value||' --'}</span>
      )),



  ];
  const table2CommonHeaders = [
    new AGridColDef("processId", 'PID')
      .setWidth(50)
      .renderCellComponent((params) => (
        <span>{params.value||' --'}</span>
      )),
      new AGridColDef("psid", 'PSID')
      .setWidth(50)
      .renderCellComponent((params) => (
        <span>{params.value||' --'}</span>
      )),
      
    new AGridColDef("fileRowIndex", 'File Row Index')
      .setWidth(50)
      .renderCellComponent((params) => (
        <span>{params.value}</span>
      )),


    new AGridColDef("status", 'Status')
      .setWidth(200)
      .renderCellComponent((params) => (
        <span >

          {params.value||' --'}
        </span>
      )),
    new AGridColDef("remark", 'Remark')
      .setWidth(150)
      .renderCellComponent((params) => (
        <Tooltip title={params.value}>
        <span>{getDisplayText(params.value)||' --'}</span>
        </Tooltip>
      )),
    new AGridColDef("updatedOn", 'Updated On')
      .setWidth(150)
      .renderCellComponent((params) => (
        <span>{getFormattedDate(params.value)||' --'}</span>
      )),
      new AGridColDef("aakashMessageId", 'Aakash Message Id')
      .setWidth(200)
      .renderCellComponent((params) => (
        <Tooltip title={params.value}>
        <span>{getDisplayText(params.value)||' --'}</span>
        </Tooltip>
      )),

    new AGridColDef("deliveryStatus", 'Delivery Status')
      .setWidth(200)
      .renderCellComponent((params) => (
        <span>{params.value||' --'}</span>
      )),
  ];
  const table2WhatsAppHeaders = [
    new AGridColDef("eventType", 'Event Type')
      .setWidth(200)
      .renderCellComponent((params) => (
        <span>{params.value||' --'}</span>
      )),
    new AGridColDef("reason", 'Reason')
      .setWidth(200)
      .renderCellComponent((params) => (
        <span>{params.value||' --'}</span>
      )),
      new AGridColDef("mobileNumber", 'Mob. no.')
      .setWidth(200)
      .renderCellComponent((params) => (
        <Tooltip title={params.value}>
        <span>{getDisplayText(params.value)||' --'}</span>
        </Tooltip>
      )),


  ]

  const table2SMSHeaders = [
    new AGridColDef("errDesc", 'Delivery Error Description')
      .setWidth(200)
      .renderCellComponent((params) => (
        <Tooltip title={params.value}>
        <span>{getDisplayText(params.value)||' --'}</span>
        </Tooltip>
      )),
      new AGridColDef("mobileNumber", 'Mob. no.')
      .setWidth(200)
      .renderCellComponent((params) => (
        <Tooltip title={params.value}>
        <span>{getDisplayText(params.value)||' --'}</span>
        </Tooltip>
      )),
  ]
  const table2EmailHeaders = [
    new AGridColDef("deliveryId", 'Delivery Id')
      .setWidth(200)
      .renderCellComponent((params) => (
        <span>{params.value||' --'}</span>
      )),
      new AGridColDef("emai", 'Email')
      .setWidth(200)
      .renderCellComponent((params) => (
        <Tooltip title={params.value}>
        <span>{getDisplayText(params.value)||' --'}</span>
        </Tooltip>
      )),
  ]
  const getTable2Headers = (channel) => {
    if (channel == 'WHATSAPP') return [...table2CommonHeaders, ...table2WhatsAppHeaders];
    else if (channel == 'SMS') return [...table2CommonHeaders, ...table2SMSHeaders];
    else if(channel=='EMAIL') return [...table2CommonHeaders, ...table2EmailHeaders]
  }
  return (
    <>
    {table1Loading ?<ALoader/>:
      <ADataGrid
        rows={table1Data}
        columns={table1Headers}
        rowId={(row) => row.processId}
        loading={table1Data.length === 0}
        // pageSize={100}
        serverPagination={false}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}

        // loading={true}
      // rowCount={10}
      // currentPage={0}
      emptyRowPlaceholder = {()=><EmptyReportPlaceholder/>}
      />
  }
      {channel && (
        <>
          <div style={{ display: 'flex' }}>
            <Col md='2'>
              PID:-<span style={{ color: "#00B0F5" }}>{selectedPID}</span>
            </Col>
            <Col md='2'>
              Channel:-<span style={{ color: "#00B0F5" }}>{channel}</span>
            </Col>
            <Col md='3'>
              Total Notification:-<span style={{ color: "#00B0F5" }}>{totalNotification}</span>
            </Col>
            <Col md='3'>
              Successful Notification:-<span style={{ color: "#00B0F5" }}>{successfulNotification}</span>
            </Col>
            <Col md='3'>
              Failed Notification:-<span style={{ color: "#00B0F5" }}>{failedNotification}</span>
            </Col>
            {/* <Col>
        <ReportSearchInput searchInputValueHandler={searchInputValue2Handler} placeHolder={placeHolder2}/>
        </Col>
        
        <CSVLink data={table1Data} headers={headers} filename={"Report_Data.csv"}>
                        <Button  color="success" size="sm" className=" btn-icon w-40" >
                          <GoDesktopDownload/>
                          <span style={{marginLeft:'2px'}}> Export to Excel</span>
                        </Button>
                    </CSVLink> */}

          </div>

          {table2Loading ?<ALoader/>:<ADataGrid
            rows={table2Data}
            columns={getTable2Headers(channel)}
            loading={table2Data.length === 0}
            rowId={(row) => row.fileRowIndex}
            
          emptyRowPlaceholder = {()=><EmptyReportPlaceholder/>}
          />}
        </>
      )}
    </>

  );
}

export default ReportList;