import React, { useContext, useEffect, useState } from 'react';
import AButton from '../../common/form-fields-mui/AButton';
import styles from './styles.module.scss';
import StarIcon from './FavouriteIcon';
import { ReactComponent as CrossIcon } from '../../../../../assets/img/svg/CrossIcon.svg';
import { UserFavouritesContext } from '../../../../../appContext/favouritesContext';
import CustomChip from '../../common/customChip/CustomChip';
import { PermissionContext } from '../../../../../appContext';
import { useHistory } from 'react-router';

const checkAccess = (scope = {}, screenId = '') => {
  return (
    typeof scope === 'object' &&
    Object.keys(scope)?.find(item => item === screenId)
  );
};
const FavouriteChip = ({ data, mode = 'view', deleteHandler }) => {
  const history = useHistory();
  const handleClick = e => {
    e.stopPropagation();
    if (!e.detail || e.detail === 1) {
      deleteHandler(data?.id);
    }
  };
  return (
    <CustomChip
      bgColor='unset'
      className={`${styles?.favouriteChip} gap-xs ${
        mode === 'view' ? 'cursor' : ''
      }`}
      onClick={() =>
        mode === 'view' && window.open(history.createHref({ pathname: data?.pathUrl || '/admin/dashboard' }), '_blank')
      }
    >
      {data?.screenName}
      {mode === 'edit' && (
        <CrossIcon width={'1rem'} height={'1rem'} onClick={handleClick} />
      )}
    </CustomChip>
  );
};
const Favourites = () => {
  const { favourites, deleteFavourite } = useContext(UserFavouritesContext) || {};
  const { scope } = useContext(PermissionContext) || {};
  const [mode, setMode] = useState('view');
  // const contentRef = useRef();
  useEffect(() => {
    !favourites?.length && setMode('view')  
  }, [favourites])

  return (
    <div className={`${styles?.favouritesContainer}`}>
      <div className={`large-bold ${styles?.favouritesHeader}`}>
        <StarIcon checked />
        <div className='flex-grow-1 color-grey-90'>My favourite actions</div>
        {favourites?.length > 0 && (
          <AButton
            variant='link'
            updatedStyle={{ height: '28px' }}
            onClick={() => setMode(prev => (prev === 'view' ? 'edit' : 'view'))}
          >
            {mode === 'view' ? 'Edit' : 'Save'}
          </AButton>
        )}
      </div>
      {favourites?.length > 0 ? (
        <div className={`${styles?.favouritesContent}`}>
          {favourites?.map(
            item =>
              checkAccess(scope, item?.screenId) && (
                <FavouriteChip
                  data={item}
                  key={JSON.stringify(item)}
                  mode={mode}
                  deleteHandler={deleteFavourite}
                />
              )
          )}
        </div>
      ) : (
        <div className='label-color semi-bold'>No favourites added yet!</div>
      )}
    </div>
  );
};

export default Favourites;
