import React, { useContext } from "react";
import { PermissionContext, RolePermissions } from "appContext";
import { failureToast } from "../common/utils/methods/toasterFunctions/function";
import CustomDatePickerV2 from "views/pages/manage/common/commonComponents/CustomDatePicker-V2";
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";
import "../edpOps/issuanceIDcard/style.scss";
import CustomButton from "../../../../components/CustomButton";
import AInput from "views/pages/manage/common/formFeilds/customInput/AInput";
import SelectSearchInputParam from "../edpOps/studentSerach/SelectSearchInputParam";
import moment from "moment";
import { endpoint, pages } from "../common/constant";
import styles from './styles.module.scss'

const ManualAttendanceForm = ({
  dropdownData,
  setErrorFields,
  values,
  setValues,
  data,
  setData,
  setServerData,
  setFilterData,
  setTableData,
  fetchFilterData,
  fetchFilter,
  globalFilter,
  setGlobalFilter,
  fetchDetailsPost,
  isFlow1,
}) => {
  const inputHeight = "42px";
  const marginTop = { marginTop: "33px" };

  const onKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      applyFilterHandler();
    }
  };

  const applyFilterHandler = async () => {
    if (!globalFilter.date1) {
      failureToast("Please select date");
      return;
    }
    if (!globalFilter.userInput) {
      failureToast(`Please enter ${globalFilter.typeOfCheckbox}`);
      return;
    }
    setValues({
      ...values,
      [globalFilter?.typeOfCheckbox]: globalFilter?.userInput,
      date1: globalFilter?.date1,
    });

    fetchFilter();
  };

  let searchParamTitleMap = {
    psid: "PSID",
    studentName: "student name",
    applicationId: "application ID",
  };
  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(
    permissions,
    pages["attendance"]["id"]
  );

  const validateSave = () => {
    let errorList = {};
    if (values.date == "") {
      failureToast("Please select date");
      errorList.dateErr = true;
      return;
    }
    if (values.businessArea == "") {
      failureToast("Please select buisness area");
      errorList.businessAreaErr = true;
      return;
    }
    if (values.term == "") {
      failureToast("Please select term");
      errorList.termErr = true;
      return;
    }
    if (values.batch == "") {
      failureToast("Please select batch");
      errorList.batchesErr = true;
      return;
    }

    if (Object.keys(errorList).length > 0) {
      setErrorFields(errorList);
      return false;
    } else return true;
  };
  const searchHandler = async () => {
    if (validateSave()) {
      fetchFilterData();
    }
  };

  const resetButton = () => {
    setValues({
      psid: "",
      batch: "",
      term: "",
      courseId: "",
      region: "",
      businessArea: "",
      date: "",
      date1: "",
      punchTime: "",
      remarks: "",
      securityGroupName: "",
      securityGroupDescription: "",
      applicationId: "",
      studentName: "",
    });
    setServerData(null);
    setTableData([]);
    setFilterData([]);
  };
  const resetPrimaryButton = () => {
    setValues({
      ...values,
      psid: "",
      applicationId: "",
      studentName: "",
      date1: "",
    });
  };

  return (
    <>
      <div className="mb-2">
        <div>
          <SelectSearchInputParam
            searchInputParam={globalFilter?.typeOfCheckbox}
            isHideName={true}
            width="200px"
            setSearchInputParam={(value) => {
              resetButton();
              setGlobalFilter((prev) => ({
                ...prev,
                typeOfCheckbox: value,
                date1: "",
                userInput: "",
                [globalFilter.typeOfCheckbox]: "",
              }));
            }}
          />
        </div>
        <div className="student-search-filer-idCard my-2">
          <div>
            <CustomDatePickerV2
              label="Date"
              placeHolder="Select date"
              value={globalFilter?.date1}
              handler={(i, date) => {
                resetButton();
                setGlobalFilter((prevVal) => ({ ...prevVal, date1: date }));
              }}
              minDate={moment(new Date(), "DD-MM-YYYY")
                .subtract(2, "days")
                .format("YYYY-MM-DD")}
              maxDate={moment().format("YYYY-MM-DD")}
            />
          </div>
          <div style={{ width: "200%", marginTop: "28px" }}>
            <AInput
              placeholder={`Search by ${
                searchParamTitleMap[globalFilter?.typeOfCheckbox]
              }`}
              style={{ height: "42px" }}
              value={globalFilter?.userInput}
              handler={(value) => {
                resetButton();
                setGlobalFilter((prev) => ({
                  ...prev,
                  userInput: value,
                  [globalFilter?.typeOfCheckbox]: value,
                }));
              }}
              onKeyDown={(e) => onKeyDown(e)}
              crossIcon={true}
              rightSearchIcon={true}
              crossHandler={async () => {
                setGlobalFilter((prev) => ({
                  ...prev,
                  userInput: "",
                  date1: "",
                }));
                resetPrimaryButton();
              }}
              searchIconClickHandler={() => {
                applyFilterHandler();
              }}
            />
          </div>
        </div>
      </div>
      <div className={`${styles['search-filter-grid']} my-2`}>
        <div>
          <CustomDatePickerV2
            label="Date"
            placeHolder="Select date"
            value={values?.date}
            handler={(i, date) => {
              setGlobalFilter((prevVal) => ({
                ...prevVal,
                date1: "",
                userInput: "",
                [globalFilter.typeOfCheckbox]: "",
              }));
              setValues({
                ...values,
                date: date,
              });
            }}
            minDate={moment(new Date(), "DD-MM-YYYY")
              .subtract(2, "days")
              .format("YYYY-MM-DD")}
            maxDate={moment().format("YYYY-MM-DD")}
          />
        </div>
        <div>
          <AAutoComplete
            label="Business Area"
            height={inputHeight}
            placeholder="Business Area"
            isMulti={true}
            isShowCount={true}
            items={dropdownData?.businessAreas}
            currentValue={values?.businessArea}
            // isDisabled={dropdown?.businessAreas?.length === 1}
            handleChange={(value) => {
              // setGlobalFilter((prevVal)=>({...prevVal,date1:"",userInput:"",[globalFilter.typeOfCheckbox]:''}))
              value.length > 5
                ? failureToast(
                    "Maximum 5 Buisness Area can be selected at a time"
                  )
                : setValues({
                    ...values,
                    businessArea: value,
                    term: "",
                    courseId: "",
                    batch: "",
                    punchTime: "",
                    remarks: "",
                  });
            }}
          />
        </div>
        <div>
          <AAutoComplete
            label="Term"
            height={inputHeight}
            placeholder="Term"
            items={dropdownData?.terms}
            currentValue={values?.term}
            isShowCount={true}
            isMulti={true}
            handleChange={(value) => {
              setGlobalFilter((prevVal) => ({
                ...prevVal,
                date1: "",
                userInput: "",
                [globalFilter.typeOfCheckbox]: "",
              }));
              if (value && values.businessArea) {
                const businessArea = values.businessArea.map((val) => {
                  return val.value;
                });
                const term = value.map((val) => {
                  return val.value;
                });

                fetchDetailsPost(
                  endpoint.batchList.fetchAllBatches + "=-1",
                  { businessArea: businessArea, term: term,  batchType : "SECONDARY", batchStatus : "ACTIVE" },
                  "post",
                  "batchesDispValue"
                ).then((pp) => {
                  setData({ ...data, batchesData: pp });
                });
              }
              setValues({
                ...values,
                term: value,
                courseId: "",
                batch: "",
                punchTime: "",
                remarks: "",
              });
            }}
          />
        </div>
        <div>
          <AAutoComplete
            height={inputHeight}
            label="Batch"
            placeholder="Batch"
            isMulti={true}
            items={data.batchesData}
            currentValue={values.batch}
            isShowCount={true}
            handleChange={(value) => {
              value.length > 10
                ? failureToast("Maximum 10 Batches can be selected at a time")
                : setValues({
                    ...values,
                    batch: value,
                  });
            }}
            isDisabled={values.businessArea && values.term ? false : true}
          />
        </div>
        <div className="d-flex">
        <div
          style={marginTop}
          className="justify-content-end align-self-center mr-0 ml-2"
        >
          <CustomButton
            content={"Search"}
            permissionType={"S"}
            icon={true}
            permissionSet={userPermissions}
            onClick={() => {
              resetPrimaryButton();
              searchHandler();
            }}
          />
        </div>
        <div
          style={marginTop}
          className="justify-content-end align-self-center ml-0"
        >
          <CustomButton
            // disabled={isFetching || (values.date=='' ) ? true : false}
            content={"Reset"}
            permissionType={"R"}
            permissionSet={userPermissions}
            onClick={resetButton}
          />
        </div>
        </div>
      </div>
    </>
  );
};

export default ManualAttendanceForm;
