import React from "react";
import ManualAttendanceTable from "./ManualAttendanceTable";
import CustomLoader from "../common/commonComponents/Loader/loader";
import {
  Card,
  CardHeader,
  Row,
  Label,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
const ManualAttendanceTableContainer = ({
  errorFields,
  setErrorFields,
  values,
  setValues,
  data,
  setData,
  serverData,
  setServerData,
  isFetching,
  setIsFetching,
  toggleCollapse,
  filterData,
  setFilterData,
  tableData,
  setTableData,
  fetchFilterData,
  filterSwitch,
  setfilterSwitch,
  isFlow1,
  setIsFlow1,
  fetchFilter,
  globalFilter,
  currentPage,
  nextButtonHandler,
  rowCount,
}) => {
  const filterStudentList = (filter) => {
    let filteredList = [];
    if (filter == "ABSENT") {
      setfilterSwitch({
        all: false,
        filtered: true,
      });
      filteredList = filterData.filter((el) => {
        return el.status == "ABSENT";
      });
      setFilterData(filteredList);
    } else {
      setfilterSwitch({
        all: true,
        filtered: false,
      });
      isFlow1 ? fetchFilter() : fetchFilterData();
    }
  };
  return (
    <Row className="mt-n2">
      {isFetching ? (
        <div className="col">
          <Card className=" w-100">
            <div
              className="mx-auto text-center py-5 my-5 "
              style={{ height: "100vh" }}
            >
              <CustomLoader apiLoader={isFetching} />
            </div>
          </Card>
        </div>
      ) : (
        <div className="col mt-2">
          <Card className="overflow-auto mb-0">
            <CardHeader className="border-0">
              <Row>
                <h3 className="mb-0 mr-2 floatLeft">Manual Attendance</h3>
                <Button
                  className=" pe-none"
                  style={{
                    marginLeft: "1rem!important",
                    marginRight: "auto!important",
                  }}
                  outline
                  color="info"
                  size="sm"
                  id="buttonToggler"
                >
                  <span className="btn-inner--text">
                    Total: {isFlow1 ? filterData.length : rowCount}
                  </span>
                </Button>
              </Row>
              <Row>
                <FormGroup className="mx-2" check>
                  <Label check>
                    <Input
                      type="radio"
                      name="radio1"
                      key={Math.random(5)}
                      checked={filterSwitch.all}
                      onChange={() => filterStudentList("all")}
                    />{" "}
                    All
                  </Label>
                </FormGroup>
                <FormGroup className="mx-2" check>
                  <Label check>
                    <Input
                      type="radio"
                      name="radio1"
                      key={Math.random(5)}
                      checked={filterSwitch.filtered}
                      onChange={() => filterStudentList("ABSENT")}
                    />{" "}
                    Show absent students only
                  </Label>
                </FormGroup>
              </Row>
            </CardHeader>
            {
              <ManualAttendanceTable
                errorFields={errorFields}
                setErrorFields={setErrorFields}
                values={values}
                setValues={setValues}
                data={data}
                setData={setData}
                serverData={serverData}
                setServerData={setServerData}
                isFetching={isFetching}
                setIsFetching={setIsFetching}
                toggleCollapse={toggleCollapse}
                filterData={filterData}
                setFilterData={setFilterData}
                tableData={tableData}
                setTableData={setTableData}
                fetchFilterData={fetchFilterData}
                filterSwitch={filterSwitch}
                setfilterSwitch={setfilterSwitch}
                rowData={filterData}
                isFlow1={isFlow1}
                setIsFlow1={setIsFlow1}
                fetchFilter={fetchFilter}
                globalFilter={globalFilter}
                currentPage={currentPage}
                nextButtonHandler={nextButtonHandler}
                rowCount={rowCount}
              />
            }
          </Card>
        </div>
      )}
    </Row>
  );
};

export default ManualAttendanceTableContainer;
