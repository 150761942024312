import React from "react";
import { ReactComponent as BackIcon } from "assets/img/svg/BackButton.svg";
import { useGetDigitalModuleContext } from "../../contextApi/digitalContext";
import { headerStyle } from "../../stockTxns/style";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx";

const PageHeader = ({ goBackHandler = () => {} }) => {
  const { selectedTab, setSelectedTab } = useGetDigitalModuleContext();

  const bottomBorder = {
    borderBottom: "3px solid #00B0F5",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "18px",
  };
  const tabStyle = { cursor: "pointer", position: "relative" };

  return (
    <div
      className="d-flex heading-4 color-dark align-items-center"
      style={headerStyle}
    >
      <div className="d-flex align-items-center">
        <IconButtonWrapper onClick={goBackHandler}>
          <BackIcon />
        </IconButtonWrapper>
      </div>
      <div
        className="d-flex justify-content-center w-100 gap-lg"
        style={{ gap: "45px" }}
      >
        <div
          style={tabStyle}
          className={selectedTab === "DISPATCH_PENDING" ? "color-primary" : ""}
          onClick={() => setSelectedTab("DISPATCH_PENDING")}
        >
          Dispatch pending
          {selectedTab === "DISPATCH_PENDING" && (
            <div style={bottomBorder}></div>
          )}
        </div>
        <div
          style={tabStyle}
          onClick={() => setSelectedTab("DISPATCHED")}
          className={selectedTab === "DISPATCHED" ? "color-primary" : ""}
        >
          Dispatched
          {selectedTab === "DISPATCHED" && <div style={bottomBorder}></div>}
        </div>
        <div
          style={tabStyle}
          onClick={() => setSelectedTab("ON_HOLD")}
          className={selectedTab === "ON_HOLD" ? "color-primary" : ""}
        >
          On hold
          {selectedTab === "ON_HOLD" && <div style={bottomBorder}></div>}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
