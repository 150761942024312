import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Table } from "reactstrap";
import { MasterHeaderAuto } from "../../common/commonComponents/masterHeader/masterHeader";
import {
  NewRow,
  getUniqueKey,
} from "../../common/utils/methods/commonMethods/utilityMethod";
import { CustomCard, CustomContainer } from "../../common/commonComponents";
import { endpoint, commonMessages } from "../../common/constant";
import { putpost } from "services/http";
import {failureToast, successToast} from "../../common/utils/methods/toasterFunctions/function";
import moment from "moment";
import { PermisionDenied } from "../../common/commonComponents";
import CustomButton from "components/CustomButton";
import { ButtonText } from 'variables/Buttons';
import {FieldsTableHeader} from "./FieldsTable";

var _ = require("lodash");

const Cardheader = (props) => {
  return (
    <>
      <h3 className="mb-0 ">Phoenix-Templates</h3>
      {/* <Button
        color="info"
        size="sm"
        type="button"
        className={"floatRight"}
        onClick={() => {
          props.history.push("/admin/template/add");
        }}
        data-testid="addNewDayMaster"
      >
        New Template
      </Button> */}
      <CustomButton
        className={'floatRight mx-1 '}
        content={ButtonText.TEMPLATE.NEW}
        permissionType={'C'}
        icon={true}
        onClick={() => props.history.push("/admin/template/new")}
        permissionSet={props.userPermissions}
      />
    </>
  );
};
const TemplateManagerTable = (props) => {
  const {
    classArray,
    isSaveVisible,
    setisSaveVisible = () => { },
    setclassArray = () => { },
    fetchClasses = () => { },
    userPermissions
  } = props;

  const [tableDataKey, settableDataKey] = useState('tableDataKey');

  const headerList = [
    { name: "S.No" },
    { name: "Process" },
    { name: "Service Name" },
    { name: "Process Type Description" },
    { name: "Template Name" },
    { name: "Version" },
    { name: "effectiveDate" },
    { name: "Status" },
    { name: "updated Date" },
    { name: "Action" }
  ];

  const listToRender = [
    "sno",
    "documentServiceTypeDispValue",
    "documentTypeKey",
    "documentDesc",
    "templateName",
    "version",
    "effectiveDate",
    "status",
    "updatedOn",
  ];

  const updateStatus = (index) => {
    const formData = classArray[index];
    putpost(
      `${endpoint.templateUpload.update}/${formData.id}`,
      (data) => {
        successToast(data["message"]);
        setisSaveVisible(false);
        fetchClasses(
          endpoint.templateUpload.getAll
        );
      },
      (data) => {
        failureToast(data["message"]);
      },
      formData,
      "put"
    );
  }

  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast(commonMessages.pleaseDeleteSaveCurrentChnage);
    } else {
      const { id = null} = classArray[index];
      if(id){
        props.history.push(`/admin/template/edit/${id}`)
        // window.open(`${document.URL}/edit/`, '_blank');
      }else{
        console.log({error:'Id Not Found'});
      }
    }
  };

  const viewRow =(index)=>{
    const { id = null} = classArray[index];
    if(id){
      props.history.push(`/admin/template/view/${id}`)
    }
    // window.open(`${document.URL}/view/${id}`, '_blank');
  }
  const updateKey = (index, value, key) => {
    var _tempArray = classArray;
    _tempArray[index][key] = value;
    setclassArray(_tempArray);
  };
  const NewFieldsTableHeader =(props)=> {

    return (

        <thead className="thead-light">
        <tr>
          {
            props.headerList.map((headerItem, index) =>
                <th
                    style={{
                      ...headerItem.styles ? headerItem.styles :'',
                      width: `${headerItem.hasOwnProperty("width") ? headerItem.width : ""}`,
                    }}
                    className={"text-center text-uppercase white-breakSpace column-heading"}
                    rowSpan={headerItem.rowSpan || "1"}
                    colSpan={headerItem.colSpan || "1"}
                    key={headerItem.name + index}
                >
                  {headerItem.name}
                </th>)
          }
        </tr>
        </thead>
    )
  }

  return (
    userPermissions ?
      <CustomContainer>
        <CustomCard HeaderElement={<Cardheader history={props.history} userPermissions={userPermissions} />}>
          <div id="questionTable" className="table-responsive-layout">
            <Table className="align-items-center">
              <NewFieldsTableHeader headerList={(userPermissions.includes('U') || userPermissions.includes('R')) ? headerList : headerList.slice(0, headerList.length -1)} />
              <tbody className="list" key={getUniqueKey()}>
                {props.classArray
                  ? props.classArray.map((el, index) =>
                    !el["editMode"] ? (
                      <NewRow
                        data={{ ...el, sno: index + (props.currentPage - 1) * 10 + 1 }}
                        listToBeRendered={listToRender}
                      >
                        {
                          userPermissions && (userPermissions.includes('U') || userPermissions.includes('R')) ?
                          <td className="text-center  white-breakSpace">
                            <CustomButton
                              className={'mx-1'}
                              forTable={true}
                              permissionType={'U'}
                              icon={true}
                              disabled={isSaveVisible}
                              onClick={() => editRow(index)}
                              permissionSet={userPermissions}
                          />
                          <CustomButton
                              className={'mx-1'}
                              forTable={true}
                              permissionType={'R'}
                              icon={true}
                              disabled={isSaveVisible}
                              onClick={() => viewRow(index)}
                              permissionSet={userPermissions}
                          />
                        </td> : null}
                      </NewRow>
                    ) : (
                      <tr key={index + "-class"}>
                        {listToRender.map((item, i) => {
                          if (item === "status") {
                            return (
                              <td className="text-center p-2 mx-1">
                                <label className="custom-toggle mx-auto ml-2">
                                  <input
                                    checked={
                                      el.status == "ACTIVE" ? true : false
                                    }
                                    type="checkbox"
                                    id={el.id}
                                    onChange={(e) => {
                                      settableDataKey(new Date().getMilliseconds());
                                      let n = "ACTIVE";
                                      if (!e.target.checked) {
                                        n = "INACTIVE";
                                      }
                                      updateKey(index, n, "status");
                                    }}
                                  />
                                  <span
                                    style={{ width: "72px" }}
                                    className="custom-toggle-slider rounded-circle activeToggle"
                                    data-label-off="Inactive"
                                    data-label-on="Active"
                                  />
                                </label>
                              </td>
                            );
                          } else {
                            return (
                              <td className="text-center  white-breakSpace">
                                {item === "effectiveDate" || item === "updatedOn" ? moment(el.item).format("DD-MM-YYYY") : el[item]}
                              </td>
                            );
                          }
                        })}
                        <td className="text-center">

                          {/* <Button
                          color="info"
                          size="sm"
                          type="button"
                          className="mx-1"
                          onClick={() => {
                            updateStatus(index);
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          color="danger"
                          size="sm"
                          type="button"
                          onClick={() => {
                            fetchClasses(endpoint.templateUpload.getAll);
                            setisSaveVisible(false);
                          }}
                        >

                          <i className="fas fa-times" />
                        </Button> */}
                          <CustomButton
                            className={'mx-1'}
                            content={ButtonText.TEMPLATE.UPDATE}
                            forTable={true}
                            permissionType={'U'}
                            icon={false}
                            onClick={() => updateStatus(index)}
                            permissionSet={userPermissions}
                          />
                          <CustomButton
                            forTable={true}
                            className={'mx-1'}
                            permissionType={'cancel'}
                            icon={true}
                            onClick={() => {
                              fetchClasses(endpoint.templateUpload.getAll);
                              setisSaveVisible(false);
                            }}
                            permissionSet={userPermissions}
                          />
                        </td>
                      </tr>
                    )
                  )
                  : null}
              </tbody>
            </Table>
          </div>
        </CustomCard>
      </CustomContainer> : <PermisionDenied />
  );
};
export default withRouter(TemplateManagerTable);
