import React, { useState } from "react";
import ListHeader from "../../issuanceIDcard/studentList/ListHeader";
// import DlpSearchInput from "../studentList/DlpSearchInput";
// import SelectFilterChip from "./SelectFilterChip";
import { useGetDlpModuleContext } from "../contextApi/dlpContext";
import FilterForm from "./FilterForm";
import ModuleCards from "./ModuleCards";
import ALoader from "views/pages/manage/common/a-loader";
import BranchConsumptionReturnPopup from "../consumptionReturn";

import { useFetchDispatchScheduleDropdown } from "../customhooks";
import { useFetchDropdown } from "../../issuanceIDcard/customhooks";
import StockTransactionsHistoryPopup from "../stockTxns";
import DownloadReportsDialog from "../downloadReports/DownloadReportsDialog";
import AddToFavourite from "../../../homepage/favourites/AddToFavourite";
import { pages } from "views/pages/manage/common/constant";

const ApplicationFilterLandingPage = ({ disabled }) => {
  const {
    selectedFilter,
    setSelectedFilter,
    isApplicationLoading,
    loading,
    filterForm,
    dropdown,
  } = useGetDlpModuleContext();

  const [dropdownDlp, loader] = useFetchDropdown("DLP");

  const [dispatchScheduleDropdown, isDropdownLoading] =
    useFetchDispatchScheduleDropdown(filterForm);
  const [isStockTxnDialogOpen, setIsStockTxnDialogOpen] = useState(false);
  const [
    isStockConsumptionReturnDialogOpen,
    setIsStockConsumptionReturnDialogOpen,
  ] = useState(false);

  const [isDownloadReportsDialogOpen, setIsDownloadReportsDialogOpen] =
    useState(false);

  const stockConsumptionReturnHandler = () => {
    setIsStockConsumptionReturnDialogOpen(true);
  };

  const stockTxnHandler = () => {
    setIsStockTxnDialogOpen(true);
  };

  const downloadReportsClickHandler = () =>
    setIsDownloadReportsDialogOpen(true);

  // console.log(dispatchScheduleDropdown)
  return (
    <div className="" style={{ marginTop: "-1rem" }}>
      <div className="p-3 student-filer-container-printId">
        {(loading || isApplicationLoading || isDropdownLoading) && (
          <ALoader position="fixed" />
        )}
        <div className="d-flex align-items-center justify-content-between">
          <ListHeader text="Search students to mark eligible to issue stocks" />
          <AddToFavourite
            screenName={pages["dlpStockAndInventory"]["name"]}
            screenId={pages["dlpStockAndInventory"]["id"]}
          />
        </div>
        {/* <SelectFilterChip
          isHideSingle = {true}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        /> */}
        {/* <DlpSearchInput disabled={selectedFilter !== "SINGLE"} /> */}
        <FilterForm
          disabled={selectedFilter !== "BULK"}
          dispatchScheduleDropdown={dispatchScheduleDropdown}
        />
      </div>
      <ModuleCards
        stockTxnHandler={stockTxnHandler}
        stockConsumptionReturnHandler={stockConsumptionReturnHandler}
        downloadReportsClickHandler={downloadReportsClickHandler}
      />
      <BranchConsumptionReturnPopup
        open={isStockConsumptionReturnDialogOpen}
        setOpen={setIsStockConsumptionReturnDialogOpen}
        dropdown={dropdownDlp}
        academicType="DLP"
        forDlp={true}
      />
      <StockTransactionsHistoryPopup
        open={isStockTxnDialogOpen}
        setOpen={setIsStockTxnDialogOpen}
        dropdown={dropdown}
        academicType="DLP"
        forDlp={true}
      />

      <DownloadReportsDialog
        dropdown={dropdown}
        open={isDownloadReportsDialogOpen}
        setOpen={setIsDownloadReportsDialogOpen}
        forDlp={true}
      />
    </div>
  );
};

export default ApplicationFilterLandingPage;
