import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { Row, Button, UncontrolledTooltip, Container } from "reactstrap";
import moment from "moment";
import { putpost } from "services/http";
import { CustomContainer, Accordion, Dialog } from "views/pages/manage/common/commonComponents";
import CustomLoader from "views/pages/manage/common/commonComponents/Loader/loader";
import FeeBreakup from "./feeBreakup/index";
import { customValidation } from "views/pages/manage/common/utils/methods/validations/validation";
import validationRules from "./validationHeaderRule.json";
import validationDateInfoRule from "./validationDateInfoRule.json";
import { endpoint, constants } from "../../../../common/constant";
import { FieldsRenderer, YearGrid } from "./components";
import { isEqual } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import "./index.scss";
import { failureToast } from "../../../../common/utils/methods/toasterFunctions/function";
import CustomButton from "../../../../../../../components/CustomButton";
import SupportingDocs from "./supportingDocument"
import SupportingDocView from "./supportingDocument/supportingDocView";
// import { log } from "handlebars";

const statusData = [
  { label: 'ACTIVE', value: 'ACTIVE' },
  { label: 'INACTIVE', value: 'INACTIVE' }
]


const epsilon = 1;

const Form = (props) => {
  const {
    id,
    action,
    headerFields,
    headerValues,
    dateFields,
    dateValues,
    feeFields,
    totals,
    setTotals,
    totalOfFields,
    groupCode,
    companyCodeData,
    courseIdData,
    termData,
    businessAreaData,
    pricingZoneData,
    securityCourseIdData,
    status,
    feeBreakUpData,
    savedFeeBreakUpData,
    isCourseIdSecurityCourseId,
    userPermissions,
    setDateValues = () => { },
    setHeaderValues = () => { },
    setFeeBreak = () => { },
    records,
    setRecords = () => { },
    allRecords,
    setAllRecords = () => { },
    supportingDocs,
    onlinePackageData,
    useOnlinePacakge
  } = props;

  const [isHeaderModalVisible, setIsHeaderModalVisible] = useState(false);
  const [isDateModalVisible, setIsDateModalVisible] = useState(false);

  const [isHeaderFormOpen, setIsHeaderFormOpen] = useState(true);
  const [isHeaderValid, setHeaderValid] = useState(false);
  const [isDateInfoFormOpen, setIsDateInfoFormOpen] = useState(true);
  const [isDateInfoValid, setDateInfoValid] = useState(false);
  const [isSupportingDocValid, setIsSupportingDocValid] = useState(false);

  const [fetchingIsHeaderValid, setFetchingIsHeaderValid] = useState(false);
  const [fetchingIsDateInfoValid, setFetchingIsDateInfoValid] = useState(false);
  const [fetchingIsBreakupValid, setFetchingIsBreakupValid] = useState(false);
  const [isBreakupValid, setIsBreakupValid] = useState(false);
  const [isFormSubmiting, setIsFormSubmiting] = useState(false);
  //preview state
  const [isValidForPreview, setISValidForPreview] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [processInstanceId, setProcessInstanceId] = useState(null);
  const [transactionId, setTransactionId] = useState(null);

  const [processInstanceIdCopied, setProcessInstanceIdCopied] = useState(null);
  const [transactionIdCopied, setTransactionIdCopied] = useState(null);

  const [completeRequestDTO, setRequestObject] = useState({
    totalCourseFeeAndYearDTO: null,
    validateManualCourseFeeRecord: null,
    normalFeeBreakupDTO: null,
    supportingDocDTO: null
  });
  const dataObjects = useOnlinePacakge ?  
  {
    groupCode: groupCode,
    companyCode: companyCodeData,
    courseStatus: status,
    courseId: courseIdData,
    term: termData,
    businessArea: businessAreaData,
    pricingZone: pricingZoneData,
    secuirtyCourseId: securityCourseIdData,
    status: statusData,
    onlinePackageData : onlinePackageData ? onlinePackageData.map((val)=>{
      return {value:val.value,label:`${val.value}-${val.label}`}
    }):onlinePackageData
  }
  : {
    groupCode: groupCode,
    companyCode: companyCodeData,
    courseStatus: status,
    courseId: courseIdData,
    term: termData,
    businessArea: businessAreaData,
    pricingZone: pricingZoneData,
    secuirtyCourseId: securityCourseIdData,
    status: statusData,
    // onlinePackageData : onlinePackageData
  };
  useEffect(() => {
    if (action === 'edit') {
      setIsSupportingDocValid(true)
    }
  }, [action])


  const setSupportingDocDto = (data) => {
    setRequestObject({ ...completeRequestDTO, supportingDocDTO: data });
  }

  const submitHandler = async () => {
      debugger
    setFetchingIsHeaderValid(true);
    let isValid = true;
    isValid = customValidation(headerValues, validationRules);
    if (!isValid) {
      setFetchingIsHeaderValid(false);
      return;
    }

    if (!headerValues.totalNumberOfInstallment && headerValues.totalNumberOfInstallment !==0 ) {
      failureToast("Please Enter Total Number of installments")
      isValid = false;
      setFetchingIsHeaderValid(false);
      return;
    }

     // {"key" : "totalNumberOfInstallment", "isRequired": "true", "type" : "string", "message" : "Please Enter Total Number of installments", "isObject" : true}

    const feeSequence = await setFeeBreak();

    if (!feeSequence) {
      setFetchingIsHeaderValid(false);
      return;
    }

    if (!headerValues.academicCareerId || !headerValues.academicGroupId) {
      failureToast("Course Details Does Not Exists For Selected Course ID");
      isValid = false;
      setFetchingIsHeaderValid(false);
      return;
    }

    if (!dateValues.totalYears) {
      failureToast("No Academic Mapping Exits for Academic Term");
      isValid = false;
      setFetchingIsHeaderValid(false);
      return;
    }

    if (!dateValues.courseMaxDays) {
      failureToast("No Fee Setup Rule Exists for Academic Term");
      isValid = false;
      setFetchingIsHeaderValid(false);
      return;
    }

    // if ((feeBreakUpData && feeBreakUpData.length == 0) || !feeBreakUpData) {
    //   failureToast("No item Type Sequence setup found");
    //   isValid = false;
    //   setFetchingIsHeaderValid(false);
    //   return;
    // }
    const formData = {
      academicCareer: headerValues?.academicCareerId,
      academicGroup: headerValues?.academicGroupId,
      academicTerm: headerValues?.academicTerm?.value,
      businessAreaIdList: headerValues.businessArea.map((item) => {
        return item.businessAreaId;
      }),
      businessGroup: headerValues?.businessGrp?.value,
      companyCode: headerValues?.companyCode?.value,
      courseId: headerValues?.courseId?.value,
      courseTypeId: headerValues?.courseTypeId,
      //"feeEffectiveFromDate":,
      // "feeEffectiveUpto": ,
      securityCourseId: headerValues?.associatedSecurityCourseId?.value,
      franchiseeCommission: headerValues?.franchiseeCommission,
      pricingZone: headerValues?.pricingZone?.value,
      status: headerValues?.headerStatus?.value,
      recordedVideoLectureId:  headerValues?.recordedVideoLectureId,//headerValues?.recordedVideoLectureId.map(i => i.value).join("_"),
      totalNumberOfInstallment: Number(headerValues?.totalNumberOfInstallment),
      onlinePackageId: headerValues?.onlinePackageId && headerValues?.onlinePackageId?.map(i => i.value).join("_")  

    };

    putpost(
      endpoint.manual_processing.validate_header,
      (data) => {
        if (data.code === 200) {
          setHeaderValid(true);
          setIsHeaderFormOpen(false);
          setRequestObject({
            ...completeRequestDTO,
            validateManualCourseFeeRecord: formData,
          });
          setFetchingIsHeaderValid(false);
          setIsDateInfoFormOpen(true);
        }
      },
      (data) => {
        setFetchingIsHeaderValid(false);
        failureToast(data["message"]);
      },
      formData,
      "post"
    );
  };

  const handleDateInfo = () => {
    setFetchingIsDateInfoValid(true);
    let isValid = true;
    isValid = customValidation(dateValues, validationDateInfoRule);

    if (!isValid) {
      setFetchingIsDateInfoValid(false);
      return;
    }

    if (
      new Date(dateValues.termStartDate).getTime() >
      new Date(dateValues.courseStartDate).getTime()
    ) {
      failureToast(`Course Start Date Should Be After Term Start Date`);
      isValid = false;
      return false;
    }

    if (!isValid) {
      setFetchingIsDateInfoValid(false);
      return;
    }

    dateValues.totalYears &&
      [...Array(dateValues.totalYears)].every((_, i) => {
        if (!dateValues[constants.course_fee_year_start_date_objs[i + 1]]) {
          failureToast(
            `Please Select ${constants.course_fee_years[i]} Year Start Date`
          );
          isValid = false;
          return false;
        }

        if (!dateValues[constants.course_fee_year_end_date_objs[i + 1]]) {
          failureToast(
            `Please Select ${constants.course_fee_years[i]} Year End Date`
          );
          isValid = false;
          return false;
        }
      });

    if (!isValid) {
      setFetchingIsDateInfoValid(false);
      return;
    }

    const totalCourseFeeAndYearDTO = {
      ...dateValues,
      totalNumberOfYears: dateValues.totalYears,
      feeEffectiveFromDate: (dateValues.feeEffectiveDateFrom && moment(dateValues.feeEffectiveDateFrom).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
      feeEffectiveToDate: (dateValues.feeEffectiveDateTo && moment(dateValues.feeEffectiveDateTo).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
      forthYearStartDate: (dateValues.fourthYearStartDate && moment(dateValues.fourthYearStartDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
      forthYearEndDate: (dateValues.fourthYearEndDate && moment(dateValues.fourthYearEndDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',


      courseStartDate: moment(dateValues.courseStartDate).utcOffset("+05:30").format("YYYY-MM-DD"),
      courseEndDate: moment(dateValues.courseEndDate).utcOffset("+05:30").format("YYYY-MM-DD"),
      termStartDate: (dateValues.termStartDate && moment(dateValues.termStartDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',

      firstYearStartDate: moment(dateValues.firstYearStartDate).utcOffset("+05:30").format("YYYY-MM-DD"),
      secondYearStartDate: (dateValues.secondYearStartDate && moment(dateValues.secondYearStartDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
      thirdYearStartDate: (dateValues.thirdYearStartDate && moment(dateValues.thirdYearStartDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',

      fifthYearStartDate: (dateValues.fifthYearStartDate && moment(dateValues.fifthYearStartDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
      sixthYearStartDate: (dateValues.sixthYearStartDate && moment(dateValues.sixthYearStartDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
      seventhYearStartDate: (dateValues.seventhYearStartDate && moment(dateValues.seventhYearStartDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
      eightYearStartDate: (dateValues.eightYearStartDate && moment(dateValues.eightYearStartDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
      firstYearEndDate: (dateValues.firstYearEndDate && moment(dateValues.firstYearEndDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
      secondYearEndDate: (dateValues.secondYearEndDate && moment(dateValues.secondYearEndDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
      thirdYearEndDate: (dateValues.thirdYearEndDate && moment(dateValues.thirdYearEndDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',

      fifthYearEndDate: (dateValues.fifthYearEndDate && moment(dateValues.fifthYearEndDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
      sixthYearEndDate: (dateValues.sixthYearEndDate && moment(dateValues.sixthYearEndDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
      seventhYearEndDate: (dateValues.seventhYearEndDate && moment(dateValues.seventhYearEndDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
      eightYearEndDate: (dateValues.eightYearEndDate && moment(dateValues.eightYearEndDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
    };

    setRequestObject({
      ...completeRequestDTO,
      totalCourseFeeAndYearDTO: totalCourseFeeAndYearDTO,
    });

    setDateInfoValid(true);
    setIsDateInfoFormOpen(false);
    setFetchingIsDateInfoValid(false);
  };

  const feeBreakupHandler = (itemObj, stateArr) => {
    try {
      setFetchingIsBreakupValid(true);
      let isValid = true;
      let previousYear = 0;

      const itemArray = []

      itemObj.every((element, i) => {

        if (element?.isDeleted) {
          return true
        }

        if (!stateArr.itemTypesId?.[i]?.value && headerValues?.courseType === 'MSCR') {
          failureToast(
            `Please Select Item Type for ${i + 1} row`
          );
          isValid = false;
          return false;
        }

        if (headerValues?.courseType === 'MSCR') {
          const inList = itemArray.includes(stateArr.itemTypesId?.[i]?.value)
          if (inList) {
            failureToast(
              `Fee Component Should be unique`
            );
            isValid = false;
            return false;
          } else {
            itemArray.push(stateArr.itemTypesId?.[i]?.value)
          }
        }

        const id = headerValues?.courseType === 'MSCR' ? stateArr.itemTypesId?.[i]?.value : element.itemTypeSetupId

        if (!stateArr?.dueDate?.[i]) {
          failureToast(
            `Please Enter Due Date For Item Type ${id}`
          );
          isValid = false;
          return false;
        } else if (!(stateArr?.finalAmount?.[i] > 0 || stateArr?.finalAmount?.[i] == 0 )) {
          failureToast(
            `Please Enter Final Amount For Item Type ${id} `
          );
          isValid = false;
          return false;
        } else if (!stateArr?.year?.[i]) {
          failureToast(
            `Please Enter Year For Item Type ${id} `
          );
          isValid = false;
          return false;
        } else if (
          stateArr?.year?.[i] &&
          (stateArr?.year?.[i] > dateValues.totalYears ||
            stateArr?.year?.[i] == 0)
        ) {
          failureToast(
            `Please Enter Valid Year For Item Type ${id} `
          );
          isValid = false;
          return false;
        }
        else if (previousYear > stateArr?.year?.[i]) {
          failureToast(
            `Year must be greater than or equals to the pervious year for item type ${id}`
          );
          isValid = false;
          return false;
        }
        else {
          previousYear = stateArr?.year?.[i];
          return true;
        }
      });

      if (!isValid) {
        setFetchingIsBreakupValid(false);
        return;
      }


      const feebreakupDTO = itemObj.map((element, i) => {
        if (!element?.isDeleted) {
          if (headerValues?.courseType !== 'MSCR') {
            return {
              ...element,
              itemType: element.itemTypeSetupId,
              dueDate: moment(stateArr?.dueDate?.[i]).utcOffset("+05:30").format("YYYY-MM-DD"),
              finalAmount: stateArr?.finalAmount?.[i],
              year: stateArr?.year?.[i],
              baseAmount: stateArr?.baseAmount?.[i],
              tax: stateArr?.tax?.[i],
              cash: stateArr?.cash?.[i],
              cheque: stateArr?.cheque?.[i]
            };
          } else {
            return {
              ...element,
              itemType: stateArr.itemTypesId?.[i]?.value,
              dueDate: moment(stateArr?.dueDate?.[i]).utcOffset("+05:30").format("YYYY-MM-DD"),
              finalAmount: stateArr?.finalAmount?.[i],
              year: stateArr?.year?.[i],
              baseAmount: stateArr?.baseAmount?.[i],
              tax: stateArr?.tax?.[i],
              cash: stateArr?.cash?.[i],
              cheque: stateArr?.cheque?.[i],
              itemTypeSequenceDetailId: stateArr.itemTypesId?.[i]?.itemTypeSequenceDetailId,
              installmentNumber: 0
            };
          }
        }else{
          return null
        }

      });

      let totalBaseFee = 0;
      let totalTax = 0;
      let totalFinalAmount = 0;

      feebreakupDTO.every((item) => {
        if(item == null){
          return true;
        }
        totalBaseFee = Number(totalBaseFee) + Number(item.baseAmount ?? 0);
        totalTax = Number(totalTax) + Number(item.tax ?? 0);
        totalFinalAmount =
          Number(totalFinalAmount) + Number(item.finalAmount ?? 0);
        return true;
      });

      setTotals({
        totalBaseFee: totalBaseFee,
        totalTax: totalTax,
        finalTotalAmount: totalFinalAmount,
      });

      setTimeout(5000);
      setRequestObject({
        ...completeRequestDTO,
        normalFeeBreakupDTO: feebreakupDTO,
      });
      setIsBreakupValid(true);
      setFetchingIsBreakupValid(false);
      setAllAccordionOpen(true);
    } catch (e) {
      console.log(e);
    }
  };

  const settingDtoForEdit = () => {
    let isValid = true;
    isValid = customValidation(headerValues, validationRules);
    
    if (headerValues.totalNumberOfInstallment === null ||  headerValues.totalNumberOfInstallment === undefined) {
      // alert("i am here")
      failureToast("Please Enter Total Number of installments")
      isValid = false;
      setFetchingIsHeaderValid(false);
      return;
    }
    isValid = customValidation(dateValues, validationDateInfoRule);
    dateValues.totalYears &&
      [...Array(dateValues.totalYears)].every((_, i) => {
        if (!dateValues[constants.course_fee_year_start_date_objs[i + 1]]) {
          failureToast(
            `Please Select ${constants.course_fee_years[i]} Year Start Date`
          );
          isValid = false;
          return false;
        }

        if (!dateValues[constants.course_fee_year_end_date_objs[i + 1]]) {
          failureToast(
            `Please Select ${constants.course_fee_years[i]} Year End Date`
          );
          isValid = false;
          return false;
        }
      });

    return isValid;
  }

  const createManualFeeBreakup = () => {
    setIsFormSubmiting(true);

    let isValid = true;

    let requestDTOForEdit = {};

    if (isEqual(action, 'edit')) {
      isValid = settingDtoForEdit();

      if (isValid) {
        const formData = {
          academicCareer: headerValues?.academicCareerId,
          academicGroup: headerValues?.academicGroupId,
          academicTerm: headerValues?.academicTerm?.value,
          businessAreaIdList: headerValues.businessArea.map((item) => {
            return item.businessAreaId;
          }),
          businessGroup: headerValues?.businessGrp?.value,
          companyCode: headerValues?.companyCode?.value,
          courseId: headerValues?.courseId?.value,
          courseTypeId: headerValues?.courseTypeId,
          //"feeEffectiveFromDate":,
          // "feeEffectiveUpto": ,
          franchiseeCommission: 0,
          pricingZone: headerValues?.pricingZone?.value,
          securityCourseId: 0,
          status: headerValues?.headerStatus?.value,
          securityCourseId: headerValues?.associatedSecurityCourseId?.value,
          franchiseeCommission: headerValues?.franchiseeCommission,
          recordedVideoLectureId:   headerValues?.recordedVideoLectureId,//headerValues?.recordedVideoLectureId.map(i => i.value).join("_"),//headerValues?.recordedVideoLectureId, //.map(i => i.value).join("_"),
          totalNumberOfInstallment: Number(headerValues?.totalNumberOfInstallment),
          onlinePackageId: headerValues?.onlinePackageId && headerValues?.onlinePackageId?.map(i => i.value).join("_")  

        };

        const totalCourseFeeAndYearDTO = {
          ...dateValues,
          // totalNumberOfYears: dateValues.totalYears,
          // feeEffectiveFromDate: dateValues.feeEffectiveDateFrom,
          // feeEffectiveToDate: dateValues.feeEffectiveDateTo,

          totalNumberOfYears: dateValues.totalYears,
          feeEffectiveFromDate: (dateValues.feeEffectiveDateFrom && moment(dateValues.feeEffectiveDateFrom).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
          feeEffectiveToDate: (dateValues.feeEffectiveDateTo && moment(dateValues.feeEffectiveDateTo).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
          forthYearStartDate: (dateValues.fourthYearStartDate && moment(dateValues.fourthYearStartDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
          forthYearEndDate: (dateValues.fourthYearEndDate && moment(dateValues.fourthYearEndDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',


          courseStartDate: moment(dateValues.courseStartDate).utcOffset("+05:30").format("YYYY-MM-DD"),
          courseEndDate: moment(dateValues.courseEndDate).utcOffset("+05:30").format("YYYY-MM-DD"),
          termStartDate: (dateValues.termStartDate && moment(dateValues.termStartDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',

          firstYearStartDate: moment(dateValues.firstYearStartDate).utcOffset("+05:30").format("YYYY-MM-DD"),
          secondYearStartDate: (dateValues.secondYearStartDate && moment(dateValues.secondYearStartDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
          thirdYearStartDate: (dateValues.thirdYearStartDate && moment(dateValues.thirdYearStartDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',

          fifthYearStartDate: (dateValues.fifthYearStartDate && moment(dateValues.fifthYearStartDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
          sixthYearStartDate: (dateValues.sixthYearStartDate && moment(dateValues.sixthYearStartDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
          seventhYearStartDate: (dateValues.seventhYearStartDate && moment(dateValues.seventhYearStartDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
          eightYearStartDate: (dateValues.eightYearStartDate && moment(dateValues.eightYearStartDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
          firstYearEndDate: (dateValues.firstYearEndDate && moment(dateValues.firstYearEndDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
          secondYearEndDate: (dateValues.secondYearEndDate && moment(dateValues.secondYearEndDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
          thirdYearEndDate: (dateValues.thirdYearEndDate && moment(dateValues.thirdYearEndDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',

          fifthYearEndDate: (dateValues.fifthYearEndDate && moment(dateValues.fifthYearEndDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
          sixthYearEndDate: (dateValues.sixthYearEndDate && moment(dateValues.sixthYearEndDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
          seventhYearEndDate: (dateValues.seventhYearEndDate && moment(dateValues.seventhYearEndDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
          eightYearEndDate: (dateValues.eightYearEndDate && moment(dateValues.eightYearEndDate).utcOffset("+05:30").format("YYYY-MM-DD")) ?? '',
        };


        requestDTOForEdit = {
          totalCourseFeeAndYearDTO: totalCourseFeeAndYearDTO,
          validateManualCourseFeeRecord: formData,
          normalFeeBreakupDTO: completeRequestDTO.normalFeeBreakupDTO,
          supportingDocDTO: supportingDocs
        }
      }
    }

    if (!isValid) {
      return
    }

    setTimeout(5000);

    const url = isEqual(action, 'edit') ? `${endpoint.manual_processing.edit}/${id}` : endpoint.manual_processing.create;
    const method = isEqual(action, 'edit') ? "put" : "post";
    const requestDTO = isEqual(action, 'edit') ? requestDTOForEdit : completeRequestDTO;
    putpost(
      url,
      (data) => {
        setIsFormSubmiting(false);
        // successToast(data['message']);
        // props.history.push("/admin/courseFeeInformation")
        setProcessInstanceId(data.data?.processInstanceId);
        setTransactionId(data.data?.transactionId);
        setIsSuccessModalVisible(true);
      },
      (data) => {
        setIsFormSubmiting(false);
        // alert(data["message"])
        failureToast(data["message"]);
      },
      requestDTO,
      method
    );
  };

  // useEffect(() => {
  //   if (isValidForPreview) {
  //     setIsHeaderFormOpen(true);
  //     setIsDateInfoFormOpen(true);
  //   }else{
  //     setIsHeaderFormOpen(false);
  //     setIsDateInfoFormOpen(false);
  //   }
  // }, [isValidForPreview]);

  const setAllAccordionOpen = () => {
    setISValidForPreview(true);
    setIsHeaderFormOpen(true);
    setIsDateInfoFormOpen(true);
  };

  const setAllAccordionClose = () => {
    setISValidForPreview(false);
    setIsHeaderFormOpen(false);
    setIsDateInfoFormOpen(false);
  };

  return (
    <CustomContainer>
      {/* header */}
      <Accordion title="Course Information" isFormOpen={isHeaderFormOpen}>
        {!groupCode ? (
          <div
            className="mx-auto text-center py-5 my-5"
            style={{ height: "100vh" }}
          >
            <CustomLoader apiLoader={!groupCode} />
          </div>
        ) : (
          <>
            <Row className="p-4 course-fee-header">
              <FieldsRenderer
                list={Object.keys(headerFields)}
                properties={headerFields}
                values={headerValues}
                dataObjects={dataObjects}
                setValues={setHeaderValues}
                fetchingIsHeaderValid={fetchingIsHeaderValid}
                isCourseIdSecurityCourseId={isCourseIdSecurityCourseId}
                isDisabled={isValidForPreview || isHeaderValid}
                action={action}
              />
            </Row>
            {!isHeaderValid && !isEqual(action, 'edit') && (
              <Row className="justify-content-end pl-4 pr-4 pb-4">
                {/* <Button
                  color="info"
                  size="sm"
                  type="button"
                  className="mx-1"
                  disabled={fetchingIsHeaderValid}
                  onClick={submitHandler}
                >
                  {fetchingIsHeaderValid ? (
                    <i
                      className="fas fa-spinner fa-spin"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    "Next"
                  )}
                </Button> */}
                <CustomButton
                  content={fetchingIsHeaderValid ? (
                    <i
                      className="fas fa-spinner fa-spin"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    "Next"
                  )}
                  permissionType={'C,U'}
                  permissionSet={userPermissions}
                  onClick={submitHandler}
                  disabled={fetchingIsHeaderValid}
                />
              </Row>
            )}
            {isHeaderValid && !isValidForPreview && !isEqual(action, 'edit') && (
              <Row className="justify-content-end pl-4 pr-4 pb-4">
                {/* <Button
                  color="info"
                  size="sm"
                  type="button"
                  className="mx-1"
                  disabled={fetchingIsHeaderValid}
                  onClick={() => setIsHeaderModalVisible(true)}
                >
                  Edit
                </Button> */}
                <CustomButton
                  type="edit"
                  color="info"
                  content={"Edit"}
                  permissionType={'C,U'}
                  permissionSet={userPermissions}
                  disabled={fetchingIsHeaderValid}
                  onClick={() => setIsHeaderModalVisible(true)}
                />
              </Row>
            )}
          </>
        )}
      </Accordion>

      {/* date */}
      {((isHeaderValid && headerValues?.academicTerm?.value) || isEqual(action, 'edit')) && (
        <Accordion
          title="Date Information"
          rightSideInfo={
            dateValues.totalYears ? (
              <h3>{`Total Numbers Year : ${dateValues.totalYears}`}</h3>
            ) : null
          }
          isFormOpen={isDateInfoFormOpen}
        >
          <Row className="p-4">
            <FieldsRenderer
              list={Object.keys(dateFields)}
              properties={dateFields}
              values={dateValues}
              dataObjects={dataObjects}
              setValues={setDateValues}
              isDisabled={isValidForPreview || isDateInfoValid}
            />
          </Row>
          {dateValues?.courseStartDate && dateValues?.courseEndDate && (
            <Row className="flex-wrap p-4">
              {dateValues?.totalYears &&
                [...Array(dateValues?.totalYears)].map((_, i) => (
                  <YearGrid
                    title={`${constants.course_fee_years[i]} Year`}
                    dateValues={dateValues}
                    setDateValues={setDateValues}
                    position={i + epsilon}
                    isDisabled={isValidForPreview || isDateInfoValid}
                  />
                ))}
            </Row>
          )}
          {!isDateInfoValid && !isEqual(action, 'edit') && (
            <Row className="justify-content-end pl-4 pr-4 pb-4">
              {/* <Button
                color="info"
                size="sm"
                type="button"
                className="mx-1"
                disabled={fetchingIsDateInfoValid || isDateInfoValid}
                onClick={handleDateInfo}
              >
                {fetchingIsDateInfoValid ? (
                  <i className="fas fa-spinner fa-spin" aria-hidden="true"></i>
                ) : (
                  "Next"
                )}
              </Button> */}
              <CustomButton
                content={fetchingIsHeaderValid ? (
                  <i
                    className="fas fa-spinner fa-spin"
                    aria-hidden="true"
                  ></i>
                ) : (
                  "Next"
                )}
                permissionType={'C,U'}
                permissionSet={userPermissions}
                onClick={handleDateInfo}
                disabled={fetchingIsDateInfoValid || isDateInfoValid}
              />
            </Row>
          )}
          {isDateInfoValid && !isValidForPreview && (
            <Row className="justify-content-end pl-4 pr-4 pb-4">
              {/* <Button
                color="info"
                size="sm"
                type="button"
                className="mx-1"
                disabled={fetchingIsHeaderValid}
                onClick={() => setIsDateModalVisible(true)}
              >
                Edit
              </Button> */}
              <CustomButton
                type="edit"
                color="info"
                content={"Edit"}
                permissionType={'C,U'}
                permissionSet={userPermissions}
                onClick={() => setIsDateModalVisible(true)}
                disabled={fetchingIsHeaderValid}
              />
            </Row>
          )}
        </Accordion>
      )}

      {isDateInfoValid && action !== 'edit' && <SupportingDocs
        userPermissions={userPermissions}
        records={records}
        setRecords={setRecords}
        allRecords={allRecords}
        setAllRecords={setAllRecords}
        setIsSupportingDocValid={setIsSupportingDocValid}
        setSupportingDocDto={setSupportingDocDto}
        isPreview={isValidForPreview || isEqual(action, 'edit')}
        isFormOpen={isDateInfoValid || isValidForPreview}
      />}

      {isEqual(action, 'edit') && <Accordion
        title="Supporting Document"
        isFormOpen={true}
      >
        <SupportingDocView data={supportingDocs} userPermissions={userPermissions} />
      </Accordion>}


      {/* fee breakup */}
      {((isSupportingDocValid && isHeaderValid) || isEqual(action, 'edit')) &&
        headerValues.companyCode?.value &&
        headerValues.businessGrp?.value && feeBreakUpData ? (
        <FeeBreakup
          groupCodeId={headerValues.businessGrp?.value}
          companyCodeId={headerValues.companyCode?.value}
          academicCareerId={headerValues.academicCareerId}
          termId={headerValues.academicTerm?.value}
          courseTypeId={headerValues.courseTypeId}
          courseType={headerValues.courseType}
          action={action}
          feeBreakupHandler={feeBreakupHandler}
          fetchingIsBreakupValid={fetchingIsBreakupValid}
          setFetchingIsBreakupValid={setFetchingIsBreakupValid}
          isDisabled={isValidForPreview}
          courseStartDate={dateValues.courseStartDate}
          courseEndDate={dateValues.courseEndDate}
          totalYears={dateValues.totalYears}
          feeBreakup={feeBreakUpData}
          savedFeeBreakUpData={savedFeeBreakUpData}
          userPermissions={userPermissions}
        />
      ) : null}

      {/* fee details */}
      {isValidForPreview && (
        <Accordion title="Fee Information" isFormOpen={true}>
          <Row className="p-4">
            <FieldsRenderer
              breakPoint={"4"}
              list={Object.keys(totalOfFields)}
              properties={totalOfFields}
              values={totals}
              dataObjects={dataObjects}
              setValues={setTotals}
            />
          </Row>
        </Accordion>
      )}

      {isValidForPreview && (
        <Row className="justify-content-between pl-4 pr-4 pb-4 mt-4">
          {/* <Button
            color="info"
            size="sm"
            type="button"
            className="mx-1"
            disabled={isFormSubmiting}
            onClick={() => setAllAccordionClose()}
          >
            Back To Edit
          </Button> */}
          <CustomButton
            type="back"
            color="info"
            content={"Back To Edit"}
            permissionType={'C,U'}
            permissionSet={userPermissions}
            onClick={() => setAllAccordionClose()}
            disabled={isFormSubmiting}
          />
          {/* <Button
            color="info"
            size="sm"
            type="button"
            className="mx-1"
            disabled={isFormSubmiting}
            onClick={createManualFeeBreakup}
          >
            {isFormSubmiting ? (
              <i className="fas fa-spinner fa-spin" aria-hidden="true"></i>
            ) : (
              "Confirm & Submit"
            )}
          </Button> */}
          <CustomButton
            color="info"
            content={isFormSubmiting ? (
              <i className="fas fa-spinner fa-spin" aria-hidden="true"></i>
            ) : (
              "Confirm & Submit"
            )}
            permissionType={'C,U'}
            permissionSet={userPermissions}
            onClick={createManualFeeBreakup}
            disabled={isFormSubmiting}
          />
        </Row>
      )}

      <Dialog
        isModalVisible={isHeaderModalVisible}
        toggle={() => setIsHeaderModalVisible(!isHeaderModalVisible)}
        title=""
        size="sm"
      >
        {/* <CustomContainer> */}
        <Row>
          <span className="text-danger alert">
            You may lose your below filled data! Are you sure you want to
            continue ?
          </span>
        </Row>
        <Row className="justify-content-center flex-row">
          {/* <Button
            color="warning"
            size="sm"
            type="button"
            className="my-2"
            onClick={() => {
              setIsHeaderModalVisible(false);
            }}
          >
            No
          </Button> */}
          <CustomButton
            type="normal"
            color="warning"
            content={"No"}
            permissionType={'C,U'}
            permissionSet={userPermissions}
            onClick={() => {
              setIsHeaderModalVisible(false);
            }}
          />
          {/* <Button
            color="info"
            size="sm"
            type="button"
            className="my-2"
            onClick={() => {
              setHeaderValid(false);
              setIsHeaderFormOpen(true);
              setDateInfoValid(false);
              setIsDateInfoFormOpen(false);
              setIsHeaderModalVisible(false);
            }}
          >
            Yes
          </Button> */}
          <CustomButton
            type="normal"
            color="info"
            content={"Yes"}
            permissionType={'C,U'}
            permissionSet={userPermissions}
            onClick={() => {
              setHeaderValid(false);
              setIsHeaderFormOpen(true);
              setDateInfoValid(false);
              setIsDateInfoFormOpen(false);
              setIsHeaderModalVisible(false);
            }}
          />
        </Row>
        {/* </CustomContainer> */}
      </Dialog>
      <Dialog
        isModalVisible={isDateModalVisible}
        toggle={() => setIsDateModalVisible(!isDateModalVisible)}
        title=""
        size="sm"
      >
        <Row>
          <span className="text-danger alert">
            You may lose your below filled data! Are you sure you want to
            continue ?
          </span>
        </Row>
        <Row className="justify-content-center flex-row">
          {/* <Button
            color="warning"
            size="sm"
            type="button"
            className="my-2"
            onClick={() => {
              setIsDateModalVisible(false);
            }}
          >
            No
          </Button> */}
          <CustomButton
            type="normal"
            color="warning"
            content={"No"}
            permissionType={'C,U'}
            permissionSet={userPermissions}
            onClick={() => {
              setIsDateModalVisible(false);
            }}
          />
          {/* <Button
            color="info"
            size="sm"
            type="button"
            className="my-2"
            onClick={() => {
              setDateInfoValid(false);
              setIsDateInfoFormOpen(true);
              setIsDateModalVisible(false);
            }}
          >
            Yes
          </Button> */}
          <CustomButton
            type="normal"
            color="info"
            content={"Yes"}
            permissionType={'C,U'}
            permissionSet={userPermissions}
            onClick={() => {
              setDateInfoValid(false);
              setIsDateInfoFormOpen(true);
              setIsDateModalVisible(false);
            }}
          />
        </Row>
      </Dialog>

      <Dialog isModalVisible={isSuccessModalVisible} toggle={() => { }} title="" size="md">
        <Row>
          <h4 className="text-success alert mt-n4">
            Record is saved successfully.
          </h4>
        </Row>
        <Row className="flex-row">
          <span className="text-info alert mt-n4" style={{ width: '100%' }}>
            {`Processs Instance Id`}
            <CopyToClipboard
              text={processInstanceId}
              onCopy={() => setProcessInstanceIdCopied(processInstanceId)}
            >
              <button
                className="btn-icon-clipboard"
                data-clipboard-text={processInstanceId}
                id="tooltip932383030"
                type="button"
              >
                <div>
                  <span>{processInstanceId}</span>
                </div>
              </button>
            </CopyToClipboard>
            <UncontrolledTooltip
              delay={0}
              trigger="hover focus"
              target="tooltip932383030"
            >
              {processInstanceIdCopied === processInstanceId
                ? "Copied"
                : "Copy To Clipbord"}
            </UncontrolledTooltip>
          </span>
          <span className="text-info alert mt-n4 " style={{ width: '100%' }}>
            {`Transaction Id`}
            <CopyToClipboard
              text={transactionId}
              onCopy={() => setTransactionIdCopied(transactionId)}
            >
              <button
                className="btn-icon-clipboard"
                data-clipboard-text={transactionId}
                id="tooltip932383034"
                type="button"
              >
                <div>
                  <span>{transactionId}</span>
                </div>
              </button>
            </CopyToClipboard>
            <UncontrolledTooltip
              delay={0}
              trigger="hover focus"
              target="tooltip932383034"
            >
              {transactionIdCopied === transactionId
                ? "Copied"
                : "Copy To Clipbord"}
            </UncontrolledTooltip>
          </span>
        </Row>
        <Row className="justify-content-center flex-row">
          <Button
            color="info"
            size="sm"
            type="button"
            className="my-2"
            onClick={() => {
              setProcessInstanceId(null);
              setTransactionId(null);
              props.history.push("/admin/courseFeeInformation");
              setIsSuccessModalVisible(false);
            }}
          >
            Close
          </Button>
        </Row>
      </Dialog>
    </CustomContainer>
  );
};

export default withRouter(Form);
