import React, { useContext, useEffect, useState } from "react";
import { fetchAllPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "views/pages/manage/common/constant";
import { Card, CardBody } from "reactstrap";
import { Header, LectureListItem, badge } from "./LectureListItem";
import ViewLectureModal from "./ViewLectureModal";
import AddLectureModalForm from "./AddLectureModalForm";
import AddPTMModalForm from "./AddPTMModalForm";
import AddEventModal from "./AddEventModal";
import AddCustomHolidayModal from "./AddCustomHolidayModal";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import { Divider, TablePagination } from "@mui/material";
import EmptyTestState from "../academicTimetableListView/EmptyTestState";
import { TimetableContext } from "../contextApi/TimetableContext";

const LectureList = ({
  setOpenForm,
  testsArray,
  roomsDropdown,
  testUpdateHandler,
  isReadyToPublish,
  ...props
}) => {
  const [allTestCount, setAllTestCount] = useState(0);
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);
  const [isLectureFormOpen, setIsLectureFormOpen] = useState(false);
  const [showPastLectures, setShowPastLectures] = useState(false);
  // const [lecturesToShow, setLecturesToShow]=useState([]);
  const [isPTMFormOpen, setIsPTMFormOpen] = useState(false);
  const [ptmRowData, setPtmRowData] = useState(false);
  const [editedRow, setEditedRow] = useState(false);
  const [customHoliday, setOpenCustomHolidayModal] = useState(false);
  const [formType, setFormType] = useState("PTM"); // this state is to handle orientation and PTM form types

  const {
    pastLectures,
    totalPastLectureCount,
    pastLecturePage,
    setPastLecturePage,
    selectedFaculty,
    academicCareer
  } = useContext(TimetableContext);
  const handleOpenLectureModal = (type) => {
    setIsAddEventModalOpen(false);
    setIsLectureFormOpen(true);
    setFormType(type)
  };
  const handleOpenPTMModal = (type, data, rowIndex) => {
    setFormType(type);
    setIsAddEventModalOpen(false);
    setIsPTMFormOpen(true);
    if (data) setPtmRowData({ ...data, mode: "EDIT", rowIndex });
  };

  const handleOpenHolidayModal = (data, rowIndex) => {
    setFormType("Holiday");
    setOpenCustomHolidayModal(true);
    setEditedRow({ ...data, mode: "EDIT", rowIndex });
  };

  const [open, setOpen] = useState(false);
  const [lectureId, setLectureId] = useState(null);
  const viewLectureHandler = (lectureId) => {
    setOpen(true);
    setLectureId(lectureId);
  };
  const getAllTestCountOfAcademicPlan = async () => {
    if (props?.formData?.plan?.value) {
      const res = await fetchAllPromisedData(
        `${endpoint?.academicPlanTests?.getAllTestScheduleByAcademicPlanId}/${props?.formData?.plan?.value}`,
        true
      );
      if (res?.code === 200) {
        setAllTestCount(res?.data?.length);
      }
    }
  };
  useEffect(() => {
    getAllTestCountOfAcademicPlan();
  }, [props?.formData, props?.openMode]);

  // useEffect(()=>{
  //   getLectures(showPastLectures)
  // },[showPastLectures,props?.lectures])

  const rowChangeHandler = (rowIdx, updatedRowObject, isSave = false) => {
    let updatedRows = [...props?.lectures];
    updatedRows[rowIdx] = updatedRowObject;
    props.updateLecturesArray(updatedRows);
    if (
      props.onEditSave &&
      isSave &&
      !updatedRowObject?.isNew &&
      updatedRowObject?.id
    ) {
      props.onEditSave(props?.skipLectureWithOutId(updatedRows), true);
    }
  };

  const deleteHandler = (rowIndex) => {
    let filteredLecture = props?.lectures?.filter(
      (item, index) => rowIndex !== index
    );
    props.updateLecturesArray(filteredLecture);
    if (
      props.onEditSave &&
      props?.mode === "EDIT" &&
      props?.lectures[rowIndex]?.id
    ) {
      props.onEditSave(
        props?.skipLectureWithOutId(filteredLecture),
        true,
        true,
        props?.lectures?.[rowIndex]?.id
      );
    }
  };
  // const getLectures=(showPastLectures)=>{
  //   if(showPastLectures) setLecturesToShow( props?.lectures);
  //   else setLecturesToShow( props?.lectures.filter(lecture=>!isLecturePassed(lecture?.lectureEndTime)))

  // }
  // const getMessage=()=>{
  //   if(props?.lectures?.length===0) return "No Lectures scheduled for this batch"
  //   return "No upcoming lectures scheduled for this batch. Try searching lectures scheduled in the past"
  // }

  return (
    <>
      <div
        className="d-flex justify-content-end ml-2"
        style={{ padding: "10px 20px" }}
      >
        {props?.mode !== "VIEW" && (
          <AButton
            onClick={() => {
              setIsAddEventModalOpen(true);
              setShowPastLectures(false);
            }}
            updatedStyle={{
              border: "1px solid #E9ECEF",
              color: "#0F0F0F",
              fontWeight: "600",
            }}
          >
            Add Event
          </AButton>
        )}
        <AButton
          onClick={() => {
            setShowPastLectures((prev) => !prev);
          }}
          updatedStyle={{
            border: showPastLectures
              ? "1px solid #00B0F5"
              : "1px solid #E9ECEF",
            color: "#0F0F0F",
            fontWeight: "600",
          }}
        >
          {showPastLectures ? "Hide Past Events" : " Show Past Events"}
        </AButton>
      </div>
      <Card className="m-2 mt-0" style={{ overflow: "auto" }}>
        <CardBody
          className="p-2"
          style={{ minWidth: "100%", width: "fit-content" }}
        >
          {(props?.lectures?.length > 0 || pastLectures?.length > 0) && (
            <Header
              isEnabledMsTeamsSync={props?.isEnabledMsTeamsSync}
              openMode={props?.mode}
              showActions={!showPastLectures}
            />
          )}
          {!showPastLectures ? (
            <div>
              {props?.lectures?.length === 0 && (
                <div className="my-2" style={{ ...badge, height: "90vh" }}>
                  <EmptyTestState
                    message="No Upcoming lectures scheduled. To view previous dates
                  lectures, click on Show past events"
                  />
                </div>
              )}
              {props?.lectures?.length > 0 ? (
                props?.lectures?.map((item, idx) => (
                  <LectureListItem
                    key={idx}
                    rowData={item}
                    rowIndex={idx}
                    rowChangeHandler={rowChangeHandler}
                    deleteHandler={deleteHandler}
                    currentRoom={props?.currentRoom}
                    roomDropdown={roomsDropdown}
                    viewLectureHandler={viewLectureHandler}
                    expandedIndex={expandedIndex}
                    setExpandedIndex={setExpandedIndex}
                    handleOpenPTMModal={handleOpenPTMModal}
                    handleOpenHolidayModal={handleOpenHolidayModal}
                    selectedFaculty = {selectedFaculty}

                    {...props}
                  />
                ))
              ) : (
                // <EmptyTestState
                // message={getMessage()}
                // />
                <></>
              )}
            </div>
          ) : (
            <div>
              {!pastLectures && (
                <div className="my-2" style={badge}>
                  No Lectures scheduled in the last 6 months. To view lectures
                  that are scheduled earlier than 6 months, please refer the
                  timetable report in Quicksight Dashboard.
                </div>
              )}
              {pastLectures?.length > 0 ? (
                pastLectures?.map((item, idx) => (
                  <LectureListItem
                    key={idx}
                    rowData={item}
                    rowIndex={idx}
                    rowChangeHandler={rowChangeHandler}
                    deleteHandler={deleteHandler}
                    currentRoom={props?.currentRoom}
                    roomDropdown={roomsDropdown}
                    viewLectureHandler={viewLectureHandler}
                    expandedIndex={expandedIndex}
                    setExpandedIndex={setExpandedIndex}
                    showActions={false}
                    {...props}
                  />
                ))
              ) : (
                // <EmptyTestState
                // message={getMessage()}
                // />
                <></>
              )}
              <div>
                {pastLectures?.length > 0 && (
                  <TablePagination
                    component={"div"}
                    page={pastLecturePage}
                    onPageChange={(e, newPage) => setPastLecturePage(newPage)}
                    count={totalPastLectureCount}
                    rowsPerPage={50}
                    rowsPerPageOptions={[50]}
                    sx={{
                      // position: 'sticky', left: 0, right: 0,
                      borderTop: "1px solid rgba(224, 224, 224, 1)",
                      marginRight: "50px",
                    }}
                  />
                )}
              </div>
            </div>
          )}
          <ViewLectureModal
            lectureId={lectureId}
            open={open}
            setOpen={setOpen}
            setLectureId={setLectureId}
          />
        </CardBody>
      </Card>
      <AddEventModal
        isOpen={isAddEventModalOpen}
        onClose={() => setIsAddEventModalOpen(false)}
        handleOpenLectureModal={handleOpenLectureModal}
        handleOpenPTMModal={handleOpenPTMModal}
        handleOpenCustomHolidayModal={() => {
          setOpenCustomHolidayModal(true);
          setIsAddEventModalOpen(false);
        }}
      />
      <AddLectureModalForm
        isOpen={isLectureFormOpen}
        onClose={() => setIsLectureFormOpen(false)}
        batchDetails={props?.batchDetails}
        planSubjects={props?.planSubjects}
        roomsDropdown={roomsDropdown}
        subjectFacultyDropdown={props.subjectFacultyDropdown}
        formData={props.formData}
        setLectures={props?.setLectures}
        setIsNeedToPublish={props.setIsNeedToPublish}
        openMode={props?.openMode}
        isEnabledMsTeamsSync={props?.isEnabledMsTeamsSync}
        lectures={props?.lectures}
        formType = {formType}
        selectedFaculty = {selectedFaculty}
      />
      {isPTMFormOpen && (
        <AddPTMModalForm
          type={formType}
          isOpen={isPTMFormOpen}
          onClose={() => {
            setIsPTMFormOpen(false);
            setPtmRowData(false);
          }}
          batchDetails={props?.batchDetails}
          roomsDropdown={roomsDropdown}
          setLectures={props?.setLectures}
          setIsNeedToPublish={props.setIsNeedToPublish}
          openMode={props?.openMode}
          isEnabledMsTeamsSync={props?.isEnabledMsTeamsSync}
          lectures={props?.lectures}
          subjectFacultyDropdown={props.subjectFacultyDropdown}
          ptmRowData={ptmRowData}
          saveOnEdit={props.onEditSave}
          academicCareer = {academicCareer}
        />
      )}
      {customHoliday && (
        <AddCustomHolidayModal
          batchDetails={props?.batchDetails}
          isOpen={customHoliday}
          lectures={props?.lectures}
          setLectures={props?.setLectures}
          setIsNeedToPublish={props.setIsNeedToPublish}
          onClose={() => {
            setIsAddEventModalOpen(false);
            setOpenCustomHolidayModal(false);
            setEditedRow(false);
          }}
          editedRow={editedRow}
          saveOnEdit={props.onEditSave}
        />
      )}
    </>
  );
};

export default LectureList;
