import React, { useEffect, useState } from 'react';
import CommonInput from "../../common/formFeilds/input/commonInput";
import { Col, Row } from "reactstrap";

const DepositSlipHeader = () => {
    return(
        <Row>
            <Col md={4}>
                <CommonInput
                    label={"Reference number"}
                    type={"text"}
                    readOnly={true}
                    readOnlyComp={<h3 className="color-dark">10975389</h3>}

                />
            </Col>
            <Col md={4}>
                <CommonInput
                    label={"Booklet ID"}
                    type={"text"}
                    readOnly={true}
                    readOnlyComp={<h3 className="color-dark">00000</h3>}
                    style={{ fontSize: '12px' }}
                />
            </Col>
        </Row>
    )
}

export default DepositSlipHeader