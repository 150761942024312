import React from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { useHistory } from 'react-router';
import AButton from '../../common/form-fields-mui/AButton';
import { Batch } from './Batch';
import AddToFavourite from '../../homepage/favourites/AddToFavourite';
import { pages } from '../../common/constant';

const BatchPage = () => {
  const history = useHistory();
  return (
    <div>
      <div
        className='d-flex align-items-center justify-content-end px-3'
        style={{
          marginBottom: '10px'
        }}
      >
        <AddToFavourite size='sm' screenName={'Search batch'} screenId={pages['searchBatch']['id']} />
        <AButton
          updatedStyle={{
            background: '#00B0F5',
            // marginBottom: '10px',
            color: '#fff',
            padding: '14px',
            // marginRight: '1rem',
            border: 'unset'
          }}
          onClick={() => history.push('/admin/edp/createBatch')}
        >
          <AiOutlinePlus /> Create Batch
        </AButton>
      </div>
      <Batch />
    </div>
  );
};

export default BatchPage;
