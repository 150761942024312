import React, {createContext, useState } from 'react'


export const TimetableContext = createContext({
    isResetEnabled:false,
    setResetEnabled : ()=>{},
    openMode:"",
    tests:[],
    setTests:()=>{},
    lectures:[],
    setLectures:()=>{}
})

export const TimetableContextProvider = ({children, ...props})=>{
      
        const [isResetEnabled, setResetEnabled]  = useState(false) 
         
    return(
       <TimetableContext.Provider value={{isResetEnabled, setResetEnabled,...props}}>
           {children}
       </TimetableContext.Provider>
    )
}


