import { endpoint } from "../../common/constant";
import {
  fetchAllPostPromisedData,
  fetchAllPromisedData,
  updateRequest,
  dropdownMapping as dropdownMapping2,
} from "../../common/utils/methods/commonMethods/utilityMethod";
import { dropdownMapping } from "../constant/batch-search";
import { validForReissue } from "./selectMeterialAndStock/issueStock/SelectMaterialInput";
const _ = require("lodash");

export const searchStockTransactions = (request, pageNo) => {
  const updatedRequest = updateRequest({ ...request });
  updatedRequest["businessArea"] = request?.businessArea?.value;

  return fetchAllPostPromisedData(
    `${endpoint.studentSearch.searchStockTransactions}?offset=${pageNo}`,
    updatedRequest,
    "post"
  );
};

export const fetchTransactionsData = (
  businessAreaId,
  transactionId,
  flag = "",
  type = ""
) => {
  const url = `${endpoint.studentSearch.getStockTransactionDetails}?businessArea=${businessAreaId}&transactionId=${transactionId}${flag}`;
  return fetchAllPromisedData(url, true);
};

export const getCourseDetails = (courseId) => {
  const url = `${endpoint.course_details.getByCourseId}/${courseId}`;
  return fetchAllPromisedData(url, true);
};

export const getBusinessAreaType = () => {
  const url = endpoint.businessType.getAllActiveUnitType;
  return fetchAllPromisedData(url, true);
};

export const getDispatchScheduleList = async (request) => {
  return fetchAllPostPromisedData(
    `${endpoint.dispatchSchedule.search}?offset=${0}`,
    updateRequest(request),
    "post"
  );
};

export const getCourseDetailsV2 = async (courseId) => {
  const url = `${endpoint.course_details.getByCourseId}/${courseId}`;
  const res = await fetchAllPromisedData(url);
  return res?.[0];
};

export const getDispatchIdMaterials = async (
  businessAreaId,
  dispatchId,
  psid
) => {
  const params = `?businessAreaId=${businessAreaId}&dispatchScheduleId=${dispatchId}&psid=${psid}`;
  const url = `${endpoint.getDispatchMaterialStatusForPsid.getMaterials}${params}`;
  return fetchAllPromisedData(url);
};

export const getCoursePricingDetails = async (courseId, businessArea, term) => {
  if (!courseId || !businessArea || !term) return {};
  const reqBody = {
    courseId,
    businessArea,
    term,
  };
  const res = await fetchAllPostPromisedData(
    endpoint?.course?.getCoursePricingDetails,
    reqBody,
    "post"
  );
  return res?.data;
};

export const getStudentApplications = (
  request,
  pageNo,
  url,
  rowsPerPage = 50
) => {
  const requestUrl = url
    ? url
    : endpoint.studentSearch.getStudentsForIssueStock;

  return fetchAllPostPromisedData(
    `${requestUrl}?offset=${pageNo}&&limit=${rowsPerPage}`,
    updateRequest(request),
    "post"
  );
};
export const editStudentDLPStockEligible = (id, request) => {
  return fetchAllPostPromisedData(
    `${endpoint.editStudentDLPStockEligible}/${id}`,
    updateRequest(request),
    "put"
  );
};

export const issueStockApi = (request) => {
  return fetchAllPostPromisedData(
    `${endpoint.studentSearch.issueStock}`,
    request,
    "post"
  );
};

// download file

export const getCSVkey = (businessArea, txnId) => {
  const url = `${endpoint.studentSearch.downloadStockTransactionDetails}?businessArea=${businessArea}&transactionId=${txnId}`;
  return fetchAllPromisedData(url, true);
};

export const downloadTemplateForBulkDispatch = (request) => {
  return fetchAllPostPromisedData(
    `${endpoint.downloadStudentDLPStockEligible}?offset=0`,
    request,
    "post"
  );
};

export const isMaterialGroupMappingExists = (request) => {
  const url = endpoint.groupTypeMaterialMapping.isMaterialGroupMappingExists;
  return fetchAllPostPromisedData(url, request, "post");
};

export const fetchGroupMaterials = () => {
  const url = `${endpoint.groupTypeMaterialMapping.search}?offset=${0}`;
  return fetchAllPostPromisedData(url, { status: "ACTIVE" }, "post");
};

export const notifyCourierDetails = (request) => {
  return fetchAllPostPromisedData(
    `${endpoint.notifyCourierDetails}`,
    request,
    "post"
  );
};

export const notifyDlpStock = (request) => {
  return fetchAllPostPromisedData(endpoint.notifyDlpStock, request, "post");
};

export const notifyLogistics = (request) => {
  return fetchAllPostPromisedData(
    `${endpoint.notifyLogistics}`,
    request,
    "post"
  );
};

export const isDuplicationAllowed = async (
  request,
  setterFn = () => {},
  selectedMaterials = []
) => {
  const response = await fetchAllPostPromisedData(
    `${endpoint.materialMaster.getMaterialFlags}`,
    request,
    "post"
  );
  if (response?.code === 200) {
    const materialMap = response?.data?.response;
    const _selectedMaterials = selectedMaterials.map((item) => ({
      ...item,
      isReIssued: materialMap[item?.materialId],
    }));
    setterFn(_selectedMaterials);
  }
};

export const studentsEligibleForReIssue = async (
  currentApplication,
  form,
  setApplications
) => {
  let { materialIds, businessArea, term, courseId } = form;
  // console.log(form);
  const request = {
    courseId: courseId[0]?.value || form?.BuAndAcdGroup?.courseId,
    businessAreaId: businessArea?.value || form?.BuAndAcdGroup?.businessAreaId,
    termId: term[0]?.value || form?.BuAndAcdGroup?.term,
    materialNumberList: materialIds,
    applicationIdList: currentApplication
      .filter((item) => !item?.isEligibleForStockIssuance)
      .map((application) => application?.applicationId),
  };
  if (request?.applicationIdList?.length === 0) return;
  const response = await fetchAllPostPromisedData(
    `${endpoint.studentSearch.validateApplicationForMaterialIssue}`,
    request,
    "post"
  );
  if (response?.code === 200) {
    const reIssueEligibleList = response?.data?.applicationValidationList;
    const applications = currentApplication.map((item) => ({
      ...item,
      isEligibleForStockIssuance: isValidForReIssue(
        item?.applicationId,
        reIssueEligibleList,
        item?.isEligibleForStockIssuance
      ),
    }));
    setApplications(applications);
  }
  // get response and update flag isEligibleForStockIssuance
};

const filterTShirtGroup = (validApplicationList, tShirtGroupMaterials = []) => {
  if (tShirtGroupMaterials?.length === 0) return validApplicationList;
  return validApplicationList.map((item) => {
    if (item?.alreadyIssuedTshirtMaterial?.length > 0) {
      item["validMaterialNumberList"] = item["validMaterialNumberList"].filter(
        (validMtr) => !tShirtGroupMaterials.includes(validMtr)
      );
    }
    return item;
  });
};

export const multipleIssueMaterialMap = async (
  currentApplication,
  form,
  setReissuedList,
  setApiLoader,
  nextPage = () => {},
  tShirtGroupMaterials = [],
  setAlreadyIssuedTshirtMaterial = () => {}
) => {
  if (currentApplication?.length === 0) {
    nextPage();
    return;
  }
  setApiLoader(true);
  const { materialIds, businessArea, term, courseId } = form;
  const request = {
    courseId: courseId[0]?.value || form?.BuAndAcdGroup?.courseId,
    businessAreaId: businessArea?.value || form?.BuAndAcdGroup?.businessAreaId,
    termId: term[0]?.value || form?.BuAndAcdGroup?.term,
    materialNumberList: materialIds,
    applicationIdList: currentApplication.map(
      (application) => application?.applicationId
    ),
  };
  const response = await fetchAllPostPromisedData(
    `${endpoint.studentSearch.validateApplicationForMaterialIssue}`,
    request,
    "post"
  );
  if (response?.code === 200) {
    const reIssueEligibleList = response?.data?.applicationValidationList;
    setAlreadyIssuedTshirtMaterial(reIssueEligibleList);
    setReissuedList(
      filterTShirtGroup(reIssueEligibleList, tShirtGroupMaterials)
    );
    nextPage();
  }
  setApiLoader(false);
};

export const updateDispatchStatusApi = (request) => {
  return fetchAllPostPromisedData(
    `${endpoint.updateStudentDLPStockEligibleByIds}`,
    request,
    "post"
  );
};

export const sendNotificationApiCall = (request) => {
  return fetchAllPostPromisedData(
    `${endpoint.updateStudentDLPStockEligibleByIds}`,
    request,
    "post"
  );
};

export const getAddressSlipByPsidApiCall = (request) => {
  return fetchAllPostPromisedData(
    `${endpoint.getAddressSlipByPsid}`,
    request,
    "post"
  );
};

export const getBatchAloKey = (
  request,
  downloadCriteria = "NOT_ALLOCATED_IN_BATCH",
  pageNo = 0,
  materialIds
) => {
  const updatedRequest = updateRequest(request);
  const { batchIds, term, courseId } = updatedRequest;
  const requestUsed = {
    batchIds,
    term,
    courseId,
    businessArea: [updatedRequest.businessArea],
    academicGroup: [updatedRequest.academicGroup],
    materialIds: materialIds,
    downloadCriteria: downloadCriteria,
  };

  const url = `${endpoint.studentSearch.downloadStuBatchAloAndNotAlo}?offset=${pageNo}`;
  return fetchAllPostPromisedData(url, requestUsed, "post");
};

export const getOpenSAPPeriod = async () => {
  return fetchAllPromisedData(
    endpoint?.sapPeriodStatus?.getAllPeriodStatus,
    true
  );
};

export const createIssueStockRequest = (applications = [], rootObj) => {
  const students = [];
  applications.forEach((application) => {
    if (Array.isArray(application.stock) && application.stock.length > 0) {
      students.push(_.pick(application, ["applicationId", "psid", "stock"]));
    }
  });
  return { ...rootObj, psidStockRequest: students };
};

const fetchBarcodeUrlBuilder = (plantId, materialIds, businessAreaId) => {
  const queryParams = `materialIds=${materialIds?.join(
    ","
  )}&businessAreaId=${businessAreaId}`;
  return `${endpoint?.materialMaster?.getMaterialBarcodes}?${queryParams}`;
};

export const fetchMaterialBarcodes = async (
  plantId,
  materialIds,
  businessAreaId
) => {
  const url = fetchBarcodeUrlBuilder(plantId, materialIds, businessAreaId);

  const res = await fetchAllPromisedData(url, true);

  if (res?.code === 200) {
    const data = res?.data;
    return {
      threshold: data?.threshold,
      materialIds: data.materialIds.reduce((acc, item) => {
        acc[item?.materialId] = item?.barcodes?.map((o) => ({
          barcode: o,
          isIssued: false,
        }));
        return acc;
      }, {}),
      materialCount: data?.materialIds?.reduce((acc, item) => {
        acc[item?.materialId] = item?.totalCount;
        return acc;
      }, {}),
    };
  }
};

// fetch course dropdown on the basis of Bu and term

export const fetchCourseIds = (businessArea, term) => {
  const termPayload = Array.isArray(term)
    ? term.map((item) => item?.value)?.join(",")
    : term?.value;
  try {
    const url = `${endpoint?.getCourseIdsForBUAndTerm}?businessAreaId=${businessArea?.value}&termId=${termPayload}`;
    return termPayload && dropdownMapping(url, "buAndTermCourse");
  } catch (error) {
    console.log(error);
  }

  // return fetchAllPromisedData(url)
};

// fetch dropdown on the basis of Bu term and courseId

export const fetchDispatchIds = async (
  buisnessArea,
  term,
  courseId,
  academicCareer
) => {
  const termPayload = Array.isArray(term)
    ? term.map((item) => item?.value)
    : term?.value;
  const courseIdPayload = Array.isArray(courseId)
    ? courseId.map((item) => item?.value)?.join(",")
    : courseId?.value;
  try {
    const reqBody = {
      businessAreaId: buisnessArea,
      courseId: courseIdPayload,
      termId: termPayload,
      academicCareer: academicCareer,
      status: "ACTIVE",
    };
    const res = await fetchAllPostPromisedData(
      `${endpoint.dispatchSchedule.search}?offset=-1`,
      updateRequest(reqBody),
      "post"
    );
    if (res?.code == 200) {
      const dropdown = res?.data?.stockDispatchMasterList.map((item) => ({
        label: item?.dispatchId,
        value: item?.dispatchId,
      }));
      return dropdown;
    }
  } catch (error) {
    console.log(error);
  }
};

//
export const fetchDispatchScheduleApi = (request) => {
  return fetchAllPostPromisedData(
    `${endpoint.dispatchSchedule.search}?offset=${0}`,
    request,
    "post"
  );
};

export const dlpLandingPageDropdown = (academicCareer = "DLP") => {
  return Promise.all([
    dropdownMapping(endpoint?.term?.getAllActive, "termDispValue", "id"),
    dropdownMapping(
      endpoint?.academic_group?.getAllActive,
      "academicGroupDispValue",
      "id"
    ),

    dropdownMapping2(
      academicCareer === "DLP"
        ? endpoint?.businessArea?.getAllDlpActiveBusinessArea
        : endpoint.businessArea.getAllDigitalBusinessArea,
      "businessAreaDispValue",
      "id",
      "sapPlantId"
    ),
    academicCareer === "DLP"
      ? dropdownMapping(endpoint?.course?.getAllDlpCourseId, "courseIds")
      : dropdownMapping(endpoint?.course?.getAllDigitalCourseId, "courseIds"),
  ]);
};

export const markEligibleStudentApi = (request) => {
  return fetchAllPostPromisedData(
    endpoint?.markStudentDLPStockEligible,
    request
  );
};
export const getPsidIssuedStock = (list = [], applicationId) => {
  const filteredList = list.filter(
    (item) => item?.applicationId === applicationId
  );
  return filteredList.length > 0 && filteredList[0]?.stock;
};

export const isValidBarcodeExist = (materialAndBarcodeMapping, materialId) => {
  let result = false;
  const barcode = materialAndBarcodeMapping?.materialIds[materialId];
  if (
    Array.isArray(barcode) &&
    barcode.filter((item) => !item?.isIssued).length > 0
  ) {
    result = true;
  }
  return result;
};

export const isMaterialAlreadyIssued = (
  stocksAlreadyIssued,
  materialId,
  isReIssuedAllowed,
  applicationId,
  applicationMaterialMap
) => {
  // if reissue allow don't check for all ready issue
  if (isReIssuedAllowed) {
    // check eligible for issue or not
    if (validForReissue(applicationMaterialMap, applicationId, materialId)) {
      return true;
    }
    return false;
  }
  return stocksAlreadyIssued.some(
    (material) => material?.materialId === materialId
  );
};

export const getFirstAvailableBarcode = (
  materialAndBarcodeMapping,
  material
) => {
  const barcodeList = materialAndBarcodeMapping?.materialIds[material.id];
  const index = barcodeList.findIndex((item) => !item?.isIssued);
  const barcodeObj = barcodeList[index];
  return [barcodeObj, index];
};

export const getBUDetails = (selectedBusinessArea) => {
  return {
    plantId: selectedBusinessArea?.sapPlantId,
    businessAreaDispValue: selectedBusinessArea?.label,
  };
};

export const updateBarcodeIssued = (
  materialAndBarcodeMapping,
  barcodeObj,
  index,
  setMaterialAndBarcodeMapping,
  material
) => {
  const tempMapping = { ...materialAndBarcodeMapping };
  barcodeObj["isIssued"] = true;
  tempMapping.materialIds[material.id][index] = { ...barcodeObj };
  setMaterialAndBarcodeMapping(tempMapping);
};

export const getAvailableStock = (
  student,
  selectedMaterial,
  issuedStockToStudent,
  selectedBusinessArea,
  setMaterialAndBarcodeMapping,
  materialAndBarcodeMapping,
  applicationId,
  canReIssueMaterialList
) => {
  const alreadyIssuedMaterial =
    getPsidIssuedStock(
      issuedStockToStudent,
      student?.applicationId,
      selectedMaterial
    ) || [];
  const materialNotIssued = getMaterialNotIssued(
    alreadyIssuedMaterial,
    selectedMaterial,
    applicationId,
    canReIssueMaterialList
  );

  // find not issued materials
  const result = [];
  materialNotIssued.forEach((material) => {
    if (isValidBarcodeExist(materialAndBarcodeMapping, material?.materialId)) {
      const [barcodeObj, index] = getFirstAvailableBarcode(
        materialAndBarcodeMapping,
        material
      );

      const updatedMaterial = {
        ...material,
        ...getBUDetails(selectedBusinessArea),
        barcode: barcodeObj?.barcode,
      };
      result.push(updatedMaterial);

      // updateBarcodeIssued
      updateBarcodeIssued(
        materialAndBarcodeMapping,
        barcodeObj,
        index,
        setMaterialAndBarcodeMapping,
        material
      );
    }
  });
  // find barcode mapping
  // create request object

  return result;
};
const isFind = (materialNotIssued, selectedStocks, allReady) => {
  // materialNotIssued does not exist in  selectedStocks then it would be red
  let matchingCount = 0;
  for (let i = 0; i < materialNotIssued.length; i++) {
    let exist = selectedStocks.some(
      (item) => materialNotIssued[i].id === item.id
    );
    if (exist) matchingCount++;
  }

  return matchingCount === materialNotIssued.length;
};
export const isErrorExist = (
  student,
  issuedStockToStudent,
  selectedMaterial,
  selectedStocks
) => {
  const alreadyIssuedMaterial =
    getPsidIssuedStock(issuedStockToStudent, student?.applicationId) || [];
  const materialNotIssued = getMaterialNotIssued(
    alreadyIssuedMaterial,
    selectedMaterial
  );
  return isFind(materialNotIssued, selectedStocks, alreadyIssuedMaterial);
};
export const getMatchingMaterials = (
  issuedAlready = [],
  selectedMaterials = []
) => {
  if (issuedAlready.length === 0) return [];
  const result = [];
  issuedAlready.forEach((material) => {
    selectedMaterials.forEach((newMaterial) => {
      if (material?.materialId === newMaterial?.materialId)
        result.push({ ...material, isReIssued: newMaterial?.isReIssued });
    });
  });
  return result;
};

export const getMaterialNotIssued = (
  issuedAlready = [],
  selectedMaterials = [],
  applicationId,
  canReIssueApplicationMaterialsList = []
) => {
  // debugger;
  if (issuedAlready.length === 0) return selectedMaterials;

  const result = [];
  selectedMaterials.forEach((material) => {
    let isIssued = false;
    issuedAlready.forEach((newMaterial) => {
      if (material?.materialId === newMaterial?.materialId) {
        isIssued = true;
      }
      if (material?.isReIssued) isIssued = false;
    });
    // if  isIssued and already issued in a valid year then skip this
    if (
      material?.isReIssued &&
      validForReissue(
        canReIssueApplicationMaterialsList,
        applicationId,
        material?.materialId
      )
    )
      isIssued = true;
    if (!isIssued) result.push(material);
  });
  return result;
};

export const freeMaterialBarcode = (
  stockList = [],
  setterFunction,
  materialBarcodeMapping
) => {
  const tempMapping = { ...materialBarcodeMapping };
  stockList.forEach((stock) => {
    let materialBarcodeList = tempMapping?.materialIds[stock?.materialId];
    for (let index = 0; index < materialBarcodeList.length; index++) {
      const element = materialBarcodeList[index];
      if (element?.barcode === stock.barcode) {
        element["isIssued"] = false;
        break;
      }
    }
  });
  setterFunction(tempMapping);
};

export const getAvailableBarcodeByMaterialId = (materialMap, materialId) => {
  const materialList = materialMap?.materialIds[materialId];
  if (!materialList) return 0;

  if (Array.isArray(materialList) && materialList.length > 0) {
    const notIssuedBarcode = materialList.filter((item) => !item?.isIssued);
    return notIssuedBarcode.length;
  }

  return 0;
};

export const getIssuedBarcodeByMaterialId = (materialMap, materialId) => {
  const materialList = materialMap?.materialIds[materialId];
  if (!materialList) return 0;

  if (Array.isArray(materialList) && materialList.length > 0) {
    const notIssuedBarcode = materialList.filter((item) => item?.isIssued);
    return notIssuedBarcode.length;
  }

  return 0;
};

export const getAvailableBarcode = (barcodeList = []) => {
  if (Array.isArray(barcodeList) && barcodeList.length === 0) return [];
  return barcodeList.filter((item) => !item?.isIssued);
};

export const updateElErrorBorder = (
  currentIndexRow,
  allRows,
  setRows,
  isError = false
) => {
  const tempRows = [...allRows];
  tempRows[currentIndexRow]["isError"] = isError;
  setRows(tempRows);
};

export const isAllAssigned = (idsMap) => {
  const list = Object.values(idsMap).filter(Boolean);
  return list.length === 50;
};

export const groupMaterialByName = (list = []) => {
  const groupMap = {};
  list.forEach((item) => {
    const { materialNumber, materialDesc } = item;
    const key = `${item["groupType"]} | ${item["groupTypeDesc"]}`;
    if (!groupMap[key]) {
      groupMap[key] = [{ materialNumber, materialDescription: materialDesc }];
    } else {
      groupMap[key] = [
        ...groupMap[key],
        { materialNumber, materialDescription: materialDesc },
      ];
    }
  });
  const result = [];
  Object.keys(groupMap).forEach((groupName) => {
    result.push({ groupName, materials: groupMap[groupName] });
  });
  return result;
};

export const isAllGroupMaterialSelected = (
  selectedMaterials,
  materialGroups
) => {
  // debugger;
  const allGroupsMaterials = [];
  materialGroups.forEach((materialGroup) => {
    if (!materialGroup["groupName"].includes("Group - 01"))
      allGroupsMaterials.push(...materialGroup["materials"]);
  });
  return allGroupsMaterials.every((item) =>
    selectedMaterials.includes(item["materialNumber"])
  );
};

export const isValidForReIssue = (
  applicationId,
  reIssueEligibleList,
  alreadyEligible
) => {
  if (alreadyEligible) return true;
  return reIssueEligibleList.some(
    (item) =>
      item?.applicationId === applicationId && item?.validApplicationForIssuance
  );
};
