import React, { useContext, useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { ReactComponent as CrossIcon } from "../../../../../../assets/img/svg/CrossIcon.svg";
import { ReactComponent as GreaterThanIcon } from "../../../../../../assets/img/svg/GreaterThanIcon.svg";
import { ReactComponent as LessThanIcon } from "../../../../../../assets/img/svg/LessThanIcon.svg";
import { ReactComponent as WarningSVG } from "../../../../../../assets/img/svg/Active-warning.svg";
import moment from "moment";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import EndDateSelectionDialog from "./EndDateSelectionDialog";
import { compare, getPlanTests, updateTestData } from "../helper";
import ALoader from "views/pages/manage/common/a-loader";
import { TimetableContext } from "../contextApi/TimetableContext";
import { getMomentWithoutTimestamp } from "../../../common/utils/methods/commonMethods/utilityMethod";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper";
import {filterPastTest} from  '../hooks'
const ChangeDateDialog = ({
  open,
  setOpen = () => {},
  formData,
  formHandler,
  tests,
  setTests,
  lectures,
  setLectures,
  fetchNewLectures = () => {},
  setIsNeedToPublish = () => {},
  setOpenForm,
}) => {
  const [newEndDate, setNewEndDate] = useState("");
  const { setDeletedIds, pastTests } = useContext(TimetableContext);
  const [openDateSelectorDialog, setOpenDateSelectorDialog] = useState(false);
  const [dateChangeConfirmed, setDateChangeConfirmed] = useState(false);
  const [updatedTests, setUpdatedTests] = useState([]);
  const [updatedLectures, setUpdatedLectures] = useState([]);
  const [loading, setLoading] = useState(false);

  const isNewDateGreater = () =>
    new Date(newEndDate) > new Date(formData?.endOn);

  const handleClose = () => setOpen(false);

  const tipsCardStyles = {
    backgroundColor: "#F5F5F5",
    borderRadius: "8px",
    padding: "12px 24px 12px 24px",
  };

  const detailsCardStyles = {
    // maxHeight: "400px",
    borderRadius: "8px",
    padding: "12px 24px",
    backgroundColor: isNewDateGreater() ? "#EDF7F2" : "#FFF6E3",
    border: isNewDateGreater() ? "1px solid #89CCA9" : "1px solid #FFD47F",
  };

  const filterNotAlready = (newTests, testsAlready) => {
    if (testsAlready.length === 0) return newTests;
    return newTests.filter(
      (i) => !testsAlready.some((item) => i?.id === item?.testScheduleId)
    );
  };
  const fetchPlanTest = () => {
    let isAfter = new Date(newEndDate) > new Date(formData?.endOn);
    let request = { ...formData };
    if (isAfter) {
      request["startFrom"] = moment(formData.endOn).isBefore(
        getMomentWithoutTimestamp()
      )
        ? getMomentWithoutTimestamp()
        : formData?.startFrom;
      request["endOn"] = newEndDate;
    } else {
      request["startFrom"] = newEndDate;
      request["endOn"] = formData.endOn;
    }
    // fetch lectures
    // fetch new lectures ;
    fetchNewLectures(
      newEndDate,
      formData.endOn,
      isNewDateGreater(),
      setUpdatedLectures,
      setLoading
    );
    if (isNewDateGreater()) {
      getPlanTests({
        ...request,
        endOn: moment(request?.endOn).endOf("day"),
      }).then((res) => {
        // if case of extent then add new tests into all ready listed test
        if (res?.code === 200) {
          setUpdatedTests(
            filterPastTest(
              filterNotAlready(
                filterNotAlready(
                  updateTestData(res?.data?.searchTestScheduleDtoList),
                  tests
                ),
                pastTests
              )
            )
          );
        } else {
          setUpdatedTests([]);
        }
        // case reduce date
      });
    } else {
      let testToBeDeleted = tests.filter(
        (lec) => new moment(lec?.testDate) > newEndDate
      );
      setUpdatedTests(testToBeDeleted);
    }
  };
  const deleteId = (items) =>
    items.map((item) => ({ ...item, testScheduleId: item.id, id: "" }));
  const handleUpdateEndDate = () => {
    handleClose();
    formHandler(newEndDate, "endOn");
    if (isNewDateGreater()) {
      // update lectures to state
      setLectures((oldLectures) => [...oldLectures, ...updatedLectures]);
      setTests([...tests, ...deleteId(updatedTests)]);
    } else {
      // remove updatedTests from tests

      const deletedIds = [
        ...updatedTests.map((item) => item.id),
        ...updatedLectures.map((item) => item.id),
      ];
      setDeletedIds(deletedIds);
      let filteredTests = [];
      for (let test of tests) {
        let exist = false;
        for (let updatedTest of updatedTests) {
          if (updatedTest?.id === test?.id) {
            exist = true;
            break;
          }
        }
        if (!exist) filteredTests.push(test);
      }

      // remove updatedTests from lectures
      let filteredLectures = [];
      for (let lecture of lectures) {
        let exist = false;
        for (let updatedLecture of updatedLectures) {
          if (updatedLecture?.id === lecture?.id) {
            exist = true;
            break;
          }
        }
        if (!exist) filteredLectures.push(lecture);
      }
      setTests(filteredTests);
      setLectures(filteredLectures);
    }

    setIsNeedToPublish(true);
    if (setOpenForm) setOpenForm("TIMETABLE");
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
      {loading && <ALoader position="fixed" />}
      <div style={{ padding: "1.5rem" }}>
        <div className="d-flex justify-content-between align-items-center">
          <span className="heading-4 color-dark">Time table date</span>
          <IconButtonWrapper onClick={handleClose}>
            <CrossIcon />
          </IconButtonWrapper>
        </div>
        <div className="mt-4">
          {newEndDate && (
            <div className="d-flex justify-content-between mr-3 mb-2">
              <span>New end date</span>
              <span className="heading-4 color-dark">
                {newEndDate ? new moment(newEndDate).format("DD MMM YYYY") : ""}
              </span>
            </div>
          )}
          <div className="d-flex justify-content-between mr-3">
            <span>End date</span>
            <span className="heading-4 color-dark">
              {formData?.endOn
                ? new moment(formData?.endOn).format("DD MMM YYYY")
                : "-"}
            </span>
          </div>
          {!dateChangeConfirmed && (
            <div className="d-flex mt-3">
              <AButton
                className="button_remove_left_margin"
                variant="primary_filled"
                onClick={() => setOpenDateSelectorDialog(true)}
              >
                Change end date
                <GreaterThanIcon stroke="#FFFFFF" fill="none" />
              </AButton>
              {newEndDate && (
                <AButton
                  className=""
                  variant="outline"
                  onClick={() => {
                    setDateChangeConfirmed(true);
                    fetchPlanTest();
                  }}
                >
                  Proceed with changes
                </AButton>
              )}
            </div>
          )}
        </div>
        {!dateChangeConfirmed && (
          <div className="mt-4" style={tipsCardStyles}>
            <span className="regular-large-bold" style={{ fontWeight: 700 }}>
              Tips:
            </span>
            <ul style={{ paddingLeft: "1rem", marginTop: "12px" }}>
              <li className="mb-2">{`Start date ${new moment(
                formData?.startFrom
              ).format(
                "DD MMM YYYY"
              )} - cannot be changed because timetable is already applied on the batch`}</li>
              <li>
                Extend or reduce end date to add or remove test/lectures from
                timetable
              </li>
            </ul>
          </div>
        )}
        {dateChangeConfirmed && (
          <>
            <div
              className="mt-4 d-flex justify-content-between"
              style={detailsCardStyles}
            >
              <div>
                <span className="regular-large-bold color-dark" style={{}}>
                  {`Following details will be ${
                    isNewDateGreater() ? "added to" : "removed from"
                  } the timetable tests because you have set end date ${moment(
                    newEndDate
                  ).format("DD/MM/YYYY")}`}
                </span>
                {updatedTests?.length > 0 ? (
                  <ul
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                      paddingLeft: "1rem",
                      marginBottom: 0,
                    }}
                  >
                    {updatedTests?.map((test) => (
                      <li
                        key={test?.testName}
                        className="mt-2"
                      >{`${test?.testType} - ${test?.testName}`}</li>
                    ))}
                  </ul>
                ) : (
                  <div>No tests found in updated date range</div>
                )}
              </div>
              {!isNewDateGreater() && <WarningSVG />}
            </div>

            {/* <----lectureSection/------> */}
            <div
              className="mt-4 d-flex justify-content-between"
              style={detailsCardStyles}
            >
              <div>
                <span className="regular-large-bold color-dark" style={{}}>
                  {`Following details will be ${
                    isNewDateGreater() ? "added to" : "removed from"
                  } the timetable lectures because you have set end date ${moment(
                    newEndDate
                  ).format("DD/MM/YYYY")}`}
                </span>
                {updatedLectures?.filter(
                  (item) => item?.lectureName !== "Break"
                )?.length > 0 ? (
                  <ul
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                      paddingLeft: "1rem",
                      marginBottom: 0,
                    }}
                  >
                    {updatedLectures
                      ?.filter((item) => item?.lectureName !== "Break")
                      ?.map((lecture) => (
                        <li key={lecture?.lectureName} className="mt-2">
                          {`${lecture.subject || lecture.classType}-${
                            lecture?.lectureName ||
                            moment(lecture.lectureDate).format("MMM Do YYYY")
                          }`}
                        </li>
                      ))}
                  </ul>
                ) : (
                  <div>No Lectures found in updated date range</div>
                )}
              </div>
              {!isNewDateGreater() && <WarningSVG />}
            </div>

            <div className="d-flex mt-3">
              <AButton
                className="button_remove_left_margin"
                variant="outline"
                onClick={() => setDateChangeConfirmed(false)}
              >
                <LessThanIcon stroke="#00B0F5" fill="none" />
                Go back
              </AButton>
              <AButton
                className=""
                variant="outline"
                updatedStyle={
                  isNewDateGreater()
                    ? {}
                    : { borderColor: "#B32306", color: "#B32306" }
                }
                onClick={() => {
                  handleUpdateEndDate();
                }}
              >
                {isNewDateGreater()
                  ? "I understand add details"
                  : "I understand remove details"}
                <GreaterThanIcon
                  fill="none"
                  stroke={isNewDateGreater() ? "#00B0F5" : "#B32306"}
                />
              </AButton>
            </div>
          </>
        )}
      </div>
      <EndDateSelectionDialog
        open={openDateSelectorDialog}
        setOpen={setOpenDateSelectorDialog}
        setNewEndDate={setNewEndDate}
        formData={formData}
      />
    </Dialog>
  );
};

export default ChangeDateDialog;
