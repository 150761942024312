import React, { useContext, useState } from "react";
import { RolePermissions, PermissionContext } from "appContext";
import DeleteDialog from "./DeleteDialog";
import { ReactComponent as EditIcon } from "assets/img/svg/Edit2.svg";
import { ReactComponent as DeleteIcon } from "assets/img/svg/Delete.svg";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import { pages } from "views/pages/manage/common/constant";
import { checkPermission } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import CustomButton from "components/CustomButton";

const ActionsCellComponent = ({
  params,
  handleEditClick,
  handleCancelClick,
  handleSaveClick,
  // deleteHandler,
  rowForm,
}) => {
  const userPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["ltaConfig"]["id"]
  );

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return params.api.getRowMode(params?.id) === "view" ? (
    <>
      <div className="d-flex align-items-center">
        {checkPermission(userPermissions, "U") && (
          <AButton
            variant="primary_filled"
            size="xs"
            onClick={() => handleEditClick(params)}
          >
            Edit
          </AButton>
        )}
        {/* {checkPermission(userPermissions, 'D') && <DeleteIcon className='ml-3' onClick={() => setIsDialogOpen(true)} />} */}
      </div>
      {/* <DeleteDialog
          open={isDialogOpen}
          setOpen={setIsDialogOpen}
          data={params?.row}
          onAgree={() => deleteHandler(params?.row)}
        /> */}
    </>
  ) : (
    <div className="d-flex align-items-center">
      <AButton
        variant="primary_filled"
        size="xs"
        onClick={() => handleSaveClick(params)}
        disabled={
          !rowForm?.businessArea?.length ||
          !rowForm?.academicCareer?.length ||
          !rowForm?.term?.length ||
          !rowForm?.academicGroup?.length ||
          !rowForm?.courseCategory?.length ||
          !rowForm?.status ||
          !rowForm?.concessionRule ||
          !rowForm?.effectiveDate
        }
      >
        Save
      </AButton>
        <CrossIcon className='ml-2' onClick={() => handleCancelClick(params)} />
    </div>
  );
};

export default ActionsCellComponent;
