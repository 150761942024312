import React, {useState, useEffect, useContext} from 'react';
import {Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row, Table} from "reactstrap";
import {fetchAllData, fetchDetails, getDispValForReactSelectByOneID} from "../../../common/utils/methods/commonMethods/utilityMethod";
import {masterServiceBaseUrl, putpost, putpost2} from "services/http";
import { failureToast, successToast} from '../../../common/utils/methods/toasterFunctions/function';
import HeaderCourseCatalog from "./headerCourseCatalog";
import moment from "moment";
import CustomLoader from "../../../common/commonComponents/Loader/loader";
import ViewAndEditTable from "./viewAndEditTable";
import LogDetails from "../courseCatalogSequenceSetup/LogDetails";
import {Accordion} from "../../../common/commonComponents/Accordion/accordion";
import {useHistory} from "react-router-dom";
import {PermissionContext, RolePermissions} from "../../../../../../appContext";
import {pages} from "../../../common/constant";

const ViewAndEditCourseCatalog =(props)=>{
    let CourseAttribute = 'CRTY';
    let history = useHistory();
    const [catalogData,setCatalogData] = useState();
    const {id,action} = props.match.params;

    const permissions = useContext(PermissionContext);

    const userPermissions = RolePermissions(
        permissions,
        pages['courseCatalogSequence']['id']
    );

    const [groupCode,setGroupCode] = useState();
    const [companyCode,setCompanyCode] = useState();
    const [academicCareer,setAcademicCareer] = useState();
    const [apiLoader, setApiLoader] = useState(true);
    const headerData = [
        {label: 'Business Group', value: catalogData ? getDispValForReactSelectByOneID(catalogData.groupCodeId, 'DispValue', groupCode):'NOT FOUND',},
        {label: 'Company Code', value:  catalogData ? getDispValForReactSelectByOneID(catalogData.companyCodeId, 'DispValue', companyCode):'NOT FOUND',},
        {label: 'Academic Career', value: catalogData ? getDispValForReactSelectByOneID(catalogData.academicCareerId, 'DispValue', academicCareer):'NOT FOUND',},
        {label: 'Status', value: catalogData && catalogData.status ? catalogData.status :'NotFound',},
    ];
    const [courseDetailData,setCourseDetailData] = useState()
    const [courseAttribute,setCourseAttribute] = useState()
    const [ courseAttributeValueList, setCourseAttributeValueList ] = useState( [] );
    const [courseAttributeMapping,setCourseAttributeMapping] = useState();
    const [groupCodeId,setGroupCodeId] = useState()
    const [companyCodeId,setCompanyCodeId] = useState();
    useEffect(() => {
        // console.log('Course Attribute mapping-->',courseAttribute)
    },[courseAttribute]);
    useEffect(() => {
        fetchAllData(masterServiceBaseUrl+'/courseAttributeMapping/getCourseCatalogSequenceSetup/'+id,setCatalogData)
        fetchDetails(masterServiceBaseUrl + '/groupCode/getAllActiveGroupCode', setGroupCode, 'groupCode', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + '/academicCareer/getAllActiveAcademicCareer', setAcademicCareer, 'academicCareer', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + '/companyCode/getAllActiveCompanyCode', setCompanyCode, 'companyCode', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + '/courseAttribute/getAllActiveCourseAttribute',setCourseAttribute,'courseAttribute','reactSelect');
        fetchDetails(masterServiceBaseUrl + '/courseAttributeValue/getAllActiveCourseAttributeValue', setCourseAttributeValueList,'courseAttributeValue','reactSelect');
        if(id){
            fetchAllData(masterServiceBaseUrl+'/courseCatalogSequenceDetail/getCourseCatalogSequenceDetailByCourseCatalogSetupId/'+id,setCourseDetailData)
        }
    },[])

    useEffect(() => {
        if(catalogData){
            setCompanyCodeId(catalogData.companyCodeId)
            setGroupCodeId(catalogData.groupCodeId)
            fetchAllData(masterServiceBaseUrl + `/courseAttributeMapping/getCourseAttributeMappingByGroupCodeAndCompanyCode?groupCode=${catalogData.groupCodeId}&companyCode=${catalogData.companyCodeId}`,setCourseAttributeMapping,'courseAttributeMapping','reactSelect');
        }
    },[catalogData])
    const [courseAttributeIds,setCourseAttributeIds] = useState([]);
    useEffect(() => {
        if(courseAttributeMapping){
            let tempArray = []
            courseAttributeMapping.map((item,index)=>{
                item.map((data)=>{
                    tempArray.push({courseAttributeId:data.courseAttributeId})
                })
            })
            setCourseAttributeIds(tempArray);
        }
    },[courseAttributeMapping])

    const [CourseAttributeDropdown,setCourseAttributeDropDown] = useState([]);
    useEffect(() => {

        if(courseAttribute && courseAttributeIds){
            let tempArray = [];
            courseAttributeIds.map((item,index)=>{
                courseAttribute.map((data,index)=>{
                    if(data.id === item.courseAttributeId){
                        if(data.text ===CourseAttribute) {
                            tempArray.push(data);
                        }
                    }
                })
            })
            let arr = tempArray.filter(function (item, index, array) {return array.indexOf(item) === index;});
            setCourseAttributeDropDown(arr);
        }
    },[courseAttributeIds,courseAttribute])

    useEffect(() => {
        // console.log('Final Data----------->',CourseAttributeDropdown)
    },[CourseAttributeDropdown])
    const [data,setData] = useState();
    useEffect(() => {
        try {
            if (courseDetailData) {
                let tempArray = [];
                courseDetailData.map((item) => {
                    tempArray.push({...item, isEdit: false, isNew: false})
                })
                setData(tempArray)
            }
        }catch(e){
            console.log('Error in useEffect-dep-courseDetailData',e);
        }
    },[courseDetailData])

    // ------------use Effect for loader---------
    useEffect(() => {
        if(catalogData && groupCode && companyCode && academicCareer){
            setApiLoader(false);
        }
    },[catalogData,companyCode,groupCode,academicCareer])
    const SaveHeaderData = (status)=>{
        if(catalogData) {
            let tempObject = {
                "id": catalogData['id'],
                "groupCodeId": catalogData['groupCodeId'],
                "companyCodeId": catalogData['companyCodeId'],
                "academicCareerId": catalogData['academicCareerId'],
                "status": status
            }
            PostData(masterServiceBaseUrl+'/courseAttributeMapping/updateCourseCatalogSequenceSetup/'+catalogData['id'],tempObject,'put')
        }

    }
    const PostData =(url,dataObject,type)=>{
        putpost(url,(data)=>{
            successToast(data.message);
            setIsEdit(false);
            fetchAllData(masterServiceBaseUrl+'/courseAttributeMapping/getCourseCatalogSequenceSetup/'+id,setCatalogData)
        },(data)=>{
            failureToast(data.message);
        },dataObject,type)
    }

    const [isEdit,setIsEdit] = useState(false);
    return(
        <>{
            apiLoader ?
                (<div><CustomLoader apiLoader={apiLoader} loaderHeight={'200px'} loaderWidth={'100% '}/></div>)
                :
                (
                    <>
                        <Card style={{margin:'15px 20px',}}>
                            <CardHeader><h2 className="mb-0 floatLeft">Course Catalog Sequence</h2></CardHeader>
                            <CardBody>
                                <HeaderCourseCatalog action={action} isEdit={isEdit} setIsEdit={setIsEdit} SaveHeaderData={SaveHeaderData} headerData={headerData} permissionSet={userPermissions} />
                            </CardBody>
                        </Card>
                        <ViewAndEditTable courseAttributeDropDown={courseAttribute} groupCodeId={groupCodeId} companyCodeId={companyCodeId} action={action} setCourseAttributeValueList={setCourseAttributeValueList} courseAttribute={CourseAttributeDropdown} courseAttributeValueList={courseAttributeValueList} id={id}  courseDetailData={data} setCourseDetailData={setData} permissionSet={userPermissions} />
                        <div style={{margin:'15px 20px',}} >
                            <Accordion title={`Log Details`} key={"log_details"} isFormOpen={true}>
                                <LogDetails 
                                    updatedOn={catalogData ? moment(catalogData.updatedOn).format('DD-MM-YYYY'):'NOT FOUND'} 
                                    createdOn={catalogData ? moment(catalogData.effectiveDate).format('DD-MM-YYYY'):'NOT FOUND'}
                                    createdBy={catalogData ? catalogData.createdBy : ''}
                                    updatedBy={catalogData ? catalogData.updatedBy : ''}
                                    courseDetailData={data}
                                    action={action}
                                />
                                <Row className="mr-2" style={{display:"flex",justifyContent:"flex-end"}}>
                                    <Button key={'CloseButton'} id="buttonToggler" color="danger" size="sm" type="button" onClick={()=> {
                                        if(action !== 'new'){window.close()}
                                        else{ history.goBack() }
                                    }
                                    }>close</Button>
                                </Row>
                            </Accordion>
                        </div>
                    </>
                )
        }
        </>
    );
}

export default ViewAndEditCourseCatalog;