import React, { useState, useEffect } from "react";
import CashierOfficeTable from './cashierOfficeTable';
import Loader from 'react-loader-spinner'
import { masterServiceBaseUrl, failureToast, getAPI } from 'services/http';
import { Card, Container, Row ,Col,Button} from "reactstrap";
// import { GetPagination } from '../../../commonComponent/pagination';
import { PaginationNode, PaginationPage, GetPagination } from '../../../common/commonComponents/pagination/pagination'

const CashierOffice = (props) => {const {
  businessUnit,
  nextStep = () => {},
  previousStep = () => {},
  isNew = true,
  isViewOnly = false,
  cashierOfficeData,
  action,
  id,
  isPrevious,
  formId,
  appendAllStepData=()=>{},
  saveStepsCompleted=()=>{}
} = props;
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [isNextDisable, setIsNextDisable] = useState(false);
  const [currency, setCurrency] = useState([]);
  const [businessArea, setBusinessArea] = useState([]);
  const [classArray, setclassArray] = useState([])
  const [apiloader, setapiloader] = useState(false)
  const [state, setstate] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null
  })
  const pageSize = 10
  const fetchClasses = async (url) => {
    setapiloader(true)
    getAPI(url, (data) => {
      data['data'].length > 0 ? setIsNextDisable(true) :  setIsNextDisable(false)
      setstate(data['data'])
      setTableData(data['data']);
      let n = pagination
      if (data['data'].length > pageSize) {
        n['nextPage'] = n.currentPage + 1
      }
      if (n.currentPage > 1) {
        n.previousPage = n.currentPage - 1
      }
      n['totalPage'] = Math.floor(data['data'].length / pageSize)
      if (data['data'].length % pageSize != 0) {
        n['totalPage'] += 1
      }
      setpagination(n)
      setapiloader(false)
      setclassArray(data['data'].slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || [])
    }, (data) => { failureToast(data['message']) })
  }
  const previousPage = () => {
    if (pagination.currentPage != 1) {
      setclassArray(state.slice((pagination.currentPage - 1) * (pageSize) - pageSize, (pagination.currentPage - 1) * pageSize))
      let n = pagination
      n['currentPage'] = n['currentPage'] - 1
      if (n['currentPage'] == 1) {
        n['previousPage'] = null
      } else {
        n['previousPage'] = n.currentPage
      }
      setpagination(n)
    }
  }

  const fetchCurrency = async (url) => {
    setapiloader(true)
    getAPI(url, (data) => {
      const currencyList = data.data.map(
        data => {
          const {
            id,
            currencyDispValue,
            status
          } = data;
          if (status === 'ACTIVE') {
            return {
              id: id,
              text: currencyDispValue
            };
          }
        }
      );
      setCurrency( currencyList )
      setapiloader(false)
    }, ( data ) => { failureToast( data[ 'message' ] );setapiloader(false)})
  }

  const fetchBusinessArea = async (url) => {
    setapiloader(true)
    getAPI(url, (data) => {
      const businessAreaList = data && data.data.map(
        data => {
          const {
            id,
            businessAreaDispValue,
            status
          } = data;
          if (status === 'ACTIVE') {
            return {
              id: id,
              text: businessAreaDispValue
            };
          }
        }
      );
      setapiloader(false)
      setBusinessArea(businessAreaList)
    }, ( data ) => { failureToast( data[ 'message' ] );setapiloader(false) })
  }

  const performPaginationFormData = () => {
     setstate(cashierOfficeData)
      setTableData(cashierOfficeData);
      let n = pagination
      if (cashierOfficeData.length > pageSize) {
        n['nextPage'] = n.currentPage + 1
      }
      if (n.currentPage > 1) {
        n.previousPage = n.currentPage - 1
      }
      n['totalPage'] = Math.floor(cashierOfficeData.length / pageSize)
      if (cashierOfficeData.length % pageSize != 0) {
        n['totalPage'] += 1
      }
      setpagination(n)
      setIsNextDisable(true)
      setapiloader(false)
      setclassArray(cashierOfficeData.slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || [])
  }
  useEffect( () => {
    // console.log("Action is here ",action);
    fetchCurrency(masterServiceBaseUrl + '/currency/getAllCurrency');
    fetchBusinessArea(masterServiceBaseUrl + '/businessArea/getAllActiveBusinessArea');
   // fetchClasses(masterServiceBaseUrl + '/cashierOffice/getCashierOfficeByBusinessAreaId/'+ props.businessAreaId);
    if ( ( action === 'edit' || action === 'view' || isPrevious) && cashierOfficeData ) {
      performPaginationFormData();
    }
  }, [])

  const toNextStep = () => {
    !props.isPrevious && props.isNew  && props.saveStepsCompleted(6)
    nextStep()
  }

  useEffect(()=>{
    return()=>{
      if(state.length){
        !props.isPrevious && props.isNew  && props.saveStepsCompleted(6)
      }
    }    
  },[state])

  useEffect(()=>{
    if(props.isPrevious && props.formId){
      setapiloader(true)
        fetchClasses(`${masterServiceBaseUrl}/cashierOffice/getCashierOfficeByBusinessAreaId/${props.formId}`)
    //     getAPI(
    //         `${masterServiceBaseUrl}/businessAreaBusinessUnitTaxSetupReceipts/getBusinessAreaBusinessUnitTaxSetupReceipts/${props.formId}`,
    //         (data) => {
    //             setTaxFranchises([...data.data]);   
    //             setApiLoader(false)   
    //         },
    //         (data) => {
    //           failureToast(data['message']);
    //           setApiLoader(false)   
    //         }
    //       );
     }
},[props.isPrevious,props.formId])


  return (
    <>
      {apiloader ? <div className="mx-auto text-center py-5 my-5" style={ { height: "100vh" } }><Loader
        type="Rings"
        color="#00BFFF"
        height={ 70 }
        width={ 70 }
        timeout={ 10000 } /></div> :
        <Container className="mt-3" fluid>
          <Row>
            <div className="col">
              <Card className='p-0 mb-0'>
                <CashierOfficeTable
                  previousPage={ previousPage }
                  fetchClasses={ fetchClasses }
                  setclassArray={ setclassArray }
                  classArray={ classArray }
                  tableData={ tableData }
                  pagination={ pagination }
                  setstate={ setstate }
                  businessArea={ businessArea }
                  currency={ currency }
                  businessAreaId={ action === 'edit' ? id : props.businessAreaId }
                  setpagination={ setpagination }
                  setisSaveVisible={ setisSaveVisible }
                  isSaveVisible={ isSaveVisible }
                  cashierOfficeData={cashierOfficeData}
                  action={action}
                />
    
                { pagination.totalPage > 1 ?
                  <GetPagination
                    pageSize={ pageSize }
                    isSaveVisible={ isSaveVisible }
                    state={ state }
                    setclassArray={ setclassArray }
                    classArray={ classArray }
                    fetchClasses={ fetchClasses }
                    pagination={ pagination }
                    setpagination={ setpagination }
                  /> : null
                }
              </Card>
              { action !== 'edit' && action !== 'view' ?
                <Card style={ { marginTop: '20px' } }>
                <Row>
                  <Col md={ 6 }>
                    <Button
                      color="primary"
                      type="button"
                      onClick={ () => {
                        previousStep();
                      } }
                    >
                      Previous
                      </Button>
                  </Col>
                  <Col md={ 6 }>
                    <Button
                      disabled={ !isNextDisable }
                      color="primary"
                      className={ 'floatRight ml-auto' }
                      type="button"
                      onClick={ () => {
                        toNextStep();
                      } }
                    >
                      Next
                      </Button>
                  </Col>
                </Row>
              </Card>:null}
            </div>
          </Row>
        </Container>
      } </>
  );
}
export default CashierOffice;
