import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { GridRowModes } from "@mui/x-data-grid";
import { newRow } from "./constant";
import { AGridColDef } from "views/pages/manage/common/data-grid/ADataGridColDef";
import ADataGrid from "views/pages/manage/common/data-grid/ADataGrid";
import { editCellContainerStyle } from "views/pages/manage/edpOps/stockManagement/mastersAndMapping/onBoardingMaterialIdSetup/constant";
import { IoEyeOutline } from "react-icons/io5";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { saveOrDeleteMsg } from "views/pages/manage/edpOps/stockManagement/mastersAndMapping/sapProfitCostCentreMapping/constant";
import { gridStyles } from "views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant";
import { gridComponents } from "views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant";
import { selectStyles } from "views/pages/manage/masters/holidayMaster/common";
import MultiSelectCellComponentWithSearch from "views/pages/manage/common/commonComponents/SearchFilterView/MultiSelectCellComponentWithSearch";
import { isObjEmpty } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { generateRandomId } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";
import ActionsCellComponent from "./ActionsCellComponent";
import moment from "moment";
import { Tooltip } from "@mui/material";
import NoRowOverlay from "views/pages/manage/masters/holidayMaster/NoRowOverlay";
import { getFormattedDate } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import StatusCellComponent from "./StatusCellComponent";
import CustomDatePickerV2 from "views/pages/manage/common/commonComponents/CustomDatePicker-V2";
import ConcessionRuleSelectDialog from "./ConcessionRuleSelectDialog";
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
const ListComponent = forwardRef(
  (
    {
      currentPage = 0,
      rowCount,
      nextButtonHandler,
      dropdown,
      rows,
      loader,
      createHandler,
      updateHandler,
      deleteHandler,
      toggleStatusHandler,
    },
    ref
  ) => {
    const [rowModesModel, setRowModesModel] = useState({});

    const [rowData, setRowData] = useState(rows);
    useEffect(() => setRowData(rows), [rows]);
    const [rowForm, setRowForm] = useState({});
    const rowFormHandler = (value, key) =>
      setRowForm((prev) => ({ ...prev, [key]: value }));

    const startEditing = (id, form) => {
      setRowModesModel((oldModel) => ({ [id]: { mode: GridRowModes.Edit } }));
      setRowForm(form);
    };

    const stopEditing = () => {
      const currForm = { ...rowForm };
      setRowModesModel((oldModel) => ({
        [currForm?.id]: { mode: GridRowModes.View, ignoreModifications: true },
      }));
      setRowForm({});
    };

    const handleEditClick = (params) => {
      if (!isObjEmpty(rowForm)) {
        failureToast(saveOrDeleteMsg);
      } else {
        startEditing(params?.id, params?.row);
      }
    };

    const handleCancelClick = async (params) => {
      if (params?.row?.isNew) {
        setRowData(rows);
        setRowForm({});
      } else {
        stopEditing(params?.id);
      }
    };

    const handleSaveClick = async (params) => {
      if (rowForm?.isNew) {
        await createHandler(rowForm);
      } else {
        await updateHandler(rowForm);
      }
    };

    const addNewRow = async () => {
      if (!isObjEmpty(rowForm)) {
        failureToast(saveOrDeleteMsg);
      } else {
        let randomId = generateRandomId();
        let emptyRow = { ...newRow, isNew: true, id: randomId };
        startEditing(randomId, emptyRow);
        setRowData((prev) => [emptyRow, ...prev]);
        // setRowData(prev => [
        //   ...prev?.slice(0, page * 50),
        //   emptyRow,
        //   ...prev?.slice(page * 50, prev?.length + 1),
        // ]);
      }
    };

    useImperativeHandle(ref, () => ({
      addNewRow: addNewRow,
      startEditing: startEditing,
      stopEditing: stopEditing,
    }));

    // const formatValue = (val) => {
    //   const arr = val.split(" ");
    //   return `${arr[0]} ${arr[1]}`;
    // };

    const columns = [
      new AGridColDef("academicCareer", "Career", false)
        .setMinWidth(110)
        .editable(true)
        .setFlex(0.5)
        .renderCellComponent((params) => (
          <MultiSelectCellComponentWithSearch
            title={"Career"}
            data={params?.value}
            eyeIcon
          />
        ))
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            <AAutoComplete
              currentValue={rowForm?.academicCareer}
              handleChange={(value) => rowFormHandler(value, "academicCareer")}
              items={dropdown?.academicCareer}
              isMulti={true}
              isShowCount={true}
              style={selectStyles}
              autoFocus={params?.hasFocus}
            />
          </div>
        )),
      new AGridColDef("businessArea", "BU", false)
        .setMinWidth(120)
        .editable(true)
        .setFlex(0.5)
        .renderCellComponent((params) => (
          <MultiSelectCellComponentWithSearch
            title={"Business area"}
            data={params?.value}
            eyeIcon
          />
        ))
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            <AAutoComplete
              currentValue={rowForm?.businessArea}
              handleChange={(value) => rowFormHandler(value, "businessArea")}
              items={dropdown?.businessArea}
              isMulti={true}
              isShowCount={true}
              style={selectStyles}
              autoFocus={params?.hasFocus}
            />
          </div>
        )),
      new AGridColDef("term", "Term", false)
        .setMinWidth(120)
        .editable(true)
        .setFlex(0.5)
        .renderCellComponent((params) => (
          <MultiSelectCellComponentWithSearch
            title={"Term"}
            data={params?.value}
            eyeIcon
          />
        ))
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            <AAutoComplete
              currentValue={rowForm?.term}
              handleChange={(value) => rowFormHandler(value, "term")}
              items={dropdown?.term}
              isMulti={true}
              isShowCount={true}
              style={selectStyles}
              autoFocus={params?.hasFocus}
            />
          </div>
        )),
      new AGridColDef("academicGroup", "Acad Group", false)
        .setMinWidth(110)
        .editable(true)
        .setFlex(0.5)
        .renderCellComponent((params) => (
          <MultiSelectCellComponentWithSearch
            title={"Academic group"}
            data={params?.value}
            eyeIcon
          />
        ))
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            <AAutoComplete
              currentValue={rowForm?.academicGroup}
              handleChange={(value) => rowFormHandler(value, "academicGroup")}
              items={dropdown?.academicGroup}
              isMulti={true}
              isShowCount={true}
              style={selectStyles}
              autoFocus={params?.hasFocus}
            />
          </div>
        )),
      new AGridColDef("courseCategory", "CRCT", false)
        .setMinWidth(120)
        .editable(true)
        .setFlex(0.5)
        .renderCellComponent((params) => (
          <MultiSelectCellComponentWithSearch
            title={"CRCT"}
            data={params?.value}
            eyeIcon
          />
        ))
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            <AAutoComplete
              currentValue={rowForm?.courseCategory}
              handleChange={(value) => rowFormHandler(value, "courseCategory")}
              items={dropdown?.courseCategory}
              isMulti={true}
              isShowCount={true}
              style={selectStyles}
              autoFocus={params?.hasFocus}
            />
          </div>
        )),
      new AGridColDef("concessionRule", "Concession Rule", false)
        .setMinWidth(200)
        .editable(true)
        // .setFlex(0.5)
        .renderCellComponent((params) => (
          <div className="d-flex  ellipsis-text-container w-100">
            <div className="ellipsis-text-container flex-grow-1">
              {params?.row?.percentageDiscount &&
                `${params?.row?.percentageDiscount} | `}
              {params?.value}
            </div>
            <div className="flex-shrink-0">
              <Tooltip title={`${params?.value}`}>
                <span>
                  <IoEyeOutline className="ml-2 pointer" size={18} />
                </span>
              </Tooltip>
            </div>
          </div>
        ))
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            <ConcessionRuleSelectDialog
              params={params}
              rowFormHandler={rowFormHandler}
              rowForm={rowForm}
            />
          </div>
        )),
      new AGridColDef("effectiveDate", "Effective Date", false)
        .setMinWidth(140)
        .editable(true)
        .setFlex(0.5)
        .renderCellComponent((params) =>
          params?.value ? (
            <div>{moment(params?.value)?.format("DD-MMM-YYYY")}</div>
          ) : (
            <div>-</div>
          )
        )
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            <CustomDatePickerV2
              placeHolder="Select date"
              value={rowForm?.effectiveDate}
              handler={(i, date) => rowFormHandler(date, "effectiveDate")}
              // minDate = {new Date()}
              // maxDate = {maxDate31Days()}
            />
          </div>
        )),
      new AGridColDef("status", "Status", false)
        .setMinWidth(120)
        .setFlex(0.5)
        .renderCellComponent((params) => (
          <StatusCellComponent
            params={params}
            toggleStatusHandler={toggleStatusHandler}
            rowForm={rowForm}
            rowFormHandler={rowFormHandler}
          />
        )),

      new AGridColDef("updatedOn", "Updated on/by", false)
        .setMinWidth(130)
        .setFlex(0.5)
        .renderCellComponent((params) =>
          params?.value ? (
            <div>
              {moment(params?.value)?.format("DD-MMM-YYYY")}
              <Tooltip
                title={`${getFormattedDate(params?.value)},
                  ${moment(params?.value).format("LT")} by ${
                  params?.row?.updatedBy
                }`}
              >
                <span>
                  <IoMdInformationCircleOutline
                    className="ml-2 pointer"
                    size={18}
                  />
                </span>
              </Tooltip>
            </div>
          ) : (
            <div>-</div>
          )
        ),
      new AGridColDef("actions", "Actions", false)
        .setMinWidth(130)
        .columnAlign("right")
        .setFlex(0.5)
        .renderCellComponent((params) => (
          <ActionsCellComponent
            params={params}
            handleEditClick={handleEditClick}
            handleCancelClick={handleCancelClick}
            handleSaveClick={handleSaveClick}
            rowForm={rowForm}
            // deleteHandler={deleteHandler}
          />
        )),
    ];
    return (
      <div className="mt-3">
        <ADataGrid
          removeWrapperContainer
          rowModesModel={rowModesModel}
          rows={rowData || []}
          columns={columns}
          rowId={(row) => row?.id}
          rowHeight={50}
          rowCount={rowCount}
          loading={false}
          currentPage={currentPage}
          nextButtonHandler={nextButtonHandler}
          components={{
            ...gridComponents,
            NoRowsOverlay: () => (
              <NoRowOverlay message={loader ? "Loading..." : null} />
            ),
          }}
          sx={{
            ...gridStyles,
          }}
          autoHeight
          hidePagination={rowCount <= 50}
        />
      </div>
    );
  }
);
export default ListComponent;
