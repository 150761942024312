import React, { Fragment, useEffect, useState } from "react";
import ExcelExportButton from "../../../common/commonComponents/ToExcel";
import { Button, Table, Input, Card, CardBody, CardHeader } from "reactstrap";
import { MasterHeaderAuto } from "../../../common/commonComponents/masterHeader/masterHeader";
import NoDataFound from "../../../common/noDataFound";
import TableList from "../../../common/tableList";
import {
  getAPI,
  putpost,
  putpost2,
  successToast,
} from "../../../../../../services/http";
import { failureToast } from "../../../common/utils/methods/toasterFunctions/function";
import Select from "react-select";
import { endpoint } from "../../../common/constant";
import { ReactSelectDropdown } from "../../../common/formFeilds";
import { Accordion } from "views/pages/manage/common/commonComponents/Accordion/accordion";
import AssignPermission from "./assignPermission";
const _ = require("lodash");
const ProcessDetails = (props) => {
  const {
    state,
    activeProcessTag,
    setRoleToProcessArr,
    roleToProcessArr,
    processTagArr,
    setIsSaveVisible,
    isSaveVisible,
    previousPage,
    masterRoleId,
    fetchRoleToProcessData,
  } = props;
  const [tableDataKey, settableDataKey] = useState("tableDataKey");
  const [tagTypeDesc, setTagTypeDesc] = useState({});
  const [roleMasterId, setRoleMasterId] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [processId, setProcessId] = useState('');
  const [permissionListPage, setPermissionListPage] = useState([]);
  const addNewClass = () => {
    setRoleToProcessArr([
      {
        editMode: true,
        __status: "__new",
        status: "ACTIVE",
        processTagTypeMasterId: null,
      },
      ...roleToProcessArr,
    ]);
  };
  const tableHeader = [
    { name: "Process Name", isRequired: true },
    { name: "Process Description", isRequired: true },
    // {name: 'Add Permission', isRequired: true},
    { name: "Status" },
    { name: "Action" },
  ];

  const listDetails = {
    processTagTypeMasterId: {
      normal: false,
      master: processTagArr,
      displayValue: "tagTypeDispValue",
      idName: "processTagTypeMasterId",
    },
    processDescription: {
      normal: false,
      master: processTagArr,
      displayValue: "tagTypeDesc",
      idName: "processTagTypeMasterId",
    },
  };

  const addNewRow = () => {
    try {
      if (isSaveVisible) {
        failureToast("Please Delete or Save current changes");
      } else {
        let cb = () => {
          addNewClass();
          setIsSaveVisible(true);
        };
        let _tempArray = roleToProcessArr;
        _tempArray = _.sortBy(_tempArray, [
          function (o) {
            return o.value;
          },
        ]);
        setRoleToProcessArr(_tempArray);
        setIsSaveVisible(false);
        cb();
      }
    } catch (e) {
      console.log("Error ", e);
    }
  };

  const deleteRow = (index) => {
    try {
      if (roleToProcessArr[index]["__status"] === "__new") {
        let n = roleToProcessArr;
        let roleId = roleMasterId;
        const findIndex = roleId.findIndex(
          (item) =>
            item === parseInt(roleToProcessArr[index].processTagTypeMasterId)
        );
        roleId.splice(findIndex, 1);
        n.splice(index, 1);
        setRoleToProcessArr(n);
        setRoleMasterId(roleId);
        setIsSaveVisible(false);
      } else {
        if (
          window.confirm("Are you sure you want to delete this Role Details ?")
        ) {
          putpost2(
            `${
              endpoint.role_To_Process.deleteRole +
              "/" +
              roleToProcessArr[index].id
            }`,
            (data) => {
              successToast(data["message"]);
              fetchRoleToProcessData(
                `${
                  endpoint.role_To_Process.searchByRoleMasterId +
                  "?roleMasterId=" +
                  masterRoleId
                }`,
                "SEARCH_RESULT"
              );
              if (roleToProcessArr.length === 1) {
                previousPage();
              } else {
                const _tempArray = roleToProcessArr;
                if (_tempArray[index]["editMode"]) {
                  setIsSaveVisible(false);
                }
                _tempArray.splice(index, 1);
              }
              settableDataKey(new Date().getMilliseconds());
            },
            (data) => {
              failureToast(data["message"]);
            },
            "PUT"
          );
        }
      }
    } catch (e) {
      console.log("Error : ", e);
    }
  };

  const saveAll = () => {
    try {
      const _tempArray = roleToProcessArr;
      if (_tempArray[0]["__status"] === "__new") {
        putpost(
          endpoint.role_To_Process.create,
          (data) => {
            successToast(data["message"]);
            setIsSaveVisible(false);
            setRoleMasterId([]);
            fetchRoleToProcessData(
              `${
                endpoint.role_To_Process.searchByRoleMasterId +
                "?roleMasterId=" +
                masterRoleId
              }`,
              "SEARCH_RESULT"
            );
          },
          (data) => {
            failureToast(data["message"]);
          },
          {
            processTagTypeMasterId: processId,
            roleMasterId: masterRoleId,
            permissionDetailRequestDtoList: permissionListPage,
          },
          "post"
        );
      }
    } catch (err) {
      console.log("Error", err);
    }
  };

  const updateKey = (index, value, key) => {
    try {
      const _tempArray = roleToProcessArr;
      _tempArray[index][key] = value;
      setRoleToProcessArr(_tempArray);
    } catch (e) {
      console.log("Error ", e);
    }
  };

  const excelDetails = {
    "Role Name": "roleName",
    "Role Description": "roleDescription",
    "Process Name": "processName",
    "Process description": "processDescription",
    Add: "add",
    Update: "update",
    Delete: "delete",
    "View Only": "viewOnly",
    Approve: "approve",
  };

  const fetchDataHandler = (url, type, roleDetails, item) => {
    try {
      getAPI(
        url,
        (data) => {
          dataHandler(data?.data, type, roleDetails, item);
        },
        (data) => {
          if (type === "PERMISSION") {
            dataHandler(
              [
                {
                  pageName: "",
                  pageDescription: "",
                  isAdd: "",
                  isUpdate: "",
                  isDelete: "",
                  isViewOnly: "",
                  isApprove: "",
                },
              ],
              type,
              roleDetails,
              item
            );
          }
        }
      );
    } catch (err) {
      console.log("Error :", err);
    }
  };

  const addRoleId = () => {
    try {
      const __tempArr = roleToProcessArr;
      const tempArr = roleMasterId;
      const value = __tempArr.find(
        (item) =>
          !item.__status && item.processTagTypeMasterId == tagTypeDesc.id
      );
      if (value) return failureToast("Record Already Exist");

      setTagTypeDesc({});
      tempArr.push(parseInt(tagTypeDesc.id));
      setRoleMasterId(tempArr);
      __tempArr[0]["editMode"] = false;
      setRoleToProcessArr(__tempArr);
      setIsSaveVisible(false);
    } catch (e) {
      console.log(e);
    }
  };

  const getDescription = (id) => {
    try {
      setProcessId(id);
      const value = processTagArr.find((item) => item.id == id);
      setTagTypeDesc({
        id: id,
        displayValue: value?.tagTypeDesc,
      });
      setIsSelected(true);
    } catch (e) {
      console.log(e);
    }
  };

  const dataHandler = (data, type, roleDetails, rowDetails) => {
    switch (type) {
      case "PERMISSION":
        data &&
          data.length > 0 &&
          data.map((item) => {
            const roleDetails = processTagArr.find(
              (v) => v.id === rowDetails.processTagTypeMasterId
            );
            let temp = exportData;
            temp.push({
              roleName: roleDetails.tagTypeDispValue,
              roleDescription: roleDetails.tagTypeDesc,
              processName: item?.pageName,
              processDescription: item?.pageDescription,
              add: item?.isAdd ? "YES" : item?.isAdd === false ? "NO" : "",
              update: item?.isUpdate
                ? "YES"
                : item?.isAdd === false
                ? "NO"
                : "",
              delete: item?.isDelete
                ? "YES"
                : item?.isAdd === false
                ? "NO"
                : "",
              viewOnly: item?.isViewOnly
                ? "YES"
                : item?.isAdd === false
                ? "NO"
                : "",
              approve: item?.isApprove
                ? "YES"
                : item?.isAdd === false
                ? "NO"
                : "",
            });
            setExportData(temp);
          });
        break;
      case "ROLE":
        props.roleToProcessArr &&
          props.roleToProcessArr.length > 0 &&
          props.roleToProcessArr.map((item) => {
            fetchDataHandler(
              `${
                endpoint.role_To_Process.searchByProcessIdAndRoleId +
                `?roleMasterId=${masterRoleId}&processTagTypeMasterId=${item.processTagTypeMasterId}`
              }`,
              "PERMISSION",
              roleDetails,
              item
            );
          });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    props.roleToProcessArr &&
      props.roleToProcessArr.length > 0 &&
      fetchDataHandler(
        endpoint.role_Name.getRoleByID + "/" + masterRoleId,
        "ROLE",
        masterRoleId,
        ""
      );
  }, [props.roleToProcessArr]);
  return (
    <Fragment>
      <Card>
        <CardHeader>
          <div className={"d-flex justify-content-between"}>
            <h2>Role Name</h2>
            <div className={"d-flex justify-content-end align-items-center"}>
              <ExcelExportButton
                data={exportData}
                detailObj={excelDetails}
                name="Attribute Mapping Details"
                className={"mx-3"}
              />
              <Button
                color={"info"}
                size={"sm"}
                onClick={() => addNewRow()}
                className={"mx-3"}
              >
                Add New
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardBody className={"p-0"}>
          <div
            id="questionTable"
            className="table-responsive"
            style={{ overflow: "auto" }}
          >
            <Table className="align-items-center table-flush ">
              <MasterHeaderAuto headerList={tableHeader} />
              <tbody className="list">
                {roleToProcessArr && roleToProcessArr.length > 0 ? (
                  roleToProcessArr.map((el, index) =>
                    !el["editMode"] ? (
                      <>
                        <TableList item={el} listDetails={listDetails}>
                          <td
                            className="text-center  white-breakSpace"
                            style={{ width: "200px" }}
                          >
                            <Button
                              color="info"
                              size="sm"
                              type="button"
                              className={" mx-1"}
                              onClick={() => deleteRow(index)}
                            >
                              <i className="fas fa-trash" />
                            </Button>
                          </td>
                        </TableList>
                      </>
                    ) : (
                      <Fragment>
                        <tr key={index + "-class"}>
                          <td>
                            <Select
                              defaultValue={tagTypeDesc?.id}
                              options={activeProcessTag}
                              className="tdSelect"
                              onChange={(e) => {
                                e.value &&
                                  getDescription(e.value);
                                e.value &&
                                  updateKey(
                                    index,
                                    e.value,
                                    "processTagTypeMasterId"
                                  );
                              }}
                            />
                          </td>
                          <td>
                            <Input
                              disabled
                              className={"tdInput"}
                              defaultValue={el["processDescription"]}
                              value={tagTypeDesc?.displayValue}
                            />
                          </td>

                          <td className="text-center p-2 mx-1">
                            <label className="custom-toggle mx-auto ml-2">
                              <input
                                checked={el.status === "ACTIVE"}
                                type="checkbox"
                                onChange={(e) => {
                                  settableDataKey(new Date().getMilliseconds());
                                  let n = "ACTIVE";
                                  if (!e.target.checked) {
                                    n = "INACTIVE";
                                  }
                                  // updateKey(index, n, 'status');
                                }}
                              />
                              <span
                                style={{ width: "72px" }}
                                className="custom-toggle-slider rounded-circle activeToggle"
                                data-label-off="Inactive"
                                data-label-on="Active"
                              />
                            </label>
                          </td>
                          <td className="text-center">
                            {isSaveVisible ? (
                              <>
                                {" "}
                                <Button
                                  data-testid="saveRow"
                                  color="info"
                                  size="sm"
                                  type="button"
                                  className="mx-1"
                                  onClick={() => {
                                    saveAll();
                                  }}
                                >
                                  Save
                                </Button>
                                <Button
                                  data-testid="closeRow"
                                  color="danger"
                                  size="sm"
                                  type="button"
                                  onClick={() => {
                                    let n = roleToProcessArr;
                                    if (n[index]["__status"] === "__new") {
                                      n.splice(index, 1);
                                      setTagTypeDesc({});
                                    } else {
                                      setIsSaveVisible(false);
                                    }
                                    setRoleToProcessArr(n);
                                    setIsSaveVisible(false);
                                  }}
                                >
                                  {" "}
                                  <i className="fas fa-times" />
                                </Button>{" "}
                              </>
                            ) : (
                              <Button
                                data-testid="deleteRow"
                                color="info"
                                size="sm"
                                type="button"
                                onClick={() => {
                                  deleteRow(index);
                                }}
                              >
                                {" "}
                                <i className="fas fa-trash" />
                              </Button>
                            )}
                          </td>
                        </tr>
                        {isSelected ? (
                          <tr>
                            <td colSpan={4} style={{ borderTop: "none" }}>
                              <Accordion
                                removePadding={true}
                                title={"Role Permission"}
                              >
                                <AssignPermission
                                  processId={processId}
                                  masterRoleId={masterRoleId}
                                  setPermissionListPage={setPermissionListPage}
                                  permissionListPage={permissionListPage}
                                />
                              </Accordion>
                            </td>
                          </tr>
                        ) : null}
                      </Fragment>
                    )
                  )
                ) : (
                  <tr>
                    <td colspan="5">
                      <NoDataFound />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>

            {/*<div className={"text-right p-4"}>*/}
            {/*  {roleMasterId.length > 0 ? (*/}
            {/*    <Fragment>*/}
            {/*      <Button color={"info"} onClick={() => saveAll()}>*/}
            {/*        Save*/}
            {/*      </Button>*/}
            {/*      <Button color={"info"} onClick={() => setRoleMasterId([])}>*/}
            {/*        Cancel*/}
            {/*      </Button>*/}
            {/*    </Fragment>*/}
            {/*  ) : null}*/}
            {/*</div>*/}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default ProcessDetails;
