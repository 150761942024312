import React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TestContentFormData from "./TestContentFormData";
import { addTestFormInput, tabLabelStyle, outlineButtonStyle } from "./style";
import { Row } from "reactstrap";
import { Divider } from "@mui/material";
import TestContentDataTempList from "./TestContentDataTempList";
import AButton from "./../../common/form-fields-mui/AButton";
import { useGetSyllabusByCourseId } from "./hooks";

const TestContentForm = ({
  formModal,
  testContentFormHandler,
  addChapterHandler,
  value,
  setValue,
  ...props
}) => {
  //const [value, setValue] = React.useState("1");
  const dropDown = useGetSyllabusByCourseId(props.currentSelectedTab?.courseId,props.setLoading);
  // const subjects = ["Physics", "Chemistry", "Mathematics"];
  const subjects = props.courses?.map((course) => course["subjectName"]);

  const currentSelectedCourse = (subject) => {
    let current = props.courses.filter(
      (course) => course["subjectName"] === subject
    );
    if(current[0]){
      props.setSelectedTab(current[0])
    }
    
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (!formModal[subjects[newValue - 1]]) {
      props.setFormModal({
        ...formModal,
        [subjects[newValue - 1]]: [addTestFormInput],
      });
    }
    if (!props.finalSavedData[subjects[newValue - 1]]) {
      props.setFinalSavedData({
        ...props.finalSavedData,
        [subjects[newValue - 1]]: [],
      });
    }
    currentSelectedCourse(subjects[newValue - 1]);
  };

  return (
    <TabContext value={value}>
      <TabList
        sx={tabLabelStyle}
        onChange={handleChange}
        aria-label="lab API tabs example"
      >
        {subjects.map((subject, index) => (
          <Tab
           sx = {{
            "&&.Mui-selected": {
              color: "#0F0F0F",
              fontWeight:"600"
                        }
          } }           key={`${subject}-${index}`}
            label={subject}
            value={(index + 1).toString()}
          />
        ))}
      </TabList>
      <Divider />
      {subjects.map((subject, index) => (
        <TabPanel
          key={`panelList-${subject}-${index}`}
          value={(index + 1).toString()}
        >
          {props.finalSavedData[subject] && (
            <TestContentDataTempList
              list={props.finalSavedData[subject]}
              subject={subject}
              formModal={props.finalSavedData}
              currentIndex={index}
              setFormModal={props.setFinalSavedData}
              dropDown={dropDown}

            />
          )}

          {formModal[subject] &&
            formModal[subject].map((form, formIndex) => (
              <TestContentFormData
                key={`${formIndex}-${subject}`}
                dropDown={dropDown}
                testContentFormHandler={testContentFormHandler}
                subject={subject}
                formModal={formModal}
                currentForm={form}
                addChapterHandler={addChapterHandler}
                currentIndex={formIndex}
                {...props}
              />
            ))}
          <Row className="mt-2">
            <AButton
              onClick={() => addChapterHandler(subject)}
              className="mt-2"
              variant="outline"
              updatedStyle= {outlineButtonStyle}
              disabled = {props.isCompleteSyllabus}
            >
              Add chapter
            </AButton>
          </Row>
        </TabPanel>
      ))}
    </TabContext>
  );
};

export default TestContentForm;
