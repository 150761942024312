
import { PermissionContext, RolePermissions, UserDetails } from "../../../../../../appContext";

import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useHistory, useParams } from "react-router";
import { BiArrowBack } from 'react-icons/bi';
import { endpoint, pages } from '../../../common/constant';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { failureToast } from '../../../common/utils/methods/toasterFunctions/function';
import BasicDetails from "../../../common/basicDetails";
import './index.scss';
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import OtherChargeList from "./OtherChargeList";
import WaiveOff from "./waiveOff";
import Collections from "./collection";
import { IoChevronForward } from "react-icons/io5";
import { dropdownMapping } from "../../studentCharges/common";
import { transformChargeStatus } from "../../studentCharges/studentChargesDetails/common";


const OtherChargesDetails = () => {
  const history = useHistory();

  const [academicCareer, setAcademicCareer] = useState(null);
  const [courseCategory, setCourseCategory] = useState(null);
  const [term, setTerm] = useState(null);
  const [businessAreas, setBusinessAreas] = useState(null);
  const [courses, setCourses] = useState(null);
  const [masterLoading, setMasterLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [updatingStatus, setUpdatingStatus] = useState(false);



  const { id } = useParams();
  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["studentOtherCharges"]['id']);

  const DYNAMIC_FORM_FIELDS = [
    { field: 'academicCareer', name: 'academicCareer', state: academicCareer, stateMethod: setAcademicCareer, url: endpoint.academic_career.getAllActive },
    { field: 'courseCategory', name: "courseAttributeValue", state: courseCategory, stateMethod: setCourseCategory, url: endpoint.course_details.courseAttribute },
    { field: 'term', name: "term", stateMethod: setTerm, state: term, url: endpoint.term.getAllActive },
    // { field: 'businessArea', name: "businessArea", state: businessAreas, stateMethod: setBusinessAreas, url: endpoint.businessArea.getAllCached },
    // { field: 'courseId', name: "courseId", state: courses, stateMethod: setCourses, url: endpoint.course_details.getCachedRecords },
  ];

  const DEPENDED_DYNAMIC_FORM_FIELDS = [
    { field: 'businessArea', name: "businessArea", state: businessAreas, stateMethod: setBusinessAreas, url: endpoint.businessArea.getBusinessAreaById, fieldName: "businessAreaId" },
    { field: 'courseId', name: "courseId", state: courses, stateMethod: setCourses, url: endpoint.course_details.getByCourseId, fieldName: "courseId" },
  ];

  useEffect(() => {
    (function init() {
      const getAllCalls = [];
      for (let i = 0; i < DYNAMIC_FORM_FIELDS.length; i++) {
        const { url, name, field, stateMethod } = DYNAMIC_FORM_FIELDS[i];
        getAllCalls.push(fetchAllData(url, name, field, stateMethod));
      }
      setMasterLoading(true);
      Promise.all(getAllCalls).then(() => setMasterLoading(false)).catch(err => console.log(err));
    })();
  }, []);

  async function fetchAllData(url, name, field, stateMethod) {
    try {
      const response = await fetchAllPromisedData(url);
      if (response) {
        if (Array.isArray(response)) {
          const list = response.map(item => {
            if (field === "courseId") {
              return {
                label: item.courseName,
                value: +item.courseId,
              }
            } else {
              return {
                label: item[`${name}DispValue`],
                value: item.id
              }
            }
          })
          stateMethod(list);
        } else {
          let res = {}
          if (field === "courseId") {
            res = {
              // For courseId, BE should send same response as other APIs
              label: response?.[0]?.courseName ?? '',
              value: +response?.[0]?.courseId ?? '',
            }
          } else {
            res = {
              label: response[`${name}DispValue`],
              value: response.id,
              desc: response[`${name}Desc`]
            }
          }
          const list = [res]
          stateMethod(list);
        }

      } else {
        failureToast("Could not fetch data!");
      }
    } catch (err) {
      failureToast("Could not fetch data!");
    }
  }
  const applicationDetailsNullData = useMemo(() => ({
    psid: null,
    studentName: "",
    phoneNo: "",
    email: "",
    applicationId: id,
    opportunityId: "",
    applicationDate: null,
    courseId: null,
    academicCareerId: null,
    courseCategoryId: null,
    termId: null,
    businessAreaId: null,
    registrationPaymentDate: null,
    chargeStatus: null,
    paymentStatus: null,
    baseFees: null,
    totalCourseFees: null,
    loading: true,
    updatedOn: null,
    updatedBy: null
  }), [id]);

  const userDetails = useContext(UserDetails);

  const [applicationDetails, setApplicationDetails] = useState(applicationDetailsNullData);
  const [courseAttributeData, setCourseAttributeData] = useState([]);
  const [otherCharges, setOtherCharges] = useState([]);
  const [courseInfomation, setCourseInfomation] = useState([]);

  async function getApplicationDetails() {
    setApplicationDetails(details => ({ ...details, loading: true }));

    try {
      const res = await Promise.all([
        fetchAllPromisedData(`${endpoint.getStudentChargesApplicationDetails}/${id}/OTHER_CHARGES`),
        fetchAllPromisedData(`${endpoint.courseCategoryOtherCharges.getAllActiveCourseCategoryOtherCharges}`),
        fetchAllPromisedData(`${endpoint.otherChargesConcession.getAllOtherCharegesById}/${id}`)
      ])

      if (res?.[0]) {
        if (Array.isArray(res?.[1])) {
          const tempData = res[1].map(p => { return {...p , label: p.courseAttributeValueDesc, value: p.id } })
          setCourseAttributeData(tempData)
        } else {
          setCourseAttributeData([])
        }
        if (Array.isArray(res?.[2])) {
          setOtherCharges(res?.[2].map((item, index) => { return { seq: index + 1, ...item } }))
        } else {
          setOtherCharges([])
        }
        const getAllCalls = [];
        for (let i = 0; i < DEPENDED_DYNAMIC_FORM_FIELDS.length; i++) {
          const { url, name, field, fieldName, stateMethod } = DEPENDED_DYNAMIC_FORM_FIELDS[i];
          getAllCalls.push(fetchAllData(`${url}/${res?.[0]?.[fieldName]}`, name, field, stateMethod));
        }

        await Promise.all(getAllCalls);
        setApplicationDetails({ ...res?.[0], chargeStatus : transformChargeStatus(res?.[0]?.chargeStatus), loading: false });
      } else {
        failureToast("Couldn't fetch application details.");
      }
    } catch (err) {
      failureToast("Couldn't fetch details.");
    }
  };

  // const getCourseDetails = async () => {
  //   const p = await Promise.all([
  //     dropdownMapping(endpoint?.course_details?.getCachedRecords, 'courseName', true),
  //   ])
  //   setCourseInfomation(p[0]);
  // }

  useEffect(() => {
    getApplicationDetails();
    // getCourseDetails();
  }, [id]);


  const [isOpen, setIsOpen] = useState({
    detailsTab: true,
    tab2: false,
    waive_off: false,
    tab4: false
  });

  return (
    <div className='other-charges-details-container'>
      <header>
        <BiArrowBack size={20} color={'#0F0F0F'} onClick={() => history.push('/admin/studentOtherCharges')} />
        <span className='title'>{applicationDetails.studentName}</span>
      </header>
      <Row className="w-100">
        <Col md='9'>
          <BasicDetails
            userPermissions={userPermissions}
            applicationDetails={applicationDetails}
            academicCareer={academicCareer}
            courseCategory={courseCategory}
            term={term}
            businessAreas={businessAreas}
            courses={courses}
            appCourseDetailsLoading={masterLoading}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isRequired={false}
          />
          <OtherChargeList
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            courseAttributeData={courseAttributeData}
            term={applicationDetails.termId}
            businessArea={applicationDetails.businessAreaId}
            applicationId={id}
            psid={applicationDetails.psid}
            otherCharges={otherCharges}
            setOtherCharges={setOtherCharges}
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
            updatingStatus={updatingStatus}
            courseInfomation={courseInfomation}
            userDetails={userDetails}
            courseId={applicationDetails.courseId}
            termDisplayVal={applicationDetails.termValue}
            businessAreaDisplayVal={applicationDetails.businessAreaValue}
          />

          <WaiveOff
            applicationId={id}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            otherCharges={otherCharges}
            courseAttributeData={courseAttributeData}
            psid={applicationDetails.psid}
            setOtherCharges={setOtherCharges}
            userPermissions={userPermissions}
            updatingStatus={updatingStatus}
            setUpdatingStatus={setUpdatingStatus}
            userDetails={userDetails}
          />
          <Collections
            applicationId={id}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            otherCharges={otherCharges}
            psid={applicationDetails.psid}
            userPermissions={userPermissions}
            selectionModel={selectionModel}
            userDetails={userDetails}
            setOtherCharges={setOtherCharges}
            setSelectionModel={setSelectionModel}
          />
        </Col>
        <Col md='3' style={{ paddingRight: 0 }}>
          <Card>
            <div className="d-flex justify-content-between p-3 align-items-center">
              <div className="d-flex flex-column">
                <span className="large-text color-dark">Payment history</span>
                <span className="regular-large-bold color-primary pt-2">View All</span>
              </div>
              <div>
                <IoChevronForward color='#00B0F5' size={20} />
              </div>
            </div>
            <div className="d-flex flex-column px-3 pb-3">
              <div>
                <span className="regular-bold color-grey-80">Last payment :</span>
              </div>
              <div className="pt-2">
                <span className="regular-bold color-grey-80">Item type :</span>
              </div>
              <div className="pt-2">
                <span className="regular-bold color-grey-80">Payment mode :</span>
              </div>
              <div className="pt-2">
                <span className="regular-bold color-grey-80">Receipt no. :</span>
              </div>
              <div className="pt-2">
                <span className="regular-bold color-grey-80">Payment type :</span>
              </div>
              <div className="pt-2">
                <span className="regular-bold color-grey-80">Paid on :</span>
              </div>
            </div>
            <div className="px-3"><hr className="w-100" /></div>
            <div className="d-flex justify-content-between p-3 align-items-center">
              <span className="regular-large-bold color-primary ">Provisional payment adjustment</span>
              <IoChevronForward color='#00B0F5' size={20} />
            </div>
            <div className="d-flex justify-content-between px-3 pb-3 align-items-center">
              <span className="regular-large-bold color-primary ">Student charges</span>
              <IoChevronForward color='#00B0F5' size={20} />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default OtherChargesDetails;


