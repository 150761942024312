import React from "react";
import GetProgressBar from "./../createAcademicPlan/GetProgressBar";
import { Button, CardBody } from "reactstrap";
import { Card } from "reactstrap";
import GetFrom from "./GetFrom";
import { useState, useEffect } from "react";

import { formLabelTitles, FORM_FIELD } from "./helper";
import AButton from "./../../common/form-fields-mui/AButton";


const isAllSubjectFacultySelected = (currentForm, subjectsObj)=>{
   let subjects = Object.keys(subjectsObj)  ;
   for(let subject of subjects) {
        if(!currentForm[subject]){
            return false ; ;
        }
   }
  return true ;
}

const AddTimeTable = ({
  batchId,
  form,
  subjectForm,
  formHandler,
  setOpenForm,
  currentStep,
  setCurrentStep,
  ...props
}) => {
 
  const isActive = (step) => {
    switch (step) {
    }
    switch (step) {
      case 1:
        return true;
      case 2:
        return form?.plan !== "" && form.duration!=='' && isAllSubjectFacultySelected(form, subjectForm) ;

      case 3:
         
        return form?.startFrom !== "" && form?.endOn !== ""
           && form?.daysPlan !== "" && form?.room  !== "";

      case 4:
        return true;

      default:
        break;
    }
  };
  
  const [isBtnDisabled, setIsBtnDisabled] = useState(isActive(currentStep));
  useEffect(() => {
    setIsBtnDisabled(isActive(currentStep));
  }, [form, currentStep]);

  return (
    <div className="m-3">
      <Card className="m-1" style={{ width: "446px", minHeight: "440px" }}>
        <CardBody className="align-vertical">
          <h3 className="timetable-heading">
            {(formLabelTitles.length < 4 && formLabelTitles[currentStep - 1]) ||
              ""}
          </h3>
          <div className="progress-bar-timetable">
            <GetProgressBar
              step={currentStep}
              isTimeTable={true}
              setStep={setCurrentStep}
            />
          </div>
          <GetFrom
            step={currentStep}
            plansDropdown={props?.plansDropdown}
            roomsDropdown = {props?.roomsDropdown}
            batchDetails={props?.batchDetails}
            form={form}
            formHandler={formHandler}
            planSubjects = { props?.planSubjects}
            subjectFacultyDropdown = {props?.subjectFacultyDropdown}
            daysPlanDropdown = {props?.daysPlanDropdown}
          />
          <AButton
            className=" btn-primary  btn-block timetable-next-btn button_remove_left_margin"
            onClick={() => {
              if (currentStep === 3) {
                if(props.fetchPlanTests){
                  props.fetchPlanTests(form)
                }else{
                  setOpenForm("ACADEMIC_TEST");
                }
              } else {
                setCurrentStep(currentStep + 1);
              }
            }}
            disabled={!isBtnDisabled}
            variant="primary_filled"
          >
            {currentStep === 3 ? "Create Timetable" : "Next"}
          </AButton>
        </CardBody>
      </Card>
    </div>
  );
};

export default AddTimeTable;
