import React, { useState, useRef } from "react";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  Input,
  Table,
  UncontrolledTooltip,
  CardHeader,
} from "reactstrap";

import Select from "react-select";
import moment from "moment";
import {
  putpost,
  putpost2,
  uploadpost,
  enrollmentServiceBaseUrl,
  documentServiceBaseUrl,
} from "../../../../../../../../services/http";
import { MasterHeaderAuto } from "../../../../../common/commonComponents/masterHeader/masterHeader";
import { getRequiredSign } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { failureToast, successToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import CustomDatePicker from '../../../../../common/commonComponents/CustomDatePicker';


const styles = {
  container: (base) => ({
    ...base,
    flex: 1,
  }),
  menu: (base) => ({
    ...base,
    maxHeight: 150,
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 150,
    overflowY: "scroll",
  }),
};

var _ = require("lodash");
const DocumentUploadTable = (props) => {
  const {
    setIsSaveVisible = () => { },
    isSaveVisible = false,
    setClassArray = () => { },
    classArray,
    sourceData = [],
    studentData,
    personType,
    processType,
    isViewOnly = false,
    isEditable = false,
    documentUserId,
    action1,
    setFirstRecordSaved = () => { },
    mandatoryDocument,
    setMandatoryDocument = () => { },
    getDataByPIdAndAId = () => { },
  } = props;

  const uploadFileRef = useRef(null);
  const [documentType, setDocumentType] = useState(null);
  const [initialStatus, setInitialStatus] = useState("PENDING");
  const [fileName, setFileName] = useState("");
  // const [bucketName, setBucketName] = useState("");
  const [s3Url, setS3Url] = useState(null);
  const [sourceId, setSourceId] = useState(null);
  const [submissionDate, setSubmissionDate] = useState("");
  const [fetching, setFetching] = useState(false);
  const [id, setID] = useState(null);
  const [file, setFile] = useState(null);
  // const [progressBarStatus, setProgressBarStatus] = useState(0);
  const [isUploading, setISUploading] = useState(false);
  const [followUpDate, setFollowUpDate] = useState(
    moment(new Date()).add(3, "days").format("YYYY-MM-DD")
  );

  const addNewClass = () => {
    setClassArray([
      {
        bucketName: "",
        documentTypeId: "",
        fileName: "",
        followUpDate: moment(new Date()).add(3, "days"),
        s3Url: "",
        sourceId: "",
        status: "PENDING",
        submissionDate: "",
        isEdit: false,
        isNew: true,
        currentStatus: "VALID",
      },
      ...classArray,
    ]);
  };

  //Column header array
  const headerList = [
    { name: "Document Type", colSpan: "2" },
    { name: "File Name" },
    { name: "Status" },
    { name: "Source", colSpan: "2" },
    { name: "Submission Date", colSpan: "2" },
    { name: "Followup Date", colSpan: "2" },
    // { name: "Valid/Invalid", colSpan: "2" },
    { name: "Action", colSpan: "5" },
  ];

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //Add new row to table
  const newRow = () => {
    let cb = () => {
      addNewClass();
      setIsSaveVisible(true);
    };
    let _tempArray = classArray;
    _tempArray = _.sortBy(_tempArray, [
      function (o) {
        return o.value;
      },
    ]);
    setClassArray(_tempArray);
    setIsSaveVisible(false);
    cb();
  };

  //Edit row in table
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      setIsSaveVisible(true);
      let tempData = classArray;
      tempData[index]["isEdit"] = true;
      setDocumentType(tempData[index]["documentType"]);
      setFileName(tempData[index]["fileName"]);
      // setBucketName(tempData[index]["bucketName"]);
      // setFileStatus(tempData[index]["fileStatus"]);
      tempData[index]["followUpDate"] &&
        setFollowUpDate(
          moment(tempData[index]["followUpDate"]).format("YYYY-MM-DD")
        );

      !tempData[index]["followUpDate"] &&
        setFollowUpDate(moment(new Date()).add(3, "days").format("YYYY-MM-DD"));
      // setS3Status(tempData[index]["s3Status"]);
      setS3Url(tempData[index]["s3Url"]);
      setSourceId(getSourceObject(tempData[index]["sourceId"]));
      setInitialStatus(tempData[index]["status"]);
      setSubmissionDate(tempData[index]["submissionDate"]);
    }
  };

  //save row to table after api call
  const saveRow = (res, index) => {
    let tempData = classArray;
    if (tempData[index]["isEdit"]) {
      let tempObject = {
        ...tempData[index],
        isEdit: false,
        bucketName: res?.Bucket ?? "",
        documentTypeId: tempData[index]["documentType"]["documentTypeId"],
        fileName: fileName,
        fileStatus: res?.Bucket ? "SUBMITTED" : "PENDING",
        followUpDate: followUpDate,
        s3Status: res?.Bucket ? "SUBMITTED" : "PENDING",
        s3Url: res?.Location ?? "",
        sourceId: sourceId?.value,
        status: res?.Bucket ? "SUBMITTED" : "PENDING",
        submissionDate: res?.submissionDate ?? submissionDate,
        processId: res?.processId,
      };
      tempData[index] = tempObject;
      setClassArray(tempData);
    }

    if (tempData[index]["isNew"]) {
      let tempObject = {
        ...tempData[index],
        isNew: false,
        bucketName: res?.Bucket ?? "",
        documentTypeId: documentType["documentTypeId"],
        fileName: fileName,
        fileStatus: res?.Bucket ? "SUBMITTED" : "PENDING",
        followUpDate: followUpDate,
        s3Status: res?.Bucket ? "SUBMITTED" : "PENDING",
        s3Url: res?.Location ?? "",
        sourceId: sourceId?.value,
        status: res?.Bucket ? "SUBMITTED" : "PENDING",
        submissionDate: res?.submissionDate ?? submissionDate,
        documentType: documentType,
        id: id,
        processId: res?.processId,
      };

      tempData[index] = tempObject;
      setClassArray(tempData);
    }

    setFetching(false);
    setIsSaveVisible(false);
    setISUploading(false);
    resetFields();

  };

  //Close edit mode
  const cancelRow = (index) => {
    let tempData = classArray;
    if (tempData[index]["isEdit"]) tempData[index]["isEdit"] = false;
    else if (tempData[index]["isNew"]) tempData.splice(index, 1);
    setClassArray(tempData);
    setIsSaveVisible(false);
    resetFields();
  };

  //reset all fields
  const resetFields = () => {
    setDocumentType(null);
    setFileName("");
    // setBucketName("");
    setFollowUpDate(moment(new Date()).add(3, "days").format("YYYY-MM-DD"));
    setS3Url(null);
    setSourceId(null);
    setInitialStatus("PENDING");
    setSubmissionDate("");
    setFile(null);
  };

  //this function is to handle file
  const handleFile = (e) => {
    if (!documentType) {
      failureToast("Please Select Document Type");
      return;
    } else {
      if (e?.target?.files[0]) {
        const file = e.target.files[0];

        const formats = documentType.documentFormats.toLowerCase().split(",");
        const fileExt = file.name.split(".")[1];

        if (formats.indexOf(fileExt) === -1) {
          failureToast(
            `You may only upload files in ${documentType.documentFormats?.toLowerCase()} format. Please, ensure that your file is from mentioned format`
          );
          return;
        }

        if (file.size > documentType.documentSize * 1000) {
          failureToast(
            `File size is too large and cannot be uploaded. Please reduce the size of the file as maximum size limit is ${documentType.documentSize / 1024
            } MB`
          );
          return;
        }

        setFileName(e.target.files[0].name);
        setFile(e.target.files[0]);
        setFollowUpDate("");

        // setISFileValid(true);
      } else {
        setFileName("");
        setFile(null);
        setFollowUpDate(moment(new Date()).add(3, "days").format("YYYY-MM-DD"));
        // setISFileValid(false);
      }
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //validation method
  const validateRequest = (index, isSubmit) => {
    let isValid = true;

    if (!studentData?.studentId?.value) {
      failureToast("Please Select Student ID");
      return;
    }

    if (!studentData?.applicationId?.value) {
      failureToast("Please Select Application ID");
      return;
    }

    if (!isSubmit) {
      if (!documentType?.value) {
        failureToast("Please Select Document Type");
        return;
      }

      if (file && !sourceId?.value) {
        failureToast("Please Select Source ID");
        return;
      }

      if (!fileName && !followUpDate) {
        failureToast("Please Enter FollowUp Date");
        return;
      }

      setISUploading(true);

      file
        ? getFilePath(index)
        : isEditable && classArray[index]["id"]
          ? saveRowToServer({}, index)
          : submitHandler({}, index);
    }

    if (isSubmit) {
      classArray.some((item, i) => {
        if (!item.sourceId) {
          failureToast(`Please Provide Source ID For Row ${i + 1}`);
          isValid = false;
          return true;
        }

        if (!item.fileName && !item.followUpDate) {
          failureToast(`Please Provide Followup Date For Row ${i + 1}`);
          isValid = false;
          return true;
        }
        return false;
      });
    }

    if (!isValid) {
      return;
    }

    isSubmit && submitHandler();

  };

  //get uploading endpoint from DMS (api call)
  const getFilePath = (index) => {
    const formData = {
      bulk: false,
      fileName: fileName,
      docType: documentType?.label,
      sourceId: sourceId?.value,
      keyValue: `${studentData?.applicationId?.value}_${studentData?.studentId?.value}`,
      serviceName: "STUDENT",
    };

    putpost(
      `${documentServiceBaseUrl}/documentManagement/getUploadUrl`,
      (data) => {
        if (data.code === 200 && data.data) {
          //  saveFileAndReturnData(data.data.uploadUrl, data.data.documentManagementId.processId, index);

          saveDataToStorage(data.data, index);
        }
        // submitOnAws()
      },
      (data) => {
        // setProgressBarStatus(0);
        setISUploading(false);
        failureToast(data["message"]);
      },
      formData,
      "post"
    );
  };

  //this is file uploadImage part
  const saveDataToStorage = (res, index) => {
    if (!studentData?.studentId?.value) {
      failureToast("Please Select Student ID");
      return;
    }

    if (!studentData?.applicationId?.value) {
      failureToast("Please Select Application ID");
      return;
    }

    if (!sourceId?.value) {
      failureToast("Please Select Source");
      return;
    }

    const formData = new FormData();
    formData.append("File", file);
    formData.append("destinationfolder", res.bucket);

    setISUploading(true);
    uploadpost(
      res.storage,
      (data) => {
        updateResponseToServer(
          { ...data.response, processId: res.processId },
          index
        );
        successToast(data["message"]);
      },
      (data) => {
        setISUploading(false);
        failureToast(data["message"]);
      },
      formData
    );
  };

  //update to DMS (api call)
  const updateResponseToServer = (response, index) => {
    const formData = {
      bucketName: response.Bucket,
      errorCode: "",
      etag: response.ETag,
      processId: response.processId,
      s3Url: response.Location,
      serviceName: "STUDENT",
    };

    putpost(
      `${documentServiceBaseUrl}/documentManagement/s3UploadedResponse`,
      (data) => {
        if (data.code === 200 && data.data) {
          (!isEditable && !classArray[index]["id"]) && submitHandler(response, index);
          (classArray[index]["id"]) && saveRowToServer(response, index);
          !isEditable && setFirstRecordSaved(true);
          isEditable && !classArray[index]["id"] && submitHandler(response, index);
        }
      },
      (data) => {
        failureToast(data["message"]);
      },
      formData,
      "post"
    );
  };

  //Submit data to enrollment services (api call)
  const submitHandler = (res, index, ttempData) => {
    setFetching(true);
    const tempData = classArray;
    let tempObject = {
      ...tempData[index],
      bucketName: res?.Bucket ?? "",
      documentTypeId: documentType["documentTypeId"],
      fileName: fileName,
      fileStatus: res?.Bucket ? "SUBMITTED" : "PENDING",
      followUpDate: followUpDate,
      s3Status: res?.Bucket ? "SUBMITTED" : "PENDING",
      s3Url: res?.Location ?? "",
      sourceId: sourceId?.value,
      status: res?.Bucket ? "SUBMITTED" : "PENDING",
      submissionDate: res?.submissionDate ?? submissionDate,
      processId: res?.processId,
    };
    tempData[index] = tempObject;
    setClassArray(tempData);

    const formdata = {
      applicationId: studentData?.applicationId?.value,
      businessArea: studentData.businessArea,
      opportunityId: studentData.opportunityId,
      personId: studentData.studentId.value,
      personName: studentData.studentName,
      personTypeId: personType,
      processTypeId: processType,
      term: studentData.term,
      documentDTOList: mandatoryDocument ? classArray : [classArray[index]],
    };

    putpost(
      `${enrollmentServiceBaseUrl}/userDocumentDetails/createUserDocumentDetails`,
      (data) => {
        if (data.code === 200 && data.data) {
          successToast(data.message);
          setMandatoryDocument(null);
          saveRow(res, index);
          if (action1) {
            props.history.push(
              `/admin/applicationIdGeneration/${action1}/${formdata.applicationId}`
            );
          } else {
            getDataByPIdAndAId(
              studentData.studentId.value,
              studentData?.applicationId?.value
            );
            //props.history.push("/admin/documentManagementSearch");
          }
        }
      },
      (data) => {
        setISUploading(false);
        failureToast(data.message);
      },
      formdata,
      "post"
    );
  };

  //delete row from table (api call)
  const deleteRow = (index) => { };

  //Edit data (api call)
  const saveRowToServer = (res, index, ttempData) => {
    if (!documentType?.value) {
      failureToast("Please Select Document Type");
      return;
    }

    if (fileName && !sourceId?.value) {
      failureToast("Please Select Source ID");
      return;
    }

    if (!fileName && !followUpDate) {
      failureToast("Please Select Followup Date");
      return;
    }

    const tempData = classArray[index];
    const formData = {
      ...tempData,
      bucketName: res?.Bucket,
      fileName: fileName,
      fileStatus: res["Location"] ? "SUBMITTED" : "PENDING",
      followUpDate: followUpDate,
      s3Status: res["Location"] ? "SUBMITTED" : "PENDING",
      s3Url: res?.Location ?? res["Location"],
      sourceId: sourceId?.value,
      status: res["Location"] ? "SUBMITTED" : "PENDING",
      submissionDate: "",
      documentTypeId: documentType?.value,
      processId: res?.processId,
      documentUserTypeDetailsId: documentUserId
    };

    putpost(
      `${enrollmentServiceBaseUrl}/userDocumentDetails/updateUserDocumentDetails?documentUserTypeDetailsId=${documentUserId}`,
      (data) => {
        successToast(data.message);
        setID(data.data.id);
        // setSubmissionDate(moment(data.data.submissionDate).format("DD-MM-YYYY"))
        // setSubmissionDate("12-15-2009")
        res = {
          ...res,
          submissionDate: data.data.submissionDate ?? new Date(),
        };
        // ttempData && setClassArray(ttempData);

        // setFetching(false);
        // setIsSaveVisible(false);
        // setISUploading(false);
        saveRow(res, index);
        setFetching(false);
        setIsSaveVisible(false);
        setISUploading(false);
      },
      (data) => {
        setISUploading(false);
        failureToast(data.message);
      },
      formData,
      "put"
    );
  };

  const TDROW = (props) => {
    const { data, index } = props;
    return (
      <tr>
        <td className="text-center p-2 mx-1 white-breakSpace" colSpan="2">
          <div className="d-flex align-items-center flex-row justify-content-center">
            {data?.documentType?.isMandatory && getRequiredSign()}
            {data?.documentType?.documentTypeDispValue}
            <i
              className="fa fa-info-circle pl-2"
              style={{ fontSize: 15, alignSelf: "flex-end", textAlign: "end" }}
              id={data?.documentType?.documentTypeKey}
            ></i>
            {data?.documentType?.documentFormats && (
              <UncontrolledTooltip
                placement="top"
                target={data?.documentType?.documentTypeKey}
              >
                {`File Format allowed are ${data?.documentType?.documentFormats?.toLowerCase()}. Max file size should be ${data?.documentType?.documentSize / 1024
                  } MB.`}
              </UncontrolledTooltip>
            )}
          </div>
        </td>
        <td className="text-center p-2 mx-1 white-breakSpace">
          {data?.fileName}
        </td>
        <td className="text-center p-2 mx-1 white-breakSpace">
          {data?.status}
        </td>
        <td className="text-center p-2 mx-1 white-breakSpace" colSpan="2">
          {getSourceObject(data?.sourceId)?.label}
        </td>
        <td className="text-center p-2 mx-1 white-breakSpace" colSpan="2">
          {(data?.submissionDate &&
            moment(data?.submissionDate).format("DD MMM YYYY")) ??
            ""}
        </td>
        <td className="text-center p-2 mx-1 white-breakSpace" colSpan="2">
          {(data?.followUpDate &&
            moment(data?.followUpDate).format("DD MMM YYYY")) ??
            ""}
        </td>
        {/* <td className="text-center p-2 mx-1 white-breakSpace" colSpan="2">
          {data["currentStatus"] === "INVALID" ? (
            <button
              disabled={true}
              type="button"
              className="btn btn-sm btn-warning"
            >
              INVALID
            </button>
          ) : (
            <button
              type="button"
              disabled={true}
              className="btn btn-sm btn-success"
            >
              VALID
            </button>
          )}
        </td> */}
        <td className="text-center p-2 mx-1 td-w-100" colSpan="5">
          {data?.s3Url && (
            <Button
              color={!data?.s3Url ? "secondary" : "info"}
              disabled={!data?.s3Url}
              size="sm"
              type="button"
              onClick={() => window.open(`${data?.s3Url}`)}
            >
              View
            </Button>
          )}
          {data?.s3Url && (
            <Button
              color={!data?.s3Url ? "secondary" : "info"}
              disabled={!data?.s3Url}
              size="sm"
              type="button"
              onClick={() => window.open(`${data?.s3Url}`)}
            >
              Download
            </Button>
          )}

          {!isViewOnly && !data?.s3Url && data["currentStatus"] !== "INVALID" && (
            <Button
              color={isViewOnly ? "secondary" : "info"}
              disabled={isViewOnly}
              size="sm"
              type="button"
              className={" mx-1"}
              onClick={() => {
                editRow(index);
              }}
            >
              <i className="fas fa-edit" />
            </Button>
          )}

          {/* {isEditable && (
            <Button
              color={"danger"}
              disabled={data?.documentType?.isMandatory}
              size="sm"
              type="button"
              className={" mx-1"}
              onClick={() => {
                deleteRow(index);
              }}
            >
              <i className="fas fa-trash" />
            </Button>
          )} */}
        </td>
      </tr>
    );
  };

  const getSourceObject = (id) => {
    const i = sourceData?.filter((item) => item.value == id);
    return i ? i?.[0] : null;
  };

  return (
    <>
      <Card>
        <CardHeader className="d-flex justify-content-end">
          <Button
            disabled={isSaveVisible || isViewOnly}
            color="info"
            size="md"
            type="button"
            className={
              "floatRight" + (isSaveVisible || isViewOnly ? " btn-dark" : null)
            }
            onClick={() => {
              newRow();
            }}
            data-testid="addNewInvoiceStatus"
          >
            <i className="fas fa-plus" /> &nbsp;Add New Document
          </Button>
        </CardHeader>
        <div
          id="questionTable"
          className="pl-3 pt-3 pr-3 table-responsive-layout"
          style={{
            overflowX: "scroll",
            width: "100%",
            display: "inline-block",
            paddingBottom: "200px",
          }}
        >
          <Table className="align-items-center" style={{ minWidth: "1450px" }}>
            <MasterHeaderAuto headerList={headerList} />

            <tbody className="list">
              {classArray.map((data, index) =>
                data["isEdit"] || data["isNew"] ? (
                  <tr key={data["id"] + "-class"}>
                    <td
                      className="text-center p-2 white-breakSpace"
                      colSpan="2"
                    >
                      <div className="d-flex align-items-center flex-row">
                        {data["isEdit"] && data.documentType.isMandatory ? (
                          <Input
                            value={documentType?.label}
                            style={{ height: "39px" }}
                            placeholder="Document Type"
                            disabled
                          // onChange={(e) => setDocumentTypeDesc(e.target.value)}
                          />
                        ) : (
                          <Select
                            value={documentType}
                            id="docuemnt_upload_document_type"
                            options={props.documents ?? []}
                            styles={styles}
                            placeholder="Select Document Type"
                            onChange={(sData) => setDocumentType(sData)}
                            className="tdSelect"
                            isDisabled={isUploading}
                          />
                        )}
                        {documentType?.value && (
                          <>
                            <i
                              className="fa fa-info-circle pl-2"
                              style={{ fontSize: 25 }}
                              id={documentType?.documentTypeKey}
                            ></i>
                            <UncontrolledTooltip
                              placement="top"
                              target={documentType?.documentTypeKey}
                            >
                              {`File Format allowed are ${documentType?.documentFormats?.toLowerCase()}. Max file size should be ${documentType?.documentSize / 1024
                                } MB.`}
                            </UncontrolledTooltip>
                          </>
                        )}
                      </div>
                    </td>
                    <td className="text-center p-2 white-breakSpace">
                      <input
                        type="file"
                        ref={uploadFileRef}
                        onChange={handleFile}
                        style={{ opacity: 0, zIndex: -5, position: "absolute" }}
                      />
                      <Input
                        value={fileName}
                        style={{ height: "39px" }}
                        placeholder="File Name"
                        disabled
                      // onChange={(e) => setDocumentTypeDesc(e.target.value)}
                      />
                    </td>
                    <td className="text-center p-2 mx-1 white-breakSpace">
                      <Input
                        value={initialStatus}
                        style={{ height: "39px" }}
                        placeholder="Status"
                        disabled
                      // onChange={(e) =>
                      //   setDocumentTypeDispValue(e.target.value)
                      // }
                      />
                    </td>
                    <td
                      className="text-center p-2 mx-1 white-breakSpace"
                      colSpan="2"
                    >
                      <Select
                        value={sourceId}
                        id="docuemnt_upload_sourse"
                        options={sourceData}
                        styles={styles}
                        isDisabled={isUploading}
                        placeholder="Select Source"
                        onChange={(sData) => setSourceId(sData)}
                        className="tdSelect"
                      // isDisabled={isDisabled}
                      />
                    </td>
                    <td
                      className="text-center p-2 mx-1 white-breakSpace"
                      colSpan="2"
                    >
                      <Input
                        value={
                          submissionDate
                            ? moment(submissionDate).format("DD-MM-YYYY")
                            : ""
                        }
                        style={{ height: "39px" }}
                        placeholder="Submission Date"
                        disabled
                      // onChange={(e) =>
                      //   setDocumentTypeDispValue(e.target.value)
                      // }
                      />
                    </td>
                    <td
                      className="text-center p-2 mx-1 white-breakSpace"
                      colSpan="2"
                    >
                      {/* <Input
                        type="date"
                        disabled={fileName}
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        value={followUpDate}
                        style={{ height: "39px" }}
                        placeholder="Followup Date"
                        onChange={(e) => setFollowUpDate(e.target.value)}
                      /> */}
                      <CustomDatePicker
                        aria-label="startDate"
                        value={followUpDate}
                        placeholder="Followup Date"
                        handler={(i, v, k) => setFollowUpDate(v)}
                        // index={index}
                        rangeFrom={1950}
                        rangeTo={2099}
                        minDate={moment(new Date()).format("YYYY-MM-DD")}
                        keyName='followupDate'
                        forTable={true}
                        data-testid="followupDate"
                      />
                    </td>
                    {/* <td
                      className="text-center p-2 mx-1 white-breakSpace"
                      colSpan="2"
                    >
                      {data["currentStatus"] === "INVALID" ? (
                        <button
                          disabled={true}
                          type="button"
                          className="btn btn-sm btn-warning"
                        >
                          INVALID
                        </button>
                      ) : (
                        <button
                          type="button"
                          disabled={true}
                          className="btn btn-sm btn-success"
                        >
                          VALID
                        </button>
                      )}
                    </td> */}

                    <td className="text-center p-2 mx-1 td-w-100" colSpan="5">
                      <Button
                        color="info"
                        size="sm"
                        type="button"
                        className="mx-1"
                        disabled={isUploading}
                      // onClick={() => saveRow(index)}
                      >
                        Create & Send Link
                      </Button>
                      <Button
                        color="info"
                        size="sm"
                        type="button"
                        className="mx-1"
                        disabled={isUploading}
                      // onClick={() => saveRow(index)}
                      >
                        Camera
                      </Button>
                      <Button
                        color="info"
                        size="sm"
                        type="button"
                        className="mx-1"
                        disabled={isUploading}
                      // onClick={() => saveRow(index)}
                      >
                        Scan
                      </Button>
                      <Button
                        color={"info"}
                        size="sm"
                        type="button"
                        disabled={isUploading}
                        onClick={() => {
                          uploadFileRef.current.click();
                        }}
                      >
                        Upload
                      </Button>
                      {!data.isNew && !data.isEdit && (
                        <Button
                          color={data.isNew ? "secondary" : "danger"}
                          disabled={data.isNew}
                          size="sm"
                          type="button"
                        // onClick={() => {
                        //   cancelRow(index);
                        // }}
                        >
                          Delete
                        </Button>
                      )}
                      {(data.isNew || data.isEdit) && (
                        <Button
                          color={"info"}
                          size="sm"
                          type="button"
                          disabled={isUploading}
                          onClick={() => validateRequest(index, false)}
                        >
                          {isUploading ? (
                            <i className="fas fa-spinner fa-spin" />
                          ) : (
                            // <i className="fas fa-check" />
                            <>Save</>
                          )}
                        </Button>
                      )}
                      {(data.isNew || data.isEdit) && (
                        <Button
                          color={"danger"}
                          size="sm"
                          type="button"
                          disabled={isUploading}
                          onClick={() => {
                            cancelRow(index);
                          }}
                        >
                          <i className="fas fa-times" />
                        </Button>
                      )}

                    </td>
                  </tr>
                ) : (
                  <TDROW
                    key={"TDROW" + index}
                    data={data}
                    index={index}
                    history={props.history}
                  />
                )
              )}
            </tbody>
          </Table>
        </div>
      </Card>
      {/* <div className="d-flex justify-content-end p-3">
        <Button
          color={"info"}
          size="sm"
          type="button"
          disabled={isUploading}
          onClick={() => {
            props.history.push("/admin/documentManagementSearch");
          }}
        >
          Close
        </Button>
      </div> */}
      <div className="d-flex justify-content-end p-3">
        {isViewOnly || isEditable ? (
          <Button
            color={"info"}
            size="sm"
            type="button"
            disabled={isUploading}
            onClick={() => {
              if (action1) {
                window.close();
              } else {
                props.history.push("/admin/documentManagementSearch");
              }
            }}
          >
            Close
          </Button>
        ) : // <Button
          //   color={"info"}
          //   size="sm"
          //   type="button"
          //   disabled={isUploading}
          //   onClick={() => {
          //     validateRequest("", true);
          //   }}
          // >
          //   {fetching ? <i className="fas fa-spinner fa-spin" /> : "Save"}
          // </Button>
          null}
      </div>
    </>
  );
};

export default withRouter(DocumentUploadTable);
