import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import ALoader from "views/pages/manage/common/a-loader";
import IssuanceTabItem from "../stockTxns/IssuanceTabItem";
import { searchStockTransactions } from "../service";
import RecordsCounter from "../stockTxns/RecordsCounter";
import StockTransactionTable from "../stockTxns/StockTransactionTable";
import { lastDays } from "../stockTxns";
import moment from "moment";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx";
import { transactionTypeMap } from "../stockReturn/constant";

const BranchConsumptionReturnPopup = ({
  open,
  setOpen,
  dropdown,
  academicType = "RCC",
  forDlp = false,
}) => {
  const handleClose = () => {
    setOpen(false);
    searchTransaction(false);
    // setCheckedOption("psid");
  };

  const initialForm = {
    issuanceDateFrom: lastDays(),
    issuanceDateTo: moment(new Date()).endOf("day"),
    status: "SUCCESS",
  };
  const [listLoader, setListLoader] = useState(false);
  const [checkedOption, setCheckedOption] = useState("txnId");
  const [form, setForm] = useState(initialForm);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [isSearchTransaction, searchTransaction] = useState(false);

  useEffect(() => {
    setCheckedOption("txnId");
    setRows([]);
    setRowCount(0);
    setPage(0);
    setForm(initialForm);
  }, [open]);

  const formHandler = (value, key) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const viewDetailsHandler = async (request) => {
    fetchTxnList(0);
  };
  const nextButtonHandler = async (page = 0) => {
    // fetchTxnList();
    fetchTxnList(page);
  };

  const onResetClearData = () => {
    setRows([]);
    setRowCount(0);
  };

  const fetchTxnList = async (pageNo) => {
    try {
      setListLoader(true);
      setPage(pageNo);

      const res = await searchStockTransactions(
        {
          ...form,
          type: transactionTypeMap.BRANCH_ISSUANCE[academicType],
          groupBy: "Transaction",
        },
        pageNo
      );
      if (res?.data) {
        setRows(res?.data?.stockTransactionAudList);
        setRowCount(res?.data?.totalRecords);
        checkedOption === "otherDetails" && searchTransaction(true);
      }
      // console.log(res);
      setListLoader(false);
    } catch (error) {
      setListLoader(false);
    }
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{ sx: { maxWidth: "unset", borderRadius: "12px" } }}
      >
        {listLoader && <ALoader />}
        <div style={{ padding: "28px 24px", width: "954px" }}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="heading-4 color-black">
              Branch consumption return
            </div>
            <IconButtonWrapper onClick={handleClose}>
              <CrossIcon width={24} height={24} />
            </IconButtonWrapper>
          </div>
          <IssuanceTabItem
            checkedOption={checkedOption}
            setCheckedOption={setCheckedOption}
            formHandler={formHandler}
            form={form}
            isConsumptionReturn={true}
            dropdown={dropdown}
            viewDetailsHandler={viewDetailsHandler}
            selectedTab={"Branch consumption"}
            isHideReason={true}
            screenName="returnStock"
            onResetClearData={onResetClearData}
            academicType={academicType}
            forDlp={forDlp}
            forHistory={false}
          />

          {isSearchTransaction && checkedOption === "otherDetails" && (
            <>
              <RecordsCounter count={rowCount} />
              <StockTransactionTable
                rowData={rows}
                rowCount={rowCount}
                nextButtonHandler={nextButtonHandler}
                isConsumptionReturn={true}
                academicType={academicType}
                forDlp={forDlp}
              />
            </>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default BranchConsumptionReturnPopup;
