import {
  STUDENT_LIST_REQUEST,
  STUDENT_LIST_FAILURE,
  STUDENT_LIST_SUCCESS,
  UPDATE_STUDENT_LIST_DROPDOWNS,
  STUDENT_LIST_CLEAR,
  START_LOADER,
  STOP_LOADER,
  REQUEST_DROPDOWNS,
  UPDATE_DROPDOWNS
} from "./actionTypes";

const defaultState = {
  studentList: [],
  rowCount: 0,
  currentPage: 0,
  loading: false,
  dropdowns: {},
  dropdownLoader: true
};

export const studentChargesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_DROPDOWNS:
      return {
        ...state,
        dropdowns: action.dropdowns,
        dropdownLoader: false
      };
    case REQUEST_DROPDOWNS:
      return {
        ...state,
        dropdownLoader: true
      }
    case STUDENT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case STUDENT_LIST_SUCCESS:
      return {
        ...state,
        studentList: action.studentList,
        rowCount: action.rowCount,
        currentPage: action.currentPage,
        loading: false,
      };
    case STUDENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        studentList: [],
        rowCount: 0,
      };
    case STUDENT_LIST_CLEAR:
        return {
          ...state,
          loading: false,
          studentList: [],
          rowCount: 0,
     };
    case UPDATE_STUDENT_LIST_DROPDOWNS:
      return {
        ...state,
        dropdowns: action.dropdowns,
        loading: false,
      };
    // case UPDATE_STUDENT_LIST_DROPDOWNS:
    //   return {
    //     ...state,
    //     loading: false,
    //   };
    case START_LOADER:
      return {
        ...state,
        loading: true
      }
    case STOP_LOADER:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
};
