import React, { useEffect, useState } from 'react';
import { PermissionContext, RoutesDetails, routesValue, UserDetails } from 'appContext';
import AdminFooter from 'components/Footers/AdminFooter.js';
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { getCookie } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { getUserPermission, getUserDetails } from 'views/pages/manage/common/utils/layoutMethods';
import ErrorPage from '../components/ErrorPage';
import InternetConnection from '../components/InternetConnection';
import useNetworkStatus from '../components/InternetConnection/netwrokStatus';
import { SomethingWentWrongContext, SomethingWentWrongDispatch } from '../contexts/SomethingWentWrong';
import CustomRoutes from './CustomRoutes';
import PageLoadingAnimation from 'views/pages/manage/common/commonComponents/PageLoadingAnimation';
import { failureToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import { SomethingWentWrong } from '../services/utils/helpers';
import { qaScope } from 'views/pages/manage/common/constant/pages';
import {setRole} from '../redux/concessionApproval/actions';
import UserFavouritesContextProvider from '../appContext/favouritesContext';

const Admin = props => {
  const { isError = '' } = props.match?.params;
  const dispatch = useDispatch();

  document.body.classList.remove('bg-default');
  const isSomeThingWentWrong = React.useContext(SomethingWentWrongContext);
  const setIsSomethingWentWrong = React.useContext(SomethingWentWrongDispatch);
  const history = useHistory();
  const [sidenavOpen, SetsidenavOpen] = useState(false);
  const isLoading = useSelector(state => state?.loader?.isLoading);
  const [token, setToken] = useState(getCookie('token'));
  const [routesDetails, setRoutesDetails] = useState();
  const [useDetails, setUserDetails] = useState();
  const [isChanged, setIsChanged] = useState(false);
  const [mouseOverVerticalNavBar, setMouseOverVerticalNavBar] = useState(false);
  const [mouseEventAllowed,setMouseEventsPermission] = useState(true);

  const getRoutes = routes => {
    return (
      routes &&
      routes.map((prop, key) => {
        if (prop.collapse) {
          return getRoutes(prop.views);
        }
        if (prop.layout === '/admin') {
          return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
        } else {
          return null;
        }
      })
    );
  };


  const handleSideNavBar = () => {
    // if () {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    // }
  }

  const toggleSidenav = e => {
    // handleSideNavBar();
    if (sidenavOpen ) {
      if (document.body.classList.contains('g-sidenav-pinned')) {
        document.body.classList.remove('g-sidenav-pinned');
        document.body.classList.add('g-sidenav-hidden');
        setMouseEventsPermission(true);
        setMouseOverVerticalNavBar(false);
      }
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');

      setMouseEventsPermission(false);
      setMouseOverVerticalNavBar(false);
    }
    SetsidenavOpen(!sidenavOpen);
  };

  const toggleMouseOver = e => {
    // if (!sidenavOpen) {
      // handleSideNavBar()
      setMouseOverVerticalNavBar(e);
    // }
  };


  const hideNavbar = () => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-show');
      document.body.classList.add('g-sidenav-hidden');
    }
    SetsidenavOpen(false);
  }

  const getNavbarTheme = () => {
    return props.location.pathname.indexOf('admin/alternative-dashboard') === -1 ? 'dark' : 'light';
  };

  const [searchTxt, setSearchTxt] = React.useState('');
  const [haveFocus, setHaveFocus] = React.useState(false);
  const [permissions, setPermissions] = useState();
  const loaction = useLocation();
  let [status] = useNetworkStatus();

  if (!getCookie('token')) {
    history.replace('/');
  }

  const setUserPermissions = async () => {
    const getPermissions = await getUserPermission();
    dispatch(setRole(getPermissions.roleType))

    if (!getPermissions?.scope) {
      failureToast('You do not have the permission.Please contact your administration');
      // history.replace("/admin/dashboard");
    }

    // const newPermissions = {...getPermissions, scope:{...qaScope, ...getPermissions?.scope}}
    // setPermissions(newPermissions);
    setPermissions(getPermissions);
    const routesValues = routesValue(getPermissions?.scope);
    // const routesValues = routesValue(newPermissions?.scope);
    setRoutesDetails(routesValues);

    const userDetails = await getUserDetails();
    setUserDetails(userDetails?.data);
    setIsChanged(!isChanged);
  };
  

  useEffect(() => {
    if (getCookie('token')) {
      setUserPermissions()
      if (window.location.toString().split('#')[1] === '/admin/errorPage/true') {
        history.push(getCookie('lastKnowPath'))
      }
    }
  }, []);

  useEffect(() => {
    if (!getCookie('token')) {
      setToken(null);
    }
  }, [getCookie('token')]);

  if (!token) {
    return <Redirect to="/auth" />;
  }

  // If any error, It will return Something went wrong error Page;
  if (isSomeThingWentWrong) {
    SomethingWentWrong();
  } else {
    setIsSomethingWentWrong(false);
  }

  // if network is disconnected then it will return Network Error Page.
  if (!status) {
    return <InternetConnection />;
  }

  return (
    <PermissionContext.Provider value={permissions}>
      <RoutesDetails.Provider value={routesDetails}>
        <UserDetails.Provider value={useDetails}>
          <UserFavouritesContextProvider>
            <>
              {routesDetails && (
                <Sidebar
                  {...props}
                  routes={routesDetails}
                  toggleSidenav={toggleSidenav}
                  sidenavOpen={sidenavOpen}
                  toggleMouseOver={toggleMouseOver}
                  mouseOverVerticalNavBar={mouseOverVerticalNavBar}
                  setMouseOverVerticalNavBar={setMouseOverVerticalNavBar}
                  mouseEventAllowed={mouseEventAllowed}
                  logo={{
                    innerLink: '/',
                    imgSrc: require('assets/img/brand/argon-react.png'),
                    imgAlt: '...',
                  }}
                  searchTxt={searchTxt}
                  accessRotues={permissions}
                  // mouseEventAllowed={mouseEventAllowed}
                />
              )}
              <div className="main-content">
                <ToastContainer position="bottom-center" />
                <div className={'main_header'}>
                  <AdminNavbar
                    search={true}
                    {...props}
                    theme={getNavbarTheme()}
                    toggleSidenav={toggleSidenav}
                    sidenavOpen={sidenavOpen}
                    brandText={'hello'}
                    searchTxt={searchTxt}
                    setSearchTxt={e => setSearchTxt(e.target.value)}
                    setHaveFocus={bool => setHaveFocus(bool)}
                    haveFocus={haveFocus}
                    hideNavbar={hideNavbar}
                    setMouseOverVerticalNavBar={setMouseOverVerticalNavBar}
                  />
                </div>
                {/* <AuthRoutes key="switchroutes" /> */}
                <div className={'main_content'}>
                  {routesDetails ? (
                    <CustomRoutes
                      getRoutes={() => getRoutes(routesDetails)}
                      routesDetails={routesDetails}
                      permissions={permissions}
                    />
                  ) : (
                    <PageLoadingAnimation />
                  )}
                  {/* <AdminFooter /> */}
                </div>
                {/* {getAccessToken() ? <AuthRoutes /> : getUserInfo()  ?<Redirect from="*" to="/sessionExpire" /> :<Redirect from="*" to="/" />} */}
                {/* <div className={'footer'}>
                  <AdminFooter />
                </div> */}
              </div>
              {sidenavOpen ? <div className="backdrop d-xl-none" onClick={toggleSidenav} /> : null}
            </>
          </UserFavouritesContextProvider>
        </UserDetails.Provider>
      </RoutesDetails.Provider>
    </PermissionContext.Provider>
  );
};

export default Admin;
