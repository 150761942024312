import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import PageLoadingAnimation from 'views/pages/manage/common/commonComponents/PageLoadingAnimation'
import { endpoint } from 'views/pages/manage/common/constant'
import { updateRequest } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod'
import { successToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function'
import { getBusinessAreas } from 'views/pages/manage/edpOps/constant/batch-search'
import { ReactComponent as LeftSideImg } from '../../../../../../assets/img/svg/academic-bg.svg'
import { createDaysPlan, createInitialForm, dropdownMapping } from '../common'
import styles from '../styles.module.scss'
import ViewEditDaysPlan from '../viewEditDaysPlan/ViewEditDaysPlan'
import CreateForm from './CreateForm'
import { PermissionContext } from 'appContext'
import { RolePermissions } from 'appContext'
import { pages } from 'views/pages/manage/common/constant'
import { PermisionDenied } from 'views/pages/manage/common/commonComponents'

const CreateDaysPlan = () => {

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages['daysPlan']['id']);

  const history = useHistory();
  const { action } = useParams();

  // CREATE and LIST
  const [openForm, setOpenForm] = useState('CREATE');
  const [apiLoader, setApiLoader] = useState(false);
  const [regionData, setRegionData] = useState([]);
  const [businessAreaData, setBusinessAreaData] = useState([]);

  const [formData, setFromData] = useState(createInitialForm);
  const formHandler = (value, key) => {
    setFromData(prev => ({ ...prev, [key]: value }));
  }

  const fetchBusinessAreas = async (regions) => {
    const res = await getBusinessAreas(regions);
    setBusinessAreaData(res);
  }

  const fetchData = async () => {
    setApiLoader(true);
    const regionRes = await dropdownMapping(endpoint?.region?.getAllActive, 'regionDispValue', 'id')
    // console.log(regionRes);
    setRegionData(regionRes);
    setApiLoader(false);
  }

  useEffect(() => {
    if (action === 'create') {
      fetchData();
    } else {
      history.replace('/admin/pageNotFound');
    }
  }, [action])

  const createPlanHandler = async () => {
    // console.log(updateRequest(formData))
    setApiLoader(true);
    const request = {
      ...formData,
      regions: formData?.regions?.map(item => ({ regionId: item?.value })),
      businessAreas: formData?.businessAreas?.map(item => ({ businessAreaId: item?.value })),
      plans: formData?.plans?.map(item => ({ ...item, id: null, startTime: item?.startTime, endTime: item?.endTime }))
    }
    const res = await createDaysPlan(request)
    setApiLoader(false);
    if (res?.code === 200) {
      successToast(res?.message);
      history.push(`/admin/master/daysPlan/view/${res?.data?.id}`)
    }
  }

  return (
    userPermissions ? (
      apiLoader ? (
        <PageLoadingAnimation />
      ) : (
        openForm === 'CREATE' ? (
          <div className={`remove-container-top-space ${styles?.create_days_plan_container}`} >
            <div className='mb-3 regular-bold color-light'>Masters / Days plan / Create days plan</div>
            <div className={`${styles?.create_img_form_container}`}>
              <LeftSideImg width={700} height={605} />
              <CreateForm
                formData={formData}
                formHandler={formHandler}
                regionData={regionData}
                businessAreaData={businessAreaData}
                fetchBusinessAreas={fetchBusinessAreas}
                setOpenForm={setOpenForm}
              />
            </div>
          </div>
        ) : (
          <ViewEditDaysPlan
            createFormData={formData}
            createFormHandler={formHandler}
            createPlanHandler={createPlanHandler}
            setCreateOpenForm={setOpenForm}
          />
        )
      )
    ) : (
      <PermisionDenied />
    )
  )
}

export default CreateDaysPlan