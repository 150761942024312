import React from 'react'
import './report-styles.scss';
import {ReactComponent as Folder} from '../../../../assets/img/svg/Folder.svg'
const EmptyReportPlaceholder = () => {
  return (
    <div className='empty-report-placeholder'>
      <div className='folder-icon-wrapper'>
        <Folder/>
      </div>
      <span >No Reports found</span>
    </div>
  )
}

export default EmptyReportPlaceholder