import React, { useEffect, useState } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import { ReactComponent as Sort } from '../../../../../assets/img/svg/sort.svg';
import { ReactComponent as SortDesc } from '../../../../../assets/img/svg/sortDesc.svg';
import './a-datagrid-style.scss';
import { BiSortAlt2 } from 'react-icons/bi';
import { getUniqueKey } from '../utils/methods/commonMethods/utilityMethod';
const ADataGrid = props => {
  const GRID_PAGE_SIZE = 10;
  const [sortModel, setSortModel] = React.useState([]);

  const {
    rows,
    columns,
    rowId,
    checkboxSelection,
    columnFilter,
    columnMenu,
    loading,
    onRowClick,
    rowCount,
    nextButtonHandler = () => {},
    currentPage,
    height,
    emptyRowPlaceholder,
    sortColumnName,
    serverPagination = true,
    // dataGridProps = {},
    rowHeight = 50,
    sx = {},
    editMode = 'row',
    pageSize = 50,
    paginationModel,
    onPaginationModelChange,
    removeWrapperContainer = false,
    hidePagination = false,
    pinLastColumn = false, // for this to work please add .pinColumnLast(true) as well in AGridColDef last element
    maxHeight,
    ...restProps
  } = props;

  const onPageChange = pageNumber => {
    nextButtonHandler(pageNumber);
  };
  const [uniqueClassName] = useState(`class-${getUniqueKey()}`);
  const headerHeight = restProps?.headerHeight;

  useEffect(() => {
    if (sortColumnName)
      setSortModel([
        {
          field: sortColumnName,
          sort: 'desc',
        },
      ]);
  }, [sortColumnName]);

  function handleRowEditStart(params, event) {
    event.defaultMuiPrevented = true;
  }

  function handleRowEditStop(params, event) {
    event.defaultMuiPrevented = true;
  }
  useEffect(() => {
    const findVirtualScroller = () => {
      const virtualScrollerElement = document.querySelector(
        `.${uniqueClassName} .MuiDataGrid-virtualScroller`
      );
      if (!virtualScrollerElement) {
        setTimeout(findVirtualScroller, 100);
      } else {
        virtualScrollerElement.addEventListener(
          'scroll',
          handleScrollHorizontal
        );
        return () => {
          virtualScrollerElement.removeEventListener(
            'scroll',
            handleScrollHorizontal
          );
        };
      }
    };
    pinLastColumn && findVirtualScroller();
  }, []);

  const handleScrollHorizontal = () => {
    const currentScrollPos = document.querySelector(
      `.${uniqueClassName} .MuiDataGrid-virtualScroller`
    ).scrollLeft;
    const columnsHeaders = document.querySelectorAll(
      `.${uniqueClassName} .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader.pin-last-column)`
    );
    const columnHeaderInner = document.querySelector(`.${uniqueClassName} .MuiDataGrid-columnHeadersInner`)
    columnHeaderInner.style.transform = 'none'
    columnsHeaders.forEach(columnHeader => {
      columnHeader.style.transform = `translate3d(-${currentScrollPos}px, 0px, 0px)`;
    });
  };

  const getDataGrid = () => {
    return (
      <DataGrid
        className={`${uniqueClassName} ${restProps?.className || ''}`}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[50]}
        getRowId={rowId}
        checkboxSelection={checkboxSelection ? checkboxSelection : false}
        density={'compact'}
        disableColumnFilter={columnFilter ? columnFilter : true}
        disableColumnMenu={columnMenu ? columnMenu : true}
        getRowHeight={params => rowHeight || 44}
        onRowClick={(params, e) => {
          e.stopPropagation();
          return onRowClick ? onRowClick(params.row) : () => {};
        }}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        onPageChange={onPageChange}
        isRowSelectable={params => (checkboxSelection ? true : false)}
        pagination
        rowCount={rowCount}
        paginationMode={serverPagination ? 'server' : 'client'}
        page={currentPage}
        components={{
          // ColumnUnsortedIcon: () => (<BiSortAlt2 />),
          ColumnSortedDescendingIcon: SortDesc,
          ColumnSortedAscendingIcon: Sort,
          NoRowsOverlay: emptyRowPlaceholder
            ? emptyRowPlaceholder
            : () => (
                <div className="d-flex justify-content-center align-items-center h-100">
                  No rows
                </div>
              ),
        }}
        sortModel={sortModel}
        onSortModelChange={newSortModel => setSortModel(newSortModel)}
        sx={{
          color: '#262626',
          fontFamily: 'inherit',
          '.MuiDataGrid-cell:focus-within, .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-columnHeader:focus': {
            outline: 'none',
           },
          '.MuiDataGrid-cell--editing:focus-within, .MuiDataGrid-cell--editing:focus, .MuiDataGrid-cell--editing:focus-visible':
            {
              outline: 'none !important',
            },
          '.MuiDataGrid-row--editing': {
            boxShadow: 'none',
          },
          '.MuiDataGrid-footerContainer': {
            display: hidePagination && 'none',
          },
          '.MuiDataGrid-main': {
            height: props?.autoHeight && 'unset',
          },
          ...(maxHeight ? {
            '.MuiDataGrid-main': {
              height: `${(headerHeight || 60) + rows?.length * rowHeight}px`,
              minHeight: `${(headerHeight || 60) + rowHeight}px`,
              maxHeight: `${maxHeight}`,
            },
          } : {}),
          ...(pinLastColumn
            ? {
                '& .MuiDataGrid-cell.pin-last-column': {
                  position: 'sticky',
                  top: '0',
                  right: '0',
                  paddingRight: '1.5rem',
                  zIndex: 1,
                  backgroundColor: '#FFF',
                  // backgroundColor: 'rgba(256, 256, 256, 0.9)',
                  boxShadow: '-20px 0px 20px -20px rgba(0,0,0,0.45)'
                },
                '& .MuiDataGrid-columnHeader.pin-last-column': {
                  position: 'sticky',
                  top: '0',
                  right: '0',
                  paddingRight: '1.5rem',
                  // border: 'none',
                  zIndex: 1,
                  backgroundColor: '#FFF',
                  boxShadow: '-20px 0px 20px -20px rgba(0,0,0,0.45)',
                },
                '& .MuiDataGrid-row:not(.MuiDataGrid-row--editing):hover .MuiDataGrid-cell.pin-last-column': {
                  backgroundColor: '#F5F5F5',
                },
                '& .MuiDataGrid-columnHeadersInner': {
                  transform: 'none !important',
                },
              }
            : {}),
          ...sx,
        }}
        columnBuffer={columns.length+2}
        editMode={editMode}
        onCellFocusOut={(params, e) => (e.defaultMuiPrevented = true)}
        onRowDoubleClick={(params, e) => (e.defaultMuiPrevented = true)}
        onCellDoubleClick={(params, e) => (e.defaultMuiPrevented = true)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        experimentalFeatures={{ newEditingApi: true }}
        {...restProps}
      />
    );
  };

  const minHeight = height || 370;
  return removeWrapperContainer ? (
    getDataGrid()
  ) : (
    <div
      className="mt-3"
      style={{
        minHeight: minHeight,
        flexGrow: 1,
        display: 'flex',
        flexFlow: 'column',
      }}
    >
      {getDataGrid()}
    </div>
  );
};

export default ADataGrid;
