import React, {useEffect, useState} from "react";
import {Button, Card, Table} from "reactstrap";
import {MasterHeaderAuto} from "../../common/commonComponents/masterHeader/masterHeader";
import {fetchDetails, getDispValForReactSelectByOneID} from "../../common/utils/methods/commonMethods/utilityMethod";
import {masterServiceBaseUrl} from "../../../../../services/http";
const ExamIdTDROW = (props) => {
    const {item, index,academicCareerData,examType} = props;
    useEffect(() => {
        // console.log('Academic career data------>',academicCareerData)
    },[academicCareerData])
    return (
        <tr>
            <td className="text-center p-2 mx-1">{item.examId ? item.examId : 'Not Found'}</td>
            <td className="text-center p-2 mx-1">{item.examIdDescription ? item.examIdDescription : 'Not Found'}</td>
            <td className="text-center p-2 mx-1">{item.examTypeId ? getDispValForReactSelectByOneID(item.examTypeId ,'DispValue',examType) : 'Not Found'}</td>
            <td className="text-center p-2 mx-1">{item.academicCareerId ? getDispValForReactSelectByOneID(item.academicCareerId ,'DispValue',academicCareerData): 'Not Found'}</td>
            <td className="text-center p-2 mx-1">
                <Button color="info" size="sm" type="button" className={' mx-1'}
                        onClick={() => window.open(`${document.URL}/view/${item.id}`, '_blank')}>
                    <i className="fas fa-eye"/>
                </Button>
                <Button color="info" size="sm" type="button" className="text-center  mx-1"
                        onClick={() => window.open(`${document.URL}/edit/${item.id}`, '_blank')}>
                    <i className="fas fa-edit"/>
                </Button>
            </td>
        </tr>
    )
}
const ExamIdTable = (props) => {
    const {examIdData,examType,searchHeaderList} = props;
    const [academicCareerData,setAcademicCareerData] = useState([])
    // const headerList = [{name: "EXAM ID"}, {name: "EXAM ID DESCRIPTION"}, {name: "EXAM TYPE"}, {name: "ACADEMIC CAREER"}, {name: "ACTION"}]
    useEffect(() => {
       fetchDetails(masterServiceBaseUrl + '/academicCareer/getAllAcademicCareer',setAcademicCareerData,'academicCareer','reactSelect')
    },[])
    useEffect(() => {
        console.log('Data------>',academicCareerData);
    },[academicCareerData]);
    return (
        <Card>
            <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush">
                    <MasterHeaderAuto headerList={searchHeaderList}/>
                    <tbody className="list" key={'courseCatalogSequenceSearch'}>
                        {
                            examIdData && examIdData.length > 0 && examIdData.map( (item, index) =>
                                <ExamIdTDROW index={index} item={item} examType={examType} academicCareerData={academicCareerData}/>
                            )
                        }
                    </tbody>
                </Table>
            </div>
        </Card>
    )
}
export default ExamIdTable;