import { PermissionContext, RolePermissions } from "appContext";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { getAPI, masterServiceBaseUrl } from 'services/http';
import { PermisionDenied } from "../../common/commonComponents";
import CustomLoader from "../../common/commonComponents/Loader/loader";
import { pages } from "../../common/constant";
import { GetPagination } from "../../common/commonComponents/pagination/pagination";
import CountryTable from './countryTable';
import { failureToast, successToast } from "../../common/utils/methods/toasterFunctions/function";

const Country = () => {
  const [classArray, setclassArray] = useState([])
  const [apiloader, setapiloader] = useState(false)
  const [state, setstate] = useState([]);
  const [tableData, setTableData] = useState([])
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null
  });

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["Country"]['id']);

  const pageSize = 10
  const fetchClasses = async (url) => {
    setapiloader(true)
    getAPI(url, (data) => {
      setstate(data['data'])
      setTableData(data['data']);
      let n = pagination
      if (data['data'].length > pageSize) {
        n['nextPage'] = n.currentPage + 1
      }
      if (n.currentPage > 1) {
        n.previousPage = n.currentPage - 1
      }
      n['totalPage'] = Math.floor(data['data'].length / pageSize)
      if (data['data'].length % pageSize != 0) {
        n['totalPage'] += 1
      }
      setpagination(n)
      setapiloader(false)
      setclassArray(data['data'].slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || [])
    }, (data) => { failureToast(data['message']) })
  }
  
  useEffect(() => {
    fetchClasses(masterServiceBaseUrl + '/country/getAllCountry')
  }, [])

  const refreshList = () => {
    fetchClasses(masterServiceBaseUrl + '/country/getAllCountry')
  }

  const previousPage = () => {
    if (pagination.currentPage != 1) {
      setclassArray(
        state.slice(
          (pagination.currentPage - 1) * pageSize - pageSize,
          (pagination.currentPage - 1) * pageSize
        )
      );
      // console.log(
      //   state.slice(
      //     (pagination.currentPage - 1) * pageSize - pageSize,
      //     (pagination.currentPage - 1) * pageSize
      //   )
      // );
      let n = pagination;
      n["currentPage"] = n["currentPage"] - 1;
      if (n["currentPage"] == 1) {
        n["previousPage"] = null;
      } else {
        n["previousPage"] = n.currentPage;
      }
      setpagination(n);
    }
  };

  return (
    <>
      {apiloader ?
        <CustomLoader
          apiLoader={apiloader}
          loaderHeight={'200px'}
          loaderWidth={'100% '}
        /> :
        <Fragment>
          {userPermissions ? <>
            <CountryTable
              userPermissions={userPermissions}
              fetchClasses={fetchClasses}
              setclassArray={setclassArray}
              classArray={classArray}
              setstate={setstate}
              state={state}
              tableData={tableData}
              pagination={pagination}
              setpagination={setpagination}
              isSaveVisible={isSaveVisible}
              setisSaveVisible={setisSaveVisible}
              refreshList={refreshList}
              previousPage={previousPage}
            />
            {pagination.totalPage > 1 ?
              <GetPagination
                isSaveVisible={isSaveVisible}
                fetchClasses={fetchClasses}
                setclassArray={setclassArray}
                pagination={pagination}
                setpagination={setpagination}
                pageSize={pageSize}
                state={state}
              />
              : null}
          </>
            : <PermisionDenied />}
        </Fragment>
      }
    </>
  );
}
export default Country;