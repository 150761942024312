import { ClickAwayListener, Tooltip } from '@mui/material';
import React, { useState } from 'react';

const themeColorMap = {
  light: {
    backgroundColor: '#FFF',
    color: '#262626',
    boxShadow: theme => theme.shadows[3],
  },
  dark: {},
};

const CustomTooltip = ({
  theme = 'light',
  showOnClick = false,
  children,
  title,
  maxWidth='none',
  ...restProps
}) => {
  const [open, setOpen] = useState(false);
  const handleTooltipClose = () => setOpen(false);
  const handleTooltipOpen = e => {
    e.stopPropagation();
    setOpen(true);
  };

  return (
    <Tooltip
      {...(showOnClick
        ? {
            onClose: handleTooltipClose,
            open: open,
            disableFocusListener: true,
            disableHoverListener: true,
            disableTouchListener: true,
          }
        : {})}
      componentsProps={{
        tooltip: {
          sx: {
            ...themeColorMap[theme],
            maxWidth: maxWidth,
            fontSize: '12px',
          },
        },
      }}
      title={
        title && (
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              {typeof title === 'function' ? title(handleTooltipClose) : title}
            </div>
          </ClickAwayListener>
        )
      }
      {...restProps}
    >
      {React.cloneElement(children, {
        onClick: handleTooltipOpen,
      })}
    </Tooltip>
  );
};

export default CustomTooltip;
