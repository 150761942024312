import { useEffect, useState } from "react";
import { getCoursesByPackageId } from "../academicTest/style";

// adding some helper function here

export const getSubjectsDifference = (oldSubjects, newSubjects) => {
  /* total cases 3 
      1 => subjects are greater then old 
      2 => subjects are less then old 
    */
  if (oldSubjects.length === newSubjects.length) {
    return { subjects: [] };
  }
  let subjects = [];
  let isExtra = false;
  
  if (oldSubjects.length > newSubjects.length) {
    // find not exit subject

    oldSubjects.forEach((oldSubject) => {
      let isAdd = true ;

      newSubjects.forEach((newSubject) => {

        if (oldSubject["subjectName"].toLowerCase() === newSubject["subjectName"].toLowerCase()) {
           isAdd = false ;

        }
      });
      if(isAdd)
       subjects.push(oldSubject);

    });
  } else {
    

    //find extra subject
    newSubjects.forEach((newSubject) => {
         let isAdd = true ;
      oldSubjects.forEach((oldSubject) => {
        if (oldSubject["subjectName"].toLowerCase() === newSubject["subjectName"].toLowerCase()) {
          isAdd = false ;

       }
      });
       if(isAdd)
        subjects.push(newSubject);

    });
    isExtra = true;

  }

  return { isExtra, subjects };
};

export function useFetchCoursesByPackageId(packageId) {
  // const [courses, setCourses] = useState([]);
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    if (packageId)
      getCoursesByPackageId(packageId).then((res) => {
        if (res?.data) {
        
          let courses = res.data?.packages[0]?.courses;

          setSubjects(courses);
        }
      });
  }, [packageId]);

  return subjects;
}
