import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import style from './model.module.scss';
import ClipboardIcon from 'react-clipboard-icon';
import { data } from '../data';
const styles = { fill: 'grey' };

const CloseModel = ({
	content = 'Are you sure you want to leave this page?',
	closeHandler = () => {},
	modelTitle,
	isCloseModelOpen = false,
	isSomeThingEdit = false,
}) => {
	return (
		<div>
			<Modal
				isOpen={isCloseModelOpen}
				className="custom_model"
				centered
				backdrop={'static'}
			>
				{/*{modelTitle ? <ModalHeader className={style.model_title}>{modelTitle}</ModalHeader>:null}*/}
				<ModalBody className={style.model_body}>
					<span style={{ marginRight: '10px' }}>
						{isSomeThingEdit
							? data?.messages?.unsavedChanges
							: data?.messages?.closeMessage}
					</span>
				</ModalBody>
				<ModalFooter className={style.model_footer}>
					{isSomeThingEdit ? (
						<Button
							color="info"
							onClick={() => closeHandler('3', true)}
							style={{ width: '50px' }}
							size="sm"
						>
							Yes
						</Button>
					) : (
						<Button
							color="info"
							onClick={() => closeHandler('2', true)}
							style={{ width: '50px' }}
							size="sm"
						>
							Yes
						</Button>
					)}
					<Button
						color="info"
						onClick={() => closeHandler('2', false)}
						style={{ width: '50px' }}
						size="sm"
					>
						No
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default CloseModel;
