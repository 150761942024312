import { endpoint } from "../../../common/constant"
import { fetchAllPostPromisedData } from "../../../common/utils/methods/commonMethods/utilityMethod"
import { successToast } from "../../../common/utils/methods/toasterFunctions/function";

export const requestSupplementaryId = async (applicationDetails) => {
  const {
    studentName,
    psid,
    applicationId,
    businessAreaDispValue: businessAreaValue,
    courseId,
    programActions: programAction,
    classes,
  } = applicationDetails || {};
  const reqBody = {
    supplementaryRequestDto: [
      {
        studentName,
        psid,
        applicationId,
        businessAreaValue,
        courseId,
        programAction,
        classes,
      },
    ],
  };
  const res = await fetchAllPostPromisedData(endpoint.studentSupplementary.requestSupplementaryId, reqBody, 'post');
  if(res?.code===200) {
    successToast(res?.message);
  }

  return res;
}