import React from "react";
import styles from "./styles.module.scss";

export const AcademicCareerTabs = ({ activeTab, setActiveTab = () => {} }) => {
  return (
    <div className={`${styles["academic-career-tabs"]}`}>
      <div
        className={`large-semi-bold ${
          activeTab === "RCC" ? styles["active"] : ""
        }`}
        onClick={() => setActiveTab("RCC")}
      >
        RCC
      </div>
      <div
        className={`large-semi-bold ${
          activeTab === "DLP" ? styles["active"] : ""
        }`}
        onClick={() => setActiveTab("DLP")}
      >
        DLP
      </div>
      <div
        className={`large-semi-bold ${
          activeTab === "DIGITAL" ? styles["active"] : ""
        }`}
        onClick={() => setActiveTab("DIGITAL")}
      >
        DIGITAL
      </div>
    </div>
  );
};

const StockDetailsTabs = ({ activeTab, setActiveTab }) => {
  return (
    <div className={`${styles["stock-details-tabs"]}`}>
      <div
        className={`large-semi-bold ${
          activeTab === "issuance" ? styles["active"] : ""
        }`}
        onClick={() => setActiveTab("issuance")}
      >
        Issuance
      </div>
      <div
        className={`large-semi-bold ${
          activeTab === "return" ? styles["active"] : ""
        }`}
        onClick={() => setActiveTab("return")}
      >
        Return
      </div>
    </div>
  );
};

export default StockDetailsTabs;
