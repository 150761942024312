import { pdf } from '@react-pdf/renderer';
import moment from 'moment';
import React from 'react'
import { endpoint } from 'views/pages/manage/common/constant';
import { documentServiceBaseUrl, putpost, uploadpost } from '../../../../../../services/http';
import { fetchAllPostPromisedData, fetchAllPromisedData, getCookie } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { failureToast, successToast } from '../../../common/utils/methods/toasterFunctions/function';
// import CSummary from './templates/CourseSummary';
// import DraftSummary from './templates/draftSummary';
// import FeeDetails from './templates/FeeDetails';
// import PSummary from './templates/Psummary';

// import MyDocument from './templates/wrapper';


export const getBusinessAreaQR = async (id) => {
    return new Promise(async (resolve) => {
        try {
            const data = await fetchAllPostPromisedData(`${endpoint.qrCode.searchQr}?offset=0`, { businessAreaId: [id] });

            const activeQr = data?.data?.qrCodeResponseDtoList?.filter(i => i.status === 'ACTIVE');

            if (activeQr?.[0]?.qrCode) {
                const downloadUrlData = await fetchAllPostPromisedData(`${endpoint.fetchS3Url.getDownloadUrl}?key=${data?.data?.qrCodeResponseDtoList?.[0]?.qrCode}`, {});
                resolve(downloadUrlData?.data?.url ?? '');
            } else {
                resolve('');
            }
        } catch (error) {
            console.error(error);
            resolve('');
        }
    });
};
// businessArea/getBusinessAreaContactDetailsLocation

export const getBusinessAreaAddress = async (id) => {
    return new Promise(async (resolve) => {
        try {
            const data = await fetchAllPromisedData(`${endpoint.businessArea.getBusinessAreaContactDetailsLocation}/${id}`);
            const address = data?.locationDetailsDto ? `${data?.locationDetailsDto?.buildingName}, ${data?.locationDetailsDto?.address1} ${data?.locationDetailsDto?.address2} ${data?.locationDetailsDto?.city}, ${data?.locationDetailsDto?.district} (${data?.locationDetailsDto?.pincode}),  ${data?.locationDetailsDto?.state}, ${data?.locationDetailsDto?.country} ` : '';
            resolve(address);

        } catch (error) {
            console.error(error);
            resolve('');
        }
    });
};

export const getStudentAddress = async (id) => {
    return new Promise(async (resolve) => {
        try {
            const data = await fetchAllPromisedData(`${endpoint.studentDetails.getStudentContactDetails}/${id}`);
            const activeAddress = data?.studentAddressDetailDTOList ? data?.studentAddressDetailDTOList?.find(i => i.status === 'ACTIVE') ?? null : null
            const address = activeAddress ? `${activeAddress.addressLine1}, ${activeAddress.addressLine2} ${activeAddress.city} ${activeAddress.state}, ${activeAddress.country} (${activeAddress.postalCode})` : '';
            const activePhone = data?.studentPhoneDetailDTOList ? data?.studentPhoneDetailDTOList?.find(i => i.isPreferred === false) ?? null : null;
            const phone = activePhone ? activePhone?.contactValue : ''
            resolve({ address, phone });

        } catch (error) {
            console.error(error);
            resolve({ address: '', phone: '' });
        }
    });
};

const checkAndCreateDepositSlip = async (name, file, status, key, applicationQuotationId, scb, fcb, feeBreakup, otherChargesMessage, sendEmailNotification, getEligibleComponentList, getFileUploadUrls, templateName) => {

    if (status === 'APPL') {
        const { depositeData: eligibleComponents, dateAndItemMapping } = await getEligibleComponentList(applicationQuotationId);

        if (eligibleComponents?.length) {
            const qrCode = await getBusinessAreaQR(eligibleComponents?.[0]?.businessAreasId);
            const fileNameArr = eligibleComponents.map(i => i.name.split(' ').join('_') + '.pdf');
            await getFileUploadUrls(fileNameArr, eligibleComponents, dateAndItemMapping, qrCode, false, applicationQuotationId, true).then(d => {

                const refArr = d?.itemAndS3KeyDtoList ? [...d?.itemAndS3KeyDtoList] : [];
                const installment = { ...file.installments }

                refArr.forEach(item1 => {
                    const { itemType = '', depositSlipReferenceNumber = '' } = item1 || {};
                    for (const key in installment)
                        installment[key].forEach((item2, index) => { if (item2.itemType === itemType) installment[key][index].slipReference = depositSlipReferenceNumber; });
                });
                getFilePath(name, { ...file, installment: installment }, status, key, applicationQuotationId, scb, fcb, feeBreakup, otherChargesMessage, sendEmailNotification, getEligibleComponentList, getFileUploadUrls, templateName)
            }).catch(e => {
                console.log(e)
                getFilePath(name, file, status, key, applicationQuotationId, scb, fcb, feeBreakup, otherChargesMessage, sendEmailNotification, getEligibleComponentList, getFileUploadUrls, templateName)
            });
        } else {
            getFilePath(name, file, status, key, applicationQuotationId, scb, fcb, feeBreakup, otherChargesMessage, sendEmailNotification, getEligibleComponentList, getFileUploadUrls, templateName)
        }

    }

}

const getFilePath = (name, file, status, key, applicationQuotationId, scb, fcb, feeBreakup, otherChargesMessage, sendEmailNotification, getEligibleComponentList, getFileUploadUrls, templateName) => {
    const formData = {
        bulk: false,
        fileName: name,
        docType: 'pdf',
        sourceId: 1,
        keyValue: new Date(),
        serviceName: "ENROLLMENT_CONCESSION_UPLOAD",
    };

    putpost(
        `${documentServiceBaseUrl}/documentManagement/getUploadUrl`,
        (data) => {
            if (data.code === 200 && data.data) {
                return saveDataToStorage(data.data, file, name, status, key, applicationQuotationId, scb, fcb, feeBreakup, otherChargesMessage, sendEmailNotification, getEligibleComponentList, getFileUploadUrls, templateName);
            }
            // submitOnAws()
            return null
        },
        (data) => {
            failureToast(data["message"]);
            return null
        },
        formData,
        "post"
    );
};

const saveDataToStorage = async (res, file, name, status, key, applicationQuotationId, scb, fcb, feeBreakup, otherChargesMessage, sendEmailNotification, getEligibleComponentList, getFileUploadUrls, templateName) => {

    // const formData = new FormData();
    // formData.append("File", file);
    // formData.append("destinationfolder", res.bucket);
    const formData = {
        templateName: templateName,
        templateData: { ...file, name: name?.split('.')?.[0] },
        uploadUrl: res.storage,

    }

    await fetchAllPostPromisedData(endpoint.createPdf, formData)
    updateStatus(res.key, status, key, applicationQuotationId, scb, fcb, feeBreakup, otherChargesMessage, sendEmailNotification)

};

const updateStatus = async (s3key, status, key, applicationQuotationId, scb, fcb, feeBreakup, otherChargesMessage, sendEmailNotification) => {
    let request = {
        applicationQuotationId: applicationQuotationId,
        "chargeStatus": status,
        "draftSummaryKey": null,
        "provisionalSummaryKey": null,
        "courseFeeSummaryKey": null,
        "sendEmailNotification": sendEmailNotification || false
    }
    request = { ...request, [key]: s3key }
    const response = await fetchAllPostPromisedData(`${endpoint.updateStudentChargeSummary}`, request, 'put');
    if (response?.code === 200) {
        successToast(response?.message)
        if (scb) {
            scb({ otherChargesMessage: otherChargesMessage });
            fcb();
        }
    } else {
        if (fcb) {
            fcb();
        }
        // failureToast(response?.message ?? 'Not able to process request , please try after sometime.')
    }
}

export const updateQuestionStatus = async (status, draftSummaryObject, courseSummaryObject, provisionalObject, feeBreakup, captureRef, applicationQuotationId, scb, fcb, courseData, sendEmailNotification, getEligibleComponentList, getFileUploadUrls) => {
    // setUpdatingStatus({ id: params.row.id, status: status });
    try {

        const request = {
            "chargeStatus": status,
            "studentChargesFeeBreakupRequestDto": feeBreakup
        }

        if (status == "VERY") {
            try {

                const response = await fetchAllPostPromisedData(`${endpoint.updateStudentQuotationStatus}?applicationQuotationId=${+applicationQuotationId}`, request, 'post');
                const qrCode = await getBusinessAreaQR(draftSummaryObject?.buId)
                const buAddress = await getBusinessAreaAddress(draftSummaryObject?.buId)
                const studentAddress = await getStudentAddress(draftSummaryObject?.studentId);

                if (response?.code === 200) {
                    getFilePath('darftSummary.pdf', { ...draftSummaryObject, qrCode: qrCode, buAddress: buAddress, studentAddress: studentAddress?.address }, status, 'draftSummaryKey', response?.data?.applicationQuotationId, scb, fcb, feeBreakup, sendEmailNotification, null, null, null, 'draftSummary');
                } else {
                    fcb();
                }
            } catch (e) {
                fcb();
                console.log(e)
            }
        } else if (status == "APPL") {
            try {
                const response = await fetchAllPostPromisedData(`${endpoint.updateStudentQuotationStatus}?applicationQuotationId=${+applicationQuotationId}`, request, 'post');
                const res = await fetchAllPromisedData(endpoint.course_details.courseAttribute);
                const qrCode = await getBusinessAreaQR(courseSummaryObject?.buId);
                const buAddress = await getBusinessAreaAddress(courseSummaryObject?.buId);
                const studentAddress = await getStudentAddress(courseSummaryObject?.studentId);

                if (response?.code === 200) {
                    let courseName = ''
                    if (response?.data?.otherChargesDetailsDTOS?.[0]?.courseId) {
                        courseName = await fetchAllPromisedData(`${endpoint.course_details.getByCourseId}/${response?.data?.otherChargesDetailsDTOS?.[0].courseId}`);
                    }
                    console.log("courseName", courseName)
                    const securityObject = response.data?.otherChargesDetailsDTOS.map(i => {
                        return {
                            courseName: courseName?.[0]?.courseName ?? '',
                            courseId: i.courseId,
                            installmentObj: {
                                installmentName: Array.isArray(res) && res?.find(item => item.id === i.chargeTypeId)?.courseAttributeValueDispValue,
                                dueDate: moment().format('DD-MMM-YYYY'),
                                slipReference: i?.id,
                                mrp: i.basePrice?.toFixed(2),
                                // concessionPer: '0.00',
                                // lumpSumPercent: '0.00',
                                concessionAmount: i.approvalStatus === 'APPROVED' && i.waveOffAmount ? i.waveOffAmount : '0.00',
                                totalTax: i.taxes,
                                netPayable: i.approvalStatus === 'APPROVED' && i.waveOffAmount ? i.finalPayable : i.netPayableAmount,
                                // paidAmount: '0.00'
                            },
                        }
                    })

                    // const securityInstallments = response.data?.otherChargesDetailsDTOS.map(i => {
                    //     return {
                    //         dueDate: '',
                    //         mrpExcTax: null,
                    //         mrpIncTax: i.approvalStatus === 'APPROVED' && i.waveOffAmount ? i.finalPayable : i.netPayableAmount,
                    //         concession: null,
                    //         netpaybleExcTax: null,
                    //         netpaybleIncTax: i.approvalStatus === 'APPROVED' && i.waveOffAmount ? i.finalPayable : i.netPayableAmount,
                    //         concessionPendingApp: null,
                    //         paidAmount: null,
                    //         payablePostApp: null,
                    //         installmentName: Array.isArray(res) && res?.find(item => item.id === i.chargeTypeId)?.courseAttributeValueDispValue,
                    //     }
                    // })

                    const newInstallments = { ...courseSummaryObject?.installments };
                    // newInstallments[Object.keys(newInstallments)?.[0]] = [...securityInstallments, ...newInstallments[Object.keys(newInstallments)?.[0]]]
                    let total = 0;
                    response.data.otherChargesDetailsDTOS.map(i => {
                        total = Number(total) + Number(i.approvalStatus === 'APPROVED' && i.waveOffAmount ? i.finalPayable : i.netPayableAmount)
                    })

                    const newTotal = { ...courseSummaryObject?.installmentsTotals };

                    newTotal[Object.keys(newInstallments)?.[0]] = {
                        ...newTotal[Object.keys(newInstallments)?.[0]],
                        totalMrpIncTax: newTotal[Object.keys(newInstallments)?.[0]]?.totalMrpIncTax + total,
                        totalNetpaybleIncTax: newTotal[Object.keys(newInstallments)?.[0]]?.totalNetpaybleIncTax + total
                    }

                    const newGrandTotal = { ...courseSummaryObject?.grandTotal };
                    newGrandTotal['totalMrpIncTax'] = newGrandTotal['totalMrpIncTax'] + total;
                    newGrandTotal['totalNetpaybleIncTax'] = newGrandTotal['totalNetpaybleIncTax'] + total;

                    checkAndCreateDepositSlip('courseSummary.pdf', { ...courseSummaryObject, studentAddress: studentAddress?.address, installments: newInstallments, installmentsTotals: newTotal, otherCharges: securityObject, grandTotal: newGrandTotal, qrCode: qrCode, buAddress: buAddress }, status, 'courseFeeSummaryKey', response?.data?.applicationQuotationId, scb, fcb, feeBreakup, response.data?.otherChargesMessage, sendEmailNotification, getEligibleComponentList, getFileUploadUrls, 'courseSummary');
                }
            } catch (e) {
                console.log(e)
            }
        } else if (status == "PAPP") {
            try {

                const response = await fetchAllPostPromisedData(`${endpoint.updateStudentQuotationStatus}?applicationQuotationId=${+applicationQuotationId}`, request, 'post');
                const res = await fetchAllPromisedData(endpoint.course_details.courseAttribute);
                const qrCode = await getBusinessAreaQR(provisionalObject?.buId);
                const buAddress = await getBusinessAreaAddress(provisionalObject?.buId);
                const studentAddress = await getStudentAddress(provisionalObject?.studentId);

                if (response?.code === 200) {
                    let courseName = ''
                    if (response?.data?.otherChargesDetailsDTOS?.[0]?.courseId) {
                        courseName = await fetchAllPromisedData(`${endpoint.course_details.getByCourseId}/${response?.data?.otherChargesDetailsDTOS?.[0].courseId}`);
                    }
                    const securityObject = response.data?.otherChargesDetailsDTOS.map(i => {
                        return {
                            courseName: courseName?.[0]?.courseName ?? '',
                            courseId: i.courseId,
                            installmentObj: {
                                installmentName: Array.isArray(res) && res?.find(item => item.id === i.chargeTypeId)?.courseAttributeValueDispValue,
                                dueDate: moment(new Date()).format('DD-MMM-YYYY'),
                                slipReference: '',
                                mrp: i.basePrice?.toFixed(2),
                                concessionAmount: i.approvalStatus === 'APPROVED' && i.waveOffAmount ? i.waveOffAmount : '0.00',
                                totalTax: i.taxes,
                                netPayable: i.approvalStatus === 'APPROVED' && i.waveOffAmount ? i.finalPayable : i.netPayableAmount,
                            },
                        }
                    })

                    // const securityInstallments = response.data?.otherChargesDetailsDTOS.map(i => {
                    //     return {
                    //         dueDate: '',
                    //         mrpExcTax: null,
                    //         mrpIncTax: i.approvalStatus === 'APPROVED' && i.waveOffAmount ? i.waveOffAmount : i.netPayableAmount,
                    //         concession: null,
                    //         netpaybleExcTax: null,
                    //         netpaybleIncTax: i.approvalStatus === 'APPROVED' && i.waveOffAmount ? i.finalPayable : i.netPayableAmount,
                    //         concessionPendingApp: null,
                    //         paidAmount: null,
                    //         payablePostApp: null,
                    //         installmentName: Array.isArray(res) && res?.find(item => item.id === i.chargeTypeId)?.courseAttributeValueDispValue,
                    //     }
                    // })

                    const newInstallments = { ...provisionalObject?.installments };
                    // newInstallments[Object.keys(newInstallments)?.[0]] = [...securityInstallments, ...newInstallments[Object.keys(newInstallments)?.[0]]]
                    let total = 0;
                    response.data.otherChargesDetailsDTOS.map(i => {
                        total = Number(total) + Number(i.approvalStatus === 'APPROVED' && i.waveOffAmount ? i.finalPayable : i.netPayableAmount)
                    })

                    const newTotal = { ...provisionalObject?.installmentsTotals };
                    newTotal[Object.keys(newInstallments)?.[0]] = {
                        ...newTotal[Object.keys(newInstallments)?.[0]],
                        totalMrpIncTax: newTotal[Object.keys(newInstallments)?.[0]]?.totalMrpIncTax + total,
                        totalNetpaybleIncTax: newTotal[Object.keys(newInstallments)?.[0]]?.totalNetpaybleIncTax + total
                    }

                    const newGrandTotal = { ...provisionalObject?.grandTotal };
                    newGrandTotal['totalMrpIncTax'] = newGrandTotal['totalMrpIncTax'] + total;
                    newGrandTotal['totalNetpaybleIncTax'] = newGrandTotal['totalNetpaybleIncTax'] + total;
                    getFilePath('provisionalSummary.pdf', { ...provisionalObject, studentAddress: studentAddress?.address, installments: newInstallments, installmentsTotals: newTotal, otherCharges: securityObject, grandTotal: newGrandTotal, qrCode: qrCode, buAddress: buAddress }, status, 'provisionalSummaryKey', response?.data?.applicationQuotationId, scb, fcb, feeBreakup, response.data?.otherChargesMessage, sendEmailNotification, getEligibleComponentList, getFileUploadUrls, 'provisionalSummary');
                }

            } catch (e) {
                console.log(e)
            }
        }

    } catch (error) {

    }
}


export const isPendingForApproval = (status) => {
    const isPending = ['L1_REJECT', 'L2_REJECT', 'L3_REJECT', 'L1_APPROVED', 'L2_APPROVED', 'L3_APPROVED'].includes(status) ? false : true;
    return isPending;
}


export const getCurrentYear = async (application) => {
    const { businessAreaId, courseId, termId } = application || {};
    const reqBody = {
        courseId,
        businessArea: businessAreaId,
        term: termId,
    };
    const res = await fetchAllPostPromisedData(endpoint?.course?.getCoursePricingDetails, reqBody);
    if (res?.data) {
        const data = res?.data;
        const today = new Date();
        const year = Object.keys(data)
            .filter(key => key.includes("StartDate"))
            .findIndex(startKey => {
                const endKey = startKey.replace("StartDate", "EndDate");
                const startDateStr = data[startKey];
                const endDateStr = data[endKey];

                if (startDateStr && endDateStr) {
                    const startDate = new Date(startDateStr);
                    const endDate = new Date(endDateStr);

                    return today >= startDate && today <= endDate;
                }
                return false;
            }) + 1; // Adding 1 because array index starts from 0

        // Output the result
        const result = year !== 0 ? year : null;
        return result
    }
    return null
}

export const transformChargeStatus = (chargeStatus) => {
    if (chargeStatus === 'MCONC') return 'CONC';
    else if (chargeStatus === 'MVERY') return 'VERY';
    return chargeStatus;
}


export const LOAN_APPLICATION_ERROR_MGS = "Charge finalization is not permitted because concessions are still in progress. Provisional finalization is not allowed for loan ( Payment Mode)-based applications."