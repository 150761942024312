import { Card, CardBody, CardHeader, Col, Collapse, Row} from "reactstrap";
import React, {Fragment,useEffect, useState} from "react";
import {fetchAllData, fetchDetails} from "../../common/utils/methods/commonMethods/utilityMethod";
import {masterServiceBaseUrl, examControllerServiceBaseUrl, failureToast, putpost, successToast} from "../../../../../services/http";
import ExamHeader from "./examIdForm/examHeader";
import ExamDescription from "./examIdForm/examDescription";
import ExamDetails from "./examIdForm/examDetails";
import LogDetailsExamId from "./logDetails";
import CustomLoader from "../../common/commonComponents/Loader/loader";
import {useHistory} from "react-router-dom";
import moment from "moment";
import CustomModel from "../../common/commonComponents/Model";
import {button, label, placeHolder} from "./properties";

const NewExamIdForm = (props) => {
    let history = useHistory();
    const {action,id} = props.match.params;
    const {label,placeHolder,headerTitle,message,button,header} = require('./properties/index.json');
    const [isCollapseOneOpen, setIsCollapseOneOpen] = useState(true);
    const [isCollapseTwoOpen, setIsCollapseTwoOpen] = useState(action!=='new');
    const [isCollapseThreeOpen, setIsCollapseThreeOpen] = useState(action!=='new');
    const [isCollapseFourOpen,setIsCollapseFourOpen] = useState(true);
    const [termData,setTermData] = useState([]);
    const [termMapping,setTermMapping] = useState([]);
    useEffect(()=>{
        console.log('Term Mapping--------->',termMapping)
    },[termMapping])
    const [testSequence,setTestSequence] = useState([]);
    useEffect(() => {
        console.log('Term DropDown---->',termData);
    },[termData])

    const [termDate,setTermDate] = useState([]);
    const [termDropDown,setTermDropDown] = useState([]);

    const [termMappingData,setTermMappingData] = useState([]);
    useEffect(() => {
        if(termData && termData.length>0 && termMappingData && termMappingData.length>0){
            let tempArray = [];
            termMappingData.map(item=>{
                termData.map(el=>{
                    if(parseInt(el.id) ===parseInt(item)){
                        tempArray.push(el);
                    }
                })
            })
            setTermDropDown(tempArray);
        }
    },[termMappingData,termData])
    // useEffect(() => {
    //     if(termData && termData.length > 0 && termDate && termDate.length > 0 && termMapping && termMapping.length>0){
    //         let tempArray =[];
    //
    //         termMapping.map((data)=>{
    //             termDate.map((item)=>{
    //                 if(item.termMappingId === data.id){
    //                     if(item.termStartDate && item.termEndDate ){
    //                         if(moment(item.termEndDate).isAfter(moment().format('YYYY-MM-DD')) && (moment(item.termStartDate).isSame(moment().format('YYYY-MM-DD')) ||   moment(item.termStartDate).isBefore(moment().format('YYYY-MM-DD'))) ){
    //                             termData.map((el)=>{
    //                                 if(el.id === item.termMappingId){
    //                                     tempArray.push(el);
    //                                 }
    //                             })
    //                         }
    //                     }
    //                 }
    //                 // if(item.termStartDate && item.termEndDate  ){
    //                 //     console.log('Term data data---------->',item)
    //                 //     if(moment(item.termEndDate).isAfter(moment().format('YYYY-MM-DD')) && (moment(item.termStartDate).isSame(moment().format('YYYY-MM-DD')) ||   moment(item.termStartDate).isBefore(moment().format('YYYY-MM-DD'))) ){
    //                 //         termData.map((el)=>{
    //                 //             if(el.id === item.termMappingId){
    //                 //                 tempArray.push(el);
    //                 //             }
    //                 //         })
    //                 //     }
    //                 // }
    //                 console.log("Temp Term Data---------->",tempArray);
    //             })
    //         })
    //         setTermDropDown(tempArray);
    //     }
    // },[termDate,termData,termMapping])
    const [academicCareer, setAcademicCareer] = useState([]);
    const [academicGroup,setAcademicGroup] = useState([]);
    const [companyCode, setCompanyCode] = useState([]);
    const [classData,setClassData] = useState([]);
    const [groupCode,setGroupCode] = useState([]);
    const [examType,setExamType] = useState([]);
    const [examMode,setExamMode] = useState([]);
    const [examGroup,setExamGroup] = useState([]);
    const [examPattern,setExamPattern] = useState([]);
    const [examMethod,setExamMethod] = useState([]);
    useEffect(() => {
        console.log('termMappingData=--->',termMappingData);
    },[termMappingData])
    const status = [{id:"ACTIVE",text:"ACTIVE",desc:"ACTIVE"},{id:"INACTIVE",text:"INACTIVE",desc:"INACTIVE"}];
    useEffect(() => {
        // fetchAllData(masterServiceBaseUrl+`/termMapping/getAllTermsByGroupCodeAndCompanyCode?groupCode=8&companyCode=13`,setTermMappingData)
        fetchDetails(masterServiceBaseUrl + '/term/getAllActiveTerm', setTermData, 'term', 'reactSelect');
        fetchDetails(examControllerServiceBaseUrl + '/testSequenceMaster/getAllActiveExamTestSequence', setTestSequence, 'testSequence', 'reactSelect');
        fetchAllData(masterServiceBaseUrl +'/termDateMaster/getAllTermDate' , setTermDate);
        if(action ==='edit' || action === 'view') {
            fetchAllData(masterServiceBaseUrl + '/termMapping/getAllTermMapping', setTermMapping);
        }
        fetchDetails(masterServiceBaseUrl + '/groupCode/getAllActiveGroupCode', setGroupCode, 'groupCode', 'reactSelect');
       fetchDetails(masterServiceBaseUrl + '/class/getAllClass', setClassData, 'class', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + '/testType/getAllTestType', setExamType, 'testType', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + '/testMode/getAllTestMode', setExamMode, 'testMode', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + '/examGroup/getAllActiveExamGroup', setExamGroup, 'examGroup', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + '/examPattern/getAllExamPattern', setExamPattern, 'examPattern', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + '/testMethod/getAllTestMethod', setExamMethod, 'testMethod', 'reactSelect');
        if( action === 'view' ){
            fetchDetails(masterServiceBaseUrl + '/companyCode/getAllActiveCompanyCode', setCompanyCode, 'companyCode', 'reactSelect');
        }
        if(action !== 'new' ){
            fetchDetails(masterServiceBaseUrl + '/academicCareer/getAllActiveAcademicCareer', setAcademicCareer, 'academicCareer', 'reactSelect');
            fetchDetails(masterServiceBaseUrl + '/academicGroup/getAllActiveAcademicGroup', setAcademicGroup, 'academicGroup', 'reactSelect');
        }
    },[]);

    const [formData,setFormData] = useState({
        groupCode:null,
        companyCodeId:null,
        status:'ACTIVE',
        examId:null,
        examIdDescription:null,
        shortDescription:null,
        termId:null,
        academicGroupId:null,
        academicCareerId:null,
        classId:null,
        examTypeId:null,
        examModeId:null,
        testSequence:null,
        examGroupId:null,
        examMethodId:null,
        examPatternId:null,
        totalNoOfTestPapers:null,
        totalNoOfTestQuestions:null,
        testSequenceMasterId :null,
        isEdit:false,
        isNew:true
    })

    useEffect(() => {
        if(formData.groupCode && (action === 'edit' || action === 'view') ){
            fetchDetails(masterServiceBaseUrl + `/companyCode/getCompanyCodeByGroup/${formData.groupCode}`, setCompanyCode, 'companyCode', 'reactSelect');
        }
    },[formData])
    const Update =(key,value)=>{
        try {
            setFormData(preState => ({
                ...preState,
                [key]:value
            }) )
        } catch ( e ) {
            console.log("Error",e)
        }
    }
    const [apiLoader,setApiLoader] = useState(false);
    useEffect(() => {
        if(action !== 'new'){
            fetchAllData(examControllerServiceBaseUrl + '/exam/getExamIdById/' + id, setFormData);
            setApiLoader(false);
        }
    },[])
     const nextHandler =(step)=>{
        try{
           if(step === 1){
               if(!formData['groupCode']){failureToast(message.failure.businessGroup)}
               else if(!formData['companyCodeId']){failureToast(message.failure.companyCode)}
               else{setIsCollapseTwoOpen(true);}
           }else if(step ===2){
               if(!formData['examIdDescription']){failureToast(message.failure.examIdDescription)}
               else if(!formData['shortDescription']){failureToast(message.failure.shortDescription)}
               else{setIsCollapseThreeOpen(true);}
           }else if(step ===3){
               if(!formData['groupCode']){failureToast(message.failure.businessGroup)}
               else if(!formData['companyCodeId']){failureToast(message.failure.companyCode)}
               else if(!formData['examIdDescription']){failureToast(message.failure.examIdDescription)}
               else if(!formData['shortDescription']){failureToast(message.failure.shortDescription)}
               else if(!formData['termId']){failureToast(message.failure.term);}
               else if(!formData['academicGroupId']){ failureToast(message.failure.academicGroup); }
               else if(!formData['academicCareerId']){ failureToast(message.failure.academicCareer); }
               else if(!formData['classId']){ failureToast(message.failure.class); }
               else if(!formData['examGroupId']){ failureToast(message.failure.examGroup); }
               else if(!formData['examTypeId']){ failureToast(message.failure.examType); }
               else if(!formData['examModeId']){ failureToast(message.failure.examMode); }
               else if(!formData['testSequenceMasterId']){ failureToast(message.failure.testSequence); }
               else{

                   // let obj = formData;
                   // obj['examIdDescription'] = obj['examIdDescription'].replace(/\s+/g, " ").toUpperCase().trim();
                   // PostData(examControllerServiceBaseUrl + `/exam/${formData['isNew'] ? 'createExamId' : 'updateExamId/'+formData['id']}`, obj, formData['isNew'] ? 'POST' : 'PUT')
                   if(formData['isNew']) {
                       let obj = formData;
                       obj['examIdDescription'] = obj['examIdDescription'].replace(/\s+/g, " ").toUpperCase().trim();
                       // obj['shortDescription'] = obj['shortDescription'].replace(/\s+/g, " ").toUpperCase().trim();
                       // console.log('Object for api--------->',obj);
                       PostData(examControllerServiceBaseUrl + '/exam/createExamId', obj, 'POST');
                   }else{
                       let obj = formData;
                       obj['examIdDescription'] = obj['examIdDescription'].replace(/\s+/g, " ").toUpperCase().trim();
                       // obj['shortDescription'] = obj['shortDescription'].replace(/\s+/g, " ").toUpperCase().trim();
                       PostData(examControllerServiceBaseUrl + '/exam/updateExamId/'+formData['id'], obj, 'PUT');
                   }
               }
           }
        } catch (e) {
            console.error('Error in next Handler---->',e);
        }
    }

    const PostData =(url,dataObject,type)=>{
        putpost(url,(data)=>{
           // action === 'new' ?  successToast(data.message +'Generated Exam Id : '+data.data.generatedExamId):  successToast(data.message)
            if(action === 'edit'){
                successToast(data.message)
                history.push('/admin/examIdGenerationSearch/view/'+id);
                fetchAllData(examControllerServiceBaseUrl + '/exam/getExamIdById/' + id, setFormData);
            }else if(action === 'new'){
                setExamId(data.data.generatedExamId);
                setShowModel(true)
                // history.push(`/admin/examIdGenerationSearch`);
            }
        },(data)=>{
            failureToast(data.message);
            console.log('Error Message ---->',data.message);
        },dataObject,type);
    }
    const [showModel,setShowModel] = useState(false);
    const [examId,setExamId] = useState(null);
    const redirectHandler =()=>{
        history.push(`/admin/examIdGenerationSearch`);
    }
    return (
        showModel && examId?
        <CustomModel
            // copyIconVisible={true}
            title={'value'}
            content={`Exam ID : ${examId}`}
            redirectHandler={redirectHandler}
            textToCopy={examId}
        />:
        apiLoader ?
            <Card style={{margin: '12px 20px'}}>
                <CustomLoader apiLoader={apiLoader} loaderHeight={'200px'} loaderWidth={'100% '}/>
            </Card>
            :
            <Fragment>
                <div style={{marginTop: '15px'}} className="col">
                    <div className="card-wrapper">
                        <Card>
                            <CardHeader>
                                <h3 className="mb-0">{headerTitle}</h3>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={12} xs={12}>
                                        <div className="accordion">
                                            <Card className="card-plain">
                                                <CardHeader className='accordionHeader' role="tab" onClick={() => setIsCollapseOneOpen(action === 'new' ? isCollapseOneOpen : !isCollapseOneOpen)} aria-expanded={isCollapseOneOpen}>
                                                    <h3 className="mb-0">{header.examHeader}</h3>
                                                </CardHeader>
                                                <Collapse role="tabpanel" isOpen={isCollapseOneOpen}>
                                                    <CardBody>
                                                        <ExamHeader setTermMappingData={setTermMappingData} setTermDropDown={setTermDropDown} setTermMapping={setTermMapping} setAcademicGroup={setAcademicGroup} setAcademicCareer={setAcademicCareer} action={action} formData={formData} setFormData={setFormData} nextHandler={nextHandler} Update={Update}   groupCode={groupCode} companyCode={companyCode} status={status} setIsCollapseOneOpen={setIsCollapseOneOpen} setIsCollapseTwoOpen={setIsCollapseTwoOpen} setIsCollapseThreeOpen={setIsCollapseThreeOpen} isCollapseOneOpen={isCollapseOneOpen} setCompanyCode={setCompanyCode} label={label} placeholder={placeHolder} button={button}/>
                                                    </CardBody>
                                                </Collapse>
                                            </Card>
                                            <Card className="card-plain">
                                                <CardHeader className='accordionHeader' role="tab" onClick={() => setIsCollapseTwoOpen(action === 'new' ?  isCollapseTwoOpen : !isCollapseTwoOpen)} aria-expanded={isCollapseTwoOpen}>
                                                    <h3 className="mb-0">{header.examDescription}</h3>
                                                </CardHeader>
                                                <Collapse role="tabpanel" isOpen={isCollapseTwoOpen}>
                                                    <CardBody>
                                                        <ExamDescription action={action} formData={formData} setFormData={setFormData} nextHandler={nextHandler} Update={Update}  isCollapseTwoOpen={isCollapseTwoOpen} setIsCollapseTwoOpen={setIsCollapseTwoOpen} setIsCollapseOneOpen={setIsCollapseOneOpen} setIsCollapseThreeOpen={setIsCollapseThreeOpen} label={label} placeholder={placeHolder} button={button}/>
                                                    </CardBody>
                                                </Collapse>
                                            </Card>
                                            <Card className="card-plain">
                                                <CardHeader className='accordionHeader' role="tab" onClick={() => {setIsCollapseThreeOpen(action === 'new' ? isCollapseThreeOpen :  !isCollapseThreeOpen)}} aria-expanded={isCollapseThreeOpen}>
                                                    <h3 className="mb-0">{header.examDetails}</h3>
                                                </CardHeader>
                                                <Collapse role="tabpanel" isOpen={isCollapseThreeOpen}>
                                                    <CardBody>
                                                        <ExamDetails testSequence={testSequence} examMethod={examMethod} examPattern={examPattern} examGroup={examGroup} action={action} formData={formData} setFormData={setFormData} nextHandler={nextHandler} Update={Update}  isCollapseThreeOpen={isCollapseThreeOpen} setIsCollapseThreeOpen={setIsCollapseThreeOpen} termDatForEditAndView={termData} termData={termDropDown} academicGroup={academicGroup} academicCareer={academicCareer} classData={classData} examType={examType} examMode={examMode} label={label} placeholder={placeHolder} button={button}/>
                                                    </CardBody>
                                                </Collapse>
                                            </Card>
                                            {action !=='new'?(<Card className="card-plain">
                                                <CardHeader className='accordionHeader' role="tab" onClick={() => {setIsCollapseFourOpen(!isCollapseFourOpen)}} aria-expanded={isCollapseFourOpen}>
                                                    <h3 className="mb-0">{header.logDetails}</h3>
                                                </CardHeader>
                                                <Collapse role="tabpanel" isOpen={isCollapseFourOpen}>
                                                    <CardBody>
                                                        <LogDetailsExamId formData={formData} action={action} label={label} button={button}    />
                                                    </CardBody>
                                                </Collapse>
                                            </Card>):null}
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </Fragment>
    )
}

export default NewExamIdForm;