import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
	Col,
	Row,
	Container,
	Button,
	Card,
	CardBody,
	CardHeader,
	Form,
} from 'reactstrap';
import Select2 from 'react-select2-wrapper';
import Loader from 'react-loader-spinner';
import {
	masterServiceBaseUrl,
	getAPI,
	putpost,
	putpost2,
} from 'services/http';
import CustomForm from './CustomForm';
import { Accordion } from '../../../common/commonComponents/Accordion/accordion';
import { failureToast, successToast } from '../../../common/utils/methods/toasterFunctions/function';

//import './index.css';

const CompanyCodeAddMidiator = (props) => {
	const {
		countryData = [],
		stateData = [],
		cityData = [],
		districtData = [],
		pincodeData = [],
		addressCategory = [],
		bussinessUnit,
		savedAddressList,
		codeDetails,
		grpCode,
		id,
		resetList = () => {},
		history,
		building,
	} = props;

	const [addressList, setAddressList] = useState([]);
	const [isFormVisible, setFormVisible] = useState(true);
	const [category, setCategory] = useState('');
	const [property, setProperty] = useState([]);
	const [isCategoryDisabled, setCategoryDisabled] = useState(false);
	const [saveingStatus, setSaveingStatus] = useState(false);
	const [isEditFormOpen, setEditForm] = useState(false);
	const [apiloader, setApiLoader] = useState(false);

	React.useEffect(() => {
		setAddressList(savedAddressList);
		if (savedAddressList.length) {
			setFormVisible(false);
		}
	}, [savedAddressList]);

	const fetchData = (url, stateMethod, value) => {
		getAPI(
			url,
			(data) => {
				stateMethod(data.data);
				// console.log(data.data)
				if (!isFormVisible) {
					toggleFormVisibility();
				}
				setCategory(value);
				setCategoryDisabled(true);
			},
			(data) => {
				failureToast(data['message']);
				setFormVisible(false);
				stateMethod('');
				setCategoryDisabled(false);
			}
		);
	};

	const saveData = (formData) => {
		formData.referenceId = Number(id);
		setApiLoader(true);
		putpost(
			`${masterServiceBaseUrl}/address/createAddress`,
			(data) => {
				successToast(data['message']);
				resetList(true);
				// formData.id = data.data.id
				// formData.updatedDate = data.data.updatedOn;
				// formData.effectiveDate = data.data.effectiveDate;
				// setAddressList([...addressList, formData])
				// console.log(formData)
				setApiLoader(false);
				toggleFormVisibility();
			},
			(data) => {
				failureToast(data['message']);
				setApiLoader(false);
			},
			formData,
			'post'
		);
	};

	const editData = (json, aid, i) => {
		json.referenceId = Number(id);
		setApiLoader(true);
		putpost(
			`${masterServiceBaseUrl}/address/updateAddress/${aid}`,
			(data) => {
				successToast(data['message']);
				resetList(true);
				//  console.log(data.data)
				// json.updatedDate = data.data.updatedOn
				// addressList[i] = json
				// setAddressList([...addressList])
				setApiLoader(false);
				// console.log(formData)
				toggleFormVisibility();
			},
			(data) => {
				failureToast(data['message']);
				setApiLoader(false);
			},
			json,
			'put'
		);
	};

	const deleteData = (aid, i) => {
		setSaveingStatus(true);
		setApiLoader(true);
		putpost2(
			`${masterServiceBaseUrl}/address/deleteAddress/${aid}/${Number(id)}`,
			(data) => {
				successToast(data['message']);
				addressList.splice(i, 1);
				setAddressList([...addressList]);
				if (!addressList.length) {
					setFormVisible(true);
				}
				setApiLoader(false);
				setEditForm(false);
				// console.log(formData)
			},
			(data) => {
				failureToast(data['message']);
				setApiLoader(false);
			},
			'delete'
		);
	};

	const getGrpCodeValues = (cid) => {
		const grpValue =
			grpCode &&
			grpCode.filter((item) => {
				return item && item.id === cid;
			});
		return grpValue.length ? grpValue[0] : [];
	};

	const toggleFormVisibility = () => {
		setFormVisible(!isFormVisible);
	};

	const addressToDisplay = addressList.map((item, i) => {
		return (
			<Accordion
				key={i}
				title={item.addressDispValue}
				isEditFormOpen={(bool) => setEditForm(bool)}
				effectiveDate={item.effectiveDate}
				updatedDate={item.updatedDate}
			>
				<CustomForm
					addressData={item}
					isNewForm={false}
					editFromList={(json, aid) => editData(json, aid, i)}
					deleteAddress={(aid) => deleteData(aid, i)}
					countryData={countryData}
					stateData={stateData}
					cityData={cityData}
					districtData={districtData}
					pincodeData={pincodeData}
					addressCategoryData={addressCategory}
					isEditFormOpen={(bool) => setEditForm(bool)}
					resetList={resetList}
					apiloader={apiloader}
					building={building}
				/>
			</Accordion>
		);
	});

	return (
		<>
			<Container className="mt-3" fluid>
				<Row>
					<div className="col">
						<div className="card-wrapper">
							<Card>
								<CardHeader className={`d-flex justify-content-between`}>
									<h3 className="mb-0">Company Code</h3>
									{addressList.length ? (
										<Button
											color="primary"
											type="button"
											className={`floatRight`}
											onClick={() => {
												setFormVisible(true);
											}}
										>
											Add More
										</Button>
									) : null}
								</CardHeader>
								<CardBody>
									<Row className={`mb-3`}>
										<Col md={2}>Group Code</Col>:
										<Col md={2}>
											{(codeDetails &&
												getGrpCodeValues(codeDetails.groupCodeId) &&
												getGrpCodeValues(codeDetails.groupCodeId)[
													'groupCodeDispValue'
												]) ||
												'Not Available'}
										</Col>
										<Col md={3}>Group Code Display Value</Col>:
										<Col md={4}>
											{(codeDetails &&
												getGrpCodeValues(codeDetails.groupCodeId) &&
												getGrpCodeValues(codeDetails.groupCodeId)[
													'groupCodeDesc'
												]) ||
												'Not Available'}
										</Col>
									</Row>
									<Row className={`mb-2`}>
										<Col md={2}>Company Code</Col>:
										<Col md={2}>
											{codeDetails && codeDetails.companyCodeDispValue}
										</Col>
										<Col md={3}>Company Code Display Value</Col>:
										<Col md={4}>
											{codeDetails && codeDetails.companyCodeDesc}
										</Col>
									</Row>
								</CardBody>
							</Card>
							{isFormVisible && !isEditFormOpen ? (
								<Card className={`mt-3`}>
									<CardBody>
										<CustomForm
											countryData={countryData}
											stateData={stateData}
											cityData={cityData}
											districtData={districtData}
											pincodeData={pincodeData}
											addressCategoryData={addressCategory}
											isNewForm={true}
											saveData={saveData}
											apiloader={apiloader}
											building={building}
										/>
									</CardBody>
								</Card>
							) : null}
						</div>
					</div>
				</Row>

				{addressToDisplay}

				{addressList.length ? (
					<Card className={`mt-3 d-flex`}>
						<CardBody>
							<Button
								color="primary"
								type="button"
								className={`ml-auto float-right`}
								onClick={() => {
									history.push('/admin/companyCode');
								}}
							>
								Done
							</Button>
						</CardBody>
					</Card>
				) : null}
			</Container>
		</>
	);
};

export default withRouter(CompanyCodeAddMidiator);
