import React from "react";

const tipsDivStyle = {
  padding: "12px 16px 12px 16px",
  borderRadius: "8px",
  gap: "10px",
  background: "#F0ECFC",
  flexDirection:"column",
  marginTop:"10pc"
};

export const NoteDiv = ({isMultiple}) => {
  return (
    <div style={tipsDivStyle} className="border-purple d-flex mt-2">
      <div className="d-flex">
        <span className="semi-bold color-dark">Notes:</span>
      </div>
      <div style={{fontSize:"14px"}}>
        ID card for the above student{isMultiple?'s':''} will be printed without roll number{" "}
      </div>
    </div>
  );
};
