import React, { useRef, useState } from 'react';
import Style from './popup.module.scss';
import { Modal, ModalBody, Button } from 'reactstrap';
import CommonInput from '../../formFeilds/input/commonInput';
import { ReactComponent as CloseIcon } from "../../../../../../assets/img/svg/CrossIcon.svg"

const useConfirm = () => {

    const [promise, setPromise] = useState(null);
    const inputRef = useRef(null);
    const checkboxRef=useRef(null);

    const confirm = () => new Promise((resolve, reject) => {
        setPromise({ resolve });
    });

    const handleClose = () => {
        setPromise(null);
    };

    const handleConfirm = (isInputVisible,isCheckboxVisible) => {
        promise.resolve(isInputVisible ? inputRef?.current?.value ?? '' : isCheckboxVisible ? (checkboxRef.current.checked ? "Checked" : "Unchecked"): true)
        handleClose();
    };

    const handleCancel = () => {
        promise.resolve(false);
        handleClose();
    };

    const confirmationPrompt = ({
        message,
        nextButtonText,
        backButtonText,
        title,
        leftAlign,
        isInputVisible = false,
        crossIconRequired = false,
        backButtonStyles,
        nextButtonStyles,
        titleStyles,
        messageStyles,
        txtAreaStyles,
        modalStyles,
        iconStyles,
        txtAreaContainerWidth = 75,
        nextBtnClasses,
        backBtnClasses,
        checkBoxDetails
    }) => (

        <Modal
            isOpen={promise !== null}
            className={Style.mode_container}
            style={modalStyles}
            centered
            zIndex={2000}
        >
            <ModalBody
                className={Style.body_content}
                style={leftAlign ? { alignItems: 'flex-start' } : {}}
            >
                <div className='d-flex justify-content-between w-100'>
                    <div className={Style.icon} style={{ ...(leftAlign ? { borderColor: '#414141', color: '#414141' } : {}), ...iconStyles }}>{'?'} </div>
                    {crossIconRequired && <CloseIcon style={{ cursor: "pointer" }} onClick={handleCancel} />}
                </div>

                <p className={Style.confirmation_message} style={{ ...titleStyles, ...(leftAlign ? { fontWeight: 700 } : {}) }}>{title ? title : 'Are you sure?'}</p>
                <p className={Style.signOut_message} style={messageStyles}>{message ? message : 'All your changes will be lost'}</p>

                {isInputVisible ?
                    <div className={`w-${txtAreaContainerWidth} mb-2`}><textarea className="w-100" style={txtAreaStyles} type="textarea" rows={3} placeHolder="Add remarks" ref={inputRef} /></div>
                    : null
                }

                {checkBoxDetails?.isVisible ?
                <div className='d-flex'>
                <input 
                ref={checkboxRef}
                type='checkbox'
                id='checkboxInput1'
                className={`${Style.checkbox}`}
                // checked={checkBoxDetails?.checked}
                defaultChecked={true}
                onChange={checkBoxDetails?.handleChange}
                disabled={checkBoxDetails?.disabled}
                />
                <label htmlFor='checkboxInput1' >{checkBoxDetails?.label|| "-"}</label>
                </div> : null

                }

                {leftAlign ?
                    <div>
                        <Button className={`${Style.btn_no} no-ml-btn outline-btn ${backBtnClasses}`} style={{ width: '140px', ...backButtonStyles }} onClick={handleCancel}>
                            {backButtonText ? backButtonText : 'NO'}
                        </Button>
                        <Button className={`${Style.btn_confirm} ${nextBtnClasses}`} onClick={() => handleConfirm(isInputVisible,checkBoxDetails?.isVisible)} style={{ width: '140px', ...nextButtonStyles }} >
                            {nextButtonText ? nextButtonText : 'YES'}
                        </Button>

                    </div>

                    : <div>
                        <Button className={`${Style.btn_yes} ${nextBtnClasses}`} style={nextButtonStyles} onClick={() => handleConfirm(isInputVisible,checkBoxDetails?.isVisible)}>
                            {nextButtonText ? nextButtonText : 'YES'}
                        </Button>
                        <Button className={`${Style.btn_no} ${backBtnClasses}`} style={backButtonStyles} onClick={handleCancel}>
                            {backButtonText ? backButtonText : 'NO'}
                        </Button>
                    </div>
                }
            </ModalBody>
        </Modal>
    );

    return [confirmationPrompt, confirm];
};

export default useConfirm;