import { Dialog } from '@mui/material'
import React from 'react'
import AButton from 'views/pages/manage/common/form-fields-mui/AButton'
import { ReactComponent as CrossIcon } from 'assets/img/svg/CrossIcon.svg'
import { ReactComponent as QuestionIcon } from 'assets/img/svg/QuestionMarkConfirmationDialog.svg'

const StatusDialog = ({ open, setOpen ,values,setValues}) => {
    console.log('op',open)
  const handleInactiveClose = () => setOpen({...open,inactive:false});
  const handleActiveClose = () =>setOpen({...open,active:false})

  return (
    open.inactive?(<Dialog onClose={handleInactiveClose} open={open.inactive} >
      <div style={{ padding: "28px 24px", width: "500px" }}>
        <div className='d-flex justify-content-between align-items-start mb-3'>
          <QuestionIcon color='#414141' />
          <CrossIcon width={32} height={32} onClick={handleInactiveClose} />
        </div>

        <>
        <div style={{width: '388px', color: 'black', fontSize: '20px', fontFamily: 'Inter', fontWeight: '600', lineHeight: '28px', wordWrap: 'break-word'}}>Students will not be able to see the learning content(ebooks/videos/tests etc) in myaakash app. </div>
        <div style={{width: '388px', color: '#414141', fontSize: '16px', fontFamily: 'Inter', fontWeight: '500', lineHeight: '24px', wordWrap: 'break-word'}}>Are you sure you want to proceed?</div>
        </>

        <div className='d-flex justify-content-between mt-3'>
          <AButton variant='outline' className='button_remove_left_margin' updatedStyle={{ border: '2px solid #414141', color: '#414141', width: '180px' }} onClick={handleInactiveClose}>No</AButton>
          <AButton variant='danger_filled' updatedStyle={{ backgroundColor: '#CC5F5F', width: '180px' }} onClick={() => {setValues({...values,status:{label:'INACTIVE',value:'INACTIVE'}}); handleInactiveClose(); }}>Yes, Deactivate</AButton>
        </div>
      </div>
    </Dialog>):
    (<Dialog onClose={handleActiveClose} open={open.active} >
    <div style={{ padding: "28px 24px", width: "500px" }}>
      <div className='d-flex justify-content-between align-items-start mb-3'>
        <QuestionIcon color='#414141' />
        <CrossIcon width={32} height={32} onClick={handleActiveClose} />
      </div>

      <>
      <div style={{width: '388px', color: 'black', fontSize: '20px', fontFamily: 'Inter', fontWeight: '600', lineHeight: '28px', wordWrap: 'break-word'}}>Students will be able to see the learning content(ebooks/videos/tests etc) in myaakash app. </div>
      <div style={{width: '388px', color: '#414141', fontSize: '16px', fontFamily: 'Inter', fontWeight: '500', lineHeight: '24px', wordWrap: 'break-word'}}>Are you sure you want to proceed?</div>
      </>

      <div className='d-flex justify-content-between mt-3'>
        <AButton variant='outline' className='button_remove_left_margin' updatedStyle={{ border: '2px solid #00B0F5', color: '#414141', width: '180px' }} onClick={handleActiveClose}>No</AButton>
        <AButton variant='danger_filled' updatedStyle={{ backgroundColor: '#00B0F5', width: '180px' ,border: '2px #00B0F5 solid'}} onClick={() => {setValues({...values,status:{label:'ACTIVE',value:'ACTIVE'}}); handleActiveClose(); }}>Yes, Activate</AButton>
      </div>
    </div>
  </Dialog>)
  )
}

export default StatusDialog