import React, { useContext, useState } from 'react'

import { RolePermissions, PermissionContext } from 'appContext';
import { ReactComponent as ActiveControl } from 'assets/img/svg/ActiveControl.svg';
import { ReactComponent as InactiveControl } from 'assets/img/svg/InactiveControl.svg';
import { checkPermission } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import StatusChangeDialog from './StatusChangeDialog';
import { pages } from 'views/pages/manage/common/constant';
import { capitalize } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
const StatusCellComponent = ({ params, toggleStatusHandler = () => { }, rowForm, rowFormHandler = () => { } }) => {

  const userPermissions = RolePermissions(useContext(PermissionContext), pages['ltaConfig']['id']);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    params.api.getRowMode(params?.id) === 'view' ? (
      <div>
        <span>
          {params.value === "ACTIVE" ?
            <ActiveControl onClick={checkPermission(userPermissions, 'U') ? () => setIsDialogOpen(true) : () => { }} /> :
            <InactiveControl onClick={checkPermission(userPermissions, 'U') ? () => setIsDialogOpen(true) : () => { }} />
          }
          <span
            style={{
              fontSize: "14px", marginLeft: "4px",
              color: params.value === "ACTIVE" ? '#0F0F0F' : '#757575'
            }}
          >
            {capitalize(params?.value)}
          </span>
        </span>
        <StatusChangeDialog
          open={isDialogOpen}
          setOpen={setIsDialogOpen}
          rowData={params?.row}
          onAgree={() => toggleStatusHandler(params?.row)}
        />
      </div>
    ) : (
      <div>
        <span>
          {rowForm.status === "ACTIVE" ?
            <ActiveControl onClick={() => setIsDialogOpen(true)} /> :
            <InactiveControl onClick={() => setIsDialogOpen(true)} />
          }
          <span
            style={{
              fontSize: "14px", marginLeft: "4px",
              color: rowForm.status === "ACTIVE" ? '#0F0F0F' : '#757575'
            }}
          >
            {capitalize(rowForm.status)}
          </span>
        </span>
        <StatusChangeDialog
          open={isDialogOpen}
          setOpen={setIsDialogOpen}
          rowData={rowForm}
          onAgree={() => rowForm?.status === 'ACTIVE' ? rowFormHandler('INACTIVE', 'status') : rowFormHandler('ACTIVE', 'status')}
        />
      </div>
    )
  )
}

export default StatusCellComponent