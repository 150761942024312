import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Popover } from "@mui/material";
import { inputContainerStyle } from "../helperFile";
import { ButtonLikeDropdown } from "../SelectLecturePopover";
import {
  useFetchAllocatedStudent,
  useFetchMappedStudent,
} from "../../../hooks";
import { primaryCheckboxStyles } from "../../../../../edpOps/issuanceIDcard/printIdCardAndMapRfid/constant";
import SearchFilter from "./SearchFilter";

const popoverStyle = {
  minWidth: "15rem",
  minHeight: "7rem",
  height: "20rem",
  overflow: "scroll",
  width: "29rem",
};

const btnLabelStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};
const getLabel = (selectedStudents) => {
  if (!selectedStudents.length) return "Select";
  
  return selectedStudents.map((item) => item.studentName).join(", ");
};
const DropdownItem = ({ item, checked, onChange, disabled }) => {
  return (
    <div>
      {!disabled && <FormControlLabel
        sx={{ margin: "0px", ...primaryCheckboxStyles }}
        control={
          <Checkbox
            onChange={(e) => onChange(e.target.checked ? true : false, item)}
            checked={checked}
          />
        }
      />}
      <span className="global-font-size">{item?.value}</span>
    </div>
  );
};
const PTMUpdatePsid = ({
  isDisabledOther,
  isDisabled,
  batchId,
  selectedStudents,
  setSelectedStudents,
  ptmId,
  mappedStudentList,
  bachTimeTablePsidMapping,
  isEdit 
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [studentList, isLoading] = useFetchAllocatedStudent(batchId);
  const [filteredStudent, setFilteredStudent] = useState([]);
  const [appliedFilteredString, setAppliedFilteredString] = useState(null);
  useEffect(() => {
    if (ptmId) {
      setSelectedStudents(mappedStudentList);
    } else {
      const addStudent = isEdit?
         bachTimeTablePsidMapping
        : studentList;
      setSelectedStudents(addStudent);
    }

    setFilteredStudent(studentList);
  }, [studentList, ptmId]);

  useEffect(() => {
    setFilteredStudent(
      studentList.filter((item) =>
        item["value"]
          ?.toLowerCase()
          .includes(appliedFilteredString?.toLowerCase())
      )
    );
  }, [appliedFilteredString]);

  const onChangeHandler = (checked, student) => {
    if (student.id === "ALL") {
      if (checked) setSelectedStudents(studentList);
      else setSelectedStudents([]);
      return;
    }
    if (checked) {
      setSelectedStudents([...selectedStudents, student]);
    } else {
      setSelectedStudents(
        selectedStudents.filter((el) => el.psid !== student.psid)
      );
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "popover-lecture" : undefined;

  return (
    <>
      <ButtonLikeDropdown
        disabled={isDisabledOther}
        className="button_remove_left_margin"
        handleClick={handleClick}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
        label={<div style={btnLabelStyle}>{getLabel(selectedStudents)}</div>}
      ></ButtonLikeDropdown>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="p-2 m-2" style={popoverStyle}>
          <div style={inputContainerStyle}>
            {!isDisabled && <SearchFilter
              setAppliedFilteredString={setAppliedFilteredString}
              appliedFilteredString={appliedFilteredString}
            />}
            {filteredStudent.length > 0 && !isDisabled &&  (
              <DropdownItem
                checked={studentList.length === selectedStudents.length}
                onChange={onChangeHandler}
                item={{ id: "ALL", value: "Select all" }}
                disabled = {isDisabled}
              />
            )}
            {filteredStudent.map((student) => (
              <DropdownItem
                key={student?.value}
                checked={selectedStudents.some(
                  (item) => item?.value === student.value
                )}
                item={student}
                disabled = {isDisabled}
                onChange={onChangeHandler}
              />
            ))}
            {filteredStudent.length === 0 && studentList.length > 0 && (
              <span>No result found</span>
            )}
          </div>
        </div>
      </Popover>
    </>
  );
};

export default PTMUpdatePsid;
