import React from "react";
import { ASelect } from "../../common/form-fields-mui/ASelect";
import AInput from "../../common/formFeilds/customInput/AInput";
import AAutoComplete from "../../common/form-fields-mui/AAutoComplete";
import { getClassesByAcademicGroupId } from '../constant/create-academic-plan'
import { useSelector } from "react-redux";
import { getAcademicPhaseValue } from "../../edpOps/constant/batch-search";


const GetAddForm = ({
  step,
  dropdown,
  classDropdown,
  businessAreaDropdown,
  form,
  formInputHandler,
  cmsPackageDropdown,
  cmsPackageLoader,
  isDuplicate
}) => {

  switch (step) {
    case 1:
      return (
        <div>
          <AAutoComplete 
            label="Stream"
            currentValue={form.academicGroup}
            items={dropdown?.academicGroup}
            handleChange={(value) => {formInputHandler(value, "academicGroup"); formInputHandler("", "packageId");}}
            isMulti={false}
            isDisabled = {isDuplicate}
          />
          <AAutoComplete 
            label="Class"
            currentValue={form.classes}
            items={classDropdown}
            handleChange={(value) => {formInputHandler(value, "classes"); formInputHandler("", "packageId");}}
            isMulti={false}
            isDisabled = {isDuplicate}

          />
          <AAutoComplete
            label="Academic Board"
            currentValue={form.board}
            items={dropdown?.academicBoard}
            handleChange={(value) => {formInputHandler(value, "board"); formInputHandler("", "packageId");}}
            isMulti={false}
          />
          <AAutoComplete
            label="Academic Year"
            currentValue={form.academicYear}
            items={dropdown?.academicYear}
            handleChange={(value) => {formInputHandler(value, "academicYear"); formInputHandler("", "packageId");}}
            isMulti={false}
          />
          <AAutoComplete
            label="CMS Package ID"
            currentValue={form.packageId}
            items={cmsPackageDropdown}
            handleChange={(value) => formInputHandler(value, "packageId")}
            isMulti={false}
            isLoading={cmsPackageLoader}
            isDisabled={!form?.academicGroup || !form?.classes || !form?.board || !form?.academicYear}
          />
        </div>
      );
    case 2:
      return (
        <div>
          <AAutoComplete
            label="Academic Career"
            currentValue={form.academicCareer}
            items={dropdown?.academicCareer}
            handleChange={(value) => formInputHandler(value, "academicCareer")}
            isMulti={false}
          />
          <AAutoComplete
            label="Academic Phase/Group"
            currentValue={form.academicPhase}
            items={getAcademicPhaseValue(dropdown?.academicPhase, true)}
            handleChange={(value) => formInputHandler(value, "academicPhase")}
            isMulti={false}
          />
          <AAutoComplete
            label="Class Type"
            currentValue={form.classType}
            items={dropdown?.classType}
            handleChange={(value) => formInputHandler(value, "classType")}
            isMulti={false}
          />
          <AInput
            label="Add Description (optional)"
            value={form.description}
            handler={(value) => formInputHandler(value, "description")}
          />
        </div>
      );
    case 3:
      return (
        <div>
          <AAutoComplete
            label="Region"
            currentValue={form.regions}
            items={dropdown?.region}
            handleChange={(value) => {
              formInputHandler(value, "regions") ;
              formInputHandler([], "businessAreas")
            }
            }
            isShowCount={true}
          />
          <AAutoComplete
            label="Branch(es)"
            currentValue={form.businessAreas}
            items={businessAreaDropdown || []}
            handleChange={(value) => formInputHandler(value, "businessAreas")}
            isShowCount={true}
          />
        </div>
      );

    default:
      break;
  }
};

export default GetAddForm;
