export const EDP_SUB_CLASSIFICATIONS = [
  { subClassification: 'TIMETABLE_YET_TO_BE_CREATED', desc: 'Secondary batches for which timetable is yet to be created', redirectPathname: '/admin/edp/searchBatch', requireBuSelection: true },
  { subClassification: 'UNALLOCATED_STUDENTS', desc: 'Students to be allocated in secondary batch', redirectPathname: '/admin/edp/searchBatch', requireBuSelection: false },
  { subClassification: 'RFID_NOT_MAPPED', desc: 'RFID yet to be issued', redirectPathname: '/admin/edp/idCards/mapRfId/mapRfIdCards', requireBuSelection: false },
];

export const NOTIFICATION_SUB_CLASSIFICATION_MAP = [
  {subClassification: 'ACADEMIC_PLAN_PUBLISHED', notifiationTitle: 'Academic Plan', redirectPathname: false },
  {subClassification: 'TIME_TABLE_TEST_CREATED', notifiationTitle: 'Timetable', redirectPathname: false }, // timetable create
  {subClassification: 'TIME_TABLE_TEST_DELETED', notifiationTitle: 'Timetable', redirectPathname: '/admin/edp/searchBatch' }, // timetable test edit // redirect to batch search 
  {subClassification: 'TIME_TABLE_EXPIRING_SOON', notifiationTitle: 'Timetable', redirectPathname: '/admin/testScheduling/timetable/view' }, // edit/view timetable screen of the batch
  {subClassification: 'TIME_TABLE_EXPIRED', notifiationTitle: 'Timetable', redirectPathname: '/admin/testScheduling/timetable/view' }, // edit/view timetable screen of the batch
]

export const getScreenIds = permissionContext => {
  return Object.keys(permissionContext?.scope || {});
};

export const restructureCountResponse = (data, businessAreas) => {
  const res = {};
  for (let item of data) {
    res[item?.subClassification] = [
      ...(res[item?.subClassification] ?? []),
      {
        ...item,
        // below key gets busiessArea display value from dropdown
        businessAreaValue: (
          businessAreas?.find(bu => bu?.id === item?.businessAreaId) || {}
        )?.businessAreaDispValue || '',
      },
    ];
  }
  // console.log(res)
  return res;
};

export const checkEdpCards = countData => {
  const subClassifications = Object?.keys(countData);
  for (let item of EDP_SUB_CLASSIFICATIONS) {
    if (subClassifications?.includes(item?.subClassification)) return true;
  }
  return false;
};

export const getTotalCountBySubClassification = (countResArray = []) => {
  let res = 0;
  for(let item of countResArray) {
    res+=item?.totalCount
  }
  return res;
}

export const getSearchParamsMap = {
  TIMETABLE_YET_TO_BE_CREATED: (params) => {
    return encodeURIComponent(params)
  },
  TIME_TABLE_TEST_DELETED: (params) => {
    return encodeURIComponent(params)
  },
}

export const getSearchParams = (data) => {
  if (getSearchParamsMap[data?.subClassification]) {
    return `?preFilters=${getSearchParamsMap[data?.subClassification](
      data?.params
    )}`;
  }
  return '';
}