import React from 'react'
import { Col, InputGroup, InputGroupText } from 'reactstrap'
import CommonInput from '../common/formFeilds/input/commonInput'

export const ApprovalSetting = ({
    roleData,
    values,
    setValues,
}) => {
    return (
        <div style={{
            border: '1px solid #EDEDED', padding: '16px 24px',
            borderRadius: '12px', marginTop: 10
        }}>
            <div className='semi-bold label-color'>2. Approval setting</div>
            <div className='d-flex justify-content-between pt-2'>
                <Col className="pl-0">
                    <CommonInput
                        type={"number"}
                        allowDecimalvalue={true}
                        mandatory={true}
                        defaultValue={values.l1ApprovalPercentage}
                        label={"Set L1 approval %"}
                        // onChange={(val) => setValues({ ...values, l1ApprovalPercentage: val })}
                        onChange={value => {
                            if (value > 100) {
                                return;
                            } else {
                                setValues({ ...values, l1ApprovalPercentage: value })
                            }
                        }}
                        adornmentText="%"
                        placeHolder="Enter"
                    />
                </Col>
                <Col className="pl-0">
                    <CommonInput
                        type={"multiSelectWithCheckboxes"}
                        mandatory={true}
                        label={"L1 Role"}
                        defaultValue={values.l1ApprovalRoles}
                        onChange={(val) => { setValues({ ...values, l1ApprovalRoles: val }) }}
                        data={roleData.data}
                    />
                </Col>
                {/* <Col className="pl-0">
                    <CommonInput
                        type={"select"}
                        mandatory={true}
                        label={"L1 User"}
                        //   value={filterForm?.startDateBegin}
                        onChange={() => { }}
                    />
                </Col> */}
            </div>
            <div className='d-flex justify-content-between'>
                <Col className="pl-0">
                    <CommonInput
                        type={"number"}
                        allowDecimalvalue={true}
                        mandatory={true}
                        label={"Set L2 approval %"}
                        defaultValue={values.l2ApprovalPercentage}
                        // onChange={(val) => { setValues({ ...values, l2ApprovalPercentage: val }) }}
                        onChange={value => {
                            if (value > 100) {
                                return;
                            } else {
                                setValues({ ...values, l2ApprovalPercentage: value })
                            }
                        }}
                        adornmentText="%"
                        placeHolder="Enter"
                    />
                </Col>
                <Col className="pl-0">
                    <CommonInput
                        type={"multiSelectWithCheckboxes"}
                        mandatory={true}
                        label={"L2 Role"}
                        defaultValue={values.l2ApprovalRoles}
                        onChange={(val) => { setValues({ ...values, l2ApprovalRoles: val }) }}
                        data={roleData.data}
                    />
                </Col>
                {/* <Col className="pl-0">
                    <CommonInput
                        type={"select"}
                        mandatory={true}
                        label={"L2 User"}
                        //   value={filterForm?.startDateBegin}
                        onChange={() => { }}
                    />
                </Col> */}
            </div>
            <div className='d-flex justify-content-between'>
                <Col className="pl-0">
                        <CommonInput
                            type={"number"}
                            allowDecimalvalue={true}
                            mandatory={true}
                            label={"Set L3 approval %"}
                            defaultValue={values.l3ApprovalPercentage}
                            // onChange={(val) => { setValues({ ...values, l3ApprovalPercentage: val }) }}
                            onChange={value => {
                                if (value > 100) {
                                    return;
                                } else {
                                    setValues({ ...values, l3ApprovalPercentage: value })
                                }
                            }}
                            adornmentText="%"
                            placeHolder="Enter"
                        />
                </Col>
                <Col className="pl-0">
                    <CommonInput
                        type={"multiSelectWithCheckboxes"}
                        mandatory={true}
                        label={"L3 Role"}
                        defaultValue={values.l3ApprovalRoles}
                        onChange={(val) => { setValues({ ...values, l3ApprovalRoles: val }) }}
                        data={roleData.data}
                    />
                </Col>
                {/* <Col className="pl-0">
                    <CommonInput
                        type={"select"}
                        mandatory={true}
                        label={"L3 User"}
                        //   value={filterForm?.startDateBegin}
                        onChange={() => { }}
                    />
                </Col> */}
            </div>



        </div>

    )
}
