import React from 'react'
import styles from './styles.module.scss'
import { ReactComponent as TickIcon } from 'assets/img/svg/TickIcon.svg'

const StepperBar = ({ step = 0, setStep = () => { }, confirmationCheck, metaData ={} }) => {
  return (
    <div className={`d-flex ${styles?.stepper_bar_container}`}>
      <div
        className={`${step === 1 ? styles?.active_tab : ''}`}
        onClick={async () => await confirmationCheck() && setStep(1)}
      >
        <span className={`${styles?.stepper_bullet} ${step === 1 ? styles?.active_bullet : step > 1 ? styles?.done_bullet : ''}`}>
          {step > 1 ? <TickIcon /> : 1}
        </span>
        {metaData?.stepOneText||'Print ID card'}
      </div>
      <div
        className={`${step === 2 ? styles?.active_tab : ''}`}
        // onClick={() => setStep(2)}
        style={{ cursor: 'default' }}
      >
        <span className={`${styles?.stepper_bullet} ${step === 2 ? styles?.active_bullet : step > 2 ? styles?.done_bullet : ''}`}>
          {step > 2 ? <TickIcon /> : 2}
        </span>
        {metaData?.stepTwoText||'Map RFIDs'}
      </div>
    </div>
  )
}

export default StepperBar