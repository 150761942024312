import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router';
import { fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import { PermissionContext, RolePermissions } from "appContext";
import { endpoint, pages } from "../../common/constant";
import Preview from './preview';
import { Card, Container } from 'reactstrap';
import CustomLoader from "../../common/commonComponents/Loader/loader";


const ViewUserProfile = (props) => {
  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["createUserProfile"]['id']);

  const history = useHistory();
  const id = props.match.params.id;
  // const project = props.match.params.project;
  const [isFetching, setIsFetching] = useState(false);
  const [ticketDetails, setTicketDetails] = useState({})
  const [userDetails, setUserDetails] = useState({})
  const [projectDetails, setProjectDetails] = useState([])


  const getDetails = async (id) => {
    setIsFetching(true)
    const department = await fetchAllPromisedData(endpoint.departmentType.getAll);
    const designation = await fetchAllPromisedData(endpoint.jobTitle.getAll);
    const details = await fetchAllPromisedData(`${endpoint.getUserByEmployeeId}/${id}`, true);
    if (details && details?.code == 200) {

      setUserDetails({
        "Employee ID": details?.data?.userProfile?.employeeId,
        "Employee Name": `${details?.data?.userProfile?.firstName ??''} ${details?.data?.userProfile?.lastName ?? ''}`,
        "Designation": Array.isArray(designation) ? designation?.filter(el => el?.id == details?.data?.userProfile?.designationId)?.[0]?.['jobTitleDispValue'] ?? '' : '',
        "Account Status": details?.data?.userProfile?.status,
        "Phone Number": details?.data?.userProfile?.mobileNumber,
        // "User Profile Type": details?.data?.userProfile?.employeeId,
        "Department": Array.isArray(department) ? department?.filter(el => el?.id == details?.data?.userProfile?.departmentId)?.[0]?.['departmentTypeDispValue'] ?? '' : '',
        "Email": details?.data?.userProfile?.email,
      })
      setTicketDetails({  ticketId: details?.data?.userProfile?.ticket_id ?? '',
      ticketDate: details?.data?.userProfile?.ticket_date?? ''})

      // const tempObj ={
      //       project: details?.data?.projectKey,
      //       roles:  [details?.data?.roleInformationList?.roleName]
      //   }

        const tempProject = details?.data?.userIdProjectRoleDtoList?.map((el=>{
          // alert(JSON.stringify(projectData.filter(item => item.label === el.clientId)))
          return {
              project : el.clientId,
              roles : [el?.roleInformationDto?.roleName],
          }
      }))

      setProjectDetails(tempProject)
      // setProjectDetails([tempObj])
      
      setIsFetching(false)
    }else{
      history.push('/admin/userProfile')
    } 
  
  }

  useEffect(() => {
    getDetails(id)
  }, [id])

  return (
    <Container fluid>
      {isFetching ? (
        <div className="col">
          <Card className=" w-100">
            <div
              className="mx-auto text-center py-5 my-5 "
              style={{ height: '100vh' }}
            >
              <CustomLoader apiLoader={isFetching} />
            </div>
          </Card>
        </div>
      ) : (
        <>
          <Preview
            isView={true}
            userPermissions={userPermissions}
            ticketDetails={ticketDetails}
            rolesData={projectDetails}
            detailsData={userDetails}

          />
        </>)
      }
    </Container>
  )
}

export default ViewUserProfile