import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { ReactComponent as BackIcon } from 'assets/img/svg/BackButton.svg';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx';
import { useHistory } from 'react-router';
import {
  capitalize,
  fetchAllPromisedData,
  getUrlSearchParams,
  isObjEmpty,
} from '../../../common/utils/methods/commonMethods/utilityMethod';
import AButton from '../../../common/form-fields-mui/AButton';
import CustomChip from 'views/pages/manage/common/customChip/CustomChip';
import ALoader from 'views/pages/manage/common/a-loader';
import { endpoint } from '../../../common/constant';
import { customChipStyles, restructureResponse } from './constant';
import moment from 'moment';
import CustomTooltip from 'views/pages/manage/common/customTooltip/CustomTooltip';
import { BiInfoCircle } from 'react-icons/bi';
import CustomCopyToClipboard from '../../../common/ACopyToClipboard/CustomCopyToClipboard';
import StockReversalTable from './StockReversalTable';
import StockReversalDialog from './StockReversalDialog';
import ReversalNotAllowedDialog from './ReversalNotAllowedDialog';
import IssueStockSuccessPrompt from '../selectMeterialAndStock/issueStock/IssueStockSuccessPrompt';

const StockReversal = () => {
  const history = useHistory();
  const { academicCareer, transactionId, businessAreaId, businessAreaValue } = getUrlSearchParams(history?.location?.search);
  const [transactionDetails, setTransactionDetails] = useState({});
  const [apiLoader, setApiLoader] = useState(false);
  const [isReversalDialogOpen, setIsReversalDialogOpen] = useState(false);
  const [notAllowedDialogOpen, setNotAllowedDialogOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  // console.log(transactionDetails);

  const fetchTransactionDetails = async () => {
    setApiLoader(true);

    try {
      const searchParams = `?businessArea=${businessAreaId}&transactionId=${transactionId}`;
      const res = await fetchAllPromisedData(
        `${endpoint?.studentSearch?.getStockTransactionDetails}${searchParams}`,
        true
      );
      if (res?.code === 200) {
        setTransactionDetails(restructureResponse(res?.data, academicCareer));
      } else {
        setTransactionDetails({});
      }
    } catch (error) {
      console.error(error);
    } finally {
      setApiLoader(false);
    }
  };

  const handleStockReverse = () => {
    transactionDetails?.isEligibleForReversal
      ? setIsReversalDialogOpen(true)
      : setNotAllowedDialogOpen(true);
  };

  const handleSuccess = async successRes => {
    fetchTransactionDetails();
    setIsSuccessDialogOpen(successRes);
  };

  useEffect(() => {
    fetchTransactionDetails();
  }, [academicCareer, transactionId, businessAreaId]);

  return (
    <>
      <div className='remove-container-top-space min-page-height'>
        {apiLoader && <ALoader position='fixed' />}
        <div className={`${styles['page-header']}`}>
          <div className='heading-4 color-dark'>
            <IconButtonWrapper onClick={() => window.close()}>
              <BackIcon />
            </IconButtonWrapper>
            <div>
              {`Stock reverse for transaction ID: ${transactionId || '-'} | ${
                businessAreaValue || '-'
              }`}
            </div>
          </div>
          <div className='flex-shrink-0'>
            <CustomChip style={customChipStyles}>
              <div>
                {`Issue details: ${moment(
                  transactionDetails?.issueDate
                )?.format('DD-MMM-YYYY')}`}
              </div>
              <CustomTooltip
                title={`${moment(transactionDetails?.issueDate)?.format(
                  'DD-MMM-YYYY'
                )}, ${moment(transactionDetails?.issueDate)?.format(
                  'hh:mm A'
                )} by ${transactionDetails?.createdBy}`}
              >
                <div className='ml-2 d-flex cursor'>
                  <BiInfoCircle size={'1rem'} />
                </div>
              </CustomTooltip>
            </CustomChip>

            <CustomChip style={customChipStyles}>
              <div>
                {`SAP document no.: ${transactionDetails?.sapDocId || '-'}`}
                <CustomCopyToClipboard
                  textToCopy={transactionDetails?.sapDocId}
                  tooltipTheme='light'
                />
              </div>
            </CustomChip>

            <CustomChip
              style={customChipStyles}
              bgColor='#BBE2CE'
              textColor='#0B442D'
              text={capitalize(transactionDetails?.status)}
            />
            <AButton
              variant='primary_filled'
              size='xs'
              className='ml-0'
              onClick={handleStockReverse}
              disabled={transactionDetails?.isReversed}
            >
              {transactionDetails?.isReversed
                ? 'Stock reversed'
                : 'Reverse stock'}
            </AButton>
          </div>
        </div>

        <div className={`${styles['content-container']}`}>
          <StockReversalTable rows={transactionDetails?.applicationDetails} />
        </div>
      </div>
      <StockReversalDialog
        open={isReversalDialogOpen}
        setOpen={setIsReversalDialogOpen}
        transactionId={transactionId}
        transactionDetails={transactionDetails}
        handleSuccess={handleSuccess}
      />
      <ReversalNotAllowedDialog
        open={notAllowedDialogOpen}
        setOpen={setNotAllowedDialogOpen}
      />
      <IssueStockSuccessPrompt
        isOpen={!isObjEmpty(isSuccessDialogOpen)}
        setIsOpen={setIsSuccessDialogOpen}
        responseData={isSuccessDialogOpen}
        isReturn={true}
        message={'Your reverse ID'}
        headerMessage={'Stock reversed successfully'}
      />
    </>
  );
};

export default StockReversal;
