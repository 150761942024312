export const commonMessages = {
    pleaseDeleteSaveCurrentChnage : "Please Delete or Save current changes",
    pleaseSelectProcessForRecords: "*Select process to see the records",
    getStudentId : "Please enter atlease 3 character to search",
    getApplicationIdOnStudentId : "Please select Student ID to get options",
    getProceesTypeByPerson : "Please select Person Type to get options",
    pricingUploadCloseConfirmation: "You have unsaved data on this page. Once closed it will be lost forever!",
    pricingUploadSuccessfullMessage: "File uploaded successfully. Data is in processing and you will be notified via Email once process is completed", 
    pricingErrorFileNotGenerated: "Something went wrong on download, please try again after sometime. If the issue persists, contact the support team.",
    passwordRule: "Password must be of Minimum 8 chararcters and maximum 12 characters and it should  include minimum 1 special char, 1 digit,1 lower case, 1 uppercase."
}