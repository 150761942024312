import {UPDATE_DATA} from "../types";
import {updateUser} from "./userAction";
const UPDATE_USER_AUTH = 'UPDATE_USER_AUTH';
const UPDATE_USER_PERMISSION = 'UPDATE_USER_PERMISSION';
const LOGOUT_USER = 'LOGOUT_USER';
const initialState = {
    isAuth : true,
    role : {
        roleType : 'viewer',
        permissions : {}
    }
}
const userReducer =(state=initialState, action = updateUser)=>{
    switch(action.type){
        // case UPDATE_USER_AUTH :return{
        //     ...state,
        // }
        //
        case UPDATE_USER_PERMISSION :
            return {
            ...state,
            role : action.role
        }
        case LOGOUT_USER :
            return {
                ...state,
                isAuth : false,
                role : null
            }

        default:return state
    }
}
export default userReducer;