import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router';
import { FormGenerator } from '../../common/commonComponents/formGenerator';
import { PermissionContext, RolePermissions } from "appContext";
import { CustomContainer, Accordion, Dialog } from "views/pages/manage/common/commonComponents";
import fieldObjects from './addForm.json';
import { Card, Container, Row } from 'reactstrap';
import { constants, endpoint, pages } from '../../common/constant';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import CustomButton from '../../../../../components/CustomButton';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import CustomLoader from '../../common/commonComponents/Loader/loader';
import { putpost } from 'services/utils';


const CreateSecurityGroup = (props) => {

    const { action, id } = props.match?.params ?? {action: '', id: ''};

    const {isView, viewId} = props;

    const history = useHistory();
    const permissions = useContext(PermissionContext);
    const userPermissions = RolePermissions(permissions, pages["securityGroup"]['id']);

    const [securityGroup, setSecurityGroup] = useState({
        securityGroupName: '',
        securityGroupDescription: '',
        status: { label: 'ACTIVE', value: 'ACTIVE' },

    })

    let [securitygValues, setSecurityValues] = useState({
        businessGroup: [],
        companyCode: [],
        operationalZone: [],
        region: [],
        businessAreaType: [],
        businessArea: [],
        career: [],
        language: [],
        cashierOffice: [],
        academicGroup: [],
        programAction: [],
    })

    // const [statusData] = useState([...constants.status])
    const [groupCodeData, setGroupCodeData] = useState({ fetching: true, data: [] });
    const [companyCodeData, setCompanyCodeData] = useState({ fetching: true, data: [] });
    const [operationalZoneData, setOperationalZoneData] = useState({ fetching: true, data: [] });
    const [regionData, setRegionData] = useState({ fetching: true, data: [] });
    const [businessAreaTypeData, setBusinessAreaTypeData] = useState({ fetching: true, data: [] });
    const [businessAreaData, setBusinessAreaData] = useState({ fetching: true, data: [] });
    const [careerData, setCareerData] = useState({ fetching: true, data: [] });
    const [languageData, setLanguageData] = useState({ fetching: true, data: [] });
    const [cashierOfficeData, setcashierOfficeData] = useState({ fetching: true, data: [] });
    const [acadmicGroupData, setAcadmicGroupData] = useState({ fetching: true, data: [] });
    const [programActionData, setprogramActionData] = useState({ fetching: true, data: [] });

    const [isFetching, setFetching] = useState(true)
    const [isPreviewEnable, setPreviewEnable] = useState(false)

    const [idsFromServer, setIdsFromServer] = useState(new Date())

    const [prevData, setPrevData] = useState({})

    const getFormatedDropdownData = (list, labelProperty) => {
        if (Array.isArray(list)) {
            const tempArr = list?.map(d => { return { label: d?.[labelProperty], value: d.id } })
            return { fetching: false, data: tempArr?.length ? [{ label: 'ALL', value: '*' }, ...tempArr] : [] }
        } else {
            return { fetching: false, data: [] }
        }
    }

    const initailData = async () => {
        const groupCode = await fetchAllPromisedData(endpoint.groupCode.getAllActive);
        // const companyCode = await fetchAllPromisedData(endpoint.companyCode.getAllActive)
        // const operationalZone = await fetchAllPromisedData(endpoint.operationalZone.getAllActive);
        const region = await fetchAllPromisedData(endpoint.region.getAllActive);
        const businessAreaType = await fetchAllPromisedData(endpoint.businessType.getAllActiveUnitType); //need api
        // const businessArea = await fetchAllPromisedData(`${endpoint.businessArea.getAllActive}`);
        // const Career = await fetchAllPromisedData(endpoint.academic_career.getAllActive);
        const language = await fetchAllPromisedData(endpoint.languageMaster.getActiveLanguageMaster);
        // const cashierOffice = await fetchAllPromisedData(endpoint.cashierOffice.getAllActive); //need api
        // const acadmicGroup = await fetchAllPromisedData(endpoint.academic_group.getAllActive);
        const programAction = await fetchAllPromisedData(endpoint.programAction.getAllActive);

        setGroupCodeData(getFormatedDropdownData(groupCode, 'groupCodeDispValue'))
        // setCompanyCodeData(getFormatedDropdownData(companyCode, 'companyCodeDispValue'))
        // setOperationalZoneData(getFormatedDropdownData(operationalZone, 'operationalZoneDispValue'))
        setRegionData(getFormatedDropdownData(region, 'regionDispValue'))
        setBusinessAreaTypeData(getFormatedDropdownData(businessAreaType, 'businessUnitTypeDispValue'))
        // setBusinessAreaData(getFormatedDropdownData(businessArea, 'businessAreaDispValue'))
        // setCareerData(getFormatedDropdownData(Career, 'academicCareerDispValue'))
        setLanguageData(getFormatedDropdownData(language, 'languageDispValue'))
        // setcashierOfficeData(getFormatedDropdownData(cashierOffice, 'cashierOfficeDispValue'))
        // setAcadmicGroupData(getFormatedDropdownData(acadmicGroup, 'academicGroupDispValue'))
        setprogramActionData(getFormatedDropdownData(programAction, 'programActionDispValue'))

        // if(action === 'view' || action === 'edit'){
        //     getSaveSecurityGroup()
        // }
        if (action === 'new') {
            setFetching(false)
        }
    }

    const getAllValueSelected = (arr) => {
        // console.log(arr)
        if (Array.isArray(arr)) {
            const newArr = arr && arr?.map(item => {
                return item.value !== '*' ? item.value : null
            })
            const finalArr = newArr.filter(el => el)
            return finalArr
        } else {
            return []
        }
    }

    const getRequestForEdit = (oldList, newList) => {
        let _tempObject = []
        let _oldList = [...oldList]
        let _newList = [...newList]
        _oldList.forEach(element => {
            if (_newList.indexOf(element) > -1) {
                _tempObject = [..._tempObject, { id: element, status: 'ACTIVE' }];
                _newList.splice(_newList.indexOf(element), 1)
            } else {
                _tempObject = [..._tempObject, { id: element, status: 'DELETED' }]
            }
        });
        _newList.forEach(el => {
            _tempObject = [..._tempObject, { id: el, status: 'INSERTED' }];
        })
        return _tempObject
    }

    const saveHandler = async () => {

        let isValid = true;
        if (!securityGroup.securityGroupName || !securityGroup.securityGroupName.toString().trim()) {
            failureToast(fieldObjects.error['securityGroupName']);
            return true
        }
        if (!securityGroup.securityGroupDescription || !securityGroup.securityGroupDescription.toString().trim()) {
            failureToast(fieldObjects.error['securityGroupDescription']);
            return true
        }
        Object.keys(securitygValues).every(item => {
            if (!securitygValues[item]?.length && item !== 'cashierOffice') {
                failureToast(fieldObjects.error[item]);
                isValid = false
                return false
            } else {
                return true
            }
        })

        if (!isValid) {
            return
        }

        setFetching(true)

        let request = {}

        if (action === 'new') {
            request = {
                "securityGroupDtoList": [{
                    "securityGroupName": securityGroup.securityGroupName.toString().toUpperCase(),
                    "securityGroupDescription": securityGroup.securityGroupDescription,
                    "status": securityGroup.status?.value,
                    "businessGroup": getAllValueSelected(securitygValues.businessGroup),
                    "companyCode": getAllValueSelected(securitygValues.companyCode),
                    "operationalZone": getAllValueSelected(securitygValues.operationalZone),
                    "region": getAllValueSelected(securitygValues.region),
                    "businessAreaType": getAllValueSelected(securitygValues.businessAreaType),
                    "businessArea": getAllValueSelected(securitygValues.businessArea),
                    "career": getAllValueSelected(securitygValues.career),
                    "language": getAllValueSelected(securitygValues.language),
                    "cashierOffice": getAllValueSelected(securitygValues.cashierOffice),
                    "academicGroup": getAllValueSelected(securitygValues.academicGroup),
                    "programAction": getAllValueSelected(securitygValues.programAction)
                }]
            }
        } else {
            request = {
                "id": id,
                "securityGroupName": securityGroup.securityGroupName.toString().toUpperCase(),
                "securityGroupDescription": securityGroup.securityGroupDescription,
                "status": securityGroup.status?.value,
                "businessGroup": getRequestForEdit(getAllValueSelected(prevData.businessGroup), getAllValueSelected(securitygValues.businessGroup)),
                "companyCode": getRequestForEdit(getAllValueSelected(prevData.companyCode), getAllValueSelected(securitygValues.companyCode)),
                "operationalZone": getRequestForEdit(getAllValueSelected(prevData.operationalZone), getAllValueSelected(securitygValues.operationalZone)),
                "region": getRequestForEdit(getAllValueSelected(prevData.region), getAllValueSelected(securitygValues.region)),
                "businessAreaType": getRequestForEdit(getAllValueSelected(prevData.businessAreaType), getAllValueSelected(securitygValues.businessAreaType)),
                "businessArea": getRequestForEdit(getAllValueSelected(prevData.businessArea), getAllValueSelected(securitygValues.businessArea)),
                "career": getRequestForEdit(getAllValueSelected(prevData.career), getAllValueSelected(securitygValues.career)),
                "language": getRequestForEdit(getAllValueSelected(prevData.language), getAllValueSelected(securitygValues.language)),
                "cashierOffice": getRequestForEdit(getAllValueSelected(prevData.cashierOffice), getAllValueSelected(securitygValues.cashierOffice)),
                "academicGroup": getRequestForEdit(getAllValueSelected(prevData.academicGroup), getAllValueSelected(securitygValues.academicGroup)),
                "programAction": getRequestForEdit(getAllValueSelected(prevData.programAction), getAllValueSelected(securitygValues.programAction))
            }
        }

        // const url = action === 'edit' ? `${endpoint.securityGroupMaster.create}/${id}` : endpoint.securityGroupMaster.create;
        const method = action === 'edit' ? 'put' : 'post';
        // const response = await fetchAllPostPromisedData(endpoint.securityGroup.common, request, 'post')
        // if (response?.code === 200) {
        //     // setSubmitting(true)
        //     successToast(response['message'])
        //     // reset()
        //     history.push('/admin/securityGroup')
        //     setFetching(false)

        // } else {
        //     // setSubmitting(false)
        //     failureToast(response['message']);
        //     setFetching(false)
        //     alert("ji")
        // }

        putpost(
            endpoint.securityGroup.common,
            (data) => {
                if (data?.code == 200) {
                    successToast(data['message'])
                    history.push('/admin/securityGroup')
                    setFetching(false)
                }
            },
            (data) => {
                failureToast(data['message']);
                setFetching(false)
            },
            request,
            method
        );

    }

    useEffect(() => {
        initailData()
    }, [])


    const getDependMasterValue = async (ids, setter, endpoint, parentKey, childKey) => {
        setter({ fetching: true, data: [] })
        const data = await fetchAllPostPromisedData(endpoint, ids, 'post');
        if (data?.code === 200 && data?.data) {
            let tempArr = data.data.map(item => {
                if(action === 'new' && item.status === 'ACTIVE'){
                    return { label: item[childKey], value: item.id, parentId: item[parentKey] }
                }

                if(action==='view' || action === 'edit' || isView){
                    return { label: item[childKey], value: item.id, parentId: item[parentKey] }
                }
            })

            tempArr = tempArr.filter(item => item)

            setter({ fetching: false, data: tempArr?.length ? [{ label: 'ALL', value: '*' }, ...tempArr] : [] })
        }
    }

    useEffect(() => {
        if (action === 'new') {
            securitygValues.companyCode = []
            securitygValues.career = []
            securitygValues.academicGroup = []
            securitygValues.businessArea = []
            setSecurityValues({
                ...securitygValues,
            })

        }

        if (securitygValues.businessGroup?.length > 0) {
            getDependMasterValue(getAllValueSelected(securitygValues.businessGroup), setCompanyCodeData, endpoint.companyCode.getByGrpCodeIds, 'groupCodeId', 'companyCodeDispValue')
        } else {
            setCompanyCodeData({ fetching: false, data: [] })
        }

    }, [securitygValues.businessGroup])

    useEffect(() => {
        if (action === 'new') {
            securitygValues.career = []
            securitygValues.academicGroup = []
            securitygValues.businessArea = []
            setSecurityValues({
                ...securitygValues,
            })

        }

        if (securitygValues.companyCode?.length > 0) {
            getDependMasterValue(getAllValueSelected(securitygValues.companyCode), setCareerData, endpoint.academic_career.getAllByCompanyCodeIds, 'companyCodeId', 'academicCareerDispValue')
        } else {
            setCareerData({ fetching: false, data: [] })
        }

        if (securitygValues.companyCode?.length > 0) {
            getDependMasterValue(getAllValueSelected(securitygValues.companyCode), setAcadmicGroupData, endpoint.academic_group.getAllByCompanyCodeIds, 'companyCodeId', 'academicGroupDispValue')
        } else {
            setAcadmicGroupData({ fetching: false, data: [] })
        }
    }, [securitygValues.companyCode])


    useEffect(() => {
        if (action === 'new') {
            securitygValues.operationalZone = []
            securitygValues.businessArea = []
            setSecurityValues({
                ...securitygValues,
            })
        }
        if (securitygValues.region?.length > 0) {
            getDependMasterValue(getAllValueSelected(securitygValues.region), setOperationalZoneData, endpoint.operationalZone.getAllByRegionIds, 'regionId', 'operationalZoneDispValue')
        } else {
            setOperationalZoneData({ fetching: false, data: [] })
        }
    }, [securitygValues.region])


    useEffect(() => {
        if (action === 'new') {
            securitygValues.businessArea = []
            setSecurityValues({
                ...securitygValues,
            })
        }

        if (securitygValues.businessGroup.length > 0 && securitygValues.companyCode.length > 0 && securitygValues.region.length > 0 && securitygValues.businessAreaType.length > 0) {
            getDependMasterValue(
                {
                    "groupCode": getAllValueSelected(securitygValues.businessGroup),
                    "companyCode": getAllValueSelected(securitygValues.companyCode),
                    "region": getAllValueSelected(securitygValues.region),
                    "businessUnitType": getAllValueSelected(securitygValues.businessAreaType)
                },
                setBusinessAreaData,
                endpoint.businessArea.getByCriteria,
                'parentId',
                'businessAreaDispValue'
            )
        } else {
            setBusinessAreaData({ fetching: false, data: [] })
        }
    }, [securitygValues.businessGroup, securitygValues.companyCode, securitygValues.region, securitygValues.businessAreaType])

    const getValue = (list, idList) => {

        const i = list && idList ? list?.data?.filter(item => idList.indexOf(item.value) > -1) : []
        return i
    }

    //values for view and edit depeded master
    useEffect(() => {
        if(companyCodeData?.data?.length > 0 && idsFromServer.companyCode && (action === 'edit' || action === 'view' || isView)){
            securitygValues.companyCode = getValue(companyCodeData, idsFromServer.companyCode) 
            setSecurityValues({...securitygValues})
            setPrevData({...prevData,companyCode: getValue(companyCodeData, idsFromServer.companyCode)})
            setIdsFromServer({...idsFromServer, companyCode : null})
        }
        if(!idsFromServer.companyCode && (action === 'edit' || action === 'view' || isView)){
            securitygValues.companyCode = getValue(companyCodeData, idsFromServer.companyCode) 
            setSecurityValues({...securitygValues})
        }
    },[companyCodeData])

    useEffect(() => {
        if(careerData?.data?.length > 0 && idsFromServer.career && (action === 'edit' || action === 'view' || isView)){
            securitygValues.career = getValue(careerData, idsFromServer.career) 
            setSecurityValues({...securitygValues})
            setPrevData({...prevData,career: getValue(careerData, idsFromServer.career)})
            setIdsFromServer({...idsFromServer, career : null})
        }
        if(!idsFromServer.career && (action === 'edit' || action === 'view' || isView)){
            securitygValues.career = getValue(careerData, idsFromServer.career) 
            setSecurityValues({...securitygValues})
        }
    },[careerData])

    useEffect(() => {
        if(acadmicGroupData?.data?.length > 0 && idsFromServer.academicGroup && (action === 'edit' || action === 'view' || isView)){
            securitygValues.academicGroup = getValue(acadmicGroupData, idsFromServer.academicGroup) 
            setSecurityValues({...securitygValues})
            setPrevData({...prevData,academicGroup: getValue(acadmicGroupData, idsFromServer.academicGroup)})
            setIdsFromServer({...idsFromServer, academicGroup : null})
        }

        if(!idsFromServer.academicGroup && (action === 'edit' || action === 'view' || isView)){
            securitygValues.academicGroup = getValue(acadmicGroupData, idsFromServer.academicGroup) 
            setSecurityValues({...securitygValues})
        }
    },[acadmicGroupData])

    useEffect(() => {
        if(operationalZoneData?.data?.length > 0 && idsFromServer.operationalZone && (action === 'edit' || action === 'view' || isView)){
            securitygValues.operationalZone = getValue(operationalZoneData, idsFromServer.operationalZone) 
            setSecurityValues({...securitygValues})
            setPrevData({...prevData,operationalZone: getValue(operationalZoneData, idsFromServer.operationalZone)})
            setIdsFromServer({...idsFromServer, operationalZone : null})
        }

        if(!idsFromServer.operationalZone && (action === 'edit' || action === 'view' || isView)){
            securitygValues.operationalZone = getValue(operationalZoneData, idsFromServer.operationalZone) 
            setSecurityValues({...securitygValues})
        }
    },[operationalZoneData])

    useEffect(() => {
       
        if(businessAreaData?.data?.length > 0 && idsFromServer.businessArea && (action === 'edit' || action === 'view' || isView)){
            securitygValues.businessArea = getValue(businessAreaData, idsFromServer.businessArea) 
            setSecurityValues({...securitygValues})
            setPrevData({...prevData,businessArea: getValue(businessAreaData, idsFromServer.businessArea)})
            setIdsFromServer({...idsFromServer, businessArea : null})
        }

        if(!idsFromServer.businessArea && (action === 'edit' || action === 'view' || isView)){
            securitygValues.businessArea = getValue(businessAreaData, idsFromServer.businessArea) 
            setSecurityValues({...securitygValues})
        }
    },[businessAreaData])
    //values for view and edit depeded master
 

    const getSaveSecurityGroup = async () => {
        const dataArr = {
            businessGroup: groupCodeData,
            companyCode: companyCodeData,
            operationalZone: operationalZoneData,
            region: regionData,
            businessAreaType: businessAreaTypeData,
            businessArea: businessAreaData,
            career: careerData,
            language: languageData,
            cashierOffice: cashierOfficeData,
            academicGroup: acadmicGroupData,
            programAction: programActionData
        }

        const idMapping = await fetchAllPromisedData(`${endpoint.securityGroup.params}`, true)
        let newMap = {};
        let tempObj = {};
        let objectFromBackend = {};
        if (idMapping?.code == 200) {
            idMapping.data.forEach(element => {
                newMap = { ...newMap, [element?.securityGroupMasterDisplayName]: element.id }
            });
        }


        const response = await fetchAllPromisedData(`${endpoint.securityGroup.common}/${isView ? viewId :id}`, true)
        if (response?.code == 200) {
            Object.keys(newMap).map((item) => {
                const id = newMap[item];
                const arr = response.data.securityGroupParamValueMapping.map((s) => {
                    return s.securityGroupParamMasterId === id ? s.securityGroupParamMasterValueId : null
                })
                const filteredArr = arr.filter(s => s)

                objectFromBackend = {...objectFromBackend,[item]: filteredArr}

                tempObj = { ...tempObj, [item]: getValue(dataArr[item], filteredArr) }
                // console.log(businessAreaData)
            })

            setSecurityGroup({
                securityGroupName: response.data.securityGroupName,
                securityGroupDescription: response.data.securityGroupDescription,
                status: {
                    label: response.data.status
                    , value: response.data.status
                },
            })
            setIdsFromServer({...objectFromBackend})
            setSecurityValues({ ...tempObj })
            setPrevData({ ...tempObj })
            setFetching(false)
        }
    }

    useEffect(() => {
        if (
            (action === 'view' || action === 'edit' || isView) &&
            (id || viewId) &&
            !groupCodeData?.fetching &&
            !regionData?.fetching &&
            !businessAreaTypeData?.fetching &&
            !languageData?.fetching &&
            !programActionData?.fetching
        ) {
            getSaveSecurityGroup()
            if (action !== 'edit' || isView) {
                setPreviewEnable(true)
            }
        }

        if (action === 'new') {
            // setFetching(false)
        }

    }, [
        action,
        id,
        isView,
        viewId,
        groupCodeData,
        regionData,
        businessAreaTypeData,
        languageData,
        programActionData
    ])

    return (
        <Container fluid>
            {isFetching ? (
                <div className="col">
                    <Card className=" w-100">
                        <div
                            className="mx-auto text-center py-5 my-5 "
                            style={{ height: '100vh' }}
                        >
                            <CustomLoader apiLoader={isFetching} />
                        </div>
                    </Card>
                </div>
            ) : (
                <>
                    <Accordion title="Define Security Group" isFormOpen={true}>
                        <Row className="pl-4 pr-4 pb-2 pt-2 course-fee-header">
                            <FormGenerator
                                fields={Object.keys(fieldObjects.securitygGroup)}
                                fieldsObject={fieldObjects.securitygGroup}
                                values={securityGroup}
                                setValues={setSecurityGroup}
                                dataObjects={{ statusData: { data: constants.status } }}
                                isPreviewEnable={isPreviewEnable}
                                disabled={isFetching}
                                isEditMode={action === 'edit' || isView ? true : false}
                            />
                        </Row>

                    </Accordion>

                    <Accordion title="Security Values" isFormOpen={true}>
                        <Row className="p-4 course-fee-header">
                            <FormGenerator
                                fields={action=='view'? Object.keys(fieldObjects.securityValuesView): Object.keys(fieldObjects.securityValues)}
                                fieldsObject={action=='view'? fieldObjects.securityValuesView: fieldObjects.securityValues}
                                values={securitygValues}
                                setValues={setSecurityValues}
                                dataObjects={{
                                    businessGroupData: groupCodeData,
                                    companyCodeData: companyCodeData,
                                    operationalZoneData: operationalZoneData,
                                    regionData: regionData,
                                    businessAreaTypeData: businessAreaTypeData,
                                    businessAreaData: businessAreaData,
                                    careerData: careerData,
                                    languageData: languageData,
                                    cashierOfficeData: cashierOfficeData,
                                    acadmicGroupData: acadmicGroupData,
                                    programActionData: programActionData
                                }}
                                isPreviewEnable={isPreviewEnable}
                                disabled={isFetching}
                            // isEditMode={action === 'edit' ? true : false}
                            />
                        </Row>


                    </Accordion>

                    <Row className="justify-content-end pl-4 pr-4 pb-4">
                    {!isPreviewEnable && <CustomButton
                            content={isFetching ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : "Save"}
                            permissionType={'C,U'}
                            permissionSet={userPermissions}
                            onClick={saveHandler}
                            disabled={isFetching || !securityGroup.securityGroupName || !securityGroup.securityGroupDescription || !securitygValues.businessGroup?.length || !securitygValues.companyCode?.length || !securitygValues?.region.length || !securitygValues.operationalZone?.length || !securitygValues.businessAreaType?.length || !securitygValues.career?.length || !securitygValues.language?.length || !securitygValues.academicGroup?.length || !securitygValues.programAction?.length }
                        />}
                        {!isView && <CustomButton
                            type="cancel"
                            content={"Close"}
                            permissionType={'cancel'}
                            permissionSet={userPermissions}
                            disabled={false}
                            onClick={() => { history.push('admin/securityGroup') }}
                        />}
                    </Row>
                </>)}
        </Container>
    )
}

export default CreateSecurityGroup