import React from "react";
import "./style.scss";

const PrintSlipCard = ({ students }) => {
  return (
    <div className="print-slip-page-layout">
      <style type="text/css" media="print">
        {
          "\
  @page { size: 22cm 35.56cm;margin: 1mm 1cm }\
"
        }
      </style>
      {students.map((student, index) => {
        return (
          <div
            className={`slip-container ${
              (index + 1) % 16 === 0 ? "page-break" : ""
            }`}
            style={
              (index + 1) % 2
                ? { paddingLeft: "2rem" }
                : { paddingRight: "2rem" }
            }
            key={student?.psid}
          >
            <div>
              <div className="bold d-flex align-items-start justify-content-between gap-md">
                <div className="">PS. ID.: {student?.psid}</div>
                <div className="word-break-all">
                  {student?.dispatchId || "-"}
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-start gap-md">
                <div className="">{student?.studentName?.toUpperCase()}</div>
                <div>S.No.{index + 1}</div>
              </div>
              <div className="three-lines-container">{student?.address}</div>
              <div>Ph.: {student?.contact}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PrintSlipCard;
