import { Dialog } from '@mui/material';
import React, { useState } from 'react';
import ALoader from '../../../common/a-loader';
import AAutoComplete from 'views/pages/manage/common/form-fields-mui/AAutoComplete';
import AButton from '../../../common/form-fields-mui/AButton';
import { ReactComponent as CrossIcon } from 'assets/img/svg/CrossIcon.svg';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx';
import moment from 'moment';
import { reversalReasonsDropdown } from './constant';
import { selectStyle, transactionTypeMap } from '../stockReturn/constant';
import ATextarea from '../../../common/formFeilds/customInput/ATextarea';
import styles from './styles.module.scss';
import { fetchAllPostPromisedData } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../../common/constant';
import { failureToast, successToast } from '../../../common/utils/methods/toasterFunctions/function';

const StockReversalDialog = ({
  open,
  setOpen,
  transactionId,
  transactionDetails,
  handleSuccess,
}) => {
  const [apiLoader, setApiLoader] = useState(false);
  const [form, setForm] = useState({});
  const formHandler = (value, key) =>
    setForm(prev => ({ ...prev, [key]: value }));
  const handleClose = () => {
    setOpen(false);
    setForm({});
  };

  const submitHandler = async () => {
    setApiLoader(true);

    const reqBody = {
      type: transactionTypeMap['PSID_REVERSAL'][transactionDetails?.academicCareer],
      academicCareerDispValue: transactionDetails?.academicCareer==='DIGITAL' ? 'ICON' : transactionDetails?.academicCareer,
      plantId: transactionDetails.plantId,
      businessArea: transactionDetails?.businessArea,
      businessAreaDispValue: transactionDetails?.businessAreaValue,
      costCenter: transactionDetails?.costCenter,
      reason: form?.reason?.value,
      psidStockRequest: transactionDetails?.applicationDetails?.map(applicationData => ({
        psid: applicationData?.psid,
        applicationId: applicationData?.applicationId,
        dispatchScheduleId: applicationData?.dispatchScheduleId,
        remark: form?.remark?.trim() || '',
        stock: applicationData?.materialDetails?.map(item => ({
          barcode: item?.barcode,
          materialId: item?.materialNumber,
          materialDescription: item?.materialDesc,
          uom: item?.uom,
          serialNumberProfile: item?.serialNumberProfile,
        })),
      }))
    }
    // console.log(reqBody);
    
    try {
      const res = await fetchAllPostPromisedData(
        endpoint?.studentSearch?.psidStockReturn,
        reqBody,
        'post',
      );
      if (res?.code === 200) {
        successToast('Request completed successfully');
        handleSuccess(res?.data);
        handleClose();
      } else {
        res?.message && failureToast(
          `${res?.message} Return ID: ${res?.data?.transactionId} failed. Retry`
        );
        setForm({});
      }
    } catch (error) {
      console.error(error)
    } finally {
      setApiLoader(false);
    }
  };

  return (
    <Dialog
      open={open}
      PaperProps={{ sx: { maxWidth: 'unset', borderRadius: '12px' } }}
    >
      <div className={`${styles['reversal-dialog']}`}>
        {apiLoader && <ALoader />}

        <div>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='heading-4 color-black'>Reverse stock</div>
            <IconButtonWrapper onClick={handleClose}>
              <CrossIcon width={24} height={24} />
            </IconButtonWrapper>
          </div>
          <div className='mt-4 color-black large-semi-bold'>
            {`Reversing stocks for transaction ID ${transactionId || '-'} | ${
              transactionDetails?.businessAreaValue || '-'
            }`}
          </div>
          <div className='mt-1'>
            {`Issue date ${moment(transactionDetails?.issueDate)?.format(
              'DD-MMM-YYYY'
            )} | SAP document no.: ${transactionDetails?.sapDocId}`}
          </div>
        </div>

        <div>
          <AAutoComplete
            placeholder='Search a reason'
            isMulti={false}
            items={reversalReasonsDropdown}
            currentValue={form?.reason || ''}
            handleChange={value => {
              formHandler(value, 'reason');
              formHandler('', 'remark');
            }}
            style={selectStyle}
          />
        </div>

        <div>
          <ATextarea
            label={`Add Remark`}
            rows={3}
            style={{ fontSize: '16px', height: '80px' }}
            value={form?.remark || ''}
            onChange={value => formHandler(value, 'remark')}
          />
        </div>

        <div>
          <AButton
            className='ml-0'
            size='sm'
            variant='primary_filled'
            onClick={() => submitHandler()}
            disabled={
              !form?.reason ||
              (form?.reason?.value === 'Others' && !form?.remark?.trim())
            }
          >
            Confirm reverse
          </AButton>
        </div>
      </div>
    </Dialog>
  );
};

export default StockReversalDialog;
