import React, { useState } from "react";
import ALoader from "views/pages/manage/common/a-loader";
import LectureList from "./LectureList";
import LectureSubHeader from "./LectureSubHeader";
import moment from "moment";

export const updateMsTeam = (lectures = [], synWithMsTeam = false) => {

   const  capitalizeFirstLetter = (string) =>{
      if(string)
         return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
   }
  return lectures.map((lecture) => ({
    ...lecture,
    msTeam: { label: synWithMsTeam?"Yes":"No", value: synWithMsTeam?"Yes":"No" },
    endTime: moment(lecture?.endTime, "HH:mm:ss").format("HH:mm"),
    startTime: moment(lecture?.startTime, "HH:mm:ss").format("HH:mm"),
    subject : capitalizeFirstLetter(lecture.subject)
  }));
};

const PlanLectures = ({
  setOpenForm,
  formData,
  formHandler,
  batchDetails,
  lectures,
  setLectures,
  ...props
}) => {
  // In this file lectures comes from autoPopulate lectures api
  const OPEN_MODE = props?.mode || "NEW";
  const [apiLoader, setApiLoader] = useState(false);
  // const requestForAutoPopulate = getAutoPopulateRequest(
  //   formData,
  //   props?.businessAreaId,
  //   props?.batchId,
  //   props.subjectForm,
  //   OPEN_MODE
  // );

  // useEffect(() => {
  //   if (requestForAutoPopulate.academicPlanId)
  //     autoPopulateLectures(requestForAutoPopulate).then((res) => {
  //       setLectures(removeBreaksFromEnd(updateMsTeam(res?.data)));
  //     });
  // }, [requestForAutoPopulate.academicPlanId]);

  const onLectureUpdate = (lecturesData, index) => {
    setLectures(lecturesData);
  };

  const publishTimetable = () => {
    props.publishTimetable(props.planTests, lectures);
  };

  return (
    <div
      style={{ position: "relative" }}
    >
      {apiLoader && <ALoader position={"fixed"} />}
      <LectureSubHeader
        openMode={OPEN_MODE}
        setOpenForm={setOpenForm}
        publishTimetable={publishTimetable}
        lectures={lectures}
        formData={formData}
        batchDetails={batchDetails}
      />

      <LectureList
        lectures={lectures}
        updateLecturesArray={onLectureUpdate}
        setOpenForm={setOpenForm}
        roomsDropdown={props.roomsDropdown}
        // testUpdateHandler={testUpdateHandler}
        publishTimetable={props?.publishTimetable}
        openMode={OPEN_MODE}
        currentRoom={formData?.room}
        formData={formData}
        setLectures={setLectures}
        batchDetails={batchDetails}
        {...props}
      />
    </div>
  );
};

export default PlanLectures;
