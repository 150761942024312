import React, { useState, useRef, useEffect } from 'react';
import './dropdown.scss';
// import useConfirm from 'views/pages/manage/common/commonComponents/ConfirmationDialog/confirmationPrompt';
import { FEE_COMPONENTS } from '.';

const Dropdown = ({ items, 
  disable = false, 
  onChange, 
  placeHolder, 
  extraComp = [], 
  customComponent = [], 
  definedComponent = [], 
  confirmFeeComponent,
  setSelectedItems,
  setErrorDialogOpen,
  prospectusSlabs,
  customSlab }) => {

  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCheckboxClick = async(value) => {
    if (disable) return;

    if (!prospectusSlabs?.length && customSlab?.action !== 'CREATE' && !customSlab?.fromBE) {
      setErrorDialogOpen(true);
      return
    }

    const isSelected = extraComp.some((item) => item === value.value);
    setSelectedItems(FEE_COMPONENTS.find(i => i.value === value.value)?.label)
    let confirm = true;
    if(!isSelected){
      confirm = await confirmFeeComponent();
    }

    if (!confirm) {
      onChange([...extraComp]);
      return
    }


    const updatedSelectedItems = isSelected
      ? extraComp.filter((item) => item !== value.value)
      : [...extraComp, value.value];
    onChange(updatedSelectedItems);
  };

  return (<>
    <div className="dropdown" ref={dropdownRef}>
      <label className="dropdown-label" onClick={toggleDropdown}>
        {placeHolder}
      </label>
      {isOpen && (
        <div className="dropdown-list">
          {items.map((item, index) => {
            console.log(customComponent.includes(item.value))
            return <div key={index} className="dropdown-item">
              <input
                type="checkbox"
                checked={extraComp.includes(item.value) || customComponent.includes(item.value) || definedComponent.includes(item.value) ? true : false}
                onClick={() => handleCheckboxClick(item)}
                disabled={disable || item.isDisable || customComponent.includes(item.value) || definedComponent.includes(item.value)}
              />
              <label>{item.label}</label>
            </div>
          })}
        </div>
      )}
    </div>
  </>
  );
};

export default Dropdown;
