import React, { useState } from "react";
import { withRouter } from "react-router";
// import CustomLoader from "../../commonComponent/Loader/loader";
import { Button, Card, Input, Table } from "reactstrap";
import moment from "moment";
import { failureToast, successToast } from "../../common/utils/methods/toasterFunctions/function";
import {
  putpost,
  putpost2,
  documentServiceBaseUrl,
} from "../../../../../services/http";
import { MasterHeaderAuto } from "../../common/commonComponents/masterHeader/masterHeader";
import CardHeader from "reactstrap/lib/CardHeader";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import Container from "reactstrap/lib/Container";
import { ButtonText } from 'variables/Buttons';
import CustomButton from "components/CustomButton";
import { CustomToggle } from "../../common/formFeilds";
import { PermisionDenied } from "../../common/commonComponents";
import './_index.scss'

var _ = require("lodash");
const DocumentTypeTable = (props) => {
  const {
    setIsSaveVisible = () => { },
    isSaveVisible = false,
    setClassArray = () => { },
    classArray,
    getData = () => { },
    isFetching,
    setIsFetching = () => { },
    activeformatType = [],
    allformatType = [],
    userPermissions
  } = props;
  const [documentTypeKey, setDocumentTypeKey] = useState('');
  const [documentTypeDesc, setDocumentTypeDesc] = useState('');
  const [documentTypeDispValue, setDocumentTypeDispValue] = useState('');
  const [documentSize, setDocumentSize] = useState('');
  const [docuemntFormat, setDocuemntFormat] = useState([]);
  const [status, setStatus] = useState("ACTIVE");
  const [isActive, setIsActive] = useState(true);
  const [effectiveDate, setEffectiveDate] = useState('')
  const addNewClass = () => {
    setClassArray([
      {
        documentTypeKey: null,
        status: "ACTIVE",
        documentTypeDesc: null,
        documentTypeDispValue: null,
        effectiveDate: null,
        documentSize: null,
        docuemntFormat: [],
        isEdit: false,
        isNew: true,
      },
      ...classArray,
    ]);
  };
  const headerList = [
    { name: "Document Type Key", isRequired: true },
    { name: "Effective Date" },
    { name: "Description", isRequired: true },
    { name: "Display Value", isRequired: true },
    { name: "Document Format", isRequired: true },
    { name: "Document Size(KB)", isRequired: true },
    { name: "Document Size(MB)" },
    { name: "Status" },
    { name: "Action" },
  ];

  const newRow = () => {
    let cb = () => {
      addNewClass();
      setIsSaveVisible(true);
    };
    let _tempArray = classArray;
    _tempArray = _.sortBy(_tempArray, [
      function (o) {
        return o.value;
      },
    ]);
    setClassArray(_tempArray);
    setIsSaveVisible(false);
    cb();
  };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      setIsSaveVisible(true);
      let tempData = classArray;
      tempData[index]["isEdit"] = true;
      setDocumentTypeKey(tempData[index]["documentTypeKey"]);
      setDocumentTypeDesc(tempData[index]["documentTypeDesc"]);
      setDocumentTypeDispValue(tempData[index]["documentTypeDispValue"]);
      setDocumentSize(tempData[index]["documentSize"]);
      // console.log(getSelectedValues(tempData[index]["docuemntFormat"],'array'))
      // console.log(tempData[index]["documentFormatIds"])
      setDocuemntFormat([...getSelectedValues(tempData[index]["documentFormatIds"], 'array')]);
      setStatus(tempData[index]["status"]);
      setEffectiveDate(tempData[index]["effectiveDate"]);
    }
  };
  const deleteRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let tempData = classArray;
      let id = tempData[index]["id"];

      // id is used when api ready

      if (
        window.confirm("Are you sure you want to delete this document type?")
      ) {
        putpost2(
          documentServiceBaseUrl + "/documentType/deleteDocumentType/" + id,
          (data) => {
            successToast(data.message);
            getData();
            resetFields();
          },
          (data) => {
            failureToast(data.message);
          },
          "Delete"
        );
      }
    }
  };

  const saveRow = (index) => {
    let tempData = classArray;
    let isValid = true;
    if (!documentTypeKey || !documentTypeKey.trim()) {
      failureToast("Please enter document type key");
      isValid = false;
    } else if (!documentTypeDesc || !documentTypeDesc.trim()) {
      failureToast("Please enter description");
      isValid = false;
    } else if (!documentTypeDispValue || !documentTypeDispValue.trim()) {
      failureToast("Please enter display value");
      isValid = false;
    } else if (!docuemntFormat.length) {
      failureToast("Please select atleast one format");
      isValid = false;
    } else if (!documentSize) {
      failureToast("Please enter document size");
      isValid = false;
    } else {
      const formatArray = docuemntFormat.map(item => { return item.value })
      if (tempData[index]["isEdit"]) {
        let tempObject = {
          ...tempData[index],
          documentTypeDesc: documentTypeDesc,
          documentTypeDispValue: documentTypeDispValue,
          documentTypeKey: documentTypeKey,
          documentSize: documentSize,
          documentFormatIds: formatArray,
          status: status,
        };
        tempData[index] = tempObject;
        setClassArray(tempData);
        let id = tempData[index]["id"];
        // id is used when API is ready.....

        //  url ='tempUrl';
        // putPostApi(`${concessionServiceBaseUrl}/addSubCategory`,tempObject,'post');
        putpost(
          `${documentServiceBaseUrl}/documentType/updateDocumentType/${id}`,
          (data) => {
            successToast(data.message);
            setIsSaveVisible(false);
            getData();
            resetFields();
            // setIsSaveVisible(false);
            // tempData[index]["isEdit"] = false;
            // resetFields();
          },
          (data) => {
            failureToast(data.message);
          },
          tempObject,
          "put"
        );
      } else if (tempData[index]["isNew"]) {
        // putPostApi(`${concessionServiceBaseUrl}/addSubCategory`,tempObject,'post');
        // setIsSaveVisible(false);
        // resetFields()
        let tempObject = {
          documentTypeDesc: documentTypeDesc,
          documentTypeDispValue: documentTypeDispValue,
          documentTypeKey: documentTypeKey,
          documentSize: documentSize,
          documentFormatIds: formatArray,
          status: status,
        };

        putpost(
          `${documentServiceBaseUrl}/documentType/createDocumentType`,
          (data) => {
            successToast(data.message);
            // tempData.push(tempObject);
            // setClassArray(tempData);

            setIsSaveVisible(false);
            // tempData[index]["isNew"] = false;
            getData();
            resetFields();
          },
          (data) => {
            failureToast(data.message);
          },
          tempObject,
          "post"
        );

        // putPostApi(`${concessionServiceBaseUrl}/addSubCategory`,tempObject,'post');
      }
    }
  };

  const cancelRow = (index) => {
    let tempData = classArray;
    if (tempData[index]["isEdit"]) tempData[index]["isEdit"] = false;
    else if (tempData[index]["isNew"]) tempData.splice(index, 1);
    setClassArray(tempData);
    setIsSaveVisible(false);
    getData();
    resetFields();
  };

  const resetFields = () => {
    setDocumentTypeKey('');
    setDocumentTypeDesc('');
    setDocumentTypeDispValue('');
    setDocumentSize('');
    setDocuemntFormat([]);
    setStatus("ACTIVE");
    setEffectiveDate('')
  };

  const TDROW = (props) => {
    const { data, index, userPermissions } = props;
    return (
      <tr>
        <td className="text-center p-2 mx-1 white-breakSpace">
          {data.documentTypeKey}
        </td>
        <td className="text-center p-2 mx-1 white-breakSpace">
          {moment(data.effectiveDate).format("DD-MM-YYYY")}
        </td>
        <td className="text-center p-2 mx-1 white-breakSpace">
          {data.documentTypeDesc}
        </td>
        <td className="text-center p-2 mx-1 white-breakSpace">
          {data.documentTypeDispValue}
        </td>
        <td className="text-center p-2 mx-1 white-breakSpace">
          {getSelectedValues(data.documentFormatIds, 'string')}
        </td>
        <td className="text-center p-2 mx-1 white-breakSpace">
          {data.documentSize}
        </td>
        <td className="text-center p-2 mx-1 white-breakSpace">
          {data?.documentSize ? `${parseFloat(data?.documentSize / 1024).toFixed(2)} MB` : ""}
        </td>
        <td className="text-center  white-breakSpace">
          {data["status"] === "INACTIVE" ? (
            <button
              disabled={true}
              type="button"
              className="btn btn-sm btn-warning"
            >
              Inactive
            </button>
          ) : (
            <button
              type="button"
              disabled={true}
              className="btn btn-sm btn-success"
            >
              Active
            </button>
          )}
        </td>

        {userPermissions && (userPermissions.includes('D') || userPermissions.includes('U')) && <td className="text-center p-2 mx-1">
          {/* <Button
            color={"info"}
            // disabled={isView}
            size="sm"
            type="button"
            className={" mx-1"}
            onClick={() => {
              editRow(index);
            }}
          >
            <i className="fas fa-edit" />
          </Button>
          <Button
            //disabled={isView}
            color={"info"}
            size="sm"
            type="button"
            className={" mx-1"}
            onClick={() => deleteRow(index)}
          >
            <i className="fas fa-trash" />
          </Button> */}
          <CustomButton
            // content={ButtonText.DOCUMENTTYPE.UPDATE}
            permissionType={'U'}
            icon={true}
            forTable={true}
            onClick={() => editRow(index)}
            permissionSet={userPermissions}
          />
          <CustomButton
            // content={ButtonText.DOCUMENTTYPE.DELETE}
            permissionType={'D'}
            icon={true}
            forTable={true}
            onClick={() => deleteRow(index)}
            permissionSet={userPermissions}
          />
        </td>}
      </tr>
    );
  };

  // const getShortDescription = (id) => {
  //     const i = subCategoryList.filter(item=> {return item.id == id})
  //     return i && i.length ? i[0] : null
  // }

  const getSelectedValues = (idlist, type) => {
    let str = "";
    let newArr = []
    idlist &&
      idlist.forEach((element) => {
        const obj = allformatType && allformatType.find((o) => o.value === element);
        str += (str.length && obj ? " , " : "") + (obj ? obj["label"] : "");
        newArr = [...newArr, obj]
      });
    return type === "string" ? str : newArr;
  };



  // const NewOrEditRow = (props) => {
  //   const { data, isSaveVisible, index } = props;
  //   return (

  //   );
  // };
  const getDropdownButtonLabel = ({ placeholderButtonLabel, value = "" }) => {
    return `${value.length} selected`;
  };

  return (
    <Container fluid className="mt-4">
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <h3>Document Type</h3>
          {/* <Button
            disabled={isSaveVisible}
            color="info"
            size="sm"
            type="button"
            className={"floatRight" + (isSaveVisible ? " btn-dark" : null)}
            onClick={() => {
              newRow();
            }}
            data-testid="addNewInvoiceStatus"
          >
            New Document Type
          </Button> */}

          <CustomButton
            disabled={isSaveVisible}
            className={'floatRight mx-1' + (isSaveVisible ? ' btn-dark cursor-gray' : null)}
            content={ButtonText.DOCUMENTTYPE.NEW}
            permissionType={'C'}
            icon={true}
            onClick={() => { newRow() }}
            permissionSet={userPermissions}
            data-testid="addNewInvoiceStatus"
          />
        </CardHeader>
        <div id="questionTable" className=" p-3">
          <Table className="align-items-center  tableLayout">
            <MasterHeaderAuto headerList={headerList} permissionSet={userPermissions} isSaveVisible={isSaveVisible} />

            <tbody>
              {classArray.map((data, index) =>
                data["isEdit"] || data["isNew"] ? (
                  <tr key={data["id"] + "-class"}>
                    <td className="text-center p-2 white-breakSpace">
                      <Input
                        value={documentTypeKey}
                        style={{ height: "39px" }}
                        placeholder="Document Type Key"
                        maxLength="2"
                        onChange={(e) => {
                          if (/^[A-Za-z]*$/.test(e.target.value)) {
                            setDocumentTypeKey(e.target.value);
                          } else {
                            return
                          }
                        }}
                      />
                    </td>
                    <td className="text-center p-2 mx-1 white-breakSpace">
                      <Input
                        value={(effectiveDate && moment(effectiveDate).format('DD-MM-YYYY')) ?? ''}
                        disabled={true}
                        style={{ height: "39px" }}
                      />
                    </td>
                    <td className="text-center p-2 white-breakSpace">
                      <Input
                        value={documentTypeDesc}
                        style={{ height: "39px" }}
                        placeholder="Description"
                        onChange={(e) => setDocumentTypeDesc(e.target.value)}
                      />
                    </td>
                    <td className="text-center p-2 mx-1 white-breakSpace">
                      <Input
                        value={documentTypeDispValue}
                        style={{ height: "39px" }}
                        placeholder="Display Value"
                        onChange={(e) =>
                          setDocumentTypeDispValue(e.target.value)
                        }
                      />
                    </td>
                    <td className="p-2 mx-1 document_type_rmselect_td" >
                      {/* <Select2
                        defaultValue={docuemntFormat}
                        data={activeformatType}
                        // style={{ height: "35px" }}
                        id="document_format_type"
                        option={{ placeholder: "Select Docuemnt Type" }}
                        onChange={(e) => {
                          setDocuemntFormat(e.target.value)
                          // setDocuemntFormat([...docuemntFormat,e.target.value]);
                        }}
                        className="tdSelect"
                      /> */}
                      <ReactMultiSelectCheckboxes
                        options={activeformatType}
                        // options={[...options]}
                        placeholderButtonLabel="Select Document Formats"
                        getDropdownButtonLabel={getDropdownButtonLabel}
                        value={docuemntFormat}
                        onChange={(e) => setDocuemntFormat(e)}
                        rightAligned={false}
                        width='-webkit-fill-available'
                        className="document_type_rmselect"
                        style={{ backgroundColor: 'transparent' }}
                      />
                    </td>
                    <td className="text-center p-2 mx-1 white-breakSpace">
                      <Input
                        value={documentSize}
                        style={{ height: "39px" }}
                        maxLength="6"
                        placeholder="Document Size"
                        onChange={(e) => setDocumentSize(e.target.value)}
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            setDocumentSize(e.target.value);
                          }
                        }}
                      />
                    </td>
                    <td className="text-center p-2 mx-1 white-breakSpace">
                      <Input
                        value={documentSize && documentSize / 1024}
                        style={{ height: "39px" }}
                        disabled={true}
                      // onChange={(e) =>
                      //   setDocumentTypeDispValue(e.target.value)
                      // }
                      />
                    </td>

                    <td className="text-center p-2 mx-1 white-breakSpace">
                      <label className="custom-toggle mx-auto ml-2">
                        {/* <input
                          checked={status == "ACTIVE" ? true : false}
                          type="checkbox"
                          onChange={(e) => {
                            // setStatus(new Date().getMilliseconds());
                            console.log(e.target.checked);
                            let n = "ACTIVE";
                            if (!e.target.checked) {
                              n = "INACTIVE";
                            }
                            setStatus(n);
                          }}
                        />
                        <span
                          style={{ width: "72px" }}
                          className="custom-toggle-slider rounded-circle activeToggle"
                          data-label-off="Inactive"
                          data-label-on="Active"
                        /> */}

                        <CustomToggle
                          status={status}
                          data-testid="activeInactiveInput"
                          id={data.id}
                          toggleOn={"Active"}
                          toggleOff={"Inactive"}
                          onChangeHandler={(e) => {
                            setIsActive(!isActive)
                            if (isActive) {
                              setStatus("ACTIVE")
                            }
                            else {
                              setStatus("INACTIVE")
                            }
                            // setStatus(n)
                          }}
                        />
                      </label>
                    </td>
                    {userPermissions && (userPermissions.includes('D') || userPermissions.includes('U')) && <td className="text-center p-2 mx-1">
                      {/* <Button
                        color="info"
                        size="sm"
                        type="button"
                        className="mx-1"
                        onClick={() => saveRow(index)}
                      >
                        Save
                      </Button>
                      <Button
                        color="danger"
                        size="sm"
                        type="button"
                        onClick={() => {
                          cancelRow(index);
                        }}
                      >
                        <i className="fas fa-times" />
                      </Button> */}
                      <CustomButton
                        content={ButtonText.DOCUMENTTYPE.UPDATE}
                        forTable={true}
                        permissionType={'C'}
                        icon={false}
                        onClick={() => saveRow(index)}
                        permissionSet={userPermissions}
                      />

                      <CustomButton
                        forTable={true}
                        permissionType={'cancel'}
                        icon={true}
                        onClick={() => { cancelRow(index) }}
                      />
                    </td>}
                  </tr>
                ) : (
                  <TDROW
                    key={"TDROW" + index}
                    data={data}
                    index={index}
                    history={props.history}
                    userPermissions={userPermissions}
                  />
                )
              )}
            </tbody>
          </Table>
        </div>
      </Card>
    </Container>
  );
};

export default withRouter(DocumentTypeTable);
