import React, { useState } from "react";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import ViewTimetableMasterCnf from "./ViewTimetableMasterCnf";

const ViewTimetableMaster = ({ timetable, subjectFacultyDropdown,subjectForm }) => {
      // console.log(subjectFacultyDropdown,timetable,subjectForm);
  const [isOpenDetailsPopup, setIsOpenDetailsPopup] = useState(false);
  return (
    <>
      <AButton
        onClick={() => setIsOpenDetailsPopup(true)}
        variant="outline"
        updatedStyle={{ alignSelf: "end" }}
      >
        View Masters
      </AButton>
      <ViewTimetableMasterCnf
        open={isOpenDetailsPopup}
        handleClose={() => setIsOpenDetailsPopup(false)}
        timetable={timetable}
        subjectFacultyDropdown = {subjectFacultyDropdown}
        subjectForm = {subjectForm}
      />
    </>
  );
};

export default ViewTimetableMaster;
