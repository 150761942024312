import { endpoint } from "../../../../common/constant";
import { dropdownMapping, fetchAllPromisedData, isFormFieldEmpty } from "../../../../common/utils/methods/commonMethods/utilityMethod";

export const saveOrDeleteMsg = 'Please save or delete current changes';

export const newRow = {
  controllingArea: 'AEGP',
  companyCode: 'AESL',
  currencyCode: 'INR',
  profitCenter: '',
  profitCenterDescription: '',
  academicCareer: '',
  academicGroup: '',
  businessArea: '',
  costCenter: '',
  costCenterDescription: '',
  costCenterStatus: 'ACTIVE',
  serviceMaterial: '',
  serviceMaterialDescription: ''
}

const profitCenterDropdownMapping = async () => {
  const res = await fetchAllPromisedData(endpoint?.sapProfitCostCentreMapping?.getAllProfitCenter, false);
  if (Array.isArray(res)) {
    return res?.map(item => ({ label: item?.sapProfitCenter, value: item?.sapProfitCenter, academicCareer: item?.academicCareer, academicGroup: item?.academicGroup, description: item?.description }));
  }
  else {
    return [];
  }
}

export const getDropdowns = async () => {
  const p = await Promise.all([
    profitCenterDropdownMapping(),
    dropdownMapping(endpoint?.businessArea?.getAllActive, 'sapBusinessArea', 'sapBusinessArea'),
    dropdownMapping(endpoint?.academic_career?.getAllActive, 'academicCareerDispValue', 'academicCareerDispValue'),
    dropdownMapping(endpoint?.academic_group?.getAllActive, 'academicGroupDispValue', 'academicGroupDispValue'),
  ])

  return {
    profitCenter: p[0],
    sapBusinessArea: p[1].filter(item => item?.label),
    academicCareer: p[2],
    academicGroup: p[3],
  }
}

export const initialFilterForm = {
  sapBusinessArea: '',
  academicCareer: '',
  academicGroup: '',
  costCenterStatus: ''
}

export const getFilterCount = (filterForm) => {
  let cnt = 0;
  for (let key in filterForm) {
    if (!isFormFieldEmpty(filterForm[key])) cnt++;
  }
  return cnt;
}