import React, { useEffect, useState } from "react";
import {
  useFetchBatch,
  useFetchRooms,
  useFetchPublishedTimetable,
  useFetchFacultyDropdown,
  useFetchPlanSubjects,
  useFetchDaysPlanDropdown,
  useFetchPlanListByBatch,
  useGetPastEvent,
  useGetMissedTests,
} from "./../hooks";
import { useHistory, useParams } from "react-router-dom";
import {
  updateTestData,
  getTimetableTestRequest,
  getOnlyEditedItem,
  getLectureRequest,
  editTimetable,
  getAutoPopulateRequest,
  getPlanTests,
  getSelectedFaculty,
  removeBreaksFromEnd,
  createTimetable,
  FORM_FIELD,
  dropdownMapping,
  getPlanListModel,
  getPlanListForBatch,
  resetTimetableByBatchId,
  getTestsThatNotExist,
} from "../helper";
import { successToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import TimetableTestListV2 from "./../academicTimetableListView/TimetableTestListV2";
import PlanLecturesV2 from "./../lectureModule/PlanLecturesV2";
import { autoPopulateLectures } from "./../helper";
import ALoader from "views/pages/manage/common/a-loader";
import moment from "moment";
import { updateMsTeam } from "../lectureModule/PlanLectures";
import TimetableForm from "../TimetableForm";
import { TimetableContextProvider } from "../contextApi/TimetableContext";
import ReviewAndPublish from "./ReviewAndPublish";
import HeaderComponent from "../academicTimetableListView/HeaderComponent";
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import { getMomentWithoutTimestamp } from "../../../common/utils/methods/commonMethods/utilityMethod";

const EditViewTimetable = () => {
  const history = useHistory();
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [isResetEnabled, setResetEnabled] = useState(false);
  const param = useParams();
  const batchDetails = useFetchBatch(param?.id);
  console.log(batchDetails, "batchDetails")
  const MODE = param?.action === "edit" ? "EDIT" : "VIEW";
  //value will be for this TIMETABLE or ACADEMIC_TEST or ACADEMIC_LECTURE or REVIEW_PUBLISH

  const [openedForm, setOpenForm] = useState("TIMETABLE");
  // this is for timetable form
  const [currentStep, setCurrentStep] = useState(2);
  const [isNeedToPublish, setIsNeedToPublish] = useState(false);

  const roomsDropdown = useFetchRooms(batchDetails?.businessArea?.id);

  // store item ids which will be deleted after end date reduce
  const [deletedIds, setDeletedIds] = useState([]);
  const [
    tests,
    setTests,
    lectures,
    setLectures,
    planData,
    setPlanDetails,
    fetchPublishedTimetable,
  ] = useFetchPublishedTimetable(param?.id, setLoading, MODE, roomsDropdown);
  // fetching dropdowns
  const [plans, setPlans] = useState([]);
  const daysPlanDropdown = useFetchDaysPlanDropdown(
    batchDetails?.businessArea?.id
  );
  const subjectFacultyDropdown = useFetchFacultyDropdown(
    batchDetails?.businessArea?.id
  );
  const [planSubjects, subjectForm] = useFetchPlanSubjects(
    planData?.plan?.packageId
  );

  // for past test and lecture
  const [pastTestPage, setPastTestPage] = useState(0);
  const [pastLecturePage, setPastLecturePage] = useState(0);

  const [
    pastTests,
    isPastTestLoading,
    totalPastTestsPage,
    totalPastTestsCount,
  ] = useGetPastEvent(param?.id, pastTestPage, "test");

  const missedTests = useGetMissedTests(param?.id)

  const [
    pastLectures,
    isPastLectureLoading,
    totalPastLecturePage,
    totalPastLectureCount,
  ] = useGetPastEvent(param?.id, pastLecturePage, "lecture");

  useEffect(() => {
    if (isResetEnabled) {
      setLoading(true);
      getPlanListForBatch(getPlanListModel(batchDetails)).then((res) => {
        setLoading(false);
        setPlans(
          dropdownMapping(
            res?.data?.academicPlanSearchResponseDTOList || [],
            "academicPlanName",
            true
          )
        );
      });
    }
  }, [isResetEnabled]);
  // reset current form, test, lectures while reset
  const resetTimetableData = () => {
    resetTimetable();
  };

  const resetTimetable = async () => {
    const response = await resetTimetableByBatchId(param?.id);

    if (response) {
      setPlanDetails(FORM_FIELD);
      setLectures([]);
      setTests([]);
      setCurrentStep(2);
      setOpenForm("TIMETABLE_FORM");
      setResetEnabled(true);
    }
  };
  const formHandler = (value, formKey) => {
    // here plan details like form data
    setPlanDetails((currentData) => {
      return { ...currentData, [formKey]: value };
    });
  };

  const getOnlyLectureCount = (lectureList) => {
    return (
      lectureList?.filter(
        (lecture) => lecture?.lectureName?.toLowerCase() !== "break"
      )?.length ?? 0
    );
  };

  const editApiHandler = (timetableId, request, isMoveView) => {
    if (deletedIds.length > 0)
      request = { ...request, timeTableIdsDeleted: deletedIds };
    if (getOnlyLectureCount(lectures) === 0) {
      failureToast("Please add at least one lecture");
      return;
    }
    setLoading(true);

    if (isResetEnabled) {
      // request.lectureTimeTable.daysPlanId = request.lectureTimeTable.daysPlan;
      // request.lectureTimeTable.selectedFaculty = JSON.stringify(
      //   getSelectedFaculty(subjectForm, planData)
      // );

      request["duration"] = planData?.duration?.value;
      createTimetable({ ...request, isResetEnabled }).then((res) => {
        if (res?.code === 200) {
          successToast(res?.message);
          history.push(`/admin/testScheduling/timetable/view/${param?.id}`);
          window.location.reload(false);
        }
        setLoading(false);
      });
      return;
    }
    // if not reset then call the edit api
    editTimetable(timetableId, request, planData?.timeTableState)
      .then((res) => {
        if (res?.code === 200) {
          successToast(res?.message);
          if (!isMoveView) {
            history.push(`/admin/testScheduling/timetable/view/${param?.id}`);
            window.location.reload(false);
          } else {
            // reset edited flag for lecture
            fetchPublishedTimetable(param?.id, lectures?.filter(_lecture=>!_lecture?.id));
          }
        }
        if (res?.code === 412) {
          fetchPublishedTimetable(param?.id);
          failureToast("Timetable has been updated. Please refresh the page");
          setTimeout(() => window.location.reload(false), 3000);
        }

        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  // fetch new tests after reset timetable
  const fetchPlanTests = (form) => {
    setLoading(true);
    getPlanTests(form).then((res) => {
      setTests(
        updateTestData(
          getTestsThatNotExist(
            res?.data?.searchTestScheduleDtoList || [],
            pastTests,
            []
          )
        )
      );
      setLoading(false);
      setOpenForm("TIMETABLE");
    });
    fetchLectures();
  };

  // After reset new lectures will be populated
  const fetchLectures = () => {
    const requestForAutoPopulate = getAutoPopulateRequest(
      {
        ...planData,
        endOn: new moment(planData.endOn),
        startFrom: new moment(planData.startFrom),
      },
      batchDetails?.businessArea?.id,
      param?.id,
      subjectForm
    );
    autoPopulateLectures(requestForAutoPopulate).then((res) => {
      if (res)
        setLectures([
          ...addRoom(
            removeBreaksFromEnd(
              updateMsTeam(res?.data, batchDetails?.isEnabledMsTeamsSync)
            )
          ),
        ]);
    });
  };

  const addRoom = (lectures) => {
    let result = lectures.map((lecture) => ({
      ...lecture,
      roomName: planData?.room?.label,
      roomId: planData?.room?.value,
    }));
    return result;
  };

  const fetchNewLectures = (
    newEndDate,
    oldEndDate,
    isGreater,
    setTempLectures,
    setLoading = () => {}
  ) => {
    // date reduce case
    setLoading(true);
    if (!isGreater) {
      let filteredLecture = lectures.filter(
        (lec) => new moment(lec?.lectureDate) > newEndDate
      );
      setTempLectures(filteredLecture);

      setLoading(false);
      return;
    }
    const requestForAutoPopulate = getAutoPopulateRequest(
      {
        ...planData,
        endOn: newEndDate,
        startFrom: moment(oldEndDate).isBefore(getMomentWithoutTimestamp())
          ? getMomentWithoutTimestamp()
          : new moment(oldEndDate),
      },
      batchDetails?.businessArea?.id,
      param?.id,
      null,
      JSON.parse(planData?.selectedFaculty)
    );

    autoPopulateLectures(requestForAutoPopulate)
      .then((res) => {
        if (isGreater && res) {
          // added extra lecture
          setLoading(false);
          setTempLectures([
            ...addRoom(
              removeBreaksFromEnd(
                updateMsTeam(res.data, batchDetails?.isEnabledMsTeamsSync)
              )
            ),
          ]);
          setLoading(false);

          // setLectures([...lectures]);
        }
      })
      .catch((e) => {
        setLoading(false);
      })
      .finally((e) => {
        setLoading(false);
      });
  };

  const skipTestWithoutId = (testItems) => {
    return testItems.filter((item) => item.id);
  };

  const skipLectureWithOutId = (lectures) => {
    return lectures.filter((lecture) => lecture.id);
  };

  const editTimetableHandler = (
    tempLectures = lectures,
    isMoveView = false,
    isDeleteTimeTable = false,
    deletedId,
    removedPsidMapping = []
  ) => {
    let request = {
      batchId: param?.id,
      academicPlanId: planData?.plan?.value,
      startDate: planData?.startFrom,
      endDate: planData?.endOn,
      isDeleteTimeTable,
      duration: planData?.duration?.value,
      testTimeTable: getOnlyEditedItem(getTimetableTestRequest(tests)),

      lectureTimeTable: getOnlyEditedItem(
        getLectureRequest(
          tempLectures,
          planData?.room?.value,
          planData?.duration?.value
        )
      ),
      batchTimeTablePsidMappingIds: removedPsidMapping,
      selectedFaculty: planData?.selectedFaculty,
      daysPlan: planData?.daysPlan?.value,
    };
    const createdRequest = {
      batchId: param?.id,
      academicPlanId: planData?.plan?.value,
      startDate: planData?.startFrom,
      endDate: planData?.endOn,
      isDeleteTimeTable,
      duration: planData?.duration?.value,
      daysPlanId: planData?.daysPlan.value,
      roomId: planData?.room?.value,
      testTimeTable: getTimetableTestRequest(tests),
      lectureTimeTable: {
        lectureDetails: getLectureRequest(
          tempLectures,
          planData?.room?.value,
          planData?.duration?.value
        ),
        duration: planData?.duration?.value,
        selectedFaculty: JSON.stringify(
          getSelectedFaculty(subjectForm, planData)
        ),
        daysPlanId: planData?.daysPlan.value,
      },
    };

    editApiHandler(
      planData?.timetableId,
      isResetEnabled ? createdRequest : request,
      isMoveView
    );
  };

  const editTimetableTestHandler = (
    tempTests = tests,
    isMoveView = false,
    isDeleteTimeTable = false,
    deletedId
  ) => {
    const request = {
      batchId: param?.id,
      academicPlanId: planData?.plan?.value,
      startDate: planData?.startFrom,
      endDate: planData?.endOn,
      isDeleteTimeTable,
      duration: planData?.duration?.value,
      testTimeTable: getOnlyEditedItem(getTimetableTestRequest(tempTests)),
      lectureTimeTable: getOnlyEditedItem(
        getLectureRequest(
          lectures,
          planData?.room?.value,
          planData?.duration?.value
        )
      ),
      timeTableIdsDeleted: deletedId && [deletedId],
      selectedFaculty: planData?.selectedFaculty,
      daysPlan: planData?.daysPlan?.value,
    };

    editApiHandler(planData?.timetableId, request, isMoveView);
  };

  const editTimetableLectureHandler = (
    tempLectures = lectures,
    isMoveView = false,
    isDeleteTimeTable = false,
    deletedId,
    removedPsidMapping = []
  ) => {
    const request = {
      batchId: param?.id,
      academicPlanId: planData?.plan?.value,
      startDate: planData?.startFrom,
      endDate: planData?.endOn,
      isDeleteTimeTable,
      duration: planData?.duration?.value,
      testTimeTable: getOnlyEditedItem(
        getTimetableTestRequest(skipTestWithoutId(tests))
      ),
      lectureTimeTable: getOnlyEditedItem(
        getLectureRequest(
          tempLectures,
          planData?.room?.value,
          planData?.duration?.value
        )
      ),
      timeTableIdsDeleted: deletedId && [deletedId],
      batchTimeTablePsidMappingIds: removedPsidMapping,
      selectedFaculty: planData?.selectedFaculty,
      daysPlan: planData?.daysPlan?.value,
    };
    editApiHandler(planData?.timetableId, request, isMoveView);
  };

  const publishTimetableHandler = (form, publishStatus) => {
    const request = {
      publishStatus: publishStatus,
      toPublishUnPublish: true,
      batchId: param?.id,
    };
    editApiHandler(form?.timetableId, request);
  };

  return (
    <TimetableContextProvider
      {...{
        isResetEnabled,
        setResetEnabled,
        resetTimetableData,
        setDeletedIds,
        pastTests,
        missedTests,
        isPastTestLoading,
        totalPastTestsPage,
        totalPastTestsCount,
        pastTestPage,
        setPastTestPage,
        pastLectures,
        isPastLectureLoading,
        totalPastLecturePage,
        totalPastLectureCount,
        pastLecturePage,
        setPastLecturePage,
        selectedFaculty:planData?.selectedFaculty,
        academicCareer :batchDetails?.academicCareer
        
      }}
    >
      <div>
        {(loading || isPastTestLoading || isPastLectureLoading) && (
          <ALoader position="fixed" />
        )}
        <div
          className="remove-container-top-space"
          style={{ position: "relative" }}
        >
          {openedForm !== "TIMETABLE_FORM" && (
            <HeaderComponent
              setOpenForm={setOpenForm}
              formData={planData}
              subjectForm={subjectForm}
              plansDropdown={plans}
              formHandler={formHandler}
              batchDetails={batchDetails}
              openMode={MODE}
              tests={tests}
              setTests={setTests}
              lectures={lectures}
              setLectures={setLectures}
              fetchNewLectures={fetchNewLectures}
              setIsNeedToPublish={setIsNeedToPublish}
              subjectFacultyDropdown={subjectFacultyDropdown}
            />
          )}
        </div>
        {openedForm === "TIMETABLE_FORM" && (
          <TimetableForm
            param={param}
            form={planData}
            formHandler={formHandler}
            setOpenForm={setOpenForm}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            batchDetails={batchDetails}
            plansDropdown={plans}
            roomsDropdown={roomsDropdown}
            planSubjects={planSubjects}
            subjectFacultyDropdown={subjectFacultyDropdown}
            daysPlanDropdown={daysPlanDropdown}
            subjectForm={subjectForm}
            fetchPlanTests={fetchPlanTests}
          />
        )}

        {openedForm === "TIMETABLE" && (
          <TimetableTestListV2
            mode={MODE}
            tests={tests}
            setTests={setTests}
            planData={planData}
            lectures={lectures}
            setLectures={setLectures}
            setPlanDetails={setPlanDetails}
            batchId={param?.id}
            formData={form}
            formHandler={formHandler}
            setOpenForm={setOpenForm}
            roomsDropdown={roomsDropdown}
            batchDetails={batchDetails}
            onEdit={editTimetableTestHandler}
            publishTimetableHandler={publishTimetableHandler}
            fetchNewLectures={fetchNewLectures}
            setIsNeedToPublish={setIsNeedToPublish}
          />
        )}
        {openedForm === "ACADEMIC_LECTURE" && (
          <PlanLecturesV2
            formData={form}
            tests={tests}
            setTests={setTests}
            lectures={lectures}
            setLectures={setLectures}
            planData={planData}
            setPlanDetails={setPlanDetails}
            formHandler={() => {}}
            setOpenForm={setOpenForm}
            // plansDropdown={plansDropdown}
            roomsDropdown={roomsDropdown}
            subjectFacultyDropdown={subjectFacultyDropdown}
            planSubjects={planSubjects}
            publishTimetable={publishTimetableHandler}
            publishStatusTimetableHandler={publishTimetableHandler}
            batchDetails={batchDetails}
            businessAreaId={batchDetails?.businessArea?.id}
            // subjectForm = {subjectForm}
            batchId={param?.id}
            planTests={tests}
            mode={MODE}
            isNeedToPublish={isNeedToPublish}
            onEditSave={editTimetableLectureHandler}
            fetchNewLectures={fetchNewLectures}
            setIsNeedToPublish={setIsNeedToPublish}
            isEnabledMsTeamsSync={batchDetails?.isEnabledMsTeamsSync}
            skipLectureWithOutId={skipLectureWithOutId}
          />
        )}
        {openedForm === "REVIEW_PUBLISH" && (
          <ReviewAndPublish
            tests={tests}
            setTests={setTests}
            lectures={lectures}
            planData={planData}
            setOpenForm={setOpenForm}
            publishTimetable={publishTimetableHandler}
            publishStatusTimetableHandler={publishTimetableHandler}
            batchDetails={batchDetails}
            batchId={param?.id}
            mode={MODE}
            isNeedToPublish={isNeedToPublish}
            onEditSave={editTimetableHandler}
            setIsNeedToPublish={setIsNeedToPublish}
            isEnabledMsTeamsSync={batchDetails?.isEnabledMsTeamsSync}
            roomsDropdown={roomsDropdown}
            subjectFacultyDropdown={subjectFacultyDropdown}
            skipLectureWithOutId={skipLectureWithOutId}
            setLectures={setLectures}
            planSubjects={planSubjects}
            isResetEnabled={isResetEnabled}
          />
        )}
      </div>
    </TimetableContextProvider>
  );
};

export default EditViewTimetable;
