import React, {useEffect, useState} from "react";
import { Card, CardBody, CardHeader} from "reactstrap";
import moment from "moment";
import {masterServiceBaseUrl, getAPI, } from "../../../../../../services/http";
import { BsFillForwardFill } from "react-icons/bs";
import SetupDatesTable from "./setupDatesTable";
import {GetPagination} from "../../../common/pagination";
import CommonnHeader from "../CommonHeader";
import {fetchCourseYear, fetchDetailsForHeader, fetchPaymentSchedule} from "../utils-methods";
import {updateData} from "../../../../../../redux";
import {connect} from "react-redux";
var _ = require('lodash');
const SetupDates =(props)=>{
    // console.log('Props data-------------->',props);
    const { id }  = props.match.params;
    const [year,setYear] = useState([])
    const [setupDates,setSetupDates] = useState(null);
    const [courseFeesYear,setCourseFeesYear] = useState([])
    const [courseYear,setCourseYear] = useState();
    const [isSaveVisible,setIsSaveVisible] = useState(false);
    const [paymentScheduleId,setPaymentScheduleId] = useState(id);
    const [previousData,setPreviousData] = useState({
        paymentScheduleId:null,
        businessGroup :null,
        companyCode : null,
        businessAreaName : null,
        cashierOffice : null,
        courseId : null,
        courseName : null,
        term : null,
        courseStartDate : null,
        courseEndDate : null,
        lastUpdatedDate:null,
        courseDuration:null,
        courseYears:null,
        businessAreaId:null
    })
    const [pagination, setpagination] = useState({
        currentPage: 1,
        nextPage: null,
        previousPage: null,
        totalPage: null
    })
    const pageSize=10;
    const [cashier,setCashier] = useState();
    const [businessAreaData,setBusinessAreaData] = useState()
    const [companyCode,setCompanyCode] = useState();
    const [groupCode,setGroupCode] = useState();

    useEffect(()=>{
        try{
            fetchCourseYear(masterServiceBaseUrl +'/courseYear/getAllActiveCourseYear',setCourseYear);
        }catch(e){
            console.log('Error in useEffect while fetching courseYear data----->',e);
        }
    },[])



    const fetchData = async (url)=>{
        getAPI(url ,(data)=>{
            // console.log('Date----after api call---->',data);
            let n = pagination
            if (data['data'].length > pageSize) {
                n['nextPage'] = n.currentPage + 1
            }
            if (n.currentPage > 1) {
                n.previousPage = n.currentPage - 1;
            }
            n['totalPage'] =Math.floor( data['data'].length / pageSize)
            if(data['data'].length%pageSize!=0){
                n['totalPage']+=1;
            }
            setpagination(n)
            // console.log('Pagination----->',pagination);
            // console.log('Number of pages------>',n);
            if(data['data'].length>=1) setSetupDates(data.data); else {setSetupDates([]); setCourseFeesYear([])}
        },(error)=>{
            console.log('Error in fetching data---->',error);
        })
    }

    const nextPage = () => {
        setCourseFeesYear(courseFeesYear.slice((pagination.currentPage + 1) * (pageSize) - pageSize, (pagination.currentPage + 1) * pageSize))
        let n = pagination;
        n['currentPage'] = n['currentPage'] + 1;
        n['previousPage'] = n.currentPage;
        setpagination(n);
    }

    const previousPage = () => {
        setCourseFeesYear(courseFeesYear.slice((pagination.currentPage-1) * (pageSize) - pageSize, (pagination.currentPage-1) *pageSize  ))
        let n = pagination
        n['currentPage'] = n['currentPage'] -1
        if(n['currentPage'] === 1){
            n['previousPage'] = null
        }else{
            n['previousPage'] = n.currentPage
        }
        setpagination(n)
    }

    const lastPage = () => {
        setCourseFeesYear(courseFeesYear.slice((pagination.totalPage) * (pageSize) - pageSize, (pagination.totalPage) * pageSize))
        // console.log(pagination)
        let n = pagination
        n['currentPage'] = n.totalPage
        n['previousPage'] = n.totalPage-1
        setpagination(n)
    }
    const firstPage = () => {
        setCourseFeesYear(courseFeesYear.slice(1 * (pageSize) - pageSize, 1 * pageSize))
        // console.log(pagination)
        let n = pagination
        n['currentPage'] = 1
        n['previousPage'] = null
        setpagination(n)
    }

    useEffect(()=>{
        if(setupDates && setupDates.length >=1){
            let tempArray = [];
            setupDates.map((item,index)=>{
                tempArray.push({
                    id : item['id'],
                    courseYear : courseYear && courseYear.find((e)=>e.id === item['courseYearId'])['courseYearDispValue'],
                    startDate:moment(item['startDate']).format('YYYY-MM-DD'),
                    endDate:moment(item['endDate']).format('YYYY-MM-DD'),
                    totalInstallments:item['totalInstalmentCount'],
                    status :item['status'],
                    updatedDate :moment(item['updatedOn']).format('DD-MM-YYYY'),
                    isEdit:false,
                    isNew:false,
                    yearNumber : null,
                    courseYearId:item['courseYearId']
                })
                setCourseFeesYear(tempArray)
            })
        }
    },[setupDates])
    const [term,setTerm] = useState();
    const [courseName,setCourseName] = useState();
    useEffect(()=>{
        props.updateHeaderData({
            cashierOffice:cashier,
            groupCode:groupCode,
            companyCode:companyCode,
            businessArea:businessAreaData && businessAreaData.businessAreaDispValue,
            previousData:{...previousData,courseName:courseName},
            term:term
        })
    },[cashier,groupCode,companyCode,businessAreaData,previousData,term,courseName])

    useEffect(()=>{
        try{
            if(previousData && previousData.cashierOffice) {
                fetchDetailsForHeader(masterServiceBaseUrl + '/cashierOffice/getCashierOffice/' + previousData.cashierOffice, setCashier, 'cashierOffice');
            }
            if(previousData.businessAreaId){
                fetchDetailsForHeader(masterServiceBaseUrl + '/businessArea/getBusinessArea/'+previousData.businessAreaId,setBusinessAreaData ,'businessAreaData');
            }
            if(previousData.term){
                fetchDetailsForHeader(masterServiceBaseUrl+'/term/getTerm/'+previousData.term,setTerm ,'term')
            }
            if(previousData.courseId){
                fetchDetailsForHeader(masterServiceBaseUrl+'/courseDetail/getCourseDetailByCourseId/'+previousData.courseId,setCourseName,'courseName')
            }
        }catch(e){
            console.log('Error in useEffect while fetching cashier | businessArea data---->',e)
        }
    },[previousData])

    useEffect(()=>{
        try{
            if(courseYear && courseYear.length>=1){
                setYear(courseYear.map((item)=>item.courseYearDispValue))
            }
            fetchData(masterServiceBaseUrl+'/setup/dates/businessArea/'+paymentScheduleId);
            fetchDetailsForHeader(masterServiceBaseUrl+'/payment/schedule/'+paymentScheduleId,setPreviousData,'paymentSchedule');
        }catch(e){
            console.log('Error in useEffect while Fetching payment schedule data--->',e);
        }
    },courseYear)

    useEffect(()=>{
        try{
            if(businessAreaData) {
                if(businessAreaData.companyCodeId){
                    fetchDetailsForHeader(masterServiceBaseUrl+'/companyCode/getCompanyCode/'+businessAreaData.companyCodeId,setCompanyCode,'companyCode');
                }
                if(businessAreaData.groupCodeId){
                    fetchDetailsForHeader(masterServiceBaseUrl+'/groupCode/getGroupCode/'+businessAreaData.groupCodeId,setGroupCode,'groupCode');
                }
            }
        }catch(e){
            console.log('Error while fetching Group Code | Company Code data------>',e);
        }
    },[businessAreaData])

    return(
        <>
            <Card style={{marginBottom:'12px',marginLeft:'20px',marginRight:'20px',marginTop:'20px'}}>
                <CardHeader><h2 className="mb-0 floatLeft">Payment Schedule {<BsFillForwardFill style={{fontSize:'30px'}}/>} Setup Dates</h2></CardHeader>
            </Card>
            <Card style={{marginBottom:'12px',marginLeft:'20px',marginRight:'20px'}}>
                <CardHeader className="border-0"><h3 className="mb-0 floatLeft">Setup Dates</h3></CardHeader>
                <CardBody >
                    <CommonnHeader cashier={cashier}
                                   groupCode={groupCode}
                                   companyCode={companyCode}
                                   previousData={previousData}
                                   type={'setupDates'}
                                   term={term}
                                   courseName={courseName}
                                   businessArea={businessAreaData && businessAreaData.businessAreaDispValue ? businessAreaData.businessAreaDispValue:'Not Found'}
                    />
                </CardBody>
            </Card>
            <SetupDatesTable isSaveVisible={isSaveVisible}  setIsSaveVisible={setIsSaveVisible}
                             courseFeesYear={courseFeesYear}  setCourseFeesYear={setCourseFeesYear}
                             previousData={previousData}  paymentScheduleId={paymentScheduleId}
                             setupDates={setupDates} setSetupDates={setSetupDates}
                             year={year} setYear={setYear} courseYear={courseYear}
                             fetchPaymentSchedule={fetchPaymentSchedule} fetchData={fetchData}
            />
            {pagination.totalPage>1 ?
                <GetPagination
                    pagination={pagination} previousPage={previousPage}
                    firstPage={firstPage} lastPage={lastPage} nextPage={nextPage}
                />
                :null}
        </>
    )
}
const mapStateToProps = (state)=>{
    return {
        headerData :state.globalData,
    }
}
const mapDispatchToProps =dispatch=>{
    return {
        updateHeaderData : (data)=>dispatch(updateData(data))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SetupDates);