import React from "react";
import CustomColoredChip from "views/pages/manage/common/customChip/CustomChip";
import { getChipBgColor, getChipTxtColor } from "../constant";
import { CustomChipWithDot } from "views/pages/manage/edpOps/batch-serach/CustomChip";
import { ReactComponent as Cross } from "assets/img/svg/CrossIcon.svg";
import styles from "../styles.module.scss";

const ShowMaterialWithQty = ({ materials = [], deleteMaterial }) => {
  const MaterialsWithZeroQty = materials.filter(
    (material) => material?.qty === 0
  );
  const MaterialsWithQty = materials.filter((material) => material?.qty > 0);
  const MaterialsWithoutOQty = materials.filter(
    (material) => !material?.qty && material?.qty !== 0
  );

  const getChip = (item) => {
    return (
      <CustomColoredChip
        key={item?.id}
        bgColor={getChipBgColor(item?.qty)}
        textColor={getChipTxtColor(item?.qty)}
        style={{
          fontSize: "12px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span>{item?.value}</span>
        <span className="ml-4">
          {item?.qty || 0} Qty
          <span className={styles["cross-icon"]}>
            <Cross onClick={() => deleteMaterial(item?.id)} />
          </span>
        </span>
      </CustomColoredChip>
    );
  };
  return (
    <div className="w-100">
      {MaterialsWithQty.length > 0 && (
        <span className="regular-bold color-dark mt-4 mb-1">
          Added material
        </span>
      )}
      {MaterialsWithoutOQty.map((item, index) => (
        <CustomChipWithDot
          key={item.id}
          text={item.value}
          onDelete={() => deleteMaterial(item?.id)}
        />
      ))}

      {MaterialsWithQty.map((item) => (
        <div className="mb-2" key={item?.id}>{getChip(item)}</div>
      ))}
      {MaterialsWithZeroQty.length > 0 && (
        <span className="regular-bold color-dark mt-4 mb-1">
          Insufficient stock - Material not added
        </span>
      )}
      {MaterialsWithZeroQty.map((item) => (
        <div className="mb-2" key={item?.id}>{getChip(item)}</div>
      ))}
    </div>
  );
};

export default ShowMaterialWithQty;
