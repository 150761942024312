import React, { useState } from 'react';
import { Checkbox, Dialog, FormControlLabel } from '@mui/material';
import { ReactComponent as CrossIcon } from 'assets/img/svg/CrossIcon.svg';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx';
import ALoader from 'views/pages/manage/common/a-loader';
import {
  checkboxOptions,
  checkedOptionWidthMap,
  fetchStockTransactions,
  inputMap,
  searchInitialForm,
} from './constant';
import { primaryCheckboxStyles } from '../../issuanceIDcard/printIdCardAndMapRfid/constant';
import AAutoComplete from 'views/pages/manage/common/form-fields-mui/AAutoComplete';
import { inputStyles, selectStyles } from '../stockReturn/constant';
import AInput from '../../../common/formFeilds/customInput/AInput';
import AButton from '../../../common/form-fields-mui/AButton';
import SearchByOtherDetails from './SearchByOtherDetails';
import { isFormFieldEmpty } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { failureToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import { useHistory } from 'react-router';

const StockReversalSearchDialog = ({
  open,
  setOpen,
  dropdown,
  academicCareer = 'RCC',
}) => {
  const history = useHistory();
  const handleClose = () => {
    setOpen(false);
    setForm(searchInitialForm(academicCareer));
    setCheckedOption('transactionId');
  };

  const [checkedOption, setCheckedOption] = useState('transactionId'); // transactionId, otherDetails
  const [form, setForm] = useState(searchInitialForm(academicCareer));
  const [listLoader, setListLoader] = useState(false);

  const formHandler = (value, key) =>
    setForm(prev => ({ ...prev, [key]: value }));

  const handleCheckboxChange = option => {
    setCheckedOption(option);
    setForm(searchInitialForm(academicCareer)); // reset the form
  };

  const handleTransactionSearch = async () => {
    setListLoader(true);
    const res = await fetchStockTransactions(0, form);
    if(res?.code===200) {
      const rows = res?.data?.stockTransactionAudList?.map(item => ({ ...item, sapPlantId: form?.businessArea?.sapPlantId}));
      if (isFormFieldEmpty(rows)) {
        failureToast('No records found');
      } else {
        // redirect
        const {businessArea, businessAreaValue, transactionId} = rows?.[0] || {};
        redirectToReversal(transactionId, businessArea, businessAreaValue);
      }
    }
    setListLoader(false);
  };

  const redirectToReversal = (transactionId, businessAreaId, businessAreaValue) =>
    window.open(
      history.createHref({
        pathname: `/admin/edp/stockManagement/stockReversal?academicCareer=${academicCareer}&transactionId=${transactionId}&businessAreaId=${businessAreaId}&businessAreaValue=${businessAreaValue}`,
      }),
      '_blank'
    )

  return (
    <Dialog
      open={open}
      PaperProps={{ sx: { maxWidth: 'unset', borderRadius: '12px' } }}
    >
      {listLoader && <ALoader />}
      <div
        style={{
          padding: '28px 24px',
          width: checkedOptionWidthMap[checkedOption],
        }}
      >
        <div className='d-flex justify-content-between align-items-center'>
          <div className='heading-4 color-black'>
            Search transaction ID for reversal
          </div>
          <IconButtonWrapper onClick={handleClose}>
            <CrossIcon width={24} height={24} />
          </IconButtonWrapper>
        </div>

        <div className='d-flex mt-4' style={{ gap: '8px' }}>
          {checkboxOptions(checkedOption, handleCheckboxChange)?.map(item => (
            <FormControlLabel
              key={JSON.stringify(item)}
              disableTypography
              // className='flex-grow-1'
              sx={{ margin: '0px', ...primaryCheckboxStyles }}
              control={
                <Checkbox
                  sx={{ padding: '3px' }}
                  onChange={item?.onChange}
                  checked={item?.checked}
                />
              }
              label={
                <div
                  className={`semi-bold ${
                    item?.checked ? 'label-color' : 'color-grey-60'
                  }`}
                >
                  {item?.title}
                </div>
              }
            />
          ))}
        </div>

        <div className='mt-3'>
          {checkedOption === 'otherDetails' ? (
            <SearchByOtherDetails
              checkedOption={checkedOption}
              academicCareer={academicCareer}
              form={form}
              formHandler={formHandler}
              dropdown={dropdown}
              setListLoader={setListLoader}
              redirectToReversal={redirectToReversal}
            />
          ) : (
            <div className='d-flex' style={{ gap: '28px' }}>
              <div style={{ flex: 0.4 }}>
                <AAutoComplete
                  label='Business area*'
                  placeholder='Select'
                  isMulti={false}
                  items={dropdown?.businessAreas}
                  currentValue={form?.businessArea}
                  handleChange={value => {
                    formHandler(value, 'businessArea');
                  }}
                  style={selectStyles}
                />
              </div>
              <div className='flex-grow-1 position-relative'>
                <AInput
                  label={`${inputMap?.[checkedOption].label}*`}
                  placeholder={
                    inputMap?.[checkedOption]?.placeholder || `Enter`
                  }
                  style={inputStyles}
                  value={form?.[checkedOption]}
                  regex={inputMap?.[checkedOption]?.regex}
                  handler={value => {
                    if (inputMap?.[checkedOption].validLength) {
                      value?.length <= inputMap[checkedOption].validLength &&
                        formHandler(value?.toUpperCase(), checkedOption);
                    } else {
                      formHandler(value?.toUpperCase(), checkedOption);
                    }
                  }}
                  crossIcon={true}
                  crossHandler={() => formHandler('', checkedOption)}
                />
              </div>
            </div>
          )}
        </div>

        {checkedOption === 'transactionId' && (
          <div className='mt-3'>
            <AButton
              variant='primary_filled'
              className='ml-0'
              disabled={!form.businessArea || !form.transactionId}
              onClick={handleTransactionSearch}
            >
              Continue
            </AButton>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default StockReversalSearchDialog;
