import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { ReactComponent as CloseIcon } from "../../../../../../../assets/img/svg/CloseIcon.svg"
import { ReactComponent as Document } from "../../../../../../../assets/img/svg/DocumentLight.svg"
// import { ReactComponent as File } from "../../../../../../../assets/img/DocumentLarge.jpg"
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import { getUniqueKey } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { failureToast, successToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import { putpost, uploadpost } from "services/utils";
import { endpoint } from "views/pages/manage/common/constant";
import ALoader from "views/pages/manage/common/a-loader";
import { Row,Col } from "reactstrap";
import { fetchAllPostPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";

const DocUploadModal = (
  {
    open,
    setOpen,
    saveHandler,
    values,
    acceptedFileTypes=[],
    maxUploadFileSize, // in Mb
    serviceName,
    uniqueKeyParameter,
    uploadedFileS3Key,
    uploadedFileName,
    modalTitle,
    viewMode=false,
    viewUploadedImage=false
  }
) => {
  const maxFileSize = maxUploadFileSize*1048576; // converting to bits
  const [file, setFile] = useState(null);
  const [loading,setLoading]=useState(false);
  // const [fileData, setFileData] = useState({s3Key:values?.gstDetails?.gstDocumentS3Key, fileName:values?.gstDetails?.gstCertificateName})
  const [fileData, setFileData] = useState({s3Key:uploadedFileS3Key, fileName:uploadedFileName})
  const [fileUrl, setFileUrl]=useState('');

  const dataToServer = {
    bulk: false,
    fileName: null,
    docType: "pdf",
    keyValue: `${uniqueKeyParameter}_${getUniqueKey()}`,
    serviceName: serviceName
  }

  const headerStyles={
    fontSize:'16px',
    fontWeight:600,
    color:'#0F0F0F'
   }

  const infoStyles={
    fontSize:'14px',
    fontWeight:400,
    color:'#545454'
  }

  const uploadLabelStyles={
    ...headerStyles,
    display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  letterSpacing: '1px',
  outline: 'none',
  border: 'none',
  padding: '10px 16px',
  backgroundColor: '#00B0F5',
  color: '#FFFFFF',
  width:'100%',
  borderRadius:'8px',
  cursor:'pointer'
  }

  const updateLabelStles={
    ...uploadLabelStyles,
    color: '#00B0F5',
    border: '2px solid #00B0F5',
    backgroundColor:"#FFFFFF",
    padding:'8px'
  }

  const showFile = (dataFile, targetFile) => {
    console.log('targetFile', targetFile);
    try {
      const fileSelected = dataFile || targetFile;
      const fileType = fileSelected?.name?.split(".")?.pop()?.toLowerCase();
      if (!acceptedFileTypes?.includes(`.${fileType}`)) {
        failureToast("File type not supported!!")
        return;
      }
      if (fileSelected.size > maxFileSize) {
        failureToast(`The file size exceeds the limit of ${maxUploadFileSize} MB. Please reduce the size of the file to upload successfully`)
        return;
      }
      setFile(fileSelected)
      setLoading(true)
      getFilePath(fileSelected.name, fileSelected);
    } catch (e) {
      console.log("Error", e);
      setLoading(false)

    }
  };

  const getFilePath = (name, file) => {
    try {
      setLoading(true)
      const formData = {
        ...dataToServer,
        fileName: name,
        docType: name?.split(".")?.pop()?.toLowerCase(),
      };

      putpost(
        endpoint.fetchS3Url.getUrl,
        (data) => {
          if (data?.code === 200 && data.data) {
            saveDataToStorage(data.data, file);
          }
        },
        (data) => {
          failureToast(data["message"]);
          setLoading(false)
          setFile(null)

        },
        formData,
        "post"
      );
    } catch (e) {
      console.log(e);
      setLoading(false)

    }
  };
  const saveDataToStorage = (res, file) => {
    try {
      const formData = new FormData();

      formData.append("File", file);
      uploadpost(
        res.storage,
        (data) => {
          setFileData({...fileData, s3Key: res.key, fileName:file.name});
          successToast("File uploaded successfully");
          setLoading(false)

        },
        (data) => {
          failureToast("File upload failed, please try again after sometime.");
          setLoading(false)

        },
        file,
        file?.type,
        file?.name
      );
    } catch (e) {
      failureToast("Unable to upload file, please try after sometime.");
      setLoading(false)

    }
  };

  useEffect(()=>{
   if(viewUploadedImage && fileData?.s3Key){
    downLoadFile(false,setFileUrl);
   }
  },[fileData])

  const downLoadFile = async(download=true, setFileUrl) => {
    const templateUrl = await fetchAllPostPromisedData(`${endpoint.fetchS3Url.getDownloadUrl}?key=${fileData?.s3Key}`,{})
    if (templateUrl?.code === 200 && templateUrl?.data) {
      if(!download){
        setFileUrl(templateUrl?.data?.url)
      }else{
      window.open(`${templateUrl?.data?.url}`)
      }
    }else{
      failureToast("Something went wrong please try after sometime.")
    }
  };

  const FileInput=({action,styles})=>{
    return(
      <>
      <label htmlFor="gstFileInput" style={styles}>
          {action}
        </label>
        <input
          onChange={(e) => showFile(null, e.target.files[0])}
          type="file"
          hidden
          id="gstFileInput"
          accept={acceptedFileTypes.join(",")}
        />
      </>
    )
  }

  const DisplayImportInput = () => {
    return (
      <>
        <div className="d-flex flex-column align-items-center justify-content-center my-4">
          <Document/>
          <span className="mt-2" style={{...infoStyles, color:'#333'}}>{`File supported: ${acceptedFileTypes.join(", ")}` }</span>
        </div>
        <FileInput action={'Upload'} styles={uploadLabelStyles}/>
      </>
    )
  }

  const DisplayFile = ({ fileName, setFile, onDownload }) => {
    console.log('file Name', fileName)
    return (
      <>
        <div className="d-flex flex-column justify-content-center align-items-center my-4">
          {viewUploadedImage ?
          <img src={fileUrl} style={{height:'225px', width:'225px'}}/> :
          <img src={require("../../../../../../../assets/img/DocumentLarge.jpg")}/>
        }
          <span className="mt-2" style={infoStyles}>{fileName}</span>
        </div>
        
        {!viewMode && <div className="d-flex flex-column justify-content-center align-items-center">
        <AButton
        className='ml-0'
        children={'Save'}
        variant="primary_filled"
        onClick={()=>{saveHandler(fileData.s3Key,fileData?.fileName)}}
        updatedStyle={{width:"100%", marginBottom:'6px'}}
        />
        
       <div className="d-flex justify-content-between w-100">
        <FileInput action={'Update'} styles={updateLabelStles}/>
      
        <AButton
        children={'Download'}
        variant='outline'
        onClick={onDownload}
        updatedStyle={{width:"100%"}}
        />
        </div>
        </div>}
      </>
    )

  }


  return (
    <Modal size='sm' isOpen={open}>
      <ModalBody>
        <div className="d-flex justify-content-between mb-1">
          <div style={headerStyles}>{modalTitle}</div>
            <CloseIcon style={{ cursor: "pointer" }} onClick={()=>{setFile(null); setOpen(false)}} />
        </div>

        <div style={infoStyles}>{`Max file size ${maxUploadFileSize} MB`}</div>
        {loading ? <div style={{height:'100px'}}><ALoader/></div> :
        ! (file||fileData?.s3Key) ? 
        <DisplayImportInput showFile={showFile} /> :
         <DisplayFile 
          onDownload={downLoadFile}
          fileName={file?.name||fileData?.fileName}
          setFile={setFile} />}
           <a style={{ display: "none" }} id="link" download></a>

      </ModalBody>
    </Modal>

  );
}

export default DocUploadModal;