import { fetchAllPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "views/pages/manage/common/constant";
import { useEffect, useState } from "react";

const fetchPaymentDetails = async (applicationDetails, setter, setLoading) => {
  const { applicationId, businessAreaDispValue } = applicationDetails;

  const url = `${endpoint.getFeeBreakupWithPaymentDetails}/${applicationId}?businessArea=${businessAreaDispValue}`;
  try {
    setLoading(true);
    const response = await fetchAllPromisedData(url, true);
    if (response?.data) {
      setter(response?.data);
    } else {
      setter(null);
    }
    setLoading(false);
  } catch (error) {
    setLoading(false);
  }
};

export const useFetchPaymentDetails = (applicationDetails) => {
  const [isLoading, setLoading] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({});

  useEffect(() => {
    if (applicationDetails)
      fetchPaymentDetails(applicationDetails, setPaymentDetails, setLoading);
  }, [applicationDetails]);

  return [paymentDetails, isLoading];
};
