import { enrollmentServiceBaseUrl } from "services/http";

export const enrollmentServiceEndpoints = {
  allocateStudents: {
    searchStudentApplications: `${enrollmentServiceBaseUrl}/studentApplications/searchStudentApplications`,
    allocateDeallocate: `${enrollmentServiceBaseUrl}/studentApplications/allocateDeallocate`,
    downloadCsv: `${enrollmentServiceBaseUrl}/studentApplications/searchStudentApplicationsDownloadCsv`,
  },
  studentSearch: {
    validateApplicationForMaterialIssue: `${enrollmentServiceBaseUrl}/studentApplications/validateApplicationForMaterialIssue`,
    getBatchesByApplicationId: `${enrollmentServiceBaseUrl}/studentApplications/getAllBatchesByApplicationId`,
    downloadCsvForRfIdMapping: `${enrollmentServiceBaseUrl}/studentApplications/searchStudentApplicationsRfidDownloadCsv?offset`,
    getUploadedCSVlist: `${enrollmentServiceBaseUrl}/studentApplications/getFileList`,
    getStudentsForIssueStock: `${enrollmentServiceBaseUrl}/studentApplications/searchStudentStockIssuance`,
    issueStock: `${enrollmentServiceBaseUrl}/studentApplications/savePsidStock`,
    searchStockTransactions: `${enrollmentServiceBaseUrl}/studentApplications/searchStockTransactions`,
    getStockTransactionDetails: `${enrollmentServiceBaseUrl}/studentApplications/getStockTransactionDetails`,
    checkStockReturnEligibility: `${enrollmentServiceBaseUrl}/studentApplications/checkStockReturnEligibility`,
    getDisabledStockModules: `${enrollmentServiceBaseUrl}/studentApplications/getDisabledStockModules`,
    getAllReturnReasonsOptions: `${enrollmentServiceBaseUrl}/studentApplications/getAllReturnReasonsOptions`,
    psidStockReturn: `${enrollmentServiceBaseUrl}/studentApplications/psidStockReturn`,
    downloadStockTransactionDetails: `${enrollmentServiceBaseUrl}/studentApplications/downloadStockTransactionDetails`,
    downloadStuBatchAloAndNotAlo: `${enrollmentServiceBaseUrl}/studentApplications/searchStudentStockIssuanceDownloadCsv`,
  },
  student: {
    resetPassword: `${enrollmentServiceBaseUrl}/student/resetPassword`,
  },
  studentDetails: {
    getAllBatchesByPsid: `${enrollmentServiceBaseUrl}/studentApplications/getAllBatchesByPsid`,
    getAllApplicationByPsid: `${enrollmentServiceBaseUrl}/studentApplications/getAllApplicationByPsid`,
    getStudentsInfo: `${enrollmentServiceBaseUrl}/student/getStudent`,
    getStudentByPsid: `${enrollmentServiceBaseUrl}/studentDetails/getStudentByPsid`,
    createMsTeamsIdByPsid: `${enrollmentServiceBaseUrl}/studentDetails/createMsTeamsIdByPsid`,
    studentCountWithNoRfId: `${enrollmentServiceBaseUrl}/studentApplications/StudentCountWithNoRfid`,
    getPrintableData: `${enrollmentServiceBaseUrl}/studentApplications/rfidPrintableData`,
    getStudentPersonalInfo: `${enrollmentServiceBaseUrl}/studentDetails/getStudentDetails`,
    getStudentContactDetails: `${enrollmentServiceBaseUrl}/studentDetails/getStudentContactDetails`,
    getPaymentInformationForPsid: `${enrollmentServiceBaseUrl}/studentApplications/getPaymentInformationForPsid`,
  },
  loanCareerValidation: {
    create: `${enrollmentServiceBaseUrl}/loan-career-validation/createLoanCareerValidation`,
    update: `${enrollmentServiceBaseUrl}/loan-career-validation/updateLoanCareerValidation`,
    getAll: `${enrollmentServiceBaseUrl}/loan-career-validation/getAllLoanCareerValidation`,
    delete: `${enrollmentServiceBaseUrl}/loan-career-validation/deleteLoanCareerValidation`,
  },
  getStudentChargesList: `${enrollmentServiceBaseUrl}/studentCharges/searchApplications`,
  getStudentChargesApplicationDetails: `${enrollmentServiceBaseUrl}/studentCharges/applicationDetails`,
  studentChargesConcessionDetails: {
    sendForApproval: `${enrollmentServiceBaseUrl}/studentApplicationCharges/concessionDetails/saveAndSendForApproval`,
  },
  getConssessionDetails: `${enrollmentServiceBaseUrl}/studentApplicationCharges/concessionDetails/applicationQuotationDetailsId`,
  getConssessionDetailsByApplicationId: `${enrollmentServiceBaseUrl}/studentApplicationCharges/concessionDetails/applicationId`,
  finalizeCharges: `${enrollmentServiceBaseUrl}/studentChargesFeeBreakup`,
  lumpSumConcession: {
    getAll: `${enrollmentServiceBaseUrl}/lumpSumConcession/getAllActiveLumpSumConcession`,
    create: `${enrollmentServiceBaseUrl}/lumpSumConcession/createLumpSumConcession`,
    delete: `${enrollmentServiceBaseUrl}/lumpSumConcession/deleteLumpSumConcession`,
    update: `${enrollmentServiceBaseUrl}/lumpSumConcession/updateLumpSumConcession`,
    getByCareerId: `${enrollmentServiceBaseUrl}/lumpSumConcession/getLumpSumConcessionByCareer`,
  },
  concessionConfig: {
    create: `${enrollmentServiceBaseUrl}/customConcessionConfig/createConfig`,
    update: `${enrollmentServiceBaseUrl}/customConcessionConfig/updateConfig`,
    delete: `${enrollmentServiceBaseUrl}/customConcessionConfig/deleteConfig`,
    search: `${enrollmentServiceBaseUrl}/customConcessionConfig/searchConfig`,
  },
  configExAakashian: {
    getById: `${enrollmentServiceBaseUrl}/config/exAakashian`,
    getAll: `${enrollmentServiceBaseUrl}/config/exAakashian/all`,
    delete: `${enrollmentServiceBaseUrl}/config/exAakashian`,
    update: `${enrollmentServiceBaseUrl}/config/exAakashian`,
    create: `${enrollmentServiceBaseUrl}/config/exAakashian`,
  },
  getAllProgramStatus: `${enrollmentServiceBaseUrl}/studentApplications/programStatus/all`,
  approval: {
    status: `${enrollmentServiceBaseUrl}/studentApplicationCharges/concessionDetails/updateApprovalStatus`,
    export: `${enrollmentServiceBaseUrl}/applicationApprovalRequests/export`,
  },
  otherCharges: {
    getOtherCharges: `${enrollmentServiceBaseUrl}/studentCharges/otherCharges/applicationId`,
    getAll: `${enrollmentServiceBaseUrl}/applicationOtherChargesDetails/applicationId`,
  },
  getDocumentsByapplicationConcessionId: `${enrollmentServiceBaseUrl}/studentApplicationCharges/concessionDocument/concession`,
  verifyDocuments: `${enrollmentServiceBaseUrl}/studentApplicationCharges/concessionDocument/verify`,
  otherCharges: {
    otherChargesForApproval: `${enrollmentServiceBaseUrl}/applicationOtherChargesDetails/search/approvalRequests`,
  },
  idCardIssuance: {
    mapRfidWithApplication: `${enrollmentServiceBaseUrl}/studentApplications/mapRfidWithApplication`,
    rfidStatusChange: `${enrollmentServiceBaseUrl}/studentApplications/rfidStatusChange`,
    checkIfRfidExists: `${enrollmentServiceBaseUrl}/studentApplications/checkIfRfidExists`,
  },
  getConcessionApprovalStatus: `${enrollmentServiceBaseUrl}/applicationCustomConcessionApprovalDetails/applicationConcessionDetail`,

  materialMaster: {
    searchMaterialMaster: `${enrollmentServiceBaseUrl}/materialMaster/searchMaterialMaster`,
    getMaterialBarcodes: `${enrollmentServiceBaseUrl}/materialMaster/getMaterialBarcodes`,
    getMaterialFlags: `${enrollmentServiceBaseUrl}/materialMaster/getMaterialFlags`,
  },
  groupTypeMaterialMapping: {
    getActiveGroupType: `${enrollmentServiceBaseUrl}/groupTypeMaterialMapping/getActiveGroupType`,
    search: `${enrollmentServiceBaseUrl}/groupTypeMaterialMapping/searchMapping`,
    create: `${enrollmentServiceBaseUrl}/groupTypeMaterialMapping/createMapping`,
    edit: `${enrollmentServiceBaseUrl}/groupTypeMaterialMapping/editMapping`,
    delete: `${enrollmentServiceBaseUrl}/groupTypeMaterialMapping/deleteMapping`,
    isMaterialGroupMappingExists: `${enrollmentServiceBaseUrl}/groupTypeMaterialMapping/isMaterialGroupMappingExists`,
  },

  barcodeReconciliation: {
    getFailedTransactions: `${enrollmentServiceBaseUrl}/reconciliation/searchFailedTransactions`,
    editUnfreezeBarcodes: `${enrollmentServiceBaseUrl}/reconciliation/barcodeReconciliation`,
    getTransactionMaterialDetails: `${enrollmentServiceBaseUrl}/studentApplications/getStockTransactionDetails`,
  },

  issueStockHistory: {
    getTransactionList: `${enrollmentServiceBaseUrl}/groupTypeMaterialMapping/deleteMapping`,
  },
  getDispatchMaterialStatusForPsid: {
    getMaterials: `${enrollmentServiceBaseUrl}/studentApplications/getDispatchMaterialStatusForPsid`,
  },
  miscCourseMaterialMapping: {
    create: `${enrollmentServiceBaseUrl}/miscCourseMaterialMapping/createMapping`,
    update: `${enrollmentServiceBaseUrl}/miscCourseMaterialMapping/editMapping`,
    delete: `${enrollmentServiceBaseUrl}/miscCourseMaterialMapping/deleteMapping`,
    getAll: `${enrollmentServiceBaseUrl}/miscCourseMaterialMapping/getAllMapping`,
    getMaterialByMiscCourseId: `${enrollmentServiceBaseUrl}/miscCourseMaterialMapping/getMaterialByMiscCourseId`,
    getMiscCourseMaterials: `${enrollmentServiceBaseUrl}/miscCourseMaterialMapping/getMiscCourseMaterials`,
  },
  dispatchSchedule: {
    search: `${enrollmentServiceBaseUrl}/dispatchSchedule/searchStockDispatch`,
    delete: `${enrollmentServiceBaseUrl}/dispatchSchedule/deleteStockDispatch`,
    update: `${enrollmentServiceBaseUrl}/dispatchSchedule/updateStockDispatch`,
    create: `${enrollmentServiceBaseUrl}/dispatchSchedule/saveStockDispatch`,
    getAllDlpUploadFiles: `${enrollmentServiceBaseUrl}/dispatchSchedule/getAllDlpUploadFiles?uploadType`,
  },
  getAllActiveDispatchCourseIds: `${enrollmentServiceBaseUrl}/dispatchSchedule/getAllActiveDispatchCourseIds`,
  markStudentDLPStockEligible: `${enrollmentServiceBaseUrl}/studentApplications/markStudentDLPStockEligible`,
  searchStudentDLPStockEligible: `${enrollmentServiceBaseUrl}/studentApplications/searchStudentDLPStockEligible`,
  editStudentDLPStockEligible: `${enrollmentServiceBaseUrl}/studentApplications/editStudentDLPStockEligible`,
  downloadStockReport: `${enrollmentServiceBaseUrl}/studentApplications/downloadStockReport`,
  DLPStudentSearch: `${enrollmentServiceBaseUrl}/studentApplications/DLPStudentSearch`,
  updateStudentDLPStockEligibleByIds: `${enrollmentServiceBaseUrl}/studentApplications/updateStudentDLPStockEligibleByIds`,
  getAddressSlipByPsid: `${enrollmentServiceBaseUrl}/addressSlips/getAddressSlipByPSID`,
  downloadStudentDLPStockEligible: `${enrollmentServiceBaseUrl}/studentApplications/downloadStudentDLPStockEligible`,
  notifyCourierDetails: `${enrollmentServiceBaseUrl}/studentApplications/notifyCourierDetails`,
  notifyLogistics: `${enrollmentServiceBaseUrl}/studentApplications/notifyLogistics`,
  getBarcodeByNumber: `${enrollmentServiceBaseUrl}/materialGrnNumber/getBarcodeByNumber`,
  notifyDlpStock: `${enrollmentServiceBaseUrl}/studentApplications/notifyDlpStock`,

  studentSupplementary: {
    requestSupplementaryId: `${enrollmentServiceBaseUrl}/studentSupplementary/saveStudentSupplementaryDetails`,
    search: `${enrollmentServiceBaseUrl}/studentSupplementary/searchStudentSupplementaryDetails`,
    approveSupplementaryId: `${enrollmentServiceBaseUrl}/studentSupplementary/saveApprovedSupplementaryId`,
    changeProgramActionMlbaToDmba: `${enrollmentServiceBaseUrl}/studentApplications/changeProgramActionMlbaToDmba`,
  },
  studentChargesCreationEligibility: `${enrollmentServiceBaseUrl}/studentCharges/studentChargesCreationEligibility`,
};
