const messages = {
  selectAcademicCareer :'Please Select Academic Career.',
  dataNotFound : 'No matching values found.',
  selectBusinessGroup : 'Please Select Business Group',
  selectCompanyCode : 'Please Select Company Code',
  selectTerm : 'Please Select Term',
  selectCourseType : 'Please Select Course Type',
  unsavedChanges : 'This page contains unsaved data. Do you want to continue without saving the changes?',
  closeMessage: 'Are you sure you want to leave this page?'
}

const formLabel = {
  businessGroup :'Business Group',
  companyCode : 'Company Code',
  term : 'term',
  academicCareer : 'AcademicCareer',
  courseType : 'Course Type',
  status : 'Status'
}

const button = {
  addNew : 'Add New Item Type Sequence',
  search:'Search',
  reset:"Reset",
  add:'Add',
  close:'Close'
}
const headerList = [
  {name: "SEQUENCE NUMBER" ,width:'8%'},
  {name: "INSTALLMENT NUMBER",width: '8%' },
  {name: "ITEM TYPE" , width:'13%'},
  {name: "DECRIPTION"},
  {name: "% For Total Amount",width:'8%'},
  {name: "Tax Code"},
  {name: "Total Tax Amount",width:'11%'},
  {name: "Action",width:'5%'}
]
export const data = {
  button:button,
  heading : 'Item Type Sequence Search',
  messages : messages,
  formLabel : formLabel,
  headerList:headerList
}