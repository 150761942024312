import React, { useState } from "react";
import "../../stockConsumption/style.scss";
import { ReactComponent as EditIcon } from "assets/img/svg/Edit2.svg";
import { ReactComponent as CrossIcon } from 'assets/img/svg/CrossIcon.svg';
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx";
import NoRowOverlay from "../../../../masters/holidayMaster/NoRowOverlay";
import { Tooltip } from "@mui/material";
import AButton from "../../../../common/form-fields-mui/AButton";
import useCustomConfirmationPrompt from '../../../../common/commonComponents/ConfirmationDialog/CustomConfirmationPrompt';

import {
  getChipBgColor,
  getChipTxtColor,
} from "../../selectMeterialAndStock/constant";
import CustomColoredChip from "views/pages/manage/common/customChip/CustomChip";
import AInput from "../../../../common/formFeilds/customInput/AInput";
import { regex } from "../../../../common/constant";


export const DisplayRowData = ({
  row,
  index,
  saveBarcodeHandler,
  rows,
  setRows,
  errMsg,
  setErrMgs

}) => {
  const [barcode, setBarcode] = useState(row?.barcode);
  const greyColorClass = row?.isIssuedAlready ? "color-grey-50" : "";
  const [EditConfirmDialog, editConfirm] = useCustomConfirmationPrompt();

  const editBarcodeHandler = async(index, row) => {
    const tempRows = [...rows];
    const barcode =tempRows[index]?.barcode
     if(barcode){
      const canContinue = await editConfirm();
      if(!canContinue) return false
     }
    tempRows[index] = { ...row, barcode, isSaved: false };
    setRows(tempRows);
    setErrMgs(false)
  };

  const cancelSaveHandler = (index, row , oldBarcode) => {
    const tempRows = [...rows];
    tempRows[index] = { ...row,isSaved: true, barcode:oldBarcode };
    setRows(tempRows);
    setErrMgs(false)
  }


  return (
    <div className={`add-barcode-dlp ${greyColorClass}`}>
      <div>{row?.dispatchSchedule || "-"}</div>
      <Tooltip title={row?.materialDescription}>
        <div className="ellipsis-text-container">{`${row?.materialNumber} | ${row?.materialDescription}`}</div>
      </Tooltip>
      <div>
        {row?.isIssuedAlready ? (
          <div>-</div>
        ) : (
          <CustomColoredChip
            text={`${row?.barcodeCount} Left`}
            bgColor={getChipBgColor(row?.barcodeCount)}
            textColor={getChipTxtColor(row?.barcodeCount)}
            style={{ fontSize: "12px" }}
          />
        )}
      </div>
      <div>
        {row?.isIssuedAlready ? (
          <div >{barcode}</div>
        ) : (
          <div>
            {row?.isSaved ? (
              row?.barcode
            ) : (
              <AInput
                placeholder="Enter"
                regex={regex.alphaNumeric}
                disabled={row?.barcodeCount === 0}
                value={barcode}
                handler={(value) => setBarcode(value)}
                error ={errMsg}
              />
            )}
          </div>
        )}
      </div>
      {row?.isIssuedAlready ? (
        <div className="m-auto" style={{ minWidth: "65px" }}>
          {" "}
          -{" "}
        </div>
      ) : (
        <div className="m-auto">
          {row?.isSaved ? (
            <IconButtonWrapper onClick={() => editBarcodeHandler(index, row)}>
              <EditIcon />
            </IconButtonWrapper>
          ) : (
            <div>
            <AButton
              size="xs"
              variant="primary_filled"
              onClick={() => saveBarcodeHandler(index, barcode, row)}
              className="btn-left-0"
              disabled={barcode === "" || row?.barcodeCount === 0}
            >
              Save
            </AButton>
            <IconButtonWrapper
            className="ml-2"
            onClick={() => cancelSaveHandler(index, row, row?.barcode)}
            >
            <CrossIcon />
          </IconButtonWrapper>
            </div>
          )}
        </div>
      )}
      <EditConfirmDialog
        isQuestionIconRequired={false}
        headerMsg={'Changing the barcode can have implications on inventory'}
        msg={'Do you want to continue?'}
        cancelBtnProps={{ variant: 'outline' }}
        confirmBtnProps={{ variant: 'outline_danger' }}
      />    </div>
  );
};

const TableRow = (props) => {
  return <DisplayRowData {...props} />;
};

const AddBarcodeCustomTable = ({
  rows,
  setRows,
  loading,
  setLoading,
  saveBarcodeHandler,
  editBarcodeHandler,
  issuedMaterialList,
  setErrMgs,
  errMsg
}) => {
  if (rows.length === 0)
    return (
      <div className="mt-2">
        <TableHeader />
        <NoRowOverlay />
      </div>
    );

  return (
    <div className="m-2">
      <TableHeader />
      {rows.map((item, index) => (
        <TableRow
          key={`new-${JSON.stringify(item)}}`}
          row={item}
          index={index}
          loading={loading}
          setLoading={setLoading}
          saveBarcodeHandler={saveBarcodeHandler}
          rows={rows}
          setRows={setRows}
          setErrMgs = {setErrMgs}
          errMsg=   {errMsg}
        />
      ))}

      {issuedMaterialList.map((item, index) => (
        <TableRow
          key={item?.barcode}
          row={item}
          index={index}
          loading={loading}
          setLoading={setLoading}
          saveBarcodeHandler={saveBarcodeHandler}
          rows={rows}
          setRows={setRows}
          setErrMgs = {setErrMgs}
          errMsg=   {errMsg}
        />
      ))}
    </div>
  );
};

const TableHeader = () => {
  return (
    <div className="header-column  add-barcode-dlp">
      <div>Dispatch Id</div>
      <div>Materials to be issue</div>
      <div>Qty left</div>
      <div>Barcode number</div>
      <div className="m-auto">Action</div>
    </div>
  );
};

export default AddBarcodeCustomTable;
