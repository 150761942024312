import { Checkbox, Divider, FormControlLabel, Link, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import CustomChip from '../../common/customChip/CustomChip'
import DataCell from './DataCell'
import styles from './styles.module.scss'
import { ReactComponent as ActiveControl } from '../../../../../assets/img/svg/ActiveControl.svg';
import { ReactComponent as InactiveControl } from '../../../../../assets/img/svg/InactiveControl.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/img/svg/Edit2.svg';
import { ReactComponent as ClipIcon } from '../../../../../assets/img/svg/ClipIcon.svg';
import moment from 'moment'
import TestSyllabus from './TestSyllabus'
import commonStyles from '../common/commonStyles.module.css'
import { ReactComponent as CopyIcon } from '../../../../../assets/img/svg/CopyClipboardIcon.svg'
import TestStatusChangeConfirmationDialog from './TestStatusChangeConfirmationDialog'
import APopover from '../../common/a-popover'
import TestForm from '../academicTest/TestForm'
import AttachTestForm from './AttachTestForm'
import { getTestMediumDisplayValue } from './constant'
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper'

export const getDateFormateDate = (date) => {

  if (moment(date).isValid()) {
    return new moment(date).format('DD/MM/YYYY')
  }
  return "-"
}
const TestCard = ({
  planData,
  testData,
  index,
  expandedIndex,
  setExpandedIndex,
  initialiseList,
  updateTestStatusHandler = () => { },
  updateAttachTestHandler = () => { },
  ...props
}) => {

  const [toolTipText, setToolTipText] = useState('Copy')
  const containerClass = props?.isImportedTest ? styles.imported_test_card_data_container : styles.test_card_data_container

  const [openDialog, setOpenDialog] = useState(false);
  const isIncomplete = (testData?.startDate && testData?.endDate && testData?.resultDate && testData?.mode && testData?.format);


  const isPastDate = (date) => {
    if (!moment(date).isValid()) {
      return false
    }
    return new Date(date) < new Date().setHours(0, 0, 0, 0)

  }

  const activeInactiveHandler = () => {
    if (props?.isImportedTest)
      return false;

    if (isIncomplete)
      setOpenDialog(true)
  }



  // used for chaching the response
  // const [isSyllabusFetched, setIsSyllabusFetched] = useState(false);
  // const [testSyllabus, setTestSyllabus] = useState([]);

  // for update form
  const [isFormOpen, setIsFormOpen] = useState(false);
  const testBasicDetails = {
    ...testData,
    testType: { label: testData?.testType, value: testData?.testType },
    isMandatory: testData?.isMandatory,
    format: { label: testData?.format, value: testData?.format },
    mode: { label: testData?.mode, value: testData?.mode },
    testNumber: "",
    startDate: testData?.startDate,
    endDate: testData?.endDate,
    resultData: testData?.resultDate,
  }

  return (
    <>
      <Card className='mb-2'>
        <CardBody className='p-3'>
          <div className='d-flex justify-content-xl-between mb-2'>
            <div className='d-flex'>
              <h3 style={{ fontSize: '20px' }}>{testData?.testType}</h3>
              <CustomChip
                // text={}
                textColor='#006A93'
                bgColor={'#CCEFFD'}
                style={{ marginLeft: '1rem' }}
              >
                <div>
                  {testData?.testName || 'Name not found'}
                  <Tooltip title={toolTipText}>
                    <span style={{ marginLeft: '5px' }}>
                      <CopyIcon
                        onClick={() => {
                          navigator.clipboard.writeText(testData?.testName);
                          setToolTipText("Copied")
                        }}
                      />
                    </span>
                  </Tooltip>
                </div>
              </CustomChip>
              <CustomChip
                text={testData?.isMandatory ? 'Mandatory test' : 'Optional test'}
                textColor='#006A93'
                bgColor={'#CCEFFD'}
                style={{ marginLeft: '1rem' }}
              />

              {testData?.isAlreadyImported &&
                <CustomChip
                  text="This test is already imported in this Academic plan"
                  textColor={"#333333"}
                  bgColor={"#FFEDC8"}
                  style={{ marginLeft: "1rem" }}
                />
              }

            </div>
            <div>
              {testData?.isImportedTest &&
                <span className='semi-bold color-dark'>
                  Imported test
                </span>
              }
              {!isIncomplete && (
                <CustomChip
                  className='ml-2'
                  text={"Incomplete details"}
                  textColor={"#705010"}
                  bgColor={"#FFEDC8"}
                  style={{ marginRight: "5px" }}
                />
              )}
            </div>
          </div>
          <Divider />

          <div className={`${containerClass} mt-2`}>
            {props?.isImportedTest &&
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => props?.importTestHandler(testData?.id)}
                    disabled={testData?.isAlreadyImported}
                  />
                }
              />
            }
            <DataCell title={'Format'} data={testData?.format || "-"} />
            <DataCell title={'Mode'} data={testData?.mode || "-"} />
            <DataCell title={'Start date'} data={getDateFormateDate(testData?.startDate)} />
            <DataCell title={'End date'} data={getDateFormateDate(testData?.endDate)} />
            <DataCell title={'Result date'} data={getDateFormateDate(testData?.resultDate)} />
            <DataCell title={'Test medium'} data={getTestMediumDisplayValue(testData?.testMediumType) || '-'} />
            <DataCell title={'Attach test'}>
              <div style={{ display: 'flex' }}>
                <Tooltip title={testData?.attachTestShortCode}>
                  <span className={commonStyles.link_button} style={{ display: 'inline-block', fontSize: '14px', borderBottom: '1px solid #00B0F5', marginRight: '3px', cursor: 'default', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '100px' }}>
                    {testData?.attachTestShortCode ? `${testData?.attachTestShortCode}` : `Attach test`}
                  </span>
                </Tooltip>
                {isPastDate(testData?.startDate) ? (
                  <Tooltip title='Cannot edit as test start date has passed'><IconButtonWrapper onClick={() => { }}><EditIcon className='ml-2' style={{ width: '14px', height: '14px', cursor: 'pointer' }}  /></IconButtonWrapper></Tooltip>
                ) : (
                  <APopover
                    buttonComponent={(onClick, styles) => !props?.isImportedTest ? <IconButtonWrapper onClick={onClick} ><EditIcon className='ml-2' style={{ width: '14px', height: '14px', cursor: 'pointer' }} /></IconButtonWrapper> : ""}
                    menuComponent={(closeForm) => (
                      <AttachTestForm
                        closeForm={closeForm}
                        testData={testData}
                        planData={planData}
                        updateAttachTestHandler={updateAttachTestHandler}
                      />
                    )}
                  />
                )}
              </div>

            </DataCell>
            <DataCell title={'Status'}>
              {props?.isImportedTest ?
                <CustomChip
                  // text={}
                  textColor='#0B442D'
                  bgColor='#BBE2CE'

                >{testData?.active ? 'Active' : 'Inactive'}
                </CustomChip>

                : <span>
                  {testData?.active ? (
                    isPastDate(testData?.startDate) ? (
                      <Tooltip title='Cannot edit as test start date has passed'><ActiveControl onClick={() => { }} /></Tooltip>
                    ) : (
                      <ActiveControl onClick={
                        () =>
                          activeInactiveHandler()} />
                    )
                  ) : (
                    isPastDate(testData?.startDate) ? (
                      <Tooltip title='Cannot edit as test start date has passed'><InactiveControl onClick={() => { }} /></Tooltip>
                    ) : (
                      <InactiveControl onClick={() => activeInactiveHandler()} />
                    )
                  )}
                  <span style={{ fontSize: "14px", marginLeft: "4px", color: testData?.active ? '#0F0F0F' : '#757575' }}>
                    {testData?.active ? 'ACTIVE' : 'INACTIVE'}
                  </span>
                </span>
              }
            </DataCell>
            <DataCell title={'Actions'}>
              <div className='d-flex align-items-center'>
                <span className={commonStyles.link_button} style={{ fontSize: '14px' }} onClick={() => expandedIndex === index ? setExpandedIndex(-1) : setExpandedIndex(index)}>
                  {expandedIndex === index ? `Hide test syllabus` : `View test syllabus`}
                </span>
                {!props?.isImportedTest && <span>

                  {isPastDate(testData?.resultDate) ? (
                    <Tooltip title='Cannot edit as test result date has passed'><IconButtonWrapper  onClick={() => { }}><EditIcon className='ml-2' style={{ width: '14px', height: '14px', cursor: 'pointer' }} /></IconButtonWrapper></Tooltip>
                  ) : (
                    <IconButtonWrapper onClick={() => setIsFormOpen(true)}><EditIcon className='ml-2' style={{ width: '14px', height: '14px', cursor: 'pointer' }}  /></IconButtonWrapper>
                  )}
                </span>
                }
              </div>
            </DataCell>
          </div>
          {expandedIndex === index && (
            <TestSyllabus
              testData={testData}
            // testSyllabus={testSyllabus}
            // setTestSyllabus={setTestSyllabus}
            // isSyllabusFetched={isSyllabusFetched}
            // setIsSyllabusFetched={setIsSyllabusFetched}
            />
          )}
        </CardBody>
      </Card>
      <TestStatusChangeConfirmationDialog
        open={openDialog}
        setOpen={setOpenDialog}
        testData={testData}
        onAgree={updateTestStatusHandler}
      />
      {isFormOpen && (
        <TestForm
          planData={planData}
          basicDetails={testBasicDetails}
          academicPlanId={planData?.id}
          testId={testData?.id}
          getTestList={initialiseList}
          open={isFormOpen} onClose={() => setIsFormOpen(false)}
          isDisableTestType={!isIncomplete}
        />
      )}
    </>
  )
}

export default TestCard