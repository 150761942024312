import React, {  } from "react";
import ADataGrid from "views/pages/manage/common/data-grid/ADataGrid";
import { useHistory } from "react-router";
import { getApplicationsTableHeader } from "../selectMeterialAndStock/issueStock/TableColumn";
import {gridComponents, gridStyles } from "../selectMeterialAndStock/constant";

const ApplicationsTable = ({
  rowData = [],
  onSelectClick,
  rowCount = 0,
  currentPage,
  isMisc
}) => {
  

  return (
    <>
      <div className="">
        <div>
          <ADataGrid
            rows={rowData || []}
            columns={getApplicationsTableHeader({onSelectClick, isMisc })}
            rowId={(row) => JSON?.stringify(row)}
            rowCount={rowCount}
            currentPage={currentPage}
            rowHeight={50}
            height={100}
            loading={false}
            hideFooterSelectedRowCount={true}
            components={gridComponents}
            sx={{
              ".MuiDataGrid-main": {
                height: `${60 + rowData?.length * 50}px`,
                minHeight: `${60 + 50}px`,
                maxHeight: `${60 + 50 * 50}px`,
              },
              ".MuiDataGrid-footerContainer": {
                display: "none",
              },
              ...gridStyles,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ApplicationsTable;
