import React, { useContext, useEffect, useState } from "react";
import styles from "../printIdCardAndMapRfid/styles.module.scss";
import UploadCSV from "../../../courseInformation/pricingService/courseFee/CourseFee/pricingUpload/RfIdUpload";
import DownloadCsv from "./DownloadCsv";
import { getPrintedApplicationIds, getUploadedCsvs } from "./service";
import Table from "./Table";
import { IssuanceIDCardContext } from "../contextApi";
import ALoader from "views/pages/manage/common/a-loader";

const BulkUpload = ({ printedData }) => {
  const { filterForm, globalFilter, dropdown, page } = useContext(
    IssuanceIDCardContext
  );
  const appliedBusinessArea = filterForm.businessArea
    ? [filterForm.businessArea]
    : dropdown?.businessAreas;
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);

  useEffect(() => {
    fetchNewList();
  }, []);

  const fetchNewList = async () => {
    setTableLoader(true);
    await getUploadedCsvs(setUploadedFiles);
    setTableLoader(false);
  };
  return (
    <div className={`${styles?.rfid_table_container}`}>
      {loading && <ALoader />}
      <div className="d-flex align-items-center">
        <div className="flex-grow-1 large-semi-bold mb-2">Bulk upload .csv</div>
      </div>
      <div className="d-flex" style={{ columnGap: "24px" }}>
        <div className="flex-grow-1" style={{ maxWidth: "55%" }}>
          <DownloadCsv
            filterForm={{
              ...filterForm,
              businessArea: appliedBusinessArea,
              applicationIds: getPrintedApplicationIds(printedData),
            }}
            setLoading={setLoading}
            page={page}
            printedData={printedData}
          />
          <UploadCSV setISUploading={setLoading} fetchNewList={fetchNewList} />
        </div>
        
        <Table
          list={uploadedFiles}
          fetchList={fetchNewList}
          tableLoader={tableLoader}
        />
      </div>
    </div>
  );
};

export default BulkUpload;
