/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import {
  TreeItem,
  TreeView,
} from "views/pages/manage/common/commonComponents/tree";
import CustomTreeItem from "./CustomTreeItem";
import { MinusSquare, PlusSquare, CloseSquare } from "./SvgIcon";
import { ReactComponent as HorizontalLine } from "../../../../../../assets/img/svg/HorizontalLine.svg";
import { useEffect } from "react";
import CustomTreeItemAdded from "./CustomTreeItemAdded";

const StyledTreeItem = styled((props) => (
  <TreeItem ContentComponent={CustomTreeItemAdded} {...props} />
))(({ theme }) => ({
  // [`& .${treeItemClasses.iconContainer}`]: {
  //   "& .close": {
  //     opacity: 0.3,
  //   },
  // },
  // [`& .${treeItemClasses.group}`]: {
  //   marginLeft: 15,
  //   paddingLeft: 18,
  //   borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.4)}`,
  // },[`& .${treeItemClasses.content}`]: {
  //   marginBottom: 8,
  // },
}));
const StyledSubtopic = styled((props) => (
  <TreeItem ContentComponent={CustomTreeItem} {...props} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    width: "20px",
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 0,
    borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

export default function TreeViewByAdd({
  subjectChapters,
  setSubjectChapters,
  addHandler,
  expended,
  deleteHandler,
}) {
  //  console.log(expended ,"expended");
  // const [subjectChapters, setSubjectChapters]  = useState({}) ;
  const nodeMutator = (nodeData) => {
    switch (nodeData?.nodeType) {
      case "Chapter":
        let temp = { ...subjectChapters };
        temp.tableOfContent.forEach((chapter) => {
          if (chapter.key === nodeData.key) {
            chapter["isAdded"] = true;
            addHandler(nodeData);
          }
        });
        setSubjectChapters(temp);
        break;
      case "Topic":
        break;

      default:
        break;
    }
  };
  return (
    <TreeView
      aria-label="customized"
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={<CloseSquare />}
      // expanded = {expended}

      sx={{
        flexGrow: 1,
        // maxWidth: 400,
        overflowY: "auto",
      }}
    >
      {subjectChapters?.tableOfContent?.map((chapter, index) => {
        return (
          <StyledTreeItem
            key={chapter.key}
            nodeId={chapter.key.toString()}
            label={chapter.title}
            onClick={{ data: chapter, mutator: deleteHandler }}
          >
            {chapter?.children?.map((topic) => {
              return (
                <StyledTreeItem
                  key={topic.key}
                  nodeId={topic.key.toString()}
                  label={topic.title}
                  onClick={{ data: topic, mutator: deleteHandler }}
                >
                  {topic?.children?.map((subtopic) => {
                    return (
                      <StyledTreeItem
                        key={subtopic.key}
                        nodeId={subtopic.key}
                        label={subtopic.title}
                        onClick={{
                          data: {
                            ...subtopic,
                            topicParentId: topic.parentNode,
                          },
                          mutator: deleteHandler,
                        }}
                        icon={<HorizontalLine />}
                      ></StyledTreeItem>
                    );
                  })}
                </StyledTreeItem>
              );
            })}
          </StyledTreeItem>
        );
      })}
    </TreeView>
  );
}
