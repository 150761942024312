import React, { useEffect, useState } from "react";
import {
  fetchAllPromisedData,
  getUrlSearchParams,
} from "../../../common/utils/methods/commonMethods/utilityMethod";
import { useHistory } from "react-router";
import { ReactComponent as BackIcon } from "assets/img/svg/BackButton.svg";
import CustomChip from "views/pages/manage/common/customChip/CustomChip.jsx";
import styles from "./styles.module.scss";
import StockReturnTable from "./StockReturnTable";
import ALoader from "views/pages/manage/common/a-loader";
import { endpoint } from "../../../common/constant";
import { Tooltip } from "@mui/material";
import { getDropdowns, transactionTypeMap } from "./constant";
import { CourseDetails } from "../selectMeterialAndStock/issueStock/TableColumn";
import { IoEyeOutline } from "react-icons/io5";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx";

const restructureMaterialDetails = (arr) => {
  let res = {};
  arr.map((item) => {
    const barcodeItem = {
      barcode: item?.barcode,
      isReturned: item?.returnTransactionId,
      status: item?.status,
    };
    res[item?.materialNumber] = {
      ...item,
      barcode: Array.isArray(res?.[item?.materialNumber]?.barcode)
        ? [...res[item?.materialNumber]?.barcode, barcodeItem]
        : [barcodeItem],
    };
  });
  return res;
};
const checkIsAllReturned = (arr = []) => {
  for (let item of arr) {
    if (!item?.returnTransactionId) return false;
  }
  return true;
};
const restructureResponse = (data) =>
  data?.map((item) => ({
    ...item,
    materialDetails: restructureMaterialDetails(item?.materialDetails),
    materialDetailsRes: item?.materialDetails,
    isAllStockReturnedForTxn:
      item?.isAllStockReturnedForTxn ??
      checkIsAllReturned(item?.materialDetails),
  }));

const StockReturn = () => {
  const history = useHistory();

  const { academicCareer, businessArea, transactionId, applicationId } =
    getUrlSearchParams(history?.location?.search);

  const [apiLoader, setApiLoader] = useState(false);
  const [dropdown, setDropdown] = useState({});
  const [rows, setRows] = useState([]);

  const chips = [
    { content: <div>PSID: {rows?.[0]?.psid || "-"}</div> },
    { content: <div>App ID: {rows?.[0]?.applicationId || "-"}</div> },
    { content: <div>Term: {rows?.[0]?.termValue || "-"}</div> },
    {
      content: (
        <div className="d-flex align-items-center">
          Course ID:{" "}
          {rows?.[0]?.courseId ? (
            <>
              {rows?.[0]?.courseId}
              <CourseDetails
                courseId={rows?.[0]?.courseId}
                applicationId={rows?.[0]?.applicationId}
              />
            </>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    ...(rows?.[0]?.primaryBatchName
      ? [
          {
            content: (
              <div className="ellipsis-text-container d-flex align-items-center">
                <div className="ellipsis-text-container">
                  Batch: {rows?.[0]?.primaryBatchName || "-"}
                </div>
                {rows?.[0]?.primaryBatchId && (
                  <Tooltip title="View batch">
                    <span
                      className="ml-2 flex-shrink-0 d-flex align-items-start cursor"
                      onClick={() =>
                        window.open(
                          history.createHref({
                            pathname: `/admin/edp/batch/view/${rows?.[0]?.primaryBatchId}`,
                          }),
                          "_blank"
                        )
                      }
                    >
                      <IoEyeOutline size={18} />
                    </span>
                  </Tooltip>
                )}
              </div>
            ),
          },
        ]
      : []),
  ];

  const fetchDropdown = async () => {
    const res = await getDropdowns();
    setDropdown(res);
  };

  const fetchData = async () => {
    // fetch get by txnId api call
    setApiLoader(true);

    const commonSearchParams = `?businessArea=${businessArea}&applicationId=${applicationId}`;
    const searchQueryParams = transactionId
      ? `${commonSearchParams}&transactionId=${transactionId}`
      : commonSearchParams;
    const issuanceType = transactionTypeMap["PSID_ISSUANCE"][academicCareer];
    const searchQuery = `${searchQueryParams}&requireAllStockReturnedFlag=true&type=${issuanceType}`;
    const res = await fetchAllPromisedData(
      `${endpoint?.studentSearch?.getStockTransactionDetails}${searchQuery}`,
      true
    );
    if (res?.code === 200) {
      setRows(restructureResponse(res?.data));
    } else {
      setRows([]);
    }

    setApiLoader(false);
  };

  useEffect(() => {
    if (!academicCareer || !businessArea || !applicationId) {
      history.replace("/admin/pageNotFound");
    } else {
      fetchData();
      fetchDropdown();
    }
  }, [businessArea, transactionId, applicationId]);

  return (
    <div className={`remove-container-top-space ${styles["page-container"]}`}>
      {apiLoader && <ALoader position="fixed" />}
      <div className={`${styles["page-card"]}`}>
        <div
          className="d-flex heading-4 color-dark align-items-center"
          style={{ gap: "12px" }}
        >
          <IconButtonWrapper>
            <BackIcon onClick={() => window.close()} />
          </IconButtonWrapper>
          <div className="">
            Stock return for {rows?.[0]?.studentName || "-"} |{" "}
            {rows?.[0]?.businessAreaValue || "-"}
          </div>
          <div className="flex-grow-1 d-flex" style={{ gap: "4px" }}>
            {chips.map((item, idx) => (
              <CustomChip
                key={idx}
                style={{ padding: "4px 11px", lineHeight: "20px" }}
                className="body-regular"
              >
                {item?.content}
              </CustomChip>
            ))}
          </div>
        </div>
        <StockReturnTable
          loader={apiLoader}
          rows={rows}
          dropdown={dropdown}
          fetchData={fetchData}
        />
      </div>
    </div>
  );
};

export default StockReturn;
