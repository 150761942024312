import React, { Fragment, useEffect } from 'react';
import { Button } from 'reactstrap';
import { Icons } from 'assets/img/icons/common/CommonIcons';
import Style from './button.module.scss';
const miscellaneousButton = ['reset', 'download', 'add', 'submit', 'upload', 'close', 'reject', 'edit', 'back', 'normal', 'loading', 'exportToExcel', 'download2'];

const getIcon = (permissionType, icon) => {
  const userRole = permissionType.toLowerCase();
  if (
    (userRole === 'c' ||
      userRole === 'u' ||
      userRole === 'r' ||
      userRole === 's' ||
      userRole === 'd' ||
      userRole === 'cancel' ||
      userRole === 'upload' ||
      userRole === 'download' ||
      userRole === 'loading' ||
      userRole === 'download2' ||
      userRole === 'exporttoexcel') &&
    icon
  ) {
    return Icons[userRole];
  }
};

const getContentForTable = (permissionType, content, icon) => {
  const userRole = permissionType.toLowerCase();
  if (
    userRole === 'u' ||
    userRole === 'r' ||
    userRole === 'c' ||
    userRole === 's' ||
    userRole === 'd' ||
    userRole === 'cancel'
  ) {
    if (content && content !== '') {
      return (
        <span>
          {getIcon(userRole, icon)}
          {icon ? <>&nbsp;&nbsp;</> : null}
          {content}
        </span>
      );
    } else {
      return <span>{getIcon(userRole, icon)}</span>;
    }
  }
};
const getSize = forTable => {
  if (forTable) {
    return 'btn-sm';
  }
  return 'btn-md';
};
const CustomButton = props => {
  let {
    permissionSet,
    permissionType,
    icon = false,
    forTable = false,
    content = '',
    dataTestId,
    type = 'button',
    className = '',
    outline = false
  } = props;

  if (permissionType && permissionType.split(',').length === 2) {
    const tempPermissionType = permissionType.split(',');
    if (permissionType.includes('R') && tempPermissionType.includes('S')) {
      permissionType = 'S';
    } else if (Array.isArray(permissionSet)) {
      permissionType = permissionSet.find((item) => tempPermissionType.includes(item));
    } else {
      permissionType = '';
    }
  }

  return (
    <Fragment>
      {miscellaneousButton.indexOf(type) > -1 && permissionSet && permissionSet.includes(permissionType) ? (
        <Button
          data-testid={dataTestId}
          className={`${props.className}  ${getSize(forTable)} custom-btn`}
          type="button"
          disabled={props.disabled}
          onClick={(e) => {
            props.onClick(e);
          }}
          color={props.color}
          outline={outline}
          style={
            type === 'upload'
              ? {
                color: '#00b0f5',
                border: '1px solid #00b0f5',
                backgroundColor: '#ffffff',
              }
              : null
          }
        >
          {icon ? (
            <>
              {getIcon(type, icon)}
              {content && <>&nbsp;&nbsp;{`${content}`}</>}
            </>
          ) : (
            content
          )}
        </Button>
      ) : permissionType === 'cancel' || (permissionSet && permissionSet.includes(permissionType)) ? (
        !forTable ? (
          <Button

            data-testid={dataTestId}
            style={{ padding: forTable ? '5px' : '' }}
            className={`${className}  ${getSize(forTable)} custom-btn`}
            type="button"
            disabled={props.disabled}
            color={
              permissionType.toLowerCase() === 'd' || permissionType.toLowerCase() === 'cancel' ? 'danger' : 'info'
            }
            outline={outline}
            onClick={(e) => {
              props.onClick(e);
            }}
          >
            {/* {getIcon(permissionType, icon)}
            &nbsp;&nbsp;
            {content} */}
            {icon ? (
              <>
                {getIcon(permissionType, icon)}
                {content && <>&nbsp;&nbsp;{`${content}`}</>}
              </>
            ) : (
              content
            )}
          </Button>
        ) : (
          <Button
            data-testid={dataTestId}
            className={`${className} ${(forTable && !content) ? Style.iconswithtable : ''} custom-btn`}
            type="button"
            size="sm"
            disabled={props.disabled}
            color={
              permissionType.toLowerCase() === 'd' || permissionType.toLowerCase() === 'cancel' ? 'danger' : 'info'
            }
            outline={outline}
            onClick={(e) => {
              props.onClick(e);
            }}
          >
            {
              getContentForTable(permissionType, content, icon)
            }

          </Button>
        )
      ) : (
        permissionType === 'S' &&
        permissionSet.includes('R') && (
          <Button
            data-testid={dataTestId}
            style={{ padding: forTable ? '5px' : '' }}
            className={className + ' ' + getSize(forTable) + 'custom-btn'}
            type="button"
            disabled={props.disabled}
            color={
              permissionType.toLowerCase() === 'd' || permissionType.toLowerCase() === 'cancel' ? 'danger' : 'info'
            }
            outline={outline}
            onClick={(e) => {
              props.onClick(e);
            }}
          >
            {getIcon(permissionType, icon)}
            &nbsp;&nbsp;
            {content}
          </Button>
        )
      )}
    </Fragment>
  );
};
export default CustomButton;
