import React, { useState } from "react";
import { useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import {
  academicCardInfoLabels,
  detailsRowStyles,
} from "../constant/student-details";
import CustomChip from "./CustomChip";
import StudentPhoto from "./studentPhoto";
import ApplicationIdList from "./ApplicationIdList";
import QuickActions from "./QuickActions";
import CustomLoader from "../../common/commonComponents/Loader/loader";

const AcademicDetailsCard = ({
  activeTab,
  psid,
  studentApplicationDetails,
  studentDetails,
  setApiLoader = () => {},
  loader,
  photoKey,
  fetchApplications = () => {},
  fetchStudentDetails = () => {},
  setCurrentApplicationDetails 
}) => {

  const [currIndex, setCurrIndex] = useState(0);
  const [activeApplicationDetails, setActiveApplicationDetails] = useState(
    studentApplicationDetails?.[currIndex]
  );

  const onApplicationChange = index =>{
     if(index !==currIndex){
      setCurrIndex(index)
      setCurrentApplicationDetails(studentApplicationDetails?.[index])
     }
  }

  useEffect(() => {
    setActiveApplicationDetails(studentApplicationDetails?.[currIndex]);
  }, [currIndex, studentApplicationDetails]);

  return (
    <Card>
      <CardBody>
        <h3 className="mb-2" style={{ fontSize: "20px" }}>
          Academic details
        </h3>
        {loader ? (
          <CustomLoader />
        ) : (
          <div>
            <div>
              <div
                style={{ gap: "20px" }}
                className="mb-3 d-flex  align-items-start"
              >
                <div>
                  <StudentPhoto
                    photoKey={photoKey}
                    fetchApplications={fetchApplications}
                    psid={psid}
                  />
                </div>
                <div>
                  <h3 style={{ fontSize: "28px" }}>
                    {activeApplicationDetails?.studentName}
                  </h3>
                  <CustomChip
                    bgColor="#EDF7F2"
                    textColor="#0B442D"
                    text={`PSID: ${psid}`}
                    style={{ fontSize: "14px" }}
                  />
                </div>
              </div>
              <div className="mb-4">
                <ApplicationIdList
                  studentApplicationDetails={studentApplicationDetails}
                  currIndex={currIndex}
                  onApplicationChange={onApplicationChange}
                />
                {academicCardInfoLabels(
                  studentApplicationDetails?.length <= 1
                )?.map(infoRow => (
                  <Row style={detailsRowStyles} key={infoRow["objKey"]}>
                    <Col sm="6">{infoRow["title"]}</Col>
                    <Col sm="6" style={{ fontWeight: 600 }}>
                      {infoRow?.valueGetter
                        ? infoRow?.valueGetter(activeApplicationDetails)
                        : (activeApplicationDetails?.[infoRow["objKey"]] || "")
                            ?.toString()
                            ?.trim() || "-"}
                    </Col>
                  </Row>
                ))}
              </div>
              <QuickActions
                activeTab={activeTab}
                fetchStudentDetails={fetchStudentDetails}
                fetchApplications={fetchApplications}
                studentDetails={studentDetails}
                setApiLoader={setApiLoader}
                activeApplicationDetails={activeApplicationDetails}
              />
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default AcademicDetailsCard;
