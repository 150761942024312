import React, { useState } from 'react'
// import { ReactComponent as CloseIcon } from "../../../../../assets/img/svg/CloseIcon.svg";
import { ReactComponent as CloseIcon } from "../../../../../../../assets/img/svg/CloseIcon.svg";
import DataLostConfirmation from 'views/pages/manage/academicPlanAndTest/academicTest/DataLostConfirmation';

export const labelStyle = {
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "28px",
  color: "#262626",
};

const FormHeader = ({
  setDetailsModalOpen,
  updateKey,
  index,
  isRowEdited,
  goBackWithDataLost,
  setViewDetails,
  buDispVal,
  el
}) => {
  const [dataLossDialogueOpen, setDataLossDialogue] = useState(false);
  //   const goBackWithDataLost=()=>{
  //     updateKey(index,null,"mappedType");
  //     updateKey(index,null,"houseBankId");
  //     setDetailsModalOpen(false);
  //   }
  const handleClose = () => {
    setDataLossDialogue(false)
  }
  const handleCloseModal = () => {
    if (isRowEdited) setDataLossDialogue(true);
    else {
      setDetailsModalOpen(false);
      setViewDetails(false);
    }
  }
  return (
    <div className="d-flex justify-content-between  mb-4">
      <span style={labelStyle}>{`${buDispVal} | ${el?.paymentModeDispVal === 'ADB' ? 'Deposit Slip Details' : 'Cash Account Details'}`}</span>{" "}
      <span>
        <CloseIcon style={{ cursor: "pointer" }} onClick={() => handleCloseModal()} />
        <DataLostConfirmation
          open={dataLossDialogueOpen}
          handleClose={handleClose}
          goBackWithDataLost={() => goBackWithDataLost(index)}

        />
      </span>
    </div>)
}

export default FormHeader