import React, { useState } from "react";
import ADataGrid from "../../common/data-grid/ADataGrid";
import { AGridColDef } from "../../common/data-grid/ADataGridColDef";
import { useHistory } from "react-router";
import EmptyListPlaceholder from "../../edpOps/studentSerach/EmptyListPlaceholder";
import { Tooltip } from "@mui/material";
import { CourseDetailsPopupById, EyeIcon } from "../studentCharges/common"
import moment from "moment";
import { openInNewTab } from "../../common/utils/methods/commonMethods/utilityMethod";
import CustomPaginationWithoutCount from "components/CustomPaginationWithoutCount/CustomPaginationWithoutCount";

const commonStyle = {
  fontSize: '12px',
  color: '#414141',
  display: 'flex'
}

const headerStyle = {
  fontFamily: 'Inter-Bold',
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0em',
  textSlign: 'left'

}

export const otherChargesPaymentStatus = {
 
    "not paid": { 
      style: {backgroundColor: '#FFEDC8', color: '#705010', padding: '4px 11px', fontSize: '12px', borderRadius : '8px'}, 
      value: 'Pending' 
  },
  "partially paid": { 
      style: {backgroundColor: '#FFEDC8', color: '#705010', padding: '4px 11px', fontSize: '12px', borderRadius : '8px'}, 
      value: 'Pending ' 
  },
  "fully paid": { 
    style: {backgroundColor: '#BBE2CE', color: '#0B442D', padding: '4px 11px', fontSize: '12px', borderRadius : '8px'}, 
      
      value: 'Paid' 
  },
  'na': {
  style: {backgroundColor: '#F5F5F5', color: '#262626', padding: '4px 11px', fontSize: '12px', borderRadius : '8px'},
  value : 'NA'}

  
}
const StudentList = ({
  rowsData,
  rowCount,
  nextButtonHandler,
  currentPage,
  isLastPage,
  courseIdDropdown
}) => {
  const history = useHistory();
  
  const BatchListHeader = [
    new AGridColDef("psid", "PSID").renderCellComponent((params) => (
      <span
        onClick={() => history.push(`/admin/edp/studentDetails/${params.value}`)}
        style={{ color: "#00B0F5", fontSize: "12px", cursor: "pointer" }}
      >
        {params.value}
      </span>
    )),
    new AGridColDef("applicationId", "App. ID").renderCellComponent((params =>
      <span style={commonStyle}>{params.value}</span>
    )),
    new AGridColDef("studentName", "Student name")
    .renderCellComponent(params => <Tooltip title={params?.value}><div className='ellipsis-text-container'>{params?.value??'-'}</div></Tooltip>)
    ,
    new AGridColDef("studentFatherName", "F name")
    .renderCellComponent(params => <Tooltip title={params?.value}><div className='ellipsis-text-container'>{params?.value??'-'}</div></Tooltip>),
    new AGridColDef("studentMotherName", "M name"),
   
    new AGridColDef("businessAreaDispVal", "BU").renderCellComponent((params =>
      <span style={commonStyle}>{params.value}</span>
    )),
    new AGridColDef("courseId", "Course ID").renderCellComponent((params =>
      <div style={commonStyle}>{params.value}&nbsp;
        <CourseDetailsPopupById courseId={params.value}/>
      </div>
    )),
    new AGridColDef("term", "Term").renderCellComponent((params =>
      <span style={commonStyle}>{params.value?.dispValue}</span>
    )),
    new AGridColDef("applicationDate", "App. date").renderCellComponent((params =>
      <span style={commonStyle}>{params.value ? moment(params.value).format("DD-MMM-YYYY") : '-'}</span>
    )),
    // new AGridColDef("updatedOn", "Updated on").renderCellComponent((params =>
    //   <Tooltip title={`By ${params.row?.updatedBy} at ${moment(params.value).format('hh:mm a ')}`}>
    //   <span style={commonStyle}>{params.value ? moment(params.value).format("DD-MMM-YYYY") : '-'}</span>
    //   </Tooltip>
    // )),
  //   new AGridColDef("otherChargesPaymentStatus", "Other charges status").renderCellComponent((params =>
     
  //   <div style={{ display: 'flex', justifyContent: 'center',width: '100%' }}>
  //   <span style={otherChargesPaymentStatus?.[params?.value?.toLowerCase()]?.style ?? {}}>{otherChargesPaymentStatus[params?.value?.toLowerCase()]?.value ?? params.value ?? '-'}</span>
  // </div>)),
    
    new AGridColDef("Actions", "Action", false)
      .setWidth(200)
      .renderCellComponent((params) => {
        return (
          !params?.row?.isOtherChargesCreated ? (
            <Tooltip title="Manage">
              <span
                onClick={() => openInNewTab('/details/' + params.row.applicationId + "/add")}
                className="color-primary tex-bold cursor"
              >
                Manage +
              </span>
            </Tooltip> 
          ) : (
            <Tooltip title="Edit">
              <span
                onClick={() => openInNewTab('/details/' + params.row.applicationId + "/edit")}
                className="color-primary cursor"
              >
                Edit
              </span>
            </Tooltip>
          )
        ) 
      }),
  ];

  return (
    <>

      <ADataGrid
        rows={rowsData}
        columns={BatchListHeader}
        rowId={(row) => row.applicationId}
        loading={rowsData.length === 0}
        // rowCount={rowCount}
        // nextButtonHandler={nextButtonHandler}
        // currentPage={currentPage}
        emptyRowPlaceholder={() => <EmptyListPlaceholder />}
        sortColumnName="psid"
        hidePagination={true}
      />
      <CustomPaginationWithoutCount
      currentPage={currentPage}
      onPageChange={nextButtonHandler}
      isLastPage={isLastPage}
      noOfCurrentPageRecords={rowsData.length}
      />
    </>
  );
};

export default StudentList;
