import React from 'react';
import {
  Card,
  CardHeader,
  Input,
  Container,
  Button,
  Table,
  Row,
} from 'reactstrap';
import { useState } from 'react';
import { successToast, failureToast } from '../../common/utils/methods/toasterFunctions/function';
import { TDROW, MasterHeader } from '../../common/commonComponents/masterRow/masterRow';
import {
  masterServiceBaseUrl,
  putpost,
  putpost2,
  deleteAPI,
} from 'services/http';
import moment from 'moment';
import CustomButton from "../../../../../components/CustomButton";
import CommonInput from "../../common/formFeilds/input/commonInput";
import { regex } from '../../common/constant';

var _ = require('lodash');

const CourseComponentTable = (props) => {
  const { permissionSet, isSaveVisible, setisSaveVisible = () => { } } = props;
  const [tableDataKey, settableDataKey] = useState('tableDataKey');
  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: '__new',
        courseComponentKey: null,
        status: 'ACTIVE',
        courseComponentDesc: null,
        courseComponentDispValue: null,
        effectiveDate: null,
      },
      ...props.classArray,
    ]);
  };
  const saveAll = (cb) => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if (el['editMode']) {
        if (
          el['courseComponentKey'] === null ||
          !el['courseComponentKey'].trim()
        ) {
          isValid = false;
          failureToast('Please enter the value of Course Component Key');
          return;
        } else if (
          el['courseComponentDesc'] === null ||
          !el['courseComponentDesc'].trim()
        ) {
          isValid = false;
          failureToast('Please enter the value of Description');
          return;
        } else if (
          el['courseComponentDispValue'] === null ||
          !el['courseComponentDispValue'].trim()
        ) {
          isValid = false;
          failureToast('Please enter the value of Display Value');
          return;
        }
        if (!isValid) {
          return;
        }
        if (_tempArray[index]['__status'] == '__new') {
          putpost(
            masterServiceBaseUrl + '/courseComponent/createCourseComponent',
            (data) => {
              successToast(data['message']);
              setisSaveVisible(false);
              props.fetchClasses(
                masterServiceBaseUrl + '/courseComponent/getAllCourseComponent'
              );
            },
            (data) => {
              failureToast(data['message']);
            },
            _tempArray[index],
            'post'
          );
        } else {
          putpost(
            masterServiceBaseUrl +
            '/courseComponent/updateCourseComponent/' +
            _tempArray[index].id,
            (data) => {
              successToast(data['message']);
              setisSaveVisible(false);
              delete _tempArray[index]['editMode'];
              props.setclassArray(_tempArray);
              settableDataKey(new Date().getMilliseconds());
              // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
            },
            (data) => {
              failureToast(data['message']);
            },
            _tempArray[index],
            'put'
          );
        }
      } else {
      }
    });
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
        // settableDataKey(new Date().getMilliseconds())
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      cb();
    }
  };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]['editMode'] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };
  const deleteRow = (index) => {
    if (props.classArray[index]['__status'] == '__new') {
      let n = props.classArray;
      n.splice(index, 1);
      props.setclassArray(n);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
    } else {
      if (
        window.confirm('Are you sure you want to delete this Course Component?')
      ) {
        putpost2(
          masterServiceBaseUrl +
          '/courseComponent/deleteCourseComponent/' +
          props.classArray[index].id,
          (data) => {
            successToast(data['message']);
            var _tempArray = props.classArray;
            if (_tempArray[index]['editMode']) {
              setisSaveVisible(false);
            }
            _tempArray.splice(index, 1);
            props.setclassArray(_tempArray);
            settableDataKey(new Date().getMilliseconds());
          },
          (data) => {
            failureToast(data['message']);
          },
          'Delete'
        );
      }
    }
  };
  const updateKey = (index, value, key) => {
    var _tempArray = [...props.classArray];
    _tempArray[index][key] = value;
    props.setclassArray([..._tempArray]);
  };
  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Course Component</h3>
                {/*<Button*/}
                {/*  data-testid="addNewRow"*/}
                {/*  disabled={isSaveVisible}*/}
                {/*  color="info"*/}
                {/*  size="sm"*/}
                {/*  type="button"*/}
                {/*  className={*/}
                {/*    'floatRight mx-1' + (isSaveVisible ? ' btn-dark' : null)*/}
                {/*  }*/}
                {/*  onClick={() => {*/}
                {/*    newRow();*/}
                {/*  }}*/}
                {/*>*/}
                {/*  New Course Component*/}
                {/*</Button>*/}
                <CustomButton
                  data-testId="addNewRow"
                  disabled={isSaveVisible}
                  className={'floatRight mx-1' + (isSaveVisible ? ' btn-dark' : null)}
                  content={'New Course Component'}
                  permissionType={'C'}
                  icon={true}
                  permissionSet={permissionSet}
                  onClick={() => newRow()}
                />
                {/* {isSaveVisible?<Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => {saveAll()}}>Save</Button>:null} */}
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout">
                  <MasterHeader type={'Course Component'} isSaveVisible={isSaveVisible} permissionSet={permissionSet} />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                        !el['editMode'] ? (
                          <TDROW
                            type={'courseComponent'}
                            index={index}
                            el={el}
                            editRow={editRow}
                            deleteRow={deleteRow}
                            isSaveVisible={isSaveVisible}
                            permissionSet={permissionSet}
                          />
                        ) : (
                          <tr key={index + '-class'}>
                            <td className="text-center p-2 mx-1">
                              <CommonInput
                                data-testid="courseComponentKey"
                                type={'text'}
                                maxLength={"50"}
                                forTable={true}
                                defaultValue={el['courseComponentKey']}
                                onChange={(value) => {
                                  updateKey(
                                    index,
                                    value,
                                    'courseComponentKey'
                                  );
                                }}
                                placeHolder={"Example:1"}
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <CommonInput
                                data-testid="effectiveDate"
                                type={'text'}
                                isDisabled={true}
                                maxLength={"50"}
                                forTable={true}
                                defaultValue={
                                  el['__status'] == '__new'
                                    ? null
                                    : moment(el['eff_date']).format(
                                      'DD-MM-YYYY'
                                    )
                                }
                                placeHolder={"Example:01-01-2020"}
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <CommonInput
                                data-testid="courseComponentDesc"
                                type={'text'}
                                maxLength={"50"}
                                forTable={true}
                                onChange={(value) => {
                                  updateKey(
                                    index,
                                    value,
                                    'courseComponentDesc'
                                  );
                                }}
                                defaultValue={el['courseComponentDesc']}
                                placeHolder={"Description"}
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <CommonInput
                                data-testid="courseComponentDispValue"
                                type={'text'}
                                maxLength={"3"}
                                forTable={true}
                                regEx={regex.alphabets}
                                onChange={(value) => {
                                  updateKey(
                                    index,
                                    value,
                                    'courseComponentDispValue'
                                  );
                                }}
                                defaultValue={el['courseComponentDispValue']}
                                placeHolder={"Display Value"}
                                onKeyPress={(e) => {
                                  if (
                                    !/^[A-Za-z]*$/.test(
                                      String.fromCharCode(e.charCode)
                                    )
                                  ) {
                                    e.preventDefault();
                                    return;
                                  }
                                }}
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <label className="custom-toggle mx-auto ml-2">
                                <input
                                  data-testid="activeInactiveInput"
                                  checked={
                                    el.status == 'ACTIVE' ? true : false
                                  }
                                  type="checkbox"
                                  id={el.id}
                                  onChange={(e) => {
                                    settableDataKey(
                                      new Date().getMilliseconds()
                                    );
                                    let n = 'ACTIVE';
                                    if (!e.target.checked) {
                                      n = 'INACTIVE';
                                    }
                                    updateKey(index, n, 'status');
                                  }}
                                />
                                <span
                                  data-testid="activeInactiveLabel"
                                  style={{ width: '72px' }}
                                  className="custom-toggle-slider rounded-circle activeToggle"
                                  data-label-off="Inactive"
                                  data-label-on="Active"
                                />
                              </label>
                            </td>
                            <td className="text-center">
                              {/* <Button color="info" size="sm" type="button" onClick={() => {
                          deleteRow(index)
                        }}>  <i className="fas fa-trash" /></Button> */}
                              {isSaveVisible ? (
                                <>
                                  {' '}
                                  {/*<Button*/}
                                  {/*  data-testid="saveBtn"*/}
                                  {/*  color="info"*/}
                                  {/*  size="sm"*/}
                                  {/*  type="button"*/}
                                  {/*  className="mx-1"*/}
                                  {/*  onClick={() => {*/}
                                  {/*    saveAll();*/}
                                  {/*  }}*/}
                                  {/*>*/}
                                  {/*  Save*/}
                                  {/*</Button>*/}
                                  <CustomButton
                                    data-testId="saveBtn"
                                    className={'mx-1'}
                                    content={'Save'}
                                    permissionType={'C,U'}
                                    forTable={true}
                                    permissionSet={permissionSet}
                                    onClick={() => saveAll()}
                                  />
                                  {/*<Button*/}
                                  {/*  data-testid="closeBtn"*/}
                                  {/*  color="danger"*/}
                                  {/*  size="sm"*/}
                                  {/*  type="button"*/}
                                  {/*  onClick={() => {*/}
                                  {/*    let n = props.classArray;*/}
                                  {/*    if (n[index]['__status'] == '__new') {*/}
                                  {/*      n.splice(index, 1);*/}
                                  {/*    } else {*/}
                                  {/*      // n[index]['editMode']=false*/}
                                  {/*      props.fetchClasses(*/}
                                  {/*        masterServiceBaseUrl +*/}
                                  {/*          '/courseComponent/getAllCourseComponent'*/}
                                  {/*      );*/}
                                  {/*    }*/}
                                  {/*    props.setclassArray(n);*/}
                                  {/*    setisSaveVisible(false);*/}
                                  {/*  }}*/}
                                  {/*>*/}
                                  {/*  {' '}*/}
                                  {/*  <i className="fas fa-times" />*/}
                                  {/*</Button>{' '}*/}
                                  <CustomButton
                                    data-testId="closeBtn"
                                    className={'mx-1'}
                                    permissionType={'cancel'}
                                    forTable={true}
                                    icon={true}
                                    permissionSet={permissionSet}
                                    onClick={() => {
                                      let n = props.classArray;
                                      if (n[index]['__status'] == '__new') {
                                        n.splice(index, 1);
                                      } else {
                                        // n[index]['editMode']=false
                                        props.fetchClasses(
                                          masterServiceBaseUrl +
                                          '/courseComponent/getAllCourseComponent'
                                        );
                                      }
                                      props.setclassArray(n);
                                      setisSaveVisible(false);
                                    }}
                                  />
                                </>
                              ) : (
                                <Button
                                  color="info"
                                  size="sm"
                                  type="button"
                                  onClick={() => {
                                    deleteRow(index);
                                  }}
                                >
                                  {' '}
                                  <i className="fas fa-trash" />
                                </Button>
                              )}
                            </td>
                          </tr>
                        )
                      )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default CourseComponentTable;
