import { Dialog } from "@mui/material";
import React from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import AButton from "../../../../common/form-fields-mui/AButton";
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";

const ConfirmationDialog = ({ open, setOpen, onAction }) => {
  const handleClose = () => setOpen(false);

  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={{ padding: "1.5rem", width: "28.3rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
            fontSize: "2rem",
            marginBottom: "10px",
            color: "black",
          }}
        >
          <AiOutlineQuestionCircle />
          <IconButtonWrapper onClick={handleClose}><CrossIcon width={32} height={32} /></IconButtonWrapper>
        </div>
        <div>
          <div
            style={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "24px",
              color: "#000000",
              marginBottom: ".6rem",
            }}
          >
            Your all filled information will be lost.
            <div className="mt-2">
              Are you sure want to edit Business area or Employee ID?
            </div>
          </div>
        </div>

        <div className="mt-2 d-flex  mt-4">
          <AButton
            className="button_remove_left_margin"
            variant="outline"
            onClick={handleClose}
          >
            No, go back
          </AButton>
          <AButton onClick={() => window.location.reload()} variant="outline">
            Yes, continue
          </AButton>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmationDialog;
