import React from "react";
import { FormGroup, Label, Card, CardHeader, Input, Button, Table, Row, Col } from "reactstrap";
import { MasterHeaderAuto} from "../../../common/commonComponents/masterHeader/masterHeader";
import moment from 'moment';
import { BsFillForwardFill } from "react-icons/bs";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";


import './index.css'

var _ = require('lodash');

const PaymentScheduleTable = (props) => {
    const { setstate, tableData, pagination, setclassArray, setpagination,courseYearData,
        paymentScheduleData,termData,cashierOffice,businessArea,businessGroup,comapnyCodeData,courseDetails} = props;

    const headersList = [{ name: 'COURSE YEAR' }, { name: 'DUE DATE' }, { name: 'ITEM TYPE' }, { name: 'ITEM TYPE DESCRIPTION' }, { name: 'BASE FEES' }, { name: 'TAX' }, { name: 'TOTAL AMOUNT' }, { name: 'CASH ALLOWED?' },
    { name: 'STATUS' }, { name: 'UPDATED DATE' }];

    const fileType ="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = 'myInstallments';


    const exportToCSV = () => {
        let apiData = [];
        tableData.map(item => {
            apiData.push({
                businessGroup : getBusinessGroupDisplayValue(),
                companyCode : getCompanyCode(),
                businessArea : getBusinessAreaName(),
                cashierOffice : getCashierOfficeDisplayValue(paymentScheduleData.cashierOfficeId),
                courseId : getCourseIdName(),
                term : getTermDisplayValue(paymentScheduleData.termId),
                courseStartDate : moment(paymentScheduleData.courseStartDate).format('DD-MM-YYYY'),
                courseEndDate : moment(paymentScheduleData.courseEndDate).format('DD-MM-YYYY'),
                lastUpdatedDate : moment(paymentScheduleData.updatedOn).format('DD-MM-YYYY'),
                noOfInstallments : paymentScheduleData.totalCourseFeeCount,
                totalBaseFees : paymentScheduleData.totalBaseFee,
                totalFees : paymentScheduleData.totalAmount,
                courseYear : getCourseYear(item.courseYearId),
                dueDate : moment(item.courseYearId.dueDate).format('DD-MM-YYYY'),
                itemType : item.itemTypeDetails.itemTypeId,
                itemTypeDescription : item.itemTypeDetails.description,
                baseFees : item.baseFee,
                tax : item.tax,
                totalAmount : item.totalAmount,
                cashAllowed : item.cashAllowed?'true':'false',
                status : item.status,
                updatedDate : moment(item.updatedOn).format('DD-MM-YYYY')
            })
        })        
        const ws = XLSX.utils.json_to_sheet(apiData);
        // console.log(ws,'data')
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    const searchPaginationHandler = (filterData, pageSize, n) => {
        if (filterData.length > pageSize) {
            n['nextPage'] = n.currentPage + 1
        }
        if (n.currentPage > 1) {
            n.previousPage = n.currentPage - 1
        }
        n['totalPage'] = Math.floor(filterData.length / pageSize)
        if (filterData.length % pageSize != 0) {
            n['totalPage'] += 1
        }
        setstate(filterData);
        setclassArray(filterData.slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || []);
        setpagination(n)
    }

    const getCourseYear = (value) => {
       return  courseYearData.find(item => item.id === value) &&
        courseYearData.find(item => item.id === value).courseYearDispValue
    }

    const getTermDisplayValue = (value) => {
        return  termData.find(item => item.id === value) &&
        termData.find(item => item.id === value).termDispValue
    }
     
    const getCashierOfficeDisplayValue = (value) => {
        return  cashierOffice.find(item => item.id === value) &&
        cashierOffice.find(item => item.id === value).description
    }

    const getCompanyCode = () => {
        const companyCodeId =  businessArea && businessArea.find(item => item.id === paymentScheduleData.businessAreaId) &&
        businessArea.find(item => item.id === paymentScheduleData.businessAreaId).companyCodeId;

        return  comapnyCodeData.find(item => item.id === companyCodeId) &&
        comapnyCodeData.find(item => item.id === companyCodeId).companyCodeDispValue
    }

    const getBusinessGroupDisplayValue = () => {
        const groupCodeId = businessArea &&  businessArea.find(item => item.id === paymentScheduleData.businessAreaId) &&
        businessArea.find(item => item.id === paymentScheduleData.businessAreaId).groupCodeId;

        return  businessGroup.find(item => item.id === groupCodeId) &&
        businessGroup.find(item => item.id === groupCodeId).groupCodeDispValue
    }

    const getBusinessAreaName = () => {
        const businessAreaKey = businessArea  &&  businessArea.find(item => item.id === paymentScheduleData.businessAreaId) &&
        businessArea.find(item => item.id === paymentScheduleData.businessAreaId).businessAreaKey;

        const businessAreaDesc = businessArea  &&  businessArea.find(item => item.id === paymentScheduleData.businessAreaId) &&
        businessArea.find(item => item.id === paymentScheduleData.businessAreaId).businessAreaDesc;

        if(businessAreaKey && businessAreaDesc){
             return  `${businessAreaKey} - ${businessAreaDesc}`
        }
    }

    const getCourseIdName = () => {
        const courseName = courseDetails.find(item => item.courseId === paymentScheduleData.courseIdRegular.toString()) &&
        courseDetails.find(item => item.courseId === paymentScheduleData.courseIdRegular.toString()).courseName;

        if(courseName){
             return  `${paymentScheduleData.courseIdRegular} - ${courseName}`
        }
    }

    const filterHandler = _.debounce((installmentData) => {
        let n = pagination;
        let pageSize = 10;
        if (installmentData) {
            const filterData = tableData.filter(data => (
                courseYearData.find(item => item.id === data.courseYearId) &&
                    courseYearData.find(item => item.id === data.courseYearId).courseYearDispValue.toUpperCase().includes(installmentData.toUpperCase())  ||

                data.itemTypeDetails.description && data.itemTypeDetails.description.toUpperCase().includes(installmentData.toUpperCase()) ||
                data.itemTypeDetails.itemTypeId && data.itemTypeDetails.itemTypeId.toString().toUpperCase().includes(installmentData.toUpperCase()) ||
                data.dueDate.toUpperCase().includes(installmentData.toUpperCase()) ||
                data['baseFee'].toString().toUpperCase().includes(installmentData.toUpperCase()) ||
                data['tax'].toString().toUpperCase().includes(installmentData.toUpperCase()) ||
                data['totalAmount'].toString().toUpperCase().includes(installmentData.toUpperCase()) ||
                data['updatedOn'].toUpperCase().includes(installmentData.toUpperCase()) ||
                data['status'].toUpperCase().includes(installmentData.toUpperCase())
            ));
            searchPaginationHandler(filterData, pageSize, n)
        } else {
            searchPaginationHandler(tableData, pageSize, n);
        }
    }, 300)


    return (
        <>
            <Card className='mb-3'>
                <CardHeader className="border-0">
                    <h3 className="mb-0 floatLeft">Payment Schedule {<BsFillForwardFill style={{fontSize:'24px'}}/>} View Installments</h3>
                </CardHeader>
            </Card>
            <Card className='mb-0'>
                <CardHeader>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label
                                    className="form-control-label"
                                    htmlFor="example3cols2Input"
                                >
                                    Business Group
                                </Label>
                                <Input
                                    maxLength="30"
                                    disabled
                                    defaultValue={getBusinessGroupDisplayValue()}
                                    name="atrributeDesc"
                                    type="text"
                                    style={{ height: '37px' }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label
                                    className="form-control-label"
                                    htmlFor="example3cols2Input"
                                >
                                    Company Code
                                </Label>
                                <Input
                                    maxLength="30"
                                    disabled
                                    defaultValue={getCompanyCode()}
                                    name="atrributeDesc"
                                    type="text"
                                    style={{ height: '37px' }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label
                                    className="form-control-label"
                                    htmlFor="example3cols2Input"
                                >
                                    Business Area - Name
                                </Label>
                                <Input
                                    maxLength="30"
                                    disabled
                                    defaultValue={getBusinessAreaName()}
                                    name="atrributeDesc"
                                    type="text"
                                    style={{ height: '37px' }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label
                                    className="form-control-label"
                                    htmlFor="example3cols2Input"
                                >
                                    Cashier Office
                                </Label>
                                <Input
                                    maxLength="30"
                                    disabled
                                    defaultValue={getCashierOfficeDisplayValue(paymentScheduleData.cashierOfficeId)}
                                    name="atrributeDesc"
                                    type="text"
                                    style={{ height: '37px' }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label
                                    className="form-control-label"
                                    htmlFor="example3cols2Input"
                                >
                                    Course ID - Course Name
                                </Label>
                                <Input
                                    maxLength="30"
                                    disabled
                                    defaultValue={getCourseIdName()}
                                    name="atrributeDesc"
                                    type="text"
                                    style={{ height: '37px' }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label
                                    className="form-control-label"
                                    htmlFor="example3cols2Input"
                                >
                                    Term
                                </Label>
                                <Input
                                    maxLength="30"
                                    disabled
                                    defaultValue={getTermDisplayValue(paymentScheduleData.termId)}
                                    name="atrributeDesc"
                                    type="text"
                                    style={{ height: '37px' }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label
                                    className="form-control-label"
                                    htmlFor="example3cols2Input"
                                >
                                    Course Start Date
                                </Label>
                                <Input
                                    maxLength="30"
                                    disabled
                                    defaultValue={moment(paymentScheduleData.courseStartDate).format('DD-MM-YYYY')}
                                    name="atrributeDesc"
                                    type="text"
                                    style={{ height: '37px' }}
                                />
                            </FormGroup>
                            <h5>Last Updated Date:{moment(paymentScheduleData.updatedOn).format('DD-MM-YYYY')}</h5>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label
                                    className="form-control-label"
                                    htmlFor="example3cols2Input"
                                >
                                    Course End Date
                                </Label>
                                <Input
                                    maxLength="30"
                                    disabled
                                    defaultValue={moment(paymentScheduleData.courseEndDate).format('DD-MM-YYYY')}
                                    name="atrributeDesc"
                                    type="text"
                                    style={{ height: '37px' }}
                                />
                            </FormGroup>
                            <h5 style={{display:'inline'}}>No Of Installments: {paymentScheduleData.totalCourseFeeCount}</h5>
                            <h5 style={{display:'inline'}} className='ml-3'>Base Fees: {paymentScheduleData.totalBaseFee}</h5>
                            <h5 style={{display:'inline'}} className='ml-3'>Total Fees: {paymentScheduleData.totalAmount}</h5>
                        </Col>
                    </Row>
                </CardHeader>
            </Card>
            <Card style={{ boxShadow: 'none' }} className='mb-0'>
                <CardHeader className="border-0">
                    <div
                        className="floatRight "
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        Search :{' '}
                        <Input
                            className="tdInput ml-3 mr-3 customInputWidth"
                            placeholder="Search"
                            onChange={(e) => {
                                let n = e.target.value.trim();
                                filterHandler(n);
                            }}
                        />
                        <Button disabled={props.isSaveVisible} color="info" size="sm" type="button" className={"floatRight mx-1"} onClick={(e) => exportToCSV()}>Download</Button>
                    </div>
                </CardHeader>
                <div id="questionTable" className="table-responsive" style={{ marginBottom: '3.5rem' }}>
                    <Table className="align-items-center table-flush " >
                        <MasterHeaderAuto headerList={headersList} />
                        <tbody className="list">
                        {props.classArray.map((el, index) =>
                           <tr key={index}>
                            <th className="text-center">{getCourseYear(el.courseYearId)}</th>
                            <th className="text-center">{moment(el.courseYearId.dueDate).format('DD-MM-YYYY')}</th>
                            <th className="text-center white-breakSpace">{el.itemTypeDetails.itemTypeId}</th>
                            <th className="text-center white-breakSpace">{el.itemTypeDetails.description}</th>
                            <th className="text-center">{el.baseFee}</th>
                            <th className="text-center">{el.tax}</th>
                            <th className="text-center">{el.totalAmount}</th>
                            <th className="text-center">
                                {el.cashAllowed ? <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" checked />:
                                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" checked />}
                            </th>
                            <th className="text-center">{el.status}</th>
                            <th className="text-center">{moment(el.updatedOn).format('DD-MM-YYYY')}</th>
                            </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </Card>
        </>
    )
}
export default PaymentScheduleTable;