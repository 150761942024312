import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { masterServiceBaseUrl, getAPI, putpost, putpost2 } from 'services/http'
import CustomLoader from 'views/pages/manage/common/commonComponents/customTable/loader'
import CompanyCodeAddMidiator from './companyCodeAddMidiator'
import { failureToast, successToast } from '../../../common/utils/methods/toasterFunctions/function';


const CompanyCodeAddressInitiator = (props) => {

    const { id, isNew = true } = props;
    const [country, setCountry] = useState([])
    const [stateName, setStateName] = useState([])
    const [city, setCity] = useState([])
    const [district, setDistrict] = useState([])
    const [pincode, setPincode] = useState([])
    const [grpCode, setGrpCode] = useState([])
    const [addressList, setAddressList] = useState([])
    const [codeDetails, setCodeDetails] = useState(null)
    const [addressCategory, setAddressCategory] = useState([])
    const [apiloader, setapiloader] = useState(true)
    const [building, setBuilding] = useState([]);

    const fetchInitialData = async (url, stateMethod, name) => {
        return new Promise((resolve, reject) => {
            getAPI(url, (data) => {
                let list = [];
                if (typeof name !== 'undefined' && name !== '' && name != 'building') {
                    //this is for dropdown fields only
                    let keyName = `${name}DispValue`
                    if (name === "addressCategoryType") {
                        keyName = name
                    }
                    list = data.data.map(
                        data => {
                            return {
                                value: data.id,
                                label: data[keyName]
                            };
                        }
                    );
                    stateMethod(list)
                }
                else if (name === 'building') {
                    list = data.data.map(
                        data => {
                            return {
                                value: data.id,
                                label: data["buildingName"]
                            };
                        }
                    );
                    stateMethod(list)
                } else {
                    stateMethod(data.data)
                }
                resolve(data?.data);

            }, (data) => { failureToast(data['message']); reject({}) })
        });
    }

    const getAddressById = (bool) => {
        if (!isNew || bool) {
            const formData = {
                "addressReferenceType": "COMPANY_CODE",
                "referenceId": id
            }
            putpost(`${masterServiceBaseUrl}/address/getAddressByReference`, (data) => {
                // successToast(data['message'])
                setAddressList(data.data)
            }, (data) => { failureToast(data['message']) }, formData, 'post')
        }
    }


    const setValues = async (id) => {
        const p = await Promise.all([
            fetchInitialData(masterServiceBaseUrl + '/country/getAllActiveCountry', setCountry, 'country'),
            fetchInitialData(masterServiceBaseUrl + '/state/getAllActiveState', setStateName, 'state'),
            fetchInitialData(masterServiceBaseUrl + '/city/getAllActiveCity', setCity, 'city'),
            fetchInitialData(masterServiceBaseUrl + '/district/getAllActiveDistrict', setDistrict, 'district'),
            fetchInitialData(masterServiceBaseUrl + '/pincode/getAllActivePincode', setPincode, 'pincode'),
            fetchInitialData(masterServiceBaseUrl + '/groupCode/getAllActiveGroupCode', setGrpCode),
            fetchInitialData(masterServiceBaseUrl + `/companyCode/getCompanyCode/${id}`, setCodeDetails),
            fetchInitialData(masterServiceBaseUrl + '/addressCategory/getAllActiveAddressCategory', setAddressCategory, 'addressCategoryType'),
            fetchInitialData(masterServiceBaseUrl + '/buildingMaster/getActiveBuildingMaster', setBuilding, 'building')
        ]);
        getAddressById()
    }



    useEffect(() => {
        setValues(id);
        setapiloader(false);
    }, [id])

    return (
        apiloader ?
            <div
                className="mx-auto text-center py-5 my-5"
                style={{ height: "100vh" }}
            >
                <CustomLoader apiLoader={apiloader} />
            </div> :
            <CompanyCodeAddMidiator
                countryData={country}
                stateData={stateName}
                cityData={city}
                districtData={district}
                pincodeData={pincode}
                codeDetails={codeDetails}
                savedAddressList={addressList}
                addressCategory={addressCategory}
                grpCode={grpCode}
                id={id}
                resetList={getAddressById}
                building={building}
            />
    )
}

export default CompanyCodeAddressInitiator