import React from 'react';
import EditIcon from 'assets/img/svg/edit.svg';
import ViewIcon from 'assets/img/svg/view.svg';
import ResetPasswordIcon from 'assets/img/icons/common/reset-icon.png'
import DownloadIcon from 'assets/img/svg/download.svg';
import downloadBlack from 'assets/img/svg/downloadBlack.svg';
import UploadIcon from 'assets/img/svg/upload.svg';
import MsExcel from 'assets/img/svg/ms_excel.svg';
const Create = <i className="fas fa-plus" />;
const Search = <i className="fas fa-search" />;
const Edit = <img style={{ height: '28px', width: '28px', padding: '6px' }} src={EditIcon} />;
const ResetIcon = () => <img style={{ height: '28px', width: '28px', padding: '0px' }} src={require('./reset-password-icon.svg')} />;
// const Edit = <i className="fas fa-edit" />;
// const View = <i className="fas fa-eye" />;
const View = <img style={{ height: '28px', width: '28px', padding: '4px' }} src={ViewIcon} />;
const Delete = <i className="fas fa-trash" />;
const Cancel = <i className="fas fa-times" />;
// const Upload = <i className="fas fa-upload" />;
// const Download = <i className="fas fa-download" />;
const Upload = <img src={UploadIcon} />;
const Download = <img src={DownloadIcon} />;
const DownloadBlack = <img src={downloadBlack} />;
const ExportToExcel = <img src={MsExcel} />
const Loading = <i className="fas fa-spinner fa-spin" aria-hidden="true"></i>
const HomeIcon = (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.415 22.953v-4.456h3.566v4.456c0 .49.4.892.891.892h2.674c.49 0 .891-.401.891-.892v-6.239h1.515c.41 0 .607-.508.295-.775l-7.452-6.712a.898.898 0 0 0-1.194 0L8.15 15.94a.444.444 0 0 0 .294.775h1.515v6.24c0 .49.401.89.891.89h2.674c.49 0 .891-.4.891-.89z"
			fill="#00B0F5"
			fill-rule="nonzero"
		/>
	</svg>
);
;
const Icons = {
  c: Create,
  r: View,
  u: Edit,
  d: Delete,
  s: Search,
  cancel: Cancel,
  download: Download,
  upload: Upload,
  loading: Loading,
  exporttoexcel: ExportToExcel,
  download2: DownloadBlack
};
export { Create, Search, Edit, View, Delete, Cancel, Icons, HomeIcon, ResetIcon };
