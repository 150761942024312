import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Modal, ModalBody } from "reactstrap";
import { ReactComponent as CloseIcon } from "../../../../../../../assets/img/svg/CrossIcon.svg";
import {
  fetchAllPromisedData,
  fetchAllPostPromisedData,
} from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "views/pages/manage/common/constant";
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import { AGridColDef } from "views/pages/manage/common/data-grid/ADataGridColDef";
import moment from "moment";
import CustomChip from "views/pages/manage/common/customChip/CustomChip";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import {
  gridStyles,
  gridComponents,
} from "../../../issuanceIDcard/printIdCardAndMapRfid/constant";
import ADataGrid from "views/pages/manage/common/data-grid/ADataGrid";
import ALoader from "views/pages/manage/common/a-loader";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx";

const DispatchScheduleHistoryModal = ({
  open,
  setOpen,
  historyDataChanged,
  setHistoryDataChanged,
  historyListType = "CREATE_DISPATCH_SCHEDULE",
  sendNotifications,
  isShowNotifyButton,
}) => {
  const [historyData, setHistoryData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const param = useParams();
  const { academicCareer } = param;
  historyListType =
    academicCareer === "DIGITAL"
      ? "DIGITAL_CREATE_DISPATCH_SCHEDULE"
      : historyListType;

  const statusChip = {
    SUCCESS: { text: "Completed", textColor: "##0B442D", bgColor: "#BBE2CE" },
    FAILED: { text: "Failed", textColor: "#B32306", bgColor: "#F9D9D7" },
    PROCESSING: {
      text: "In-processing",
      textColor: "#006A93",
      bgColor: "#CCEFFD",
    },
  };

  const getHistoryData = async (page) => {
    setLoading(true);
    try {
      const resp = await fetchAllPromisedData(
        `${endpoint.dispatchSchedule.getAllDlpUploadFiles}=${historyListType}&offset=${page}`,
        true
      );
      if (resp?.code === 200) {
        setHistoryData(resp?.data?.fileList);
        setTotalRecords(resp?.data?.totalRecords);
        setHistoryDataChanged(false);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const onDownload = async (row) => {
    const downloadKey =
      row?.status === "SUCCESS" ? row?.fileKey : row?.errorKey;

    const templateUrl = await fetchAllPostPromisedData(
      `${endpoint.fetchS3Url.getDownloadUrl}?key=${downloadKey}`,
      {}
    );
    if (templateUrl?.code === 200 && templateUrl?.data) {
      window.open(`${templateUrl?.data?.url}`);
    } else {
      failureToast("Something went wrong please try after sometime.");
    }
  };

  const nextButtonHandler = (page) => {
    setCurrentPage(page);
    getHistoryData(page);
  };

  useEffect(() => {
    open && getHistoryData(0);
  }, [open]);

  const headers = [
    new AGridColDef("createdOn", "Upload date & time")
      .setMinWidth(170)
      .renderCellComponent((params) => (
        <span>{moment(params.value).format("DD/MM/yyyy, hh:mm")}</span>
      )),

    new AGridColDef("processInstanceId", "Process Id")
      .setMinWidth(150)
      .renderCellComponent((params) => <span>{params.value || " --"}</span>),

    new AGridColDef("status", "Status")
      .setMinWidth(100)
      .renderCellComponent((params) => {
        const statusChipColor = statusChip[params.value];
        return (
          <CustomChip
            bgColor={statusChipColor?.["bgColor"]}
            textColor={statusChipColor?.["textColor"]}
            text={statusChipColor?.["text"]}
          >
            {params.value}
          </CustomChip>
        );
      }),

    new AGridColDef("errorKey", "Action", false)
      .setMinWidth(210)
      .setFlex(0.7)
      .renderCellComponent((params) => {
        const status = params?.row?.status;

        return (
          <div>
            <AButton
              className="btn-left-0"
              size="xs"
              variant="primary_filled"
              onClick={() => onDownload(params?.row)}
              disabled={
                status === "PROCESSING" ||
                (status === "SUCCESS" && !params?.row?.fileKey) ||
                (status === "FAILED" && !params?.row?.errorKey)
              }
            >
              Download
            </AButton>
            {isShowNotifyButton && (
              <AButton
                size="xs"
                variant="primary_filled"
                updatedStyle={{ minWidth: "5.1rem" }}
                onClick={() => sendNotifications(params?.row)}
                disabled={
                  params?.row?.status !== "SUCCESS" ||
                  params?.row?.notificationSent
                }
              >
                {params?.row?.notificationSent ? "Notified" : "Notify"}
              </AButton>
            )}
          </div>
        );
      }),
  ];

  return (
    <Modal size="lg" style={{ width: "740px" }} isOpen={open}>
      <ModalBody>
        <div className="d-flex justify-content-between align-items-center">
          <h2>File Upload History</h2>
          <IconButtonWrapper onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButtonWrapper>
        </div>

        {loading && <ALoader />}
        <ADataGrid
          removeWrapperContainer={true}
          rows={historyData}
          columns={headers}
          rowId={(row) => row.id}
          rowCount={totalRecords}
          currentPage={currentPage}
          nexButtonHandler={(nextPage) => nextButtonHandler(nextPage)}
          sx={{
            ".MuiDataGrid-main": {
              height: `${60 + historyData?.length * 50}px`,
              minHeight: `${60 + 50}px`,
              maxHeight: `${60 + 50 * 8}px`,
            },
            // ".MuiDataGrid-footerContainer": {
            //     display: totalRecords <= 50 ? "none" : "block"
            // },
            ...gridStyles,
          }}
          rowHeight={50}
          hidePagination={totalRecords <= 50}
          components={{ ...gridComponents }}
        />
      </ModalBody>
    </Modal>
  );
};

export default DispatchScheduleHistoryModal;
