import React from 'react';
import { withRouter } from 'react-router-dom';
import {
	Card,
	CardHeader,
	Input,
	Container,
	Button,
	Table,
	Row,
} from 'reactstrap';
import { useState } from 'react';
import { successToast, failureToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import {
	TERMTDROW,
	TermMasterHeader,
} from '../../../common/commonComponents/masterRow/masterRow';
import {
	masterServiceBaseUrl,
	putpost,
	putpost2,
	deleteAPI,
} from 'services/http';
import moment from 'moment';
import CustomButton from 'components/CustomButton';
import CustomLoader from '../../../common/commonComponents/Loader/loader';

var _ = require('lodash');

const TermFormTable = (props) => {
	const { userPermissions, apiloader } = props;
	const [isSaveVisible, setisSaveVisible] = useState(false);
	const [tableDataKey, settableDataKey] = useState('tableDataKey');
	const addNewClass = () => {
		props.setclassArray([
			{
				editMode: true,
				__status: '__new',
				termFormKey: null,
				status: 'ACTIVE',
				termFormDesc: null,
				termFormDispValue: null,
				effectiveDate: null,
			},
			...props.classArray,
		]);
	};
	const saveAll = (cb) => {
		var _tempArray = props.classArray;
		var isValid = true;
		_tempArray.forEach((el, index) => {
			if (el['editMode']) {
				if (el['termFormKey'] === null || !el['termFormKey'].trim()) {
					isValid = false;
					failureToast('Please enter the value of Term Form Key');
					return;
				} else if (el['termFormDesc'] === null || !el['termFormDesc'].trim()) {
					isValid = false;
					failureToast('Please enter the value of Description');
					return;
				} else if (
					el['termFormDispValue'] === null ||
					!el['termFormDispValue'].trim()
				) {
					isValid = false;
					failureToast('Please enter the value of Display Value');
					return;
				}
				if (!isValid) {
					return;
				}
				if (_tempArray[index]['__status'] == '__new') {
					putpost(
						masterServiceBaseUrl + '/termForm/createTermForm',
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							props.fetchClasses(
								masterServiceBaseUrl + '/termForm/getAllTermForm'
							);
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'post'
					);
				} else {
					putpost(
						masterServiceBaseUrl +
							'/termForm/updateTermForm/' +
							_tempArray[index].id,
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							delete _tempArray[index]['editMode'];
							props.setclassArray(_tempArray);
							settableDataKey(new Date().getMilliseconds());
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'put'
					);
				}
			} else {
			}
		});
	};
	const newRow = () => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				addNewClass();
				setisSaveVisible(true);
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			props.setclassArray(_tempArray);
			setisSaveVisible(false);
			cb();
		}
	};
	const editRow = (index) => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				var _tempArray = props.classArray;
				_tempArray[index]['editMode'] = true;
				props.setclassArray(_tempArray);
				setisSaveVisible(true);
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			cb();
		}
	};
	const deleteRow = (index) => {
		if (props.classArray[index]['__status'] == '__new') {
			let n = props.classArray;
			n.splice(index, 1);
			props.setclassArray(n);
			setisSaveVisible(false);
			settableDataKey(new Date().getMilliseconds());
		} else {
			if (window.confirm('Are you sure you want to delete this Term Form?')) {
				putpost2(
					masterServiceBaseUrl +
						'/termMapping/deleteTermMapping/' +
						props.classArray[index].id,
					(data) => {
						successToast(data['message']);
						if (props.classArray.length == 1) {
							props.previousPage();
							props.fetchClasses(
								masterServiceBaseUrl + '/termMapping/getAllTermMapping'
							);
						} else {
							var _tempArray = props.classArray;
							if (_tempArray[index]['editMode']) {
								setisSaveVisible(false);
							}
							_tempArray.splice(index, 1);
							props.setclassArray(_tempArray);
						}
						settableDataKey(new Date().getMilliseconds());
					},
					(data) => {
						failureToast(data['message']);
					},
					'Delete'
				);
			}
		}
	};
	const updateKey = (index, value, key) => {
		var _tempArray = props.classArray;
		_tempArray[index][key] = value;
		props.setclassArray(_tempArray);
	};
	return (
		<>
			<Container className="mt-3" fluid>
				<Row>
					<div className="col">
						<Card>
							<CardHeader className="border-0">
								<h3 className="mb-0 floatLeft">Term Table</h3>
								<CustomButton
									disabled={isSaveVisible}
									className={
										'floatRight mx-1' +
										(isSaveVisible ? ' btn-dark cursor-gray' : null)
									}
									content={'New Term Table'}
									permissionType={'C'}
									icon={true}
									permissionSet={userPermissions}
									onClick={() => {
										props.history.push('/admin/termForm/new');
									}}
								/>
							</CardHeader>
							{props.children}
							<CardHeader className="border-0">
								<h3 className="mb-0 floatLeft">Search Results</h3>
							</CardHeader>
							{apiloader ?
								<CustomLoader
									apiLoader={apiloader}
									loaderHeight={'200px'}
									loaderWidth={'100% '}
								/> :
								<div id="questionTable" className="table-responsive">
									<Table className="align-items-center table-flush tableLayout">
										<TermMasterHeader
											type={'TermForm'}
											isSaveVisible={isSaveVisible}
											permissionSet={userPermissions}
										/>
										<tbody className="list" key={tableDataKey}>
											{props.classArray
												? props.classArray.map((el, index) =>
														!el['editMode'] ? (
															<TERMTDROW
																type={'termForm'}
																index={index}
																el={el}
																deleteRow={deleteRow}
																editRow={() =>
																	props.history.push(
																		`/admin/termForm/edit/${el.id}`
																	)
																}
																viewRow={() =>
																	props.history.push(
																		`/admin/termForm/view/${el.id}`
																	)
																}
																academicData={props.academicData}
																termData={props.termData}
																isSaveVisible={isSaveVisible}
																permissionSet={userPermissions}
															/>
														) : (
															<tr key={index + '-class'}>
																<td className="text-center p-2 mx-1">
																	<Input
																		data-testid="termFormKey"
																		id="termFormKey"
																		maxLength="50"
																		defaultValue={el['termFormKey']}
																		onChange={(e) => {
																			updateKey(
																				index,
																				e.target.value,
																				'termFormKey'
																			);
																		}}
																		placeholder="Example:1"
																		className="tdInput"
																		name="termFormKey"
																	/>
																</td>
																<td className="text-center p-2 mx-1">
																	<Input
																		data-testid="effectiveDate"
																		disabled={true}
																		defaultValue={
																			el['__status'] == '__new'
																				? null
																				: moment(el['eff_date']).format(
																						'DD-MM-YYYY'
																				)
																		}
																		placeholder="Example:01-01-2020"
																		className="tdInput"
																	/>
																</td>
																<td className="text-center p-2 mx-1">
																	<Input
																		data-testid="termFormDesc"
																		maxLength="50"
																		defaultValue={el['termFormDesc']}
																		onChange={(e) => {
																			updateKey(
																				index,
																				e.target.value,
																				'termFormDesc'
																			);
																		}}
																		placeholder="Description"
																		className="tdInput"
																	/>
																</td>
																<td className="text-center p-2 mx-1">
																	<Input
																		data-testid="termFormDispValue"
																		maxLength="50"
																		defaultValue={el['termFormDispValue']}
																		onChange={(e) => {
																			updateKey(
																				index,
																				e.target.value,
																				'termFormDispValue'
																			);
																		}}
																		placeholder="Display Value"
																		className="tdInput"
																	/>
																</td>
																<td className="text-center p-2 mx-1">
																	<label className="custom-toggle mx-auto ml-2">
																		<input
																			checked={
																				el.status == 'ACTIVE' ? true : false
																			}
																			type="checkbox"
																			id={el.id}
																			onChange={(e) => {
																				settableDataKey(
																					new Date().getMilliseconds()
																				);
																				let n = 'ACTIVE';
																				if (!e.target.checked) {
																					n = 'INACTIVE';
																				}
																				updateKey(index, n, 'status');
																			}}
																			data-testid="termFormStatus"
																		/>
																		<span
																			style={{ width: '72px' }}
																			className="custom-toggle-slider rounded-circle activeToggle"
																			data-label-off="Inactive"
																			data-label-on="Active"
																		/>
																	</label>
																</td>
																<td className="text-center">
																	{isSaveVisible ? (
																		<>
																			<CustomButton
																				content={'Save'}
																				permissionType={'C,U'}
																				forTable={true}
																				permissionSet={userPermissions}
																				onClick={() => {
																					saveAll();
																				}}
																			/>
																			<CustomButton
																				permissionType={'cancel'}
																				icon={true}
																				forTable={true}
																				permissionSet={userPermissions}
																				onClick={() => {
																					let n = props.classArray;
																					if (n[index]['__status'] == '__new') {
																						n.splice(index, 1);
																					} else {
																						props.fetchClasses(
																							masterServiceBaseUrl +
																								'/termForm/getAllTermForm'
																						);
																					}
																					props.setclassArray(n);
																					setisSaveVisible(false);
																				}}
																			/>
																		</>
																	) : (
																		<CustomButton
																			permissionType={'D'}
																			icon={true}
																			forTable={true}
																			permissionSet={userPermissions}
																			onClick={() => deleteRow(index)}
																		/>
																	)}
																</td>
															</tr>
														)
												)
												: null}
										</tbody>
									</Table>
								</div>
							}
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};
export default withRouter(TermFormTable);
