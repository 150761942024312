import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  masterServiceBaseUrl,
  deleteAPI,
  getAPI
} from 'services/http';

import { failureToast,successToast } from '../../common/utils/methods/toasterFunctions/function';

import {
  Table
} from 'reactstrap';
import { checkDataForRedirect } from './businessUnitRedirect';
import { GetPagination } from '../../common/commonComponents/pagination/pagination';
import { BusinessAreaDetails, BusinessAreaSearchHeader } from '../../common/commonComponents/masterRow/masterRow';
import { PermisionDenied } from "../../common/commonComponents";

const BusinessAreaTable = (props) => {
  const { userPermissions } = props;
  let history = useHistory();
  const {
    pagination,
    setPagination,
    state,
    setState,
    fetchSearchList,
    businessAreaDetails,
    setBusinessAreaDetails,
    allGroupCode,
    allCompanyCode,
    allBusinessUnitType,
    allStateName,
    currentPage
  } = props;

  const [apiLoader, setApiLoader] = useState(false);
  const [tableDataKey, settableDataKey] = useState('tableDataKey');
  const [change, setChange] = useState(false);

  const pageSize = 10;

  const deleteRow = (index) => {
    try {
      if (
        window.confirm(
          'Are you sure you want to delete this Business Area Detail?'
        )
      ) {
        deleteAPI(
          masterServiceBaseUrl +
          `/businessArea/deleteBusinessArea/` +
          businessAreaDetails[index].businessAreaId,
          (data) => {
            let temp = businessAreaDetails;
            let __temp = state;
            temp.splice(index, 1);
            __temp.splice(index, 1);
            setBusinessAreaDetails(temp);
            setState(__temp);
            setChange(!change);
            successToast(data['message']);
          },
          (data) => {
            failureToast(data['message']);
          },
          'Delete'
        );
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  const openInNewTab = async (url, id) => {

    try {
      getAPI(masterServiceBaseUrl + `/businessArea/getBusinessAreaDetails/${id}`, async (data) => {
        const redirectData = await checkDataForRedirect(data['data']);
        window.localStorage.setItem('businessAreaData', JSON.stringify(data['data']))
        if (redirectData) {
          window.open(`${document.URL}${url}`, '_blank');
        } else {
          if (id !== 'new') {
            history.push(`/admin/businessUnit/${id}`);
          } else {
            history.push('/admin/businessUnit/new');
          }
         
        }
      }, (data) => { failureToast(data['message']) })

    } catch (e) {
      console.log("Error", e)
    }
    // const redirectData = await checkForRedirect( id );
    // console.log('Redirect data is here : ', redirectData );
    // window.open( `${ document.URL }${ url }`, '_blank' );

  };

  return (
    userPermissions ?
      <>
        <div id="questionTable" className="table-responsive">
          <Table className="align-items-center table-flush">
            <BusinessAreaSearchHeader />
            <tbody className="list" key={tableDataKey}>
              {props.businessAreaDetails
                ? props.businessAreaDetails.map((el, index) => (
                  <BusinessAreaDetails
                    type={'BusinessAreaDetails'}
                    index={currentPage === 1 ? index : index+((currentPage-1)*10)}
                    el={el}
                    deleteRow={deleteRow}
                    viewRow={() => {
                      openInNewTab(`/view/${el.businessAreaId}`, el.businessAreaId);
                    }}
                    editRow={() => openInNewTab(`/edit/${el.businessAreaId}`, el.businessAreaId)}
                    businessAreaDetailsAll={props.businessAreaDetails}
                    group={allGroupCode}
                    companyCode={allCompanyCode}
                    businessUnitType={allBusinessUnitType}
                    allStateName={allStateName}
                    userPermissions={userPermissions}
                  />
                ))
                : null}
            </tbody>
          </Table>
          {pagination.totalPage > 1 ? (
            <GetPagination
              pagination={pagination}
              isSaveVisible={false}
              setclassArray={setBusinessAreaDetails}
              pageSize={pageSize}
              // pagination={pagination}
              setpagination={setPagination}
              state={state}
              isPaginationFromServer={false}
              isFetching={apiLoader}
            />
          ) : null}
        </div>
      </> : <PermisionDenied />

  );
};



export default BusinessAreaTable;