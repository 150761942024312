import React, { useEffect, useState } from "react";
import SelectSearchInputParam from "../../../studentSerach/SelectSearchInputParam";
import AInput from "../../../../common/formFeilds/customInput/AInput";
import AButton from "../../../../common/form-fields-mui/AButton";
import SearchFilter from "./SearchFilter";
import { fetchDispatchScheduleDropdown } from "../../customhooks";
import UploadBulkDispatch from "../../stockConsumption/form/UploadBulkDispatch";

const TableFilter = ({
  formHandler,
  applyFilterHandler,
  filterForm,
  dropdown,
  setFilterForm,
  fetchList,
  selectedTab,
  downloadHandler,
  sendNotifications,
}) => {
  const [dispatchScheduleDropdown, setDispatchScheduleDropdown] = useState([]);

  useEffect(() => {
    fetchDispatchScheduleDropdown({}, setDispatchScheduleDropdown);
  }, []);
  const [searchInputParam, setSearchInputParam] = useState("psid");
  const [userInput, setUserInput] = useState("");
  let searchParamTitleMap = { psid: "PSID", applicationId: "application ID" };
  const keyOppositeMap = { psid: "applicationId", applicationId: "psid" };

  const onKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      applyFilterHandler();
    }
  };

  return (
    <div className="d-flex gap-lg align-items-center">
      <div className="d-flex align-items-center">
        <SelectSearchInputParam
          width="180px"
          isHideName={true}
          searchInputParam={searchInputParam}
          setSearchInputParam={setSearchInputParam}
        />
        <div>
          <AInput
            placeholder={`Search by ${searchParamTitleMap[searchInputParam]}`}
            style={{ minWidth: "250px" }}
            rightSearchIcon
            crossIcon
            value={filterForm[searchInputParam]}
            handler={(value) => {
              formHandler(value, searchInputParam);
              formHandler("", keyOppositeMap[searchInputParam]);
            }}
            onKeyDown={(e) => onKeyDown(e)}
            crossHandler={() => {
              formHandler("", searchInputParam);
              applyFilterHandler({ [searchInputParam]: "" });
            }}
            searchIconClickHandler={() => applyFilterHandler()}
          />
        </div>
      </div>
      <SearchFilter
        dropdown={dropdown}
        applyFilter={applyFilterHandler}
        applyNameFilter={() => {}}
        formHandler={formHandler}
        filterForm={filterForm}
        setFilterForm={setFilterForm}
        dispatchScheduleDropdown={dispatchScheduleDropdown}
      />
      {selectedTab === "DISPATCH_PENDING" && (
        <UploadBulkDispatch
          downloadHandler={downloadHandler}
          sendNotifications={sendNotifications}
          fetchList={fetchList}
          historyListType="UPDATE_DISPATCH_DETAIL"
        />
      )}
    </div>
  );
};

export default TableFilter;
