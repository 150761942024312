import { knowYourRoleServiceUrl, authServiceUrl, validateToken } from 'services/http';
import { getCookie, eraseCookie } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { failureToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import { controller, signal } from 'services/utils/helpers';
import { LOGOUTMESSAGE } from 'services/constant';


export const getUserPermission = async () => {
  // const validate = await validateToken();
  // if (validate?.statusCode === 401) {
  //   eraseCookie('token');
  //   window.location.replace(document.URL.split('#')[0]);
  // }
  return new Promise((resolve, reject) => {
    fetch(`${knowYourRoleServiceUrl}/userMaster/getUserPermissionsForAuth`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie('token')}`,
        // TOKEN: getCookie('token'),
        // clientId: getCookie('userId'),
        // PROJECTID: 'PHOENIX',
      },
      redirect: 'follow',
      signal
    })
      .then(response => {
        if ((response.status === 403 || response.status === 401) && getCookie('token')) {
          eraseCookie('token');
          controller.abort();
          window.location.replace(document.URL.split('#')[0]);
          failureToast(LOGOUTMESSAGE)
          return null;
        }

        return response.json();
      })
      .then(data => {
        if (data && data.code === 200) {
          resolve(data?.data);
        } else {
          resolve({});
        }
      })
      .catch(error => {
        resolve({});
        console.error('Error:', error);
      });
  });
};

export const getUserDetails = async () => {
  // const validate = await validateToken();
  // if (validate?.statusCode === 401) {
  //   eraseCookie('token');
  //   window.location.replace(document.URL.split('#')[0]);
  // }
  return new Promise((resolve, reject) => {
    fetch(`${authServiceUrl}/userInfo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie('token')}`,
      },
      signal
    })
      .then(function (response) {
        if ((response.status === 403 || response.status === 401) && getCookie('token')) {
          failureToast(LOGOUTMESSAGE)
          eraseCookie('token');
          controller.abort();
          setTimeout(() => window.location.replace(`${document.URL.split('#')[0]}`), 3000);
          return null
        }

        if (response.ok) {
          return response.json();
        } else {
          response
            .json()
            .then(er => {
              // failureToast('User Details Not Found');
            })
            .catch(error => { });
        }
      })
      .then(function (data) {
        resolve(data);
      })
      .catch(error => {
        resolve({});
        console.log(error);
      });
  });
};
