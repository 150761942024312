import React, { useContext, useState } from "react";
import ListHeader from "../../../issuanceIDcard/studentList/ListHeader";
// import DlpSearchInput from "../studentList/DlpSearchInput";
// import SelectFilterChip from "./SelectFilterChip";
// import FilterForm from "../../dlpStockIssuance/FilterForm";
import ALoader from "views/pages/manage/common/a-loader";
import BranchConsumptionReturnPopup from "../../consumptionReturn";

import { useFetchDispatchScheduleDropdown } from "../../customhooks";
import { useFetchDropdown } from "../../../issuanceIDcard/customhooks";
import StockTransactionsHistoryPopup from "../../stockTxns";
import AddToFavourite from "../../../../homepage/favourites/AddToFavourite";
import { pages } from "views/pages/manage/common/constant";
import { useGetDigitalModuleContext } from "../../contextApi/digitalContext";
import ModuleCards from "./ModuleCards";
import FilterForm from "./FilterForm";
import DigitalDownloadReportsDialog from "../../downloadReports/DigitalDownloadReportsDialog";
import {
  PermissionContext,
  RolePermissions,
} from "../../../../../../../appContext";

const ApplicationFilterLandingPage = ({ disabled }) => {
  const markEligiblePermissions = RolePermissions(
    useContext(PermissionContext),
    pages["digitalMarkEligible"]["id"]
  );
  const {
    selectedFilter,
    isApplicationLoading,
    loading,
    filterForm,
    dropdown,
  } = useGetDigitalModuleContext();

  const [dropdownDlp, loader] = useFetchDropdown("DIGITAL");

  const [dispatchScheduleDropdown, isDropdownLoading] =
    useFetchDispatchScheduleDropdown({
      ...filterForm,
      academicCareer: "DIGITAL",
    });
  const [isStockTxnDialogOpen, setIsStockTxnDialogOpen] = useState(false);
  const [
    isStockConsumptionReturnDialogOpen,
    setIsStockConsumptionReturnDialogOpen,
  ] = useState(false);

  const [isDownloadReportsDialogOpen, setIsDownloadReportsDialogOpen] =
    useState(false);

  const stockConsumptionReturnHandler = () => {
    setIsStockConsumptionReturnDialogOpen(true);
  };

  const stockTxnHandler = () => {
    setIsStockTxnDialogOpen(true);
  };

  const downloadReportsClickHandler = () =>
    setIsDownloadReportsDialogOpen(true);

  // console.log(dispatchScheduleDropdown)
  return (
    <div className="" style={{ marginTop: "-1rem" }}>
      {(loading || isApplicationLoading || isDropdownLoading) && (
        <ALoader position="fixed" />
      )}
      {markEligiblePermissions && (
        <div className="p-3 student-filer-container-printId">
          <div className="d-flex align-items-center justify-content-between">
            <ListHeader text="Search students to mark eligible to issue stocks" />
            <AddToFavourite
              screenName={pages["digitalStockAndInventory"]["name"]}
              screenId={pages["digitalStockAndInventory"]["id"]}
            />
          </div>
          {/* <SelectFilterChip
          isHideSingle = {true}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        /> */}
          {/* <DlpSearchInput disabled={selectedFilter !== "SINGLE"} /> */}
          <FilterForm
            disabled={selectedFilter !== "BULK"}
            dispatchScheduleDropdown={dispatchScheduleDropdown}
          />
        </div>
      )}
      <ModuleCards
        stockTxnHandler={stockTxnHandler}
        stockConsumptionReturnHandler={stockConsumptionReturnHandler}
        downloadReportsClickHandler={downloadReportsClickHandler}
      />
      <BranchConsumptionReturnPopup
        open={isStockConsumptionReturnDialogOpen}
        setOpen={setIsStockConsumptionReturnDialogOpen}
        dropdown={dropdownDlp}
        forDlp={false}
        academicType="DIGITAL"
      />
      <StockTransactionsHistoryPopup
        open={isStockTxnDialogOpen}
        setOpen={setIsStockTxnDialogOpen}
        dropdown={{
          academicGroups: dropdown.academicGroup,
          businessAreas: dropdown.businessArea,
          courseIds: dropdown.courseId,
          terms: dropdown.term,
        }}
        academicType="DIGITAL"
      />

      <DigitalDownloadReportsDialog
        dropdown={dropdown}
        open={isDownloadReportsDialogOpen}
        setOpen={setIsDownloadReportsDialogOpen}
      />
    </div>
  );
};

export default ApplicationFilterLandingPage;
