import React ,{ useState ,useEffect} from "react";
import { Card, CardHeader, Input, Button, Table ,Row ,Col} from "reactstrap";
import Select2 from "react-select2-wrapper";
import { failureToast } from '../../../common/utils/methods/toasterFunctions/function'
import { TDROW, MasterHeaderCashier } from "../../../common/commonComponents/masterRow/masterRow";
import { masterServiceBaseUrl, successToast, putpost, putpost2 } from 'services/http'
import moment from 'moment';
import CustomDatePicker from '../../../common/commonComponents/CustomDatePicker/index';

import './index.css'

var _ = require('lodash');

const CashierOfficeTable = (props) => {
    const {
        setstate,
        tableData,
        pagination,
        setclassArray,
        setpagination,
        cashierOfficeData,
        action
    } = props;
    const [ tableDataKey, settableDataKey ] = useState( 'tableDataKey' );
    const [ isDisabled, setIsDisabled ] = useState( false );
    
    useEffect( () => {
        if ( action === 'edit' || action === 'view' ) {
            // console.log("action === 'edit'",action)
            setIsDisabled( true );
        }
        
         return()=>{
          !props.isPrevious && props.isNew  && props.saveStepsCompleted(6)
        }   
    }, [] )
    

    
    const addNewClass = () => {
        props.setclassArray([{ "editMode": true, "__status": "__new",'status': 'ACTIVE',"effectiveDate": null,}, ...props.classArray])
    }
    const saveAll = (cb) => {
        var _tempArray = props.classArray
        var isValid = true
        _tempArray.forEach((el, index) => {
            if (el['editMode']) {
                // console.log(el, 'el')
                if (el['cashierOfficeKey'] == null || !el['cashierOfficeKey'].trim()) {
                    isValid = false
                    failureToast('Please enter the value of Cashier Office Key')
                    return
                } else if (el['description'] == null || !el['description'].trim()) {
                    isValid = false
                    failureToast('Please enter the value of Description')
                    return
                }
                else if (el['currencyId'] == null || !el['currencyId'].toString().trim()) {
                    isValid = false
                    failureToast('Please select the value of Currency')
                    return
                }
                if (!isValid) {
                    return
                }
                if ( _tempArray[ index ][ '__status' ] == "__new" ) {
                    putpost(masterServiceBaseUrl + '/cashierOffice/createCashierOffice', (data) => {
                        successToast(data['message'])
                        props.setisSaveVisible(false)
                        props.fetchClasses(masterServiceBaseUrl + '/cashierOffice/getCashierOfficeByBusinessAreaId/'+ props.businessAreaId);
                    }, (data) => { failureToast(data['message']) }, _tempArray[index], 'post')
                } else {
                    putpost(masterServiceBaseUrl + '/cashierOffice/updateCashierOffice/' + _tempArray[index].id, (data) => {
                        successToast(data['message'])
                        props.setisSaveVisible(false)
                        delete _tempArray[index]['editMode']
                        props.setclassArray(_tempArray)
                        settableDataKey(new Date().getMilliseconds())
                    }, (data) => { failureToast(data['message']) }, _tempArray[index], 'put')
                }
            } else {

            }
        })
    }
    const newRow = () => {
        if (props.isSaveVisible) {
            failureToast("Please Delete or Save current changes")
        }
        else {
            let cb = () => {
                addNewClass()
                props.setisSaveVisible(true)
            }
            var _tempArray = props.classArray
            _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
            // console.log(_tempArray)
            props.setclassArray(_tempArray)
            props.setisSaveVisible(false)
            cb()
        }
    }
    const editRow = (index) => {
        if (props.isSaveVisible) {
            failureToast("Please Delete or Save current changes")
        }
        else {
            let cb = () => {
                var _tempArray = props.classArray
                _tempArray[index]['editMode'] = true
                props.setclassArray(_tempArray)
                props.setisSaveVisible(true)
            }
            var _tempArray = props.classArray
            _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
            cb()
        }
    }
    const deleteRow = (index) => {
        if (props.classArray[index]["__status"] == "__new") {
            let n = props.classArray
            n.splice(index, 1)
            props.setclassArray(n)
            props.setisSaveVisible(false)
            settableDataKey(new Date().getMilliseconds())
        } else {
            if (window.confirm('Are you sure you want to delete this Cashier Office?')) {
                putpost2(masterServiceBaseUrl + '/cashierOffice/deleteCashierOffice/' + props.classArray[index].id, (data) => {
                    successToast(data['message'])
                    if (props.classArray.length == 1) {
                        props.previousPage()
                        props.fetchClasses(masterServiceBaseUrl + '/cashierOffice/getCashierOfficeByBusinessAreaId/'+ props.businessAreaId);
                    } else {
                        var _tempArray = props.classArray
                        props.fetchClasses(masterServiceBaseUrl + '/cashierOffice/getCashierOfficeByBusinessAreaId/'+ props.businessAreaId);
                        if (_tempArray[index]['editMode']) {
                            props.setisSaveVisible(false)
                        }
                        _tempArray.splice(index, 1)
                        props.fetchClasses(masterServiceBaseUrl + '/cashierOffice/getCashierOfficeByBusinessAreaId/'+ props.businessAreaId);
                        props.setclassArray(_tempArray)
                    }
                    settableDataKey(new Date().getMilliseconds())
                }, (data) => { failureToast(data['message']) }, 'PUT')
            }
        }
    }
    const updateKey = (index, value, key) => {
        var _tempArray = props.classArray
        _tempArray[index][key] = value
        _tempArray[index]['businessAreaId'] = props.businessAreaId;
        // console.log(_tempArray, '_tempArray')
        props.setclassArray(_tempArray)
    }

    const searchPaginationHandler = (filterData, pageSize, n) => {
        if (filterData.length > pageSize) {
            n['nextPage'] = n.currentPage + 1
        }
        if (n.currentPage > 1) {
            n.previousPage = n.currentPage - 1
        }
        n['totalPage'] = Math.floor(filterData.length / pageSize)
        if (filterData.length % pageSize != 0) {
            n['totalPage'] += 1
        }
        setstate(filterData);
        setclassArray(filterData.slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || []);
        setpagination(n)
    }

    const filterHandler = _.debounce((cashierOfficeData) => {
        // console.log(props.currency,'props.currency')
        let n = pagination;
        let pageSize = 10;
        if (cashierOfficeData) {
            // console.log(cashierOfficeData, tableData, 'tableData')
            const filterData = tableData.filter(data => (
                data.cashierOfficeKey.toUpperCase().includes(cashierOfficeData.toUpperCase()) ||
                data.description.toUpperCase().includes(cashierOfficeData.toUpperCase()) ||
                data.currencyId.toString().toUpperCase()
                    .includes(props.currency.find(item => item.text.toUpperCase().includes(cashierOfficeData.toUpperCase())) && 
                    props.currency.find(item => item.text.toUpperCase().includes(cashierOfficeData.toUpperCase())).id.toString()) ||
                
                data.businessAreaId.toString().toUpperCase()
                    .includes(props.businessArea.find(item => item.text.toUpperCase().includes(cashierOfficeData.toUpperCase())) && 
                    props.businessArea.find(item => item.text.toUpperCase().includes(cashierOfficeData.toUpperCase())).id.toString()) ||
                data['updatedOn'].toUpperCase().includes(cashierOfficeData.toUpperCase()) ||
                data['status'].toUpperCase().includes(cashierOfficeData.toUpperCase())
            ));
            searchPaginationHandler(filterData, pageSize, n)
        } else {
            searchPaginationHandler(tableData, pageSize, n);
        }
    }, 300)

    return (
        <>
            <Card style={{ boxShadow: 'none' }} className='mb-0'>
                <CardHeader className="border-0">
                    { action === 'new' ? <h3 className="mb-0 floatLeft">Add Cashier Office</h3> : null }
                    <div
                        className="floatRight "
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        Search :{' '}
                        <Input
                            className="tdInput ml-3 mr-3 customInputWidth"
                            placeholder="Search"
                            onChange={(e) => {
                                let n = e.target.value.trim();
                                filterHandler(n);
                            }}
                        />
                        { !isDisabled ?
                            <Button
                            disabled={ props.isSaveVisible }
                            color="info"
                            size="sm"
                            type="button"
                            className={ "floatRight mx-1" + ( props.isSaveVisible ? " btn-dark" : null ) }
                            onClick={ () => { newRow() } }>
                            Add New Cashier Office                             
                        </Button> : null}
                    </div>
                </CardHeader>
                <div id="questionTable" className="table-responsive" style={{ marginBottom: '3.5rem' }}>
                    <Table className="align-items-center table-flush tableLayout" >
                        <MasterHeaderCashier
                            type={"Cashier Office"}
                            itemType='Cashier Office'
                            isDisabled={isDisabled}
                        />
                        <tbody className="list" key={tableDataKey} >
                            {props.classArray ? props.classArray.map((el, index) =>
                                !el['editMode'] ? (
                                    <TDROW  isDisabled={isDisabled} type={"cashierOffice"} itemType='cashierOffice' index={index} el={el} editRow={editRow} deleteRow={deleteRow} 
                                         currency = {props.currency} businessArea = {props.businessArea}/>
                                ) :
                                    (<tr key={index + '-class'}>
                                        <td className="text-center p-2 mx-1"><Input maxLength={10} defaultValue={el['cashierOfficeKey']}
                                            onChange={(e) => { updateKey(index, e.target.value, 'cashierOfficeKey') }} placeholder="Cashier Office Key" className="tdInput" />
                                        </td>
                                        <td className="text-center p-2 mx-1"><Input maxLength={50} defaultValue={el['description']}
                                            onChange={(e) => { updateKey(index, e.target.value, 'description') }} placeholder="Description" className="tdInput" />
                                        </td>
                                        <td className="text-center p-2 mx-1">
                                            <Select2 defaultValue={el['currencyId']} data={props.currency} onChange={(e) => { updateKey(index, Number(e.target.value), 'currencyId') }} options={{ placeholder: "Select currency" }} className="tdSelect" />
                                        </td>
                                        <td className="text-center p-2 mx-1">
                                        <CustomDatePicker 
                                            disabled={true}
                                            value={
                                                el["__status"] == "__new"
                                                ? null
                                                : moment(el["updatedOn"]).toDate()
                                            }
                                            placeholder="Example:01-01-2020"
                                        />
                                        {/* <Input disabled={true} defaultValue={el["__status"] == "__new" ? null : moment(el['updatedOn']).format('DD-MM-YYYY')} placeholder="Example:01-01-2020" className="tdInput" /> */}
                                        </td>
                                        <td className="text-center p-2 mx-1">
                                            <label className="custom-toggle mx-auto ml-2">
                                                <input
                                                    checked={el.status == "ACTIVE" ? true : false}
                                                    type="checkbox" id={el.id}
                                                    onChange={(e) => {
                                                        settableDataKey(new Date().getMilliseconds());
                                                        // console.log(e.target.checked);
                                                        let n = "ACTIVE"; if (!e.target.checked) { n = "INACTIVE" }
                                                        updateKey(index, n, 'status')
                                                    }}
                                                />
                                                <span style={{ width: "72px", margin: '0 auto' }} className="custom-toggle-slider rounded-circle activeToggle"
                                                    data-label-off="Inactive" data-label-on="Active" />
                                            </label>
                                        </td>
                                        <td className="text-center">
                                            {props.isSaveVisible ? <> <Button color="info" size="sm" type="button" className="mx-1" onClick={() => { saveAll() }}>Save</Button>
                                                <Button color="danger" size="sm" type="button" onClick={() => {
                                                    let n = props.classArray
                                                    if (n[index]['__status'] == '__new') {
                                                        n.splice(index, 1)
                                                    } else {
                                                        n[index]['editMode'] = false
                                                        props.fetchClasses(masterServiceBaseUrl + '/cashierOffice/getCashierOfficeByBusinessAreaId/'+ props.businessAreaId);
                                                    }
                                                    props.setclassArray(n)
                                                    props.setisSaveVisible(false)
                                                }}>  <i className="fas fa-times" /></Button> </> :
                                                <Button color="info" size="sm" type="button" onClick={() => {
                                                    deleteRow(index)
                                                }}>  <i className="fas fa-trash" /></Button>}
                                        </td>
                                    </tr>)
                            ) : null}
                        </tbody>
                    </Table>
                </div>
                { action === 'edit' ?
                    <Row>
                    <Col>
                        <Button
                            className={ 'floatRight ml-auto mr-4 mb-4' }
                            color="primary"
                            size="md"
                            type="button"
                            onClick={()=>setIsDisabled(!isDisabled)}
                        >
                            {isDisabled ?'Enable Form':'Disable Form'}
                        </Button>
                    </Col>
                </Row>:null}
            </Card>
        </>
    )
}
export default CashierOfficeTable;