import React, { useEffect, useState } from "react";
import AButton from "../../../../common/form-fields-mui/AButton";
import ViewDispatchPopup from "./ViewDispatchPopup";
import { getDispatchScheduleList } from "../../service";

const sortingFn = (v1, v2) =>
  (+v1?.dispatchId?.split("-")?.[1] || "-1") -
  (+v2?.dispatchId?.split("-")?.[1] || "-1");
const ViewDispatchSchedule = ({
  dispatchSchedular,
  filterForm,
  academicCareer,
}) => {
  const [openPopUp, setOpenPopUp] = useState(false);
  const [dispatchScheduleList, setDispatchScheduleList] = useState([]);

  useEffect(() => {
    if (openPopUp) fetchDispatchList();
  }, [openPopUp]);

  const fetchDispatchList = async () => {
    const { businessArea, term, courseId } = filterForm;
    const response = await getDispatchScheduleList({
      businessAreaId: businessArea,
      termId: [term],
      courseId,
      ...(academicCareer === "ICON" && { academicCareer }),
    });
    if (response?.code === 200) {
      setDispatchScheduleList(
        response?.data?.stockDispatchMasterList.sort(sortingFn)
      );
    }
  };
  return (
    <div>
      <AButton onClick={() => setOpenPopUp(true)} size="xs" variant="outline">
        View Dispatch Schedule
      </AButton>
      <ViewDispatchPopup
        handleClose={() => setOpenPopUp(false)}
        open={openPopUp}
        dispatchSchedular={dispatchSchedular}
        dispatchScheduleList={dispatchScheduleList}
      />
    </div>
  );
};

export default ViewDispatchSchedule;
