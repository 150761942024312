import React, { useState, useContext } from 'react';
import {
	Card,
	CardHeader,
	Input,
	Container,
	Button,
	Table,
	Row,
} from 'reactstrap';
import {
	successToast,
	failureToast,
} from '../../common/utils/methods/toasterFunctions/function';
import {
	TDROW,
	MasterHeader,
} from '../../common/commonComponents/masterRow/masterRow';
import { masterServiceBaseUrl, putpost, putpost2 } from 'services/http';
import moment from 'moment';
import IsCoreMaster from '../../common/commonComponents/coreMaster';
import CustomButton from 'components/CustomButton';

var _ = require('lodash');

const GroupCodeTable = (props) => {
	const { userPermissions, isSaveVisible, setisSaveVisible = () => { } } = props;
	const [tableDataKey, settableDataKey] = useState('tableDataKey');

	const addNewClass = () => {
		props.setclassArray([
			{
				editMode: true,
				__status: '__new',
				groupCodeKey: null,
				status: 'ACTIVE',
				groupCodeDesc: null,
				groupCodeDispValue: null,
				effectiveDate: null,
			},
			...props.classArray,
		]);
	};
	const saveAll = (cb) => {
		var _tempArray = props.classArray;
		var isValid = true;
		_tempArray.forEach((el, index) => {
			if (el['editMode']) {
				if (el['groupCodeKey'] == null || !el['groupCodeKey'].trim()) {
					isValid = false;
					failureToast('Please enter the value of Group Code Key');
					return;
				} else if (el['groupCodeDesc'] == null || !el['groupCodeDesc'].trim()) {
					isValid = false;
					failureToast('Please enter the value of Description');
					return;
				} else if (
					el['groupCodeDispValue'] == null ||
					!el['groupCodeDispValue'].trim()
				) {
					isValid = false;
					failureToast('Please enter the value of Display Value');
					return;
				}
				if (!isValid) {
					return;
				}
				if (_tempArray[index]['__status'] == '__new') {
					putpost(
						masterServiceBaseUrl + '/groupCode/createGroupCode',
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							props.fetchClasses(
								masterServiceBaseUrl + '/groupCode/getAllGroupCode'
							);
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'post'
					);
				} else {
					putpost(
						masterServiceBaseUrl +
						'/groupCode/updateGroupCode/' +
						_tempArray[index].id,
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							delete _tempArray[index]['editMode'];
							props.setclassArray(_tempArray);
							settableDataKey(new Date().getMilliseconds());
							// props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'put'
					);
				}
			} else {
			}
		});
	};
	const newRow = () => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				addNewClass();
				setisSaveVisible(true);
				// settableDataKey(new Date().getMilliseconds())
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			props.setclassArray(_tempArray);
			setisSaveVisible(false);
			cb();
		}
	};
	const editRow = (index) => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				var _tempArray = props.classArray;
				_tempArray[index]['editMode'] = true;
				props.setclassArray(_tempArray);
				setisSaveVisible(true);
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			cb();
		}
	};
	const deleteRow = (index) => {
		if (props.classArray[index]['__status'] == '__new') {
			let n = props.classArray;
			n.splice(index, 1);
			props.setclassArray(n);
			setisSaveVisible(false);
			settableDataKey(new Date().getMilliseconds());
		} else {
			if (window.confirm('Are you sure you want to delete this Group Code?')) {
				putpost2(
					masterServiceBaseUrl +
					'/groupCode/deleteGroupCode/' +
					props.classArray[index].id,
					(data) => {
						successToast(data['message']);
						if (props.classArray.length == 1) {
							props.previousPage();
							props.fetchClasses(
								masterServiceBaseUrl + '/groupCode/getAllGroupCode'
							);
						} else {
							var _tempArray = props.classArray;
							if (_tempArray[index]['editMode']) {
								setisSaveVisible(false);
							}
							_tempArray.splice(index, 1);
							props.setclassArray(_tempArray);
						}
						settableDataKey(new Date().getMilliseconds());
					},
					(data) => {
						failureToast(data['message']);
					},
					'PUT'
				);
			}
		}
	};
	const updateKey = (index, value, key) => {
		var _tempArray = props.classArray;
		_tempArray[index][key] = value;
		props.setclassArray(_tempArray);
	};
	return (
		<>
			<Container className="mt-3" fluid>
				<Row>
					<div className="col">
						<Card>
							<CardHeader className="border-0">
								<h3 className="mb-0 floatLeft">Master - Group Code</h3>
								<IsCoreMaster
									master={'groupCode'}
									masterDisplayValue={'Business Group'}
								/>
								<CustomButton
									disabled={isSaveVisible}
									className={
										'floatRight mx-1' +
										(isSaveVisible ? ' btn-dark cursor-gray' : null)
									}
									content={'New Group Code'}
									permissionType={'C'}
									icon={true}
									permissionSet={userPermissions}
									onClick={() => {
										newRow();
									}}
								/>
							</CardHeader>
							<div id="questionTable" className="table-responsive">
								<Table className="align-items-center table-flush tableLayout">
									<MasterHeader
										type={'Group Code'}
										isSaveVisible={isSaveVisible}
										permissionSet={userPermissions}
									/>
									<tbody className="list" key={tableDataKey}>
										{props.classArray
											? props.classArray.map((el, index) =>
												!el['editMode'] ? (
													<TDROW
														type={'groupCode'}
														index={index}
														el={el}
														editRow={editRow}
														deleteRow={deleteRow}
														isSaveVisible={isSaveVisible}
														permissionSet={userPermissions}
													/>
												) : (
													<tr key={index + '-class'}>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength={50}
																defaultValue={el['groupCodeKey']}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'groupCodeKey'
																	);
																}}
																placeholder="Example:1"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																disabled={true}
																defaultValue={
																	el['__status'] == '__new'
																		? null
																		: moment(el['effectiveDate']).format(
																			'DD-MM-YYYY'
																		)
																}
																placeholder="Example:01-01-2020"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength={50}
																defaultValue={el['groupCodeDesc']}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'groupCodeDesc'
																	);
																}}
																placeholder="Description"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength={50}
																defaultValue={el['groupCodeDispValue']}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'groupCodeDispValue'
																	);
																}}
																placeholder="Display Value"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<label className="custom-toggle mx-auto ml-2">
																<input
																	checked={
																		el.status == 'ACTIVE' ? true : false
																	}
																	type="checkbox"
																	id={el.id}
																	onChange={(e) => {
																		settableDataKey(
																			new Date().getMilliseconds()
																		);
																		let n = 'ACTIVE';
																		if (!e.target.checked) {
																			n = 'INACTIVE';
																		}
																		updateKey(index, n, 'status');
																	}}
																/>
																<span
																	style={{ width: '72px' }}
																	className="custom-toggle-slider rounded-circle activeToggle"
																	data-label-off="Inactive"
																	data-label-on="Active"
																/>
															</label>
														</td>
														<td className="text-center">
															{isSaveVisible ? (
																<>
																	<CustomButton
																		content={'Save'}
																		permissionType={'C,U'}
																		forTable={true}
																		permissionSet={userPermissions}
																		onClick={() => {
																			saveAll();
																		}}
																	/>
																	<CustomButton
																		permissionType={'cancel'}
																		icon={true}
																		forTable={true}
																		permissionSet={userPermissions}
																		onClick={() => {
																			let n = props.classArray;
																			if (n[index]['__status'] == '__new') {
																				n.splice(index, 1);
																			} else {
																				props.fetchClasses(
																					masterServiceBaseUrl +
																					'/groupCode/getAllGroupCode'
																				);
																			}
																			props.setclassArray(n);
																			setisSaveVisible(false);
																		}}
																	/>
																</>
															) : (
																<CustomButton
																	permissionType={'D'}
																	icon={true}
																	forTable={true}
																	permissionSet={userPermissions}
																	onClick={() => deleteRow(index)}
																/>
															)}
														</td>
													</tr>
												)
											)
											: null}
									</tbody>
								</Table>
							</div>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};
export default GroupCodeTable;
