import moment from "moment";
import React from "react";
const style = {
  height: "28px",
  padding: "4px, 11px, 4px, 11px",
  borderRadius: "8px",
  background: "#F5F5F5",
  textAlign: "center",
  fontSize: "14px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const ShowDispatchedChip = ({ data }) => {
  return (
    <div style={style} className="mt-4">
      DIspatch details:{" "}
      {moment(data?.updatedOn)?.format("DD-MMM-YYYY, hh:mm A")}
       <span className="ml-1"> by {data?.updatedBy}</span>
    </div>
  );
};

export default ShowDispatchedChip;
