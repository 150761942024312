import React, { useState, useEffect } from "react";
import { Box, Typography, Link } from "@mui/material";
import { Col, Row } from "reactstrap";
import moment from 'moment';
import { endpoint } from '../../common/constant';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function.js';
import { fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod.js';
import CustomButton from 'components/CustomButton';
import SearchInput from '../studentCharges/SearchInput';
import { ReactComponent as BackArrow } from 'assets/img/svg/BackArrow.svg'
import { ReactComponent as DocumentIcon } from 'assets/img/svg/bankDocument.svg';
import { ReactComponent as SearchIcon } from 'assets/img/svg/search-icon.svg';
import { TablePagination } from '@mui/material';

const HouseBankMaster = () => {
    const [currentPage,setCurrentPage] = useState(0);
    const [bankList, setBankList] = useState([]);
    const [isViewDetails, setIsViewDetails] = useState(false)
    const [currentBankList, setCurrentBankList] = useState()
    const [loading, setLoading] = useState(true);
    const [accountNumberFilter, setAccountNumberFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const getBankList = async () => {
        setLoading(true);
        try{
            const response = await fetchAllPromisedData(`${endpoint?.getHouseBankList}`, true);
            if (response.code === 200) {
                setBankList(response.data)
                setFilteredData(response.data)
            } else {
                failureToast("Failed to fetch Data");
                setLoading(false);
            }
        }
            catch (e) {
            console.log(e)
            failureToast("Failed to fetch Data");
            setLoading(false);
        }
    }
    useEffect(() => {
        getBankList();
    }, [])

    const itemCount = bankList.length;

    const getviewDetails = (accountId) => {
        setCurrentBankList(bankList.find(item => {
            return item.accountId === accountId
            }) 
        )
    }

    const handlePageChange = (e,p) => {
        setCurrentPage(+p)
    }
    
    const handleAccountNumberChange = (e) => {
        const filterValue = e.target.value;
        setAccountNumberFilter(filterValue)

        const filteredItems = bankList.filter((item) =>
          item.accountId.toLowerCase().includes(filterValue.toLowerCase().trim()) || 
          item.houseBank.toLowerCase().includes(filterValue.toLowerCase().trim())
        )
    
        setFilteredData(filterValue ? filteredItems :  bankList);
        setCurrentPage(0);
      } 

    return (
        <>
        <Box className='d-flex align-items-center depositSlip-header'>
                <Link href="/#/admin/bankAccount" underline="none"><BackArrow /></Link>
                <Typography variant="h6">House bank master</Typography>
            </Box>
    <Box className="hd-container">
    <Row>
        <Col md={5}>
            <Box className="hd-card-wrapper mb-20 backgound-h">
                <Box className="d-flex justify-content-between search align-items-center">
                <Typography variant="h6">Total {itemCount}</Typography>
                <Box class="search-container" style={{position:'relative'}}>
                    <input 
                        type="text" 
                        class="search-input" 
                        placeholder="Search By Bank Account ID/ Short Key" 
                        onChange={handleAccountNumberChange}
                        style={{paddingLeft:'8px',borderRadius:'4px', minWidth:'18rem'}}
                        value={accountNumberFilter}
                        />
                        <SearchIcon class="search-icon"/>
                    </Box>
                </Box>
               <Box>
                <Box style={{height:'604px'}}>
                <table className="mt-20 w-100">
                    <tr>
                        <th className="regular-bolder label-color"><span style={{lineHeight:'48px'}}>SAP bank short key</span> <hr className="hr-style"/></th>
                        <th className="regular-bolder label-color"><span style={{lineHeight:'48px'}}>SAP Bank account ID</span> <hr className="hr-style"/></th>
                        <th className="regular-bolder label-color" style={{textAlign:'center'}}><span style={{lineHeight:'48px'}}>Actions</span> <hr className="hr-style"/></th>
                    </tr>
                        {filteredData.slice( +currentPage * (10) , +currentPage * 10 + 10).map((item) => (
                          <tr>
                          <td className="regular-large label-color mb-20">{item.houseBank}</td>
                          <td className="regular-large label-color mb-20">{item.accountId}</td>
                          <td  className="mb-20" style={{textAlignLast:'center'}}><CustomButton
                              content={"View details"}
                              permissionType={'C'}
                              permissionSet={'C'}
                              onClick={() => { setIsViewDetails(true);getviewDetails(item.accountId) }}
                              className={'no-ml-btn lh-10 tiny-semi-bold mb-20'}
                          /></td>
                      </tr>
                    ))
                    }
                </table>
                </Box>
                <TablePagination
                    component="div"
                    count={filteredData?.length}
                    page={currentPage}
                    onPageChange={handlePageChange}
                    rowsPerPage={10}
                    sx={{
                        '& .MuiInputBase-root': {
                        display: 'none',
                        },
                        '& .MuiTablePagination-selectLabel': {
                            display: 'none'
                        }
                    }}
                />
               </Box>
            </Box>                
        </Col>
        <Col md={7}>
        {!isViewDetails && <Box className="hd-card-wrapper backgound-h mb-20">
            <Box className="block-center">
                <DocumentIcon style={{margin:'0 auto', width:'100%', marginBottom:'30px'}}/>
                <Typography variant="h6" className="large-text">Select bank to view details</Typography>
                <Typography variant="body2" className="text-center">Bank details will be visible here</Typography>
            </Box>
        </Box> }
        {isViewDetails && <Box className="hd-card-wrapper mb-20">   
             <Typography variant="h5" className="large-text color-black-60 mb-20" style={{fontWeight:'700', fontSize:'20px'}}>House bank details</Typography>
                <>
                <Box className="house-bank-container">
               <Row>
                <Col md={4}>
                    <Typography variant="body2">Company code</Typography>
                    <Typography variant="h6">{currentBankList?.companyCode}</Typography>
                </Col>
                <Col md={4}>
                    <Typography variant="body2">Bank short key</Typography>
                    <Typography variant="h6">{currentBankList?.houseBank}</Typography>
                </Col>
                <Col md={4}>
                    <Typography variant="body2">Bank account ID</Typography>
                    <Typography variant="h6">{currentBankList?.accountId}</Typography>
                </Col>
                  
               </Row>
               </Box> 
               <Box className="house-bank-container">
                <Typography variant="h6" style={{color:'#45464E', marginBottom:'15px'}}>Bank details</Typography>
               <Row>
                <Col md={4}>
                    <Typography variant="body2">Primary ID</Typography>
                    <Typography variant="h6">{currentBankList?.changeNumber}</Typography>
                </Col>
                <Col md={4}>
                    <Typography variant="body2">Status</Typography>
                    <Typography variant="h6">{currentBankList?.status}</Typography>
                </Col>
                <Col md={4}>
                    <Typography variant="body2">Bank IFSC ID</Typography>
                    <Typography variant="h6">{currentBankList?.bankNumber}</Typography>
                </Col>
                <Col md={4}>
                    <Typography variant="body2">Bank account #</Typography>
                    <Typography variant="h6">{currentBankList?.bankAccount}</Typography>
                </Col>
                <Col md={4}>
                    <Typography variant="body2">Description</Typography>
                    <Typography variant="h6">{currentBankList?.description}</Typography>
                </Col>
                <Col md={4}>
                    <Typography variant="body2">Account (DR.)</Typography>
                    <Typography variant="h6">{currentBankList?.gl1Account}</Typography>
                </Col>
                <Col md={4}>
                    <Typography variant="body2">Account (CR.)</Typography>
                    <Typography variant="h6">{currentBankList?.gl2Account}</Typography>
                </Col>
                <Col md={4}>
                    <Typography variant="body2">Country</Typography>
                    <Typography variant="h6">{currentBankList?.bankCountry}</Typography>
                </Col>
                <Col md={4}>
                    <Typography variant="body2">Currency code</Typography>
                    <Typography variant="h6">{currentBankList?.currency}</Typography>
                </Col>
                  
               </Row>
               </Box>
               <Box className="house-bank-container">
                <Typography variant="h6" style={{color:'#45464E',marginBottom:'15px'}}>Audit</Typography>

                <Row>
                <Col md={4}>
                    <Typography variant="body2">Date time created</Typography>
                    <Typography variant="h6">{currentBankList?.createdOn ? moment(currentBankList?.createdOn).format('MM/DD/YY h:mm A') : '-'}</Typography>
                </Col>
                <Col md={4}>
                    <Typography variant="body2">By</Typography>
                    <Typography variant="h6">{currentBankList?.createdBy}</Typography>
                </Col>
                </Row>
                <Row>
                <Col md={4}>
                    <Typography variant="body2">Last update date/time</Typography>
                    <Typography variant="h6">{currentBankList?.updatedOn ? moment(currentBankList?.updatedOn).format('MM/DD/YY h:mm A') : '-'}</Typography>
                </Col>
                <Col md={4}>
                    <Typography variant="body2">By</Typography>
                    <Typography variant="h6">{currentBankList?.updatedBy}</Typography>
                </Col>
                </Row>
               </Box>
               </>
             </Box>}
        </Col>
    </Row>
</Box>
</>
)}

export default HouseBankMaster;