import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import IssuanceTabItem from "./IssuanceTabItem";
import ReturnTabItem from "./ReturnTabItem";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import ALoader from "views/pages/manage/common/a-loader";
import MenuTabs from "./MenuTabs";
import { searchStockTransactions } from "../service";
import StockTransactionTable from "./StockTransactionTable";
import moment from "moment";
import RecordsCounter from "./RecordsCounter";
import { useGetBatchListV2 } from "../../issuanceIDcard/customhooks";
import { issuanceTypeMap, messageNoDataFound } from "./helper";
import { failureToast } from "../../../common/utils/methods/toasterFunctions/function";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx";
import ReversalTabItem from "./ReversalTabItem";
import { transactionTypeMap } from "../stockReturn/constant";

export const lastDays = (
  numberOfDays = 31,
  date = new Date().setHours(0, 0, 0, 0)
) => {
  return moment(date).subtract(numberOfDays, "days");
};

const StockTransactionsHistoryPopup = ({
  open,
  setOpen,
  dropdown,
  academicType,
}) => {
  const formFields = {
    businessArea: "",
    transactionId: "",
    psid: "",
    term: "",
    courseId: "",
    batchId: "",
    issuanceDateFrom: lastDays(),
    issuanceDateTo: moment(new Date()).endOf("day"),
    groupBy: "Transaction",
    type: transactionTypeMap.PSID_ISSUANCE[academicType],
  };

  const handleClose = () => {
    setOpen(false);
    setForm(formFields);
    setCheckedOption("txnId");
  };
  const [isTableDialogOpen, setIsTableDialogOpen] = useState(true);

  const [checkedOption, setCheckedOption] = useState("txnId"); // txnId, otherDetails
  const [form, setForm] = useState(formFields);
  const [listLoader, setListLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [selectedTab, setSelectedTab] = useState("Issuance");
  // there three have same flow
  const tabs = ["Issuance", "Branch consumption", "Branch consumption return"];

  const consumptionTabs = ["Branch consumption", "Branch consumption return"];

  const branchConsumptionTabs = [
    "Branch consumption",
    "Branch consumption return",
  ];

  const resetDetailsOnTabChange = () => {
    setForm(formFields);
    setRows([]);
    setPage(0);
    setRowCount(0);
  };

  useEffect(() => {
    resetDetailsOnTabChange();
  }, [checkedOption, selectedTab]);

  useEffect(() => {
    setCheckedOption("txnId");
  }, [selectedTab]);

  const [batchDropdown, isLoading] = useGetBatchListV2(
    form?.businessArea,
    form?.term,
    form?.courseId,
    academicType !== "RCC" ? "PRIMARY" : ""
  );

  const formHandler = (value, key) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const nextButtonHandler = async (page = 0) => {
    // fetchTxnList();
    fetchTxnList(page);
  };

  const viewDetailsHandler = async (request) => {
    fetchTxnList(0);
  };

  const fetchTxnList = async (pageNo) => {
    try {
      setListLoader(true);
      setPage(pageNo);
      const res = await searchStockTransactions(
        { ...form, type: issuanceTypeMap(academicType)?.[selectedTab] },
        pageNo
      );
      if (res?.data) {
        setRows(res?.data?.stockTransactionAudList);
        setRowCount(res?.data?.totalRecords);
        if (res?.data?.stockTransactionAudList.length === 0)
          failureToast(messageNoDataFound);
      }
      setListLoader(false);
    } catch (error) {
      setListLoader(false);
    }
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{ sx: { maxWidth: "unset", borderRadius: "12px" } }}
      >
        {listLoader && <ALoader />}
        <div style={{ padding: "28px 24px", width: "1069px" }}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="heading-4 color-black">Transaction history</div>
            <IconButtonWrapper onClick={handleClose}>
              <CrossIcon width={24} height={24} />
            </IconButtonWrapper>
          </div>

          <div className="d-flex mt-4" style={{ gap: "8px" }}>
            <MenuTabs
              selectedTab={selectedTab}
              onTabChangeHandler={(e, value) => setSelectedTab(value)}
              dropdown={dropdown}
              academicType={academicType}
            />
          </div>
          <div className="mt-4" style={{ gap: "8px" }}>
            {tabs.includes(selectedTab) && (
              <IssuanceTabItem
                dropdown={dropdown}
                viewDetailsHandler={viewDetailsHandler}
                form={form}
                formHandler={formHandler}
                checkedOption={checkedOption}
                setCheckedOption={setCheckedOption}
                batchDropdown={batchDropdown}
                selectedTab={selectedTab}
                isConsumptionReturn={branchConsumptionTabs.includes(
                  selectedTab
                )}
                academicType={academicType}
              />
            )}

            {selectedTab === "Return" && (
              <ReturnTabItem
                dropdown={dropdown}
                viewDetailsHandler={viewDetailsHandler}
                form={form}
                formHandler={formHandler}
                checkedOption={checkedOption}
                setCheckedOption={setCheckedOption}
                batchDropdown={batchDropdown}
                selectedTab={selectedTab}
                academicType={academicType}
              />
            )}
          </div>

          {tabs.includes(selectedTab) &&
            rows.length > 0 &&
            checkedOption === "otherDetails" && (
              <>
                <RecordsCounter count={rowCount} />
                <StockTransactionTable
                  rowData={rows}
                  rowCount={rowCount}
                  nextButtonHandler={nextButtonHandler}
                  selectedTab={selectedTab}
                  isConsumptionHistory={consumptionTabs.includes(selectedTab)}
                  academicType={academicType}
                />
              </>
            )}
          {selectedTab === "Reversal" && (
            <ReversalTabItem
              academicType={academicType}
              selectedTab={selectedTab}
              dropdown={dropdown}
              form={form}
              formHandler={formHandler}
              batchDropdown={batchDropdown}
              resetForm={resetDetailsOnTabChange}
            />
          )}
        </div>
      </Dialog>
    </>
  );
};

export default StockTransactionsHistoryPopup;
