import React, { useState } from "react";
import { ReactComponent as BackIcon } from "assets/img/svg/BackButton.svg";
import { chipStyle, headerStyle } from "../stockTxns/style";
import EmployeeDetailsCards from "./EmployeeDetailsCards";
import SelectMaterialCards from "./SelectMaterialCards";
import CustomChip from "views/pages/manage/common/customChip/CustomChip.jsx";
import SapPeriodAndSelectSapPostingDate from "../sapPeriodComponent/SapPeriodAndSelectSapPostingDate.jsx";
import { getFormattedDate } from "../../../common/utils/methods/commonMethods/utilityMethod";
import {
  ColoredChip,
  IssuedDetailsInfo,
} from "../selectMeterialAndStock/issueStock/TableColumn";
import { useParams } from "react-router";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx";
import StatusChipWithFailureReason from "../stockTxns/StatusChipWithFailureReason";
import { transactionTypeMap } from "../stockReturn/constant";

export const Header = ({
  showLeftBar = false,
  transactionDetails,
  isReturnedBarcode,
  sapPostingDate,
  setSapPostingDate,
  isShowSapPostingDate = false,
  failedReason,
}) => {
  const widthClass = isShowSapPostingDate ? "w-100" : "";
  return (
    <div
      className="d-flex heading-4 color-dark align-items-center justify-content-between"
      style={{ ...headerStyle, gap: "0px" }}
    >
      <div
        className={`d-flex justify-content-between ${
          widthClass ? widthClass : ""
        }`}
      >
        <div className="d-flex align-items-center">
          <IconButtonWrapper onClick={() => window.close()}>
            <BackIcon />
          </IconButtonWrapper>
          <div className="">Branch consumption details</div>
        </div>
        {isShowSapPostingDate && (
          <SapPeriodAndSelectSapPostingDate
            sapPostingDate={sapPostingDate}
            setSapPostingDate={(date) => setSapPostingDate(date)}
          />
        )}
      </div>

      {showLeftBar && (
        <div className="mr-2 d-flex">
          <CustomChip style={chipStyle} className="body-regular">
            {isReturnedBarcode ? "Return details " : "Issue details"}:{" "}
            {transactionDetails?.status === "FAILED"
              ? "--"
              : getFormattedDate(transactionDetails?.issueDate)}{" "}
            <IssuedDetailsInfo stock={transactionDetails} />
          </CustomChip>
          <StatusChipWithFailureReason
            transactionDetails={transactionDetails}
            failedReason={failedReason}
          />
        </div>
      )}
    </div>
  );
};

const ConsumptionReturnPage = ({
  data,
  returnStockHandlerFinal,
  isShowSapPostingDate,
}) => {
  const [selectedMaterials, setSelectedMaterials] = useState({});
  const [sapPostingDate, setSapPostingDate] = useState(undefined);
  const [remarks, setRemarks] = useState("");
  const { academicCareer } = useParams() || {};
  console.log(isShowSapPostingDate);
  const formHandler = (value, key) => {
    setSelectedMaterials((prev) => ({ ...prev, [key]: value }));
  };

  const returnStockHandler = () => {
    // validation

    const { materialDetails } = data;

    const { transactionType, costCenter, reason, plantId } =
      materialDetails && materialDetails[0] ? materialDetails[0] : {};
    returnStockHandlerFinal(Object.values(selectedMaterials).flat(), remarks, {
      transactionType,
      academicCareerDispValue: academicCareer,
      type: transactionTypeMap.BRANCH_RETURN[academicCareer],
      costCenter,
      reason,
      psid: data?.psid,
      plantId,
      cleanData: cleanData,
      sapPostingDate,
    });
  };

  const cleanData = () => {
    setSelectedMaterials({});
    setRemarks("");
    // window.location.reload(false);
  };

  return (
    <div>
      <Header
        sapPostingDate={sapPostingDate}
        setSapPostingDate={setSapPostingDate}
        isShowSapPostingDate={isShowSapPostingDate}
      />
      <EmployeeDetailsCards data={data} />
      <SelectMaterialCards
        form={selectedMaterials}
        formHandler={formHandler}
        data={data?.materialDetails || []}
        reasonForm={remarks}
        reasonFormHandler={(value) => setRemarks(value)}
        returnStockHandler={returnStockHandler}
        isAllReturn={data?.isAllStockReturnedForTxn}
      />
    </div>
  );
};

export default ConsumptionReturnPage;
