import React, { useEffect, useState } from "react";
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import "../style.scss";
import {
  FILTER__FORM_FIELDS_GLOBAL,
  FILTER__FORM_FIELDS,
  useGetDlpModuleContext,
  PAGE_STATE,
  DLP_DEFAULT_BU,
} from "../contextApi/dlpContext";
import { InputLabel } from "@mui/material";
import CustomDatePickerV2 from "../../../common/commonComponents/CustomDatePicker-V2";
import { InputLabelStyle } from "../../../common/form-fields-mui/ASelect";
import moment from "moment";
import { useGetDlpCourseIds } from "../customhooks";
import ALoader from "../../../common/a-loader";
const inputHeight = "42px";
const styleFlexItem = {
  flexGrow: 1,
};
const marginTop = { marginTop: "33px" };
const errorBorder = { border: "1px solid red", borderColor: "red" };

const FilterForm = ({ disabled, dispatchScheduleDropdown }) => {
  const {
    dropdown,
    filterForm,
    setFilterForm,
    getApplications,
    setPage,
    setCurrentPageState,
    setGlobalFilter,
  } = useGetDlpModuleContext();
  const [courseIdsDropdown, isDropdownFetching] =
    useGetDlpCourseIds(filterForm);

  useEffect(() => {
    setFilterForm({
      ...filterForm,
      businessArea:
        dropdown?.dlpActiveBusinessArea?.find(
          (item) => item?.label === DLP_DEFAULT_BU
        ) ?? "",
    });
  }, [dropdown]);

  const [isError, setIsError] = useState({
    businessArea: null,
    academicGroup: null,
    batchIds: null,
    courseId: null,
    term: null,
    scheduleDateFrom: null,
    scheduleDateTo: null,
    dispatchScheduleId: null,
  });

  const [isShowError, setShowError] = useState(false);

  const resetButtonHandler = () => {
    setFilterForm(FILTER__FORM_FIELDS);
  };

  const formHandler = (key, value) => {
    setFilterForm((prev) => ({ ...prev, [key]: value }));
    setShowError(false);
    setIsError((prev) => ({ ...prev, [key]: null }));
  };

  const callBack = () => {
    setCurrentPageState(PAGE_STATE.MARK_ELIGIBLE);
    setGlobalFilter(FILTER__FORM_FIELDS_GLOBAL);
    setPage(0);
  };

  const isErrorExist = (formFields) => {
    let isFindError = false;
    for (const key in formFields) {
      if (filterForm[key]?.length === 0) {
        formFields[key] = true;
        isFindError = true;
        setShowError(true);
      } else {
        formFields[key] = null;
      }
    }
    setIsError(formFields);
    return isFindError;
  };
  const nextButtonHandler = () => {
    if (isErrorExist({ ...isError })) return;
    getApplications(0, filterForm, callBack);
  };

  return (
    <div>
      <div className="student-search-filer-mark-eligible my-2">
        {isDropdownFetching && <ALoader position="fixed" />}
        <div>
          <AAutoComplete
            label="Business area*"
            height={inputHeight}
            placeholder="Business area"
            isMulti={false}
            items={dropdown?.dlpActiveBusinessArea}
            currentValue={
              dropdown?.businessAreas?.length === 1
                ? dropdown?.businessAreas[0]
                : filterForm?.businessArea
            }
            isDisabled={dropdown?.businessAreas?.length === 1 || disabled}
            handleChange={(value) => {
              formHandler("businessArea", value);
              formHandler("academicGroup", "");
              formHandler("term", "");
              formHandler("courseId", "");
              formHandler("scheduleDateFrom", "");
              formHandler("scheduleDateTo", "");
              formHandler("dispatchScheduleId", "");
            }}
            style={{ control: isError["businessArea"] ? errorBorder : {} }}
          />
        </div>
        <div>
          <AAutoComplete
            height={inputHeight}
            label="Academic group*"
            placeholder="Academic group"
            items={dropdown?.academicGroup}
            currentValue={filterForm?.academicGroup}
            isShowCount={true}
            handleChange={(value) => {
              formHandler("academicGroup", value);
              formHandler("term", "");
              formHandler("courseId", "");
              formHandler("scheduleDateFrom", "");
              formHandler("scheduleDateTo", "");
              formHandler("dispatchScheduleId", "");
            }}
            isDisabled={disabled}
            isMulti={false}
            style={{ control: isError["academicGroup"] ? errorBorder : {} }}
          />
        </div>
        <div>
          <AAutoComplete
            label="Term*"
            height={inputHeight}
            placeholder="Term"
            isMulti={false}
            items={dropdown?.term}
            currentValue={filterForm?.term}
            isShowCount={true}
            handleChange={(value) => {
              formHandler("term", value);
              formHandler("courseId", "");
              formHandler("scheduleDateFrom", "");
              formHandler("scheduleDateTo", "");
              formHandler("dispatchScheduleId", "");
            }}
            isDisabled={disabled}
            style={{ control: isError["term"] ? errorBorder : {} }}
          />
        </div>
        <div>
          <AAutoComplete
            height={inputHeight}
            label="Course ID*"
            placeholder="Course ID"
            isShowCount={true}
            items={courseIdsDropdown}
            currentValue={filterForm?.courseId}
            handleChange={(value) => {
              formHandler("courseId", value);
              formHandler("scheduleDateFrom", "");
              formHandler("scheduleDateTo", "");
              formHandler("dispatchScheduleId", "");
            }}
            isMulti={false}
            isDisabled={disabled}
            style={{ control: isError["courseId"] ? errorBorder : {} }}
          />
        </div>
        <div className="d-flex gap-md">
          <div className="" style={styleFlexItem}>
            <InputLabel sx={InputLabelStyle}>Dispatch date from*</InputLabel>
            <CustomDatePickerV2
              value={filterForm?.scheduleDateFrom}
              handler={(i, date) => {
                formHandler("scheduleDateFrom", date);
                formHandler("scheduleDateTo", "");
                formHandler("dispatchScheduleId", "");
              }}
              style={{
                input: isError["scheduleDateFrom"] ? errorBorder : {},
              }}
              maxDate={new Date()}
              disabled={disabled}
            />
          </div>
          <div className="" style={styleFlexItem}>
            <InputLabel sx={InputLabelStyle}>Dispatch date to*</InputLabel>
            <CustomDatePickerV2
              value={filterForm?.scheduleDateTo}
              handler={(i, date) => {
                formHandler("scheduleDateTo", moment(date).endOf("day"));
                formHandler("dispatchScheduleId", "");
              }}
              disabled={disabled}
              minDate={filterForm?.scheduleDateFrom}
              maxDate={new Date()}
              style={{ input: isError["scheduleDateTo"] ? errorBorder : {} }}
            />
          </div>
        </div>
        <div>
          <AAutoComplete
            label="Dispatch schedule*"
            height={inputHeight}
            isMulti={false}
            placeholder="Dispatch schedular"
            items={dispatchScheduleDropdown || []}
            currentValue={filterForm?.dispatchScheduleId || ""}
            isShowCount={true}
            handleChange={(value) => {
              formHandler("dispatchScheduleId", value);
            }}
            isDisabled={
              disabled ||
              !filterForm?.businessArea ||
              !filterForm?.academicGroup ||
              !filterForm?.term ||
              !filterForm?.courseId ||
              !filterForm?.scheduleDateFrom ||
              !filterForm?.scheduleDateTo
            }
            noOptionsMessage="Dispatch schedule not available for the selected combination."
            style={{
              control: isError["dispatchScheduleId"] ? errorBorder : {},
            }}
          />
        </div>
        <div style={marginTop} className="d-flex">
          <AButton
            className="button_remove_left_margin"
            updatedStyle={{ fontSize: "14px", flex: "1.75" }}
            onClick={nextButtonHandler}
            variant="primary_filled"
            disabled={disabled || filterForm?.dispatchScheduleId === ""}
          >
            Next
          </AButton>
          <AButton
            updatedStyle={{ fontSize: "14px", flex: "0.75" }}
            onClick={resetButtonHandler}
            variant="outline"
            disabled={disabled}
          >
            Reset
          </AButton>
        </div>
      </div>
      {isShowError && (
        <span className="error-psid">All fields are required*</span>
      )}
    </div>
  );
};

export default FilterForm;
