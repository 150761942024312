import React, { useState, useEffect, Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardHeader, Button, Form, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import { masterServiceBaseUrl, getAPI, putpost, enrollmentServiceBaseUrl, documentServiceBaseUrl } from "services/http";
import { getRequiredSign, fetchAllData } from "../../../common/utils/methods/commonMethods/utilityMethod";
import DocumentManagementTable from "./documentMangementTable";
import { PermissionContext, RolePermissions } from "appContext";
import { PermisionDenied } from "../../../common/commonComponents";
import { pages } from "../../../common/constant";
import CustomButton from "components/CustomButton";
import { ButtonText } from 'variables/Buttons';
import { failureToast, successToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';

const _ = require("lodash");

const DocumentManagementSearch = () => {

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["documentManagementSearch"]['id']);

  let history = useHistory();
  const [personType, setPersonType] = useState(null);
  const [documentType, setDocumentType] = useState([]);
  const [processType, setProcessType] = useState(null);
  const [selectedPersonType, setSelectedPersonType] = useState("");
  const [showPerson, setShowPerson] = useState("");
  const [documentArr, setDocumentArr] = useState([]);
  const [userSearchID, setUserSearchID] = useState([]);
  const [applicationID, setApplicationId] = useState([]);
  const [personTypeReset, setPersonTypeReset] = useState([]);
  const [processTypeReset, setProcessTypeReset] = useState([]);
  const [documentTypeReset, setDocumentTypeReset] = useState([]);
  const [userIDReset, setUserIDReset] = useState([]);
  const [applicationIDReset, setApplicationIDReset] = useState([]);
  const [mappedPersonData, setMappedPersonData] = useState(null);
  const [mappedProcessTypeData, setMappedProcessTypeData] = useState(null);

  const fetchDetails = async (url, type) => {
    try {
      getAPI(
        url,
        (data) => {
          indentifyDataHandler(data?.data, type);
        },
        (data) => {
          failureToast(data["message"]);
        }
      );
    } catch (e) {
      console.log("Error", e);
    }
  };

  const indentifyDataHandler = (data, type) => {
    try {
      switch (type) {
        case "PERSON_TYPE":
          dropdownDataHandler(data, "personTypeDispValue", setPersonType);
          break;
        case "DOCUMENT_TYPE":
          mapDocumentTypeData(data);
          break;
        case "PROCESS_TYPE":
          dropdownDataHandler(data, "tagTypeDispValue", setProcessType);
          break;
        case "STUDENT_ID":
          studentIDDropdownHandler(data);
          break;
        case "APPLICATION_ID":
          applicationIDDropDownHandler(data);
          break;
        default:
          break;
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const mapDocumentTypeData = (data) => {
    const temp = [];
    data &&
      data.map((item) => {
        if (!getData(temp, item.documentTypeId, "value")) {
          temp.push({ value: item.documentTypeId, label: item.documentTypeDispValue });
        }
      });
    setDocumentType(temp);
  };

  const studentIDDropdownHandler = (studentRecord) => {
    try {
      let temp = [];
      studentRecord.map((item) => {
        temp.push({
          value: item,
          label: item,
        });
      });
      setUserSearchID(temp);
    } catch (e) {
      console.log("Error", e);
    }
  };

  const applicationIDDropDownHandler = (applications) => {
    try {
      const temp = [];
      applications.map((item) => {
        temp.push({
          label: item["applicationId"],
          value: item["applicationId"],
        });
      });
      setApplicationId(temp);
    } catch (e) {
      console.log("Error", e);
    }
  };

  const applicationIDSearchHandler = (studentID) => {
    try {
      fetchDetails(`${enrollmentServiceBaseUrl}/studentApplication/getStudentApplicationByStudentId/${studentID}`, "APPLICATION_ID");
    } catch (e) {
      console.log("Error", e);
    }
  };

  const dropdownDataHandler = (dataArr, displayValue, stateMethod) => {
    try {
      const tempArr = [];
      dataArr &&
        dataArr.map((item) => {
          return tempArr.push({
            value: item["id"],
            label: item[displayValue],
          });
        });
      stateMethod(tempArr);
    } catch (e) {
      console.log("Error", e);
    }
  };

  //filter Data for Edit
  const getData = (list, id, strToCompare) => {
    const i =
      list &&
      list.filter((item) => {
        return item[strToCompare] === id;
      });
    return i ? i?.[0] : null;
  };

  const getMappedObject = (mappedList, originalList) => {
    const temp = [];
    mappedList &&
      mappedList.forEach((element) => {
        const isInList = getData(originalList, element, "value");
        if (isInList !== null && isInList !== undefined) {
          temp.push(isInList);
        }
      });
    return temp ?? [];
  };

  useEffect(() => {
    try {
      fetchDetails(`${masterServiceBaseUrl}/personType/getAllPersonType`, "PERSON_TYPE");
      fetchDetails(`${masterServiceBaseUrl}/processTag/getAllProcessTag`, "PROCESS_TYPE");
      fetchAllData(`${documentServiceBaseUrl}/DocumentProcessTag/getDistinctPersonType`, setMappedPersonData);
    } catch (e) {
      console.log("Error", e);
    }
  }, []);

  useEffect(() => {
    if (personTypeReset?.value && processTypeReset?.value) {
      fetchDetails(
        `${documentServiceBaseUrl}/documentType/getDocumentTypeByProcessTypeAndPersonType?processType=${processTypeReset?.value}&personType=${personTypeReset?.value}`,
        "DOCUMENT_TYPE"
      );
    }
  }, [personTypeReset?.value, processTypeReset?.value]);

  const searchStudentIDHandler = _.debounce((id) => {
    try {
      fetchDetails(`${enrollmentServiceBaseUrl}/documentUserTypeDetails/getStudentAndVendorBySmartSearch/${id}`, "STUDENT_ID");
    } catch (e) {
      console.log("Error", e);
    }
  }, 300);

  const searchHandler = () => {
    try {
      let searchFilterObj = {
        applicationId: applicationIDReset?.value,
        documentType: documentTypeReset?.value,
        documentUserId: userIDReset?.value,
        documentUserTypeId: personTypeReset?.value,
        processType: processTypeReset?.value,
      };
      if (!searchFilterObj?.documentUserTypeId) return failureToast("Please Select Person Type");
      if (!searchFilterObj?.documentUserId) return failureToast(`Please Select ${selectedPersonType} ID`);
      putpost(
        `${enrollmentServiceBaseUrl}/documentUserTypeDetails/searchDocumentUpload`,
        (data) => {
          setDocumentArr(data?.data);
          successToast(data["message"]);
        },
        (data) => {
          failureToast(data["message"]);
          setDocumentArr([]);
        },
        searchFilterObj,
        "post"
      );
    } catch (e) {
      console.log("Error :: ", e);
    }
  };

  const resetSearchHandler = () => {
    try {
      setPersonTypeReset("");
      setProcessTypeReset("");
      setDocumentTypeReset("");
      setShowPerson("");
      setUserIDReset("");
      setApplicationIDReset("");
      setApplicationId([]);
      setUserSearchID([]);
      setDocumentArr([]);
    } catch (e) {
      console.log("Error :: ", e);
    }
  };

  useEffect(() => {
    if (personTypeReset?.value) {
      fetchAllData(
        `${documentServiceBaseUrl}/DocumentProcessTag/getDistinctProcessType?personTypeId=${personTypeReset?.value}`,
        setMappedProcessTypeData
      );
    }
  }, [personTypeReset?.value]);

  return (
    userPermissions ?
      <Fragment>
        <Container className="mt-3" fluid={true}>
          <Form>
            <Row>
              <Col>
                <Card>
                  <CardHeader className="border-0">
                    <h3 className="mb-0 floatLeft">Document Upload Search</h3>
                    {/* <Button
                    color="info"
                    size="md"
                    type="button"
                    className="floatRight"
                    onClick={() => history.push("/admin/documentManagementSearch/new")}
                  >
                    Add New Document
                  </Button> */}
                    <CustomButton
                      className={'floatRight mx-1 '}
                      content={ButtonText.DOCUMENTMANAGEMENT.NEW}
                      permissionType={'C'}
                      icon={true}
                      onClick={() => history.push("/admin/documentManagementSearch/new")}
                      permissionSet={userPermissions}
                    />
                  </CardHeader>
                  <hr />
                  <CardBody>
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <Label className="form-control-label">Person Type {getRequiredSign()}</Label>
                          <Select
                            value={personTypeReset}
                            options={getMappedObject(mappedPersonData, personType)}
                            onChange={(e) => {
                              setPersonTypeReset(e);
                              setShowPerson(e.label);
                              setSelectedPersonType(e.label);
                              setMappedProcessTypeData(null);
                              setDocumentArr([]);
                            }}
                            isLoading={!personType || !mappedPersonData}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label className="form-control-label">Process Type</Label>
                          <Select
                            value={processTypeReset}
                            options={getMappedObject(mappedProcessTypeData, processType)}
                            onChange={(e) => {
                              setProcessTypeReset(e);
                            }}
                            isLoading={personTypeReset?.value && (!mappedProcessTypeData || !processType)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label className="form-control-label">Document Type</Label>
                          <Select
                            value={documentTypeReset}
                            options={documentType}
                            onChange={(e) => {
                              setDocumentTypeReset(e);
                            }}
                            isLoading={personTypeReset?.value && processTypeReset?.value && !documentType}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {showPerson?.toUpperCase() === "STUDENT" ? (
                        <Fragment>
                          <Col md={4}>
                            <FormGroup>
                              <Label className="form-control-label">Student ID{getRequiredSign()}</Label>
                              <Select
                                value={userIDReset}
                                options={userSearchID}
                                onKeyDown={(e) => {
                                  let studentID = e.target.value;
                                  studentID.length > 0 ? searchStudentIDHandler(studentID) : setUserSearchID([]);
                                }}
                                placeholder="Enter Student ID"
                                onChange={(e) => {
                                  setUserIDReset(e);
                                  setUserSearchID([]);
                                  applicationIDSearchHandler(e.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup>
                              <Label className="form-control-label">Application ID</Label>
                              <Select
                                value={applicationIDReset}
                                options={applicationID}
                                onChange={(e) => {
                                  setApplicationIDReset(e);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Fragment>
                      ) : null}

                      {showPerson?.toUpperCase() === "VENDOR" ? (
                        <Col md={4}>
                          <FormGroup>
                            <Label className="form-control-label">Vendor ID{getRequiredSign()}</Label>
                            <Select
                              value={userIDReset}
                              options={userSearchID}
                              onKeyDown={(e) => {
                                let studentID = e.target.value;
                                studentID.length > 0 ? searchStudentIDHandler(studentID) : setUserSearchID([]);
                              }}
                              onChange={(e) => {
                                setUserIDReset(e);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      ) : null}
                    </Row>
                    <Row>
                      <Col>
                        <div className="floatRight">
                          {/* <Button size="md" type="button" color="info" onClick={() => searchHandler()}>
                          {" "}
                          <i className="fas fa-search"></i> Search
                        </Button>
                        <Button
                          size="md"
                          type="button"
                          color="info"
                          onClick={() => {
                            resetSearchHandler();
                          }}
                        >
                          Reset
                        </Button> */}
                          <CustomButton
                            className={'mx-1'}
                            content={ButtonText.DOCUMENTMANAGEMENT.SEARCH}
                            permissionType={'S'}
                            icon={true}
                            onClick={searchHandler}
                            permissionSet={userPermissions}
                          />

                          <CustomButton
                            type="reset"
                            className={'mx-1 reset-button'}
                            content={ButtonText.DOCUMENTMANAGEMENT.RESET}
                            permissionType={'R'}
                            onClick={resetSearchHandler}
                            permissionSet={userPermissions}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
        {documentArr?.length > 0 ? <DocumentManagementTable documentArr={documentArr} selectedPersonType={selectedPersonType} userPermissions={userPermissions} /> : null}
      </Fragment> : <PermisionDenied />
  );
};

export default DocumentManagementSearch;
