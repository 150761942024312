import React from "react";
import { Row, Col } from "reactstrap";
import {
  CustomCard,
  CustomContainer,
} from "views/pages/manage/common/commonComponents";
import { GoArrowLeft } from "react-icons/go";



const RenderRow = ({ list, data, isFloor }) => {
  return (
    <div className="p-4">
      {list?.map((item) => {
        return (
          <Row className="pb-3"> 
            {item.map((key) => {
              return (
               <Col md={4}>
                  <Row>
                    <Col className="font-weight-bold" md="4" sm="2">{key}</Col> 
                    <Col md="1">:</Col>
                    <Col md="7" sm="8">{`${data[key]}`}</Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
        );
      })}
    </div>
  );
};

const Title = ({title,onBack=()=>{}}) => {
  return (
    <div className="d-flex align-items-end">
      <GoArrowLeft color="#11CDEF" size={40} onClick={onBack}/>
      <h3>{title}</h3>
    </div>
  )
}

const HeaderInfo = ({ data, isFloor=false, onBack=()=>{} }) => {

  const headerData = {
    ...data
  }
  
    if(isFloor){
        delete headerData['Floor Name'];
        delete headerData['Floor Code'];
        delete headerData['Floor Status'];
    }
    delete headerData['businessAreaId'];
    delete headerData['businessGroupId'];
    delete headerData['companyCodeId']; 
    delete headerData['regionId'];
    delete headerData['zoneId']

    const rows =
    headerData &&
    Object.keys(headerData).reduce(function (rows, key, index) {
      return (
        (index % 3 == 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) &&
        rows
      );
    }, []);


  return (
    <CustomContainer>
      <CustomCard  HeaderElement={<Title title="Building Information" onBack={onBack}/>}>
        <RenderRow list={rows} data={headerData} isFloor={isFloor}/>
      </CustomCard>
    </CustomContainer>
  );
};

export default HeaderInfo;
