import React from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Accordion } from "../../../common/commonComponents";
import CustomChip from "views/pages/manage/common/customChip/CustomChip";
import { parse2Digit } from "../../../unAssigned/otherCharges/otherChargeDetails/OtherChargeList";
import { numberWithCommas } from "./ConsolidatedFeeDetails";
import { getFormattedDate } from "../../../common/utils/methods/commonMethods/utilityMethod";

const statusChip = {
  COMPLETED: {
    text: "Completed",
    textColor: "#0B442D",
    bgColor: "#BBE2CE",
  },
  UPCOMING: {
    text: "Upcoming",
    textColor: "#262626",
    bgColor: "#F5F5F5",
  },

  OVERDUE: {
    text: "Over due",
    textColor: "#705010",
    bgColor: "#FFEDC8",
  },
};

const tableHeaderStyle = {
  display: "grid",
  gridTemplateColumns: "140px 110px 150px 1fr",
  gridGap: "12px",
  fontSize:"14px"
};

export const headerColumnBorder = {
  borderBottom: "1px solid #E3E3E3",
  paddingBottom: "5px",
};

const headerColumnStyle = {
  display: "grid",
  gridTemplateColumns: "137px 110px 104px 104px",
  gridGap: "10px",
};
const tableBorder = {
  border: "1px solid #E3E3E3",
  padding: "10px",
  borderRadius: "8px",
};

const Column = ({ children, style }) => {
  return (
    <div
      className="text-bold label-color"
      style={{ ...style, ...headerColumnBorder }}
    >
      {children}
    </div>
  );
};

const Row = ({ currentRow, openSummaryDialog }) => {
  return (
    <div className="global-text-size  mb-4 label-color" style={{...tableHeaderStyle}}>
      <div  >{currentRow.description}</div>
      <div>{getFormattedDate(currentRow.dueDate)}</div>
      <div>
        ₹ {numberWithCommas(parse2Digit(currentRow.outstandingAmount || 0))}
      </div>
      <div style={headerColumnStyle}>
        <div>{getFormattedDate(currentRow.dateOfPayment)}</div>
        <div>₹ {numberWithCommas(parse2Digit(currentRow.paidAmount || 0))}</div>
        <div>
          <ColoredChip value={currentRow.status} />
        </div>
        <div>
          <span
            className="cursor"
            onClick={() => openSummaryDialog(currentRow)}
          >
            <MdKeyboardArrowDown size={24} />
          </span>
        </div>
      </div>
    </div>
  );
};

export const ColoredChip = ({ value }) => {
  if (!value) return "";
  const statusChipColor = statusChip[value.toUpperCase()];
  return (
    <CustomChip
      bgColor={statusChipColor?.["bgColor"]}
      textColor={statusChipColor?.["textColor"]}
      text={statusChipColor?.["text"]}
    >
      {value}
    </CustomChip>
  );
};
const TableHeader = () => {
  return (
    <div className="mb-2" style={tableHeaderStyle}>
      <Column>Description</Column>
      <Column>Due date</Column>
      <Column>Outstanding amt.</Column>
      <Column style={headerColumnStyle}>
        <div>Date of payment</div>
        <div>Paid amount</div>
        <div>Status</div>
        <div>Action</div>
      </Column>
    </div>
  );
};

const StudentFeeSummary = ({ openSummaryDialog, paymentDetails }) => {
  return (
    <div>
      <Accordion title="Student fee summary" titleColor="#262626">
        <div className="mt-3" style={tableBorder}>
          <TableHeader />
          {paymentDetails?.feeSummaryDTOList?.map((row) => (
            <Row
              key={row.description}
              openSummaryDialog={openSummaryDialog}
              currentRow={row}
            ></Row>
          ))}
          {!paymentDetails?.feeSummaryDTOList && <span>No data found</span>}
        </div>
      </Accordion>
    </div>
  );
};

export default StudentFeeSummary;
