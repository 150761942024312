import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import AButton from '../../common/form-fields-mui/AButton';
import { getFilterCount } from '../stockManagement/mastersAndMapping/onBoardingMaterialIdSetup/constant';
import { initialFilterForm } from './constant';
import AAutoComplete from '../../common/form-fields-mui/AAutoComplete';
import { classDropdown, programActionDropdown } from '../constant/common';

const SearchFilterForm = ({
  closeForm,
  filterCount,
  filterPayload,
  applyFilter = () => {},
  dropdowns,
}) => {
  const columnSize = 12;
  const [filterForm, setFilterForm] = useState(filterPayload);
  const filterFormHandler = (value, key) =>
    setFilterForm(prev => ({ ...prev, [key]: value }));
  useEffect(() => {
    setFilterForm(filterPayload);
  }, [filterPayload]);

  return (
    <div>
      <Card style={{ width: '300px', marginBottom: '0px' }}>
        <CardBody style={{ paddingTop: '.5rem', paddingBottom: '.5rem' }}>
          <h4 style={{ color: 'black' }}> Filter </h4>
          <>
            <Row>
              <Col md={columnSize}>
                <AAutoComplete
                  label='Course ID'
                  currentValue={filterForm?.courseIds}
                  items={dropdowns?.courseId}
                  handleChange={value => filterFormHandler(value, 'courseIds')}
                  isShowCount={true}
                />
              </Col>
            </Row>
            <Row>
              <Col md={columnSize}>
                <AAutoComplete
                  label='Class'
                  currentValue={filterForm?.classes}
                  items={classDropdown}
                  handleChange={value => filterFormHandler(value, 'classes')}
                  isShowCount={true}
                />
              </Col>
            </Row>
            <Row>
              <Col md={columnSize}>
                <AAutoComplete
                  label='Program action'
                  currentValue={filterForm?.programAction}
                  items={programActionDropdown}
                  handleChange={value =>
                    filterFormHandler(value, 'programAction')
                  }
                  isShowCount={true}
                />
              </Col>
            </Row>
          </>

          <div className='mt-2 d-flex flex-row-reverse justify-content-between'>
            <AButton
              // variant='primary_filled'
              className='ml-0'
              onClick={() => {
                closeForm();
                applyFilter(filterForm);
              }}
              disabled={!getFilterCount(filterForm)}
              updatedStyle={{
                background: '#00B0F5',
                border: '1px solid #00B0F5',
                borderRadius: '8px',
                color: '#fff',
              }}
            >
              Apply Filter
            </AButton>
            {filterCount > 0 && (
              <AButton
                className='ml-0'
                onClick={() => {
                  closeForm();
                  applyFilter(initialFilterForm);
                }}
                updatedStyle={{
                  border: '1px solid #CC5F5F',
                  borderRadius: '8px',
                  color: '#CC5F5F',
                }}
              >
                Remove Filter
              </AButton>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default SearchFilterForm;
