import React from 'react'
import { useHistory } from 'react-router';

const DODetailsButton = ({ doExist, viewOnly, applicationId, applicationQuotationDetailsId, chargeStatus, pastIdSelected }) => {
  const history = useHistory();
  let isOldQuotation = pastIdSelected?"old":"new"

  const searchQuery = `?applicationId=${applicationId}&applicationQuotationDetailsId=${applicationQuotationDetailsId}&isOldQuotation=${isOldQuotation}`

  const disabled = viewOnly && !doExist;
  const clickHandler = () => {
    viewOnly && doExist && history.push(`/admin/doDetails${searchQuery}&readOnly=true`);
    !viewOnly && history.push(`/admin/doDetails${searchQuery}`);
  }

  return (
    <span
      onClick={clickHandler}
      className={disabled ? `cursor-not-allowed color-grey-50` : 'cursor color-dark'}
      style={{ padding: "10px 16px", border: "1px solid #CCCCCC", borderRadius: "4px", fontSize: '12px' }}
    >
      {viewOnly ? (
        doExist ? 'View DO details' : 'Add DO details'
      ) : (
        doExist ? chargeStatus==='APPL' ? 'View DO details' : 'View/edit DO details' : 'Add DO details'
      )}
    </span>
  )
}

export default DODetailsButton