import React, { useState, useEffect } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { useDispatch } from "react-redux";
import AButton from "../../common/form-fields-mui/AButton";

import { RightSideFilterForm } from "./RightSideFilterForm";
import APopover from "../../common/pop-hover/APophover";
import { PastDateFilter } from "./pastDateFilter";


// this is for filter button style
const highLightedStyle = {
  border: "1px solid #00B0F5",
  color: "#00B0F5",
  padding: "17px 16px"
};

const NormalStyle = {
  border: "1px solid #CCCCCC",
  padding: "17px 16px"
};

const Filter = ({
  filter,
  setFilter,
  applyFilter,
  removeFilter,
  appliedFilter,
  pastFilter,
  setPastFilter,
  isPastFilterApplied,
  applyPastFilter,
removePastFilter,
  isSaveVisible
}) => {

  const dispatch = useDispatch();
  const [anchorFilterEl, setAnchorFilterEl] = useState(null);
  const [anchorPastFilterEl, setAnchorPastFilterEl] = useState(null);
  const [showAllStudent, setShowAllStudent] = useState(false);


  const removerFilterWithClose = () => {
    removeFilter()
    // setAnchorFilterEl(null)
  }

  const applyFilterHandler = () => {
    applyFilter();
    setAnchorFilterEl(null)
  }



  const removerPastFilterWithClose = () => {
    removePastFilter()
    setAnchorPastFilterEl(null)
  }

  const applyPastFilterHandler = () => {
    applyPastFilter();
    setAnchorPastFilterEl(null)
  }

  return (
    <div style={{ display: "flex" }}>

      <AButton
        onClick={!isSaveVisible ? () => {} : (event) => {
          setAnchorPastFilterEl(event.currentTarget);
        }}
        updatedStyle={anchorPastFilterEl || appliedFilter > 0 ? {...highLightedStyle, cursor: !isSaveVisible ? 'not-allowed' : 'pointer'} : {...NormalStyle,cursor: !isSaveVisible ? 'not-allowed' : 'pointer'}}
      >

        <span style={{ fontSize: '12px', fontWeight: '600', color: '#0F0F0F' }}>Past roster</span>
        {isPastFilterApplied > 0 && <span style={{ width: 'max-content', paddingLeft: '5px' }}>( {isPastFilterApplied} )</span>}
        <MdOutlineKeyboardArrowDown className="ml-2 regular-bold" />
      </AButton>

      <AButton
        onClick={ !isSaveVisible ? () => {} : (event) => {
          setAnchorFilterEl(event.currentTarget);
        }}
        updatedStyle={anchorFilterEl || appliedFilter > 0 ? {...highLightedStyle, cursor: !isSaveVisible ? 'not-allowed' : 'pointer'} : {...NormalStyle,cursor: !isSaveVisible ? 'not-allowed' : 'pointer'}}
      >

        <span style={{ fontSize: '12px', fontWeight: '600', color: '#0F0F0F' }}>Filter</span>
        {appliedFilter > 0 && <span style={{ width: 'max-content', paddingLeft: '5px' }}>( {appliedFilter} )</span>}
        <MdOutlineKeyboardArrowDown className="ml-2 regular-bold" />
      </AButton>

      <APopover
        handleClose={() => setAnchorFilterEl(null)}
        id="info-batch-filter-1"
        anchorEl={anchorFilterEl}
      >
        <RightSideFilterForm
          formModal={filter}
          setFilter={setFilter}
          applyFilterHandler={applyFilterHandler}
          removeFilterHandler={removerFilterWithClose}
          isShowRemoveFilter={!!appliedFilter} />
      </APopover>

      <APopover
        handleClose={() => setAnchorPastFilterEl(null)}
        id="info-batch-filter-1"
        anchorEl={anchorPastFilterEl}
      >
        <PastDateFilter
          pastFilter={pastFilter}
          setPastFilter={setPastFilter}
          applyFilterHandler={applyPastFilterHandler}
          removeFilterHandler={removerPastFilterWithClose}
          isShowRemoveFilter={!!isPastFilterApplied} />
      </APopover>
    </div>
  );
};

export default Filter;