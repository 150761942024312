import React, { useEffect, useState } from 'react';
import {
	masterServiceBaseUrl,
	getAPI,
	putpost,
} from 'services/http';
import { failureToast, successToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import CustomButton from 'components/CustomButton';
import { Card, CardBody, FormGroup, Input, Row, Col, Button } from 'reactstrap';
import moment from 'moment';
import CommonInput from '../../../../../common/formFeilds/input/commonInput';
import './index.css';
import Tooltip from 'components/Tooltip';
import CustomLoader from '../../../../../common/commonComponents/Loader/loader';

export const QuestionSVG = () => (
	<svg version="1.1" viewBox="0 0 512 512" width="16px" height="16px">
		<path
			fill="currentColor"
			d={`M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z`}
		/>
	</svg>
);
// const InputValidationRegEx = /^[^\s].+[a-zA-Z0-9_:;\\\\/"-]+$/;
const nameInputValidationRegEx = /^[^\s].+[a-zA-Z0-9-_:;\\/"& ]+$/;
const descInputValidationRegEx = /^[^\s].+[a-zA-Z0-9-_:;\\/"&() ]+$/;

const FirstFormPricingZone = (props) => {
	const {
		nextStep = () => {},
		isNew = true,
		formId,
		isViewOnly = false,
		userPermissions
	} = props;

	const [companyCodeData, setCompanyCodeData] = useState([]);
	const [companyCodeDataAll, setCompanyCodeDataAll] = useState([]);
	const [idNo, setIdNo] = useState('');
	const [businessData, setBusinessData] = useState([]);
	const [apiloader, setApiLoader] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [isActive, setActive] = useState(true);
	const [companyCodeOnGroupCode, setCompanyCodeOnGroupCode] = useState([]);

	const status = [
		{ value: 'ACTIVE', label: 'ACTIVE' },
		{ value: 'INACTIVE', label: 'INACTIVE' },
	];

	const [value, setValues] = useState({
		businessgrp: '',
		comapanyCode: '',
		pricingZoneName: '',
		pricingZoneDesc: '',
		status: 'ACTIVE',
		createdDate: moment().format('DD-MM-YYYY'),
	});

	const fetchPricingZoneData = () => {
		setApiLoader(true);
		getAPI(
			`${masterServiceBaseUrl}/pricingZone/getPricingZone/${formId}`,
			(data) => {
				setValues({
					...value,
					businessgrp: getSelectedObj(data.data.groupCodeId, businessData),
					pricingZoneName: data.data.pricingZoneName,
					pricingZoneDesc: data.data.pricingZoneDesc,
					status: data.data.status,
					createdDate: moment(data.data.createdOn).format('DD-MM-YYYY'),
					comapanyCode: getSelectedObj(
						data.data.companyCodeId,
						companyCodeData
					),
				});
				setIsDisabled(true);
				props.setBusinessGroupCode(
					getSelectedObj(data.data.groupCodeId, businessData).value
				);
				props.setCompanyCode(
					getSelectedObj(data.data.companyCodeId, companyCodeData).value
				);
				setApiLoader(false);
			},
			(data) => {
				failureToast(data['message']);
				setApiLoader(false);
			}
		);
	};

	const getSelectedObj = (id, arr) => {
		return arr.filter((item) => {
			return item.value === id;
		})[0];
	};

	React.useEffect(() => {
		if (!isNew && businessData.length && companyCodeData.length) {
			fetchPricingZoneData();
		}
	}, [isNew, businessData, companyCodeData]);

	const fetchData = (url, stateMethod, name) => {
		setApiLoader(true);
		getAPI(
			url,
			(data) => {
				let list = [];
				//this is for dropdown fields only
				let keyName = `${name}DispValue`;
				list = data.data.map((data) => {
					return {
						value: data.id,
						label: data[keyName],
					};
				});
				stateMethod(list);
				name === 'companyCode' && setCompanyCodeDataAll(data.data);
				setApiLoader(false);
			},
			(data) => {
				failureToast(data['message']);
				setApiLoader(false);
			}
		);
	};

	useEffect(() => {
		fetchData(
			masterServiceBaseUrl + '/groupCode/getAllActiveGroupCode',
			setBusinessData,
			'groupCode'
		);
		fetchData(
			masterServiceBaseUrl + '/companyCode/getAllActiveCompanyCode',
			setCompanyCodeData,
			'companyCode'
		);
	}, []);

	const saveHandler = () => {
		if (value.businessgrp === '') {
			failureToast('Please Select Business Group');
			return;
		} else if (value.comapanyCode === '') {
			failureToast('Please Select Company Code');
			return;
		} else if (value.pricingZoneName.trim() === '') {
			failureToast('Please Enter the value of Pricing zone name');
			return;
		} else if (value.pricingZoneDesc.trim() === '') {
			failureToast('Please Enter the value of Pricing zone Description');
			return;
		}
		if (!nameInputValidationRegEx.test(value.pricingZoneName.trim())) {
			failureToast('Please Enter a valid value of Pricing zone name');
			return;
		}
		if (!descInputValidationRegEx.test(value.pricingZoneDesc.trim())) {
			failureToast('Please Enter a valid value of Pricing zone description');
			return;
		}
		setApiLoader(true);

		const obj = {
			groupCodeId: value.businessgrp.value,
			pricingZoneName: value.pricingZoneName.replace(/\s+/g, ' ').trim(),
			companyCodeId: value.comapanyCode.value,
			pricingZoneDesc: value.pricingZoneDesc.replace(/\s+/g, ' ').trim(),
			status: value.status,
		};

		if (isNew) {
			putpost(
				`${masterServiceBaseUrl}/pricingZone/createPricingZone`,
				(data) => {
					successToast(data['message']);
					setValues({
						businessgrp: '',
						courseAttribute: '',
						comapanyCode: '',
						pricingZoneName: '',
						pricingZoneDesc: '',
						status: 'ACTIVE',
					});
					setIdNo(data.data['id']);
					nextStep(data.data.id);
					setApiLoader(false);
				},
				(data) => {
					failureToast(data['message']);
					setApiLoader(false);
				},
				obj,
				'post'
			);
		}

		if (!isNew) {
			putpost(
				`${masterServiceBaseUrl}/pricingZone/updatePricingZone/${formId}`,
				(data) => {
					successToast(data['message']);
					fetchPricingZoneData();
				},
				(data) => {
					failureToast(data['message']);
					setApiLoader(false);
				},
				obj,
				'put'
			);
		}
	};

	const discard = () => {
		fetchPricingZoneData();
		setIsDisabled(true);
		setActive(true);
	};

	const getCompanyCodeOnGroupCode = (GroupCodeId) => {
		let CompanyCode = companyCodeDataAll.filter(
			(item) => item.groupCodeId === GroupCodeId
		);
		CompanyCode = CompanyCode.map((data) => {
			return {
				value: data.id,
				label: data.companyCodeDispValue,
			};
		});
		setCompanyCodeOnGroupCode(CompanyCode);
	};

	const tooltipText = {
		pricingZoneName:
			'Only AlphaNumeric and -_:;/"& special characters are allowed.',
		pricingZoneDescription:
			'Only AlphaNumeric and -_:;/"&() special characters are allowed.',
	};
	

	return (
		<Card className="mb-4">
			<CardBody>
				{apiloader ? (
					<CustomLoader
						apiLoader={apiloader}
						loaderHeight={'200px'}
						loaderWidth={'100% '}
					/>
				) : (
					<>
						<Row>
							<Col md="6">
								<CommonInput
									mandatory={true}
									label={'Business Group'}
									placeHolder={'Business Group'}
									type={'select'}
									data={businessData}
									isDisabled={isDisabled || !isActive}
									defaultValue={value.businessgrp}
									onChange={(val) => {
										setValues({
											...value,
											businessgrp: val,
											comapanyCode: '',
										});
										getCompanyCodeOnGroupCode(val.value);
									}}
								/>
							</Col>
							<Col md="6">
								<CommonInput
									mandatory={true}
									label={'Company Code'}
									placeHolder={'Company Code'}
									type={'select'}
									data={companyCodeOnGroupCode}
									isDisabled={
										isDisabled || !isActive || value.businessgrp === ''
									}
									defaultValue={value.comapanyCode}
									onChange={(val) => {
										setValues({ ...value, comapanyCode: val });
										props.setCompanyCode(val.value);
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col md="6">
								<CommonInput
									mandatory={true}
									type={'text'}
									defaultValue={value.pricingZoneName}
									label={'Pricing Zone Name'}
									placeHolder={'Pricing Zone Name'}
									tooltip={true}
									tooltipContent={tooltipText.pricingZoneName}
									maxLength={50}
									allowedSomeSpecialCharacters={true}
									restrictSpecialCharacter={true}
									regEx={/[^a-zA-Z0-9-_:;\\/"& ]/g}
									lengthNotifier={true}
									isDisabled={
										isDisabled || !isActive || value.comapanyCode === ''
									}
									onChange={(e) => setValues({ ...value, pricingZoneName: e })}
								/>
							</Col>
							<Col md="6">
								<CommonInput
									mandatory={true}
									type={'text'}
									defaultValue={value.pricingZoneDesc}
									label={'Pricing Zone Description'}
									placeHolder={'Pricing Zone Description'}
									tooltip={true}
									tooltipContent={tooltipText.pricingZoneDescription}
									maxLength={100}
									allowedSomeSpecialCharacters={true}
									restrictSpecialCharacter={true}
									regEx={/[^a-zA-Z0-9-_:;\\/"&() ]/g}
									lengthNotifier={true}
									isDisabled={isDisabled || value.pricingZoneName === ''}
									onChange={(e) => setValues({ ...value, pricingZoneDesc: e })}
								/>
							</Col>
							<Col md="6">
								<CommonInput
									mandatory={true}
									label={'Status'}
									placeHolder={'Status'}
									type={'select'}
									data={status}
									isDisabled={isDisabled}
									defaultValue={{
										value: value['status'],
										label: value['status'],
									}}
									onChange={(e) => {
										setValues({ ...value, status: e.value });
									}}
								/>
							</Col>
							<Col md="6">
								<FormGroup>
									<label
										className="form-control-label"
										htmlFor="example4cols2Input"
									>
										Effective Date
									</label>
									<Input
										style={{ height: '40px' }}
										value={value.createdDate}
										name="atrributeDesc"
										placeholder=""
										id="example4cols2Input"
										disabled={true}
										data-testid="createDate"
									/>
								</FormGroup>
							</Col>
						</Row>
						{isNew ? (
							<Row className={`d-flex pr-3 justify-content-end`}>
								{/* <Button
									color="info"
									size="sm"
									className={`ml-auto`}
									onClick={saveHandler}
									data-testid="saveAndNext"
								>
									Save and Next
								</Button> */}
									<CustomButton
										className={`ml-auto`}
										data-testid="saveAndNext"
										content={'Save and Next'}
										permissionType={'C,U'}
										// forTable={true}
										permissionSet={userPermissions}
										onClick={saveHandler}
									/>
								</Row>
						) : !isNew && !isDisabled ? (
							<>
								<Row className={`d-flex pr-3 justify-content-end`}>
									{/* <Button
										color="info"
										size="sm"
										className={`mr-3 `}
										onClick={saveHandler}
									>
										Save
									</Button> */}
									<CustomButton
										className={`mr-3 `}
										content={'Save'}
										permissionType={'C,U'}
										// forTable={true}
										permissionSet={userPermissions}
										onClick={() => {saveHandler()}}
									/>
									{/* <Button
										color="warning"
										size="sm"
										className={``}
										onClick={discard}
									>
										Cancel
									</Button> */}
									<CustomButton
										content={'Cancel'}
										permissionType={'cancel'}
										// forTable={true}
										permissionSet={userPermissions}
										onClick={() => {discard()}}
									/>
								</Row>
							</>
						) : !isViewOnly ? (
							<Row className={`d-flex pr-3 justify-content-end`}>
								{/* <Button
									color="info"
									size="sm"
									className={`ml-auto`}
									onClick={() => {
										setIsDisabled(false);
										setActive(false);
									}}
								>
									Edit
								</Button> */}
									
									<CustomButton
										className={`ml-auto`}
										content={'Edit'}
										permissionType={'U'}
										// forTable={true}
										permissionSet={userPermissions}
										onClick={() => {
											setIsDisabled(false);
											setActive(false);
										}}
									/>
							</Row>
						) : null}
					</>
				)}
			</CardBody>
		</Card>
	);
};

export default FirstFormPricingZone;
