import React, { useEffect, useState } from 'react';
import { Box, Dialog, Modal, Slide, Typography } from '@mui/material';
import { Col, Container, Row } from 'reactstrap'
import CustomButton from '../../../../components/CustomButton/index.js';
import document from '../../../../assets/Document.png'
import { BasicDetails } from './basicDetails.js';
import { DocumentUpload } from './documentUpload.js';
import { ApprovalSetting } from './approvalSetting.js';
import { endpoint } from '../common/constant/index.js';
import { dropdownMapping } from '../unAssigned/studentCharges/common.js';
import closeIcon from "../../../../assets/CloseSquare.png"
import infoIcon from "../../../../assets/concessionInfo.png"
import downarrow from "../../../../assets/downarrow.png"
import { fetchAllPostPromisedData, fetchAllPromisedData, fetchDeletePromisedData } from '../common/utils/methods/commonMethods/utilityMethod.js';
import CustomLoader from '../common/commonComponents/Loader/loader.js';
import "./index.scss"
import { DetailCard } from './detailCard';
import { failureToast, successToast } from '../common/utils/methods/toasterFunctions/function.js';
import { putpost } from 'services/http.js';
import { ConfirmationDeleteDialog } from '../common/commonComponents/index.js';
import Filter from './Filter.jsx';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const ClosedStep = ({ title, setStep }) => {
    return <div className='closed-step justify-content-between'>
        <div>{title}</div>
        <div className='cursor' onClick={setStep}><img height={6} src={downarrow} /></div>
    </div>
}

const ConcessionWorkflow = () => {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);

    const [workflowData, setWorkflowData] = useState([])
    const [step, setStep] = useState(0);
    const [values, setValues] = useState({
        "region": [],
        "businessAreas": [],
        "courseCategories": [],
        "l1ApprovalPercentage": '',
        "l1ApprovalRoles": [],
        "l2ApprovalPercentage": '',
        "l2ApprovalRoles": [],
        "l3ApprovalPercentage": '',
        "l3ApprovalRoles": [],
        "documentUploadPercentCriteria": '',
        "status": "ACTIVE"
    })

    const [filter, setFilter] = useState({
        "businessAreas": [],
        "courseCategories": [],
        "roles": []
    })

    const [regionData, setRegionData] = useState({ fetching: true, data: [] })
    const [businessArea, setBusinessAreaData] = useState({ fetching: true, data: [] })
    const [courseCategoryData, setCourseCategoryData] = useState({ fetching: true, data: [] })
    const [roleData, setRoleData] = useState({ fetching: true, data: [] })
    const [fetching, setFetching] = useState(false);
    const [saving, setSaving] = useState(false);
    const [deleteing, setDeleteting] = useState();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedConfig, setSelectedConfig] = useState(null);

    const getAllInitailList = async () => {
        const p = await Promise.all([
            dropdownMapping(endpoint?.region.getAllActive, 'regionDispValue'),
            dropdownMapping(endpoint?.businessArea.getAllCached, 'businessAreaDispValue'),
            dropdownMapping(`${endpoint?.courseCategory.getAllActiveCourseCategory}`, 'courseAttributeValueDispValue'),
            dropdownMapping(`${endpoint?.getRole.roleWithApprovalPermissions}`, 'roleName'),
        ])

        setRegionData({ fetching: false, data: p[0] });
        setBusinessAreaData({ fetching: false, data: p[1] });
        setCourseCategoryData({ fetching: false, data: p[2] });
        setRoleData({ fetching: false, data: p[3] })
    }

    const getAllRecords = async () => {
        setFetching(true)
        const response = await fetchAllPromisedData(endpoint.concessionConfigConc.getAll, true);
        if (response?.code === 200) {
            setWorkflowData(response.data);
            setFetching(false);
        } else {
            setWorkflowData([]);
            setFetching(false);
        }
    }

    const getAllFilteredRecords = async () => {
        setFetching(true)
        const request = {
            "businessAreas": filter.businessAreas.map(i => i.value),
            "courseCategories": filter.courseCategories.map(i => i.value),
            "roles": filter.roles.map(i => i.value),
        }
        const response = await fetchAllPostPromisedData(endpoint.concessionConfig.search, request, 'post');
        if (response?.code === 200) {
            setWorkflowData(response.data);
            setFetching(false);
        } else {
            setWorkflowData([]);
            setFetching(false);
        }
    }

    useEffect(() => {
        getAllInitailList();
        getAllRecords();
    }, [])

    const handleEditData = (data) => {
        setValues({ ...data })
        setOpen(true)
    }


    const reset = () => {
        setValues({
            "region": [],
            "businessAreas": [],
            "courseCategories": [],
            "l1ApprovalPercentage": '',
            "l1ApprovalRoles": [],
            "l2ApprovalPercentage": '',
            "l2ApprovalRoles": [],
            "l3ApprovalPercentage": '',
            "l3ApprovalRoles": [],
            "documentUploadPercentCriteria": '',
            "status": "ACTIVE"
        })
        handleClose();
        getAllRecords();
        setStep(0)
    }


    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") 
        return;
        setOpen(false);
        setValues({
            "region": [],
            "businessAreas": [],
            "courseCategories": [],
            "l1ApprovalPercentage": '',
            "l1ApprovalRoles": [],
            "l2ApprovalPercentage": '',
            "l2ApprovalRoles": [],
            "l3ApprovalPercentage": '',
            "l3ApprovalRoles": [],
            "documentUploadPercentCriteria": '',
            "status": "ACTIVE"
        })
        setStep(0)
    }

    const handleNextStep = async () => {

        if (step === 0) {
            if (!values.region?.length) {
                failureToast("Please select region")
                return
            }

            if (!values.businessAreas?.length) {
                failureToast("Please select business area")
                return
            }

            if (!values.courseCategories?.length) {
                failureToast("Please select course category")
                return
            }

            setStep(step + 1);
        }

        if (step === 1) {
            if (!values.l1ApprovalPercentage) {
                failureToast("Please enter L1 approval percentage")
                return
            }

            if (!values.l1ApprovalRoles?.length) {
                failureToast("Please select L1 approval roles")
                return
            }

            if (!values.l2ApprovalPercentage) {
                failureToast("Please enter L2 approval percentage")
                return
            }


            if (values.l2ApprovalPercentage && (Number(values.l1ApprovalPercentage) + 0.09 > Number(values.l2ApprovalPercentage))) {
                failureToast("L2 approval percentage must be 0.1 more than L1 approval percentage")
                return
            }

            if (!values.l2ApprovalRoles?.length) {
                failureToast("Please select L2 approval roles")
                return
            }

            if (!values.l3ApprovalPercentage) {
                failureToast("Please enter L3 approval percentage")
                return
            }

            if (values.l3ApprovalPercentage && (Number(values.l2ApprovalPercentage) + 0.09 > Number(values.l3ApprovalPercentage))) {
                failureToast("L3 approval percentage must be 0.1 more than L2 approval percentage")
                return
            }

            if (!values.l3ApprovalRoles?.length) {
                failureToast("Please select L3 approval roles")
                return
            }
            setStep(step + 1);
        }

        if (step === 2) {
            if (!values.documentUploadPercentCriteria) {
                failureToast("Please mention the Concession cutoff to make the Document Upload Mandatory.")
                return
            }

            setSaving(true)

            const request = {
                ...values,
                businessAreas: values.businessAreas.map(item => { return item.value && item.value !== '*' ? item.value : null}).filter(i => i),
                courseCategories: values.courseCategories.map(item => item.value),
                l1ApprovalRoles: values.l1ApprovalRoles.map(item => item.value),
                l2ApprovalRoles: values.l2ApprovalRoles.map(item => item.value),
                l3ApprovalRoles: values.l3ApprovalRoles.map(item => item.value),
            }

            const url = values?.id ? `${endpoint.concessionConfig.update}/${values.id}` : endpoint.concessionConfig.create;
            const method = values?.id ? 'put' : 'post';

            putpost(
                url,
                (data) => {
                    setSaving(false)
                    successToast(data.message)
                    reset()
                },
                (data) => {
                    failureToast(data.message)
                    setSaving(false)
                },
                request,
                method
            );
        }
    }

    const handleCloseConfimation = () => {
        setSelectedConfig(null)
        setOpenDeleteDialog(false)
    }


    const deleteConfig = async (index) => {
        setDeleteting(true);
        const deleteRecord = await fetchDeletePromisedData(`${endpoint.concessionConfig.delete}/${workflowData[index].id}`);
        if (deleteRecord?.code === 200) {
            successToast(deleteRecord['message']);
            setDeleteting(false);
            handleCloseConfimation()
            getAllRecords();
        } else {
            setDeleteting(false);
        }
    }



    return (
        <Container fluid>
            <div className='d-flex justify-content-between align-items-center' >
                <div className='large-text color-dark'>Custom concession configuration</div>
                <div className='d-flex flex-row'>
                    {workflowData?.length ? <Filter
                        businessAreaData={businessArea}
                        roleData={roleData}
                        courseCategoryData={courseCategoryData}
                        getFilteredData={getAllFilteredRecords}
                        getAllData={getAllRecords}
                        filter={filter}
                        setFilter={setFilter}
                    /> : null}
                    <CustomButton
                        className={"regular-large-bold mx-1"}
                        content={'Add config.'}
                        icon={false}
                        permissionType={'C,U,R,D'}
                        permissionSet={'C,U,R,D'}
                        onClick={handleOpen}
                    />
                </div>
            </div>

            {fetching || regionData?.fetching || businessArea?.fetching || roleData?.fetching || courseCategoryData?.fetching ? <CustomLoader
                apiLoader={fetching || regionData?.fetching || businessArea?.fetching || roleData?.fetching || courseCategoryData?.fetching}
                loaderHeight={'100%'}
                loaderWidth={'100%'}
            /> :
                workflowData?.length ? <div className='workflow-list-tab-wrapper mt-4 pt-3 pb-4'>
                    {workflowData.map((item, index) => {
                        return <DetailCard
                            data={item}
                            regionData={regionData}
                            businessAreaData={businessArea}
                            roleData={roleData}
                            categoryData={courseCategoryData}
                            deleteCurrent={() => { setSelectedConfig(index); setOpenDeleteDialog(true) }}
                            isDeleting={deleteing}
                            isSaveing={saving}
                            handleEditData={handleEditData}
                        />
                    })}
                </div>
                    :

                    <div style={{ height: '80vh' }} className='d-flex flex-column justify-content-center align-items-center'>
                        <>
                            <div><img src={document} width={54} height={60} /></div>
                            <div className='large-text color-black pb-4 pt-4 text-center' style={{width:'376px'}}>Click here to add first<br/>
                                Custom concession configuration</div>
                            <CustomButton
                                className={"regular-large-bold"}
                                content={'Add config'}
                                icon={false}
                                permissionType={'C,U,R,D'}
                                permissionSet={'C,U,R,D'}
                                onClick={handleOpen}
                            />
                        </>
                    </div>}
            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <div className='pl-4 pr-4 pt-3 pb-3 concession_workflow'>

                    <div className='d-flex justify-content-between pb-4'>
                        <div className='regular-large-bold label-color'>Add custom concession configuration </div>
                        {/* <div><img onClick={handleClose} src={closeIcon} width={20} height={20} /></div> */}
                        <div onClick={handleClose} style={{fontSize: "14px", fontWeight: 600, color: "#E7685A" , cursor: "pointer"}}>Close</div>
                    </div>
                    {step === 0 ?
                        <BasicDetails
                            regionData={regionData}
                            businessArea={businessArea}
                            courseCategoryData={courseCategoryData}
                            values={values}
                            setValues={setValues}
                        /> : <ClosedStep title="1. Basic details" setStep={() => setStep(0)} />}
                    {step === 1 ?
                        <ApprovalSetting
                            roleData={roleData}
                            values={values}
                            setValues={setValues}
                        /> : step > 1 ? <ClosedStep title="2. Approval setting" setStep={() => setStep(1)} /> : null}
                    {step === 2 ?
                        <DocumentUpload
                            values={values}
                            setValues={setValues}
                        /> : ""}

                    <div className=' d-flex justify-content-between align-items-center mt-2'>
                        {/* <Row style={{ gap: 10 }} className='justify-content-between align-items-center'> */}
                        <div className='d-flex align-items-center'>
                            <img src={infoIcon} width={20} height={20} />
                            <div className='semi-bold color-grey-60 pl-1'>All fields are mandatory</div>
                        </div>
                        <div>
                            <CustomButton
                                className="regular-large-bold pl-5 pr-5 pt-1 pb-1"
                                content={saving ? <i className="fas fa-spinner fa-spin" aria-hidden="true">{''}</i> : step === 2 ? 'Save config.' : 'Next'}
                                permissionType={'C,U,R,D'}
                                permissionSet={'C,U,R,D'}
                                onClick={handleNextStep}
                            />
                        </div>
                        {/* </Row> */}
                    </div>
                </div>
            </Dialog>
            <ConfirmationDeleteDialog
                isOpen={openDeleteDialog}
                onAgree={() => { deleteConfig(selectedConfig) }}
                setIsOpen={handleCloseConfimation}
                headerMsg={'Confirm configuration delete'}
                msg={`Are you sure you want to delete the selected configuration?`}
            />
        </Container>
    )
}

export default ConcessionWorkflow