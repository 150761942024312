import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import { Container, Row } from 'reactstrap';
import ItemTypeTable from './itemTypeTable';
import { masterServiceBaseUrl, failureToast, getAPI } from 'services/http';
import { GetPagination } from '../../../../common/pagination';
import CommonHeader from '../commonHeader/commonHeader';
import CustomLoader from '../../../../common/commonComponents/Loader/loader';
import { PermissionContext, RolePermissions } from 'appContext';
import { pages } from '../../../../common/constant';
import { PermisionDenied } from '../../../../common/commonComponents';
import CustomButton from "components/CustomButton";
import { ConfirmationDialog } from '../../../../common/commonComponents';

const ItemType = (props) => {
	const history = useHistory();
	const { id } = props.match.params;
	const [classArray, setclassArray] = useState([]);
	const [apiloader, setapiloader] = useState(false);
	const [state, setstate] = useState([]);
	const [grpCodeData, setGrpCodeData] = useState(null);
	const [companyCodeData, setCompanyCodeData] = useState(null);
	const [taxCode, setTaxCode] = useState(null);
	const [itemType, setItemType] = useState(null);
	const [itemTypeCount, setItemTypeCount] = useState(0);
	const [unSortedList, setUnSortedList] = useState(null);
	const [tableData, setTableData] = useState([]);

	const [isSaveVisible, setisSaveVisible] = useState(false);
	const [confirmationPopup,setConfirmationPopup] = useState(false);

	const [pagination, setpagination] = useState({
		currentPage: 1,
		nextPage: null,
		previousPage: null,
		totalPage: null,
	});
	const pageSize = 10;

	const permissions = useContext(PermissionContext);

	const userPermissions = RolePermissions(
		permissions,
		pages['taxCodeId']['id']
	);

	const fetchClasses = async (url) => {
		setapiloader(true);
		getAPI(
			url,
			(data) => {
				SetDataFromServer(data['data']);
			},
			(data) => {
				failureToast(data['message']);
			}
		);
	};

	const SetDataFromServer = (data) => {
		setstate(data);
		setTableData(data);
		let n = pagination;
		if (data.length > pageSize) {
			n['nextPage'] = n.currentPage + 1;
		}
		if (n.currentPage > 1) {
			n.previousPage = n.currentPage - 1;
		}
		n['totalPage'] = Math.floor(data.length / pageSize);
		if (data.length % pageSize != 0) {
			n['totalPage'] += 1;
		}
		setpagination(n);
		setapiloader(false);
		setclassArray(
			data.slice(
				pagination.currentPage * pageSize - pageSize,
				pagination.currentPage * pageSize
			) || []
		);
	};

	const nextPage = () => {
		setclassArray(
			state.slice(
				(pagination.currentPage + 1) * pageSize - pageSize,
				(pagination.currentPage + 1) * pageSize
			)
		);
		let n = pagination;
		n['currentPage'] = n['currentPage'] + 1;
		n['previousPage'] = n.currentPage;
		setpagination(n);
	};
	const firstPage = () => {
		setclassArray(state.slice(1 * pageSize - pageSize, 1 * pageSize));
		let n = pagination;
		n['currentPage'] = 1;
		n['previousPage'] = null;
		setpagination(n);
	};
	const lastPage = () => {
		setclassArray(
			state.slice(
				pagination.totalPage * pageSize - pageSize,
				pagination.totalPage * pageSize
			)
		);
		let n = pagination;
		n['currentPage'] = n.totalPage;
		n['previousPage'] = n.totalPage - 1;
		setpagination(n);
	};
	const previousPage = () => {
		if (pagination.currentPage != 1) {
			setclassArray(
				state.slice(
					(pagination.currentPage - 1) * pageSize - pageSize,
					(pagination.currentPage - 1) * pageSize
				)
			);
			// console.log(
			// 	state.slice(
			// 		(pagination.currentPage - 1) * pageSize - pageSize,
			// 		(pagination.currentPage - 1) * pageSize
			// 	)
			// );
			let n = pagination;
			n['currentPage'] = n['currentPage'] - 1;
			if (n['currentPage'] == 1) {
				n['previousPage'] = null;
			} else {
				n['previousPage'] = n.currentPage;
			}
			setpagination(n);
		}
	};

	const fetchDropdown = async (url, name, setMethod) => {
		setapiloader(true);
		getAPI(
			url,
			(data) => {
				let dropList = [];
				if (name === 'itemTypeSetup') {
					setUnSortedList(data.data);
					dropList = data.data.map((data) => {
						return { id: data.id, text: data[`itemTypeId`] };
					});
				} else {
					dropList = data.data.map((data) => {
						return { id: data.id, text: data[`${name}DispValue`] };
					});
				}

				setMethod(dropList);
			},
			(data) => {
				failureToast(data['message']);
			}
		);
	};

	const getTaxCodeData = async () => {
		getAPI(
			`${masterServiceBaseUrl}/taxCodeId/getTaxCodeId/${id}`,
			(data) => {
				setTaxCode(data.data);
				if (data.data.totalItemTypeValue > 0) {
					setItemTypeCount(data.data.totalItemTypeValue);
					fetchClasses(
						`${masterServiceBaseUrl}/itemTypeTaxComponent/getItemTypeTaxComponentByTaxCodeId/${id}`
					);
				}
				fetchDropdown(
					`${masterServiceBaseUrl}/itemTypeSetup/getByCompanyCodeAndGroupCode?companyCodeId=${data.data.companyCodeId}&groupCodeId=${data.data.groupCodeId}`,
					'itemTypeSetup',
					setItemType
				);
			},
			(data) => {
				failureToast(data['message']);
			}
		);
	};

	useEffect(() => {
		fetchDropdown(
			masterServiceBaseUrl + '/groupCode/getAllGroupCode',
			'groupCode',
			setGrpCodeData
		);
		fetchDropdown(
			masterServiceBaseUrl + '/companyCode/getAllActiveCompanyCode',
			'companyCode',
			setCompanyCodeData
		);
	}, []);

	useEffect(() => {
		getTaxCodeData();
	}, [id]);

	const decrementCount = () => {
		setItemTypeCount(itemTypeCount - 1);
	};

	const incrementCount = () => {
		setItemTypeCount(itemTypeCount + 1);
	};

	return (
		<>
			{!grpCodeData &&
				!taxCode &&
				!companyCodeData &&
				!itemType &&
				!unSortedList &&
				apiloader &&
				id ? (
				<CustomLoader
					apiLoader={apiloader}
					loaderHeight={'200px'}
					loaderWidth={'100% '}
				/>
			) : (
				<div>
					{userPermissions ? (
						<>
							<CommonHeader
								title={'Add Item Type'}
								groupCodeData={grpCodeData}
								comapnyCodeData={companyCodeData}
								taxCodeData={taxCode}
								itemCount={itemTypeCount}
								component={'item'}
								userPermissions={userPermissions}
							/>
							<ItemTypeTable
								decrementCount={decrementCount}
								incrementCount={incrementCount}
								previousPage={previousPage}
								fetchClasses={fetchClasses}
								setclassArray={setclassArray}
								classArray={classArray}
								itemTypeData={itemType}
								unSortedList={unSortedList}
								taxCodeId={id}
								setstate={setstate}
								state={state}
								tableData={tableData}
								pagination={pagination}
								setpagination={setpagination}
								SetDataFromServer={SetDataFromServer}
								userPermissions={userPermissions}
								isSaveVisible={isSaveVisible}
								setisSaveVisible={setisSaveVisible}
							/>
							{pagination.totalPage > 1 ? (
								<GetPagination
									fetchClasses={fetchClasses}
									pagination={pagination}
									previousPage={previousPage}
									firstPage={firstPage}
									lastPage={lastPage}
									nextPage={nextPage}
									setpagination={setpagination}
									userPermissions={userPermissions}
								/>
							) : null}

							<Container fluid>
								<Row className="justify-content-end pr-3">
									<CustomButton
										type="close"
										className={'reset-button mx-1'}
										content={"Close"}
										permissionType={'C'}
										// icon={true}
										onClick={!isSaveVisible ? () => history.push("/admin/taxCodeId") : () => setConfirmationPopup(true)}
										permissionSet={userPermissions}
									// disabled={isSaveVisible}
									/>
								</Row>
							</Container>
							<ConfirmationDialog
								isOpen={confirmationPopup}
								onAgree={() => { history.push("/admin/taxCodeId"); setConfirmationPopup(false); }}
								setIsOpen={setConfirmationPopup}
								msg={"You have unsaved data on this page."}
							/>
						</>
					) : (
						<PermisionDenied />
					)}
				</div>
			)}
		</>
	);
};

export default ItemType;
