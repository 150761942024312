import React, { useEffect, useState } from 'react'
import AsyncSelect from 'react-select/async'
import Select, { components } from 'react-select'
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../utils/methods/commonMethods/utilityMethod'
import { FormGroup, InputLabel } from '@mui/material'


const InputLabelStyle = {
  fontFamily: "Gilroy-Regular",
  fontSize: "12px",
  lineHeight: "14px",
  color: "#414141",
  marginTop: "10px"
};

const customStyles = {
  container: (baseStyles) => ({ ...baseStyles, width: '100%' }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    // padding: "7px 8px",
    maxHeight: "15rem",
    minHeight: '20px',
    height: "31px",
    fontSize: "12px",
    background: "#FFFFFF",
    borderRadius: "8px",
    width: "100%",
    display:"flex"
  }),
  valueContainer: baseStyles => ({
    ...baseStyles,
    padding: '2px 18px',
    width: '100%',
    "flex-wrap": "nowrap",
    "white-space": "nowrap",
    overflow: "hidden",
    'text-overflow': 'ellipsis',
    color: 'rgba(0, 0, 0, 0.87)',
  }),
  indicatorSeparator: (baseStyles) => ({
    display: 'hidden'
  }),
  dropdownIndicator: (baseStyles) => ({ padding: 0 })
}

const APaginatedReactSelect = ({
  values,
  url,
  onChangeHandler,
  isMulti = false,
  label = '',

}) => {

  const [options, setOptions] = useState([]);
  const [totalPages, setTotalPages] = useState(0)
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);


  const dropdownOptionsMapping = (dataArray) => {
    return dataArray.map((data) => { return { label: data, value: data } })
  }

  const fetchInitialList = async () => {
    setIsLoading(true);
    const response = await fetchAllPromisedData(`${url}?offset=0`, true)
    setTotalPages(response?.data?.totalPages);
    setOptions(dropdownOptionsMapping(response?.data?.courseIds));
    setIsLoading(false);
  }

  const fetchNextOptions = async () => {
    setPage((page) => page + 1);
    setIsLoading(true);
    if (page < totalPages - 1) {
      const response = await fetchAllPromisedData(`${url}?offset=${page + 1}`, true)
      setOptions((options) => [...options, ...dropdownOptionsMapping(response?.data?.courseIds)])
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchInitialList();
  }, [])

  const Option = (props) => {
    return (
      <components.Option {...props}>
        {isMulti && <input
          style={{ marginRight: '3px' }}
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />}
        {props.children}
      </components.Option>
    );
  };

  const MultiValue = ({ index, getValue, ...props }) => {
    // console.log(props)
    const values = props?.selectProps?.value?.map((o) => o.label)
    // console.log(props)
    return !index && (
      values?.join(", ")
    );
  };

  
  const MultiValueWithCount = ({ index, getValue, ...props }) => {
    // console.log(props)
    const values = props?.selectProps?.value?.map((o) => o.label) ;
     if(values.length>4){
       let value = values.slice(0,4);
       let count  =   values.length - 4 ;
      return (`${value}... ${count}` )
     }else{
       return values?.join(", ")
     }
   
  };

  const DropdownIndicator = props => {
    // console.log(props)
    return (
      <components.DropdownIndicator {...props}>
        <svg stroke="currentColor" fill="rgba(0, 0, 0, 0.54)" stroke-width="0" viewBox="0 0 512 512" height="24px" width="24px" xmlns="http://www.w3.org/2000/svg"><path d="M128 192l128 128 128-128z"></path></svg>
        {/* {props?.menuIsOpen 
          ? <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg>
          : <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg>
        } */}
      </components.DropdownIndicator>
    )
  }

  const onInputChangeHandler = (inputValue) => {
    console.log(inputValue)
    if (inputValue.length) setIsSearching(true)
    else setIsSearching(false)
  }

  return (
    <div>
      <FormGroup>
        {label && <InputLabel sx={InputLabelStyle}>{label}</InputLabel>}
        <Select
          placeholder={'Select'}
          styles={customStyles}
          value={values}
          isMulti={isMulti}
          isLoading={isLoading}
          hideSelectedOptions={false}
          cacheOptions
          options={options}
          onMenuScrollToBottom={isSearching ? () => {} : fetchNextOptions}
          onChange={onChangeHandler}
          onInputChange={onInputChangeHandler}
          closeMenuOnSelect={!isMulti}
          components={{ Option, MultiValue, DropdownIndicator }}
          isClearable={false}
        />
      </FormGroup>
    </div>
  )
}

export default APaginatedReactSelect