import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from "react-router-dom"
import { ReactComponent as BackArrow } from "../../../../../../../assets/img/svg/BackArrow.svg"
import { Card, CardHeader } from 'reactstrap';
import AButton from '../../../../common/form-fields-mui/AButton';
import ListComponent from './ListComponent';
import { fetchAllPostPromisedData } from '../../../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../../../common/constant';
import { getAllActiveBusinessArea } from '../../paymentGatewaysConfiguration/common';
import { successToast } from '../../../../common/utils/methods/toasterFunctions/function';
import FilterForm from './FilterForm';
import APopover from '../../../../common/a-popover';
import SearchInput from '../../../onlinePackage/SearchInput';
import { machineTypes } from './common';

const PosMappingListing = () => {

    const history = useHistory();

    const ref = useRef();

    const [allActiveBusinessAreas, setAllActiveBusinessAreas] = useState([]);
    const [posMappingData, setPosMappingData] = useState([]);
    const [loading, setLoading] = useState([]);
    const [paginationInfo, setPaginationInfo] = useState({ currentPage: 0, totalRecords: 0 });
    const [filterForm, setFilterForm] = useState({});

    const getPosMappings = async (filterObj = {}, pageNumber = 0, activeBUs = allActiveBusinessAreas) => {
        try {
            setLoading(true);
            const resp = await fetchAllPostPromisedData(`${endpoint.posMapping.search}?offset=${pageNumber}`, filterObj, 'post');
            if (resp?.code === 200) {
                const updatedData = resp.data.posMappingDtoList?.map(el => ({
                    ...el,
                    businessArea: activeBUs?.find(bu => bu.value === el.businessAreaId),
                    type: machineTypes?.find(type => type.value === el.machineType)
                }));
                setPosMappingData(updatedData);
                setPaginationInfo({ totalRecords: resp.data.totalRecords, currentPage: resp.data.currentPage })
                ref.current.stopEditing();
            }
            setLoading(false);
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    }


    const getFilterCount = () => {

        const { businessArea, status } = filterForm;
        let count = 0;

        if (businessArea?.length) count++;
        if(!!status) count++;

        return count;
    }


    const createUpdateHandler = async (rowForm) => {
        try {
            setLoading(true);
            const reqObj = {
                ...rowForm,
                businessAreaId: rowForm.businessArea?.id,
                businessAreaValue: rowForm.businessArea?.businessAreaKey,
                machineType: rowForm.type?.value
            }
            let url = endpoint.posMapping.create;
            let method = 'post';

            if (!rowForm?.isNew) {
                url = `${endpoint.posMapping.update}/${reqObj?.id}`;
                method = 'put'
            }
            const resp = await fetchAllPostPromisedData(url, reqObj, method);
            if (resp?.code === 200) {
                getPosMappings();
                successToast('Request completed successfully!!!')
                ref.current.stopEditing();
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }


    const pageChangeHandler = (newPage) => {
        applyFilterHanlder(newPage);
    }

    const handleFilterForm = (value, key) => {
        setFilterForm(prev => ({ ...prev, [key]: value }))
    }

    const applyFilterHanlder = (pageNumber = 0) => {
        const { businessArea, status } = filterForm;
        const filterObj = {
            ...(businessArea && { businessAreaIds: businessArea?.map(bu => bu.value) }),
            ...(status && {status : status?.value})
        }
        setFilterForm(prev => ({ ...prev, appliedFilterCount: getFilterCount() }))

        getPosMappings(filterObj, pageNumber);
    }

    const removeFilterHandler = () => {
        getPosMappings();
        setFilterForm({});
    }

    const searchHandler = (inputVal) => {
        const newFilterObj = inputVal ? { userId: inputVal } : {};
        setFilterForm({});
        getPosMappings(newFilterObj);
    }

    const cancelSearchHandler = () => {
        getPosMappings(filterForm);
    }

    const init = async () => {
        setLoading(true);
        const allActiveBu = await getAllActiveBusinessArea();
        setAllActiveBusinessAreas(allActiveBu);
        getPosMappings({}, 0, allActiveBu)
    }

    useEffect(() => {
        init();
    }, []);


    return (
        <>
            <Card className="mx-2">
                <div className="d-flex justify-content-between my-3 mx-2" >
                    <div className='d-flex justify-content-between'>
                        <BackArrow className='color-black mr-2 cursor' onClick={() => history.goBack()} />
                        <h4 className='color-black'>POS Mapping</h4>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <SearchInput
                            inputStyles={{ border: "1px solid #000" }}
                            placeholder={'Search by User ID'}
                            searchInputValueHandler={searchHandler}
                            onCancel={cancelSearchHandler}
                        />
                        <APopover
                            buttonComponent={openPopover =>
                                <AButton
                                    variant="outline"
                                    onClick={openPopover}
                                    size='xs'
                                >
                                    Filter{filterForm?.appliedFilterCount > 0 ? `(${filterForm?.appliedFilterCount})` : ''}
                                </AButton>
                            }
                            menuComponent={closePopup =>
                                <FilterForm
                                    businessAreaDropdown={allActiveBusinessAreas}
                                    form={filterForm}
                                    applyFilterHandler={applyFilterHanlder}
                                    handleFilterForm={handleFilterForm}
                                    closePopup={closePopup}
                                    removeFilterHandler={removeFilterHandler}
                                    filterCount={getFilterCount()}
                                />}
                        />
                        <AButton
                            variant='primary_filled'
                            onClick={() => ref.current.addNewRow()}
                            size='xs'
                        >
                            + Add new POS mapping
                        </AButton>
                    </div>
                </div>

                <ListComponent
                    rows={posMappingData}
                    ref={ref}
                    businessAreaDropdown={allActiveBusinessAreas}
                    createUpdateHandler={createUpdateHandler}
                    loading={loading}
                    currentPage={paginationInfo.currentPage}
                    totalRecords={paginationInfo.totalRecords}
                    pageChangeHandler={pageChangeHandler}
                    setLoading={setLoading}
                />

            </Card>
        </>
    );
}

export default PosMappingListing;