import React, { useState, useEffect } from 'react';
import {
	FormGroup,
	Container,
	Card,
	CardHeader,
	Input,
	Row,
	Col,
	Button,
} from 'reactstrap';
import { putpost, successToast, failureToast } from 'services/http';
import CustomLoader from '../../../../common/commonComponents/Loader/loader';
import { endpoint } from 'views/pages/manage/common/constant';
import { fetchAllData } from '../../../../common/utils/methods/commonMethods/utilityMethod';
import UserRoleTable from '../component/userRoleAddNewRow/userRoleTable';

const RoleNameSearch = (props) => {
	const [serverData, setServerData] = useState(null);
	const [apiLoader, setApiLoader] = useState(false);
	const [searchParams, setSearchParam] = useState({
		roleName: '',
	});

	const submitHandler = () => {
		if (!searchParams.roleName && !searchParams.roleName.trim()) {
			failureToast('Please input some value for search parameters');
			return;
		}
		searchRoleName();
	};

	const searchRoleName = (obj) => {
		if (searchParams.roleName === null || searchParams.roleName === '') {
			getLatestAllData();
		} else {
			setApiLoader(true);
			const searchData = obj ?? {
				roleName: searchParams.roleName,
			};
			putpost(
				endpoint.role_Name.search,
				(data) => {
					setServerData(data.data);
					setApiLoader(false);
				},
				(data) => {
					setServerData([]);
					failureToast(data['message']);
					setApiLoader(false);
				},
				searchData,
				'post'
			);
		}
	};

	const reset = () => {
		setSearchParam({
			roleName: '',
		});
		setServerData(null);
		getLatestAllData();
	};

	const getLatestAllData = () => {
		fetchAllData(endpoint.role_Name.getAll, setServerData);
	};

	useEffect(() => {
		getLatestAllData();
	}, []);


	return (
		<Container fluid className="mt-4">
			<Card>
				<CardHeader className=" d-flex justify-content-between">
					<h3 className="mb-0 ">User Role Search Page</h3>
				</CardHeader>
				<Row
					style={{
						marginLeft: '260px',
						marginTop: '50px',
						marginBottom: '40px',
					}}
				>
					<Col md="6" style={{ marginTop: '-5px' }}>
						<FormGroup className="text-center">
							<label
								className="form-control-label"
								htmlFor="example3cols2Input"
							>
								Role Name
							</label>
							<Input
								id="roleName"
								value={searchParams.roleName}
								placeholder={`Role Name`}
								type="text"
								maxLength="20"
								style={{ height: '39px' }}
								onChange={(e) =>
									setSearchParam({
										...searchParams,
										roleName: e.target.value,
									})
								}
							/>
						</FormGroup>
					</Col>
					<Col md="3" className="mt-4">
						<Button
							className="btn-vimeo btn-icon w-100"
							color="info"
							type="button"
							onClick={() => {
								submitHandler();
							}}
						>
							<span className="btn-inner--icon mr-1">
								<i className="fas fa-search" />
							</span>
							<span className="btn-inner--text">Search</span>
						</Button>
					</Col>
					<Col md="3" className="mt-4">
						<Button
							className="btn"
							color="secondary"
							type="button"
							onClick={reset}
						>
							<span className="btn-inner--text">Clear</span>
						</Button>
					</Col>
				</Row>
			</Card>
			{apiLoader || !serverData ? (
				<Card className="mt-4">
					<div
						className="mx-auto text-center py-5 my-5"
						style={{ height: '100vh' }}
					>
						<CustomLoader apiLoader={apiLoader} />
					</div>
				</Card>
			) : serverData ? (
				<UserRoleTable data={serverData} searchRoleName={searchRoleName} />
			) : null}
		</Container>
	);
};

export default RoleNameSearch;
