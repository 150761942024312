import React, { useEffect, useState } from 'react';
import {
  masterServiceBaseUrl,
  failureToast,
  getAPI,
  putpost,
  putpost2,
  successToast,
} from 'services/http';
import {
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';

// import { failureToast} from '../../commonComponent/function';
import Select from 'react-select';
import Loader from 'react-loader-spinner';
import moment from 'moment';

const FormFirstAllSection = (props) => {
  const {
    nextStep = () => {},
    isNew = true,
    formId,
    setDate = () => {},
  } = props; //termId

  const [companyCodeData, setCompanyCodeData] = useState([]);
  const [programActionData, setProgramActionData] = useState([]);
  const [idNo, setIdNo] = useState('');
  const [businessData, setBusinessData] = useState([]);
  const [programActionMappingData, setProgramActionMappingData] = useState([]);
  const [apiloader, setApiLoader] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const [value, setValues] = useState({
    businessgrp: '',
    programAction: '',
    comapanyCode: '',
    date: '',
    // status: 'ACTIVE',
  });

  const fetchTermMappingnData = () => {
    setApiLoader(true);
    getAPI(
      `${masterServiceBaseUrl}/programActionMapping/getProgramActionMapping/${formId}`,
      (data) => {
        setValues({
          ...value,
          businessgrp: getSelectedObj(data.data.groupCodeId, businessData),
          programAction: getSelectedObj(
            data.data.programActionId,
            programActionData
          ),
          comapanyCode: getSelectedObj(
            data.data.companyCodeId,
            companyCodeData
          ),
          date: data.data.createdOn,
        });
        setIsDisabled(true);
        setApiLoader(false);
      },
      (data) => {
        failureToast(data['message']);
        setApiLoader(false);
      }
    );
  };

  const getSelectedObj = (id, arr) => {
    return arr.filter((item) => {
      return item.value === id;
    })[0];
  };

  React.useEffect(() => {
    if (
      !isNew &&
      businessData.length &&
      companyCodeData.length &&
      programActionData.length
    ) {
      fetchTermMappingnData();
    }
  }, [isNew, programActionData, businessData, companyCodeData]);

  const fetchData = (url, stateMethod, name) => {
    getAPI(
      url,
      (data) => {
        let list = [];
        //this is for dropdown fields only
        let keyName = `${name}DispValue`;

        list = data.data.map((data) => {
          // if (data.status === "ACTIVE") {
          return {
            value: data.id,
            label: data[keyName],
            date: data.effectiveDate,
          };
          // }
        });
        stateMethod(list);
      },
      (data) => {
        failureToast(data['message']);
      }
    );
  };

  useEffect(() => {
    fetchData(
      masterServiceBaseUrl + '/groupCode/getAllActiveGroupCode',
      setBusinessData,
      'groupCode'
    );
    fetchData(
      masterServiceBaseUrl + '/companyCode/getAllActiveCompanyCode',
      setCompanyCodeData,
      'companyCode'
    );
    fetchData(
      masterServiceBaseUrl + '/programAction/getAllActiveProgramAction',
      setProgramActionData,
      'programAction'
    );
    fetchData(
      masterServiceBaseUrl + '/programActionMapping/getAllProgramActionMapping',
      setProgramActionMappingData,
      'programAction'
    );
  }, []);

  const saveHandler = () => {
    if (value.businessgrp === '') {
      failureToast('Please Select Business Group');
      return;
    } else if (value.programAction === '') {
      failureToast('Please Select Program Action');
      return;
    } else if (value.comapanyCode === '') {
      failureToast('Please Select Company Code');
      return;
    }
    setApiLoader(true);

    const obj = {
      groupCodeId: value.businessgrp.value,
      programActionId: value.programAction.value,
      companyCodeId: value.comapanyCode.value,
      // status: 'ACTIVE',
    };


    if (isNew) {
      putpost(
        `${masterServiceBaseUrl}/programActionMapping/createProgramActionMapping`,
        (data) => {
          successToast(data['message']);
          setDate({ date: value.date });
          setValues({
            businessgrp: '',
            programAction: '',
            comapanyCode: '',
            date: '',
          });
          setApiLoader(false);
          setIdNo(data.data['id']);
          nextStep(data.data.id);
        },
        (data) => {
          setApiLoader(false);
          failureToast(data['message']);
        },
        obj,
        'post'
      );
    }

    if (!isNew) {
      putpost(
        `${masterServiceBaseUrl}/programActionMapping/updateProgramActionMapping/${formId}`,
        (data) => {
          successToast(data['message']);
          setDate({ date: value.date });
          fetchTermMappingnData();
        },
        (data) => {
          setApiLoader(false);
          failureToast(data['message']);
        },
        obj,
        'put'
      );
    }
  };

  const discard = () => {
    fetchTermMappingnData();
    setIsDisabled(true);
  };

  return (
    <Card className="mb-4">
      <CardBody>
        {apiloader ? (
          <div
            className="mx-auto text-center py-5 my-5"
            style={{ height: '30vh' }}
          >
            <Loader
              type="Rings"
              color="#00BFFF"
              height={70}
              width={70}
              timeout={10000}
            />
          </div>
        ) : (
          <>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Business Group
                  </label>
                  <Select
                    value={value.businessgrp}
                    id="example1cols1Input"
                    options={businessData}
                    isDisabled={isDisabled}
                    required
                    onChange={(sData) =>
                      setValues({ ...value, businessgrp: sData })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example1cols1Input"
                  >
                    Company Code
                  </label>
                  <Select
                    value={value.comapanyCode}
                    id="example3cols2Input"
                    options={companyCodeData}
                    isDisabled={isDisabled}
                    required
                    onChange={(sData) =>
                      setValues({ ...value, comapanyCode: sData })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Program Action
                  </label>
                  <Select
                    value={value.programAction}
                    id="example3cols2Input"
                    options={programActionData}
                    isDisabled={isDisabled}
                    required
                    onChange={(sData) => {
                      setSelectedOption(sData.label);
                      setValues({ ...value, programAction: sData });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example4cols2Input"
                  >
                    Created Date
                  </label>
                  <input
                    data-testid="createdDate"
                    defaultValue={
                      value.date ? moment(value.date).format('DD-MM-YYYY') : ''
                    }
                    className="form-control addresslocationInput"
                    onKeyDown={(e) => e.preventDefault()}
                    disabled={true}
                    // placeholder="Created Date"
                    style={{ height: '38px' }}
                  />
                </FormGroup>
              </Col>
            </Row>
            {isNew ? (
              <Row className={`floatRight d-flex pr-3`}>
                <Button
                  data-testid="nextBtn"
                  color="info"
                  size="sm"
                  className={`ml-auto`}
                  onClick={saveHandler}
                >
                  Next
                </Button>
              </Row>
            ) : !isNew && !isDisabled ? (
              <>
                <Row className={`d-flex pr-3 justify-content-end`}>
                  <Button
                    data-testid="saveBtn"
                    color="info"
                    size="sm"
                    className={`mr-3 `}
                    onClick={saveHandler}
                  >
                    Save
                  </Button>
                  <Button
                    data-testid="closeBtn"
                    color="warning"
                    size="sm"
                    className={``}
                    onClick={discard}
                  >
                    Cancel
                  </Button>
                </Row>
              </>
            ) : (
              <Row className={`d-flex pr-3`}>
                <Button
                  color="info"
                  size="sm"
                  className={`ml-auto`}
                  onClick={() => setIsDisabled(false)}
                >
                  Edit
                </Button>
              </Row>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default FormFirstAllSection;

