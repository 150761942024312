import React, { useState, useEffect } from 'react';
import {
  InvoiceNumberSettingHeader,
  INVOICENUMBERSETTING,
} from '../../../common/commonComponents/masterRow/masterRow';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Table,
  Input,
  Label,
} from 'reactstrap';
import Select2 from 'react-select2-wrapper';
import { GetPagination } from '../../../common/pagination';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import CustomDatePicker from '../../../common/commonComponents/CustomDatePicker/index';

import {
  getAPI,
  masterServiceBaseUrl,
  successToast,
  failureToast,
  putpost,
  putpost2,
  deleteAPI,
} from 'services/http';

var _ = require('lodash');

const InvoiceNumberSetting = (props) => {
  const {
    receiptLabel,
    getDisplayValue,
    action,
    businessUnit,
    businessKeyValue,
    invoiceNumberSetting,
    setInvoiceNumberSetting,
    isDisabled
  } = props;
  const [isSaveVisible, setIsSaveVisible] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [state, setState] = useState([]);
  const [dataChange, setDataChange] = useState(false);
  const [tableDataKey, settableDataKey] = useState('tableDataKey');

  const [pagination, setPagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const pageSize = 10;

  // console.log('businessUnit__Plan', businessUnit);

  useEffect(() => {
    try {
      if ( action === 'edit' || action ===  'view' ) {
        performPaginationForData();
      }
    } catch ( e ) {
      console.log('Error :: ',e)
    }
  }, [] );
  
  const performPaginationForData = () => {
    try {
       setApiLoader(true);
    //  console.log(invoiceNumberSetting);
        setState(invoiceNumberSetting);
        let n = pagination;
        if (invoiceNumberSetting.length > pageSize) {
          n['nextPage'] = n.currentPage + 1;
        }
        if (n.currentPage > 1) {
          n.previousPage = n.currentPage - 1;
        }
        n['totalPage'] = Math.floor(invoiceNumberSetting.length / pageSize);
        if (invoiceNumberSetting.length % pageSize != 0) {
          n['totalPage'] += 1;
        }
        setPagination(n);
        
        // console.log(n);
        setInvoiceNumberSetting( invoiceNumberSetting.slice(
         pagination.currentPage * pageSize - pageSize,
            pagination.currentPage * pageSize
          ) || []
        );
        setApiLoader(false);
    } catch ( e ) {
      console.log('Error ::',e)
    }    
     
  }

  const fetchClasses = async (url) => {
    setApiLoader(true);
    getAPI(
      url,
      (data) => {
        // console.log(data['data']);
        setState(data['data']);
        let n = pagination;
        if (data['data'].length > pageSize) {
          n['nextPage'] = n.currentPage + 1;
        }
        if (n.currentPage > 1) {
          n.previousPage = n.currentPage - 1;
        }
        n['totalPage'] = Math.floor(data['data'].length / pageSize);
        if (data['data'].length % pageSize != 0) {
          n['totalPage'] += 1;
        }
        setPagination(n);
        setApiLoader(false);
        // console.log(n);
        setInvoiceNumberSetting(
          data['data'].slice(
            pagination.currentPage * pageSize - pageSize,
            pagination.currentPage * pageSize
          ) || []
        );
      },
      (data) => {
        failureToast(data['message']);
        setApiLoader(false);
      }
    );
  };

  const nextPage = () => {
    setInvoiceNumberSetting(
      state.slice(
        (pagination.currentPage + 1) * pageSize - pageSize,
        (pagination.currentPage + 1) * pageSize
      )
    );
    // console.log(pagination);
    let n = pagination;
    n['currentPage'] = n['currentPage'] + 1;
    n['previousPage'] = n.currentPage;
    setPagination(n);
  };
  const firstPage = () => {
    setInvoiceNumberSetting(state.slice(1 * pageSize - pageSize, 1 * pageSize));
    // console.log(pagination);
    let n = pagination;
    n['currentPage'] = 1;
    n['previousPage'] = null;
    setPagination(n);
  };
  const lastPage = () => {
    setInvoiceNumberSetting(
      state.slice(
        pagination.totalPage * pageSize - pageSize,
        pagination.totalPage * pageSize
      )
    );
    // console.log(pagination);
    let n = pagination;
    n['currentPage'] = n.totalPage;
    n['previousPage'] = n.totalPage - 1;
    setPagination(n);
  };
  const previousPage = () => {
    if (pagination.currentPage != 1) {
      setInvoiceNumberSetting(
        state.slice(
          (pagination.currentPage - 1) * pageSize - pageSize,
          (pagination.currentPage - 1) * pageSize
        )
      );
      // console.log(pagination);
      let n = pagination;
      n['currentPage'] = n['currentPage'] - 1;
      if (n['currentPage'] == 1) {
        n['previousPage'] = null;
      } else {
        n['previousPage'] = n.currentPage;
      }
      setPagination(n);
    }
  };

  const addNewClass = () => {
    // console.log("inside the last number generation is here : ",businessUnit)
    setInvoiceNumberSetting([
      {
        editMode: true,
        __status: 'new',
        businessAreaId: businessUnit,
        code: businessKeyValue,
        year: null,
        label: null,
        regPosInv: null,
        regNegInv: null,
        regTranId: null,
        expPosInv: null,
        expNegInv: null,
        expTranId: null,
        effectiveDate: null,
        status: 'ACTIVE',
      },
      ...invoiceNumberSetting,
    ]);
  };

  // console.log('invoiceNumberSetting__ClassArray', invoiceNumberSetting);
  const saveAll = (cb) => {
    try {
      let pattern_1 = /^[0-9\b]*$/;
      var _tempArray = invoiceNumberSetting;
      var isValid = true;
      _tempArray.forEach((el, index) => {
        if (el['editMode']) {
          if (el['businessAreaId'] === null) {
            isValid = false;
            failureToast('Business Area ID not found');
            return;
          } else if (el['code'] === null || el['code'] == '') {
            isValid = false;
            failureToast('Business Key Code not found');
            return;
          } else if (el['year'] === null || el['year'] == '') {
            isValid = false;
            failureToast('Please enter the Year');
            return;
          } else if (!pattern_1.test(el['year'])) {
            isValid = false;
            failureToast('Year Should be in Numeric value');
            return;
          } else if (el['year'].length < 4) {
            isValid = false;
            failureToast('Please enter a valid Year');
            return;
          } else if (el['label'] === null) {
            isValid = false;
            failureToast('Please Select the Label');
            return;
          } else if (el['regPosInv'] === null || el['regPosInv'] == '') {
            isValid = false;
            failureToast('Please enter the value of Reg Pos Inv');
            return;
          } else if (!pattern_1.test(el['regPosInv'])) {
            isValid = false;
            failureToast('Reg Pos Inv Should be in Numeric value');
            return;
          } else if (el['regNegInv'] === null || el['regNegInv'] == '') {
            isValid = false;
            failureToast('Please enter the value of Reg Neg Inv');
            return;
          } else if (!pattern_1.test(el['regNegInv'])) {
            isValid = false;
            failureToast('Reg Neg Inv Should be in Numeric value');
            return;
          } else if (el['regTranId'] === null || !el['regTranId'].trim()) {
            isValid = false;
            failureToast('Please enter the value of Reg Tran ID');
            return;
          } else if (el['expPosInv'] === null || el['expPosInv'] == '') {
            isValid = false;
            failureToast('Please enter the value of Exp Pos Inv');
            return;
          } else if (!pattern_1.test(el['expPosInv'])) {
            isValid = false;
            failureToast('Exp Pos Inv Should be in Numeric value');
            return;
          } else if (el['expNegInv'] === null || el['expNegInv'] == '') {
            isValid = false;
            failureToast('Please enter the value of Exp Neg Inv');
            return;
          } else if (!pattern_1.test(el['expNegInv'])) {
            isValid = false;
            failureToast('Exp Neg Inv Should be in Numeric value');
            return;
          } else if (el['expTranId'] === null || !el['expTranId'].trim()) {
            isValid = false;
            failureToast('Please enter the value of Exp Tran Id');
            return;
          }

          if (!isValid) {
            return;
          }
          if (_tempArray[index]['__status'] == 'new') {
            setApiLoader(true);
            putpost(
              masterServiceBaseUrl + '/lastNumberGenerated/invoiceNumberSetting',
              (data) => {
                successToast(data['message']);
                settableDataKey(new Date().getMilliseconds());
                fetchClasses(
                  masterServiceBaseUrl +
                    `/lastNumberGenerated/invoiceNumberSetting/businessArea/${businessUnit}`
                );
                setIsSaveVisible(false);
                setApiLoader(false);
              },
              (data) => {
                failureToast(data['message']);
                setApiLoader(false);
              },
              _tempArray[index],
              'post'
            );

            // console.log(
            //   'invoiceNumberSetting[ index ]',
            //   invoiceNumberSetting[index]
            // );
          } else {
            setApiLoader(true);
            putpost(
              masterServiceBaseUrl +
                '/lastNumberGenerated/invoiceNumberSetting/' +
                _tempArray[index].id,
              (data) => {
                successToast(data['message']);
                fetchClasses(
                  masterServiceBaseUrl +
                    `/lastNumberGenerated/invoiceNumberSetting/businessArea/${businessUnit}`
                );

                delete _tempArray[index]['editMode'];
                setInvoiceNumberSetting(_tempArray);
                settableDataKey(new Date().getMilliseconds());
                setIsSaveVisible(false);
                setApiLoader(false);
              },
              (data) => {
                failureToast(data['message']);
                setApiLoader(false);
              },
              _tempArray[index],
              'put'
            );
          }
        }
      });
    } catch (e) {
      console.log('Error', e);
    }
  };

  useEffect(() => {
    if(props.isPrevious && props.formId){
      setApiLoader(true)
      fetchClasses(`${masterServiceBaseUrl}/lastNumberGenerated/invoiceNumberSetting/businessArea/${props.formId}`)
   }
  }, [props.isPrevious,props.formId])

  useEffect(()=>{
    return()=>{
      if(state.length){
        !props.isPrevious && props.isNew  && props.saveStepsCompleted()
      }     
    }    
  },[state])

  const newRow = () => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        addNewClass();
        setIsSaveVisible(true);
      };
      var _tempArray = invoiceNumberSetting;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      // console.log(_tempArray);
      setInvoiceNumberSetting(_tempArray);
      setDataChange(true);
      setIsSaveVisible(false);
      cb();
    }
  };

  const updateRecord = (index, value, property) => {
    try {
      let _tempArray = invoiceNumberSetting;
      _tempArray[index][property] = value;
      setInvoiceNumberSetting(_tempArray);
    } catch (e) {
      console.log('Error', e);
    }
  };

  const editRecord = (index) => {
    try {
      if (isSaveVisible) {
        failureToast('Please Delete or Save current changes');
      } else {
        let cb = () => {
          var _tempArray = invoiceNumberSetting;
          _tempArray[index]['editMode'] = true;
          setInvoiceNumberSetting(_tempArray);
          setIsSaveVisible(true);
        };
        var _tempArray = invoiceNumberSetting;
        _tempArray = _.sortBy(_tempArray, [
          function (o) {
            return o.value;
          },
        ]);
        cb();
      }
    } catch (e) {
      console.log('Error : ', e);
    }
  };

  const deleteRecord = (index) => {
    try {
      if (invoiceNumberSetting[index]['__status'] == 'new') {
        let n = invoiceNumberSetting;
        n.splice(index, 1);
        setInvoiceNumberSetting(n);
        setIsSaveVisible(false);
        settableDataKey(new Date().getMilliseconds());
      } else {
        if (
          window.confirm(
            'Are you sure you want to delete this Business Area & Plan ?'
          )
        ) {
          putpost2(
            masterServiceBaseUrl +
              '/lastNumberGenerated/invoiceNumberSetting/' +
              invoiceNumberSetting[index].id,
            (data) => {
              successToast(data['message']);
              if (invoiceNumberSetting.length == 1) {
                previousPage();
                fetchClasses(
                  masterServiceBaseUrl +
                    `/lastNumberGenerated/invoiceNumberSetting/businessArea/${businessUnit}`
                );
              } else {
                var _tempArray = invoiceNumberSetting;
                if (_tempArray[index]['editMode']) {
                  setIsSaveVisible(false);
                }
                _tempArray.splice(index, 1);
                setInvoiceNumberSetting(_tempArray);
              }
              settableDataKey(new Date().getMilliseconds());
            },
            (data) => {
              failureToast(data['message']);
            },
            'Delete'
          );
        }
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  const closeRecord = (index) => {
    try {
      let n = invoiceNumberSetting;
      if (n[index]['__status'] == 'new') {
        n.splice(index, 1);
      } else {
        // n[index]['editMode']=false
        fetchClasses(
          masterServiceBaseUrl +
            `/lastNumberGenerated/invoiceNumberSetting/businessArea/${businessUnit}`
        );
      }
      setInvoiceNumberSetting(n);
      setIsSaveVisible(false);
    } catch (e) {
      console.log('Error', e);
    }
  };

  return (
    <Container className="mt-3" fluid>
      <Row>
        <Col className={`mb-3 ${action === 'new' ? '' :'mt-3'}`}>
          {!isDisabled? (
            <Button
              disabled={isSaveVisible}
              color="info"
              size="sm"
              type="button"
              className={
                'floatRight mx-1' + (isSaveVisible ? ' btn-dark' : null)
              }
              onClick={() => {
                newRow();
              }}
            >
              Add Sequence
            </Button>
          ) : null}
        </Col>
      </Row>
      {apiLoader ? (
        <div className="mx-auto text-center" style={{ height: '100px' }}>
          <Loader
            type="Rings"
            color="#00BFFF"
            height={70}
            width={70}
            // timeout={10000}
          />
        </div>
      ) : (
        <div id="questionTable" className="table-responsive">
          <Table className="align-items-center table-flush">
            {invoiceNumberSetting.length > 0 ? (
                <InvoiceNumberSettingHeader action={ action } isDisabled={ isDisabled}/>
            ) : (
              ''
            )}
            <tbody className="list" key={tableDataKey}>
              {invoiceNumberSetting
                ? invoiceNumberSetting.map((item, index) =>
                    !item['editMode'] ? (
                      <INVOICENUMBERSETTING
                        dataChange={dataChange}
                        isSaveVisible={isSaveVisible}
                        key={index + '_class'}
                        action={action}
                        deleteRecord={deleteRecord}
                        getDisplayValue={getDisplayValue}
                        item={item}
                        editRecord={editRecord}
                        index={index}
                      closeRecord={ closeRecord }
                      isDisabled={isDisabled}
                      />
                    ) : (
                      <tr key={index + '-class'}>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={5}
                            disabled
                            defaultValue={item['code']}
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={4}
                            type="text"
                            defaultValue={item['year']}
                            onChange={(e) => {
                              let n = e.target.value.trim();
                              updateRecord(index, n, 'year');
                            }}
                            className="tdInput"
                            // placeholder="year"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Select2
                            data={receiptLabel}
                            className="tdSelect"
                            options={{ placeholder: 'Select...' }}
                            value={item.label}
                            onChange={(e) => {
                              let n = e.target.value;
                              updateRecord(index, n, 'label');
                            }}
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={10}
                            defaultValue={item['regPosInv']}
                            onChange={(e) => {
                              let n = e.target.value.trim();
                              updateRecord(index, n, 'regPosInv');
                            }}
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={10}
                            defaultValue={item['regNegInv']}
                            onChange={(e) => {
                              let n = e.target.value.trim();
                              updateRecord(index, n, 'regNegInv');
                            }}
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={10}
                            defaultValue={item['regTranId']}
                            onChange={(e) => {
                              updateRecord(index, e.target.value, 'regTranId');
                            }}
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={10}
                            defaultValue={item['expPosInv']}
                            onChange={(e) => {
                              let n = e.target.value.trim();
                              updateRecord(index, n, 'expPosInv');
                            }}
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={10}
                            defaultValue={item['expNegInv']}
                            onChange={(e) => {
                              let n = e.target.value.trim();
                              updateRecord(index, n, 'expNegInv');
                            }}
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={10}
                            defaultValue={item['expTranId']}
                            onChange={(e) => {
                              updateRecord(index, e.target.value, 'expTranId');
                            }}
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <CustomDatePicker 
                              disabled={true}
                              value={
                              item['__status'] == 'new'
                                ? null
                                : moment(item['effectiveDate']).toDate()
                              }
                              placeholder="Example:01-01-2020"
                          />
                          {/* <Input
                            disabled={true}
                            defaultValue={
                              item['__status'] == 'new'
                                ? null
                                : moment(item['effectiveDate']).format(
                                    'DD-MM-YYYY'
                                  )
                            }
                            placeholder="Example:01-01-2020"
                            className="tdInput"
                          /> */}
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Label className="custom-toggle mx-auto ml-2">
                            <Input
                              checked={item.status == 'ACTIVE' ? true : false}
                              type="checkbox"
                              onChange={(e) => {
                                settableDataKey(new Date().getMilliseconds());
                                let n = 'ACTIVE';
                                if (!e.target.checked) {
                                  n = 'INACTIVE';
                                }
                                updateRecord(index, n, 'status');
                                setDataChange(!dataChange);
                              }}
                            />
                            <span
                              style={{ width: '72px' }}
                              className="custom-toggle-slider rounded-circle activeToggle"
                              data-label-off="Inactive"
                              data-label-on="Active"
                            />
                          </Label>
                        </td>
                        <td>
                          {isSaveVisible ? (
                            <>
                              <Button
                                size="sm"
                                color="info"
                                onClick={() => saveAll()}
                              >
                                Save
                              </Button>
                              <Button
                                size="sm"
                                color="danger"
                                onClick={() => closeRecord(index)}
                              >
                                <i className="fas fa-times" />
                              </Button>
                            </>
                          ) : (
                            <Button
                              color="info"
                              size="sm"
                              type="button"
                              onClick={() => {
                                deleteRecord(index);
                              }}
                            >
                              <i className="fas fa-trash" />
                            </Button>
                          )}
                        </td>
                      </tr>
                    )
                  )
                : null}
            </tbody>
          </Table>

          {pagination.totalPage > 1 ? (
            <GetPagination
              fetchClasses={fetchClasses}
              pagination={pagination}
              previousPage={previousPage}
              firstPage={firstPage}
              lastPage={lastPage}
              nextPage={nextPage}
              setpagination={setPagination}
            />
          ) : null}
        </div>
      )}
    </Container>
  );
};

export default InvoiceNumberSetting;
