import React, {useEffect, useState} from "react";
import {Button, Card, CardBody , Input, Table} from "reactstrap";
import {SetupDatesHeader} from "../../../common/commonComponents/masterRow/masterRow";
import Select2 from "react-select2-wrapper";
import moment from "moment";
import {failureToast} from "../../../common/utils/methods/toasterFunctions/function";
import CustomDatePicker from '../../../common/commonComponents/CustomDatePicker/index';
import { masterServiceBaseUrl,  putpost, putpost2, successToast} from "../../../../../../services/http";
import { useHistory } from 'react-router-dom';
var _ = require('lodash');
const SetupDatesTable =(props)=>{
    let history = useHistory();
    const [startDate,setStartDate] = useState(null);
    const [endDate,setEndDate] = useState(null);
    const [status,setStatus] = useState('ACTIVE');
    const [selectedYear,setSelectedYear] = useState();
    const [courseYearId,setCourseYearId] = useState();

    const newRow =()=>{
        let cb = () => { addNewClass(); props.setIsSaveVisible(true) }
        let _tempArray = props.courseFeesYear;
        _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
        props.setCourseFeesYear(_tempArray);
        props.setIsSaveVisible(false)
        cb()
    }
    const addNewClass = () =>{props.setCourseFeesYear([{id:null,courseYear :null,startDate:null,endDate:null,totalInstallments:null, status :status, updatedDate :moment().format('DD-MM-YYYY'), isEdit:false, isNew:true, yearNumber : null, courseYearId : null,},...props.courseFeesYear])}
    const editRow = (index)=>{
        if (props.isSaveVisible) {
            failureToast("Please Delete or Save current changes")
        }
        else {
            let cb = () => {
                let tempArray = props.courseFeesYear;
                tempArray[index]['isEdit'] = true;
                setStatus(tempArray[index]['status']);
                setStartDate(tempArray[index]['startDate'])
                setEndDate(tempArray[index]['endDate'])
                setSelectedYear(tempArray[index]['courseYear'])
                setCourseYearId(tempArray[index]['id']);
                props.setIsSaveVisible(true)
                props.setIsSaveVisible(true)
            }
            let tempArray = props.courseFeesYear
            tempArray = _.sortBy(tempArray, [function (o) { return o.value; }])
            cb()
        }
    }
    const cancelRow =(index)=>{
        let n=props.courseFeesYear
        if(n[index]['isNew']){
            n.splice(index,1)
        }else{
            n[index]['isEdit'] = false;
        }
        props.setCourseFeesYear(n)
        props.setIsSaveVisible(false)
        setStartDate(null);
        setEndDate(null);
        setSelectedYear(null)
    }

    const deleteRow =(index)=> {
        let Array = props.courseFeesYear;
        let id = Array[index]['id'];

        if (window.confirm('Are you sure you want to delete this setup Payment?')) {
            putpost2(masterServiceBaseUrl + '/setup/dates/' + id, (data) => {
                successToast(data['message']);
                props.fetchData(masterServiceBaseUrl + '/setup/dates/businessArea/'+props.paymentScheduleId);
            }, (data) => {
                failureToast(data['message']);
            }, 'Delete');
        }
    }
    const saveRow =(index)=>{
        try{
            let tempArray = props.courseFeesYear;
            let isValid = true
            if (!selectedYear) {
                failureToast("Please Select Course Year"); isValid = false;
            }else if (!startDate) {
                failureToast("Please Select Start Date"); isValid = false;
            } else if (!endDate) {
                failureToast('Please Select End Date.'); isValid = false;
            } else if (!(new Date(startDate) < new Date(endDate))) {
                failureToast('End Date must be greater than Start Date'); isValid = false;
            }else if(new Date(startDate) < new Date(props.previousData['courseStartDate'])){
                failureToast('Start Date must be greater than course Start Date'); isValid = false;
            } else if(!(new Date(endDate)<= new Date(props.previousData['courseEndDate'].split('-').reverse().join('-')))){
                failureToast('End Date must be less than or equal to Course End Date.'); isValid =false;
            }
            if(!isValid) {
                return;
            } else{
                if(tempArray[index]['isEdit']){
                    let id = tempArray[index]['id'];
                    let tempDataObject ={
                        "courseYearId" : tempArray[index]['courseYearId'],
                        "paymentScheduleId" :props.paymentScheduleId,
                        "status" : status,
                        "endDate" :  moment(endDate).format("YYYY-MM-DDT00:00:00.000") +'Z',
                        "startDate" : moment(startDate).format("YYYY-MM-DDT00:00:00.000") +'Z'
                    }
                    let flag = PostData('/setup/dates/' ,tempDataObject,id);
                    if(flag){
                        tempArray[index]['isEdit'] =false;
                        setSelectedYear(null); setStartDate(null); setEndDate(null); setStatus('ACTIVE'); props.setIsSaveVisible(false);
                    }
                }else if(tempArray[index]['isNew']){
                    let  tempArray = props.courseFeesYear;
                    tempArray[index]['startDate'] = startDate;
                    tempArray[index]['endDate'] = endDate;
                    let tempDataObjectNew = {
                        "courseYearId": courseYearId,
                        "paymentScheduleId":props.paymentScheduleId,
                        "status": status,
                        "endDate":  moment(endDate).format("YYYY-MM-DDT00:00:00.000") +'Z',
                        "startDate": moment(startDate).format("YYYY-MM-DDT00:00:00.000") +'Z'
                    }
                    let flag = PostData('/setup/dates',tempDataObjectNew,null);
                    if(flag){
                        tempArray[index]['isNew'] =false;
                        setSelectedYear(null);  setStartDate(null); setEndDate(null); setStatus('ACTIVE'); props.setIsSaveVisible(false);
                    }
                }
            }
        }catch(e){
            console.log('Error in save row----->',e);
        }
    }
    const PostData = (url,dataObject,id)=>{
        let tempUrl = !id ? url : url+id;
        let method = !id ? 'post' : 'put';
        putpost(masterServiceBaseUrl + tempUrl,(data)=>{
            successToast(data['message'])
            props.setIsSaveVisible(false)
            let n = props.courseFeesYear;
            props.fetchData(masterServiceBaseUrl + '/setup/dates/businessArea/'+props.paymentScheduleId);
            return true;
        },(data)=>{
            failureToast(data['message']);
            return false
        },dataObject,method);
    }


    const TDROW =(props)=>{
        return(
            <tr >
                <td className="text-center p-2 mx-1">{props.courseFeesYear.courseYear ? props.courseFeesYear.courseYear :''}</td>
                <td  className="text-center p-2 mx-1">{props.courseFeesYear.startDate? props.courseFeesYear.startDate.split('-').reverse().join('-') : ''}</td>
                <td  className="text-center p-2 mx-1">{props.courseFeesYear.endDate?props.courseFeesYear.endDate.split('-').reverse().join('-'):''}</td>
                <td  className="text-center p-2 mx-1">
                    <span style={{textAlign:'center'}}>
                        <Button
                            color={props.totalInstallments ? 'primary' : 'info'}
                            size="sm" type="button" className={" mx-1"+(props.isEdit?" btn-dark":null)} disabled={props.isEdit}
                            onClick={()=>history.push(`/admin/paymentSchedule/setupDates/${props.paymentScheduleId}/${props.courseFeesYear.courseYear}/setupInstallments/${props.courseFeesYear.id}/${props.courseFeesYear.courseYearId}`)}
                        >
                            Setup Installments
                        </Button>
                    </span>
                </td>
                <td  className="text-center p-2 mx-1">
                    <Button color='info'
                        // color={props.totalInstallments ? 'primary' : 'info'}
                        size="sm" type="button" className={" mx-1"+(props.isEdit?" btn-dark":null)} disabled={props.isEdit}>
                        Dispatch Schedule
                    </Button>
                </td>
                <td  className="text-center p-2 mx-1">{props.courseFeesYear.totalInstallments ? props.courseFeesYear.totalInstallments : '0'}</td>
                <td  className="text-center p-2 mx-1">{props.courseFeesYear.status === 'ACTIVE' ? <Button color="success" size={'sm'} disabled={true} >Active</Button> : <Button color="danger" size={'sm'} disabled={true} >Inactive</Button> }</td>
                <td  className="text-center p-2 mx-1">{props.courseFeesYear.updatedDate}</td>
                <td  className="text-center p-2 mx-1">
                    <Button color="info" size="sm" type="button" className={" mx-1"} onClick={()=>{editRow(props.index)}}><i className="fas fa-edit" /></Button>
                    <Button color="info" size="sm" type="button" className={" mx-1"}  onClick={()=>deleteRow(props.index)} ><i className="fas fa-trash" /></Button>
                </td>
            </tr>
        )
    }
    useEffect(()=>{
        if(selectedYear ==='firstYear') setStartDate(props.previousData['courseStartDate'].split('-').reverse().join('-'));
        if(props.courseYear && props.courseYear.length>=1){
            props.courseYear.map((item)=>{
                if(selectedYear === item.courseYearDispValue){
                    setCourseYearId(item.id)
                }
            })
        }
    },[selectedYear])
    useEffect(()=>{
        if(props.courseYear && props.courseYear.length>=1){
            props.setYear(props.courseYear.map((item)=>item.courseYearDispValue))
        }
    },props.courseYear)

    const updateKey = (index, value, key) => {
        var _tempArray = props.classArray;
        _tempArray[index][key] = value;
        props.setclassArray(_tempArray);
    };

    return(
        <Card style={{marginBottom:'12px',marginLeft:'20px',marginRight:'20px'}}>
            <CardBody style={{paddingBottom:0}}>
                <Button disabled={props.isSaveVisible} color="info" size="sm" type="button"  className={"floatRight mx-1"+(props.isSaveVisible ?" btn-dark":null)} onClick={newRow}>Add Course Fees Year</Button><br/><br/>
            </CardBody>
                <div id="questionTable" className="table-responsive" >
                    <Table className="align-items-center table-flush " >
                        <SetupDatesHeader />
                        <tbody className="list" key={'setupDateKey'} >
                        { props.courseFeesYear.map((data,index)=>
                            (data['isEdit'] || data['isNew']) ?
                                (<tr key={data['id']+'-class'}>
                                    <td className="text-center p-2 mx-1">
                                        <Select2
                                            defaultValue={selectedYear}
                                            disabled={data['isEdit']}
                                            data={props.year}
                                            onChange={(e)=>{
                                                setSelectedYear(e.target.value);
                                            }}
                                        />
                                    </td>
                                    <td className="text-center p-2 mx-1">
                                        {/* <Input  type={'date'}
                                                min={props.previousData.courseStartDate && props.previousData.courseStartDate.split('-').reverse().join('-')}
                                                max={props.previousData.courseEndDate && props.previousData.courseEndDate.split('-').reverse().join('-')}
                                                className="tdInput"  value={startDate} onChange={(e)=> { setStartDate(e.target.value)}} readOnly={selectedYear ==='firstYear'}
                                        /> */}
                                        <CustomDatePicker 
                                            disabled={selectedYear ==='firstYear'}
                                            value={startDate ? moment(startDate).toDate() : null}
                                            minDate={new Date(props.previousData.courseStartDate && props.previousData.courseStartDate.split('-').reverse().join('/'))}
                                            maxDate={new Date(props.previousData.courseEndDate && props.previousData.courseEndDate.split('-').reverse().join('/'))}
                                            placeholder="Example:01-01-2020"
                                            handler={(index,date,keyName) => setStartDate(date)}
                                            index={index}
                                            rangeFrom={1950}
                                            rangeTo={2099}
                                            keyName='courseStartDate'
                                        />
                                    </td>
                                    <td className="text-center p-2 mx-1">
                                        {/* <Input  type={'date'} dateformat="d M y" className="tdInput"
                                                min={props.previousData.courseStartDate && props.previousData.courseStartDate.split('-').reverse().join('-')}
                                                max={props.previousData.courseEndDate && (props.previousData.courseEndDate).split('-').reverse().join('-')}
                                                value={ endDate }  onChange={(e)=>{setEndDate(e.target.value)}}
                                                // readOnly={selectedYear ==='thirdYear'}
                                        /> */}
                                         <CustomDatePicker 
                                            // disabled={selectedYear ==='thirdYear'}
                                            value={endDate ? moment(endDate).toDate() : null}
                                            minDate={new Date(props.previousData.courseStartDate && props.previousData.courseStartDate.split('-').reverse().join('/'))}
                                            maxDate={new Date(props.previousData.courseEndDate && props.previousData.courseEndDate.split('-').reverse().join('/'))}
                                            placeholder="Example:01-01-2020"
                                            handler={(index,date,keyName) => setEndDate(date)}
                                            index={index}
                                            rangeFrom={1950}
                                            rangeTo={2099}
                                            keyName='courseEndDate'
                                        />
                                    </td>
                                    <td className="text-center p-2 mx-1">
                                        <Button color="info" size="sm" type="button" className={" mx-1"+(props.isSaveVisible?" btn-dark":null)} disabled={props.isSaveVisible}>
                                            Setup Installments
                                        </Button>
                                    </td>
                                    <td className="text-center p-2 mx-1"><Button color="info" size="sm" type="button" className={" mx-1"+(props.isSaveVisible?" btn-dark":null)} disabled={props.isSaveVisible}>Dispatch Schedule</Button></td>
                                    <td className="text-center p-2 mx-1"><span>{data['totalInstallments'] ? data['totalInstallments'] : '0'}</span></td>
                                    <td className="text-center p-2 mx-1">
                                        <label className="custom-toggle mx-auto ml-2">
                                            <input checked={status === 'ACTIVE'} type="checkbox" id={'status'} onChange={(e) => { let n = "ACTIVE"; if (!e.target.checked) { n = "INACTIVE" } ; setStatus(n)} } />
                                            <span style={{width:"72px"}} className="custom-toggle-slider rounded-circle activeToggle" data-label-off="Inactive" data-label-on="Active" />
                                        </label>
                                    </td>
                                    <td  className="text-center p-2 mx-1">
                                        <Input maxLength={50} value={data['updatedDate']} className="customInput" disabled />
                                    </td>
                                    <td  className="text-center p-2 mx-1">
                                        <Button color="info" size="sm" type="button" className="mx-1" onClick={()=>saveRow(index)}>Save</Button>
                                        <Button color="danger" size="sm" type="button" onClick={()=>{cancelRow(index)}}><i className="fas fa-times" /></Button>
                                    </td>
                                </tr>):
                                <TDROW courseFeesYear={data} index={index} totalInstallments={data['totalInstallments'] } key={data['id']} paymentScheduleId={props.paymentScheduleId}/>
                        )}
                        </tbody>
                    </Table>
                </div>
        </Card>
    )
}
export default SetupDatesTable;