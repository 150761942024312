import React, { useContext, useState } from 'react'
import DeleteDialog from '../searchDaysPlan/DeleteDialog';
import { ReactComponent as DeleteIcon } from '../../../../../../assets/img/svg/Delete.svg';
import { ReactComponent as EditIcon } from '../../../../../../assets/img/svg/Edit2.svg';
import { pages } from 'views/pages/manage/common/constant';
import { PermissionContext, RolePermissions } from '../../../../../../appContext'
import { checkPermission } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper';

const ActionsCellComponent = ({ params, editRowHandler, deleteRowHandler = () => { } }) => {

  const userPermissions = RolePermissions(useContext(PermissionContext), pages['daysPlan']['id']);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  return (
    <>
      <div className='d-flex align-items-center'>
        {(checkPermission(userPermissions, 'U') || checkPermission(userPermissions, 'C')) && <IconButtonWrapper onClick={() => editRowHandler(params?.row)}><EditIcon  /></IconButtonWrapper>}
        {(checkPermission(userPermissions, 'U') || checkPermission(userPermissions, 'C')) && <IconButtonWrapper className='ml-2' onClick={() => setIsDeleteDialogOpen(true)}><DeleteIcon  /></IconButtonWrapper>}
      </div>
      <DeleteDialog
        rowDeleteDialog={true}
        open={isDeleteDialogOpen}
        setOpen={setIsDeleteDialogOpen}
        data={params?.row}
        onAgree={() => deleteRowHandler(params?.row)}
      />
    </>
  )
}

export default ActionsCellComponent