import { Tooltip } from '@mui/material'
import React, { useState } from 'react'
import ADataGrid from '../../common/data-grid/ADataGrid'
import { AGridColDef } from '../../common/data-grid/ADataGridColDef'
import { ReactComponent as ActiveControl } from '../../../../../assets/img/svg/ActiveControl.svg';
import { ReactComponent as InactiveControl } from '../../../../../assets/img/svg/InactiveControl.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/img/svg/Edit2.svg'
import { useSelector, useDispatch } from 'react-redux'
import NoRowOverlay from './NoRowOverlay'
// import BranchesCellComponent from './BranchesCellComponent'
import { publishStatusMap } from './constant/faculty-roaster'
import PlanStatusChangeDialog from './PlanStatusChangeDialog'
import { useHistory } from 'react-router'
import APopover from '../../common/pop-hover/APophover.jsx';
import { AiOutlineEye } from "react-icons/ai";
import SearchFilterView from "./../facultyMaster/searchFilterPopover";
import CustomButton from 'components/CustomButton';
import deleteIcon from '../../../../../assets/img/svg/Delete.svg';
import editIcon from '../../../../../assets/img/svg/Edit2.svg';
import { facultyRoasterDelete } from 'redux/facultyRoaster/actions'
import { ConfirmationDialog } from '../../common/commonComponents';
import CustomLoader from '../../common/commonComponents/Loader/loader';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper';




const FacultyRoasterList = ({ page, onPageChange, userPermissions, businessArea }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const facultyRoasterState = useSelector(state => state?.facultyRoaster)
  const { dropdowns, facultyRoasterList } = facultyRoasterState;

  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  // const [planData, setPlanData] = useState({});

  const statusClickHandler = (data) => {
    // setOpenDialog(true);
    // setPlanData(data);
  }

  const SeeMoreValues = ({ list, headerName, label }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return <div className='d-flex'>
      <span style={{ whiteSpace: 'nowrap' }}> {`${list?.[0]?.label ?? ''}${(list?.length ?? 0) - 1 > 0 ? `, +${list?.length - 1}` : ''}`}</span>
      {list?.length > 1 ? <span
        onClick={handleClick}
        aria-describedby={"demo"}
        style={{
          color: " #00B0F5",
          cursor: "pointer",
        }}
      >
        <AiOutlineEye />
      </span> : null}
      <APopover handleClose={handleClose} anchorEl={anchorEl}>
        <SearchFilterView options={list} label={headerName} />
      </APopover>
    </div>
  }

  const handleDeletePrompt = (item) => {
    setCurrentItem(item);
    setOpenDeleteDialog(true);
  }

  const handleCloseDeletePrompt = (bool) => {
    setOpenDeleteDialog(bool);
    setCurrentItem(null);
  }

  const onAgreeDelete = () => {
    dispatch(facultyRoasterDelete(currentItem.id, page, businessArea))
    handleCloseDeletePrompt(false);
  }

  const academicPlansListHeader = [
    new AGridColDef('facultyName', 'Faculty Name').setMinWidth(150).setValueGetter((params) => params?.value),
    new AGridColDef('facultyId', 'Faculty ID').setMinWidth(150).setValueGetter((params) => params.value),
    new AGridColDef('facultyMasterBusinessAreasResponseList', 'Business area').setMinWidth(150)
      .renderCellComponent((params) => (
        <SeeMoreValues list={params.value.map(item => { return { label: item.businessAreaValue, value: item.businessAreaKey } })} headerName={'Business Area'} />
      )),
    new AGridColDef('academicCareer', 'Academic Career').setMinWidth(100).renderCellComponent((params) => (
      <SeeMoreValues list={dropdowns.academicCareer?.filter(item => params.value.indexOf(item.value) > -1) ?? []} headerName={'Academic Career'} />
    )),
    new AGridColDef('academicGroup', 'Academic Group').setMinWidth(150)
      .renderCellComponent((params) => (
        <SeeMoreValues list={dropdowns.academicGroup?.filter(item => params.value.indexOf(item.value) > -1) ?? []} headerName={'Academic Group'} />
      )),
    new AGridColDef('subject', 'Subject').setMinWidth(70).renderCellComponent((params) => (
      <SeeMoreValues list={dropdowns.subject?.filter(item => params.value.indexOf(item.value) > -1) ?? []} headerName={'Subject'} />
    )),
    // new AGridColDef('status', 'Status').setMinWidth(100)
    //   .renderCellComponent((params) => (
    //     <span>
    //       {params.value === "ACTIVE" ?
    //         <ActiveControl onClick={() => statusClickHandler(params?.row)} /> :
    //         <InactiveControl onClick={() => statusClickHandler(params?.row)} />
    //       }
    //       <span
    //         style={{
    //           fontSize: "14px", marginLeft: "4px",
    //           color: params.value === "P" ? '#0F0F0F' : '#757575'
    //         }}
    //       >
    //         {publishStatusMap[params?.value]}
    //       </span>
    //     </span>
    //   )),
    new AGridColDef('Actions', 'Actions', false).setMinWidth(100)
      .renderCellComponent((params) => (
        <>
          {/* <span onClick={() => console.log(params.row)} style={{ cursor: 'pointer', color: '#00B0F5' }}>
            Duplicate
          </span> */}
          {userPermissions && userPermissions.includes('R') && <span onClick={() => { history.push(`/admin/facultyRoaster/faculty/${params.row.id}`) }} className='cursor color-primary regular-bold'>
            View/Edit
          </span>}
          {/* <CustomButton
            icon={false}
            className="btn-transparent color-primary"
            content={"View/Edit"}
            forTable={true}
            permissionType={'R'}
            permissionSet={userPermissions}
            onClick={() => { history.push(`/admin/facultyRoaster/faculty/${params.row.id}`) }}
          /> */}
          <CustomButton
            icon={false}
            content={<IconButtonWrapper><img src={deleteIcon} /></IconButtonWrapper>}
            forTable={true}
            className="btn-transparent p-0"
            permissionType={'D'}
            permissionSet={userPermissions}
            onClick={() => { handleDeletePrompt(params.row) }}
          />
        </>
      )),
  ]

  // const rowsData = [
  //   {
  //     id: 1, name: '2022_RCC_ENG_123456', academicYear: '2022-23', academicCareer: 'RCC', board: 'CBSE', packageId: '123', academicPhase: 'G-1', classes: '12', classType: 'Weekend', regions: [{"id": 1, "dispValue": 'Delhi-NCR1'}], businessAreas: [{"id": 11, "dispValue": 'JP001'}, {"id": 12, "dispValue": 'JP002'}], publishStatus: 'P', description: 'lorem'
  //   },
  //   { id: 2, name: '2022_RCC_ENG', academicYear: '2022-23', academicCareer: 'RCC', board: 'CBSE', packageId: 'lorem', academicPhase: 'G-1', classes: '12', classType: 'Weekend', regions: [], businessAreas: [], publishStatus: 'U', description: 'lorem' },
  //   { id: 3, name: '2022_RCC_ENG', academicYear: '2022-23', academicCareer: 'RCC', board: 'CBSE', packageId: 'test', academicPhase: 'G-1', classes: '12', classType: 'Weekend', regions: [], businessAreas: [], publishStatus: 'U', description: 'lorem' },
  // ]

  return (
    <>
      {facultyRoasterState.searchFacultyRoasterLoader && <div className='d-flex align-items-center justify-content-center' style={{ position: 'absolute', top: 0, left: 0, width: '100vw', height: '85vh', zIndex: 10, background: 'white', opacity: 0.9 }}>
        <CustomLoader
          apiLoader={true}
          loaderHeight={'100%'}
          loaderWidth={'100%'}
        />
      </div>}

      
      <ADataGrid
        // rows={rowsData}
        rows={facultyRoasterList || []}
        columns={academicPlansListHeader}
        rowId={(row) => row.id}
        loading={facultyRoasterState.searchFacultyRoasterLoader}
        rowCount={facultyRoasterState?.searchFacultyRoasterRowCount}
        currentPage={page}
        nextButtonHandler={onPageChange}
        emptyRowPlaceholder={() => <NoRowOverlay />}
      />
      <ConfirmationDialog
        isOpen={openDeleteDialog}
        onAgree={() => onAgreeDelete()}
        setIsOpen={(bool) => handleCloseDeletePrompt(bool)}
        headerMsg={'Confirm row delete'}
        msg={'Are you sure you want to delete the selected roaster?'}
      />
    </>
  )
}

export default FacultyRoasterList