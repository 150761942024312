import { Divider, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import CustomChip from "views/pages/manage/common/customChip/CustomChip";
import { ReactComponent as CopyIcon } from "../../../../../../assets/img/svg/CopyClipboardIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../../../../assets/img/svg/Delete.svg";
import { ReactComponent as EditIcon } from "../../../../../../assets/img/svg/Edit2.svg";
import { ReactComponent as ActiveControl } from "../../../../../../assets/img/svg/ActiveControl.svg";
import { ReactComponent as InactiveControl } from "../../../../../../assets/img/svg/InactiveControl.svg";
import TestSyllabus from "../../testsListView/TestSyllabus";
import styles from "./styles.module.css";
import commonStyles from "../../common/commonStyles.module.css";
import moment from "moment";
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";
import CustomDatePickerV2 from "./../../../common/commonComponents/CustomDatePicker-V2/index";
import APopover from "views/pages/manage/common/a-popover";
import TimeSelectionForm from "./TimeSelectionForm";
import AttachTestForm from "../../testsListView/AttachTestForm";
import { YellowBgColor, isDatePassed } from "../helper";
import { getTestMediumDisplayValue } from "../../testsListView/constant";
import { minimumOnlineDate } from "../../academicTest/BasicForm";
import { IoMdInformationCircleOutline } from "react-icons/io";

const PurpleBgColor = { backgroundColor: "inherit", color: "inherit" };

const minimumStartDate = (testData)=>{
 return  isDatePassed(testData?.testScheduleStartDate||testData?.startDate)?
         new Date():
         moment(testData?.startDate ||testData?.testScheduleStartDate)
}

const reactSelectBorderlessStyles = {
  control: {
    border: "none",
    width: "90%",
    boxShadow: "none",
    background: "transparent",
  },
  valueContainer: { padding: "2px 0" },
};
const datePickerBorderlessStyles = {
  input: {
    border: "none",
    width: "90%",
    boxShadow: "none",
    padding: "10px 0",
    background: "transparent",
    "--placeholder-color": "#00B0F5",
  },
};

const add3Day = (date) => {
  return moment(moment(date), "DD-MM-YYYY").add(2, "days");
};
// react select placeholder color updated
const customStylesUpdate = {
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#00B0F5",
    };
  },
};

const labelStyle = {
  // this styles have been added in class
  // fontWeight: "800",
  // fontSize: "15px"
};

const minOnlineEndDate = (testData, timeTableEndOn) => {
  let testDateWith3Days = "";
  if (testData?.mode.toLowerCase() === "both") {
    testDateWith3Days = add3Day(testData?.onlineTestDate);
  } else {
    testDateWith3Days = add3Day(testData?.testDate);
  }

  let testEndDate = moment(testData?.endDate || testData?.testScheduleEndDate);
  timeTableEndOn = moment(timeTableEndOn);

  if (testDateWith3Days <= testEndDate && testDateWith3Days <= timeTableEndOn)
    return testDateWith3Days;
  else if (testEndDate <= testDateWith3Days && testEndDate <= timeTableEndOn)
    return testEndDate;
  else return timeTableEndOn;
};

const TestCard = ({
  planData,
  testData,
  index,
  expandedIndex,
  setExpandedIndex,
  roomsDropdown,
  testUpdateHandler,
  mode,
  deleteTimetableTest,
  // initialiseList,
  // updateTestStatusHandler = () => { },
  updateAttachTestHandler = () => {},
  timeTableEndOn,
  isHideTopHeader = false,
  reviewCard = false,
  testPassed = false,
  isMissedTest,
}) => {
  const [toolTipText, setToolTipText] = useState("Copy");
  const [conductTest, setConductTest] = useState(testData?.conductTest);

  const testConductedHandler = () => {
    setConductTest((prev) => !prev);
    testUpdateHandler(index, !conductTest, "conductTest");
  };

  const isPastTest = () => {
    return isDatePassed(testData?.endDate || testData?.testScheduleEndDate);
  };

  const getDateForView = (date) => {
    if (moment(date).isValid()) {
      return moment(date)?.format("DD MMM YYYY");
    }

    return "-";
  };

  return (
    <>
      <Card
        className={reviewCard ? "mb-0" : "mb-2"}
        style={
          reviewCard
            ? PurpleBgColor
            : testData?.isNewAdded
            ? YellowBgColor
            : testPassed
            ? { background: "#E3E3E3" }
            : {}
        }
      >
        <CardBody className="p-3">
          {!isHideTopHeader && (
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="d-flex align-items-start">
                <h3 style={{ fontSize: "20px", marginBottom: "0" }}>
                  {testData?.testType}
                </h3>
                <CustomChip
                  // text={}
                  textColor="#006A93"
                  bgColor={"#CCEFFD"}
                  style={{ marginLeft: "1rem" }}
                >
                  <div>
                    {testData?.testName || "Name not found"}
                    <Tooltip title={toolTipText}>
                      <span style={{ marginLeft: "5px" }}>
                        <CopyIcon
                          onClick={() => {
                            navigator.clipboard.writeText(testData?.testName);
                            setToolTipText("Copied");
                          }}
                        />
                      </span>
                    </Tooltip>
                  </div>
                </CustomChip>
                <CustomChip
                  text={
                    testData?.isMandatory ? "Mandatory test" : "Optional test"
                  }
                  textColor={testData?.isMandatory ? "#006A93" : "#262626"}
                  bgColor={testData?.isMandatory ? "#CCEFFD" : "#F5F5F5"}
                  style={{ marginLeft: "1rem" }}
                />
                {testData?.isTimeTableEvent && (
                  <CustomChip
                    text="Branch test"
                    textColor="#183DD3"
                    bgColor="#CCD9FC"
                    style={{ marginLeft: "1rem" }}
                  />
                )}
                {!testData?.isMandatory && (
                  <span className="d-flex align-items-center ml-2">
                    <p
                      className="mb-0 mr-1"
                      style={{
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "28px",
                        color: "#0F0F0F",
                      }}
                    >
                      Do you want to conduct this test?
                    </p>
                    {conductTest ? (
                      <>
                        <ActiveControl
                          onClick={() =>
                            mode === "VIEW" || isMissedTest || testPassed
                              ? null
                              : testConductedHandler()
                          }
                        />
                        <span
                          style={{
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "28px",
                            color: "#0F0F0F",
                            marginLeft: "5px",
                          }}
                        >
                          Yes
                        </span>
                      </>
                    ) : (
                      <>
                        <InactiveControl
                          onClick={() =>
                            mode === "VIEW" || isMissedTest || testPassed
                              ? null
                              : testConductedHandler()
                          }
                        />
                        <span
                          style={{
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "28px",
                            color: "#0F0F0F",
                            marginLeft: "5px",
                          }}
                        >
                          No
                        </span>
                      </>
                    )}
                  </span>
                )}
              </div>

              <div className="d-flex align-items-center">
                {isMissedTest && (
                  <CustomChip
                    text={
                      <span className="d-flex">
                        Missed test
                        <Tooltip title="Missed Test">
                          <div className="cursor ml-1">
                            <IoMdInformationCircleOutline />
                          </div>
                        </Tooltip>
                      </span>
                    }
                    textColor="#B32306"
                    bgColor="#F9D9D7"
                    style={{ marginLeft: "1rem" }}
                  />
                )}

                {testData?.conductTest && !testData?.isComplete && (
                  <CustomChip
                    text={"Incomplete details"}
                    textColor={"#705010"}
                    bgColor={"#FFEDC8"}
                    style={{ marginRight: "5px" }}
                  />
                )}
                {testData?.isTimeTableEvent &&
                  !testPassed &&
                  (mode !== "VIEW" || isPastTest()) && (
                    <DeleteIcon onClick={() => deleteTimetableTest(index)} />
                  )}
                <span
                  className={commonStyles.link_button}
                  style={{ fontSize: "14px", marginLeft: "10px" }}
                  onClick={() =>
                    expandedIndex === index
                      ? setExpandedIndex(-1)
                      : setExpandedIndex(index)
                  }
                >
                  {expandedIndex === index
                    ? `Hide test syllabus`
                    : `View test syllabus`}
                </span>
              </div>
            </div>
          )}
          {!isHideTopHeader && <Divider />}
          <div className={`${styles.test_card_data_container} mt-2`}>
            <div
              className={`${styles.test_card_row} ${styles.test_card_header_row}`}
            >
              <div>Mode</div>
              <div>Format</div>
              <div>Result date</div>
              <div>Test date slot</div>
              <div>
                {testData?.mode === "Offline"
                  ? "Test time slot"
                  : "Test end date"}
              </div>
              {/* <div>Invigilator</div> */}
              <div>Room</div>
              <div>Test medium</div>
              <div>Test</div>
            </div>
            {(testData?.mode === "Offline" || testData?.mode === "Both") && (
              <div
                className={`${styles.test_card_row} ${styles.test_card_data_row}`}
              >
                <div style={labelStyle}>Offline</div>
                <div style={labelStyle}>{testData?.format}</div>
                <div style={labelStyle}>
                  {new moment(testData?.resultDate).format("DD MMM YYYY")}
                </div>
                <div>
                  {mode === "VIEW" || isPastTest() ? (
                    <span>
                      {testData?.isNewAdded
                        ? "-"
                        : getDateForView(testData?.testDate)}
                    </span>
                  ) : (
                    <CustomDatePickerV2
                      InputFontSize="15px"
                      placeHolder="Select date"
                      value={testData?.testDate}
                      handler={(i, date) =>
                        testUpdateHandler(index, date, "testDate")
                      }
                      style={datePickerBorderlessStyles}
                      minDate = {minimumStartDate(testData)}
                      maxDate={
                        testData?.endDate || testData?.testScheduleEndDate
                      }
                    />
                  )}
                </div>
                <div>
                  {mode === "VIEW" || isPastTest() ? (
                    <span style={labelStyle}>
                      {testData?.testStartTime
                        ? `${
                            (testData?.testStartTime &&
                              new moment(testData?.testStartTime).format(
                                "HH:mm"
                              )) ||
                            "-"
                          } - ${
                            (testData?.testEndTime &&
                              new moment(testData?.testEndTime).format(
                                "HH:mm"
                              )) ||
                            "-"
                          }`
                        : `-`}
                    </span>
                  ) : (
                    <APopover
                      buttonComponent={(onClick, styles) => (
                        <span
                          onClick={onClick}
                          style={{ color: "#00B0F5", cursor: "pointer" }}
                        >
                          {testData?.testStartTime
                            ? `${
                                testData?.testStartTime &&
                                new moment(testData?.testStartTime).format(
                                  "HH:mm"
                                )
                              } - ${
                                testData?.testEndTime &&
                                new moment(testData?.testEndTime).format(
                                  "HH:mm"
                                )
                              }`
                            : `Select time`}
                        </span>
                      )}
                      menuComponent={(closeForm) => (
                        <TimeSelectionForm
                          minTime={new moment(testData?.testStartTime).format(
                            "HH:mm"
                          )}
                          index={index}
                          testUpdateHandler={testUpdateHandler}
                          testData={testData}
                          closeForm={closeForm}
                        />
                      )}
                    />
                  )}
                </div>
                <div>
                  {mode === "VIEW" || isPastTest() ? (
                    <span style={labelStyle}>
                      {testData?.room?.label || "-"}
                    </span>
                  ) : (
                    <AAutoComplete
                      isMulti={false}
                      fontSize="15px"
                      style={reactSelectBorderlessStyles}
                      items={roomsDropdown}
                      currentValue={testData?.room}
                      handleChange={(value) =>
                        testUpdateHandler(index, value, "room")
                      }
                      customStylesUpdate={customStylesUpdate}
                    />
                  )}
                </div>
                <div style={labelStyle} className={styles.NA_data_cell}>
                  NA
                </div>
                {testData?.isTimeTableEvent ? (
                  <div>
                    <div style={{ display: "flex" }}>
                      <Tooltip title={testData?.attachTestShortCode}>
                        <span
                          className={commonStyles.link_button}
                          style={{
                            display: "inline-block",
                            fontSize: "14px",
                            borderBottom: "1px solid #00B0F5",
                            marginRight: "3px",
                            cursor: "default",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "100px",
                          }}
                        >
                          {testData?.attachTestShortCode
                            ? `${testData?.attachTestShortCode}`
                            : `Attach test`}
                        </span>
                      </Tooltip>
                      {new Date(testData?.startDate) <
                      new Date().setHours(0, 0, 0, 0) ? (
                        <Tooltip title="Cannot edit as test start date has passed">
                          <EditIcon
                            className="ml-2"
                            style={{
                              width: "14px",
                              height: "14px",
                              cursor: "pointer",
                            }}
                            onClick={() => {}}
                          />
                        </Tooltip>
                      ) : (
                        <APopover
                          buttonComponent={(onClick, styles) => (
                            <EditIcon
                              className="ml-2"
                              style={{
                                width: "14px",
                                height: "14px",
                                cursor: "pointer",
                              }}
                              onClick={onClick}
                            />
                          )}
                          menuComponent={(closeForm) => (
                            <AttachTestForm
                              closeForm={closeForm}
                              testData={testData}
                              planData={planData}
                              updateAttachTestHandler={updateAttachTestHandler}
                            />
                          )}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <Tooltip title={testData?.attachTestShortCode}>
                    <div
                      className={`${styles.test_data_cell} ellipsis-text-container`}
                    >
                      {testData?.attachTestShortCode || "-"}
                    </div>
                  </Tooltip>
                )}
              </div>
            )}
            {testData?.mode === "Online" && (
              <div
                className={`${styles.test_card_row} ${styles.test_card_data_row}`}
              >
                <div style={labelStyle}>Online</div>
                <div style={labelStyle}>{testData?.format}</div>
                <div style={labelStyle}>
                  {new moment(testData?.resultDate).format("DD MMM YYYY")}
                </div>
                <div>
                  {mode === "VIEW" || isPastTest() ? (
                    <span>
                      {testData?.isNewAdded
                        ? "-"
                        : getDateForView(testData?.testDate)}
                    </span>
                  ) : (
                    <CustomDatePickerV2
                      InputFontSize="15px"
                      placeHolder="Select start date"
                      value={testData?.testDate}
                      handler={(i, date) => {
                        testUpdateHandler(index, date, "testDate");
                        testUpdateHandler(index, "", "onlineTestEndDate");
                      }}
                      style={datePickerBorderlessStyles}
                      minDate = {minimumStartDate(testData)}
                      // maxDate={
                      //   testData?.endDate || testData?.testScheduleEndDate
                      // }
                      maxDate={
                        (testData?.endDate &&
                          moment(testData?.endDate).subtract(1, "day")) ||
                        (testData?.testScheduleEndDate &&
                          moment(testData?.testScheduleEndDate).subtract(
                            1,
                            "day"
                          ))
                      }
                    />
                  )}
                </div>
                <div style={labelStyle}>
                  {mode === "VIEW" || isPastTest() ? (
                    <span>
                      {testData?.isNewAdded
                        ? "-"
                        : getDateForView(testData?.onlineTestEndDate)}
                    </span>
                  ) : (
                    <CustomDatePickerV2
                      InputFontSize="15px"
                      placeHolder="Select end date"
                      value={testData?.onlineTestEndDate}
                      handler={(i, date) =>
                        testUpdateHandler(index, date, "onlineTestEndDate")
                      }
                      style={datePickerBorderlessStyles}
                      // minDate={testData?.testDate}
                      minDate={minimumOnlineDate(
                        { value: "Online" },
                        testData?.testDate
                      )}
                      // maxDate={
                      //   moment(moment(testData?.testDate), "DD-MM-YYYY").add(
                      //     3,
                      //     "days"
                      //   ) > moment(timeTableEndOn)
                      //     ? timeTableEndOn
                      //     : moment(
                      //         moment(testData?.testDate),
                      //         "DD-MM-YYYY"
                      //       ).add(3, "days")
                      // }
                      maxDate={minOnlineEndDate(testData, timeTableEndOn)}
                      disabled={!moment(testData?.testDate).isValid()}
                    />
                  )}
                </div>
                <div style={labelStyle} className={styles.NA_data_cell}>
                  NA
                </div>
                <div style={labelStyle}>
                  {getTestMediumDisplayValue(testData?.testMediumType) || "-"}
                </div>
                {testData?.isTimeTableEvent ? (
                  <div>
                    <div style={{ display: "flex" }}>
                      <Tooltip title={testData?.attachTestShortCode}>
                        <span
                          className={commonStyles.link_button}
                          style={{
                            display: "inline-block",
                            fontSize: "14px",
                            borderBottom: "1px solid #00B0F5",
                            marginRight: "3px",
                            cursor: "default",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "100px",
                          }}
                        >
                          {testData?.attachTestShortCode
                            ? `${testData?.attachTestShortCode}`
                            : `Attach test`}
                        </span>
                      </Tooltip>
                      {isDatePassed(testData?.testDate) ? (
                        <Tooltip title="Cannot edit as test start date has passed">
                          <EditIcon
                            className="ml-2"
                            style={{
                              width: "14px",
                              height: "14px",
                              cursor: "pointer",
                            }}
                            onClick={() => {}}
                          />
                        </Tooltip>
                      ) : (
                        <APopover
                          buttonComponent={(onClick, styles) => (
                            <EditIcon
                              className="ml-2"
                              style={{
                                width: "14px",
                                height: "14px",
                                cursor: "pointer",
                              }}
                              onClick={onClick}
                            />
                          )}
                          menuComponent={(closeForm) => (
                            <AttachTestForm
                              closeForm={closeForm}
                              testData={testData}
                              planData={planData}
                              updateAttachTestHandler={updateAttachTestHandler}
                            />
                          )}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <Tooltip title={testData?.attachTestShortCode}>
                    <div
                      className={`${styles.test_data_cell} ellipsis-text-container`}
                    >
                      {testData?.attachTestShortCode || "-"}
                    </div>
                  </Tooltip>
                )}
              </div>
            )}
            {testData?.mode === "Both" && (
              <div
                className={`${styles.test_card_row} ${styles.test_card_data_row}`}
              >
                <div style={labelStyle}>Online</div>
                <div style={labelStyle}>{testData?.format}</div>
                <div style={labelStyle}>
                  {new moment(testData?.resultDate).format("DD MMM YYYY")}
                </div>
                <div>
                  {mode === "VIEW" || isPastTest() ? (
                    <span>
                      {testData?.isNewAdded
                        ? "-"
                        : getDateForView(
                            testData?.onlineTestDate || testData?.testDate
                          )}
                    </span>
                  ) : (
                    <CustomDatePickerV2
                      InputFontSize="15px"
                      placeHolder="Select date"
                      value={testData?.onlineTestDate}
                      handler={(i, date) => {
                        testUpdateHandler(index, date, "onlineTestDate");
                        testUpdateHandler(index, "", "onlineTestEndDate");
                      }}
                      style={datePickerBorderlessStyles}
                      minDate = {minimumStartDate(testData)}
                      // maxDate={
                      //   testData?.endDate || testData?.testScheduleEndDate
                      // }
                      maxDate={
                        (testData?.endDate &&
                          moment(testData?.endDate).subtract(1, "day")) ||
                        (testData?.testScheduleEndDate &&
                          moment(testData?.testScheduleEndDate).subtract(
                            1,
                            "day"
                          ))
                      }
                    />
                  )}
                </div>
                <div style={labelStyle}>
                  <div style={labelStyle}>
                    {" "}
                    {mode === "VIEW" || isPastTest() ? (
                      <span>
                        {testData?.isNewAdded
                          ? "-"
                          : getDateForView(testData?.onlineTestEndDate)}
                      </span>
                    ) : (
                      <CustomDatePickerV2
                        InputFontSize="15px"
                        placeHolder="Select end date"
                        value={testData?.onlineTestEndDate}
                        disabled={!moment(testData?.onlineTestDate).isValid()}
                        handler={(i, date) =>
                          testUpdateHandler(index, date, "onlineTestEndDate")
                        }
                        style={datePickerBorderlessStyles}
                        // minDate={testData?.onlineTestDate}
                        minDate={minimumOnlineDate(
                          { value: "Online" },
                          testData?.onlineTestDate
                        )}
                        maxDate={minOnlineEndDate(testData, timeTableEndOn)}
                      />
                    )}
                  </div>
                </div>
                <div style={labelStyle} className={styles.NA_data_cell}>
                  NA
                </div>
                <div style={labelStyle}>
                  {getTestMediumDisplayValue(testData?.testMediumType) || "-"}
                </div>
                <Tooltip title={testData?.attachTestShortCode}>
                  <div
                    className={`${styles.test_data_cell} ellipsis-text-container`}
                  >
                    {testData?.attachTestShortCode || "-"}
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
          {expandedIndex === index && (
            <TestSyllabus testData={testData} isTimetable={true} />
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default TestCard;
