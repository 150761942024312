import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { Container, Card, CardHeader, Input, Col, Button } from "reactstrap"
import { masterServiceBaseUrl, pricingServiceBaseUrl } from "services/http";
import SearchTable from "./searchTable";
import CustomLoader from "../../../common/commonComponents/Loader/loader";
import { fetchDetails, fetchAllData } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { PermissionContext, RolePermissions } from "appContext";
import { PermisionDenied } from "../../../common/commonComponents";
import { endpoint, pages } from "../../../common/constant";
import CustomButton from "components/CustomButton";
import { ButtonText } from 'variables/Buttons';
import CommonInput from "../../../common/formFeilds/input/commonInput";
import { failureToast } from "../../../common/utils/methods/toasterFunctions/function";
import { getPaginatedData } from '../../../common/utils/methods/commonMethods/paginationMethod';
import { fetchAllPostPromisedData } from '../../../common/utils/methods/commonMethods/utilityMethod';

let page = 0;
let isNextCallPossible = true;
const emptyPagination = {
  currentPage: 1,
  nextPage: null,
  previousPage: null,
  totalPage: null
}
const statusData = [
  { label: "Initiated", value: "initiated" },
  { label: "Submitted", value: "submitted" },
  { label: "L1 Rejected", value: "L1Rejected" },
  { label: "L1 Approved", value: "L1Approved" },
  { label: "L2 Rejected", value: "L2Rejected" },
  { label: "L2 Approved", value: "L2Approved" },
];

const PricingFeeInfoSearch = (props) => {
  const pageSize = 10;
  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["courseFeeInformation"]['id']);

  const [serverData, setServerData] = useState([]);
  const [tableData, setTableData] = useState([]);
  let [pagination, setpagination] = useState(emptyPagination);
  const [isFetching, setIsFetching] = useState(false);


  const [companyCodeData, setCompanyCodeData] = useState([]);
  const [termData, setTermData] = useState([]);
  const [courseIdData, setCourseIdData] = useState([]);
  const [businessAreaData, setBusinessAreaData] = useState([]);
  const [allTermData, setAllTermData] = useState([]);
  const [allCourseData, setAllCourseIdData] = useState([]);
  const [allBusinessAreaData, setAllBusinessAreaData] = useState([]);
  const [pricingZoneData, setPricingZoneData] = useState([]);

  const [isLoading,setIsLoading] = useState(false)
  const [savedSearchParams,setSavedSearchParams] = useState(null);
  const [isExportPossible,setExportPossible] = useState(false);


  const [searchParams, setSearchParam] = useState({
    companyCode: null,
    term: null,
    businessArea: null,
    courseId: null,
    transtionId: "",
    courseFeeStatus: null,
    pricingZone: null,
    processId: ""
  });

  const [initialCompanyCode,setInitialCompanyCode] = useState(null)



  const submitHandler = async () => {
    if( !searchParams?.companyCode?.value){
      failureToast("Select company code");
      setIsFetching(false);
      return;
    }
    if (
      !searchParams?.term?.value &&
      !searchParams?.businessArea?.value &&
      !searchParams?.courseId?.value &&
      !searchParams?.transtionId &&
      !searchParams?.courseFeeStatus &&
      !searchParams?.pricingZone?.value &&
      !searchParams?.processId) {
      failureToast("Select at-least one value for search");
      setIsFetching(false);
      return;
    }

    if (searchParams?.transtionId && searchParams?.transtionId?.length < 10) {
      failureToast("Transaction ID must be of 10 digit or more");
      setIsFetching(false);
      return;
    }

    page = 0;
    setIsFetching(true);

    const searchData = {
      academicTerm: searchParams?.term?.value ?? "",
      businessArea: searchParams?.businessArea?.value ?? "",
      companyCode: searchParams?.companyCode?.value ?? "",
      courseFeeStatus: searchParams?.courseFeeStatus?.value ?? "",
      courseId:
        searchParams?.courseId?.value !== ""
          ? searchParams?.courseId?.value
          : null,
      transactionId: searchParams?.transtionId,
      pricingZone: searchParams?.pricingZone?.value ?? "",
      processInstanceId: searchParams?.processId ? Number(searchParams?.processId) : "",
    };

    // putpost(
    //   `${pricingServiceBaseUrl}/courseFeeProcessingTemp/search`,
    //   (data) => {
    //     successToast(data["message"]);
    //     setServerData(data.data);
    //     setIsFetching(false);
    //   },
    //   (data) => {
    //     setServerData(null);
    //     failureToast(data["message"]);
    //     setIsFetching(false);
    //   },
    //   searchData,
    //   "post"
    // );

    const courseData = await fetchAllPostPromisedData(`${pricingServiceBaseUrl}/courseFeeProcessingTemp/search/${page}`, searchData)
    if (courseData?.code === 200) {
      setServerData(courseData?.data?.courseFeeSearchResponseDTO);
      getPaginatedData(courseData?.data?.courseFeeSearchResponseDTO, pageSize, emptyPagination, setpagination, setIsFetching, setTableData)
      setSavedSearchParams(searchData)
      setExportPossible(courseData?.data?.recordLimit > courseData?.data?.totalRecords || courseData?.data?.recordLimit == courseData?.data?.totalRecords ? true : false )
    } else {
      setServerData([]);
      setIsFetching(false);
      setSavedSearchParams(null)
      setExportPossible(false)
      // failureToast(courseData['message']);
    }
  };

  const reset = () => {
    setSearchParam({
      companyCode: initialCompanyCode,
      term: null,
      businessArea: null,
      courseId: null,
      transtionId: "",
      courseFeeStatus: null,
      pricingZone: null,
      processId: ""
    });
    setServerData(null);
  };

  useEffect(() => {
    fetchDetails(
      `${masterServiceBaseUrl}/companyCode/getAllActiveCompanyCode`,
      setCompanyCodeData,
      "companyCode",
      "select"
    );
    fetchDetails(
      `${masterServiceBaseUrl}/term/getAllActiveTerm`,
      setTermData,
      "term",
      "select"
    );
    fetchAllData(
      `${masterServiceBaseUrl}/term/getAllActiveTerm`,
      setAllTermData
    );
    fetchAllData(
      `${masterServiceBaseUrl}/businessArea/getAllBusinessArea`,
      setAllBusinessAreaData
    );
    fetchAllData(
      `${masterServiceBaseUrl}/courseDetail/getAllCourseDetail`,
      setAllCourseIdData
    );
    fetchDetails(
      endpoint.pricing_zone.getAll,
      setPricingZoneData,
      "pricingZone",
      "pricingZone"
    );
  }, []);

  useEffect(() => {
    setCourseIdData([]);
    setBusinessAreaData([]);
    setSearchParam({ ...searchParams, courseId: null, businessArea: null });
    if (searchParams?.companyCode && searchParams?.companyCode?.value !== "") {
      fetchDetails(
        `${masterServiceBaseUrl}/courseCatalogCourseHeader/getCourseCatalogCourseHeaderByCompanyCode?companyCode=${searchParams.companyCode.value}`,
        setCourseIdData,
        "courseId",
        "courseId"
      );
      fetchDetails(
        `${masterServiceBaseUrl}/businessArea/getBusinessAreaByCompanyCode/${searchParams.companyCode.value}`,
        setBusinessAreaData,
        "businessArea",
        "select"
      );
    }
  }, [searchParams.companyCode]);

  useEffect(()=>{
      if(companyCodeData?.length){
        const aselObject = companyCodeData?.filter(item=>item.label === 'AESL1')
        setSearchParam({ ...searchParams, companyCode: aselObject?.[0] ?? null })
        setInitialCompanyCode(aselObject?.[0] ?? null)
      }
  },[companyCodeData])

  const fetchNext = async () => {
    // const searchData = {
    //   academicTerm: searchParams?.term?.value ?? "",
    //   businessArea: searchParams?.businessArea?.value ?? "",
    //   companyCode: searchParams?.companyCode?.value ?? "",
    //   courseFeeStatus: searchParams?.courseFeeStatus?.value ?? "",
    //   courseId:
    //     searchParams?.courseId?.value !== ""
    //       ? searchParams?.courseId?.value
    //       : null,
    //   transactionId: searchParams?.transtionId,
    //   pricingZone: searchParams?.pricingZone?.value ?? "",
    //   processInstanceId: searchParams?.processId ? Number(searchParams?.processId) : "",
    // };
    setIsFetching(true);
    page = page + 1;
    const courseData = await fetchAllPostPromisedData(`${pricingServiceBaseUrl}/courseFeeProcessingTemp/search/${page}`, savedSearchParams)
    if (courseData?.code === 200 && courseData?.data?.courseFeeSearchResponseDTO?.length > 0) {
      const updatedData = [...serverData, ...courseData.data.courseFeeSearchResponseDTO]
      setServerData(updatedData);
      getPaginatedData(updatedData, pageSize, pagination, setpagination, setIsFetching, setTableData)
      isNextCallPossible = courseData?.data?.length == 50 ? true : false;
    } else {
      isNextCallPossible = false;
      setIsFetching(false);
    }
  }

  return (
    userPermissions ?
      <Container fluid className="mt-4">
        <Card>
          <CardHeader className=" d-flex justify-content-between">
            <h3 className="mb-0 ">Course FEE Installment Search</h3>
            {/* <Button color={'info'} size={'sm'} style={{ width: '100px', marginLeft: '10px' }} onClick={() => props.history.push("/admin/courseFeeSetup/new")}>+ Add
            New</Button> */}
            <CustomButton
              className={'floatRight mx-1 '}
              content={ButtonText.COURSEFEEINFORMATON.NEW}
              permissionType={'C'}
              icon={true}
              onClick={() => props.history.push("/admin/courseFeeSetup/new")}
              permissionSet={userPermissions}
            />
          </CardHeader>
          {!companyCodeData || !termData ? (
            <div
              className="mx-auto text-center py-5 my-5"
              style={{ height: "100vh" }}
            >
              <CustomLoader apiLoader={!companyCodeData || !termData} />
            </div>
          ) : (
            <div className="p-4">
              <div className="form-row">
                <Col className="mb-3" md="3">
                  <CommonInput label={'Company Code'}
                    type={'select'}
                    // mandatory={true}
                    id="course_information_companyCode"
                    placeHolder={'Select Company Code'}
                    defaultValue={searchParams.companyCode}
                    data={companyCodeData}
                    onChange={(value) => setSearchParam({ ...searchParams, companyCode: value })}
                  />
                </Col>
                <Col className="mb-3" md="3">
                  <CommonInput label={'Term ID'}
                    type={'select'}
                    // mandatory={true}
                    id="course_information_term"
                    placeHolder={'Select Term ID'}
                    defaultValue={searchParams.term}
                    data={termData}
                    onChange={(value) => setSearchParam({ ...searchParams, term: value })}
                  />
                </Col>
                <Col className="mb-3" md="3">
                  <CommonInput label={'Course ID'}
                    type={'select'}
                    id="course_information_courseId"
                    placeHolder={'Select Course ID'}
                    defaultValue={searchParams.courseId}
                    data={courseIdData}
                    onChange={(value) => setSearchParam({ ...searchParams, courseId: value })}
                  />
                </Col>
                <Col className="mb-3" md="3">
                  <CommonInput label={'Business Area'}
                    type={'select'}
                    id="course_information_businessArea"
                    placeHolder={'Select Business Area'}
                    defaultValue={searchParams.businessArea}
                    data={businessAreaData}
                    onChange={(value) => setSearchParam({ ...searchParams, businessArea: value })}
                  />
                </Col>
              </div>
              <div className="form-row">
                <Col className="mb-3" md="3">
                  <CommonInput label={'Pricing Zone'}
                    type={'select'}
                    id="course_information_pricingZone"
                    placeHolder={'Select Pricing Zone'}
                    defaultValue={searchParams.pricingZone}
                    data={pricingZoneData}
                    onChange={(value) => setSearchParam({ ...searchParams, pricingZone: value })}
                  />
                </Col>
                <Col className="mb-3" md="3">
                  <CommonInput label={'Process ID'}
                    type={'number'}
                    id="course_information_processId"
                    maxLength={20}
                    placeHolder={'Process ID'}
                    defaultValue={searchParams.processId}
                    onChange={(value) => setSearchParam({ ...searchParams, processId: value })}
                  />
                </Col>

                <Col className="mb-3" md="3">
                  <CommonInput label={'Transaction ID'}
                    type={'number'}
                    id="course_information_transactionId"
                    maxLength={10}
                    placeHolder={'Transaction ID'}
                    defaultValue={searchParams.transtionId}
                    onChange={(value) => setSearchParam({ ...searchParams, transtionId: value })}
                  />
                </Col>
                <Col className="mb-3" md="3">
                  <CommonInput label={'Course Fee Status'}
                    type={'select'}
                    id="course_information_status"
                    placeHolder={'Select Status'}
                    defaultValue={searchParams.courseFeeStatus}
                    data={statusData}
                    onChange={(value) => setSearchParam({ ...searchParams, courseFeeStatus: value })}
                  />
                </Col>
              </div>
              <div className="d-flex justify-content-end ">
                <CustomButton
                  className={'mx-1'}
                  content={ButtonText.COURSEFEEINFORMATON.SEARCH}
                  permissionType={'R,S'}
                  icon={true}
                  onClick={submitHandler}
                  permissionSet={userPermissions}
                  disabled={isLoading}
                />
                <CustomButton
                  type="reset"
                  className={'mx-1 reset-button'}
                  content={ButtonText.COURSEFEEINFORMATON.RESET}
                  permissionType={'R'}
                  icon={true}
                  onClick={reset}
                  permissionSet={userPermissions}
                  disabled={isLoading}
                />
              </div>
            </div>
          )}
        </Card>
        {isFetching ? (
          <div
            className="mx-auto text-center py-5 my-5"
            style={{ height: "100vh" }}
          >
            <CustomLoader apiLoader={isFetching} />
          </div>
        ) : serverData?.length > 0 ? (
          <SearchTable
            data={serverData}
            busMasterData={allBusinessAreaData}
            termMasterData={allTermData}
            courseMasterData={allCourseData}
            pricingZoneData={pricingZoneData}
            userPermissions={userPermissions}
            pagination={pagination}
            serverData={serverData}
            tableData={tableData}
            pageSize={pageSize}
            isNextCallPossible={isNextCallPossible}
            isFetching={isFetching}
            setTableData={setTableData}
            setpagination={setpagination}
            fetchNext={fetchNext}
            searchParams={searchParams}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            savedSearchParams={savedSearchParams}
            isExportPossible={isExportPossible}
          />
        ) : null}
      </Container> : <PermisionDenied />
  );
};

export default withRouter(PricingFeeInfoSearch);
