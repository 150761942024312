import React, { useEffect, useState, useContext } from "react";

import { documentServiceBaseUrl, getAPI } from "../../../../../services/http";
import { fetchDetails } from "../../common/utils/methods/commonMethods/utilityMethod";
import DocumentTypeTable from "./documentTypeTable";
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function'

import { GetPagination } from "../../common/commonComponents/pagination/pagination";
import CustomLoader from '../../common/commonComponents/Loader/loader'
import { PermissionContext, RolePermissions } from "appContext";
import { PermisionDenied } from "../../common/commonComponents";
import { pages } from "../../common/constant";

const DocumentType = (props) => {
  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["documentType"]['id']);

  const [classArray, setclassArray] = useState([])
  const [serverData, setServerData] = useState([])
  const [isSaveVisible, setIsSaveVisible] = useState(false);
  const [isFetching, setIsFetching] = useState(true)
  const [activeformatType, setActiveFormatType] = useState([])
  const [allformatType, setAllFormatType] = useState([])


  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const pageSize = 10;

  const setPagination = (paramsdata) => {
    let n = pagination;
    if (paramsdata.length > pageSize) {
      n["nextPage"] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n["totalPage"] = Math.floor(paramsdata.length / pageSize);
    if (paramsdata.length % pageSize != 0) {
      n["totalPage"] += 1;
    }
    setpagination(n);
    setclassArray(
      paramsdata.slice(
        pagination.currentPage * pageSize - pageSize,
        pagination.currentPage * pageSize
      ) || []
    );
    setIsFetching(false);
  };

  const getData = () => {
    setIsFetching(true)
    getAPI(
      `${documentServiceBaseUrl}/documentType/getAllDocumentType`,
      (data) => {
        setPagination(data.data)
        setServerData(data.data)
        setIsFetching(false)
        // setClassArray(data.data);
        // setIsFetching(false);
      },
      (data) => {
        failureToast(data["message"]);
        setServerData([])
        setPagination([])
        setIsFetching(false);
      }
    );
  };

  useEffect(() => {
    getData()
    fetchDetails(
      `${documentServiceBaseUrl}/documentFormat/getActiveDocumentFormats`,
      setActiveFormatType,
      "documentFormat",
      "select"
    );

    fetchDetails(
      `${documentServiceBaseUrl}/documentFormat/getAllDocumentFormats`,
      setAllFormatType,
      "documentFormat",
      "select"
    );
  }, [])

  return (
    userPermissions ?
      <>
        {isFetching ? (
          <div
            className="mx-auto text-center py-5 my-5"
            style={{ height: "100vh" }}
          >
            <CustomLoader apiLoader={isFetching} />
          </div>
        ) : (
          <>
            <DocumentTypeTable
              isSaveVisible={isSaveVisible}
              setIsSaveVisible={setIsSaveVisible}
              classArray={classArray}
              setClassArray={setclassArray}
              isFetching={isFetching}
              setIsFetching={setIsFetching}
              getData={getData}
              activeformatType={activeformatType}
              allformatType={allformatType}
              userPermissions={userPermissions}
            />

            {serverData && serverData.length > 10 ? (
              <GetPagination
                isSaveVisible={isSaveVisible}
                fetchClasses={getData}
                setclassArray={setclassArray}
                pagination={pagination}
                setpagination={setpagination}
                pageSize={pageSize}
                state={serverData}
              />
            ) : null}
          </>
        )}
      </> : <PermisionDenied />
  );
};

export default DocumentType;
