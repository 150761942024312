import React, { useState, useEffect } from "react";
import { Card, Table, CardHeader } from "reactstrap";
import { MasterHeaderAuto } from "../../../common/commonComponents/masterHeader/masterHeader";
import { openInNewTab, NewRow, isEqual, fetchAllPostPromisedData, getCookie } from '../../../common/utils/methods/commonMethods/utilityMethod';
import {
  GetPagination,
} from "../../../common/commonComponents/pagination/pagination";
import CustomButton from "components/CustomButton";
import { pricingServiceBaseUrl } from "services/http";
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import { successToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import moment from 'moment';

const headerList = [
  { name: "S.No" },
  { name: "Business Area" },
  { name: "Term" },
  { name: "Course ID" },
  { name: "Pricing Zone" },
  { name: "Process ID" },
  { name: "Transaction ID" },
  {name: "Created On"},
  { name: "Course Fee Status" },
  { name: "Actions" },
];

const listToRender = [
  'sno',
  'businessArea',
  'academicTerm',
  'courseId',
  'pricingZoneId',
  'processInstanceId',
  'transactionId',
  'createdOn',
  'courseFeeStatus',
];

const itemToBeRenderFromMaster = ['businessArea', 'academicTerm', 'pricingZoneId']
const propertyList = ['', 'businessAreaDispValue', 'termDispValue', 'courseId', 'label']

const downloadFile = async (searchParams, setIsLoading, savedSearchParams, isExportPossible) => {

  if(!isExportPossible){
    failureToast("Please select more search values to minimize rows in search list as it has crossed the limit to export data")
    return
  }

  setIsLoading(true)

  const response = await fetch(`${pricingServiceBaseUrl}/courseFeeProcessingTemp/export`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCookie('token'),
    },
    body: JSON.stringify(savedSearchParams)
  });

  if (response.ok) {

    const blob = await response.blob();
    const newBlob = new Blob([blob]);

    const blobUrl = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', `search-result.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    // clean up Url
    window.URL.revokeObjectURL(blobUrl);
    successToast("Data from search list has been exported successfully")
    setIsLoading(false)

  } else {
    setIsLoading(false)
    successToast("Something went wrong please contact admin")
  }


}

const SearchTable = (props) => {
  const {
    data,
    busMasterData = [],
    termMasterData = [],
    courseMasterData = [],
    pricingZoneData = [],
    userPermissions,
    pagination,
    serverData,
    tableData,
    pageSize,
    isNextCallPossible,
    isFetching,
    searchParams,
    isLoading,
    setIsLoading = () => { },
    setTableData = () => { },
    setpagination = () => { },
    fetchNext = () => { },
    savedSearchParams,
    isExportPossible
  } = props;
  const mastersList = [[], busMasterData, termMasterData, courseMasterData, pricingZoneData]

  return (
    <Card className="mt-4 mb-4">
      <CardHeader className="border-0">
        <h3 className="mb-0 floatLeft">Search Results</h3>
        <CustomButton
          type={isLoading ? "loading" : "download"}
          className={`mx-1`}
          permissionType={'R'}
          color="info"
          icon={true}
          forTable={false}
          content={'Export'}
          onClick={() => downloadFile(searchParams, setIsLoading,savedSearchParams, isExportPossible)}
          permissionSet={userPermissions}
        />
      </CardHeader>
      <div className="responsive">
        <Table className="align-items-center table-flush">
          <MasterHeaderAuto headerList={headerList} permissionSet={userPermissions} />
          <tbody className="list">
            {tableData ?
              tableData.map((el, index) => {
        
                return (
                  <NewRow data={{ ...el, sno: (index + 1) + (pagination.currentPage - 1) * 10, }}
                    listToBeRendered={listToRender}
                    itemToBeRenderFromMaster={itemToBeRenderFromMaster}
                    arrList={mastersList}
                    propertyList={propertyList}
                    pricing={true}
                  >
                    <td className="text-center  white-breakSpace">
                      {
                        el.courseFeeStatus !== 'Initiated' && <CustomButton
                          className={'mx-1'}
                          permissionType={'R'}
                          icon={true}
                          forTable={true}
                          onClick={() => openInNewTab(`/view/${el.id}`)}
                          permissionSet={userPermissions}
                        />
                      }
                      {
                        isEqual(el.courseFeeStatus, 'L2Approved') && <CustomButton
                          className={'mx-1'}
                          permissionType={'U'}
                          icon={true}
                          forTable={true}
                          onClick={() => window.open(`/#/admin/courseFeeSetup/edit/${el.id}`)}
                          permissionSet={userPermissions}
                        />
                      }
                    </td>
                  </NewRow>
                )
              })
              : <span>No Record Found</span>}
          </tbody>
        </Table>
        {data && data.length > 10 ?
          <GetPagination
            isSaveVisible={false}
            setclassArray={setTableData}
            pagination={pagination}
            setpagination={setpagination}
            pageSize={pageSize}
            state={serverData}
            isPaginationFromServer={true}
            fetchNext={fetchNext}
            isFetching={isFetching}
            currentPage={tableData}
            isNextCallPossible={isNextCallPossible}
          />
          : null}

      </div>
    </Card>
  );
};

export default SearchTable;
