import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import AAutoComplete from "../../../common/form-fields-mui/AAutoComplete";
import AInput from "views/pages/manage/common/formFeilds/customInput/AInput";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import { endpoint, pages } from '../../../common/constant';
import { fetchAllPostPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { successToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import { fetchLocationData } from "./utils";
import { isObjEmpty } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import moment from "moment";
import CustomLoader from "views/pages/manage/common/commonComponents/Loader/loader";
import { PermissionContext, RolePermissions } from "../../../../../../appContext";
import { checkPermission } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { PermisionDenied } from "../../../common/commonComponents";

var _ = require('lodash');


export const labelStyle = {
    fontSize: "14px",
    fontWeight: 600,
    color: "#525F7F"
}

export const selectStyles = { control: { height: '40px', borderRadius: '4px' } }


const LocationDetailsForm = ({ selectedTabValue, action, buId, locationDetails, setSelectedTabValue, fetchLocationAndContactDetails = () => { }, locationDetailsFetching }) => {

    const EMPTY_FORM = {
        buildingName: '',
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        pincode: '',
        city: '',
        district: '',
        state: '',
        country: '',
        landmark: '',
        latitude: '',
        longitude: '',
        googleMapLink: '',
    }

    const permissions = useContext(PermissionContext);
    const userPermissions = RolePermissions(permissions, pages['businessAreaLocationDetails']['id']);
    const isUpdatePermission = checkPermission(userPermissions, 'U');
    const isCreatePermission = checkPermission(userPermissions, 'C');

    const [form, setForm] = useState(EMPTY_FORM);
    const [geoData, setGeoData] = useState({});
    const [isFormDisabled, setIsFormDisabled] = useState(false);

    const formHandler = (value, key) => {
        setForm(prev => ({ ...prev, [key]: value }));
    }

    const pincodeChangeHandler = (val) => {
        const { cityData, districtData, stateData, countryData } = geoData;
        setForm(prev => ({
            ...prev,
            pincode: val,
            district: districtData.find(district => district.id === val.districtId).value,
            city: cityData.find(city => city.id === val.cityId).value,
            state: stateData.find(state => state.id === val.stateId).value,
            country: countryData.find(country => country.id === val.countryId).value,
        }))
    }

    const init = async () => {
        if (isObjEmpty(geoData)) {
            const data = await fetchLocationData();
            console.log('geoData', data);
            setGeoData(data);
        }

        if (action === 'edit' || action === 'view') {
            setIsFormDisabled(true);
            const {
                buildingName,
                address1,
                address2,
                address3,
                address4,
                pincode,
                city,
                district,
                state,
                country,
                landmark,
                longitude,
                latitude,
                googleMapLink,
            } = locationDetails ?? {};

            setForm({
                buildingName,
                address1,
                address2,
                address3,
                address4,
                pincode: { label: pincode, value: pincode },
                city,
                district,
                state,
                country,
                landmark,
                longitude,
                latitude,
                googleMapLink,
            })
        }
    }


    useEffect(() => {
        init();
    }, [locationDetails])

    const saveHandler = async () => {
        const reqObj = { ...form, businessAreaId: buId, pincode: form.pincode.value };

        let url = endpoint.locationDetails.create;
        let method = 'post'
        if (locationDetails?.id) {
            url = `${endpoint.locationDetails.update}/${locationDetails?.id}`;
            method = 'put'
        }

        const resp = await fetchAllPostPromisedData(url, reqObj, method);
        if (resp?.code === 200) {
            fetchLocationAndContactDetails();
            setSelectedTabValue('three');
            successToast('Request completed successfully!');
            // setIsFormDisabled(true);
        }
    }

    const allMandatoryFieldsEntered = () => {
        let copyForm = { ...form };
        delete copyForm?.address3;
        delete copyForm?.address4;
        delete copyForm?.latitude
        delete copyForm?.longitude
        delete copyForm?.googleMapLink
        return Object.values(copyForm).every(value => (_.isObject(value) ? !!value?.value?.trim() : !!value?.trim()));
    }



    if (selectedTabValue !== "two") return null;

    return (
        userPermissions ? 
        <div className="w-100">

            {locationDetailsFetching ? <CustomLoader /> :
                <>
                    <Row>
                        <Col md="6">
                            <AInput
                                label="Building Name*"
                                labelStyle={labelStyle}
                                style={selectStyles.control}
                                placeholder="Enter building name (Max. 50 Characters)"
                                handler={val => formHandler(val, 'buildingName')}
                                value={form.buildingName}
                                disabled={isFormDisabled}
                                maxLength={50}
                            />
                        </Col>
                        <Col md="6">
                            <AInput
                                label="Address Line 1*"
                                labelStyle={labelStyle}
                                style={selectStyles.control}
                                placeholder="Enter SAP business area"
                                handler={val => formHandler(val, 'address1')}
                                value={form.address1}
                                disabled={isFormDisabled}
                                maxLength={80}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <AInput
                                label="Address Line 2*"
                                labelStyle={labelStyle}
                                style={selectStyles.control}
                                placeholder="Enter description in SAP"
                                handler={val => formHandler(val, 'address2')}
                                value={form.address2}
                                disabled={isFormDisabled}
                                maxLength={80}
                            />
                        </Col>
                        <Col md="6">
                            <AInput
                                label="Address Line 3"
                                labelStyle={labelStyle}
                                style={selectStyles.control}
                                placeholder="Enter Business place code"
                                handler={val => formHandler(val, 'address3')}
                                value={form.address3}
                                disabled={isFormDisabled}
                                maxLength={80}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <AInput
                                label="Address Line 4"
                                labelStyle={labelStyle}
                                style={selectStyles.control}
                                placeholder="Enter Business place code"
                                handler={val => formHandler(val, 'address4')}
                                value={form.address4}
                                disabled={isFormDisabled}
                                maxLength={80}
                            />
                        </Col>
                        <Col md="6">
                            <AInput
                                label="Landmark*"
                                labelStyle={labelStyle}
                                style={selectStyles.control}
                                placeholder="Enter landmark"
                                handler={val => formHandler(val, 'landmark')}
                                value={form.landmark}
                                disabled={isFormDisabled}
                                maxLength={100}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <AAutoComplete
                                label="Pincode*"
                                labelStyle={labelStyle}
                                style={selectStyles}
                                items={geoData.pincodeData}
                                placeholder="Enter pincode"
                                handleChange={val => pincodeChangeHandler(val)}
                                currentValue={form.pincode}
                                isMulti={false}
                                isDisabled={isFormDisabled}
                            />
                        </Col>

                        <Col md="6">
                            <AInput
                                label="City"
                                labelStyle={labelStyle}
                                style={selectStyles.control}
                                disabled
                                value={form.city}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <AInput
                                label="District"
                                labelStyle={labelStyle}
                                style={selectStyles.control}
                                disabled
                                value={form.district}
                            />
                        </Col>

                        <Col md="6">
                            <AInput
                                label="State"
                                labelStyle={labelStyle}
                                style={selectStyles.control}
                                disabled
                                value={form.state}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <AInput
                                label="Country"
                                labelStyle={labelStyle}
                                style={selectStyles.control}
                                disabled
                                value={form.country}
                            />
                        </Col>

                        <Col md="6">
                            <AInput
                                label="Google Map Link"
                                labelStyle={labelStyle}
                                style={selectStyles.control}
                                placeholder="Enter Branch Google Map location link"
                                handler={val => formHandler(val, 'googleMapLink')}
                                value={form.googleMapLink}
                                disabled={isFormDisabled}
                                maxLength={200}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <AInput
                                label="Longitude"
                                labelStyle={labelStyle}
                                style={selectStyles.control}
                                placeholder="Enter details"
                                handler={val => formHandler(val, 'longitude')}
                                value={form.longitude}
                                disabled={isFormDisabled}
                                maxLength={10}
                            />
                        </Col>
                        <Col md="6">
                            <AInput
                                label="Latitude"
                                labelStyle={labelStyle}
                                style={selectStyles.control}
                                placeholder="Enter details"
                                handler={val => formHandler(val, 'latitude')}
                                value={form.latitude}
                                disabled={isFormDisabled}
                                maxLength={10}
                            />
                        </Col>
                    </Row>

                    <Row className="m-2 d-flex justify-content-between">

                        {((action === 'view' || action === 'edit') && locationDetails)
                            ? <h4 className="mt-2">Last Updated by  {locationDetails?.updatedBy} at {moment(locationDetails?.updatedOn).format("hh:mm a")} on {moment(locationDetails?.updatedOn).format('DD/MM/YYYY')}</h4>
                            : <div></div>
                        }

                        <div>
                            {(isFormDisabled && action === 'edit') &&
                                <AButton
                                    variant="primary_filled"
                                    onClick={() => setIsFormDisabled(false)}
                                    disabled={!isUpdatePermission}
                                >Update
                                </AButton>
                            }

                            {!isFormDisabled &&
                                <>
                                    <AButton
                                        variant="outline"
                                        onClick={() => setForm(EMPTY_FORM)}
                                        disabled={(action === 'new' && !isCreatePermission)}
                                    >
                                        Reset
                                    </AButton>

                                    <AButton
                                        variant="primary_filled"
                                        onClick={saveHandler}
                                        disabled={(action === 'new' && !isCreatePermission) || !allMandatoryFieldsEntered()}
                                    >
                                        Save
                                    </AButton>
                                </>
                            }
                        </div>
                    </Row>
                </>
            }

        </div>
        : <PermisionDenied/>
    );
}

export default LocationDetailsForm;