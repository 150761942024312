
import { endpoint } from "../../common/constant";
import { dropdownMapping, fetchAllPostPromisedData, fetchAllPromisedData, fetchDeletePromisedData, updateRequest } from "../../common/utils/methods/commonMethods/utilityMethod";

const formInfo = {
  holidayType: 'Array',
  day: 'Array',
  region: 'Array',
  businessArea: 'Array',
  city: 'Array',
  state: 'Array'
}

export const getFilterCount = (dataSet) => {
  let cnt = 0;
  for (let item in dataSet) {
    if (formInfo[item] === 'String') {
      if (dataSet[item]) cnt++;
    }
    if (formInfo[item] === 'Array') {
      if (dataSet[item].length) cnt++;
    }
    if (formInfo[item] === 'Boolean') {
      if (dataSet[item]) cnt++;
    }
  }
  return cnt;
}

export const holidayTypeDropdownData = [
  { label: 'Regional', value: 'Regional' },
  { label: 'National', value: 'National' },
  { label: 'Restricted', value: 'Restricted' },
]

export const dayDropdownData = [
  { label: 'Monday', value: 'MONDAY' },
  { label: 'Tuesday', value: 'TUESDAY' },
  { label: 'Wednesday', value: 'WEDNESDAY' },
  { label: 'Thursday', value: 'THURSDAY' },
  { label: 'Friday', value: 'FRIDAY' },
  { label: 'Saturday', value: 'SATURDAY' },
  { label: 'Sunday', value: 'SUNDAY' },
]

export const searchFilterInitialForm = {
  holidayNameOrHolidayId: '',
  holidayType: [],
  day: [],
  region: [],
  businessArea: [],
  city: [],
  state: [],
}

export const holidayRowInitialForm = {
  holidayName: "",
  holidayType: "",
  holidayDate: "",
  day: "",
  regions: [],
  businessAreas: [],
  states: [],
  city: [],
  status: "ACTIVE"
}

export const selectStyles = {
  control: {
    borderRadius: '4px',
    height: '40px'
  }
}
export const inputStyles = {
  borderRadius: '4px',
  height: '40px'
}

export const getDropdowns = async () => {
  const p = await Promise.all([
    dropdownMapping(endpoint?.region?.getAllActive, 'regionDispValue', 'id'),
    dropdownMapping(endpoint?.region?.getAll, 'regionDispValue', 'id'),
    dropdownMapping(endpoint?.businessArea?.getAll, 'businessAreaDispValue', 'id'),
    dropdownMapping(endpoint?.state?.getAllActive, 'stateDispValue', 'id'),
    dropdownMapping(endpoint?.state?.getAll, 'stateDispValue', 'id'),
    dropdownMapping(endpoint?.city?.getAllActive, 'cityDispValue', 'id'),
    dropdownMapping(endpoint?.city?.getAll, 'cityDispValue', 'id'),
  ])

  return {
    region: p[0],
    allRegion: p[1],
    allBusinessArea: p[2],
    state: p[3],
    allState: p[4],
    city: p[5],
    allCity: p[6],
  }
}

export const deleteHoliday = (id) => fetchDeletePromisedData(`${endpoint?.holidayMaster?.delete}/${id}`);
export const createHoliday = (requestPayload) => fetchAllPostPromisedData(`${endpoint?.holidayMaster?.create}`, updateRequest(requestPayload), 'post');
export const getHolidayList = (requestPayload) => fetchAllPostPromisedData(`${endpoint?.holidayMaster?.search}`, updateRequest(requestPayload), 'post');
export const updateHoliday = (id, requestPayload) => fetchAllPostPromisedData(`${endpoint?.holidayMaster?.update}/${id}`, updateRequest(requestPayload), 'put');