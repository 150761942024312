import { IconButton, Tooltip, Divider, Dialog, Typography, Tabs, Tab } from '@mui/material'
import React, { useState, useRef, useEffect } from 'react'
import { FaTimes } from 'react-icons/fa'
import { Card } from 'reactstrap'
import closeIcon from 'assets/img/svg/closeSquare.svg';
import CustomButton from '../../../../../../../components/CustomButton'
import { getDraftSummaryData } from '../templates/mapping'
// import { Tooltip } from '@mui/material'
import './index.scss'
import { updateQuestionStatus } from '../common';
import { fetchAllPostPromisedData } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod'
import { endpoint } from 'views/pages/manage/common/constant'
import { successToast,failureToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function'
import { uploadpost } from 'services/utils'
import { putpost } from 'services/utils'
import { regex } from 'views/pages/manage/common/constant'
import DocPreview from '../../../concessionApproval/docPreview';
import { Box } from '@mui/system';
import useConfirm from '../../../../common/commonComponents/ConfirmationDialog/confirmationPrompt.jsx';
import { fetchAllPromisedData } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { parseFloatTillDigit } from '../feeBreakup/common';



const VerifyCharges = (props) => {
    const { handleVerifyChargesDialog,
        studentCurrentYear,
        setIsOpen,
        applicationDetails,
        concessionDetails,
        paymentDetails,
        finalBreakupData,
        finalizeCharges,
        courses,
        allCategories, applicationQuotationDetailsId,
        getApplicationDetails,
        getConcessionDetails,
        getPaymentDetails,businessAreas,setInfoModalOpen, docRequiredForCustomConcession } = props;
  console.log(concessionDetails,"concessionDetails")
    const fileInputRef = useRef(null);
    const captureRef = useRef(null);
    const [activeTab, setActiveTab] = useState(0)
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [preview, setPreview] = useState(null);
    const [processInsID, setProcessInsID] = useState(null);
    const [ConfirmationDialog, confirm] = useConfirm();
    const [loadingCategoryInfo,setLoadingCategoryInfo] = useState(true);
    const [docStatus,setDocStatus] = useState([]);

    let concessionTotal = 0

    const getTotal = (data, keys) => {
        const sum = Object.values(data).reduce((acc, item) => {
            keys.forEach((key) => {
                if (item.hasOwnProperty(key)) {
                    acc = (Number(acc) || 0) + Number(item[key]);
                }
            })
            return acc;
        }, {});
        return isNaN(sum) ? '' : sum?.toFixed(2) ?? ''
    }

    const getConcessionName = (subId, subSubId) => {
        let name = ''
        allCategories.forEach(element => {
            if (subId) {
                const filteredObj = element?.subCategories.find(i => i.id === subId);
                if (filteredObj?.subCategoryDispVal) {
                    name = filteredObj?.subCategoryDispVal
                }
            } else {
                element.subCategories.forEach(el => {
                    const filteredObj = el?.subSubCategory.find(i => i.id === subSubId);
                    if (filteredObj?.subSubCategoryDisplayValue) {
                        name = filteredObj?.subSubCategoryDisplayValue
                    }
                })
            }
        })

        return name
    }

    const getFeeBreakup = () => {
        try {
            // setLoading(true);
            let tempArr = [];
            Object.values(finalizeCharges.data).map(item => {
                tempArr = [...tempArr, ...item]
            })
            console.log("tempArr",tempArr)
            const breakup = tempArr.map(item => {
                const gstBreakup = {
                    cgst: 0,
                    sgst: 0,
                    utgst: 0,
                    igst: 0
                };
                item?.taxBreakupResDtos && item.taxBreakupResDtos.map(i => {
                    return gstBreakup[i.taxCodeTypeDispVal.toLowerCase()] = parseFloat(((i.taxPercentage*item.payableAmt)/100).toFixed(4))
                })

                const payableAmountPostApproval = item.concApprovalPending ? item.payableAmt - item.concApprovalPending  : 0 ;
                const taxPostApproval = (payableAmountPostApproval * item.totalTaxPercentage)/100;

                return {
                    "applicationQuotationDetailsId": applicationQuotationDetailsId,
                    "id":item?.id||null,
                    "itemType": item.itemType,
                    "dueDate": item.dueDate,
                    "grossAmount": item.baseAmount,
                    "concessionApplied": item.consession,
                    "payableAmount": item.payableAmt,
                    "taxes": item.tax,
                    "netPayable": item.netPayable,
                    "concessionApprovalPending": item?.concApprovalPending ?? 0,
                    "payableAmountPostApproval": payableAmountPostApproval,
                    "status": "ACTIVE",
                    installmentNo: Number(item?.installmentNo),
                    year: item?.year,
                    taxPostApproval: parseFloat(taxPostApproval.toFixed(4)),
                    netPayablePostApproval: parseFloat((taxPostApproval + payableAmountPostApproval).toFixed(4)),
                    concessionIds: item?.concessionIds ?? [],
                    ...gstBreakup
                }
            });

            const request = {
                "applicationQuotationDetailsId": applicationQuotationDetailsId,
                "studentChargesFeeBreakup": breakup,
                "totalGrossAmount": Object.keys(finalizeCharges.total).reduce((a, b) => a + finalizeCharges.total[b]['totalGross'], 0),
                "totalConcessionApplied": Object.keys(finalizeCharges.total).reduce((a, b) => a + finalizeCharges.total[b]['conAmt'], 0),
                "totalPayable": Object.keys(finalizeCharges.total).reduce((a, b) => a + finalizeCharges.total[b]['totalPayAmt'], 0),
                "totalTaxes": Object.keys(finalizeCharges.total).reduce((a, b) => a + finalizeCharges.total[b]['totalTax'], 0),
                "totalNetPayable": Object.keys(finalizeCharges.total).reduce((a, b) => a + finalizeCharges.total[b]['netAmt'], 0),
                "totalConcessionApprovalPending": Object.keys(finalizeCharges.total).reduce((a, b) => a + Number(finalizeCharges.total[b]['concApprovalPendingTotal']), 0),
                "totalPayableAmountPostApproval": Object.keys(finalizeCharges.total).reduce((a, b) => a + Number(finalizeCharges.total[b]['netPayablePostApprovalTotal']), 0),
            }

            console.log(finalizeCharges)
            console.log(request)
            return request
        } catch (e) {
            console.log(e);
            return []
        }
    }

    const getDocUploadRequired = (row) => {
        const c = docStatus.find(i => (i?.subCategoryId && i?.id === row?.concessionSubSubCategoryId) || i?.id === row?.concessionSubCategoryId);
        return c?.documentUpload ? 'Yes' : 'No'
    }

    const allMandatoryDocSubmitted = () => {
        let allowed = true;
        concessionDetails.forEach(el => {
            if (el?.concessionType === "CUSTOM" && !el.concessionDocuments?.length && el?.concessionApprovalStatus !== 'REVOKE' && !['L1_REJECT','L2_REJECT','L3_REJECT'].includes(el?.concessionApprovalStatus)) {
                if(docRequiredForCustomConcession){
                    allowed = false
                }
            }else{
                const isDocAttached = el?.concessionDocuments?.length ? true : false;
                const isRequired = getDocUploadRequired(el) === 'Yes' ? true : false;
                if(isRequired && !isDocAttached){
                    allowed = false
                }
            }
        })
        

        return allowed
    }

    const documentPending = () => {
        let pending = false;
        concessionDetails.forEach(el => {
            if (!el.concessionDocuments?.length) {
                pending = true
            }
        })
        return pending
    }

    // attached flow not to be removed
    const getFilePath = (file) => {
        try {
            setUploading(true);
            const formData = {
                bulk: false,
                fileName: file.name,
                sourceId: 1,
                keyValue: new Date(),
                docType: file.name.split(".")[1],
                serviceName: "ENROLLMENT_CONCESSION_UPLOAD",
            };

            putpost(endpoint.fetchS3Url.getUrl,
                (data) => {
                    if (data.code === 200 && data.data) {
                        saveDataToStorage(data.data, file);
                    }
                },
                (data) => {
                    setSelectedDoc(null)
                    setUploading(false)
                    failureToast("Something went wrong please try after sometime.")
                },
                formData,
                "post"
            );
        } catch (e) {
            console.log(e);
        }
    };

    const saveDataToStorage = (res, file) => {

        // const formData = new FormData();
        // formData.append("File", file);
        // formData.append("destinationfolder", res.bucket);

        // setISUploading(true);
        uploadpost(
            res.storage,
            (data) => {
                addFileToQoutation(res, file)
            },
            (data) => {
                setSelectedDoc(null)
                setUploading(false)
                failureToast(data["message"]);
                return null
            },
            file,
            file?.type,
            file?.name
        );
    };

    const addFileToQoutation = async (data, file) => {
        const request = [{
            "applicationQuotationDetailsId": applicationQuotationDetailsId,
            "uploadedFileKey": data?.key,
            "documentType": selectedDoc?.name,
            "processInstanceId": data?.processId,
            "fileName": file?.name,
            applicationConcessionId: selectedDoc?.id
        }]

        const response = await fetchAllPostPromisedData(`${endpoint.concessionFiles}`, request, 'post');
        if (response?.code === 200) {
            setSelectedDoc(null)
            setUploading(false)
            // getApplicationDetails()
            getConcessionDetails()
            successToast(response.message)
        } else {
            setSelectedDoc(null)
            setUploading(false)
            // failureToast("some")
        }
    }

    // logic for attach


    const handleFileSelect = (name, id) => {
        setSelectedDoc({ name: name, id: id })
        fileInputRef.current.click();
    };
    const handleFileChange = (e) => {
        setUploading(true)
        const allowedExt = ['jpeg', 'png', 'pdf', 'jpg'];
        try {
            const currentFile = e.target.files?.[0];
            const currentFileName = currentFile.name;
            const currentFileSize = currentFile.size;
            if (!/^[^.]*\.[^.]+$/.test(currentFileName)) {
                failureToast("File name error. Please rename file without special characters and upload");
                setUploading(false)
                return
            }
            if (allowedExt.indexOf(currentFileName.split(".")[1]) > -1) {
                if (currentFile.name.length <= 100) {
                    let value = currentFileName.split(".")[0];

                    if (regex.alphaNumericWithSpaceAndUnderscore.test(value)) {
                        if (currentFileSize && (currentFileSize) / (1024 * 1024) > 10) {
                            failureToast(`File Size error. Please upload file not exceeding 10 MB size`)
                            setUploading(false)
                            return
                        } else {
                            // setSelectedFile(currentFile)
                            getFilePath(currentFile)
                        }
                    } else {
                        failureToast("File name error. Please rename file without special characters and upload")
                        setUploading(false)
                        return
                    }
                } else {
                    failureToast("File name error. Please rename file not exceeding 100 characters.")
                    setUploading(false)
                    return
                }
            } else {
                failureToast(` File Format error. Please upload file in ${allowedExt.join(', ')} format only`)
                setUploading(false)
                return
            }
        } catch (e) {
            console.log(e)
        }
    };

    const handleVerify = async () => {
        let canContinue = true;
        if (documentPending()) {
            canContinue = await confirm();
        }
        if (!canContinue) return
        setLoading(true);
        const feebreakupTotalCal = {
            netPayable: getTotal(finalizeCharges?.total ?? [], ['netAmt']),
            totalMrp: getTotal(finalizeCharges?.total ?? [], ['totalGross']),
            totalConcession: getTotal(finalizeCharges?.total ?? [], ['conAmt'])
        }
        const darftSummary = getDraftSummaryData(applicationDetails, finalizeCharges, feebreakupTotalCal, businessAreas, courses, paymentDetails?.loanSchemeMasterId);
        const feeBreakup = getFeeBreakup();
        updateQuestionStatus('VERY', darftSummary, {}, {}, feeBreakup, captureRef, applicationQuotationDetailsId, () => { setInfoModalOpen(true);  getApplicationDetails(); getPaymentDetails();handleVerifyChargesDialog(false);setInfoModalOpen(true); }, 
        ()=>{
            setLoading(false) ;
            setIsOpen(state => ({ ...state, finalTab: true })) 
        }
        )
    }

 const getConcessionYear = (concessionMethodType)=>{
    if(!concessionMethodType) return null
 const yearMap =   {
        "lumpsum_first_year":1,
        "lumpsum_year_on_year":studentCurrentYear,
        "lumpsum_full_course":"*"
        
    }
  
        return yearMap[concessionMethodType.toLowerCase()]
 }
 
    const calculateTotalForKey = (keys, concessionMethodType, isLumpSumCategory) => {
        
        let total = 0;
        
        // Iterate through the data and calculate the total for the specified key
        for (const date in finalizeCharges?.data) {
            for (const item of finalizeCharges?.data[date]) {
                 
                if (keys.includes(item.itemType.slice(-2))) {
                    if(isLumpSumCategory){
                       const year =   getConcessionYear(concessionMethodType)
                         if(year === "*"){
                          total += item.baseAmount;
                         }
                         if(year === item.year){
                          total += item.baseAmount;
                         }
                    }else{
                        total += item.baseAmount;
                    }
                }
            }
        }

        return total;
    }



    const getKeysForTotal = (object) => {
         
        let total = 0
        const itemTypeMapping = {
            tutionFeeConcession: '01',
            classFeeConcession: '05',
            regFeeConcession: '29',
            adminFeeConcession: '30',
            techFeeConcession: '04'
        }

        for (const key in itemTypeMapping) {
            // const mappedKey = itemTypeMapping[key];
            const value = object[key];
            // Check if the value is greater than 0
            if (value > 0) {
                if (object?.concessionParameter === "PERCENTAGE") {
                    const tot = calculateTotalForKey([itemTypeMapping[key]],object?.concessionMethodType , object?.isLumpSumCategory);
                    total += (tot * value) / 100
                } else {
                    total += value
                }
            }
        }
        concessionTotal += + total ?? 0
        return total?.toFixed(2)
    }

    const getConcessionbreakup = (conc) => {
        const FEE_COMPONENTS = [
            { label: 'Registration fees', beName: 'regFeeConcession' },
            { label: 'Admission fees', beName: 'adminFeeConcession' },
            { label: 'Tuition fees', beName: 'tutionFeeConcession' },
            { label: 'Class fees', beName: 'classFeeConcession' },
            { label: 'Tech fees', beName: 'techFeeConcession' },
        ];
        const concbreak = FEE_COMPONENTS.map(i => { return <div>{i.label}: {conc.concessionParameter === "AMOUNT" ? '₹' : ''} {conc[i.beName]} {conc.concessionParameter === "PERCENTAGE" ? '%' : ''}</div> })
        return concbreak
    }

    const openPopOver = (data) => {
        setProcessInsID(data?.[0]?.processInstanceId)
        setPreview(data)
    }

    const handleClose = () => {
        setPreview(null)
    }

    useEffect(() => {
        concessionTotal = 0
    }, [])


    const getCategoryInfo = async() => {
        const calls = [];
        concessionDetails.forEach(el => {
            if(el?.isDocMandatory === undefined){
                if(el?.concessionSubSubCategoryId){
                    calls.push(fetchAllPromisedData(`${endpoint.subSubCategory}/${el?.concessionSubSubCategoryId}`))
                }
                if(el?.concessionSubCategoryId){
                    calls.push(fetchAllPromisedData(`${endpoint.subCategory}/${el?.concessionSubCategoryId}`))
                }
            }
        })

        const p = await  Promise.all([...calls]);
        console.log(p)
        setDocStatus(p)
        setLoadingCategoryInfo(false);

    }

    useEffect(() => {
        if(concessionDetails.length){
            getCategoryInfo()
        }
    },[concessionDetails])

  

    return (
        <>
        <Card className="mb-0 verify-details">
            <div className='d-flex justify-content-between align-items-center'>
                <span className='large-text'>Verify details</span>
                <Tooltip title="Close"><IconButton onClick={() => handleVerifyChargesDialog(false)}><FaTimes /></IconButton></Tooltip>
            </div>
            <div className='d-flex justify-content-start align-items-center px-3 tab pt-3'>
                <span className={`${activeTab === 0 ? 'active text-bold large-semi-bold' : ''} pb-2 px-4 cursor`} onClick={() => setActiveTab(0)}>Concession</span>
                <span className={`${activeTab === 1 ? 'active text-bold large-semi-bold' : ''} pb-2 px-4 cursor`} >Documents checklist</span>
            </div>
            {activeTab === 0 ? <div className='Concession'>
                <div className='py-3 pr-3 d-flex '>
                    <div className='d-flex flex-column col-2'>
                        <span className='regular-bold color-grey-90'> PSID </span>
                        <span className='regular-large-bold color-dark'>{applicationDetails?.psid ?? '-'}</span>
                    </div>
                    <div className='d-flex flex-column col-2'>
                        <span className='regular-bold color-grey-90'> Name </span>
                        <span className='regular-large-bold color-dark'>{applicationDetails?.studentName ?? '-'}</span>
                    </div>
                    <div className='d-flex flex-column col-7'>
                        <span className='regular-bold color-grey-90'> Course ID </span>
                        <Tooltip title={`${applicationDetails?.courseId ?? ''} | ${courses?.find(i => i.value == applicationDetails?.courseId)?.label ?? ''}`}>
                            <span className='regular-large-bold color-dark ellipsis-text-container'>
                                {`${applicationDetails?.courseId ?? ''} | ${courses?.find(i => i.value == applicationDetails?.courseId)?.label ?? ''}`}
                            </span>
                        </Tooltip>

                    </div>
                </div>

                <div className='d-flex flex-row fees-concession-details pr-3'>
                    <div className='col-6 fees-details'>
                        <span className='regular-large-bold color-dark'>Fees details</span>
                        <div className='d-flex justify-content-between pt-3'>
                            <span className='regular-bold color-grey-90'> Course fee (Exc. taxes)</span>
                            <span className='regular-large-bold color-dark'>{`: ₹ ${getTotal(finalizeCharges?.total ?? [], ['totalGross'])} `}</span>
                        </div>
                        <div className='d-flex justify-content-between pt-3'>
                            <span className='regular-bold color-grey-90'> Course fee (Inc. taxes)</span>
                            <span className='regular-large-bold color-dark'>{`: ₹ ${Math.round(getTotal(finalizeCharges?.total ?? [], ['totalGross', 'totalTax']))} `}.00</span>
                        </div>
                        <div className='d-flex justify-content-between pt-3'>
                            <span className='regular-bold color-grey-90'> Total concession </span>
                            <span className='regular-large-bold color-dark'>{`: ₹ ${getTotal(finalizeCharges?.total ?? [], ['conAmt'])} `}</span>
                        </div>
                        <div className='d-flex justify-content-between pt-3'>
                            <span className='regular-bold color-grey-90'> Total paid </span>
                            <span className='regular-large-bold color-dark'> : Nil</span>
                        </div>
                        <div className='d-flex justify-content-between pt-3'>
                            <span className='regular-bold color-grey-90'>Total payable (Exc. taxes) </span>
                            <span className='regular-large-bold color-dark'>{`: ₹ ${getTotal(finalizeCharges?.total ?? [], ['totalPayAmt'])} `}</span>
                        </div>
                        <div className='d-flex justify-content-between pt-3'>
                            <span className='regular-bold color-grey-90'> Total payable (Inc. taxes)</span>
                            <span className='regular-large-bold color-dark'>{`: ₹ ${getTotal(finalizeCharges?.total ?? [], ['netAmt'])} `}</span>
                        </div>

                    </div>
                    <div className='concession-details col-6'>
                        <div className='p-3'>
                            <span className='regular-large-bold color-grey-90'> Concession details</span>
                        </div>
                        <table className='mt-2 w-100'>
                            <tr>
                                <th className='regular-bolder color-grey-90'>Code</th>
                                <th className='regular-bolder color-grey-90'>Name</th>
                                <th className='regular-bolder color-grey-90'>Concession</th>
                                <th className='regular-bolder color-grey-90'>Amount</th>
                            </tr>
                            {
                                concessionDetails.map((conc, index) => {
                                    return (conc?.concessionType === "CUSTOM" && !['L1_REJECT','L2_REJECT','L3_REJECT'].includes(conc?.concessionApprovalStatus) && conc?.concessionApprovalStatus !== 'REVOKE') || conc?.concessionType === "DEFINED" ? <tr key={`${conc?.applicationQuotationDetailsId}-${index}`}>
                                        <td className='regular-bold color-grey-90'>{conc?.concessionCategoryCode}</td>
                                        <td className='regular-bold color-grey-90'>{conc?.concessionType === "CUSTOM" ? conc?.concessionType : getConcessionName(conc?.concessionSubCategoryId, conc?.concessionSubSubCategoryId)}</td>
                                        <td className='regular-bold color-grey-90'>
                                            <Tooltip title={getConcessionbreakup(conc)}>
                                                <div className='pointer'>
                                                    View
                                                </div>
                                            </Tooltip>
                                        </td>
                                        <td className='regular-bold color-grey-90'>{getKeysForTotal(conc)}</td>
                                    </tr> : null
                                })
                            }

                            <tr className='total'>
                                <th>Total</th>
                                <th></th>
                                <th>-</th>
                                <th>{concessionTotal ? `₹ ${concessionTotal?.toFixed(2)}` : ''}</th>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className='pt-3'>
                    <CustomButton
                        className={'regular-large outline-btn btn-left-0 text-bold'}
                        content={'Close'}
                        outline
                        onClick={() => handleVerifyChargesDialog(false)}
                        permissionType={'R'}
                        permissionSet={'R'}
                    />
                    <CustomButton
                        className={'regular-large text-bold'}
                        content={'Confirm & Next'}
                        onClick={() => setActiveTab(1)}
                        permissionType={'R'}
                        permissionSet={'R'}
                    />
                </div>
            </div> :
                <div className='document-checklist'>
                    <div className='table-wrapper'>
                        <table >
                            <tr className=''>
                                <th className='regular-bolder label-color' >Document type<Divider variant="middle" className='hr-style' /></th>
                                <th className='regular-bolder label-color'>Status <Divider variant="middle" className='hr-style' /></th>
                                <th className='regular-bolder label-color'>Mandatory <Divider variant="middle" className='hr-style' /></th>
                                <th className='regular-bolder label-color'>Document<Divider variant="middle" className='hr-style' /></th>
                                <th className='regular-bolder label-color'>Action <Divider variant="middle" className='hr-style' /></th>
                            </tr>
                            {
                                concessionDetails.map((conc, index) => {
                                    return (conc?.concessionType === "CUSTOM" && !['L1_REJECT','L2_REJECT','L3_REJECT'].includes(conc?.concessionApprovalStatus) && conc?.concessionApprovalStatus !== 'REVOKE') || conc?.concessionType === "DEFINED" ?  <tr className='my-2' key={`${conc?.applicationQuotationDetailsId}-${index}`}>
                                        <td className='label-color'>{conc?.concessionType === "CUSTOM" ? conc?.concessionType : getConcessionName(conc?.concessionSubCategoryId, conc?.concessionSubSubCategoryId)}</td>
                                        <td><div className={`checklist-status label-color d-flex regular-small ${conc?.concessionDocuments?.length ? 'completed-status' : 'pending-status'}`}>{conc?.concessionDocuments?.length ? 'Completed' : 'Pending'}</div></td>
                                        <td ><div className='mandetory label-color regular-small'>{conc?.concessionType === "CUSTOM" ? docRequiredForCustomConcession ? 'Yes' : 'No' : getDocUploadRequired(conc)}</div></td>
                                        <td><span className={`regular-small ${conc?.concessionDocuments?.length ? 'pointer' : 'cursor-not-allowed'}`} onClick={conc?.concessionDocuments?.length ? () => openPopOver(conc?.concessionDocuments) : () => { }}>View</span></td>
                                        <td><div>
                                            <CustomButton
                                                className={'regular-small BTNbgChange'}
                                                content={uploading && (selectedDoc?.name === conc?.concessionType || selectedDoc?.name === getConcessionName(conc?.concessionSubCategoryId, conc?.concessionSubSubCategoryId)) ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : 'Attach'}
                                                onClick={() => { handleFileSelect(conc?.concessionType === "CUSTOM" ? "CUSTOM" : getConcessionName(conc?.concessionSubCategoryId, conc?.concessionSubSubCategoryId), conc?.id) }}
                                                forTable={true}
                                                permissionType={'C'}
                                                permissionSet={'C'}
                                                disabled={conc?.concessionDocuments?.length}
                                            />
                                        </div></td>
                                    </tr> : null
                                })
                            }

                            {/* <tr>
                                <td className='label-color'>Photograph</td>
                                <td><div className='checklist-status label-color regular-small d-flex pending-status '>Pending</div></td>
                                <td ><div className='mandetory label-color regular-small'>No</div></td>
                                <td><div> <CustomButton
                                    className={'regular-small BTNbgChange'}
                                    content={uploading && selectedDoc === 'Photograph' ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : 'Attach'}
                                    onClick={() => { handleFileSelect('Photograph') }}
                                    forTable={true}
                                    permissionType={'C'}
                                    permissionSet={'C'}
                                /></div></td>
                            </tr>
                            <tr>
                                <td className='label-color'>High school/12th Marksheet</td>
                                <td><div className='checklist-status label-color regular-small d-flex pending-status'>Pending</div></td>
                                <td ><div className='mandetory label-color regular-small'>No</div></td>
                                <td><div> <CustomButton
                                    className={'regular-small BTNbgChange'}
                                    content={uploading && selectedDoc === 'High school/12th Marksheet' ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : 'Attach'}
                                    onClick={() => { handleFileSelect('High school/12th Marksheet') }}
                                    forTable={true}
                                    permissionType={'C'}
                                    permissionSet={'C'}
                                /></div></td>
                            </tr>
                            <tr>
                                <td className='label-color  '>Aadhar/ID proof</td>
                                <td><div className='checklist-status label-color regular-small d-flex pending-status'>Pending</div></td>
                                <td ><div className='mandetory label-color regular-small'>No</div></td>
                                <td><div> <CustomButton
                                    className={'regular-small BTNbgChange'}
                                    content={uploading && selectedDoc === 'Aadhar' ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : 'Attach'}
                                    onClick={() => { handleFileSelect('Aadhar') }}
                                    forTable={true}
                                    permissionType={'C'}
                                    permissionSet={'C'}
                                /></div></td>
                            </tr> */}
                        </table>
                    </div>
                   {!allMandatoryDocSubmitted() ?  <div className='note d-flex flex-column color-purple-90'>
                        <span className='regular-bolder'>Document verification pending:</span>
                        <span className='regular-bold'>Some of the mandatory documents are pending. Please upload the documents to verify charges</span>
                    </div> : null}
                    <div className='pt-3'>
                        <CustomButton
                            className={'regular-large br-8 outline-btn btn-left-0'}
                            content={'Go Back'}
                            outline
                            onClick={() => setActiveTab(0)}
                            permissionType={'R'}
                            permissionSet={'R'}
                            disabled={loading}
                        />
                        <CustomButton
                            className={'regular-large br-8'}
                            content={loading ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : 'Confirm & verify details'}
                            onClick={handleVerify}
                            permissionType={'R'}
                            permissionSet={'R'}
                            disabled={!allMandatoryDocSubmitted() || loading}
                        />
                    </div>
                </div>}
            <div
                ref={captureRef}
                style={{
                    position: "absolute",
                    left: "-99999px",
                    top: "-99999px",
                    maxWidth: "800px",
                }}
            />
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />

            <Dialog open={preview?.length ?? false} onClose={handleClose} maxWidth={false}>
                <Box className='concession-approval-dialog-content' width={'88.2vw'}>
                    <Box className='approval-dialog-header'>
                        <Typography variant='h6'>{'Documents'}</Typography>
                        <div onClick={handleClose}><img src={closeIcon} /></div>
                    </Box>
                    {preview?.length > 0 && <Box>
                        <Tabs value={processInsID} onChange={(e, val) => setProcessInsID(val)} variant="scrollable" scrollButtons="auto">
                            {preview?.map((file) => (<Tab key={file.processInstanceId} label={file.fileName} value={file.processInstanceId} />))}
                        </Tabs>
                        {processInsID ? <DocPreview fileType={preview.find(i => i.processInstanceId === processInsID)?.fileName?.split('.')?.[1]} uploadedKey={preview.find(i => i.processInstanceId === processInsID)?.uploadedFileKey} /> : null}
                    </Box>}
                </Box>
            </Dialog>
            <ConfirmationDialog message={"Some documents are pending to be uploaded. Are you sure you want to continue?"} nextButtonText={"Proceed"} backButtonText={"Discard"} />
            
        </Card>
       
    </>
    )
}

export default VerifyCharges