import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import CustomChip from "views/pages/manage/common/customChip/CustomChip";
import StudentPhoto from "views/pages/manage/edpOps/studentDetails/studentPhoto";
import { ReactComponent as BackIcon } from "assets/img/svg/BackArrow.svg"
import { academicCardInfoLabels } from "views/pages/manage/gateAttendance/constant";
import { detailsRowStyles } from "views/pages/manage/edpOps/constant/student-details";
import { getCourseDetailsV2 } from "views/pages/manage/edpOps/stockManagement/service";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import moment from "moment";
import { fetchAllPostPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "views/pages/manage/common/constant";
import {ReactComponent as TickIcon} from "assets/img/svg/Tick.svg";
import './style.scss';
import { fetchAllPromisedData } from "../common/utils/methods/commonMethods/utilityMethod";

const GateAttendanceDetailCard = (
  {
 
    studentData,
    fetchApplication,
    setStudentDetailVisible
  }
)=>{
  const [courseDetails, setCourseDetails] = useState({});
  const [apiLoader, setApiLoader] = useState(false);
  const [isMarked, setMarked] = useState(false);
  const [markedTime, setMarkedTime] = useState(null);

  const fetchCourseDetails = async () => {
    setApiLoader(true);
    const res = await getCourseDetailsV2(studentData?.courseId);
    setCourseDetails(res);
    setApiLoader(false);
  };
  useEffect(() => {
    fetchCourseDetails();
   fetchOfflineAttendance();
  }, [])

  const fetchOfflineAttendance= async ()=>{
    const requestParam ={
      psid_list:studentData?.psid,
      punch_date:moment().format('YYYY-MM-DD')
    }
    const header={
      'Content-Type': 'application/json',
      'request_id':Date.now().toString(36),
      'clientId': 'PHX'
     }
     const response=await fetchAllPromisedData(
      `${endpoint?.attendanceDetails?.fetchOfflineAttendanceStatus}?psid=${requestParam?.psid_list}&&punch_date=${requestParam?.punch_date}`,
      true,
      true,
      header,
      'attendance'
    );
    try{
      if(response?.code==200){
          setMarked(response?.data?.present)
          setMarkedTime(response?.data?.time)
      }
    }
    catch(error){
      console.log(error)
    }
    
  }

  const markAttendance=async()=>{
  const payLoad={"AttendancePunch":[ { PSID: studentData?.psid,
    PUNCHDATE: moment().format('YYYY-MM-DD'),
    PUNCHTIME: moment().format('HH:mm'),
   }]}

   const header={
    'Content-Type': 'application/json',
    'request_id':Date.now().toString(36),
    'clientId': 'PHX'
   }

    const markResult = await fetchAllPostPromisedData(
      endpoint?.attendanceDetails?.gateAttendance,
      payLoad,
      "post",
      true,
      header,
      'attendance'
    );

    if(markResult?.code===200){
      setMarked(true);
      setMarkedTime(moment())
    }
  }

  return (<div className="d-flex flex-column min-page-height attendance-gate-tab-margin attendance-gate-mobile-margin">
    <Card className="mb-1 mt-0">
      <CardHeader className='custom-extra-padding'style={{position:'sticky',top:'44px',zIndex:'5'}}><BackIcon style={{ cursor: 'pointer' }} onClick={()=>setStudentDetailVisible(false)} /></CardHeader>
      <CardBody className='custom-extra-padding'>
        <div style={{ gap: "24px" }} className="d-flex  align-items-start">
          <div>
            <StudentPhoto photoKey={studentData?.photoKey} fetchApplications={fetchApplication} psid={studentData?.psid} isUpdate={false}/>
          </div>
          <div className="color-grey-80" >
          <CustomChip
                text={isMarked?"Present":"Absent"}
                textColor={isMarked?'#07794C':'#DC2804'}
                bgColor={isMarked?'#E6F4ED':'#FCEEED'}
                style={{ fontSize: 12,fontWeight:'400px', fontFamily: 'Gilroy-SemiBold' }}
              />
            <div style={{  fontSize: "20px" ,fontWeight:'400px', fontFamily: 'Gilroy-Bold'}}>{studentData?.studentName}</div>
            <div style={{  fontSize: 14,fontWeight:'400px', fontFamily: 'Gilroy-SemiBold' }}>
              PSID: {studentData?.psid}
            </div>
            <div style={{ fontSize: 14, fontWeight:'400px',fontFamily: 'Gilroy-SemiBold' }} className="color-grey-80">
              App ID: {studentData?.applicationId}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
    <Card className="mb-1 mt-0 color-grey-80">
      <CardBody className='custom-extra-padding'>
      <div  className="w-100 " style={{ fontSize: 14, fontWeight:'400px',fontFamily: 'Gilroy-SemiBold' }} >{studentData?.courseId}-{courseDetails?.courseDescription}</div>
      </CardBody>
    </Card>
    <Card className="mb-1 mt-0 flex-grow-1">
      <CardBody className='custom-extra-padding'>
      {academicCardInfoLabels()?.map(infoRow => (
                  <Row style={{...detailsRowStyles,marginBottom:"5px"}} key={infoRow["objKey"]}>
                    <Col col="5">{infoRow["title"]}</Col>
                    <Col className ="text-bold" col="5" style={{ fontWeight: 600 }}>
                      {infoRow?.valueGetter
                        ? infoRow?.valueGetter(studentData)
                        : (studentData?.[infoRow["objKey"]] || "")
                            ?.toString()
                            ?.trim() || "-"}
                    </Col>
                  </Row>
                ))}
      </CardBody>
    </Card>
    
    <Card style={{padding:'12px 20px'}}className="d-flex flex-shrink-0 w-100 h-100 attendance-gate-mobile-bottom-margin attendance-gate-tab-bottom-margin">
            {isMarked&&<div style={{ fontSize: 14, lineHeight: '24px', paddingBottom:'10px'}}className="d-flex align-items-center justify-content-center gilroy-medium"><span> Marked present on <span className="text-bold">"{moment().format('DD-MM-YYYY')}"</span> at <span className="text-bold">"{moment(markedTime).format('hh:mm A')}"</span></span></div>}
    <AButton
        variant={!isMarked?"primary_filled":"success_filled"}
        onClick={()=>{!isMarked&&markAttendance()}}
        className="button_remove_left_margin"
        updatedStyle={isMarked?{cursor:'not-allowed'}:''}  
      >
        {!isMarked?'Mark Attendance':<span style={{gap:"8px"}}>Marked present <TickIcon/></span>}
    </AButton>
    </Card>
    </div>
  );
};

export default GateAttendanceDetailCard;
