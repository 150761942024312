import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Card, CardBody } from 'reactstrap'
import { ReactComponent as BackIcon } from '../../../../../assets/img/svg/BackButton.svg'
import commonStyles from '../common/commonStyles.module.css'
import AButton from '../../common/form-fields-mui/AButton'
import { outlineButtonStyles } from '../constant/common'
import AInput from '../../common/formFeilds/customInput/AInput';
import APopover from '../../common/a-popover';
import { CiFilter } from 'react-icons/ci'
import TestsSearchFilterForm from './TestsSearchFilterForm'
import { endpoint, pages } from '../../common/constant'
import { PermissionContext, RolePermissions } from 'appContext'
import { checkPermission, fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod'
import ImportTestForm from "./ImportTestForm";
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper'

const highlightedStyle = {
  border: "1px solid #00B0F5",
  color: "#00B0F5",
};
const normalStyle = {
  border: "1px solid #E9ECEF",
};

const TestsSearchFilter = ({
  setIsFormOpen,
  filterCount,
  filterForm,
  inputHandler,
  applyNameFilterHandler,
  clearNameFilterHandler,
  applyFilterHandler,
  removeFilterHandler,
  dropdowns,
  academicGroup,
  setImportedTests,
  importedTests,
  saveImportedTests = ()=>{},
  setTestIdsToBeImported = ()=>{},
  planId,
  testsArray,
  form,
  setForm
}) => {

  const userPermissions = RolePermissions(useContext(PermissionContext), pages['searchAcademicPlan']['id']);
  const [businessAreaIds, setBusinessAreaIds] = useState([])
  const history = useHistory();

   useEffect(()=>{
    const loadBusinessArea = async() =>{
      const response = await fetchAllPromisedData(`${endpoint.businessArea.getAllActive}`);
      if(response && Array.isArray(response)){
        setBusinessAreaIds(response.map(list=>list?.id))
      }
    }
    loadBusinessArea()
   },[])

  const onKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      applyNameFilterHandler();
    }
  };

  const onCancelImport = () =>{
      setImportedTests([]);
      setTestIdsToBeImported([]) ;
      setForm({})
  }
  return (
    // <div>
    <Card className="mb-1">
      <CardBody className={`py-2 px-3`}>
        <div className="d-flex align-items-center justify-content-between">
       {importedTests.length > 0  ? 
         <div className="d-flex align-items-center">
            <div>
              <BackIcon onClick={onCancelImport} />
            </div>
            <h3 className="mb-0" style={{ fontSize: "20px" }}>
              Apply Existing Test            
           </h3>
          </div>
        :
          <div className="d-flex align-items-center">
            <div>
             <IconButtonWrapper className="mr-1" onClick={() => history.goBack()} > <BackIcon /> </IconButtonWrapper> 
            </div>
            <h3 className="mb-0" style={{ fontSize: "20px" }}>
              Tests
            </h3>
          </div>
       }

       


        {!importedTests.length> 0 && <div className="d-flex align-items-center">
            <AInput
              type="text"
              placeholder={"Search here"}
              value={filterForm?.testName}
              searchIcon
              crossIcon
              handler={(value) => inputHandler(value, "testName")}
              onKeyDown={onKeyDown}
              crossHandler={() => {
                inputHandler("", "testName");
                clearNameFilterHandler();
              }}
              searchIconClickHandler={applyNameFilterHandler}
              style={{ width: "200px", border: "1px solid rgb(233, 236, 239)" }}
            />
            <APopover
              buttonComponent={(onClick, styles) => (
                <AButton
                  onClick={onClick}
                  updatedStyle={filterCount > 0 ? highlightedStyle : styles}
                >
                  <CiFilter />
                  Filter {filterCount > 0 && <>( {filterCount} )</>}
                </AButton>
              )}
              menuComponent={(closeFilterForm) => (
                <TestsSearchFilterForm
                  closeFilterForm={closeFilterForm}
                  filterForm={filterForm}
                  filterCount={filterCount}
                  inputHandler={inputHandler}
                  removeFilterHandler={removeFilterHandler}
                  applyFilterHandler={applyFilterHandler}
                  dropdowns={dropdowns}
                />
              )}
              maxHeight={"calc(100% - 150px)"}
              highlightedStyle={highlightedStyle}
              normalStyle={normalStyle}
            />
          </div>
        
        }
          <div className="d-flex align-items-center">
          
          {importedTests?.length>0  && 
            <AButton
            variant="outline"
            updatedStyle={{border:"2px solid #DC2804", color:"#DC2804"}}
             onClick={onCancelImport} >Cancel Import</AButton>
          }

          {importedTests?.length>0 ? 
            <AButton
            variant="primary_filled"
             onClick={saveImportedTests}
            >Import Tests</AButton>
          : <APopover
              buttonComponent={(onClick, styles) => (
                <AButton
                  className="ml-2"
                  variant="outline"
                  onClick={onClick}
                >
                  Import Test(s)
                </AButton>
              )}
              menuComponent={(closeForm) => 
                     <ImportTestForm {
                       ...{academicGroup, setImportedTests, 
                        closeForm, planId, businessAreaIds, 
                        testsArray, form, setForm
                      }}
                   />
                 }
            />}
           {!importedTests.length  >  0 && <AButton
              variant="primary_filled"
              onClick={() => setIsFormOpen(true)}
            >
              Add Test
            </AButton>
         }
          </div>
         
        </div>
      </CardBody>
    </Card>
    // </div>
  );
};

export default TestsSearchFilter;
