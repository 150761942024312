import React from "react";
import { Dialog } from "@mui/material";
import { ReactComponent as Warning } from "../../../../../assets/img/svg/DangerWarning.svg";
import { ReactComponent as CrossIcon } from "../../../../../assets/img/svg/CrossIcon.svg";
import AButton from "../../common/form-fields-mui/AButton";

import { outlineButtonStyle } from "./style";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper";



const DataLostConfirmation = ({handleClose,open ,goBackWithDataLost}) => {
  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={{ padding: "1.5rem", width: "24rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "2rem",
            marginBottom: "10px",
            color: "black",
          }}
        >
         <Warning/>
          <IconButtonWrapper onClick={handleClose}><CrossIcon  /></IconButtonWrapper>
        </div>
        <div>
          <div
            style={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "24px",
              color: "#000000",
              marginBottom: ".6rem",
            }}
          >
            All information will be lost.{" "}
          </div>
          <div
            style={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#414141",
              marginBottom: ".6rem",
              padding: "10px",
            }}
          >
            Are you sure you want to go back?
          </div>
        </div>

        <div className="mt-2 d-flex align-items-center justify-content-between">
          <AButton
            onClick={handleClose}
            className="mt-2"
            variant="outline"
            updatedStyle={outlineButtonStyle}
          >
            No
          </AButton>
          <AButton
            onClick={goBackWithDataLost}
            className="mt-2"
            variant="outline"
            updatedStyle={outlineButtonStyle}
          >
            Go Back
          </AButton>
        </div>
      </div>
    </Dialog>
  );
};

export default DataLostConfirmation;
