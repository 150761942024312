import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import DayMasterTable from "./dayMasterTable";
import CustomLoader from "../../common/commonComponents/Loader/loader";
import { masterServiceBaseUrl, failureToast, getAPI } from "services/http";
import { Container, Button } from "reactstrap";
import { GetPagination } from "../../common/commonComponents/pagination/pagination";

const DayMaster = (props) => {
  const [classArray, setclassArray] = useState([]);
  const [apiloader, setapiloader] = useState(false);
  const [state, setstate] = useState([]);
  const [serverData, setServerData] = useState([]);
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const pageSize = 10;
  const fetchClasses = async (url) => {
    setapiloader(true);
    getAPI(
      url,
      (data) => {
        setstate(data["data"]);
        setServerData(data["data"]);
        setPagination(data["data"]);
      },
      (data) => {
        failureToast(data["message"]);
        setapiloader(false);
      }
    );
  };

  const setPagination = (paramsdata) => {
    let n = pagination;
    if (paramsdata.length > pageSize) {
      n["nextPage"] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n["totalPage"] = Math.floor(paramsdata.length / pageSize);
    if (paramsdata.length % pageSize != 0) {
      n["totalPage"] += 1;
    }
    setpagination(n);
    setclassArray(
      paramsdata.slice(
        pagination.currentPage * pageSize - pageSize,
        pagination.currentPage * pageSize
      ) || []
    );
    setapiloader(false);
  };

  

  useEffect(() => {
    fetchClasses(`${masterServiceBaseUrl}/dayMaster/getAllDayMaster`);
  }, []);

  return (
    <>
      {apiloader ? (
        <div
          className="mx-auto text-center py-5 my-5"
          style={{ height: "100vh" }}
        >
          <CustomLoader apiLoader={apiloader} />
        </div>
      ) : (
        <>
          <DayMasterTable
            fetchClasses={fetchClasses}
            setclassArray={setclassArray}
            classArray={classArray}
            isSaveVisible={isSaveVisible}
            setisSaveVisible={setisSaveVisible}
            serverData={serverData}
          />
          {pagination.totalPage > 1 ? (
            <GetPagination
              isSaveVisible={isSaveVisible}
              fetchClasses={fetchClasses}
              setclassArray={setclassArray}
              pagination={pagination}
              setpagination={setpagination}
              pageSize={pageSize}
              state={state}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default DayMaster;
