import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import CustomButton from '../../../../../components/CustomButton';
import ACopyToClipboard from '../ACopyToClipboard/ACopyToClipboard';
import CustomLoader from '../commonComponents/Loader/loader';
import CommonInput from '../formFeilds/input/commonInput';

const StudentDetailsCard = (props) => {
  const [values, setValues] = useState(props.details);
  const [isReadOnly, setIsReadOnly] = useState(true);

  useEffect(() => {
    setValues(props.details);
  }, [props.details]);
  
  const onChange = (key, val) => {
    setValues({ ...values, [key]: val });
  }

  return (
    <Card className='student-details-card'>
      <CardHeader>
        <h3>Student details</h3>
      </CardHeader>
      <CardBody>
        {values.loading ? <CustomLoader /> : <>
          <Row>
            <Col md="3">
              <CommonInput
                type="number"
                mandatory={true}
                defaultValue={values.psid}
                onChange={(val) => { onChange("psid", val) }}
                label="PSID"
                readOnly={isReadOnly}
                isDisabled={true}
                readOnlyComp={
                  <h3>
                    {values.psid}
                    {values.psid && <ACopyToClipboard text={values.psid}/>}
                  </h3>
                }
              />
            </Col>
            <Col md="3">
              <CommonInput
                type="text"
                mandatory={true}
                defaultValue={values.studentName}
                onChange={(val) => { onChange("studentName", val) }}
                label="Name"
                readOnly={isReadOnly}
              />
            </Col>
            <Col md="3">
              <CommonInput
                type="number"
                mandatory={true}
                defaultValue={values.phoneNo}
                onChange={(val) => { onChange("phoneNo", val) }}
                label="Phone number"
                readOnly={isReadOnly}
                readOnlyComp={
                  <h3>
                    {values.phoneNo}
                    {values.phoneNo && <ACopyToClipboard text={values.phoneNo}/>}
                  </h3>
                }
              />
            </Col>
            <Col md="3">
              <CommonInput
                type="text"
                mandatory={true}
                defaultValue={values.email}
                onChange={(val) => { onChange("email", val) }}
                label="Email"
                readOnly={isReadOnly}
              />
            </Col>
          </Row>
        </>}
      </CardBody>
    </Card>
  )
}

export default StudentDetailsCard;