import React from "react";
import { Card, CardHeader,Input, Container, Button, Table, Row } from "reactstrap";
import { useState } from "react"
import { failureToast} from '../../common/utils/methods/toasterFunctions/function'
import {FollowUpData ,FollowUpHeader } from "../../common/commonComponents/masterRow/masterRow";
import { masterServiceBaseUrl, successToast, putpost, putpost2, deleteAPI } from 'services/http'
import moment from 'moment';

var _ = require('lodash');
  
const DepartmentTypeTable = (props) => {
  const [isSaveVisible, setisSaveVisible] = useState(false)
  const [tableDataKey, settableDataKey] = useState('tableDataKey')
  const  [updatedKey, setUpdateKey] = useState(new Date());
  const addNewClass = () =>{
    props.setclassArray([{"editMode":true,"__status":"__new",'status':'ACTIVE',"followUpStatusDesc": null,"followUpStatusDispValue": null, "effectiveDate": null },...props.classArray])
  }
  const saveAll = (cb) =>{
    var _tempArray = props.classArray
    var isValid = true
    // let alphanumericSpecialChar = /^[ A-Za-z0-9_@./#&+-]*$/
    let alphanumericSpecialChar = /^[ A-Za-z0-9-._]*$/
    let alphanumeric = /^[a-zA-Z0-9\.]*$/
    _tempArray.forEach((el,index)=>{
      if(el['editMode']){
        if(el['followUpStatusDesc'] === null || !el['followUpStatusDesc'].trim()){
          isValid= false
          failureToast('Please enter the value of Description')
          return
        }
        else if(!alphanumericSpecialChar.test(el['followUpStatusDesc'])){
          isValid= false
          failureToast('Please enter the valid value of Description')
          return
        }
        else if(el['followUpStatusDispValue'] === null || !el['followUpStatusDispValue'].trim()){
          isValid= false
          failureToast('Please enter the value of Display Value')
          return
        }
        else if(!alphanumeric.test(el['followUpStatusDispValue'])){
          isValid= false
          failureToast('Please enter the valid value of Display Value')
          return
        }
        if(!isValid){
          return
        }
        if (_tempArray[index]['__status'] == "__new") {
          putpost(masterServiceBaseUrl + '/class/createFollowUpStatus', (data) => {
            successToast(data['message'])
            setisSaveVisible(false)
            setUpdateKey(new Date());
            props.fetchClasses(masterServiceBaseUrl + '/class/getAllFollowUpStatus')
          }, (data) => { failureToast(data['message']) }, _tempArray[index], 'post')
        } else {
          putpost(masterServiceBaseUrl + '/class/updateFollowUpStatus/' + _tempArray[index].id, (data) => {
            successToast(data['message'])
            setisSaveVisible(false)
            delete _tempArray[index]['editMode']
            props.setclassArray(_tempArray)
            settableDataKey(new Date().getMilliseconds())
            // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
          }, (data) => { failureToast(data['message']) }, _tempArray[index], 'put')
        }
        } else {
  
        }
      })
  }
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes")
    }
    else {
      let cb = () => {
        addNewClass()
        setisSaveVisible(true)
        // settableDataKey(new Date().getMilliseconds())
      }
      var _tempArray = props.classArray
      _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
      props.setclassArray(_tempArray)
      setisSaveVisible(false)
      cb()
    }
  }
  const editRow = (index)=>{
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes")
    }
    else {
      let cb = () => {
        var _tempArray = props.classArray
        _tempArray[index]['editMode'] = true
        props.setclassArray(_tempArray)
        setisSaveVisible(true)
      }
      var _tempArray = props.classArray
      _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
      cb()
    }
  }
  const deleteRow = (index) => {
    if (props.classArray[index]["__status"] == "__new") {
      let n = props.classArray
      n.splice(index, 1)
      props.setclassArray(n)
      setisSaveVisible(false)
      settableDataKey(new Date().getMilliseconds())
    } else {
      if (window.confirm('Are you sure you want to delete this Department Type?')) {
        putpost2(masterServiceBaseUrl + '/class/deleteFollowUpStatus/' + props.classArray[index].id, (data) => {
          successToast(data['message'])
          if (props.classArray.length == 1) {
            props.previousPage()
            props.fetchClasses(masterServiceBaseUrl + '/class/getAllFollowUpStatus')
          }
          else {
            var _tempArray = props.classArray
            if (_tempArray[index]['editMode']) {
              setisSaveVisible(false)
            }
            _tempArray.splice(index, 1)
            props.setclassArray(_tempArray)
          }
          
          settableDataKey(new Date().getMilliseconds())
        }, (data) => { failureToast(data['message']) }, 'Delete')
      }
    }
  }
  const updateKey = (index,value,key)=>{
    var _tempArray = props.classArray
    _tempArray[index][key] = value
    props.setclassArray(_tempArray)
    setUpdateKey(new Date());
  }
  return (
    <>  
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master -  OS Follow Up Status</h3>
                <Button disabled={isSaveVisible}  color="info" size="sm" type="button" className={"floatRight mx-1"+(isSaveVisible?" btn-dark":null)} onClick={() => {newRow()}} data-testid="addNewFollowUpStatus">Add New</Button>
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout" >
                  <FollowUpHeader type={"follow Up Status"} />
                  <tbody className="list" key={tableDataKey} >
                    {props.classArray ? props.classArray.map((el, index) => 
                    !el['editMode']?(
                      <FollowUpData type={"followUpStatus"} index={index} el={el} editRow={editRow} deleteRow={deleteRow} />
                    ):
                    (<tr key={index+'-class'}>
                      <td className="text-center p-2 mx-1"><Input  maxLength="4" disabled defaultValue={el["followUpSerialNo"]}  placeholder="Example:1" className="tdInput" data-testid='followUpStatusKey'/></td>
                      <td className="text-center p-2 mx-1">
                        <Input maxLength="50" value={el['followUpStatusDesc']} onChange={(e) => {
                            updateKey(
                                index,
                                e.target.value.replace(/[^a-zA-Z 0-9._-]/g,''),
                                'followUpStatusDesc',
                                true
                            );
                        }}
                        placeholder="Description" 
                        className="tdInput" 
                        data-testid='followUpStatusDesc'/>
                      </td>
                      <td className="text-center p-2 mx-1">
                        <Input maxLength="10" 
                          value={el['followUpStatusDispValue']} 
                          onChange={(e) => {
                            updateKey(
                                index,
                                e.target.value.replace(/[^a-zA-Z0-9\.]/g,''),
                                'followUpStatusDispValue',
                                true
                            );
                        }}
                        placeholder="Display Value" 
                        className="tdInput" 
                        data-testid='followUpStatusDispValue'/>
                      </td>
                      <td className="text-center p-2 mx-1"><Input disabled={true} defaultValue={el["__status"] == "__new" ? null : moment(el['effectiveDate']).format('DD-MM-YYYY')} placeholder="Example:01-01-2020" className="tdInput" data-testid='effectiveDate'/></td>
                      <td className="text-center p-2 mx-1">
                      <label className="custom-toggle mx-auto ml-2">
                      <input checked={el.status == "ACTIVE" ? true : false} type="checkbox" id={el.id} onChange={(e) => { settableDataKey(new Date().getMilliseconds());  let n = "ACTIVE"; if (!e.target.checked) { n = "INACTIVE" } updateKey(index, n, 'status') }}
                       data-testid='followUpStatus' />
                          <span style={{width:"72px"}} className="custom-toggle-slider rounded-circle activeToggle" data-label-off="Inactive" data-label-on="Active" />
                          </label>
                      </td>
                      <td className="text-center">
                        {/* <Button color="info" size="sm" type="button" onClick={() => {
                          deleteRow(index)
                        }}>  <i className="fas fa-trash" /></Button> */}
                         {isSaveVisible ? <> <Button color="info" size="sm" type="button" className="mx-1" onClick={() => { saveAll()}} data-testid='saveFollowUpStatus'
                             >Save</Button>
                            <Button color="danger" size="sm" type="button"
                              data-testid="closedDepartmentType"
                             onClick={() => {
                              let n=props.classArray
                              if(n[index]['__status']=='__new'){
                                n.splice(index,1)
                              }else{
                              // n[index]['editMode']=false
                              props.fetchClasses(masterServiceBaseUrl+'/class/getAllFollowUpStatus')
                              }
                              props.setclassArray(n)
                              setisSaveVisible(false)
                            }}>  <i className="fas fa-times" /></Button> </>:
                              <Button color="info" size="sm" type="button" onClick={() => {
                                deleteRow(index)
                              }}>  <i className="fas fa-trash" /></Button>}
                      </td>
                    </tr>)
                    ) : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
export default DepartmentTypeTable;