import React from "react";
import { Dialog } from "@mui/material";
// import { ReactComponent as Warning } from "../../../../../../assets/img/svg/DangerWarning.svg";
import { ReactComponent as CrossIcon } from "../../../../../../assets/img/svg/CrossIcon.svg";

import AButton from "../../../common/form-fields-mui/AButton";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper";

const AddTestTimetableCnf = ({ handleClose, open, batchName, openAddTest }) => {
  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={{ padding: "1.5rem", width: "24rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            fontSize: "2rem",
            marginBottom: "10px",
            color: "black",
          }}
        >
          {/* <Warning /> */}
          <IconButtonWrapper onClick={handleClose}>
            <CrossIcon />
          </IconButtonWrapper>
        </div>
        <div>
          <div
            style={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "32px",
              color: "#000000",
              marginBottom: ".6rem",
            }}
          >
            {" "}
            Create test for batch <br />
            {batchName} ?
          </div>
          <div
            style={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#414141",
              marginBottom: ".6rem",
              //   padding: "10px",
            }}
          >
            This test will be only visible to the <br /> students of the above
            mentioned batch.{" "}
          </div>
        </div>

        <div className="mt-2 d-flex align-items-center">
          <AButton
            onClick={handleClose}
            className="mt-2 button_remove_left_margin"
            variant="outline"
          >
            Go back
          </AButton>
          <AButton
            onClick={openAddTest}
            className="mt-2"
            variant="primary_filled"
          >
            Yes add new test{" "}
          </AButton>
        </div>
      </div>
    </Dialog>
  );
};

export default AddTestTimetableCnf;
