import React, { useEffect, useState } from "react";
import { Card, CardBody, Row } from "reactstrap";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import { ReactComponent as Warning } from "assets/img/svg/WarningRed.svg";
import IssueStockMiscFlow from "../stockConsumption/form/IssueStockMiscFlow";
import { PAGE_STATE, useGetContext } from "../contextApi";
import PageHeader from "../stockConsumption/PageHeader";
import {
  getIssueStockRequest,
  useCourseDetails,
  useFetchMaterialsDropdown,
} from "./service";
import CustomChip from "views/pages/manage/common/customChip/CustomChip.jsx";
import { chipStyle } from "../stockTxns/style";
import { getCurrentDate } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { issueStockApi } from "../service";
import ALoader from "../../../common/a-loader";
import {
  failureToast,
  successToast,
} from "../../../common/utils/methods/toasterFunctions/function";
import moment from "moment";

const IssueStockMiscForm = () => {
  const { applicationMisc, setApplicationMisc, setCurrentPageState } = useGetContext();
  const [materialDropdown, isFetching] = useFetchMaterialsDropdown(
    applicationMisc?.courseId,
    applicationMisc?.businessArea
  );
  const [courseDetails, isLoadingCourse] = useCourseDetails(applicationMisc?.courseId);
  const [loading, setLoading] = useState(false);
  const [row, setRow] = useState({
    courseId: applicationMisc?.courseId,
    courseName: courseDetails?.courseName,
    barcode: "",
    materialDescription: "",
    materialNumber: "",
    openForm: true,
  });
  
  const [error, setError] = useState({ isError: false, errorMgs: "" });
  const [disableAllButtons, setDisableAllButtons] = useState(false);
  
  const saveRowHandler = (barcode, openForm=false) => {
    setRow({ ...row, openForm: openForm, barcode });
  };
  useEffect(() => {
    setRow(prev => ({...prev, courseName: courseDetails?.courseName}))
  }, [courseDetails])

  // const updateRowHandler = (data, barCodes = []) => {
  //   const { id, materialId, quantity, availableCount } = data;
  //   const barcodeList = barCodes.slice(0, quantity);
  //   // find material id index
  //   const index = selectedMaterials.findIndex(
  //     (item) => item?.materialId === materialId
  //   );
  //   if (index === -1) return false;
  //   const tempMaterials = [...selectedMaterials];
  //   tempMaterials[index] = {
  //     ...materialId,
  //     id,
  //     barcodeList,
  //     quantity,
  //     qty: barCodes.length,
  //     availableCount,
  //   };
  //   setSelectedMaterials(tempMaterials);
  // };
  const issueStockHandler = async () => {
    // console.log(getIssueStockRequest(applicationMisc, row));
    // return;
    setLoading(true);
    const response = await issueStockApi(getIssueStockRequest(applicationMisc, row));
    if (response?.code === 200) {
      successToast(`Stock issued successfully. Your branch Transaction ID: ${response?.data?.transactionId} and SAP document number: ${response?.data?.documentId}`);
      setDisableAllButtons(true);
    } else {
      failureToast(` ${response?.message} \n 
      Transaction ID : ${response?.data.transactionId} failed. Retry.`);
    }
    setLoading(false);
  };
  return (
    <>
      <PageHeader
        title="Issuance for misc. course ID"
        onClickHandler={() => {setCurrentPageState(PAGE_STATE.SEARCH_STUDENT); setApplicationMisc({})}}
      >
        <div className="mr-2 d-flex gap-md">
          <CustomChip style={chipStyle} className="body-regular">
            Name: {applicationMisc?.studentName}
          </CustomChip>
          <CustomChip style={chipStyle} className="body-regular">
            PSID: {applicationMisc?.psid}
          </CustomChip>
          <CustomChip style={chipStyle} className="body-regular">
            Application ID: {applicationMisc?.applicationId}
          </CustomChip>
          <CustomChip style={chipStyle} className="body-regular">
            Receipt date: {moment(applicationMisc?.receiptDate).format('DD-MMM-YYYY')}
          </CustomChip>
        </div>
      </PageHeader>

      <Card className="m-3">
        {(loading || isFetching || isLoadingCourse) && <ALoader position="fixed" />}
        <CardBody>
          <Row style={{ justifyContent: "space-between" }}>
            <span className="heading-4 color-dark">
              Select material that you want to issue
            </span>
          </Row>
          <div className="table-border-material mb-0">
            <IssueStockMiscFlow
              row={row}
              setRow={setRow}
              saveRowHandler={saveRowHandler}
              businessArea={applicationMisc.businessArea}
              courseName={courseDetails?.courseName}
              // updateRowHandler={updateRowHandler}
              loading={loading}
              setLoading={setLoading}
              setError={setError}
              materialDropdown = {materialDropdown}
              disableAllButtons={disableAllButtons}
            />
          </div>
          {error.isError && (
            <Row className="warning-svg ml-0 align-items-center">
              <Warning />
              <span
                style={{
                  color: "#B32306",
                  fontSize: "14px",
                  marginLeft: "5px",
                }}
              >
                {error.errorMgs}
              </span>
            </Row>
          )}
          <Row style={{ justifyContent: "space-between" }}>
            <AButton
              variant="primary_filled"
              className="mt-3"
              onClick={issueStockHandler}
              disabled={!row?.barcode || row?.openForm || disableAllButtons}
            >
              Issue Stock
            </AButton>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default IssueStockMiscForm;
