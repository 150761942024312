import React from 'react'
import { ReactComponent as CheckIcon } from 'assets/img/svg/fi_check.svg'
import { ReactComponent as DangerDisabledIcon } from "assets/img/svg/DangerWarningDisabled.svg";
import { Tooltip } from '@mui/material';
import { isValidPhotoKey } from '../constant';

const PhotoCellComponent = ({ params }) => {
  return (
    <div>
      {isValidPhotoKey(params?.value) ? (
        <CheckIcon height={24} width={24} />
      ) : (
        <Tooltip title={'To print the ID card for this student, please get the photo uploaded for this student from Campus Community in PSERP'}>
          <DangerDisabledIcon height={24} width={24} />
        </Tooltip>
      )}
    </div>
  )
}

export default PhotoCellComponent