var AGridColDef = /** @class */ (function () {
    function AGridColDef(field, headerName, sortable = true) {
        this.type = 'string';
        this.width = 200;
        this.field = field;
        this.headerName = headerName;
        this.flex = 1;
        this.sortable = sortable;
        this.sort = 'desc';
        this.align = 'left'
        this.headerAlign = 'left'
    }
    AGridColDef.prototype.sort = function (value) {
        this.sortable = value;
        return this;
    };
    AGridColDef.prototype.colType = function (value) {
        this.type = value;
        return this;
    };
    AGridColDef.prototype.renderCellComponent = function (value) {
        this.renderCell = value;
        return this;
    };
    AGridColDef.prototype.renderEditCellComponent = function (value) {
        this.renderEditCell = value;
        return this;
    };
    AGridColDef.prototype.setWidth = function (value) {
        this.width = value;
        return this;
    };
    AGridColDef.prototype.setMinWidth = function (value) {
        this.minWidth = value;
        return this;
    };
    AGridColDef.prototype.setValueGetter = function (cb) {
        this.valueGetter = cb;
        return this;
    };
    AGridColDef.prototype.columnAlign = function (value) {
        this.align = value;
        this.headerAlign = value;
        this.headerClassName = `${this.headerClassName || ''} text-${value}`;
        return this;
    };
    AGridColDef.prototype.setFlex = function (value) {
        this.flex = value;
        return this;
    };
    AGridColDef.prototype.editable = function (value) {
        this.editable = value;
        return this;
    };
    AGridColDef.prototype.setHeaderClassName = function (value) {
        this.headerClassName = `${this.headerClassName || ''} ${value}`;
        return this;
    };
    AGridColDef.prototype.setCellClassName = function (value) {
        this.cellClassName = `${this.cellClassName || ''} ${value}`;
        return this;
    };
    AGridColDef.prototype.setValueFormatter = function (cb) {
        this.valueFormatter = cb;
        return this;
    };
    AGridColDef.prototype.setSortComparator = function (cb) {
        this.sortComparator = cb;
        return this;
    };
    AGridColDef.prototype.pinColumnLast = function (value) {
        this.cellClassName = `${this.cellClassName || ''} ${value ? 'pin-last-column' : ''}`;
        this.headerClassName = `${this.headerClassName || ''} ${value ? 'pin-last-column' : ''}`;
        return this;
    };
    return AGridColDef;
}());
exports.AGridColDef = AGridColDef;