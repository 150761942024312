import React, { useContext } from 'react'
import { PermisionDenied } from '../../../../common/commonComponents';
import { PermissionContext, RolePermissions } from '../../../../../../../appContext';
import { pages } from '../../../../common/constant';
import PosMappingListing from './PosMappingListing';

const PosMappingSetup = () => {

    const userPermissions = RolePermissions(useContext(PermissionContext), pages["paymentCollectionMasterPosMapping"]["id"]);
    return (
        userPermissions
            ? <PosMappingListing />
            : <PermisionDenied />
    );
}

export default PosMappingSetup;