import React, { useEffect, useRef, useState } from 'react'
import { Dialog } from '@mui/material'
import { ReactComponent as CrossIcon } from 'assets/img/svg/CrossIcon.svg'
import CommonInput from '../../../../common/formFeilds/input/commonInput';
import CustomButton from '../../../../../../../components/CustomButton';
import { endpoint } from '../../../../common/constant';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../../../common/utils/methods/commonMethods/utilityMethod';
import { failureToast, successToast } from '../../../../common/utils/methods/toasterFunctions/function';
import { getOrdinal } from '../templates/mapping';
import './depositSlipDialog.scss';
import { FaChevronDown } from 'react-icons/fa';
import { getBusinessAreaQR } from '../common';



const DepositSlipDialog = ({ open, setOpen, applicationQuotationDetailsId, psid, pastIdSelected, getEligibleComponentList, getFileUploadUrls, concessionDetails, studentCurrentYear }) => {

  const [loading, setLoading] = useState(true);
  const [option, setOption] = useState([]);
  const [value, setValue] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const [downloading, setDownloading] = useState(false);
  const [notifing, setNotifing] = useState(false);
  const dropdownRef = useRef(null);

  const downloadComponents = async () => {
    setDownloading(true);
    for (let i = 0; i < value?.length; i++) {
      await fetchAllPostPromisedData(`${endpoint.fetchS3Url.getDownloadUrl}?key=${value[i]?.value}`, {}).then((data) => {
        window.open(`${data?.data?.url}`)
      })
    }
    successToast(`The deposit slip for the Student ${psid} is successfully downloaded.`)
    setDownloading(false);
    setOpen(false);
    // }


  }

  const notifyComponents = async () => {
    setNotifing(true);
    let ids = [];
    value.map(i => {
      if (i.label !== 'Security') {
        ids = [...ids, i.feeBreakupId?.[0]]
      }
    })

    console.log(value.filter(i => i.label === 'Security'))

    const req = {
      "quotationId": applicationQuotationDetailsId,
      "feeComponentsIds": ids,
      otherChargesIds: [value.filter(i => i.label === 'Security')?.[0]?.otherChargesId]
    }

    await fetchAllPostPromisedData(`${endpoint.notifyDepositSlip}`, req).then((data) => {
      if(data?.code){
        successToast("Parent is successfully notified with the updated Deposit slips");
      }
      setNotifing(false);
      setOpen(false);
    }).catch(e => {
      failureToast("Unable to notify user")
      console.log(e)
      setNotifing(false);
    })
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleOption = (opt) => {
    const isSelected = value.find(i => i.value === opt.value);
    if (isSelected) {
      setValue(value.filter((selectedOption) => selectedOption.value !== opt.value));
    } else {
      setValue([...value, opt]);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  const isComponentPresent = (depositSlip, component) => {
    return depositSlip.some(item => item.label === component.name);
  }

  const isComponentPresent2 = (eligibleComponents, component) => {
    return eligibleComponents.some(item => item.name === component.label);
  }

  const getAllDepositeSlip = async () => {
    const components = await fetchAllPromisedData(`${endpoint.allDepositeSlip}/${applicationQuotationDetailsId}`, true);
    if (components?.code === 200) {
      try {
        const itemTypeComponents = components?.data?.eligibleDepositSlipResponseDTOList;
        const securityFeeComponent = components?.data?.securityAttributeDepositSlip;
        if (itemTypeComponents?.length || securityFeeComponent?.netPayableAmount) {
          const depositeData = []

          if (securityFeeComponent?.depositSlipKey) {
            depositeData.push({ label: 'Security', value: securityFeeComponent?.depositSlipKey, otherChargesId: securityFeeComponent?.id });
          }


          const lumpsumValues = ['LUMPSUM_First_Year', 'LUMPSUM_Year_On_Year', 'LUMPSUM_Full_Course']
          const isLumpsum = concessionDetails?.find(i => lumpsumValues.includes(i.concessionMethodType));
          const lumpsumYear = isLumpsum?.concessionMethodType === 'LUMPSUM_First_Year' ? 1 : isLumpsum?.concessionMethodType === 'LUMPSUM_Year_On_Year' ? studentCurrentYear : isLumpsum?.concessionMethodType === 'LUMPSUM_Full_Course' ? "*" : null;

          if (isLumpsum && lumpsumYear && itemTypeComponents?.length) {
            if (lumpsumYear === "*") {
              const ids = itemTypeComponents.map(comp => comp.itemType);
              const feeBreakupId = itemTypeComponents.map(comp => comp.id);
              depositeData.push({ label: `Lumpsum`, value: itemTypeComponents?.[0]?.s3Key ?? '', itemType: ids, feeBreakupId: feeBreakupId });
            } else {
              if (lumpsumYear !== 1) {
                const regFeeComponent = itemTypeComponents?.filter(comp => comp.itemType.slice(-2) === '29');
                if (regFeeComponent?.length) {
                  depositeData.push({ label: 'Registration', value: regFeeComponent?.[0]?.s3Key, itemType: [regFeeComponent?.[0]?.itemType], feeBreakupId: [regFeeComponent?.[0]?.id] });
                }


                // admin fee component logic
                const adminFeeComponent = itemTypeComponents?.filter(comp => comp.itemType.slice(-2) === '30');
                if (adminFeeComponent?.length) {
                  depositeData.push({ label: 'Admission', value: adminFeeComponent?.[0]?.s3Key, itemType: [adminFeeComponent?.[0]?.itemType], feeBreakupId: [adminFeeComponent?.[0]?.id] });
                }
              }
              const year = itemTypeComponents.filter(comp => comp.year === lumpsumYear);
              console.log(year)
              if (year?.length) {
                const ids = year.map(comp => comp.itemType);
                const feeBreakupId = year.map(comp => comp.id);
                depositeData.push({ label: `Lumpsum`, value: year[0]?.s3Key ?? '', itemType: ids, feeBreakupId: feeBreakupId });
              }

              const others = itemTypeComponents.filter(comp => comp.year !== lumpsumYear);
              const groupedData = others && others?.reduce((result, current) => {
                const dueDate = current.itemType?.slice(-4, -2);
                if (!result[dueDate]) {
                  result[dueDate] = [];
                }

                result[dueDate].push({ ...current });

                return result;
              }, {});

              Object.keys(groupedData).forEach((installmentData, i) => {
                const techFeeAmt = groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04');
                const tuitionFeeAmt = groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '01');
                const classFeeAmt = groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '05');

                if (techFeeAmt?.length || tuitionFeeAmt?.length || classFeeAmt?.length) {
                  const ids = groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(i => i.itemType);
                  const feeBreakupId = groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(i => i.id);
                  depositeData.push({ label: `${getOrdinal(ids?.[0]?.slice(-4, -2))} Installment`, value: (classFeeAmt?.[0]?.s3Key || tuitionFeeAmt?.[0]?.s3Key || techFeeAmt?.[0]?.s3Key) ?? '', itemType: ids, feeBreakupId: feeBreakupId });
                }
              })
            }

          } else {
            // Reg fee component logic
            const regFeeComponent = itemTypeComponents?.filter(comp => comp.itemType.slice(-2) === '29');
            if (regFeeComponent?.length) {
              depositeData.push({ label: 'Registration', value: regFeeComponent?.[0]?.s3Key, itemType: [regFeeComponent?.[0]?.itemType], feeBreakupId: [regFeeComponent?.[0]?.id] });
            }


            // admin fee component logic
            const adminFeeComponent = itemTypeComponents?.filter(comp => comp.itemType.slice(-2) === '30');
            if (adminFeeComponent?.length) {
              depositeData.push({ label: 'Admission', value: adminFeeComponent?.[0]?.s3Key, itemType: [adminFeeComponent?.[0]?.itemType], feeBreakupId: [adminFeeComponent?.[0]?.id] });
            }


            const groupedData = itemTypeComponents && itemTypeComponents?.reduce((result, current) => {
              // component grouping logic
              const dueDate = current.itemType?.slice(-4, -2);
              if (!result[dueDate]) {
                result[dueDate] = [];
              }

              result[dueDate].push({ ...current });

              return result;
            }, {});
            Object.keys(groupedData).forEach((installmentData, i) => {
              const techFeeAmt = groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04');
              const tuitionFeeAmt = groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '01');
              const classFeeAmt = groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '05');

              if (techFeeAmt?.length || tuitionFeeAmt?.length || classFeeAmt?.length) {
                const ids = groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(i => i.itemType);
                const feeBreakupId = groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(i => i.id);
                depositeData.push({ label: `${getOrdinal(ids?.[0]?.slice(-4, -2))} Installment`, value: (classFeeAmt?.[0]?.s3Key || tuitionFeeAmt?.[0]?.s3Key || techFeeAmt?.[0]?.s3Key) ?? '', itemType: ids, feeBreakupId: feeBreakupId });
              }
            })
          }

          


          if (!pastIdSelected) {

            const { depositeData: eligibleComponents, dateAndItemMapping } = await getEligibleComponentList(applicationQuotationDetailsId, true);

            if (eligibleComponents?.length && !pastIdSelected) {
              // isComponentPresent check s3key already present or 
              const uploadSlips = eligibleComponents.filter(component => !isComponentPresent(depositeData, component));

              if (uploadSlips?.length) {
                const qrCode = await getBusinessAreaQR(eligibleComponents?.[0]?.businessAreasId);
                const fileNameArr = eligibleComponents.map(i => i.name.split(' ').join('_') + '.pdf');
                const newObject = await getFileUploadUrls(fileNameArr, eligibleComponents, dateAndItemMapping, qrCode, true, applicationQuotationDetailsId, false, true , true);
                setOption(newObject)
              } else {
                setOption(depositeData.filter(component => isComponentPresent2(eligibleComponents, component)));
              }
            } else {
              setOption(depositeData)
             // failureToast("Payment control limit exceeded: Deposit slip failed to generate as the Student has already consumed the Payment control limit")
            }
          } else {
            setOption(depositeData)
          }
          setLoading(false);
        } else if (!pastIdSelected) {
          const { depositeData: eligibleComponents, dateAndItemMapping } = await getEligibleComponentList(applicationQuotationDetailsId, true);
          if (eligibleComponents?.length) {
            const qrCode = await getBusinessAreaQR(eligibleComponents?.[0]?.businessAreasId);
            const fileNameArr = eligibleComponents.map(i => i.name.split(' ').join('_') + '.pdf');
            const newObject = await getFileUploadUrls(fileNameArr, eligibleComponents, dateAndItemMapping, qrCode, true, applicationQuotationDetailsId, false , true, true);
            setOption(newObject)
          }
          setLoading(false);
        } else {
          failureToast("Deposit slip is not available")
        }
      } catch (e) {
        console.log(e)
        // failureToast("Sorry something went wrong.");
        setLoading(false);
        setOpen(false);
      }
    }
  }

  useEffect(() => {
    getAllDepositeSlip();
  }, [])

  const handleClose = () => setOpen(false);
  return (
    <Dialog onClose={handleClose} open={open} PaperProps={{ sx: { maxWidth: 'unset', borderRadius: '12px' } }}>
      <div className='' style={{ padding: '28px 24px', width: '600px' }}>
        {
          loading ? <div className='d-flex w-100 justify-content-center'><i className="fas fa-spinner fa-spin" aria-hidden="true"></i></div> :
            <>
              <div className='d-flex justify-content-between align-items-start mb-4'>
                <div className='heading-4 color-black'>Deposit Slip</div>
                <CrossIcon width={24} height={24} onClick={handleClose} />
              </div>
              <div className='deposit-slip'>
                {/* <CommonInput
                  label="Fees Installment"
                  type={"multiSelectWithCheckboxes"}
                  placeHolder="Select"
                  defaultValue={value}
                  onChange={(value) => { setValue(value) }}
                  data={option}
                // isDisabled={isViewOnly}
                /> */}
                <div className="dropdown" ref={dropdownRef}>
                  <div className="dropdown-input" onClick={toggleDropdown}>
                    <span>{value.length > 0 ? `${value.map(i => i.label)?.join(", ")}` : 'Select options'}</span>
                    <div className={`dropdown-arrow ${isOpen ? 'open' : ''}`}><FaChevronDown color='#757575' /></div>
                  </div>
                  {isOpen && (
                    <div className="dropdown-list">
                      {option.map((opt) => (
                        <div
                          key={opt.label}
                          className={`dropdown-option ${value.find(i => i.value === opt.value) ? 'selected' : ''}`}
                          onClick={() => toggleOption(opt)}
                        >
                          <input type="checkbox" id={`option${opt.label}`} className="checkbox" checked={value.find(i => i.value === opt.value)} readOnly />
                          <label htmlFor={`option${opt.label}`} className="checkbox-label">
                            {opt.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className='d-flex flex-row'>
                <CustomButton
                  className={"no-ml-btn outline-btn w-50"}
                  content={notifing ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : 'Notify'}
                  permissionType={'C'}
                  permissionSet={'C'}
                  onClick={notifyComponents}
                  disabled={!value.length || pastIdSelected}
                />
                <CustomButton
                  className={"outline-btn w-50"}
                  content={downloading ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : "Download"}
                  permissionType={'C'}
                  permissionSet={'C'}
                  onClick={downloadComponents}
                  disabled={!value.length}
                />
              </div>
            </>}
      </div>
    </Dialog>
  )
}

export default DepositSlipDialog