// import React, {Fragment, useEffect, useState} from "react";
// import {Card} from "reactstrap";
// import CustomLoader from "../../common/commonComponents/Loader/loader";
// import ExamGroupTable from "./examGroupTable";
// import {fetchAllData} from "../../common/utils/methods/commonMethods/utilityMethod";
// import {masterServiceBaseUrl, failureToast, getAPI} from "../../../../../services/http";
// import {GetPagination} from "../../common/pagination";

// const ExamGroup = (props) => {
//     const [apiLoader, setApiLoader] = useState(true);
//     const [isSaveVisible, setIsSaveVisible] = useState(false);
//     const [data, setData] = useState([]);
//     const [examGroupData, setExamGroupData] = useState([]);
//     const [pagination, setpagination] = useState({
//         currentPage: 1,
//         nextPage: null,
//         previousPage: null,
//         totalPage: null
//     })
//     const pageSize=10;
//     const nextPage = () => {
//         setExamGroupData(data.slice((pagination.currentPage + 1) * (pageSize) - pageSize, (pagination.currentPage + 1) * pageSize))
//         let n = pagination
//         n['currentPage'] = n['currentPage'] + 1
//         n['previousPage'] = n.currentPage
//         setpagination(n)
//     }
//     const firstPage = () => {
//         setExamGroupData(data.slice(1 * (pageSize) - pageSize, 1 * pageSize))
//         let n = pagination
//         n['currentPage'] = 1
//         n['previousPage'] = null
//         setpagination(n)
//     }
//     const lastPage = () => {
//         setExamGroupData(data.slice((pagination.totalPage) * (pageSize) - pageSize, (pagination.totalPage) * pageSize))
//         let n = pagination
//         n['currentPage'] = n.totalPage
//         n['previousPage'] = n.totalPage-1
//         setpagination(n)
//     }
//     const previousPage = () => {
//         if(pagination.currentPage!=1){
//             setExamGroupData(data.slice((pagination.currentPage-1) * (pageSize) - pageSize, (pagination.currentPage-1) *pageSize  ))
//             let n = pagination
//             n['currentPage'] = n['currentPage'] -1
//             if(n['currentPage']==1){
//                 n['previousPage'] = null
//             }else{
//                 n['previousPage'] = n.currentPage
//             }
//             setpagination(n)
//         }
//     }

//     useEffect(() => {
//             getAPI(
//                 masterServiceBaseUrl + '/examGroup/getAllExamGroup',
//                 (data) => {
//                     setData(data.data);
//                     setApiLoader(false);
//                 },
//                 (data) => {
//                     failureToast(data["message"]);
//                 }
//             );
//     }, [])

//     useEffect(() => {
//         let tempArray = [];
//         if(data.length===0){
//             setExamGroupData([]);
//         }
//         if (data && data.length > 0) {
//             let n = pagination
//             if (data.length > pageSize) {
//                 n['nextPage'] = n.currentPage + 1
//             }
//             if (n.currentPage > 1) {
//                 n.previousPage = n.currentPage - 1
//             }
//             n['totalPage'] =Math.floor( data.length / pageSize)
//             if(data.length%pageSize!=0){
//                 n['totalPage']+=1
//             }
//             setpagination(n)
//             data.map((item) => {
//                 if(tempArray.length<10){
//                     tempArray.push({...item, isEdit: false, isNew: false})
//                 }
//             })
//             setExamGroupData(tempArray);
//             setApiLoader(false);
//         }
//     }, [data])

//     return (
//         <>
//             {apiLoader ?
//                 <Card style={{margin: '12px 20px'}}>
//                     <CustomLoader apiLoader={apiLoader} loaderHeight={'200px'} loaderWidth={'100% '}/>
//                 </Card>
//                 :
//                 <Fragment>
//                     <Card style={{margin: '12px 20px'}}>
//                         <ExamGroupTable setData={setData} isSaveVisible={isSaveVisible}
//                                         setIsSaveVisible={setIsSaveVisible} examGroupData={examGroupData}
//                                         setExamGroupData={setExamGroupData}/>
//                     </Card>
//                     {pagination.totalPage>1 ?
//                         <GetPagination
//                             pagination={pagination}
//                             previousPage={previousPage}
//                             firstPage={firstPage}
//                             lastPage={lastPage}
//                             nextPage={nextPage}
//                         />
//                         :null}
//                 </Fragment>
//             }
//         </>
//     )
// }

// new page frmo here
import React, { useState, useEffect, useContext } from "react";
import ExamGroupTable from "./examGroupTable";
import { failureToast } from "../../common/utils/methods/toasterFunctions/function";
import { GetPagination } from "../../common/commonComponents/pagination/pagination";
import { PermissionContext, RolePermissions } from "../../../../../appContext";
import { constants, endpoint, pages } from "../../common/constant";
import { PermisionDenied } from "../../common/commonComponents";
import CustomLoader from "../../common/commonComponents/Loader/loader";
import { fetchAllPromisedData } from "../../common/utils/methods/commonMethods/utilityMethod";
import { getPaginatedData } from "../../common/utils/methods/commonMethods/paginationMethod";

const ExamGroup = () => {
  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(
    permissions,
    pages['examGroup']['id']
  );

  const [classArray, setclassArray] = useState([])
  const [state, setstate] = useState([])
  const [apiloader, setapiloader] = useState(false)
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [pagination, setpagination] = useState(constants.pagination)
  const pageSize=10;

  const fetchClasses = async (url) => {
    setapiloader(true)
    const syncData = await fetchAllPromisedData(url,true);
    if (syncData?.code === 200) {
      setstate(syncData.data);
      getPaginatedData(syncData.data, pageSize, pagination, setpagination, setapiloader, setclassArray)
    }else{
      setstate([]);
      setclassArray([]);
      failureToast(syncData['message'])
      setapiloader(true);
    }
  }

  const getAllrecords = (toPreviousPage = false) => {
    if(toPreviousPage){
      let n = pagination;
      if(n.currentPage>1)
        n.currentPage=n.currentPage-1;
      setpagination(n)
    }
    fetchClasses(endpoint.examGroup.getAll);
  }

  useEffect(()=>{
    getAllrecords();
  },[])

  return (
    userPermissions ? (
      <>
        {apiloader ? (
          <CustomLoader 
            apiLoader={apiloader}
            loaderHeight={'200px'}
            loaderWidth={'100% '}
          />
        ) : (
          <>
            <ExamGroupTable
              getAllrecords={getAllrecords}
              fetchClasses={fetchClasses}
              setclassArray={setclassArray}
              classArray={classArray}
              permissionSet={userPermissions}
              isSaveVisible={isSaveVisible}
              setisSaveVisible={setisSaveVisible}
            />
            {pagination.totalPage>1 ? (
              <GetPagination
                isSaveVisible={isSaveVisible}
                fetchClasses={fetchClasses}
                setclassArray={setclassArray}
                pagination={pagination}
                setpagination={setpagination}
                pageSize={pageSize}
                state={state}
              />
            ) : (
              null
            )}
          </>
        )}
      </>
    ) : (
      <PermisionDenied />
    )
  )
}

export default ExamGroup;

