import React , { useState,useEffect } from 'react';
import { TaxReceiptHeader,TAXRECEIPTSTHROW } from '../../../common/commonComponents/masterRow/masterRow';
import { Container, Row, Col, Button, Card, CardBody, CardHeader, Table,Input,Label} from 'reactstrap';
import Select2 from 'react-select2-wrapper';
import { GetPagination } from '../../../common/pagination';
import Loader from 'react-loader-spinner';

import { getAPI, masterServiceBaseUrl, successToast,failureToast, putpost,deleteAPI } from 'services/http'

const TaxReceipts = (props) => {
    const { taxCodeId,
        taxCodeType,
        getDisplayValue,
        businessUnit,
        taxReceipts,
        setTaxReceipts,
        action,
        isDisabled
    } = props;
    const [ isSaveVisible, setIsSaveVisible ] = useState();
    const [ dataChange, setDataChange ] = useState( false );
    const [taxCodeDescription,setTaxCodeDescription] = useState( '' );
    const [ apiLoader, setApiLoader ] = useState( false );
    const [pagination, setPagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null
    })
    const pageSize = 10;

  
    const fetchTaxReceipts = async ( url ) => {
      setApiLoader( true );
    getAPI(url, (data) => {
    //   console.log(data['data'])
       setTaxReceipts( data[ 'data' ] )
      let n = pagination
      if (data['data'].length > pageSize) {
        n['nextPage'] = n.currentPage + 1
      }
      if (n.currentPage > 1) {
        n.previousPage = n.currentPage - 1
      }
      n['totalPage'] =Math.floor( data['data'].length / pageSize)
      if(data['data'].length%pageSize!=0){
        n['totalPage']+=1
      }
      setPagination(n)
    //   console.log(n)
      setTaxReceipts(data['data'].slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || [])
      setApiLoader( false );
    }, ( data ) => { failureToast( data[ 'message' ] );setApiLoader( false );} )
    }
 
    const nextPage = () => {
        setTaxReceipts(taxReceipts.slice((pagination.currentPage + 1) * (pageSize) - pageSize, (pagination.currentPage + 1) * pageSize))
        // console.log(pagination)
        let n = pagination
        n['currentPage'] = n['currentPage'] + 1
        n['previousPage'] = n.currentPage
        setPagination(n)
    }
    const firstPage = () => {
        setTaxReceipts(taxReceipts.slice(1 * (pageSize) - pageSize, 1 * pageSize))
        // console.log(pagination)
        let n = pagination
        n['currentPage'] = 1
        n['previousPage'] = null
        setPagination(n)
    }
    const lastPage = () => {
        setTaxReceipts(taxReceipts.slice((pagination.totalPage) * (pageSize) - pageSize, (pagination.totalPage) * pageSize))
        // console.log(pagination)
        let n = pagination
        n['currentPage'] = n.totalPage
        n['previousPage'] = n.totalPage-1
        setPagination(n)
    }
    const previousPage = () => {
        setTaxReceipts(taxReceipts.slice((pagination.currentPage-1) * (pageSize) - pageSize, (pagination.currentPage-1) *pageSize  ))
        // console.log(pagination)
        let n = pagination
        n['currentPage'] = n['currentPage'] -1
        if(n['currentPage']==1){
        n['previousPage'] = null
        }else{
        n['previousPage'] = n.currentPage
        }
        setPagination(n)
    }
    
    const addNewRow = () => {
        try {            
            setTaxReceipts( [ {
                __status: "new",
                editMode: true,
                businessAreaId: businessUnit,
                taxCodeTypeKey: null,
                b2bTaxId: null,
                b2cTaxId: null,
                igstB2bTaxId: null,
                igstB2cTaxId: null,
                status: "ACTIVE"
            }, ...taxReceipts ] );

            setIsSaveVisible( true );

        } catch ( e ) {
            console.log("Error",e)
        }
    }

    useEffect(()=>{
        if(props.isPrevious && props.formId){
            setApiLoader(true)
            fetchTaxReceipts(`${masterServiceBaseUrl}/businessAreaBusinessUnitTaxSetupReceipts/getBusinessAreaBusinessUnitTaxSetupReceiptsByBusinessArea/${props.formId}`)
        //     getAPI(
        //         `${masterServiceBaseUrl}/businessAreaBusinessUnitTaxSetupReceipts/getBusinessAreaBusinessUnitTaxSetupReceipts/${props.formId}`,
        //         (data) => {
        //             setTaxFranchises([...data.data]);   
        //             setApiLoader(false)   
        //         },
        //         (data) => {
        //           failureToast(data['message']);
        //           setApiLoader(false)   
        //         }
        //       );
         }
    },[props.isPrevious,props.formId])

    
    useEffect(()=>{
        if(taxReceipts.length){
            return()=>{
            !props.isPrevious && props.isNew && props.saveStepsCompleted()
            }  
        }  
      },[taxReceipts])

   

    const saveAll = (index) => {
        try {

            if ( !taxReceipts[ index ]['businessAreaId'] )  return failureToast("Business Area ID not found")
            if ( !taxReceipts[ index ]['taxCodeTypeKey'] )  return failureToast("Please Select Tax Code Key")
            if ( !taxReceipts[ index ]['b2bTaxId'] )  return failureToast("Please Select B2B Tax Id")
            if ( !taxReceipts[ index ]['b2cTaxId'] )  return failureToast("Please Select B2C Tax Id")
            if ( !taxReceipts[ index ]['igstB2bTaxId'] )  return failureToast("Please Select IGST B2B Tax Id")
            if ( !taxReceipts[ index ]['igstB2cTaxId'] )  return failureToast("Please Select IGST B2C Tax Id")
           
            if ( taxReceipts[ index ][ '__status' ] === "new" ) {
                setApiLoader(true)
                putpost(masterServiceBaseUrl + '/businessAreaBusinessUnitTaxSetupReceipts/createBusinessAreaBusinessUnitTaxSetupReceipts', (data) => {
                    successToast( data[ 'message' ] );
                    fetchTaxReceipts( masterServiceBaseUrl + `/businessAreaBusinessUnitTaxSetupReceipts/getBusinessAreaBusinessUnitTaxSetupReceiptsByBusinessArea/${businessUnit}` );
                    setIsSaveVisible( false );
                    setTaxCodeDescription( '' );
                    setApiLoader( false );
                }, ( data ) => {
                    // console.log("inside the Receipts section")
                    failureToast( data[ 'message' ] );
                    setApiLoader( false );
                }, taxReceipts[ index ], 'post' )
               
                // console.log("taxReceipts[ index ]",taxReceipts[ index ])
            } else {
                setApiLoader(true)
                 putpost(masterServiceBaseUrl + `/businessAreaBusinessUnitTaxSetupReceipts/updateBusinessAreaBusinessUnitTaxSetupReceipts/${taxReceipts[index]['id']}`, (data) => {
                     successToast( data[ 'message' ] );
                     fetchTaxReceipts( masterServiceBaseUrl + `/businessAreaBusinessUnitTaxSetupReceipts/getBusinessAreaBusinessUnitTaxSetupReceiptsByBusinessArea/${businessUnit}` );
                     setIsSaveVisible( false );
                     setTaxCodeDescription( '' );
                     setApiLoader( false );
                }, ( data ) => {
                     failureToast( data[ 'message' ] );
                     setApiLoader( false );
                }, taxReceipts[ index ], 'put' )
               
            }
        } catch ( e ) {
            console.log("Error",e)
        }
    }

    const updateRecord=( value,index,property )=>{
        try {
            setApiLoader( true );
            let temp = taxReceipts;
            temp[ index ][ property ] = value;
            if ( property === "taxCodeTypeKey" ) {                
                setTaxCodeDescription( getDisplayValue( value, "taxCodeTypeDesc" ) );
            }
            setTaxReceipts( temp );
            setApiLoader( false );
        } catch ( e ) {
            console.log( "Error", e );
        }
    }

    const closeRecord = ( index ) => {
        try {
            setApiLoader( true );
            let temp = taxReceipts;
            if ( temp[ index ][ '__status' ] === "new" ) {
                temp.splice( index, 1 )
            } else {
                delete temp[index]['editMode']
            }
            setTaxReceipts( temp );
            setIsSaveVisible( false );
            setApiLoader( false );
        } catch ( e ) {
            console.log( "Error", e );
        }
        
    }

    const editRecord = (index) => {
        try {
            if ( isSaveVisible ) return failureToast( 'Please add or close the current Tax Receipts' );
            let temp = taxReceipts;
            // console.log("Tax details",taxReceipts)
            temp[ index ][ 'editMode' ] = true;
            setTaxCodeDescription( getDisplayValue( temp[ index ]['taxCodeTypeKey'], "taxCodeTypeDesc" ) );
            setTaxReceipts( temp );
            setIsSaveVisible( true );
        } catch ( e ) {
            console.log("Error : ",e)
        }
    }

    const deleteRecord = (id,index) => {
        try {            
             if (window.confirm('Are you sure you want to delete this Tax Receipt ?')) {
          deleteAPI(masterServiceBaseUrl + `/businessAreaBusinessUnitTaxSetupReceipts/deleteBusinessAreaBusinessUnitTaxSetupReceipts/${id}`, (data) => {
            successToast( data[ 'message' ] )
            let _tempArray = taxReceipts;
            _tempArray.splice( index + ( pagination.currentPage > 1 ? pagination.currentPage - 1 * 10: index), 1 );
            setTaxReceipts( _tempArray );
            setDataChange( !dataChange );
          }, (data) => { failureToast(data['message']) })}
        } catch ( e ) {
            console.log("Error",e)
        }
    }
    
    return (
       <Container className="mt-3 customContainer">
            <Row>
                <Col className={action === 'edit' ? 'mb-3 mt-3' :'mb-3'}>
                    {  action=== 'new' || !isDisabled ?<Button disabled={ isSaveVisible } color="info" size="sm" type="button" className={ "floatRight mx-1 mr-4" + ( isSaveVisible ? " btn-dark" : null ) } onClick={ () => { addNewRow() } } >Add Tax Components</Button> :null}
                </Col>
            </Row>
                { apiLoader?
                    <div className="mx-auto text-center"  style={ { height: "100px" } }>
                            <Loader type="Rings" color="#00BFFF" height={ 70 } width={ 70 } />
                    </div> :
                    <div id="questionTable" className="table-responsive">                        
                        <Table className="align-items-center table-flush tableLayout" style={{minWidth:'1200px'}} >
                            { taxReceipts && taxReceipts.length ? <TaxReceiptHeader isDisabled={isDisabled}/> :'' }                         
                            <tbody className="list" >
                                {
                                    taxReceipts && taxReceipts.map( ( item, index ) =>
                                        item[ 'editMode' ] ? (
                                            <tr key={ index }>
                                                <td className="text-center p-2 mx-1">
                                                    <Select2 className="input-height"
                                                        options={ { placeholder: "Select..." } }
                                                        value={ item.taxCodeTypeKey }
                                                        onChange={ ( e ) => {
                                                            let n = e.target.value;
                                                            updateRecord( n, index, "taxCodeTypeKey" )
                                                        } }
                                                        data={ taxCodeType }
                                                    />
                                                </td>
                                                <td className="text-center p-2 mx-1">
                                                    <Label className="customLabel white-breakSpace" style={{height: '100%'}}>{ taxCodeDescription }</Label>
                                                </td>
                                                <td className="text-center p-2 mx-1">
                                                    <Select2 data={ taxCodeId } className="tdSelect"
                                                        options={ { placeholder: "Select..." } }
                                                        value={ item.b2bTaxId }
                                                        onChange={ ( e ) => {
                                                            let n = e.target.value;
                                                            updateRecord( n, index, "b2bTaxId" )
                                                        } }
                                                    />
                                                </td>
                                                <td className="text-center p-2 mx-1">
                                                    <Select2 data={ taxCodeId } className="tdSelect"
                                                        options={ { placeholder: "Select..." } }
                                                        value={ item.b2cTaxId }
                                                        onChange={ ( e ) => {
                                                            let n = e.target.value;
                                                            updateRecord( n, index, "b2cTaxId" )
                                                        } }
                                                    />
                                                </td>
                                                <td className="text-center p-2 mx-1">
                                                    <Select2 data={ taxCodeId } className="tdSelect"
                                                        options={ { placeholder: "Select..." } }
                                                        value={ item.igstB2bTaxId }
                                                        onChange={ ( e ) => {
                                                            let n = e.target.value;
                                                            updateRecord( n, index, "igstB2bTaxId" )
                                                        } }
                                                    />
                                                </td>
                                                <td className="text-center p-2 mx-1">
                                                    <Select2 data={ taxCodeId } className="tdSelect"
                                                        options={ { placeholder: "Select..." } }
                                                        value={ item.igstB2cTaxId }
                                                        onChange={ ( e ) => {
                                                            let n = e.target.value;
                                                            updateRecord( n, index, "igstB2cTaxId" )
                                                        } }
                                                    />
                                                </td>
                                                <td className="text-center p-2 mx-1">
                                                    <Label className="custom-toggle mx-auto ml-2">
                                                        <Input checked={ item.status == "ACTIVE" ? true : false } type="checkbox"
                                                            onChange={ ( e ) => {
                                                                let n = "ACTIVE";
                                                                if ( !e.target.checked ) {
                                                                    n = "INACTIVE"
                                                                }
                                                                updateRecord( n, index, "status" )
                                                                setDataChange( !dataChange );
                                                            } } />
                                                        <span style={ { width: "72px" } } className="custom-toggle-slider rounded-circle activeToggle" data-label-off="Inactive" data-label-on="Active" />
                                                    </Label>
                                                </td>
                                                <td>
                                                    <Button size="sm" color="info" onClick={ () => saveAll( index ) }>Save</Button>
                                                    <Button size="sm" color="danger" onClick={ () => closeRecord( index ) }><i className="fas fa-times" /></Button>
                                                </td>
                                            </tr>
                                        ) : ( <TAXRECEIPTSTHROW isDisabled={isDisabled} key={ index + "_class" } deleteRecord={ deleteRecord } getDisplayValue={ getDisplayValue } item={ item } editRecord={ editRecord } index={ index } closeRecord={ closeRecord } /> )
                                    )
                                }
                            </tbody>
                        
                        </Table>
                    
                    {pagination.totalPage>1?<GetPagination fetchClasses={taxReceipts} pagination={pagination} previousPage={previousPage} firstPage={firstPage} lastPage={lastPage} nextPage={nextPage} setpagination={setPagination} />:null}
                </div>
                }
                
        </Container>
    )
}

export default TaxReceipts;