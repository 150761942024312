import React, { useEffect, useCallback, useState, useRef } from "react";
import { Card, CardBody } from "reactstrap";
import { fetchAllPostPromisedData } from "../../../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "../../../../common/constant";
import AInput from "../../../../common/formFeilds/customInput/AInput";
import CustomChip, {
  CustomChipWithDot,
} from "../../../../edpOps/batch-serach/CustomChip";
import { useGetContext } from "../../contextApi";
import { Checkbox, FormControlLabel } from "@mui/material";
import CustomColoredChip from "views/pages/manage/common/customChip/CustomChip";
import ShowMaterialWithQty from "./ShowMaterialWithQty";
import {
  MAXIMUM_MATERIAL_ALLOWED,
  MESSAGE_MATERIAL_SEARCH,
  MESSAGE_MAXIMUM_MATERIAL_ALLOWED,
  getChipBgColor,
  getChipTxtColor,
} from "../constant";
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import {
  getIssuedBarcodeByMaterialId,
  isMaterialAlreadyIssued,
  isValidBarcodeExist,
} from "../../service";
import { autoCompleterReqBody } from "../../mastersAndMapping/onBoardingMaterialIdSetup/constant";

const _ = require("lodash");

const CheckBox = ({
  checkBoxHandler,
  isChecked,
  pSid,
  material,
  disabled,
  label,
}) => {
  return (
    <FormControlLabel
      sx={{ marginBottom: "0px" }}
      control={
        <Checkbox
          size="small"
          onChange={(e) =>
            checkBoxHandler(e.target.checked ? true : false, pSid, material)
          }
          name="emailEnabled"
          checked={isChecked}
          disabled={disabled}
        />
      }
      label={label}
    />
  );
};

export const OptionCard = ({
  options,
  setSelectedMaterials,
  selectedMaterials,
  wrapperRef,
  isShowCheckBox = false,
  checkBoxHandler,
  pSid,
  checked,
  isShowChip = true,
}) => {
  const isSelected = (materialId) => {
    return selectedMaterials.some((stock) => stock?.id === materialId);
  };

  return (
    <Card style={{ zIndex: 1000 }}>
      <CardBody className="input-suggetion-wrapper">
        <div className="input-suggetions" ref={wrapperRef}>
          {options.map((option, index) => {
            const material = {
              value: `${option?.details?.materialId} | ${option?.details?.materialDescription}`,
              id: option?.details?.materialId,
              materialDescription: option?.details?.materialDescription,
              materialId: option?.details?.materialId,
              uom: option?.details?.baseUnitOfMeasure,
              serialNumberProfile: option?.details?.serialNumberProfile,
            };
            return (
              <div
                key={`${index}-${option?.id}`}
                className="d-flex align-items-center gap-xs"
              >
                <CheckBox
                  checkBoxHandler={(e) => checkBoxHandler(e, material)}
                  pSid={pSid}
                  isChecked={isSelected(option?.details?.materialId)}
                  material={material}
                />

                <div
                  key={option.facultyId}
                  className="input-suggetion-items"
                  onClick={() => setSelectedMaterials(material)}
                >
                  {material?.value}
                </div>
                {isShowChip && (
                  <CustomColoredChip
                    text="30 Left"
                    bgColor={"#BBE2CE"}
                    textColor={"#0B442D"}
                    style={{ fontSize: "12px" }}
                  />
                )}
              </div>
            );
          })}
        </div>
      </CardBody>
    </Card>
  );
};

const useOutsideClick = (ref, callback) => {
  useEffect(() => {
    const handleClickOutside = (evt) => {
      if (ref.current && !ref.current.contains(evt.target)) {
        callback(); //Do what you want to handle in the callback
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};

const SelectMaterialInput = ({
  isMaterialQtyChecked,
  setIsProcessedBtnDisabled,
  setIsCheckQuantity,
}) => {
  const wrapperRef = useRef(null);
  const [value, setValues] = useState("");
  const { setSelectedMaterials, selectedMaterials } = useGetContext();
  const [options, setOption] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isNoMatchFound, setNoMatchFound] = useState(false);
  useOutsideClick(wrapperRef, () => setVisible(false));

  const deleteMaterial = (materialId) => {
    let tempItems = [...selectedMaterials];
    setSelectedMaterials(
      tempItems.filter((tempMat) => tempMat?.materialId !== materialId)
    );
  };

  const addMaterial = (material) => {
    if (selectedMaterials?.length === MAXIMUM_MATERIAL_ALLOWED) {
      failureToast(MESSAGE_MAXIMUM_MATERIAL_ALLOWED);
      return;
    }
    // check not already not added
    const isAlreadyAdded = selectedMaterials.some(
      (option) => option?.id === material?.id
    );
    // else add
    setIsProcessedBtnDisabled(false);
    setIsCheckQuantity(true);
    if (!isAlreadyAdded)
      setSelectedMaterials((prev) => [...prev, { ...material }]);
  };

  const getMaterials = async (searchStr) => {
    setFetching(true);

    const response = await fetchAllPostPromisedData(
      `${endpoint.autoComplete.search}`,
      { ...autoCompleterReqBody(), value: searchStr },
      "put"
    );
    if (response) {
      setFetching(false);
      if (Array.isArray(response) && response.length === 0) {
        setNoMatchFound(true);
        setOption([]);
      } else {
        setOption(response);
        setNoMatchFound(false);
      }
      setVisible(true);
    } else {
      setFetching(false);
      setOption([]);
      setNoMatchFound(false);
    }
  };

  const changeHandler = (value) => {
    if (value?.length > 3) {
      getMaterials(value);
    } else {
      setOption([]);
      setNoMatchFound(false);
    }
    setValues(value);
  };

  const debouncedChangeHandler = useCallback(
    _.debounce(changeHandler, 300),
    []
  );

  const checkBoxHandler = (isChecked, curr_material) => {
    if (selectedMaterials?.length === MAXIMUM_MATERIAL_ALLOWED && isChecked) {
      failureToast(MESSAGE_MAXIMUM_MATERIAL_ALLOWED);
      return;
    }
    if (isChecked) {
      setSelectedMaterials((prev) => [...prev, curr_material]);
    } else {
      const index = selectedMaterials.findIndex(
        (material) => material.id === curr_material.id
      );
      deleteMaterial(curr_material?.materialId);
    }
    setIsCheckQuantity(true);
    setIsProcessedBtnDisabled(false);
  };
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center"></div>
      <AInput
        style={{ height: 40 }}
        crossIcon={true}
        handler={(val) => {
          debouncedChangeHandler(val);
          setValues(val);
        }}
        placeholder="Enter 4 digit/chars to search"
        searchIconClickHandler={() => debouncedChangeHandler(value)}
        crossHandler={() => {
          setValues("");
          setOption([]);
          setNoMatchFound(false);
        }}
        value={value}
        loading={fetching}
        onFocus={() => setVisible(true)}
        rightSearchIcon={true}
      />

      {isNoMatchFound && <span>{MESSAGE_MATERIAL_SEARCH}</span>}

      {options?.length && visible ? (
        <OptionCard
          setSelectedMaterials={addMaterial}
          selectedMaterials={selectedMaterials}
          checkBoxHandler={checkBoxHandler}
          options={options}
          wrapperRef={wrapperRef}
          isShowChip={false}
        />
      ) : null}

      <div className="gap-xs d-flex flex-wrap gap-2 mt-2">
        <div>
          {!isMaterialQtyChecked ? (
            selectedMaterials.map((item, index) => (
              <CustomChipWithDot
                key={item.id}
                text={item.value}
                onDelete={() => deleteMaterial(item.id)}
              />
            ))
          ) : (
            <ShowMaterialWithQty
              materials={selectedMaterials}
              deleteMaterial={deleteMaterial}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const validForReissue = (applicationMap, applicationId, materialId) => {
  console.log(applicationMap, "applicationMap");
  const findApplication = applicationMap?.filter(
    (item) =>
      item.applicationId === applicationId &&
      item.validMaterialNumberList.some(
        (validMaterial) => validMaterial === materialId
      )
  );
  return !(findApplication.length > 0);
};

const RenderDropdownOptions = ({
  options,
  getMaterialCount,
  materialAndBarcodeMapping,
  canReIssueMaterialList,
  stocksAlreadyIssued,
  isSelected,
  isATshirtAlreadySelected,
  isDisableCheckbox,
  pSid,
  isShowCheckBox = false,
  checkBoxHandler,
}) => {
  return options.map((option, index) => {
    const leftBarcode =
      getMaterialCount(option?.id) -
      getIssuedBarcodeByMaterialId(materialAndBarcodeMapping, option?.id);
    const isAlreadyIssued = isMaterialAlreadyIssued(
      stocksAlreadyIssued,
      option?.id,
      option?.isReIssued,
      pSid?.applicationId,
      canReIssueMaterialList
    );

    return (
      <div
        key={`${index}-${option?.id}`}
        className="d-flex align-items-center gap-xs w-100 justify-content-between"
      >
        <div className="d-flex align-items-center ">
          {isShowCheckBox && (
            <CheckBox
              checkBoxHandler={checkBoxHandler}
              pSid={pSid}
              isChecked={isSelected(option?.id)}
              material={option}
              disabled={isDisableCheckbox(option?.id, option?.isReIssued)}
              label={
                <div
                  style={
                    isAlreadyIssued ||
                    (!isSelected(option?.id) &&
                      isATshirtAlreadySelected(option?.id))
                      ? { color: "#AFAFAF" }
                      : leftBarcode === 0
                      ? { color: "#B32306" }
                      : {}
                  }
                  key={option.facultyId}
                  className="input-suggetion-items"
                >
                  {option?.value}{" "}
                  {option?.isReIssued && (
                    <span style={{ color: "red" }}>*</span>
                  )}
                </div>
              }
            />
          )}
        </div>
        <div>
          {!isAlreadyIssued && (
            <CustomColoredChip
              text={`${leftBarcode} Left`}
              bgColor={getChipBgColor(leftBarcode)}
              textColor={getChipTxtColor(leftBarcode)}
              style={{ fontSize: "12px" }}
            />
          )}
        </div>
      </div>
    );
  });
};

export const MaterialDropdown = ({
  options,
  stocks = [],
  wrapperRef,
  isShowCheckBox = false,
  checkBoxHandler,
  pSid,
  checked,
  stocksAlreadyIssued = [],
  materialAndBarcodeMapping,
  getMaterialCount,
  canReIssueMaterialList,
  tShirtOptions = [],
  tShirtGroupMaterials,
}) => {
  console.log(tShirtGroupMaterials);

  const isSelected = (materialId) => {
    return stocks.some((stock) => stock?.id === materialId);
  };

  const isATshirtAlreadySelected = (materialId) => {
    return stocks.some(
      (stock) =>
        tShirtGroupMaterials.includes(materialId) &&
        stock?.isTShirtGroupMaterial
    );
  };

  const isDisableCheckbox = (materialId, isReIssued) => {
    let result = false;
    // is already issued case ;
    if (
      isReIssued &&
      validForReissue(canReIssueMaterialList, pSid?.applicationId, materialId)
    ) {
      console.log("validForReissue");
      return true;
    }

    if (
      isMaterialAlreadyIssued(
        stocksAlreadyIssued,
        materialId,
        isReIssued,
        pSid?.applicationId,
        canReIssueMaterialList
      )
    ) {
      return true;
    }

    // if selected the it can we unchecked
    if (isSelected(materialId)) return false;
    // is already tShirt selected then disable other tShirt selection
    if (isATshirtAlreadySelected(materialId)) return true;
    //  at least one valid barcode check
    if (!result)
      result = !isValidBarcodeExist(materialAndBarcodeMapping, materialId);
    return result;
  };
  return (
    <Card style={{ zIndex: 1000, minHeight: "200px", marginBottom: "0px" }}>
      <CardBody className="input-suggetion-wrapper">
        <div
          style={{ height: "100%" }}
          className="input-suggetions-h100"
          ref={wrapperRef}
        >
          <RenderDropdownOptions
            options={options}
            getMaterialCount={getMaterialCount}
            materialAndBarcodeMapping={materialAndBarcodeMapping}
            canReIssueMaterialList={canReIssueMaterialList}
            stocksAlreadyIssued={stocksAlreadyIssued}
            pSid={pSid}
            checkBoxHandler={checkBoxHandler}
            isSelected={isSelected}
            isDisableCheckbox={isDisableCheckbox}
            isShowCheckBox={isShowCheckBox}
            isATshirtAlreadySelected = {isATshirtAlreadySelected}
          />
          {tShirtOptions.length > 0 && <span>Select one T-shirt size</span>}
          <RenderDropdownOptions
            options={tShirtOptions}
            getMaterialCount={getMaterialCount}
            materialAndBarcodeMapping={materialAndBarcodeMapping}
            canReIssueMaterialList={canReIssueMaterialList}
            stocksAlreadyIssued={stocksAlreadyIssued}
            pSid={pSid}
            checkBoxHandler={checkBoxHandler}
            isSelected={isSelected}
            isDisableCheckbox={isDisableCheckbox}
            isShowCheckBox={isShowCheckBox}
            isATshirtAlreadySelected = {isATshirtAlreadySelected}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default SelectMaterialInput;
