import React from "react";
import { Card, CardFooter, CardHeader } from "reactstrap";

const CustomCard = ({
  title,
  children,
  isCloseButtonRequired,
  onClickCloseButton,
  HeaderElement = () => {},
  noTitle = false,
}) => {
  return (
    <Card>
      {!noTitle && (
        <CardHeader className="d-flex justify-content-between align-items-center">
          {title ? <h3>{title}</h3> : HeaderElement}
        </CardHeader>
      )}
      <div>{children}</div>
      {/* <CardFooter className="d-flex justify-content-end">
  
        </CardFooter> */}
    </Card>
  );
};

export default CustomCard;
