import React, { useContext, useEffect, useState } from 'react'
import { endpoint } from 'views/pages/manage/common/constant'
import AButton from 'views/pages/manage/common/form-fields-mui/AButton'
import { deleteDaysPlan, dropdownMapping, getDaysPlanList, getFilterCount, searchFilterInitialForm, updateDaysPlan } from '../common'
import styles from '../styles.module.scss'
import DaysPlanList from './DaysPlanList'
import DaysPlanSearchFilter from './DaysPlanSearchFilter'
import ALoader from 'views/pages/manage/common/a-loader'
import { successToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function'
import { useHistory } from 'react-router'
import { PermissionContext, RolePermissions } from '../../../../../../appContext'
import { pages } from '../../../common/constant'
import { PermisionDenied } from '../../../common/commonComponents'
import { checkPermission, fetchAllPromisedData } from '../../../common/utils/methods/commonMethods/utilityMethod'

const SearchDaysPlan = () => {

  const userPermissions = RolePermissions(useContext(PermissionContext), pages['daysPlan']['id']);
  const history = useHistory();

  const [allActiveBusinessAreas, setAllActiveBusinessAreas] = useState([]);
  const [businessAreaData, setBusinessAreaData] = useState([]);
  const [regionData, setRegionData] = useState([]);

  const [daysPlanList, setDaysPlanList] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [listApiLoader, setListApiLoader] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [updateApiLoader, setUpdateApiLoader] = useState(false);

  const [page, setPage] = useState(0);
  const [filterPayload, setFilterPayload] = useState(searchFilterInitialForm);
  const [filterForm, setFilterForm] = useState(searchFilterInitialForm);
  const [filterCount, setFilterCount] = useState(0);

  const filterFormHandler = (value, inputKey) => {
    setFilterForm(currentData => ({ ...currentData, [inputKey]: value }));
  }

  const planListHandler = async (filterPayload, page, sgBA) => {
    setListApiLoader(true);
    let updatedPayload = {...filterPayload}
    if(filterPayload?.businessAreas?.length===0){
      updatedPayload['businessAreas'] = sgBA;
    }
    // console.log(updatedPayload);
    const res = await getDaysPlanList(updatedPayload, page);
    // console.log(res);
    if (res?.code === 200) {
      setDaysPlanList(res?.data?.daysPlanMasterSearchResponseDTOList);
      setRowCount(res?.data?.totalRecords);
    } else {
      setDaysPlanList([]);
      setRowCount(0);
    }
    setListApiLoader(false);
  }

  const fetchData = async () => {
    const regionRes = await dropdownMapping(endpoint?.region?.getAllActive, 'regionDispValue', 'id')
    setRegionData(regionRes);
  }

  const onPageChange = async (currentPage) => {
    setPage(currentPage);
    planListHandler(filterPayload, currentPage, allActiveBusinessAreas);
  }
  const applyNameFilterHandler = () => {
    setPage(0);
    const updatedPayload = { ...filterPayload };
    updatedPayload['daysPlanName'] = filterForm['daysPlanName']
    setFilterPayload(updatedPayload);
    planListHandler(updatedPayload, 0, allActiveBusinessAreas);
  }
  const clearNameFilterHandler = () => {
    setPage(0);
    const updatedPayload = { ...filterPayload };
    updatedPayload['daysPlanName'] = ''
    setFilterPayload(updatedPayload);
    planListHandler(updatedPayload, 0, allActiveBusinessAreas);
  }
  const applyFilterHandler = () => {
    setPage(0);
    setFilterCount(getFilterCount(filterForm));
    setFilterPayload(filterForm);
    planListHandler(filterForm, 0, allActiveBusinessAreas);
  }
  const removeFilterHandler = () => {
    setPage(0);
    setFilterCount(0);
    const resettedFilterForm = { ...searchFilterInitialForm, daysPlanName: filterForm?.daysPlanName }
    setFilterForm(resettedFilterForm);
    setFilterPayload(resettedFilterForm);
    planListHandler(resettedFilterForm, 0, allActiveBusinessAreas);
  }

  const onLoad = async () => {
    setApiLoader(true);
    const res = await fetchAllPromisedData(endpoint?.businessArea?.getAllActive);
    // console.log(res);
    const allBAIds = res?.map(item => item?.id);
    setAllActiveBusinessAreas(allBAIds);
    setApiLoader(false);
    planListHandler(filterPayload, 0, allBAIds);
  }

  useEffect(() => {
    fetchData();
    onLoad();
  }, [])

  const togglePlanStatusHandler = async (planData) => {
    setUpdateApiLoader(true);
    const request = {
      ...planData,
      status: planData?.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
    }
    const res = await updateDaysPlan(request, planData?.id);
    if (res?.code === 200) {
      successToast(res?.message);
      // to remain on same page page is not set to 0
      // setPage(0); 
      planListHandler(filterPayload, page, allActiveBusinessAreas);
    }
    setUpdateApiLoader(false);
  }

  const deletePlanHandler = async (planData) => {
    setUpdateApiLoader(true);
    const res = await deleteDaysPlan(planData?.id);
    if (res?.code === 200) {
      successToast(res?.message);
      setPage(0);
      planListHandler(filterPayload, 0, allActiveBusinessAreas);
    }
    setUpdateApiLoader(false);
  }

  return (
    userPermissions ? (
      <div className={`remove-container-top-space ${styles?.view_all_main_container}`}>
        {(apiLoader || listApiLoader || updateApiLoader) && <ALoader position='fixed' />}
        <div className='d-flex justify-content-between align-items-center'>
          <div className='regular-bold color-dark'>Masters / Days plan</div>
          {checkPermission(userPermissions, 'C') && (
            <AButton variant='primary_filled' onClick={() => history.push('/admin/master/daysPlan/create')}>Create days plan</AButton>
          )}
        </div>
        <div className={`${styles?.all_days_plan_conatiner}`}>
          <DaysPlanSearchFilter
            filterCount={filterCount}
            filterForm={filterForm}
            filterFormHandler={filterFormHandler}
            applyNameFilterHandler={applyNameFilterHandler}
            clearNameFilterHandler={clearNameFilterHandler}
            applyFilterHandler={applyFilterHandler}
            removeFilterHandler={removeFilterHandler}
            regionData={regionData}
            businessAreaData={businessAreaData}
            setBusinessAreaData={setBusinessAreaData}
          />
          <div className={`${styles?.data_grid_container}`}>
            <DaysPlanList
              page={page}
              onPageChange={onPageChange}
              rowData={daysPlanList}
              rowCount={rowCount}
              togglePlanStatusHandler={togglePlanStatusHandler}
              deletePlanHandler={deletePlanHandler}
            />
          </div>
        </div>
      </div>
    ) : (
      <PermisionDenied />
    )
  )
}

export default SearchDaysPlan