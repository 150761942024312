import React, { useEffect, useState } from "react";
import { Dialog, Tooltip } from "@mui/material";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import { ReactComponent as BulletPoint } from "assets/img/svg/mc-timeline-bullets.svg";
import moment from "moment";
import { getCourseDetails } from "../../service";
import ALoader from "views/pages/manage/common/a-loader";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper";

const lineStyle = {
  width: "1px",
  border: "1px solid #262626",
  position: "absolute",
  height: "100%",
  left: "44%",
  top: "20px",
};
const bottomBullet = {
  position: "absolute",
  bottom: "-4px",
  left: "0px",
};

const ViewDispatchPopup = ({
  handleClose,
  open,
  dispatchSchedular,
  dispatchScheduleList,
  
}) => {
  const [courseData, setCourseData] = useState({});
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (dispatchSchedular?.courseId && open) fetchCourse();
  }, [dispatchSchedular?.courseId, open]);

  const fetchCourse = async () => {
    setLoading(true);
    const res = await getCourseDetails(dispatchSchedular?.courseId);
    if (res?.code === 200 && res?.data) setCourseData(res?.data[0]);
    setLoading(false);
  };
  const DisplayMaterialList = ({ dispatchSchedular, isLast }) => {
    return (
      <div className="d-flex gap-lg" style={{ marginTop: "-4px" }}>
        <div
          className="icon-container"
          style={{ position: "relative", overflow: "hidden" }}
        >
          <BulletPoint />
          {!isLast && <div style={lineStyle}></div>}
        </div>
        <div>
          <div className="heading-3 color-dark">
            {dispatchSchedular?.dispatchId} |{" "}
            {moment(dispatchSchedular?.dispatchDate).format("DD-MMM-YYYY")}
          </div>
          <ul className="pl-4">
            {dispatchSchedular?.stockDispatchMaterialMappingList?.map(
              (item) => (
                <li key={item.materialNumber} className="mb-1 global-font-size">
                  {item.materialNumber} | {item?.materialDescription} |
                  {item?.materialCount} Qty
                </li>
              )
            )}
          </ul>
        </div>
      </div>
    );
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={{ padding: "1.5rem", width: "33rem" }}>
        {isLoading && <ALoader />}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "1.4rem",
            marginBottom: "10px",
            color: "black",
          }}
        >
          <span className="font-weight-bold">Dispatch schedule details </span>
          <IconButtonWrapper>
            <CrossIcon onClick={handleClose} />
          </IconButtonWrapper>
        </div>

        <div className="mb-4 d-flex">
          {dispatchSchedular?.courseId} |
          <Tooltip title={courseData?.courseName}>
            <div
              style={{ maxWidth: "200px" }}
              className="ellipsis-text-container ml-1"
            >
              {courseData?.courseName || "-"}
            </div>
          </Tooltip>
          • Term {dispatchSchedular?.termDispValue}
        </div>
        <div>
          {dispatchScheduleList.map((item, index) => (
            <DisplayMaterialList
              key={item.materialNumber}
              isLast={index + 1 === dispatchScheduleList?.length}
              dispatchSchedular={item}
            />
          ))}
        </div>
      </div>
    </Dialog>
  );
};

export default ViewDispatchPopup;
