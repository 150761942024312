import React, { useState, useEffect, useContext } from "react";
import { putpost, masterServiceBaseUrl } from "services/http";
import { useSelector, useDispatch } from "react-redux";
import Header from "./header/header";
import Building from "./building/building";
import { updateData } from '../../../../../redux/globalData/globalDataAction';
import { fetchDetails } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { fetchAllPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { getBusinessAreas, getDisplayValue } from "./../../edpOps/constant/batch-search";

// import { getDisplayValue } from './../../edpOps/constant/batch-search';
import { endpoint } from 'views/pages/manage/common/constant';
import { failureToast, successToast } from "../../common/utils/methods/toasterFunctions/function";
import { RolePermissions } from 'appContext'
import { PermissionContext } from 'appContext'
import { pages } from '../../common/constant'
import { checkPermission, dropdownMapping, fetchAllPostPromisedData, updateRequest } from "../../common/utils/methods/commonMethods/utilityMethod";
import SearchBuildingHeader from "./searchBuildingHeader/SearchBuildingHeader";
import { filterInitialForm } from "./constant";
import ALoader from "../../common/a-loader";

const BuildingMaster = () => {
  const dispatch = useDispatch();
  const buildingHeraderData = useSelector((state) => state.globalData.data);
  const [buildingData, setBuildingData] = useState([]);
  const FORM_FIELDS = {

    region: '',
    businessArea: '',
    pincode: "",
    buildingName: "",
    address: "",
    state: '',
    city: '',
    country: ''
  }
  const [headers, setHeaders] = useState({
    businessGroup: null,
    companyCode: null,
    zone: null,
    region: null,
    businessArea: null,
  });

  const userPermissions = RolePermissions(useContext(PermissionContext), pages['buildingmaster']['id']);

  const [buildingForm, setBuildingForm] = useState(FORM_FIELDS);

  const [pincodeData, setPincodeData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [countryData, setCountryData] = useState([]);

  const [regionData, setRegionData] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [stateDropdownData, setStateDropdownData] = useState([]);
  const [cityDropdownData, setCityDropdownData] = useState([]);

  const [sgBusinessAreas, setSgBusinessAreas] = useState(null);

  const [createApiLoader, setCreateApiLoader] = useState(false);
  const [getAllApiLoader, setGetAllApiLoader] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);

  // below states are for new development
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filterPayload, setFilterPayload] = useState(filterInitialForm);
  const [filterForm, setFilterForm] = useState(filterInitialForm);
  const filterFormHandler = (value, key) => setFilterForm(prev => ({ ...prev, [key]: value }));

  const [visibleCard, setVisibleCard] = useState('search');

  useEffect(() => {
    setStateDropdownData(stateData.map(item => ({ label: item?.text, value: item?.id })));
    setCityDropdownData(cityData.map(item => ({ label: item?.text, value: item?.id })));
  }, [stateData, cityData])

  useEffect(() => {
    if (buildingForm?.region?.id) {
      getBusinessAreas([buildingForm?.region])
        .then(res => setBusinessData(res))
    }
  }, [buildingForm.region])

  const fetchActiveBusinessAreas =  async () => {
    setApiLoader(true);
    const baRes = await dropdownMapping(endpoint?.businessArea?.getAllActive, 'businessAreaDispValue', 'id');
    await setSgBusinessAreas(baRes);
    setApiLoader(false);
  }

  useEffect(() => {
    if (sgBusinessAreas) {
      fetchBuildingMaster();
    }
  }, [sgBusinessAreas])


  useEffect(() => {
    fetchActiveBusinessAreas();
    fetchDetails(
      `${masterServiceBaseUrl}/pincode/getAllActivePincode`,
      setPincodeData,
      "pincode",
      "pincode"
    );
    fetchDetails(
      `${masterServiceBaseUrl}/state/getAllState`,
      setStateData,
      "state",
      "reactSelect"
    );
    fetchDetails(
      `${masterServiceBaseUrl}/city/getAllCity`,
      setCityData,
      "city",
      "reactSelect"
    );
    fetchDetails(
      `${masterServiceBaseUrl}/country/getAllCountry`,
      setCountryData,
      "country",
      "reactSelect"
    );
    fetchDetails(
      `${masterServiceBaseUrl}/region/getAllActiveRegion`,
      setRegionData,
      "region",
      "select"
    );
  }, []);

  // const fetchBuildingMaster = () => {
  //   setGetAllApiLoader(true);
  //   const URL = `${endpoint?.building?.getAllBuildingByBusinessAreaWithSecurityGroup}`
  //   return fetchAllPromisedData(URL)
  //     .then(res => {
  //       setBuildingData(res)
  //       setGetAllApiLoader(false);
  //     })
  // }
  const fetchBuildingMaster = async (page = 0, request = filterPayload) => {
    setGetAllApiLoader(true);
    setPage(page);
    const updatedPayload = { ...request }
    if (request?.businessArea?.length === 0) {
      updatedPayload['businessArea'] = sgBusinessAreas
    }
    const res = await fetchAllPostPromisedData(`${endpoint?.building?.searchBuilding}?offset=${page}`, updateRequest(updatedPayload), 'post');
    // console.log('search res', res);
    if (res?.code === 200) {
      setTotalPages(res?.data?.totalPage);
      setBuildingData(res?.data?.searchBuildingMasterDtoList);
    } else {
      setTotalPages(0);
      setBuildingData([]);
    }
    setGetAllApiLoader(false);
  }

  const searchHandler = async () => {
    setFilterPayload(filterForm);
    await fetchBuildingMaster(0, filterForm);
  }

  const resetSearchHandler = async () => {
    setFilterPayload(filterInitialForm);
    setFilterForm(filterInitialForm);
    await fetchBuildingMaster(0, filterInitialForm);
  }

  const pageChangeHandler = async (newPage) => {
    await fetchBuildingMaster(newPage - 1);
  }

  // const searchBuilding = (obj) => {
  //   const searchData = obj ?? {
  //     businessGroup: headers.businessGroup?.value,
  //     companyCode: headers.companyCode?.value,
  //     zone: headers.zone?.value,
  //     region: headers.region?.value,
  //     businessArea: headers.businessArea?.value,
  //   };

  //   putpost(
  //     masterServiceBaseUrl + "/buildingMaster/searchBuildingMaster",
  //     (data) => {

  //       setBuildingData(data.data);
  //     },
  //     (data) => {
  //       setBuildingData(null);
  //       failureToast(data["message"]);
  //     },
  //     searchData,
  //     "post"
  //   );
  // };

  // const resetHeader = () => {
  //   setHeaders({
  //     businessGroup: null,
  //     companyCode: null,
  //     zone: null,
  //     region: null,
  //     businessArea: null,
  //   })
  //   setBuildingData([])
  // }

  // const removeDataFromStorage = () => {
  //   dispatch(updateData(null))
  // }

  const getDisplayVal = (id, list) => {
    const i =
      id &&
      list?.filter((item) => {
        return item.id == id;
      });
    return i && i?.length ? i[0] : "";
  };

  useEffect(() => {
    if (buildingForm.pincode) {
      let pinCode = buildingForm.pincode

      pinCode && setBuildingForm({ ...buildingForm, state: pinCode.stateId, city: pinCode.cityId, country: pinCode.countryId })
    }
  }, [buildingForm.pincode])

  // useEffect(() => {
  //   if (buildingHeraderData?.businessAreaId?.value) {
  //     setHeaders({
  //       businessGroup: buildingHeraderData?.businessGroupId,
  //       companyCode: buildingHeraderData?.companyCodeId,
  //       zone: buildingHeraderData?.zoneId,
  //       region: buildingHeraderData?.regionId,
  //       businessArea: buildingHeraderData?.businessAreaId,
  //     });

  //     const oldObj = {
  //       businessGroup: buildingHeraderData?.businessGroupId?.value,
  //       companyCode: buildingHeraderData?.companyCodeId.value,
  //       zone: buildingHeraderData?.zoneId?.value,
  //       region: buildingHeraderData?.regionId?.value,
  //       businessArea: buildingHeraderData?.businessAreaId?.value,
  //     };
  //     // searchBuilding(oldObj);
  //     removeDataFromStorage()
  //   }
  // }, [buildingHeraderData?.businessAreaId]);

  const addBuildingFormHandler = (value, fieldName) => {
    setBuildingForm(currentForm => ({ ...currentForm, [fieldName]: value }))
  }

  const createBuildingMasterHandler = () => {

    if (!buildingForm?.region?.value) {
      failureToast("Please Select Region");
      return;
    }
    if (!buildingForm?.businessArea?.value) {
      failureToast("Please Select Business Area");
      return;
    }
    if (!buildingForm?.buildingName) {
      failureToast("Please enter the Building name");
      return;
    }
    if (!buildingForm?.address) {
      failureToast("Please enter the Address");
      return;
    }
    if (!buildingForm?.pincode) {
      failureToast("Please Select Pincode");
      return;
    }




    createBuildingMaster();
  };

  const createBuildingMaster = () => {
    setCreateApiLoader(true);
    putpost(
      `${masterServiceBaseUrl}/buildingMaster/createBuildingMaster`,
      (data) => {
        successToast(data.message);
        // fetchBuildingMaster();
        resetSearchHandler();
        setBuildingForm(FORM_FIELDS)
        setCreateApiLoader(false);
      },
      (data) => {
        failureToast(data.message);
        setCreateApiLoader(false);
      },
      {
        ...buildingForm,
        region: buildingForm.region.id,
        businessArea: buildingForm.businessArea.value,
        pincode: buildingForm.pincode.value,
        status: "ACTIVE"
      },
      "post"
    );
  }

  return (
    <>
      {apiLoader && <ALoader position={'fixed'} />}
      {checkPermission(userPermissions, 'C') && visibleCard === 'create' && (
        <Header
          headerValues={headers}
          setHeaderValues={setHeaders}
          // searchBuildingData={searchBuilding}
          setBuildingData={setBuildingData}
          // resetHeader={resetHeader}
          pincodeData={pincodeData}
          stateData={stateData}
          cityData={cityData}
          countryData={countryData}
          buildingForm={buildingForm}
          addBuildingFormHandler={addBuildingFormHandler}
          getDisplayVal={getDisplayVal}
          createBuildingMasterHandler={createBuildingMasterHandler}
          regionData={regionData}
          businessData={businessData}
          createApiLoader={createApiLoader}
          setVisibleCard={setVisibleCard}
        />
      )}

      {visibleCard === 'search' && (
        <SearchBuildingHeader
          setVisibleCard={setVisibleCard}
          regionData={regionData}
          stateData={stateDropdownData}
          cityData={cityDropdownData}
          filterForm={filterForm}
          filterFormHandler={filterFormHandler}
          searchHandler={searchHandler}
          resetSearchHandler={resetSearchHandler}
        />
      )}

      <Building
        data={buildingData}
        // searchBuilding={searchBuilding}
        headers={headers}
        regionData={regionData}
        businessData={businessData}
        fetchBuildingMaster={fetchBuildingMaster}
        getAllApiLoader={getAllApiLoader}
        page={page}
        totalPages={totalPages}
        pageChangeHandler={pageChangeHandler}
      />
    </>
  );
};

export default BuildingMaster;
