import React, { useEffect, useState } from "react";
import {
  fetchAllPostPromisedData,
  fetchAllPromisedData,
} from "../../../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "../../../../common/constant";
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import { transactionTypeMap } from "../../stockReturn/constant";

export const getFirstAvailableBarcode = (barCodes) => {
  const index = barCodes.findIndex((item) => !item?.isIssued);
  const barcodeObj = barCodes[index];
  return [barcodeObj, index];
};

export const useFetchSapCostCenter = (businessArea) => {
  const [sapCostCenter, setSapCostCenter] = useState([]);
  useEffect(() => {
    if (businessArea) fetchCostCenter();
  }, [businessArea]);

  const fetchCostCenter = async () => {
    const res = await fetchAllPostPromisedData(
      `${
        endpoint.sapProfitCostCentreMapping.searchProfitCostCenter
      }?offset=${0}`,
      {
        sapBusinessArea: businessArea?.sapBusinessArea,
        costCenterStatus: "ACTIVE",
      },
      "post"
    );
    if (res && res?.code === 200)
      setSapCostCenter(
        res?.data?.profitCostCenterList.map((item) => ({
          value: item?.costCenter,
          label: `${item?.costCenter}-${item?.profitCenterDescription}`,
        }))
      );
    // console.log(res);
  };
  return sapCostCenter;
};

export const verifyEmpId = (empId) => {
  const url = `${endpoint.sgUserProjectMapping.getSgMaster}/${empId}/PHX`;
  return fetchAllPromisedData(url, true);
};
export const employeeDetailsFormFields = {
  businessArea: "",
  businessAreaDispValue: "",
  plantId: "",
  transactionType: "",
  transactionDate: new Date(),
  reason: "",
  attachment: "",
  sapCostCenter: "",
  s3key: null,
  psid: "",
};
// employee details dropdowns

export const transactionTypeDropdown = [
  { label: "Business Promotion", value: "Business Promotion" },
  { label: "Consumption at Branch", value: "Consumption at Branch" },
  { label: "Marketing Activity", value: "Marketing Activity" },
  { label: "Stock Conversion", value: "Stock Conversion" },
  { label: "Miscellaneous", value: "Miscellaneous" },
  { label: "For Left-Out Students", value: "For Left-Out Students" },
];

// Reason Code
export const reasonCodeDropdown = [
  { label: "Damage at branch", value: "Damage at branch" },
  { label: "Damage from Vendor", value: "Damage from Vendor" },
  { label: "Duplicate Stock Issue", value: "Duplicate Stock Issue" },
  { label: "Issued to Faculty", value: "Issued to Faculty" },
  { label: "Used at Branch", value: "Used at Branch" },
  { label: "Issuance not done on time", value: "Issuance not done on time" },
  { label: "Others", value: " Others" },
];

export const statusDropdown = [
  { label: "Success", value: "success" },
  { label: "Failure", value: "FAILED" },
];

export const getDownloadFileUrl = async (key) => {
  const templateUrl = await fetchAllPostPromisedData(
    `${endpoint.fetchS3Url.getDownloadUrl}?key=${key}`,
    {}
  );
  if (templateUrl?.code === 200 && templateUrl?.data) {
    window.open(`${templateUrl?.data?.url}`);
  } else {
    failureToast("Something went wrong please try after sometime.");
  }
};

export const createRequestFormat = (
  employeeDetailsForm,
  selectedMaterials = [],
  academicCareerDispValue
) => {
  const { businessArea, transactionType, reason, s3key, psid, sapCostCenter } =
    employeeDetailsForm;

  const stock = [];
  selectedMaterials.forEach((item) => {
    const { materialId, materialDescription, uom } = item;
    item.barcodeList.forEach((barcode) => {
      stock.push({
        materialId,
        materialDescription,
        barcode: barcode?.barcode,
        uom,
      });
    });
  });

  const request = {
    businessArea: businessArea?.value,
    businessAreaDispValue: businessArea?.label,
    plantId: businessArea?.sapPlantId,
    // academicGroupDispValue: "SOE",
    academicCareerDispValue:
      academicCareerDispValue.toUpperCase() === "DIGITAL"
        ? "ICON"
        : academicCareerDispValue,
    transactionType: transactionType.value,
    businessUnitType: "Owned",
    reason: reason?.value,
    attachment: s3key,
    type: transactionTypeMap.BRANCH_ISSUANCE[academicCareerDispValue],
    costCenter: sapCostCenter?.value,
    psidStockRequest: [{ psid, stock }],
  };

  return request;
};

export const MESSAGES_STOCK = {
  QTY_GRT_AVL:
    "Quantity entered cannot be greater than the available material quantity",
  MINIMUM_QTY: "Minimum value for quantity is 1",
  MAX_QTY: "Currently max 50 barcode allocation possible",
  DUPLICATE_MATERIAL:
    "This material is already selected, please select other one",
  NO_RECORDS: "No records found for your search",
  ZERO_BARCODE: "Available quantity is not sufficient for issuance",
};
