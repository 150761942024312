import React, {useState, useContext, useEffect} from 'react';
import { Card, CardHeader, Container } from 'reactstrap';

import FormFirstAll from './formFirstAll';
import AttributeRow from './attributeRow';
import { PermissionContext, RolePermissions } from 'appContext';
import { pages } from '../../../../../common/constant';
import { PermisionDenied } from '../../../../../common/commonComponents';

const NewCourseAttr = props => {
  const { id, action } = props.match.params;
  const [step, setStep] = useState(0);
  const [formId, setFormId] = useState(null);
  const [isNew, setIsNew] = useState(true);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [customRoutePermission, setCustomRoutePermission] = useState(false);
  const permissions = useContext(PermissionContext);

  const userPermissions = RolePermissions(permissions, pages['courseAttributes']['id']);

  React.useEffect(() => {
    if (isNew === true) {
      userPermissions.includes('C') ? setCustomRoutePermission(true) : setCustomRoutePermission(false);
    }
    if (action === 'view' && id) {
      setFormId(id);
      setIsNew(false);
      setIsViewOnly(true);
      action === 'view' && userPermissions.includes('R')
        ? setCustomRoutePermission(true)
        : setCustomRoutePermission(false);
    }
    if (action === 'edit' && id) {
      setFormId(id);
      setIsNew(false);
      setIsViewOnly(false);
      action === 'edit' && userPermissions.includes('U')
        ? setCustomRoutePermission(true)
        : setCustomRoutePermission(false);
    }
  }, [id]);

  const nextStep = id => {
    if (id) {
      setFormId(id);
    }
    setStep(step + 1);
  };
  useEffect(() => {
    // console.log({step})
  },[step])

  let pageToRender = null;

  switch (step) {
    case 0:
      pageToRender = (
        <FormFirstAll nextStep={nextStep} isNew={isNew} formId={formId} userPermissions={userPermissions} />
      );
      break;
    case 1:
      pageToRender = (
        <AttributeRow formId={formId} nextStep={nextStep} isNew={isNew} userPermissions={userPermissions} />
      );
      break;
    default:
      pageToRender = <FormFirstAll />;
  }


  return (
    <>
      {userPermissions && customRoutePermission ? (
        <Container className="mt-3" fluid>
          <Card className="mb-4">
            <CardHeader>
              <h3>Course Attribute Mapping</h3>
            </CardHeader>
          </Card>
          {isNew ? (
            pageToRender
          ) : (
            <>
              <FormFirstAll isNew={isNew} formId={formId} isViewOnly={isViewOnly} userPermissions={userPermissions} />
              <AttributeRow formId={formId} isNew={isNew} isViewOnly={isViewOnly} userPermissions={userPermissions} />
            </>
          )}
        </Container>
      ) : (
        <PermisionDenied />
      )}
    </>
  );
};

export default NewCourseAttr;
