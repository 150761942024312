import React from "react";
import {Col,Button, Row} from "reactstrap";
import CustomButton from "components/CustomButton";
import store from "../redux/store";
import {SomethingWentWrongDispatch} from "../contexts/SomethingWentWrong";

export const getPermissions = (screenId=null)=>{
    let CryptoJS = require("crypto-js");
    try{
        // By Default Permission is false
        let PermissionsObject = {
            C: false,
            R: false,
            U: false,
            D: false,
            A: false,
            S: true
        };
        let GlobalState = store.getState();
        if (GlobalState && GlobalState.user && GlobalState.user.role) {
            let role = '';
            let permissions = null;
            let bytes = CryptoJS.AES.decrypt(GlobalState.user.role, 'my-secret-key@123');
            let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            // let permissions = GlobalState?.user?.role?.permissions
            if(decryptedData && decryptedData.permissions) {
                permissions = decryptedData?.permissions
            } else{
               return PermissionsObject;
            }
            if (screenId) {
                let permissionForScreen = permissions[screenId] ? permissions[screenId] : null;
                if (permissionForScreen) {
                    permissionForScreen.split(',').forEach(item => {
                        PermissionsObject[item] = true;
                    })
                    return PermissionsObject;
                } else {
                    // If No access to current Screen
                    return PermissionsObject;
                }
            } else {
                // If No Data For Roles & Permissions
            }
        }
    } catch(e){
        console.log('Error :',e)
    }
}

const TestComponent = ()=>{

    const setIsSomethingWentWrong = React.useContext(SomethingWentWrongDispatch);
    // let permissions = getPermissions('testScreen')

    return (
        <Row style={{marginLeft:'20px'}}>
            <Col md={9}>
                <Button size={'md'} onClick={()=>{setIsSomethingWentWrong(true)}} className={'reset-button'} >Reset</Button>
            </Col>
        </Row>
    )

}
export default TestComponent;