import React, { useEffect, useState } from "react";
import BackBtnWithChip from "./BackBtnWithChip";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import GetFromProgressBar from "./GetFormProgressBar";
import { Card, CardBody } from "reactstrap";
import { getLectures } from "../../lecture/helper";
import { useGetAllLecturesCount } from "../hooks";
import PublishUnpublishConfirmationDialog from "./PublishUnpublishConfirmationDialog";
import AddLectureModalForm from './AddLectureModalForm'
import AddEventModal from './AddEventModal'

const LectureSubHeader = ({
  setOpenForm,
  openMode,
  publishTimetable,
  isNeedToPublish,
  roomResponse,
  onEditSave = () => { },
  lectures,
  setLectures,
  formData,
  publishStatusTimetableHandler,
  batchDetails,
  isShowPastLecture
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const allLecturesCount = useGetAllLecturesCount(formData?.plan?.value, formData?.duration?.value);
  const currentLecturesCount = lectures?.filter((lecture) => lecture.lectureName?.toLowerCase() !== 'break')?.length ?? 0;


  return (
    <>
      <Card className="mb-2">
        <CardBody style={{ padding: "14px 20px" }}>
          <div className="d-flex align-items-center justify-content-between">
            <BackBtnWithChip setOpenForm={()=>setOpenForm("TIMETABLE")} openMode={openMode} allLecturesCount={allLecturesCount} currentLecturesCount={currentLecturesCount} />
            <GetFromProgressBar
              clickHandler={()=>setOpenForm("TIMETABLE")}
              mode={openMode} step={2}
              isLectureListEmpty ={lectures.length === 0}
            />

            {/* {openMode === "NEW" && (
              <AButton variant="primary_filled" onClick={publishTimetable}>
                Publish timetable
              </AButton>
            )} */}
            {openMode === "VIEW" && (
              <AButton disabled={true} variant="primary_filled">
                {formData?.publishStatus === "P" ? "Published" : "Unpublished"}
              </AButton>
            )}
            {/* {openMode === "EDIT" && isNeedToPublish && (
              <AButton
                // onClick={onEditSave}
                onClick={() => setIsDialogOpen(true)}
                variant="primary_filled"
              >
                Save & Publish timetable
              </AButton>
            )} */}
            {/* {openMode === "EDIT" && !isNeedToPublish && formData?.publishStatus === "P" && (
              <AButton
                // onClick={() =>
                //   publishStatusTimetableHandler(formData, "U")
                // }
                onClick={() => setIsDialogOpen(true)}
                variant="danger_filled"
              >
                Unpublish timetable
              </AButton>
            )} */}
            {/* {openMode === "EDIT" && !isNeedToPublish && formData?.publishStatus === "U" && (
              <AButton
                // onClick={() =>
                //   publishStatusTimetableHandler(formData, "P")
                // }
                onClick={() => setIsDialogOpen(true)}
                variant="primary_filled"
              >
                Publish timetable
              </AButton>
            )} */}
            {(openMode === "EDIT" || openMode === "NEW") && (
              <AButton
                variant="primary_filled"
                onClick={() => setOpenForm("REVIEW_PUBLISH")}
              >
                Save & Next
              </AButton>
            )}
          </div>
        </CardBody>
      </Card>
      <PublishUnpublishConfirmationDialog
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        formData={formData}
        isSave={isNeedToPublish}
        onEditSave={onEditSave}
        publishStatusTimetableHandler={publishStatusTimetableHandler}
      />
    </>
  );
};

export default LectureSubHeader;
