import React, { useEffect, useState } from "react";
import APopover from "../../../common/a-popover";
import { IoEyeOutline } from "react-icons/io5";
import { getCourseDetailsV2, getCoursePricingDetails } from "../service";
import ALoader from "../../../common/a-loader";
import { Col, Row } from "reactstrap";
import moment from "moment";

export const CourseDetailsWithEndDateModal = ({
  closeForm,
  rowData,
}) => {
  const {courseId, term, businessArea} = rowData || {};
  const [courseDetails, setCourseDetails] = useState({});
  const [coursePricingDetails, setCoursePricingDetails] = useState({});
  const [apiLoader, setApiLoader] = useState(false);

  const fetchData = async () => {
    setApiLoader(true);
    const p = await Promise.all([
      getCourseDetailsV2(courseId),
      getCoursePricingDetails(courseId, businessArea, term)
    ])
    setCourseDetails(p[0]);
    setCoursePricingDetails(p[1]);
    setApiLoader(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ width: '400px' }} className="p-3">
      {apiLoader && <ALoader />}
      <div className="large-semi-bold color-grey-90 mb-2">{`Course ID ${courseId}`}</div>
      <Row className="semi-bold color-grey-90 mb-1">
        <Col sm="8">Course name</Col>
        <Col sm="4">End date</Col>
      </Row>
      {apiLoader ? (
        <div className="text-center regular-body color-grey-90">Loading...</div>
      ) : (
        <Row className="regular-body color-grey-90">
          <Col sm="8">{courseDetails?.courseName}</Col>
          <Col sm="4">
            {moment(coursePricingDetails?.courseEndDate).format('DD-MMM-YYYY')}
          </Col>
        </Row>
      )}
    </div>
  );
};

const CourseDetailsWithEndDate = ({ rowData }) => {
  return rowData?.courseId ? (
    <div className="ml-2">
      <APopover
        buttonComponent={onclick => (
          <IoEyeOutline className="pointer" onClick={onclick} size={18} />
        )}
        menuComponent={closeForm => <CourseDetailsWithEndDateModal rowData={rowData} closeForm={closeForm} />}
      />
    </div>
  ) : (
    <></>
  );
};

export default CourseDetailsWithEndDate;