import moment from "moment";
import React from "react";
import CustomButton from "./customButton";
const TableList = ({ listDetails, item, children, isStatus = true }) => {
  // console.log("listdetailss>>", listDetails?.examDate);

  const getDisplayValue = (masterArray, property, id) => {
    try {
      // console.log("masterArray, property, id", masterArray, property, id);
      const value = masterArray && masterArray.find((item) => item.id == id);
      return value ? value[property] : "NOT FOUND";
    } catch (err) {
      console.log("Error :", err);
    }
  };

  const typeHandler = (details, item) => {
    try {
      switch (details.type) {
        case "BUTTON":
          return <CustomButton details={details} rowDetails={item} />;
          break;
        default:
          break;
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  return (
    <tr>
      {Object.keys(listDetails).map((el, index) => (
        <td className="text-center  white-breakSpace" key={index}>
          {/* {console.log("asdfasdfasdfsd", el, item[el], listDetails[el].format)} */}
          {listDetails[el].normal
            ? listDetails[el]?.type === "date" && item[el]
              ? moment(item[el]).format(listDetails[el].format)
              : item[el]
            : listDetails[el].hasOwnProperty("type")
            ? typeHandler(listDetails[el], item)
            : getDisplayValue(listDetails[el].master, listDetails[el].displayValue, item[listDetails[el].idName])}
        </td>
      ))}
      {isStatus ? (
        <td className="text-center  white-breakSpace">
          {item["status"] === "INACTIVE" ? (
            <button disabled={true} type="button" className="btn btn-sm btn-warning">
              Inactive
            </button>
          ) : (
            <button type="button" disabled={true} className="btn btn-sm btn-success">
              Active
            </button>
          )}
        </td>
      ) : null}
      {item ? children : null}
    </tr>
  );
};

export default TableList;
