import {  styled, Tooltip, tooltipClasses } from '@mui/material';
import React, {useEffect, useMemo, useState } from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import { Row } from 'reactstrap';
import { FEE_COMPONENTS } from '.';
import MuiTable from '../../../../common/commonComponents/MuiTable';
import moment from 'moment';
import { failureToast } from '../../../../common/utils/methods/toasterFunctions/function';
import CategorySelector from './categorySelector';
import { useParams } from 'react-router';
import {SupportingDocUploader} from './upload';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
// import { GetDisplayData } from './discretionary';
import RemarksDialogue ,{fetchRemarks} from '../../../concessionApproval/RemarksDialogue';
import { getCurrentStatus, getExtraCompValue } from './common';
import { NEW_APPROVAL_STATUS, STATUS_DATA } from './constants';
import { getFormatedDateTime } from './discretionary';

// const categoryCodeData = [
//     {label: "A", value: "A"},
//     {label: "B", value: "B"},
//     {label: "C", value: "C"},
//     {label: "S", value: "S"}
//   ];
  

export const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        maxWidth:'1000px',
    },
}));

const ScholarshipComp = (params) => {
    const { id, value, field, row, feeComps,  extraComponent, fee} = params;
    const extraComponentVal = getExtraCompValue(row.tutionFeeConcession, row.adminFeeConcession, row.regFeeConcession, row.classFeeConcession, row.techFeeConcession);


    //console.log(extraComponent)
    const getViewOnlyVal = (val, componentName) => {
        return (val === 0 && extraComponent.find(item => item === componentName)) ? extraComponentVal && parseFloat(extraComponentVal?.toFixed(2)) : val && parseFloat(val?.toFixed(2));
    }

    const values = (
        <>
            {FEE_COMPONENTS.map(item => {
                const value = getViewOnlyVal(row[item.beName], item.value);

                let percentVal, amountVal;
                if(row.concessionParameter === 'AMOUNT'){
                    amountVal = value;
                    percentVal = ((amountVal*100)/fee[item.feeName]).toFixed(2);
                }else {
                    percentVal = value;
                    amountVal = ((fee[item.feeName] * percentVal)/100).toFixed(2);
                }

                return (
                <div className={value > 0 ? 'font-weight-bold' : ''}>{item.label}: {`${percentVal}% (₹${amountVal})`}</div>
            )
})}
        </>
    );

    return (
        <div className='w-100 d-flex'>
            <LightTooltip title={values}>
                <span style={{ color: '#00B0F5', cursor: 'pointer' }}>View</span>
            </LightTooltip>
        </div>
    )
}

const getFormatedDate = (date) => {
    return date ? moment(date).format('DD-MMM-YYYY') : ''
}

const ProspectusConcession = (props) => {

    const {
        userPermissions,
        applicationDetails,
        feeComps,
        prospectusSlabs,
        setProspectusSlabs,
        allSlabs,
        setAllSlabs,
        setRowsCountInEditMode,
        allCategories,
        open,
        setOpen,
        categoryCodeData,
        rowsCountInEditMode,
        disableActions,
      
        customSlab,
        mapGlobalFeeComponents,
        extraComponent,
        setEditFormOpen,
        setDefinedConcessionDirty,
        freezeAction,
        customConfig,
        paymentDetails,
        selectedQoutationId,
        fee
    } = props;   

    let { id, action } = useParams();

    //console.log(extraComponent)

    // const [autoConcessionId,setAutoConcessionId]=useState(0)
    // useEffect(()=>{
    //     if(autoConcessionId>=0) setAutoConcessionId(prospectusSlabs.find(slab=>slab?.isAutoConcession===true)?.id || 0);
    // },[prospectusSlabs])

    // useEffect(()=>{
    //   setAutoConcessionId(0);
    // },[autoConcession])
    
    const [remarks, setRemarks]=useState(null);
    const [openRemarksDialogue,setOpenRemarksDialogue]=useState(false);

    const getFilteredCodeCategory = (params) => {
        const usedCategory = {};
        prospectusSlabs.forEach(element => {
            if(element.action !== 'DELETE' && element?.id !== params?.id && element?.code?.[0]?.value){
                usedCategory[element.code?.[0]?.value] = (usedCategory[element.code?.[0]?.value] || 0) + 1
            }
        });
        const filteredOption = categoryCodeData.filter(code => {
            const {value , frequency} = code;
            return !usedCategory?.[value] || usedCategory[value] < frequency;
        })

        return filteredOption || []
    }

    const isDisabled = (param) => {
        return param?.concessionMethodType?.split("_")?.[0] === 'AUTO' ? true : false
    }

    const columns = useMemo(() => {
        return [
            {
                field: 'code', headerName: 'Code', flex: 0.5, minWidth: 66, type: 'singleSelect', mandatory: true, editable: true, disabled:false, getIsFeildDisabled : (row)=> isDisabled(row),
                valueOptionsGetter: (params) => {return getFilteredCodeCategory(params)}, placeHolder: "", affects: ['category'], sortable: false,
            },
            {
                field: 'category', headerName: 'Category', flex: 1, minWidth: 140, mandatory: true, editable: true, disabled: false, sortable: false,
                renderCell: (params) => (
                    <div className='w-100 d-flex'>
                        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '85%' }}>{params.value?.subSubCategoryDisplayValue ?? params.value?.subCategoryDispVal ?? params.value?.displayVal}</span>
                        {(params.value?.subSubCategoryDesc ?? params.value?.subCategoryDesc ?? params.value?.categoryDesc) &&
                            <LightTooltip title={params.value.subSubCategoryDesc ?? params.value?.subCategoryDesc ?? params.value?.categoryDesc}>
                                <div style={{ cursor: 'pointer' }}>
                                    <AiOutlineEye className='ml-1' size={16} color='#11cdef' />
                                </div>
                            </LightTooltip>
                        }
                    </div>
                ),
                renderEditCell: (params) => <CategorySelector applicationDetails={applicationDetails} allCategories={allCategories} {...params} userPermissions={userPermissions} prospectusSlabs={prospectusSlabs} isCategorySelectorDisabled={isDisabled}/>
            },
                  
            { field: 'concessionSlabId', headerName: 'Concession Slab Id', width: 10, mandatory: false, editable: true, hide: true },
            { field: 'adminFeeConcession', headerName: 'Admission Fee', width: 10, mandatory: false, editable: true, hide: true },
            { field: 'regFeeConcession', headerName: 'Reg Fee', width: 10, mandatory: false, editable: true, hide: true },
            { field: 'classFeeConcession', headerName: 'Class Fee', width: 10, mandatory: false, editable: true, hide: true },
            { field: 'techFeeConcession', headerName: 'Tech Fee', width: 10, mandatory: false, editable: true, hide: true },
            { field: 'tutionFeeConcession', headerName: 'Tution Fee', width: 10, mandatory: false, editable: true, hide: true },

          
            {
                field: 'scholarship', headerName: 'Scholarship', flex: 1, width: 100 , mandatory: false, editable: false, sortable: false,
                renderCell: (params) => <ScholarshipComp feeComps={feeComps} {...params} allSlabs={allSlabs}  extraComponent={[...extraComponent]} fee={fee}/>,
                renderEditCell: (params) => <ScholarshipComp feeComps={feeComps} {...params} allSlabs={allSlabs}  extraComponent={[...extraComponent]} fee={fee}/>,
            },     
            {
                field: 'remarks', headerAlign: 'center' ,align: 'center', headerName: 'Remarks', flex: 1, width: 100, type: 'text', mandatory: false, editable: true, disabled: false, sortable: false,
                placeHolder: 'Add',
                renderCell: (params) => (
                    <div className='w-100 d-flex custom-concession-cell'
                    style={{ justifyContent: "center", paddingRight : '14px' }}
                    >
                       <span onClick={()=>fetchRemarks(applicationDetails.applicationId ,setRemarks,setOpenRemarksDialogue , params?.row?.id)} style={{ color: '#00B0F5', cursor: 'pointer' }}>View</span>
                    </div>
                )
            },
            {
                field: 'concessionDocuments', headerAlign:'center',headerName: 'Documents', flex: 1, width: 50, mandatory: false, editable: true, disabled: false, sortable: false,
                renderCell: (params) => <SupportingDocUploader userPermissions={userPermissions}  params={params} viewOnly={true} viewText={true}/>,
                renderEditCell: (params) => <SupportingDocUploader userPermissions={userPermissions}  params={params} viewOnly={false}/>, initialValue: [],
            },
            {
                field: 'updatedOn', headerName: 'Updated on', flex: 1, width: 100, mandatory: false, editable: true, disabled: false, sortable: false,
                renderCell: (params) => (
                <LightTooltip title={getFormatedDateTime(params.value)}>
                <div style={{ cursor: "pointer" }}>
                  <span>{getFormatedDate(params.value)}</span>
                </div>
              </LightTooltip>),
                renderEditCell: (params) => (<span>-</span>)
            },
            {
                field: 'status', headerName: 'Status', flex: 1, minWidth: 130, mandatory: false, editable: false, disabled: false,sortable: false,
                renderCell: (params) => {
                    const getApprPercentage = (status) => {
                        switch (status) {
                            case "L1_APPROVED": return customConfig?.l1ApprovalPercentage || "";
                            case "L2_APPROVED": return customConfig?.l2ApprovalPercentage || "";
                            default: return "";
                        }
                    }

                    const percent = params.row.isApproved ? 0 : getApprPercentage(params.value);
                    const val = params.row.isApproved ? 'Approved' : `${percent ? percent + '% ' : ''}${NEW_APPROVAL_STATUS?.[params.value] ?? ''}`;

                    return (
                        params.row?.hasExtraFee ? 
                        <div className='w-100 d-flex custom-concession-cell'>
                            <LightTooltip title={val}>
                                <div className='d-flex' style={{ marginTop: '0px', width: '90%' }}>
                                    <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%', whiteSpace: 'nowrap', padding: ['Approved', 'Rejected', 'Recheck'].includes(val) ? '2px 10px' : '2px 10px', borderRadius: '4px', backgroundColor: STATUS_DATA[val]?.BCOLOR ?? (val ? '#CCEFFD' : '') }}>{val}</span></div>
                            </LightTooltip>
                        </div>
                        :  <div className='w-100 d-flex custom-concession-cell'>NA</div>
                    )
                },
                renderEditCell: (params) => (<div className='custom-concession-cell'>-</div>),
                value: customSlab.status
            },
            { field: 'concessionParameter', headerName: 'Concession Parameter', width: 50, mandatory: false, editable: true, hide: true },
            { field: 'scholarshipCriteria', headerName: 'Scholarship Criteria Fee', width: 50, mandatory: false, editable: true, hide: true },
            { field: 'scholarshipCriteriaValue', headerName: 'Scholarship Criteria Value', width: 50, mandatory: false, editable: true, hide: true },
            { field: 'categoryId', headerName: 'CategoryId', width: 50, mandatory: false, editable: true, hide: true },
            { field: 'concessionMethodType', headerName: 'concessionMethodType', width: 50, mandatory: false, editable: true, hide: true },
        ];
    }, [prospectusSlabs, feeComps]);

    // useEffect(() => {
    //     if (feeComps.length > 0) {
    //         let allSlabsTemp = [...allSlabs];
    //         prospectusSlabs.forEach(slab => {
    //             const rows = allSlabsTemp.filter(row => row.id !== slab.id);
    //             allSlabsTemp = [...rows, { ...slab, action: slab.fromBE ? "UPDATE" : "CREATE", concessionType: "DEFINED" }];
    //         });
    //         setAllSlabs(allSlabsTemp);
    //     }
    // }, [feeComps]);


    const onRowDelete = (slab) => {
        const slabPresent = allSlabs.find(row => row.id === slab.id);
        if (slabPresent) {
            if (slabPresent.action === "CREATE") { 
                setAllSlabs(slabs => slabs.filter(row => row.id !== slabPresent.id))
            } else if (slabPresent.action === "UPDATE") {
                setAllSlabs(slabs => slabs.map(row => row.id === slabPresent.id ? { ...slab, action: "DELETE", concessionType: "DEFINED" } : row));
            }
            // mapGlobalFeeComponents(prospectusSlabs.filter(row => row.id !== slabPresent.id));
            setDefinedConcessionDirty(true);
        } else {
            setAllSlabs(slabs => [...slabs, { ...slab, action: "DELETE", concessionType: "DEFINED" }]);
            }
            // mapGlobalFeeComponents([...prospectusSlabs, { ...slab, action: "DELETE", concessionType: "DEFINED" }]);
            setDefinedConcessionDirty(true);
        // if(slab?.isAutoConcession){
        //         setAutoConcession([]);
        // }
        mapGlobalFeeComponents(prospectusSlabs.filter(row => row.id !== slab.id));
    }
    

    const frequencyValidation = (arr) => {
        //console.log(arr)
        const frequencyMap = {};
        for(const obj of arr){
            const { value, frequency} = obj.code?.[0];
            if(obj.action !== 'DELETE' && obj.concessionType){

                if(!frequencyMap.hasOwnProperty(value)){
                    frequencyMap[value] = 1;
                }else{
                    frequencyMap[value] += 1;
                }
            }
            if(frequencyMap[value] > frequency){
                failureToast("Frequency of code exceeds the provided frequency");
                return false
            }
        }

        for(const obj of arr){
            if(obj.code?.[0]?.notAllowedCombination ){
                const combinations = obj.code?.[0]?.notAllowedCombination?.split(',');
                let isAllPresent = true;
                combinations.map(i => {
                    if(!frequencyMap.hasOwnProperty(i)){
                        isAllPresent = false;
                    }
                })
                if(isAllPresent){
                    failureToast("Combination not allowed")
                    return false
                }
            }
        }
        return true;

    }


    const saveSlab = (slab) => {
        //console.log(prospectusSlabs)
        // setAutoConcessionId(-1);
        const _slab = { ...slab, updatedOn: new Date() };
        const rows = allSlabs.filter(row => row.id !== _slab.id);
        const newRows = [...rows, { ..._slab, action: _slab.fromBE && !_slab.isAutoConcession ? "UPDATE" : "CREATE", concessionType: "DEFINED", isDirty: true }]
        const fromBe = prospectusSlabs.filter(row => row.fromBE)
        const fromBeWithCategoryInformation = fromBe.map(i => {
            const newCode = categoryCodeData.filter(c => i.code?.[0]?.value === c.value)
            return {
                ...i,
                code : newCode
            }
        })
        
        const isDataValid = frequencyValidation([...fromBeWithCategoryInformation,...newRows]);
        if(isDataValid){
            setAllSlabs(newRows);
            setRowsCountInEditMode(count => count - 1);
            setEditFormOpen(false);
            mapGlobalFeeComponents(newRows);
            setDefinedConcessionDirty(true);

    
            return Promise.resolve(_slab);
        }else{
            return Promise.reject('slab not ')
        }
    }

    const closeTableForm = () => {
        setRowsCountInEditMode(0);
        setEditFormOpen(false);
    }

   

    const headerComp = (
        <Row onClick={()=>{
        setOpen(prev=>({...prev , propspectus : !prev.propspectus}));
        if(open) closeTableForm();
    }
        } className="prospectus-header pt-3 cursor">
            <div className="prospectus-header-title"><h3>Defined concession</h3></div>
        </Row>
    );


    const headerRight = (<MdKeyboardArrowUp className='cursor' onClick={() => {setOpen(open => ({...open, propspectus: false}));closeTableForm()}} size={26} />);

    return (
        <div className="propectus-concession">
            {open ?
            <>
                <MuiTable
                   actionSeperatorRequired = {false}
                    rows={prospectusSlabs}
                    columns={columns}
                    headerComp={headerComp}
                    headerMiscellaneousRightComp={headerRight}
                    userPermissions={userPermissions}
                    loading={false}
                    onRowDelete={onRowDelete}
                    asyncSaveRow={saveSlab}
                    useDeleteIconForCancel={true}
                    onRowChange={(rows) => {setProspectusSlabs(rows)}}
                    extraHeight={0}
                    // maxHeight={168}
                    onEditClick={() => {setRowsCountInEditMode(count => count + 1);setEditFormOpen(true)}}
                    onCancelClick={(id) => {
                        let slab=prospectusSlabs.find(sl=>sl?.id===id);
                        // if(slab?.isAutoConcession && !allSlabs.find(sl=>sl?.id===slab?.id)?.id){
                        //     setAutoConcession([])
                        // }
                        // setAutoConcessionId(-1);
                        setRowsCountInEditMode(count => count - 1)
                        setEditFormOpen(false)}}
                    onAddRewRow={() => {setRowsCountInEditMode(count => count + 1);setEditFormOpen(true)}}
                    readOnly={action === "view" || disableActions || freezeAction}
                    pageSize={100}
                    disableVirtualization={true}
                    rowsCountInEditMode={rowsCountInEditMode}
                    newRowLabel={"Add Concession"}
                    defaultEditRowId={''}
                /> 
                {/* <GetDisplayData customSlab={customSlab}/> */}
                <RemarksDialogue remarksData={remarks} open={openRemarksDialogue} setOpen={setOpenRemarksDialogue} setRemarksData={setRemarks}/>
                </>:
                <Row onClick={()=>setOpen(prev=>({...prev , propspectus : !prev.propspectus}))} className="prospectus-header-collapsed py-3 cursor">
                    <div className="prospectus-header-title"><h3>Defined concession</h3></div>
                    <MdKeyboardArrowDown size={26} onClick={e => {e.stopPropagation(); setOpen(prev=>({...prev, propspectus: true}))}} />
                </Row>}
        </div>
    )
}

export default ProspectusConcession;