import React, { useEffect, useRef } from "react";
import { getUniqueKey } from "../../utils/methods/commonMethods/utilityMethod";
import "./index.scss";

const BooleanStatus = ({ value, onChange = () => {}, disabled=false }) => {
  const innerRef = useRef(null);
  const innerInputRef = useRef(null);

  useEffect(() => {
    const label = innerRef.current;
    // subscribe event
    label.addEventListener("keypress", handleKeyPress);
    return () => {
      // unsubscribe event
      label.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  
  const handleKeyPress = (e) => {
    if (e.keyCode === 32) {
      if(innerInputRef.current.checked){
        onChange(false)
      }else{
        onChange(true)
      }
    }
  };

  return (
    <label
      className="custom-toggle mx-auto ml-2 status-toggle"
      tabIndex="0"
      ref={innerRef}
    >
      <input
        disabled={disabled}
        checked={value === true}
        type="checkbox"
        id={getUniqueKey()}
        ref={innerInputRef}
        onChange={(e) => {
          let n = true;
          if (!e.target.checked) n = false;
          onChange(n);
        }}
      />
      <span
        style={{ width: "72px" }}
        className="custom-toggle-slider rounded-circle activeToggle"
        data-label-off="FALSE"
        data-label-on="TRUE"
      />
    </label>
  );
};

export default BooleanStatus;
