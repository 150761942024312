import React from 'react';
import {Table} from 'reactstrap';
import {GLCodeHeader, GLCODETHROW} from '../../common/commonComponents/masterRow/masterRow';
import {GetPagination} from '../../common/pagination'
import {masterServiceBaseUrl, failureToast, deleteAPI, successToast} from "services/http";
import {useHistory} from 'react-router-dom';
import {useState} from 'react';

const GlCode = (props) => {

  let history = useHistory();
  const {
    searchDataList,
    setSearchDataList,
    getDisplayValue,
    glCodeArray,
    setGlCodeArray,
    fetchSearchList,
    previousPage,
    lastPage,
    firstPage,
    nextPage,
    setPagination,
    pagination
  } = props;
  const [change, setChange] = useState(false);


  const getIndex = (dataList, id) => {
    try {
      let index = dataList.findIndex(item => item['id'] === id);
      return index >= 0 ? index : '';
    } catch (e) {
      console.log("Error", e)
    }
  }

  const deleteRecordFromState = (id) => {
    try {
      let indexGlCodeArray = getIndex(glCodeArray, id);
      let indexForSearchDataArray = getIndex(searchDataList, id);
      let temp = glCodeArray;
      let __temp = searchDataList;
      temp.splice(indexGlCodeArray, 1);
      __temp.splice(indexForSearchDataArray, 1);
      setGlCodeArray(temp);
      setSearchDataList(__temp);
      setChange(!change);
    } catch (e) {
      console.log("Error", e)
    }
  }

  const deleteRecord = (id) => {
    try {
      if (window.confirm('Are you sure you want to delete GL Code Record ?')) {
        deleteAPI(masterServiceBaseUrl + `/glcode/${id}`, (data) => {
          deleteRecordFromState(id)
          successToast(data['message']);
        }, (data) => {
          failureToast(data['message'])
        }, 'Delete')
      }
    } catch (e) {
      console.log("Error", e)
    }
  }

  return (
    <div id="questionTable" className="table-responsive">
      <Table className="align-items-center table-flush">
        <GLCodeHeader/>
        <tbody className="list" key={"1"}>
        {
          glCodeArray && glCodeArray.map((item, index) => (
            <GLCODETHROW
              key={index}
              index={index}
              getDisplayValue={getDisplayValue}
              item={item}
              history={history}
              deleteRecord={deleteRecord}/>
          ))
        }
        </tbody>
      </Table>
      {pagination.totalPage > 1 ?
        <GetPagination
          fetchClasses={fetchSearchList}
          pagination={pagination}
          previousPage={previousPage}
          firstPage={firstPage}
          lastPage={lastPage}
          nextPage={nextPage}
          setPagination={setPagination}/> : null}
    </div>
  )
}

export default GlCode;
