import React from 'react'
import {
    getAPI,
} from "services/http";
import {
  failureToast
} from "../toasterFunctions/function";

export const MasterDataForSelect = async (url, key, method) => {
    getAPI(
        url,
        (data) => {
          let _n =[];
          _n = data &&  data.data.map((data) => {
            return { value: data["id"], label: data[`${key}DispValue`] };
          });
          method(_n);
        },
        (data) => {
          method([]);
          failureToast(data["message"]);
        }
    );
}

export const MasterDataForReactSelect = async (url, key, method) => {
    getAPI(
        url,
        (data) => {
          console.log(data.data)
          let _n = data && data.data.map((data) => {
            return { id: data["id"], text: data[`${key}DispValue`] };
          });
          method(_n);
        },
        (data) => {
          failureToast(data["message"]);
        }
    );
}

export const getSelectedDropDownValue = (id,list) => {
  // console.log(id)
  // console.log(list)
  const item = list && list.filter((item) => {
    return item && item.value === id;
  });
  return item && item.length ? item[0] : '';
}
