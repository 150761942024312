import { version as uuidVersion } from "uuid";
import { validate as uuidValidate } from "uuid";

import { fetchAllPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "views/pages/manage/common/constant";
import { fetchAllPostPromisedData } from "../../common/utils/methods/commonMethods/utilityMethod";
import moment from "moment";
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";

export const YellowBgColor = { background: "#FFEDC8" };

export const yesNoDropdown = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

export const classTypeDropdown = [
  { label: "LECTURE", value: "LECTURE" },
  { label: "Extra Class", value: "EXTRA CLASS" },
  { label: "PTM", value: "PTM" },
  { label: "Orientation", value: "Orientation" },
  { label: "Doubt Class", value: "DOUBT CLASS" },
];

export const getLabelWithDescription = (label, description) => {
  return description ? `${label}: ${description}` : label;
};

export const dropdownMapping = (
  list,
  key = "academicPlanName",
  isAppendDescription = false
) =>
  list.map((item) => ({
    value: item.id,
    label: isAppendDescription
      ? getLabelWithDescription(item[key], item?.description)
      : item[key],
    packageId: item["packageId"],
  }));
// this is for lectures for dropdown
export const dropdownMappingV2 = (list, key = "academicPlanName") =>
  list.map((item) => ({
    value: item.id,
    label: `${item[key]}${item["syllabusPreview"] ? "-" : ""}${
      item["syllabusPreview"] ?? ""
    }`,
    lectureName: `Lecture-${item[key]}`,
    lecturePreview: item["syllabusPreview"],
    lectureCode: item["name"],
  }));

export const dropdownRoomMapping = (list, key = "roomName") =>
  list.map((item) => ({
    value: item.id,
    label: `${item["roomCode"]}-${item[key]}`,
  }));
// services

export const getBatch = (batchId) => {
  const url = `${endpoint.viewEditBatch.getBatchByIdV2}/${batchId}?requireDispValue=true`;
  return fetchAllPromisedData(url);
};

export const getPlanTests = (selectedData) => {
  const { plan, startFrom, endOn } = selectedData;
  //academicPlanTests
  const request = {
    academicPlanId: plan?.value,
    startDateBegin: startFrom,
    endDateEnd: endOn ? moment(endOn).endOf("day") : endOn,
    active: true,
  };
  const url = `${endpoint.academicPlanTests.getTestList}?offset=${0}`;
  return fetchAllPostPromisedData(url, request);
};

export const getPlanListForBatch = (request) => {
  return fetchAllPostPromisedData(
    `${
      endpoint.searchAcademicPlan.getPlanList
    }?requireDispValue=false&offset=${0}`,
    request,
    "post"
  );
};

export const createTimetable = (request) => {
  // console.log(endpoint, endpoint.timetable.createTimeTable);
  const url = endpoint.timetable.createTimeTable;
  return fetchAllPostPromisedData(url, request);
};

export const resetTimetableByBatchId = (batchId) => {
  const url = `${endpoint.timetable.resetTimetable}/${batchId}`;
  return fetchAllPromisedData(url, {});
};

export const getRooms = (batchId) => {
  const url = `${endpoint.room.getAllActiveRoomByBusinessArea}/${batchId}`;
  return fetchAllPromisedData(url);
};

export const getTimetable = (batchId) => {
  const url = `${endpoint.timetable.getPublishedTimetable}/${batchId}?requireDispValue=true`;
  return fetchAllPromisedData(url);
};

export const getFaculty = (businessAreaId = 11) => {
  const url = `${endpoint.faculty.getByBusinessAreaId}/${businessAreaId}`;

  return fetchAllPromisedData(url);
};

// state is key  coming in in get timetable api as timeTableState
export const editTimetable = (timeTableId, request, state) => {
  const url = `${endpoint.timetable.editTimeTable}/${timeTableId}`;
  return fetchAllPostPromisedData(url, request, "put", true, { state: state });
};

export const autoPopulateLectures = (request) => {
  // return Promise.resolve({
  //   code: 200,
  //   data: [
  //     {
  //       date: "2023-01-15",
  //       startTime: "09:00:00",
  //       endTime: "10:15:00",
  //       classType: "LECTURE",
  //       subject: "chemistry",
  //       lectureName: "Lecture1",
  //       facultyName: "Shubham",
  //       status: "ACTIVE",
  //       lectureId: 2,
  //       facultyId: 69,
  //     },
  //     {
  //       date: "2023-01-15",
  //       startTime: "09:00:00",
  //       endTime: "11:00:00",
  //       classType: "",
  //       subject: "",
  //       lectureName: "Break",
  //       facultyName: "",
  //       status: "ACTIVE",
  //       lectureId: 0,
  //       facultyId: 0,
  //     },
  //     {
  //       date: "2023-01-16",
  //       startTime: "09:00:00",
  //       endTime: "10:15:00",
  //       classType: "LECTURE",
  //       subject: "chemistry",
  //       lectureName: "Lecture1",
  //       facultyName: "Shubham",
  //       status: "ACTIVE",
  //       lectureId: 3,
  //       facultyId: 49,
  //     },
  //   ],
  //   message: "Request Completed Successfully",
  // });
  const url = endpoint.lecture.lectureAutoPopulate;
  return fetchAllPostPromisedData(url, request);
};

export const getDaysPlan = (businessAreaId) => {
  const url = `${endpoint.daysPlan.search}/0/?requireDispValue=false`;
  return fetchAllPostPromisedData(url, {
    businessAreas: [businessAreaId],
    active: true,
  });
};

export const getPastTests = (batchId) => {
  const url = `${
    endpoint?.timetable?.getPastTest
  }/${batchId}?offSet=${0}&requireDispValue=false`;
  return fetchAllPostPromisedData(url, true);
};

export const getMissedTests = (batchId) => {
  const url = `${endpoint?.timetable?.missedTest}/${batchId}`;
  return fetchAllPostPromisedData(url, true);
};

const getValueByKey = (dataObject, key) => {
  if (dataObject && dataObject[key]) {
    if (!Array.isArray(dataObject[key])) {
      return dataObject && dataObject[key].id;
    } else {
      return dataObject[key].map((item) => item.id);
    }
  } else {
    return "";
  }
};

export const getPlanListModel = (batch) => {
  return {
    academicCareer: [getValueByKey(batch, "academicCareer")],
    academicGroup: [getValueByKey(batch, "academicGroup")],
    academicPhase: getValueByKey(batch, "academicPhase"),
    academicYear: [getValueByKey(batch, "academicYear")],
    board: [getValueByKey(batch, "schoolBoard")],
    businessArea: [getValueByKey(batch, "businessArea")],
    classType: [getValueByKey(batch, "classType")],
    classes: getValueByKey(batch, "batchClass"),
    publishStatus: ["P"],
    // classes: [getValueByKey(batch, "academicCareer")],
  };
};

const getLabels = (list) => {
  if (Array.isArray(list)) {
    let displayValues = list.map((item) => item["dispValue"]);
    if (displayValues.length > 2) {
      return `${displayValues[0]}, ${displayValues[1]}, + ${
        displayValues.length - 2
      } more `;
    } else {
      return displayValues.join(",");
    }
  }
};

export const getDisplayValueByKey = (dataObject, key) => {
  if (dataObject && dataObject[key] && !Array.isArray(dataObject[key])) {
    return dataObject[key].dispValue;
  } else if (dataObject && dataObject[key] && Array.isArray(dataObject[key])) {
    return getLabels(dataObject[key]);
  } else {
    return "";
  }
};

export const formLabelTitles = ["Batch", "Academic plan", "Timetable date"];

export const FORM_FIELD = {
  plan: "",
  startFrom: "",
  endOn: "",
  tests: [],
  duration: "",
  daysPlan: "",
  subjects: {},
  room: "",
};

export const updateTestData = (tests) => {
  if (Array.isArray(tests)) {
    return tests.map((item) => ({
      ...item,
      isComplete: false,
      room: "",
      testDate: "",
      testStartTime: "",
      testEndTime: "",
      conductTest: true,
      onlineTestDate: "",
      onlineTestEndDate: "",
    }));
  } else {
    return [];
  }
};
// by this parsing timetable tests
export const updateTimetableTests = (tests) => {
  if (Array.isArray(tests)) {
    return tests.map((item) => ({
      ...item,
      mode: item.testMode,
      format: item.testFormat,
      resultDate: item.testResultDate,
      isComplete: true,
      room: {
        value: item?.roomResponse?.id,
        label: item?.roomResponse?.dispValue,
      },
      conductTest: item?.isConductTest,
    }));
  } else {
    return [];
  }
};

export const updateTimetableLectures = (lectures) => {
  if (Array.isArray(lectures)) {
    return lectures.map((item) => ({
      ...item,
      date: item.lectureDate,
      startTime: moment(item?.lectureStartTime).format("HH:mm"),
      endTime: moment(item?.lectureEndTime).format("HH:mm"),
      classType: item.timeTableClassType,
      facultyId: item?.invigilatorResponse?.id,
      facultyName: item?.invigilatorResponse?.dispValue,
      roomName: item?.roomResponse?.dispValue,
      roomId: item?.roomResponse?.id,
      status: item?.status,
      lectureName: item?.isBreakExist ? "Break" : item?.lectureName,
      msTeam: {
        label: item?.isCreateMsTeamsEvent ? "Yes" : "No",
        value: item?.isCreateMsTeamsEvent ? "Yes" : "No",
      },
    }));
  } else {
    return [];
  }
};

export const isTestCompleted = (test) => {
  if (test?.mode === "Online") return test?.testDate && test?.onlineTestEndDate;
  let result =
    test?.room && test?.testDate && test?.testStartTime && test?.testEndTime;
  if (test?.mode === "Offline") return result;

  if (test?.mode === "Both")
    return result && test?.onlineTestDate && test?.onlineTestEndDate;
};

export const isAllTestCompleted = (tests) => {
  if (tests && tests.length === 0) {
    return true;
  }
  let completedAll = true;
  if (Array.isArray)
    for (let test of tests) {
      if (!test?.isMandatory && !test?.conductTest) {
        continue;
      }
      if (!test?.isComplete) {
        completedAll = false;
        break;
      }
    }

  return completedAll;
};

const getTestStartAndEndTime = (testDate, startTime, endTime, mode) => {
  if (mode?.toLowerCase() === "online") {
    return null;
  }
  if (testDate && startTime) {
    return new moment(`${testDate} ${startTime}`, "DD-MM-YYYY hh:mm A");
  }
  if (testDate && endTime) {
    return new moment(`${testDate} ${endTime}`, "DD-MM-YYYY hh:mm A");
  }
};

export const add9Hours = (time) => {
  if (!time) return "";
  if (moment(time).hours() !== 0) return time;
  return moment(time).add(9, "hours");
};

export const getTimetableTestRequest = (tests) => {
  return tests.map((test) => {
    let startTime = new moment(test?.testStartTime).format("hh:mm A");
    let endTime = new moment(test?.testEndTime).format("hh:mm A");
    let testDate = new moment(test?.testDate).format("DD-MM-YYYY");

    return {
      isTimeTableEvent: test?.isTimeTableEvent,
      isItemEdited: test?.isItemEdited,
      mode: test?.mode,
      testScheduleId: test?.testScheduleId || test?.id,
      id: test?.id,
      // testDate: test?.testDate,
      testDate:
        test.mode?.toLowerCase() === "online"
          ? add9Hours(test?.testDate)
          : test?.testDate,
      // onlineTestDate: test?.onlineTestDate,
      onlineTestDate: add9Hours(test?.onlineTestDate),
      // onlineTestEndDate: moment(
      //   moment(test?.onlineTestEndDate).endOf("day")
      // ).subtract(2, "seconds"),
      onlineTestEndDate: add9Hours(test?.onlineTestEndDate),
      testStartTime: test?.testStartTime
        ? getTestStartAndEndTime(testDate, startTime, null, test?.mode)
        : null,
      testEndTime: test?.testEndTime
        ? getTestStartAndEndTime(testDate, null, endTime, test?.mode)
        : null,
      roomId: test?.room?.value,
      ...(!test.isMandatory ? { isConductTest: test?.conductTest } : {}),
    };
  });
};

export const uuidValidateV4 = (uuid) => {
  return uuidValidate(uuid) && uuidVersion(uuid) === 4;
};

export const getLectureRequest = (lectures, SelectedRoomId, duration = "") => {
  return lectures.map((lecture) => {
    //  debugger
    let {
      lectureId,
      subject,
      lectureName,
      msTeam,
      facultyId,
      roomId,
      status = "ACTIVE",
      lectureCode,
      documentS3Key,
      isItemEdited,
      bachTimeTablePsidMapping,
    } = lecture;

    return {
      id: uuidValidateV4(lecture?.id) ? null : lecture?.id,
      lectureDate: moment(lecture.date),
      isTimeTableEvent: lecture?.isTimeTableEvent,
      lectureStartTime:
        lecture.classType === "Holiday"
          ? lecture?.startTime
          : lecture?.startTime
          ? moment(
              `${moment(lecture.date).format("DD-MM-YYYY")} ${
                lecture.startTime
              }`,
              "DD-MM-YYYY hh:mm"
            ).format()
          : null,
      lectureEndTime:
        lecture.classType === "Holiday"
          ? lecture?.endTime
          : lecture?.endTime
          ? moment(
              `${moment(lecture.date).format("DD-MM-YYYY")} ${lecture.endTime}`,
              "DD-MM-YYYY hh:mm"
            ).format()
          : null,
      timeTableClassType:
        lectureName && lectureName.toLowerCase() === "break"
          ? "break"
          : lecture.classType,
      roomId: roomId || SelectedRoomId,
      isBreakExist:
        lectureName && lectureName.toLowerCase() === "break" ? true : false,
      lectureId,
      subject,
      lectureName,
      isCreateMsTeamsEvent:
        lectureName && lectureName.toLowerCase() === "break"
          ? false
          : msTeam?.value === "Yes",
      status,
      invigilatorId: facultyId,
      lectureCode,
      documentS3Key,
      isItemEdited,
      duration,
      bachTimeTablePsidMapping,
    };
  });
};

export function compare(dateTimeA, dateTimeB) {
  let momentA = new moment(dateTimeA).format("DD/MMM/YYYY");
  let momentB = new moment(dateTimeB).format("DD/MMM/YYYY");
  if (momentA > momentB) return 1;
  else if (momentA < momentB) return -1;
  else return 0;
}

const getDropdownOption = (item) => {
  return {
    label: `${item["facultyName"]}-${item["facultyId"]}`,
    value: `${item["id"]}`,
  };
};

export const getSubjectsFacultyDropdown = (items) => {
  const dropdownObj = {};
  items.forEach((item) => {
    item.subjectList &&
      item.subjectList.forEach((subject) => {
        let key = subject.subjectDisplayValue;
        if (!dropdownObj[key]) {
          dropdownObj[key] = [getDropdownOption(item)];
        } else {
          dropdownObj[key] = [...dropdownObj[key], getDropdownOption(item)];
        }
      });
  });
  return dropdownObj;
};
/* subject form like {[subjectName]:[subjectId]} 
 and form contain {[subjectName] : [facultyId]}
 */
export const getSelectedFaculty = (subjectForm = {}, form = {}) => {
  if (subjectForm === null) {
    return {};
  }
  let subjects = Object.keys(subjectForm);
  let faculty = {};
  subjects.forEach((subject) => {
    if (form[subject])
      faculty[subject] = (form[subject] && form[subject]?.value) || "";
  });
  return faculty;
};
export const getAutoPopulateRequest = (
  form,
  businessAreaId,
  batchId,
  subjectForm,
  selectedFal
) => {
  let faculty = getSelectedFaculty(subjectForm, form);
  if (subjectForm === null) {
    faculty = selectedFal;
  }
  return {
    batchId,
    businessAreaId,
    academicPlanId: form?.plan?.value,
    duration: form?.duration.value,
    daysPlan: form?.daysPlan.value,
    startDate: form?.startFrom,
    endDate: form?.endOn,
    faculty,
    // faculty: {
    //       physics: "69",
    //       chemistry: "48",
    //     },
  };
};
export const lectureDurationDropdown = [
  { value: 60, label: "1hr" },
  { value: 75, label: "1hr 15mins." },
  { value: 90, label: "1hr 30mins." },
  { value: 105, label: "1hr 45mins." },
  { value: 120, label: "2hrs" },
];

function hmsToSeconds(s) {
  var b = s.split(":");
  return b[0] * 3600 + b[1] * 60 + (+b[2] || 0);
}
function secondsToHMS(secs) {
  function z(n) {
    return (n < 10 ? "0" : "") + n;
  }
  var sign = secs < 0 ? "-" : "";
  secs = Math.abs(secs);
  return (
    sign +
    z((secs / 3600) | 0) +
    ":" +
    z(((secs % 3600) / 60) | 0) +
    ":" +
    z(secs % 60)
  );
}

export const timeBreakGap = (endTime, startTime) => {
  let timeInHour = secondsToHMS(
    hmsToSeconds(endTime) - hmsToSeconds(startTime)
  ); // -10:39:18
  var timeParts = timeInHour.split(":");
  return Number(timeParts[0]) * 60 + Number(timeParts[1]);
};

// const removeBreakFromAutoPopulate = (lectures)=>{
//         let needToRemoveBreak =  lectures.forEach(lecture=>{

//         })
// }

export const removeBreaksFromEnd = (lectures) => {
  let temp = lectures;
  let lastLectureIndex = -1;
  for (let i = temp?.length - 1; i >= 0; i--) {
    if (temp[i]?.lectureName.toLowerCase() !== "break") {
      lastLectureIndex = i;
      break;
    }
  }
  // console.log(lastLectureIndex);
  const res = temp?.splice(
    lastLectureIndex + 1,
    temp?.length - (lastLectureIndex + 1)
  );
  // console.log(temp);
  return temp;
};

export const maxDate31Days = (date = new Date(), numberOfDays = 31) => {
  return moment(date, "DD-MM-YYYY").add(numberOfDays, "days");
};

export const lastDays = (
  numberOfDays = 31,
  date = new Date().setHours(0, 0, 0, 0)
) => {
  return moment(date).subtract(numberOfDays, "days");
};

export const isCallAutoPopulateApi = (form, businessAreaId, batchId) => {
  const { plan, startFrom, endOn, duration, daysPlan, subjects } = form;
  return (
    businessAreaId !== "" &&
    batchId !== "" &&
    plan !== "" &&
    startFrom !== "" &&
    endOn !== "" &&
    duration !== "" &&
    daysPlan !== "" &&
    subjects !== ""
  );
};

export const isDatePassed = (date) => {
  return new Date(date) < new Date().setHours(0, 0, 0, 0);
};

export const isLecturePassed = (date) => {
  return new Date(date) < new Date();
};
// export const isTestPassed = (date) => {
//   return new Date(date) < new Date();
// };
export const isTestPassed = (test) => {
  let onlineTestEndDate;
  let offlineTestEndDateTime;
  if (test?.mode === "Online" || test?.mode === "Both") {
    //increasing the date by 1
    let testEndDate = new Date(test?.onlineTestEndDate);
    onlineTestEndDate = new moment(
      `${testEndDate.getDate() + 1}-${
        testEndDate.getMonth() + 1
      }-${testEndDate.getFullYear()}`,
      "D-M-YYYY",
      true
    );
  }
  if (test?.mode === "Offline" || test?.mode === "Both") {
    // extracting test end date
    let testEndDate = new Date(test?.testDate);
    testEndDate = `${testEndDate.getDate()}-${
      testEndDate.getMonth() + 1
    }-${testEndDate.getFullYear()}`;
    // extracting test end time
    let testEndTime = new moment(test?.testEndTime).format("HH-mm-ss");
    // combining test end date and test end time
    offlineTestEndDateTime = new moment(
      `${testEndDate}-${testEndTime}`,
      "D-M-YYYY-HH-mm-ss",
      true
    );
  }
  if (test?.mode === "Online") {
    // return new Date(test?.onlineTestEndDate)<new Date();
    return new Date(onlineTestEndDate) < new Date();
  } else if (test?.mode === "Offline") {
    // console.log('offlineTestEndDateTime',offlineTestEndDateTime);
    return new Date(offlineTestEndDateTime) < new Date();
  }
  // else return ((new Date(test?.onlineTestEndDate)<new Date())&&(new Date(test?.testEndTime)<new Date()));
  else {
    // console.log('onlineTestEndDate',onlineTestEndDate,'-----','offlineTestEndDateTime',offlineTestEndDateTime);
    return (
      new Date(onlineTestEndDate) < new Date() &&
      new Date(offlineTestEndDateTime) < new Date()
    );
  }
};

export const getOnlyPlanTests = (response) => {
  return response?.testTimeTable?.filter((item) => !item?.isTimeTableEvent);
};

export const getTestsThatNotExist = (
  testsInPlan,
  testsInTimetable,
  pastTests
) => {
  let newTests = [];
  testsInPlan.forEach((item) => {
    let isTestExist = testsInTimetable.filter(
      (test) => test?.testScheduleId === item.id
    ).length;

    let isTestExistInPast = pastTests.filter(
      (test) => test?.testScheduleId === item.id
    ).length;

    if (!isTestExist && !isTestExistInPast) {
      newTests.push({
        ...item,
        testScheduleId: item?.id,
        id: "",
        isNewAdded: true,
      });
    }
  });
  return newTests;
};

export const getDuplicateErrorMessage = (type = "lecture") => {
  return `A ${type.toLowerCase()} is already scheduled at the date and time entered. please select a different date or time to proceed`;
};
export const DUPLICATE_LECTURE_ERROR_MESSAGE =
  "A lecture is already scheduled at the date and time entered. please select a different date or time to proceed";
export const checkDuplicateLecture = (lectures, form, lectureIndex = -1) => {
  if (lectures && lectures?.length > 0) {
    for (let i = 0; i < lectures.length; i++) {
      const { endTime, startTime } = lectures[i];
      const lectureDate = lectures[i]?.date || lectures[i]?.lectureDate;

      if (
        moment(lectureDate).isSame(moment(form?.date)) &&
        endTime === form?.endTime &&
        startTime === form?.startTime &&
        lectureIndex !== i
      ) {
        return lectures[i];
      }
    }
  }
  return false;
};

//  export  const checkDuplicateLectureWhilePublishing = (lectures)=>{
//              if(lectures.length>0){
//               for(let index =  0 ; index<lectures.length ; index++){
//                     let {date, endTime, startTime} = lectures[index] ;
//                      let isDuplicate = checkDuplicateLecture(lectures,{date,endTime,startTime} ,index);
//                      if(!isDuplicate){
//                       failureToast("Multiple lectures are schedule at same date and time. please update different date or time to proceed") ;
//                         return isDuplicate ;
//                          break ;
//                      }
//               }
//               return true ;
//              }
//   }

export const getFacultyDropdown = (obj) => {
  const dropdown = [];
  for (let key in obj) {
    dropdown.push(...obj[key]);
  }
  return dropdown;
};

export const getUniqueFaculty = (list = []) => {
  let map = {};
  let filteredDropdown = [];
  list.forEach((item) => {
    if (!map[item.value]) {
      filteredDropdown.push(item);
      map[item.value] = true;
    }
  });
  return filteredDropdown;
};

export const getOnlyEditedItem = (list = []) =>
  list.filter((item) => item?.isItemEdited || !item.id);
