import React from 'react';
import Select from "react-select";

const ReactSelect = (props) => {
  const {item,index,tableData} = props;

  return (
    <Select options={item.dropdownValue}/>
  )
}

export default ReactSelect;
