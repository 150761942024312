import React, { useEffect, useState } from 'react';
import CustomTooltip from '../../../common/customTooltip/CustomTooltip';
import { IoEyeOutline } from 'react-icons/io5';
import ADataGrid from '../../../common/data-grid/ADataGrid';
import { AGridColDef } from '../../../common/data-grid/ADataGridColDef';
import NoRowOverlay from '../../../masters/holidayMaster/NoRowOverlay';
import {
  gridStyles,
  gridComponents,
} from 'views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant.js';
import { fetchAllPromisedData } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../../common/constant';
import { getPsidMaterialData } from './constant';
import { isBarcodedMaterial } from '../../stockManagement/selectMeterialAndStock/constant';
import { useParams } from 'react-router';
import AButton from '../../../common/form-fields-mui/AButton.jsx'

const columns = [
  new AGridColDef('materialNumber', 'Material details', false)
    .setFlex(1.7)
    .renderCellComponent(params => (
      <CustomTooltip
        title={`${params?.row?.materialNumber} | ${params?.row?.materialDesc}`}
      >
        <div className='ellipsis-text-container'>
          {params?.row?.materialNumber} | {params?.row?.materialDesc}
        </div>
      </CustomTooltip>
    )),
  new AGridColDef('barcode', 'Barcode', false)
    .setValueGetter(params => params?.value?.trim())
    .renderCellComponent(params => {
      const isBarcoded = isBarcodedMaterial(params?.row);
      return (
        <CustomTooltip title={isBarcoded && params?.value}>
          <div className='ellipsis-text-container'>
            {isBarcoded && params?.value ? params?.value : '-'}
          </div>
        </CustomTooltip>
      );
    }),
];

const Details = ({ rowData, handleClose }) => {
  const { psid } = useParams();
  const { transactionId, businessArea } = rowData || {};
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(false);

  const fetchTransactionDetails = async () => {
    const searchQuery = `?transactionId=${transactionId}&businessArea=${businessArea}`;
    try {
      setLoader(true);
      const res = await fetchAllPromisedData(
        `${endpoint?.studentSearch?.getStockTransactionDetails}${searchQuery}`,
        true
      );
      if (res?.code === 200) {
        const rows = getPsidMaterialData(res?.data, psid);
        setRows(rows || []);
      } else {
        setRows([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchTransactionDetails();
  }, []);

  return (
    <div
      className='p-2 overflow-auto position-relative'
      style={{ width: '500px' }}
    >
      <ADataGrid
        removeWrapperContainer
        rows={rows}
        columns={columns}
        rowId={row => JSON.stringify(row)}
        maxHeight={'400px'}
        serverPagination={false}
        hidePagination={rows?.length <= 50}
        components={{
          ...gridComponents,
          NoRowsOverlay: () => (
            <NoRowOverlay message={loader ? 'Loading...' : null} />
          ),
        }}
        sx={gridStyles}
      />
      <AButton className='ml-0' variant='primary_filled' size='xs' onClick={handleClose}>Ok, got it!</AButton>
    </div>
  );
};
const MaterialDetailsCellComponent = ({ rowData }) => {
  const displayString = rowData?.materialNumber
    ? `${rowData?.materialDescription} | ${rowData?.materialNumber}`
    : '-';
  return (
    <div className='d-flex align-items-center gap-md ellipsis-text-container'>
      <div className='ellipsis-text-container'>{displayString}</div>
      <CustomTooltip
        showOnClick
        placement='bottom-end'
        title={ (handleClose) => <Details rowData={rowData} handleClose={handleClose} />}
      >
        <div>
          <IoEyeOutline className='pointer' size={18} />
        </div>
      </CustomTooltip>
    </div>
  );
};

export default MaterialDetailsCellComponent;
