import React from "react";
import { FaFontAwesomeFlag } from "react-icons/fa";
import { Tooltip } from "@mui/material";
import { getFormattedDate } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { ReactComponent as Delete } from "../../../../../../assets/img/svg/Delete.svg";
import { isDatePassed } from "../helper";

const rowStyle = {
  height: "40px",
  padding: "8px, 11px, 8px, 11px",
  borderRadius: "8px",
  background: "#89CCA9",
  display: "flex",
  justifyContent: "space-between",
  maxWidth: "96%",
  alignItems: "center",
  padding: "0px 10px",
  fontSize: "14px",
  margin: "4px 0px",
};

const editBtnStyle = {
  fontSize: "12px",
  background: "#BBE2CE",
  padding: "4px 11px",
  color: "#0D2A1E",
  borderRadius: "8px",
  cursor: "pointer",
};
const CustomHolidayRow = ({
  rowIndex,
  rowData,
  openMode,
  handleOpenHolidayModal,
  setIsDeleteDialogOpen
}) => {
  console.log(openMode);

  const editHandler = () => {
    handleOpenHolidayModal(rowData, rowIndex);
  };
  const rowWidth = openMode === "VIEW" ? "90%" : "96%";
  return (
    <>
      <div style={{ ...rowStyle, maxWidth: rowWidth }}>
        <div className="text-bold color-black">
          <FaFontAwesomeFlag /> Holiday
        </div>
        <div style={{ color: "#0D2A1E" }}>
          {" "}
          {rowData?.lectureName}: {getFormattedDate(rowData?.lectureStartTime)}
          to {getFormattedDate(rowData?.lectureEndTime)}
          {openMode !== "VIEW" && (
            <>
              <span onClick={editHandler} style={editBtnStyle} className="ml-2 mr-2">
                Edit
              </span>
              <span className="mr-1">
              {(isDatePassed(rowData?.lectureEndTime) ? (
              <Tooltip title="Cannot delete as end date has passed">
                <Delete />
              </Tooltip>
            ) : (
              <Delete onClick={() => setIsDeleteDialogOpen(true)} />
            ))}
              </span>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomHolidayRow;
