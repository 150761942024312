import React from "react";
import CustomTooltip from "views/pages/manage/common/customTooltip/CustomTooltip";
import { ColoredChip } from "../selectMeterialAndStock/issueStock/TableColumn";

const StatusChipWithFailureReason = ({ failedReason, transactionDetails }) => {
  if (!failedReason) return <ColoredChip value={transactionDetails?.status} />;
  return (
      <CustomTooltip
        title={<div style={{ maxWidth: "400px" }}>{failedReason}</div>}
      >
        <div className="cursor">
          <ColoredChip value={transactionDetails?.status} />
        </div>
      </CustomTooltip>
  );
};

export default StatusChipWithFailureReason;
