import React, { useState } from 'react';
import { linkButtonStyles } from '../../constant/student-details';
import { fetchAllPostPromisedData } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../../common/constant';
import { successToast } from '../../../common/utils/methods/toasterFunctions/function';
import { Dialog } from '@mui/material';
import { ReactComponent as CrossIcon } from 'assets/img/svg/CrossIcon.svg';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx';
import AAutoComplete from 'views/pages/manage/common/form-fields-mui/AAutoComplete.jsx';
import AButton from 'views/pages/manage/common/form-fields-mui/AButton';
import ALoader from '../../../common/a-loader';

const PROGRAM_ACTIONS = [
  { label: 'MLBA', value: 'MLBA' },
  { label: 'DMBA', value: 'DMBA' },
];
const StatusChangeDialog = ({
  open,
  setOpen = () => {},
  activeApplicationDetails,
  fetchApplications,
}) => {
  const handleClose = () => {
    setValue(PROGRAM_ACTIONS[0]); // this is to reset form state
    setOpen(false);
  };
  const [value, setValue] = useState(PROGRAM_ACTIONS[0]);
  const [apiLoader, setApiLoader] = useState(false);

  const handleProgramActionChange = async () => {
    setApiLoader(true);
    try {
      const res = await fetchAllPostPromisedData(
        `${endpoint.studentSupplementary.changeProgramActionMlbaToDmba}?applicationId=${activeApplicationDetails?.applicationId}`,
        {},
        'post'
      );
      if (res?.code === 200) {
        successToast(res?.message);
        handleClose();
        fetchApplications();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setApiLoader(false);
    }
  };

  return (
    <Dialog open={open} PaperProps={{ sx: { borderRadius: '12px' } }}>
      {apiLoader && <ALoader position={'fixed'} />}
      <div style={{ padding: '28px 24px', width: '450px' }}>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <div className='heading-4 color-dark'>Select status</div>
          <IconButtonWrapper onClick={handleClose}>
            <CrossIcon width={32} height={32} />
          </IconButtonWrapper>
        </div>
        <AAutoComplete
          style={{ control: { height: '48px' } }}
          items={PROGRAM_ACTIONS}
          currentValue={value}
          handleChange={val => setValue(val)}
          isMulti={false}
        />
        <div className='mt-3 d-flex justify-content-between'>
          <AButton
            className='ml-0'
            variant='outline_danger'
            updatedStyle={{ width: '180px' }}
            onClick={handleClose}
          >
            No
          </AButton>
          <AButton
            className='ml-0'
            variant='outline'
            updatedStyle={{ width: '180px' }}
            disabled={value?.value === 'MLBA'}
            onClick={() => {
              handleProgramActionChange();
            }}
          >
            Yes, confirm
          </AButton>
        </div>
      </div>
    </Dialog>
  );
};
const ChangeProgramAction = ({
  activeApplicationDetails,
  fetchApplications,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return activeApplicationDetails?.programActions === 'MLBA' ? (
    <>
      <span
        style={linkButtonStyles}
        onClick={() => {
          setIsDialogOpen(true);
        }}
      >
        Program status
      </span>
      <StatusChangeDialog
        key={activeApplicationDetails?.applicationId}
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        activeApplicationDetails={activeApplicationDetails}
        fetchApplications={fetchApplications}
      />
    </>
  ) : (
    <></>
  );
};

export default ChangeProgramAction;
