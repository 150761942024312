import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'
import ALoader from '../../../../common/a-loader'
import { ReactComponent as BackIcon } from "assets/img/svg/BackButton.svg";
import { useHistory } from 'react-router';
import AButton from '../../../../common/form-fields-mui/AButton';
import ListComponent from './ListComponent';
import SearchFilter from './SearchFilter';
import { RolePermissions, PermissionContext } from 'appContext';
import { endpoint, pages } from '../../../../common/constant';
import { PermisionDenied } from '../../../../common/commonComponents';
import { fetchAllPostPromisedData, fetchDeletePromisedData, updateRequest } from '../../../../common/utils/methods/commonMethods/utilityMethod';
import { successToast } from '../../../../common/utils/methods/toasterFunctions/function';
import { getDropdowns, getFilterCount, initialFilterForm } from './constant';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'

const restructureListResponse = (data) => {
  return data?.map(item => ({
    ...item,
    businessArea: { label: item?.businessArea, value: item?.businessArea },
    profitCenter: { label: item?.profitCenter, value: item?.profitCenter, academicCareer: item?.academicCareer, academicGroup: item?.academicGroup, description: item?.profitCenterDescription }
  }))
}

const SAPProfitCostCentreMapping = () => {

  const userPermissions = RolePermissions(useContext(PermissionContext), pages['sapProfitCostCentreMapping']['id']);

  const history = useHistory();
  const listRef = useRef();
  const [page, setPage] = useState(0);
  const [dropdownLoader, setDropdownLoader] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [listApiLoader, setListApiLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [dropdowns, setDropdowns] = useState({});
  const [filterPayload, setFilterPayload] = useState(initialFilterForm);
  const [filterCount, setFilterCount] = useState(0);

  const onPageChange = (newPage) => setPage(newPage);

  const fetchDropdowns = async () => {
    setDropdownLoader(true);

    const res = await getDropdowns();
    setDropdowns(res);

    setDropdownLoader(false);
  }

  const fetchList = async (page = 0, reqBody = initialFilterForm) => {
    setListApiLoader(true);

    const res = await fetchAllPostPromisedData(`${endpoint.sapProfitCostCentreMapping.searchProfitCostCenter}?offset=${page}`, updateRequest(reqBody), 'post');
    if (res?.code === 200) {
      setRows(restructureListResponse(res?.data?.profitCostCenterList));
      setRowCount(res?.data?.totalRecords);
    } else {
      setRows([]);
      setRowCount(0);
    }

    setListApiLoader(false);
  }

  const nextButtonHandler = async (newPage) => {
    setPage(newPage);
    await fetchList(newPage, filterPayload);
  }

  useEffect(() => {
    const fetchData = async () => {
      await fetchDropdowns();
      await fetchList();
    }
    fetchData();
  }, [])

  const createHandler = async (rowData) => {
    setApiLoader(true);

    const reqBody = updateRequest({
      ...rowData,
      id: null,
    })
    const res = await fetchAllPostPromisedData(endpoint?.sapProfitCostCentreMapping?.createProfitCostCenter, reqBody, 'post');
    if (res?.code === 200) {
      setPage(0);
      await fetchList(0, filterPayload);
      successToast(res?.message);
      listRef.current.stopEditing();
    }
    setPage(0);
    setApiLoader(false);
  }

  const updateHandler = async (rowData) => {
    setApiLoader(true);

    const reqBody = updateRequest({
      ...rowData
    });
    const res = await fetchAllPostPromisedData(`${endpoint?.sapProfitCostCentreMapping?.updateProfitCostCenter}/${rowData?.id}`, reqBody, 'put');
    if (res?.code === 200) {
      await fetchList(page, filterPayload);
      successToast(res?.message);
      listRef.current.stopEditing();
    }

    setApiLoader(false);
  }
  const toggleStatusHandler = async (rowData) => {
    await updateHandler({ ...rowData, costCenterStatus: rowData?.costCenterStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' });
  }

  const deleteHandler = async (rowData) => {
    setApiLoader(true);
    const res = await fetchDeletePromisedData(`${endpoint?.sapProfitCostCentreMapping?.deleteProfitCostCenter}/${rowData?.id}`);
    if (res?.code === 200) {
      await fetchList(page, filterPayload);
      successToast(res?.message);
      listRef.current.stopEditing();
    }
    setApiLoader(false);
  }

  const applyFilter = async (filterForm) => {
    listRef.current.stopEditing();
    setFilterPayload(filterForm);
    setFilterCount(getFilterCount(filterForm));
    const reqBody = updateRequest(filterForm);
    setPage(0);
    await fetchList(0, reqBody);
  }

  return (
    userPermissions ? (
      <div className={`remove-container-top-space ${styles.page_container}`}>
        {(dropdownLoader || listApiLoader || apiLoader) && <ALoader position='fixed' />}
        <div className={`${styles.table_card}`}>
          <div className='d-flex align-items-center justify-content-between'>
            <div className='heading-4 color-dark d-flex align-items-center'>
              <IconButtonWrapper onClick={() => history.goBack()}><BackIcon /></IconButtonWrapper>
              <div className='ml-2'>SAP Profit/Cost Centre Mapping</div>
            </div>
            <div className='d-flex align-items-center justify-content-center'>
              <AButton className='tiny-semi-bold color-dark' updatedStyle={{ width: '84px' }} onClick={() => listRef?.current?.addNewRow()}>Add new</AButton>
              <SearchFilter
                dropdowns={dropdowns}
                filterPayload={filterPayload}
                filterCount={filterCount}
                applyFilter={applyFilter}
              />
            </div>
          </div>
          <ListComponent
            currentPage={page}
            nextButtonHandler={nextButtonHandler}
            rowCount={rowCount}
            ref={listRef}
            dropdowns={dropdowns}
            loader={dropdownLoader || listApiLoader}
            rows={rows}
            createHandler={createHandler}
            updateHandler={updateHandler}
            toggleStatusHandler={toggleStatusHandler}
            deleteHandler={deleteHandler}
            page={page}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    ) : (
      <PermisionDenied />
    )
  )
}

export default SAPProfitCostCentreMapping