import { testScheduleServiceBaseUrl } from "services/http";

export const testScheduleServiceEndpoints = {
  // Primary Batch Sequence
  primaryBatchSequence: {
    searchByTerm: `${testScheduleServiceBaseUrl}/batch/searchBatchSequence`,
  },

  // create batch
  createBatch: {
    create: `${testScheduleServiceBaseUrl}/batches/createBatch`,
  },

  //View Edit Batch
  viewEditBatch: {
    getBatchById: `${testScheduleServiceBaseUrl}/batches/getBatchById`,
    getBatchByIdV2: `${testScheduleServiceBaseUrl}/batches/v2/getBatchById`,
    update: `${testScheduleServiceBaseUrl}/batches/updateBatch`,
  },

  batchList: {
    fetchAllBatches: `${testScheduleServiceBaseUrl}/batches/searchBatch?offSet`,
    update: `${testScheduleServiceBaseUrl}/batches/updateBatch`,
    downloadCsv: `${testScheduleServiceBaseUrl}/batches/searchBatchDownloadCsv`,
  },

  searchAcademicPlan: {
    getPlanList: `${testScheduleServiceBaseUrl}/academicPlan/searchPlans`,
  },
  createAcademicPlan: {
    isPlanExist: `${testScheduleServiceBaseUrl}/academicPlan/checkIfPlanExists`,
    createPlan: `${testScheduleServiceBaseUrl}/academicPlan/createPlan`,
  },
  viewAcademicPlan: {
    getPlanById: `${testScheduleServiceBaseUrl}/academicPlan/plan`,
    updatePlan: `${testScheduleServiceBaseUrl}/academicPlan/editPlan`,
  },

  academicPlanTests: {
    getTestList: `${testScheduleServiceBaseUrl}/testSchedules/searchTestSchedule`,
    getPackagesByCourseOrPackageId: `${testScheduleServiceBaseUrl}/cms/package`,
    getSyllabusByCourseId: `${testScheduleServiceBaseUrl}/cms/getSyllabus?subjectId`,
    getTestTypes: `${testScheduleServiceBaseUrl}/cms/testTypes`,
    createTest: `${testScheduleServiceBaseUrl}/testSchedules/createTestSchedule`,
    getTestSyllabusByTestScheduleId: `${testScheduleServiceBaseUrl}/testSchedules/getSyllabusByTestSchedule`,
    updateTest: `${testScheduleServiceBaseUrl}/testSchedules/editTestSchedule`,
    importTestSchedule: `${testScheduleServiceBaseUrl}/testSchedules/importTestSchedule`,
    getAllTestScheduleByAcademicPlanId: `${testScheduleServiceBaseUrl}/testSchedules/getAllTestScheduleByAcademicPlanId`,
    getTestIdsFromCMS: `${testScheduleServiceBaseUrl}/cms/getTest`,
  },

  timetable: {
    getPlansByBatch: `${testScheduleServiceBaseUrl}/academicPlan/getApplicablePlansForBatch`,
    createTimeTable: `${testScheduleServiceBaseUrl}/timeTable/createTimeTable?requireDispValue=true`,
    getPublishedTimetable: `${testScheduleServiceBaseUrl}/timeTable/getAllTimeTable`,
    getPublishedTimetableV2: `${testScheduleServiceBaseUrl}/timeTable/getPublishedTimeTable`,
    editTimeTable: `${testScheduleServiceBaseUrl}/timeTable/editTimeTable`,
    resetTimetable: `${testScheduleServiceBaseUrl}/timeTable/resetTimetable`,
    getPastTest: `${testScheduleServiceBaseUrl}/timeTable/pastTest`,
    missedTest: `${testScheduleServiceBaseUrl}/timeTable/missedTest`,
    getPastLecture: `${testScheduleServiceBaseUrl}/timeTable/pastLecture`,
    getAllPsidByBatchTimeTableId: `${testScheduleServiceBaseUrl}/timeTable/getAllPsidByBatchTimeTableId`,
  },
  lecture: {
    createLecture: `${testScheduleServiceBaseUrl}/lectures/createLecture`,
    editLecture: `${testScheduleServiceBaseUrl}/lectures/editLecture`,
    getLectures: `${testScheduleServiceBaseUrl}/lectures/getLecturesByPlanId`,
    getLecture: `${testScheduleServiceBaseUrl}/lectures/getLectureByLectureId`,
    getSubjectCourse: `${testScheduleServiceBaseUrl}/testSchedules/getUpdatedSyllabus`,
    lectureAutoPopulate: `${testScheduleServiceBaseUrl}/lectures/autoPopulateLectures`,
  },
  facultyAvailability: `${testScheduleServiceBaseUrl}/timeTable/facultyAvailability`,

  // days plan master
	daysPlan: {
		getById: `${testScheduleServiceBaseUrl}/daysPlan`,
		create: `${testScheduleServiceBaseUrl}/daysPlan`,
		delete: `${testScheduleServiceBaseUrl}/daysPlan`,
		update: `${testScheduleServiceBaseUrl}/daysPlan`,
		search: `${testScheduleServiceBaseUrl}/daysPlan/search`,
		validateDaysPlanName: `${testScheduleServiceBaseUrl}/daysPlan/duplicateDaysPlanMaster`
	},
};
