import React, { useContext } from "react";
import StudentList from "./studentList";
import { IssuanceIDCardContext, IssuanceIDCardProvider } from "./contextApi";
import { pages } from "../../common/constant";
import { PermissionContext, RolePermissions } from "appContext";
import { PermisionDenied } from "../../common/commonComponents";
import SelectMaterialAndStockIssue from "./selectMeterialAndStock/SelectMatetialAndStockIssue";
import IssueStockMiscForm from "./IssueStockMisc/IssueStockMiscForm";
const pageStateMapping = {
  SEARCH_STUDENT: <StudentList />,
  SELECT_MATERIAL: <SelectMaterialAndStockIssue initialStep={1} />,
  ISSUE_STOCK: <SelectMaterialAndStockIssue initialStep={2} />,
  ISSUE_STOCK_MISC: <IssueStockMiscForm />,
};

const GetPageComponentByState = () => {
  const { currentPageState } = useContext(IssuanceIDCardContext);

  return pageStateMapping[currentPageState];
};

const StockIssuancePage = () => {
  const userPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["stockAndInventory"]["id"]
  );

  return userPermissions ? (
    <IssuanceIDCardProvider>
      <GetPageComponentByState />
    </IssuanceIDCardProvider>
  ) : (
    <PermisionDenied />
  );
};

export default StockIssuancePage;
