import React from "react";
import { Dialog } from "@mui/material";
// import { ReactComponent as Warning } from "../../../../../../assets/img/svg/DangerWarning.svg";
// import { ReactComponent as CrossIcon } from "../../assets/img/svg/CrossIcon.svg";
import { ReactComponent as CrossIcon } from "../../../../../assets/img/svg/CrossIcon.svg";

import AButton from "../../common/form-fields-mui/AButton";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper";

const planNameStyle =  {
  inlineSize: "303px",
  overflowWrap: "break-word"

}
//metaData
  const metaDataDetails = {
     cancelBtnTxt:"No",
     agreeBtnTxt:"Yes",
     headerMsg:"",
     helperTxt:""
   }
  
const CnfDialogBox = ({ handleClose, open, onAgree,planName,isDuplicatePlan = true, metaData = metaDataDetails }) => {
  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={{ padding: "1.5rem", width: "33rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "2rem",
            marginBottom: "10px",
            color: "black",
          }}
        >
          <div
            style={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "32px",
              color: "#000000",
              marginBottom: ".6rem",
            }}
          >
            {isDuplicatePlan && <span>Are you sure you want to duplicate</span>}
            {isDuplicatePlan && <div style={planNameStyle}>{planName} ?</div>}
            {!isDuplicatePlan && <span> {metaData?.headerMsg} </span>}
          </div>
          <div>
            <IconButtonWrapper onClick={handleClose}>
              <CrossIcon />
            </IconButtonWrapper>
          </div>
        </div>
        <div>
          <div
            style={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#414141",
              marginBottom: ".6rem",
              //   padding: "10px",
            }}
          >
            {isDuplicatePlan && (
              <span>
                Lectures & Tests of this Academic Plan will be copied to the
                next syllabus plan
              </span>
            )}
            {!isDuplicatePlan && <span> {metaData?.helperTxt} </span>}
          </div>
        </div>

        <div className="mt-2 d-flex align-items-center">
          <AButton
            onClick={handleClose}
            className="mt-2 button_remove_left_margin"
            variant="outline"
            updatedStyle={{ width: "180px" }}
          >
            {isDuplicatePlan ? "NO" : metaData?.cancelBtnTxt}
          </AButton>
          <AButton
            updatedStyle={{ width: "180px" }}
            onClick={onAgree}
            className="mt-2"
            variant="primary_filled"
          >
            {isDuplicatePlan ? "Yes, Duplicate" : metaData?.agreeBtnTxt}
          </AButton>
        </div>
      </div>
    </Dialog>
  );
};

export default CnfDialogBox;
