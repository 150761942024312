import React, { useContext, useEffect, useState } from "react";
import BatchDetailsCard from "./BatchDetailsCard";
import NoBatchesCard from "./NoBatchesCard";
import { Divider } from "antd";
import ConfirmationDialog from "./ConfirmationDialog";
import {
  fetchAllPostPromisedData,
  fetchAllPromisedData,
} from "../../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint, pages } from "../../../common/constant";
import {
  failureToast,
  successToast,
} from "../../../common/utils/methods/toasterFunctions/function";
import { PermissionContext, RolePermissions } from "appContext";
import { useParams } from "react-router";
import CustomLoader from "../../../common/commonComponents/Loader/loader";

const BatchDetails = () => {
  const batchDetailsPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["studentDashboardBatchDetails"]["id"]
  );

  const { psid } = useParams();
  const [currentBatches, setCurrentBatches] = useState([]);
  const [pastBatches, setPastBatches] = useState([]);
  const [fetchBatchesLoader, setFetchBatchesLoader] = useState(false);
  const [deallocateLoader, setDeallocateLoader] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogBatchDetails, setDialogBatchDetails] = useState({});

  const deallocateStudentHandler = async (applicationId, batchId) => {
    setDeallocateLoader(true);
    const reqBody = {
      applicationIds: [applicationId],
      batchId: batchId,
      action: "DEALLOCATE",
    };

    const response = await fetchAllPostPromisedData(
      endpoint.allocateStudents.allocateDeallocate,
      reqBody,
      "post"
    );

    if (response?.code === 200) {
      if (response?.data?.length) {
        failureToast(response.message);
      } else {
        successToast(response.message);
      }
      fetchBatches();
    }
    setDeallocateLoader(false);
  };

  const fetchBatches = async () => {
    // batch API call here
    setFetchBatchesLoader(true);
    const batchDetailsResponse = await fetchAllPromisedData(
      `${endpoint.studentDetails.getAllBatchesByPsid}/${psid}`,
      true
    );
    // console.log(batchDetailsResponse)
    if (batchDetailsResponse?.code === 200) {
      const applicationBatchDetails = batchDetailsResponse?.data;

      const {
        pastApplicationBatchDetailsDTOList,
        currentApplicationBatchDetailsDTOList,
      } = applicationBatchDetails || {};

      setPastBatches(pastApplicationBatchDetailsDTOList || []);
      setCurrentBatches(currentApplicationBatchDetailsDTOList || []);
    } else {
      setPastBatches([]);
      setCurrentBatches([]);
    }
    setFetchBatchesLoader(false);
  };

  useEffect(() => {
    // console.log("batches mounted");
    fetchBatches();
    return () => {
      // console.log("batches unmounted");
      setCurrentBatches([]);
      setPastBatches([]);
    };
  }, [psid]);

  return fetchBatchesLoader || deallocateLoader ? (
    <>
      <CustomLoader apiLoader={fetchBatchesLoader || deallocateLoader} />
    </>
  ) : (
    <>
      <div className="mb-4">
        <h3 className="mb-2" style={{ fontSize: "20px" }}>
          Current batches
        </h3>
        {currentBatches?.length > 0 ? (
          <div className="d-flex flex-wrap justify-content-between">
            {currentBatches?.map((batch, idx) => (
              <BatchDetailsCard
                batchDetails={batch}
                key={`${batch?.batchId}-${idx}`}
                setIsDialogOpen={setIsDialogOpen}
                setDialogBatchDetails={setDialogBatchDetails}
                batchDetailsPermissions={batchDetailsPermissions}
              />
            ))}
          </div>
        ) : (
          <NoBatchesCard
            message={"Currently, this student is not enrolled in any batch"}
          />
        )}
      </div>
      {pastBatches?.length > 0 && (
        <>
          <Divider />
          <div className="mt-4">
            <h3 className="mb-2" style={{ fontSize: "20px" }}>
              Past batches
            </h3>
            <div className="d-flex flex-wrap justify-content-between">
              {pastBatches?.map((batch, idx) => (
                <BatchDetailsCard
                  type="past"
                  batchDetails={batch}
                  key={`${batch?.batchId}-${idx}`}
                />
              ))}
            </div>
          </div>
        </>
      )}

      <ConfirmationDialog
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        batchDetails={dialogBatchDetails}
        onAgree={deallocateStudentHandler}
      />
    </>
  );
};

export default BatchDetails;
