import React, { useState } from "react";
import ALoader from "views/pages/manage/common/a-loader";
import HeaderComponent from "../academicTimetableListView/HeaderComponent";
import GetFromProgressBar from "../lectureModule/GetFormProgressBar";
import { Card, CardBody } from "reactstrap";
import BackBtnWithChip from "../lectureModule/BackBtnWithChip";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import { ReactComponent as BackIcon } from "../../../../../../assets/img/svg/BackButton.svg";
import PublishUnpublishConfirmationDialog from "../lectureModule/PublishUnpublishConfirmationDialog";
import ReviewListComponent from "./ReviewListComponent";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper";

const ReviewAndPublish = ({
  setOpenForm,
  planData,
  batchDetails,
  lectures,
  tests,
  isNeedToPublish,
  setIsNeedToPublish,
  onEditSave,
  publishStatusTimetableHandler,
  isEnabledMsTeamsSync,
  roomsDropdown,
  setTests,
  ...props
}) => {
  const OPEN_MODE = props?.mode;
  const [apiLoader, setApiLoader] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const publishTimetable = () => {
    props.publishTimetable(tests, lectures);
  };

  return (
    <div>
      {apiLoader && <ALoader position={"fixed"} />}
      <>
        <Card className="mb-2">
          <CardBody style={{ padding: "14px 20px" }}>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <IconButtonWrapper
                  onClick={() => {
                    if (OPEN_MODE === "NEW") {
                      setOpenForm("ACADEMIC_LECTURE");
                    } else {
                      setOpenForm("ACADEMIC_LECTURE");
                    }
                  }}
                >
                  <BackIcon />
                </IconButtonWrapper>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "28px",
                    color: "#0F0F0F",
                  }}
                >
                  Review Details
                </div>
              </div>
              <GetFromProgressBar
                clickHandler={setOpenForm}
                mode={OPEN_MODE}
                step={3}
              />

              {OPEN_MODE === "NEW" && (
                <AButton variant="primary_filled" onClick={publishTimetable}>
                  Publish timetable
                </AButton>
              )}
              {OPEN_MODE === "VIEW" && (
                <AButton disabled={true} variant="primary_filled">
                  {planData?.publishStatus === "P"
                    ? "Published"
                    : "Unpublished"}
                </AButton>
              )}
              {OPEN_MODE === "EDIT" && isNeedToPublish && (
                <AButton
                  // onClick={onEditSave}
                  onClick={() => setIsDialogOpen(true)}
                  variant="primary_filled"
                >
                  Save & Publish timetable
                </AButton>
              )}
              {OPEN_MODE === "EDIT" &&
                props?.isResetEnabled &&
                !isNeedToPublish && (
                  <AButton
                    // onClick={onEditSave}
                    onClick={() => setIsDialogOpen(true)}
                    variant="primary_filled"
                  >
                    Save & Publish timetable
                  </AButton>
                )}

              {OPEN_MODE === "EDIT" &&
                !isNeedToPublish &&
                planData?.publishStatus === "P" && (
                  <AButton
                    // onClick={() =>
                    //   publishStatusTimetableHandler(planData, "U")
                    // }
                    onClick={() => setIsDialogOpen(true)}
                    variant="danger_filled"
                  >
                    Unpublish timetable
                  </AButton>
                )}
              {OPEN_MODE === "EDIT" &&
                !isNeedToPublish &&
                planData?.publishStatus === "U" && (
                  <AButton
                    // onClick={() =>
                    //   publishStatusTimetableHandler(planData, "P")
                    // }
                    onClick={() => setIsDialogOpen(true)}
                    variant="primary_filled"
                  >
                    Publish timetable
                  </AButton>
                )}
            </div>
          </CardBody>
        </Card>
        <PublishUnpublishConfirmationDialog
          open={isDialogOpen}
          setOpen={setIsDialogOpen}
          formData={planData}
          isSave={isNeedToPublish || props?.isResetEnabled}
          onEditSave={onEditSave}
          publishStatusTimetableHandler={publishStatusTimetableHandler}
        />
      </>
      <ReviewListComponent
        tests={tests}
        setTests={setTests}
        lectures={lectures}
        isEnabledMsTeamsSync={isEnabledMsTeamsSync}
        currentRoom={props?.currentRoom}
        roomsDropdown={roomsDropdown}
        formData={planData}
        onEditSave={onEditSave}
        setIsNeedToPublish={setIsNeedToPublish}
        batchDetails={batchDetails}
        {...props}
      />
    </div>
  );
};

export default ReviewAndPublish;
