import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  Button,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select";
import HouseBankDetails from "./houseBank";
import {
  getAPI,
  masterServiceBaseUrl,
  putpost,
  successToast,
  failureToast,
} from "services/http";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import moment from "moment";
import CustomLoader from "../../common/commonComponents/Loader/loader";

const SearchHouseBank = () => {
  let history = useHistory();
  const [apiLoader, setApiLoader] = useState(false);
  const [houseBankArray, setHouseBankArray] = useState([]);
  const [activeGroupCode, setActiveGroupCode] = useState([]);
  const [activeCompanyCode, setActiveCompanyCode] = useState([]);
  const [showCompanyCode, setShowCompanyCode] = useState([]);
  const [activeCountry, setActiveCountry] = useState([]);
  const [searchDataList, setSearchDataList] = useState([]);
  const [activeCurrency, setActiveCurrency] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const [search, setSearch] = useState({
    bankAccountId: "",
    bankAccountNumber: "",
    companyCodeId: null,
    groupCodeId: null,
  });

  const header = [
    { label: "HOUSE BANK ID", key: "id" },
    { label: "GROUP CODE", key: "groupCodeId" },
    { label: "COMPANY CODE", key: "companyCodeId" },
    { label: "BANK ACCOUNT ID", key: "bankAccountId" },
    { label: "BANK SHORT KEY", key: "bankShortKey" },
    { label: "REFERENCE ID", key: "referenceId" },
    { label: "BANK ID", key: "bankId" },
    { label: "BANK IFSC CODE", key: "bankIfscCode" },
    { label: "BANK ACCOUNT NUMBER", key: "bankAccountNumber" },
    { label: "ACCOUNT HOLDER NAME", key: "accountHolderName" },
    { label: "AMOUNT(Dr.)", key: "accountDr" },
    { label: "ACCOUNT(Cr.)", key: "accountCr" },
    { label: "COUNTRY", key: "country" },
    { label: "CURRENCY", key: "currency" },
    { label: "EFFECTIVE DATE", key: "effectiveDate" },
    { label: "STATUS", key: "status" },
    { label: "CREATED ON", key: "createdOn" },
    { label: "CREATED BY", key: "createdBy" },
    { label: "UPDATED ON", key: "updatedOn" },
    { label: "UPDATED BY", key: "updatedBy" },
  ];

  useEffect(() => {
    fetchHouseBankDetails(
      masterServiceBaseUrl + "/groupCode/getAllActiveGroupCode",
      setActiveGroupCode,
      "GroupCode"
    );
    fetchHouseBankDetails(
      masterServiceBaseUrl + "/companyCode/getAllActiveCompanyCode",
      setActiveCompanyCode,
      "CompanyCode"
    );
    fetchHouseBankDetails(
      masterServiceBaseUrl + "/currency/getAllActiveCurrency",
      setActiveCurrency,
      "CurrencyID"
    );
    fetchHouseBankDetails(
      masterServiceBaseUrl + "/country/getAllActiveCountry",
      setActiveCountry,
      "Country"
    );
  }, []);

  const pageSize = 10;
  const fetchSearchList = async (url) => {
    try {
      if (!search["groupCodeId"]) return failureToast("Select Group Code");
      if (!search["companyCodeId"]) return failureToast("Select Company Code");
      setApiLoader(true);
      putpost(
        url,
        (data) => {
          setSearchDataList(data["data"]);
          refactorDataForDownload(data["data"]);
          let n = pagination;
          if (data["data"].length > pageSize) {
            n["nextPage"] = n.currentPage + 1;
          }
          if (n.currentPage > 1) {
            n.previousPage = n.currentPage - 1;
          }
          n["totalPage"] = Math.floor(data["data"].length / pageSize);
          if (data["data"].length % pageSize != 0) {
            n["totalPage"] += 1;
          }
          setPagination(n);
          setHouseBankArray(
            data["data"].slice(
              pagination.currentPage * pageSize - pageSize,
              pagination.currentPage * pageSize
            ) || []
          );
          setApiLoader(false);
        },
        (data) => {
          failureToast(data["message"]);
          setApiLoader(false);
        },
        search,
        "post"
      );
    } catch (e) {
      console.log("Error", e);
    }
  };
  const nextPage = () => {
    try {
      setHouseBankArray(
        searchDataList.slice(
          (pagination.currentPage + 1) * pageSize - pageSize,
          (pagination.currentPage + 1) * pageSize
        )
      );
      let n = pagination;
      n["currentPage"] = n["currentPage"] + 1;
      n["previousPage"] = n.currentPage;
      setPagination(n);
    } catch (e) {
      console.log("Error", e);
    }
  };
  const firstPage = () => {
    try {
      setHouseBankArray(
        searchDataList.slice(1 * pageSize - pageSize, 1 * pageSize)
      );
      let n = pagination;
      n["currentPage"] = 1;
      n["previousPage"] = null;
      setPagination(n);
    } catch (e) {
      console.log("Error", e);
    }
  };
  const lastPage = () => {
    try {
      setHouseBankArray(
        searchDataList.slice(
          pagination.totalPage * pageSize - pageSize,
          pagination.totalPage * pageSize
        )
      );
      let n = pagination;
      n["currentPage"] = n.totalPage;
      n["previousPage"] = n.totalPage - 1;
      setPagination(n);
    } catch (e) {
      console.log("Error", e);
    }
  };
  const previousPage = () => {
    try {
      setHouseBankArray(
        searchDataList.slice(
          (pagination.currentPage - 1) * pageSize - pageSize,
          (pagination.currentPage - 1) * pageSize
        )
      );
      let n = pagination;
      n["currentPage"] = n["currentPage"] - 1;
      if (n["currentPage"] == 1) {
        n["previousPage"] = null;
      } else {
        n["previousPage"] = n.currentPage;
      }
      setPagination(n);
    } catch (e) {
      console.log("Error", e);
    }
  };

  const fetchHouseBankDetails = (url, stateMethod, type) => {
    try {
      getAPI(
        url,
        (data) => {
          refactorData(data["data"], stateMethod, type);
        },
        (data) => {
          failureToast(data["message"]);
        }
      );
    } catch (e) {
      console.log("Error", e);
    }
  };

  const refactorData = (data, stateMethod, type) => {
    try {
      switch (type) {
        case "GroupCode":
          iterateData(data, "id", "groupCodeDispValue", stateMethod);
          break;
        case "CompanyCode":
          iterateData(data, "id", "companyCodeDispValue", stateMethod);
          break;
        case "CurrencyID":
          iterateData(data, "id", "currencyDispValue", stateMethod);
          break;
        case "Country":
          iterateData(data, "id", "countryDispValue", stateMethod);
          break;
        case "HouseBank":
          stateMethod(data);
          break;
        default:
          break;
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const iterateData = (data, id, property, stateMethod) => {
    try {
      let temp = [];
      data.map((item) => {
        temp.push({
          value: item[id],
          label: item[property],
        });
      });
      stateMethod(temp);
    } catch (e) {
      console.log("Error", e);
    }
  };

  const getDisplayValue = (id, type) => {
    try {
      switch (type) {
        case "GroupCodeID":
          return findDisplayValue(activeGroupCode, id);
          break;
        case "CompanyCodeID":
          return findDisplayValue(activeCompanyCode, id);
          break;
        case "CurrencyID":
          return findDisplayValue(activeCurrency, id);
          break;
        case "CountryID":
          return findDisplayValue(activeCountry, id);
          break;
        default:
          break;
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const findDisplayValue = (datalist, id) => {
    try {
      const findIndex = datalist.findIndex((item) => item["value"] === id);
      return findIndex >= 0 ? datalist[findIndex]["label"] : "NOT FOUND";
    } catch (e) {
      console.log("Error", e);
    }
  };

  const refactorDataForDownload = (data) => {
    try {
      let temp = [];
      data.map((item) => {
        temp.push({
          id: item["id"],
          accountCr: item["accountCr"],
          accountDr: item["accountDr"],
          accountHolderName: item["accountHolderName"],
          bankAccountId: item["bankAccountId"],
          bankAccountNumber: item["bankAccountNumber"],
          bankId: item["bankId"],
          bankIfscCode: item["bankIfscCode"],
          bankShortKey: item["bankShortKey"],
          companyCodeId: item["companyCodeId"]
            ? getDisplayValue(item["companyCodeId"], "CompanyCodeID")
            : "",
          country: item["country"]
            ? getDisplayValue(item["country"], "CountryID")
            : "",
          createdBy: item["createdBy"]
            ? moment(item["createdBy"]).format("DD-MM-YYY hh:mm:ss")
            : "",
          createdOn: item["createdOn"]
            ? moment(item["createdOn"]).format("DD-MM-YYY hh:mm:ss")
            : "",
          currency: item["currency"]
            ? getDisplayValue(item["currency"], "CurrencyID")
            : "",
          effectiveDate: item["effectiveDate"],
          groupCodeId: item["groupCodeId"]
            ? getDisplayValue(item["groupCodeId"], "GroupCodeID")
            : "",
          referenceId: item["referenceId"],
          status: item["status"],
          updatedBy: item["updatedBy"]
            ? moment(item["updatedBy"]).format("DD-MM-YYY hh:mm:ss")
            : "",
          updatedOn: item["updatedOn"]
            ? moment(item["updatedOn"]).format("DD-MM-YYY hh:mm:ss")
            : "",
        });
      });
      setDownloadData(temp);
    } catch (e) {
      console.log("Error", e);
    }
  };

  const searchData = (value, property) => {
    setSearch({
      ...search,
      [property]: value,
    });
  };

  const resetSearch = () => {
    try {
      setSearch({
        bankAccountId: "",
        bankAccountNumber: "",
        companyCodeId: null,
        groupCodeId: null,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const getCompanyCode = (value) => {
    try {
      getAPI(
        masterServiceBaseUrl + `/companyCode/getCompanyCodeByGroup/${value}`,
        (data) => {
          let tempCompanyCode = [];
          data &&
            data.data.map((item) => {
              tempCompanyCode.push({
                value: item.id,
                label: item.companyCodeDispValue,
              });
            });
          setSearch((preState) => ({
            ...preState,
            companyCodeId: "",
          }));
          setShowCompanyCode(tempCompanyCode);
        },
        (data) => {
          setSearch((preState) => ({
            ...preState,
            companyCodeId: "",
          }));
          setShowCompanyCode([]);
          failureToast(data["message"]);
        }
      );
    } catch (e) {
      console.log("Error", e);
    }
  };

  return (
    <Fragment>
      <Container className="mt-3" fluid={true}>
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft headingColor">
                  Search House Bank
                </h3>
                <Button
                  color="info"
                  size="sm"
                  type="button"
                  className="floatRight"
                  onClick={() =>
                    history.push("/admin/houseBank/addNewHouseBank")
                  }
                >
                  Add New House Bank
                </Button>
              </CardHeader>
              <hr />
              <CardBody>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="form-control-label">
                        <span className="custom-span-color">*</span>Group Code
                      </Label>
                      <Select
                        value={{
                          value: search["groupCodeId"],
                          label: search["groupCodeId"] ? (
                            getDisplayValue(
                              search["groupCodeId"],
                              "GroupCodeID"
                            )
                          ) : (
                            <span className="customSpan">
                              Select Group Code
                            </span>
                          ),
                        }}
                        options={activeGroupCode}
                        onChange={(e) => {
                          getCompanyCode(e.value);
                          searchData(e.value, "groupCodeId");
                        }}
                      ></Select>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="form-control-label">
                        <span className="custom-span-color">*</span>Company Code
                      </Label>
                      <Select
                        value={{
                          value: search["companyCodeId"],
                          label: search["companyCodeId"] ? (
                            getDisplayValue(
                              search["companyCodeId"],
                              "CompanyCodeID"
                            )
                          ) : (
                            <span className="customSpan">
                              Select Company Code
                            </span>
                          ),
                        }}
                        options={showCompanyCode}
                        onChange={(e) => searchData(e.value, "companyCodeId")}
                      ></Select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="form-control-label">
                        Bank Account ID
                      </Label>
                      <Input
                        maxLength={50}
                        value={search["bankAccountId"]}
                        className="customInput"
                        onChange={(e) =>
                          searchData(e.target.value, "bankAccountId")
                        }
                        placeholder="Enter Bank Account ID"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="form-control-label">
                        Bank Account Number
                      </Label>
                      <Input
                        value={search["bankAccountNumber"]}
                        className="customInput"
                        placeholder="Bank Account Number"
                        onChange={(e) =>
                          searchData(e.target.value, "bankAccountNumber")
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="floatRight">
                      <Button
                      data-testid="searchDataHouseBank"
                        size="md"
                        type="button"
                        color="info"
                        onClick={() =>
                          fetchSearchList(masterServiceBaseUrl + "/houseBank/search")
                        }
                      >
                        {" "}
                        <i className="fas fa-search"></i> Search
                      </Button>
                      <Button
                        size="md"
                        type="button"
                        color="info"
                        onClick={() => resetSearch()}
                      >
                        Reset Filters
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {apiLoader ? (
        <CustomLoader
          apiLoader={apiLoader}
          loaderHeight={"200px"}
          loaderWidth={"100% "}
        />
      ) : !(searchDataList.length === 0) ? (
        <Container fluid={true}>
          <Row>
            <Col>
              <Card>
                <CardHeader className="border-0">
                  <h3 className="mb-0 floatLeft headingColor">
                    Manage House Bank
                  </h3>
                  <div className="floatRight">
                    <CSVLink
                      data={downloadData}
                      headers={header}
                      filename={"house_bank_search_details.csv"}
                      className="mr-3"
                    >
                      <Button color="info" size="sm" type="button">
                        Download
                      </Button>
                    </CSVLink>
                    <Button color="info" size="sm" type="button">
                      Filter
                    </Button>
                  </div>
                </CardHeader>
                <hr />
                <CardBody className="customCardBody">
                  <HouseBankDetails
                    activeGroupCode={activeGroupCode}
                    activeCompanyCode={activeCompanyCode}
                    searchDataList={searchDataList}
                    fetchSearchList={fetchSearchList}
                    previousPage={previousPage}
                    lastPage={lastPage}
                    firstPage={firstPage}
                    nextPage={nextPage}
                    pagination={pagination}
                    setPagination={setPagination}
                    houseBankArray={houseBankArray}
                    setHouseBankArray={setHouseBankArray}
                    searchDataList={searchDataList}
                    setSearchDataList={setSearchDataList}
                    getDisplayValue={getDisplayValue}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : null}
    </Fragment>
  );
};

export default SearchHouseBank;
