import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Card, CardHeader, Col, Button } from "reactstrap";
import Select from "react-select";
import SearchTable from "./searchTable";
import { fetchDetails, getRequiredSign } from "../../common/utils/methods/commonMethods/utilityMethod";
import {
  masterServiceBaseUrl,
  examControllerServiceBaseUrl,
  failureToast,
  putpost,
  successToast,
} from "services/http";
import CustomLoader from "../../common/commonComponents/Loader/loader";

const data = [
  {
    id: 10,
    groupCode: 31,
    companyCodeId: 85,
    termId: 3,
    academicCareerId: 63,
    businessAreaId: 57,
    courseId: 1664655,
    effectiveDate: "2021-08-23T12:15:03.000+00:00",
    status: "ACTIVE",
    createdBy: null,
    updatedBy: null,
    createdOn: "2021-08-23T12:15:03.000+00:00",
    updatedOn: "2021-08-23T12:15:03.000+00:00",
    isDeleted: false,
  },
];

const ExamDate = (props) => {
  const [values, setValues] = useState({
    businessArea: null,
    courseId: null,
    academicCareer: null,
    term: null,
    status: null,
  });

  const [businessAreaData, setBusinessAreaData] = useState(null);
  const [courseIdData, setCourseIdData] = useState(null);
  const [termData, setTermData] = useState(null);
  const [academicCareerData, setAcademicCareerData] = useState(null);
  const [statusData, setStatusData] = useState([
    { label: "ACTIVE", value: "ACTIVE" },
    { label: "INACTIVE", value: "INACTIVE" },
  ]);
  const [isFetching, setIsFetching] = useState(true);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [serverData, setServerData] = useState(null);

  useEffect(() => {
    fetchDetails(
      masterServiceBaseUrl + `/academicCareer/getAllActiveAcademicCareer`,
      setAcademicCareerData,
      "academicCareer",
      "select"
    );
    fetchDetails(
      masterServiceBaseUrl + `/businessArea/getAllActiveBusinessArea`,
      setBusinessAreaData,
      "businessArea",
      "select"
    );
    fetchDetails(
      masterServiceBaseUrl + `/term/getAllActiveTerm`,
      setTermData,
      "term",
      "select"
    );
    fetchDetails(
      masterServiceBaseUrl + `/courseCatalogCourseHeader/getAllCourseCatalogCourseHeader`,
      setCourseIdData,
      "courseId",
      "courseId"
    );
    // fetchDetails(masterServiceBaseUrl + `/groupCode/getAllActiveGroupCode`, setGroupCodeData, 'groupCode', 'select');
  }, []);

  useEffect(() => {
    if (businessAreaData && courseIdData && termData && academicCareerData) {
      setIsFetching(false);
    }
  }, [businessAreaData, courseIdData, termData, academicCareerData]);

  const onSearch = () => {
    if (!values?.term?.value) {
      failureToast("Please select term ");
      return;
    }
    if (!values?.courseId?.value) {
      failureToast("Please select course Id");
      return;
    }

    setIsFetchingData(true);

    const serverData = {
      academicCareerId: (values?.academicCareer?.value) ?? '',
      businessAreaId: (values?.businessArea?.value) ?? '',
      courseId: values?.courseId?.value,
      status: (values?.status?.value) ?? '',
      termId: values?.term?.value,
    };

    putpost(
      examControllerServiceBaseUrl + "/examDateSearch/examDateSearch",
      (data) => {
        setServerData(data.data);
        successToast(data["message"]);
        setIsFetchingData(false);
      },
      (data) => {
        failureToast(data["message"]);
        setServerData(null)
        setIsFetchingData(false);
      },
      serverData,
      "post"
    );
  };

  const onReset = () => {
    setValues({
      businessArea: null,
      courseId: null,
      academicCareer: null,
      term: null,
      status: null,
    });
    setServerData(null)
  };

  return (
    <Container fluid className="mt-3">
      <Card>
        <CardHeader className="d-flex justify-content-between">
          {/* <h3>Exam Date configuration</h3> */}
          <h3>Exam Date Configuration Search</h3>
          <Button
            color="info"
            size="sm"
            type="button"
            className={"floatRight mx-1"}
            onClick={() => props.history.push("/admin/examDateConfig/new")}
          >
            New Exam Details
          </Button>
        </CardHeader>
        {isFetching ? (
          <div
            className="mx-auto text-center py-5 my-5"
            style={{ height: "100vh" }}
          >
            <CustomLoader apiLoader={isFetching} />
          </div>
        ) : (
          <div className="p-4">
            <div className="form-row mb-3">
              <Col className="mb-3" md="6">
                <label className="form-control-label">Academic Career</label>
                <Select
                  value={values.academicCareer}
                  id="academic_examConfig"
                  placeholder="Select Academic Career"
                  options={academicCareerData}
                  onChange={(sdata) =>
                    setValues({ ...values, academicCareer: sdata })
                  }
                />
              </Col>
              <Col className="mb-3" md="6">
                <label className="form-control-label">Business Area</label>
                <Select
                  value={values.businessArea}
                  id="business_examConfig"
                  options={businessAreaData}
                  placeholder="Select Business Area"
                  onChange={(sdata) =>
                    setValues({ ...values, businessArea: sdata })
                  }
                />
              </Col>
            </div>
            <div className="form-row mb-3">
              <Col className="mb-3" md="6">
                <label className="form-control-label">
                  Term{getRequiredSign()}
                </label>
                <Select
                  value={values.term}
                  id="term_examConfig"
                  placeholder="Select Term"
                  options={termData}
                  onChange={(sdata) => setValues({ ...values, term: sdata })}
                />
              </Col>
              <Col className="mb-3" md="6">
                <label className="form-control-label">
                  Course ID{getRequiredSign()}
                </label>
                <Select
                  value={values.courseId}
                  id="course_examConfig"
                  options={courseIdData}
                  placeholder="Select Course ID"
                  onChange={(sdata) =>
                    setValues({ ...values, courseId: sdata })
                  }
                />
              </Col>
            </div>
            <div className="form-row mb-3">
              <Col className="mb-3" md="6">
                <label className="form-control-label">Status</label>
                <Select
                  value={values.status}
                  id="status_examConfig"
                  placeholder="Select Status"
                  options={statusData}
                  onChange={(sdata) => setValues({ ...values, status: sdata })}
                />
              </Col>
              <Col
                className="mb-3 d-flex align-items-end justify-content-end"
                md="6"
              >
                <Button
                  color="info"
                  size="sm"
                  type="button"
                  className={"floatRight mx-1"}
                  onClick={onSearch}
                >
                  Search
                </Button>
                <Button
                  color="info"
                  size="sm"
                  type="button"
                  className={"floatRight mx-1"}
                  onClick={onReset}
                >
                  Reset
                </Button>
                {/* <Select
                value={''}
                id="created_examConfig"
                options={[]}
              /> */}
              </Col>
            </div>
          </div>
        )}
      </Card>
      {isFetchingData && !isFetching ? (
        <div
          className="mx-auto text-center py-5 my-5"
          style={{ height: "100vh" }}
        >
          <CustomLoader apiLoader={isFetchingData} />
        </div>
      ) : !serverData ? null : (
        <SearchTable
          onSearch={onSearch}
          data={serverData}
          businessAreaData={businessAreaData}
          courseIdData={courseIdData}
          termData={termData}
          academicCareerData={academicCareerData}
        />
      )}
    </Container>
  );
};

export default withRouter(ExamDate);
