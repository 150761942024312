import {putpost} from "../../../../../../services/http";
import {endpoint} from "../../../common/constant";
import {failureToast} from "../../../common/utils/methods/toasterFunctions/function";
import moment from "moment";

const MANDATORY_DROPDOWN_VALUES = [
    {value:true,label:'Yes',text : 'Yes'},
    {value:false,label:'No',text : 'No'}
];
const FIELD_CONFIGURATION_TABLE_HEADER = [
    { name : 'S. No.',width : '8%' , className:'p-l-4',
        styles:{'padding': "12px 10px 12px 28px",}
        },
    { name : "Field Name",width:'20%',styles:{'padding': "12px 10px 12px 10px",}},
    { name : "Field Type",width:'20%',styles:{'padding': "12px 10px 12px 10px",}},
    { name : "Required Message",width:'20%',styles:{'padding': "12px 10px 12px 10px",}},
    { name : "Error",width:'20%',styles:{'padding': "12px 10px 12px 10px",}},
    { name : "Mandatory",width:'11%',styles:{'padding': "12px 10px 12px 10px",}},
    { name : "Length",width:'8%',styles:{'padding': "12px 10px 12px 10px",}},
    { name : "Action",width:'8%',styles:{'padding': "12px 10px 12px 10px",}}
];

const TEMPLATE_HEADER_OBJECT = {
    documentDesc: "",
    documentServiceTypeDispValue: "",
    documentTypeKey: "",
    serviceType: "",
    status: {value:'ACTIVE',label:'ACTIVE'},
    templateName: "",
    documentFormatId:'',
    maxRows: null,
    maxSize: null
}

const getFormatRequestDTO = (data=[],templateId = null)=>{
    let tempArray = [];
    if(data.length){
        data.forEach((item,index)=>{
            tempArray.push({
                documentTemplateId : templateId,
                templateDatatypeMasterId:  item?.templateDatatypeMasterId?.value ?? item?.templateDatatypeMasterId,
                columnSequence: index+1,
                // isMandatory:  !!item?.isMandatory?.value ,
                isMandatory:  item?.isMandatory ? item?.isMandatory?.value : item?.required,
                columnDataLength: item?.columnDataLength ?? '',
                error: item?.error ??'',
                required_message: item?.required_message ??'',
                columnName: item?.columnName ?? '',
                status: "ACTIVE"
            })
        })
    }
    return {
        documentTemplateId: templateId,
        documentTemplateMetaDataDTO: tempArray
    }
}
const getTemplateName =(formData)=>{
    return `${formData?.serviceType?.tagTypeDispValue}_${moment().valueOf()}` || null
}


const getFormattedCreateTemplateDTO = (formData)=>{
    return {
        documentDesc: formData?.serviceType?.tagTypeDesc || null,
        documentServiceTypeDispValue: formData?.serviceType?.tagTypeDispValue || null,
        documentTypeKey: formData?.serviceType?.tagTypeKey || null,
        serviceType: formData?.serviceType?.tagTypeKey || null,
        status: formData?.status?.value || null,
        templateName: getTemplateName(formData),
        error: formData?.error ??'',
        required_message: formData?.required_message ??'',
        documentFormatId: formData?.documentFormatId?.id || null,
        maxRows:  Number(formData.maxRows),
        maxSize: Number(formData.maxSize),
        processTagTypeId: formData.serviceType?.id ?? null
    };
}
const getIsMandatory = (isMandatory)=>{
    const label = isMandatory ? "Yes" : "No";
    return {
        value : isMandatory,
        label : label
    }
}


const validateTemplateDetails =(data)=>{
    if(data.serviceType === '' || !data.serviceType){
        failureToast('Please Select Process.');
        return false;
    }else if(data.documentFormatId === '' || !data.documentFormatId){
        failureToast('Please Select Document Format.');
        return false;
    }
    return true;
}

const validateColumns = (data)=>{
    if(data.length > 0){
        let flag = true;
        data.every((item,index)=>{
            if(item.columnName === '' || !item.columnName){
                failureToast(`Please enter Field Name in Row ${index+1}`);
                flag = false;
                return false;
            } else if(item.templateDatatypeMasterId === '' || !item.templateDatatypeMasterId){
                failureToast(`Please Select Field Type in Row ${index+1}`);
                flag = false;
                return false;
            }  else if(item.required_message === '' || !item.required_message){
                failureToast(`Please enter required message in Row ${index+1}`);
                flag = false;
                return false;
            } else if(item.error === '' || !item.error){
                failureToast(`Please enter error in Row ${index+1}`);
                flag = false;
                return false;
            } else if(item.columnDataLength === '' || !item.columnDataLength){
                failureToast(`Please enter Field Length in Row ${index+1}`);
                flag = false;
                return false;
            }
        })
        return flag;
    }
}
const addNewRowsInTemplateTable = (data=[],n=0)=>{
    let newTableData = [...data];
    if(n>0){
        for (let i = 0; i < n; i++) {
            let newObject = {
                templateDatatypeMasterId: '',
                columnSequence: '',
                isMandatory: {value: false, label: 'No'},
                columnDataLength: '',
                columnName: '',
                error:'',
                required_message:'',
                status: '',
                isNew: true
            }
            newTableData.push(newObject);
        }
        return newTableData;
    }else if(n === 0){
        return newTableData;
    }
}

const getFormattedTemplateDTO = (dataObj,MasterData=[]) =>{
    return {
        id: dataObj.id,
        documentDesc: dataObj.documentDesc,
        documentServiceTypeDispValue: dataObj.documentServiceTypeDispValue,
        documentTypeKey: dataObj.documentTypeKey,
        process: dataObj.documentServiceTypeDispValue,
        serviceType: {
            tagTypeKey: dataObj.documentTypeKey,
            tagTypeDesc: dataObj.documentDesc
        },

        status: getStatusValue(dataObj.status),
        templateName: dataObj.templateName,
        formatId : dataObj.documentFormatId,
        documentFormatId: {label : MasterData.filter(item => item.id === dataObj.documentFormatId)[0]?.label,value :dataObj.documentFormatId},
        maxSize:dataObj?.maxSize ?? '',
        maxRows:dataObj?.maxRows ?? '',
        processTagTypeId: dataObj.processTagTypeId ?? null
    };
}
const getStatusValue =(status=null)=>{
    if(status){
        return {
            value : status,
            label : status
        }
    }
}
const getTemplateTableFieldAttributes = (props)=>{
    return {
        fieldName: {
            type: 'text',
            placeHolder: 'Field Name',
            defaultValue: props?.item?.columnName ?? '',
            onChange: (value) => props.updateDataHandler('columnName', value, 'fieldsTable', props.index),
            forTable: true,
        },
        error: {
            type: 'text',
            placeHolder: 'Error',
            defaultValue: props?.item?.error ?? '',
            onChange: (value) => props.updateDataHandler('error', value, 'fieldsTable', props.index),
            forTable: true,
        },
        required_message: {
            type: 'text',
            placeHolder: 'Required Message',
            defaultValue: props?.item?.required_message ?? '',
            onChange: (value) => props.updateDataHandler('required_message', value, 'fieldsTable', props.index),
            forTable: true,
        },
        mandatory: {
            type: 'select',
            placeHolder: 'Mandatory',
            defaultValue: props?.item?.isMandatory ?? '',
            onChange: (value) => props.updateDataHandler('isMandatory', value, 'fieldsTable', props.index),
            forTable: true,
            data: MANDATORY_DROPDOWN_VALUES
        },
        fieldType: {
            type: 'select',
            placeHolder: 'Field Type',
            defaultValue: props?.item?.templateDatatypeMasterId ?? '',
            onChange: (value) => props.updateDataHandler('templateDatatypeMasterId', value, 'fieldsTable', props.index),
            forTable: true,
            data: props.MasterData.templateDataType || []
        },
        fieldLength: {
            type: 'number',
            placeHolder: 'Length',
            defaultValue: props?.item?.columnDataLength ?? '',
            maxLength: 5,
            onChange: (value) => props.updateDataHandler('columnDataLength', value, 'fieldsTable', props.index),
            forTable: true,
        },
    }
}

const getTemplateHeaderFieldsAttributes =(props)=> {


    return {
        process : {
            col : 3,
            label :'Process',
            mandatory:true,
            name:'serviceType',
            data:props.MasterData.processType || [],
            type:props.action === 'new' ?'select' : 'text' ,
            placeHolder:'Select Process',
            defaultValue:props.templateHeaderData && props.templateHeaderData.serviceType && props.action === 'new' ? props.templateHeaderData.serviceType : props.templateHeaderData.process,
            isDisabled:(props.action === 'view' || props.action === 'edit'),
            onChange:(value)=>props.updateHeader('serviceType',value,'header'),
        },
        serviceName : {
            col : 3,
            label:'Service Name',
            type:'text',
            placeHolder:'Service Name',
            defaultValue: props?.templateHeaderData?.serviceType?.tagTypeKey ?? '',
            isDisabled : true
        },
        processTypeDescription : {
            col : 3,
            label : 'Process Type Description',
            type : 'text',
            placeHolder : 'Process Type Description',
            defaultValue : props?.templateHeaderData?.serviceType?.tagTypeDesc ?? "",
            isDisabled : true,
        },
        documentFormat : {
            col : 3,
            label : 'Document Format',
            mandatory : true,
            data : props.MasterData.documentFormat ?? [],
            type : (props.action === 'view' || props.action !== 'new' && !props.isHeaderInEditMode) ? 'text' : 'select' ,
            placeHolder : 'Document Format',
            defaultValue :( props.action !== 'new' && !props.isHeaderInEditMode) || props.action === 'view' ? props?.templateHeaderData?.documentFormatId?.label :  props?.templateHeaderData?.documentFormatId,
            isDisabled : ( props.action !== 'new' && !props.isHeaderInEditMode) || props.action === 'view',
            onChange : (value)=>{props.updateHeader('documentFormatId',value,'header')},
        },
        processTypeDescription : {
            col : 3,
            label : 'Process Type Description',
            type : 'text',
            placeHolder : 'Process Type Description',
            defaultValue : props?.templateHeaderData?.serviceType?.tagTypeDesc ?? "",
            isDisabled : true,
        },
        maxSize : {
            col : 3,
            label : 'Max File Size ( IN MB )',
            type : 'number',
            mandatory:true,
            placeHolder : 'Max File Size',
            defaultValue : props?.templateHeaderData?.maxSize ?? "",
            isDisabled : ( props.action !== 'new' && !props.isHeaderInEditMode) || props.action === 'view',
            onChange : (value)=>{props.updateHeader('maxSize',value,'header')},
            maxLength: 4
        },
        maxRows : {
            col : 3,
            label : 'Max Rows In File',
            type : 'number',
            mandatory:true,
            placeHolder : 'Max Rows In File',
            defaultValue : props?.templateHeaderData?.maxRows ?? "",
            isDisabled : ( props.action !== 'new' && !props.isHeaderInEditMode) || props.action === 'view',
            onChange : (value)=>{props.updateHeader('maxRows',value,'header')},
            maxLength: 6
        },
        // processTagTypeId : {
        //     col : 3,
        //     label : 'Process Tag Type',
        //     mandatory : true,
        //     data : props.MasterData.processTagType ?? [],
        //     type : (props.action === 'view' || props.action !== 'new' && !props.isHeaderInEditMode) ? 'text' : 'select' ,
        //     placeHolder : 'process Tag Type',
        //     defaultValue :( props.action !== 'new' && !props.isHeaderInEditMode) || props.action === 'view' ? props?.templateHeaderData?.processTagTypeId?.label :  props?.templateHeaderData?.processTagTypeId,
        //     isDisabled : ( props.action !== 'new' && !props.isHeaderInEditMode) || props.action === 'view',
        //     onChange : (value)=>{props.updateHeader('processTagTypeId',value,'header')},
        // },
        status : {
            col :3,
            label : 'Status',
            data : props.MasterData.status || [],
            type : (props.action === 'view' || props.action !== 'new' && !props.isHeaderInEditMode) ? 'text' : 'select' ,
            placeHolder : 'Status',
            defaultValue : (props.action === 'view' || props.action !== 'new' && !props.isHeaderInEditMode)  ? props?.templateHeaderData?.status?.label:  props?.templateHeaderData?.status,
            isDisabled : ( props.action !== 'new' && !props.isHeaderInEditMode) || props.action === 'view',
            onChange : (value)=>{props.updateHeader('status',value,'header')}
        }
    }
}

const CreateTemplateHeader = async (payload)=>{
    return new Promise((resolve,reject)=>{
        putpost(endpoint.templateUpload.create,
            (data)=>{
                resolve(data);
            },
            (data)=>{
                reject(data)
            },
            payload,
            'post' )
            .then(res=>{});
    })
}

const UpdateTemplateHeader = async (payload,id=null)=>{
    return new Promise((resolve,reject)=>{
        putpost(`${endpoint.templateUpload.update}/${id}`,
            (data)=>{
                resolve(data);
            },
            (data)=>{
                failureToast(data.message)
                reject(data)
            },
            payload,
            'put' )
            .then(res=>{});
    })
}


export default  {
    CreateTemplateHeader : CreateTemplateHeader,
    UpdateTemplateHeader : UpdateTemplateHeader,
    getFormatRequestDTO : getFormatRequestDTO,
    getFormattedTemplateDTO : getFormattedTemplateDTO,
    validateColumns : validateColumns,
    getIsMandatory : getIsMandatory,
    validateTemplateDetails : validateTemplateDetails,
    addNewRowsInTemplateTable : addNewRowsInTemplateTable,
    getTemplateTableFieldAttributes : getTemplateTableFieldAttributes,
    getTemplateHeaderFieldsAttributes : getTemplateHeaderFieldsAttributes,
    getFormattedCreateTemplateDTO : getFormattedCreateTemplateDTO,
    MANDATORY_DROPDOWN_VALUES : MANDATORY_DROPDOWN_VALUES,
    FIELD_CONFIGURATION_TABLE_HEADER : FIELD_CONFIGURATION_TABLE_HEADER,
    TEMPLATE_HEADER_OBJECT : TEMPLATE_HEADER_OBJECT
}