import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'
import CustomLoader from '../../common/commonComponents/Loader/loader';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import TestSyllabusChapterRow from './TestSyllabusChapterRow';
import { fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../common/constant';

const TestSyllabus = ({ testData, isTimetable = false, isNeedToFilterSubject = false }) => {
  
  const syllabusContainerRef = useRef(0);
  const [apiLoader, setApiLoader] = useState(false);
  const [testSyllabus, setTestSyllabus] = useState([]);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const fetchTestSyllabus = async () => {
    setApiLoader(true);
    
    const testId  = isTimetable ? testData?.testScheduleId|| testData?.id:testData?.id ;
     if(isNeedToFilterSubject){
      const res = await fetchAllPromisedData(`${endpoint?.lecture?.getLecture}/${testId}`, true);
      setTestSyllabus([res?.data])
     }else{
      const res = await fetchAllPromisedData(`${endpoint?.academicPlanTests?.getTestSyllabusByTestScheduleId}/${testId}`, true);
      setTestSyllabus(res?.data?.testContent)
     }
    setApiLoader(false);
    // setIsSyllabusFetched(true);
  }

  useEffect(() => {
    // get test syllabus API here
    // if(!isSyllabusFetched) fetchTestSyllabus();
    fetchTestSyllabus();
  }, [])

  return (
    <div className={`${styles.test_syllabus_container}`}>
      {apiLoader ? (
        <CustomLoader />
      ) : (
        !testSyllabus?.length ? (
          <div className='d-flex justify-content-center'>No data found</div>
        ) : (
          <div>
            <TabContext value={currentTabIndex} >
              {!isNeedToFilterSubject && <TabList
                onChange={(e, value) => {setCurrentTabIndex(value); syllabusContainerRef.current.scroll({top: 0, behavior: 'smooth'}) }}
                sx={{
                  backgroundColor: 'inherit',
                  minHeight: '25px',
                  borderBottom: '1px solid #EDEDED',
                  '& .MuiTab-root': {
                    color: '#757575',
                    fontSize: '16px',
                    fontWeight: 600,
                    minHeight: '25px',
                    height: '35px',
                    textTransform: 'none'
                  },
                  '& .MuiTab-root.Mui-selected': {
                    color: '#0F0F0F',
                    borderBottom: '3px solid #00B0F5'
                  },
                  '& .MuiTabs-indicator': {
                    display: 'none'
                  }
                }}
              >
                {testSyllabus?.map((item, index) => <Tab key={index} value={index} label={item?.subject} />)}
              </TabList>
}
              <TabPanel value={currentTabIndex} style={{ flex: 1, padding: '12px' }}>
                <div className={styles.test_syallabus_chapter_container}>
                  <div style={{  }}>Chapter</div>
                  <div className={styles.test_syallabus_topic_container} style={{  }}>
                    <div style={{  }}>Topic</div>
                    <div style={{  }}>Sub-topic</div>
                  </div>
                </div>
                <div ref={syllabusContainerRef} className='w-100' style={{maxHeight: '50vh', overflow: 'auto'}}>
                  {testSyllabus?.[currentTabIndex]?.['tableOfContent']?.map((chapterObject, index) => {
                    return <TestSyllabusChapterRow chapterObject={chapterObject} key={chapterObject?.title} /> 
                  })}
                  {!testSyllabus?.[currentTabIndex]?.['tableOfContent']?.length && <div className='d-flex justify-content-center'>No data found</div>}
                </div>
              </TabPanel>
            </TabContext>
          </div>
        )
      )}
    </div>
  )
}

export default TestSyllabus