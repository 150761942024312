import React from 'react';
import {Col, Form, Row} from "reactstrap";
import CommonInput from "../../../common/formFeilds/input/commonInput";
import {
    fetchAllData,
    fetchDetails,
    getDispValForReactSelectByOneID
} from "../../../common/utils/methods/commonMethods/utilityMethod";
import {masterServiceBaseUrl, pricingServiceBaseUrl} from "services/http";
import moment from "moment";
import {label} from "./properties";

const FeeSetupRulesHeader = (props)=>{
    const {
        action,
        groupCode=[],
        companyCode=[],
        allCompanyCode=[],
        allTermData=[],
        academicCareer=[],
        status=[],
        headerData,
        updateHeaderData,
        setCompanyCode,
        setTerm,
        termDropDownData,
        setTermDropDownData,
        setAcademicCareer,
        setCourseAttributeValueMappingData,
        label,
        placeholder
    } = props

    return (
        <Form>
            <Row>
                <Col md={3}>
                    <CommonInput label={label.businessGroup}
                                 mandatory={true}
                                 data={groupCode}
                                 type={action === 'new' ? 'reactSelect' : 'text'}
                                 placeHolder={placeholder.businessGroup}
                                 defaultValue={action === 'new' ? headerData["businessGroupId"]: getDispValForReactSelectByOneID(headerData["businessGroupId"], 'DispValue', groupCode)}
                                 isDisabled={action !== 'new'}
                                 onChange={(value)=>{
                                     if(value && action === 'new') {
                                         updateHeaderData('businessGroupId',value);
                                         setTermDropDownData([]);
                                         setCompanyCode([]);
                                         if(headerData['companyCodeId'] !== '' && headerData['academicCareerId'] !== ''){
                                             fetchAllData(pricingServiceBaseUrl + `/getAllTermByGroupCodeAcademicCareerCompanyCode/${value},${headerData['academicCareerId']},${headerData['companyCodeId']}`, setTerm);
                                         }
                                         setAcademicCareer([])
                                         fetchDetails(masterServiceBaseUrl + `/companyCode/getCompanyCodeByGroup/${value}`, setCompanyCode, 'companyCode', 'reactSelect');
                                     }
                                 }}
                    />
                </Col>
                <Col md={3}>
                    <CommonInput label={label.companyCode}
                                 mandatory={true}
                                 data={!(action === 'new') ? allCompanyCode : companyCode}
                                 type={action === 'new' ? 'reactSelect' : 'text'}
                                 placeHolder={placeholder.companyCode}
                                 defaultValue={action === 'new' ? headerData["companyCodeId"] : getDispValForReactSelectByOneID(headerData["companyCodeId"], 'DispValue', allCompanyCode)}
                                 isDisabled={action !== 'new'}
                                 onChange={(value)=>{
                                     if(action === 'new'){
                                         updateHeaderData('companyCodeId', value);
                                         setTermDropDownData([]);
                                         setAcademicCareer([]);
                                         fetchDetails(masterServiceBaseUrl + `/academicCareer/getAcademicCareerByCompanyCode/${value}`, setAcademicCareer, 'academicCareer', 'reactSelect');
                                         if (headerData['businessGroupId'] !== '' && headerData['academicCareerId'] !== '') {
                                             fetchAllData(pricingServiceBaseUrl + `/getAllTermByGroupCodeAcademicCareerCompanyCode/${headerData['businessGroupId']},${headerData['academicCareerId']},${value}`, setTerm);
                                         }
                                     }
                                 }}
                    />
                </Col>
                <Col md={3}>
                    <CommonInput label={label.academicCareer}
                                 mandatory={true}
                                 data={academicCareer}
                                 type={action === 'new' ? 'reactSelect' : 'text'}
                                 placeHolder={placeholder.academicCareer}
                                 defaultValue={action === 'new' ? headerData["academicCareerId"] : getDispValForReactSelectByOneID(headerData["academicCareerId"], 'DispValue', academicCareer)}
                                 isDisabled={action !== 'new'}

                                onChange={(value)=>{
                                    updateHeaderData('academicCareerId',value);
                                    setTermDropDownData([])
                                    if(headerData['businessGroupId'] !== '' && headerData['companyCodeId'] !== ''){
                                        fetchAllData(pricingServiceBaseUrl + `/getAllTermByGroupCodeAcademicCareerCompanyCode/${headerData['businessGroupId']},${value},${headerData['companyCodeId']}`, setTerm);
                                    }
                                }}
                    />
                </Col>
                <Col md={3}>
                    <CommonInput label={label.description}
                                 type={'text'}
                                 placeHolder={placeholder.description}
                                 defaultValue={headerData["academicCareerDescription"]}
                                 isDisabled={true}
                    />
                </Col>

                <Col md={3}>
                    <CommonInput label={label.effectiveDate}
                                 type={'text'}
                                 placeHolder={placeholder.effectiveDate}
                                 defaultValue={moment(headerData["effectiveDate"]).format('DD-MM-YYYY')}
                                 isDisabled={true}
                    />
                </Col>
                <Col md={3}>
                    <CommonInput label={label.term}
                                 mandatory={true}
                                 data={action === 'new' ? termDropDownData  :allTermData  }
                                 type={action === 'new' ? 'reactSelect' : 'text'}
                                 placeHolder={placeholder.term}
                                 defaultValue={action === 'new' ?headerData["term"] : getDispValForReactSelectByOneID(headerData["term"], 'DispValue', allTermData)}
                                 isDisabled={action !== 'new'}
                                 onChange={(value)=> {
                                     if(value){
                                         updateHeaderData('term', value);
                                     }
                                 }}
                    />
                </Col>

                <Col md={3}>
                    <CommonInput label={label.effectiveStatus}
                                 mandatory={true}
                                 type={action === 'view' ? 'text' : 'reactSelect'}
                                 data={status}
                                 placeHolder={placeholder.effectiveStatus}
                                 defaultValue={headerData["status"]}
                                 isDisabled={action ==='view'}
                                 onChange={(value)=> {
                                     if(value) updateHeaderData('status', value)
                                 }}
                    />
                </Col>

            </Row>
        </Form>
    )
}

export default FeeSetupRulesHeader;