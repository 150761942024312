import React, { createContext, useState } from "react";
import { PAGE_STATE, FILTER__FORM_FIELDS_GLOBAL } from "../../studentCharges/contextApi";

export const OtherChargesContext = createContext({
  currentPageState: "SEARCH_STUDENT",
  setCurrentPageState: () => { },
  globalFilter: {},
  setGlobalFilter: () => { },
});

export const StudentListProvider = ({ children, ...props }) => {

  // global search 
  const [globalFilter, setGlobalFilter] = useState({
    ...FILTER__FORM_FIELDS_GLOBAL,
  });

  const [currentPageState, setCurrentPageState] = useState(
    PAGE_STATE.SEARCH_STUDENT
  );

  return (
    <OtherChargesContext.Provider
      value={{
        currentPageState,
        setCurrentPageState,

        globalFilter,
        setGlobalFilter,
        ...props,
      }}
    >
      {children}
    </OtherChargesContext.Provider>
  );
};
