import React, { Component, Fragment, cloneElement } from "react";
import { Tooltip as ReactstrapTooltip } from "reactstrap";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./assets/styles/index.scss";

class Tooltip extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };
        this.target = this.props.target || this.generateTarget();
        this.targetRef = React.createRef();
        this.tooltipRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("click", this.onClickListener);
    }

    generateTarget = () =>
        `TooltipTarget-${Math.random()
            .toString(36)
            .slice(2)}`;

    isOpen = () =>
        this.props.isOpen === undefined ? this.state.isOpen : this.props.isOpen;

    isTrigger = eventType => {
        const { trigger } = this.props;

        switch (trigger) {
            case "hover": {
                return eventType === "mouseover" || eventType === "mouseout";
            }

            case "click": {
                return eventType === trigger;
            }

            default: {
                return false;
            }
        }
    };

    toggle = (customIsOpen = null) => {
        const isOpen = customIsOpen !== null ? !customIsOpen : this.isOpen();
        const { toggle } = this.props;

        if (toggle) {
            toggle(isOpen);
        } else {
            this.setState({ isOpen: !isOpen });
        }
    };

    onTrigger = event => {
        if (this.isTrigger(event.type)) {
            this.toggle();
        }
    };

    onClickListener = event => {
        const targetRef = this.targetRef.current;
        const tooltipRef = this.tooltipRef.current;
        const { target: eventTarget } = event;

        if (
            targetRef &&
            eventTarget &&
            // Don't hide tooltip if click was inside it.
            !(tooltipRef && tooltipRef.contains(eventTarget))
        ) {
            if (targetRef.contains(eventTarget)) {
                this.toggle();
            } else {
                this.toggle(false);
            }
        }
    };

    renderContentText = content => {
        const {
            classNames: { text }
        } = this.props;

        const textClassNames = classNames(
            this.MODULE_CLASSES.tooltipContentText,
            text
        );

        return <div className={textClassNames}>{content}</div>;
    };

    renderContentList = items => {
        const {
            classNames: { list, listItem }
        } = this.props;

        const listClassNames = classNames(
            this.MODULE_CLASSES.tooltipContentList,
            list
        );
        const listItemClassNames = classNames(
            this.MODULE_CLASSES.tooltipContentListItem,
            listItem
        );

        const itemsComponents = items.map((item, index) => (
            <li key={index} className={listItemClassNames}>
                {item}
            </li>
        ));

        return <ul className={listClassNames}>{itemsComponents}</ul>;
    };

    renderContentDoubleLines = ([firstLine, secondLine]) => {
        const {
            classNames: {
                doubleLinesContainer,
                doubleLinesFirstLine,
                doubleLinesSecondLine
            }
        } = this.props;

        const containerClassNames = classNames(
            this.MODULE_CLASSES.tooltipContentDoubleLines,
            doubleLinesContainer
        );
        const firstLineClassNames = classNames(
            this.MODULE_CLASSES.tooltipContentFirstLine,
            doubleLinesFirstLine
        );
        const secondLineClassNames = classNames(
            this.MODULE_CLASSES.tooltipContentSecondLine,
            doubleLinesSecondLine
        );

        return (
            <div className={containerClassNames}>
                <div className={firstLineClassNames}>{firstLine}</div>
                <div className={secondLineClassNames}>{secondLine}</div>
            </div>
        );
    };

    renderContent = mode => {
        const { content } = this.props;

        switch (mode) {
            case "text": {
                return this.renderContentText(content);
            }

            case "list": {
                return this.renderContentList(content);
            }

            case "double-lines": {
                return this.renderContentDoubleLines(content);
            }

            default: {
                return null;
            }
        }
    };

    render() {
        const {
            children,
            content,
            placement,
            delay,
            offset: { x = 3, y = 0 },
            parentOffsetX,
            supportScrollRight,
            mode,
            trigger,
            classNames: { tooltip }
        } = this.props;

        if (!content) {
            return children;
        }

        let resultOffset = [y, x + parentOffsetX];
        const isOpen = this.isOpen();
        const extendedChildren = cloneElement(children, {
            id: this.target,
            ref: this.targetRef
        });

        const { current: targetRef } = this.targetRef;

        if (targetRef) {
            const {
                parentNode: { clientWidth, offsetWidth, scrollHeight, offsetHeight }
            } = targetRef;

            if (
                scrollHeight >= offsetHeight &&
                placement.includes("right") &&
                supportScrollRight
            ) {
                resultOffset[1] += `+${offsetWidth - clientWidth}px`;
            }
        }
        const tooltipClassNames = classNames(this.MODULE_CLASSES.tooltip, tooltip);

        return (
            <Fragment>
                {extendedChildren}
                <ReactstrapTooltip
                    container={this.targetRef}
                    innerRef={this.tooltipRef}
                    trigger={trigger}
                    target={this.target}
                    placement={placement}
                    delay={delay}
                    offset={resultOffset.join(", ")}
                    isOpen={isOpen}
                    toggle={this.onTrigger}
                    className={tooltipClassNames}
                    boundariesElement="viewport"
                    modifiers={{
                        preventOverflow: { enabled: false },
                        flip: { enabled: false },
                        hide: { enabled: false }
                    }}
                >
                    {this.renderContent(mode)}
                </ReactstrapTooltip>
            </Fragment>
        );
    }

    MODULE_CLASSES = {
        tooltip: "tooltip",
        tooltipContentText: "tooltip__content tooltip__content_text",
        tooltipContentList: "tooltip__content tooltip__content_list",
        tooltipContentListItem: "tooltip__content_list-item",
        tooltipContentDoubleLines: "tooltip__content tooltip__content_double-lines",
        tooltipContentFirstLine: "tooltip__content_first-line",
        tooltipContentSecondLine: "tooltip__content_second-line"
    };

    static propTypes = {
        trigger: PropTypes.oneOf(["click", "hover"]),
        mode: PropTypes.oneOf(["text", "list", "double-lines"]),
        target: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func,
            PropTypes.element
        ]),
        content: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.element
        ]),
        isOpen: PropTypes.bool,
        toggle: PropTypes.func,
        delay: PropTypes.oneOfType([
            PropTypes.shape({ show: PropTypes.number, hide: PropTypes.number }),
            PropTypes.number
        ]),
        placement: PropTypes.oneOf([
            "auto",
            "auto-start",
            "auto-end",
            "top",
            "top-start",
            "top-end",
            "right",
            "right-start",
            "right-end",
            "bottom",
            "bottom-start",
            "bottom-end",
            "left",
            "left-start",
            "left-end"
        ]),
        offset: PropTypes.shape({
            // moves a tooltip from it's target.
            x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            // moves a tooltip's arrow from center.
            y: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        }),
        supportScrollRight: PropTypes.bool,
        parentOffsetX: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        classNames: PropTypes.shape({
            tooltip: PropTypes.string,
            text: PropTypes.string,
            list: PropTypes.string,
            listItem: PropTypes.string,
            doubleLinesContainer: PropTypes.string,
            doubleLinesFirstLine: PropTypes.string,
            doubleLinesSecondLine: PropTypes.string
        })
    };

    static defaultProps = {
        trigger: "hover",
        mode: "text",
        content: "",
        delay: 0,
        placement: "right",
        offset: {},
        supportScrollRight: false,
        parentOffsetX: 0,
        classNames: {
            tooltip: "",
            text: "",
            list: "",
            listItem: "",
            doubleLinesContainer: "",
            doubleLinesFirstLine: "",
            doubleLinesSecondLine: ""
        }
    };
}

export default Tooltip;
