import CustomButton from "components/CustomButton";
import moment from 'moment';
import React, { useState } from 'react';
import {
  Card,
  CardHeader, Container, Input, Row, Table
} from 'reactstrap';
import {
  masterServiceBaseUrl, putpost,
  putpost2
} from 'services/http';
import { MasterHeader, TDROW } from '../../common/commonComponents/masterRow/masterRow';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';

var _ = require('lodash');

const BusinessTypeTable = (props) => {
  const { userPermissions, isSaveVisible, setisSaveVisible = () => { } } = props;
  const [tableDataKey, settableDataKey] = useState('tableDataKey');
  const [isDisabledField, setIsDisabledField] = useState(false);


  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: '__new',
        businessUnitTypeKey: null,
        status: 'ACTIVE',
        businessUnitTypeDesc: null,
        businessUnitTypeDispValue: null,
        sapBranchTypeSequence: null,
        sapBranchType: null,
        effectiveDate: null,
      },
      ...props.classArray,
    ]);
  };
  const saveAll = (cb) => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if (el['editMode']) {
        if (
          el['businessUnitTypeKey'] === null ||
          !el['businessUnitTypeKey'].trim()
        ) {
          isValid = false;
          failureToast('Please enter the value of Busines Unit Type Key');
          return;
        } else if (
          el['businessUnitTypeDesc'] === null ||
          !el['businessUnitTypeDesc'].trim()
        ) {
          isValid = false;
          failureToast('Please enter the value of Description');
          return;
        } else if (
          el['businessUnitTypeDispValue'] === null ||
          !el['businessUnitTypeDispValue'].trim()
        ) {
          isValid = false;
          failureToast('Please enter the value of Display Value');
          return;
        } else if (
          el['sapBranchTypeSequence'] === null ||
          !el['sapBranchTypeSequence'].trim()
        ) {
          isValid = false;
          failureToast('Please enter the value of SAP Branch Sequence');
          return;
        } else if (
          el['sapBranchType'] === null ||
          !el['sapBranchType'].trim()
        ) {
          isValid = false;
          failureToast('Please enter the value of SAP Branch Type');
          return;
        }
        if (!isValid) {
          return;
        }
        if (_tempArray[index]['__status'] == '__new') {
          putpost(
            masterServiceBaseUrl + '/businessUnitType/createBusinessUnitType',
            (data) => {
              successToast(data['message']);
              setisSaveVisible(false);
              props.fetchClasses(
                masterServiceBaseUrl + '/businessUnitType/getAllBusinessUnitType'
              );
            },
            (data) => {
              failureToast(data['message']);
            },
            _tempArray[index],
            'post'
          );
        } else {
          putpost(
            masterServiceBaseUrl +
            '/businessUnitType/updateBusinessUnitType/' +
            _tempArray[index].id,
            (data) => {
              successToast(data['message']);
              setisSaveVisible(false);
              delete _tempArray[index]['editMode'];
              props.setclassArray(_tempArray);
              setIsDisabledField(false);
              settableDataKey(new Date().getMilliseconds());
              // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
            },
            (data) => {
              failureToast(data['message']);
            },
            _tempArray[index],
            'put'
          );
        }
      } else {
      }
    });
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
        // settableDataKey(new Date().getMilliseconds())
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      cb();
    }
  };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]['editMode'] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
        setIsDisabledField(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };
  const deleteRow = (index) => {
    if (props.classArray[index]['__status'] == '__new') {
      let n = props.classArray;
      n.splice(index, 1);
      props.setclassArray(n);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
    } else {
      if (
        window.confirm(
          'Are you sure you want to delete this Busines Unit Type?'
        )
      ) {
        putpost2(
          masterServiceBaseUrl +
          '/businessUnitType/deleteBusinessUnitType/' +
          props.classArray[index].id,
          (data) => {
            successToast(data['message']);
            if (props.classArray.length == 1) {
              props.previousPage();
              props.fetchClasses(
                masterServiceBaseUrl + '/businessUnitType/getAllBusinessUnitType'
              );
            } else {
              var _tempArray = props.classArray;
              if (_tempArray[index]['editMode']) {
                setisSaveVisible(false);
              }
              _tempArray.splice(index, 1);
              props.setclassArray(_tempArray);
            }

            settableDataKey(new Date().getMilliseconds());
          },
          (data) => {
            failureToast(data['message']);
          },
          'Delete'
        );
      }
    }
  };
  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
  };

  const validate = (value, type) => {
    try {
      let pattern_1 = /^[0-9]*$/;

      if (type === 'sapSequence') {
        if (!pattern_1.test(value)) {
          return failureToast('SAP Sequence contains only Numeric value');
        } else {
          return true;
        }
      }
    } catch (error) {
      console.log('Error', error);
    }
  };
  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Business Unit Type</h3>
                <CustomButton
                  disabled={isSaveVisible}
                  className={'floatRight mx-1' + (isSaveVisible ? ' btn-dark' : null)}
                  content={'New Business Unit Type'}
                  permissionType={'C'}
                  icon={true}
                  onClick={() => { newRow(); }}
                  permissionSet={userPermissions}
                />
                {/* {isSaveVisible?<Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => {saveAll()}}>Save</Button>:null} */}
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout">
                  <MasterHeader type={'Business Unit Type'} permissionSet={userPermissions} isSaveVisible={isSaveVisible} />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                        !el['editMode'] ? (
                          <TDROW
                            type={'businessUnitType'}
                            index={index}
                            el={el}
                            editRow={editRow}
                            deleteRow={deleteRow}
                            permissionSet={userPermissions}
                            isSaveVisible={isSaveVisible}
                          />
                        ) : (
                          <tr key={index + '-class'}>
                            <td className="text-center p-2 mx-1">
                              <Input
                                maxLength="50"
                                defaultValue={el['businessUnitTypeKey']}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    e.target.value,
                                    'businessUnitTypeKey'
                                  );
                                }}
                                placeholder="Example:1"
                                className="tdInput"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <Input
                                disabled={true}
                                defaultValue={
                                  el['__status'] == '__new'
                                    ? null
                                    : moment(el['eff_date']).format(
                                      'DD-MM-YYYY'
                                    )
                                }
                                placeholder="Example:01-01-2020"
                                className="tdInput"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <Input
                                maxLength="50"
                                defaultValue={el['businessUnitTypeDesc']}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    e.target.value,
                                    'businessUnitTypeDesc'
                                  );
                                }}
                                placeholder="Description"
                                className="tdInput"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <Input
                                maxLength="50"
                                defaultValue={el['businessUnitTypeDispValue']}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    e.target.value,
                                    'businessUnitTypeDispValue'
                                  );
                                }}
                                placeholder="Display Value"
                                className="tdInput"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <Input
                                maxLength="5"
                                defaultValue={el['sapBranchTypeSequence']}
                                disabled={isDisabledField}
                                onChange={(e) => {
                                  let n = e.target.value;
                                  if (validate(n, 'sapSequence')) {
                                    updateKey(
                                      index,
                                      n,
                                      'sapBranchTypeSequence'
                                    );
                                  }
                                }}
                                placeholder="SAP Branch Type Sequence"
                                className="tdInput"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <Input
                                maxLength="50"
                                defaultValue={el['sapBranchType']}
                                disabled={isDisabledField}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    e.target.value,
                                    'sapBranchType'
                                  );
                                }}
                                placeholder="SAP Branch Type"
                                className="tdInput"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <label className="custom-toggle mx-auto ml-2">
                                <input
                                  checked={
                                    el.status == 'ACTIVE' ? true : false
                                  }
                                  type="checkbox"
                                  id={el.id}
                                  onChange={(e) => {
                                    settableDataKey(
                                      new Date().getMilliseconds()
                                    );
                                    let n = 'ACTIVE';
                                    if (!e.target.checked) {
                                      n = 'INACTIVE';
                                    }
                                    updateKey(index, n, 'status');
                                  }}
                                />
                                <span
                                  style={{ width: '72px' }}
                                  className="custom-toggle-slider rounded-circle activeToggle"
                                  data-label-off="Inactive"
                                  data-label-on="Active"
                                />
                              </label>
                            </td>
                            <td className="text-center">
                              {/* <Button color="info" size="sm" type="button" onClick={() => {
                          deleteRow(index)
                        }}>  <i className="fas fa-trash" /></Button> */}
                              {isSaveVisible ? (
                                <>
                                  <CustomButton
                                    content={'Save'}
                                    permissionType={'C,U'}
                                    icon={false}
                                    forTable={true}
                                    onClick={() => { saveAll() }}
                                    permissionSet={userPermissions}
                                  />
                                  <CustomButton
                                    content={''}
                                    permissionType={'cancel'}
                                    icon={true}
                                    forTable={true}
                                    onClick={() => {
                                      let n = props.classArray;
                                      if (n[index]['__status'] == '__new') {
                                        n.splice(index, 1);
                                      } else {
                                        // n[index]['editMode']=false
                                        props.fetchClasses(
                                          masterServiceBaseUrl +
                                          '/businessUnitType/getAllBusinessUnitType'
                                        );
                                      }
                                      props.setclassArray(n);
                                      setisSaveVisible(false);
                                      setIsDisabledField(false);
                                    }}
                                    permissionSet={userPermissions}
                                  />
                                </>
                              ) : (
                                <CustomButton
                                  content={''}
                                  permissionType={'D'}
                                  icon={true}
                                  forTable={true}
                                  onClick={() => deleteRow(index)}
                                  permissionSet={userPermissions}
                                />
                              )}
                            </td>
                          </tr>
                        )
                      )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default BusinessTypeTable;
