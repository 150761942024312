 import { getAPI } from "services/utils";

import { endpoint } from "views/pages/manage/common/constant";
import { fetchAllPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { fetchAllPostPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { FETCH_ACADEMIC_MAP_LIST_FAILURE, FETCH_ACADEMIC_MAP_LIST_REQUEST, FETCH_ACADEMIC_MAP_LIST_SUCCESS, UPDATE_MAPPING_DROPDOWNS } from "./action-types";

export const fetchListSuccess = (data, rowCount, currentPage) => {
  return {
    data,
    rowCount,
    currentPage,
    type: FETCH_ACADEMIC_MAP_LIST_SUCCESS,
  };
};

export const fetchMapListFailure = () => {
  return {
    type: FETCH_ACADEMIC_MAP_LIST_FAILURE
  };
};


export const getMappingList = ( offSet= 0) => {
  return async (dispatch, getState) => {
    dispatch({type:FETCH_ACADEMIC_MAP_LIST_REQUEST})
   
    try {

        const response = await fetchAllPromisedData(
          `${endpoint.academicSubMapping.getAll}/${offSet}`,
          "GET"
        );
        if(response?.code===200){
          dispatch(
            fetchListSuccess(
              response.data.academicGroupSubjectMappingList,
              response.data.totalRecords,
              response.currentPage
            )
          );        } else {
          dispatch(fetchMapListFailure())
        }
      
    } catch (error) {
        console.log(error)
        dispatch(fetchMapListFailure())
        
    }
  
  };
};


export const createMapping = (request, cb) => {
    return async (dispatch, getState) => {
      dispatch({type:FETCH_ACADEMIC_MAP_LIST_REQUEST})
      try {
          const response = await fetchAllPostPromisedData(
            `${endpoint.academicSubMapping.create}`,
            request,
            "post"
          );
          if(response){
          dispatch(getMappingList())
          cb()
          }else{
              dispatch(fetchMapListFailure())
          }
      } catch (error) {
          dispatch(fetchMapListFailure())
      }
    
    };
  };

export const updateMapping = (request, id, cb ) => {
  return async (dispatch, getState) => {
    dispatch({type:FETCH_ACADEMIC_MAP_LIST_REQUEST})
    try {
        const response = await fetchAllPostPromisedData(
          `${endpoint.academicSubMapping.update}/${id}`,
          request,
          "put"
        );
        if(response){
        dispatch(getMappingList())
        cb()
        }else{
            dispatch(fetchMapListFailure())
        }
    } catch (error) {
        dispatch(fetchMapListFailure())
    }
  
  };
};

export const deleteMapping = (id) => {
    return async (dispatch, getState) => {
      dispatch({type:FETCH_ACADEMIC_MAP_LIST_REQUEST})
      try {
          const response = await fetchAllPostPromisedData(
            `${endpoint.academicSubMapping.delete}/${id}`,
             {},
            "delete"
          );
          if(response){
          dispatch(getMappingList())
          }else{
              dispatch(fetchMapListFailure())
          }
      } catch (error) {
          dispatch(fetchMapListFailure())
      }
    
    };
  };


const dropdownMapping = async (url, displayLabel) => {
    const responseData = await fetchAllPromisedData(url, false);
   
    if (Array.isArray(responseData)) {
    
      const result = responseData?.map((o) => {
        return {label: o[displayLabel], value: o['id']}
      })
      return result;
    }
    else return [];
  }


export   const getDropdowns = async () => {
    const p = await Promise.all([
      dropdownMapping(endpoint.academic_group.getAllActive, 'academicGroupDispValue'),
      dropdownMapping(endpoint.subject.getAllActive, 'subjectDispValue'),
      
    ])
   return  {
      academicGroups: p[0],
      subjects:p[1]
      
    }
  };

  export const loadDropdowns = () => {
  return async (dispatch) => {
    const response = await getDropdowns();
    if (response)
      dispatch({ type: UPDATE_MAPPING_DROPDOWNS, dropdowns: response });
  };
};