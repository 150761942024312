import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ADataGrid from "../../common/data-grid/ADataGrid";
import { AGridColDef } from "../../common/data-grid/ADataGridColDef";
import {
  getFormattedDate,
  KEY_TO_DISPLAY,
  SEARCH_BATCH_TABLE_HEADERS as BatchListHeaders,
} from "../constant/batch-search";
import { BatchListActions } from "./BatchListActions";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import MsID from "./MsID";
import AChip from "../../common/chip/AChip";
import BatchActivationDialog from "./BatchActivationDialog";
import { ReactComponent as ActiveControl } from '../../../../../assets/img/svg/ActiveControl.svg';
import { ReactComponent as InactiveControl } from '../../../../../assets/img/svg/InactiveControl.svg';


import { useHistory } from "react-router";
import { color } from "@mui/system";
import EmptyBatchPlaceholder from "./EmptyBatchPlaceholder";

const BatchList = ({
  rowsData,
  rowCount,
  deActiveBatchHandler,
  nextButtonHandler,
  currentPage,
  loading
}) => {
  const history =  useHistory()
  const [openDialog, setOpenDiaLog] = useState(false);
  const [batchData, setBatchData] = useState("");

 

  const statusHandler = (data) => {
    setOpenDiaLog(true);
    setBatchData(data);
  };

  const BatchListHeader = [
    new AGridColDef("batchName", BatchListHeaders.BATCH_NAME)
      .setWidth(200)
      .renderCellComponent((params) => (
        <span 
          onClick={()=>history.push(`/admin/edp/batch/view/${params.row.id}`)}
          style={{ color: "#00B0F5", fontSize: "14px", cursor:"pointer" }}>
          {params.value}
        </span>
      )),
    new AGridColDef("batchCapacity", BatchListHeaders.CAPACITY)
      .setWidth(200)
      .renderCellComponent((params) => (
        <span>
         {params.row.currentStrength}/{params.value} students
        </span>
      )),
    ,
    new AGridColDef("batchType", BatchListHeaders.TYPE)
      .setWidth(150)
      .renderCellComponent((params) => (
        <span>{KEY_TO_DISPLAY[params.value]}</span>
      )),

    new AGridColDef("batchEnrollmentStatus", BatchListHeaders.ENROLLMENT)
      .setWidth(200)
      .renderCellComponent((params) => (
        <AChip
          label={KEY_TO_DISPLAY[params.value.trim()]}
          success={params.value === "OPEN"}
        />
      )),

    new AGridColDef("msTeamsGroupSequence", BatchListHeaders.MS_TEAM_SEQUENCE)
      .setWidth(200)
      .renderCellComponent((params) => <MsID id={params.value} />),

    new AGridColDef("batchStartDate", BatchListHeaders.START_DATE)
      .setWidth(250)
      .renderCellComponent((params) => (
        <span>{getFormattedDate(params.value)}</span>
      )),

    new AGridColDef("batchEndDate", BatchListHeaders.END_DATE)
      .setWidth(150)
      .renderCellComponent((params) => (
        <span>{getFormattedDate(params.value)}</span>
      )),

    new AGridColDef("batchStatus", BatchListHeaders.STATUS)
      .setWidth(200)
      .renderCellComponent((params) => (
       <span>
        {/* <FormControlLabel
          control={
            <Switch
              onChange={() => statusHandler(params.row)}
              checked={params.value === "ACTIVE"}
              size="small"
              disabled = {params.value === "INACTIVE" && params.row.batchEnrollmentStatus ==="CLOSED" }
            />
          }
         sx ={{ marginRight:"5px"
            }}
        /> */}
        {params.value === "ACTIVE"?
        <ActiveControl onClick= {() => statusHandler(params.row)} />:
        <InactiveControl onClick=  {() => statusHandler(params.row)}/>
        }
        <span 
        style ={{fontSize:"14px" ,marginLeft:"4px",
        color:params.value === "ACTIVE"?'#2B2F32':'#414141'
      }}
        >{KEY_TO_DISPLAY[params.value]}</span>
        </span>
      )),

    new AGridColDef("Actions", "Actions", false).renderCellComponent((params) => (
      <BatchListActions batchData={params.row} />
    )),
  ];

  return (
    <>
      <ADataGrid
        rows={rowsData}
        columns={BatchListHeader}
        rowId={(row) => row.id}
        loading={loading}
        rowCount={rowCount}
        nextButtonHandler={nextButtonHandler}
        currentPage={currentPage}
        emptyRowPlaceholder = {()=><EmptyBatchPlaceholder/>}
        sortColumnName = "batchName"
      />
      <BatchActivationDialog
        open={openDialog}
        onClose={setOpenDiaLog}
        batchData={batchData}
        deActiveBatchHandler={deActiveBatchHandler}
      />
    </>
  );
};

export default BatchList;
