import moment from "moment";
import React, { useContext, useState } from "react";
import { Card, CardBody } from "reactstrap";
import styles from "./styles.module.css";
import { ReactComponent as EditIcon } from "../../../../../../assets/img/svg/Edit2.svg";
import { Tooltip } from "@mui/material";
import ChangeAcademicNameDialog from "./ChangeAcademicNameDialog";
import ChangeDateDialog from "./ChangeDateDialog";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import CustomCopyToClipboard from "views/pages/manage/common/ACopyToClipboard/CustomCopyToClipboard";
import ResetTimetableCnf from "./ResetTimetableCnf";
import { TimetableContext } from "../contextApi/TimetableContext";
import ViewTimetableMaster from "../editViewTimetable/ViewTimetableMaster";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper";

const cardStyle = {
  boxShadow: "none",
  backgroundColor: "#004662",
  margin: 0,
  maxWidth: "352px",
};

const titleStyle = {
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "16px",
  marginBottom: "5px",
};

const dataStyle = {
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "28px",
  width: "90%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const HeaderComponent = ({
  formData,
  plansDropdown,
  formHandler,
  batchDetails,
  openMode,
  tests,
  setTests,
  lectures,
  setLectures,
  fetchNewLectures,
  setIsNeedToPublish,
  setOpenForm,
  subjectFacultyDropdown,
  subjectForm
}) => {
  const [openPlanNameUpdatePopup, setOpenPlanNameUpdatePopup] = useState(false);
  const [openDateChangeDialog, setOpenDateChangeDialog] = useState(false);
  const [isOpenResetTimetableCnf, setOpenResetTimetableCnf] = useState(false);
  const { setResetEnabled, resetTimetableData } = useContext(TimetableContext);

  const timetableResetHandler = () => {
    resetTimetableData();
  };
  let actionTypeLabel =
    openMode === "NEW" ? "Create" : openMode === "EDIT" ? "Edit" : "View";

  return (
    <div className={styles.header_container}>
      <div style={{ color: "#FFFFFF", fontWeight: "400", fontSize: "14px" }}>
        {batchDetails?.batchName} / {actionTypeLabel} timetable
      </div>
      <div className={`mt-3 ${styles.display_details_container}`}>
        <Card style={cardStyle}>
          <CardBody style={{ padding: "12px 16px" }}>
            <div style={titleStyle}>Applied academic plan</div>
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ gap: "16px" }}
            >
              {/* <div style={{ maxWidth: '88%' }} className="d-flex align-items-center"> */}
              <Tooltip title={formData?.plan?.label}>
                <div style={{ ...dataStyle, display: "inline-block" }}>
                  {formData?.plan?.label}
                </div>
              </Tooltip>
              <CustomCopyToClipboard textToCopy={formData?.plan?.label} />
              {/* </div> */}
              {/* {openMode === 'EDIT' &&  <EditIcon onClick={() => setOpenPlanNameUpdatePopup(true)} />} */}
            </div>
          </CardBody>
        </Card>
        <Card style={cardStyle}>
          <CardBody style={{ padding: "12px 16px" }}>
            <div style={titleStyle}>Timetable set from-to</div>
            <div className="d-flex align-items-center justify-content-between">
              <div style={dataStyle}>
                {`${new moment(formData?.startFrom).format(
                  "DD MMM YYYY"
                )} - ${new moment(formData?.endOn).format("DD MMM YYYY")}`}
              </div>
              {openMode === "EDIT" && (
                <IconButtonWrapper onClick={() => setOpenDateChangeDialog(true)}><EditIcon  /></IconButtonWrapper>
              )}
            </div>
          </CardBody>
        </Card>
        {isOpenResetTimetableCnf && (
          <ResetTimetableCnf
            open={isOpenResetTimetableCnf}
            handleClose={() => setOpenResetTimetableCnf(false)}
            restTimetableHandler={timetableResetHandler}
          />
        )}
        <ChangeAcademicNameDialog
          open={openPlanNameUpdatePopup}
          setOpen={setOpenPlanNameUpdatePopup}
          formData={formData}
          plansDropdown={plansDropdown}
          formHandler={formHandler}
        />
        <Card style={cardStyle}>
          <CardBody style={{ padding: "12px 16px" }}>
            <div style={titleStyle}>Applied days plan</div>
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ gap: "16px" }}
            >
              <Tooltip title={formData?.daysPlan?.label}>
                <div style={dataStyle}>{formData?.daysPlan?.label}</div>
              </Tooltip>
              {/* {openMode === 'EDIT' &&  <EditIcon onClick={() => setOpenPlanNameUpdatePopup(true)} />} */}
            </div>
          </CardBody>
        </Card>
        <div className="d-flex align-items-center">

       {openMode !== "NEW" && <ViewTimetableMaster 
        timetable={formData}
        subjectFacultyDropdown  = {subjectFacultyDropdown}
        subjectForm = {subjectForm}
        />
       }
        {openMode === "EDIT" &&
        new Date(formData?.startFrom) < new Date().setHours(0, 0, 0, 0) ? (
          <AButton
            variant="outline"
            updatedStyle={{ alignSelf: "end" }}

            onClick={() => setOpenResetTimetableCnf(true)}
          >
            Reset Details
          </AButton>
        ) : (
          <AButton
            variant="outline"
            updatedStyle={{
              alignSelf: "end",
              display: `${openMode === "EDIT" ? "" : "none"}`,
            }}
            onClick={() => setOpenResetTimetableCnf(true)}
          >
            Reset Details
          </AButton>
        )}
       </div>
        {openDateChangeDialog && (
          <ChangeDateDialog
            open={openDateChangeDialog}
            setOpen={setOpenDateChangeDialog}
            formData={formData}
            formHandler={formHandler}
            tests={tests}
            setTests={setTests}
            lectures={lectures}
            setLectures={setLectures}
            fetchNewLectures={fetchNewLectures}
            setIsNeedToPublish={setIsNeedToPublish}
            setOpenForm={setOpenForm}
          />
        )}
      </div>
    </div>
  );
};

export default HeaderComponent;
