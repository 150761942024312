import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { ReactComponent as Uparrow } from "../../../../../assets/img/svg/upwardArrow.svg";
import { headingStyle } from "./style";
import TestContentForm from "./TestContentForm";

const TestContent = ({
  formModal,
  testContentFormHandler,
  addChapterHandler,
  ...props
}) => {
  function onChangeHandler(event) {
  //  console.log(event, this, event.currentTarget);
    props.setIsTestContentExpanded(!props.isTestContentExpanded);
  }
  return (
    <Row>
      <Col md={12}>
        <Accordion
          expanded={props.isTestContentExpanded}
          onChange={onChangeHandler}
        >
          <AccordionSummary
            expandIcon={<Uparrow />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <div>
              <span style={headingStyle}> 2. Test Contents</span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <span style={{ position: "absolute", top: "16px", left: "150px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={props.isCompleteSyllabus}
                    onChange={() =>
                      props.setIsCompleteSyllabus(!props.isCompleteSyllabus)
                    }
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: "20px" },
                      fontSize: 12,
                    }}
                  />
                }
                sx={{ fontSize: 14, m: 0 }}
                label="Complete syllabus"
              />
            </span>
            <TestContentForm
              testContentFormHandler={testContentFormHandler}
              formModal={formModal}
              addChapterHandler={addChapterHandler}
              {...props}
            />
          </AccordionDetails>
        </Accordion>
      </Col>
    </Row>
  );
};

export default TestContent;
