import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Accordion } from "../../../../common/commonComponents/Accordion/accordion";
import Loader from "react-loader-spinner";
import ApplicationDetails from "./applicationDetails";
import {
  masterServiceBaseUrl,
  enrollmentServiceBaseUrl,
  failureToast,
  getAPI,
  putpost,
  putpost2,
  successToast,
} from "services/http";
import moment from "moment";
import { fetchDetails } from "../../../../common/utils/methods/commonMethods/utilityMethod";

const bankDetail = {
  bankId: "",
  bankAccountNumber: "",
  IFSCCode: "",
  accountHolder: "",
};

const getNameFromList = (list, id) => {
  const name =
    list &&
    list.filter((item) => {
      return Number(item.value) === Number(id);
    });
  return name.length ? name[0]["label"] : "";
};

const getNameFromScoolList = (list, id) => {
  const name =
    list &&
    list.filter((item) => {
      return item.value === id;
    });
  return name.length ? name[0]["label"] : "";
};

const StudentDetailsRendere = ({
  data,
  bloodGrp,
  gender,
  category,
  studentIdStatus,
  schoolStudingIn
}) => {
  const [isFetchingImage, setIsFetchingImage] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (data?.studentId) {
      setIsFetchingImage(true);
      getAPI(
        `${enrollmentServiceBaseUrl}/student/getStudentPhotoFilePath/?studentId=${data.studentId}`,
        (data) => {
          setImageUrl(data?.data?.s3FilePathOfStudentImage);
          setIsFetchingImage(false);
        },
        (data) => {
          failureToast(data["message"]);
          setIsFetchingImage(false);
        }
      );
    }
  }, [data?.studentId]);

  return (
    <>
      {!data || !bloodGrp || !gender || !category || !studentIdStatus || !schoolStudingIn ? (
        <div
          className="mx-auto text-center py-5 my-5"
          style={{ height: "100%" }}
        >
          <Loader
            type="Rings"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={10000}
          />
        </div>
      ) : (
        <Container fluid>
          <Row style={{ fontSize: "15px" }}>
            <Col md={5}>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Student ID</strong>
                </Col>
                :<Col md={5}>{data && data.studentId}</Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Student First Name</strong>
                </Col>
                :<Col md={5}>{data && data.studentFirstName}</Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Date Of Birth</strong>
                </Col>
                :
                <Col md={5}>
                  {data && moment(data.dob).format("DD-MM-YYYY")}
                </Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Blood Group</strong>
                </Col>
                :
                <Col md={5}>
                  {bloodGrp && getNameFromList(bloodGrp, data.bloodGroupId)}
                </Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Category</strong>
                </Col>
                :
                <Col md={5}>
                  {category &&
                    getNameFromList(category, data.studentCategoryId)}
                </Col>
              </Row>
            </Col>
            <Col md={5}>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Student ID Status</strong>
                </Col>
                :
                <Col md={5}>
                  {studentIdStatus &&
                    getNameFromList(studentIdStatus, data.studentIdStatus)}
                </Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Student Last Name</strong>
                </Col>
                :<Col md={5}>{data && data.studentLastName}</Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Gender</strong>
                </Col>
                :
                <Col md={5}>
                  {gender && getNameFromList(gender, data.genderId)}
                </Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>School Studying IN</strong>
                </Col>
                :
                <Col md={5}>
                  {data && getNameFromScoolList(schoolStudingIn, data.schoolId)}
                </Col>
              </Row>
            </Col>
            <Col md={2}>
              <div className={``} style={{ height: "150px" }}>
                {isFetchingImage ? (
                  <Loader
                    type="Rings"
                    color="#00BFFF"
                    height={100}
                    width={100}
                  />
                ) : (
                  <img
                    src={imageUrl ?? ""}
                    alt=""
                    height="150px"
                    width="130px"
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

const ContactDetailsRendere = ({ data, country, stateName, pincode, city }) => {
  const [address, setAddress] = React.useState(null);
  const [wnumber, setWNumber] = React.useState(null);
  const [pnumber, setPNumber] = React.useState(null);
  const [mnumber, setMNumber] = React.useState(null);

  const getAddress = (url, method) => {
    getAPI(
      url,
      (data) => {
        method(data.data);
        // console.log(data.data);
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  };

  React.useEffect(() => {
    if (data && data.addressMasterId) {
      getAddress(
        `${masterServiceBaseUrl}/address/getAddress/${data.addressMasterId}`,
        setAddress
      );
    }

    data &&
      data.primaryPhoneMasterId &&
      getAddress(
        `${masterServiceBaseUrl}/phone/getPhone/${data.primaryPhoneMasterId}`,
        setPNumber
      );
    data &&
      data.studentMobilePhoneMasterId &&
      getAddress(
        `${masterServiceBaseUrl}/phone/getPhone/${data.studentMobilePhoneMasterId}`,
        setMNumber
      );
    data &&
      data.whatsappPhoneMasterId &&
      getAddress(
        `${masterServiceBaseUrl}/phone/getPhone/${data.whatsappPhoneMasterId}`,
        setWNumber
      );
  }, [data]);

  return (
    <>
      {!data || !address || !wnumber || !pnumber ? (
        <div
          className="mx-auto text-center py-5 my-5"
          style={{ height: "100%" }}
        >
          <Loader
            type="Rings"
            color="#00BFFF"
            height={100}
            width={100}
            //timeout={10000}
          />
        </div>
      ) : (
        <Container fluid>
          <Row style={{ fontSize: "15px" }}>
            <Col md={6}>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Primary Mobile Number</strong>
                </Col>
                :<Col md={5}>{pnumber && pnumber.phone}</Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Student Mobile Number</strong>
                </Col>
                :<Col md={5}>{mnumber && mnumber.phone}</Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Address Line 1</strong>
                </Col>
                :<Col md={5}>{address && address.addressLine1}</Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>PinCode</strong>
                </Col>
                :
                <Col md={5}>
                  {address && getNameFromList(pincode, address.pincodeId)}
                </Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>State</strong>
                </Col>
                :
                <Col md={5}>
                  {address && getNameFromList(stateName, address.stateId)}
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Whatsapp Number</strong>
                </Col>
                :<Col md={5}>{wnumber && wnumber.phone}</Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Student Email ID</strong>
                </Col>
                :<Col md={5}>{data.email}</Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Address Line 2</strong>
                </Col>
                :<Col md={5}>{address && address.addressLine2}</Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>City</strong>
                </Col>
                :
                <Col md={5}>
                  {address && getNameFromList(city, address.cityId)}
                </Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Country</strong>
                </Col>
                :
                <Col md={5}>
                  {address && getNameFromList(country, address.countryId)}
                </Col>
              </Row>
            </Col>
            {/* <Col md={2}/> */}
          </Row>
        </Container>
      )}
    </>
  );
};

const OtherDetailsRendere = ({ data, parentsOcc }) => {
  return (
    <>
      {!data || !parentsOcc ? (
        <div
          className="mx-auto text-center py-5 my-5"
          style={{ height: "100%" }}
        >
          <Loader
            type="Rings"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={10000}
          />
        </div>
      ) : (
        <Container fluid>
          <Row style={{ fontSize: "15px" }}>
            <Col md={6}>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Father Name</strong>
                </Col>
                :<Col md={5}>{data && data.studentFatherName}</Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Father Mobile Number</strong>
                </Col>
                :<Col md={5}>{data && data.studentFathersMobileNumber}</Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Father Email ID</strong>
                </Col>
                :<Col md={5}>{data && data.studentFathersEmailId}</Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Father's Occupation</strong>
                </Col>
                :
                <Col md={5}>
                  {parentsOcc &&
                    getNameFromList(parentsOcc, data.studentFatherOccupationId)}
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Mother Name</strong>
                </Col>
                :<Col md={5}>{data && data.studentMotherName}</Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Mother Mobile Number</strong>
                </Col>
                :<Col md={5}> {data && data.studentMothersMobileNumber}</Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Mother Email ID</strong>
                </Col>
                :<Col md={5}> {data && data.studentMothersEmailId}</Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Mother's Occupation</strong>
                </Col>
                :
                <Col md={5}>
                  {parentsOcc &&
                      getNameFromList(parentsOcc, data.studentMotherOccupationId)}
                </Col>
              </Row>
            </Col>
            {/* <Col md={2}/> */}
          </Row>
        </Container>
      )}
    </>
  );
};

const LogDetailsRendere = ({ data }) => {
  return (
    <>
      {!data ? (
        <div
          className="mx-auto text-center py-5 my-5"
          style={{ height: "100%" }}
        >
          <Loader
            type="Rings"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={10000}
          />
        </div>
      ) : (
        <Container fluid>
          <Row style={{ fontSize: "15px" }}>
            <Col md={6}>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Created Date</strong>
                </Col>
                :
                <Col md={5}>
                  {data && moment(data.createdDate).format("DD-MM-YYYY")}
                </Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Last Updated Date</strong>
                </Col>
                :
                <Col md={5}>
                  {data && moment(data.updatedOn).format("DD-MM-YYYY")}
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Created By</strong>
                </Col>
                :<Col md={5}>{data && data.createdBy}</Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Last Updated By</strong>
                </Col>
                :<Col md={5}> {data && data.lastUpdatedBy}</Col>
              </Row>
            </Col>
            {/* <Col md={2}/> */}
          </Row>
        </Container>
      )}
    </>
  );
};

const GstDetailsRendere = ({ data, gstCategory }) => {
  const [gstDetails, setGstDetails] = React.useState(null);

  const getAddress = (url, method) => {
    getAPI(
      url,
      (data) => {
        method(data.data);
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  };

  React.useEffect(() => {
    if (data && data.gstinDetailMasterId) {
      getAddress(
        `${masterServiceBaseUrl}/gstinDetails/getgstinDetails/${data.gstinDetailMasterId}`,
        setGstDetails
      );
    }
  }, [data]);
  return (
    <>
      {!data && !gstCategory ? (
        <div
          className="mx-auto text-center py-5 my-5"
          style={{ height: "100%" }}
        >
          <Loader
            type="Rings"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={10000}
          />
        </div>
      ) : (
        <Container fluid>
          <Row style={{ fontSize: "15px" }}>
            <Col md={6}>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>GST Category</strong>
                </Col>
                :
                <Col md={5}>
                  {gstDetails &&
                    gstCategory &&
                    getNameFromList(
                      gstCategory,
                      gstDetails.gstinCategoryMasterId
                    )}
                </Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>Company Name</strong>
                </Col>
                :<Col md={5}>{gstDetails && gstDetails.gstinCompanyName}</Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>GST Effective Date </strong>
                </Col>
                :
                <Col md={5}>
                  {data &&
                    data.effectiveDate &&
                    moment(data.effectiveDate).format("DD-MM-YYYY")}
                </Col>
              </Row>
              <Row className={`mb-2`}>
                <Col md={6}>
                  <strong>GSTIN</strong>
                </Col>
                :<Col md={5}> {gstDetails && gstDetails.gstinGstNo}</Col>
              </Row>
            </Col>
            {/* <Col md={2}/> */}
          </Row>
        </Container>
      )}
    </>
  );
};

const BankDetailsRendere = ({ data }) => {
  const [bankDetails, setBankDetails] = React.useState(null);

  const getBankDetails = (url, method) => {
    getAPI(
      url,
      (data) => {
        method(data?.data);
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  };

  React.useEffect(() => {
    if (data && data.bankId) {
      getBankDetails(
        `${masterServiceBaseUrl}/bank/getBankById/${data.bankId}`,
        setBankDetails
      );
    }
  }, [data]);

  return (
    <>
      <Container fluid>
        <Row style={{ fontSize: "15px" }}>
          <Col md={6}>
            <Row className={`mb-2`}>
              <Col md={6}>
                <strong>Bank ID</strong>
              </Col>
              :<Col md={5}>{(data?.bankId && bankDetails?.bankName) ?? ""}</Col>
            </Row>
            <Row className={`mb-2`}>
              <Col md={6}>
                <strong>IFSC Code</strong>
              </Col>
              :<Col md={5}>{data?.ifscCode}</Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row className={`mb-2`}>
              <Col md={6}>
                <strong>Bank Account Number</strong>
              </Col>
              :<Col md={5}>{data?.bankAccountNumber}</Col>
            </Row>
            <Row className={`mb-2`}>
              <Col md={6}>
                <strong>Account Holder Name</strong>
              </Col>
              :<Col md={5}> {data?.accountHolderName}</Col>
            </Row>
          </Col>
          {/* <Col md={2}/> */}
        </Row>
      </Container>
    </>
  );
};

const StudentInformation = (props) => {
  const { id = null } = props.match.params;

  const [studentDetails, setStudentDetails] = useState(null);
  const [contactDetails, setContactDetails] = useState(null);
  const [otherDetails, setOtherDetails] = useState(null);
  const [logDetails, setLogDetails] = useState(null);
  const [gstDeatls, setGstDetails] = useState(null);
  const [bankDetails, setBankDetials] = useState(null);

  const [country, setCountry] = useState([]);
  const [stateName, setStateName] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [city, setCity] = useState([]);
  const [bloodGrp, setBloodGrp] = useState([]);
  const [gender, setGender] = useState([]);
  const [phone, setPhone] = useState([]);
  const [email, setEmail] = useState([]);
  const [gstCategory, setGstCategory] = useState([]);
  const [parentsOcc, setParentOcc] = useState([]);
  const [category, setCategory] = useState([]);
  const [studentIdStatus, setStudentIdStatus] = useState();
  const [externalOrganisation, setExternalOrganisation] = useState([]);


  const fetchData = (url, stateMethod, name) => {
    getAPI(
      url,
      (data) => {
        let list = [];

        if (name === "externalOrganizationId") {
          list = data.data.map((data) => {
            return {
              value: data.id,
              label: data.organizationName,
            };
          });
        } else {
          //this is for dropdown fields only
          let keyName = `${name}DispValue`;

          list = data.data.map((data) => {
            return {
              value: data.id,
              label: data[keyName],
            };
          });
        }
        stateMethod(list);
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  };
  const [programAction,setProgramAction] = useState([]);
  const getStudentData = (url, methodName) => {
    getAPI(
      url,
      (data) => {
        if (data) {
          setContactDetails(data.data.studentContactDetailsResponseDTO);
          setStudentDetails(data.data.studentDetailsDTO);
          setGstDetails(data.data.studentGstinDetailMappingDTO);
          setOtherDetails(data.data.studentDetailsDTO);
          setBankDetials(data.data.studentBankDetailsDto);
          let dateArr = [];
          data.data.studentContactDetails &&
            dateArr.push(
              new Date(data.data.studentContactDetails.updatedOn).getTime()
            );
          data.data.studentDetailsDTO &&
            dateArr.push(
              new Date(data.data.studentDetailsDTO.updatedOn).getTime()
            );
          data.data.studentGstinDetailMappingDTO &&
            dateArr.push(
              new Date(
                data.data.studentGstinDetailMappingDTO.updatedOn
              ).getTime()
            );

          // console.log(dateArr)
          //console.log(Math.max.apply(Math, dateArr.map(function(o) { return o.y; })))

          setLogDetails({
            createdDate:
              data.data.studentDetailsDTO &&
              data.data.studentDetailsDTO.effectiveDate,
            updatedOn: dateArr && Math.max(...dateArr),
          });
        }
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  };

  useEffect(() => {
    //matser apis
    fetchData(
      masterServiceBaseUrl + "/country/getAllCountry",
      setCountry,
      "country"
    );
    fetchData(
      masterServiceBaseUrl + "/state/getAllState",
      setStateName,
      "state"
    );
    fetchData(
      masterServiceBaseUrl + "/pincode/getAllPincode",
      setPincode,
      "pincode"
    );
    fetchData(masterServiceBaseUrl + "/city/getAllCity", setCity, "city");
    fetchData(
      masterServiceBaseUrl + "/gender/getAllGender",
      setGender,
      "gender"
    );
    fetchData(
      masterServiceBaseUrl +
        "/externalOrganizationId/getAllExternalOrganizationId",
      setExternalOrganisation,
      "externalOrganizationId"
    );
    fetchData(
      masterServiceBaseUrl + "/bloodGroup/getAllBloodGroup",
      setBloodGrp,
      "bloodGroup"
    );
    fetchData(
      masterServiceBaseUrl + "/phoneType/getAllPhoneType",
      setPhone,
      "phoneType"
    );
    fetchData(
      masterServiceBaseUrl + "/emailType/getAllEmailType",
      setEmail,
      "emailType"
    );
    fetchData(
      masterServiceBaseUrl + "/gstinCategory/getAllGstinCategory",
      setGstCategory,
      "gstinCategory"
    );
    fetchData(
      masterServiceBaseUrl + "/parentsOccupation/getAllParentsOccupation",
      setParentOcc,
      "parentsOccupation"
    );
    fetchData(
      masterServiceBaseUrl + "/category/getAllCategory",
      setCategory,
      "category"
    );
    fetchData(
      masterServiceBaseUrl + "/studentIdStatus/getAllStudentIdStatus",
      setStudentIdStatus,
      "studentIdStatus"
    );
    // student detail apis
    // getStudentData(`${baseUrl3}/student/getStudent/${id}`);

    fetchDetails(
      masterServiceBaseUrl + "/businessArea/getAllActiveBusinessArea",
      setBusinesArea,
      "businessArea",
      "reactSelect"
    );
    fetchDetails(
        masterServiceBaseUrl + "/programAction/getAllProgramAction",
        setProgramAction,
        "programAction",
        "reactSelect"
    );
    fetchData(
      masterServiceBaseUrl +
        "/externalOrganizationId/getAllExternalOrganizationId",
      setExternalOrganisation,
      "externalOrganizationId"
    );
    fetchDetails(
      masterServiceBaseUrl + "/term/getAllActiveTerm",
      setTermData,
      "term",
      "reactSelect"
    );
    getStudentData(`${enrollmentServiceBaseUrl}/student/getStudent/${id}`);

    if (id) {
      fetchAllData(
        enrollmentServiceBaseUrl +
          "/studentApplication/getStudentApplicationByStudentId/" +
          id,
        setApplicationIdData
      );
    }
  }, []);
  const fetchAllData = (url, stateMethod) => {
    getAPI(
      url,
      (data) => {
        stateMethod(data?.data);
      },
      (data) => {
        // failureToast(data['message']);
      }
    );
  };

  const [businessArea, setBusinesArea] = useState([]);
  const [termData, setTermData] = useState([]);

  // const [businessArea,setBusinesArea] = useState([])
  // const [termData,setTermData] = useState([]);

  const [applicationIdData,setApplicationIdData] = useState([]);
  //  console.log(studentIdStatus,'studentIdStatus')

  useEffect(() => {
    console.log(externalOrganisation,'externalOrganisation');
  }, [applicationIdData]);
  return (
    <Container className={`mt-3`}>
      <Card>
        <CardHeader>
          <h3 className="floatLeft">Student Information</h3>
          <a>
            <h3
              className="floatLeft ml-3"
              style={{
                display: "flex",
                alignItems: "center",
                textDecoration: "underline",
                color: "blue",
              }}
            >
              Student Dashboard
            </h3>
          </a>
        </CardHeader>
        <CardBody>
          <Accordion
            title={`Student Details`}
            key={"student_details"}
            isFormOpen={true}
          >
            <StudentDetailsRendere
              data={studentDetails}
              bloodGrp={bloodGrp}
              gender={gender}
              category={category}
              studentIdStatus={studentIdStatus}
              schoolStudingIn={externalOrganisation}
            />
          </Accordion>
          <Accordion
            title={`Contact Details`}
            key={"contact_details"}
            isFormOpen={false}
          >
            <ContactDetailsRendere
              data={contactDetails}
              country={country}
              stateName={stateName}
              pincode={pincode}
              city={city}
            />
          </Accordion>
          <Accordion
            title={`Other Details`}
            key={"other_details"}
            isFormOpen={false}
          >
            <OtherDetailsRendere data={otherDetails} parentsOcc={parentsOcc} />
          </Accordion>
          {/* <Accordion
            title={`GST Details`}
            key={"gst_details"}
            isFormOpen={true}
          >
            <GstDetailsRendere data={gstDeatls} gstCategory={gstCategory} />
          </Accordion> */}
          <Accordion
            title={`Bank Details`}
            key={"bank_details"}
            isFormOpen={false}
          >
            <BankDetailsRendere data={bankDetails} />
          </Accordion>
          <Accordion
            title={`Application Details`}
            key={"application_details"}
            isFormOpen={false}
          >
            <ApplicationDetails
              applicationIdData={applicationIdData}
              businessArea={businessArea}
              termData={termData}
              programAction={programAction}
            />
          </Accordion>
          <Accordion
            title={`Log Details`}
            key={"log_details"}
            isFormOpen={false}
          >
            <LogDetailsRendere data={logDetails} />
          </Accordion>
        </CardBody>
        <CardFooter>
          <Button
            color="info"
            size="md"
            type="button"
            className={"floatRight mx-1 "}
            onClick={() => props.history.push("/admin/studentInfo")}
            // onClick={ () => moveToNextStep() }
          >
            Close
          </Button>
        </CardFooter>
      </Card>
    </Container>
  );
};

export default StudentInformation;
