import {failureToast, getAPI} from "../../../../../../services/http";
import {removeDuplicates} from "../../../common/utils/methods/commonMethods/utilityMethod";

export const fetchData =(url,stateMethod,name)=>{
    getAPI(
        url,
        (data) => {
            let tempArray = [];
            let tempArray2 = [];
            let tempArray3 = [];
            if(data.data && data.data.length > 0){
                data.data.map((item)=>{
                    if(name ==='studentFromApplication'){
                        tempArray.push({
                            id: item.studentId,
                            text: item.studentId
                        })
                        tempArray2.push({
                            text: item.applicationId,
                            id: item.applicationId,
                        })
                        tempArray3.push({
                            text: item.opportunityId,
                            id: item.opportunityId,
                        })
                    } else if(name ==='student') {
                        tempArray.push({
                            id: item.studentId,
                            desc: item.studentFirstName + ' ' + item.studentLastName,
                            text: item.studentId
                        })
                        // tempArray = tempArray.filter(function(item, pos, self) {
                        //     return self.indexOf(item) === pos;
                        // })
                    } else if(name ==='exam'){
                        tempArray.push({
                            id:item.id,
                            desc:item.shortDescription,
                            text:item.examId
                        })
                    }else if(name === 'externalOrganization'){
                        tempArray.push({
                            id:item.id,
                            text : item.id,
                            desc:item.organizationName,
                            organizationShortName : item.organizationShortName
                        })
                    } else if(name ==='courseId'){
                        tempArray.push({
                            id:parseInt(item.courseId),
                            text : item.courseId,
                            desc:item.courseId
                        });
                    } else if ( name === 'academicYear'){
                        tempArray.push({
                            id : item.id,
                            text: item.academicYearDisplayValue,
                            desc : item.academicYearDisplayValue
                        })
                    }
                })
                if(name === 'studentFromApplication'){
                    const uniqueArray = removeDuplicates(tempArray);
                    stateMethod.student(uniqueArray);
                    stateMethod.application(tempArray2);
                    stateMethod.opportunity(tempArray3);
                    return;
                }
                stateMethod(tempArray);
            }else{
                stateMethod([]);
            }
        },
        (data) => {
            console.error('Error in fetchStudent Ids-------->',data.message);
        }
    );
}

// export const fetchExamIds =(url,stateMethod)=>{
//     getAPI(
//         url,
//         (data) => {
//             let tempArray = [];
//             if(data.data && data.data.length > 0){
//                 data.data.map((item)=>{
//                     tempArray.push({
//                         id:item.id,
//                         desc:item.shortDescription,
//                         text:item.examId
//                     })
//                 })
//                 stateMethod(tempArray);
//             }
//         },
//         (data) => {
//             console.error('Error in fetchStudent Ids-------->',data.message);
//         }
//     );
// }

 export const getExamDates = (url ,setMethod)=>{
     try{
         getAPI(
             url,
             (data) => {
                 let tempArray = [];
                 if (data.data && data.data.length > 0) {
                     data.data.map((item) => {
                         tempArray.push({
                             id:item.id,
                             text:item.examStartDate,
                             desc:item.examStartDate
                         })
                     })
                     // console.log('Exam dates from api---->',tempArray);
                     setMethod(tempArray);
                 }
             },
             (data) => {
                 failureToast(data.message);
                 // console.log('Error in getExamDates Ids-------->', data.message);
             }
         );
     }catch(e){
         console.error('Error in getExamDates -------->',e);
     }
 }


 // const removeDuplicates =(arr)=>{
 //     const uniqueArray = arr.filter((thing, index) => {
 //         const _thing = JSON.stringify(thing);
 //         return index === arr.findIndex(obj => {
 //             return JSON.stringify(obj) === _thing;
 //         });
 //     });
 //     return uniqueArray;
 // }
