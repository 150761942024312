import React from 'react';
import Style from './popup.module.scss';
import { Modal, ModalBody, Button } from 'reactstrap';

const ConfirmationBackDialog = ({ isOpen, onAgree = () => { }, onDisagree = () => { }, setIsOpen = () => { }, msg, headerMsg, popupSymbol, type, headerStyle }) => {
    return (
        <Modal isOpen={isOpen} className={Style.mode_container} centered zIndex={2000}>
            <ModalBody className={Style.secondary_body_content}>
                <div className='d-flex justify-content-between align-item-center'>
                   <span className={Style.backPrompt_icon}> {'?'} </span>
                   <span className='cursor p-1' onClick={() => { setIsOpen(false); onDisagree(); }}> {'x'} </span>
                </div>
                <h2 className={`${Style.secondary_confirmation_message}`}>{headerMsg ?? 'All information will be lost.'}</h2>
                <p className={Style.secondary_signOut_message}>{msg ?? 'Are you sure you want to leave this page?'}</p>
                <div className='ml-n3'>
                    <Button className={`${Style.btn_back}` } onClick={() => { setIsOpen(false); onDisagree(); }}>
                        No
                    </Button>
                    <Button className={`${Style.btn_back}`} onClick={() => onAgree()}>
                        Yes go back
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ConfirmationBackDialog;

