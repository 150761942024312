import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { getApprovalRequests } from 'redux/concessionApproval/actions';
import CustomLoader from '../../common/commonComponents/Loader/loader';
import { endpoint } from '../../common/constant';
import { fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import VerificationPopup from './verificationPopup';

const DocumentVerification = ({ documentAttached, applicationId, userPermissions, applicationQuotationDetailsId, appliedFilters , appliedPsid}) => {

    const [openPopOver, setOpenPopOver] = useState(false);
    const approvalState = useSelector(state => state.concessionApproval);
    const dispatch = useDispatch();

    const handleClose = (e, updateRequests=false) => {
        setOpenPopOver(false);
        if(updateRequests) {
            dispatch(getApprovalRequests({
                // roleId: approvalState.approverType.value, 
                // status: 'pending', 
                approvalStatus: `${approvalState.approverType.value}_${'pending'.toString()?.toUpperCase()}`,
                businessAreaValue: appliedFilters?.businessArea?.length ? appliedFilters?.businessArea : approvalState.allBusinessAreas.map(i => i.label),
                ...(appliedPsid ? {psid : appliedPsid} : appliedFilters),
            },approvalState?.currentPage));
        }
    }

    return (
        <>
            {documentAttached ? (<Typography variant='body2' className="approval-screen-view-button" onClick={() => setOpenPopOver(true)}>View</Typography>) : "-"}
            {openPopOver && <VerificationPopup openPopOver={openPopOver} applicationQuotationDetailsId={applicationQuotationDetailsId} documentAttached={documentAttached} applicationId={applicationId} handleClose={handleClose} userPermissions={userPermissions} />}
        </>
    )
}

export default DocumentVerification;