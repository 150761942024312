import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Container, Table, Col, Collapse, Row, Form } from "reactstrap";
import { useHistory } from "react-router-dom";
import CommonInput from "../../../common/formFeilds/input/commonInput";
import { MasterHeaderAuto } from "../../../common/commonComponents/masterHeader/masterHeader";
import {
    getAPI,
    masterServiceBaseUrl,
    pricingServiceBaseUrl,
    putpost
} from "services/http";
import {
    successToast,
    failureToast,
} from '../../../common/utils/methods/toasterFunctions/function';
import {
    fetchAllData,
    fetchDetails,
    getDispValForReactSelectByOneID,
    getUniqueKey,
} from "../../../common/utils/methods/commonMethods/utilityMethod";
import moment from "moment";
import FeeSetupRulesHeader from "./feeSetupRulesHeader";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import './index.scss';
import { GetPagination } from "../../../common/commonComponents/pagination/pagination";
import CloseModel from "../itemTypeSequenceSetup/model/closeModel";
import CustomLoader from "../../../common/commonComponents/Loader/loader";
import { DropDownButton } from "../../../masters/examSyllabus/examSyllabusForm";
import { PermissionContext, RolePermissions } from "../../../../../../appContext";
import { endpoint, pages, constants } from "../../../common/constant";
import CustomButton from "../../../../../../components/CustomButton/index";
import { button } from "./properties";
import { PermisionDenied } from "../../../common/commonComponents";
import Index from "views/Index";
const getCourseDescription = (item, allCourseDetailsData, mode) => {
    if (item.courseIds.length === 1) {
        return getDispValForReactSelectByOneID(mode === 'view' ? item.courseIds[0] : item.isNew ? item.courseIds[0].value : item.courseIds[0], 'DescValue', allCourseDetailsData)
    } else if (item.isAllSelected) {
        return `All Selected`;
    } else {
        let flag = false;
        item.courseIds.forEach(item => { if (item.value === '*') { flag = true } })
        if (flag)
            return 'All Selected'
        return `${item.courseIds.length} Selected`
    }
}
const getSerialNumber = (index, pagination) => {
    return ((pagination?.currentPage - 1) * 10) + (index + 1)
}
const TDRow = (props) => {
    const {
        pagination,
        action,
        item,
        index,
        editRow,
        allCourseAttributeData,
        allCourseDetailsData,
        allCourseAttributeValueData,
        deleteRow,
        getCourseIds,
        permissionSet
    } = props;

    // const Action = (action)=>{
    //     return (
    //         (action === 'new' || action === 'edit') ?
    //             <td className="text-centers p-2 mx-1" style={{textAlign: 'center'}}>
    //                 <Button key={'EditButton'} color="info" size="sm" type="button"  className=" mx-1"
    //                         onClick={() => editRow(index,'edit')}
    //                 >
    //                     <i className="fas fa-edit"/>
    //                 </Button>
    //                 <Button color="info" size="sm" className=" mx-1"
    //                         onClick={() => deleteRow(index)}>
    //                     <i className="fas fa-trash"/>
    //                 </Button>
    //             </td>
    //             :
    //             null
    //     )
    // }
    return (
        <tr key={'feeSetupRule' + index}>
            <td className="text-center p-2 mx-1">
                {getSerialNumber(index, pagination)}
            </td>
            <td className="text-center p-2 mx-1">
                {getDispValForReactSelectByOneID(item.courseAttributeId, 'DispValue', allCourseAttributeData)}
            </td>
            <td className="text-center p-2 mx-1">
                {getDispValForReactSelectByOneID(item.courseAttributeId, 'DescValue', allCourseAttributeData)}
            </td>
            <td className="text-center p-2 mx-1">
                {
                    getDispValForReactSelectByOneID(item.courseAttributeValueId, 'DispValue', allCourseAttributeValueData)
                }
            </td>
            <td className="text-center p-2 mx-1">
                {getDispValForReactSelectByOneID(item.courseAttributeValueId, 'DescValue', allCourseAttributeValueData)}
                {/*{ item.courseAttributeValueDescription }*/}
            </td>
            <td className="p-2 mx-1">
                {/*{ item.courseId }*/}
                {<DropDownButton direction={'bottom'} data={getCourseIds(item['courseIds'])} />}
            </td>
            <td className="text-center p-2 mx-1">
                {getCourseDescription(item, allCourseDetailsData, 'view')}
            </td>
            <td className="text-center p-2 mx-1">
                {item.dayCountFromTermStart}
            </td>
            <td className="text-center p-2 mx-1">
                {item.dayCountFromCourseStart}
            </td>
            {
                (action === 'new' || action === 'edit') && (permissionSet && (permissionSet.includes('U') || permissionSet.includes('D'))) ?
                    <td className="text-centers p-2 mx-1" style={{ textAlign: 'center' }}>
                        {/*<Button key={'EditButton'} color="info" size="sm" type="button"  className=" mx-1"*/}
                        {/*        onClick={() => editRow(index,'edit')}*/}
                        {/*>*/}
                        {/*    <i className="fas fa-edit"/>*/}
                        {/*</Button>*/}
                        <CustomButton
                            key={'EditButton'}
                            className={'mx-1'}
                            permissionType={'U'}
                            forTable={true}
                            icon={true}
                            onClick={() => editRow(item.key, 'edit')}
                            permissionSet={permissionSet}
                        />
                        {/*<Button color="info" size="sm" className=" mx-1"*/}
                        {/*        onClick={() => deleteRow(index)}>*/}
                        {/*    <i className="fas fa-trash"/>*/}
                        {/*</Button>*/}
                        <CustomButton
                            className={'mx-1'}
                            permissionType={'D'}
                            forTable={true}
                            icon={true}
                            onClick={() => deleteRow(index,item.key)}
                            permissionSet={permissionSet}
                        />
                    </td>
                    :
                    null
            }
        </tr>
    );
};


const fetchCourseDetails = (url, setMethod) => {
    try {
        getAPI(
            url,
            (data) => {
                if (data.data.length > 0) {
                    let tempArray = [];
                    data.data.map((item) => {
                        tempArray.push({
                            id: item.courseId,
                            desc: item.courseDescription,
                            text: item.courseName
                        })
                    })
                    setMethod(tempArray);
                }
            },
            (data) => {
                failureToast(data['message']);
            }
        );
    } catch (e) {
        console.error("Error", e);
    }
}

const FeeSetupRules = (props) => {
    const { action, id = null } = props.match.params;
    const { label, placeHolder, message, headerTitle, headerList, button } = require('./properties/index.json');
    let history = useHistory();
    const [groupCode, setGroupCode] = useState([]);
    const [companyCode, setCompanyCode] = useState([]);
    const [academicCareer, setAcademicCareer] = useState([]);
    const [term, setTerm] = useState([]);
    const [courseAttributeValueDropDown, setCourseAttributeValueDropDown] = useState([])
    const [courseAttribute, setCourseAttribute] = useState([])
    const status = [
        { label: 'ACTIVE', text: 'ACTIVE', id: 'ACTIVE' },
        { label: 'INACTIVE', text: 'INACTIVE', id: 'INACTIVE' },
    ];
    // const headerList = [{name: "S. No"}, {name: "Course Attribute"}, {name: "Description"}, {name: "Course Attribute Value"}, {name: "Description"}, {name: "Course ID"}, {name: "Description"}, {name: "Add upto no. of Days"}, {name: "Total No. of Days"}, {name: "Action"}]
    const [headerData, setHeaderData] = useState({
        businessGroupId: '',
        companyCodeId: '',
        academicCareerId: '',
        academicCareerDescription: '',
        effectiveDate: moment(),
        status: 'ACTIVE',
        term: ''
    })
    // const [feeSetupTableData,setFeeSetupTableData] = useState([]);
    const [tableData, setTableData] = useState([]);

    const [savedStateData, setSavedStateData] = useState([]);
    const [tableDataShow, setTableDataShow] = useState([])
    const [courseAttributeValueMasterData, setCourseAttributeValueMasterData] = useState([])
    const [courseAttributeValueMappingData, setCourseAttributeValueMappingData] = useState([])
    const [termMasterData, setTermMasterData] = useState([])
    const [courseIds, setCourseIds] = useState([]);
    const [courseIdDropDown, setCourseIdDropDown] = useState([])
    const [termDropDownData, setTermDropDownData] = useState([])
    const [courseAttributeId, setCourseAttributeId] = useState(null);

    const [isEdit, setIsEdit] = useState(false);
    const [feeSetupData, setFeeSetupData] = useState(null);
    const [allCompanyCode, setAllCompanyCode] = useState([]);
    const [allTermData, setAllTermData] = useState([])
    const [allCourseAttributeData, setAllCourseAttributeData] = useState([]);
    const [allCourseAttributeValueData, setAllCourseAttributeValueData] = useState([]);
    const [allCourseDetailsData, setAllCourseDetailsData] = useState([]);
    const [dateDiffernce, setDateDifference] = useState('');

    const [courseAttributeCRCTId,setCourseAttributeCRCTId] = useState(null);
    const [updateTableKey,setUpdateTableKey] = useState(new Date().getMilliseconds());

    useEffect(() => {
        if (action === 'edit' || action === 'view' || action === 'new') {
            if ((action === 'edit' || action === 'view') && allCompanyCode.length > 1 && allTermData.length > 1 && groupCode.length > 1 && academicCareer.length > 1 && courseAttributeId) {
                setApiLoader(false);
            }
            if (action === 'new') {
                if (groupCode.length)
                    setApiLoader(false)
            }
        }
    }, [allCompanyCode, allTermData, tableData, groupCode, academicCareer, courseAttributeId])
    // useEffect(()=>{
    //
    // },[groupCode])

    useEffect(()=>{
        getAPI(`${endpoint.courseAttribute.getCRTYId}${constants.FEE_SETUP_RULE_COURSE_ATTRIBUTE_DISP_VALUE}`,
            (data) => {
                if (data.data) {
                    setCourseAttributeCRCTId(data?.data?.id)
                }else{
                    failureToast(data['message']);
                }
            },
            (data) => {
                failureToast(data['message']);
            }
        )
    },[])

    let courseAttributeKey = 'crct'
    const [pagination, setpagination] = useState({
        currentPage: 1,
        nextPage: null,
        previousPage: null,
        totalPage: null,
    });

    const pageSize = 10;

    const permissions = useContext(PermissionContext);

    const userPermissions = RolePermissions(
        permissions,
        pages['feeSetupRules']['id']
    );

    const addNewRow = () => {
        if (action === 'edit' && !isSomeThingEdit) {
            setIsSomeThingEdit(true);
        }
        let tempArray = [];
        let tempObject = {
            isEdit: false,
            isNew: true,
            courseAttributeId: courseAttributeCRCTId,
            courseAttributeValueId: '',
            courseIds: [],
            status: "ACTIVE",
            courseAttributeDescription: '',
            courseAttributeValueDescription: '',
            courseIdDescription: '',
            dayCountFromTermStart: '',
            dayCountFromCourseStart: dateDiffernce,
            courseIdDropDown: [],
            key: getUniqueKey()
        }
        tempArray = [...tableDataShow, tempObject];

        if (tempArray.length > pageSize) {
            let n = { ...pagination };
            n["totalPage"] = Math.floor(tempArray.length / pageSize);
            if (tempArray.length % pageSize !== 0) {
                n["totalPage"] += 1;
            }
            if (n.totalPage !== n.currentPage) {
                // if(n.totalPage !== pagination.totalPage && (tableDataShow.length % pageSize === 0)){
                n.currentPage = n.totalPage;
                // }
                setTableDataShow(tempArray);
                setTableData([...tempArray.slice((pagination.currentPage) * pageSize, tempArray.length + 1)]);
                setpagination(n);
            } else {
                setTableData([...tempArray.slice((pagination.currentPage - 1) * pageSize, tempArray.length + 1)]);
                setTableDataShow(tempArray);
            }
        } else {
            setTableData(tempArray);
            setTableDataShow(tempArray)
        }
        // setTableDataShow(tempArray);
    }

    const deleteRow = (index,key) => {
        // let exactIndex = index + (pagination.currentPage - 1) * pageSize;
        let exactIndex = tableData.findIndex(item => item.key === key);
        let exactIndex2 = tableDataShow.findIndex(item => item.key === key);
        if(exactIndex === -1) {
            return
        }
        if (window.confirm('Are you sure want to delete this row?')) {
            let tempArray = [...tableData];
            let tempArray2 = [...tableDataShow];
            tempArray.splice(exactIndex, 1);
            setUpdateTableKey(new Date().getMilliseconds())

            if (tempArray2[exactIndex2]['isNew']) {
                tempArray2.splice(exactIndex2, 1);
                // setUpdateTableKey(new Date().getMilliseconds())
            } else {
                tempArray2[exactIndex]['status'] = 'INACTIVE';
                tempArray2[exactIndex]['isDeleted'] = true;
            }
            if (tempArray.length === 0 && pagination.currentPage > 1) {
                let n = { ...pagination };
                n.currentPage = n.currentPage - 1;
                n.totalPage = n.totalPage - 1;
                setTableData(tempArray2.slice((n.currentPage - 1) * pageSize, (n.currentPage) * pageSize));
                setTableDataShow(tempArray2);
                setpagination(n);
            } else {
                setTableData([...tempArray]);
                setTableDataShow([...tempArray2])
            }
        }
    }

    const editRow = (key, type) => {
        let exactIndex = tableData.findIndex(item => item.key === key);
        let exactIndex2 = tableDataShow.findIndex(item => item.key === key);
        if (action === 'edit' && !isSomeThingEdit) {
            setIsSomeThingEdit(true);
        }
        // if(isEdit && type ==='edit'){
        //     failureToast('Please save or delete current changes.')
        // }else {
        let tempArray = [...tableData.slice(0)];
        let tempArray2 = [...tableDataShow]
        if (type === 'edit') {
            tempArray[exactIndex].isEdit = true;
            setTableData([...tempArray]);
            setIsEdit(true);
        } else if (type === 'cancel') {
            // let exactIndex = index + (pagination.currentPage - 1) * pageSize;
            // tempArray[index].isEdit = false;
            tempArray[exactIndex] = {
                ...savedStateData[exactIndex],
                isEdit: false
            }
            tempArray2[exactIndex2] = {
                ...savedStateData[exactIndex2],
                isEdit: false
            }
            setTableData([...tempArray]);
            setTableDataShow(tempArray2);
            setIsEdit(false);
        } else if (type === 'save') {
            tempArray[exactIndex].isEdit = false;
            setTableData(tempArray);
            setIsEdit(false);
        }
        // }
    }
    const resetTableData = (data) => {
        let tempArray = [...data];
        data.map((item, index) => {
            if (item.isNew || item.isEdit) {
                if ((item.courseAttributeValueId && item.courseAttributeValueId !== '')) {
                    item.courseIds = [];
                    item.courseAttributeValueId = '';
                    item.courseAttributeValueDescription = '';
                    item.dayCountFromTermStart = '';
                    item.dayCountFromCourseStart = '';
                }
            }
        });
        return tempArray;

    }
    const resetCourseIdsAndOtherValue = () => {
        setCourseIds([]);
        setCourseIdDropDown([]);
        let tempArray = resetTableData(tableData)
        let tempArray2 = resetTableData(tableDataShow)
        setTableData(tempArray)
        setTableDataShow(tempArray2)
    }

    const fetchTermStartEndDate = (businessGroupId, companyCodeId, academicCareerId, value) => {
        try {
            getAPI(
                masterServiceBaseUrl + `/termDateMaster/getTermDateByGroupCompanyCodeCareerTermId?groupCode=${businessGroupId}&companyCode=${companyCodeId}&termId=${value}&academicCareerId=${academicCareerId}`,
                (data) => {
                 
                    if (data.data) {
                        let startDate = moment(data.data.termStartDate);
                        let EndDate = moment(data.data.termEndDate);
                        let DateDiff = EndDate.diff(startDate, 'days')
                        setDateDifference(DateDiff)
                    }
                },
                (data) => {
                    failureToast(data['message']);
                }
            )
        } catch (e) {
            console.error("Error", e);
        }
    }

    const updateHeaderData = (key, value) => {
        if (action === 'edit' && !isSomeThingEdit) {
            setIsSomeThingEdit(true);
        }
        if (key === 'academicCareerId') {
            resetCourseIdsAndOtherValue();
            if (academicCareer.length > 0) {
                let desc;
                academicCareer.map((item) => {
                    if (parseInt(item.id) === parseInt(value)) {
                        desc = item.desc;
                    }
                });
                setHeaderData(preState => ({ ...preState, [key]: value, ['academicCareerDescription']: desc }));
            }
        } else if (key === 'businessGroupId') {
            headerData.academicCareerId = '';
            headerData.academicCareerDescription = '';
            headerData.term = '';
            setAcademicCareer([]);
            setHeaderData(preState => ({ ...preState, [key]: value, ['companyCodeId']: '' }));
            setCourseAttributeValueMappingData([]);
            setCourseAttributeValueDropDown([]);
            resetCourseIdsAndOtherValue();
            if (headerData['companyCodeId'] !== '' && courseAttributeId && value && action === 'new') {
                fetchAllData(masterServiceBaseUrl + `/courseAttributeValueMapping/getCourseAttributeValueByCourseAttributeIdAndGroupCodeIdAndCompanyCodeId/?groupCodeid=${value}&companyCodeId=${headerData['companyCodeId']}&courseAttributeId=${courseAttributeId}`, setCourseAttributeValueMappingData);
            }
        } else if (key === 'companyCodeId' && action === 'new') {
            headerData.academicCareerId = '';
            headerData.academicCareerDescription = '';
            headerData.term = '';
            setHeaderData(preState => ({ ...preState, [key]: value }));
            setCourseAttributeValueMappingData([]);
            setCourseAttributeValueDropDown([]);
            resetCourseIdsAndOtherValue();
            if (headerData['businessGroupId'] !== '' && courseAttributeId && value) {
                fetchAllData(masterServiceBaseUrl + `/courseAttributeValueMapping/getCourseAttributeValueByCourseAttributeIdAndGroupCodeIdAndCompanyCodeId/?groupCodeid=${headerData['businessGroupId']}&companyCodeId=${value}&courseAttributeId=${courseAttributeId}`, setCourseAttributeValueMappingData);
            }
        }
        else if (key === 'term') {
            setHeaderData(preState => ({ ...preState, [key]: value }));
            if (headerData['businessGroupId'] !== '' && headerData['companyCodeId'] !== '' && headerData['academicCareerId'] !== '' && value) {
                fetchTermStartEndDate(headerData.businessGroupId, headerData.companyCodeId, headerData.academicCareerId, value);
            }
        }
        else {
            setHeaderData(preState => ({ ...preState, [key]: value }));
        }
    }
    const fetchCourseIds = (companyCodeId, businessGrpId, value, academicCareerId, stateMethod, index) => {
        try {
            let exactIndex = tableData.findIndex(item => item.key === index);
            let exactIndex2 = tableDataShow.findIndex(item => item.key === index);
            getAPI(
                masterServiceBaseUrl + `/courseCatalogCourseHeader/getCourseCatalogHeaderByHeaderData?companyCode=${companyCodeId}&groupCode=${businessGrpId}&courseAttributeValueId=${value}&academicCareer=${academicCareerId}`,
                (data) => {
                    if (data.data.length > 0) {
                        let tempArray = [];
                        // let exactIndex = index + (pagination.currentPage - 1) * pageSize;
                        let tempArray2 = [{ value: '*', label: 'All' }];
                        let tableRows = [...tableData];
                        let tableRowsShow = [...tableDataShow];
                        let tempObj = tableRows[exactIndex];

                        data.data.map(item => {
                            tempArray.push(item.courseId)
                            tempArray2.push({ value: item.courseId, label: item.courseId })
                        });
                        tempObj = {
                            ...tempObj,
                            courseAttributeValueId: value,
                            courseIdDropDown: tempArray2
                        }
                        if (courseAttributeValueDropDown.length > 0) {
                            courseAttributeValueDropDown.map(item => {
                                if (parseInt(item.id) === parseInt(value)) {
                                    let description = item.desc;
                                    tempObj = {
                                        ...tempObj,
                                        courseAttributeValueDescription: description,
                                        courseId: []
                                    }
                                }
                            })
                        }
                        tableRows[exactIndex] = tempObj;
                        tableRowsShow[exactIndex2] = tempObj;
                        setTableDataShow(tableRowsShow);
                        setTableData(tableRows);
                        stateMethod(tempArray);

                    }
                },
                (data) => {
                    failureToast(data['message']);
                    let tempArray = [];
                    let tableRowsShow = [...tableDataShow];
                    // let exactIndex = index + (pagination.currentPage - 1) * pageSize;
                    let tempArray2 = [{ value: '*', label: 'All' }];
                    let tableRows = [...tableData];
                    let tempObj = tableRows[exactIndex];

                    // data.data.map(item => {
                    //     tempArray.push(item.courseId)
                    //     tempArray2.push({value:item.courseId,label : item.courseId})
                    // });
                    tempObj = {
                        ...tempObj,
                        courseAttributeValueId: value,
                        courseIdDropDown: []
                    }
                    if (courseAttributeValueDropDown.length > 0) {
                        courseAttributeValueDropDown.map(item => {
                            if (parseInt(item.id) === parseInt(value)) {
                                let description = item.desc;
                                tempObj = {
                                    ...tempObj,
                                    courseAttributeValueDescription: description,
                                    courseId: []
                                }
                            }
                        })
                    }
                    tableRows[exactIndex] = tempObj;
                    tableRowsShow[exactIndex2] = tempObj;
                    setTableDataShow(tableRowsShow);
                    setTableData(tableRows);
                    // stateMethod(tempArray);
                }
            );
        } catch (e) {
            console.error("Error", e);
        }
    }

    const updateTableData = (itemKey, key, value, event) => {
        let exactIndex = tableData.findIndex(item => item.key === itemKey);
        let exactIndex2 = tableDataShow.findIndex(item => item.key === itemKey);
        if (action === 'edit' && !isSomeThingEdit) {
            setIsSomeThingEdit(true);
        }
        let tempArray = [...tableData];
        let tempArray2 = [...tableDataShow];
        let tempObject = tempArray[exactIndex];

        if (key === 'courseAttributeValueId') {
            if (headerData.academicCareerId !== '') {
                fetchCourseIds(headerData.companyCodeId, headerData.businessGroupId, value, headerData.academicCareerId, setCourseIds, itemKey);
            }
            else {
                failureToast("Please select Academic Career.");
            }
            // fetchAllData(pricingServiceBaseUrl + `/getFeeSetupCourseIdByCourseAttributeCourseAttributeValue/${courseAttributeId},${value}`, setCourseIds);
            // if (courseAttributeValueDropDown.length > 0) {
            //     courseAttributeValueDropDown.map(item => {
            //         if (parseInt(item.id) === parseInt(value)) {
            //             let description = item.desc;
            //             tempObject = {
            //                 ...tempObject,
            //                 courseAttributeValueDescription: description,
            //                 courseId:[]
            //             }
            //         }
            //     })
            // }
        } else if (key === 'courseIds') {
            let flag = false;
            if (event.action === "select-option" && event.option.value === "*") {
                tempObject = {
                    ...tempObject,
                    courseIds: [...courseIdDropDown],
                    courseIdDescription: 'All'
                }
                flag = true;
            } else if (event.action === "deselect-option" && event.option.value === "*") {
                tempObject = {
                    ...tempObject,
                    courseIds: [],
                    courseIdDescription: ''
                }
                flag = true;
            } else if (event.action === "deselect-option") {
                tempObject = {
                    ...tempObject,
                    courseIds: value.filter(o => o.value !== "*"),
                    courseIdDescription: ''
                }
                if (value.length === 1) {
                    if (allCourseDetailsData.length) {
                        allCourseDetailsData.map((item, index) => {
                            if (item.id === value[0].value) {
                                let desc = item.desc;
                                tempObject = {
                                    ...tempObject,
                                    courseIdDescription: desc,
                                }
                            }
                        })
                    }
                } else if (value.length > 1) {
                    tempObject = {
                        ...tempObject,
                        courseIdDescription: 'Multiple selected',
                    }
                }
                flag = true;
            } else if (value.length === courseIdDropDown.length - 1 && courseIdDropDown.length > 2) {
                tempObject = {
                    ...tempObject,
                    courseIds: [...courseIdDropDown],
                    courseIdDescription: 'All'

                }
                flag = true;
            } else {
                if (value.length > 1) {
                    tempObject = {
                        ...tempObject,
                        courseIds: value,
                        courseIdDescription: 'Multiple selected'
                    }
                } else {
                    tempObject = {
                        ...tempObject,
                        courseIds: value,
                    }
                }
            }
            if (value.length === 1 && !flag) {
                if (allCourseDetailsData.length) {
                    allCourseDetailsData.map((item, index) => {
                        if (item.id === value[0].value) {
                            let desc = item.desc;
                            tempObject = {
                                ...tempObject,
                                courseIdDescription: desc,
                            }
                        }
                    })
                }
            }

            tempArray[exactIndex] = tempObject;
            tempArray2[exactIndex2] = tempObject;
            setTableData(tempArray);
            setTableDataShow(tempArray2)
        } else {
            tempObject = { ...tempObject, [key]: value }
            tempArray[exactIndex] = tempObject;
            tempArray2[exactIndex2] = tempObject;
            setTableData(tempArray);
            setTableDataShow(tempArray2)
        }
        // tempArray[index] = tempObject;
        // tempArray2[exactIndex] = tempObject;
        // setTableData(tempArray);
        // setTableDataShow(tempArray2)
    }
    const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
        if (value && value.some((o) => o.value === "*")) {
            return `${placeholderButtonLabel}: All`;
        } else if (value && value.length) {
            return `${placeholderButtonLabel}: ${value.length} selected`;
        } else {
            return placeholderButtonLabel;
        }
    }

    const chkIsSameCourseIdSelected = (arrays) => {
        if (arrays.length < 2) {
            return false
        }
        const result = arrays.shift().reduce(function (res, v) {
            if (res.indexOf(v) === -1 && arrays.every(function (a) {
                return a.indexOf(v) !== -1;
            })) res.push(v);
            return res;
        }, []);

        return result.length ? true : false
    }

    const submitHandler = () => {
        try {
            if (headerData.businessGroupId === '') {
                failureToast(message.failure.businessGroup)
            } else if (headerData.companyCodeId === '') {
                failureToast(message.failure.companyCode);
            } else if (headerData.academicCareerId === '') {
                failureToast(message.failure.academicCareer);
            } else if (headerData.term === '') {
                failureToast(message.failure.term);
            } else if (courseAttributeId === null) {
                failureToast(message.failure.courseAttributeId);
            }
            else {
                if (tableDataShow.length === 0) {
                    failureToast(message.failure.saveOneRow);
                } else {
                    let flag = false;
                    let tempArray = [...tableDataShow];
                    let tableDataPayloadArray = [];
                    let courseIdsArray = [];
                    if (tempArray.length) {
                        tempArray.map((item, index) => {
                            // if(action ==='edit' && item.isEdit){
                            //     failureToast(message.failure.submitRecord);
                            //     flag = true;
                            // }
                            if (item.courseAttributeValueId === '') {
                                failureToast(message.failure.courseAttributeValue + (index + 1));
                                flag = true;
                            } else if (item.courseIds && item.courseIds.length === 0) {
                                failureToast(message.failure.courseId);
                                flag = true;
                            } else {
                                let flagForAllSelected = false;
                                let tempArray2 = [];
                                let tempCourseIds = [];
                                if (action === 'new' || item.isNew) {
                                    tempCourseIds = item.courseIds;
                                    tempCourseIds.forEach(item => {
                                        if (item.value === '*') {
                                            flagForAllSelected = true;
                                        }
                                        if (item.value !== '*') {
                                            tempArray2.push(item.value);
                                        }
                                    });
                                } else {
                                    tempCourseIds = item.courseIds;
                                    tempCourseIds.forEach(item => {
                                        tempArray2.push(item);
                                    })
                                }
                                let tableDataForPut = {
                                    courseAttributeId: courseAttributeCRCTId,
                                    courseAttributeValueId: item.courseAttributeValueId,
                                    courseIds: item.isNew ? tempArray2 : item.courseIds,
                                    // courseIds:  tempArray2,
                                    dayCountFromCourseStart: item.dayCountFromCourseStart,
                                    dayCountFromTermStart: item.dayCountFromTermStart,
                                    status: item.status,
                                    isAllSelected: item.id ? item.isAllSelected : flagForAllSelected,
                                    id: item.id
                                }
                                let tableDataForPost = {
                                    courseAttributeId: courseAttributeCRCTId,
                                    courseAttributeValueId: item.courseAttributeValueId,
                                    courseIds: tempArray2,
                                    dayCountFromCourseStart: item.dayCountFromCourseStart,
                                    dayCountFromTermStart: item.dayCountFromTermStart,
                                    status: 'ACTIVE',
                                    isAllSelected: flagForAllSelected
                                }
                                let temObject = (action === "new") ? tableDataForPost : tableDataForPut
                                tableDataPayloadArray.push(temObject);
                                if(item.status !== 'INACTIVE'){
                                    courseIdsArray.push(item.isNew ? tempArray2 : item.courseIds)
                                }
                            }
                        })
                    }

                    if (chkIsSameCourseIdSelected(courseIdsArray)) {
                        failureToast(message.failure.dublicateCourseId);
                        return
                    }

                    if (!flag) {
                        let feeSetupRuleMasterDto = {};
                        if (!id) {
                            feeSetupRuleMasterDto = {
                                academicCareerId: headerData.academicCareerId,
                                businessGroupId: headerData.businessGroupId,
                                companyCodeId: headerData.companyCodeId,
                                term: headerData.term,
                                status: headerData.status,
                            }
                        } else {
                            feeSetupRuleMasterDto = {
                                academicCareerId: headerData.academicCareerId,
                                businessGroupId: headerData.businessGroupId,
                                companyCodeId: headerData.companyCodeId,
                                term: headerData.term,
                                status: headerData.status,
                                id: headerData.id
                            }
                        }
                        let requestPayload = {
                            feeSetupRuleMasterDto: feeSetupRuleMasterDto,
                            feeSetupCourseAttributeDtoList: tableDataPayloadArray
                        }
                        let method = (action === 'new') ? 'POST' : 'PUT';
                        let url = pricingServiceBaseUrl + ((action === 'new') ? '/createFeeSetupRuleValue' : `/updateFeeSetupRuleValue/${headerData.id}`);
                        setApiLoader(true);
                        PostData(url, method, requestPayload);
                    }
                }
            }
        } catch (e) {
            console.error('Error in submit Handler----->', e);
        }
    }


    const PostData = (url, requestMethod, dataObject) => {
        setApiLoader(true);
        putpost(url, (data) => {
            if (action === 'edit') {
                successToast(data.message);
                history.push('/admin/feeSetupRules/view/' + id);
                fetchAllData(pricingServiceBaseUrl + '/getFeeSetupRuleValue/' + id, setFeeSetupData);
            } else if ((action === 'new')) {
                successToast(data.message);
                // history.push('/admin/feeSetupRules')
                history.push('/admin/feeSetupRules/view/' + data.data.feeSetupRuleMasterDto.id);
                fetchAllData(pricingServiceBaseUrl + '/getFeeSetupRuleValue/' + data.data.feeSetupRuleMasterDto.id, setFeeSetupData);
            }
        }, (data) => {
            setApiLoader(false)
            failureToast(data.message);
        }, dataObject, requestMethod);
    }

    const fetchRequiredData = () => {
        fetchCourseDetails(`${masterServiceBaseUrl}/courseDetail/getAllCourseDetail`, setAllCourseDetailsData)
        fetchDetails(masterServiceBaseUrl + `/groupCode/getAllActiveGroupCode`, setGroupCode, 'groupCode', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + `/courseAttributeValue/getAllCourseAttributeValue`, setAllCourseAttributeValueData, 'courseAttributeValue', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + `/courseAttribute/getAllCourseAttribute`, setAllCourseAttributeData, 'courseAttribute', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + `/term/getAllActiveTerm`, setTermMasterData, 'term', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + `/courseAttributeValue/getAllActiveCourseAttributeValue`, setCourseAttributeValueMasterData, 'courseAttributeValue', 'reactSelect');
        if (action === 'new' || action === 'edit' || action === 'view') {
            fetchAllData(masterServiceBaseUrl + '/courseAttribute/getAllActiveCourseAttribute', setCourseAttribute);
        }
        if (action === 'view' || action === 'edit') {
            fetchDetails(masterServiceBaseUrl + `/academicCareer/getAllAcademicCareer`, setAcademicCareer, 'academicCareer', 'reactSelect');
            fetchDetails(masterServiceBaseUrl + `/companyCode/getAllCompanyCode`, setAllCompanyCode, 'companyCode', 'reactSelect');
            fetchDetails(masterServiceBaseUrl + `/term/getAllTerm`, setAllTermData, 'term', 'reactSelect');
            fetchAllData(pricingServiceBaseUrl + '/getFeeSetupRuleValue/' + id, setFeeSetupData);
        }
    }


    useEffect(() => {
        fetchRequiredData();
    }, [])

    const getTotalPage = (data = []) => {
        let length = data.length;
        let totalPage = Math.floor(length / pageSize);
        if (length % pageSize !== 0) {
            totalPage += 1;
        }
        return totalPage
    }

    useEffect(() => {
        if (feeSetupData) {
            if (feeSetupData?.feeSetupRuleMasterDto) {
                let data = feeSetupData.feeSetupRuleMasterDto;
                setHeaderData(feeSetupData.feeSetupRuleMasterDto);
                fetchTermStartEndDate(feeSetupData.feeSetupRuleMasterDto.businessGroupId, feeSetupData.feeSetupRuleMasterDto.companyCodeId, feeSetupData.feeSetupRuleMasterDto.academicCareerId, feeSetupData.feeSetupRuleMasterDto.term);
                // setTableData(feeSetupData.feeSetupCourseAttributeDtoList);

                let feeSetupCourseAttributeDtoListWithUniqueKey = feeSetupData.feeSetupCourseAttributeDtoList?.map(item => {return {...item, key: getUniqueKey()}})

                if (feeSetupData.feeSetupCourseAttributeDtoList.length && feeSetupData.feeSetupCourseAttributeDtoList.length > pageSize) {
                    // let tempArray = feeSetupData.feeSetupCourseAttributeDtoList.slice(0, pageSize);
                    let tempArray = feeSetupCourseAttributeDtoListWithUniqueKey.slice(0, pageSize);
                    setTableData([...tempArray]);
                    let n = pagination;
                    n['currentPage'] = 1;
                    // n["totalPage"] = getTotalPage(feeSetupData.feeSetupCourseAttributeDtoList);
                    n["totalPage"] = getTotalPage(feeSetupCourseAttributeDtoListWithUniqueKey);
                    setpagination(n);
                    // setApiLoader(false);
                } else if (feeSetupData.feeSetupCourseAttributeDtoList.length && feeSetupData.feeSetupCourseAttributeDtoList.length <= pageSize) {
                    // setTableData([...feeSetupData.feeSetupCourseAttributeDtoList]);
                    setTableData([...feeSetupCourseAttributeDtoListWithUniqueKey]);
                    // setApiLoader(false);
                }
                if (courseAttributeId) {
                    fetchAllData(masterServiceBaseUrl + `/courseAttributeValueMapping/getCourseAttributeValueByCourseAttributeIdAndGroupCodeIdAndCompanyCodeId/?groupCodeid=${feeSetupData.feeSetupRuleMasterDto.businessGroupId}&companyCodeId=${feeSetupData.feeSetupRuleMasterDto['companyCodeId']}&courseAttributeId=${courseAttributeId}`, setCourseAttributeValueMappingData);
                }

                setTableDataShow([...feeSetupCourseAttributeDtoListWithUniqueKey]);
                setSavedStateData([...feeSetupCourseAttributeDtoListWithUniqueKey])
                setApiLoader(false);
            }
        }
    }, [feeSetupData]);
    useEffect(() => {
        if (courseAttributeId && headerData.businessGroupId !== '' && headerData.companyCodeId !== '') {
            fetchAllData(masterServiceBaseUrl + `/courseAttributeValueMapping/getCourseAttributeValueByCourseAttributeIdAndGroupCodeIdAndCompanyCodeId/?groupCodeid=${headerData.businessGroupId}&companyCodeId=${headerData['companyCodeId']}&courseAttributeId=${courseAttributeId}`, setCourseAttributeValueMappingData);
        }
    }, [headerData, courseAttributeId])

    useEffect(() => {
        if (courseIds?.length > 0) {
            let tempArray = [{ label: 'All', value: '*' }]
            courseIds.map((item) => {
                tempArray.push({
                    label: item, value: item
                })
            })
            setCourseIdDropDown(tempArray);
        }
    }, [courseIds]);

    useEffect(() => {
        if (term && term.length > 0 && termMasterData && termMasterData.length > 0) {
            let tempArray = [];
            term.map((termId) => {
                termMasterData.map((id) => {
                    if (parseInt(id.id) === parseInt(termId)) {
                        tempArray.push(id);
                    }
                })
            })
            setTermDropDownData(tempArray);
        }
    }, [term, termMasterData])

    useEffect(() => {
        if (courseAttribute && courseAttribute.length > 0) {
            let id = null;
            courseAttribute.map((item) => {
                if (item.courseAttributeKey.toLowerCase() === courseAttributeKey) {
                    id = item.id;
                    setCourseAttributeId(id);
                }
            })
        }
    }, [courseAttribute, courseAttributeId])


    useEffect(() => {
        if (courseAttributeValueMappingData?.length > 0 && courseAttributeValueMasterData?.length > 0) {
            let tempArray = [];
            courseAttributeValueMappingData.map(item => {
                courseAttributeValueMasterData.map(element => {
                    if (parseInt(element.id) === parseInt(item.courseAttributeValueId)) {
                        tempArray.push(element)
                    }
                })
            })
            setCourseAttributeValueDropDown(tempArray);
        }
    }, [courseAttributeValueMasterData, courseAttributeValueMappingData])

    const closeHandler = (key, flag = false) => {
        if (key === '1') {
            setIsCloseModelOpen(true);
        } else if (key === '2') {
            if ((action === 'edit' || action === 'view') && flag) {
                setIsCloseModelOpen(false);
                window.close();
            } else if (action === 'new' && flag) {
                setIsCloseModelOpen(false);
                history.push('/admin/feeSetupRules');
            } else {
                setIsCloseModelOpen(false);
            }
        }
    }
    const [isCloseModelOpen, setIsCloseModelOpen] = useState(false);
    const [isSomeThingEdit, setIsSomeThingEdit] = useState(false);
    const [apiLoader, setApiLoader] = useState(true);
    useEffect(() => {
        if (action === 'view' && isSomeThingEdit) {
            setIsSomeThingEdit(false);
        }
    }, [action]);
    const GoBack = () => {
        if (action === 'view' || action === 'edit') {
            window.close();
        } else if (action === 'new') {
            window.history.back();
        }
    }
    const redirectToEditPage = () => {
        let URL = window.location.href;
        if (URL && URL.length) {
            URL = URL.replace('view', 'edit');

            // window.location.href = url;
            window.open(URL, "_self");
            setApiLoader(true);
            fetchAllData(pricingServiceBaseUrl + '/getFeeSetupRuleValue/' + id, setFeeSetupData);
        }
    }
    const getCourseIds = (courseIds) => {
        let tempArray = [];
        if (courseIds && courseIds.length) {
            courseIds.forEach(courseId => {
                tempArray.push({ label: courseId, value: courseId })
            })
        }
        return tempArray;
    }

    return (

        userPermissions ?
            apiLoader
                ?
                <Card style={{ margin: '12px 20px' }}>
                    <CustomLoader apiLoader={apiLoader} loaderHeight={'200px'} loaderWidth={'100% '} />
                </Card>
                :
                <Fragment>
                    <Container fluid className="mt-3">
                        <Row>
                            <Col style={{ marginBottom: '19px', paddingLeft: '30px' }}>
                                {/*<h3 className="mb-0 floatLeft" style={{marginTop: '3px'}}>*/}
                                {/*    <i className="fa fa-arrow-left" style={{marginRight: '10px', cursor: 'pointer'}}*/}
                                {/*       onClick={GoBack}/>*/}
                                {/*    Fee Set Up Rules*/}
                                {/*</h3>*/}
                                {/* {
                                    action === 'view'
                                        ?
                                        <CustomButton
                                            className={'floatRight mr-3'}
                                            content={'Edit Details'}
                                            permissionType={'U'}
                                            onClick={redirectToEditPage}
                                            permissionSet={userPermissions}
                                        />
                                        : null
                                } */}
                            </Col>
                        </Row>
                        <Card>
                            <CardHeader>
                                <h2 className="mb-0 floatLeft">
                                    {headerTitle}
                                </h2>
                            </CardHeader>
                            <CardBody>
                                <FeeSetupRulesHeader
                                    action={action}
                                    allCompanyCode={allCompanyCode}
                                    allTermData={allTermData}
                                    groupCode={groupCode}
                                    setCompanyCode={setCompanyCode}
                                    companyCode={companyCode}
                                    academicCareer={academicCareer}
                                    status={status}
                                    headerData={headerData}
                                    updateHeaderData={updateHeaderData}
                                    setTerm={setTerm}
                                    termDropDownData={termDropDownData}
                                    setTermDropDownData={setTermDropDownData}
                                    setAcademicCareer={setAcademicCareer}
                                    setCourseAttributeValueMappingData={setCourseAttributeValueMappingData}
                                    label={label}
                                    placeholder={placeHolder}
                                />
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader className="border-0">
                                {
                                    (action === 'new' || action === 'edit')
                                        ?
                                        // <Button color="info" size="sm" className="floatRight mx-1" onClick={addNewRow}>
                                        //     {button.add}
                                        // </Button>
                                        <CustomButton
                                            className={'floatRight mx-1'}
                                            content={button.add}
                                            permissionType={'C'}
                                            icon={true}
                                            onClick={addNewRow}
                                            permissionSet={userPermissions}
                                        />
                                        :
                                        null
                                }
                            </CardHeader>
                            <div
                            // style={{height:tableDataShow.length > pageSize && tableData.length >=6  ? '675px' : ''}}
                            >
                                <Table className="align-items-center table-flush">
                                    <MasterHeaderAuto permissionSet={userPermissions}
                                        headerList={action === 'view' || !(userPermissions.includes('U') || userPermissions.includes('D') || userPermissions.includes('C')) ? headerList.slice(0, headerList.length - 1) : headerList} />
                                    <tbody className="list" key={'itemTypeSequenceTableBody'}>
                                        {
                                            tableData && tableData.length > 0 ?
                                                tableData.filter(i=> i.status !== 'INACTIVE').map((item, index) =>
                                                    (item.isNew && (action === 'new')) || (item.isNew && (action === 'edit')) || item.isEdit
                                                        ?
                                                        <tr key={'feeSetupRule' + index}>
                                                            <td className="text-center p-2 mx-1">
                                                                {getSerialNumber(index, pagination)}
                                                            </td>
                                                            <td className="text-center p-2 mx-1">
                                                                {'CRCT'}
                                                                {/*{*/}
                                                                {/*    getDispValForReactSelectByOneID(courseAttributeId,'DispValue',courseAttribute)*/}
                                                                {/*}*/}
                                                            </td>
                                                            <td className="text-center p-2 mx-1">
                                                                {'Course Category'}
                                                                {/*{*/}
                                                                {/*    getDispValForReactSelectByOneID(courseAttributeId,'DescValue',courseAttribute)*/}
                                                                {/*}*/}
                                                            </td>
                                                            <td className="text-center p-2 mx-1">

                                                                {action === 'new' || (item.isNew && (action === 'edit'))
                                                                    ?
                                                                    <CommonInput forTable={true}
                                                                        mandatory={true}
                                                                        type={'reactSelect'}
                                                                        data={courseAttributeValueDropDown}
                                                                        placeHolder={placeHolder.courseAttributeValue}
                                                                        defaultValue={item['courseAttributeValueId']}
                                                                        onChange={(value) => updateTableData(item.key, 'courseAttributeValueId', value)}
                                                                        isDisabled={headerData.academicCareerId !== '' ? false : true}
                                                                    />
                                                                    :
                                                                    getDispValForReactSelectByOneID(item.courseAttributeValueId, 'DispValue', allCourseAttributeValueData)
                                                                    // item.courseAttributeValueId
                                                                }
                                                            </td>
                                                            <td className="text-center p-2 mx-1">
                                                                {
                                                                    action === 'new' ?
                                                                        <CommonInput forTable={true}
                                                                            type={'text'}
                                                                            placeHolder={placeHolder.description}
                                                                            defaultValue={item['courseAttributeValueId'] && item['courseAttributeValueId'] !== '' && item['courseAttributeValueDescription'] !== '' ? item['courseAttributeValueDescription'] : ''}
                                                                            isDisabled={true}
                                                                        />
                                                                        :
                                                                        getDispValForReactSelectByOneID(item.courseAttributeValueId, 'DescValue', allCourseAttributeValueData)
                                                                    // item.courseAttributeValueDescription
                                                                }
                                                            </td>
                                                            <td className=" p-2 mx-1">
                                                                {
                                                                    action === 'new' || (item.isNew && (action === 'edit')) ?
                                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                            {/*<ReactMultiSelectCheckboxes*/}
                                                                            {/*    placeholderButtonLabel={placeHolder.courseId}*/}
                                                                            {/*    // options={(courseIdDropDown.length === 2 ? courseIdDropDown.filter(item=>item.value !== '*') : courseIdDropDown ) || []}*/}
                                                                            {/*    options={(item.courseIdDropDown.length === 2 ? item.courseIdDropDown.filter(item=>item.value !== '*') : item.courseIdDropDown )|| []}*/}
                                                                            {/*    getDropdownButtonLabel={getDropdownButtonLabel}*/}
                                                                            {/*    rightAligned={false}*/}
                                                                            {/*    width={'100%'}*/}
                                                                            {/*    className="custom-styles-for-multiSelect"*/}
                                                                            {/*    value={item['courseIds']}*/}
                                                                            {/*    onChange={(value,event) => updateTableData(index, 'courseIds',value,event)}*/}
                                                                            {/*/>*/}
                                                                            <CommonInput forTable={true}
                                                                                placeHolder={placeHolder.courseId}
                                                                                type={'multiSelectWithCheckboxes'}
                                                                                data={(item.courseIdDropDown.length === 2 ? item.courseIdDropDown.filter(item => item.value !== '*') : item.courseIdDropDown) || []}
                                                                                getDropdownButtonLabel={getDropdownButtonLabel}
                                                                                defaultValue={item['courseIds']}
                                                                                onChange={(value, event) => updateTableData(item.key, 'courseIds', value, event)}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        <DropDownButton direction={'bottom'} data={getCourseIds(item['courseIds'])} />
                                                                    // item.courseId
                                                                }
                                                            </td>
                                                            <td className="text-center p-2 mx-1">
                                                                {getCourseDescription(item, allCourseDetailsData, 'edit')}
                                                            </td>
                                                            <td className="text-center p-2 mx-1">
                                                                <CommonInput forTable={true}
                                                                    mandatory={true}
                                                                    type={'number'}
                                                                    maxLength={4}
                                                                    placeHolder={placeHolder.addUptoNoOfDays}
                                                                    isDisabled={false}
                                                                    defaultValue={item['dayCountFromTermStart']}

                                                                    onChange={(value) => updateTableData(item.key, 'dayCountFromTermStart', value)}
                                                                />
                                                            </td>
                                                            <td className="text-center p-2 mx-1">
                                                                <CommonInput forTable={true}
                                                                    mandatory={true}
                                                                    type={'number'}
                                                                    maxLength={4}
                                                                    placeHolder={placeHolder.totalNoOfDays}
                                                                    defaultValue={item['dayCountFromCourseStart']}
                                                                    isDisabled={false}
                                                                    onChange={(value) => updateTableData(item.key, 'dayCountFromCourseStart', value)}
                                                                />
                                                            </td>
                                                            <td className="text-center p-2 mx-1" style={{ display: 'flex', justifyContent: 'center' }}>
                                                                {
                                                                    item.isEdit
                                                                        ?
                                                                        // <Button color="danger" size="sm"
                                                                        //         className="floatRight mx-1"
                                                                        //         onClick={() => editRow(index, 'cancel')}
                                                                        // >
                                                                        //     Cancel
                                                                        //     {/*<i className="fas fa-times"/>*/}
                                                                        // </Button>
                                                                        <CustomButton
                                                                            className={'floatRight mx-1'}
                                                                            content={'Cancel'}
                                                                            permissionType={'cancel'}
                                                                            forTable={true}
                                                                            onClick={() => editRow(item.key, 'cancel')}
                                                                            permissionSet={userPermissions}
                                                                        />
                                                                        :
                                                                        <Button color="danger" size="sm"
                                                                            className="floatRight mx-1"
                                                                            onClick={() => deleteRow(index, item.key)}>
                                                                            <i className="fas fa-trash" />
                                                                        </Button>
                                                                    // <CustomButton
                                                                    //     className={'floatRight mx-1'}
                                                                    //     permissionType={'D'}
                                                                    //     icon={true}
                                                                    //     forTable={true}
                                                                    //     onClick={() => deleteRow(index)}
                                                                    //     permissionSet={userPermissions}
                                                                    // />
                                                                }
                                                                {/*<Button color="info" size="sm" className="floatRight mx-1"*/}
                                                                {/*    onClick={() => editRow(index,'save')}*/}
                                                                {/*>*/}
                                                                {/*    Save*/}
                                                                {/*</Button>*/}
                                                            </td>
                                                        </tr>
                                                        : item.status !== 'INACTIVE' ?
                                                            <TDRow
                                                                item={item}
                                                                getCourseIds={getCourseIds}
                                                                index={index}
                                                                action={action}
                                                                allCourseAttributeData={allCourseAttributeData}
                                                                editRow={editRow}
                                                                allCourseAttributeValueData={allCourseAttributeValueData}
                                                                allCourseDetailsData={allCourseDetailsData}
                                                                deleteRow={deleteRow}
                                                                pagination={pagination}
                                                                permissionSet={userPermissions}
                                                            /> : null
                                                )
                                                :
                                                null
                                        }
                                    </tbody>
                                </Table>
                            </div>
                            {
                                pagination.totalPage > 1
                                    ?
                                    (
                                        <GetPagination
                                            setclassArray={setTableData}
                                            pagination={pagination}
                                            setpagination={setpagination}
                                            pageSize={pageSize}
                                            state={tableDataShow}
                                        />
                                    )
                                    :
                                    null
                            }
                            <div>
                            <CardHeader className="border-0 floatRight">
                                {/* <Button color="danger" size="sm" className="floatRight mx-1" onClick={() => closeHandler('1')}>
                                    {button.close}
                                </Button> */}
                                {
                                    action === 'edit' || action === 'new'
                                        ?
                                        // <Button color="info" size="sm" className="floatRight mx-1" onClick={submitHandler}>
                                        //     {button.save}
                                        // </Button>
                                        <CustomButton
                                            className={'floatRight mx-1'}
                                            content={'Save'}
                                            permissionType={'C,U'}
                                            forTable={true}
                                            onClick={submitHandler}
                                            permissionSet={userPermissions}
                                        />
                                        :
                                        null
                                }
                                <CustomButton
                                    className={'floatRight mx-1'}
                                    content={"Close"}
                                    permissionType={'cancel'}
                                    permissionSet={userPermissions}
                                    disabled={false}
                                    forTable={true}
                                    onClick={() => { closeHandler('1') }}
                                />
                            </CardHeader>
                            </div>
                        </Card>
                    </Container>
                    <CloseModel isCloseModelOpen={isCloseModelOpen} closeHandler={closeHandler} isSomeThingEdit={isSomeThingEdit} />
                </Fragment>
            :
            <PermisionDenied />
    );
}
export default FeeSetupRules;