import React from 'react'
import CompanyCodeStepper from './component/stepper'
import CompanyCodeAddressInitiator from './component/companyCodeAddInitiator'

const CompanyCodeRedirector = (props) => {
    const { id } = props.match.params;
    if(id === 'New'){
        return <CompanyCodeStepper />
    }else{
        return <CompanyCodeAddressInitiator id={id} isNew={false} />
    }
}

export default CompanyCodeRedirector
