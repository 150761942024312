import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import { ReactComponent as CloudIcon } from "assets/img/svg/CloudArrow.svg";
import { ReactComponent as PdfIcon } from "assets/img/svg/pdfIcon.svg";
import { ReactComponent as UserProfileIcon } from "assets/img/svg/bankDocument.svg";
import { ReactComponent as PencilIcon } from "assets/img/svg/Edit2.svg";
import { RiEdit2Fill } from "react-icons/ri";
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'

import AButton from "../../../../common/form-fields-mui/AButton";
import ALoader from "../../../../common/a-loader";
import {
  failureToast,
  successToast,
} from "../../../../common/utils/methods/toasterFunctions/function";
import "../style.scss";
import { putpost, uploadpost } from "../../../../../../../services/utils";
import { endpoint } from "../../../../common/constant";
import { getUniqueKey } from "../../../../common/utils/methods/commonMethods/utilityMethod";
import { getDownloadFileUrl } from "./helper";
import { MdOutlineFileUpload } from "react-icons/md";

const UserPlaceHolder = () => {
  return (
    <div className="image-placeholder">
      <UserProfileIcon />
    </div>
  );
};

const UploadPhotoPlaceHolder = ({
  maxAllowedFileSize,
  supportedFileTypes,
  handleChange,
  disableUpload,
  file = null,
  loading,
  saveHandler,
  additionalUI,
}) => {
  return (
    <div className="d-flex flex-column">
      <span className="mb-4" style={{ fontSize: "12px" }}>
        Max file size {maxAllowedFileSize} MB
      </span>
      {loading && <ALoader />}
      {file ? (
        <div className="ellipsis-text-container">
          <div className="text-center">
            <PdfIcon />
          </div>
          <div className="mb-4 mt-2 sub-heading ellipsis-text-container">
            {file?.name}
          </div>
          <div className="mb-2">
            <AButton
              className="button_remove_left_margin"
              updatedStyle={{ width: "100%" }}
              variant="primary_filled"
              onClick={saveHandler}
            >
              Save
            </AButton>
          </div>
          <div className="button-container">
            <label htmlFor="profilePhoto">
              <input
                id="profilePhoto"
                type="file"
                style={{ display: "none" }}
                onChange={handleChange}
              />
              <span
                className="upload-btn"
                style={{
                  border: "2px solid rgb(0, 176, 245)",
                  color: "rgb(0, 176, 245)",
                  backgroundColor: "unset",
                }}
              >
                Update
              </span>
            </label>
            <AButton
              updatedStyle={{ height: "41px" }}
              onClick={() => getDownloadFileUrl(file?.key)}
              variant="outline"
            >
              Download
            </AButton>
          </div>
          {additionalUI}
        </div>
      ) : (
        <div>
          <UserPlaceHolder />
          <div className="mb-4 mt-2 sub-heading">
            File supported:{" "}
            {supportedFileTypes.map((item) => `.${item}`).join(", ")}
          </div>

          <div>
            <label className="w-100" htmlFor="profilePhoto">
              <input
                id="profilePhoto"
                type="file"
                style={{ display: "none" }}
                onChange={handleChange}
              />
              <span className="upload-btn">
                Upload file <CloudIcon className="ml-2" />
              </span>
            </label>
          </div>
          {additionalUI}
        </div>
      )}
    </div>
  );
};

// initialFile prop will have name and s3key in object
const UploadAttachments = ({
  disableUpload = false,
  form,
  setFormHandler = () => {},
  serviceName = "STOCK_BRANCH",
  supportedFileTypes = [
    "png",
    "jpeg",
    "jpg",
    "pdf",
    "docx",
    "xlsx",
    "xls",
    "xlsb",
    "csv",
  ],
  maxAllowedFileSize = 2,
  uploadButtonProps = {},
  uploadFileHeader = "Upload supporting document",
  disabledBtn = false,
  customUploadButton, // this function will have 1 param i.e onClick
  additionalUI,
  saveCallback = () => {},
}) => {
  const initialFile = form?.s3key
    ? { name: form?.fileName, key: form?.s3key }
    : null;
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const maxFileSize = maxAllowedFileSize * 1024 * 1024; // mentioned value is in bytes (1MB = 1024*1024)
  // console.log(form, 'form');
  const dataToServer = {
    bulk: false,
    fileName: null,
    docType: "pdf",
    keyValue: `consession_${getUniqueKey()}`,
    serviceName: serviceName,
  };

  const handleChange = (e) => {
    // setLoading(true);
    const file = e.target.files[0];
    // console.log(file.size, maxFileSize, 'size');
    if (file.size > maxFileSize) {
      failureToast(
        `Please select a file of size less than ${maxAllowedFileSize} MB`
      );
      return;
    }

    const fileType = file?.name?.split(".")?.pop();
    if (!supportedFileTypes.includes(fileType)) {
      failureToast(
        `Only ${supportedFileTypes
          .map((item) => `.${item}`)
          .join(", ")} files are allowed!`
      );
      return;
    }

    getFilePath(file.name, file, fileType);
  };

  const getFilePath = (name, file, fileType) => {
    try {
      setLoading(true);
      const formData = {
        ...dataToServer,
        fileName: name,
        docType: fileType,
      };

      putpost(
        endpoint.fetchS3Url.getUrl,
        (data) => {
          if (data.code === 200 && data.data) {
            saveDataToStorage(data.data, file, name);
          }
        },
        (data) => {
          failureToast(data["message"]);
          setLoading(false);
        },
        formData,
        "post"
      );
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const saveDataToStorage = (res, file, name) => {
    try {
      const formData = new FormData();

      formData.append("File", file);
      uploadpost(
        res.storage,
        (data) => {
          setFile({
            ...res,
            name,
          });
          successToast("File uploaded successfully");
          setLoading(false);
        },
        (data) => {
          failureToast("File upload failed, please try again after sometime.");
          setLoading(false);
        },
        file,
        file?.type,
        file?.name
      );
    } catch (e) {
      failureToast("Unable to upload file, please try after sometime.");
      setLoading(false);
    }
  };

  const saveHandler = async () => {
    setLoading(true);
    setFormHandler(file?.key, "s3key");
    setFormHandler(file?.name, "fileName");
    await saveCallback(file);
    setLoading(false);
    setOpen(false);
    setFile(initialFile);
  };

  return (
    <>
      {form?.fileName ? (
        <div className="file-exist-container">
          <PdfIcon className="pdf-icon flex-shrink-0" />
          <span className="ellipsis-text-container">{form?.fileName}</span>
          <RiEdit2Fill
            size={22}
            className={`flex-shrink-0 cursor ${
              disableUpload ? "color-grey-50" : "color-primary"
            }`}
            onClick={() => (disableUpload ? null : setOpen(true))}
          />
        </div>
      ) : customUploadButton ? (
        customUploadButton(() => setOpen(true))
      ) : (
        <AButton
          variant="outline"
          updatedStyle={{ minWidth: "200px" }}
          onClick={() => setOpen(true)}
          disabled={disabledBtn}
          className="mb-3"
          {...uploadButtonProps}
        >
          <MdOutlineFileUpload className="mr-1" size={"24px"} />
          <span className="ml-2">Upload</span>
        </AButton>
      )}
      <Modal size="md" isOpen={open}>
        <ModalBody>
          <div className="d-flex justify-content-between align-items-center">
            <span className="heading-3 color-dark">{uploadFileHeader}</span>
            <IconButtonWrapper
              onClick={() => {
                setOpen(false);
                setFile(initialFile);
              }}
            >
              <CrossIcon />
            </IconButtonWrapper>
          </div>
          <div>
            <UploadPhotoPlaceHolder
              maxAllowedFileSize={maxAllowedFileSize}
              supportedFileTypes={supportedFileTypes}
              disableUpload={disableUpload}
              handleChange={handleChange}
              file={file || initialFile}
              loading={loading}
              saveHandler={saveHandler}
              additionalUI={additionalUI}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UploadAttachments;
