import moment from 'moment';
import React from 'react';
import './style.scss';

const headerList = [
  "Description",
  "Due date",
  "Gross Amount",
  "Concession Amount",
  "Payable Amount",
  "taxes",
  "Net Payable"
]
export const BreakupGrid = ({ processedData,
  totals, itemTypeLabel }) => {


  return (
    <>
      <table className='fee-breakup-grid'>
        {
          Object.keys(processedData).map((date, index) => {
            return <>
              <tr className='header'>
                {headerList.map((headerName, i) => {
                  return <th colSpan={i == 0 ? 3 : ''}>{headerName}</th>
                })}
              </tr>
              {
                processedData[date].map((item, i) => {
                  return <tr>
                    <td colSpan={3}>{item.desc}</td>
                    <td>{moment(item.dueDate).format('DD MMM YYYY')}</td>
                    <td>Rs {item['baseAmount']}</td>
                    <td>{item['consession'] ? `Rs ${item['consession']}` : ''}</td>
                    <td>Rs {item['payableAmt']}</td>
                    <td>Rs {item['tax']}</td>
                    <td>Rs {item['netPayable']}</td>
                  </tr>
                })
              }
             <tr className='total-installment'>
                <td colSpan={3}>{index + 1} {index + 1 == 1 ? 'st' : index + 1 == 2 ? 'nd' : index + 1 == 3 ? 'rd' : 'th'} Installment Total</td>
                <td>{date}</td>
                <td>Rs {totals[date]['totalGross']}</td>
                <td>Rs {totals[date]['conAmt']}</td>
                <td>Rs {totals[date]['totalPayAmt']}</td>
                <td>Rs {totals[date]['totalTax']}</td>
                <td>Rs {totals[date]['netAmt']}</td>
              </tr>
              {Object.keys(processedData).length == index + 1 && <tr className='total'>
                <td colSpan={3}>Total Amount</td>
                <td></td>
                <td>Rs {Math.round(Object.keys(totals).reduce((a, b) => a + totals[b]['totalGross'], 0)* 100) / 100}</td>
                <td>Rs {Math.round(Object.keys(totals).reduce((a, b) => a + totals[b]['conAmt'], 0)* 100) / 100}</td>
                <td>Rs {Math.round(Object.keys(totals).reduce((a, b) => a + totals[b]['totalPayAmt'], 0)* 100) / 100}</td>
                <td>Rs {Math.round(Object.keys(totals).reduce((a, b) => a + totals[b]['totalTax'], 0)* 100) / 100}</td>
                <td>Rs {Math.round(Object.keys(totals).reduce((a, b) => a + totals[b]['netAmt'], 0)* 100) / 100}</td>
              </tr>}
            </>
          })
        }
      </table>
    </>
  )
}
