import React from "react";
import { Input, Button, Table, Label, FormGroup } from "reactstrap";
import { useState } from "react";
import { RiUploadCloudFill } from "react-icons/ri";
// import "views/pages/manage/addressType/node_modules/react-sliding-pane/dist/react-sliding-pane.css";
import { failureToast } from "../../common/utils/methods/toasterFunctions/function";
import Select2 from "react-select2-wrapper";
import { MasterHeaderAuto } from "../../common/commonComponents/masterHeader/masterHeader";
import { NewRow, openInNewTab } from "../../common/utils/methods/commonMethods/utilityMethod";
import DocumentUpload from "./subSubTopicUpload";
import {endpoint} from '../../common/constant';
import CreateButton from "components/FormComponent/Button/New/index";
import UpdateButton from "components/FormComponent/Button/Update/index";
import EditButton from "components/FormComponent/Button/Edit/index";
import { ButtonText } from "variables/Buttons";

import {
  putpost2,
  putpost,
  successToast,
  masterServiceBaseUrl,
  baseUrl,
} from "services/http";
import moment from "moment";
import {
  CustomCard,
  CustomContainer,
  ToExcel,
  GetTemplate
} from "../../common/commonComponents";

const excelDetails = {
  "S.NO.": "sno",
  Subject: "subjectMaster",
  Description: "subSubTopicDescription",
  "Topic ID": "ssTopicId",
  "Effective Date": "effectiveDate",
  Status: "status",
};

const HeaderElement = ({
  data,
  newRow,
  isSaveVisible,
  allClassData,
  allSubjectData,
  setModalVisible = () => {},
}) => {
  const getDisplayVal = (id, list) => {
    const i =
      id &&
      list?.filter((item) => {
        return item.id == id;
      });
    return i && i?.length ? i[0] : "";
  };

  const dataToExport = data?.map((item, index) => {
    return {
      ...item,
      sno: index + 1,
      subjectMaster:
        getDisplayVal(item.subjectMasterId, allSubjectData)?.text ?? "",
      effectiveDate: moment(item.effectiveDate).format("DD-MM-YYYY"),
    };
  });

  return (
    <>
      <h3>Master - Sub Sub Topic</h3>
      <div className="d-flex flex-lg-row flex-sm-column align-items-center">
        {!isSaveVisible && (
          <ToExcel
            data={dataToExport}
            detailObj={excelDetails}
            name="Topic"
            isSaveVisible={isSaveVisible}
          />
        )}
        <GetTemplate url={endpoint.subSubTopicMasterTemplate} isSaveVisible={isSaveVisible}/>
        <Button
          disabled={isSaveVisible}
          color="info"
          size="sm"
          type="button"
          className={
            "export_button floatRight mx-1 my-2 " +
            (isSaveVisible ? " btn-dark" : null)
          }
          onClick={setModalVisible}
        >
          <RiUploadCloudFill className="pr-1" size={18} />
          Upload CSV
        </Button>
        <CreateButton
          text={ButtonText.SubSubTopic.addNew}
          disabled={isSaveVisible}
          className={"floatRight mx-1"+(isSaveVisible?" btn-dark":null)} 
          onClick={() => {newRow()}}
        />
      </div>
    </>
  );
};

var _ = require("lodash");

const SubSubTopicMasterTable = (props) => {
  const {
    isSaveVisible,
    setisSaveVisible = () => {},

    activeSubjectData,

    allSubjectData,
    pagination,
  } = props;

  const [tableDataKey, settableDataKey] = useState("tableDataKey");
  const [isNewDocument, setIsNewDocument] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);

  const getAllData = () => {
    props.fetchClasses(endpoint.subSubTopicMaster.getAll);
  }

  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: "__new",
        subjectMasterId: null,
        ssTopicId: "",
        status: "ACTIVE",
        subSubTopicDescription: "",
      },
      ...props.classArray,
    ]);
  };

  const headerList = [
    { name: "S.No" },
    {
      name: "Subject",
      isRequired: true,
      filterable: true,
      filterFun: props.setSearchStr,
      filterVal: props.searchStr,
    },
    { name: "Description", isRequired: true, colSpan : 3 },
    { name: "SS Topic ID", isRequired: true },
    { name: "Effective Date" },
    { name: "Status", isRequired: true },
    { name: "Actions" },
  ];

  const listToBeRendered = [
    "sno",
    "subjectMasterId",
    "subSubTopicDescription",
    "ssTopicId",
    "effectiveDate",
    "status",
  ];

  const colunmWidths =[1,1,3,1,1,1,1]

  const itemToBeRenderFromMaster = ["subjectMasterId"];
  const propertyList = [null, "text", null, null, null, null, null];
  const arrList = [null, allSubjectData, null, null, null, null, null];

  const saveAll = (cb) => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if (el["editMode"]) {
        if (el["subjectMasterId"] == null) {
          isValid = false;
          failureToast("Please Select Subject");
          return;
        } else if (
          !el["subSubTopicDescription"] ||
          !el["subSubTopicDescription"].trim()
        ) {
          isValid = false;
          failureToast("Please Enter Description");
          return;
        } else if (!el["ssTopicId"] || !el["ssTopicId"].trim()) {
          isValid = false;
          failureToast("Please Enter Topic ID");
          return;
        }
        if (!isValid) {
          return;
        }
        if (_tempArray[index]["__status"] == "__new") {
          putpost(
            endpoint.subSubTopicMaster.create,
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              getAllData();
            },
            (data) => {
              failureToast(data["message"]);
            },
            {
              ..._tempArray[index],
              ssTopicId: _tempArray[index]["ssTopicId"].toUpperCase(),
            },
            "post"
          );
        } else {
          const updatedValues = _tempArray[index];
          delete updatedValues.topicMasterKey;

          putpost(
           `${endpoint.subSubTopicMaster.update}/${ _tempArray[index].id}`,
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              props.fetchClasses(
                endpoint.subSubTopicMaster.getAll
              );
              // delete _tempArray[index]["editMode"];
              // console.log(_tempArray[index]);
              // props.setclassArray(_tempArray);
              settableDataKey(new Date().getMilliseconds());
            },
            (data) => {
              failureToast(data["message"]);
            },
            {
              ...updatedValues,
              ssTopicId: updatedValues["ssTopicId"].toUpperCase(),
            },
            "put"
          );
        }
      }
    });
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
        setIsNewDocument(true);
        // settableDataKey(new Date().getMilliseconds())
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
      cb();
    }
  };

  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]["editMode"] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
      };
      cb();
    }
  };
  const deleteRow = (index) => {
    if (props.classArray[index]["__status"] == "__new") {
      let n = props.classArray;
      n.splice(index, 1);
      props.setclassArray(n);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
    } else {
      if (
        window.confirm("Are you sure you want to delete this Sub Sub Topic?")
      ) {
        putpost2(
          `${endpoint.subSubTopicMaster.delete}/${props.classArray[index].id}`,
          (data) => {
            successToast(data["message"]);
            getAllData();
            settableDataKey(new Date().getMilliseconds());
          },
          (data) => {
            failureToast(data["message"]);
          },
          "PUT"
        );
      }
    }
  };
  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
  };

  return (
    <CustomContainer>
      <CustomCard
        HeaderElement={
          <HeaderElement
            isSaveVisible={isSaveVisible}
            data={props.serverData}
            allSubjectData={allSubjectData}
            newRow={newRow}
            setModalVisible={() => setModalVisible(true)}
          />
        }
      >
        <div id="questionTable" className="table-responsive">
          <Table className="align-items-center table-flush tableLayout">
            <MasterHeaderAuto headerList={headerList} />
            <tbody className="list" key={tableDataKey}>
              {props.classArray
                ? props.classArray.map((el, index) =>
                    !el["editMode"] ? (
                      <NewRow
                        data={{
                          ...el,
                          sno: isNewDocument
                            ? index + (pagination.currentPage - 1) * 10
                            : index + 1 + (pagination.currentPage - 1) * 10,
                        }}
                        listToBeRendered={listToBeRendered}
                        itemToBeRenderFromMaster={itemToBeRenderFromMaster}
                        arrList={arrList}
                        propertyList={propertyList}
                        colunmWidths={colunmWidths}
                      >
                        <td className="text-center  white-breakSpace">
                          <EditButton 
                            disabled={props.isViewOnly}
                            onClick={() => editRow(index)} 
                            // data-testid="editButton"
                          />
                          {/* <Button
                            color="info"
                            size="sm"
                            type="button"
                            disabled={props.isViewOnly}
                            onClick={() => editRow(index)}
                            // data-testid="editButton"
                          >
                            <i className="fas fa-edit" />
                          </Button> */}
                        </td>
                      </NewRow>
                    ) : (
                      <tr key={index + "-class"}>
                        <td className="text-center p-2 mx-1">
                          {el["__status"] !== "__new" ? (
                            index + 1 + (pagination.currentPage - 1) * 10
                          ) : (
                            <Input
                              value=""
                              disabled
                              placeholder="S.No."
                              className="tdInput"
                            />
                          )}
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Select2
                            defaultValue={el["subjectMasterId"]}
                            data={activeSubjectData}
                            onChange={(e) => {
                              updateKey(
                                index,
                                e.target.value,
                                "subjectMasterId"
                              );
                            }}
                            options={{
                              placeholder: "Select Subject",
                              disabled: el["__status"] !== "__new",
                            }}
                            className="tdSelect"
                          />
                        </td>
                        <td className="text-center p-2 mx-1" colSpan={3}> 
                          <Input
                            maxLength={150}
                            defaultValue={el["subSubTopicDescription"]}
                            onKeyPress={(e) => {
                              if (
                                !/^[a-zA-Z0-9, :\()&_-]*$/.test(
                                  String.fromCharCode(e.charCode)
                                )
                              ) {
                                e.preventDefault();
                                return;
                              }
                            }}
                            onChange={(e) => {
                              updateKey(
                                index,
                                e.target.value.trim(),
                                "subSubTopicDescription"
                              );
                            }}
                            disabled={el["__status"] !== "__new"}
                            placeholder="Description"
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={15}
                            defaultValue={el["ssTopicId"]}
                            onKeyPress={(e) => {
                              if (
                                !/^[A-Za-z0-9]+$/.test(
                                  String.fromCharCode(e.charCode)
                                )
                              ) {
                                e.preventDefault();
                                return;
                              }
                            }}
                            onChange={(e) => {
                              updateKey(index, e.target.value, "ssTopicId");
                            }}
                            placeholder="SS Topic ID"
                            disabled={el["__status"] !== "__new"}
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            defaultValue={moment(el["effectiveDate"]).format(
                              "DD-MM-YYYY"
                            )}
                            disabled
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <label className="custom-toggle mx-auto ml-2">
                            <input
                              checked={el.status == "ACTIVE" ? true : false}
                              type="checkbox"
                              id={el.id}
                              onChange={(e) => {
                                settableDataKey(new Date().getMilliseconds());
                                let n = "ACTIVE";
                                if (!e.target.checked) {
                                  n = "INACTIVE";
                                }
                                updateKey(index, n, "status");
                              }}
                            />
                            <span
                              style={{ width: "72px" }}
                              className="custom-toggle-slider rounded-circle activeToggle"
                              data-label-off="Inactive"
                              data-label-on="Active"
                            />
                          </label>
                        </td>
                        <td className="text-center">
                          {" "}
                          <UpdateButton text={ButtonText.SubSubTopic.save} onClick={() => { saveAll() }}/>
                          <Button
                            color="danger"
                            size="sm"
                            type="button"
                            onClick={() => {
                              let n = props.classArray;
                              if (n[index]["__status"] == "__new") {
                                n.splice(index, 1);
                              } else {
                                // n[index]['editMode']=false

                                getAllData();
                              }
                              props.setclassArray(n);
                              setisSaveVisible(false);
                              setIsNewDocument(false);
                            }}
                          >
                            {" "}
                            <i className="fas fa-times" />
                          </Button>{" "}
                        </td>
                      </tr>
                    )
                  )
                : null}
            </tbody>
          </Table>
        </div>
      </CustomCard>
      <DocumentUpload
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        getAllData={getAllData}
      />
    </CustomContainer>
  );
};
export default SubSubTopicMasterTable;
