import React from 'react'
import styles from './style.module.css'

const AButton = ({
  children,
  onClick = () => { },
  updatedStyle = {},
  className = '',
  variant = 'default',
  size = 'sm',
  outlineVariantColor,
  ...props
}) => {

  const sizeMap = {
    lg: {
      height: '62px',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '28px',
      borderRadius: '12px',
    },
    sm: {
      height: '44px',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
      borderRadius: '8px',
    },
    // tiny button
    xs: {
      height: '28px',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '16px',
      borderRadius: '4px',
    }
  }

  const style = {
    padding: "8px",
    // gap: "10px",
    background: "#FFFFFF",
    border: "1px solid #262626",
    borderRadius: "4px",
    lineHeight: "12px",
    height: "29px",
    fontSize: "12px",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    color: '#0F0F0F',
    fontFamily: 'Gilroy-Medium'
  };

  const commonVariantStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'inherit',
    letterSpacing: '1px',
    outline: 'none',
    border: 'none',
    padding: '10px 16px',
    color: '#00B0F5',
    ...sizeMap?.[size],
  }
  const outlineButtonStyle = {
    border: `2px solid ${outlineVariantColor || '#00B0F5'}`,
    ...(size==='xs' ? {borderWidth: '1px'} : ''),
    color: `${outlineVariantColor || '#00B0F5'}`,
  }
  const filledButtonStyle = {
    backgroundColor: '#00B0F5',
    color: '#FFFFFF',
  }
  const variantStyleMap = {
    link: { ...commonVariantStyle, padding: '0' },
    outline: { ...commonVariantStyle, ...outlineButtonStyle },
    outline_danger: {...commonVariantStyle, ...outlineButtonStyle, borderColor: '#DC2804', color: '#DC2804'},
    primary_filled: { ...commonVariantStyle, ...filledButtonStyle },
    danger_filled: { ...commonVariantStyle, ...filledButtonStyle, backgroundColor: '#DC2804' },
    success_filled: { ...commonVariantStyle, ...filledButtonStyle, backgroundColor:'#05945B' }
  }

  const disabledClassMap = {
    link: 'link_disabled_button',
    outline: 'outline_disabled_button',
    primary_filled: 'filled_disabled_button',
    danger_filled: 'filled_disabled_button',
    success_filled: 'filled_disabled_button'
  }

  return (
    <>
      {variant === 'default' && <button className={className} style={{ ...style, ...updatedStyle }} onClick={onClick} {...props} >{children}</button>}
      {variant !== 'default' && <button className={`${className} ${props?.disabled ? `${styles?.[disabledClassMap[variant]]} ${styles.disabled_button}` : ''}`} style={{ ...variantStyleMap[variant], ...updatedStyle }} onClick={onClick} {...props} >{children}</button>}
    </>
  )
}

export default AButton