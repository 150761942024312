import React, { useState, useRef } from "react";
import HeaderComponent from "./HeaderComponent";
import ListComponent from "./ListComponent";
import { useFetchPlanTest } from "../hooks";
import ALoader from "views/pages/manage/common/a-loader";
import { useEffect } from "react";
import { isTestCompleted, isAllTestCompleted } from "./../helper";
import { Button } from "reactstrap";

const TimetableTestListV2 = ({
  setOpenForm,
  formData,
  formHandler,
  batchDetails,
  tests,
  setTests,
  planData,
  setPlanDetails,
  fetchNewLectures,
  setIsNeedToPublish,
  ...props
}) => {
  
  const OPEN_MODE = props?.mode || "NEW";
  const [apiLoader, setApiLoader] = useState(false);
  const [isReadyToPublish, setIsReadyToPublish] = useState(false);
  const initialRender = useRef(true);

  useEffect(() => {
    setIsReadyToPublish(isAllTestCompleted(tests));
    if (initialRender.current) {
      initialRender.current = false;
    } else {
    }
  }, [tests]);


  const testUpdateHandler = (index, value, formKey) => {
    let tempTest = [...tests];
    tempTest[index][formKey] = value;
    tempTest[index]["isComplete"] = isTestCompleted(tempTest[index]);
    tempTest[index]["isItemEdited"] = true
    setTests(tempTest);
    
    if (tests.length !== 0) setIsNeedToPublish(true);
  };

  return (
    <div>
      {apiLoader && <ALoader position={"fixed"} />}
      <ListComponent
        testsArray={tests}
        setTests={setTests}
        setOpenForm={setOpenForm}
        roomsDropdown={props.roomsDropdown}
        testUpdateHandler={testUpdateHandler}
        isReadyToPublish={isReadyToPublish}
        publishTimetable={props?.publishTimetable}
        openMode={OPEN_MODE}
        formData={planData}
        batchName={batchDetails?.batchName}
        {...props}
      />
    </div>
  );
};

export default TimetableTestListV2;
