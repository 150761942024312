import React, { useState, useEffect } from 'react';
import {
  ReceiptNumberSettingHeader,
  RECEIPTNUMBERSETTING,
} from '../../../common/commonComponents/masterRow/masterRow';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Table,
  Input,
  Label,
} from 'reactstrap';
import Select2 from 'react-select2-wrapper';
import { GetPagination } from '../../../common/pagination';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import CustomDatePicker from '../../../common/commonComponents/CustomDatePicker/index';

import {
  getAPI,
  masterServiceBaseUrl,
  successToast,
  failureToast,
  putpost,
  putpost2,
  deleteAPI,
} from 'services/http';

var _ = require('lodash');

const TaxableReceiptNumberSetting = (props) => {
  const {
    getDisplayValue,
    action,
    businessUnit,
    businessKeyValue,
    receiptLabel,
    receiptNumberSetting,
    setReceiptNumberSetting,
    isDisabled
  } = props;

  const [isSaveVisible, setIsSaveVisible] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [state, setState] = useState([]);
  const [dataChange, setDataChange] = useState(false);
  const [tableDataKey, settableDataKey] = useState('tableDataKey');

  const [pagination, setPagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const pageSize = 10;

  // console.log('businessUnit__Plan', businessUnit);

  useEffect(() => {
    try {
      if ( action === 'edit' || action === 'view' ) {
        performPaginationForData();
      }
    } catch ( e ) {
      console.log('Error :: ',e)
    }
  }, [] );

  const performPaginationForData = () => {
    try {
      setApiLoader(true);
        // console.log(receiptNumberSetting);
        setState(receiptNumberSetting);
        let n = pagination;
        if (receiptNumberSetting.length > pageSize) {
          n['nextPage'] = n.currentPage + 1;
        }
        if (n.currentPage > 1) {
          n.previousPage = n.currentPage - 1;
        }
        n['totalPage'] = Math.floor(receiptNumberSetting.length / pageSize);
        if (receiptNumberSetting.length % pageSize != 0) {
          n['totalPage'] += 1;
        }
        setPagination(n);
        setApiLoader(false);
        // console.log(n);
        setReceiptNumberSetting(
          receiptNumberSetting.slice(
            pagination.currentPage * pageSize - pageSize,
            pagination.currentPage * pageSize
          ) || []
        );
    } catch ( e ) {
      console.log('Error ::',e)
    }
  }

  const fetchClasses = async (url) => {
    setApiLoader(true);
    getAPI(
      url,
      (data) => {
        // console.log(data['data']);
        setState(data['data']);
        let n = pagination;
        if (data['data'].length > pageSize) {
          n['nextPage'] = n.currentPage + 1;
        }
        if (n.currentPage > 1) {
          n.previousPage = n.currentPage - 1;
        }
        n['totalPage'] = Math.floor(data['data'].length / pageSize);
        if (data['data'].length % pageSize != 0) {
          n['totalPage'] += 1;
        }
        setPagination(n);
        setApiLoader(false);
        // console.log(n);
        setReceiptNumberSetting(
          data['data'].slice(
            pagination.currentPage * pageSize - pageSize,
            pagination.currentPage * pageSize
          ) || []
        );
      },
      (data) => {
        failureToast(data['message']);
        setApiLoader(false);
      }
    );
  };

  const nextPage = () => {
    setReceiptNumberSetting(
      state.slice(
        (pagination.currentPage + 1) * pageSize - pageSize,
        (pagination.currentPage + 1) * pageSize
      )
    );
    // console.log(pagination);
    let n = pagination;
    n['currentPage'] = n['currentPage'] + 1;
    n['previousPage'] = n.currentPage;
    setPagination(n);
  };
  const firstPage = () => {
    setReceiptNumberSetting(state.slice(1 * pageSize - pageSize, 1 * pageSize));
    // console.log(pagination);
    let n = pagination;
    n['currentPage'] = 1;
    n['previousPage'] = null;
    setPagination(n);
  };
  const lastPage = () => {
    setReceiptNumberSetting(
      state.slice(
        pagination.totalPage * pageSize - pageSize,
        pagination.totalPage * pageSize
      )
    );
    // console.log(pagination);
    let n = pagination;
    n['currentPage'] = n.totalPage;
    n['previousPage'] = n.totalPage - 1;
    setPagination(n);
  };
  const previousPage = () => {
    if (pagination.currentPage != 1) {
      setReceiptNumberSetting(
        state.slice(
          (pagination.currentPage - 1) * pageSize - pageSize,
          (pagination.currentPage - 1) * pageSize
        )
      );
      // console.log(pagination);
      let n = pagination;
      n['currentPage'] = n['currentPage'] - 1;
      if (n['currentPage'] == 1) {
        n['previousPage'] = null;
      } else {
        n['previousPage'] = n.currentPage;
      }
      setPagination(n);
    }
  };

  useEffect(() => {
    if(props.isPrevious && props.formId){
      setApiLoader(true)
      fetchClasses(`${masterServiceBaseUrl}/lastNumberGenerated/taxableCourseReceiptNumberSetting/businessArea/${props.formId}`)
   }
  }, [props.isPrevious,props.formId])

  useEffect(()=>{
    if(state.length){
      !props.isPrevious && props.isNew  && props.saveStepsCompleted()
    }   
  },[state])

  const addNewClass = () => {
    setReceiptNumberSetting([
      {
        editMode: true,
        __status: 'new',
        businessAreaId: businessUnit,
        code: businessKeyValue,
        year: null,
        label: null,
        receiptNumber: null,
        transactionNumber: null,
        lastReceiptNumber: null,
        effectiveDate: null,
        status: 'ACTIVE',
      },
      ...receiptNumberSetting,
    ]);
  };

  // console.log('receiptNumberSetting__ClassArray', receiptNumberSetting);
  const saveAll = (cb) => {
    try {
      let pattern_1 = /^[0-9\b]*$/;
      var _tempArray = receiptNumberSetting;
      var isValid = true;
      _tempArray.forEach((el, index) => {
        if (el['editMode']) {
          if (el['businessAreaId'] === null) {
            isValid = false;
            failureToast('Business Area ID not found');
            return;
          } else if (el['code'] === null || el['code'] == '') {
            isValid = false;
            failureToast('Business Key Code not found');
            return;
          } else if (el['year'] === null || el['year'] == '') {
            isValid = false;
            failureToast('Please enter the Year');
            return;
          } else if (!pattern_1.test(el['year'])) {
            isValid = false;
            failureToast('Year Should be in Numeric value');
            return;
          } else if (el['year'].length < 4) {
            isValid = false;
            failureToast('Please enter a valid Year');
            return;
          } else if (el['label'] === null) {
            isValid = false;
            failureToast('Please Select the Label');
            return;
          } else if (
            el['receiptNumber'] === null ||
            el['receiptNumber'] == ''
          ) {
            isValid = false;
            failureToast('Please enter the value of Receipt Number');
            return;
          } else if (!pattern_1.test(el['receiptNumber'])) {
            isValid = false;
            failureToast('Receipt Number Should be in Numeric value');
            return;
          } else if (
            el['transactionNumber'] === null ||
            el['transactionNumber'] == ''
          ) {
            isValid = false;
            failureToast('Please enter the value of Transaction Number');
            return;
          } else if (!pattern_1.test(el['transactionNumber'])) {
            isValid = false;
            failureToast('Transaction Number Should be in Numeric value');
            return;
          } else if (
            el['lastReceiptNumber'] === null ||
            !el['lastReceiptNumber'].trim()
          ) {
            isValid = false;
            failureToast('Please enter the value of Last Receipt Number');
            return;
          }

          if (!isValid) {
            return;
          }
          if (_tempArray[index]['__status'] == 'new') {
            setApiLoader(true);
            putpost(
              masterServiceBaseUrl +
                '/lastNumberGenerated/taxableCourseReceiptNumberSetting',
              (data) => {
                successToast(data['message']);
                settableDataKey(new Date().getMilliseconds());
                fetchClasses(
                  masterServiceBaseUrl +
                    `/lastNumberGenerated/taxableCourseReceiptNumberSetting/businessArea/${businessUnit}`
                );
                setIsSaveVisible(false);
                setApiLoader(false);
              },
              (data) => {
                failureToast(data['message']);
                setApiLoader(false);
              },
              _tempArray[index],
              'post'
            );

            // console.log(
            //   'receiptNumberSetting[ index ]',
            //   receiptNumberSetting[index]
            // );
          } else {
            setApiLoader(true);
            putpost(
              masterServiceBaseUrl +
                '/lastNumberGenerated/taxableCourseReceiptNumberSetting/' +
                _tempArray[index].id,
              (data) => {
                successToast(data['message']);
                fetchClasses(
                  masterServiceBaseUrl +
                    `/lastNumberGenerated/taxableCourseReceiptNumberSetting/businessArea/${businessUnit}`
                );
                setIsSaveVisible(false);
                delete _tempArray[index]['editMode'];
                setReceiptNumberSetting(_tempArray);
                settableDataKey(new Date().getMilliseconds());
                setApiLoader(false);
              },
              (data) => {
                failureToast(data['message']);
                setApiLoader(false);
              },
              _tempArray[index],
              'put'
            );
          }
        }
      });
    } catch (e) {
      console.log('Error', e);
    }
  };

  const newRow = () => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        addNewClass();
        setIsSaveVisible(true);
      };
      var _tempArray = receiptNumberSetting;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      // console.log(_tempArray);
      setReceiptNumberSetting(_tempArray);
      setIsSaveVisible(false);
      cb();
    }
  };

  const updateRecord = (index, value, property) => {
    try {
      let _tempArray = receiptNumberSetting;
      _tempArray[index][property] = value;
      setReceiptNumberSetting(_tempArray);
    } catch (e) {
      console.log('Error', e);
    }
  };

  const editRecord = (index) => {
    try {
      if (isSaveVisible) {
        failureToast('Please Delete or Save current changes');
      } else {
        let cb = () => {
          var _tempArray = receiptNumberSetting;
          _tempArray[index]['editMode'] = true;
          setReceiptNumberSetting(_tempArray);
          setIsSaveVisible(true);
        };
        var _tempArray = receiptNumberSetting;
        _tempArray = _.sortBy(_tempArray, [
          function (o) {
            return o.value;
          },
        ]);
        cb();
      }
    } catch (e) {
      console.log('Error : ', e);
    }
  };

  const deleteRecord = (index) => {
    try {
      if (receiptNumberSetting[index]['__status'] == 'new') {
        let n = receiptNumberSetting;
        n.splice(index, 1);
        setReceiptNumberSetting(n);
        setIsSaveVisible(false);
        settableDataKey(new Date().getMilliseconds());
      } else {
        if (
          window.confirm(
            'Are you sure you want to delete this Business Area & Plan ?'
          )
        ) {
          putpost2(
            masterServiceBaseUrl +
              '/lastNumberGenerated/taxableCourseReceiptNumberSetting/' +
              receiptNumberSetting[index].id,
            (data) => {
              successToast(data['message']);
              if (receiptNumberSetting.length == 1) {
                previousPage();
                fetchClasses(
                  masterServiceBaseUrl +
                    `/lastNumberGenerated/taxableCourseReceiptNumberSetting/businessArea/${businessUnit}`
                );
              } else {
                var _tempArray = receiptNumberSetting;
                if (_tempArray[index]['editMode']) {
                  setIsSaveVisible(false);
                }
                _tempArray.splice(index, 1);
                setReceiptNumberSetting(_tempArray);
              }
              settableDataKey(new Date().getMilliseconds());
            },
            (data) => {
              failureToast(data['message']);
            },
            'Delete'
          );
        }
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  const closeRecord = (index) => {
    try {
      let n = receiptNumberSetting;
      if (n[index]['__status'] == 'new') {
        n.splice(index, 1);
      } else {
        // n[index]['editMode']=false
        fetchClasses(
          masterServiceBaseUrl +
            `/lastNumberGenerated/taxableCourseReceiptNumberSetting/businessArea/${businessUnit}`
        );
      }
      setReceiptNumberSetting(n);
      setIsSaveVisible(false);
    } catch (e) {
      console.log('Error', e);
    }
  };

  return (
    <Container className="mt-3" fluid>
      <Row>
        <Col className={`mb-3 ${action === 'new' ? '' :'mt-3'}`}>
          {!isDisabled ? (
            <Button
              disabled={isSaveVisible}
              color="info"
              size="sm"
              type="button"
              className={
                'floatRight mx-1' + (isSaveVisible ? ' btn-dark' : null)
              }
              onClick={() => {
                newRow();
              }}
            >
              Add Sequence
            </Button>
          ) : null}
        </Col>
      </Row>
      {apiLoader ? (
        <div className="mx-auto text-center" style={{ height: '100px' }}>
          <Loader
            type="Rings"
            color="#00BFFF"
            height={70}
            width={70}
            // timeout={10000}
          />
        </div>
      ) : (
        <div id="questionTable" className="table-responsive">
          <Table className="align-items-center table-flush">
            {receiptNumberSetting.length > 0 ? (
                <ReceiptNumberSettingHeader action={ action } isDisabled={ isDisabled }/>
            ) : (
              ''
            )}
            <tbody className="list" key={tableDataKey}>
              {receiptNumberSetting
                ? receiptNumberSetting.map((item, index) =>
                    !item['editMode'] ? (
                      <RECEIPTNUMBERSETTING
                        key={index + '_class'}
                        action={action}
                        deleteRecord={deleteRecord}
                        getDisplayValue={getDisplayValue}
                        item={item}
                        editRecord={editRecord}
                        index={index}
                      closeRecord={ closeRecord }
                      isDisabled={isDisabled}
                      />
                    ) : (
                      <tr key={index + '-class'}>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={5}
                            disabled
                            defaultValue={item['code']}
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={4}
                            defaultValue={item['year']}
                            onChange={(e) => {
                              let n = e.target.value.trim();
                              updateRecord(index, n, 'year');
                            }}
                            // placeholder="year"
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Select2
                            data={receiptLabel}
                            className="tdSelect"
                            options={{ placeholder: 'Select...' }}
                            value={item.label}
                            onChange={(e) => {
                              let n = e.target.value;
                              updateRecord(index, n, 'label');
                            }}
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={10}
                            defaultValue={item['receiptNumber']}
                            onChange={(e) => {
                              let n = e.target.value.trim();
                              updateRecord(index, n, 'receiptNumber');
                            }}
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={10}
                            defaultValue={item['transactionNumber']}
                            onChange={(e) => {
                              let n = e.target.value.trim();
                              updateRecord(index, n, 'transactionNumber');
                            }}
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={10}
                            defaultValue={item['lastReceiptNumber']}
                            onChange={(e) => {
                              updateRecord(
                                index,
                                e.target.value,
                                'lastReceiptNumber'
                              );
                            }}
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <CustomDatePicker 
                              disabled={true}
                              value={
                              item['__status'] == 'new'
                                ? null
                                : moment(item['effectiveDate']).toDate()
                              }
                              placeholder="Example:01-01-2020"
                          />
                          {/* <Input
                            disabled={true}
                            defaultValue={
                              item['__status'] == 'new'
                                ? null
                                : moment(item['effectiveDate']).format(
                                    'DD-MM-YYYY'
                                  )
                            }
                            placeholder="Example:01-01-2020"
                            className="tdInput"
                          /> */}
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Label className="custom-toggle mx-auto ml-2">
                            <Input
                              checked={item.status == 'ACTIVE' ? true : false}
                              type="checkbox"
                              onChange={(e) => {
                                settableDataKey(new Date().getMilliseconds());
                                let n = 'ACTIVE';
                                if (!e.target.checked) {
                                  n = 'INACTIVE';
                                }
                                updateRecord(index, n, 'status');
                                setDataChange(!dataChange);
                              }}
                            />
                            <span
                              style={{ width: '72px' }}
                              className="custom-toggle-slider rounded-circle activeToggle"
                              data-label-off="Inactive"
                              data-label-on="Active"
                            />
                          </Label>
                        </td>
                        <td>
                          {isSaveVisible ? (
                            <>
                              <Button
                                size="sm"
                                color="info"
                                onClick={() => saveAll()}
                              >
                                Save
                              </Button>
                              <Button
                                size="sm"
                                color="danger"
                                onClick={() => closeRecord(index)}
                              >
                                <i className="fas fa-times" />
                              </Button>
                            </>
                          ) : (
                            <Button
                              color="info"
                              size="sm"
                              type="button"
                              onClick={() => {
                                deleteRecord(index);
                              }}
                            >
                              <i className="fas fa-trash" />
                            </Button>
                          )}
                        </td>
                      </tr>
                    )
                  )
                : null}
            </tbody>
          </Table>

          {pagination.totalPage > 1 ? (
            <GetPagination
              fetchClasses={fetchClasses}
              pagination={pagination}
              previousPage={previousPage}
              firstPage={firstPage}
              lastPage={lastPage}
              nextPage={nextPage}
              setpagination={setPagination}
            />
          ) : null}
        </div>
      )}
    </Container>
  );
};

export default TaxableReceiptNumberSetting;
