import React from "react";
import { ReactComponent as Profile } from "assets/img/svg/Profile.svg";
import { CardBody, Card, CardHeader } from "reactstrap";
import { CourseDetailsPopupById } from "views/pages/manage/unAssigned/studentCharges/common";
import './style.scss';
const GateAttendanceStudentList = ({
    studentInfo,
    index
}) => {
  return (
    <Card style={{ minHeight: "20px" }} className={index==49?"mb-0":"mb-1"}>
        {index==0&&<CardHeader style={{padding:'12px 20px'}}><div style={{color: '#333333', fontSize: 12, fontFamily: 'Gilroy-Medium', fontWeight: '400'}}>Select an Appliaction ID</div></CardHeader>}
      <CardBody className="custom-padding">
        <div className="d-flex align-items-center">
          <diV>
            <Profile />
          </diV>

          <div
            className="d-flex ml-3 align-items-start"
            style={{ flexDirection: "column" }}
          >
            <div
              style={{
                width: 191,
                color: "#333333",
                fontSize: 14,
                fontFamily: "Gilroy-Bold",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              Application ID: {studentInfo?.applicationId}
            </div>
            <div
              style={{
                alignSelf: "stretch",
                color: "#757575",
                fontSize: 14,
                fontFamily: "Gilroy-Medium",
                fontWeight: "400",
              }}
            >
              {studentInfo?.courseId} <CourseDetailsPopupById courseId={studentInfo?.courseId} placement='top' maxWidth='250px'/>
            </div>
            <div
              style={{
                color: "#333333",
                fontSize: 12,
                fontFamily: "Gilroy-Medium",
                fontWeight: "400",
            
              }}
             className = "text-bold"
            >
              {studentInfo?.studentName}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
export default GateAttendanceStudentList;
