import React, { useState } from "react";
import { Row, Col, FormGroup, Input, Label } from "reactstrap";
import Select from "react-select";
const BankInformation = (props) => {
  const {
    activeCurrency,
    activeCountry,
    submitDataHandler,
    houseBankDetails,
    getDisplayValue,
    actionDisabled,
  } = props;
  const [status, setStatus] = useState([
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "INACTIVE", label: "INACTIVE" },
  ]);
  const validate = (type, value) => {
    const numberValidation = /^([0-9])*$/;
    const accountHolder = /^[a-zA-Z.\s]*$/;
    const accountNumber = /^[a-zA-Z0-9\s]*$/;
    switch (type) {
      case "number":
        if (value === "" || numberValidation.test(value)) {
          return true;
        }
        break;
      case "accountHolderName":
        if (value === "" || accountHolder.test(value)) {
          return true;
        }
        break;
      case "accountNumber":
        if (value === "" || accountNumber.test(value)) {
          return true;
        }
        break;
      default:
        break;
    }
  };

  return (
    <Row>
      <Col>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="form-control-label">Reference ID</Label>
              <Input
                data-testid="referenceId"
                className="customInput"
                maxLength={50}
                disabled={actionDisabled}
                placeholder="Enter Reference ID"
                value={houseBankDetails["referenceId"]}
                onChange={(e) =>
                  submitDataHandler(e.target.value, "referenceId")
                }
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="form-control-label">Status</Label>
              <Select
                isDisabled={actionDisabled}
                value={{
                  value: houseBankDetails["status"],
                  label: houseBankDetails["status"] ? (
                    houseBankDetails["status"]
                  ) : (
                    <span className="customSpan">Select Status</span>
                  ),
                }}
                options={status}
                onChange={(e) => submitDataHandler(e.value, "status")}
              ></Select>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="form-control-label">Bank ID</Label>
              <Select
                isDisabled={actionDisabled}
                value={{
                  value: houseBankDetails["bankId"],
                  label: houseBankDetails["bankId"] ? (
                    houseBankDetails["bankId"]
                  ) : (
                    <span className="customSpan">Select Bank ID</span>
                  ),
                }}
                onChange={(e) =>  submitDataHandler(e.value, "bankId")}
              ></Select>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="form-control-label">Bank IFSC Code</Label>
              <Input
                data-testid="bankIfscCode"
                placeholder="Bank IFSC Code"
                maxLength={50}
                disabled={true}
                className="customInput"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="form-control-label">Bank Account Number</Label>
              <Input
                data-testid="bankAccountNumber"
                placeholder="Bank Account Number"
                maxLength={50}
                disabled={actionDisabled}
                className="customInput"
                value={houseBankDetails["bankAccountNumber"]}
                onChange={(e) => {
                  let n = e.target.value;
                  if (validate("accountNumber", n)) {
                    submitDataHandler(n, "bankAccountNumber");
                  }
                }}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="form-control-label">Account Holder Name</Label>
              <Input
                data-testid="accountHolderName"
                placeholder="Account Holder Name"
                maxLength={50}
                disabled={actionDisabled}
                className="customInput"
                value={houseBankDetails["accountHolderName"]}
                onChange={(e) => {
                  let n = e.target.value;
                  if (validate("accountHolderName", n)) {
                    submitDataHandler(n, "accountHolderName");
                  }
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="form-control-label">Account (Dr.)</Label>
              <Input
                data-testid="accountDr"
                className="customInput"
                maxLength={15}
                disabled={actionDisabled}
                placeholder="Enter Account (Dr.)"
                value={houseBankDetails["accountDr"]}
                onChange={(e) => {
                  let n = e.target.value;
                  if (validate("number", n)) {
                    submitDataHandler(n, "accountDr");
                  }
                }}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="form-control-label">Account (Cr.)</Label>
              <Input
                data-testid="accountCr"
                className="customInput"
                maxLength={15}
                disabled={actionDisabled}
                placeholder="Enter Account (Cr.)"
                value={houseBankDetails["accountCr"]}
                onChange={(e) => {
                  let n = e.target.value;
                  if (validate("number", n)) {
                    submitDataHandler(e.target.value, "accountCr");
                  }
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="form-control-label">Country</Label>
              <Select
                isDisabled={actionDisabled}
                value={{
                  value: houseBankDetails["country"],
                  label: houseBankDetails["country"] ? (
                    getDisplayValue(houseBankDetails["country"], "CountryID")
                  ) : (
                    <span className="customSpan">Select Country</span>
                  ),
                }}
                options={activeCountry}
                onChange={(e) => submitDataHandler(e.value, "country")}
              ></Select>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="form-control-label">Currency</Label>
              <Select
                isDisabled={actionDisabled}
                value={{
                  value: houseBankDetails["currency"],
                  label: houseBankDetails["currency"] ? (
                    getDisplayValue(houseBankDetails["currency"], "CurrencyID")
                  ) : (
                    <span className="customSpan">Select Currency</span>
                  ),
                }}
                options={activeCurrency}
                onChange={(e) => submitDataHandler(e.value, "currency")}
              ></Select>
            </FormGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BankInformation;
