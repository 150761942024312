import { endpoint } from "../../common/constant";
import { fetchAllPromisedData } from "../../common/utils/methods/commonMethods/utilityMethod";
import { dropdownMapping } from "./common";

export const getInitialFilterForm = () => {
  return {
    testName: '',
    testType: '',
    isMandatory: null,
    format: null,
    mode: null,
    active: null,
    startDateBegin: '',
    endDateEnd: '',
  }
}

const formInfo = {
  isMandatory: 'String',
  testType: 'String',
  format: 'String',
  mode: 'String',
  active: 'String',
  startDateBegin: 'String',
  endDateEnd: 'String',
}

export const getFilterCount = (dataSet) => {
  let cnt = 0;
  for (let item in dataSet) {
    if (formInfo[item] === 'String') {
      if (dataSet[item]?.toString()) cnt++;
    }
    if (formInfo[item] === 'Array') {
      if (dataSet[item].length) cnt++;
    }
  }
  return cnt;
}

export const statusDropdown = [
  {label: 'Active', value: true},
  {label: 'Inactive', value: false},
]

const getDropdownArray = (dataArray, labelKey, valueKey) => {
  if(Array.isArray(dataArray)){
    return dataArray?.map(item => {
      return {label: item[labelKey], value: item[valueKey]}
    })
  } else {
    return [];
  }
}

export const fetchDropdowns = async () => {
  const p = await Promise.all([
    await fetchAllPromisedData(endpoint?.academicPlanTests?.getTestTypes, true)
  ])
  return {
    testTypeDropdown: getDropdownArray(p[0]?.data, 'testType', 'testType'),
  }
}