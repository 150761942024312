import React from "react";
import { Input } from "reactstrap";
import Styles from "./customToggle.module.scss";

const CustomToggle = (props) => {
  const { status, onChangeHandler = () => { }, toggleOn, toggleOff } = props;
  // const temp = { ...props };
  // delete temp.toggleOff
  // delete temp.toggleOn

  // console.log("temp>>", temp);



  return (
    <label className="custom-toggle mx-auto ml-2">
      <Input
        {...props}
        checked={status === "ACTIVE" ? true : false}
        type="checkbox"
        onChange={onChangeHandler}
        className={Styles.custom__toggle__input}
      />
      <span
        data-testid="activeInactiveLabel"
        style={{ width: "73px" }}
        className="custom-toggle-slider rounded-circle activeToggle"
        data-label-off={toggleOff}
        data-label-on={toggleOn}
      />
    </label>
  );
};

export default CustomToggle;