import React from "react";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Label, Row } from "reactstrap";
import { failureToast, enrollmentServiceBaseUrl } from "services/http";
import { ReactSelectDropdown } from "../../../common/formFeilds";
import { getRequiredSign, findObjectById } from "../../../common/utils/methods/commonMethods/utilityMethod";
import Select1 from "react-select";
import Loader from "react-loader-spinner";

const SearchComponent = (props) => {
  const {
    fetchDetails,
    dropdownYear,
    dropdownExamBoard,
    searchValues,
    setSearchValues,
    studentResult,
    allRecords,
    searchResult,
    setStudentResult,
    setSearchResult,
    setState,
    searchButtonClicked,
    setSearchButtonClicked,
    setpagination,
    searchApiHandler,
    setIsSaveVisible,
  } = props;

  const searchDataHandler = () => {
    try {
      if (!searchValues?.examBoard) return failureToast("Please Select Exam Board");
      setSearchButtonClicked(true);
      setIsSaveVisible(false);
      searchApiHandler();
    } catch (e) {
      console.log("Error:", e);
    }
  };

  const searchHandler = (index, value, key) => {
    try {
      setSearchValues((prev) => ({
        ...prev,
        [key]: value,
      }));
    } catch (e) {
      console.log("Error:", e);
    }
  };

  const selectHandler = (studentID) => {
    try {
      findObjectById(allRecords?.studentDetails, "studentId", studentID);
    } catch (e) {
      console.log("Error", e);
    }
  };

  const updateKey = (index, value, key) => {
    const _tempArray = searchResult;
    _tempArray[index][key] = value;
    setSearchResult(_tempArray);
  };

  const resetSearch = () => {
    try {
      setSearchValues({});
      setStudentResult([]);
      setState([]);
      setSearchResult([]);
      setpagination({
        currentPage: 1,
        nextPage: null,
        previousPage: null,
        totalPage: null,
      });
    } catch (e) {
      console.log("Error", e);
    }
  };

  return (
    <>
      <Card>
        <CardHeader>
          <div className={"d-flex justify-content-between"}>
            <h2>External Test Result</h2>
          </div>
        </CardHeader>
        <CardBody className={"p-0"}>
          <Row className={"p-4"}>
            <Col md={3}>
              <FormGroup>
                <Label className="form-control-label">Student ID</Label>
                <Select1
                  isDisabled={searchButtonClicked}
                  id="studentDetail"
                  value={searchValues?.studentId ? searchValues?.studentId : ""}
                  options={studentResult}
                  onInputChange={(e) => {
                    e && e.length > 2 && fetchDetails(`${enrollmentServiceBaseUrl}/studentDetails/getAllStudentBySmartSearch/${e}`, "STUDENT_RESULT");
                  }}
                  onChange={(e) => {
                    selectHandler(e.value);
                    searchHandler("", { value: e.value, label: e.value }, "studentId");
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label className="form-control-label">Exam Board {getRequiredSign()}</Label>
                <ReactSelectDropdown
                  disabled={searchButtonClicked}
                  options={dropdownExamBoard}
                  id={searchValues?.examBoard}
                  dropdownHandler={searchHandler}
                  keyName={"examBoard"}
                  // index={index}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label className="form-control-label">Exam Year</Label>
                <ReactSelectDropdown
                  disabled={searchButtonClicked}
                  options={dropdownYear}
                  id={searchValues?.examYear}
                  keyName={"examYear"}
                  dropdownHandler={searchHandler}
                  // index={index}
                />
              </FormGroup>
            </Col>
            <Col md={3} className={"d-flex align-items-center"}>
              <Button color={"info"} onClick={searchDataHandler} style={{ width: "100px", marginTop: "4px" }}>
                {searchButtonClicked ? <Loader type="TailSpin" color="white" height="20" width="50" /> : <span>Search</span>}
              </Button>
              <Button color={"secondary"} onClick={resetSearch} style={{ marginTop: "4px" }}>
                Reset
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default SearchComponent;
