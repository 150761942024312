import React from "react";
import ADataGrid from "views/pages/manage/common/data-grid/ADataGrid";
import { useHistory, useParams } from "react-router";
import {
  getReversalTableHeader,
  getStockTxnTableHeader,
  getTransactionHistory,
} from "../selectMeterialAndStock/issueStock/TableColumn";
import { gridComponents, gridStyles } from "../selectMeterialAndStock/constant";
import { getStockReturnTableHeader } from "./tableColumn";

const StockTransactionTable = ({
  rowData = [],
  onSelectClick,
  rowCount = 0,
  currentPage,
  nextButtonHandler,
  isConsumptionReturn,
  selectedTab,
  isConsumptionHistory,
  academicType,
  forReversal,
}) => {
  const history = useHistory();

  return (
    <>
      <div className="mt-3">
        <div
          style={{
            border: "1px solid rgba(224, 224, 224, 1)",
            borderRadius: "12px",
          }}
        >
          <ADataGrid
            rows={rowData || []}
            columns={
              forReversal
                ? getReversalTableHeader(history, selectedTab, academicType)
                : getStockTxnTableHeader({
                    onSelectClick,
                    history,
                    isConsumptionReturn,
                    isConsumptionHistory,
                    selectedTab,
                    academicType,
                    forReversal,
                  })
            }
            rowId={(row) => JSON?.stringify(row)}
            rowCount={rowCount}
            currentPage={currentPage}
            rowHeight={50}
            height={100}
            loading={false}
            hideFooterSelectedRowCount={true}
            components={gridComponents}
            sx={gridStyles}
            nextButtonHandler={nextButtonHandler}
          />
        </div>
      </div>
    </>
  );
};

export default StockTransactionTable;

export const TransactionHistoryTable = ({
  rowData = [],
  onSelectClick,
  rowCount = 0,
  currentPage,
  nextButtonHandler,
}) => {
  const { academicCareer } = useParams() || {};

  return (
    <>
      <div className="mt-3">
        <div
          style={{
            border: "1px solid rgba(224, 224, 224, 1)",
            borderRadius: "12px",
          }}
        >
          <ADataGrid
            rows={rowData || []}
            columns={getTransactionHistory(academicCareer)}
            rowId={(row) => JSON?.stringify(row)}
            // rowCount={rowCount}
            // currentPage={currentPage}
            serverPagination={false}
            rowHeight={50}
            height={100}
            loading={false}
            hideFooterSelectedRowCount={true}
            components={gridComponents}
            sx={gridStyles}
            nextButtonHandler={nextButtonHandler}
            autoHeight
          />
        </div>
      </div>
    </>
  );
};

export const StockReturnTable = ({
  rowData,
  rowCount,
  currentPage,
  nextButtonHandler,
  isOtherDetail = false,
  academicType,
}) => {
  return (
    <>
      <div className="mt-3">
        <div
          style={{
            border: "1px solid rgba(224, 224, 224, 1)",
            borderRadius: "12px",
          }}
        >
          <ADataGrid
            rows={rowData || []}
            columns={getStockReturnTableHeader(isOtherDetail, academicType)}
            rowId={(row) => JSON?.stringify(row)}
            rowCount={rowCount}
            currentPage={currentPage}
            rowHeight={50}
            height={100}
            loading={false}
            hideFooterSelectedRowCount={true}
            components={gridComponents}
            sx={gridStyles}
            nextButtonHandler={nextButtonHandler}
          />
        </div>
      </div>
    </>
  );
};
