export const ALL_ACTIVE_CONFIG_REQUEST = 'ALL_ACTIVE_CONFIG_REQUEST';
export const ALL_ACTIVE_CONFIG_SUCCESS = 'ALL_ACTIVE_CONFIG_SUCCESS';
export const ALL_ACTIVE_CONFIG_FAILURE = 'ALL_ACTIVE_CONFIG_FAILURE';

export const APPROVAL_REQ_REQUEST = 'APPROVAL_REQ_REQUEST';
export const APPROVAL_REQ_SUCCESS = 'APPROVAL_REQ_SUCCESS';
export const APPROVAL_REQ_FAILURE = 'APPROVAL_REQ_FAILURE';

export const SET_APPROVER_TYPE = 'SET_APPROVER_TYPE';
export const SET_GROUPED_REQUESTS = 'SET_GROUPED_REQUESTS';

export const ALL_BUSINESS_AREAS_REQUEST = 'ALL_BUSINESS_AREAS_REQUEST';
export const ALL_BUSINESS_AREAS_SUCCESS = 'ALL_BUSINESS_AREAS_SUCCESS';
export const ALL_BUSINESS_AREAS_FAILURE = 'ALL_BUSINESS_AREAS_FAILURE';

export const ALL_REGIONS_REQUEST = 'ALL_REGIONS_REQUEST';
export const ALL_REGIONS_SUCCESS = 'ALL_REGIONS_SUCCESS';
export const ALL_REGIONS_FAILURE = 'ALL_REGIONS_FAILURE';

export const ALL_COURSE_CATEGORIES_REQUEST = 'ALL_COURSE_CATEGORIES_REQUEST';
export const ALL_COURSE_CATEGORIES_SUCCESS = 'ALL_COURSE_CATEGORIES_SUCCESS';
export const ALL_COURSE_CATEGORIES_FAILURE = 'ALL_COURSE_CATEGORIES_FAILURE';

export const ALL_COURSE_IDS_REQUEST = 'ALL_COURSE_IDS_REQUEST';
export const ALL_COURSE_IDS_SUCCESS = 'ALL_COURSE_IDS_SUCCESS';
export const ALL_COURSE_IDS_FAILURE = 'ALL_COURSE_IDS_FAILURE';

export const ROLE_ID_REQUEST = 'ROLE_ID_REQUEST';
export const ROLE_ID_SUCCESS = 'ROLE_ID_SUCCESS';
export const ROLE_ID_FAILURE = 'ROLE_ID_FAILURE';

export const SET_SELECTED_ROW_IDS = 'SET_SELECTED_ROW_IDS'; 
export const CLEAR_SELECTED_ROW_IDS = 'CLEAR_SELECTED_ROW_IDS';

export const CLEAR_APPROVAL_DATA = 'CLEAR_APPROVAL_DATA';

export const SET_VERIFICATION_ROWS = 'SET_VERIFICATION_ROWS';
export const DELETE_VERIFICATION_ROW = 'DELETE_VERIFICATION_ROW';

export const OTHER_CHARGES_APPROVAL_REQ_REQUEST = 'OTHER_CHARGES_APPROVAL_REQ_REQUEST';
export const OTHER_CHARGES_APPROVAL_REQ_SUCCESS = 'OTHER_CHARGES_APPROVAL_REQ_SUCCESS';
export const OTHER_CHARGES_APPROVAL_REQ_FAILURE = 'OTHER_CHARGES_APPROVAL_REQ_FAILURE';