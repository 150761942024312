import React, { useContext } from 'react';
import { ReactComponent as PrinterIcon } from './../../../../../../assets/img/svg/fi_printer.svg';
import { lastDays } from '../../../academicPlanAndTest/academicTimetable/helper';
import { FILTER__FORM_FIELDS, FILTER__FORM_FIELDS_GLOBAL, IssuanceIDCardContext, PAGE_STATE } from '../contextApi';


const StudentListTableHeader = () => {

  const { setCurrentPageState, setPage, getApplications, setPrintedIdCardArray, setFilterForm, setGlobalFilter } = useContext(IssuanceIDCardContext);

  return (
    <div className='d-flex justify-content-between'>
      <div className='d-flex flex-column'>
        <span className='heading-3'>ID cards pending for new admissions since {lastDays().format('DD-MMM-YYYY')}</span>
        {/* <span className='regular-small color-grey-60'>List of new students taken student in your branch</span> */}
      </div>
      <div
        onClick={() => {
          // resetting filters
          setFilterForm(FILTER__FORM_FIELDS);
          setGlobalFilter(FILTER__FORM_FIELDS_GLOBAL);
          setPage(0);
          getApplications(0, { ...FILTER__FORM_FIELDS, ...FILTER__FORM_FIELDS_GLOBAL }, () => {
            setCurrentPageState(PAGE_STATE?.PRINT_ID_CARD);
            setPrintedIdCardArray([]);
          });
        }}
        className='cursor'
      >
        <span className='bulk-print-printId'>Bulk print ID cards</span>
        <PrinterIcon />
      </div>
    </div>
  )
}

export default StudentListTableHeader