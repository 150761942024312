import React from 'react'
import styles from './style.module.css'
import { ReactComponent as TimeTableHeaderImage } from '../../../../../assets/img/svg/TimeTableHeaderImage.svg'

const HeaderComponent = () => {

    return (
        <div className={styles.header_div}>

            <div className='d-flex flex-column align-items-start justify-content-between' style={{ flex: 1, height: '100%' }}>
                <div>
                    <h3 style={{ fontSize: '28px', fontWeight: 700, color: 'inherit' }}>Payment management</h3>
                    <span style={{ color: '#CCEFFD' }}>Here you can find all the Academic Plans created under all academic groups - SOE, SOM and SOF..</span>
                </div>
                {/* {userPermissions && userPermissions.includes('C') && (<span className={commonStyles.primary_filled_button} onClick={}>Create Roster</span>)} */}
            </div>
            <TimeTableHeaderImage />
        </div>
    )
}

export default HeaderComponent

