import React, { useEffect, useState } from "react";
import APopover from "../../../../common/a-popover";
import AButton from "../../../../common/form-fields-mui/AButton";
import {
  highlightedStyle,
  normalStyle,
} from "../../mastersAndMapping/onBoardingMaterialIdSetup/SearchFilter";
import SearchFilterForm from "./SearchFilterForm";
import { initialFilterForm } from "./helper";
import { getFilterCount } from "../../mastersAndMapping/onBoardingMaterialIdSetup/constant";

const SearchFilter = ({
  dropdown,
  filterForm,
  applyFilter,
  setFilterForm,
  dispatchScheduleDropdown,
}) => {
  const [apiLoader, setApiLoader] = useState(false);
  const filterCount = getFilterCount({
    ...filterForm,
    applicationId: "",
    psid: "",
  });

  const applySecondaryFilter = (request) => {
    
    const _request = {...request}
     if(request?.status && !Array.isArray(request?.status)) _request['status'] =  [request?.status.value]
    setFilterForm(request);
    applyFilter(_request);
  };

  return (
    <div className="d-flex justify-content-end align-items-center">
      <APopover
        buttonComponent={(onClick, styles) => (
          <AButton
            className="color-dark"
            onClick={onClick}
            updatedStyle={{
              minWidth: "61px",
              ...(filterCount > 0 ? highlightedStyle : styles),
            }}
          >
            Filter {filterCount > 0 && <>( {filterCount} )</>}
          </AButton>
        )}
        menuComponent={(closeForm) => (
          <SearchFilterForm
            closeForm={closeForm}
            filterCount={filterCount}
            dropdown={dropdown}
            applyFilter={applySecondaryFilter}
            filterPayload={filterForm}
            dispatchScheduleDropdown = {dispatchScheduleDropdown}
          />
        )}
        maxHeight={"calc(100vh - 250px)"}
      />
    </div>
  );
};

export default SearchFilter;
