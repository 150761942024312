import { CircularProgress, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { CiFilter } from 'react-icons/ci'
import { MdOutlineSimCardDownload } from 'react-icons/md'
import { useSelector } from 'react-redux'
import APopover from '../../common/a-popover'
import AButton from '../../common/form-fields-mui/AButton'
import AInput from '../../common/formFeilds/customInput/AInput'
import DeallocateStudentFilterForm from './DeallocateStudentFilterForm'
import SelectSearchInputParam from '../studentSerach/SelectSearchInputParam';

const highlightedStyle = {
  border: "1px solid #00B0F5",
  color: "#00B0F5",
};
// const normalStyle = {
//   border: "1px solid #E9ECEF",
// };

const DeallocateStudentSearchFilter = ({
  batchDetails,
  inputHandler,
  applyFilterHandler,
  removeFilter,
  form,
  filterCount,
  downloadCsvHandler,
  applyNameFilterHandler,
  removeNameFilterHandler,
}) => {

  const downloadCsvLoader = useSelector(state => state.deallocateStudents.downloadCsvLoader)
  const rowCount = useSelector(state => state?.deallocateStudents?.studentListRowCount)

  const onKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      applyNameFilterHandler({ [searchInputParam]: userInput });
    }
  }

  const [searchInputParam, setSearchInputParam] = useState('psid');
  const [userInput, setUserInput] = useState('');
  let searchParamTitleMap = { psid: 'PSID', studentName: 'student name', applicationId: 'application ID' }

  return (
    <div className='d-flex align-items-start'>
      <div className='d-flex flex-column'>
        <div>
          <AInput
            placeholder={`Search by ${searchParamTitleMap[searchInputParam]}`}
            // style={{ border: '1px solid rgb(233, 236, 239)', width: '200px' }}
            value={userInput}
            handler={(value) => setUserInput(value)}
            onKeyDown={(e) => onKeyDown(e)}
            crossIcon={true}
            crossHandler={() => {
              setUserInput('');
              removeNameFilterHandler();
            }}
          />
        </div>
        <SelectSearchInputParam searchInputParam={searchInputParam} setSearchInputParam={setSearchInputParam} />
      </div>
      <APopover
        buttonComponent={(onClick, styles) => <AButton onClick={onClick} updatedStyle={filterCount > 0 ? highlightedStyle : styles}><CiFilter />Filter {filterCount > 0 && <>( {filterCount} )</>}</AButton>}
        menuComponent={() =>
          <DeallocateStudentFilterForm
            batchType={batchDetails.batchType}
            inputHandler={inputHandler}
            applyFilterHandler={applyFilterHandler}
            removeFilter={removeFilter}
            form={form}
          />
        }

        width={'323px'}
        maxHeight={'calc(100% - 200px)'}
        highlightedStyle={highlightedStyle}
        // normalStyle={normalStyle}

      />
      {/* <AButton onClick={downloadCsvHandler} disabled={downloadCsvLoader} >
        {downloadCsvLoader ? <CircularProgress color="inherit" size={"12px"} /> : <MdOutlineSimCardDownload />}
        Download CSV
      </AButton> */}
      {rowCount > 2000 ? (
				<Tooltip title='Number of students exceed the download limit of 2000 records. Use filters including business area(s) to narrow down the results to download'>
					<AButton>
						<MdOutlineSimCardDownload />
						Download CSV
					</AButton>
				</Tooltip>
			) : (
				<AButton onClick={downloadCsvHandler} disabled={downloadCsvLoader} >
					{downloadCsvLoader ? <CircularProgress color="inherit" size={"12px"} /> : <MdOutlineSimCardDownload />}
					Download CSV
				</AButton>
			)}
    </div>
  )
}

export default DeallocateStudentSearchFilter