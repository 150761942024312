import React from "react";
import { Col } from "reactstrap";
import CommonInput from "../../../common/formFeilds/input/commonInput";
import { regex } from "../../../common/constant";
// import { MasterHeaderAuto } from "../../../../common/commonComponents/masterHeader/masterHeader";
// import { isEqual } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
// import { constants } from "../../../../common/constant";

export const FormGenerator = ({
    fields,
    fieldsObject,
    values,
    dataObjects,
    isPreviewEnable,
    disabled,
    isEditMode = false,
    readOnly = false,
    setValues = () => {},
    col
}) => {

    return (
        fields.map((field,index) => {
            return <Col className="mb-3" sm={col ?? 6} xs={12} md={col ?? 4} lg={col ?? 3} key={index}>
                <CommonInput 
                    type={(fieldsObject[field]['dependent'] && !values[fieldsObject[field]['dependedOn']]?.length && fieldsObject[field]['type'] === 'multiSelectWithCheckboxes') && !isPreviewEnable ? 'select' : fieldsObject[field]['type']}
                    id={`user-profile-${field}`}
                    placeHolder={dataObjects[fieldsObject[field]?.['data']]?.['fetching'] ? 'loading data ...' :fieldsObject?.[field]?.['placeholder'] ?? ''}
                    key={`manual-fee-setup-${field}`}
                    label={fieldsObject[field]['label'] ?? ""}
                    mandatory={fieldsObject[field]['mandatory']}
                    defaultValue={fieldsObject[field]['dependedValue'] && values[fieldsObject[field]['dependedValue']] ? values[fieldsObject[field]['dependedValue']][field] :values[field]}
                    onChange={(val)=> setValues({ ...values, [field]: fieldsObject[field]['inCaps'] ? val.toString().toUpperCase(): val })}
                    isDisabled={fieldsObject[field]['disabled'] || isPreviewEnable || disabled || (fieldsObject[field]['dependedOn'] && !values[fieldsObject[field]['dependedOn']] ? true : false ) ||  (fieldsObject[field]["disabledInEdit"] && isEditMode ? true : false) || (fieldsObject[field]['dependent'] && !values[fieldsObject[field]['dependedOn']]?.length)}
                    data={dataObjects?.[fieldsObject?.[field]?.['data']]?.['data']}
                    loading={ fieldsObject[field]['type'] === 'select' && dataObjects[fieldsObject[field]['data']]?.['fetching']}
                    maxLength={fieldsObject[field]['max'] ?? 50}
                    onKeyPress={fieldsObject[field]['regex'] ? (e)=>{
                        if (!regex[fieldsObject[field]['regex']].test(String.fromCharCode(e.charCode))) {
                            e.preventDefault();
                            return;
                        }
                    } : ()=>{}}

                    // maxDate={new Date()}
                    // minDate={new Date('04-01-2022')}
                    dataLabelOff={fieldsObject[field]['dataLabelOff']}
                    dataLabelOn={fieldsObject[field]['dataLabelOn']}
                    // style={fieldsObject[field]['inCaps'] ? {textTransform : 'uppercase'} : {}}
                    readOnly={readOnly}
                />
            </Col>
        })
    )
}