import React from 'react'
import APopover from 'views/pages/manage/common/a-popover';
import ViewBranchesCommonComponent from 'views/pages/manage/common/branchesCommonComponent/ViewBranchesCommonComponent';
import { ReactComponent as EyeIcon } from '../../../../../../assets/img/svg/eye_open.svg';


const BusinessAreaCellComponent = ({ params }) => {
  const SHOW_COUNT = 2;

  let dispLabels = params?.value?.map(item => item?.businessAreaDispValue).slice(0,SHOW_COUNT).join(", ");

  const getRegionArray = (arr) => arr?.map(item => ({ dispValue: item?.regionDispValue, id: item?.regionId }))
  const getBusinessAreaArray = (arr) => arr?.map(item => ({ dispValue: item?.businessAreaDispValue, id: item?.businessAreaId }))

  return (
    <div>
      {dispLabels}
      {params?.value?.length > SHOW_COUNT && (<>
        <span className='ml-2'>{`+${params?.value?.length - SHOW_COUNT} more`}</span>
        <APopover
          buttonComponent={(onClick, styles) => <EyeIcon onClick={onClick} className='ml-2' />}
          menuComponent={(closeForm) => <ViewBranchesCommonComponent regions={getRegionArray(params?.row?.regions)} businessAreas={getBusinessAreaArray(params?.row?.businessAreas)} closeForm={closeForm} />}
        />
      </>
      )}
    </div>
  )
}

export default BusinessAreaCellComponent