import { Divider, Modal, Dialog } from '@mui/material';
import CustomButton from '../../../../../../../../components/CustomButton/index';
import React, { useEffect, useState } from 'react';
import closeIcon from 'assets/img/svg/closeSquare.svg'
import deleteIcon from 'assets/img/svg/Delete.svg';
import UploadIcon from 'assets/img/svg/upload.svg';
import DownloadIcon from 'assets/img/svg/downloadIcon.svg';
import infoIcon from 'assets/img/svg/infoIcon.svg';
import { failureToast } from '../../../../../common/utils/methods/toasterFunctions/function';
import { putpost, uploadpost } from 'services/utils';
import { endpoint, regex } from '../../../../../common/constant'
import moment from 'moment';
import fileIcon from 'assets/img/svg/DocumentLight.svg'
import pdfIcon from 'assets/img/svg/pdfIcon.svg'
import { fetchAllPostPromisedData, fetchAllPromisedData, getUniqueKey } from '../../../../../common/utils/methods/commonMethods/utilityMethod';
import infoError from 'assets/img/svg/info.svg'
import check from 'assets/img/svg/checkgreen.svg'
import { ConfirmationDeleteDialog } from 'views/pages/manage/common/commonComponents/';
import { ReactComponent as EyeIcon } from 'assets/img/svg/eye_open.svg';
import { LazyPDFViewer } from 'views/pages/manage/common/commonComponents';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';

const Uploader = ({ isOpen = false, handleClose = () => { }, anchorEl, handleSave = () => { }, documents, isView, userPermissions }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [progressStatus, setProgressStatus] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [uploadingFile, setUploadingFile] = useState(0);
    const [handleUploadedFiles, setHandleUploadedFiles] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSucess] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [downloading, setDownloading] = useState(false);
    const [docForView, setDocForView] = useState(null);
    const [viewing, setViewing] = useState(false);
    const [viewerType, setViewerType] = useState(null);
    const [docViewingIndex, setDocIndex] = useState(null);


    const setSucessMsg = (msg) => {
        setSucess(msg);
        setTimeout(() => {
            setSucess(null)
        }, 4000)
    }

    const setErrorMsg = (msg) => {
        setError(msg);
        setTimeout(() => {
            setError(null)
        }, 4000)
    }



    const setData = (data) => {
        const newObject = {
            "processInstanceId": data.processId,
            "fileName": data.file.name,
            "uploadedFileKey": data.key,
            "fileStatus": "SUCCESS",
            "createdOn": new Date(),
        }
        setProgressStatus(100);
        setHandleUploadedFiles([...handleUploadedFiles, newObject]);
        setUploading(false);
        setProgressStatus(0);
        setSelectedFile(null);
    }

    const dataToServer = {
        bulk: false,
        fileName: null,
        docType: "xlsx",
        keyValue: `consession_${getUniqueKey()}`,
        serviceName: 'ENROLLMENT_CONCESSION_UPLOAD',
    };

    //get uploading endpoint from DMS (api call)
    const getFilePath = (file) => {
        try {
            setUploading(true);
            const formData = {
                ...dataToServer,
                fileName: file.name,
                docType: file.name.split(".")[1],
                // description: `Supporting files`
            };

            putpost(endpoint.fetchS3Url.getUrl,
                (data) => {
                    if (data.code === 200 && data.data) {
                        saveDataToStorage(data.data, file);
                        setProgressStatus(40);
                    }
                },
                (data) => {
                    setProgressStatus(0);
                    setUploading(false);
                    setSelectedFile(null)
                    setErrorMsg(data["message"]);
                },
                formData,
                "post"
            );
        } catch (e) {
            console.log(e);
        }
    };

    const handleDelete = (index) => {
        setSelectedDoc(index);
        setOpenDeleteDialog(true);
    }

    const handleCloseConfimation = () => {
        setSelectedDoc(null);
        setOpenDeleteDialog(false);
    }

    const deleteRow = (index) => {
        const docList = [...handleUploadedFiles]
        docList.splice(index, 1)
        setHandleUploadedFiles([...docList])
        handleCloseConfimation()
    }

    //this is file uploadImage part
    const saveDataToStorage = (res, file) => {
        try {
            // const formData = new FormData();
            // formData.append("File", file);
            // formData.append("destinationfolder", res.bucket);
            setProgressStatus(70);
            uploadpost(
                res.storage,
                (data) => {
                    setProgressStatus(100);
                    setData({ ...res, file: file });
                    // successToast(data["message"]);
                    setSucessMsg('File uploaded successfully')
                },
                (data) => {
                    setProgressStatus(0);
                    setUploading(false);
                    setSelectedFile(null)
                    setErrorMsg("Unable to upload file, please try after sometime.");
                },
                file,
                file?.type,
                file?.name
            );
        } catch (e) {
            setSelectedFile(null)
            setProgressStatus(0);
            setUploading(false);
            setErrorMsg("Unable to upload file, please try after sometime.");
            console.log(e);
        }
    };

    const handleFile = (e) => {
        const allowedExt = ['jpeg', 'png', 'pdf', 'jpg'];
        try {
            const currentFile = e.target.files?.[0];
            const currentFileName = currentFile.name;
            const currentFileSize = currentFile.size;
            if (!/^[^.]*\.[^.]+$/.test(currentFileName)) {
                setErrorMsg("File name error. Please rename file without special characters and upload")
                return
            }
            if (allowedExt.indexOf(currentFileName.split(".")[1]) > -1) {
                if (currentFile.name.length <= 100) {
                    let value = currentFileName.split(".")[0];

                    if (regex.alphaNumericWithSpaceAndUnderscore.test(value)) {
                        if (currentFileSize && (currentFileSize) / (1024 * 1024) > 10) {
                            setErrorMsg(`File Size error. Please upload file not exceeding 10 MB size`)
                            return
                        } else {
                            setSelectedFile(currentFile)
                            getFilePath(currentFile)
                        }
                    } else {
                        setErrorMsg("File name error. Please rename file without special characters and upload")
                        return
                    }
                } else {
                    setErrorMsg("File name error. Please rename file not exceeding 100 characters.")
                    return
                }
            } else {
                setErrorMsg(` File Format error. Please upload file in ${allowedExt.join(', ')} format only`)
                return
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleUploadedFile = () => {
        handleSave(handleUploadedFiles);
    }

    const handleClosepopup = () => {
        setHandleUploadedFiles(Array.isArray(documents) ? documents : [])
        handleClose()
    }

    const getDownloadKey = async (index, action) => {
        if (action === 'DOWNLOAD') {
            setDownloading(true)
        } else {
            setViewing(true)
        }
        // setDownloading(true)
        const response = await fetchAllPromisedData(`${endpoint.fetchS3Url.getDownloadKey}/${handleUploadedFiles?.[index]?.processInstanceId}`, true);
        if (response.code === 200) {
            getFileUrl(response?.data?.keyValue, action)

            if (response?.data?.docType === 'jpg' || response?.data?.docType === 'jpeg' || response?.data?.docType === 'png') {
                setViewerType("IMAGE");
            } else {
                setViewerType("PDF");
            }


        } else {
            setErrorMsg(`Unable to ${selectedDoc ? 'download' : 'view'} file, please try after sometime`)
            setDownloading(false)
            setViewing(false)
        }
    }

    const getFileUrl = async (index, action) => {
        if (action === 'DOWNLOAD') {
            setDownloading(true)
        } else {
            setViewing(true)
        }

        if (handleUploadedFiles?.[index]?.fileName?.split('.')?.[1] === 'jpg' || handleUploadedFiles?.[index]?.fileName?.split('.')?.[1] === 'jpeg' || handleUploadedFiles?.[index]?.fileName?.split('.')?.[1] === 'png') {
            setViewerType("IMAGE");
        } else {
            setViewerType("PDF");
        }
        const response = await fetchAllPostPromisedData(`${endpoint.fetchS3Url.getDownloadUrl}?key=${handleUploadedFiles?.[index]?.uploadedFileKey}`, true);
        if (response.code === 200) {
            if (action === 'DOWNLOAD') {
                window.open(`${response.data.url}`)
            } else {
                setDocForView(response.data.url)
            }
            // window.open(`${response.data.url}`)
            setDownloading(false)
            setViewing(false)
        } else {
            setErrorMsg(`Unable to ${selectedDoc ? 'download' : 'view'} file, please try after sometime`)
            setDownloading(false)
            setViewing(false)
        }
    }

    useEffect(() => {
        if (documents) {
            setHandleUploadedFiles(documents ?? [])
        }
    }, [documents])

    return (<>
        {/* <Popover
            // anchorEl={anchorEl}
            open={isOpen}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            style={{
                zIndex: '10',
                background: 'rgba(0,0,0,0.75)'
            }}
            sx={{
                '& .MuiPopover-paper': {
                    marginTop: '5px',
                    boxShadow: "0px 0px 9px 0px rgba(0,0,0,0.5)",
                    width: 380,
                    maxHeight: 500,
                    padding: 2,
                }
            }} > */}
        <Dialog
            open={isOpen}
            sx={{
                '& .MuiPaper-root': {
                    marginTop: '5px',
                    boxShadow: "0px 0px 9px 0px rgba(0,0,0,0.5)",
                    width: 380,
                    maxHeight: 500
                }
            }}
        ><div className='p-3'>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className='label-color large-text pb-2'>Documents</div>
                    <div onClick={handleClosepopup}><img src={closeIcon} /></div>
                </div>
                {!isView ? <div style={{ marginTop: 20, marginBottom: 20 }}>
                    <div style={{ color: '#262626', fontSize: 14, fontWeight: 600, marginTop: 20, marginBottom: 20 }}>Upload a new document</div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 20, cursor: 'pointer' }}>
                        <span style={{ color: '#00B0F5', fontSize: 14, fontWeight: 600, cursor: 'pointer' }}>Select document to upload</span> <img src={UploadIcon} />
                        <input onChange={(e) => { handleFile(e) }} style={{ position: 'absolute', opacity: 0, cursor: 'pointer' }} type='file' disabled={isView} />

                    </div>
                    <div style={{ display: 'flex', gap: 15, marginTop: 20, alignItems: 'center' }}>
                        <div><img style={{}} src={infoIcon} /></div>
                        <div style={{ width: 300, color: '#757575', fontSize: 12, fontWeight: 400 }}>File supported jpg, jpeg, png, and pdf.
                            Max. file size 10MB</div>
                    </div>
                </div> : null}
                <Divider sx={{ marginTop: 20, marginBottom: 5 }} />
                <div className='scroll-overlay' style={{ marginTop: 10, marginBottom: 20, height: 'auto', maxHeight: '200px', paddingRight: '10px' }}>
                    {handleUploadedFiles?.length || selectedFile ? <div className='label-color regular-bolder mb-2'>Saved documents</div> : null}
                    {
                        selectedFile ? <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                                <div style={{ width: 40, height: 40 }}>
                                    {!uploading ?
                                        <img src={selectedFile?.name.split(".")[1] === 'pdf' ? pdfIcon : fileIcon} width="100%" height="100%" /> :
                                        <i className="fas fa-spinner fa-spin" aria-hidden="true">{''}</i>
                                    }
                                </div>
                                <div>
                                    <div style={{ color: '#0F0F0F', fontSize: 12, width:'140px', wordWrap: 'break-word' }}>{selectedFile?.name ?? ''}</div>
                                    <div style={{ color: '#757575', fontSize: 10 }}>
                                        {uploading ? `${progressStatus}% Uploading...` : `Uploaded on ${moment(new Date()).format('DD MMM YYYY')}`}
                                    </div>
                                </div>
                            </div>
                        </div> : null
                    }
                    {
                        (handleUploadedFiles ?? [])?.map((file, index) => {
                            return <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                                <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                                    <div style={{ minWidth: 40, height: 40 }}>
                                        <img src={file?.fileName?.split(".")[1] === 'pdf' ? pdfIcon : fileIcon} width="100%" height="100%" />
                                    </div>
                                    <div className='pl-2 pr-1'>
                                        <div style={{ color: '#0F0F0F', fontSize: 12, width:'140px', wordWrap: 'break-word' }}>{file?.fileName ?? ''}</div>
                                        <div style={{ color: '#757575', fontSize: 10 }}>
                                            {`Uploaded on ${moment(file.createdOn).format('DD MMM YYYY')}`}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                                    {viewing && docViewingIndex === index ? <i className="fas fa-spinner fa-spin" aria-hidden="true">{''}</i> : <div className="pr-1" onClick={() => { setDocIndex(index); getFileUrl(index, 'VIEW') }}><EyeIcon /></div>}
                                    <div style={{ cursor: 'pointer' }} onClick={() => { setSelectedDoc(index); getFileUrl(index, 'DOWNLOAD') }}>
                                        {
                                            downloading && selectedDoc === index ?
                                                <i className="fas fa-spinner fa-spin" aria-hidden="true">{''}</i> :
                                                <img style={{ color: '#00B0F5' }} src={DownloadIcon} />}
                                    </div>
                                    {!isView ? <div className="pr-1" onClick={() => { handleDelete(index) }}><img src={deleteIcon} /></div> : null}
                                </div>
                            </div>
                        })
                    }
                </div>
                {error ? <div style={{ color: 'red', fontSize: 14, display: 'flex', gap: 10, alignItems: 'center' }}><img src={infoError} width={12} height={12} />{error}</div> : null}
                {success ? <div style={{ color: 'green', fontSize: 14, display: 'flex', gap: 10, alignItems: 'center' }}><img src={check} width={12} height={12} />{success}</div> : null}
                {!isView && <CustomButton
                    content={"Save & Close"}
                    className="btn-left-0 regular-large"
                    forTable={true}
                    permissionType={'C'}
                    permissionSet={userPermissions}
                    onClick={handleUploadedFile}
                />}
                {/* </Popover> */}
            </div>
        </Dialog>
        <ConfirmationDeleteDialog
            isOpen={openDeleteDialog}
            onAgree={() => { deleteRow(selectedDoc) }}
            setIsOpen={handleCloseConfimation}
            headerMsg={'Confirm document delete'}
            msg={`Are you sure you want to delete “${handleUploadedFiles?.[selectedDoc]?.fileName ?? ''}” ?`}
        />
        <Dialog open={!!docForView} onClose={() => { setDocIndex(null); setDocForView(null); setViewerType(null) }} maxWidth={'md'}>
            {/* <object data={docForView} width="100%" height="500px"></object> */}
            {viewerType ? viewerType === 'IMAGE' ?
                <TransformWrapper>
                {({ zoomIn, zoomOut }) => (
                  <div>
                    <TransformComponent>
                    <img src={docForView} width="100%" height="500px" />
                    </TransformComponent>
                    <div className='d-flex justify-content-center' style={{backgroundColor:'#808080',gap:'6px', opacity:'0.5'}}>
                        <AiOutlineZoomOut size={20} color={'#fff'} onClick={() => zoomOut()}/>
                        <AiOutlineZoomIn size={20} color={'#fff'} onClick={() => zoomIn()}/>
                    </div>
                  </div>
                )}
                </TransformWrapper>
                 :
                // <object data={docForView} width="100%" height="500px"></object>
                <div style={{width: '600px', height: '600px', overflow:'hidden'}}>
                    <LazyPDFViewer src={docForView} />
                </div>
                : null}
        </Dialog>
    </>
    )
}

export default Uploader