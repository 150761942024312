import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { Card, Table, Button, Input } from "reactstrap";
import { MasterHeaderAuto } from "../../common/commonComponents/masterHeader/masterHeader";
import { openInNewTab } from "../../common/utils/methods/commonMethods/utilityMethod";
import CustomLoader from "../../common/commonComponents/Loader/loader";
import { documentServiceBaseUrl } from "services/http";
import { fetchAllData, NewRow } from "../../common/utils/methods/commonMethods/utilityMethod";
import CardHeader from "reactstrap/lib/CardHeader";
import Container from "reactstrap/lib/Container";
import { GetPagination } from "../../common/commonComponents/pagination/pagination";
import "./_documentProcessTag.scss"
import { PermissionContext, RolePermissions } from "appContext";
import { PermisionDenied } from "../../common/commonComponents";
import { pages } from "../../common/constant";
import CustomButton from "components/CustomButton";
import CommonInput from "../../common/formFeilds/input/commonInput";



const getMappedData = (list, masterList) => {
  return masterList.filter((e) => list.indexOf(e.id) !== -1);
};

const DocumentProcessTagSearch = (props) => {

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["documentProcessTag"]['id']);

  const { data } = props;
  const [documentData, setAllDocumentData] = useState(null);
  const [uniqueData, setUniqueData] = useState(null);
  const [serverData, setServerData] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [classArray, setclassArray] = useState([])
  const [SearchStr, setSearchStr] = useState('')

  const headerList = [
    { name: "S. No" },
    { name: "Document Type" },
    { name: "Actions" },
  ];
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const pageSize = 10;

  const setPagination = (paramsdata) => {
    let n = pagination;
    if (paramsdata.length > pageSize) {
      n["nextPage"] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n["totalPage"] = Math.floor(paramsdata.length / pageSize);
    if (paramsdata.length % pageSize != 0) {
      n["totalPage"] += 1;
    }
    setpagination(n);
    setclassArray(
      paramsdata.slice(
        pagination.currentPage * pageSize - pageSize,
        pagination.currentPage * pageSize
      ) || []
    );
    setIsFetching(false);
  };

  useEffect(() => {
    fetchAllData(
      `${documentServiceBaseUrl}/DocumentProcessTag/getDistinctDocumentType`,
      setUniqueData
    );
    fetchAllData(
      `${documentServiceBaseUrl}/documentType/getAllDocumentType`,
      setAllDocumentData
    );
  }, []);
  const listToRender = ["sno", "documentTypeDispValue"];

  useEffect(() => {
    if (documentData && uniqueData) {
      setServerData(getMappedData(uniqueData, documentData));
    }
  }, [documentData, uniqueData]);

  useEffect(() => {
    serverData && setPagination(serverData)
  }, [serverData])


  const filterData = (str) => {
    const newData =
      serverData &&
      serverData.filter((obj) =>
        // Object.values(obj).some(
        //   (val) =>
        obj && obj['documentTypeDispValue'].toString().toLowerCase().includes(str.toLowerCase())
        // )
      );
    setPagination(newData);
  };

  useEffect(() => {
    if (serverData && SearchStr) {
      filterData(SearchStr)
    } else {
      serverData && setPagination(serverData);
    }
  }, [SearchStr])

  return (
    userPermissions ?
      <Container fluid className="pt-4">
        <Card className="mt-4">
          <CardHeader className=" d-flex justify-content-between">
            <h3 className="mb-0 col-4">Document Tag Process Search</h3>
            <div className="d-flex flex-row justify-content-between document_proceess_tag_header">
              <div className="d-flex flex-row  align-items-center justify-content-between mr-3">
                {/* <h3 className="text-muted col-5">Search :</h3> */}
                {/* <Input
                  className="tdInput"
                  // style={{ width: "70%" }}
                  defaultValue={SearchStr}
                  onChange={(e) => setSearchStr(e.target.value)}
                  
                /> */}
                <CommonInput
                  // label="Start"
                  forTable={true}
                  type="text"
                  defaultValue={SearchStr}
                  onChange={(e) => setSearchStr(e)}
                  placeHolder={`Search...`}
                />
              </div>
              {/* <Button
                // disabled={isSaveVisible || isDisabled}
                color="info"
                size="md"
                type="button"
                className={"floatRight"}
                onClick={() =>
                  props.history.push(`/admin/documentProcessTag/new`)
                }
                data-testid="addNewdocumentProcessTag"
              >
                New Document Process Tag
              </Button> */}
              <CustomButton
                className={`floatRight`}
                content={'New Document Process Tag'}
                permissionType={'C,U'}
                icon={true}
                permissionSet={userPermissions}
                onClick={() =>
                  props.history.push(`/admin/documentProcessTag/new`)
                }
              />
            </div>
          </CardHeader>
          <div className="p-4">
            <Table className="align-items-center table-flush">
              <MasterHeaderAuto headerList={headerList} />
              {isFetching ? (
                <div
                  className="mx-auto text-center py-5 my-5"
                  style={{ height: "100vh" }}
                >
                  <CustomLoader apiLoader={isFetching} />
                </div>
              ) : (
                <tbody className="list">
                  {documentData && uniqueData ? (
                    classArray.map((el, index) => {
                      // if (uniqueData.indexOf(el.id) > 0) {
                      return (
                        <NewRow data={{ ...el, sno: (index + 1) + (pagination.currentPage - 1) * 10 }} listToBeRendered={listToRender}>
                          <td className="text-center  white-breakSpace">
                            {/* <Button
                              color="info"
                              size="sm"
                              type="button"
                              onClick={() =>
                                props.history.push(
                                  `/admin/documentProcessTag/view/${el.id}`
                                )
                              }
                            >
                              <i className="fas fa-eye" />
                            </Button> */}
                            <CustomButton
                              permissionType={'R'}
                              forTable={true}
                              icon={true}
                              permissionSet={userPermissions}
                              onClick={() =>
                                props.history.push(
                                  `/admin/documentProcessTag/view/${el.id}`
                                )
                              }
                            />
                            {/* <Button
                              color="info"
                              size="sm"
                              type="button"
                              onClick={() =>
                                props.history.push(
                                  `/admin/documentProcessTag/edit/${el.id}`
                                )
                              }
                            >
                              <i className="fas fa-edit" />
                            </Button> */}
                            <CustomButton
                              permissionType={'U'}
                              forTable={true}
                              icon={true}
                              permissionSet={userPermissions}
                              onClick={() =>
                                props.history.push(
                                  `/admin/documentProcessTag/edit/${el.id}`
                                )
                              }
                            />
                          </td>
                        </NewRow>
                      );
                      // } else {
                      //   return null;
                      // }
                    })
                  ) : (
                    <tr>No records found</tr>
                  )}
                </tbody>
              )}
            </Table>
            {serverData && serverData.length > 10 ? (
              <GetPagination
                // fetchClasses={fetchClasses}
                setclassArray={setclassArray}
                pagination={pagination}
                setpagination={setpagination}
                pageSize={pageSize}
                state={data}
              />
            ) : null}
          </div>
        </Card>
      </Container> : <PermisionDenied />
  );
};

export default withRouter(DocumentProcessTagSearch);
