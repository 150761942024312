import React from "react";
import { Link } from "react-router-dom";

// Using link this will add feature open in new tab in right click
const LinkWrapper = ({ children, url, newTab = "_blank", ...rest }) => {
  return (
    <Link to={url} target={newTab} {...rest}>
      {children}
    </Link>
  );
};

export default LinkWrapper;
