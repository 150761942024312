import { BATCH_DETAILS_FAILURE, BATCH_DETAILS_REQUEST, BATCH_DETAILS_SUCCESS, CLEAR_BATCH_DETAILS_STATE, FETCH_DROPDOWN_FAILURE, FETCH_DROPDOWN_SUCCESS, START_DOWNLOAD_LOADER, STOP_DOWNLOAD_LOADER, STUDENT_LIST_FAILURE, STUDENT_LIST_REQUEST, STUDENT_LIST_SUCCESS } from "./allocate-students-action-types"

const initialState = {
  batchDetails: {},
  batchDetailsLoading: false,
  batchError: '',
  studentList: [],
  studentListLoading: false,
  totalServerPages: 0,
  studentListRowCount: 0,
  showDialog: false,
  studentListError: '',
  dropdowns: {},
  downloadCsvLoader: false,
}

export const allocateStudentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case STUDENT_LIST_REQUEST:
      return {
        ...state,
        studentListLoading: true
      }
    case STUDENT_LIST_SUCCESS:
      return {
        ...state,
        studentListLoading: false,
        studentListError: '',
        studentList: action.payload,
        totalServerPages: action.totalServerPages,
        studentListRowCount: action.rowCount
      }
    case STUDENT_LIST_FAILURE:
      return {
        ...state,
        studentListLoading: false,
        studentList: [],
        totalServerPages: 0,
        studentListRowCount: 0,
        studentListError: action.payload
      }
    case BATCH_DETAILS_REQUEST:
      return {
        ...state,
        batchDetailsLoading: true
      }
    case BATCH_DETAILS_SUCCESS:
      return {
        ...state,
        batchDetailsLoading: false,
        batchError: '',
        batchDetails: action.payload
      }
    case BATCH_DETAILS_FAILURE:
      return {
        ...state,
        batchDetailsLoading: false,
        batchDetails: {},
        batchError: action.payload
      }
    case CLEAR_BATCH_DETAILS_STATE:
      return {
        ...state,
        studentList: [],
        totalServerPages: 0,
        studentListRowCount: 0,
        batchDetails: {},
        batchError: ''
      }
    case FETCH_DROPDOWN_SUCCESS: 
      return {
        ...state,
        dropdowns: action.payload
      }
    case FETCH_DROPDOWN_FAILURE:
      return {
        ...state,
        dropdowns: {},
        dropdownsError: action.payload
      }
    case START_DOWNLOAD_LOADER:
      return {
        ...state,
        downloadCsvLoader: true,
      }
    case STOP_DOWNLOAD_LOADER:
      return {
        ...state,
        downloadCsvLoader: false,
      }
    default:
      return state
  }
}