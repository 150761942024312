import CustomButton from "components/CustomButton";
import moment from 'moment';
import React, { useState } from "react";
import Select2 from "react-select2-wrapper";
import { Card, CardHeader, Col, Container, Input, Row, Table } from "reactstrap";
import { getAPI, masterServiceBaseUrl, putpost, putpost2 } from 'services/http';
import { MasterHeader, TDROW } from "../../common/commonComponents/masterRow/masterRow";
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import './index.scss';
var _ = require('lodash');

const DistrictTable = (props) => {
  const { setDistrict, district, tableData, pagination, setclassArray, setpagination, userPermissions, isSaveVisible, setisSaveVisible = () => { } } = props;

  const [tableDataKey, settableDataKey] = useState('tableDataKey')
  const [stateList, setStateList] = useState([])
  const [districtSearchValue, setDistrictSearchValue] = useState('');
  const [countrySearchValue, setCountrySearchValue] = useState('');
  const [stateSearchValue, setStateSearchValue] = useState('');
  React.useEffect(() => {
    setStateList([])
  }, [])
  const addNewClass = () => {
    props.setclassArray([{ "editMode": true, "__status": "__new", "districtKey": null, 'status': 'ACTIVE', "districtDesc": null, "districtDispValue": null, "effectiveDate": null, "districtGstCode": null, "countryId": null, "stateId": null }, ...props.classArray])
  }
  const saveAll = (cb) => {
    var _tempArray = props.classArray
    var isValid = true
    _tempArray.forEach((el, index) => {
      if (el['editMode']) {
        if (el['districtKey'] === null || !el['districtKey'].trim()) {
          isValid = false
          failureToast('Please enter the value of District Key')
          return
        } else if (el['districtDesc'] === null || !el['districtDesc'].trim()) {
          isValid = false
          failureToast('Please enter the value of Description')
          return
        }
        else if (el['districtDispValue'] === null || !el['districtDispValue'].trim()) {
          isValid = false
          failureToast('Please enter the value of Display Value')
          return
        }
        else if (el['districtGstCode'] === null || !el['districtGstCode'].trim()) {
          isValid = false
          failureToast('Please enter the value of District Code')
          return
        }
        else if (!el['countryId']) {
          isValid = false
          failureToast('Please enter the value of Country')
          return
        }
        else if (!el['stateId']) {
          isValid = false
          failureToast('Please enter the value of State')
          return
        }
        if (!isValid) {
          return
        }
        if (_tempArray[index]['__status'] == "__new") {
          putpost(masterServiceBaseUrl + '/district/createDistrict', (data) => {
            successToast(data['message'])
            setisSaveVisible(false)
            props.fetchClasses(masterServiceBaseUrl + '/district/getAllDistrict')
            setStateList([])
          }, (data) => { failureToast(data['message']) }, _tempArray[index], 'post')
        } else {
          putpost(masterServiceBaseUrl + '/district/updateDistrict/' + _tempArray[index].id, (data) => {
            successToast(data['message'])
            setisSaveVisible(false)
            props.fetchClasses(masterServiceBaseUrl + '/district/getAllDistrict')
            setStateList([])
            // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
          }, (data) => { failureToast(data['message']) }, _tempArray[index], 'put')
        }
      } else {

      }
    })
  }
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes")
    }
    else {
      let cb = () => {
        addNewClass()
        setisSaveVisible(true)
        // settableDataKey(new Date().getMilliseconds())
      }
      var _tempArray = props.classArray
      _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
      props.setclassArray(_tempArray)
      setisSaveVisible(false)
      cb()
    }
  }
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes")
    }
    else {
      let cb = () => {
        var _tempArray = props.classArray
        _tempArray[index]['editMode'] = true
        props.setclassArray(_tempArray)
        getData(`${masterServiceBaseUrl}/state/getStateByCountry/${_tempArray[index]['countryId']}`, setStateList, 'state')
        setisSaveVisible(true)
      }
      var _tempArray = props.classArray
      _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
      cb()
    }
  }
  const deleteRow = (index) => {
    if (window.confirm('Are you sure you want to delete this row?')) {
      if (props.classArray[index]["__status"] == "__new") {
        let n = props.classArray
        n.splice(index, 1)
        props.setclassArray(n)
        setisSaveVisible(false)
        settableDataKey(new Date().getMilliseconds())
      } else {
        putpost2(masterServiceBaseUrl + '/district/deleteDistrict/' + props.classArray[index].id, (data) => {
          successToast(data['message'])
          if (props.classArray.length == 1) {
            props.previousPage()
          } else {
            var _tempArray = props.classArray
            if (_tempArray[index]['editMode']) {
              setisSaveVisible(false)
            }
            _tempArray.splice(index, 1)
            props.setclassArray(_tempArray)
          }
          props.refreshList()
          settableDataKey(new Date().getMilliseconds())
        }, (data) => { failureToast(data['message']) }, 'Delete')
      }
    }
  }
  const updateKey = (index, value, key) => {

    var _tempArray = props.classArray
    _tempArray[index][key] = value
    props.setclassArray(_tempArray)

    if (key === 'countryId' && value !== null && value !== '') {
      setStateList([])
      getData(`${masterServiceBaseUrl}/state/getStateByCountry/${value}`, setStateList, 'state')
    }
  }

  const getData = (url, stateMethod, keyword) => {
    getAPI(url,
      (data) => {
        const dropList = data.data.map(item => {
          return { id: item.id, text: item[`${keyword}DispValue`] };
        })
        stateMethod(dropList)
      },
      (data) => {
        // failureToast(data['message']); 
      }
    )
  }

  const searchPaginationHandler = (filterData, pageSize, n) => {
    if (filterData.length > pageSize) {
      n['nextPage'] = n.currentPage + 1
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1
    }
    n['totalPage'] = Math.floor(filterData.length / pageSize)
    if (filterData.length % pageSize != 0) {
      n['totalPage'] += 1
    }
    setDistrict(filterData);
    setclassArray(filterData.slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || []);
    setpagination(n)
  }

  const filterHandler = _.debounce((districtValue, countryValue, stateValue) => {
    let n = pagination;
    let pageSize = 10;
    const filterCountryList = props.countryList.filter(data => data.text.toUpperCase().includes(countryValue.toUpperCase()));
    const filterStateList = props.stateList.filter(data => data.text.toUpperCase().includes(stateValue.toUpperCase()));
    if (districtValue || countryValue || stateValue) {
      if (districtValue && !countryValue && !stateValue) {
        const filterCountry = props.countryList.filter(data => data.text.toUpperCase().includes(districtValue.toUpperCase()));
        const filterState = props.stateList.filter(data => data.text.toUpperCase().includes(districtValue.toUpperCase()));
        const filterData = tableData.filter(data => (
          filterCountry.findIndex(countryCode => countryCode['id'] === data['countryId']) >= 0 ||
          filterState.findIndex(stateCode => stateCode['id'] === data['stateId']) >= 0 ||
          data['districtKey'].toUpperCase().includes(districtValue.toUpperCase()) ||
          data['districtDesc'].toUpperCase().includes(districtValue.toUpperCase()) ||
          data['districtDispValue'].toUpperCase().includes(districtValue.toUpperCase()) ||
          data['districtGstCode'].toUpperCase().includes(districtValue.toUpperCase()) ||
          data['status'].toUpperCase().includes(districtValue.toUpperCase())
        ));
        searchPaginationHandler(filterData, pageSize, n);
      } else if (districtValue && countryValue && !stateValue) {
        const filterData = tableData.filter(data => (
          filterCountryList.findIndex(countryCode => countryCode['id'] === data['countryId']) >= 0 &&
          data['districtKey'].toUpperCase().includes(districtValue.toUpperCase())
        ));
        searchPaginationHandler(filterData, pageSize, n)
      } else if (districtValue && countryValue && stateValue) {
        const filterData = tableData.filter(data => (
          filterCountryList.findIndex(countryCode => countryCode['id'] === data['countryId']) >= 0 &&
          filterStateList.findIndex(stateCode => stateCode['id'] === data['stateId']) >= 0 &&
          data['districtKey'].toUpperCase().includes(districtValue.toUpperCase())
        ));
        searchPaginationHandler(filterData, pageSize, n)
      } else if (districtValue && !countryValue && stateValue) {
        const filterData = tableData.filter(data => (
          filterStateList.findIndex(stateCode => stateCode['id'] === data['stateId']) >= 0 &&
          data['districtKey'].toUpperCase().includes(districtValue.toUpperCase())
        ));
        searchPaginationHandler(filterData, pageSize, n)
      } else if (!districtValue && countryValue && stateValue) {
        const filterData = tableData.filter(data => (
          filterCountryList.findIndex(countryCode => countryCode['id'] === data['countryId']) >= 0 &&
          filterStateList.findIndex(stateCode => stateCode['id'] === data['stateId']) >= 0
        ));
        searchPaginationHandler(filterData, pageSize, n)
      } else if (!districtValue && countryValue && !stateValue) {
        const filterData = tableData.filter(data => filterCountryList.findIndex(countryCode => countryCode['id'] === data['countryId']) >= 0);
        searchPaginationHandler(filterData, pageSize, n)
      } else if (!districtValue && !countryValue && stateValue) {
        const filterData = tableData.filter(data => filterStateList.findIndex(stateCode => stateCode['id'] === data['stateId']) >= 0);
        searchPaginationHandler(filterData, pageSize, n)
      }
    } else {
      searchPaginationHandler(tableData, pageSize, n);
    }
  }, 300)

  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - District</h3>
                <div className="floatRight " style={{ display: "flex", alignItems: "center", marginLeft: 'auto' }}>
                  Search : <Input disabled={isSaveVisible} value={districtSearchValue} onChange={(e) => { let n = e.target.value.trim(); setDistrictSearchValue(n); filterHandler(n, countrySearchValue, stateSearchValue) }} className="tdInput ml-3 mr-3 customInputWidth" placeholder="Search" />
                  <CustomButton
                    disabled={isSaveVisible}
                    className={'floatRight mx-1' + (isSaveVisible ? ' btn-dark cursor-gray' : null)}
                    content={'New District'}
                    permissionType={'C'}
                    icon={true}
                    onClick={() => { newRow(); }}
                    permissionSet={userPermissions}
                  />
                </div>
              </CardHeader>
              <Row>
                <Col style={{ display: "flex" }} className="mb-3">
                  <Input disabled={isSaveVisible} className="tdInput ml-3 customInputWidth" placeholder="Search Country" onChange={(e) => { let n = e.target.value.trim(); setCountrySearchValue(n); filterHandler(districtSearchValue, n, stateSearchValue) }} />
                  <Input disabled={isSaveVisible} className="tdInput ml-3 customInputWidth " placeholder="Search State" onChange={(e) => { let n = e.target.value.trim(); setStateSearchValue(n); filterHandler(districtSearchValue, countrySearchValue, n) }} />
                </Col>
              </Row>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout" >
                  <MasterHeader type={"District"} permissionSet={userPermissions} isSaveVisible={isSaveVisible} />
                  <tbody className="list" key={tableDataKey} >
                    {props.classArray ? props.classArray.map((el, index) =>
                      !el['editMode'] ? (
                        <TDROW type={"district"} index={index} el={el} editRow={editRow} deleteRow={deleteRow} countryList={props.countryList} stateList={props.stateList} permissionSet={userPermissions} isSaveVisible={isSaveVisible} />
                      ) :
                        (<tr key={index + '-class'}>
                          <td className="text-center p-2 mx-1"><Select2 defaultValue={el['countryId']} data={props.countryList} onChange={(e) => { updateKey(index, e.target.value, 'countryId', true) }} options={{ placeholder: "Select Country" }} className="tdSelect" /></td>

                          {el['__status'] && el['__status'] === '__new' ?
                            <td className="text-center p-2 mx-1"><Select2 defaultValue={el['stateId']} data={stateList} onChange={(e) => { updateKey(index, e.target.value, 'stateId') }} options={{ placeholder: "Select State" }} className="tdSelect" /></td>
                            : stateList.length ?
                              <td className="text-center p-2 mx-1"><Select2 defaultValue={el['stateId']} data={stateList} onChange={(e) => { updateKey(index, e.target.value, 'stateId') }} options={{ placeholder: "Select State" }} className="tdSelect" /></td>
                              : <td className="text-center p-2 mx-1"><Select2 defaultValue={{}} data={[]} options={{ placeholder: "Select State" }} className="tdSelect" /></td>
                          }

                          {/* <td className="text-center p-2 mx-1"><Select2 defaultValue={el['stateId']} data={stateList} onChange={(e)=>{updateKey(index,e.target.value,'stateId')}} options={{placeholder: "Select State"}} className="tdSelect" /></td> */}
                          <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['districtKey']} onChange={(e) => { updateKey(index, e.target.value, 'districtKey') }} placeholder="Example:1" className="tdInput" /></td>
                          <td className="text-center p-2 mx-1"><Input disabled={true} defaultValue={el["__status"] == "__new" ? null : moment(el['eff_date']).format('DD-MM-YYYY')} placeholder="Example:01-01-2020" className="tdInput" /></td>
                          <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['districtDesc']} onChange={(e) => { updateKey(index, e.target.value, 'districtDesc') }} placeholder="Description" className="tdInput" /></td>
                          <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['districtDispValue']} onChange={(e) => { updateKey(index, e.target.value, 'districtDispValue') }} placeholder="Display Value" className="tdInput" /></td>
                          <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['districtGstCode']} onChange={(e) => { updateKey(index, e.target.value, 'districtGstCode') }} placeholder="District Code" className="tdInput" /></td>
                          <td className="text-center p-2 mx-1">
                            <label className="custom-toggle mx-auto ml-2">
                              <input checked={el.status == "ACTIVE" ? true : false} type="checkbox" id={el.id} onChange={(e) => { settableDataKey(new Date().getMilliseconds()); let n = "ACTIVE"; if (!e.target.checked) { n = "INACTIVE" } updateKey(index, n, 'status') }} />
                              <span style={{ width: "72px" }} className="custom-toggle-slider rounded-circle activeToggle" data-label-off="Inactive" data-label-on="Active" />
                            </label>
                          </td>
                          <td className="text-center">
                            {isSaveVisible ? <>
                              <CustomButton
                                content={'Save'}
                                permissionType={'C,U'}
                                icon={false}
                                forTable={true}
                                onClick={() => { saveAll() }}
                                permissionSet={userPermissions}
                              />

                              <CustomButton
                                content={''}
                                permissionType={'cancel'}
                                icon={true}
                                forTable={true}
                                onClick={() => {
                                  let n = props.classArray
                                  if (n[index]['__status'] == '__new') {
                                    n.splice(index, 1)
                                  } else {
                                    // n[index]['editMode']=false
                                    props.fetchClasses(masterServiceBaseUrl + '/district/getAllDistrict')
                                  }
                                  setStateList([])
                                  props.setclassArray(n)
                                  setisSaveVisible(false)
                                }}
                                permissionSet={userPermissions}
                              />

                            </> :
                              <CustomButton
                                content={''}
                                permissionType={'D'}
                                icon={true}
                                forTable={true}
                                onClick={() => {
                                  setStateList([])
                                  deleteRow(index)
                                }}
                                permissionSet={userPermissions}
                              />}
                          </td>
                        </tr>)
                    ) : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
export default DistrictTable;