import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import NewCourseAttr from "../views/pages/manage/courseInformation/courseService/courseAttributes/courseAttributeForm/component/index";
import NewPricingZone from "../views/pages/manage/courseInformation/pricingService/pricingZone/associatedBusinessArea/component/index";
import CompanyCodeRedirector from "../views/pages/manage/masters/companyCode/companyCodeRedirector";
import NewTermForm from "../views/pages/manage/applicationInformation/applicationIdSetup/termForm/component/index";
import NewProgramActions from "../views/pages/manage/applicationInformation/applicationIdSetup/programActionMapping/programActionForm/component/index";
import StudentInformation from "../views/pages/manage/transactions/studentAdmission/studentInfo/component";
import CreateNewStudent from "../views/pages/manage/transactions/studentAdmission/studentInfo/component/createNewStudent";
import ExternalOrg from "../views/pages/manage/applicationInformation/externalOrganizationId/externalOrg/component/index";
import CourseHeader from "../views/pages/manage/courseInformation/courseService/courseCatalogForm/courseCatalogForm";
import HouseBank from "../views/pages/manage/unAssigned/houseBank/addNewHouseBank";
import TaxComponent from "../views/pages/manage/unAssigned/taxCodeId/components/taxBreakup/taxComponent";
import BusinessUnitForm from "../views/pages/manage/businessArea/businessAreaSearch/mainFom";
import ItemType from "../views/pages/manage/unAssigned/taxCodeId/components/itemType/itemType";
import BusinessUnitEditViewForm from "../views/pages/manage/businessArea/businessAreaSearch/businessUnitEditViewForm";
import ProfitMapping from "../views/pages/manage/masters/profitCenteMappingPage";
import GLCode from "../views/pages/manage/unAssigned/glCode/addNewglCode";
import ControlPaymodesFunctionalities from "../views/pages/manage/businessArea/paymentSchedule/controlPaymodeAndFunctionality/index";
import ViewInstallment from "../views/pages/manage/businessArea/paymentSchedule/viewInstallment/index";
import SetupDates from "../views/pages/manage/businessArea/paymentSchedule/setupDates";
import SetupInstallments from "../views/pages/manage/businessArea/paymentSchedule/setupInstallments";
import SubCategory from "../views/pages/manage/unAssigned/scholarshipMaster/concessionInnerMaster/subCategory";
import SubSubCategory from "../views/pages/manage/unAssigned/scholarshipMaster/concessionInnerMaster/SubSubCategoryMapping/index";
import AddCourseSecurity from "../views/pages/manage/unAssigned/scholarshipMaster/concessionInnerMaster/AddCourseForSecurity/index";
import ReceiptAccountEntry from "../views/pages/manage/unAssigned/paymentMode/receiptAccountEntry/index";
import RefundAccountEntry from "../views/pages/manage/unAssigned/paymentMode/refundAccountEntry/index";

import SetupCourseSecurity from "../views/pages/manage/unAssigned/scholarshipMaster/concessionInnerMaster/setupBUandCourseSecurity";
import CourseCatalogSequenceSetup from "../views/pages/manage/courseInformation/courseService/courseCatalogSequenceSetup/mainform";
import ViewAndEditCourseCatalog from "../views/pages/manage/courseInformation/courseService/courseCatelogSequenceSearch/viewAndEdit";
import CourseDetails from "../views/pages/manage/courseInformation/pricingService/courseFeeInfoSearch/courseDetails";
import DocumentDetails from "../views/pages/manage/courseInformation/pricingService/courseFee/CourseFee/details";
import FeeMaster from "../views/pages/manage/courseInformation/pricingService/courseFee/CourseFee";
import PricingUpload from "../views/pages/manage/courseInformation/pricingService/courseFee/CourseFee/pricingUpload";

import UploadDocumentBulk from "../views/pages/manage/examService/ExamID_BulkSearch/ExamIdBulkUpload/uploadDocument";
import ConfigrationHeader from "../views/pages/manage/examService/examConfig/configrationHeader";
import ApplicationIdGeneration from "../views/pages/manage/transactions/studentAdmission/applicationIdGeneration";
import DocumentProcessTag from "../views/pages/manage/masters/documentProcessTag/documentProcessTag";
import relatedDocumentApplicationId from "../views/pages/manage/transactions/studentAdmission/applicationIdGeneration/documentUpload";
import CommonComp from "../views/pages/manage/common/temp";
import NewExamIdForm from "../views/pages/manage/examService/examIdGeneration/newExamIdForm";
import UploadProcess from "../views/pages/manage/transactions/documentManagement/documentUploadManagement/upload";
import Bank from "../views/pages/manage/unAssigned/bank/addNewBankCode";
import ItemTypeSequence from "../views/pages/manage/courseInformation/pricingService/itemTypeSequenceSetup/itemTypeSequence";
import FeeSetupRules from "../views/pages/manage/courseInformation/pricingService/feeSetupRule/feeSetupRules";
import DocumentBulkUploadSearch from "../views/pages/manage/transactions/documentManagement/bulkUpload";
import DocumentBulkUpload from "../views/pages/manage/transactions/documentManagement/bulkUpload/uploadDocuments";
import ExamIDbulkUpload from "../views/pages/manage/examService/ExamID_BulkSearch/ExamIdBulkUpload/index";
import Floor from "views/pages/manage/masters/buildingMaster/floor/floor";
import Room from "views/pages/manage/masters/buildingMaster/room/room";
import ItemTypeDetailsAndDescriptionTable from "../views/pages/manage/transactions/feeCalculator/common/itemTypeDetailsAndDescriptionTable";
import AssignPermission from "../views/pages/manage/userSecurity/roleToProcess/assignPermission";
import TemplateUploadForm from "../views/pages/manage/transactions/templateUploadManager/form";
import InternalTestResult from "../views/pages/manage/transactions/Curriculum/internalTestResult/internalTestResultAddNewForm";
import NewCourseSetup from "../views/pages/manage/courseInformation/pricingService/courseFeeInfoSearch/manualCourseSetup";
import ExamSyllabusNew from "../views/pages/manage/masters/examSyllabus/examSyllabusForm";
import InternetConnection from "../components/InternetConnection";
import ErrorPage from "../components/ErrorPage";
import MessageTemplateForm from "../views/pages/manage/TemplateService/messageTemplateForm";
import { getCookie } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
// import HomePage from 'views/pages/manage/homepage';
import HomePage from "views/pages/manage/homepage/Homepage.jsx";
import PageNotFound from "views/pages/manage/pageNotFound";
import NewClassSection from "views/pages/manage/edpOps/classSection/newClassSection";
import BatchViewEdit from "views/pages/manage/edpOps/viewEditBatch";

import CreateProfile from "views/pages/manage/employeeManagement/userProfile";
import ViewUserProfile from "views/pages/manage/employeeManagement/userProfile/viewProfile";
import CreateSecurityGroup from "views/pages/manage/userSecurity/securityGroup/CreateForm";
import CreateRole from "views/pages/manage/role/addRole";
import CreateBatch from "views/pages/manage/edpOps/createBatch/createBatch";
import AllocateStudents from "views/pages/manage/edpOps/allocateStudents/AllocateStudents";
import DeallocateStudents from "views/pages/manage/edpOps/deallocateStudents/DeallocateStudents";
import StudentDetails from "views/pages/manage/edpOps/studentDetails/StudentDetails";
import ScholarshipMaster from "views/pages/manage/unAssigned/scholarshipMaster";
import Lumpsum from "views/pages/manage/unAssigned/scholarshipMaster/lumpsumConfig/index.js";
import BuildingMaster from "views/pages/manage/masters/buildingMaster";
import LoanSchemeSetup from "views/pages/manage/unAssigned/loanSchemeSetup";
import StudentChargesDetails from "views/pages/manage/unAssigned/studentCharges/studentChargesDetails";
// import SearchAcademicPlan from 'views/pages/manage/academicPlanAndTest/searchAcademicPlan/SearchAcademicPlan';
import CreateAcademicPlan from "../views/pages/manage/academicPlanAndTest/createAcademicPlan/CreateAcademicPlan";
import ViewAcademicPlan from "views/pages/manage/academicPlanAndTest/viewAcademicPlan/ViewAcademicPlan";
// import AcademicTestPage from './../views/pages/manage/academicPlanAndTest/academicTest/Index';
import ViewTests from "views/pages/manage/academicPlanAndTest/testsListView/ViewTests";
import TimeTable from "./../views/pages/manage/academicPlanAndTest/academicTimetable/index";
import EditViewTimetable from "./../views/pages/manage/academicPlanAndTest/academicTimetable/editViewTimetable/index";
import LecturePage from "views/pages/manage/academicPlanAndTest/lecture/LecturePage";
import CreateFacultyRoaster from "../views/pages/manage/facultyMasterRoaste/facultyRoaster/CreateFacultyRoaster";
import EditRoaster from "../views/pages/manage/facultyMasterRoaste/facultyRoaster/EditRoaster";
import FacultySchedule from "../views/pages/manage/facultyMasterRoaste/facultyRoaster/facultySchedule/FacultySchedule";
import CreateDaysPlan from "views/pages/manage/masters/daysPlanMaster/createDaysPlan/CreateDaysPlan";
import ViewEditDaysPlan from "views/pages/manage/masters/daysPlanMaster/viewEditDaysPlan/ViewEditDaysPlan";
import ExAakashianConfig from "views/pages/manage/unAssigned/scholarshipMaster/exAakashianConfig/ExAakashianConfig";
import OnlinePackageForm from "views/pages/manage/masters/onlinePackage/form";
import ConcessionApproval from "views/pages/manage/unAssigned/concessionApproval";
import OtherChargesDetails from "views/pages/manage/unAssigned/otherCharges/otherChargeDetails";
import Report from "views/pages/manage/reports/Report";
import SlipDetailForm from "views/pages/manage/unAssigned/depositSlip/slipDetailForm";
import HouseBankMaster from "views/pages/manage/unAssigned/depositSlip/houseBankMaster";
import MaterialMaster from "../views/pages/manage/edpOps/stockManagement/mastersAndMapping/materialMaster/MaterialMaster";
import SAPPeriodStatus from "../views/pages/manage/edpOps/stockManagement/mastersAndMapping/sapPeriodStatus/SAPPeriodStatus";
import SAPProfitCostCentreMapping from "../views/pages/manage/edpOps/stockManagement/mastersAndMapping/sapProfitCostCentreMapping/SAPProfitCostCentreMapping";
import OnBoardingMaterialIdSetup from "../views/pages/manage/edpOps/stockManagement/mastersAndMapping/onBoardingMaterialIdSetup/OnBoardingMaterialIdSetup";
import StockReturn from "../views/pages/manage/edpOps/stockManagement/stockReturn/StockReturn";
import StockReversal from "../views/pages/manage/edpOps/stockManagement/stockReversal/StockReversal";
import StockTxnHistory from "../views/pages/manage/edpOps/stockManagement/stockTxns/StockHistory";
import StockConsumption from "../views/pages/manage/edpOps/stockManagement/stockConsumption";
import BarcodeReconcilliation from "../views/pages/manage/edpOps/stockManagement/barcodeReconcilliation";
import QRCode from "views/pages/manage/unAssigned/qrCode";
import DispatchSchedule from "views/pages/manage/edpOps/stockManagement/dlpStockIssuance/dispatchSchedule/DispatchSchedule";
import IssueStockAndUpdateDispatch from "views/pages/manage/edpOps/stockManagement/dlpStockIssuance/issueStock/IssueStockDlpPage";
import CourierVendorMaster from "views/pages/manage/edpOps/stockManagement/dlpStockIssuance/courierVendorMaster/CourierVendorMaster";

import DODetails from "../views/pages/manage/unAssigned/deliveryOrder/doDetails/DODetails.jsx";
import MiscCourseMaterialMapping from "../views/pages/manage/edpOps/stockManagement/mastersAndMapping/miscCourseMaterialMapping/MiscCourseMaterialMapping";
import LeftOutAdmission from "views/pages/manage/unAssigned/scholarshipMaster/leftOutAdmission/LeftOutAdmission";
import ScholarshipConfig from "views/pages/manage/unAssigned/scholarshipMaster/scholarshipConfig/ScholarshipConfig";
import GateAttendanceDetailCard from "views/pages/manage/gateAttendance/GateAttendanceDetailCard";
import PaymentGatewaysConfigLandingPage from "../views/pages/manage/masters/studentFinancials/paymentGatewaysConfiguration";
import IssueStockDispatchAndHold from "views/pages/manage/edpOps/stockManagement/digitalStockManagement/issueStock";
import SecurityDepositSequencing from "views/pages/manage/masters/studentFinancials/paymentCollectionSetup/securityDepositSequencing";
import CollectionsBankSetup from "../views/pages/manage/masters/studentFinancials/paymentCollectionSetup/bankMaster";
import PosMappingSetup from "../views/pages/manage/masters/studentFinancials/paymentCollectionSetup/posMapping";

const CustomRoutes = (props) => {
  if (!getCookie("token")) {
    return <Redirect to="/auth" />;
  }
  return (
    <Switch>
      <Route
        exact
        path="/admin/exam-management"
        component={() => {
          window.location.href = `${process.env.REACT_APP_BASE_PATH}/exam-management`;
          return null;
        }}
      />

      <Route
        exact
        path="/admin/errorPage/:isError"
        component={ErrorPage}
        key={`errorPage/true`}
      />
      <Route
        exact
        path="/admin/errorPage"
        component={ErrorPage}
        key={`errorPage`}
      />
      <Route
        exact
        path="/admin/networkError"
        component={InternetConnection}
        key={`companyCode/id`}
      />
      {/*<Route exact path="/admin/testComponent" component={TestComponent} key={`companyCode/id`} />*/}
      <Route
        exact
        path="/admin/companyCode/:id"
        component={CompanyCodeRedirector}
        key={`companyCode/id`}
      />
      <Route
        exact
        path="/admin/commonComponent"
        component={CommonComp}
        key={`/admin/commonComponent`}
      />
      <Route
        exact
        path="/admin/courseAttributes/:id"
        component={NewCourseAttr}
        key={`newCourseAttr/id`}
      />
      <Route
        exact
        path="/admin/courseAttributes/:action/:id"
        component={NewCourseAttr}
        key={`newCourseAttr/action/id`}
      />
      <Route
        exact
        path="/admin/programActions/:id"
        component={NewProgramActions}
        key={`newProgramActions/id`}
      />
      <Route
        exact
        path="/admin/termForm/:id"
        component={NewTermForm}
        key={`newTermForm/id`}
      />
      <Route
        exact
        path="/admin/termForm/:action/:id"
        component={NewTermForm}
        key={`newTermForm/action/id`}
      />
      <Route
        exact
        path="/admin/studentInformation/:id"
        component={StudentInformation}
        key={`StudentInformation/id`}
      />
      <Route
        exact
        path="/admin/studentInformation/new/createStudentId"
        component={CreateNewStudent}
        key={`CreateNewStudent`}
      />
      <Route
        exact
        path="/admin/externalOrg"
        component={ExternalOrg}
        key={`newExternalOrg/id`}
      />
      <Route
        exact
        path="/admin/externalOrg/:id"
        component={ExternalOrg}
        key={`newExternalOrg/id`}
      />
      <Route
        exact
        path="/admin/houseBank/:id"
        component={HouseBank}
        key={`newHouseBank/:id`}
      />
      <Route
        exact
        path="/admin/houseBank/:action/:id"
        component={HouseBank}
        key={`newHouseBank/:id`}
      />
      <Route
        exact
        path="/admin/courseHeader/:action"
        component={CourseHeader}
        key={`courseHeader/id`}
      />
      <Route
        exact
        path="/admin/courseCatalog/:action/:id"
        component={CourseHeader}
        key={`courseCatalog/action/id`}
      />
      <Route
        exact
        path="/admin/taxCodeId/taxBreakup/:id"
        component={TaxComponent}
        key={`taxCodeId/taxBreakup/id`}
      />
      <Route
        exact
        path="/admin/taxCodeId/itemType/:id"
        component={ItemType}
        key={`taxCodeId/itemType/id`}
      />
      <Route
        exact
        path="/admin/businessUnit/:id"
        component={BusinessUnitForm}
        key={`businessUnitForm/id`}
      />
      <Route
        exact
        path="/admin/businessUnit/:action/:id"
        component={BusinessUnitEditViewForm}
        key={`businessUnitForm/action/id`}
      />
      <Route
        exact
        path="/admin/GLCode/:id"
        component={GLCode}
        key={`newHouseBank/:id`}
      />
      <Route
        exact
        path="/admin/GLCode/:action/:id"
        component={GLCode}
        key={`/GLCode/action/id`}
      />
      <Route
        exact
        path="/admin/profitCenter"
        component={ProfitMapping}
        key={`ProfitMapping`}
      />
      <Route
        exact
        path="/admin/paymentSchedule/viewInstallment/:id"
        component={ViewInstallment}
        key={`paymentSchedule/viewInstallment/id`}
      />
      <Route
        exact
        path="/admin/paymentSchedule/controlPaymodesFunctionalities/:id"
        component={ControlPaymodesFunctionalities}
        key={`paymentSchedule/controlPaymodesFunctionalities/id`}
      />
      <Route
        exact
        path="/admin/paymentSchedule/setupDates/:id"
        component={SetupDates}
        key={"paymentSchedule/setupDates/id"}
      />
      <Route
        exact
        path="/admin/paymentSchedule/setupDates/:id/:year/setupInstallments/:setupDatesId/:courseYearId"
        component={SetupInstallments}
        key={"/admin/paymentSchedule/setupDates/:id/setupInstallments"}
      />
      <Route
        exact
        path="/admin/scholarship/addSubCategory/:id"
        component={SubCategory}
        key={"scholarship/addSubCategory/id"}
      />
      <Route
        exact
        path="/admin/scholarship/subCategory/addSubSubCategory/:id"
        component={SubSubCategory}
        key={"scholarship/subCategory/addSubSubCategory/id"}
      />
      <Route
        exact
        path="/admin/scholarship/subCategory/subSubCategory/addCourseSecurity/:id"
        component={SetupCourseSecurity}
        key={
          "/admin/scholarship/subCategory/subSubCategory/addCourseSecurity/:id"
        }
      />
      <Route
        exact
        path="/admin/scholarship/subCategory/subSubCategory/courseSecurity/addCourse/:id"
        component={AddCourseSecurity}
        key={
          "scholarship/subCategory/subSubCategory/courseSecurity/addCourse/id"
        }
      />
      <Route
        exact
        path="/admin/CourseCatalogSequence/new"
        component={CourseCatalogSequenceSetup}
        key={"/admin/CourseCatalogSequence/new"}
      />
      <Route
        exact
        path="/admin/CourseCatalogSequence/:action/:id"
        component={ViewAndEditCourseCatalog}
        key={"/admin/CourseCatalogSequence/action"}
      />
      <Route
        exact
        path="/admin/paymentMode/addReceiptAccEntry/:id"
        component={ReceiptAccountEntry}
        key={"/admin/paymentMode/addReceiptAccEntry/id"}
      />
      <Route
        exact
        path="/admin/paymentMode/addRefundAccEntry/:id"
        component={RefundAccountEntry}
        key={"/admin/paymentMode/addRefundAccEntry/id"}
      />
      <Route
        exact
        path="/admin/pricingZone/:action"
        component={NewPricingZone}
        key={"/admin/pricingZone/action"}
      />
      <Route
        exact
        path="/admin/pricingZone/:action/:id"
        component={NewPricingZone}
        key={"/admin/pricingZone/action"}
      />
      <Route
        exact
        // path="/admin/courseFeeInformation/view/:id/:tid/:pid/:businessArea"
        path="/admin/courseFeeInformation/view/:id"
        component={CourseDetails}
        key={"/admin/pricingServiceFeeInformation/view/id"}
      />
      <Route
        exact
        path="/admin/examIdGenerationSearch/:action/:id"
        component={NewExamIdForm}
        key={"/admin/newExamIdGeneration"}
      />
      <Route
        exact
        path="/admin/examIdGenerationForm/:action"
        component={NewExamIdForm}
        key={"/admin/newExamIdGeneration"}
      />
      <Route
        exact
        path="/admin/processInstanceSearch/:action/:pid"
        component={DocumentDetails}
        key={"/admin/feeDetails/view/id"}
      />
      <Route
        exact
        path={[
          "/admin/coursefee/approval/:approvalRole",
          "/admin/coursefee/approval/:approvalRole/:pid",
        ]}
        component={FeeMaster}
        key={"/admin/feeDetails/view/id/approvalRole"}
      />
      <Route
        exact
        path={"/admin/:approval/coursefee/:action/:approvalRole/:pid"}
        component={FeeMaster}
        key={"/admin/feeDetails/view/id/approvalRole"}
      />
      <Route
        exact
        path="/admin/uploadPricingDocument"
        component={PricingUpload}
        key={"/admin/uploadPricingDocument"}
      />
      <Route
        exact
        path="/admin/uploadExamIdBulkDocument"
        component={UploadDocumentBulk}
        key={"/admin/uploadExamIdBulkDocument"}
      />
      <Route
        exact
        path="/admin/examDateConfig/:action/:id"
        component={ConfigrationHeader}
        key={"/admin/examConfig/new/id"}
      />
      <Route
        exact
        path="/admin/examDateConfig/:action"
        component={ConfigrationHeader}
        key={"/admin/examConfig/new"}
      />
      <Route
        exact
        path="/admin/applicationIdGeneration/:action"
        component={ApplicationIdGeneration}
        key={"/admin/applicationIdGeneration"}
      />
      <Route
        exact
        path="/admin/applicationIdGeneration/:action/:id"
        component={ApplicationIdGeneration}
        key={"/admin/applicationIdGeneration/action/id"}
      />
      <Route
        exact
        path="/admin/studentInformation/:studentId/:id/:action"
        component={ApplicationIdGeneration}
        key={"/admin/applicationIdGeneration"}
      />
      <Route
        exact
        path="/admin/studentInformation/:pid/:aid/:action1/document/:action"
        component={UploadProcess}
        key={"/admin/applicationIdGeneration"}
      />

      {/*<Route*/}
      {/*	exact*/}
      {/*	path="/admin/applicationIdGeneration/:action/:id/relatedDocument/:action2"*/}
      {/*	component={relatedDocumentApplicationId}*/}
      {/*	key={'/admin/applicationIdGeneration/action/id/relatedDocument/action2'}*/}
      {/*/>*/}
      <Route
        exact
        path="/admin/documentProcessTag/:action"
        component={DocumentProcessTag}
        key={"/admin/documentProcessTag/action/id"}
      />
      <Route
        exact
        path="/admin/documentProcessTag/:action/:id"
        component={DocumentProcessTag}
        key={"/admin/documentProcessTag/action/id"}
      />

      <Route
        exact
        path="/admin/documentManagementSearch/:action/:pid?/:aid?"
        component={UploadProcess}
        key={"/admin/documentManagementSearch/new"}
      />
      <Route
        exact
        path="/admin/applicationIdGeneration/:action1/:aid/:pid/:action"
        component={UploadProcess}
        key={"/admin/documentManagementSearch/new"}
      />
      <Route
        exact
        path="/admin/bulkUpload"
        component={DocumentBulkUploadSearch}
        key={"/admin/bulkUpload"}
      />
      <Route
        exact
        path="/admin/bulkUpload/:action"
        component={DocumentBulkUpload}
        key={"/admin/bulkUpload/new"}
      />
      <Route
        exact
        path="/admin/bank/:id"
        component={Bank}
        key={`newBankCode/:id`}
      />
      <Route
        exact
        path="/admin/bank/:action/:id"
        component={Bank}
        key={`newBankCode/:id`}
      />
      <Route
        exact
        path="/admin/itemTypeSequence/:action"
        component={ItemTypeSequence}
        key={"/admin/itemTypeSequence/action"}
      />

      <Route
        exact
        path="/admin/itemTypeSequence/:action/:id"
        component={ItemTypeSequence}
        key={"/admin/itemTypeSequence/action/id"}
      />
      <Route
        exact
        path="/admin/feeSetupRules/:action"
        component={FeeSetupRules}
        key={"/admin/feeSetupRules/action"}
      />
      <Route
        exact
        path="/admin/feeSetupRules/:action/:id"
        component={FeeSetupRules}
        key={"/admin/feeSetupRules/action/:id"}
      />
      <Route
        exact
        path="/admin/examIDbulkSearch/new"
        component={ExamIDbulkUpload}
        key={`/examIDbulkSearch/new`}
      />
      <Route
        exact
        path="/admin/building/:id/floors"
        component={Floor}
        key={`/admin/building/id/floors`}
      />
      <Route
        exact
        path="/admin/feeCalculator/viewConcessionDetails"
        component={ItemTypeDetailsAndDescriptionTable}
        key={`/admin/building/id/floors`}
      />
      <Route
        exact
        path="/admin/floor/:buildingId/rooms/:floorId"
        component={Room}
        key={`/admin/floor/id/rooms`}
      />
      <Route
        exact
        path="/admin/roleToProcess/assignPermission"
        component={AssignPermission}
        key={`/admin/roleToProcess/assignPermission`}
      />
      <Route
        exact
        path="/admin/internalTestResult/:action"
        component={InternalTestResult}
        key={`/admin/internalTestResult/action`}
      />
      <Route
        exact
        path="/admin/internalTestResult/:action/:id"
        component={InternalTestResult}
        key={`/admin/internalTestResult/action`}
      />
      <Route
        exact
        path={["/admin/template/:action", "/admin/template/:action/:id"]}
        component={TemplateUploadForm}
        key={`/admin/template/:action`}
      />
      <Route
        exact
        path={[
          "/admin/courseFeeSetup/:action",
          "/admin/courseFeeSetup/:action/:id",
        ]}
        component={NewCourseSetup}
        key={`/admin/courseSetup/new`}
      />

      <Route
        exact
        path={"/admin/userProfile/viewProfile/:id"}
        component={ViewUserProfile}
        key={`/admin/userProfile/viewProfile/:project/:id`}
      />

      <Route
        exact
        path={[
          "/admin/userProfile/:action",
          "/admin/userProfile/:action/:id",
          "/admin/empProfile/:action/:id",
        ]}
        component={CreateProfile}
        key={`/admin/userProfile/newProfile`}
      />

      <Route
        exact
        path={["/admin/role/:action", "/admin/role/:action/:id"]}
        component={CreateRole}
        key={`/admin/role/new`}
      />

      <Route
        exact
        path={[
          "/admin/securityGroup/:action",
          "/admin/securityGroup/:action/:id",
        ]}
        component={CreateSecurityGroup}
        key={`/admin/securityGroup/:action`}
      />

      <Route
        exact
        path={
          "/admin/consession/:type/:mode/:categoryId?/:subCategoryId?/:subSubCategoryId?"
        }
        component={ScholarshipMaster}
        key={
          "/admin/consession/:type/:mode/:categoryId?/:subCategoryId?/:subSubCategoryId?"
        }
      />

      {/* <Route exact path={'/admin/consession/lumpsumConfig'} component={Lumpsum} key={'/admin/consession/lumpsumConfig'} /> */}

      <Route
        exact
        path={"/admin/loanSchemeSetup/tab/:tabIndex"}
        component={LoanSchemeSetup}
        key={"/admin/loanSchemeSetup/tab/:tabIndex"}
      />

      <Route
        exact
        path={"/admin/concessionApproval/:stage"}
        component={ConcessionApproval}
        key={"/admin/concessionApproval/:stage"}
      />

      <Route
        exact
        path={"/admin/bankAccount/slipDetailForm"}
        component={SlipDetailForm}
        key={"/admin/bankAccount/slipDetailsForm"}
      />

      <Route
        exact
        path={"/admin/bankAccount/manageQRCode"}
        component={QRCode}
        key={"/admin/bankAccount/manageQRCode"}
      />

      <Route
        exact
        path={"/admin/bankAccount/houseBankMaster"}
        component={HouseBankMaster}
        key={"/admin/bankAccount/houseBankMaster"}
      />

      <Route
        exact
        path={"/admin/studentCharges/details/:id/:action"}
        component={StudentChargesDetails}
        key={"/admin/studentCharges/details/:id"}
      />
      <Route
        exact
        path={"/admin/studentOtherCharges/details/:id/:action"}
        component={OtherChargesDetails}
        key={"/admin/otherCharges/details/:id"}
      />
      <Route
        exact
        path={"/admin/facultyRoaster/faculty/:id"}
        component={EditRoaster}
        key={"/admin/facultyRoaster/faculty/:id"}
      />

      <Route
        exact
        path={"/admin/facultyRoaster/faculty/:id/schedule"}
        component={FacultySchedule}
        key={"/admin/facultyRoaster/faculty/:id/schedule"}
      />

      <Route
        exact
        path={"/admin/facultyRoaster/newRoaster"}
        component={CreateFacultyRoaster}
        key={"/admin/facultyRoaster/newRoaster"}
      />

      <Route
        exact
        // path={'/admin/onlinePackage/create'}
        path={[
          "/admin/onlinePackage/:action",
          "/admin/onlinePackage/:action/:id",
          "/admin/onlinePackage/:action/:id",
        ]}
        component={OnlinePackageForm}
        key={"/admin/onlinePackage/create"}
      />

      <Route
        exact
        path="/admin/examSyllabus/:action"
        component={ExamSyllabusNew}
        key={`/admin/examSyllabus/action`}
      />
      <Route
        exact
        path="/admin/examSyllabus/:action"
        component={ExamSyllabusNew}
        key={`/admin/examSyllabus/action`}
      />
      <Route
        exact
        path="/admin/edp/classSection/:action"
        component={NewClassSection}
        key="/admin/edp/classSection/new"
      />
      <Route
        exact
        path="/admin/edp/classSection/:action/:id"
        component={NewClassSection}
        key="/admin/edp/classSection/edit"
      />
      <Route
        exact
        path={"/admin/edp/batch/:action/:id"}
        component={BatchViewEdit}
        key="/admin/edp/batch/form"
      />
      <Route
        exact
        path={"/admin/edp/createBatch/:id"}
        component={CreateBatch}
        key="/admin/edp/createBatch/copyCreate"
      />
      <Route
        exact
        path={"/admin/edp/allocateStudents/:id"}
        component={AllocateStudents}
        key="/admin/edp/allocateStudents"
      />
      <Route
        exact
        path={"/admin/edp/deallocateStudents/:id"}
        component={DeallocateStudents}
        key="/admin/edp/deallocateStudents"
      />
      <Route
        exact
        path="/admin/dashboard"
        component={HomePage}
        key={`/admin/dashboard`}
      />
      <Route
        exact
        path="/admin/create-template"
        component={MessageTemplateForm}
        key={`/admin/create-template`}
      />
      <Route
        exact
        path="/admin/edp/studentDetails/:psid"
        component={StudentDetails}
        key={`/admin/edp/studentDetails`}
      />
      <Route
        exact
        path="/admin/testScheduling/academicPlan/view/:id"
        component={ViewAcademicPlan}
        key={`/admin/testScheduling/viewAcademicPlan/view/:id`}
      />
      <Route
        exact
        path="/admin/testScheduling/academicPlan/view/:id/tests"
        component={ViewTests}
        key={`/admin/testScheduling/test/:planId/tests`}
      />
      <Route
        exact
        path="/admin/testScheduling/timetable/create/:id"
        component={TimeTable}
        key={`/admin/testScheduling/timetable/create/:id`}
      />
      <Route
        exact
        path="/admin/testScheduling/timetable/:action/:id"
        component={EditViewTimetable}
        key={`/admin/testScheduling/timetable/view/:id`}
      />
      <Route
        exact
        path="/admin/testScheduling/academicPlan/:planId/lecture"
        component={LecturePage}
        key={`/admin/testScheduling/academicPlan/view/:id/lecture`}
      />
      <Route
        exact
        path="/admin/master/daysPlan/:action"
        component={CreateDaysPlan}
        key={`/admin/master/daysPlan/create`}
      />
      <Route
        exact
        path="/admin/master/daysPlan/:action/:daysPlanId"
        component={ViewEditDaysPlan}
        key={`/admin/master/daysPlan/:action/:daysPlanId`}
      />
      <Route
        exact
        path="/admin/consession/scholarshipConfig"
        component={ScholarshipConfig}
        key={`/admin/consession/scholarshipConfig`}
      />
      <Route
        exact
        path="/admin/consession/leftOutAdmissionConfig"
        component={LeftOutAdmission}
        key={`/admin/consession/leftOutAdmissionConfig`}
      />
      <Route
        exact
        path="/admin/consession/exAakashianConfig"
        component={ExAakashianConfig}
        key={`/admin/consession/exAakashianConfig`}
      />
      <Route
        exact
        path="/admin/consession/exAakashianConfig/:action/:id"
        component={ExAakashianConfig}
        key={`/admin/consession/exAakashianConfig/:action/:id`}
      />
      <Route
        exact
        path="/admin/testScheduling/duplicatePlan/:id"
        component={CreateAcademicPlan}
        key={`/admin/testScheduling/duplicatePlan/:id`}
      />
      <Route
        exact
        path="/admin/reports/reportlist/:id/:report"
        component={Report}
        key={"/admin/reports/reportlist/:id/:report"}
      />
      <Route
        exact
        path="/admin/bankAccount/slipDetailForm/:id"
        component={SlipDetailForm}
        key={"/admin/bankAccount/slipDetailForm/:id"}
      />
      <Route
        exact
        path="/admin/edp/stockInventoryMaster/materialMaster"
        component={MaterialMaster}
        key={"/admin/edp/stockInventoryMaster/materialMaster"}
      />
      <Route
        exact
        path="/admin/edp/stockInventoryMaster/materialIdSetup"
        component={OnBoardingMaterialIdSetup}
        key={"/admin/edp/stockInventoryMaster/materialIdSetup"}
      />
      <Route
        exact
        path="/admin/edp/stockInventoryMaster/sapPeriodStatus"
        component={SAPPeriodStatus}
        key={"/admin/edp/stockInventoryMaster/sapPeriodStatus"}
      />
      <Route
        exact
        path="/admin/edp/stockInventoryMaster/sapProfitCostCentreMapping"
        component={SAPProfitCostCentreMapping}
        key={"/admin/edp/stockInventoryMaster/sapProfitCostCentreMapping"}
      />
      <Route
        exact
        path="/admin/edp/stockManagement/stockReturn"
        component={StockReturn}
        key={"/admin/edp/stockManagement/stockReturn"}
      />
      <Route
        exact
        path="/admin/edp/stockManagement/stockReversal"
        component={StockReversal}
        key={"/admin/edp/stockManagement/stockReversal"}
      />
      <Route
        exact
        path="/admin/edp/stockManagement/stockTxnHistory/:academicCareer/:id/:business/:buValue/:type"
        component={StockTxnHistory}
        key={"/admin/edp/stockManagement/stockTxnHistory"}
      />
      <Route
        exact
        path="/admin/edp/stockManagement/StockConsumption"
        component={StockConsumption}
        key={"/admin/edp/stockManagement/StockConsumption"}
      />
      <Route
        exact
        path="/admin/edp/dlpStockManagement/StockConsumption/:id"
        component={StockConsumption}
        key={"/admin/edp/dlpStockManagement/StockConsumption"}
      />
      <Route
        exact
        path="/admin/edp/digitalStockManagement/StockConsumption/:id"
        component={StockConsumption}
        key={"/admin/edp/digitalStockManagement/StockConsumption"}
      />
      <Route
        exact
        path="/admin/doDetails"
        component={DODetails}
        key={"/admin/doDetails"}
      />
      <Route
        exact
        path="/admin/edp/stockInventoryMaster/miscCourseMaterialMapping"
        component={MiscCourseMaterialMapping}
        key={"/admin/edp/stockInventoryMaster/miscCourseMaterialMapping"}
      />
      <Route
        exact
        path="/admin/edp/dlpStockManagement/dispatchSchedule/:academicCareer"
        component={DispatchSchedule}
        key={"/admin/edp/dlpStockManagement/dispatchSchedule"}
      />
      <Route
        exact
        path="/admin/edp/dlpStockManagement/IssueStockAndUpdateDispatch"
        component={IssueStockAndUpdateDispatch}
        key={"/admin/edp/dlpStockManagement/IssueStockAndUpdateDispatch"}
      />
      <Route
        exact
        path="/admin/edp/dlpStockManagement/courierVendorMaster/:academicCareer"
        component={CourierVendorMaster}
        key={"/admin/edp/dlpStockManagement/courierVendorMaster"}
      />
      {/* <Route exact path='/admin/gateAttendance' component={GateAttendance} key={'/admin/gateAttendance'}/> */}
      <Route
        exact
        path="/admin/gateAttendance/studentInfo"
        component={GateAttendanceDetailCard}
        key={"/admin/gateAttendance/studentInfo"}
      />
      <Route
        exact
        path="/admin/bankAccount/paymentGatewayConfiguration"
        component={PaymentGatewaysConfigLandingPage}
        key={"/admin/bankAccount/paymentGatewayConfiguration"}
      />

      <Route
        exact
        path="/admin/doDetails"
        component={DODetails}
        key={"/admin/doDetails"}
      />
      <Route
        exact
        path="/admin/edp/stockInventoryMaster/miscCourseMaterialMapping"
        component={MiscCourseMaterialMapping}
        key={"/admin/edp/stockInventoryMaster/miscCourseMaterialMapping"}
      />
      <Route
        exact
        path="/admin/edp/digitalStockManagement/dispatchSchedule/:academicCareer"
        component={DispatchSchedule}
        key={"/admin/edp/digitalStockManagement/dispatchSchedule"}
      />
      <Route
        exact
        path="/admin/edp/digitalStockManagement/IssueStockAndUpdateDispatch"
        component={IssueStockDispatchAndHold}
        key={"/admin/edp/digitalStockManagement/IssueStockAndUpdateDispatch"}
      />
      <Route
        exact
        path="/admin/edp/digitalStockManagement/courierVendorMaster/:academicCareer"
        component={CourierVendorMaster}
        key={"/admin/edp/digitalStockManagement/courierVendorMaster"}
      />
      {/* <Route exact path='/admin/gateAttendance' component={GateAttendance} key={'/admin/gateAttendance'}/> */}
      <Route
        exact
        path="/admin/gateAttendance/studentInfo"
        component={GateAttendanceDetailCard}
        key={"/admin/gateAttendance/studentInfo"}
      />
      <Route
        exact
        path="/admin/bankAccount/paymentGatewayConfiguration"
        component={PaymentGatewaysConfigLandingPage}
        key={"/admin/bankAccount/paymentGatewayConfiguration"}
      />
      <Route
        exact
        path="/admin/edp/stockManagement/barcodeReconcilliation"
        component={BarcodeReconcilliation}
        key={"/admin/edp/stockManagement/barcodeReconcilliation"}
      />
      <Route
        exact
        path="/admin/master/studentFinanceSetup/paymentCollection/sdSequence"
        component={SecurityDepositSequencing}
        key={"/admin/master/studentFinanceSetup/paymentCollection/sdSequence"}
      />
      <Route
        exact
        path="/admin/master/studentFinanceSetup/paymentCollection/bankMaster"
        component={CollectionsBankSetup}
        key={"/admin/master/studentFinanceSetup/paymentCollection/bankMaster"}
      />
      <Route
        exact
        path="/admin/master/studentFinanceSetup/paymentCollection/posMapping"
        component={PosMappingSetup}
        key={"/admin/master/studentFinanceSetup/paymentCollection/posMapping"}
      />

      {/* <Route exact path="/admin/testScheduling/timetable//:id" component={EditViewTimetable} key={`/admin/testScheduling/timetable/view/:id`} /> */}

      <Route
        exact
        path="/admin/pageNotFound"
        component={PageNotFound}
        key={`/admin/pageNotFound`}
      />
      {props.getRoutes()}

      {props.routesDetails && <Redirect from="*" to="/admin/pageNotFound" />}
    </Switch>
  );
};

export default CustomRoutes;
