import React from "react";
import { Card, Table, CardHeader } from "reactstrap";
import { MasterHeaderAuto } from "../../../../common/commonComponents/masterHeader/masterHeader";
import { NewRow } from '../../../../common/utils/methods/commonMethods/utilityMethod'

const FeeBreakUpTable = (props) => {
  const { data, itemTypeData=[] } = props;
  const headerList = [
    { name: "S.No" },
    { name: "Item Type" },
    { name: "Installment number" },
    { name: "Due Date" },
    { name: "Base Amount" },
    { name: "Tax" },
    { name: "Final Amount" },
    {name: "CASH"},
    {name: "CHKL"},
    { name: "Year" },
  ];

  const listToRender = [
    'sno',
    'itemType',
    'installmentNumber',
    'dueDate',
    'baseAmount',
    'tax',
    'finalAmount',
    'cash',
    'cheque',
    'year'
  ];

  const itemToBeRenderFromMaster = []
  const mastersList = [[],itemTypeData]
  const propertyList = ['','itemTypeId']
  console.log("itemxxx",itemTypeData,propertyList,data)

  return (
    <div className="p-2 table-responsive-layout">
      <Table className="align-items-center table-flush">
        <MasterHeaderAuto headerList={headerList} />
        <tbody className="list">
        {data ? 
            data.map((el, index) => {
              return (
                <NewRow data={{...el,sno : index+1}} 
                  listToBeRendered={listToRender} 
                  itemToBeRenderFromMaster={itemToBeRenderFromMaster}
                  arrList={mastersList}
                  propertyList={propertyList}
                />
              )}) 
            : <span>No Record Found</span>}
        </tbody>
      </Table>
    </div>
  );
};

export default FeeBreakUpTable;
