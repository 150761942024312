import React, { useState } from 'react';
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Popover,
    Typography,
} from '@mui/material';
import CustomButton from '../../../../../../components/CustomButton';


const FieldHandler = (props) => {


    const { anchorEl, setAnchorEl, hidableColumns, onSaveClick } = props;


    const [selectedCols,setSelectedCols] = useState([]);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCheckboxClick = (e,cl) => {
        if(e.target.checked){
            const temp = [...selectedCols,cl];
            setSelectedCols([...temp]);
        }else{
            const temp= [...selectedCols];
            temp.splice(selectedCols.findIndex(i => i.field === cl.field),1);
            setSelectedCols([...temp])
        }
        
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleSaveClick = () => {
        onSaveClick(selectedCols);
        setAnchorEl(null)
    }

    return (
        <div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{ padding: '16px' }}>
                    <FormGroup>
                        {hidableColumns?.map(col => {
                            return  <FormControlLabel
                            control={<Checkbox checked={selectedCols?.findIndex(i => i.field === col.field) > -1} onChange={(e)=>{handleCheckboxClick(e,col)}} style={{ color: "#00B0F5", padding: 0 }}/>}
                            label={<Typography style={{ color: '#333333', fontSize: 14 }}>{col.headerName}</Typography>}
                            // onClick={(e)=>{handleCheckboxClick(e,col)}}
                            key={col.field}
                        />
                        })}
                      
                        {/* Add more checkboxes as needed */}
                    </FormGroup>
                    <CustomButton
                        className={'regular-small  w-100 ml-0 no-ml-btn'}
                        content={'Save'}
                        // forTable={true}
                        permissionType={'R'}
                        permissionSet={'R'}
                        onClick={handleSaveClick}
                    />
                </div>
            </Popover>
        </div>
    );
};

export default FieldHandler;
