import React from 'react';
import {Button, Card, Table} from "reactstrap";
import {MasterHeaderAuto} from "../../../common/commonComponents/masterHeader/masterHeader";
import {getDispValForReactSelectByOneID} from "../../../common/utils/methods/commonMethods/utilityMethod";
import {CustomTableRow, StatusButton} from "../../../common/commonComponents/TableComponents";
import CustomButton from "../../../../../../components/CustomButton/index";

const TableData =(props)=>{
    const {item,index,allTermData,allAcademicCareerData,permissionSet} = props;
    return(
        <CustomTableRow>
            <td className="text-center p-2 mx-1">
                {
                    getDispValForReactSelectByOneID(item.academicCareerId, 'DispValue', allAcademicCareerData)
                }
            </td>
            <td className="text-center p-2 mx-1">
                {
                    getDispValForReactSelectByOneID(item.term, 'DispValue', allTermData)
                }
            </td>
            <td className="text-center p-2 mx-1">
                <StatusButton status={item.status}/>
            </td>
            {
                permissionSet && permissionSet.includes('R')|| permissionSet.includes('U') ?
                    <td className="text-center p-2 mx-1">
                        <CustomButton
                            key={'ViewButton'}
                            permissionType={'R'}
                            icon={true}
                            forTable={true}
                            onClick={() => {
                                window.open(`${document.URL}/view/${item.id}`, '_blank');
                            }}
                            permissionSet={permissionSet}
                        />
                        <CustomButton
                            key={'EditButton'}
                            permissionType={'U'}
                            icon={true}
                            forTable={true}
                            onClick={() => {
                                window.open(`${document.URL}/edit/${item.id}`, '_blank');
                            }}
                            permissionSet={permissionSet}
                        />
                    </td>
                    :null
            }
        </CustomTableRow>
    )
}
const FeeSetupRulesSearchTable =(props)=>{
    const {
        tableData,
        allAcademicCareerData,
        allTermData,searchHeaderList,
        permissionSet
    } = props;

    return(
        <Card>
            <div id="questionTable" className="table-responsive" >
                <Table className="align-items-center table-flush ">
                    <MasterHeaderAuto permissionSet={permissionSet}  headerList={permissionSet.includes('R')|| permissionSet.includes('U')? searchHeaderList :searchHeaderList.slice(0,3)} />
                    <tbody className="list" key={'courseCatalogSequenceSearch'}>
                    {
                        tableData && tableData.length > 0 &&
                        tableData.map((item,index)=>
                            <TableData item={item}
                                       allAcademicCareerData={allAcademicCareerData}
                                       allTermData={allTermData}
                                       index={index}
                                       permissionSet={permissionSet}
                            />
                        )
                    }
                    </tbody>
                </Table>
            </div>
        </Card>
    );
}

export default FeeSetupRulesSearchTable;