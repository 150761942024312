import React, { useState, useEffect, useContext } from 'react';
import TermFormTable from './termFormTable';
import { getAPI, masterServiceBaseUrl } from 'services/http';
import { failureToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import { GetPagination } from '../../../common/commonComponents/pagination/pagination';
import { PermissionContext, RolePermissions } from 'appContext';
import { pages } from '../../../common/constant';
import { PermisionDenied } from '../../../common/commonComponents';
import TermTableSearchForm from './TermTableSearchForm';
import { fetchAllPostPromisedData } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { getPaginatedData } from "../../../common/utils/methods/commonMethods/paginationMethod";

const apiEndPoints = {
	search: `${masterServiceBaseUrl}/termMapping/searchTermMapping`,
}

const searchDataObject = {
	academicCareer: '',
	term: '',
}

let isNextCallPossible = true;

const pageSize = 10;
let page = 0;

const TermForm = () => {
	const [classArray, setclassArray] = useState([]);
	const [apiloader, setapiloader] = useState(false);
	const [state, setstate] = useState([]);
	const [termData, setTermData] = useState([]);
	const [academicData, setAcademicData] = useState([]);
	const [searchData, setSearchData] = useState(searchDataObject);
	const [serverData, setServerData] = useState(null);
	const [savedSearchParams, setSavedSeachParams] = useState(null);
	const [isPaginationFromServer, setisPaginationFromServer] = useState(true);
	const [pagination, setpagination] = useState({
		currentPage: 1,
		nextPage: null,
		previousPage: null,
		totalPage: null,
	});

	const permissions = useContext(PermissionContext);

	const userPermissions = RolePermissions(
		permissions,
		pages['termFormTable']['id']
	);

	const fetchClasses = async (url) => {
		setapiloader(true);
		getAPI(
			`${masterServiceBaseUrl}/termMapping/getAllTermMappingForSearch/${page}`,
			(data) => {
				setServerData(data.data);
				pagination.currentPage = 1;
				getPaginatedData(data.data, pageSize, pagination, setpagination, setapiloader, setclassArray);
			},
			(data) => {
				setServerData([]);
				failureToast(data['message']);
			}
		);
	};
	const nextPage = () => {
		setclassArray(
			state.slice(
				(pagination.currentPage + 1) * pageSize - pageSize,
				(pagination.currentPage + 1) * pageSize
			)
		);
		let n = pagination;
		n['currentPage'] = n['currentPage'] + 1;
		n['previousPage'] = n.currentPage;
		setpagination(n);
	};
	const firstPage = () => {
		setclassArray(state.slice(1 * pageSize - pageSize, 1 * pageSize));
		let n = pagination;
		n['currentPage'] = 1;
		n['previousPage'] = null;
		setpagination(n);
	};
	const lastPage = () => {
		setclassArray(
			state.slice(
				pagination.totalPage * pageSize - pageSize,
				pagination.totalPage * pageSize
			)
		);
		let n = pagination;
		n['currentPage'] = n.totalPage;
		n['previousPage'] = n.totalPage - 1;
		setpagination(n);
	};
	const previousPage = () => {
		if (pagination.currentPage != 1) {
			setclassArray(
				state.slice(
					(pagination.currentPage - 1) * pageSize - pageSize,
					(pagination.currentPage - 1) * pageSize
				)
			);
			let n = pagination;
			n['currentPage'] = n['currentPage'] - 1;
			if (n['currentPage'] == 1) {
				n['previousPage'] = null;
			} else {
				n['previousPage'] = n.currentPage;
			}
			setpagination(n);
		}
	};

	const getAllTerm = () => {
		setapiloader(true);
		getAPI(
			`${masterServiceBaseUrl}/term/getAllTerm`,
			(data) => {
				let tempData;
				if (data.data) {
					tempData = data.data.map(item => ({ ...item, text: item.termDispValue }))
					setTermData(tempData.filter(item => item.status === "ACTIVE"));
				}
				setapiloader(false);
			},
			(data) => {
				failureToast(data['message']);
				setapiloader(false);
			}
		);
	};

	const getAllAcademicCareer = () => {
		setapiloader(true);
		getAPI(
			`${masterServiceBaseUrl}/academicCareer/getAllAcademicCareer`,
			(data) => {
				let tempData;
				if (data.data) {
					tempData = data.data.map(item => ({ ...item, text: item.sapAcademicCareer }))
					setAcademicData(tempData.filter(item => item.status === "ACTIVE"));
				}
				setapiloader(false);
			},
			(data) => {
				failureToast(data['message']);
				setapiloader(false);
			}
		);
	};

	const resetHandler = () => {
		page = 0;
		setSearchData(searchDataObject);
		fetchClasses(masterServiceBaseUrl + '/termMapping/getAllTermMappingForSearch');
		setSavedSeachParams(null);
		setisPaginationFromServer(true);
	}

	const updateSearchData = (key, value) => {
		setSearchData(preState => ({ ...preState, [key]: value }))
	}

	const searchHandler = async () => {
		try {

			if (searchData.academicCareer === '' && searchData.term === '') {
				failureToast("Atleast one search value is required to be selected")
				return
			}

			setapiloader(true);
			page = 0;
			const resp = await fetchAllPostPromisedData(`${apiEndPoints.search}/0`, { academicCareerId: searchData.academicCareer ?? null, termId: searchData.term ?? null })
			if (resp?.code === 200) {
				setServerData(resp.data);
				pagination.currentPage = 1;
				getPaginatedData(resp.data, pageSize, pagination, setpagination, setapiloader, setclassArray);
				setSavedSeachParams({ academicCareerId: searchData.academicCareer ?? null, termId: searchData.term ?? null });
				isNextCallPossible = resp?.data?.length == 50 ? true : false;
			} else {
				setServerData(null);
				getPaginatedData([], pageSize, pagination, setpagination, setapiloader, setclassArray);
				setSavedSeachParams(null);
				setisPaginationFromServer(true);
			}
		} catch (e) {
			console.log('Error', e);
		}
	};

	const fetchNext = async () => {
		if (isNextCallPossible) {
			if (savedSearchParams) {
				setapiloader(true);
				page = page + 1;
				const resp = await fetchAllPostPromisedData(`${apiEndPoints.search}/${page}`, savedSearchParams)
				if (resp?.code === 200 && resp?.data?.length > 0) {
					const updatedData = [...serverData, ...resp.data]
					setServerData(updatedData);
					getPaginatedData(updatedData, pageSize, pagination, setpagination, setapiloader, setclassArray);
					isNextCallPossible = resp?.data?.length == 50 ? true : false;
				} else {
					isNextCallPossible = false;
					setapiloader(false);
				}
			} else {
				setapiloader(true);
				page = page + 1;
				getAPI(
					`${masterServiceBaseUrl}/termMapping/getAllTermMappingForSearch/${page}`,
					(data) => {
						const updatedData = [...serverData, ...data.data]
						setServerData(updatedData);
						// setServerData(data.data);
						getPaginatedData(updatedData, pageSize, pagination, setpagination, setapiloader, setclassArray);
						isNextCallPossible = data?.data?.length == 50 ? true : false;
					},
					(data) => {
						isNextCallPossible = false;
						setapiloader(false);
						setServerData([]);
						failureToast(data['message']);
					}
				);
			}
		}
	}

	useEffect(() => {
		page = 0
		fetchClasses(masterServiceBaseUrl + '/termMapping/getAllTermMappingForSearch');
		getAllTerm();
		getAllAcademicCareer();
	}, []);
	return (
		<>
			{userPermissions ? (
				<>
					<TermFormTable
						academicData={academicData}
						termData={termData}
						previousPage={previousPage}
						fetchClasses={fetchClasses}
						setclassArray={setclassArray}
						classArray={classArray}
						apiloader={apiloader}
						userPermissions={userPermissions}
					>
						<TermTableSearchForm
							searchData={searchData}
							searchHandler={searchHandler}
							resetHandler={resetHandler}
							updateSearchData={updateSearchData}
							academicData={academicData}
							termData={termData}
							userPermissions={userPermissions}
						/>
					</TermFormTable>
					{pagination.totalPage > 1 && !apiloader ? (
						<GetPagination
							isSaveVisible={false}
							setclassArray={setclassArray}
							pageSize={pageSize}
							pagination={pagination}
							setpagination={setpagination}
							state={serverData}
							isPaginationFromServer={isPaginationFromServer}
							isFetching={apiloader}
							fetchNext={fetchNext}
							isNextCallPossible={isNextCallPossible}
						/>
					) : null}
				</>
			) : (
				<PermisionDenied />
			)}
		</>
	);
};

export default TermForm;
