import React from "react";
import { Col, Row,Form} from "reactstrap";
import CommonInput from "../../../common/formFeilds/input/commonInput";
import { masterServiceBaseUrl} from "services/http";
import {fetchDetails} from "../../../common/utils/methods/commonMethods/utilityMethod";


const ItemTypeSequenceSearchForm = (props) =>{
    const {searchData,courseType,updateSearchData,statusData,setCompanyCode,term,companyCode,groupCode,academicCareer,label,placeholder} = props;
    return (
        <Form>
            <Row>
                <Col md={4}>
                    <CommonInput
                        label={label.businessGroup}
                        data={groupCode}
                        type={'reactSelect'}
                        placeHolder={placeholder.businessGroup}
                        onChange={(value)=>{
                            if(value) {
                                updateSearchData('groupCodeId',value);
                                updateSearchData('companyCodeId', null);
                                updateSearchData('academicCareerId', null);
                                fetchDetails(masterServiceBaseUrl + `/companyCode/getCompanyCodeByGroup/${value}`, setCompanyCode, 'companyCode', 'reactSelect');
                            }
                        }}
                        defaultValue={searchData["groupCodeId"]}
                        isDisabled={false}
                    />
                </Col>
                <Col md={4}>
                    <CommonInput
                        label={label.companyCode}
                        data={companyCode}
                        type={'reactSelect'}
                        onChange={(value)=>{
                            if(value){
                                updateSearchData('companyCodeId', value);
                                updateSearchData('academicCareerId', null);
                            }
                        }}
                        placeHolder={placeholder.companyCode}
                        defaultValue={searchData["companyCodeId"]}
                        isDisabled={false}
                    />
                </Col>
                <Col md={4}>
                    <CommonInput
                        label={label.term}
                        data={term}
                        type={'reactSelect'}
                        placeHolder={placeholder.term}
                        onChange={(value)=> {
                            if (value) {
                                updateSearchData('termId', value);
                            }
                        }}
                        defaultValue={searchData["termId"]}
                        isDisabled={false}
                    />
                </Col>
                <Col md={4}>
                    <CommonInput
                        label={label.academicCareer}
                        mandatory={true}
                        data={academicCareer}
                        type={'reactSelect'}
                        placeHolder={placeholder.academicCareer}
                        onChange={(value)=>{
                            if(value){
                                updateSearchData('academicCareerId', value);
                            }
                        }}
                        defaultValue={searchData["academicCareerId"]}
                        isDisabled={false}
                    />
                </Col>
                <Col md={4}>
                    <CommonInput
                        label={label.courseType}
                        mandatory={true}
                        data={courseType}
                        type={'reactSelect'}
                        placeHolder={placeholder.courseType}
                        onChange={(value)=>{
                            if(value){
                                updateSearchData('courseType', value);
                            }
                        }}
                        defaultValue={searchData["courseType"]}
                        isDisabled={false}
                    />
                </Col>
                <Col md={4}>
                    <CommonInput
                        label={label.status}
                        type={'reactSelect'}
                        data={statusData}
                        placeHolder={placeholder.status}
                        onChange={(value)=> {
                            if(value){
                                updateSearchData('status', value)
                            }
                        }}
                        defaultValue={searchData["status"]}
                        isDisabled={false}
                    />
                </Col>
            </Row>
        </Form>
    );
}
export default ItemTypeSequenceSearchForm