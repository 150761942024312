import { Dialog } from "@mui/material";
import React from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import { ReactComponent as WarningIcon } from "../../../../../assets/img/svg/yellowWarning.svg";
import { ReactComponent as CrossIcon } from "../../../../../assets/img/svg/CrossIcon.svg";
import AButton from "../../common/form-fields-mui/AButton";
import CustomChip from "../../common/customChip/CustomChip";

const buttonStyles = {
  width: "9rem",
  borderRadius: "12px",
  fontSize: "16px",
  height: "44px",
};

const noteDivStyle = {
  width: "Fill (462px)",
  height: "Hug (64px)",
  padding: "12px",
  borderRadius: "8px",
  gap: "10px",
  background: "#FFEDC8",
};

const noteText = {
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  letterSpacing: "0em",
  textAlign: "left",
};

const CMSSubjectWarning = ({
  open,
  setOpen,
  onAgree = () => {},
  cmsSubjectMismatch,
  doNotDuplicateHandler
}) => {
  const { isExtra, subjects } = cmsSubjectMismatch;
  const displayStatus = "publish";

  const handleClose = () => setOpen(false);

  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={{ padding: "1.5rem", width: "30rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "2rem",
            marginBottom: "10px",
            color: "black",
          }}
        >
          <WarningIcon />
          {/* <MdCancel onClick={handleClose} /> */}
          <CrossIcon onClick={handleClose} />
        </div>
        <div>
          <div
            style={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "24px",
              color: "#000000",
            }}
            className="mt-3"
          >
            {cmsSubjectMismatch?.isExtra
              ? "Additional subjects in CMS package "
              : "Subjects missing in CMS package"}
          </div>
          <div
            style={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#414141",
            }}
            className="my-3"
          >
            {isExtra && isExtra
              ? "Below subjects do not exist in the Academic plan being duplicated. Lectures will have to be added in the below additional subjects"
              : 'Following subjects are missing in the CMS package. Please add the missing subjects in CMS package to  duplicate the Lectures of these subjects.'}
          </div>
        </div>
        {subjects &&
          subjects.map((subject) => {
            return (
              <div
                className="d-flex justify-content-between mb-1"
                key={subject?.courseId}
              >
                <div>{subject?.subjectName}</div>
                <CustomChip
                  text={isExtra ? "New" : "Missing in cms"}
                  textColor={isExtra ? "#FFFFFF" : "#B32306"}
                  bgColor={isExtra ? "#572AC8" : "#F9D9D7"}
                />
              </div>
            );
          })}

        <div
          className="heading-4"
          style={{ fontFamily: "sans-serif", fontWeight: "600" }}
        >
          What do you want to do next?
        </div>
        <div className=" my-4 d-flex align-items-center justify-content-between">
          <AButton
            updatedStyle={{
              ...buttonStyles,
              border: "1px solid #B32306",
              color: "#B32306",
              width: "223px",
            }}
            onClick={doNotDuplicateHandler}
            className="button_remove_left_margin"
          >
            Don’t duplicate
          </AButton>

          <AButton
            x
            updatedStyle={{
              ...buttonStyles,
              border: "1px solid #00B0F5",
              color: "#00B0F5",
              width: "223px",
            }}
            onClick={() => {
              onAgree(displayStatus);
              handleClose();
            }} // to dispatch plan update action or add update handler
          >
            Continue to duplicate
          </AButton>
        </div>
        <div style={noteDivStyle}>
          <span style={noteText}>
            Note: The missing subjects lectures will not be replicated if you
            skip this step.
          </span>
        </div>
      </div>
    </Dialog>
  );
};

export default CMSSubjectWarning;
