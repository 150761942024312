import React from 'react'

const fontStyle = {
  fontSize: '20px',
  // fontFamily: 'Gilroy-Medium'
}
const NoRowsAllocation = ({batchName}) => {
  return (
    <div style={{...fontStyle, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%'}}>
      <div>
        <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="139" height="139" rx="69.5" fill="#E9ECEF" />
          <path fillRule="evenodd" clipRule="evenodd" d="M83.235 58.2276C83.235 65.5702 77.3478 71.4578 70 71.4578C62.6547 71.4578 56.765 65.5702 56.765 58.2276C56.765 50.8851 62.6547 45 70 45C77.3478 45 83.235 50.8851 83.235 58.2276ZM70 95C59.1559 95 50 93.2375 50 86.4374C50 79.6348 59.2135 77.9348 70 77.9348C80.8466 77.9348 90 79.6973 90 86.4974C90 93.3 80.7865 95 70 95Z" fill="#0F0F0F" />
          <rect x="0.5" y="0.5" width="139" height="139" rx="69.5" stroke="#E7E7E7" />
        </svg>
      </div>
      <span className='mt-3'>No eligible students found for allocation in batch {batchName}</span>
    </div>
  )
}

export default NoRowsAllocation