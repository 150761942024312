import React, { useEffect, useState } from 'react';
import { fetchAllPostPromisedData, getCookie } from '../../../../common/utils/methods/commonMethods/utilityMethod.js';
import { endpoint } from '../../../../common/constant';
import { failureToast, successToast } from '../../../../common/utils/methods/toasterFunctions/function.js';
// import moment from 'moment';
import { Accordion } from '../../../../common/commonComponents';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/system';
import { Tooltip } from '@material-ui/core';
import { EyeIcon } from '../../../studentCharges/common.js';
import { parse2Digit } from '../OtherChargeList/index.js';
import ADataGrid from '../../../../common/data-grid/ADataGrid.jsx';

const color = {
    pending: '#262626',
    approved: '#262626',
    rejected: '#B32306'
}

const bg = {
    rejected: '#F9D9D7',
    approved: '#22A06B',
    pending: '#8270DB'
}

const txt = {
    pending : 'Pending For Approval',
    approved : 'Approved',
    rejected  : 'Rejected'
}

const commonStyle = {
    fontSize: '12px',
    color: '#414141',
    display: 'flex'
  }


const actionExecuted = (statue) => {
    return statue === 'APPROVED' || statue === 'REJECTED' ? true : false;
}

const WaiveOff = ({ userPermissions, applicationId, isOpen, setIsOpen, otherCharges, psid,
    courseAttributeData, setOtherCharges, updatingStatus,
    setUpdatingStatus,userDetails }) => {

    const [loading, setLoading] = useState(true);
    // const [chargeType, setChargesType] = useState({ fetching: true, data: [] });
    const [rowData, setRowData] = useState([])



    const handleStatusUpdate = async (params, status) => {
        setUpdatingStatus({ id: params.row.id, status: status });
        const request = {
            "approvalStatus": status,
            approverUserId: getCookie('userId'),
            "approverName": userDetails ? `${userDetails.firstName} ${userDetails.lastName}` : ""
        }

        const response = await fetchAllPostPromisedData(`${endpoint.otherChargesConcession.updateStatus}/${params.row.id}`, request, 'put');
        if (response?.code === 200) {
            setOtherCharges(prevState => prevState.map(row =>
                row.id === params.row.id
                    ? { ...row, ...request, approvalStatus: status }
                    : row
            ));
            // setUpdatingStatus(false);
            successToast(response?.message)
            setUpdatingStatus(false);
        } else {
            // setSaving(false)
            setUpdatingStatus(false);
            failureToast(response?.message ?? 'Not able to process request , please try after sometime.')
        }
    }


    const handleRevokeStatusUpdate = async (params, status) => {
        setUpdatingStatus({ id: params.row.id, status: status });
        const request = {
            ...params.row,
            "psid": psid,
            "applicationId": applicationId,
            "waveOffAmount": 0,
            "finalPayable": params.row.netPayableAmount,
            "approvalStatus": 'APPLIED'
        }

        const response = await fetchAllPostPromisedData(`${endpoint.otherChargesConcession.create}/${params.row.id}`, request, 'put');
        if (response?.code === 200) {
            setOtherCharges(prevState => prevState.map(row =>
                row.id === params.row.id
                    ? { ...row, ...request }
                    : row
            ));
            // setUpdatingStatus(false);
            successToast(response?.message)
            setUpdatingStatus(false);
        } else {
            // setSaving(false)
            setUpdatingStatus(false);
            failureToast(response?.message ?? 'Not able to process request , please try after sometime.')
        }
    }

    const columns = [
        { field: 'seq', headerName: 'Seq', type: 'text',maxWidth: 50,  mandatory: false, editable: false, disabled: true, sortable: false, },
        {
            field: 'chargeTypelabel', headerName: 'Charge type', minWidth: 120, editable: false, disabled: false, sortable: false, renderCell: (params) => {
                return <div style={commonStyle} className="w-100">
                    <div className='w-75 overflow-hidden'>{params?.value ?? ''}</div>&nbsp;
                    <Tooltip title={params?.value}>
                        <div style={{ cursor: 'pointer' }}>
                            {/* <AiOutlineEye className='ml-1' size={16} color='#11cdef' /> */}
                            <EyeIcon color={'#00B0F5'} />
                        </div>
                    </Tooltip>
                </div>
            },
        },
        {
            field: 'netPayableAmount', headerName: 'Net Payable', minWidth: 110, sortable: false, editable: false, disabled: true, headerAlign: "right", renderCell: (params) => (
                <div className='w-100 d-flex justify-content-end'>
                    {/* <span style={{overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '85%'}}>{params.value}</span> */}
                    {params.value ? `₹ ${ parse2Digit(params.value)}` : null}
                </div>
            )
        },
        {
            field: 'waveOffAmount', headerName: 'Waive off amount', minWidth: 100, sortable: false, editable: false, disabled: true, headerAlign: "right", renderCell: (params) => (
                <div className='w-100 d-flex justify-content-end'>
                    {/* <span style={{overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '85%'}}>{params.value}</span> */}
                    {params.value ? `₹ ${parse2Digit(params.value)}` : null}
                </div>
            )
        },
        {
            field: 'finalPayable', headerName: 'Final Amount', type: 'text',minWidth: 100,  mandatory: false, editable: false, disabled: true, sortable: false, align: "right",
            headerAlign: "right", renderCell: (params) => (
                <div className='w-100 d-flex justify-content-end'>
                    {/* <span style={{overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '85%'}}>{params.value}</span> */}
                    {params.value ? `₹ ${parse2Digit(params.value)}` : null}
                </div>
            )
        },
        {
            field: 'approvalStatus', headerName: 'Approval Status', type: 'text',minWidth: 200,  mandatory: false, editable: false, disabled: true, sortable: false, renderCell: (params) => (
                params.value ? <span style={{ background: bg[params.value.toLowerCase()], color: color[params.value.toLowerCase()], padding: '4px 11px', borderRadius: '8px' }}>
                    {/* <span style={{overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '85%'}}>{params.value}</span> */}
                    {txt[params.value.toLowerCase()]}
                </span> : ''
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            
            // minWidth:300,
            minWidth : 200, 
            sortable: false,
            headerAlign: "center",
            renderCell: (params) => {

                return (
                    <>
                        {userPermissions.includes('A') ? <span onClick={actionExecuted(params.row.approvalStatus) ? () => { } : () => { handleStatusUpdate(params, 'APPROVED') }} style={{ color: '#fff', border: `1px solid ${actionExecuted(params.row.approvalStatus) ? '#B0B0B0' : '#00B0F5'}`, padding: '5px 16px', cursor: `${actionExecuted(params.row.approvalStatus) ? 'not-allowed ' : 'pointer'}`, borderRadius: '5px', background: `${actionExecuted(params.row.approvalStatus) ? '#B0B0B0' : '#00B0F5'}`, marginRight: '10px' }}>
                            {updatingStatus?.id === params.id && updatingStatus?.status === 'APPROVED' ? <i className="fas fa-spinner fa-spin" aria-hidden="true">{''}</i> : 'Approve'}
                        </span> : null}

                        {userPermissions.includes('A') ? <span onClick={actionExecuted(params.row.approvalStatus) ? () => { } : () => { handleStatusUpdate(params, 'REJECTED') }} style={{ color: '#fff', border: `1px solid ${actionExecuted(params.row.approvalStatus) ? '#B0B0B0' : '#B32306'}`, padding: '5px 16px', cursor: `${actionExecuted(params.row.approvalStatus) ? 'not-allowed ' : 'pointer'}`, borderRadius: '5px', background: `${actionExecuted(params.row.approvalStatus) ? '#B0B0B0' : '#E7685A'}`, marginRight: '10px' }}>
                            {updatingStatus?.id === params.id && updatingStatus?.status === 'REJECTED' ? <i className="fas fa-spinner fa-spin" aria-hidden="true">{''}</i> : 'Reject'}
                        </span> : null}

                        {/* {userPermissions.includes('C') ? <span onClick={actionExecuted(params.row.approvalStatus) ? () => { } : () => { handleRevokeStatusUpdate(params, 'APPLIED') }} style={{ color: `${actionExecuted(params.row.approvalStatus) ? '#B0B0B0' : '#B32306'}`, border: `1px solid ${actionExecuted(params.row.approvalStatus) ? '#B0B0B0' : '#B32306'}`, padding: '5px 16px', cursor: `${actionExecuted(params.row.approvalStatus) ? 'not-allowed ' : 'pointer'}`, borderRadius: '5px' }}>
                            {updatingStatus?.id === params.id && updatingStatus?.status === 'APPLIED' ? <i className="fas fa-spinner fa-spin" aria-hidden="true">{''}</i> : 'Revoke'}
                        </span> : null} */}
                    </>

                )

            },
        }
    ];

    function createSchemes(schemesPayload) {

        return schemesPayload.map((scheme, index) => {

            const { id, chargeTypeId,
                netPayableAmount,
                waveOffAmount,
                finalPayable,
                approvalStatus, } = scheme;

            return {
                ...scheme,
                seq: index + 1,
                id: id,
                chargeTypelabel: courseAttributeData.find(i => i.value === chargeTypeId)?.label ?? '',
                netPayableAmount,
                waveOffAmount,
                finalPayable,
                approvalStatus
            }
        })
    }




    const getOtherCharges = () => {
        const waiveOffData = otherCharges.map(i => {
            if (i.approvalStatus === 'APPROVED' || i.approvalStatus === 'PENDING' || i.approvalStatus === 'REJECTED') {
                return i
            } else {
                return null
            }
        })

        const rows = createSchemes(waiveOffData.filter(i => i))
        setRowData(rows)
        setLoading(false)
    }


    useEffect(() => {
        getOtherCharges();
    }, [otherCharges])


    function handleRowEditStart(params, event) {
        event.defaultMuiPrevented = true;
    };

    function handleRowEditStop(params, event) {
        event.defaultMuiPrevented = true;
    };


    function onCellDoubleClick(params, event) {
        event.defaultMuiPrevented = true;
    }

    return (
        rowData?.length ? <>
            <Accordion
                isFormOpen={isOpen.waive_off}
                title={`Applied Waive Off`}
                key={'waive_off'}
                isEditFormOpen={(open) => {
                    open === false ?
                        setIsOpen(state => ({ ...state, waive_off: false })) :
                        setIsOpen({
                            detailsTab: false,
                            tab2: false,
                            waive_off: true,
                            tab4: false
                        })
                }}
            >

                <Box sx={{ width: '100%', height: 'auto', overflow: "auto", background: '#ffffff', borderRadius: '16px' }}>
                    <ADataGrid
                        rows={rowData}
                        columns={columns}
                        rowId={(row) => row.id}
                        rowHeight={52}
                        height={100}
                        // getRowHeight={(params) => 52}
                        // autoHeight
                        // headerHeight={52}
                        // disableColumnMenu
                        // onRowModesModelChange={(newModel) => {setRowModesModel(newModel); console.log(newModel)}}
                        // onRowEditStart={handleRowEditStart}
                        // onRowEditStop={handleRowEditStop}
                        // processRowUpdate={processRowUpdate}
                        // onCellDoubleClick={onCellDoubleClick}
                        // disableSelectionOnClick
                        // pagination={false}
                        serverPagination={false}
                        loading={loading}
                        sx={{
                            color: '#262626',
                            fontFamily: 'inherit',
                            '.MuiDataGrid-cell:focus-within, .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-columnHeader:focus': {
                                outline: 'none'
                            },
                            '.MuiDataGrid-cell--editing:focus-within, .MuiDataGrid-cell--editing:focus, .MuiDataGrid-cell--editing:focus-visible': {
                                outline: 'none !important',
                            },
                            borderTop: 'none !important',
                            '.MuiDataGrid-main': {
                                height: `${100 + rowData?.length * 50}px`,
                                minHeight: `${60 + 50}px`,
                                maxHeight: `${60 + 50 * 50}px`,
                                backgroundColor: '#ffffff',
                                padding: "18px 0px !important",
                            },
                            '.MuiDataGrid-row.Mui-selected': {
                                backgroundColor: 'transparent',
                            },
                            '.MuiDataGrid-row:hover': {
                                backgroundColor: 'transparent',
                            },
                            '.MuiDataGrid-row.Mui-selected:hover': {
                                backgroundColor: 'transparent',
                            },
                            '.MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 600,
                                fontSize: '14px',
                                lineHeight: '20px',
                                // padding: "10px 10px !important",
                            },

                            '.MuiDataGrid-footerContainer': {
                                display: 'none'
                            },
                            overflow : 'scroll'
                        }}
                    />
                </Box>
            </Accordion>
        </> : null
    )

}

export default WaiveOff;
