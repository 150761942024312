import React from "react";
// reactstrap components
import { useState, useEffect ,useMemo} from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Button
} from "reactstrap";
import {
  successToast,
  putpost,
  failureToast,
  getAPI,
  masterServiceBaseUrl,
} from "services/http";
import Select from "react-select";
import {returnDropDownObjectById} from '../../../common/utils/methods/commonMethods/utilityMethod'
import {getSelectedDropDownValue} from '../../../common/utils/methods/commonMethods/masterDataForSelect'
import "./index.scss";

// core components

const customStyles = {
  control: (base) => ({
    ...base,
    height: 40,
    minHeight: 40,
  }),
};

const FranchiseeForm = (props) => {
  const {
    countryData = [],
    stateData = [],
    pincodeData = [],
    districtData = [],
    cityData = [],
    statusData = [],
    addressCategoryData = [],
    bussinessUnitId,
    businessTypeData = [],
    nextStep = () => { },
    setFranchisesSetupId,
    franchiseeAddressData,
    franchiseeSetupData,
    action,
    data,
    isPrevious,
    appendData=()=>{},
    updateData=()=>{},
  } = props;

  const [addressKey, setAddressKey] = useState("");
  const [addressDesc, setAddressDesc] = useState("");
  const [addressDispValue, setAddressDispValue] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine3, setAddressLine3] = useState("");
  const [addressLine4, setAddressLine4] = useState("");
  const [building, setBuilding] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [pincode, setPincode] = useState("");
  const [stateName, setStateName] = useState("");
  const [country, setCountry] = useState("");
  const [status, setStatus] = useState({label:'ACTIVE',value:'ACTIVE'});
  const [addressCategory, setAddressCategory] = useState("");
  const [isDisabled, setDisabled] = useState(false);
  const [allowedProperty, setAllowedProperty] = useState([]);
  const [pinData,setPinData] = useState([])
  const [businessName,setBusinessName] = useState('')
  const [businessType,setBusinessType] = useState('')


  const [franId,setFranId] = useState(null)


  
  useEffect( () => {
    console.log('Action inside the Franchisee setUp',action)
    if ( action === 'edit' || action === 'view' ) {
      console.log('inside the franhcisee setup')
      setDisabled( true );
    }
  }, [action])



  React.useMemo( () => {
    console.log('addressCategoryData',addressCategoryData)
    setPinData( pincodeData );
    console.log( "Action is here ", action )
    if ( action === 'view' || action === 'edit' ) {
      const addressCategoryObj = returnDropDownObjectById(
        franchiseeAddressData[ 'addressCategoryId' ],
        addressCategoryData
      );
      const businessTypeObj = returnDropDownObjectById(
        franchiseeSetupData [ 'businessTypeId' ],
        businessTypeData
      );
      const pincodeObj = returnDropDownObjectById(
        franchiseeAddressData  [ 'pincodeId' ],
        pincodeData
      );
      const cityObj = returnDropDownObjectById(
        franchiseeAddressData  [ 'cityId' ],
        cityData
      );
      const districtObj = returnDropDownObjectById(
        franchiseeAddressData  [ 'districtId' ],
        districtData
      );
      console.log('Franchices dis', franchiseeAddressData  [ 'districtId' ],districtData)
      const stateObj = returnDropDownObjectById(
        franchiseeAddressData  [ 'stateId' ],
        stateData
      );
      const countryObj = returnDropDownObjectById(
        franchiseeAddressData  [ 'countryId' ],
        countryData
      );
      console.log( "State obj", stateObj );
      console.log( 'country obj', countryObj );
      console.log( 'city obj', cityObj );
      console.log( 'district obj', districtObj );
      console.log('pincode obj',pincodeObj);
      setAddressCategory( addressCategoryObj );
      setBusinessType( businessTypeObj );
      setBusinessName( franchiseeSetupData[ 'businessName' ] );
      setCountry( countryObj );
      setDistrict( districtObj );
      setCity( cityObj );
      setStateName( stateObj );
      setPincode( pincodeObj );
      setAddressKey( franchiseeAddressData[ 'addressKey' ] );
      setAddressDesc(franchiseeAddressData['addressDesc']);
      setAddressDispValue( franchiseeAddressData[ 'addressDispValue' ] );
      setBuilding(franchiseeAddressData[ 'building' ])
      setAddressLine1(franchiseeAddressData[ 'addressLine1' ]);
      setAddressLine2(franchiseeAddressData[ 'addressLine2' ]);
      setAddressLine3(franchiseeAddressData[ 'addressLine3' ]);
      setAddressLine4(franchiseeAddressData[ 'addressLine4' ]);
    }
    console.log("franchiseeSetupData",franchiseeSetupData)
    console.log("franchiseeAddressData",franchiseeAddressData)
  },[pincodeData,addressCategoryData,businessTypeData,districtData])

  const addressCategoryHandler = (sData) => {
    if(sData && sData.value){
    getAPI(
      `${masterServiceBaseUrl}/address/getAddressProperty/${sData.value}`,
      (data) => {
        setAddressCategory( sData );
        
        setAllowedProperty(data.data);
      },
      (data) => {
        setAddressCategory("");
        setAllowedProperty([]);
        failureToast(data["message"]);
      }
    );}
  };

  const getSelectedValue = (id, listName) => {
    console.log(listName);
    return listName.filter((item) => {
      return item.value === id;
    });
  };

  const getPincodeByCity = (sdata) => {
    // console.log(data)
    if (!sdata || sdata==='') {
      setCity("");
      setPinData(pincodeData)
      return;
    }
    getAPI(
      `${masterServiceBaseUrl}/pincode/getPincodeByCity/${Number(sdata.value)}`,
      (data) => {
        setCity(sdata)
        const list = data.data.map(
          data => {
              return {
                  value: data.id,
                  label: data['pincodeDispValue']
              };
          }
      );     
      setPinData(list)
      setCountry('');
      setStateName('');
      setPincode('')
      setDistrict('')
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  };

  const fetchPincodeById = (data) => {
    if (data===null) {
      return;
    }
    getAPI(
      `${masterServiceBaseUrl}/pincode/getPincode/${Number(data.value)}`,
      (data) => {
        setPincode(getSelectedValue(data.data.id, pincodeData)[0]);
        setCity(getSelectedValue(data.data.cityId, cityData)[0]);
        setCountry(getSelectedValue(data.data.countryId, countryData)[0]);
        setStateName(getSelectedValue(data.data.stateId, stateData)[0]);
        setDistrict(getSelectedValue(data.data.districtId, districtData)[0]);
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  };

  // const editData = (url,formData,i) =>{
  //   putpost(url, (data) => {
  //     successToast(data['message'])
  //     addressList[i] = formData
  //     setAddressList([...addressList])
  //     setSaveingStatus(false)
  //     // console.log(formData)
  //    // toggleFormVisibility()
  //   }, (data) => { failureToast(data['message']); setSaveingStatus(false)}, formData, 'put')
  // }

  const isInProperty = (key) => {
    // console.log(key)
    const item =
      allowedProperty.length &&
      allowedProperty.filter((item) => {
        return item && item.property.propertyKey === key;
      });
    //console.log(item)
    const result = item.length ? true : false;
    return result;
  };

  const saveData = () => {
    
    if(businessType === "") {
      failureToast("Please Select Business Type");
      return;
    }
    
    if(businessName === "") {
      failureToast("Please Enter Business Name");
      return;
    }

    if (addressKey == "") {
      failureToast("Please Enter Address Key Value");
      return;
    }

    // if (addressDesc == "") {
    //   failureToast("Please Enter Address Description Value");
    //   return;
    // }

    // if (addressDispValue == "") {
    //   failureToast("Please Enter Address Display Value ");
    //   return;
    // }

    if (addressLine1 == "" && isInProperty("ADDRESS_LINE1")) {
      failureToast("Please Enter Address Line1 Value");
      return;
    }

    if (addressLine2 == "" && isInProperty("ADDRESS_LINE2")) {
      failureToast("Please Enter Address Line2 Value");
      return;
    }

    if (addressLine3 == "" && isInProperty("ADDRESS_LINE3")) {
      failureToast("Please Enter Address Line3 Value");
      return;
    }

    if (addressLine4 == "" && isInProperty("ADDRESS_LINE4")) {
      failureToast("Please Enter Address Line4 Value");
      return;
    }

    // if (building == "") {
    //   failureToast("Please Enter Building Value");
    //   return;
    // }


    if (!city || city == "") {
      failureToast("Please Select City Value");
      return;
    }


    if (!district || district == "") {
      failureToast("Please Select District Value");
      return;
    }


    if (!pincode || pincode == "") {
      failureToast("Please Select Pincode Value");
      return;
    }


    if (!stateName || stateName == "") {
      failureToast("Please Select State Value");
      return;
    }

    if (!country || country == "") {
      failureToast("Please Select Country Value");
      return;
    }
    const formData = {
      addressKey: addressKey, //isInProperty('ADDRESS_KEY') ? addressKey :null,
      addressDesc: addressDesc, //isInProperty('ADDRESS_DESC') ? addressDesc :null,
      addressDispValue: addressDispValue, //isInProperty('ADDRESS_DISPLAY_VALUE') ? addressDispValue :null,
      addressReferenceType: "FRANCHISEE_SETUP",
      referenceId: action === 'edit' ? franchiseeAddressData['referenceId']  : bussinessUnitId.businessUnit,
      addressLine1: isInProperty("ADDRESS_LINE1") ? addressLine1 : null,
      addressLine2: isInProperty("ADDRESS_LINE2") ? addressLine2 : null,
      addressLine3: isInProperty("ADDRESS_LINE3") ? addressLine3 : null,
      addressLine4: isInProperty("ADDRESS_LINE4") ? addressLine4 : null,
      cityId: city && city.value, //isInProperty('DISTRICT') ? district :null,
      districtId: district && district.value,
      pincodeId: pincode && pincode.value, //isInProperty('PINCODE') ? pincode :null,
      building: building, //isInProperty('BUILDING') ? building :null,
      stateId: stateName && stateName.value, //isInProperty('STATE') ? stateName :null,
      countryId: country && country.value, //isInProperty('COUNTRY') ? country :null,
      addressCategoryId: addressCategory.value,
      status: status.value, //isInProperty('STATUS') ? status :null
    };

    const obj = {
      businessAreaId: action === 'edit' ? bussinessUnitId : bussinessUnitId.businessUnit,
      businessTypeId:businessType.value,
      businessName:businessName
    }

    if(franId === null){
      if ( action !== 'edit' &&  props.isNew && !isPrevious) {
        putpost(
        `${masterServiceBaseUrl}/franchisee/createFranchisee`,
        (data) => {        
          formData.referenceId = data.data.id
           setFranId( data.data.id );
           setFranchisesSetupId( data.data.id  );
           saveAddress(formData,obj)
           appendData()
        },
        (data) => {
          failureToast(data["message"]);
        },
        obj,
        "post"
      );
      } else {

        if(isPrevious){
         
          formData.referenceId = data['franData']['id']
          putpost(
          `${masterServiceBaseUrl}/franchisee/updateFranchisee/${data['franData']['id']}`,
            ( data ) => {
              if (action !== 'edit') {
                
                setFranId( data.data.id );
                setFranchisesSetupId( data.data.id  );
              }
             saveAddress(formData,obj)
          },
          (data) => {
            failureToast(data["message"]);
          },
          obj,
          "put"
        );
        }else{  
          
          putpost(
          `${masterServiceBaseUrl}/franchisee/updateFranchisee/${franchiseeSetupData['id']}`,
            ( data ) => {
              
              if (action !== 'edit') {
                formData.referenceId = franchiseeSetupData['id']
                setFranId( data.data.id );
                setFranchisesSetupId( data.data.id  );
              }
             saveAddress(formData,obj)
          },
          (data) => {
            failureToast(data["message"]);
          },
          obj,
          "put"
        );
        }
      }
      
    }

    if(franId !== null){
      formData.referenceId = franId
      saveAddress(formData,obj)
    }
    
  };

  const saveAddress = ( formData ,obj) => {
    if ( action !== 'edit' && props.isNew && !isPrevious ) {
     
      putpost(
        `${ masterServiceBaseUrl }/address/createAddress`,
        ( data ) => {
          successToast( data[ "message" ] );
          appendData()
          nextStep();
        },
        ( data ) => {
          failureToast( data[ "message" ] );
        },
        formData,
        "post"
      );
    } else {
      if(isPrevious){
        putpost(
          `${ masterServiceBaseUrl }/address/updateAddress/${data['address']['id']}`,
          ( data ) => {
            successToast( data[ "message" ] );
            appendData()
            nextStep();
            if ( action === 'edit' ) {
              setDisabled( true );
            }
          },
          ( data ) => {
            failureToast( data[ "message" ] );
          },
          formData,
          "put"
        );
      }else{
        putpost(
          `${ masterServiceBaseUrl }/address/updateAddress/${franchiseeAddressData['id']}`,
          ( data ) => {
            successToast( data[ "message" ] );
            appendData()
            nextStep();
            if ( action === 'edit' ) {
              setDisabled( true );
            }
          },
          ( data ) => {
            failureToast( data[ "message" ] );
          },
          formData,
          "put"
        );
      }      
    }
  }

  useEffect(() => {
    if(data && isPrevious ){
      setBusinessType(getSelectedDropDownValue(
        data['franData']["businessTypeId"],
        businessTypeData
      ))
      setBusinessName( data['franData']["businessName"])
      setAddressKey(data['address']["addressKey"]);
      setAddressDesc(data['address']["addressDesc"]);
      setAddressDispValue(data['address']["addressDispValue"]);
        setAddressLine1(data['address']["addressLine1"]);
        setAddressLine2(data['address']["addressLine2"]);
        setAddressLine3(data['address']["addressLine3"]);
        setAddressLine4(data['address']["addressLine4"]);
        setBuilding(data['address']["building"]);
        setCity(getSelectedDropDownValue(data['address']["cityId"], cityData));
        setDistrict(getSelectedDropDownValue(data['address']["districtId"], districtData));
        setPincode(
          getSelectedDropDownValue(data['address']["pincodeId"], pincodeData));
        setStatus(getSelectedDropDownValue(data['address']["status"], statusData));
        setCountry(
          getSelectedDropDownValue(data['address']["countryId"], countryData));
        setStateName(getSelectedDropDownValue(data['address']["stateId"], stateData));
        // setAddressCategory(
        //   getSelectedDropDownValue(
        //     data['address']["addressCategoryId"],
        //     addressCategoryData
        //   )
        // );
        // setDisabled(true);
        addressCategoryHandler( getSelectedDropDownValue(
          data['address']["addressCategoryId"],
          addressCategoryData
        ));    
    }
  }, [props.data,props.isPrevious,cityData,districtData,pincodeData,countryData,stateData,addressCategoryData,businessTypeData])


  return (
    <>
      <Container className="mt-4" fluid>
        <Card className="mb-4">
          { action === 'new' ?
            <CardHeader>
            <Row>
              <Col md="6">
                 <h3 className="mb-0">Franchisee Setup</h3> 
              </Col>
            </Row>
          </CardHeader>:null}
          <CardBody>
          <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example2cols1Input"
                  >
                    *Business Type
                  </label>
                  <Select
                    value={ businessType }
                    id="example3cols2Input"
                    placeholder={ isDisabled ? '' : 'Select Business Type'}
                    options={businessTypeData}
                    onChange={ ( sData ) => setBusinessType( sData ) }
                    isDisabled={isDisabled}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example2cols1Input"
                  >
                    *Business Name
                  </label>
                  <input
                    value={businessName}
                    className="form-control addresslocationInput"
                    required={true}
                    placeholder={ isDisabled ? '' : 'Business Name'}
                    type="text"
                    maxLength="50"
                     disabled={isDisabled}
                    onChange={(e) => setBusinessName(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
            <Col>
                <FormGroup>
                <label
                    className="form-control-label"
                    htmlFor="example2cols1Input"
                  >
                    *Select Address Category
                  </label>
                  <Select
                    value={addressCategory}
                    id="example3cols2Input"
                    placeholder={ isDisabled ? '' : 'Select Address Category'}
                    options={addressCategoryData}
                    onChange={addressCategoryHandler}
                    style={ customStyles }
                    isDisabled={isDisabled}
                  />
                </FormGroup>
              </Col>
              <Col />
            </Row>
            {addressCategory ? (
              <>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols1Input"
                      >
                        *Address Key
                      </label>
                      <input
                        value={addressKey}
                        className="form-control addresslocationInput"
                        required={true}
                        placeholder={ isDisabled ? '' : 'Address Key'}
                        maxLength="50"
                        type="text"
                        disabled={isDisabled}
                        onChange={(e) => setAddressKey(e.target.value)}
                        style={{ height: "38px" }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols2Input"
                      >
                        *Status
                      </label>
                      <Select
                        value={addressCategory}
                        //className="form-control"
                        id="example3cols2Input"
                        value={status}
                        options={statusData}
                        onChange={ ( sData ) => setStatus( sData ) }
                        isDisabled={isDisabled}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label
                        className="form-control-label "
                        htmlFor="example2cols1Input"
                      >
                        Address Description
                      </label>
                      <input
                        value={addressDesc}
                        className="form-control addresslocationInput"
                        required={true}
                        placeholder={ isDisabled ? '' : 'Address Description'}
                        type="text"
                        maxLength="50"
                        disabled={isDisabled}
                        onChange={(e) => setAddressDesc(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols1Input"
                      >
                        Address Display Value
                      </label>
                      <input
                        value={addressDispValue}
                        className="form-control addresslocationInput"
                        required={true}
                        placeholder={ isDisabled ? '' : 'Address Display Value'}
                        type="text"
                        maxLength="50"
                        disabled={isDisabled}
                        onChange={(e) => setAddressDispValue(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {isInProperty("ADDRESS_LINE1") ? (
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example2cols1Input"
                        >
                          *Address Line 1
                        </label>
                        <input
                          value={addressLine1}
                          className="form-control addresslocationInput"
                          required={true}
                          placeholder={ isDisabled ? '' : 'Address Line 1'}
                          type="text"
                          maxLength="50"
                          disabled={isDisabled}
                          onChange={(e) => setAddressLine1(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                ) : null}
                {isInProperty("ADDRESS_LINE2") ? (
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example2cols1Input"
                        >
                          *Address Line 2
                        </label>
                        <input
                          value={addressLine2}
                          className="form-control addresslocationInput"
                          required={true}
                          placeholder={ isDisabled ? '' : 'Address Line 2'}
                          type="text"
                          maxLength="50"
                          disabled={isDisabled}
                          onChange={(e) => setAddressLine2(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                ) : null}
                {isInProperty("ADDRESS_LINE3") ? (
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example2cols1Input"
                        >
                          *Address Line 3
                        </label>
                        <input
                          value={addressLine3}
                          className="form-control addresslocationInput"
                          required={true}
                          placeholder={ isDisabled ? '' : 'Address Line 3'}
                          type="text"
                          maxLength="50"
                          disabled={isDisabled}
                          onChange={(e) => setAddressLine3(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                ) : null}
                {isInProperty("ADDRESS_LINE4") ? (
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example2cols1Input"
                        >
                          *Address Line 4
                        </label>
                        <input
                          value={addressLine4}
                          className="form-control addresslocationInput"
                          required={true}
                          placeholder={ isDisabled ? '' : 'Address Line 4'}
                          type="text"
                          maxLength="50"
                          disabled={false}
                          onChange={(e) => setAddressLine4(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols1Input"
                      >
                        Building
                      </label>
                      <input
                        value={building}
                        className="form-control addresslocationInput"
                        required={true}
                        placeholder={ isDisabled ? '' : 'Building'}
                        title="Address Key"
                        type="text"
                        maxLength="50"
                        disabled={isDisabled}
                        onChange={(e) => setBuilding(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols2Input"
                      >
                        *Pincode
                      </label>
                      <Select
                        value={pincode}
                        //className="form-control"
                        id="example3cols2Input"
                        options={pinData}
                        onChange={ fetchPincodeById }
                        isDisabled={isDisabled}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols2Input"
                      >
                        *City
                      </label>
                      <Select
                        value={city}
                        //className="form-control"
                        id="example3cols2Input"
                        options={cityData}
                        // isClearable={true}
                        onChange={ getPincodeByCity }
                        isDisabled={isDisabled}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols2Input"
                      >
                        *District
                      </label>
                      <Select
                        value={district}
                        //className="form-control"
                        id="example3cols2Input"
                        options={districtData}
                        isDisabled={true}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                <Col md="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols2Input"
                      >
                        *State
                      </label>
                      <Select
                        value={stateName}
                        //className="form-control"
                        id="example3cols2Input"
                        options={stateData}
                        isDisabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols2Input"
                      >
                        *Country
                      </label>
                      <Select
                        value={country}
                        //className="form-control"
                        id="example3cols2Input"
                        options={countryData}
                        isDisabled={true}
                      />
                    </FormGroup>
                  </Col>
                 
                </Row>               
                  <Row>
                  <Col md={ 6 }>
                    { action !== 'view' && action !== 'edit' ?
                      <Button
                          color="primary"
                          type="button"
                          onClick={() => {
                            props.previousStep();
                          }}
                        >
                            Previous
                      </Button>: null}
                  </Col> 
                  <Col md="6">
                    {(action !== 'edit' && action !== 'view')  && !isDisabled ?
                      <Button  color="primary" className="floatRight mr-4 mb-3" type="button" onClick={ saveData }>
                      {action === 'view' || action === 'edit' ? 'Update' : 'Save & Next'}
                      </Button> :
                      action === 'edit' ?
                        <Button
                        color="primary"
                        className="floatRight mr-4 mb-3"
                        type="button"
                        onClick={ () => setDisabled( false ) }
                      >
                        Enable Form
                      </Button> : null
                    }
                  </Col>
                </Row>
              </>
            ) : (
              <p>Please Select Address Category from Above Dropdown.</p>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
};
export default FranchiseeForm;
