import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";

import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import { ReactComponent as CloudIcon } from "assets/img/svg/CloudArrow.svg";
import { ReactComponent as PdfIcon } from "assets/img/svg/pdfIcon.svg";
import { TiDocumentText } from "react-icons/ti";
import { RiEdit2Fill } from "react-icons/ri";
import AButton from "../../../../common/form-fields-mui/AButton";
import ALoader from "../../../../common/a-loader";
import {
  failureToast,
  successToast,
} from "../../../../common/utils/methods/toasterFunctions/function";
import "../style.scss";
import { putpost, uploadpost } from "../../../../../../../services/utils";
import { endpoint } from "../../../../common/constant";
import { getUniqueKey } from "../../../../common/utils/methods/commonMethods/utilityMethod";
import { getDownloadFileUrl } from "./helper";
import { ExtraUI } from "../../dlpStockIssuance/dispatchSchedule/UploadDispatchSchedule";
import DispatchScheduleHistoryModal from "../../dlpStockIssuance/dispatchSchedule/DispatchScheduleHistoryModal";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx";
const UploadPhotoPlaceHolder = ({
  handleChange,
  file = null,
  loading,
  saveHandler,
  cancelHandler,
}) => {
  return (
    <div className="d-flex flex-column">
      <span className="mb-4" style={{ fontSize: "12px" }}>
        FIle format supported .csv. Max file size 5 mb
      </span>
      {loading && <ALoader />}
      {file ? (
        <div className="ellipsis-text-container">
          <div className="text-center">
            <TiDocumentText size={"40px"} />
          </div>
          <div className="mb-4 mt-2 sub-heading ellipsis-text-container">
            {file?.name}
          </div>
          <div className="mb-2"></div>
          <div className="button-container">
            <AButton
              updatedStyle={{
                height: "41px",
                color: "red",
                border: "1px solid red",
                background: "#fff",
              }}
              onClick={() => cancelHandler()}
              variant="primary_filled"
            >
              Cancel
            </AButton>
            <AButton
              updatedStyle={{ height: "41px" }}
              onClick={() => saveHandler()}
              variant="primary_filled"
            >
              Save
            </AButton>
          </div>
        </div>
      ) : (
        <div>
          <div>
            <label className="w-100" htmlFor="profilePhoto">
              <input
                id="profilePhoto"
                type="file"
                style={{ display: "none" }}
                onChange={handleChange}
              />
              <span className="upload-btn">
                Upload file <CloudIcon className="ml-2" />
              </span>
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

// initialFile prop will have name and s3key in object
const UploadBulkDispatch = ({
  disableUpload = false,
  form,
  setFormHandler,
  serviceName = "BULK_DISPATCH_DETAILS",
  supportedFileTypes = ["xlsx", "xls", "xlsb", "csv"],
  maxAllowedFileSize = 5,
  uploadButtonProps = {},
  uploadFileHeader = "Bulk upload dispatch details",
  disabledBtn = false,
  fetchList = () => {},
  downloadHandler,
  sendNotifications,
  historyListType,
}) => {
  const [viewHistoryModalOpen, setViewHistoryModalOpen] = useState(false);
  const [historyDataList, setHistoryList] = useState(true);

  const initialFile = form?.s3key
    ? { name: form?.fileName, key: form?.s3key }
    : null;
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const maxFileSize = maxAllowedFileSize * 1024 * 1024; // mentioned value is in bytes (1MB = 1024*1024)
  // console.log(form, 'form');
  const dataToServer = {
    bulk: false,
    fileName: null,
    docType: "pdf",
    keyValue: `consession_${getUniqueKey()}`,
    serviceName: serviceName,
  };

  const handleChange = (e) => {
    // setLoading(true);
    const file = e.target.files[0];
    // console.log(file.size, maxFileSize, 'size');
    if (file.size > maxFileSize) {
      failureToast(
        `Please select a file of size less than ${maxAllowedFileSize} MB`
      );
      return;
    }

    const fileType = file?.name?.split(".")?.pop();
    if (!supportedFileTypes.includes(fileType)) {
      failureToast(
        `Only ${supportedFileTypes
          .map((item) => `.${item}`)
          .join(", ")} files are allowed!`
      );
      return;
    }
    setFile({ file, name: file?.name, fileType });
  };

  const uploadHandler = () => {
    getFilePath(file);
  };
  const getFilePath = ({ name, file, fileType }) => {
    try {
      setLoading(true);
      const formData = {
        ...dataToServer,
        fileName: name,
        docType: fileType,
      };

      putpost(
        endpoint.fetchS3Url.getUrl,
        (data) => {
          if (data.code === 200 && data.data) {
            saveDataToStorage(data.data, file, name);
          }
        },
        (data) => {
          failureToast(data["message"]);
          setLoading(false);
        },
        formData,
        "post"
      );
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const saveDataToStorage = (res, file, name) => {
    try {
      const formData = new FormData();
      formData.append("File", file);
      uploadpost(
        res.storage,
        (data) => {
          saveFileAndReturnData({
            ...res,
          });
        },
        (data) => {
          failureToast("File upload failed, please try again after sometime.");
          setLoading(false);
        },
        file,
        file?.type,
        file?.name
      );
    } catch (e) {
      failureToast("Unable to upload file, please try after sometime.");
      setLoading(false);
    }
  };

  const cancelHandler = () => {
    setOpen(false);
    setFile(null);
  };
  const saveFileAndReturnData = (data) => {
    const request = {
      courseFeesProcessingDTO: {
        key: data.key,
        fileName: file.name,
        status: "ACTIVE",
        processInstanceId: Number(data.processId),
      },
      courseFeeSupportingDocumentDtos: null,
      isProcess: true,
      serviceName: "BULK_DISPATCH_DETAILS",
      processId: data.processId,
    };

    putpost(
      endpoint.fetchS3Url.updateToServer,
      (res) => {
        if (res.code === 200 && res.data) {
          successToast(
            "The file uploaded successfully, Please check the upload history and notify students for the same."
          );
          setLoading(false);
          cancelHandler();
          fetchList();
        }
      },
      (res) => {
        failureToast(res["message"]);
      },
      request,
      "post"
    );
  };

  return (
    <>
      {form?.fileName ? (
        <div className="file-exist-container">
          <PdfIcon className="pdf-icon flex-shrink-0" />
          <span className="ellipsis-text-container">{form?.fileName}</span>
          <RiEdit2Fill
            size={22}
            className={`flex-shrink-0 cursor ${
              disableUpload ? "color-grey-50" : "color-primary"
            }`}
            onClick={() => (disableUpload ? null : setOpen(true))}
          />
        </div>
      ) : (
        <AButton
          onClick={() => setOpen(true)}
          disabled={disabledBtn}
          {...uploadButtonProps}
        >
          <span className=""> Bulk Upload</span>
        </AButton>
      )}
      <Modal size="sm" isOpen={open}>
        <ModalBody>
          <div className="d-flex justify-content-between align-items-center">
            <span className="heading-4 color-dark">{uploadFileHeader}</span>
            <IconButtonWrapper
              onClick={() => {
                setOpen(false);
                setFile(initialFile);
              }}
            >
              <CrossIcon />
            </IconButtonWrapper>
          </div>
          <div>
            <UploadPhotoPlaceHolder
              maxAllowedFileSize={maxAllowedFileSize}
              supportedFileTypes={supportedFileTypes}
              disableUpload={disableUpload}
              handleChange={handleChange}
              file={file || initialFile}
              loading={loading}
              saveHandler={uploadHandler}
              cancelHandler={cancelHandler}
            />
          </div>
          <ExtraUI
            setViewHistoryModalOpen={setViewHistoryModalOpen}
            downloadTemplate={downloadHandler}
          />
          <DispatchScheduleHistoryModal
            open={viewHistoryModalOpen}
            setOpen={setViewHistoryModalOpen}
            historyDataChanged={historyDataList}
            setHistoryDataChanged={setHistoryList}
            historyListType={historyListType}
            sendNotifications={sendNotifications}
            isShowNotifyButton={true}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default UploadBulkDispatch;
