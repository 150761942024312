import React, {useEffect, useState} from 'react';
import APopover from '../../../common/a-popover';
import { Card, Col, CardBody, Row } from 'reactstrap';
import EditBranchesCommonComponent from '../../../common/branchesCommonComponent/EditBranchesCommonComponent';
import AAutoComplete from '../../../common/form-fields-mui/AAutoComplete';
import { endpoint } from '../../../common/constant';
import { fetchAllPromisedData } from '../../../common/utils/methods/commonMethods/utilityMethod';
import AButton from '../../../common/form-fields-mui/AButton';
import {ReactComponent as CloseIcon} from "../../../../../../assets/img/svg/CloseIcon.svg";

var _ = require('lodash');


const CareerLoanSetupFilter = ({loanVendors , academicCareerData, businessAreaDropdown, applyFilterHandler , form , handleFilterForm, closePopup , removeFilterHandler , filterCount}) => {

    // const isFormEmpty = _.isEmpty(form);

    return ( 
        <Card style={{width : '350px'}} className='mb-0 p-3'>
            <div className='d-flex justify-content-between'>
                <h4>Filter</h4>
                {/* <CloseIcon onClick={closePopup}/> */}
            </div>
                <Row>
                    <Col md={"12"}>
                        {/* <APopover
                        buttonComponent={onClick=>
                            <div onClick={onClick}>
                            <AAutoComplete
                            label='Business Area'
                            isMulti={true}
                            currentValue={form?.businessArea}
                            />
                            </div>
                        }
                        menuComponent={closeForm =>
                            <EditBranchesCommonComponent
                            closeForm={closeForm} 
                            title='Select Business Area'
                            updateHandler={handleBusinessAreaChange}
                            />
                        }
                        /> */}
                        <AAutoComplete
                            label='Business Area'
                            currentValue={form?.businessAreas}
                            items={businessAreaDropdown}
                            handleChange={val=>handleFilterForm(val,'businessAreas')}
                            isShowCount={true}
                            />
                    </Col>
                    <Col md={'12'}>
                        <AAutoComplete
                        label='Loan Partner'
                        items={loanVendors}
                        currentValue={form?.loanVendor}
                        isMulti={false}
                        handleChange={val=>handleFilterForm(val,'loanVendor')}
                        />
                    </Col>
                    <Col md={'12'}>
                        <AAutoComplete
                        label='Career'
                        items={academicCareerData}
                        currentValue={form?.academicCareer}
                        isMulti={false}
                        handleChange={val => handleFilterForm(val, 'academicCareer')}
                        />
                    </Col>
                </Row>
                <div className='d-flex justify-content-between mt-2'>
                    <AButton
                    variant='primary_filled'
                    updatedStyle={{backgroundColor : 'red'}}
                    onClick={()=>{removeFilterHandler(); closePopup();}}
                    size='xs'
                    className='no-ml-btn'
                    disabled={!form?.appliedFilterCount}
                    >
                        Remove
                    </AButton>

                        <AButton
                        variant='primary_filled'
                        onClick={()=>{
                            applyFilterHandler();
                            closePopup();
                        }}
                        size='xs'
                        disabled={filterCount === 0}
                        >
                            Apply
                        </AButton>
                </div>
        </Card>
     );
}
 
export default CareerLoanSetupFilter;