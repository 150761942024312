import { regex } from "views/pages/manage/common/constant";
import { transactionTypeMap } from "../stockReturn/constant";

export const getLabelForTxnId = (key, checkedOption) => {
  const labelMap = {
    Issuance: "Transaction",
    "Branch consumption": "Consumption",
    "Branch consumption return": "Consumption return",
    Return: checkedOption === "txnId" ? "Return" : "Application",
  };
  return labelMap[key];
};

export const getRegexForTxnId = (key, checkedOption) => {
  const regexMap = {
    Issuance: regex?.stockIssueTnxId,
    Return: checkedOption === "txnId" ? regex?.stockReturnId : regex?.numeric,
    "Branch consumption": regex.stockIssueConsumptionId,
    "Branch consumption return": regex?.stockConsumptionReturnId,
  };
  return regexMap[key];
};

export const getTransactionType = (key, academicType = "RCC") => {
  const map = {
    Issuance: transactionTypeMap.PSID_ISSUANCE[academicType],
    Return: transactionTypeMap.PSID_RETURN[academicType],
    "Branch consumption": transactionTypeMap.BRANCH_ISSUANCE[academicType],
    "Branch consumption return": transactionTypeMap.BRANCH_RETURN[academicType],
  };
  return `&type=${map[key]}`;
};
export const getScreenName = (selectedTab) => {
  const screenMap = {
    "Branch consumption": "branchConsumption",
    "Branch consumption return": "branchConsumptionReturn",
    Issuance: "txn",
    Reversal: "stockReversal",
  };
  return screenMap[selectedTab];
};

export const issuanceTypeMap = (academicType = "RCC") => ({
  Issuance: transactionTypeMap.PSID_ISSUANCE[academicType],
  "Branch consumption": transactionTypeMap.BRANCH_ISSUANCE[academicType],
  "Branch consumption return": transactionTypeMap.BRANCH_RETURN[academicType],
});

export const messageNoDataFound = "No record matches your search";
