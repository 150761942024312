import React from "react";
import GetAddForm from "./GetAddForm";
import GetProgressBar from "./GetProgressBar";
import { CardBody, Card } from "reactstrap";
import { useState } from "react";
import { validateForm } from '../constant/create-academic-plan'

const AddPlanForm = ({
  dropdown,
  classDropdown,
  businessAreaDropdown,
  form,
  formHandler,
  saveAcademicPlanHandler,
  cmsPackageDropdown,
  cmsPackageLoader,
  isDuplicate,
  currentStep,
  setCurrentStep,
  nextHandler
}) => {
  // const [currentStep, setCurrentStep] = useState(1);

  // const nextHandler = () => {
      
  //   if(currentStep === 1){
  //      if(validateSubjectsMismatch())
  //          setCurrentStep(currentStep + 1);
  //   }else{
  //     setCurrentStep(currentStep + 1);

  //   }

  // };


  return (
    <div className="m-3">
      <Card className="m-1" style={{ maxWidth: "400px" }}>
        <CardBody
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="a-h2">Create Academic Plan</div>
          <div>
            <GetProgressBar setStep={setCurrentStep} step={currentStep} />
            <GetAddForm
              dropdown={dropdown}
              classDropdown={classDropdown}
              businessAreaDropdown={businessAreaDropdown}
              step={currentStep}
              form={form}
              formInputHandler={formHandler}
              cmsPackageDropdown={cmsPackageDropdown}
              cmsPackageLoader={cmsPackageLoader}
              isDuplicate = {isDuplicate}
            />
            <div>
              {currentStep < 3 && (
                <button
                  onClick={nextHandler}
                  className=" mt-2 ml-0  w-100  add-plan-button"
                  disabled={validateForm(currentStep, form)}
                >
                  Next
                </button>
              )}
              {currentStep === 3 && (
                <button
                  onClick={saveAcademicPlanHandler}
                  className=" mt-2 ml-0  w-100  add-plan-button"
                  disabled={validateForm(currentStep, form)}
                >
                  Save & Continue
                </button>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default AddPlanForm;
