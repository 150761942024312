import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import ApprovalMatricTable from "./approvalMatricTable";
import Loader from "react-loader-spinner";
import { masterServiceBaseUrl, failureToast, getAPI } from "services/http";
import { Container, Button } from "reactstrap";
import {
  PaginationNode,
  PaginationPage,
  GetPagination,
} from "../../../common/pagination";

import CommonHeader from "./addApprovals/commonHeader";
import AddApprovals from "./addApprovals/addApprovals";

const ApprovalMatric = ( props ) => {
  const { approvalSetupsData,action,appendAllStepData=()=>{},
  isPrevious, saveStepsCompleted=()=>{} } = props;
  const [classArray, setclassArray] = useState([]);
  const [apiloader, setapiloader] = useState(false);
  const [state, setstate] = useState([]);

  const [termList, setTermList] = useState(null);
  const [attributeList, setAtrributeList] = useState(null);
  const [acadmicList, setAcademicList] = useState(null);
  const [courseAttributeList, setCourseAttributeList] = useState([]);
  const [ca, setCa] = useState(null);

  const [addApprovals, setApprovals] = useState(null);
  const [totallevel, setTotalLevel] = useState(0);
  const [isNew, setIsNew] = useState(true);
  const [ isDisabled, setIsDisabled ] = useState(false);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const pageSize = 10;
  const fetchClasses = async (url) => {
    setapiloader(true);
    getAPI(
      url,
      (data) => {
        setstate(data["data"]);
        let n = pagination;
        if (data["data"].length > pageSize) {
          n["nextPage"] = n.currentPage + 1;
        }
        if (n.currentPage > 1) {
          n.previousPage = n.currentPage - 1;
        }
        n["totalPage"] = Math.floor(data["data"].length / pageSize);
        if (data["data"].length % pageSize != 0) {
          n["totalPage"] += 1;
        }
        setpagination(n);
        setapiloader(false);
        setclassArray(
          data["data"].slice(
            pagination.currentPage * pageSize - pageSize,
            pagination.currentPage * pageSize
          ) || []
        );
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  };

  const fetchLists = async (url, key, setMethod) => {
    getAPI(
      url,
      (data) => {
        const list = data.data.map((data) => {
          return {
            id: data["id"],
            text: data[`${key}DispValue`],
          };
        });
        setMethod(list);
        if (key === "courseAttribute") {
          setCa(data.data);
        }
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  };

  const previousPage = () => {
    if (pagination.currentPage != 1) {
      setclassArray(
        state.slice(
          (pagination.currentPage - 1) * pageSize - pageSize,
          (pagination.currentPage - 1) * pageSize
        )
      );
      // console.log(
      //   state.slice(
      //     (pagination.currentPage - 1) * pageSize - pageSize,
      //     (pagination.currentPage - 1) * pageSize
      //   )
      // );
      let n = pagination;
      n["currentPage"] = n["currentPage"] - 1;
      if (n["currentPage"] == 1) {
        n["previousPage"] = null;
      } else {
        n["previousPage"] = n.currentPage;
      }
      setpagination(n);
    }
  };

  useEffect(() => {
    // debugger
    if(approvalSetupsData && isPrevious){
      approvalSetupsData?.length > 0 && performPaginationFormData();
    }
  }, [approvalSetupsData,isPrevious])

  const toNextStep = () => {
    if(props.isNew && !classArray.length){
      failureToast("Please add concession approval setup");
      return
    }
    !props.isPrevious && props.isNew  && props.saveStepsCompleted(8)
    props.nextStep()
  }

  useEffect(()=>{
    return()=>{
      if(state.length){
        !props.isPrevious && props.isNew  && props.saveStepsCompleted(8)
      }       
    }    
  },[state])

  const performPaginationFormData = () => {
// console.log('pagination is calle d---------------------')
     setstate(approvalSetupsData);
        let n = pagination;
        if (approvalSetupsData?.length > pageSize) {
          n["nextPage"] = n.currentPage + 1;
        }
        if (n.currentPage > 1) {
          n.previousPage = n.currentPage - 1;
        }
        n["totalPage"] = Math.floor(approvalSetupsData?.length / pageSize);
        if (approvalSetupsData?.length % pageSize != 0) {
          n["totalPage"] += 1;
        }
        setpagination(n);
        setapiloader(false);
        setclassArray(
          approvalSetupsData.slice(
            pagination?.currentPage * pageSize - pageSize,
            pagination?.currentPage * pageSize
          ) || []
        );
  }
  useEffect(() => {
    fetchLists(
      `${masterServiceBaseUrl}/academicCareer/getAllActiveAcademicCareer`,
      "academicCareer",
      setAcademicList
    );
    fetchLists(`${masterServiceBaseUrl}/term/getAllActiveTerm`, "term", setTermList);

    fetchLists(
      `${masterServiceBaseUrl}/courseAttributeValue/getAllActiveCourseAttributeValue`,
      "courseAttributeValue",
      setAtrributeList
    );
    fetchLists(
      `${masterServiceBaseUrl}/courseAttribute/getCourseAttributeByGroupCodeAndCompanyCode?groupCode=${props.groupCode}&companyCode=${props.companyCode}`,
      "courseAttribute",
      setCourseAttributeList
    );
  }, [props.groupCode]);

  useEffect(() => {
  //  if (!props.isNew || props.isPrevious) {
     // fetchClasses(
        // `${masterServiceBaseUrl}/concessionApprovalSetup/getAllConcessionApprovalSetup`,
     //   `${masterServiceBaseUrl}/concessionApprovalSetup/getConcessionApprovalSetupByBusinessAreaId/${props.formId}`
     // );
   // }
   
  //  console.log("approvalSetupsData",approvalSetupsData)
    if ( action === 'edit' || action === 'view' ) {
      setIsDisabled( true );
      
    // console.log("approvalSetupsData",approvalSetupsData)
      approvalSetupsData?.length > 0 && performPaginationFormData();
    }
  }, [props]);

  useEffect(()=>{
    if(isPrevious && props.formId){
      fetchClasses(`${masterServiceBaseUrl}/concessionApprovalSetup/getConcessionApprovalSetupByBusinessAreaId/${props.formId}`)
    }
  },[isPrevious,props.formId])

  // const checkAndNext=()=>{
  //   if(props.isNew && !classArray.length){
  //     failureToast("Please add concession approval setup");
  //     return
  //   }
  //   props.nextStep();
  // }

  return (
    <>
      {apiloader || !termList || !acadmicList || !attributeList ? (
        <div
          className="mx-auto text-center py-5 my-5"
          style={{ height: "100vh" }}
        >
          <Loader
            type="Rings"
            color="#00BFFF"
            height={70}
            width={70}
            timeout={10000}
          />
        </div>
      ) : (
        <>
          {addApprovals ? (
            <>
              <CommonHeader
                areaName={props.areaName}
                grpKey={props.groupCodekey}
                companyKey={props.companyCodeKey}
                data={addApprovals}
                totallevel={
                  addApprovals &&
                  totallevel + Number(addApprovals.totalApprovalLevel)
                }
                closeHandler={() => setApprovals(null)}
                courseAttributeData={courseAttributeList}
                courseAttributValueData={attributeList}
                termData={termList}
                academicCareerData={acadmicList}
              />
              <AddApprovals
                id={addApprovals && addApprovals.id}
                setTotalLevel={setTotalLevel}
                totallevel={totallevel}
              />
            </>
          ) : (
            <>
              <ApprovalMatricTable
                previousPage={previousPage}
                fetchClasses={fetchClasses}
                setclassArray={setclassArray}
                classArray={classArray}
                courseAttributeList={courseAttributeList}
                attributeList={attributeList}
                termList={termList}
                groupCode={props.groupCode}
                companyCode={props.companyCode}
                formId={props.formId}
                acadmicList={acadmicList}
                ca={ca}
                isViewOnly={props.isViewOnly}
                setApprovals={setApprovals}
                    setTotalLevel={ setTotalLevel }
                    isDisabled={ isDisabled }
                    setIsDisabled={ setIsDisabled }
                    action={action}
              />
              {pagination.totalPage > 1 ? (
                <GetPagination
                  fetchClasses={fetchClasses}
                  setclassArray={setclassArray}
                  pagination={pagination}
                  setpagination={setpagination}
                  pageSize={pageSize}
                  state={state}
                />
              ) : null}
              {(action !== 'edit' && action !== 'view') ?
              <Container
                style={{ marginTop: "20px",marginBottom: "20px" }}
                className="d-flex justify-content-between "
                fluid
              >
                <Button
                  color="primary"
                  type="button"
                  onClick={() => {
                    props.previousStep();
                  }}
                >
                  Previous
                </Button>

                <Button
                  color="primary"
                  type="button"
                  onClick={toNextStep}
                >
                  Next
                </Button>
              </Container>:null}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ApprovalMatric;
