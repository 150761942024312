import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import SearchFilter from "./SearchFilter";
import { getFilterCount, initialFilterForm, typeDropdown } from "./constant";
import ListComponent from "./ListComponent";
import {
  dropdownMapping,
  fetchAllPostPromisedData,
  fetchDeletePromisedData,
  updateRequest,
} from "../../../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint, pages } from "../../../../common/constant";
import ALoader from "../../../../common/a-loader";
import { successToast } from "../../../../common/utils/methods/toasterFunctions/function";
import { ReactComponent as BackIcon } from "assets/img/svg/BackButton.svg";
import { useHistory } from "react-router";
import {
  RolePermissions,
  PermissionContext,
} from "../../../../../../../appContext";
import { PermisionDenied } from "../../../../common/commonComponents";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx";

const getNameSearchReqBody = (userInput) => ({ materialNumber: userInput });
const restructureListResponse = (res, dropdown) => {
  return res.map((item) => ({
    ...item,
    groupTypeId:
      dropdown?.find((option) => option?.value === item?.groupTypeId) || "",
    materialNumber: item?.materialNumber
      ? {
          label: `${item?.materialNumber} | ${item?.materialDesc}`,
          value: item?.materialNumber,
        }
      : "",
    type: typeDropdown?.find((o) => o?.value === item?.type),
  }));
};

const OnBoardingMaterialIdSetup = () => {
  const userPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["materialIdSetup"]["id"]
  );

  const history = useHistory();
  const listRef = useRef();
  const [dropdownLoader, setDropdownLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [listApiLoader, setListApiLoader] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [nameFilterPayload, setNameFilterPayload] = useState("");
  const [filterPayload, setFilterPayload] = useState(initialFilterForm);
  const [filterCount, setFilterCount] = useState(0);
  const [groupTypeDropdown, setGroupTypeDropdown] = useState([]);
  const [page, setPage] = useState(0);

  const getCombinedPayload = () => ({
    ...getNameSearchReqBody(nameFilterPayload),
    ...filterPayload,
  });

  const fetchDropdown = async () => {
    setDropdownLoader(true);
    const res = await dropdownMapping(
      endpoint.groupTypeMaterialMapping.getActiveGroupType,
      "longName",
      "id",
      "shortName"
    );
    const dropdownValues = res?.map((item) => ({
      ...item,
      label: `${item?.label} | ${item?.shortName}`,
    }));
    setGroupTypeDropdown(dropdownValues);
    setDropdownLoader(false);
    fetchList(...Array(2), dropdownValues); // Array(2) is passed to skip first 2 arguments
  };

  const fetchList = async (
    page = 0,
    reqBody = { ...getNameSearchReqBody(""), ...initialFilterForm },
    dropdown = groupTypeDropdown
  ) => {
    setListApiLoader(true);

    const res = await fetchAllPostPromisedData(
      `${endpoint.groupTypeMaterialMapping.search}?offset=${page}`,
      updateRequest(reqBody),
      "post"
    );
    if (res?.code === 200) {
      setRowCount(res?.data?.totalRecords);
      setRows(
        restructureListResponse(res?.data?.groupTypeMaterialMapList, dropdown)
      );
    } else {
      setRowCount(0);
      setRows([]);
    }

    setListApiLoader(false);
  };

  const nextButtonHandler = async (newPage) => {
    setPage(newPage);
    const reqBody = getCombinedPayload();
    await fetchList(newPage, reqBody);
  };

  const fetchInitialData = async () => {
    await fetchDropdown();
    // await fetchList();
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const createHandler = async (rowData) => {
    setApiLoader(true);

    const reqBody = updateRequest({
      groupTypeId: rowData?.groupTypeId,
      materialNumber: rowData?.materialNumber,
      type: rowData?.type,
      status: rowData?.status,
    });
    const res = await fetchAllPostPromisedData(
      endpoint?.groupTypeMaterialMapping?.create,
      reqBody,
      "post"
    );
    if (res?.code === 200) {
      successToast(res?.message);
      await fetchList(page, getCombinedPayload());
      listRef.current.stopEditing();
    }

    setApiLoader(false);
  };

  const updateHandler = async (rowData) => {
    setApiLoader(true);

    const reqBody = updateRequest({
      groupTypeId: rowData?.groupTypeId,
      materialNumber: rowData?.materialNumber,
      type: rowData?.type,
      status: rowData?.status,
    });
    const res = await fetchAllPostPromisedData(
      `${endpoint?.groupTypeMaterialMapping?.edit}/${rowData?.id}`,
      reqBody,
      "put"
    );
    if (res?.code === 200) {
      successToast(res?.message);
      await fetchList(page, getCombinedPayload());
      listRef.current.stopEditing();
    }

    setApiLoader(false);
  };

  const toggleStatusHandler = async (rowData) => {
    await updateHandler({
      ...rowData,
      status: rowData?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
    });
  };

  const deleteHandler = async (rowData) => {
    setApiLoader(true);
    const res = await fetchDeletePromisedData(
      `${endpoint?.groupTypeMaterialMapping?.delete}/${rowData?.id}`
    );
    if (res?.code === 200) {
      successToast(res?.message);
      await fetchList(page, getCombinedPayload());
      listRef.current.stopEditing();
    }
    setApiLoader(false);
  };

  const applyFilter = async (filterForm) => {
    listRef.current.stopEditing();
    setFilterPayload(filterForm);
    setFilterCount(getFilterCount(filterForm));
    const reqBody = updateRequest({
      ...getNameSearchReqBody(nameFilterPayload),
      ...filterForm,
    });
    setPage(0);
    await fetchList(0, reqBody);
  };

  const applyNameFilter = async (userInput) => {
    listRef.current.stopEditing();
    setNameFilterPayload(userInput);
    const reqBody = updateRequest({
      ...getNameSearchReqBody(userInput),
      ...filterPayload,
    });
    setPage(0);
    await fetchList(0, reqBody);
  };

  return userPermissions ? (
    <div className={`${styles?.page_container} remove-container-top-space`}>
      {(dropdownLoader || listApiLoader || apiLoader) && (
        <ALoader position={"fixed"} />
      )}
      <div className={`${styles?.table_card}`}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="heading-4 color-dark d-flex align-items-center">
            <IconButtonWrapper onClick={() => history.goBack()}>
              <BackIcon />
            </IconButtonWrapper>
            <div className="ml-2">On boarding material ID set-up</div>
          </div>
          <SearchFilter
            filterCount={filterCount}
            filterPayload={filterPayload}
            nameFilterPayload={nameFilterPayload}
            groupTypeDropdown={groupTypeDropdown}
            listRef={listRef}
            applyNameFilter={applyNameFilter}
            applyFilter={applyFilter}
          />
        </div>
        <ListComponent
          ref={listRef}
          rows={rows}
          groupTypeDropdown={groupTypeDropdown}
          loader={dropdownLoader || listApiLoader}
          currentPage={page}
          nextButtonHandler={nextButtonHandler}
          rowCount={rowCount}
          createHandler={createHandler}
          updateHandler={updateHandler}
          toggleStatusHandler={toggleStatusHandler}
          deleteHandler={deleteHandler}
        />
      </div>
    </div>
  ) : (
    <PermisionDenied />
  );
};

export default OnBoardingMaterialIdSetup;
