import React, { useEffect, useState, useContext } from 'react'
import FacultyRoasterList from './FacultyRoasterList'
import FacultyRoasterSearchFilter from './FacultyRoasterSearchFilter'
import HeaderComponent from './HeaderComponent'
import { Card, CardBody } from 'reactstrap'
import { loadDropdowns, getFacultyRoasterList, facultyRoasterListClear } from 'redux/facultyRoaster/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getFilterCount, getInitialFilterForm } from './constant/faculty-roaster'
import CreateFacultyRoaster from './CreateFacultyRoaster'
import { pages } from '../../common/constant'
import { PermissionContext, RolePermissions } from "../../../../../appContext";
import { PermisionDenied } from '../../common/commonComponents';
import { failureToast } from '../../common/utils/methods/toasterFunctions/function'



const FacultyRoaster = () => {
  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["facultyRoaster"]['id']);


  const dispatch = useDispatch();
  const dropdownData = useSelector(state => state?.facultyRoaster?.dropdowns)


  const [page, setPage] = useState(0);
  const [filterPayload, setFilterPayload] = useState(getInitialFilterForm());
  const [filterForm, setFilterForm] = useState(getInitialFilterForm());
  const [filterCount, setFilterCount] = useState(0);
  const [activePlanData, setActivePlanData] = useState({});
  const [updateApiLoader, setUpdateApiLoader] = useState(false);
  const [businessAreaData, setBusinessAreaData] = useState([]);

  const inputHandler = (value, inputKey) => {
    // const currentData = { ...filterForm };
    // currentData[inputKey] = value;
    setFilterForm(currentData => { return { ...currentData, [inputKey]: value } });
  }
  const onPageChange = (currentPage) => {
    setPage(currentPage);
    dispatch(getFacultyRoasterList(filterPayload, currentPage, dropdownData?.businessArea));
  }
  const clearNameFilterHandler = () => {
    setPage(0);
    const updatedPayload = filterPayload;
    updatedPayload['facultyNameOrfacultyId'] = ''
    setFilterPayload(updatedPayload);
    dispatch(getFacultyRoasterList(updatedPayload, 0, dropdownData?.businessArea))
  }

  const applyNameFilterHandler = () => {
    if(filterForm.facultyNameOrfacultyId.length < 4){
      failureToast("Please provide atleast 4 characters.")
      return
    }
    setPage(0);
    const updatedPayload = filterPayload;
    updatedPayload['facultyNameOrfacultyId'] = filterForm['facultyNameOrfacultyId']
    setFilterPayload(updatedPayload);
    dispatch(getFacultyRoasterList(updatedPayload, 0, dropdownData?.businessArea));
  }

  const applyFilterHandler = () => {
    setPage(0);
    setFilterCount(getFilterCount(filterForm));
    setFilterPayload(filterForm);
    dispatch(getFacultyRoasterList(filterForm, 0, dropdownData?.businessArea));
  }
  const removeFilterHandler = () => {
    setPage(0);
    setFilterCount(0);
    const resettedFilterForm = { ...getInitialFilterForm(), facultyNameOrfacultyId: filterForm?.facultyNameOrfacultyId }
    setFilterForm(resettedFilterForm);
    setFilterPayload(resettedFilterForm);
    dispatch(getFacultyRoasterList(resettedFilterForm, 0, dropdownData?.businessArea));
  }

  const fetchInitialData = async (dropdownData) => {
    // console.log(currentTabValue)
    dispatch(getFacultyRoasterList(filterPayload, 0, dropdownData?.businessArea));
  }

  useEffect(() => {
    // dropdownds call and fetch initial list call with 'All' as initial tab value
    dispatch(loadDropdowns(fetchInitialData));
    // dispatch(getFacultyRoasterList(filterPayload, 0));

    // clear list cleanup function
    return () => {
      dispatch(facultyRoasterListClear())
    }
  }, [])

  return (
    userPermissions ? <div className='d-flex flex-column justify-content-between remove-container-top-space' style={{ height: 'calc(100vh - 100px)' }}>
      <HeaderComponent isInformationAvailable={false} userPermissions={userPermissions}/>

      <Card style={{ marginBottom: 0, height: '100%' }}>
        <CardBody style={{ display: 'flex', flexFlow: 'column' }}>
          <div style={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
            <div style={{ fontSize: '20px', fontWeight: 600, color: '#0F0F0F' }}>Faculty Roster</div>
            <FacultyRoasterSearchFilter
              filterCount={filterCount}
              filterForm={filterForm}
              inputHandler={inputHandler}
              applyNameFilterHandler={applyNameFilterHandler}
              clearNameFilterHandler={clearNameFilterHandler}
              applyFilterHandler={applyFilterHandler}
              removeFilterHandler={removeFilterHandler}
              businessAreaData={businessAreaData}
              setBusinessAreaData={setBusinessAreaData}
            />
          </div>
          <div style={{ flexGrow: 1, display: 'flex' }}>
            <FacultyRoasterList
              page={page}
              onPageChange={onPageChange}
              planData={activePlanData}
              setPlanData={setActivePlanData}
              userPermissions={userPermissions}
              businessArea={dropdownData?.businessArea}
            // updatePublishStatusHandler={updatePublishStatusHandler}
            // updateBranchesHandler={updateBranchesHandler}
            />
          </div>
        </CardBody>
      </Card>
      {/* <CreateFacultyRoaster/> */}
    </div> : <PermisionDenied />
  )
}

export default FacultyRoaster