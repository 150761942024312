import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import AButton from "../../common/form-fields-mui/AButton";
import { constants } from '../../common/constant';
import AAutoComplete from '../../common/form-fields-mui/AAutoComplete'
import CommonInput from "../../common/formFeilds/input/commonInput";
import moment from "moment";


export const modalStyle = {
  position: "absolute" /* Stay in place */,
  zIndex: "75" /* Sit on top */,
  top: "2rem",
  right: "5rem",
  width: "60%" /* Full width */,
  height: "100%" /* Full height */,
};

export const PastDateFilter = ({
  pastFilter,
  setPastFilter,
  applyFilterHandler,
  removeFilterHandler,
  isShowRemoveFilter,
  disabled = false
}) => {
  const columnSize = 12;

  return (
    <div >
      <Card style={{ width: "300px", marginBottom: "0px" }}>
        <CardBody style={{ paddingTop: ".5rem", paddingBottom: ".5rem" }}>
          <h4 style={{ color: "black" }}> Past roster range </h4>
          <Row className="pt-1">
            <Col md={12} className="pb-1"><span className="regular-small">Date Range</span></Col>
            <Col md={6}>
              <CommonInput type="datePickerV2" defaultValue={pastFilter.startDate} onChange={(value) => setPastFilter({ ...pastFilter, startDate: value, endDate : '' })} forFilter={true} forTable={true} placeHolder={"From"} maxDate={moment().subtract(1, 'days')} withportal={true}/>
            </Col>
            <Col md={6}>
              <CommonInput type="datePickerV2" defaultValue={pastFilter.endDate} onChange={(value) => setPastFilter({ ...pastFilter, endDate: value })} forFilter={true} forTable={true} placeHolder={"To"} maxDate={moment().subtract(1, 'days')} minDate={pastFilter.startDate} withportal={true}/>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col md="6" className="pl-1">
              {isShowRemoveFilter && (
                <AButton
                  onClick={removeFilterHandler}
                  updatedStyle={{
                    border: "1px solid #CC5F5F",
                    borderRadius: "8px",
                    color: "#CC5F5F",
                    marginTop: ".3rem",
                    marginRight: ".3rem",
                    marginLeft: "0rem"
                  }}
                >
                  Remove Filter
                </AButton>
              )}
            </Col>
            <Col md="6" style={{ display: "flex", justifyContent: "flex-end" }}>
              <AButton
                onClick={applyFilterHandler}
                updatedStyle={{
                  background: "#00B0F5",
                  border: "1px solid #00B0F5",
                  borderRadius: "8px",
                  color: "#fff",
                  margin: ".3rem"

                }}
                disabled={disabled}
              >
                Apply Filter
              </AButton>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
