import React, { useContext } from 'react';
import FavoriteIcon from './FavouriteIcon';
import styles from './styles.module.scss';
import { useHistory } from 'react-router';
import { UserFavouritesContext } from '../../../../../appContext/favouritesContext';

const AddToFavourite = ({
  screenName,
  screenId,
  className = '',
  size = 'md',
  ...restProps
}) => {
  const history = useHistory();
  const { favourites, addFavourite, deleteFavourite } = useContext(UserFavouritesContext) || {};

  const pathname = history?.location?.pathname;
  // all the logic for added to favourite will be written here
  const favMarked = favourites?.find(
    item => item?.screenName === screenName && item?.pathUrl === pathname
  );

  const handleClick = (e) => {
    if(!e.detail || e.detail===1) {
      if(favMarked) {
        // delete logic here
        deleteFavourite(favMarked?.id);
      } else {
        // add logic here
        addFavourite(screenName, pathname, screenId);
      }
    }
  }

  return (
    <div
      className={`${styles?.addToFavourites} ${styles?.[size]} ${className}`}
      onClick={handleClick}
      {...restProps}
    >
      <FavoriteIcon checked={favMarked} />
      <span>{favMarked ? 'Added to Favourite' : 'Add to Favourite'}</span>
    </div>
  );
};

export default AddToFavourite;
