import React, { useContext } from "react";
import StudentWidgetAndFilters from "./studentFiltersandwidget";
import {StudentListContext,StudentListProvider} from "./contextApi";
import { pages } from "../../common/constant";
import { PermissionContext, RolePermissions } from 'appContext';
import { PermisionDenied } from "../../common/commonComponents";
import StudentListWrapper from "./studentListWrapper";
import { getStudentList, loadDropdowns } from "redux/studentCharges/actions";

// import { IssuanceIDCardContext, IssuanceIDCardProvider } from "../../edpOps/issuanceIDcard/contextApi";


const pageStateMapping = {
  SEARCH: <StudentWidgetAndFilters
  storeState={'studentCharges'}
  getStudentList={getStudentList}
  loadDropdowns={loadDropdowns}
  studentContext={StudentListContext}
   />,
  LIST: <StudentListWrapper />,
}

const GetPageComponentByState = () => {
  const { currentPageState } = useContext(StudentListContext);

  return (
    pageStateMapping[currentPageState]
  )
}

const StudentCharges = () => {

  const userPermissions = RolePermissions(useContext(PermissionContext), pages['studentCharges']['id']);

  return (
    userPermissions ? (
      <StudentListProvider>
        <GetPageComponentByState />
      </StudentListProvider>
    ) : (
      <PermisionDenied />
    )
  );
};

export default StudentCharges;
