import moment from "moment";
import { endpoint } from "../../../../common/constant";
import {
  dropdownMapping,
  fetchAllPostPromisedData,
  fetchAllPromisedData,
} from "../../../../common/utils/methods/commonMethods/utilityMethod";
import { dropdownMapping as dropdownMapping2 } from "../../../constant/batch-search";
import { useEffect, useState } from "react";

export const dispatchIdDropdown = [
  { label: "DISPATCH-01", value: "DISPATCH-01" },
  { label: "DISPATCH-02", value: "DISPATCH-02" },
  { label: "DISPATCH-03", value: "DISPATCH-03" },
  { label: "DISPATCH-04", value: "DISPATCH-04" },
  { label: "DISPATCH-05", value: "DISPATCH-05" },
  { label: "DISPATCH-06", value: "DISPATCH-06" },
  { label: "DISPATCH-07", value: "DISPATCH-07" },
  { label: "DISPATCH-08", value: "DISPATCH-08" },
  { label: "DISPATCH-09", value: "DISPATCH-09" },
  { label: "DISPATCH-10", value: "DISPATCH-10" },
  { label: "DISPATCH-11", value: "DISPATCH-11" },
  { label: "DISPATCH-12", value: "DISPATCH-12" },
  { label: "DISPATCH-13", value: "DISPATCH-13" },
  { label: "DISPATCH-14", value: "DISPATCH-14" },
  { label: "DISPATCH-15", value: "DISPATCH-15" },
];

export const initialFilterForm = {
  businessArea: "",
  courseId: "",
  term: "",
  academicGroup: "",
  materials: "",
  scheduleDateFrom: "",
  scheduleDateTo: "",
  status: null,
  dispatchId: "",
};

export const newRow = (dropdown, termDropdown) => ({
  dispatchId: "",
  businessArea:
    dropdown?.businessArea?.length === 1
      ? dropdown?.businessArea?.[0]
      : dropdown?.businessArea?.find((item) => item?.label === "DW005") ?? "",
  courseId: "",
  term: termDropdown?.length === 1 ? termDropdown?.[0] : "",
  academicGroup: "",
  dispatchDate: "",
  year: "",
  status: "ACTIVE",
  materials: [],
});

export const getDropdowns = async (academicCareer = "DLP") => {
  const p = await Promise.all([
    dropdownMapping(endpoint?.term?.getAllActive, "termDispValue", "id"),
    dropdownMapping(
      endpoint?.academic_group?.getAllActive,
      "academicGroupDispValue",
      "id"
    ),
    dropdownMapping(
      academicCareer === "DLP"
        ? endpoint?.businessArea?.getAllDlpActiveBusinessArea
        : endpoint.businessArea.getAllDigitalBusinessArea,
      "businessAreaDispValue",
      "id"
    ),
  ]);

  return {
    dispatchIdDropdown: dispatchIdDropdown,
    term: p[0],
    academicGroup: p[1],
    businessArea: p[2],
    courseId: p[3],
  };
};
export const useGetDlpCourseIdsByBu = (form) => {
  const [courseIds, setCourseIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (form?.businessArea) {
      fetchCourseIds(form?.businessArea);
    }
  }, [form?.businessArea]);

  const fetchCourseIds = async (businessArea) => {
    setIsLoading(true);
    // console.log(businessArea);
    const p = await dropdownMapping2(
      `${endpoint?.course?.getAllDlpCourseId}?businessArea=${businessArea?.value}`,
      "courseIds"
    );
    // console.log(p);
    setCourseIds(p);
    setIsLoading(false);
  };
  return [courseIds, isLoading];
};

export const useGetDigitalCourseIdsByBu = (form) => {
  const [courseIds, setCourseIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (form?.businessArea) {
      fetchCourseIds(form?.businessArea);
    }
  }, [form?.businessArea]);
  const fetchCourseIds = async (businessArea) => {
    setIsLoading(true);
    // console.log(businessArea);
    const p = await dropdownMapping2(
      `${endpoint?.course?.getAllDigitalCourseId}?businessArea=${businessArea?.value}`,
      "courseIds"
    );
    // console.log(p);
    setCourseIds(p);
    setIsLoading(false);
  };
  return [courseIds, isLoading];
};

export const getListResponse = (dataArr, dropdown) => {
  return dataArr?.map((item) => {
    return {
      ...item,
      dispatchId: { label: item?.dispatchId, value: item?.dispatchId },
      businessArea: {
        label: item?.businessAreaDispValue,
        value: item?.businessAreaId,
      },
      courseId: { label: item?.courseId, value: item?.courseId },
      term: { label: item?.termDispValue, value: item?.termId },
      academicGroup: {
        label: item?.academicGroupDispValue,
        value: item?.academicGroupId,
      },
      materials: item?.stockDispatchMaterialMappingList?.map((item) => ({
        ...item,
        materialWeight: item?.materialWeight,
        materialIdAndDescription: `${item?.materialNumber} | ${item?.materialDescription}`,
      })),
    };
  });
};

export const getSearchReqBody = (reqBody, academicCareer) => {
  return {
    businessAreaId: reqBody?.businessArea?.value,
    courseId: reqBody?.courseId?.value,
    termId: Array.isArray(reqBody?.term) ? reqBody?.term : reqBody?.term?.value,
    academicGroupId: reqBody?.academicGroup?.value,
    materialNumber: reqBody?.materialNumber?.value,
    scheduleDateFrom: reqBody?.scheduleDateFrom,
    scheduleDateTo:
      reqBody?.scheduleDateTo && moment(reqBody?.scheduleDateTo).endOf("day"),
    status: reqBody?.status || "ACTIVE",
    dispatchId: reqBody?.dispatchId,
    academicCareer: academicCareer === "DIGITAL" ? "ICON" : academicCareer,
  };
};

export const getCreateReqBody = (rowData, academicCareer) => {
  return {
    academicCareer: academicCareer === "DIGITAL" ? "ICON" : academicCareer,
    dispatchId: rowData?.dispatchId?.value,
    businessAreaId: rowData?.businessArea?.value,
    businessAreaDispValue: rowData?.businessArea?.label,
    courseId: rowData?.courseId?.value,
    termId: rowData?.term?.value,
    termDispValue: rowData?.term?.label,
    academicGroupId: rowData?.academicGroup?.value,
    academicGroupDispValue: rowData?.academicGroup?.label,
    dispatchDate: rowData?.dispatchDate,
    year: rowData?.year || "1",
    status: rowData?.status,
    stockDispatchMaterialMappingList: rowData?.materials?.map((item) => ({
      materialNumber: item?.materialNumber,
      materialDescription: item?.materialDescription,
      materialCount: item?.materialCount,
      materialWeight: item?.materialWeight,
    })),
  };
};

export const getTermDropdown = async (courseId, setMethod, dropdown) => {
  if (!courseId) return;
  const res = await fetchAllPromisedData(
    `${endpoint?.getAllTermByCourseId}/${courseId}`
  );
  if (Array.isArray(res)) {
    const filteredTerms = dropdown?.term?.filter((item) =>
      res?.includes(item?.value)
    );
    setMethod(filteredTerms);
  } else {
    setMethod([]);
  }
};

export const getCourseDetails = async (
  rowForm,
  setCourseDetails,
  setAcademicGroup,
  dropdown
) => {
  if (rowForm?.businessArea && rowForm?.courseId && rowForm?.term) {
    const res = await fetchAllPostPromisedData(
      endpoint.course.getCoursePricingDetails,
      {
        courseId: rowForm?.courseId?.value,
        businessArea: rowForm?.businessArea?.value,
        term: rowForm?.term?.value,
      },
      "post"
    );
    if (res?.code === 200) {
      setCourseDetails(res?.data);
      const academicGroup =
        dropdown?.academicGroup?.find(
          (item) => item?.value === res?.data?.academicGroup
        ) || "";
      setAcademicGroup(academicGroup);
    } else {
      setCourseDetails({});
      setAcademicGroup("");
    }
  } else {
    setCourseDetails({});
    setAcademicGroup("");
  }
};

export const getYear = (date, courseDetails) => {
  if (!date || !courseDetails) return "";
  if (
    moment(date).isSameOrAfter(courseDetails?.firstYearStartDate) &&
    moment(date).isSameOrBefore(courseDetails?.firstEndDate)
  )
    return 1;
  if (
    moment(date).isSameOrAfter(courseDetails?.secondYearStartDate) &&
    moment(date).isSameOrBefore(courseDetails?.secondEndDate)
  )
    return 2;
  if (
    moment(date).isSameOrAfter(courseDetails?.thirdYearStartDate) &&
    moment(date).isSameOrBefore(courseDetails?.thirdEndDate)
  )
    return 3;
  if (
    moment(date).isSameOrAfter(courseDetails?.fourthYearStartDate) &&
    moment(date).isSameOrBefore(courseDetails?.fourthEndDate)
  )
    return 4;
  if (
    moment(date).isSameOrAfter(courseDetails?.fifthYearStartDate) &&
    moment(date).isSameOrBefore(courseDetails?.fifthEndDate)
  )
    return 5;
  return "";
};

export const createSuccessMsg = "Dispatch schedule saved successfully.";
export const updateSuccessMsg = "Dispatch schedule updated successfully.";
export const deleteSuccessMsg = "Dispatch schedule deleted successfully.";

export const qtyDropdown = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
];
