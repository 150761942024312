import React from 'react';
import { Button,Card,CardHeader,Row,Col,CardBody,Form} from 'reactstrap';
import CommonInput from "../../../../common/formFeilds/input/commonInput";
import CustomButton from "components/CustomButton";


const InternalTestResultSearchForm = (props)=>{
    const {
        searchHandler,
        resetHandler,
        updateSearchData,
        searchData,
        businessArea,
        promiseOptions,
        examIds,
        userPermissions
    } = props;
    return (
        <Row>
            <div className="col">
                <Card>
                    <CardHeader>
                        <h3 className="mb-0  floatLeft">
                            Internal Test Result
                        </h3>
                        {/*<Button color="info" size="sm"*/}
                        {/*    // className={"floatRight mx-1" +((!selectedAcademicCareer && !selectedCompanyCode && !selectedGroupCode) ? ' btn-dark' : null)}*/}
                        {/*        className={"floatRight mx-1"}*/}
                        {/*        onClick={ ()=>addNewRowHandler()}*/}
                        {/*>*/}
                        {/*    Add New*/}
                        {/*</Button>*/}
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <Row>
                                <Col md={4}>
                                    <CommonInput label={'Student ID'}
                                        // data={studentIds}
                                                 type={'smartSearchSelect'}
                                                 placeHolder={'Student ID'}
                                                 onChange={(data)=>{
                                                     updateSearchData('studentId',data)
                                                 }}
                                                 defaultValue={searchData.studentId}
                                                 isDisabled={false}
                                                 promiseHandler={promiseOptions}
                                    />
                                </Col>
                                <Col md={4}>
                                    <CommonInput label={'Roll Number'}
                                           maxLength={15}
                                           allowedSomeSpecialCharacters={true}
                                           regEx={/[^0-9a-zA-Z_]/g}
                                           restrictSpecialCharacter={true}
                                           type={'text'}
                                           placeHolder={'Roll Number'}
                                           onChange={(value)=>{updateSearchData('rollNumber',value)}}
                                           defaultValue={searchData["rollNumber"]}
                                           isDisabled={false}
                                    />
                                </Col>
                                <Col md={4}>
                                    <CommonInput label={'Test Rec ID'}
                                            type={'text'}
                                            maxLength={10}
                                            allowedSomeSpecialCharacters={true}
                                            regEx={/[^0-9a-zA-Z_]/g}
                                            restrictSpecialCharacter={true}
                                            placeHolder={'Test Rec ID'}
                                            onChange={(value)=>{updateSearchData('testRecId',value)}}
                                            defaultValue={searchData["testRecId"]}
                                            isDisabled={false}
                                    />
                                </Col>
                                <Col md={4}>
                                    <CommonInput label={'Exam ID'}
                                            data={examIds}
                                            type={'reactSelect'}
                                            placeHolder={'Exam ID'}
                                            onChange={(value)=>{updateSearchData('examId',value)}}
                                            defaultValue={searchData["examId"]}
                                            isDisabled={false}
                                    />
                                </Col>
                                <Col md={4}>
                                    <CommonInput label={'Business Area'}
                                            data={businessArea}
                                            type={'reactSelect'}
                                            placeHolder={'Business Area'}
                                            onChange={(value)=>{updateSearchData('businessAreaId',value)}}
                                            defaultValue={searchData["businessAreaId"]}
                                            isDisabled={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    {/* <Button color="info" size="sm"
                                        // className={"floatRight mx-1" +((!selectedAcademicCareer && !selectedCompanyCode && !selectedGroupCode) ? ' btn-dark' : null)}
                                            className={"floatRight mx-1"}
                                            onClick={ ()=>resetHandler()}
                                    >
                                        Resett
                                    </Button> */}
                                    <CustomButton 
                                        className={ "floatRight mx-1" }
                                        content={'Reset'}
                                        type={'reset'}
                                        permissionType={'S'}
                                        permissionSet={userPermissions}
                                        forTable={true}
                                        onClick={ ()=>resetHandler()}
                                    />            
                                    {/* <Button color="info" size="sm" className="floatRight mx-1" onClick={()=>searchHandler()}>
                                        Searchh
                                    </Button> */}
                                    <CustomButton 
                                        className={ "floatRight mx-1" }
                                        content={'Search'}
                                        icon={true}
                                        permissionType={'S'}
                                        forTable={true}
                                        permissionSet={userPermissions}
                                        onClick={ ()=>searchHandler()}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        </Row>
    )
}
export default InternalTestResultSearchForm