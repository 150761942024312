import React, { useState } from 'react'
import { ImCross } from 'react-icons/im';
import { ReactComponent as Search } from "../../../../../assets/img/svg/SearchIcon.svg";
import './batch-style.scss';
const crossIconStyle = {
  fontSize: "11px",
  position: "absolute",
  top: "0.6rem",
  right: "0.7rem",
  cursor: "pointer",
}
const errorStyle = {
    position:"absolute",
    left:"0px",
    top:"30px",
    fontSize:"12px",
    color:"red",
    opacity:".8"
    
}

const BatchSearchInput = ({ searchBatchNameHandler ,onCancel }) => {
  const [batchName, setBatchName] = useState('')
  const [isError, setError] = useState(false)
  const handler = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      if(!isError)
      searchBatchNameHandler(batchName)
    }
  }

  const clickHandler = (e) => {
      if(batchName !== '')
      searchBatchNameHandler(batchName)
    }
  
 const onChangeHandler = (value)=>{
   setBatchName(value)
   if(value.trim().length>=4){
    setError(false)
   }else{
    setError(true)
   }
 }
 const onBlurHandler = ()=>{
  if(batchName.length === 0){
    setError(false)
  }
 }
  return (
    <div  style={{ position: "relative" }} >
    <span className='search-input-wrapper'> <Search onClick = {clickHandler}/></span>
      <input
       className='search-input'
        id="batchName"
        value={batchName}
        placeholder ="Search by batch name"
        onChange={(e) => {
          onChangeHandler(e.target.value);
        }}
        onKeyDown={handler}
        onBlur = {onBlurHandler}
        style={{ minWidth: "15rem", border: "1px solid #00aeef" }}
      />
      {isError && <span style={errorStyle}>At least 4 characters are required</span>}
      {batchName.length > 0 && 
        <ImCross onClick={() =>{
             setBatchName('')
             onCancel();
            }} style={crossIconStyle} />
      }
    </div>


  )
}

export default BatchSearchInput