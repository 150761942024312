import React, { useState } from "react";
import EmployDetailsForm from "./EmployDetailsForm";
import SelectMaterialForm from "./SelectMaterialForm";
import { useFetchDropdown } from "../../../issuanceIDcard/customhooks";
import ALoader from "views/pages/manage/common/a-loader";
import IssueStockConfirmation from "../../selectMeterialAndStock/issueStock/IssueStockConfirmation";
import {
  createRequestFormat,
  employeeDetailsFormFields,
  useFetchSapCostCenter,
  verifyEmpId,
} from "./helper";
import { issueStockApi } from "../../service";
import IssueStockSuccessPrompt from "../../selectMeterialAndStock/issueStock/IssueStockSuccessPrompt";
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import { successToast } from "../../../../common/utils/methods/toasterFunctions/function";
const IssueStockForm = ({ isDlp, sapPostingDate, academicType }) => {
  // forms
  const [employeeDetailsForm, setEmployeeDetailsForm] = useState(
    employeeDetailsFormFields
  );
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [isEmpIdVerified, setEmpIdVerified] = useState(false);
  const [isErrorWhileVerify, setErrorWhile] = useState(false);

  const [isLoading, setLoading] = useState(false);

  // dropdown
  const [dropdown, isDropdownLoading] = useFetchDropdown(academicType);
  const sapCostDropdown = useFetchSapCostCenter(
    employeeDetailsForm?.businessArea
  );

  const [issuedSuccess, setIssuedSuccess] = useState(false);
  const [successResponse, setSuccessResponse] = useState({});
  const [issueStockConfirmation, setIssueStockConfirmation] = useState(false);

  const employeeFormHandler = (value, key) => {
    if (key === "psid") {
      setEmpIdVerified(false);
      setErrorWhile(false);
    }
    setEmployeeDetailsForm((prev) => ({ ...prev, [key]: value }));
  };

  const issueStockHandler = async () => {
    try {
      if (
        employeeDetailsForm?.businessArea === "" ||
        employeeDetailsForm?.reason === "" ||
        employeeDetailsForm?.transactionType === "" ||
        employeeDetailsForm?.reason === "" ||
        employeeDetailsForm?.sapCostCenter === "" ||
        employeeDetailsForm?.psid === ""
      ) {
        failureToast("Please select all  required field");
        return;
      }
      if (!isEmpIdVerified) return failureToast("Please verify empID ");

      if (selectedMaterials.length === 0) {
        failureToast("Please add a material details");
        return;
      }
      if (!employeeDetailsForm?.s3key) {
        failureToast("Please add attachment file");
        return;
      }
      setIssueStockConfirmation(true);
    } catch (error) {}
  };
  const onSuccessCancel = () => {
    setIssuedSuccess(false);
    successResponse({});
  };

  const onAgree = async () => {
    try {
      setLoading(true);
      setIssueStockConfirmation(false);
      const request = createRequestFormat(
        employeeDetailsForm,
        selectedMaterials,
        academicType
      );
      request["sapPostingDate"] = sapPostingDate;
      const response = await issueStockApi(request);
      setLoading(false);
      if (response?.code === 200) {
        successToast("Request completed successfully");
        setIssuedSuccess(true);
        setSuccessResponse(response?.data);
        setEmployeeDetailsForm({ ...employeeDetailsFormFields });
        setSelectedMaterials([]);
        setEmpIdVerified(false);
      } else {
        failureToast(`${response?.message} \n 
         Branch Consumption ID : ${response?.data.transactionId} failed. Retry.`);
      }
    } catch (error) {}
  };

  const verifyEmpIdHandler = async () => {
    if (employeeDetailsForm?.psid === "")
      return failureToast("Please enter a valid Employee ID");

    try {
      setLoading(true);
      const response = await verifyEmpId(employeeDetailsForm?.psid);
      setLoading(false);
      if (response?.code === 200) {
        if (response.data?.includes(employeeDetailsForm?.businessArea?.value)) {
          setEmpIdVerified(true);
        } else {
          failureToast("Employee ID not found in the selected BU. Try another");
        }
      } else {
        setErrorWhile(true);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const isAllTransactionDetailsFilled = (form) => {
    return (
      form?.transactionType !== "" &&
      form?.reason !== "" &&
      form?.sapCostCenter !== ""
    );
  };

  return (
    <div>
      {(isDropdownLoading || isLoading) && <ALoader position="fixed" />}
      <EmployDetailsForm
        dropdown={dropdown}
        employeeFormHandler={employeeFormHandler}
        sapCostDropdown={sapCostDropdown}
        form={employeeDetailsForm}
        verifyEmpIdHandler={verifyEmpIdHandler}
        isErrorWhileVerify={isErrorWhileVerify}
        isEmpIdVerified={isEmpIdVerified}
      />
      <SelectMaterialForm
        form={employeeDetailsForm}
        setFormHandler={employeeFormHandler}
        selectedMaterials={selectedMaterials}
        setSelectedMaterials={setSelectedMaterials}
        issueStockHandler={issueStockHandler}
        loading={isLoading}
        setLoading={setLoading}
        isAllTransactionDetailsFilled={isAllTransactionDetailsFilled(
          employeeDetailsForm
        )}
        isDlp={isDlp}
      />
      <IssueStockSuccessPrompt
        isOpen={issuedSuccess}
        setIsOpen={setIssuedSuccess}
        responseData={successResponse}
        message="Your branch consumption ID"
      />
      <IssueStockConfirmation
        open={issueStockConfirmation}
        setOpen={setIssueStockConfirmation}
        onAgree={onAgree}
        message="Do you want to issue stock as per the submitted details?"
      />
    </div>
  );
};

export default IssueStockForm;
