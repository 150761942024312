import React from "react";
import { ReactComponent as BackIcon } from "../../../../../../assets/img/svg/BackButton.svg";
import CustomChip  from 'views/pages/manage/common/customChip/CustomChip';
import { useHistory } from 'react-router';
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper";
const BackBtnWithChip = ({setOpenForm, openMode, allLecturesCount, currentLecturesCount}) => {
     
    const history = useHistory();

  return (
    <div className="d-flex align-items-center">
      <IconButtonWrapper
        onClick={() => {
          if (openMode === "NEW") {
            setOpenForm("ACADEMIC_TEST");
          } else {
            setOpenForm("TIMETABLE");
          }
        }}
      >
        <BackIcon />
      </IconButtonWrapper>

      <div
        style={{
          fontSize: "20px",
          fontWeight: 600,
          lineHeight: "28px",
          color: "#0F0F0F",
        }}
      >
        Lecture Details
      </div>
      <CustomChip
        text={`${currentLecturesCount}/${allLecturesCount} Lectures added`}
        textColor="#FFFFFF"
        bgColor={"#572AC8"}
        style={{ marginLeft: "24px" }}
      />
    </div>
  );
};

export default BackBtnWithChip;
