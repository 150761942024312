import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { getStudentList, loadDropdowns, searchStudentClear, startLoader, stopLoader } from "../../../../../redux/studentSearch/actions";
import ALoader from "../../common/a-loader";
import LeftSideFilter from "./LeftSideFilter";
import Filter from "./Filter";
import StudentList, { SUPPLEMENTARY_ID_FLAG } from "./StudentList";
import { filterFormModal, searchInputFormModal } from '../constant/student-search';
import { getDataSetMap } from '../constant/common'
import './style.scss'
import { isFilterApplied } from "../constant/batch-search";
import { useRegionAndBusinessArea } from "../../common/utils/customHooks";
import { endpoint, pages } from "../../common/constant";
import { dropdownMapping } from "../constant/allocate-students";
import AddToFavourite from '../../homepage/favourites/AddToFavourite';
import { RolePermissions, PermissionContext } from "appContext";
import AButton from "../../common/form-fields-mui/AButton";
import { useHistory } from "react-router";

const StudentSearchPage = () => {
  const userPermissions = RolePermissions(useContext(PermissionContext), pages['searchStudent']['id']);
  const approveSupplementaryIdPermissions = RolePermissions(useContext(PermissionContext), pages['approveSupplementaryId']['id']);

  const history = useHistory();
  const dispatch = useDispatch();
  const studentSearch = useSelector((state) => state.studentSearch);
  const [filterForm, setFilterForm] = useState(filterFormModal);
  const [searchInputForm, setSearchInputForm] = useState(searchInputFormModal);
  const [regionAndBusiness, setRegionAndBusiness] = useRegionAndBusinessArea(() => dispatch(startLoader()), () => dispatch(stopLoader()));
  const [currentPageNumber, setPageNumber] = useState(0);
  const [appliedFilterCount, setAppliedFilterCount] = useState(0);
  const [businessAreaMap, setBusinessAreaMap] = useState({})
  const [courseIdDropdown, setCourseIdDropdown] = useState([]);

  const inputHandler = (value, inputName) => {
    let currentData = { ...filterForm };
    if (inputName === "businessAreaClear") {
      currentData = { ...filterForm, businessArea: [] };
      currentData["region"] = value;
      setFilterForm(currentData);
    } else {
      currentData[inputName] = value;
      setFilterForm(currentData);
    }
  };

  const searchInputFormHandler = (key, value) => {
    setSearchInputForm(prev => ({...prev, [key]: value}))
  }

  const leftSideFormInputHandler = (value, inputName) => {
    setRegionAndBusiness({ ...regionAndBusiness, [inputName]: value })
  }

  const getAllCourseIds = async () => {
    const res = await dropdownMapping(endpoint?.courseCatelogMapping?.getAllActiveCourseIds, 'courseIds');
    setCourseIdDropdown(res);
  }

  useEffect(() => {
    getAllCourseIds();
    dispatch(loadDropdowns(fetchInitialList));
    return ()=>dispatch(searchStudentClear())
  }, []);


  const fetchInitialList = (data) => {

    let tempRequest = ({
      businessArea: (data?.businessAreas || []),
      region: (data?.regions || []),
    });
    setRegionAndBusiness(tempRequest)
    dispatch(
      getStudentList(
        { ...tempRequest, ...searchInputForm, ...filterForm },
        0
      )
    );
    setBusinessAreaMap(getDataSetMap(data?.businessAreas))

  }



  const searchHandler = () => {
    dispatch(getStudentList({ ...filterForm, ...searchInputForm, ...regionAndBusiness }));
  };

  const nextButtonHandler = (pageNumber) => {
    dispatch(getStudentList({ ...filterForm, ...searchInputForm, ...regionAndBusiness }, pageNumber));
    setPageNumber(pageNumber);
  };

  const applyFilterHandler = () => {
    setPageNumber(0);
    dispatch(getStudentList({ ...filterForm, ...searchInputForm, ...regionAndBusiness }, 0));
    setAppliedFilterCount(isFilterApplied(filterForm))
  };

  const removeFilterHandler = () => {
    setPageNumber(0);
    dispatch(getStudentList({ ...filterFormModal, ...searchInputForm, ...regionAndBusiness }, 0));
    setAppliedFilterCount(0)
    setFilterForm(filterFormModal)
  };
 
  const crossInputHandler = () => {
    setPageNumber(0);
    setSearchInputForm(searchInputFormModal)
    dispatch(getStudentList({...filterForm, ...searchInputFormModal, ...regionAndBusiness}, 0));
  };
  
  const handleApproveClick = () => {
    window.open(
      history.createHref({
        pathname: '/admin/edp/approveSupplementaryId',
      }),
      '_blank'
    );
  }
  
  return (
    <>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          flexFlow: 'column',
          height: 'calc(100vh - 120px)',
        }}
      >
        {studentSearch.loading && <ALoader />}
        <div className='mx-3 d-flex align-items-center justify-content-end mb-3'>
          <AddToFavourite
            screenName='Search student'
            screenId={pages['searchStudent']['id']}
            size='sm'
          />
          {SUPPLEMENTARY_ID_FLAG && approveSupplementaryIdPermissions && <AButton variant='link' size='sm' onClick={() => handleApproveClick()}>
            Approve Supplementary ID
          </AButton>}
        </div>
        <Card className='mx-3 mb-2' style={{ flexGrow: 1 }}>
          <CardBody style={{ display: 'flex', flexFlow: 'column' }}>
            <div className='search-match-container'>
              <LeftSideFilter
                filterForm={regionAndBusiness}
                inputHandler={leftSideFormInputHandler}
                defaultRegionAndBusiness={regionAndBusiness}
                setDefaultRegionAndBusiness={setRegionAndBusiness}
                searchHandler={searchHandler}
              />
              <Filter
                // data={batch.batchList || []}
                filterForm={filterForm}
                regionAndBusiness={regionAndBusiness}
                setNormalFilterForm={setFilterForm}
                inputHandler={inputHandler}
                applyFilterHandler={applyFilterHandler}
                setAppliedFilterCount={setAppliedFilterCount}
                appliedFilterCount={appliedFilterCount}
                removeFilterHandler={removeFilterHandler}
                crossInputHandler={crossInputHandler}
                courseIdDropdown={courseIdDropdown}
                searchInputForm={searchInputForm}
                searchInputFormHandler={searchInputFormHandler}
              />
            </div>
            <StudentList
              rowsData={studentSearch.studentList || []}
              rowCount={studentSearch.rowCount || 0}
              nextButtonHandler={nextButtonHandler}
              currentPage={currentPageNumber}
              businessAreaMap={businessAreaMap}
              userPermissions={userPermissions}
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default StudentSearchPage