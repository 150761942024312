import React from "react";
import {
  Card,
  CardHeader,
  Input,
  Container,
  Table,
  Row,
} from "reactstrap";
import { useState } from "react";
import { failureToast, successToast } from "../../common/utils/methods/toasterFunctions/function";
import { TDROW } from "../../common/commonComponents/masterRow/masterRow";
import moment from "moment";
import { ButtonText } from "variables/Buttons";
import CustomButton from "../../../../../components/CustomButton";
import { endpoint } from "../../common/constant";
import { fetchAllPostPromisedData, fetchDeletePromisedData, getCurrentDate } from "../../common/utils/methods/commonMethods/utilityMethod";
import validationRules from './validation.json'
import { customValidation } from "../../common/utils/methods/validations/validation";
import { editTableRow, newTableRow } from "../../common/utils/methods/commonMethods/tableMethods";
import { MasterHeaderAuto } from "../../common/commonComponents/masterHeader/masterHeader";
var _ = require("lodash");

const WebsiteSyncTable = (props) => {
  const { permissionSet, isSaveVisible, setisSaveVisible = () => { }, getAllrecords = () => { } } = props;
  const [tableDataKey, settableDataKey] = useState("tableDataKey");

  const headerList = [
    {name: 'WEBSITE SYNC KEY', isRequired: true},
    {name: 'EFFECTIVE DATE'},
    {name: 'DESCRIPTION', isRequired: true},
    {name: 'DISPLAY VALUE', isRequired: true},
    {name: 'STATUS'},
    {name: 'ACTION'}
  ]

  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: "__new",
        websiteSyncKey: null,
        status: "ACTIVE",
        websiteSyncDesc: null,
        websiteSyncDispValue: null,
        effectiveDate: null,
      },
      ...props.classArray,
    ]);
  };
  const saveAll = async (index) => {
    let _data = props.classArray[index];
    let isValid = true;
    isValid = customValidation(_data, validationRules);

    if (!isValid) {
      return;
    }
    if (_data["__status"] == "__new") {
      const createRecord = await fetchAllPostPromisedData(endpoint.websiteSync.create, _data, 'post')
      if (createRecord?.code === 200) {
        successToast(createRecord["message"]);
        setisSaveVisible(false);
        getAllrecords()
      }
    } else {
      const createRecord = await fetchAllPostPromisedData(`${endpoint.websiteSync.update}/${_data.id}`, _data, 'put')
      if (createRecord?.code === 200) {
        successToast(createRecord["message"]);
        setisSaveVisible(false);
        getAllrecords()
      }
    }
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      newTableRow(props.classArray,props.setclassArray,setisSaveVisible,addNewClass);
    }
  };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      editTableRow(index,props.classArray,props.setclassArray,setisSaveVisible);
    }
  };
  const deleteRow = async (index) => {
      if (
        window.confirm("Are you sure you want to delete this Website Sync?")
      ) {
        const deleteRecords = await fetchDeletePromisedData(`${endpoint.websiteSync.delete}/${props.classArray[index].id}`);
        if(deleteRecords?.code === 200){
          let flag=false;
          if(props.classArray.length==1){
            flag=true;
          }
          getAllrecords(flag);
          settableDataKey(new Date().getMilliseconds());
        }
      }
    // }
  };
  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
  };
  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Website Sync</h3>
                <CustomButton 
                  data-testId="addNewWebsiteSync"
                  disabled={isSaveVisible}
                  className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
                  content={ButtonText.WebsiteSync.addNew}
                  permissionType={'C'}
                  icon={true}
                  permissionSet={permissionSet}
                  onClick={() => newRow()}
                />
                {/* {isSaveVisible?<Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => {saveAll()}}>Save</Button>:null} */}
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout">
                  {/* <MasterHeader type={"Website Sync"} isSaveVisible={isSaveVisible} permissionSet={permissionSet} /> */}
                  <MasterHeaderAuto headerList={headerList} isSaveVisible={isSaveVisible} permissionSet={permissionSet} />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                        !el["editMode"] ? (
                          <TDROW
                            type={"websiteSync"}
                            index={index}
                            el={el}
                            editRow={editRow}
                            deleteRow={deleteRow}
                            isSaveVisible={isSaveVisible} 
                            permissionSet={permissionSet}
                          />
                        ) : (
                          <tr key={index + "-class"}>
                            <td className="text-center p-2 mx-1">
                              <Input
                                data-testid="websiteSyncKey"
                                maxLength={50}
                                defaultValue={el["websiteSyncKey"]}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    e.target.value,
                                    "websiteSyncKey"
                                  );
                                }}
                                placeholder="Example:1"
                                className="tdInput"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <Input
                                data-testid="effectiveDate"
                                disabled={true}
                                defaultValue={
                                  el["__status"] == "__new"
                                    ? getCurrentDate()
                                    : moment(el["effectiveDate"]).format(
                                      "DD-MM-YYYY"
                                    )
                                }
                                placeholder="Example:01-01-2020"
                                className="tdInput"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <Input
                                data-testid="websiteSyncDesc"
                                maxLength={50}
                                defaultValue={el["websiteSyncDesc"]}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    e.target.value,
                                    "websiteSyncDesc"
                                  );
                                }}
                                placeholder="Description"
                                className="tdInput"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <Input
                                data-testid="websiteSyncDispValue"
                                maxLength={50}
                                defaultValue={el["websiteSyncDispValue"]}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    e.target.value,
                                    "websiteSyncDispValue"
                                  );
                                }}
                                placeholder="Display Value"
                                className="tdInput"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <label className="custom-toggle mx-auto ml-2">
                                <input
                                  data-testid="activeInactiveWebsiteSync"
                                  checked={
                                    el.status == "ACTIVE" ? true : false
                                  }
                                  type="checkbox"
                                  id={el.id}
                                  onChange={(e) => {
                                    settableDataKey(
                                      new Date().getMilliseconds()
                                    );
                                    let n = "ACTIVE";
                                    if (!e.target.checked) {
                                      n = "INACTIVE";
                                    }
                                    updateKey(index, n, "status");
                                  }}
                                />
                                <span
                                  data-testid="aciveInactiveLabelWebsiteSync"
                                  style={{ width: "72px" }}
                                  className="custom-toggle-slider rounded-circle activeToggle"
                                  data-label-off="Inactive"
                                  data-label-on="Active"
                                />
                              </label>
                            </td>
                            <td className="text-center p-2 mx-1">
                              {/* <Button color="info" size="sm" type="button" onClick={() => {
                          deleteRow(index)
                        }}>  <i className="fas fa-trash" /></Button> */}
                              {isSaveVisible ? (
                                <>
                                  {/* <UpdateButton text={ButtonText.WebsiteSync.save} data-testid="saveWebsiteSync" onClick={() => { saveAll(index) }} /> */}
                                  <CustomButton 
                                    data-testid="saveWebsiteSync"
                                    className={'mx-1'}
                                    content={ButtonText.WebsiteSync.save}
                                    permissionType={'C,U'}
                                    forTable={true}
                                    permissionSet={permissionSet}
                                    onClick={() => { saveAll(index) }}
                                  />
                                  <CustomButton 
                                    data-testId="closeWebsiteSync"
                                    className={'mx-1'}
                                    permissionType={'cancel'}
                                    forTable={true}
                                    icon={true}
                                    permissionSet={permissionSet}
                                    onClick={() => {
                                      let n = props.classArray;
                                      if (n[index]["__status"] == "__new") {
                                        n.splice(index, 1);
                                      } else {
                                        // n[index]['editMode']=false
                                        props.getAllrecords();
                                      }
                                      // props.setclassArray(n);
                                      setisSaveVisible(false);
                                    }}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </td>
                          </tr>
                        )
                      )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default WebsiteSyncTable;
