import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Card,
  CardHeader,
  Container,
  Button,
  Table,
  Row,
} from "reactstrap";
import { useState } from "react";
// import "views/pages/manage/addressType/node_modules/react-sliding-pane/dist/react-sliding-pane.css";
import { failureToast, successToast } from "../../common/utils/methods/toasterFunctions/function";
import { MasterHeaderAuto, Rows } from "../../common/commonComponents/masterHeader/masterHeader";
import {
  documentServiceBaseUrl,
  putpost,
  putpost2,
} from "services/http";
import { NewRow } from "../../common/utils/methods/commonMethods/utilityMethod";

import Select2 from "react-select2-wrapper";
import { CustomToggle } from '../../common/formFeilds';
import CardFooter from "reactstrap/lib/CardFooter";
import { ButtonText } from 'variables/Buttons';
import CustomButton from "components/CustomButton";
import { PermisionDenied } from "../../common/commonComponents";
var _ = require("lodash");


const DocumentProcessTagTable = (props) => {
  const {
    serverData,
    setSearch,
    resetSearch,
    isSaveVisible,
    setisSaveVisible,
    personTypeData = [],
    documentStatusData = [],
    processTypeData = [],
    action = "new",
    id,
    personTypeActiveData = [],
    processTypeActiveData = [],
    documentStatusActiveData = [],
    openNewRow,
    setOpenNewRow = () => { },
    userPermissions
  } = props;

  const [tableDataKey, settableDataKey] = useState("tableDataKey");
  const [updatedKey, setUpdateKey] = useState(new Date());
  const [searchStr, setSearchStr] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: "__new",
        status: "ACTIVE",
        documentTypeId: null,
        personTypeId: null,
        processTypeId: null,
        mandatory: "false",
        updatedDate: null,
        sendNotification: "false",
        physicalDocRequired: "false",
      },
      ...props.classArray,
    ]);
  };

  const headerList = [
    { name: `Document Type`, isRequired: true },
    { name: `Person Type`, isRequired: true },
    { name: `Process Type`, isRequired: true },
    { name: "Notification" },
    { name: "Physical Document Required" },
    { name: "Mandatory" },
    { name: "Status" },
    { name: "Action" },
  ];
  const boolList = [
    { id: true, text: "Yes" },
    { id: false, text: "No" },
  ];
  const listToRender = [
    "documentTypeId",
    "personTypeId",
    "processTypeId",
    "sendNotification",
    "physicalDocRequired",
    "mandatory",
    "status",
  ];

  const itemToBeRenderFromMaster = [
    "documentTypeId",
    "personTypeId",
    "processTypeId",
    "sendNotification",
    "physicalDocRequired",
    "mandatory",
  ];
  const mastersList = [
    documentStatusData,
    personTypeData,
    processTypeData,
    boolList,
    boolList,
    boolList,
  ];
  const propertyList = ["text", "text", "text", "text", "text", "text"];

  const saveAll = (cb) => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if (el["editMode"]) {
        if (!el["documentTypeId"]) {
          isValid = false;
          failureToast("Please Select  Document Type");
          return;
        } else if (!el["personTypeId"]) {
          isValid = false;
          failureToast("Please Select  Person Type");
          return;
        } else if (!el["processTypeId"]) {
          isValid = false;
          failureToast("Please Select  Process Type");
          return;
        }
        // else if (
        //   !el['documentProcessTagShortDesc'] ||
        //   !el['documentProcessTagShortDesc'].trim()
        // ) {
        //   isValid = false;
        //   failureToast('Please Enter Short Description');
        //   return;
        // }
        if (!isValid) {
          return;
        }
        if (_tempArray[index]["__status"] == "__new") {
          putpost(
            documentServiceBaseUrl + "/DocumentProcessTag/createDocumentProcessTag",
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              setUpdateKey(new Date());
              // props.fetchClasses(`${masterServiceBaseUrl}/DocumentProcessTag/getAllDocumentProcessTag`);
              // props.history.push(`/admin/documentProcessTag`)
              setOpenNewRow(false);
              props.fetchClasses(
                `${documentServiceBaseUrl}/DocumentProcessTag/getAllDocumentProcessTag`
              );
            },
            (data) => {
              failureToast(data["message"]);
            },
            _tempArray[index],
            "post"
          );
        } else {
          putpost(
            documentServiceBaseUrl +
            "/DocumentProcessTag/updateDocumentProcessTag/" +
            _tempArray[index].id,
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              // delete _tempArray[index]['editMode']
              // props.setclassArray(_tempArray)
              action !== "new" && props.fetchClasses(
                `${documentServiceBaseUrl}/DocumentProcessTag/getDocumentProcessTagByDocumentTypeId/${id}`
              );
              action === "new" &&
                props.fetchClasses(
                  `${documentServiceBaseUrl}/DocumentProcessTag/getAllDocumentProcessTag`
                );
              settableDataKey(new Date().getMilliseconds());
              // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
            },
            (data) => {
              failureToast(data["message"]);
            },
            _tempArray[index],
            "put"
          );
        }
      } else {
      }
    });
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
        // settableDataKey(new Date().getMilliseconds())
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      cb();
    }
  };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]["editMode"] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };
  const deleteRow = (index) => {
    if (props.classArray[index]["__status"] == "__new") {
      let n = props.classArray;
      n.splice(index, 1);
      props.setclassArray(n);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
    } else {
      if (
        window.confirm(
          "Are you sure you want to delete this Document Process Tag?"
        )
      ) {
        putpost2(
          documentServiceBaseUrl +
          "/DocumentProcessTag/deleteDocumentProcessTag/" +
          props.classArray[index].id,
          (data) => {
            successToast(data["message"]);
            if (props.classArray.length == 1) {
              // props.previousPage();
            } else {
              var _tempArray = props.classArray;
              if (_tempArray[index]["editMode"]) {
                setisSaveVisible(false);
              }
              _tempArray.splice(index, 1);
              props.setclassArray(_tempArray);
            }
            action !== "new" &&
              props.fetchClasses(
                `${documentServiceBaseUrl}/DocumentProcessTag/getDocumentProcessTagByDocumentTypeId/${id}`
              );
            action === "new" &&
              props.fetchClasses(
                `${documentServiceBaseUrl}/DocumentProcessTag/getAllDocumentProcessTag`
              );
            settableDataKey(new Date().getMilliseconds());
          },
          (data) => {
            failureToast(data["message"]);
          },
          "PUT"
        );
      }
    }
  };

  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
    // setUpdateKey(new Date());
  };

  // const filterData = (str) => {
  //   const newData =
  //     serverData &&
  //     serverData.filter((obj) =>
  //       Object.values(obj).some(
  //         (val) =>
  //           val && val.toString().toLowerCase().includes(str.toLowerCase())
  //       )
  //     );
  //   setSearch(newData);
  // };

  // useEffect(() => {
  //   if (!searchStr || !searchStr.trim()) {
  //     resetSearch();
  //     setIsDisabled(false);
  //   } else {
  //     setIsDisabled(true);
  //     filterData(searchStr);
  //   }
  // }, [searchStr]);

  useEffect(() => {
    if (openNewRow) {
      newRow();
    }
  }, []);

  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0 d-flex justify-content-between">
                <h3 className="mb-0 ">Document Process Tag</h3>

                {action === 'new' &&
                  // <Button
                  //   disabled={isSaveVisible || isDisabled}
                  //   color="info"
                  //   size="sm"
                  //   type="button"
                  //   className={
                  //     "floatRight" +
                  //     (isSaveVisible || isDisabled ? " btn-dark" : null)
                  //   }
                  //   onClick={() => {
                  //     newRow();
                  //   }}
                  //   data-testid="addNewdocumentProcessTag"
                  // >
                  //   New Document Process Tag
                  // </Button>
                  <CustomButton
                    disabled={isSaveVisible || isDisabled}
                    className={'floatRight mx-1' + (isSaveVisible || isDisabled ? " btn-dark" : null)}
                    content={ButtonText.PROCESSTAGTYPE.NEW}
                    permissionType={'C'}
                    icon={true}
                    onClick={() => { newRow(); }}
                    permissionSet={userPermissions}
                  />
                }
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush ">
                  <MasterHeaderAuto headerList={headerList} />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                        !el["editMode"] ? (
                          //
                          <>
                            <NewRow
                              data={el}
                              listToBeRendered={listToRender}
                              itemToBeRenderFromMaster={
                                itemToBeRenderFromMaster
                              }
                              arrList={mastersList}
                              propertyList={propertyList}
                            >
                              <td className="text-center  white-breakSpace">
                                {/* <Button
                                  color={
                                    action === "view" ? "secondary" : "info"
                                  }
                                  size="sm"
                                  type="button"
                                  disabled={action === "view"}
                                  onClick={() => editRow(index)}
                                // data-testid="editButton"
                                >
                                  <i className="fas fa-edit" />
                                </Button>
                                <Button
                                  color={
                                    action === "view" ? "secondary" : "info"
                                  }
                                  size="sm"
                                  type="button"
                                  disabled={action === "view"}
                                  onClick={() => deleteRow(index)}
                                // data-testid="deletebtn"
                                >
                                  <i className="fas fa-trash" />
                                </Button> */}
                                <CustomButton
                                  // content={ButtonText.DOCUMENTTYPE.UPDATE}
                                  permissionType={'U'}
                                  icon={true}
                                  forTable={true}
                                  disabled={action === "view"}
                                  onClick={() => editRow(index)}
                                  permissionSet={userPermissions}
                                />
                                <CustomButton
                                  // content={ButtonText.DOCUMENTTYPE.DELETE}
                                  permissionType={'D'}
                                  icon={true}
                                  forTable={true}
                                  disabled={action === "view"}
                                  onClick={() => deleteRow(index)}
                                  permissionSet={userPermissions}
                                />
                              </td>
                            </NewRow>
                          </>
                        ) : (
                          <tr key={index + "-class"}>
                            <td className="text-center p-1 mx-1">
                              <Select2
                                defaultValue={el["documentTypeId"]}
                                data={documentStatusActiveData}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    Number(e.target.value),
                                    "documentTypeId"
                                  );
                                }}
                                options={{
                                  placeholder: "Select Document Type",
                                }}
                                className="tdSelect"
                                id="documentTypeId"
                              />
                            </td>
                            <td className="text-center p-1 mx-1">
                              <Select2
                                defaultValue={el["personTypeId"]}
                                data={personTypeActiveData}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    Number(e.target.value),
                                    "personTypeId"
                                  );
                                }}
                                options={{
                                  placeholder: "Select Person Type",
                                }}
                                className="tdSelect"
                                id="personTypeId"
                              />
                            </td>
                            <td className="text-center p-1 mx-1">
                              <Select2
                                defaultValue={el["processTypeId"]}
                                data={processTypeActiveData}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    Number(e.target.value),
                                    "processTypeId"
                                  );
                                }}
                                options={{
                                  placeholder: "Select Process Type",
                                }}
                                className="tdSelect"
                                id="processTypeId"
                              />
                            </td>
                            <td className="text-center p-1 mx-1">
                              <Select2
                                defaultValue={el[
                                  "sendNotification"
                                ].toString()}
                                data={boolList}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    e.target.value,
                                    "sendNotification"
                                  );
                                }}
                                className="tdSelect"
                                id="sendNotification"
                              />
                            </td>
                            <td className="text-center p-1 mx-1">
                              <Select2
                                defaultValue={el[
                                  "physicalDocRequired"
                                ].toString()}
                                data={boolList}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    e.target.value,
                                    "physicalDocRequired"
                                  );
                                }}
                                className="tdSelect"
                                id="physicalDocRequired"
                              />
                            </td>
                            <td className="text-center p-1 mx-1">
                              <Select2
                                defaultValue={el["mandatory"].toString()}
                                data={boolList}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    e.target.value,
                                    "mandatory"
                                  );
                                }}
                                className="tdSelect"
                                id="mandatory"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <label className="custom-toggle mx-auto ml-2">
                                <CustomToggle
                                  status={el.status}
                                  data-testid="documentProcessTagStatus"
                                  id={el.id}
                                  toggleOn={"Active"}
                                  toggleOff={"Inactive"}
                                  onChangeHandler={(e) => {
                                    settableDataKey(
                                      new Date().getMilliseconds()
                                    );
                                    let n = 'ACTIVE';
                                    if (!e.target.checked) {
                                      n = 'INACTIVE';
                                    }
                                    updateKey(index, n, 'status');
                                  }}
                                />
                              </label>
                            </td>
                            <td
                              className="text-center"
                              data-testid="saveConcessiondocumentProcessTag"
                            >
                              {isSaveVisible ? (
                                <>
                                  <CustomButton
                                    content={ButtonText.DOCUMENTFORMAT.UPDATE}
                                    forTable={true}
                                    permissionType={'U'}
                                    icon={false}
                                    onClick={() => saveAll()}
                                    permissionSet={userPermissions}
                                  />

                                  <CustomButton
                                    forTable={true}
                                    permissionType={'cancel'}
                                    icon={true}
                                    onClick={() => {
                                      let n = props.classArray;
                                      if (n[index]["__status"] == "__new") {
                                        n.splice(index, 1);
                                        setOpenNewRow(false);
                                        props.fetchClasses(
                                          `${documentServiceBaseUrl}/DocumentProcessTag/getAllDocumentProcessTag`
                                        );
                                      } else {
                                        // n[index]['editMode'] = false
                                        if (action === "new") {
                                          props.fetchClasses(
                                            `${documentServiceBaseUrl}/DocumentProcessTag/getAllDocumentProcessTag`
                                          );
                                        } else {
                                          props.fetchClasses(
                                            `${documentServiceBaseUrl}/DocumentProcessTag/getDocumentProcessTagByDocumentTypeId/${id}`
                                          );
                                        }
                                      }
                                      props.setclassArray(n);
                                      setisSaveVisible(false);
                                    }}
                                    permissionSet={userPermissions}
                                  />
                                </>
                              ) : (
                                <CustomButton
                                  permissionType={'D'}
                                  icon={true}
                                  forTable={true}
                                  onClick={() => deleteRow(index)}
                                  permissionSet={userPermissions}
                                />
                              )}
                            </td>
                          </tr>
                        )
                      )
                      : null}
                  </tbody>
                </Table>
              </div>
              <CardFooter className="d-flex justify-content-end">
                {/* <Button
                  color="info"
                  size="sm"
                  type="button"
                  onClick={() => {
                    props.history.push("/admin/documentProcessTag");
                  }}
                >
                  {" "}
                  Close
                </Button> */}
                <CustomButton
                  permissionType={'cancel'}
                  content={'Close'}
                  forTable={true}
                  onClick={() => {
                    props.history.push("/admin/documentProcessTag");
                  }}
                  permissionSet={userPermissions}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default withRouter(DocumentProcessTagTable);
