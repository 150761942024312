import React, { useEffect, useState, useRef, Fragment } from "react";
import { Label, FormGroup, Row, Col, Button } from "reactstrap";
import AffiliationTable from "./affiliationDetailsTable";
import Select from "react-select";
import { failureToast } from "../../../../common/utils/methods/toasterFunctions/function";

 const AffiliationDetails = (props) => {
  const {
    affiliationDetails,
    formMode,
    externalOrganzationObj,
    setExternalOrganzationObj,
    affiliationBoardList,
    setAffiliationDetails,
    enableEditMode,
  } = props;
  const [isDisabled, setIsDisabled] = useState();
  const [affiliationBoardDisp, setAffiliationBoardDisp] = useState();
  const [editMode, setEditMode] = useState({
    index: undefined,
    editMode: undefined,
  });
  const affNum = useRef(null);

  useEffect(() => {
    formMode === "new" || enableEditMode
      ? setIsDisabled(false)
      : setIsDisabled(true);
  }, [formMode, enableEditMode]);

  const addAffiliationDetails = () => {
    try {
      let pattern = /^[0-9]+$/;

      if (
        affiliationDetails.affliationNumber.trim() === "" ||
        affiliationDetails.affliateBoard === ""
      )
        return failureToast("Please Enter affiliation details");

      if (!pattern.test(affiliationDetails["affliationNumber"]))
        return failureToast("Please enter valid affiliation number ");

      let tempAffiliation = externalOrganzationObj["affliationDetailsList"];
      tempAffiliation.push(affiliationDetails);
      setExternalOrganzationObj((preState) => ({
        ...preState,
        affliationDetailsList: tempAffiliation,
      }));
      setAffiliationDetails({
        affliationNumber: "",
        affliateBoard: "",
      });
      setAffiliationBoardDisp("");
      affNum.current.value = "";
    } catch (error) {
      console.log(error);
    }
  };

  const updateAffiliationDetails = (index) => {
    try {
      let pattern = /^[0-9]+$/;
      if (
        affiliationDetails.affliationNumber.trim() === "" ||
        affiliationDetails.affliateBoard === ""
      )
        return failureToast("Please Enter affiliation details");

      if (!pattern.test(affiliationDetails["affliationNumber"]))
        return failureToast("Please enter valid affiliation number ");

      let tempAffiliation = externalOrganzationObj["affliationDetailsList"];
      tempAffiliation[index] = affiliationDetails;
      setExternalOrganzationObj((preState) => ({
        ...preState,
        affliationDetailsList: tempAffiliation,
      }));
      setAffiliationDetails({
        affliationNumber: "",
        affliateBoard: "",
      });
      setEditMode({
        index: undefined,
        editMode: undefined,
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Fragment>
      {!isDisabled ? (
        <>
          <Row>
            <Col sm={6} lg={5} xl={5}>
              <FormGroup row>
                <Col md={12} lg={12} xl={4}>
                  <Label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Affiliation Number
                  </Label>
                </Col>
                <Col md={12} xl={8}>
                  <input
                    data-testid="affliationNumber"
                    disabled={isDisabled}
                    ref={affNum}
                    maxLength={10}
                    onChange={(value) => {
                      let affiliationNum = value.target.value;
                      setAffiliationDetails((preState) => ({
                        ...preState,
                        affliationNumber: affiliationNum,
                      }));
                    }}
                    className="form-control addresslocationInput"
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col sm={6} lg={5} xl={5}>
              <FormGroup row>
                <Col lg={12} xl={4}>
                  <Label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Affiliated Board
                  </Label>
                </Col>
                <Col lg={12} xl={8}>
                  <Select
                    value={{
                      value: affiliationDetails["affliateBoard"],
                      label: affiliationBoardDisp,
                    }}
                    isDisabled={isDisabled}
                    options={props.affiliationBoardList}
                    onChange={(e) => {
                      setAffiliationBoardDisp(e.label);
                      let affiliateBoard = e.value;
                      setAffiliationDetails((preState) => ({
                        ...preState,
                        affliateBoard: affiliateBoard,
                      }));
                    }}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col lg={2} xl={2} className="text-center mb-2">
              <Button
              data-testid="submitAffilationDetails"
                color="info"
                size="sm"
                className={`ml-auto`}
                disabled={
                  isDisabled
                    ? true
                    : externalOrganzationObj["affliationDetailsList"] &&
                      externalOrganzationObj["affliationDetailsList"].length >=
                        5
                    ? true
                    : false
                }
                onClick={() => {
                  addAffiliationDetails();
                }}
              >
                Add
              </Button>
            </Col>
          </Row>
        </>
      ) : null}
      <AffiliationTable
        setExternalOrganzationObj={setExternalOrganzationObj}
        externalOrganzationObj={externalOrganzationObj}
        setEditMode={setEditMode}
        editMode={editMode}
        affiliationBoardList={affiliationBoardList}
        setAffiliationDetails={setAffiliationDetails}
        updateAffiliationDetails={updateAffiliationDetails}
        isDisabled={isDisabled}
      />
    </Fragment>
  );
};


export default AffiliationDetails;