import React, { useState } from 'react'
import { putpost } from 'services/http'
import CustomButton from "components/CustomButton";
import { endpoint } from '../../constant';
import { failureToast } from '../../utils/methods/toasterFunctions/function';
import { ConfirmationDialog } from "views/pages/manage/common/commonComponents";
import { commonMessages } from "views/pages/manage/common/constant";
import './index.scss'

const CustomDownload = ({ isIcon = false, text, downloadKey, permissionSet, downloadUrl = endpoint.fetchS3Url.getDownloadUrl, content, type = "download", outline = false, color = "info" }) => {

    const [isInfoModalOpen,setInfoModalOpen] = useState(false)
    const [isLoading, setLoading] = useState();

    const getDownloadUrl = () => {
        if(downloadKey){
        setLoading(true)
        putpost(`${downloadUrl}?key=${downloadKey}`,
            (data) => {
                if (data.code === 200 && data.data) {
                    window.open(`${data.data.url}`)
                    setLoading(false)
                } else {
                    setLoading(false)
                    setInfoModalOpen(true)
                    // failureToast(data.message)
                }
            },
            (data) => {
                setLoading(false)
                setInfoModalOpen(true)
                // failureToast(data["message"]);
            },
            {},
            "post"
        );
        }else{
            setInfoModalOpen(true)
        }
    }


    return (
        <>
        {
            text ? 
            
                isLoading ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : <span className={`pointer`} onClick={getDownloadUrl}>{text}</span>
            
            :
        
            <CustomButton
                type={isLoading ? "loading" : type}
                className={`mx-1`}
                permissionType={'R'}
                color={color}
                icon={true}
                outline={outline}
                forTable={true}
                content={content}
                onClick={getDownloadUrl}
                permissionSet={permissionSet}
            />
        }
            <ConfirmationDialog
                type="info"
                msg={commonMessages.pricingErrorFileNotGenerated}
                setIsOpen={setInfoModalOpen}
                isOpen={isInfoModalOpen}
                headerMsg={"Info"}
                popupSymbol="!"
            />
        </>
    )
}

export default CustomDownload