import React, { useState, useEffect } from 'react';
import AcademicPhaseTable from './academicPhaseTable';
import CustomLoader from '../../common/commonComponents/Loader/loader';
import { masterServiceBaseUrl, failureToast, getAPI } from 'services/http';
import {
    GetPagination,
} from '../../common/commonComponents/pagination/pagination';

const AcademicPhase = (props) => {
    const [classArray, setclassArray] = useState([]);
    const [apiloader, setapiloader] = useState(false);
    const [state, setstate] = useState([]);
    const [serverData,setServerData] = useState([]);
    const [isSaveVisible, setisSaveVisible] = useState(false);
    const [pagination, setpagination] = useState({
        currentPage: 1,
        nextPage: null,
        previousPage: null,
        totalPage: null,
    });
    const pageSize = 10;
    const fetchAcademicPhase = async (url) => {
        setapiloader(true);
        getAPI(
            url,
            (data) => {
                setstate(data['data']);
                setServerData(data['data']);
                setPagination(data['data']);       
            },
            (data) => {
                failureToast(data['message']);
                setapiloader(false);
            }
        );
    };

    const setPagination = (paramsdata) => {
        let n = pagination;
        if (paramsdata.length > pageSize) {
            n['nextPage'] = n.currentPage + 1;
        }
        if (n.currentPage > 1) {
            n.previousPage = n.currentPage - 1;
        }
        n['totalPage'] = Math.floor(paramsdata.length / pageSize);
        if (paramsdata.length % pageSize != 0) {
            n['totalPage'] += 1;
        }
        setpagination(n);
        setclassArray(
            paramsdata.slice(
                pagination.currentPage * pageSize - pageSize,
                pagination.currentPage * pageSize
            ) || []
        );
        setapiloader(false);
    };

    const previousPage = () => {
        if (pagination.currentPage != 1) {
            setclassArray(
                state.slice(
                    (pagination.currentPage - 1) * pageSize - pageSize,
                    (pagination.currentPage - 1) * pageSize
                )
            );
            // console.log(
            //     state.slice(
            //         (pagination.currentPage - 1) * pageSize - pageSize,
            //         (pagination.currentPage - 1) * pageSize
            //     )
            // );
            let n = pagination;
            n['currentPage'] = n['currentPage'] - 1;
            if (n['currentPage'] == 1) {
                n['previousPage'] = null;
            } else {
                n['previousPage'] = n.currentPage;
            }
            setpagination(n);
        }
    };

    useEffect(()=>{
        fetchAcademicPhase(`${masterServiceBaseUrl}/academicPhase/all`);
    },[]);


    return (
        <>
            {apiloader ? (
                <div
                    className="mx-auto text-center py-5 my-5"
                    style={{ height: '100vh' }}
                >
                    <CustomLoader apiLoader={apiloader}/>
                </div>
            ) : (
                <>
                    <AcademicPhaseTable
                        previousPage={previousPage}
                        fetchClasses={fetchAcademicPhase}
                        setclassArray={setclassArray}
                        classArray={classArray}
                        isSaveVisible={isSaveVisible}
                        setisSaveVisible={setisSaveVisible}
                        serverData={serverData}
                    />
                    {pagination.totalPage > 1 ? (
                        <GetPagination
                            isSaveVisible={isSaveVisible}
                            fetchClasses={fetchAcademicPhase}
                            setclassArray={setclassArray}
                            pagination={pagination}
                            setpagination={setpagination}
                            pageSize={pageSize}
                            state={state}
                        />
                    ) : null}
                </>
            )}
        </>
    );
};

export default AcademicPhase;
