import styled from "styled-components";
import {Button} from "reactstrap";
import React from "react";

export const CustomTableRow = styled.tr`
  cursor:${props =>props.cursor? props.cursor:''};
  :hover{
    background-color:#f6f9fc;
  }
`

export const StatusButton =({status})=>{
    return (
        <Button key={"statusButton"} color={status === 'ACTIVE' ? "success" : "danger"} size={'sm'} disabled={true}>
            { status === 'ACTIVE' ? "Active" : "Inactive"}
            {/*{status}*/}
        </Button>
    )
}