import React, {useRef} from 'react';
import { useState, useEffect } from "react"
import {
    Card,
    CardHeader,
    CardBody,
    // Button,
    Row,
    Col,
    Dropdown,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu, ListGroupItem, ListGroup, Label, FormGroup, DropdownItem,
} from "reactstrap";
import Button  from 'reactstrap-button-loader';
import CommonInput from "../../../common/formFeilds/input/commonInput";
import {
    fetchDetails,
    fetchMultiSelectDropDownData, getDispValForReactSelectByOneID, iterateData
} from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import {
    masterServiceBaseUrl,
    failureToast,
    getAPI,
    examControllerServiceBaseUrl,
    putpost,
    successToast
} from "services/http";
import CustomLoader from "../../../common/commonComponents/Loader/loader";
import ExamSyllabusTable from "../examSyllabusTable";
import operationalZone from "../../operationalZoneMaster/operationalZone";
import {RedAstric} from "../../../examService/examIdGeneration/examIdSearchForm";
import CloseModel from "../../../courseInformation/pricingService/itemTypeSequenceSetup/model/closeModel";
import AddNewModel from "../../../courseInformation/pricingService/itemTypeSequenceSetup/model/addNewModel";
import {useHistory} from "react-router-dom";
import ButtonWithLoader from "../../../common/commonComponents/buttonWithLoader";
import styled from "styled-components";

const StyledSpan = styled.span`
    display: block !important;
    width: 100% !important;
    padding : 0.5rem 1rem !important;
    clear : both !important;
    text-align : inherit !important;
    white-space : nowrap !important;
    background-color : transparent !important;
    border : 0 !important;
    padding-top : 16px !important;
    padding-bottom : 11px !important;
    padding-left : 13px !important;
`;


const DropDownItem = (props)=>{
    const {item,index} = props;
    return (
        <>
            <StyledSpan>
                {index+1 + ". " + item.label}
            </StyledSpan>
            <DropdownItem style={{margin:'0'}} divider/>
        </>
    )
}


export const DropDownButton = (props)=>{
    const {data=[],direction='bottom'} = props;
    const [isOpen,setIsOpen] = useState(false);

    const getSelectedIds = (data) => {
        try{
            if(data?.[0]?.value){
                const allInList  = data?.filter(item => item?.value === '*');
                return allInList?.length > 0 ? data.length - 1  : data.length
            }
            return (Array.isArray(data) && data?.length) ? data?.length  : 0
        }catch{
            return 0
        }
      
    }

    return (
        <Dropdown
            className="dropdown-new"
            direction={direction}
            style={{
                width:'100%',
                paddingTop: '5px',
                height: '100%'
            }}
            isOpen={isOpen} toggle={()=>setIsOpen(!isOpen)}
        >
            <DropdownToggle
                onClick={() => setIsOpen(!isOpen)}
                style={{
                    display:'block',
                    border:'1px solid #dee2e6',
                    borderRadius:'0.25rem',
                    padding:'7px',
                    // paddingTop:'10px',
                    paddingRight:'12px',
                    color:'#00AEEF',
                    width:'100%',
                    // height :'40px',
                    backgroundColor:'rgba(0, 176, 245, 0.05)'
                }}
                className="nav-link floatRight" color="" tag="a"
            >
                 <span style={{paddingLeft:'8px'}}>
                        {data?.length > 1 ? getSelectedIds(data) + " Selected" : data?.length === 0 ? '0 Selected' : data?.[0]?.label ?? '' }
                    </span>
                <i className="floatRight fa fa-angle-down" style={{fontSize:'17px',marginTop:'3px'}} />
                {/*<i*/}
                {/*    onClick={() => setIsOpen(!isOpen)}*/}
                {/*    style={{ marginTop: '1%', marginRight: '3%'}}*/}
                {/*    className={`floatRight fas fa-ellipsis-h`}/>*/}


                {/*{isOpen ?*/}
                {/*    <i*/}
                {/*        onClick={() => setIsOpen(!isOpen)}*/}
                {/*        style={{ marginTop: '1%', marginRight: '3%'}}*/}
                {/*        className={`floatRight fas fa-ellipsis-h`}/>*/}

                {/*    :*/}
                {/*    <i*/}
                {/*        onClick={() => setIsOpen(!isOpen)}*/}
                {/*        style={{transform: '-151px, 35px, 0px', marginTop: '2%', marginRight: '3%'}}*/}
                {/*        className={`floatRight hovered fas fa-ellipsis-h`}/>*/}
                {/*}*/}
            </DropdownToggle>
            {data?.length > 1 && <DropdownMenu
                modifiers={{
                    setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: (data) => {
                            return {
                                ...data,
                                styles: {
                                    ...data.styles,
                                    overflow: 'auto',
                                    maxHeight: 250,
                                    zIndex:3,
                                    minWidth:180,
                                    overflowX:'hidden',
                                    top:'0px',
                                    left:'0px',
                                    width:'100%'
                                },
                            };
                        },
                    },
                }}
            >
                {
                    (Array.isArray(data) && data?.map((item,index)=> <DropDownItem item={item} index={index} />)) ?? ''
                }
            </DropdownMenu>}
        </Dropdown>
    )
}


export const getSelectedIds = (selectedValues=[])=>{
    let selectedIds = [];
    if(selectedValues.length > 0){
        selectedValues.forEach(item=>{
            if(item.value !== '*'){
                selectedIds.push(item.value)
            }
        });
    }
    return selectedIds;
}
const checkValidations =(headerData)=>{
    let flag = true;
    if ( headerData.businessGroupIds === '' ){
        failureToast('Please Select Business Group.');
        flag = false;
    } else if ( headerData.companyCodeIds  === ''){
        failureToast('Please Select Company Code');
        flag = false;
    } else if ( headerData.zoneIds && headerData.zoneIds.length === 0 ){
        failureToast('Please Select Zone');
        flag = false;
    } else if ( headerData.regionIds && headerData.regionIds.length === 0 ){
        failureToast('Please Select Region');
        flag = false;
    } else if ( headerData.businessAreaIds && headerData.businessAreaIds.length === 0 ){
        failureToast('Please Select Business Area.');
        flag = false;
    } else if ( headerData.academicCareerIds && headerData.academicCareerIds.length === 0 ){
        failureToast('Please Select Academic Career.');
        flag = false;
    } else if ( headerData.academicGroupIds && headerData.academicGroupIds.length === 0 ){
        failureToast('Please Select Academic Group.');
        flag = false;
    } else if ( headerData.courseIds && headerData.courseIds.length === 0 ){
        failureToast('Please Select Course ID.');
        flag = false;
    } else if ( headerData.termIds && headerData.termIds.length === 0 ){
        failureToast('Please Select Term.');
        flag = false;
    } else if ( headerData.academicYearIds && headerData.academicYearIds.length === 0 ){
        failureToast('Please Select Academic Year.');
        flag = false;
    } else if ( headerData.academicPhaseIds && headerData.academicPhaseIds.length === 0 ){
        failureToast('Please Select Academic Phase.');
        flag = false;
    }
    else if ( headerData.examIds && headerData.examIds.length === 0 ){
        failureToast('Please Select Exam ID.');
        flag = false;
    }
    return flag;
}
const checkTableValidation = (ExamSyllabusData) => {
    if(ExamSyllabusData.length > 0) {
        let flag = true;
        ExamSyllabusData.map((item ,index) => {
            if(flag){
                if (item.isEdit) {
                    failureToast('Please Save Row ' + (index + 1));
                    flag = false;
                    return;
                }
            }
        })
        return flag;
    }
}

export const  formatData =(data,key)=>{
    let tempArray = []
    data.map(item=>{
        tempArray.push({value: item['id'],label:item[key]})
    })
    return tempArray;
}
const formatDataPut = (data,key) =>{
    let tempArray =[];
    data.map(item => {
        tempArray.push(item[key])
    })
    return tempArray;
}
export const formatExamSyllabusData = (data,viewSubject=[])=>{
    let formattedData = [];
    if(Array.isArray(data) && data.length){
        data.map(item => {
            let id = item.id
            let subj = getDispValForReactSelectByOneID(item.subject,'DispValue',viewSubject);
            formattedData.push({
                id:id,
                subject: {
                    value: item.subject,
                    label: subj
                },
                subjectDesc : subj,
                topicIds: formatData(item.topicIds,'description'),
                chapterIds:formatData(item.chapterIds,'displayValue'),
                subTopicIds:formatData(item.subTopicIds,'description'),
                subSubTopicIds: formatData(item.subSubTopicIds,'subSubTopicDescription'),
                status: item.status,
                isEdit : false,
                isNew : false
            })
        });
        return formattedData;
    }
    return formattedData;
}

const ExamSyllabusNew =(props)=>{
    let history = useHistory();
    const {action,id=null} = props.match.params;
    const [headerData,setHeaderData]=useState({
        businessGroupIds :'',
        companyCodeIds :'',
        academicCareerIds :[],
        academicGroupIds :[],
        academicPhaseIds :[],
        academicYearIds :[],
        batchIds :[],
        businessAreaIds :[],
        courseIds :[],
        examIds :[],
        regionIds :[],
        termIds :[],
        zoneIds :[],
        batchIdsSelectedValues: [],
        businessAreaIdsSelectedValues: [],
        academicCareerIdsSelectedValues: [],
        academicGroupIdsSelectedValues: [],
        academicPhaseIdsSelectedValues: [],
        academicYearIdsSelectedValues: [],
        courseIdsSelectedValues: [],
        examIdsSelectedValues: [],
        regionIdsSelectedValues: [],
        termIdsSelectedValues: [],
        zoneIdsSelectedValues: [],
        status:'ACTIVE'
    })


    const [examSyllabus,setExamSyllabus] = useState([])
    const [businessGroup,setBusinessGroup] = useState([]);
    const [companyCode,setCompanyCode] = useState([]);
    const [operationalZone,setOperationalZone] = useState([]);
    const [region,setRegion] = useState([]);
    const [businessArea,setBusinessArea] = useState([]);
    const [academicCareer,setAcademicCareer] = useState([]);
    const [academicGroup,setAcademicGroup] = useState([]);
    const [courseId,setCourseId] = useState([]);
    const [term,setTerm] = useState([]);
    const [academicYear,setAcademicYear] = useState([]);
    const [academicPhase,setAcademicPhase] = useState([]);
    const [examId,setExamId] = useState([]);
    const [subject, setSubject] = useState([]);
    const [apiLoader,setApiLoader] = useState(true);
    const [isTableVisible,setIsTableVisible] = useState(!(action === 'new'));
    const [viewSubject, setViewSubject] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [isHeaderEdit,setIsHeaderEdit] = useState(action === 'new' );
    const [previousHeaderData,setPreviousHeaderData] = useState({});
    // const saveHandler =()=>{
    //     try {
    //         let HeaderData = headerData;
    //         let isHeaderValid = checkValidations(HeaderData);
    //         let ExamSyllabusData = examSyllabus;
    //         let isExamSyllabusDataValid = checkTableValidation(ExamSyllabusData);
    //         if (isHeaderValid && isExamSyllabusDataValid) {
    //             if (action === 'new') {
    //                 let payload = {
    //                     examSyllabusMasterHeaderDTO:
    //                         {
    //                             businessGroupIds: [headerData.businessGroupIds[0].value],
    //                             companyCodeIds: [headerData.companyCodeIds.value],
    //                             academicCareerIds: headerData?.academicCareerIdsSelectedValues,
    //                             academicGroupIds: headerData?.academicGroupIdsSelectedValues,
    //                             academicPhaseIds: headerData?.academicPhaseIdsSelectedValues,
    //                             academicYearIds: headerData?.academicYearIdsSelectedValues,
    //                             batchIds: [1],
    //                             businessAreaIds: headerData?.businessAreaIdsSelectedValues,
    //                             courseIds: headerData?.courseIdsSelectedValues,
    //                             examIds: headerData?.examIdsSelectedValues,
    //                             regionIds: headerData?.regionIdsSelectedValues,
    //                             termIds: headerData?.termIdsSelectedValues,
    //                             zoneIds: headerData?.zoneIdsSelectedValues,
    //                             status: 'ACTIVE'
    //                         }
    //                 };
    //                 payload.examSyllabusMasterDetailDTOList = [];
    //                 if(examSyllabus.length > 0){
    //                     examSyllabus.map((item) =>{
    //                         payload.examSyllabusMasterDetailDTOList.push(
    //                             {
    //                                 subject:item?.subject.value,
    //                                 chapterIds: item?.selectedchapterIds,
    //                                 topicIds : item?.selectedtopicIds,
    //                                 subTopicIds : item?.selectedsubTopicIds,
    //                                 subSubTopicIds : item?.selectedsubSubTopicIds,
    //                                 status: item?.status
    //                             }
    //                         )
    //                     })
    //                 }
    //                 console.log('Payload for Api--->', payload);
    //                 let url = masterServiceBaseUrl + '/examSyllabusMaster/createExamSyllabusMaster';
    //                 putpost(url,
    //                     (data)=>{
    //                         successToast(data.message);
    //                         const headerId = data.data?.examSyllabusMasterHeader?.id;
    //                         history.push(`/admin/examSyllabus/view/${headerId}`);
    //                     },
    //                     (data)=>{
    //                         failureToast(data.message);
    //                     },
    //                     payload,'POST').then((res)=>{
    //                 });
    //
    //             }
    //             else if(action === 'edit'){
    //                 let resAcademicCareer = formatDataPut(headerData.academicCareerIds, 'value');
    //                 let resAcademicGroup = formatDataPut(headerData.academicGroupIds,'value');
    //                 let resAcademicPhase =formatDataPut(headerData.academicPhaseIds,'value');
    //                 let resAcademicYear =formatDataPut(headerData.academicYearIds,'value');
    //                 let resBusinessArea =formatDataPut(headerData.businessAreaIds,'value');
    //                 let resTerm =formatDataPut(headerData.termIds,'value');
    //                 let resZone= formatDataPut(headerData.zoneIds,'value');
    //                 let resRegion = formatDataPut(headerData.regionIds,'value');
    //                 let resBusinessGroup = headerData.businessGroupIds.map(item=>item.value);
    //                 let resCompanyCode = headerData.companyCodeIds.map(item=>item.value);
    //                 let batch = {};
    //                 batch = headerData.batchIds;
    //                 let resExamID = formatDataPut(headerData.examIds,'value');
    //                 let resCourse = formatDataPut(headerData.courseIds,'value');
    //                 let id = headerData.id;
    //                 console.log("EXAm syllabus------>",examSyllabus);
    //                 let payload1 = {
    //                     examSyllabusMasterHeaderDTO:
    //                         {
    //                             businessGroupIds: resBusinessGroup,
    //                             companyCodeIds: resCompanyCode,
    //                             academicCareerIds: resAcademicCareer,
    //                             academicGroupIds: resAcademicGroup,
    //                             academicPhaseIds: resAcademicPhase,
    //                             academicYearIds: resAcademicYear,
    //                             batchIds: [1],
    //                             businessAreaIds: resBusinessArea,
    //                             courseIds: resCourse,
    //                             examIds: resExamID,
    //                             regionIds: resRegion,
    //                             termIds: resTerm,
    //                             zoneIds: resZone,
    //                             id: id,
    //                             status: 'ACTIVE'
    //                         }
    //                 };
    //                 payload1.examSyllabusMasterDetailDTOList = [];
    //                 if(examSyllabus.length > 0){
    //                     examSyllabus.map((item) =>{
    //                         payload1.examSyllabusMasterDetailDTOList.push(
    //                             item.isNew ?
    //                                 {
    //                                     subject:item?.subject.value,
    //                                     chapterIds: item?.selectedchapterIds,
    //                                     topicIds : item?.selectedtopicIds,
    //                                     subTopicIds : item?.selectedsubTopicIds,
    //                                     subSubTopicIds : item?.selectedsubSubTopicIds,
    //                                     status: item?.status
    //                                 }
    //                                 :
    //                                 {
    //                                     id:item.id,
    //                                 subject:item?.subject.value,
    //                                 chapterIds: getSelectedIds(item?.chapterIds),
    //                                 topicIds : getSelectedIds(item?.topicIds),
    //                                 subTopicIds : getSelectedIds(item?.subTopicIds),
    //                                 subSubTopicIds : getSelectedIds(item?.subSubTopicIds),
    //                                 status: item?.status
    //                             }
    //                         )
    //                     })
    //                 }
    //                 console.log("Payload11......", payload1);
    //                 let url = masterServiceBaseUrl + '/examSyllabusMaster/updateExamSyllabusMaster/' + id;
    //                     putpost(url,
    //                         (data) =>{
    //                             successToast(data.message);
    //                             console.log('Data----->',data.data);;
    //                             const headerId = data.data?.examSyllabusMasterHeader?.id;
    //                             history.push(`/admin/examSyllabus/view/${headerId}`);
    //                         },
    //                         (data) =>{
    //                             failureToast(data.message);
    //                         },
    //                         payload1, 'PUT').then((res) => {
    //                         console.log("res....", res);
    //                     });
    //             }
    //         }
    //     }catch(e){
    //         console.error('Error in SaveHandler----->',e);
    //     }
    // }
    const submitHandler =()=>{
        let isValid = checkValidations(headerData);
        if(isValid){
            setIsLoading(true);
            let payload = {
                businessGroupIds: isHeaderEdit ? formatDataPut(headerData.businessGroupIds,'value') : [headerData.businessGroupIds[0]?.value],
                companyCodeIds: isHeaderEdit ? formatDataPut(headerData.companyCodeIds,'value') :  [headerData.companyCodeIds[0]?.value],
                academicCareerIds: isHeaderEdit ? formatDataPut(headerData.academicCareerIds,'value') :  headerData?.academicCareerIdsSelectedValues,
                academicGroupIds: isHeaderEdit ? formatDataPut(headerData.academicGroupIds,'value') :  headerData?.academicGroupIdsSelectedValues,
                academicPhaseIds: isHeaderEdit ? formatDataPut(headerData.academicPhaseIds,'value') :  headerData?.academicPhaseIdsSelectedValues,
                academicYearIds:  isHeaderEdit ? formatDataPut(headerData.academicYearIds,'value') : headerData?.academicYearIdsSelectedValues,
                businessAreaIds: isHeaderEdit ? formatDataPut(headerData.businessAreaIds,'value') :  headerData?.businessAreaIdsSelectedValues,
                courseIds: isHeaderEdit ? formatDataPut(headerData.courseIds,'value') :  headerData?.courseIdsSelectedValues,
                examIds: isHeaderEdit ? formatDataPut(headerData.examIds,'value') :  headerData?.examIdsSelectedValues,
                regionIds: isHeaderEdit ? formatDataPut(headerData.regionIds,'value') :  headerData?.regionIdsSelectedValues,
                termIds: isHeaderEdit ? formatDataPut(headerData.termIds,'value') :  headerData?.termIdsSelectedValues,
                zoneIds: isHeaderEdit ? formatDataPut(headerData.zoneIds,'value') :  headerData?.zoneIdsSelectedValues,
                batchIds:  headerData.batchIds,
                status: headerData.status
            }
            let HID = '' ;
            let apiEndPoint = masterServiceBaseUrl + '/examSyllabusMasterHeader'
            let requestMethod = 'POST'
            if(action === 'edit'){
                HID = headerData.id;
                payload = {
                    ...payload,
                    id:HID
                }
                apiEndPoint = apiEndPoint + '/updateExamSyllabusMasterHeader/' + HID;
                requestMethod = 'PUT'
            }else{
                apiEndPoint = apiEndPoint + '/createExamSyllabusMasterHeader';
            }
            putpost(apiEndPoint,
                (data)=>{
                    successToast(data.message);
                     HID = data.data?.id;
                    setIsLoading(false);
                    setIsHeaderEdit(false)
                    history.push('/admin/examSyllabus/edit/'+HID)
                },
                (data)=>{
                    failureToast(data.message);
                    setIsLoading(false);
                },
                payload,requestMethod).then((res)=>{});
        }
    }

    const updateHandler =(value,key,event)=>{
        let tempObject = headerData;
        if(key === 'businessGroupIds'){
            let id = value?.value
            fetchDetails(masterServiceBaseUrl + `/companyCode/getCompanyCodeByGroup/${id}`, setCompanyCode, 'companyCode', 'select');
            tempObject = {
                ...tempObject,
                [key] : [value],
                companyCodeIds:[]
            }
        }else if(key === 'companyCodeIds'){
            tempObject = {
                ...tempObject,
                [key] : [value]
            }
        }else if (key === 'batchIds'){
            tempObject = {
                ...tempObject,
                [key] :[value],
            }
        } else {
            tempObject = {
                ...tempObject,
                [key] :value,
                [key+'SelectedValues'] : getSelectedIds(value)
            }
        }
        setHeaderData(tempObject);
    }

    const fetchExamSyllabusMasterData = (url, stateMethod) => {
        getAPI(url,
            (data) => {
                let tableDataResponse = data.data?.examSyllabusMasterDetailList;
                let headerDataResponse = data.data.examSyllabusMasterHeader;
                let businessGroupId = headerDataResponse.businessGroupIds && headerDataResponse.businessGroupIds.length && headerDataResponse.businessGroupIds[0]?.id;
                if(businessGroupId){
                    fetchDetails(masterServiceBaseUrl + `/companyCode/getCompanyCodeByGroup/${businessGroupId}`, setCompanyCode, 'companyCode', 'select');
                }
                let tempArray = formatExamSyllabusData(tableDataResponse,viewSubject);
                setExamSyllabus([...tempArray])
                let resCourse = [];
                let resExamID = [];
                let batch = {};
                let courseIDs = headerDataResponse.course.split(",");

                courseIDs.map(item =>{
                    resCourse.push({
                        label: item,
                        value:item
                    })
                })
                headerDataResponse.examId.split(',').forEach(item=> resExamID.push({value:item,label:item}))
                // resExamID.push({
                //     value:headerDataResponse.examId,
                //     label:headerDataResponse.examId
                // })
                batch = headerDataResponse.batch
                setHeaderData({
                    academicCareerIds : formatData(headerDataResponse.academicCareerIds,'academicCareerDispValue') || [] ,
                    academicGroupIds : formatData(headerDataResponse.academicGroupIds,'academicGroupDispValue') || [],
                    academicPhaseIds : formatData(headerDataResponse.academicPhaseIds,'academicPhaseDisplayValue') || [],
                    academicYearIds : formatData(headerDataResponse.academicYearIds,'academicYearDisplayValue') || [],
                    businessAreaIds : formatData(headerDataResponse.businessAreaIds,'businessAreaDispValue') || [],
                    termIds : formatData(headerDataResponse.termIds,'termDispValue') || [],
                    zoneIds : formatData(headerDataResponse.zoneIds,'operationalZoneDispValue') || [],
                    regionIds : formatData(headerDataResponse.regionIds,'regionDispValue') || [],
                    businessGroupIds : formatData(headerDataResponse.businessGroupIds,'groupCodeDispValue') || [],
                    companyCodeIds : formatData(headerDataResponse.companyCodeIds,'companyCodeDispValue') || [],
                    courseIds:resCourse,
                    examIds:resExamID,
                    batchIds:[batch],
                    id:headerDataResponse.id,
                    status:headerDataResponse.status
                })
                setApiLoader(false)
            },
            (data)=>{

            })
    }
    const fetchData =()=>{
        fetchDetails(masterServiceBaseUrl + `/groupCode/getAllActiveGroupCode`, setBusinessGroup, 'groupCode', 'select');
        fetchMultiSelectDropDownData(masterServiceBaseUrl+'/operationalZone/getAllActiveOperationalZone',setOperationalZone,'operationalZone');
        fetchMultiSelectDropDownData(masterServiceBaseUrl+'/region/getAllActiveRegion',setRegion,'region');
        fetchMultiSelectDropDownData(masterServiceBaseUrl+'/businessArea/getAllActiveBusinessArea',setBusinessArea,'businessArea');
        fetchMultiSelectDropDownData(masterServiceBaseUrl+'/academicCareer/getAllActiveAcademicCareer',setAcademicCareer,'academicCareer');
        fetchMultiSelectDropDownData(masterServiceBaseUrl+'/academicGroup/getAllActiveAcademicGroup',setAcademicGroup,'academicGroup');
        fetchMultiSelectDropDownData(masterServiceBaseUrl+'/courseDetail/getAllCourseDetail',setCourseId,'courseId');
        fetchMultiSelectDropDownData(masterServiceBaseUrl+'/term/getAllActiveTerm',setTerm,'term');
        fetchMultiSelectDropDownData(masterServiceBaseUrl+'/academicYear/all',setAcademicYear,'academicYear');
        fetchMultiSelectDropDownData(masterServiceBaseUrl+'/academicPhase/all',setAcademicPhase,'academicPhase');
        fetchMultiSelectDropDownData(examControllerServiceBaseUrl + '/exam/getAllActiveExamId',setExamId,'examId');
        fetchDetails(masterServiceBaseUrl + `/subject/getAllActiveSubject`, setSubject, 'subject', 'select');
        if(action ==='view'|| action === 'edit'){
            fetchDetails(masterServiceBaseUrl + `/subject/getAllSubject`, setViewSubject, 'subject', 'reactSelect');

        }
    }

    // UseEffect to control API Loader and to fetchExamSyllabus data in edit and view mode
    useEffect(() =>{
        if( businessGroup.length && viewSubject.length && (action === 'edit' || action === 'view') && id){
            setApiLoader(true);
            fetchExamSyllabusMasterData(masterServiceBaseUrl + `/examSyllabusMaster/getExamSyllabusMasterById/${id}`)
        } else if(action === 'new' && businessGroup.length){
            setApiLoader(false);
        }
    },[action,id,viewSubject,businessGroup])

    const fetchMultiSelectDropDownData1 = (url,stateMethod,displayValueKey,masterName,index,value)=>{
        getAPI(url,(data)=>{
            if(data.data.length){
                let dropDownValues = [];
                data.data.forEach(item=>{
                    dropDownValues.push({
                        value : item.id,
                        label : item[displayValueKey]
                    });
                });
                let tempArray = examSyllabus;
                let tempObject = tempArray[index];
                tempObject = {
                    ...tempObject,
                    'subject':value,
                    [masterName+'DropDownValues'] : dropDownValues
                }
                tempArray[index] = tempObject
                stateMethod([...tempArray]);

            }
        },(data)=>{

        })
    }
    const fetchDataBasedOnSubjectId = (id = null,index,value)=>{
        if(id){
            fetchMultiSelectDropDownData1(`${masterServiceBaseUrl}/chapterMaster/getChapterMasterBySubjectId/${id}`, setExamSyllabus, 'displayValue','chapter',index,value);
            fetchMultiSelectDropDownData1(`${masterServiceBaseUrl}/topicMaster/getTopicMasterBySubjectId/${id}`, setExamSyllabus, 'topicId','topic',index,value);
            fetchMultiSelectDropDownData1(`${masterServiceBaseUrl}/subTopicMaster/getSubTopicMasterBySubjectId/${id}`, setExamSyllabus, 'subTopicId','subTopic',index,value);
            fetchMultiSelectDropDownData1(`${masterServiceBaseUrl}/subSubTopicMaster/getSubSubTopicBySubjectId/${id}`, setExamSyllabus, 'ssTopicId','subSubTopic',index,value);
        }
    }

    useEffect(()=>{
        fetchData();
    },[])

    const [isCloseModelOpen,setIsCloseModelOpen] = useState(false);
    const closeHandler =(key,flag = false)=>{
        if(key === '1'){
            setIsCloseModelOpen(true);
        }
        else if(key === '2'){
            if ((action === 'edit' || action === 'view') && flag) {
                setIsCloseModelOpen(false);
                history.push('/admin/examSyllabus')
            } else if(action === 'new' && flag ) {
                setIsCloseModelOpen(false);
                history.push('/admin/examSyllabus')
            } else {
                setIsCloseModelOpen(false);
            }
        }
    }
    const [isSomeThingEdit,setIsSomeThingEdit] = useState(false);
    const [numOfRows,setNumOfRows] = useState(1);
    const [isAddNewModelVisible,setIsAddNewModelVisible] = useState(false);
    const addNewRow = ()=>{
        if(numOfRows<=10){
            let newRows = []
            let tempObject =  {
                subject : '',
                chapterIds : [],
                chapterDropDownValues : [],
                topicIds : [],
                topicDropDownValues:[],
                subTopicIds : [],
                subTopicDropDownValues : [],
                subSubTopicIds : [],
                subSubTopicDropDownValues : [],
                status : 'ACTIVE',
                isEdit:true,
                isNew:true
            };
            for(let i=0;i<numOfRows;i++){
                newRows.push(tempObject);
            }
            let tempArray = [...examSyllabus,...newRows];
            setExamSyllabus([...tempArray]);
            setIsAddNewModelVisible(false);
            if(!isSomeThingEdit){
                setIsSomeThingEdit(true);
            }
        }else{
            failureToast('Please Enter values between 1 and 10.');
        }
    }

    const cancelHandler =()=>{
        setHeaderData(previousHeaderData);
        setIsHeaderEdit(false);
    }
    const editHandler =()=>{
        setIsHeaderEdit(true);
        setPreviousHeaderData(headerData);
    }
    return (
        <>
            {
                apiLoader
                    ?
                    <>
                        <div className="mx-auto text-center py-5 my-5" style={{ height: "100vh" }}>
                            <CustomLoader apiLoader={apiLoader}/>
                        </div>
                    </>
                    :
                    <div style={{marginTop: '15px'}} className="col">
                        <div className="card-wrapper">
                            <Card>
                                <CardHeader>
                                    <h3 className="mb-0">Exam Syllabus</h3>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md={3}>
                                            <CommonInput
                                                mandatory={true}
                                                onChange={(value)=>updateHandler(value,'businessGroupIds')}
                                                // defaultValue={ action === 'view'  ? getDispValForReactSelectByOneID(headerData.businessGroupIds ,'DispValue',viewBusinessGroup) :headerData.businessGroupIds}
                                                defaultValue={ action === 'view'  ? headerData.businessGroupIds[0]?.label :headerData.businessGroupIds}
                                                label={'Business Group'}
                                                placeHolder={'Business Group'}
                                                type={ action === 'view'  ? 'text':'select'}
                                                data={businessGroup}
                                                isDisabled={((action === 'view' || action ==='edit') && !isHeaderEdit ) }
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <CommonInput
                                                mandatory={true}
                                                tabIndex={'2'}
                                                onChange={(value)=>updateHandler(value,'companyCodeIds')}
                                                defaultValue={action === 'view'  ? headerData.companyCodeIds[0]?.label :headerData.companyCodeIds}
                                                label={'Company Code'}
                                                placeHolder={'Company Code'}
                                                type={action === 'view' ? 'text':'select'}
                                                data={companyCode}
                                                isDisabled={( action === 'edit' || action === 'view') && !isHeaderEdit }
                                            />
                                        </Col>
                                        <Col md={3}>
                                            {((action === 'view' || action ==='edit') && !isHeaderEdit )  ?
                                                <FormGroup>
                                                    <Label className="form-control-label">
                                                        Zone ID <RedAstric/>
                                                    </Label>
                                                    <DropDownButton data={headerData.zoneIds}/>
                                                </FormGroup>
                                                :
                                                <CommonInput
                                                    mandatory={true}
                                                    onChange={(value,event)=>updateHandler(value,'zoneIds',event)}
                                                    defaultValue={headerData.zoneIds}
                                                    label={'Zone'}
                                                    placeHolder={'Zone'}
                                                    isDisabled={headerData.companyCodeIds === ''}
                                                    type={headerData.companyCodeIds === '' ? 'select': 'multiSelectWithCheckboxes'}
                                                    data={(action === 'new'||action ==='edit') ? [{label:'All',value:'*'},...operationalZone] : headerData.zoneIds}
                                                />
                                            }
                                        </Col>
                                        <Col md={3}>
                                            {((action === 'view' || action ==='edit') && !isHeaderEdit )  ?
                                                <FormGroup>
                                                    <Label className="form-control-label">
                                                        Region <RedAstric/>
                                                    </Label>
                                                    <DropDownButton data={headerData.regionIds}/>
                                                </FormGroup> :
                                                <CommonInput
                                                    mandatory={true}
                                                    onChange={(value,event)=>updateHandler(value,'regionIds',event)}
                                                    defaultValue={headerData.regionIds}
                                                    label={'Region'}
                                                    placeHolder={'Region'}
                                                    isDisabled={headerData.zoneIds?.length === 0}
                                                    type={headerData.zoneIds?.length === 0 ? 'select': 'multiSelectWithCheckboxes'}
                                                    data={(action === 'new'||action ==='edit') ?[{label:'All',value:'*'},...region] : headerData.regionIds}
                                                />}
                                        </Col>
                                        <Col md={3}>
                                            {((action === 'view' || action ==='edit') && !isHeaderEdit )  ?
                                                <FormGroup>
                                                    <Label className="form-control-label">
                                                        Business Area <RedAstric/>
                                                    </Label>
                                                    <DropDownButton data={headerData.businessAreaIds}/>
                                                </FormGroup>
                                                :
                                                <CommonInput
                                                    mandatory={true}
                                                    onChange={(value,event)=>updateHandler(value,'businessAreaIds',event)}
                                                    defaultValue={headerData.businessAreaIds}
                                                    label={'Business Area'}
                                                    placeHolder={'Business Area'}
                                                    isDisabled={headerData.regionIds?.length === 0}
                                                    type={headerData.regionIds?.length === 0 ? 'select': 'multiSelectWithCheckboxes'}
                                                    data={action === 'new' || action === 'edit' ?[{label:'All',value:'*'},...businessArea] : headerData.businessAreaIds}
                                                />
                                            }
                                        </Col>
                                        <Col md={3}>
                                            {((action === 'view' || action ==='edit') && !isHeaderEdit )   ?
                                                <FormGroup>
                                                    <Label className="form-control-label">
                                                        Academic Career <RedAstric/>
                                                    </Label>
                                                    <DropDownButton data={headerData.academicCareerIds}/>
                                                </FormGroup>
                                                :
                                                <CommonInput
                                                    mandatory={true}
                                                    onChange={(value, event) => updateHandler(value, 'academicCareerIds', event)}
                                                    defaultValue={headerData.academicCareerIds}
                                                    label={'Academic Career'}
                                                    placeHolder={'Academic Career'}
                                                    isDisabled={headerData.businessAreaIds?.length === 0}
                                                    type={headerData.businessAreaIds?.length === 0 ? 'select' : 'multiSelectWithCheckboxes'}
                                                    data={(action === 'new'||action ==='edit') ? [{
                                                        label: 'All',
                                                        value: '*'
                                                    }, ...academicCareer] : headerData.academicCareerIds}
                                                />
                                            }
                                        </Col>
                                        <Col md={3}>
                                            {((action === 'view' || action ==='edit') && !isHeaderEdit )  ?
                                                <FormGroup>
                                                    <Label className="form-control-label">
                                                        Academic Group <RedAstric/>
                                                    </Label>
                                                    <DropDownButton data={headerData.academicGroupIds}/>
                                                </FormGroup>
                                                :
                                                <CommonInput
                                                    mandatory={true}
                                                    onChange={(value, event) => updateHandler(value, 'academicGroupIds', event)}
                                                    defaultValue={headerData.academicGroupIds}
                                                    label={'Academic Group'}
                                                    placeHolder={'Academic Group'}
                                                    isDisabled={headerData.academicCareerIds?.length === 0}
                                                    type={headerData.academicCareerIds?.length === 0 ? 'select' : 'multiSelectWithCheckboxes'}
                                                    data={(action === 'new'||action ==='edit') ? [{
                                                        label: 'All',
                                                        value: '*'
                                                    }, ...academicGroup] : headerData.academicGroupIds}
                                                />
                                            }
                                        </Col>
                                        <Col md={3}>
                                            {((action === 'view' || action ==='edit') && !isHeaderEdit ) ?
                                                <FormGroup>
                                                    <Label className="form-control-label">
                                                        Course ID <RedAstric/>
                                                    </Label>
                                                    <DropDownButton data={headerData.courseIds}/>
                                                </FormGroup>
                                                :
                                                <CommonInput
                                                    mandatory={true}
                                                    onChange={(value, event) => updateHandler(value, 'courseIds', event)}
                                                    defaultValue={headerData.courseIds}
                                                    label={'Course ID'}
                                                    placeHolder={'Course ID'}
                                                    isDisabled={headerData.academicGroupIds?.length === 0}
                                                    type={headerData.academicGroupIds?.length === 0 ? 'select' : 'multiSelectWithCheckboxes'}
                                                    data={(action === 'new'||action ==='edit') ? [{
                                                        label: 'All',
                                                        value: '*'
                                                    }, ...courseId] : headerData.courseIds}
                                                />
                                            }
                                        </Col>
                                        <Col md={3}>
                                            {((action === 'view' || action ==='edit') && !isHeaderEdit )  ?
                                                <FormGroup>
                                                    <Label className="form-control-label">
                                                        Term <RedAstric/>
                                                    </Label>
                                                    <DropDownButton data={headerData.termIds}/>
                                                </FormGroup>
                                                :
                                                <CommonInput
                                                    mandatory={true}
                                                    onChange={(value, event) => updateHandler(value, 'termIds', event)}
                                                    defaultValue={headerData.termIds}
                                                    label={'Term'}
                                                    placeHolder={'Term'}
                                                    isDisabled={headerData.courseIds?.length === 0}
                                                    type={headerData.courseIds?.length === 0 ? 'select' : 'multiSelectWithCheckboxes'}
                                                    data={(action === 'new'||action ==='edit')? [{
                                                        label: 'All',
                                                        value: '*'
                                                    }, ...term] : headerData.termIds}
                                                />
                                            }
                                        </Col>
                                        <Col md={3}>
                                            {((action === 'view' || action ==='edit') && !isHeaderEdit )   ?
                                                <FormGroup>
                                                    <Label className="form-control-label">
                                                        Academic Year <RedAstric/>
                                                    </Label>
                                                    <DropDownButton data={headerData.academicYearIds}/>
                                                </FormGroup>
                                                :
                                                <CommonInput
                                                    mandatory={true}
                                                    onChange={(value, event) => updateHandler(value, 'academicYearIds', event)}
                                                    defaultValue={headerData.academicYearIds}
                                                    label={'Academic Year'}
                                                    placeHolder={'Academic Year'}
                                                    isDisabled={headerData.termIds?.length === 0}
                                                    type={headerData.termIds?.length === 0 ? 'select' : 'multiSelectWithCheckboxes'}
                                                    data={(action === 'new'||action ==='edit') ? [{
                                                        label: 'All',
                                                        value: '*'
                                                    }, ...academicYear] : headerData.academicYearIds}
                                                />
                                            }
                                        </Col>
                                        <Col md={3}>
                                            {((action === 'view' || action ==='edit') && !isHeaderEdit )   ?
                                                <FormGroup>
                                                    <Label className="form-control-label">
                                                        Academic Phase <RedAstric/>
                                                    </Label>
                                                    <DropDownButton data={headerData.academicPhaseIds}/>
                                                </FormGroup>
                                                :
                                                <CommonInput
                                                    mandatory={true}
                                                    onChange={(value, event) => updateHandler(value, 'academicPhaseIds', event)}
                                                    defaultValue={headerData.academicPhaseIds}
                                                    label={'Academic Phase'}
                                                    placeHolder={'Academic Phase'}
                                                    isDisabled={headerData.academicYearIds?.length === 0}
                                                    type={headerData.academicYearIds?.length === 0 ? 'select' : 'multiSelectWithCheckboxes'}
                                                    data={(action === 'new'||action ==='edit') ? [{
                                                        label: 'All',
                                                        value: '*'
                                                    }, ...academicPhase] : headerData.academicPhaseIds}
                                                />
                                            }
                                        </Col>
                                        <Col md={3}>
                                            <CommonInput
                                                mandatory={true}
                                                onChange={(value,event)=>updateHandler(value,'batchIds',event)}
                                                defaultValue={headerData.batchIds}
                                                label={'Batch'}
                                                placeHolder={'Batch'}
                                                isDisabled={((action === 'view' || action ==='edit') && !isHeaderEdit )}
                                                type={'text'}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            {((action === 'view' || action ==='edit') && !isHeaderEdit ) ?
                                                <FormGroup>
                                                    <Label className="form-control-label">
                                                        Exam ID <RedAstric/>
                                                    </Label>
                                                    <DropDownButton data={headerData.examIds}/>
                                                </FormGroup>
                                                :
                                                <CommonInput
                                                    mandatory={true}
                                                    onChange={(value, event) => updateHandler(value, 'examIds', event)}
                                                    defaultValue={headerData.examIds}
                                                    label={'Exam ID'}
                                                    placeHolder={'Exam ID'}
                                                    isDisabled={headerData.academicPhaseIds?.length === 0}
                                                    type={headerData.academicPhaseIds?.length === 0 ? 'select' : 'multiSelectWithCheckboxes'}
                                                    data={(action === 'new'||action ==='edit') ? [{
                                                        label: 'All',
                                                        value: '*'
                                                    }, ...examId] : headerData.examIds}
                                                />
                                            }
                                        </Col>
                                    </Row>

                                    {
                                        action === 'new' || action === 'edit'
                                            ?
                                            <Row>
                                                <Col>
                                                    {
                                                        isHeaderEdit ?
                                                            <>
                                                                {
                                                                    action === 'edit'
                                                                        ?
                                                                        <Button color="info" size="sm" type="button" className={"floatRight mx-1"} onClick={cancelHandler} data-testid="addNewExamSyllabus">
                                                                            Cancel
                                                                        </Button>
                                                                        :
                                                                        null
                                                                }
                                                                <ButtonWithLoader loading={isLoading} color="info" style={{ width:'110px'}} size="sm" type="button" className={"floatRight mx-1"} onClick={submitHandler} data-testid="addNewExamSyllabus">
                                                                    Submit
                                                                </ButtonWithLoader>
                                                            </>
                                                    :
                                                            action === 'edit'
                                                                ?
                                                                <Button color="info" size="sm" type="button" className={"floatRight mx-1"} onClick={editHandler} data-testid="addNewExamSyllabus">
                                                                    Edit
                                                                </Button>
                                                                :
                                                                null
                                                    }
                                                </Col>
                                            </Row>

                                            :
                                            null
                                    }
                                </CardBody>
                            </Card>
                            {
                                isTableVisible ?
                                    <ExamSyllabusTable
                                        headerData={headerData}
                                        action={action}
                                        setIsCloseModelOpen={setIsCloseModelOpen}
                                        setExamSyllabus={setExamSyllabus}
                                        examSyllabus={examSyllabus}
                                        academicYear={academicYear}
                                        academicPhase={academicPhase}
                                        subject={subject}
                                        setIsAddNewModelVisible={setIsAddNewModelVisible}
                                        course={[]}
                                        exam={[]}
                                        fetchDataBasedOnSubjectId={fetchDataBasedOnSubjectId}
                                        viewSubject={viewSubject}
                                        setViewSubject={setViewSubject}
                                        setApiLoader={setApiLoader}
                                    />
                                    : null
                            }
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <Button color="info" size="sm" className="floatRight mx-1" onClick={() => closeHandler('1')}>
                                            Close
                                        </Button>
                                        {/*{*/}
                                        {/*    action === 'new'*/}
                                        {/*        ?*/}
                                        {/*        <Button color="info" size="sm" className="floatRight mx-1" onClick={saveHandler}>*/}
                                        {/*            Save*/}
                                        {/*        </Button>*/}
                                        {/*        :*/}
                                        {/*        null*/}
                                        {/*}*/}
                                    </Col>
                                </Row>
                            </CardHeader>
                            <AddNewModel
                                num={numOfRows}
                                setNum={setNumOfRows}
                                isVisible={isAddNewModelVisible}
                                addNewRow={addNewRow}
                                setIsVisible={setIsAddNewModelVisible}
                            />
                            <CloseModel
                                isCloseModelOpen={isCloseModelOpen}
                                closeHandler={closeHandler}
                                isSomeThingEdit={isSomeThingEdit}
                            />
                        </div>
                    </div>
            }
        </>
    )
}
export default ExamSyllabusNew;
