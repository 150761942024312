import { endpoint } from "views/pages/manage/common/constant";
import { fetchAllPostPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { fetchAllPromisedData } from "../../common/utils/methods/commonMethods/utilityMethod";
import { TEST_MODE_FOR_9AM } from "./TestForm";
import { add9Hours } from "../academicTimetable/helper";


export const outlineButtonStyle = {
  border: "1px solid #00B0F5",
  height: "28px",
  fontSize: "14px",
  fontWeight: "400",
  padding: "17px 16px",
  borderRadius: "4px",
}

export const headingStyle = {
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "24px",
  color: "#262626",
};

export const labelStyle = {
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "28px",
  color: "#262626",
};

export const buttonStyle = {
  height: "44px",
  width: "180px",
  borderRadius: "8px",
  padding: "17px 16px 17px 16px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};

export const tabLabelStyle = {
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "20px",
  //   textAlign: "center",
  color: "#0F0F0F",
};

export const saveTestContentSvg = {
  position: "absolute",
    right: "-23px",
    top: "55%",
    cursor: "pointer",
    width: "36px"
};

export const actionStyle = {
  padding: "0px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: "10px",
};

export const formDataSet = { chapter: "", topic: [], subTopic: [] };
 
export const basicForm = {
  testType: "",
  isMandatory: true,
  all: false,
  format: "",
  mode: "",
  testNumber: "",
  startDate: "",
  endDate: "",
  resultDate: "",
  testMediumType: 'Tribyte',
};

export const addTestFormInput = { chapter: "", topic: [], subTopic: [] };

export const isBasicFormFilled = (form) => {
  const { testType, format, mode, startDate, endDate, resultDate, testMediumType } = form;

  const testMediumTypeCheck = mode?.value?.toLowerCase()!=='offline' ? testMediumType : true

  return (
    format  &&
    mode  &&
    startDate  &&
    endDate  &&
    resultDate &&
    testType &&
    testMediumTypeCheck
  );
};

const getSubTopicByParentId = (subTopics, parentId) => {
  return subTopics
    .filter((subTopic) => subTopic.parentNode === parentId)
    .map((item) => ({
      key: item.key,
      title: item.label,
      nodeType: "sub-topic",
      parentNode: parentId,
      children: null,
    }));
};

const getTopicsAndItsChildren = (topics, subTopics, parentId) => {
   let topicArr = []
   topics.forEach((topic) => {
    if(topic.key !=="ALL"){
    topicArr.push({
    key: topic.value,
    title: topic.label,
    nodeType: "topic",
    parentNode: parentId,
    children: getSubTopicByParentId(subTopics, topic.key)
    })}
   }
   )
   return  topicArr

};

const getTestContentEl = (contents, isCompleteSyllabus) => {
    if(isCompleteSyllabus) return null ;
    if( contents && contents[0]?.chapter.key === "ALL") return null
  return contents && contents.map((content) => {
    return {
      key: content?.chapter?.value,
      parentNode: null,
      title: content.chapter.label,
      nodeType: "chapter",
      children: getTopicsAndItsChildren(
        content.topic,
        content.subTopic,
        content.key
      ),
    };
  });
};
const getCourseId = (subject, courses) => {
  let data = courses.filter((course) => course["subjectName"] === subject);
  if (data.length > 0) {
    return data[0]["courseId"];
  } else {
    return 0;
  }
};
export const createRequestFormate = (basicForm, testContentForm, courses, isCompleteSyllabus) => {
  const basicDetails = {
    ...basicForm,
    format: basicForm?.format?.label,
    mode: basicForm?.mode?.value,
    testType: basicForm?.testType?.label,
  };
  // if mode is online or both add 9hours in start data and end date 
  if(TEST_MODE_FOR_9AM.includes(basicDetails.mode)){
    basicDetails.startDate = add9Hours(basicDetails.startDate);
    basicDetails.endDate = add9Hours(basicDetails.endDate);
  }
  const request = { basicDetails: { ...basicDetails } };
  const testContent = [];

  for (let key in testContentForm) {
    let contentData = getTestContentEl(testContentForm[key],isCompleteSyllabus) ;
    testContent.push({
      subject: key,
      all: contentData === null,
      courseId: getCourseId(key, courses),
      tableOfContent:contentData 
    });
    request["testContent"] = testContent;
  }
  return request;
};

export const getTestTypes = () => {
  const url = `${endpoint.academicPlanTests.getTestTypes}`;
  return fetchAllPromisedData(url);
};


export const createTest = (request, isEditMode = false) => {
    if(isEditMode){
      const url = `${endpoint.academicPlanTests.updateTest}`;
      return fetchAllPostPromisedData(url, request, 'put')
    }else{
      if(!request.testContent||request.testContent.length==0){
        return new Promise((resolve, reject) => {
          resolve(false);
        });
      }
     const data= request.testContent;
     request["testContent"]=data.filter((item)=> item["tableOfContent"]==null||item["tableOfContent"].length!=0)

     if(!request.testContent||request.testContent.length==0){
      return new Promise((resolve, reject) => {
        resolve(false);
      });
    }
      const url = `${endpoint.academicPlanTests.createTest}`;
      return fetchAllPostPromisedData(url, request);
      
    }
;
};

export const getCoursesByPackageId = async (packageId) => {
  const url = `${endpoint.academicPlanTests.getPackagesByCourseOrPackageId}`;
  return fetchAllPostPromisedData(url, {packageId})

  // return Promise.resolve({
  //   courses: [
  //     {
  //       "courseId": "158",
  //       "subjectName": "Physics"
  //     },
  //     {
  //       "courseId": "159",
  //       "subjectName": "Chemistry"
  //     },
  //     {
  //       "courseId": "160",
  //       "subjectName": "Mathematics"
  //     }
  //   ],
  // });
};

export const getSyllabusByCourseId = async (courseId) => {
  const url = `${endpoint.academicPlanTests.getSyllabusByCourseId}=${courseId}`;
  return fetchAllPromisedData(url);
};

const AllSelectedItem = { label: "All", value: "ALL", key: "ALL" , isEdit:true };

export const getAllSelected = () => {
  return [
    {
      chapter: AllSelectedItem,
      topic: [AllSelectedItem],
      subTopic: [AllSelectedItem],
    },
  ];
};

export const onSaveValidatorTestContent = (tempForm, savedForm) =>{
     let tempSubjects = Object.keys(tempForm) ;
     let chapterNotAddedFor = []
     if(Array.isArray(tempSubjects)){
      tempSubjects.forEach(subjectName=>{
        if(!savedForm[subjectName] || savedForm[subjectName] &&savedForm[subjectName].length ===0 ){
          chapterNotAddedFor.push(subjectName)
        }
      })
     }
     return chapterNotAddedFor.join(",")
}

export const getSyllabusByTestId  = async (testId)=>{
   return fetchAllPromisedData(`${endpoint?.academicPlanTests?.getTestSyllabusByTestScheduleId}/${testId}`, true);

}



const dropdownOptionFormat = (item)=>{
  return {
      key :item.key,
      parentNode :item.parentNode,
      label:item.title,
      value:item.key
  }
}
export const getFinalModal = (data)=>{
  const subject = {}
  data.forEach(item=>{
    let content = []

        // if(!item.all){
           item.tableOfContent && item.tableOfContent.forEach(chapter=>{
              const rowItem = {chapter:{}, topic:[], subTopic:[]}
                
                rowItem['chapter'] = dropdownOptionFormat(chapter);
               let subTopic = []

                rowItem['topic'] =  chapter.children.map(e=>{
                  let currentSubtopic = (e.children && e.children.map(sub=>dropdownOptionFormat(sub)))
                     if(currentSubtopic)
                        (subTopic.push(...currentSubtopic))
               return   dropdownOptionFormat(e)
                        
                  })
              rowItem['subTopic'] = subTopic
              content.push(rowItem)

            })
            subject[item['subject']] = content
        // }
  })
  return subject ;
}

export const allSubTopicOption = { label: "All (sub-topics)", value: "*", key: "ALL" }