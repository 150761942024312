  import React from 'react'
  const style  = {
    background: 'rgba(50, 147, 111, 0.16)',
    borderRadius: '8px',
    padding:" 1px 10px",
}
  const AChip = ({success, label}) => {
    const textColor  = success?"#519C66":"#CC5F5F" ;
    const bgColor  = success?"rgba(50, 147, 111, 0.16)":"rgba(245, 126, 119, 0.2)"
    return (
      <div style = {{...style, 
          color:textColor, 
          background:bgColor}}>
          {label}
       </div>
    )
  }
  
  export default AChip