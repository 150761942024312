import React, {useState, useEffect, useContext} from 'react';
import {ToastContainer} from 'react-toastify';
import {
    masterServiceBaseUrl,
    getAPI,
    examControllerServiceBaseUrl,
    enrollmentServiceBaseUrl
} from 'services/http';
import {Container, Button,Card,CardHeader,Row,Col,CardBody,Table} from 'reactstrap';
import InternalTestResultSearchForm from "./internalTestResultSearchForm";
import InternalTestResultTable from "./internalTestResultTable";
import {useHistory} from "react-router-dom";
import CustomLoader from "../../../common/commonComponents/Loader/loader";
import {fetchDetails, getDispValForReactSelectByOneID} from "../../../common/utils/methods/commonMethods/utilityMethod";
import {fetchData} from "../../studentAdmission/applicationIdGeneration/applicationIdFunctions";
import {PostData} from "./internalTestResultAddNewForm";
import {failureToast, successToast} from "../../../common/utils/methods/toasterFunctions/function";
import {GetPagination} from "../../../common/commonComponents/pagination/pagination";
import { PermissionContext, RolePermissions } from "appContext";
import { pages } from "../../../common/constant";

const apiEndPoints = {
    // getAll : 'http://10.10.2.223:8593/api/masterServices/internalTestResult/getAllActiveInternalTestResult',
    // search : 'http://10.10.2.223:8593/api/masterServices/internalTestResult/internalTestResultSearch'
    getAll : `${examControllerServiceBaseUrl}/internalTestResult/getAllActiveInternalTestResult`,
    search : `${examControllerServiceBaseUrl}/internalTestResult/internalTestResultSearch`,
    // delete : `${masterServiceBaseUrl}/epmrStatus/deleteEpmrStatus/`,
    // update : `${masterServiceBaseUrl}/epmrStatus/updateEpmrStatus/`,
    // create : `${masterServiceBaseUrl}/epmrStatus/createEpmrStatus`,
}
const searchDataObject = {
    studentId:'',
    rollNumber:'',
    testRecId:'',
    examId:'',
    businessAreaId:''
}


const InternalTestResultSearch = (props)=>{

    const permissions = useContext(PermissionContext);
    const userPermissions = RolePermissions(permissions, pages["internalTestResult"]['id']);

    let history = useHistory();
    const [internalTestData,setInternalTestData] = useState([]);
    const [searchData,setSearchData] = useState(searchDataObject);
    const [businessArea,setBusinessArea] = useState([]);
    const [apiLoader,setApiLoader] = useState(true);
    const [examIds,setExamIds] = useState([]);
    const [ academicYear, setAcademicYear ] = useState([] );
    const [serverData,setServerData] = useState([]);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        nextPage: null,
        previousPage: null,
        totalPage: null,
    });
    const pageSize = 10;
    const dataForExcel = internalTestData?.map(item=>{
        return {
            ...item,
            businessAreaDispValue : getDispValForReactSelectByOneID(item.businessAreaId ,'DispValue',businessArea),
        }
    })

    const excelDetails = {
        "Student ID": "studentId",
        "Student Name": "studentName",
        "Roll Number": "rollNumber",
        "Exam ID": "examId",
        "Exam Description": "examDescription",
        "Test Rec ID": "testRecId",
        "Business Area": "businessAreaDispValue",
        "Exam Type": "examTypeId",
        "Academic Year": "academicYearId",
        "Percentage": "percentage",
        "Percentile": "percentile",
        "All India Rank": "allIndRank",
        "Exam Date": "examDate",
        "Status": "status",
    };

    const updatePagination = (paramsdata) => {
        let n = pagination;
        if (paramsdata.length > pageSize) {
            n["nextPage"] = n.currentPage + 1;
        }
        if (n.currentPage > 1) {
            n.previousPage = n.currentPage - 1;
        }
        n["totalPage"] = Math.floor(paramsdata.length / pageSize);
        if (paramsdata.length % pageSize !== 0) {
            n["totalPage"] += 1;
        }
        setPagination(n);
        setInternalTestData(
            paramsdata.slice(
                pagination.currentPage * pageSize - pageSize,
                pagination.currentPage * pageSize
            ) || []
        );
        setApiLoader(false);
    }

    const searchHandler = () =>{
        if(searchData.studentId === '' && searchData.rollNumber === '' && searchData.testRecId === '' && searchData.examId === '' && searchData.businessAreaId === ''){
            failureToast('Please Select atleast one value.');
        }else{
            let tempObject = {
                studentId : searchData.studentId.value,
                rollNumber : searchData.rollNumber,
                testRecId : searchData.testRecId,
                examId : searchData.examId,
                businessAreaId : searchData.businessAreaId
            };
            let url = apiEndPoints.search;
            let method = 'POST';

            PostData(url,tempObject,method).then((data)=>{

                if(data.code === 200){
                    successToast(data.message);
                    setServerData(data.data)
                    updatePagination(data.data);
                }else if(data.code ===404){
                    failureToast(data.message);
                    setInternalTestData([]);
                    setApiLoader(false);
                }
            })
        }
    }

    const resetHandler = () =>{
        setSearchData(searchDataObject);
        setInternalTestData([])
    }

    const updateSearchData = (key,value)=>{
        setSearchData(preState => ({...preState, [key]: value}))
    }

    const fetchRequiredData = async ()=>{
        fetchDetails(masterServiceBaseUrl+'/businessArea/getAllActiveBusinessArea',setBusinessArea, 'businessArea', 'reactSelect');
        fetchData(examControllerServiceBaseUrl+'/exam/getAllActiveExamId',setExamIds,'exam');
        fetchData(masterServiceBaseUrl + '/academicYear/all',setAcademicYear,'academicYear');
    }

    const filterOption = (data) => {
        return data?.map((data) => {
            return Object.assign(
                {},
                {
                    label : data?.studentId,
                    value : data?.studentId,
                    studentName : `${data?.studentFirstName} ${data?.studentLastName}`,
                }
            );
        });
    };
    const promiseOptions = (inputValue) => {
        return new Promise((resolve) => {
            if (inputValue && inputValue.length < 10) {
                return resolve([]);
            }
            getAPI(
                `${enrollmentServiceBaseUrl}/studentDetails/getAllStudentBySmartSearch/${inputValue}`,
                (data) => {
                    resolve(filterOption(data?.data));
                },
                (data) => {
                    // failureToast(data["message"]);
                }
            );
        });
    };

    useEffect(()=>{
        fetchRequiredData().then(()=>{setApiLoader(false)});
    },[])




    return (
        apiLoader
            ?
            <div className="mx-auto text-center py-5 my-5" style={{ height: "100vh" }}>
                <CustomLoader apiLoader={apiLoader}/>
            </div>
            :
            <Container className="mt-3" fluid>
                <InternalTestResultSearchForm
                    searchData={searchData}
                    resetHandler={resetHandler}
                    searchHandler={searchHandler}
                    updateSearchData={updateSearchData}
                    businessArea={businessArea}
                    promiseOptions={promiseOptions}
                    examIds={examIds}
                    userPermissions={userPermissions}
                />
                {
                    internalTestData.length > 0 ?
                        <InternalTestResultTable
                            internalTestData={internalTestData}
                            businessArea={businessArea}
                            history={history}
                            excelDetails={excelDetails}
                            exportData={dataForExcel}
                            academicYear={academicYear}
                            userPermissions={userPermissions}
                        />
                        :
                        null
                }
                {
                    pagination.totalPage > 1
                        ?
                            <GetPagination
                                isSaveVisible={false}
                                setclassArray={setInternalTestData}
                                pagination={pagination}
                                setpagination={setPagination}
                                pageSize={pageSize}
                                state={serverData}
                            />
                        :
                        null
                }
            </Container>
    );
}

export default InternalTestResultSearch;