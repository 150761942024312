import React, { useEffect } from "react";
import ScreenTopHeader from "../ScreenTopHeader";
import {
  useGetDlpModuleContext,
  PAGE_STATE,
} from "../../contextApi/dlpContext";
import MarkEligibleTable from "./MarkEligibleTable";
import { Card, CardBody } from "reactstrap";
import SuccessDialog from "./SuccessDialog";
import { getDownloadFileUrl } from "../../stockConsumption/form/helper";
import { downloadTemplateForBulkDispatch } from "../../service";

const MarkEligibleScreen = () => {
  const {
    setCurrentPageState,
    openSuccessPopup,
    setSuccessPopup,
    filterForm,
    markedEligibleRequest,
    setLoading,
    loading,
  } = useGetDlpModuleContext();

  const downloadHandler = async () => {
    try {
      setLoading(true);
      const response = await downloadTemplateForBulkDispatch(
        markedEligibleRequest?.map(item=>({...item, status: [item?.status]}))
      );
      if (response?.code === 200) {
        getDownloadFileUrl(response?.data.key);
      }
      setLoading(false);
    } catch (error) {}
  };

  return (
    <div>
      <ScreenTopHeader
        goBackHandler={() => setCurrentPageState(PAGE_STATE.SEARCH_STUDENT)}
        labelText="Mark eligible to issue stocks"
      />
      <Card className="mx-3 my-4">
        <CardBody>
          <MarkEligibleTable />
        </CardBody>
        <SuccessDialog
          open={openSuccessPopup}
          handleClose={() => setSuccessPopup(false)}
          downloadHandler={downloadHandler}
          isLoading={loading}
        />
      </Card>
    </div>
  );
};

export default MarkEligibleScreen;
