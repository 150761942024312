import temp from "../../../common/temp";

const getHeaderExportData =(data,sheetName)=>{
    let  columns= [
        { label: 'Business Group', value: 'businessGroupIds'},
        { label: 'Company Code', value: 'companyCodeIds'},
        { label : 'Zone', value : 'zoneIds'},
        { label : 'Region', value : 'regionIds'},
        { label : 'Business Area', value : 'businessAreaIds'},
        { label : 'Academic Career', value : 'academicCareerIds'},
        { label : 'Academic Group', value : 'academicGroupIds'},
        { label : 'Course ID', value : 'courseIds'},
        { label : 'Term', value : 'termIds'},
        { label : 'Academic Year', value : 'academicYearIds'},
        { label : 'Academic Phase', value : 'academicPhaseIds'},
        { label : 'Batch', value : 'batchIds'},
        { label : 'Exam ID', value : 'examIds'}
    ]
    let contentObj = {
        businessGroupIds:'',
        companyCodeIds:'',
        zoneIds:'',
        regionIds:'',
        businessAreaIds:'',
        academicCareerIds:'',
        academicGroupIds:'',
        courseIds:'',
        termIds:'',
        academicYearIds:'',
        academicPhaseIds:'',
        batchIds:'',
        examIds:''
    }
    let content = [];
    for (let key in data ){
        if (data.hasOwnProperty(key) && (Array.isArray(data[key]))) {
            if(data[key].length){
                data[key].forEach((item,index)=>{
                    let tempObj = content[index];
                    if(tempObj){
                        tempObj = content[index];
                        tempObj = {
                            ...tempObj,
                            [key] : item?.label ? item.label : ''
                        }
                    }else{
                        tempObj = contentObj;
                        tempObj = {
                            ...tempObj,
                            [key] : item?.label ? item.label : ''
                        }
                    }
                    content[index] = tempObj;
                })
            }
        }
    }
    if( content.length ){
        return {
            sheet : sheetName,
            content:content,
            columns : columns
        };
    }
    return null;
}
const extractLabel = (data,key) =>{
    let tempArray = [];
    data.map(item => {
        tempArray.push(
            item[key]
        )
    })
    return tempArray;
}

const getExamSyllabusData = (data,sheet)=>{
    let content = [];
    let columns = [
        { label : 'S. NO.',value:'sNo'},
        { label: 'Subject', value: 'subject' }, // Top level data
        { label: 'Chapter', value: 'chapterIds' }, // Run functions
        { label: 'Topic', value : 'topicIds' }, // Deep props
        { label: 'Sub Topic', value: 'subTopicIds' }, // Deep props
        { label: 'Sub Sub Topic', value: 'subSubTopicIds' }, // Deep props
        { label: 'Status', value: 'status' },
    ];
    let contentObj = {
        subject : '',
        chapterIds:'',
        topicIds : '',
        subTopicIds : '',
        subSubTopicIds : '',
    }
    let lastIndex = 0;
    data.forEach((item,index)=>{
        let sNoIndex = 0;
        let tempObj = {
            subject : item.subject.label,
            sNo : index +1
        }
        content[lastIndex ] = tempObj;
        let chapter = item.chapterIds;
        let topic = item.topicIds;
        let subTopic = item.subTopicIds;
        let subSubTopic = item.subSubTopicIds;
        let status = item.status;
        if(chapter.length >= 1 && topic.length >= 1){
            let newChapter = (extractLabel(chapter,'label' )).toString();
            let newTopic = (extractLabel(topic,'label' )).toString();
            let newSubTopic = (extractLabel(subTopic,'label' )).toString();
            let newSubSubTopicIds = (extractLabel(subSubTopic,'label' )).toString();
            let tempObj = content[index];
            tempObj = {
                ...tempObj,
                chapterIds:newChapter,
                topicIds: newTopic,
                subTopicIds: newSubTopic,
                subSubTopicIds: newSubSubTopicIds,
                status:status
            }
            content[index] = tempObj;
            lastIndex = index + 1;
        }
    })

    return {
        columns : columns,
        content : content,
        sheet : sheet
    }

}
export const ExamSyllabusHelpers = {
    getHeaderExportData : getHeaderExportData,
    getExamSyllabusData : getExamSyllabusData
}