import React from "react";
import { Container } from "reactstrap";

const CustomContainer = ({ children, backIcon = false, goBack = () => { }, title = '' }) => {
  return (
    <Container fluid className="mt-4">
      {backIcon && <div className="d-flex felx-row mb-5 align-items-center text-bold" type="button" onClick={goBack}>
        <i className="fa fa-arrow-left" />&nbsp;&nbsp;&nbsp;
        {`Back to ${title}`}
      </div>}
      {children}
    </Container>
  );
};

export default CustomContainer