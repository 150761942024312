import React from 'react'
import { isLecturePassed, timeBreakGap } from '../helper'
import { Tooltip } from '@mui/material'
import { badge } from './LectureListItem'
import { ReactComponent as DeleteIcon } from "assets/img/svg/Delete.svg";
import { ReactComponent as EditIcon } from "assets/img/svg/Edit2.svg";
import DeleteLectureConfirmationDialog from "./DeleteLectureConfirmationDialog";
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'


const LectureListItemBreak = ({ rowIndex, rowData, isEnabledMsTeamsSync, openMode, rowClassName, isDeleteDialogOpen, setIsDeleteDialogOpen, deleteHandler }) => {
  return (
    <>
      <div className={`${isEnabledMsTeamsSync
        ? rowClassName
        : "timetable-lecture-details-card-row-without-msteams"
        } my-2`} style={badge}>
        <div>{timeBreakGap(rowData?.endTime, rowData?.startTime)} Mins break</div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        {isEnabledMsTeamsSync && <div></div>}
        <div></div>
        <div className="d-flex" style={{ gap: "8px" }}>
          {openMode !== "VIEW" &&
            (isLecturePassed(rowData?.lectureEndTime) ? (
              <>
                <IconButtonWrapper className='invisible'><EditIcon /></IconButtonWrapper>
                <IconButtonWrapper>
                  <Tooltip title="Cannot delete as start date has passed">
                    <DeleteIcon />
                  </Tooltip>
                </IconButtonWrapper>
              </>
            ) : (
              <>
                <IconButtonWrapper className='invisible'><EditIcon /></IconButtonWrapper>
                <IconButtonWrapper onClick={() => setIsDeleteDialogOpen(true)}><DeleteIcon /></IconButtonWrapper>
              </>
            ))}
        </div>
      </div>
      <DeleteLectureConfirmationDialog
        open={isDeleteDialogOpen}
        setOpen={setIsDeleteDialogOpen}
        data={rowData}
        onAgree={() => deleteHandler(rowIndex)}
      />
    </>
  )
}

export default LectureListItemBreak