import React, { useContext, useRef } from 'react'
import { useHistory, useParams } from 'react-router';
import { PermissionContext, RolePermissions } from "appContext";
import { endpoint, pages } from '../../../common/constant';
import { checkPermission, fetchAllPromisedData } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { successToast } from '../../../common/utils/methods/toasterFunctions/function';
import { linkButtonStyles } from '../../constant/student-details';
import PasswordReset from './PasswordReset';
import { requestSupplementaryId } from './constant';
import { SUPPLEMENTARY_ID_FLAG } from '../../studentSerach/StudentList';
import { eligibleProgramActionForSupplementaryId } from '../../studentSerach/constant';
import ChangeProgramAction from '../commonQuickActions/ChangeProgramAction';


const BatchDetailsQuickActions = ({
  studentDetails,
  fetchStudentDetails,
  fetchApplications,
  activeApplicationDetails,
  setApiLoader = () => {},
}) => {
  const history = useHistory();
  const { psid } = useParams();

  const batchDetailsPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["studentDashboardBatchDetails"]["id"]
  );

  const createMsTeamsId = async () => {
    setApiLoader(true);
    const msRes = await fetchAllPromisedData(
      `${endpoint?.studentDetails?.createMsTeamsIdByPsid}/${psid}`,
      true
    );
    if (msRes?.code === 200) {
      await fetchStudentDetails();
      successToast(msRes?.message);
    }
    setApiLoader(false);
  };

  const resetPasswordHandler = async userInput => {
    return await fetchAllPromisedData(
      `${endpoint?.student?.resetPassword}/${psid}?studentPassword=${userInput}`,
      true
    );
  };

  const allocateInBatchClickHandler = e => {
    if (e?.ctrlKey) {
      window.open(
        history.createHref({ pathname: `/admin/edp/searchBatch` }),
        "_blank"
      );
    } else {
      history.push(`/admin/edp/searchBatch`);
    }
  };

  const requestSupplementaryIdHandler = async () => {
    setApiLoader(true);
    try {
      const res = await requestSupplementaryId(activeApplicationDetails);
      if(res?.code===200) fetchApplications();
    } catch (error) {
      console.error(error)
    } finally {
      setApiLoader(false);
    }
  }

  return (
    <div className='d-flex flex-column align-items-start'>
      {checkPermission(batchDetailsPermissions, ['C', 'U']) && (
        <span style={linkButtonStyles} onClick={allocateInBatchClickHandler}>
          Allocate in batch
        </span>
      )}
      {checkPermission(batchDetailsPermissions, ['C', 'U']) &&
        studentDetails?.isMsTeamsSyncEligible &&
        !studentDetails?.msTeamsUserId && (
          <span style={linkButtonStyles} onClick={createMsTeamsId}>
            MS teams ID sync
          </span>
        )}
      {checkPermission(batchDetailsPermissions, ['C', 'U']) &&
        studentDetails?.isMsTeamsSyncEligible && (
          <PasswordReset
            setApiLoader={setApiLoader}
            resetPasswordHandler={resetPasswordHandler}
          />
        )}
      {SUPPLEMENTARY_ID_FLAG &&
        eligibleProgramActionForSupplementaryId?.includes(
          activeApplicationDetails?.programActions
        ) &&
        checkPermission(batchDetailsPermissions, ['C', 'U']) && (
          <span
            style={linkButtonStyles}
            onClick={requestSupplementaryIdHandler}
          >
            Request for supplementary ID
          </span>
        )}
      {checkPermission(batchDetailsPermissions, ['C', 'U']) && (
        <ChangeProgramAction
          activeApplicationDetails={activeApplicationDetails}
          fetchApplications={fetchApplications}
        />
      )}
    </div>
  );
};

export default BatchDetailsQuickActions