import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { getStudentList, searchStudentClear } from "../../../../../redux/studentCharges/actions";
import ALoader from "../../common/a-loader";
import LeftSideFilter from "./LeftSideFilter";
import Filter from "./Filter";
import StudentList from "./studentList";
// import { globalFilterModal } from './common';
import '../../edpOps/studentSerach/style.scss'

import { FILTER__FORM_FIELDS_GLOBAL, PAGE_STATE, StudentListContext } from "./contextApi";
import { ReactComponent as BackIcon } from "assets/img/svg/BackButton.svg";



const selectAllObject = {
  label: 'ALL', value: '*'
}

export const emptySearchFields = {
  psid : '',
  applicationId : '',
  opportunityId : '',
  studentName : ''
}

const StudentListWrapper = () => {
  const { globalFilter, setGlobalFilter,setCurrentPageState } = useContext(StudentListContext);

  const dispatch = useDispatch();
  const studentCharges = useSelector((state) => state.studentCharges);
  // const [globalFilter, setFilterForm] = useState(globalFilterModal);
  // const [regionAndBusiness, setRegionAndBusiness] = useRegionAndBusinessArea(() => dispatch(startLoader()), () => dispatch(stopLoader()));
  const [currentPageNumber, setPageNumber] = useState(0);
  const [isLastPage, setIsLastPage]=useState(false);
  const [appliedFilterCount, setAppliedFilterCount] = useState(0);
  const [businessAreaMap, setBusinessAreaMap] = useState({ region: studentCharges?.dropdowns?.region ?? [], businessArea: globalFilter?.businessArea ? globalFilter?.businessArea : studentCharges?.dropdowns?.businessArea ?? [] })
  // const [courseIdDropdown, setCourseIdDropdown] = useState([]);
  // const [termDropdown,setTermDropdown] = useState([]);
  // const [initailFetching,setInitailFetching] = useState(true);

  const setFilterForm = (f) => {
    setGlobalFilter({ ...f })
  }

  const inputHandler = (value, inputName) => {
    let currentData = { ...globalFilter };
    if (inputName === "businessAreaClear") {
      currentData = { ...globalFilter, businessArea: [] };
      currentData["region"] = value;
      setFilterForm(currentData);
    } else {
      currentData[inputName] = value;
      setFilterForm(currentData);
    }
  };

  // const leftSideFormInputHandler = (value, inputName) => {
  //   setRegionAndBusiness({[inputName]: value })
  // }

  // const getAllInitailList = async () => {
  //   const p = await Promise.all([
  //     dropdownMapping(endpoint?.course_details?.getCachedRecords,'courseName',true),
  //     dropdownMapping(endpoint?.term?.getAllActive, 'termDispValue'),
  //     dropdownMapping(`${endpoint.businessArea.getAllActive}`, 'businessAreaDispValue'),
  //     dropdownMapping(`${endpoint.region.getAllActive}`, 'regionDispValue'),
  //   ])
  //   // setFilterForm({...globalFilter, course: p[0].map(c=>c.value), businessArea: p[2].map(b=>b.value), term: p[1].map(t=>t.value)})
  //   setCourseIdDropdown(p[0]);
  //   setTermDropdown(p[1]);
  //   setFilterForm({...globalFilter, region: p[3].map(i => i.value), businessArea: p[2].map(i => i.value)});
  //   setBusinessAreaMap({region: p[3], businessArea : p[2]})
  //   setInitailFetching(false)
  // }

 

  const searchHandler = (f) => {
    setPageNumber(0);
    dispatch(getStudentList({ ...f }));
  };

  const isFilterApplied = (filters) => {
    let count = 0;
    Object.keys(globalFilter).map(item => {
      if(!['businessArea','region','psid','searchField'].includes(item)){
        if (Array.isArray(filters[item])) {
          if (filters[item].length !== 0) {
            count++;
          }
        } else if (filters[item] !== '' && filters[item] !== null) {
          count++;
        }
      }
    })
    return count;
  }

  const nextButtonHandler = (pageNumber) => {
    dispatch(getStudentList({ ...globalFilter, courseId: globalFilter?.courseId?.map(i => i.value) ?? [], term: globalFilter?.term?.map(i => i.value) ?? [], chargesStatus: globalFilter?.chargesStatus?.map(i => i.value) ?? [], paymentStatus: globalFilter?.paymentStatus?.map(i => i.value) ?? [] }, pageNumber,setPageNumber, setIsLastPage));
    // setPageNumber(pageNumber);
  };

  const applyFilterHandler = () => {

    setPageNumber(0);
    dispatch(getStudentList({ ...globalFilter, courseId: globalFilter?.courseId?.map(i => i.value) ?? [], term: globalFilter?.term?.map(i => i.value) ?? [], chargesStatus: globalFilter?.chargesStatus?.map(i => i.value) ?? [], paymentStatus: globalFilter?.paymentStatus?.map(i => i.value) ?? [] }, 0));
    setAppliedFilterCount(isFilterApplied(globalFilter))
  };

  const removeFilterHandler = () => {
    // setPageNumber(0);
    // dispatch(getStudentList({ ...globalFilterModal, region: businessAreaMap?.region.map(i => i.value) ?? [], businessArea: businessAreaMap?.businessArea.map(i => i.value) ?? [] }, 0));
    // setAppliedFilterCount(0)
    // setFilterForm(globalFilterModal)
    setFilterForm({
      ...FILTER__FORM_FIELDS_GLOBAL
    })
    setCurrentPageState(PAGE_STATE.SEARCH_STUDENT)
  };

  // const crossInputHandler = () => {
  //   setPageNumber(0);
  //   if(!isFilterApplied){
  //     setCurrentPageState(PAGE_STATE.SEARCH_STUDENT)
  //   }else{
  //     setFilterForm({ ...globalFilter, psid: '' });
  //     dispatch(getStudentList({ ...globalFilter, psid: '' }, 0));
  //   }
  // };

  const crossInputHandler =()=>{
    setPageNumber(0);
      setFilterForm({ ...globalFilter, ...emptySearchFields});
      dispatch(getStudentList({ ...globalFilter,  ...emptySearchFields}, 0));
  }

  const searchInputValueHandler = (name) => {
    setPageNumber(0);
    dispatch(getStudentList({ ...globalFilter}, 0));
    // setFilterForm({ ...filterForm, psid: name });
  };


  useEffect(() => {
    // getAllInitailList();
    // dispatch(fetchInitialList);
    setAppliedFilterCount(isFilterApplied(globalFilter))
    return () => dispatch(searchStudentClear())
  }, []);

  return (
    <>
      <div className={`remove-container-top-space d-flex px-3 py-4 align-items-center`}>
        <BackIcon className="mr-2 large-text" onClick={()=>{setGlobalFilter(prev=>({...prev, searchField : 'psid', ...emptySearchFields})) ; setCurrentPageState(PAGE_STATE.SEARCH_STUDENT)}}/>
        <div className="heading-4 color-black-60 large-text">
          Student List
        </div>
      </div>
      <div
        style={{
          position: "relative",
          display: "flex",
          flexFlow: "column",
          height: "calc(100vh - 120px)",
        }}
      >
        {studentCharges.loading && <ALoader />}
        <Card className="mx-3 mb-2" style={{ flexGrow: 1 }}>
          <CardBody style={{ display: "flex", flexFlow: "column" }}>
            <div className="search-match-container">
              <LeftSideFilter
                filterForm={globalFilter}
                searchHandler={searchHandler}
                setFilterForm={setFilterForm}
                businessAreaMap={businessAreaMap}
                setBusinessAreaMap={setBusinessAreaMap}
              />
              <Filter
                filterForm={globalFilter}
                // regionAndBusiness = {regionAndBusiness}
                setNormalFilterForm={setFilterForm}
                inputHandler={inputHandler}
                applyFilterHandler={applyFilterHandler}
                setAppliedFilterCount={setAppliedFilterCount}
                appliedFilterCount={appliedFilterCount}
                removeFilterHandler={removeFilterHandler}
                crossInputHandler={crossInputHandler}
                courseIdDropdown={studentCharges?.dropdowns?.course ?? []}
                termDropdown={studentCharges?.dropdowns?.term ?? []}
                setFilterForm={setFilterForm}
              // businessAreaDropdown={businessAreaMap}
                setPageNumber={setPageNumber}
                // emptySearchFields={emptySearchFields}
                setGlobalFilter={setGlobalFilter}
                searchInputValueHandler={searchInputValueHandler}
              />
            </div>
            <StudentList
              rowsData={studentCharges.studentList || []}
              rowCount={studentCharges.rowCount || 0}
              nextButtonHandler={nextButtonHandler}
              currentPage={currentPageNumber}
              isLastPage={isLastPage}
              businessAreaMap={businessAreaMap}
              courseIdDropdown={studentCharges?.dropdowns?.course ?? []}
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default StudentListWrapper;