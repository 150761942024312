import React, { useEffect, useState } from "react";
import AAutoComplete from "../../../../common/form-fields-mui/AAutoComplete";
import { Card, CardBody, Col, Row } from "reactstrap";
import AButton from "../../../../common/form-fields-mui/AButton";
import { dispatchIdDropdown, initialFilterForm, useGetDlpCourseIdsByBu } from "./constant";
import SelectMaterialElasticSearch from "../../mastersAndMapping/onBoardingMaterialIdSetup/SelectMaterialElasticSearch";
import { InputLabel } from "@mui/material";
import { InputLabelStyle } from "../../../../common/form-fields-mui/ASelect";
import CustomDatePicker from "../../../../common/commonComponents/CustomDatePicker";
import moment from "moment";
import { getFilterCount } from "../../mastersAndMapping/onBoardingMaterialIdSetup/constant";
import { STATUS_DROPDOWN } from "../../../constant/batch-search";
import ALoader from "views/pages/manage/common/a-loader";

const SearchFilterForm = ({
  dropdown,
  closeForm,
  filterCount,
  filterPayload,
  applyFilter = () => {},
}) => {
  const [filterForm, setFilterForm] = useState(filterPayload);
  const filterFormHandler = (value, key) =>
    setFilterForm((prev) => ({ ...prev, [key]: value }));
  const [courseIdDropdown, isFetching] = useGetDlpCourseIdsByBu(filterForm);

  useEffect(() => {
    setFilterForm(filterPayload);
  }, [filterPayload]);

  return (
    <Card
      className="mb-0 regular-body label-color"
      style={{ width: '330px', borderRadius: '12px' }}
    >
      {isFetching && <ALoader />}
      <CardBody className="d-flex flex-column">
        <div className="mb-1 d-flex align-items-start justify-content-between">
          <div className="regular-large-bold">Filter by</div>
        </div>
        <div className="d-flex flex-column gap-md">
          <AAutoComplete
            label="Dispatch ID"
            currentValue={filterForm?.dispatchId}
            items={dispatchIdDropdown}
            handleChange={value => {
              filterFormHandler(value, 'dispatchId');
            }}
            isMulti={false}
          />

          <AAutoComplete
            label="Business area"
            currentValue={filterForm?.businessArea}
            items={dropdown?.businessArea}
            handleChange={value => {
              filterFormHandler(value, 'businessArea');
            }}
            isMulti={false}
          />

          <AAutoComplete
            label="Course ID"
            currentValue={filterForm?.courseId}
            items={courseIdDropdown}
            handleChange={value => {
              filterFormHandler(value, 'courseId');
            }}
            isMulti={false}
          />

          <AAutoComplete
            label="Term"
            currentValue={filterForm?.term}
            items={dropdown?.term}
            handleChange={value => {
              filterFormHandler(value, 'term');
            }}
            isMulti={false}
          />

          <AAutoComplete
            label="Academic group"
            currentValue={filterForm?.academicGroup}
            items={dropdown?.academicGroup}
            handleChange={value => {
              filterFormHandler(value, 'academicGroup');
            }}
            isMulti={false}
          />

          <SelectMaterialElasticSearch
            label="Material details"
            currentValue={filterForm?.materialNumber}
            handleChange={value => filterFormHandler(value, 'materialNumber')}
            isMulti={false}
            dropdownMappingParams={[
              'materialIdAndDescription',
              'materialId',
              'materialDescription',
            ]}
            isDlpSearch
          />

          <div>
            <InputLabel style={InputLabelStyle}>
              Schedule date from-to
            </InputLabel>
            <Row>
              <Col sm="6">
                <CustomDatePicker
                  placeHolder="Select"
                  // maxDate={moment()}
                  value={
                    filterForm?.scheduleDateFrom
                      ? moment(filterForm?.scheduleDateFrom)
                      : null
                  }
                  handler={(index, date, keyName) => {
                    filterFormHandler(date, 'scheduleDateFrom');
                  }}
                />
              </Col>
              <Col sm="6">
                <CustomDatePicker
                  placeHolder="Select"
                  value={
                    filterForm?.scheduleDateTo
                      ? moment(filterForm?.scheduleDateTo)
                      : null
                  }
                  handler={(index, date, keyName) => {
                    filterFormHandler(date, 'scheduleDateTo');
                  }}
                  minDate={moment(filterForm?.scheduleDateFrom)}
                  disabled={!filterForm?.scheduleDateFrom}
                />
              </Col>
            </Row>
          </div>

          <AAutoComplete
            label="Status"
            currentValue={filterForm?.status}
            items={STATUS_DROPDOWN}
            handleChange={value => {
              filterFormHandler(value, 'status');
            }}
            isMulti={false}
          />
        </div>

        <div className="mt-3 d-flex flex-row-reverse justify-content-between">
          <AButton
            variant="primary_filled"
            className="button_remove_left_margin"
            onClick={() => {
              closeForm();
              applyFilter(filterForm);
            }}
            disabled={!getFilterCount(filterForm)}
          >
            Apply filter
          </AButton>
          {filterCount > 0 && (
            <AButton
              variant="outline"
              className="button_remove_left_margin"
              updatedStyle={{ border: '1px solid #CC5F5F', color: '#CC5F5F' }}
              onClick={() => {
                closeForm();
                applyFilter(initialFilterForm);
              }}
            >
              Remove filter
            </AButton>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default SearchFilterForm;
