import React, { useEffect, useRef } from "react";
import "./index.scss";

const Status = ({ value, onChange = () => {}, disabled=false }) => {
  const innerRef = useRef(null);
  const innerInputRef = useRef(null);

  useEffect(() => {
    const label = innerRef.current;
    // subscribe event
    label.addEventListener("keypress", handleKeyPress);
    return () => {
      // unsubscribe event
      label.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  
  const handleKeyPress = (e) => {
    if (e.keyCode === 32) {
      if(innerInputRef.current.checked){
        onChange("INACTIVE")
      }else{
        onChange("ACTIVE")
      }
    }
  };

  return (
    <label
      className="custom-toggle mx-auto ml-2 status-toggle"
      tabIndex="0"
      ref={innerRef}
    >
      <input
        disabled={disabled}
        checked={value === "ACTIVE"}
        type="checkbox"
        id={"status"}
        ref={innerInputRef}
        onChange={(e) => {
          let n = "ACTIVE";
          if (!e.target.checked) n = "INACTIVE";
          onChange(n);
        }}
      />
      <span
        style={{ width: "72px" }}
        className="custom-toggle-slider rounded-circle activeToggle"
        data-label-off="Inactive"
        data-label-on="Active"
      />
    </label>
  );
};

export default Status;
