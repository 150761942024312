export const getInitialFilterForm = () => {
  return {
    name: '',
    academicYear: [],
    academicCareer: [],
    classes: [],
    board: [],
    academicPhase: [],
    classType: [],
    region: [],
    businessArea: [],
    publishStatus: []
  }
}

export const publishStatusDropdownData = [
  {label: 'Published', value: 'P'},
  {label: 'Unpublished', value: 'U'}
]

export const publishStatusMap = {
  'P': 'Published',
  'U': 'Unpublished'
}

const formInfo = {
  academicYear: 'Array',
  academicCareer: 'Array',
  classes: 'Array',
  board: 'Array',
  academicPhase: 'Array',
  classType: 'Array',
  region: 'Array',
  businessArea: 'Array',
  publishStatus: 'Array',
}

export const getFilterCount = (dataSet) => {
  let cnt = 0;
  for (let item in dataSet) {
    if (formInfo[item] === 'String') {
      if (dataSet[item]) cnt++;
    }
    if (formInfo[item] === 'Array') {
      if (dataSet[item].length) cnt++;
    }
  }
  return cnt;
}

export const getTabRequest = (label, dataArray) => {
  if(label==='All') {
    const ids = dataArray?.map(item => item?.value)
    return {academicGroup: ids}
  }
  else {
    const id = dataArray.find(item => item['label']===label)?.value
    return { academicGroup: id ? [id] : [] };
  }
}