import React, { useState, Fragment, useMemo } from 'react';
import {
	Card,
	CardHeader,
	Container,
	Row,
	Col,
	CardBody,
	Input,
	Button,
	Label,
	FormGroup,
	Form,
} from 'reactstrap';
import Loader from '../../common/commonComponents/Loader/loader';
import moment from 'moment';
import FileViewer from 'react-file-viewer';

const ShowService = (props) => {
	const {
		item,
		showTicketDetails,
		documentData,
		index,
		isLoaderActive,
		clickedIndex,
		pagination,
	} = props;

	const [pageNo, setPageNo] = useState();

	return (
		<Fragment>
			<Card
				className={'overflow-hidden p-3 student_service_border'}
				style={{
					cursor: 'pointer',
				}}
				onClick={() => {
					showTicketDetails(item['TicketNo'], index);
					setPageNo(pagination['currentPage']);
				}}
			>
				<span className="student_service_border_bottom"></span>
				<Row>
					<Col sm="12" md="6" className="p-2">
						<Row>
							<Col xs="6">
								<Label className="form-control-label">SR.No</Label>
							</Col>
							<Col xs="6">
								<span className="data-font-size">{item['TicketNo']}</span>
							</Col>
						</Row>
					</Col>
					<Col sm="12" md="6" className="p-2">
						<Row>
							<Col xs="6">
								<Label className="form-control-label">Creation Date</Label>
							</Col>
							<Col xs="6">
								<span className="data-font-size">
									{moment(item['CreationDateTime']).format(
										'DD MMM YYYY hh:mm A'
									)}
								</span>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col sm="12" md="6" className="p-2">
						<Row>
							<Col xs="6">
								<Label className="form-control-label">Status</Label>
							</Col>
							<Col xs="6">
								<span className="data-font-size">
									{item['Status'].toUpperCase().trim() === 'OPEN' ? (
										<span
											className="data-font-size"
											style={{
												color: 'red',
												fontWeight: '600',
											}}
										>
											{item['Status'].toUpperCase().trim()}
										</span>
									) : (
										<span
											className="data-font-size"
											style={{
												color: 'green',
												fontWeight: '600',
											}}
										>
											{item['Status'].toUpperCase().trim()}
										</span>
									)}
								</span>
							</Col>
						</Row>
					</Col>
					<Col xs="12" md="6" className="p-2">
						<Row>
							<Col xs="6">
								<Label className="form-control-label">Description</Label>
							</Col>
							<Col xs="6">
								<span className="data-font-size">{item['Description']}</span>
							</Col>
						</Row>
					</Col>
				</Row>
			</Card>
			{isLoaderActive &&
			clickedIndex === index &&
			pageNo === pagination['currentPage'] ? (
				documentData && documentData['TicketNo'] === item['TicketNo'] ? (
					<Card
						className={'overflow-hidden p-3  ticket_border'}
						style={{
							background: '#f8fbf8',
						}}
					>
						<CardHeader
							className="student_service_header"
							style={{
								background: '#f8fbf8',
							}}
						>
							<h3>Details</h3>
						</CardHeader>
						<Row>
							<Col sm="12" md="6" className="p-2">
								<Row>
									<Col xs="6">
										<Label className="form-control-label">Category</Label>
									</Col>
									<Col xs="6">
										<span className="data-font-size">
											{documentData['Category']}
										</span>
									</Col>
								</Row>
							</Col>
							<Col sm="12" md="6" className="p-2">
								<Row>
									<Col xs="6">
										<Label className="form-control-label">SubCategory</Label>
									</Col>
									<Col xs="6">
										<span className="data-font-size">
											{documentData['SubCategory']}
										</span>
									</Col>
								</Row>
							</Col>
						</Row>
						{documentData['ResolvedDate'] ? (
							<Row>
								<Col sm="12" md="6" className="p-2">
									<Row>
										<Col xs="6">
											<Label className="form-control-label ">
												Resolved Date
											</Label>
										</Col>
										<Col xs="6">
											<span className="data-font-size">
												{documentData['ResolvedDate']}
											</span>
										</Col>
									</Row>
								</Col>
								<Col sm="12" md="6" className="p-2">
									<Row>
										<Col xs="6">
											<Label className="form-control-label">Comment</Label>
										</Col>
										<Col xs="6">
											<span className="data-font-size">
												{documentData['Comment']}
											</span>
										</Col>
									</Row>
								</Col>
							</Row>
						) : null}
						{documentData['Document'].length > 0 ? (
							<Row>
								<Col sm="12" md="6" className="p-2">
									<Row>
										<Col xs="6">
											<Label className="form-control-label ">Document</Label>
										</Col>
										<Col xs="6">
											<a
												href={documentData['Document'][0]['DocPath']}
												target="_blank"
												className="data-font-size"
											>
												Open Document
											</a>
										</Col>
									</Row>
								</Col>
							</Row>
						) : null}
					</Card>
				) : (
					<Card
						className="ticket_border"
						style={{
							background: '#f8fbf8',
						}}
					>
						<Loader />
					</Card>
				)
			) : null}
		</Fragment>
	);
};

export default ShowService;
