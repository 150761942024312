import CustomButton from "components/CustomButton";
import moment from 'moment';
import React, { useState } from "react";
import Select2 from "react-select2-wrapper";
import { Card, CardHeader, Col, Container, Input, Row, Table } from "reactstrap";
import { getAPI, masterServiceBaseUrl, putpost, putpost2 } from 'services/http';
import { MasterHeader, TDROW } from "../../common/commonComponents/masterRow/masterRow";
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import './index.scss';
import CommonInput from "../../common/formFeilds/input/commonInput";

var _ = require('lodash');

const PincodeTable = (props) => {
  const { pincode, setPincode, pagination, setpagination, tableData, setclassArray, userPermissions, isSaveVisible, setisSaveVisible = () => { } } = props;
  const [tableDataKey, settableDataKey] = useState('tableDataKey')
  const [stateList, setStateList] = useState([])
  const [districtList, setDistrictList] = useState([])
  const [cityList, setCityList] = useState([])
  const [pinCodeSearch, setPinCodeSearch] = useState('');
  const [countrySearch, setCountrySearch] = useState('');
  const [stateSearch, setStateSearch] = useState('');
  const [districtSearch, setDistrictSearch] = useState('');

  const addNewClass = () => {
    props.setclassArray([{ "editMode": true, "__status": "__new", "pincodeKey": null, 'status': 'ACTIVE', "pincodeDesc": null, "pincodeDispValue": null, "effectiveDate": null, "pincodeGstCode": null, "countryId": null, "stateId": null, "districtId": null, "cityId": null }, ...props.classArray])
  }
  const saveAll = (cb) => {
    var _tempArray = props.classArray
    var isValid = true
    _tempArray.forEach((el, index) => {
      if (el['editMode']) {
        if (!el['countryId']) {
          isValid = false
          failureToast('Please enter the value of Country')
          return
        }
        else if (!el['stateId']) {
          isValid = false
          failureToast('Please enter the value of State')
          return
        }
        else if (!el['districtId']) {
          isValid = false
          failureToast('Please enter the value of District')
          return
        }
        else if (!el['cityId']) {
          isValid = false
          failureToast('Please enter the value of City')
          return
        }
        else if (el['pincodeKey'] === null || !el['pincodeKey'].trim()) {
          isValid = false
          failureToast('Please enter the value of Pincode Key')
          return
        } else if (el['pincodeDesc'] === null || !el['pincodeDesc'].trim()) {
          isValid = false
          failureToast('Please enter the value of Description')
          return
        }
        else if (el['pincodeDispValue'] === null || !el['pincodeDispValue'].trim()) {
          isValid = false
          failureToast('Please enter the value of Display Value')
          return
        }
        else if (el['pincodeGstCode'] === null || !el['pincodeGstCode'].trim()) {
          isValid = false
          failureToast('Please enter the value of Pincode')
          return
        }

        if (!isValid) {
          return
        }
        if (_tempArray[index]['__status'] == "__new") {
          putpost(masterServiceBaseUrl + '/pincode/createPincode', (data) => {
            successToast(data['message'])
            setisSaveVisible(false)
            props.fetchClasses(masterServiceBaseUrl + '/pincode/getAllPincode')
            setStateList([])
            setDistrictList([])
            setCityList([])
          }, (data) => { failureToast(data['message']) }, _tempArray[index], 'post')
        } else {
          putpost(masterServiceBaseUrl + '/pincode/updatePincode/' + _tempArray[index].id, (data) => {
            successToast(data['message'])
            setisSaveVisible(false)
            // delete _tempArray[index]['editMode']
            // props.setclassArray(_tempArray)
            // settableDataKey(new Date().getMilliseconds())
            props.fetchClasses(masterServiceBaseUrl + '/pincode/getAllPincode')
            setStateList([])
            setDistrictList([])
            setCityList([])
            // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
          }, (data) => { failureToast(data['message']) }, _tempArray[index], 'put')
        }
      } else {

      }
    })
  }
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes")
    }
    else {
      let cb = () => {
        addNewClass()
        setisSaveVisible(true)
        // settableDataKey(new Date().getMilliseconds())
      }
      var _tempArray = props.classArray
      _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
      props.setclassArray(_tempArray)
      setisSaveVisible(false)
      cb()
    }
  }
  const editRow = (index) => {

    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes")
    }
    else {
      let cb = () => {
        var _tempArray = props.classArray
        _tempArray[index]['editMode'] = true
        props.setclassArray(_tempArray)
        setisSaveVisible(true)
      }
      var _tempArray = props.classArray
      _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
      cb()
    }
    getData(`${masterServiceBaseUrl}/state/getStateByCountry/${props.classArray[index]['countryId']}`, setStateList, 'state');
    getData(`${masterServiceBaseUrl}/district/getDistrictByState/${props.classArray[index]['stateId']}`, setDistrictList, 'district');
    getData(`${masterServiceBaseUrl}/city/getCityByDistrict/${props.classArray[index]['districtId']}`, setCityList, 'city');
  }
  const deleteRow = (index) => {
    if (props.classArray[index]["__status"] == "__new") {
      let n = props.classArray
      n.splice(index, 1)
      props.setclassArray(n)
      setisSaveVisible(false)
      settableDataKey(new Date().getMilliseconds())
    } else {
      if (window.confirm('Are you sure you want to delete this Pincode?')) {
        putpost2(masterServiceBaseUrl + '/pincode/deletePincode/' + props.classArray[index].id, (data) => {
          successToast(data['message'])
          if (props.classArray.length == 1) {
            props.previousPage()
          } else {
          var _tempArray = props.classArray
          if (_tempArray[index]['editMode']) {
            setisSaveVisible(false)
          }
          _tempArray.splice(index, 1)
          props.setclassArray(_tempArray)
        }
          props.refreshList()
          settableDataKey(new Date().getMilliseconds())
        }, (data) => { failureToast(data['message']) }, 'Delete')
      }
    }
  }
  const updateKey = (index, value, key) => {
    // e.preventDefault();
    let _tempArray = [...props.classArray]
    _tempArray[index][key] = value
    props.setclassArray(_tempArray)

    if (key === 'countryId' && value !== null && value !== '') {
      _tempArray[index]['stateId'] = null
      _tempArray[index]['districtId'] = null
      _tempArray[index]['cityId'] = null
      props.setclassArray(_tempArray)
      setStateList([])
      setDistrictList([])
      setCityList([])
      getData(`${masterServiceBaseUrl}/state/getStateByCountry/${value}`, setStateList, 'state')
    }

    if (key === 'stateId' && value !== null && value !== '') {
      setDistrictList([])
      setCityList([])
      getData(`${masterServiceBaseUrl}/district/getDistrictByState/${value}`, setDistrictList, 'district')
      // getData(`${masterServiceBaseUrl}/city/getCityByState/${value}`,setCityList,'city')
    }

    if (key === 'districtId' && value !== null && value !== '') {
      getData(`${masterServiceBaseUrl}/city/getCityByDistrict/${value}`, setCityList, 'city')
    }
  }


  const getData = (url, stateMethod, keyword) => {
    getAPI(url,
      (data) => {
        const dropList = data.data.map(item => {
          return { id: item.id, text: item[`${keyword}DispValue`] };
        })
        stateMethod(dropList)
      },
      (data) => {
        // failureToast(data['message']);
      }
    )
  }

  const searchPaginationHandler = (filterData, pageSize, n) => {
    if (filterData.length > pageSize) {
      n['nextPage'] = n.currentPage + 1
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1
    }
    n['totalPage'] = Math.floor(filterData.length / pageSize)
    if (filterData.length % pageSize != 0) {
      n['totalPage'] += 1
    }
    setPincode(filterData);
    setclassArray(filterData.slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || []);
    setpagination(n)
  }

  const filterHandler = _.debounce((countryValue, stateValue, districtValue, pincodeValue) => {
    let n = pagination;
    n.currentPage = 1;
    let pageSize = 10;
    if (countryValue || stateValue || districtValue || pincodeValue) {
      const filterCountryList = props.countryList.filter(data => data.text.toUpperCase().includes(countryValue.toUpperCase()));
      const filterStateList = props.stateList.filter(data => data.text.toUpperCase().includes(stateValue.toUpperCase()));
      const filterDistrictList = props.districtList.filter(data => data.text.toUpperCase().includes(districtValue.toUpperCase()));
      const filterCountry = props.countryList.filter(data => data.text.toUpperCase().includes(pincodeValue.toUpperCase()));
      const filterState = props.stateList.filter(data => data.text.toUpperCase().includes(pincodeValue.toUpperCase()));
      const filterDistrict = props.districtList.filter(data => data.text.toUpperCase().includes(pincodeValue.toUpperCase()));
      const filterCity = props.cityList.filter(data => data.text.toUpperCase().includes(pincodeValue.toUpperCase()));

      if (!countryValue && !stateValue && !districtValue && pincodeValue) {
        const filterData = tableData.filter(data => (
          filterCountry.findIndex(findCountry => findCountry['id'] === data['countryId']) >= 0 ||
          filterState.findIndex(findState => findState['id'] === data['stateId']) >= 0 ||
          filterDistrict.findIndex(findDistrict => findDistrict['id'] === data['districtId']) >= 0 ||
          filterCity.findIndex(findCity => findCity['id'] === data['cityId']) >= 0 ||
          data['pincodeDispValue'].toUpperCase().includes(pincodeValue.toUpperCase()) ||
          data['pincodeDesc'].toUpperCase().includes(pincodeValue.toUpperCase()) ||
          data['pincodeGstCode'].toUpperCase().includes(pincodeValue.toUpperCase()) ||
          data['pincodeKey'].toUpperCase().includes(pincodeValue.toUpperCase()) ||
          data['status'].toUpperCase().includes(pincodeValue.toUpperCase())));
        searchPaginationHandler(filterData, pageSize, n);
      } else if (countryValue && !stateValue && !districtValue && pincodeValue) {
        const filterData = tableData.filter(data => data['pincodeDispValue'].toUpperCase().includes(pincodeValue.toUpperCase()) && filterCountryList.findIndex(find => find['id'] === data['countryId']) >= 0);
        searchPaginationHandler(filterData, pageSize, n)
      } else if (countryValue && stateValue && !districtValue && pincodeValue) {
        const filterData = tableData.filter(data => data['pincodeDispValue'].toUpperCase().includes(pincodeValue.toUpperCase()) && filterCountryList.findIndex(findCountry => findCountry['id'] === data['countryId']) >= 0 && (filterStateList.findIndex(findState => findState['id'] === data['stateId']) >= 0));
        searchPaginationHandler(filterData, pageSize, n)
      } else if (countryValue && stateValue && districtValue && pincodeValue) {
        const filterData = tableData.filter(data => data['pincodeDispValue'].toUpperCase().includes(pincodeValue.toUpperCase()) && filterCountryList.findIndex(findCountry => findCountry['id'] === data['countryId']) >= 0 && filterStateList.findIndex(findState => findState['id'] === data['stateId']) >= 0 && filterDistrictList.findIndex(findDistrict => findDistrict['id'] === data['districtId']) >= 0);
        searchPaginationHandler(filterData, pageSize, n)
      } else if (countryValue && !stateValue && districtValue && pincodeValue) {
        const filterData = tableData.filter(data => data['pincodeDispValue'].toUpperCase().includes(pincodeValue.toUpperCase()) && filterCountryList.findIndex(findCountry => findCountry['id'] === data['countryId']) >= 0 && filterDistrictList.findIndex(findDistrict => findDistrict['id'] === data['districtId']) >= 0);
        searchPaginationHandler(filterData, pageSize, n)
      } else if (!countryValue && stateValue && districtValue && pincodeValue) {
        const filterData = tableData.filter(data => data['pincodeDispValue'].toUpperCase().includes(pincodeValue.toUpperCase()) && filterStateList.findIndex(findState => findState['id'] === data['stateId']) >= 0 && filterDistrictList.findIndex(findDistrict => findDistrict['id'] === data['districtId']) >= 0);
        searchPaginationHandler(filterData, pageSize, n)
      } else if (countryValue && !stateValue && districtValue && pincodeValue) {
        const filterData = tableData.filter(data => data['pincodeDispValue'].toUpperCase().includes(pincodeValue.toUpperCase()) && filterCountryList.findIndex(findCountry => findCountry['id'] === data['countryId']) >= 0 && filterDistrictList.findIndex(findDistrict => findDistrict['id'] === data['districtId']) >= 0);
        searchPaginationHandler(filterData, pageSize, n)
      } else if (!countryValue && !stateValue && districtValue && pincodeValue) {
        const filterData = tableData.filter(data => data['pincodeDispValue'].toUpperCase().includes(pincodeValue.toUpperCase()) && filterDistrictList.findIndex(findDistrict => findDistrict['id'] === data['districtId']) >= 0);
        searchPaginationHandler(filterData, pageSize, n)
      } else if (!countryValue && stateValue && !districtValue && pincodeValue) {
        const filterData = tableData.filter(data => data['pincodeDispValue'].toUpperCase().includes(pincodeValue.toUpperCase()) && filterStateList.findIndex(findState => findState['id'] === data['stateId']) >= 0);
        searchPaginationHandler(filterData, pageSize, n)
      } else if (countryValue && !stateValue && !districtValue && !pincodeValue) {
        const filterData = tableData.filter(data => filterCountryList.findIndex(findCountry => findCountry['id'] === data['countryId']) >= 0);
        searchPaginationHandler(filterData, pageSize, n)
      } else if (countryValue && stateValue && districtValue && !pincodeValue) {
        const filterData = tableData.filter(data => filterCountryList.findIndex(findCountry => findCountry['id'] === data['countryId']) >= 0 && filterStateList.findIndex(findState => findState['id'] === data['stateId']) >= 0 && filterDistrictList.findIndex(findDistrict => findDistrict['id'] === data['districtId']) >= 0);
        searchPaginationHandler(filterData, pageSize, n)
      } else if (countryValue && stateValue && !districtValue && !pincodeValue) {
        const filterData = tableData.filter(data => filterCountryList.findIndex(findCountry => findCountry['id'] === data['countryId']) >= 0 && filterStateList.findIndex(findState => findState['id'] === data['stateId']) >= 0);
        searchPaginationHandler(filterData, pageSize, n)
      } else if (countryValue && !stateValue && districtValue && !pincodeValue) {
        const filterData = tableData.filter(data => filterCountryList.findIndex(findCountry => findCountry['id'] === data['countryId']) >= 0 && filterDistrictList.findIndex(findDistrict => findDistrict['id'] === data['districtId']) >= 0);
        searchPaginationHandler(filterData, pageSize, n)
      } else if (!countryValue && stateValue && districtValue && !pincodeValue) {
        const filterData = tableData.filter(data => filterStateList.findIndex(findState => findState['id'] === data['stateId']) >= 0 && filterDistrictList.findIndex(findDistrict => findDistrict['id'] === data['districtId']) >= 0);
        searchPaginationHandler(filterData, pageSize, n)
      } else if (!countryValue && stateValue && !districtValue && !pincodeValue) {
        const filterData = tableData.filter(data => filterStateList.findIndex(findState => findState['id'] === data['stateId']) >= 0);
        searchPaginationHandler(filterData, pageSize, n)
      } else {
        const filterData = tableData.filter(data => filterDistrictList.findIndex(findDistrict => findDistrict['id'] === data['districtId']) >= 0);
        searchPaginationHandler(filterData, pageSize, n)
      }
    } else {
      searchPaginationHandler(tableData, pageSize, n);
    }
  }, 300)

  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Pincode</h3>
                <div className="floatRight " style={{ display: "flex", alignItems: "center",marginLeft: 'auto' }}>
                  Search : <Input  disabled={isSaveVisible} className="tdInput ml-3 mr-3 customInputWidth" placeholder="Search" onChange={(e) => { let n = e.target.value.trim(); setPinCodeSearch(n); filterHandler(countrySearch, stateSearch, districtSearch, n) }} />
                  <CustomButton
                    disabled={isSaveVisible}
                    className={'floatRight mx-1' + (isSaveVisible ? ' btn-dark cursor-gray' : null)}
                    content={'New Pincode'}
                    permissionType={'C'}
                    icon={true}
                    onClick={() => { newRow(); }}
                    permissionSet={userPermissions}
                  />
                </div>
              </CardHeader>
              <Row>
                <Col style={{ display: "flex" }} className="mb-3">
                  <Input  disabled={isSaveVisible} className="tdInput ml-3 customInputWidth" placeholder="Search Country" onChange={(e) => { let n = e.target.value.trim(); setCountrySearch(n); filterHandler(n, stateSearch, districtSearch, pinCodeSearch) }} />
                  <Input  disabled={isSaveVisible} className="tdInput ml-3 customInputWidth " placeholder="Search State" onChange={(e) => { let n = e.target.value.trim(); setStateSearch(n); filterHandler(countrySearch, n, districtSearch, pinCodeSearch) }} />
                  <Input  disabled={isSaveVisible} className="tdInput ml-3 customInputWidth " placeholder="Search District" onChange={(e) => { let n = e.target.value.trim(); setDistrictSearch(n); filterHandler(countrySearch, stateSearch, n, pinCodeSearch) }} />
                </Col>
              </Row>
              <div id="questionTable" className="table-responsive ">
                <Table className="align-items-center table-flush" >
                  <MasterHeader type={"Pincode"} permissionSet={userPermissions} isSaveVisible={isSaveVisible} />
                  <tbody className="list" key={tableDataKey} >
                    {props.classArray ? props.classArray.map((el, index) =>
                      !el['editMode'] ? (
                        <TDROW
                          type={"pincode"} index={index} el={el} editRow={editRow} deleteRow={deleteRow} districtList={props.districtList} countryList={props.countryList} stateList={props.stateList} cityList={props.cityList} permissionSet={userPermissions} isSaveVisible={isSaveVisible} colspan={2} />
                      ) :
                        (<tr key={index + '-class'}>
                          <td className="text-center p-2 mx-1"><Select2 defaultValue={el['countryId']} data={props.countryList} onChange={(e) => { updateKey(index, e.target.value, 'countryId') }} options={{ placeholder: "Select Country" }} className="tdSelect" /></td>

                          {el['__status'] && el['__status'] === '__new' ?
                            <td className="text-center p-2 mx-1"><Select2 defaultValue={el['stateId']} data={stateList} onChange={(e) => { updateKey(index, e.target.value, 'stateId') }} options={{ placeholder: "Select State" }} className="tdSelect" /></td>
                            : stateList.length ?
                              <td className="text-center p-2 mx-1"><Select2 defaultValue={el['stateId']} data={stateList} onChange={(e) => { updateKey(index, e.target.value, 'stateId') }} options={{ placeholder: "Select State" }} className="tdSelect" /></td>
                              : <td className="text-center p-2 mx-1"><Select2 defaultValue={{}} data={[]} options={{ placeholder: "Select State" }} className="tdSelect" /></td>
                          }

                          {el['__status'] && el['__status'] === '__new' ?
                            <td className="text-center p-2 mx-1"><Select2 defaultValue={el['districtId']} data={districtList} onChange={(e) => { updateKey(index, e.target.value, 'districtId') }} options={{ placeholder: "Select District" }} className="tdSelect" /></td>
                            : districtList.length ?
                              <td className="text-center p-2 mx-1"><Select2 defaultValue={el['districtId']} data={districtList} onChange={(e) => { updateKey(index, e.target.value, 'districtId') }} options={{ placeholder: "Select District" }} className="tdSelect" /></td>
                              : <td className="text-center p-2 mx-1"><Select2 defaultValue={{}} data={[]} options={{ placeholder: "Select District" }} className="tdSelect" /></td>
                          }

                          {el['__status'] && el['__status'] === '__new' ?
                            <td className="text-center p-2 mx-1"><Select2 defaultValue={el['cityId']} data={cityList} onChange={(e) => { updateKey(index, e.target.value, 'cityId') }} options={{ placeholder: "Select City" }} className="tdSelect" /></td>
                            : cityList.length ?
                              <td className="text-center p-2 mx-1"><Select2 defaultValue={el['cityId']} data={cityList} onChange={(e) => { updateKey(index, e.target.value, 'cityId') }} options={{ placeholder: "Select City" }} className="tdSelect" /></td>
                              : <td className="text-center p-2 mx-1"><Select2 defaultValue={{}} data={[]} options={{ placeholder: "Select City" }} className="tdSelect" /></td>
                          }
                          {/* <td className="text-center p-2 mx-1"><Select2 defaultValue={el['districtId']} data={districtList} onChange={(e)=>{updateKey(index,e,'districtId')}} options={{placeholder: "Select District"}} className="tdSelect" /></td>
                      <td className="text-center p-2 mx-1"><Select2 defaultValue={el['cityId']} data={cityList} onChange={(e)=>{updateKey(index,e,'cityId')}} options={{placeholder: "Select City"}} className="tdSelect" /></td> */}
                          <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['pincodeKey']} onChange={(e) => { updateKey(index, e.target.value, 'pincodeKey') }} placeholder="Example:1" className="tdInput" /></td>
                          <td className="text-center p-2 mx-1"><Input disabled={true} defaultValue={el["__status"] == "__new" ? null : moment(el['eff_date']).format('DD-MM-YYYY')} placeholder="Example:01-01-2020" className="tdInput" /></td>
                          <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['pincodeDesc']} onChange={(e) => { updateKey(index, e.target.value, 'pincodeDesc') }} placeholder="Description" className="tdInput" /></td>
                          <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['pincodeDispValue']} onChange={(e) => { updateKey(index, e.target.value, 'pincodeDispValue') }} placeholder="Display Value" className="tdInput" /></td>
                          <td className="text-center p-2 mx-1">
                            {/*<Input maxLength="50" defaultValue={el['pincodeGstCode']} onChange={(e) => { updateKey(index, e.target.value, 'pincodeGstCode') }} placeholder="Pincode" className="tdInput" />*/}
                            <CommonInput
                              type={'number'}
                              maxLength={"6"}
                              forTable={true}
                              restrictSpecialCharacter={true}
                              defaultValue={el['pincodeGstCode']}
                              placeHolder={"Pincode"}
                              className={"tdInput"}
                              onChange={(value) => { updateKey(index, value, 'pincodeGstCode') }}
                            />
                          </td>
                          <td className="text-center p-2 mx-1">
                            <label className="custom-toggle mx-auto ml-2">
                              <input checked={el.status == "ACTIVE" ? true : false} type="checkbox" id={el.id} onChange={(e) => { settableDataKey(new Date().getMilliseconds()); let n = "ACTIVE"; if (!e.target.checked) { n = "INACTIVE" } updateKey(index, n, 'status') }} />
                              <span style={{ width: "72px" }} className="custom-toggle-slider rounded-circle activeToggle" data-label-off="Inactive" data-label-on="Active" />
                            </label>
                          </td>
                          <td className="text-center" colSpan="2">
                            {isSaveVisible ?
                              <>
                                <CustomButton
                                  content={'Save'}
                                  permissionType={'C,U'}
                                  icon={false}
                                  forTable={true}
                                  onClick={() => { saveAll() }}
                                  permissionSet={userPermissions}
                                />
                                <CustomButton
                                  content={''}
                                  permissionType={'cancel'}
                                  icon={true}
                                  forTable={true}
                                  onClick={() => {
                                    let n = props.classArray
                                    if (n[index]['__status'] == '__new') {
                                      n.splice(index, 1)
                                    } else {
                                      props.fetchClasses(masterServiceBaseUrl + '/pincode/getAllPincode')
                                    }
                                    props.setclassArray(n)
                                    setStateList([])
                                    setDistrictList([])
                                    setCityList([])
                                    setisSaveVisible(false)
                                  }}
                                  permissionSet={userPermissions}
                                /> </> :
                              <CustomButton
                                content={''}
                                permissionType={'D'}
                                icon={true}
                                forTable={true}
                                onClick={() => {
                                  setStateList([])
                                  setDistrictList([])
                                  setCityList([])
                                  deleteRow(index)
                                }}
                                permissionSet={userPermissions}
                              />
                            }
                          </td>
                        </tr>)
                    ) : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
export default PincodeTable;