import React, { useContext, useEffect, useRef, useState } from "react";
import ALoader from "views/pages/manage/common/a-loader";
import { endpoint } from "views/pages/manage/common/constant";
import { fetchAllPostPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import styles from './styles.module.scss';
import { ReactComponent as BackIcon } from 'assets/img/svg/BackButton.svg';
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import { useHistory } from "react-router";
import { successToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import ListComponent from "./ListComponent";
import { getCreateReqBody, getDropdowns } from "./constant";
import { checkPermission } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { RolePermissions } from "appContext";
import { PermissionContext } from "appContext";
import { pages } from "views/pages/manage/common/constant";
const SecurityDepositSequencing=()=>{
    const [loader,setLoader] = useState(false)
    const [dropdownData, setDropdownData] = useState();
    const [rows,setRows] = useState()
    const [currentPage, setCurrentPage] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isNew,setIsNew] = useState(false)
    const history = useHistory();
    const listRef = useRef()
    const userPermissions = RolePermissions(useContext(PermissionContext), pages['paymentCollectionMasterSdSequencing']['id'])

    const getDropdown = async () =>{
        setLoader(true);
        const data = await getDropdowns();
        setDropdownData(data);
        setLoader(false)
    }
    const fetchList= async(page=0)=>{
        setLoader(true);
        try{
            const res = await fetchAllPostPromisedData(endpoint?.sdChargePayment?.search,{},'post')
            if(res?.code===200){
                setRows(res?.data)
                listRef.current.stopEditing();
            }
            else{
                setRows([])
            }
        }
        catch(error){
            console.log(error)
        }
        finally{
            setLoader(false)
        }
    }

    const createHandler = async rowData =>{
         setLoader(true);
         const reqBody = getCreateReqBody(rowData);
         try{
         const res= await fetchAllPostPromisedData(
            endpoint?.sdChargePayment?.create,
            reqBody,
            'post'
         );
         if(res?.code===200){
            fetchList(0)
         }
        }
        catch(error){
            console.log(error);
        }
        finally{
            setLoader(false)
        }
    }
    const updateHandler = async(rowData) =>{
        setLoader(true);
        const reqBody=rowData
        try{
        const res = await fetchAllPostPromisedData(
            `${endpoint.sdChargePayment.update}/${rowData?.id}`,
            reqBody,
            'put'
          );
          if(res?.code===200){
            successToast(res?.message);
            fetchList(currentPage);
          }
          else{
            failureToast(res?.message);
          }
        }
        catch(error){
            console.log(error)
        }
        finally{
            setLoader(false)
        }


    }

    useEffect(()=>{
      getDropdown()
        fetchList()
    },[])
    const nextButtonHandler = (newPage) => {
        setCurrentPage(newPage);
        fetchList(newPage);
      };
      const toggleStatusHandler = async rowData => {
        setLoader(true);
        const reqBody = {
          ...getCreateReqBody(rowData),
          status: rowData?.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
        };
        const res = await fetchAllPostPromisedData(
          `${endpoint.sdChargePayment.update}/${rowData?.id}`,
          reqBody,
          'put'
        );
        if (res?.code === 200) {
          successToast(res?.message);
          fetchList(currentPage);
        } else {
          failureToast(res?.message);
        }
        setLoader(false);
      };

    return(
        <div className={`remove-container-top-space`}>
      {loader && (
        <ALoader position="fixed" />
      )}
      <div className={`${styles['page-container']}`}>
        <div className="d-flex justify-content-between align-items-start">
            <div className={`${styles['header-bar']}`}>
        <BackIcon onClick={() => history.goBack()} />
        <div className="ml-3 heading-4 color-dark">Security deposit configuration</div>
</div>
         {checkPermission(userPermissions, 'C') &&<AButton
            // updatedStyle={{ fontWeight: 600 }}
            onClick={() =>  listRef?.current?.addNewRow()}
            variant="primary_filled"
          >
            Add configuration
          </AButton>
}

        </div>
        <ListComponent
          ref={listRef}
          rows={rows}
          loader={loader}
          dropdown={dropdownData}
          createHandler={createHandler}
          updateHandler={updateHandler}
          toggleStatusHandler={toggleStatusHandler}
          //deleteHandler={deleteHandler}
          nextButtonHandler={nextButtonHandler}
          currentPage={currentPage}
          rowCount={rowCount}
          isNew={isNew}
          setIsNew={setIsNew}
        />
        </div>
        </div>

    )

}
export default SecurityDepositSequencing;