import React from "react";

import { Card, CardBody, Col, Row } from "reactstrap";
import AButton from "../../common/form-fields-mui/AButton";
import { classDropdown, programActionDropdown } from "../constant/common";
import { allocationStatusDropdown } from './../constant/common';
import AAutoComplete from '../../common/form-fields-mui/AAutoComplete'

export const modalStyle = {
  position: "absolute" /* Stay in place */,
  zIndex: "75" /* Sit on top */,
  top: "2rem",
  right: "5rem",
  width: "60%" /* Full width */,
  height: "100%" /* Full height */,
};



export const RightSideFilterForm = ({
  formModal,
  inputHandler,
  applyFilterHandler,
  removeFilterHandler,
  isShowRemoveFilter,
  courseIdDropdown,
}) => {
  const columnSize = 12;

  return (
    <div >
      <Card style={{ width: "300px", marginBottom: "0px" }}>
        <CardBody style={{ paddingTop: ".5rem", paddingBottom: ".5rem" }}>
          <h4 style={{ color: "black" }}> Filter </h4>
          <Row>
            <Col md={columnSize}>
              <AAutoComplete
                label="Course ID"
                currentValue={formModal?.courseId}
                items={courseIdDropdown}
                handleChange={(value) => inputHandler(value, 'courseId')}
                isShowCount={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={columnSize}>
              <AAutoComplete 
                label="Class"
                currentValue={formModal?.classes}
                items={classDropdown}
                handleChange={(value) => inputHandler(value, 'classes')}
                isShowCount={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={columnSize}>
              <AAutoComplete 
                label="Program action"
                currentValue={formModal?.programAction}
                items={programActionDropdown}
                handleChange={(value) => inputHandler(value, 'programAction')}
                isShowCount={true}
              />
            </Col>
          </Row>

        

           <Row>
            <Col md={columnSize}>
              
              <AAutoComplete 
                label="Allocation status"
                currentValue={formModal?.allocationStatus}
                items={allocationStatusDropdown}
                handleChange={(value) => inputHandler(value, 'allocationStatus')}
                isMulti={false}
              />
            </Col>
          </Row>

        

          <Row>
            <Col md="6">
              {isShowRemoveFilter && (
                <AButton
                  onClick={removeFilterHandler}
                  updatedStyle={{
                    border: "1px solid #CC5F5F",
                    borderRadius: "8px",
                    color: "#CC5F5F",
                    margin: ".3rem"
                  }}
                >
                  Remove Filter
                </AButton>
              )}
            </Col>
            <Col md="6" style={{ display: "flex", justifyContent: "flex-end" }}>
              <AButton
                onClick={applyFilterHandler}
                updatedStyle={{
                  background: "#00B0F5",
                  border: "1px solid #00B0F5",
                  borderRadius: "8px",
                  color: "#fff",
                  margin: ".4rem"

                }}
              >
                Apply Filter
              </AButton>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
