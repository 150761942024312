import React, { useState, useEffect } from 'react';
import { SUBSUBCATEGORYTHROW } from '../../../common/commonComponents/masterRow/masterRow';
import {
	Container,
	Row,
	Col,
	Button,
	Card,
	CardBody,
	CardHeader,
	Table,
	Input,
	Label,
} from 'reactstrap';
import Select2 from 'react-select2-wrapper';
import { GetPagination } from '../../../common/pagination';
import CustomLoader from '../../../common/commonComponents/Loader/loader';
import moment from 'moment';
import {
	MasterHeaderAuto,
	Rows,
} from '../../../common/commonComponents/masterHeader/masterHeader';
import {
	fetchAllData,
	fetchDetails,
	getDispValForReactSelectByOneID,
} from '../../../common/utils/methods/commonMethods/utilityMethod';
import {
	getAPI,
	masterServiceBaseUrl,
	successToast,
	failureToast,
	putpost,
	putpost2,
	deleteAPI,
} from 'services/http';
import CustomButton from 'components/CustomButton';

var _ = require('lodash');

const RefundAccEntryTable = (props) => {
	const {
		getPaymentModeData,
		action,
		paymentModeID,
		accountTypeList,
		accountEntryTypeList,
		gLAccountList,
		invoiceStatusList,
		refundAccEntry,
		setRefundAccEntry,
		userPermissions
	} = props;
	const [isSaveVisible, setIsSaveVisible] = useState(false);
	const [apiLoader, setApiLoader] = useState(false);
	const [state, setState] = useState([]);
	const [dataChange, setDataChange] = useState(false);
	const [tableDataKey, settableDataKey] = useState('tableDataKey');
	const [isEdit, setIsEdit] = useState(false);
	const [pagination, setPagination] = useState({
		currentPage: 1,
		nextPage: null,
		previousPage: null,
		totalPage: null,
	});
	const pageSize = 10;

	useEffect(() => {
		if (paymentModeID) {
			fetchClasses(
				masterServiceBaseUrl + `/refundAccountEntry/PaymentModeMasterId/${paymentModeID}`
			);
		}
	}, [paymentModeID]);

	const fetchClasses = async (url) => {
		setApiLoader(true);
		getAPI(
			url,
			(data) => {
				setState(data['data']);
				let n = pagination;
				if (data['data'].length > pageSize) {
					n['nextPage'] = n.currentPage + 1;
				}
				if (n.currentPage > 1) {
					n.previousPage = n.currentPage - 1;
				}
				n['totalPage'] = Math.floor(data['data'].length / pageSize);
				if (data['data'].length % pageSize != 0) {
					n['totalPage'] += 1;
				}
				setPagination(n);
				setApiLoader(false);
				setRefundAccEntry(
					data['data'].slice(
						pagination.currentPage * pageSize - pageSize,
						pagination.currentPage * pageSize
					) || []
				);
			},
			(data) => {
				failureToast(data['message']);
				setApiLoader(false);
			}
		);
	};

	const nextPage = () => {
		setRefundAccEntry(
			state.slice(
				(pagination.currentPage + 1) * pageSize - pageSize,
				(pagination.currentPage + 1) * pageSize
			)
		);
		let n = pagination;
		n['currentPage'] = n['currentPage'] + 1;
		n['previousPage'] = n.currentPage;
		setPagination(n);
	};
	const firstPage = () => {
		setRefundAccEntry(state.slice(1 * pageSize - pageSize, 1 * pageSize));
		let n = pagination;
		n['currentPage'] = 1;
		n['previousPage'] = null;
		setPagination(n);
	};
	const lastPage = () => {
		setRefundAccEntry(
			state.slice(
				pagination.totalPage * pageSize - pageSize,
				pagination.totalPage * pageSize
			)
		);
		let n = pagination;
		n['currentPage'] = n.totalPage;
		n['previousPage'] = n.totalPage - 1;
		setPagination(n);
	};
	const previousPage = () => {
		if (pagination.currentPage != 1) {
			setRefundAccEntry(
				state.slice(
					(pagination.currentPage - 1) * pageSize - pageSize,
					(pagination.currentPage - 1) * pageSize
				)
			);
			let n = pagination;
			n['currentPage'] = n['currentPage'] - 1;
			if (n['currentPage'] == 1) {
				n['previousPage'] = null;
			} else {
				n['previousPage'] = n.currentPage;
			}
			setPagination(n);
		}
	};

	const headersList = [
		{ name: 'SF GL Account type' },
		{ name: 'Debit/Credit' },
		{ name: 'GL Account & Description' },
		{ name: 'Invoice Status' },
		{ name: 'Updated On' },
		{ name: 'Status' },
		{ name: 'Action' },
	];

	const addNewClass = () => {
		setRefundAccEntry([
			{
				editMode: true,
				__status: 'new',
				paymentModeId: paymentModeID,
				sfGlAccountId: null,
				debitCreditId: null,
				glAccountId: null,
				invoiceStatusId: null,
				effectiveDate: null,
				status: 'ACTIVE',
			},
			...refundAccEntry,
		]);
	};

	const saveAll = (cb) => {
		try {
			var _tempArray = refundAccEntry;
			var isValid = true;
			_tempArray.forEach((el, index) => {
				if (el['editMode']) {
					if (el['paymentModeId'] === null) {
						isValid = false;
						failureToast('Paymode Mode ID not found');
						return;
					} else if (el['sfGlAccountId'] === null) {
						isValid = false;
						failureToast('Please Select SF GL Account Type');
						return;
					} else if (el['debitCreditId'] === null) {
						isValid = false;
						failureToast('Please Select Debit / Credit');
						return;
					} else if (el['glAccountId'] === null) {
						isValid = false;
						failureToast('Please Select GL Account');
						return;
					} else if (el['invoiceStatusId'] === null) {
						isValid = false;
						failureToast('Please Select Invoice Status');
						return;
					}

					if (!isValid) {
						return;
					}

					if (_tempArray[index]['__status'] == 'new') {
						setApiLoader(true);
						putpost(
							masterServiceBaseUrl + '/refundAccountEntry',
							(data) => {
								successToast(data['message']);
								settableDataKey(new Date().getMilliseconds());
								getPaymentModeData();
								fetchClasses(
									masterServiceBaseUrl +
									`/refundAccountEntry/PaymentModeMasterId/${paymentModeID}`
								);
								setIsSaveVisible(false);
								setApiLoader(false);
							},
							(data) => {
								failureToast(data['message']);
								setApiLoader(false);
							},
							_tempArray[index],
							'post'
						);
					} else {
						setApiLoader(true);
						putpost(
							masterServiceBaseUrl + '/refundAccountEntry/' + _tempArray[index].id,
							(data) => {
								successToast(data['message']);
								getPaymentModeData();
								fetchClasses(
									masterServiceBaseUrl +
									`/refundAccountEntry/PaymentModeMasterId/${paymentModeID}`
								);
								setIsSaveVisible(false);
								delete _tempArray[index]['editMode'];
								setRefundAccEntry(_tempArray);
								settableDataKey(new Date().getMilliseconds());
								setApiLoader(false);
							},
							(data) => {
								failureToast(data['message']);
								setApiLoader(false);
							},
							_tempArray[index],
							'put'
						);
					}
				}
			});
		} catch (e) {
			console.log('Error', e);
		}
	};

	const newRow = () => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			setIsEdit(false);
			let cb = () => {
				addNewClass();
				setIsSaveVisible(true);
			};
			var _tempArray = refundAccEntry;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			setRefundAccEntry(_tempArray);
			setIsSaveVisible(false);
			cb();
		}
	};

	const updateRecord = (index, value, property) => {
		try {
			let _tempArray = refundAccEntry;
			_tempArray[index][property] = value;
			setRefundAccEntry(_tempArray);
		} catch (e) {
			console.log('Error', e);
		}
	};

	const editRecord = (index) => {
		try {
			if (isSaveVisible) {
				failureToast('Please Delete or Save current changes');
			} else {
				setIsEdit(true);
				let cb = () => {
					var _tempArray = refundAccEntry;
					_tempArray[index]['editMode'] = true;
					setRefundAccEntry(_tempArray);
					setIsSaveVisible(true);
				};
				var _tempArray = refundAccEntry;
				_tempArray = _.sortBy(_tempArray, [
					function (o) {
						return o.value;
					},
				]);
				cb();
			}
		} catch (e) {
			console.log('Error : ', e);
		}
	};

	const deleteRecord = (index) => {
		try {
			if (refundAccEntry[index]['__status'] == 'new') {
				let n = refundAccEntry;
				n.splice(index, 1);
				setRefundAccEntry(n);
				setIsSaveVisible(false);
				settableDataKey(new Date().getMilliseconds());
			} else {
				if (
					window.confirm(
						'Are you sure you want to delete this Refund details details ?'
					)
				) {
					putpost2(
						masterServiceBaseUrl + '/refundAccountEntry/' + refundAccEntry[index].id,
						(data) => {
							successToast(data['message']);
							getPaymentModeData();
							if (refundAccEntry.length == 1) {
								previousPage();
								fetchClasses(
									masterServiceBaseUrl +
									`/refundAccountEntry/PaymentModeMasterId/${paymentModeID}`
								);
							} else {
								var _tempArray = refundAccEntry;
								if (_tempArray[index]['editMode']) {
									setIsSaveVisible(false);
								}
								_tempArray.splice(index, 1);
								setRefundAccEntry(_tempArray);
							}
							settableDataKey(new Date().getMilliseconds());
						},
						(data) => {
							failureToast(data['message']);
						},
						'Delete'
					);
				}
			}
		} catch (e) {
			console.log('Error', e);
		}
	};

	const closeRecord = (index) => {
		try {
			let n = refundAccEntry;
			if (n[index]['__status'] == 'new') {
				n.splice(index, 1);
			} else {
				// n[index]['editMode']=false
				fetchClasses(
					masterServiceBaseUrl + `/refundAccountEntry/PaymentModeMasterId/${paymentModeID}`
				);
			}
			setRefundAccEntry(n);
			setIsSaveVisible(false);
		} catch (e) {
			console.log('Error', e);
		}
	};

	const TDROW = (props) => {
		const {
			item,
			editRecord,
			index,
			deleteRecord,
			action,
			isDisabled,
			accountTypeList,
			accountEntryTypeList,
			gLAccountList,
			invoiceStatusList,
		} = props;
		return (
			<tr key={index}>
				<td className="text-center ">
					{getDispValForReactSelectByOneID(
						item.sfGlAccountId,
						'DispValue',
						accountTypeList
					)}
				</td>
				<td className="text-center ">
					{getDispValForReactSelectByOneID(
						item.debitCreditId,
						'DispValue',
						accountEntryTypeList
					)}
				</td>
				<td className="text-center ">
					{getDispValForReactSelectByOneID(
						item.glAccountId,
						'DispValue',
						gLAccountList
					)}
				</td>
				<td className="text-center ">
					{getDispValForReactSelectByOneID(
						item.invoiceStatusId,
						'DispValue',
						invoiceStatusList
					)}
				</td>
				<td className="text-center ">
					{moment(item.effectiveDate).format('DD-MM-YYYY')}
				</td>
				<td className="text-center ">
					{item.status === 'ACTIVE' ? (
						<button
							disabled={true}
							type="button"
							className="btn btn-sm btn-success"
						>
							ACTIVE
						</button>
					) : (
						<button
							type="button"
							disabled={true}
							className="btn btn-sm btn-warning"
						>
							INACTIVE
						</button>
					)}
				</td>
				{!isDisabled ? (
					<td className="text-center">
						{/* <Button size="sm" color="info" onClick={() => editRecord(index)}>
							<i className="fas fa-edit" />
						</Button> */}
						<CustomButton
							className={'mx-1'}
							permissionType={'U'}
							icon={true}
							forTable={true}
							permissionSet={userPermissions}
							onClick={() => { editRecord(index)}}
						/>
						{/* <Button
							size="sm"
							color="danger"
							onClick={() => deleteRecord(index)}
						>
							<i className="fas fa-trash" />
						</Button> */}
						<CustomButton
							className={'mx-1'}
							permissionType={'D'}
							icon={true}
							forTable={true}
							permissionSet={userPermissions}
							onClick={() => { deleteRecord(index)}}
						/>
					</td>
				) : null}
			</tr>
		);
	};

	return (
		<Container className="mt-3" fluid>
			<Row>
				<Col className="mb-3">
					{!(action === 'view') ? (
						// <Button
						// 	disabled={isSaveVisible}
						// 	color="info"
						// 	size="sm"
						// 	type="button"
						// 	className={
						// 		'floatRight mx-1' + (isSaveVisible ? ' btn-dark' : null)
						// 	}
						// 	onClick={() => {
						// 		newRow();
						// 	}}
						// >
						// 	Add GL Account
						// </Button>
						<CustomButton
							disabled={isSaveVisible}
							className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
							content={'Add GL Account'}
							permissionType={'C'}
							icon={true}
							permissionSet={userPermissions}
							onClick={() => newRow()}
						/>
					) : null}
				</Col>
			</Row>
			{apiLoader ? (
				<div className="mx-auto text-center" style={{ height: '100px' }}>
					<CustomLoader
						apiLoader={apiLoader}
						loaderHeight={'200px'}
						loaderWidth={'100% '}
					/>
				</div>
			) : (
				<div id="questionTable" className="table-responsive">
					<Table className="align-items-center table-flush">
						{refundAccEntry.length > 0 ? (
							<MasterHeaderAuto action={action} headerList={headersList} />
						) : (
							''
						)}
						<tbody className="list" key={tableDataKey}>
							{refundAccEntry
								? refundAccEntry.map((item, index) =>
									!item['editMode'] ? (
										<TDROW
											key={index + '_class'}
											type={'refundAccEntryMapping'}
											action={action}
											deleteRecord={deleteRecord}
											accountTypeList={accountTypeList}
											accountEntryTypeList={accountEntryTypeList}
											gLAccountList={gLAccountList}
											invoiceStatusList={invoiceStatusList}
											item={item}
											editRecord={editRecord}
											index={index}
											closeRecord={closeRecord}
										/>
									) : (
										<tr key={index + '-class'}>
											<td className="text-center p-2 mx-1">
												<Select2
													data={accountTypeList}
													className="tdSelect"
													options={{ placeholder: 'Select...' }}
													value={item.sfGlAccountId}
													onChange={(e) => {
														let n = e.target.value;
														updateRecord(index, n, 'sfGlAccountId');
													}}
												/>
											</td>
											<td className="text-center p-2 mx-1">
												<Select2
													data={accountEntryTypeList}
													className="tdSelect"
													options={{ placeholder: 'Select...' }}
													value={item.debitCreditId}
													onChange={(e) => {
														let n = e.target.value;
														updateRecord(index, n, 'debitCreditId');
													}}
												/>
											</td>
											<td className="text-center p-2 mx-1">
												<Select2
													data={gLAccountList}
													className="tdSelect"
													// disabled={isEdit}
													options={{ placeholder: 'Select...' }}
													value={item.glAccountId}
													onChange={(e) => {
														let n = e.target.value;
														updateRecord(index, n, 'glAccountId');
													}}
												/>
											</td>
											<td className="text-center p-2 mx-1">
												<Select2
													data={invoiceStatusList}
													className="tdSelect"
													options={{ placeholder: 'Select...' }}
													value={item.invoiceStatusId}
													onChange={(e) => {
														let n = e.target.value;
														updateRecord(index, n, 'invoiceStatusId');
													}}
												/>
											</td>
											<td className="text-center p-2 mx-1">
												<Input
													disabled={true}
													defaultValue={
														item['__status'] == 'new'
															? null
															: moment(item['effectiveDate']).format(
																'DD-MM-YYYY'
															)
													}
													placeholder="Example:01-01-2020"
													className="tdInput"
												/>
											</td>
											<td className="text-center p-2 mx-1">
												<Label className="custom-toggle mx-auto ml-2">
													<Input
														checked={item.status == 'ACTIVE' ? true : false}
														type="checkbox"
														onChange={(e) => {
															settableDataKey(new Date().getMilliseconds());
															let n = 'ACTIVE';
															if (!e.target.checked) {
																n = 'INACTIVE';
															}
															updateRecord(index, n, 'status');
															setDataChange(!dataChange);
														}}
													/>
													<span
														style={{ width: '72px' }}
														className="custom-toggle-slider rounded-circle activeToggle"
														data-label-off="Inactive"
														data-label-on="Active"
													/>
												</Label>
											</td>
											<td className="text-center p-2 mx-1">
												{isSaveVisible ? (
													<>
														{/* <Button
															size="sm"
															color="info"
															onClick={() => saveAll()}
														>
															Save
														</Button> */}
														<CustomButton
															className={'mx-1'}
															permissionType={'C,U'}
															content={"Save"}
															forTable={true}
															permissionSet={userPermissions}
															onClick={() => {  saveAll()}}
														/>
														{/* <Button
															size="sm"
															color="danger"
															onClick={() => closeRecord(index)}
														>
															<i className="fas fa-times" />
														</Button> */}
														<CustomButton
															className={'mx-1'}
															permissionType={'cancel'}
															icon={true}
															forTable={true}
															permissionSet={userPermissions}
															onClick={() => { closeRecord(index) }}
														/>
													</>
												) : (
													// <Button
													// 	color="info"
													// 	size="sm"
													// 	type="button"
													// 	onClick={() => {
													// 		deleteRecord(index);
													// 	}}
													// >
													// 	<i className="fas fa-trash" />
													// </Button>
													<CustomButton
														className={'mx-1'}
														permissionType={'D'}
														icon={true}
														forTable={true}
														permissionSet={userPermissions}
														onClick={() => { deleteRecord(index) }}
													/>
												)}
											</td>
										</tr>
									)
								)
								: null}
						</tbody>
					</Table>

					{pagination.totalPage > 1 ? (
						<GetPagination
							fetchClasses={fetchClasses}
							pagination={pagination}
							previousPage={previousPage}
							firstPage={firstPage}
							lastPage={lastPage}
							nextPage={nextPage}
							setpagination={setPagination}
						/>
					) : null}
				</div>
			)}
		</Container>
	);
};

export default RefundAccEntryTable;
