import React from "react";
import { Row, Col, FormGroup } from "reactstrap";
import Select from "react-select";
import CustomDatePicker from '../../../../common/commonComponents/CustomDatePicker/index';
import moment from "moment";

const style = {
  height: "38px",
};

const requireMarker = {
  color: "red",
};

const getRequiredMark = () => {
  return <span style={requireMarker}>*</span>;
};

const validate = (type, value) => {
  const textValReg = /^[a-zA-Z.]+(\s{0,1}[a-zA-Z.])*$/;///^[A-Za-z]|[A-Za-z][A-Za-z\s]*[A-Za-z]*$/;
  const fatherReg = /^[^-\s][a-zA-Z.\s]*$/;
  const mobileReg = /^[0-9\b]+$/;
  const addressReg =  /^([a-zA-Z0-9,./\\()#_]+\s)*[a-zA-Z0-9,./\\()#_]*$/g;//^[^-\s][a-zA-Z0-9,./\\()#_\s-]*$/;//^[^\s]+[A-Za-z0-9,./\\()#-]*$/;
  const gst =/[a-zA-Z\.// ]+$/;
  const gstin = /^[a-zA-Z0-9]+$/i;//[A-Za-z0-9]*$/;
  const sname = /^([a-zA-Z0-9./]+\s)*[a-zA-Z0-9./]*$/g;//^[a-zA-Z0-9./ ]+$/i;
  const bank = /[A-Z0-9]+$/;
  const email = /^[^\s<>&=+'\-,]+[A-Za-z0-9./\\@#]*$/;//^[^\s]+[-a-zA-Z\s]+([-a-zA-Z]+)*$/;
  const companyName = /^[a-zA-Z]+(\s+[a-zA-Z]+)*$/;
  const accountHolderName = /^[a-zA-Z./]+$/;

  if(type === "accountHolderName"){
    if (value === "" || accountHolderName.test(value)) {
      return true;
    }
  }

  if (type === "text") {
    if (value === "" || textValReg.test(value)) {
      return true;
    }
  }

  if(type=== "fatherReg") {
    if (value === "" || fatherReg.test(value)) {
      return true;
    }
  }

  if (type === "sname") {
    if (value === "" || sname.test(value)) {
      return true;
    }
  }

  if(type === "number"){
    if (value === "" || mobileReg.test(value)) {
      return true;
    }
  }

  if(type === "address"){
    if (value === "" || addressReg.test(value)) {
      return true;
    }
  }

  if(type === "gst"){
    if (value === "" || gst.test(value)) {
      return true;
    }
  }

  if(type === "gstin"){
    if (value === "" || gstin.test(value)) {
      return true;
    }
  }

  if(type === "bank"){
    if (value === "" || bank.test(value)) {
      return true;
    }
  }

  if(type === "email"){
    if (value === "" || email.test(value)) {
      return true;
    }
  }
};

export const StudentDetailsForm = (props) => {
  const {
    studentFirstName,
    studentLastName,
    dob,
    gender,
    bloodGroup,
    schoolStudingIn,
    category,
    studentIdStatus,
    genderData = [],
    bloodGroupData = [],
    schoolStudingInData = [],
    categoryData = [],
    studentIdStatusData = [],
    imagePath,
    fileHandler = () => {},
    onchange = () => {},
    setValues= () => {},
    onDropdownChange = () => {},
    values
  } = props;

  return (
    <Row tabindex='0'>
      <Col md={4}>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">Student Id</label>
              <input
                disabled
                name={"studentId"}
                className="form-control"
                type="text"
                style={style}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">
                Student First Name {getRequiredMark()}
              </label>
              <input
                tabindex='2'
                value={studentFirstName}
                name={"studentFirstName"}
                className="form-control"
                placeholder="Enter Student First Name"
                type="text"
                maxLength="40"
                onChange={(e) => {
                  if (validate("text", e.target.value)) {
                    onchange(e);
                  }
                }}
                style={style}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">
                Date of Birth {getRequiredMark()}
              </label>
              <CustomDatePicker 
                tabIndex='5'
                value={dob? moment(
                dob
                ).toDate():''}
                index={0}
                handler={(index,date,keyName) => {
                  setValues({ ...values, [keyName]: date })
                }}
                keyName='dob'
                minDate={'1990-01-01'}
                maxDate={'2015-12-31'}
                rangeFrom={1990}
                rangeTo={2015}
                placeholder="Example:01-01-2020"
              />
              {/* <input
                tabIndex='5'
                value={dob}
                name={"dob"}
                min={"1990-01-01"}
                max={moment(new Date).format('YYYY-MM-DD')}
                // onKeyDown={(e) => e.preventDefault()}
                className="form-control"
                type="date"
                onChange={(e) => onchange(e)}
                style={style}
              /> */}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">Blood Group</label>
              <Select
                value={bloodGroup}
                name={"bloodGroup"}
                id="example3cols2Input"
                options={bloodGroupData}
                onChange={(sData) => onDropdownChange(sData, "bloodGroup")}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">Category</label>
              <Select
                value={category}
                name={"category"}
                id="example3cols2Input"
                options={categoryData}
                onChange={(sData) => onDropdownChange(sData, "category")}
              />
            </FormGroup>
          </Col>
        </Row>
      </Col>
      <Col md={4}>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">
                Student ID Status {getRequiredMark()}
              </label>
              <Select
                tabIndex='1'
                value={studentIdStatus}
                name={"studentIdStatus"}
                id="example3cols2Input"
                tabIndex={1}
                options={studentIdStatusData}
                onChange={(sData) => onDropdownChange(sData, "studentIdStatus")}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">
                Student Last Name {getRequiredMark()}
              </label>
              <input
                tabindex='3'
                value={studentLastName}
                name={"studentLastName"}
                className="form-control"
                type="text"
                maxLength={40}
                placeholder="Enter Student Last Name"
                onChange={(e) => {
                  if (validate("text", e.target.value)) {
                    onchange(e);
                  }
                }}
                style={style}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">Gender</label>
              <Select
                value={gender}
                name={"gender"}
                id="example3cols2Input"
                options={genderData}
                onChange={(sData) => onDropdownChange(sData, "gender")}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">School Studying In</label>
              <Select
                value={schoolStudingIn}
                name={"schoolStudingIn"}
                id="example3cols2Input"
                options={schoolStudingInData}
                onChange={(sData) => onDropdownChange(sData, "schoolStudingIn")}
              />
            </FormGroup>
          </Col>
        </Row>
      </Col>
      <Col md={4} className={"d-flex justify-content-center"}>
        <FormGroup>
          {/* <input
            type="file"
            style={{
              height: "200px",
              width: "200px",
              position: "absolute",
              opacity: "0",
            }}
            onChange={(e) => fileHandler(e)}
          /> */}
          <div
            style={{
              height: "200px",
              width: "200px",
              border: "1px solid #d2d2d2",
            }}
            className={"d-flex justify-content-center align-items-center"}
          >
            {imagePath ? (
              <img src={imagePath} height="200px" width="200px" />
            ) : (
              <i className="fas fa-user fa-10x" />
            )}
          </div>
        </FormGroup>
      </Col>
    </Row>
  );
};

export const ContactDetailsForm = (props) => {
  const {
    primaryMobileNumber,
    studentMobileNumber,
    whatsAppNumber,
    country,
    state,
    city,
    pincode,
    studentEmailId,
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4,
    cityData = [],
    pincodeData = [],
    stateData = [],
    countryData = [],
    onchange = () => {},
    fetchPincodeById =() =>{},
    onDropdownChange = () => {},
  } = props;
  return (
    <Row style={{ fontSize: "15px" }} tabindex='5'>
      <Col md={6}>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">
                Primary Mobile Number {getRequiredMark()}
              </label>
              <input
                tabIndex='6'
                value={primaryMobileNumber}
                name={"primaryMobileNumber"}
                className="form-control"
                placeholder="Enter Student Primary Number"
                type="text"
                maxLength="10"
                onFocus={primaryMobileNumber===whatsAppNumber ?()=>onDropdownChange('','whatsAppNumber'):null }
                onBlur={whatsAppNumber==='' ? ()=>onDropdownChange(primaryMobileNumber,'whatsAppNumber'): null}
                onChange={(e) => {
                  if (validate("number", e.target.value)) {
                    onchange(e);
                  }
                }}
                style={style}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">
                Student Mobile Number 
              </label>
              <input
                value={studentMobileNumber}
                name={"studentMobileNumber"}
                className="form-control"
                placeholder="Enter Student First Name"
                type="text"
                maxLength="10"
                onChange={(e) => {
                  if (validate("number", e.target.value)) {
                    onchange(e);
                  }
                }}
                style={style}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">Address Line 1</label>
              <input
                value={addressLine1}
                name="addressLine1"
                className="form-control"
                placeholder="Enter Address "
                type="text"
                onChange={(e) => {
                  if (validate("address", e.target.value)) {
                    onchange(e);
                  }
                }}
                style={style}
              />
            </FormGroup>
          </Col>
        </Row>
        {/* <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">Address Line 3</label>
              <input
                value={addressLine3}
                name="addressLine3"
                className="form-control"
                placeholder="Enter Address "
                type="text"
                onChange={(e) => {
                  if (validate("address", e.target.value)) {
                    onchange(e);
                  }
                }}
                style={style}
              />
            </FormGroup>
          </Col>
        </Row> */}

        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">Pincode {getRequiredMark()}</label>
              <Select
                value={pincode}
                name={"pincode"}
                tabIndex='8'
                id="example3cols2Input"
                options={pincodeData}
                onChange={(sdata)=>fetchPincodeById(sdata)}
                // onChange={(sData) => onDropdownChange(sData, "pincode")}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">State</label>
              <Select
                value={state}
                name={"state"}
                id="example3cols2Input"
                options={stateData}
                isDisabled={true}
                // onChange={(sData) => onDropdownChange(sData, "state")}
              />
            </FormGroup>
          </Col>
        </Row>
      </Col>
      <Col md={6}>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">
                Whatsapp Number 
              </label>
              <input
                value={whatsAppNumber}
                name={"whatsAppNumber"}
                className="form-control"
                placeholder="Enter Whatsapp Number"
                type="text"
                onBlur={whatsAppNumber==='' ? ()=>onDropdownChange(primaryMobileNumber,'whatsAppNumber'): null}
                onChange={(e) => {
                  if (validate("number", e.target.value)) {
                    onchange(e);
                  }
                }}
                style={style}
                maxLength="10"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">
                Student Email ID {getRequiredMark()}
              </label>
              <input
                value={studentEmailId}
                name={"studentEmailId"}
                tabindex='7'
                className="form-control"
                placeholder="Enter Student's Email Id"
                type="text"
                onChange={(e) => {
                  if (validate("email", e.target.value)) {
                    onchange(e);
                  }
                }}
                style={style}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">Address Line 2</label>
              <input
                value={addressLine2}
                name="addressLine2"
                className="form-control"
                placeholder="Enter Address "
                type="text"
                onChange={(e) => {
                  if (validate("address", e.target.value)) {
                    onchange(e);
                  }
                }}
                style={style}
              />
            </FormGroup>
          </Col>
        </Row>
        {/* <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">Address Line 4</label>
              <input
                value={addressLine4}
                name="addressLine4"
                className="form-control"
                placeholder="Enter Address "
                type="text"
                onChange={(e) => {
                  if (validate("address", e.target.value)) {
                    onchange(e);
                  }
                }}
                style={style}
              />
            </FormGroup>
          </Col>
        </Row> */}
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">City</label>
              <Select
                value={city}
                name={"city"}
                isDisabled={true}
                id="example3cols2Input"
                options={cityData}
                // onChange={(sData) => onDropdownChange(sData, "city")}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">Country</label>
              <Select
                value={country}
                isDisabled={true}
                name={"country"}
                id="example3cols2Input"
                options={countryData}
                // onChange={(sData) => onDropdownChange(sData, "country")}
              />
            </FormGroup>
          </Col>
        </Row>
      </Col>
      {/* <Col md={2}/> */}
    </Row>
  );
};

export const OtherDetailsForm = (props) => {
  const {
    fatherName,
    fatherMobileNumber,
    fatherEmailId,
    motherName,
    motherMobileNumber,
    motherEmailId,
    parentOccupation,
    motherOccupation={},
    fatherOccupation={},
    parentsOcc=[],
    onchange=()=>{},
    onDropdownChange=(value,key)=>{}
  } = props;

  return (
    <Row style={{ fontSize: "15px" }} tabindex='9'>
      <Col md={6}>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">Father Name {getRequiredMark()}</label>
              <div className={'d-flex align-items-center'}>
              <span style={{position:'absolute',left:'20px'}}>Mr.</span>
              <input
                tabIndex='10'
                value={fatherName}
                name="fatherName"
                className="form-control"
                placeholder="Enter Father Name"
                type="text"
                maxLength={75}
                onChange={(e) => {
                  if (validate("fatherReg", e.target.value)) {
                    onchange(e);
                  }
                }}
                style={{...style,paddingLeft:'35px'}}
              />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">Father Mobile Number</label>
              <input
                value={fatherMobileNumber}
                name="fatherMobileNumber"
                className="form-control"
                placeholder="Enter Father Mobile Number"
                type="text"
                onChange={(e) => {
                  if (validate("number", e.target.value)) {
                    onchange(e);
                  }
                }}
                style={style}
                maxLength="10"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">Father Email ID</label>
              <input
                value={fatherEmailId}
                name="fatherEmailId"
                className="form-control"
                placeholder="Enter Father Email ID"
                type="text"
                onChange={(e) => {
                  if (validate("email", e.target.value)) {
                    onchange(e);
                  }
                }}
                style={style}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">Father's Occupation</label>
              <Select
                value={fatherOccupation}
                name={"fatherOccupation"}
                id="example3cols2Input"
                placeholder="Select Father's Occupations"
                options={parentsOcc}
                onChange={(sData) => onDropdownChange(sData, "studentFatherOccupationId")}
              />
              {/* <input
                value={parentOccupation}
                name="parentOccupation"
                className="form-control"
                placeholder="Enter Parent Occupation"
                type="text"
                onChange={(e) => onchange(e)}
                style={style}
              /> */}
            </FormGroup>
          </Col>
        </Row>
      </Col>
      <Col md={6}>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">Mother Name {getRequiredMark()}</label>
              <input
                value={motherName}
                name="motherName"
                className="form-control"
                placeholder="Enter Mother  Name"
                type="text"
                tabIndex='11'
                onChange={(e) => {
                  if (validate("fatherReg", e.target.value)) {
                    onchange(e);
                  }
                }}
                maxLength={75}
                style={style}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">Mother Mobile Number</label>
              <input
                value={motherMobileNumber}
                name="motherMobileNumber"
                className="form-control"
                placeholder="Enter Mother Mobile Number"
                type="text"
                onChange={(e) => {
                  if (validate("number", e.target.value)) {
                    onchange(e);
                  }
                }}
                style={style}
                maxLength="10"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">Mother Email ID</label>
              <input
                value={motherEmailId}
                name="motherEmailId"
                className="form-control"
                placeholder="Enter Mother Email ID"
                type="text"
                onChange={(e) => {
                  if (validate("email", e.target.value)) {
                    onchange(e);
                  }
                }}
                style={style}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label className="form-control-label">Mother's Occupation</label>
              <Select
                  value={motherOccupation}
                  name={"motherOccupation"}
                  id="example3cols2Input"
                  placeholder="Select Mother's Occupations"
                  options={parentsOcc}
                  onChange={(sData) => onDropdownChange(sData, "studentMotherOccupationId")}
              />
              {/* <input
                value={parentOccupation}
                name="parentOccupation"
                className="form-control"
                placeholder="Enter Parent Occupation"
                type="text"
                onChange={(e) => onchange(e)}
                style={style}
              /> */}
            </FormGroup>
          </Col>
        </Row>
      </Col>
      {/* <Col md={2}/> */}
    </Row>
  );
};

export const GstDetailsForm = (props) => {
  const {
    gstCategory,
    companyName,
    effectiveDate,
    gstin,
    gstCategoryData = [],
    onchange = () => {},
    onDropdownChange = () => {},
  } = props;

  return (
    <div>
      <Row style={{ fontSize: "15px" }}>
        <Col md={6}>
          <Row>
            <Col md={12}>
              <FormGroup>
                <label className="form-control-label">GST Category</label>
                <Select
                  value={gstCategory}
                  name={"gstCategory"}
                  id="example3cols2Input"
                  options={gstCategoryData}
                  isClearable={true}
                  onChange={(sData) => onDropdownChange(sData, "gstCategory")}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>
                <label className="form-control-label">GST Sponsor Name {gstCategory?getRequiredMark():null}</label>
                <input
                  value={companyName}
                  name="companyName"
                  className="form-control"
                  placeholder="GST Sponsor Name"
                  type="text"
                  maxLength={100}
                  disabled={gstCategory?false: true}
                  onChange={(e) => {
                    if (validate("sname", e.target.value)) {
                      onchange(e);
                    }
                  }}
                  style={style}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <Row>
            <Col md={12}>
              <FormGroup>
                <label className="form-control-label">GST Effective Date {gstCategory?getRequiredMark():null}</label>
                <input
                  value={effectiveDate}
                  name="effectiveDate"
                  className="form-control"
                  type="date"
                  min={"2021-04-01"}
                  max={moment(new  Date()).format('YYYY-MM-DD')}
                  onChange={(e)=>onchange(e)}
                  onKeyDown={(e) => e.preventDefault()}
                  disabled={gstCategory?false: true}
                  style={style}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>
                <label className="form-control-label">GSTIN {gstCategory?getRequiredMark():null}</label>
                <input
                  value={gstin}
                  name="gstin"
                  className="form-control"
                  placeholder="Please Enter GSTIN"
                  type="text"
                  maxLength="15"
                  onChange={(e) => {
                    if (validate("gstin", e.target.value)) {
                      onchange(e);
                    }
                  }}
                  disabled={gstCategory?false: true}
                  style={style}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        {/* <Col md={2}/> */}
      </Row>
    </div>
  );
};

export const BankDetailsForm = (props) => {
  const {
    bankId,
    bankAccountNumber,
    ifscCode,
    accountHolderName,
    bankIdData = [],
    onchange = () => {},
    onDropdownChange = () => {},
  } = props;

  return (
    <div>
      <Row style={{ fontSize: "15px" }}>
        <Col md={6}>
          <Row>
            <Col md={12}>
              <FormGroup>
                <label className="form-control-label">Bank ID</label>
                <Select
                  value={bankId}
                  name={"bankId"}
                  id="example3cols2Input"
                  options={bankIdData}
                  onChange={(sData) => onDropdownChange(sData, "bankId")}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>
                <label className="form-control-label">
                  Bank Account Number {bankId?getRequiredMark():null}
                </label>
                <input
                  value={bankAccountNumber}
                  name="bankAccountNumber"
                  className="form-control"
                  placeholder="Enter Bank Account Number"
                  type="text"
                  disabled={bankId?false: true}
                  onChange={(e) => {
                    if (validate("bank", e.target.value)) {
                      onchange(e);
                    }
                  }}
                  style={style}
                  maxLength="16"
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <Row>
            <Col md={12}>
              <FormGroup>
                <label className="form-control-label">Bank IFSC code {bankId?getRequiredMark():null}</label>
                <input
                  value={ifscCode}
                  name="ifscCode"
                  placeholder="Enter Bank IFSC Code"
                  className="form-control"
                  type="text"
                  maxLength="11"
                  disabled={bankId?false: true}
                  onChange={(e) => {
                    if (validate("gstin", e.target.value)) {
                      onchange(e);
                    }
                  }}
                  style={style}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>
                <label className="form-control-label">
                  Account Holder Name {bankId?getRequiredMark():null}
                </label>
                <input
                  value={accountHolderName}
                  name="accountHolderName"
                  className="form-control"
                  placeholder="Enter Account Holder Name"
                  type="text"
                  maxLength="100"
                  disabled={bankId?false: true}
                  onChange={(e) => {
                    if (validate("accountHolderName", e.target.value)) {
                      onchange(e);
                    }
                  }}
                  style={style}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        {/* <Col md={2}/> */}
      </Row>
    </div>
  );
};


export const LogDetailsForm = (props) => {
  const {
    updatedOn,
    updatedBy,
    createdBy,
    createdOn,
  } = props;

  return (
    <div>
      <Row style={{ fontSize: "15px" }}>
        <Col md={6}>
          <Row>
            <Col md={12}>
              <FormGroup>
                <label className="form-control-label">Created Date
                </label>
                <input
                  type="date"
                  value={createdOn}
                  name="createdOn"
                  className="form-control"
                  disabled
                  style={style}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>
                <label className="form-control-label">Created By</label>
                <input
                  value={createdBy}
                  name="createdBy"
                  className="form-control"
                  type="text"
                  disabled
                  style={style}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <Row>
            <Col md={12}>
              <FormGroup>
                <label className="form-control-label">Last Updated Date</label>
                <input
                  value={updatedOn}
                  name="updatedOn"
                  className="form-control"
                  type="date"
                  disabled
                  style={style}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>
                <label className="form-control-label">Last Updated By</label>
                <input
                  value={updatedBy}
                  name="updatedBy"
                  className="form-control"
                  disabled
                  style={style}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        {/* <Col md={2}/> */}
      </Row>
    </div>
  );
};
