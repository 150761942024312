import React, { useEffect,useState} from 'react';
import {Container, Card, CardBody, CardHeader, Button,Row ,Col} from 'reactstrap';
import { Accordion } from '../../../common/commonComponents/Accordion/accordion';
import TaxRefund from './taxRefunds';
import TaxReceipts from './taxReceipts';
import CommissionFranchisesOnly from './commissionFranchisesOnly';
import { masterServiceBaseUrl, getAPI, failureToast } from "services/http";
import { useParams } from 'react-router-dom';
import './taxDetails.scss';


const AddTaxDetails = ( props ) => {
    
    const { businessUnit,
        franchisesSetupId,
        nextStep = () => { },
        franchiseDetails,
        previousStep,
        groupCode,
        companyCode,
        taxRefundDetails,
        taxReceiptsDetails,
        taxCommissionDetails,
        action,
        appendAllStepData=()=>{},
        updateAllStepData=()=>{},
        data,
        formId,
        isPrevious,
        isNew
    } = props;
    const { id } = useParams();
    const [ taxCodeId, setTaxCodeId ] = useState([]);
    const [ taxCodeType, setTaxCodeType ] = useState( [] );
    const [ academicGroup, setAcademicGroup ] = useState( [] );    
    const [ taxRefund, setTaxRefund ] = useState( [] );
    const [ taxReceipts, setTaxReceipts ] = useState( [] );    
    const [ taxFranchises, setTaxFranchises ] = useState( [] );
    const [ isFranchise, setIsFranchise ] = useState( false );    
    const [ isDisabled, setIsDisabled ] = useState( false );
    

    const setAllData = () => {
        // if(isPrevious){
        //     updateAllStepData({taxreceipt : taxReceipts, taxrefund : taxRefund, commisionsetup : taxFranchises },'fifthForm')            
        // }else{
        //     appendAllStepData({taxreceipt : taxReceipts, taxrefund : taxRefund, commisionsetup : taxFranchises },'fifthForm')
            !isPrevious && isNew && props.saveStepsCompleted(5)
        // }
        nextStep()        
    }

    useEffect( () => {
        try {
            groupCode && companyCode && fetchTaxSetUpDetails( masterServiceBaseUrl + `/taxCodeId/getActiveTaxCodeIdByGroupCodeAndCompanyCode?groupCode=${ groupCode }&companyCode=${ companyCode }`, setTaxCodeId, "taxCodeId" )
            fetchTaxSetUpDetails( masterServiceBaseUrl + "/taxCodeType/getAllActiveTaxCodeType", setTaxCodeType, "taxCodeType" )
            fetchTaxSetUpDetails( masterServiceBaseUrl + "/academicGroup/getAllActiveAcademicGroup", setAcademicGroup, "academicGroup" );
            // //console.log( "franchiseDetails", franchiseDetails )
            // const findIndex = franchiseDetails && franchiseDetails.findIndex( item => item.label === "FRANCHISEE" )
            if (franchisesSetupId ) {
                // console.log('isnide the section for franchise set up')
                setIsFranchise( true );
            }

            // console.log('Action tax details',action)

            if ( action === 'view' || action === 'edit' ) {
                setIsDisabled( true );
                // console.log('Tax Commission data is here',taxCommissionDetails)
                setTaxFranchises( taxCommissionDetails );
                setTaxReceipts( taxReceiptsDetails );
                setTaxRefund( taxRefundDetails ); 
            }

        } catch ( e ) {
            // //console.log("Error",e)
        }
    },[])
    //console.log("this is data :"+data)

     const fetchTaxSetUpDetails = ( url , stateMethod, type) => {
        try {
            getAPI( url, ( data ) => {
                // console.log("Data from the server for drop down",data,type)
                refactorData( data[ 'data' ], stateMethod, type );
            }, ( data ) => { failureToast( data[ 'message' ] ) } )
        } catch ( e ) {
            //console.log( "Error", e )
        }
    }

     const refactorData = ( data, stateMethod, type ) => {
        try {
            switch ( type ) {
                case "taxCodeId":
                    iterateData( data, "id", "taxCodeIdDispValue","taxCodeIdDesc", stateMethod )
                    break;
                case "taxCodeType":
                    iterateData( data, "id", "taxCodeTypeDispValue","taxCodeTypeDesc", stateMethod )
                    break;
                case "academicGroup":
                    iterateData( data, "id", "academicGroupKey","academicGroupDesc", stateMethod )
                    break;
                default:
                    break;
            }
        } catch ( e ) {
            //console.log( "Error", e )
        }
    }

    const iterateData = ( data, id, property,propertyDesc, stateMethod ) => {
        try {
            let temp = [];
            data.map( item => {
                temp.push( {
                    id: item[ id ],
                    text: item[ property ],
                    desc:item[propertyDesc]
                } )
            } )
            stateMethod( temp )
        } catch ( e ) {
            //console.log( "Error", e )
        }
    }

    const getDisplayValue = ( id , type ) => {
        try {
            switch ( type ) {
                case "taxCodeTypeDisplayValue":
                    const typeIndex = findIndexForDisplay( id, taxCodeType );
                    return taxCodeType[ typeIndex ][ 'text' ];
                    break;
                case "taxCodeTypeDesc":
                    const typeDescIndex = findIndexForDisplay( id, taxCodeType );
                    return taxCodeType[ typeDescIndex ][ 'desc' ];
                    break;
                case "taxCodeIdDisplayValue":
                    const idIndex = findIndexForDisplay( id, taxCodeId );
                    //console.log( 'Id for the checking ', id, type, taxCodeId );
                    return  taxCodeId[ idIndex ][ 'text' ];
                    break;
                case "academicGroupKeyIdDesc":
                    const idIndexDesc = findIndexForDisplay( id, academicGroup );
                    return academicGroup[ idIndexDesc ][ 'desc' ];
                    break;
                case "academicGroupKeyIdDisp":
                    const idIndexDisp = findIndexForDisplay( id, academicGroup );
                    return academicGroup[ idIndexDisp ][ 'text' ];
                    break;
                default:
                    break;
            }
        } catch ( e ) {
            //console.log("Error",e)
        }
    }

    const findIndexForDisplay = (id,datalist) => {
        const index = datalist.findIndex(item => item['id'] == id)
        return index
    }

    const moveToNextStep = () => {
        try {
            
            if ( taxReceipts.length === 0 ) return failureToast( "Please fill the Tax Receipts Details" );
            if ( taxRefund.length === 0 ) return failureToast( "Please fill the Tax Refund Details" );
            if ( isFranchise ) {            
                if ( taxFranchises.length === 0 ) return failureToast( "Please fill the Franchisees Details" );
            }
           
            setAllData();
        } catch ( e ) {
            //console.log(e)
        }
    }

    return (
         <Container className={action=== 'new'?'mt-4':''} fluid>
                <Card>
                { action === 'new' ? <>
                <CardHeader className="border-0">
                    <h3 className="mb-0 floatLeft">Add Tax Details</h3>
                </CardHeader>
                <hr /></> :null}
                <CardBody> 
                    <Accordion
                        isFormOpen={ action === 'new' ? true : false} 
                        removePadding={ true }
                        title={ `Tax Structure For Receipts` }
                        key={ 'tax_structure_for_receipts' }
                    >
                        <TaxReceipts
                            setTaxReceipts={ setTaxReceipts }
                            isDisabled={isDisabled}
                            taxReceipts={ taxReceipts }
                            action={action}
                            businessUnit={action === 'new' ? businessUnit && businessUnit[ 'businessUnit' ] : id}
                            taxCodeId={ taxCodeId }
                            taxCodeType={ taxCodeType }
                            getDisplayValue={ getDisplayValue }
                            saveStepsCompleted={()=>props.saveStepsCompleted(5)}
                            isPrevious={isPrevious}
                            formId = {formId}
                            isNew={isNew}
                        />
                    </Accordion>
                    <Accordion
                        isFormOpen={ action === 'new' ? true : false} 
                        removePadding={ true }
                        title={ `Tax Structure For Refunds` }
                        key={ 'tax_structure_for_refunds' }>
                        <TaxRefund
                            isDisabled={isDisabled}
                            taxRefund={ taxRefund }
                            action = {action}
                            setTaxRefund={ setTaxRefund }
                            businessUnit={ action === 'new' ? businessUnit && businessUnit[ 'businessUnit' ] : id }
                            taxCodeId={ taxCodeId }
                            taxCodeType={ taxCodeType }
                            getDisplayValue={ getDisplayValue }
                            saveStepsCompleted={()=>props.saveStepsCompleted(5)}
                            isPrevious={isPrevious}
                            formId = {formId}
                            isNew={isNew}
                        />
                    </Accordion>
                     <Accordion Accordion
                        isFormOpen={ action === 'new' ? true : false} 
                        removePadding={ true }
                        title={ `Commission % And TDS Setup (Franchises Only)` }
                        key={ 'commission_franchises_only' }>
                        <CommissionFranchisesOnly
                            isDisabled={isDisabled}
                            isFranchise={ isFranchise }
                            setTaxFranchises={ setTaxFranchises }
                            taxFranchises={ taxFranchises }
                            businessUnit={ action === 'new' ? businessUnit && businessUnit[ 'businessUnit' ]  : id}
                            academicGroup={ academicGroup }
                            getDisplayValue={ getDisplayValue }
                            franchisesSetupId={ franchisesSetupId }
                            franchiseDetails={ franchiseDetails }
                            action = {action}
                            saveStepsCompleted={()=>props.saveStepsCompleted(5)}
                            isPrevious={isPrevious}
                            formId = {formId}
                            isNew={isNew}
                        />
                    </Accordion>
                </CardBody>
                <Row>
                    {action !== 'edit' && action !== 'view' ?<Col>
                        <Button className="floatLeft ml-4 mb-3" color="primary" type="button"
                            onClick={ () => {
                                previousStep();
                            } }
                        >
                            Previous
                      </Button>
                        <Button color="primary" className="floatRight mr-4 mb-3" type="button"
                            onClick={
                                () => moveToNextStep()
                            }
                        >
                            Save & Next
                       </Button>                       
                        
                    </Col>:
                     <Col>
                    {  action === 'edit' ?
                      <Button className="floatRight mr-4 mb-3" color="primary" type="button" 
                        onClick={()=>setIsDisabled(!isDisabled)}
                        >
                        {isDisabled ? 'Enable Form':'Disable Form'}
                        </Button>:null}                       
                        
                    </Col> }
                </Row>
            </Card>           
            </Container>
    )
}

export default AddTaxDetails;