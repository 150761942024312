export const getPaginatedData = (data,pageSize,pagination,setpagination=()=>{},setIsFetching=()=>{},setTableData=()=>{}) => {
    let n = pagination;
    if (data.length > pageSize) {
      n["nextPage"] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n["totalPage"] = Math.floor(data.length / pageSize);
    if (data.length % pageSize != 0) {
      n["totalPage"] += 1;
    }
    setpagination(n);
    setTableData(
      data.slice(
        pagination.currentPage * pageSize - pageSize,
        pagination.currentPage * pageSize
      ) || []
    );
    setIsFetching(false);
};
