import { makeStyles } from '@material-ui/styles';
import { Popover } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'


// const useStyles = makeStyles({
//   root:{
//     // zIndex: 'unset'

//   },
//   paper: {
//     marginTop: '5px',
//     boxShadow: "0px 0px 9px 0px rgba(0,0,0,0.5)",
//     width: (props) => (props?.width ? props.width : null),
//     // height: (props) => (props.height ? `${props.height}` : "400px"),
//     maxHeight: (props) => (props?.maxHeight ? props.maxHeight : '400px')
//   }
// });

const APopover = ({
  buttonComponent = () => { },
  menuComponent = () => { },
  width,
  maxHeight,
  highlightedStyle = {
    border: "1px solid #00B0F5",
    color: "#00B0F5",
  },
  normalStyle = {
    border: "1px solid #262626",
  },
  anchorOriginVertical = 'bottom',
  anchorOriginHorizontal = 'right',
  transformOriginVertical = 'top',
  transformOriginHorizontal = 'right',
  ...popoverProps
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  // const classes = useStyles({width, maxHeight});

  const handleOpen = (e) => setAnchorEl(e.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const open = Boolean(anchorEl);

  return (
    <div style={{ display: 'inline-block' }}>
      {buttonComponent(handleOpen, open ? highlightedStyle : normalStyle)}
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: anchorOriginVertical,
          horizontal: anchorOriginHorizontal,
        }}
        transformOrigin={{
          vertical: transformOriginVertical,
          horizontal: transformOriginHorizontal,
        }}
        // classes={classes}
        style={{zIndex: '1300'}}
        sx={{
          // zIndex: 2,
          '& .MuiPopover-paper': {
            marginTop: '5px',
            boxShadow: "0px 0px 9px 0px rgba(0,0,0,0.5)",
            width: width ? width : undefined,
            maxHeight: maxHeight ? maxHeight : undefined
          }
        }}
        {...popoverProps}
      >
        {menuComponent(handleClose)}
      </Popover>
    </div>
  )
}

export default APopover