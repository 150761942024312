import { useEffect, useState } from "react";
import { endpoint } from "../../../common/constant";
import { fetchAllPostPromisedData, fetchAllPromisedData } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { getCourseDetails } from "../service";

const formateResponse = (responseList = []) => {
  return responseList.map((item) => ({
    label: item?.materialNumber,
    value: item?.materialNumber,
    materialNumber: item?.materialNumber,
    materialId : item?.materialNumber,
    materialDescription: item?.materialDescription,
    serialNumberProfile: item?.serialNumberProfile,
  }));
};
export const fetchMaterialList = async (courseId, businessArea, setLoading, setData) => {
  setLoading(true);
  const res = await fetchAllPromisedData(
    `${endpoint.miscCourseMaterialMapping.getMiscCourseMaterials}?courseId=${courseId}&businessArea=${businessArea}`,
    true
  );
  if (res?.code === 200) {
    setData(formateResponse(res?.data?.materials));
  } else {
    setData([]);
  }
  setLoading(false);
};

export const useFetchMaterialsDropdown = (courseId, businessArea) => {
  const [materialDropdown, setMaterialDropdown] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    fetchMaterialList(courseId, businessArea, setLoading, setMaterialDropdown);
  }, [courseId, businessArea]);
  return [materialDropdown, isLoading];
};

export const useCourseDetails = (courseId) => {
  const [isLoading, setLoading] = useState(false);
  const [courseData, setCourseData] = useState([]);

  useEffect(() => {
    fetchCourse();
  }, [courseId]);

  const fetchCourse = async () => {
    setLoading(true);
    const res = await getCourseDetails(courseId);
    if (res?.code === 200 && res?.data) setCourseData(res?.data[0]);
    setLoading(false);
  };

  return [courseData, isLoading];
};

export const getIssueStockRequest = (studentApplication, row) => {
  // debugger;
  const {
    courseId,
    businessArea,
    businessAreaDispValue,
    sapPlantId,
    psid,
    applicationId,
    academicGroup,
    receiptId,
  } = studentApplication;

  return {
    businessArea,
    businessAreaDispValue: businessAreaDispValue,
    plantId: sapPlantId,
    academicCareerDispValue: "RCC",
    academicGroupDispValue: academicGroup,
    type: "PSID_ISSUANCE",
    businessUnitType :"Owned",

    // costCenter: "DL10A1110C",
    psidStockRequest: [
      {
        applicationId,
        receiptId: receiptId,
        psid,
        courseId,
        stock: [{ ...row }],
      },
    ],
  };
};

export const getPaymentInfoForPsid = async (reqBody) => {
  return fetchAllPostPromisedData(endpoint.studentDetails.getPaymentInformationForPsid, reqBody, 'post'); 
}