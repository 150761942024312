import React, { useState } from 'react';
import { ReactComponent as DocumentIcon } from 'assets/img/svg/Document.svg';
import {ReactComponent as ExcellIcon} from 'assets/img/svg/excel.svg'
import { useHistory } from 'react-router';
import { getScreenCards } from 'views/pages/manage/finOps/IssueStock/search/SearchCard';
import { RiFileExcelLine } from 'react-icons/ri';


const ModuleCards = ({ stockConsumptionReturnHandler, stockTxnHandler, downloadReportsClickHandler = () => { } }) => {
  const history = useHistory();



  const cards = [
    {
      heading: 'Print receipt',
      subHeading: 'Click here to print receipt',
      icon: <DocumentIcon />,
      action: () => {}
    },
    {
      heading: 'Download reports',
      subHeading: 'Ageing report, Current stock availability etc.',
      icon: <ExcellIcon />,
      action: () => {} ,
    },
   

  ];
  return (
    <>
      <div className={`screen-cards-container`}>{getScreenCards(cards)}</div>

    </>
  );
};

export default ModuleCards;
