import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import { AiOutlinePlus } from "react-icons/ai";
 
import AddMSTeamChMapForm from "./AddMSTeamChMapForm";
import AButton from "../../common/form-fields-mui/AButton";
import MSMappingList from "./MSMappingList";
import "./style.scss";
import {
  getMappingList,
  loadDropdowns,
  createMapping,
  updateMapping,
  deleteMapping,
} from "../../../../../redux/academic-sub-map/action";
import { useDispatch, useSelector } from "react-redux";
import ALoader from "../../common/a-loader";
import { failureToast } from "../../common/utils/methods/toasterFunctions/function";
const MsTeamChannelSubMapping = () => {
  const formDataSet = { id: "", subjectId: "", academicGroupId: "" };
  const [form, setForm] = useState(formDataSet);
  const [showForm, setShowForm] = useState(false);
  const dispatch = useDispatch();
  const mappingData = useSelector((state) => state.academicSubMapping);
  const [currentPage, setCurrentPage] = useState(0);

  const nextButtonHandler = (pageNumber) => {
    setCurrentPage(currentPage);
    dispatch(getMappingList(pageNumber));
  };

  useEffect(() => {
    dispatch(loadDropdowns());
    dispatch(getMappingList());
  }, []);

  const formHandler = (value, inputName) => {
    const currentData = { ...form };
    currentData[inputName] = value;
    setForm(currentData);
  };

  const addNewHandler = () => {
    if (showForm) {
      failureToast("Please save or delete current form");
      return;
    }
    setShowForm(true);
  };

  const removeHandler = () => {
    setForm(formDataSet);
    setShowForm(false);
  };

  const deleteHandler = (row) => {
    if (window.confirm("Are you sure you want delete it "))
      dispatch(deleteMapping(row.id));
  };

  const editHandler = (data) => {
    // if (showForm) {
    //   alert("Please save or delete current from");
    //   return;
    // }
    setShowForm(true);
    setForm(data);
  };

  const saveHandler = () => {
    if (form.id) {
      dispatch(
        updateMapping({ ...form, status: "ACTIVE" }, form.id, removeHandler)
      );
    } else {
      dispatch(createMapping({ ...form, status: "ACTIVE" }, removeHandler));
    }
  };

  return (
    <div className="d-flex flex-column" style={{ position: "relative", minHeight: 'calc(100vh - 100px)' }}>
      {mappingData.loading && <ALoader />}

      <Card className='flex-grow-1' style={{ marginBottom: "5px" }}>
        <CardBody className='d-flex flex-column'>
          <h3 style={{color:"black"}}>MS Teams Channel Subject Mapping</h3>
          <MSMappingList
            rowsData={[]}
            deleteHandler={deleteHandler}
            editHandler={editHandler}
            nextButtonHandler={nextButtonHandler}
            currentPage={currentPage}
            data={mappingData.mappingList || []}
          />
        </CardBody>
        {showForm && (
          <AddMSTeamChMapForm
            formHandler={formHandler}
            removeHandler={removeHandler}
            saveHandler={saveHandler}
            form={form}
          />
        )}
      </Card>
      <AButton
        updatedStyle={{
          border: "1px solid #00B0F5",
          background: "#FFFFFF",
          marginBottom: "10px",
          color: "#00B0F5",
          padding: "5px 8px",
          marginRight: "1rem",
          alignSelf: 'start'
        }}
        onClick={addNewHandler}
      >
        <AiOutlinePlus /> Add new
      </AButton>
    </div>
  );
};

export default MsTeamChannelSubMapping;
