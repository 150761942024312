import React, { useState } from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ReactComponent as CloseIcon } from "assets/img/svg/CloseIcon.svg";
import AButton from "../../../common/form-fields-mui/AButton";
import { savePTMValidator } from "./EditLectureForm";
import ALoader from "views/pages/manage/common/a-loader";

import UploadForTimetable from "../../../common/commonComponents/CustomUpload/UploadForTimetable";

const UploadDocumentModal = ({
  type,
  isOpen,
  setIsOpen,
  getS3Url = () => {},
  onDeleteHandler
}) => {
  const [s3Key, setS3Key] = useState("");
  const [loading, setLoading] = useState(false);

  const saveFileHandler = (e) => {
    setS3Key(e.key);
    getS3Url(e);
  };

  const onCloseReset = () => {
     if(loading) return
    setIsOpen(false);
  };

 

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Modal
        size="lg"
        isOpen={isOpen}
        toggle={onCloseReset}
        style={{ width: "510px" }}
      >
        <ModalBody>
          <div className="d-flex justify-content-between align-items-start mb-4">
            {loading && <ALoader/>}
            <div className="heading-3 label-color">Attachment</div>
            <CloseIcon
              onClick={onCloseReset}
            />
          </div>
          <UploadForTimetable
            saveHandler={saveFileHandler}
            setLoading={setLoading}
            deleteFileHandler = {onDeleteHandler}
          />
          <div>
            <AButton
              className="btn-left-0"
              variant="primary_filled"
              onClick={onCloseReset}
              disabled={loading}
            >
              Upload
            </AButton>
          </div>
        </ModalBody>
      </Modal>
    </LocalizationProvider>
  );
};

export default UploadDocumentModal;
