import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Card,
  CardBody,
  FormGroup,
  Col,
  Row,
  Button,
} from "reactstrap";
import Loader from 'react-loader-spinner'
import Select from "react-select";
import { returnDropDownObjectById } from '../../../common/utils/methods/commonMethods/utilityMethod';
import ContactPhone from './contactPhone'
import { getSelectedDropDownValue } from '../../../common/utils/methods/commonMethods/masterDataForSelect'
import { failureToast, successToast } from "../../../common/utils/methods/toasterFunctions/function";
import { PermisionDenied } from "../../../common/commonComponents";
import CustomButton from "components/CustomButton";

const ContactHistoryForm = (props) => {
  const {
    data,
    isDisabled,
    setEditVisible = () => { },
    isEditable = false,
    addContact = () => { },
    editContact = () => { },
    deleteContact = () => { },
    canReset = false,
    setIsVisible = () => { },
    isEditVisible,
    isFetching = false,
    action,
    isSavedData = false,
    userPermissions
  } = props;

  const [values, setValues] = useState({
    businessAreaId: null,
    contactHistoryDesc: "",
    contactHistoryDispValue: "",
    contactHistoryKey: "",
    contactId: "",
    contactName: "",
    departmentTypeId: "",
    emailId: "",
    jobTitleId: "",
    status: { label: "ACTIVE", value: "ACTIVE" },
    urlAddress: "",
  });
  const [isDisable, setIsDisable] = useState(false);
  const [isFetchingApi, setIsFetchingApi] = useState(false);
  const [contactId, setContactId] = useState(null);

  const statusList = [
    { label: "ACTIVE", value: "ACTIVE" },
    { label: "INACTIVE", value: "INACTIVE" },
  ];

  useEffect(() => {
    if (data) {
      // console.log('isDisabled value', isDisabled, isDisable)
      setValues({ ...data, status: getSelectedDropDownValue(data.status, statusList), departmentTypeId: getSelectedDropDownValue(data.departmentTypeId, props.departmentTypeData), jobTitleId: getSelectedDropDownValue(data.jobTitleId, props.jobTitleData) });
      setIsDisable(isDisabled);
      setContactId(data.id)
    }
  }, [data, props.jobTitleData, props.departmentTypeData]);

  useEffect(() => {
    setIsFetchingApi(isFetching);
  }, [data, isDisabled, isFetching, canReset]);



  const save = () => {
    if (!values.contactId) {
      failureToast("Please Enter Employee ID");
      return;
    }
    if (!values.contactHistoryKey || !values.contactHistoryKey.trim()) {
      failureToast("Please Enter Contact History Key");
      return;
    }
    if (!values.contactHistoryDesc || !values.contactHistoryDesc.trim()) {
      failureToast("Please Enter Contact History Description");
      return;
    }
    if (
      !values.contactHistoryDispValue ||
      !values.contactHistoryDispValue.trim()
    ) {
      failureToast("Please Enter Contact History Display Value");
      return;
    }
    if (values.status === "") {
      failureToast("Please Select Status");
      return;
    }
    // if (!values.contactName || !values.contactName.trim()) {
    //   failureToast("Please Enter Employee Name");
    //   return;
    // }
    if (values.jobTitleId && values.jobTitleId === "") {
      failureToast("Please Select Job Title");
      return;
    }
    if (values.departmentTypeId && values.departmentTypeId === "") {
      failureToast("Please Select Department Type");
      return;
    }
    // if (!values.emailId || !values.emailId.trim()) {
    //   failureToast("Please Enter Email ID");
    //   return;
    // }
    if (values.emailId) {
      const email = /^[^\s<>&=+'\-,]+[A-Za-z0-9./\\@#]*$/;
      if (!email.test(values.emailId)) {
        failureToast("Please Enter Valid Email ID");
        return;
      }
    }

    if (values.urlAddress) {
      const url = /^(http:\/\/www\.|https:\/\/www\.|www\.)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
      if (!url.test(values.urlAddress)) {
        failureToast("Please Enter Valid URL");
        return;
      }
    }

    // if (!values.urlAddress || !values.urlAddress.trim()) {
    //   failureToast("Please Enter URL Address");
    //   return;
    // }

    if (isEditable) {
      editContact(values);
    }

    if (!isEditable) {
      addContact(values);
    }
  };

  const reset = () => {
    setValues({
      businessAreaId: null,
      contactHistoryDesc: "",
      contactHistoryDispValue: "",
      contactHistoryKey: "",
      contactId: "",
      contactName: "",
      departmentTypeId: "",
      emailId: "",
      jobTitleId: "",
      status: { label: "ACTIVE", value: "ACTIVE" },
      urlAddress: "",
    });
  };

  const canDisableForm = () => {
    setIsVisible(false);
  };

  const chkAnyEditVisisble = () => {
    if (!isEditVisible) {
      setEditVisible(true);
      // console.log('inside the edit section');
      setIsDisable(false);
    } else {
      window.alert("Please Save the unsaved changes first.");
    }
  };

  return (
    userPermissions.includes('C') ?
      <Container>
        <Card className="editViewStretch">
          <CardBody>
            {isFetchingApi ? (
              <div
                className="mx-auto text-center py-5 my-5"
                style={{ height: "100vh" }}
              >
                <Loader type="Rings" color="#00BFFF" height={70} width={70} />
              </div>
            ) : (
              <>
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols1Input"
                      >
                        *Employee ID
                      </label>
                      <input
                        value={values.contactId}
                        className="form-control addresslocationInput"
                        required={true}
                        placeholder={isDisabled ? '' : 'Enter Employee ID'}
                        type="text"
                        maxLength="20"
                        disabled={isDisable}
                        onChange={(e) => {
                          setValues({ ...values, contactId: e.target.value })
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols1Input"
                      >
                        *Contact History Key
                      </label>
                      <input
                        value={values.contactHistoryKey}
                        className="form-control addresslocationInput"
                        required={true}
                        placeholder={isDisabled ? '' : 'Enter Contact History Key'}
                        type="text"
                        maxLength="50"
                        disabled={isDisable}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            contactHistoryKey: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols1Input"
                      >
                        *Contact History Description
                      </label>
                      <input
                        value={values.contactHistoryDesc}
                        className="form-control addresslocationInput"
                        required={true}
                        placeholder={isDisabled ? '' : 'Enter Contact History Description'}
                        type="text"
                        maxLength="50"
                        disabled={isDisable}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            contactHistoryDesc: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols1Input"
                      >
                        *Contact History Display Value
                      </label>
                      <input
                        value={values.contactHistoryDispValue}
                        className="form-control addresslocationInput"
                        required={true}
                        placeholder={isDisabled ? '' : 'Enter Contact History Display Value'}
                        type="text"
                        maxLength="50"
                        disabled={isDisable}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            contactHistoryDispValue: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols1Input"
                      >
                        *Status
                      </label>
                      <Select
                        value={values.status}
                        id="example3cols2Input"
                        placeholder={isDisabled ? '' : 'Select Status'}
                        isDisabled={isDisable}
                        options={statusList}
                        onChange={(sData) =>
                          setValues({ ...values, status: sData })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols1Input"
                      >
                        Employee Name
                      </label>
                      <input
                        value={values.contactName}
                        className="form-control addresslocationInput"
                        required={true}
                        placeholder={isDisable ? '' : 'Enter Employee Name'}
                        type="text"
                        maxLength="50"
                        disabled={isDisable}
                        onChange={(e) =>
                          setValues({ ...values, contactName: e.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols1Input"
                      >
                        *Job Title
                      </label>
                      <Select
                        value={values.jobTitleId}
                        id="example3cols2Input"
                        placeholder={isDisable ? '' : 'Select Job Title'}
                        options={props.jobTitleData}
                        isDisabled={isDisable}
                        onChange={(sData) =>
                          setValues({ ...values, jobTitleId: sData })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols1Input"
                      >
                        *Department Type
                      </label>
                      <Select
                        value={values.departmentTypeId}
                        id="example3cols2Input"
                        placeholder={isDisable ? '' : 'Select Department Type'}
                        options={props.departmentTypeData}
                        isDisabled={isDisable}
                        onChange={(sData) =>
                          setValues({ ...values, departmentTypeId: sData })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols1Input"
                      >
                        Email
                      </label>
                      <input
                        value={values.emailId}
                        className="form-control addresslocationInput"
                        required={true}
                        placeholder={isDisable ? '' : 'Enter Email'}
                        type="text"
                        maxLength="50"
                        disabled={isDisable}
                        onChange={(e) =>
                          setValues({ ...values, emailId: e.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols1Input"
                      >
                        URL Address
                      </label>
                      <input
                        value={values.urlAddress}
                        className="form-control addresslocationInput"
                        required={true}
                        placeholder={isDisable ? '' : 'Enter URL Address'}
                        type="text"
                        maxLength="50"
                        disabled={isDisable}
                        onChange={(e) =>
                          setValues({ ...values, urlAddress: e.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {isEditable ? (
                  isDisable ? (
                    !(action === 'view') ?
                      <Row className="pl-3 pr-3 justify-content-end">
                        {" "}
                        {/* <Button
                          style={{ marginLeft: "0px !important" }}
                          color="primary"
                          type="button"
                          onClick={chkAnyEditVisisble}
                        >
                          Edit
                        </Button>{" "} */}
                        <CustomButton
                          className={'mx-1'}
                          content={"Edit"}
                          permissionType={'U'}
                          onClick={chkAnyEditVisisble}
                          permissionSet={userPermissions}
                        />
                        {/* <Button
                          style={{ marginLeft: "0px !important" }}
                          color="primary"
                          type="button"
                          onClick={deleteContact}
                        >
                          Delete
                        </Button>{" "} */}
                        <CustomButton
                          className={'mx-1'}
                          content={"Delete"}
                          permissionType={'D'}
                          onClick={deleteContact}
                          permissionSet={userPermissions}
                        />
                      </Row> : null
                  ) : (
                    <Row className="pl-3 pr-3 justify-content-end">
                      {/* <Button
                        style={{ marginLeft: "0px !important" }}
                        color="primary"
                        type="button"
                        onClick={save}
                      >
                        Save
                      </Button> */}
                      <CustomButton
                        className={'mx-1'}
                        content={"Save"}
                        permissionType={'C'}
                        onClick={save}
                        permissionSet={userPermissions}
                      />
                      {action === 'new' ?
                        // <Button
                        //   color="primary"
                        //   type="button"
                        //   onClick={() => {
                        //     setEditVisible(false);
                        //     setIsDisable(true);
                        //   }}
                        // >
                        //   Cancel
                        // </Button> 
                        <CustomButton
                          className={'mx-1'}
                          content={"Cancel"}
                          permissionType={'cancel'}
                          onClick={() => {
                            setEditVisible(false);
                            setIsDisable(true);
                          }}
                          permissionSet={userPermissions}
                        />
                        : null}
                    </Row>
                  )
                ) : (
                  <Row className="pl-3 pr-3 justify-content-end">
                    {/* <Button
                      style={{ marginLeft: "0px !important" }}
                      color="primary"
                      type="button"
                      onClick={save}
                    >
                      Add
                    </Button> */}
                    <CustomButton
                      className={'mx-1'}
                      content={"Add"}
                      permissionType={'C'}
                      onClick={save}
                      permissionSet={userPermissions}
                    />
                    {/* <Button
                    color="primary"
                    type="button"
                    onClick={canDisableForm}
                  >
                    Cancel
                  </Button> */}
                    <CustomButton
                      className={'mx-1'}
                      content={"Cancel"}
                      permissionType={'cancel'}
                      onClick={canDisableForm}
                      permissionSet={userPermissions}
                    />
                  </Row>
                )}
              </>
            )}
          </CardBody>
        </Card>
        {isSavedData ? <ContactPhone action={action} isDisabled={isDisabled} cId={contactId} userPermissions={userPermissions}/> : null}
      </Container> : <PermisionDenied />
  );
};

export default ContactHistoryForm;
