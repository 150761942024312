import React, { useContext } from "react";
// import { useTreeItem } from "@mui/lab/TreeItem";
// import { useTreeItem } from '@mui/x-tree-view/TreeItem';

import { useTreeItem } from '@mui/x-tree-view/TreeItem';

import clsx from "clsx";
import Typography from "@mui/material/Typography";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import { pages } from "views/pages/manage/common/constant";
import { PermissionContext, RolePermissions } from 'appContext'
import { checkPermission } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";

const CustomTreeItem = React.forwardRef(function CustomContent(props, ref) {

  const userPermissions = RolePermissions(useContext(PermissionContext), pages['searchAcademicPlan']['id']);

  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    onClick: onClickData,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);
  console.log(useTreeItem(nodeId));
  const { data, mutator } = onClickData;
  //console.log(data);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event) => {
    handleSelection(event);
  };

  const addHandler = (event, data) => {
     // if(!expanded)
       // handleExpansion(event);
      mutator(data);
    
  };
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography
        onClick={handleSelectionClick}
        component="div"
        className={classes.label}
      >
        {label}
      </Typography>
      {checkPermission(userPermissions, 'C') &&  <div>
      {(data.isAdded || data?.added)&& data?.nodeType === "Sub-Topic" ? (
        <span className="semi-bold my-1" style={{ fontSize: '12px', color: '#757575' }}>Added</span>
      ) : (
        <AButton
          disabled={data.isAdded  || data?.added|| false}
          onClick={(e) => addHandler(e, data)}
          className='my-1'
          updatedStyle={{ height:"28px", fontSize:"12px", borderRadius: '4px', borderWidth: "1px" }}
          variant="outline"
        >
          Add
        </AButton>
      )}
      </div>}
    </div>
  );
});

export default CustomTreeItem;
