import React, { useEffect, useState } from "react";
import APopover from "../../../common/a-popover";
import SearchFilterForm from "./ReviewSearchFilterForm";
import AButton from "../../../common/form-fields-mui/AButton";
import ReviewSearchFilterForm from "./ReviewSearchFilterForm";
import { combinedViewInitialFilterForm } from "./constant";
import { useFetchTestTypes } from "../../academicTest/hooks";
import {
  addLectureText,
  useFetchFacultyDropdown,
  useFetchSubjectLectureDropdown,
  useGetPlanLecturesByDuration,
} from "../hooks";
import { subjectDropdown } from "../lectureModule/EditLectureForm";
import { dropdownMappingV2 } from "../helper";

import ListIcon from "assets/listIcon.png";
import GridIcon from "assets/gridIcon.png";

const highlightedStyle = {
  border: "1px solid #00B0F5",
  color: "#00B0F5",
};
const normalStyle = {
  // border: "1px solid #CCCCCC",
  border: "1px solid #E9ECEF",
  color: "#0F0F0F",
};

const ReviewSearchFilter = ({
  isCalenderView,
  setCalenderView,
  filterForm,
  filterInputHandler,
  resetDependentFields,
  applyFilterHandler,
  removeFilterHandler,
  roomsDropdown,
  filterCount,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lecturesDropdown, setLecturesDropdown] = useState([]);
  const [facultyDropdown, setFaculty] = useState([]);

  const onModalClose = () => setIsModalOpen(false);
  const subjectsDropdown = subjectDropdown(props?.planSubjects);
  const allLectures = useGetPlanLecturesByDuration(
    props?.formData?.plan?.value,
    props?.formData?.duration?.value
  );

  useEffect(() => {
    //  console.log(allLectures,props?.lectures);
    if (allLectures.length > 0 && props.lectures.length > 0) {
      let existLectures = [];
      let existFacultyMap = {};
      let existFaculty = [];

      props.lectures.forEach((lectureInTimetable) => {
        //  console.log(lectureInTimetable);
        allLectures.forEach((lectureInPlan) => {
          if (lectureInPlan?.id === lectureInTimetable?.lectureId)
            existLectures.push(lectureInPlan);
        });
        if (
          lectureInTimetable?.facultyId &&
          !existFacultyMap[lectureInTimetable?.facultyId]
        ) {
          existFacultyMap[lectureInTimetable?.facultyId] = true;
          existFaculty.push({
            label: lectureInTimetable?.facultyName,
            value: lectureInTimetable?.facultyId,
          });
        }
      });
      setLecturesDropdown(
        addLectureText(dropdownMappingV2(existLectures, "lectureNumber"))
      );
      setFaculty(existFaculty);
    }
  }, [allLectures, props?.lectures]);

  const testTypeDropdown = useFetchTestTypes();

  return (
    <div className="">
      {isCalenderView ? (
        <AButton onClick={() => setCalenderView(!isCalenderView)}>
          List View
          <img src={ListIcon} alt="black-color-list-icon" className="ml-1" />
        </AButton>
      ) : (
        <>
          <AButton
            onClick={() => setIsModalOpen(true)}
            updatedStyle={{
              fontWeight: "600",
              minWidth: "61px",
              ...(filterCount > 0 ? highlightedStyle : normalStyle),
            }}
          >
            Filter {filterCount > 0 && <>( {filterCount} )</>}
          </AButton>
          <AButton onClick={() => setCalenderView(!isCalenderView)}>
            Calendar View
            <img src={GridIcon} alt="black-color-grid-icon" className="ml-1" />
          </AButton>
        </>
      )}

      <ReviewSearchFilterForm
        isOpen={isModalOpen}
        onClose={onModalClose}
        form={filterForm}
        inputHandler={filterInputHandler}
        applyFilterHandler={applyFilterHandler}
        removeFilterHandler={removeFilterHandler}
        resetDependentFields={resetDependentFields}
        testTypeDropdown={testTypeDropdown}
        roomsDropdown={roomsDropdown}
        subjectsDropdown={subjectsDropdown}
        lecturesDropdown={lecturesDropdown}
        facultyDropdown={facultyDropdown}
      />
    </div>
  );
};

export default ReviewSearchFilter;
