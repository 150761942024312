import React, { useEffect } from 'react';
import {
  Card,
  CardHeader,
  Input,
  Container,
  Button,
  Table,
  Row,
} from 'reactstrap';
import { useState } from 'react';
// import "views/pages/manage/addressType/node_modules/react-sliding-pane/dist/react-sliding-pane.css";
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import CustomButton from 'components/CustomButton';
// import { failureToast } from '../../common/utils/methods/toasterFunctions/function';
import { MasterHeaderAuto, Rows } from '../../common/commonComponents/masterHeader/masterHeader';
import {
  getAPI,
  masterServiceBaseUrl,
  putpost,
  putpost2,
} from 'services/http';
import moment from 'moment';

var _ = require('lodash');

const DocumentStatusTable = (props) => {
  const {
    serverData,
    setSearch,
    resetSearch,
    isSaveVisible,
    setisSaveVisible,
    userPermissions,
    action,
  } = props;
  const [tableDataKey, settableDataKey] = useState('tableDataKey');
  const [updatedKey, setUpdateKey] = useState(new Date());
  const [searchStr, setSearchStr] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: '__new',
        status: 'ACTIVE',
        documentStatusDesc: null,
        documentStatusShortDesc: null,
        documentStatusDispValue: null,
        updatedDate: null,
      },
      ...props.classArray,
    ]);
  };

  const headerList = [
    { name: 'Document Status Key' },
    { name: 'Description' },
    { name: 'Display Value' },
    { name: 'Status' },
    { name: 'Action' },
  ];
  const listToRender = [
    'documentStatusKey',
    'documentStatusDesc',
    'documentStatusDispValue',
    'status',
  ];

  const saveAll = (cb) => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if (el['editMode']) {
        if (!el['documentStatusKey'] || !el['documentStatusKey'].trim()) {
          isValid = false;
          failureToast('Please Enter Document Status Key');
          return;
        } 
        else if (!el['documentStatusDesc'] || !el['documentStatusDesc'].trim()) {
          isValid = false;
          failureToast('Please Enter Description');
          return;
        } 
        // else if (
        //   !el['documentStatusShortDesc'] ||
        //   !el['documentStatusShortDesc'].trim()
        // ) {
        //   isValid = false;
        //   failureToast('Please Enter Short Description');
        //   return;
        // } 
        else if (
          !el['documentStatusDispValue'] ||
          !el['documentStatusDispValue'].trim()
        ) {
          isValid = false;
          failureToast('Please Enter Display Value');
          return;
        }
        if (!isValid) {
          return;
        }
        if (_tempArray[index]['__status'] == '__new') {
          putpost(
            masterServiceBaseUrl + '/documentStatus/createDocumentStatus',
            (data) => {
              successToast(data['message']);
              setisSaveVisible(false);
              setUpdateKey(new Date());
              props.fetchClasses(`${masterServiceBaseUrl}/documentStatus/getAllDocumentStatus`);
            },
            (data) => {
              failureToast(data['message']);
            },
            _tempArray[index],
            'post'
          );
        } else {
          putpost(
            masterServiceBaseUrl + '/documentStatus/updateDocumentStatus/' + _tempArray[index].id,
            (data) => {
              successToast(data['message']);
              setisSaveVisible(false);
              // delete _tempArray[index]['editMode']
              // props.setclassArray(_tempArray)
              props.fetchClasses(`${masterServiceBaseUrl}/documentStatus/getAllDocumentStatus`);
              settableDataKey(new Date().getMilliseconds());
              // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
            },
            (data) => {
              failureToast(data['message']);
            },
            _tempArray[index],
            'put'
          );
        }
      } else {
      }
    });
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
        // settableDataKey(new Date().getMilliseconds())
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      cb();
    }
  };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]['editMode'] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };
  const deleteRow = (index) => {
    if (props.classArray[index]['__status'] == '__new') {
      let n = props.classArray;
      n.splice(index, 1);
      props.setclassArray(n);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
    } else {
      if (
        window.confirm('Are you sure you want to delete this document status?')
      ) {
        putpost2(
          masterServiceBaseUrl +
            '/documentStatus/deleteDocumentStatus/' +
            props.classArray[index].id,
          (data) => {
            successToast(data['message']);
            if (props.classArray.length == 1) {
              
              props.fetchClasses(`${masterServiceBaseUrl}/documentStatus/getAllDocumentStatus`);
            } else {
              var _tempArray = props.classArray;
              if (_tempArray[index]['editMode']) {
                setisSaveVisible(false);
              }
              _tempArray.splice(index, 1);
              props.setclassArray(_tempArray);
            }
            settableDataKey(new Date().getMilliseconds());
            props.fetchClasses(`${masterServiceBaseUrl}/documentStatus/getAllDocumentStatus`);
          },
          (data) => {
            failureToast(data['message']);
          },
          'PUT'
        );
      }
    }
  };

  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
    setUpdateKey(new Date());
  };

  const filterData = (str) => {
    const newData =
      serverData &&
      serverData.filter((obj) =>
        Object.values(obj).some(
          (val) =>
            val && val.toString().toLowerCase().includes(str.toLowerCase())
        )
      );
    setSearch(newData);
  };

  useEffect(() => {
    if (!searchStr || !searchStr.trim()) {
      resetSearch();
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
      filterData(searchStr);
    }
  }, [searchStr]);

  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0 d-flex justify-content-between">
                <h3 className="mb-0 ">
                  Document Status
                </h3>
                
                  {/* <Button
                    disabled={isSaveVisible || isDisabled}
                    color="info"
                    size="sm"
                    type="button"
                    className={
                      'floatRight' +
                      (isSaveVisible || isDisabled ? ' btn-dark' : null)
                    }
                    onClick={() => {
                      newRow();
                    }}
                    data-testid="addNewDocumentStatus"
                  >
                    New Document Status
                  </Button> */}
                <CustomButton
                permissionType={'C,U'}
                disabled={isSaveVisible || isDisabled}
                className={
                  'floatRight' +
                  (isSaveVisible || isDisabled ? ' btn-dark' : null)
                }
                  permissionSet={userPermissions}
                  icon={true}
                  onClick={() => {
                    newRow();
                  }}
                  data-testid="addNewDocumentStatus"
                  content={'New Document Status'}
                />
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout">
                  <MasterHeaderAuto headerList={headerList} />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                          !el['editMode'] ? (
                            //
                            <>
                              <Rows data={el} listToBeRendered={listToRender}>
                                <td className="text-center" >
                                  {/* <Button
                                    color="info"
                                    size="sm"
                                    type="button"
                                    disabled={props.isViewOnly}
                                    onClick={() => editRow(index)}
                                    // data-testid="editButton"
                                  >
                                    <i className="fas fa-edit" />
                                  </Button> */}
                                  <CustomButton
                                  permissionType={'U'}
                                  disabled={props.isViewOnly}
                                  icon={true}
                                  forTable={true}
                                  permissionSet={userPermissions}
                                  onClick={() => editRow(index)}
                                />
                                  {/* <Button
                                    color="info"
                                    size="sm"
                                    type="button"
                                    disabled={props.isViewOnly}
                                    onClick={() => deleteRow(index)}
                                    // data-testid="deletebtn"
                                  >
                                    <i className="fas fa-trash" />
                                  </Button> */}
                                  <CustomButton
																		permissionType={'D'}
                                    disabled={props.isViewOnly}
																		icon={true}
																		forTable={true}
																		permissionSet={userPermissions}
																		onClick={() => deleteRow(index)}
																	/>
                                </td>
                              </Rows>
                            </>
                          ) : (
                            <tr key={index + '-class'}>
                              <td className="text-center p-2 mx-1">
                                <Input
                                 maxLength="50"
                                  data-testid='documentStatusKey'
                                  placeholder="Document Status Key"
                                  className="tdInput"
                                  defaultValue={el['documentStatusKey']}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      'documentStatusKey',
                                      true
                                    );
                                  }}
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength="50"
                                  defaultValue={el['documentStatusDesc']}
                                  data-testid='documentStatusDesc'
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      'documentStatusDesc',
                                      true
                                    );
                                  }}
                                  placeholder="Description"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength="50"
                                  defaultValue={el['documentStatusDispValue']}
                                  data-testid='documentStatusDispValue'
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      'documentStatusDispValue',
                                      true
                                    );
                                  }}
                                  placeholder="Display value"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <label className="custom-toggle mx-auto ml-2">
                                  <input
                                    checked={
                                      el.status == 'ACTIVE' ? true : false
                                    }
                                    type="checkbox"
                                    id={el.id}
                                    onChange={(e) => {
                                      settableDataKey(
                                        new Date().getMilliseconds()
                                      );
                                      let n = 'ACTIVE';
                                      if (!e.target.checked) {
                                        n = 'INACTIVE';
                                      }
                                      updateKey(index, n, 'status');
                                    }}
                                    data-testid='documentStatusStatus'
                                  />
                                  <span
                                    style={{ width: '72px' }}
                                    className="custom-toggle-slider rounded-circle activeToggle"
                                    data-label-off="Inactive"
                                    data-label-on="Active"
                                  />
                                </label>
                              </td>
                              <td className="text-center" data-testid="saveConcessionDocumentStatus">
                                {isSaveVisible ? (
                                  <>
                                    {' '}
                                    {/* <Button
                                      color="info"
                                      size="sm"
                                      type="button"
                                      className="mx-1"
                                      onClick={() => {
                                        saveAll();
                                      }}
                                    >
                                      Save
                                    </Button> */}
                                    <CustomButton
																			content={'Save'}
																			permissionType={'C,U'}
																			forTable={true}
																			permissionSet={userPermissions}
																			onClick={() => { saveAll(); }}
																		/>
                                    {/* <Button
                                      color="danger"
                                      size="sm"
                                      type="button"
                                      onClick={() => {
                                        let n = props.classArray;
                                        if (n[index]['__status'] == '__new') {
                                          n.splice(index, 1);
                                        } else {
                                          // n[index]['editMode'] = false
                                          props.fetchClasses(
                                            `${masterServiceBaseUrl}/documentStatus/getAllDocumentStatus`
                                          );
                                        }
                                        props.setclassArray(n);
                                        setisSaveVisible(false);
                                      }}
                                    >
                                      {' '}
                                      <i className="fas fa-times" />
                                    </Button> */}
                                    
                                    <CustomButton
                                      permissionType={'cancel'}
                                      icon={true}
                                      forTable={true}
                                      permissionSet={userPermissions}
                                      onClick={() => {
                                        let n = props.classArray;
                                        if (n[index]['__status'] == '__new') {
                                          n.splice(index, 1);
                                        } else {
                                          // n[index]['editMode'] = false
                                          props.fetchClasses(
                                            `${masterServiceBaseUrl}/documentStatus/getAllDocumentStatus`
                                          );
                                        }
                                        props.setclassArray(n);
                                        setisSaveVisible(false);
                                      }}
                                    />
                                    {' '}
                                  </>
                                ) : (
                                  // <Button
                                  //   color="info"
                                  //   size="sm"
                                  //   type="button"
                                  //   onClick={() => {
                                  //     deleteRow(index);
                                  //   }}
                                  // >
                                  //   {' '}
                                  //   <i className="fas fa-trash" />
                                  // </Button>
                                  <CustomButton
																		permissionType={'D'}
																		icon={true}
																		forTable={true}
																		permissionSet={userPermissions}
                                    onClick={() => { deleteRow(index); }}
																	/>
                                )}
                              </td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
              {action === 'edit' ? (
                <Button
                  className={'floatRight ml-auto m-3'}
                  color="primary"
                  size="md"
                  type="button"
                  onClick={() => {
                    setIsDisabled(!isDisabled);
                  }}
                >
                  {isDisabled ? 'Enable Form' : 'Disabled Form'}
                </Button>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default DocumentStatusTable;
