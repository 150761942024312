import React, { useContext } from 'react'
import BankListing from './BankListing';
import { PermisionDenied } from '../../../../common/commonComponents';
import { PermissionContext, RolePermissions } from '../../../../../../../appContext';
import { pages } from '../../../../common/constant';

const CollectionsBankSetup = () => {

    const userPermissions = RolePermissions(useContext(PermissionContext), pages["paymentCollectionMasterBankMaster"]["id"]);

    return (
        userPermissions
            ? <BankListing />
            : <PermisionDenied />
    );
}

export default CollectionsBankSetup;