import React, { useEffect } from "react";
import { Input, Button, Table } from "reactstrap";
import { useState } from "react";
// import "views/pages/manage/addressType/node_modules/react-sliding-pane/dist/react-sliding-pane.css";
import { failureToast } from "../../common/utils/methods/toasterFunctions/function";
import {
  MasterHeaderAuto,
  Rows,
} from "../../common/commonComponents/masterHeader/masterHeader";
import { successToast, putpost, putpost2 } from "services/http";
import { CustomCard, CustomContainer } from "../../common/commonComponents";
import { customValidation } from "../../common/utils/methods/validations/validation";
import { endpoint, commonMessages } from "../../common/constant";
import validationRule from "./validationRules.json";

var _ = require("lodash");

const CardHeader = ({
  isSaveVisible = false,
  newRow = () => {},
  setIsSaveVisible = () => {},
}) => {
  return (
    <>
      <h3>Page Details</h3>
      <div className="d-flex flex-row  justify-content-between">
        <Button
          disabled={isSaveVisible}
          color="info"
          size="sm"
          type="button"
          className={
            "floatRight mx-1 ml-2" + (isSaveVisible ? " btn-dark" : null)
          }
          onClick={() => {
            newRow();
            setIsSaveVisible(true);
          }}
        >
          Add New
        </Button>
      </div>
    </>
  );
};

const ProceesToPageTable = (props) => {
  const {
    serverData,
    setSearch,
    resetSearch,
    isSaveVisible,
    setisSaveVisible,
    processId,
  } = props;
  const [tableDataKey, settableDataKey] = useState("tableDataKey");
  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: "__new",
        status: "ACTIVE",
        pageName: null,
        pageDescription: null,
        navigation: null,
      },
      ...props.classArray,
    ]);
  };
  const headerList = [
    { name: "Page Name" },
    { name: "Page Description" },
    { name: "Navigation" },
    { name: "Action" },
  ];

  const listToRender = ["pageName", "pageDescription", "navigation"];

  const saveAll = (index) => {
    var _tempData = props.classArray[index];
    var isValid = true;
    isValid = customValidation(_tempData, validationRule);
    if (!isValid) {
      return;
    } else if (_tempData["__status"] == "__new") {
      putpost(
        endpoint.processToPage.create,
        (data) => {
          successToast(data["message"]);
          setisSaveVisible(false);
          props.fetchClasses(`${endpoint.processToPage.getAllByProcessTagId}/${processId}`);
        },
        (data) => {
          failureToast(data["message"]);
        },
        { ..._tempData, processId: processId },
        "post"
      );
    } else {
      putpost(
        `${endpoint.processToPage.update}/${_tempData.id}`,
        (data) => {
          successToast(data["message"]);
          setisSaveVisible(false);
          props.fetchClasses(`${endpoint.processToPage.getAllByProcessTagId}/${processId}`);
          settableDataKey(new Date().getMilliseconds());
        },
        (data) => {
          failureToast(data["message"]);
        },
        _tempData,
        "put"
      );
    }
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast(commonMessages.pleaseDeleteSaveCurrentChnage);
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
        // settableDataKey(new Date().getMilliseconds())
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      cb();
    }
  };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast(commonMessages.pleaseDeleteSaveCurrentChnage);
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]["editMode"] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };
  const deleteRow = (index) => {
    if (props.classArray[index]["__status"] == "__new") {
      let n = props.classArray;
      n.splice(index, 1);
      props.setclassArray(n);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
    } else {
      if (
        window.confirm(
          "Are you sure you want to delete this Process Page Mapping?"
        )
      ) {
        putpost2(
          `${endpoint.processToPage.delete}/${props.classArray[index].id}`,
          (data) => {
            successToast(data["message"]);
            props.fetchClasses(`${endpoint.processToPage.getAllByProcessTagId}/${processId}`);
            settableDataKey(new Date().getMilliseconds());
          },
          (data) => {
            failureToast(data["message"]);
          },
          "PUT"
        );
      }
    }
  };

  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
    //setUpdateKey(new Date());
  };

  return (
    <CustomContainer>
      <CustomCard
        HeaderElement={
          <CardHeader
            isSaveVisible={isSaveVisible}
            setIsSaveVisible={setisSaveVisible}
            newRow={newRow}
          />
        }
      >
        <div id="questionTable" className="table-responsive">
          <Table className="align-items-center table-flush ">
            <MasterHeaderAuto headerList={headerList} />
            <tbody className="list" key={tableDataKey}>
              {props.classArray
                ? props.classArray.map((el, index) =>
                    !el["editMode"] ? (
                      //
                      <>
                        <Rows data={el} listToBeRendered={listToRender}>
                          <td className="text-center  white-breakSpace">
                            <Button
                              color="info"
                              size="sm"
                              type="button"
                              onClick={() => editRow(index)}
                              // data-testid="editButton"
                            >
                              <i className="fas fa-edit" />
                            </Button>
                            <Button
                              color="info"
                              size="sm"
                              type="button"
                              onClick={() => deleteRow(index)}
                              // data-testid="deletebtn"
                            >
                              <i className="fas fa-trash" />
                            </Button>
                          </td>
                        </Rows>
                      </>
                    ) : (
                      <tr key={index + "-class"}>
                        <td className="text-center p-2 mx-1">
                          <Input
                            data-testid="pageName"
                            placeholder="Page Name"
                            className="tdInput"
                            defaultValue={el["pageName"]}
                            onChange={(e) => {
                              updateKey(
                                index,
                                e.target.value,
                                "pageName",
                                true
                              );
                            }}
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength="50"
                            defaultValue={el["pageDescription"]}
                            data-testid="pageDescription"
                            onChange={(e) => {
                              updateKey(
                                index,
                                e.target.value,
                                "pageDescription",
                                true
                              );
                            }}
                            placeholder="Page Description"
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength="100"
                            defaultValue={el["navigation"]}
                            data-testid="navigation"
                            onChange={(e) => {
                              updateKey(
                                index,
                                e.target.value,
                                "navigation",
                                true
                              );
                            }}
                            placeholder="Navigation"
                            className="tdInput"
                          />
                        </td>
                        <td
                          className="text-center"
                          data-testid="saveConcessionSubCategory"
                        >
                          {isSaveVisible ? (
                            <>
                              {" "}
                              <Button
                                color="info"
                                size="sm"
                                type="button"
                                className="mx-1"
                                onClick={() => {
                                  saveAll(index);
                                }}
                              >
                                Save
                              </Button>
                              <Button
                                color="danger"
                                size="sm"
                                type="button"
                                onClick={() => {
                                  let n = props.classArray;
                                  if (n[index]["__status"] == "__new") {
                                    n.splice(index, 1);
                                  } else {
                                    // n[index]['editMode'] = false
                                    props.fetchClasses(`${endpoint.processToPage.getAllByProcessTagId}/${processId}`);
                                  }
                                  props.setclassArray(n);
                                  setisSaveVisible(false);
                                }}
                              >
                                {" "}
                                <i className="fas fa-times" />
                              </Button>{" "}
                            </>
                          ) : (
                            <Button
                              color="info"
                              size="sm"
                              type="button"
                              onClick={() => {
                                deleteRow(index);
                              }}
                            >
                              {" "}
                              <i className="fas fa-trash" />
                            </Button>
                          )}
                        </td>
                      </tr>
                    )
                  )
                : null}
            </tbody>
          </Table>
        </div>
      </CustomCard>
    </CustomContainer>
  );
};
export default ProceesToPageTable;
