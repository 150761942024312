import {Button, Col, FormGroup, Input, Label, Row} from "reactstrap";
import React from "react";
import {RedAstric} from "../examIdSearchForm";

const ExamDescription = (props) => {
    const {
        formData, setFormData, Update,action,
        isCollapseTwoOpen, setIsCollapseTwoOpen,nextHandler,
        setIsCollapseOneOpen, setIsCollapseThreeOpen,label,placeholder,button
    } = props;
    return (
        <div className='container-fluid'>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label for="examId" className="form-control-label">{label.examIdDescription} {action === 'new' ? <RedAstric/> : null}</Label>
                        <Input disabled={action ==='view'} placeholder={placeholder.examIdDescription} className="tdSelect"
                               value={formData['examIdDescription']} maxLength={100}
                               onChange={(e) => {
                                   let s  = e.target.value;
                                   Update('examIdDescription',s.replace(/[^\w\s]/gi, "").toUpperCase())
                               }}/>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="examType" className="form-control-label">{label.shortDescription} {action === 'new' ? <RedAstric/> : null}</Label>
                        <Input disabled={action ==='view'} placeholder={placeholder.shortDescription} className="tdSelect"
                               value={formData['shortDescription']}
                               maxLength={50}
                               onChange={(e) => {
                                   Update('shortDescription',e.target.value.replace(/[^\w\s]/gi, ""))
                               }}/>
                    </FormGroup>
                </Col>
            </Row>
            {action ==='new' ?
                (<Row>
                    <Col xs={12}>
                        <Button color="info" size="sm" className="floatRight mx-1"
                                onClick={() => nextHandler(2)}>{button.next}</Button>
                    </Col>
                </Row>)
                :null}
        </div>
    )
};

export default ExamDescription;