import React, {useEffect, useState} from "react";
// import ProcessTagTypeTable from './documentTagTable';
import Loader from 'react-loader-spinner'
import {masterServiceBaseUrl, examControllerServiceBaseUrl, failureToast, getAPI} from 'services/http';
import {GetPagination} from '../../common/pagination'
import MessageSequenceTable from "./messageSequenceTable";
import {fetchAllData, fetchDetails} from "../../common/utils/methods/commonMethods/utilityMethod";
var _ = require('lodash');

const MessageCatalog = () => {
    const [classArray, setclassArray] = useState([

        // {
        //     editMode: false,
        //     // __status: "__new",
        //     processTagId:26,
        //     messageCatalogKey: 'test',
        //     status: "ACTIVE",
        //     messageCatalogDesc: 'test',
        //     messageCatalogDispValue: 'test',
        //     effectiveDate: '10-10-2021',
        // },
        // {
        //     editMode: false,
        //     // __status: "__new",
        //     processTagId:27,
        //     messageCatalogKey: 'test2',
        //     status: "ACTIVE",
        //     messageCatalogDesc: 'test2',
        //     messageCatalogDispValue: 'test2',
        //     effectiveDate: '10-10-2021',
        // },
    ])

    useEffect(() => {
        console.log('Table Data---->',classArray);
    },[classArray])
    const [apiloader, setapiloader] = useState(false)
    const [state, setstate] = useState([])
    const [pagination, setpagination] = useState({
        currentPage: 1,
        nextPage: null,
        previousPage: null,
        totalPage: null
    })
    const pageSize = 10
    const fetchClasses = async (url) => {
        setapiloader(true)
        getAPI(url, (data) => {
            setstate(data['data'])
            let n = pagination
            if (data['data'].length > pageSize) {
                n['nextPage'] = n.currentPage + 1
            }
            if (n.currentPage > 1) {
                n.previousPage = n.currentPage - 1
            }
            n['totalPage'] = Math.floor(data['data'].length / pageSize)
            if (data['data'].length % pageSize != 0) {
                n['totalPage'] += 1
            }
            setpagination(n)
            setapiloader(false)
            setclassArray(data['data'].slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || [])
        }, (data) => {
            failureToast(data['message'])
        })
    }
    const nextPage = () => {
        setclassArray(state.slice((pagination.currentPage + 1) * (pageSize) - pageSize, (pagination.currentPage + 1) * pageSize))
        let n = pagination
        n['currentPage'] = n['currentPage'] + 1
        n['previousPage'] = n.currentPage
        setpagination(n)
    }
    const firstPage = () => {
        setclassArray(state.slice(1 * (pageSize) - pageSize, 1 * pageSize))
        let n = pagination
        n['currentPage'] = 1
        n['previousPage'] = null
        setpagination(n)
    }
    const lastPage = () => {
        setclassArray(state.slice((pagination.totalPage) * (pageSize) - pageSize, (pagination.totalPage) * pageSize))
        let n = pagination
        n['currentPage'] = n.totalPage
        n['previousPage'] = n.totalPage - 1
        setpagination(n)
    }
    const previousPage = () => {
        if (pagination.currentPage != 1) {
            setclassArray(state.slice((pagination.currentPage - 1) * (pageSize) - pageSize, (pagination.currentPage - 1) * pageSize))
            let n = pagination
            n['currentPage'] = n['currentPage'] - 1
            if (n['currentPage'] == 1) {
                n['previousPage'] = null
            } else {
                n['previousPage'] = n.currentPage
            }
            setpagination(n)
        }
    }

    const filterMessageCatalog = _.debounce((searchStr) => {
        try {
            getAPI(masterServiceBaseUrl+`/messageCatalog/getMessageCatalog/?processTag=${searchStr}`, (data) => {
                setstate(data['data'])
                let n = pagination
                if (data['data'].length > pageSize) {
                    n['nextPage'] = n.currentPage + 1
                }
                if (n.currentPage > 1) {
                    n.previousPage = n.currentPage - 1
                }
                n['totalPage'] = Math.floor(data['data'].length / pageSize)
                if (data['data'].length % pageSize != 0) {
                    n['totalPage'] += 1
                }
                setpagination(n)
                // setapiloader(false)
                setclassArray(data['data'].slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || [])
            }, (data) => {
                failureToast(data['message'])
            })
        } catch (e) {
            console.log("Error", e);
        }
    }, 300);
      
      const [processTag,setProcessTag] = useState([])
      useEffect(() => {
          console.log('Process Tag DropDown Values---->',processTag);
    },[processTag])
    useEffect(() => {
        fetchClasses(masterServiceBaseUrl+'/messageCatalog/getAllMessageCatalog')
        // fetchAllData(masterServiceBaseUrl+'/messageCatalog/getAllMessageCatalog',setclassArray)
        fetchDetails(masterServiceBaseUrl+'/processTag/getAllActiveProcessTag',setProcessTag, 'tagType', 'reactSelect');
    }, [])
    return (
        <React.Fragment>
            {apiloader ? <div className="mx-auto text-center py-5 my-5" style={{height: "100vh"}}><Loader type="Rings" color="#00BFFF" height={70} width={70} timeout={10000}/></div> : <div></div>}
            <MessageSequenceTable filterMessageCatalog={filterMessageCatalog} processTag={processTag} previousPage={previousPage} fetchClasses={fetchClasses} setclassArray={setclassArray} classArray={classArray}/>
            {
                pagination.totalPage > 1
                    ?
                    <GetPagination fetchClasses={fetchClasses} pagination={pagination} previousPage={previousPage} firstPage={firstPage} lastPage={lastPage} nextPage={nextPage} setpagination={setpagination}/>
                    :
                    null
            }
        </React.Fragment>
    );
}
export default MessageCatalog;
