import React , {Fragment,useEffect,useState} from 'react';
import SearchContext from './searchContext/index';
import {useDispatch} from "react-redux";
import {updateMasterData} from "../../../../../redux";
import CustomLoader from "../../common/commonComponents/Loader/loader";

const ExamSyllabus=(props)=> {
    const dispatch = useDispatch();
    const [apiLoader,setApiLoader] = useState(false);
    useEffect(()=>{
        // dispatch(updateMasterData(['businessArea']));
        // console.log('TEstRedux------>',updateMasterData(['businessArea']))
    },[])
    return (
        <Fragment>
            <SearchContext setApiLoader={setApiLoader}/>
        </Fragment>
    );
}

export default ExamSyllabus;