import React, { useEffect, useState } from "react"
import PaymentCollectionHeader from "./PaymentCollectionHeader"
import PaymentCollectionForm from "./PaymentCollectionForm";
import ModuleCards from "./moduleCards";
import StudentSearchPage from "./PaymentCollectionList";
import { useSelector } from "react-redux";
import { FILTER__FORM_FIELDS_GLOBAL } from "./constant";
const PaymentCollection = () => {
    const [apiLoader, setApiLoader] = useState(false)
    const [rows, setRows] = useState([])
    const [isListVisible, setListVisible] = useState(false)
    const studentSearch = useSelector((state) => state.studentSearch);
    const [globalFilter, setGlobalFilter] = useState(FILTER__FORM_FIELDS_GLOBAL)

    return (<>
        {!isListVisible ? (<><div className="d-flex flex-column remove-container-top-space">
            <div className="p-3 student-filer-container-printId">
                <PaymentCollectionHeader />
                <PaymentCollectionForm apiLoader={apiLoader} setApiLoader={setApiLoader} rows={rows} setRows={setRows} isPopoveOpen={isListVisible} setPopoverOpen={setListVisible}setGlobalFilter={setGlobalFilter} globalFilter= {globalFilter}/>
            </div>
        </div>
            <ModuleCards /> </>
        )
            :
            <StudentSearchPage
                rows={studentSearch.studentList}
                setPopover={setListVisible}
                setRows={setRows}
                globalFilter={globalFilter}
                studentSearch={studentSearch}
                setGlobalFilter={setGlobalFilter}
            />


        }
    </>
    )
}
export default PaymentCollection
