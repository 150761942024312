import React, { useState } from 'react'
import { ReactComponent as ActiveControl } from '../../../../../../assets/img/svg/ActiveControl.svg';
import { ReactComponent as InactiveControl } from '../../../../../../assets/img/svg/InactiveControl.svg';
import StatusChangeDialog from './StatusChangeDialog';

const StatusCellComponent = ({ params, updateRowHandler = () => { } }) => {

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <span>
        {params.value === "ACTIVE" ?
          <ActiveControl onClick={() => setIsDialogOpen(true)} /> :
          <InactiveControl onClick={() => setIsDialogOpen(true)} />
        }
        <span
          style={{
            fontSize: "14px", marginLeft: "4px",
            color: params.value === "ACTIVE" ? '#0F0F0F' : '#757575'
          }}
        >
          {params?.value}
        </span>
      </span>
      <StatusChangeDialog
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        rowData={params?.row}
        onAgree={() => updateRowHandler({ ...params?.row, status: params?.row?.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' })}
      />
    </>
  )
}

export default StatusCellComponent