import React from 'react';
import Radio from '@mui/material/Radio';
import { BsSquare, BsSquareFill } from 'react-icons/bs';
import {styled, RadioGroup, FormControlLabel, FormControl, FormLabel} from '@mui/material';

const StyledFormControl = styled(FormControl)`
  .MuiTypography-root {
      font-family: inherit;
  }

  .MuiFormControlLabel-root {
      margin-right: 30px;
      margin-left: 0;
  }

  .MuiFormLabel-root {
    font-family: inherit;
    margin-left: 5px;

    &.Mui-focused {
        color: inherit;
    }
  }

  .MuiRadio-root {
      padding: 5px;
      margin-right: 4px;
  }

`;

export const BpRadio = (props) => {
    return (
      <Radio
        color="default"
        checkedIcon={<BsSquareFill color={props?.isDisabled ? "#dddddd" :'#00aeef'} size={20} />}
        icon={<BsSquare color={props?.isDisabled ? "#dddddd" :'#00aeef'} size={20} />}
        {...props}
      />
    );
}

const RadioButtons = (
    { 
        formLabel = null,
        options,
        value,
        onChange = (val) => {},
        readOnly = false
    }) => {

    return (
        <StyledFormControl>
            {formLabel && <FormLabel>{formLabel}</FormLabel>}
            <RadioGroup
                row
                aria-labelledby="radio-form-control-label"
                name="position"
                value={value}
            >
                {options.map(option => (
                    <FormControlLabel key={option.value} value={option} control={<BpRadio isDisabled={readOnly} />} label={option.label} onChange={(e) => {
                        if(readOnly === false) {
                            onChange(option);
                        }
                    }} />
                ))}
            </RadioGroup>
        </StyledFormControl>
    )
}

export default RadioButtons;