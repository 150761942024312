import { TabContext, TabList, TabPanel } from '@mui/lab';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router';
import { ReactComponent as BackIcon } from '../../../../../assets/img/svg/BackButton.svg'
import { Card, CardBody } from 'reactstrap';
import ALoader from '../../common/a-loader';
import PageLoadingAnimation from '../../common/commonComponents/PageLoadingAnimation';
import { endpoint } from '../../common/constant';
import { fetchAllPromisedData, getUrlSearchParams } from '../../common/utils/methods/commonMethods/utilityMethod';
import { getSyllabusByCourseId } from '../academicTest/style';
import HeaderComponent from '../viewAcademicPlan/HeaderComponent'
import AddLecture from './AddLecture';
import { tabListComponentStyles } from '../common/commonStyles';
import { Tab } from '@mui/material';
import { createLecture, getSubjectCourse } from './helper';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper';

const LecturePage = (props) => {
  const tabComponentStyle = {
    ...tabListComponentStyles,
    '& .MuiTab-root': {
      ...tabListComponentStyles?.['& .MuiTab-root'],
      fontSize: '20px',
      lineHeight: '28px',
      height: '60px'
    }
  }

  const history = useHistory();
  const { planId } = useParams();
  const { courseId, subjectName } = getUrlSearchParams(history?.location?.search);

  const [planApiLoader, setPlanApiLoader] = useState(false);
  const [syllabusApiLoader, setSyllabusApiLoader] = useState(false);
  const [saveLectureLoader, setSaveLectureLoader] = useState(false);
  const [isEdit, setIsEdit] = useState(false)


  const [planData, setPlanData] = useState({});
  const [courseSyllabus, setCourseSyllabus] = useState({
    courseId: courseId,
    subject: subjectName,
    tableOfContent: [],
  });

  // here duration is taken in mins
  const [currentDuration, setCurrentDuration] = useState('90');

  const fetchPlanData = async (id) => {
    setPlanApiLoader(true);
    const response = await fetchAllPromisedData(`${endpoint.viewAcademicPlan.getPlanById}/${id}?requireDispValue=true`, true)
    // console.log(response)
    if (response?.data) {
      setPlanData(response?.data);
    } else {
      setPlanData({});
    }
    setPlanApiLoader(false);
  }

  const fetchCourseSyllabus = async (courseId) => {
    setSyllabusApiLoader(true);
    const syllabusResponse = await getSyllabusByCourseId(courseId);
    // console.log(syllabusResponse);
    
    setCourseSyllabus(prev => ({ ...prev, tableOfContent: syllabusResponse }));
    setSyllabusApiLoader(false);
  }

  const fetchCourseSyllabusV2 = async (courseId) => {
    setSyllabusApiLoader(true);
    const syllabusResponse = await getSubjectCourse(
          {subjectId:courseId,
           subject:subjectName,
           academicPlanId:planId,
           duration:  currentDuration
          });
    setCourseSyllabus(prev => ({ ...prev, tableOfContent: [...syllabusResponse?.data] }));
    setSyllabusApiLoader(false);
  }

  useEffect(() => {
    fetchPlanData(planId);
  }, [planId])

  useEffect(() => {
    setCourseSyllabus(prev => ({
      ...prev,
      courseId: courseId,
      subject: subjectName,
    }))
    //fetchCourseSyllabus(courseId);
    fetchCourseSyllabusV2(courseId)
  }, [courseId, subjectName])

  const onTabChangeHandler = (e, newValue) => {
    setCurrentDuration(newValue);
  }

  const createLectureRequest = (request, clearCurrentForm = () => { }, fetchLectureList, isUpdate) => {

    setSaveLectureLoader(true) ;
     
    const updatedRequest = {
      academicPlanId: planId,
      subject: courseSyllabus.subject,
      subjectId:courseSyllabus?.courseId,
      courseId: courseSyllabus.courseId,
      packageId: planData.packageId,
      duration: currentDuration,

      ...request
    }
    createLecture(updatedRequest, isEdit || isUpdate,request?.lectureState)
      .then(res => {
        debugger


        if (res?.code === 412) {
          if (fetchLectureList)
          fetchLectureList()
          failureToast(res?.message);
          setTimeout(() => window.location.reload(false), 3000);
          return 
        }
        successToast(res?.message)
        setSaveLectureLoader(false);
        setIsEdit(false)
        clearCurrentForm();
        fetchCourseSyllabusV2(courseId)
        if (fetchLectureList)
          fetchLectureList()
      })
  }

  return (
    (planApiLoader) ? (
      <PageLoadingAnimation />
    ) : (
      <div className='remove-container-top-space' style={{ position: 'relative' }}>
        {(syllabusApiLoader || saveLectureLoader) && <ALoader position={'fixed'} />}
        <HeaderComponent
          planData={planData}
          fetchPlanData={fetchPlanData}
          setApiLoader={setPlanApiLoader}
          showViewAllPlanButton={false}
        />
        <Card style={{ marginBottom: '20px' }}>
          <CardBody className={`py-0 px-3`}>
            <div className='d-flex align-items-center'>
              <div className='d-flex align-items-center'>
                <div><IconButtonWrapper  onClick={() => history.goBack()} ><BackIcon/></IconButtonWrapper></div>
              </div>
              <div className='flex-grow-1'>
                <TabContext value={currentDuration}>
                  <TabList
                    onChange={onTabChangeHandler}
                    centered
                    sx={tabComponentStyle}
                  >
                     <Tab label='1hr ' value={'60'} />
                    <Tab label='1hr 15mins.' value={'75'} />
                    <Tab label='1hr 30mins.' value={'90'} />
                    <Tab label='1hr 45mins.' value={'105'} />
                    <Tab label='2hrs' value={'120'} />
                  </TabList>
                </TabContext>
              </div>
            </div>
          </CardBody>
        </Card>
        <AddLecture
          DATA={courseSyllabus}
          currentDuration={currentDuration}
          createLecture={createLectureRequest}
          planId={planId}
          isLoading={saveLectureLoader}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          fetchCourseSyllabusV2={fetchCourseSyllabusV2}
          courseId={courseId}
        />
      </div>
    )
  )
}

export default LecturePage