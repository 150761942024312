import React, { useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import AButton from 'views/pages/manage/common/form-fields-mui/AButton';
import PrintSlipCard from './PrintSlipCard';
import { getAddressSlipByPsidApiCall } from '../../service';
class ComponentToPrint extends React.PureComponent {
  // constructor(props) {
  //   super(props);
  // }
  render() {
    return <PrintSlipCard students={this.props?.contents} />;
  }
}

const requestFormatter = (data = [], allData = []) => {
  const getPhoneNo = item => {
    if (item?.primaryMobileNumber && item?.secondaryMobileNumber)
      return `${item?.primaryMobileNumber}/${item?.secondaryMobileNumber}`;

    return item?.primaryMobileNumber || item?.secondaryMobileNumber;
  };
  const getDispatchId = (psid, allStudents) => {
    const student = allStudents.filter(
      student => JSON.parse(student).psid === psid
    )[0];
    return JSON.parse(student)?.dispatchId;
  };

  return data.map(item => ({
    studentName: `${item?.studentFirstName} ${item?.studentLastName}`,
    psid: item?.psid || '-',
    contact: getPhoneNo(item),
    dispatchId: getDispatchId(item?.psid, allData),
    materialName: item?.dispatchMaterialDescription,
    address: `${item?.addressLine1 || ''} ${item?.addressLine2 || ''} PinCode-${
      item?.postalCode
    }`,
  }));
};

const createRequest = item => {
  const parsedItem = JSON.parse(item);
  const { psid, dispatchScheduleId } = parsedItem;
  return { psid, dispatchScheduleId };
};
const filterIssuedStock = item => {
  const parsedItem = JSON.parse(item);
  if (parsedItem.status === 'STOCK_ISSUED') return item;
};
const PrintSlipBtn = ({ data = [], rowData }) => {
  const componentRef = useRef();
  const [contents, setContents] = useState([]);
  const [printData, setPrintData] = useState([]);

  const loadPrintableContent = async (resolve, reject) => {
    //  console.log(data.filter(filterIssuedStock));
    const psidList = data.filter(filterIssuedStock).map(createRequest);
    const response = await getAddressSlipByPsidApiCall(psidList);
    if (response?.code === 200) {
      // console.log(response);
      setContents(
        requestFormatter(response?.data, data.filter(filterIssuedStock))
      );
    }
    return '';
  };

  return (
    <div>
      <ReactToPrint
        fonts={{ fontWeight: 700 }}
        trigger={() => (
          <AButton size="xs" variant="primary_filled">
            {`Print slip${data?.length > 1 ? 's' : ''}`}
          </AButton>
        )}
        content={() => componentRef.current}
        onBeforeGetContent={() => loadPrintableContent()}
      />
      <div style={{ display: 'none' }}>
        <ComponentToPrint contents={contents} ref={componentRef} />
      </div>
    </div>
  );
};
export default PrintSlipBtn;
