import {Button} from "reactstrap";
import React,{Fragment} from "react";
const ViewButton =(props)=>{
    const {text=''} = props;
    return (
        <Fragment>
            <Button type="button" color="info" size="sm" {...props}>
                <i className="fas fa-eye" />  {text ? '&nbsp; ' + text : null }
            </Button>
        </Fragment>
    )
}
export default ViewButton;