import React, { useState, useMemo, useContext, useEffect } from 'react';
import './index.scss';
import { BiArrowBack } from 'react-icons/bi';
import BasicDetails from "../../../common/basicDetails";
import PaymentMode from './paymentMode';
import { useHistory, useParams } from "react-router";
import FeeBreakup from './feeBreakup';
import { PermissionContext, RolePermissions } from "../../../../../../appContext";
import { endpoint, pages } from '../../../common/constant';
import { checkPermission, fetchAllPromisedData, setCookie } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { failureToast } from '../../../common/utils/methods/toasterFunctions/function';
import { Col, Row } from 'reactstrap';
import ConcessionDetails, { NULL_CUSTOM_OBJ } from './concessionDetails';
import CustomLoader from '../../../common/commonComponents/Loader/loader';
import FinalizeCharges from './finalizeCharges';
import { Dialog, Menu, MenuItem } from '@mui/material';
import VerifyCharges from './verifyCharges'
// import TemplateToPDF from './templates/templateToPdf';
import ConfirmationDialogSuccess from '../../../common/commonComponents/ConfirmationDialog';
import { BsCheck2 } from 'react-icons/bs';
import { PermisionDenied } from '../../../common/commonComponents';
import { FaChevronDown } from 'react-icons/fa';
import { canRevoke, getPaidItemListApi, formatPaidItemType } from './concessionDetails/common';
import ALoader from 'views/pages/manage/common/a-loader';
import AButton from '../../../common/form-fields-mui/AButton';
import { getCurrentYear, transformChargeStatus } from './common';
import { IoInformation } from "react-icons/io5";

export const getClassificationData = async (setAllCategories) => {
  try {
    const treeData = await fetchAllPromisedData(`${endpoint.treeView}`, false);
    if (treeData && Array.isArray(treeData)) {
      setAllCategories(treeData);
    } else {
      failureToast("Couldn't fetch categories!")
    }
  } catch (err) {
    failureToast("Couldn't fetch categories!")
  }
}

const StudentChargesDetails = (props) => {
  const history = useHistory();
  let { id, action } = useParams();
  const applicationDetailsNullData = useMemo(() => ({
    psid: null,
    studentName: "",
    phoneNo: "",
    email: "",
    applicationId: id,
    opportunityId: "",
    applicationDate: null,
    courseId: null,
    academicCareerId: null,
    courseCategoryId: null,
    termId: null,
    businessAreaId: null,
    registrationPaymentDate: null,
    chargeStatus: null,
    paymentStatus: null,
    baseFees: null,
    totalCourseFees: null,
    loading: true,
    updatedOn: null,
    updatedBy: null,
    courseCategoryValue: '',
    termValue: '',
    businessAreaValue: '',
    academicCareerValue: '',
    applicationQuotationDetailsId: null,
    studentType:'',
    alternatePhoneNo: ''
  }), [id]);

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["studentCharges"]['id']);
  const [applicationDetails, setApplicationDetails] = useState(applicationDetailsNullData);
  const [businessAreas, setBusinessAreas] = useState(null);
  const [courses, setCourses] = useState(null);
  const [masterLoading, setMasterLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [concessionLoading, setConcessionLoading] = useState(false);
  const [finalizeCharges, setFinalizeCharges] = useState(null);
  const [isChargesFinializes, setChargesFinalized] = useState(false);
  const [finalBreakupData, setFinalBreakupData] = useState(null);
  const [allowChargesModification, setAllowChargesModification] = useState(false);
  const [isChargesCancelled, setChargesCancelled] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedQoutationId, setSelectedQoutationId] = useState(null);
  const [latestQoutationId, setLatestQoutationId] = useState(null);
  const [pastIdSelected, setPastIdSelected] = useState(false);
  const [allQuotationIds, setAllQuotationIds] = useState([]);
  const [fetchingQuotationIds, setFetchingQuotationIds] = useState(false);


  const [autoConcession,setApplyAutoConcession] = useState(false);
  const [modificationType,setModificationType] = useState(null);
  const [cmprAllowed,setCmprAllowed] = useState(false);
  // if net playable is lesser then UI calculated netPayable then user can apply cmprAllowed 
  const [lowestNetPayable,setLowestNetPayable] = useState(null)
  const [paidItems, setPaidItems] = useState([])
  const [isDefinedConcessionDirty, setDefinedConcessionDirty] = useState(false);
  const [customSlab, setCustomSlab] = useState(NULL_CUSTOM_OBJ);
  const [processedData, setProcessedData] = useState({});
  const [studentEligibilityInfo, setStudentEligiblityInfo] = useState(null);




  useEffect(()=>{
    getPaidItemList(applicationDetails)
  },[applicationDetails?.applicationQuotationDetailsId])



  const [paymentUpdated, setPaymentUpdated] = useState(false);
  const [concessioUpdated, setConcessionUpdated] = useState(true);
  const [isOpen, setIsOpen] = useState({
    detailsTab: true,
    paymentTab: false,
    concessionsTab: false,
    finalTab: false
  });
  const [verifyChargesDialog, handleVerifyChargesDialog] = useState(false);

  // this for new implemetation
  const [basicDeatilVerified, setBasicDetailVerified] = useState(false);

  const handleConfirmVerfyBasicDetail = () => {
    setBasicDetailVerified(true);
    setIsOpen({
      detailsTab: false,
      paymentTab: true,
      concessionsTab: false,
      finalTab: false
    })
  }


  
  // end of new implementation

 const getPaidItemList = async()=>{
     const response = await getPaidItemListApi(applicationDetails) 
      setPaidItems(formatPaidItemType(response))
 }

  const DYNAMIC_FORM_FIELDS = [
    { field: 'businessArea', name: "businessArea", state: businessAreas, stateMethod: setBusinessAreas, url: endpoint.businessArea.getBusinessAreaById, fieldName: "businessAreaId" },
    { field: 'courseId', name: "courseId", state: courses, stateMethod: setCourses, url: endpoint.course_details.getByCourseId, fieldName: "courseId" },
  ];

 async function fetchAllData(url, name, field /** field will be removed later */, stateMethod) {
    try {
      const response = await fetchAllPromisedData(url);
      if (response) {
        let res = {}
        if (field === "courseId") {
          res = {
            // For courseId, BE should send same response as other APIs
            label: response?.[0]?.courseName ?? '',
            value: +response?.[0]?.courseId ?? '',
          }
        } else {
          res = {
            label: response[`${name}DispValue`],
            value: response.id,
            desc: response[`${name}Desc`]
          }
        }
        const list = [res]
        stateMethod(list);
      } else {
        failureToast("Could not fetch data!");
      }
    } catch (err) {
      failureToast("Could not fetch data!");
    }
  }

  // const { id, action } = props.match.params ?? { id: '', action: '' };
  

  const [paymentDetails, setPaymentDetails] = useState(null);
  console.log(paymentDetails,"paymentDetails")
  const [concessionDetails, setConcessionDetails] = useState([]);
  const [feeBreakupData, setFeeBreakupData] = useState({ fetching: true, data: [] });
  const [itemTypes, setItemTypes] = useState({ fetching: true, data: [] });

  // const [autoConcession, setAutoConcession] = useState([]);
  const [doExist, setDoExist] = useState(false);

  const [upfrontPaymentModes, setUpfrontPaymentModes] = useState([]);
  const [advancePaymentModes, setAdvancePaymentModes] = useState([]);
  const [docRequiredForCustomConcession, setDocRequiredForCustomConsession] = useState(false);
  const [paymentModificationAllowed, setPaymentModificationAllowed] = useState(true);

  const [asyncLoader,setAsyncLoader] = useState(false);
  const [studentCurrentYear,setStudentCurrentYear] = useState(null);
   

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  async function getApplicationDetails() {
    setApplicationDetails(details => ({ ...details, loading: true }));

    try {
      const resp = await fetchAllPromisedData(`${endpoint.getStudentChargesApplicationDetails}/${id}/STUDENT_CHARGES`); //STUDENT_CHARGES
      if (resp) {
        setPastIdSelected(false);
        setLatestQoutationId(resp?.applicationQuotationDetailsId ?? null);
        setSelectedQoutationId(resp?.applicationQuotationDetailsId ?? null);

        setApplicationDetails({ ...resp, chargeStatus : transformChargeStatus(resp?.chargeStatus), loading: false });
        // checkDoExist(resp?.applicationQuotationDetailsId);
        if (!courses || !businessAreas) {
          const getAllCalls = [];
          for (let i = 0; i < DYNAMIC_FORM_FIELDS.length; i++) {
            const { url, name, field, fieldName, stateMethod } = DYNAMIC_FORM_FIELDS[i];
            getAllCalls.push(fetchAllData(`${url}/${resp?.[fieldName]}`, name, field, stateMethod));
          }
          setMasterLoading(true);
          const year = await getCurrentYear(resp);
          setStudentCurrentYear(year)
          Promise.all(getAllCalls).then(() => setMasterLoading(false)).catch(err => console.log(err));
        }
      } else {
        failureToast("Couldn't fetch application details.");
      }
    } catch (err) {
      console.log("error student charges", err)
      failureToast("Couldn't fetch application details.");
    }
  };

  async function getPaymentDetails() {
    try {
      setPaymentLoading(true);
      let url = `${endpoint.applicationQuotationDetails}/${id}`;
      if (pastIdSelected) {
        url = `${endpoint.applicationQuotationDetails}/${id}?applicationQuotationId=${selectedQoutationId}`;
      }
      const resp = await fetchAllPromisedData(url);
      setPaymentLoading(false);
      if (resp) {
        setPaymentDetails(resp);
        if (allowChargesModification) {
          getApplicationDetails();
          setBasicDetailVerified(true)
          setAllowChargesModification(false)
          setChargesFinalized(false)
        }
        setPaymentUpdated(true);
      } else {
        setPaymentDetails(null);
      }
    } catch (err) {
      failureToast("Couldn't fetch payment details.");
      setPaymentDetails(null);
      setPaymentLoading(false);
    }
  };

  async function getConcessionDetails() {
    try {
      if(selectedQoutationId){
      setConcessionLoading(true);
      let url = `${endpoint.studentChargesConcessionDetailsConcession.getByApplicationId}/${id}?applicationQuotationDetailId=${selectedQoutationId}`;
      if (pastIdSelected) {
        url = `${endpoint.studentChargesConcessionDetailsConcession.getByApplicationId}/${id}?applicationQuotationDetailId=${selectedQoutationId}`;
      }
      const resp = await fetchAllPromisedData(url);
      setConcessionLoading(false);
      if (resp) {
        setConcessionDetails(resp);
        setConcessionUpdated(true);
      } else {
        failureToast("Couldn't fetch Concession Details.");
        setConcessionDetails([]);
      }
      setConcessionUpdated(true);
    }
    } catch (err) {
      failureToast("Couldn't fetch Concession Details.");
      setConcessionDetails([]);
      setConcessionLoading(false);
    }
  };


  const getFeeBreakupData = async () => {
    setFeeBreakupData({ fetching: true, data: [] });
    let url = `${endpoint.feeBreakfromPricingByApplicationId}/${id}`;
    if (applicationDetails?.applicationQuotationDetailsId) {
      url = `${url}?quotationId=${applicationDetails?.applicationQuotationDetailsId}`
    }
    const breakup = await fetchAllPromisedData(url, true);
    // const breakup = await fetchAllPromisedData(`${endpoint.breakup.getAll}/${applicationDetails.termId}/${applicationDetails.courseId}/${applicationDetails.businessAreaId}`, true);
    if (breakup?.code === 200) {
      setFeeBreakupData({ fetching: false, data: breakup.data })
    } else {
      setFeeBreakupData({ fetching: false, data: [] })
    }
  }

  const getItemTypeData = async () => {
    const itemType = await fetchAllPromisedData(endpoint.item_type_setup.getAll, false);
    if (Array.isArray(itemType)) {
      // const tempData = itemType?.map(p => { return { label: p.description, value: p.id } })
      setItemTypes({ fetching: false, data: itemType })
    } else {
      setItemTypes({ fetching: false, data: [] })
    }
  }


  const getSavedBreakup = async () => {
    const resp = await fetchAllPromisedData(`${endpoint.savedBreakup}/${paymentDetails?.id}`, true);
    if (resp?.code === 200) {
      
      setFinalBreakupData(resp.data)
    } else {
      setFinalBreakupData(null);
    }
  }

 

  const checkDoExist = async (applicationQuotationDetailsId) => {
    // console.log('Do check validated', applicationQuotationDetailsId)

    if (!applicationQuotationDetailsId) {
      setDoExist(false);
      return;
    }

    const res = await fetchAllPromisedData(`${endpoint.deliveryOrder.deliveryOrderDetails}/${applicationQuotationDetailsId}`, false, false);
    if (res) {
      setDoExist(true);
    } else {
      setDoExist(false);
    }
  }
  const checkPaymentModeForDo = (paymentModeMasterId) => {
    const selectedPaymentMode = advancePaymentModes.find(item => item?.id === paymentModeMasterId);
    if (!selectedPaymentMode) return false;
    return selectedPaymentMode?.paymentModeKey?.toLowerCase() === 'loan';
  }

  const checkStudentEligibility = async() => {
    try {
      const resp = await fetchAllPromisedData(`${endpoint.studentChargesCreationEligibility}/${id}`, true);
      if(resp?.code === 200){
        setStudentEligiblityInfo(resp?.data);
      }else{
        setStudentEligiblityInfo(null);
      }

    }catch(e){
       console.log(e);
    }
  }

  useEffect(() => {
    getItemTypeData();
    getClassificationData(setAllCategories);
  }, [])

  useEffect(() => {
    if (applicationDetails.termId && applicationDetails.courseId && applicationDetails.businessAreaId) {
      getFeeBreakupData();
    }

    if (applicationDetails.chargeStatus === 'CANC') {
      setChargesCancelled(true)
    }

  }, [applicationDetails])

  useEffect(() => {
    getApplicationDetails();
    getPaymentDetails();
    getConcessionDetails();
    checkStudentEligibility();
  }, [id]);

  useMemo(() => {
    if (selectedQoutationId) {
      getPaymentDetails();
      getConcessionDetails();
      checkDoExist(selectedQoutationId);
    }
  }, [pastIdSelected, selectedQoutationId])

  const handleChargesUpdate = (charges) => {
    setFinalizeCharges(charges)
  }

  useEffect(() => {
    
    if ((paymentDetails?.id && paymentDetails?.chargeStatus === 'APPL' && applicationDetails?.chargeStatus === 'APPL') || (paymentDetails?.id && paymentDetails?.chargeStatus === 'MODY')) {
      getSavedBreakup();
      if(paymentDetails?.id && paymentDetails?.chargeStatus === 'APPL' && applicationDetails?.chargeStatus === 'APPL' || paymentDetails?.id && paymentDetails?.chargeStatus === 'MODY'){
        setChargesFinalized(true);
      }
    }
  }, [paymentDetails, applicationDetails])

  useEffect(() => {
      if(paymentDetails?.id && ["CONC", "VERY", "PAPP"].includes(paymentDetails?.chargeStatus)){
        setChargesFinalized(false);
      }
    
  }, [pastIdSelected, paymentDetails])



  // const addAutoConcession = () => {
  //   let tempConcessionDetails = concessionDetails?.filter(el => el?.isAutoConcession !== true);
  //   let concessionAlreadyPresent = false;
  //   tempConcessionDetails.forEach(el => {
  //     if (el?.concessionType === "DEFINED" &&
  //       (el?.concessionSubCategoryId
  //         ? el.concessionSubCategoryId === autoConcession[0]?.concessionSubCategoryId
  //         : el.concessionSubSubCategoryId === autoConcession[0]?.concessionSubSubCategoryId)
  //     ) concessionAlreadyPresent = true;
  //   })

  //   if (concessionAlreadyPresent) setConcessionDetails(tempConcessionDetails)
  //   else setConcessionDetails([...autoConcession, ...tempConcessionDetails])
  // }

  const isConcessionUnderApproval = () => {
    return concessionDetails?.length > 0 && canRevoke(concessionDetails[0]?.concessionApprovalStatus, concessionDetails[0]?.isApproved)
  }

  // useEffect(() => {
  //   addAutoConcession();
  // }, [autoConcession])

  const getAllQuotationIds = async () => {
    setAllQuotationIds([]);
    setFetchingQuotationIds(true);
    fetchAllPromisedData(`${endpoint.allQuotations}/${id}`, true).then((data) => {
      if (data?.code === 200) {
        setAllQuotationIds(data.data);
        setFetchingQuotationIds(false);
        // handleClick();
      }
    })
  }


  return (
    userPermissions ? (
      <>
        {masterLoading || paymentLoading || concessionLoading || asyncLoader ? <ALoader position={"fixed"} /> : null}
        <div className='student-charges-details-container'>
          <header className='d-flex justify-content-between pr-3'>
            <div>
              <BiArrowBack size={20} color={'#0F0F0F'} onClick={() => history.push('/admin/studentCharges')} />
              <span className='title'>{applicationDetails.studentName}</span>
            </div>
            <div className='d-flex align-items-center'>
            {action==='view' && checkPermission(userPermissions, 'U') && <div className='mr-3'><AButton size='sm' variant='primary_filled' disabled={pastIdSelected} onClick={() => {history.push(`/admin/studentCharges/details/${id}/edit`); setConcessionUpdated(true)}}>Edit</AButton></div>}
              <span className='title color-dark mr-3'>Quotation IDs:</span>
              {paymentDetails?.id ?
                <span
                  className='cursor d-inline-block px-4 py-1 mx-2 label-color br-8 regular-large mr-2'
                  style={{ background: '#00B0F5', color: '#ffffff' }}
                >
                  {paymentDetails?.id}
                </span> :
                null
              }
              <>
                <div className='d-inline cursor br-8 px-3 py-2 regular-large ml-2' style={{ background: '#ffffff' }} onClick={(e) => { getAllQuotationIds(); handleClick(e) }}>
                  <span className='pr-2'>View All</span><FaChevronDown className={"mr-1"} />
                </div>
                {allQuotationIds?.length || fetchingQuotationIds ? <Menu
                  id="long-menu"
                  MenuListProps={{
                    'aria-labelledby': 'long-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      // maxHeight: ITEM_HEIGHT * 4.5,
                      width: '12ch',
                    },
                  }}
                >
                  {
                    fetchingQuotationIds ? <div className='d-flex justify-content-center'><i className="fas fa-spinner fa-spin" aria-hidden="true"></i></div> : null
                  }
                  {
                    allQuotationIds.map(i => (
                      <MenuItem key={`${i}`} onClick={() => { setSelectedQoutationId(i); setPastIdSelected(i !== latestQoutationId ? true : false); setAnchorEl(null); }} sx={selectedQoutationId === i ? { background: '#00B0F5', color: '#ffffff' } : {}}>
                        {i}
                      </MenuItem>
                    ))
                  }
                </Menu> : null}
              </>
            </div>
          </header>
          <Row className="w-100">
            <Col md='9'>
              <BasicDetails userPermissions={userPermissions}
                applicationDetails={applicationDetails}
                academicCareer={[]}
                courseCategory={[]}
                term={[]}
                businessAreas={businessAreas}
                courses={courses}
                appCourseDetailsLoading={masterLoading}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                basicDeatilVerified={basicDeatilVerified}
                handleConfirmVerfyBasicDetail={handleConfirmVerfyBasicDetail}
                disableActions={pastIdSelected}
                pastIdSelected={pastIdSelected}
                chargeStatus={paymentDetails?.chargeStatus}
              />
              <PaymentMode
                userPermissions={userPermissions}
                applicationDetails={applicationDetails}
                paymentDetails={paymentDetails}
                getApplicationDetails={getApplicationDetails}
                getPaymentDetails={getPaymentDetails}
                paymentLoading={paymentLoading}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                courseCategory={[]}
                // setAutoConcession={setAutoConcession}
                upfrontPaymentModes={upfrontPaymentModes}
                setUpfrontPaymentModes={setUpfrontPaymentModes}
                advancePaymentModes={advancePaymentModes}
                setAdvancePaymentModes={setAdvancePaymentModes}
                selectedQoutationId={selectedQoutationId}
                pastIdSelected={pastIdSelected}
                isConcessionUnderApproval={isConcessionUnderApproval()}
                concessionDetails={concessionDetails}
                paymentModificationAllowed={paymentModificationAllowed}
                modificationType={modificationType}
                setCmprAllowed={setCmprAllowed}
                setModificationType={setModificationType}
                //new key
                disableActions={!basicDeatilVerified || (isChargesFinializes && !allowChargesModification) || pastIdSelected || !paymentModificationAllowed}
              />
              <ConcessionDetails
                userPermissions={userPermissions}
                applicationDetails={applicationDetails}
                concessionDetails={concessionDetails}
                paymentDetails={paymentDetails}
                getConcessionDetails={getConcessionDetails}
                getApplicationDetails={getApplicationDetails}
                concessionLoading={concessionLoading || paymentLoading}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                feeBreakupData={feeBreakupData.data}
                itemTypes={itemTypes.data}
                allCategories={allCategories}
                setAllCategories={setAllCategories}
                // setAutoConcession={setAutoConcession}
                // autoConcession={autoConcession}
                docRequiredForCustomConcession={docRequiredForCustomConcession}
                setDocRequiredForCustomConsession={setDocRequiredForCustomConsession}
                getPaymentDetails={getPaymentDetails}
                setPaymentModificationAllowed={setPaymentModificationAllowed}
                setAsyncLoader={setAsyncLoader}
                setApplyAutoConcession={setApplyAutoConcession}
                setModificationType={setModificationType}
                autoConcession={autoConcession}
                modificationType={modificationType}
                allowChargesModification={allowChargesModification}
                cmprAllowed={cmprAllowed}
                setCmprAllowed={setCmprAllowed}
                processedData = {processedData}
                setProcessedData={setProcessedData}
                

                //new key
                disableActions={!basicDeatilVerified || (isChargesFinializes && !allowChargesModification) || pastIdSelected || action === 'view'}
                finalizeCharges={finalizeCharges}
                businessAreas={businessAreas}
                courses={courses}
                studentCurrentYear={studentCurrentYear}
                selectedQoutationId={selectedQoutationId}
                lowestNetPayable = {lowestNetPayable}
                setLowestNetPayable ={setLowestNetPayable}
                paidItems = {paidItems}
                isDefinedConcessionDirty = {isDefinedConcessionDirty}
                setDefinedConcessionDirty = {setDefinedConcessionDirty}
                customSlab = {customSlab}
                setCustomSlab = {setCustomSlab} 

              />

              <FinalizeCharges
                userPermissions={userPermissions}
                finalizeCharges={finalizeCharges}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                applicationQuotationDetailsId={applicationDetails?.applicationQuotationDetailsId ?? paymentDetails?.id}
                applicationStatus={pastIdSelected ? paymentDetails?.chargeStatus : applicationDetails?.chargeStatus}
                updatedBy={paymentDetails?.updatedBy}
                updatedOn={paymentDetails?.updatedOn}
                isChargesFinializes={isChargesFinializes}
                finalBreakupData={finalBreakupData}
                setAllowChargesModification={setAllowChargesModification}
                allowChargesModification={allowChargesModification}
                isChargesCancelled={isChargesCancelled}
                viewOnly={action === 'view'}
                getApplicationDetails={getApplicationDetails}
                handleVerifyChargesDialog={handleVerifyChargesDialog}
                applicationDetails={applicationDetails}
                paymentDetails={paymentDetails}
                concessionDetails={concessionDetails}
                getPaymentDetails={getPaymentDetails}
                setAsyncLoader={setAsyncLoader}

                //new key
                disableActions={!basicDeatilVerified  || pastIdSelected || action === 'view'}
                businessAreas={businessAreas}
                courses={courses}
                allCategories={allCategories}
                doExist={doExist}
                checkPaymentModeForDo={checkPaymentModeForDo}
                pastIdSelected={pastIdSelected}
                selectedQoutationId={selectedQoutationId}
                studentCurrentYear={studentCurrentYear}
                applicationId={id}
                setModificationType={setModificationType}
                setCmprAllowed={setCmprAllowed}
                setLowestNetPayable ={setLowestNetPayable}
                paidItems = {paidItems}
                isDefinedConcessionDirty = {isDefinedConcessionDirty}
                customSlab = {customSlab}
                activePaymentModes ={[...advancePaymentModes, upfrontPaymentModes]}
              />
            </Col>
            <Col md='3' style={{ paddingRight: 0 }}>
              <FeeBreakup
                values={{
                  masterLoading: masterLoading,
                  applicationDetailsLoading: applicationDetails.loading,
                  term: applicationDetails.termValue,
                  businessArea: applicationDetails.businessAreaId,
                  courseId: applicationDetails.courseId,
                  paymentStatus: applicationDetails.paymentStatus
                }}
                businessArea={businessAreas}
                term={[]}
                courses={courses}
                applicationId={applicationDetails.applicationId}
                concessionDetails={concessionDetails}
                feeBreakupData={feeBreakupData}
                itemTypes={itemTypes}
                handleChargesUpdate={handleChargesUpdate}
                isChargesFinializes={isChargesFinializes}
                finalBreakupData={finalBreakupData}
                allowChargesModification={allowChargesModification}
                pastIdSelected={pastIdSelected}
                selectedQoutationId={selectedQoutationId}
                studentCurrentYear={studentCurrentYear}
                paymentUpdated={paymentUpdated} 
                concessioUpdated={concessioUpdated}
                setPaymentUpdated={setPaymentUpdated}
                setConcessionUpdated={setConcessionUpdated}
                paymentDetails={paymentDetails}
                paidItems = {paidItems}
                cmpr={applicationDetails.cmpr}
                processedData = {processedData}
                setProcessedData={setProcessedData}

              />
            </Col>

          </Row>

          {/* <TemplateToPDF /> */}


          <Dialog
            fullWidth
            maxWidth={'md'}
            open={verifyChargesDialog}
          // onClose={() => handleVerifyChargesDialog(false)}
          // onBackdropClick={()=>{}}
          >
            <VerifyCharges
              applicationDetails={applicationDetails}
              concessionDetails={concessionDetails}
              paymentDetails={paymentDetails}
              finalBreakupData={finalBreakupData}
              finalizeCharges={finalizeCharges}
              courses={courses}
              handleVerifyChargesDialog={handleVerifyChargesDialog}
              allCategories={allCategories}
              applicationQuotationDetailsId={applicationDetails?.applicationQuotationDetailsId ?? paymentDetails?.id ?? null}
              getApplicationDetails={getApplicationDetails}
              getConcessionDetails={getConcessionDetails}
              getPaymentDetails={getPaymentDetails}
              businessAreas={businessAreas}
              setInfoModalOpen={setInfoModalOpen}
              docRequiredForCustomConcession={docRequiredForCustomConcession}
              paidItems = {paidItems}
              setIsOpen = {setIsOpen}
              studentCurrentYear = {studentCurrentYear}
            />
          </Dialog>
          <ConfirmationDialogSuccess
            type="info"
            msg={<span>Thanks for verifying the Charges for the Student <b>{`${applicationDetails?.studentName} ${applicationDetails?.psid}`}</b></span>}
            setIsOpen={setInfoModalOpen}
            isOpen={isInfoModalOpen}
            onAgree={() => { setInfoModalOpen(false); }}
            headerMsg={"Info"}
            headerMsgStyles={{alignSelf : 'self-start'}}
            buttonStyle={{float : 'left !important'}}
            msgClass={'text-regular-large'}
            nextBtnClasses={'no-ml-btn'}
            iconClass={'border-dark'}
            popupSymbol={<BsCheck2 style={{color : '#262626'}}/>}
          />

              <ConfirmationDialogSuccess
                type="info"
                msg={studentEligibilityInfo?.validationMessage}
                isOpen={studentEligibilityInfo?.isEligibleForChargeCreation === false}
                onAgree={() => {
                  window.close();
                  if(!window.closed) window.history.back();
                }}
                headerMsg={"Info"}
                popupSymbol={<IoInformation />}
            />
        </div></>) : <PermisionDenied />
  )
}

export default StudentChargesDetails;