import moment from 'moment';
import React, { useEffect, useState, useContext } from 'react';
import { Card, Col, Row } from 'reactstrap';
import Banner from 'assets/img/brand/login_banner.svg';
import Styles from './homepage.module.scss';
import { RoutesDetails, UserDetails } from 'appContext';
const HomePage = () => {
  const [isShow, setIsShow] = useState(false);
  const routes = useContext(RoutesDetails);
  const userDetails = useContext(UserDetails);

  const getCurrentDate = () => {
    let today = new Date();
    return moment(today).format('MMMM DD, YYYY');
  };

  const checkDetails = data => {
    data.views.map(item => {
      if (item?.collapse && item?.state === 'courseInformationCollapse') {
        setIsShow(true);
      }
    });
  };

  const showCard = () => {
    if (routes) {
      routes.map(item => {
        if (item?.collapse && item.name === 'Manage') {
          checkDetails(item);
        }
      });
    }
  };

  useEffect(() => {
    showCard();
  }, [routes]);

  console.log(process.env)

  return (
    <div className={Styles.homepage__container}>
      <Row className={Styles.homepage__user__row}>
        <Col md={12} className={Styles.homepage_user_col}>
          <div className={Styles.homepage__card__rows}>
            <h3 className={Styles.homepage__name__label}>
              Welcome to new HomePage
              Hello, {userDetails?.firstName} {userDetails?.lastName}
            </h3>
            <h1 className={Styles.homepage__welcome__title}>Welcome to the</h1>
            <h1 className={Styles.homepage__welcome__title}>
              Phoenix - ERP 2.0
            </h1>
            <h3 className={Styles.homepage__date}>{getCurrentDate()}</h3>
            <div>
              <img src={Banner} />
            </div>
          </div>
        </Col>
      </Row>
      {/* {isShow && (
        <div className={Styles.homepage_card_container}>
          <Row>
            <h1 className={Styles.homepage__feature__heading}>
              Feature Access
            </h1>
          </Row>
          <Row>
            <ExploreCard title={'Course & Pricing Service'} />
          </Row>
        </div>
      )} */}
    </div>
  );
};

export default HomePage;
