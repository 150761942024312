import React, { useEffect, useState, Fragment, useMemo } from "react";
import { Label, FormGroup, Row, Col, Input } from "reactstrap";
import moment from "moment";
import Select from "react-select";
import CustomDatePicker from '../../../../common/commonComponents/CustomDatePicker/index';
import { failureToast } from "../../../../common/utils/methods/toasterFunctions/function";

export const ExternalOrg = (props) => {
  const {
    formMode,
    externalOrganzationObj,
    setExternalOrganzationObj,
    organizationType,
    assocaitedAeslCampus,
    enableEditMode,
  } = props;
  const [isDisabled, setIsDisabled] = useState();
  const [AESLDisp, setAESLDisp] = useState();
  const [orgTypeDisp, setOrgTypeDisp] = useState();

  useEffect(() => {
    formMode === "new" || enableEditMode
      ? setIsDisabled(false)
      : setIsDisabled(true);
    if (!(formMode === "new") && externalOrganzationObj["organizationType"]) {
      getDisplayForOrganizationType(externalOrganzationObj["organizationType"]);
    }

    if (!(formMode === "new") && externalOrganzationObj["businessAreaId"]) {
      setDisplayValForAssociation();
    }
  }, [formMode, enableEditMode, organizationType, assocaitedAeslCampus]);

  const setDisplayValForAssociation = () => {
    try {
      const index =
        assocaitedAeslCampus &&
        assocaitedAeslCampus.findIndex((item) => {
          return item.value == externalOrganzationObj["businessAreaId"];
        });
      let tempValue = index >= 0 ? assocaitedAeslCampus[index]["label"] : "";
      setAESLDisp(tempValue);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const getDisplayForOrganizationType = (id) => {
    try {
      // console.log(
      //   "Display value for the organization : ",
      //   id,
      //   organizationType
      // );
      const index =
        organizationType &&
        organizationType.findIndex((item) => {
          return item.value === id;
        });
      let tempValue = index >= 0 ? organizationType[index]["label"] : "";
      setOrgTypeDisp(tempValue);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const OrganizationIdValidation = (value) => {
    try {
      let pattern = /^[A-Za-z]+[a-zA-z0-9-_]*$/;
      if (value.length < 1) return;
      if (!pattern.test(value))
        return failureToast(
          "Organization Id always start with Alphabet and special character ‘-' and '_’ allowed"
        );
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <Fragment>
      <Row>
        <Col sm={12} style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>
          <Row>
            <Col sm={6}>
              <FormGroup row>
                <Col sm={12}>
                  <Label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Organization ID
                  </Label>
                </Col>
                <Col sm={12}>
                  <input
                    data-testid='organizationId'
                    type="text"
                    maxLength={12}
                    disabled={!(formMode === "new") ? true : false}
                    Value={externalOrganzationObj.id}
                    className="form-control addresslocationInput"
                    onChange={(e) => {
                      let tempId = e.target.value;
                      setExternalOrganzationObj((prevState) => ({
                        ...prevState,
                        id: tempId,
                      }));
                      OrganizationIdValidation(e.target.value);
                    }}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup row>
                <Col sm={12}>
                  <Label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Effective Date
                  </Label>
                </Col>
                <Col sm={12}>
                <CustomDatePicker 
                    disabled={isDisabled}
                    data-testid='effectiveDate'
                    value={externalOrganzationObj["effectiveDate"]? moment(
                      externalOrganzationObj["effectiveDate"]
                    ).toDate():''}
                    index={0}
                    handler={(index,date,keyName) => {
                      let effectiveDateTemp = date;
                      setExternalOrganzationObj((prevState) => ({
                        ...prevState,
                        effectiveDate: effectiveDateTemp,
                      }));
                    }}
                    keyName='effectiveDate'
                    rangeFrom={1950}
                    rangeTo={2099}
                    placeholder="Example:01-01-2020"
                />
                  {/* <input
                    data-testid='effectiveDate'
                    onKeyDown={(e) => e.preventDefault()}
                    className="form-control addresslocationInput"
                    type="Date"
                    disabled={isDisabled}
                    value={moment(
                      externalOrganzationObj["effectiveDate"]
                    ).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      let effectiveDateTemp = e.target.value;
                      setExternalOrganzationObj((prevState) => ({
                        ...prevState,
                        effectiveDate: effectiveDateTemp,
                      }));
                    }}
                  /> */}
                </Col>
              </FormGroup>
            </Col>
            {/* <Col sm={4}>
                   <Row>
                     <Col sm={12} >                  
                      <Label>SF Sync</Label>   <input type="checkbox" checked/> 
                     </Col>
                    </Row>
                   <Row>
                   <Col sm={12}>
                       <Label>SF Sync Status Success</Label>
                    </Col>
                   </Row>
              </Col> */}
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={12} lg={6}>
          <FormGroup row>
            <Col sm={4} md={3} lg={12} xl={4}>
              <Label className="form-control-label">
                Organization Type
              </Label>
            </Col>
            <Col sm={8} md={9} lg={12} xl={8}>
              <Select
                placeholder="Select Organization Type"
                value={{
                  value: externalOrganzationObj["organizationType"],
                  label: orgTypeDisp,
                }}
                isDisabled={isDisabled}
                options={organizationType}
                onChange={(value) => {
                  setOrgTypeDisp(value.label);
                  props.setExternalOrganzationObj((prevState) => ({
                    ...prevState,
                    organizationType: value.value,
                  }));
                }}
              />
            </Col>
          </FormGroup>
        </Col>
        <Col md={12} lg={6}>
          <FormGroup row>
            <Col sm={4} md={3} lg={12} xl={4}>
              <Label className="form-control-label" for="associatedBU">
                Org ID Status
              </Label>
            </Col>
            <Col sm={8} md={9} lg={12} xl={8}>
              <Select
                name="associatedBU"
                id="associatedBU"
                placeholder="Select Org Id Status"
                value={{
                  value: externalOrganzationObj["status"],
                  label: externalOrganzationObj["status"],
                }}
                isDisabled={isDisabled}
                options={props.orgIdStatus}
                onChange={(value) => {
                  setExternalOrganzationObj((prevState) => ({
                    ...prevState,
                    status: value.value,
                  }));
                }}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <FormGroup row>
            <Col sm={4} md={3} lg={3} xl={2}>
              <Label
                className="form-control-label"
                htmlFor="example3cols2Input"
              >
                Organization Name
              </Label>
            </Col>
            <Col sm={8} md={9} xl={10}>
              <input
                data-testid='organizationName'
                className="form-control addresslocationInput"
                maxLength={200}
                disabled={isDisabled}
                value={externalOrganzationObj["organizationName"]}
                onChange={(value) => {
                  let orgNameTemp = value.target.value;
                  setExternalOrganzationObj((prevState) => ({
                    ...prevState,
                    organizationName: orgNameTemp,
                  }));
                }}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <FormGroup row>
            <Col sm={4} md={3} lg={3} xl={2}>
              <Label
                className="form-control-label"
                htmlFor="example3cols2Input"
              >
                Organization Short Name
              </Label>
            </Col>
            <Col sm={8} md={9} xl={10}>
              <input
                data-testid='organizationShortName'
                className="form-control addresslocationInput"
                maxLength={50}
                disabled={isDisabled}
                value={externalOrganzationObj["organizationShortName"]}
                onChange={(value) => {
                  let orgShortNameTemp = value.target.value;
                  setExternalOrganzationObj((prevState) => ({
                    ...prevState,
                    organizationShortName: orgShortNameTemp,
                  }));
                }}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <FormGroup row>
            <Col sm={12}>
              <Label
                className="form-control-label"
                htmlFor="example3cols2Input"
              >
                Proprietorship
              </Label>
            </Col>
            <Col sm={12}>
              <input
              data-testid='proprietership'
                disabled={isDisabled}
                value={externalOrganzationObj["proprietership"]}
                className="form-control addresslocationInput"
                onChange={(value) => {
                  let tempProp = value.target.value;
                  setExternalOrganzationObj((prevState) => ({
                    ...prevState,
                    proprietership: tempProp,
                  }));
                }}
              />
            </Col>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup row>
            <Col sm={12}>
              <Label
                className="form-control-label"
                htmlFor="example3cols2Input"
              >
                Contract Signing Date
              </Label>
            </Col>
            <Col sm={12}>
            <CustomDatePicker 
                disabled={isDisabled}
                data-testid='contractSigningDate'
                value={externalOrganzationObj["contractSigningDate"]? moment(
                  externalOrganzationObj["contractSigningDate"]
                ).toDate():''}
                index={1}
                handler={(index,date,keyName) => {
                  let contractSigningDateTemp = date;
                  setExternalOrganzationObj((prevState) => ({
                    ...prevState,
                    contractSigningDate: contractSigningDateTemp,
                    contractExpiryDate: "",
                  }));
                }}
                minDate={moment().subtract(50, "years").toDate()}
                maxDate={moment().add(50, "years").toDate()}
                keyName='contractSigningDate'
                rangeFrom={1950}
                rangeTo={2099}
                placeholder="Example:01-01-2020"
            />
              {/* <input
              data-testid='contractSigningDate'
                min={moment().subtract(50, "years").format("YYYY-MM-DD")}
                max={moment().add(50, "years").format("YYYY-MM-DD")}
                disabled={isDisabled}
                value={moment(
                  externalOrganzationObj["contractSigningDate"]
                ).format("YYYY-MM-DD")}
                onKeyDown={(e) => e.preventDefault()}
                className="form-control addresslocationInput"
                type="Date"
                onChange={(e) => {
                  let contractSigningDateTemp = e.target.value;
                  setExternalOrganzationObj((prevState) => ({
                    ...prevState,
                    contractSigningDate: contractSigningDateTemp,
                    contractExpiryDate: "",
                  }));
                }}
              /> */}
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <FormGroup row>
            <Col sm={12}>
              <Label
                className="form-control-label"
                htmlFor="example3cols2Input"
              >
                Associated AESL Campus
              </Label>
            </Col>
            <Col sm={12}>
              <Select
                placeholder="Select Associated AESL Campus"
                value={{
                  value: externalOrganzationObj["businessAreaId"],
                  label: AESLDisp,
                }}
                isDisabled={isDisabled}
                options={assocaitedAeslCampus}
                onChange={(value) => {
                  setAESLDisp(value.label);
                  setExternalOrganzationObj((prevState) => ({
                    ...prevState,
                    businessAreaId: value.value,
                  }));
                }}
              />
            </Col>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup row>
            <Col sm={12}>
              <Label
                className="form-control-label"
                htmlFor="example3cols2Input"
              >
                Contract Expiry Date
              </Label>
            </Col>
            <Col sm={12}>
              <CustomDatePicker 
                  disabled={
                    isDisabled
                      ? true
                      : externalOrganzationObj["contractSigningDate"] &&
                        formMode === "new"
                      ? false
                      : !isDisabled && !(formMode === "new")
                      ? false
                      : true
                  }
                  data-testid='contractExpiryDate'
                  value={externalOrganzationObj["contractExpiryDate"]? moment(
                    externalOrganzationObj["contractExpiryDate"]
                  ).toDate():''}
                  index={1}
                  handler={(index,date,keyName) => {
                    let contractExpiryDateTemp = date;
                    setExternalOrganzationObj((prevState) => ({
                      ...prevState,
                      contractExpiryDate: contractExpiryDateTemp,
                    }));
                  }}
                  minDate={moment(externalOrganzationObj["contractSigningDate"]).toDate()}
                  maxDate={moment().add(50, "years").toDate()}
                  keyName='contractSigningDate'
                  rangeFrom={1950}
                  rangeTo={2099}
                  placeholder="Example:01-01-2020"
              />
              {/* <input
               data-testid='contractExpiryDate'
                min={externalOrganzationObj["contractSigningDate"]}
                max={moment().add(50, "years").format("YYYY-MM-DD")}
                disabled={
                  isDisabled
                    ? true
                    : externalOrganzationObj["contractSigningDate"] &&
                      formMode === "new"
                    ? false
                    : !isDisabled && !(formMode === "new")
                    ? false
                    : true
                }
                value={moment(
                  externalOrganzationObj["contractExpiryDate"]
                ).format("YYYY-MM-DD")}
                onKeyDown={(e) => e.preventDefault()}
                className="form-control addresslocationInput"
                type="Date"
                onChange={(e) => {
                  let contractExpiryDateTemp = e.target.value;
                  setExternalOrganzationObj((prevState) => ({
                    ...prevState,
                    contractExpiryDate: contractExpiryDateTemp,
                  }));
                }}
              /> */}
            </Col>
          </FormGroup>
        </Col>
      </Row>
    </Fragment>
  );
};
