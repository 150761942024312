import moment from "moment";
import React from "react";
import {
  getFormattedDate,
  getFormattedTime,
} from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";

const getLastUpdatedOnAndUpdatedBy = (paymentDetails, concessionDetails) => {
  
  if (concessionDetails && concessionDetails.length == 0)
    return [paymentDetails?.updatedBy, paymentDetails?.updatedOn];
    // last concession 
    const lastConcessionItem = concessionDetails?.at(-1);
  return moment(paymentDetails?.updatedOn).isAfter(
    moment(lastConcessionItem?.updatedOn)
  )
    ? [paymentDetails?.updatedBy, paymentDetails?.updatedOn]
    : [lastConcessionItem?.updatedBy, lastConcessionItem?.updatedOn];
};

const ViewLastUpdatedBy = ({
  applicationStatus,
  paymentDetails,
  concessionDetails,
}) => {
  const [lastUpdatedBy, lastUpdatedOn] = getLastUpdatedOnAndUpdatedBy(
    paymentDetails,
    concessionDetails
  );

  const timeStamp = `${lastUpdatedBy} at ${getFormattedTime(
    lastUpdatedOn
  )} on ${getFormattedDate(lastUpdatedOn)}`;

  const isAppl = applicationStatus === "APPL";
  return timeStamp ? (
    isAppl ? (
      <div
        className="regular-bold mt-3 align-items-center d-flex"
        style={{ color: "#05945B" }}
      >
        <img
          src={require("../../../../../../../assets/img/svg/check-circle.svg")}
        />
        <span className="pl-2">{`  Charges finalized by ${timeStamp}`}</span>
      </div>
    ) : (
      <div className="regular-bold color-grey-60 mt-3">
        {`Last updated by ${timeStamp}`}
      </div>
    )
  ) : null;
};

export default ViewLastUpdatedBy;
