import React, { useContext, useState } from 'react';
import { Box, Checkbox, Dialog, Typography } from '@mui/material';
import closeIcon from 'assets/img/svg/closeSquare.svg';
import CustomButton from 'components/CustomButton';
import { Col, Row } from 'reactstrap';
import { getFormatedDate, getRequestPayload, getSuccessMsg, getUpdatedRow } from './common';
import { constants, endpoint } from '../../common/constant';
import { GoCheck } from 'react-icons/go';
import { useDispatch, useSelector } from 'react-redux';
import { deleteVerificationRow } from 'redux/concessionApproval/actions';
import VerificationPopup from './verificationPopup';
import { getApprovalRequests } from 'redux/concessionApproval/actions';
import { ReactComponent as QuestionIcon } from 'assets/img/svg/QuestionMarkConfirmationDialog.svg';
import { ReactComponent as CrossIcon } from 'assets/img/svg/CrossIcon.svg'
import CommonInput from '../../common/formFeilds/input/commonInput';
import { UserDetails } from 'appContext';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import { clearSelectedRowsIds } from 'redux/concessionApproval/actions';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import { getDocsStatus } from './common';
import { createBreakUpData, getBreakUp, getConsessionDetails, getEligibleComponentList, getFileUploadUrls } from '../studentCharges/studentChargesDetails/feeBreakup/common';
import { updateQuestionStatus } from '../studentCharges/studentChargesDetails/common';
import { getFeeBreakup } from '../studentCharges/studentChargesDetails/finalizeCharges';
import { getCourseFeeSummary } from '../studentCharges/studentChargesDetails/templates/mapping';
import { getCurrentYear } from '../studentCharges/studentChargesDetails/common';
import AButton from '../../common/form-fields-mui/AButton';

const DocReviewPopup = ({ handleClose = () => { }, requests = [], openPopOver = false, userPermissions , appliedFilters, setApproving}) => {

    const dispatch = useDispatch();
    const approvalState = useSelector(state => state.concessionApproval);
    const userDetails = useContext(UserDetails);
    
    const [currRequest, setCurrRequest] = useState(null);
    const [remarks, setRemarks] = useState('');
    const [bulkApprOpen, setBulkApprOpen] = useState(false);
    const [submitting,setSubmitting] = useState(false);

    const onVerificationPopupClose = (e, updateRequests=false) => {
        setCurrRequest(null);
        setRemarks('');
        if(updateRequests) {
            dispatch(getApprovalRequests({
                approvalStatus: `${approvalState.approverType.value}_${'pending'.toString()?.toUpperCase()}`,
                businessAreaValue: appliedFilters?.businessArea?.length ? appliedFilters?.businessArea : approvalState.allBusinessAreas.map(i => i.label),
                ...appliedFilters

            },approvalState?.currentPage));
        }
    }

    const handleActionClick = async (remarks) => {
        setSubmitting(true);
        const approvalStatus = constants.APPROVAL_STAGE.APPROVED;
        const approvalType = approvalState.approverType?.value;
        const request = approvalState.selectedVerificationRows.map(row => getUpdatedRow(row, approvalType, approvalStatus, remarks, userDetails));

        try {
            const response = await fetchAllPostPromisedData(endpoint.approval.status, request, 'put');
            if(response?.code === 200) {
                for(let i=0; i < response.data.length; i++){
                    setApproving(true);
                    if(response.data[i]?.chargeStatus === 'PAPP' && response.data?.[0]?.concessionDetailsDtoList?.[0]?.isApproved){
                        const p = Promise.all([
                            await fetchAllPromisedData(`${endpoint.getStudentChargesApplicationDetails}/${response.data?.[0]?.concessionDetailsDtoList?.[0]?.applicationId}/STUDENT_CHARGES`),
                            await fetchAllPromisedData(endpoint.item_type_setup.getAll, true),
                            await fetchAllPromisedData(`${endpoint.treeView}`, false),
                            await fetchAllPromisedData(`${endpoint.applicationQuotationDetails}/${response.data?.[0]?.concessionDetailsDtoList?.[0]?.applicationId}`, false),
                        ]).then(async(values) => {
                            const breakup = await fetchAllPromisedData(`${endpoint.feeBreakfromPricingByApplicationId}/${response.data?.[0]?.concessionDetailsDtoList?.[0]?.applicationId}?quotationId=${values[0]?.applicationQuotationDetailsId}`, true);
                            const {groupedData, itemTypeLabelMapping,total } = await getBreakUp(breakup,values?.[1]);
                            const studentCurrentYear = await getCurrentYear(values?.[0]);
                            const {totalConsession, concessionAppliedList } = await getConsessionDetails(response.data?.[0]?.concessionDetailsDtoList,total, studentCurrentYear);
                            const {tempData,totalTempData,percentPendingForApproval} = createBreakUpData(groupedData,totalConsession, concessionAppliedList, studentCurrentYear, response.data?.[0]?.concessionDetailsDtoList);
                            const feeBreakup = getFeeBreakup({data: tempData,total:totalTempData},response.data[i]?.quotationId)
                            const csummary = getCourseFeeSummary(values[0], {data: tempData,total: totalTempData}, response.data?.[i]?.concessionDetailsDtoList, approvalState.allBusinessAreas, approvalState.allCourses, values?.[2], values[0]?.loanSchemeMasterId);
                            await updateQuestionStatus('APPL', {}, csummary, {}, feeBreakup, null, values[0]?.applicationQuotationDetailsId, (data) => { },() => {}, approvalState.allCourses,true, (qid)=>getEligibleComponentList(qid,approvalState.allBusinessAreas.filter(i => i.value === (approvalState.selectedVerificationRows.find(q => q.applicationQuotationDetailsId === response.data[i]?.quotationId))?.applicationBusinessArea), approvalState.allCourses?.filter(i => i.value === (approvalState.selectedVerificationRows.find(q => q.applicationQuotationDetailsId === response.data[i]?.quotationId))?.courseId),values[0], response.data?.[0]?.concessionDetailsDtoList, studentCurrentYear,false),(...rest)=>getFileUploadUrls(...rest,false))
                            // successToast(getSuccessMsg(approvalStatus));
                            // dispatch((clearSelectedRowsIds()));
                            // dispatch(getApprovalRequests({
                            //     // roleId: approvalState.approverType.value,
                            //     approvalStatus: `${approvalState.approverType.value}_${stage.toString()?.toUpperCase()}`,
                            //     businessAreaValue: appliedFilters?.businessArea?.length ? appliedFilters?.businessArea : approvalState.allBusinessAreas.map(i => i.label)
                            // },approvalState.currentPage));
                        })
                    }
                }
                successToast(getSuccessMsg(approvalStatus));
                dispatch((clearSelectedRowsIds()));
                dispatch(getApprovalRequests({
                    approvalStatus: `${approvalState.approverType.value}_${'pending'.toString()?.toUpperCase()}`,
                    businessAreaValue: appliedFilters?.businessArea?.length ? appliedFilters?.businessArea : approvalState.allBusinessAreas.map(i => i.label)
                },approvalState?.currentPage));
                setBulkApprOpen(false);
                setSubmitting(false)
                handleClose();
                setApproving(false);
            } else {
                setSubmitting(false)
                failureToast("Details not saved.");
            }
        } catch(err) {
            setSubmitting(false)
            failureToast("Details not saved.");
        }
    }

    const isDisabled = requests.some(req => getDocsStatus(req, approvalState.allActiveConfigs) === constants.DOCUMENT_VERFICATION_STATUS.PENDING);
    const selectedRowsCount = Object.values(approvalState?.selectedRowIds || {}).reduce((acc, curr) => acc+curr.length, 0);
    const selectedVerificationRowsCount = approvalState?.selectedVerificationRows?.length;

    return (
        <>
            <Dialog open={openPopOver && requests?.length>0} onClose={handleClose}>
                <Box className='concession-approval-dialog-content document-review-dialog' width={515}>
                    <Box className='approval-dialog-header'>
                        <Typography variant='h6'>Document review pending</Typography>
                        <div onClick={handleClose}><img src={closeIcon} /></div>
                    </Box>
                    <Typography variant='body2'>Please review the documents of the following students before approving the concession.</Typography>
                    <Box className='document-review-requests'>
                        {requests.map(req => (
                            <Box className="requested-student">
                                <Box className='student-info'>
                                    <Typography variant='body2'>{req?.psid || ''}</Typography>
                                    <Typography variant='caption' className='uploaded-on'>{`Uploaded on ${getFormatedDate(req?.createdOn)}`}</Typography>
                                </Box>
                                <Box className='review-action-buttons'>
                                    {getDocsStatus(req, approvalState.allActiveConfigs) === constants.DOCUMENT_VERFICATION_STATUS.PENDING ?
                                        // <CustomButton
                                        //     permissionType={'R,A'}
                                        //     outline={true}
                                        //     content={'Review'}
                                        //     onClick={() => {if(!req?.isDocumentAttached){ failureToast("Document not uploaded.")}else{setCurrRequest(req)}}}
                                        //     permissionSet={userPermissions}
                                        //     forTable={true}
                                        // /> 
                                        <AButton
                                        variant='primary_filled'
                                        size='xs'
                                        onClick={() => {if(!req?.isDocumentAttached){ failureToast("Document not uploaded.")}else{setCurrRequest(req)}}}
                                        >
                                            Review
                                        </AButton>
                                        : <Typography variant='caption' className='document-reviewed-button' onClick={() => { }}>
                                            Reviewed <GoCheck size={15} color={'#0B442D'} />
                                        </Typography>}
                                    <Typography variant='caption' className='remove-from-list' onClick={() => dispatch(deleteVerificationRow(req?.applicationQuotationDetailsId))}>Remove from list</Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Typography variant='body2' className='document-review-warning'>In case of any fraud/mismatch in the document. Please remove the student from list.</Typography>
                    <CustomButton
                        className='approval-dialog-action-button document-review-approve-button'
                        content={'Proceed to approve'}
                        permissionType={'C,A'}
                        permissionSet={userPermissions}
                        onClick={() => setBulkApprOpen(true)}
                        disabled={isDisabled}
                    />
                </Box>
            </Dialog>

            {!!currRequest && <VerificationPopup openPopOver={!!currRequest} documentAttached={currRequest?.isDocumentAttached} applicationConcessionDetailsId={currRequest?.applicationConcessionDetailsId} applicationId={currRequest?.applicationId}  applicationQuotationDetailsId={currRequest?.applicationQuotationDetailsId} handleClose={onVerificationPopupClose} userPermissions={userPermissions} />}

            {/** Bulk Approve Dialog */}
            <Dialog open={bulkApprOpen} onClose={() => setBulkApprOpen(false)}>
                <Box className='concession-approval-dialog-content bulk-approve-dialog' width={515}>
                    <Box className='approval-dialog-header'>
                        <QuestionIcon color='#414141' />
                        <CrossIcon width={25} height={25} onClick={() => setBulkApprOpen(false)} />
                    </Box>
                    <Typography variant='h5' marginBottom={3}>{`Confirm concession approval for ${selectedVerificationRowsCount}/${selectedRowsCount} students`}</Typography>
                    <CommonInput
                        type={'textarea'}
                        defaultValue={remarks}
                        placeHolder={'Add remarks'}
                        maxLength={400}
                        onChange={value => setRemarks(value)}
                    />
                    <Box className='bulk-approve-buttons'>
                        <CustomButton
                            className='approval-dialog-action-button'
                            content={'Cancel'}
                            permissionType={'C,A'}
                            permissionSet={userPermissions}
                            onClick={() => setBulkApprOpen(false)}
                            disabled={false}
                            outline={true}
                        />
                        <CustomButton
                            className='approval-dialog-action-button'
                            content={submitting ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i>  :'Approve concesion'}
                            permissionType={'C,A'}
                            permissionSet={userPermissions}
                            onClick={() => handleActionClick(remarks)}
                            disabled={submitting}
                        />
                    </Box>
                </Box>
            </Dialog>
            {/** Bulk Approve Dialog ends */}
        </>

    )
}

export default DocReviewPopup;