import React, { useState, useEffect } from "react";
import { Input } from 'reactstrap';

const CustomTextArea = (props) => {

    const {
        maxLength,
        minLength,
        index,
        placeHolder,
        isDisabled = false,
        regex,
        regValidation,
        defaultValue,
        handler,
        keyName,
        rows = 2,
    } = props;

    const styles = { 
        overflow: 'hidden',
    }

    const [value, setValue] = useState("");

    useEffect(() => {
    setValue(defaultValue ?? "");
    }, [defaultValue]);

    return (
        <Input  
            type={"textarea"}
            rows={rows}
            className="tdTextarea"
            style={styles}
            minLength={minLength}
            maxLength={maxLength}
            key={"Textarea" + index}
            placeholder={placeHolder}
            value={value}
            disabled={isDisabled}
            onChange={(e) => {
            let value = e.target.value;
            if (regValidation) {
                if (regex.test(value)) {
                setValue(value);
                handler(index, value, keyName);
                }
            } else {
                setValue(value);
                handler(index, value, keyName);
            }
            }}
        />
    )
}

export default CustomTextArea;
