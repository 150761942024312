import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import CommonInput from "views/pages/manage/common/formFeilds/input/commonInput";
import { isEqual } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import moment from "moment";
import { Checkbox, FormControlLabel } from "@mui/material";

const styles = {
  container: (base) => ({
    ...base,
    flex: 1,
  }),
  menu: (base) => ({
    ...base,
    maxHeight: 150,
    position: "absolute",
    zIndex: 200000,
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 150,
    overflowY: "scroll",
  }),
};

const FeeBreakupForm = (props) => {
  const {
    action,
    data,
    feeBreakupData,
    setFeeBreakupData = () => { },
    index,
    saveRow = () => { },
    cancelRow = () => { },
    isSaveVisible,
    activeItemTypeData = [],
    isEditForm = false,
    isDisabled,
    courseStartDate,
    courseEndDate,
    totalYears,
    setDueDate = () => { },
    isExceptionalCourse,
    itemTypeData,
    removeRow = () => { }
  } = props;
   
  const [checkboxParam,setCheckoxParam] = useState()
  // const getPincodeInfo = (id) => {
  //   if(id){
  //       getAPI(`${masterServiceBaseUrl}/pincode/getPincodeByCity/${id}`,
  //       (data) => {
  //           setTimeout(3000)
  //           setAttendanceMachineData({ ...attendanceMachineData, stateId : data.data?.[0]?.stateId, pincode : data.data?.[0]?.id, country : data.data?.[0]?.countryId })
  //       },
  //       (data) => { failureToast(data['message']) })
  //   }
  // }

  // useEffect(() => {
  //    if(attendanceMachineData.pincode){
  //      const pinValues = getDisplayVal(attendanceMachineData.pincode,pincodeData)
  //      pinValues && setAttendanceMachineData({ ...attendanceMachineData, stateId : pinValues.stateId, city : pinValues.cityId , country : pinValues.countryId })
  //    }
  // }, [attendanceMachineData.pincode])

  const getCalculatedAmt = (e) => {
    try {
      const totalTax = isExceptionalCourse ? feeBreakupData.itemTypesId?.[index]?.['totalTaxAmount'] : data?.totalTaxAmount
      if (totalTax) {
        const baseAmt = Math.round(
          (e * 100) / (100 + (totalTax ?? 0))
        );
        const tax = Math.round(e - baseAmt);
        setFeeBreakupData({
          ...feeBreakupData,
          baseAmount:
            feeBreakupData.baseAmount !== ""
              ? { ...feeBreakupData.baseAmount, [index]: baseAmt }
              : { [index]: baseAmt },
          tax:
            feeBreakupData.tax !== ""
              ? { ...feeBreakupData.tax, [index]: tax }
              : { [index]: tax },
        });
      } else {
        setFeeBreakupData({
          ...feeBreakupData,
          baseAmount: { ...feeBreakupData.baseAmount, [index]: e },
          tax: { ...feeBreakupData.tax, [index]: 0 }
        });
      }
    } catch (e) {
      console.log("some goest wrong in cal culation");
    }
  };

  useEffect(() => {
    feeBreakupData?.finalAmount &&
      getCalculatedAmt(feeBreakupData?.finalAmount?.[index]);
  }, [feeBreakupData?.finalAmount?.[index]]);

  const handleCheckboxChange = (type, index) => (e) => {
    const isChecked = e.target.checked;
    if (type === 'cash') {
      setFeeBreakupData((prevState) => ({
        cash: { ...prevState.cash, [index]: isChecked },
        cheque: { ...prevState.cheque, [index]: !isChecked },
      }));
    } else {
      setFeeBreakupData((prevState) => ({
        cheque: { ...prevState.cheque, [index]: isChecked },
        cash: { ...prevState.cash, [index]: !isChecked },
      }));
    }
  };

  return (
    <tr key={data["id"] + "-class"}>
      {!isExceptionalCourse && <td className="text-center p-2 mx-1" style={{ width: "100px" }}>
        {Number(data["sno"]) + 1}
      </td>}
      <td
        className="text-center p-2 mx-1"
        colSpan={2}
        style={{ width: "350px" }}
      >
        {isExceptionalCourse ?
          <CommonInput
            type={'select'}
            forTable={true}
            id="course_information_ItemTypeSetupId"
            placeHolder={'Select ItemTypeSetupId'}
            defaultValue={feeBreakupData.itemTypesId?.[index]}
            data={itemTypeData}
            onChange={(value) => {
              setFeeBreakupData({ ...feeBreakupData, itemTypesId: { ...feeBreakupData.itemTypesId, [index]: value } });
            }}
          />
          : data["itemTypeSetupId"]}
      </td>
      <td className="text-center p-2 mx-1" style={{ width: "150px" }}>
        {isExceptionalCourse ? '0' : data["installmentNumber"]}
      </td>
      <td className="text-center p-2 mx-1">
        <CommonInput
          type="datePicker"
          forTable={true}
          defaultValue={feeBreakupData.dueDate?.[index] ?? ""}
          placeHolder="Due Date"
          id="manual_course_fee_setup_due_date"
          style={{ height: "38px" }}
          onChange={(e) => {
            isExceptionalCourse ?
              setFeeBreakupData({
                ...feeBreakupData,
                dueDate:
                  feeBreakupData.dueDate !== ""
                    ? { ...feeBreakupData.dueDate, [index]: e }
                    : { [index]: e },
              })
              :
              setDueDate(index, e, data["installmentNumber"]);
          }}
          isDisabled={isDisabled || (index !== 0 && !feeBreakupData?.year[index - 1]) || (isExceptionalCourse && !feeBreakupData?.itemTypesId?.[index]?.value)}
          minDate={courseStartDate && index == 0 ? moment(courseStartDate).toDate() : (feeBreakupData?.dueDate[index - 1] ?? new Date())}
          maxDate={courseEndDate ? moment(courseEndDate).toDate() : ''}
        />
      </td>
      <td className="text-center p-2 mx-1">
        <CommonInput
          type="text"
          forTable={true}
          defaultValue={feeBreakupData.baseAmount?.[index] ?? ""}
          placeHolder="Base Amount"
          id="manual_course_fee_setup_base_amount"
          style={{ height: "38px" }}
          isDisabled={true}
        />
      </td>
      <td className="text-center p-2 mx-1">
        <CommonInput
          type="text"
          forTable={true}
          defaultValue={feeBreakupData.tax?.[index] ?? ""}
          placeHolder="Tax"
          id="manual_course_fee_setup_tax"
          style={{ height: "38px" }}
          isDisabled={true}
        />
      </td>
      <td className="text-center p-2 mx-1">
        <CommonInput
          type="number"
          maxLength="7"
          forTable={true}
          defaultValue={feeBreakupData.finalAmount?.[index] ?? ""}
          placeHolder="Final Amount"
          id="manual_course_fee_setup_final_amount"
          style={{ height: "38px" }}
          // isDisabled={!feeBreakupData?.itemType?.value}
          onChange={(e) => {
           setFeeBreakupData({
              ...feeBreakupData,
              finalAmount:
                feeBreakupData.finalAmount !== ""
                  ? { ...feeBreakupData.finalAmount, [index]: e }
                  : { [index]: e },
            })
          }}
          isDisabled={isDisabled || !feeBreakupData.dueDate?.[index]}
        />
      </td>
      <td>
      <FormControlLabel
        sx={{ marginBottom: "0px" }}
        control={
          <Checkbox
            size="small"
            onChange={(e)=>{
              setFeeBreakupData((prev)=>({
                ...prev,
                cash:{ ...prev?.cash, [index]: e?.target?.checked }
              }))
            }}
            name="cash"
            checked={feeBreakupData?.cash?.[index]}
            disabled={isDisabled}
          />
        }
      />
      </td>
      <td>
      <FormControlLabel
        sx={{ marginBottom: "0px" }}
        control={
          <Checkbox
            size="small"
            onChange={(e)=>{
              setFeeBreakupData((prev)=>({
                ...prev,
                cheque:
                prev.cheque !== ""
                    ? { ...prev.cheque, [index]: e?.target?.checked }
                    : { [index]: e?.target?.checked  },
                    
              }))
            }}
            name="cheque"
            checked={feeBreakupData?.cheque?.[index]}
            disabled={isDisabled}
          />
        }
      />
      </td>
      <td className="text-center p-2 mx-1">
        <CommonInput
          type="number"
          forTable={true}
          maxLength="1"
          maxValue={totalYears}
          defaultValue={feeBreakupData.year?.[index] ?? ""}
          placeHolder="Year"
          id="manual_course_fee_setup_year"
          style={{ height: "38px" }}
          onChange={(e) => {
            // isEqual(action, "new")
            //   ? 
            setFeeBreakupData({
              ...feeBreakupData,
              year:
                feeBreakupData.year !== ""
                  ? { ...feeBreakupData.year, [index]: e }
                  : { [index]: e },
            })
            // : setFeeBreakupData({ ...feeBreakupData, year: e });
          }}
          isDisabled={isDisabled || !feeBreakupData.finalAmount?.[index]}
        />
      </td>
      {isExceptionalCourse && (
        <td className="text-center p-2 mx-1">
          <Button
            color="danger"
            size="sm"
            type="button"
            onClick={() => {
              removeRow(index);
            }}
          >
            <i className="fas fa-times" />
          </Button>
        </td>
      )}
    </tr>
  );
};

export default FeeBreakupForm;
