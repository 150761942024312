import React from "react";
import { Card, CardHeader, Input, Container, Button, Table, Row } from "reactstrap";
import { useState } from "react"
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function'
import { TDROW, MasterHeader } from "../../common/commonComponents/masterRow/masterRow";
import { masterServiceBaseUrl, putpost, putpost2 } from 'services/http'
import moment from 'moment';
import CreateButton from "components/FormComponent/Button/New/index";
import UpdateButton from "components/FormComponent/Button/Update/index";
import { ButtonText } from "variables/Buttons";
import CustomButton from "components/CustomButton";
import { regex } from "../../common/constant";

var _ = require('lodash');

const RegionTable = (props) => {
  const { userPermissions, isSaveVisible, setisSaveVisible = () => { } } = props;
  const [tableDataKey, settableDataKey] = useState('tableDataKey')
  const addNewClass = () => {
    props.setclassArray([{ "editMode": true, "__status": "__new", "regionKey": null, 'status': 'ACTIVE', "regionDesc": null, "regionDispValue": null, "effectiveDate": null }, ...props.classArray])
  }
  const saveAll = (cb) => {
    var _tempArray = props.classArray
    var isValid = true
    _tempArray.forEach((el, index) => {
      if (el['editMode']) {
        if (el['regionKey'] == null || !el['regionKey'].trim()) {
          isValid = false
          failureToast('Please enter the value of Region Key')
          return
        } else if (el['regionDesc'] == null || !el['regionDesc'].trim()) {
          isValid = false
          failureToast('Please enter the value of Description')
          return
        }
        else if (el['regionDispValue'] == null || !el['regionDispValue'].trim()) {
          isValid = false
          failureToast('Please enter the value of Display Value')
          return
        }
        if (!isValid) {
          return
        }
        if (_tempArray[index]['__status'] == "__new") {
          putpost(masterServiceBaseUrl + '/region/createRegion', (data) => {
            successToast(data['message'])
            setisSaveVisible(false)
            props.fetchClasses(masterServiceBaseUrl + '/region/getAllRegion')
          }, (data) => { failureToast(data['message']) }, _tempArray[index], 'post')
        } else {
          putpost(masterServiceBaseUrl + '/region/updateRegion/' + _tempArray[index].id, (data) => {
            successToast(data['message'])
            setisSaveVisible(false)
            delete _tempArray[index]['editMode']
            props.setclassArray(_tempArray)
            settableDataKey(new Date().getMilliseconds())
            // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
          }, (data) => { failureToast(data['message']) }, _tempArray[index], 'put')
        }
      } else {

      }
    })
  }
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes")
    }
    else {
      let cb = () => {
        addNewClass()
        setisSaveVisible(true)
        // settableDataKey(new Date().getMilliseconds())
      }
      var _tempArray = props.classArray
      _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
      props.setclassArray(_tempArray)
      setisSaveVisible(false)
      cb()
    }
  }
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes")
    }
    else {
      let cb = () => {
        var _tempArray = props.classArray
        _tempArray[index]['editMode'] = true
        props.setclassArray(_tempArray)
        setisSaveVisible(true)
      }
      var _tempArray = props.classArray
      _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
      cb()
    }
  }
  const deleteRow = (index) => {
    if (props.classArray[index]["__status"] == "__new") {
      let n = props.classArray
      n.splice(index, 1)
      props.setclassArray(n)
      setisSaveVisible(false)
      settableDataKey(new Date().getMilliseconds())
    } else {
      if (window.confirm('Are you sure you want to delete this Region?')) {
        putpost2(masterServiceBaseUrl + '/region/deleteRegion/' + props.classArray[index].id, (data) => {
          successToast(data['message'])
          if (props.classArray.length == 1) {
            props.previousPage()
          } else {
            var _tempArray = props.classArray
            if (_tempArray[index]['editMode']) {
              setisSaveVisible(false)
            }
            _tempArray.splice(index, 1)
            props.setclassArray(_tempArray)
          }
          props.fetchClasses(masterServiceBaseUrl + '/region/getAllRegion')
          settableDataKey(new Date().getMilliseconds())
        }, (data) => { failureToast(data['message']) }, 'PUT')
      }
    }
  }
  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray
    _tempArray[index][key] = value
    props.setclassArray(_tempArray)
  }
  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Region</h3>
                <CustomButton
                  disabled={isSaveVisible}
                  className={'floatRight mx-1' + (isSaveVisible ? ' btn-dark cursor-gray' : null)}
                  content={'New Region'}
                  permissionType={'C'}
                  icon={true}
                  onClick={() => { newRow(); }}
                  permissionSet={userPermissions}
                />
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout" >
                  <MasterHeader type={"Region"} permissionSet={userPermissions} isSaveVisible={isSaveVisible} />
                  <tbody className="list" key={tableDataKey} >
                    {props.classArray ? props.classArray.map((el, index) =>
                      !el['editMode'] ? (
                        <TDROW type={"region"} index={index} el={el} editRow={editRow} deleteRow={deleteRow} permissionSet={userPermissions} isSaveVisible={isSaveVisible} />
                      ) :
                        (<tr key={index + '-class'}>
                          <td className="text-center p-2 mx-1">
                            <Input 
                              maxLength={50} 
                              defaultValue={el['regionKey']} 
                              onKeyPress={(e) => {
                                if (!regex.regionAllowedSpecialChars.test(String.fromCharCode(e.charCode))) {
                                  e.preventDefault();
                                  return;
                                }
                              }}
                              onChange={(e) => { updateKey(index, e.target.value, 'regionKey') }} 
                              placeholder="Example:1" className="tdInput" 
                            />
                          </td>
                          <td className="text-center p-2 mx-1"><Input disabled={true} defaultValue={el["__status"] == "__new" ? null : moment(el['effectiveDate']).format('DD-MM-YYYY')} placeholder="Example:01-01-2020" className="tdInput" /></td>
                          <td className="text-center p-2 mx-1"><Input maxLength={50} defaultValue={el['regionDesc']} onChange={(e) => { updateKey(index, e.target.value, 'regionDesc') }} placeholder="Description" className="tdInput" /></td>
                          <td className="text-center p-2 mx-1">
                            <Input 
                              maxLength={50} 
                              defaultValue={el['regionDispValue']} 
                              onKeyPress={(e) => {
                                if (!regex.regionAllowedSpecialChars.test(String.fromCharCode(e.charCode))) {
                                  e.preventDefault();
                                  return;
                                }
                              }}
                              onChange={(e) => { updateKey(index, e.target.value, 'regionDispValue') }} 
                              placeholder="Display Value" 
                              className="tdInput" 
                              disabled ={!!el['id']}
                            />
                          </td>
                          <td className="text-center p-2 mx-1">
                            <label className="custom-toggle mx-auto ml-2">
                              <input checked={el.status == "ACTIVE" ? true : false} type="checkbox" id={el.id} onChange={(e) => { settableDataKey(new Date().getMilliseconds()); console.log(e.target.checked); let n = "ACTIVE"; if (!e.target.checked) { n = "INACTIVE" } updateKey(index, n, 'status') }} />
                              <span style={{ width: "72px" }} className="custom-toggle-slider rounded-circle activeToggle" data-label-off="Inactive" data-label-on="Active" />
                            </label>
                          </td>
                          <td className="text-center">
                            {isSaveVisible ? <>
                              <CustomButton
                                content={'Save'}
                                permissionType={'C,U'}
                                icon={false}
                                forTable={true}
                                onClick={() => { saveAll() }}
                                permissionSet={userPermissions}
                              />
                              <CustomButton
                                content={''}
                                permissionType={'cancel'}
                                icon={true}
                                forTable={true}
                                onClick={() => {
                                  let n = props.classArray
                                  if (n[index]['__status'] == '__new') {
                                    n.splice(index, 1)
                                  } else {
                                    // n[index]['editMode']=false
                                    props.fetchClasses(masterServiceBaseUrl + '/region/getAllRegion')
                                  }
                                  props.setclassArray(n)
                                  setisSaveVisible(false)
                                }}
                                permissionSet={userPermissions}
                              /> </> :
                              <CustomButton
                                content={''}
                                permissionType={'D'}
                                icon={true}
                                forTable={true}
                                onClick={() => deleteRow(index)}
                                permissionSet={userPermissions}
                              />
                            }
                          </td>
                        </tr>)
                    ) : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
export default RegionTable;