import React, { useState } from "react";
import ListComponent from "./ListComponent";
import ALoader from "views/pages/manage/common/a-loader";
import { useEffect } from "react";
import { isAllTestCompleted } from "./../helper";

const TimetableTestList = ({
  setOpenForm,
  formData,
  formHandler,
  tests,
  setTests,
  batchDetails,
  testUpdateHandler,
  ...props
}) => {
  const OPEN_MODE = props?.mode || "NEW";
  const [apiLoader, setApiLoader] = useState(false);
  const [isReadyToPublish, setIsReadyToPublish] = useState(false);

  useEffect(() => {
    setIsReadyToPublish(isAllTestCompleted(tests));
    props.setPlanTests(tests);
  }, [tests]);

  return (
    <div style={{ position: "relative" }}>
      {apiLoader && <ALoader position={"fixed"} />}
      <ListComponent
        testsArray={tests}
        setTests={setTests}
        setOpenForm={setOpenForm}
        roomsDropdown={props.roomsDropdown}
        testUpdateHandler={testUpdateHandler}
        isReadyToPublish={isReadyToPublish}
        publishTimetable={props?.publishTimetable}
        openMode={OPEN_MODE}
        batchName={batchDetails?.batchName}
        formData={formData}
        {...props}
      />
    </div>
  );
};

export default TimetableTestList;
