import React, {Fragment, useEffect, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Collapse, FormGroup, Input, Label, Row} from "reactstrap";


const RelatedDocumentHeader =(props)=>{
    const {data} = props
    return(
        <Fragment>
            <Row>
                <Col span={6}>
                    <FormGroup>
                        <Label className="form-control-label">Person Type</Label>
                        <Input
                            value={data.personType}
                            disabled
                        />
                    </FormGroup>
                </Col>
                <Col span={6}>
                    <FormGroup>
                        <Label className="form-control-label">Process Type</Label>
                        <Input
                            defaultValue={data.processType}
                            disabled />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <FormGroup>
                        <Label className="form-control-label">Student Id</Label>
                        <Input
                            value={data.studentId}
                            disabled
                        />
                    </FormGroup>
                </Col>
                <Col span={6}>
                    <FormGroup>
                        <Label className="form-control-label">Student Name</Label>
                        <Input
                            defaultValue={data.studentName}
                            disabled />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <FormGroup>
                        <Label className="form-control-label">Term</Label>
                        <Input
                            value={data.term}
                            disabled
                        />
                    </FormGroup>
                </Col>
                <Col span={6}>
                    <FormGroup>
                        <Label className="form-control-label">Application Id</Label>
                        <Input
                            defaultValue={data.applicationId}
                            disabled />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <FormGroup>
                        <Label className="form-control-label">Opportunity Id</Label>
                        <Input
                            value={data.opportunityId}
                            disabled
                        />
                    </FormGroup>
                </Col>
                <Col span={6}>
                    <FormGroup>
                        <Label className="form-control-label">Business Area</Label>
                        <Input
                            defaultValue={data.businessArea}
                            disabled />
                    </FormGroup>
                </Col>
            </Row>
        </Fragment>
    )

}

export default RelatedDocumentHeader;