import React from "react";
import {withRouter} from "react-router-dom"
import { Card, Table, Button } from "reactstrap";
import { MasterHeaderAuto, Rows } from "../../../common/commonComponents/masterHeader/masterHeader";
import { openInNewTab } from '../../../common/utils/methods/commonMethods/utilityMethod';

const SearchTable = (props) => {
  const {data} = props;

  const headerList = [
    { name: "S.No" },
    { name: "Process ID" },
    { name: "Description" },
    { name: "File Name" },
    { name: "Status" },
    { name: "Error" },
    { name: "Actions" },
  ];

  const listToRender = [
    'sno',
    'processInstanceId',
    'description',
  ];
  return (
    <Card  className="mt-4">
      <div className="p-4">
        <Table className="align-items-center  tableLayout">
          <MasterHeaderAuto headerList={headerList} />
          <tbody className="list">
          {data ? 
            data.map((el, index) => {
              return (
                <Rows data={{...el,sno : index+1}} listToBeRendered={listToRender}>
                  <td className="text-center  white-breakSpace">
                    <span onClick={()=>window.open(`${el.filePath}`)} style={{cursor:'pointer'}} className="text-info">{el.fileName}</span>
                  </td>
                  <td className="text-center  white-breakSpace">
                    {el?.fileProcessingStatus && el.fileProcessingStatus.toUpperCase()}
                  </td>
                  <td className="text-center  white-breakSpace">
                    {el.fileProcessingStatus === 'Failed' ? 
                      <Button
                        color="info"
                        size="sm"
                        type="button"
                        onClick={() => window.open(`${el.errorPath}`)}
                      >
                        <i className="fas fa-download" />
                      </Button>
                    : null  
                  }
                  </td>
                  <td className="white-breakSpace d-flex ">
                    <div className="offset-3">
                   {el.fileProcessingStatus==='Success' ?  <Button
                      color="info"
                      size="sm"
                      type="button"
                      onClick={() => openInNewTab(`/view/${el.processInstanceId}`)}
                    >
                      <i className="fas fa-eye" />
                    </Button> : null}
                   {el.fileProcessingStatus==='Success' && !(el?.dataStatus==='Submitted') ?  <Button
                      color="info"
                      size="sm"
                      type="button"
                      onClick={() => props.history.push(`/admin/processInstanceSearch/edit/${el.processInstanceId}`)}
                    >
                      <i className="fas fa-edit" />
                    </Button> : null}
                    </div>
                  </td>
                </Rows>
              )}) 
            : <span>No Record Found</span>}
          </tbody>
        </Table>
        <div className="d-flex justify-content-between ">
          <Button
            color="info"
            size="sm"
            type="button"
            className={""}
            // onClick={reset}
          >
            Submit Now
          </Button>
          <Button
            color="info"
            size="sm"
            type="button"
            className={""}
            // onClick={submitHandler}
          >
            Submit Later
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default withRouter(SearchTable);
