import React, { useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import AAutoComplete from 'views/pages/manage/common/form-fields-mui/AAutoComplete'
import AButton from 'views/pages/manage/common/form-fields-mui/AButton'
import { ReactComponent as CloseIcon } from '../../../../../assets/img/svg/CloseIcon.svg'
import { getDropdownIdsFromSelectArray } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod'
import { fetchBusinessAreasByRegions } from 'views/pages/manage/academicPlanAndTest/constant/common'
import { dayDropdownData, holidayTypeDropdownData } from './common'
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper'

const SearchFilterForm = ({
  closeForm,
  removeFilterHandler = () => { },
  applyFilterHandler = () => { },
  filterFormHandler = () => { },
  filterForm,
  filterCount,
  businessAreaData,
  setBusinessAreaData,
  dropdowns,
}) => {

  const [businessAreaLoader, setBusinessAreaLoader] = useState(false);
  const fetchBusinessArea = async (regionsArray) => {
    if (regionsArray?.length > 0) {
      setBusinessAreaLoader(true);
      const responseData = await fetchBusinessAreasByRegions(getDropdownIdsFromSelectArray(regionsArray));
      setBusinessAreaData(responseData);
      setBusinessAreaLoader(false);
    } else {
      setBusinessAreaData([]);
    }
  }

  return (
    <Card className='mb-0' style={{ width: '500px' }}>
      <CardBody className='d-flex flex-column'>
        <div className='mb-4 d-flex align-items-start justify-content-between'>
          <div className='heading-4 label-color'>Filter</div>
          <IconButtonWrapper onClick={closeForm} ><CloseIcon /></IconButtonWrapper>
        </div>
        <div>
          <Row>
            <Col className='mb-2' sm='6'>
              <AAutoComplete
                label="Holiday type"
                currentValue={filterForm?.holidayType}
                items={holidayTypeDropdownData}
                handleChange={(value) => {
                  filterFormHandler(value, 'holidayType');
                }}
                isShowCount={true}
              />
            </Col>
            <Col className='mb-2' sm='6'>
              <AAutoComplete
                label="Day"
                currentValue={filterForm?.day}
                items={dayDropdownData}
                handleChange={(value) => {
                  filterFormHandler(value, 'day');
                }}
                isShowCount={true}
              />
            </Col>
            <Col className='mb-2' sm='6'>
              <AAutoComplete
                label="Region"
                currentValue={filterForm?.region}
                items={dropdowns?.region}
                handleChange={(value) => {
                  filterFormHandler(value, 'region');
                  filterFormHandler([], 'businessArea');
                  fetchBusinessArea(value);
                }}
                menuPlacement='auto'
                isShowCount={true}
              />
            </Col>
            <Col className='mb-2' sm='6'>
              <AAutoComplete
                label="Business area"
                currentValue={filterForm?.businessArea}
                items={businessAreaData}
                handleChange={(value) => filterFormHandler(value, 'businessArea')}
                isDisabled={!filterForm?.region?.length}
                isShowCount={true}
                isLoading={businessAreaLoader}
              />
            </Col>
            <Col className='mb-2' sm='6'>
              <AAutoComplete
                label="State"
                currentValue={filterForm?.state}
                items={dropdowns?.state}
                handleChange={(value) => filterFormHandler(value, 'state')}
                isShowCount={true}
              />
            </Col>
            <Col className='mb-2' sm='6'>
              <AAutoComplete
                label="City"
                currentValue={filterForm?.city}
                items={dropdowns?.city}
                handleChange={(value) => filterFormHandler(value, 'city')}
                isShowCount={true}
              />
            </Col>
          </Row>
        </div>
        <div className='mt-2 d-flex flex-row-reverse justify-content-between'>
          <AButton
            variant='primary_filled'
            className='button_remove_left_margin'
            onClick={() => { closeForm(); applyFilterHandler(); }}
          >
            Apply
          </AButton>
          {filterCount > 0 && (
            <AButton
              variant='outline'
              className='button_remove_left_margin'
              updatedStyle={{ border: "1px solid #CC5F5F", color: '#CC5F5F' }}
              onClick={() => { closeForm(); removeFilterHandler(); }}
            >
              Remove filter
            </AButton>
          )}
        </div>
      </CardBody>
    </Card>
  )
}

export default SearchFilterForm