import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import React from "react";
import Divider from "@mui/material/Divider";

const MenuTabs = ({
  onTabChangeHandler,
  selectedTab = "Issuance",
  academicType,
}) => {
  const tabs = [
    "Issuance",
    "Return",
    "Branch consumption",
    "Branch consumption return",
    ...(academicType === "RCC" ? ["Reversal"] : []),
  ];
  return (
    <div>
      <TabContext value={selectedTab}>
        <TabList
          sx={{
            backgroundColor: "#FFFFFF",
            "& .MuiTabs-flexContainer": {
              gap: "12px",
            },
            "& .MuiTab-root": {
              color: "#757575",
              fontSize: "16px",
              textTransform: "capitalize",
            },
            "& .MuiTab-root.Mui-selected": {
              color: "#0F0F0F",
              borderBottom: "3px solid #00B0F5",
            },
            "& .MuiTabs-indicator": {
              display: "none",
            },
            "& button": {
              textTransform: "capitalize",
            },
          }}
          onChange={onTabChangeHandler}
          centered
        >
          {tabs.map((tab, index) => (
            <Tab
              key={`${tab}-${index}`}
              className="ml-0"
              label={tab}
              value={tab}
            />
          ))}
        </TabList>
        <Divider />
      </TabContext>
    </div>
  );
};

export default MenuTabs;
