export const FETCH_ACADEMIC_MAP_LIST_REQUEST = 'FETCH_ACADEMIC_MAP_LIST_REQUEST'
export const FETCH_ACADEMIC_MAP_LIST_SUCCESS = 'FETCH_ACADEMIC_MAP_LIST_SUCCESS'
export const FETCH_ACADEMIC_MAP_LIST_FAILURE = 'FETCH_ACADEMIC_MAP_LIST_FAILURE'

export const ADD_ACADEMIC_SUB_MAP_REQUEST = 'ADD_ACADEMIC_SUB_MAP_REQUEST'
export const ADD_ACADEMIC_SUB_MAP_SUCCESS = 'ADD_ACADEMIC_SUB_MAP_SUCCESS'
export const ADD_ACADEMIC_SUB_MAP_FAILURE = 'ADD_ACADEMIC_SUB_MAP_FAILURE'


export const UPDATE_ACADEMIC_SUB_MAP_REQUEST = 'UPDATE_ACADEMIC_SUB_MAP_REQUEST'
export const UPDATE_ACADEMIC_SUB_MAP_SUCCESS = 'UPDATE_ACADEMIC_SUB_MAP_SUCCESS'
export const UPDATE_ACADEMIC_SUB_MAP_FAILURE = 'UPDATE_ACADEMIC_SUB_MAP_FAILURE'
export const UPDATE_MAPPING_DROPDOWNS = 'UPDATE_MAPPING_DROPDOWNS'