import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import moment from 'moment';
import Select2 from 'react-select2-wrapper';
import CustomButton from "../../../../../../components/CustomButton";
import CommonInput from "../../../common/formFeilds/input/commonInput";

const RenderHeader = ({ list }) => {
	return (
		list &&
		list.map((item, index) => {
			return (
				<div className="form-row mb-3" key={index}>
					<Col className="mb-3" md="6">
						<label className="form-control-label">
							{item && item[0]['label']}
						</label>
						<Input
							value={item && item[0]['value']}
							disabled
							style={{ height: '39px' }}
						/>
					</Col>
					{item[1] ? (
						<Col className="mb-3" md="6">
							<label className="form-control-label">{item[1]['label']}</label>
							<Input
								value={item[1]['value']}
								disabled
								style={{ height: '39px' }}
							/>
						</Col>
					) : null}
				</div>
			);
		})
	);
};

const HeaderCourseCatalog = (props) => {
	const { headerData = [], SaveHeaderData, setIsEdit, isEdit, action, permissionSet } = props;
	const [selectedStatus, setSelectedStatus] = useState(null);
	const editData = () => {
		setIsEdit(true);
	};

	const status = [
		{ id: 'ACTIVE', text: 'ACTIVE', desc: 'ACTIVE' },
		{ id: 'INACTIVE', text: 'INACTIVE', desc: 'INACTIVE' },
	];

	useEffect(() => {
		if (headerData && headerData[3]['value']) {
			setSelectedStatus(headerData[3]['value']);
		}
	}, []);
	const cancel = () => {
		setIsEdit(false);
		if (headerData && headerData[3]['value']) {
			setSelectedStatus(headerData[3]['value']);
		}
	};


	return (
		<>
			<div className="form-row mb-3" key={'test'}>
				<Col className="mb-3" md="6">
					<CommonInput label={headerData && headerData[0]['label']}
								 type={'text'}
								 isDisabled={true}
								 id="businessGroupDisabled"
								 placeHolder={'Business Group'}
								 defaultValue={headerData && headerData[0]['value']}
					/>
				</Col>
				<Col className="mb-3" md="6">
					<CommonInput label={headerData[1]['label']}
								 type={'text'}
								 isDisabled={true}
								 id="CompanyCodeDisabled"
								 placeHolder={'Company Code'}
								 defaultValue={headerData[1]['value']}
					/>
				</Col>
				<Col className="mb-3" md="6">
					<CommonInput label={headerData && headerData[2]['label']}
								 type={'text'}
								 isDisabled={true}
								 id="AcademicCareerDisabled"
								 placeHolder={'Academic Career'}
								 defaultValue={headerData && headerData[2]['value']}
					/>
				</Col>
				<Col className="mb-3" md="6">
					<CommonInput label={headerData && headerData[3]['label']}
								 type={!isEdit ?  'text' : 'reactSelect'}
								 isDisabled={!isEdit }
								 id="statusDisabled"
								 placeHolder={'Status'}
								 data={status}
								 defaultValue={selectedStatus}
								 onChange={(sdata)=>{setSelectedStatus(sdata)}}
					/>
				</Col>
			</div>
			<Row>
				<Col xs={12}>
					{!isEdit ? (
						action === 'view' ? null : (
							// <Button
							// 	color="info"
							// 	size="sm"
							// 	className="floatRight mx-1"
							// 	onClick={() => editData()}
							// >
							// 	Edit
							// </Button>
							<CustomButton
								className={'floatRight mx-1'}
								content={'Edit'}
								permissionType={'U'}
								permissionSet={permissionSet}
								onClick={() => editData()}
							/>
						)
					) : (
						<>
							{/*<Button*/}
							{/*	color="info"*/}
							{/*	size="sm"*/}
							{/*	className="floatRight mx-1"*/}
							{/*	onClick={() => cancel()}*/}
							{/*>*/}
							{/*	Cancel*/}
							{/*</Button>*/}
							<CustomButton
								className={'floatRight mx-1'}
								content={'Cancel'}
								permissionType={'cancel'}
								permissionSet={permissionSet}
								onClick={() => cancel()}
							/>
							{/*<Button*/}
							{/*	color="info"*/}
							{/*	size="sm"*/}
							{/*	className="floatRight mx-1"*/}
							{/*	onClick={() => SaveHeaderData(selectedStatus)}*/}
							{/*>*/}
							{/*	Save*/}
							{/*</Button>*/}
							<CustomButton
								className={'floatRight mx-1'}
								content={'Save'}
								permissionType={'C,U'}
								permissionSet={permissionSet}
								onClick={() => SaveHeaderData(selectedStatus)}
							/>
						</>
					)}
				</Col>
			</Row>
		</>
	);
};

export default HeaderCourseCatalog;
