import React, { useEffect, useState } from "react";
import { Dialog, InputLabel, Tooltip } from "@mui/material";
import AButton from "../../../../common/form-fields-mui/AButton";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";

import { Col, Row } from "reactstrap";
import AAutoComplete from "../../../../common/form-fields-mui/AAutoComplete";
import CustomDatePicker from "../../../../common/commonComponents/CustomDatePicker";
import { InputLabelStyle } from "../../../../common/form-fields-mui/ASelect";
import AInput from "../../../../common/formFeilds/customInput/AInput";
import ALoader from "../../../../common/a-loader";
import { getFormattedDate } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";

import { fetchAllPromisedData } from "../../../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint, regex } from "../../../../common/constant";
import moment from "moment";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx";
import { useGetDispatchSchedular } from "../../customhooks";
import ShowDispatchedChip from "../../dlpStockIssuance/issueStock/ShowDispatchedChip";
import ViewDispatchHistory from "../../dlpStockIssuance/issueStock/ViewDispatchHistory";

const inputStyle = { borderRadius: "4px", minHeight: "41px" };

const selectStyle = { control: { borderRadius: inputStyle?.borderRadius } };

const getMaterialWeights = ({ materialDetails }) => {
  if (Array.isArray(materialDetails)) {
    const materialsWeight = materialDetails.reduce(
      (acc, item) => acc + item?.materialCount * item?.materialWeight,
      0
    );
    return materialsWeight ? materialsWeight.toFixed(3) : "";
  }
  return "";
};

function AddDispatchDetails({
  open,
  setOpen,
  dispatchId,
  addDispatchDetailsApiCall,
  loader,
  currentRowData,
  getPsid,
}) {
  const [courierDropdown, setCourierDropdown] = useState([]);
  const [dispatchDetails, flag] = useGetDispatchSchedular(
    currentRowData?.dispatchScheduleId
  );
  const [isLoading, setListLoader] = useState(false);

  useEffect(() => {
    fetchCourierList();
  }, []);

  const fetchCourierList = async () => {
    setListLoader(true);
    const res = await fetchAllPromisedData(
      `${endpoint.courierVendorMaster.getAll}?academicCareer=DIGITAL`,
      true
    );
    if (res?.code === 200) {
      setCourierDropdown(
        res?.data.map((item) => ({
          label: item?.courierName,
          value: item?.courierName,
        }))
      );
    } else {
      setCourierDropdown([]);
    }
    setListLoader(false);
  };

  const saveHandler = (form, setForm) => {
    const status = currentRowData?.isReDispatched
      ? "RE_DISPATCHED"
      : "DISPATCHED";
    const request = {
      dispatchDetails: { ...form, courierName: form["courierName"]?.value },
      status,
      id: dispatchId,
      psid: getPsid(currentRowData?.psid, dispatchId),
    };

    addDispatchDetailsApiCall(
      request,
      {
        applicationIds: [currentRowData?.applicationId],
        nextStatus: currentRowData?.nextStatus,
      },
      () => clearDataCb(setForm)
    );
  };
  const validateForm = (form) => {
    const keys = [
      // "rate",
      "weight",
      "cno",
      // "regd",
      // "pod",
      "dispatchDate",
      "amount",
    ];

    if (!form?.courierName?.value) {
      return true;
    }

    for (const key of keys) {
      if (!(form[key] && form[key].toString().trim())) {
        return true;
      }
    }

    return false;
  };

  const clearDataCb = (setForm) => {
    setForm({});
    setOpen(false);
  };

  const Form = ({ disabled, data }) => {
    const [form, setForm] = useState({
      ...data,
      dispatchDate: data?.dispatchDate || moment(),
      weight: getMaterialWeights(dispatchDetails),
    });

    const formHandler = (value, key) => {
      setForm((prev) => ({ ...prev, [key]: value }));
    };

    return (
      <>
        <Row>
          {isLoading && <ALoader />}
          <Col md="3">
            <AAutoComplete
              label="Courier name"
              currentValue={form?.courierName || ""}
              items={courierDropdown}
              handleChange={(value) => {
                formHandler(value, "courierName");
              }}
              height="41px"
              isDisabled={disabled}
              isMulti={false}
              style={selectStyle}
            />
          </Col>
          <Col md="3">
            <AInput
              label="Weight(in Kgs)"
              value={form?.weight || ""}
              placeholder={"Enter"}
              style={inputStyle}
              disabled={disabled}
              handler={(value) => formHandler(value, "weight")}
              maxLength={10}
              regex={regex?.numericDecimal}
            />
          </Col>
          {/* <Col md="3">
            <AInput
              label="Rate"
              value={form?.rate || ""}
              handler={(value) => formHandler(value, "rate")}
              placeholder={"Enter"}
              disabled={disabled}
              style={inputStyle}
            />
          </Col> */}
          <Col md="3">
            <AInput
              label="C.No"
              value={form?.cno || ""}
              handler={(value) => formHandler(value, "cno")}
              placeholder={"Enter"}
              style={inputStyle}
              disabled={disabled}
              maxLength={50}
              regex={regex?.alphaNumeric}
            />
          </Col>
          {/* <Col md="3">
            <AInput
              label="POD"
              value={form?.pod || ""}
              handler={(value) => formHandler(value, "pod")}
              placeholder={"Enter"}
              style={inputStyle}
              disabled={disabled}
              maxLength={50}
            />
          </Col> */}
          {/* <Col md="3">
            <AInput
              label="Regd"
              value={form?.regd || ""}
              handler={(value) => formHandler(value, "regd")}
              style={inputStyle}
              placeholder={"Enter"}
              disabled={disabled}
              maxLength={50}
            />
          </Col> */}
          <Col md="3" className="d-flex flex-column">
            <InputLabel style={InputLabelStyle}>Date</InputLabel>
            <CustomDatePicker
              placeHolder="Select"
              value={form?.dispatchDate || ""}
              handler={(index, date, keyName) => {
                formHandler(date, "dispatchDate");
              }}
              disabled={disabled}
              maxDate={moment()}
            />
          </Col>
          <Col md="3">
            <AInput
              label="Amount"
              value={form?.amount || ""}
              placeholder={"Enter"}
              style={inputStyle}
              handler={(value) => formHandler(value, "amount")}
              disabled={disabled}
              regex={regex?.numeric}
              maxLength={10}
            />
          </Col>
        </Row>
        {!disabled && (
          <AButton
            className="mt-4 button_remove_left_margin"
            variant="primary_filled"
            onClick={() => saveHandler(form, setForm)}
            disabled={validateForm(form) ? true : false}
          >
            {currentRowData?.isReDispatched
              ? "Update"
              : "Save and move to dispatched"}
          </AButton>
        )}
      </>
    );
  };

  const getFormLabel = (label, value) => (
    <Col md="3 d-flex flex-column">
      <InputLabel sx={InputLabelStyle}>{label}</InputLabel>
      <div className="d-flex">
        <Tooltip title={value}>
          <div className="heading-3 color-dark ellipsis-text-container">
            {value}
          </div>
        </Tooltip>
      </div>
    </Col>
  );

  const ShowData = () => {
    return (
      <>
        <Row>
          {getFormLabel("Courier name", currentRowData?.courierName)}
          {getFormLabel("Weight", currentRowData?.weight)}
          {/* {getFormLabel("Rate", currentRowData?.rate)} */}
          {getFormLabel("C.No", currentRowData?.cno)}
          {/* </Row>
        <Row> */}
          {/* {getFormLabel("POD name", currentRowData?.pod)} */}
          {/* {getFormLabel("Regd.", currentRowData?.regd)} */}
          {getFormLabel("Date", getFormattedDate(currentRowData?.dispatchDate))}
          {getFormLabel("Amount", currentRowData?.amount)}
        </Row>
        <ShowDispatchedChip data={currentRowData} />
      </>
    );
  };

  return (
    <>
      <Dialog onClose={() => setOpen(false)} open={open} maxWidth="lg">
        <div style={{ padding: "28px 24px", minWidth: "52rem" }}>
          {loader && <ALoader position="fixed" />}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="color-dark heading-4">Dispatch details </div>
            <IconButtonWrapper onClick={() => setOpen(false)}>
              <CrossIcon width={32} height={32} />
            </IconButtonWrapper>
          </div>
          {/* {currentRowData ? (
            currentRowData?.isReDispatched ? (
              <Form />
            ) : (
              <ShowData />
            )
          ) : (
            <>
            Hello
            <Form />
            </>
          )} */}
          {currentRowData?.isReDispatched ||
          currentRowData?.status === "STOCK_ISSUED" ? (
            <Form data={{}} />
          ) : (
            <ShowData />
          )}
          {currentRowData?.isReDispatched && (
            <div className="mt-4">
              <ViewDispatchHistory data={currentRowData}>
                <Form
                  disabled={true}
                  data={{
                    ...currentRowData,
                    courierName: {
                      label: currentRowData?.courierName,
                      value: currentRowData?.courierName,
                    },
                  }}
                />
              </ViewDispatchHistory>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
}

export default AddDispatchDetails;
