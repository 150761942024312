import React, { useState, useEffect } from "react";
import { putpost, masterServiceBaseUrl, failureToast } from "services/http";

import Header from "./header/header";
import Building from "./msGroupSequence/msGroupSequence";

const msdata = [{
  sequence : 1,
  coreMasterId : 2
}]

const MsGroupSequence = () => {
  const [msData, setMsData] = useState(null);

  const [headers, setHeaders] = useState({
    businessGroup: null,
    companyCode: null,
    zone: null,
    region: null,
    businessArea: null,
  });

  const searchBuilding = (obj) => {
    const searchData = obj ?? {
      businessGroup: headers.businessGroup?.value,
      companyCode: headers.companyCode?.value,
      zone: headers.zone?.value,
      region: headers.region?.value,
    };

    // putpost(
    //   masterServiceBaseUrl + "/buildingMaster/searchMsGroupSequence",
    //   (data) => {
    //     // setMsData(data.data.buildingMasterDtoList);
    //     setMsData(msdata)
    //   },
    //   (data) => {
    //     setMsData(null);
    //     failureToast(data["message"]);
    //   },
    //   searchData,
    //   "post"
    // );
    setMsData(msdata)
  };

  const resetHeader = () => {
    setHeaders({
      businessGroup: null,
      companyCode: null,
      zone: null,
      region: null,
      businessArea: null,
    });
    setMsData(null);
  };

  return (
    <>
      <Header
        headerValues={headers}
        setHeaderValues={setHeaders}
        searchBuildingData={searchBuilding}
        setMsData={setMsData}
        resetHeader={resetHeader}
      />
      {msData && (
        <Building
          data={msData}
          searchBuilding={searchBuilding}
          headers={headers}
        />
      )}
    </>
  );
};

export default MsGroupSequence;
