import React from "react";
import {
  Page,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import DepositSlip from "./DepositSlip";
// import DraftSummary from "./draftSummary";

// Define a custom font with a specific weight
Font.register({ family: "MyFont", fontWeight: 100 });

// Create your PDF document
const DepositSlipWrapper = ({ data, qrCode }) => (
    <Document>
    <Page wrap orientation="landscape" size="A4" style={styles.page}>
      <DepositSlip data={data} qrCode={qrCode} desc={'Bank Copy'}/>
      <DepositSlip data={data} qrCode={qrCode} desc={'Office Copy'}/>
      <DepositSlip data={data} qrCode={qrCode} desc={'Customer Copy'}/>
    
    </Page>
  </Document>
);

// Create styles for Texte components
const styles = StyleSheet.create({
  page: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingHorizontal: 10,
    flexDirection: "row",
    justifyContent: 'space-between'
  },
//   tableRow: {
//     flexDirection: "row",
//     fontSize: 8,
//     gap: 10,
//   },
});

export default DepositSlipWrapper;