import React, {Fragment, useEffect, useState} from "react";
import {Button, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import Select2 from "react-select2-wrapper";

export const RedAstric = () => (<span style={{color: 'red'}}>*</span>)
const ExamIdSearchForm = (props) => {
    const {term, academicCareer, academicGroup, examType,searchData,setSearchData,resetFields,searchHandler,label,placeholder} = props;
    const UpdateSearchData = (key, value) => setSearchData(preState => ({...preState, [key]: value}))

    return (
        <Fragment>
            <Form>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="examId" className="form-control-label">{label.examId} </Label>
                            <Input disabled={false} placeholder={placeholder.examId} className="tdSelect" value={searchData['examId'] ? searchData['examId'] : '' } maxLength={21} onChange={(e) => UpdateSearchData('examId', e.target.value.replace(/[^\w\s]/gi, ""))}/>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="examType" className="form-control-label">{label.examType} </Label>
                            <Select2 options={{placeholder:placeholder.examType,}} data={examType} defaultValue={searchData['examTypeId']} onChange={(e) => UpdateSearchData('examTypeId', e.target.value)}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="academicCareer" className="form-control-label">{label.academicCareer} <RedAstric/></Label>
                            <Select2 options={{placeholder:placeholder.academicCareer,}} defaultValue={searchData['academicCareerId']} data={academicCareer} onChange={(e) => UpdateSearchData('academicCareerId', e.target.value)}/>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="status" className="form-control-label">{label.term} <RedAstric/></Label>
                            <Select2 defaultValue={searchData['termId']} options={{placeholder: placeholder.term,}} data={term} onChange={(e) => UpdateSearchData('termId', e.target.value) }/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="academicCareer" className="form-control-label">{label.academicGroup}</Label>
                            <Select2 options={{placeholder: placeholder.academicGroup,}} defaultValue={searchData['academicGroupId']} data={academicGroup} onChange={(e) => UpdateSearchData('academicGroupId', e.target.value)}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Button color="info" size="sm" disabled={!searchData['academicCareerId'] && !searchData['examId'] && !searchData['examTypeId'] && !searchData['academicGroupId'] && !searchData['termId']} className={"floatRight mx-1" + ((!searchData['academicCareerId'] && !searchData['examId'] && !searchData['examTypeId'] && !searchData['academicGroupId'] && !searchData['termId']) ? ' btn-dark' : null)} onClick={() => resetFields()}>Reset</Button>
                        <Button color="info" size="sm" className="floatRight mx-1" onClick={() => searchHandler()}>Search</Button>
                    </Col>
                </Row>
            </Form>
        </Fragment>
    )
}
export default ExamIdSearchForm;