import React from "react";
const style = {
  display: "inline-block",
  minWidth: "54px",
  color: "#45464E"
};
const DisplayRegion = ({ items, type }) => {
  // console.log(items);
  if (items && items.length > 0) {
    return (
      <span style={style}>
        {items[0].label}
        {items.length > 1 && `, + ${items.length - 1} more `}
      </span>
    );
  } else {
    return <span style={style}>{type} ...</span>;
  }
};

export default DisplayRegion;
