import React, { useEffect } from "react";
import { Button, Input } from "reactstrap";
import Select from "react-select";
import Status from "../../../common/formFeilds/status";

const AttendanceMachineForm = (props) => {
  const {
    data,
    attendanceMachineData,
    setAttendanceMachineData = () => {},
    index,
    saveRow = () => {},
    cancelRow = () => {},
    isSaveVisible,
    getDisplayVal = () => {},
    activeBuildingData = [],
    activeMachineTypeData = [],
    isEditForm = false,
  } = props;

  // const getPincodeInfo = (id) => {
  //   if(id){
  //       getAPI(`${masterServiceBaseUrl}/pincode/getPincodeByCity/${id}`,
  //       (data) => {
  //           setTimeout(3000)
  //           setAttendanceMachineData({ ...attendanceMachineData, stateId : data.data?.[0]?.stateId, pincode : data.data?.[0]?.id, country : data.data?.[0]?.countryId })
  //       },
  //       (data) => { failureToast(data['message']) })
  //   }
  // }

  // useEffect(() => {
  //    if(attendanceMachineData.pincode){
  //      const pinValues = getDisplayVal(attendanceMachineData.pincode,pincodeData)
  //      pinValues && setAttendanceMachineData({ ...attendanceMachineData, stateId : pinValues.stateId, city : pinValues.cityId , country : pinValues.countryId })
  //    }
  // }, [attendanceMachineData.pincode])

  return (
    <tr key={data["id"] + "-class"}>
      <td className="text-center p-2 mx-1">
        <Select
          value={attendanceMachineData.buildingId}
          id="attendance_machine_master_building"
          options={activeBuildingData}
          isDisabled={isEditForm}
          placeholder="Please Select Building"
          onChange={(sData) => {
            setAttendanceMachineData({
              ...attendanceMachineData,
              buildingId: sData,
            });
          }}
          components={{
            IndicatorSeparator: () => null
          }}
        />
      </td>
      <td className="text-center p-2 mx-1">
        <Input
          defaultValue={attendanceMachineData.rfidMachineId}
          onChange={(e) =>
            setAttendanceMachineData({
              ...attendanceMachineData,
              rfidMachineId: e.target.value.trim(),
            })
          }
          placeholder="RFID Machine Id"
          id="attendance_machine_master_rfid_machine_id"
          style={{ height: "38px" }}
          maxLength="50"
          disabled={isEditForm}
        />
      </td>
      <td className="text-center p-2 mx-1">
        <Input
          defaultValue={attendanceMachineData.machineName}
          onChange={(e) =>
            setAttendanceMachineData({
              ...attendanceMachineData,
              machineName: e.target.value.trim(),
            })
          }
          placeholder="Machine Name"
          id="attendance_machine_master_machine_name"
          style={{ height: "38px" }}
          maxLength="50"
          disabled={isEditForm}
        />
      </td>
      <td className="text-center p-2 mx-1">
        <Input
          defaultValue={attendanceMachineData.rfidMachineVendor}
          onChange={(e) =>
            setAttendanceMachineData({
              ...attendanceMachineData,
              rfidMachineVendor: e.target.value.trim(),
            })
          }
          placeholder="RFID Machine Vendor"
          id="attendance_machine_master_rfid_machine_vendor"
          style={{ height: "38px" }}
          maxLength="50"
          disabled={isEditForm}
        />
      </td>
      <td className="text-center p-2 mx-1">
        <Select
          value={attendanceMachineData.machineTypeId}
          id="attendance_machine_master_machine_type"
          options={activeMachineTypeData}
          isDisabled={isEditForm}
          placeholder="Please Select Building"
          onChange={(sData) => {
            setAttendanceMachineData({
              ...attendanceMachineData,
              machineTypeId: sData,
            });
          }}
          components={{
            IndicatorSeparator: () => null
          }}
        />
      </td>
      <td className="text-center p-2 mx-1">
        <Input
          defaultValue={attendanceMachineData.ipAddress}
          onKeyPress={(e) => {
            if (!/^[0-9.]+$/.test(String.fromCharCode(e.charCode))) {
              e.preventDefault();
              return;
            }
          }}
          onChange={(e) =>
            setAttendanceMachineData({
              ...attendanceMachineData,
              ipAddress: e.target.value.trim(),
            })
          }
          placeholder="IP Address"
          id="attendance_machine_master_ip_address"
          style={{ height: "38px" }}
          maxLength="15"
          disabled={isEditForm}
        />
      </td>
      <td className="text-center p-2 mx-1">
        <Input
          defaultValue={attendanceMachineData.macAddress}
          onKeyPress={(e) => {
            if (!/^[A-Z0-9-]+$/.test(String.fromCharCode(e.charCode))) {
              e.preventDefault();
              return;
            }
          }}
          onChange={(e) =>
            setAttendanceMachineData({
              ...attendanceMachineData,
              macAddress: e.target.value.trim(),
            })
          }
          placeholder="MAC Address"
          id="attendance_machine_master_mac_address"
          style={{ height: "38px" }}
          maxLength="17"
          disabled={isEditForm}
        />
      </td>
      <td>
        <Input
          value={attendanceMachineData.effectiveDate}
          disabled
          id="attendance_machine_master_effective_date"
          style={{ height: "38px" }}
        />
      </td>
      <td>
        <Status
          value={attendanceMachineData.status}
          onChange={(n) => {
            setAttendanceMachineData({ ...attendanceMachineData, status: n });
          }}
        />
      </td>
      <td className="text-center p-2 mx-1">
        <Button
          color="info"
          size="sm"
          type="button"
          className="mx-1"
          onClick={() => saveRow(index)}
        >
          Save
        </Button>
        <Button
          color="danger"
          size="sm"
          type="button"
          onClick={() => {
            cancelRow(index);
          }}
        >
          <i className="fas fa-times" />
        </Button>
      </td>
    </tr>
  );
};

export default AttendanceMachineForm;
