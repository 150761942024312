import React, { useEffect, useMemo, useState } from "react";
import { Form, Input, Col, Button } from "reactstrap";
import Loader from "react-loader-spinner";
import Select from "react-select";
import Select2 from "react-select2-wrapper";
import {
  masterServiceBaseUrl,
  getAPI,
  putpost,
  putpost2,
} from "services/http";
import { failureToast, successToast } from '../../../common/utils/methods/toasterFunctions/function';


const statusData = [
  { label: "ACTIVE", value: "ACTIVE" },
  { label: "INACTIVE", value: "INACTIVE" },
];

const CustomForm = (props) => {
  const {
    cityData = [],
    districtData = [],
    pincodeData = [],
    stateData = [],
    countryData = [],
    addressCategoryData = [],
    isNewForm = true,
    addressData,
    addToList = () => {},
    saveData = () => {},
    editFromList = () => {},
    deleteAddress = () => {},
    property = [],
    setSaveingStatus = () => {},
    saveingStatus = false,
    isEditFormOpen = () => {},
    resetList = () => {},
    apiloader = false,
    building
  } = props;

  const [addressKey, setAddressKey] = useState("");
  const [addressDesc, setAddressDesc] = useState("");
  const [addressDispValue, setAddressDispValue] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine3, setAddressLine3] = useState("");
  const [addressLine4, setAddressLine4] = useState("");

  const [city, setCity] = useState("");
  const [district,setDistrict] = useState("");
  const [pincode, setPincode] = useState("");
  const [stateName, setStateName] = useState("");
  const [country, setCountry] = useState("");
  const [status, setStatus] = useState({ label: "ACTIVE", value: "ACTIVE" });
  const [addressCategory, setAddressCategory] = useState("");
  const [isDisabled, setDisabled] = useState(false);
  const [allowedProperty, setAllowedProperty] = useState([]);
  const [isEditable, setIsEditable] = React.useState(false);
  const [pinData, setPinData] = React.useState([]);
  const [selectedBuildings, setSelectedBuildings] = useState([]);
  const [savedBuilding, setSavedBuilding] = useState([]);


  React.useEffect(() => {
    setPinData(pincodeData);
  }, [pincodeData]);

  const fetchData = (val) => {
    if (val && val.value) {
      getAPI(
        `${masterServiceBaseUrl}/address/getAddressProperty/${val.value}`,
        (data) => {
          setAllowedProperty(data.data);
          setAddressCategory(val);
          // setAddressCategory(getSelectedValue(val, addressCategoryData)[0]);
        },
        (data) => {
          failureToast(data["message"]);
          setAllowedProperty([]);
          setAddressCategory("");
        }
      );
    }
  };

  const fetchPincodeById = (id) => {
    if (!id) {
      return;
    }
    getAPI(
      `${masterServiceBaseUrl}/pincode/getPincode/${Number(id.value)}`,
      (data) => {
        setPincode(id);
        setCity(getSelectedValue(data.data.cityId, cityData));
        setCountry(getSelectedValue(data.data.countryId, countryData));
        setStateName(getSelectedValue(data.data.stateId, stateData));
        setDistrict(getSelectedValue(data.data.districtId, districtData))
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  };

  // console.log("addressData>>", { addressData });

  React.useEffect(() => {
    if (!isNewForm) {

      const temp = addressData["buildingIds"].map((item) => Number(item))
      const filterBuildings = building.filter(e => temp.includes(e.value))

      setAddressKey(addressData["addressKey"]);
      setAddressDesc(addressData["addressDesc"]);
      setAddressDispValue(addressData["addressDispValue"]);
      setAddressLine1(addressData["addressLine1"]);
      setAddressLine2(addressData["addressLine2"]);
      setAddressLine3(addressData["addressLine3"]);
      setAddressLine4(addressData["addressLine4"]);
      setSelectedBuildings(filterBuildings);
      setCity(getSelectedValue(addressData["cityId"], cityData));
      setPincode(getSelectedValue(addressData["pincodeId"], pincodeData));
      setStatus(getSelectedValue(addressData["status"], statusData));
      setDistrict(getSelectedValue(addressData["districtId"], districtData));
      setCountry(
        getSelectedValue(Number(addressData["countryId"]), countryData)
      );
      setStateName(
        getSelectedValue(Number(addressData["stateId"]), stateData)
      );
      setDisabled(true);
      setAddressCategory(
        getSelectedValue(
          Number(addressData["addressCategoryId"]),
          addressCategoryData
        )
      );
      fetchData( getSelectedValue(
        Number(addressData["addressCategoryId"]),
        addressCategoryData
      ));
    }
  }, [addressData]);

  const getSelectedValue = (id, listName) => {
    const val = listName.filter((item) => {
      return item.value === id;
    });
    return val.length ? val[0] : ""
  };

  React.useEffect(() => {
    if (saveingStatus === "saved") {
      setAddressKey("");
      setAddressDesc("");
      setAddressDispValue("");
      setAddressLine1("");
      setAddressLine2("");
      setAddressLine3("");
      setAddressLine4("");
      // setBuilding("");
      setCity("");
      setPincode("");
      setStatus("ACTIVE");
      setCountry("");
      setStateName("");
      setDistrict("");
    }
  }, [saveingStatus]);

  const inputHandler = (e, methodName) => {
    methodName(e.target.value);
  };

  const categoryChangeHandler = (val) => {
    // e.preventDefault();
    // const { value } = e.target;
    fetchData(val);
  };

  const getPincodeByCity = (sdata) => {
    // console.log(data)
    if (!sdata || sdata === "") {
      setCity("");
      setPinData(pincodeData);
      return;
    }
    getAPI(
      `${masterServiceBaseUrl}/pincode/getPincodeByCity/${Number(sdata.value)}`,
      (data) => {
        setCity(sdata);
        const list = data.data.map((data) => {
          return {
            value: data.id,
            label: data["pincodeDispValue"],
          };
        });
        setPinData(list);
        setCountry("");
        setStateName("");
        setPincode("");
        setDistrict("");
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  };

  const addAddressToList = () => {
    if (addressKey.trim() === "") {
      failureToast("Please Enter Address Key Value");
      return;
    }

    if (addressDesc.trim() === "") {
      failureToast("Please Enter Address Description Value");
      return;
    }

    if (addressDispValue.trim() === "") {
      failureToast("Please Enter Address Display Value ");
      return;
    }

    if (addressLine1=== "" && addressLine1.trim() === "" &&
      isInProperty("ADDRESS_LINE1")
    ) {
      failureToast("Please Enter Address Line1 Value");
      return;
    }

    if (addressLine2=== "" &&
      addressLine2.trim() === "" &&
      isInProperty("ADDRESS_LINE2")
    ) {
      failureToast("Please Enter Address Line2 Value");
      return;
    }

    if (addressLine3=== "" &&
      addressLine3.trim() === "" &&
      isInProperty("ADDRESS_LINE3")
    ) {
      failureToast("Please Enter Address Line3 Value");
      return;
    }

    if ( addressLine4=== "" &&
      addressLine4.trim() === "" &&
      isInProperty("ADDRESS_LINE4")
    ) {
      failureToast("Please Enter Address Line4 Value");
      return;
    }

    if (!selectedBuildings.length) {
      failureToast("Please Enter Building Value");
      return;
    }

    if (!city) {
      failureToast("Please Select City Value");
      return;
    }

    if (!district) {
      failureToast("Please Select District Value");
      return;
    }

    if (!pincode) {
      failureToast("Please Select Pincode Value");
      return;
    }

    if (!stateName) {
      failureToast("Please Select State Value");
      return;
    }

    if (!country) {
      failureToast("Please Select Country Value");
      return;
    }

    const buildingDetails = selectedBuildings.map(item => item.value)

    const formData = {
      addressKey: addressKey, //isInProperty('ADDRESS_KEY') ? addressKey :null,
      addressDesc: addressDesc, //isInProperty('ADDRESS_DESC') ? addressDesc :null,
      addressDispValue: addressDispValue, //isInProperty('ADDRESS_DISPLAY_VALUE') ? addressDispValue :null,
      addressReferenceType: "COMPANY_CODE",
      referenceId: null,
      addressLine1: isInProperty("ADDRESS_LINE1") ? addressLine1 : null,
      addressLine2: isInProperty("ADDRESS_LINE2") ? addressLine2 : null,
      addressLine3: isInProperty("ADDRESS_LINE3") ? addressLine3 : null,
      addressLine4: isInProperty("ADDRESS_LINE4") ? addressLine4 : null,
      cityId: city.value, //isInProperty('DISTRICT') ? district :null,
      pincodeId: pincode.value, //isInProperty('PINCODE') ? pincode :null,
      buildingIds: buildingDetails, //isInProperty('BUILDING') ? building :null,
      stateId: stateName.value, //isInProperty('STATE') ? stateName :null,
      countryId: country.value, //isInProperty('COUNTRY') ? country :null,
      districtId : district.value,
      addressCategoryId: addressCategory.value,
      status: status.value, //isInProperty('STATUS') ? status :null
    };

    // console.log("formData>>", { formData });

    if (isNewForm) {
      // console.log(formData)
      saveData(formData);
      // setSaveingStatus(false)
      // addToList(formData)
    }

    if (!isNewForm) {
      formData.addressCategoryId = addressData.addressCategoryId;
      formData.updatedDate = addressData.updatedDate;
      formData.effectiveDate = addressData.effectiveDate;
      editFromList(formData, addressData.id);
    }
  };

  const deleteAddressFromList = () => {
    if (window.confirm("Are you sure you want to delete this Address?")) {
      deleteAddress(addressData.id);
    }
  };

  const isInProperty = (key) => {
    if (isNewForm) {
      const item =
        allowedProperty.length &&
        allowedProperty.filter((item) => {
          return item && item.property.propertyKey === key;
        });
      const result = item.length ? true : false;
      return result;
    } else {
      const item =
        allowedProperty.length &&
        allowedProperty.filter((item) => {
          return item && item.property.propertyKey === key;
        });
      const result = item.length ? true : false;
      return result;
    }
  };

  return (
    <>
      {apiloader ? (
        <div
          className="mx-auto text-center py-5 my-5"
          style={{ height: "100vh" }}
        >
          <Loader
            type="Rings"
            color="#00BFFF"
            height={70}
            width={70}
            timeout={10000}
          />
        </div>
      ) : (
        <div></div>
      )}
      <>
        <div className="form-row mb-3">
          <Col className="mb-3" md="4">
            <label className="form-control-label">Address Category</label>
            <Select
              value={addressCategory}
              id="example3cols2Input"
              options={addressCategoryData}
              // isDisabled={isDisabled}
              placeholder="Select Address Category"
              onChange={(sData) => categoryChangeHandler(sData)}
              isDisabled={!isNewForm}
            />
            {/* <Select2
                            defaultValue={addressCategory}
                            data={addressCategoryData}
                            onChange={e => categoryChangeHandler(e)}
                            // onChange={(e)=>inputHandler(e,'addressCategory')} 
                            options={{ placeholder: "Select Address Category", disabled: !isNewForm }} className="tdSelect"
                        /> */}
          </Col>
          {addressCategory ? (
            <>
              {" "}
              <Col className="mb-3">
                <label className="form-control-label">{`Address Key`}</label>
                <Input
                  value={addressKey}
                  placeholder={`Address Key`}
                  type="text"
                  disabled={isDisabled}
                  maxLength="10"
                  onChange={(e) => inputHandler(e, setAddressKey)}
                  style={{ height: "39px" }}
                />
                <div className="invalid-feedback"></div>
              </Col>
              <Col className="mb-3" md="4">
                <label className="form-control-label">Status</label>
                <Select
                  value={status}
                  id="example3cols2Input"
                  options={statusData}
                  // isDisabled={isDisabled}
                  placeholder="Status"
                  onChange={(sData) => setStatus(sData)}
                  isDisabled={isDisabled}
                />
              </Col>{" "}
            </>
          ) : null}
        </div>
        {addressCategory ? (
          <>
            <div className="form-row">
              <Col className="mb-3" md="6">
                <label className="form-control-label">
                  {`Address Description`}
                </label>
                <Input
                  value={addressDesc}
                  placeholder={`Address Description`}
                  type="text"
                  maxLength="50"
                  disabled={isDisabled}
                  onChange={(e) => inputHandler(e, setAddressDesc)}
                  style={{ height: "39px" }}
                />
                <div className="invalid-feedback"></div>
              </Col>
              <Col className="mb-3" md="6">
                <label className="form-control-label">
                  {`Address Display Value`}
                </label>
                <Input
                  value={addressDispValue}
                  placeholder={`Address Display Value`}
                  type="text"
                  maxLength="50"
                  disabled={isDisabled}
                  onChange={(e) => inputHandler(e, setAddressDispValue)}
                  style={{ height: "39px" }}
                />
                <div className="invalid-feedback"></div>
              </Col>
            </div>
            {isInProperty("ADDRESS_LINE1") ? (
              <div className="form-row">
                <Col className="mb-3" md="12">
                  <label className="form-control-label">Address Line 1</label>
                  <Input
                    value={addressLine1}
                    placeholder="Address"
                    type="text"
                    maxLength="50"
                    disabled={isDisabled}
                    onChange={(e) => inputHandler(e, setAddressLine1)}
                    style={{ height: "39px" }}
                  />
                  <div className="invalid-feedback"></div>
                </Col>
              </div>
            ) : null}
            {isInProperty("ADDRESS_LINE2") ? (
              <div className="form-row">
                <Col className="mb-3" md="12">
                  <label className="form-control-label">Address Line 2</label>
                  <Input
                    value={addressLine2}
                    placeholder="Address"
                    type="text"
                    maxLength="50"
                    disabled={isDisabled}
                    style={{ height: "39px" }}
                    onChange={(e) => inputHandler(e, setAddressLine2)}
                  />
                  <div className="invalid-feedback"></div>
                </Col>
              </div>
            ) : null}
            {isInProperty("ADDRESS_LINE3") ? (
              <div className="form-row">
                <Col className="mb-3" md="12">
                  <label className="form-control-label">Address Line 3</label>
                  <Input
                    value={addressLine3}
                    placeholder="Address"
                    type="text"
                    maxLength="50"
                    disabled={isDisabled}
                    style={{ height: "39px" }}
                    onChange={(e) => inputHandler(e, setAddressLine3)}
                  />
                  <div className="invalid-feedback"></div>
                </Col>
              </div>
            ) : null}
            {isInProperty("ADDRESS_LINE4") ? (
              <div className="form-row">
                <Col className="mb-3" md="12">
                  <label className="form-control-label">Address Line 4</label>
                  <Input
                    value={addressLine4}
                    placeholder="Address"
                    type="text"
                    maxLength="50"
                    disabled={isDisabled}
                    style={{ height: "39px" }}
                    onChange={(e) => inputHandler(e, setAddressLine4)}
                  />
                  <div className="invalid-feedback"></div>
                </Col>
              </div>
            ) : null}
            <div className="form-row">
              <Col className="mb-3" md="6">
                <label className="form-control-label">Building</label>
                {/* <Input
                  value={building}
                  placeholder="Building"
                  type="text"
                  maxLength="50"
                  style={{ height: "39px" }}
                  disabled={isDisabled}
                  onChange={(e) => inputHandler(e, setBuilding)}
                /> */}
                <Select
                  value={selectedBuildings}
                  id="example3cols2Input"
                  options={building}
                  onChange={(sData) =>
                    setSelectedBuildings(sData)
                    // console.log("sData", sData)

                  }
                  isDisabled={isDisabled}
                  placeholder="Building"
                  isMulti
                />
                <div className="invalid-feedback"></div>
              </Col>            

              <Col className="mb-3" md="6">
                <label className="form-control-label">Pincode</label>
                <Select
                  value={pincode}
                  id="example3cols2Input"
                  options={pinData}
                  // isDisabled={isDisabled}
                  onChange={(sData) => fetchPincodeById(sData)}
                  isDisabled={isDisabled}
                  placeholder="Pincode"
                />
                <div className="invalid-feedback"></div>
              </Col>
            </div>
            <div className="form-row">
            <Col className="mb-3" md="6">
                <label className="form-control-label">City</label>
                <Select
                  value={city}
                  id="example3cols2Input"
                  options={cityData}
                  onChange={(sData) => getPincodeByCity(sData)}
                  isDisabled={isDisabled}
                  placeholder="City"
                  isClearable={true}
                />
                <div className="invalid-feedback"></div>
              </Col>

              <Col className="mb-3" md="6">
                <label className="form-control-label">District</label>
                <Select
                  value={district}
                  id="example3cols2Input"
                  options={districtData}
                  isDisabled={true}
                  placeholder="District"
                />
                <div className="invalid-feedback"></div>
              </Col>

             
            </div>
            <div className="form-row">
            <Col className="mb-3" md="6">
                <label className="form-control-label">State</label>
                <Select
                  value={stateName}
                  id="example3cols2Input"
                  options={stateData}
                  // isDisabled={isDisabled}
                  onChange={(sData) => fetchPincodeById(sData)}
                  isDisabled={true}
                  placeholder="State"
                />
               
                <div className="invalid-feedback"></div>
              </Col>
              <Col className="mb-3" md="6">
                <label className="form-control-label">Country</label>
                <Select
                  value={country}
                  id="example3cols2Input"
                  options={countryData}
                  // isDisabled={isDisabled}
                  onChange={(sData) => fetchPincodeById(sData)}
                  isDisabled={true}
                  placeholder="Country"
                />
              </Col>
              </div>
              <div className="form-row">
                <Col md={6}/>
              <Col
                className={`d-flex justify-content-end align-items-center`}
              >
                {isNewForm ? (
                  <Button
                    color="primary"
                    type="button"
                    className={`floatRight`}
                    onClick={addAddressToList}
                  >
                    Add
                  </Button>
                ) : !isNewForm && !isDisabled ? (
                  <div className={`ml-auto`}>
                    <Button
                      color="primary"
                      type="button"
                      className={`floatRight`}
                      onClick={addAddressToList}
                    >
                      Save
                    </Button>
                    <Button
                      color="danger"
                      type="button"
                      className={`floatRight`}
                      onClick={resetList}
                    >
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <div className={`floatRight`}>
                    <Button
                      color="primary"
                      type="button"
                      className={``}
                      onClick={() => {
                        setDisabled(false);
                        isEditFormOpen(true);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      color="danger"
                      type="button"
                      className={`ml-3`}
                      onClick={deleteAddressFromList}
                    >
                      Delete
                    </Button>
                  </div>
                )}
              </Col>
            </div>{" "}
          </>
        ) : null}
      </>
    </>
  );
};

export default CustomForm;
