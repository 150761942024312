import { Modal, ModalBody } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import FormHeader from './FormHeader';
import CustomDatePickerV2 from 'views/pages/manage/common/commonComponents/CustomDatePicker-V2';
import AAutoComplete from 'views/pages/manage/common/form-fields-mui/AAutoComplete';
import AButton from 'views/pages/manage/common/form-fields-mui/AButton';
import SelectAccountModal from './SelectAccountModal';
import moment from 'moment';
import AInput from 'views/pages/manage/common/formFeilds/customInput/AInput';
import CommonInput from 'views/pages/manage/common/formFeilds/input/commonInput';

const DetailsModal = (
  {
    isDetailsModalOpen,
    setDetailsModalOpen,
    updateKey,
    index,
    el,
    accountsArray,
    accNoDropdownModalData,
    isRowEdited,
    goBackWithDataLost,
    viewDetails,
    setViewDetails,
    setRowEdited,
    buDispVal,
    sapAccountDropdown
  }
) => {
  // const toggle=()=>setDetailsModalOpen(!isDetailsModalOpen)
  const mappingTypeDropdown = [{ value: "BRANCH", label: 'Branch' }, { value: "FRANCHISE", label: 'Franchise' }, { value: "MERITNATION", label: 'Meritnantion' }]
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(accNoDropdownModalData?.find(obj => obj.id === el?.houseBankId));
  const selectHandler = (houseBankId) => {
    updateKey(index, houseBankId, 'houseBankId');
    setSelectedRow(accNoDropdownModalData.find(obj => obj.id === houseBankId));
    setOpen(false);
  }
  useEffect(() => {
    const targetRow=accNoDropdownModalData?.find(obj => obj.id === el?.houseBankId);
    setSelectedRow(targetRow);
    setSapAccount({label : targetRow?.houseBank, value: targetRow?.houseBank});

  }, [accountsArray])

  const [sapAccount, setSapAccount]=useState({});

  const headerStyles = {
    color: 'rgb(38, 38, 38)',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '20px',
    paddingBottom: '6px',
    marginBottom: '8px',
    textAlign: 'center',
    borderBottom: '1px solid rgb(203, 203, 203)'
  }
  const textStyle = {
    color: 'rgb(38, 38, 38)',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    // paddingTop: '6px',
    textAlign: 'center',
  };
  return (
    <div>
      <Modal size="xl" style={{top: '15%'}} isOpen={isDetailsModalOpen}>
        <ModalBody>
          <FormHeader
            setDetailsModalOpen={setDetailsModalOpen}
            updateKey={updateKey}
            index={index}
            isRowEdited={isRowEdited}
            goBackWithDataLost={goBackWithDataLost}
            setViewDetails={setViewDetails}
            buDispVal={buDispVal}
            el={el}
          />
          <Row style={{ padding: '10px', borderRadius: '12px', border: 'solid 1px #CBCBCB', backgroundColor: '#F5F5F5', marginTop: '16px' }}>
            <Col md={12} className='h3 mb-2'>{el?.paymentModeDispVal === 'ADB' ? 'Details' : 'Bank Details'}</Col>
            {!(el?.paymentModeDispVal === 'ADB') ? (
              <>
                <Col className='mb-2' md={2}>
                  <div style={headerStyles}>SAP Account</div>
                   {!viewDetails ? (
                     <AAutoComplete
                     placeholder={'Select'}
                     currentValue={sapAccount}
                     isMulti={false}
                     items={sapAccountDropdown}
                     handleChange={val=>setSapAccount(val)}
                     style={{ control: { borderRadius: '4px',height:'40px' } }}
                   />
                   ) :(
                  <div style={textStyle}>{selectedRow?.houseBank || "-"}</div>
                   )
                   }
                </Col>
                <Col className='mb-2' md={2}>
                  <div style={headerStyles}>A/c no*</div>
                  {!viewDetails ? (
                    <div onClick={() => setOpen(true)}>
                      <AAutoComplete
                        placeholder={'Select'}
                        currentValue={{ label: selectedRow?.bankAccount, value: '' }}
                        isMulti={false}
                        // items={[{label:'Select',value:'Select'}]}
                        // handleChange={e=>setOpen(true)}
                        style={{ control: { borderRadius: '4px',height:'40px' } }}

                      />
                    </div>
                  ) : (
                    <div style={textStyle}>{selectedRow?.bankAccount || "-"}</div>
                  )
                  }
                </Col>
                <Col className='mb-2' md={2}>
                  <div style={headerStyles}>Bank Name</div>
                  <div style={textStyle}>{selectedRow?.description || "-"}</div>
                </Col>
                <Col className='mb-2' md={2}>
                  <div style={headerStyles}>Credit A/C</div>
                  <div style={textStyle}>{selectedRow?.gl1Account || "-"}</div>
                </Col>
                <Col className='mb-2' md={2}>
                  <div style={headerStyles}>Debit A/C</div>
                  <div style={textStyle}>{selectedRow?.gl2Account || "-"}</div>
                </Col>

                <Col className='mb-2' md={2}>
                  <div style={headerStyles}>Effective Date</div>
                  <div style={textStyle}>{selectedRow?.date ? (new moment(selectedRow?.date).format("DD-MM-YYYY")) : "-"} </div>
                </Col>
              </>
            ) : (
              <>
                <Col md={3}>
                  <div style={headerStyles}>Client Code*</div>
                  {!viewDetails ? (
                    <AInput
                      value={el?.clientCode}
                      handler={(val) => updateKey(index, val, 'clientCode')}
                      placeholder={'Enter client code'}
                      style={{ borderRadius: '4px', height:'40px' }}
                      maxLength={10}
                      regex={new RegExp(/^[a-zA-Z0-9]*$/)}
                    />) : (
                    <div style={textStyle}>{el?.clientCode || "-"}</div>
                  )}
                </Col>

                <Col md={3}>
                  <div style={headerStyles}>A/C no.</div>
                  {!viewDetails ? (
                    <AInput
                      value={el?.accountNumber||""}
                      handler={(val) => updateKey(index, val, 'accountNumber')}
                      placeholder={'Enter account number'}
                      style={{ borderRadius: '4px', height:'40px' }}
                      maxLength={20}
                      // type="number"
                      regex={new RegExp(/^[0-9]*$/)}
                      // regex={/^\d+$/}
                      // pattern={"\d*"}

                    />
                  ) : (
                    <div style={textStyle}>{el?.accountNumber || "-"}</div>
                  )}
                </Col>

                <Col md={3}>
                  <div style={headerStyles}>Effective date*</div>
                  {!viewDetails ? (
                    <CommonInput
                      type="datePicker"
                      defaultValue={el?.effectiveDate}
                      onChange={(val) => updateKey(index, val, 'effectiveDate')}
                    />
                  ) : (
                    <div style={textStyle}>{el?.effectiveDate ? (new moment(el?.effectiveDate).format("DD-MM-YYYY")) : "-"} </div>
                  )}
                </Col>
              </>
            )}

            <Col className='mb-2' md={(el?.paymentModeDispVal === 'ADB') ? 3 : 2}>
              <div style={headerStyles}>Mapping Type*</div>
              {!viewDetails ? (
                <AAutoComplete

                  currentValue={mappingTypeDropdown.find(obj => obj.value === el["mappedType"])}
                  // label={'Mapping Type'}
                  isMulti={false}
                  items={mappingTypeDropdown}
                  handleChange={e => updateKey(index, e.value, 'mappedType')}
                  style={{ control: { borderRadius: '4px', height:'40px' } }}
                />) : (
                <div style={textStyle}>{mappingTypeDropdown.find(obj => obj.value === el["mappedType"])?.label || "-"}</div>
              )}
            </Col>

          </Row>
          {!viewDetails && (
            <div style={{ marginTop: '24px' }}>
              <AButton
                className="button_remove_left_margin"
                updatedStyle={{ fontSize: "12px" }}
                onClick={() => {
                  setDetailsModalOpen(false);
                  setRowEdited(false);
                }}
                variant="primary_filled"
                disabled={
                  (el?.paymentModeDispVal === 'ADB' ?
                    !(el?.clientCode?.length===10) : !(el?.houseBankId)) || !(el?.mappedType)
                }
              >
                Continue
              </AButton>
            </div>
          )}
          <SelectAccountModal
            accNoDropdownModalData={accNoDropdownModalData.filter(item=>item.houseBank===sapAccount.value)}
            open={open}
            selectHandler={selectHandler}
            setOpen={setOpen}
          />

        </ModalBody>
      </Modal>
    </div>

  );
}

export default DetailsModal;