import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
} from "reactstrap";
import { Accordion } from "../../../common/commonComponents/Accordion/accordion";
import HeaderInformation from "./headerInformation";
import BankInformation from "./bankInformation";
import LogDetails from "./logDetails";
import {
  getAPI,
  putpost,
  masterServiceBaseUrl,
  successToast,
  failureToast,
} from "services/http";
import { useHistory, useParams } from "react-router-dom";

const AddNewHouseBank = () => {
  let history = useHistory();
  const { id, action } = useParams();
  const [activeGroupCode, setActiveGroupCode] = useState([]);
  const [activeCompanyCode, setActiveCompanyCode] = useState([]);
  const [activeCurrency, setActiveCurrency] = useState([]);
  const [activeCountry, setActiveCountry] = useState([]);
  const [actionDisabled, setActionDisabled] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [houseBankDetails, setHouseBankDetails] = useState({
    accountCr: "",
    accountDr: "",
    accountHolderName: "",
    bankAccountId: "",
    bankAccountNumber: "",
    bankId: "",
    bankIfscCode: "",
    bankShortKey: "",
    companyCodeId: "",
    country: "",
    currency: "",
    groupCodeId: "",
    referenceId: "",
    status: "ACTIVE",
  });

  useEffect(() => {
    try {
      if (action) {
        action === "view" ? setActionDisabled(true) : setActionDisabled(false);
        fetchDetails(
          masterServiceBaseUrl + `/houseBank/getHouseBank/${id}`,
          setHouseBankDetails,
          "HouseBank"
        );
      }
      fetchDetails(
        masterServiceBaseUrl + "/groupCode/getAllActiveGroupCode",
        setActiveGroupCode,
        "GroupCode"
      );
      fetchDetails(
        masterServiceBaseUrl + "/companyCode/getAllActiveCompanyCode",
        setActiveCompanyCode,
        "CompanyCode"
      );
      fetchDetails(
        masterServiceBaseUrl + "/country/getAllActiveCountry",
        setActiveCountry,
        "Country"
      );
      fetchDetails(
        masterServiceBaseUrl + "/currency/getAllActiveCurrency",
        setActiveCurrency,
        "Currency"
      );
    } catch (e) {
      console.log("Error", e);
    }
  }, []);

  const fetchDetails = async(url, stateMethod, type) => {
    try {
     await getAPI(
        url,
        (data) => {
            refactorData(data["data"], stateMethod, type);
        },
        (data) => {
          failureToast(data["message"]);
        }
      );
    } catch (e) {
      console.log("Error", e);
    }
  };

  const refactorData = (data, stateMethod, type) => {
    try {
      switch (type) {
        case "GroupCode":
          iterateData(data, "id", "groupCodeDispValue", stateMethod);
          break;
        case "CompanyCode":
          iterateData(data, "id", "companyCodeDispValue", stateMethod);
          break;
        case "Country":
          iterateData(data, "id", "countryDispValue", stateMethod);
          break;
        case "Currency":
          iterateData(data, "id", "currencyDispValue", stateMethod);
          break;
        case "HouseBank":
          setHouseBankDetails(data);
          break;
        default:
          break;
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const iterateData = (data, id, property, stateMethod) => {
    try {
      let temp = [];
      data.map((item) => {
        temp.push({
          value: item[id],
          label: item[property],
        });
      });
      stateMethod(temp);
    } catch (e) {
      console.log("Error", e);
    }
  };

  const submitDataHandler = (value, property) => {
    try {
      setHouseBankDetails((preState) => ({
        ...preState,
        [property]: value,
      }));
    } catch (e) {
      console.log("Error", e);
    }
  };

  const getDisplayValue = (id, type) => {
    try {
      switch (type) {
        case "GroupCodeID":
          return findDisplayValue(activeGroupCode, id);
          break;
        case "CompanyCodeID":
          return findDisplayValue(activeCompanyCode, id);
          break;
        case "CountryID":
          return findDisplayValue(activeCountry, id);
          break;
        case "CurrencyID":
          return findDisplayValue(activeCurrency, id);
          break;
        default:
          break;
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const findDisplayValue = (datalist, id) => {
    try {
      const findIndex = datalist.findIndex((item) => item["value"] === id);
      return findIndex >= 0 ? datalist[findIndex]["label"] : "NOT FOUND";
    } catch (e) {
      console.log("Error", e);
    }
  };

  const saveHandler = () => {
    try {
      if (!houseBankDetails["groupCodeId"])
        return failureToast("Select Group Code");
      if (!houseBankDetails["companyCodeId"])
        return failureToast("Select Company Code");
      if (!houseBankDetails["bankAccountId"])
        return failureToast("Enter Bank Account ID");
      if (!houseBankDetails["bankShortKey"])
        return failureToast("Enter Bank Short Key");

      if (action === "edit") {
        putpost(
          masterServiceBaseUrl + `/houseBank/updateHouseBank/${houseBankDetails["id"]}`,
          (data) => {
            successToast(data["message"]);
            history.push("/admin/houseBank");
          },
          (data) => {
            failureToast(data["message"]);
          },
          houseBankDetails,
          "put"
        );
      } else {
        putpost(
          `${masterServiceBaseUrl}/houseBank/createHouseBank`,
          (data) => {
            successToast("House Bank Created successfully");
            history.push("/admin/houseBank");
          },
          (data) => {
            failureToast(data["message"]);
          },
          houseBankDetails,
          "post"
        );
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  return (
    <Container className="mt-3" fluid>
      <Card>
        <CardHeader>
          <h3>New House Bank Information</h3>
        </CardHeader>
        <hr />
        <CardBody>
          <Accordion
            isFormOpen={true}
            title={`Header Information`}
            key={"header_information"}
          >
            <HeaderInformation
              getDisplayValue={getDisplayValue}
              houseBankDetails={houseBankDetails}
              activeGroupCode={activeGroupCode}
              activeCompanyCode={activeCompanyCode}
              submitDataHandler={submitDataHandler}
              actionDisabled={actionDisabled}
            />
          </Accordion>
          <Accordion
            isFormOpen={true}
            title={`Bank Details`}
            key={"bank_details"}
          >
            <BankInformation
              getDisplayValue={getDisplayValue}
              houseBankDetails={houseBankDetails}
              activeCountry={activeCountry}
              activeCurrency={activeCurrency}
              submitDataHandler={submitDataHandler}
              actionDisabled={actionDisabled}
            />
          </Accordion>
          <Accordion
            isFormOpen={true}
            title={`Log Details`}
            key={"log_details"}
          >
            <LogDetails houseBankDetails={houseBankDetails} />
          </Accordion>
          <Row>
            <Col>
              {!(action === "view") ? (
                <Button
                data-testid="saveBtnHandler"
                  size="md"
                  type="button"
                  color="info"
                  className="floatRight"
                  onClick={() => saveHandler()}
                >
                  {action === "edit" ? "Update" : "Save"}
                </Button>
              ) : (
                <Button
                  size="md"
                  type="button"
                  color="info"
                  className="floatRight"
                  onClick={() => {
                    history.push("/admin/houseBank");
                  }}
                >
                  Close
                </Button>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default AddNewHouseBank;
