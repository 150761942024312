import React, { useState } from "react";
import { Col, Row } from "reactstrap"
const { default: CustomCopyToClipboard } = require("views/pages/manage/common/ACopyToClipboard/CustomCopyToClipboard")

const AllOnlinePackage=(prop)=>{
  return (prop.data.length>0 && prop.data.map((val,key)=>{
      return( <div key={key} className="d-flex align-items-center justify-content-between mb-3">
            <div className="md-6" style={{width: '100%',font:'inter', fontSize: 16, fontWeight: '600',}}>
                {val}
            </div>
            {/* <Col className="md-6 d-flex justify-content-end"> */}
            {val&&<CustomCopyToClipboard textToCopy={val}/>}
            {/* </Col> */}
        </div>
      )
}))
}
export default AllOnlinePackage