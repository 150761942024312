import React, { useState, useContext, useEffect } from 'react'
import CustomButton from '../../../../../components/CustomButton/index.js';
import CommonInput from '../../common/formFeilds/input/commonInput.js';
import downArrow from '../../../../../assets/chevronDownarrow.png'
import { Card, CardBody, Col, Row } from 'reactstrap';
import SearchInput from './SearchInput.jsx';
import { PermissionContext, RolePermissions } from "../../../../../appContext";
import { endpoint, pages, regex } from "../../common/constant";
import { PermisionDenied } from "../../common/commonComponents";
import OnlinePackageForm from './form.js';
import { useHistory } from 'react-router';
import PackageList from './packageList.jsx';
import { dropdownMapping } from '../../unAssigned/studentCharges/common.js';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod.js';
import ALoader from '../../common/a-loader/index.jsx';



const OnlinePackage = () => {
    const history = useHistory();
    const permissions = useContext(PermissionContext);
    const userPermissions = RolePermissions(
        permissions,
        pages['onlinePackage']['id']
    );
    const [pagination, setPagination] = useState({
        page: 0,
        rowCount: 0
    })
    const [isFilterTouched, setFilterTouched] = useState(false)
    const [dropdown, setDropdown] = useState({
        fetching: true,
        data: {
            career: [],
            academicGroup: [],
            academicYear: [],
            class: [],
            academicBoard: [],
            features: [],
            cmsPackage: [],
            cmsCourse: []
        }
    })
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true)
    const [filters, setFilters] = useState({
        "onlinePackageId": "",
        "onlinePackageName": "",
        "academicCareerId": [],
        "cmsPackageId": [],
        "cmsCourseIds": [],
        "feature": [],
        "validFrom": "",
        "validTo": "",
        "size": 10,
        "sortDirection": ""
    })

    const createSchema = (schemesPayload) => {
        return schemesPayload.map(scheme => {
            const {
                id,
                onlinePackageName,
                academicCareerId,
                academicGroupMasterId,
                academicYearId,
                classMasterId,
                cmsPackage,
                feature,
                examBoardTypeMasterId,
                status,
                validFrom,
                validTo
            } = scheme;

            let cmsCoursemap = [].concat.apply([], cmsPackage.map(i => { return i.cmsCourse.map(o => { return { label: o.cmsSubjectName, value: o.cmsCourseId } }) }))
            const uniqueCourse = Object.values(
                cmsCoursemap.reduce((acc, obj) => ({ ...acc, [obj.value]: obj }), {})
            );

            return {
                id: id,
                packageId: id,
                onlinePackageName,
                academicCareerId: dropdown.data.career.filter(i => academicCareerId.indexOf(i.value) > -1),
                academicGroupMasterId: dropdown.data.academicGroup.filter(i => i.value === academicGroupMasterId)?.[0] ?? '',
                academicYearId: dropdown.data.academicYear.filter(i => i.value === academicYearId)?.[0] ?? '',
                classMasterId: dropdown.data.class.filter(i => i.value === classMasterId)?.[0] ?? '',
                cmsPackage: cmsPackage.map(i => { return { label: i.cmsPackageName, value: i.cmsPackageId } }),
                cmsCourse: uniqueCourse,
                feature: feature.map(i => { return { label: i, value: i } }),
                examBoardTypeMasterId: dropdown.data.academicBoard.filter(i => i.value === examBoardTypeMasterId)?.[0] ?? '',
                status,
                validFrom,
                validTo
            }
        })
    }

    const getInitialRecords = async (p, f) => {
        setLoading(true)
        setRowData([])
        const request = {
            ...f,
            academicCareerId: f.academicCareerId.map(item => { return item.value && item.value !== '*' ? item.value : null }).filter(i => i),
            cmsPackageId: f.cmsPackageId.map(item => { return item.value && item.value !== '*' ? item.value : null }).filter(i => i),
            cmsCourseIds: f.cmsCourseIds.map(item => { return item.value && item.value !== '*' ? item.value : null }).filter(i => i),
            feature: f.feature.map(item => { return item.value && item.value !== '*' ? item.value : null }).filter(i => i),
            page: p ?? 0
        }
        const response = await fetchAllPostPromisedData(endpoint.onlinePackage.search, request);
        if (response?.code == 200) {
            const data = createSchema(response.data.onlinePackageDtoList)
            setPagination({
                ...pagination,
                page: response.data.currentPage,
                rowCount: response.data.totalRecords
            })
            setRowData([...data])
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const getInitailData = async () => {
        const p = await Promise.all([
            dropdownMapping(endpoint.academic_group.getAllActive, 'academicGroupDispValue'),
            dropdownMapping(endpoint.academic_career.getAllActive, 'academicCareerDispValue'),
            // dropdownMapping(endpoint.academicPhase.getAllActive, 'academicPhaseDisplayValue'),
            dropdownMapping(endpoint.academic_year.getAllActive, 'academicYearDisplayValue'),
            dropdownMapping(endpoint.examBoardType.getAllActive, 'examBoardTypeDispValue'),
            // dropdownMapping(endpoint.languageMaster.getActiveLanguageMaster, 'languageDispValue'),
            // dropdownMapping(endpoint.classType.getAllActive, 'classTypeDispValue'),
            // dropdownMapping(endpoint.region.getAllActive, 'regionDispValue'),
            dropdownMapping(endpoint.features.all, 'feature'),
            dropdownMapping(endpoint.classes.getAllActive, 'classDispValue'),
            dropdownMapping(endpoint.onlinePackage.getAllCourse, 'cmsCourse')
        ])

        const cmsData = await fetchAllPromisedData(endpoint.onlinePackage.allCmsPackages);
        let cmsPackage = [];
        let cmsCourse = {}

        try {
            if (Array.isArray(Object.values(cmsData))) {
                Object.values(cmsData).map(i => {
                    i.cmsPackage.map(c => {
                        if (cmsPackage.filter(i => i.value === c.cmsPackageId)?.length === 0) {
                            cmsPackage.push({ label: c.cmsPackageName, value: c.cmsPackageId })
                        }
                        let course = []
                        c.cmsCourse.map(cc => {
                            if (course.filter(i => i.value === cc.cmsCourseId)?.length === 0) {
                                course.push({ label: cc.cmsSubjectName, value: cc.cmsCourseId })
                            }
                        })
                        cmsCourse = { ...cmsCourse, [c.cmsPackageId]: course }
                    })
                })
            }
        } catch (e) {
            console.log(e)
        }

        setDropdown({
            fetching: false,
            data: {
                career: p?.[1] ?? [],
                academicGroup: p?.[0] ?? [],
                academicYear: p?.[2] ?? [],
                class: p?.[5] ?? [],
                academicBoard: p?.[3] ?? [],
                features: p?.[4] ?? [],
                cmsPackage: cmsPackage,
                cmsCourse: p?.[6] ?? []
            }
        })
    }

    const resetAll = () => {
        const filtersReq = {
            "onlinePackageId": "",
            "academicCareerId": [],
            "cmsPackageId": [],
            "cmsCourseIds": [],
            "feature": [],
            "validFrom": "",
            "validTo": "",
            "size": 10,
            "sortDirection": ""
        }
        setFilters({ ...filtersReq })
        setFilterTouched(false)
        setLoading(true)
        getInitialRecords(0, filtersReq)

    }

    const handleSeachWithId = (val) => {
        const filtersReq = {
            "onlinePackageId": val,
            "academicCareerId": [],
            "cmsPackageId": [],
            "cmsCourseIds": [],
            "feature": [],
            "validFrom": "",
            "validTo": "",
            "size": 10,
            "sortDirection": ""
        }

        setFilters(filtersReq);
        setFilterTouched(false)
        setLoading(true)
        getInitialRecords(0, filtersReq)
    }

    const applyFilters = () => {
        setLoading(true)
        getInitialRecords(0, filters)
    }

    const courseDropdown = () => {
        let arr = [];
        if (filters.cmsPackageId?.length) {
            filters.cmsPackageId.map(i => {
                arr = arr.concat(dropdown.data?.cmsCourse[i.value])
            })
        }
        return arr ?? []
    }

    useEffect(() => {
        getInitailData()
    }, [])
    
    useEffect(() => {
        if (!dropdown.fetching) {
            getInitialRecords(pagination.page, filters)
        }
    }, [dropdown.fetching])


    const handleNext = (p) => {
        getInitialRecords(p, filters);
        setPagination({
            ...pagination,
            page : p
        })
    }


  const onKeyPress= (e) => {
    if (
      !regex.numeric.test(
        String.fromCharCode(e.charCode)
      )
    ) {
      e.preventDefault();
      return;
    }
  }

    return (
        userPermissions ?
            <div style={{ marginTop: '-18px' }} className='student-charges-wrapper'>
                <div style={{ background: '#CCEFFD', gap: 20 }} className="d-flex justify-content-center flex-column pt-2 pb-2 pl-3 pr-3">
                    <div className='d-flex justify-content-between align-items-center'>
                        <div style={{ fontSize: 22, fontWeight: 700, lineHeight: '35px', color: '#004662' }}>
                            Search online packages
                            {/* {isViewClicked ? null : <div className='regular-bold color-grey-54'>Searching for 2334422, DLP, 712006 + 5 more</div>} */}
                        </div>
                        {/* {isViewClicked ?  */}
                        <CustomButton
                            className={' floatRight'}
                            content={"Create online package"}
                            outline
                            permissionType={'C'}
                            permissionSet={'C'}
                            onClick={() => history.push('/admin/onlinePackage/create')}
                        // disabled={loading}
                        />
                        {/* // :
                            // <div onClick={() => setIsViewClicked(!isViewClicked)} style={{ background: '#ffffff' }} className='pt-1 pb-1 pl-3 pr-3 rounded gap-2 d-flex justify-content-center align-items-center cursor'>
                            //     <span style={{ color: '#00B0F5' }} className='regular-bolder pr-2'>View filter</span><span><img height={15} src={downArrow} /></span>
                            // </div>} */}
                    </div>
                    <div style={{ width: 'fit-content' }}><SearchInput placeholder={"Search by online package ID"} searchInputValueHandler={handleSeachWithId} onCancel={resetAll} onKeyPress={onKeyPress}/></div>
                    <div className='d-flex justify-content-between align-items-center'>
                        <Col className="pl-0">
                            <CommonInput
                                type={"multiSelectWithCheckboxes"}
                                placeHolder="Select"
                                label="Career"
                                defaultValue={filters?.academicCareerId}
                                onChange={(value) => {
                                    setFilters({ ...filters, academicCareerId: value })
                                    setFilterTouched(true)
                                }}
                                data={dropdown.data.career}
                            />
                        </Col>
                        <Col className="pl-0">
                            <CommonInput
                                type={"multiSelectWithCheckboxes"}
                                placeHolder="Select"
                                label="CMS package"
                                defaultValue={filters?.cmsPackageId}
                                onChange={(value) => {
                                    setFilters({ ...filters, cmsPackageId: value })
                                    setFilterTouched(true)
                                }}
                                data={dropdown.data.cmsPackage}
                            />
                        </Col>
                        <Col className="pl-0">
                            <CommonInput
                                type={"multiSelectWithCheckboxes"}
                                placeHolder="Select"
                                label={"CMS courses"}
                                defaultValue={filters?.cmsCourseIds}
                                onChange={(value) => {
                                    setFilters({ ...filters, cmsCourseIds: value })
                                    setFilterTouched(true)
                                }}
                                data={dropdown.data.cmsCourse}
                            />
                        </Col>
                        <Col className="pl-0">
                            <CommonInput
                                type={"multiSelectWithCheckboxes"}
                                placeHolder="Select"
                                label="Features"
                                defaultValue={filters?.feature}
                                onChange={(value) => {
                                    setFilters({ ...filters, feature: value })
                                    setFilterTouched(true)
                                }}
                                data={dropdown.data.features}
                            />
                        </Col>
                        <Col className="pl-0">
                            <CommonInput
                                type={"datePickerV2"}
                                placeHolder="Select"
                                label="Validity From"
                                defaultValue={filters?.validFrom}
                                onChange={(value) => {
                                    setFilters({ ...filters, validFrom: value, validTo: '' })
                                    setFilterTouched(true)
                                }}
                            />
                        </Col>
                        <Col className="pl-0">
                            <CommonInput
                                type={"datePickerV2"}
                                placeHolder="Select"
                                label="Validity to"
                                defaultValue={filters?.validTo}
                                onChange={(value) => {
                                    setFilters({ ...filters, validTo: value })
                                    setFilterTouched(true)
                                }}
                                minDate={filters.validFrom}
                            />
                        </Col>
                        <div>
                            <CustomButton
                                // className={'m-3 floatRight'}
                                content={"Search"}
                                permissionType={'C'}
                                permissionSet={'C'}
                                onClick={applyFilters}
                                disabled={!isFilterTouched}
                            />
                            <span>
                                <CustomButton
                                    // className={'m-3 floatRight'}
                                    content={"Reset"}
                                    permissionType={'reset'}
                                    permissionSet={'reset'}
                                    onClick={resetAll}
                                    disabled={!isFilterTouched}
                                />
                            </span>
                        </div>
                    </div>
                </div>

                <div style={{ height: "calc(100vh - 250px)" }} className="p-4">
                    <Card>
                        <CardBody>
                            {loading && <ALoader />}
                            <PackageList
                                rowsData={rowData ?? []}
                                rowCount={pagination?.rowCount ?? 0}
                                nextButtonHandler={handleNext}
                                currentPage={pagination?.page ?? 0}
                                loading={loading}
                            />
                        </CardBody>
                    </Card>
                </div>



            </div> : <PermisionDenied />
    )
}

export default OnlinePackage