import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  Input,
  Container,
  Button,
  Table,
  Row,
} from 'reactstrap';
import { failureToast } from '../../common/utils/methods/toasterFunctions/function';
import { validateAlphaNumericWithSpecial } from '../../common/utils/methods/validations/validation';
import {
  MasterHeaderAuto,
  Rows,
} from '../../common/commonComponents/masterHeader/masterHeader';
import CoreMaster from '../../common/commonComponents/coreMaster';
import {
  masterServiceBaseUrl,
  successToast,
  putpost,
  putpost2,
} from 'services/http';
import moment from 'moment';
var _ = require('lodash');

const AcademicPhaseTable = (props) => {
  const { isSaveVisible, setisSaveVisible } = props;
  const [tableDataKey, settableDataKey] = useState('tableDataKey');
  const [updatedKey, setUpdateKey] = useState(new Date());
  const [isDisabled, setIsDisabled] = useState(false);
  const addNewClass = () => {
    try {
      props.setclassArray([
        {
          editMode: true,
          __status: '__new',
          status: 'ACTIVE',
          accountTypeKey: null,
          academicPhaseDescription: null,
          academicPhaseDisplayValue: null,
          effectiveDate: moment(),
        },
        ...props.classArray,
      ]);
    } catch (e) {
      console.log(e);
    }
  };

  const headerList = [
    { name: 'S.NO' },
    { name: 'Effective Date' },
    { name: 'Description', isRequired: true },
    { name: 'Display Value', isRequired: true },
    { name: 'Status' },
    { name: 'Action' },
  ];
  const listToRender = [
    'sno',
    'effectiveDate',
    'academicPhaseDescription',
    'academicPhaseDisplayValue',
    'status',
  ];

  const saveAll = (cb) => {
    try {
      var _tempArray = props.classArray;
      var isValid = true;
      _tempArray.forEach((el, index) => {
        if (el['editMode']) {
          if (
            !el['academicPhaseDescription'] ||
            validateAlphaNumericWithSpecial(
              el['academicPhaseDescription'].trim()
            )
          ) {
            isValid = false;
            failureToast(
              "Please enter valid description as Alphanumeric and special characters  '.' , '-', '_' "
            );
            return;
          } else if (
            !el['academicPhaseDisplayValue'] ||
            validateAlphaNumericWithSpecial(
              el['academicPhaseDisplayValue'].trim()
            )
          ) {
            isValid = false;
            failureToast(
              "Please enter valid display as Alphanumeric and special characters  '.' , '-', '_' "
            );
            return;
          }

          if (!isValid) {
            return;
          }
          if (_tempArray[index]['__status'] == '__new') {
            putpost(
              masterServiceBaseUrl + '/academicPhase',
              (data) => {
                successToast(data['message']);
                setisSaveVisible(false);
                setUpdateKey(new Date());
                props.fetchClasses(`${masterServiceBaseUrl}/academicPhase/all`);
              },
              (data) => {
                failureToast(data['message']);
              },
              _tempArray[index],
              'post'
            );
          } else {
            putpost(
              masterServiceBaseUrl + '/academicPhase/' + _tempArray[index].id,
              (data) => {
                successToast(data['message']);
                setisSaveVisible(false);
                props.fetchClasses(`${masterServiceBaseUrl}/academicPhase/all`);
                settableDataKey(new Date().getMilliseconds());
              },
              (data) => {
                failureToast(data['message']);
              },
              _tempArray[index],
              'put'
            );
          }
        } else {
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const newRow = () => {
    try {
      if (isSaveVisible) {
        failureToast('Please Delete or Save current changes');
      } else {
        let cb = () => {
          addNewClass();
          setisSaveVisible(true);
        };
        var _tempArray = props.classArray;
        _tempArray = _.sortBy(_tempArray, [
          function (o) {
            return o.value;
          },
        ]);
        props.setclassArray(_tempArray);
        setisSaveVisible(false);
        cb();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const editRow = (index) => {
    try {
      if (isSaveVisible) {
        failureToast('Please Delete or Save current changes');
      } else {
        let cb = () => {
          var _tempArray = props.classArray;
          _tempArray[index]['editMode'] = true;
          props.setclassArray(_tempArray);
          setisSaveVisible(true);
        };
        var _tempArray = props.classArray;
        _tempArray = _.sortBy(_tempArray, [
          function (o) {
            return o.value;
          },
        ]);
        cb();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const deleteRow = (index) => {
    try {
      if (props.classArray[index]['__status'] == '__new') {
        let n = props.classArray;
        n.splice(index, 1);
        props.setclassArray(n);
        setisSaveVisible(false);
        settableDataKey(new Date().getMilliseconds());
      } else {
        if (
          window.confirm(
            'Are you sure you want to delete this Acadamic Phase ?'
          )
        ) {
          putpost2(
            masterServiceBaseUrl +
              '/academicPhase/' +
              props.classArray[index].id,
            (data) => {
              successToast(data['message']);
              if (props.classArray.length == 1) {
                props.previousPage();
                props.fetchClasses(`${masterServiceBaseUrl}/academicPhase/all`);
              } else {
                var _tempArray = props.classArray;
                if (_tempArray[index]['editMode']) {
                  setisSaveVisible(false);
                }
                _tempArray.splice(index, 1);
                props.setclassArray(_tempArray);
              }
              settableDataKey(new Date().getMilliseconds());
            },
            (data) => {
              failureToast(data['message']);
            },
            'PUT'
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateKey = (index, value, key) => {
    try {
      var _tempArray = props.classArray;
      _tempArray[index][key] = value;
      props.setclassArray(_tempArray);
      setUpdateKey(new Date());
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Container className='mt-3' fluid>
        <Row>
          <div className='col'>
            <Card>
              <CardHeader className='border-0 d-flex justify-content-between'>
                <h3 className='mb-0 '>Academic Phase</h3>

                <span className='d-flex'>
                  <CoreMaster
                    master={'academicPhase'}
                    masterDisplayValue={'Academic Phase'}
                  />
                  <Button
                    disabled={isSaveVisible || isDisabled}
                    color='info'
                    size='sm'
                    type='button'
                    className={
                      'floatRight ml-4 ' +
                      (isSaveVisible || isDisabled ? ' btn-dark' : null)
                    }
                    onClick={() => {
                      newRow();
                    }}
                  >
                    New Academic Phase
                  </Button>
                </span>
              </CardHeader>
              <div id='questionTable' className='table-responsive'>
                <Table className='align-items-center table-flush '>
                  <MasterHeaderAuto headerList={headerList} />
                  <tbody className='list' key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                          !el['editMode'] ? (
                            <>
                              <Rows data={el} listToBeRendered={listToRender}>
                                <td className='text-center  white-breakSpace'>
                                  <Button
                                    color='info'
                                    size='sm'
                                    type='button'
                                    disabled={props.isViewOnly}
                                    onClick={() => editRow(index)}
                                  >
                                    <i className='fas fa-edit' />
                                  </Button>
                                </td>
                              </Rows>
                            </>
                          ) : (
                            <tr key={index + '-class'}>
                              <td className='text-center p-2 mx-1'>
                                <Input
                                  disabled
                                  defaultValue={el['sno']}
                                  placeholder='S.NO'
                                  className='tdInput'
                                />
                              </td>
                              <td className='text-center p-2 mx-1'>
                                <Input
                                  placeholder='eEfective Date'
                                  disabled
                                  className='tdInput'
                                  defaultValue={moment(
                                    el['effectiveDate']
                                  ).format('DD-MM-YYYY')}
                                />
                              </td>
                              <td className='text-center p-2 mx-1'>
                                <Input
                                  maxLength='50'
                                  defaultValue={el['academicPhaseDescription']}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value.trim(),
                                      'academicPhaseDescription',
                                      true
                                    );
                                  }}
                                  placeholder='Description'
                                  className='tdInput'
                                />
                              </td>
                              <td className='text-center p-2 mx-1'>
                                <Input
                                  maxLength='50'
                                  defaultValue={el['academicPhaseDisplayValue']}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value.trim(),
                                      'academicPhaseDisplayValue',
                                      true
                                    );
                                  }}
                                  placeholder='Display Value '
                                  className='tdInput'
                                />
                              </td>
                              <td className='text-center p-2 mx-1'>
                                <label className='custom-toggle mx-auto ml-2'>
                                  <input
                                    checked={
                                      el.status == 'ACTIVE' ? true : false
                                    }
                                    type='checkbox'
                                    onChange={(e) => {
                                      settableDataKey(
                                        new Date().getMilliseconds()
                                      );
                                      let n = 'ACTIVE';
                                      if (!e.target.checked) {
                                        n = 'INACTIVE';
                                      }
                                      updateKey(index, n, 'status');
                                    }}
                                  />
                                  <span
                                    style={{ width: '72px' }}
                                    className='custom-toggle-slider rounded-circle activeToggle'
                                    data-label-off='Inactive'
                                    data-label-on='Active'
                                  />
                                </label>
                              </td>
                              <td className='text-center'>
                                {isSaveVisible ? (
                                  <>
                                    {' '}
                                    <Button
                                      color='info'
                                      size='sm'
                                      type='button'
                                      className='mx-1'
                                      onClick={() => {
                                        saveAll();
                                      }}
                                    >
                                      Save
                                    </Button>
                                    <Button
                                      color='danger'
                                      size='sm'
                                      type='button'
                                      onClick={() => {
                                        let n = props.classArray;
                                        if (n[index]['__status'] == '__new') {
                                          n.splice(index, 1);
                                        } else {
                                          // n[index]['editMode'] = false
                                          props.fetchClasses(
                                            `${masterServiceBaseUrl}/academicPhase/all`
                                          );
                                        }
                                        props.setclassArray(n);
                                        setisSaveVisible(false);
                                      }}
                                    >
                                      {' '}
                                      <i className='fas fa-times' />
                                    </Button>{' '}
                                  </>
                                ) : (
                                  <Button
                                    color='info'
                                    size='sm'
                                    type='button'
                                    onClick={() => {
                                      deleteRow(index);
                                    }}
                                  >
                                    {' '}
                                    <i className='fas fa-trash' />
                                  </Button>
                                )}
                              </td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default AcademicPhaseTable;
