import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { PermissionContext, RolePermissions } from "../../../../../appContext";
import { endpoint, pages } from '../../common/constant';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod.js';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import MuiTable from '../../../manage/common/commonComponents/MuiTable.js';
import './index.scss';
import { Box, Card, Typography } from '@mui/material';
import CommonInput from '../../common/formFeilds/input/commonInput';
import { useGridApiContext } from '@mui/x-data-grid';
import { LightTooltip } from '../studentCharges/studentChargesDetails/concessionDetails/prospectus';
import { AiOutlineEye } from 'react-icons/ai';

const getFormatedDate = (date) => {
    return date ? moment(date).format('HH:mm, DD-MMM-YYYY') : ''
}

const NotAllowedCombinations = (params) => {
    const { id, field, value, row, codes } = params;
    const apiRef = useGridApiContext();

    const options = codes.filter(item => item.id !== row.id).map(c => ({label: c.code, value: c.code}));

    function getValue() {
        const values = [];
        value.forEach(val => {
            const el = options.find(item => item.value === val.value);
            if (el) values.push(el);
        })

        return values;
    }

    const handleChange = (newValue) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    }

    return (
        <div className='w-100 muiMultiSelectWithCheckboxes'>
            <CommonInput
                type={'multiSelectWithCheckboxes'}
                forTable
                placeHolder={"Select"}
                defaultValue={getValue()}
                data={options}
                onChange={(value) => {
                    handleChange(value);
                }}
                loading={false}
            />
        </div>
    );
}

const Code = params => {
    const { id, field, value, row, codes } = params;
    const apiRef = useGridApiContext();

    const handleChange = (newValue) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    }

    return (
        <div className='d-flex w-100'>
            {row?.fromBE === true ? 
                value : 
                <CommonInput
                    type={'text'}
                    defaultValue={value}
                    placeHolder={"Enter code"}
                    maxLength={400}
                    restrictSpecialCharacter={false}
                    onChange={value => handleChange(value)}
                    forTable={true}
                    regEx={/^$|^([A-Z]){1}$/}
                />
            }
        </div>
    );

}

const ConcessionCode = () => {

    const permissions = useContext(PermissionContext);
    const userPermissions = RolePermissions(permissions, pages["consession"]['id']);
    const [codes, setcodes] = useState([]);
    const [loading, setLoading] = useState(false);

    const getModifiedCodes = (response) => {
        return response.map(codeItem => {
            const { id, code, description, frequency, notAllowedCategoryCode, updatedOn, status } = codeItem;
            return {
                id,
                codeID: id,
                code,
                description,
                frequency: [{ label: frequency, value: frequency }],
                notAllowedCategoryCode: notAllowedCategoryCode.split(",").map(item => ({ label: item, value: item })),
                updatedOn,
                fromBE: true,
                status
            }
        })
    }

    const getAllCodes = async () => {
        setLoading(true);
        try {
            const response = await fetchAllPromisedData(`${endpoint.categoryCode}/all`);
            if (response) {
                const _codes = getModifiedCodes(response);
                setcodes(_codes);
                setLoading(false);
            } else {
                failureToast("Failed to fetch Data");
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            failureToast("Failed to fetch Data");
        }
    }

    useEffect(() => {
        getAllCodes();
    }, []);


    const columns = [
        { field: 'codeID', headerName: 'ID', type: 'number', flex: 14, mandatory: false, editable: false, disabled: false, sortable: false },
        {
            field: 'code', headerName: 'Code', flex: 20, mandatory: true, editable: true, disabled: false, sortable: false,
            renderCell: params => params.value,
            renderEditCell: params => {
                return <Code {...params}/>;
            }
        },
        { field: 'description', headerName: 'Description', flex: 46, type: 'text', mandatory: true, editable: true, disabled: false, 
            sortable: false, placeHolder: 'Add description',
            renderCell: (params) => (
                <div className='w-100 d-flex'>
                    <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '85%' }}>{params.value}</span>
                    {params.value &&
                        <LightTooltip title={params.value}>
                            <div style={{ cursor: 'pointer' }}>
                                <AiOutlineEye className='ml-1' size={16} color='#11cdef' />
                            </div>
                        </LightTooltip>
                    }
                </div>
            ),
        },
        {
            field: 'frequency', headerName: 'Frequency', type: 'singleSelect', flex: 26, mandatory: true, editable: true, disabled: false,
            valueOptions: [...Array(5).keys()].map((_, i) => ({ label: i + 1, value: i + 1 })), sortable: false, placeHolder: 'Select'
        },
        {
            field: 'notAllowedCategoryCode', headerName: 'Not allowed combinations', flex: 37, mandatory: false, editable: true, disabled: false,
            renderCell: params => {
                const _value = params.value;
                return <>{_value.map(item => item.label).join(',')}</>;
            },
            renderEditCell: params => {
                return <NotAllowedCombinations {...params} codes={codes}/>;
            },
            sortable: false, initialValue: []
        },
        {
            field: 'updatedOn', headerName: 'Last update', flex: 30, type: 'date', mandatory: false, editable: false, disabled: false,
            formatter: getFormatedDate, sortable: false
        },
        { 
            field: 'status', headerName: 'Status', flex: 26, type: 'toggle', mandatory: true, editable: true, disabled: false,
            dataLabelOn: "ACTIVE", dataLabelOff: "INACTIVE", sortable: false, initialValue: "ACTIVE"
        },
    ];

    async function deleteScheme(row) {
        setLoading(true);
        const p = new Promise((resolve, reject) => {
            fetchAllPostPromisedData(`${endpoint.categoryCode}/${row.id}`, {}, 'delete')
                .then(response => {
                    setLoading(false);
                    if (response && response.code === 200) {
                        successToast('Category code deleted successfully!');
                        getAllCodes();
                        resolve(row);
                    } else {
                        failureToast(response.message)
                        reject(response.message);
                    }
                })
                .catch(err => { setLoading(false); reject(err); });
        });
        return p;
    }

    function createCodesPayload(row) {
        const request = {
            code: row.code,
            description: row.description,
            frequency: row.frequency[0].value,
            notAllowedCategoryCode: row.notAllowedCategoryCode.map(item => item.value).join(","),
            status: row.status
        }
        return request;
    }

    async function saveScheme(row) {
        setLoading(true);

        const request = createCodesPayload(row);
        const isRowNew = row.fromBE !== true;

        const p = new Promise((resolve, reject) => {
            fetchAllPostPromisedData(`${endpoint.categoryCode}${isRowNew ? '' : '/' + row.id}`, request, isRowNew ? 'post' : 'put')
                .then(response => {
                    setLoading(false);
                    if (response && response.code === 200) {
                        successToast(`Category code saved successfully!`);
                        // if (isRowNew) {
                            getAllCodes();
                        // }
                        resolve(row);
                    } else {
                        failureToast(response.message)
                        reject(response.message);
                    }
                })
                .catch(err => { setLoading(false); reject(err); });
        });
        return p;
    }

    return (
        <Box className='category-code-screen'>
            <Typography variant={'h6'} >Category code</Typography>
            <Card className='category-code-table'>
                <MuiTable
                    rows={codes}
                    columns={columns}
                    headerComp={<div></div>}
                    userPermissions={userPermissions}
                    loading={loading}
                    asyncDeleteRow={deleteScheme}
                    asyncSaveRow={saveScheme}
                    // useDeleteIconForCancel={true}
                    extraHeight={110}
                    disableVirtualization={true}
                    pageSize={100}
                    minHeight={156}
                    rowClassifier={"status"}
                />
            </Card>
        </Box>);
}

export default ConcessionCode;