import {
  getBatchList,
  getBusinessAreaDropdowns,
  getPrintIdCardDropdowns,
  getStudentApplications,
  getCourseList,
} from "./service";
import { pendingRfIdFilter } from "./printIdCardAndMapRfid/constant";
import { useEffect, useState } from "react";
import { constructDropdownArray, fetchAllPromisedData, isFormFieldEmpty } from "../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "../../common/constant";

export const useFetchDropdown = (businessAreaType) => {
  const [dropdown, setDropdown] = useState({});
  const [dropdownLoader, setDropdownLoader] = useState(false);
  useEffect(() => {
    const fetchDropdown = async () => {
      setDropdownLoader(true);
      const response = businessAreaType
        ? await getBusinessAreaDropdowns(businessAreaType)
        : await getPrintIdCardDropdowns();

      setDropdown(response);
      setDropdownLoader(false);
    };
    fetchDropdown();
  }, []);

  return [dropdown, dropdownLoader];
};

const getDropdownFormat = (list) => {
  if (Array.isArray(list)) {
    return list.map((item) => ({ label: item?.batchName, value: item.id }));
  } else {
    return [];
  }
};
const getCourseDropdownFormat = (list) => {
  if (Array.isArray(list)) {
    return list.map((item) => ({ label: item, value: item }));
  } else {
    return [];
  }
};
export const useGetBatchList = (
  businessArea,
  terms = [],
  batchType = "PRIMARY"
) => {
  const [batches, setBatches] = useState([]);

  useEffect(() => {
    // console.log(typeof terms);
    const getBatches = async () => {
      if (businessArea && terms.length > 0) {
        try {
          const response = await getBatchList({
            businessArea: [businessArea],
            term: terms,
            ...(batchType && { batchType: batchType }),
          });
          if (response?.code === 200) {
            setBatches(
              getDropdownFormat(response.data.batchSearchResponseDTOList)
            );
          } else {
            setBatches([]);
          }
        } catch (error) {}
      }
    };
    getBatches();
  }, [businessArea, terms]);
  return batches;
};

export const useGetBatchListByBuAcadGroupTermCourseId = (
  businessArea,
  academicGroup,
  terms = [],
  courseIds = []
) => {
  const [batches, setBatches] = useState([]);

  useEffect(() => {
    const getBatches = async () => {
      if (
        businessArea &&
        academicGroup &&
        terms.length > 0 &&
        courseIds?.length > 0
      ) {
        try {
          const response = await getBatchList({
            businessArea: [businessArea],
            academicGroup: academicGroup,
            term: terms,
            courseId: courseIds,
            batchStatus:"ACTIVE"
          });
          if (response?.code === 200) {
            setBatches(
              getDropdownFormat(response.data.batchSearchResponseDTOList)
            );
          } else {
            setBatches([]);
          }
        } catch (error) {}
      } else {
        setBatches([]);
      }
    };
    getBatches();
  }, [businessArea, academicGroup, terms, courseIds]);
  return batches;
};

export const useGetCourseIdList = (businessArea, academicGroup, terms) => {
  const [courseId, setCourseId] = useState([]);

  useEffect(() => {
    const getCourseId = async () => {
      const conditionForApi = Array.isArray(terms)
        ? businessArea && terms.length > 0
        : businessArea && terms;
        
      if (conditionForApi) {
        try {
          const response = await getCourseList({
            businessAreaId: businessArea,
            academicGroup: academicGroup,
            termId: Array.isArray(terms) ? terms[0] : terms,
          });
          if (response?.code === 200) {
            setCourseId(getCourseDropdownFormat(response.data.courseIds));
          } else {
            setCourseId([]);
          }
        } catch (error) {
        }
      }
    };
    getCourseId();
  }, [businessArea, academicGroup, terms]);
  return courseId;
};

export const useGetBatchListV2 = (
  businessArea,
  terms = "",
  courseId = "",
  batchType = "PRIMARY"
) => {
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getBatches = async () => {
      setIsLoading(true);
      if (businessArea && terms && courseId) {
        try {
          const response = await getBatchList({
            businessArea: [businessArea],
            term: [terms],
            courseId: [courseId],
            ...(batchType && { batchType: batchType }),
          });
          if (response?.code === 200) {
            setBatches(
              getDropdownFormat(response.data.batchSearchResponseDTOList)
            );
          } else {
            setBatches([]);
          }
        } catch (error) {}
      }
      setIsLoading(false);
    };
    getBatches();
  }, [businessArea, terms, courseId]);
  return [batches, isLoading];
};

export const useGetBatchListByTermBUCourseId = (
  businessArea,
  terms = [],
  courseIds = [],
  emptyCourseIdAllowed = false,
  batchType = "PRIMARY"
) => {
  const [batches, setBatches] = useState([]);
  const [batchesLoading, setBatchesLoading] = useState(false);

  useEffect(() => {
    // console.log(typeof terms);
    const getBatches = async () => {
      if (
        businessArea &&
        terms.length > 0 &&
        (courseIds.length > 0 || emptyCourseIdAllowed)
      ) {
        try {
          setBatchesLoading(true);
          const response = await getBatchList({
            businessArea: [businessArea],
            term: terms,
            courseId: courseIds,
            ...(batchType && { batchType: batchType }),
          });
          if (response?.code === 200) {
            setBatches(
              getDropdownFormat(response.data.batchSearchResponseDTOList)
            );
          } else {
            setBatches([]);
          }
          setBatchesLoading(false);
        } catch (error) {}
      }
    };
    getBatches();
  }, [businessArea, terms, courseIds]);
  return [batches, batchesLoading];
};

export const useStudentApplicationList = (accessBusinessAreas = []) => {
  const [applications, setApplications] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const getApplications = async (
    page = 0,
    updatedFilter = {},
    cb = () => {}
  ) => {
    try {
      setLoading(true);
      // debugger
      const appliedBusinessArea = updatedFilter?.businessArea
        ? [updatedFilter?.businessArea.value]
        : accessBusinessAreas;
      const request = { businessArea: appliedBusinessArea };
      if (updatedFilter?.typeOfCheckbox)
        request[updatedFilter?.typeOfCheckbox] = updatedFilter?.userInput;
      const finalRequest = { ...updatedFilter, ...request };
      if (finalRequest?.batchIds?.length > 0) {
        finalRequest["allocationStatus"] = "ALLOCATE";
      }
      const response = await getStudentApplications(finalRequest, page);
      if (response?.code === 200) {
        const updatedResponse =
          response.data.studentApplicationDetailsSearchResponseDTOList?.map(
            (item) => ({ ...item, issueType: item?.rfid ? "Reprint" : "New" })
          );
        setApplications(updatedResponse);
        setTotalPage(response.data.totalPage);
        setTotalRecords(response?.data?.totalRecords);
        setLoading(false);
        cb(updatedResponse);
      } else {
        setApplications([]);
        setTotalRecords(0);
        setTotalPage(0);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const resetApplications = () => {
    setApplications([]);
    setTotalRecords(0);
    setTotalPage(0);
    setLoading(false);
  };
  return [
    applications,
    isLoading,
    getApplications,
    totalPage,
    totalRecords,
    resetApplications,
  ];
};

export const useDefaultList = (
  filter,
  accessBusinessAreas = [],
  currentPageState
) => {
  const [applications, setApplications] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const getApplications = async (page = 0, cb = () => {}) => {
    if (accessBusinessAreas.length > 0) {
      try {
        setLoading(true);
        const response = await getStudentApplications(
          {
            ...filter,
            businessArea: accessBusinessAreas,
            rfidMappedApplication: false,
          },
          page
        );
        if (response?.code === 200) {
          const updatedResponse =
            response.data.studentApplicationDetailsSearchResponseDTOList?.map(
              (item) => ({ ...item, issueType: item?.rfid ? "Reprint" : "New" })
            );
          setApplications(updatedResponse);
          setTotalPage(response.data.totalPage);
          setTotalRecords(response?.data?.totalRecords);
          setLoading(false);
          cb(updatedResponse);
        } else {
          setApplications([]);
          setTotalRecords(0);
          setTotalPage(0);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    getApplications();
  }, [accessBusinessAreas, currentPageState]);
  return [applications, isLoading, getApplications, totalPage, totalRecords];
};

export const useFetchPendingRfIdList = (
  accessBusinessAreas = [],
  setLoading = () => {}
) => {
  const [applications, setApplications] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const getApplications = async (
    page = 0,
    updatedFilter = {},
    cb = () => {}
  ) => {
    if (accessBusinessAreas.length > 0) {
      try {
        setLoading(true);
        const response = await getStudentApplications(
          {
            ...pendingRfIdFilter,
            ...updatedFilter,
            businessArea: accessBusinessAreas,
          },
          page
        );
        if (response?.code === 200) {
          const updatedResponse =
            response.data.studentApplicationDetailsSearchResponseDTOList?.map(
              (item) => ({ ...item, issueType: item?.rfid ? "Reprint" : "New" })
            );
          setApplications(updatedResponse);
          setTotalPage(response.data.totalPage);
          setTotalRecords(response?.data?.totalRecords);
          setLoading(false);
          cb(updatedResponse);
        } else {
          setApplications([]);
          setTotalPage(0);
          setLoading(false);
          setTotalRecords(0);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return [applications, getApplications, totalPage, totalRecords];
};

export const useFetchEmployeeDetails = (employeeId) => {
  const [employeeDetails, setEmployeeDetails] = useState({});

  useEffect(() => {
    if (employeeId) fetchDetails();
  }, [employeeId]);

  const fetchDetails = async () => {
    const details = await fetchAllPromisedData(
      `${endpoint.getUserByEmployeeId}/${employeeId}`,
      true
    );
    if (details && details?.code == 200) setEmployeeDetails(details?.data);
  };

  return employeeDetails;
};

// this generic customHook can be used to fetch batches dropdown depending on any mentioned parameters be it array or single select
export const useFetchBatches = (businessArea, term, courseId) => {
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchBatches = async () => {
    setIsLoading(true);
    try {
      const reqBody = {
        businessArea: Array.isArray(businessArea) ? businessArea : businessArea ? [businessArea] : [],
        courseId: Array.isArray(courseId) ? courseId : courseId ? [courseId] : [],
        term: Array.isArray(term) ? term : term ? [term] : [],
      }
      const res = await getBatchList(reqBody);
      setBatches(constructDropdownArray(res?.data?.batchSearchResponseDTOList, 'batchName', 'id'))
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    !isFormFieldEmpty(businessArea) && fetchBatches();
  } ,[businessArea, term, courseId]);

  return [batches, isLoading];
};