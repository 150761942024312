import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as LiftSideImage } from "../../../../../assets/img/svg/faculty-roaster-bg.svg";
import "../../common/utils/scss/Acommon-style.scss";
import "./style.scss";
import { loadDropdowns } from "redux/facultyRoaster/actions";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllPostPromisedData, updateRequest } from "../../common/utils/methods/commonMethods/utilityMethod";
import PageLoadingAnimation from 'views/pages/manage/common/commonComponents/PageLoadingAnimation';
import { endpoint, pages } from "../../common/constant";
import { successToast } from "../../common/utils/methods/toasterFunctions/function";
import { useHistory } from "react-router";
import { PermissionContext, RolePermissions } from 'appContext'
import { PermisionDenied } from '../../common/commonComponents'
import AddRoasterForm from "./AddRoasterForm";

const CreateFacultyRoaster = (props) => {


  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages['facultyRoaster']['id']);

  const history = useHistory();
  const facultyRoasterState = useSelector((state) => state.facultyRoaster);
  const { dropdowns } = facultyRoasterState;

  const [selectedFaculty,setSelectedFaculty] = useState(null);
  const [apiLoader, setApiLoader] = useState(false);

  const dispatch = useDispatch();

  const fetchData = async () => {
    setApiLoader(true);
    await dispatch(loadDropdowns());
    setApiLoader(false)
  }

  useEffect(() => {
    fetchData();
  }, []);

  const saveAcademicPlanHandler = async () => {
    setApiLoader(true)

    // // console.log(updateRequest(form), "logging data")
    // const request = { ...updateRequest(form), packageExtId: form?.packageId?.label }
    // // console.log('final req', request)
    // const response = await fetchAllPostPromisedData(endpoint?.createAcademicPlan?.createPlan, request, 'post')

    // if(response?.code===200){
    //   successToast(response?.message)
    //   history.push(`/admin/testScheduling/academicPlan/view/${response?.data?.id}`)
    // }
    // setApiLoader(false);
  }
  return (
    userPermissions ? (
      apiLoader ? (
        <PageLoadingAnimation />
      ) : (
          <div className="remove-container-top-space faculty-roaster-container">
            <LiftSideImage />
            <AddRoasterForm
              dropdown={dropdowns || {}}
              setSelectedFaculty={setSelectedFaculty}
              selectedFaculty={selectedFaculty}
            />
          </div>
      )
    ) : (
      <PermisionDenied />
    )
  );
};

export default CreateFacultyRoaster;
