import React from 'react';
import {
  Card,
  CardHeader,
  Input,
  Container,
  Button,
  Table,
  Row,
} from 'reactstrap';
import { useState } from 'react';
import { failureToast } from '../../common/utils/methods/toasterFunctions/function';
import {
  TDROW,
  THIRDPARTYSYSTEMTDROW,
  MasterHeader,
} from '../../common/commonComponents/masterRow/masterRow';
import {
  masterServiceBaseUrl,
  successToast,
  putpost,
  putpost2,
  deleteAPI,
} from 'services/http';
import moment from 'moment';
import CreateButton from "components/FormComponent/Button/New/index";
import UpdateButton from "components/FormComponent/Button/Update/index";
import { ButtonText } from "variables/Buttons";

var _ = require('lodash');

const ThirdPartySystemsTable = (props) => {
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [tableDataKey, settableDataKey] = useState('tableDataKey');
  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: '__new',
        thirdPartySystemKey: null,
        status: 'ACTIVE',
        thirdPartyDesc: null,
        thirdPartyDispValue: null,
        effectiveDate: null,
      },
      ...props.classArray,
    ]);
  };
  const saveAll = (cb) => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if (el['editMode']) {
        if (
          el['thirdPartySystemKey'] === null ||
          !el['thirdPartySystemKey'].trim()
        ) {
          isValid = false;
          failureToast('Please enter the value of Third Party System Key');
          return;
        } else if (
          el['thirdPartyDesc'] === null ||
          !el['thirdPartyDesc'].trim()
        ) {
          isValid = false;
          failureToast('Please enter the value of Description');
          return;
        } else if (
          el['thirdPartyDispValue'] === null ||
          !el['thirdPartyDispValue'].trim()
        ) {
          isValid = false;
          failureToast('Please enter the value of Display Value');
          return;
        }
        if (!isValid) {
          return;
        }
        if (_tempArray[index]['__status'] == '__new') {
          putpost(
            masterServiceBaseUrl + '/businessAreaThirdPartySystem/createThirdPartySystem',
            (data) => {
              successToast(data['message']);
              setisSaveVisible(false);
              props.fetchClasses(
                masterServiceBaseUrl +
                  '/businessAreaThirdPartySystem/getAllThirdPartySystem'
              );
            },
            (data) => {
              failureToast(data['message']);
            },
            _tempArray[index],
            'post'
          );
        } else {
          putpost(
            masterServiceBaseUrl +
              '/businessAreaThirdPartySystem/updateThirdPartySystem/' +
              _tempArray[index].id,
            (data) => {
              successToast(data['message']);
              setisSaveVisible(false);
              delete _tempArray[index]['editMode'];
              props.setclassArray(_tempArray);
              settableDataKey(new Date().getMilliseconds());
            },
            (data) => {
              failureToast(data['message']);
            },
            _tempArray[index],
            'put'
          );
        }
      } else {
      }
    });
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
        // settableDataKey(new Date().getMilliseconds())
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      cb();
    }
  };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]['editMode'] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };
  const deleteRow = (index) => {
    if (props.classArray[index]['__status'] == '__new') {
      let n = props.classArray;
      n.splice(index, 1);
      props.setclassArray(n);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
    } else {
      if (
        window.confirm(
          'Are you sure you want to delete this Third Party System Detail?'
        )
      ) {
        putpost2(
          masterServiceBaseUrl +
            '/businessAreaThirdPartySystem/deleteThirdPartySystem/' +
            props.classArray[index].id,
          (data) => {
            successToast(data['message']);
            if (props.classArray.length == 1) {
              props.previousPage();
              props.fetchClasses(
                masterServiceBaseUrl +
                  '/businessAreaThirdPartySystem/getAllThirdPartySystem'
              );
            } else {
              var _tempArray = props.classArray;
              if (_tempArray[index]['editMode']) {
                setisSaveVisible(false);
              }
              _tempArray.splice(index, 1);
              props.setclassArray(_tempArray);
            }
            settableDataKey(new Date().getMilliseconds());
          },
          (data) => {
            failureToast(data['message']);
          },
          'Delete'
        );
      }
    }
  };
  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
  };
  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Third Party Systems</h3>
                <CreateButton
									text={ButtonText.ThirdPartySystems.addNew}
									disabled={isSaveVisible}
									className={"floatRight mx-1"+(isSaveVisible?" btn-dark":null)} 
									onClick={() => {newRow()}}
								/>
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout">
                  <MasterHeader type={'Third Party Systems'} />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                          !el['editMode'] ? (
                            <THIRDPARTYSYSTEMTDROW
                              type={'thirdPartySystems'}
                              index={index}
                              el={el}
                              editRow={editRow}
                              deleteRow={deleteRow}
                            />
                          ) : (
                            <tr key={index + '-class'}>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="thirdPartySystemKey"
                                  maxLength="50"
                                  defaultValue={el['thirdPartySystemKey']}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      'thirdPartySystemKey'
                                    );
                                  }}
                                  placeholder="Example:1"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="effectiveDate"
                                  disabled={true}
                                  defaultValue={
                                    el['__status'] == '__new'
                                      ? null
                                      : moment(el['eff_date']).format(
                                          'DD-MM-YYYY'
                                        )
                                  }
                                  placeholder="Example:01-01-2020"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="thirdPartyDesc"
                                  maxLength="50"
                                  defaultValue={el['thirdPartyDesc']}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      'thirdPartyDesc'
                                    );
                                  }}
                                  placeholder="Description"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="thirdPartyDispValue"
                                  maxLength="50"
                                  defaultValue={el['thirdPartyDispValue']}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      'thirdPartyDispValue'
                                    );
                                  }}
                                  placeholder="Display Value"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <label className="custom-toggle mx-auto ml-2">
                                  <input
                                    data-testid="activeInactiveInput"
                                    checked={
                                      el.status == 'ACTIVE' ? true : false
                                    }
                                    type="checkbox"
                                    id={el.id}
                                    onChange={(e) => {
                                      settableDataKey(
                                        new Date().getMilliseconds()
                                      );
                                      let n = 'ACTIVE';
                                      if (!e.target.checked) {
                                        n = 'INACTIVE';
                                      }
                                      updateKey(index, n, 'status');
                                    }}
                                  />
                                  <span
                                    data-testid="activeInactiveLabel"
                                    style={{ width: '72px' }}
                                    className="custom-toggle-slider rounded-circle activeToggle"
                                    data-label-off="Inactive"
                                    data-label-on="Active"
                                  />
                                </label>
                              </td>
                              <td className="text-center">
                                {isSaveVisible ? (
                                  <>
                                    {' '}
                                    <UpdateButton text={ButtonText.ThirdPartySystems.save} onClick={() => { saveAll() }} data-testid="saveBtn"/>
                                    <Button
                                      data-testid="closeBtn"
                                      color="danger"
                                      size="sm"
                                      type="button"
                                      onClick={() => {
                                        let n = props.classArray;
                                        if (n[index]['__status'] == '__new') {
                                          n.splice(index, 1);
                                        } else {
                                          // n[index]['editMode']=false
                                          props.fetchClasses(
                                            masterServiceBaseUrl +
                                              '/businessAreaThirdPartySystem/getAllThirdPartySystem'
                                          );
                                        }
                                        props.setclassArray(n);
                                        setisSaveVisible(false);
                                      }}
                                    >
                                      <i className="fas fa-times" />
                                    </Button>{' '}
                                  </>
                                ) : (
                                  <Button
                                    color="info"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                      deleteRow(index);
                                    }}
                                  >
                                    <i className="fas fa-trash" />
                                  </Button>
                                )}
                              </td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default ThirdPartySystemsTable;
