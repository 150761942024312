import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment/moment'
import React, { useState } from 'react'
import { ALLOCATE_STUDENTS_TABLE_HEADERS as TABLE_HEADERS } from '../constant/allocate-students'
import AllocateStudentActions from './AllocateStudentActions'
import ConfirmationDialog from './ConfirmationDialog'
import MultiSelectFooter from './MultiSelectFooter'
import NoRowsAllocation from './NoRowsAllocation'
import { ReactComponent as Sort } from '../../../../../assets/img/svg/sort.svg';
import { ReactComponent as SortDesc } from '../../../../../assets/img/svg/sortDesc.svg';
import { useHistory } from 'react-router'


const StudentList = ({ rowsData, rowCount, page, onPageChange, allocateStudentHandler, availableForSelection, batchName }) => {
  const history = useHistory();
  const GRID_PAGE_SIZE = 50;

  const [openDialog, setOpenDialog] = useState(false);
  const [studentId, setStudentId] = useState();
  const [selectionModel, setSelectionModel] = useState([]);
  const [isMultiAllocate, setIsMultiAllocate] = useState(false);

  const isRowSelectable = (params) => {
    if(!params?.row?.isEligibleProgramActionForAllocation){
      return false;
    }
    else {
      if (selectionModel.length >= availableForSelection) {
        if (selectionModel.includes(params.id)) return true;
        else return false;
      }
      return true;
    }
  }

  const StudentListHeader = [
    { field: 'psid', headerName: TABLE_HEADERS.PSID, minWidth: 100, flex: 1, renderCell: (params) => <strong className='color-primary cursor' onClick={() => window.open(history.createHref({ pathname: `admin/edp/studentDetails/${params?.value}` }), '_blank')}>{params.value}</strong> },
    { field: 'applicationId', headerName: TABLE_HEADERS.APPLICATION_NO, minWidth: 150, flex: 1 },
    { field: 'studentName', headerName: TABLE_HEADERS.STUDENT_NAME, minWidth: 200, flex: 1 },
    { field: 'registrationDate', headerName: TABLE_HEADERS.REGISTRATION_DATE, minWidth: 150, flex: 1, renderCell: (params) => <span>{params?.value ? moment(params.value).format('DD-MM-YYYY') : '-'}</span> },
    { field: 'termDispVal', headerName: TABLE_HEADERS?.TERM, minWidth: 150, flex: 1, valueGetter: (params) => params?.value ?? '-' },
    { field: 'courseId', headerName: TABLE_HEADERS.COURSE, minWidth: 150, flex: 1 },
    { field: 'classes', headerName: TABLE_HEADERS.CLASS, minWidth: 100, flex: 1 },
    { field: 'programAction', headerName: TABLE_HEADERS.PROGRAM_ACTION, minWidth: 150, flex: 1 },
    { field: 'Actions', headerName: 'Actions', type: 'actions',minWidth: 150, flex: 1, filterable: false, sortable: false, renderCell: (params) => <AllocateStudentActions row={params.row} setOpenDialog={setOpenDialog} setId={setStudentId} />},
  ]

  return (
    <div className='mt-3' style={{ minHeight: 370, flexGrow: 1, display: 'flex', flexFlow: 'column' }}>
      <DataGrid
        rows={rowsData}
        columns={StudentListHeader}
        getRowId={(row) => row.applicationId}
        checkboxSelection={true}
        density={"compact"}
        rowHeight={44}
        getRowHeight={(params) => 44}
        headerHeight={56}
        pagination
        pageSize={GRID_PAGE_SIZE}
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        paginationMode={"server"}
        page={page}
        onPageChange={(e) => onPageChange(e)}
        disableColumnMenu
        loading={false}
        components={{
          ColumnSortedDescendingIcon: SortDesc,
          ColumnSortedAscendingIcon: Sort,
          // LoadingOverlay: () => <ALoader />
          NoRowsOverlay : () => <NoRowsAllocation batchName={batchName} />
        }}
        // onRowClick={() => console.log('row')}
        isRowSelectable={isRowSelectable}
        disableSelectionOnClick
        selectionModel={selectionModel}
        onSelectionModelChange={(newSelectionModel) => {
          if (newSelectionModel.length > availableForSelection) {
            setSelectionModel(newSelectionModel.slice(0, availableForSelection))
            return;
          }
          setSelectionModel(newSelectionModel)
        }}
        rowCount={rowCount}
        rowBuffer={10}
        hideFooterSelectedRowCount={true}
        sx={{
          color: '#262626',
          fontFamily: 'inherit',
          '.MuiDataGrid-cell:focus-within, .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-columnHeader:focus': {
            outline: 'none'
          },
          '.MuiDataGrid-cell--editing:focus-within, .MuiDataGrid-cell--editing:focus, .MuiDataGrid-cell--editing:focus-visible': {
            outline: 'none !important',
          },
        }}
      />
      {selectionModel.length > 0 &&
        <MultiSelectFooter
          selectionModel={selectionModel}
          setSelectionModel={setSelectionModel}
          setIsMultiAllocate={setIsMultiAllocate}
          setOpenDialog={setOpenDialog}
        />
      }
      <ConfirmationDialog
        open={openDialog}
        setOpen={setOpenDialog}
        id={studentId}
        allocateStudentHandler={allocateStudentHandler}
        isMulti={isMultiAllocate}
        selectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
      />
    </div>
  )
}

export default StudentList