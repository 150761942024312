import React, { useState, useEffect } from 'react';
import AcademicPhaseMappingTable from './academicPhaseMappingTable';
import CustomLoader from '../../common/commonComponents/Loader/loader';
import { masterServiceBaseUrl, getAPI } from 'services/http';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import { GetPagination } from '../../common/commonComponents/pagination/pagination';

const AcademicPhaseMapping = (props) => {
  const [classArray, setclassArray] = useState([]);
  const [apiloader, setapiloader] = useState(false);
  const [state, setstate] = useState([]);
  const [serverData, setServerData] = useState([]);
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [term, setTerm] = useState([]);
  const [academicYear, setAcademicYear] = useState([]);
  const [courseAttribute, setCourseAttribute] = useState([]);
  const [attributeValue, setAttributeValue] = useState([]);
  const [academicPhase, setAcademicPhase] = useState([]);
  const [groupCode, setGroupCode] = useState([]);
  const [companyCode, setCompanyCode] = useState([]);
  const [academicCareer, setAcademicCareer] = useState([]);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });

  const pageSize = 10;
  const fetchAcademicPhaseMapping = async (url, type) => {
    setapiloader(true);
    getAPI(
      url,
      (data) => {
        AcademicPhaseDataHandler(data?.data, type);
        setapiloader(false);
      },
      (data) => {
        failureToast(data['message']);
        setapiloader(false);
      }
    );
  };

  const AcademicPhaseDataHandler = (data, type) => {
    switch (type) {
      case 'TABLE_RECORDS':
        setstate(data);
        setServerData(data);
        setPagination(data);
        break;
      case 'ACADEMIC_YEAR':
        const academicYearTemp = [];
        data.map((item) => {
          academicYearTemp.push({
            id: item.id,
            text: item.academicYearDisplayValue,
          });
        });
        setAcademicYear(academicYearTemp);
        break;
      case 'COURSE_ATTRIBUTE':
        const courseAttributeTemp = [];
        data.map((item) => {
          if (item.courseAttributeDispValue.toUpperCase() === 'CRCT') {
            courseAttributeTemp.push({
              id: item.id,
              text: item.courseAttributeDispValue,
            });
          }
        });
        setCourseAttribute(courseAttributeTemp);
        break;
      case 'ATTRIBUTE_VALUE':
        setAttributeValue(data);
        break;
      case 'ACADEMIC_PHASE':
        const academicPhaseTemp = [];
        data.map((item) => {
          academicPhaseTemp.push({
            id: item.id,
            text: item.academicPhaseDisplayValue,
          });
        });
        setAcademicPhase(academicPhaseTemp);
        break;
      case 'TERM':
        const termTemp = [];
        data.map((item) => {
          termTemp.push({
            id: item.id,
            text: item.termDispValue,
          });
        });
        setTerm(termTemp);
        break;
      case 'GROUP_CODE':
        const groupCodeTemp = [];
        data.map((item) => {
          groupCodeTemp.push({
            id: item.id,
            text: item.groupCodeDispValue,
          });
        });
        setGroupCode(groupCodeTemp);
        break;
      case 'COMPANY_CODE':
        setCompanyCode(data);
        break;
      case 'ACADEMIC_CAREER':
        setAcademicCareer(data);
        break;
      default:
        break;
    }
  };

  const setPagination = (paramsdata) => {
    let n = pagination;
    if (paramsdata.length > pageSize) {
      n['nextPage'] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n['totalPage'] = Math.floor(paramsdata.length / pageSize);
    if (paramsdata.length % pageSize != 0) {
      n['totalPage'] += 1;
    }
    setpagination(n);
    setclassArray(
      paramsdata.slice(
        pagination.currentPage * pageSize - pageSize,
        pagination.currentPage * pageSize
      ) || []
    );
    setapiloader(false);
  };

  const previousPage = () => {
    if (pagination.currentPage != 1) {
      setclassArray(
        state.slice(
          (pagination.currentPage - 1) * pageSize - pageSize,
          (pagination.currentPage - 1) * pageSize
        )
      );
      let n = pagination;
      n['currentPage'] = n['currentPage'] - 1;
      if (n['currentPage'] == 1) {
        n['previousPage'] = null;
      } else {
        n['previousPage'] = n.currentPage;
      }
      setpagination(n);
    }
  };

  useEffect(() => {
    fetchAcademicPhaseMapping(
      `${masterServiceBaseUrl}/academicPhaseMappingMaster/getAllAcademicPhaseMappingMaster`,
      'TABLE_RECORDS'
    );
    fetchAcademicPhaseMapping(
      `${masterServiceBaseUrl}/academicYear/active`,
      'ACADEMIC_YEAR'
    );
    fetchAcademicPhaseMapping(
      `${masterServiceBaseUrl}/courseAttribute/getAllActiveCourseAttribute`,
      'COURSE_ATTRIBUTE'
    );
    fetchAcademicPhaseMapping(
      `${masterServiceBaseUrl}/courseAttributeValue/getAllActiveCourseAttributeValue`,
      'ATTRIBUTE_VALUE'
    );
    fetchAcademicPhaseMapping(
      `${masterServiceBaseUrl}/academicPhase/active`,
      'ACADEMIC_PHASE'
    );
    fetchAcademicPhaseMapping(
      `${masterServiceBaseUrl}/term/getAllActiveTerm`,
      'TERM'
    );
    fetchAcademicPhaseMapping(
      `${masterServiceBaseUrl}/groupCode/getAllActiveGroupCode`,
      'GROUP_CODE'
    );
    fetchAcademicPhaseMapping(
      `${masterServiceBaseUrl}/companyCode/getAllCompanyCode`,
      'COMPANY_CODE'
    );
    fetchAcademicPhaseMapping(
      `${masterServiceBaseUrl}/academicCareer/getAllAcademicCareer`,
      'ACADEMIC_CAREER'
    );
  }, []);

  return (
    <>
      {apiloader ? (
        <div
          className='mx-auto text-center py-5 my-5'
          style={{ height: '100vh' }}
        >
          <CustomLoader apiLoader={apiloader} />
        </div>
      ) : (
        <>
          <AcademicPhaseMappingTable
            previousPage={previousPage}
            fetchClasses={fetchAcademicPhaseMapping}
            setclassArray={setclassArray}
            classArray={classArray}
            isSaveVisible={isSaveVisible}
            setisSaveVisible={setisSaveVisible}
            serverData={serverData}
            term={term}
            academicYear={academicYear}
            courseAttribute={courseAttribute}
            attributeValue={attributeValue}
            academicPhase={academicPhase}
            groupCode={groupCode}
            allCompanyCode={companyCode}
            academicCareer={academicCareer}
          />
          {pagination.totalPage > 1 ? (
            <GetPagination
              isSaveVisible={isSaveVisible}
              fetchClasses={fetchAcademicPhaseMapping}
              setclassArray={setclassArray}
              pagination={pagination}
              setpagination={setpagination}
              pageSize={pageSize}
              state={state}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default AcademicPhaseMapping;
