import React, { useState, useRef } from "react";
import { withRouter } from "react-router";
import { FormText, CardBody, Card, Row } from "reactstrap";
import { CircularProgressbar } from 'react-circular-progressbar';
import { documentServiceBaseUrl, putpost, uploadpost } from "services/http";
import { successToast, failureToast } from '../../../common/utils/methods/toasterFunctions/function';
import { validateFileName } from "../../utils/methods/validations/validation";
import style from "./dragAndDrop.module.scss";
import { PermisionDenied } from "../../../common/commonComponents";
import CustomButton from "components/CustomButton";
import { ButtonText } from "variables/Buttons";
import { regex, endpoint } from ".././../constant"
import 'react-circular-progressbar/dist/styles.css';
import ConfirmationDialog from "../ConfirmationDialog";
import { ReactComponent as CSV_ICON } from "../../../../../../assets/img/svg/CSV.svg";


const CustomUpload = (props) => {
  const {
    documentTitle,
    documentType = "File",
    isFileValid,
    filename,
    file,
    progressBarStatus,
    setProgressBarStatus = () => { },
    setISFileValid = () => { },
    setFile = () => { },
    setFileName = () => { },
    setISUploading = () => { },
    isUploading,
    handleFile = () => { },
    dataToServer,
    saveHandler = () => { },
    showDescription,
    descriptionField,
    description,
    userPermissions,
    goBack = () => { },
    typeAllowed = "csv",
    confirmationRequired = false,
    confimationMsg = 'Do you want to continue upload',
    showCloseButton=true,
  } = props;

  const [changeIconColor, setChangeIconColor] = useState(false);
  const inputRef = useRef(null);
  const [confirmation,setConfimation] = useState(false);

  //get uploading endpoint from DMS (api call)
  const getFilePath = () => {
    try {
      setConfimation(false);
      setISUploading(true);
      // alert(isUploading)
      if (!file) {
        failureToast("Please Select File");
        return;
      }

      if (showDescription && !description) {
        failureToast("Please Enter Description");
        return;
      }
      setISUploading(true);
      const formData = {
        ...dataToServer,
        fileName: file.name,
        docType: typeAllowed,
        description: description
      };

      putpost(endpoint.fetchS3Url.getUrl,
        // `${documentServiceBaseUrl}/documentManagement/getUploadUrl`,
        (data) => {
          if (data.code === 200 && data.data) {
            saveDataToStorage(data.data);
            setProgressBarStatus(15);
          }
        },
        (data) => {
          setProgressBarStatus(0);
          setISUploading(false);
          failureToast(data["message"]);
        },
        formData,
        "post"
      );
    } catch (e) {
      console.log(e);
    }
  };

  //this is file uploadImage part
  const saveDataToStorage = (res) => {
    try {
      const formData = new FormData();
      formData.append("File", file);
      // formData.append("destinationfolder", res.bucket);
      setProgressBarStatus(30);
      uploadpost(
        res.storage,
        (data) => {
          saveHandler({
            ...res
          });
          setProgressBarStatus(45);
          // successToast(data["message"]);
        },
        (data) => {
          setProgressBarStatus(0);
          setISUploading(false);
          failureToast("Unable to upload file, please try after sometime.");
        },
        file,
        file?.type,
        file?.name
      );
    } catch (e) {
      setProgressBarStatus(0);
      setISUploading(false);
      failureToast("Unable to upload file, please try after sometime.");
      console.log(e);
    }
  };

  //update to DMS (api call)
  const updateResponseToServer = (response) => {
    try {
      setProgressBarStatus(55);
      const formData = {
        bucketName: response.Bucket,
        errorCode: "",
        etag: response.ETag,
        processId: response.processId,
        s3Url: response.Location,
        serviceName: dataToServer.serviceName,
      };

      putpost(
        `${documentServiceBaseUrl}/documentManagement/s3Uploaded`,
        (data) => {
          if (data.code === 200 && data.data) {
            setProgressBarStatus(75);
            saveHandler(response);
          }
        },
        (data) => {
          setISUploading(false);
          failureToast(data["message"]);
        },
        formData,
        "post"
      );
    } catch (e) {
      console.log(e);
    }
  };

  const dragOverHandler = (e) => {
    try {
      e.preventDefault();
      setFileName("Release to Upload");
    } catch (e) {
      //console.log('Error', e);
    }
  };

  const dragLeaveHandler = (e) => {
    try {
      setFileName("Drag & Drop to Upload");
    } catch (e) {
      //console.log('Error', e);
    }
  };

  const dropHandler = (e) => {
    try {
      e.preventDefault();
      // console.log('Inside the dragger handler',e.dataTransfer.files[0])
      showFile(e.dataTransfer.files[0], null);
      // setFileName("Drag & Drop to Upload File");
    } catch (e) {
      console.log("Error", e);
    }
  };

  const showFile = (dataFile, targetFile) => {
    // console.log('Inside the show file',e.target.files[0]);
    try {
      const fileSelected = dataFile || targetFile;
      const fileArr = fileSelected.name.split(".");

      if (!fileSelected) {
        setFileName("Drag & Drop to Upload");
        setISFileValid(false);
        return;
      }

      // if (!validateFileName(fileArr[0]) || fileArr.length > 2) {
      if (fileArr.length > 2) {
        failureToast("File name error. Please rename file without special characters and upload");
        inputRef.current.value = "";
        return;
      }

      // const regex = /^[A-Za-z0-9 _]*$/
      // if (!regex.alphaNumericWithSpaceAndUnderscore.test(fileArr[0])) {
      //   failureToast('File name error. Please rename file without special characters and upload');
      //   inputRef.current.value = '';
      //   return;
      // }

      // alert(e.target.file[0])

      handleFile(fileSelected);
    } catch (e) {
      console.log("Error", e);
    }
  };

  const removeImageHandler = () => {
    try {
      setISFileValid(false);
      setFile(null);
      document.getElementById("uploadDoc").value = null;
      setFileName("Drag & Drop to Upload");
    } catch (e) {
      console.log("Error", e);
    }
  };

  const getAllowedExtentions = () => {
    const exts = typeAllowed.split(",").map(item => { return `.${item}` }).join(",");
    return exts ?? ''
  }

  return (
    userPermissions ?
      <>
        <Card>
          {/* <CardHeader>
            <h3>
              Upload Template
            </h3>
          </CardHeader> */}
          <CardBody>
            <div
              className={style.drag_area}
              onDragOver={(e) => dragOverHandler(e)}
              onDragLeave={(e) => dragLeaveHandler(e)}
              onDrop={(e) => dropHandler(e)}
              id="dropArea"
            >
              {isFileValid ? <i className={`fas fa-trash-alt ${style.removeImage}`} onClick={() => !isUploading && removeImageHandler()}></i> : null}

              <div className={style.drag_area_icon_area}>
                {!isUploading && <>
                  {!isFileValid ? (
                    // <i className="fas fa-cloud-upload-alt"></i>
                    <img src={require('assets/img/theme/upload-placeholder.png')} alt="Upload" style={{ "-webkit-user-drag": "none" }} />
                  ) : (
                    typeAllowed === 'csv' ? <CSV_ICON/>: <i className={`fas fa-file ${!changeIconColor ? style.drag_area_icon : style.drag_area_icon_success}`}></i>
                  )}</>
                }

                {
                  isUploading ? <div className={style.drag_progressor}><CircularProgressbar value={progressBarStatus} text={`${progressBarStatus}%`} /></div> : null
                }
              </div>


              {!isUploading && <>
                {filename ? <p className={style.drag_drop_text} id="dragger-text">{filename}</p> : <>
                  <p className={style.drag_drop_text} id="dragger-text">
                    {/* {`${filename} ${documentType}`} */}
                    {documentTitle ? documentTitle : 'Upload Your Template'}
                  </p>
                  <span className={style.drag_drop_secondary_text} id="dragger-text-secondary">
                    Drag & drop or Upload your file here
                  </span></>}</>}

              {isUploading && filename && <>
                <p className={style.drag_drop_text} id="dragger-text">
                  {/* {`${filename} ${documentType}`} */}
                  Uploading...
                </p>
                <p className={style.drag_drop_text} id="dragger-text">{filename}</p>

              </>}


              {!isFileValid ? (
                <>
                  {/* <p className={style.drag_drop_text}>OR</p> */}
                  {/* <button onClick={() => document.getElementById("uploadDoc").click()}>{`Upload ${documentType}`}</button> */}
                  <CustomButton
                    content={ButtonText.UPLOADPAGE.ADD}
                    className={'mx-1 my-1'}
                    permissionType={'C,B'}
                    icon={true}
                    onClick={() => document.getElementById("uploadDoc").click()}
                    permissionSet={userPermissions}
                  />
                </>
              ) : null}

              {/* isUploading ? (
                // <Progress animated={progressBarStatus == 100 ? false : true} color={progressBarStatus == 100 ? 'success' : 'info'} className={style.customProgressBar} value={progressBarStatus}>
                //   {progressBarStatus} %
                // </Progress>
                
              ) : null} */}

              <input onChange={(e) => showFile(null, e.target.files[0])} type="file" hidden id="uploadDoc" accept={getAllowedExtentions()} ref={inputRef} />
            </div>
            <div className="feedback">
              <FormText className="text-info">
                <span className={style.madatoryField}>*</span>{`Only file in ${typeAllowed} format will be accepted`}
              </FormText>
            </div>
            <div className="d-flex justify-content-end pt-4">

            </div>
          </CardBody>
        </Card>
        <div className="d-flex justify-content-end align-items-center">
          <CustomButton
            content={ButtonText.UPLOADPAGE.SUBMIT}
            type="submit"
            className={'reset-button mx-1 reset-button-primary'}
            permissionType={'C,B'}
            // icon={true}
            onClick={confirmationRequired ? () => setConfimation(true) : () => getFilePath()}
            permissionSet={userPermissions}
            disabled={!isFileValid || isUploading}
          />
          {showCloseButton && <CustomButton
            type="close"
            className={'reset-button mx-1'}
            content={ButtonText.UPLOADPAGE.CLOSE}
            permissionType={'C,B'}
            // icon={true}
            onClick={goBack}
            permissionSet={userPermissions}
            disabled={isUploading}
          />}
        </div>


        <ConfirmationDialog
          isOpen={confirmation}
          setIsOpen={setConfimation}
          msg={confimationMsg}
          onAgree={getFilePath}  // take to batch search page
          onDisagree={()=>setConfimation(false)}
        />


      </> : <PermisionDenied />
  );
};

export default withRouter(CustomUpload);
