import React, { useState } from 'react';
import RequiredCheck from "../requiredCheck";
import { BiFilterAlt } from 'react-icons/bi';
import { Input } from 'reactstrap';
import Style from './index.module.scss';
import InputField from "../tableEditField/inputField";
import ReactSelect from "../tableEditField/reactSelect";
import ReactSelect2 from "../tableEditField/reactSelect2";
import StatusToggle from "../tableEditField/statusToggle";

const TableHeader = ({ header, fieldDetails, permissionSet, actionExist }) => {
  // console.log('header', header);
  const [isShowSearch, setIsShowSearch] = useState({
    show: false,
    index: null
  });
  const searchInputHandler = (item) => {
    try {
      switch (item.type) {
        case 'text':
          return <InputField item={item} isSearch={true} />
          break;
        case 'react_select':
          return <ReactSelect />
          break;
        case 'react_select_2':
          return <ReactSelect2 />
          break;
        case 'Status':
          return <StatusToggle />
          return
        default:
          break;
      }
    } catch (e) {
      console.log('Error', e);
    }
  }

  return (
    <thead className="thead-light">
      <tr key={'header'}>
        {
          header !== undefined && header !== null && header.length > 0 && header.map((item, index) => {
            if (item.name === 'Action') {
              if((permissionSet?.includes('U') || permissionSet?.includes('D')) || (permissionSet?.includes('C') && actionExist)){
                return (
                  <th className="text-center white-breakSpace" key={'header' + index}>
                    {item.name}
                  </th>
                )
              }else{
                return
              }
            }
            else {
              return (
                <th className="text-center white-breakSpace" key={'header' + index}>
                  {item.name} {item.required && <RequiredCheck />}
                  {item.isFilter && <BiFilterAlt size={15} className="float-right filter-icon" onClick={() => setIsShowSearch({
                    show: !isShowSearch.show,
                    index: index
                  })} />}
                </th>
              )
            }
          })
        }
      </tr>
      {isShowSearch.show && <tr>
        {
          isShowSearch.show && fieldDetails !== undefined && fieldDetails !== null && fieldDetails.length > 0 && fieldDetails.map((item, index) => {
            return (
              <th className="text-center white-breakSpace" key={'header' + index}>
                {item.isFilter && isShowSearch.index === index && searchInputHandler(item)}
              </th>
            )
          })
        }
        <th></th>
      </tr>}
    </thead>
  )
}

export default TableHeader;
