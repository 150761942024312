import React, { useState } from 'react';
import {styled, Tabs, Tab, Box} from '@mui/material';

const StyledTabs = styled(Tabs)`
  .MuiTypography-root {
      font-family: inherit;
  }

  &.MuiTabs-root {
      border-right: none;
  }

  .MuiTab-root {
      text-transform: none;
      font-family: inherit;
      background: #EEEEEE;
      align-items: flex-start;
      min-width: 160px;
      min-height: 56px;
      font-size: 16px;
      font-weight: 600;

      &.Mui-selected {
        background: white;
        color: #00aeef;
    }
  }

  .MuiTabs-indicator {
      left: 0;
      background-color: #00aeef;
  }

`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{flexGrow: 1}}
    >
      {value === index && (
        <Box sx={{ p: 3, bgcolor: 'white'}}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const VerticalTabs = ({
    options,
    defaultValue = 0
}) => {

    const [value, setValue] = useState(defaultValue);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box className='mui-vertical-tabs' sx={{ flexGrow: 1, display: 'flex' }}>
                {options.length && 
                    <>
                        <StyledTabs
                            orientation="vertical"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: 'divider', flexShrink : 0 }}
                        >
                            {options.map((option, index) => (
                                <Tab key={option.id} label={option.label} {...a11yProps(index)} />
                            ))}
                        </StyledTabs>
                        {options.map((option, index) => (
                            <TabPanel key={option.id} value={value} index={index}>
                                {option.tabPanel}
                            </TabPanel>
                        ))}
                    </>
                }
        </Box>
    )
}

export default VerticalTabs;