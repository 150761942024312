import { Dialog } from '@mui/material';
import React, { useState } from 'react'
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { ReactComponent as CrossIcon } from '../../../../../../assets/img/svg/CrossIcon.svg'
import { ReactComponent as QuestionIcon } from '../../../../../../assets/img/svg/QuestionMarkConfirmationDialog.svg'
import AButton from 'views/pages/manage/common/form-fields-mui/AButton';

const PublishUnpublishConfirmationDialog = ({ open, setOpen, formData, isSave, onEditSave = () => { }, publishStatusTimetableHandler = () => { } }) => {

  const handleClose = () => { setOpen(false); setDisableActionButton(false); }
  const [disableActionButton, setDisableActionButton] = useState(false);

   const publishHandler = (e)=>{
    e.currentTarget.disabled = true;
    setDisableActionButton(true); 
    handleClose(); 
    if(!disableActionButton)
      onEditSave(); 
   }

  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={{ padding: "28px 24px", width: "500px" }}>
        <div className='d-flex justify-content-between align-items-start mb-3'>
          <QuestionIcon color='#414141' />
          <CrossIcon width={32} height={32} onClick={handleClose} />
        </div>

        <>
          <div
            style={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "24px",
              color: "#000000",
              marginBottom: "1rem",
            }}
          >
            {isSave && 'Are you sure you want to save changes and publish this timetable?'}
            {!isSave && formData?.publishStatus === 'P' && 'Are you sure you want to unpublish this timetable?'}
            {!isSave && formData?.publishStatus === 'U' && 'Are you sure you want to publish this timetable?'}
          </div>
          <div
            style={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#414141",
              marginBottom: "28px",
            }}
          >
            {/* if any note add here */}
          </div>
        </>

        <div className='d-flex justify-content-between'>
          <AButton variant='outline' className='button_remove_left_margin' updatedStyle={{ border: '1px solid #414141', color: '#414141', width: '180px' }} onClick={handleClose}>No</AButton>
          {isSave ? (
            <AButton variant='primary_filled' updatedStyle={{ }} disabled={disableActionButton} onClick={publishHandler}>Yes, Save and publish</AButton>
          ) : (
            formData?.publishStatus === 'U' ? (
              <AButton variant='primary_filled' updatedStyle={{ width: '180px' }} disabled={disableActionButton} onClick={() => { setDisableActionButton(true); handleClose(); publishStatusTimetableHandler(formData, 'P'); }}>Yes, Publish</AButton>
            ) : (
              <AButton variant='danger_filled' updatedStyle={{ backgroundColor: '#CC5F5F', width: '180px' }} disabled={disableActionButton} onClick={() => { setDisableActionButton(true); handleClose(); publishStatusTimetableHandler(formData, 'U'); }}>Yes, Unpublish</AButton>
            )
          )}
        </div>
      </div>
    </Dialog>
  )
}

export default PublishUnpublishConfirmationDialog;