import React from "react";
import { Col, FormGroup, Input, Label, Row,} from "reactstrap";
const CommonnHeader = ( props ) => {
   return(
        <>
            <Row>
                <Col span={6}><FormGroup><Label className="form-control-label">Business Group</Label><Input
                    value={props.groupCode ? props.groupCode :'Not Found'} disabled className="customInput" /></FormGroup></Col>
                <Col span={6}>
                    <FormGroup><Label className="form-control-label">Company Code</Label>
                        <Input maxLength={50}
                               value={props.companyCode ? props.companyCode:'Not Found'}
                               className="customInput" disabled
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <FormGroup>
                        <Label className="form-control-label">Business Area - Name</Label>
                        <Input
                            value={props.businessArea?props.businessArea:'Not Found'}
                            disabled className="customInput"
                        />
                    </FormGroup>
                </Col>
                <Col span={6}><FormGroup><Label className="form-control-label">Cashier Office</Label><Input maxLength={50} value={props.cashier ? props.cashier : 'Not Found'} className="customInput" disabled /></FormGroup></Col>
            </Row>
            <Row>
                <Col span={6}><FormGroup><Label className="form-control-label">Course ID - Course Name</Label><Input value={props.previousData?( props.previousData.courseId + ' - ' + props.courseName ):'Not Found'} disabled className="customInput"/></FormGroup></Col>
                <Col span={6}><FormGroup><Label className="form-control-label">Term</Label><Input maxLength={50} value={props.term} className="customInput" disabled /></FormGroup></Col>
            </Row>
            <Row>
                <Col span={6}><FormGroup><Label className="form-control-label">Course Start Date</Label><Input value={props.previousData.courseStartDate ? props.previousData.courseStartDate : 'Not Found'} disabled /></FormGroup></Col>
                <Col span={6}><FormGroup><Label className="form-control-label">Course End Date</Label><Input defaultValue={props.previousData.courseEndDate} disabled /></FormGroup></Col>
            </Row>
            {
                props.type ==='setupInstallments'?
                    <><Row>
                        <Col span={6}><FormGroup><Label className="form-control-label">{props.year} Start Date</Label><Input value={props.setupDatesData.startDate?props.setupDatesData.startDate:''} disabled /></FormGroup></Col>
                        <Col span={6}><FormGroup><Label className="form-control-label">{props.year} End Date</Label><Input defaultValue={props.setupDatesData.endDate?props.setupDatesData.endDate:''} disabled /></FormGroup></Col>
                    </Row>
                    <Row>
                        <Col span={6}>Last Updated Date : {props.setupDatesData.lastUpdatedDate}</Col>
                        <Col span={6}>{props.year} Item Type Count : <b>{props.setupDatesData.totalInstallments && props.setupDatesData.totalInstallments}</b>   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {props.year} Total Amount : <b>{props.setupDatesData.totalAmount}</b></Col>
                    </Row></>
                : null
            }
            {props.type ==='setupDates'?
                <Row>
                    <Col>Last Updated Date : {props.previousData.lastUpdatedDate}</Col>
                    <Col>Course Duration (In Years) : <b>{props.previousData.courseDuration}</b></Col>
                </Row>:null
            }
        </>
    );
};

export default CommonnHeader;