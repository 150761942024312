import { PermissionContext, RolePermissions } from 'appContext';
import React, { useContext, useEffect, useState } from 'react';
import { getAPI, masterServiceBaseUrl } from 'services/http';
import { failureToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import { PermisionDenied } from '../../common/commonComponents';
import CustomLoader from '../../common/commonComponents/Loader/loader';
import { pages } from '../../common/constant';
import { GetPagination } from "../../common/commonComponents/pagination/pagination";
import CourseAttributeValueTable from './courseAttributeValueTable';

const CourseAttributeValue = () => {
	const [classArray, setclassArray] = useState([]);
	const [apiloader, setapiloader] = useState(false);
	const [state, setstate] = useState([]);
	const [isSaveVisible, setisSaveVisible] = useState(false);
	const [pagination, setpagination] = useState({
		currentPage: 1,
		nextPage: null,
		previousPage: null,
		totalPage: null,
	});
	const pageSize = 10;

	const permissions = useContext(PermissionContext);

	const userPermissions = RolePermissions(
		permissions,
		pages['courseAttributeValue']['id']
	);

	const fetchClasses = async (url) => {
		setapiloader(true);
		getAPI(
			url,
			(data) => {
				setstate(data['data']);
				let n = pagination;
				if (data['data'].length > pageSize) {
					n['nextPage'] = n.currentPage + 1;
				}
				if (n.currentPage > 1) {
					n.previousPage = n.currentPage - 1;
				}
				n['totalPage'] = Math.floor(data['data'].length / pageSize);
				if (data['data'].length % pageSize != 0) {
					n['totalPage'] += 1;
				}
				setpagination(n);
				setapiloader(false);
				setclassArray(
					data['data'].slice(
						pagination.currentPage * pageSize - pageSize,
						pagination.currentPage * pageSize
					) || []
				);
			},
			(data) => {
				failureToast(data['message']);
			}
		);
	};
	const nextPage = () => {
		setclassArray(
			state.slice(
				(pagination.currentPage + 1) * pageSize - pageSize,
				(pagination.currentPage + 1) * pageSize
			)
		);
		let n = pagination;
		n['currentPage'] = n['currentPage'] + 1;
		n['previousPage'] = n.currentPage;
		setpagination(n);
	};
	const firstPage = () => {
		setclassArray(state.slice(1 * pageSize - pageSize, 1 * pageSize));
		let n = pagination;
		n['currentPage'] = 1;
		n['previousPage'] = null;
		setpagination(n);
	};
	const lastPage = () => {
		setclassArray(
			state.slice(
				pagination.totalPage * pageSize - pageSize,
				pagination.totalPage * pageSize
			)
		);
		let n = pagination;
		n['currentPage'] = n.totalPage;
		n['previousPage'] = n.totalPage - 1;
		setpagination(n);
	};
	const previousPage = () => {
		setclassArray(
			state.slice(
				(pagination.currentPage - 1) * pageSize - pageSize,
				(pagination.currentPage - 1) * pageSize
			)
		);

		let n = pagination;
		n['currentPage'] = n['currentPage'] - 1;
		if (n['currentPage'] == 1) {
			n['previousPage'] = null;
		} else {
			n['previousPage'] = n.currentPage;
		}
		setpagination(n);
	};
	useEffect(() => {
		fetchClasses(
			masterServiceBaseUrl + '/courseAttributeValue/getAllCourseAttributeValue'
		);
	}, []);
	return (
		<>
			{apiloader ? (
				<CustomLoader
					apiLoader={apiloader}
					loaderHeight={'200px'}
					loaderWidth={'100% '}
				/>
			) : (
				<>
					{userPermissions ? (
						<>
							<CourseAttributeValueTable
								fetchClasses={fetchClasses}
								setclassArray={setclassArray}
								classArray={classArray}
								userPermissions={userPermissions}
								isSaveVisible={isSaveVisible}
								setisSaveVisible={setisSaveVisible}
							/>
							{pagination.totalPage > 1 ? (
								<GetPagination
								isSaveVisible={isSaveVisible}
								fetchClasses={fetchClasses}
								setclassArray={setclassArray}
								pagination={pagination}
								setpagination={setpagination}
								pageSize={pageSize}
								state={state}
							/>
							) : null}
						</>
					) : (
						<PermisionDenied />
					)}
				</>
			)}
		</>
	);
};
export default CourseAttributeValue;
