import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Col, Button } from "reactstrap";
import CustomLoader from "views/pages/manage/common/commonComponents/Loader/loader";
import { masterServiceBaseUrl } from "services/http";
import { CustomContainer } from "views/pages/manage/common/commonComponents";
import { CustomCard } from "views/pages/manage/common/commonComponents";
import {
  fetchDetails,
  getRequiredSign,
} from "../../../common/utils/methods/commonMethods/utilityMethod";
import { failureToast } from "services/http";
import SearchButton from "components/FormComponent/Button/Search/index";
import { ButtonText } from "variables/Buttons";

const Header = (props) => {
  const { headerValues, setHeaderValues = () => {}, searchBuildingData = () => {}, setBuildingData = () => {}, isView = false, resetHeader = () => {} } = props;
  const [groupCodeData, setGroupCode] = useState(null);
  const [companyCodeData, setCompanyCodeData] = useState(null);
  const [zoneData, setZoneData] = useState(null);
  const [regionData, setRegionData] = useState(null);
  const [businessData, setBusinessData] = useState(null);

  useEffect(() => {
    fetchDetails(`${masterServiceBaseUrl}/groupCode/getAllActiveGroupCode`, setGroupCode, "groupCode", "select");
    // fetchDetails(
    //   `${masterServiceBaseUrl}/companyCode/getAllActiveCompanyCode`,
    //   setCompanyCodeData,
    //   "companyCode",
    //   "select"
    // );
    fetchDetails(`${masterServiceBaseUrl}/operationalZone/getAllActiveOperationalZone`, setZoneData, "operationalZone", "select");
    fetchDetails(`${masterServiceBaseUrl}/region/getAllActiveRegion`, setRegionData, "region", "select");
  }, []);

  const search = () => {
    if (!headerValues?.businessGroup?.value) {
      failureToast("Please Select Business Group");
      return;
    }
    if (!headerValues?.companyCode?.value) {
      failureToast("Please Select Comapny Code");
      return;
    }
    if (!headerValues?.zone?.value) {
      failureToast("Please Select Zone");
      return;
    }
    if (!headerValues?.region?.value) {
      failureToast("Please Select Region");
      return;
    }
    searchBuildingData();
  };

  return (
    <CustomContainer>
      <CustomCard title="Search Context">
        {!groupCodeData || !zoneData || !regionData ? (
          <div className="mx-auto text-center py-5 my-5" style={{ height: "100vh" }}>
            <CustomLoader apiLoader={!groupCodeData || !zoneData || !regionData} />
          </div>
        ) : (
          <>
            <div className="form-row p-4">
              <Col className="mb-3" md="3">
                <label className="form-control-label">Business Group{getRequiredSign()}</label>
                <Select
                  value={headerValues.businessGroup}
                  id="building_header_business_grp"
                  options={groupCodeData}
                  isDisabled={isView}
                  placeholder="Select Business Group"
                  onChange={(sData) => {
                    setHeaderValues({
                      ...headerValues,
                      businessGroup: sData,
                      companyCode: null,
                      businessArea: null,
                    });
                    setBusinessData(null);
                    setCompanyCodeData(null);
                    setBuildingData(null);
                    fetchDetails(`${masterServiceBaseUrl}/companyCode/getCompanyCodeByGroup/${sData.value}`, setCompanyCodeData, "companyCode", "select");
                  }}
                />
              </Col>
              <Col className="mb-3" md="3">
                <label className="form-control-label">Company Code{getRequiredSign()}</label>
                <Select
                  value={headerValues.companyCode}
                  id="building_header_company_code"
                  options={companyCodeData}
                  isDisabled={isView}
                  placeholder={"Select Company Code"}
                  onChange={(sData) => {
                    setHeaderValues({ ...headerValues, companyCode: sData });
                    setBusinessData(null);
                    setBuildingData(null);
                  }}
                  isLoading={headerValues.businessGroup && !companyCodeData}
                />
              </Col>
              <Col className="mb-3" md="3">
                <label className="form-control-label">Zone{getRequiredSign()}</label>
                <Select
                  value={headerValues.zone}
                  id="building_header_zone"
                  options={zoneData}
                  isDisabled={isView}
                  placeholder="Select Zone"
                  onChange={(sData) => {
                    setHeaderValues({ ...headerValues, zone: sData });
                    setBuildingData(null);
                  }}
                />
              </Col>
              <Col className="mb-3" md="3">
                <label className="form-control-label">Region{getRequiredSign()}</label>
                <Select
                  value={headerValues.region}
                  id="building_header_region"
                  options={regionData}
                  isDisabled={isView}
                  placeholder="Select Region"
                  onChange={(sData) => {
                    setHeaderValues({ ...headerValues, region: sData });
                    setBuildingData(null);
                  }}
                />
              </Col>
            </div>
            <div className="d-flex justify-content-end mr-4 mb-2">
              <SearchButton text={ButtonText.MSGroupSequence.search} onClick={search} />
            </div>
          </>
        )}
      </CustomCard>
    </CustomContainer>
  );
};

export default Header;
