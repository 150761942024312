import React, { useContext, useEffect, useState } from "react";
import { Dialog, Tooltip } from "@mui/material";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import { ReactComponent as DangerIcon } from "assets/img/svg/WarningRed.svg";
import { ReactComponent as EditIcon } from "assets/img/svg/Edit2.svg";
import styles from "../../selectMeterialAndStock/styles.module.scss";
import { AGridColDef } from "../../../../common/data-grid/ADataGridColDef";
import ADataGrid from "../../../../common/data-grid/ADataGrid";
import { gridStyles } from "views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant.js";
import CustomChip from "views/pages/manage/common/customChip/CustomChip.jsx";
import NoRowOverlay from "views/pages/manage/masters/holidayMaster/NoRowOverlay.jsx";
import { IssuanceIDCardContext } from "../../contextApi";
import {
  getChipBgColor,
  getChipTxtColor,
} from "../../selectMeterialAndStock/constant";
import { GridRowModes } from "@mui/x-data-grid";
import { editCellContainerStyle, inputStyles } from "../../mastersAndMapping/onBoardingMaterialIdSetup/constant";
import AInput from "../../../../common/formFeilds/customInput/AInput";
import { regex } from "../../../../common/constant";
import ALoader from "../../../../common/a-loader";
import useConfirm from "views/pages/manage/common/commonComponents/ConfirmationDialog/confirmationPrompt.jsx";
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import { validateBarcode } from "../../dlpStockIssuance/issueStock/BarcodeMappingTable";
import { isObjEmpty } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { saveOrDeleteMsg } from "../../mastersAndMapping/sapProfitCostCentreMapping/constant";
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'

const getRows = rowData => {
  return rowData?.barcodeList?.map((item, index) => ({
    index: index,
    barcode: item?.barcode,
    materialDescription: rowData?.materialDescription,
    materialId: rowData?.materialId,
  })) || [];
}
const getRowId = rowData => rowData?.index;

const ActionsCellComponent = ({ params, startEditing, stopEditing, rowForm, errFlag, saveRowBarcodeHandler }) => {
  return params.api.getRowMode(params?.id) === "view" ? (
    <div>
      <IconButtonWrapper
        onClick={() => startEditing(getRowId(params?.row), params?.row)}
      >
        <EditIcon />
      </IconButtonWrapper>
    </div>
  ) : (
    <div className="d-flex align-items-center">
      <AButton
        variant="primary_filled"
        size="xs"
        onClick={() => saveRowBarcodeHandler()}
        disabled={!rowForm?.barcode?.trim()}
      >
        Save
      </AButton>
      <IconButtonWrapper className="ml-2" onClick={() => stopEditing()}><CrossIcon /></IconButtonWrapper>
    </div>
  );
};

const BarcodePopup = ({ rowData = [], materialRowIndex, count = 0, updateBarcodeListForMaterial, employeeDetailsForm }) => {
  const [open, setOpen] = useState(false);
  const [ConfirmationDialog, confirmPromise] = useConfirm();
  const handleClose = async (requireConfirmation=false) => {
    if (requireConfirmation) {
      const canContinue = await confirmPromise();
      if (canContinue) {
        setOpen(false);
        setIsBarcodeEdited(false);
        stopEditing();
      }
    } else {
      setOpen(false);
      setIsBarcodeEdited(false);
      stopEditing();
    }
  };
  const [rows, setRows] = useState(getRows(rowData));
  useEffect(() => {
    setRows(getRows(rowData));
  }, [open])
  const [errFlag, setErrFlag] = useState(false);
  const [editBarcodeLoader, setEditBarcodeLoader] = useState(false);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowForm, setRowForm] = useState({});
  const [isBarcodeEdited, setIsBarcodeEdited] = useState(false);
  const rowFormHandler = (value, key) => setRowForm(prev => ({...prev, [key]: value}));

  const startEditing = (id, row) => {
    if (!isObjEmpty(rowForm)) {
      failureToast(saveOrDeleteMsg);
      return;
    }
    setRowModesModel((oldModel) => ({
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "barcode" },
    }));
    setRowForm(row);
  };

  const stopEditing = () => {
    const currForm = { ...rowForm };
    setRowModesModel((oldModel) => ({
      [getRowId(currForm)]: { mode: GridRowModes.View, ignoreModifications: true },
    }));
    setRowForm({});
    setErrFlag('');
  };

  const getMaterialCount = (materialId) => rowData?.availableCount;

  const editBarcodeHandler = async () => {
    // check for other rows if newBarcode exist
    if(rows?.find((row, index) => index!==rowForm?.index && row?.barcode?.toString()===rowForm?.barcode?.toString())) {
      failureToast('This barcode is already used, please enter a barcode which is not used.');
      setErrFlag('This barcode is already used, please enter a barcode which is not used.');
      return false;
    } else {
      const isInvalidBarcode = await validateBarcode(employeeDetailsForm?.businessArea?.value, rowForm?.barcode, rowForm);
      // console.log(isInvalidBarcode, 'invalid')
      if(isInvalidBarcode) {
        failureToast(isInvalidBarcode);
        setErrFlag(isInvalidBarcode);
        return false;
      } else {
        setRows(prev => prev?.map(row => row?.index===rowForm?.index ? rowForm : row))
        return true;
      }
    }
  }
  const saveRowBarcodeHandler = async () => {
    setEditBarcodeLoader(true);
    const isBarcodeEdited = await editBarcodeHandler();
    setEditBarcodeLoader(false);
    if(isBarcodeEdited) {
      setIsBarcodeEdited(true);
      stopEditing();
    }
  }

  const columns = [
    new AGridColDef("materialId", "Material details", false)
      .setFlex(2)
      .setValueGetter(
        params => `${params?.value} | ${params?.row?.materialDescription}`
      )
      .renderCellComponent(params => (
        <div
          className="d-flex align-items-center ellipsis-text-container"
          style={{ gap: "12px" }}
        >
          <Tooltip title={params?.value}>
            <div className="ellipsis-text-container">{params?.value}</div>
          </Tooltip>
          {/* <CustomChip
            bgColor={getChipBgColor(getMaterialCount(params?.row?.materialId))}
            textColor={getChipTxtColor(
              getMaterialCount(params?.row?.materialId)
            )}
            text={`${getMaterialCount(params?.row?.materialId) || 0} Left`}
          /> */}
        </div>
      )),
    new AGridColDef("barcode", "Barcode number", false)
      .setFlex(1)
      .editable(true)
      .renderCellComponent(params => (
        <div className="ellipsis-text-container">{params?.value}</div>
      ))
      .renderEditCellComponent(params => (
        <div style={editCellContainerStyle}>
          <AInput
            placeholder={"Enter"}
            value={rowForm?.barcode}
            handler={value => rowFormHandler(value, "barcode")}
            regex={regex.alphaNumeric}
            style={inputStyles}
            error={errFlag}
            autoFocus={params?.hasFocus}
          />
        </div>
      )),
    new AGridColDef("actions", "Actions", false)
      .setFlex(0.5)
      .columnAlign("right")
      .setMinWidth(120)
      .renderCellComponent(params => (
        <ActionsCellComponent
          params={params}
          startEditing={startEditing}
          stopEditing={stopEditing}
          rowForm={rowForm}
          errFlag={errFlag}
          saveRowBarcodeHandler={saveRowBarcodeHandler}
        />
      )),
  ];

  return (
    <>
      <span onClick={() => setOpen(true)} className="color-primary pointer">
        Edit/View
      </span>
      <Dialog
        onClose={() => handleClose(isBarcodeEdited)}
        open={open}
        PaperProps={{ sx: { width: "100%", maxWidth: "750px !important" } }}
      >
        <div style={{ padding: "28px 24px" }}>
          {editBarcodeLoader && <ALoader position="fixed" />}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="color-dark heading-4">
              Materials and mapped barcodes
            </div>
            <IconButtonWrapper onClick={() => handleClose(isBarcodeEdited)}><CrossIcon width={32} height={32} /></IconButtonWrapper>
          </div>

          <div className={`${styles?.barcode_dialog_table_container}`}>
            <ADataGrid
              removeWrapperContainer
              rows={rows || []}
              columns={columns}
              rowId={row => getRowId(row)}
              rowModesModel={rowModesModel}
              rowHeight={50}
              loading={false}
              serverPagination={false}
              autoHeight
              hidePagination
              sx={gridStyles}
              components={{
                NoRowsOverlay: () => (
                  <NoRowOverlay message="Please select material to be issued" />
                ),
              }}
            />
          </div>
          <div>
            {errFlag && (
              <div className="mt-3 d-flex align-items-center">
                <DangerIcon width={24} height={24} className="mr-2" />
                <span className="color-red-60">
                  {errFlag}
                </span>
              </div>
            )}
            <AButton
              className="mt-3 btn-left-0"
              variant="primary_filled"
              onClick={() => {
                isBarcodeEdited && updateBarcodeListForMaterial(
                  materialRowIndex,
                  rows?.map(item => ({
                    barcode: item?.barcode,
                    isIssued: false,
                  }))
                );
                handleClose();
              }}
            >
              {isBarcodeEdited ? "Save" : "Done"}
            </AButton>
          </div>
        </div>
      </Dialog>
      <ConfirmationDialog />
    </>
  );
};

export default BarcodePopup;
