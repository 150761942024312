import React, { useContext, useState } from 'react'
import { IoWarningOutline } from 'react-icons/io5'
import AButton from 'views/pages/manage/common/form-fields-mui/AButton.jsx'
import styles from '../styles.module.scss'
import StockAlertModal from './StockAlertModal';
import CustomChip from 'views/pages/manage/common/customChip/CustomChip.jsx'
import { IssuanceIDCardContext } from '../../contextApi';
import { getChipBgColor, getChipTxtColor } from '../constant';

const MaterialInfoFragment = ({ data, count }) => {
  return (
    <div className='ellipsis-text-container d-flex align-items-center' style={{ gap: '8px' }}>
      <div className='ellipsis-text-container'>{data?.value}</div>
      {/* <div className='color-light regular-large d-flex align-items-center' style={{ backgroundColor: '#705010', padding: '2px 4px', height: '24px', borderRadius: '2px' }}>{count} left</div> */}
      <CustomChip
        bgColor={getChipBgColor(count)}
        textColor={getChipTxtColor(count)}
        text={`${count || 0} Left`}
        style={{ fontSize: '16px', fontWeight: 400, padding: '2px 4px', height: '24px', borderRadius: '2px' }}
      />
    </div>
  )
}

const StockAlertBadgeMessage = () => {

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { selectedMaterials, materialAndBarcodeMapping } = useContext(IssuanceIDCardContext);
  const getMaterialCount = (materialId) => materialAndBarcodeMapping?.['materialCount']?.[materialId] || 0;

  const filteredMaterials = selectedMaterials?.filter(item => getMaterialCount(item?.materialId) < materialAndBarcodeMapping?.threshold)

  return (
    <>
      <div className={`${styles['stock-alert-badge-container']}`}>
        <IoWarningOutline style={{ width: '20px', height: '20px' }} />
        <div className='flex-grow-1 d-flex ellipsis-text-container align-items-center' style={{ gap: '12px' }}>
          <div>Stock alert: </div>
          <MaterialInfoFragment data={filteredMaterials[0]} count={getMaterialCount(filteredMaterials[0].materialId)} />
          {filteredMaterials?.[1] && <MaterialInfoFragment data={filteredMaterials[1]} count={getMaterialCount(filteredMaterials[1].materialId)} />}
          {filteredMaterials?.length > 2 && <span className='ml-2'>+{filteredMaterials.length - 2} more material(s)</span>}
        </div>
        <AButton
          variant='link'
          updatedStyle={{ textDecoration: 'underline', color: '#3D2C0B' }}
          onClick={() => setIsDialogOpen(true)}
        >
          View details
        </AButton>
      </div>
      <StockAlertModal
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        selectedMaterials={filteredMaterials}
        getMaterialCount={getMaterialCount}
      />
    </>
  )
}

export default StockAlertBadgeMessage