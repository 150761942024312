import { v4 as uuidv4 } from "uuid";

import React, { useState } from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { ReactComponent as CloseIcon } from "assets/img/svg/CloseIcon.svg";
import moment from "moment";
import CustomDatePickerV2 from "views/pages/manage/common/commonComponents/CustomDatePicker-V2";
import AInput from "views/pages/manage/common/formFeilds/customInput/AInput";
import AButton from "../../../common/form-fields-mui/AButton";
import ALoader from "views/pages/manage/common/a-loader";
import { maxDate31Days, uuidValidateV4 } from "../helper";
import { failureToast } from "../../../common/utils/methods/toasterFunctions/function";

import BatchName from "./BatchName";
import { isLecturePassed } from "../helper";
import { isDatePassed } from "../helper";

const FormField = {
  lecture: "",
  faculty: "",
  date: "",
  startTime: "",
  endTime: "",
  lectureName: "",
};

const createFormObject = (rowData) => {
  const formObj = {
    date: rowData.lectureDate || "",
    startTime: rowData?.lectureStartTime || "",
    endTime: rowData?.lectureEndTime || "",
    lectureName: rowData?.lectureName || "",
  };
  return formObj;
};

const AddCustomHolidayModal = ({
  isOpen,
  onClose,
  batchDetails,
  openMode,
  lectures,
  setLectures,
  setIsNeedToPublish,
  rowData,
  saveOnEdit,
  editedRow,
}) => {
  const [inputForm, setInputForm] = useState(createFormObject(editedRow));

  const inputFormHandler = (value, key) =>
    setInputForm((prev) => ({ ...prev, [key]: value }));

  const [loading, setLoading] = useState(false);

  const getLectureRequest = () => {
    const lecture = {
      ...inputForm,

      id: uuidv4(),
      timeTableClassType: "Holiday",
      classType: "Holiday",
      subject: null,
      roomName: "",
      roomId: "",
      date: inputForm?.startTime,
      lectureStartTime: inputForm?.startTime,
      lectureEndTime: inputForm?.endTime,

      lectureId: 0,
      lectureCode: null,
      facultyId: 0,
      msTeam: "",
      isEdit: false,
      isNew: true,
      isTimeTableEvent: true,
      status: "ACTIVE",
      documentS3Key: "",
    };
    return lecture;
  };

  const saveEvent = () => {
    if (
      !inputForm?.startTime ||
      !inputForm?.endTime ||
      !inputForm?.lectureName
    ) {
      return failureToast("Please enter all required fields");
    }
    const lecture = getLectureRequest();

    if (!editedRow) {
      setLectures((lectures) => [...lectures, lecture]);
    } else if (editedRow && !editedRow?.id) {
      setLectures((lectures) => [...lectures, lecture]);
    } else if (editedRow) {
      const tempLectures = [...lectures];
      tempLectures[editedRow?.index || editedRow?.rowIndex] = {
        ...lecture,
        id: editedRow?.id,
      };
      setLectures(tempLectures);
      if (!uuidValidateV4(editedRow?.id))
        //  check event - id with no '-', id given from backend
        saveOnEdit(
          [{ ...lecture, id: editedRow?.id, isItemEdited: true }],
          true,
          false,
          undefined,
          []
        );
    }

    if (
      (uuidValidateV4(lecture?.id) || uuidValidateV4(editedRow?.id)) && //  check id is uuid given from FE
      setIsNeedToPublish
    )
      setIsNeedToPublish(true);

    onCloseReset();
  };

  const onCloseReset = () => {
    onClose();
    setInputForm(FormField);
  };

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={onCloseReset}
      style={{ width: "500px" }}
    >
      <ModalBody>
        <div className="d-flex justify-content-between align-items-start mb-4">
          {loading && <ALoader />}
          <div className="heading-4 label-color text-bold">Custom holiday</div>
          <CloseIcon onClick={() => onCloseReset()} />
        </div>

        <BatchName batchDetails={batchDetails} />
        <Row className="mb-2">
          <Col md={12}>
            <div className="d-flex flex-column">
              <div className="regular-large-bold color-dark mb-2 bold">
                Title
              </div>
              <AInput
                value={inputForm.lectureName}
                handler={(value) => inputFormHandler(value, "lectureName")}
                maxLength="50"
                placeholder="Add title"
                style={{ height: "32px", borderRadius: "5px" }}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm="6">
            <div className="d-flex flex-column">
              <div className="regular-large-bold color-dark mb-2">
                Start date
              </div>
              <CustomDatePickerV2
                placeHolder="Date"
                value={inputForm?.startTime}
                minDate={moment()}
                handler={(index, date) => {
                  inputFormHandler(date, "startTime");
                  inputFormHandler("", "endTime");
                }}
                height="32px"
                maxDate={maxDate31Days()}
                disabled={editedRow?.id && isDatePassed(inputForm?.startTime)}
              />
            </div>
          </Col>
          <Col sm="6">
            <div className="d-flex flex-column">
              <div className="regular-large-bold color-dark mb-2">End date</div>
              <CustomDatePickerV2
                placeHolder="Date"
                value={inputForm?.endTime}
                minDate={inputForm?.startTime}
                handler={(index, date) => inputFormHandler(date, "endTime")}
                height="32px"
                maxDate={maxDate31Days()}
              />
            </div>
          </Col>
        </Row>

        <div>
          <AButton
            className="btn-left-0 mt-2"
            variant="primary_filled"
            onClick={saveEvent}
            disabled={isDatePassed(inputForm?.endTime)}
          >
            Save event
          </AButton>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddCustomHolidayModal;
