import { createContext, useContext } from 'react';
import routes from 'routes.js';
import { pages } from 'views/pages/manage/common/constant';
const _ = require('lodash');

export const PermissionContext = createContext();
export const RoutesDetails = createContext();
export const UserDetails = createContext();

export const RolePermissions = (value, pageName) => {
  if (value) {
    if (value?.scope?.hasOwnProperty(pageName) && value?.scope[pageName].length > 0) {
      const enablePermissionArr = value.scope[pageName].split(',');

      // console.log("enablePermissionArr",enablePermissionArr)

      return enablePermissionArr;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

let pageName = ['Dashboard'];

const handlerViewsArray = arr => {
  const arrLen = arr.length - 1;
  for (let index = arrLen; index >= 0; index--) {
    if (arr[index].collapse) {
      removeUnAuthorizedRoutes(arr[index]);
    } else {
      validateRoute(arr, arr[index], index);
    }
  }
};

const validateRoute = (arr, item, index) => {
  if (!pageName.includes(item.name)) {
    arr.splice(index, 1);
  } else {
    return false;
  }
};

const removeUnAuthorizedRoutes = data => {
  if (data.collapse) {
    const arrLen = data.views.length - 1;
    for (let index = arrLen; index >= 0; index--) {
      if (data.views[index].collapse) {
        handlerViewsArray(data.views[index].views);
      } else {
        validateRoute(data.views, data.views[index], index);
      }
    }
  }
};

let removeEmpty = 1;

const removeEmptyRoute = routestempValue => {
  let routesValueLen = routestempValue.length - 1;
  for (let index = routesValueLen; index >= 0; index--) {
    if (routestempValue[index]?.views?.length > 0) {
      removeEmptyRoute(routestempValue[index].views);
    } else if (routestempValue[index]?.views?.length === 0) {
      routestempValue.splice(index, 1);
      removeEmpty++;
    }
  }
};

export const routesValue = response => {
  const routestemp = _.cloneDeep(routes);

  removeEmpty = 1;
  pageName = ['Dashboard'];
  let mapPage = {};
  for (const page in pages) {
    mapPage[pages[page].id] = pages[page].name;
  }
  for (const val in response) {
    if (mapPage[val]) {
      pageName.push(mapPage[val]);
    }
  }

  routestemp.forEach((item, index) => {
    if (!item?.hasOwnProperty('views')) {
      validateRoute(routestemp, item, index);
    }
    removeUnAuthorizedRoutes(item);
  });

  while (removeEmpty) {
    removeEmpty = 0;
    removeEmptyRoute(routestemp);
  }

  return routestemp;
};

export const CheckRoutePermissions = (value, pages) => {
  // if (value) {
  //   let permissionObj = [];
  //   Object.keys(pages).map(item => {
  //     if (value.scope.hasOwnProperty(pages[item]['id'])) {
  //       permissionObj.push({
  //         permission: value.scope[pages[item]['id']].split(','),
  //         name: pages[item]['name'],
  //       });
  //     }
  //   });
  //   return permissionObj;
  // } else {
  //   return false;
  // }

  return [];
};
