import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { BsFillForwardFill } from 'react-icons/bs';
import SetupBuAndCourseTable from './subCategoryTable';
import { masterServiceBaseUrl, concessionServiceBaseUrl, getAPI } from '../../../../../../../services/http';
import CommonHeader from '../commonHeader';
import {
	fetchDetails,
	fetchAllData,
	getDispValForReactSelectByOneID,
} from '../../../../common/utils/methods/commonMethods/utilityMethod';
import CustomLoader from '../../../../common/commonComponents/Loader/loader';
import { updateData } from '../../../../../../../redux/globalData/globalDataAction';

const SubCategory = (props) => {
	const dispatch = useDispatch();
	const { id } = props.match.params;
	const [isSaveVisible, setIsSaveVisible] = useState(false);
	const [grpCode, setGrpCode] = useState(null);
	const [cmpyCode, setCmpyCode] = useState(null);
	const [academicCareer, setAcademicCareer] = useState(null);
	const [academicGrp, setAcademicGrp] = useState(null);
	const [subCategoryList, setSubCategoryList] = useState(null);
	const [scholarshipData, setScholarshipData] = useState(null);
	const [subCategory, setSubCategory] = useState([]);

	const headerData = [
		{
			label: 'Business Group',
			value: `${
				scholarshipData &&
				grpCode &&
				getDispValForReactSelectByOneID(
					scholarshipData.groupCodeId,
					'DispValue',
					grpCode
				)
			}`,
		},
		{
			label: 'Company Code',
			value: `${
				scholarshipData &&
				cmpyCode &&
				getDispValForReactSelectByOneID(
					scholarshipData.companyCodeId,
					'DispValue',
					cmpyCode
				)
			}`,
		},
		{
			label: 'Academic Career - Academic Group',
			value: `${
				scholarshipData &&
				academicCareer &&
				getDispValForReactSelectByOneID(
					scholarshipData.academicCareerId,
					'DispValue',
					academicCareer
				)
			} - ${
				scholarshipData &&
				academicGrp &&
				getDispValForReactSelectByOneID(
					scholarshipData.academicGroupId,
					'DispValue',
					academicGrp
				)
			}`,
		},
		{
			label: 'Category Code - Description',
			value: `${scholarshipData && scholarshipData.categoryName}  ${
				scholarshipData && scholarshipData.description
					? `- ${scholarshipData.description}`
					: ''
			}`,
		},
	];
	// const commonMethodForFetchData =(url,setMethod,title)=>{
	//     getAPI(url,(data)=>{
	//       if(title === 'termData'){
	//           if(data.data && data.data.length > 0){
	//               let tempTermdata=[];
	//               data.data.map((item)=>{
	//                   tempTermdata.push(item.termDispValue);
	//               })
	//               setTermData(tempTermdata);
	//           }
	//       }
	//     },(data)=>{
	//         console.log('Error in fetching ',title,' data------>',data.message);
	//     })
	// }

	const getDataAfterId = () => {
		fetchAllData(
			`${concessionServiceBaseUrl}/scholarship/getScholarship/${id}`,
			setScholarshipData
		);
		fetchAllData(
			`${concessionServiceBaseUrl}/addSubCategory/scholarshipMaster/${id}`,
			setSubCategory
		);
	};

	useEffect(() => {
		if (id) {
			getDataAfterId();
		}
	}, [id]);

	const isLoading =
		scholarshipData &&
		cmpyCode &&
		grpCode &&
		academicCareer &&
		academicGrp &&
		subCategoryList
			? false
			: true;

	useEffect(() => {
		fetchDetails(
			`${masterServiceBaseUrl}/subCategory/getAllActiveSubCategory`,
			setSubCategoryList,
			'subCategory',
			'category'
		);
		fetchDetails(
			`${masterServiceBaseUrl}/companyCode/getAllCompanyCode`,
			setCmpyCode,
			'companyCode',
			'reactSelect'
		);
		fetchDetails(
			`${masterServiceBaseUrl}/groupCode/getAllGroupCode`,
			setGrpCode,
			'groupCode',
			'reactSelect'
		);
		fetchDetails(
			`${masterServiceBaseUrl}/academicGroup/getAllAcademicGroup`,
			setAcademicGrp,
			'academicGroup',
			'reactSelect'
		);
		fetchDetails(
			`${masterServiceBaseUrl}/academicCareer/getAllAcademicCareer`,
			setAcademicCareer,
			'academicCareer',
			'reactSelect'
		);
		// commonMethodForFetchData(masterServiceBaseUrl+'/term/getAllActiveTerm',setTermData,'termData');
		// props.updateHeaderData(headerData)
	}, []);

	useEffect(() => {
		if (
			scholarshipData &&
			scholarshipData.id &&
			cmpyCode &&
			grpCode &&
			academicCareer &&
			academicGrp
		) {
			dispatch(
				updateData({
					businessGroup: getDispValForReactSelectByOneID(
						scholarshipData.groupCodeId,
						'DispValue',
						grpCode
					),
					companyCode: getDispValForReactSelectByOneID(
						scholarshipData.companyCodeId,
						'DispValue',
						cmpyCode
					),
					academicCareer: getDispValForReactSelectByOneID(
						scholarshipData.academicCareerId,
						'DispValue',
						academicCareer
					),
					academicGroup: getDispValForReactSelectByOneID(
						scholarshipData.academicGroupId,
						'DispValue',
						academicGrp
					),
					categoryCode: scholarshipData.categoryName,
					description: scholarshipData.description || '',
				})
			);
		}
	}, [scholarshipData, cmpyCode, grpCode, academicCareer, academicGrp]);

	return (
		<>
			{isLoading ? (
				<div
					className="mx-auto text-center py-5 my-5"
					style={{ height: '100vh' }}
				>
					<CustomLoader apiLoader={isLoading} />
				</div>
			) : (
				<>
					<Card
						style={{
							marginBottom: '12px',
							marginLeft: '20px',
							marginRight: '20px',
							marginTop: '20px',
						}}
					>
						<CardHeader>
							<h2 className="mb-0 floatLeft">
								<span
									onClick={() => props.history.goBack()}
									style={{ cursor: 'pointer', color: '#11CDEF' }}
								>
									Scholarship Master
								</span>{' '}
								&nbsp;{<BsFillForwardFill style={{ fontSize: '30px' }} />}&nbsp;
								Add Sub Category
							</h2>
						</CardHeader>
					</Card>
					<Card
						style={{
							marginBottom: '12px',
							marginLeft: '20px',
							marginRight: '20px',
						}}
					>
						<CardBody>
							<CommonHeader
								headerData={headerData}
								lastUpdatedDate={
									scholarshipData && scholarshipData['updatedOn']
								}
								countLabel={'Total Active Sub Category Count'}
								countValue={
									(scholarshipData &&
										scholarshipData['totalSubCategoryCount']) ||
									'00'
								}
							/>
						</CardBody>
					</Card>
					<SetupBuAndCourseTable
						scholarshipId={scholarshipData && scholarshipData.id}
						isSaveVisible={isSaveVisible}
						setIsSaveVisible={setIsSaveVisible}
						subCategory={subCategory}
						setSubCategory={setSubCategory}
						subCategoryList={subCategoryList}
						getDataAfterId={getDataAfterId}
					/>
				</>
			)}
		</>
	);
};

export default withRouter(SubCategory);
