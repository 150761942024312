import React from "react";
import { Button } from "reactstrap";
import {
  NewRow,
} from "../../../common/utils/methods/commonMethods/utilityMethod";


const AttendanceMachineRow = ({
  data,
  index,
  history,
  isSaveVisible = false,
  deleteRow = () => {},
  editRow = () => {},
  getDisplayVal = () => {},
  buildingData = [],
  machineTypeData = [],
}) => {
  const itemToBeRenderFromMaster = ["buildingMasterId","machineTypeId"];

  const arrList = [
    buildingData,
    null,
    null,
    null,
    machineTypeData,
    null,
    null,
    null,
  ];
  const propertyList = ["label", null, null,  null, "label", null, null, null];
  const listToBeRendered = [
    "buildingMasterId",
    "rfidMachineId",
    "machineName",
    "rfidMachineVendor",    
    "machineTypeId",
    "ipAddress",
    "macAddress",
    "effectiveDate",
    "status"
  ];

  return (
    <NewRow
      data={data}
      itemToBeRenderFromMaster={itemToBeRenderFromMaster}
      propertyList={propertyList}
      listToBeRendered={listToBeRendered}
      arrList={arrList}
    >
      {/* <td className="text-center p-2 mx-1">{data?.buildingName}</td>
      <td className="text-center p-2 mx-1" colSpan={2}>
        {data?.address}
      </td>
      <td className="text-center p-2 mx-1">{data?.city}</td>
      <td className="text-center p-2 mx-1">{data?.state}</td>
      <td className="text-center p-2 mx-1">{data?.country}</td>
      <td className="text-center p-2 mx-1">{data?.pinCode}</td>

      <td className="text-center p-2 mx-1">
        {data.status === "ACTIVE" ? (
          <Button color="success" size={"sm"} disabled={true}>
            Active
          </Button>
        ) : (
          <Button color="danger" size={"sm"} disabled={true}>
            Inactive
          </Button>
        )}
      </td> */}
      <td className="text-center p-2 mx-1">
        <Button
          color="info"
          size="sm"
          type="button"
          className={" mx-1"}
          onClick={() => {
            editRow(index);
          }}
        >
          <i className="fas fa-edit" />
        </Button>
        <Button
          color="info"
          size="sm"
          type="button"
          className={" mx-1"}
          onClick={() => deleteRow(index)}
        >
          <i className="fas fa-trash" />
        </Button>
      </td>
    </NewRow>
  );
};

export default AttendanceMachineRow;
