import React, { useState } from 'react';
import { Button, Card, Col, FormGroup, Input, Label, Row } from 'reactstrap';
// import { getCookie, setCookie, eraseCookie } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import BrandLogo1 from '../../../assets/img/brand/aakashlogo.png';
import BrandLogo2 from '../../../assets/img/brand/aakashlogo@2x.png';
import BrandLogo3 from '../../../assets/img/brand/aakashlogo@3x.png';
// import Banner from '../../../assets/img/brand/login_banner.svg';
// import { setUserLogedOut } from '../manage/common/services';
import Styles from './login.module.scss';
import CloseEye from 'assets/img/svg/eye_open.svg';
import CloseOpen from 'assets/img/svg/eye_close.svg';
import Eye from 'assets/img/svg/eye.svg';

const LoginWithOTP = () => {

    const [userName, setUserName] = useState('')
    const [idError, setIdError] = useState(false)
    const [via, setVia] = useState('phone')
    const [isLoading, setIsLoading] = useState(false)
    const [isOtpSent, setOtpSent] = useState(false)


    const handleGenrateOtp = () => {
        setIsLoading(true)
        if (!userName) {
            setIdError(true)
            setIsLoading(false)
            return
        } else {

            setOtpSent(true)
            setIsLoading(false)
        }
    }

    const verifyOtp = async () => {
        const login ={};
        // if (login?.data?.statusCode === 200) {
        //     if (getCookie('userId') !== userName) {
        //       eraseCookie('lastKnowPath')
        //     }
        //     setCookie('userId', userName);
        //     setCookie('token', login?.data?.jwtToken);
        //     const validate = await validateToken();
        //     if (validate?.data?.statusCode === 200) {
        //       getCookie('lastKnowPath')
        //         ? prop.history.replace(getCookie('lastKnowPath'))
        //         : prop.history.replace('/admin/dashboard');
        //       setErrorType('');
        //       setErrorType('');
        //     } else {
        //       setIsLoading(false);
        //       setErrorType('INVALID_TOKEN');
        //     }
        //   } else {
        //     setIsLoading(false);
        //     setErrorType('INVALID_CREDENTIALS');
        //   }
    }

    return (
        <Card className={Styles.login__page__right}>
            <div className={Styles.logo__brand__aakash__block}>
                <img
                    src={BrandLogo1}
                    srcSet={`${BrandLogo2}, ${BrandLogo3}`}
                    className={Styles.brand__logo__aakash}
                    alt="Brand"
                />
            </div>
            <div className={Styles.form__control}>
                <div className={Styles.welcome__header__block}>
                    <h2 className={Styles.welcome__header}>
                        Welcome!
                        <br />
                        <span>Login to your account</span>
                    </h2>
                </div>
                <div className="mt-4">
                    <FormGroup>
                        {!isOtpSent && <>
                            <Label className={`${Styles.login__input__label} form-control-label fw-bold`}>User ID</Label>
                            <div
                                className={Styles.login__input__box}
                                style={idError ? { borderColor: '#f5365c' } : { borderColor: '#a1aeb7' }}
                            >
                                <Input
                                    disabled={isLoading}
                                    type="text"
                                    maxLength={70}
                                    id={Styles.login__user__id}
                                    onChange={e => setUserName(e.target.value)}
                                    className={`${Styles.login__text__box}`}
                                    invalid={idError}
                                    valid={idError === false}
                                    required
                                    placeholder={'User ID'}
                                    onFocus={() => { setIdError(false) }}
                                />
                            </div>
                            {idError && (
                                <div className={Styles.login__input__error}> Please provide valid User ID</div>
                            )}
                            <div className="d-flex justify-content-between flex-row">
                                <Label className={`form-control-label fw-bold mt-4`}>
                                    Get OTP Via
                                </Label>
                                <div>
                                    <Label check className="mt-4 text-light pr-4 mr-2">
                                        <Input type="radio" name="verification" checked={via === 'email'} onClick={() => setVia('email')} />{' '}
                                        E-mail
                                    </Label>
                                    <Label check className="mt-4 text-light">
                                        <Input type="radio" name="verification" checked={via === 'phone'} onClick={() => setVia('phone')} />{' '}
                                        Message
                                    </Label>
                                </div>
                            </div>


                            <Button
                                className={Styles.login__button}
                                disabled={isLoading}
                                onClick={e => {
                                    handleGenrateOtp()
                                }}
                            >
                                {!isLoading ? (
                                    <span>Send OTP</span>
                                ) : (
                                    <i className="fas fa-spinner fa-spin" aria-hidden="true"></i>
                                )}
                            </Button></>}

                        {isOtpSent && <>
                            <div><span className='text-primary'>An OTP have been sent to requested channel.</span></div>
                            
                            {/* <Label className={`${Styles.login__input__label} form-control-label fw-bold`}>OTP</Label> */}
                            <div
                                className={Styles.login__input__box}
                            //   style={userIdError ? { borderColor: '#f5365c' } : { borderColor: '#a1aeb7' }}
                            >
                                <Input
                                    // disabled={isLoading}
                                    type="text"
                                    maxLength={70}
                                    id={Styles.login__user__id}
                                    // onChange={e => setUserCredentials({ ...userCredentials, username: e.target.value })}
                                    className={`${Styles.login__text__box}`}
                                    // invalid={userIdError === true}
                                    //valid={userIdError === false}
                                    required
                                    placeholder={'Please Enter OTP'}
                                // onFocus={()=>{setUserIdError(false);setIsError(false)}}
                                />
                            </div>

                            <Button
                                className={Styles.login__button}
                                disabled={isLoading}
                                onClick={e => {
                                    // handleGenrateOtp()
                                }}
                            >
                                {!isLoading ? (
                                    <span>Verify OTP</span>
                                ) : (
                                    <i className="fas fa-spinner fa-spin" aria-hidden="true"></i>
                                )}
                            </Button>

                        </>}

                    </FormGroup>
                </div>
            </div>
        </Card>
    )
}

export default LoginWithOTP