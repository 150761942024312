import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import {
  masterServiceBaseUrl,
  getAPI,
  putpost
} from 'services/http';
import LocationAddressHelper from './locationAddress';
import { failureToast, successToast } from "../../../common/utils/methods/toasterFunctions/function";

const Address = (props) => {
  //console.log(props);
  const {
    businessUnit,
    nextStep = () => { },
    isNew = true,
    formId,
    isViewOnly = false,
    data,
    appendAllStepData = () => { },
    updateAllStepData = () => { },
    isPrevious,
    previousStep = () => { },
    addAddressData,
    action,
    userPermissions
  } = props;
  const [country, setCountry] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [stateName, setStateName] = useState([]);
  const [city, setCity] = useState([]);
  const [district, setDistrict] = useState([]);
  const [addressCategory, setAddressCategory] = useState([]);
  const [apiloader, setapiloader] = useState(true);
  const [savedAddress, setSaveAddress] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const statusData = [
    { label: 'ACTIVE', value: 'ACTIVE' },
    { label: 'INACTIVE', value: 'INACTIVE' },
  ];

  const appendData = (data) => {
    //console.log(data)
    appendAllStepData(data, 'secondForm')
    props.saveStepsCompleted(2)
  }

  const updateData = (data) => {
    //console.log(data)
    updateAllStepData(data, 'secondForm')
  }

  const fetchData = (url, stateMethod, name) => {
    getAPI(
      url,
      (data) => {
        let list = [];
        if (typeof name !== 'undefined' && name !== '') {
          //this is for dropdown fields only
          let keyName = `${name}DispValue`;
          if (name === 'addressCategoryType') {
            keyName = name;
          }
          list = data.data.map((data) => {
            return {
              value: data.id,
              label: data[keyName],
            };
          });
          stateMethod(list);
        }
      },
      (data) => {
        failureToast(data['message']);
      }
    );
  };

  const getSavedAddress = () => {
    const formData = {
      addressReferenceType: 'BUSINESS_UNIT',
      referenceId: formId,
    };
    //console.log('i m called');
    putpost(
      `${masterServiceBaseUrl}/address/getAddressByReference`,
      (data) => {
        successToast(data['message']);
        setSaveAddress(data.data[0]);
      },
      (data) => {
        failureToast(data['message']);
      },
      formData,
      'post'
    );
    // getAPI(`${masterServiceBaseUrl}/address/getAddressByReference`, (data) => {
    //     //console.log(data.data)
    // }, (data) => { failureToast(data['message']) })
  };

  useEffect(() => {
    fetchData(masterServiceBaseUrl + '/country/getAllActiveCountry', setCountry, 'country');
    fetchData(masterServiceBaseUrl + '/state/getAllActiveState', setStateName, 'state');
    fetchData(masterServiceBaseUrl + '/city/getAllActiveCity', setCity, 'city');
    fetchData(masterServiceBaseUrl + '/district/getAllActiveDistrict', setDistrict, 'district');
    fetchData(masterServiceBaseUrl + '/pincode/getAllActivePincode', setPincode, 'pincode');
    fetchData(masterServiceBaseUrl + '/addressCategory/getAllActiveAddressCategory', setAddressCategory, 'addressCategoryType');
    setapiloader(false);
    //console.log( "Business unit details", businessUnit );
    if (action === 'view' || action === 'edit') {
      setIsDisabled(true);
    }
    if (isPrevious) {
      getSavedAddress()
    }
    if (props.isCopied && !isPrevious) {
      if (data) {
        setSaveAddress(data)
      }
    }
  }, [businessUnit, formId]);

  useEffect(() => {
    if (props.isCopied && !isPrevious) {
      //console.log("i m in copy address ")
      //console.log(data)
      if (data) {
        setSaveAddress(data)
      }
    }
  }, [data, props.isCopied])

  return (
    <>
      {apiloader ? (
        <div className="mx-auto text-center py-5 my-5">
          <Loader
            type="Rings"
            color="#00BFFF"
            height={70}
            width={70}
            timeout={10000}
          />
        </div>
      ) : (
        <>
          <LocationAddressHelper
            countryData={country}
            stateData={stateName}
            pincodeData={pincode}
            cityData={city}
            districtData={district}
            statusData={statusData}
            addressCategoryData={addressCategory}
            fetchData={(u, m, n) => fetchData(u, m, n)}
            bussinessUnitId={businessUnit}
            savedAddress={action === 'edit' || action === 'view' ? addAddressData : props.isCopied && !isPrevious ? data : isPrevious ? savedAddress : data}
            getSavedAddress={getSavedAddress}
            nextStep={nextStep}
            appendAllStepData={appendData}
            updateAllStepData={updateData}
            isPrevious={isPrevious}
            isNew={isNew}
            isCopied={props.isCopied}
            previousStep={previousStep}
            action={action}
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
            formId={props.formId}
            saveStepsCompleted={() => props.saveStepsCompleted(2)}
            userPermissions={userPermissions}
          />
        </>
      )}
    </>
  );
};

export default Address;
