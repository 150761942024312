export const pages = {
  groupCode: {
    name: "Group Code",
    id: "S_MST_BUS_GRP",
  },
  companyCode: {
    name: "Company Code",
    id: "S_MST_COMPANY_CODE",
  },
  academicCareer: {
    name: "Academic Career",
    id: "S_MST_ACAD_CAREER",
  },
  term: {
    name: "Term",
    id: "S_MST_TERM",
  },
  academicGroup: {
    name: "Academic Group",
    id: "S_MST_ACAD_GROUP",
  },
  academicInstitution: {
    name: "Academic Institution",
    id: "S_MST_ACADEMIC_INSTITUTION",
  },
  itemTypeSetup: {
    name: "Item Type Setup",
    id: "S_MST_ITM_TYP_SETUP",
  },
  ItemTypeClassification: {
    name: "Item Type Classification",
    id: "S_MST_ITM_TYP_CLASSIFICATION",
  },
  ItemTypeElement: {
    name: "Item Type Element",
    id: "S_MST_ITM_TYP_ELEMENT",
  },
  ItemTypeCategory: {
    name: "Item Type Category",
    id: "S_MST_ITM_TYP_CATEGORY",
  },
  businessAreaSearch: {
    name: "Business Area",
    id: "S_MST_BUS_AREA",
  },
  taxCodeId: {
    name: "Tax Code ID",
    id: "S_MST_TAX_CODE_ID",
  },
  taxCodeType: {
    name: "Tax Code Type",
    id: "S_MST_TAX_CODE_TYPE",
  },
  termFormTable: {
    name: "Term Table",
    id: "S_MST_TERM_MAPPING",
  },
  academicYear: {
    name: "Academic Year",
    id: "S_MST_ACADEMIC_YEAR",
  },
  session: {
    name: "Session",
    id: "S_MST_SESSION",
  },
  courseCatalog: {
    name: "Course Catalog",
    id: "S_CRS_COURSE_CATALOG",
  },
  courseCatalogSequence: {
    id: "S_CRS_COURSE_ID_SEQUENCE",
    name: "Course Catalog Sequence",
  },
  courseAttribute: {
    name: "Course Attribute",
    id: "S_CRS_COURSE_ATTR",
  },
  courseAttributeValue: {
    name: "Course Attribute Value",
    id: "S_CRS_ATTR_VALUES",
  },
  courseAttributes: {
    name: "Course Attributes Mapping",
    id: "S_CRS_MAPPING_OF_CATT_ND_VAL",
  },
  courseComponent: {
    name: "Course Component",
    id: "S_CRS_COURSE_COMPONENT",
  },
  subjectArea: {
    name: "Subject Area",
    id: "S_CRS_SUB_AREA",
  },
  pricingZone: {
    name: "Pricing Zone",
    id: "S_PRC_PRC_ZONE",
  },
  processInstanceSearch: {
    name: "Course Fee Upload",
    id: "S_PRC_FEE_SETUP",
  },
  level1Approval: {
    name: "Level 1 Approval",
    id: "S_PRC_FEE_SETUP_L1",
  },
  level2Approval: {
    name: "Level 2 Approval",
    id: "S_PRC_FEE_SETUP_L2",
  },
  courseFeeInformation: {
    name: "Course Fee Information",
    id: "S_PRC_FEE_SETUP_MANUAL",
  },
  itemTypeSequence: {
    name: "Item Type Sequence",
    id: "S_PRC_ITM_TYP_SEQUENCE",
  },
  feeSetupRules: {
    name: "Fee Setup Rules",
    id: "S_PRC_FEE_SETUP_RULES",
  },
  documentType: {
    name: "Document Type",
    id: "S_DMS_DOC_TYPE",
  },
  documentFormat: {
    name: "Document Format",
    id: "S_DMS_DOC_FORMAT",
  },
  processTagType: {
    name: "Process Tag Type",
    id: "S_DMS_PROC_TAG_TYP",
  },
  documentProcessTag: {
    name: "Document Process Tag",
    id: "S_DMS_PROC_TO_DOC_MAPPING",
  },
  templateUploadManager: {
    name: "Templates",
    id: "S_DMS_TEMP_VERSION",
  },
  BusinessUnitType: {
    name: "Business Unit Type",
    id: "S_MST_BUS_UNIT_TYPE",
  },
  Region: {
    name: "Region",
    id: "S_MST_REG",
  },
  AddressCategory: {
    name: "Address Category",
    id: "S_MST_ADD_CAT",
  },
  Country: {
    name: "Country",
    id: "S_MST_CNTRY",
  },
  State: {
    name: "State",
    id: "S_MST_STATE",
  },
  District: {
    name: "District",
    id: "S_MST_DIST",
  },
  City: {
    name: "City",
    id: "S_MST_CITY",
  },
  Pincode: {
    name: "Pincode",
    id: "S_MST_PIN",
  },
  businessType: {
    name: "Business Type",
    id: "S_MST_BUS_TYPE",
  },
  Currency: {
    name: "Currency",
    id: "S_MST_CURRENCY",
  },
  operationalZone: {
    name: "Operational Zone",
    id: "S_MST_OP_ZONE",
  },
  erpTemplate: {
    name: "Notification Templates",
    id: "S_NTS_MSG_TEMPLATE",
  },
  lessonPlan: {
    id: "lessonPlan",
    name: "Lesson Plan",
  },

  // new screens for master
  bulkUpload: {
    id: "S_DMS_BULK_UPLOAD",
    name: "Bulk Upload",
  },
  projectDetails: {
    id: "S_KYR_PROJECT_DETAILS",
    name: "Project Details",
  },
  permissionDetails: {
    id: "S_KYR_PERMISSION_DETAILS",
    name: "Permission Details",
  },
  languageMaster: {
    id: "S_MST_LANGUAGE_MASTER",
    name: "Language Master",
  },
  websiteSync: {
    id: "S_MST_WEBSITE_SYNC",
    name: "Website Sync",
  },
  examSyllabus: {
    id: "examSyllabus",
    name: "Exam Syllabus",
  },
  departmentType: {
    id: "S_MST_DEPARTMENT_TYPE",
    name: "Department Type",
  },
  jobTitle: {
    id: "S_MST_JOB_TITLE",
    name: "Job Title",
  },
  houseBank: {
    id: "S_STUDENT_FIN_MST_HOUSE_BANK_MST",
    name: "House Bank Master",
  },
  approvalLevel: {
    id: "approvalLevel",
    name: "Approval Level",
  },
  profitCenter: {
    id: "profitCenter",
    name: "Profit Center Mapping",
  },
  thirdPartySystems: {
    id: "thirdPartySystems",
    name: "Third Party Systems",
  },
  invoiceStatus: {
    id: "invoiceStatus",
    name: "Invoice Status",
  },
  AccountingEntryType: {
    id: "AccountingEntryType",
    name: "Accounting Entry Type",
  },
  AccountingType: {
    id: "AccountingType",
    name: "Accounting Type",
  },
  glCode: {
    id: "glCode",
    name: "GLCode",
  },
  searchBank: {
    id: "searchBank",
    name: "Bank Code",
  },
  meetingType: {
    id: "S_MST_MEETING_TYPE",
    name: "Meeting Type",
  },
  adjustmentFlag: {
    id: "S_MST_ADJUSTMENT_FLAG",
    name: "Adjustment Flag",
  },
  msGroupSequence: {
    id: "msGroupSequence",
    name: "MS Group Sequence",
  },
  approvalStatus: {
    id: "approvalStatus",
    name: "Approval Status",
  },
  paymentsMethod: {
    id: "paymentsMethod",
    name: "Payments Method",
  },
  paymentModePinelab: {
    id: "paymentModePinelab",
    name: "Payment Mode Pinelab",
  },
  paymentSchedule: {
    id: "paymentSchedule",
    name: "Payment Schedule",
  },
  extTestResult: {
    id: "extTestResult",
    name: "External Test Result",
  },
  gender: {
    id: "S_MST_GENDER",
    name: "Gender",
  },
  parentOccupation: {
    id: "S_MST_PARENT_OCCUPATION",
    name: "Parent Occupation",
  },
  phonetype: {
    id: "S_MST_PHONE_TYPE",
    name: "Phone Type",
  },
  addresstype: {
    id: "S_MST_ADDRESS_TYPE",
    name: "Address type",
  },
  emailType: {
    id: "S_MST_EMAIL_TYPE",
    name: "Email Type",
  },
  admission: {
    id: "S_MST_ADMISSION_TYPE",
    name: "Admission Type",
  },
  category: {
    id: "S_MST_CATEGORY",
    name: "Category",
  },
  studentIdStatus: {
    id: "S_MST_STUDENT_ID_STATUS",
    name: "Student ID Status",
  },
  bloodGroup: {
    id: "S_MST_BLOOD_GROUP",
    name: "Blood group",
  },
  saletype: {
    id: "S_MST_SALE_TYPE",
    name: "Sale Type",
  },
  consession: {
    id: "S_STUDENT_FIN_MST_CUSTOM_CON_CATEGORY_MST",
    name: "Concession Category Masters",
  },
  buildingmaster: {
    id: "S_MST_BUILDING",
    name: "Building Master",
  },
  concessionSlab: {
    id: "S_STUDENT_FIN_MST_CON_SLAB",
    name: "Concession Slab",
  },
  concessionSubCategory: {
    id: "concessionSubCategory",
    name: "Concession SubCategory",
  },
  concessionSubSubCategory: {
    id: "concessionSubSubCategory",
    name: "Concession Sub Sub Category",
  },
  examGroup: {
    id: "S_EXM_EXAM_GROUP",
    name: "Exam Group",
  },
  examPattern: {
    id: "S_EXM_EXAM_PATTERN",
    name: "Exam Pattern",
  },
  examIDbulkSearch: {
    id: "examIDbulkSearch",
    name: "Exam ID Bulk Search",
  },
  examIdGenerationSearch: {
    id: "examIdGenerationSearch",
    name: "Exam ID Generation Form",
  },
  examBoardType: {
    id: "S_EXM_EXAM_BOARD_TYPE",
    name: "Exam Board Type",
  },
  testType: {
    id: "S_EXM_TEST_TYPE",
    name: "Test Type",
  },
  testMode: {
    id: "S_EXM_TEST_MODE",
    name: "Test Mode",
  },
  testMethod: {
    id: "testMethod",
    name: "Test Method",
  },
  testHandledBy: {
    id: "testHandledBy",
    name: "Test Handled By",
  },
  examDateConfig: {
    id: "examDateConfig",
    name: "Exam Date configuration",
  },
  testSequence: {
    id: "testSequence",
    name: "Test Sequence",
  },
  studentInfo: {
    id: "S_ENR_STUDENT_INFO",
    name: "Student Info",
  },
  internalTestResult: {
    id: "S_EXM_INTERNAL_TEST_RESULT",
    name: "Internal Test Result",
  },
  attendance: {
    id: "attendance",
    name: "Add RFID",
  },
  subject: {
    id: "subject",
    name: "Subject",
  },
  dayMaster: {
    id: "dayMaster",
    name: "Day",
  },
  building: {
    id: "building",
    name: "Building",
  },
  academicPhase: {
    id: "academicPhase",
    name: "Academic Phase",
  },
  academicPhaseMapping: {
    id: "academicPhaseMapping",
    name: "Academic Phase Mapping",
  },
  chapters: {
    id: "chapters",
    name: "Chapters",
  },
  topics: {
    id: "topics",
    name: "Topics",
  },
  subTopics: {
    id: "subTopics",
    name: "Sub Topic",
  },
  subSubTopic: {
    id: "subSubTopic",
    name: "Sub Sub Topic",
  },
  attendanceDeviceType: {
    id: "attendanceDeviceType",
    name: "Attendance",
  },
  attendanceMachine: {
    id: "attendanceMachine",
    name: "Attendance Machine",
  },
  documentStatus: {
    id: "documentStatus",
    name: "Document Status",
  },
  personType: {
    id: "personType",
    name: "Master-Person Type",
  },
  documentSource: {
    id: "documentSource",
    name: "Document Source",
  },
  paymentMethod: {
    id: "paymentMethod",
    name: "Payment Method",
  },
  userRolePage: {
    id: "userRolePage",
    name: "User Role Search Page",
  },
  mapProcessToPage: {
    id: "mapProcessToPage",
    name: "Process To Page",
  },
  roleToProcess: {
    id: "roleToProcess",
    name: "Role To Process",
  },
  timeTable: {
    id: "timeTable",
    name: "Student Calendar",
  },
  services: {
    id: "services",
    name: "Services Request",
  },
  classSection: {
    id: "S_MST_CLASS_SECTION_MST",
    name: "Class Section Master",
  },
  createBatch: {
    id: "S_EDP_CREATE_BATCH",
    name: "Create Batch",
  },
  programAction: {
    id: "S_MST_PROG_ACT",
    name: "Program Action",
  },
  actionReason: {
    id: "S_MST_PROG_ACT_REASON",
    name: "Action Reason",
  },
  // programActions: {
  //   id: 'S_MST_PROG_ACT',
  //   name: 'Program Action Mapping',
  // },
  employeeManagement: {
    id: "S_UMT_UPLOAD_NEW_EMP",
    name: "Upload Employee Data",
  },
  createUserProfile: {
    id: "S_UMT_ADD_NEW_USR_PROFILE",
    name: "Users and Employees",
  },
  primaryBatchSequence: {
    id: "S_EDP_PRIMARY_BATCH_SEQ",
    name: "Primary Batch Sequence",
  },
  searchBatch: {
    id: "S_EDP_SEARCH_BATCH",
    name: "Search Batch",
  },
  userRolePage: {
    id: "S_UMT_ROLE_SEARCH",
    name: "Roles",
  },
  securityGroup: {
    id: "S_UMT_SECURITY_GROUP",
    name: "Security Group",
  },
  configRefresh: {
    id: "S_ADM_CONFIG_REFRESH",
    name: "Config Refresh",
  },
  feeCalculator: {
    id: "S_MST_FEE_CALCULATOR",
    name: "Fee Calculator",
  },
  applicationIDGeneration: {
    id: "S_ENR_APP_ID_GENERATION",
    name: "Application ID Generation",
  },
  documentManagementSearch: {
    id: "S_DMS_DOC_MANAGEMENT",
    name: "Document Management",
  },
  programActionsMapping: {
    id: "S_MST_PROG_ACT_MAPPING",
    name: "Program Action Mapping",
  },
  admissionAgentType: {
    id: "S_MST_ADMISSION_AGENT_TYPE",
    name: "Admission Agent Type",
  },
  resetPassword: {
    id: "S_UMT_RESET_PASSWORD",
    name: "Reset Password",
  },
  classes: {
    id: "S_MST_CLASS_MASTER",
    name: "Classes",
  },
  msTeamsChannelsSubjectMaster: {
    id: "S_EDP_MS_TEAM_CH_SUB_MST",
    name: "MS Teams Channels Subject Master",
  },
  searchStudent: {
    id: "S_EDP_STD_BATCH_DTL",
    name: "Student’s Batch Details",
  },
  searchAcademicPlan: {
    id: "S_EDP_SEARCH_ACAD_PLAN",
    name: "Search Academic Plan",
  },
  createAcademicPlan: {
    id: "S_EDP_CREATE_ACAD_PLAN",
    name: "Create Academic Plan",
  },
  templateMapping: {
    id: "S_NTS_MSG_TEMPLATE_MAPPING",
    name: "Notification Templates Mapping",
  },
  paymentMode: {
    id: "S_STUDENT_FIN_MST_PAYMENT_MODE",
    name: "Payment Mode",
  },
  loanSchemeMaster: {
    id: "S_STUDENT_FIN_MST_LOAN_SCHEME_SETUP",
    name: "Loan Scheme Setup",
  },
  studentCharges: {
    id: "S_STUDENT_FIN_OPS_APPLY_CHARGES",
    name: "Apply charges",
  },
  studentOtherCharges: {
    id: "S_STUDENT_FIN_OPS_APPLY_OTHER_CHARGES",
    name: "Apply other charges",
  },
  depositSlip: {
    id: "S_STUDENT_FIN_MST_DEPOSIT_SLIP_SETUP",
    name: "Deposit Slip Setup",
  },
  faculty: {
    id: "S_MST_FACULTY",
    name: "Faculty Master",
  },
  facultyRoaster: {
    id: "S_MST_FACULTY_ROASTER",
    name: "Faculty Roster",
  },
  daysPlan: {
    id: "S_MST_DAYS_PLAN",
    name: "Days Plan",
  },
  concessionCode: {
    id: "S_STUDENT_FIN_MST_CON_CODE_MST",
    name: "Concession Code Master",
  },
  customConcessionConfig: {
    id: "S_STUDENT_FIN_MST_CUSTOM_CON_CONFIG",
    name: "Custom concession config",
  },
  exAakashianConfig: {
    id: "S_STUDENT_FIN_MST_EX_AAKASHIAN_CONFIG",
    name: "Ex-Aakashian Config",
  },
  onlinePackage: {
    id: "S_MST_ONLINE_PACKAGE",
    name: "Online Package",
  },
  concessionApproval: {
    id: "S_STUDENT_FIN_OPS_CONCESSION_APPROVAL",
    name: "Concession Approval",
  },
  holidayMaster: {
    id: "S_MST_HOLIDAY",
    name: "Holiday Master",
  },
  attendance: {
    id: "S_STD_MAN_ATTENDANCE",
    name: "Manual Attendance",
  },
  gateAttendance: {
    id: "S_STD_GATE_ATTENDANCE",
    name: "Gate Attendance",
  },
  reports: {
    id: "S_RPT_REPORT_SCREEN",
    name: "Reports",
  },
  idCards: {
    id: "S_EDP_ID_CARD",
    name: "ID Cards",
  },
  stockAndInventory: {
    id: "S_EDP_STOCK_AND_INVENTORY",
    name: "RCC | Stock & Inventory",
  },
  mastersAndMapping: {
    id: "S_EDP_STOCK_MASTERS_AND_MAPPING",
    name: "Masters & Mapping",
  },
  materialMaster: {
    id: "S_EDP_STOCK_MATERIAL_MASTER",
    name: "Material Master",
  },
  materialIdSetup: {
    id: "S_EDP_STOCK_MATERIAL_ID_SETUP",
    name: "Material ID Setup",
  },
  sapPeriodStatus: {
    id: "S_EDP_STOCK_SAP_PERIOD_STATUS",
    name: "SAP Period Status",
  },
  sapProfitCostCentreMapping: {
    id: 'S_EDP_STOCK_SAP_PRO_COST_CENTRE_MAPPING',
    name: 'SAP Profit Cost Centre Mapping'
  },
  // paymentManagement: {
  //   id: 'S_ENR_PAYMENT_MANAGEMENT',
  //   name: 'Payment management'
  // }
  bankAccount: {
    id: "S_STUDENT_FIN_MST_SETUP_BANK_ACCOUNT",
    name: "Set-up Bank Account",
  },
  miscCourseMaterialMapping: {
    id: 'S_EDP_STOCK_MISC_COURSE_MATERIAL_MAPPING',
    name: 'Misc Course Material Mapping'
  },
  dlpStockAndInventory: {
    id: 'S_EDP_STOCK_AND_INVENTORY_DLP',
    name: 'DLP | Stock & Inventory'
  },
  ltaConfig: {
    id: 'S_STUDENT_FIN_MST_LTA_CONFIG',
    name: 'LTA Config'
  },
  scholarshipConfig: {
    id: 'S_STUDENT_FIN_MST_SCHOLARSHIP_CONFIG',
    name: 'Scholarship Config'
  },
  studentDashboard: {
    id: 'S_STUDENT_DASHBOARD',
    name: 'Student Dashboard'
  },
  studentDashboardBatchDetails: {
    id: 'S_STUDENT_DASHBOARD_BATCH_DETAILS',
    name: 'Batch Details'
  },
  studentDashboardStudentInfo: {
    id: 'S_STUDENT_DASHBOARD_STUDENT_INFORMATION',
    name: 'Student Information'
  },
  studentDashboardResults: {
    id: 'S_STUDENT_DASHBOARD_RESULTS',
    name: 'Results'
  },
  studentDashboardStockDetails: {
    id: 'S_STUDENT_DASHBOARD_STOCK_DETAILS',
    name: 'Stock details'
  },
  studentDashboardPaymentDetails: {
    id: 'S_STUDENT_DASHBOARD_PAYMENT_DETAILS',
    name: 'Student Payment Details'
  },
  paymentGatewayConfiguration : {
    id: 'S_MST_PAYMENT_GATEWAY_CONFIGURATION',
    name : 'Payment Gateway Configuration'
  },
  qrCodeManagement: {
    id: 'S_STUDENT_FIN_MST_QR_CODE_MANAGEMENT',
    name: 'QR Code Management'
  },
  approveSupplementaryId: {
    id: 'S_EDP_APPROVE_SUPPLEMENTARY_ID',
    name: 'Approve supplementary ID'
  },
  businessAreaBasicDetails : {
    id : 'S_MST_BUS_AREA_BASIC_DETAILS',
    name : 'Business Area (Basic Details)'
  },
  businessAreaLocationDetails : {
    id : 'S_MST_BUS_AREA_LOCATION_DETAILS',
    name : 'Business Area (Location Details)'
  },
  businessAreaContactDetails : {
    id : 'S_MST_BUS_AREA_CONTACT_DETAILS',
    name : 'Business Area (Contact Details)'
  },
  digitalStockAndInventory: {
    id: 'S_DIGI_STOCK_LANDING_PAGE',
    name: 'DIGITAL | Stock & Inventory'
  },
  digitalMarkEligible: {
    id: 'S_DIGI_STOCK_MARK_ELIGIBLE',
    name: 'Mark Eligible'
  },
  digitalDispatchSchedule: {
    id: 'S_DIGI_STOCK_DISPATCH_SCHEDULE',
    name: 'Dispatch Schedule'
  },
  digitalStockIssueDispatch: {
    id: 'S_DIGI_STOCK_STOCK_ISSUE_DISPATCH',
    name: 'Stock Issue And Dispatch'
  },
  digitalBranchConsumption: {
    id: 'S_DIGI_STOCK_BRANCH_CONSUMPTION',
    name: 'Branch Consumption'
  },
  digitalBranchConsumptionReturn: {
    id: 'S_DIGI_STOCK_BRANCH_CONSUMPTION_RETURN',
    name: 'Branch Consumption Return'
  },
  digitalStockReturn: {
    id: 'S_DIGI_STOCK_STOCK_RETURN',
    name: 'Stock Return'
  },
  digitalTransactionHistory: {
    id: 'S_DIGI_STOCK_TRANSACTION_HISTORY',
    name: 'Transaction History'
  },
  digitalCourierVendor: {
    id: 'S_DIGI_STOCK_COURIER_VENDOR_MASTER',
    name: 'Courier Vendor Master'
  },
  digitalDownloadReports: {
    id: 'S_DIGI_STOCK_DOWNLOAD_REPORTS',
    name: 'Download Reports'
  },
  digitalStockReversal: {
    id: 'S_DIGI_STOCK_STOCK_REVERSAL',
    name: 'Stock Reversal'
  },

  paymentCollection: {
    id: 'S_STUDENT_FIN_OPS_PAYMENT_COLLECTION',
    name: 'Payment Collection'
  },
  paymentCollectionMaster:{
    id:'S_STUDENT_FIN_MST_PAYMENT_COLLECTION',
    name:'Payment Collection'
  },
  paymentCollectionMasterSdSequencing:{
     id:'S_STUDENT_FIN_MST_SD_SEQUENCING',
    name:'Security Deposit Sequencing'
  },
  paymentCollectionMasterPosMapping:{
    id:'S_STUDENT_FIN_MST_POS_MAPPING',
    name:'Pine labs POS Mapping'
  },
  paymentCollectionMasterBankMaster:{
    id:'S_STUDENT_FIN_MST_BANK_MASTER',
    name:'Bank Master'
  },
  paymentCollectionMasterBfControl:{
    id:'S_STUDENT_FIN_MST_BF_CONTROL',
    name:'Branch Functionality Control'
  }

};

// export const pages = {
//   groupCode: {
//     id: 'groupCode', //This is the [key] from server response.
//     name: 'Group Code', //This is the page route.
//     new: 'S_MST_BUS_GRP',
//   },
//   companyCode: {
//     id: 'companyCode',
//     name: 'Company Code',
//     new: 'S_MST_COMPANY_CODE',
//   },
//   academicCareer: {
//     id: 'academicCareer',
//     name: 'Academic Career',
//     new: 'S_MST_ACAD_CAREER',
//   },
//   term: {
//     id: 'term',
//     name: 'Term',
//     new: 'S_MST_TERM',
//   },
//   academicGroup: {
//     id: 'academicGroup',
//     name: 'Academic Group',
//     new: 'S_MST_ACAD_GROUP',
//   },
//   academicInstitution: {
//     id: 'academicInstitution',
//     name: 'Academic Institution',
//     new: 'S_MST_ACADEMIC_INSTITUTION',
//   },
//   itemTypeSetup: {
//     id: 'itemTypeSetup',
//     name: 'Item Type Setup',
//     new: 'S_MST_ITM_TYP_SETUP',
//   },
//   ItemTypeClassification: {
//     id: 'ItemTypeClassification',
//     name: 'Item Type Classification',
//     new: 'S_MST_ITM_TYP_CLASSIFICATION',
//   },
//   ItemTypeElement: {
//     id: 'ItemTypeElement',
//     name: 'Item Type Element',
//     new: 'S_MST_ITM_TYP_ELEMENT',
//   },
//   ItemTypeCategory: {
//     id: 'ItemTypeCategory',
//     name: 'Item Type Category',
//     new: 'S_MST_ITM_TYP_CATEGORY',
//   },
//   businessAreaSearch: {
//     id: 'businessAreaSearch',
//     name: 'Business Area',
//     new: 'S_MST_BUS_AREA',
//   },
//   taxCodeId: {
//     id: 'taxCodeId',
//     name: 'Tax Code ID',
//     new: 'S_MST_TAX_CODE_ID',
//   },
//   taxCodeType: {
//     id: 'taxCodeType',
//     name: 'Tax Code Type',
//     new: 'S_MST_TAX_CODE_TYPE',
//   },
//   termFormTable: {
//     id: 'termFormTable',
//     name: 'Term Table',
//     new: 'S_MST_TERM',
//   },
//   academicYear: {
//     id: 'academicYear',
//     name: 'Academic Year',
//     new: 'S_MST_ACADEMIC_YEAR',
//   },
//   session: {
//     id: 'session',
//     name: 'Session',
//     new: 'S_MST_SESSION',
//   },
//   courseCatalog: {
//     id: 'courseCatalog',
//     name: 'Course Catalog',
//     new: 'S_CRS_COURSE_CATALOG',
//   },
//   courseAttribute: {
//     id: 'courseAttribute',
//     name: 'Course Attribute',
//     new: 'S_CRS_COURSE_ATTR',
//   },
//   courseAttributeValue: {
//     id: 'courseAttributeValue',
//     name: 'Course Attribute Value',
//     new: 'S_CRS_ATTR_VALUES',
//   },
//   courseAttributes: {
//     id: 'courseAttributes',
//     name: 'Course Attributes Mapping',
//     new: 'S_CRS_MAPPING_OF_CATT_ND_VAL',
//   },
//   courseComponent: {
//     id: 'courseComponent',
//     name: 'Course Component',
//     new: 'S_CRS_COURSE_COMPONENT',
//   },
//   subjectArea: {
//     id: 'subjectArea',
//     name: 'Subject Area',
//     new: 'S_CRS_SUB_AREA',
//   },
//   pricingZone: {
//     id: 'pricingZone',
//     name: 'Pricing Zone',
//     new: 'S_PRC_PRC_ZONE',
//   },
//   processInstanceSearch: {
//     id: 'processInstanceSearch',
//     name: 'Course Fee Upload',
//     new: 'S_PRC_FEE_SETUP',
//   },
//   level1Approval: {
//     id: 'level1Approval',
//     name: 'Level 1 Approval',
//     new: 'S_PRC_FEE_SETUP_L1',
//   },
//   level2Approval: {
//     id: 'level2Approval',
//     name: 'Level 2 Approval',
//     new: 'S_PRC_FEE_SETUP_L2',
//   },
//   courseFeeInformation: {
//     id: 'courseFeeInformation',
//     name: 'Course Fee Information',
//     new: 'S_PRC_FEE_SETUP_MANUAL',
//   },
//   itemTypeSequence: {
//     id: 'itemTypeSequence',
//     name: 'Item Type Sequence',
//     new: 'S_PRC_ITM_TYP_SEQUENCE',
//   },
//   feeSetupRules: {
//     id: 'feeSetupRules',
//     name: 'Fee Setup Rules',
//     new: 'S_PRC_FEE_SETUP_RULES',
//   },
//   documentType: {
//     id: 'documentType',
//     name: 'Document Type',
//     new: 'S_DMS_DOC_TYPE',
//   },
//   documentFormat: {
//     id: 'documentFormat',
//     name: 'Document Format',
//     new: 'S_DMS_DOC_FORMAT',
//   },
//   processTagType: {
//     id: 'processTagType',
//     name: 'Process Tag Type',
//     new: 'S_DMS_PROC_TAG_TYP',
//   },
//   documentProcessTag: {
//     id: 'documentProcessTag',
//     name: 'Document Process Tag',
//     new: 'S_DMS_PROC_TO_DOC_MAPPING',
//   },
//   templateUploadManager: {
//     id: 'templateUploadManager',
//     name: 'Templates',
//     new: 'S_DMS_TEMP_VERSION',
//   },
//   BusinessUnitType: {
//     id: 'BusinessUnitType',
//     name: 'Business Unit Type',
//     new: 'S_MST_BUS_UNIT_TYPE',
//   },
//   Region: {
//     id: 'Region',
//     name: 'Region',
//     new: 'S_MST_REG',
//   },
//   AddressCategory: {
//     id: 'AddressCategory',
//     name: 'AddressCategory',
//     new: 'S_MST_ADD_CAT',
//   },
//   Country: {
//     id: 'Country',
//     name: 'Country',
//     new: 'S_MST_CNTRY',
//   },
//   State: {
//     id: 'State',
//     name: 'State',
//     new: 'S_MST_STATE',
//   },
//   District: {
//     id: 'District',
//     name: 'District',
//     new: 'S_MST_DIST',
//   },
//   City: {
//     id: 'City',
//     name: 'City',
//     new: 'S_MST_CITY',
//   },
//   Pincode: {
//     id: 'Pincode',
//     name: 'Pincode',
//     new: 'S_MST_PIN',
//   },
//   businessType: {
//     id: 'businessType',
//     name: 'Bussiness Type',
//     new: 'S_MST_BUS_TYPE',
//   },
//   Currency: {
//     id: 'Currency', //This is the [key] from server response.
//     name: 'Currency', //This is the page route.
//     new: 'S_MST_CURRENCY',
//   },
//   operationalZone: {
//     id: 'operationalZone',
//     name: 'Operational Zone',
//     new: 'S_MST_OP_ZONE',
//   },

//   //remaining master

//   HomePage: {
//     id: 'HomePage',
//     name: 'Dashboard',
//   },
//   courseAttributesMapping: {
//     id: 'courseAttributesMapping',
//     name: 'Course Attributes Mapping',
//   },
//   documentManagementSearch: {
//     id: 'documentManagementSearch',
//     name: 'Document Management',
//   },
//   bulkUpload: {
//     id: 'bulkUpload',
//     name: 'Bulk Upload',
//   },
//   projectDetails: {
//     id: 'projectDetails',
//     name: 'Project Details',
//   },
//   permissionDetails: {
//     id: 'permissionDetails',
//     name: 'Permission Details',
//   },
//   languageMaster: {
//     id: 'languageMaster',
//     name: 'Language Master',
//   },
//   websiteSync: {
//     id: 'websiteSync',
//     name: 'Website Sync',
//   },
//   examSyllabus: {
//     id: 'examSyllabus',
//     name: 'Exam Syllabus',
//   },

//   departmentType: {
//     id: 'departmentType',
//     name: 'Department Type',
//   },
//   paymentMode: {
//     id: 'paymentMode',
//     name: 'Payment Mode',
//   },
//   jobTitle: {
//     id: 'jobTitle',
//     name: 'Job Title',
//   },
//   addressCategory: {
//     id: 'addressCategory',
//     name: 'Address Category',
//   },
//   houseBank: {
//     id: 'houseBank',
//     name: 'House Bank',
//   },

//   approvalLevel: {
//     id: 'approvalLevel',
//     name: 'Approval Level',
//   },
//   profitCenter: {
//     id: 'profitCenter',
//     name: 'Profit Center Mapping',
//   },
//   thirdPartySystems: {
//     id: 'thirdPartySystems',
//     name: 'Third Party Systems',
//   },
//   scholarship: {
//     id: 'scholarship',
//     name: 'Scholarship',
//   },
//   concessionSubCategory: {
//     id: 'concessionSubCategory',
//     name: 'Concession SubCategory',
//   },
//   concessionSubSubCategory: {
//     id: 'concessionSubSubCategory',
//     name: 'Concession Sub Sub Category',
//   },
//   invoiceStatus: {
//     id: 'invoiceStatus',
//     name: 'Invoice Status',
//   },
//   AccountingEntryType: {
//     id: 'AccountingEntryType',
//     name: 'Accounting Entry Type',
//   },
//   AccountingType: {
//     id: 'AccountingType',
//     name: 'Accounting Type',
//   },
//   glCode: {
//     id: 'glCode',
//     name: 'GLCode',
//   },
//   searchBank: {
//     id: 'searchBank',
//     name: 'Bank Code',
//   },
//   meetingType: {
//     id: 'meetingType',
//     name: 'Meeting Type',
//   },
//   adjustmentFlag: {
//     id: 'adjustmentFlag',
//     name: 'Adjustment Flag',
//   },
//   msGroupSequence: {
//     id: 'msGroupSequence',
//     name: 'MS Group Sequence',
//   },
//   approvalStatus: {
//     id: 'approvalStatus',
//     name: 'Approval Status',
//   },
//   paymentsMethod: {
//     id: 'paymentsMethod',
//     name: 'Payments Method',
//   },
//   paymentModePinelab: {
//     id: 'paymentModePinelab',
//     name: 'Payment Mode Pinelab',
//   },
//   paymentSchedule: {
//     id: 'paymentSchedule',
//     name: 'Payment Schedule',
//   },
//   extTestResult: {
//     id: 'extTestResult',
//     name: 'External Test Result',
//   },

//   gender: {
//     id: 'gender',
//     name: 'Gender',
//   },
//   parentOccupation: {
//     id: 'parentOccupation',
//     name: 'Parent Occupation',
//   },
//   phonetype: {
//     id: 'phonetype',
//     name: 'Phone Type',
//   },
//   addresstype: {
//     id: 'addresstype',
//     name: 'Address type',
//   },
//   emailType: {
//     id: 'emailType',
//     name: 'Email Type',
//   },
//   admission: {
//     id: 'admission',
//     name: 'Admission Type',
//   },
//   category: {
//     id: 'category',
//     name: 'Category',
//   },
//   studentIdStatus: {
//     id: 'studentIdStatus',
//     name: 'Student ID Status',
//   },
//   bloodGroup: {
//     id: 'bloodGroup',
//     name: 'Blood group',
//   },
//   saletype: {
//     id: 'saletype',
//     name: 'Sale Type',
//   },

//   CourseCatalogSequence: {
//     id: 'CourseCatalogSequence',
//     name: 'Course Catalog Sequence',
//   },

//   examGroup: {
//     id: 'examGroup',
//     name: 'Exam Group',
//   },
//   examPattern: {
//     id: 'examPattern',
//     name: 'Exam Pattern',
//   },
//   examIDbulkSearch: {
//     id: 'examIDbulkSearch',
//     name: 'Exam ID Bulk Search',
//   },
//   examIdGenerationSearch: {
//     id: 'examIdGenerationSearch',
//     name: 'Exam ID Generation Form',
//   },
//   examBoardType: {
//     id: 'examBoardType',
//     name: 'Exam Board Type',
//   },
//   testType: {
//     id: 'testType',
//     name: 'Test Type',
//   },
//   testMode: {
//     id: 'testMode',
//     name: 'Test Mode',
//   },
//   testMethod: {
//     id: 'testMethod',
//     name: 'Test Method',
//   },
//   testHandledBy: {
//     id: 'testHandledBy',
//     name: 'Test Handled By',
//   },
//   examDateConfig: {
//     id: 'examDateConfig',
//     name: 'Exam Date configuration',
//   },
//   testSequence: {
//     id: 'testSequence',
//     name: 'Test Sequence',
//   },
//   studentInfo: {
//     id: 'studentInfo',
//     name: 'Student Info',
//   },
//   internalTestResult: {
//     id: 'internalTestResult',
//     name: 'Internal Test Result',
//   },
//   courseCatalogSequence: {
//     id: 'courseCatalogSequence',
//     name: 'Course Catalog Sequence',
//   },

//   attendance: {
//     id: 'attendance',
//     name: 'Add RFID',
//   },
//   subject: {
//     id: 'subject',
//     name: 'Subject',
//   },

//   dayMaster: {
//     id: 'dayMaster',
//     name: 'Day',
//   },
//   building: {
//     id: 'building',
//     name: 'Building',
//   },
//   academicPhase: {
//     id: 'academicPhase',
//     name: 'Academic Phase',
//   },
//   academicPhaseMapping: {
//     id: 'academicPhaseMapping',
//     name: 'Academic Phase Mapping',
//   },
//   chapters: {
//     id: 'chapters',
//     name: 'Chapters',
//   },
//   topics: {
//     id: 'topics',
//     name: 'Topics',
//   },
//   subTopics: {
//     id: 'subTopics',
//     name: 'Sub Topic',
//   },
//   subSubTopic: {
//     id: 'subSubTopic',
//     name: 'Sub Sub Topic',
//   },
//   attendanceDeviceType: {
//     id: 'attendanceDeviceType',
//     name: 'Attendance',
//   },
//   attendanceMachine: {
//     id: 'attendanceMachine',
//     name: 'Attendance Machine',
//   },
//   lessonPlan: {
//     id: 'lessonPlan',
//     name: 'Lesson Plan',
//   },
//   documentStatus: {
//     id: 'documentStatus',
//     name: 'Document Status',
//   },
//   personType: {
//     id: 'personType',
//     name: 'Master-Person Type',
//   },
//   documentSource: {
//     id: 'documentSource',
//     name: 'Document Source',
//   },
// };

// {
//   roleType: 'Admin',
export const qaScope = {
  configRefresh: "C,R,U,D,S",
};
//   createBatch: 'C,R,U,D,S',
//   examBoardType: 'C,R,U,D,S',
//     Currency: 'C,R,U,D,S',
//     ItemTypeElement: 'C,R,U,D,S',
//     ItemTypeClassification: 'C,R,U,D,S',
//     HomePage: 'C,R,U,D,S',
//     term: 'C,R,U,D,S',
//     academicGroup: 'C,R,U,D,S',
//     courseAttribute: 'C,R,U,D,S',
//     courseAttributeValue: 'C,R,U,D,S',
//     courseAttributesMapping: 'C,R,U,D,S',
//     BusinessUnitType: 'C,R,U,D,S',
//     Region: 'C,R,U,D,S',
//     AddressCategory: 'C,R,U,D,S',
//     Country: 'C,R,U,D,S',
//     State: 'C,R,U,D,S',
//     District: 'C,R,U,D,S',
//     City: 'C,R,U,D,S',
//     Pincode: 'C,R,U,D,S',
//     academicYear: 'C,R,U,D,S',
//     session: 'C,R,U,D,S',
//     termFormTable: 'C,R,U,D,S',
//     businessAreaSearch: 'C,R,U,D,S',
//     courseFeeInformation: 'C,R,U,D,S',
//     processInstanceSearch: 'C,R,U,D,S',
//     level1Approval: 'C,R,U,D,S,A',
//     level2Approval: 'C,R,U,D,S,A',
//     examDateConfig: 'C,R,U,D,S',
//     testSequence: 'C,R,U,D,S',
//     epmrStatus: 'C,R,U,D,S',
//     receiptLabel: 'C,R,U,D,S',
//     companyCode: 'C,R,U,D,S',
//     paymentMethod: 'C,R,U,D,S',
//     typeClassification: 'C,R,U,D,S',
//     itemTypeElement: 'C,R,U,D,S',
//     ItemTypeCategory: 'C,R,U,D,S',
//     businessUnitType: 'C,R,U,D,S',
//     businessType: 'C,R,U,D,S',
//     region: 'C,R,U,D,S',
//     accessCode: 'C,R,U,D,S',
//     taxCodeType: 'C,R,U,D,S',
//     groupCode: 'C,R,U,D,S',
//     machineType: 'C,R,U,D,S',
//     country: 'C,R,U,D,S',
//     state: 'C,R,U,D,S',
//     district: 'C,R,U,D,S',
//     city: 'C,R,U,D,S',
//     pincode: 'C,R,U,D,S',
//     currency: 'C,R,U,D,S',
//     calculatorType: 'C,R,U,D,S',
//     documentStatus: 'C,R,U,D,S',
//     personType: 'C,R,U,D,S',
//     documentProcessTag: 'C,R,U,D,S',
//     documentFormat: 'C,R,U,D,S',
//     documentType: 'C,R,U,D,S',
//     documentSource: 'C,R,U,D,S',
//     processTagType: 'C,R,U,D,S',
//     cashAwardType: 'C,R,U,D,S',
//     vendorType: 'C,R,U,D,S',
//     messageCatalog: 'C,R,U,D,S',
//     osFollowStatus: 'C,R,U,D,S',
//     templateUploadManager: 'C,R,U,D,S',
//     internalTestResult: 'C,R,U,D,S',
//     feeCalculator: 'C,R,U,D,S',
//     studentInfo: 'C,R,U,D,S',
//     applicationIdGeneration: 'C,R,U,D,S',
//     documentManagementSearch: 'C,R,U,D,S',
//     bulkUpload: 'C,R,U,D,S',
//     epmr: 'C,R,U,D,S',
//     timeTable: 'C,R,U,D,S',
//     services: 'C,R,U,D,S',
//     userRolePage: 'C,R,U,D,S',
//     mapProcessToPage: 'C,R,U,D,S',
//     roleToProcess: 'C,R,U,D,S',
//     offlineAttendanceSetup: 'C,R,U,D,S',
//     projectDetails: 'C,R,U,D,S',
//     permissionDetails: 'C,R,U,D,S',
// languageMaster: 'C,R,U,D,S',
//     websiteSync: 'C,R,U,D,S',
//     examSyllabus: 'C,R,U,D,S',
//     academicInstitution: 'C,R,U,D,S',
//     departmentType: 'C,R,U,D,S',
//     paymentMode: 'C,R,U,D,S',
//     jobTitle: 'C,R,U,D,S',
//     addressCategory: 'C,R,U,D,S',
//     houseBank: 'C,R,U,D,S',
//     taxCodeId: 'C,R,U,D,S',
//     approvalLevel: 'C,R,U,D,S',
//     profitCenter: 'C,R,U,D,S',
//     thirdPartySystems: 'C,R,U,D,S',
//     scholarship: 'C,R,U,D,S',
//     concessionSubCategory: 'C,R,U,D,S',
//     concessionSubSubCategory: 'C,R,U,D,S',
//     invoiceStatus: 'C,R,U,D,S',
//     AccountingEntryType: 'C,R,U,D,S',
//     AccountingType: 'C,R,U,D,S',
//     glCode: 'C,R,U,D,S',
//     searchBank: 'C,R,U,D,S',
//     meetingType: 'C,R,U,D,S',
//     adjustmentFlag: 'C,R,U,D,S',
//     msGroupSequence: 'C,R,U,D,S',
//     courseCatalogSequence: 'C,R,U,D,S',
//     subjectArea: 'C,R,U,D,S',
//     courseComponent: 'C,R,U,D,S',
//     academicCareer: 'C,R,U,D,S',
//     itemTypeSetup: 'C,R,U,D,S',
//     courseCatalog: 'C,R,U,D,S',
//     feeSetupRules: 'C,R,U,D,S',
//     itemTypeSequence: 'C,R,U,D,S',
//     extTestResult: 'C,R,U,D,S',
//     operationalZone: 'C,R,U,D,S',
//     attendance: 'C,R,U,D,S',
//     subject: 'C,R,U,D,S',
//     dayMaster: 'C,R,U,D,S',
//     building: 'C,R,U,D,S',
//     academicPhase: 'C,R,U,D,S',
//     academicPhaseMapping: 'C,R,U,D,S',
//     chapters: 'C,R,U,D,S',
//     topics: 'C,R,U,D,S',
//     subTopics: 'C,R,U,D,S',
//     subSubTopic: 'C,R,U,D,S',
//     attendanceDeviceType: 'C,R,U,D,S',
//     attendanceMachine: 'C,R,U,D,S',
//     lessonPlan: 'C,R,U,D,S',
// }
// export const qaScope = {
//   //     Currency: 'C,R,U,D,S',
//   //     ItemTypeElement: 'C,R,U,D,S',
//   //     ItemTypeClassification: 'C,R,U,D,S',
//   //     HomePage: 'C,R,U,D,S',
//   //     term: 'C,R,U,D,S',
//   //     academicGroup: 'C,R,U,D,S',
//   //     courseAttribute: 'C,R,U,D,S',
//   //     courseAttributeValue: 'C,R,U,D,S',
//   //     courseAttributesMapping: 'C,R,U,D,S',
//   //     BusinessUnitType: 'C,R,U,D,S',
//   //     Region: 'C,R,U,D,S',
//   AddressCategory: 'C,R,U,D,S',
//   testMode: 'C,R,U,D,S',
//   testType: 'C,R,U,D,S',
//   examBoardType: 'C,R,U,D,S',
//   examPattern: 'C,R,U,D,S',
//   addresstype: 'C,R,U,D,S',
//   admission: 'C,R,U,D,S',
//   emailType: 'C,R,U,D,S',
//   bloodGroup: 'C,R,U,D,S',
//   //     Country: 'C,R,U,D,S',
//   //     State: 'C,R,U,D,S',
//   //     District: 'C,R,U,D,S',
//   //     City: 'C,R,U,D,S',
//   //     Pincode: 'C,R,U,D,S',
//   //     academicYear: 'C,R,U,D,S',
//   //     session: 'C,R,U,D,S',
//   //     termFormTable: 'C,R,U,D,S',
//   //     businessAreaSearch: 'C,R,U,D,S',
//   //     courseFeeInformation: 'C,R,U,D,S',
//   //     processInstanceSearch: 'C,R,U,D,S',
//   //     level1Approval: 'C,R,U,D,S,A',
//   //     level2Approval: 'C,R,U,D,S,A',
//   examDateConfig: 'C,R,U,D,S',
//   //     testSequence: 'C,R,U,D,S',
//   //     epmrStatus: 'C,R,U,D,S',
//   //     receiptLabel: 'C,R,U,D,S',
//   //     companyCode: 'C,R,U,D,S',
//   paymentsMethod: 'C,R,U,D,S',
//   //     typeClassification: 'C,R,U,D,S',
//   //     itemTypeElement: 'C,R,U,D,S',
//   //     ItemTypeCategory: 'C,R,U,D,S',
//   //     businessUnitType: 'C,R,U,D,S',
//   //     businessType: 'C,R,U,D,S',
//   //     region: 'C,R,U,D,S',
//   //     accessCode: 'C,R,U,D,S',
//   //     taxCodeType: 'C,R,U,D,S',
//   //     groupCode: 'C,R,U,D,S',
//   phonetype: 'C,R,U,D,S',
//   gender: 'C,R,U,D,S',
//   machineType: 'C,R,U,D,S',
//   examGroup: 'C,R,U,D,S',
//   //     country: 'C,R,U,D,S',
//   //     state: 'C,R,U,D,S',
//   //     district: 'C,R,U,D,S',
//   //     city: 'C,R,U,D,S',
//   //     pincode: 'C,R,U,D,S',
//   //     currency: 'C,R,U,D,S',
//   //     calculatorType: 'C,R,U,D,S',
//   saletype: 'C,R,U,D,S',
//   category: 'C,R,U,D,S',
//   documentStatus: 'C,R,U,D,S',
//   personType: 'C,R,U,D,S',
//   parentOccupation: 'C,R,U,D,S',
//   //     documentProcessTag: 'C,R,U,D,S',
//   //     documentFormat: 'C,R,U,D,S',
//   //     documentType: 'C,R,U,D,S',
//   documentSource: 'C,R,U,D,S',
//   //     processTagType: 'C,R,U,D,S',
//   //     cashAwardType: 'C,R,U,D,S',
//   //     vendorType: 'C,R,U,D,S',
//   //     messageCatalog: 'C,R,U,D,S',
//   //     osFollowStatus: 'C,R,U,D,S',
//   //     templateUploadManager: 'C,R,U,D,S',
//   internalTestResult: 'C,R,U,D,S',
//   //     feeCalculator: 'C,R,U,D,S',
//   studentInfo: 'C,R,U,D,S',
//   //     applicationIdGeneration: 'C,R,U,D,S',
//   //     documentManagementSearch: 'C,R,U,D,S',
//   bulkUpload: 'C,R,U,D,S',
//   epmr: 'C,R,U,D,S',
//   //     timeTable: 'C,R,U,D,S',
//   //     services: 'C,R,U,D,S',
//   //     userRolePage: 'C,R,U,D,S',
//   //     mapProcessToPage: 'C,R,U,D,S',
//   //     roleToProcess: 'C,R,U,D,S',
//   offlineAttendanceSetup: 'C,R,U,D,S',
//   projectDetails: 'C,R,U,D,S',
//   permissionDetails: 'C,R,U,D,S',
//   languageMaster: 'C,R,U,D,S',
//   websiteSync: 'C,R,U,D,S',
//   examSyllabus: 'C,R,U,D,S',
//   academicInstitution: 'C,R,U,D,S',
//   departmentType: 'C,R,U,D,S',
//   paymentMethod: 'C,R,U,D,S',
//   paymentModePinelab: 'C,R,U,D,S',
//   paymentSchedule: 'C,R,U,D,S',
//   jobTitle: 'C,R,U,D,S',
//   // addressCategory: 'C,R,U,D,S',
//   houseBank: 'C,R,U,D,S',
//   taxCodeId: 'C,R,U,D,S',
//   approvalLevel: 'C,R,U,D,S',
//   approvalStatus: 'C,R,U,D,S',
//   profitCenter: 'C,R,U,D,S',
//   thirdPartySystems: 'C,R,U,D,S',
//   scholarship: 'C,R,U,D,S',
//   concessionSubCategory: 'C,R,U,D,S',
//   concessionSubSubCategory: 'C,R,U,D,S',
//   invoiceStatus: 'C,R,U,D,S',
//   AccountingEntryType: 'C,R,U,D,S',
//   AccountingType: 'C,R,U,D,S',
//   glCode: 'C,R,U,D,S',
//   searchBank: 'C,R,U,D,S',
//   meetingType: 'C,R,U,D,S',
//   adjustmentFlag: 'C,R,U,D,S',
//   msGroupSequence: 'C,R,U,D,S',
//   courseCatalogSequence: 'C,R,U,D,S',
//   // subjectArea: 'C,R,U,D,S',
//   courseComponent: 'C,R,U,D,S',
//   academicCareer: 'C,R,U,D,S',
//   // itemTypeSetup: 'C,R,U,D,S',
//   // courseCatalog: 'C,R,U,D,S',
//   // feeSetupRules: 'C,R,U,D,S',
//   // itemTypeSequence: 'C,R,U,D,S',
//   extTestResult: 'C,R,U,D,S',
//   operationalZone: 'C,R,U,D,S',
//   attendance: 'C,R,U,D,S',
//   subject: 'C,R,U,D,S',
//   dayMaster: 'C,R,U,D,S',
//   building: 'C,R,U,D,S',
//   studentIdStatus: 'C,R,U,D,S',
//   // academicPhase: 'C,R,U,D,S',
//   // academicPhaseMapping: 'C,R,U,D,S',
//   chapters: 'C,R,U,D,S',
//   topics: 'C,R,U,D,S',
//   subTopics: 'C,R,U,D,S',
//   subSubTopic: 'C,R,U,D,S',
//   attendanceDeviceType: 'C,R,U,D,S',
//   attendanceMachine: 'C,R,U,D,S',
//   lessonPlan: 'C,R,U,D,S',
//   userRolePage: 'C,R,U,D,S',
//   mapProcessToPage: 'C,R,U,D,S',
//   roleToProcess: 'C,R,U,D,S',
//   timeTable: 'C,R,U,D,S',
//   services: 'C,R,U,D,S',
//   classSection: 'C,R,U,D,S',
//   examBoardType: 'C,R,U,D,S',
//   //     Currency: 'C,R,U,D,S',
//   //     ItemTypeElement: 'C,R,U,D,S',
//   //     ItemTypeClassification: 'C,R,U,D,S',
//   //     HomePage: 'C,R,U,D,S',
//   //     term: 'C,R,U,D,S',
//   //     academicGroup: 'C,R,U,D,S',
//   //     courseAttribute: 'C,R,U,D,S',
//   //     courseAttributeValue: 'C,R,U,D,S',
//   //     courseAttributesMapping: 'C,R,U,D,S',
//   //     BusinessUnitType: 'C,R,U,D,S',
//   //     Region: 'C,R,U,D,S',
//   //     AddressCategory: 'C,R,U,D,S',
//   //     Country: 'C,R,U,D,S',
//   //     State: 'C,R,U,D,S',
//   //     District: 'C,R,U,D,S',
//   //     City: 'C,R,U,D,S',
//   //     Pincode: 'C,R,U,D,S',
//   //     academicYear: 'C,R,U,D,S',
//   //     session: 'C,R,U,D,S',
//   //     termFormTable: 'C,R,U,D,S',
//   //     businessAreaSearch: 'C,R,U,D,S',
//   //     courseFeeInformation: 'C,R,U,D,S',
//   //     processInstanceSearch: 'C,R,U,D,S',
//   //     level1Approval: 'C,R,U,D,S,A',
//   //     level2Approval: 'C,R,U,D,S,A',
//   //     examDateConfig: 'C,R,U,D,S',
//   //     testSequence: 'C,R,U,D,S',
//   //     epmrStatus: 'C,R,U,D,S',
//   //     receiptLabel: 'C,R,U,D,S',
//   //     companyCode: 'C,R,U,D,S',
//   //     paymentMethod: 'C,R,U,D,S',
//   //     typeClassification: 'C,R,U,D,S',
//   //     itemTypeElement: 'C,R,U,D,S',
//   //     ItemTypeCategory: 'C,R,U,D,S',
//   //     businessUnitType: 'C,R,U,D,S',
//   //     businessType: 'C,R,U,D,S',
//   //     region: 'C,R,U,D,S',
//   //     accessCode: 'C,R,U,D,S',
//   //     taxCodeType: 'C,R,U,D,S',
//   //     groupCode: 'C,R,U,D,S',
//   //     machineType: 'C,R,U,D,S',
//   //     country: 'C,R,U,D,S',
//   //     state: 'C,R,U,D,S',
//   //     district: 'C,R,U,D,S',
//   //     city: 'C,R,U,D,S',
//   //     pincode: 'C,R,U,D,S',
//   //     currency: 'C,R,U,D,S',
//   //     calculatorType: 'C,R,U,D,S',
//   //     documentStatus: 'C,R,U,D,S',
//   //     personType: 'C,R,U,D,S',
//   documentProcessTag: 'C,R,U,D,S',
//   documentFormat: 'C,R,U,D,S',
//   documentType: 'C,R,U,D,S',
//   //     documentSource: 'C,R,U,D,S',
//   processTagType: 'C,R,U,D,S',
//   //     cashAwardType: 'C,R,U,D,S',
//   //     vendorType: 'C,R,U,D,S',
//   //     messageCatalog: 'C,R,U,D,S',
//   //     osFollowStatus: 'C,R,U,D,S',
//   //     templateUploadManager: 'C,R,U,D,S',
//   //     internalTestResult: 'C,R,U,D,S',
//   //     feeCalculator: 'C,R,U,D,S',
//   //     studentInfo: 'C,R,U,D,S',
//   //     applicationIdGeneration: 'C,R,U,D,S',
//   //     documentManagementSearch: 'C,R,U,D,S',
//   //     bulkUpload: 'C,R,U,D,S',
//   //     epmr: 'C,R,U,D,S',
//   //     timeTable: 'C,R,U,D,S',
//   //     services: 'C,R,U,D,S',
//   //     userRolePage: 'C,R,U,D,S',
//   //     mapProcessToPage: 'C,R,U,D,S',
//   //     roleToProcess: 'C,R,U,D,S',
//   //     offlineAttendanceSetup: 'C,R,U,D,S',
//   //     projectDetails: 'C,R,U,D,S',
//   //     permissionDetails: 'C,R,U,D,S',
//   languageMaster: 'C,R,U,D,S',
//   programAction: 'C,R,U,D,S',
//   actionReason: 'C,R,U,D,S',
//   programActions: 'C,R,U,D,S',
//   //     websiteSync: 'C,R,U,D,S',
//   //     examSyllabus: 'C,R,U,D,S',
//   //     academicInstitution: 'C,R,U,D,S',
//   //     departmentType: 'C,R,U,D,S',
//   //     paymentMode: 'C,R,U,D,S',
//   //     jobTitle: 'C,R,U,D,S',
//   //     addressCategory: 'C,R,U,D,S',
//   //     houseBank: 'C,R,U,D,S',
//   //     taxCodeId: 'C,R,U,D,S',
//   //     approvalLevel: 'C,R,U,D,S',
//   //     profitCenter: 'C,R,U,D,S',
//   //     thirdPartySystems: 'C,R,U,D,S',
//   //     scholarship: 'C,R,U,D,S',
//   //     concessionSubCategory: 'C,R,U,D,S',
//   //     concessionSubSubCategory: 'C,R,U,D,S',
//   //     invoiceStatus: 'C,R,U,D,S',
//   //     AccountingEntryType: 'C,R,U,D,S',
//   //     AccountingType: 'C,R,U,D,S',
//   //     glCode: 'C,R,U,D,S',
//   //     searchBank: 'C,R,U,D,S',
//   //     meetingType: 'C,R,U,D,S',
//   //     adjustmentFlag: 'C,R,U,D,S',
//   //     msGroupSequence: 'C,R,U,D,S',
//   //     courseCatalogSequence: 'C,R,U,D,S',
//   //     subjectArea: 'C,R,U,D,S',
//   //     courseComponent: 'C,R,U,D,S',
//   //     academicCareer: 'C,R,U,D,S',
//   //     itemTypeSetup: 'C,R,U,D,S',
//   //     courseCatalog: 'C,R,U,D,S',
//   //     feeSetupRules: 'C,R,U,D,S',
//   //     itemTypeSequence: 'C,R,U,D,S',
//   //     extTestResult: 'C,R,U,D,S',
//   //     operationalZone: 'C,R,U,D,S',
//   //     attendance: 'C,R,U,D,S',
//   //     subject: 'C,R,U,D,S',
//   //     dayMaster: 'C,R,U,D,S',
//   //     building: 'C,R,U,D,S',
//   //     academicPhase: 'C,R,U,D,S',
//   //     academicPhaseMapping: 'C,R,U,D,S',
//   //     chapters: 'C,R,U,D,S',
//   //     topics: 'C,R,U,D,S',
//   //     subTopics: 'C,R,U,D,S',
//   //     subSubTopic: 'C,R,U,D,S',
//   //     attendanceDeviceType: 'C,R,U,D,S',
//   //     attendanceMachine: 'C,R,U,D,S',
//   //     lessonPlan: 'C,R,U,D,S',
// }
//   currentScreenId: 'test',
// }
