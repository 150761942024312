import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Input, Table } from 'reactstrap';
import Select2 from 'react-select2-wrapper';
import moment from 'moment';
import { SetupBuAndCourseSecuiryHeader } from '../../../../common/commonComponents/masterRow/masterRow';
import {
	concessionServiceBaseUrl,
	failureToast,
	putpost,
	putpost2,
	successToast,
} from '../../../../../../../services/http';
import {
	fetchAllData,
	getDispValForReactSelectByOneID,
	searchInTable,
} from '../../../../common/utils/methods/commonMethods/utilityMethod';
import { useHistory } from 'react-router-dom';
let _ = require('lodash');
const SetupBuAndCourseTable = (props) => {
	const {
		setupBuData,
		setSetupBuData,
		isSaveVisible,
		setIsSaveVisible,
		termData,
		subsubCategoryId,
		fetchData,
		setSubSubCategoryData,
	} = props;
	const [searchedValue, setSearchedValue] = useState();
	const [searchedData, setSearchedData] = useState([]);
	const [isAllCourses, setIsAllCourses] = useState(false);
	const [status, setStatus] = useState('ACTIVE');
	const [isSearch, setIsSearch] = useState(false);
	const [selectedTerm, setSelectedTerm] = useState();
	let history = useHistory();
	const addNewClass = () => {
		setSetupBuData([
			{
				term: null,
				isAllCourse: null,
				courseCount: null,
				isAllBusinessArea: null,
				businessAreaCount: null,
				status: null,
				updatedOn: moment(),
				isEdit: false,
				isNew: true,
			},
			...setupBuData,
		]);
	};
	const newRow = () => {
		resetFields();
		let cb = () => {
			addNewClass();
			setIsSaveVisible(true);
		};
		let _tempArray = setupBuData;
		_tempArray = _.sortBy(_tempArray, [
			function (o) {
				return o.value;
			},
		]);
		setSetupBuData(_tempArray);
		setIsSaveVisible(false);
		cb();
	};
	const editRow = (index) => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			setIsSaveVisible(true);
			// let tempData = isSearch ? searchedData: setupBuData;
			let tempData = setupBuData;
			tempData[index]['isEdit'] = true;
			setSelectedTerm(tempData[index]['termId']);
			setStatus(tempData[index]['status']);
			setIsAllCourses(tempData[index]['allCourses']);
		}
	};
	const deleteRow = (index) => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let tempData = setupBuData;
			// let tempData = isSearch ? searchedData: setupBuData;
			let id = tempData[index]['id'];
			if (window.confirm('Are you sure you want to delete this Term?')) {
				putpost2(
					concessionServiceBaseUrl + '/buAndCourseSecurity/' + id,
					(data) => {
						successToast(data.message);
						fetchData(
							concessionServiceBaseUrl +
								'/buAndCourseSecurity/subSubCategory/' +
								subsubCategoryId,
							setSetupBuData
						);
					},
					(data) => {
						failureToast(data.message);
					},
					'Delete'
				);
			}
		}
	};
	const saveRow = (index) => {
		let tempData = setupBuData;
		// let tempData = isSearch ? searchedData: setupBuData;
		let isValid = true;
		if (!selectedTerm) {
			failureToast('Please Select Term');
			isValid = false;
		} else {
			if (tempData[index]['isEdit']) {
				let tempObject = {
					addSubSubCategoryId: subsubCategoryId,
					allCourses: isAllCourses,
					status: status,
					termId: selectedTerm,
				};
				let id = tempData[index]['id'];
				let url = concessionServiceBaseUrl + '/buAndCourseSecurity/' + id;
				let flag = putPostApi(url, tempObject, 'put');
				if (flag) {
					tempData[index]['isEdit'] = false;
					resetFields();
				}
				tempData[index]['isEdit'] = false;
			} else if (tempData[index]['isNew']) {
				let tempObject = {
					addSubSubCategoryId: subsubCategoryId,
					allCourses: isAllCourses,
					status: status,
					termId: selectedTerm,
				};
				let flag = putPostApi(
					concessionServiceBaseUrl + '/buAndCourseSecurity',
					tempObject,
					'post'
				);
				if (flag) {
					tempData[index]['isNew'] = false;
					resetFields();
				}
			}
		}
	};
	const putPostApi = (url, dataObject, method) => {
		putpost(
			url,
			(data) => {
				successToast(data.message);
				fetchData(
					concessionServiceBaseUrl + '/buAndCourseSecurity/subSubCategory/' + subsubCategoryId,
					setSetupBuData
				);
				setIsSaveVisible(false);
				return true;
			},
			(data) => {
				failureToast(data.message);
				return false;
			},
			dataObject,
			method
		);
	};
	const cancelRow = (index) => {
		// let tempData = isSearch ? searchedData: setupBuData;
		let tempData = setupBuData;
		if (tempData[index]['isEdit']) tempData[index]['isEdit'] = false;
		else if (tempData[index]['isNew']) tempData.splice(index, 1);
		setSetupBuData(tempData);
		resetFields();
	};
	const resetFields = () => {
		setStatus('ACTIVE');
		setIsAllCourses(null);
		setSelectedTerm(null);
		setIsSaveVisible(false);
	};
	const TDROW = (props) => {
		const { data, index } = props;
		return (
			<tr>
				<td className="text-center p-2 mx-1">
					{getDispValForReactSelectByOneID(data.termId, 'DispValue', termData)}
				</td>
				<td className="text-center p-2 mx-1">
					{' '}
					<input
						style={{ height: '20px', width: '20px' }}
						type="checkbox"
						readOnly={true}
						checked={data.allCourses ? data.allCourses : false}
					/>{' '}
				</td>
				<td className="text-center p-2 mx-1">
					<span style={{ textAlign: 'center' }}>
						<Button
							color={data.courseCount && !data.allCourses ? 'primary' : 'info'}
							size="sm"
							type="button"
							className={' mx-1' + (props.isEdit ? ' btn-dark' : null)}
							onClick={() => {
								if (data.allCourses)
									successToast('All Courses are already added.');
								else {
									history.push(
										`/admin/scholarship/subCategory/subSubCategory/courseSecurity/addCourse/${data['id']}`
									);
								}
							}}
						>
							Add Courses
						</Button>
					</span>
				</td>
				<td className="text-center p-2 mx-1">
					{data.allCourses ? null : data.courseCount}
				</td>
				<td className="text-center p-2 mx-1">
					{data.status === 'ACTIVE' ? (
						<Button color="success" size={'sm'} disabled={true}>
							Active
						</Button>
					) : (
						<Button color="danger" size={'sm'} disabled={true}>
							Inactive
						</Button>
					)}
				</td>
				<td className="text-center p-2 mx-1">
					{' '}
					{moment(data.updatedOn).format('DD-MM-YYYY')}{' '}
				</td>
				<td className="text-center p-2 mx-1">
					<Button
						color="info"
						size="sm"
						type="button"
						className={' mx-1'}
						onClick={() => {
							editRow(index);
						}}
					>
						<i className="fas fa-edit" />
					</Button>
					<Button
						color="info"
						size="sm"
						type="button"
						className={' mx-1'}
						onClick={() => deleteRow(index)}
					>
						<i className="fas fa-trash" />
					</Button>
				</td>
			</tr>
		);
	};
	const NewOrEditRow = (props) => {
		const { data, isSaveVisible, index, termData } = props;
		return (
			<tr key={data['id'] + '-class'}>
				<td className="text-center p-2 mx-1">
					<Select2
						defaultValue={selectedTerm}
						data={termData}
						disabled={data['isEdit']}
						onChange={(e) => {
							setSelectedTerm(e.target.value);
						}}
					/>
				</td>
				<td className="text-center p-2 mx-1">
					<input
						disabled={data['isEdit']}
						style={{ height: '20px', width: '20px' }}
						type="checkbox"
						checked={isAllCourses}
						onChange={(e) => setIsAllCourses(!isAllCourses)}
					/>
				</td>
				<td className="text-center p-2 mx-1">
					<Button
						size="sm"
						type="button"
						className={' mx-1' + (isSaveVisible ? ' btn-dark' : null)}
						disabled={isSaveVisible}
					>
						Add Courses
					</Button>
				</td>
				<td className="text-center p-2 mx-1">
					{' '}
					{isAllCourses ? '' : data['courseCount']}
				</td>
				<td className="text-center p-2 mx-1">
					<label className="custom-toggle mx-auto ml-2">
						<input
							checked={status === 'ACTIVE'}
							type="checkbox"
							id={'status'}
							onChange={(e) => {
								let n = 'ACTIVE';
								if (!e.target.checked) n = 'INACTIVE';
								setStatus(n);
							}}
						/>
						<span
							style={{ width: '72px' }}
							className="custom-toggle-slider rounded-circle activeToggle"
							data-label-off="Inactive"
							data-label-on="Active"
						/>
					</label>
				</td>
				<td className="text-center p-2 mx-1">
					<Input
						maxLength={50}
						value={moment(data['updatedOn']).format('DD-MM-YYYY')}
						className="customInput"
						disabled
					/>
				</td>
				<td className="text-center p-2 mx-1">
					<Button
						color="info"
						size="sm"
						type="button"
						className="mx-1"
						onClick={() => saveRow(index)}
					>
						Save
					</Button>
					<Button
						color="danger"
						size="sm"
						type="button"
						onClick={() => {
							cancelRow(index);
						}}
					>
						<i className="fas fa-times" />
					</Button>
				</td>
			</tr>
		);
	};
	useEffect(() => {
		// if(isSearch ){
		//     let fields = {text:true};
		//     searchInTable(searchedValue,fields,setupBuData,termData,searchedData,setSearchedData,isSearch,setIsSearch)
		// }
		fetchAllData(
			concessionServiceBaseUrl + '/addSubSubCategory/' + subsubCategoryId,
			setSubSubCategoryData
		);
	}, [setupBuData]);
	return (
		<Card
			key={'SetupBUTable'}
			style={{ marginBottom: '12px', marginLeft: '20px', marginRight: '20px' }}
		>
			<CardBody style={{ paddingBottom: 0 }}>
				<div
					className="floatRight "
					style={{
						display: 'flex',
						alignItems: 'center',
						marginBottom: '10px',
					}}
				>
					Search :{' '}
					<Input
						className={'tdInput ml-3 mr-3 customInputWidth floatRight'}
						style={{ display: 'inline' }}
						placeholder={'Search'}
						// onChange={(e)=>{
						//     setIsSearch(true);
						//     setSearchedValue(e.target.value);
						//     let fields = {text:true};
						//     searchInTable(e.target.value,fields,setupBuData,termData,searchedData,setSearchedData,isSearch,setIsSearch)
						// }}
					/>
					<Button
						disabled={props.isSaveVisible}
						color="info"
						size="sm"
						type="button"
						className={
							'floatRight mx-1' + (props.isSaveVisible ? ' btn-dark' : null)
						}
						// onClick={()=>{if(isSearch){ failureToast('Please delete search field'); }
						// else{ newRow();} }}>
						onClick={newRow}
					>
						Add Term
					</Button>
				</div>
			</CardBody>
			<div id="questionTable" className="table-responsive">
				<Table className="align-items-center table-flush ">
					<SetupBuAndCourseSecuiryHeader />
					<tbody>
						{
							// isSearch ?
							//    searchedData && searchedData.map((data,index)=>
							//         (data['isEdit'] || data['isNew'] )
							//             ?
							//             <NewOrEditRow key={'NewOrEditRow'+index} data={data} isSaveVisible={true} termData={termData} index={index}/>
							//             :
							//             <TDROW key={'TDROW'+index} data={data} index={index}/>
							//     )
							//     :
							setupBuData.map((data, index) =>
								data['isEdit'] || data['isNew'] ? (
									<NewOrEditRow
										key={'NewOrEditRow' + index}
										data={data}
										isSaveVisible={true}
										termData={termData}
										index={index}
									/>
								) : (
									<TDROW key={'TDROW' + index} data={data} index={index} />
								)
							)
						}
					</tbody>
				</Table>
			</div>
		</Card>
	);
};
export default SetupBuAndCourseTable;
