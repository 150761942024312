import {getAPI} from "../../../../services/http";
import {failureToast} from "../../../../views/pages/manage/common/utils/methods/toasterFunctions/function";
import {endpoint} from "../../../../views/pages/manage/common/constant";
// }
const getFormattedProcessType =(data =[])=>{
    if(data && data.length){
        let formattedArray = [];
        data.forEach((item)=>{
            let obj = {
                label: item.tagTypeDispValue,
                value: item.id,
                ...item,
            }
            formattedArray.push(obj);
        });
        return formattedArray;
    }
}


const getProcessType = async ()=>{
    let url = endpoint.process_Tag.getTemplatesProcessTypes
    return new Promise(async(resolve,reject)=>{
        let result = {
            error : false,
            data : []
        };
        await getAPI(
            url,
            (data) => {
                result['data'] = getFormattedProcessType(data.data);
                resolve(result);
            },
            (data) => {
                reject({error:true,message:data?.message})
                failureToast(data["message"]);
            }
        );
    })
}

export default getProcessType