import React from 'react';
import Select from "react-select2-wrapper";

const ReactSelect2 = (props) => {
  const {item,index,tableData} = props;
  return (
    <Select data={item.dropdownValue}/>
  )
}

export default ReactSelect2;
