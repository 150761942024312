import { documentServiceEndpoints } from './documentService';
import { masterServiceEndpoints } from './masterServices';
import { pricingServiceEndpoints } from './pricingService';
import { enrollmentServiceEndpoints } from './enrollmentService';
import { examServiceEndpoints } from './examService';
import { knowYourRoleServiceEndpoints } from './knowYourRoleService';
import { offlineAttendanceEndpoints } from './offlineAttendanceSetup';
import { authServiceEndpoints } from './auth';
import { batchServiceEndpoints } from './batchServices';
import { consessionServiceEndpoints } from './consessionService';
import { testScheduleServiceEndpoints } from './testScheduleService';
import { messageServiceEndpoints } from './messageTemplate';
import { timetableServiceEndpoints } from './timetableService';
import { reportServiceEndpoints } from './reportService';
import { baseUrl } from 'services/http';
import { nodeService } from './nodeService';
import { attendanceServiceEndpoints } from './attendanceServices';

export const endpoint = {
	baseUrl,
	...documentServiceEndpoints,
	...masterServiceEndpoints,
	...pricingServiceEndpoints,
	...enrollmentServiceEndpoints,
	...examServiceEndpoints,
	...knowYourRoleServiceEndpoints,
	...offlineAttendanceEndpoints,
	...authServiceEndpoints,
	...batchServiceEndpoints,
	...consessionServiceEndpoints,
	...testScheduleServiceEndpoints,
	...messageServiceEndpoints,
	...timetableServiceEndpoints,
	...reportServiceEndpoints,
	...nodeService,
	...attendanceServiceEndpoints,
};
