import React, { useState, useEffect } from 'react';
import { SUBSUBCATEGORYTHROW } from '../../../../common/commonComponents/masterRow/masterRow';
import {
	Container,
	Row,
	Col,
	Button,
	Card,
	CardBody,
	CardHeader,
	Table,
	Input,
	Label,
} from 'reactstrap';
import Select2 from 'react-select2-wrapper';
import { GetPagination } from '../../../../common/pagination';
import CustomLoader from '../../../../common/commonComponents/Loader/loader';
import CustomDatePicker from '../../../../common/commonComponents/CustomDatePicker/index';

import moment from 'moment';
import {
	MasterHeaderAuto,
	Rows,
} from '../../../../common/commonComponents/masterHeader/masterHeader';
import {
	fetchAllData,
	fetchDetails,
	getDispValForReactSelectByOneID,
} from '../../../../common/utils/methods/commonMethods/utilityMethod';
import {
	getAPI,
	masterServiceBaseUrl,
	concessionServiceBaseUrl,
	successToast,
	failureToast,
	putpost,
	putpost2,
	deleteAPI,
} from 'services/http';

var _ = require('lodash');

const SubSubCategory = (props) => {
	const {
		getSubCategoryData,
		action,
		getDisplayValue,
		subCategoryID,
		subSubCategoryData,
		subSubCategory,
		setSubSubCategory,
	} = props;
	const [isSaveVisible, setIsSaveVisible] = useState(false);
	const [apiLoader, setApiLoader] = useState(false);
	const [state, setState] = useState([]);
	const [dataChange, setDataChange] = useState(false);
	const [subSubCategoryShortDesc, setSubSubCategoryShortDesc] = useState('');
	const [tableDataKey, settableDataKey] = useState('tableDataKey');
	const [isEdit, setIsEdit] = useState(false);
	const [pagination, setPagination] = useState({
		currentPage: 1,
		nextPage: null,
		previousPage: null,
		totalPage: null,
	});
	const pageSize = 10;

	useEffect(() => {
		fetchClasses(
			concessionServiceBaseUrl + `/addSubSubCategory/subCategoryId/${subCategoryID}`
		);
	}, [subCategoryID]);

	const fetchClasses = async (url) => {
		setApiLoader(true);
		getAPI(
			url,
			(data) => {
				setState(data['data']);
				let n = pagination;
				if (data['data'].length > pageSize) {
					n['nextPage'] = n.currentPage + 1;
				}
				if (n.currentPage > 1) {
					n.previousPage = n.currentPage - 1;
				}
				n['totalPage'] = Math.floor(data['data'].length / pageSize);
				if (data['data'].length % pageSize != 0) {
					n['totalPage'] += 1;
				}
				setPagination(n);
				setApiLoader(false);
				setSubSubCategory(
					data['data'].slice(
						pagination.currentPage * pageSize - pageSize,
						pagination.currentPage * pageSize
					) || []
				);
			},
			(data) => {
				failureToast(data['message']);
				setApiLoader(false);
			}
		);
	};

	const nextPage = () => {
		setSubSubCategory(
			state.slice(
				(pagination.currentPage + 1) * pageSize - pageSize,
				(pagination.currentPage + 1) * pageSize
			)
		);
		let n = pagination;
		n['currentPage'] = n['currentPage'] + 1;
		n['previousPage'] = n.currentPage;
		setPagination(n);
	};
	const firstPage = () => {
		setSubSubCategory(state.slice(1 * pageSize - pageSize, 1 * pageSize));
		let n = pagination;
		n['currentPage'] = 1;
		n['previousPage'] = null;
		setPagination(n);
	};
	const lastPage = () => {
		setSubSubCategory(
			state.slice(
				pagination.totalPage * pageSize - pageSize,
				pagination.totalPage * pageSize
			)
		);
		let n = pagination;
		n['currentPage'] = n.totalPage;
		n['previousPage'] = n.totalPage - 1;
		setPagination(n);
	};
	const previousPage = () => {
		if (pagination.currentPage != 1) {
			setSubSubCategory(
				state.slice(
					(pagination.currentPage - 1) * pageSize - pageSize,
					(pagination.currentPage - 1) * pageSize
				)
			);
			let n = pagination;
			n['currentPage'] = n['currentPage'] - 1;
			if (n['currentPage'] == 1) {
				n['previousPage'] = null;
			} else {
				n['previousPage'] = n.currentPage;
			}
			setPagination(n);
		}
	};

	const headersList = [
		{ name: 'Sub Sub Category Key & Description' },
		{ name: 'Short Description' },
		{ name: 'Setup Course Security' },
		{ name: 'Update date' },
		{ name: 'Status' },
		{ name: 'Action' },
	];

	const addNewClass = () => {
		setSubSubCategory([
			{
				editMode: true,
				__status: 'new',
				subCategoryId: subCategoryID,
				subSubCategoryMasterId: null,
				effectiveDate: null,
				status: 'ACTIVE',
			},
			...subSubCategory,
		]);
	};

	const saveAll = (cb) => {
		try {
			var _tempArray = subSubCategory;
			var isValid = true;
			_tempArray.forEach((el, index) => {
				if (el['editMode']) {
					if (el['subCategoryId'] === null) {
						isValid = false;
						failureToast('Sub Category ID not found');
						return;
					} else if (el['subSubCategoryMasterId'] === null) {
						isValid = false;
						failureToast('Please Select Sub Sub Category Key');
						return;
					}

					if (!isValid) {
						return;
					}

					if (_tempArray[index]['__status'] == 'new') {
						setApiLoader(true);
						putpost(
							concessionServiceBaseUrl + '/addSubSubCategory',
							(data) => {
								successToast(data['message']);
								settableDataKey(new Date().getMilliseconds());
								getSubCategoryData();
								fetchClasses(
									concessionServiceBaseUrl + `/addSubSubCategory/subCategoryId/${subCategoryID}`
								);
								setIsSaveVisible(false);
								setSubSubCategoryShortDesc('');
								setApiLoader(false);
							},
							(data) => {
								failureToast(data['message']);
								setApiLoader(false);
							},
							_tempArray[index],
							'post'
						);

					} else {
						setApiLoader(true);
						putpost(
							concessionServiceBaseUrl + '/addSubSubCategory/' + _tempArray[index].id,
							(data) => {
								successToast(data['message']);
								getSubCategoryData();
								fetchClasses(
									concessionServiceBaseUrl + `/addSubSubCategory/subCategoryId/${subCategoryID}`
								);
								setIsSaveVisible(false);
								setSubSubCategoryShortDesc('');
								delete _tempArray[index]['editMode'];
								setSubSubCategory(_tempArray);
								settableDataKey(new Date().getMilliseconds());
								setApiLoader(false);
							},
							(data) => {
								failureToast(data['message']);
								setApiLoader(false);
							},
							_tempArray[index],
							'put'
						);
					}
				}
			});
		} catch (e) {
			console.log('Error', e);
		}
	};

	const newRow = () => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			setIsEdit(false);
			let cb = () => {
				addNewClass();
				setIsSaveVisible(true);
			};
			var _tempArray = subSubCategory;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			setSubSubCategory(_tempArray);
			setIsSaveVisible(false);
			cb();
		}
	};

	const updateRecord = (index, value, property) => {
		try {
			let _tempArray = subSubCategory;
			_tempArray[index][property] = value;
			if (property === 'subSubCategoryMasterId') {
				setSubSubCategoryShortDesc(
					getDispValForReactSelectByOneID(
						value,
						'DescValue',
						subSubCategoryData
					)
				);
			}
			setSubSubCategory(_tempArray);
		} catch (e) {
			console.log('Error', e);
		}
	};

	const editRecord = (index) => {
		try {
			if (isSaveVisible) {
				failureToast('Please Delete or Save current changes');
			} else {
				setIsEdit(true);
				let cb = () => {
					var _tempArray = subSubCategory;
					_tempArray[index]['editMode'] = true;
					setSubSubCategoryShortDesc(
						getDispValForReactSelectByOneID(
							_tempArray[index]['subSubCategoryMasterId'],
							'DescValue',
							subSubCategoryData
						)
					);
					setSubSubCategory(_tempArray);
					setIsSaveVisible(true);
				};
				var _tempArray = subSubCategory;
				_tempArray = _.sortBy(_tempArray, [
					function (o) {
						return o.value;
					},
				]);
				cb();
			}
		} catch (e) {
			console.log('Error : ', e);
		}
	};

	const deleteRecord = (index) => {
		try {
			if (subSubCategory[index]['__status'] == 'new') {
				let n = subSubCategory;
				n.splice(index, 1);
				setSubSubCategory(n);
				setIsSaveVisible(false);
				settableDataKey(new Date().getMilliseconds());
			} else {
				if (
					window.confirm(
						'Are you sure you want to delete this Sub Category details ?'
					)
				) {
					putpost2(
						concessionServiceBaseUrl + '/addSubSubCategory/' + subSubCategory[index].id,
						(data) => {
							successToast(data['message']);
							getSubCategoryData();
							if (subSubCategory.length == 1) {
								previousPage();
								fetchClasses(
									concessionServiceBaseUrl + `/addSubSubCategory/subCategoryId/${subCategoryID}`
								);
							} else {
								var _tempArray = subSubCategory;
								if (_tempArray[index]['editMode']) {
									setIsSaveVisible(false);
								}
								_tempArray.splice(index, 1);
								setSubSubCategory(_tempArray);
							}
							settableDataKey(new Date().getMilliseconds());
						},
						(data) => {
							failureToast(data['message']);
						},
						'Delete'
					);
				}
			}
		} catch (e) {
			console.log('Error', e);
		}
	};

	const closeRecord = (index) => {
		try {
			let n = subSubCategory;
			if (n[index]['__status'] == 'new') {
				n.splice(index, 1);
			} else {
				// n[index]['editMode']=false
				fetchClasses(
					concessionServiceBaseUrl + `/addSubSubCategory/subCategoryId/${subCategoryID}`
				);
			}
			setSubSubCategory(n);
			setSubSubCategoryShortDesc('');
			setIsSaveVisible(false);
		} catch (e) {
			console.log('Error', e);
		}
	};

	return (
		<Container className="mt-3" fluid>
			<Row>
				<Col className="mb-3">
					<div
						className="floatRight d-flex flex-row justify-content-between align-items-center"
						style={{ width: '35%' }}
					>
						<h3 className="text-muted">Search :</h3>
						<Input className="tdInput" style={{ width: '40%' }} />

						{!(action === 'view') ? (
							<Button
								disabled={isSaveVisible}
								color="info"
								size="sm"
								type="button"
								className={
									'floatRight mx-1' + (isSaveVisible ? ' btn-dark' : null)
								}
								onClick={() => {
									newRow();
								}}
							>
								Add Sub Sub Category
							</Button>
						) : null}
					</div>
				</Col>
			</Row>
			{apiLoader ? (
				<div className="mx-auto text-center" style={{ height: '100px' }}>
					<CustomLoader
						apiLoader={apiLoader}
						loaderHeight={'200px'}
						loaderWidth={'100% '}
					/>
				</div>
			) : (
				<div id="questionTable" className="table-responsive">
					<Table className="align-items-center table-flush">
						{subSubCategory.length > 0 ? (
							<MasterHeaderAuto action={action} headerList={headersList} />
						) : (
							''
						)}
						<tbody className="list" key={tableDataKey}>
							{subSubCategory
								? subSubCategory.map((item, index) =>
										!item['editMode'] ? (
											<SUBSUBCATEGORYTHROW
												key={index + '_class'}
												type={'subSubCategoryMapping'}
												action={action}
												deleteRecord={deleteRecord}
												subSubCategoryData={subSubCategoryData}
												item={item}
												editRecord={editRecord}
												index={index}
												closeRecord={closeRecord}
											/>
										) : (
											<tr key={index + '-class'}>
												<td className="text-center p-2 mx-1">
													<Select2
														data={subSubCategoryData}
														className="tdSelect"
														disabled={isEdit}
														options={{ placeholder: 'Select...' }}
														value={item.subSubCategoryMasterId}
														onChange={(e) => {
															let n = e.target.value;
															updateRecord(index, n, 'subSubCategoryMasterId');
														}}
													/>
												</td>
												<td className="text-center p-2 mx-1">
													<Label className="customLabel">
														{subSubCategoryShortDesc}
													</Label>
												</td>
												<td className="text-center p-2 mx-1">
													<Button
														disabled={isSaveVisible}
														color="info"
														size="sm"
														type="button"
														className={
															'' + (isSaveVisible ? ' btn-dark' : null)
														}
													>
														Setup Course Security
													</Button>
												</td>
												<td className="text-center p-2 mx-1">
													<CustomDatePicker 
														disabled={true}
														value={ 
															subSubCategory && 
															subSubCategory.effectiveDate ? moment(subSubCategory.effectiveDate).toDate() : ''
														}
														// value={
														// item["__status"] == "__new"
														// 	? null
														// 	: moment(item["effectiveDate"]).toDate()
														// }
														placeholder="Example:01-01-2020"
													/>
													{/* <Input
														disabled={true}
														defaultValue={
															item['__status'] == 'new'
																? null
																: moment(item['effectiveDate']).format(
																		'DD-MM-YYYY'
																  )
														}
														placeholder="Example:01-01-2020"
														className="tdInput"
													/> */}
												</td>
												<td className="text-center p-2 mx-1">
													<Label className="custom-toggle mx-auto ml-2">
														<Input
															checked={item.status == 'ACTIVE' ? true : false}
															type="checkbox"
															onChange={(e) => {
																settableDataKey(new Date().getMilliseconds());
																let n = 'ACTIVE';
																if (!e.target.checked) {
																	n = 'INACTIVE';
																}
																updateRecord(index, n, 'status');
																setDataChange(!dataChange);
															}}
														/>
														<span
															style={{ width: '72px' }}
															className="custom-toggle-slider rounded-circle activeToggle"
															data-label-off="Inactive"
															data-label-on="Active"
														/>
													</Label>
												</td>
												<td className="text-center p-2 mx-1">
													{isSaveVisible ? (
														<>
															<Button
																size="sm"
																color="info"
																onClick={() => saveAll()}
															>
																Save
															</Button>
															<Button
																size="sm"
																color="danger"
																onClick={() => closeRecord(index)}
															>
																<i className="fas fa-times" />
															</Button>
														</>
													) : (
														<Button
															color="info"
															size="sm"
															type="button"
															onClick={() => {
																deleteRecord(index);
															}}
														>
															<i className="fas fa-trash" />
														</Button>
													)}
												</td>
											</tr>
										)
								  )
								: null}
						</tbody>
					</Table>

					{pagination.totalPage > 1 ? (
						<GetPagination
							fetchClasses={fetchClasses}
							pagination={pagination}
							previousPage={previousPage}
							firstPage={firstPage}
							lastPage={lastPage}
							nextPage={nextPage}
							setpagination={setPagination}
						/>
					) : null}
				</div>
			)}
		</Container>
	);
};

export default SubSubCategory;
