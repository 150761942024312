import React from 'react'
import AInput from '../../../common/formFeilds/customInput/AInput';
import { labelStyle } from './LocationDetailsForm';
import { selectStyles } from './LocationDetailsForm';
import { Row, Col } from 'reactstrap';

const GrievanceInfoForm = ({level , formObj, changeHandler , isFormDisabled ,inValidDataInfo }) => {
    
    const { field: inValidField, level: inValidLevel } = inValidDataInfo;

    return (
        <div className='mt-3'>
            <hr/>
           <h4 className='mb-3 mt-2'>{level} Grievance</h4>
            <Row>
                <Col md="6">
                    <AInput
                        label="Role name*"
                        style={selectStyles.control}
                        labelStyle={labelStyle}
                        placeholder="Enter Role ID"
                        handler={val => changeHandler(level, val ,'roleName')}
                        value={formObj?.roleName}
                        disabled={isFormDisabled}
                        maxLength={50}
                    />
                </Col>
                <Col md="6">
                    <AInput
                        label="Contact Number"
                        labelStyle={labelStyle}
                        style={{
                            ...selectStyles.control,
                            borderColor: '#CFD3D4',
                            ...(inValidLevel === level && inValidField === 'contactNumber') && ({ borderColor: '#B32306' })
                        }}
                        placeholder="Enter mobile number"
                        handler={val => changeHandler(level , val , 'contactNumber')}
                        value={formObj?.contactNumber}
                        disabled={isFormDisabled}
                        maxLength={10}
                        regex={new RegExp(/^[0-9]*$/)}
                    />
                </Col>
            </Row>

            <Row>
            <Col md="6">
                    <AInput
                        label="Email ID"
                        labelStyle={labelStyle}
                        style={{
                            ...selectStyles.control,
                            borderColor: '#CFD3D4',
                            ...(inValidLevel === level && inValidField === 'emailId') && ({ borderColor: '#B32306' })
                        }}
                        placeholder="Enter email id"
                        handler={val => changeHandler(level, val, 'emailId')}
                        value={formObj?.emailId}
                        disabled={isFormDisabled}
                        maxLength={50}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default GrievanceInfoForm;