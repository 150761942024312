import React, { Fragment, useState, useEffect } from 'react';
import {
	Card,
	CardHeader,
	CardBody,
	Button,
	Table,
	Input,
	Label,
} from 'reactstrap';
import { GetPagination } from '../../../../../common/commonComponents/pagination/pagination';
import CustomLoader from '../../../../../common/commonComponents/Loader/loader';
import moment from 'moment';
import { MasterHeaderAuto } from '../../../../../common/commonComponents/masterHeader/masterHeader';
import TableList from 'views/pages/manage/common/tableList';
import { ToExcel } from 'views/pages/manage/common/commonComponents';
import {
	successToast,
	failureToast,
	putpost,
	putpost2,
	getAPI,
	deleteAPI,
} from 'services/http';
import CommonInput from 'views/pages/manage/common/formFeilds/input/commonInput';
import { endpoint } from 'views/pages/manage/common/constant';
import { fetchAllData } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { Accordion } from 'views/pages/manage/common/commonComponents';
import AddProject from '../AddPeoject';

var _ = require('lodash');

const UserRoleTable = (props) => {
	const { action } = props;

	const [isSaveVisible, setIsSaveVisible] = useState(false);
	const [apiLoader, setApiLoader] = useState(false);
	const [isDisabledField, setIsDisabledField] = useState(false);
	const [state, setState] = useState([]);
	const [classArray, setClassArray] = useState([]);
	const [serverData, setServerData] = useState([]);
	const [dataChange, setDataChange] = useState(false);
	const [tableDataKey, settableDataKey] = useState('tableDataKey');
	const [isEdit, setIsEdit] = useState(false);
	const [isNew, setIsNew] = useState(false);

	const [isSelected, setIsSelected] = useState(false);
	const [projectID, setProjectID] = useState();

	const [isAddNewModelVisible, setIsAddNewModelVisible] = useState(false);
	const [allActiveRoleNameData, setAllActiveRoleNameData] = useState([]);
	const [allRoleNameData, setAllRoleNameData] = useState([]);
	const [roleNameData, setRoleNameData] = useState([]);
	const [rowNameID, setRowNameID] = useState('');
	const [roleName, setRoleName] = useState('');
	const [isViewOnly, setIsViewOnly] = useState(false);
	const [pagination, setPagination] = useState({
		currentPage: 1,
		nextPage: null,
		previousPage: null,
		totalPage: null,
	});

	const pageSize = 10;

	const listDetails = {
		roleName: {
			normal: true,
		},
		roleDescription: {
			normal: true,
		},
		defaultDelegationDays: {
			normal: true,
		},
		effectiveDate: {
			normal: true,
		},
	};

	const fetchClasses = async (url) => {
		setState(props.data);
		setServerData(props.data);
		setPaginationData(props.data);
	};

	useEffect(() => {
		fetchClasses();
		fetchAllData(endpoint.role_Name.getAllActive, setAllActiveRoleNameData);
		fetchAllRoleName(endpoint.role_Name.getAll, setAllRoleNameData);
	}, [props.data]);

	const fetchAllRoleName = async (url, stateMethod) => {
		setApiLoader(true);
		getAPI(
			url,
			(data) => {
				let tempValue = [];
				data.data.map((item) => {
					tempValue.push({
						id: item.id,
						text: item.roleName,
						desc: item.roleDescription,
					});
				});
				stateMethod(tempValue);
				setApiLoader(false);
			},
			(data) => {
				failureToast(data['message']);
				setApiLoader(false);
			}
		);
	};

	const setPaginationData = (fetchData) => {
		setApiLoader(true);
		let n = pagination;
		if (fetchData.length > pageSize) {
			n['nextPage'] = n.currentPage + 1;
		}
		if (n.currentPage > 1) {
			n.previousPage = n.currentPage - 1;
		}
		n['totalPage'] = Math.floor(fetchData.length / pageSize);
		if (fetchData.length % pageSize != 0) {
			n['totalPage'] += 1;
		}
		setPagination(n);
		setApiLoader(false);
		setClassArray(
			fetchData.slice(
				pagination.currentPage * pageSize - pageSize,
				pagination.currentPage * pageSize
			) || []
		);
	};

	const excelDetails = {
		'Role Name': 'roleName',
		'Role Description': 'roleDescription',
		'Default Delegation Days': 'defDelegationDays',
		Status: 'status',
		'Effective Date Area': 'effectiveDate',
	};

	const dataToExport = serverData?.map((item) => {
		return {
			...item,
			roleName: item.roleName,
			roleDescription: item.roleDescription,
			defDelegationDays: item.defaultDelegationDays,
			status: item.status,
			effectiveDate: item.effectiveDate,
		};
	});

	const headersList = [
		{ name: 'Role Name', isRequired: true },
		{ name: 'Role Description', isRequired: true },
		{ name: 'Default Delegation Days', isRequired: true },
		{ name: 'Effective Date' },
		{ name: 'Status' },
		{ name: 'Action' },
		{ name: 'Project' },
	];

	const addNewClass = () => {
		setClassArray([
			{
				editMode: true,
				__status: 'new',
				roleName: null,
				roleDescription: null,
				defaultDelegationDays: null,
				effectiveDate: null,
				status: 'ACTIVE',
			},
			...classArray,
		]);
	};

	const saveAll = (cb) => {
		let pattern_1 = /^[a-zA-z0-9_-\s]*$/;
		try {
			var _tempArray = classArray;
			var isValid = true;
			_tempArray.forEach((el, index) => {
				if (el['editMode']) {
					if (el['roleName'] === null) {
						isValid = false;
						failureToast('Please Enter Role Name');
						return;
					} else if (!pattern_1.test(el['roleName'])) {
						isValid = false;
						failureToast(
							'Role Name Contains Alpha-numeric with these special character _ , -  are only allowed.'
						);
						return;
					} else if (el['roleDescription'] === null) {
						isValid = false;
						failureToast('Please Enter Role Description');
						return;
					} else if (!pattern_1.test(el['roleDescription'])) {
						isValid = false;
						failureToast(
							'Role Description Contains Alpha-numeric with these special character _ , -  are only allowed.'
						);
						return;
					} else if (el['defaultDelegationDays'] === null) {
						isValid = false;
						failureToast('Please Enter Default Delegation Days');
						return;
					}

					if (!isValid) {
						return;
					}

					if (_tempArray[index]['__status'] === 'new') {
						setApiLoader(true);
						putpost(
							endpoint.role_Name.create,
							(data) => {
								successToast(data['message']);
								settableDataKey(new Date().getMilliseconds());
								setIsSaveVisible(false);
								props.searchRoleName();
								setIsDisabledField(false);
								setApiLoader(false);
							},
							(data) => {
								failureToast(data['message']);
								setApiLoader(false);
							},
							_tempArray[index],
							'post'
						);
					} else {
						setApiLoader(true);
						putpost(
							`${endpoint.role_Name.update}/
								${_tempArray[index].id}`,
							(data) => {
								successToast(data['message']);
								setIsSaveVisible(false);
								props.searchRoleName();
								delete _tempArray[index]['editMode'];
								setClassArray(_tempArray);
								settableDataKey(new Date().getMilliseconds());
								setApiLoader(false);
							},
							(data) => {
								failureToast(data['message']);
								setApiLoader(false);
							},
							_tempArray[index],
							'put'
						);
					}
				}
			});
		} catch (e) {
			console.log('Error', e);
		}
	};

	const newRow = () => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			setIsEdit(false);
			let cb = () => {
				addNewClass();
				setIsSaveVisible(true);
			};
			var _tempArray = classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			setClassArray(_tempArray);
			setIsSaveVisible(false);
			cb();
		}
	};

	const updateRecord = (index, value, property) => {
		try {
			let _tempArray = classArray;
			_tempArray[index][property] = value;
			setClassArray(_tempArray);
		} catch (e) {
			console.log('Error', e);
		}
	};

	const editRecord = (index) => {
		try {
			if (isSaveVisible) {
				failureToast('Please Delete or Save current changes');
			} else {
				setIsEdit(true);
				let cb = () => {
					var _tempArray = classArray;
					_tempArray[index]['editMode'] = true;
					setClassArray(_tempArray);
					setIsSaveVisible(true);
					setIsDisabledField(true);
				};
				var _tempArray = classArray;
				_tempArray = _.sortBy(_tempArray, [
					function (o) {
						return o.value;
					},
				]);
				cb();
			}
		} catch (e) {
			console.log('Error : ', e);
		}
	};

	const deleteRecord = (index) => {
		try {
			if (classArray[index]['__status'] === 'new') {
				let n = classArray;
				n.splice(index, 1);
				setClassArray(n);
				setIsSaveVisible(false);
				settableDataKey(new Date().getMilliseconds());
			} else {
				if (
					window.confirm(
						'Are you sure you want to delete this User Role details ?'
					)
				) {
					putpost2(
						`${endpoint.role_Name.update}/${classArray[index].id}`,
						(data) => {
							successToast(data['message']);
							if (classArray.length == 1) {
								props.searchRoleName();
							} else {
								var _tempArray = classArray;
								if (_tempArray[index]['editMode']) {
									setIsSaveVisible(false);
								}
								_tempArray.splice(index, 1);
								setClassArray(_tempArray);
							}
							settableDataKey(new Date().getMilliseconds());
						},
						(data) => {
							failureToast(data['message']);
						},
						'Delete'
					);
				}
			}
		} catch (e) {
			console.log('Error', e);
		}
	};

	const closeRecord = (index) => {
		try {
			let n = classArray;
			if (n[index]['__status'] === 'new') {
				n.splice(index, 1);
			} else {
				props.searchRoleName();
			}
			setClassArray(n);
			setIsSaveVisible(false);
			setIsDisabledField(false);
		} catch (e) {
			console.log('Error', e);
		}
	};
	// const addProject = (index) => {
	const addProject = () => {
		try {
			setApiLoader(true);
			// setIsSelected(true);
			// setIsNew( true );
			// dataValidations(_tempArray[index]['id']);

			// var _tempArray = classArray;
			// const value = _tempArray[index]['project'];
			// const value = true;
			// if (value === true) {
			// 	// setProjectID(1);
			// 	// setProjectID(_tempArray[index]['id']);
			// } else {
			// 	setIsNew(true);
			//}
			setApiLoader(false);
		} catch (error) {
			console.log('Error', error);
		}
	};

	const dataValidations = (id) => {
		try {
			setApiLoader(true);
			getAPI(
				`${endpoint.role_Merge.getRoleMergeByNewRoleID}/${id}`,
				(data) => {
					if (!data.data?.id) {
						setIsNew(true);
					} else if (data.data?.id) {
						setIsNew(false);
						setIsViewOnly(true);
					}
					setApiLoader(false);
				},
				(data) => {
					failureToast(data['message']);
					setApiLoader(false);
				}
			);
		} catch (error) {
			console.log('Error', error);
		}
	};

	return (
		<Fragment>
			<Card>
				<CardHeader className="d-flex justify-content-between align-items-center">
					<h3>Define User Roles Master</h3>
					<div className="d-flex flex-row  justify-content-between">
						{!isSaveVisible && serverData.length ? (
							<ToExcel
								data={dataToExport}
								detailObj={excelDetails}
								name="User Roles"
								isSaveVisible={isSaveVisible}
							/>
						) : null}
						<Button
							disabled={isSaveVisible}
							color="info"
							size="sm"
							type="button"
							className={
								'floatRight mx-1 ml-2' + (isSaveVisible ? ' btn-dark' : null)
							}
							onClick={() => {
								newRow();
								setIsSaveVisible(true);
							}}
						>
							Add New Role
						</Button>
					</div>
				</CardHeader>

				{apiLoader ? (
					<div className="mx-auto text-center" style={{ height: '100px' }}>
						<CustomLoader
							apiLoader={apiLoader}
							loaderHeight={'200px'}
							loaderWidth={'100% '}
						/>
					</div>
				) : (
					<div id="questionTable" className="table-responsive">
						<Table className="align-items-center table-flush">
							{classArray.length > 0 ? (
								<MasterHeaderAuto headerList={headersList} />
							) : (
								''
							)}
							<tbody className="list" key={tableDataKey}>
								{classArray
									? classArray.map((el, index) =>
											!el['editMode'] ? (
												<>
													<TableList item={el} listDetails={listDetails}>
														<td
															className="text-center  white-breakSpace"
															style={{ width: '200px' }}
														>
															<Button
																color="info"
																size="sm"
																type="button"
																disabled={false}
																onClick={() => editRecord(index)}
															>
																<i className="fas fa-edit" />
															</Button>
														</td>
														<td className="text-center p-2 mx-1">
															<Button
																disabled={isSaveVisible}
																color="info"
																size="sm"
																type="button"
																className={
																	'' + (isSaveVisible ? ' btn-dark' : null)
																}
																// onClick={() => {
																// 	addProject();
																// }}
															>
																Add Projects
															</Button>
														</td>
													</TableList>
												</>
											) : (
												<>
													<tr key={index + '-class'}>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="50"
																type="text"
																disabled={isDisabledField}
																defaultValue={el['roleName']}
																onChange={(e) => {
																	updateRecord(
																		index,
																		e.target.value,
																		'roleName'
																	);
																}}
																placeholder="Role Name"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="100"
																type="text"
																disabled={isDisabledField}
																defaultValue={el['roleDescription']}
																onChange={(e) => {
																	updateRecord(
																		index,
																		e.target.value,
																		'roleDescription'
																	);
																}}
																placeholder="Role Description"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="4"
																onKeyPress={(e) => {
																	if (
																		!/^[0-9.]+$/.test(
																			String.fromCharCode(e.charCode)
																		)
																	) {
																		e.preventDefault();
																		return;
																	}
																}}
																defaultValue={el['defaultDelegationDays']}
																onChange={(e) => {
																	updateRecord(
																		index,
																		e.target.value.trim(),
																		'defaultDelegationDays'
																	);
																}}
																placeholder="Default Delegation Days"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																disabled={true}
																defaultValue={
																	el['__status'] === 'new'
																		? null
																		: moment(el['effectiveDate']).format(
																				'DD-MM-YYYY'
																		  )
																}
																placeholder="Example:01-01-2020"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Label className="custom-toggle mx-auto ml-2">
																<Input
																	checked={el.status == 'ACTIVE' ? true : false}
																	type="checkbox"
																	onChange={(e) => {
																		settableDataKey(
																			new Date().getMilliseconds()
																		);
																		let n = 'ACTIVE';
																		if (!e.target.checked) {
																			n = 'INACTIVE';
																		}
																		updateRecord(index, n, 'status');
																		setDataChange(!dataChange);
																	}}
																/>
																<span
																	style={{ width: '72px' }}
																	className="custom-toggle-slider rounded-circle activeToggle"
																	data-label-off="Inactive"
																	data-label-on="Active"
																/>
															</Label>
														</td>
														<td className="text-center p-2 mx-1">
															{isSaveVisible ? (
																<>
																	<Button
																		size="sm"
																		color="info"
																		onClick={() => saveAll()}
																	>
																		Save
																	</Button>
																	<Button
																		size="sm"
																		color="danger"
																		onClick={() => closeRecord(index)}
																	>
																		<i className="fas fa-times" />
																	</Button>
																</>
															) : (
																<Button
																	color="info"
																	size="sm"
																	type="button"
																	onClick={() => {
																		deleteRecord(index);
																	}}
																>
																	<i className="fas fa-trash" />
																</Button>
															)}
														</td>
														<td className="text-center p-2 mx-1">
															<Button
																disabled={isSaveVisible}
																color="info"
																size="sm"
																type="button"
																className={
																	'' + (isSaveVisible ? ' btn-dark' : null)
																}
															>
																Add Projects
															</Button>
														</td>
													</tr>
													{isSelected ? (
														<tr>
															<td colSpan={8}>
																<CardBody>
																	<Accordion
																		removePadding={true}
																		title={'Add Projects'}
																		key={'Add Projects'}
																		isFormOpen={true}
																	>
																		<AddProject
																			isNew={isNew}
																			setIsNew={setIsNew}
																			isViewOnly={isViewOnly}
																			setIsViewOnly={setIsViewOnly}
																			// projectID={projectID}
																			setIsSelected={setIsSelected}
																		/>
																	</Accordion>
																</CardBody>
															</td>
														</tr>
													) : null}
												</>
											)
									  )
									: null}
							</tbody>
						</Table>

						{pagination.totalPage > 1 ? (
							<GetPagination
								isSaveVisible={isSaveVisible}
								setclassArray={setClassArray}
								state={state}
								pagination={pagination}
								setpagination={setPagination}
								pageSize={pageSize}
							/>
						) : null}
					</div>
				)}
			</Card>
		</Fragment>
	);
};

export default UserRoleTable;
