export const APPROVAL_STATUS = {
    NA: "Na",
    IN_PROGRESS: "In Progress",
    L1_APPROVED_L2_PENDING: "L1 Approved, Pending at L2",
    L2_APPROVED_L3_PENDING: "L2 Approved, Pending at L3",
    APPROVED: "Approved",
    REJECTED: "Rejected",
    RECHECK: "Recheck"
}

export const NEW_APPROVAL_STATUS = {
    NA: "Na",
    Pending: "In Progress",
    L1_PENDING: "",
    L1_APPROVED: "L1 Approved, Pending at L2",
    L1_REJECT:"Rejected",
    L1_RECHECK:"Recheck",
    L2_PENDING:"",
    L2_APPROVED:"L2 Approved, Pending at L3",
    L2_REJECT:"Rejected",
    L2_RECHECK:"Recheck",
    L3_PENDING:"",
    L3_APPROVED:"Approved",
    L3_REJECT:"Rejected",
    L3_RECHECK:"Recheck",
    REVOKE: "Revoked",
    MODIFY: "MODIFY"
}

export const STATUS_DATA = {
    Approved: {
        ACTION_TEXT: 'Approved',
        BCOLOR: '#BBE2CE',
        COLOR:'#0B442D',
        DISPLAY_TEXT:'Your concession has been approved',
        SVG_COLOR: '#07794C'
    },
    Rejected:{
        ACTION_TEXT: 'Rejected',
        BCOLOR: '#F9D9D7',
        COLOR:'#61140B',
        DISPLAY_TEXT:'The concession request has been rejected',
        SVG_COLOR: '#B32306'
    },
    Recheck:{
        ACTION_TEXT: 'Recheck',
        BCOLOR: '#FFE0A3',
        COLOR:'#705010',
        DISPLAY_TEXT:'The concession request has been requested for rechecking',
        SVG_COLOR: '#705010'
    },
    Pending:{
        ACTION_TEXT: '',
        BCOLOR: '#FFE0A3',
        COLOR:'',
        DISPLAY_TEXT:'',
        SVG_COLOR: '' 
    },
    "In Progress": {
        ACTION_TEXT: '',
        BCOLOR: '#FFE0A3',
        COLOR:'',
        DISPLAY_TEXT:'',
        SVG_COLOR: '' 
    },
    REVOKE: {
        BCOLOR: '#FFE0A3',
    },
    MODIFY: {
        BCOLOR: '#FFE0A3',
    }
};
