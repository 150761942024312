import React, { useState } from "react";
import { Dialog } from "@mui/material";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";
import { selectStyles } from "views/pages/manage/masters/holidayMaster/common";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import { onHoldReasonDropdown } from "views/pages/manage/edpOps/stockManagement/selectMeterialAndStock/constant";

const OnHoldReasonSelector = ({
  isOpen,
  setOpen,
  index='',
  onHoldHaldler=()=>{},
  student,
  btnHandler = () => {},
  isBulk=false,
  bulkIssueStockHandler=()=>{},
  isAlreadyMarkedEligible,
  isDisable=false
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  const [reason, setReason] = useState("Select");
  return (
    <Dialog onClose={handleClose} open={isOpen} maxWidth="lg">
      <div style={{ width: "649px", padding: "24px 28px" }}>
        <div className="d-flex align-items-center justify-content-between">
          <span className="large-text">
            Select a reason to hold stock issuance
          </span>
          <CrossIcon onClick={handleClose} />
        </div>
        <div style={{ marginTop: "24px", marginBottom: "24px" }}>
          <AAutoComplete
            currentValue={reason}
            handleChange={(value) => {
              setReason(value);
              onHoldHaldler(index, value?.value);
            }}
            items={onHoldReasonDropdown}
            isMulti={false}
            style={selectStyles}
            isDisabled={isDisable}
          />
        </div>

        <AButton
          onClick={() => {
           isBulk?bulkIssueStockHandler('onHold',reason?.value): btnHandler(student,true);
           handleClose();
          }}
          disabled={isDisable||!reason?.value}
          className="ml-0"
          variant="primary_filled"
        >
          {(isDisable&&!isAlreadyMarkedEligible)?'Already on hold' :"Confirm hold"}
        </AButton>
      </div>
    </Dialog>
  );
};
export default OnHoldReasonSelector;
