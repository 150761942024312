import React, {useState, useEffect, Fragment, useContext} from "react";
import {Row, Col, Container, Button, Card, CardHeader, CardBody} from 'reactstrap';
import {masterServiceBaseUrl, getAPI,} from 'services/http';
import { failureToast } from '../../../common/utils/methods/toasterFunctions/function';
import {useHistory} from "react-router-dom";
import {fetchAllData, fetchDetails} from "../../../common/utils/methods/commonMethods/utilityMethod";
import CourseCatalogSequenceSearchForm from "./form";
import CourseCatalogSequenceTable from "./courseCatalogSequenceTable";
import {PermissionContext, RolePermissions} from "../../../../../../appContext";
import {pages} from "../../../common/constant";
import {PermisionDenied} from "../../../common/commonComponents";
import CustomButton from "../../../../../../components/CustomButton";
const CourseCatelogSequenceSearch = (props) =>{
    let history = useHistory();
    const [ selectedGroupCode, setSelectedGroupCode ] = useState(null)
    const [ selectedCompanyCode, setSelectedCompanyCode ] = useState(null);
    const [ selectedAcademicCareer, setSelectedAcademicCareer ] = useState(null);
    const [ selectedStatus, setSelectedStatus ] = useState('ACTIVE');
    const [ groupCode, setGroupCode ] = useState();
    const [ companyCode, setCompanyCode ] = useState(null);
    const [ academicCareer, setAcademicCareer ] = useState(null);
    const [ companyCodeData, setCompanyCodeData ] = useState();
    const [ courseCatalogData, setCourseCatalogData ] = useState()
    const [data,setData] = useState();

    const permissions = useContext(PermissionContext);

    const userPermissions = RolePermissions(
        permissions,
        pages['courseCatalogSequence']['id']
    );

    const searchHandler = () =>{
        try{
            setCourseCatalogData([])
            if ( !selectedGroupCode ) return failureToast( "Please Select Business Group" );
            if ( !selectedCompanyCode ) return failureToast( "Please Select Company Code" );
            if ( !selectedAcademicCareer) return failureToast( "Please Select Academic Career" );
            else if(selectedGroupCode && selectedCompanyCode && selectedAcademicCareer && selectedStatus){fetchAllData(masterServiceBaseUrl+`/courseAttributeMapping/searchCourseIdSequenceSetupByStatus?groupCodeId=${selectedGroupCode}&companyCodeId=${selectedCompanyCode}&academicId=${selectedAcademicCareer}&status=${selectedStatus}`,setCourseCatalogData);}
        } catch ( error ) { console.log(error); }
    }
    // useEffect(() => {if(data){ let tempArray = []; tempArray.push(data); setCourseCatalogData(tempArray);}},[data])
    useEffect(() => {
        fetchDetails(masterServiceBaseUrl + '/groupCode/getAllActiveGroupCode', setGroupCode, 'groupCode', 'reactSelect');
        // fetchDetails(masterServiceBaseUrl + '/academicCareer/getAllActiveAcademicCareer', setAcademicCareer, 'academicCareer', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + '/companyCode/getAllActiveCompanyCode', setCompanyCodeData, 'companyCode', 'reactSelect');
    }, [] )
    return (
        <Fragment>
            {
                userPermissions ? (
                    <Container fluid={true} className="p-4">
                        <Card>
                            <CardHeader>
                                <h2 className="mb-0 floatLeft">Course Catalog Sequence</h2>
                                {/*<Button color="info" size="sm" className="floatRight mx-1"*/}
                                {/*        onClick={() => history.push(`/admin/CourseCatalogSequence/new`)}>*/}
                                {/*    Add New Sequence*/}
                                {/*</Button>*/}
                                <CustomButton
                                    className={'floatRight mx-1'}
                                    content={'Add New Sequence'}
                                    permissionType={'C'}
                                    icon={true}
                                    permissionSet={userPermissions}
                                    onClick={() => history.push(`/admin/CourseCatalogSequence/new`)}
                                />
                            </CardHeader>
                            <CardBody>
                                <CourseCatalogSequenceSearchForm
                                    setCourseCatalogData={setCourseCatalogData}
                                    groupCode={groupCode} companyCode={companyCode}
                                    academicCareer={academicCareer}
                                    setAcademicCareer={setAcademicCareer}
                                    setGroupCode={setGroupCode}
                                    setCompanyCode={setCompanyCode}
                                    selectedGroupCode={selectedGroupCode}
                                    setSelectedGroupCode={setSelectedGroupCode}
                                    selectedCompanyCode={selectedCompanyCode}
                                    setSelectedCompanyCode={setSelectedCompanyCode}
                                    selectedAcademicCareer={selectedAcademicCareer}
                                    setSelectedAcademicCareer={setSelectedAcademicCareer}
                                    selectedStatus={selectedStatus}
                                    setSelectedStatus={setSelectedStatus}
                                    searchHandler={searchHandler}
                                    permissionSet={userPermissions}
                                />
                            </CardBody>
                        </Card>
                        {
                            courseCatalogData && courseCatalogData.length > 0 ?
                                <Card>
                                    <CardHeader><h2 className="mb-0 floatLeft">Course Catalog Sequence</h2></CardHeader>
                                    <CourseCatalogSequenceTable
                                        courseCatalogSequenceData={courseCatalogData}
                                        groupCode={groupCode}
                                        companyCode={companyCodeData} academicCareer={academicCareer}
                                        setCourseCatalogData={setCourseCatalogData}
                                        permissionSet={userPermissions}/>
                                </Card>
                                :
                                null
                        }
                    </Container>
                ) : (
                    <PermisionDenied/>
                )}
        </Fragment>
    );
}
export default CourseCatelogSequenceSearch;