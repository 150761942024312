import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import './loader.scss';
import ErrorPage from "../../../../../../components/ErrorPage";
import {SomethingWentWrongDispatch} from "../../../../../../contexts/SomethingWentWrong";
import {useHistory} from "react-router-dom";

const CustomLoader = (props) => {
  let history = useHistory();
  const { apiLoader, loaderHeight, loaderWidth } = props;
  const [showMessage, setShowMessage] = useState(true);
  const setIsSomethingWentWrong = React.useContext(SomethingWentWrongDispatch);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (apiLoader) {
        setShowMessage(false);
      }
    }, 60000);
    return () => {
      clearTimeout(timeout);
    }
  }, []);
  useEffect(() => {
    if(!showMessage){
      setIsSomethingWentWrong(true);
    }
  },[showMessage])

  return (
    <div>
      {showMessage ? (
        <div
          className="align-center-loader"
          style={{ height: `${loaderHeight}`, width: `${loaderWidth}` }}
        >
          <Loader type="Rings" color="#00BFFF" height={70} width={70} />
        </div>
      ) : (
          // <ErrorPage/>
        // <div
        //   style={{ height: `${loaderHeight}`, width: `${loaderWidth}`,marginTop:'5%' }}
        //   className="pb-7 align-center-loader"
        // >
        //   <div className="align-center-loader loader-style" style={{padding:'11px'}}>
        //     <p className="mb-0 loader-message">Something went wrong.</p>
        //   </div>
        // </div>
          ''
      )}
    </div>
  );
};

export default CustomLoader;
