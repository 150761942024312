import { setCookie } from '../../../views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { get_request_params } from '../../properties/headers';
import { authServiceUrl } from 'services/http';
const validateToken = async () => {
  try {
    // if (document.URL.split('#')[1] !== '/auth') {
    //   setCookie('lastKnowPath', document.URL.split('#')[1]);
    // }

    const request_params = get_request_params('Post');
    const value = await fetch(`${authServiceUrl}/validatetoken`, request_params)
      .then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          response
            .json()
            .then(er => {
              return er;
            })
            .catch(error => {});
        }
      })
      .then(function (data) {
        return data;
      })
      .catch(error => {
        return error;
      });

    return value;
  } catch (e) {}
};

const getResponse = () => {};

export { validateToken };
