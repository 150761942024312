import React, {Fragment, useEffect, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import StudentDetailsForm from "./studentDetails/index";
import {fetchAllData, fetchDetails, getDispValForReactSelectByOneID} from "../../../common/utils/methods/commonMethods/utilityMethod";
import {
    masterServiceBaseUrl,
    enrollmentServiceBaseUrl,
    examControllerServiceBaseUrl,
    failureToast,
    getAPI,
    putpost,
    successToast
} from "../../../../../../services/http";
import CourseApplicationDetails from "./courseApplicationDetails";
import ExamDetailsForm from "./examDetails";
import AgentDetails from "./form/agentDetails";
import GstDetails from "./form/gstDetails";
import RelatedDocument from "./form/relatedDocument";
import LogDetailsApplicationId from "./form/logDetails";
import {CustomCollapse} from "../../../common/commonComponents/CustomCollapse";
import {fetchData,} from "./applicationIdFunctions";
import CustomLoader from "../../../common/commonComponents/Loader/loader";
import {useHistory} from "react-router-dom";
import Loader from "react-loader";
import styled, { css } from "styled-components";
import {useDispatch} from "react-redux";
import {updateData} from "../../../../../../redux";
import CustomModel from "../../../common/commonComponents/Model";
import ButtonWithLoader from "../../../common/commonComponents/buttonWithLoader";
import {label, placeHolder} from "./properties";

export const DarkBackground = styled.div`
  display: none;
  position: fixed;
  z-index: 999; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  ${props =>
    props.disappear &&
    css`
      display: inline; 
    `}
`;
const ApplicationIdGeneration =(props)=>{
    const dispatch = useDispatch();
    let history = useHistory();
    const {action,id,studentId=null}= props.match.params;
    const {label,placeHolder,headerTitle,message,button,header} = require('./properties/index.json');
    const [isCourseDetailOpen,setIsCourseDetailOpen] = useState(false);
    const [isExamDetailOpen,setIsExamDetailOpen] = useState(false);
    const [gridNumber,setGridNumber] = useState(null);
    const [opportunityType,setOpportunityType] = useState([]);
    const [businessGroup,setBusinessGroup] = useState([]);
    const [companyCode,setCompanyCode] = useState([]);
    const [term,setTerm] = useState([]);
    const [admissionType,setAdmissionType] = useState([]);
    const [gst,setGst] = useState([]);
    const [admissionAgent,setAdmissionAgent] = useState([]);
    const [saleType,setSaleType] = useState([]);
    const [studentIds,setStudentIds] = useState([]);
    const [examIds,setExamIds] = useState([]);
    const [businessArea,setBusinessArea] = useState([]);
    const [examDate,setExamDate] = useState([]);
    const [externalOrganization,setExternalOrganization] = useState([]);
    const [academicCareerData,setAcademicCareerData] = useState([]);
    const [academicGroupData,setAcademicGroupData] = useState([]);
    const [aiatsData,setAiatsData] = useState([]);
    const [applicationIdData,setApplicationIdData] = useState(null);
    const [opportunityTypeForView,setOpportunityTypeForView] = useState([])
    const [viewExamDetails,setViewExamDetails] = useState(true);
    const [viewCourseDetails,setViewCourseDetails] = useState(true);
    const [courseIds,setCourseIds] = useState([]);

    const triggerThis = () => {
        window.onbeforeunload = confirmExit;
        function confirmExit()
        {
            return "show message";
        }
    }

    // useEffect(() => {
    //     if(action !=='new'){
    //         fetchData(masterServiceBaseUrl + `/courseCatalogMapping/getAllCourseIdWithCRTYAndAttributeValueMAINAndADDC`,setCourseIds,'courseId')
    //     }
    // },[])

    const [studentDetails,setStudentDetails] = useState({
        studentId :'',
        status:'ACTIVE',
        studentName:'',
        businessGroup:'',
        companyCode:'',
        opportunityId:'',
        opportunityType:'',
        applicationId:'',
        // applicationDate:moment().format('DD/MM/YYYY')
    });

    const [courseApplicationDetails,setCourseApplicationDetails] = useState({
        businessArea:'',
        term:'',
        courseId:'',
        courseName:'',
        academicCareer:'',
        academicGroup:'',
        courseType:'',
        admissionTypeId:'',
        courseClass:'',
        rollNumber:'',
        saleTypeId:'',
        courseCategoryId:'',
        externalSchoolNameId:'',
        actionReason:'',
        aiatsCenterCode:'',
        programAction:'',
        organizationShortName:''
    });


    useEffect(() => {
        console.log('courseApplicationDetails------>',courseApplicationDetails);
    },[courseApplicationDetails]);
    const [examDetails,setExamDetails] = useState({
        businessArea:'',
        term:'',
        examDate:'',
        examCourseId:'',
        examId:'',
        examCenterBusinessArea:'',
        rollNumber:'',
        examCourseName:''
    });

    useEffect(() => {
        console.log('examDetails------>',examDetails);
    },[examDetails]);
    const [admissionAgentDetails,setAdmissionAgentDetails] = useState({
        admissionAgentType:'',
        admissionAgentId:'',
        admissionAgentName:'',
        applicationId:(action ==='new')? '' : id,
        status:'ACTIVE',
        id:null
    });

    const [gstDetails,setGstDetails] = useState({
        applicationId:id,
        admissionGSTCategory:'',
        admissionGSTIN:'',
        gstEffectiveDate:'',
        admissionGSTSponsorName:'',
        status:'ACTIVE',
        id:null
    });

    const UpdateFormData =(grid,key,value)=>{
        try {
            if(grid ===1){
                if(key === 'studentId'){
                    setStudentDetails(preState => ({...preState, [key]:value,'studentName':value.studentName}) );
                }else if(key === 'businessGroup'){
                    setStudentDetails(preState => ({...preState, [key]: value,companyCode: ''}));
                    setCourseApplicationDetails(preState => ({
                        ...preState,
                        businessArea: '',
                        externalSchoolNameId: '',
                        organizationShortName: ''
                    }));
                    setCompanyCode([]);
                    setBusinessArea([]);
                    if(action ==='new'){
                        // fetchDetails(masterServiceBaseUrl + `/companyCode/getCompanyCodeByGroup/` + value, setCompanyCode, 'companyCode', 'reactSelect');
                        fetchDetails(`${masterServiceBaseUrl}/companyCode/getCompanyCodeByGroup/${value}`, setCompanyCode, 'companyCode', 'reactSelect');
                    }
                }else if(key === 'companyCode'){
                    setStudentDetails(preState => ({...preState, [key]: value}));
                    setCourseApplicationDetails(preState => ({
                        ...preState,
                        businessArea: '',
                        externalSchoolNameId: '',
                        organizationShortName: ''
                    }));
                    setBusinessArea([]);
                    if(action ==='new'){
                        // fetchDetails(masterServiceBaseUrl + `/businessArea/getBusinessAreaByCompanyGroupCode?companyCode=${value}&groupCode=${formData['businessGroup']}`, setBusinessArea, 'businessArea', 'reactSelect');
                        fetchDetails(`${masterServiceBaseUrl}/businessArea/getBusinessAreaByCompanyGroupCode?companyCode=${value}&groupCode=${studentDetails['businessGroup']}`, setBusinessArea, 'businessArea', 'reactSelect');
                    }
                }
                else {
                    setStudentDetails(preState => ({...preState, [key]: value}));
                }
            } else if(grid ===2){
                if(key === 'businessArea'){
                    if(action === 'new'){
                        setCourseApplicationDetails(preState => ({
                            ...preState,
                            [key]: value,
                            externalSchoolNameId: '',
                            organizationShortName: ''
                        }));
                    }
                    setAiatsData([])
                    fetchData(masterServiceBaseUrl+'/externalOrganizationId/getExternalOrganizationByBusinessArea/'+value,setExternalOrganization,'externalOrganization')
                    fetchData(masterServiceBaseUrl+'/externalOrganizationId/getExternalOrganizationByOrgType?businessAreaId='+value,setAiatsData,'externalOrganization')
                    if(courseApplicationDetails['term'] !=='' && value && action ==='new') {
                        setCourseIds([])
                        fetchData(masterServiceBaseUrl + `/courseCatalogMapping/getAllCourseIdWithCRTYAndAttributeValueMAINAndADDC/${value}/${courseApplicationDetails.term}?isForExam=false`, setCourseIds, 'courseId')
                    }
                }else if(key === 'externalSchoolNameId'){
                    if(externalOrganization.length){
                        let organizationName = [];
                        organizationName = externalOrganization.filter((item)=>item.id === value);
                        if(organizationName.length){
                            setCourseApplicationDetails(preState => ({
                                ...preState,
                                [key]:value,
                                organizationShortName:organizationName[0]['organizationShortName']
                            }));
                        }else{
                            setCourseApplicationDetails(preState => ({...preState, [key]:value}));
                        }
                    }
                }else{
                    setCourseApplicationDetails(preState => ({...preState, [key]:value}));
                }
            }else if(grid ===3){
                setExamDetails(preState => ({...preState, [key]:value}));
            }else if(grid ===4){
                setAdmissionAgentDetails(preState => ({...preState, [key]:value}));
            }else if(grid ===5){
                setGstDetails(preState => ({...preState, [key]:value}));
            }
        } catch ( e ) {
            console.error("Error---->",e);
        }
    }

    const nextHandler =()=>{
        // console.log('Grid number------>',gridNumber)
        if(studentDetails['studentId'] === ''){
            failureToast(message.failure.studentId);
        }else if(studentDetails['businessGroup'] === ''){
            failureToast(message.failure.businessGroup);
        }else if(studentDetails['companyCode'] === ''){
            failureToast(message.failure.companyCode);
        }else if(studentDetails['opportunityId'] === ''){
            failureToast(message.failure.opportunityId);
        }else if(studentDetails['opportunityType'] === ''){
            failureToast(message.failure.opportunityType);
        }else{
            if(gridNumber === 1){
                // fetchData(masterServiceBaseUrl + `/courseCatalogMapping/getAllCourseIdWithCRTYAndAttributeValueMAINAndADDC?isForExam=false`,setCourseIds,'courseId')
                // fetchData(baseUrl2 + `/courseCatalogMapping/getAllCourseIdWithCRTYAndAttributeValueMAINAndADDC?isForExam=false`,setCourseIds,'courseId')
                setIsCourseDetailOpen(true);
                setIsExamDetailOpen(false);
            }else if(gridNumber === 2){
                fetchData(masterServiceBaseUrl + `/courseCatalogMapping/getAllCourseIdWithCRTYAndAttributeValueMAINAndADDC?isForExam=true`,setCourseIds,'courseId')
                setIsExamDetailOpen(true);
                setIsCourseDetailOpen(false);
            }else if(!gridNumber){
                failureToast(message.failure.selectExamOrCourse)
            }
        }
    }

    const [programAction,setProgramAction] = useState([]);
    useEffect(() => {
        if(action === 'new'){
            setApiLoader(false);
        }
        fetchDetails(masterServiceBaseUrl + `/programAction/getAllActiveProgramAction`, setProgramAction, 'programAction', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + `/academicCareer/getAllAcademicCareer`, setAcademicCareerData, 'academicCareer', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + `/academicGroup/getAllAcademicGroup`, setAcademicGroupData, 'academicGroup', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + `/groupCode/getAllActiveGroupCode`, setBusinessGroup, 'groupCode', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + `/opportunityType/getAllActiveOpportunityType`, setOpportunityType, 'opportunityType', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + `/opportunityType/getAllOpportunityType`, setOpportunityTypeForView, 'opportunityType', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + `/term/getAllActiveTerm`, setTerm, 'term', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + `/admissionType/getAllActiveAdmissionType`, setAdmissionType, 'admissionType', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + `/gstinCategory/getAllGstinCategory`, setGst, 'gstinCategory', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + `/admissionAgentType/getAllActiveAdmissionAgentType`, setAdmissionAgent, 'admissionAgentType', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + `/saleType/getAllActiveSaleType`, setSaleType, 'saleType', 'reactSelect');
        fetchData(enrollmentServiceBaseUrl+'/studentDetails/getAllStudent',setStudentIds,'student');
        fetchData(examControllerServiceBaseUrl+'/exam/getAllActiveExamId',setExamIds,'exam')
        // fetchDetails(masterServiceBaseUrl + `/businessArea/getAllActiveBusinessArea`, setBusinessArea, 'businessArea', 'reactSelect');
        // fetchData(masterServiceBaseUrl+'/externalOrganizationId/getAllExternalOrganizationId',setExternalOrganization,'externalOrganization')
        // fetchData(masterServiceBaseUrl+'/externalOrganizationId/getAllExternalOrganizationId',setAiatsData,'externalOrganization')
        // fetchData(masterServiceBaseUrl+'/externalOrganizationId/getExternalOrganizationByOrgType',setAiatsData,'externalOrganization')
        // fetchData(masterServiceBaseUrl + `/courseCatalogMapping/getAllCourseIdWithCRTYAndAttributeValueMAINAndADDC`,setCourseIds,'courseId')
        if(action !== 'new' && id!==''){
            fetchDetails(masterServiceBaseUrl + `/businessArea/getAllBusinessArea`, setBusinessArea, 'businessArea', 'reactSelect');
            fetchApplicationIdData(`${enrollmentServiceBaseUrl}/studentApplicationGeneration/getStudentApplicationDetails/${id}`).then(data=>{
                if(data?.data){
                    if(data.data?.applicationIdGeneration) {
                        setApplicationIdData(data.data.applicationIdGeneration);
                        setExamDetails(data.data.applicationIdGeneration);
                        let businessAreaId = data.data.applicationIdGeneration?.businessArea;
                        if(businessAreaId){
                            fetchData(masterServiceBaseUrl+'/externalOrganizationId/getExternalOrganizationByBusinessArea/'+businessAreaId,setExternalOrganization,'externalOrganization')
                            fetchData(masterServiceBaseUrl+'/externalOrganizationId/getExternalOrganizationByOrgType?businessAreaId='+businessAreaId,setAiatsData,'externalOrganization')
                        }
                    }
                    if(data.data?.admissionGSTDetails){
                        setGstDetails(data.data.admissionGSTDetails);
                    }
                    if(data.data?.admissionAgentDetails){
                        setAdmissionAgentDetails(data.data.admissionAgentDetails)
                    }
                }
            })
            // getAPI(
            //     enrollmentServiceBaseUrl+'/studentApplicationGeneration/getStudentApplicationDetails/' +id,
            //     (data) => {
            //         if(data.data){
            //            if(data.data?.applicationIdGeneration) {
            //                setApplicationIdData(data.data.applicationIdGeneration);
            //                setExamDetails(data.data.applicationIdGeneration);
            //            }
            //            if(data.data?.admissionGSTDetails){
            //                setGstDetails(data.data.admissionGSTDetails);
            //            }
            //            if(data.data?.admissionAgentDetails){
            //                setAdmissionAgentDetails(data.data.admissionAgentDetails)
            //            }
            //         }
            //     },
            //     (data) => {
            //         console.error('Error in get Application Data-------->',data.message);
            //     }
            // );
        }
    },[])

    const fetchApplicationIdData =async(url)=>{
        return new Promise((resolve,reject)=>{
            getAPI( url,
                (data) => {
                    resolve(data);
                },
                (data) => {
                    resolve(data);
                    console.error('Error in get Application Data-------->',data.message);
                }
            );
        });
    }

    useEffect(() => {
        if(action !=='new'){
            fetchData(masterServiceBaseUrl + '/externalOrganizationId/getAllExternalOrganizationId' ,setExternalOrganization,'externalOrganization')
            // fetchData(baseUrl2 + `/courseCatalogMapping/getAllCourseIdWithCRTYAndAttributeValueMAINAndADDC`,setCourseIds,'courseId')
            fetchDetails(masterServiceBaseUrl + `/companyCode/getAllCompanyCode`, setCompanyCode, 'companyCode', 'reactSelect');
            if(applicationIdData){
                setStudentDetails(applicationIdData);
                if(opportunityTypeForView){
                    opportunityTypeForView.map((item)=>{
                        if(studentIds && studentIds.length>0 && applicationIdData['studentId'] !== '') {
                            studentIds.map((item) => {
                                if (item.id === applicationIdData['studentId']) {
                                    // console.log('Item---------->',item);
                                    UpdateFormData(1, 'studentName', item.desc)
                                }
                            })
                        }
                        if(item.id ===applicationIdData.opportunityType && item.text ==='Exam'){
                            setViewCourseDetails(false);
                            // setExamDetails(applicationIdData);
                            setIsExamDetailOpen(true);
                            setApiLoader(false);
                        }else if(item.id ===applicationIdData.opportunityType && item.text ==='Course'){
                            setViewExamDetails(false);
                            setCourseApplicationDetails({
                                externalSchoolNameId:applicationIdData.externalOrgId,
                                programAction:'Applicant',
                                ...applicationIdData
                            })
                            setIsCourseDetailOpen(true)
                            setApiLoader(false)
                        }
                    })
                }
            }
        }
    },[applicationIdData,opportunityTypeForView,studentIds])

    const submitHandler =(name)=>{
        try {
            if (name === 'course') {
                if (courseApplicationDetails) {
                    if (courseApplicationDetails.businessArea === '') {
                        failureToast(message.failure.businessArea);
                    } else if (courseApplicationDetails.term === '') {
                        failureToast(message.failure.term);
                    } else if (courseApplicationDetails.courseId === '') {
                        failureToast(message.failure.courseId);
                    } else if (courseApplicationDetails.admissionTypeId === '') {
                        failureToast(message.failure.admissionType);
                    } else if (courseApplicationDetails.courseClass === '') {
                        failureToast(message.failure.class);
                    }else if (courseApplicationDetails.rollNumber === '') {
                        failureToast(message.failure.rollNumber);
                    } else if (courseApplicationDetails.aiatsCenterCode === '') {
                        failureToast(message.failure.aiatsCenterCode);
                    } else {
                        let data = {
                            "studentId": studentDetails && studentDetails.studentId.value ? studentDetails.studentId.value : studentDetails.studentId,
                            "businessGroup": studentDetails && studentDetails.businessGroup,
                            "companyCode": studentDetails && studentDetails.companyCode,
                            "opportunityId": studentDetails && studentDetails.opportunityId,
                            "opportunityType": studentDetails && studentDetails.opportunityType,
                            "businessArea": courseApplicationDetails && courseApplicationDetails.businessArea,
                            "term": courseApplicationDetails && courseApplicationDetails.term,
                            "courseId": courseApplicationDetails && courseApplicationDetails.courseId,
                            "academicCareer": courseApplicationDetails && courseApplicationDetails.academicCareer,
                            "academicGroup": courseApplicationDetails && courseApplicationDetails.academicGroup,
                            "courseType": courseApplicationDetails && courseApplicationDetails.courseType && courseApplicationDetails.courseType.id,
                            "admissionTypeId": courseApplicationDetails && courseApplicationDetails.admissionTypeId,
                            "courseClass": courseApplicationDetails && courseApplicationDetails.courseClass && courseApplicationDetails.courseClass.id,
                            "rollNumber": courseApplicationDetails && courseApplicationDetails.rollNumber,
                            "saleTypeId": courseApplicationDetails && courseApplicationDetails.saleTypeId,
                            "courseCategory": courseApplicationDetails && courseApplicationDetails.courseCategory && courseApplicationDetails.courseCategory.id,
                            "externalOrgId":courseApplicationDetails && courseApplicationDetails.externalSchoolNameId,
                            programAction: courseApplicationDetails && courseApplicationDetails.programAction,
                            "actionReason": null,
                            "aiatsCenterCode": courseApplicationDetails && courseApplicationDetails.aiatsCenterCode,
                            "status": "ACTIVE",
                        };
                        if(action ==='edit' || (action ==='view' && studentId)){
                            let flagForAdmissionAgent = false;
                            let flagForGst = false;
                            if (action === 'edit' || (action ==='view' && studentId)) {
                                if (admissionAgentDetails['admissionAgentType'] !== '') {
                                    if (admissionAgentDetails['admissionAgentId'] === '') {
                                        failureToast(message.failure.agentId);
                                        return;
                                    } else if (admissionAgentDetails['admissionAgentName'] === '') {
                                        failureToast(message.failure.agentName);
                                        return;
                                    } else {
                                        flagForAdmissionAgent = true;
                                    }
                                }
                                if (gstDetails['admissionGSTCategory'] !== '') {
                                    if (gstDetails['gstEffectiveDate'] === '') {
                                        failureToast(message.failure.gstEffectiveDate);
                                        return;
                                    } else if (gstDetails['admissionGSTSponsorName'] === '') {
                                        failureToast(message.failure.gstSponserName);
                                        return;
                                    } else if (gstDetails['admissionGSTIN'] === '') {
                                        failureToast(message.failure.gstIn);
                                        return;
                                    } else {
                                        flagForGst = true;
                                    }
                                }
                            }

                            let dataObject = {
                                admissionAgentDetailsDTO: null,
                                admissionGSTDetailsDTO: null,
                                applicationIdGenerationCourseDTO: data
                            }
                            let tempUrl ='/studentApplication/updateStudentApplicationByCourse/'+id;
                            let method = 'PUT'
                            let checkOptionalDetails = false;
                            // if only student details and gst details  update
                            if (flagForGst && !flagForAdmissionAgent && gstDetails.id) {
                                tempUrl = `/studentApplicationGeneration/updateStudentApplicationByCourseWithGSTAndAgent/${id}?gstId=${gstDetails.id}`;
                                dataObject = {
                                    admissionAgentDetailsDTO: null,
                                    admissionGSTDetailsDTO: gstDetails,
                                    applicationIdGenerationCourseDTO: data
                                }
                                checkOptionalDetails = true;

                            } else if (!flagForGst && flagForAdmissionAgent && admissionAgentDetails.id && admissionAgentDetails.id !== '') {
                                tempUrl = `/studentApplicationGeneration/updateStudentApplicationByCourseWithGSTAndAgent/${id}?agentId=${admissionAgentDetails.id}`;
                                dataObject = {
                                    admissionAgentDetailsDTO: admissionAgentDetails,
                                    admissionGSTDetailsDTO: null,
                                    applicationIdGenerationCourseDTO: data
                                }
                                checkOptionalDetails = true;
                            } else if (flagForGst && flagForAdmissionAgent && admissionAgentDetails.id && gstDetails.id) {
                                tempUrl = `/studentApplicationGeneration/updateStudentApplicationByCourseWithGSTAndAgent/${id}?gstId=${gstDetails.id}&agentId=${admissionAgentDetails.id}`
                                dataObject = {
                                    admissionAgentDetailsDTO: admissionAgentDetails,
                                    admissionGSTDetailsDTO: gstDetails,
                                    applicationIdGenerationCourseDTO: data
                                }
                                // post api to create admission and gst
                                checkOptionalDetails = true;
                            } else if (flagForAdmissionAgent && !admissionAgentDetails.id && !flagForGst && !gstDetails.id ) {
                                dataObject = {
                                    admissionAgentDetailsDTO: admissionAgentDetails,
                                    admissionGSTDetailsDTO: null,
                                    applicationIdGenerationCourseDTO: data
                                }
                                tempUrl = `/studentApplicationGeneration/createStudentApplicationByCourseWithGSTAndAgent/${id}`;
                                method = 'POST';
                                checkOptionalDetails = true;
                            } else if (flagForGst && !gstDetails.id && !flagForAdmissionAgent) {
                                dataObject = {
                                    admissionAgentDetailsDTO:null,
                                    admissionGSTDetailsDTO:gstDetails,
                                    applicationIdGenerationCourseDTO:data
                                }
                                tempUrl = `/studentApplicationGeneration/createStudentApplicationByCourseWithGSTAndAgent/${id}`;
                                method = 'POST';
                                checkOptionalDetails = true;
                            } else if(flagForAdmissionAgent && !admissionAgentDetails.id && flagForGst && !gstDetails.id){
                                dataObject = {
                                    admissionAgentDetailsDTO:admissionAgentDetails,
                                    admissionGSTDetailsDTO:gstDetails,
                                    applicationIdGenerationCourseDTO:data
                                }
                                tempUrl = `/studentApplicationGeneration/createStudentApplicationByCourseWithGSTAndAgent/${id}`;
                                method = 'POST';
                                checkOptionalDetails = true;

                            }
                            else if(flagForAdmissionAgent && !admissionAgentDetails.id && flagForGst && gstDetails.id){
                                dataObject = {
                                    admissionAgentDetailsDTO:admissionAgentDetails,
                                    admissionGSTDetailsDTO:gstDetails,
                                    applicationIdGenerationCourseDTO:data
                                }
                                tempUrl = `/studentApplicationGeneration/updateStudentApplicationByCourseWithGSTAndAgent/${id}?gstId=${gstDetails.id}`;
                                method = 'PUT';
                                checkOptionalDetails = true;}
                            else if (flagForGst && flagForAdmissionAgent && !gstDetails.id  && admissionAgentDetails.id) {
                                dataObject = {
                                    admissionAgentDetailsDTO:admissionAgentDetails,
                                    admissionGSTDetailsDTO:gstDetails,
                                    applicationIdGenerationCourseDTO:data
                                }
                                tempUrl = `/studentApplicationGeneration/updateStudentApplicationByCourseWithGSTAndAgent/${id}?agentId=${admissionAgentDetails.id}`;
                                method = 'PUT';
                                checkOptionalDetails = true;
                            }
                            else if (flagForGst && flagForAdmissionAgent && !gstDetails.id  && !admissionAgentDetails.id) {
                                dataObject = {
                                    admissionAgentDetailsDTO:admissionAgentDetails,
                                    admissionGSTDetailsDTO:gstDetails,
                                    applicationIdGenerationCourseDTO:data
                                }
                                tempUrl = `/studentApplicationGeneration/createStudentApplicationByCourseWithGSTAndAgent/${id}`;
                                method = 'POST';
                                checkOptionalDetails = true;
                            }
                            let finalUrl = enrollmentServiceBaseUrl +tempUrl
                            setIsLoading(true);
                            if(checkOptionalDetails){
                                PostData(finalUrl, method, dataObject);
                            }else{
                                PostData(finalUrl, method, data);
                            }
                        }else if(action ==='new'){
                            let url = enrollmentServiceBaseUrl + '/studentApplication/createStudentApplicationByCourse';
                            setIsLoading(true);
                            PostData(url,'POST',data);
                        }
                    }
                }
            } else if (name === 'exam') {
                if (examDetails) {
                    if (examDetails.businessArea === '') {
                        failureToast(message.failure.businessArea);
                    } else if (examDetails.term === '') {
                        failureToast(message.failure.term);
                    } else if (examDetails.courseId === '') {
                        failureToast(message.failure.examCourseId);
                    } else if (examDetails.examId === '') {
                        failureToast(message.failure.examId);
                    } else if (examDetails.examCenterBusinessArea === '') {
                        failureToast(message.failure.examCenter);
                    } else {
                        let data = {
                            // ...studentDetails,
                            studentId : studentDetails && studentDetails.studentId.value,
                            businessGroup : studentDetails && studentDetails.businessGroup,
                            companyCode : studentDetails && studentDetails.companyCode,
                            opportunityId : studentDetails && studentDetails.opportunityId,
                            opportunityType : studentDetails && studentDetails.opportunityType,
                            ...examDetails,
                            status:'ACTIVE'
                        }
                        let checkOptionalDetails = false;
                        let flagForAdmissionAgent = false;
                        let flagForGst = false;
                        if (admissionAgentDetails['admissionAgentType'] !== '') {
                            if (admissionAgentDetails['admissionAgentId'] === '') {
                                failureToast(message.failure.agentId)
                                return;
                            } else if (admissionAgentDetails['admissionAgentName'] === '') {
                                failureToast(message.failure.agentName)
                                return;
                            } else {
                                flagForAdmissionAgent = true;
                            }
                        }
                        if (gstDetails['admissionGSTCategory'] !== '') {
                            if (gstDetails['gstEffectiveDate'] === '') {
                                failureToast(message.failure.gstEffectiveDate);
                                return;
                            } else if (gstDetails['admissionGSTSponsorName'] === '') {
                                failureToast(message.failure.gstSponserName);
                                return;
                            } else if (gstDetails['admissionGSTIN'] === '') {
                                failureToast(message.failure.gstIn);
                                return
                            } else {
                                flagForGst = true;
                            }
                        }

                        if( action ==='edit' || ( action ==='view' && studentId ) ) {

                            // .............For New api........

                            let dataObject = {
                                admissionAgentDetailsDTO: null,
                                admissionGSTDetailsDTO: null,
                                applicationIdGenerationExamDTO: data
                            }
                            let tempUrl ='/studentApplication/updateStudentApplicationByExam/' + id;
                            let method = 'PUT'
                            // if only student details and gst details  update
                            if (flagForGst && !flagForAdmissionAgent && gstDetails.id) {
                                tempUrl = `/studentApplicationGeneration/updateStudentApplicationByExamWithGSTAndAgent/${id}?gstId=${gstDetails.id}`;
                                dataObject = {
                                    admissionAgentDetailsDTO: null,
                                    admissionGSTDetailsDTO: gstDetails,
                                    applicationIdGenerationExamDTO: data
                                }
                                checkOptionalDetails = true;
                            } else if (!flagForGst && flagForAdmissionAgent && admissionAgentDetails.id && admissionAgentDetails.id !== '') {
                                tempUrl = `/studentApplicationGeneration/updateStudentApplicationByExamWithGSTAndAgent/${id}?agentId=${admissionAgentDetails.id}`;
                                dataObject = {
                                    admissionAgentDetailsDTO: admissionAgentDetails,
                                    admissionGSTDetailsDTO: null,
                                    applicationIdGenerationExamDTO: data
                                }
                                checkOptionalDetails = true;
                            } else if (flagForGst && flagForAdmissionAgent && admissionAgentDetails.id && gstDetails.id) {
                                tempUrl = `/studentApplicationGeneration/updateStudentApplicationByExamWithGSTAndAgent/${id}?gstId=${gstDetails.id}&agentId=${admissionAgentDetails.id}`
                                dataObject = {
                                    admissionAgentDetailsDTO: admissionAgentDetails,
                                    admissionGSTDetailsDTO: gstDetails,
                                    applicationIdGenerationExamDTO: data
                                }
                                checkOptionalDetails = true;
                                // post api to create admission and gst
                            } else if (flagForAdmissionAgent && !admissionAgentDetails.id && !gstDetails.id  && flagForAdmissionAgent) {
                                dataObject = {
                                    admissionAgentDetailsDTO: admissionAgentDetails,
                                    admissionGSTDetailsDTO: gstDetails,
                                    applicationIdGenerationExamDTO: data
                                }
                                checkOptionalDetails = true;
                                tempUrl = `/studentApplicationGeneration/createStudentApplicationByExamWithGSTAndAgent/${id}`;
                                method = "POST"
                            } else if (flagForAdmissionAgent && !admissionAgentDetails.id && !gstDetails.id  && !flagForAdmissionAgent) {
                                dataObject = {
                                    admissionAgentDetailsDTO: admissionAgentDetails,
                                    admissionGSTDetailsDTO: null,
                                    applicationIdGenerationExamDTO: data
                                }
                                checkOptionalDetails = true;
                                tempUrl = `/studentApplicationGeneration/createStudentApplicationByExamWithGSTAndAgent/${id}`;
                                method = "POST"
                            }
                            else if (flagForGst && !gstDetails.id) {
                                dataObject = {
                                    admissionAgentDetailsDTO: null,
                                    admissionGSTDetailsDTO: gstDetails,
                                    applicationIdGenerationExamDTO: data
                                }
                                checkOptionalDetails = true;
                                tempUrl = `/studentApplicationGeneration/createStudentApplicationByExamWithGSTAndAgent/${id}`;
                                method = "POST"
                            } else if (flagForAdmissionAgent && !admissionAgentDetails.id && flagForGst && !gstDetails.id) {
                                dataObject = {
                                    admissionAgentDetailsDTO: admissionAgentDetails,
                                    admissionGSTDetailsDTO: gstDetails,
                                    applicationIdGenerationExamDTO: data
                                }
                                checkOptionalDetails = true;
                                tempUrl = `/studentApplicationGeneration/createStudentApplicationByExamWithGSTAndAgent/${id}`;
                                method = "POST"
                            }
                            if( checkOptionalDetails ) {
                                let finalUrl = enrollmentServiceBaseUrl  + tempUrl;
                                setIsLoading(true);
                                PostData(finalUrl, method, dataObject);
                            }else{

                                let finalUrl = enrollmentServiceBaseUrl  + tempUrl;
                                setIsLoading(true);
                                PostData(finalUrl, method, data);
                            }
                            // let finalUrl = enrollmentServiceBaseUrl  + tempUrl;
                            // setIsLoading(true);
                            // PostData(finalUrl, method, dataObject);
                        }else if (action ==='new') {
                            let url = `${enrollmentServiceBaseUrl}/studentApplication/createStudentApplicationByExam`;
                            let method = 'POST';
                            setIsLoading(true);
                            PostData(url, method, data);
                        }
                    }
                }
            }
        } catch(e){
            console.error('Error in submitHandler------>',e);
        }
    }
    const [appId,setAppId] = useState(null);
    const [showModel,setShowModel] = useState(false);
    const PostData =(url,requestMethod,dataObject)=>{
        putpost(url,(data)=>{
            if(action ==='edit'){
                successToast(data.message);
                setIsLoading(false);
                cancelEvent();
                history.push('/admin/applicationIdGeneration/view/'+id);
            }else if((action === 'view' && studentId)){
                successToast(data.message);
                setIsLoading(false);
                setIsEdit(false);
            }
            else if(action === 'new'){
                setAppId(data.data.id);
                setShowModel(true);
                setIsLoading(false);
                // successToast(data.message +' Generated Exam Id : '+data.data.id);
                // history.push(`/admin/applicationIdGeneration`);
            }
            return true;
        },(data)=>{
            failureToast(data.message);
            setIsLoading(false);
            return false;
        },dataObject,requestMethod);
    }
    useEffect(() => {
        if(applicationIdData && studentDetails.applicationId !=='' && courseApplicationDetails.academicCareer !=='' && action !=='new'){
            setLoader(false);
        }
    },[applicationIdData,courseApplicationDetails,studentDetails])
    const [documentData,setDocumentData] = useState(null)
    const uploadDocument =(viewAction)=>{
        if(applicationIdData){
            dispatch(
                updateData({
                    applicationId:applicationIdData.applicationId,
                    studentId:applicationIdData.studentId,
                    studentName:studentDetails.studentName,
                    opportunityId:applicationIdData.opportunityId,
                    businessAreaId:applicationIdData.businessArea,
                    termId:applicationIdData.term,
                    businessArea:getDispValForReactSelectByOneID(applicationIdData.businessArea ,'DispValue',businessArea),
                    term:getDispValForReactSelectByOneID(applicationIdData.term ,'DispValue',term),
                    personType: {
                        label:'Student',
                        desc:'Student',
                        value:'1'
                    },
                    processType:{
                        label:'Admission',
                        desc:'Admission',
                        value:'1'
                    }
                })
            );
            let url = ''
            if(documentData && !studentId){
                url = `${applicationIdData.studentId}/${viewAction}`
                window.open( `${ document.URL }/${url}`, '_blank' );
            } else if(viewAction === 'edit' && !studentId){
                url = `${applicationIdData.studentId}/new`
                window.open( `${ document.URL }/${url}`, '_blank' );
                // url = `/admin/applicationIdGeneration/edit/${applicationIdData.applicationId}/${applicationIdData.studentId}/new`
            }else if(documentData && studentId){
                window.open( `${ document.URL }/document/${viewAction}`, '_blank' );
            } else if(viewAction === 'view'){
                alert('Please UpDate Document First.');
            }
        }
    }
    const [ isEdit,setIsEdit ] = useState(false);
    const [ loader,setLoader ] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    useEffect(()=>{
        console.log('isLoading====>',isLoading)
    },[isLoading])
    const [ apiLoader,setApiLoader] = useState(true);
    const [ relatedDocument,setRelatedDocument ] = useState(null);
    const studentDetailsChild = (<StudentDetailsForm setBusinessArea={setBusinessArea} setCourseIds={setCourseIds} setIsExamDetailOpen={setIsExamDetailOpen} setIsCourseDetailOpen={setIsCourseDetailOpen} studentIds={studentIds} setGridNumber={setGridNumber} action={action} nextHandler={nextHandler} formData={studentDetails} UpdateFormData={UpdateFormData} opportunityType={opportunityType} businessGroup={businessGroup} companyCode={companyCode} setCompanyCode={setCompanyCode} label={label} placeholder={placeHolder} button={button}/>)
    const courseApplicationDetailsChild = (<CourseApplicationDetails isEdit={isEdit} programAction={programAction} setCourseIds={setCourseIds} setLoader={setLoader} isLoading={isLoading} setIsLoading={setIsLoading} aiatsData={aiatsData} setExternalOrganization={setExternalOrganization} setAiatsData={setAiatsData} academicGroupData={academicGroupData} academicCareerData={academicCareerData} submitHandler={submitHandler} setApiLoader={setApiLoader} courseIds={courseIds} externalOrganization={externalOrganization} businessArea={businessArea} saleType={saleType} admissionType={admissionType} term={term} UpdateFormData={UpdateFormData} action={action} formData={courseApplicationDetails} label={label} placeholder={placeHolder}/>);
    const examDetailsChild =(<ExamDetailsForm isEdit={isEdit} isLoading={isLoading} viewExamDetails={viewExamDetails} setCourseIds={setCourseIds} setTerm={setTerm} setLoader={setLoader} submitHandler={submitHandler} examDate={examDate} setExamDate={setExamDate} courseIds={courseIds} businessArea={businessArea} action={action} examIds={examIds} term={term} formData={examDetails} UpdateFormData={UpdateFormData} label={label} placeholder={placeHolder} />);
    const gstDetailsChild = (<GstDetails isEdit={isEdit}  gst={gst} studentId={studentId} action={action} formData={gstDetails} UpdateFormData={UpdateFormData} label={label} placeholder={placeHolder}/>);
    const admissionAgentDetailsChild =(<AgentDetails isEdit={isEdit} studentId={studentId} admissionAgent={admissionAgent} action={action} formData={admissionAgentDetails} UpdateFormData={UpdateFormData} label={label} placeholder={placeHolder}/>)
    const relatedDocumentChild =(<RelatedDocument documentData={documentData} isEdit={isEdit} studentId={studentId} uploadDocument={uploadDocument} action={action} relatedDocument={relatedDocument} setRelatedDocument={setRelatedDocument} UpdateFormData={UpdateFormData} button={button}/>);
    const logDetailsChild = (<LogDetailsApplicationId studentId={studentId} action={action} logDetails={applicationIdData} label={label} button={button}/>)
    const [flagForDocument,setFlagForDocument] = useState(false);
    useEffect(() => {
        if((action ==='view' || action ==='edit') && studentDetails && studentDetails.studentId !== ''  && studentDetails.applicationId !== '' && !apiLoader && !flagForDocument){
            // console.log('Student Details---->',studentDetails);
            fetchAllData(`${enrollmentServiceBaseUrl}/userDocumentDetails/getUserDocumentDetails?studentId=${studentDetails.studentId}&applicationId=${studentDetails.applicationId}`,setDocumentData)
            setFlagForDocument(true)
        }
    },[studentDetails])


    useEffect(() => {
        if(action ==='view' || action ==='edit'){
            fetchData(masterServiceBaseUrl + `/courseCatalogCourseHeader/getAllCourseCatalogCourseHeader`, setCourseIds, 'courseId')
            if(courseIds && courseIds.length > 0){
                setApiLoader(false);
            }
        }
    },[])

    const redirectHandler = ()=>{
        history.push(`/admin/applicationIdGeneration`);
    }

    const cancelEvent = ()=>{
        window.onbeforeunload = null;
    }
    return(
        showModel && appId?
            <CustomModel
                // copyIconVisible={true}
                title={'value'}
                content={`Application ID : ${appId}`}
                redirectHandler={redirectHandler}
                textToCopy={appId}
            />:
            apiLoader
                ?
                <div className="mx-auto text-center py-5 my-5" style={{ height: "100vh" }}>
                    <CustomLoader apiLoader={apiLoader}/>
                </div>
                :
                <Fragment>
                    <DarkBackground disappear={loader}>
                        <Loader loaded={false} lines={10} length={20} width={10} radius={25} corners={1} rotate={0} direction={1} color="#11cdef" speed={0.6} trail={60} shadow={false} hwaccel={false} className="spinner" zIndex={2e9} top="50%" left="50%" scale={0.7} loadedClassName="loadedContent"/>
                    </DarkBackground>
                    <div style={{marginTop: '15px'}} className="col">
                        <div className="card-wrapper">
                            <Card>
                                <CardHeader>
                                    <h3 className="mb-0">{headerTitle}</h3>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md={12} xs={12}>
                                            <div className="accordion">
                                                <CustomCollapse header={header.studentDetails} isOpen={true}  child={studentDetailsChild}/>
                                                {
                                                    viewCourseDetails
                                                        ?
                                                        <CustomCollapse header={header.courseApplicationDetails} isOpen={isCourseDetailOpen} headerCollapsable={false} headerCollapse={() => setIsCourseDetailOpen(!isCourseDetailOpen)} child={courseApplicationDetailsChild}/>
                                                        :
                                                        null
                                                }
                                                {
                                                    viewExamDetails
                                                        ?
                                                        <CustomCollapse  header={header.examDetails} isOpen={isExamDetailOpen} headerCollapsable={false} child={examDetailsChild} headerCollapse={() => setIsExamDetailOpen(!isExamDetailOpen)}/>
                                                        :
                                                        null
                                                }
                                                {
                                                    action ==='new'
                                                        ?
                                                        null
                                                        :
                                                        <Fragment>
                                                            <CustomCollapse header={header.admissionAgent} isOpen={true} headerCollapsable={false} child={admissionAgentDetailsChild}/>
                                                            <CustomCollapse header={header.gstDetails} isOpen={true} headerCollapsable={false} child={gstDetailsChild}/>
                                                            {
                                                                action === 'edit'
                                                                    ?
                                                                    viewCourseDetails
                                                                        ?
                                                                        <Row>
                                                                            <Col xs={12} style={{marginBottom:'20px'}}>
                                                                                {/*<Button color="info" size="sm" className="floatRight mx-1" disabled={true}>*/}
                                                                                {/*    Proceed for Charges And Payment*/}
                                                                                {/*</Button>*/}
                                                                                <ButtonWithLoader loading={isLoading} color="info" size="sm" className="floatRight mx-1" onClick={()=>submitHandler('course')}>
                                                                                    Submit
                                                                                </ButtonWithLoader>
                                                                            </Col>
                                                                        </Row>
                                                                        :
                                                                        action ==='edit'
                                                                            ?
                                                                            <Row>
                                                                                <Col xs={12} style={{marginBottom:'20px'}}>
                                                                                    {/*<Button color="info" size="sm" className="floatRight mx-1" disabled={true}>*/}
                                                                                    {/*    Pay Exam Fee*/}
                                                                                    {/*</Button>*/}
                                                                                    <ButtonWithLoader loading={isLoading} color="info" size="sm" className="floatRight mx-1" onClick={()=>submitHandler('exam')}>
                                                                                        Submit
                                                                                    </ButtonWithLoader>
                                                                                </Col>
                                                                            </Row>
                                                                            :
                                                                            null
                                                                    :
                                                                    null
                                                            }

                                                            {
                                                                action === 'view'
                                                                    ?
                                                                    viewCourseDetails && studentId
                                                                        ?
                                                                        isEdit
                                                                            ?<Row>
                                                                                <Col xs={12} style={{marginBottom:'20px'}}>
                                                                                    <Button color="info" size="sm" className="floatRight mx-1" onClick={()=>{
                                                                                        if (window.confirm('Are you sure you want cancel?')) {
                                                                                            setIsEdit(false);
                                                                                            cancelEvent();
                                                                                        }}}
                                                                                    >
                                                                                        Cancel
                                                                                    </Button>
                                                                                    <ButtonWithLoader loading={isLoading} color="info" size="sm" className="floatRight mx-1" onClick={()=>submitHandler('course')}>
                                                                                        Save
                                                                                    </ButtonWithLoader>
                                                                                </Col>
                                                                            </Row>
                                                                            :
                                                                            <Row>
                                                                                <Col xs={12} style={{marginBottom:'20px'}}>
                                                                                    {/*<Button color="info" size="sm" className="floatRight mx-1" disabled={true}>*/}
                                                                                    {/*    Proceed for Charges And Payment*/}
                                                                                    {/*</Button>*/}
                                                                                    <Button color="info" size="sm" className="floatRight mx-1" onClick={()=> {
                                                                                        setIsEdit(true)
                                                                                        triggerThis()
                                                                                    }}>
                                                                                        Edit
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        :
                                                                        action ==='view' && studentId
                                                                            ?
                                                                            isEdit
                                                                                ?<Row>
                                                                                    <Col xs={12} style={{marginBottom:'20px'}}>
                                                                                        <Button color="info" size="sm" className="floatRight mx-1" onClick={()=> {
                                                                                            if (window.confirm('Are you sure you want cancel?')) {
                                                                                                setIsEdit(false);
                                                                                                cancelEvent()
                                                                                            }
                                                                                        }} >
                                                                                            Cancel
                                                                                        </Button>
                                                                                        <ButtonWithLoader loading={isLoading} color="info" size="sm" className="floatRight mx-1" onClick={()=>submitHandler('exam')}>
                                                                                            Save
                                                                                        </ButtonWithLoader>
                                                                                    </Col>
                                                                                </Row>
                                                                                :
                                                                                <Row>
                                                                                    <Col xs={12} style={{marginBottom:'20px'}}>
                                                                                        <Button color="info" size="sm" className="floatRight mx-1" onClick={()=> {
                                                                                            setIsEdit(true);
                                                                                            triggerThis();
                                                                                        }}>
                                                                                            {/*<Button color="info" disabled={isModifyingDetails} size="sm" className="floatRight mx-1" onClick={()=>submitHandler('exam')}>*/}
                                                                                            Edit
                                                                                        </Button>
                                                                                    </Col>
                                                                                </Row>
                                                                            :
                                                                            null
                                                                    :
                                                                    null
                                                            }
                                                            <CustomCollapse header={header.relatedDocuments} documentData={documentData} isOpen={true} headerCollapsable={false} child={relatedDocumentChild}/>
                                                            <CustomCollapse header={header.logDetails} isOpen={true}  headerCollapsable={false} child={logDetailsChild}/>
                                                        </Fragment>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </Fragment>
    );
}
export default ApplicationIdGeneration;

