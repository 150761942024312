import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap'
import { MasterHeaderAuto } from "../../common/commonComponents/masterHeader/masterHeader";
import { endpoint } from '../../common/constant';
import { fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import { failureToast } from '../../common/utils/methods/toasterFunctions/function';
import ProjectRow from './projectRow';

const headerList = [
    { name: "Project", isRequired: true },
    { name: "Role", isRequired: true },
    { name: "User ID", isRequired: true },
    { name: "Status" },
    { name: "New User Notification" },
    { name: "User ID is Email ID" },
    { name: "Action" }
]

const ProjectAndRoleTable = ({ projectData,
    projectDetails,
    isPreviewEnable,
    userDetails,
    isVendor,
    setProjectDetails = () => { },
    isEditMode = false,
    userPermissions,
    action,
    setRoleAndProjectObject = () => {},
    roleAndProjectObject
}) => {

    return (
        <Table className="align-items-center">
            <MasterHeaderAuto headerList={headerList} />
            <tbody>
                {projectDetails && projectDetails.map((rowData, idx) => {
                    return <ProjectRow
                        rowData={rowData}
                        isVendor={isVendor}
                        isEditMode={isEditMode}
                        userDetails={userDetails}
                        projectData={projectData}
                        index={idx}
                        projectDetails={projectDetails}
                        setProjectDetails={setProjectDetails}
                        isPreviewEnable={isPreviewEnable}
                        userPermissions={userPermissions}
                        action={action}
                        setRoleAndProjectObject={setRoleAndProjectObject}
                        roleAndProjectObject={roleAndProjectObject}
                    />
                })}
            </tbody>
        </Table>
    )
}

export default ProjectAndRoleTable