import React from "react";
import Dialog from "@mui/material/Dialog";
import { MdCancel } from "react-icons/md";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { useDispatch } from "react-redux";
import AButton from "../../common/form-fields-mui/AButton";
import { useSelector } from "react-redux";

const BatchActivationDialog = (props) => {
  const dispatch = useDispatch();
  const batch = useSelector((state) => state.batch);
  const dropdowns = useSelector((state) => state.batch.dropdowns);
  const { onClose, open, batchData } = props;
  const { batchStatus, batchName, id } = batchData;
  const status = batchStatus ;
  const displayStatus = status === "ACTIVE" ? "Inactive" : "Active";

  const handleClose = () => {
    onClose(false);
  };
  const onAction = () => {
  const isBatchSync = batchData.msTeamsGroupId?true:false
  
  const getKeyValue  = (id, key ) =>{
    
      let list = dropdowns[key].
         filter((item) => {
           if(item.value === id)
             return item
         }
          )
    if(list.length>0)
    return ({id,dispValue:list[0].label})
    return { }
  }

const request = {
   ...batchData ,
    batchStatus:status === "ACTIVE" ? "INACTIVE" : "ACTIVE" ,
    isEnabledMsTeamsSync:isBatchSync,
    schoolBoard: getKeyValue(+batchData?.schoolBoard, "schoolBoardData"),
  classType: getKeyValue(+batchData?.classType, "classTypeData"),
  medium: getKeyValue(+batchData?.medium, "mediumData"),
   }
    props.deActiveBatchHandler(request, id);
    handleClose(false)
    
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={{ padding: "1.5rem", width: "21.3rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "2rem",
            marginBottom: "10px",
            color: "black",
          }}
        >
          <AiOutlineQuestionCircle />
          <MdCancel onClick={handleClose} />
        </div>
        <div>
          <div
            style={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "24px",
              color: "#000000",
              marginBottom: ".6rem",
            }}
          >
            Confirm {displayStatus} Branch
          </div>
          <div
            style={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#414141",
              marginBottom: ".6rem",
            }}
          >
            Are you sure you want to{" "}
            {displayStatus && displayStatus.toLowerCase()} {batchName}?
          </div>
        </div>

        <div className="mt-2 d-flex ">
          <AButton updatedStyle={{ width: "7rem" }} onClick={handleClose}>
            No
          </AButton>
          <AButton
            updatedStyle={{
              background: "#CC5F5F",
              color: "#fff",
              width: "7rem",
            }}
            onClick={onAction}
          >
            Yes, {displayStatus}
          </AButton>
        </div>
      </div>
    </Dialog>
  );
};

export default BatchActivationDialog;
