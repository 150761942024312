import React, { useEffect } from 'react'
import CustomChip from "views/pages/manage/common/customChip/CustomChip";
import { Tooltip } from "@mui/material";
import { ReactComponent as CopyIcon } from "../../../../../../assets/img/svg/CopyClipboardIcon.svg";
import { useState } from 'react';

const ChipWithName = ({ lecture }) => {
  const [toolTipText, setToolTipText] = useState("Copy");
  const [timeoutID, setTimeoutID] = useState(null);

  const resetTooltip = () => setTimeout(() => {
    setToolTipText("Copy")
  }, 5000)

  useEffect(() => {
    return () => {
      clearTimeout(timeoutID);
    }
  }, [timeoutID])

  return (
    <CustomChip
      textColor="##262626"
      bgColor={"#F5F5F5"}
      style={{ fontSize: "14px", marginTop: "5px" }}
    >
      <div>
        {lecture?.name}
        <Tooltip title={toolTipText}>
          <span style={{ marginLeft: "5px" }}>
            <CopyIcon
              onClick={() => {
                navigator.clipboard.writeText(lecture?.name);
                setToolTipText("Copied");
                setTimeoutID(resetTooltip());
              }}
            />
          </span>
        </Tooltip>
      </div>
    </CustomChip>
  )
}

export default ChipWithName