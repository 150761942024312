import React from "react";
import Style from './tableHeader.module.scss';
const TableHeader = () => {

  return (
    <thead className="thead-light">
    <tr>
      <th
        className={`text-uppercase text-center white-breakSpace column-heading ${Style.table_th_padding}`}
        rowSpan={'2'}
      >
        Page Name
      </th>
      <th
        className={`text-uppercase text-center white-breakSpace column-heading ${Style.table_th_padding}`}
        rowSpan={'2'}
      >
        Page description
      </th>
      <th
        className={`text-uppercase text-center white-breakSpace column-heading ${Style.table_th_padding}`}
        rowSpan={'2'}
      >
        Navigation
      </th>
      <th
        className={`text-uppercase text-center white-breakSpace column-heading ${Style.table_th_padding} ${Style.table_th_border_bottom}`}
        colSpan={5}
      >
        Action Allowed
      </th>
    </tr>
    <tr>
      <th
        className={`text-uppercase text-center white-breakSpace column-heading ${Style.table_th_padding} ${Style.table_th_sub}`}
      >
        Add
      </th>
      <th
        className={`text-uppercase text-center white-breakSpace column-heading ${Style.table_th_padding} ${Style.table_th_sub}`}
      >
        Update
      </th>
      <th
        className={`text-uppercase text-center white-breakSpace column-heading ${Style.table_th_padding} ${Style.table_th_sub}`}
      >
        Delete
      </th>
      <th
        className={`text-uppercase text-center white-breakSpace column-heading ${Style.table_th_padding} ${Style.table_th_sub}`}
      >
        View
      </th>
      <th
        className={`text-uppercase text-center white-breakSpace column-heading ${Style.table_th_padding} ${Style.table_th_sub}`}
      >
        Approve
      </th>
    </tr>
    </thead>
  )
}

export default TableHeader;
