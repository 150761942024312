import React, { useContext, useState } from 'react'
import { ReactComponent as EditIcon } from 'assets/img/svg/Edit2.svg';
import { ReactComponent as DeleteIcon } from 'assets/img/svg/Delete.svg';
import { ReactComponent as CrossIcon } from 'assets/img/svg/CrossIcon.svg';
import DeleteDialog from './DeleteDialog';
import { checkPermission } from '../../../../common/utils/methods/commonMethods/utilityMethod';
import AButton from 'views/pages/manage/common/form-fields-mui/AButton'
import { RolePermissions, PermissionContext } from 'appContext'
import { pages } from '../../../../common/constant';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'


const ActionsCellComponent = ({ params, handleEditClick, handleCancelClick, handleSaveClick, deleteHandler, rowForm, isStock }) => {

  const userPermissions = RolePermissions(useContext(PermissionContext), pages['materialIdSetup']['id']);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    params.api.getRowMode(params?.id) === 'view' ? (
      <>
        <div className='d-flex align-items-center'>
          {checkPermission(userPermissions, 'U') && <IconButtonWrapper onClick={() => handleEditClick(params)}><EditIcon /></IconButtonWrapper>}
          {checkPermission(userPermissions, 'D') && <IconButtonWrapper className='ml-2' onClick={() => setIsDialogOpen(true)} ><DeleteIcon /></IconButtonWrapper>}
        </div>
        <DeleteDialog
          open={isDialogOpen}
          setOpen={setIsDialogOpen}
          data={params?.row}
          isStock = {isStock}
          onAgree={() => deleteHandler(params?.row)}
        />
      </>
    ) : (
      <div className='d-flex align-items-center'>
        <AButton
          variant='primary_filled'
          size='xs'
          onClick={() => handleSaveClick(params)}
          disabled={!rowForm?.groupTypeId || !rowForm?.materialNumber || !rowForm?.type}
        >
          Save
        </AButton>
        <IconButtonWrapper className='ml-2' onClick={() => handleCancelClick(params)}><CrossIcon /></IconButtonWrapper>
      </div>
    )
  )
}

export default ActionsCellComponent