import React, {useEffect, useState} from "react";
import {Button, CardHeader, Container, Card, CardBody, Col, Input, Label, Table, Row, FormGroup} from "reactstrap";
import {MasterHeaderAuto} from "../../../common/commonComponents/masterHeader/masterHeader";
import TableList from "../../../common/tableList";
import {
    useLocation
} from "react-router-dom";
import MasterHeader from './tableHeader';
import NoDataFound from "../../../common/noDataFound";
import Style from "./tableHeader.module.scss";
import {endpoint} from "../../../common/constant";
import Select from "react-select";
import {failureToast, getAPI, putpost, successToast} from "../../../../../../services/http";

const _ = require('lodash');

const AssignPermission = () => {

    const [processName, setProcessName] = useState([]);
    const [role, setRole] = useState([]);
    const [permissionListPage, setPermissionListPage] = useState([]);
    const [searchValue, setSearchValue] = useState({});
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const fetchData = (url, type) => {
        try {
            getAPI(
                url,
                (data) => {
                    dataHandler(data?.data, type)
                },
                (data) => {
                    failureToast(data['message']);
                }
            );
        } catch (err) {
            console.log('Error :', err)
        }
    };

    const fetchSearchData = _.debounce((url, obj, type) => {
        putpost(url,
            (data) => {
                dataHandler(data?.data, type)
            },
            (data) => {
                failureToast(data['message']);
            },
            obj,
            'post'
        );
    }, 300);

    const getSearchData = _.debounce((url, type) => {
        try {
            getAPI(
                url,
                (data) => {
                    dataHandler(data?.data, type)
                },
                (data) => {
                    failureToast(data['message']);
                }
            );
        } catch (err) {
            console.log('Error :', err)
        }
    }, 300);

    const dataHandler = (data, type) => {
        try {
            switch (type) {
                case 'SEARCH_RESULT':
                    setPermissionListPage(data);
                    break;
                case 'ROLE_NAME':
                    const roleTemp = [];
                    data.forEach(item => {
                        roleTemp.push({
                            label: item?.roleName,
                            value: item?.id,
                            desc: item?.tagTypeDesc
                        })
                    });
                    setRole(roleTemp);
                    break;
                case 'PROCESS_NAME':
                    const processTemp = [];
                    data.forEach(item => {
                        processTemp.push({
                            label: item?.tagTypeDispValue,
                            value: item?.id,
                        })
                    });
                    setProcessName(processTemp);
                    break;
                case 'RECORD_CREATED':
                    successToast('Records Updated')
                    break;
                default:
                    break;
            }
        } catch (e) {
            console.log(e)
        }
    }
    const onChangeHandler = (type, index, checked) => {
        try {
            const _tempArr = permissionListPage;
            _tempArr[index][type] = checked;
            setPermissionListPage(_tempArr);
        } catch (e) {
            console.log('Error ', e)
        }
    }

    const searchHandler = (value, property) => {
        try {
            setSearchValue((pre) => ({
                ...pre,
                [property]: value
            }))
        } catch (e) {
            console.log('Error ------- ', e);
        }
    }


    return (
        <Container className={'mt-5'} fluid>
            <Card>
                <CardHeader>
                    <div className={'d-flex justify-content-between'}>
                        <h2>Role Permission</h2>
                    </div>
                </CardHeader>
                <CardBody className={'p-0'}>
                    <Row className={'p-4'}>
                        <Col sm={4}>
                            <FormGroup>
                                <Label className="form-control-label">
                                    Role Name
                                </Label>
                                <Select
                                    id="roleProcess"
                                    options={role}
                                    placeholder="Enter Role to Search"
                                    onInputChange={(e) => {
                                        e.length <= 2 && setRole([]);
                                        e.length > 2 && fetchSearchData(endpoint.role_Name.search, {roleName: e}, 'ROLE_NAME')
                                    }}
                                    onChange={(e) => {
                                        searchHandler(e.value, 'roleId')
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Label className="form-control-label">
                                    Process Name
                                </Label>
                                <Select
                                    id="processName"
                                    options={processName}
                                    placeholder="Enter Process Name"
                                    onInputChange={(e) => {
                                        e.length <= 2 && setProcessName([]);
                                        e.length > 2 && getSearchData(endpoint.process_Tag.search + `?key=${e}`, 'PROCESS_NAME');
                                    }}
                                    onChange={(e) => {
                                        searchHandler(e.value, 'processId');
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={4} className={'d-flex align-items-center'}>
                            <Button color={'info'}
                                    onClick={() => {
                                        setPermissionListPage([]);
                                        fetchData(`${endpoint.role_To_Process.searchByProcessIdAndRoleId + `?roleMasterId=${searchValue?.roleId}&processTagTypeMasterId=${searchValue.processId}`}`, 'SEARCH_RESULT');
                                    }}
                            >Search</Button>
                        </Col>

                    </Row>

                </CardBody>
            </Card>
            <Card>
                <CardHeader>
                    <h2>Permission Details</h2>
                </CardHeader>
                <CardBody className={'p-0'}>
                    <div
                        id='questionTable'
                        className='table-responsive'
                        style={{overflow: 'auto !important'}}
                    >
                        <Table className='align-items-center table-flush '>
                            <MasterHeader/>
                            <tbody className='list'>
                            {permissionListPage && permissionListPage.length > 0
                                ? permissionListPage.map((el, index) =>
                                    (
                                        <tr>
                                            <td
                                                className='text-center  white-breakSpace'
                                                style={{width: '200px'}}
                                            >
                                                <Label className={`form-control-label`}>
                                                    {el['pageName']}
                                                </Label>
                                            </td>
                                            <td
                                                className='text-center  white-breakSpace'
                                                style={{width: '200px'}}
                                            >
                                                <Label className={`form-control-label`}>
                                                    {el['pageDescription']}
                                                </Label>
                                            </td>
                                            <td
                                                className='text-center  white-breakSpace'
                                                style={{width: '200px'}}
                                            >
                                                <Label className={`form-control-label`}>
                                                    {el['navigation']}
                                                </Label>
                                            </td>
                                            <td
                                                className='text-center  white-breakSpace'
                                            >
                                                <div className={Style.customCheckBox}>
                                                    <Input
                                                        type={'checkbox'}
                                                        defaultChecked={el['isAdd']}
                                                        className={Style.form_control}
                                                        onChange={(e) => {
                                                            onChangeHandler('isAdd', index, e.target.checked);
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                            <td
                                                className='text-center  white-breakSpace'
                                            >
                                                <div className={Style.customCheckBox}>
                                                    <Input
                                                        type={'checkbox'}
                                                        defaultChecked={el['isUpdate']}
                                                        className={Style.form_control}
                                                        onChange={(e) => {
                                                            onChangeHandler('isUpdate', index, e.target.checked);
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                            <td
                                                className='text-center  white-breakSpace'
                                            >
                                                <div className={Style.customCheckBox}>
                                                    <Input
                                                        type={'checkbox'}
                                                        defaultChecked={el['isDelete']}
                                                        className={Style.form_control}
                                                        onChange={(e) => {
                                                            onChangeHandler('isDelete', index, e.target.checked);
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                            <td
                                                className='text-center  white-breakSpace'
                                            >
                                                <div className={Style.customCheckBox}>
                                                    <Input
                                                        type={'checkbox'}
                                                        defaultChecked={el['isViewOnly']}
                                                        className={Style.form_control}
                                                        onChange={(e) => {
                                                            onChangeHandler('isViewOnly', index, e.target.checked);
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                            <td
                                                className='text-center  white-breakSpace'
                                            >
                                                <div className={Style.customCheckBox}>
                                                    <Input
                                                        type={'checkbox'}
                                                        defaultChecked={el['isApprove']}
                                                        className={Style.form_control}
                                                        onChange={(e) => {
                                                            onChangeHandler('isApprove', index, e.target.checked);
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                )
                                : <tr>
                                    <td colspan="8">
                                        <NoDataFound/>
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </Table>
                    </div>
                    {
                        permissionListPage && permissionListPage.length > 0 ?
                            <div className={'text-right p-4'}>
                                <Button
                                    color={'info'}
                                    onClick={() => {
                                        let obj = {
                                            permissionDetailRequestDtoList: permissionListPage,
                                            processTagTypeMasterId: params.get('processId'),
                                            roleMasterId: params.get('roleId'),
                                            roleToProcessDetailId: params.get('roleToProcessDetailId'),
                                        }
                                        fetchSearchData(endpoint.role_To_Process.createPermission, obj, 'RECORD_CREATED')
                                    }}
                                >Save</Button>
                                <Button color={'info'}>Cancel</Button>
                            </div> : null
                    }
                </CardBody>
            </Card>
        </Container>
    )
}

export default AssignPermission;
