import React, { useState, useEffect, useRef, useContext } from 'react';
import Loader from 'react-loader-spinner';
import {
	Card,
	CardHeader,
	Input,
	FormGroup,
	Container,
	Button,
	Table,
	Row,
	Col,
	UncontrolledCollapse,
	CardBody,
	Form,
} from 'reactstrap';
import Select from 'react-select';
import { baseUrl } from 'services/http';
import { SimpleInput } from 'components/FormComponent/formcomponent';
import { useForm } from 'react-hook-form';
import {
	masterServiceBaseUrl,
	putpost,
	putpost2,
	getAPI,
	enrollmentServiceBaseUrl,
} from 'services/http';
import { useHistory } from 'react-router-dom';
import { GetPagination } from '../../../common/commonComponents/pagination/pagination';
import { PROGRAMACTION } from '../../../common/commonComponents/masterRow/masterRow';
import { PermissionContext, RolePermissions } from "../../../../../../appContext";
import { pages } from "../../../common/constant";
import CustomButton from "components/CustomButton";
import {ButtonText} from "variables/Buttons";
import { PermisionDenied } from '../../../common/commonComponents';
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";

const InfoRendere = () => {};

const ProgramActionFilter = (props) => {
	const permissions = useContext(PermissionContext);
	const userPermissions = RolePermissions(
		permissions,
		pages['programActionsMapping']['id']
	);
	
	let history = useHistory();
	const [classArray, setclassArray] = useState([]);
	const [apiloader, setapiloader] = useState(false);
	const [state, setstate] = useState([]);
	const [searchData, setSearchData] = useState([]);
	const [programActionData, setProgramActionData] = useState([]);
	const [activeReasonData, setActiveReasonData] = useState([]);
	const [programActionValue, setProgramActionValue] = useState('');
	const [pagination, setpagination] = useState({
		currentPage: 1,
		nextPage: null,
		previousPage: null,
		totalPage: null,
	});

	const pageSize = 10;

	const fetchData = (url, stateMethod) => {
		getAPI(
			url,
			(data) => {
				stateMethod(data['data']);
			},
			(data) => {
				failureToast(data['message']);
			}
		);
	};

	const searchProgramActionData = (url, stateMethod) => {
		getAPI(
			url,
			(data) => {
				let list = [];
				list = data.data.map((data) => {
					return {
						value: data.id,
						label: data.programActionDispValue,
					};
				});
				stateMethod(list);
			},
			(data) => {
				failureToast(data['message']);
			}
		);
	};

	useEffect(() => {
		searchProgramActionData(
			masterServiceBaseUrl + '/programAction/getAllActiveProgramAction',
			setSearchData
		);
		fetchData(
			masterServiceBaseUrl + '/programAction/getAllActiveProgramAction',
			setProgramActionData
		);
		fetchData(
			masterServiceBaseUrl + '/actionReason/getAllActiveActionReason',
			setActiveReasonData
		);
	}, []);

	const fetchClasses = async (url) => {
		setapiloader(true);
		getAPI(
			url,
			(data) => {
				if (data.data == '' || data.data == null) {
					failureToast('No Values Found against search criteria');
					setapiloader(false);
				} else {
					setstate(data['data']);
					let n = pagination;
					if (data['data'].length > pageSize) {
						n['nextPage'] = n.currentPage + 1;
					}
					if (n.currentPage > 1) {
						n.previousPage = n.currentPage - 1;
					}
					n['totalPage'] = Math.floor(data['data'].length / pageSize);
					if (data['data'].length % pageSize != 0) {
						n['totalPage'] += 1;
					}
					setpagination(n);
					setapiloader(false);
					setclassArray(
						data['data'].slice(
							pagination.currentPage * pageSize - pageSize,
							pagination.currentPage * pageSize
						) || []
					);
				}
			},
			(data) => {
				failureToast(data['message']);
				setclassArray([]);
				setapiloader(false);
			}
		);
	};

	const nextPage = () => {
		setclassArray(
			state.slice(
				(pagination.currentPage + 1) * pageSize - pageSize,
				(pagination.currentPage + 1) * pageSize
			)
		);
		let n = pagination;
		n['currentPage'] = n['currentPage'] + 1;
		n['previousPage'] = n.currentPage;
		setpagination(n);
	};
	const firstPage = () => {
		setclassArray(state.slice(1 * pageSize - pageSize, 1 * pageSize));
		let n = pagination;
		n['currentPage'] = 1;
		n['previousPage'] = null;
		setpagination(n);
	};
	const lastPage = () => {
		setclassArray(
			state.slice(
				pagination.totalPage * pageSize - pageSize,
				pagination.totalPage * pageSize
			)
		);
		let n = pagination;
		n['currentPage'] = n.totalPage;
		n['previousPage'] = n.totalPage - 1;
		setpagination(n);
	};
	const previousPage = () => {
		if (pagination.currentPage != 1) {
			setclassArray(
				state.slice(
					(pagination.currentPage - 1) * pageSize - pageSize,
					(pagination.currentPage - 1) * pageSize
				)
			);
			// console.log(
			// 	state.slice(
			// 		(pagination.currentPage - 1) * pageSize - pageSize,
			// 		(pagination.currentPage - 1) * pageSize
			// 	)
			// );
			let n = pagination;
			n['currentPage'] = n['currentPage'] - 1;
			if (n['currentPage'] == 1) {
				n['previousPage'] = null;
			} else {
				n['previousPage'] = n.currentPage;
			}
			setpagination(n);
		}
	};

	return (
		<>
			{apiloader ? (
				<div
					className="mx-auto text-center py-5 my-5"
					style={{ height: '100vh' }}
				>
					<Loader
						type="Rings"
						color="#00BFFF"
						height={70}
						width={70}
						timeout={10000}
					/>
				</div>
			) : (
				<div></div>
			)}
			
			{userPermissions ? (
				<>
					<ProgramActionTable
						searchData={searchData}
						setSearchData={setSearchData}
						programActionData={programActionData}
						activeReasonData={activeReasonData}
						state={state}
						setstate={setstate}
						setclassArray={setclassArray}
						classArray={classArray}
						fetchClasses={fetchClasses}
						programActionValue={programActionValue}
						setProgramActionValue={setProgramActionValue}
						pagination={pagination}
						setpagination={setpagination}
						userPermissions={userPermissions}
					/>
					{pagination.totalPage > 1 ? (
						<GetPagination
							pagination={pagination}
							isSaveVisible={false}
							setclassArray={setclassArray}
							pageSize={pageSize}
							pagination={pagination}
							setpagination={setpagination}
							state={state}
							isPaginationFromServer={false}
							isFetching={apiloader}
						/>
					) : null}
				</>
			) : (
				<PermisionDenied />
			)}
			
		</>
	);
};
const ProgramActionTable = (props) => {
	let history = useHistory();
	const { handleSubmit, control, formState, reset, register } = useForm();
	const {
		pagination,
		setpagination,
		searchData,
		setclassArray,
		classArray,
		fetchClasses,
		programActionValue,
		setProgramActionValue,
		programActionData,
		activeReasonData,
		userPermissions
	} = props;
	const [tableDataKey, settableDataKey] = useState('tableDataKey');
	const [apiLoader, setApiLoader] = useState(false);
	const [randomkey, setrandomKey] = useState('randomKey');
	const [clicked, setClicked] = useState(false);

	const searchedData = async () => {
		if (programActionValue == '' || programActionValue == null) {
			failureToast('Please select dropdown values');
			return;
		} else if (programActionValue) {
			setApiLoader(true);
			await fetchClasses(
				`${masterServiceBaseUrl}/programActionReasonMapping/getProgramActionReasonSearch/${programActionValue}`
			);
			setApiLoader(false);
		} else {
			setclassArray([]);
			setApiLoader(false);
		}
	};

	const onReset = () => {
		setrandomKey(new Date().getMilliseconds());
		setclassArray([]);
		setClicked(true);
		setpagination({
			currentPage: 1,
			nextPage: null,
			previousPage: null,
			totalPage: null,
		});
	};

	return (
		<>
			<Container key={randomkey} className={('mt-3', 'fadeInDown')} fluid>
				<Row>
					<div className="col">
						<Card className="mb-0">
							<CardHeader>
								<h3 className="mb-0">Program Action</h3>
								{/* <Button
									color="info"
									size="sm"
									type="button"
									className="floatRight mx-1"
									onClick={() => history.push('/admin/programActions/new')}
								>
									New Program Action Mapping
								</Button> */}
								<CustomButton 
									className={ "floatRight mx-1" }
									content={ButtonText.ProgramActionMapping.addNew}
									icon={true}
									permissionType={'C'}
									permissionSet={userPermissions}
									onClick={() => history.push('/admin/programActions/new')}
								/>
							</CardHeader>
							<CardBody>
								<Form id="filterQuestion">
									<Row>
										<Col md="6" style={{ marginTop: '-9px' }}>
											<FormGroup>
												<label
													className="form-control-label"
													htmlFor="example3cols2Input"
												>
													Program Action ID
												</label>
												<Select
													placeholder="Select Program Action ID"
													id="example1cols1Input"
													options={searchData}
													required
													onChange={(e) => {
														setclassArray([]);
														setClicked(false);
														let programActionDataId = e.value;
														setProgramActionValue(programActionDataId);
													}}
												/>
											</FormGroup>
										</Col>
										<Col md="3" className="mt-4">
											{/* <Button
												className="btn-vimeo btn-icon w-100"
												color="info"
												type="button"
												onClick={() => {
													if (!clicked) {
														searchedData();
														setclassArray([]);
														setClicked(true);
													}
												}}
											>
												<span className="btn-inner--icon mr-1">
													<i className="fas fa-search" />
												</span>
												<span className="btn-inner--text">Search</span>
											</Button> */}
											<CustomButton 
												className={ "btn-vimeo btn-icon w-100" }
												content={ButtonText.ProgramActionMapping.search}
												icon={true}
												permissionType={'S'}
												permissionSet={userPermissions}
												onClick={() => {
													if (!clicked) {
														searchedData();
														setclassArray([]);
														setClicked(true);
													}
												}}
											/>
										</Col>
										<Col md="3" className="mt-4">
											{/* <Button
												className="btn"
												color="secondary"
												type="button"
												onClick={() => {
													setrandomKey(new Date().getMilliseconds());
													setclassArray([]);
													setClicked(true);
													setpagination({
														currentPage: 1,
														nextPage: null,
														previousPage: null,
														totalPage: null,
													});
												}}
											>
												<span className="btn-inner--text">Clear</span>
											</Button> */}
											<CustomButton
												type="reset"
												className={'btn'}
												content={ButtonText.ProgramActionMapping.clear}
												permissionType={'R'}
												permissionSet={userPermissions}
												icon={true}
												onClick={onReset}
											/>
										</Col>
									</Row>
								</Form>
							</CardBody>

							{apiLoader ? (
								<div
									className="mx-auto text-center py-5 my-5"
									style={{ height: '400px' }}
								>
									<Loader type="Rings" color="#00BFFF" height={70} width={70} />
								</div>
							) : (
								<div id="questionTable" className="table-responsive">
									{classArray.length > 0 ? (
										<Table className="align-items-center table-flush tableLayout">
											<thead className="thead-light">
												<tr>
													<th className="text-center white-breakSpace">
														Program Action
													</th>
													<th className="text-center white-breakSpace">
														Action Reason Description
													</th>
													<th className="text-center white-breakSpace">
														Status
													</th>
												</tr>
											</thead>
											<tbody className="list" key={tableDataKey}>
												{classArray
													? classArray.map((el, index) => (
															<PROGRAMACTION
																// type={'programAction'}
																index={index}
																el={el}
																programActionAllData={programActionData}
																activeReasonAllData={activeReasonData}
																classArrayAll={classArray}
															/>
													  ))
													: null}
											</tbody>
										</Table>
									) : null}
								</div>
							)}
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

export default ProgramActionFilter;
