import React, { useState, useEffect, useContext } from 'react';
import CourseAttributeTable from './courseAttributeTables';
import { getAPI } from 'services/http';
import { PermissionContext, RolePermissions } from "../../../../../../appContext";
import { constants, endpoint, pages } from "../../../common/constant";
import { PermisionDenied } from "../../../common/commonComponents";
import CustomLoader from "../../../common/commonComponents/Loader/loader";
import { GetPagination } from "../../../common/commonComponents/pagination/pagination";
import { fetchAllPromisedData } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { getPaginatedData } from '../../../common/utils/methods/commonMethods/paginationMethod';
import { failureToast } from '../../../common/utils/methods/toasterFunctions/function';


let page = 0;
let isNextCallPossible = true;
const _ = require('lodash');

const CourseAttributes = () => {
	const permissions = useContext(PermissionContext);
	const userPermissions = RolePermissions(
		permissions,
		pages['courseAttributes']['id']
	);

	const [classArray, setclassArray] = useState([]);
	const [apiloader, setapiloader] = useState(false);
	const [serverData, setServerData] = useState([]);
	const [courseAllAttrState, setCourseAllAttrState] = useState([]);
	const [allCompCodeState, setAllCompCodeState] = useState([]);
	const [allbusinessGpState, setAllBusinessGpState] = useState([]);
	const [searchStr, setSearchStr] = useState(null);
	const [isSaveVisible, setisSaveVisible] = useState(false);
	const [pagination, setpagination] = useState(constants.pagination);
	const pageSize = 10;

	const fetchClasses = async url => {
		setapiloader(true);
		const courseAttributeData = await fetchAllPromisedData(url, true);
		if (courseAttributeData?.code === 200) {
			setServerData(courseAttributeData.data?.courseAttributeMappingMasterDTOList);
			getPaginatedData(courseAttributeData.data?.courseAttributeMappingMasterDTOList, pageSize, pagination, setpagination, setapiloader, setclassArray)
			isNextCallPossible = courseAttributeData.data?.courseAttributeMappingMasterDTOList?.length == 50 ? true : false;
		} else {
			setServerData([]);
			setclassArray([]);
			setapiloader(true);
		}
	};

	const getAllrecords = (toPreviousPage = false) => {
		if (toPreviousPage) {
			let n = pagination;
			n.currentPage = n.currentPage - 1;
			setpagination(n)
		}
		fetchClasses(`${endpoint.courseAttributeMapping.getAll}/${page}`);
	}


	const fetchAllBusinessGroup = () => {
		setapiloader(true);
		getAPI(
			endpoint.groupCode.getAllActive,
			// `${masterServiceBaseUrl}/groupCode/getAllActiveGroupCode`,
			(data) => {
				setAllBusinessGpState(data['data']);
				setapiloader(false);
			},
			(data) => {
				failureToast(data['message']);
				setapiloader(false);
			}
		);
	};

	const fetchAllCompanyCode = () => {
		setapiloader(true);
		getAPI(
			endpoint.companyCode.getAllActive,
			//`${masterServiceBaseUrl}/companyCode/getAllActiveCompanyCode`,
			(data) => {
				setAllCompCodeState(data['data']);
				setapiloader(false);
			},
			(data) => {
				failureToast(data['message']);
				setapiloader(false);
			}
		);
	};

	const fetchAllCourseAttr = () => {
		setapiloader(true);
		getAPI(
			endpoint.courseAttribute.getAllActive,
			//`${masterServiceBaseUrl}/courseAttribute/getAllActiveCourseAttribute`,
			(data) => {
				setCourseAllAttrState(data['data']);
				setapiloader(false);
			},
			(data) => {
				failureToast(data['message']);
				setapiloader(false);
			}
		);
	};

	useEffect(() => {
		getAllrecords()
		fetchAllBusinessGroup();
		fetchAllCompanyCode();
		fetchAllCourseAttr();
	}, []);

	const fetchNext = async () => {
		if (!isNextCallPossible) {
			return
		}
		setapiloader(true);
		page = page + 1;
		let url = `${endpoint.courseAttributeMapping.getAll}/${page}`;
		if (searchStr) {
			url = `${endpoint.courseAttributeMapping.getAll}/${page}?courseAttributeDisplayValue=${searchStr}`
		}
		const courseAttributesData = await fetchAllPromisedData(`${endpoint.courseAttributeMapping.getAll}/${page}`, true)
		if (courseAttributesData?.code === 200 && courseAttributesData?.data?.courseAttributeMappingMasterDTOList?.length > 0) {
			const updatedData = [...serverData, ...courseAttributesData.data?.courseAttributeMappingMasterDTOList]
			setServerData(updatedData);
			getPaginatedData(updatedData, pageSize, pagination, setpagination, setapiloader, setclassArray)
			isNextCallPossible = courseAttributesData?.data?.courseAttributeMappingMasterDTOList?.length == 50 ? true : false;
		} else {
			isNextCallPossible = false;
			setapiloader(false);
		}
	}


	const searchWithParams = _.debounce(() => {

		let n = pagination;
		n.currentPage = 1;
		setpagination(n)
		fetchClasses(`${endpoint.courseAttributeMapping.getAll}/${page}?courseAttributeDisplayValue=${searchStr}`);
	}, 300)

	useEffect(() => {
		if (searchStr) {
			searchWithParams();
		} else {
			if (searchStr === '') {
				getAllrecords()
			}
		}
	}, [searchStr])


	return (
		userPermissions ?
			<>
				<CourseAttributeTable
					getAllrecords={getAllrecords}
					setclassArray={setclassArray}
					classArray={classArray}
					permissionSet={userPermissions}
					isSaveVisible={isSaveVisible}
					setisSaveVisible={setisSaveVisible}
					fetchAllBusinessGroup={allbusinessGpState}
					fetchAllCompanyCode={allCompCodeState}
					fetchAllCourseAttr={courseAllAttrState}
					searchStr={searchStr}
					setSearchStr={setSearchStr}
					apiloader={apiloader}
				/>
				{pagination.totalPage > 1 ? (
					<GetPagination
						isSaveVisible={isSaveVisible}
						fetchClasses={fetchClasses}
						setclassArray={setclassArray}
						pagination={pagination}
						setpagination={setpagination}
						pageSize={pageSize}
						state={serverData}
						isPaginationFromServer={true}
						fetchNext={fetchNext}
						isNextCallPossible={isNextCallPossible}
					/>
				)
					: null}
			</>
			:
			<PermisionDenied />
	);
};
export default CourseAttributes;

