import React from 'react'
import { Card, CardBody } from 'reactstrap'

const cardStyle = {
  boxShadow: 'none',
  backgroundColor: '#004662',
  margin: 0,
}

const titleStyle = {
  fontSize: '12px',
  fontWeight: 400,
  marginBottom: '5px',
}

const dataStyle = {
  fontSize: '20px',
  fontWeight: 600,
}

const DisplayDetailCard = ({title, data}) => {
  return (
    <Card style={cardStyle}>
      <CardBody className='px-3 py-2'>
        <div style={titleStyle}>{title}</div>
        <div style={dataStyle}>{data}</div>
      </CardBody>
    </Card>
  )
}

export default DisplayDetailCard