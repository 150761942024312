import React, { useEffect, useState } from "react"
import AButton from "views/pages/manage/common/form-fields-mui/AButton"
import AInput from "views/pages/manage/common/formFeilds/customInput/AInput"
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function"
import { updateRequest } from "views/pages/manage/edpOps/constant/common"
import SelectSearchInputParam from "views/pages/manage/edpOps/studentSerach/SelectSearchInputParam"
import { isNumber } from "views/pages/manage/gateAttendance/constant"
import { FILTER__FORM_FIELDS_GLOBAL, getDropdowns } from "./constant"
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete"
import { inputHeight } from "views/pages/manage/edpOps/stockManagement/stockTxns/SearchByOtherDetailsForm"
import { useGetCourseIdsForBUAndTerm } from "views/pages/manage/edpOps/stockManagement/customhooks"
import { useDispatch } from "react-redux"
import { getStudentList } from "redux/studentCharges/actions"
import SelectFilterChip from "./SelectFilterChip"
import { chargesStatueDropdown } from "views/pages/manage/unAssigned/studentCharges/common"
import { chargeStatusMapping } from "views/pages/manage/unAssigned/studentCharges/common"

const PaymentCollectionForm = ({ setApiLoader,  setPopoverOpen, globalFilter, setGlobalFilter }) => {

    const [checkbox, setCheckbox] = useState('psid')
    const [dropdowns, setDropdowns] = useState({})
    const [filterType,setFilterType] = useState('')
    const [selectedFilter,setSelectedFilter] = useState('SINGLE')
    const dispatch = useDispatch();

    const callback = () => {
        setGlobalFilter((prev) => (FILTER__FORM_FIELDS_GLOBAL ));
      };

    const onKeyDown = (e) => {
        if (e.key === "Enter" || e.keyCode === 13) {
            searchHandler();
        }
    };
    const validatePsIdAppId = () => {
        if (checkbox === 'psid') {
            return isNumber(globalFilter?.searchValue) && globalFilter?.searchValue.length === 11 ? true : false
        }
        else if (checkbox === 'applicationId') {
            return isNumber(globalFilter?.searchValue) && globalFilter?.searchValue.length === 8 ? true : false
        }
    }
    const searchHandler = async () => {
        if(filterType=='single'){
        if (checkbox === 'psid' && !validatePsIdAppId()) {
            failureToast('PSID should comprise of 11 digits only')
            return;
        }
        else if (checkbox === 'applicationId' && !validatePsIdAppId()) {
            failureToast("Application number should comprise of 8 digits only")
            return;
        }}
       
        try {


            dispatch(getStudentList({ ...globalFilter, [checkbox]: globalFilter?.searchValue, businessArea:filterType==='single'? dropdowns?.businessArea.map((item) => item?.value):globalFilter?.businessArea },0));
            setPopoverOpen(true);
        }

        catch (error) {
            console.log(error)
        }

    }
    const resetHandler = () => {
        setGlobalFilter({

            studentName: '',
            psid: '',
            applicationId: '',
            opportunityId: '',
            courseId: [],
            paymentStatus: [],
            chargesStatus: [],
            term: [],
            businessArea: [],
            region: [],
            searchField: 'psid',
            chargesStatus:[]

        })
    }
    const formHandler = (key, value) => {
        setGlobalFilter(prev => ({ ...prev, [key]: value, searchValue: '' }))
    }
    const [courseIdDropdown, courseIdDropdownLoading] = useGetCourseIdsForBUAndTerm({
        businessArea: globalFilter?.businessArea?.[0],
        term: globalFilter?.term?.[0]
    })
    const getDropdown = async () => {
        setApiLoader(true);
        const res = await getDropdowns()
        setDropdowns(res)
        setApiLoader(false);
    }
    useEffect(() => {
        getDropdown();
    }, [])
    const styles = {
        display: 'grid',
        gridTemplateColumns: 'repeat(4,minmax(120px, 1fr)) minmax(130px, 1fr)minmax(50px, 1fr)',
        gap: '16px',
        alignItems: 'center',
    };
    const applicationStatusDropdown = chargesStatueDropdown.map(item => ({...item , label : `${item.label} (${chargeStatusMapping[item.label]})`}));
    return (
        <>
        <SelectFilterChip
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        cb={callback}
      />
            <SelectSearchInputParam
                searchInputParam={checkbox}
                isHideName={true}
                width="200px"
                setSearchInputParam={(value) => {
                    value!==checkbox&&setGlobalFilter(prev => ({ ...prev, searchValue: '' }))
                    setCheckbox(value)
                }}
            />
            <div style={{ width: 'fit-content' }}>
                <AInput
                    disabled={selectedFilter !== "SINGLE"}
                    placeholder={`Search by ${checkbox === 'psid' ? 'PSID' : 'Application ID'}`}
                    style={{ height: "45px", width: "535px", marginTop: "4px" }}
                    value={globalFilter?.searchValue}
                    handler={(value) => {setGlobalFilter((prev) => ({ ...FILTER__FORM_FIELDS_GLOBAL, searchValue: value })); setFilterType('single')}}
                    onKeyDown={(e) => {
                        onKeyDown(e);
                    }}
                    crossIcon={true}
                    rightSearchIcon={true}
                    searchIconClickHandler={() => {
                        searchHandler();
                    }}
                    crossHandler={async () => {
                        setGlobalFilter(prev => ({ ...prev, searchValue: '' }));

                    }}
                    onKeyPress={(e) => {
                        if (
                            !/^[0-9]*$/.test(
                                String.fromCharCode(e.charCode)
                            )
                        ) {
                            e.preventDefault();
                            return;
                        }
                    }
                    }
                    maxLength={checkbox  === 'psid' ? 11 : 8}

                />
            </div>
            <div style={styles} className="mt-4 mb-2">
                <div>
                    <AAutoComplete
                        label="Business Area*"
                        height={inputHeight}
                        placeholder="Business Area"
                        isMulti={false}
                        // isShowCount={true}
                        items={dropdowns?.businessArea ?? []}
                        currentValue={globalFilter?.businessArea}
                        handleChange={(value) => {
                            formHandler("businessArea", [value]);
                            formHandler("courseId", [])
                            setFilterType('bulk')
                        }}
                        isDisabled={selectedFilter !== "MASTER"}
                    />
                </div>

                <div>
                    <AAutoComplete
                        label="Term*"
                        height={inputHeight}
                        placeholder="Term"
                        // items={dropdown?.terms}
                        // isShowCount={true}
                        isMulti={false}
                        items={dropdowns?.term ?? []}
                        currentValue={globalFilter?.term}
                        handleChange={(value) => {
                            formHandler("term", [value]);
                            formHandler("courseId", []);
                            setFilterType('bulk')
                        }}
                        isDisabled={selectedFilter !== "MASTER"}
                    />
                </div>

                <div>
                    <AAutoComplete
                        height={inputHeight}
                        label="Course Id*"
                        placeholder="Course Id"
                        isMulti={true}
                        isShowCount={true}
                        items={courseIdDropdown}
                        currentValue={globalFilter?.courseId}
                        handleChange={(value) => { formHandler("courseId", value); setFilterType('bulk') }}
                        selectAllMenuButton={true}
                        isLoading={courseIdDropdownLoading}
                        isDisabled={selectedFilter !== "MASTER"}
                    />
                </div>
                <div>
                    <AAutoComplete
                        height={inputHeight}
                        label="Charge status"
                        placeholder="Charge status"
                        isMulti={true}
                        isShowCount={true}
                        items={applicationStatusDropdown}
                        currentValue={globalFilter?.chargeStatus}
                        handleChange={(value) => { formHandler("chargesStatus", value); setFilterType('bulk') }}
                        selectAllMenuButton={true}
                        isDisabled={selectedFilter !== "MASTER"}
                    />
                </div>
                <div className="d-flex mt-4">
                    <AButton
                        variant={"primary_filled"}
                        onClick={() => { searchHandler() }}
                        disabled={selectedFilter !== "MASTER"||!globalFilter?.businessArea.length||!globalFilter?.term.length||!globalFilter?.courseId.length}
                    >
                        Next
                    </AButton>
                    <AButton
                        variant={"outline"}
                        onClick={() => { resetHandler() }}
                        className=""
                        disabled={selectedFilter !== "MASTER"}
                    >
                        Reset
                    </AButton>
                </div>


            </div>


        </>
    )
}
export default PaymentCollectionForm