import { templateBaseurl } from 'services/http';
export const messageServiceEndpoints = {

	getTemplatesByRole : `${templateBaseurl}/getTemplateRoleMapping`,
    updateMapping :  `${templateBaseurl}/updateTemplateRoleMapping`,
    allCategory : `${templateBaseurl}/getAllTemplateCategory`,
    	///destination
	destinationType: {
		getAll: `${templateBaseurl}/getAllTemplateDestinationType`
	}
};
