import React, { Fragment, useState, useEffect, useRef } from 'react';
import {
  Card,
  CardHeader,
  Container,
  CardBody,
  Input,
  Table,
  Button,
} from 'reactstrap';
import {
  getAPI,
  putpost,
  adminServiceBaseUrl,
} from '../../../../../services/http';
import Loader from 'react-loader-spinner';
import style from './configRefresh.module.scss';
import moment from 'moment';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';


var _ = require('lodash');

const ConfigRefresh = () => {
  const [sericesData, setServicesData] = useState([]);
  const [objectKeys, setObjectKeys] = useState();
  const [objectKeysShow, setObjectKeysShow] = useState();
  const [content, setContent] = useState();
  const [searchInput, setSearchInput] = useState('');
  const [disabledServiceBtn, setDisabledServiceBtn] = useState({
    disable: false,
    index: null,
  });
  const [service, setService] = useState('');
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    fetchServicesDetails();
  }, []);

  const fetchServicesDetails = () => {
    getAPI(
      `${adminServiceBaseUrl}/instances`,
      (data) => {
        let serviceInfo = {}
        data.data.map(service => {
          const serviceName = service?.instanceId?.split(":")?.[1]?.toLowerCase();
          if(serviceName !== 'adminservice'){
            serviceInfo = {
              ...serviceInfo, [serviceName]: {
                serviceDisplayName: serviceName,
                lastRefreshTime: new Date(service?.instanceInfo?.lastUpdatedTimestamp),
                enabled: 'true',
                disable: false
              }
            }
          }
        })

        setServicesData(serviceInfo);
        setObjectKeys(Object.keys(serviceInfo).sort());
        setObjectKeysShow(Object.keys(serviceInfo).sort());
      },
      (data) => {
        failureToast(data['message']);
      }
    );
  };

  const refreshServiceHandler = (serviceName) => {
    putpost(
      `${adminServiceBaseUrl}/refresh/service`,
      (data) => {
        // let arr = eval(data['data']['data']);
        setContent(data.data);
        setDisabledServiceBtn((pre) => ({
          ...pre,
          disable: false,
        }));
        fetchServicesDetails();
        setSearchInput('');
        setService(serviceName);
      },
      (data) => {
        failureToast(data['error']);
        delete sericesData[serviceName].disable;
        setDisabledServiceBtn({
          disable: false,
          index: null,
        });
        setIsChanged(!isChanged);
        setContent({});
      },
      { serviceName: serviceName },
      'post'
    );
  };

  const searchHandler = _.debounce((searchService) => {
    try {
      if (searchService.length > 0) {
        const filterList = objectKeys.filter((data) =>
          data.toUpperCase().includes(searchService.toUpperCase())
        );
        setObjectKeysShow(filterList);
      } else {
        setObjectKeysShow(objectKeys);
      }
    } catch (e) {
      console.log('Error ::', e);
    }
  }, 300);

  return (
    <Fragment>
      <Container>
        <Card className='student_service_card mt-3'>
          <CardHeader className={style.fixed_header}>
            <div>
              <div>
                <h3>Config Refresh</h3>
              </div>
            </div>
            <div className='header_search'>
              <Input
                maxLength='12'
                name='serviceName'
                placeholder='Service name'
                value={searchInput}
                onChange={(e) => {
                  let service = e.target.value;
                  searchHandler(service);
                  setSearchInput(service);
                }}
              />
            </div>
          </CardHeader>
          <CardBody className={style.card_body}>
            <div id='questionTable' className='table-responsive'>
              <Table className='align-items-center table-flush tableLayout'>
                <thead className='thead-light'>
                  <th className='text-center white-breakSpace'>Service Name</th>
                  <th className='text-center white-breakSpace'>
                    Last Refreshed
                  </th>
                  <th className='text-center white-breakSpace'>Action</th>
                </thead>
                <tbody className='list'>
                  {objectKeysShow?.map((item, index) => (
                    <Fragment>
                      <tr key={index + '-class'}>
                        <td className='text-center'>
                          {sericesData[item]['serviceDisplayName']}
                        </td>
                        <td className='text-center'>
                          {sericesData[item]['lastRefreshTime']
                            ? moment(
                              sericesData[item]['lastRefreshTime']
                            ).format('DD-MM-YYYY hh:mm:ss A')
                            : 'NOT YET REFRESHED'}
                        </td>
                        <td className='text-center'>
                          {sericesData[item]['enabled'] === 'true' ? (
                            <Button
                              color='success'
                              size='md'
                              type='button'
                              className={`mx-1 ${style.btn_refresh}`}
                              disabled={disabledServiceBtn['disable']}
                              onClick={() => {
                                sericesData[item].disable = true;
                                setDisabledServiceBtn({
                                  disable: true,
                                  index: index,
                                });
                                refreshServiceHandler(item);
                              }}
                            >
                              Refresh
                              {sericesData[item]?.disable ? (
                                <Loader
                                  style={{ right: '10px' }}
                                  className='button-Loader'
                                  type='BallTriangle'
                                  color='#ffffff'
                                  height={30}
                                  width={30}
                                />
                              ) : null}
                            </Button>
                          ) : (
                            <Button
                              color='success'
                              size='md'
                              type='button'
                              className={`mx-1 ${style.btn_refresh}`}
                              disabled={true}
                            >
                              Refresh Disabled
                            </Button>
                          )}
                        </td>
                      </tr>
                      {service === item ? (
                        <tr>
                          <td
                            colspan='3'
                            style={{
                              border: 'none',
                              // padding: '0 3rem 1rem 3rem',
                              cursor: 'pointer',
                              color: '#2dce89',
                            }}
                          >
                            <Table className='align-items-center table-flush tableLayout'>
                              <thead className=''>
                                <th className='white-breakSpace' style={{ borderBottom: 'none' }}>Refreshed Instances</th>
                                <th className='white-breakSpace' style={{ borderBottom: 'none' }}>
                                  Properties Refreshed
                                </th>
                                <th className='white-breakSpace' style={{ borderBottom: 'none' }}>
                                  <span
                                    onClick={() => {
                                      setService('');
                                    }}
                                  >
                                    <i
                                      class='far fa-times-circle'
                                      style={{ position: 'absolute', right: '77px' }}
                                    ></i>{' '}
                                  </span>
                                </th>
                              </thead>
                              <tbody>

                                {Object.keys(content)?.map((item, index) => {
                                  if (content[item]) {
                                    return <tr>
                                      <td className={style.refresh_properties} style={{ borderTop: 'none' }}>
                                        {index + 1 + '. ' + item}
                                      </td>
                                      <td className={style.refresh_properties} style={{ borderTop: 'none' }}>
                                        {content[item]}
                                      </td>
                                    </tr>
                                  }
                                })}
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      ) : null}
                    </Fragment>
                  ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default ConfigRefresh;

