import React, { useContext } from 'react'
import styles from './academicPlansList.module.css'
import commonStyles from '../common/commonStyles.module.css'
import { ReactComponent as TimeTableHeaderImage } from '../../../../../assets/img/svg/TimeTableHeaderImage.svg'
import { useHistory } from 'react-router'
import { pages } from '../../common/constant'
import { PermissionContext, RolePermissions } from 'appContext'

const HeaderComponent = () => {
  const history = useHistory();
  const createAcademicPlanUserPermissions = RolePermissions(useContext(PermissionContext), pages['createAcademicPlan']['id']);


  const clickHandler = (e) => {
    if (e?.ctrlKey) {
      window.open(history.createHref({ pathname: `/admin/testScheduling/createAcademicPlan` }), '_blank')
    } else {
      history.push(`/admin/testScheduling/createAcademicPlan`)
    }
  }

  return (
    <div className={styles.header_div}>
      <div className='d-flex flex-column align-items-start justify-content-between' style={{ flex: 1, height: '100%' }}>
        <div>
          <h3 style={{ fontSize: '28px', fontWeight: 700, color: 'inherit' }}>All Academic Plans</h3>
          <span style={{ color: '#CCEFFD' }}>Here you can find all the Academic Plans created under all academic groups - SOE, SOM and SOF.</span>
        </div>
        {createAcademicPlanUserPermissions && <span className={commonStyles.primary_filled_button} onClick={clickHandler}>Create new academic plan</span>}
      </div>
      <TimeTableHeaderImage />
    </div>
  )
}

export default HeaderComponent