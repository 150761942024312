import { PermissionContext, RolePermissions } from "appContext";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { getAPI, masterServiceBaseUrl } from 'services/http';
import { PermisionDenied } from "../../common/commonComponents";
import CustomLoader from "../../common/commonComponents/Loader/loader";
import { pages } from "../../common/constant";
import { GetPagination } from "../../common/commonComponents/pagination/pagination";
import DistrictTable from './districtTable';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';

const District = () => {
  const [classArray, setclassArray] = useState([])
  const [apiloader, setapiloader] = useState(false)
  const [district, setDistrict] = useState([])
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([]);
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [tableData, setTableData] = useState([])
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null
  });

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["District"]['id']);

  const pageSize = 10
  const fetchClasses = async (url) => {
    setapiloader(true)
    getAPI(url, (data) => {
      setDistrict(data['data']);
      setTableData(data['data']);
      let n = pagination
      if (data['data'].length > pageSize) {
        n['nextPage'] = n.currentPage + 1
      }
      if (n.currentPage > 1) {
        n.previousPage = n.currentPage - 1
      }
      n['totalPage'] = Math.floor(data['data'].length / pageSize)
      if (data['data'].length % pageSize != 0) {
        n['totalPage'] += 1
      }
      setpagination(n)
      setapiloader(false)
      setclassArray(data['data'].slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || [])
    }, (data) => { failureToast(data['message']) })
  }


  const fetchDropdown = async (url, droptype) => {
    setapiloader(true)
    getAPI(url, (data) => {
      const dropList = data.data.map(data => {
        if (droptype === 'country') {
          const { id, countryDispValue, status } = data;
          if (status === 'ACTIVE') {
            return { id: id, text: countryDispValue };
          }
        }
        if (droptype === 'state') {
          const { id, stateDispValue, status } = data;
          if (status === 'ACTIVE') {
            return { id: id, text: stateDispValue };
          }
        }

        return
      }
      );

      droptype === 'country' ? setCountries(dropList) : setStates(dropList)
    }, (data) => { failureToast(data['message']) })
  }

  useEffect(() => {
    fetchClasses(masterServiceBaseUrl + '/district/getAllDistrict')
    fetchDropdown(masterServiceBaseUrl + '/country/getAllActiveCountry', 'country')
    fetchDropdown(masterServiceBaseUrl + '/state/getAllActiveState', 'state')
  }, [])


  const refreshList = () => {
    fetchClasses(masterServiceBaseUrl + '/district/getAllDistrict')
  }

  const previousPage = () => {
    if (pagination.currentPage != 1) {
      setclassArray(
        states.slice(
          (pagination.currentPage - 1) * pageSize - pageSize,
          (pagination.currentPage - 1) * pageSize
        )
      );
      let n = pagination;
      n["currentPage"] = n["currentPage"] - 1;
      if (n["currentPage"] == 1) {
        n["previousPage"] = null;
      } else {
        n["previousPage"] = n.currentPage;
      }
      setpagination(n);
    }
  };

  return (
    <>
      {apiloader ? <CustomLoader
        apiLoader={apiloader}
        loaderHeight={'200px'}
        loaderWidth={'100% '}
      /> :
        <Fragment>
          {userPermissions ? <>
            <DistrictTable
              userPermissions={userPermissions}
              fetchClasses={fetchClasses}
              setclassArray={setclassArray}
              classArray={classArray}
              countryList={countries}
              stateList={states}
              district={district} setDistrict={setDistrict}
              tableData={tableData}
              pagination={pagination}
              setpagination={setpagination}
              isSaveVisible={isSaveVisible}
              setisSaveVisible={setisSaveVisible}
              refreshList={refreshList}
              previousPage={previousPage}
            />
            {pagination.totalPage > 1 ?
              <GetPagination
                isSaveVisible={isSaveVisible}
                fetchClasses={()=>fetchClasses(masterServiceBaseUrl + '/district/getAllDistrict')}
                setclassArray={setclassArray}
                pagination={pagination}
                setpagination={setpagination}
                pageSize={pageSize}
                state={district}
              />
              : null}
          </> : <PermisionDenied />}
        </Fragment>
      }
    </>
  );
}
export default District;