import React, { useState, useEffect } from 'react';
import {
  BusinessAreaPlanHeader,
  BUSINESSAREAPLANTHROW,
} from '../../../common/commonComponents/masterRow/masterRow';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Table,
  Input,
  Label,
} from 'reactstrap';
import Select2 from 'react-select2-wrapper';
import { GetPagination } from '../../../common/pagination';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import CustomDatePicker from '../../../common/commonComponents/CustomDatePicker/index';

import {
  getAPI,
  masterServiceBaseUrl,
  successToast,
  failureToast,
  putpost,
  putpost2,
  deleteAPI,
} from 'services/http';

var _ = require('lodash');

const BusinessAreaPlan_2 = (props) => {
  const {
    academicCareer,
    getDisplayValue,
    action,
    businessUnit,
    businessAreaAndPlan,
    setBusinessAreaAndPlan,
    businessPlanData,
    isDisabled
  } = props;
  const [isSaveVisible, setIsSaveVisible] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [state, setState] = useState([]);
  const [dataChange, setDataChange] = useState(false);
  const [tableDataKey, settableDataKey] = useState('tableDataKey');

  const [pagination, setPagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const pageSize = 10;

  useEffect(() => {
    if ( (action === 'edit' || action === 'view')  && businessPlanData) {
     performPaginationForData();
    }
  }, []);

  const performPaginationForData = () => {
  
    try {
      setApiLoader(true);
        setState(businessPlanData);
        let n = pagination;
        if (businessPlanData.length > pageSize) {
          n['nextPage'] = n.currentPage + 1;
        }
        if (n.currentPage > 1) {
          n.previousPage = n.currentPage - 1;
        }
        n['totalPage'] = Math.floor(businessPlanData.length / pageSize);
        if (businessPlanData.length % pageSize != 0) {
          n['totalPage'] += 1;
        }
        setPagination(n);
        setApiLoader(false);
        setBusinessAreaAndPlan(
          businessPlanData.slice(
            pagination.currentPage * pageSize - pageSize,
            pagination.currentPage * pageSize
          ) || []
        );
    
    } catch ( e ) {
      console.log('Error ::',e)  
    }
  }

  const fetchClasses = async (url) => {
    setApiLoader(true);
    getAPI(
      url,
      (data) => {
        setState(data['data']);
        let n = pagination;
        if (data['data'].length > pageSize) {
          n['nextPage'] = n.currentPage + 1;
        }
        if (n.currentPage > 1) {
          n.previousPage = n.currentPage - 1;
        }
        n['totalPage'] = Math.floor(data['data'].length / pageSize);
        if (data['data'].length % pageSize != 0) {
          n['totalPage'] += 1;
        }
        setPagination(n);
        setApiLoader(false);
        setBusinessAreaAndPlan(
          data['data'].slice(
            pagination.currentPage * pageSize - pageSize,
            pagination.currentPage * pageSize
          ) || []
        );
      },
      (data) => {
        failureToast(data['message']);
        setApiLoader(false);
      }
    );
  };

  const nextPage = () => {
    setBusinessAreaAndPlan(
      state.slice(
        (pagination.currentPage + 1) * pageSize - pageSize,
        (pagination.currentPage + 1) * pageSize
      )
    );
    let n = pagination;
    n['currentPage'] = n['currentPage'] + 1;
    n['previousPage'] = n.currentPage;
    setPagination(n);
  };
  const firstPage = () => {
    setBusinessAreaAndPlan(state.slice(1 * pageSize - pageSize, 1 * pageSize));
    let n = pagination;
    n['currentPage'] = 1;
    n['previousPage'] = null;
    setPagination(n);
  };
  const lastPage = () => {
    setBusinessAreaAndPlan(
      state.slice(
        pagination.totalPage * pageSize - pageSize,
        pagination.totalPage * pageSize
      )
    );
    let n = pagination;
    n['currentPage'] = n.totalPage;
    n['previousPage'] = n.totalPage - 1;
    setPagination(n);
  };
  const previousPage = () => {
    if (pagination.currentPage != 1) {
      setBusinessAreaAndPlan(
        state.slice(
          (pagination.currentPage - 1) * pageSize - pageSize,
          (pagination.currentPage - 1) * pageSize
        )
      );
      let n = pagination;
      n['currentPage'] = n['currentPage'] - 1;
      if (n['currentPage'] == 1) {
        n['previousPage'] = null;
      } else {
        n['previousPage'] = n.currentPage;
      }
      setPagination(n);
    }
  };

  const addNewClass = () => {
    setBusinessAreaAndPlan([
      {
        editMode: true,
        __status: 'new',
        businessAreaId: businessUnit,
        sapPlantId: null,
        sapBusinessArea: null,
        sapBusinessPlace: null,
        academicCareerId: null,
        defermentBusinessArea: null,
        effectiveDate: null,
        status: 'ACTIVE',
      },
      ...businessAreaAndPlan,
    ]);
  };

  const saveAll = (cb) => {
    try {
      var _tempArray = businessAreaAndPlan;
      var isValid = true;
      _tempArray.forEach((el, index) => {
        if (el['editMode']) {
          if (el['businessAreaId'] === null) {
            isValid = false;
            failureToast('Business Area ID not found');
            return;
          } else if (el['sapPlantId'] === null || !el['sapPlantId'].trim()) {
            isValid = false;
            failureToast('Please enter the value of SAP Plant ID');
            return;
          }
          // if (el['sapPlantId'] === null || !el['sapPlantId'].trim()) {
          //   isValid = false;
          //   failureToast('Please enter the value of SAP Plant ID');
          //   return;
          // }
          else if (
            el['sapBusinessArea'] === null ||
            !el['sapBusinessArea'].trim()
          ) {
            isValid = false;
            failureToast('Please enter the value of SAP Business Area');
            return;
          } else if (
            el['sapBusinessPlace'] === null ||
            !el['sapBusinessPlace'].trim()
          ) {
            isValid = false;
            failureToast('Please enter the value of SAP Business Place');
            return;
          } else if (el['academicCareerId'] === null) {
            isValid = false;
            failureToast('Please Select Academic Career ID');
            return;
          } else if (
            el['defermentBusinessArea'] === null ||
            !el['defermentBusinessArea'].trim()
          ) {
            isValid = false;
            failureToast('Please enter the value of Deferment Business Area');
            return;
          }

          if (!isValid) {
            return;
          }
          if (_tempArray[index]['__status'] == 'new') {
            setApiLoader(true);
            putpost(
              masterServiceBaseUrl + '/businessAreaAndPlan/createBusinessAreaAndPlan',
              (data) => {
                successToast(data['message']);
                settableDataKey(new Date().getMilliseconds());
                // fetchClasses(
                //   masterServiceBaseUrl + '/businessAreaAndPlan/getAllBusinessAreaAndPlan'
                // );
                fetchClasses(
                  masterServiceBaseUrl +
                    `/businessAreaAndPlan/getBusinessAreaAndPlanByBusinessAreaId/${businessUnit}`
                );
                setIsSaveVisible(false);
                setApiLoader(false);
              },
              (data) => {
                failureToast(data['message']);
                setApiLoader(false);
              },
              _tempArray[index],
              'post'
            );
          } else {
            setApiLoader(true);
            putpost(
              masterServiceBaseUrl +
                '/businessAreaAndPlan/updateBusinessAreaAndPlan/' +
                _tempArray[index].id,
              (data) => {
                successToast(data['message']);
                fetchClasses(
                  masterServiceBaseUrl +
                    `/businessAreaAndPlan/getBusinessAreaAndPlanByBusinessAreaId/${businessUnit}`
                );
                setIsSaveVisible(false);
                delete _tempArray[index]['editMode'];
                setBusinessAreaAndPlan(_tempArray);
                settableDataKey(new Date().getMilliseconds());
                setApiLoader(false);
              },
              (data) => {
                failureToast(data['message']);
                setApiLoader(false);
              },
              _tempArray[index],
              'put'
            );
          }
        }
      });
    } catch (e) {
      console.log('Error', e);
    }
  };

  const newRow = () => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        addNewClass();
        setIsSaveVisible(true);
      };
      var _tempArray = businessAreaAndPlan;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      setBusinessAreaAndPlan(_tempArray);
      setIsSaveVisible(false);
      cb();
    }
  };

  const updateRecord = (index, value, property) => {
    try {
      let _tempArray = businessAreaAndPlan;
      _tempArray[index][property] = value;
      setBusinessAreaAndPlan(_tempArray);
    } catch (e) {
      console.log('Error', e);
    }
  };

  const closeRecord = (index) => {
    try {
      let n = businessAreaAndPlan;
      if (n[index]['__status'] == 'new') {
        n.splice(index, 1);
      } else {
        // n[index]['editMode']=false
        fetchClasses(
          masterServiceBaseUrl +
            `/businessAreaAndPlan/getBusinessAreaAndPlanByBusinessAreaId/${businessUnit}`
        );
      }
      setBusinessAreaAndPlan(n);
      setIsSaveVisible(false);
    } catch (e) {
      console.log('Error', e);
    }
  };

  const editRecord = (index) => {
    try {
      if (isSaveVisible) {
        failureToast('Please Delete or Save current changes');
      } else {
        let cb = () => {
          var _tempArray = businessAreaAndPlan;
          _tempArray[index]['editMode'] = true;
          setBusinessAreaAndPlan(_tempArray);
          setIsSaveVisible(true);
        };
        var _tempArray = businessAreaAndPlan;
        _tempArray = _.sortBy(_tempArray, [
          function (o) {
            return o.value;
          },
        ]);
        cb();
      }
    } catch (e) {
      console.log('Error : ', e);
    }
  };

  const deleteRecord = (index) => {
    try {
      if (businessAreaAndPlan[index]['__status'] == 'new') {
        let n = businessAreaAndPlan;
        n.splice(index, 1);
        setBusinessAreaAndPlan(n);
        setIsSaveVisible(false);
        settableDataKey(new Date().getMilliseconds());
      } else {
        if (
          window.confirm(
            'Are you sure you want to delete this Business Area & Plan ?'
          )
        ) {
          putpost2(
            masterServiceBaseUrl +
              '/businessAreaAndPlan/deleteBusinessAreaAndPlan/' +
              businessAreaAndPlan[index].id,
            (data) => {
              successToast(data['message']);
              if (businessAreaAndPlan.length == 1) {
                previousPage();
                // fetchClasses(
                //   masterServiceBaseUrl + '/businessAreaAndPlan/getAllBusinessAreaAndPlan'
                // );
                fetchClasses(
                  masterServiceBaseUrl +
                    `/businessAreaAndPlan/getBusinessAreaAndPlanByBusinessAreaId/${businessUnit}`
                );
              } else {
                var _tempArray = businessAreaAndPlan;
                if (_tempArray[index]['editMode']) {
                  setIsSaveVisible(false);
                }
                _tempArray.splice(index, 1);
                setBusinessAreaAndPlan(_tempArray);
              }
              settableDataKey(new Date().getMilliseconds());
            },
            (data) => {
              failureToast(data['message']);
            },
            'Delete'
          );
        }
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  useEffect(() => {
    if(props.isPrevious && props.formId){
      setApiLoader(true);
      fetchClasses(`${masterServiceBaseUrl}/businessAreaAndPlan/getBusinessAreaAndPlanByBusinessAreaId/${props.formId}`)
   }
  }, [props.isPrevious,props.formId])

  useEffect(()=>{
    return()=>{
      if(state.length){
        !props.isPrevious && props.isNew && props.saveStepsCompleted()
      }
    }    
  },[state])

  return (
    <Container className="mt-3" fluid>
      <Row>
        <Col className={`mb-3 ${action === 'new' ? '' :'mt-3'}`}>
          { !isDisabled ? (
            <Button
              disabled={isSaveVisible}
              color="info"
              size="sm"
              type="button"
              className={
                'floatRight mx-1' + (isSaveVisible ? ' btn-dark' : null)
              }
              onClick={() => {
                newRow();
              }}
            >
              Add SAP Business Area
            </Button>
          ) : null}
        </Col>
      </Row>
      {apiLoader ? (
        <div className="mx-auto text-center" style={{ height: '100px' }}>
          <Loader
            type="Rings"
            color="#00BFFF"
            height={70}
            width={70}
            // timeout={10000}
          />
        </div>
      ) : (
        <div id="questionTable" className="table-responsive">
          <Table className="align-items-center table-flush">
            {businessAreaAndPlan.length > 0 ? (
              <BusinessAreaPlanHeader isDisabled={isDisabled} action={action} />
            ) : (
              ''
            )}
            <tbody className="list" key={tableDataKey}>
              {businessAreaAndPlan
                ? businessAreaAndPlan.map((item, index) =>
                    !item['editMode'] ? (
                      <BUSINESSAREAPLANTHROW
                        key={index + '_class'}
                        action={action}
                        deleteRecord={deleteRecord}
                        getDisplayValue={getDisplayValue}
                        item={item}
                        editRecord={editRecord}
                        index={index}
                      closeRecord={ closeRecord }
                      isDisabled={isDisabled}
                      />
                    ) : (
                      <tr key={index + '-class'}>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={50}
                            defaultValue={item['sapPlantId']}
                            onChange={(e) => {
                              updateRecord(index, e.target.value, 'sapPlantId');
                            }}
                            placeholder="SAP Plant ID"
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={50}
                            defaultValue={item['sapBusinessArea']}
                            onChange={(e) => {
                              updateRecord(
                                index,
                                e.target.value,
                                'sapBusinessArea'
                              );
                            }}
                            placeholder="SAP Business Area"
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={50}
                            defaultValue={item['sapBusinessPlace']}
                            onChange={(e) => {
                              updateRecord(
                                index,
                                e.target.value,
                                'sapBusinessPlace'
                              );
                            }}
                            placeholder="SAP Business Place"
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Select2
                            data={academicCareer}
                            className="tdSelect"
                            options={{ placeholder: 'Select...' }}
                            value={item.academicCareerId}
                            onChange={(e) => {
                              let n = e.target.value;
                              updateRecord(index, n, 'academicCareerId');
                            }}
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={50}
                            defaultValue={item['defermentBusinessArea']}
                            onChange={(e) => {
                              updateRecord(
                                index,
                                e.target.value,
                                'defermentBusinessArea'
                              );
                            }}
                            placeholder="Deferment Business Area"
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <CustomDatePicker 
                              disabled={true}
                              value={
                                  item["__status"] == "__new"
                                  ? null
                                  : moment(item["effectiveDate"]).toDate()
                              }
                              placeholder="Example:01-01-2020"
                          />
                          {/* <Input
                            disabled={true}
                            defaultValue={
                              item['__status'] == 'new'
                                ? null
                                : moment(item['effectiveDate']).format(
                                    'DD-MM-YYYY'
                                  )
                            }
                            placeholder="Example:01-01-2020"
                            className="tdInput"
                          /> */}
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Label className="custom-toggle mx-auto ml-2">
                            <Input
                              checked={item.status == 'ACTIVE' ? true : false}
                              type="checkbox"
                              onChange={(e) => {
                                settableDataKey(new Date().getMilliseconds());
                                let n = 'ACTIVE';
                                if (!e.target.checked) {
                                  n = 'INACTIVE';
                                }
                                updateRecord(index, n, 'status');
                                setDataChange(!dataChange);
                              }}
                            />
                            <span
                              style={{ width: '72px' }}
                              className="custom-toggle-slider rounded-circle activeToggle"
                              data-label-off="Inactive"
                              data-label-on="Active"
                            />
                          </Label>
                        </td>
                        <td>
                          {isSaveVisible ? (
                            <>
                              <Button
                                size="sm"
                                color="info"
                                onClick={() => saveAll()}
                              >
                                Save
                              </Button>
                              <Button
                                size="sm"
                                color="danger"
                                onClick={() => closeRecord(index)}
                              >
                                <i className="fas fa-times" />
                              </Button>
                            </>
                          ) : (
                            <Button
                              color="info"
                              size="sm"
                              type="button"
                              onClick={() => {
                                deleteRecord(index);
                              }}
                            >
                              <i className="fas fa-trash" />
                            </Button>
                          )}
                        </td>
                      </tr>
                    )
                  )
                : null}
            </tbody>
          </Table>

          {pagination.totalPage > 1 ? (
            <GetPagination
              fetchClasses={fetchClasses}
              pagination={pagination}
              previousPage={previousPage}
              firstPage={firstPage}
              lastPage={lastPage}
              nextPage={nextPage}
              setpagination={setPagination}
            />
          ) : null}
        </div>
      )}
    </Container>
  );
};

export default BusinessAreaPlan_2;
