import React from 'react';
import { Col, FormGroup, Input, Label, Row} from "reactstrap";
import Select2 from "react-select2-wrapper";
import {
    RedAstric
} from "../../../../common/utils/methods/commonMethods/utilityMethod";
import CommonInput from "../../../../common/formFeilds/input/commonInput";
const AgentDetails =(props)=>{
    const { action,isEdit,admissionAgent,formData, UpdateFormData,studentId,label,placeholder } = props;

    return(
        <div className='container-fluid'>
            <Row>
                <Col md={4}>
                    <CommonInput label={label.agentType} placeHolder={placeholder.agentType} defaultValue={formData['admissionAgentType']}
                                 type={'reactSelect'}
                                 data={admissionAgent}
                                 isDisabled={(action ==='view' && !studentId) ||  (!isEdit && action === 'view' && studentId !==null) }
                                 onChange={(value)=>{UpdateFormData(4, 'admissionAgentType', value);}}
                    />
                </Col>

                <Col md={4}>
                    <CommonInput label={label.agentId} placeHolder={placeholder.agentId} defaultValue={formData['admissionAgentId']}
                                 type={'text'}
                                 restrictSpecialCharacter={true}
                                 allowedSomeSpecialCharacters={true}
                                 regEx={/[^0-9a-zA-Z ]/g}
                                 maxLength={25}
                                 mandatory={!(formData['admissionAgentType'] ==='')}
                                 isDisabled={formData['admissionAgentType'] ==='' || (action ==='view' && !studentId) ||  (!isEdit && action === 'view' && studentId !==null)}
                                 onChange={(value)=>{UpdateFormData(4, 'admissionAgentId', value);}}
                    />
                </Col>
                <Col md={4}>

                    <CommonInput label={label.agentName} placeHolder={placeholder.agentName} defaultValue={formData['admissionAgentName']}
                                 type={'text'}
                                 restrictSpecialCharacter={true}
                                 allowedSomeSpecialCharacters={true}
                                 regEx={/[^a-zA-Z .]/g}
                                 maxLength={100}
                                 mandatory={!(formData['admissionAgentType'] ==='')}
                                 isDisabled={formData['admissionAgentType'] === '' || (action ==='view' && !studentId) ||  (!isEdit && action === 'view' && studentId !==null)}
                                 onChange={(value)=>{UpdateFormData(4, 'admissionAgentName', value);}}
                    />

                </Col>
            </Row>
        </div>
    )
}
export default AgentDetails;
