import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Box,
  Checkbox,
  Typography,
  Link,
  FormControlLabel,
} from "@mui/material";
import { Card, CardBody, Col, Row, Form } from "reactstrap";
import { fetchAllPostPromisedData } from "../../common/utils/methods/commonMethods/utilityMethod.js";
import { endpoint } from "../../common/constant";
import CommonInput from "../../common/formFeilds/input/commonInput";
import "./index.scss";
import { useHistory, useParams } from "react-router-dom";
import AInput from "views/pages/manage/common/formFeilds/customInput/AInput";
import AAutoComplete from "../../common/form-fields-mui/AAutoComplete";
import {
  failureToast,
  successToast,
} from "../../common/utils/methods/toasterFunctions/function.js";
import { ReactComponent as BackArrow } from "assets/img/svg/BackArrow.svg";
import { fetchAllPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import DepositSlipHeader from "./depositSlipHeader.js";

const inputStyles = {
  height: "38px",
  borderRadius: "6px",
  fontSize: "14px",
  marginBottom: "30px",
  padding: "20px 12px",
};

const SlipDetailsForm = () => {
  let history = useHistory();
  const { id } = useParams();
  const [courseCategory, setCourseCategory] = useState({
    fetching: false,
    data: [],
  });
  const [editFormData, setEditFormData] = useState();
  const [loading, setLoading] = useState(true);
  const [postData, setPostData] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isNew, setNew] = useState(false);
  const [paymentMode, setPaymentModes] = useState([]);
  const [formData, setFormData] = useState({
    dueDay: "",
    maximumPaymentLimit: "",
    paymentModeMaster: [],
    balanceAmount: "",
    courseCategoriesNotAllowed: [],
    termLimit: "",
    nominalDifferenceAmount: "",
    smsEnabled: false,
    emailEnabled: false,
    whatsappEnabled: false,
  });
  const inputHandler = (value, key) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const formHandler = (value, key) =>
    setFormData((prev) => ({ ...prev, [key]: value }));

  const handleFormData = (e) => {
    const { name, value } = e.target;
    setFormData((preV) => ({
      ...preV,
      [name]: value,
    }));
  };
const DUE_DATE_LENGTH = 3;
const TERM_GREATER_THAN_LENGTH = 4
  const handleCheckbox = (e) => {
    const { name, checked } = e.target;

    setFormData((preV) => ({
      ...preV,
      [name]: checked,
    }));
  };

  const updateFormat = (e) => {
    const { name, value } = e.target;
    if (value) {
      setFormData((preV) => ({
        ...preV,
        [name]: Number(value).toFixed(2),
      }));
    }
  };

  const getCourseCategory = async () => {
    const response = await fetchAllPromisedData(
      `${endpoint?.courseCategory?.getAllActiveCourseCategory}`,
      true
    );
    if (response?.code == 200) {
      const res = response.data.map((item) => {
        return { label: item.courseAttributeValueDispValue, value: item.id };
      });
      setCourseCategory({ fetching: false, data: res });
    } else {
      setCourseCategory({ fetching: false, data: [] });
    }
  };
  useEffect(() => {
    const options = [];
    const courseDropDownData = editFormData?.courseCategoriesNotAllowed.map(
      (item) => courseCategory.data.find((c) => c.value === item)
    );
    setFormData((preV) => ({
      ...preV,
      courseCategoriesNotAllowed: courseDropDownData,
    }));
  }, [editFormData, courseCategory]);

  useEffect(() => {
    const options = [];
    const paymentModeData = editFormData?.paymentModeIds.map((item) =>
      paymentMode.find((c) => {
        return c.id === item;
      })
    );
    setFormData((preV) => ({
      ...preV,
      paymentModeMaster: paymentModeData,
    }));
  }, [paymentMode, editFormData]);

  const getDepositSlipData = async () => {
    try {
      const response = await fetchAllPromisedData(
        `${endpoint?.depositSlip?.getAll}`,
        true
      );
      if (response?.code == 200) {
        setEditFormData(response.data);
        setFormData((preV) => ({
          ...preV,
          smsEnabled: response.data.smsEnabled,
          whatsappEnabled: response.data.whatsappEnabled,
          emailEnabled: response.data.emailEnabled,
          dueDay: response.data.dueDay,
          termLimit: response.data.termLimit,
          dueDay: response.data.dueDay,
          balanceAmount: response.data.balanceAmount.toFixed(2),
          maximumPaymentLimit: response.data.maxPaymentLimit.toFixed(2),
          nominalDifferenceAmount:
            response.data.nominalDifferenceAmount.toFixed(2),
        }));
      } else {
        setNew(true);
        failureToast("Failed to fetch Category Data");
      }
    } catch (e) {
      console.log(e);
      failureToast("Failed to fetch Data");
      setLoading(false);
    }
  };

  useEffect(() => {
    getCourseCategory();
    getDepositSlipData();
  }, []);

  const saveScheme = (e) => {
    e.preventDefault();
    const {
      dueDay,
      balanceAmount,
      termLimit,
      courseCategoriesNotAllowed,
      nominalDifferenceAmount,
      maximumPaymentLimit,
      paymentModeMaster,
    } = formData;
    if (!dueDay) {
      failureToast("Please enter due day");
      return;
    }

    if (!balanceAmount) {
      failureToast("Please enter balance amount");
      return;
    }

    if (!courseCategoriesNotAllowed?.length) {
      failureToast("Please select course category");
      return;
    }
    if (!maximumPaymentLimit) {
      failureToast("Please enter maximum payment limit");
      return;
    }
    if (!paymentModeMaster?.length) {
      failureToast("Please select payment mode");
      return;
    }

    if (!termLimit) {
      failureToast("Please enter term limit");
      return;
    }

    if (!nominalDifferenceAmount) {
      failureToast("Please enter nominal difference amount");
      return;
    }

    setLoading(true);
    const request = {
      dueDay: formData?.dueDay,
      balanceAmount: formData?.balanceAmount,
      termLimit: formData?.termLimit,
      nominalDifferenceAmount: formData?.nominalDifferenceAmount,
      courseCategoriesNotAllowed: formData?.courseCategoriesNotAllowed.map(
        (item) => item.value
      ),
      smsEnabled: formData?.smsEnabled,
      emailEnabled: formData?.emailEnabled,
      whatsappEnabled: formData?.whatsappEnabled,
      maxPaymentLimit: formData?.maximumPaymentLimit,
      paymentModeIds: formData?.paymentModeMaster.map((item) => item.id),
      status: "ACTIVE",
    };

    const depositSlipData = new Promise(() => {
      fetchAllPostPromisedData(
        `${endpoint.depositSlip.create}`,
        request,
        "post"
      )
        .then((response) => {
          setLoading(false);
          if (response && response.code === 200) {
            successToast(
              `Deposit Slip Configurations ${
                !isEdit ? "saved" : "updated"
              } successfully!`
            );
            setPostData(response.data);
            setIsEdit(false);
            setNew(false);
            getDepositSlipData();
          } else {
            failureToast(response.message);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    });
    return depositSlipData;
  };

  const onEditClick = (e) => {
    e.preventDefault();
    setIsEdit(true);
  };

  useEffect(() => {
    getAllPaymentModes();
  }, []);
  async function getAllPaymentModes() {
    setLoading(true);
    try {
      const resp = await fetchAllPromisedData(endpoint.paymentMode.getAll);
      setLoading(false);

      if (resp) {
        const _paymentModes = resp.map((item) => ({
          id: item.id,
          label: item.paymentModeDispValue,
          value: item.paymentModeKey,
        }));
        setPaymentModes(_paymentModes);
      } else {
        failureToast("Couldn't fetch payment methods");
      }
    } catch (err) {
      setLoading(false);
      failureToast("Couldn't fetch payment methods");
    }
  }
  return (
    <>
      <Box className="d-flex align-items-center depositSlip-header">
        <Link href="/#/admin/bankAccount" underline="none">
          <BackArrow />
        </Link>
        <Typography variant="h6">Deposit slip set-up</Typography>
      </Box>
      {isEdit || isNew ? (
        <Box className="depositslip-form">
          <Card>
            <CardBody>
              <Form>
                <DepositSlipHeader />
                <Row>
                  <Col md="4">
                    <label className="form-control-label">Due day</label>
                    <AInput
                      name="dueDay"
                      type="number"
                      placeholder="Select due day"
                      style={inputStyles}
                      mandatory={true}
                      onChange={(e)=>{
                        if(String(e.target.value).length<=DUE_DATE_LENGTH)
                           handleFormData(e)
                      }}
                      value={formData?.dueDay}
                    />
                  </Col>
                  <Col md="4">
                    <label className="form-control-label">Balance amount</label>
                    <div>
                      <AInput
                        type="text"
                        name="balanceAmount"
                        placeholder="Enter balance amount"
                        mandatory={true}
                        value={formData?.balanceAmount}
                        handler={(value) => {
                          if (
                            !value ||
                            (parseFloat(value) >= 0 &&
                              parseFloat(value) <= 100000)
                          ) {
                            formHandler(
                              value
                                .replace(/[^0-9.]/g, "")
                                .replace(/(\..*)\./g, "$1")
                                .replace(/(\.\d{1,2}).*/g, "$1"),
                              "balanceAmount"
                            );
                          }
                        }}
                        onBlur={updateFormat}
                        customIcon={
                          <div style={{ fontSize: "14px" }}>&#8377;</div>
                        }
                        customIconStyle={{
                          left: "8px",
                          display: "block",
                          top: "10px",
                        }}
                        style={{
                          ...inputStyles,
                          paddingLeft: "20px",
                          borderRadius: "4px",
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="4">
                    <AAutoComplete
                      height="40px"
                      currentValue={formData?.courseCategoriesNotAllowed}
                      handleChange={(value) =>
                        inputHandler(value, "courseCategoriesNotAllowed")
                      }
                      fontSize="14px"
                      isMulti={true}
                      label="Course category"
                      placeholder="Enter Course category except starting with"
                      name="courseCategoriesNotAllowed"
                      items={courseCategory?.data}
                      isShowCount={true}
                      labelStyle={{ fontWeight: "600", color: "#262626" }}
                      style={{ control: { borderRadius: "6px" } }}
                    />
                  </Col>
                  <Col md="4">
                    <label className="form-control-label">
                      Term greater than
                    </label>
                    <AInput
                      type="number"
                      name="termLimit"
                      placeholder="Enter Term greater"
                      style={inputStyles}
                      mandatory={true}
                      onChange={(e)=>{
                        if(String(e.target.value).length<=TERM_GREATER_THAN_LENGTH)
                             handleFormData(e)
                        }}
                      value={formData?.termLimit}
                    />
                  </Col>
                  <Col md="4">
                    <label className="form-control-label">
                      Nominal difference amount
                    </label>
                    <AInput
                      type="text"
                      name="nominalDifferenceAmount"
                      placeholder="Enter Nominal difference amount"
                      style={inputStyles}
                      mandatory={true}
                      value={formData?.nominalDifferenceAmount}
                      onBlur={updateFormat}
                      handler={(value) => {
                        if (
                          !value ||
                          (parseFloat(value) >= 0 &&
                            parseFloat(value) <= 100000)
                        )
                          formHandler(
                            value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*)\./g, "$1")
                              .replace(/(\.\d{1,2}).*/g, "$1"),
                            "nominalDifferenceAmount"
                          );
                      }}
                    />
                  </Col>
                  <Col md={12}>
                    <Box className="deposit-slip-notification">
                      <Typography variant="h6" className="title">
                        Payment Control Limit
                      </Typography>
                      <Row>
                        <Col md="4">
                          <label className="form-control-label">
                            Maximum Payment Limit
                          </label>
                          <AInput
                            type="text"
                            name="maximumPaymentLimit"
                            placeholder="Enter maximum payment limit"
                            mandatory={true}
                            value={formData?.maximumPaymentLimit}
                            handler={(value) => {
                              if (
                                !value ||
                                (parseFloat(value) >= 0 &&
                                  parseFloat(value) <= 1000000)
                              ) {
                                formHandler(
                                  value
                                    .replace(/[^0-9.]/g, "")
                                    .replace(/(\..*)\./g, "$1")
                                    .replace(/(\.\d{1,2}).*/g, "$1"),
                                  "maximumPaymentLimit"
                                );
                              }
                            }}
                            onBlur={updateFormat}
                            customIcon={
                              <div style={{ fontSize: "14px" }}>&#8377;</div>
                            }
                            customIconStyle={{
                              left: "8px",
                              display: "block",
                              top: "10px",
                            }}
                            style={{
                              ...inputStyles,
                              paddingLeft: "20px",
                              borderRadius: "4px",
                            }}
                          />
                        </Col>
                        <Col md="4">
                          <AAutoComplete
                            height="40px"
                            currentValue={formData?.paymentModeMaster}
                            handleChange={(value) =>
                              inputHandler(value, "paymentModeMaster")
                            }
                            fontSize="14px"
                            isMulti={true}
                            label="Payment Mode"
                            placeholder="Select Payment modes"
                            name="paymentModeMaster"
                            items={paymentMode}
                            isShowCount={true}
                            labelStyle={{ fontWeight: "600", color: "#262626" }}
                            style={{ control: { borderRadius: "6px" } }}
                          />
                        </Col>
                      </Row>
                    </Box>
                  </Col>
                  <Col md={12}>
                    <Box
                      className="deposit-slip-notification"
                      style={{ marginTop: "20px" }}
                    >
                      <Typography variant="h6" className="title">
                        Notifications
                      </Typography>
                      <Box className="checkbox-container">
                        <FormControlLabel
                          sx={{ marginBottom: "0px" }}
                          control={
                            <Checkbox
                              size="small"
                              onChange={handleCheckbox}
                              name="emailEnabled"
                              checked={formData?.emailEnabled}
                            />
                          }
                          label={<span>Email</span>}
                        />
                        <FormControlLabel
                          sx={{ marginBottom: "0px" }}
                          control={
                            <Checkbox
                              size="small"
                              onChange={handleCheckbox}
                              name="smsEnabled"
                              checked={formData?.smsEnabled}
                            />
                          }
                          label={<span>SMS</span>}
                        />
                        <FormControlLabel
                          sx={{ marginBottom: "0px" }}
                          control={
                            <Checkbox
                              size="small"
                              onChange={handleCheckbox}
                              name="whatsappEnabled"
                              checked={formData?.whatsappEnabled}
                            />
                          }
                          label={<span>Whatsapp</span>}
                        />
                      </Box>
                    </Box>
                  </Col>
                </Row>
                <Box className="deposit-btn">
                  <button onClick={saveScheme}>Save details</button>
                </Box>
              </Form>
            </CardBody>
          </Card>
        </Box>
      ) : (
        <Box className="depositslip-form">
          <Card>
            <CardBody>
              <Form>
                <Row>
                  <Col md={4}>
                    <CommonInput
                      label={"Reference number"}
                      type={"text"}
                      readOnly={true}
                      readOnlyComp={<h3 className="color-dark">10975389</h3>}
                    />
                  </Col>
                  <Col md={4}>
                    <CommonInput
                      label={"Booklet ID"}
                      type={"text"}
                      readOnly={true}
                      // readOnlyComp={<h3 className="color-dark">{id}</h3>}
                      readOnlyComp={<h3 className="color-dark">00000</h3>}
                      style={{ fontSize: "12px" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <CommonInput
                      label={"Due day"}
                      type={"text"}
                      placeHolder={"Select date"}
                      readOnly={true}
                      readOnlyComp={<h3>{formData?.dueDay}</h3>}
                    />
                  </Col>
                  <Col md={4}>
                    <CommonInput
                      label={"Balance amount"}
                      type={"text"}
                      placeHolder={"Enter balance amount"}
                      readOnly={true}
                      readOnlyComp={<h3>₹ {formData?.balanceAmount}</h3>}
                    />
                  </Col>
                  <Col md={4}>
                    <CommonInput
                      label={"Course category except starting with"}
                      type={"checkbox"}
                      placeHolder={"Enter Course category except starting with"}
                      readOnly={true}
                      readOnlyComp={
                        <h3>
                          {formData?.courseCategoriesNotAllowed !== undefined &&
                            formData?.courseCategoriesNotAllowed
                              .map((item) => {
                                return item?.label;
                              })
                              .join(", ")}
                        </h3>
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <CommonInput
                      label={"Term greater than"}
                      type={"text"}
                      placeHolder={"Term greater than"}
                      readOnly={true}
                      readOnlyComp={<h3>{formData?.termLimit}</h3>}
                    />
                  </Col>
                  <Col md={4}>
                    <CommonInput
                      label={"Nominal difference amount"}
                      type={"text"}
                      placeHolder={"Enter Nominal difference amount"}
                      readOnly={true}
                      readOnlyComp={
                        <h3>{formData?.nominalDifferenceAmount}</h3>
                      }
                    />
                  </Col>
                  <Col md={12}>
                    <Box className="deposit-slip-notification">
                      <Typography variant="h6" className="title">
                        Payment Control Limit
                      </Typography>

                      <Row>
                        <Col md={4}>
                          <CommonInput
                            label={"Maximum payment limit"}
                            type={"text"}
                            placeHolder={"Enter maximum payment limit"}
                            readOnly={true}
                            readOnlyComp={
                              <h3>₹ {formData?.maximumPaymentLimit}</h3>
                            }
                          />
                        </Col>
                        <Col md={4}>
                          <CommonInput
                            label={"Payment modes"}
                            type={"checkbox"}
                            placeHolder={"Enter payment modes"}
                            readOnly={true}
                            readOnlyComp={
                              <h3>
                                {formData?.paymentModeMaster !== undefined &&
                                  formData?.paymentModeMaster
                                    .map((item) => {
                                      return item?.label;
                                    })
                                    .join(", ")}
                              </h3>
                            }
                          />
                        </Col>
                      </Row>
                    </Box>
                  </Col>
                  <Col md={12}>
                    <Box
                      className="deposit-slip-notification"
                      style={{ marginTop: "20px" }}
                    >
                      <Typography variant="h6" className="title">
                        Notifications
                      </Typography>
                      <Box className="checkbox-container">
                        <FormControlLabel
                          sx={{ marginBottom: "0px" }}
                          control={
                            <Checkbox
                              size="small"
                              onChange={handleCheckbox}
                              name="emailEnabled"
                              disabled
                              checked={formData?.emailEnabled}
                            />
                          }
                          label={
                            <span style={{ color: "#262626" }}>Email</span>
                          }
                        />
                        <FormControlLabel
                          sx={{ marginBottom: "0px" }}
                          control={
                            <Checkbox
                              size="small"
                              onChange={handleCheckbox}
                              disabled
                              checked={formData?.smsEnabled}
                              name="smsEnabled"
                            />
                          }
                          label={<span style={{ color: "#262626" }}>SMS</span>}
                        />
                        <FormControlLabel
                          sx={{ marginBottom: "0px" }}
                          control={
                            <Checkbox
                              size="small"
                              onChange={handleCheckbox}
                              disabled
                              checked={formData?.whatsappEnabled}
                              name="whatsappEnabled"
                            />
                          }
                          label={
                            <span style={{ color: "#262626" }}>Whatsapp</span>
                          }
                        />
                      </Box>
                    </Box>
                  </Col>
                </Row>
                <Box
                  style={{ marginTop: "20px" }}
                  className="edit-btn d-flex align-items-center"
                >
                  <button type="button" onClick={onEditClick}>
                    Edit
                  </button>
                  <Typography variant="body1">
                    Last updated on:{" "}
                    {moment(editFormData?.updatedOn).format("DD-MMM-YYYY")}
                  </Typography>
                  <Typography variant="body1">
                    Last updated by: {editFormData?.updatedBy}{" "}
                  </Typography>
                </Box>
              </Form>
            </CardBody>
          </Card>
        </Box>
      )}
    </>
  );
};

export default SlipDetailsForm;
