import {Button, Col, FormGroup,  Row} from "reactstrap";
import React from "react";
import moment from "moment";
import {useHistory} from "react-router-dom";
const LogDetailsApplicationId = (props)=>{
    let history = useHistory();
    const {formData,logDetails,studentId,action,button,label} = props;
    return (
        <div>
            <Row style={{ fontSize: "15px" }} >
                <Col md={5}>
                    <Row className="pl-5 pr-5">
                        <Col md={12}>
                            <FormGroup row>
                                <Col md={5}>
                                    <label className="form-control-label">{label.createdDate} </label>
                                </Col>
                                <Col md={7}>
                                    : <label className="pl-3">{logDetails ? moment(logDetails['updatedOn']).format('DD/MM/YYYY') : 'Not Found'}</label>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col md={5}>
                    <Row  className="pl-5 pr-5">
                        <Col md={12}>
                            <FormGroup row>
                                <Col md={5}>
                                    <label className="form-control-label">{label.lastUpdatedDate}</label>
                                </Col>
                                <Col md={7}>
                                    : <label className="pl-3">{logDetails ? moment(logDetails['updatedOn']).format('DD/MM/YYYY') : 'Not Found'}</label>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col md={2}>
                    <Button color="info" size="sm" className="floatRight mx-1" onClick={()=> {
                        if(action ==='view' && studentId){
                            window.close()
                        }else {
                            history.push(`/admin/applicationIdGeneration`)
                        }
                    }}>
                        {button.close}
                    </Button>
                </Col>
            </Row>
            <Row style={{fontSize: "15px"}}>
                <Col md={5}>
                    <Row className="pl-5 pr-5">
                        <Col md={12}>
                            <FormGroup row>
                                <Col md={5}>
                                    <label className="form-control-label">{label.createdBy} </label>
                                </Col>
                                <Col md={7}>
                                    : <label className="pl-3">{logDetails && logDetails['createdBy'] ? logDetails['createdBy'] :'Not Found'}</label>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col md={5}>
                    <Row  className="pl-5 pr-5">
                        <Col md={12}>
                            <FormGroup row>
                                <Col md={5}>
                                    <label className="form-control-label">{label.lastUpdatedBy}</label>
                                </Col>
                                <Col md={7}>
                                    : <label className="pl-3">{logDetails && logDetails['updatedBy'] ? logDetails['updatedBy'] : 'Not Found'}</label>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default LogDetailsApplicationId;