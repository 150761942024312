import React, { useEffect, useState } from 'react';
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  Button,
  Row,
  Col,
} from 'reactstrap';
import { masterServiceBaseUrl,getAPI, failureToast } from 'services/http';
import Loader from 'react-loader-spinner';
import CustomLoader from '../../../common/commonComponents/Loader/loader';
import { Accordion } from '../../../common/commonComponents/Accordion/accordion';
import { useHistory } from 'react-router-dom';
import CommonHeader from './commonHeader';
import PaymodeControl from './paymodeControl';
import FunctionalityControl from './functionalityControl';
import './controlPaymode.scss';

const ControlPaymodes = (props) => {
  const { id } = props.match.params;
  let history = useHistory();
  const { action, isNew = true, formId, isViewOnly = false } = props;
  const [apiLoader, setApiLoader] = useState(false);
  const [grpCodeData, setGrpCodeData] = useState([]);
  const [companyCodeData, setCompanyCodeData] = useState([]);
  const [businessAreaData, setBusinessAreaData] = useState([]);
  const [cashierOffice, setCashierOfficeData] = useState([]);
  const [courseId, setCourseIdData] = useState([]);
  const [term, setTermData] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [functionalityCode, setFunctionalityCode] = useState([]);
  const [paymodeControl, setPaymodeControl] = useState([]);
  const [functionalityControl, setFunctionalityControl] = useState([]);
  const [paymentSchedule, setPaymentSchedule] = useState([]);
  const [businessAreaID, setBusinessAreaID] = useState('');
  const [courseDetailID, setCourseDetailID] = useState('');

  // let id = 36;
  // console.log('PaymentScheduleID__', id);

  useEffect(() => {
    getPaymentScheduleData();
  }, [id]);

  useEffect(() => {
    fetchDetails(
      masterServiceBaseUrl + '/groupCode/getAllActiveGroupCode',
      setGrpCodeData,
      'groupCode'
    );
    fetchDetails(
      masterServiceBaseUrl + '/companyCode/getAllActiveCompanyCode',
      setCompanyCodeData,
      'companyCode'
    );
    fetchDetails(
      masterServiceBaseUrl + '/businessArea/getAllActiveBusinessArea',
      setBusinessAreaData,
      'businessArea'
    );
    fetchDetails(
      masterServiceBaseUrl + '/cashierOffice/getActiveCashierOffice',
      setCashierOfficeData,
      'cashierOffice'
    );
    fetchDetails(
      masterServiceBaseUrl + '/courseDetail/getAllCourseDetail',
      setCourseIdData,
      'course'
    );
    fetchDetails(masterServiceBaseUrl + '/term/getAllActiveTerm', setTermData, 'term');
    fetchDetails(
      masterServiceBaseUrl + '/paymentMethod/getAllActivePaymentMethod',
      setPaymentMethod,
      'paymentMethod'
    );
    fetchDetails(
      masterServiceBaseUrl + '/functionalityCode/getAllActiveFunctionalityCode',
      setFunctionalityCode,
      'functionalityCode'
    );
  }, []);

  const getPaymentScheduleData = async () => {
    try {
      setApiLoader(true);
      getAPI(
        `${masterServiceBaseUrl}/payment/schedule/${id}`,
        (data) => {
          setPaymentSchedule(data.data);
          setBusinessAreaID(data.data.businessAreaId);
          setCourseDetailID(data.data.courseIdRegular);
          setApiLoader(false);
        },
        (data) => {
          failureToast(data['message']);
          setApiLoader(false);
        }
      );
    } catch (e) {
      console.log('Error', e);
    }
  };

  const fetchDetails = (url, stateMethod, type) => {
    try {
      setApiLoader(true);
      getAPI(
        url,
        (data) => {
          refactorData(data['data'], stateMethod, type);
          setApiLoader(false);
        },
        (data) => {
          failureToast(data['message']);
          setApiLoader(false);
        }
      );
    } catch (e) {
      console.log('Error', e);
    }
  };

  const refactorData = (data, stateMethod, type) => {
    try {
      switch (type) {
        case 'groupCode':
          iterateData(
            data,
            'id',
            'groupCodeDispValue',
            'groupCodeDesc',
            stateMethod
          );
          break;
        case 'companyCode':
          iterateData(
            data,
            'id',
            'companyCodeDispValue',
            'companyCodeDesc',
            stateMethod
          );
          break;
        case 'businessArea':
          iterateData(
            data,
            'id',
            'businessAreaKey',
            'businessAreaDesc',
            stateMethod
          );
          break;
        case 'cashierOffice':
          iterateData(
            data,
            'id',
            'cashierOfficeKey',
            'description',
            stateMethod
          );
          break;
        case 'course':
          iterateData(data, 'id', 'courseId', 'courseName', stateMethod);
          break;
        case 'term':
          iterateData(data, 'id', 'termDispValue', 'termDesc', stateMethod);
          break;
        case 'paymentMethod':
          iterateData(
            data,
            'id',
            'paymentMethodDispValue',
            'paymentMethodDesc',
            stateMethod
          );
          break;
        case 'functionalityCode':
          iterateData(
            data,
            'id',
            'functionalityCodeDispVal',
            'functionalityCodeDesc',
            stateMethod
          );
          break;
        default:
          break;
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  const iterateData = (data, id, property, propertyDesc, stateMethod) => {
    try {
      let temp = [];
      data.map((item) => {
        temp.push({
          id: item[id],
          text: item[property],
          desc: item[propertyDesc],
        });
      });
      stateMethod(temp);
    } catch (e) {
      console.log('Error', e);
    }
  };

  // console.log('paymentMethod___', paymentMethod);
  // console.log('functionalityCode__', functionalityCode);
  // console.log('grpCodeData__', grpCodeData);
  // console.log('companyCodeData__', companyCodeData);
  // console.log('businessAreaData__', businessAreaData);
  // console.log('cashierOffice__', cashierOffice);
  // console.log('courseId__', courseId);
  // console.log('term__', term);

  const getDisplayValue = (id, type) => {
    try {
      switch (type) {
        case 'paymentMethodDispValue':
          const idIndex = findIndexForDisplay(id, paymentMethod);
          return paymentMethod[idIndex]['text'];
          break;
        case 'paymentMethodDescription':
          const paymentDesc = findIndexForDisplay(id, paymentMethod);
          return paymentMethod[paymentDesc]['desc'];
          break;
        case 'functionalityDispValue':
          const functionalityDisp = findIndexForDisplay(id, functionalityCode);
          return functionalityCode[functionalityDisp]['text'];
          break;
        case 'functionalityDescription':
          const functionalityDesc = findIndexForDisplay(id, functionalityCode);
          return functionalityCode[functionalityDesc]['desc'];
          break;
        default:
          break;
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  const findIndexForDisplay = (id, datalist) => {
    const index = datalist.findIndex((item) => item['id'] == id);
    return index;
  };

  const findDisplayValue = (datalist, id) => {
    try {
      const findIndex = datalist.findIndex((item) => item['id'] == id);
      return findIndex >= 0 ? datalist[findIndex]['text'] : 'NOT FOUND';
    } catch (e) {
      console.log('Error', e);
    }
  };

  const close = () => {
    if (paymodeControl.length === 0)
      return failureToast('Please fill the Paymode Control Section');
    if (functionalityControl.length === 0)
      return failureToast('Please fill the Functionality Control Section');
    history.push('/admin/paymentSchedule');
  };

  return (
    <>
      <Container className="mt-4" fluid>
        <Card className="m-5 p-5">
          {apiLoader && !businessAreaID && !courseDetailID ? (
            <div
              className="mx-auto text-center py-5 my-5"
              style={{ height: '100vh' }}
            >
              <CustomLoader
                apiLoader={apiLoader}
                loaderHeight={'200px'}
                loaderWidth={'100%'}
              />
            </div>
          ) : (
            <div>
              <CommonHeader
                title={'Control Paymodes & Functionalities'}
                groupCodeData={grpCodeData}
                companyCodeData={companyCodeData}
                businessAreaCodeData={businessAreaData}
                cashierCodeOfficeData={cashierOffice}
                courseIdData={courseId}
                termCodeData={term}
                paymentSchedule={paymentSchedule}
                businessAreaID={businessAreaID}
                courseDetailID={courseDetailID}
              />
              <Card>
                <CardBody>
                  <Accordion
                    isFormOpen={true}
                    title={`Paymode Control`}
                    key={'paymode_control'}
                  >
                    <PaymodeControl
                      PaymentScheduleId={id}
                      paymentMethod={paymentMethod}
                      paymodeControl={paymodeControl}
                      setPaymodeControl={setPaymodeControl}
                      getDisplayValue={getDisplayValue}
                      getPaymentScheduleData={getPaymentScheduleData}
                    />
                  </Accordion>
                  <Accordion
                    isFormOpen={true}
                    title={`Functionality Control`}
                    key={'functionality_control'}
                  >
                    <FunctionalityControl
                      PaymentScheduleId={id}
                      functionalityCode={functionalityCode}
                      functionalityControl={functionalityControl}
                      setFunctionalityControl={setFunctionalityControl}
                      getDisplayValue={getDisplayValue}
                      getPaymentScheduleData={getPaymentScheduleData}
                    />
                  </Accordion>
                </CardBody>
                <Row>
                  <Col>
                    <Button
                      color="primary"
                      className="floatRight mr-4 mb-3"
                      type="button"
                      onClick={() => close()}
                    >
                      Close
                    </Button>
                  </Col>
                </Row>
              </Card>
            </div>
          )}
        </Card>
      </Container>
    </>
  );
};

export default ControlPaymodes;
