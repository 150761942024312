import { endpoint } from "views/pages/manage/common/constant";
import { updateRequest } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { dropdownMapping } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
export const newRow = {
  examTimeCOde: "",
  term: "",
  testType: "",
  courseCategory: "",
  academicGroup: "",
  category1: "",
  categoryType: "",
  additionalCategory: "",
  status: "ACTIVE",
};
export const initialFilterForm = {
  examTimeCOde: "",
  term: "",
  testType: "",
  academicGroup: "",
  courseCategory: "",
  status: "",
};

export const getDropdowns = async () => {
  const dropdown = await Promise.all([
    dropdownMapping(endpoint.testType.getAllActive, "testTypeDispValue", "id"),

    dropdownMapping(endpoint?.term?.getAllActive, "termDispValue", "id"),
    dropdownMapping(
      endpoint.academic_group.getAllActive,
      "academicGroupDispValue",
      "id"
    ),
    dropdownMapping(
      endpoint.courseCategory.getAllActiveCourseCategory,
      "courseAttributeValueDispValue",
      "id"
    ),
  ]);
  return {
    testType: dropdown[0],
    term: dropdown[1],
    academicGroup: dropdown[2],
    courseCategory: dropdown[3],
    categoryType: categoryType,
  };
};
export const categoryType = [
  { label: "Percentage", value: "PERCENTAGE" },
  { label: "Percentile", value: "PERCENTILE" },
  { label: "All INDIA RANK", value: "All INDIA RANK" },
  { label: "STATE RANK", value: "STATE RANK" },
];

const catagoryMapping = (item, categoryData) => {
  const cat = categoryData.filter((cat) => {
    return cat?.subCategories.find((sub) => {
      return sub?.id == item?.subCategoryId;
    });
  });
  const sub = cat[0]?.subCategories.find((sub) => {
    return sub?.id == item?.subCategoryId;
  });
  if (sub?.subSubCategory.length > 0) {
    return {
      isSubSubCategory: true,
      ...sub?.subSubCategory.find((subSub) => {
        return subSub?.id == item?.subSubCategoryId;
      }),
    };
  } else {
    return sub;
  }
};
const additionalCategoryMapping = (item, categoryData) => {
  const cat = categoryData.filter((cat) => {
    return cat?.subCategories.find((sub) => {
      return sub?.id == item?.additionalSubCategoryId;
    });
  });

  const sub = cat[0]?.subCategories.find((sub) => {
    return sub?.id == item?.additionalSubCategoryId;
  });
  if (sub?.subSubCategory.length > 0) {
    const subsub = sub?.subSubCategory.find((subSub) => {
      return subSub?.id == item?.additionalSubSubCategoryId;
    });
    return { isSubSubCategory: true, ...subsub };
  } else {
    return sub;
  }
};
export const restructureGetAllRes = (data, dropdown, categoryData) => {
  return data?.scholarshipConfigDtoList.map((item) => ({
    ...item,
    examTimeCode: item?.examType,

    testType: { label: item?.testTypeDispVal, value: item?.testTypeId },

    term: item?.termMappings?.map((item) => ({
      label: item?.termDispVal,
      value: item?.termId,
    })),
    academicGroup: item?.academicGroupMappings?.map((item) => ({
      label: item?.academicGroupDispVal,
      value: item?.academicGroupId,
    })),
    category1: catagoryMapping(item, categoryData),

    courseCategory: item?.courseCategoryMappings?.map((item) => ({
      label: item?.courseCategoryDispVal,
      value: item?.courseCategoryId,
    })),
    additionalCategory: {
      cutoff: item?.additionalCategoryCutoff,
      category2: additionalCategoryMapping(item, categoryData),
      categoryType: item?.additionalCategoryType,
    },
    categoryType: categoryType.find((c) => c?.value == item?.categoryType),
    status: item?.status,
    updatedBy: item?.updatedBy,
    updatedOn: item?.updatedOn,
  }));
};

export const getSearchReqBody = (reqBody) => {
  return updateRequest({
    examType: reqBody?.examTimeCode,
    testTypeId: reqBody?.testType?.value,
    termIds: reqBody?.term ? reqBody?.term?.map((item) => item?.value) : [],
    academicGroupIds: reqBody?.academicGroup
      ? reqBody?.academicGroup?.map((item) => item?.value)
      : [],
    status: reqBody?.status,
    courseCategoryIds: reqBody?.courseCategory
      ? reqBody?.courseCategory?.map((item) => item?.value)
      : [],
  });
};

export const getCreateReqBody = (rowData) => {
  return updateRequest({
    subCategoryId: rowData?.subCategoryId,
    subSubCategoryId: rowData?.subSubCategoryId,
    categoryType: rowData?.categoryType,
    additionalCategoryCutoff: rowData?.additionalCategoryCutoff,
    additionalSubCategoryId: rowData?.additionalSubCategoryId,
    additionalSubSubCategoryId: rowData?.additionalSubSubCategoryId,
    additionalCategoryType: rowData?.additionalCategoryType,
    testTypeId: rowData?.testType?.value,
    testTypeDispVal: rowData?.testType?.label,
    status: rowData?.status,
    examType: rowData?.examTimeCode,
    termMappings: rowData?.term?.map((item) => ({
      termId: item?.value,
      termDispVal: item?.label,
    })),
    courseCategoryMappings: rowData?.courseCategory?.map((item) => ({
      courseCategoryId: item?.value,
      courseCategoryDispVal: item?.label,
    })),
    academicGroupMappings: rowData?.academicGroup?.map((item) => ({
      academicGroupId: item?.value,
      academicGroupDispVal: item?.label,
    })),
  });
};
