import React, { useState, useEffect, useContext } from "react";
import BusinessTypeTable from './businessTypeTable';
import { getAPI, putpost, masterServiceBaseUrl } from 'services/http';
import CustomLoader from '../../common/commonComponents/Loader/loader';
import { successToast, failureToast } from "../../common/utils/methods/toasterFunctions/function";
import { PermissionContext, RolePermissions } from 'appContext';
import { pages } from '../../common/constant';
import { PermisionDenied } from '../../common/commonComponents';
import { GetPagination } from "../../common/commonComponents/pagination/pagination";
const BusinessType = () => {
  const [classArray, setclassArray] = useState([])
  const [apiloader, setapiloader] = useState(false)
  const [state, setstate] = useState([])
	const [isSaveVisible, setisSaveVisible] = useState(false);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null
  })
  const pageSize = 10

  const permissions = useContext(PermissionContext);

  const userPermissions = RolePermissions(permissions, pages['businessType']['id']);


  const fetchClasses = async (url) => {
    setapiloader(true)
    getAPI(url, (data) => {
      setstate(data['data'])
      let n = pagination
      if (data['data'].length > pageSize) {
        n['nextPage'] = n.currentPage + 1
      }
      if (n.currentPage > 1) {
        n.previousPage = n.currentPage - 1
      }
      n['totalPage'] = Math.floor(data['data'].length / pageSize)
      if (data['data'].length % pageSize != 0) {
        n['totalPage'] += 1
      }
      setpagination(n)
      setapiloader(false)
      setclassArray(data['data'].slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || [])
    }, (data) => { failureToast(data['message']) })
  }

  const previousPage = () => {
    if (pagination.currentPage != 1) {
      setclassArray(state.slice((pagination.currentPage - 1) * (pageSize) - pageSize, (pagination.currentPage - 1) * pageSize))
      let n = pagination
      n['currentPage'] = n['currentPage'] - 1
      if (n['currentPage'] == 1) {
        n['previousPage'] = null
      } else {
        n['previousPage'] = n.currentPage
      }
      setpagination(n)
    }
  }
  useEffect(() => {
    fetchClasses(masterServiceBaseUrl + '/businessType/getAllBusinessType')
  }, [])
  return (
    <>
      {apiloader ? <CustomLoader
        apiLoader={apiloader}
        loaderHeight={'200px'}
        loaderWidth={'100% '}
      /> :
        <>
          {userPermissions ? (
            <>
              <BusinessTypeTable
                previousPage={previousPage}
                fetchClasses={fetchClasses}
                setclassArray={setclassArray}
                classArray={classArray}
                userPermissions={userPermissions}
                isSaveVisible={isSaveVisible}
                setisSaveVisible={setisSaveVisible}
              />
              {pagination.totalPage > 1 ?
                <GetPagination
                  isSaveVisible={isSaveVisible}
                  fetchClasses={fetchClasses}
                  setclassArray={setclassArray}
                  pagination={pagination}
                  setpagination={setpagination}
                  pageSize={pageSize}
                  state={state}
                />
                : null}
            </>) :
            <PermisionDenied />
          }
        </>}
    </>
  );
}
export default BusinessType;