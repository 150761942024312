import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router';
import { Stepper } from 'react-form-stepper';
import { Row, Col, Card, CardBody, Container } from 'reactstrap';
import BussinessUnit from '../component/FirstForm/businessUnit';
import BusinessUnitNewUI from '../component/FirstForm/businessUnitNewUI';
import LocationAddress from '../component/LocationAddress';
import ContactHistory from '../component/thirdForm';
import Franchisee from '../component/Franchisee/index';
import TaxDetails from '../component/taxDetails';
import CashierOffice from '../component/cashierOffice/index';
import ApprovalMatric from '../component/approvalMatrix/approvalMatric';
import BranchAccount from '../component/branchAccount/index';
import SAPDetails from '../component/SAP_BusinessArea&Plan/index';
import PineLabsSetup from '../component/pineLabsSetup/index';
import LastNumberGenerated from '../component/LastNumberGenerated/index';
import Loader from 'react-loader-spinner';
import CustomLoader from '../../common/commonComponents/Loader/loader';
import BusinessUnitFormTabs from '../component/FirstForm';
import {
	MasterDataForSelect,
	getSelectedDropDownValue,
	MasterDataForReactSelect,
} from '../../common/utils/methods/commonMethods/masterDataForSelect';
import { masterServiceBaseUrl, getAPI } from 'services/http';
import { failureToast } from "../../common/utils/methods/toasterFunctions/function";

import { PermissionContext, RolePermissions } from "appContext";
import { PermisionDenied } from "../../common/commonComponents";
import { pages } from "../../common/constant";
import CustomButton from "components/CustomButton";
import { ButtonText } from 'variables/Buttons';

// import courseAttrSessionTable from '../../flows/courseAttributes/courseAttributeForm/component/courseAttrSessionTable';

const MainBuForm = (props) => {
	const { id, action } = props.match.params;

	const permissions = useContext(PermissionContext);
	const userPermissions = RolePermissions(permissions, pages["businessAreaSearch"]['id']);

	const [formId, setFormId] = useState(null);
	const [isNew, setIsNew] = useState(true);
	const [isViewOnly, setIsViewOnly] = useState(false);
	const [groupCode, setGroupCode] = useState(false);
	const [companyCode, setCompanyCode] = useState(false);

	let [allStepData, setAllStepData] = useState({
		firstForm: null,
		secondForm: null,
		thirdForm: null,
		forthFom: null,
		fifthForm: null,
		sixthForm: null,
		sevethForm: null,
		eightForm: null,
		ninethForm: null,
		thenthForm: null,
		eleventhForm: null,
	});

	let [stepCompleted, setStepCompleted] = useState([]);

	const [activeStep, setActiveStep] = React.useState(0);
	const [businessUnit, setBusinessUnit] = useState(null);
	const [businessAreaId, setBusinessAreaId] = useState({});
	const [franchiseDetails, setFranchiseDetails] = useState([]);
	const [franchisesSetupId, setFranchisesSetupId] = useState(null);
	const [isPrevious, setIsPrevious] = useState(false);
	const [selectedCode, setSelectedCode] = useState({
		groupCodeId: null,
		companyCodeId: null,
	});

	const [isCopied, setIscopied] = useState(false);
	const [businessAreaList, setBusinessAreaList] = useState(null);
	const [copyBusinessArea, setCopyBusinessArea] = useState(null);
	const [businessUnitType, setBusinessUnitType] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const chkForBusinessUnit = () => {
		const i =
			businessUnit &&
			businessUnit.businessUnitType &&
			businessUnit.businessUnitType.filter((item) => {
				return item && item.label === 'FRANCHISEE';
			});
		return i && i.length ? true : false;
	};

	useEffect(() => {
		// //console.log("Action is here for the action", id, action);
		if (action === 'view' && id) {
			setFormId(id);
			setBusinessUnit({ businessUnit: id });
			setIsNew(false);
			setIsViewOnly(true);
			setIsLoading(false);
		} else if (action === 'edit' && id) {
			setFormId(id);
			setBusinessUnit({ businessUnit: id });
			setIsNew(false);
			setIsViewOnly(false);
			setIsLoading(false);
		} else if (!action && id && id !== 'new' && businessUnitType) {
			setIsLoading(true);
			getStepNumber(id);
		}
		if (id === 'new') {
			setIsLoading(false);
		}
	}, [id, businessUnitType]);


	useEffect(() => {
		setAllStepData({ firstForm: null, secondForm: null, thirdForm: null, forthFom: null, fifthForm: null, sixthForm: null, sevethForm: null, eightForm: null, ninethForm: null, thenthForm: null, eleventhForm: null, })
		setIscopied(false)
		if (copyBusinessArea && copyBusinessArea.value) {
			getAPI(
				`${masterServiceBaseUrl}/businessArea/getBusinessAreaDetails/${copyBusinessArea.value}`,
				(data) => {
					setAllStepData({
						firstForm: data && data.data.businessAreaMasterDTO,
						secondForm: data && data.data.addressMaster,
						thirdForm: null,
						forthFom: null,
						fifthForm: null,
						sixthForm: null,
						sevethForm: null,
						eightForm: null,
						ninethForm: null,
						thenthForm: null,
						eleventhForm: null
					})
					setIscopied(true)
				},
				(data) => {
					failureToast(data["message"]);
				}
			);
		}
	}, [copyBusinessArea])


	const appendAllStepData = (data, key) => {
		// debugger
		//allStepData[key] = data;
		setAllStepData({ ...allStepData, [key]: data });
	};

	const updateAllStepData = (data, key) => {
		// allStepData[key] = data;
		setAllStepData({ ...allStepData, [key]: data });
	};

	const getBusinessUnitTypeArray = (arr) => {
		let newarr = [];
		businessUnitType &&
			arr &&
			arr.forEach((element) => {
				//console.log(element);
				//console.log(businessUnitType);
				const val = getSelectedDropDownValue(element, businessUnitType);
				//console.log(val);
				if (val) {
					newarr = [...newarr, val];
				}
			});
		return newarr;
	};

	const getStepNumber = (id) => {
		getAPI(
			`${masterServiceBaseUrl}/businessArea/getBusinessAreaDetails/${id}`,
			(data) => {
				if (data.data && data.data.businessAreaMasterDTO.id) {
					const basicInfo = {
						businessUnit: data.data.businessAreaMasterDTO.id,
						companyCode: data.data.businessAreaMasterDTO.companyCodeId,
						groupCode: data.data.businessAreaMasterDTO.groupCodeId,
						businessUnitType: getBusinessUnitTypeArray(
							data.data.businessAreaMasterDTO.businessUnitTypeId
						),
						businessKey: data.data.businessAreaMasterDTO.businessAreaKey,
						businessDesc: data.data.businessAreaMasterDTO.businessAreaDesc,
					};
					setBusinessUnit(basicInfo);
					setBusinessAreaId(data.data.businessAreaMasterDTO.id);
					setGroupCode(data.data.businessAreaMasterDTO.groupCodeId);
					setCompanyCode(data.data.businessAreaMasterDTO.companyCodeId);
					saveStepsCompleted(1);
					if (
						data.data &&
						data.data.addressMaster &&
						data.data.addressMaster.id
					) {
						saveStepsCompleted(2);
						if (
							data.data &&
							data.data.contactHistory &&
							data.data.contactHistory.length > 0
						) {
							saveStepsCompleted(3);
							// debugger
							const bool = getBusinessUnitTypeArray(
								data.data.businessAreaMasterDTO.businessUnitTypeId
							)
							const i = bool && bool.filter((item) => {
								return item && item.label === 'FRANCHISEE';
							});
							if (i && i.length) {
								if (
									data.data &&
									data.data.franchiseeSetupMaster &&
									data.data.franchiseeSetupMaster.id
								) {

									saveStepsCompleted(4);
									setFranchisesSetupId(data?.data?.franchiseeSetupMaster?.id)
									if (
										data.data &&
										data.data.businessAreaTaxDetail &&
										data.data.businessAreaTaxDetail
											.businessAreaBusinessUnitTaxSetupReceipts &&
										data.data.businessAreaTaxDetail
											.businessAreaBusinessUnitTaxSetupReceipts.length > 0
									) {
										saveStepsCompleted(5);
										if (
											data.data &&
											data.data.cashierOfficeMasters &&
											data.data.cashierOfficeMasters.length > 0
										) {
											saveStepsCompleted(6);
											if (
												data.data &&
												data.data.houseBankAndHouseBankSetupDTO &&
												data.data.houseBankAndHouseBankSetupDTO.length > 0
											) {
												saveStepsCompleted(7);
												if (
													data.data &&
													data.data.consessionApprovalSetups &&
													data.data.consessionApprovalSetups.length > 0
												) {
													saveStepsCompleted(8);
													if (
														data.data &&
														data.data.sapDetails &&
														data.data.sapDetails.profitAndCostMasters &&
														data.data.sapDetails.profitAndCostMasters.length > 0
													) {
														saveStepsCompleted(9);
														if (
															data.data &&
															data.data.pineLabs &&
															data.data.pineLabs.pineLabsPOSIMEIMappings &&
															data.data.pineLabs.pineLabsPOSIMEIMappings
																.length > 0
														) {
															saveStepsCompleted(10);
															if (
																data.data &&
																data.data.lastNumberGeneratedDTO &&
																data.data.lastNumberGeneratedDTO
																	.invoiceNumberSetting &&
																data.data.lastNumberGeneratedDTO
																	.invoiceNumberSetting.length > 0
															) {
																props.history.push(
																	`/admin/businessUnit/view/${id}`
																);
																setActiveStep(10);
															} else {
																setActiveStep(10);
															}
														} else {
															setActiveStep(9);
														}
													} else {
														setActiveStep(8);
													}
												} else {
													setActiveStep(7);
												}
											} else {
												setActiveStep(6);
											}
										} else {
											setActiveStep(5);
										}
									} else {
										setActiveStep(4);
										if (data.data &&
											data.data.businessAreaTaxDetail && (
												data.data.businessAreaTaxDetail.businessAreaBusinessUnitTaxSetupReceipts ||
												data.data.businessAreaTaxDetail.businessAreaBusinessUnitTaxSetupRefunds ||
												data.data.businessAreaTaxDetail.businessAreaAndUnitFranchiseeCommissionTdsSetups
											)) { setIsPrevious(true) }
									}
								} else {
									setActiveStep(3);
								}
							} else {
								if (
									data.data &&
									data.data.businessAreaTaxDetail &&
									data.data.businessAreaTaxDetail
										.businessAreaBusinessUnitTaxSetupReceipts &&
									data.data.businessAreaTaxDetail
										.businessAreaBusinessUnitTaxSetupReceipts.length > 0
								) {
									saveStepsCompleted(5);
									if (
										data.data &&
										data.data.cashierOfficeMasters &&
										data.data.cashierOfficeMasters.length > 0
									) {
										saveStepsCompleted(6);
										if (
											data.data &&
											data.data.houseBankAndHouseBankSetupDTO &&
											data.data.houseBankAndHouseBankSetupDTO.length > 0
										) {
											saveStepsCompleted(7);
											if (
												data.data &&
												data.data.consessionApprovalSetups &&
												data.data.consessionApprovalSetups.length > 0
											) {
												saveStepsCompleted(8);
												if (
													data.data &&
													data.data.sapDetails &&
													data.data.sapDetails.profitAndCostMasters &&
													data.data.sapDetails.profitAndCostMasters.length > 0
												) {
													saveStepsCompleted(9);
													if (
														data.data &&
														data.data.pineLabs &&
														data.data.pineLabs.pineLabsPOSIMEIMappings &&
														data.data.pineLabs.pineLabsPOSIMEIMappings.length >
														0
													) {
														saveStepsCompleted(10);
														if (
															data.data &&
															data.data.lastNumberGeneratedDTO &&
															data.data.lastNumberGeneratedDTO
																.invoiceNumberSetting &&
															data.data.lastNumberGeneratedDTO
																.invoiceNumberSetting.length > 0
														) {
															props.history.push(
																`/admin/businessUnit/view/${id}`
															);
															setActiveStep(10);
														} else {
															setActiveStep(10);
														}
													} else {
														setActiveStep(9);
													}
												} else {
													setActiveStep(8);
												}
											} else {
												setActiveStep(7);
											}
										} else {
											setActiveStep(6);
										}
									} else {
										setActiveStep(5);
									}
								} else {
									setActiveStep(4);
								}
							}
						} else {
							setActiveStep(2);
						}
					} else {
						setActiveStep(1);
					}
				}
				businessUnitType && setIsLoading(false);
			},
			(data) => {
				setIsLoading(false);
				props.history.push('/admin/businessUnit/new');
				failureToast(data['message']);
			}
		);
	};

	React.useEffect(() => {
		MasterDataForSelect(
			`${masterServiceBaseUrl}/businessArea/getAllBusinessArea`,
			'businessArea',
			setBusinessAreaList
		);
		MasterDataForSelect(
			`${masterServiceBaseUrl}/businessUnitType/getAllActiveBusinessUnitType`,
			'businessUnitType',
			setBusinessUnitType
		);
	}, []);

	const saveStepsCompleted = (stepNo) => {
		if (stepCompleted.indexOf(stepNo) < 0) {
			stepCompleted.push(stepNo);
			setStepCompleted(stepCompleted);
		}
	};

	const toNextStep = (props) => {
		// //console.log('nextStepClicked');
		if (stepCompleted.indexOf(activeStep + 2) < 0) {

			setIsPrevious(false);
		}
		if (props) {
			setBusinessUnit(props);
		}
		if (activeStep === 2 && !chkForBusinessUnit()) {
			setActiveStep(activeStep + 2);
		} else {
			setActiveStep(activeStep + 1);
		}
	};
	const toPreviousStep = () => {
		setIsPrevious(true);
		if (activeStep === 4 && !chkForBusinessUnit()) {
			setActiveStep(activeStep - 2);
		} else {
			setActiveStep(activeStep - 1);
		}
	};

	// //console.log('businessUnit__', businessUnit,businessAreaId);

	let formToRender = null;
	switch (activeStep) {
		case 0:
			formToRender = (
				<BusinessUnitFormTabs
					nextStep={toNextStep}
					isNew={isNew}
					formId={businessUnit && businessUnit.businessUnit}
					isViewOnly={isViewOnly}
					businessUnit={businessUnit}
					setFranchiseDetails={setFranchiseDetails}
					setBusinessAreaId={(value) => setBusinessAreaId(value)}
					setGroupCode={setGroupCode}
					setCompanyCode={setCompanyCode}
					isPrevious={isPrevious}
					data={allStepData && allStepData['firstForm']}
					appendAllStepData={appendAllStepData}
					updateAllStepData={updateAllStepData}
					action={id}
					saveStepsCompleted={saveStepsCompleted}
					copyBusinessArea={copyBusinessArea}
					isCopied={isCopied}
					setCopyBusinessArea={setCopyBusinessArea}
					businessAreaList={businessAreaList}
					userPermissions={userPermissions}
				/>
			);
			break;

		case 1:
			formToRender = (
				<LocationAddress
					nextStep={toNextStep}
					isNew={isNew}
					// formId={219}
					formId={businessUnit && businessUnit.businessUnit}
					isViewOnly={isViewOnly}
					previousStep={toPreviousStep}
					businessAreaId={businessAreaId}
					franchisesSetupId={franchisesSetupId}
					businessUnit={businessUnit}
					isPrevious={isPrevious}
					data={allStepData && allStepData['secondForm']}
					appendAllStepData={appendAllStepData}
					updateAllStepData={updateAllStepData}
					action={id}
					saveStepsCompleted={saveStepsCompleted}
					isCopied={isCopied}
					userPermissions={userPermissions}
				/>
			);
			break;
		case 2:
			formToRender = (
				<ContactHistory
					previousStep={toPreviousStep}
					nextStep={toNextStep}
					isNew={isNew}
					// formId={219}
					isPrevious={isPrevious}
					formId={businessUnit && businessUnit.businessUnit}
					isViewOnly={isViewOnly}
					businessUnit={businessUnit}
					appendAllStepData={appendAllStepData}
					updateAllStepData={updateAllStepData}
					// data={allStepData && allStepData['thirdForm']}
					action={id}
					saveStepsCompleted={saveStepsCompleted}
					isCopied={isCopied}
					userPermissions={userPermissions}
				/>
			);
			break;
		case 3:
			formToRender = (
				<Franchisee
					previousStep={toPreviousStep}
					nextStep={toNextStep}
					isNew={isNew}
					formId={businessUnit && businessUnit.businessUnit}
					isViewOnly={isViewOnly}
					businessUnit={businessUnit}
					setFranchisesSetupId={setFranchisesSetupId}
					franchiseDetails={franchiseDetails}
					isPrevious={isPrevious}
					appendAllStepData={appendAllStepData}
					updateAllStepData={updateAllStepData}
					data={allStepData && allStepData['forthFom']}
					// franchiseeAddressData={allStepData && allStepData[3] && allStepData[3]['address']}
					// franchiseeSetupData={allStepData && allStepData[3] && allStepData[3]['franData']}
					action={id}
					saveStepsCompleted={saveStepsCompleted}
					userPermissions={userPermissions}
				/>
			);
			break;
		case 4:
			formToRender = (
				<TaxDetails
					previousStep={toPreviousStep}
					nextStep={toNextStep}
					isNew={isNew}
					formId={businessUnit && businessUnit.businessUnit}
					isCopied={isCopied}
					isViewOnly={isViewOnly}
					businessUnit={businessUnit}
					franchisesSetupId={franchisesSetupId}
					franchiseDetails={franchiseDetails}
					groupCode={businessUnit && businessUnit.groupCode}
					companyCode={businessUnit && businessUnit.companyCode}
					action={id}
					appendAllStepData={appendAllStepData}
					updateAllStepData={updateAllStepData}
					isPrevious={isPrevious}
					data={allStepData && allStepData['fifthForm']}
					saveStepsCompleted={saveStepsCompleted}
					userPermissions={userPermissions}
				/>
			);
			break;
		case 5:
			formToRender = (
				<CashierOffice
					previousStep={toPreviousStep}
					nextStep={toNextStep}
					isNew={isNew}
					formId={businessUnit && businessUnit.businessUnit}
					isCopied={isCopied}
					isViewOnly={isViewOnly}
					businessUnit={businessUnit}
					businessAreaId={businessAreaId}
					franchisesSetupId={franchisesSetupId}
					action={id}
					appendAllStepData={appendAllStepData}
					updateAllStepData={updateAllStepData}
					cashierOfficeData={allStepData && allStepData['sixthForm']}
					isPrevious={isPrevious}
					saveStepsCompleted={saveStepsCompleted}
					userPermissions={userPermissions}
				/>
			);
			break;
		case 6:
			formToRender = (
				<BranchAccount
					previousStep={toPreviousStep}
					nextStep={toNextStep}
					isNew={isNew}
					formId={businessUnit && businessUnit.businessUnit}
					isCopied={isCopied}
					isViewOnly={isViewOnly}
					businessAreaId={businessAreaId}
					businessUnit={businessUnit}
					franchisesSetupId={franchisesSetupId}
					groupCode={groupCode}
					companyCode={companyCode}
					action={id}
					appendAllStepData={appendAllStepData}
					updateAllStepData={updateAllStepData}
					houseBankMastersData={allStepData && allStepData['sevethForm']}
					isPrevious={isPrevious}
					saveStepsCompleted={saveStepsCompleted}
					userPermissions={userPermissions}
				/>
			);
			break;
		case 7:
			formToRender = (
				<ApprovalMatric
					previousStep={toPreviousStep}
					nextStep={toNextStep}
					isNew={isNew}
					//formId={219}
					formId={businessUnit && businessUnit.businessUnit}
					isViewOnly={isViewOnly}
					businessUnit={businessUnit && businessUnit.businessUnit}
					franchisesSetupId={franchisesSetupId}
					// groupCode={14}
					// companyCode={68}
					areaName={`${businessUnit && businessUnit.companyCodeKey} ${businessUnit && businessUnit.companyCodeDesc
						}`}
					isPrevious={isPrevious}
					companyCode={businessUnit && businessUnit.companyCode}
					companyCodeKey={businessUnit && businessUnit.companyCodeKey}
					groupCodekey={businessUnit && businessUnit.groupCodeKey}
					groupCode={businessUnit && businessUnit.groupCode}
					action={id}
					appendAllStepData={appendAllStepData}
					updateAllStepData={updateAllStepData}
					approvalSetupsData={allStepData && allStepData['eightForm']}
					saveStepsCompleted={saveStepsCompleted}
					userPermissions={userPermissions}
				/>
			);
			break;
		case 8:
			formToRender = (
				<SAPDetails
					previousStep={toPreviousStep}
					nextStep={toNextStep}
					isNew={isNew}
					formId={businessUnit && businessUnit.businessUnit}
					isViewOnly={isViewOnly}
					// businessUnit="123"
					businessUnit={businessUnit}
					action={id}
					sapDetails={allStepData && allStepData['ninethForm']}
					appendAllStepData={appendAllStepData}
					isPrevious={isPrevious}
					saveStepsCompleted={saveStepsCompleted}
					userPermissions={userPermissions}
				/>
			);
			break;
		case 9:
			formToRender = (
				<PineLabsSetup
					previousStep={toPreviousStep}
					nextStep={toNextStep}
					isNew={isNew}
					formId={formId}
					isViewOnly={isViewOnly}
					businessAreaId={businessAreaId}
					businessUnit={businessUnit}
					franchisesSetupId={franchisesSetupId}
					groupCode={businessUnit && businessUnit.groupCode}
					companyCode={businessUnit && businessUnit.companyCode}
					action={id}
					appendAllStepData={appendAllStepData}
					isPrevious={isPrevious}
					saveStepsCompleted={saveStepsCompleted}
					pineLabsKeyData={
						allStepData &&
						allStepData['thenthForm'] &&
						allStepData['thenthForm']['pineLabsKeyData']
					}
					pineLabsTableData={
						allStepData &&
						allStepData['thenthForm'] &&
						allStepData['thenthForm']['pineLabsTableData']
					}
					userPermissions={userPermissions}
				/>
			);
			break;
		case 10:
			formToRender = (
				<LastNumberGenerated
					previousStep={toPreviousStep}
					nextStep={toNextStep}
					isNew={isNew}
					formId={businessUnit && businessUnit.businessUnit}
					isCopied={isCopied}
					isViewOnly={isViewOnly}
					// businessUnit={518}
					// businessUnit={503}
					businessUnit={businessUnit}
					businessKey={businessUnit && businessUnit.businessKey}
					action={id}
					appendAllStepData={appendAllStepData}
					isPrevious={isPrevious}
					saveStepsCompleted={saveStepsCompleted}
					data={allStepData && allStepData['eleventhForm']}
					userPermissions={userPermissions}
				/>
			);
			break;
		default:
			formToRender = null;
	}

	let BussinessUnitTypeStr = '';
	stepCompleted.length > 0 &&
		businessUnit &&
		businessUnit.businessUnitType &&
		businessUnit.businessUnitType.map((item, i) => {
			i === 0
				? (BussinessUnitTypeStr = `${item && item.label}`)
				: (BussinessUnitTypeStr =
					BussinessUnitTypeStr + `, ${item && item.label}`);
		});

	const navigate = () => {
		if (chkForBusinessUnit()) {
			setActiveStep(3);
			setIsPrevious(true);
		}
	};

	return (
		userPermissions ?
		<>
			<div className="overflow-auto">
				{/* commenting this for first relaese afther that the below code should be umcommented */}
				{/* <Stepper
					activeStep={activeStep}
					nonLinear={true}
					// completedSteps={[stepCompleted]}
					steps={[
						{
							label: 'Basic Information',
							onClick: (e) => {
								//console.log('inside first step');
								e.preventDefault();
								setActiveStep(0);
								setIsPrevious(true);
							},
							completed: stepCompleted.indexOf(1) > -1 && activeStep !== 0,
							active: activeStep === 0,
							styleConfig: {
								activeBgColor: '#5096FF',
								completedBgColor: '#06D755',
							},
						},
						{
							label: 'Add Address',
							onClick: (e) => {
								//console.log('inside second step');
								e.preventDefault();
								setActiveStep(1);
								setIsPrevious(true);
							},
							//active: false,
							completed: stepCompleted.indexOf(2) > -1 && activeStep !== 1,
							disabled: stepCompleted.indexOf(2) < 0 && activeStep !== 1,
							styleConfig: {
								activeBgColor: '#5096FF',
								completedBgColor: '#06D755',
							},
						},
						{
							label: 'Add Contact History',
							onClick: (e) => {
								e.preventDefault();
								setActiveStep(2);
								setIsPrevious(true);
							},
							completed: stepCompleted.indexOf(3) > -1 && activeStep !== 2,
							disabled: stepCompleted.indexOf(3) < 0 && activeStep !== 2,
							styleConfig: {
								activeBgColor: '#5096FF',
								completedBgColor: '#06D755',
							},
						},
						{
							label: 'Franchisee Setup',
							onClick: (e) => {
								e.preventDefault();
								navigate();
							},
							completed:
								stepCompleted.indexOf(4) > -1 &&
								chkForBusinessUnit() &&
								activeStep !== 3,
							//active : activeStep === 3 ? true : false,
							disabled:
								!chkForBusinessUnit() ||
								(activeStep !== 3 && stepCompleted.indexOf(4) < 0),
							styleConfig: {
								activeBgColor: '#5096FF',
								completedBgColor: '#06D755',
								inactiveBgColor: '#E0E0E0',
							},
						},
						{
							label: 'Add Tax Details',
							onClick: (e) => {
								e.preventDefault();
								setActiveStep(4);
								setIsPrevious(true);
							},
							completed: stepCompleted.indexOf(5) > -1 && activeStep !== 4,
							disabled: stepCompleted.indexOf(5) < 0 && activeStep !== 4,
							styleConfig: {
								activeBgColor: '#5096FF',
								completedBgColor: '#06D755',
							},
						},
						{
							label: 'Cashier Office',
							onClick: (e) => {
								e.preventDefault();
								setActiveStep(5);
								setIsPrevious(true);
							},
							completed: stepCompleted.indexOf(6) > -1 && activeStep !== 5,
							disabled: stepCompleted.indexOf(6) < 0 && activeStep !== 5,
							styleConfig: {
								activeBgColor: '#5096FF',
								completedBgColor: '#06D755',
							},
						},
						{
							label: 'Branch Account',
							onClick: (e) => {
								e.preventDefault();
								setActiveStep(6);
								setIsPrevious(true);
							},
							completed: stepCompleted.indexOf(7) > -1 && activeStep !== 6,
							disabled: stepCompleted.indexOf(7) < 0 && activeStep !== 6,
							styleConfig: {
								activeBgColor: '#5096FF',
								completedBgColor: '#06D755',
							},
						},
						{
							label: 'Concession Approval Setup',
							onClick: (e) => {
								e.preventDefault();
								setActiveStep(7);
								setIsPrevious(true);
							},
							completed: stepCompleted.indexOf(8) > -1 && activeStep !== 7,
							disabled: stepCompleted.indexOf(8) < 0 && activeStep !== 7,
							styleConfig: {
								activeBgColor: '#5096FF',
								completedBgColor: '#06D755',
							},
						},
						{
							label: 'SAP Details',
							onClick: (e) => {
								e.preventDefault();
								setActiveStep(8);
								setIsPrevious(true);
							},
							completed: stepCompleted.indexOf(9) > -1 && activeStep !== 8,
							disabled: stepCompleted.indexOf(9) < 0 && activeStep !== 8,
							styleConfig: {
								activeBgColor: '#5096FF',
								completedBgColor: '#06D755',
							},
						},
						{
							label: 'Pine Labs Setup',
							onClick: (e) => {
								e.preventDefault();
								setActiveStep(9);
								setIsPrevious(true);
							},
							completed: stepCompleted.indexOf(10) > -1 && activeStep !== 9,
							disabled: stepCompleted.indexOf(10) < 0 && activeStep !== 9,
							styleConfig: {
								activeBgColor: '#5096FF',
								completedBgColor: '#06D755',
							},
						},
						{
							label: 'Last Number Generated',
							onClick: (e) => {
								e.preventDefault();
								setActiveStep(10);
								setIsPrevious(true);
							},
							completed: stepCompleted.indexOf(11) > -1 && activeStep !== 10,
							disabled: stepCompleted.indexOf(11) < 0 && activeStep !== 10,
							styleConfig: {
								activeBgColor: '#5096FF',
								completedBgColor: '#06D755',
							},
						},
					]}
				/> */}
			</div>
			{isLoading ? (
				<div
					className="mx-auto text-center py-5 my-5"
					style={{ height: '100vh' }}
				>
					<CustomLoader
						apiLoader={isLoading}
						loaderHeight={'200px'}
						loaderWidth={'100%'}
					/>
				</div>
			) : (
				<Row className={`mt-3 mr-0 ml-0`}>
					{businessUnit ? (
						<Container fluid>
							<Card>
								<CardBody>
									<Row>
										<Col md={7}>
											<span>
												<b>Business Area :</b>
											</span>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<span>{businessUnit && businessUnit.businessKey}</span>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<span>{businessUnit && businessUnit.businessDesc}</span>
										</Col>
										<Col md={5}>
											<span>
												<b>Business Area Type :</b>
											</span>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<span>{businessUnit && BussinessUnitTypeStr}</span>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Container>
					) : null}
					{/* <Col md={10} className={`m-auto`}> */}
					{formToRender}
					{/* </Col> */}
				</Row>
			)}
		</> : <PermisionDenied />
	);
};

export default withRouter(MainBuForm);
