import React, { useState } from "react";
import AInput from "views/pages/manage/common/formFeilds/customInput/AInput";
import './style.scss'

const SearchFilter = ({
  disabled,
  appliedFilteredString,
  setAppliedFilteredString,
}) => {
  

  return (
    <div className="mb-2">
      <div>
        <AInput
          placeholder=""
          style={{
            height: "36px",
          }}
          value={appliedFilteredString}
          handler={(value) => {
            setAppliedFilteredString(value);
          }}
          crossIcon={appliedFilteredString?true:false}
          rightSearchIcon={!appliedFilteredString?true:false}
          crossHandler={() => {
            setAppliedFilteredString('');
          }}
          searchIconClickHandler={() => {
            // applyFilterHandler(searchedValue, searchInputParam);
          }}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default SearchFilter;
