import moment from "moment";

export const getOrdinal = number => number + (number % 10 === 1 && number % 100 !== 11 ? 'st' : number % 10 === 2 && number % 100 !== 12 ? 'nd' : number % 10 === 3 && number % 100 !== 13 ? 'rd' : 'th');

export const onAdmission = ['Registration Fee', 'Admission Kit & Digital Access', 'Tuition Fee-1st Installment', 'Tech + Exam Fee-1st installment', 'Classroom Fee Service-01st Installment']

export const getDraftSummaryData = (applicationDetails, feeBreakup, feebreakupTotalCal, businessAreas, courses, loanAvailed) => {

    try {
        // const installmentRawArray = Object.values(feeBreakup.data).reduce(
        //     (accumulator, currentValue) => accumulator.concat(currentValue),
        //     []
        // );
        // const yearGroupedData = installmentRawArray.reduce((result, current) => {
        //     const year = current.year;
        //     // If there's no entry for this year in the result, create one
        //     if (!result[year]) {
        //         result[year] = [];
        //     }
        //     // Push the current object into the corresponding year's array
        //     result[year].push({
        //         installmentName: current?.desc,
        //         dueDate: onAdmission.indexOf(current?.desc) === -1 ? moment(current?.dueDate).format("DD-MMM-YYYY") : 'On Admission',
        //         mrp: current?.baseAmount?.toFixed(2),
        //         concessions: current?.concessionPer ? parseFloat((current?.concessionPer)?.toFixed(2)) : '0.00',
        //         lumpSumPerscent: current?.lumpsumPer ? parseFloat(current?.lumpsumPer?.toFixed(2)) : "0.00",
        //         concessionAmount: current?.consession?.toFixed(2),
        //         totalTax: current?.tax?.toFixed(2),
        //         alreadyPaid: "0.00",
        //         netPayable: current?.netPayable?.toFixed(2),
        //         balancePending: current?.netPayable?.toFixed(2)
        //     });

        //     return result;
        // }, {});

        // const yearWiseTotal = {};
        // Object.keys(yearGroupedData).map(i => {
        //     const total = yearGroupedData[i].reduce(
        //         (accumulator, item) => {
        //             accumulator.totalMrp += parseFloat(item.mrp) || 0;
        //             accumulator.totalTotalTax += parseFloat(item.totalTax) || 0;
        //             accumulator.totalAlreadyPaid += parseFloat(item.alreadyPaid) || 0;
        //             accumulator.totalNetPayable += parseFloat(item.netPayable) || 0;
        //             accumulator.totalBalancePending += parseFloat(item.balancePending) || 0;
        //             accumulator.totalConcession += parseFloat(item.concessionAmount) || 0;
        //             return accumulator;
        //         },
        //         {
        //             totalMrp: 0,
        //             totalTotalTax: 0,
        //             totalAlreadyPaid: 0,
        //             totalNetPayable: 0,
        //             totalBalancePending: 0,
        //             totalConcession: 0
        //         }
        //     );

        //     yearWiseTotal[i] = {
        //         totalMrp: total?.totalMrp?.toFixed(2) ?? '0.00',
        //         totalTotalTax: total?.totalTotalTax?.toFixed(2) ?? '0.00',
        //         totalAlreadyPaid: total?.totalAlreadyPaid?.toFixed(2) ?? '0.00',
        //         totalNetPayable: total?.totalNetPayable?.toFixed(2) ?? '0.00',
        //         totalBalancePending: total?.totalBalancePending?.toFixed(2) ?? '0.00',
        //         totalConcession: total?.totalConcession?.toFixed(2) ?? '0.00',
        //     }
        // })

        // const combinedTotal = Object.values(yearWiseTotal).reduce((acc, item) => {
        //     for (const prop in item) {
        //         if (prop.startsWith('total')) {
        //             if (!acc[prop]) {
        //                 acc[prop] = item[prop];
        //             } else {
        //                 const oldValue = parseFloat(acc[prop].replace(/,/g, '')) || 0;
        //                 const newValue = parseFloat(item[prop].replace(/,/g, '')) || 0;
        //                 const totalValue = (oldValue + newValue).toFixed(2);
        //                 acc[prop] = totalValue.toString();
        //             }
        //         }
        //     }
        //     return acc;
        // }, {});
        const installmentRawArray = Object.values(feeBreakup.data).reduce(
            (accumulator, currentValue) => accumulator.concat(currentValue),
            []
        );
    
        const yearGroupedData = installmentRawArray.reduce((result, current) => {
            const year = current.year;
            // If there's no entry for this year in the result, create one
            if (!result[year]) {
                result[year] = [];
            }
            // Push the current object into the corresponding year's array
            result[year].push({ ...current });
            return result;
        }, {});
    
        const groupedOnInstallements = {};
        let instNo = 0;
    
        Object.keys(yearGroupedData).map(year => {
            const temp = {};
            yearGroupedData[year].map(item => {
                const installmentNo = moment(item.dueDate).format("DD-MM-YYYY");
                const installmentNumber = item.itemType.slice(-4, -2);
                const itemType = item.itemType.slice(-2);
                const key = itemType === '29' || itemType === '30' ? itemType : installmentNumber;
                console.log(key)
                temp[key] = {
                    installmentName: itemType === '29' ? 'Registration Fee' : itemType === '30' ? 'Admission Kit & Digital Access' : `${getOrdinal(Number(installmentNumber))} Installment`,
                    dueDate: installmentNumber !== '00' && installmentNumber !== '01' ? moment(item.dueDate).format("DD-MMM-YYYY") : 'On Admission',
                    mrp: ((temp?.[key]?.mrp ?? 0) + item.baseAmount),
                    concessionAmount: ((temp?.[key]?.concessionAmount ?? 0)) + item.consession,
                    totalTax: (temp?.[key]?.totalTax ?? 0) + item.tax,
                    netPayable : (temp?.[key]?.netPayable ?? 0) + item?.netPayable,
                    // concessionPendingApp: ((temp?.[key]?.concessionPendingApp ?? 0) + item.concApprovalPending),
                }
            })
    
            const tempArray = [];
            Object.keys(temp).forEach((el) => {
                console.log(el)
                tempArray.push({
                    ...temp[el]
                })
            })
    
            groupedOnInstallements[year] = tempArray;
        })
    
        const installmentsTotals = {};
    
        for (const key in groupedOnInstallements) {
            if (groupedOnInstallements.hasOwnProperty(key)) {
                installmentsTotals[key] = groupedOnInstallements[key].reduce((acc, item) => ({
                    totalMrp: acc.totalMrp + item.mrp,
                    totalTotalTax: acc.totalTotalTax + item.totalTax,
                    totalConcession: acc.totalConcession + item.concessionAmount,
                    // totalconcessionPendingApp: acc.totalconcessionPendingApp + item.concessionPendingApp,
                    totalNetPayable : acc.totalNetPayable + item.netPayable,
                }), {
                    totalMrp: 0,
                    totalTotalTax: 0,
                    totalConcession: 0,
                    // totalconcessionPendingApp: 0,
                    totalNetPayable: 0,
                });
            }
        }
    
        const grandTotal = Object.values(installmentsTotals).reduce((acc, item) => ({
            totalMrp: acc.totalMrp + item.totalMrp,
            totalTotalTax: acc.totalTotalTax + item.totalTotalTax,
            totalConcession: acc.totalConcession + item.totalConcession,
            // totalconcessionPendingApp: acc.totalconcessionPendingApp + item.totalconcessionPendingApp,
            totalNetPayable : acc.totalNetPayable + item.totalNetPayable,
        }), {
            totalMrp: 0,
            totalTotalTax: 0,
            totalConcession: 0,
            // totalconcessionPendingApp: 0
            totalNetPayable: 0
        });
    


        return {
            studentId: applicationDetails.psid,
            acadamicBranch: applicationDetails.businessAreaValue,
            acadamicBranchLabel: businessAreas?.find(i => i.value === applicationDetails.businessAreaId)?.desc ?? "",
            applicationNumber: applicationDetails.applicationId,
            salesBranch: "",
            fatherName: applicationDetails?.studentFatherName ?? '',
            issueDate: moment(new Date()).format('DD-MMM-YYYY'),//applicationDetails?.registrationPaymentDate ?? '',
            address: "",
            bajajFinUniqueId: loanAvailed ? applicationDetails?.applicationQuotationDetailsId : '',
            vpaAddress: "",
            netPayable: feebreakupTotalCal?.netPayable ?? '',
            totalMrp: feebreakupTotalCal?.totalMrp ?? '',
            totalPaidTillDate: "0.00",
            totalConcession: feebreakupTotalCal?.totalConcession ?? '',
            balancePayable: feebreakupTotalCal?.netPayable,
            term: applicationDetails.termValue,
            uniqueId: loanAvailed ? applicationDetails?.applicationQuotationDetailsId : '',
            vanDetails: "",
            ifscCode: "",
            beneficiaryName: "",
            courseId: applicationDetails.courseId,
            courseName: courses?.find(i => i.value === applicationDetails.courseId)?.label ?? '',
            installments: groupedOnInstallements,
            yearWiseTotal:installmentsTotals,
            grandTotal: grandTotal,
            sname: applicationDetails?.studentName,
            buId: applicationDetails.businessAreaId,
            emailAddress: applicationDetails?.email,
            phoneNo: applicationDetails?.phoneNo,
            altPhone: applicationDetails?.alternatePhoneNo
        }
    } catch (e) {
        console.log(e)
        return {}
    }

}

export const getCourseFeeSummary = (applicationDetails, feeBreakup, concessionDetails, businessAreas, courses, allCategories, loanAvailed) => {

    const securityInstallments = [
        {
            installmentName: '',
            dueDate: '',
            slipRefNo: '',
            mrp: '0.00',
            concessionAmount: "0.00",
            totalTax: "0.00",
        }
    ]

    const securityTotal = {
        mrp: '0.00',
        concessionAmount: "0.00",
        totalTax: "0.00",
    }

    const installmentRawArray = Object.values(feeBreakup.data).reduce(
        (accumulator, currentValue) => accumulator.concat(currentValue),
        []
    );

    const yearGroupedData = installmentRawArray.reduce((result, current) => {
        const year = current.year;
        // If there's no entry for this year in the result, create one
        if (!result[year]) {
            result[year] = [];
        }
        // Push the current object into the corresponding year's array
        result[year].push({ ...current });
        return result;
    }, {});

    const groupedOnInstallements = {};
    let instNo = 0;

    Object.keys(yearGroupedData).map(year => {
        const temp = {};
        yearGroupedData[year].map(item => {
            const installmentNo = moment(item.dueDate).format("DD-MM-YYYY");
            const installmentNumber = item.itemType.slice(-4, -2);
            const itemType = item.itemType.slice(-2);
            const key = itemType === '29' || itemType === '30' ? itemType : installmentNumber;
            console.log(key)
            temp[key] = {
                installmentName: itemType === '29' ? 'Registration Fee' : itemType === '30' ? 'Admission Kit & Digital Access' : `${getOrdinal(Number(installmentNumber))} Installment`,
                dueDate: installmentNumber !== '00' && installmentNumber !== '01' ? moment(item.dueDate).format("DD-MMM-YYYY") : 'On Admission',
                mrp: ((temp?.[key]?.mrp ?? 0) + parseFloat(item.baseAmount?.toFixed(2))),
                concessionAmount: (temp?.[key]?.concessionAmount ?? 0) + parseFloat(item.consession?.toFixed(2)),
                totalTax: ((temp?.[key]?.totalTax ?? 0) + parseFloat(item.tax?.toFixed(2))),
                netPayable : ((temp?.[key]?.netPayable ?? 0) + parseFloat(item.netPayable?.toFixed(2))),
                concessionPendingApp: ((temp?.[key]?.concessionPendingApp ?? 0) + parseFloat(item.concApprovalPending?.toFixed(2))),
                itemType: item.itemType
            }
        })

        const tempArray = [];
        Object.keys(temp).forEach((el) => {
            console.log(el)
            tempArray.push({
                ...temp[el]
            })
        })

        groupedOnInstallements[year] = tempArray;
    })

    const installmentsTotals = {};

    for (const key in groupedOnInstallements) {
        if (groupedOnInstallements.hasOwnProperty(key)) {
            installmentsTotals[key] = groupedOnInstallements[key].reduce((acc, item) => ({
                totalMrp: acc.totalMrp + parseFloat(item.mrp?.toFixed(2)),
                totalTotalTax: acc.totalTotalTax + parseFloat(item.totalTax?.toFixed(2)),
                totalConcession: acc.totalConcession + parseFloat(item.concessionAmount?.toFixed(2)),
                totalconcessionPendingApp: acc.totalconcessionPendingApp + parseFloat(item.concessionPendingApp?.toFixed(2)),
                totalNetPayable : acc.totalNetPayable + parseFloat(item.netPayable?.toFixed(2))
            }), {
                totalMrp: 0,
                totalTotalTax: 0,
                totalConcession: 0,
                totalconcessionPendingApp: 0,
                totalNetPayable : 0
            });
        }
    }

    const grandTotal = Object.values(installmentsTotals).reduce((acc, item) => ({
        totalMrp: acc.totalMrp + parseFloat(item.totalMrp?.toFixed(2)),
        totalTotalTax: acc.totalTotalTax + parseFloat(item.totalTotalTax?.toFixed(2)),
        totalConcession: acc.totalConcession + parseFloat(item.totalConcession?.toFixed(2)),
        totalconcessionPendingApp: acc.totalconcessionPendingApp + parseFloat(item.totalconcessionPendingApp?.toFixed(2)),
        totalNetPayable: acc.totalNetPayable + parseFloat(item.totalNetPayable?.toFixed(2)),
    }), {
        totalMrp: 0,
        totalTotalTax: 0,
        totalConcession: 0,
        totalconcessionPendingApp: 0,
        totalNetPayable: 0,
    });

    // const yearGroupedData = installmentRawArray.reduce((result, current) => {
    //     const year = current.year;
    //     // If there's no entry for this year in the result, create one
    //     if (!result[year]) {
    //         result[year] = [];
    //     }
    //     // Push the current object into the corresponding year's array
    //     result[year].push({...current});
    //     return result;
    // }, {});

    // const groupedOnInstallements = {};
    // let instNo = 0;

    // Object.keys(yearGroupedData).map((year) => {
    //     const temp = {};
    //     yearGroupedData[year].map(item => {
    //         const installmentNo = moment(item.dueDate).format("DD-MM-YYYY");
    //         const installmentNumber= item.itemType.slice(-4,-2);
    //         temp[installmentNo] = {
    //             dueDate: installmentNumber=== 0 || installmentNumber === 1 ?  'On Admission' : item.dueDate,
    //             mrpExcTax : ((temp?.[installmentNo]?.mrpExcTax ?? 0) + item.baseAmount),
    //             tax :item.tax,
    //             concession : ((temp?.[installmentNo]?.concession ?? 0) + item.consession),
    //             netpaybleExcTax: ((temp?.[installmentNo]?.netpaybleExcTax ?? 0) + item.baseAmount - item.consession),
    //             netpaybleIncTax: ((temp?.[installmentNo]?.netpaybleIncTax ?? 0) + item.netPayable),
    //         }
    //     }) 

    //     const tempArray = [];
    //     Object.keys(temp).sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate)).forEach((el) => {
    //         instNo = instNo + 1;
    //         tempArray.push({
    //             ...temp[el],
    //             installmentName: `${getOrdinal(instNo)} Installment Due Data: ${moment(temp[el]?.dueDate).format('DD-MMM-YY')}`
    //         })
    //     })

    //     groupedOnInstallements[year] = tempArray;
    // })

    // const yearGroupedData = installmentRawArray.reduce((result, current) => {
    //     const year = current.year;
    //     // If there's no entry for this year in the result, create one
    //     if (!result[year]) {
    //         result[year] = [];
    //     }
    //     // Push the current object into the corresponding year's array
    //     result[year].push({
    //         installmentName: current?.desc,
    //         dueDate: onAdmission.indexOf(current?.desc) === -1 ? moment(current?.dueDate).format("DD-MMM-YYYY") : 'On Admission',
    //         mrp: current?.baseAmount?.toFixed(2),
    //         concessionAmount: current?.consession?.toFixed(2),
    //         totalTax: current?.tax?.toFixed(2),
    //     });

    //     return result;
    // }, {});

    // const yearWiseTotal = {};
    // Object.keys(yearGroupedData).map(i => {
    //     const total = yearGroupedData[i].reduce(
    //         (accumulator, item) => {
    //             accumulator.totalMrp += parseFloat(item.mrp) || 0;
    //             accumulator.totalTotalTax += parseFloat(item.totalTax) || 0;
    //             accumulator.totalConcession += parseFloat(item.concessionAmount) || 0;
    //             return accumulator;
    //         },
    //         {
    //             totalMrp: 0,
    //             totalTotalTax: 0,
    //             totalConcession: 0
    //         }
    //     );

    //     yearWiseTotal[i] = {
    //         totalMrp: total?.totalMrp?.toFixed(2) ?? '0.00',
    //         totalTotalTax: total?.totalTotalTax?.toFixed(2) ?? '0.00',
    //         totalConcession: total?.totalConcession?.toFixed(2) ?? '0.00',
    //     }
    // })

    // const combinedTotal = Object.values(yearWiseTotal).reduce((acc, item) => {
    //     for (const prop in item) {
    //         if (prop.startsWith('total')) {
    //             if (!acc[prop]) {
    //                 acc[prop] = item[prop];
    //             } else {
    //                 const oldValue = parseFloat(acc[prop].replace(/,/g, '')) || 0;
    //                 const newValue = parseFloat(item[prop].replace(/,/g, '')) || 0;
    //                 const totalValue = (oldValue + newValue).toFixed(2);
    //                 acc[prop] = totalValue.toString();
    //             }
    //         }
    //     }
    //     return acc;
    // }, {});

    const getConcessionName = (subId, subSubId, nametoreturn) => {
        let name = ''
        allCategories.forEach(element => {
            if (subId) {
                const filteredObj = element?.subCategories.find(i => i.id === subId);
                if (filteredObj?.subCategoryDispVal) {
                    name = !nametoreturn ? filteredObj?.subCategoryDispVal : nametoreturn === 'cName' ? element?.categoryDispVal : filteredObj?.subCategoryDispVal
                }
            } else {
                element.subCategories.forEach(el => {
                    const filteredObj = el?.subSubCategory.find(i => i.id === subSubId);
                    if (filteredObj?.subSubCategoryDisplayValue) {
                        name = !nametoreturn ? filteredObj?.subSubCategoryDisplayValue : nametoreturn === 'cName' ? element?.categoryDispVal : nametoreturn === 'sName' ? el?.subCategoryDispVal : filteredObj?.subSubCategoryDisplayValue
                    }
                })
            }
        })

        return name
    }

    const concessionList = concessionDetails?.map(item => {
        return item.concessionType === 'CUSTOM' && item.isApproved || item.concessionType === 'DEFINED' ? {
            cName: item.concessionType === 'CUSTOM' ? 'CUSTOM' : getConcessionName(item?.concessionSubCategoryId, item?.concessionSubSubCategoryId, 'cName'),
            sName: item.concessionType === 'CUSTOM' ? '' : getConcessionName(item?.concessionSubCategoryId, item?.concessionSubSubCategoryId, 'sName'),
            ssName: item.concessionType === 'CUSTOM' ? '' : getConcessionName(item?.concessionSubCategoryId, item?.concessionSubSubCategoryId, 'ssName'),
            remark: item?.concessionRemarks ?? '',
            percent: item?.tutionFeeConcession?.toFixed(2) ?? '0.00'
        } : null
    })

    const documentCheckList = concessionDetails?.map(item => {
        return item.concessionType === 'CUSTOM' && item.isApproved || item.concessionType === 'DEFINED' ? {
            name: item.concessionType === 'CUSTOM' ? 'CUSTOM' : getConcessionName(item?.concessionSubCategoryId, item?.concessionSubSubCategoryId),
            dueData: '',
            status: item?.concessionDocuments?.length ? 'Submitted' : 'Pending',
            remark: item?.concessionRemarks ?? ''
        } : null
    })

    return {
        studentId: applicationDetails.psid,
        acadamicBranch: applicationDetails.businessAreaValue,
        acadamicBranchLabel: businessAreas?.find(i => i.value === applicationDetails.businessAreaId)?.desc ?? "",
        applicationNumber: applicationDetails.applicationId,
        salesBranch: "",
        fatherName: applicationDetails?.studentFatherName ?? '',
        issueDate: moment(new Date()).format('DD-MMM-YYYY'),//applicationDetails?.registrationPaymentDate ?? '',
        address: "",
        bajajFinUniqueId: loanAvailed ? applicationDetails?.applicationQuotationDetailsId : '',
        vpaAddress: "",
        term: applicationDetails.termValue,
        uniqueId: applicationDetails.termId,
        vanDetails: "",
        ifscCode: "",
        beneficiaryName: "Aakash Educational Services Limited",
        courseId: applicationDetails.courseId,
        courseName: courses?.find(i => i.value === applicationDetails.courseId)?.label ?? '',
        bookletId: applicationDetails?.applicationQuotationDetailsId,

        securityCourseId: "",
        securityCName: "",
        securityInstallments: securityInstallments,
        securityTotal: securityTotal,

        installments: groupedOnInstallements,
        installmentsTotals:installmentsTotals,
        grandTotal: grandTotal,

        concessionList: concessionList.filter(obj => obj !== null),
        documentCheckList: documentCheckList.filter(obj => obj !== null),
        sname: applicationDetails?.studentName,
        phoneNo: applicationDetails?.phoneNo,
        altPhone: applicationDetails?.alternatePhoneNo,

        emailAddress: applicationDetails?.email,
        address: applicationDetails?.address,
        buId: applicationDetails.businessAreaId
    }
}

export const getProvisionalSummary = (applicationDetails, feeBreakup, concessionDetails, businessAreas, courses, allCategories, loanAvailed) => {

    const securityInstallments = [
        {
            installmentName: '',
            dueDate: '',
            slipRefNo: '',
            mrp: '0.00',
            concessions: "0.00",
            lumpSumPerscent: "0.00",
            concessionAmount: "0.00",
            totalTax: "0.00",
            paidAmount: "0.00",
            netPayable: "0.00",
        }
    ]

    const securityTotal = {
        mrp: '0.00',
        concessions: "0.00",
        lumpSumPerscent: "0.00",
        concessionAmount: "0.00",
        totalTax: "0.00",
        paidAmount: "0.00",
        netPayable: "0.00"
    }

    const installmentRawArray = Object.values(feeBreakup.data).reduce(
        (accumulator, currentValue) => accumulator.concat(currentValue),
        []
    );

    const yearGroupedData = installmentRawArray.reduce((result, current) => {
        const year = current.year;
        // If there's no entry for this year in the result, create one
        if (!result[year]) {
            result[year] = [];
        }
        // Push the current object into the corresponding year's array
        result[year].push({ ...current });
        return result;
    }, {});

    const groupedOnInstallements = {};
    let instNo = 0;

    Object.keys(yearGroupedData).map(year => {
        const temp = {};
        yearGroupedData[year].map(item => {
            const installmentNo = moment(item.dueDate).format("DD-MM-YYYY");
            const installmentNumber = item.itemType.slice(-4, -2);
            const itemType = item.itemType.slice(-2);
            console.log(itemType)
            const key = itemType === '29' || itemType === '30' ? itemType : installmentNumber;
            temp[key] = {
                installmentName: itemType === '29' ? 'Registration Fee' : itemType === '30' ? 'Admission Kit & Digital Access' : `${getOrdinal(Number(installmentNumber))} Installment`,
                dueDate: installmentNumber !== '00' && installmentNumber !== '01' ? moment(item.dueDate).format("DD-MMM-YYYY") : 'On Admission',
                mrp: ((temp?.[key]?.mrp ?? 0) + item.baseAmount),
                concessionAmount: (temp?.[key]?.concessionAmount ?? 0) +  item.consession,
                totalTax: ((temp?.[key]?.totalTax ?? 0) +  item.tax),
                concessionPendingApp: ((temp?.[key]?.concessionPendingApp ?? 0) +  item.concApprovalPending),
                netPayable: ((temp?.[key]?.netPayable ?? 0) +  item.netPayable),
            }
        })

        const tempArray = [];
        Object.keys(temp).forEach((el) => {
            // instNo = instNo + 1;
            tempArray.push({
                ...temp[el],
                // installmentName: `${getOrdinal(instNo)} Installment`
            })
        })

        groupedOnInstallements[year] = tempArray;
    })

    const installmentsTotals = {};

    for (const key in groupedOnInstallements) {
        if (groupedOnInstallements.hasOwnProperty(key)) {
            installmentsTotals[key] = groupedOnInstallements[key].reduce((acc, item) => ({
                totalMrp: acc.totalMrp +  item.mrp,
                totalTotalTax: acc.totalTotalTax +  item.totalTax,
                totalConcession: acc.totalConcession +  item.concessionAmount,
                totalconcessionPendingApp: acc.totalconcessionPendingApp +  item.concessionPendingApp,
                totalNetPayable: acc.totalNetPayable +  item.netPayable,
            }), {
                totalMrp: 0,
                totalTotalTax: 0,
                totalConcession: 0,
                totalconcessionPendingApp: 0,
                totalNetPayable: 0,
            });
        }
    }

    const grandTotal = Object.values(installmentsTotals).reduce((acc, item) => ({
        totalMrp: acc.totalMrp +  item.totalMrp,
        totalTotalTax: acc.totalTotalTax +  item.totalTotalTax,
        totalConcession: acc.totalConcession +  item.totalConcession,
        totalconcessionPendingApp: acc.totalconcessionPendingApp +  item.totalconcessionPendingApp,
        totalNetPayable: acc.totalNetPayable +  item.totalNetPayable,
    }), {
        totalMrp: 0,
        totalTotalTax: 0,
        totalConcession: 0,
        totalconcessionPendingApp: 0,
        totalNetPayable: 0
    });

    // const yearGroupedData = installmentRawArray.reduce((result, current) => {
    //     const year = current.year;
    //     // If there's no entry for this year in the result, create one
    //     if (!result[year]) {
    //         result[year] = [];
    //     }
    //     // Push the current object into the corresponding year's array
    //     result[year].push({
    //         installmentName: current?.desc,
    //         dueDate: onAdmission.indexOf(current?.desc) === -1 ? moment(current?.dueDate).format("DD-MMM-YYYY") : 'On Admission',
    //         mrp: current?.baseAmount?.toFixed(2),
    //         concessionAmount: current?.consession?.toFixed(2),
    //         totalTax: current?.tax?.toFixed(2),
    //         concessionPendingApp : (current?.concApprovalPending?.toFixed(2)),
    //     });

    //     return result;
    // }, {});

    // const yearWiseTotal = {};
    // Object.keys(yearGroupedData).map(i => {
    //     const total = yearGroupedData[i].reduce(
    //         (accumulator, item) => {
    //             accumulator.totalMrp += parseFloat(item.mrp) || 0;
    //             accumulator.totalTotalTax += parseFloat(item.totalTax) || 0;
    //             accumulator.totalConcession += parseFloat(item.concessionAmount) || 0;
    //             accumulator.totalconcessionPendingApp += parseFloat(item.totalconcessionPendingApp) || 0;
    //             return accumulator;
    //         },
    //         {
    //             totalMrp: 0,
    //             totalTotalTax: 0,
    //             totalConcession: 0,
    //             totalconcessionPendingApp:0
    //         }
    //     );

    //     yearWiseTotal[i] = {
    //         totalMrp: total?.totalMrp?.toFixed(2) ?? '0.00',
    //         totalTotalTax: total?.totalTotalTax?.toFixed(2) ?? '0.00',
    //         totalConcession: total?.totalConcession?.toFixed(2) ?? '0.00',
    //         totalconcessionPendingApp: total?.totalconcessionPendingApp?.toFixed(2) ?? '0.00',

    //     }
    // })

    // const combinedTotal = Object.values(yearWiseTotal).reduce((acc, item) => {
    //     for (const prop in item) {
    //         if (prop.startsWith('total')) {
    //             if (!acc[prop]) {
    //                 acc[prop] = item[prop];
    //             } else {
    //                 const oldValue = parseFloat(acc[prop].replace(/,/g, '')) || 0;
    //                 const newValue = parseFloat(item[prop].replace(/,/g, '')) || 0;
    //                 const totalValue = (oldValue + newValue).toFixed(2);
    //                 acc[prop] = totalValue.toString();
    //             }
    //         }
    //     }
    //     return acc;
    // }, {});


    const getConcessionName = (subId, subSubId, nametoreturn) => {
        let name = ''
        allCategories.forEach(element => {
            if (subId) {
                const filteredObj = element?.subCategories.find(i => i.id === subId);
                if (filteredObj?.subCategoryDispVal) {
                    name = !nametoreturn ? filteredObj?.subCategoryDispVal : nametoreturn === 'cName' ? element?.categoryDispVal : filteredObj?.subCategoryDispVal
                }
            } else {
                element.subCategories.forEach(el => {
                    const filteredObj = el?.subSubCategory.find(i => i.id === subSubId);
                    if (filteredObj?.subSubCategoryDisplayValue) {
                        name = !nametoreturn ? filteredObj?.subSubCategoryDisplayValue : nametoreturn === 'cName' ? element?.categoryDispVal : nametoreturn === 'sName' ? el?.subCategoryDispVal : filteredObj?.subSubCategoryDisplayValue
                    }
                })
            }
        })

        return name
    }

    const concessionList = concessionDetails?.map(item => {
        return item.concessionType === 'CUSTOM' && item.isApproved || item.concessionType === 'DEFINED' ? {
            cName: item.concessionType === 'CUSTOM' ? 'CUSTOM' : getConcessionName(item?.concessionSubCategoryId, item?.concessionSubSubCategoryId, 'cName'),
            sName: item.concessionType === 'CUSTOM' ? '' : getConcessionName(item?.concessionSubCategoryId, item?.concessionSubSubCategoryId, 'sName'),
            ssName: item.concessionType === 'CUSTOM' ? '' : getConcessionName(item?.concessionSubCategoryId, item?.concessionSubSubCategoryId, 'ssName'),
            remark: item?.concessionRemarks ?? '',
            percent: item?.tutionFeeConcession?.toFixed(2) ?? '0.00'
        } : null
    })

    const documentCheckList = concessionDetails?.map(item => {
        return item.concessionType === 'CUSTOM' && item.isApproved || item.concessionType === 'DEFINED' ? {
            name: item.concessionType === 'CUSTOM' ? 'CUSTOM' : getConcessionName(item?.concessionSubCategoryId, item?.concessionSubSubCategoryId),
            dueData: '',
            status: item?.concessionDocuments?.length ? 'Submitted' : 'Pending',
            remark: item?.concessionRemarks ?? ''
        } : null
    })

    return {
        studentId: applicationDetails.psid,
        acadamicBranch: applicationDetails.businessAreaValue,
        acadamicBranchLabel: businessAreas?.find(i => i.value === applicationDetails.businessAreaId)?.desc ?? "",
        applicationNumber: applicationDetails.applicationId,
        salesBranch: "",
        fatherName: applicationDetails?.studentFatherName ?? '',
        issueDate: moment(new Date()).format('DD-MMM-YYYY'),//applicationDetails?.registrationPaymentDate ?? '',
        address: "",
        bajajFinUniqueId: loanAvailed ? applicationDetails?.applicationQuotationDetailsId : '',
        vpaAddress: "",
        term: applicationDetails.termValue,
        uniqueId: applicationDetails.termId,
        vanDetails: "",
        ifscCode: "",
        beneficiaryName: "Aakash Educational Services Limited",
        courseId: applicationDetails.courseId,
        courseName: courses?.find(i => i.value === applicationDetails.courseId)?.label ?? '',
        bookletId: applicationDetails?.applicationQuotationDetailsId,

        securityCourseId: "",
        securityCName: "",
        securityInstallments: securityInstallments,
        securityTotal: securityTotal,

        installments: groupedOnInstallements,
        installmentsTotals: installmentsTotals,
        grandTotal: grandTotal,

        concessionList: concessionList.filter(obj => obj !== null),
        documentCheckList: documentCheckList.filter(obj => obj !== null),
        sname: applicationDetails?.studentName,


        phoneNo: applicationDetails?.phoneNo,
        altPhone: applicationDetails?.alternatePhoneNo,

        emailAddress: applicationDetails?.email,
        address: applicationDetails?.address,
        buId: applicationDetails.businessAreaId

    }
}