


import React from 'react'

const ListHeader = ({text}) => {
  return (
    <div style={{fontFamily:"sans-serif"}} className='heading-4 mb-2 mt-2 label-color'>
      {text||'Search students to print & issue ID card'}</div>
  )
}

export default ListHeader