import React, { useState, useRef, Fragment } from "react";
import { Dialog, CustomUpload } from "../../common/commonComponents";
import {
  failureToast,
  putpost,
  successToast,
  masterServiceBaseUrl,
} from "services/http";
import { getUniqueKey } from "../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint, constants } from "../../common/constant";

const DocumentUpload = (props) => {
  const { isModalVisible = false, setModalVisible = () => {}, getAllData=()=>{} } = props;

  const [isFileValid, setISFileValid] = useState(false);
  const [filename, setFileName] = useState("Drag & Drop to Upload");
  const [file, setFile] = useState(null);
  const [progressBarStatus, setProgressBarStatus] = useState(0);
  const [isUploading, setISUploading] = useState(false);

  const dataToServer = {
      bulk: true,
      fileName: null,
      docType: "csv",
      keyValue: `topic_${getUniqueKey()}`,
      serviceName: constants.templates_service_name.topic_master,
      subProcessType: constants.templates_sub_process_type.topic_master
  }

  const handleFile = (e) => {
    try{
      if(e.name.split(".")[1] === "csv"){
        setFileName(e.name);
        setFile(e);
        setISFileValid(true);
      }else{
        failureToast("Only csv files are allowed")
      }  
    }catch(e){
      console.log(e)
    }    
  };

  const saveFileAndReturnData = (data) => {
    putpost(
      `${endpoint.topic.createBulk}?csvUrl=${data.Location}&processInstanceId=${data.processId}`,
      (data) => {
        if (data.code === 200 && data.data) {
          setProgressBarStatus(100);
          setModalVisible(!isModalVisible);
          getAllData()
          successToast("File Uploaded Successfully")
          reset()
        }
      },
      (data) => {
        setISUploading(false);
        failureToast(data["message"]);
      },
      {},
      "post"
    );
  };

  const reset = () => {
    setISFileValid(false);
    setFileName("Drag & Drop to Upload");
    setFile(null);
    setProgressBarStatus(0);
    setISUploading(false);
  };

  return (
    <Dialog
      title="Bulk Topic Upload"
      isModalVisible={isModalVisible}
      toggle={() => {
        setModalVisible(!isModalVisible);
        reset();
      }}
    >
      <CustomUpload
        documentType="Topic Data"
        isFileValid={isFileValid}
        filename={filename}
        file={file}
        progressBarStatus={progressBarStatus}
        setISFileValid={setISFileValid}
        setFileName={setFileName}
        setFile={setFile}
        setProgressBarStatus={setProgressBarStatus}
        isUploading={isUploading}
        setISUploading={setISUploading}
        handleFile={handleFile}
        dataToServer={dataToServer}
        saveHandler={saveFileAndReturnData}
      />
    </Dialog>
  );
};

export default DocumentUpload;
