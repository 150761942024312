import React, { useContext } from "react";
import StudentWidgetAndFilters from "../studentCharges/studentFiltersandwidget";
import { OtherChargesContext, StudentListProvider } from "./otherChargesContext";
import { pages } from "../../common/constant";
import { PermissionContext, RolePermissions } from 'appContext';
import { PermisionDenied } from "../../common/commonComponents";
import OtherChargesList from ".";
import { getStudentList, loadDropdowns } from "redux/otherCharges/actions";

// import { IssuanceIDCardContext, IssuanceIDCardProvider } from "../../edpOps/issuanceIDcard/contextApi";


const pageStateMapping = {
  SEARCH: <StudentWidgetAndFilters
  storeState={'otherCharges'}
  getStudentList={getStudentList}
  loadDropdowns={loadDropdowns}
  studentContext={OtherChargesContext}
  showStudentTiles={false}
   />,
  LIST: <OtherChargesList />,
}

const GetPageComponentByState = () => {
  const { currentPageState } = useContext(OtherChargesContext);

  return (
    pageStateMapping[currentPageState]
  )
}

const StudentOtherCharges = () => {

  const userPermissions = RolePermissions(useContext(PermissionContext), pages['studentOtherCharges']['id']);

  return (
    userPermissions ? (
      <StudentListProvider>
        <GetPageComponentByState />
      </StudentListProvider>
    ) : (
      <PermisionDenied />
    )
  );
};

export default StudentOtherCharges;
