import React, { useEffect, useState } from "react";
import { Table, Input, Button } from "reactstrap";
import {
  AffiliationTableHeader,
  AffiliationTableTDRow,
} from "../../../../common/commonComponents/masterRow/masterRow";
import { failureToast } from "services/http";
import Select from "react-select";
const AffiliationTable = (props) => {
  const {
    externalOrganzationObj,
    setExternalOrganzationObj,
    setEditMode,
    editMode,
    updateAffiliationDetails,
    setAffiliationDetails,
    isDisabled,
    affiliationBoardList,
  } = props;
  const editAffiliationDetails = (index) => {
    try {
      if (editMode["editMode"] === true)
        return failureToast("Please close first one");
      setAffiliationDetails({
        affliationNumber:
          externalOrganzationObj["affliationDetailsList"][index][
            "affliationNumber"
          ],
        affliateBoard:
          externalOrganzationObj["affliationDetailsList"][index][
            "affliateBoard"
          ],
      });
      setEditMode({
        index: index,
        editMode: true,
      });
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  const closeEdit = (index) => {
    try {
      setEditMode({
        index: undefined,
        editMode: undefined,
      });
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  const deleteRow = (index) => {
    try {
      let tempAff = externalOrganzationObj["affliationDetailsList"];
      tempAff.splice(index, 1);
      setExternalOrganzationObj((preState) => ({
        ...preState,
        affliationDetailsList: tempAff,
      }));
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  return (
    <div id="questionTable" style={{ display: "block", width: "100%" }}>
      <Table className="align-items-center table-flush tableLayout">
        <AffiliationTableHeader />
        <tbody className="list">
          {" "}
          {externalOrganzationObj?.affliationDetailsList?.map((item, index) =>
            editMode && editMode["index"] === index && editMode["editMode"] ? (
              <tr key={index}>
                <td className="text-center p-2 mx-1">
                  <Input
                  data-testid="updateAffliationNumber"
                    defaultValue={item["affliationNumber"]}
                    name="affiliationNumber"
                    maxLength={10}
                    onChange={(e) => {
                      let affiliationNum = e.target.value;
                      setAffiliationDetails((preState) => ({
                        ...preState,
                        affliationNumber: affiliationNum,
                      }));
                    }}
                    className="tdInput"
                  />
                </td>
                <td className="text-center p-2 mx-1">
                  <Select
                    placeholder={item["affliateBoard"]}
                    options={props.affiliationBoardList}
                    onChange={(e) => {
                      let affiliateBoard = e.value;
                      setAffiliationDetails((preState) => ({
                        ...preState,
                        affliateBoard: affiliateBoard,
                      }));
                    }}
                  />
                </td>
                <td>
                  <Button
                   data-testid="updateAffiliationDetails"
                    color="info"
                    size="sm"
                    type="button"
                    className="mx-1"
                    onClick={() => {
                      updateAffiliationDetails(index);
                    }}
                  >
                    Save
                  </Button>
                  <Button
                  data-testid="closeRow"
                    color="danger"
                    size="sm"
                    type="button"
                    onClick={() => closeEdit(index)}
                  >
                    <i className="fas fa-times" />
                  </Button>
                </td>
              </tr>
            ) : (
              <AffiliationTableTDRow
                key={index + "-class"}
                tabletd={item}
                index={index}
                affiliationBoardList={affiliationBoardList}
                editAffiliationDetails={editAffiliationDetails}
                isDisabled={isDisabled}
                deleteRow={deleteRow}
              />
            )
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default AffiliationTable;
