import React, { useState, useEffect } from 'react';
import { Card } from 'reactstrap';
import CourseAttributeSessionTable from './courseAttrSessionTable';
import { masterServiceBaseUrl, failureToast, getAPI } from 'services/http';
import { PermisionDenied } from '../../../../../common/commonComponents';
import CustomLoader from '../../../../../common/commonComponents/Loader/loader';

const AttributeRow = (props) => {
	const { userPermissions } = props;
	const { isNew = true, formId, initialDate, isViewOnly = false } = props;
	const [classArray, setclassArray] = useState([]);
	const [apiloader, setApiLoader] = useState(false);
	const [attributeData, setAttributeData] = useState([]);

	const fetchAttributeData = () => {
		setApiLoader(true);
		getAPI(
			masterServiceBaseUrl +
				'/courseAttributeValue/getAllActiveCourseAttributeValue',
			(data) => {
				const list = data.data.map((data) => {
					return {
						value: data.id,
						label: data.courseAttributeValueDispValue,
						desc: data.courseAttributeValueDesc,
					};
				});
				setAttributeData(list);
				setApiLoader(false);
			},
			(data) => {
				failureToast(data['message']);
				setApiLoader(false);
			}
		);
	};
	const getAllAttributeMaster = () => {
		setApiLoader(true);
		getAPI(
			`${masterServiceBaseUrl}/courseAttributeValueMapping/getCourseAttributeValueMappingByCourseAttributeMapping/${formId}`,
			(data) => {
				setclassArray(data['data']);
				setApiLoader(false);
			},
			(data) => {
				failureToast(data['message']);
				setApiLoader(false);
			}
		);
	};

	useEffect(() => {
		fetchAttributeData();
	}, []);

	useEffect(() => {
		if (!isNew) {
			getAllAttributeMaster();
		}
	}, [isNew]);

	return (
		<>
			<Card>
				{apiloader ? (
					<CustomLoader
						apiLoader={apiloader}
						loaderHeight={'200px'}
						loaderWidth={'100% '}
					/>
				) : (
					<>
						{userPermissions ? (
							<>
								<CourseAttributeSessionTable
									attributeData={attributeData}
									setclassArray={setclassArray}
									fetchClasses={getAllAttributeMaster}
									classArray={classArray}
									formId={formId}
									isViewOnly={isViewOnly}
									initialDate={initialDate}
									userPermissions={userPermissions}
								/>
							</>
						) : (
							<PermisionDenied />
						)}
					</>
				)}
			</Card>
		</>
	);
};
export default AttributeRow;
