import React, { useEffect, useState, useContext, createContext } from 'react';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import { useHistory } from 'react-router';
import { PermissionContext, RolePermissions } from "../../../../../appContext";
import { PermisionDenied } from "../../common/commonComponents";
import AButton from "../../common/form-fields-mui/AButton";
import { CiFilter } from "react-icons/ci";
import { Container,  CardBody, Card, CardHeader } from 'reactstrap';
import { ConsessionFilter } from './consessionFilter';
import Modal from '@mui/material/Modal';
import { Grid, Typography } from '@material-ui/core';
import { endpoint, pages } from '../../common/constant';
import SubCategory from './subCategory';
import Category from './category';
import CustomLoader from '../../common/commonComponents/Loader/loader';
import SubSubCategory from './subsubCategory';
import { FaFile, FaTimes } from 'react-icons/fa';
import './index.scss';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import moment from 'moment';
import { Badge, Box, Dialog, IconButton, Tooltip } from '@mui/material';
import ConsessionTreeClassification from '../../../manage/common/commonComponents/treeView';
import CustomButton from 'components/CustomButton';

export const ConcessionSlabContext = createContext();

const FileSvg = () => {
    return <svg width="141" height="140" viewBox="0 0 141 140" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="0.5" width="139" height="139" rx="69.5" fill="#E9ECEF" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M60.025 45H80.9775C88.7 45 93 49.45 93 57.075V82.9C93 90.65 88.7 95 80.9775 95H60.025C52.425 95 48 90.65 48 82.9V57.075C48 49.45 52.425 45 60.025 45ZM60.7 56.65V56.625H68.1725C69.25 56.625 70.125 57.5 70.125 58.5725C70.125 59.675 69.25 60.55 68.1725 60.55H60.7C59.6225 60.55 58.75 59.675 58.75 58.6C58.75 57.525 59.6225 56.65 60.7 56.65ZM60.7 71.85H80.3C81.375 71.85 82.25 70.975 82.25 69.9C82.25 68.825 81.375 67.9475 80.3 67.9475H60.7C59.6225 67.9475 58.75 68.825 58.75 69.9C58.75 70.975 59.6225 71.85 60.7 71.85ZM60.7 83.275H80.3C81.2975 83.175 82.05 82.3225 82.05 81.325C82.05 80.3 81.2975 79.45 80.3 79.35H60.7C59.95 79.275 59.225 79.625 58.825 80.275C58.425 80.9 58.425 81.725 58.825 82.375C59.225 83 59.95 83.375 60.7 83.275Z" fill="#0F0F0F" />
        <rect x="1" y="0.5" width="139" height="139" rx="69.5" stroke="#E7E7E7" />
    </svg>
}


const screensMapped = ['cs', 'scs', 'sscs'];

const ScholarshipMaster = (props) => {

    const permissions = useContext(PermissionContext);
    const userPermissions = RolePermissions(permissions, pages["consession"]['id']);
    const history = useHistory();

    const { type, mode, categoryId, subCategoryId, subSubCategoryId } = props.match.params ?? { type: '', mode: '', categoryId: '', subCategoryId: '', subSubCategoryId: '' }
    const [filters, setFilters] = useState({
        id: '',
        code: '',
        title: '',
        description: '',
        rangeAndCriteria: '',
        applicabilityMode: '',
        applicabilityOn: '',
        concessionParameter: '',
        status: '',
        type: '',
        effectiveFrom: '',
        expiryDate: ''
    })
    const [open, setOpen] = useState(false);
    const [classificationData, setClassificationData] = useState([]);

    const [isRemoveFilter, setIsRemoveFilter] = useState(0);
    const [categoryData, setCategoryData] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [fetchingRightSideData, setFetchingRightSideData] = useState(false)
    //this will be used to render the right side screen
    const [rightScreenId, setRightScreen] = useState(null);

    //filters management
    const [filterStatus, setFilterStatus] = useState({
        applying: false,
        applied: false
    })
    const [categoryCodeData, setCategoryCodeData] = useState([]);

    const getAllCategoryCodes = async () => {
        setFetching(true);
        try {
            const response = await fetchAllPromisedData(`${endpoint.categoryCode}/all`);
            if (response) {
                const _codes = response?.filter(el=>el.status==='ACTIVE')?.map(item => ({ label: item.code, value: item.code }));
                setCategoryCodeData(_codes);
                setFetching(false);
            } else {
                failureToast("Failed to fetch category codes!");
                setFetching(false);
            }
        } catch (error) {
            setFetching(false);
            failureToast("Failed to fetch category codes!");
        }
    }

    useEffect(() => {
        getAllCategoryCodes();
    }, [])

    const getClassifaicationData = async () => {
        setFetching(true)
        const treeData = await fetchAllPromisedData(`${endpoint.treeView}`, false);
        if (Array.isArray(treeData)) {
            setClassificationData(treeData)
        }
        setFetching(false)
    }

    const [concessionSlabData, setConcessionSlabData] = useState(null);

    const getRightScreen = () => {
        switch (rightScreenId) {
            case 'cs': return <Category userPermissions={userPermissions} getClassifaicationData={getClassifaicationData} mode={mode} categoryId={categoryId} setFetching={(bool) => fetchingRightSideData(bool)} classificationData={classificationData} categoryCodeData={categoryCodeData} />
            case 'scs': return <SubCategory userPermissions={userPermissions} getClassifaicationData={getClassifaicationData} mode={mode} categoryId={categoryId} subCategoryId={subCategoryId} setFetching={(bool) => fetchingRightSideData(bool)} classificationData={classificationData} />
            case 'sscs': return <SubSubCategory userPermissions={userPermissions} getClassifaicationData={getClassifaicationData} mode={mode} categoryId={categoryId} subCategoryId={subCategoryId} subSubCategoryId={subSubCategoryId} setFetching={(bool) => fetchingRightSideData(bool)} classificationData={classificationData} />
            default: return <CardBody style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                <FileSvg />
                <Typography style={{ color: '#545454', marginTop: 20, fontSize: 16, width: 350, letterSpacing: 1, textAlign: 'center', lineHeight: 2 }}>Select an item from left side panel to
                    view the details</Typography>
            </CardBody>
        }
    }

    const submitHandler = async () => {

        if (!filters.id && !filters.code?.value && !filters.title && !filters.description && !filters.applicabilityMode?.value && !filters.applicabilityOn?.value && !filters.concessionParameter?.value && !filters.effectiveFrom && !filters.expiryDate && !filters.rangeAndCriteria?.value && !filters.status?.value) {
            failureToast("Please Select atleast one Criteria");
            return
        }

        const request = {
            externalId: filters.id !== '' ? filters.id : null,
            categoryCode: filters.code?.value ?? null,
            dispVal: filters.title !== '' ? filters.title : null,
            status: filters.status?.value ?? null,
            effectiveFrom: filters.effectiveFrom ?? null,
            effectiveTo: filters.expiryDate ?? null,
            availability: filters.applicabilityMode?.value ?? null,
            applicableOn: filters.applicabilityOn?.value ?? null,
            scholarshipCriteria: filters.rangeAndCriteria?.value ?? null,
            concessionParameter: filters.concessionParameter?.value ?? null,
            description: filters.description !== '' ? filters.description : null
        }

        setFilterStatus({ ...filterStatus, applying: true });

        const response = await fetchAllPostPromisedData(endpoint.masterFilter, request, 'post')
        if (response?.code === 200) {
            setFilterStatus({ ...filterStatus, applying: false, applied: true });
            setRightScreen(null)
            setClassificationData(response.data)
            // successToast(response['message']);
            setOpen(false)
        } else {
            setFilterStatus({ ...filterStatus, applying: false, applied: true });
            // failureToast(response?.['message']);
        }

    };

    const removeFilterHandler = () => {
        setFilters({
            id: '',
            code: '',
            title: '',
            description: '',
            rangeAndCriteria: '',
            applicabilityMode: '',
            applicabilityOn: '',
            concessionParameter: '',
            status: '',
            type: '',
            effectiveFrom: '',
            expiryDate: ''
        })
        getClassifaicationData();
        setFilterStatus({ ...filterStatus, applying: false, applied: false });
        setOpen(false)
    }

    useEffect(() => {
        if (type && screensMapped.indexOf(type) !== -1 && mode && ((type === 'cs') || (type === 'scs' && categoryId) || (type === 'sscs' && categoryId && subCategoryId))) {
            setRightScreen(type)
            setFetchingRightSideData(true)
        } else {
            setRightScreen(null)
        }
    }, [type, mode, categoryId, subCategoryId, subSubCategoryId])


    // useEffect(() => {
    //     if(){

    //     }
    // },[filters])

    const getAppliedFilterCount = () => {
        let count = 0;
        if (filterStatus.applied) {
            Object.keys(filters).map(item => {
                if (filters[item] !== '' && filters[item] !== null) {
                    count = count + 1;
                }
            })
        }
        return count
    }

    return (
        userPermissions ?
            <Container fluid className="scholarship-master">
                <Card>
                    <CardHeader className='justify-content-between'>
                        <Typography variant='h6'>Concession Master</Typography>
                        <Box className='d-flex'>
                            {/* <CustomButton
                                className={'regular-large'}
                                content={"Lump sum pay benefit config."}
                                forTable={true}
                                permissionType={'C'}
                                onClick={() => history.push(`/admin/consession/lumpsumConfig`)}
                                permissionSet={'C'}
                                // outline
                            /> */}
                            <CustomButton
                                className={'regular-large'}
                                content={"Scholarship Config."}
                                forTable={true}
                                permissionType={'C'}
                                onClick={() => history?.push('/admin/consession/scholarshipConfig')}
                                permissionSet={'C'}
                                // outline
                            />
                             <CustomButton
                                className={'regular-large'}
                                content={"LTA Config."}
                                forTable={true}
                                permissionType={'C'}
                                onClick={() => history?.push('/admin/consession/leftOutAdmissionConfig')}
                                permissionSet={'C'}
                                // outline
                            />
                            <CustomButton
                                className={'regular-large'}
                                content={"Ex-Aakashian Config."}
                                forTable={true}
                                permissionType={'C'}
                                onClick={() => history?.push('/admin/consession/exAakashianConfig')}
                                permissionSet={'C'}
                                // outline
                            />
                            {/** Ex-Aakashian config. */}
                            {/* <Typography className='ml-2' variant='body1' style={{ color: '#00B0F5', cursor: 'pointer' }} onClick={() => history?.push('/admin/consession/exAakashianConfig')}>
                                Ex-Aakashian Config.
                            </Typography> */}
                        </Box>
                    </CardHeader>
                </Card>

                <Grid container spacing={2} style={{ minHeight: '400px' }}>
                    <Grid item xs={4} >
                        <Card style={{ minHeight: '400px' }}>
                            <CardBody>
                                <Typography style={{ color: '#D29822', fontSize: 14 }}>Filter applied, showing restricted information.</Typography>
                                <Typography style={{ color: '#0F0F0F', marginTop: 10, fontSize: 20 }}>All concession</Typography>
                                <Typography style={{ color: '#757575', fontSize: 14, marginTop: 5, letterSpacing: 1 }}>Click on ‘+’ to view the list of all sub categories</Typography>
                                <div className='d-flex justify-content-between flex-row mt-3'>
                                    <Typography style={{ color: filterStatus.applied ? '#757575' : '#00B0F5', marginBottom: 20, fontSize: 16, cursor: 'pointer' }}
                                        onClick={!filterStatus.applied ? () => history.push(`/admin/consession/cs/new`) : () => { }}
                                    >
                                        Add new category
                                    </Typography>
                                    <Tooltip title="Filter" placement="top-start">
                                        <Badge badgeContent={getAppliedFilterCount()} color="primary">
                                        {/* <Typography style={{ color: '#00B0F5', fontSize: '16px', fontWeight: '600', cursor: 'pointer', padding: '0 5px 5px 5px' }}
                                        onClick={()=>setOpen(true)}
                                    >
                                        Filter
                                    </Typography> */}
                                            <AButton
                                                onClick={() => setOpen(true)}
                                                updatedStyle={{border: "1px solid #00B0F5",color:"#FFFFFF", backgroundColor: '#00B0F5', fontSize: '14px', fontWeight: '400',letterSpacing:"1px",padding:'2px 4px 2px 4px',height:'22px' }}
                                            >
                                               
                                                Filter
                                            </AButton>
                                            

                                        </Badge>
                                    </Tooltip>
                                </div>

                                <ConsessionTreeClassification
                                    classificationData={classificationData}
                                    fetching={fetching}
                                    getClassifaicationData={getClassifaicationData}
                                    isFilterApplied={filterStatus.applied}
                                    onCategoryClick={(c) => history.push(`/admin/consession/cs/view/${c.id}`)}
                                    onSubCategoryClick={(c, sc) => history.push(`/admin/consession/scs/view/${c.id}/${sc.id}`)}
                                    onSubSubCategoryClick={(c, sc, ssc) => history.push(`/admin/consession/sscs/view/${c.id}/${sc.id}/${ssc.id}`)}
                                    expandedArr={[`${categoryId}-c`, `${subCategoryId}-sc`, `${subSubCategoryId}-ssc`]}
                                    editTree={true}
                                />
                            </CardBody>
                        </Card>
                    </Grid>
                    <Grid item xs={8}>
                        <Card style={{ minHeight: '400px' }}>
                            <CardBody>
                                <ConcessionSlabContext.Provider value={{ concessionSlabData, setConcessionSlabData }}>
                                    {getRightScreen()}
                                </ConcessionSlabContext.Provider>
                            </CardBody>
                        </Card>
                    </Grid>

                </Grid>
                {/* <Dialog
                    isModalVisible={open}
                    title="Filter"
                    toggle={() => { setOpen(false) }}
                    size="xl"
                >
                    <ConsessionFilter

                        filterStatus={filterStatus}
                        setFilterStatus={setFilterStatus}
                        // inputHandler={inputHandler}
                        filters={filters}
                        setFilters={setFilters}
                        isAdvance={false}
                        submitHandler={submitHandler}
                        removeFilterHandler={removeFilterHandler}
                        categoryCodeData={categoryCodeData}
                    />
                </Dialog> */}
                <Dialog open={open}
                    sx={{ top: '10%' }}
                    maxWidth={'lg'}
                    onClose={()=>setOpen(false)}
                >
                    <Card className="mb-0">
                        <CardHeader>
                            <h4>Filter</h4>
                            <Tooltip title="Close"><IconButton onClick={() => setOpen(false)}><FaTimes /></IconButton></Tooltip>
                        </CardHeader>
                       <CardBody>
                        <ConsessionFilter
                            filterStatus={filterStatus}
                            setFilterStatus={setFilterStatus}
                            // inputHandler={inputHandler}
                            filters={filters}
                            setFilters={setFilters}
                            isAdvance={false}
                            submitHandler={submitHandler}
                            removeFilterHandler={removeFilterHandler}
                            categoryCodeData={categoryCodeData}
                        />
                       </CardBody>
                    </Card>
                </Dialog>
                {/* </APopover> */}

            </Container> : <PermisionDenied />
    )
}

export default ScholarshipMaster
