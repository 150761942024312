import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import AButton from 'views/pages/manage/common/form-fields-mui/AButton'
import { getDaysPlanById, planRowInitialForm, updateDaysPlan } from '../common'
import { ReactComponent as PlusIcon } from '../../../../../../assets/img/svg/PlusIcon.svg'
import styles from '../styles.module.scss'
import HeaderComponent from './HeaderComponent'
import ListComponent from './ListComponent'
import PageLoadingAnimation from 'views/pages/manage/common/commonComponents/PageLoadingAnimation'
import { failureToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function'
import { successToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function'
import { useHistory } from 'react-router'
import { PermissionContext, RolePermissions } from 'appContext'
import { pages } from 'views/pages/manage/common/constant'
import { PermisionDenied } from 'views/pages/manage/common/commonComponents'
import ALoader from 'views/pages/manage/common/a-loader'
import { checkPermission, dropdownMapping } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod'
import { endpoint } from 'views/pages/manage/common/constant'

const ViewEditDaysPlan = ({
  createFormData = {},
  createFormHandler = () => { },
  createPlanHandler = () => { },
  setCreateOpenForm = () => { },
}) => {

  const userPermissions = RolePermissions(useContext(PermissionContext), pages['daysPlan']['id']);

  const history = useHistory();
  const { action, daysPlanId } = useParams();
  const [fetchPlanDataLoader, setFetchPlanDataLoader] = useState(false);
  const [apiLoader, setApiLoader] = useState(false)
  const [dropdownLoader, setDropdownLoader] = useState(false)
  const [subjectDropdown, setSubjectDropdown] = useState([]);

  const createPlanData = {
    ...createFormData,
    businessAreas: createFormData?.businessAreas?.map(item => ({ dispValue: item?.label, id: item?.value })),
    regions: createFormData?.regions?.map(item => ({ dispValue: item?.label, id: item?.value })),
  }

  const [planData, setPlanData] = useState({});

  const fetchPlanData = async () => {
    setFetchPlanDataLoader(true);
    const res = await getDaysPlanById(daysPlanId);
    if (res?.code === 200) {
      let plan = {
        ...res?.data,
        regions: res?.data?.regions?.map(item => ({ dispValue: item?.regionDispValue, id: item?.regionId })),
        businessAreas: res?.data?.businessAreas?.map(item => ({ dispValue: item?.businessAreaDispValue, id: item?.businessAreaId })),
      }
      setPlanData(plan);
      setIsRowFormOpen(false);
      setRowForm(planRowInitialForm);
    } else {

    }
    setFetchPlanDataLoader(false);
  }

  const updateDaysPlanHandler = async (request) => {
    setApiLoader(true);
    // console.log('updating', request);
    const res = await updateDaysPlan(request, daysPlanId);
    if (res?.code === 200) {
      successToast(res?.message);
      fetchPlanData();
    }
    setApiLoader(false);
    return res;
  }

  const fetchSubjectDropdown = async () => {
    setDropdownLoader(true);
    try {
      const res = await dropdownMapping(endpoint?.subject?.getAllActive, 'subjectDispValue', 'subjectDispValue');
      console.log(res, 'subject')
      setSubjectDropdown(res);
    } catch (error) {
      console.error(error);
    } finally {
      setDropdownLoader(false);
    }
  }

  useEffect(() => {
    fetchSubjectDropdown();
    if (daysPlanId) {
      if (action === 'edit' || action === 'view') {
        // this condition is to avoid unnecessary actions
        fetchPlanData();
      } else {
        history.replace('/admin/pageNotFound');
      }
    }
  }, [daysPlanId, action])

  const [isRowFormOpen, setIsRowFormOpen] = useState(false);
  const [rowForm, setRowForm] = useState({});
  const [isNewRow, setIsNewRow] = useState(true);
  const rowFormHandler = (value, key) => setRowForm(prev => ({ ...prev, [key]: value }));

  const addNewRowHandler = async (isBreak = false) => {
    if (isRowFormOpen) {
      failureToast('Please save or discard current changes before adding new row');
    } else {
      setIsNewRow(true);
      let temp = { ...planRowInitialForm, ...(isBreak && { sessionName: 'Break' }) }
      setRowForm(temp);
      await setIsRowFormOpen(true);
    }
  }

  const editRowHandler = async (rowData) => {
    setIsNewRow(false);
    await setIsRowFormOpen(true);
    setRowForm(rowData);
  }
  const deleteRowHandler = async (rowData) => {
    // const rowId = rowData?.id;
    if (action === 'create') {
      let updatedPlans = createFormData?.plans?.filter(item => item?.id !== rowData?.id)
      // console.log(updatedPlans);
      createFormHandler(updatedPlans, 'plans');
    } else {
      let updatedPlans = planData?.plans?.filter(item => item?.id !== rowData?.id)
      const request = {
        daysPlanName: planData?.daysPlanName,
        businessAreas: planData?.businessAreas?.map(item => ({ businessAreaId: item?.id })),
        regions: planData?.regions?.map(item => ({ regionId: item?.id })),
        plans: updatedPlans,
      }
      updateDaysPlanHandler(request);
    }
  }
  const saveRowHandler = async (rowData) => {
    // console.log('this is save');
    if (action === 'create') {
      createFormHandler([{ ...rowData, id: new Date().toString() }, ...createFormData?.plans], 'plans');
      // console.log(rowForm);
      setIsRowFormOpen(false);
      setRowForm(planRowInitialForm);
    } else {
      // edit page save row handler here
      const request = {
        daysPlanName: planData?.daysPlanName,
        businessAreas: planData?.businessAreas?.map(item => ({ businessAreaId: item?.id })),
        regions: planData?.regions?.map(item => ({ regionId: item?.id })),
        plans: [...planData?.plans, { ...rowData, id: null }]
      }
      updateDaysPlanHandler(request);
    }
  }
  const updateRowHandler = async (rowData) => {
    // console.log('this is update');
    if (action === 'create') {
      let updatedPlans = createFormData?.plans?.map(item => item?.id === rowData?.id ? rowData : item);
      // console.log('plans', updatedPlans);
      createFormHandler(updatedPlans, 'plans');
      setIsRowFormOpen(false);
      setRowForm(planRowInitialForm);
    } else {
      let updatedPlans = planData?.plans?.map(item => item?.id === rowData?.id ? rowData : item);
      const request = {
        daysPlanName: planData?.daysPlanName,
        businessAreas: planData?.businessAreas?.map(item => ({ businessAreaId: item?.id })),
        regions: planData?.regions?.map(item => ({ regionId: item?.id })),
        plans: updatedPlans,
      }
      updateDaysPlanHandler(request);
    }
  }

  return (
    userPermissions ? (
      (fetchPlanDataLoader || apiLoader) ? (
        <PageLoadingAnimation />
      ) : (
        <div className={`remove-container-top-space ${styles?.view_plan_main_container}`}>
          {(fetchPlanDataLoader || apiLoader || dropdownLoader) && <ALoader position='fixed' />}
          <HeaderComponent
            planData={action === 'create' ? createPlanData : planData}
            createFormData={createFormData}
            createFormHandler={createFormHandler}
            updateDaysPlanHandler={updateDaysPlanHandler}
          />
          <ListComponent
            addNewRowHandler={addNewRowHandler}
            editRowHandler={editRowHandler}
            deleteRowHandler={deleteRowHandler}
            plans={action === 'create' ? createPlanData?.plans : planData?.plans}
            createPlanHanlder={createPlanHandler}
            setCreateOpenForm={setCreateOpenForm}
            isRowFormOpen={isRowFormOpen}
            setIsRowFormOpen={setIsRowFormOpen}
            isNewRow={isNewRow}
            rowForm={rowForm}
            setRowForm={setRowForm}
            rowFormHandler={rowFormHandler}
            saveRowHandler={saveRowHandler}
            updateRowHandler={updateRowHandler}
            subjectDropdown={subjectDropdown}
          />
          {(checkPermission(userPermissions, 'C') || checkPermission(userPermissions, 'U')) && action !== 'view' && (
            <div style={{ padding: '10px 20px' }}>
              <AButton
                className='button_remove_left_margin'
                variant='outline'
                updatedStyle={{ borderWidth: '1px', backgroundColor: '#FFFFFF', height: '28px', fontWeight: 400, fontSize: '12px', lineHeight: '16px', borderRadius: '4px' }}
                onClick={() => addNewRowHandler()}
              >
                <PlusIcon className='mr-2' /> Add new session
              </AButton>
              {(createPlanData?.plans?.length > 0 || planData?.plans?.length > 0) && (
                <AButton
                  variant='outline'
                  updatedStyle={{ borderWidth: '1px', backgroundColor: '#FFFFFF', height: '28px', fontWeight: 400, fontSize: '12px', lineHeight: '16px', borderRadius: '4px' }}
                  onClick={() => addNewRowHandler(true)}
                >
                  <PlusIcon className='mr-2' /> Add break
                </AButton>
              )}
            </div>
          )}
        </div>
      )
    ) : (
      <PermisionDenied />
    )
  )
}

export default ViewEditDaysPlan