import React from 'react'
import styles from './styles.module.scss'
import { AiOutlineInfoCircle } from 'react-icons/ai'

const ReturnDisabledAlertBadge = ({ msg }) => {
  return (
    <div className={`${styles['stock-return-disabled-alert']}`}>
      <AiOutlineInfoCircle size={16} />
      <span className='ml-2 regular-small'>{msg}</span>
    </div>
  )
}

export default ReturnDisabledAlertBadge