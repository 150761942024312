import React, { useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import RegionBusinessAreaCustomChip from '../../academicPlanAndTest/common/RegionBusinessAreaCustomChip';
import { ReactComponent as CloseIcon } from '../../../../../assets/img/svg/CrossIcon.svg'
import { chipContainerStyle } from './EditBranchesCommonComponent';
import AInput from '../formFeilds/customInput/AInput';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper';

// const chipContainerStyle = {
//   maxHeight: "120px",
//   overflowY: "auto",
//   display: "flex",
//   flexWrap: "wrap",
//   gap: "2px",
//   marginTop: "3px",
// };

// regions and businessAreas arrays passed to component need to be array of objects of type {dispValue: 'abc', id: 123}
const ViewBranchesCommonComponent = ({ regions, businessAreas, closeForm = () => { } }) => {

  const [userInput, setUserInput] = useState('');
  const filteredBusinessAreas = businessAreas?.filter(item => (item?.dispValue?.toLowerCase()?.includes(userInput?.toLowerCase())))
  const filteredRegions = regions?.filter(item => (item?.dispValue?.toLowerCase()?.includes(userInput?.toLowerCase())));

  return (
    <Card className='mb-0' style={{ position: 'relative', width: '370px' }}>
      <CardBody>
        <div className='d-flex justify-content-between align-items-start mb-3'>
          <div className='heading-4' style={{ color: '#414141' }}>View Branches</div>
          <IconButtonWrapper onClick={closeForm}><CloseIcon  /></IconButtonWrapper>
        </div>
        <div className={`mb-2`}>
          <AInput
            className=''
            value={userInput}
            handler={(value) => setUserInput(value)}
            crossIcon={false}
            searchIcon={true}
            searchIconClickHandler={() => { }}
            placeholder={'Search'}
            style={{ borderRadius: '8px', padding: '8px 16px', height: '45px', borderColor: '#CCCCCC', fontSize: '16px', paddingLeft: '16px', paddingRight: '36px' }}
            customSearchIconStyle={{ right: '16px', left: 'unset' }}
          />
        </div>
        {filteredRegions?.length > 0 && (
          <div className='mb-3'>
            <div>Region</div>
            <div style={chipContainerStyle}>
              {filteredRegions?.map((item) => (
                <RegionBusinessAreaCustomChip
                  key={item.id}
                  text={item.dispValue}
                  crossIcon={false}
                />
              ))}
            </div>
          </div>
        )}
        {filteredBusinessAreas?.length > 0 && (
          <div>
            <div>Business Area</div>
            <div style={chipContainerStyle}>
              {filteredBusinessAreas?.map((item) => (
                <RegionBusinessAreaCustomChip
                  key={item.id}
                  text={item.dispValue}
                  crossIcon={false}
                />
              ))}
            </div>
          </div>
        )}
        {!filteredRegions?.length && !filteredBusinessAreas?.length && (
          <div style={{color: '#CCCCCC', fontSize: '14px'}}>No results found</div>
        )}
      </CardBody>
    </Card>
  )
}
export default ViewBranchesCommonComponent