import { Button, Table, FormGroup, Modal, ModalBody, ModalFooter, Input, Col, InputGroup, Row, Label } from "reactstrap";
import React, { useState, useEffect } from "react";
import classnames from "classnames";
var _ = require('lodash');

const SimpleInput = (props) => {
  return (
    <>
      <FormGroup className="mb-3" key={props.name}>
        <label className="form-control-label" htmlFor="exampleFormControlSelect1">
          {props.title}
        </label>
        <InputGroup className={classnames("input-group-merge", { focused: false, })}>
          <Input
              autocomplete="off"
              style={{height:'40px'}}
              onChange={(e) => {
            if (props.onChange) { props.onChange(e); }
          }} id={props.id} name={props.name} type={props.type?props.type:"text"} innerRef={props.reference} placeholder={props.placeholder} defaultValue={props.defaultValue ? props.defaultValue : null} readOnly={props.readOnly || false} type={props.type ? props.type : 'text'} min={props.min ? props.min : null} maxLength={props.maxLength?props.maxLength:null} max={props.max ? props.max : null} />
        </InputGroup>
        {props.error ? _.get(props.error, props.name + '.type') === "required" &&
          (<div className="alert-danger-custom m-0 pt-1 px-0" style={{color:"red"}}>Required</div>) : null}
        {props.error ? _.get(props.error, props.name + '.type') === "maxLength" &&
          (<div className="alert-danger-custom m-0 pt-1 px-0">Max Length: {props.maxLength}</div>) : null}
        {props.error ? _.get(props.error, props.name + '.type') === "minLength" &&
          (<div className="alert-danger-custom m-0 pt-1 px-0">Min Length: {props.minLength}</div>) : null}
      </FormGroup>
    </>
  );
};
export { SimpleInput }