import React, { useEffect } from "react";
import { BankAccountDataRow, BankAccountHeaders } from "views/pages/manage/common/commonComponents/masterRow/masterRow";
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";
import { editTableRow, newTableRow } from "views/pages/manage/common/utils/methods/commonMethods/tableMethods";
import { Card, CardHeader, Input, Container, Button, Table, Row } from "reactstrap";
import CustomButton from "../../../../../../../components/CustomButton";
import { useState } from "react";
import DetailsModal from "./DetailsModal";
import { fetchAllPromisedData, fetchAllPostPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "views/pages/manage/common/constant";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import { successToast, failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import CustomLoader from "views/pages/manage/common/commonComponents/Loader/loader";
import { ReactComponent as BackArrow } from "../../../../../../../assets/img/svg/BackArrow.svg"
import { ReactComponent as ActiveControl } from "../../../../../../../assets/img/svg/ActiveControl.svg";
import { ReactComponent as InactiveControl } from "../../../../../../../assets/img/svg/InactiveControl.svg";

const BankAccountList = ({
    buDispVal,
    businessAreaDesc,
    setBuDispVal
}) => {
    const [isSaveVisible, setisSaveVisible] = useState(false);
    const [tableDataKey, settableDataKey] = useState("tableRowsKey");
    const [accountsArray, setAccountsArray] = useState([]);
    const [isDetailsModalOpen, setDetailsModalOpen] = useState(false);
    const [paymentModeDropdown, setPaymentModeDropdown] = useState([]);
    const [accNoDropdownModalData, setAccNoDropdownModalData] = useState([]);
    const [sapAccountDropdown, setSapAccountDropdown]=useState([]);
    const [loading, setLoading] = useState(false);
    const [oldRowData, setOldRowData] = useState({});
    const [isRowEdited, setRowEdited] = useState(false);
    const [viewDetails, setViewDetails] = useState(false);
    const [openedIndex, setOpenedIndex] = useState(null);

    useEffect(() => {
        // console.log('buDispVal', buDispVal);
        fetchData(endpoint.bankAccount, setAccountsArray, buDispVal)
        fetchPaymentDropdown();
        fetchData(endpoint.getHouseBankList, setAccNoDropdownModalData, null, setSapAccountDropdown);
    }, [])
    // useEffect(() => {
    //     console.log('accountsArray changed', accountsArray)
    // }, [accountsArray])
    const fetchData = async (url, setData, buDispVal, setSapAccountDropdown) => {
        if (buDispVal) {
            setLoading(true);
            url = `${url}/${buDispVal}`
        }
        const data = await fetchAllPromisedData(url, true)
        if (data?.code === 200) {
            setData(data?.data);

            if(setSapAccountDropdown){
                let distinctSapBankShortKeys=data?.data.reduce((acc,item)=>{
                    // console.log('item.houseBank', item.houseBank);
                    if(!acc.includes(item.houseBank)) acc.push(item.houseBank);
                    // console.log('acc', acc);
                    return acc;
                },[])
                console.log('distinctSapBankShortKeys', distinctSapBankShortKeys);
                setSapAccountDropdown(distinctSapBankShortKeys.map(item=>({label: item, value : item})))
            }

        if(data?.data?.length === 0)
            successToast("Please create your first account mapping");
        }
        setLoading(false);
    }
    const fetchPaymentDropdown = async () => {
        const data = await fetchAllPromisedData(endpoint.paymentMode.getActive, false);
        setPaymentModeDropdown(data.map((el) => (
            {
                label: el?.paymentModeDispValue,
                value: el?.paymentModeDispValue
            }
        )))
    }

    const emptyNewRow = { editMode: true, __status: "__new", businessAreaDispVal: buDispVal, paymentModeDispVal: null, status: "ACTIVE", houseBankId: null, mappedType: null, isDeleted: false, accountNumber: null, clientCode: null, effectiveDate:new Date()};

    const addNewAccount = () => {
        setAccountsArray([
            emptyNewRow,
            ...accountsArray,
        ]);
    };
    const newRow = () => {
        if (isSaveVisible) {
            failureToast("Please Delete or Save current changes");
        } else {
            //   console.log('classArray',props.classArray);
            newTableRow(accountsArray, setAccountsArray, setisSaveVisible, addNewAccount);
        }
    };
    const editRow = (index) => {
        if (isSaveVisible) {
            failureToast("Please Delete or Save current changes");
        } else {
            editTableRow(index, accountsArray, setAccountsArray, setisSaveVisible);
            setOldRowData({
                mappedType: accountsArray[index].mappedType,
                houseBankId: accountsArray[index].houseBankId,
                clientCode: accountsArray[index].clientCode,
                effectiveDate: accountsArray[index].effectiveDate,
                accountNumber: accountsArray[index].accountNumber
            })
        }
    };
    const updateKey = (index, value, key) => {
        console.log('updateKeyCalled', index, value, key);
        if (!["businessAreaDispVal", "paymentModeDispVal"].includes(key)) setRowEdited(true);
        var _tempArray = [...accountsArray];
        _tempArray[index][key] = value;
        setAccountsArray(_tempArray);
        // console.log('accountsArray', accountsArray);
    };
    const saveAll = async (index) => {
        let _data = accountsArray[index];
        let reqObj;
        if (_data?.paymentModeDispVal === 'ADB') {
            reqObj = {
                ..._data,
                houseBankId: 0,
            }
        } else {
            reqObj = {
                ..._data,
                accountNumber: null,
                clientCode: null
            }
        }

        if (reqObj["__status"] == "__new") {
            const createRecord = await fetchAllPostPromisedData(`${endpoint.bankAccount}`, reqObj, 'post')
            if (createRecord?.code === 200) {
                successToast('Account details added successfully!');
                setisSaveVisible(false);
                fetchData(endpoint.bankAccount, setAccountsArray, buDispVal)
            }
        } else {
            const updateRecord = await fetchAllPostPromisedData(`${endpoint.bankAccount}/${_data.id}`, reqObj, 'put');
            if (updateRecord?.code === 200) {
                successToast('Account details edited successfully!');
                setisSaveVisible(false);
                fetchData(endpoint.bankAccount, setAccountsArray, buDispVal)
            }
        }
    }
    const isNewRow = (index) => {
        return accountsArray[index]["__status"] == "__new"
    }
    const goBackWithDataLost = (index) => {
        if (isNewRow(index)) {
            updateKey(index, null, "houseBankId");
            updateKey(index, null, "mappedType");
            updateKey(index, null, "clientCode");
            updateKey(index, null, "effectiveDate");
            updateKey(index, null, "accountNumber");
        } else {
            updateKey(index, oldRowData?.houseBankId, 'houseBankId');
            updateKey(index, oldRowData?.mappedType, 'mappedType');
            updateKey(index, oldRowData?.clientCode, 'clientCode');
            updateKey(index, oldRowData?.effectiveDate, 'effectiveDate');
            updateKey(index, oldRowData?.accountNumber, 'accountNumber');
        }
        setRowEdited(false);
        setDetailsModalOpen(false);
    }
    const viewDetailsHandler = () => {
        setDetailsModalOpen(true);
        setViewDetails(true);
    }

    const statusChangeHandler = (el, index) => {
        if (el.status === 'ACTIVE') updateKey(index, 'INACTIVE', "status");
        else updateKey(index, 'ACTIVE', "status");
    }

    return (
        <>
            <Container className="mt-3" fluid>
                <Card>
                    {/* <CardBody> */}
                    <div className="d-flex align-items-center" style={{ height: '50px' }}>
                        <BackArrow onClick={() => { setBuDispVal(null) }} style={{ cursor: 'pointer', display: 'inline' }} />
                        <span className="h2 ml-2" style={{ color: '#45464E' }}>{`${businessAreaDesc} : Bank Account Set-up`}</span>
                    </div>
                    {/* </CardBody> */}
                </Card>
                <Row>
                    {loading ? (
                        <div className="col">
                            <Card className=" w-100">
                                <div
                                    className="mx-auto text-center py-5 my-5 "
                                    style={{ height: '100vh' }}
                                >
                                    <CustomLoader apiLoader={loading} />
                                </div>
                            </Card>
                        </div>) : (
                        <div className="col">
                            <Card>
                                <CardHeader className="border-0">
                                    <span style={{ color: '#45464E', fontSize: '20px', fontWeight: 700 }}>{`Bank Account Total- ${accountsArray?.length}`}</span>
                                    <AButton
                                        disabled={isSaveVisible}
                                        variant="primary_filled"
                                        onClick={() => newRow()}
                                        updatedStyle={{
                                            height: '28px',
                                            padding: '17px 16px',
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            borderRadius: '4px'
                                        }}
                                    >
                                        Add new account
                                    </AButton>
                                </CardHeader>
                                <div id="bankAccountsTable" className="table-responsive">
                                    <Table className="align-items-center table-flush tableLayout">
                                        <BankAccountHeaders />
                                        <tbody className="list" key={tableDataKey}>
                                            {accountsArray
                                                ? accountsArray.map((el, index) =>
                                                    !el["editMode"] ? (
                                                        <tr key={`${index}-account`}>
                                                            <BankAccountDataRow
                                                                index={index}
                                                                el={el}
                                                                editRow={editRow}
                                                                // deleteRow={deleteRow}
                                                                isSaveVisible={isSaveVisible}
                                                                viewDetailsHandler={viewDetailsHandler}
                                                                isDetailsModalOpen={isDetailsModalOpen}
                                                                setDetailsModalOpen={setDetailsModalOpen}
                                                                updateKey={updateKey}
                                                                accountsArray={accountsArray}
                                                                accNoDropdownModalData={accNoDropdownModalData}
                                                                isRowEdited={isRowEdited}
                                                                goBackWithDataLost={goBackWithDataLost}
                                                                viewDetails={viewDetails}
                                                                setViewDetails={setViewDetails}
                                                                openedIndex={openedIndex}
                                                                setOpenedIndex={setOpenedIndex}
                                                                buDispVal={buDispVal}
                                                                sapAccountDropdown={sapAccountDropdown}
                                                            />

                                                        </tr>
                                                    ) : (
                                                        <tr key={index + "-class"}>
                                                            <td className="text-center p-2 mx-1">
                                                                <AAutoComplete
                                                                    isMulti={false}
                                                                    currentValue={{ value: buDispVal, label: buDispVal }}
                                                                    items={[{ label: buDispVal, value: buDispVal }]}
                                                                    handleChange={(e) => updateKey(index, e.value, "businessAreaDispVal")}
                                                                    style={{ control: { width: '125px', marginLeft: '15px', borderRadius: '4px' } }}
                                                                />
                                                            </td>
                                                            <td className="text-center p-2 mx-1">
                                                                <AAutoComplete
                                                                    currentValue={paymentModeDropdown?.find(obj => obj.value === el["paymentModeDispVal"])}
                                                                    isMulti={false}
                                                                    items={paymentModeDropdown}
                                                                    handleChange={(e) => {
                                                                        updateKey(index, e.value, "paymentModeDispVal")
                                                                    }}
                                                                    style={{ control: { width: '125px', marginLeft: '15px', borderRadius: '4px' } }}
                                                                />
                                                            </td>
                                                            <td className="text-center p-2 mx-1">
                                                                {/* <label className="custom-toggle mx-auto ml-2">
                                <input
                                  checked={el.status == "ACTIVE" ? true : false}
                                  type="checkbox"
                                  id={el.id}
                                  onChange={(e) => {
                                    settableDataKey(new Date().getMilliseconds());
                                    let n = "ACTIVE";
                                    if (!e.target.checked) {
                                      n = "INACTIVE";
                                    }
                                    updateKey(index, n, "status");
                                  }}
                                  data-testid="bankAccountStatus"
                                />
                                <span
                                  style={{ width: "72px" }}
                                  className="custom-toggle-slider rounded-circle activeToggle"
                                  data-label-off="Inactive"
                                  data-label-on="Active"
                                />
                              </label> */}
                                                                <span>
                                                                    {el.status === 'ACTIVE' ?
                                                                        <ActiveControl onClick={() => statusChangeHandler(el, index)} /> :
                                                                        <InactiveControl onClick={() => statusChangeHandler(el, index)} />
                                                                    }
                                                                    <span
                                                                        style={{
                                                                            fontSize: "13px", marginLeft: "4px",
                                                                            color: '#525F7F'
                                                                        }}
                                                                    >{el.status === 'ACTIVE' ? 'Active' : 'Inactive'}</span>
                                                                </span>
                                                            </td>
                                                            <td className="text-center">
                                                                <AButton
                                                                    variant='primary_filled'
                                                                    size='xs'
                                                                    onClick={() => {
                                                                        setDetailsModalOpen(true);
                                                                        setOpenedIndex(index);
                                                                    }}
                                                                >
                                                                    {
                                                                        (el?.paymentModeDispVal === 'ADB' ? el?.clientCode : el?.houseBankId) && el?.mappedType ?
                                                                            "Edit Details" :
                                                                            "Add Details"
                                                                    }
                                                                </AButton>

                                                                {(openedIndex === index) && (<DetailsModal
                                                                    isDetailsModalOpen={isDetailsModalOpen}
                                                                    setDetailsModalOpen={setDetailsModalOpen}
                                                                    index={index}
                                                                    updateKey={updateKey}
                                                                    accountsArray={accountsArray}
                                                                    el={el}
                                                                    accNoDropdownModalData={accNoDropdownModalData}
                                                                    isRowEdited={isRowEdited}
                                                                    goBackWithDataLost={goBackWithDataLost}
                                                                    viewDetails={viewDetails}
                                                                    setViewDetails={setViewDetails}
                                                                    setRowEdited={setRowEdited}
                                                                    buDispVal={buDispVal}
                                                                    sapAccountDropdown={sapAccountDropdown}
                                                                />
                                                                )}

                                                            </td>
                                                            <td></td>
                                                            <td className="text-center">
                                                                {isSaveVisible ? (
                                                                    <>
                                                                        <AButton
                                                                            disabled={
                                                                                !(el?.businessAreaDispVal) ||
                                                                                !(el?.paymentModeDispVal) ||
                                                                                !(el?.mappedType) ||
                                                                                !(el?.paymentModeDispVal === 'ADB' ? el?.clientCode : el?.houseBankId)
                                                                            }
                                                                            variant="primary_filled"
                                                                            size="xs"
                                                                            onClick={() => {
                                                                                saveAll(index);
                                                                                setRowEdited(false);
                                                                                setOldRowData({});
                                                                            }}
                                                                        >
                                                                            Save
                                                                        </AButton>
                                                                        <CustomButton
                                                                            data-testId="closedDepartmentType"
                                                                            className={'mx-1'}
                                                                            permissionType={'cancel'}
                                                                            forTable={true}
                                                                            icon={true}
                                                                            onClick={() => {
                                                                                //   let n = accountsArray;
                                                                                //   if (n[index]["__status"] == "__new") {
                                                                                //     n.splice(index, 1);
                                                                                if (isNewRow(index)) {
                                                                                    setAccountsArray(accountsArray.splice(index + 1));

                                                                                } else {
                                                                                    fetchData(endpoint.bankAccount, setAccountsArray, buDispVal)
                                                                                }
                                                                                setisSaveVisible(false);
                                                                                setRowEdited(false);
                                                                                setOldRowData({});
                                                                            }}
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                                : null}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card>
                        </div>
                    )}
                </Row>
            </Container>
        </>
    );

}

export default BankAccountList;