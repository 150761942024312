import moment from "moment"
import { endpoint } from "views/pages/manage/common/constant";
import { fetchAllPostPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { fetchAllPromisedData, updateRequest } from "../../views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { ACADEMIC_PLAN_LIST_CLEAR, ACADEMIC_PLAN_LIST_FAILURE, ACADEMIC_PLAN_LIST_REQUEST, ACADEMIC_PLAN_LIST_SUCCESS, REQUEST_DROPDOWNS, UPDATE_DROPDOWNS } from "./actionTypes";
import { dropdownMapping } from "views/pages/manage/edpOps/constant/common";
import { getAPI } from 'services/http';
import { publishStatusDropdownData } from "views/pages/manage/academicPlanAndTest/constant/search-academic-plan";
import axios from 'axios'


export const requestDropdowns = () => {
  return {
    type: REQUEST_DROPDOWNS
  }
}

export const updateDropdowns = (data) => {
  return {
    type: UPDATE_DROPDOWNS,
    dropdowns: data
  }
}

export const getDropdowns = async () => {
  const p = await Promise.all([
    dropdownMapping(endpoint.academic_group.getAllActive, 'academicGroupDispValue'),
    dropdownMapping(endpoint.academic_career.getAllActive, 'academicCareerDispValue'),
    dropdownMapping(endpoint.academicPhase.getAllActive, 'academicPhaseDisplayValue'),
    dropdownMapping(endpoint.academic_year.getAllActive, 'academicYearDisplayValue'),
    dropdownMapping(endpoint.examBoardType.getAllActive, 'examBoardTypeDispValue'),
    dropdownMapping(endpoint.languageMaster.getActiveLanguageMaster, 'languageDispValue'),
    dropdownMapping(endpoint.classType.getAllActive, 'classTypeDispValue'),
    dropdownMapping(endpoint.region.getAllActive, 'regionDispValue'),
    dropdownMapping(endpoint.businessArea.getAllActive, 'businessAreaDispValue'),
    dropdownMapping(endpoint.classes.getAllActive, 'classDispValue'),
  ])
  return {
    academicGroup: p[0],
    academicCareer: p[1],
    academicPhase: p[2],
    academicYear: p[3],
    academicBoard: p[4],
    medium: p[5],
    classType: p[6],
    region: p[7],
    businessArea: p[8],
    classes: p[9],
    publishStatus: publishStatusDropdownData
  }
};

export const loadDropdowns = (cb) => {

  return async (dispatch) => {
    dispatch(requestDropdowns())
    const response = await getDropdowns();
    if (response) {
      dispatch(updateDropdowns(response));
      if (cb)
        cb(response)
    }
    else {
      dispatch(updateDropdowns({}))
    }
  };
};

// export const fetchAllBatchesByApplicationId = (applicationId) => {
//   return fetchAllPromisedData(`${endpoint.studentSearch.getBatchesByApplicationId}/${applicationId}`)

// }


// academic plans listing page actions from here
export const academicPlanListRequest = () => {
  return {
    type: ACADEMIC_PLAN_LIST_REQUEST
  }
}

export const academicPlanListSuccess = (data) => {
  return {
    type: ACADEMIC_PLAN_LIST_SUCCESS,
    payload: data?.academicPlanSearchResponseDTOList,
    rowCount: data?.totalRecords,
    totalServerPages: data?.totalPages
  }
}

export const academicPlanListFailure = (errMsg) => { 
  return {
    type: ACADEMIC_PLAN_LIST_FAILURE,
    payload: errMsg
  }
}

export const academicPlanListClear = () => {
  return {
    type: ACADEMIC_PLAN_LIST_CLEAR
  }
}

export const getPlanList = (filterData = {}, tabFilterData = {}, page = 0, allActiveBusinessArea = []) => {
  let updatedFilterData = { ...filterData };
  if (updatedFilterData?.businessArea?.length === 0) {
    updatedFilterData['businessArea'] = allActiveBusinessArea
  }
  return async (dispatch, getState) => {
    dispatch(academicPlanListRequest())
    const request = {...updateRequest(updatedFilterData), ...tabFilterData};
    // console.log(request);
    const response = await fetchAllPostPromisedData(`${endpoint.searchAcademicPlan.getPlanList}?requireDispValue=true&offset=${page}`, request, "post")
    // console.log(response)
    
    if(response?.code===200){
      dispatch(academicPlanListSuccess(response?.data))
    } else {
      dispatch(academicPlanListFailure(response?.message))
    }
  }
}

export const getPlanListForDropdown = (filterData = {}, tabFilterData = {}, page = 0, allActiveBusinessArea = []) => {
  let updatedFilterData = { ...filterData };
  if (updatedFilterData?.businessArea?.length === 0) {
    updatedFilterData['businessArea'] = allActiveBusinessArea
  }
  return async (dispatch, getState) => {
    dispatch(academicPlanListRequest())
    const request = {...updateRequest(updatedFilterData), ...tabFilterData};
    const response = await fetchAllPostPromisedData(`${endpoint.searchAcademicPlan.getPlanList}?requireDispValue=false&offset=${page}`, request, "post")

    if(response?.code===200){
      dispatch(academicPlanListSuccess(response?.data))
    } else {
      dispatch(academicPlanListFailure(response?.message))
    }
  }
}