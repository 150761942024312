import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import MachineTypeTable from './machineTypeTable';
import {getAPI,putpost,masterServiceBaseUrl,successToast,failureToast} from 'services/http';
import Loader from 'react-loader-spinner'
import { CardFooter, Pagination } from "reactstrap";
import { PaginationNode, PaginationPage, GetPagination } from '../../common/pagination'
const MachineType = () => {
  const [classArray, setclassArray] = useState([])
  const [apiloader, setapiloader] = useState(false)
  const [state, setstate] = useState([])
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null
  })
  const pageSize=10
  const fetchClasses = async (url) => {
    setapiloader(true)
    getAPI(url, (data) => {
      setstate(data['data'])
      let n = pagination
      if (data['data'].length > pageSize) {
        n['nextPage'] = n.currentPage + 1
      }
      if (n.currentPage > 1) {
        n.previousPage = n.currentPage - 1
      }
      n['totalPage'] =Math.floor( data['data'].length / pageSize)
      if(data['data'].length%pageSize!=0){
        n['totalPage']+=1
      }
      setpagination(n)
      setapiloader(false)
      setclassArray(data['data'].slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || [])
    }, (data) => { failureToast(data['message']) })
  }
  const nextPage = () => {
    setclassArray(state.slice((pagination.currentPage + 1) * (pageSize) - pageSize, (pagination.currentPage + 1) * pageSize))
    let n = pagination
    n['currentPage'] = n['currentPage'] + 1
    n['previousPage'] = n.currentPage
    setpagination(n)
  }
  const firstPage = () => {
    setclassArray(state.slice(1 * (pageSize) - pageSize, 1 * pageSize))
    let n = pagination
    n['currentPage'] = 1
    n['previousPage'] = null
    setpagination(n)
  }
  const lastPage = () => {
    setclassArray(state.slice((pagination.totalPage) * (pageSize) - pageSize, (pagination.totalPage) * pageSize))
    let n = pagination
    n['currentPage'] = n.totalPage
    n['previousPage'] = n.totalPage-1
    setpagination(n)
  }
  const previousPage = () => {
    if(pagination.currentPage!=1){
    setclassArray(state.slice((pagination.currentPage-1) * (pageSize) - pageSize, (pagination.currentPage-1) *pageSize  ))
    let n = pagination
    n['currentPage'] = n['currentPage'] -1
    if(n['currentPage']==1){
      n['previousPage'] = null
    }else{
    n['previousPage'] = n.currentPage
    }
    setpagination(n)
  }
  }
  useEffect(()=>{
    fetchClasses(masterServiceBaseUrl+'/machineType/getAllMachineType')
  },[])
  return (
    <>
     {apiloader ? <div className="mx-auto text-center py-5 my-5" style={{ height: "100vh" }}><Loader
        type="Rings"
        color="#00BFFF"
        height={70}
        width={70}
        timeout={10000} /></div> : <div></div>}
      <MachineTypeTable previousPage={previousPage}  fetchClasses={fetchClasses} setclassArray={setclassArray} classArray={classArray}/>
      {pagination.totalPage>1?<GetPagination fetchClasses={fetchClasses} pagination={pagination} previousPage={previousPage} firstPage={firstPage} lastPage={lastPage} nextPage={nextPage} setpagination={setpagination} />:null}
 </>
  );
}
export default MachineType;