import React, { useState } from "react";
import ADataGrid from "../../common/data-grid/ADataGrid";
import { AGridColDef } from "../../common/data-grid/ADataGridColDef";
import { useHistory } from "react-router";
import EmptyListPlaceholder from "./EmptyListPlaceholder";
import { Tooltip } from "@mui/material";
import ViewBatchDetailsDialog from "./ViewBatchDetailsDialog";
import { gridComponents, gridStyles } from "../issuanceIDcard/printIdCardAndMapRfid/constant";
import { isEligibleForSupplementaryId, requestSupplementaryId } from './constant';
import { checkPermission } from "../../common/utils/methods/commonMethods/utilityMethod";
import BulkSelectionFooter from "./BulkSelectionFooter";
import ALoader from "../../common/a-loader";

export const SUPPLEMENTARY_ID_FLAG = true; // temporary flag for controlling feature release
const StudentList = ({
  userPermissions,
  rowsData,
  rowCount,
  nextButtonHandler,
  currentPage,
  businessAreaMap
}) => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);
  const [openedPsid, setOpenedPsid] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [supplementaryIdLoader, setSupplementaryIdLoader] = useState(false);

  const isRowSelectable = (params) => {
    return isEligibleForSupplementaryId(params?.row);
  }

  const requestSupplementaryIdHandler = async () => {
    setSupplementaryIdLoader(true);
    try {
      const res = await requestSupplementaryId(selectionModel);
      if (res?.code === 200) {
        setSelectionModel([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSupplementaryIdLoader(false);
    }
  }

  const BatchListHeader = [
    new AGridColDef("psid", "PSID").renderCellComponent((params) => (
      <span
        onClick={() => window.open(history.createHref({ pathname: `/admin/edp/studentDetails/${params.value}` }), '_blank')}
        style={{ color: "#00B0F5", fontSize: "14px", cursor: "pointer" }}
      >
        {params.value}
      </span>
    )),
    new AGridColDef("applicationId", "Application no"),
    new AGridColDef("studentName", "Student name"),
    new AGridColDef("courseId", "Course"),
    new AGridColDef("businessArea", "Business area").renderCellComponent((params=>
     <span>{businessAreaMap[params.value]}</span> 
      )),
    new AGridColDef("classes", "Class"),
    new AGridColDef("programAction", "Program action"),
    new AGridColDef("Actions", "Action", false)
      .setWidth(200)
      .renderCellComponent((params) => (
        <Tooltip title="View Batches">
          <span
            onClick={() => {
              setOpenDialog(true);
              setOpenedPsid(params.row.applicationId);
            }}
            style={{
              color: " #00B0F5",
              cursor: "pointer",
            }}
          >
            View Batch
          </span>
        </Tooltip>
      )),
  ];
  const handleClose = () => {
    setOpenDialog(false);
    setOpenedPsid("");
  };
  return (
    <>
      {supplementaryIdLoader && <ALoader position={'fixed'} />}
      <ADataGrid
        rows={rowsData}
        columns={BatchListHeader}
        rowId={row => JSON.stringify(row)}
        loading={rowsData.length === 0}
        rowCount={rowCount}
        nextButtonHandler={nextButtonHandler}
        currentPage={currentPage}
        emptyRowPlaceholder={() => <EmptyListPlaceholder />}
        sortColumnName='psid'
        components={{
          ...gridComponents,
        }}
        sx={{
          ...gridStyles,
        }}
        hideFooterSelectedRowCount={true}
        checkboxSelection={SUPPLEMENTARY_ID_FLAG && checkPermission(userPermissions, ['C', 'U'])}
        disableSelectionOnClick
        selectionModel={selectionModel}
        onSelectionModelChange={newSelectionModel =>
          setSelectionModel(newSelectionModel)
        }
        isRowSelectable={isRowSelectable}
      />
      <ViewBatchDetailsDialog
        psid={openedPsid}
        handleClose={handleClose}
        open={openDialog}
      />
      <BulkSelectionFooter selectionModel={selectionModel} setSelectionModel={setSelectionModel} requestSupplementaryIdHandler={requestSupplementaryIdHandler} />
    </>
  );
};

export default StudentList;
