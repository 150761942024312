import { useEffect, useState } from "react";
import { endpoint } from "../../common/constant";
import { updateRequest } from "../../common/utils/methods/commonMethods/utilityMethod";
import { dropdownMapping } from "../constant/batch-search";
import {
  dispatchPendingTabStatus,
  dispatchedTabStatus,
  onHoldStatus,
} from "./dlpStockIssuance/issueStock/helper";
import {
  dlpLandingPageDropdown,
  fetchCourseIds,
  fetchDispatchIds,
  fetchDispatchScheduleApi,
  getBusinessAreaType,
  getDispatchIdMaterials,
  getOpenSAPPeriod,
  getStudentApplications,
} from "./service";

export const useStudentApplicationList = (
  accessBusinessAreas = [],
  url,
  dtOList
) => {
  const [applications, setApplications] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [issuedStockToStudent, setIssueStockToStudent] = useState([]);
  const getApplications = async (
    page = 0,
    updatedFilter = {},
    cb = () => {},
    rowsPerPage = 50
  ) => {
    try {
      setLoading(true);
      let request;
      if (!url) {
        const appliedBusinessArea = updatedFilter?.businessArea
          ? [updatedFilter?.businessArea.value]
          : accessBusinessAreas;
        request = { businessArea: appliedBusinessArea };
        const acdGroup = updatedFilter["academicGroup"]?.value;
        request["academicGroup"] = acdGroup ? [acdGroup] : [];
        if (updatedFilter?.typeOfCheckbox)
          request[updatedFilter?.typeOfCheckbox] = updatedFilter?.userInput;

        if (updatedFilter?.batchIds?.length > 0) {
          updatedFilter["allocationStatus"] = "ALLOCATE";
        }
      }

      const response = await getStudentApplications(
        updateRequest({ ...updatedFilter, ...request }),
        page,
        url,
        rowsPerPage
      );
      if (response?.code === 200) {
        const updatedResponse = url
          ? response?.data[dtOList].map((item)=>{
            return {...item,paymentDate:item?.registrationDate}
          })
          : response.data.studentApplicationDetailsSearchResponseDTOList;
          
        setApplications(updatedResponse);
        setTotalPage(response.data.totalPage);
        setTotalRecords(response?.data?.totalRecords);
        setLoading(false);
        setIssueStockToStudent(response?.data?.psidStockIssuanceResponseDTO);
        cb(updatedResponse);
      } else {
        setApplications([]);
        setTotalRecords(0);
        setTotalPage(0);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const resetApplications = () => {
    setApplications([]);
    setTotalRecords(0);
    setTotalPage(0);
    setLoading(false);
  };
  return [
    applications,
    isLoading,
    getApplications,
    totalPage,
    totalRecords,
    resetApplications,
    setApplications,
    issuedStockToStudent,
  ];
};

export const useFetchIssueDispatch = (page, request, selectedTab) => {
  // console.log(selectedTab);
  const [applications, setApplications] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const getApplications = async (
    page = 0,
    updatedFilter = {},
    cb = () => {}
  ) => {
    try {
      setLoading(true);

      if (updatedFilter?.typeOfCheckbox)
        request[updatedFilter?.typeOfCheckbox] = updatedFilter?.userInput;

      let status = updatedFilter?.status
        ? updatedFilter?.status
        : selectedTab === "DISPATCH_PENDING"
        ? dispatchPendingTabStatus
        : selectedTab == "ON_HOLD"
        ? onHoldStatus
        : dispatchedTabStatus;
      if (!Array.isArray(status)) status = [status?.value];
      const response = await getStudentApplications(
        { ...updatedFilter, status },
        page,
        endpoint?.searchStudentDLPStockEligible
      );
      if (response?.code === 200) {
        const updatedResponse =
          response.data.dlpApplicationDispatchScheduleList;
        setApplications(updatedResponse);
        setTotalPage(response.data.totalPage);
        setTotalRecords(response?.data?.totalRecords);
        setLoading(false);
        cb(updatedResponse);
      } else {
        setApplications([]);
        setTotalRecords(0);
        setTotalPage(0);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const resetApplications = () => {
    setApplications([]);
    setTotalRecords(0);
    setTotalPage(0);
    setLoading(false);
  };

  useEffect(() => {
    getApplications(page, { ...request });
  }, [selectedTab, page]);

  return [
    applications,
    isLoading,
    getApplications,
    totalPage,
    totalRecords,
    resetApplications,
    setApplications,
  ];
};

export const useGetCourseIdsForBUAndTerm = ({ businessArea, term }) => {
  const [termDropdown, setTermDropdown] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (businessArea?.value && term) fetchDropdownData();
  }, [businessArea?.value, term]);

  const fetchDropdownData = async () => {
    try {
      setIsLoading(true);
      const response = await fetchCourseIds(businessArea, term);
      setTermDropdown(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return [termDropdown, isLoading];
};

export const useGetDispatchIdsForBuTermAndCourseId = ({
  businessArea,
  term,
  courseId,
  academicCareer = "DLP",
}) => {
  const [dispatchIdDropdown, setDispatchIdDropdown] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (businessArea?.value && term.length > 0 && courseId?.value)
      fetchDropdownData();
  }, [businessArea?.value, term, courseId]);
  const fetchDropdownData = async () => {
    try {
      setIsLoading(true);
      const response = await fetchDispatchIds(
        businessArea,
        term,
        courseId,
        academicCareer
      );
      setDispatchIdDropdown(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return [dispatchIdDropdown, isLoading];
};

export const useGetDlpCourseIds = (form) => {
  const [courseIdsDropdown, setCourseIdDropdown] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (form?.academicGroup?.value) fetchDropdownData();
  }, [form?.academicGroup?.value]);

  const fetchDropdownData = async () => {
    try {
      setIsLoading(true);
      const response = await dropdownMapping(
        `${endpoint?.getAllActiveDispatchCourseIds}?academicGroupId=${form?.academicGroup?.value}`,
        "courseIds"
      );
      setCourseIdDropdown(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return [courseIdsDropdown, isLoading];
};

export const useFetchDispatchScheduleDropdown = ({
  businessArea,
  academicGroup,
  term,
  courseId,
  scheduleDateFrom,
  scheduleDateTo,
  academicCareer = "DLP",
}) => {
  const [dispatchDropdown, setDispatchDropdown] = useState([]);
  //  dispatchSchedule

  useEffect(() => {
    if (
      businessArea &&
      academicGroup &&
      term &&
      courseId &&
      scheduleDateFrom &&
      scheduleDateTo
    ) {
      const reqBody = {
        businessAreaId: businessArea,
        academicGroupId: academicGroup,
        termId: Array.isArray(term)?term:[term],
        courseId,
        scheduleDateFrom,
        scheduleDateTo,
        academicCareer: academicCareer === "DIGITAL" ? "ICON" : academicCareer,
      };
      fetchDispatchScheduleDropdown(reqBody, setDispatchDropdown);
    }
  }, [scheduleDateFrom, scheduleDateTo]);

  return [dispatchDropdown];
};

export const fetchDispatchScheduleDropdown = async (
  request,
  setDispatchDropdown
) => {
  const response = await fetchDispatchScheduleApi(updateRequest(request));
  if (response?.code === 200) {
    const { stockDispatchMasterList } = response?.data;

    setDispatchDropdown(
      stockDispatchMasterList.map((item) => ({
        value: item?.id,
        label: item?.dispatchId,
      }))
    );
  } else {
    setDispatchDropdown([]);
  }
};

export const useFetchDlpLandingPageDropdown = () => {
  const [dropdown, setDropdown] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDropdown = async () => {
      // setLoading(true);
      const p = await dlpLandingPageDropdown();
      const data = {
        term: p[0],
        academicGroup: p[1],
        dlpActiveBusinessArea: p[2],
        dlpCourseId: p[3],
      };
      setDropdown(data);
      setLoading(false);
    };
    fetchDropdown();
  }, []);
  return [dropdown, loading];
};

export const useGetDispatchSchedular = (dispatchSchedularId) => {
  const [selectedSchedular, setSelectedSchedular] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dispatchSchedularId)
      fetchDispatchSchedule(
        dispatchSchedularId,
        setSelectedSchedular,
        setLoading
      );
  }, [dispatchSchedularId]);

  return [selectedSchedular, loading];
};

export const fetchDispatchSchedule = async (
  dispatchSchedularId,
  setSelectedSchedular,
  setLoading = () => {}
) => {
  setLoading(true);
  const response = await fetchDispatchScheduleApi({
    dispatchScheduleId: dispatchSchedularId,
  });

  if (response?.code === 200) {
    const { stockDispatchMasterList } = response?.data;
    if (stockDispatchMasterList.length > 0) {
      const {
        createdBy,
        stockDispatchMaterialMappingList: materialDetails,
        dispatchDate,
        courseId,
        termDispValue,
        dispatchId,
        id,
      } = stockDispatchMasterList[0];
      setSelectedSchedular({
        createdBy,
        dispatchDate,
        courseId,
        termDispValue,
        dispatchId,
        materialDetails,
        id,
      });
    }
  } else {
    setSelectedSchedular({});
  }
  setLoading(false);
};

export const useGetBusinessAreaType = (typeList = []) => {
  const [buType, setType] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const response = await getBusinessAreaType();
      if (response?.code === 200) {
        // debugger;
        const ownedItem =
          response?.data?.filter(
            (item) => item?.businessUnitTypeDispValue === "Owned"
          )[0] || [];

        if (typeList.includes(+ownedItem["businessUnitTypeKey"])) setType(true);
      }
    };
    fetchData();
  }, [typeList]);

  return buType;
};

export const useFetchDispatchMaterialForPsid = (
  businessAreaId,
  dispatchScheduleId,
  psid
) => {
  const [details, setDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await getDispatchIdMaterials(
        businessAreaId,
        dispatchScheduleId,
        psid
      );
      setIsLoading(false);
      if (response) {
        setDetails(response);
      }
    };
    if (businessAreaId && dispatchScheduleId && psid) fetchData();
  }, [businessAreaId, dispatchScheduleId, psid]);

  return [details, isLoading];
};

export const useFetchOpenSapPeriod = () => {
  const [openPeriod, setOpenPeriod] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const response = await getOpenSAPPeriod();
    if (response?.code === 200) {
      //  console.log(response.data);
      setOpenPeriod(
        response?.data?.filter(
          (item) => item.sapPeriodStatus.toUpperCase() === "OPEN"
        )
      );
      setIsLoading(false);
    }
  };
  return openPeriod.length > 0 ? [openPeriod[0], isLoading] : [{}, isLoading];
};

export const useFetchDigitalLandingPageDropdown = () => {
  const [dropdown, setDropdown] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDropdown = async () => {
      // setLoading(true);
      const p = await dlpLandingPageDropdown("DIGITAL");
      const data = {
        term: p[0],
        academicGroup: p[1],
        businessArea: p[2],
        courseId: p[3] || [],
      };
      setDropdown(data);
      setLoading(false);
    };
    fetchDropdown();
  }, []);
  return [dropdown, loading];
};

export const useFetchDigitalIssueDispatch = (page, request, selectedTab) => {
  // console.log(selectedTab);
  const [applications, setApplications] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const getApplications = async (
    page = 0,
    updatedFilter = {},
    cb = () => {}
  ) => {
    try {
      setLoading(true);

      if (updatedFilter?.typeOfCheckbox)
        request[updatedFilter?.typeOfCheckbox] = updatedFilter?.userInput;

      let status = updatedFilter?.status
        ? updatedFilter?.status
        : selectedTab === "DISPATCH_PENDING"
        ? dispatchPendingTabStatus
        : selectedTab == "ON_HOLD"
        ? onHoldStatus
        : dispatchedTabStatus;
      if (!Array.isArray(status)) status = [status?.value];
      const response = await getStudentApplications(
        { ...updatedFilter, status, academicCareer: "ICON" },
        page,
        endpoint?.searchStudentDLPStockEligible
      );
      if (response?.code === 200) {
        const updatedResponse =
          response.data.dlpApplicationDispatchScheduleList;
        setApplications(updatedResponse);
        setTotalPage(response.data.totalPage);
        setTotalRecords(response?.data?.totalRecords);
        setLoading(false);
        cb(updatedResponse);
      } else {
        setApplications([]);
        setTotalRecords(0);
        setTotalPage(0);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const resetApplications = () => {
    setApplications([]);
    setTotalRecords(0);
    setTotalPage(0);
    setLoading(false);
  };

  useEffect(() => {
    getApplications(page, { ...request });
  }, [selectedTab, page]);

  return [
    applications,
    isLoading,
    getApplications,
    totalPage,
    totalRecords,
    resetApplications,
    setApplications,
  ];
};
