import { putpost } from "services/http";
import { fetchAllPromisedData } from "../../../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "../../../../common/constant";
import { failureToast } from "../../../../common/utils/methods/toasterFunctions/function";

export const getDownloadUrl = async(pid,level,setDownloading =() => {}) =>{
    setDownloading(true)
    const keyData =   await fetchAllPromisedData(`${endpoint.download_csv}/${pid}/${level}`);
    if(keyData.key){
        putpost(`${endpoint.fetchS3Url.getDownloadUrl}?key=${keyData.key}`,
            (data) => {
                if (data.code === 200 && data.data) {
                    window.open(`${data.data.url}`)
                    setDownloading(false)
                } else {
                    setDownloading(false)
                    failureToast("Sorry Something went wrong")
                    // failureToast(data.message)
                }
            },
            (data) => {
                setDownloading(false)
                failureToast("Sorry Something went wrong")
                // failureToast(data["message"]);
            },
            {},
            "post"
        );
    }else{
        failureToast(keyData.failureReason ?? "Sorry Something went wrong")
        setDownloading(false)
    }
}