import React, {Fragment, useEffect, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Collapse, FormGroup, Input, Label, Row} from "reactstrap";
import Select2 from "react-select2-wrapper";
import {fetchDetails, getDispValForReactSelectByOneID} from "../../../../common/utils/methods/commonMethods/utilityMethod";
import {masterServiceBaseUrl, getAPI} from "../../../../../../../services/http";
import {fetchData} from "../applicationIdFunctions";
import {RedAstric} from "../../../../examService/examIdGeneration/examIdSearchForm";
import CommonInput from "../../../../common/formFeilds/input/commonInput";
import ButtonWithLoader from "../../../../common/commonComponents/buttonWithLoader";
const CourseApplicationDetails =(props)=>{
    const {action,isEdit,formData,UpdateFormData,admissionType,courseIds,submitHandler,academicCareerData,academicGroupData,setCourseIds,
        term,saleType,businessArea,externalOrganization,aiatsData,setLoader,setExternalOrganization,setAiatsData,programAction,isLoading,label,placeholder
    } = props;
    useEffect(() => {
        if( action !== 'new' && formData['courseId'] !==''){
            setLoader(true)
            getAPI(masterServiceBaseUrl + `/courseAttributeValue/getCourseAttributeValueByCourseIdAndAttributeValue/${formData['courseId']}`,
                (data)=>{
                    UpdateFormData(2, 'courseType', data.data.courseType);
                    UpdateFormData(2,'courseClass',data.data.courseClass);
                    UpdateFormData(2,'courseCategory',data.data.courseCategory);
                    UpdateFormData(2,'courseClass',data.data.courseClass);
                    getAPI(masterServiceBaseUrl + `/courseDetail/getCourseDetailByCourseId/${formData['courseId']}`,
                        (data)=>{
                            UpdateFormData(2, 'courseName', data.data[0]['courseName']);
                            UpdateFormData(2, 'academicCareer', data.data[0]['academicCareerId']);
                            UpdateFormData(2, 'academicGroup', data.data[0]['academicGroupId']);
                            setLoader(false)
                        },(data)=>{

                        })
                },(data)=>{

                })
        }
    },[formData['courseId']])


    return(
        <div className='container-fluid'>
            <Row>
                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.businessArea} <RedAstric/></Label>
                        {
                            <Select2 options={{placeholder: placeholder.businessArea,}}
                                data={businessArea}
                                     disabled={action !=='new'}
                                     defaultValue={formData['businessArea']}
                                     onChange={(e) => {
                                         if(e.target.value) {UpdateFormData(2, 'businessArea', parseInt(e.target.value));}
                                     }}
                            />
                        }
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.term} <RedAstric/></Label>
                        {
                            <Select2 options={{placeholder: placeholder.term,}} data={term}
                                     disabled={action !=='new'}
                                     defaultValue={formData['term']}
                                     onChange={(e) => {
                                         if(e.target.value) { UpdateFormData(2, 'term', parseInt(e.target.value)); }
                                         if(e.target.value && formData.businessArea !== '' && action ==='new') {
                                             setCourseIds([])
                                             UpdateFormData(2, 'courseType', '');
                                             UpdateFormData(2, 'courseClass', '');
                                             UpdateFormData(2, 'courseCategory', '');
                                             UpdateFormData(2, 'courseClass', '');

                                             UpdateFormData(2, 'courseName', '');
                                             UpdateFormData(2, 'academicCareer', '');
                                             UpdateFormData(2, 'academicGroup', '');
                                             fetchData(masterServiceBaseUrl + `/courseCatalogMapping/getAllCourseIdWithCRTYAndAttributeValueMAINAndADDC/${formData.businessArea}/${e.target.value}?isForExam=false`, setCourseIds, 'courseId')
                                         }}}
                            />
                        }
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.courseId} <RedAstric/></Label>
                        {
                            <Select2 options={{placeholder: placeholder.courseId,}}
                                     data={courseIds}
                                     disabled={action !=='new' ||(formData['businessArea'] ==='' || formData['term'] ==='')}
                                     defaultValue={formData['courseId']}
                                     onChange={(e) => {
                                         if(e.target.value) {
                                             UpdateFormData(2, 'courseId', e.target.value)
                                             if(action ==='new' && e.target.value){
                                                 setLoader(true)
                                                 getAPI(masterServiceBaseUrl + `/courseAttributeValue/getCourseAttributeValueByCourseIdAndAttributeValue/${e.target.value}`,
                                                     (data) => {
                                                         UpdateFormData(2, 'courseType', data.data.courseType);
                                                         UpdateFormData(2, 'courseClass', data.data.courseClass);
                                                         UpdateFormData(2, 'courseCategory', data.data.courseCategory);
                                                         UpdateFormData(2, 'courseClass', data.data.courseClass);
                                                         getAPI(masterServiceBaseUrl + `/courseDetail/getCourseDetailByCourseId/${e.target.value}`,
                                                             (data) => {
                                                                 UpdateFormData(2, 'courseName', data.data[0]['courseName']);
                                                                 UpdateFormData(2, 'academicCareer', data.data[0]['academicCareerId']);
                                                                 UpdateFormData(2, 'academicGroup', data.data[0]['academicGroupId']);
                                                                 setLoader(false)
                                                             }, (data) => {

                                                             })
                                                     }, (data) => {

                                                     })
                                             }

                                         }
                                     }}
                            />
                        }
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.courseName} <RedAstric/></Label>
                        <Input disabled={true} className="tdSelect" value={ formData['courseName']} placeholder={placeholder.courseName}/>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.academicCareer} <RedAstric/></Label>
                        <Input disabled={true} className="tdSelect"
                               value={formData['academicCareer'] ==='' ? '' : getDispValForReactSelectByOneID(formData['academicCareer'] ,'DispValue',academicCareerData)}
                               placeholder={placeholder.academicCareer}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.academicGroup} <RedAstric/></Label>
                        <Input disabled={true} className="tdSelect"
                               value={formData['academicGroup'] !== '' ? getDispValForReactSelectByOneID(formData['academicGroup'] ,'DispValue',academicGroupData) :''}
                               placeholder={placeholder.academicGroup}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.courseType} </Label>
                        <Input disabled={true} className="tdSelect"
                               value={ (formData['courseType'] && formData['courseType']['courseAttributeValueDesc']) ? formData['courseType']['courseAttributeValueDesc'] : ''}
                               placeholder={placeholder.courseType}/>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.admissionType} <RedAstric/></Label>
                        {
                            <Select2 options={{placeholder: placeholder.admissionType,}}
                                     data={admissionType}
                                     disabled={action ==='view'}
                                     defaultValue={formData['admissionTypeId']}
                                     onChange={(e) => {
                                         if(e.target.value) {
                                             UpdateFormData(2, 'admissionTypeId', parseInt(e.target.value));
                                         }
                                     }}
                            />
                        }
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.class} <RedAstric/></Label>
                        <Input disabled={true} className="tdSelect" placeholder={placeholder.class}
                               value={formData['courseClass'] && formData['courseClass']['courseAttributeValueDesc'] ? formData['courseClass']['courseAttributeValueDesc'] : ''}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.rollNumber} <RedAstric/></Label>
                        <Input disabled={action !=='new'} className="tdSelect" placeholder={placeholder.rollNumber} maxLength={50}
                               value={ formData['rollNumber']}
                               onChange={(e) => {
                                   UpdateFormData(2, 'rollNumber', e.target.value.replace(/[^a-zA-Z0-9]/g, ""));
                               }}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.saleType} </Label>
                        <Select2 options={{placeholder: placeholder.saleType,}} data={saleType}
                                     disabled={action ==='view'}
                                     defaultValue={formData['saleTypeId']}
                                     onChange={(e) => {
                                         if(e.target.value) {
                                             UpdateFormData(2, 'saleTypeId', parseInt(e.target.value));
                                         }
                                     }}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.courseCategory}</Label>
                        <Input disabled={true} className="tdSelect" placeholder={placeholder.courseCategory}
                               value={formData['courseCategory'] && formData['courseCategory']['courseAttributeValueDesc'] ? formData['courseCategory']['courseAttributeValueDesc'] : '' }
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.externalSchoolId}</Label>
                        <Select2 options={{placeholder: placeholder.externalSchoolId,}}
                                 data={externalOrganization}
                                 disabled={action ==='view'}
                                 defaultValue={formData['externalSchoolNameId']}
                                 onChange={(e) => {
                                     if(e.target.value)
                                         UpdateFormData(2, 'externalSchoolNameId',e.target.value)
                                     }
                                 }
                            />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <CommonInput
                        label={label.externalSchoolName}
                        placeHolder={placeholder.externalSchoolName}
                        defaultValue={ formData['organizationShortName']}
                        // defaultValue={ (formData.externalSchoolNameId && externalOrganization.length) ?
                        //     externalOrganization.filter((item)=>item.id === formData.externalSchoolNameId)[0]['organizationShortName'] :  formData['organizationShortName']}
                        type={ 'text'}
                        isDisabled={ true }
                    />
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.programAction} <RedAstric/> </Label>
                        <Input disabled={true} className="tdSelect" placeholder={placeholder.programAction}
                               value={formData['programAction'] ==='' || !formData['programAction'] ? 'Applicant' : getDispValForReactSelectByOneID(formData['programAction'] ,'DispValue',programAction)}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.activeReason}</Label>
                        <Input disabled={true} className="tdSelect" placeholder={placeholder.activeReason}
                               value={formData['actionReason']}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.aiatsCenterCode} <RedAstric/></Label>
                        <Select2 options={{placeholder:placeholder.aiatsCenterCode,}}
                                 data={aiatsData}
                                 disabled={action ==='view'}
                                 defaultValue={formData['aiatsCenterCode']}
                                 onChange={(e) => {
                                     if(e.target.value) {
                                         UpdateFormData(2, 'aiatsCenterCode', e.target.value)
                                     }
                                 }}
                            />
                    </FormGroup>
                </Col>
            </Row>
            {
                action ==='new' || action === 'edit'
                ?
                (
                    <Row>
                        <Col xs={12}>
                            <Button color="info" size="sm" className="floatRight mx-1" disabled={true}>
                                Proceed for Charges And Payment
                            </Button>
                            {
                                action === 'new'
                                    ?
                                    <ButtonWithLoader loading={isLoading} color="info" size="sm" className="floatRight mx-1"
                                             onClick={() => submitHandler('course')}>
                                        Submit
                                    </ButtonWithLoader>
                                    :
                                    null
                            }
                        </Col>
                    </Row>
                )
                :
                    action === 'view' && isEdit
                        ?
                        <Row>
                            <Col xs={12}>
                                <Button color="info" size="sm" className="floatRight mx-1" disabled={true}>
                                    Proceed for Charges And Payment
                                </Button>
                            </Col>
                        </Row>
                        :
                        null
            }
        </div>
    )
}
export default CourseApplicationDetails;
