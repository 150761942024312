import {
  ADD_ACADEMIC_SUB_MAP_SUCCESS,
  FETCH_ACADEMIC_MAP_LIST_FAILURE,
  FETCH_ACADEMIC_MAP_LIST_REQUEST,
  FETCH_ACADEMIC_MAP_LIST_SUCCESS,
  UPDATE_ACADEMIC_SUB_MAP_REQUEST,
  UPDATE_ACADEMIC_SUB_MAP_SUCCESS,
  UPDATE_MAPPING_DROPDOWNS,
} from "./action-types";

const defaultState = {
  mappingList: [],
  rowCount: 0,
  currentPage: 0,
  loading: false,
  dropdowns: [],
};

export const academicSubMappingReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ACADEMIC_MAP_LIST_REQUEST:
      return { ...state, loading: true };

    case FETCH_ACADEMIC_MAP_LIST_SUCCESS:
      return {
        ...state,
        mappingList: action.data,
        rowCount: action.rowCount,
        currentPage: action.currentPage,
        loading: false,
      };
    case FETCH_ACADEMIC_MAP_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        mappingList: [],
        rowCount: 0,
        currentPage: 0,
      };
    case UPDATE_MAPPING_DROPDOWNS:
      return {
        ...state,
        dropdowns: action.dropdowns,
      };
    case ADD_ACADEMIC_SUB_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_ACADEMIC_SUB_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
