import React, { useContext } from 'react'
import { PermissionContext, RolePermissions } from 'appContext';
import { pages } from '../../../common/constant';
import { PermisionDenied } from '../../../common/commonComponents';
import styles from './styles.module.scss';
import { ReactComponent as DocumentIcon } from 'assets/img/svg/Document.svg';
import { ReactComponent as Refresh } from "assets/img/svg/Group 29.svg";
import { getScreenCards } from 'views/pages/manage/finOps/IssueStock/search/SearchCard.jsx'
import { useHistory } from 'react-router';
import { ReactComponent as LIST_ICON } from "assets/img/svg/listIcon.svg";

const MastersAndMappingLandingPage = () => {

  const history = useHistory();
  const userPermissions = RolePermissions(useContext(PermissionContext), pages['mastersAndMapping']['id']);
  const materialMasterPermissions = RolePermissions(useContext(PermissionContext), pages['materialMaster']['id']);
  const materialIdSetupPermissions = RolePermissions(useContext(PermissionContext), pages['materialIdSetup']['id']);
  const sapPeriodStatusPermissions = RolePermissions(useContext(PermissionContext), pages['sapPeriodStatus']['id']);
  const sapProfitCostMappingPermissions = RolePermissions(useContext(PermissionContext), pages['sapProfitCostCentreMapping']['id']);
  const miscCourseMaterialMappingPermissions = RolePermissions(useContext(PermissionContext), pages['miscCourseMaterialMapping']['id']);

  const screens = [
    materialMasterPermissions && {
      heading: "Material master",
      subHeading: "View list of material masters",
      icon: <DocumentIcon />,
      action: () => history.push(`/admin/edp/stockInventoryMaster/materialMaster`),
    },
    materialIdSetupPermissions && {
      heading: "On boarding material ID set-up",
      subHeading: "Add or edit on boarding materials",
      icon: <DocumentIcon />,
      action: () => history.push(`/admin/edp/stockInventoryMaster/materialIdSetup`),
    },
    sapProfitCostMappingPermissions && {
      heading: "SAP Profit/Cost Centre Mapping",
      subHeading: "Map profit centre",
      icon: <Refresh />,
      action: () => history.push('/admin/edp/stockInventoryMaster/sapProfitCostCentreMapping'),
    },
    sapPeriodStatusPermissions && {
      heading: "SAP Period Status",
      subHeading: "View SAP period status",
      icon: <DocumentIcon />,
      action: () => history.push(`/admin/edp/stockInventoryMaster/sapPeriodStatus`),
    },
    miscCourseMaterialMappingPermissions && {
      heading: "Misc. course ID item mapping",
      subHeading: "Map miscellaneous course ID and item",
      icon: <DocumentIcon />,
      action: () => history.push(`/admin/edp/stockInventoryMaster/miscCourseMaterialMapping`),
    },
    {
      heading: "Barcode reconciliation",
      subHeading: "Upload file to reconcile the barcode",
      icon: <LIST_ICON />,
      action: () => history.push(`/admin/edp/stockManagement/barcodeReconcilliation`) 
    }
  ]

  return (
    userPermissions ? (
      <div className={`screen-cards-container`}>
        {getScreenCards(screens?.filter(item => item))}
      </div>
    ) : (
      <PermisionDenied />
    )
  )
}

export default MastersAndMappingLandingPage