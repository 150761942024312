import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";

const CustomInput = (props) => {
  const {
    maxLength,
    minLength,
    index,
    placeHolder,
    isDisabled = false,
    regex,
    regValidation,
    type,
    defaultValue,
    handler,
    keyName,
    isUpperCase,
  } = props;

  const [value, setValue] = useState("");

  useEffect(() => {
    let val = defaultValue ? defaultValue : "";
    setValue(val);
  }, [defaultValue]);

  return (
    <Input
      type={type}
      className="tdInput"
      minLength={minLength}
      maxLength={maxLength}
      key={"InputField" + index}
      placeholder={placeHolder}
      value={value}
      disabled={isDisabled}
      onChange={(e) => {
        let value = isUpperCase ? e.target.value.toUpperCase() : e.target.value;

        // console.log("value>>>>>", value);
        if (regValidation) {
          if (regex.test(value)) {
            setValue(value);
            handler(index, value, keyName);
          }
        } else {
          setValue(value);
          handler(index, value, keyName);
          // console.log("handlers", index, value, keyName);
        }
      }}
    />
  );
};

export default CustomInput;
