import React from 'react';
import ResetPassword from "views/pages/login/resetPassword";
import { getCookie } from "../../common/utils/methods/commonMethods/utilityMethod";

const ResetPasswordSelf = props => {

    const userId = getCookie('userId');

    const backToLogin = () => {
        if (document.body.classList.contains('g-sidenav-pinned')) {
            document.body.classList.remove('g-sidenav-pinned');
            document.body.classList.remove('g-sidenav-show');
            document.body.classList.add('g-sidenav-hidden');
        }
        props.history.push('/');
    };

    return (
        <ResetPassword userId={userId} alreadyLoggedIn={true} setPasswordExpired={backToLogin}/>
    )
}

export default ResetPasswordSelf;