import React from "react";
import { ReactComponent as FolderSVG } from "../../../../../../assets/img/svg/Folder2.svg";

const EmptyTestState = ({
  isPastTest = false,
  message = "No Upcoming tests scheduled. To view previous dates tests, click on Show past Tests",
}) => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ padding: "25px 0" }}
    >
      <FolderSVG />
      <span
        style={{
          fontSize: "20px",
          fontWeight: 400,
          color: "#000000",
          marginTop: "1rem",
        }}
      >
        {isPastTest
          ? "No Tests scheduled in the last 6 months. To view tests that are scheduled earlier than 6 months, please refer the timetable report in Quicksight Dashboard."
          : message}
      </span>
    </div>
  );
};

export default EmptyTestState;
