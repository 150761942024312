import React, { useState, useEffect, useContext } from 'react';
import TaxCodeIdTable from './taxCodeIdTable';
import { getAPI, masterServiceBaseUrl } from 'services/http';
import CustomLoader from '../../common/commonComponents/Loader/loader';
import { PermissionContext, RolePermissions } from 'appContext';
import { pages } from '../../common/constant';
import { PermisionDenied } from '../../common/commonComponents';
import { failureToast } from "../../common/utils/methods/toasterFunctions/function";
import { GetPagination } from "../../common/commonComponents/pagination/pagination";

const TaxCodeId = () => {
  const [classArray, setclassArray] = useState([]);
  const [apiloader, setapiloader] = useState(false);
  const [taxCodeId, setTaxCodeId] = useState([]);
  const [taxType, setTaxType] = useState([]);
  const [groupCodeData, setGroupCode] = useState([]);
  const [companyCodeData, setCompanyCode] = useState([]);
  const [isSaveVisible, setisSaveVisible] = useState(false);


  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const pageSize = 10;

  const permissions = useContext(PermissionContext);

  const userPermissions = RolePermissions(permissions, pages['taxCodeId']['id']);

  const fetchClasses = async url => {
    setapiloader(true);
    getAPI(
      url,
      data => {
        setTaxCodeId(data['data']);
        let n = pagination;
        if (data['data'].length > pageSize) {
          n['nextPage'] = n.currentPage + 1;
        }
        if (n.currentPage > 1) {
          n.previousPage = n.currentPage - 1;
        }
        n['totalPage'] = Math.floor(data['data'].length / pageSize);
        if (data['data'].length % pageSize != 0) {
          n['totalPage'] += 1;
        }
        setpagination(n);
        setapiloader(false);
        setclassArray(
          data['data'].slice(pagination.currentPage * pageSize - pageSize, pagination.currentPage * pageSize) || []
        );
      },
      data => {
        failureToast(data['message']);
      }
    );
  };
  

  const fetchDropdown = async (url, prefix, setMethod) => {
    setapiloader(true);
    getAPI(
      url,
      data => {
        if (prefix === 'taxCodeType') {
          const tempTaxCodeType = [];
          const dropList = data?.data.forEach(data => {
            if (data.taxRegine === 'Y') {
              tempTaxCodeType.push({ id: data.id, text: data[`${prefix}DispValue`] });
            }
          });
          setMethod(tempTaxCodeType);
        } else {
          const dropList = data?.data.map(data => {
            return { id: data.id, text: data[`${prefix}DispValue`] };
          });
          setMethod(dropList);
        }
      },
      data => {
        failureToast(data['message']);
      }
    );
  };

  useEffect(() => {
    fetchClasses(masterServiceBaseUrl + '/taxCodeId/getAllTaxCodeId');
    fetchDropdown(masterServiceBaseUrl + '/taxCodeType/getAllActiveTaxCodeType', 'taxCodeType', setTaxType);
    fetchDropdown(masterServiceBaseUrl + '/groupCode/getAllActiveGroupCode', 'groupCode', setGroupCode);
    fetchDropdown(masterServiceBaseUrl + '/companyCode/getAllActiveCompanyCode', 'companyCode', setCompanyCode);
  }, []);
  return (
    <>
      {apiloader ? (
        <CustomLoader apiLoader={apiloader} loaderHeight={'200px'} loaderWidth={'100% '} />
      ) : (
        <>
          {userPermissions ? (
            <>
              <TaxCodeIdTable
                fetchClasses={fetchClasses}
                setclassArray={setclassArray}
                classArray={classArray}
                taxCodeType={taxType}
                grpData={groupCodeData}
                companyCodeData={companyCodeData}
                userPermissions={userPermissions}
                isSaveVisible={isSaveVisible}
                setisSaveVisible={setisSaveVisible}
              />
              {pagination.totalPage > 1 ? (
                // <GetPagination
                //   fetchClasses={fetchClasses}
                //   pagination={pagination}
                //   previousPage={previousPage}
                //   firstPage={firstPage}
                //   lastPage={lastPage}
                //   nextPage={nextPage}
                //   setpagination={setpagination}
                // />
                <GetPagination
									isSaveVisible={isSaveVisible}
									fetchClasses={fetchClasses}
									setclassArray={setclassArray}
									pagination={pagination}
									setpagination={setpagination}
									pageSize={pageSize}
									state={taxCodeId}
								/>
              ) : null}
            </>
          ) : (
            <PermisionDenied />
          )}
        </>
      )}
    </>
  );
};
export default TaxCodeId;
