import React from "react";
import { masterServiceBaseUrl} from 'services/http';
import { CardFooter, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import "react-sliding-pane/dist/react-sliding-pane.css";
import 'react-toastify/dist/ReactToastify.css';

var _ = require('lodash');

const PaginationNode = (props) => {
  return (
    <>
      <PaginationItem className={(props.currentPage==1&&props.num=="Next")||(props.num=="Previous"&&props.totalPage<=props.currentPage)?"disabled":null}>
        <PaginationLink onClick={(e) =>{props.triggerFun()}}>
          <i className={props.icon} />
          <span className="sr-only">{props.num}</span>
        </PaginationLink>
      </PaginationItem>
    </>
  )
}

const PaginationPage = (props) => {
  return (
    <>
      {props.num&&!(props.totalPage<props.num) ? <PaginationItem className={props.url ? (props.active ? 'active' : null) : (props.active ? 'active disable' : 'disable')}>
        <PaginationLink onClick={(e) =>{props.triggerFun()}}>
          {props.num}
        </PaginationLink>
      </PaginationItem> : null}
    </>
  )
}

const GetPagination = (props) => {
  return (
    <>
      {props.pagination ? <CardFooter className="py-4">
        <nav aria-label="...">
          <Pagination className="pagination justify-content-end mb-0" listClassName="justify-content-end mb-0">
            <PaginationNode num={'Next'} totalPage={props.pagination.totalPage} currentPage={props.pagination.currentPage} active={true} triggerFun={props.firstPage} icon={'fa fa-angle-double-left'} />
            <PaginationNode num={'Next'} totalPage={props.pagination.totalPage} currentPage={props.pagination.currentPage} active={true} triggerFun={props.previousPage} icon={'fa fa-angle-left'} />
            <PaginationPage active={false} totalPage={props.pagination.totalPage} num={parseInt(props.pagination.currentPage-1)} triggerFun={props.previousPage} />
            <PaginationPage active={true} totalPage={props.pagination.totalPage} num={parseInt(props.pagination.currentPage)} triggerFun={()=>{}}/>
            <PaginationPage active={false} totalPage={props.pagination.totalPage} num={parseInt(props.pagination.currentPage+1)} triggerFun={props.nextPage} />
            <PaginationNode num={'Previous'} totalPage={props.pagination.totalPage} currentPage={props.pagination.currentPage} triggerFun={props.nextPage} icon={'fa fa-angle-right'} />
            <PaginationNode num={'Previous'} totalPage={props.pagination.totalPage} currentPage={props.pagination.currentPage} triggerFun={props.lastPage} icon={'fa fa-angle-double-right'} />
          </Pagination>
        </nav>
      </CardFooter> : null}
    </>
  )
}
export {PaginationNode,PaginationPage,GetPagination}