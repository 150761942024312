import React from 'react';
import { Row, Col, FormGroup, Input, Label } from 'reactstrap';
import moment from 'moment';

const LogDetails = (props) => {
    const { houseBankDetails } = props;
    return (
        <Row>
            <Col>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label className="form-control-label">Created Date</Label>
                            <Input value={ houseBankDetails['createdOn'] ? moment(houseBankDetails['createdOn']).format('DD-MM-YYYY') : moment().format('DD-MM-YYYY') } disabled={ true } className="customInput"/>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label className="form-control-label">Last Updated Date</Label>
                            <Input value={ houseBankDetails['updatedOn']? moment(houseBankDetails['updatedOn']).format('DD-MM-YYYY') : moment().format('DD-MM-YYYY')} disabled={ true } className="customInput"/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label className="form-control-label">Created By</Label>
                            <Input                                
                                value={ houseBankDetails[ 'createdBy' ] ? houseBankDetails[ 'createdBy' ] : '' }
                                disabled={ true } className="customInput" />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label className="form-control-label">Last Updated By</Label>
                            <Input value={ houseBankDetails['updatedBy'] ?  houseBankDetails['updatedBy'] :''} disabled={ true } className="customInput"/>
                        </FormGroup>
                    </Col>
                </Row>
            </Col>
    </Row>
    )
}

export default LogDetails;