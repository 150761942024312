import React, { useEffect, useState } from 'react'
import AInput from '../../../../common/formFeilds/customInput/AInput';
import APopover from '../../../../common/a-popover';
import AButton from '../../../../common/form-fields-mui/AButton';
import { highlightedStyle, normalStyle } from '../../mastersAndMapping/onBoardingMaterialIdSetup/SearchFilter';
import SearchFilterForm from './SearchFilterForm';


const SearchFilter = ({
  listRef,
  filterCount,
  filterPayload,
  nameFilterPayload,
  dropdown,
  applyFilter,
  applyNameFilter,
}) => {

  // const [userInput, setUserInput] = useState(nameFilterPayload);
  // useEffect(() => {
  //   setUserInput(nameFilterPayload);
  // }, [nameFilterPayload])

  // const onKeyDown = (e) => {
  //   if (e.key === 'Enter' || e.keyCode === 13) {
  //     applyNameFilter(userInput)
  //   }
  // }

  return (
    <div className='d-flex justify-content-end align-items-center'>
      {/* <AInput
        type='text'
        placeholder={'Search by dispatch ID'}
        value={userInput}
        rightSearchIcon
        crossIcon
        handler={(value) => setUserInput(value)}
        onKeyDown={onKeyDown}
        crossHandler={() => { setUserInput(''); applyNameFilter(''); }}
        searchIconClickHandler={() => applyNameFilter(userInput)}
        style={{ width: '200px', borderRadius: '4px', height: '29px', ...normalStyle }}
      /> */}
      <APopover
        buttonComponent={(onClick, styles) => (
          <AButton
            className='tiny-semi-bold color-dark'
            onClick={onClick}
            updatedStyle={{ minWidth: '61px', ...(filterCount > 0 ? highlightedStyle : styles) }}
          >
            Filter {filterCount > 0 && <>( {filterCount} )</>}
          </AButton>
        )}
        menuComponent={(closeForm) =>
          <SearchFilterForm
            closeForm={closeForm}
            filterCount={filterCount}
            filterPayload={filterPayload}
            dropdown={dropdown}
            applyFilter={applyFilter}
          />
        }
        maxHeight={'calc(100vh - 250px)'}
      />
      <AButton 
        className='tiny-semi-bold'
        variant='outline' 
        size='xs'
        onClick={() => listRef?.current?.addNewRow()}
      >
        Add dispatch schedule
      </AButton>
    </div>
  )
}

export default SearchFilter