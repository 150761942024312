import React, { useState, useEffect } from "react";
import { Container, Card, CardHeader, Input, Row, Col, Button } from "reactstrap";
import Select from "react-select";
import { failureToast, successToast, putpost, masterServiceBaseUrl, getAPI } from "services/http";
import { getUniqueKey } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { CustomUpload, Dialog } from "../../../common/commonComponents";
import { endpoint, constants } from "../../../common/constant";
import { GetTemplate } from "../../../common/commonComponents";
import ExternalOrgTable from "./extOrgTable";
import { GetPagination } from "views/pages/manage/common/commonComponents/pagination/pagination";

const ExternalOrgBulkUpload = (props) => {
  const [state, setState] = useState([]);
  const [isFileValid, setISFileValid] = useState(false);
  const [filename, setFileName] = useState("Drag & Drop to Upload");
  const [file, setFile] = useState(null);
  const [progressBarStatus, setProgressBarStatus] = useState(0);
  const [isUploading, setISUploading] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [description, setDescription] = useState("");
  const [searchParams, setSearchParam] = useState({});
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });

  const pageSize = 10;

  const initialPagination = (paramsdata) => {
    // console.log("Params data", paramsdata);
    let n = pagination;
    if (paramsdata.length > pageSize) {
      n["nextPage"] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n["totalPage"] = Math.floor(paramsdata.length / pageSize);
    if (paramsdata.length % pageSize != 0) {
      n["totalPage"] += 1;
    }
    setpagination(n);
    setSearchResult(paramsdata.slice(pagination.currentPage * pageSize - pageSize, pagination.currentPage * pageSize) || []);
    // console.log("searchResult", searchResult);
  };

  const previousPage = () => {
    if (pagination.currentPage != 1) {
      setSearchResult(state.slice((pagination.currentPage - 1) * pageSize - pageSize, (pagination.currentPage - 1) * pageSize));
      let n = pagination;
      n["currentPage"] = n["currentPage"] - 1;
      if (n["currentPage"] == 1) {
        n["previousPage"] = null;
      } else {
        n["previousPage"] = n.currentPage;
      }
      setpagination(n);
    }
  };

  const fetchDetails = async (url, type) => {
    getAPI(
      url,
      (data) => {
        setSearchResult(data.data);
        setState(data?.data);
        initialPagination(data?.data);
      },
      (data) => {
        setSearchResult([]);
        setState([]);
        failureToast(data["message"]);
      }
    );
  };

  // const dataHandler = (data, type) => {
  //   try {
  //     switch (type) {
  //       case "SEARCH_RESULT":
  //         setSearchResult(data?.data);
  //         // initialPagination(data?.data);
  //         break;
  //       default:
  //         break;
  //     }
  //   } catch (e) {
  //     console.log("Error", e);
  //   }
  // };
  // console.log("searchResult", searchResult);

  const dataToServer = {
    bulk: true,
    fileName: "extOrg",
    docType: "csv",
    keyValue: `extOrg_${getUniqueKey()}`,
    serviceName: constants.templates_service_name.external_organization,
    subProcessType: constants.templates_sub_process_type.external_organization,
  };

  const handleFile = (e) => {
    try {
      if (e.name.split(".")[1] === "csv") {
        setFileName(e.name);
        setFile(e);
        setISFileValid(true);
      } else {
        failureToast("Only csv files are allowed");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const saveFileAndReturnData = (data) => {
    // console.log("Response from the server", data, description);
    const fileName = data.Location.split("/");
    putpost(
      `${masterServiceBaseUrl}/externalOrganizationId/upload`,
      (data) => {
        setProgressBarStatus(100);
        successToast("File Uploaded Successfully");
        setModalVisible(!isModalVisible);
        reset();
        fetchDetails(`${masterServiceBaseUrl}/externalOrganizationId/search/process/excel`)
      },
      (data) => {
        failureToast("Uploading Failed");
      },
      {
        key: data.key,
        bucket: data.Bucket,
        description: description,
        etag: data.ETag,
        fileName: fileName[fileName.length - 1],
        filePath: data.Location,
        status: "ACTIVE",
      },
      "post"
    );
  };

  const reset = () => {
    setISFileValid(false);
    setFileName("Drag & Drop to Upload");
    setFile(null);
    setProgressBarStatus(0);
    setISUploading(false);
  };

  const statusData = [
    { label: "UPLOADED", value: "Uploaded" },
    { label: "PROCESSING", value: "Processing" },
    { label: "SUCCESS", value: "Success" },
    { label: "FAILED", value: "Failed" },
  ];

  const searchDataHandler = () => {
    try {
      if(searchParams === {}) { fetchDetails(`${masterServiceBaseUrl}/externalOrganizationId/search/process/excel`) }
      else {
        let urlParams = "";   // Creating URL parameters based on user inputs.
  
        Object.keys(searchParams).map(key => urlParams += `&${key}=${key === "status" ? searchParams[key]?.value : searchParams[key]}`);
  
        !urlParams ? 
        fetchDetails(`${masterServiceBaseUrl}/externalOrganizationId/search/process/excel`) :
        fetchDetails(`${masterServiceBaseUrl}/externalOrganizationId/search/process/excel?${urlParams.substring(1)}`);
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const clearAll = () => {
    setSearchParam({ processId: "", status: null, description: "" });
    fetchDetails(`${masterServiceBaseUrl}/externalOrganizationId/search/process/excel`);
    setpagination({
      currentPage: 1,
      nextPage: null,
      previousPage: null,
      totalPage: null,
    });
  };

  const descriptionField = () => {
    return (
      <>
        <label className="form-control-label mt-4">{`Description`}</label>
        <Input placeholder="Description" onChange={(e) => setDescription(e.target.value)} />
      </>
    );
  };

  useEffect(() => {
    fetchDetails(`${masterServiceBaseUrl}/externalOrganizationId/search/process/excel`);
  }, []);

  return (
    <>
      <Container fluid className="mt-4">
        <Dialog
          title="External Org Bulk Upload"
          isModalVisible={isModalVisible}
          toggle={() => {
            setModalVisible(!isModalVisible);
            reset();
          }}
        >
          <CustomUpload
            documentType="Org"
            isFileValid={isFileValid}
            filename={filename}
            file={file}
            progressBarStatus={progressBarStatus}
            setISFileValid={setISFileValid}
            setFileName={setFileName}
            setFile={setFile}
            setProgressBarStatus={setProgressBarStatus}
            isUploading={isUploading}
            setISUploading={setISUploading}
            handleFile={handleFile}
            dataToServer={dataToServer}
            saveHandler={saveFileAndReturnData}
            descriptionField={descriptionField}
            showDescription={true}
            description={description}
          />
        </Dialog>
        <Card>
          <CardHeader className=" d-flex justify-content-between">
            <h3 className="mb-0 ">Upload External Org ID</h3>
            <div className="d-flex flex-row justify-content-between align-items-center">
              <GetTemplate url={endpoint.externalOrganizationTemplate} />
              <Button color="info" size="sm" type="button" className={"floatRight"} onClick={() => setModalVisible(true)}>
                <i className="fas fa-upload"></i>&nbsp;&nbsp;Upload Template
              </Button>
            </div>
          </CardHeader>

          <div className="p-4">
            <div className="form-row">
              <Col className="mb-3" md="3">
                <label className="form-control-label">{`Process ID`}</label>
                <Input
                  id="search_processID"
                  value={searchParams.processId}
                  placeholder={`Process ID`}
                  type="text"
                  maxLength="6"
                  style={{ height: "39px" }}
                  onChange={(e) =>
                    !isNaN(e.target.value)
                      ? setSearchParam({
                          ...searchParams,
                          processId: e.target.value,
                        })
                      : setSearchParam({ ...searchParams })
                  }
                />
              </Col>
              <Col className="mb-3" md="3">
                <label className="form-control-label">{`Status`}</label>
                <Select
                  value={searchParams.status}
                  id="search_status"
                  options={statusData}
                  placeholder="Status"
                  onChange={(sData) => setSearchParam({ ...searchParams, status: sData })}
                />
              </Col>
              <Col className="mb-3" md="6">
                <label className="form-control-label">{`Description`}</label>
                <Input
                  value={searchParams.description}
                  placeholder={`Description`}
                  id="search_description"
                  type="text"
                  maxLength="50"
                  style={{ height: "39px" }}
                  onChange={(e) =>
                    setSearchParam({
                      ...searchParams,
                      description: e.target.value,
                    })
                  }
                />
              </Col>
            </div>
            <Row>
              <Col md={12} className={"d-flex justify-content-end"}>
                <Button color={"info"} onClick={searchDataHandler}>
                  Search
                </Button>
                <Button color={"secondary"} onClick={clearAll}>
                  Clear All
                </Button>
              </Col>
            </Row>
          </div>
        </Card>
        <ExternalOrgTable searchResult={searchResult} />
      </Container>
        {pagination.totalPage > 1 ? (
          <GetPagination
            // isSaveVisible={isSaveVisible}
            fetchClasses={fetchDetails}
            setclassArray={setSearchResult}
            pagination={pagination}
            setpagination={setpagination}
            pageSize={pageSize}
            state={state}
          />
        ) : null}
    </>
  );
};

export default ExternalOrgBulkUpload;
