import React, { useEffect } from 'react';
import {
	Card,
	CardHeader,
	Input,
	Container,
	Button,
	Table,
	Row,
	Label,
	FormGroup,
} from 'reactstrap';
import { useState } from 'react';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import {
	TDROW,
	MasterHeader,
} from '../../common/commonComponents/masterRow/masterRow';
import {
	getAPI,
	masterServiceBaseUrl,
	putpost,
	putpost2,
	deleteAPI,
} from 'services/http';
import moment from 'moment';
import Select2 from 'react-select2-wrapper';
import { MasterHeaderAuto } from '../../common/commonComponents/masterHeader/masterHeader';
import CustomLoader from '../../common/commonComponents/Loader/loader';
import IsCoreMaster from '../../common/commonComponents/coreMaster';
import CustomButton from "../../../../../components/CustomButton";

var _ = require('lodash');

const AcademicCareerTable = (props) => {
	const { companyCode, permissionSet, isSaveVisible, setisSaveVisible = () => { } } = props;
	const [apiLoader, setApiLoader] = useState(false);
	const [tableDataKey, settableDataKey] = useState('tableDataKey');
	const [isDisabledField, setIsDisabledField] = useState(false);

	const addNewClass = () => {
		props.setclassArray([
			{
				editMode: true,
				__status: '__new',
				academicCareerKey: null,
				status: 'ACTIVE',
				academicCareerDesc: null,
				academicCareerDispValue: null,
				sapAcademicCareerSequence: null,
				sapAcademicCareer: null,
				sapAcademicTypeSequence: null,
				sapAcademicType: null,
				effectiveDate: null,
				academicCareerCategoryCode: null,
				companyCodeMasterId: null,
				
			},
			...props.classArray,
		]);
	};
	const saveAll = (cb) => {
		var _tempArray = props.classArray;
		var isValid = true;
		_tempArray.forEach((el, index) => {
			if (el['editMode']) {
				if (
					el['academicCareerDesc'] === null ||
					!el['academicCareerDesc'].trim()
				) {
					isValid = false;
					failureToast('Please enter the value of Description');
					return;
				} else if (
					el['academicCareerDispValue'] === null ||
					!el['academicCareerDispValue'].trim()
				) {
					isValid = false;
					failureToast('Please enter the value of Display Value');
					return;
				} else if (
					el['sapAcademicCareerSequence'] === null ||
					!el['sapAcademicCareerSequence'].trim()
				) {
					isValid = false;
					failureToast(
						'Please enter the value of SAP Academic Career Sequence'
					);
					return;
				} else if (
					el['sapAcademicCareer'] === null ||
					!el['sapAcademicCareer'].trim()
				) {
					isValid = false;
					failureToast('Please enter the value of SAP Academic Career');
					return;
				} else if (
					el['sapAcademicTypeSequence'] === null ||
					!el['sapAcademicTypeSequence'].trim()
				) {
					isValid = false;
					failureToast('Please enter the value of SAP Career Type Sequence');
					return;
				} else if (
					el['sapAcademicType'] === null ||
					!el['sapAcademicType'].trim()
				) {
					isValid = false;
					failureToast('Please enter the value of SAP Career Type');
					return;
				} else if (
					el['academicCareerCategoryCode'] === null ||
					!el['academicCareerCategoryCode'].trim()
				) {
					
					isValid = false;
					failureToast('Please enter the value of Category Code');
					return;
				}
				if (!isValid) {
					return;
				}
				if (_tempArray[index]['__status'] == '__new') {
					putpost(
						masterServiceBaseUrl + '/academicCareer/createAcademicCareer',
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							props.fetchClasses(
								masterServiceBaseUrl + '/academicCareer/getAllAcademicCareer'
							);
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'post'
					);
				} else {
					putpost(
						masterServiceBaseUrl +
						'/academicCareer/updateAcademicCareer/' +
						_tempArray[index].id,
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							delete _tempArray[index]['editMode'];
							props.setclassArray(_tempArray);
							setIsDisabledField(false);
							settableDataKey(new Date().getMilliseconds());
							props.fetchClasses(
								masterServiceBaseUrl + '/academicCareer/getAllAcademicCareer'
							);
							// props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'put'
					);
				}
			} else {
			}
		});
	};
	const newRow = () => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				addNewClass();
				setisSaveVisible(true);
				// settableDataKey(new Date().getMilliseconds())
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			props.setclassArray(_tempArray);
			setisSaveVisible(false);
			cb();
		}
	};
	const editRow = (index) => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				var _tempArray = props.classArray;
				_tempArray[index]['editMode'] = true;
				props.setclassArray(_tempArray);
				setisSaveVisible(true);
				setIsDisabledField(true);
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			cb();
		}
	};
	const deleteRow = (index) => {
		if (props.classArray[index]['__status'] == '__new') {
			let n = props.classArray;
			n.splice(index, 1);
			props.setclassArray(n);
			setisSaveVisible(false);
			settableDataKey(new Date().getMilliseconds());
		} else {
			if (
				window.confirm('Are you sure you want to delete this Academic Career?')
			) {
				putpost2(
					masterServiceBaseUrl +
					'/academicCareer/deleteAcademicCareer/' +
					props.classArray[index].id,
					(data) => {
						successToast(data['message']);
						props.fetchClasses(
							masterServiceBaseUrl + '/academicCareer/getAllAcademicCareer'
						);
						if (props.classArray.length === 1) {
							props.previousPage();
							props.fetchClasses(
								masterServiceBaseUrl + '/academicCareer/getAllAcademicCareer'
							);
						} else {
							var _tempArray = props.classArray;
							if (_tempArray[index]['editMode']) {
								setisSaveVisible(false);
							}
							_tempArray.splice(index, 1);
							props.setclassArray(_tempArray);
						}
						settableDataKey(new Date().getMilliseconds());
					},
					(data) => {
						failureToast(data['message']);
					},
					'Delete'
				);
			}
		}
	};
	const updateKey = (index, value, key) => {
		var _tempArray = props.classArray;
		_tempArray[index][key] = value;

		props.setclassArray(_tempArray);
	};

	const validate = (value, type) => {
		try {
			let pattern_1 = /^[0-9]*$/;

			if (type === 'sapSequence') {
				if (!pattern_1.test(value)) {
					return failureToast('SAP Sequence contains only Numeric value');
				} else {
					return true;
				}
			}
		} catch (error) {
			console.log('Error', error);
		}
	};

	const headerList = [
		{ name: 'Company Code', isRequired: true },
		{ name: 'ACADEMIC CAREER KEY', isRequired: true },
		{ name: 'EFFECTIVE DATE' },
		{ name: 'DESCRIPTION', isRequired: true },
		{ name: 'DISPLAY VALUE', isRequired: true },
		{ name: 'SAP ACADEMIC CAREER SEQUENCE', isRequired: true },
		{ name: 'SAP ACADEMIC CAREER', isRequired: true },
		{ name: 'SAP CAREER TYPE SEQUENCE', isRequired: true },
		{ name: 'SAP CAREER TYPE', isRequired: true },
		{ name: 'CATEGORY CODE', isRequired: true },
		{ name: 'STATUS' },
		{ name: 'ACTION' },
	];

	return (
		<>
			<Container className="mt-3" fluid>
				<Row>
					<div className="col">
						<Card>
							<CardHeader className="border-0">
								<h3 className="mb-0 floatLeft">Master - Academic Career</h3>
								<IsCoreMaster
									master={'academicCareer'}
									masterDisplayValue={'Academic Career'}
									setApiLoader={setApiLoader}
								/>
								{/*<Button*/}
								{/*	disabled={isSaveVisible}*/}
								{/*	color="info"*/}
								{/*	size="sm"*/}
								{/*	type="button"*/}
								{/*	className={*/}
								{/*		'floatRight mx-1' + (isSaveVisible ? ' btn-dark' : null)*/}
								{/*	}*/}
								{/*	onClick={() => {*/}
								{/*		newRow();*/}
								{/*	}}*/}
								{/*>*/}
								{/*	New Academic Career*/}
								{/*</Button>*/}
								<CustomButton
									data-testId="addNewRow"
									disabled={isSaveVisible}
									className={'floatRight mx-1' + (isSaveVisible ? ' btn-dark' : null)}
									content={'New Academic Career'}
									permissionType={'C'}
									icon={true}
									permissionSet={permissionSet}
									onClick={() => newRow()}
								/>
								{/* {isSaveVisible?<Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => {saveAll()}}>Save</Button>:null} */}
							</CardHeader>
							<div id="questionTable" className="table-responsive">
								<Table className="align-items-center table-flush tableLayout">
									{/*<MasterHeader type={'Academic Career'} />*/}
									<MasterHeaderAuto headerList={permissionSet.includes('U') || permissionSet.includes('D') || isSaveVisible ? headerList : headerList.slice(0, 11)} permissionSet={permissionSet} isSaveVisible={isSaveVisible} />
									<tbody className="list" key={tableDataKey}>
										{props.classArray
											? props.classArray.map((el, index) =>
												!el['editMode'] ? (
													<TDROW
														companyCode={companyCode}
														type={'academicCareer'}
														index={index}
														el={el}
														editRow={editRow}
														deleteRow={deleteRow}
														isSaveVisible={isSaveVisible}
														permissionSet={permissionSet}
													/>
												) : (
													<tr key={index + '-class'}>
														<td className="text-center p-2 mx-1">
															<Select2
																options={{
																	placeholder: ' Select Company Code',
																}}
																defaultValue={el['companyCodeMasterId']}
																data={companyCode}
																onChange={(e) =>
																	updateKey(
																		index,
																		e.target.value,
																		'companyCodeMasterId'
																	)
																}
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="50"
																defaultValue={el['academicCareerKey']}
																disabled={true}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'academicCareerKey'
																	);
																}}
																// placeholder="Example:1"
																className="tdInput"

															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																disabled={true}
																defaultValue={
																	el['__status'] == '__new'
																		? null
																		: moment(el['eff_date']).format(
																			'DD-MM-YYYY'
																		)
																}
																placeholder="Example:01-01-2020"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="50"
																defaultValue={el['academicCareerDesc']}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'academicCareerDesc'
																	);
																}}
																placeholder="Description"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="50"
																defaultValue={el['academicCareerDispValue']}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'academicCareerDispValue'
																	);
																}}
																placeholder="Display Value"
																className="tdInput"
																disabled ={!!el.id}
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="5"
																defaultValue={el['sapAcademicCareerSequence']}
																disabled={isDisabledField}
																onChange={(e) => {
																	let n = e.target.value;
																	if (validate(n, 'sapSequence')) {
																		updateKey(
																			index,
																			n,
																			'sapAcademicCareerSequence'
																		);
																	}
																}}
																placeholder="Academic Career Sequence"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="50"
																defaultValue={el['sapAcademicCareer']}
																disabled={isDisabledField}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'sapAcademicCareer'
																	);
																}}
																placeholder="Academic Career"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="5"
																defaultValue={el['sapAcademicTypeSequence']}
																disabled={isDisabledField}
																onChange={(e) => {
																	let n = e.target.value;
																	if (validate(n, 'sapSequence')) {
																		updateKey(
																			index,
																			n,
																			'sapAcademicTypeSequence'
																		);
																	}
																}}
																placeholder="Academic Type Sequence"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="50"
																defaultValue={el['sapAcademicType']}
																disabled={isDisabledField}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'sapAcademicType'
																	);
																}}
																placeholder="Academic Type"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="1"
																defaultValue={
																	el['academicCareerCategoryCode']
																}
																// disabled={isDisabledField}
																onChange={(e) => {
																	let c = e.target.value;
																	updateKey(
																		index,
																		c,
																		'academicCareerCategoryCode'
																	);
																}}
																placeholder="Category Code"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<label className="custom-toggle mx-auto ml-2">
																<input
																	checked={
																		el.status == 'ACTIVE' ? true : false
																	}
																	type="checkbox"
																	id={el.id}
																	onChange={(e) => {
																		settableDataKey(
																			new Date().getMilliseconds()
																		);
																		
																		let n = 'ACTIVE';
																		if (!e.target.checked) {
																			n = 'INACTIVE';
																		}
																		updateKey(index, n, 'status');
																	}}
																/>
																<span
																	style={{ width: '72px' }}
																	className="custom-toggle-slider rounded-circle activeToggle"
																	data-label-off="Inactive"
																	data-label-on="Active"
																/>
															</label>
														</td>
														<td className="text-center">
															{isSaveVisible ? (
																<>
																	{' '}
																	{/*<Button*/}
																	{/*	color="info"*/}
																	{/*	size="sm"*/}
																	{/*	type="button"*/}
																	{/*	className="mx-1"*/}
																	{/*	onClick={() => {*/}
																	{/*		saveAll();*/}
																	{/*	}}*/}
																	{/*>*/}
																	{/*	Save*/}
																	{/*</Button>*/}
																	<CustomButton
																		className={'mx-1'}
																		content={'Save'}
																		permissionType={'C,U'}
																		forTable={true}
																		permissionSet={permissionSet}
																		onClick={() => saveAll()}
																	/>
																	{/*<Button*/}
																	{/*	color="danger"*/}
																	{/*	size="sm"*/}
																	{/*	type="button"*/}
																	{/*	onClick={() => {*/}
																	{/*		let n = props.classArray;*/}
																	{/*		if (n[index]['__status'] == '__new') {*/}
																	{/*			n.splice(index, 1);*/}
																	{/*		} else {*/}
																	{/*			// n[index]['editMode']=false*/}
																	{/*			props.fetchClasses(*/}
																	{/*				masterServiceBaseUrl +*/}
																	{/*					'/academicCareer/getAllAcademicCareer'*/}
																	{/*			);*/}
																	{/*		}*/}
																	{/*		props.setclassArray(n);*/}
																	{/*		setisSaveVisible(false);*/}
																	{/*		setIsDisabledField(false);*/}
																	{/*	}}*/}
																	{/*>*/}
																	{/*	{' '}*/}
																	{/*	<i className="fas fa-times" />*/}
																	{/*</Button>{' '}*/}
																	<CustomButton
																		className={'mx-1'}
																		permissionType={'cancel'}
																		forTable={true}
																		icon={true}
																		permissionSet={permissionSet}
																		onClick={() => {
																			let n = props.classArray;
																			if (n[index]['__status'] == '__new') {
																				n.splice(index, 1);
																			} else {
																				// n[index]['editMode']=false
																				props.fetchClasses(
																					masterServiceBaseUrl +
																					'/academicCareer/getAllAcademicCareer'
																				);
																			}
																			props.setclassArray(n);
																			setisSaveVisible(false);
																			setIsDisabledField(false);
																		}}
																	/>
																</>
															) : (
																<Button
																	color="info"
																	size="sm"
																	type="button"
																	onClick={() => {
																		deleteRow(index);
																	}}
																>
																	{' '}
																	<i className="fas fa-trash" />
																</Button>
															)}
														</td>
													</tr>
												)
											)
											: null}
									</tbody>
								</Table>
							</div>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};
export default AcademicCareerTable;
