import moment from "moment"

import { endpoint } from "views/pages/manage/common/constant";
import { fetchAllPostPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { getDropdowns } from "views/pages/manage/edpOps/constant/batch-search";
import { BATCH_LIST_FAILURE, BATCH_LIST_REQUEST, BATCH_LIST_SUCCESS, START_DOWNLOAD_LOADER, START_LOADER, STOP_DOWNLOAD_LOADER, STOP_LOADER, UPDATE_BATCH_DROPDOWNS, UPDATE_BATCH_MAIN_DROPDOWNS, UPDATE_BATCH_STATUS_FAILURE } from "./batch-action-types";
import { getIdsArray, updateRequest } from "views/pages/manage/edpOps/constant/common";
import { getBusinessAreaAndRegionDropdowns } from "views/pages/manage/edpOps/constant/common";
import { getDownloadFileUrl } from "views/pages/manage/edpOps/constant/common";

export const batchListSuccess = (batchList, rowCount, currentPage) => {
  return {
    batchList,
    rowCount,
    currentPage,
    type: BATCH_LIST_SUCCESS,
  };
};

export const batchListFailure = () => {
  return {
    type: BATCH_LIST_FAILURE
  };
};

export const batchListClear = () => {
  return {
    type: BATCH_LIST_FAILURE
  };
};


export const getBatchList = (data = {}, offSet = 0) => {
  return async (dispatch, getState) => {
    dispatch({ type: BATCH_LIST_REQUEST })

    try {

      if (data['batchStartToDate'] && !data['batchStartFromDate']) {
        data['batchStartFromDate'] = (moment(data['batchStartToDate']).subtract(1, 'days'))
      }
      if (data['batchStartFromDate'] && !data['batchStartToDate']) {
        data['batchStartToDate'] = moment(data['batchStartFromDate']).add(1, 'days')
      }
      let request = { ...data }
      if (!data?.businessArea || (data.businessArea && data.businessArea.length === 0)) {
        request = { ...data, businessArea: getIdsArray(getState().batch.dropdowns.businessAreas) }
      }

      const response = await fetchAllPostPromisedData(
        `${endpoint.batchList.fetchAllBatches}=${offSet}`,
        { ...updateRequest(request) },
        "post"
      );
      if (response?.code === 200) {
        dispatch(
          batchListSuccess(
            response.data.batchSearchResponseDTOList,
            response.data.totalRecords,
            response.currentPage
          )
        );
      } else {
        dispatch(batchListFailure())
      }

    } catch (error) {
      dispatch({ type: BATCH_LIST_FAILURE })

    }

  };
};



export const updateBatchStatus = (request, id, filter) => {
  return async (dispatch, getState) => {
    dispatch({ type: BATCH_LIST_REQUEST })
    try {
      const response = await fetchAllPostPromisedData(
        `${endpoint.viewEditBatch.update}/${id}`,
        request,
        "put"
      );
      if (response) {
        dispatch(getBatchList(filter))
      } else {
        dispatch({ type: UPDATE_BATCH_STATUS_FAILURE })
      }
    } catch (error) {
      dispatch({ type: BATCH_LIST_FAILURE })
    }

  };
};

export const loadDropdowns = (cb) => {
  return async (dispatch) => {
    const response = await getDropdowns();
    if (response) {
      dispatch({ type: UPDATE_BATCH_DROPDOWNS, dropdowns: response });
      if (cb) cb(response)
    }
  };
};

export const fetchMainDropdowns = (cb) => {

  return async (dispatch) => {
    dispatch({ type: BATCH_LIST_REQUEST })
    const response = await getBusinessAreaAndRegionDropdowns();
    if (response) {
      dispatch({ type: UPDATE_BATCH_MAIN_DROPDOWNS, dropdowns: response });
      cb(response)
    }
  };
};

export const startDownloadLoader = () => {
  return {
    type: START_DOWNLOAD_LOADER
  }
}

export const stopDownloadLoader = () => {
  return {
    type: STOP_DOWNLOAD_LOADER
  }
}

export const downloadCsv = (request) => {
  return async (dispatch, getState) => {
    dispatch(startDownloadLoader())
    try {
      const response = await fetchAllPostPromisedData(
        `${endpoint.batchList.downloadCsv}`,
        { ...updateRequest(request) },
        "post"
      );
      if (response) {
        getDownloadFileUrl(response.data?.key)
        // dispatch(CLOSE_LOADER)
        dispatch(stopDownloadLoader())

      } else {
        dispatch(stopDownloadLoader())
      }
    } catch (error) {
      dispatch(stopDownloadLoader())
    }

  };
};

export const startLoader = () => {
  return {
    type: START_LOADER
  }
}

export const stopLoader = () => {
  return {
    type: STOP_LOADER
  }
}