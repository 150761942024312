import React, { useState, useEffect, Fragment } from 'react';
import ServiceView from './serviceView';
import Loader from 'react-loader-spinner';
import {
	Card,
	CardHeader,
	Container,
	Row,
	Col,
	CardBody,
	Input,
	Button,
	Label,
	FormGroup,
	Form,
} from 'reactstrap';
import {
	PaginationNode,
	PaginationPage,
	GetPagination,
} from '../../common/pagination';
import {
	getAPI,
	masterServiceBaseUrl,
	sfServiceBaseUrl,
	putpost,
	successToast,
	failureToast,
} from 'services/http';
import { validate } from '../../common/utils/methods/validations/validation';
const ServiceDataShow = (props) => {
	const {
		psIdData,
		psId,
		isDisabled,
		getSearchData,
		searchPsID,
		setSearchPsId,
	} = props;
	const [serviceViewData, setServiceViewData] = useState([]);
	const [documentData, setDocumentData] = useState();
	const [isLoaderActive, setIsLoaderActive] = useState(false);
	const [clickedIndex, setClickedIndex] = useState();
	const [pagination, setPagination] = useState({
		currentPage: 1,
		nextPage: null,
		previousPage: null,
		totalPage: null,
	});

	const pageSize = 10;
	useEffect(() => {
		serviceData();
	}, [psIdData]);

	const serviceData = () => {
		try {
			let n = pagination;
			if (psIdData.length > pageSize) {
				n['nextPage'] = n.currentPage + 1;
			}
			if (n.currentPage > 1) {
				n.previousPage = n.currentPage - 1;
			}
			n['totalPage'] = Math.floor(psIdData.length / pageSize);
			if (psIdData.length % pageSize != 0) {
				n['totalPage'] += 1;
			}
			setPagination(n);
			setServiceViewData(
				psIdData.slice(
					pagination.currentPage * pageSize - pageSize,
					pagination.currentPage * pageSize
				) || []
			);
		} catch (e) {
			console.log('Error ::', e);
		}
	};

	const nextPage = () => {
		try {
			setServiceViewData(
				psIdData.slice(
					(pagination.currentPage + 1) * pageSize - pageSize,
					(pagination.currentPage + 1) * pageSize
				)
			);
			let n = pagination;
			n['currentPage'] = n['currentPage'] + 1;
			n['previousPage'] = n.currentPage;
			setPagination(n);
		} catch (e) {
			console.log('Error ::', e);
		}
	};

	const firstPage = () => {
		try {
			setServiceViewData(psIdData.slice(1 * pageSize - pageSize, 1 * pageSize));
			let n = pagination;
			n['currentPage'] = 1;
			n['previousPage'] = null;
			setPagination(n);
		} catch (e) {
			console.log('Error ::', e);
		}
	};

	const lastPage = () => {
		try {
			setServiceViewData(
				psIdData.slice(
					pagination.totalPage * pageSize - pageSize,
					pagination.totalPage * pageSize
				)
			);
			let n = pagination;
			n['currentPage'] = n.totalPage;
			n['previousPage'] = n.totalPage - 1;
			setPagination(n);
		} catch (e) {
			console.log('Error ::', e);
		}
	};

	const previousPage = () => {
		try {
			setServiceViewData(
				psIdData.slice(
					(pagination.currentPage - 1) * pageSize - pageSize,
					(pagination.currentPage - 1) * pageSize
				)
			);
			let n = pagination;
			n['currentPage'] = n['currentPage'] - 1;
			if (n['currentPage'] == 1) {
				n['previousPage'] = null;
			} else {
				n['previousPage'] = n.currentPage;
			}
			setPagination(n);
		} catch (e) {
			console.log('Error ::', e);
		}
	};

	const showTicketDetails = (id, index) => {
		try {
			setClickedIndex(index);
			setIsLoaderActive(true);
			putpost(
				`${sfServiceBaseUrl}/getDetailsServiceTicket`,
				(data) => {
					setDocumentData(data['data']);
				},
				(data) => {
					failureToast('Please Enter Valid PSID');
				},
				{ ticketNo: id },
				'post'
			);
		} catch (e) {
			console.log('Error', e);
		}
	};

	return (
		<Container fluid>
			<Card className="student_service_card">
				<CardHeader className="fixedHeader">
					<div className="header_search">
						<Input
							value={searchPsID}
							disabled={isDisabled}
							maxLength="12"
							name="psid"
							placeholder="Student ID"
							onChange={(e) => {
								let value = e.target.value;
								if (validate('numericValue', value)) {
									setSearchPsId(value);
								}
							}}
						/>
						<span
							className="search_icon"
							onClick={() => {
								getSearchData(searchPsID, 'searchInside');
							}}
						>
							{!isDisabled ? (
								<i class="fas fa-search"></i>
							) : (
								<Loader type="Oval" color="#ffffff" height={25} width={25} />
							)}
						</span>
					</div>
					<div className="right_heading">
						<div>
							<h4>Student ID : </h4> <span>{psId}</span>
						</div>
					</div>
				</CardHeader>
				<CardBody>
					{serviceViewData &&
						serviceViewData.map((item, index) => (
							<ServiceView
								item={item}
								index={index}
								clickedIndex={clickedIndex}
								showTicketDetails={showTicketDetails}
								documentData={documentData}
								isLoaderActive={isLoaderActive}
								pagination={pagination}
							/>
						))}
					<>
						{pagination.totalPage > 1 ? (
							<GetPagination
								pagination={pagination}
								previousPage={previousPage}
								firstPage={firstPage}
								lastPage={lastPage}
								nextPage={nextPage}
								setPagination={setPagination}
							/>
						) : null}
					</>
				</CardBody>
			</Card>
		</Container>
	);
};

export default ServiceDataShow;
