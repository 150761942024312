import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom'
import Loader from 'react-loader-spinner';
import {
  Card,
  CardHeader,
  Container,
  Button,
  Table,
  Row,
  Col,
  UncontrolledCollapse,
  CardBody,
  Form,
  FormGroup
} from 'reactstrap';
import { SimpleInput } from 'components/FormComponent/formcomponent';
import { useForm } from 'react-hook-form';
import {
  getAPI,
  enrollmentServiceBaseUrl,
} from 'services/http';
import { useHistory } from 'react-router-dom';
import { GetPagination } from '../../../common/pagination';
import { PermissionContext, RolePermissions } from "appContext";
import { pages } from "../../../common/constant";
import {ButtonText} from "variables/Buttons";
import CustomButton from "components/CustomButton";
import { failureToast } from '../../../common/utils/methods/toasterFunctions/function';
const _ = require('lodash');


const StudentFilter = (props) => {

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["studentInfo"]['id']);

  const [state, setstate] = useState([]);
  const [studentDetails, setStudentDetails] = useState([]);
  const [apiloader, setapiloader] = useState(false);

  const pageSize = 10;
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });

  const nextPage = () => {
    setStudentDetails(
      state.slice(
        (pagination.currentPage + 1) * pageSize - pageSize,
        (pagination.currentPage + 1) * pageSize
      )
    );
    let n = pagination;
    n['currentPage'] = n['currentPage'] + 1;
    n['previousPage'] = n.currentPage;
    setpagination(n);
  };
  const firstPage = () => {
    setStudentDetails(state.slice(1 * pageSize - pageSize, 1 * pageSize));
    let n = pagination;
    n['currentPage'] = 1;
    n['previousPage'] = null;
    setpagination(n);
  };
  const lastPage = () => {
    setStudentDetails(
      state.slice(
        pagination.totalPage * pageSize - pageSize,
        pagination.totalPage * pageSize
      )
    );
    let n = pagination;
    n['currentPage'] = n.totalPage;
    n['previousPage'] = n.totalPage - 1;
    setpagination(n);
  };
  const previousPage = () => {
    if (pagination.currentPage != 1) {
      setStudentDetails(
        state.slice(
          (pagination.currentPage - 1) * pageSize - pageSize,
          (pagination.currentPage - 1) * pageSize
        )
      );
      let n = pagination;
      n['currentPage'] = n['currentPage'] - 1;
      if (n['currentPage'] == 1) {
        n['previousPage'] = null;
      } else {
        n['previousPage'] = n.currentPage;
      }
      setpagination(n);
    }
  };
  useEffect(() => {
    document.getElementById('buttonToggler').click()
  }, []);

  return (
    <>
      {apiloader ? (
        <div
          className="mx-auto text-center py-5 my-5"
          style={{ height: '50vh' }}
        >
          <Loader
            type="Rings"
            color="#00BFFF"
            height={70}
            width={70}
            timeout={10000}
          />
        </div>
      ) : null}
      <Filter
        setStudentDetails={setStudentDetails}
        setstate={setstate}
        setapiloader={setapiloader}
        apiloader={apiloader}
        pageSize={pageSize}
        pagination={pagination}
        setpagination={setpagination}
        history={props.history}
        userPermissions={userPermissions}
      />
      <StudentInfoTable
        setStudentDetails={setStudentDetails}
        studentDetails={studentDetails}
        apiloader={apiloader}
      />
      {pagination.totalPage > 1 && !apiloader ? (
        <GetPagination
          pagination={pagination}
          previousPage={previousPage}
          firstPage={firstPage}
          lastPage={lastPage}
          nextPage={nextPage}
        />
      ) : null}
    </>
  );
};



const StudentInfoTable = (props) => {
  let history = useHistory();
  return (
    <>
      <Container className={props.apiloader ? "d-none" : "mt-3"} fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Student Info</h3>
                <div
                  className="floatRight "
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Button
                    color="info"
                    size="sm"
                    type="button"
                    id="buttonToggler"
                  >
                    Filter
                </Button>
                </div>

              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout">
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center white-breakSpace">
                        Student ID
                      </th>
                      <th className="text-center white-breakSpace">
                        Student First Name
                      </th>
                      <th className="text-center white-breakSpace">
                        Student Last Name
                      </th>
                      <th className="text-center white-breakSpace">
                        Father Name
                      </th>
                      <th className="text-center white-breakSpace">
                        Primary Mobile Number
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {props.studentDetails
                      ? props.studentDetails.map((el, index) => {
                        return (
                          <tr
                            key={index + '-class-'}
                            onClick={() => {
                              history.push(
                                `/admin/studentInformation/${el.studentId}`
                              );
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <td className="text-center white-breakSpace">
                              {el['studentId']}
                            </td>
                            <td className="text-center white-breakSpace">
                              {el['studentFirstName']}
                            </td>
                            <td className="text-center white-breakSpace">
                              {el['studentLastName']}
                            </td>
                            <td className="text-center white-breakSpace">
                              {el['studentFatherName']}
                            </td>
                            <td className="text-center white-breakSpace">
                              {el['studentMobileNumber']}
                            </td>
                          </tr>
                        );
                      })
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};


const Filter = (props) => {
  const { handleSubmit,register } = useForm();
  const [randomkey, setrandomKey] = useState('randomKey');
  const [studentId, setStudentId] = React.useState('')
  const [mobileNumber, setMobileNumber] = React.useState('')

  const SaveButton = () => {
    handleSubmit((formValues) => {
      if (formValues['studentFirstName']) {
        formValues['studentFirstName'] = formValues['studentFirstName'].trim()
      }
      if (formValues['studentLastName']) {
        formValues['studentLastName'] = formValues['studentLastName'].trim()
      }
      if (formValues['studentFatherName']) {
        formValues['studentFatherName'] = formValues['studentFatherName'].trim()
      }
      if (studentId) {
        formValues['studentId'] = studentId
      }
      if (mobileNumber) {
        formValues['primaryPhoneNumber'] = mobileNumber
      }
      if (formValues['studentFirstName'] && !formValues['studentLastName'] && !formValues['studentFatherName']) {
        failureToast('Please Enter Student Last Name')
        return
      }
      if (formValues['studentLastName'] && !formValues['studentFirstName']) {
        failureToast('Please Enter Student First Name')
        return
      }
      if (formValues['studentFatherName'] && !formValues['studentFirstName']) {
        failureToast('Please Enter Student First Name')
        return
      }
      if (studentId && studentId.length > 12) {
        failureToast('Student Id Can Be Of 12 Digits')
        return
      }
      const ret = [];
      for (var key in formValues) {
        if (formValues.hasOwnProperty(key)) {
          if (formValues[key] != null && formValues[key] != '') {
            ret.push(encodeURIComponent(key) + '=' + encodeURIComponent(formValues[key]));
          } else {
            formValues[key] = null
          }
        }
      }

      if (ret.length > 0) {
        props.setapiloader(true)
        let queryStringForSmartSearch = ''
        if(formValues['studentId'] && formValues['studentId'] !== null){
          queryStringForSmartSearch = queryStringForSmartSearch.concat(`studentId=${formValues['studentId']}&`);
        }
        if(formValues['studentFirstName'] !== null){
          queryStringForSmartSearch = queryStringForSmartSearch.concat(`studentFirstName=${formValues['studentFirstName']}&`);
        }
        if(formValues['studentLastName'] !== null){
          queryStringForSmartSearch = queryStringForSmartSearch.concat(`studentLastName=${formValues['studentLastName']}&`);
        }
        if(formValues['primaryPhoneNumber'] !== undefined){
          queryStringForSmartSearch = queryStringForSmartSearch.concat(`primaryPhoneNumber=${formValues['primaryPhoneNumber']}&`);
        }
        if(formValues['studentFatherName'] !== null){
          queryStringForSmartSearch = queryStringForSmartSearch.concat(`studentFatherName=${formValues['studentFatherName']}&`);
        }

        getAPI(enrollmentServiceBaseUrl + '/studentDetails/getAllStudentBySmartSearch/?' + queryStringForSmartSearch, (data) => {
            props.setstate(data.data)
            let n = props.pagination;
            n['currentPage'] = 1
            if (data['data'].length > props.pageSize) {
              n['nextPage'] = n.currentPage + 1;
            }
            if (n.currentPage > 1) {
              n.previousPage = n.currentPage - 1;
            }
            n['totalPage'] = Math.floor(data['data'].length / props.pageSize);
            if (data['data'].length % props.pageSize != 0) {
              n['totalPage'] += 1;
            }
            props.setpagination(n);
            props.setapiloader(false);
            props.setStudentDetails(
              data['data'].slice(
                props.pagination.currentPage * props.pageSize - props.pageSize,
                props.pagination.currentPage * props.pageSize
              ) || []
            );
          }, (data) => { 
            props.setapiloader(false); 
            props.setStudentDetails([]); 
            failureToast(data['message']) 
          }, formValues, 'post')  
      }
      else {
        failureToast('Please Choose At Least One Filter')
      }
    })()
  }

  return (
    <>
      <UncontrolledCollapse toggler="#linkToggler,#buttonToggler">
        <Container className={props.apiloader ? "d-none" : ("mt-3", "fadeInDown")} key={randomkey} fluid>
          <Row>
            <div className="col">
              <Card className="mb-0">
                <CardHeader className={'d-flex justify-content-between'}>
                  <h3 className="mb-0">Student Info Search Page</h3>
                  {/* <Button color="info" size="md" type="button" className={"floatRight mx-1 "} 
                     onClick={() => props.history.push('/admin/studentInformation/new/createStudentId')}
                    >Add New Student</Button> */}
                  <CustomButton 
                      className={ "floatRight mx-1" }
                      content={ButtonText.StudentInfo.addNew}
                      icon={true}
                      permissionType={'C'}
                      permissionSet={props.userPermissions}
                      onClick={() => props.history.push('/admin/studentInformation/new/createStudentId')}
                  />
                </CardHeader>
                <CardBody>
                  <Form id="filterQuestion">
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label">Student ID</label>
                          <input
                            value={studentId}
                            className="form-control"
                            type="text"
                            onChange={(e) => {
                              const re = /^[0-9\b]+$/;
                              if (e.target.value === '' || re.test(e.target.value)) {
                                setStudentId(e.target.value)
                              }
                            }}
                            maxLength={12}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <SimpleInput maxLength={50} reference={register} title={"Student First Name"} name={"studentFirstName"} />
                      </Col>
                      <Col md="6">
                        <SimpleInput maxLength={50} reference={register} title={"Student Last Name"} name={"studentLastName"} />
                      </Col>
                      <Col md="6">

                        <SimpleInput maxLength={50} reference={register} title={"Father Name"} name={"studentFatherName"} />

                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label">Primary Mobile Number</label>
                          <input
                            value={mobileNumber}
                            className="form-control"
                            type="text"
                            onChange={(e) => {
                              const re = /^[0-9\b]+$/;
                              if (e.target.value === '' || re.test(e.target.value)) {
                                setMobileNumber(e.target.value)
                              }
                            }}
                            maxLength={10}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3" style={{ "marginTop": "2rem" }} >
                        {/* <Button

                          className="btn-vimeo btn-icon w-100"
                          color="info"
                          type="button"
                          onClick={() => SaveButton()}>
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-search" />
                          </span>
                          <span className="btn-inner--text">Search</span>
                        </Button> */}
                        <CustomButton 
                          className={ "btn-vimeo btn-icon w-100" }
                          content={ButtonText.StudentInfo.search}
                          icon={true}
                          permissionType={'S'}
                          permissionSet={props.userPermissions}
                          onClick={() => SaveButton()}
                        />
                      </Col>
                      <Col md="3" style={{ "marginTop": "2rem" }}>
                        {/* <Button
                          className="btn"
                          color="secondary"
                          type="button"
                          style={{ "background": "gray" }}
                          onClick={() => {
                              setrandomKey(
                                new Date().getMilliseconds()); 
                                props.setStudentDetails([]); 
                                setStudentId(''); 
                                setMobileNumber(''); 
                                props.setpagination({
                                  currentPage: 1,
                                  nextPage: null,
                                  previousPage: null,
                                  totalPage: null,
                              });
                          }}>
                          <span className="btn-inner--text">Reset Filters</span>
                        </Button> */}
                        <CustomButton 
                          className={ "btn" }
                          content={ButtonText.StudentInfo.reset}
                          type={'reset'}
                          permissionType={'S'}
                          permissionSet={props.userPermissions}
                          onClick={() => {
                            setrandomKey(
                              new Date().getMilliseconds()); 
                              props.setStudentDetails([]); 
                              setStudentId(''); 
                              setMobileNumber(''); 
                              props.setpagination({
                                currentPage: 1,
                                nextPage: null,
                                previousPage: null,
                                totalPage: null,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Row >
        </Container >
      </UncontrolledCollapse >
    </>
  );
};
export default withRouter(StudentFilter);
