import {
  BATCH_LIST, BATCH_LIST_FAILURE, BATCH_LIST_REQUEST,
  BATCH_LIST_SUCCESS, START_DOWNLOAD_LOADER, START_LOADER, STOP_DOWNLOAD_LOADER, STOP_LOADER, UPDATE_BATCH_DROPDOWNS, UPDATE_BATCH_MAIN_DROPDOWNS, UPDATE_BATCH_STATUS_FAILURE
} from "./batch-action-types";




const defaultState = {
  batchList: [],
  showDialog: false,
  batch: undefined,
  rowCount: 0,
  currentPage: 0,
  loading: false,
  dropdowns: [],
  mainDropdowns: [],
  downloadCsvLoader: false,
};

export const studentBatchReducer = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    case BATCH_LIST:
      return { ...state };
    case BATCH_LIST_REQUEST:
      return {
        ...state,
        loading: true
      }
    case BATCH_LIST_SUCCESS:
      return {
        ...state,
        batchList: action.batchList,
        rowCount: action.rowCount,
        currentPage: action.currentPage,
        loading: false
      };
    case BATCH_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        batchList: [],
        rowCount: 0,

      }
    case UPDATE_BATCH_DROPDOWNS:
      return {
        ...state,
        dropdowns: action.dropdowns,
        loading: false

      }
    case UPDATE_BATCH_MAIN_DROPDOWNS:
      return {
        ...state,
        mainDropdowns: action.dropdowns,
        loading: false

      }
    case UPDATE_BATCH_STATUS_FAILURE:
      return {
        ...state,
        loading: false
      }
    case START_DOWNLOAD_LOADER:
      return {
        ...state,
        downloadCsvLoader: true,
      }
    case STOP_DOWNLOAD_LOADER:
      return {
        ...state,
        downloadCsvLoader: false,
      }
    case START_LOADER:
      return {
        ...state,
        loading: true
      }
    case STOP_LOADER:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
}