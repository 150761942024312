import React, { createContext, useState } from "react";

const SomethingWentWrongContext = createContext({});
const SomethingWentWrongDispatch = createContext({});

const SomethingWentWrongProvider=({ children })=> {
    const [isSomeThingWentWrong, setIsSomethingWentWrong] = useState(false);

    return (
        <SomethingWentWrongContext.Provider value={isSomeThingWentWrong}>
            <SomethingWentWrongDispatch.Provider value={setIsSomethingWentWrong}>
                {children}
            </SomethingWentWrongDispatch.Provider>
        </SomethingWentWrongContext.Provider>
    );
}

export { SomethingWentWrongProvider, SomethingWentWrongContext, SomethingWentWrongDispatch };