import React from 'react';
import {Card,Container,Row,Col,CardBody,Form} from 'reactstrap';
import CommonInput from "../../../../common/formFeilds/input/commonInput";
import CustomButton from "components/CustomButton";


const TermTableSearchForm = (props)=>{
    const {
        searchHandler,
        resetHandler,
        updateSearchData,
        searchData,
        academicData,
        termData,
        userPermissions
    } = props;

    return (
        <Container className="mt-3 mb-3" fluid>
            <Row>
            <div className="col">
                    <Form>
                        <Row>
                            <Col md={6}>
                                <CommonInput label={'Academic Career'}
                                        data={academicData}
                                        type={'reactSelect'}
                                        placeHolder={'Academic Career'}
                                        onChange={(value)=>{updateSearchData('academicCareer',value)}}
                                        defaultValue={searchData["academicCareer"]}
                                        isDisabled={false}
                                />
                            </Col>
                            <Col md={6}>
                                <CommonInput label={'Term'}
                                        data={termData}
                                        type={'reactSelect'}
                                        placeHolder={'Term'}
                                        onChange={(value)=>{updateSearchData('term',value)}}
                                        defaultValue={searchData["term"]}
                                        isDisabled={false}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <CustomButton 
                                    className={ "floatRight mx-1" }
                                    content={'Reset'}
                                    type={'reset'}
                                    permissionType={'R'}
                                    permissionSet={userPermissions}
                                    onClick={ ()=>resetHandler()}
                                />
                                <CustomButton 
                                    className={ "floatRight mx-1" }
                                    content={'Search'}
                                    icon={true}
                                    permissionType={'S'}
                                    permissionSet={userPermissions}
                                    onClick={ ()=>searchHandler()}
                                />
                            </Col>
                        </Row>
                    </Form>
            </div>
            </Row>
        </Container>
    )
}
export default TermTableSearchForm