import React from 'react';
import SearchData from './searchData';
import './attendance.scss';

const TimeTableAttendance = () => {   
    
    return <SearchData  />
    
}

export default TimeTableAttendance;