import React, {useState, Fragment, useContext} from 'react';
import CourseCatalogSequence from './courseCatalogSequence';
import CourseDetails from './courseDetails';
import {PermissionContext, RolePermissions} from "../../../../../../appContext";
import {pages} from "../../../common/constant";

const MainForm = () =>
{
    const permissions = useContext(PermissionContext);

    const userPermissions = RolePermissions(
        permissions,
        pages['courseCatalogSequence']['id']
    );
    const [ activeStep, setActiveStep ] = useState( 0 )
    const [courseId,setCourseId] = useState()
    let showForm = null;

    const nextStep = (id) =>
    {
        setCourseId(id)
        setActiveStep( activeStep + 1 );
    }

    switch ( activeStep )
    {
        case 0: showForm = <CourseCatalogSequence nextStep={ nextStep } permissionSet={userPermissions}/>
            break;
        case 1: showForm = <CourseDetails nextStep={ nextStep } courseId={ courseId}/>
            break;
        default:
            break;
    }

    return (
        <Fragment>
              {showForm}
        </Fragment>
    )
}

export default MainForm;