import React, { useState } from 'react'
import { ImCross } from 'react-icons/im';
import { ReactComponent as Search } from "../../../../../assets/img/svg/SearchIcon.svg";
import styled from 'styled-components';


const crossIconStyle = {
  fontSize: "11px",
  position: "absolute",
  top: "0.6rem",
  right: "30px",
  cursor: "pointer",
}

const SeachWrapper = styled.span`
  svg {
    position: absolute;
    top: 5.5px;
    right: 5px;
    cursor: pointer;
    z-index: 3;
  } 
`


const SearchInput = ({
   searchInputValueHandler=()=>{}, 
   onCancel=()=>{}, 
   placeholder, 
   onKeyPress,
   inputStyles
   }) => {
  const [inputValue, setInputValue] = useState('')
  const handler = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      searchInputValueHandler(inputValue)
    }
  }
  const clickHandler = () => {
    if (inputValue !== '')
      searchInputValueHandler(inputValue)

  }
  const onChangeHandler = (value) => {
    setInputValue(value)
    if (!value) {
      searchInputValueHandler('')
    }
    //  if(value.trim().length>=4){
    //   setError(false)
    //  }else{
    //   setError(true)
    //  }
  }



  return (
    <div style={{ position: "relative" }}>
      <input
        className='search-input'
        id="inputValue"
        value={inputValue}
        placeholder={placeholder}
        onChange={(e) => {
          onChangeHandler(e.target.value);
        }}
        onKeyDown={handler}
        onKeyPress={onKeyPress}
        style={{paddingLeft: 5,width:"28rem", minWidth: "20rem", border: "1px solid #00aeef", ...inputStyles }}
      />
      {inputValue.length > 0 &&
        <ImCross onClick={() => {
          setInputValue('')
          onCancel();
        }} style={crossIconStyle} />
      }
      <SeachWrapper> <Search onClick={clickHandler} /></SeachWrapper>
    </div>


  )
}

export default SearchInput