import React from "react";
import { Card, CardBody } from "reactstrap";
import { YellowBgColor } from "../helper";

const TestUpdatedDetails = ({ tests , mode}) => {
  const filteredTestCount = tests?.filter((test) => test?.isNewAdded)?.length;
  if (filteredTestCount === 0 || !filteredTestCount) {
    return "";
  }
  return (
    <div className="p-2">
      <Card className="m-1" style={YellowBgColor}>
        <CardBody className="p-3">
          <h4 className="heading-4">Incompleted test details</h4>
          <span>
            Information of below
            {filteredTestCount > 1 ? ` ${filteredTestCount} test(s) have ` : " 1 test has "}
              been updated from the central team. Please {mode === "VIEW" &&`'Edit test' to` } fill
            incomplete details to proceed.
          </span>
        </CardBody>
      </Card>
    </div>
  );
};

export default TestUpdatedDetails;
