import React, { useContext } from "react";
import AInput from "views/pages/manage/common/formFeilds/customInput/AInput";
import SelectSearchInputParam from "./SelectSearchInputParam";
import { FILTER__FORM_FIELDS_GLOBAL, PAGE_STATE, StudentListContext } from "../contextApi";
import { failureToast } from "../../../common/utils/methods/toasterFunctions/function";
import { useDispatch } from "react-redux";
// import { getStudentList } from "../../../../../../redux/studentCharges/actions";
let searchParamTitleMap = { psid: 'PSID', applicationId: 'Application ID' }


const SearchInput = ({studentContext, getStudentList}) => {
  const dispatch = useDispatch();

  const { globalFilter, setGlobalFilter,setCurrentPageState } = useContext(studentContext)
  // const callBack = () => {
  //   setCurrentPageState(PAGE_STATE.PRINT_ID_CARD);
  //   StudentFilterForm(FILTER__FORM_FIELDS)
  // }
  const onKeyDown = (e) => {
    if ((globalFilter?.searchField === 'psid' && e.target.value.length === 11) || (globalFilter?.searchField === 'applicationId' && e.target.value.length === 8)) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        applyFilterHandler();
      }
    }
  }

  const applyFilterHandler = () => {
    if ((globalFilter?.searchField === 'psid' && globalFilter?.['psid']?.length === 11) || (globalFilter?.searchField === 'applicationId' && globalFilter?.['applicationId']?.length === 8)) {
      dispatch(
        getStudentList(
          {...FILTER__FORM_FIELDS_GLOBAL, [globalFilter?.searchField] :   globalFilter?.[globalFilter?.searchField], searchField : globalFilter?.searchField},
          0
        )
      );
      setGlobalFilter({...FILTER__FORM_FIELDS_GLOBAL, [globalFilter?.searchField] :   globalFilter?.[globalFilter?.searchField], searchField : globalFilter?.searchField})
      setCurrentPageState(PAGE_STATE.LIST_STUDENT)
    }else{
      failureToast(`Please enter valid ${searchParamTitleMap[globalFilter?.searchField]}`)
    }
  }

  const removeFilterHandler = () => {
    // dispatch(
    //   getStudentList(
    //     { ...globalFilter },
    //     0
    //   )
    // );
  }


  return (
    <div className="mb-2">
      <div>
        <SelectSearchInputParam
          searchInputParam={globalFilter?.searchField ?? 'psid'}
          setSearchInputParam={(value) => setGlobalFilter(prev => ({ ...FILTER__FORM_FIELDS_GLOBAL,'searchField': value, [value] : '' }))}
          searchParamTitleMap={searchParamTitleMap}
        />
      </div>
      <div style={{ width: "40%" }}>
        <AInput
          placeholder={`Search by ${searchParamTitleMap[globalFilter?.searchField ?? 'psid']}`}
          style={{ height: "42px" }}
          value={globalFilter?.[globalFilter?.searchField ?? 'psid']}
          handler={(value) => setGlobalFilter(prev => ({ searchField: prev?.searchField ?? 'psid', [globalFilter?.searchField ?? 'psid']: value }))}
          onKeyDown={(e) => onKeyDown(e)}
          crossIcon={true}
          rightSearchIcon={true}
          crossHandler={async () => {
            setGlobalFilter(prev => ({ ...prev, [globalFilter?.searchField]: '' }));
            // setPage(0);
            // resetStudentApplications();
            removeFilterHandler()
          }}
          searchIconClickHandler={() => {
            applyFilterHandler();
          }}
          onKeyPress={(e) => {
            if (
              !/^[0-9]*$/.test(
                String.fromCharCode(e.charCode)
              )
            ) {
              e.preventDefault();
              return;
            }
          }}
          maxLength={globalFilter?.searchField === 'psid' ? 11 : 8}
        />
      </div>
    </div>
  );
};


export default SearchInput;
