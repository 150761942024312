import React from 'react'
import { useHistory } from 'react-router'
import { Card, CardBody } from 'reactstrap'
import CustomChip from '../../common/customChip/CustomChip'
import AButton from '../../common/form-fields-mui/AButton'
import { courseCardHeadingStyles } from './utils'
import ShowLectureCount from './ShowLectureCount';

const ListViewCourseCard = ({ courseIcon, courseData = {}, viewAddLink = ``, lectureCountByDuration }) => {

  const history = useHistory();
  let subject = courseData['subjectName'].toUpperCase()
  let lectureCount = lectureCountByDuration[subject]
  return (
    <Card className='mb-4'>
      <CardBody className='d-flex justify-content-between'>
        <div className='d-flex align-items-center'>
          {courseIcon}
          <h3 style={{ ...courseCardHeadingStyles, marginBottom: 0, marginLeft: '1rem' }}>{courseData?.subjectName}</h3>
        </div>
        <div className='d-flex align-items-center'>
          <ShowLectureCount lectureDuration={lectureCount} viewMode='list' />

          <AButton variant='link' updatedStyle={{ height: '24px' }} onClick={() => history.push(viewAddLink)}>View/Add lectures</AButton>
        </div>
      </CardBody>
    </Card>
  )
}

export default ListViewCourseCard