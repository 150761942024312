import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router';
import { putpost } from 'services/http';
import searchForm from './serachForm.json'
import { FieldsRenderer } from './component';
// import { putpost } from 'services/http'
import { fetchAllPostPromisedData, fetchAllPromisedData, getRequiredSign, NewRow } from '../../common/utils/methods/commonMethods/utilityMethod';
import { Card, CardHeader, Container, Row, Table, UncontrolledTooltip, Button } from 'reactstrap';
import CustomButton from '../../../../../components/CustomButton';
import { PermissionContext, RolePermissions } from "appContext";
import { constants, endpoint, pages } from "../../common/constant";
import { getPaginatedData } from '../../common/utils/methods/commonMethods/paginationMethod';
import { failureToast } from '../../common/utils/methods/toasterFunctions/function';
import CustomLoader from "../../common/commonComponents/Loader/loader";
import { MasterHeaderAuto } from '../../common/commonComponents/masterHeader/masterHeader';
import { GetPagination } from '../../common/commonComponents/pagination/pagination';
import { ConfirmationDialog, Dialog } from '../../common/commonComponents';
import fieldObjects from './form.json'
import moment from 'moment'
import CustomDownload from "../../common/commonComponents/CustomDownload"
import { ResetIcon } from 'assets/img/icons/common/CommonIcons';

const pageSize = 10;
let page = 0;
let isNextCallPossible = true;
const headerList = [
  { name: 'USER ID' },
  { name: 'USER Name' },
  { name: 'Designation' },
  { name: 'Department' },
  { name: 'Employee ID' },
  { name: 'Project' },
  { name: 'Status' },
  { name: 'Action', colSpan: '2' }
]

const listToRender = [
  'userId',
  'userName',
  'designationId',
  'departmentId',
  'employeeId',
  'project',
  'status'
]

const itemToBeRenderFromMaster = [
  "designationId",
  "departmentId",
];
const propertyList = [
  "",
  "",
  "label",
  "label",
  "",
  "",
];

const altList = [
  null, null, "designation", "department", null, null
]


const ProfileSearchPage = () => {

  const history = useHistory();

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["createUserProfile"]['id']);


  const [values, setValues] = useState({
    userId: '',
    userName: '',
    department: '',
    employeeId: '',
    project: '',
    designation: ''
  })

  const [ticketDetails, setTicketDetails] = useState({
    ticketId: '',
    ticketDate: null
  })

  const [savedSearchParams, setSavedSeachParams] = useState(null)

  const [department, setDepartment] = useState([]);
  const [project, setProject] = useState([]);
  const [designation, setDestination] = useState([])
  const [status] = useState({ fetching: false, data: constants.status })
  const [isFetching, setIsFetching] = useState(false)
  let [pagination, setpagination] = useState(constants.pagination);
  const [tableData, setTableData] = useState([]);
  const [serverData, setServerData] = useState(null);
  const [reseting, setReseting] = useState(false);
  const [resetDialogOpen, setResetDialogOpen] = useState(false)
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [userIdToReset, setUserIdToReset] = useState(null)
  const [exportDataKey, setExportDataKey] = useState(null);

  // const [confirmationDialog,setConfirmationDialog] = useState(false)
  // const [userIdToReset,setUserIdToReset] = useState(null)


  const mastersList = [[], [], designation?.data, department?.data, [], [], []];


  const getDepartment = async () => {
    const department = await fetchAllPromisedData(endpoint.departmentType.getAll);
    if (Array.isArray(department)) {
      const tempArr = department.map(d => { return { label: d.departmentTypeDispValue, value: d.id, id: d.id } })
      setDepartment({ data: tempArr })
    }
  }

  const getDesignation = async () => {
    const designation = await fetchAllPromisedData(endpoint.jobTitle.getAll);
    if (Array.isArray(designation)) {
      const tempArr = designation.map(d => { return { label: d.jobTitleDispValue, value: d.id, id: d.id } })
      setDestination({ data: tempArr })
    }
  }

  const getProject = async () => {
    const project = await fetchAllPromisedData(endpoint.projectDetails.getAllProject);
    if (Array.isArray(project)) {
      const tempArr = project.map(d => { return { label: d.projectDispValue, value: d.id, key: d.projectKey } })
      setProject({ data: tempArr })
    }
  }

  const getInitailData = () => {
    getDepartment();
    getDesignation();
    getProject();
  }

  useEffect(() => {
    getInitailData();
  }, [])


  const searchHandler = async () => {
    try {

      if (!values.userId && !values.userName && !values.designation?.value && !values.department?.value && !values.employeeId && !values.project?.key) {
        failureToast("Select atleast one search criteria")
        return
      }

      setIsFetching(true);
      const searchData = await fetchAllPostPromisedData(`${endpoint.searchProfile}/0`, { ...values, project: values.project?.key ?? null, designation: values.designation?.value ?? null, department: values.department?.value ?? null })
      if (searchData?.code === 200) {
        setServerData(searchData.data);
        getPaginatedData(searchData.data, pageSize, constants.pagination, setpagination, setIsFetching, setTableData);
        setSavedSeachParams({ ...values, project: values.project?.key ?? null, designation: values.designation?.value ?? null, department: values.department?.value ?? null })
      } else {
        setIsFetching(false);
        setSavedSeachParams(null);
        setServerData(null);
        tableData([])
        // failureToast(templateData['message']);
      }
    } catch (e) {
      console.log('Error', e);
    }

    callExportApi(values);
  };

  const callExportApi = async (values) => {
    try {
      const exportKeyData = await fetchAllPostPromisedData(`${endpoint.exportExcel}`, { ...values, project: values.project?.key ?? null, designation: values.designation?.value ?? null, department: values.department?.value ?? null });

      if (exportKeyData.code === 200) {
        setExportDataKey(exportKeyData.data);
      } else {
        failureToast(exportKeyData.message);
      }
    } catch (e) {
      console.log('Error', e);
    }
  }

  const downloadFile = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `search-result.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  const fetchNext = async () => {
    setIsFetching(true);
    page = page + 1;
    const templateData = await fetchAllPostPromisedData(`${endpoint.searchProfile}/${page}`, savedSearchParams)
    if (templateData?.code === 200 && templateData?.data?.length > 0) {
      const updatedData = [...serverData, ...templateData.data]
      setServerData(updatedData);
      getPaginatedData(updatedData, pageSize, pagination, setpagination, setIsFetching, setTableData)
      isNextCallPossible = templateData?.data?.length == 50 ? true : false;
    } else {
      isNextCallPossible = false;
      setIsFetching(false);
    }
  }

  const reset = () => {
    setValues({
      userId: '',
      userName: '',
      department: '',
      employeeId: '',
      project: '',
      designation: ''
    })
    setSavedSeachParams(null)
  }

  const resetPassword = () => {
    if (!ticketDetails.ticketId) {
      failureToast("Please enter ticket ID")
      return
    }

    if (ticketDetails.ticketId && ticketDetails.ticketId.toString().length < 5) {
      failureToast("Please enter valid ticket ID")
      return
    }

    if (!ticketDetails.ticketDate) {
      failureToast("Please select ticket date")
      return
    }

    setReseting(true)

    putpost(
      endpoint.requestResetPassword,
      (data) => {
        if (data?.code == 200) {
          setResetDialogOpen(false)
          setConfirmationDialog(true)
          setUserIdToReset(null)
        }
      },
      (data) => {
        failureToast(data['message']);
        setReseting(false)
        // setResetDialogOpen(false)
      },
      { ...ticketDetails, ticketDate: moment(ticketDetails.ticketDate).format('MM.DD.YYYY'), "userId": userIdToReset, "clientId": "PHX", ticketDescription: "Reset password-Support" },
      'post'
    );
  }

  const resetPasswordDialog = () => {
    setTicketDetails({
      ticketId: '',
      ticketDate: null
    })
    setReseting(false)
  }

  return (

    <Container fluid>
      <Card>
        <CardHeader>
          <div>
            <h3 style={{ marginBottom: 0 }}>User/Employee Search</h3>
            <span style={{ fontSize: '10px', marginTop: '-10px' }}>{getRequiredSign()}For employee data whose User profile is not created, please search with Employee id or Employee name only.</span>
          </div>

          <CustomButton
            className={'floatRight mx-1 '}
            content={"Add New User Profile"}
            permissionType={'C'}
            icon={true}
            onClick={() => history.push("/admin/userProfile/new")}
            permissionSet={userPermissions}
          />
        </CardHeader>
        <Row className="pl-4 pr-4 pb-2 pt-2">
          <FieldsRenderer
            fields={Object.keys(searchForm)}
            fieldsObject={searchForm}
            values={values}
            setValues={setValues}
            dataObjects={{
              status: status, department: department, project: project,
              designation: designation
            }}
          // isPreviewEnable={isPreviewEnable}
          // disabled={isTicketDetailValid}
          />
        </Row>
        <Row className="justify-content-end pr-5 pb-4">

          <CustomButton
            disabled={isFetching}
            content={'Search'}
            permissionType={'S'}
            icon={true}
            permissionSet={userPermissions}
            onClick={searchHandler}
          />
          <CustomButton
            disabled={!values.userId && !values.userName && !values.designation?.value && !values.department?.value && !values.employeeId && !values.project?.value ? true : false}
            content={'Reset'}
            type={'reset'}
            permissionType={'R'}
            permissionSet={userPermissions}
            onClick={reset}
          />

        </Row>
      </Card>

      <Row className="mt-n2">
        {isFetching ? (
          <div className="col">
            <Card className=" w-100">
              <div
                className="mx-auto text-center py-5 my-5 "
                style={{ height: '100vh' }}
              >
                <CustomLoader apiLoader={isFetching} />
              </div>
            </Card>
          </div>
        ) : (
          <>

            {serverData && <div className="col">
              <Card className="overflow-auto">
                <CardHeader className="border-0">
                  <h3 className="mb-0 floatLeft">Search Results</h3>
                  {exportDataKey && <CustomDownload
                    downloadKey={exportDataKey}
                    downloadUrl={endpoint.fetchS3Url.getDownloadUrl}
                    type={'exportToExcel'}
                    content={"Export to Excel"}
                    permissionSet={userPermissions}
                    outline
                    color="success"
                    isIcon={true}
                  />}
                </CardHeader>
                <Table className="align-items-center tableLayout">
                  <MasterHeaderAuto headerList={headerList} permissionSet={userPermissions} isSaveVisible={false} />
                  <tbody className="list" key={'table-message-template'}>
                    {tableData.map((el) => {
                      return <NewRow
                        data={{ ...el }}
                        itemToBeRenderFromMaster={itemToBeRenderFromMaster}
                        arrList={mastersList}
                        propertyList={propertyList}
                        listToBeRendered={listToRender}
                        altList={altList}
                      >
                        <td className="text-center  white-breakSpace" colSpan={2}>

                          <CustomButton
                            permissionType={'R'}
                            icon={true}
                            forTable={true}
                            permissionSet={userPermissions}
                            // onClick={() => { history.push(`/admin/userProfile/view/${el.employeeId}`) }}
                            onClick={() => { window.open(`${document.URL.split('#')[0]}#/admin/${el.userId ? 'userProfile' : 'empProfile'}/view/${el.employeeId}`, '_Blank') }}

                          // onClick={()=>{}}
                          />

                          {el.status !== 'SEPARATED' && <CustomButton
                            permissionType={'U'}
                            icon={true}
                            forTable={true}
                            permissionSet={userPermissions}
                            // onClick={()=>{}}
                            onClick={() => { history.push(`/admin/${el.userId ? 'userProfile' : 'empProfile'}/edit/${el.employeeId}`) }}
                          />}

                          {el?.isNewUser == 1 && (el.status === 'ACTIVE' || el.status === 'IDLE ACCOUNT' || el.status === 'ACCOUNT LOCKED' || el.status === 'PASSWORD EXPIRED') && 
                          <>
                            <UncontrolledTooltip delay={0} placement="top" target="tooltip198087688" hideArrow={true} style={{marginLeft:20}}>
                            Reset Password
                          </UncontrolledTooltip>
                        
                          <Button
                            color={'primary'}
                            data-placement={'top'}
                            id={'tooltip198087688'}
                            size={'sm'}
                            // type={'button'}
                            permissionType={'C'}
                            icon={true}
                            forTable={true}
                            permissionSet={userPermissions}
                            onClick={() => { setResetDialogOpen(true); setUserIdToReset(el.employeeId) }}
                            style={{ padding: 0 }}
                          >
                            <ResetIcon />
                          </Button>  </>}
                        </td>
                      </NewRow>

                    })}
                  </tbody>
                </Table>
                {pagination.totalPage > 1 ? (
                  <GetPagination
                    isSaveVisible={false}
                    setclassArray={setTableData}
                    pagination={pagination}
                    setpagination={setpagination}
                    pageSize={pageSize}
                    state={serverData}
                    isPaginationFromServer={true}
                    isFetching={isFetching}
                    fetchNext={fetchNext}
                    isNextCallPossible={isNextCallPossible}
                  />
                ) : null}
              </Card>
            </div>}
          </>
        )}
      </Row>
      <Dialog
        isModalVisible={resetDialogOpen}
        // isCloseButtonRequired={true}
        title={'Reset Password Ticket Details'}
        toggle={() => { setUserIdToReset(null); setResetDialogOpen(false); }}
        headerWithBg={true}
        size={"md"}
      >
        <Row className="pb-2 pt-2 ">
          <FieldsRenderer
            fields={Object.keys(fieldObjects.resetticketDetails)}
            fieldsObject={fieldObjects.resetticketDetails}
            values={ticketDetails}
            setValues={setTicketDetails}
            disabled={reseting}
            size={6}
          />
        </Row>
        <Row className="justify-content-center pl-4 pr-4 pb-2">
          <CustomButton
            content={!reseting ? (
              <span>Reset</span>
            ) : (
              <i className="fas fa-spinner fa-spin" aria-hidden="true"></i>
            )}
            permissionType={'C'}
            permissionSet={userPermissions}
            onClick={resetPassword}
            disabled={reseting || !ticketDetails.ticketId || !ticketDetails.ticketDate}
          />
        </Row>
      </Dialog>

      {/* <ConfirmationDialog isOpen={confirmationDialog} onAgree = { reset } setIsOpen = {setConfirmationDialog}/> */}
      <ConfirmationDialog
        isOpen={confirmationDialog}
        onAgree={() => { resetPasswordDialog() }}
        setIsOpen={setConfirmationDialog}
        type={'info'}
        headerMsg={'Success'}
        msg={'Password reset done'}
        popupSymbol={<img src={require("../../../../../assets/img/svg/resetSuccess.svg")} className="m-5 p-2" width={'100%'} />}
      />

    </Container>
  )
}

export default ProfileSearchPage