import { eraseCookie, setCookie, getCookie } from '../../../views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { get_request_params } from '../../properties/headers';
import {SomethingWentWrong, controller} from "../helpers";
import { failureToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import { LOGOUTMESSAGE } from "../../constant"

const deleteAPI = async (url, _success, _failure) => {
  try {
    const request_params = get_request_params('DELETE', null);
    setCookie('lastKnowPath', document.URL.split('#')[1]);
    fetch(url, request_params)
      .then(function (response) {
        if ((response.status === 403 || response.status === 401) && getCookie('token')) {
          failureToast(LOGOUTMESSAGE);
          eraseCookie('token');
          controller.abort();
          setTimeout(()=>window.location.replace(`${document.URL.split('#')[0]}`), 3000);
          return null
        }
        if (response.ok) {
          return response.json();
        } else {
          response
            .json()
            .then(er => {
              _failure(er);
            })
            .catch(error => { });
        }
      })
      .then(function (data) {
        _success(data);
      })
      .catch(error => {
        // _failure(error)
      });
  } catch (e) {
      SomethingWentWrong();
    //console.log('Error', e);
  }
};

export { deleteAPI };
