import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader, Container, Input, Row, Table, } from "reactstrap";
// import {TDROW} from "../commonComponent/masterRow";
import { masterServiceBaseUrl, putpost, putpost2 } from "services/http";
import moment from "moment";
import { MasterHeaderAuto } from "../../common/commonComponents/masterHeader/masterHeader";
import { ButtonText } from "variables/Buttons";
import CustomToggle from "views/pages/manage/common/formFeilds/customToggle";
import { failureToast, successToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import { PermisionDenied } from "../../common/commonComponents";
import CustomButton from "components/CustomButton";


var _ = require("lodash");

const TDROW = (props) => {
    const { el, index, editRow, deleteRow, userPermissions } = props;
    return (
        <tr key={"ExamGroupNewOrEditRow"}>
            <td className="text-center p-2 mx-1">{el['tagTypeKey']}</td>
            <td className="text-center p-2 mx-1">{moment(el['effectiveDate']).format('DD-MM-YYYY')}</td>
            <td className="text-center p-2 mx-1">{el['tagTypeDesc']}</td>
            <td className="text-center p-2 mx-1">{el['tagTypeDispValue']}</td>
            <td className="text-center p-2 mx-1">{el['documentTag']}</td>
            <td className="text-center p-2 mx-1"><Button key={"statusButton"}
                color={el.status === 'ACTIVE' ? "success" : "danger"}
                size={'sm'}
                disabled={true}>{el.status === 'ACTIVE' ? "Active" : "Inactive"}</Button>
            </td>
            {userPermissions && (userPermissions.includes('D') || userPermissions.includes('U')) && <td className="text-center p-2 mx-1">
                {/* <EditButton onClick={() => editRow(index)} />
                <DeleteButton onClick={() => deleteRow(index)} /> */}
                <CustomButton
                    // content={ButtonText.ProcessTagType.save}
                    permissionType={'U'}
                    icon={true}
                    forTable={true}
                    onClick={() => editRow(index)}
                    permissionSet={userPermissions}
                    data-testid="update"
                />
                <CustomButton
                    // content={ButtonText.ProcessTagType.save}
                    permissionType={'D'}
                    icon={true}
                    forTable={true}
                    onClick={() => {
                        deleteRow(index);
                    }}
                    permissionSet={userPermissions}
                    data-testid="delete"
                />
            </td>}
        </tr>
    )
}
const ProcessTagTypeTable = (props) => {
    const { userPermissions, isSaveVisible, setisSaveVisible=()=>{} } = props;
    const [tableDataKey, settableDataKey] = useState("tableDataKey");
    const addNewClass = () => {
        props.setclassArray([
            {
                editMode: true,
                __status: "__new",
                tagTypeKey: null,
                status: "ACTIVE",
                tagTypeDesc: null,
                tagTypeDispValue: null,
                effectiveDate: null,
            },
            ...props.classArray,
        ]);
    };
    const saveAll = (cb) => {
        var _tempArray = props.classArray;
        var isValid = true;
        _tempArray.forEach((el, index) => {
            if (el["editMode"]) {
                if (el["tagTypeKey"] == null || (el["tagTypeKey"] && !el["tagTypeKey"].trim())) {
                    isValid = false;
                    failureToast("Please enter the value of Tag Type Key");
                    return;
                } else if (el["tagTypeDesc"] == null || (el["tagTypeDesc"] && !el["tagTypeDesc"].trim())) {
                    isValid = false;
                    failureToast("Please enter the value of Description");
                    return;
                } else if (el["tagTypeDispValue"] == null || (el["tagTypeDispValue"] && !el["tagTypeDispValue"].trim())) {
                    isValid = false;
                    failureToast("Please enter the value of Display Value");
                    return;
                }
                // else if (el["documentTag"] == null || (el["documentTag"] && !el["documentTag"].trim())) {
                //     isValid = false;
                //     failureToast("Please enter the value of Document Tag");
                //     return;
                // }
                if (!isValid) {
                    return;
                }
                if (_tempArray[index]["__status"] == "__new") {
                    putpost(masterServiceBaseUrl + "/processTag/createProcessTag", (data) => {
                        successToast(data["message"]);
                        setisSaveVisible(false);
                        props.fetchClasses(masterServiceBaseUrl + "/processTag/getAllProcessTag");
                    }, (data) => {
                        failureToast(data["message"]);
                    }, _tempArray[index], "post");
                } else {
                    putpost(masterServiceBaseUrl + "/processTag/updateProcessTag/" + _tempArray[index].id, (data) => {
                        successToast(data["message"]);
                        setisSaveVisible(false);
                        delete _tempArray[index]["editMode"];
                        props.setclassArray(_tempArray);
                        settableDataKey(new Date().getMilliseconds());
                        // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
                    }, (data) => { failureToast(data["message"]); }, _tempArray[index], "put"
                    );
                }
            } else {
            }
        });
    };
    const newRow = () => {
        if (isSaveVisible) {
            failureToast("Please Delete or Save current changes");
        } else {
            let cb = () => {
                addNewClass();
                setisSaveVisible(true);
                // settableDataKey(new Date().getMilliseconds())
            };
            var _tempArray = props.classArray;
            _tempArray = _.sortBy(_tempArray, [
                function (o) {
                    return o.value;
                },
            ]);
            props.setclassArray(_tempArray);
            setisSaveVisible(false);
            cb();
        }
    };
    const editRow = (index) => {
        if (isSaveVisible) {
            failureToast("Please Delete or Save current changes");
        } else {
            let cb = () => {
                var _tempArray = props.classArray;

                _tempArray[index]["editMode"] = true;
                // _tempArray[index]['documentTag'] = _tempArray[index]['documentTag'];
                props.setclassArray(_tempArray);
                setisSaveVisible(true);
            };
            var _tempArray = props.classArray;
            _tempArray = _.sortBy(_tempArray, [
                function (o) {
                    return o.value;
                },
            ]);
            cb();
        }
    };
    const deleteRow = (index) => {
        if (props.classArray[index]["__status"] == "__new") {
            let n = props.classArray;
            n.splice(index, 1);
            props.setclassArray(n);
            setisSaveVisible(false);
            settableDataKey(new Date().getMilliseconds());
        } else {
            if (window.confirm("Are you sure you want to delete this Course Fees Approval?")) {
                putpost2(masterServiceBaseUrl + "/processTag/deleteProcessTag/" + props.classArray[index].id, (data) => {
                    successToast(data["message"]);
                    props.fetchClasses(masterServiceBaseUrl + "/processTag/getAllProcessTag");
                    if (props.classArray.length == 1) {
                        // props.previousPage();
                        // props.fetchClasses(masterServiceBaseUrl + "/processTag/getAllProcessTag");
                    } else {
                        var _tempArray = props.classArray;
                        if (_tempArray[index]["editMode"]) {
                            setisSaveVisible(false);
                        }
                        _tempArray.splice(index, 1);
                        props.setclassArray(_tempArray);
                    }
                    settableDataKey(new Date().getMilliseconds());
                }, (data) => { failureToast(data["message"]); }, "PUT");
            }
        }
    };
    const updateKey = (index, value, key) => {
        var _tempArray = props.classArray;
        _tempArray[index][key] = value;
        props.setclassArray(_tempArray);
    };
    useEffect(() => {
        console.log('Dat--------------->', props.classArray)
    }, [props.classArray])
    const headerList = [{ name: "TAG TYPE KEY" }, { name: "EFFECTIVE DATE" }, { name: "DESCRIPTION" }, { name: "DISPLAY VALUE" }, { name: "DOCUMENT TAG" }, { name: "STATUS" }, { name: "ACTION" }]
    return (
        userPermissions ?
            <React.Fragment>
                <Container className="mt-3" fluid>
                    <Row>
                        <div className="col">
                            <Card>
                                <CardHeader className="border-0">
                                    <h3 className="mb-0 floatLeft">Master - Process Tag Type</h3>
                                    {/* <CreateButton
                                        text={ButtonText.ProcessTagType.addNew}
                                        data-testid="newCourseFee"
                                        disabled={isSaveVisible}
                                        className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
                                        onClick={() => { newRow(); }}
                                    /> */}
                                    <CustomButton
                                        className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
                                        content={"New Tag Type"}
                                        permissionType={'C'}
                                        icon={true}
                                        onClick={() => { newRow(); }}
                                        permissionSet={userPermissions}
                                        data-testid="newCourseFee"
                                        disabled={isSaveVisible}
                                    />
                                    {/* {isSaveVisible ? <Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => {saveAll()}}>Save</Button>:null} */}
                                </CardHeader>
                                <div id="questionTable" className="table-responsive">
                                    <Table className="align-items-center table-flush">
                                        {/*<MasterHeader type={"Course Fee Approval"} />*/}
                                        <MasterHeaderAuto headerList={headerList} permissionSet={userPermissions} isSaveVisible={isSaveVisible} />
                                        <tbody className="list" key={tableDataKey}>
                                            {props.classArray
                                                ? props.classArray.map((el, index) =>
                                                    !el["editMode"] ?
                                                        (
                                                            <TDROW type={"feeApproval"} index={index} el={el} editRow={editRow} deleteRow={deleteRow} userPermissions={userPermissions} />
                                                        )
                                                        :
                                                        (<tr key={index + "-class"}>
                                                            <td className="text-center p-2 mx-1">
                                                                <Input data-testid="feeApprovalKey" maxLength={50} defaultValue={el["tagTypeKey"]} placeholder="Example:1" className="tdInput"
                                                                    onChange={(e) => {
                                                                        updateKey(index, e.target.value, "tagTypeKey")
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="text-center p-2 mx-1">
                                                                <Input data-testid="effectiveDate" disabled={true} defaultValue={el["__status"] == "__new" ? null : moment(el["effectiveDate"]).format("DD-MM-YYYY")} placeholder="Example:01-01-2020" className="tdInput" />
                                                            </td>
                                                            <td className="text-center p-2 mx-1">
                                                                <Input data-testid="feeApprovalDesc" maxLength={50} defaultValue={el["tagTypeDesc"]} placeholder="Description" className="tdInput"
                                                                    onChange={(e) => {
                                                                        updateKey(index, e.target.value, "tagTypeDesc");
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="text-center p-2 mx-1">
                                                                <Input data-testid="feeApprovalDispValue" maxLength={50} defaultValue={el["tagTypeDispValue"]} placeholder="Display Value" className="tdInput"
                                                                    onChange={(e) => {
                                                                        updateKey(index, e.target.value, "tagTypeDispValue");
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="text-center p-2 mx-1">
                                                                <Input data-testid="documentTag" maxLength={50} defaultValue={el["documentTag"]} placeholder="Document Tag" className="tdInput"
                                                                    onChange={(e) => {
                                                                        updateKey(index, e.target.value, "documentTag");
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="text-center p-2 mx-1">
                                                                <CustomToggle
                                                                    status={el.status}
                                                                    data-testid="activeInactiveInput"
                                                                    id={el.id}
                                                                    toggleOn={"Active"}
                                                                    toggleOff={"Inactive"}
                                                                    onChangeHandler={(e) => {
                                                                        settableDataKey(new Date().getMilliseconds());
                                                                        let n = "ACTIVE";
                                                                        if (!e.target.checked) { n = "INACTIVE"; }
                                                                        updateKey(index, n, "status");
                                                                    }}
                                                                />


                                                            </td>
                                                            <td className="text-center">
                                                                {/* <Button color="info" size="sm" type="button" onClick={() => {
                          deleteRow(index)
                        }}>  <i className="fas fa-trash" /></Button> */}
                                                                {isSaveVisible ? (
                                                                    <>
                                                                        {/* {" "} */}
                                                                        {/* <UpdateButton
                                                                    text={ButtonText.ProcessTagType.save} 
                                                                    data-testid="savefee"
                                                                    onClick={() => { saveAll(); }}
                                                                /> */}
                                                                        <CustomButton
                                                                            content={ButtonText.ProcessTagType.save}
                                                                            permissionType={'C'}
                                                                            // icon={false}
                                                                            forTable={true}
                                                                            onClick={() => { saveAll() }}
                                                                            permissionSet={userPermissions}
                                                                            data-testid="savefee"
                                                                        />
                                                                        <CustomButton
                                                                            // content={ButtonText.ProcessTagType.save}
                                                                            permissionType={'cancel'}
                                                                            icon={true}
                                                                            forTable={true}
                                                                            onClick={() => {
                                                                                let n = props.classArray;
                                                                                if (n[index]["__status"] == "__new") {
                                                                                    n.splice(index, 1);
                                                                                } else {
                                                                                    // n[index]['editMode']=false
                                                                                    props.fetchClasses(masterServiceBaseUrl + "/processTag/getAllProcessTag");
                                                                                }
                                                                                props.setclassArray(n);
                                                                                setisSaveVisible(false);
                                                                            }}
                                                                            permissionSet={userPermissions}
                                                                            data-testid="closeApproval"
                                                                        />
                                                                        {/* <Button data-testid="closeApproval" color="danger" size="sm" type="button"
                                                                            onClick={() => {
                                                                                let n = props.classArray;
                                                                                if (n[index]["__status"] == "__new") {
                                                                                    n.splice(index, 1);
                                                                                } else {
                                                                                    // n[index]['editMode']=false
                                                                                    props.fetchClasses(masterServiceBaseUrl + "/processTag/getAllProcessTag");
                                                                                }
                                                                                props.setclassArray(n);
                                                                                setisSaveVisible(false);
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            <i className="fas fa-times" />
                                                                        </Button>{" "} */}
                                                                    </>
                                                                ) : (
                                                                    // <Button data-testid="deletefee" color="info" size="sm" type="button"
                                                                    //     onClick={() => {
                                                                    //         deleteRow(index);
                                                                    //     }}
                                                                    // >
                                                                    //     {" "}
                                                                    //     <i className="fas fa-trash" />
                                                                    // </Button>
                                                                    <CustomButton
                                                                        // content={ButtonText.ProcessTagType.save}
                                                                        permissionType={'D'}
                                                                        icon={true}
                                                                        forTable={true}
                                                                        onClick={() => {
                                                                            deleteRow(index);
                                                                        }}
                                                                        permissionSet={userPermissions}
                                                                        data-testid="savefee"
                                                                    />
                                                                )}
                                                            </td>
                                                        </tr>
                                                        )
                                                )
                                                : null}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </React.Fragment> : <PermisionDenied />
    );
};
export default ProcessTagTypeTable;
