import React from "react";
import ADataGrid from "../../common/data-grid/ADataGrid";
import { AGridColDef } from "../../common/data-grid/ADataGridColDef";
import { ReactComponent as DeleteIcon } from "../../../../../assets/img/svg/Delete.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/img/svg/Edit2.svg";
import { useSelector } from "react-redux";
import EmptyRowPlaceholder from './EmptyRowPlaceholder'
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'
const MSMappingList = ({ data, rowsData, deleteHandler, editHandler, nextButtonHandler, currentPage }) => {
  const stateData = useSelector(state=>state.academicSubMapping) ;

  const ListHeader = [
    new AGridColDef("academicGroups", "Academic").setWidth(200),
    new AGridColDef("subjects", "Subject").setWidth(200),
    new AGridColDef("Actions", "Actions", false).renderCellComponent(
      (params) => (
        <div className="list-action">
         <IconButtonWrapper onClick={() => editHandler(params.row)}><EditIcon /></IconButtonWrapper> 
         <IconButtonWrapper onClick={() => deleteHandler(params.row)}><DeleteIcon  /></IconButtonWrapper> 
        </div>
      )
    ),
  ];

  return (
    <div className="ms-team-channel flex-grow-1 d-flex">
      <ADataGrid
        rows={stateData.mappingList||[]}
        columns={ListHeader}
        rowId={(row) => row.id}
        loading={rowsData.length === 0}
        rowCount={stateData.rowCount||0}
        height={180}
        currentPage = {currentPage}
        nextButtonHandler = {nextButtonHandler}
        emptyRowPlaceholder = {()=><EmptyRowPlaceholder/>}
      />
    </div>
  );
};

export default MSMappingList;
