import React, { useState } from 'react';
import {
	Card,
	CardHeader,
	Input,
	Container,
	Button,
	Table,
	Row,
} from 'reactstrap';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import {
	TDROW,
	MasterHeader,
} from '../../common/commonComponents/masterRow/masterRow';
import {
	masterServiceBaseUrl,
	putpost,
	putpost2,
} from 'services/http';
import { validate } from '../../common/utils/methods/validations/validation';
import moment from 'moment';
import IsCoreMaster from '../../common/commonComponents/coreMaster';
import CustomButton from 'components/CustomButton';

var _ = require('lodash');

const TermTable = (props) => {
	const { userPermissions, isSaveVisible, setisSaveVisible=()=>{} } = props;
	const [tableDataKey, settableDataKey] = useState('tableDataKey');
	const [termKey, setTermKey] = useState('');
	const addNewClass = () => {
		props.setclassArray([
			{
				editMode: true,
				__status: '__new',
				termKey: null,
				status: 'ACTIVE',
				termDesc: null,
				termDispValue: null,
				effectiveDate: null,
			},
			...props.classArray,
		]);
	};
	const saveAll = (cb) => {
		var _tempArray = props.classArray;
		var isValid = true;
		_tempArray.forEach((el, index) => {
			if (el['editMode']) {
				if (el['termKey'] === null || !el['termKey'].trim()) {
					isValid = false;
					failureToast('Please enter the value of Term Key');
					return;
				} else if (el['termKey'].trim().length < 4) {
					isValid = false;
					failureToast('Please enter Term Key of four digits');
					return;
				} else if (el['termDesc'] === null || !el['termDesc'].trim()) {
					isValid = false;
					failureToast('Please enter the value of Description');
					return;
				} else if (
					el['termDispValue'] === null ||
					!el['termDispValue'].trim()
				) {
					isValid = false;
					failureToast('Please enter the value of Display Value');
					return;
				}
				if (!isValid) {
					return;
				}
				if (_tempArray[index]['__status'] == '__new') {
					putpost(
						masterServiceBaseUrl + '/term/createTerm',
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							props.fetchClasses(masterServiceBaseUrl + '/term/getAllTerm');
							setTermKey('');
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'post'
					);
				} else {
					putpost(
						masterServiceBaseUrl + '/term/updateTerm/' + _tempArray[index].id,
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							delete _tempArray[index]['editMode'];
							props.setclassArray(_tempArray);
							settableDataKey(new Date().getMilliseconds());
							setTermKey('');
							// props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'put'
					);
				}
			} else {
			}
		});
	};
	const newRow = () => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				addNewClass();
				setisSaveVisible(true);
				// settableDataKey(new Date().getMilliseconds())
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			props.setclassArray(_tempArray);
			setisSaveVisible(false);
			setTermKey('');
			cb();
		}
	};
	const editRow = (index) => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				var _tempArray = props.classArray;
				_tempArray[index]['editMode'] = true;
				setTermKey(_tempArray[index]['termKey']);
				props.setclassArray(_tempArray);
				setisSaveVisible(true);
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			cb();
		}
	};
	const deleteRow = (index) => {
		if (props.classArray[index]['__status'] == '__new') {
			let n = props.classArray;
			n.splice(index, 1);
			props.setclassArray(n);
			setisSaveVisible(false);
			settableDataKey(new Date().getMilliseconds());
		} else {
			if (window.confirm('Are you sure you want to delete this Term?')) {
				putpost2(
					masterServiceBaseUrl +
						'/term/deleteTerm/' +
						props.classArray[index].id,
					(data) => {
						successToast(data['message']);
						// var _tempArray = props.classArray
						// if (_tempArray[index]['editMode']) {
						//   setisSaveVisible(false)
						// }
						// _tempArray.splice(index, 1)
						// props.setclassArray(_tempArray)
						props.fetchClasses(masterServiceBaseUrl + '/term/getAllTerm');
						settableDataKey(new Date().getMilliseconds());
					},
					(data) => {
						failureToast(data['message']);
					},
					'DELETE'
				);
			}
		}
	};
	const updateKey = (index, value, key) => {
		var _tempArray = props.classArray;
		_tempArray[index][key] = value;
		props.setclassArray(_tempArray);
	};
	return (
		<>
			<Container className="mt-3" fluid>
				<Row>
					<div className="col">
						<Card>
							<CardHeader className="border-0">
								<h3 className="mb-0 floatLeft">Master - Term </h3>
								<IsCoreMaster master={'term'} masterDisplayValue={'Term'} />
								<CustomButton
									disabled={isSaveVisible}
									className={
										'floatRight mx-1' +
										(isSaveVisible ? ' btn-dark cursor-gray' : null)
									}
									content={'New Term'}
									permissionType={'C'}
									icon={true}
									onClick={() => {
										newRow();
									}}
									permissionSet={userPermissions}
								/>
							</CardHeader>
							<div id="questionTable" className="table-responsive">
								<Table className="align-items-center table-flush tableLayout">
									<MasterHeader
										type={'Term'}
										isRequired={true}
										isSaveVisible={isSaveVisible}
										permissionSet={userPermissions}
									/>
									<tbody className="list" key={tableDataKey}>
										{props.classArray
											? props.classArray.map((el, index) =>
													!el['editMode'] ? (
														<TDROW
															type={'term'}
															index={index}
															el={el}
															editRow={editRow}
															deleteRow={deleteRow}
															isSaveVisible={isSaveVisible}
															permissionSet={userPermissions}
														/>
													) : (
														<tr key={index + '-class'}>
															<td className="text-center p-2 mx-1">
																<Input
																	maxLength="4"
																	value={termKey}
																	onChange={(e) => {
																		if (
																			validate('numericValue', e.target.value)
																		) {
																			setTermKey(e.target.value);
																			updateKey(
																				index,
																				e.target.value,
																				'termKey'
																			);
																		}
																	}}
																	placeholder="Example:1"
																	className="tdInput"
																/>
															</td>
															<td className="text-center p-2 mx-1">
																<Input
																	disabled={true}
																	defaultValue={
																		el['__status'] == '__new'
																			? null
																			: moment(el['effectiveDate']).format(
																					'DD-MM-YYYY'
																			  )
																	}
																	placeholder="Example:01-01-2020"
																	className="tdInput"
																/>
															</td>
															<td className="text-center p-2 mx-1">
																<Input
																	maxLength="50"
																	defaultValue={el['termDesc']}
																	onChange={(e) => {
																		updateKey(
																			index,
																			e.target.value,
																			'termDesc'
																		);
																	}}
																	placeholder="Description"
																	className="tdInput"
																/>
															</td>
															<td className="text-center p-2 mx-1">
																<Input
																	maxLength="50"
																	defaultValue={el['termDispValue']}
																	onChange={(e) => {
																		updateKey(
																			index,
																			e.target.value,
																			'termDispValue'
																		);
																	}}
																	placeholder="Display Value"
																	className="tdInput"
																	disabled ={!!el.id}
																/>
															</td>
															<td className="text-center p-2 mx-1">
																<label className="custom-toggle mx-auto ml-2">
																	<input
																		checked={
																			el.status == 'ACTIVE' ? true : false
																		}
																		type="checkbox"
																		id={el.id}
																		onChange={(e) => {
																			settableDataKey(
																				new Date().getMilliseconds()
																			);
																			let n = 'ACTIVE';
																			if (!e.target.checked) {
																				n = 'INACTIVE';
																			}
																			updateKey(index, n, 'status');
																		}}
																	/>
																	<span
																		style={{ width: '72px' }}
																		className="custom-toggle-slider rounded-circle activeToggle"
																		data-label-off="Inactive"
																		data-label-on="Active"
																	/>
																</label>
															</td>
															<td className="text-center">
																{isSaveVisible ? (
																	<>
																		<CustomButton
																			content={'Save'}
																			permissionType={'C,U'}
																			forTable={true}
																			onClick={() => {
																				saveAll();
																			}}
																			permissionSet={userPermissions}
																		/>
																		<CustomButton
																			permissionType={'cancel'}
																			icon={true}
																			forTable={true}
																			onClick={() => {
																				let n = props.classArray;
																				if (n[index]['__status'] == '__new') {
																					n.splice(index, 1);
																				} else {
																					// n[index]['editMode']=false
																					props.fetchClasses(
																						masterServiceBaseUrl +
																							'/term/getAllTerm'
																					);
																				}
																				props.setclassArray(n);
																				setisSaveVisible(false);
																				setTermKey('');
																			}}
																			permissionSet={userPermissions}
																		/>
																	</>
																) : (
																	<CustomButton
																		permissionType={'D'}
																		icon={true}
																		forTable={true}
																		onClick={() => deleteRow(index)}
																		permissionSet={userPermissions}
																	/>
																)}
															</td>
														</tr>
													)
											  )
											: null}
									</tbody>
								</Table>
							</div>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};
export default TermTable;
