import React from "react";
import {
  Card,
  CardHeader,
  Input,
  Container,
  Button,
  Table,
  Row,
} from "reactstrap";
import { useState } from "react";
import { failureToast } from "../../common/utils/methods/toasterFunctions/function";
import { TDROW, MasterHeader } from "../../common/commonComponents/masterRow/masterRow";
import { masterServiceBaseUrl, successToast, putpost, putpost2 } from "services/http";
import moment from "moment";
import CreateButton from "components/FormComponent/Button/New/index";
import EditButton from "components/FormComponent/Button/Edit/index";
import DeleteButton from "components/FormComponent/Button/Delete/index";
import UpdateButton from "components/FormComponent/Button/Update/index";
import { ButtonText } from "variables/Buttons";

var _ = require("lodash");

const OpportunityTypeTable = (props) => {
  const { setisSaveVisible = () => {}, isSaveVisible = false } = props;

  const [tableDataKey, settableDataKey] = useState("tableDataKey");
  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: "__new",
        opportunityTypeKey: null,
        status: "ACTIVE",
        opportunityTypeDesc: null,
        opportunityTypeDispValue: null,
        effectiveDate: null,
      },
      ...props.classArray,
    ]);
  };
  const saveAll = (cb) => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if (el["editMode"]) {
        if (el["opportunityTypeKey"] == null || !el["opportunityTypeKey"].trim()) {
          isValid = false;
          failureToast("Please enter the value of opportunity type Key");
          return;
        } else if (el["opportunityTypeDesc"] == null || !el["opportunityTypeDesc"].trim()) {
          isValid = false;
          failureToast("Please enter the value of Description");
          return;
        } else if (el["opportunityTypeDispValue"] == null || !el["opportunityTypeDispValue"].trim()) {
          isValid = false;
          failureToast("Please enter the value of Display Value");
          return;
        }
        if (!isValid) {
          return;
        }
        if (_tempArray[index]["__status"] == "__new") {
          putpost(
            masterServiceBaseUrl + "/opportunityType/createOpportunityType",
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              props.fetchClasses(masterServiceBaseUrl + "/opportunityType/getAllOpportunityType");
            },
            (data) => {
              failureToast(data["message"]);
            },
            _tempArray[index],
            "post"
          );
        } else {
          putpost(
            masterServiceBaseUrl + "/opportunityType/updateOpportunityType/" + _tempArray[index].id,
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              delete _tempArray[index]["editMode"];
              props.setclassArray(_tempArray);
              settableDataKey(new Date().getMilliseconds());
              // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
            },
            (data) => {
              failureToast(data["message"]);
            },
            _tempArray[index],
            "put"
          );
        }
      } else {
      }
    });
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
        // settableDataKey(new Date().getMilliseconds())
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      cb();
    }
  };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]["editMode"] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };
  const deleteRow = (index) => {
    if (props.classArray[index]["__status"] == "__new") {
      let n = props.classArray;
      n.splice(index, 1);
      props.setclassArray(n);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
    } else {
      if (window.confirm("Are you sure you want to delete this opportunity type ?")) {
        putpost2(
          masterServiceBaseUrl + "/opportunityType/deleteOpportunityType/" + props.classArray[index].id,
          (data) => {
            successToast(data["message"]);
            if (props.classArray.length == 1) {
              // props.previousPage();
              props.fetchClasses(masterServiceBaseUrl + "/opportunityType/getAllOpportunityType");
            } else {
              var _tempArray = props.classArray;
              if (_tempArray[index]["editMode"]) {
                setisSaveVisible(false);
              }
              _tempArray.splice(index, 1);
              props.setclassArray(_tempArray);
            }
            settableDataKey(new Date().getMilliseconds());
            props.fetchClasses(masterServiceBaseUrl + "/opportunityType/getAllOpportunityType");
          },
          (data) => {
            failureToast(data["message"]);
          },
          "PUT"
        );
      }
    }
  };
  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
  };
  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Opportunity Type</h3>
                <CreateButton
                  text={ButtonText.OpportunityType.addNew}
                  data-testid="addNewOpportunityType"
                  disabled={isSaveVisible}
                  className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
                  onClick={() => {
                    newRow();
                  }}
                />
                {/* {isSaveVisible?<Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => {saveAll()}}>Save</Button>:null} */}
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout">
                  <MasterHeader type={"Opportunity Type"} />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                          !el["editMode"] ? (
                            <TDROW type={"opportunityType"} index={index} el={el} editRow={editRow} deleteRow={deleteRow} />
                          ) : (
                            <tr key={index + "-class"}>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="opportunityTypeKey"
                                  maxLength={50}
                                  defaultValue={el["opportunityTypeKey"]}
                                  onChange={(e) => {
                                    updateKey(index, e.target.value, "opportunityTypeKey");
                                  }}
                                  placeholder="Example:1"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input data-testid="effectiveDate" disabled={true} defaultValue={el["__status"] == "__new" ? null : moment(el["effectiveDate"]).format("DD-MM-YYYY")} placeholder="Example:01-01-2020" className="tdInput" />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="opportunityTypeDesc"
                                  maxLength={50}
                                  defaultValue={el["opportunityTypeDesc"]}
                                  onChange={(e) => {
                                    updateKey(index, e.target.value, "opportunityTypeDesc");
                                  }}
                                  placeholder="Description"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="opportunityTypeDispValue"
                                  maxLength={50}
                                  defaultValue={el["opportunityTypeDispValue"]}
                                  onChange={(e) => {
                                    updateKey(index, e.target.value, "opportunityTypeDispValue");
                                  }}
                                  placeholder="Display Value"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <label className="custom-toggle mx-auto ml-2">
                                  <input
                                    data-testid="activeInactiveCourseInstallment"
                                    checked={el.status == "ACTIVE" ? true : false}
                                    type="checkbox"
                                    id={el.id}
                                    onChange={(e) => {
                                      settableDataKey(new Date().getMilliseconds());
                                      let n = "ACTIVE";
                                      if (!e.target.checked) {
                                        n = "INACTIVE";
                                      }
                                      updateKey(index, n, "status");
                                    }}
                                  />
                                  <span data-testid="activeInactiveLabelCourseInstallment" style={{ width: "72px" }} className="custom-toggle-slider rounded-circle activeToggle" data-label-off="Inactive" data-label-on="Active" />
                                </label>
                              </td>
                              <td className="text-center">
                                {/* <Button color="info" size="sm" type="button" onClick={() => {
                          deleteRow(index)
                        }}>  <i className="fas fa-trash" /></Button> */}
                                {isSaveVisible ? (
                                  <>
                                    <UpdateButton
                                      text={ButtonText.OpportunityType.save}
                                      data-testid="saveCourseInstallment"
                                      onClick={() => {
                                        saveAll();
                                      }}
                                    />
                                    <Button
                                      data-testid="closeCourseInstallment"
                                      color="danger"
                                      size="sm"
                                      type="button"
                                      onClick={() => {
                                        let n = props.classArray;
                                        if (n[index]["__status"] == "__new") {
                                          n.splice(index, 1);
                                        } else {
                                          // n[index]['editMode']=false
                                          props.fetchClasses(masterServiceBaseUrl + "/opportunityType/getAllOpportunityType");
                                        }
                                        props.setclassArray(n);
                                        setisSaveVisible(false);
                                      }}
                                    >
                                      <i className="fas fa-times" />
                                    </Button>
                                  </>
                                ) : (
                                  <Button
                                    data-testid="deleteCourseInstallment"
                                    color="info"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                      deleteRow(index);
                                    }}
                                  >
                                    {" "}
                                    <i className="fas fa-trash" />
                                  </Button>
                                )}
                              </td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default OpportunityTypeTable;
