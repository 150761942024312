import React from "react";
import { Dialog } from "@mui/material";
import Divider from "@mui/material/Divider";
import { getFormattedDate } from "../../../common/utils/methods/commonMethods/utilityMethod";
import AButton from "../../../common/form-fields-mui/AButton";
import { headerColumnBorder } from "./StudentFeeSummary";
import { numberWithCommas } from "./ConsolidatedFeeDetails";
import { parse2Digit } from "../../../unAssigned/otherCharges/otherChargeDetails/OtherChargeList";

const tableHeaderStyle = {
  display: "grid",
  gridTemplateColumns: "300px 1fr",
  gridGap: "12px",
  fontSize:"14px"

};

const subColumn = {
  display: "grid",
  gridTemplateColumns: "repeat(9, 110px)",
  gridGap: "10px",
};

const Header = () => {
  return (
    <div className="text-bold label-color mt-2 mb-2" style={tableHeaderStyle}>
      <div style={headerColumnBorder}>Description</div>
      <div style={{ ...subColumn, ...headerColumnBorder }}>
        <div>Due date</div>
        <div>Gross amount</div>
        <div>Concession applied</div>
        <div>Payable amount</div>
        <div>Taxes</div>
        <div>Net payable</div>
        <div>Payment date</div>
        <div>Paid amount</div>
        <div>Balance amount</div>
      </div>
    </div>
  );
};

const Row = ({ row }) => {
  return (
    <div style={tableHeaderStyle} className="global-text-size mb-3">
      <div>{row.description}</div>
      <div style={{ ...subColumn }}>
        <div> {getFormattedDate(row.dueDate)} </div>
        <div>₹ {numberWithCommas(parse2Digit(row.grossAmount || 0))}</div>
        <div>₹ {numberWithCommas(parse2Digit(row.concessionApplied || 0))}</div>
        <div>₹ {numberWithCommas(parse2Digit(row.payableAmount || 0))}</div>
        <div>₹ {numberWithCommas(parse2Digit(row.taxes || 0))}</div>
        <div>₹ {numberWithCommas(parse2Digit(row.netPayable || 0))}</div>
        <div> {getFormattedDate(row.paymentDate)} </div>
        <div>₹ {numberWithCommas(parse2Digit(row.paidAmount || 0))}</div>
        <div>₹ {numberWithCommas(parse2Digit(row.balanceAmount || 0))}</div>
      </div>
    </div>
  );
};

const FeeBreakupSummary = ({ feeSummaryDialog, setFeeSummaryDialog }) => {
  const handleClose = () => {
    setFeeSummaryDialog({ isOpen: false, data: null });
  };

  return (
    <Dialog
      maxWidth="xl"
      fullWidth={true}
      onClose={handleClose}
      open={feeSummaryDialog.isOpen}
    >
      <div className="p-4">
        <div className="d-flex justify-content-between">
          <h2 className="heading-1 text-bold">Fee Breakup Summary</h2>
          <AButton variant="danger_filled" onClick={handleClose} size="xs">
            Close
          </AButton>
        </div>
        <Divider />
        <Header />
        {feeSummaryDialog?.data?.feeBreakupDTOList.map((row) => (
          <Row key={row.description} row={row} />
        ))}
      </div>
    </Dialog>
  );
};

export default FeeBreakupSummary;
