import React, { useEffect, useState, useRef } from "react";
import { Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Button, Row } from "reactstrap";
import Select2 from "react-select2-wrapper";
import { RedAstric } from "../../../examService/examIdGeneration/examIdSearchForm";
import Select, { components } from "react-select";
import moment from "moment";
import { AsyncSelectSearch, AutoComplete, ReactSelectAll, SearchFilterView } from "../../commonComponents";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import CustomDatePicker from "../../commonComponents/CustomDatePicker";
import CustomDatePickerV3 from "../../commonComponents/CustomDatePicker-V3";
import Tooltip from "../../../../../../components/Tooltip";
import { QuestionSVG } from "../../../courseInformation/courseService/courseCatalogForm/component/courseDetails";
import { fontWeight } from "@material-ui/system";
import { DropDownButton } from "../../../masters/examSyllabus/examSyllabusForm";
import { FormControlLabel, Switch } from "@mui/material";
import Popover from '@mui/material/Popover';
import DatePicker from 'react-datepicker';
import CustomTimePicker from "../../commonComponents/CustomTimePicker";
import CustomDatePickerV4 from "../../commonComponents/CustomDatePicker-V4";
import { RiArrowDropDownLine } from "react-icons/ri";
import { red } from "@mui/material/colors";



const timepickerStyle = {
    '& .MuiInputBase-input': {
        height: '20px',
        padding: '10px',
    }
}

const toggleV2Style = {
    '& .MuiTypography-root': {
        fontSize: '14px !important'
    },
    '& .MuiSwitch-colorPrimary.Mui-checked': {
        color: '#00aeef !important'
    },
    '& .Mui-checked+.MuiSwitch-track': {
        background: '#CCEFFD !important'
    }
}


// --------regEx Example====> {/[^0-9a-zA-Z ]/g}

export const customStylesSelect = {
    menuPortal: base => { return { ...base, zIndex: 9999 } },
    indicatorSeparator: (defaultStyles) => {
        return {
            ...defaultStyles,
            display: 'none'
        }
    },
    valueContainer: (defaultStyles) => {
        return {
            ...defaultStyles,
            fontSize: '14px',
            height: '40px',
            border: 'none !important'
        }
    },
    dropdownIndicator: (defaultStyles) => {
        return {
            ...defaultStyles,
            padding:'0px 0px 5px 0px'
            // color: '#0F0F0F !important',
        }

    },
    control: (defaultStyles, { isDisabled }) => {
        return {
            ...defaultStyles,
            borderColor: '#dee2e6',
            cursor: isDisabled ? 'not-allowed' : 'default',
            background: isDisabled ? 'rgba(0, 176, 245, 0.05)' : '#fff',
            height: '40px',
            "&:hover": {
                borderColor: "#00AEEF",
                color: '#00AEEF'
            }
        }
    },
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#8898aa',
        }
    },
    svg: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: 'red'
        }
    },
    singleValue: (defaultStyles, { isDisabled }) => {
        return {
            ...defaultStyles,
            color: '#00b0f5',
            fontWeight: 500
        }
    },
    input: (defaultStyles) => {
        return {
            ...defaultStyles,
            border: 'none !important',
        }
    },
    // menuList: (styles) => {
    //     return {
    //         ...styles,
    //         "::-webkit-scrollbar": {
    //             width: "4px",
    //             height: "0px",
    //           },
    //           "::-webkit-scrollbar-track": {
    //             background: "#f1f1f1"
    //           },
    //           "::-webkit-scrollbar-thumb": {
    //             background: "#888"
    //           },
    //           "::-webkit-scrollbar-thumb:hover": {
    //             background: "#555"
    //           }
    //     }
    // },
}
export const stylesForFilledSelect = {
    ...customStylesSelect,
    singleValue: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#00AEEF',
            // top:'61%'
            // fontWeight : '550'
        }
    },
    control: (defaultStyles,  { isDisabled }) => {
        return {
            ...defaultStyles,
            border: '1px solid #00AEEF',
            height: '40px',
            background: isDisabled ? 'rgba(0, 176, 245, 0.05)' : '#fff',
            "&:hover": {
                borderColor: "#00AEEF",
                color: '#00AEEF'
            }
        }
    },
    indicatorContainer: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#00AEEF'
        }
    },
    Svg: (ds) => {
        return {
            ...ds,
            color: '#00AEEF'
        }
    },
    valueContainer: (defaultStyles) => {
        return {
            ...defaultStyles,
            fontSize: '14px',
            height: '40px'
        }
    },
    input: (defaultStyles) => {
        return {
            ...defaultStyles,
            border: 'none !important'
        }
    },
    // menuList: (styles) => {
    //     return {
    //         ...styles,
    //         "::-webkit-scrollbar": {
    //             width: "4px",
    //             height: "0px",
    //           },
    //           "::-webkit-scrollbar-track": {
    //             background: "#f1f1f1"
    //           },
    //           "::-webkit-scrollbar-thumb": {
    //             background: "#888"
    //           },
    //           "::-webkit-scrollbar-thumb:hover": {
    //             background: "#555"
    //           }
    //     }
    // },
}

const styleForMultiSelect = {
    dropdownButton: () => {
        return {
            border: '1px solid #ccc',
            width: '100%',
            maxWidth: '100%',
            background: '#FFF',
            lineHeight: 1.36,
            alignItems: 'baseLine',
            color: '#515151',
            margin: 0,
            flexWrap: 'nowrap',
            padding: '8px 8px 5px 8px',
            borderRadius: '2px',
            boxShadow: '0 1px 1px 1px reg(0 0 0 /8%)',
            fontStyle: 'normal',
            fontSize: '14px',
            fontWeight: 400,
            textDecoration: 'none',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            verticalAlign: 'middle',
            transition: 'background 0.1s ease-out 0s,box-shadow 0.15s cubic-bezier(0.47,0.03,0.49,1.38) 0s',
            display: 'inline-flex',

        }
    }
}


const getDropdownButtonLabel = ( placeholderButtonLabel, value, showOnlyPlaceholder ) => {

    if(showOnlyPlaceholder){
        return placeholderButtonLabel;
    }
    else if (value && Array.isArray(value) &&  value.some((o) => o.value === "*")) {
        return `${placeholderButtonLabel}: All`;
    }
     else if (value && value.length) {
        return `${placeholderButtonLabel}: ${value.length} selected`;
    } else {
        return placeholderButtonLabel;
    }
}
export const customTheme = (theme) => ({
    ...theme,
    borderRadius: '4px',
    colors: {
        ...theme.colors,
        primary: '#00AEEF',
    },
})

export const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <RiArrowDropDownLine size={25} color={props.hasValue ? '#00AEEF' : '0f0f0f'} />
        </components.DropdownIndicator>
    );
};

const CommonInput = (props) => {
    const {
        lengthNotifier = false,
        action = 'edit',
        key = "",
        label = "",
        placeHolder,
        onChange = () => { },
        promiseHandler = () => { },
        data = [],
        isDisabled = false,
        type = "",
        maxLength = 100,
        defaultValue = "",
        allowedSomeSpecialCharacters = false,
        restrictSpecialCharacter = false,
        allowDecimalvalue = false,
        mandatory = false,
        forTable = false,
        regEx,
        loading = false,
        minDate = "1950-01-01",
        maxDate = "2099-01-01",
        loadOptions = (query, callback) => { },
        cacheOptions = true,
        defaultOptions,
        dataLabelOff = '',
        dataLabelOn = '',
        rangeFrom = 1950,
        rangeTo = 2099,
        // tabIndex='',
        menuPlacement = 'auto',
        tooltipContent = '',
        tooltip = false,
        onKeyPress = () => { },
        onClear = () => { },
        clearable = false,
        readOnly = false,
        readOnlyComp = null,
        onKeyDown = () => { },
        onFocus = () => { },
        secondaryLabel = '',
        rightSecondaryLabel = '',
        error = false,
        forFilter= false,
        withportal= false,
        multiselectWithportal = true,
        hideSearch=false,
        showOnlyPlaceholder=false,
        showToggleLabels=true,
        newToggle=false
    } = props;
    // console.log('Default Value------->',defaultValue);

    const [focus, setFocus] = useState(false);

    const readOnlyDispVal = () => {
         if(type?.toLowerCase() ==='select'){
           if( typeof defaultValue  === "string") return defaultValue
           return defaultValue?.label ? defaultValue?.label : '-';
         }
        return type === 'toggle' ?
            toggleButton :
            type === 'datePicker' ?
                (defaultValue?.label ?? moment(defaultValue)?.format("DD-MM-YYYY") === "Invalid date" ? '-' : moment(defaultValue)?.format("DD-MM-YYYY")) :
                (defaultValue?.label ?? defaultValue ?? '-');
    }

    const onChangeMultiSelect = (value, event) => {
        if (event.action === "select-option" && event.option.value === "*") {
            onChange(data, event);
        } else {
            onChange(value, event)
        }
    }

    const onInputChange = (value, event = '') => {
        if (type.toLowerCase() === "number") {
            if (value.length <= maxLength) {
                if (allowDecimalvalue) {
                    onChange(value?.replace(/[^0-9.]/g, "")
                        .replace(/(\..*)\./g, "$1")
                        .replace(/(\.\d{1,3}).*/g, "$1"))
                }
                else if (restrictSpecialCharacter) {
                    onChange(value.replace(/[^0-9]/g, ""));
                } else {
                    onChange(value.replace(/[^0-9]/g, ""));
                }
            }
        } else if (type === "text" || type === "textarea") {
            if (restrictSpecialCharacter && !allowedSomeSpecialCharacters) {
                onChange(value.replace(/[^a-zA-Z 0-9]/g, ""));
            } else if (restrictSpecialCharacter && allowedSomeSpecialCharacters && regEx) {
                let tempValue = value.replace(regEx, "");
                onChange(tempValue);
            }
            else if (regEx) {
                if (regEx.test(value)) onChange(value)
            }
            else {
                onChange(value);
            }
        } else if (type === 'multiSelectWithCheckboxes') {
            if (event.action === "select-option" && event.option.value === "*") {
                onChangeMultiSelect(data, event);
            } else if (event.action === "deselect-option" && event.option.value === "*") {
                onChangeMultiSelect([], event);
            } else if (event.action === "deselect-option") {
                let updatedValue = value.filter(o => o.value !== "*");
                onChangeMultiSelect(updatedValue, event);
            } else if (event.action === 'select-option' && event.option.value !== '*') {
                onChangeMultiSelect(value, event);
            }
        } else if (type === 'datePicker' || type === 'datePickerV2' || type === 'timePicker') {
            onChange(value);
        } else if (
            type === "reactSelect" ||
            type === "select" ||
            type === "smartSearchSelect" ||
            type === "dateField" ||
            type === 'toggle' ||
            type === 'toggleV2' ||
            type === 'toggleV3' ||
            type === "timeField"
        ) {
            onChange(value);
        }
    };
    const checkArray = () => {
        if (Array.isArray(defaultValue)) {
            return !!defaultValue.length;
        }
        return true;
    }

    // const addActiveClass = () => {
    //     const parentContainer = document.getElementsByClassName('select2-selection select2-selection--single')
    //     for(let i = 0; i < parentContainer.length;i++){
    //         if(parentContainer[i].getElementsByClassName('select2-selection__placeholder').length === 0){
    //             parentContainer[i].style.border='1px solid red'
    //         }
    //     };
    // }

    let select = (
        <>
        <Select
            required={mandatory}
            style={{ height: '40px !important' }}
            // className={'custom-select'}
            theme={customTheme}
            styles={(defaultValue !== '' && defaultValue && defaultValue?.label !== '' && checkArray() && !isDisabled) ? stylesForFilledSelect : customStylesSelect}
            key={key + moment()}
            placeholder={placeHolder}
            value={defaultValue && defaultValue?.label !== '' ? defaultValue : ''}
            isDisabled={isDisabled || readOnly}
            options={data}
            onChange={(e) => {

                onInputChange(e);

            }}
            isLoading={loading}
            menuPlacement={menuPlacement}
            components={{ DropdownIndicator }}
            menuPortalTarget={document.body}
            isClearable={clearable}
        />
        {
            (mandatory && error)
                ?
                <span className={"text-warning field-info float-right"}>
                   Required
                </span>
                :
                null
        }
        </>
    );

    const datePicker = (
        <CustomDatePicker
            disabled={isDisabled || readOnly}
            value={defaultValue}
            placeholder={placeHolder ?? "Example:01-01-2020"}
            rangeFrom={rangeFrom}
            rangeTo={rangeTo}
            handler={(index, date, keyName) => { onInputChange(date) }}
            {...props}
        />
    )

    const datePickerV2 = (
        <CustomDatePickerV3
            disabled={isDisabled || readOnly}
            value={defaultValue}
            placeholder={placeHolder ?? "Example:01-01-2020"}
            rangeFrom={rangeFrom}
            rangeTo={rangeTo}
            handler={(index, date, keyName) => { onInputChange(date) }}
            {...props}
            forFilter={forFilter}
            minDate={minDate}
            maxDate={maxDate}
            withportal={withportal}
        />
    )

    const datePickerV4 = (
        <CustomDatePickerV4
            value={defaultValue}
            disabled={isDisabled}
            minDate={minDate}
            onChange={(val) => onChange(val)}
        />
    )



    const getMultiSelectDropDown = (value) => {
        if (value.length === 2) {

        }
    }

    const [multiSelectOpen, setMultiSelectOpen]=useState(false);

    let multiSelectWithCheckboxes = (
        isDisabled ?
            <DropDownButton direction={'bottom'} data={defaultValue} />
            : 
            <div onClick={()=>setMultiSelectOpen(true)}>
            <ReactMultiSelectCheckboxes
                styles={
                    {   
                        control : (styles)=>{
                            return {
                                ...styles,
                                margin:'4px !important'
                            }
                        },

                        dropdownButton: (styles,state) => {
                            return {
                                ...styles,
                                border: state.isOpen ? '1px solid #00B0F5' : `1px solid ${defaultValue.length > 0 ? 'rgb(0, 174, 239)' : '#dee2e6'}`,
                                borderRadius: '4px',
                                boxShadow: '0',
                                color: defaultValue.length > 0 ? 'rgb(0, 174, 239)' : '#8898aa',
                                marginLeft: '0px !important'
                            }
                        },
                        dropdownButtonIconContainer: (styles) => {
                            return {
                                ...styles,
                                backgroundColor: 'yellow'
                            }
                        },
                        menu: (styles) => {
                            return {
                                ...styles,
                                backgroundColor: '#fff',
                                outline: '3px solid #fff',
                                marginTop: '11px',
                                boxShadow: '0px 4px 32px rgba(61, 70, 112, 0.08)',
                                overflowX: 'hidden',
                                position: 'absolute'
                            }
                        },
                        container: (styles) => {
                            return {
                                ...styles,
                                border: '1px solid #d0d0d0',
                                borderRadius: '4px',
                                overflowX: 'hidden !important',
                                // state.isFocus: {
                                //     border: '1px solid #ff8b67',
                                //     boxShadow: '0px 0px 6px #ff8b67',
                                //   },
                            }
                        },
                        menuList: (styles) => {
                            return {
                                ...styles,
                                overflowX : 'hidden',
                                "::-webkit-scrollbar": {
                                    width: "4px",
                                    height: "0px",
                                  },
                                  "::-webkit-scrollbar-track": {
                                    background: "#f1f1f1"
                                  },
                                  "::-webkit-scrollbar-thumb": {
                                    background: "#888"
                                  },
                                  "::-webkit-scrollbar-thumb:hover": {
                                    background: "#555"
                                  }
                            }
                        },
                        option: (style,state) => {
                            return {
                                ...style,
                                whiteSpace: 'break-spaces',
                                fontSize: 14,
                                "input": {
                                    width: '16px',
                                    height: '16px',
                                    background: state.isDisabled ? '#000' : "",
                                }
                            }
                        },
                        menuPortal: (base) => ({
                            ...base,
                            zIndex: 1400
                        }),
                    }
                }
                maxWidth={'100%'}
                placeholderButtonLabel={placeHolder}
                options={data || []}
                getDropdownButtonLabel={({placeholderButtonLabel, value}) => getDropdownButtonLabel(placeholderButtonLabel, value,showOnlyPlaceholder)}
                rightAligned={false}
                width={'100%'}
                className="styles-for-multiSelect"
                value={defaultValue}
                disabled={isDisabled || readOnly}
                isOptionDisabled={(option) => option.isDisabled} 
                readOnly={true}
                disable={true}
                isLoading={loading}
                onChange={(value, event) => onInputChange(value, event)}
                closeMenuOnScroll={false}
                menuIsOpen={multiSelectOpen}
                maxMenuHeight={200}
                minMenuHeight={200}
                menuPosition={'absolute'}
                onFocus={onFocus}
                onBlur={()=>{console.log('onBlur'); setMultiSelectOpen(false)}}
                hideSearch={hideSearch}
                menuPlacement={menuPlacement}
                menuPortalTarget={!hideSearch && multiselectWithportal ? document.body : null}
                components={{ DropdownButtonIcon: () => <span style={{ marginRight: '-4px' }} className={defaultValue.length === 0 ? "text-body1" : ""}><RiArrowDropDownLine size={25} /></span> }}
            />
            </div>
    );

    let reactSelect = (
        <Select2
            key={key + moment()}
            options={{ placeholder: placeHolder }}
            data={data}
            // styles={stylesForFilledSelect}
            className="test-class"
            style={{ height: '40px !important' }}
            disabled={isDisabled || readOnly}
            value={defaultValue}
            onChange={(e) => {
                if (e.target.value) {
                    onInputChange(e.target.value);
                }
                // addActiveClass()
            }}
        />
    );
    const smartSearchSelect = (
        <AsyncSelectSearch
            {...props}
            // cacheOptions={cacheOptions}
            value={defaultValue}
            placeholder={placeHolder}
            onChangeHandler={(data) => {
                console.log("Data in common input--->", data);
                onInputChange(data);
            }}
            // defaultOptions
            // loadOptions={(query,callback)=>loadOptions(query,callback)}
            promiseHandler={promiseHandler}
        />
    );

    let stylesForInput = {
        height: '38px !important',
        border: '1px solid red !important',
        color: '#00AEEF'
    };
    // console.log('Action--------------------->',action === 'new')
    let inputField = (
        <div className="d-flex align-items-center">
            <Input
                key={key}
                disabled={isDisabled || readOnly}
                placeholder={placeHolder}
                className="tdSelect"
                style={defaultValue && defaultValue !== '' && !isDisabled ? { color: '#00AEEF', border: '1px solid #00AEEF', height: '40px' } : { height: '40px' }}
                value={defaultValue}
                maxLength={maxLength}
                onChange={(e) => onInputChange(e.target.value)}
                type={type ?? 'text'}
                onFocus={() => {
                    if (lengthNotifier) {
                        setFocus(true);
                    } else {
                        onFocus();
                    }
                }}
                onBlur={() => {
                    if (lengthNotifier) {
                        setFocus(false);
                    }
                }}
                onKeyPress={onKeyPress}
                onKeyDown={onKeyDown}
                spellCheck={false}
            />
            {props?.adornmentText && <span style={{ position: 'absolute', right: '0px', paddingRight: '28px', color: '#000000' }}>{props?.adornmentText ?? ''}</span>}
        </div>
    );


    let toggleButton = (
        <div className="d-flex flex-row align-items-center">
            {secondaryLabel && <span style={{ fontSize: '14px', fontWeight: 400, marginRight: '5px' }}>{secondaryLabel}</span>}
            <label className="custom-toggle ml-2">
                <input
                    {...props}
                    checked={defaultValue === dataLabelOn}
                    // checked={defaultValue === "ACTIVE"}
                    disabled={isDisabled || readOnly}
                    type="checkbox"
                    onChange={(e) => {
                        console.log(e.target.checked);
                        let n = dataLabelOn;
                        if (!e.target.checked) {
                            n = dataLabelOff;
                        }
                        onInputChange(n);
                    }}
                />
                <span style={{ width: "72px" }} className="custom-toggle-slider rounded-circle activeToggle"
                     data-label-off={dataLabelOff} data-label-on={dataLabelOn} />
            </label>
        </div>

    )

    let toggleV3 = (
        <div className={`d-flex flex-row align-items-end ${newToggle ? 'newToggle' : ''}`}>
            {secondaryLabel && <span style={{ fontSize: '14px', fontWeight: 400, marginRight: '5px' }}>{secondaryLabel}</span>}
            <label className="custom-toggle v3 ml-2">
                <input
                    {...props}
                    checked={defaultValue === dataLabelOn}
                    // checked={defaultValue === "ACTIVE"}
                    disabled={isDisabled || readOnly}
                    type="checkbox"
                    onChange={(e) => {
                        let n = dataLabelOn;
                        if (!e.target.checked) {
                            n = dataLabelOff;
                        }
                        onInputChange(n);
                    }}
                />
                <span style={{ width: "50px" }} className="custom-toggle-slider rounded-circle activeToggle"/>
            </label>
            {showToggleLabels ? <span style={{ fontSize: '14px', fontWeight: 400, marginRight: '5px' }}>{defaultValue === dataLabelOn ? dataLabelOn : dataLabelOff}</span> : null }
            {rightSecondaryLabel && <span style={{ fontSize: '14px', fontWeight: 400, marginRight: '5px' }}>{rightSecondaryLabel}</span>}
        </div>

    )

    let toggleV2 = (
        <div className="d-flex flex-row align-items-center justify-content-center">
            <FormControlLabel control={<Switch checked={defaultValue === 'Active'}
                onChange={(e) => {
                    let n = 'Active';
                    if (!e.target.checked) {
                        n = 'Inactive';
                    }
                    onInputChange(n);
                }} disabled={isDisabled} />} label={defaultValue} sx={toggleV2Style} />
        </div>
    )

    let timePicker = (
        <CustomTimePicker
            defaultValue={defaultValue}
            onChange={(date) => { onInputChange(date) }}
            disabled={isDisabled}
            forFilter={forFilter}
            placeHolder={placeHolder}
        />

    )

    let dateField = (
        <>
        <Input
            key={key}
            type="date"
            min={minDate}
            max={maxDate}
            disabled={isDisabled || readOnly}
            placeholder={placeHolder}
            className="tdSelect"
            value={defaultValue}
            maxLength={maxLength}
            onChange={(e) => onInputChange(e.target.value)}
        />
        {
            (mandatory && error)
                ?
                <span className={"text-warning field-info float-right"}>
                Required
                </span>
                :
                null
        }
        </>
    );
    let timeField = (
        <>
        <Input
            key={key}
            type="time"
            min={minDate}
            max={maxDate}
            disabled={isDisabled || readOnly}
            placeholder={placeHolder}
            className="tdSelect"
            value={defaultValue}
            maxLength={maxLength}
            onChange={(e) => onInputChange(e.target.value)}
        />
        {
            (mandatory && error)
                ?
                <span className={"text-warning field-info float-right"}>
                Required
                </span>
                :
                null
        }
        </>
    );

    const Para = () => {
        const tempArr = defaultValue.map(item => item.label);
        const tempString = tempArr.slice(0, 5).join(', ');

        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);
        const id = open ? crypto.randomUUID() : undefined;

        return (
            <>
                <div
                    key={key}
                    style={{ fontWeight: 'bolder' }}
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={(e) => e.stopPropagation()}
                >
                    {tempString}
                    {tempArr.length > 5 &&
                        <>
                            ...
                            <Button color='link' className='custom_link_button' onClick={handleClick}>View all</Button>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <SearchFilterView options={defaultValue} label={label} />
                            </Popover>
                        </>
                    }
                </div>
            </>
        )
    }

    // addActiveClass();

    let ReactSelectAllSelf = (
        <ReactSelectAll
            options={data}
            onChange={onChange}
            placeholder={placeHolder}
            styles={(defaultValue !== '' && defaultValue && defaultValue?.label !== '' && checkArray() && !isDisabled) ? stylesForFilledSelect : customStylesSelect}
        />
    );

    let AutoCompleteSelf = (
        <AutoComplete
            options={data}
            onChange={onChange}
            placeholder={placeHolder}
            styles={(defaultValue !== '' && defaultValue && defaultValue?.label !== '' && checkArray() && !isDisabled) ? stylesForFilledSelect : customStylesSelect}
        />
    );

    return forTable ? (
        type === "reactSelect" ? reactSelect
            : type === "select" ? select
                : type === "smartSearchSelect" ? smartSearchSelect
                    : type === "dateField" ? dateField
                        : type === 'multiSelectWithCheckboxes' ? multiSelectWithCheckboxes
                            : type === 'toggle' ? toggleButton
                                : type === 'para' ? <Para />
                                    : type === 'datePicker' ? datePicker
                                        : type === 'reactSelectAll' ? ReactSelectAllSelf
                                            : type === 'autoComplete' ? AutoCompleteSelf
                                                : type === 'datePickerV2' ? datePickerV2
                                                    : type === 'toggleV2' ? toggleV2
                                                        : type === 'datePickerV4' ? datePickerV4
                                                            : type === 'timePicker' ? timePicker
                                                                : type === 'timeField' ? timeField
                                                                : inputField
    ) : (
        <FormGroup>
            {label && <Label className="form-control-label">
                {label} {mandatory && !readOnly ? <RedAstric /> : null}
                {
                    tooltip ?
                        <Tooltip
                            content={tooltipContent}
                            placement={'top'}
                        >
                            <span style={{ marginLeft: '4px', color: '#8898aa' }} id={'courseDesc'} className={'courseDesc'}>
                                {/*<QuestionSVG/>*/}
                                <i className="fas fa-info-circle" />
                            </span>
                        </Tooltip>
                        : null
                }
            </Label>}
            {readOnly ? readOnlyComp ?? <h3>{readOnlyDispVal()}</h3> :
                (
                    type === "reactSelect" ? reactSelect
                        : (type === "select" ? select
                            : type === "smartSearchSelect" ? smartSearchSelect
                                : type === "dateField" ? dateField
                                    : type === 'multiSelectWithCheckboxes' ? multiSelectWithCheckboxes
                                        : type === 'toggle' ? toggleButton
                                            : type === 'para' ? <Para />
                                                : type === 'datePicker' ? datePicker
                                                    : type === 'reactSelectAll' ? ReactSelectAllSelf
                                                        : type === 'autoComplete' ? AutoCompleteSelf
                                                            : type === 'datePickerV2' ? datePickerV2
                                                                : type === 'datePickerV4' ? datePickerV4
                                                                    : type === 'timeField' ? timeField
                                                                    :type === 'toggleV3' ? toggleV3
                                                                        : inputField)
                )
            }
            {
                (focus && maxLength)
                    ?
                    <span style={{ marginTop: '3px' }} className={"text-warning field-info float-right"}>
                        Add upto {maxLength - defaultValue.length} characters
                    </span>
                    :
                    null
            }
        </FormGroup>
    );
};
export default CommonInput;
