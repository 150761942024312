import React from 'react';
import { linkButtonStyles } from '../../constant/student-details';
import LinkWrapper from 'views/pages/manage/common/Link/index.jsx';

const stockUrlMap = {
  RCC: '/admin/edp/stockManagement',
  DLP: '/admin/edp/dlpStockManagement',
};
const StockDetailsQuickActions = ({ activeApplicationDetails = {} }) => {
  const { academicCareerValue: academicCareer } = activeApplicationDetails;

  return (
    <div className='d-flex flex-column align-items-start'>
      <LinkWrapper
        style={linkButtonStyles}
        url={`${stockUrlMap[academicCareer]}` || '/'}
      >
        <span>Issue stock</span>
      </LinkWrapper>
      <LinkWrapper
        style={linkButtonStyles}
        url={`${stockUrlMap[academicCareer]}` || '/'}
      >
        <span>Return stock</span>
      </LinkWrapper>
    </div>
  );
};

export default StockDetailsQuickActions;
