import React from 'react'
import { ReactComponent as EyeIcon } from "assets/img/svg/eyeIcon.svg";
import { useHistory } from "react-router";
import { Tooltip } from "@mui/material";

const BatchName = ({batchDetails}) => {
    
 const history = useHistory();

  return (
    <div className="d-flex align-items-center mb-4">
    <div className="regular-large-bold text-bold color-dark ">
      Batch: {batchDetails?.batchName}
    </div>
    <Tooltip title="View batch">
      <EyeIcon
        width="16px"
        height="16px"
        className="ml-1 cursor"
        onClick={() =>
          window.open(
            history.createHref({
              pathname: `admin/edp/batch/view/${batchDetails?.id}`,
            })
          )
        }
      />
    </Tooltip>
  </div>
  )
}

export default BatchName