import React from "react";
import { Card, CardHeader, Input, Container, Button, Table, Row } from "reactstrap";
import { useState } from "react"
import Select2 from "react-select2-wrapper";
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function'
import { TDROW, MasterHeader } from "../../common/commonComponents/masterRow/masterRow";
import { masterServiceBaseUrl, putpost, putpost2, deleteAPI } from 'services/http'
import moment from 'moment';
import CreateButton from "components/FormComponent/Button/New/index";
import UpdateButton from "components/FormComponent/Button/Update/index";
import { ButtonText } from "variables/Buttons";
import CustomButton from "components/CustomButton";
import CommonInput from "../../common/formFeilds/input/commonInput";

var _ = require('lodash');

const StateTable = (props) => {

  const { setstate, state, tableData, pagination, setclassArray, setpagination, countries, userPermissions, isSaveVisible, setisSaveVisible = () => { } } = props;
  // const [isSaveVisible, setisSaveVisible] = useState(false)
  const [tableDataKey, settableDataKey] = useState('tableDataKey')
  const addNewClass = () => {
    props.setclassArray([{ "editMode": true, "__status": "__new", "stateKey": null, 'status': 'ACTIVE', "stateDesc": null, "stateDispValue": null, "effectiveDate": null, "countryId": null, "stateGstCode": null }, ...props.classArray])
  }
  const saveAll = (cb) => {
    var _tempArray = props.classArray
    var isValid = true
    _tempArray.forEach((el, index) => {
      if (el['editMode']) {
        if (el['stateKey'] === null || !el['stateKey'].trim()) {
          isValid = false
          failureToast('Please enter the value of State Key')
          return
        } else if (el['stateDesc'] === null || !el['stateDesc'].trim()) {
          isValid = false
          failureToast('Please enter the value of Description')
          return
        }
        else if (el['stateDispValue'] === null || !el['stateDispValue'].trim()) {
          isValid = false
          failureToast('Please enter the value of Display Value')
          return
        }
        else if (el['stateGstCode'] === null || !el['stateGstCode'].trim()) {
          isValid = false
          failureToast('Please enter the value of State Gst Code')
          return
        }
        else if (el['countryId'] === null || !el['countryId']) {
          isValid = false
          failureToast('Please select the value of Country')
          return
        }
        if (!isValid) {
          return
        }
        if (_tempArray[index]['__status'] == "__new") {
          putpost(masterServiceBaseUrl + '/state/createState', (data) => {
            successToast(data['message'])
            setisSaveVisible(false)
            props.fetchClasses(masterServiceBaseUrl + '/state/getAllState')
          }, (data) => { failureToast(data['message']) }, _tempArray[index], 'post')
        } else {
          putpost(masterServiceBaseUrl + '/state/updateState/' + _tempArray[index].id, (data) => {
            successToast(data['message'])
            setisSaveVisible(false)
            delete _tempArray[index]['editMode']
            props.setclassArray(_tempArray)
            settableDataKey(new Date().getMilliseconds())
            // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
          }, (data) => { failureToast(data['message']) }, _tempArray[index], 'put')
        }
      } else {

      }
    })
  }
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes")
    }
    else {
      let cb = () => {
        addNewClass()
        setisSaveVisible(true)
        // settableDataKey(new Date().getMilliseconds())
      }
      var _tempArray = props.classArray
      _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
      props.setclassArray(_tempArray)
      setisSaveVisible(false)
      cb()
    }
  }
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes")
    }
    else {
      let cb = () => {
        var _tempArray = props.classArray
        _tempArray[index]['editMode'] = true
        props.setclassArray(_tempArray)
        setisSaveVisible(true)
      }
      var _tempArray = props.classArray
      _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
      cb()
    }
  }
  const deleteRow = (index) => {
    if (props.classArray[index]["__status"] == "__new") {
      let n = props.classArray
      n.splice(index, 1)
      props.setclassArray(n)
      setisSaveVisible(false)
      settableDataKey(new Date().getMilliseconds())
    } else {
      if (window.confirm('Are you sure you want to delete this State?')) {
        putpost2(masterServiceBaseUrl + '/state/deleteState/' + props.classArray[index].id, (data) => {
          successToast(data['message'])
          if (props.classArray.length === 1) {
            props.previousPage()
          } else {
            var _tempArray = props.classArray
            if (_tempArray[index]['editMode']) {
              setisSaveVisible(false)
            }
            _tempArray.splice(index, 1)
            props.setclassArray(_tempArray)
          }
          props.refreshList()
          settableDataKey(new Date().getMilliseconds())
        }, (data) => { failureToast(data['message']) }, 'Delete')
      }
    }
  }
  const updateKey = (index, value, key) => {
    let _tempArray = [...props.classArray];
    _tempArray[index][key] = value
    props.setclassArray(_tempArray)
  }

  const searchPaginationHandler = (filterData, pageSize, n) => {
    if (filterData.length > pageSize) {
      n['nextPage'] = n.currentPage + 1
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1
    }
    n['totalPage'] = Math.floor(filterData.length / pageSize)
    if (filterData.length % pageSize != 0) {
      n['totalPage'] += 1
    }
    setstate(filterData);
    setclassArray(filterData.slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || []);
    setpagination(n)
  }

  const filterHandler = _.debounce((stateValue) => {
    let n = pagination;
    let pageSize = 10;
    if (stateValue) {
      const filterCountryList = countries.filter(country => country['text'].toUpperCase().includes(stateValue.toUpperCase()));
      const filterData = tableData.filter(data => (
        filterCountryList.findIndex(countryCode => countryCode['id'] === data['countryId']) >= 0 ||
        data['stateKey'].toUpperCase().includes(stateValue.toUpperCase()) ||
        data['stateDesc'].toUpperCase().includes(stateValue.toUpperCase()) ||
        data['stateDispValue'].toUpperCase().includes(stateValue.toUpperCase()) ||
        data['stateGstCode'].toUpperCase().includes(stateValue.toUpperCase()) ||
        data['status'].toUpperCase().includes(stateValue.toUpperCase())
      ));
      searchPaginationHandler(filterData, pageSize, n)
    } else {
      searchPaginationHandler(tableData, pageSize, n);
    }
  }, 300)

  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - State</h3>
                <div className="floatRight " style={{ display: "flex", alignItems: "center",marginLeft: 'auto' }}>
                  Search : <Input disabled={isSaveVisible} className="tdInput ml-3 mr-3" style={{ width: '150px' }} placeholder="Search" onChange={(e) => filterHandler(e.target.value)} />
                  <CustomButton
                    disabled={isSaveVisible}
                    className={'floatRight mx-1' + (isSaveVisible ? ' btn-dark cursor-gray' : null)}
                    content={'New State'}
                    permissionType={'C'}
                    icon={true}
                    onClick={() => { newRow(); }}
                    permissionSet={userPermissions}
                  />
                </div>
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout" >
                  <MasterHeader type={"State"} permissionSet={userPermissions} isSaveVisible={isSaveVisible} />
                  <tbody className="list" key={tableDataKey} >
                    {props.classArray ? props.classArray.map((el, index) =>
                      !el['editMode'] ? (
                        <TDROW type={"state"} index={index} el={el} editRow={editRow} deleteRow={deleteRow} countryList={props.data} permissionSet={userPermissions} isSaveVisible={isSaveVisible} />
                      ) :
                        (<tr key={index + '-class'}>
                          <td className="text-center p-2 mx-1"><Select2 defaultValue={el['countryId']} data={props.data} onChange={(e) => { updateKey(index, Number(e.target.value), 'countryId') }} options={{ placeholder: "Select Country" }} className="tdSelect" /></td>
                          <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['stateKey']} onChange={(e) => { updateKey(index, e.target.value, 'stateKey') }} placeholder="Example:1" className="tdInput" /></td>
                          <td className="text-center p-2 mx-1"><Input disabled={true} defaultValue={el["__status"] == "__new" ? null : moment(el['eff_date']).format('DD-MM-YYYY')} placeholder="Example:01-01-2020" className="tdInput" /></td>
                          <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['stateDesc']} onChange={(e) => { updateKey(index, e.target.value, 'stateDesc') }} placeholder="Description" className="tdInput" /></td>
                          <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['stateDispValue']} onChange={(e) => { updateKey(index, e.target.value, 'stateDispValue') }} placeholder="Display Value" className="tdInput" /></td>
                          <td className="text-center p-2 mx-1">
                            {/*<Input maxLength="50" defaultValue={el['stateGstCode']} onChange={(e)=>{updateKey(index,e.target.value,'stateGstCode')}} placeholder="State Gst Code" className="tdInput" />*/}
                            <CommonInput
                              type={'number'}
                              maxLength={"2"}
                              forTable={true}
                              restrictSpecialCharacter={true}
                              defaultValue={el['stateGstCode']}
                              placeHolder={"State Gst Code"}
                              className={"tdInput"}
                              onChange={(value) => { updateKey(index, value, 'stateGstCode') }}
                            />
                          </td>

                          <td className="text-center p-2 mx-1">
                            <label className="custom-toggle mx-auto ml-2">
                              <input checked={el.status == "ACTIVE" ? true : false} type="checkbox" id={el.id} onChange={(e) => { settableDataKey(new Date().getMilliseconds());  let n = "ACTIVE"; if (!e.target.checked) { n = "INACTIVE" } updateKey(index, n, 'status') }} />
                              <span style={{ width: "72px" }} className="custom-toggle-slider rounded-circle activeToggle" data-label-off="Inactive" data-label-on="Active" />
                            </label>
                          </td>
                          <td className="text-center">
                            {isSaveVisible ? <>
                              <CustomButton
                                content={'Save'}
                                permissionType={'U,C'}
                                icon={false}
                                forTable={true}
                                onClick={() => { saveAll() }}
                                permissionSet={userPermissions}
                              />
                              <CustomButton
                                content={''}
                                permissionType={'cancel'}
                                icon={true}
                                forTable={true}
                                onClick={() => {
                                  let n = props.classArray
                                  if (n[index]['__status'] == '__new') {
                                    n.splice(index, 1)
                                  } else {
                                    // n[index]['editMode']=false
                                    props.fetchClasses(masterServiceBaseUrl + '/state/getAllState')
                                  }
                                  props.setclassArray(n)
                                  setisSaveVisible(false)
                                }}
                                permissionSet={userPermissions}
                              />
                            </> :
                              <CustomButton
                                content={''}
                                permissionType={'D'}
                                icon={true}
                                forTable={true}
                                onClick={() => deleteRow(index)}
                                permissionSet={userPermissions}
                              />}
                          </td>
                        </tr>)
                    ) : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
export default StateTable;