import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  Row,
  Table,
  UncontrolledAlert,
  UncontrolledDropdown,
} from 'reactstrap';
import { getAPI, putpost, templateBaseurl, templateBaseurl2 } from 'services/http';
import { endpoint } from '../../common/constant';
import { fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
// core components
import './index.css';

// import { Link } from "react-router-dom";
import TestTemplateModal from './TestTemplateModal';

class MessageTemplateForm extends React.Component {
  state = {
    createTemplate: {},
    NotificationChannels: {},
    MappingForVariable: {},
    TypeOfVariable: [],
    ChannelCategoryNotification: {},
    ChannelCategoryBodyLength: {},
    ChannelAccounts: {},
    CategorySMS: [],
    CategoryChannelDepartmentAccounts: {},
    DepartmentCategoryChannel: {},
    DepartmentCategory: {},
    DepartmentList: [],
    customStyles: {
      templateName: '',
      templateNameState: null,
      templateDesc: '',
      templateDescState: null,
      templateCategoryId: '',
      templateCategoryState: '',
      templateBody: '',
      templateBodyState: null,
      hasAttachment: 'N',
      hasAttachmentState: 'N',
      requestType: '',
      requestTypeState: null,
      departmentName: '',
      departmentNameState: null,
      userDeliveryFrequencyState:null,
      templateApprovedState:null,
      contactOrTokenNumber: '',
      contactOrTokenState: null,
      BodyLength: 500,
      isActive: 'ENABLED',
      isActiveState: true,
      selectedChannelData: [],
      selectedChannelState: null,
      selectedChannelDataInitialState: [],
      selectedChannelTemplateId: null,
      variableDescriptionData: [],
      // customVariableDescriptionData: [],
      variableDescriptionDataTest: [],
      variableDescriptionState: null,
      // customVariableDescriptionState: null,
      isEdit: false,
      maxBodyLength: 0
    },
    isAlert: false,
    alertMessage: '',
    alertColor: '',
    selectedChannelToTest: '',
    selectedChannelToTestState: null,
    templateCharacterCount: 0,
    templateParamsTest: {},
    templateParamsTestState: null,
    attachmentLinks:{},
    attachmentLinksState: null,
    // customParamsTest: {},
    // customParamsTestState: 'invalid',
    loader: false,
    testButtonActive: true,
    templateModal: false,
    templateModalMessage: '',
    templateStatus: false,
    isHtmlInBody: false,
    customEnabled: false,
    persistent: false,
    modifiedTemplate: '',
    testSuccessId: null,
    pageSize: 0,
    categoryData: [],
    fetchingCategory: true,
    loading: false
  };



  getData = () => {
    this.setState({ loader: true });
    getAPI(
      `${templateBaseurl}/configuration`,
      result => {
        if (result.code === 200) {
          this.setState({
            createTemplate: result.data.CreateTemplate,
            NotificationChannels: result.data.CreateTemplate.NotificationChannels,
            ChannelCategoryNotification: result.data.ChannelCategory.ChannelCategoryNotification,
            channelCategoryList: result.data.ChannelCategory.CategoryList,
            MappingForVariable: result.data.CreateTemplate.variableMappingList,
            TypeOfVariable: result.data.CreateTemplate.VariableTypeList,
            ChannelAccounts: result.data.ChannelCategory.ChannelAccounts,
            ChannelCategoryBodyLength: result.data.ChannelCategory.ChannelCategoryBodyLength,
            CategorySMS: result.data.ChannelCategory.CategoryList,
            DepartmentList: result.data.DEPARTMENTS,
            DepartmentCategory: result.data.ChannelCategory['Department-Category'],
            DepartmentCategoryChannel: result.data.ChannelCategory['Department-Category-Channel'],
            CategoryChannelDepartmentAccounts: result.data.ChannelCategory['CategoryChannelDepartmentAccounts'],
            //  DepartmentCategory: result.data.ChannelCategory.DepartmentCategory,
            // DepartmentCategoryChannel: result.data.ChannelCategory.DepartmentCategoryChannel,
            // CategoryChannelDepartmentAccounts: result.data.ChannelCategory.CategoryChannelDepartmentAccounts,
            loader: false,
            pageSize: result?.data?.pageSize ?? 10
          });
        } else {
          this.setState({ loader: true });
          const currentState = { ...this.state };
          currentState.isAlert = true;
          currentState.alertMessage = result.message;
          if (result.code === 201 || result.code === 203 || result.code === 204) {
            currentState.alertColor = 'success';
          } else {
            currentState.alertColor = 'danger';
          }
          this.setState(currentState);
        }
      },
      data => {
        failureToast(data['message']);
      }
    );
  };


  getAllCategory = async () => {
    const category = await fetchAllPromisedData(endpoint.allCategory, false);
    if (Array.isArray(category)) {
      const tempData = category?.map(c => { return { label: c.templateCategoryType, value: c.id } })
      this.setState({
        ...this.state, categoryData: tempData,
        fetchingCategory: false
      })
    } else {
      this.setState({
        ...this.state, categoryData: [],
        fetchingCategory: false
      })
    }
  }


  componentDidMount() {
    this.getData();
    this.getAllCategory();
    if (this.props.location.state) {
      const id = this.props.location.state.id;
      getAPI(
        `${templateBaseurl}/getTemplate/${id}`,
        result => {
          if (result.code === 200) {
            this.setState({
              customStyles: {
                templateId: result.data.id,
                departmentName:result.data?.departmentName? result.data.departmentName : 'TECH',
                requestType: result.data.requestType.length > 0 ? result.data.requestType : 'PRM',
                hasAttachment: result.data.hasAttachment,
                templateName: result.data.templateName,
                templateCategoryId: `${result.data.templateCategoryId}`,
                templateDesc: result.data.templateDesc,
                templateBody: result.data.template,
                selectedChannelData: result.data.templateChannels,
                // selectedChannelDataInitialState:result.data.templateChannels,
                variableDescriptionData: result.data.templateVariables,
                // customVariableDescriptionData: result.data?.customTemplateVariables ?? [],
                isEdit: true,
                isActive: result.data.status,
                templateNameState: 'valid',
                templateDescState: 'valid',
                templateBodyState: 'valid',
                selectedChannelState: 'valid',
                selectedChannelTemplateId: 'valid',
                variableDescriptionState: 'valid',
                // customVariableDescriptionState: 'valid',
                selectedChannelTemplateAccount: 'valid',
                requestTypeState: 'valid',
                contactOrTokenNumber: '',
                templateCategoryState: 'valid',
              },
              selectedChannelToTest:
                result.data.templateChannels.length > 1 ? '' : result.data.templateChannels[0].channelName,
              selectedChannelToTestState: result.data.templateChannels.length > 1 ? null : 'valid',
              templateParamsTest: {},
              templateParamsTestState:'valid',
              attachmentLinksState: 'valid',
              // customParamsTest: {},
              // custmoParamsTestState: 'invalid',
              templateStatus: result.data.status === 'ENABLED' ? true : false,
              isHtmlInBody: result.data.templateChannels.filter(item => item.channelName === 'EMAIL')?.[0]?.htmlEnabled ?? false,
              customEnabled: result.data.templateChannels.filter(item => item.channelName === 'FCM')?.[0]?.customEnabled ?? false,
              persistent: result.data.templateChannels.filter(item => item.channelName === 'FCM')?.[0]?.persistent ?? false,
              modifiedTemplate: result.data.template
            });

          } else {
            const currentState = { ...this.state };
            currentState.isAlert = true;
            currentState.alertMessage = result.message;
            if (result.code === 201 || result.code === 203 || result.code === 204) {
              currentState.alertColor = 'success';
            } else {
              currentState.alertColor = 'danger';
            }
            this.setState(currentState);
          }
        },
        data => {
          failureToast(data['message']);
        }
      );
    }
  }

  //validation
  validateCustomStylesForm = () => {
    let IsTestTemplateObjectValid = false;
    let newState = this.state.customStyles;
    const templateParamsTest = { ...this.state.templateParamsTest, ...this.state.attachmentLinks };
    // console.log('templateParamsTest', this.state.templateParamsTest);

    newState.variableDescriptionData.length > 0 &&
      newState.variableDescriptionData.forEach(item => {
        // console.log(item.variableName);
        IsTestTemplateObjectValid =
          templateParamsTest.hasOwnProperty(item.variableName) &&
          templateParamsTest[item.variableName].trim().length !== 0;
      });

    const valueIsEmpty = Object.keys(this.state.templateParamsTest).some(
      key => this.state.templateParamsTest[key].length === 0
    );
    // console.log('valueIsEmpty', valueIsEmpty)

    // const IdNotExist =
    //   newState.selectedChannelData.length > 0 &&
    //   newState.selectedChannelData.every(item => item.hasOwnProperty('channelTemplateId'));

    const DescriptionNotExist =
      newState.variableDescriptionData.length > 0 &&
      newState.variableDescriptionData.every(item => item.hasOwnProperty('variableDesc'));

    const descriptionEmpty =
      newState.variableDescriptionData.length > 0 && newState.variableDescriptionData.some(i => i.variableDesc === '');

    const variableTypeEmpty =
      newState.variableDescriptionData.length > 0 && newState.variableDescriptionData.some(i => i.variableType === '');

    let typeNotExist =
      newState.variableDescriptionData.length > 0 &&
      newState.variableDescriptionData.every(item => item.hasOwnProperty('variableType'));
    //validation for template description exist

    if (newState.templateName === '' || newState.templateName.trim().length === 0) {
      newState.templateNameState = 'invalid';
    } else {
      newState.templateNameState = 'valid';
    }
    if (newState.templateCategoryId === '') {
      newState.templateCategoryState = 'invalid';
    } else {
      newState.templateCategoryState = 'valid';
    }
    if (newState.templateDesc === '' || newState.templateDesc.trim().length === 0) {
      newState.templateDescState = 'invalid';
    } else {
      newState.templateDescState = 'valid';
    }
    if (newState.templateBody === '' || newState.templateBody.trim().length === 0) {
      newState.templateBodyState = 'invalid';
    } else {

      if (this.state.isHtmlInBody && /<.+?>/g.test(newState.templateBody)) {
        if (!newState.templateBody.match(/<html[^>]*>[\s\S]*<\/html>/gi) || !newState.templateBody.match(/<head[^>]*>[\s\S]*<\/head>/gi) || !newState.templateBody.match(/<body[^>]*>[\s\S]*<\/body>/gi)) {
          newState.templateBodyState = 'invalid';
        }
      } else if (this.state.isHtmlInBody && !/<.+?>/g.test(newState.templateBody)) {
        newState.templateBodyState = 'invalid';
      } else if (!this.state.isHtmlInBody && /<.+?>/g.test(newState.templateBody)) {
        newState.templateBodyState = 'invalid';
      } else {
        newState.templateBodyState = 'valid';
      }
    }
    if (newState.requestType === '' || newState.requestType.trim().length === 0) {
      newState.requestTypeState = 'invalid';
    } else {
      newState.requestTypeState = 'valid';
    }
    if (newState.departmentName === '' || newState.departmentName.trim().length === 0) {
      newState.departmentNameState = 'invalid';
    } else {
      newState.departmentNameState = 'valid';
    }
    if (
      newState.contactOrTokenNumber === '' ||
      newState.contactOrTokenNumber.trim().length === 0 ||
      ((this.state.selectedChannelToTest === 'SMS' || this.state.selectedChannelToTest === 'WHATSAPP') &&
        newState.contactOrTokenNumber.trim().length !== 10)
    ) {
      newState.contactOrTokenState = 'invalid';
    } else {
      newState.contactOrTokenState = 'valid';
    }
    if (this.state.selectedChannelToTest === '' || this.state.selectedChannelToTest.trim().length === 0) {
      this.setState({ selectedChannelToTestState: 'invalid' });
    } else {
      this.setState({ selectedChannelToTestState: 'valid' });
    }
     let templateParamsTestStateTemp;
    if (
      newState.variableDescriptionData.length !== 0 &&
      ((Object.keys(this.state.templateParamsTest).length === 0 &&
        this.state.templateParamsTest.constructor === Object) ||
        valueIsEmpty ||
        !IsTestTemplateObjectValid)
    ) {
      // this.setState({ templateParamsTestState: 'invalid' });
      templateParamsTestStateTemp='invalid';
    } else {
      // this.setState({ templateParamsTestState: 'valid' },()=>{console.log('templateParamsTest validated')});
      templateParamsTestStateTemp='valid';
    }

    //channel validations 1
    if (newState.selectedChannelData.length === 0) {
      newState.selectedChannelState = 'invalid';
    } else {
      newState.selectedChannelState = 'valid';
    }
    //channel validations 2

    // if (newState.selectedChannelData.length > 0 && !IdNotExist) {
    //   newState.selectedChannelTemplateId = 'invalid';
    // } else {
    //   newState.selectedChannelTemplateId = 'valid';
    // }
    let selectedChannelTemplateId='valid';
    newState.selectedChannelData.forEach((item)=>{
      const templateApprovedEnabled=this.state.NotificationChannels?.[item.channelName]?.isTemplateApprovalRequired?.enabled;
      if(templateApprovedEnabled){
        if(item.templateApproved&&item.channelTemplateId===''){
          selectedChannelTemplateId='invalid';
        }
      }else if(item.channelTemplateId===''){
        selectedChannelTemplateId='invalid';
      }
    })
    newState.selectedChannelTemplateId=selectedChannelTemplateId;
    //channel validation 3
    let userDeliveryFrequencyState='valid';
    newState.selectedChannelData.forEach((item)=>{
      const templateApprovedEnabled=this.state.NotificationChannels?.[item.channelName]?.isTemplateApprovalRequired?.enabled;
      const {minFreq,maxFreq}=this.getMinMaxFrequency(item.channelName);
      if(templateApprovedEnabled){
        if(item.templateApproved&&(item.userDeliveryFrequency===''||(item.userDeliveryFrequency<minFreq||item.userDeliveryFrequency>maxFreq))){
          userDeliveryFrequencyState='invalid';
        }
      }else if((item.userDeliveryFrequency===''||(item.userDeliveryFrequency<minFreq||item.userDeliveryFrequency>maxFreq))){
        userDeliveryFrequencyState='invalid';
      }
    })
    newState.userDeliveryFrequencyState=userDeliveryFrequencyState;
    // console.log('UDFS', this.state.customStyles.userDeliveryFrequencyState);

    //channel Validation 4
    let templateApprovedState='valid';
    newState.selectedChannelData.forEach(item=>{
      const templateApprovedEnabled=this.state.NotificationChannels?.[item.channelName]?.isTemplateApprovalRequired?.enabled;
      if(templateApprovedEnabled&& !item.templateApproved){
        templateApprovedState='invalid';
      }
    })
    newState.templateApprovedState=templateApprovedState;
    
    

    //variable description validations 1
    if (newState.variableDescriptionData.length > 0 && !DescriptionNotExist) {
      newState.variableDescriptionState = 'invalid';
    } else if (newState.variableDescriptionData.length > 0 && descriptionEmpty) {
      newState.variableDescriptionState = 'invalid';
    } else if (newState.variableDescriptionData.length > 0 && !typeNotExist) {
      newState.variableDescriptionState = 'invalid';
    } else if (newState.variableDescriptionData.length > 0 && descriptionEmpty) {
      newState.variableDescriptionState = 'invalid';
    } else if (newState.variableDescriptionData.length > 0 && !typeNotExist) {
      newState.variableDescriptionState = 'invalid';
    } else if (newState.variableDescriptionData.length > 0 && variableTypeEmpty) {
      newState.variableDescriptionState = 'invalid';
    } else {
      newState.variableDescriptionState = 'valid';
    }
    this.setState(prevState=>({
      ...prevState,
      templateParamsTestState:templateParamsTestStateTemp,
      customStyles: newState,
    }));
  };

  customStylesForm = (e, stateName, BodyLength) => {
    let newState = this.state.customStyles;
    //for channel Name input
    if (stateName === 'channelName') {
      if (e.target.checked === true) {
        newState['selectedChannelData'] = [
          ...newState['selectedChannelData'],
          {
            [stateName]: e.target.name,
            ['templateApproved']:false,
            ['channelTemplateId']:'',
            ['userDeliveryFrequency']:(this.state.NotificationChannels?.[e.target.name]?.Ratelimit?.defaultLimit||''),
            ['rateLimitType']: this.state.NotificationChannels?.[e.target.name]?.Ratelimit?.RateLimitType,
            ['channelTemplateAccount']:this.state.CategoryChannelDepartmentAccounts?.[this.state.customStyles.requestType]?.[e.target.name]?.[this.state.customStyles.departmentName]?.['Account-Name']

          },
        ];
        newState.selectedChannelState = 'valid';
        // console.log('initialSelectedChannelData',newState['selectedChannelData']);
      } else if (e.target.checked === false) {
        newState['selectedChannelData'] = newState.selectedChannelData.filter(item => {
          return item.channelName !== e.target.name;
        });
        if (e.target.name === 'EMAIL') {
          this.setState({ ...this.state, isHtmlInBody: false })
        }
        if (e.target.name === 'FCM') {
          this.setState({ ...this.state, customEnabled: false })
        }
      }
      //validation for template id exist

      // let IdNotExist =
      //   newState.selectedChannelData.length > 0 &&
      //   newState.selectedChannelData.every(item => item.hasOwnProperty('channelTemplateId'));

      // let accNotExits = newState.selectedChannelData.length > 0 &&
      //   newState.selectedChannelData.every(item => item.hasOwnProperty('channelTemplateAccount'));

      // if (newState.selectedChannelData.length > 0 && !IdNotExist) {
      //   newState.selectedChannelTemplateId = 'invalid';
      // } else {
      //   newState.selectedChannelTemplateId = 'valid';
      // }

      // if (newState.selectedChannelData.length > 0 && !accNotExits) {
      //   newState.selectedChannelTemplateAccount = 'invalid';
      // } else {
      //   newState.selectedChannelTemplateAccount = 'valid';
      // }
    }
    //for channel template id input
    else if (stateName === 'channelTemplateId') {
      let newdata = [...newState.selectedChannelData];
      newdata.forEach(function (item) {
        if (item.channelName === e.target.name) {
          item[stateName] = e.target.value;
        }
      });
      newState['selectedChannelData'] = newdata;
      // console.log(newState['selectedChannelData']);

      //validation for template id exist

      // const idEmpty =
      //   newState.selectedChannelData.length > 0 && newState.selectedChannelData.some(i => i.channelTemplateId === '');

      // let IdNotExist =
      //   newState.selectedChannelData.length > 0 &&
      //   newState.selectedChannelData.every(item => item.hasOwnProperty('channelTemplateId'));

      // if (newState.selectedChannelData.length > 0 && !IdNotExist) {
      //   newState.selectedChannelTemplateId = 'invalid';
      // } else if (newState.selectedChannelData.length > 0 && idEmpty) {
      //   newState.selectedChannelTemplateId = 'invalid';
      // } else {
      //   newState.selectedChannelTemplateId = 'valid';
      // }
    } else if (stateName === 'templateApproved') {
      let newdata = [...newState.selectedChannelData];
      // console.log(newdata);
      newdata.forEach(function (item) {
        if (item.channelName === e.target.name) {
          item[stateName] = e.target.checked;
          if(e.target.checked===false) {
            item['userDeliveryFrequency']='';
            item['channelTemplateId']='';
          }
        }
      });

      newState['selectedChannelData'] = newdata;
      // console.log(newState['selectedChannelData']);
    }else if(stateName==='userDeliveryFrequency'){
        let newdata=[...newState.selectedChannelData];
        newdata.forEach(function (item){
          if(item.channelName===e.target.name){
            if(e.target.value!=='')
            item[stateName]=parseInt(e.target.value);
            else
            item[stateName]='';
          }
        });
        newState['selectedChannelData']=newdata;
        // const minFrequency=this.state.NotificationChannels?.[e.target.name]?.Ratelimit?.LimitFrequencyMin;
        // const maxFrequency=this.state.NotificationChannels?.[e.target.name]?.Ratelimit?.LimitFrequencyMax;
        // if(parseInt(e.target.value)<minFrequency||parseInt(e.target.value)>maxFrequency)
        // newState.userDeliveryFrequencyState='invalid';
        // else newState.userDeliveryFrequencyState='valid';
        // console.log('minFreq', minFrequency);
        // console.log('maxFreq', maxFrequency);
        // console.log('selectedChannelData',newdata);
        // console.log('userDeliveryFrequencyState',newState.userDeliveryFrequencyState);
    }
    // else if (stateName === 'channelTemplateAccount') {
    //   let newdata = [...newState.selectedChannelData];
    //   newdata.forEach(function (item) {
    //     if (item.channelName === e.target.name) {
    //       item[stateName] = e.target.value;
    //       console.log(e.target.value);
    //     }
    //   });
    //   newState['selectedChannelData'] = newdata;
    //   const idEmpty =
    //     newState.selectedChannelData.length > 0 && newState.selectedChannelData.some(i => i.channelTemplateAccount === '');

    //   let IdNotExist =
    //     newState.selectedChannelData.length > 0 &&
    //     newState.selectedChannelData.every(item => item.hasOwnProperty('channelTemplateAccount'));

    //   if (newState.selectedChannelData.length > 0 && !IdNotExist) {
    //     newState.selectedChannelTemplateAccount = 'invalid';
    //   } else if (newState.selectedChannelData.length > 0 && idEmpty) {
    //     newState.selectedChannelTemplateAccount = 'invalid';
    //   } else {
    //     newState.selectedChannelTemplateAccount = 'valid';
    //   }
    // }
    //
    else if (stateName === 'templateBody') {
      newState[stateName] = e.target.value;
      let variable = e.target.value.match(/\{{([0-9]+)\}}/g);
      //regex for alphanumeric or number or character
      //let variable = e.target.value.match(/\{([A-Za-z0-9^}]+)\}/g)
      if (variable) {
        variable = variable.map(item => {
          return item.toString().replace(/[{{,}}]/g, '');
        });
        variable = [...new Set(variable)];

        //descrption data before manipultaion
        let oldVariableDetails = [...newState.variableDescriptionData];
        let oldVarObject = oldVariableDetails.filter((item, key) => variable.includes(item.variableName));

        //descrption data before manipultaion
        let newData = variable.map(item => {
          return {
            variableName: item,
          };
        });

        let finalProduct = oldVarObject.concat(newData);
        finalProduct = finalProduct.filter((v, i, a) => a.findIndex(t => t.variableName === v.variableName) === i);

        //check if array already contains Attachment link
        let AttachmentExist = newState.variableDescriptionData.filter(o => o.variableType === 'AttachmentLink');
        let AttachmentExistObjectName = Math.max(...finalProduct.map(o => o.variableName)) * 1 + 1;

        for (var item in AttachmentExist) {
          if (AttachmentExist[item].variableType === 'AttachmentLink') {
            AttachmentExist[item].variableName = AttachmentExistObjectName;
            break; //Stop this loop, we found it!
          }
        }

        let variableData = finalProduct.concat(AttachmentExist);
        newState['variableDescriptionData'] = variableData;
      } else {
        if (newState['hasAttachment'] === 'Y') {
          // newState[stateName] = 'Y';
          let newdata = [];
          let AttachmentObject = {
            variableName: 1,
            variableMapping: 'URL',
            variableDesc: 'Attachment',
            variableType: 'AttachmentLink',
          };
          newdata.push(AttachmentObject);
          newState['variableDescriptionData'] = newdata;
        } else {
          newState['variableDescriptionData'] = [];
        }
      }
      //validations for template body
      if (newState.templateBody === '' || newState.templateBody.trim().length === 0) {
        newState.templateBodyState = 'invalid';
      } else {
        newState.templateBodyState = 'valid';
      }
    }
    //for variable description
    else if (stateName === 'variableDesc') {
      let newdata = [...newState.variableDescriptionData];
      newdata.forEach(function (item) {
        if (item.variableName === e.target.name) {
          item[stateName] = e.target.value;
        }
      });
      newState['variableDescriptionData'] = newdata;

      //validation for template description type mapping exist

      const variableTypeEmpty =
        newState.variableDescriptionData.length > 0 &&
        newState.variableDescriptionData.some(i => i.variableType === '');

      let typeNotExist =
        newState.variableDescriptionData.length > 0 &&
        newState.variableDescriptionData.every(item => item.hasOwnProperty('variableType'));
      //validation for template description exist

      const descriptionEmpty =
        newState.variableDescriptionData.length > 0 &&
        newState.variableDescriptionData.some(i => i.variableDesc === '');

      let descNotExist =
        newState.variableDescriptionData.length > 0 &&
        newState.variableDescriptionData.every(item => item.hasOwnProperty('variableDesc'));

      const mappingEmpty =
        newState.variableDescriptionData.length > 0 &&
        newState.variableDescriptionData.some(i => i.variableMapping === '');

      let mappingNotExist =
        newState.variableDescriptionData.length > 0 &&
        newState.variableDescriptionData.every(item => item.hasOwnProperty('variableMapping'));

      if (newState.variableDescriptionData.length > 0 && !descNotExist) {
        newState.variableDescriptionState = 'invalid';
      } else if (newState.variableDescriptionData.length > 0 && descriptionEmpty) {
        newState.variableDescriptionState = 'invalid';
      } else if (newState.variableDescriptionData.length > 0 && !typeNotExist) {
        newState.variableDescriptionState = 'invalid';
      } else if (newState.variableDescriptionData.length > 0 && variableTypeEmpty) {
        newState.variableDescriptionState = 'invalid';
      } else if (newState.variableDescriptionData.length > 0 && !mappingNotExist) {
        newState.variableDescriptionState = 'invalid';
      } else if (newState.variableDescriptionData.length > 0 && mappingEmpty) {
        newState.variableDescriptionState = 'invalid';
      } else {
        newState.variableDescriptionState = 'valid';
      }
    }
    //for variable type
    else if (stateName === 'variableType') {
      let newdata = [...newState.variableDescriptionData];
      newdata.forEach(function (item) {
        if (item.variableName === e.target.name) {
          item[stateName] = e.target.value;
        }
      });
      newState['variableDescriptionData'] = newdata;
      //validation for template variable desc type mapping exist

      const variableTypeEmpty =
        newState.variableDescriptionData.length > 0 &&
        newState.variableDescriptionData.some(i => i.variableType === '');

      let typeNotExist =
        newState.variableDescriptionData.length > 0 &&
        newState.variableDescriptionData.every(item => item.hasOwnProperty('variableType'));
      //validation for template description exist

      const descriptionEmpty =
        newState.variableDescriptionData.length > 0 &&
        newState.variableDescriptionData.some(i => i.variableDesc === '');

      let descNotExist =
        newState.variableDescriptionData.length > 0 &&
        newState.variableDescriptionData.every(item => item.hasOwnProperty('variableDesc'));

      const mappingEmpty =
        newState.variableDescriptionData.length > 0 &&
        newState.variableDescriptionData.some(i => i.variableMapping === '');

      let mappingNotExist =
        newState.variableDescriptionData.length > 0 &&
        newState.variableDescriptionData.every(item => item.hasOwnProperty('variableMapping'));

      if (newState.variableDescriptionData.length > 0 && !descNotExist) {
        newState.variableDescriptionState = 'invalid';
      } else if (newState.variableDescriptionData.length > 0 && descriptionEmpty) {
        newState.variableDescriptionState = 'invalid';
      } else if (newState.variableDescriptionData.length > 0 && !typeNotExist) {
        newState.variableDescriptionState = 'invalid';
      } else if (newState.variableDescriptionData.length > 0 && variableTypeEmpty) {
        newState.variableDescriptionState = 'invalid';
      } else if (newState.variableDescriptionData.length > 0 && !mappingNotExist) {
        newState.variableDescriptionState = 'invalid';
      } else if (newState.variableDescriptionData.length > 0 && mappingEmpty) {
        newState.variableDescriptionState = 'invalid';
      } else {
        newState.variableDescriptionState = 'valid';
      }
    } else if (stateName === 'variableMapping') {
      let newdata = [...newState.variableDescriptionData];
      newdata.forEach(function (item) {
        if (item.variableName === e.target.name) {
          item[stateName] = e.target.value;
        }
      });
      newState['variableDescriptionData'] = newdata;

      //validation for template variable name type exist
      const variableTypeEmpty =
        newState.variableDescriptionData.length > 0 &&
        newState.variableDescriptionData.some(i => i.variableType === '');

      let typeNotExist =
        newState.variableDescriptionData.length > 0 &&
        newState.variableDescriptionData.every(item => item.hasOwnProperty('variableType'));
      //validation for template description exist

      const descriptionEmpty =
        newState.variableDescriptionData.length > 0 &&
        newState.variableDescriptionData.some(i => i.variableDesc === '');

      let descNotExist =
        newState.variableDescriptionData.length > 0 &&
        newState.variableDescriptionData.every(item => item.hasOwnProperty('variableDesc'));

      const mappingEmpty =
        newState.variableDescriptionData.length > 0 &&
        newState.variableDescriptionData.some(i => i.variableMapping === '');

      let mappingNotExist =
        newState.variableDescriptionData.length > 0 &&
        newState.variableDescriptionData.every(item => item.hasOwnProperty('variableMapping'));

      if (newState.variableDescriptionData.length > 0 && !descNotExist) {
        newState.variableDescriptionState = 'invalid';
      } else if (newState.variableDescriptionData.length > 0 && descriptionEmpty) {
        newState.variableDescriptionState = 'invalid';
      } else if (newState.variableDescriptionData.length > 0 && !typeNotExist) {
        newState.variableDescriptionState = 'invalid';
      } else if (newState.variableDescriptionData.length > 0 && variableTypeEmpty) {
        newState.variableDescriptionState = 'invalid';
      } else if (newState.variableDescriptionData.length > 0 && !mappingNotExist) {
        newState.variableDescriptionState = 'invalid';
      } else if (newState.variableDescriptionData.length > 0 && mappingEmpty) {
        newState.variableDescriptionState = 'invalid';
      } else {
        newState.variableDescriptionState = 'valid';
      }
    }
    //for attatchment
    else if (stateName === 'hasAttachment') {
      if (e.target.checked === true) {
        newState[stateName] = 'Y';
        let newdata = [...newState.variableDescriptionData];
        if (newState.variableDescriptionData.length > 0) {
          let variableDescriptionDataLength = Math.max(...newState.variableDescriptionData.map(o => o.variableName));
          variableDescriptionDataLength = parseInt(variableDescriptionDataLength, 10);
          variableDescriptionDataLength = variableDescriptionDataLength + 1;
          let AttachmentObject = {
            variableName: newState.variableDescriptionData.length > 0 ? variableDescriptionDataLength.toString() : 1,
            variableMapping: 'URL',
            variableDesc: 'Attachment',
            variableType: 'AttachmentLink',
          };
          newdata.push(AttachmentObject);
        } else {
          let AttachmentObject = {
            variableName: 1,
            variableMapping: 'URL',
            variableDesc: 'Attachment',
            variableType: 'AttachmentLink',
          };
          newdata.push(AttachmentObject);
        }

        newState['variableDescriptionData'] = newdata;
      } else {
        newState[stateName] = 'N';
        let newdata = [...newState.variableDescriptionData];
        let removedObject = newdata.filter(function (obj) {
          return obj.variableType !== 'AttachmentLink';
        });
        newState['variableDescriptionData'] = removedObject;
      }
    }
    //for status
    else if (stateName === 'isActive') {
      if (e.target.checked === true) {
        newState[stateName] = 'ENABLED';
      } else {
        newState[stateName] = 'DISABLED';
      }
    } else if (stateName === 'templateName') {
      newState[stateName] = e.target.value;
      if (newState.templateName === '' || newState.templateName.trim().length === 0) {
        newState.templateNameState = 'invalid';
      } else {
        newState.templateNameState = 'valid';
      }
    }
    else if (stateName === 'templateCategoryId') {
      // console.log('templateCategoryId',e.target.value);
      newState[stateName] = e.target.value;
      if (newState.templateCategoryId === '' || newState.templateCategoryId.trim().length === 0) {
        newState.templateCategoryState = 'invalid';
      } else {
        newState.templateCategoryState = 'valid';
      }
    }
     else if (stateName === 'templateDesc') {
      newState[stateName] = e.target.value;
      if (newState.templateDesc === '' || newState.templateDesc.trim().length === 0) {
        newState.templateDescState = 'invalid';
      } else {
        newState.templateDescState = 'valid';
      }
    } else if (stateName === 'requestType') {
      newState[stateName] = e.target.value;
      newState['BodyLength'] = BodyLength;
      if (newState.requestType === '' || newState.requestType.trim().length === 0) {
        newState.requestTypeState = 'invalid';
      } else {
        newState.requestTypeState = 'valid';
      }
    } else if (stateName === 'departmentName') {
      // newState['requestType'] = '';
      // newState['templateBody'] = '';
      // newState['templateBodyState'] = null;
      newState[stateName] = e.target.value;
      if (newState.departmentName === '' || newState.departmentName.trim().length === 0) {
        newState.departmentNameState = 'invalid';
      } else {
        newState.departmentNameState = 'valid';
      }
      // console.log('newState.departmentName', newState[stateName]);
    } else if (stateName === 'contactOrTokenNumber') {
      newState[stateName] = e.target.value;
      const mobileValidation = /^\d*(?:\.\d{1,2})?$/;
      if (BodyLength === 'SMS' || BodyLength === 'WHATSAPP') {
        if (
          newState.contactOrTokenNumber === '' ||
          newState.contactOrTokenNumber.trim().length === 0 ||
          // newState.contactOrTokenNumber.trim().length !== 10
          !e.target.value.match(mobileValidation)
        ) {
          newState.contactOrTokenState = 'invalid';
        } else {
          newState.contactOrTokenState = 'valid';
        }
      } else if (BodyLength === 'EMAIL') {
        const emailRegEx = /\S+@\S+\.\S+/;
        const isValid = newState.contactOrTokenNumber.match(emailRegEx);

        if (newState.contactOrTokenNumber === '' || newState.contactOrTokenNumber.trim().length === 0 || !isValid) {
          newState.contactOrTokenState = 'invalid';
        } else {
          newState.contactOrTokenState = 'valid';
        }
      } else if (BodyLength === 'FCM') {
        if (newState.contactOrTokenNumber === '' || newState.contactOrTokenNumber.trim().length === 0) {
          newState.contactOrTokenState = 'invalid';
        } else {
          newState.contactOrTokenState = 'valid';
        }
      }
    }
    //for other inputs
    else {
      newState[stateName] = e.target.value;
    }
    this.setState({
      customStyles: newState,
    });
  };

  removeChannelData = () => {
    const selected = this.state.customStyles.selectedChannelData.filter(item => item.channelName === 'EMAIL');
    let newState = this.state.customStyles;
    newState['selectedChannelData'] = selected;
    this.setState({
      customStyles: newState,
    });
  }

  handleProceedCreate = () => {
    this.validateCustomStylesForm();
    this.setState({ loading: true })
    const formData = { ...this.state.customStyles, htmlEnabled: this.state.isHtmlInBody, customEnabled: this.state.customEnabled, persistent: this.state.persistent };
    let bodyLen = 0;
    if (!formData.htmlEnabled) {
      formData.selectedChannelData.map(c => {
        if (!bodyLen || bodyLen > this.state.ChannelCategoryBodyLength?.[formData.requestType]?.[c.channelName]) {
          bodyLen = this.state.ChannelCategoryBodyLength?.[formData.requestType]?.[c.channelName]
        }
      })
    }

    if (formData.templateBody.length > bodyLen && !this.state.isHtmlInBody) {
      let newState = this.state.customStyles;
      newState['templateBodyState'] = 'invalid';
      newState['maxBodyLength'] = bodyLen;
      this.setState({
        customStyles: newState,
      })
      this.setState({ loading: false })
      return
    }

    if (
      formData.templateNameState === 'valid' &&
      formData.templateCategoryState==='valid'&&
      formData.templateDescState === 'valid' &&
      formData.templateBodyState === 'valid' &&
      formData.selectedChannelState === 'valid' &&
      formData.selectedChannelTemplateId === 'valid' &&
      formData.variableDescriptionState === 'valid' &&
      formData.requestTypeState === 'valid' &&
      formData.departmentNameState==='valid' &&
      formData.userDeliveryFrequencyState==='valid'&&
      formData.templateApprovedState==='valid'
      // && (this.state.customEnabled ? formData.customVariableDescriptionState==='valid' : true)
    ) {
      if (!formData.isEdit) {

        const indexOfEmail = formData.selectedChannelData.findIndex(item => item.channelName === 'EMAIL')
        if (indexOfEmail > -1) {
          formData.selectedChannelData[indexOfEmail] = { ...formData.selectedChannelData[indexOfEmail], htmlEnabled: formData.htmlEnabled }
        }
        const indexOfFCM = formData.selectedChannelData.findIndex(item => item.channelName === 'FCM')
        if (indexOfFCM > -1) {
          formData.selectedChannelData[indexOfFCM] = { ...formData.selectedChannelData[indexOfFCM], customEnabled: formData.customEnabled, persistent: formData.persistent , channelTemplateAccount: "FCM-TRANSACTIONAL"}
        }
        const indexOfWEBPUSH = formData.selectedChannelData.findIndex(item => item.channelName === 'WEBPUSH')
        if (indexOfWEBPUSH > -1) {
          formData.selectedChannelData[indexOfWEBPUSH] = { ...formData.selectedChannelData[indexOfWEBPUSH], customEnabled: formData.customEnabled, persistent: formData.persistent , channelTemplateAccount: "WEBPUSH-TRANSACTIONAL"}
        }

        const reqBody = {
          template: Buffer.from(formData.templateBody).toString('base64'),
          templateName: formData.templateName,
          templateDesc: formData.templateDesc,
          hasAttachment: formData.hasAttachment,
          requestType: formData.requestType,
          status: formData.isActive,
          templateChannels: formData.selectedChannelData,
          templateVariables: formData.variableDescriptionData,
          templateCategoryId: formData.templateCategoryId,
          departmentName:formData.departmentName
          // customTemplateVariables: formData.customVariableDescriptionData,
        };
        // console.log('reqBody',reqBody);

        putpost(
          `${templateBaseurl}/createTemplate`,
          result => {
            if (result.code === 200) {
              this.props.history.push({
                pathname: '/admin/erpTemplate',
                state: {
                  alert: true,
                  message: result.message,
                },
              });
              this.setState({ loading: false })
            } else {
              const currentState = { ...this.state };
              currentState.isAlert = true;
              currentState.alertMessage = result.message;
              if (result.code === 201 || result.code === 203 || result.code === 204) {
                currentState.alertColor = 'success';
              } else {
                currentState.alertColor = 'danger';
              }
              this.setState({ loading: false })
              this.setState(currentState);
            }
          },
          data => {
            this.setState({ loading: false })
            failureToast(data['message']);
          },
          reqBody,
          'post'
        );
      } else {
        const elementId = this.props.location.state.id;

        const indexOfEmail = formData.selectedChannelData.findIndex(item => item.channelName === 'EMAIL')
        if (indexOfEmail > -1) {
          formData.selectedChannelData[indexOfEmail] = { ...formData.selectedChannelData[indexOfEmail], htmlEnabled: formData.htmlEnabled }
        }
        const indexOfFCM = formData.selectedChannelData.findIndex(item => item.channelName === 'FCM')
        if (indexOfFCM > -1) {
          formData.selectedChannelData[indexOfFCM] = { ...formData.selectedChannelData[indexOfFCM], customEnabled: formData.customEnabled, persistent: formData.persistent, channelTemplateAccount: "FCM-TRANSACTIONAL" }
        }
        const reqBody = {
          hasAttachment: formData.hasAttachment,
          requestType: formData.requestType,
          status: formData.isActive,
          template: Buffer.from(formData.templateBody).toString('base64'),
          templateName: formData.templateName,
          templateDesc: formData.templateDesc,
          templateChannels: formData.selectedChannelData,
          templateVariables: formData.variableDescriptionData,
          templateCategoryId: formData.templateCategoryId,
          departmentName: formData.departmentName
          // customTemplateVariables: formData.customVariableDescriptionData
        };
        // console.log('reqBody',reqBody);
        putpost(
          `${templateBaseurl}/updateTemplate/${elementId}`,
          result => {
            if (result.code === 200) {
              successToast(result.message);
              this.props.history.push({
                pathname: '/admin/erpTemplate',
                state: {
                  alert: true,
                  message: result.message,
                },
              });
              this.setState({ loading: false })
            } else {
              const currentState = { ...this.state };
              currentState.isAlert = true;
              currentState.alertMessage = result.message;
              if (result.code === 201 || result.code === 203 || result.code === 204) {
                currentState.alertColor = 'success';
              } else {
                currentState.alertColor = 'danger';
              }
              this.setState({ loading: false })
              this.setState(currentState);
            }
          },
          data => {
            this.setState({ loading: false })
            failureToast(data['message']);
          },
          reqBody,
          'put'
        );
      }
    }else{
      this.setState({ loading: false })
    }
  };

  onDissmiss = () => {
    const currentState = { ...this.state };
    currentState.isAlert = false;
    currentState.alertMessage = '';
    this.setState(currentState);
  };

  getContent = () => {
    let secondCheck = '';
    let newState = this.state.customStyles;
    let firstCheck = this.state.customStyles.templateBody.replace(/\\n/g, '<br />').replace(/(?:\r\n|\r|\n)/g, '<br>');
    if (
      Object.keys(this.state.templateParamsTest).length === 0 &&
      this.state.templateParamsTest.constructor === Object
    ) {
      newState.variableDescriptionData.length > 0
        ? newState.variableDescriptionData.forEach(item => {
          if (item.variableMapping) {
            secondCheck = firstCheck
              .split(`{{${item.variableName}}}`)
              .join(`<span id='underlined'>${item.variableMapping}</span>`);
            firstCheck = secondCheck;
          } else {
            secondCheck = firstCheck;
          }
        })
        : (secondCheck = firstCheck);
    } else {
      let templateBody = firstCheck;
      newState.variableDescriptionData.length > 0 &&
        newState.variableDescriptionData.forEach(item => {
          for (const property in this.state.templateParamsTest) {
            if (item.variableName === property && this.state.templateParamsTest[property].trim().length !== 0) {
              templateBody = templateBody
                .split(`{{${item.variableName}}}`)
                .join(`<span id='underlined'>${this.state.templateParamsTest[property]}</span>`);
            }
          }
        });
      let templateBodyConverted = templateBody;
      newState.variableDescriptionData.length > 0 &&
        newState.variableDescriptionData.forEach(item => {
          if (item.variableMapping) {
            templateBodyConverted = templateBodyConverted
              .split(`{{${item.variableName}}}`)
              .join(`<span id='underlined'>${item.variableMapping}</span>`);
          }
        });
      secondCheck = templateBodyConverted;
    }
    return {
      __html: `<p>${secondCheck}</p>`,
    };
  };


  getHtmlWithVariables = () => {
    let modifiedTemplate = this.state.customStyles.templateBody;
    if (this.state.customStyles.variableDescriptionData && this.state.customStyles.variableDescriptionData.length && Object.keys(this.state.templateParamsTest).length !== 0) {
      this.state.customStyles.variableDescriptionData.forEach(element => {
        modifiedTemplate = modifiedTemplate.replace(`{{${element.variableName}}}`, `${this.state.templateParamsTest?.[element.variableName] ?? element.variableName}`) //this.state.templateParamsTest
      })
      this.setState({ ...this.state, modifiedTemplate: modifiedTemplate })
    } else {
      this.setState({ ...this.state, modifiedTemplate: modifiedTemplate })
    }
  }

  getTemplateCharacterCount = () => {
    let newState = this.state.customStyles;
    let firstCheck = this.state.customStyles.templateBody;
    let secondCheck = '';
    if (
      Object.keys(this.state.templateParamsTest).length === 0 &&
      this.state.templateParamsTest.constructor === Object
    ) {
      newState.variableDescriptionData.length > 0
        ? newState.variableDescriptionData.forEach(item => {
          if (item.variableMapping) {
            secondCheck = firstCheck
              .split(`{{${item.variableName}}}`)
              .join(`<span id='underlined'>${item.variableMapping}</span>`);
            firstCheck = secondCheck;
          } else {
            secondCheck = firstCheck;
          }
        })
        : (secondCheck = firstCheck);
    } else {
      let newTemplateBody = '';
      newState.variableDescriptionData.length > 0
        ? newState.variableDescriptionData.forEach(item => {
          if (item.variableMapping) {
            secondCheck = firstCheck
              .split(`{{${item.variableName}}}`)
              .join(`<span id='underlined'>${item.variableMapping}</span>`);
            firstCheck = secondCheck;
          } else {
            secondCheck = firstCheck;
          }
        })
        : (secondCheck = firstCheck);
      newState.variableDescriptionData.length > 0 &&
        newState.variableDescriptionData.forEach(item => {
          for (const property in this.state.templateParamsTest) {
            if (item.variableName === property) {
              newTemplateBody = secondCheck
                .split(item.variableMapping)
                .join(`<span id='underlined'>${this.state.templateParamsTest[property]}</span>`);
            } else {
              secondCheck = firstCheck;
            }
            secondCheck = newTemplateBody;
          }
        });
    }
    const regex = /(<([^>]+)>)/gi;
    const exactLength = secondCheck.replace(regex, '');
    return {
      __html: `<div form-control-label preview-label float-right>Total Count: ${exactLength.length}</p>`,
    };
  };

  handleCheckedValue = key => {
    let checkedValue = false;
    if (key === 'hasAttachment') {
      this.state.customStyles.hasAttachment === 'Y' ? (checkedValue = true) : (checkedValue = false);
    } else if (key === 'isActive') {
      this.state.customStyles.isActive === 'ENABLED' ? (checkedValue = true) : (checkedValue = false);
    }
    return checkedValue;
  };

  handleSelectChannelToTest = value => {
    let newState = this.state.customStyles;
    this.setState({
      selectedChannelToTestState: 'valid',
      selectedChannelToTest: value,
    });
    if (newState.contactOrTokenNumber.trim().length !== 0) {
      const mobileValidation = /^\d*(?:\.\d{1,2})?$/;
      if (value === 'SMS' || value === 'WHATSAPP') {
        if (
          newState.contactOrTokenNumber === '' ||
          newState.contactOrTokenNumber.trim().length === 0 ||
          newState.contactOrTokenNumber.trim().length !== 10 ||
          !newState.contactOrTokenNumber.match(mobileValidation)
        ) {
          newState.contactOrTokenState = 'invalid';
        } else {
          newState.contactOrTokenState = 'valid';
        }
      } else if (value === 'EMAIL') {
        const emailRegEx = /\S+@\S+\.\S+/;
        const isValid = newState.contactOrTokenNumber.match(emailRegEx);

        if (newState.contactOrTokenNumber === '' || newState.contactOrTokenNumber.trim().length === 0 || !isValid) {
          newState.contactOrTokenState = 'invalid';
        } else {
          newState.contactOrTokenState = 'valid';
        }
      } else if (value === 'FCM') {
        if (newState.contactOrTokenNumber === '' || newState.contactOrTokenNumber.trim().length === 0) {
          newState.contactOrTokenState = 'invalid';
        } else {
          newState.contactOrTokenState = 'valid';
        }
      }
    }
  };
  
  checkAttachment=(array, obj)=> {
    for (const item of array) {
        const variableName = item.variableName;
        if (!obj.hasOwnProperty(variableName)) {
            return false;
        }
        const value = obj[variableName];
        if (!value || value.trim() === "") {
            return false;
        }
    }
    return true;
}

 
  handleTestValue = (e, variableType, action, channelName) => {
    this.validateCustomStylesForm();
    let newState = this.state.customStyles;
    let Isvalid = false;
    const templateParamsTest = { ...this.state.templateParamsTest };
    const attachmentLinks = {...this.state.attachmentLinks}
    if(variableType == "AttachmentLink" && action == "test" && channelName == "EMAIL"){
      attachmentLinks[e.target.name * 1] = e.target.value;
    } else {
      templateParamsTest[e.target.name * 1] = e.target.value;
    }
    let isEmpty = true;
    for (const property in templateParamsTest) {
      if (templateParamsTest[property].length === 0) {
        isEmpty = false;
      }
    }
    let isEmptylink = true;
    for (const property in attachmentLinks) {
      if (attachmentLinks[property].length === 0) {
        isEmptylink = false;
      }
    }
    const newObjs= {...attachmentLinks, ...templateParamsTest}
  
    // newState.variableDescriptionData.length > 0
    //   ? newState.variableDescriptionData.forEach(item => {
    //       Isvalid = templateParamsTest.hasOwnProperty(item.variableName) && isEmpty
    //   })
    //   : (Isvalid = true);
    
    Isvalid = this.checkAttachment(newState.variableDescriptionData, newObjs);
    this.setState({
      templateParamsTest: templateParamsTest,
      attachmentLinks: attachmentLinks,
    }, () => this.getHtmlWithVariables());
    if (Isvalid === true) {
      this.setState({
        templateParamsTestState: 'valid',
      });
    } else {
      this.setState({
        templateParamsTestState: 'invalid',
      });
    }
  };

  handleAddMore = () =>{
    const variableDataCopy = [...this.state.customStyles.variableDescriptionData];
    const lastItem = variableDataCopy[variableDataCopy.length-1];
    const lastItemDataName = parseInt(lastItem?.variableName)+1;
    const newObject = [{...lastItem, variableName:String(lastItemDataName)}]
    this.setState({ ...this.state,customStyles:{
      ...this.state.customStyles, 
      variableDescriptionData: [...this.state.customStyles.variableDescriptionData,...newObject]
    }})
  }
  
  handleProceedTest = () => {
    this.validateCustomStylesForm();
    // console.log('templateParamsTest', this.state.templateParamsTest);
    const formData = this.state.customStyles;

    if (
      formData.templateNameState === 'valid' &&
      formData.templateDescState === 'valid' &&
      formData.templateBodyState === 'valid' &&
      formData.selectedChannelState === 'valid' &&
      formData.selectedChannelTemplateId === 'valid' &&
      formData.variableDescriptionState === 'valid' &&
      formData.contactOrTokenState === 'valid' &&
      this.state.templateParamsTestState === 'valid'
      // && (this.state.customEnabled ? this.state.customParamsTestState==='valid' : true)
    ) {
      // console.log('everything is valid');
      let apiEndPoint = '';
      let reqBody = {};
      let sourceApi = '';
      this.setState({
        loader: true,
        testButtonActive: false,
      });
      if (this.state.selectedChannelToTest === 'SMS') {
        apiEndPoint = `${templateBaseurl2}/sendSMS`;
        sourceApi = 'PhoenixTestNotificationApi';
        reqBody = {
          type: 'SMS',
          source: sourceApi,
          requestType: formData.requestType,
          externalRefId: Date.now(),
          message: '',
          toMobNumber: formData.contactOrTokenNumber,
          templateId: formData.templateId,
          hasAttachment: formData.hasAttachment,
          templateParams: this.state.templateParamsTest,
        };
      } else if (this.state.selectedChannelToTest === 'WHATSAPP') {
        apiEndPoint = `${templateBaseurl2}/sendWhatsAppMessage`;
        sourceApi = 'PhoenixTestNotificationApi';
        reqBody = {
          type: 'WHATSAPP',
          source: sourceApi,
          requestType: formData.requestType,
          externalRefId: Date.now(),
          message: 'hit testing 2nd case.',
          toMobNumber: formData.contactOrTokenNumber,
          templateId: formData.templateId,
          hasAttachment: formData.hasAttachment,
          templateParams: this.state.templateParamsTest,
        };
      } else if (this.state.selectedChannelToTest === 'FCM') {
        apiEndPoint = `${templateBaseurl2}/sendPushNotification`;
        sourceApi = 'ERP-Payments';
        reqBody = {
          type: 'FCM',
          source: sourceApi,
          requestType: formData.requestType,
          externalRefId: Date.now(),
          message: 'hit testing 2nd case.',
          template: formData.templateBody,
          deviceId: formData.contactOrTokenNumber,
          templateId: formData.templateId,
          hasAttachment: formData.hasAttachment,
          templateParams: this.state.templateParamsTest,
          // customTemplateParams: this.state.customParamsTest
        };
      } else if (this.state.selectedChannelToTest === 'WEBPUSH') {
        apiEndPoint = `${templateBaseurl2}/sendWebPushNotification`;
        sourceApi = 'ERP-Payments';
        reqBody = {
          type: 'WEBPUSH',
          source: sourceApi,
          requestType: formData.requestType,
          externalRefId: Date.now(),
          message: 'hit testing 2nd case.',
          template: formData.templateBody,
          employeeId: formData.contactOrTokenNumber,
          templateId: formData.templateId,
          hasAttachment: formData.hasAttachment,
          templateParams: this.state.templateParamsTest,
          // customTemplateParams: this.state.customParamsTest
        };
      } else if (this.state.selectedChannelToTest === 'EMAIL') {
        apiEndPoint = `${templateBaseurl2}/sendMail`;
        sourceApi = 'PhoenixTestNotificationApi';
        reqBody = {
          type: 'EMAIL',
          source: sourceApi,
          requestType: formData.requestType,
          externalRefId: Date.now(),
          message: 'hit testing 2nd case.',
          body: 'Aesl',
          to: [formData.contactOrTokenNumber],
          subject: 'Subject',
          cc: [],
          bcc: [],
          templateId: formData.templateId,
          hasAttachment: formData.hasAttachment,
          templateParams: this.state.templateParamsTest,
          attachmentLinks: Object.values(this.state.attachmentLinks)
        };
      }

      putpost(
        apiEndPoint,
        result => {
          const currentState = { ...this.state };
          // currentState.isAlert = true;
          // currentState.alertMessage = result.message
          currentState.templateModal = true;
          currentState.templateModalMessage = result.message;
          currentState.testSuccessId = result?.data ?? null
          currentState.loader = false;
          currentState.testSuccessId = result?.data ?? null
          currentState.testButtonActive = false;
          if (result.code === 200 || result.code === 201 || result.code === 203 || result.code === 204) {
            currentState.alertColor = 'success';
          } else {
            currentState.alertColor = 'danger';
          }
          this.setState(currentState);
        },
        data => {
          const currentState = { ...this.state };
          currentState.loader = false;
          currentState.testSuccessId = null
          this.setState(currentState);
          failureToast(data['message']);
        },
        reqBody,
        'post'
      );
    }
    // console.log('finished handleProceedTest')
  };

  // addCustomVariableRow = () => {
  //   const customData = [...this.state.customStyles.customVariableDescriptionData];
  //   customData.push({
  //     variableName: customData.length + 1,
  //     variableMapping: null, 
  //     variableDesc: '',
  //     variableType: null,
  //   })

  //   this.setState({...this.state, customStyles: {
  //     ...this.state.customStyles,
  //     customVariableDescriptionData: customData
  //   }}, () => {
  //     const isValid = this.isCustomVariableDescriptionDataValid() ? 'valid' : 'invalid';
  //     this.setState({...this.state, customStyles: {...this.state.customStyles, customVariableDescriptionState: isValid}})
  //   })
  // }

  // removeCustomVariableRow = (index) => {
  //   let customData = [...this.state.customStyles.customVariableDescriptionData];
  //   customData.splice(index, 1);
  //   customData = customData.map((el, idx) => {
  //     return {...el, variableName: idx+1}
  //   })
  //   this.setState({...this.state, customStyles: {
  //     ...this.state.customStyles,
  //     customVariableDescriptionData: customData
  //   }})
  // }

  // customVariableInputHandler = (index, value, key, type='data') => {
  //   if(type==='data'){
  //     const newCustomVariableDescriptionData = this.state.customStyles.customVariableDescriptionData;
  //     newCustomVariableDescriptionData[index][key] = value;

  //     this.setState({...this.state, customStyles: {...this.state.customStyles, customVariableDescriptionData: newCustomVariableDescriptionData}}, () => {
  //       const isValid = this.isCustomVariableDescriptionDataValid() ? 'valid' : 'invalid'
  //       this.setState({...this.state, customStyles: {...this.state.customStyles, customVariableDescriptionState: isValid}})
  //     })
  //   } else if(type==='test'){
  //     const newCustomParamsTest = {...this.state.customParamsTest};
  //     newCustomParamsTest[key] = value;
  //     this.setState({...this.state, customParamsTest: newCustomParamsTest}, () => {
  //       const isValid = this.isCustomVariableDescriptionDataValid('test') ? 'valid' : 'invalid'
  //       this.setState({...this.state, customParamsTestState: isValid})
  //     });
  //   }
  // }

  // isCustomVariableDescriptionDataValid = (type='data') => {
  //   if(type==='data'){
  //     const customData = this.state.customStyles.customVariableDescriptionData;
  //     let isValid = true;
  //     customData.forEach((obj, idx) => {
  //       if (!obj.variableType || !obj.variableDesc || !obj.variableMapping) {
  //         isValid = false;
  //       }
  //     })
  //     return isValid;
  //   } else if(type==='test'){
  //     const customParams = this.state.customParamsTest;

  //     for(const key in customParams){
  //       if(!customParams[key] || customParams[key]==='') {return false}
  //     }

  //     return true;
  //   }
  // }
  getMinMaxFrequency(key){
    return{
     minFreq:this.state.NotificationChannels?.[key]?.Ratelimit?.LimitFrequencyMin,
     maxFreq:this.state.NotificationChannels?.[key]?.Ratelimit?.LimitFrequencyMax
    }
    
  }

  render() {
    const selectedChannelData =
      this.state.customStyles.selectedChannelData.length > 0 && this.state.customStyles.selectedChannelData;
    const variableDescriptionData =
      this.state.customStyles.variableDescriptionData.length > 0 && this.state.customStyles.variableDescriptionData;
    // const customVariableDescriptionData = this.state.customStyles.customVariableDescriptionData.length > 0 && this.state.customStyles.customVariableDescriptionData;
    const ONLYSHOWTEMPLATE = this.props.location.state && this.props.location.state.onlyShowTemplate;
    const createTemplate = this.state.createTemplate;
    const NotificationChannels = this.state.NotificationChannels;
    const ChannelCategoryNotification = this.state.ChannelCategoryNotification;
    const ChannelAccounts = this.state.ChannelAccounts;
    const MappingForVariable = this.state.MappingForVariable;
    const ChannelCategoryBodyLength = this.state.ChannelCategoryBodyLength;
    const channelName = this.state.customStyles?.selectedChannelData[0]?.channelName;
    const TypeOfVariable = channelName == "EMAIL" ? [...this.state.TypeOfVariable, "TableObject"] : [...this.state.TypeOfVariable];
    const CategorySMS = this.state.CategorySMS;
    const DepartmentList = this.state.DepartmentList;
    const DepartmentCategoryChannel = this.state.DepartmentCategoryChannel;
    const Action = this.props.location.state && this.props.location.state.action;
    // const NotificationChannels=this.state.NotificationChannels
    return (
      <>
        {/* {!this.state.customStyles.isEdit && !ONLYSHOWTEMPLATE && <SimpleHeader parentName="Create Template" />}
        {this.state.customStyles.isEdit && !ONLYSHOWTEMPLATE && <SimpleHeader parentName="Update Template" />} */}
        {this.state.loader && <div className="loader"></div>}
        {/* {ONLYSHOWTEMPLATE && <SimpleHeader parentName="View Template" />} */}
        <Container className="mt 1" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                {Action === 'test' && this.state.loader && <div class="loader"></div>}
                <Card className="template-card">
                  {(ONLYSHOWTEMPLATE || this.state.customStyles.isEdit) && (
                    <CardHeader>
                      {/* {Action !== 'test' && ( */}
                      <Row>
                        <Col sm="8">
                          <b>
                            Template ID - {this.state.customStyles.templateId && this.state.customStyles.templateId}
                          </b>
                        </Col>
                      </Row>
                      {/* )} */}
                    </CardHeader>
                  )}
                  <CardBody>
                    <Row>
                      <Col sm="8">
                        <TestTemplateModal
                          color={this.state.alertColor}
                          templateModal={this.state.templateModal}
                          templateModalMessage={this.state.templateModalMessage}
                          testSuccessId={this.state.testSuccessId}
                          setTemplateModal={value => this.setState({ templateModal: value })}
                        />
                        {this.state.isAlert && (
                          <UncontrolledAlert
                            color={this.state.alertColor}
                            isOpen={this.state.isAlert}
                            toggle={this.onDissmiss}
                            className="alertMessage"
                          >
                            {this.state.alertColor === 'success' && (
                              <span className="alert-inner--icon">
                                <i className="ni ni-like-2" />
                              </span>
                            )}
                            {this.state.alertColor === 'danger' && (
                              <span className="alert-inner--icon">
                                <i className="fas fa-exclamation-triangle" />
                              </span>
                            )}{' '}
                            <strong>{this.state.alertMessage}!</strong>
                          </UncontrolledAlert>
                        )}
                        {/* <Link to={{ pathname: `/`}} >
                                                <p className='form-control-label'> 
                                                  <i class="fas fa-arrow-left"></i>&nbsp;&nbsp;Back
                                                </p>
                                                </Link> */}
                        <Form className="needs-validation" noValidate>
                          <div className="form-row">
                            <Col className="mb-3" md="6">
                              <label
                                className={
                                  Action === 'test' ? 'form-control-label' : 'form-control-label required-field'
                                }
                                htmlFor="validationCustom01"
                              >
                                Template Name
                              </label>
                              <Input
                                id="validationCustom01"
                                placeholder="Template Name"
                                maxLength="150"
                                type="text"
                                value={this.state.customStyles.templateName}
                                valid={this.state.customStyles.templateNameState === 'valid'}
                                invalid={this.state.customStyles.templateNameState === 'invalid'}
                                onChange={e => this.customStylesForm(e, 'templateName')}
                                disabled={ONLYSHOWTEMPLATE}
                              />
                              {this.state.customStyles.templateNameState==='invalid'&&<div className="invalid-feedback">Please choose a Template Name.</div>}
                            </Col>
                            <Col className="mb-3" md="6">
                              <label
                                className={
                                  Action === 'test' ? 'form-control-label' : 'form-control-label required-field'
                                }
                                htmlFor="validationCustom22"
                              >
                                Template Category
                              </label>
                              <Input
                                id="validationCustom22"
                                type="select"
                                value={this.state.customStyles.templateCategoryId}
                                valid={this.state.customStyles.templateCategoryState === 'valid'}
                                invalid={this.state.customStyles.templateCategoryState === 'invalid'}
                                onChange={e => this.customStylesForm(e, 'templateCategoryId')}
                                disabled={ONLYSHOWTEMPLATE}
                              >
                                <option></option>
                                {this.state.categoryData.map(item => {
                                  return <option value={item.value} key={item.value}>{item.label}</option>
                                })}
                              </Input>
                              {this.state.customStyles.templateCategoryState==='invalid'&&<div className="invalid-feedback">Please choose Template Category.</div>}
                            </Col>
                            <Col className="mb-3" md="12">
                              <label
                                className={
                                  Action === 'test' ? 'form-control-label' : 'form-control-label required-field'
                                }
                                htmlFor="validationCustom02"
                              >
                                Template Description
                              </label>
                              <Input
                                id="validationCustom02"
                                placeholder="Template Description"
                                maxLength="500"
                                type="text"
                                value={this.state.customStyles.templateDesc}
                                valid={this.state.customStyles.templateDescState === 'valid'}
                                invalid={this.state.customStyles.templateDescState === 'invalid'}
                                onChange={e => this.customStylesForm(e, 'templateDesc')}
                                disabled={ONLYSHOWTEMPLATE}
                              />
                              <div className="invalid-feedback">Please choose Template Description.</div>
                            </Col>
                            {Action === 'test' && selectedChannelData.length > 0 && (
                              <>
                                <Col md="12" className="mb-3">
                                  <label className="form-control-label required-field" htmlFor="example3cols2Input">
                                    Select the Channel for Test Notifications :
                                  </label>
                                  {this.state.selectedChannelToTestState === 'invalid' && (
                                    <div style={{ fontSize: '80%', color: '#fb6340' }}>
                                      Please choose a channel to test
                                    </div>
                                  )}
                                </Col>

                                {Object.keys(NotificationChannels).map(
                                  (key, item) =>
                                    (key === 'SMS' || key === 'WHATSAPP' || key === 'FCM' || key === 'EMAIL' || key === 'WEBPUSH') &&
                                    NotificationChannels[key].enabled &&
                                    selectedChannelData.some(item => item.channelName === key) &&
                                    selectedChannelData.some(item => item.channelName === key) && (
                                      <>
                                        <Row className="mb-3">
                                          <Col className="custom-control custom-radio" md="12"> 
                                            <input
                                              type="radio"
                                              className="custom-control-input"
                                              id={key}
                                              checked={this.state.selectedChannelToTest === key}
                                              name="custom-radio-1"
                                              onChange={e => this.handleSelectChannelToTest(key)}
                                            />
                                            <label for={key} className="custom-control-label channelLabel">
                                              {NotificationChannels[key].display}
                                            </label>
                                          </Col>
                                          {/* <Col className="mb-3" md="6">
                                          <Input
                                            type="text"
                                            id={selectedChannelData.filter(i => i.channelName === key)?.[0]?.['channelTemplateAccount'] ?? key}
                                            defaultValue={selectedChannelData.filter(i => i.channelName === key)?.[0]?.['channelTemplateAccount'] ?? ''}
                                            name="custom-channel-account"
                                            onChange={e => this.handleSelectChannelToTest(key)}
                                            disabled
                                            placeholder="Channel Template Account"
                                          />
                                          </Col> */}
                                          
                                              <Col className="mb-3" md="6">
                                                <label
                                                  className={
                                                    Action === 'test' ? 'form-control-label' : 'form-control-label required-field'
                                                  }
                                                  htmlFor="validationCustom22"
                                                >
                                                  Channel Template Account
                                                </label>
                                                <Input
                                                  id="validationCustom22"
                                                  type="text"
                                                  // name={key}
                                                  // placeholder={`${key} Template Id Required`}
                                                  // placeholder="Channel Template Account"
                                                  value={selectedChannelData.filter(i => i.channelName === key)?.[0]?.['channelTemplateAccount']}
                                                  // valid={this.state.customStyles.selectedChannelTemplateAccount === 'valid'}
                                                  // invalid={this.state.customStyles.selectedChannelTemplateAccount === 'invalid'}
                                                  // onChange={e => this.customStylesForm(e, 'channelTemplateAccount')}
                                                  disabled
                                                >
                                                  
                                                </Input>
                                              </Col>
                                              {(selectedChannelData.filter(i => i.channelName === key)?.[0]?.['templateApproved']) && (
                                                <Col className="mb-3 pt-4" md="6">
                                                  <div className="custom-control custom-checkbox">
                                                    <Input
                                                      type="checkbox"
                                                      id={`${key}-templateApprovedd`}
                                                      // name={key}
                                                      checked={

                                                        true
                                                      }
                                                      className="custom-control-input"
                                                      disabled
                                                    // disabled={ONLYSHOWTEMPLATE || (this.state.isHtmlInBody && key !== 'EMAIL')}
                                                    // disabled={ONLYSHOWTEMPLATE && Action !== 'test'}
                                                    />
                                                    <label htmlFor={`${key}-templateApprovedd`} className="custom-control-label channelLabel">
                                                      {NotificationChannels[key]?.isTemplateApprovalRequired?.displayText}
                                                    </label>
                                                  </div>

                                                </Col>
                                              )}   {(selectedChannelData.filter(i => i.channelName === key)?.[0]?.['channelTemplateId']) &&
                                                <Col className="mb-3" md="6">
                                                   <label
                                                  className={
                                                    'form-control-label'
                                                  }
                                                  htmlFor="channelTemplateID"
                                                >
                                                  Channel Template Id
                                                </label>
                                                  <Input
                                                    type="text"
                                                    id='channelTemplateID'
                                                    // name={key}
                                                    // maxlength="250"
                                                    // placeholder={`${key} Template Id Required`}
                                                    // onChange={e => this.customStylesForm(e, 'channelTemplateId')}
                                                    value={selectedChannelData.filter(i => i.channelName === key)?.[0]?.['channelTemplateId'] ?? ''}
                                                    disabled
                                                  // disabled={isEditing}
                                                  />
                                                </Col>
                                              }
                                              {(Boolean(selectedChannelData.filter(i => i.channelName === key)?.[0]?.['userDeliveryFrequency'])==true) &&
                                                <Col className="mb-3" md="6">
                                                  <label
                                                  className={
                                                    'form-control-label'
                                                  }
                                                  htmlFor="validationCustom87"
                                                >
                                                  User Delivery Frequency
                                                </label>
                                                  <Input
                                                    id="validationCustom87"
                                                    type="number"
                                                    name={key}
                                                    // placeholder={'Enter user delivery frequency'}
                                                    // onChange={e => this.customStylesForm(e, 'userDeliveryFrequency')}
                                                    value={selectedChannelData.filter(i => i.channelName === key)?.[0]?.['userDeliveryFrequency']}
                                                    disabled
                                                  />
                                                </Col>
                                              }

                                        </Row>
                                      </>
                                    )
                                )}
                              </>
                            )}
                            <Col md="12"></Col>
                            {Action === 'test' && this.state.selectedChannelToTest !== '' ? (
                              <>
                                <Col className="mb-3" md="6">
                                  <label className="form-control-label required-field" htmlFor="validationCustom01">
                                    {this.state.selectedChannelToTest === 'FCM' && 'Enter the Device Token:'}
                                    {this.state.selectedChannelToTest === 'WEBPUSH' && 'Enter the Employee Id:'}
                                    {this.state.selectedChannelToTest === 'EMAIL' && 'Enter the Email Id:'}
                                    {(this.state.selectedChannelToTest === 'SMS' ||
                                      this.state.selectedChannelToTest === 'WHATSAPP') &&
                                      'Enter the Contact No:'}
                                  </label>
                                  <Input
                                    id="exampleFormControlTextarea1"
                                    className="messageTextArea"
                                    rows="3"
                                    type="text"
                                    maxLength={
                                      (this.state.selectedChannelToTest === 'SMS' ||
                                        this.state.selectedChannelToTest === 'WHATSAPP') &&
                                      '10'
                                    }
                                    minlength={
                                      (this.state.selectedChannelToTest === 'SMS' ||
                                        this.state.selectedChannelToTest === 'WHATSAPP') &&
                                      '10'
                                    }
                                    value={this.state.customStyles.contactOrTokenNumber}
                                    valid={this.state.customStyles.contactOrTokenState === 'valid'}
                                    invalid={this.state.customStyles.contactOrTokenState === 'invalid'}
                                    onChange={e =>
                                      this.customStylesForm(e, 'contactOrTokenNumber', this.state.selectedChannelToTest)
                                    }
                                  // disabled={ONLYSHOWTEMPLATE}
                                  />
                                  <div className="invalid-feedback">
                                    {this.state.selectedChannelToTest === 'FCM' && 'Please enter a valid Device Token'}
                                    {this.state.selectedChannelToTest === 'WEBPUSH' && 'Please enter a valid Employee id'}
                                    {this.state.selectedChannelToTest === 'EMAIL' && 'Please enter a valid Email Id'}
                                    {(this.state.selectedChannelToTest === 'SMS' ||
                                      this.state.selectedChannelToTest === 'WHATSAPP') &&
                                      'Please enter a valid Contact No'}
                                  </div>
                                </Col>
                                <Col className="mb-3" md="6"></Col>
                              </>
                            ) : (
                              null
                            )}
                            {Action !== 'test' &&
                              // console.log('DepartmentList', DepartmentList)
                              DepartmentList && (
                                <Col className="mb-3 pl-0" md="4" xs="4">
                                  <UncontrolledDropdown disabled={ONLYSHOWTEMPLATE} className="ml-0">
                                    <DropdownToggle caret color="primary">
                                      {this.state.customStyles.departmentName !== ''
                                        ? DepartmentList.find(item => item.Key === this.state.customStyles.departmentName) &&
                                        DepartmentList.find(item => item.Key === this.state.customStyles.departmentName)
                                          .Display
                                        : 'Department'}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {DepartmentList.map(item => (
                                        <DropdownItem
                                          value={item.Key}
                                          name={item.Key}
                                          key={item.Key}
                                          //removed body length will add with channel
                                          // onClick={e => this.customStylesForm(e, 'requestType', item.BodyLength)}
                                          onClick={e => this.customStylesForm(e, 'departmentName')}
                                        >
                                          {item.Display}
                                        </DropdownItem>
                                      ))}
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                  {this.state.customStyles.departmentNameState === 'invalid' && (
                                    <div style={{ fontSize: '80%', color: '#fb6340' }}>
                                      Please Select the Department
                                    </div>
                                  )}
                                </Col>
                              )}

                            {Action !== 'test' && this.state.customStyles.departmentName &&
                              CategorySMS && (
                                <Col className="mb-3 pl-8" md="6" xs="4">
                                  <UncontrolledDropdown disabled={ONLYSHOWTEMPLATE} className="ml-0">
                                    <DropdownToggle caret color="primary">
                                      {this.state.customStyles.requestType !== ''
                                        ? CategorySMS.find(item => item.Key === this.state.customStyles.requestType) &&
                                        CategorySMS.find(item => item.Key === this.state.customStyles.requestType)
                                          .Display
                                        : 'Select Channel Category'}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {CategorySMS.map(item => {
                                        if ((this.state.DepartmentCategory?.[this.state.customStyles.departmentName] ?? []).includes(item.Key)) {
                                          return (
                                            <DropdownItem
                                              value={item.Key}
                                              name={item.Key}
                                              key={item.Key}
                                              //removed body length will add with channel
                                              // onClick={e => this.customStylesForm(e, 'requestType', item.BodyLength)}
                                              onClick={e => this.customStylesForm(e, 'requestType')}
                                            >
                                              {item.Display}
                                            </DropdownItem>
                                          )
                                        }
                                      })}
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                  {this.state.customStyles.requestTypeState === 'invalid' && (
                                    <div style={{ fontSize: '80%', color: '#fb6340' }}>
                                      Please Select the Channel Category
                                    </div>
                                  )}
                                </Col>
                              )}
                            {Object.keys(createTemplate).map(
                              (key, i) =>
                                key === 'hasAttachment' && this.state.customStyles.requestType &&
                                createTemplate[key].enabled && (
                                  <Col className="mb-3" md="4" xs="4">
                                    <label className="form-control-label" htmlFor="validationCustom01">
                                      {createTemplate[key].display}
                                    </label>
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        name={key}
                                        defaultChecked={createTemplate[key].default}
                                        checked={
                                          // this.state.customStyles.isEdit ?
                                          this.handleCheckedValue(key)
                                          // :createTemplate[key].default
                                        }
                                        id={key}
                                        className="custom-control-input"
                                        onChange={e => this.customStylesForm(e, key)}
                                        disabled={ONLYSHOWTEMPLATE}
                                      />
                                      <label htmlFor={key} className="custom-control-label channelLabel">
                                        Yes
                                      </label>
                                    </div>
                                  </Col>
                                )
                            )}
                            {/* {console.log(createTemplate)} */}
                            {Object.keys(createTemplate).map(
                              (key, i) =>
                                key === 'isActive' && this.state.customStyles.requestType &&
                                createTemplate[key].enabled && (
                                  <Col className="mb-3 pl-9" md="4" xs="4">
                                    <label className="form-control-label" htmlFor="validationCustom01">
                                      {createTemplate[key].display}
                                    </label>
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        name={key}
                                        defaultChecked={this.state.templateStatus}
                                        checked={this.handleCheckedValue(key)}
                                        id={key}
                                        className="custom-control-input cfg-1"
                                        onChange={e => this.customStylesForm(e, key)}
                                        disabled={
                                          this.state.customStyles.isEdit
                                            ? (ONLYSHOWTEMPLATE||Action==='test')
                                            : !createTemplate.AllowEnableTemplateWhileCreation
                                        }
                                      />
                                      <label htmlFor={key} className="custom-control-label channelLabel">
                                        Yes
                                      </label>
                                    </div>
                                  </Col>
                                )
                            )}



                            {Action !== 'test' && this.state.customStyles.requestType && (
                              <Col md="12">
                                <label className="form-control-label required-field" htmlFor="example3cols2Input">
                                  Select the Channel :
                                </label>
                                {this.state.customStyles.selectedChannelState === 'invalid' && (
                                  <div style={{ fontSize: '80%', color: '#fb6340' }}>Please Select the Channel</div>
                                )}
                              </Col>
                            )}
                            {Action !== 'test' && this.state.customStyles.requestType &&
                              (DepartmentCategoryChannel?.[this.state.customStyles.requestType]?.[this.state.customStyles.departmentName] ?? []).map(
                                (key, item) =>

                                  (key === 'SMS' || key === 'WHATSAPP' || key === 'FCM' || key === 'EMAIL' || key === 'WEBPUSH') &&
                                  NotificationChannels[key]?.enabled && <Col className="mb-3" md="12" style={{ borderBottom: 'solid 1px rgba(128, 128, 128, 0.3)' }} key={key}>
                                    <div className="custom-control custom-checkbox">
                                      <div className="invalid-feedback">Please Select the Channel</div>
                                      <Input
                                        type="checkbox"
                                        id={key}
                                        name={key}
                                        checked={
                                          ((selectedChannelData.length > 0 &&
                                          selectedChannelData.find(i => i.channelName === key))||false)
                                          
                                        }
                                        className="custom-control-input"
                                        onChange={e => this.customStylesForm(e, 'channelName')}
                                        disabled={ONLYSHOWTEMPLATE || (this.state.isHtmlInBody && key !== 'EMAIL')}
                                      // disabled={ONLYSHOWTEMPLATE && Action !== 'test'}
                                      />
                                      <label htmlFor={key} className="custom-control-label channelLabel">
                                        {NotificationChannels[key]?.display}
                                      </label>
                                    </div>
                                    {selectedChannelData.length > 0
                                      ? selectedChannelData.map(
                                        item2 =>
                                          item2.channelName === key && (
                                            <Row key={item2.channelName}>
                                              {/* <Col className="mb-3" md="6">
                                                <label
                                                  className={
                                                    Action === 'test' ? 'form-control-label' : 'form-control-label required-field'
                                                  }
                                                  htmlFor="validationCustom22"
                                                >
                                                  Channel Template Account
                                                </label>
                                                <Input
                                                  id="validationCustom22"
                                                  type="text"
                                                  name={key}
                                                  // placeholder={`${key} Template Id Required`}
                                                  placeholder="Channel Template Account"
                                                  value={item2.channelTemplateAccount}
                                                  valid={this.state.customStyles.selectedChannelTemplateAccount === 'valid'}
                                                  invalid={this.state.customStyles.selectedChannelTemplateAccount === 'invalid'}
                                                  onChange={e => this.customStylesForm(e, 'channelTemplateAccount')}
                                                  disabled={ONLYSHOWTEMPLATE}
                                                >
                                                  
                                                </Input>
                                                <div className="invalid-feedback">Please choose Channel Account.</div>
                                              </Col> */}
                                              {this.state.CategoryChannelDepartmentAccounts?.[this.state.customStyles.requestType]?.[key]?.[this.state.customStyles.departmentName]?.DisplayText &&
                                                <Col className="mt-2 font-italic"  md="12">
                                                  {this.state.CategoryChannelDepartmentAccounts?.[this.state.customStyles.requestType]?.[key]?.[this.state.customStyles.departmentName]?.DisplayText
                                                  }
                                                </Col>
                                              }
                                              {(NotificationChannels[key]?.isTemplateApprovalRequired?.enabled) && (
                                                <Col className="mt-2" md="12">
                                                  <div className="custom-control custom-checkbox">
                                                    <Input
                                                      type="checkbox"
                                                      id={`${key}-templateApproved`}
                                                      name={key}
                                                      checked={

                                                        (selectedChannelData.length > 0 &&
                                                        selectedChannelData.find(i => i.channelName=== key)?.templateApproved)
                                                      }
                                                      className="custom-control-input"
                                                      disabled={ONLYSHOWTEMPLATE}
                                                      onChange={e => {
                                                        // console.log('abcd');
                                                        this.customStylesForm(e, 'templateApproved')
                                                      }}
                                                    // disabled={ONLYSHOWTEMPLATE || (this.state.isHtmlInBody && key !== 'EMAIL')}
                                                    // disabled={ONLYSHOWTEMPLATE && Action !== 'test'}
                                                    />
                                                    <label htmlFor={`${key}-templateApproved`} className="custom-control-label channelLabel">
                                                      {NotificationChannels[key]?.isTemplateApprovalRequired?.displayText}
                                                    </label>
                                                    {(item2.templateApproved===false)&& (
                                                      <div style={{ fontSize: '80%', color: '#fb6340' }}>
                                                        Please confirm template approval
                                                      </div>
                                                    )}
                                                  </div>

                                                </Col>
                                              )}   {(this.state.customStyles.selectedChannelData.find(i => i.channelName === key)?.templateApproved || !(NotificationChannels[key]?.isTemplateApprovalRequired?.enabled)) &&
                                                <Col className="mb-3 mt-2" md="6">
                                                   <label
                                                  className={
                                                    Action === 'test' ? 'form-control-label' : 'form-control-label required-field'
                                                  }
                                                  htmlFor={`${key}-TemplateId`}
                                                >
                                                  Channel Template Id
                                                </label>
                                                  <Input
                                                    id={`${key}-TemplateId`}
                                                    type="text"
                                                    name={key}
                                                    maxLength="250"
                                                    placeholder={`${key} Template Id Required`}
                                                    onChange={e => this.customStylesForm(e, 'channelTemplateId')}
                                                    value={item2.channelTemplateId}
                                                    disabled={ONLYSHOWTEMPLATE}
                                                  // disabled={isEditing}
                                                  />
                                                  {(item2.channelTemplateId==='')&& (
                                                      <div style={{ fontSize: '80%', color: '#fb6340' }}>
                                                        Please Enter the Template Id
                                                      </div>
                                                    )}
                                                </Col>
                                              }
                                              {(this.state.customStyles.selectedChannelData.find(i => i.channelName === key)?.templateApproved || !(NotificationChannels[key]?.isTemplateApprovalRequired?.enabled)) &&
                                                <Col className="mb-3 mt-2" md="6">
                                                  <label
                                                  className={
                                                    Action === 'test' ? 'form-control-label' : 'form-control-label required-field'
                                                  }
                                                  htmlFor={`${key}-UserDeliveryFrequency`}
                                                >
                                                  User Delivery Frequency
                                                </label>
                                                  <Input
                                                    id={`${key}-UserDeliveryFrequency`}
                                                    type="number"
                                                    name={key}
                                                    placeholder={'User delivery frequency Required'}
                                                    onChange={e => this.customStylesForm(e, 'userDeliveryFrequency')}
                                                    value={item2.userDeliveryFrequency}
                                                    disabled={ONLYSHOWTEMPLATE}
                                                  />
                                                  {((item2.userDeliveryFrequency!=='')&&(item2?.userDeliveryFrequency<this.getMinMaxFrequency(key)?.minFreq
                                                  ||item2?.userDeliveryFrequency>this.getMinMaxFrequency(key)?.maxFreq)) && (
                                                      <div style={{ fontSize: '80%', color: '#fb6340' }}>
                                                        {`Enter frequency between ${
                                                          this.getMinMaxFrequency(key)?.minFreq
                                                        } and ${
                                                         this.getMinMaxFrequency(key)?.maxFreq
                                                        }`}
                                                      </div>)
                                                    }
                                                    {/* {console.log('udf',item2.userDeliveryFrequency)} */}
                                                    {(item2.userDeliveryFrequency==='')&&
                                                    (<div style={{ fontSize: '80%', color: '#fb6340' }}>
                                                    Please Enter User Delivery Frequency
                                                  </div>)
                                                    }
                                                </Col>
                                              }
                                              {key === 'EMAIL' && selectedChannelData.length > 0 &&
                                                selectedChannelData.find(i => i.channelName === 'EMAIL') && (
                                                  <Col className="mb-3 pt-2" md="6">
                                                    <div className="custom-control custom-checkbox">
                                                      <Input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id={"is-html-body-checkbox"}
                                                        checked={this.state.isHtmlInBody}
                                                        onChange={e => { this.setState({ ...this.state, isHtmlInBody: !this.state.isHtmlInBody }); this.removeChannelData(); }}
                                                        disabled={ONLYSHOWTEMPLATE}
                                                      />
                                                      <label htmlFor={'is-html-body-checkbox'} className="custom-control-label channelLabel">
                                                        {"Is HTML"}
                                                      </label>
                                                    </div>
                                                  </Col>
                                                )}
                                              {(key === 'FCM' || key === 'WEBPUSH') && selectedChannelData.length > 0 &&
                                                selectedChannelData.find(i => i.channelName === 'FCM') && (
                                                  <Col className="mb-3 pt-2" md="6">
                                                    <div className="custom-control custom-checkbox">
                                                      <Input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id={"is-custom-enabled-checkbox"}
                                                        checked={this.state.customEnabled}
                                                        onChange={e => {
                                                          this.setState({
                                                            ...this.state, customEnabled: !this.state.customEnabled,
                                                            customStyles: {
                                                              ...this.state.customStyles,
                                                              // customVariableDescriptionData: [], 
                                                              // customVariableDescriptionState: null
                                                            }
                                                          });
                                                        }}
                                                        disabled={ONLYSHOWTEMPLATE}
                                                      />
                                                      <label for={'is-custom-enabled-checkbox'} className="custom-control-label channelLabel">
                                                        {"Is Custom Enabled"}
                                                      </label>
                                                    </div>
                                                  </Col>
                                                )}
                                                {(key === 'FCM' || key === 'WEBPUSH') && selectedChannelData.length > 0 &&
                                                selectedChannelData.find(i => i.channelName === 'FCM') && (
                                                  <Col className="mb-3 pt-2" md="6">
                                                    <div className="custom-control custom-checkbox">
                                                      <Input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id={"is-save-notification-checkbox"}
                                                        checked={this.state.persistent}
                                                        onChange={e => {
                                                          this.setState({
                                                            ...this.state, persistent: !this.state.persistent,
                                                            customStyles: {
                                                              ...this.state.customStyles,
                                                              // customVariableDescriptionData: [], 
                                                              // customVariableDescriptionState: null
                                                            }
                                                          });
                                                        }}
                                                        disabled={ONLYSHOWTEMPLATE}
                                                      />
                                                      <label for={'is-save-notification-checkbox'} className="custom-control-label channelLabel">
                                                        {"Save Notification"}
                                                      </label>
                                                    </div>
                                                  </Col>
                                                )}

                                            </Row>
                                          )
                                      )
                                      : ''}
                                  </Col>
                              )}
                            {/* <Col md='12'>
                              <Row>
                                {selectedChannelData.length > 0 &&
                                  selectedChannelData.find(i => i.channelName === 'EMAIL') ? (
                                  <Col className="mb-3" md="6">
                                    <div className="custom-control custom-checkbox">
                                      <Input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={"is-html-body-checkbox"}
                                        checked={this.state.isHtmlInBody}
                                        onChange={e => { this.setState({ ...this.state, isHtmlInBody: !this.state.isHtmlInBody }); this.removeChannelData(); }}
                                        disabled={ONLYSHOWTEMPLATE}
                                      />
                                      <label for={'is-html-body-checkbox'} className="custom-control-label channelLabel">
                                        {"Is HTML"}
                                      </label>
                                    </div>
                                  </Col>
                                ) : (
                                  <Col className="mb-3" md="6"></Col>
                                )}
                                {selectedChannelData.length > 0 &&
                                  selectedChannelData.find(i => i.channelName === 'FCM') ? (
                                  <Col className="mb-3" md="6">
                                    <div className="custom-control custom-checkbox">
                                      <Input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={"is-custom-enabled-checkbox"}
                                        checked={this.state.customEnabled}
                                        onChange={e => {
                                          this.setState({
                                            ...this.state, customEnabled: !this.state.customEnabled,
                                            customStyles: {
                                              ...this.state.customStyles,
                                              // customVariableDescriptionData: [], 
                                              // customVariableDescriptionState: null
                                            }
                                          });
                                        }}
                                        disabled={ONLYSHOWTEMPLATE}
                                      />
                                      <label for={'is-custom-enabled-checkbox'} className="custom-control-label channelLabel">
                                        {"Is Custom Enabled"}
                                      </label>
                                    </div>
                                  </Col>
                                ) : (
                                  <Col className="mb-3" md="6"></Col>
                                )}
                              </Row>
                            </Col> */}

                            {Action === 'test' && (
                              <Col className="mb-3" md="6">
                                <div>
                                  <span className="form-control-label">Category :&nbsp;</span>
                                  <span className="categoryValue">
                                    {this.state.customStyles.requestType !== ''
                                      ? CategorySMS.find(item => item.Key === this.state.customStyles.requestType) &&
                                      CategorySMS.find(item => item.Key === this.state.customStyles.requestType)
                                        .Display
                                      : ''}
                                  </span>
                                </div>
                              </Col>
                            )}
                            {
                              Action !== 'test' && this.state.customStyles.requestType && selectedChannelData.length > 0 && (
                                <Col className="mb-3" md="12">
                                  <label className="form-control-label required-field" htmlFor="validationCustom01">
                                    Enter the Template Body:
                                  </label>
                                  <Input
                                    id="exampleFormControlTextarea1"
                                    className="messageTextArea"
                                    rows="3"
                                    type="textarea"
                                    maxLength={this.state.isHtmlInBody ? 100000 : this.state.customStyles.BodyLength}
                                    value={this.state.customStyles.templateBody}
                                    valid={this.state.customStyles.templateBodyState === 'valid'}
                                    invalid={this.state.customStyles.templateBodyState === 'invalid'}
                                    onKeyDown={e => this.customStylesForm(e, 'templateBody')}
                                    onChange={e => this.customStylesForm(e, 'templateBody')}
                                    disabled={ONLYSHOWTEMPLATE}
                                  />
                                  <div className="invalid-feedback">{
                                    this.state.customStyles.templateBody && this.state.isHtmlInBody ?
                                      'Please Add Valid HTML Template Body.' :
                                      this.state.customStyles.maxBodyLength && this.state.customStyles.templateBody && !this.state.isHtmlInBody ?
                                        `Body Length must not exceed ${this.state.customStyles.maxBodyLength}` :
                                        this.state.customStyles.templateBody && !this.state.isHtmlInBody ?
                                          "Please Add Valid Template Body." :

                                          "Please choose Template Body."}
                                  </div>
                                </Col>
                              )
                            }

                            <Col className="mb-3 table-parent-1" md="12">
                              {variableDescriptionData.length > 0 && (
                                <label className="form-control-label" htmlFor="validationCustom01">
                                  Variable Description
                                </label>
                              )}
                              {this.state.customStyles.variableDescriptionState === 'invalid' && (
                                <div style={{ fontSize: '80%', color: '#fb6340' }}>Please Fill All the Details</div>
                              )}
                              {Action === 'test' && this.state.templateParamsTestState === 'invalid' && (
                                <div style={{ fontSize: '80%', color: '#fb6340' }}>Please fill the test value</div>
                              )}
                              {variableDescriptionData.length > 0 ? <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                  <tr>
                                    <th className="text-center" scope="col">
                                      Variables
                                    </th>
                                    {Action === 'test' && (
                                      <th className="text-center required-field" scope="col">
                                        Test Value
                                      </th>
                                    )}
                                    <th
                                      className={Action === 'test' ? 'text-center' : 'text-center required-field'}
                                      scope="col"
                                    >
                                      Variable Mapping
                                    </th>
                                    <th
                                      className={Action === 'test' ? 'text-center' : 'text-center required-field'}
                                      scope="col"
                                    >
                                      Variable Description
                                    </th>
                                    <th
                                      className={Action === 'test' ? 'text-center' : 'text-center required-field'}
                                      scope="col"
                                    >
                                      Type
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {variableDescriptionData.length > 0
                                    ? variableDescriptionData.map(
                                      (item, key) =>
                                        item.variableType !== 'AttachmentLink' && (
                                          <tr key={key}>
                                            <td className="text-center">
                                              <span className="mb-0 text-sm">{item.variableName}</span>
                                            </td>
                                            {Action === 'test' && (
                                              <td className="text-center">
                                                <Input
                                                  name={item.variableName}
                                                  placeholder="Required"
                                                  onChange={e => this.handleTestValue(e)}
                                                />
                                              </td>
                                            )}
                                            <td className="text-center">
                                              {Action !== 'test' ? (
                                                <UncontrolledDropdown
                                                  disabled={ONLYSHOWTEMPLATE}
                                                  className="dropdown-grid"
                                                >
                                                  <DropdownToggle caret color="primary">
                                                    {item.variableMapping
                                                      ? item.variableMapping
                                                      : 'Select the Mapping'}
                                                  </DropdownToggle>
                                                  <DropdownMenu>
                                                    {MappingForVariable.length > 0 &&
                                                      MappingForVariable.map(variable => (
                                                        <DropdownItem
                                                          value={variable}
                                                          name={item.variableName}
                                                          onClick={e => this.customStylesForm(e, 'variableMapping')}
                                                        >
                                                          {variable}
                                                        </DropdownItem>
                                                      ))}
                                                  </DropdownMenu>
                                                </UncontrolledDropdown>
                                              ) : (
                                                <b>{item.variableMapping && item.variableMapping}</b>
                                              )}
                                            </td>
                                            <td className="text-center">
                                              <Input
                                                maxLength="250"
                                                name={item.variableName}
                                                value={item.variableDesc ? item.variableDesc : ''}
                                                placeholder="Required"
                                                onChange={e => this.customStylesForm(e, 'variableDesc')}
                                                disabled={ONLYSHOWTEMPLATE}
                                              />
                                            </td>
                                            <td className="text-center">
                                              {Action !== 'test' ? (
                                                <UncontrolledDropdown
                                                  disabled={ONLYSHOWTEMPLATE}
                                                  className="dropdown-grid"
                                                  direction="left"
                                                >
                                                  <DropdownToggle caret color="primary">
                                                    {item.variableType ? item.variableType : 'Select Type'}
                                                  </DropdownToggle>
                                                  <DropdownMenu className="dropdown-custommenulist">
                                                    {TypeOfVariable.length > 0 &&
                                                      TypeOfVariable.map(variable => (
                                                        <DropdownItem
                                                          value={variable}
                                                          name={item.variableName}
                                                          onClick={e => this.customStylesForm(e, 'variableType')}
                                                        >
                                                          {variable}
                                                        </DropdownItem>
                                                      ))}
                                                  </DropdownMenu>
                                                </UncontrolledDropdown>
                                              ) : (
                                                <b>{item.variableType && item.variableType}</b>
                                              )}
                                            </td>
                                          </tr>
                                        )
                                    )
                                    : ''}
                                  {variableDescriptionData.length > 0
                                    ? variableDescriptionData.map(
                                      (item, key) =>
                                        item.variableType === 'AttachmentLink' && (
                                          <tr key={key}>
                                            <td className="text-center">
                                              <span className="mb-0 text-sm">{item.variableName}</span>
                                            </td>
                                            {Action === 'test' && (
                                              <td className="text-center">
                                                <Input
                                                  name={item.variableName}
                                                  placeholder="Required"
                                                  onChange={e => this.handleTestValue(e, item.variableType, Action, channelName)}
                                                />
                                              </td>
                                            )}
                                            <td className="text-center">
                                              <span className="mb-0 text-sm">URL</span>
                                            </td>
                                            <td className="text-center">
                                              <span className="mb-0 text-sm">Attachment</span>
                                            </td>
                                            <td className="text-center">
                                              <span className="mb-0 text-sm">Attachment Link</span>
                                            </td>
                                          </tr>
                                        )
                                    )
                                    : ''}
                                  {/* {this.state.customStyles.hasAttachment === 'Y' && <tr>
                                                                        <td className="text-center">
                                                                            <span className="mb-0 text-sm">
                                                                                {variableDescriptionData.length ? variableDescriptionData.length + 1 : 1}
                                                                            </span>
                                                                        </td>
                                                                        {Action === 'test' &&
                                                                            <td className="text-center">
                                                                                <Input
                                                                                    // name={item.variableName}
                                                                                    placeholder='Required'
                                                                                    onChange={e => this.handleTestValue(e)}
                                                                                />
                                                                            </td>
                                                                        }
                                                                        <td className="text-center">
                                                                            <span className="mb-0 text-sm">
                                                                                URL
                                                                            </span>
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <span className="mb-0 text-sm">
                                                                                Attachment
                                                                            </span>
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <span className="mb-0 text-sm">
                                                                                Attachment Link
                                                                            </span>
                                                                        </td>
                                                                    </tr>} */}
                                </tbody>
                                {Action === 'test' && this.state.customStyles.hasAttachment === "Y" && channelName == "EMAIL"? (
                                  <div className='w-100 ml-4 d-flex justify-content-center align-items-center'>
                                    <Button
                                      color="primary"
                                      type="button"
                                      className="buttonProceed"
                                      onClick={this.handleAddMore}
                                    >
                                      Add +
                                    </Button>
                                </div>
                                ) : null}
                              </Table> : null}
                           
                            </Col>
                            {/* {this.state.customEnabled && 
                                <Col className="mb-3 pt-6 table-parent-1" md="12">
                                  <label className="form-control-label">
                                    Custom Variable Description
                                  </label>
                                  {!ONLYSHOWTEMPLATE && <Button
                                      color="primary"
                                      type="button"
                                      size="sm"
                                      className="mb-3 float-right"
                                      onClick={this.addCustomVariableRow}
                                      disabled={this.state.customStyles.customVariableDescriptionData.length >= this.state.pageSize}
                                    >
                                     + Add custom variable
                                    </Button>
                                  }
                                  {this.state.customStyles.customVariableDescriptionState === 'invalid' && (
                                    <div style={{ fontSize: '80%', color: '#fb6340' }}>Please Fill All the Details</div>
                                  )}
                                  {Action === 'test' && this.state.customParamsTestState === 'invalid' && (
                                    <div style={{ fontSize: '80%', color: '#fb6340' }}>Please fill the test value</div>
                                  )}
                                  <Table className="align-items-center table-flush" responsive>
                                  <thead className="thead-light">
                                    <tr>
                                      <th className="text-center" scope="col">
                                        Variables
                                      </th>
                                      {Action === 'test' && (
                                        <th className="text-center required-field" scope="col">
                                          Test Value
                                        </th>
                                      )}
                                      <th
                                        className={Action === 'test' ? 'text-center' : 'text-center required-field'}
                                        scope="col"
                                      >
                                        Variable Mapping
                                      </th>
                                      <th
                                        className={Action === 'test' ? 'text-center' : 'text-center required-field'}
                                        scope="col"
                                      >
                                        Variable Description
                                      </th>
                                      <th
                                        className={Action === 'test' ? 'text-center' : 'text-center required-field'}
                                        scope="col"
                                      >
                                        Type
                                      </th>
                                      {!ONLYSHOWTEMPLATE && 
                                        <th
                                          className={Action === 'test' ? 'text-center' : 'text-center required-field'}
                                          scope="col"
                                        >
                                          Action
                                        </th>
                                      }
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {customVariableDescriptionData.length > 0
                                      ? customVariableDescriptionData.map(
                                        (item, key) =>
                                          item.variableType !== 'AttachmentLink' && (
                                            <tr key={key}>
                                              <td className="text-center">
                                                <span className="mb-0 text-sm">{item.variableName}</span>
                                              </td>
                                              {Action === 'test' && (
                                                <td className="text-center">
                                                  <Input
                                                    name={item.variableName}
                                                    placeholder="Required"
                                                    onChange={e => this.customVariableInputHandler(key, e.target.value, item.variableName, 'test')}
                                                  />
                                                </td>
                                              )}
                                              <td className="text-center">
                                                {Action !== 'test' ? (
                                                  <UncontrolledDropdown
                                                    disabled={ONLYSHOWTEMPLATE}
                                                    className="dropdown-grid"
                                                  >
                                                    <DropdownToggle caret color="primary">
                                                      {item.variableMapping
                                                        ? item.variableMapping
                                                        : 'Select the Mapping'}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                      {MappingForVariable.length > 0 &&
                                                        MappingForVariable.map(variable => (
                                                          <DropdownItem
                                                            value={variable}
                                                            name={item.variableName}
                                                            onClick={e => this.customVariableInputHandler(key, variable, 'variableMapping')}
                                                          >
                                                            {variable}
                                                          </DropdownItem>
                                                        ))}
                                                    </DropdownMenu>
                                                  </UncontrolledDropdown>
                                                ) : (
                                                  <b>{item.variableMapping && item.variableMapping}</b>
                                                )}
                                              </td>
                                              <td className="text-center">
                                                <Input
                                                  maxlength="250"
                                                  name={item.variableName}
                                                  value={item.variableDesc ? item.variableDesc : ''}
                                                  placeholder="Required"
                                                  onChange={e => this.customVariableInputHandler(key, e.target.value, 'variableDesc')}
                                                  disabled={ONLYSHOWTEMPLATE}
                                                />
                                              </td>
                                              <td className="text-center">
                                                {Action !== 'test' ? (
                                                  <UncontrolledDropdown
                                                    disabled={ONLYSHOWTEMPLATE}
                                                    className="dropdown-grid"
                                                  >
                                                    <DropdownToggle caret color="primary">
                                                      {item.variableType ? item.variableType : 'Select Type'}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                      {TypeOfVariable.length > 0 &&
                                                        TypeOfVariable.map(variable => (
                                                          <DropdownItem
                                                            value={variable}
                                                            name={item.variableName}
                                                            onClick={e => this.customVariableInputHandler(key, variable, 'variableType')}
                                                          >
                                                            {variable}
                                                          </DropdownItem>
                                                        ))}
                                                    </DropdownMenu>
                                                  </UncontrolledDropdown>
                                                ) : (
                                                  <b>{item.variableType && item.variableType}</b>
                                                )}
                                              </td>
                                              {!ONLYSHOWTEMPLATE && 
                                                <td className='text-center' onClick={() => this.removeCustomVariableRow(key)}>
                                                  <Button
                                                    color="danger"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => this.removeCustomVariableRow(key)}
                                                    id="delete"
                                                  >
                                                    <i className="far fa-trash-alt" />
                                                  </Button>
                                                </td>
                                              }
                                            </tr>
                                          )
                                      )
                                      : ''}
                                  </tbody>
                                  </Table>
                                </Col>
                              } */}
                          </div>
                        </Form>
                      </Col>
                      <Col sm="4" className="text-center mb-3">
                        <div className="form-control-label preview-label float-left" htmlFor="validationCustom01">
                          Preview
                        </div>
                        <div
                          className="form-control-label preview-label float-right"
                          htmlFor="validationCustom01"
                          dangerouslySetInnerHTML={this.getTemplateCharacterCount()}
                        ></div>
                        {this.state.isHtmlInBody ? Action !== 'test' ?
                          <iframe srcDoc={this.state.customStyles.templateBody} className="preview messageTextArea" id="preview" frameBorder={0} />
                          :
                          <iframe srcDoc={this.state.modifiedTemplate} className="preview messageTextArea" id="preview" frameBorder={0} />
                          :
                          <div
                            id="preview"
                            className="preview messageTextArea"
                            dangerouslySetInnerHTML={this.getContent()}
                          />
                        }
                        {Action === 'test' && (
                          <Button
                            color="primary"
                            type="button"
                            className="buttonProceed"
                            onClick={()=>{
                              // console.log('Test Button Clicked');
                              this.handleProceedTest();
                            }
                            }
                            disable={!this.state.testButtonActive}
                          >
                            Test
                          </Button>
                        )}
                        {!ONLYSHOWTEMPLATE && (
                          <Button
                            color="primary"
                            type="button"
                            className="buttonProceed"
                            onClick={this.handleProceedCreate}
                          >
                            {this.state.loading ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : 'Proceed'}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default MessageTemplateForm;
