import {masterServiceBaseUrl, getAPI} from "../../../../../../services/http";
import moment from "moment";

 export const fetchCourseYear =(url,StateMethod)=>{
    getAPI(url,(data)=>{
        StateMethod(data.data);
    },(data)=>{
        console.log('Error in fetching Course Year----->',data.message);
    })
}

export const fetchDetails =(url,stateMethod)=>{
     getAPI(url,(data)=>{
        //  console.log('Fetched data--->',data.data);
         stateMethod(data.data);
     },(data)=>{
         console.log("Error in fetching Data.",data);
     })
}

export const fetchPaymentSchedule = (url,stateMethod)=>{
    getAPI(url,(data)=>{
        // console.log('Payment Schedule Data------------------->',data.data)
        stateMethod({
            courseStartDate:moment(data['data']['courseStartDate']).format('DD-MM-YYYY'),
            courseEndDate:moment(data['data']['courseEndDate']).format('DD-MM-YYYY'),
            courseId:data['data']['courseIdRegular'],
            courseDuration: data['data']['courseDuration'],
            lastUpdatedDate: moment(data['data']['updatedOn']).format('DD-MM-YYYY'),
            term:data['data']['termId'],
            cashierOffice:data['data']['cashierOfficeId'],
            businessAreaId:data['data']['businessAreaId'],
        })
    })
}


 export const fetchDetailsForHeader =(url,setMethod,type)=>{
    try{
        // console.log('Url------------->',url);
        // console.log('Type---------->',type);
        getAPI(url,(data)=>{
            // console.log('Data in Fetch Details------->',data.data);
            if(type ==='courseName'){
                if(data.data && data.data.length>0){
                    setMethod(data.data[0]['courseName']);
                }
            }
            if(type ==='term'){
                if(data.data){
                    setMethod(data.data.termDispValue)
                }
            }
            if(type ==='paymentSchedule'){
                if(data.data){
                    setMethod( {
                        courseStartDate:moment(data['data']['courseStartDate']).format('DD-MM-YYYY'),
                        courseEndDate:moment(data['data']['courseEndDate']).format('DD-MM-YYYY'),
                        courseId:data['data']['courseIdRegular'],
                        courseDuration: data['data']['courseDuration'],
                        lastUpdatedDate: moment(data['data']['updatedOn']).format('DD-MM-YYYY'),
                        term:data['data']['termId'],
                        cashierOffice:data['data']['cashierOfficeId'],
                        businessAreaId:data['data']['businessAreaId'],
                    })
                }
            }
            if(type ==='businessAreaData'){
                if(data.data){
                    setMethod(data.data);
                }
            }
            if(type === 'companyCode'){
                if(data && data.data && data.data.companyCodeDispValue){
                    setMethod(data.data.companyCodeDispValue);
                    // console.log('Company Code------->',data.data.companyCodeDispValue)
                }
            }
            if(type ==='groupCode'){
                if(data && data.data && data.data.groupCodeDispValue){
                    setMethod(data.data.groupCodeDispValue)
                    // console.log('Group Code------>',data.data.groupCodeDispValue)
                }
            }
            if(type ==='businessArea'){
                if(data.data  && data.data.businessAreaDispValue){
                    setMethod(data.data.businessAreaDispValue)
                    // console.log('Business Area------->',data.data.businessAreaDispValue)
                }
            }
            if(type ==='cashierOffice'){
                if(data.data && data.data.cashierOfficeKey){
                    // console.log('Cashier data-------->',data.data);
                    setMethod(data.data.cashierOfficeKey + ' - ' + data.data.description);
                }
            }
            if(type ==='itemTypeData'){
                // console.log('Item Type Data------------>',data.data)
                if(data.data){
                    setMethod(data.data)
                }
            }

        },(data)=>{
                // console.log('Error in fetch details-------->',data.message);
        })
    }catch(e){
        console.log('Error in Fetching Detail---->',e);
    }
}
