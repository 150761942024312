import React, { useEffect, useState } from "react";
import AInput from "../../../common/formFeilds/customInput/AInput";
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";
import { inputStyles, selectStyles } from "../stockReturn/constant";
import { inputHeight } from "./SearchByOtherDetailsForm";
import AButton from "../../../common/form-fields-mui/AButton";
import { useHistory } from "react-router";
import {
  getLabelForTxnId,
  getRegexForTxnId,
  getScreenName,
  getTransactionType,
  messageNoDataFound,
} from "./helper";
import { fetchTransactionsData } from "../service";
import { failureToast } from "../../../common/utils/methods/toasterFunctions/function";

const SearchByTxnForm = ({
  dropdown,
  checkedOption,
  isConsumptionReturn,
  clickHandler,
  selectedTab,
  screenName,
  academicType = "RCC",
  forHistory = true,
}) => {
  const [form, setForm] = useState({ businessArea: "", txnId: "" });

  useEffect(() => {
    setForm({ businessArea: "", txnId: "" });
  }, [checkedOption, selectedTab]);

  const screenType = screenName ? screenName : getScreenName(selectedTab);
  const history = useHistory();

  const viewDetailsHandler = async () => {
    if (clickHandler) return clickHandler(form);
    const { txnId, businessArea } = form;
    const response = await fetchTransactionsData(
      businessArea?.value,
      txnId,
      getTransactionType(selectedTab, academicType)
    );

    if (response?.data?.length === 0) return failureToast(messageNoDataFound);
    window.open(
      history.createHref({
        pathname: `/admin/edp/stockManagement/stockTxnHistory/${academicType}/${form?.txnId}/${form?.businessArea?.value}/${form?.businessArea?.label}/${screenType}`,
      }),
      "_blank"
    );
  };

  const formHandler = (value, key) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <div style={forHistory ? { width: "673px" } : {}}>
      <div className="mt-3">
        <div className="d-flex" style={{ gap: "28px" }}>
          <div style={{ flex: 0.2, minWidth: "130px" }}>
            <AAutoComplete
              label="Business area*"
              placeholder="Select"
              isMulti={false}
              items={
                dropdown?.businessAreas ||
                dropdown?.dlpActiveBusinessArea ||
                dropdown?.businessArea
              }
              currentValue={form?.businessArea}
              handleChange={(value) => {
                formHandler(value, "businessArea");
              }}
              style={selectStyles}
              height={inputHeight}
            />
          </div>
          <div className="position-relative flex-grow-1" style={{ flex: 0.5 }}>
            <AInput
              label={`${getLabelForTxnId(selectedTab, checkedOption)} ID *`}
              placeholder={`Enter`}
              regex={getRegexForTxnId(selectedTab, checkedOption)}
              style={{
                ...inputStyles,
                ...{ height: inputHeight, borderRadius: "4px" },
              }}
              handler={(value) => formHandler(value.toUpperCase(), "txnId")}
              value={form?.txnId}
              crossIcon={true}
              crossHandler={() => formHandler("", "txnId")}
            />
          </div>
        </div>
        <AButton
          className="button_remove_left_margin"
          variant="primary_filled"
          disabled={!(form?.businessArea && form?.txnId)}
          onClick={viewDetailsHandler}
          updatedStyle={{ marginTop: "1.8rem" }}
        >
          View {forHistory ? "history" : "details"}
        </AButton>
      </div>
    </div>
  );
};

export default SearchByTxnForm;
