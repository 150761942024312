import React, { useState } from "react";
import "../style.scss";
import NoRowOverlay from "../../../../masters/holidayMaster/NoRowOverlay";
import { AddMaterialForm, DisplayRowData } from "./tableComponents";

const TableHeader = () => {
  return (
    <div className="header-column add-material-table">
      <div>Material Number</div>
      <div>Material description</div>
      <div>UOM</div>
      <div>Available qty.</div>
      <div>Quantity</div>
      <div>Barcode</div>
      <div>Action</div>
    </div>
  );
};

const AddMaterialTableV2 = ({
  rows,
  setRows,
  loading,
  setLoading,
  businessArea,
  saveRowHandler,
  updateBarcodeListForMaterial,
  employeeDetailsForm,
}) => {
  const [barCodes, setBarCodes] = useState([]);

  if (rows.length === 0)
    return (
      <div className="mt-2">
        <TableHeader />
        <NoRowOverlay />
      </div>
    );

  const updateFlag = (flag = false, index, obj = {}) => {
    const tempRows = [...rows];
    tempRows[index] = { ...tempRows[index], openForm: flag, ...obj };
    setRows(tempRows);
  };

  const cancelHandler = (index) => {
    updateFlag(false, index, { });
  }
  const editHandler = (index) => {
    updateFlag(true, index, { mode: "EDIT" });
  };

  const deleteHandler = (index) => {
    const temp = [...rows];
    temp.splice(index, 1);
    setRows(temp);
  };

  const TableRow = ({ row, index, loading, setLoading, rows }) => {
    if (row?.openForm)
      return (
        <AddMaterialForm
          loading={loading}
          setLoading={setLoading}
          saveHandler={saveRowHandler}
          setBarCodes={setBarCodes}
          indexNo={index}
          row={row}
          businessArea={businessArea}
          deleteHandler={deleteHandler}
          rows={rows}
          cancelHandler={cancelHandler}
        />
      );

    return (
      <DisplayRowData
        editHandler={editHandler}
        deleteHandler={deleteHandler}
        index={index}
        row={row}
        updateBarcodeListForMaterial={updateBarcodeListForMaterial}
        employeeDetailsForm={employeeDetailsForm}
      />
    );
  };

  return (
    <div className="mt-2">
      <TableHeader />
      {rows.map((item, index) => (
        <TableRow
          key={`new-${JSON.stringify(item)}}`}
          row={item}
          index={index}
          loading={loading}
          setLoading={setLoading}
          rows={rows}
        />
      ))}
    </div>
  );
};

export default AddMaterialTableV2;
