import React from "react";
import {  Col, Modal, ModalBody, Row } from "reactstrap";
import AAutoComplete from "../../../common/form-fields-mui/AAutoComplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AButton from "../../../common/form-fields-mui/AButton";
import AInput from '../../../common/formFeilds/customInput/AInput'
import { ReactComponent as CloseIcon } from "../../../../../../assets/img/svg/CloseIcon.svg";

import CustomDatePickerV2 from "../../../common/commonComponents/CustomDatePicker-V2";
import CustomDesktopTimePicker from "../../../common/form-fields-mui/CustomDesktopTimePicker";
import { classTypeDropdown } from "../helper";
import { modeDropdown } from "../../constant/common";
import { formatDropdown } from "../../constant/common";
import { STATUS_DROPDOWN } from "../../../edpOps/constant/batch-search";
import moment from "moment";
import { getMomentObjFromTimeString } from "../../../masters/daysPlanMaster/common";
import { failureToast } from "../../../common/utils/methods/toasterFunctions/function";
import { LECTURE_TYPE_ITEMS } from "./constant";

const formControlLabelStyles = {
  marginBottom: '0px',
  '.MuiFormControlLabel-label': {
    fontFamily: 'inherit'
  }
}
const checkBoxStyles = {
  // padding: '5px',
  // paddingLeft: '9px'
}
const timePickerStyles = {
  '& .MuiInputBase': { borderRadius: '8px' }
}
const selectStyles = {
  // control: { borderRadius: '8px' }
}

const ReviewSearchFilterForm = ({
  isOpen,
  onClose,
  form,
  inputHandler,
  applyFilterHandler,
  removeFilterHandler,
  resetDependentFields,
  testTypeDropdown,
  roomsDropdown,
  subjectsDropdown,
  lecturesDropdown,
  facultyDropdown
}) => {

  return (
    <Modal size='lg' isOpen={isOpen} toggle={onClose} style={{ width: '410px' }}>
      <ModalBody>
        <div className="mb-2 mt-2 d-flex align-items-start justify-content-between">
          <h4 className="heading-4">Filter</h4>
          <CloseIcon onClick={onClose} />
        </div>
        <Row>
          <Col md='12' className='mb-3'>
            <Row>
              <Col md='12' className="mb-2">
                <div className="regular-small label-color">Date range</div>
              </Col>
              <Col md='6'>
                <CustomDatePickerV2
                  placeHolder="From"
                  value={form?.startDate}
                  // startDate={startDate}
                  handler={(index, date) => inputHandler(date, 'startDate')}
                // minDate={startDate}
                // maxDate = {formData?.endOn}
                />
              </Col>
              <Col md='6'>
                <CustomDatePickerV2
                  placeHolder="To"
                  value={form?.endDate}
                  // startDate={startDate}
                  handler={(index, date) => inputHandler(date, 'endDate')}
                  minDate={moment(form?.startDate)?.add(1, 'days')}
                  disabled={!form?.startDate}
                />
              </Col>
            </Row>
          </Col>
          <Col md='12' className='mb-2'>
            <Row>
              <Col md='12' className="mb-2">
                <div className="regular-small label-color">Time range</div>
              </Col>
              <Col md='6'>
                <CustomDesktopTimePicker
                  sx={timePickerStyles}
                  value={getMomentObjFromTimeString(form?.startTime)}
                  onChange={(value) => inputHandler(moment(value)?.format('HH:mm'), 'startTime')}
                />
              </Col>
              <Col md='6'>
                <CustomDesktopTimePicker
                  sx={timePickerStyles}
                  value={getMomentObjFromTimeString(form?.endTime)}
                  onChange={(value) => inputHandler(moment(value)?.format('HH:mm'), 'endTime')}
                  disabled={!form?.startTime}
                />
              </Col>
            </Row>
          </Col>
          <Col md="12" className='mb-2'>
            <AAutoComplete
              label="Class type"
              isMulti={false}
              currentValue={form?.classType}
              items={[{ label: 'Test', value: 'TEST' }, ...classTypeDropdown]}
              handleChange={(value) => { resetDependentFields(value); }}
              style={selectStyles}
            />
          </Col>
        </Row>
        {form?.classType?.value === 'TEST' &&
          <Row>
            <>
              <Col md="6" className='mb-2'>
                <FormControlLabel
                  sx={formControlLabelStyles}
                  control={
                    <Checkbox
                      sx={checkBoxStyles}
                      onChange={(e) => inputHandler(e.target.checked ? true : '', "isMandatory")}
                      checked={form?.isMandatory === true}
                    />
                  }
                  label={<span className="regular-body label-color">Mandatory tests</span>}
                />
              </Col>
              <Col md="6" className='mb-2'>
                <FormControlLabel
                  sx={formControlLabelStyles}
                  control={
                    <Checkbox
                      sx={checkBoxStyles}
                      onChange={(e) => inputHandler(e.target.checked ? false : '', "isMandatory")}
                      checked={form?.isMandatory === false}
                    />
                  }
                  label={<span className="regular-body label-color">Optional tests</span>}
                />
              </Col>
            </>
            <Col md="12" className='mb-2'>
              <AAutoComplete
                label="Test type"
                isMulti={false}
                currentValue={form?.testType}
                items={testTypeDropdown}
                handleChange={(value) => inputHandler(value, 'testType')}
              />
            </Col>
            <Col md="12" className='mb-2'>
              <AAutoComplete
                label="Format"
                isMulti={false}
                currentValue={form?.format}
                items={formatDropdown}
                handleChange={(value) => inputHandler(value, 'format')}
              />
            </Col>
            <Col md="12" className='mb-2'>
              <AAutoComplete
                label="Mode"
                isMulti={false}
                currentValue={form?.mode}
                items={modeDropdown}
                handleChange={(value) => inputHandler(value, 'mode')}
              />
            </Col>
            <Col md="12" className='mb-2'>
              <AInput
                label="Test number"
                value={form?.testNumber}
                handler={(value) => inputHandler(value, 'testNumber')}
              />
            </Col>
            {/* <Col md="12" className='mb-2'>
              <AAutoComplete
                label="Status"
                isMulti={false}
                currentValue={form?.status}
                items={STATUS_DROPDOWN}
                handleChange={(value) => inputHandler(value, 'status')}
              />
            </Col> */}
          </Row>
        }
        {LECTURE_TYPE_ITEMS.includes (form?.classType?.value) &&
          <Row>
            <Col md="12" className='mb-2'>
              <AAutoComplete
                label="Subject"
                isMulti={false}
                currentValue={form?.subject}
                items={subjectsDropdown}
                handleChange={(value) => inputHandler(value, 'subject')}
              />
            </Col>
            <Col md="12" className='mb-2'>
              <AAutoComplete
                label="Lecture"
                isMulti={false}
                currentValue={form?.lecture}
                items={lecturesDropdown}
                handleChange={(value) => inputHandler(value, 'lecture')}
              // isDisabled={!form?.subject}
              />
            </Col>
            <Col md="12" className='mb-2'>
              <AAutoComplete
                label="Faculty"
                isMulti={false}
                currentValue={form?.faculty}
                items={facultyDropdown}
                handleChange={(value) => inputHandler(value, 'faculty')}
              // isDisabled={!form?.subject}
              />
            </Col>
            <Col md="12" className='mb-2'>
              <AAutoComplete
                label="Room"
                isMulti={false}
                currentValue={form?.room}
                items={roomsDropdown}
                handleChange={(value) => inputHandler(value, 'room')}
              />
            </Col>
            <Col md="12" className='mb-2'>
              <AAutoComplete
                label="Status"
                isMulti={false}
                currentValue={form?.status}
                items={STATUS_DROPDOWN}
                handleChange={(value) => inputHandler(value, 'status')}
              />
            </Col>
          </Row>
        }
        {(form?.classType?.value === 'PTM' || form?.classType?.value === 'Orientation') && (
          <Row>
            <Col md="12" className='mb-2'>
              <AAutoComplete
                label="Faculty"
                isMulti={false}
                currentValue={form?.faculty}
                items={facultyDropdown}
                handleChange={(value) => inputHandler(value, 'faculty')}
              // isDisabled={!form?.subject}
              />
            </Col>
            <Col md="12" className='mb-2'>
              <AAutoComplete
                label="Room"
                isMulti={false}
                currentValue={form?.room}
                items={roomsDropdown}
                handleChange={(value) => inputHandler(value, 'room')}
              />
            </Col>
            <Col md="12" className='mb-2'>
              <AAutoComplete
                label="Status"
                isMulti={false}
                currentValue={form?.status}
                items={STATUS_DROPDOWN}
                handleChange={(value) => inputHandler(value, 'status')}
              />
            </Col>
          </Row>
        )}
        <div className="mt-3 d-flex flex-row-reverse justify-content-between align-items-center">
          <AButton
            className="btn-left-0"
            variant="primary_filled"
            onClick={() => {
              if (getMomentObjFromTimeString(form?.startTime)?.isSameOrAfter(getMomentObjFromTimeString(form?.endTime))) {
                failureToast('Start time should be less than end time')
              } else {
                onClose(); applyFilterHandler(form);
              }
            }}
            disabled={(form?.startDate && !form?.endDate) || (form?.startTime && !form?.endTime)}
          >
            Apply filter
          </AButton>
          <AButton
            className="btn-left-0"
            variant="outline"
            updatedStyle={{ border: "1px solid #CC5F5F", color: '#CC5F5F' }}
            onClick={() => { removeFilterHandler(); }}
          >
            Remove filter
          </AButton>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ReviewSearchFilterForm;
