import React, { useState } from 'react'
import ADataGrid from '../../../common/data-grid/ADataGrid'
import { AGridColDef } from '../../../common/data-grid/ADataGridColDef'
import moment from 'moment'
import NoRowOverlay from '../../../masters/holidayMaster/NoRowOverlay'
import AButton from '../../../common/form-fields-mui/AButton';
import { gridStyles, gridComponents } from 'views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant.js';
import StockReturnDialog from "./StockReturnDialog"
import { MaterialDetails } from 'views/pages/manage/edpOps/stockManagement/selectMeterialAndStock/issueStock/TableColumn.js'
import IssueStockSuccessPrompt from '../selectMeterialAndStock/issueStock/IssueStockSuccessPrompt'
import { useHistory } from 'react-router';
import { getUrlSearchParams } from '../../../common/utils/methods/commonMethods/utilityMethod';

const MaterialDetailsCellComponent = ({ params }) => {
  
  const displayString = Object?.values(params?.row?.materialDetails || {})?.reduce((acc, item) => {
    return acc + `${acc ? ', ' : ''}${item?.materialNumber} | ${item?.materialDesc}`
  }, '')
  
  return (
    <div className='d-flex ellipsis-text-container align-items-center'>
      <div className='ellipsis-text-container'>{displayString}</div>
      <MaterialDetails dataRows={params?.row?.materialDetailsRes} />
    </div>
  )
}
const StockReturnTable = ({ loader, rows = [], dropdown, fetchData }) => {
  const history = useHistory();
  const { academicCareer } = getUrlSearchParams(history?.location?.search);
  const [isReturnDialogOpen, setIsReturnDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [successResponse, setSuccessResponse] = useState({});
  const handleSuccess = (res) => {
    fetchData();
    setIsSuccessDialogOpen(true);
    setSuccessResponse(res);
  }
  
  const columns = [
    new AGridColDef('issueDate', 'Issue date', false).setMinWidth(120).setFlex(0.5)
      .renderCellComponent(params => (
        <div>{moment(params?.value)?.format('DD-MMM-YYYY')}</div>
      )),
    new AGridColDef('transactionId', 'Transaction ID', false).setMinWidth(120)
      .renderCellComponent(params => (
        <div>{params?.value || '-'}</div>
      )),
    new AGridColDef('sapDocId', 'SAP Doc Number', false).setMinWidth(120)
      .renderCellComponent(params => (
        <div>{params?.value || '-'}</div>
      )),
    ...(academicCareer!=='RCC' ? [
      new AGridColDef('dispatchId', 'Dispatch ID', false).setMinWidth(120)
      .renderCellComponent(params => (
        <div>{params?.value || '-'}</div>
      )),
    ] : []),
    new AGridColDef('materialDetails', 'Material details', false).setMinWidth(120).setFlex(2)
      .renderCellComponent(params => <MaterialDetailsCellComponent params={params} />),
    new AGridColDef('actions', 'Actions', false).setMinWidth(125).columnAlign('right').setFlex(0.6)
      .renderCellComponent(params => (
        <div>
          <AButton
            size='xs'
            variant='primary_filled'
            onClick={() => { setIsReturnDialogOpen(true); setDialogData(params?.row); }}
            disabled={params?.row?.status?.toLowerCase() === 'failed' || params?.row?.isAllStockReturnedForTxn}
          >
            {params?.row?.isAllStockReturnedForTxn ? 'Stock returned' : 'Return stock'}
          </AButton>
        </div>
      )),
  ]

  return (
    <>
      <div className='mt-3'>
        <ADataGrid
          removeWrapperContainer
          rows={rows || []}
          columns={columns}
          rowId={(row) => JSON.stringify(row)}
          rowHeight={50}
          loading={false}
          serverPagination={false}
          components={{ ...gridComponents, NoRowsOverlay: () => <NoRowOverlay message={loader ? 'Loading...' : null} /> }}
          hidePagination={rows?.length <= 50 ? true : false}
          sx={gridStyles}
          autoHeight
        />
        <StockReturnDialog
          open={isReturnDialogOpen}
          setOpen={setIsReturnDialogOpen}
          data={dialogData}
          dropdown={dropdown}
          handleSuccess={handleSuccess}
        />
      </div>
      <IssueStockSuccessPrompt
        isOpen={isSuccessDialogOpen}
        setIsOpen={setIsSuccessDialogOpen}
        responseData={successResponse}
        isReturn = {true}
        message={'Your return ID'}
      />
    </>

  )
}

export default StockReturnTable