import React, { useState, useEffect } from "react";
import { Row, Col, FormGroup, Input, Label } from "reactstrap";
import Select from "react-select";
import { getAPI, masterServiceBaseUrl, failureToast } from "services/http";

const HeaderInformation = (props) => {
  const {
    activeGroupCode,
    activeCompanyCode,
    submitDataHandler,
    glCodeDetails,
    getDisplayValue,
    actionDisabled,
  } = props;
  const [showCompanyCode, setShowCompanyCode] = useState([]);
  const [glCodeValue, setGlCodeValue] = useState("");

  useEffect(() => {
    if (glCodeDetails["glCode"]) {
      setGlCodeValue(glCodeDetails["glCode"]);
    }
  }, [glCodeDetails["glCode"]]);

  const getCompanyCode = (value) => {
    try {
      getAPI(
        masterServiceBaseUrl + `/companyCode/getCompanyCodeByGroup/${value}`,
        (data) => {
          let tempCompanyCode = [];
          data &&
            data.data.map((item) => {
              tempCompanyCode.push({
                value: item.id,
                label: item.companyCodeDispValue,
              });
            });

          setShowCompanyCode(tempCompanyCode);
        },
        (data) => {
          setShowCompanyCode([]);
          failureToast(data["message"]);
        }
      );
      submitDataHandler("", "companyCodeId");
    } catch (e) {
      console.log("Error ", e);
    }
  };

  const validate = (type, value) => {
    const numberValidation = /^[0-9\b]+$/;
    if (type === "number") {
      if (value === "" || numberValidation.test(value)) {
        return true;
      }
    }
  };

                 
  
  return (
    <Row>
      <Col>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="form-control-label">
                <span className="custom-span-color">*</span>Group Code
              </Label>
              <Select
                isDisabled={actionDisabled}
                value={{
                  value: glCodeDetails["groupCodeId"],
                  label: glCodeDetails["groupCodeId"] ? (
                    getDisplayValue(glCodeDetails["groupCodeId"], "GroupCodeID")
                  ) : (
                    <span className="customSpan">Select Group Code</span>
                  ),
                }}
                options={activeGroupCode}
                onChange={(e) => {
                  getCompanyCode(e.value);
                  submitDataHandler(e.value, "groupCodeId");
                }}
              ></Select>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="form-control-label">
                <span className="custom-span-color">*</span>Company Code
              </Label>
              <Select
                isDisabled={actionDisabled}
                value={{
                  value: glCodeDetails["companyCodeId"],
                  label: glCodeDetails["companyCodeId"] ? (
                    getDisplayValue(
                      glCodeDetails["companyCodeId"],
                      "CompanyCodeID"
                    )
                  ) : (
                    <span className="customSpan">Select Company Code</span>
                  ),
                }}
                options={showCompanyCode}
                onChange={(e) => submitDataHandler(e.value, "companyCodeId")}
              ></Select>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="form-control-label">
                <span className="custom-span-color">*</span>GL Code
              </Label>
              <Input
                data-testid="glCode"
                className="customInput"
                disabled={actionDisabled}
                maxLength={50}
                value={glCodeValue}
                placeholder="Enter GL Code"
                onChange={(e) => {
                  let n = e.target.value;
                  if (validate("number", n)) {
                    submitDataHandler(n, "glCode");
                    setGlCodeValue(n);
                  }
                }}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="form-control-label">
                <span className="custom-span-color">*</span>GL Description
              </Label>
              <Input
                data-testid="glDescription"
                className="customInput"
                maxLength={50}
                disabled={actionDisabled}
                value={glCodeDetails["glDescription"]}
                placeholder="Enter GL Description"
                onChange={(e) =>
                  submitDataHandler(e.target.value, "glDescription")
                }
              />
            </FormGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default HeaderInformation;
