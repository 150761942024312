import React, { useState,useEffect } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import ReportSearchFilter from "./ReportSearchFilter";
import ReportList from "./ReportList";
import { endpoint } from "../common/constant";
import { fetchAllPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { updateRequest } from "../edpOps/constant/common";
import {useParams} from 'react-router-dom';
const Report = () => {
  const param=useParams();
  const [table1Loading,setTable1Loading]=useState(false);
  const [table2Loading,setTable2Loading]=useState(false);
  const [table1Data, setTable1Data] = useState([]);
  const [table2Data, setTable2Data] = useState([]);
  const [channel, setChannel] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [totalNotification,setTotalNotification]=useState('');
  const [successfulNotification,setSuccessfulNotification]=useState('');
  const [failedNotification,setFailedNotification]=useState('');
  const resetHandler = () => {
    setTable1Data([]);
    setTable2Data([]);
    setChannel('');
  }
  const getTable1DataByPID = (pid) => {
    let url = `${endpoint.bulkNotification.getAllReports}?processId=${pid}`;
    fetchData(url,setTable1Data,setTable1Loading);
  }
  const getTable1DataByDateAndChannel = (filterForm) => {
    const filterObject = updateRequest(filterForm);
    // console.log(filterObject);
    // console.log(filterForm);
    let url = endpoint.bulkNotification.getAllReports+'?'
    Object.keys(filterObject).map(
      key => {
        if (filterObject[key] !== '') {
          url = url + `${key}=${filterObject[key]}&`
        }
      }
    )
     url = url.substring(0, url.length - 1);
    //  console.log(url);
    fetchData(url,setTable1Data,setTable1Loading);

  }
  const getTable2Data = (pid) => {
    let url = endpoint.bulkNotification.getChannelReports;
    let channel = table1Data.find(item => item.processId === pid).channelName;
    setChannel(channel);
    if (channel === 'WHATSAPP') url = `${url}/${pid}/whatsapp-delivery-details`
    else if (channel === 'SMS') url = `${url}/${pid}/delivery-details`
    else if(channel==='EMAIL')  url = `${url}/${pid}/email-delivery-details`
    // console.log(url);
    fetchData(url,setTable2Data,setTable2Loading);
  }
  const fetchData = async (url,setterFunction,setTableLoading) => {
    try {
      const response = await fetchAllPromisedData(url, true)
      // console.log(response)
      if (response?.code === 200){ 
        if(response.data?.reportDTOS) {
          setterFunction(response.data.reportDTOS);
          setTotalNotification(response.data.totalNotification);
          setSuccessfulNotification(response.data.sucessfulNotification);
          setFailedNotification(response.data.failedNotification);
        }
        else setterFunction(response.data);
        setTableLoading(false);
      }
      else return [];
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexFlow: "column",
        height: "calc(80vh - 120px)",
      }}
    >
      <Card className="mx-3 mb-2" style={{ flexGrow: 1 }}>
        
      <CardHeader className="d-flex justify-content-between align-items-center">
          <h3 className="mb-0">{param?.report}</h3>
        </CardHeader>
        <CardBody style={{ display: "flex", flexFlow: "column" }}>
          <ReportSearchFilter
            getTable1DataByDateAndChannel={getTable1DataByDateAndChannel}
            resetHandler={resetHandler}
            getTable1DataByPID={getTable1DataByPID}
            setTable1Loading={setTable1Loading}
            searchInputValue={searchInputValue}
            setSearchInputValue={setSearchInputValue}
            setChannel={setChannel}

          />
          <ReportList
            getTable2Data={getTable2Data}
            table1Data={table1Data}
            table2Data={table2Data}
            channel={channel}
            table1Loading={table1Loading}
            table2Loading={table2Loading}
            setTable2Loading={setTable2Loading}
            totalNotification={totalNotification}
            successfulNotification={successfulNotification}
            failedNotification={failedNotification}
          />
        </CardBody>
      </Card>
    </div>

  );
}

export default Report;