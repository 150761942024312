import React, { useState } from "react";
import UploadAttachments from "../../stockConsumption/form/UploadAttachments";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import DispatchScheduleHistoryModal from "./DispatchScheduleHistoryModal";
import {
  fetchAllPostPromisedData,
  fetchAllPromisedData,
} from "../../../../../manage/common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "views/pages/manage/common/constant";
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import { successToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";

export const ExtraUI = ({ setViewHistoryModalOpen, downloadTemplate }) => {
  return (
    <div className="d-flex justify-content-around">
      <AButton variant="link" onClick={() => setViewHistoryModalOpen(true)}>
        View History
      </AButton>

      <AButton variant="link" onClick={downloadTemplate}>
        Download template
      </AButton>
    </div>
  );
};
const UploadDispatchSchedule = () => {
  const [viewHistoryModalOpen, setViewHistoryModalOpen] = useState(false);
  const [historyDataChanged, setHistoryDataChanged] = useState(true);

  const saveHandler = async (file) => {
    const reqObj = {
      courseFeesProcessingDTO: {
        key: file?.key,
        fileName: file?.name,
        status: "ACTIVE",
        processInstanceId: file?.processId,
        description: file?.processId,
      },
      courseFeeSupportingDocumentDtos: [],
      isProcess: true,
      serviceName: "BULK_DISPATCH_SCHEDULE",
      processId: file?.processId,
    };

    const resp = await fetchAllPostPromisedData(
      endpoint.fetchS3Url.updateToServer,
      reqObj,
      "post"
    );
    if (resp?.code === 200) {
      setHistoryDataChanged(true);
      successToast("File saved successfully.");
    } else failureToast("Something went wrong please try after sometime.");
  };

  const downloadTemplate = async () => {
    try {
      const resp = await fetchAllPromisedData(
        endpoint.templateUpload.downloadDispatchScheduleCsv,
        true
      );
      if (resp?.code === 200) {
        const templateUrl = await fetchAllPostPromisedData(
          `${endpoint.fetchS3Url.getDownloadUrl}?key=${resp?.data?.["Template Url"]}`,
          {}
        );
        if (templateUrl?.code === 200 && templateUrl?.data) {
          window.open(`${templateUrl?.data?.url}`);
        } else {
          failureToast("Something went wrong please try after sometime.");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <UploadAttachments
        uploadFileHeader="Upload dispatch schedule"
        maxAllowedFileSize={5}
        supportedFileTypes={["csv"]}
        serviceName="BULK_DISPATCH_SCHEDULE"
        customUploadButton={(onClick) => (
          <AButton variant="primary_filled" onClick={onClick}>
            Upload dispatch schedule
          </AButton>
        )}
        additionalUI={
          <ExtraUI
            setViewHistoryModalOpen={setViewHistoryModalOpen}
            downloadTemplate={downloadTemplate}
          />
        }
        saveCallback={saveHandler}
      />
      <DispatchScheduleHistoryModal
        open={viewHistoryModalOpen}
        setOpen={setViewHistoryModalOpen}
        historyDataChanged={historyDataChanged}
        setHistoryDataChanged={setHistoryDataChanged}
      />
    </>
  );
};

export default UploadDispatchSchedule;
