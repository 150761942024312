import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { GridRowModes } from "@mui/x-data-grid";
import { newRow } from "./constant";
import { AGridColDef } from "views/pages/manage/common/data-grid/ADataGridColDef";
import ADataGrid from "views/pages/manage/common/data-grid/ADataGrid";
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import { editCellContainerStyle } from "views/pages/manage/edpOps/stockManagement/mastersAndMapping/onBoardingMaterialIdSetup/constant";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { saveOrDeleteMsg } from "views/pages/manage/edpOps/stockManagement/mastersAndMapping/sapProfitCostCentreMapping/constant";
import { gridStyles } from "views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant";
import { gridComponents } from "views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant";
import { selectStyles } from "views/pages/manage/masters/holidayMaster/common";
import MultiSelectCellComponentWithSearch from "views/pages/manage/common/commonComponents/SearchFilterView/MultiSelectCellComponentWithSearch";
import { isObjEmpty } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { generateRandomId } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";
import ActionsCellComponent from "./ActionsCellComponent";
import moment from "moment";
import { Tooltip } from "@mui/material";
import NoRowOverlay from "views/pages/manage/masters/holidayMaster/NoRowOverlay";
import { getFormattedDate } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import StatusCellComponent from "./StatusCellComponent";
import AInput from "views/pages/manage/common/formFeilds/customInput/AInput";
import AdditionalCategoryDialog from "views/pages/manage/unAssigned/scholarshipMaster/scholarshipConfig/AdditionalCategoryDialog";
import { RolePermissions } from "appContext";
import { pages } from "views/pages/manage/common/constant";
import { PermissionContext } from "appContext";
import { SelectorCategory } from "./categorSelector";

const ListComponent = forwardRef(
  (
    {
      page = 0,
      onPageChange = () => {},
      dropdown,
      rows,
      loader,
      createHandler,
      updateHandler,
      deleteHandler,
      toggleStatusHandler,
      allCategories,
      rowCount
    },
    ref
  ) => {
    const [rowModesModel, setRowModesModel] = useState({});
    const [rowData, setRowData] = useState(rows);
    const userPermissions = RolePermissions(
      useContext(PermissionContext),
      pages["scholarshipConfig"]["id"]
    );
    useEffect(() => setRowData(rows), [rows]);
    const [rowForm, setRowForm] = useState({});
    const rowFormHandler = (value, key) =>
      setRowForm((prev) => ({ ...prev, [key]: value }));
    const startEditing = (id, form) => {
      setRowModesModel((oldModel) => ({ [id]: { mode: GridRowModes.Edit } }));
      setRowForm(form);
    };
    const stopEditing = () => {
      const currForm = { ...rowForm };
      setRowModesModel((oldModel) => ({
        [currForm?.id]: { mode: GridRowModes.View, ignoreModifications: true },
      }));
      setRowForm({});
    };
    const handleEditClick = (params) => {
      if (!isObjEmpty(rowForm)) {
        failureToast(saveOrDeleteMsg);
      } else {
        startEditing(params?.id, params?.row);
      }
    };
    const handleCancelClick = async (params) => {
      if (params?.row?.isNew) {
        setRowData(rows);
        setRowForm({});
      } else {
        stopEditing(params?.id);
      }
    };
    const handleSaveClick = async (params) => {
      if (rowForm?.isNew) {
        if(!validateAdditionalCateory())
          return;
        await createHandler(rowForm);
      } else {
        if(!validateAdditionalCateory())
          return;
        await updateHandler(rowForm);
      }
    };
    const validateAdditionalCateory = ()=>{
      if(rowForm?.additionalCategoryCutoff || rowForm?.additionalCategoryType || rowForm?.additionalSubSubCategoryId){
        if(!(rowForm?.additionalCategoryCutoff && rowForm?.additionalCategoryType && rowForm?.additionalSubCategoryId)){
          failureToast("Please fill all the fields of additional category")
          return false;
        }
        else
        return true;
      }
      else{
        return true;
      }
    }
    const addNewRow = async () => {
      if (!isObjEmpty(rowForm)) {
        failureToast(saveOrDeleteMsg);
      } else {
        let randomId = generateRandomId();
        let emptyRow = { ...newRow, isNew: true, id: randomId };
        startEditing(randomId, emptyRow);
        // setRowData((prev) => [emptyRow, ...prev]);
        setRowData((prev) => [
          ...prev?.slice(0, page * 50),
          emptyRow,
          ...prev?.slice(page * 50, prev?.length + 1),
        ]);
      }
    };
    useImperativeHandle(ref, () => ({
      addNewRow: addNewRow,
      startEditing: startEditing,
      stopEditing: stopEditing,
    }));

    const columns = [
      new AGridColDef("examTimeCode", "Exam Time Code",false)
        .setMinWidth(150)
        .editable(true)
        .setFlex(0.5)
        .renderCellComponent((params) => params?.value)
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            <AInput
              placeHolder="Enter"
              value={rowForm?.examTimeCode}
              handler={(value) =>
                value?.length <= 20 && rowFormHandler(value, "examTimeCode")
              }
              style={{
                paddingRight: "20px",
                borderRadius: "4px",
                height: "40px",
              }}
            />
          </div>
        )),
      new AGridColDef("testType", "Test Type",false)
        .setMinWidth(150)
        .editable(true)
        .setFlex(0.5)
        .setValueGetter((params) => params?.value?.label)
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            <AAutoComplete
              currentValue={rowForm?.testType}
              handleChange={(value) => rowFormHandler(value, "testType")}
              style={selectStyles}
              isMulti={false}
              items={dropdown?.testType || []}
              autoFocus={params?.hasFocus}
            />
          </div>
        )),

      new AGridColDef("term", "Term",false)
        .setMinWidth(150)
        .editable(true)
        .setFlex(0.5)
        .renderCellComponent((params) => (
          <MultiSelectCellComponentWithSearch
            title={"Term"}
            data={params?.value}
            eyeIcon
          />
        ))
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            <AAutoComplete
              currentValue={rowForm?.term}
              handleChange={(value) => rowFormHandler(value, "term")}
              items={dropdown?.term}
              isMulti={true}
              isShowCount={true}
              style={selectStyles}
              autoFocus={params?.hasFocus}
            />
          </div>
        )),
      new AGridColDef("courseCategory", "Course Category",false)
        .setMinWidth(150)
        .editable(true)
        .setFlex(0.5)
        .renderCellComponent((params) => (
          <MultiSelectCellComponentWithSearch
            title={"Course Category"}
            data={params?.value}
            eyeIcon
          />
        ))
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            <AAutoComplete
              currentValue={rowForm?.courseCategory}
              handleChange={(value) => rowFormHandler(value, "courseCategory")}
              items={dropdown?.courseCategory}
              isMulti={true}
              isShowCount={true}
              style={selectStyles}
              autoFocus={params?.hasFocus}
            />
          </div>
        )),
      new AGridColDef("academicGroup", "Group",false)
        .setMinWidth(150)
        .editable(true)
        .setFlex(0.5)
        .renderCellComponent((params) => (
          <MultiSelectCellComponentWithSearch
            title={"Group"}
            data={params?.value}
            eyeIcon
          />
        ))
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            <AAutoComplete
              currentValue={rowForm?.academicGroup}
              handleChange={(value) => rowFormHandler(value, "academicGroup")}
              items={dropdown?.academicGroup}
              isMulti={true}
              isShowCount={true}
              style={selectStyles}
              autoFocus={params?.hasFocus}
            />
          </div>
        )),
      new AGridColDef("category1", "Category 1",false)
        .setMinWidth(120)
        .editable(true)
        .setFlex(0.5)
        .renderCellComponent((params) => (
          <SelectorCategory
            allCategories={allCategories}
            {...params}
            userPermissions={userPermissions}
            rowFormHandler={rowFormHandler}
            isView={true}
          />
        ))
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            <SelectorCategory
              allCategories={allCategories}
              {...params}
              userPermissions={userPermissions}
              rowFormHandler={rowFormHandler}
              isView={false}
            />
          </div>
        )),
      new AGridColDef("categoryType", "Category Type",false)
        .setMinWidth(150)
        .editable(true)
        .setFlex(0.5)
        .setValueGetter((params) => params?.value?.label)
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            <AAutoComplete
              currentValue={rowForm?.categoryType}
              handleChange={(value) => {
                rowFormHandler(value, "categoryType");
                rowFormHandler('', "additionalCategoryCutoff");
              }}
              items={dropdown?.categoryType}
              isMulti={false}
              style={selectStyles}
              autoFocus={params?.hasFocus}
            />
          </div>
        )),
      new AGridColDef("additionalCategory", "Additional Category",false)
        .setMinWidth(160)
        .editable(true)
        .setFlex(0.5)
        .renderCellComponent((params) => (
          <>
            <AdditionalCategoryDialog
              allCategories={allCategories}
              userPermissions={userPermissions}
              rowFormHandler={rowFormHandler}
              rowForm={rowForm}
              isView={true}
              params={params}
              isViewAdditional={true}
            />
          </>
        ))
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            <AdditionalCategoryDialog
              allCategories={allCategories}
              userPermissions={userPermissions}
              rowFormHandler={rowFormHandler}
              rowForm={rowForm}
              params={params}
            />
          </div>
        )),
      new AGridColDef("status", "Status", false)
        .setMinWidth(120)
        .setFlex(0.5)
        .renderCellComponent((params) => (
          <StatusCellComponent
            params={params}
            toggleStatusHandler={toggleStatusHandler}
            rowForm={rowForm}
            rowFormHandler={rowFormHandler}
          />
        )),
      new AGridColDef("updatedOn", "Updated on/by", false)
        .setMinWidth(150)
        .setFlex(0.5)
        .renderCellComponent((params) =>
          params?.value ? (
            <div>
              {moment(params?.value)?.format("DD-MMM-YYYY")}
              <Tooltip
                title={`${getFormattedDate(params?.value)},
                    ${moment(params?.value).format("LT")} by ${
                  params?.row?.updatedBy
                }`}
              >
                <span>
                  <IoMdInformationCircleOutline
                    className="ml-2 pointer"
                    size={18}
                  />
                </span>
              </Tooltip>
            </div>
          ) : (
            <div>-</div>
          )
        ),
      new AGridColDef("actions", "Actions", false)
        .setMinWidth(120)
        .columnAlign("right")
        .setFlex(0.5)
        .renderCellComponent((params) => (
          <ActionsCellComponent
            params={params}
            handleEditClick={handleEditClick}
            handleCancelClick={handleCancelClick}
            handleSaveClick={handleSaveClick}
            rowForm={rowForm}
            deleteHandler={deleteHandler}
          />
        )),
    ];
    return (
      <div className="mt-3">
        <ADataGrid
          removeWrapperContainer
          rowModesModel={rowModesModel}
          rows={rowData || []}
          columns={columns}
          rowId={(row) => row?.id}
          rowHeight={50}
          loading={false}
          rowCount={rowCount}
          currentPage={page}
          nextButtonHandler={onPageChange}
          components={{
            ...gridComponents,
            NoRowsOverlay: () => (
              <NoRowOverlay message={loader ? "Loading..." : null} />
            ),
          }}
          sx={{
            ...gridStyles,
          }}
          autoHeight
          hidePagination={rowCount <= 50}
        />
      </div>
    );
  }
);
export default ListComponent;
