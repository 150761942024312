import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Col, Button, Input } from "reactstrap";
import { GoArrowLeft } from "react-icons/go";
import CustomLoader from "views/pages/manage/common/commonComponents/Loader/loader";
import { CustomContainer } from "views/pages/manage/common/commonComponents";
import { CustomCard } from "views/pages/manage/common/commonComponents";
import {
  fetchDetails,
  getRequiredSign,
} from "../../common/utils/methods/commonMethods/utilityMethod";
import { AsyncSelectSearch } from "../../common/commonComponents";
import {
  failureToast,
  enrollmentServiceBaseUrl,
  getAPI,
  masterServiceBaseUrl,
} from "services/http";
import { endpoint } from "../../common/constant";

const Cardheader = ({ onBack = () => {} }) => {
  return (
    <div className="d-flex align-items-end">
      <GoArrowLeft color="#11CDEF" size={40} onClick={onBack} />
      <h3>New EPMR</h3>
    </div>
  );
};

const AddEmpr = (props) => {
  const {
    headerValues,
    setHeaderValues = () => {},
    resetHeader = () => {},
  } = props;

  const [groupCode, setGroupCode] = useState(null);
  const [companyCode, setCompanyCode] = useState(null);
  const [epmrDetails, setEpmrDetails] = useState({
    ASK_CENTERID: null,
    AKS_PSID: "",
    ASK_NAME: "",
    AKS_TERM: "",
    AKS_CRSCODE: "",
    AKS_PAYMODE: "",
    AKS_AMOUNT: "",
    AKS_CREATEDBY: "",
    CASHIER_OFFICE: "",
  });

  const [groupCodeData, setGroupCodeData] = useState(null);
  const [companyCodeData, setCompanyCodeData] = useState(null);
  const [businessData, setBusinessData] = useState(null);
  const [termData, setTermData] = useState(null);
  const [courseData, setCourseData] = useState(null);
  const [payModeData, setPaymodeData] = useState(null);

  useEffect(() => {
    fetchDetails(
      endpoint.groupCode.getAllActive,
      setGroupCodeData,
      "groupCode",
      "select"
    );
    fetchDetails(
      `${masterServiceBaseUrl}/term/getAllActiveTerm`,
      setTermData,
      "term",
      "select"
    );
    fetchDetails(
      `${masterServiceBaseUrl}/paymode/getAllActivePaymode`,
      setPaymodeData,
      "paymode",
      "select"
    );
  }, []);

  // const search = () => {
  //   if (!headerValues?.businessGroup?.value) {
  //     failureToast("Please Select Business Group");
  //     return;
  //   }
  //   if (!headerValues?.companyCode?.value) {
  //     failureToast("Please Select Comapny Code");
  //     return;
  //   }
  //   if (!headerValues?.businessArea?.value) {
  //     failureToast("Please Select Business Area");
  //     return;
  //   }

  //   //searchAttendanceMachine();
  // };

  const filterOption = (data) => {
    return data?.map((data) => {
      return Object.assign(
        {},
        {
          label: data?.studentId,
          value: data?.studentId,
          studentName: `${data?.studentFirstName} ${data?.studentLastName}`,
        }
      );
    });
  };

  const promiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      if (inputValue && inputValue.length < 3) {
        return resolve([]);
      }
      getAPI(
        `${enrollmentServiceBaseUrl}/studentDetails/getAllStudentBySmartSearch/${inputValue}`,
        (data) => {
          resolve(filterOption(data?.data));
        },
        (data) => {
          failureToast(data["message"]);
        }
      );
    });
  };

  return (
    <CustomContainer>
      <CustomCard HeaderElement={<Cardheader />}>
        {!groupCodeData ? (
          <div
            className="mx-auto text-center py-5 my-5"
            style={{ height: "100vh" }}
          >
            <CustomLoader apiLoader={!groupCodeData} />
          </div>
        ) : (
          <>
            <div className="form-row mb-3 pl-4 pr-4 pt-4">
              <Col className="mb-3" md="3">
                <label className="form-control-label">
                  Business Group{getRequiredSign()}
                </label>
                <Select
                  value={groupCode}
                  id="add_epmr_business_grp"
                  options={groupCodeData}
                  //isDisabled={isView}
                  placeholder="Select Business Group"
                  onChange={(sData) => {
                    setGroupCode(sData);
                    setCompanyCode(null);
                    setEpmrDetails({ ...epmrDetails, ASK_CENTERID: null });
                    setBusinessData(null);
                    setCompanyCodeData(null);
                    setCourseData(null);
                    fetchDetails(
                      `${endpoint.companyCode.getAllActiveByGroupCode}/${sData.value}`,
                      setCompanyCodeData,
                      "companyCode",
                      "select"
                    );
                  }}
                />
              </Col>
              <Col className="mb-3" md="3">
                <label className="form-control-label">
                  Company Code{getRequiredSign()}
                </label>
                <Select
                  value={companyCode}
                  id="add_epmr_company_code"
                  options={companyCodeData}
                  //isDisabled={isView}
                  placeholder={"Select Company Code"}
                  onChange={(sData) => {
                    setCompanyCode(sData);
                    setEpmrDetails({ ...epmrDetails, ASK_CENTERID: null });
                    setBusinessData(null);
                    setCourseData(null);
                    groupCode?.value &&
                      fetchDetails(
                        `${endpoint.businessArea.getAllActiveByGroupCodeAndComapnyCode}?companyCode=${sData.value}&groupCode=${groupCode?.value}`,
                        setBusinessData,
                        "businessArea",
                        "select"
                      );
                    groupCode?.value &&
                      fetchDetails(
                        `${masterServiceBaseUrl}/courseCatalogCourseHeader/getCourseCatalogCourseHeaderByCompanyCode?companyCode=${sData.value}`,
                        setCourseData,
                        "courseId",
                        "courseId"
                      );
                  }}
                  isLoading={groupCode && !companyCodeData}
                />
              </Col>
              <Col className="mb-3" md="3">
                <label className="form-control-label">
                  Business Area{getRequiredSign()}
                </label>
                <Select
                  value={epmrDetails.ASK_CENTERID}
                  id="add_epmr_business_area"
                  options={businessData}
                  //isDisabled={isView}
                  placeholder={"Select Business Area"}
                  onChange={(sData) => {
                    setEpmrDetails({ ...epmrDetails, ASK_CENTERID: sData });
                    //setAttendanceMachineData(null);
                  }}
                  isLoading={
                    headerValues?.businessGrp?.value &&
                    headerValues?.companyCode?.value &&
                    !businessData
                  }
                />
              </Col>
              <Col md="3">
                <label className="form-control-label">
                  Student Id{getRequiredSign()}
                </label>
                <AsyncSelectSearch
                  value={epmrDetails.AKS_PSID}
                  placeholder={"Select Student"}
                  onChangeHandler={(sData) => {
                    setEpmrDetails({
                      ...epmrDetails,
                      AKS_PSID: sData,
                      ASK_NAME: sData.studentName,
                      AKS_TERM: "",
                      AKS_CRSCODE: "",
                    });
                  }}
                  promiseHandler={promiseOptions}
                />
              </Col>
            </div>
            <div className="form-row mb-3 pl-4 pr-4">
              <Col className="mb-3" md="3">
                <label className="form-control-label">Student Name</label>
                <Input
                  value={epmrDetails.ASK_NAME}
                  type="text"
                  disabled
                  style={{ height: "39px" }}
                />
              </Col>
              <Col md="3">
                <label className="form-control-label">
                  Term{getRequiredSign()}
                </label>
                <Select
                  value={epmrDetails.AKS_TERM}
                  id="add_epmr_company_code"
                  options={termData}
                  placeholder={"Select Term"}
                  onChange={(sData) => {
                    setEpmrDetails({ ...epmrDetails, AKS_TERM: sData });
                  }}
                  isLoading={!termData}
                />
              </Col>
              <Col md="3">
                <label className="form-control-label">
                  Course ID{getRequiredSign()}
                </label>
                <Select
                  value={epmrDetails.AKS_CRSCODE}
                  id="add_epmr_company_code"
                  options={courseData}
                  placeholder={"Select Term"}
                  onChange={(sData) => {
                    setEpmrDetails({ ...epmrDetails, AKS_CRSCODE: sData });
                  }}
                  isLoading={companyCode?.value && !courseData}
                />
              </Col>
              <Col md="3">
                <label className="form-control-label">
                  Pay Mode{getRequiredSign()}
                </label>
                <Select
                  value={epmrDetails.AKS_PAYMODE}
                  id="add_epmr_company_code"
                  options={payModeData}
                  placeholder={"Select Term"}
                  onChange={(sData) => {
                    setEpmrDetails({ ...epmrDetails, AKS_PAYMODE: sData });
                  }}
                  isLoading={!payModeData}
                />
              </Col>
            </div>
            <div className="form-row mb-3 pl-4 pr-4">
              <Col md="3">
                <label className="form-control-label">
                  Amount{getRequiredSign()}
                </label>
                <Input
                  value={epmrDetails.AKS_AMOUNT}
                  placeholder={`Amount`}
                  type="text"
                  onChange={(e) =>
                    setEpmrDetails({
                      ...epmrDetails,
                      AKS_AMOUNT: e.target.value,
                    })
                  }
                  style={{ height: "39px" }}
                />
              </Col>
              <Col md="3">
                <label className="form-control-label">
                  Created By{getRequiredSign()}
                </label>
                <Input
                  value={epmrDetails.AKS_CREATEDBY}
                  placeholder={`Created By`}
                  type="text"
                  disabled
                  style={{ height: "39px" }}
                />
              </Col>
              <Col md="3">
                <label className="form-control-label">
                  Confirmed By{getRequiredSign()}
                </label>
                <Input
                  value={epmrDetails.CASHIER_OFFICE}
                  placeholder={`Confirmed By`}
                  type="text"
                  disabled
                  style={{ height: "39px" }}
                />
              </Col>
            </div>

            <div className="form-row justify-content-end mb-3 pl-4 pr-4 pt-4">
              <Button
                color="info"
                size="sm"
                type="button"
                className="ml-3"
                //onClick={search}
              >
                Submit
              </Button>
            </div>
          </>
        )}
      </CustomCard>
    </CustomContainer>
  );
};

export default AddEmpr;
