import React, { useEffect, useState } from 'react'
import { Card, Table, Row } from 'reactstrap';
import { putpost } from "services/http";
import { MasterHeaderAuto } from "../../../../../common/commonComponents/masterHeader/masterHeader";
import CustomButton from "components/CustomButton";
import { Dialog, CustomUpload } from 'views/pages/manage/common/commonComponents';
// import { fetchAllPromisedData } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { getUniqueKey } from "../../../../../common/utils/methods/commonMethods/utilityMethod";
import { constants, endpoint, regex } from "../../../../../common/constant";
import CustomLoader from 'views/pages/manage/common/commonComponents/Loader/loader';
import CommonInput from 'views/pages/manage/common/formFeilds/input/commonInput';
import { ButtonText } from "variables/Buttons";
import { failureToast, successToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import CustomDownload from '../../../../../common/commonComponents/CustomDownload'

const SupportingDocument = ({
  userPermissions,
  isUploading,
  setISUploading = () => { },
  activeStep,
  goBack,
  description,
  records,
  allRecords,
  setRecords,
  setAllRecords,
  isSaveVisible,
  setIsSaveVisible,
  isFetching,
  addDataBeforeNext = () => { },
  setIsConfirmationOpen = () => { },
  isInManual = false,
  isPreview = false,
  isSupportingDocEditable=true,
  setSupportingDocEditable=()=>{}
}) => {

  const [isUploaderVisible, setUploaderVisible] = useState(false);
  const [docSelected, setSelectedDoc] = useState(null);

  //for upload
  const [isFileValid, setISFileValid] = useState(false);
  const [filename, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [progressBarStatus, setProgressBarStatus] = useState(0);
  const [tempData, setTempData] = useState(null);
  //   const [isUploading, setISUploading] = useState(false);

  //

  const headerList = [
    { name: "S.NO" },
    { name: "Document" },
    { name: "Document description" },
    // { name: "Mandatory" },
    { name: "Uploaded File Name" },
    { name: "Action" }
  ];

  //upload logics


  // const dataToServer = {
  //   bulk: true,
  //   fileName: null,
  //   docType: "csv",
  //   keyValue: `pricing_support_${getUniqueKey()}`,
  //   serviceName: constants.templates_service_name.pricing,
  //   sourceId: constants.sourceId
  // };
  const dataToServer = {
    bulk: false,
    bulkDoc: true,
    docType: "pdf",
    keyValue: `pricing_support_${getUniqueKey()}`,
    serviceName: constants.templates_service_name.pricing,
    sourceId: 1
  }

  const handleFile = (e) => {

    // alert("i m here in handle")

    let docTypeAllowed = [];
    let fileSizeAllowed = "";

    if (!docSelected) {
      docTypeAllowed = (tempData?.documentFormats && tempData?.documentFormats.toString().toLowerCase().split(',')) ?? [];
      fileSizeAllowed = tempData.documentSize;
    } else {
      docTypeAllowed = (docSelected?.documentFormats && docSelected.documentFormats.toString().toLowerCase().split(',')) ?? [];
      fileSizeAllowed = docSelected.documentSize;
    }

    try {
      if (docTypeAllowed.indexOf(e.name.split(".")[1].toString().toLowerCase()) > -1) {
        if (e.name.length <= 50) {
          let value = e.name.split(".")[0];
          // const regex = /^[A-Za-z0-9 _]*$/
          if (regex.alphaNumericWithSpaceAndUnderscore.test(value)) {
            if (e.size > (fileSizeAllowed * 1000)) {
              failureToast(`File size should be less than ${fileSizeAllowed} KB`)
            } else {
              setFileName(value);
              setFile(e);
              setISFileValid(true);
            }
          } else {
            failureToast("Only these special characters are allowed in file name:  Space and Underscore ")
          }
        } else {
          failureToast("File name should be less than 50 characters.")
        }
      } else {
        failureToast("Document Type not allowed")
      }
    } catch (e) {
      console.log(e)
    }
  };

  const saveFileAndReturnData = (res) => {

    try {

      const request = {
        "courseFeesProcessingDTO": {
          "key": res.key,
          "fileName": filename,
          "status": "ACTIVE",
          "processInstanceId": Number(res.processId)
        },
        "courseFeeSupportingDocumentDtos": null,
        "isProcess": false,
        "serviceName": "PRICING",
        "processId": res.processId
      }

      putpost(
        endpoint.fetchS3Url.updateToServer,
        (data) => {
          if (data.code === 200 && data.data) {
            const formData = {
              "key": res.key,
              "fileName": filename,
              "status": "ACTIVE",
              "processInstanceId": Number(res.processId)
            }

            let updatedRowObject = {};

            if (!docSelected) {
              updatedRowObject = {
                ...records[tempData.index],
                ...formData
              }
            } else {
              updatedRowObject = {
                ...docSelected,
                ...formData
              }
            }

            records[tempData.index] = updatedRowObject;
            setRecords(records);
            removeTempData()
          }
        },
        (data) => {
          setISUploading(false);
          failureToast(data["message"]);
        },
        request,
        "post"
      );

    } catch (e) {
      console.log(e)
    }
  };


  const reset = () => {
    setISFileValid(false);
    setFileName("");
    setFile(null);
    setProgressBarStatus(0);
    setUploaderVisible(false);
  };


  const temp = (item) => {
    setTempData(item);
    setUploaderVisible(true);
  }

  const removeTempData = () => {
    setTempData(null);
    setIsSaveVisible(false);
    setISUploading(false);
    reset();
  }

  const Validate = () => {
    let isValid = true;

    if (!isInManual && (!description || !description.toString().trim())) {
      failureToast(`Please enter description`)
      isValid = false
    }

    if (!isValid) {
      return
    }

    records.every((item, i) => {
      if (item.fileName) {
        isValid = true
        return true
      } else {
        failureToast(`Please upload document for row ${i + 1}`)
        isValid = false
        return false
      }
    })

    if (!isValid) {
      return
    }

    addDataBeforeNext('supportingDocument', records);
    setSupportingDocEditable(false)
  }

  const onCancel = (index) => {
    records.splice(index, 1)
    setRecords(records)
    setSelectedDoc(null)
    setIsSaveVisible(false)
  }

  return (
    <>
      <Card>
        {isFetching ?
          <div
            className="mx-auto text-center py-5 my-5"
            style={{ height: "100vh" }}
          >
            <CustomLoader apiLoader={isFetching} />
          </div> :
          <Table className="align-items-center table-flush">
            <MasterHeaderAuto headerList={headerList} permissionSet={userPermissions} isSaveVisible={isSaveVisible} />
            <tbody className="list">
              {records &&
                records.map((item, index) => (
                  item.__isNew ?
                    (
                      <tr>
                        <td className="text-center  white-breakSpace">
                          {index + 1}
                        </td>
                        <td className="text-center  white-breakSpace">
                          <CommonInput
                            defaultValue={docSelected}
                            type="select"
                            data={allRecords}
                            style={{ height: "39px" }}
                            placeHolder={"Select Document"}
                            onChange={(val) => {
                              setSelectedDoc(val)
                            }}
                          />
                        </td>
                        <td className="text-center  white-breakSpace">
                          {docSelected?.documentTypeDesc ?? '-'}
                        </td>
                        {/* <td className="text-center  white-breakSpace">
                      {item.isMandatory ? 'Yes' : 'No'}
                    </td> */}
                        <td className="text-center  white-breakSpace">
                          {item.fileName ? item.fileName : '-'}
                        </td>
                        <td className="text-center  white-breakSpace">
                          <CustomButton
                            type="upload"
                            className={'mx-1'}
                            // content={ButtonText.DOCUMENTMANAGEMENT.RESET}
                            permissionType={'C'}
                            icon={true}
                            onClick={() => temp({ ...item, index: index })}
                            forTable={true}
                            permissionSet={userPermissions}
                            disabled={isUploading || !docSelected?.value}
                          />

                          <CustomButton
                            className={'mx-1'}
                            // content={ButtonText.DOCUMENTMANAGEMENT.RESET}
                            permissionType={'cancel'}
                            icon={true}
                            onClick={() => onCancel(index)}
                            forTable={true}
                            permissionSet={userPermissions}
                            disabled={isUploading}
                          />
                        </td>
                      </tr>
                    ) :
                    (
                      <tr>
                        <td className="text-center  white-breakSpace">
                          {index + 1}
                        </td>
                        <td className="text-center  white-breakSpace">
                          {item.documentTypeDispValue}
                        </td>
                        <td className="text-center  white-breakSpace">
                          {item.documentTypeDesc}
                        </td>
                        {/* <td className="text-center  white-breakSpace">
                        {item.isMandatory ? 'Yes' : 'No'}
                      </td> */}
                        <td className="text-center  white-breakSpace">
                          {item.fileName ? item.fileName : '-'}
                        </td>
                        <td className="text-center  white-breakSpace">
                          <CustomButton
                            type="upload"
                            className={'mx-1'}
                            // content={ButtonText.DOCUMENTMANAGEMENT.RESET}
                            permissionType={'C'}
                            icon={true}
                            onClick={() => temp({ ...item, index: index })}
                            forTable={true}
                            permissionSet={userPermissions}
                            disabled={isUploading || isSaveVisible || !isSupportingDocEditable}
                          />

                          {!item.isMandatory && <CustomButton
                            className={'mx-1'}
                            // content={ButtonText.DOCUMENTMANAGEMENT.RESET}
                            permissionType={'D'}
                            icon={true}
                            onClick={() => temp({ ...item, index: index })}
                            forTable={true}
                            permissionSet={userPermissions}
                            disabled={isUploading || isSaveVisible}
                          />}

                          {/* {item.fileName && <CustomButton
                            type="download"
                            className={'mx-1'}
                            color="info"
                            // content={ButtonText.DOCUMENTMANAGEMENT.RESET}
                            permissionType={'R'}
                            icon={true}
                            onClick={() => window.open(`${item.filePath}`)}
                            forTable={true}
                            permissionSet={userPermissions}
                            disabled={isUploading || isSaveVisible}
                          />} */}
                          {item.key &&
                            <CustomDownload
                              downloadKey={item.key}
                              permissionSet={userPermissions}
                              isIcon={true}
                            />
                          }
                        </td>
                      </tr>
                    )
                ))}
            </tbody>
          </Table>
        }
      </Card>

      {
        activeStep == 1 && <Row className={`justify-content-end align-items-center mb-3 ${isInManual ? 'mr-2' : ''}`}>
          {!isPreview && <CustomButton
            content={ButtonText.UPLOADPAGE.NEXT}
            type="submit"
            className={'reset-button mx-1'}
            permissionType={'C'}
            // icon={true}
            onClick={Validate}
            permissionSet={userPermissions}
            disabled={isUploading || isSaveVisible}
          />}
          {/* <Button color={isFileValid ? "info" : "secondary"} disabled={!isFileValid || isUploading} onClick={getFilePath}>
            Submit
          </Button> */}
          {!isInManual && <CustomButton
            type="close"
            className={'reset-button mx-1'}
            content={ButtonText.UPLOADPAGE.CLOSE}
            permissionType={'C'}
            // icon={true}
            onClick={() => setIsConfirmationOpen(true)}
            permissionSet={userPermissions}
            disabled={isUploading || isSaveVisible}
          />}
        </Row>
      }
      <Dialog isModalVisible={isUploaderVisible} toggle={() => {setUploaderVisible(false);setIsSaveVisible(false)}} title="Upload Document" size="lg">
      {isUploaderVisible &&  <CustomUpload
          documentTitle="Upload Supporting Document"
          isFileValid={isFileValid}
          filename={filename}
          file={file}
          progressBarStatus={progressBarStatus}
          setISFileValid={setISFileValid}
          setFileName={setFileName}
          setFile={setFile}
          setProgressBarStatus={setProgressBarStatus}
          isUploading={isUploading}
          setISUploading={setISUploading}
          handleFile={handleFile}
          dataToServer={dataToServer}
          saveHandler={saveFileAndReturnData}
          userPermissions={userPermissions}
          goBack={() => removeTempData()}
          typeAllowed={!docSelected ? tempData?.documentFormats : docSelected?.documentFormats}
        // title={""}
        />}
      </Dialog>
    </>
  )
}

export default SupportingDocument