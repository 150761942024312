import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import AInput from '../../../common/formFeilds/customInput/AInput';
import moment from 'moment';
import AButton from '../../../common/form-fields-mui/AButton';
import ADataGrid from '../../../common/data-grid/ADataGrid';
import { GridRowModes } from '@mui/x-data-grid';
import { isObjEmpty } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { failureToast } from '../../../common/utils/methods/toasterFunctions/function';
import { saveOrDeleteMsg } from '../../../edpOps/stockManagement/mastersAndMapping/sapProfitCostCentreMapping/constant';
import { generateRandomId } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { AGridColDef } from '../../../common/data-grid/ADataGridColDef';
import { editCellContainerStyle } from '../../../edpOps/stockManagement/mastersAndMapping/onBoardingMaterialIdSetup/constant';
import AAutoComplete from '../../../common/form-fields-mui/AAutoComplete';
import { gridStyles, gridComponents } from '../../../edpOps/issuanceIDcard/printIdCardAndMapRfid/constant';
import { newRow, ActionsCellComponent, StatusCellComponent } from './common';
import ALoader from '../../../common/a-loader';
import NoRowOverlay from '../../holidayMaster/NoRowOverlay';


const ListComponent = ({
    businessAreaDropdown = [],
    rows = [],
    createUpdateHandler,
    loading,
    currentPage,
    totalRecords,
    pageChangeHandler,
}, ref) => {

    const [rowModesModel, setRowModesModel] = useState({});
    const [rowData, setRowData] = useState(rows);
    const [rowForm, setRowForm] = useState({});


    const rowFormHandler = (value, key) =>
        setRowForm((prev) => ({ ...prev, [key]: value }));

    useEffect(() => {
        setRowData(rows);
    }, [rows]);


    const startEditing = (id, form) => {
        setRowModesModel((oldModel) => ({
            [id]: { mode: GridRowModes.Edit, fieldToFocus: "financeInstitution" },
        }));
        setRowForm(form);
    };

    const stopEditing = () => {
        const currForm = { ...rowForm };
        setRowModesModel((oldModel) => ({
            [currForm?.id]: { mode: GridRowModes.View, ignoreModifications: true },
        }));
        setRowForm({});
    };

    const handleEditClick = (params) => {
        if (!isObjEmpty(rowForm)) {
            failureToast(saveOrDeleteMsg);
        } else {
            startEditing(params?.id, params?.row);
        }
    };

    const handleCancelClick = async (params) => {
        if (params?.row?.isNew) {
            setRowData(rows);
            setRowForm({});
        } else {
            stopEditing(params?.id);
        }
    };

    const handleSaveClick = async (params) => {
        createUpdateHandler(rowForm);
    };

    const addNewRow = async () => {
        if (!isObjEmpty(rowForm)) {
            failureToast(saveOrDeleteMsg);
        } else {
            let randomId = generateRandomId();
            let emptyRow = {
                ...newRow,
                isNew: true,
                id: randomId,
            };
            startEditing(randomId, emptyRow);
            setRowData((prev) => [emptyRow, ...prev]);
        }
    };

    useImperativeHandle(ref, () => ({
        addNewRow: addNewRow,
        startEditing: startEditing,
        stopEditing: stopEditing,
    }));

    const columns = [
        new AGridColDef('financeInstitution', 'Finance Partner Name', false)
            .setMinWidth(110)
            .setFlex(0.8)
            .editable(true)
            .setValueGetter(params => params?.value)
            .renderEditCellComponent(params => (
                <div style={editCellContainerStyle}>
                    <AInput
                        value={rowForm?.financeInstitution}
                        handler={val => rowFormHandler(val, 'financeInstitution')}
                        placeholder={'Enter'}
                        maxLength={50}
                    />
                </div>
            )),

        new AGridColDef('businessArea', 'Business Area', false)
            .setMinWidth(110)
            .setFlex(0.5)
            .editable(true)
            .setValueGetter(params => params?.value?.label)
            .renderEditCellComponent(params => (
                <div style={editCellContainerStyle}>
                    <AAutoComplete
                        currentValue={rowForm?.businessArea}
                        handleChange={value => {
                            rowFormHandler(value, 'businessArea');
                        }}
                        items={businessAreaDropdown}
                        isMulti={false}
                        //   style={selectStyles}
                        placeholder={'Select'}
                        autoFocus={params?.hasFocus}
                    />
                </div>
            )),
        new AGridColDef('merchantId', 'Merchant ID', false)
            .setMinWidth(110)
            .setFlex(0.8)
            .editable(true)
            .setValueGetter(params => params?.value)
            .renderEditCellComponent(params => (
                <div style={editCellContainerStyle}>
                    <AInput
                        value={rowForm?.merchantId}
                        handler={val => rowFormHandler(val, 'merchantId')}
                        placeholder={'Enter'}
                        maxLength={100}
                    />
                </div>
            )),
        new AGridColDef('key', 'Key', false)
            .setMinWidth(110)
            .setFlex(0.8)
            .editable(true)
            .setValueGetter(params => params?.value)
            .renderEditCellComponent(params => (
                <div style={editCellContainerStyle}>
                    <AInput
                        value={rowForm?.key}
                        handler={val => rowFormHandler(val, 'key')}
                        placeholder={'Enter'}
                        maxLength={100}
                    />
                </div>
            )),
        new AGridColDef('salt', 'Salt', false)
            .setMinWidth(110)
            .setFlex(0.8)
            .editable(true)
            .setValueGetter(params => params?.value)
            .renderEditCellComponent(params => (
                <div style={editCellContainerStyle}>
                    <AInput
                        value={rowForm?.salt}
                        handler={val => rowFormHandler(val, 'salt')}
                        placeholder={'Enter'}
                        maxLength={100}
                    />
                </div>
            )),
        new AGridColDef('status', 'Status', false)
            .setMinWidth(90)
            .setFlex(0.4)
            .editable(true)
            .renderCellComponent(params =>
                <StatusCellComponent value={params.value} />
            )
            .renderEditCellComponent(params => (
                <StatusCellComponent
                    value={rowForm?.status}
                    handleChange={val => rowFormHandler(val, 'status')}
                />
            )),

        new AGridColDef('updatedOn', 'Last Updated On')
            .setMinWidth(110)
            .setFlex(0.9)
            .editable(false)
            .renderCellComponent(params => (
                <div>{moment(params.value).format('DD-MMM-YYYY hh:mm a')}</div>
            )),

        new AGridColDef('actions', 'Action', false)
            .setMinWidth(110)
            .setFlex(0.6)
            .editable(true)
            // .pinColumnLast(true)
            .columnAlign('right')
            .renderCellComponent(params =>
                <ActionsCellComponent
                    mode={'view'}
                    handleEditClick={handleEditClick}
                    params={params}
                />
            )
            .renderEditCellComponent(params => (
                <ActionsCellComponent
                    mode={'edit'}
                    handleSaveClick={handleSaveClick}
                    handleCancelClick={handleCancelClick}
                    params={params}
                />
            )),

    ]

    return (
        <>
            {loading && <ALoader position='fixed' />}
            <ADataGrid
                removeWrapperContainer
                rowModesModel={rowModesModel}
                rows={rowData || []}
                columns={columns}
                rowId={(row) => row.id}
                rowHeight={50}
                loading={false}
                rowCount={totalRecords}
                nextButtonHandler={pageChangeHandler}
                currentPage={currentPage}
                components={{
                    ...gridComponents,
                    NoRowsOverlay: () => (
                        <NoRowOverlay message={loading ? "Loading..." : null} />
                    ),
                }}
                sx={gridStyles}
                autoHeight
            // pinLastColumn
            />
        </>
    );
}

export default forwardRef(ListComponent);