import React, { useState, useEffect, useMemo, useRef } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridRowModes, useGridApiContext } from '@mui/x-data-grid';
import CustomButton from 'components/CustomButton';
import CustomLoader from '../../common/commonComponents/Loader/loader';
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import { BiSortAlt2 } from "react-icons/bi";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { Row, Col } from "reactstrap";
import { ConfirmationDialog, SearchFilterView } from 'views/pages/manage/common/commonComponents';
import Popover from '@mui/material/Popover';
import CommonInput, { customStylesSelect, stylesForFilledSelect, DropdownIndicator } from '../formFeilds/input/commonInput';
import Select from "react-select";
import { Delete } from 'assets/img/icons/common/CommonIcons';
import { IconButton, Stack } from '@mui/material';
import APopover from '../../common/a-popover/index'
import EditBranchesBasedOnBusinessArea from '../../common/branchesCommonComponent/EditBranchesBasedOnBusinessArea'
import ViewBranchesBasedOnBusinessArea from '../../common/branchesCommonComponent/ViewBrnchesBasedOnBusinessArea'
import { ReactComponent as EditIcon } from '../../../../../assets/img/svg/Edit2.svg'
import { ConfirmationDeleteDialog } from '.';
import { Tooltip } from '@material-ui/core';
import deleteIcon from '../../../../../assets/img/svg/Delete.svg';
import editIcon from '../../../../../assets/img/svg/Edit2.svg'


const CELL_HEIGHT = 36;
const ROW_PADDING = 10;
const DEFAULT_HEADER_HEIGHT = 56;
const SCROLLBAR_WIDTH = 16;

const CustomPagination = ({ pageSize, rowsCount, page, setPage }) => {

  const pageCount = useMemo(() =>
    Math.floor(rowsCount / pageSize) + (rowsCount % pageSize === 0 ? 0 : 1),
    [rowsCount, pageSize]);

  const options = useMemo(() => [...Array(pageCount).keys()].map(item => ({ label: item + 1, value: item })), [pageCount]);

  const val = options.find(item => item.value === page);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }} className="mui-table-pagination">
      <div>
        {rowsCount !== 0 && <span>{page * pageSize + 1}-{page === pageCount - 1 ? rowsCount : pageSize * (page + 1)} of&nbsp;</span>}
        {rowsCount}&nbsp;items</div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Select
           styles={{
            control: (provided) => ({
              ...provided,
              backgroundColor: 'rgba(0,0,0,0.1)', // Set the background color to gray
              height: '10px', // Set the height to 20px
              border: 'none', // Remove the border
            }),
            menu: (provided) => ({
              ...provided,
              border: 'none', // Remove the border around the menu
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected ? 'gray' : 'white',
              color: state.isSelected ? 'white' : 'black',
            }),
            menuPortal: base => ({ ...base, zIndex: 9999 }),
            input: (provided) => ({
              ...provided,
              margin : '0 !important'
            })
          }}
          components={{
            IndicatorSeparator: () => null
          }}
          value={val}
          options={options}
          onChange={(e) => {
            setPage(e.value);
          }}
          menuPortalTarget={document.body}
        />
        <div className='mr-3 ml-2'>of {pageCount} pages</div>
        <div style={{ cursor: 'pointer' }}>
          <Tooltip title="Previous Page"><IconButton  onClick={() => setPage(page - 1 < 0 ? 0 : page - 1)} ><MdKeyboardArrowLeft size={24}/></IconButton></Tooltip>
          <Tooltip title="Next Page"><IconButton  onClick={() => setPage(page + 1 > pageCount - 1 ? pageCount - 1 : page + 1)} ><MdKeyboardArrowRight className='ml-2' size={24}  /></IconButton></Tooltip>
        </div>
      </div>
    </div>
  );
}

const MultiSelect = (props) => {
  const { id, value, field, col, row, multiselectWithportal } = props;
  const apiRef = useGridApiContext();

  const options = [{ label: "All", value: "*" }, ...col.valueOptions].map(opt => ({ ...opt, ...(col.loading && { isDisabled: true }) }));

  const handleChange = (newValue) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });

    const fieldsToEdit = col.affects || [];
    fieldsToEdit.forEach(f => {
      const v = Array.isArray(row[f]) ? [] : '';
      apiRef.current.setEditCellValue({ id: id, field: f, value: v });
    })
  }

  function getValue() {
    const values = [];
    value.forEach(val => {
      const el = options.find(item => item.value === val.value);
      if (el) values.push(el);
    })

    return values;
  }

  return (
    <div style={{ width: "100%" }} className='muiMultiSelectWithCheckboxes'>
      <CommonInput
        type={'multiSelectWithCheckboxes'}
        forTable
        placeHolder={col.placeHolder ?? ""}
        defaultValue={getValue()}
        data={options}
        onChange={(value) => {
          handleChange(value);
        }}
        isDisabled={col.disabled}
        loading={col.loading ?? false}
        onFocus={col.onFocus ? () => col.onFocus(row, col) : (() => { })}
        multiselectWithportal={multiselectWithportal}
      />
    </div>
  )
}

const SingleSelect = (props) => {
  const { id, value, field, col, row, options } = props;
  const apiRef = useGridApiContext();

  const handleChange = (newValue) => {
    apiRef.current.setEditCellValue({ id, field, value: [newValue] });

    const fieldsToEdit = col.affects || [];
    fieldsToEdit.forEach(f => {
      const v = Array.isArray(row[f]) ? [] : '';
      apiRef.current.setEditCellValue({ id, field: f, value: v });
    })
  }
  const val = value?.[0] ? options.find(item => item.value === value[0].value) : null;
  console.log(col.disabled)

  return (
    <div style={{ width: '100%' }} className='muiSingleSelect'>
      <Select
        styles={val ? stylesForFilledSelect : customStylesSelect}
        placeholder={col.placeHolder ?? ""}
        value={val}
        isDisabled={col.disabled}
        options={options}
        onChange={(e) => {
          handleChange(e);
        }}
        menuPortalTarget={document.body}
        components={{ DropdownIndicator }}
      />
    </div>

  )
}

const CommonField = (props) => {
  const { id, value, field, col } = props;
  const apiRef = useGridApiContext();

  const handleChange = (newValue) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  }

  return (
    <>
      {col.type === "text" && (
        <CommonInput
          type={'text'}
          defaultValue={value}
          placeHolder={col.placeHolder ?? ""}
          maxLength={400}
          restrictSpecialCharacter={col.restrictSpecialCharacter ?? true}
          onChange={value => handleChange(value)}
          forTable={true}
          regEx={col.regEx}
        />
      )}
      {col.type === "number" && (
        <CommonInput
          type={'number'}
          maxLength={col.maxLength ?? 100}
          forTable={true}
          allowDecimalvalue={col.allowDecimalvalue ?? false}
          defaultValue={value}
          placeHolder={col.placeHolder ?? ""}
          onChange={value => {
            if (col.maxValue && +value > col.maxValue) {
              return;
            } else {
              handleChange(value)
            }
          }}
        />
      )}
      {col.type === "date" && (
        <CommonInput
          type="datePicker"
          placeHolder={col.placeHolder ?? ""}
          defaultValue={value}
          onChange={(val) => { handleChange(val) }}
          isDisabled={col.disabled}
          forTable={true}
          minDate={col.minDate ?? new Date()}
          maxDate={col.maxDate ?? new Date('2099-01-01')}
        />
      )}
    </>


  )
}

const Toggle = (props) => {
  const { id, value, field, col, row, cellMode } = props;
  const apiRef = useGridApiContext();

  const handleChange = (newValue) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  }

  return (
    <div style={{ width: '100%' }}>
      <CommonInput
        type="toggle"
        dataLabelOff={col.dataLabelOff}
        dataLabelOn={col.dataLabelOn}
        defaultValue={value}
        // placeHolder="Enter status"
        onChange={(val) => handleChange(val)}
        forTable
        readOnly={cellMode === "view"}
      />
    </div>

  )
}

const Para = ({ defaultValue, col }) => {

  const { tempString, options, moreCount } = useMemo(() => {
    let tempString = "";
    let options = [];
    let moreCount = 0;
    if (defaultValue?.length) {
      if (defaultValue.length === 1) {
        tempString = defaultValue[0].label;
        options = [...defaultValue];
        moreCount = 0;
      }
      else if (defaultValue?.[0].label === "All") {
        tempString = "All";
        options = [...defaultValue.slice(1)];
        moreCount = 0;
      } else {
        tempString = defaultValue[0].label;
        options = [...defaultValue];
        moreCount = options?.length - 1;
      }
    }

    return { tempString, options, moreCount };
  }, [defaultValue]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? crypto.randomUUID() : undefined;

  return (
    tempString &&
    <div style={{ width: '100%', cursor: 'pointer' }}
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <div onClick={handleClick} style={{ display: 'inline-block', overflowX: 'hidden' }}>
        {tempString} {moreCount ? <span style={{ color: "#00b0f5" }}>{` +${moreCount} more`}</span> : ``}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <SearchFilterView open={true} onClickOutside={() => 1} options={options} label={col.headerName} />
      </Popover>
    </div>
  )
}

const BranchCellComponent = (props) => {
  const editIconStyles = { height: '15px', width: '15px', cursor: 'pointer', marginLeft: '5px' }

  const { id, value, field, col, row } = props;
  const apiRef = useGridApiContext();

  const handleChange = (newValue) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue?.businessArea ?? [] });
  }

  // console.log(value?.[0])

  return <APopover
    buttonComponent={(onClick, styles) => <span onClick={onClick} style={editIconStyles} >
      {value?.[0]?.['label']} <span style={{ color: '#00B0F5', marginLeft: '1px' }}>
                {value?.length > 1 ? (<span style={{ cursor: 'pointer' }}>{`+${value?.length - 1} more`}</span>) : null }
                </span>
      View/Edit
      
    </span>}
    menuComponent={(closeForm) =>
      // <RegionBusinessAreaForm
      //   key={params.id}
      //   closeForm={closeForm}
      //   // regionBusinessAreaForm={regionBusinessAreaForm}
      //   // setRegionBusinessAreaForm={setRegionBusinessAreaForm}
      //   // inputHandler={inputHandler}
      //   updateHandler={updateHandler}
      //   planData={planData}
      // />
      <EditBranchesBasedOnBusinessArea
        closeForm={closeForm}
        businessAreas={Array.isArray(value) ? value?.map(item => ({ label: item?.dispValue ?? item?.label, value: item?.id ?? item?.value })) : []}
        updateHandler={(updatedForm) => handleChange(updatedForm)}
      />
    }
    anchorOriginVertical={"top"}
    transformOriginVertical={"bottom"}
  />
}






/** MUITable starts */








function MuiTable(props) {

  const {
    rows = [],
    columns = [],
    headerComp = "",
    onCellValueChange = () => { },
    onEditClick = () => { },
    onCancelClick = () => { },
    onAddRewRow = () => { },
    userPermissions = "",
    loading = false,
    onRowChange = (rows) => { },
    onRowDelete = (row) => { },
    asyncDeleteRow = (row) => Promise.resolve(row),
    asyncSaveRow = (row) => Promise.resolve(row),
    useDeleteIconForCancel = false,
    validationList = [],
    maxColunmWidth = '90px',
    NoRowsOverlay,
    useCustomOverLay = false,
    actionSeperatorRequired = true,
    headerMiscellaneousRightComp,
    editMode = 'col',
    FilterComp,
    customFilterRequired = false,
    extraHeight = 200,
    maxHeight = null,
    minHeight = null,
    readOnly = false,
    pageSize = 10,
    disableVirtualization = false,
    rowClassifier = "id",
    rowsCountInEditMode,
    newRowLabel = "Add new row",
    newRowHeaderClassName='',
    multiselectWithportal,
    outlined=true,
    defaultEditRowId
  } = props;

  const [muiRows, setMuiRows] = useState(rows);
  const [rowModesModel, setRowModesModel] = useState({});

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [page, setPage] = useState(0);

  useEffect(() => {
    if(defaultEditRowId) handleEditClick(defaultEditRowId)
    setMuiRows(rows);
  }, [rows,defaultEditRowId]);

  const prevRowsVal = useRef({}); // used for returning to previous saved value of row on cancel click

  const currRowId = useRef(null); // used for storing current id on delete click

  const currRowsRef = useRef({}); // changes everytime cell value changes to update ui


  const muiColumns = useMemo(() => {

    const cols = columns.map(col => {
      if (col.type === 'businessArea') {
        return {
          ...col,
          valueParser: (value, params) => {
            onCellValueChange(value, params);
            handleCellValueChange(value, params)
            return value;
          },
          renderEditCell: (params) => {
            return <BranchCellComponent {...params} col={col} />
          },
          renderCell: (params) => {
            let values = params.value;
            const bus = Array.isArray(values) && values?.[0]?.label ? values.map(i => { return { id: i.value, dispValue: i.label } }) : values
            return <span>
              {values?.[0]?.['businessAreaDispValue'] ?? values?.[0]?.['label']} <span style={{ color: '#00B0F5', marginLeft: '1px' }}>
                {values?.length > 1 ? (
                  <APopover
                    buttonComponent={(onClick, styles) => <span onClick={onClick} style={{ cursor: 'pointer' }}>{`+${values?.length - 1} more`}</span>}
                    menuComponent={(closeForm) => <ViewBranchesBasedOnBusinessArea businessAreas={bus} closeForm={closeForm} />}
                    anchorOriginVertical={"top"}
                    transformOriginVertical={"bottom"}
                  />
                ) : (
                  ''
                )}
              </span>
            </span>
          }
        }
      }
      else if (col.type === 'multiSelect') {
        return {
          ...col,
          valueParser: (value, params) => {
            onCellValueChange(value, params);
            handleCellValueChange(value, params)
            return value;
          },
          renderEditCell: (params) => {
            return <MultiSelect {...params} col={col} multiselectWithportal={multiselectWithportal}/>
          },
          renderCell: (params) => {
            let values = params.value;
            return (
              <Para defaultValue={values} col={col} />
            );
          },
        }
      }
      else if (col.type === 'singleSelect') {
        return {
          ...col,
          valueParser: (value, params) => {
            onCellValueChange(value, params);
            handleCellValueChange(value, params)
            return value;
          },
          renderEditCell: (params) => {
            const disable = params?.colDef?.getIsFeildDisabled ? params?.colDef?.getIsFeildDisabled(params?.row) : params?.colDef?.disabled;
            const options = col?.valueOptionsGetter ? col?.valueOptionsGetter(params) : col?.valueOptions;
            console.log(disable)

            return <SingleSelect {...params} col={{...col,disabled: disable}} options={options} />
          },
          valueFormatter: (params) => {
            return params.value?.[0]?.label || "";
          }
        }
      }
      else if (col.type === 'text' || col.type === "number" || col.type === 'date') {
        return {
          ...col,
          valueParser: (value, params) => {
            onCellValueChange(value, params);
            handleCellValueChange(value, params)
            return value;
          },
          renderEditCell: (params) => {
            return <CommonField {...params} col={col} />
          },
          valueFormatter: (params) => {
            return col.formatter?.(params.value) || params.value;
          }
        }
      }
      else if (col.type === 'toggle') {
        return {
          ...col,
          valueParser: (value, params) => {
            onCellValueChange(value, params);
            handleCellValueChange(value, params)
            return value;
          },
          renderEditCell: (params) => {
            return <Toggle {...params} col={col} />
          },
          renderCell: (params) => {
            return <Toggle {...params} col={col} />
          }
        }
      }
      return {
        ...col,
        valueParser: (value, params) => {
          onCellValueChange(value, params);
          handleCellValueChange(value, params);
          return value;
        },
        valueFormatter: (params) => params.value
      };
    })

    return readOnly ? [...cols.map(col => ({ ...col, headerClassName: col.mandatory ? 'col-mandatory' : '' }))] :
      [...cols.map(col => ({ ...col, headerClassName: col.mandatory ? 'col-mandatory' : '' })),
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 130,
        cellClassName: 'actions',
        getActions: ({ id, row }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <CustomButton
                className='muiTable-button-save'
                content={"Save"}
                forTable={true}
                permissionType={'C'}
                permissionSet={userPermissions}
                onClick={() => handleSaveClick(id)}
              />,
              <CustomButton
                className='muiTable-button-cancel'
                icon={!useDeleteIconForCancel}
                forTable={true}
                type="cancel"
                content={useDeleteIconForCancel ? Delete : ''}
                permissionType={'cancel'}
                permissionSet={userPermissions}
                onClick={() => handleCancelClick(id)}
              />,
            ];
          }

          return [ 
            <CustomButton
              className='muiTable-button-edit btn-transparent'
              icon={false}
              content={<Tooltip title="Edit" placement='top-start'><img src={editIcon} /></Tooltip>}
              forTable={true}
              permissionType={'U'}
              permissionSet={userPermissions}
              onClick={() => handleEditClick(id)}
              disabled={rowsCountInEditMode === 1 || row?.editable === false }
            />,
            <CustomButton
              className='muiTable-button-delete btn-transparent'
              icon={false}
              content={<Tooltip title="Delete" placement='top-start'><img src={deleteIcon} /></Tooltip>}
              forTable={true}
              permissionType={'D'}
              permissionSet={userPermissions}
              onClick={() => handleDeleteClick(id)}
              disabled={rowsCountInEditMode === 1}
            />,
          ];
        },
      },
      ]
  }, [columns, rowModesModel, muiRows, readOnly, rowsCountInEditMode]);

  function handleCellValueChange(val, params) {
    const fieldsToEdit = params.colDef?.affects || [];
    const currentRow = muiRows.find((row) => row.id === params.id);
    currentRow[params.field] = val;
    fieldsToEdit.forEach(field => {
      const newValue = Array.isArray(currentRow[field]) ? [] : '';
      currentRow[field] = newValue;
    })
    currRowsRef.current[params.id] = { ...currentRow };
    const updatedRows = muiRows.map(row => row.id === params.id ? currentRow : row);
    setMuiRows(updatedRows);
  }

  function handleRowEditStart(params, event) {
    event.defaultMuiPrevented = true;
  };

  function handleRowEditStop(params, event) {
    event.defaultMuiPrevented = true;
  };

  function handleEditClick(id) {
    onEditClick(id);
    currRowId.current = id;
    const currentRow = muiRows.find((row) => row.id === id);
    currRowsRef.current[id] = { ...currentRow };
    prevRowsVal.current[id] = { ...currentRow };
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit, fieldToFocus: muiColumns[0].field } });
  };

  function handleSaveClick(id) {
    const isValid = isRowValid(currRowsRef.current[id]);
    if (isValid) {
      const updatedRow = { ...currRowsRef.current[id], isNew: false };

      asyncSaveRow(updatedRow)
        .then((modifiedRow) => {
          const updatedRows = muiRows.map((row) => (row.id === currRowsRef.current[id].id ? modifiedRow : row));
          setMuiRows(updatedRows);
          onRowChange(updatedRows);
          setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });
          currRowId.current = null;
          currRowsRef.current[id] = null;
        })
        .catch(err => console.error(err));
    }
  };

  function handleDeleteClick(id) {
    currRowId.current = id;
    setOpenDeleteDialog(true);
  };

  function deleteRow(id) {
    setOpenDeleteDialog(false);
    const rowToDelete = muiRows.find(row => row.id === id);

    asyncDeleteRow(rowToDelete)
      .then((deletedRow) => {
        onRowDelete(deletedRow);
        setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
        const updatedRows = muiRows.filter((row) => row.id !== id);
        setMuiRows(updatedRows);
        onRowChange(updatedRows);
        currRowId.current = null;
      })
      .catch(err => console.error(err));

  }

  function handleCancelClick(id) {
    onCancelClick(id);
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = muiRows.find((row) => row.id === id);
    if (editedRow.isNew) {
      const updatedRows = muiRows.filter((row) => row.id !== id);
      setMuiRows(updatedRows);
      onRowChange(updatedRows);
    }
    else {
      const updatedRows = muiRows.map(row => row.id === id ? prevRowsVal.current[id] : row);
      setMuiRows(updatedRows);
      prevRowsVal.current[id] = null;
    }
    currRowId.current = null;
    currRowsRef.current[id] = null;
  };

  // function processRowUpdate(newRow) {
  //   console.log("updated")
  //   validateRow(newRow);
  //   const updatedRow = { ...newRow, isNew: false };
  //   const updatedRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row));
  //   setMuiRows(updatedRows);
  //   onRowChange(updatedRows);
  //   return updatedRow;
  // };

  function onCellDoubleClick(params, event) {
    event.defaultMuiPrevented = true;
  }

  const addNewRow = () => {
    onAddRewRow();
    const id = muiRows.length ? Math.max(...muiRows.map(row => row.id)) + 1 : 0;
    const blankRow = columns.reduce((acc, curr) => {
      let val = '';
      if (curr['initialValue']) {
        val = curr['initialValue'];
      } else {
        if (curr['type'] === 'singleSelect' || curr['type'] === 'multiSelect') {
          val = [];
        } else if (curr['type'] === 'toggle') {
          val = curr.dataLabelOff || "";
        }
      }
      return { ...acc, [curr['field']]: val };
    }, { concessionType :'DEFINED'});
    // console.log(blankRow)
    currRowsRef.current[id] = { ...blankRow, id, isNew: true };
    setMuiRows((oldRows) => [{ ...blankRow, id, isNew: true }, ...oldRows]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: muiColumns[0].field },
    }));
  };

  function isRowValid(currRow) {

    if (validationList.length === 0) {
      let isValid = true;
      props.columns.every(col => {
        if (col.mandatory) {
          if (currRow[col.field] === "" || currRow[col.field] === null || currRow[col.field] === undefined || currRow[col.field].length === 0) {
            isValid = false;
            failureToast(`${col.headerName} is required!`);
            return false;
          }
          return true;
        }
        return true;
      });

      return isValid;
    }

    return validationList.every((element) => {
      const valid = element.validateFn(currRow);
      if (!valid) failureToast(element.message);
      return valid;
    });
  }

  return (
    <>
      <Row className="d-flex justify-content-between align-items-center pl-3 pr-3">
        {headerComp}
        <div className="d-flex align-items-center mui-table-right-header py-4">
          {headerMiscellaneousRightComp}
          {readOnly === false && <CustomButton
            className={`floatRight regular-large ${outlined?'outline-btn':''} ${newRowHeaderClassName}`}
            content={newRowLabel}
            outline={outlined}
            permissionType={'C'}
            permissionSet={"C,R,U,D,A"}
            onClick={() => addNewRow()}
            disabled={loading || rowsCountInEditMode === 1}
          />}
        </div>
      </Row>
      <Box sx={{
        width: '100%',
        position: 'relative',
        '.MuiDataGrid-root': {
          backgroundColor: '#ffffff',
          padding: "10px 20px",
          borderRadius: "12px !important",
          color: '#262626',
          fontFamily: 'Gilroy-Regular, sans-serif',
          border: '1px solid rgba(224, 224, 224, 1) !important',
        },
      }}>
        {loading && <div className='d-flex align-items-center justify-content-center' style={{ position: 'absolute', top: 0, width: '100%', height: '100%', zIndex: 10, background: 'white', opacity: 0.9 }}>
          <CustomLoader
            apiLoader={true}
            loaderHeight={'100%'}
            loaderWidth={'100%'}
          />
        </div>}
        {/* <div className='d-flex justify-content-end'>
          {filterComp}
        </div> */}
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              initialState={{
                pinnedColumns: { right: ['actions'] },
              }}
              rows={muiRows}
              columns={muiColumns}
              rowModesModel={rowModesModel}
              // onRowModesModelChange={(newModel) => {setRowModesModel(newModel); console.log(newModel)}}
              onRowEditStart={handleRowEditStart}
              onRowEditStop={handleRowEditStop}
              // processRowUpdate={processRowUpdate}
              onCellDoubleClick={onCellDoubleClick}
              // onProcessRowUpdateError={(error) => failureToast(error.message)}
              page={page}
              pageSize={pageSize}
              rowsPerPageOptions={[pageSize]}
              // checkboxSelection
              // disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              components={{
                ColumnUnsortedIcon: () => (<BiSortAlt2 />),
                Pagination: muiRows?.length > pageSize ? CustomPagination : () => null,
                NoRowsOverlay: useCustomOverLay ? NoRowsOverlay : () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    No rows
                  </Stack>
                ),
                Panel: customFilterRequired ? FilterComp : () => null
              }}
              componentsProps={{
                pagination: { pageSize: pageSize, rowsCount: muiRows.length, page, setPage }
              }}
              rowHeight={'100%'}
              editMode={editMode}
              disableVirtualization={disableVirtualization}
              getRowClassName={(params) => `mui-row--${params.row[rowClassifier]}`}
              sx={{
                '.MuiDataGrid-main': {
                  height: `${(muiRows.length * (CELL_HEIGHT + 2 * ROW_PADDING)) + DEFAULT_HEADER_HEIGHT + SCROLLBAR_WIDTH}px`,
                  maxHeight: `${maxHeight ? maxHeight + SCROLLBAR_WIDTH : ((pageSize * (CELL_HEIGHT + 2 * ROW_PADDING)) + DEFAULT_HEADER_HEIGHT + SCROLLBAR_WIDTH)}px`,
                  minHeight: `${minHeight ? minHeight + SCROLLBAR_WIDTH : (CELL_HEIGHT + 2 * ROW_PADDING) + DEFAULT_HEADER_HEIGHT + SCROLLBAR_WIDTH}px`
                },
                '.muiMultiSelectWithCheckboxes button': {
                  padding: '5px 8px 5px 8px'
                },
                '.muiSingleSelect [class$="control"]': {
                  minHeight: `${CELL_HEIGHT}px`,
                  height: `${CELL_HEIGHT}px`
                },
                '.muiSingleSelect [class$="placeholder"]': {
                  top: '45%'
                },
                '.muiSingleSelect [class$="IndicatorsContainer"]': {
                  height: `${CELL_HEIGHT}px`
                },
                'input': {
                  padding: '8px 12px',
                  height: `${CELL_HEIGHT}px !important`
                },
                '.MuiDataGrid-editInputCell': {
                  border: '1px solid rgba(0, 0, 0, 0.5)',
                  padding: '10px 0',
                  borderRadius: '5px',
                  height: `${CELL_HEIGHT}px !important`
                },
                '.styles-for-multiSelect input': {
                  height: '10px !important'
                },
                // '.MuiDataGrid-cell--editing': {
                //   borderTop : '16px solid rgba(245, 245, 245, 1) !important',
                //   borderBottom : '16px solid rgba(245, 245, 245, 1) !important'
                // },
                '.MuiInputBase-root': {
                  // margin: '10px',
                  width: '100%'
                },
                '.MuiDataGrid-cell:focus': {
                  outline: 'none',
                  border: 'none',
                },
                '.MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within': {
                  outline: 'none',
                },
                '.MuiDataGrid-row--editing': {
                  boxShadow: 'none',
                },
                '.MuiDataGrid-cell[data-field="actions"]': {
                  // position: 'sticky',
                  // right: "0px",
                  boxShadow: actionSeperatorRequired ? '-10px 0px 4px rgba(0, 0, 0, 0.04)' : 'none',
                  backgroundColor: 'transparent',
                },
                '.MuiDataGrid-row': {
                  padding: `${ROW_PADDING}px`,
                },
                '.MuiDataGrid-row.Mui-selected': {
                  backgroundColor: 'transparent',
                },
                '.MuiDataGrid-row:hover': {
                  backgroundColor: 'transparent',
                },
                '.MuiDataGrid-row.Mui-selected:hover': {
                  backgroundColor: 'transparent',
                },
                '.MuiDataGrid-cell': {
                  border: "none",
                  justifyContent: "flex-start",
                  overflow: "visible !important",
                  padding: "10px !important",
                  paddingLeft: '0 !important',
                  height: `${CELL_HEIGHT}px !important`
                },
                '.MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within': {
                  outline: "none"
                },
                '.MuiDataGrid-menuIcon': {
                  display: "none"
                },
                '.MuiDataGrid-iconButtonContainer': {
                  visibility: 'visible',
                  float: 'right',
                  'button': {
                    marginLeft: '0 !important',
                  }
                },
                '.MuiDataGrid-sortIcon': {
                  opacity: 'inherit !important',
                },
                '.MuiDataGrid-columnHeaderTitleContainer': {
                  flexDirection: "row !important",
                  '.MuiDataGrid-columnHeaderTitleContainerContent': {
                    float: 'left',
                    '.MuiDataGrid-columnHeaderTitle': {
                      fontFamily: 'Gilroy-Medium',
                      lineHeight: 1.5,
                      whiteSpace: 'pre-wrap',
                      maxWidth: maxColunmWidth,
                    }
                  }
                },
                '.MuiDataGrid-columnHeader.col-mandatory': {
                  '.MuiDataGrid-columnHeaderTitle::after': {
                    content: '"*"',
                    display: 'inline-block',
                    color: 'red'
                  }
                },
                '.MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                  maxWidth: 300
                },
                '.MuiDataGrid-selectedRowCount': {
                  display: 'none'
                },
                '.MuiSelect-select': {
                  padding: '8px 0'
                },
                '.MuiDataGrid-footerContainer' : {
                    display : muiRows?.length > pageSize ? 'flex' : 'none'
                    
                },
                '.MuiDataGrid-footerContainer .MuiInputBase-root': {
                  margin: 0,
                  width: 'auto'
                },
                '.MuiAutocomplete-root': {
                  width: '90%'
                },
                '.MuiDataGrid-virtualScroller': {
                  overflow: 'hidden',
                  '&:hover': {
                    overflow: 'overlay',
                  },
                  '&::-webkit-scrollbar': {
                    height: '8px',
                    background: 'transparent',
                    width : '8px'
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#e8e8ed',
                    borderRadius: '8px',
                    border: 'none',
                  }
                }
              }}
            />
          </div>
        </div>

      </Box>
        <ConfirmationDeleteDialog
            isOpen={openDeleteDialog}
            onAgree={() => { deleteRow(currRowId.current) }}
            setIsOpen={setOpenDeleteDialog}
            headerMsg={'Confirm row delete'}
            msg={props?.deleteConfimationMsg ? props?.deleteConfimationMsg : 'Are you sure you want to delete the selected row?'}
        />
    </>
  );
}

export default MuiTable;