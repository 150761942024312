import React from 'react'
import ADataGrid from 'views/pages/manage/common/data-grid/ADataGrid'
import { AGridColDef } from 'views/pages/manage/common/data-grid/ADataGridColDef';
import { getMomentObjFromTimeString } from '../common'
import ActionsCellComponent from './ActionsCellComponent';
import { useParams } from 'react-router';
import NoRowOverlay from './NoRowOverlay';
import StatusCellComponent from './StatusCellComponent'

const PlanList = ({
  plans,
  editRowHandler = () => { },
  deleteRowHandler = () => { },
  updateRowHandler = () => { },
}) => {

  const { action, daysPlanId } = useParams();

  const planListHeader = [
    new AGridColDef('subject', 'Subject').setMinWidth(200).setValueGetter(params => params?.value || '-'),
    new AGridColDef('planDay', 'Day').setMinWidth(200),
    new AGridColDef('sessionName', 'Session name').setMinWidth(250),
    new AGridColDef('startTime', 'Start time').setMinWidth(150).setValueGetter(params => getMomentObjFromTimeString(params?.value).format('HH:mm')),
    new AGridColDef('endTime', 'End time').setMinWidth(150).setValueGetter(params => getMomentObjFromTimeString(params?.value).format('HH:mm')),
    new AGridColDef('status', 'Status').setMinWidth(150)
      .renderCellComponent(params => <StatusCellComponent params={params} updateRowHandler={updateRowHandler} />),
    new AGridColDef('Actions', 'Actions', false).setMinWidth(150).columnAlign('right')
      .renderCellComponent(params => <ActionsCellComponent params={params} editRowHandler={editRowHandler} deleteRowHandler={deleteRowHandler} />),
  ]
  const viewPlanListHeader = [
    new AGridColDef('subject', 'Subject').setMinWidth(200).setValueGetter(params => params?.value || '-'),
    new AGridColDef('planDay', 'Day').setMinWidth(200),
    new AGridColDef('sessionName', 'Session name').setMinWidth(250),
    new AGridColDef('startTime', 'Start time').setMinWidth(150).setValueGetter(params => getMomentObjFromTimeString(params?.value).format('HH:mm')),
    new AGridColDef('endTime', 'End time').setMinWidth(150).setValueGetter(params => getMomentObjFromTimeString(params?.value).format('HH:mm')),
  ]

  return (
    <>
      <ADataGrid
        serverPagination={false}
        rows={plans || []}
        columns={action === 'view' ? viewPlanListHeader : planListHeader}
        rowId={(row) => row.id}
        loading={false}
        emptyRowPlaceholder={() => <NoRowOverlay />}
      />
    </>
  )
}

export default PlanList