import React from 'react'
import { regexValidate } from '../../utils/methods/commonMethods/utilityMethod';
import { InputLabelStyle } from "../../form-fields-mui/ASelect";
import { InputLabel } from '@mui/material';
import styles from './styles.module.scss'

const ATextarea = ({
  placeholder = 'Enter',
  label,
  value,
  onChange = () => { },
  style,
  regex,
  ...props
}) => {

  return (
    <>
      {label && <InputLabel sx={InputLabelStyle}>{label}</InputLabel>}
      <div className={`realtive ${styles['custom-textarea']}`}>
        <textarea
          value={value}
          onChange={(e) => {
            regexValidate(regex, e.target.value) && onChange(e.target.value);
          }}
          placeholder={placeholder}
          style={style}
          resize='none'
          {...props}
        />
      </div>
    </>
  )
}

export default ATextarea