import React, { useContext, useState } from 'react'
import { Col } from 'reactstrap';
import { CustomContainer } from "views/pages/manage/common/commonComponents";
import { CustomCard } from "views/pages/manage/common/commonComponents";
import AAutoComplete from 'views/pages/manage/common/form-fields-mui/AAutoComplete'
import AInput from 'views/pages/manage/common/formFeilds/customInput/AInput'
import AButton from '../../../common/form-fields-mui/AButton';
import CustomButton from "components/CustomButton";
import { pages } from '../../../common/constant';
import { RolePermissions, PermissionContext } from 'appContext'
import { STATUS_DROPDOWN } from '../../../edpOps/constant/batch-search';
import { checkPermission, getDropdownIdsFromSelectArray } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { fetchBusinessAreasByRegions } from 'views/pages/manage/academicPlanAndTest/constant/common'


const inputStyles = { height: '38px', borderRadius: '4px', fontSize: '14px' }
const selectStyles = { control: { height: '38px', borderRadius: '4px' }, valueContainer: { fontSize: '14px' } }
const SearchBuildingHeader = ({
  setVisibleCard = () => { },
  regionData,
  stateData,
  cityData,
  filterForm,
  filterFormHandler,
  searchHandler,
  resetSearchHandler,
}) => {

  const userPermissions = RolePermissions(useContext(PermissionContext), pages['buildingmaster']['id']);

  const [businessAreaData, setBusinessAreaData] = useState([]);
  const [businessAreaLoader, setBusinessAreaLoader] = useState(false);
  const fetchBusinessArea = async (regionsArray) => {
    if (regionsArray?.length > 0) {
      setBusinessAreaLoader(true);
      const responseData = await fetchBusinessAreasByRegions(getDropdownIdsFromSelectArray(regionsArray));
      setBusinessAreaData(responseData);
      setBusinessAreaLoader(false);
    } else {
      setBusinessAreaData([]);
    }
  }

  return (
    <CustomContainer>
      <CustomCard 
        // title="Search Building Master"
        HeaderElement={
          <div className='w-100 d-flex justify-content-between align-items-center color-primary'>
            <h3>Search Building Master</h3>
            {checkPermission(userPermissions, 'C') && <span className='cursor' onClick={() => setVisibleCard('create')}>Create building master</span>}
          </div>
        }
      >
        <div className='p-4 form-row' style={{ rowGap: '1rem' }}>
          <Col md='3'>
            <label className="form-control-label">
              Building Name
            </label>
            <AInput
              placeholder="Building name"
              style={inputStyles}
              value={filterForm?.buildingName}
              handler={value => filterFormHandler(value, 'buildingName')}
            />
          </Col>
          <Col md='3'>
            <label className="form-control-label">
              Region
            </label>
            <AAutoComplete
              style={selectStyles}
              currentValue={filterForm?.region}
              handleChange={value => {
                filterFormHandler(value, 'region')
                filterFormHandler([], 'businessArea');
                fetchBusinessArea(value);
              }}
              items={regionData}
              isMulti={true}
              isShowCount={true}
            />
          </Col>
          <Col md='3'>
            <label className="form-control-label">
              Business Area
            </label>
            <AAutoComplete
              style={selectStyles}
              currentValue={filterForm?.businessArea}
              handleChange={value =>{
                filterFormHandler(value, 'businessArea')}}
              items={businessAreaData}
              isLoading={businessAreaLoader}
              isMulti={true}
              isShowCount={true}
              isDisabled={!filterForm?.region?.length}
            />
          </Col>
          <Col md='3'>
            <label className="form-control-label">
              State
            </label>
            <AAutoComplete
              style={selectStyles}
              currentValue={filterForm?.state}
              handleChange={value => filterFormHandler(value, 'state')}
              items={stateData}
              isMulti={true}
              isShowCount={true}
            />
          </Col>
          <Col md='3'>
            <label className="form-control-label">
              City
            </label>
            <AAutoComplete
              style={selectStyles}
              currentValue={filterForm?.city}
              handleChange={value => filterFormHandler(value, 'city')}
              items={cityData}
              isMulti={true}
              isShowCount={true}
            />
          </Col>
          <Col md='3'>
            <label className="form-control-label">
              Status
            </label>
            <AAutoComplete
              style={selectStyles}
              currentValue={filterForm?.status}
              handleChange={value => filterFormHandler(value, 'status')}
              items={STATUS_DROPDOWN}
              isMulti={false}
              isShowCount={true}
            />
          </Col>
        </div>
        <div className='p-4 d-flex justify-content-end'>
          <CustomButton
            content={'Search'}
            permissionType={'S'}
            icon={true}
            permissionSet={userPermissions}
            onClick={searchHandler}
          />
          <CustomButton
            type="reset"
            content={'Clear All'}
            permissionType={'R'}
            permissionSet={userPermissions}
            icon={true}
            onClick={resetSearchHandler}
          />
        </div>
      </CustomCard>
    </CustomContainer>
  )
}

export default SearchBuildingHeader