import React, { useEffect, useState } from "react";
import AInput from "../../../common/formFeilds/customInput/AInput";
import AButton from "../../../common/form-fields-mui/AButton";
import { CircularProgress, Tooltip } from "@mui/material";
import { linkButtonStyles } from "../../constant/student-details";
import TooltipContent from "../TooltipContent";
import { successToast } from "../../../common/utils/methods/toasterFunctions/function";

const buttonStyles = {
  height: "30px",
  borderRadius: "4px",
  fontSize: "12px",
  padding: "10px 10px",
};
const warningStyles = {
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "16px",
  letterSpacing: "0em",
  color: "red",
};
const PasswordReset = ({ resetPasswordHandler, setApiLoader }) => {
  // const [apiLoader, setApiLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [validStart, setValidStart] = useState(false);
  const [validMid, setValidMid] = useState(false);
  const [validEnd, setValidEnd] = useState(false);

  const seqMap = [
    123, 234, 345, 456, 567, 678, 789, 890, 987, 876, 765, 654, 543, 432, 321,
  ];

  const regFirstTwoChars = /^[a-zA-Z]{2}/;
  const regDigits = /\d{6}/;

  const allCharactersSame = (value) =>
    value.split("").every((c) => c === value[0]);

  const validatePwdSubstrings = (pwd) => {
    let endString = pwd?.substr(3);
    // if (allCharactersSame(endString)) return false;

    const subStrings = [];
    for (let i = 0; i <= endString?.length - 3; i++) {
      subStrings.push(endString?.substr(i, 3));
    }
    let cnt = 0;

    subStrings.forEach((element) => {
      if (seqMap.find((item) => item == element)) cnt++;
    });

    return !(cnt > 2);
  };

  const validatePassword = (value) => {
    if (regFirstTwoChars.test(value)) {
      setValidStart(true);
    } else {
      setValidStart(false);
    }
    if (value?.charAt(2) === "@") {
      setValidMid(true);
    } else {
      setValidMid(false);
    }
    if (regDigits.test(value?.substr(3)) && validatePwdSubstrings(value)) {
      setValidEnd(true);
    } else {
      setValidEnd(false);
    }
  };

  const saveHandler = async () => {
    // setApiLoader(true);
    setApiLoader(true);
    const res = await resetPasswordHandler("");
    if (res?.code === 200) {
      setShowPassword(false);
      const { password, psid } = res?.data;
      // {Password, psi}
      successToast(`Password "${password}” updated for PSID ${psid} in MS Teams.`);
    }
    setApiLoader(false);
  };

  useEffect(() => {
    setUserInput("");
  }, [showPassword]);

  return (
    <>
      <span style={linkButtonStyles}>
        <span onClick={() => saveHandler(true)}>Reset MSteams password</span>
        {showPassword && (
          <Tooltip title={<TooltipContent />} placement="top">
            <i className="fas fa-info-circle ml-2" />
          </Tooltip>
        )}
      </span>

      {showPassword && (
        <span className="align-self-stretch">
          <div className="d-flex align-items-center">
            {/* <AInput
              style={{ borderRadius: '4px' }}
              placeholder='Enter password'
              maxLength={9}
              value={userInput}
              handler={(value) => { validatePassword(value); setUserInput(value); }}
            /> */}

            {/* <span className='d-flex align-item-center'> */}
            {/* <AButton
              variant='primary_filled'
              updatedStyle={buttonStyles}
              disabled={!validStart || !validMid || !validEnd || apiLoader}
              onClick={saveHandler}
            >
              {apiLoader && <CircularProgress size='12px' color='inherit' className='mr-1' />}
              Save
            </AButton> */}
            {/* <AButton
              variant='danger_filled'
              updatedStyle={{ ...buttonStyles, backgroundColor: '#E7685A' }}
              onClick={() => setShowPassword(false)}
            >
              Cancel
            </AButton> */}
            {/* </span> */}
          </div>
          {/* {!validStart && <div className='regular-small' style={{ color: 'red' }}>First 2 should be chars</div>}
          {!validMid && <div className='regular-small' style={{ color: 'red' }}>Third should be '@'</div>}
          {!validEnd && <div className='regular-small' style={{ color: 'red' }}>Rest 6 should be digits</div>} */}
          {/* {(!validStart || !validMid || !validEnd) && (
            <div className='mt-2' style={warningStyles}>
              The password should be of 9 charatcters where first 2 characters should be alphabets, 3rd character should be ‘@’ and last 6 digits should be non-consecutive numbers
            </div>
          )} */}
        </span>
      )}
    </>
  );
};

export default PasswordReset;
