import React, { Fragment } from "react";
import CustomTable from "../../common/commonComponents/customTable";
import { endpoint } from "../../common/constant";
import { Button } from "reactstrap";

const DemoTestTable = () => {
  const tableDetails = {
    headerDetails: [
      { name: "Job Title", required: true, isFilter: true },
      { name: "Description", required: true, isFilter: true },
      { name: "Display Value", required: false },
      { name: "Status", required: true },
      { name: "Action", required: true },
    ],
    apiDetails: {
      create: endpoint.jobTitle.create,
      update: endpoint.jobTitle.update,
      delete: endpoint.jobTitle.delete,
      getAll: endpoint.jobTitle.getAll,
    },
    listDetails: {
      jobTitleKey: {
        normal: true,
      },
      jobTitleDesc: {
        normal: true,
      },
      jobTitleDispValue: {
        normal: true,
      },
      status: {
        normal: false,
        type: "TOGGLE",
      },
      action: {
        type: "ACTION",
        button: [
          {
            icon: "fas fa-edit",
            color: "info",
            actionType: "EDIT",
          },
          {
            icon: "fas fa-trash",
            color: "info",
            actionType: "DELETE",
          },
        ],
      },
    },
    fieldDetails: [
      {
        type: "text",
        minLength: "2",
        maxLength: "4",
        regx: /^[A-Za-z0-9]*$/i,
        regxValidation: true,
        placeHolder: "Job Title",
        propertyName: "jobTitleKey",
        isFilter: true,
      },
      {
        type: "text",
        minLength: "2",
        maxLength: "4",
        regx: /^[A-Za-z0-9]*$/i,
        regxValidation: true,
        placeHolder: "Job Description",
        propertyName: "jobTitleDesc",
        isFilter: true,
      },
      {
        type: "text",
        minLength: "2",
        maxLength: "4",
        regx: /^[A-Za-z0-9]*$/i,
        regxValidation: true,
        placeHolder: "Job Display Value",
        propertyName: "jobTitleDispValue",
      },
      {
        type: "Status",
        propertyName: "status",
      },
    ],
    isSearch: false,
    isNew: true,
  };

  const downloadHandler = () => {
    try {
      console.log("download Clicked");
    } catch (e) {
      console.log("Error", e);
    }
  };

  const TableHeader = () => {
    return (
      <div className={"d-flex justify-content-between w-100"}>
        <h3 className="mb-0 floatLeft">Job Details</h3>
        <div>
          <Button color={"info"} size={"sm"} onClick={downloadHandler}>
            Download
          </Button>
          <Button color={"info"} size={"sm"}>
            section
          </Button>
        </div>
      </div>
    );
  };

  return <CustomTable tableHeader={TableHeader} tableDetails={tableDetails} />;
};

export default DemoTestTable;
