import {UPDATE_DATA} from '../types';

const UPDATE_USER_AUTH = 'UPDATE_USER_AUTH';
const UPDATE_USER_PERMISSION = 'UPDATE_USER_PERMISSION';
const LOGOUT_USER = 'LOGOUT_USER';
export const updateUser =(object)=>{
    return {
        type:UPDATE_USER_AUTH,
        payload : object
    }
}
export const updateUserPermission = (role)=>{
    return {
        type : UPDATE_USER_PERMISSION,
        role : role
    }
}

export const logoutUser = (isLogout)=>{
    try{
        return {
            type : LOGOUT_USER,
            logout : {
                value : isLogout
            }
        }
    }catch(e){
        console.error('Error : ',e)
    }

}