import React, { useState } from 'react';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { TablePagination } from '@mui/material';

const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {
    const handleChange = (event, value) => {
        onPageChange(value);
    };

    return (
        <div className='d-flex justify-content-end p-2' style={{ backgroundColor: '#fff', borderRadius: '10px' }}>
            {/* <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handleChange}
                renderItem={(item) => (
                    <PaginationItem
                        component="button"
                        onClick={() => onPageChange(item.page)}
                        {...item}
                    />
                )}
            /> */}
            <TablePagination
                component="div"
                count={totalPages}
                page={currentPage}
                onPageChange={handleChange}
                rowsPerPage={50}
                sx={{
                    '& .MuiInputBase-root': {
                      display: 'none',
                    },
                    '& .MuiTablePagination-selectLabel': {
                        display: 'none'
                    }
                  }}
                // onRowsPerPageChange={()=>{}}
            />
        </div>
    );
};

export default CustomPagination;
