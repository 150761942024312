import React, { useState, useEffect } from "react";
import CommonInput from "views/pages/manage/common/formFeilds/input/commonInput";
import ReportSearchInput from "./ReportSearchInput";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import { Col } from "reactstrap";
import moment from "moment";
import { failureToast } from "services/http";
const ReportSearchFilter = ({
  getTable1DataByPID,
  getTable1DataByDateAndChannel,
  resetHandler,
  setTable1Loading,
  searchInputValue,
  setSearchInputValue,
  setChannel
}) => {
  const [filterForm, setFilterForm] = useState({
    startDate: '',
    endDate: '',
    channelName: '',
  })
  const filterFormHandler = (value, inputKey) => {
    setFilterForm(currentData => ({ ...currentData, [inputKey]: value }));
  }

  return (
    <div style={{ display: "flex", justifyContent: 'flex-end' }}>
      <Col md='0'>
        <ReportSearchInput
         searchInputValueHandler={getTable1DataByPID}
         placeHolder={'Search by PID'}
         onCancel={resetHandler}
         setTable1Loading={setTable1Loading}
         searchInputValue={searchInputValue}
         setSearchInputValue={setSearchInputValue}
         setFilterForm={setFilterForm}
         setChannel={setChannel}
             />
      </Col>
      <Col md='2'>
        <CommonInput
          type={"datePicker"}
          mandatory={true}
          placeHolder="From"
          value={filterForm?.startDate}
          onChange={(value) => {
            filterFormHandler(moment(value).format('YYYY-MM-DD'), 'startDate');
            setSearchInputValue('');
          }
          }
        // minDate={new moment()}
        />
      </Col>

      <Col md='2'>
        <CommonInput
          type={"datePicker"}
          mandatory={true}
          placeHolder="To"
          isDisabled={!filterForm?.startDate}
          value={filterForm?.endDate}
          onChange={(value) => {
            filterFormHandler(moment(value).format('YYYY-MM-DD'), 'endDate');
          }
          }
        />
      </Col>
      <Col md='2'>
        <CommonInput
          type={'select'}
          placeHolder={'Select Channel'}
          defaultValue={filterForm?.channelName}
          isDisabled={!filterForm?.endDate}
          data={[{ label: 'Email', value: 'EMAIL' }, { label: 'WhatsApp', value: 'WHATSAPP' }, { label: 'SMS', value: 'SMS' }]}
          onChange={(e) => { filterFormHandler(e, 'channelName') }
          }
        >
        </CommonInput>
      </Col>
      {(
        <>
          <Col md='0'>
            <AButton
              onClick={() => { 
                if(searchInputValue!=='') getTable1DataByPID(searchInputValue)
                else if(filterForm.startDate!==''&&filterForm.endDate!==''&&filterForm.channelName!=='') 
                getTable1DataByDateAndChannel(filterForm); 
                else if(filterForm.startDate===''&&filterForm.endDate===''&&filterForm.channelName==='') 
                return
                else {
                    failureToast("Please select all three filters");
                    return;
                  }
                setChannel('');
                setTable1Loading(true) 
              }}
              updatedStyle={{
                background: "#00B0F5",
                border: "1px solid #00B0F5",
                borderRadius: "8px",
                color: "#fff",
                margin: ".4rem",
              }}
            >
              Search
            </AButton>
          </Col>
          <Col md='0'>
            <AButton
              onClick={() => {
                setFilterForm({
                  startDate: '',
                  endDate: '',
                  channelName: '',

                })
                setSearchInputValue('');
                resetHandler();
              }
              }
              updatedStyle={{
                border: "1px solid #CC5F5F",
                borderRadius: "8px",
                color: "#CC5F5F",
                margin: ".4rem",
              }}
            >
              Reset
            </AButton>
          </Col>
        </>
      )}


    </div>
  );
}

export default ReportSearchFilter;