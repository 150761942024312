import React from 'react';
import {Button, Card,Table} from "reactstrap";
import {MasterHeaderAuto} from "../../../../common/commonComponents/masterHeader/masterHeader";
import {getDispValForReactSelectByOneID} from "../../../../common/utils/methods/commonMethods/utilityMethod";
import {CustomTableRow, StatusButton} from "../../../../common/commonComponents/TableComponents";
import CustomButton from "components/CustomButton";

// const getStudentNameById = (studentList=[],id)=>{
//     if(studentList && studentList.length>0){
//         let name='Not Found'
//         studentList.map((item)=>{
//             if(item.id === id){
//                 name = item.desc
//             }
//         })
//         return name;
//     }
// }
const ApplicationIdTable =(props)=>{
    // const headerList =[{name:"S. No"}, {name:"Application Id"}, {name:"Student Id"},{name:"Student Name"}, {name:"Opportunity Id"},{name:"Business Area"}, {name:"Admission Status"}, {name:"Action"}];
    const {data,studentIds,businessArea,pagination,studentName,searchHeaderList, permissionSet} = props;
    const TableData =(props)=>{
        const {item,index} = props;
        return(
            <CustomTableRow>
                <td className="text-center p-2 mx-1">{((pagination?.currentPage-1)*10) + index+1}</td>
                <td className="text-center p-2 mx-1">{item.applicationId}</td>
                <td className="text-center p-2 mx-1">{item.studentId}</td>
                <td className="text-center p-2 mx-1">{studentName}</td>
                <td className="text-center p-2 mx-1">{item.opportunityId}</td>
                <td className="text-center p-2 mx-1">{getDispValForReactSelectByOneID(item.businessArea ,'DispValue',businessArea)}</td>
                <td className="text-center p-2 mx-1">
                    <StatusButton status={item.status}/>
                </td>
                <td className="text-center p-2 mx-1">
                    {/* <Button key={'ViewButton'} color="info" size="sm" type="button" onClick={() => {window.open( document.URL +'/view/'+item.applicationId, '_blank' );}}>
                        <i className="fas fa-eye" />
                    </Button> */}
                    <CustomButton
                        forTable={true}
                        permissionType={'R'}
                        icon={true}
                        onClick={() => {window.open( document.URL +'/view/'+item.applicationId, '_blank' );}}
                        permissionSet={permissionSet}
                    />
                    {/* <Button key={'EditButton'} color="info" size="sm" type="button" onClick={() => {window.open( `${ document.URL }/edit/${item.applicationId}`, '_blank' );}}>
                        <i className="fas fa-edit" />
                    </Button> */}
                    <CustomButton
                        forTable={true}
                        permissionType={'U'}
                        icon={true}
                        onClick={() => {window.open( `${ document.URL }/edit/${item.applicationId}`, '_blank' );}}
                        permissionSet={permissionSet}
                    />
                </td>
            </CustomTableRow>
        )
    }
    return(
        <Card>
            <div id="questionTable" className="table-responsive" >
                <Table className="align-items-center table-flush ">
                    <MasterHeaderAuto headerList={searchHeaderList} permissionSet={permissionSet}/>
                    <tbody className="list" key={'courseCatalogSequenceSearch'}>
                    {
                        data && data.length>0 && data.map((item,index)=>
                        <TableData item={item} index={index}/>
                        )
                    }
                    </tbody>

                </Table>
            </div>
        </Card>
    );
}

export default ApplicationIdTable;
