import React from "react";
import { Button } from "reactstrap";
import {
  getDispValForReactSelectByOneID,
  NewRow,
} from "../../../common/utils/methods/commonMethods/utilityMethod";
import DeleteButton from "components/FormComponent/Button/Delete/index";
import EditButton from "components/FormComponent/Button/Edit/index";

const BuildingRow = ({ data, index, history, isSaveVisible = false, deleteRow = () => {}, editRow = () => {}, getDisplayVal = () => {} }) => {
  // const itemToBeRenderFromMaster = ["city", "state", "country", "pinCode"];

  // const arrList = [
  //   null,

  // ];
  // const propertyList = [
  //   null,
  //   null,
  //   "text",
  //   "text",
  //   "text",
  //   "text",
  //   null,
  // ];
  const listToBeRendered = ["sequence", "coreMasterId"];

  return (
    <NewRow
      data={data}
      // itemToBeRenderFromMaster={itemToBeRenderFromMaster}
      // propertyList={propertyList}
      listToBeRendered={listToBeRendered}
      // arrList={arrList}
      // colunmWidths={colunmWidths}
    >
      {/* <td className="text-center p-2 mx-1">{data?.buildingName}</td>
      <td className="text-center p-2 mx-1" colSpan={2}>
        {data?.address}
      </td>
      <td className="text-center p-2 mx-1">{data?.city}</td>
      <td className="text-center p-2 mx-1">{data?.state}</td>
      <td className="text-center p-2 mx-1">{data?.country}</td>
      <td className="text-center p-2 mx-1">{data?.pinCode}</td>

      <td className="text-center p-2 mx-1">
        {data.status === "ACTIVE" ? (
          <Button color="success" size={"sm"} disabled={true}>
            Active
          </Button>
        ) : (
          <Button color="danger" size={"sm"} disabled={true}>
            Inactive
          </Button>
        )}
      </td> */}
      <td className="text-center p-2 mx-1">
        <EditButton
          onClick={() => {
            editRow(index);
          }}
        />
        <DeleteButton onClick={() => deleteRow(index)} />
      </td>
    </NewRow>
  );
};

export default BuildingRow;
