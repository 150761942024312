import React,{Fragment} from "react";
import { CardFooter, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import "react-sliding-pane/dist/react-sliding-pane.css";
import 'react-toastify/dist/ReactToastify.css';

const _ = require('lodash');

const PaginationNode = (props) => {
  const {num, disabled, currentPage, totalPage, triggerFun, icon} = props;
  return (
    <Fragment>
      <PaginationItem disabled={disabled} className={(currentPage===1&&num==="Next")||(num==="Previous"&&totalPage<= currentPage)?"disabled":null}>
        <PaginationLink onClick={(e) =>{triggerFun()}}>
          <i className={icon} />
          <span className="sr-only">{num}</span>
        </PaginationLink>
      </PaginationItem>
    </Fragment>
  )
}

const PaginationPage = (props) => {
  const {num, disabled, currentPage, totalPage, triggerFun, active} = props;
  return (
    <Fragment>
      {num&&!(totalPage<num) ? <PaginationItem disabled={disabled} className={props.url ? (active ? 'active' : null) : (active ? 'active disable' : 'disable')}>
        <PaginationLink onClick={currentPage !== num ? (e) => triggerFun() : ()=>{}}>
          {num}
        </PaginationLink>
      </PaginationItem> : null}
    </Fragment>
  )
}

const GetPagination = (props) => {

  const {isSaveVisible,setTableData,pagination, setPagination,pageSize, state,previousPage} = props

  const nextPage = () => {
    setTableData(
      state.slice(
        (pagination.currentPage + 1) * pageSize - pageSize,
        (pagination.currentPage + 1) * pageSize
      )
    );
    let n = pagination;
    n["currentPage"] = n["currentPage"] + 1;
    n["previousPage"] = n.currentPage;
    setPagination(n);
  };

  const firstPage = () => {
    setTableData(state.slice(1 * pageSize - pageSize, 1 * pageSize));
    let n = pagination;
    n["currentPage"] = 1;
    n["previousPage"] = null;
    setPagination(n);
  };

  const lastPage = () => {
    setTableData(
      state.slice(
        pagination.totalPage * pageSize - pageSize,
        pagination.totalPage * pageSize
      )
    );
    let n = pagination;
    n["currentPage"] = n.totalPage;
    n["previousPage"] = n.totalPage - 1;
    setPagination(n);
  };



  return (
    <Fragment>
      {pagination ? <CardFooter className="py-4">
        <nav aria-label="...">
          <Pagination  className="pagination justify-content-end mb-0" listClassName="justify-content-end mb-0">
            <PaginationNode disabled={isSaveVisible} num={'Next'} totalPage={pagination.totalPage} currentPage={pagination.currentPage} active={true} triggerFun={firstPage} icon={'fa fa-angle-double-left'} />
            <PaginationNode disabled={isSaveVisible} num={'Next'} totalPage={pagination.totalPage} currentPage={pagination.currentPage} active={true} triggerFun={previousPage} icon={'fa fa-angle-left'} />
            <PaginationPage disabled={isSaveVisible} active={false} totalPage={pagination.totalPage} num={parseInt(pagination.currentPage - 1)} currentPage={pagination.currentPage} triggerFun={previousPage} />
            <PaginationPage disabled={isSaveVisible} active={true} totalPage={pagination.totalPage} num={parseInt(pagination.currentPage)} currentPage={pagination.currentPage} triggerFun={nextPage} />
            <PaginationPage disabled={isSaveVisible} active={false} totalPage={pagination.totalPage} num={parseInt(pagination.currentPage+1)} currentPage={pagination.currentPage} triggerFun={nextPage} />
            <PaginationNode disabled={isSaveVisible} num={'Previous'} totalPage={pagination.totalPage} currentPage={pagination.currentPage} triggerFun={nextPage} icon={'fa fa-angle-right'} />
            <PaginationNode disabled={isSaveVisible} num={'Previous'} totalPage={pagination.totalPage} currentPage={pagination.currentPage} triggerFun={lastPage} icon={'fa fa-angle-double-right'} />
          </Pagination>
        </nav>
      </CardFooter> : null}
    </Fragment>
  )
}
export {PaginationNode,PaginationPage,GetPagination}
