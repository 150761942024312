import React, { useState, useEffect } from "react";
import SearchInput from "./SearchInput";



// this is for filter button style
const Filter = ({
  filterForm,
  crossInputHandler,
  setGlobalFilter,
  searchInputValueHandler,
}) => {


  return (
    <div style={{ display: "flex" }}>
      <SearchInput
        onCancel={crossInputHandler}
        searchInputValueHandler={searchInputValueHandler}
        placeholder="Enter the 11 digit PSID number"
        errorMsg={true}
        val={filterForm?.psid ?? ''}
        globalFilter = {filterForm}
        setGlobalFilter = {setGlobalFilter}
      />

    </div>
  );
};

export default Filter;
