import { knowYourRoleServiceUrl } from "services/http";

export const knowYourRoleServiceEndpoints = {
  //role
  role_Name: {
    search: `${knowYourRoleServiceUrl}/roleMaster/searchRole`,
    create: `${knowYourRoleServiceUrl}/roleMaster/createRole`,
    update: `${knowYourRoleServiceUrl}/roleMaster/updateRole`,
    delete: `${knowYourRoleServiceUrl}/roleMaster/deleteRole`,
    getAll: `${knowYourRoleServiceUrl}/roleMaster/getAllRole`,
    getAllActive: `${knowYourRoleServiceUrl}/roleMaster/getAllActiveRole`,
    getRoleByID: `${knowYourRoleServiceUrl}/roleMaster/getRole`,
    getAllRole: `${knowYourRoleServiceUrl}/roleMaster/getAllRole`,
  },

  //Project Details
  projectDetails: {
    create: `${knowYourRoleServiceUrl}/project/createProject`,
    delete: `${knowYourRoleServiceUrl}/project/deleteProject`,
    getAllActiveProject: `${knowYourRoleServiceUrl}/project/getAllActiveProject`,
    getAllProject: `${knowYourRoleServiceUrl}/project/getAllProject`,
    getProjectById: `${knowYourRoleServiceUrl}/project/getProject`,
    update: `${knowYourRoleServiceUrl}/project/updateProject`,
  },

  //Permission Details
  permissionDetails: {
    create: `${knowYourRoleServiceUrl}/permission/createPermission`,
    delete: `${knowYourRoleServiceUrl}/permission/deletePermission`,
    getAllActivePermission: `${knowYourRoleServiceUrl}/permission/getAllActivePermission`,
    getAllPermission: `${knowYourRoleServiceUrl}/permission/getAllPermission`,
    getPermissionById: `${knowYourRoleServiceUrl}/permission/getPermission`,
    update: `${knowYourRoleServiceUrl}/permission/updatePermission`,
  },

  //userRole on project
  getRole: {
    getAllRoleOnProject: `${knowYourRoleServiceUrl}/userMaster/getRoleMasterBasedOnProjectId`,
    getRoles: `${knowYourRoleServiceUrl}/roleMaster/searchRole`,
    create: `${knowYourRoleServiceUrl}/roleMaster/createRole`,
    getRoleById: `${knowYourRoleServiceUrl}/roleMaster/getRoleById`,
    updateRole: `${knowYourRoleServiceUrl}/roleMaster/updateRole`,
    allActiveRoles: `${knowYourRoleServiceUrl}/roleMaster/getAllActiveRole`,
    roleWithApprovalPermissions: `${knowYourRoleServiceUrl}/roleMaster/getRoles/screen/approval`,
  },
  securityGroup: {
    search: `${knowYourRoleServiceUrl}/sgMaster/name`,
    common: `${knowYourRoleServiceUrl}/sgMaster`,
    update: `${knowYourRoleServiceUrl}/securityGroup/createSecurityGroup`,
    params: `${knowYourRoleServiceUrl}/sgParamMaster`,
  },
  screen: {
    getById: `${knowYourRoleServiceUrl}/screenMaster/getAllScreen`,
  },
  roleScreen: {
    create: `${knowYourRoleServiceUrl}/userMaster/createRoleProjectScreenPermissions`,
    update: `${knowYourRoleServiceUrl}/userMaster/updateRoleProjectScreenPermissions`,
    merge: `${knowYourRoleServiceUrl}/userMaster/mergeRoleProjectScreenPermissions`,
  },
  permission: {
    getAllActive: `${knowYourRoleServiceUrl}/permission/getAllActivePermission`,
    gwtPermissionByRoleAndProjectId: `${knowYourRoleServiceUrl}/userMaster/viewRoleProjectScreenPermissions`,
  },
  sgUserProjectMapping: {
    getSgMaster: `${knowYourRoleServiceUrl}/sgUserProjectMapping/getAllBusinessAreas`,
  },
  userFavourites: {
    create: `${knowYourRoleServiceUrl}/favourites/userFavourites`,
    getAll: `${knowYourRoleServiceUrl}/favourites/getUserFavourites`,
    delete: `${knowYourRoleServiceUrl}/favourites/deleteUserFavourites`,
  },
  notification:`${knowYourRoleServiceUrl}/notification/details`,
};
