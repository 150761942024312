import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../../components/CustomButton';
import CommonInput from '../../common/formFeilds/input/commonInput';
import closeIcon from 'assets/img/svg/closeSquare.svg'
import { constants } from "../../common/constant/constants/index";
import VerificationPopup from './verificationPopup';
import { useDispatch, useSelector } from 'react-redux';
import { getApprovalRequests } from 'redux/concessionApproval/actions';
import { getDocsStatus } from './common';
import { failureToast } from '../../common/utils/methods/toasterFunctions/function';

const ApprovalDialogContent = ({
    isBulk = false,
    userPermissions,
    row = null,
    onActionClick,
    handleClose,
    width = 516,
    action = constants.APPROVAL_ACTIONS.APPROVE,
    documentAttached = false,
    applicationQuotationDetailsId = null,
    applicationId,
    saving,
    overrideParams=false,
    getApprovalDetails=()=>{},
    customConfig,
    appliedFilters,
    appliedPsid=''
}) => {

    const [openPopOver, setOpenPopOver] = useState(false);

    const approvalState = useSelector(state => state.concessionApproval);
    const dispatch = useDispatch();


    const handleVerify = (e, updateRequests = false) => {
        setOpenPopOver(false);
        if(overrideParams){
            getApprovalDetails()
        }
        if (updateRequests && !overrideParams) {
            dispatch(getApprovalRequests({
                approvalStatus: `${approvalState.approverType.value}_${'pending'.toString()?.toUpperCase()}`,
                businessAreaValue: appliedFilters?.businessArea?.length ? appliedFilters?.businessArea : approvalState.allBusinessAreas.map(i => i.label),
                ...(appliedPsid ? {psid : appliedPsid} : appliedFilters),

            },approvalState?.currentPage));
        }
    }


    const [value, setValue] = useState('');

    const docsStatus = getDocsStatus(row, overrideParams ? customConfig : approvalState.allActiveConfigs);
    const verificationPending = docsStatus === constants.DOCUMENT_VERFICATION_STATUS.PENDING;
    const docVerificationVisible = isBulk === false && action === constants.APPROVAL_ACTIONS.APPROVE && 
        (docsStatus === constants.DOCUMENT_VERFICATION_STATUS.PENDING || 
            docsStatus === constants.DOCUMENT_VERFICATION_STATUS.APPROVED);

    return (
        <Box className='concession-approval-dialog-content' width={width}>
            <Box className='approval-dialog-header'>
                <Typography variant='h6'>Add remarks</Typography>
                <div onClick={handleClose}><img src={closeIcon} /></div>
            </Box>
            {docVerificationVisible && <Box className='document-verification-container'>
                <Box className='dialog-document-verification'>
                    <Typography variant='body2'>Document verification</Typography>
                    <CustomButton
                        content={verificationPending ? 'View & verify' : 'View'}
                        permissionType={'R'}
                        permissionSet={userPermissions}
                        onClick={() => {if(!row?.isDocumentAttached){ failureToast("Document not uploaded.")}else{setOpenPopOver(true)}}}
                        forTable={true}
                        outline={true}
                    />
                </Box>
                <Typography className={`dialog-verification-state ${verificationPending ? 'pending' : 'done'}`} variant='caption'>
                    {verificationPending ? "Pending" : "Done"}
                </Typography>
            </Box>}
            <CommonInput
                type={'textarea'}
                defaultValue={value}
                placeHolder={'Add remarks'}
                maxLength={400}
                onChange={value => setValue(value)}
            />
            <Box className="dailog-alert-text">
                <Typography variant='h5' fontSize='0.9rem' color='#5d584e'>In case of any fraud/mismatch in the document. Please remove the student from list.</Typography>
            </Box>
            <CustomButton
                className='approval-dialog-action-button'
                type={action === constants.APPROVAL_ACTIONS.REJECT ? 'cancel' : 'C'}
                content={'Confirm ' + action.toLowerCase()}
                permissionType={action === constants.APPROVAL_ACTIONS.REJECT ? 'cancel' : 'C,A'}
                permissionSet={userPermissions}
                onClick={() => onActionClick(value)}
                disabled={(isBulk === false && action === constants.APPROVAL_ACTIONS.APPROVE && verificationPending) ||((action===constants.APPROVAL_ACTIONS.RECHECK ||action===constants.APPROVAL_ACTIONS.REJECT)&& !value) || saving }
            />
            {openPopOver && <VerificationPopup
                openPopOver={openPopOver}
                handleClose={handleVerify}
                userPermissions={userPermissions}
                documentAttached={documentAttached}
                applicationQuotationDetailsId={applicationQuotationDetailsId}
                applicationId={applicationId}
                overrideParams={overrideParams}
            />}
        </Box>
    )
}

export default ApprovalDialogContent;