import React, { useEffect, useState } from "react";
import ADataGrid from "views/pages/manage/common/data-grid/ADataGrid";
import { gridComponents } from "../../../issuanceIDcard/printIdCardAndMapRfid/constant";
import ALoader from "views/pages/manage/common/a-loader";
import { Card, CardBody } from "reactstrap";
import {
  DISPATCHED_FILTER,
  DISPATCH_PENDING_FILTER,
} from "../../dlpStockIssuance/issueStock/helper";
import { useFetchDigitalIssueDispatch } from "../../customhooks";
import { useGetDigitalModuleContext } from "../../contextApi/digitalContext";
import {
  downloadTemplateForBulkDispatch,
  editStudentDLPStockEligible,
} from "../../service";

import TableFilter from "./TableFilter";
import AddDispatchDetails from "./AddDispatchDetails";
import BarcodeMappingPopup from "./BarcodeMappingPopup";

import UpdateStatusPopup from "./UpdateStatusPopup";
import BulkSelectionFooter from "../../selectMeterialAndStock/issueStock/BulkSelectionFooter";
import SendNotificationPopup from "./SendNotificationPopup";
import ViewBarcodePopup from "./ViewBarcodePopup";
import { getDownloadFileUrl } from "../../stockConsumption/form/helper";
import { gridStyles } from "../../selectMeterialAndStock/constant";

const DispatchPendingTable = ({ selectedTab, tableHeader }) => {
  const [selectionModel, setSelectionModel] = useState([]);
  const { dropdown } = useGetDigitalModuleContext(selectedTab);
  const [openAddBarcodePopup, setOpenAddBarcodePopup] = useState(false);
  const [barcodePopupData, setBarcodePopupData] = useState({});
  const [apiLoader, setApiLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [filterForm, setFilterForm] = useState(DISPATCH_PENDING_FILTER);
  const [currentRowData, setCurrentRowData] = useState({});
  const [isAddDispatchDetails, setIsDispatchDetails] = useState(null);
  const [updateStatusData, setUpdateStatusData] = useState({
    open: false,
    data: {},
  });
  const [viewBarcode, setViewBarcode] = useState({ open: false, data: {} });
  const [isSendNotification, setIsSendNotification] = useState(null);
  const [
    studentApplications,
    isApplicationLoading,
    getApplications,
    totalPage,
    totalRecords,
    resetStudentApplications,
    setApplications,
  ] = useFetchDigitalIssueDispatch(0, {}, selectedTab);

  const filterFormHandler = (value, key) =>
    setFilterForm((prev) => ({ ...prev, [key]: value }));

  const applyFilterHandler = (updatedFilter = {}) => {
    setPage(0);
    getApplications(0, {
      ...filterForm,
      ...updatedFilter,
      academicCareer: "DIGITAL",
    });
  };

  const nextButtonHandler = (page) => {
    setPage(page);
    getApplications(page, { ...filterForm, academicCareer: "DIGITAL" });
  };

  const addBarcodeHandler = (row) => {
    setBarcodePopupData(row);
    setOpenAddBarcodePopup(true);
  };

  const viewBarcodeHandler = (row) => {
    setViewBarcode({ open: true, data: row });
  };

  const addDispatchDetailsHandler = (id, data) => {
    setIsDispatchDetails(id);
    setCurrentRowData(data);
  };

  const updateStatusHandler = (data) => {
    setUpdateStatusData({ open: true, data });
  };

  const addDispatchDetailsApiCall = async (
    request,
    notificationInfo,
    cb = () => {}
  ) => {
    try {
      setApiLoader(true);
      const response = await editStudentDLPStockEligible(request?.id, request);
      if (response?.code === 200) {
        cb({});
        refreshTableData();
        setIsSendNotification(notificationInfo);
        // successToast(response?.message);
      }
      setApiLoader(false);
    } catch (error) {
      setApiLoader(false);
    }
  };

  const refreshTableData = (isNotification = false) => {
    const _request = { ...filterForm };
    if (filterForm?.status) _request["status"] = [filterForm?.status.value];
    setPage(0);
    getApplications(0, { ..._request, academicCareer: "DIGITAL" });
    if (isNotification) setIsSendNotification(true);
  };

  const downloadHandler = async () => {
    try {
      let request = selectionModel.map((item) => ({
        ...JSON.parse(item),
        status: [JSON.parse(item)?.status],
      }));

      if (request.length === 0)
        request = studentApplications.map((item) => ({
          ...item,
          status: [item.status],
        }));
      setApiLoader(true);
      const response = await downloadTemplateForBulkDispatch(
        request.filter((item) => item?.status[0] === "STOCK_ISSUED")
      );
      if (response?.code === 200) {
        getDownloadFileUrl(response?.data.key);
      }
      setApiLoader(false);
    } catch (error) {}
  };

  const getPsid = (psid, dispatchId) => {
    if (psid) return psid;
    return studentApplications.filter((item) => item?.id === dispatchId)[0]
      ?.psid;
  };
  const sendNotificationBtnHandler = (row) => {
    if (row?.applicationIdList)
      setIsSendNotification({
        applicationIds: row?.applicationIdList.split(",") || [],
        nextStatus: "DISPATCHED",
      });
  };
  const unholdHandler = async (id, request) => {
    try {
      setApiLoader(true);
      const response = await editStudentDLPStockEligible(id, request);
      if (response?.code === 200) {
        refreshTableData();
        // successToast(response?.message);
      }
      setApiLoader(false);
    } catch (error) {
      setApiLoader(false);
    }
  };

  useEffect(() => {
    // to refresh filter form and filter count
    setFilterForm(
      selectedTab === "DISPATCHED" ? DISPATCHED_FILTER : DISPATCH_PENDING_FILTER
    );
  }, [selectedTab]);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexFlow: "column",
        minHeight: "calc(100vh - 120px)",
      }}
    >
      <Card className="m-4">
        <CardBody>
          <div className="d-flex flex-column">
            {isApplicationLoading && <ALoader position="fixed" />}
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="heading-4 color-dark">
                List of eligible students
              </div>
              <div className="d-flex align-items-center gap-lg">
                <TableFilter
                  formHandler={filterFormHandler}
                  dropdown={dropdown}
                  filterForm={filterForm}
                  applyFilterHandler={applyFilterHandler}
                  setFilterForm={setFilterForm}
                  fetchList={refreshTableData}
                  selectedTab={selectedTab}
                  downloadHandler={downloadHandler}
                  sendNotifications={sendNotificationBtnHandler}
                />
              </div>
            </div>
            <div className="d-flex" style={{ minHeight: "500px" }}>
              <ADataGrid
                rows={studentApplications || []}
                columns={tableHeader({
                  addBarcodeHandler,
                  addDispatchDetailsHandler,
                  updateStatusHandler,
                  viewBarcodeHandler,
                  unholdHandler,
                })}
                rowId={(row) => JSON?.stringify(row)}
                rowCount={totalRecords}
                nextButtonHandler={nextButtonHandler}
                currentPage={page}
                rowHeight={50}
                height={300}
                loading={false}
                components={gridComponents}
                hideFooterSelectedRowCount={true}
                checkboxSelection={selectedTab != "ON_HOLD"}
                disableSelectionOnClick
                selectionModel={selectionModel}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(
                    selectedTab === "DISPATCH_PENDING"
                      ? newSelectionModel.filter(
                          (student) =>
                            JSON.parse(student).status === "STOCK_ISSUED"
                        )
                      : newSelectionModel
                  );
                }}
                sx={gridStyles}
                // isRowSelectable={isRowSelectable}
                autoHeight
              />
            </div>
          </div>
          {selectedTab === "DISPATCHED" ? (
            <BulkSelectionFooter
              selectionModel={selectionModel}
              setSelectionModel={setSelectionModel}
              btnText="Update status"
              bulkIssueStockHandler={() =>
                updateStatusHandler({
                  isBulk: true,
                  applications: selectionModel,
                })
              }
              deSelectAllHandler={() => setSelectionModel([])}
            />
          ) : selectedTab === "ON_HOLD" ? (
            <BulkSelectionFooter
              selectionModel={selectionModel}
              setSelectionModel={setSelectionModel}
              btnText="Unhold stock"
              bulkIssueStockHandler={
                () => {}
                // updateStatusHandler({
                //   isBulk: true,
                //   applications: selectionModel,
                // })
              }
              data={selectionModel}
              deSelectAllHandler={() => setSelectionModel([])}
            />
          ) : (
            <BulkSelectionFooter
              selectionModel={selectionModel}
              setSelectionModel={setSelectionModel}
              btnText="Download to bulk upload dispatch details"
              isSecondButton={true}
              bulkIssueStockHandler={downloadHandler}
              data={selectionModel}
              deSelectAllHandler={() => setSelectionModel([])}
            />
          )}
        </CardBody>
        {openAddBarcodePopup && (
          <BarcodeMappingPopup
            open={openAddBarcodePopup}
            setOpen={setOpenAddBarcodePopup}
            applicationData={barcodePopupData}
            setApplicationData={setBarcodePopupData}
            apiLoader={apiLoader}
            refreshTableData={refreshTableData}
          />
        )}
        <AddDispatchDetails
          open={!!isAddDispatchDetails}
          dispatchId={isAddDispatchDetails}
          currentRowData={currentRowData}
          setOpen={setIsDispatchDetails}
          addDispatchDetailsApiCall={addDispatchDetailsApiCall}
          loader={apiLoader}
          getPsid={getPsid}
        />
        <UpdateStatusPopup
          {...{
            ...updateStatusData,
            setOpen: setUpdateStatusData,
            setApiLoader,
            refreshTableData,
            addDispatchDetailsHandler,
            setIsSendNotification,
          }}
        />
        <SendNotificationPopup
          handleClose={() => setIsSendNotification(null)}
          notificationInfo={isSendNotification}
          open={!!isSendNotification}
          selectedTab={selectedTab}
        />
        <ViewBarcodePopup
          open={viewBarcode?.open}
          applicationData={viewBarcode?.data}
          setOpen={() => setViewBarcode({ open: false, data: {} })}
        />
      </Card>
    </div>
  );
};

export default DispatchPendingTable;
