import { masterServiceBaseUrl } from "../../../../../../../services/http";
import { fetchAllPromisedData } from "../../../../common/utils/methods/commonMethods/utilityMethod";

export const statusFilterOptions = [
    { label: 'Inactive', value: 'INACTIVE' },
    { label: 'Active', value: 'ACTIVE' },
    { label: 'All', value: 'ALL' },
]

export const bankTypes = [
    { label: 'Commercial', value: 'Commercial' },
    { label: 'Community', value: 'Community' },
    { label: 'General', value: 'General' },
    { label: 'Post Bank', value: 'Post Bank' },
    { label: 'Saving', value: 'Saving' },
]


export const getAllActiveCountries = async () => {
    try {
        const resp = await fetchAllPromisedData(`${masterServiceBaseUrl}/country/getAllCountry`, true);
        if (resp?.code === 200) {
            const data = resp?.data?.map(item => ({ ...item, label: item.countryDesc, value: item?.countryCode }));
            return data;
        } else {
            return [];
        }
    } catch (err) {
        console.log(err);
    }

}

export const newRow = {
    bankId: '',
    bankName: '',
    bankType:{ label: '', value: '' },
    country: { label: '', value: '' },
    bankIdentifiedCode: '',
    address: '',
    description: '',
    alternateBankCode : '',
    status: 'ACTIVE'
};