import React, { forwardRef, useEffect, useState } from 'react';
import { FaChevronRight as RightNavIcon } from 'react-icons/fa';
import BellIcon from 'views/pages/manage/homepage/notifications/BellIcon';
import { fetchAllPostPromisedData } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from 'views/pages/manage/common/constant';
import ALoader from 'views/pages/manage/common/a-loader';
import styles from '../homepage.module.scss';
import { NOTIFICATION_SUB_CLASSIFICATION_MAP, getSearchParams } from '../constant';
import { useHistory } from 'react-router';
import moment from 'moment';

const getNotificationMetaData = notificationData =>
  NOTIFICATION_SUB_CLASSIFICATION_MAP?.find(
    item => item?.subClassification === notificationData?.subClassification
  );
const Notificaiton = forwardRef(({ data }, ref) => {
  const history = useHistory();
  const handleRedirect = () => {
    if(getNotificationMetaData(data)?.redirectPathname) {
      const redirectPathname =
        (data?.subClassification === 'TIME_TABLE_EXPIRING_SOON' ||
        data?.subClassification === 'TIME_TABLE_EXPIRED')
          ? `${getNotificationMetaData(data)?.redirectPathname}/${
              data?.classificationId
            }`
          : getNotificationMetaData(data)?.redirectPathname;
      window.open(history.createHref({ pathname: `${redirectPathname}${getSearchParams(data)}` }), '_blank');
    }
  };

  return (
    <div
      className={`d-flex justify-content-center mt-2 ${
        getNotificationMetaData(data)?.redirectPathname ? 'cursor' : ''
      }`}
      onClick={handleRedirect}
    >
      <div className='mr-2'>
        <BellIcon active={false} />
      </div>
      <div className='d-flex flex-column'>
        <div className='semi-bold'>{data?.message}</div>
        <div className='color-grey-60 regular-small'>
          {getNotificationMetaData(data)?.notifiationTitle || ''} | {moment(data?.createdOn).format('DD-MMM-YYYY')} {moment(data?.createdOn).format('hh:mm a')}
        </div>
      </div>
      <div className='ml-2'>
        <RightNavIcon />
      </div>
    </div>
  );
});

const Notifications = ({
  businessAreas = [],
  fetchingBusinessAreas,
  screenIds = [],
}) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const fetchNotifications = async () => {
    const reqBody = {
      offSet: page,
      type: 'NOTIFICATION',
      screenIds: screenIds,
      businessAreaIds: businessAreas?.map(item => item?.id),
    };
    setLoading(true);
    const res = await fetchAllPostPromisedData(endpoint?.notification, reqBody);
    if (res?.code === 200) {
      if (page === 0 && res?.data?.length < 50) setHasMore(false);
      if (res?.data?.length === 0) {
        setHasMore(false);
      } else {
        setData(prev => [...prev, ...res?.data]);
        setPage(prev => prev + 1);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!fetchingBusinessAreas && businessAreas.length > 0) {
      fetchNotifications();
    } else {
      setHasMore(false);
    }
  }, [fetchingBusinessAreas, businessAreas]);

  return (
    <div
      className={`${styles['homepage__notifications-container']} ${styles['homepage__card']} rounded-0 d-flex flex-column`}
    >
      <div className='large-semi-bold label-color mb-1'>Notifications</div>
      {!loading && data?.length === 0 && (
        <div className='mt-2 label-color semi-bold'>No new notifications!</div>
      )}
      <div className='flex-grow-1 overflow-auto'>
        {data?.map((notification, idx, notifications) => (
          <Notificaiton
            data={notification}
            key={`${JSON.stringify(notification)}-${idx}`}
          />
        ))}
        {!loading && hasMore && (
          <div
            className='text-center cursor'
            onClick={() => fetchNotifications()}
          >
            Load more
          </div>
        )}
        {loading && (
          <div
            className='text-center position-relative'
            style={{ height: '50px' }}
          >
            <ALoader />
          </div>
        )}
      </div>
    </div>
  );
};

export default Notifications;