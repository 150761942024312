import { Dialog } from '@mui/material'
import React from 'react'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { MdCancel } from 'react-icons/md'
import { useSelector } from 'react-redux'
import AButton from '../../common/form-fields-mui/AButton'
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper'

const ConfirmationDialog = ({
  open,
  setOpen,
  id,
  allocateStudentHandler,
  isMulti,
  selectionModel,
  setSelectionModel = () => { }
}) => {

  const batchDetails = useSelector(state => state.allocateStudents.batchDetails)
  const handleClose = () => setOpen(false)

  const onAction = () => {
    let reqBody = [];
    if (isMulti) reqBody = selectionModel
    else reqBody = [id];
    // console.log(reqBody);
    allocateStudentHandler(reqBody);
    setSelectionModel([]);
    handleClose();
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={{ padding: "1.5rem", width: "21.3rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems:"center",
            fontSize: "2rem",
            marginBottom: "10px",
            color: "black",
          }}
        >
          <AiOutlineQuestionCircle />
          <IconButtonWrapper onClick={handleClose}>
            <MdCancel color='black' fontSize='2rem'/>
          </IconButtonWrapper>
        </div>
        <div>
          <div
            style={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "24px",
              color: "#000000",
              marginBottom: ".6rem",
            }}
          >
            Confirm Allocate Students
          </div>
          <div
            style={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#414141",
              marginBottom: ".6rem",
            }}
          >
            Are you sure you want to allocate{" "}
            {isMulti ? selectionModel.length : 1} students to batch{" "}
            {batchDetails.batchName}?
          </div>
        </div>

        <div className="mt-2 d-flex ">
          <AButton
            updatedStyle={{
              width: "7rem",
              border: "1px solid #00B0F5",
            }}
            onClick={handleClose}
          >
            No
          </AButton>
          <AButton
            updatedStyle={{
              background: "#00B0F5",
              color: "#fff",
              width: "7rem",
            }}
            onClick={onAction}
          >
            Yes
          </AButton>
        </div>
      </div>
    </Dialog>
  );
}

export default ConfirmationDialog