import { Checkbox, FormControlLabel, InputLabel } from '@mui/material'
import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import AAutoComplete from '../../common/form-fields-mui/AAutoComplete'
import { InputLabelStyle } from '../../common/form-fields-mui/ASelect'
import CommonInput from '../../common/formFeilds/input/commonInput'
import AButton from '../../common/form-fields-mui/AButton'
import commonStyles from '../common/commonStyles.module.css'
import { formatDropdown, modeDropdown } from '../constant/common'
import { statusDropdown } from '../constant/view-tests'

const checkBoxStyle = {
  borderRadius: "6px",
  // padding: 0
};

const TestsSearchFilterForm = ({
  closeFilterForm,
  removeFilterHandler = () => { },
  applyFilterHandler = () => { },
  inputHandler = () => { },
  filterForm,
  filterCount,
  dropdowns,
}) => {
  return (
    <Card className='mb-0' style={{ width: '350px' }}>
      <CardBody>
        <div style={{ color: '#414141', fontSize: '16px', fontFamily: 'Gilroy-Medium' }}>Filter</div>
        <Row>
          <Col md={6}>
            <FormControlLabel
              sx={{ marginBottom: "0px" }}
              control={
                <Checkbox
                  onChange={(e) =>
                    inputHandler(
                      e.target.checked ? true : null,
                      "isMandatory"
                    )
                  }
                  checked={filterForm?.isMandatory===true}
                  sx={checkBoxStyle}
                />
              }
              label={<span style={{ fontSize: '12px' }}>Mandatory tests</span>}
            />
          </Col>
          <Col md={6}>
            <FormControlLabel
              sx={{ marginBottom: "0px" }}
              control={
                <Checkbox
                  onChange={(e) =>
                    inputHandler(
                      e.target.checked ? false : null,
                      "isMandatory"
                    )
                  }
                  checked={filterForm?.isMandatory === false}
                  sx={checkBoxStyle}
                />
              }
              label={<span style={{ fontSize: '12px' }}>Optional tests</span>}
            />
          </Col>
        </Row>
        <Row>
          <Col className='mb-2' sm='12'>
            <AAutoComplete
              label='Test type'
              currentValue={filterForm?.testType}
              items={dropdowns?.testTypeDropdown}
              handleChange={(value) => inputHandler(value,"testType")}
              isMulti={false}
            />
          </Col>
          {/* <Col className='mb-2' sm='12'>
            <AAutoComplete
              label='Test number'
              // currentValue={filterForm?.academicYear}
              // items={dropdownData?.academicYear}
              // handleChange={(value) => inputHandler(value,"academicYear")}
              isShowCount={true}
            />
          </Col> */}
          <Col className='mb-2' sm='12'>
            <AAutoComplete
              label='Format'
              currentValue={filterForm?.format}
              items={formatDropdown}
              handleChange={(value) => inputHandler(value, "format")}
              isMulti={false}
            />
          </Col>
          <Col className='mb-2' sm='12'>
            <AAutoComplete
              label='Mode'
              currentValue={filterForm?.mode}
              items={modeDropdown}
              handleChange={(value) => inputHandler(value, "mode")}
              isMulti={false}
            />
          </Col>
          <Col className='mb-2' md='12'>
            <InputLabel style={InputLabelStyle}>Date range</InputLabel>
            <Row style={{ marginBottom: '-1.5rem' }}>
              <Col md='6'>
                <CommonInput
                  type={"datePicker"}
                  mandatory={true}
                  placeHolder="From"
                  value={filterForm?.startDateBegin}
                  onChange={(value) =>
                    inputHandler(new Date(value), "startDateBegin")
                  }
                />
              </Col>
              <Col md='6'>
                <CommonInput
                  type={"datePicker"}
                  mandatory={true}
                  placeHolder="To"
                  value={filterForm?.endDateEnd}
                  onChange={(value) =>
                    inputHandler(new Date(value), "endDateEnd")
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col className='mb-2' sm='12'>
            <AAutoComplete
              label='Status'
              currentValue={filterForm?.active}
              items={statusDropdown}
              handleChange={(value) => inputHandler(value, "active")}
              isMulti={false}
            />
          </Col>
        </Row>
        <Row className='mt-2'>
          {filterCount > 0 && (
            <Col>
              <AButton
                className={commonStyles.button_remove_left_margin}
                onClick={() => { removeFilterHandler(); closeFilterForm(); }}
                updatedStyle={{
                  border: "1px solid #CC5F5F",
                  borderRadius: "12px",
                  color: "#CC5F5F",
                }}
              >
                Remove Filter
              </AButton>
            </Col>
          )}
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <AButton
              onClick={() => { applyFilterHandler(); closeFilterForm(); }}
              updatedStyle={{
                background: "#00B0F5",
                border: "1px solid #00B0F5",
                borderRadius: "12px",
                color: "#fff"
              }}
            >
              Apply Filter
            </AButton>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default TestsSearchFilterForm