import { Checkbox, FormControlLabel } from '@mui/material'
import React from 'react'

const checkBoxStyle = { borderRadius: "6px", padding: '0 5px' };
const formControlLabeStyle = {marginBottom: 0, marginRight: 0 };

const SelectSearchInputParam1 = ({ searchInputParam, setSearchInputParam, searchParamTitleMap ,styles }) => {
  return (
    <div className='d-flex justify-content-between align-items-center' style={{ width: '240px', padding: '0.5rem', ...styles }}>
      {
        Object.keys(searchParamTitleMap).map(el => {
          return   <div>
          <FormControlLabel
            sx={formControlLabeStyle}
            control={
              <Checkbox
                onChange={(e) => e.target.checked && setSearchInputParam(el)}
                checked={searchInputParam === el}
                sx={checkBoxStyle}
              />
            }
            label={<span style={{ fontSize: '12px' }}>{searchParamTitleMap[el]?.dispValue ?? searchParamTitleMap[el]}</span>}
          />
        </div>
        })
      }
    </div>
  )
}

export default SelectSearchInputParam1