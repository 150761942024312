import React, { useState } from "react";
import { withRouter } from "react-router";
import { Button, Card, CardBody, CardFooter, Input, Table } from "reactstrap";

import moment from "moment";
import {
  masterServiceBaseUrl,
  putpost,
  putpost2,
} from "../../../../../../services/http";
import { MasterHeaderAuto } from "../../../common/commonComponents/masterHeader/masterHeader";
import {
  CustomCard,
  CustomContainer,
  ToExcel
} from "views/pages/manage/common/commonComponents";
import { fetchAllPostPromisedData, getObjectById } from '../../../common/utils/methods/commonMethods/utilityMethod'

import BuildingForm from "./buildingForm";
import BuildingRow from "./buildingRow";
import { successToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import ALoader from '../../../common/a-loader/index'
import { endpoint } from "../../../common/constant";
import NoRowOverlay from 'views/pages/manage/masters/holidayMaster/NoRowOverlay.jsx'
import ServerPagination from "../../../common/commonComponents/pagination/ServerPagination";
import useConfirm from "views/pages/manage/common/commonComponents/ConfirmationDialog/confirmationPrompt";

const excelDetails = {
  "Business Group": "businessGroup",
  "Comapany Code": "companyCode",
  Zone: "zone",
  Region: "region",
  "Business Area": "businessArea",
  "Building Name": "buildingName",
  Address: "address",
  City: "city",
  State: "state",
  Country: "country",
  Pincode: "pincode",
};

const Cardheader = ({
  isSaveVisible = false,
  newRow = () => { },
  buildingArray,
  headers,
  getDisplayVal = () => { },
  pincodeData = [],
  stateData = [],
  cityData = [],
  countryData = [],
  setIsSaveVisible = () => { }
}) => {
  const dataToExport = buildingArray?.map((item) => {
    return {
      ...item,
      businessArea: headers?.businessArea?.label,
      businessGroup: headers?.businessGroup?.label,
      companyCode: headers?.companyCode?.label,
      region: headers?.region?.label,
      zone: headers?.zone?.label,
      city: getDisplayVal(item.city, cityData)?.text ?? "",
      state: getDisplayVal(item.state, stateData)?.text ?? "",
      country: getDisplayVal(item.country, countryData)?.text ?? "",
      pincode: getDisplayVal(item.pinCode, pincodeData)?.text ?? "",
    };
  });

  return (
    <>
      <h3>Building Master</h3>
      <div className="d-flex flex-row  justify-content-between">
        {!isSaveVisible && buildingArray.length ? <ToExcel data={dataToExport} detailObj={excelDetails} name="Floor" isSaveVisible={isSaveVisible} /> : null}
        {/* <Button
          disabled={isSaveVisible}
          color="info"
          size="sm"
          type="button"
          className={
            "floatRight mx-1 ml-2" + (isSaveVisible ? " btn-dark" : null)
          }
          onClick={()=>{newRow();setIsSaveVisible(true)}}
        >
          Add Building
        </Button> */}
      </div>
    </>
  );
};

var _ = require("lodash");
const BuildingTable = (props) => {
  const {
    // fetchClasses,
    // setclassArray,
    classArray,
    // serverData,
    // isSaveVisible,
    // setIsSaveVisible = () => {},
    pincodeData = [],
    stateData = [],
    cityData = [],
    countryData = [],
    // setHeaderDataForFloor = () => { },
    totalPages,
    page,
    pageChangeHandler
  } = props;
  const [buildingData, setBuildingData] = useState({
    buildingName: "",
    address: "",
    pincode: "",
    city: "",
    stateId: "",
    country: "",
    status: "ACTIVE",
  });

  const [isSaveVisible, setIsSaveVisible] = useState(false)
  const [apiLoader, setApiLoader] = useState(false);
  const [ConfirmationDialog, confirm] = useConfirm();

  const isNavigationAllowed = async(n) => {
    if(isSaveVisible){
      const canContinue = await confirm();
      if(canContinue){
        setIsSaveVisible(false);
        pageChangeHandler(n)
      }else{
        return
      }
    }else{
      pageChangeHandler(n)
    }
  }

  const headerList = [
    { name: "Building Name", isRequired: true },
    { name: "Address", colSpan: "2", isRequired: true },
    { name: "City", isRequired: true },
    { name: "State", isRequired: true },
    { name: "Country", isRequired: true },
    { name: "Pincode", isRequired: true },
    { name: "Status", isRequired: true },
    { name: "Add/View Floor" },
    { name: "Actions" },
  ];

  // const newRow = () => {
  //   setIsSaveVisible(true)
  //   let cb = () => {
  //     addNewClass();
  //     // setIsSaveVisible(true);
  //     setIsSaveVisible(true)
  //   };
  //   let _tempArray = classArray;
  //   _tempArray = _.sortBy(_tempArray, [
  //     function (o) {
  //       return o.value;
  //     },
  //   ]);
  //   setclassArray(_tempArray);
  //   // setIsSaveVisible(false);
  //   setIsSaveVisible(false)
  //   cb();
  // };

  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      setIsSaveVisible(true);
      let tempData = classArray;
      tempData[index]["isEdit"] = true;
      setBuildingData({
        buildingName: tempData[index]["buildingName"],
        address: tempData[index]["address"],
        pincode: tempData[index]["pinCode"],
        city: tempData[index]["city"],
        stateId: tempData[index]["state"],
        country: tempData[index]["country"],
        status: tempData[index]["status"],
      });
    }
  };

  const deleteRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let tempData = classArray;
      let id = tempData[index]["id"];

      // id is used when api ready

      if (window.confirm("Are you sure you want to delete this Building?")) {
        setApiLoader(true);
        putpost2(
          masterServiceBaseUrl +
          "/buildingMaster/deleteBuildingMaster/" +
          id,
          (data) => {
            successToast(data.message);
            // props.searchBuilding();
            props.fetchBuildingMaster();
            resetFields();
            setApiLoader(false);
          },
          (data) => {
            failureToast(data.message);
            setApiLoader(false);
          },
          "Delete"
        );
      }
    }
  };

  const saveRow = async (index) => {
    let tempData = classArray;
    let isValid = true;
    if (!buildingData.buildingName || !buildingData.buildingName.trim()) {
      failureToast("Please Enter Building Name");
      return;
    } else if (!buildingData.address || !buildingData.address.trim()) {
      failureToast("Please Enter Building Address");
      return;
    } else if (!buildingData.pincode) {
      failureToast("Please Select Pincode");
      return;
    } else {
      if (tempData[index]["isEdit"]) {
        let tempObject = {
          businessArea: props.headers?.businessArea?.value,
          businessGroup: props.headers?.businessGroup?.value,
          companyCode: props.headers?.companyCode?.value,
          region: props.headers?.region?.value,
          zone: props.headers?.zone?.value,
          buildingMasterDto: {
            ...tempData[index],
            state: buildingData?.stateId,
            country: buildingData?.country,
            pinCode: buildingData?.pincode,
            city: buildingData?.city,
            address: buildingData?.address,
            buildingName: buildingData?.buildingName,
            status: buildingData?.status,
          },
        };
        // tempData[index] = tempObject;
        // let id = tempData[index]["buildingMasterDto"]["id"];
        setApiLoader(true);
        // putpost(
        //   `${masterServiceBaseUrl}/buildingMaster/updateBuildingMaster/${tempData?.[index]?.['id']}`,
        //   (data) => {
        //     successToast(data.message);
        //     // props.searchBuilding();
        //     props.fetchBuildingMaster();
        //     setIsSaveVisible(false)
        //     resetFields();
        //     setApiLoader(false);
        //   },
        //   (data) => {
        //     failureToast(data.message);
        //     setApiLoader(false);
        //   },
        //   tempObject?.buildingMasterDto, // this payload was updated on backend in new dev phase
        //   "put"
        // );
        const res = await fetchAllPostPromisedData(`${endpoint?.building?.updateBuilding}/${tempData?.[index]?.['id']}`, tempObject?.buildingMasterDto, 'put')
        if (res?.code === 200) {
          successToast(res?.message);
          await props?.fetchBuildingMaster();
          setIsSaveVisible(false);
          resetFields();
          setApiLoader(false);
        } else {
          setApiLoader(false);
        }
      } else if (tempData[index]["isNew"]) {
        // putPostApi(`${concessionServiceBaseUrl}/addSubCategory`,tempObject,'post');
        // setIsSaveVisible(false);
        // resetFields()
        let tempObject = {
          buildingMasterDto: {
            address: buildingData.address,
            buildingName: buildingData.buildingName,
            city: buildingData.city,
            country: buildingData.country,
            pinCode: buildingData.pincode,
            state: buildingData.stateId,
            status: buildingData.status,
          },
          businessArea: props.headers?.businessArea?.value,
          businessGroup: props.headers?.businessGroup?.value,
          companyCode: props.headers?.companyCode?.value,
          region: props.headers?.region?.value,
          zone: props.headers?.zone?.value,
          isEdit: false,
          isNew: false,
        };

        setApiLoader(true);
        putpost(
          `${masterServiceBaseUrl}/buildingMaster/createBuildingMaster`,
          (data) => {
            successToast(data.message);
            // props.searchBuilding();
            setIsSaveVisible(false)
            resetFields();
            setApiLoader(false);
          },
          (data) => {
            failureToast(data.message);
            setApiLoader(false);
          },
          tempObject,
          "post"
        );

        // putPostApi(`${concessionServiceBaseUrl}/addSubCategory`,tempObject,'post');
      }
    }
  };

  const cancelRow = (index) => {
    let tempData = classArray;
    if (tempData[index]["isEdit"]) tempData[index]["isEdit"] = false;
    // else if (tempData[index]["isNew"]) tempData.splice(index, 1);
    // setclassArray(tempData);
    setIsSaveVisible(false)
    resetFields();
  };

  const resetFields = () => {
    setBuildingData({
      buildingName: "",
      address: "",
      pincode: "",
      city: "",
      stateId: "",
      country: "",
      country: "",
      status: "ACTIVE",
    });
  };

  // const getDisplayVal = (id, list) => {
  //   const i =
  //     id &&
  //     list?.filter((item) => {
  //       return item.id == id;
  //     });
  //   return i && i?.length ? i[0] : "";
  // };

  // const getShortDescription = (id) => {
  //     const i = classArrayList.filter(item=> {return item.id == id})
  //     return i && i.length ? i[0] : null
  // }


  return (
    <CustomContainer>
      <CustomCard
        HeaderElement={
          <Cardheader
            isSaveVisible={isSaveVisible}
            setIsSaveVisible={setIsSaveVisible}
            // newRow={newRow}
            headers={props.headers}
            // buildingArray={serverData}
            buildingArray={classArray}
            getDisplayVal={getObjectById}
            pincodeData={pincodeData}
            stateData={stateData}
            cityData={cityData}
            countryData={countryData}
          />
        }
      >
        <div className=" position-relative">
          {(props?.getAllApiLoader || apiLoader) && <ALoader position={'fixed'} />}
          <div id="questionTable" className="table-responsive p-3">
            <Table className="align-items-center table-flush table-layout">
              <MasterHeaderAuto headerList={headerList} />
              <tbody>
                {classArray?.length > 0 ? classArray.map((data, index) =>
                  data["isEdit"] || data["isNew"] ? (
                    <BuildingForm
                      key={"NewOrEditRow" + index}
                      buildingData={buildingData}
                      setBuildingData={setBuildingData}
                      data={data}
                      index={index}
                      saveRow={saveRow}
                      cancelRow={cancelRow}
                      getDisplayVal={getObjectById}
                      isSaveVisible={isSaveVisible}
                      setIsSaveVisible={setIsSaveVisible}
                      {...props}
                    />
                  ) : (
                    <BuildingRow
                      key={"TDROW" + index}
                      data={data}
                      index={index}
                      history={props.history}
                      deleteRow={deleteRow}
                      editRow={editRow}
                      isSaveVisible={isSaveVisible}
                      getDisplayVal={getObjectById}
                      {...props}
                    />
                  )) : (
                  <tr><td className="p-0" colSpan={20}><NoRowOverlay /></td></tr>
                )}
              </tbody>
            </Table>
          </div>
          {totalPages>0 && (
            <CardFooter>
              <ServerPagination
                totalPages={totalPages}
                currentPage={page + 1}
                nextHandler={isNavigationAllowed}
              />
            </CardFooter>
          )}
        </div>
      </CustomCard>
      <ConfirmationDialog />
    </CustomContainer>
  );
};

export default withRouter(BuildingTable);
