import React from "react";
import ReactExport from "react-data-export";
import { Button } from "reactstrap";
import {RiFileExcel2Line} from 'react-icons/ri';
import styles from './exportFile.module.scss';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const ToExcel = ({ data=[], detailObj={}, name,filename='Download' }) => {

  const column = detailObj && Object.keys(detailObj)?.map((item, i) => {
    return <ExcelColumn label={item} value={detailObj[item]} />;
  });


  return (
    <ExcelFile
        filename={filename}
      element={
        <Button size="sm" type="button" className={`mx-1 floatRight ${styles.export_button}`} >
          {/*<RiFileExcel2Line style={{height: '20px',width: '20px'}}/>*/}
          <img src="https://upload.wikimedia.org/wikipedia/commons/7/73/Microsoft_Excel_2013-2019_logo.svg" width="15" className={`${styles.export__icon}`} alt="" />
           &nbsp;&nbsp;Export To Excel
        </Button>
      }
    >
      <ExcelSheet data={data} name={name}>
        {column}
      </ExcelSheet>
    </ExcelFile>
  );
}

export default ToExcel;