import React from 'react'
import APopover from 'views/pages/manage/common/a-popover';
import AButton from 'views/pages/manage/common/form-fields-mui/AButton';
import AInput from 'views/pages/manage/common/formFeilds/customInput/AInput'
import SearchFilterForm from './SearchFilterForm'

const highlightedStyle = {
  border: "1px solid #00B0F5",
  color: "#00B0F5",
};
const normalStyle = {
  border: "1px solid #CCCCCC",
};
const SearchFilter = ({
  filterCount = 0,
  filterForm,
  filterFormHandler,
  applyNameOrIdFilterHandler,
  clearNameOrIdFilterHandler,
  applyFilterHandler,
  removeFilterHandler,
  setBusinessAreaData,
  businessAreaData,
  dropdowns,
}) => {

  const onKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      applyNameOrIdFilterHandler()
    }
  }

  return (
    <div className='d-flex justify-content-between align-items-center'>
      <div className='heading-4 color-dark'></div>
      <div className='d-flex align-items-center'>
        <AInput
          type='text'
          placeholder={'Search by Name or ID'}
          value={filterForm?.holidayNameOrHolidayId}
          searchIcon
          crossIcon
          handler={(value) => filterFormHandler(value, 'holidayNameOrHolidayId')}
          onKeyDown={onKeyDown}
          crossHandler={() => { filterFormHandler('', 'holidayNameOrHolidayId'); clearNameOrIdFilterHandler(); }}
          searchIconClickHandler={applyNameOrIdFilterHandler}
          style={{ borderRadius: '4px', height: '28px', ...normalStyle }}
        />
        <APopover
          buttonComponent={(onClick, styles) => (
            <AButton
              onClick={onClick}
              updatedStyle={{ minWidth: '61px', height: '28px', ...(filterCount > 0 ? highlightedStyle : styles) }}
            >
              Filter {filterCount > 0 && <>( {filterCount} )</>}
            </AButton>
          )}
          menuComponent={(closeForm) =>
            <SearchFilterForm
              closeForm={closeForm}
              filterCount={filterCount}
              filterForm={filterForm}
              filterFormHandler={filterFormHandler}
              applyFilterHandler={applyFilterHandler}
              removeFilterHandler={removeFilterHandler}
              setBusinessAreaData={setBusinessAreaData}
              businessAreaData={businessAreaData}
              dropdowns={dropdowns}
            />
          }
        />
      </div>
    </div>
  )
}

export default SearchFilter