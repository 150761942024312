import React, { useEffect, useState } from "react";
import { ReactComponent as GroupIcon } from "assets/img/svg/Group.svg";
import { ReactComponent as DownArrowIcon } from "assets/img/svg/fi-chevron-down.svg";
import { Checkbox, FormControlLabel, Popover, Radio } from "@mui/material";
import SearchFilter from "./SearchFilter";
import { primaryCheckboxStyles } from "views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant";
import { inputContainerStyle } from "views/pages/manage/academicPlanAndTest/academicTimetable/lectureModule/selectLecture/helperFile";
import { ReactComponent as NoLocationIcon } from "assets/img/svg/fluent-location-off-28-filled.svg";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
const popoverStyle = {
  minWidth: "15rem",
  minHeight: "7rem",
  height: "20rem",
  overflow: "scroll",
};

const DropdownItem = ({ item, checked, onChange, disabled }) => {
  return (
    <div className="d-flex align-items-center pb-1">
      {!disabled && (
        <FormControlLabel
          sx={{ margin: "0px", ...primaryCheckboxStyles }}
          control={
            <Radio
              onChange={(e) => onChange(e.target.checked ? true : false, item)}
              checked={checked}
            />
          }
        />
      )}
      <span className="global-font-size" style={{fontSize: 14, fontFamily: 'Gilroy-Medium', fontWeight: '400'}}>{item?.value}</span>
    </div>
  );
};

const BusinessAreaSelector = ({
  dropdownData = [],
  selectedBusinessArea,
  setSelectedBusinessArea,
}) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [filteredBusinessArea, setFilteredBusinessArea] = useState([]);
  const [appliedFilteredString, setAppliedFilteredString] = useState(null);

  useEffect(() => {
    setFilteredBusinessArea(
      dropdownData.length &&
        dropdownData?.filter((item) =>
          item["value"]
            ?.toLowerCase()
            .includes(appliedFilteredString?.toLowerCase())
        )
    );
  }, [appliedFilteredString]);

  useEffect(() => {
    setFilteredBusinessArea(dropdownData);
    !selectedBusinessArea && setSelectedBusinessArea(dropdownData[0]);
  }, [dropdownData]);

  const onChangeHandler = (checked, businessArea) => {
    if (checked) {
      setSelectedBusinessArea(businessArea);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const open = Boolean(anchorEl);
  const id = open ? "popover-businessArea" : undefined;

  return (
    <>
      <div className="d-flex align-items-start justify-content-start ">
        <div className="mr-2 d-flex align-items-end">
          <GroupIcon />
        </div>
        <div
          className="d-flex align-items-start justify-content-center"
          onClick={handleClick}
        >
          <div className="d-flex flex-column">
            <div
              style={{
                color: "white",
                fontSize: 16,
                fontFamily: "Gilroy-Bold",
                fontWeight: "400",
              }}
            >
              {selectedBusinessArea?.businessAreaDispValue}
            </div>
            <div
              style={{
                width: "100%",
                color: "white",
                fontSize: 12,
                fontFamily: "Gilroy-SemiBold",
                fontWeight: "400",
              }}
            >
              {selectedBusinessArea?.businessAreaDesc}
            </div>
          </div>
          <DownArrowIcon className="cursor"/>
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="d-flex align-items-center justify-content-between p-2 m-2">
          <div className="d-flex flex-column p-2">
            <div
              style={{
                color: "#333333",
                fontSize: 24,
                fontFamily: "Gilroy-Bold",
                fontWeight: "400",
              }}
            >
              Select branch
            </div>
            <div
              style={{
                color: "#333333",
                fontSize: 14,
                fontFamily: "Gilroy-Medium",
                fontWeight: "400",
                lineHeight: "20px",
              }}
            >
              Search by branch code or name
            </div>
          </div>
          <CrossIcon onClick={() => handleClose()} />
        </div>

        <div className="p-2 m-2" style={popoverStyle}>
          <div style={inputContainerStyle}>
            {
              <SearchFilter
                setAppliedFilteredString={setAppliedFilteredString}
                appliedFilteredString={appliedFilteredString}
              />
            }
            {filteredBusinessArea &&
              filteredBusinessArea.length > 0 &&
              filteredBusinessArea.map((ba) => (
                <DropdownItem
                  key={ba?.value}
                  checked={
                    selectedBusinessArea &&
                    selectedBusinessArea?.value === ba.value
                  }
                  item={ba}
                  // disabled = {isDisabled}
                  onChange={onChangeHandler}
                />
              ))}
            {filteredBusinessArea &&
              filteredBusinessArea.length === 0 &&
              dropdownData.length > 0 && (
                <>
                  <div className="d-flex flex-column  align-items-center mt-5 text-center">
                    <div><NoLocationIcon /></div>
                    <div
                      style={{
                        color: "#B32306",
                        fontSize: 12,
                        fontFamily: "Gilroy-Medium",
                        fontWeight: "400",
                      }}
                    >
                      <span>
                        Branch doesn’t exist in the list. Please contact{" "}
                      </span>
                      <span style={{ textDecoration: "underline" }}>
                        productsupport@aesl.in
                      </span>
                      <span> to tag a branch.</span>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      </Popover>
    </>
  );
};
export default BusinessAreaSelector;
