import React, { useEffect, useState } from "react";
import { Container, Card, CardHeader, Button } from "reactstrap";
import PhoneForm from "./phoneForm";
import {
  putpost,
  getAPI,
  masterServiceBaseUrl,
} from "services/http";
import { Accordion } from "../../../../common/commonComponents/Accordion/accordion";
import { putpost2 } from "services/http";
import { MasterDataForSelect } from "../../../../common/utils/methods/commonMethods/masterDataForSelect";
import { failureToast, successToast } from "../../../../common/utils/methods/toasterFunctions/function";
import { PermisionDenied } from "../../../../common/commonComponents";
import CustomButton from "components/CustomButton";

const ContactPhone = (props) => {
  const { action, isDisabled, userPermissions } = props;
  const [isVisible, setIsVisible] = useState(true);
  const [phoneArr, setPhoneArr] = useState([]);
  const [isFetching, setISFetching] = useState(false);
  const [isEditVisible, setEditVisible] = useState(false);
  const [canReset, setCanResst] = useState(false);
  const [phoneTypeData, setPhoneTypeData] = useState([]);
  const [isShowNewForm, setIsShowNewForm] = useState(false);
  const getAllContactPhone = (cId) => {
    setISFetching(true);
    getAPI(
      `${masterServiceBaseUrl}/phone/getPhoneByReference/${cId}`,
      (data) => {
        setPhoneArr(data.data);
        setISFetching(false);
      },
      (data) => {
        // failureToast(data["message"]);
        setPhoneArr([]);
        setISFetching(false);
      }
    );
  };

  const addPhone = (newData) => {
    newData["referenceId"] = props.cId;
    const obj = {
      ...newData,
      phoneTypeId: newData.phoneTypeId.value,
      status: newData.status.value,
    };
    setISFetching(true);
    putpost(
      `${masterServiceBaseUrl}/phone/createPhone`,
      (data) => {
        successToast(data["message"]);
        obj["id"] = data.data.id;
        setPhoneArr([...phoneArr, obj]);
        setCanResst(true);
        setIsVisible(false);
        setISFetching(false);
      },
      (data) => {
        failureToast(data["message"]);
        setISFetching(false);
      },
      obj,
      "post"
    );
  };

  const editPhone = (newData) => {
    const obj = {
      ...newData,
      phoneTypeId: newData.phoneTypeId.value,
      status: newData.status.value,
    };
    setISFetching(true);
    putpost(
      `${masterServiceBaseUrl}/phone/updatePhone/${newData.id}`,
      (data) => {
        successToast(data["message"]);
        setEditVisible(false);
        getAllContactPhone(props.cId);
      },
      (data) => {
        failureToast(data["message"]);
        setISFetching(false);
      },
      obj,
      "put"
    );
  };

  useEffect(() => {
    props.cId && getAllContactPhone(props.cId);
    MasterDataForSelect(
      `${masterServiceBaseUrl}/phoneType/getAllActivePhoneType`,
      "phoneType",
      setPhoneTypeData
    );

    // console.log('inside the section for phone', isDisabled);
    setIsVisible(!isDisabled);
    //  setIsShowNewForm(isDisabled);


    // console.log('isnide t-----', isDisabled)

  }, [props.cId, isDisabled]);


  const deletePhone = (id) => {
    if (
      window.confirm("Are you sure you want to delete this Contact History?")
    ) {
      setISFetching(true);
      putpost2(
        `${masterServiceBaseUrl}/phone/deletePhone/${id}`,
        (data) => {
          successToast(data["message"]);
          getAllContactPhone(props.cId);
        },
        (data) => {
          failureToast(data["message"]);
          setISFetching(false);
        },
        "Delete"
      );
    }
  };

  const phoneList =
    phoneArr &&
    phoneArr.map((item) => {
      // {
      //   // console.log('item Phone item', item)
      // }
      return (
        <Accordion title={item.phoneDispValue} removePadding={action === 'edit' || action === 'view' ? true : false} >
          <PhoneForm
            data={item}
            isDisabled={true}
            editPhone={editPhone}
            phoneTypeData={phoneTypeData}
            isEditable={true}
            setEditVisible={setEditVisible}
            isEditVisible={isEditVisible}
            deletePhone={() => deletePhone(item.id)}
            isFetching={isFetching}
            action={action}
            userPermissions={userPermissions}
          />
        </Accordion>
      );
    });

  // console.log("i m in phone master " + action)
  return (
    userPermissions ?
      <Container>
        {!(action === 'view') ?
          <Card>
            {isVisible && !isEditVisible || (action === 'edit' ? !isDisabled : true) ? <CardHeader className="border-0">
              <h3 className="mb-0 floatLeft">Contact Phone Details</h3>
              {/* <Button
            disabled={isVisible || isEditVisible}
            color="info"
            size="sm"
            type="button"
            className={
              "floatRight mx-1" +
              (isVisible || isEditVisible ? " btn-dark" : null)
            }
            onClick={() => setIsVisible(true)}
          >
            New Phone Details
          </Button> */}
              <CustomButton
                className={
                  "floatRight mx-1" +
                  (isVisible || isEditVisible ? " btn-dark" : null)
                }
                content={"New Phone Details"}
                permissionType={'C'}
                icon={true}
                onClick={() => setIsVisible(true)}
                permissionSet={userPermissions}
                disabled={isVisible || isEditVisible}
              />

            </CardHeader> : null}
            {isVisible && !isEditVisible && (action === 'edit' ? !isDisabled : true) ? (
              <PhoneForm
                addPhone={addPhone}
                phoneTypeData={phoneTypeData}
                isFetching={isFetching}
                canReset={canReset}
                setIsVisible={setIsVisible}
                isDisabled={isDisabled}
                action={action}
                isEditable={false}
                userPermissions={userPermissions}
              />
            ) : null}
          </Card> : null}
        <div>{phoneList}</div>
      </Container> : <PermisionDenied />
  );
};

export default ContactPhone;
