import {Button, Col, FormGroup, Input, Label, Row} from "reactstrap";
import React from "react";
import Select2 from "react-select2-wrapper";
import {RedAstric} from "../examIdSearchForm";
import {getDispValForReactSelectByOneID} from "../../../common/utils/methods/commonMethods/utilityMethod";
const ExamDetails = (props) => {
    const {
        formData, setFormData, Update, submitHandler,examType,action,examMethod,
        isCollapseThreeOpen, setIsCollapseThreeOpen,nextHandler,examMode,setAcademicCareer,
        termData, academicGroup, academicCareer, classData,examGroup,examPattern,setAcademicGroup,testSequence,termDatForEditAndView,label,placeholder,button
    } = props;
    return (
        <div className='container-fluid'>
            <Row>
                <Col md={3}>
                    <FormGroup>
                        <Label for="examType" className="form-control-label">{label.term}  {action ==='new' ? <RedAstric/>:null}</Label>
                        {
                            action === 'new'
                            ?
                            <Select2 disabled={action === 'edit'} options={{placeholder: placeholder.term,}} data={termData}
                                  defaultValue={formData['termId']}
                                  onChange={(e) => Update('termId', e.target.value)}/>
                            :
                            <Input disabled={true} className="tdSelect" value={ formData.termId ? getDispValForReactSelectByOneID(formData['termId'],'DispValue',termDatForEditAndView) :'Not Found'}/>
                        }
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="examType" className="form-control-label">{label.academicGroup}  {action ==='new' ? <RedAstric/>:null}</Label>
                        {
                            action === 'new' ?
                                <Select2 disabled={action === 'edit'}
                                         options={{placeholder: placeholder.academicGroup,}}
                                         data={academicGroup}
                                         defaultValue={formData['academicGroupId']} onChange={(e) => Update('academicGroupId', e.target.value)}/>
                            :
                                <Input disabled={true} className="tdSelect" value={ formData.academicGroupId ? getDispValForReactSelectByOneID(formData['academicGroupId'],'DispValue',academicGroup) :'Not Found'}/>
                        }
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="examId" className="form-control-label">{label.academicCareer}  {action ==='new' ? <RedAstric/>:null}</Label>
                        {
                            action === 'new' ?
                                <Select2 disabled={action === 'edit'}  options={{placeholder: placeholder.academicCareer}} data={academicCareer} defaultValue={formData['academicCareerId']} onChange={(e) => Update('academicCareerId', e.target.value)}/>
                                :
                                <Input disabled={true} className="tdSelect" value={ formData.academicCareerId ? getDispValForReactSelectByOneID(formData['academicCareerId'],'DispValue',academicCareer) :'Not Found'}/>
                        }
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="examId" className="form-control-label">{label.class}  {action ==='new' ? <RedAstric/>:null}</Label>
                        {
                            action === 'new' ?
                                <Select2 disabled={action === 'edit'}   options={{placeholder: placeholder.class,}} data={classData} defaultValue={formData['classId']} onChange={(e) => Update('classId', e.target.value)}/>
                                :
                                <Input disabled={true} className="tdSelect" value={ formData.classId ? getDispValForReactSelectByOneID(formData['classId'],'DispValue',classData) :'Not Found'}/>
                        }
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="examId" className="form-control-label">{label.examType}  {action ==='new' ? <RedAstric/>:null}</Label>

                        {
                            action === 'new' ?
                                <Select2 options={{placeholder: placeholder.examType,}}
                                         data={examType}
                                         disabled={action === 'edit'}
                                         defaultValue={formData['examTypeId']}
                                         onChange={(e) => Update('examTypeId', e.target.value)}
                                />
                                :
                                <Input disabled={true} className="tdSelect"
                                       value={
                                           formData.examTypeId
                                           ? getDispValForReactSelectByOneID(formData['examTypeId'],'DispValue',examType)
                                           :'Not Found'}
                                />
                        }
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="examType" className="form-control-label">{label.examGroup}  {action ==='new' ? <RedAstric/>:null}</Label>
                        {
                            action === 'new' ?
                                <Select2 disabled={action ==='edit'} options={{placeholder: placeholder.examGroup,}} data={examGroup} defaultValue={formData['examGroupId']} onChange={(e) => Update('examGroupId', e.target.value)}/>
                                :
                                <Input disabled={true} className="tdSelect"
                                       value={
                                           formData.examGroupId
                                               ? getDispValForReactSelectByOneID(formData['examGroupId'],'DispValue',examGroup)
                                               :'Not Found'
                                       }
                                />
                        }

                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="examType" className="form-control-label">{label.examMode}  {action ==='new' ? <RedAstric/>:null}</Label>
                        {
                            action === 'new' ?
                                <Select2 disabled={action === 'edit'}   options={{placeholder: placeholder.examMode,}} defaultValue={formData['examModeId']} data={examMode} onChange={(e) => Update('examModeId', e.target.value)}/>
                                :
                                <Input disabled={true} className="tdSelect"
                                       value={
                                           formData.examModeId
                                               ? getDispValForReactSelectByOneID(formData['examModeId'],'DispValue',examMode)
                                               :'Not Found'
                                       }
                                />
                        }
                    </FormGroup>
                </Col>
                {action ==='new' ? null:<Col md={3}>
                    <FormGroup>
                        <Label for="examType" className="form-control-label">{label.examIdSequence}</Label>
                        <Input disabled={action !=='new'} placeholder={placeholder.examIdSequence} className="tdSelect"
                               value={formData['examIdTestSequence']}
                               onChange={(e) => Update('examIdTestSequence', e.target.value)}/>
                    </FormGroup>
                </Col>}
                <Col md={3}>
                    <FormGroup>
                        <Label for="examType" className="form-control-label">{label.examMethod}  {action ==='new' ? <RedAstric/>:null}</Label>
                        {
                            action === 'new' ?
                                <Select2 disabled={action === 'edit'}  options={{placeholder: placeholder.examMethod,}} defaultValue={formData['examMethodId']} data={examMethod} onChange={(e) => Update('examMethodId', e.target.value)}/>
                                :
                                <Input disabled={true} className="tdSelect"
                                       value={
                                           formData.examMethodId
                                               ? getDispValForReactSelectByOneID(formData['examMethodId'],'DispValue',examMethod)
                                               :'Not Found'
                                       }
                                />
                        }
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="examType" className="form-control-label">{label.examPattern} {action ==='new' ? <RedAstric/>:null}</Label>
                        {
                            action === 'new' ?
                                <Select2 disabled={action === 'edit'} options={{placeholder: placeholder.examPattern,}} defaultValue={formData['examPatternId']} data={examPattern} onChange={(e) => Update('examPatternId', e.target.value)}/>
                                :
                                <Input disabled={true} className="tdSelect"
                                       value={
                                           formData.examPatternId
                                               ? getDispValForReactSelectByOneID(formData['examPatternId'],'DispValue',examPattern)
                                               :'Not Found'
                                       }
                                />
                        }
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="examType" className="form-control-label">{label.totalNoOfTestPapers}</Label>
                        <Input disabled={action !=='new'} maxLength={3} placeholder={placeholder.totalNoOfTestPapers} className="tdSelect" value={formData['totalNoOfTestPapers']}
                               type={'number'}
                               onChange={(e) => {
                                   if(e.target.value.length <=3 ){
                                       Update('totalNoOfTestPapers', e.target.value)
                                   }
                               }}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="examType" className="form-control-label">{label.totalNoOfQuestions}</Label>
                        <Input disabled={action !=='new'} maxLength={3} type={'number'} placeholder={placeholder.totalNoOfQuestions} className="tdSelect" value={formData['totalNoOfTestQuestions']}
                               onChange={(e) => {
                                   if(e.target.value.length <=3 ) {
                                       Update('totalNoOfTestQuestions', e.target.value)
                                   }
                               }}/>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="testSequence" className="form-control-label">{label.testSequence} {action ==='new' ? <RedAstric/>:null}</Label>
                        {
                            action === 'new' ?
                                <Select2 disabled={action === 'edit'} options={{placeholder: placeholder.testSequence,}} defaultValue={formData['testSequenceMasterId']} data={testSequence} onChange={(e) => Update('testSequenceMasterId', e.target.value)}/>
                                :
                                <Input disabled={true} className="tdSelect"
                                       value={
                                           formData.examPatternId
                                               ? getDispValForReactSelectByOneID(formData['testSequenceMasterId'],'DispValue',testSequence)
                                               :'Not Found'
                                       }
                                />
                        }
                    </FormGroup>
                </Col>
            </Row>
            {action !=='view'
                ?
                (
                    <Row>
                        <Col xs={12}>
                            <Button color="info" size="sm" className="floatRight mx-1" onClick={()=>nextHandler(3)}>
                                {button.save}
                            </Button>
                        </Col>
                    </Row>
                )
                :
                null
            }
        </div>
    );
};
export default ExamDetails