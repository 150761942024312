import { InputLabel } from "@mui/material";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { InputLabelStyle } from "./ASelect";

// import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const ADatePicker = ({label, handler,value, placeholder}) => {
  return (
    <>
     {label &&<InputLabel sx={InputLabelStyle}>{label}</InputLabel>}
    <DatePicker placeholderText= {placeholder}   selected={value} onChange={handler} />
    </>
  );
}
export default ADatePicker;