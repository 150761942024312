import React, { useState, useEffect, useContext } from "react";
import AdjustmentFlagTable from './adjustmentFlagTable';
import { failureToast } from "../../common/utils/methods/toasterFunctions/function";
import { GetPagination } from "../../common/commonComponents/pagination/pagination";
import { PermissionContext, RolePermissions } from "../../../../../appContext";
import { constants, endpoint, pages } from "../../common/constant";
import { PermisionDenied } from "../../common/commonComponents";
import CustomLoader from "../../common/commonComponents/Loader/loader";
import { fetchAllPromisedData } from "../../common/utils/methods/commonMethods/utilityMethod";
import { getPaginatedData } from "../../common/utils/methods/commonMethods/paginationMethod";
const DepartmentType = () => {
  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(
    permissions,
    pages['adjustmentFlag']['id']
  );

  const [classArray, setclassArray] = useState([])
  const [state, setstate] = useState([])
  const [apiloader, setapiloader] = useState(false)
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [pagination, setpagination] = useState(constants.pagination)
  const pageSize=10;

  const fetchClasses = async (url) => {
    setapiloader(true)
    const syncData = await fetchAllPromisedData(url,true);
    if (syncData?.code === 200) {
      setstate(syncData.data);
      getPaginatedData(syncData.data, pageSize, pagination, setpagination, setapiloader, setclassArray)
    }else{
      setstate([]);
      setclassArray([]);
      failureToast(syncData['message'])
      setapiloader(true);
    }
    // getAPI(url, (data) => {
    //   setstate(data['data'])
    //   let n = pagination
    //   if (data['data'].length > pageSize) {
    //     n['nextPage'] = n.currentPage + 1
    //   }
    //   if (n.currentPage > 1) {
    //     n.previousPage = n.currentPage - 1
    //   }
    //   n['totalPage'] =Math.floor( data['data'].length / pageSize)
    //   if(data['data'].length%pageSize!=0){
    //     n['totalPage']+=1
    //   }
    //   setpagination(n)
    //   setapiloader(false)
    //   console.log(n)
    //   setclassArray(data['data'].slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || [])
    // }, (data) => { failureToast(data['message']) })
  }
  const getAllrecords = (toPreviousPage = false) => {
    if(toPreviousPage){
      let n = pagination;
      if(n.currentPage>1)
        n.currentPage=n.currentPage-1;
      setpagination(n)
    }
    fetchClasses(endpoint.adjustmentFlag.getAll);
  }
  useEffect(()=>{
    // fetchClasses(masterServiceBaseUrl+'/adjustmentFlagMaster/getAllAdjustmentFlagMasters')
    getAllrecords();
  },[])
  return (
    // <>
    //   {apiloader ?
    //     <div className="mx-auto text-center py-5 my-5" style={{ height: "100vh" }}>
    //       <Loader
    //         type="Rings"
    //         color="#00BFFF"
    //         height={70}
    //         width={70}
    //         timeout={10000}
    //       />
    //     </div>
    //     :
    //     <>
    //       {
    //         userPermissions ? (
    //           <>
    //             <AdjustmentFlagTable
    //               previousPage={previousPage}
    //               fetchClasses={fetchClasses}
    //               setclassArray={setclassArray}
    //               classArray={classArray}
    //               permissionSet={userPermissions}
    //             />
    //             {pagination.totalPage > 1 ?
    //               <GetPagination
    //                 fetchClasses={fetchClasses}
    //                 pagination={pagination}
    //                 previousPage={previousPage}
    //                 firstPage={firstPage}
    //                 lastPage={lastPage}
    //                 nextPage={nextPage}
    //                 setpagination={setpagination}
    //               />
    //               :
    //               null
    //             }
    //           </>
    //         ) : (
    //           <PermisionDenied />
    //         )
    //       }
    //     </>
    //   }

    // </>
    userPermissions ? (
      <>
        {apiloader ? (
          <CustomLoader
            apiLoader={apiloader}
            loaderHeight={'200px'}
            loaderWidth={'100% '}
          />
        ) : (
          <>
            <AdjustmentFlagTable
              getAllrecords={getAllrecords}
              fetchClasses={fetchClasses}
              setclassArray={setclassArray}
              classArray={classArray}
              permissionSet={userPermissions}
              isSaveVisible={isSaveVisible}
              setisSaveVisible={setisSaveVisible}
            />
            {pagination.totalPage > 1 ? (
              <GetPagination
                isSaveVisible={isSaveVisible}
                fetchClasses={fetchClasses}
                setclassArray={setclassArray}
                pagination={pagination}
                setpagination={setpagination}
                pageSize={pageSize}
                state={state}
              />
            ) : (
              null
            )}
          </>
        )}
      </>
    ) : (
      <PermisionDenied />
    )
  );
}
export default DepartmentType;