import { Dialog } from "@mui/material";
import React from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import AButton from "../../../../common/form-fields-mui/AButton";

const IssueStockConfirmation = ({
  open,
  setOpen,
  onAgree,
  message = false,
}) => {
  const handleClose = () => setOpen(false);
  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={{ padding: "1.5rem", width: "400px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "2rem",
            marginBottom: "10px",
            color: "black",
          }}
        >
          <AiOutlineQuestionCircle />
          <MdCancel onClick={handleClose} />
        </div>
        <div>
          <div
            style={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "24px",
              color: "#000000",
              marginBottom: ".6rem",
            }}
          >
            {message
              ? message
              : "Do you want to issue stocks to the selected student?"}
          </div>
        </div>

        <div className="d-flex" style={{ marginTop: "28px" }}>
          <AButton
            className={"button_remove_left_margin"}
            variant="outline"
            onClick={handleClose}
          >
            No, Edit
          </AButton>
          <AButton variant="outline" onClick={onAgree}>
            Yes, Issue stock
          </AButton>
        </div>
      </div>
    </Dialog>
  );
};

export default IssueStockConfirmation;
