import React, { useState } from "react";
import CustomChip from "views/pages/manage/common/customChip/CustomChip";
import { ReactComponent as EditIcon } from "../../../../../../assets/img/svg/Edit2.svg";
import { ReactComponent as Delete } from "../../../../../../assets/img/svg/Delete.svg";
import EditLectureForm from "./EditLectureForm";
import { checkDuplicateLecture, isLecturePassed } from "../helper";
import moment from "moment";
import { Tooltip } from "@mui/material";
import MsID from "./../../../edpOps/batch-serach/MsID";
import TestSyllabus from "../../testsListView/TestSyllabus";
import DeleteLectureConfirmationDialog from "./DeleteLectureConfirmationDialog";
import { endpoint } from "../../../common/constant";
import { putpost } from "../../../../../../services/utils";
import { failureToast } from "../../../common/utils/methods/toasterFunctions/function";
import ViewDocumentModal from "./ViewDocumentModal";
import LectureListItemBreak from "./LectureListItemBreak";
import CustomHolidayRow from "./CustomHolidayRow";
import { getDayShortName } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'

export const openFileInNewWindow = (key) => {
  putpost(
    `${endpoint.fetchS3Url.getDownloadUrl}?key=${key}`,
    (data) => {
      if (data.code === 200 && data.data) {
        window.open(data.data.url);
      } else {
        failureToast("Sorry Something went wrong");
      }
    },
    (data) => {
      failureToast("Sorry Something went wrong");
      // failureToast(data["message"]);
    },
    {},
    "post"
  );
};

export const badge = {
  // width: "1100",
  height: "35px",
  fontSize: "12px",
  paddingLeft: "10px",
  background: "#F5F5F5",
  borderRadius: "8px",
  // display: "flex",
  alignItems: "center",
  // justifyContent: "space-between",
};
export const rowStyle = {
  fontWeight: "400px",
  fontSize: "14px",
  color: "#262626",
};

export const RowCell = ({ width, text, children }) => (
  <div style={{ width: `${width}px` }}>{text || children}</div>
);

export const Header = ({
  isEnabledMsTeamsSync,
  showActions = true,
  openMode = "EDIT",
}) => {
  let rowClassName =
    openMode === "VIEW"
      ? "timetable-lecture-details-card-row-view"
      : "timetable-lecture-details-card-row";
  return (
    <div
      className={`${
        isEnabledMsTeamsSync
          ? rowClassName
          : "timetable-lecture-details-card-row-without-msteams"
      } ${!showActions ? "no-actions" : ""}`}
      style={{ ...rowStyle, fontWeight: 600 }}
    >
      <div>Date</div>
      <div>Day</div>
      <div>Start time</div>
      <div>End time</div>
      <div>Class type</div>
      <div>Subject</div>
      <div>Lecture</div>
      <div>Class details</div>
      <div>Faculty</div>
      <div>Room</div>
      {isEnabledMsTeamsSync && <div>MS team</div>}
      <div>Status</div>
      {showActions && <div>Actions</div>}
    </div>
  );
};

export const LectureListItem = ({
  isLabel = false,
  rowData,
  rowIndex,
  rowChangeHandler,
  deleteHandler,
  currentRoom,
  expandedIndex,
  setExpandedIndex,
  showActions = true,
  selectedFaculty,
  ...props
}) => {
  // console.log(expandedIndex, rowIndex);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [viewDocumentUrl, setViewDocumentUrl] = useState(false);
  const rowClassName =
    props?.openMode === "VIEW"
      ? "timetable-lecture-details-card-row-view"
      : "timetable-lecture-details-card-row";

  const checkLectureDuplicated = (form, rowIndex) => {
    return checkDuplicateLecture(props?.lectures, form, rowIndex);
  };

  if (rowData?.isEdit)
    return (
      <EditLectureForm
        currentRoom={currentRoom}
        roomDropdown={props?.roomDropdown}
        updateLecture={rowChangeHandler}
        rowIndex={rowIndex}
        rowData={rowData}
        checkLectureDuplicated={checkLectureDuplicated}
        selectedFaculty = {selectedFaculty}
        {...props}
      />
    );
  if (rowData.lectureName && rowData.lectureName.toLowerCase() === "break")
    return (
      <LectureListItemBreak
        rowIndex={rowIndex}
        rowData={rowData}
        isEnabledMsTeamsSync={props?.isEnabledMsTeamsSync}
        openMode={props?.openMode}
        rowClassName={rowClassName}
        isDeleteDialogOpen={isDeleteDialogOpen}
        setIsDeleteDialogOpen={setIsDeleteDialogOpen}
        deleteHandler={deleteHandler}
      />
    );

  if (rowData?.classType?.toLowerCase() === "holiday")
    return (
      <>
        <CustomHolidayRow
          rowIndex={rowIndex}
          rowData={rowData}
          isEnabledMsTeamsSync={props?.isEnabledMsTeamsSync}
          openMode={props?.openMode}
          rowClassName={rowClassName}
          isDeleteDialogOpen={isDeleteDialogOpen}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
          deleteHandler={deleteHandler}
          handleOpenHolidayModal={props.handleOpenHolidayModal}
        />
        <DeleteLectureConfirmationDialog
          open={isDeleteDialogOpen}
          setOpen={setIsDeleteDialogOpen}
          data={rowData}
          onAgree={() => deleteHandler(rowIndex)}
        />
      </>
    );

  return (
    <>
      <div
        className={`${
          props?.isEnabledMsTeamsSync
            ? rowClassName
            : "timetable-lecture-details-card-row-without-msteams"
        } my-2 ${!showActions ? "no-actions" : ""}`}
        style={{
          ...rowStyle,
          fontWeight: isLabel ? "600" : "400",
          color: isLecturePassed(rowData?.lectureEndTime)
            ? "#B0B0B0"
            : "#262626",
        }}
      >
        <div>{moment(rowData?.date)?.format("DD MMM YYYY")}</div>
        <div>{getDayShortName(rowData?.date)}</div>
        <div>{rowData?.startTime}</div>
        <div>{rowData?.endTime}</div>
        <div>{rowData?.classType}</div>
        <div>{rowData?.subject || "-"}</div>
        <div>
          <Tooltip title={rowData?.lectureName}>
            <div className="ellipsis-text-container">
              {rowData?.lectureName || "-"}
            </div>
          </Tooltip>
        </div>
        <div>
          {!["PTM", "Orientation"].includes(rowData.timeTableClassType) ? (
            <span
              className="color-primary regular-bold pointer"
              onClick={() =>
                expandedIndex === rowIndex
                  ? setExpandedIndex(-1)
                  : setExpandedIndex(rowIndex)
              }
            >
              {expandedIndex === rowIndex ? `Hide syllabus` : `View syllabus`}
            </span>
          ) : (
            <span
              className={`regular-bold ${
                rowData?.documentS3Key
                  ? "color-primary pointer"
                  : "color-grey-50 cursor-not-allowed"
              }`}
              onClick={
                rowData?.documentS3Key
                  ? () => openFileInNewWindow(rowData.documentS3Key)
                  : () => {}
              }
            >
              Download
            </span>
          )}
        </div>
        <div>
          <Tooltip title={rowData?.facultyName}>
            <div className="ellipsis-text-container">
              {rowData?.facultyName || "-"}
            </div>
          </Tooltip>
        </div>
        <div>
          <Tooltip title={rowData?.roomName}>
            <div className="ellipsis-text-container">
              {rowData?.roomName || currentRoom?.label}
            </div>
          </Tooltip>
        </div>
        {props?.isEnabledMsTeamsSync && (
          <div className="ellipsis-text-container">
            {rowData?.msTeamsId && props?.openMode === "VIEW" ? (
              <MsID
                id={rowData?.msTeamsId}
                copyUrl={rowData?.msTeamsMeetingUrl}
              />
            ) : (
              rowData?.msTeam?.label
            )}
          </div>
        )}
        <div>
          <CustomChip
            textColor={
              isLecturePassed(rowData?.lectureEndTime) ? "#262626" : "#0B442D"
            }
            bgColor={
              isLecturePassed(rowData?.lectureEndTime)
                ? "#F5F5F5"
                : rowData?.status === "ACTIVE"
                ? "#BBE2CE"
                : "#CCCCCC"
            }
            text={
              isLecturePassed(rowData?.lectureEndTime)
                ? "Completed"
                : rowData?.status
            }
          />
        </div>

        {showActions && (
          <div className="d-flex" style={{ gap: "8px" }}>
            {props?.openMode !== "VIEW" &&
              (isLecturePassed(rowData?.lectureEndTime) ? (
                <IconButtonWrapper>
                  <Tooltip title="Cannot edit as start date has passed">
                    <EditIcon onClick={() => {}} />
                  </Tooltip>
                </IconButtonWrapper>
              ) : (
                <IconButtonWrapper
                  onClick={() => {
                    if (rowData?.classType === "PTM") {
                      props.handleOpenPTMModal(
                        "PTM",
                        { ...rowData, isEdit: true },
                        rowIndex
                      );
                    } else if (
                      rowData?.classType?.toLowerCase() === "holiday"
                    ) {
                      props.handleOpenPTMModal(
                        { ...rowData, isEdit: true },
                        rowIndex
                      );
                    } else {
                      rowChangeHandler(rowIndex, { ...rowData, isEdit: true });
                    }
                  }}
                >
                  <EditIcon />
                </IconButtonWrapper>
              ))}

            {props?.openMode !== "VIEW" &&
              (isLecturePassed(rowData?.lectureEndTime) ? (
                <IconButtonWrapper>
                  <Tooltip title="Cannot delete as start date has passed">
                    <Delete />
                  </Tooltip>
                </IconButtonWrapper>
              ) : (
                <IconButtonWrapper onClick={() => setIsDeleteDialogOpen(true)}><Delete /></IconButtonWrapper>
              ))}
            <DeleteLectureConfirmationDialog
              open={isDeleteDialogOpen}
              setOpen={setIsDeleteDialogOpen}
              data={rowData}
              onAgree={() => deleteHandler(rowIndex)}
            />
          </div>
        )}
      </div>
      {expandedIndex === rowIndex && rowIndex > -1 && (
        <TestSyllabus
          testData={{ ...rowData, testScheduleId: rowData?.lectureId }}
          isTimetable={true}
          isNeedToFilterSubject={true}
        />
      )}
      <ViewDocumentModal
        setIsOpen={setViewDocumentUrl}
        isOpen={viewDocumentUrl}
      />
    </>
  );
};
