import React from 'react';
import Loader from "react-loader-spinner";

const CustomLoader = ({loaderHeight,loaderWidth,color}) => {
  return (
    <Loader
      type="TailSpin"
      color={color}
      height={loaderHeight}
      width={loaderWidth}
  />
  )
}

export default CustomLoader;
