import React from "react";
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";
import { inputStyles, selectStyles } from "./constant";
import AInput from "../../../common/formFeilds/customInput/AInput";
import {
  useGetBatchListV2,
  useGetCourseIdList,
} from "../../issuanceIDcard/customhooks";
import { isFormFieldEmpty } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { Col, Row } from "reactstrap";

const SearchByNameForm = ({ dropdown, form, formHandler, academicCareer }) => {
  // businessArea is passed conditionally to avoid search batch api call for DLP and DIGITAL
  const [batchDropdown, isLoading] = useGetBatchListV2(
    academicCareer === "RCC" ? form?.businessArea : "",
    form?.term,
    form?.courseId,
    ""
  );
  const courseIdDropdown = useGetCourseIdList(
    form?.businessArea,
    form?.academicGroup,
    form?.term
  );
  return (
    <div>
      <Row>
        <Col>
          <AAutoComplete
            label="Business area*"
            placeholder="Select"
            isMulti={false}
            items={dropdown?.businessAreas}
            currentValue={form?.businessArea}
            handleChange={(value) => {
              formHandler(value, "businessArea");
              formHandler("", "batchId");
            }}
            style={selectStyles}
          />
        </Col>
        <Col>
          <AAutoComplete
            label="Term*"
            placeholder="Select"
            isMulti={false}
            items={dropdown?.terms}
            currentValue={form?.term}
            handleChange={(value) => {
              formHandler(value, "term");
              formHandler("", "batchId");
            }}
            style={selectStyles}
          />
        </Col>
        <Col>
          <AAutoComplete
            label="Course ID"
            placeholder="Select"
            isMulti={false}
            items={courseIdDropdown}
            currentValue={form?.courseId}
            isDisabled={
              isFormFieldEmpty(form?.businessArea) ||
              isFormFieldEmpty(form?.term)
            }
            handleChange={(value) => {
              formHandler(value, "courseId");
              formHandler("", "batchId");
            }}
            style={selectStyles}
          />
        </Col>
        {academicCareer === "RCC" && (
          <Col>
            <AAutoComplete
              label="Batch"
              placeholder="Select"
              isMulti={false}
              items={batchDropdown}
              currentValue={form?.batchId}
              handleChange={(value) => {
                formHandler(value, "batchId");
              }}
              isDisabled={
                isFormFieldEmpty(form?.businessArea) ||
                isFormFieldEmpty(form?.term) ||
                isFormFieldEmpty(form?.courseId)
              }
              style={selectStyles}
              isLoading={isLoading}
            />
          </Col>
        )}
      </Row>
      <div className="mt-3">
        <AInput
          placeholder={`Search by name`}
          style={inputStyles}
          value={form?.studentName}
          handler={(value) => {
            formHandler(value, "studentName");
          }}
          crossIcon={true}
          crossHandler={() => formHandler("", "studentName")}
          disabled={
            isFormFieldEmpty(form?.businessArea) ||
            isFormFieldEmpty(form?.term) ||
            (isFormFieldEmpty(form?.courseId) &&
              isFormFieldEmpty(form?.batchId))
          }
        />
      </div>
    </div>
  );
};

export default SearchByNameForm;
