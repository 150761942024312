import React, { useEffect, useState } from 'react';
import APopover from '../../../../common/a-popover';
import { IoEyeOutline } from 'react-icons/io5';
import { Col, Row } from 'reactstrap';
import { getCourseDetailsV2 } from '../../service';
import AButton from '../../../../common/form-fields-mui/AButton';
import ALoader from '../../../../common/a-loader';

const CourseDetailsModal = ({ applicationData, closeForm }) => {
  const { applicationId, courseId, termDispValue, termDispVal } = applicationData || {};
  const [courseDetails, setCourseDetails] = useState({});
  const [apiLoader, setApiLoader] = useState(false);

  const fetchCourseDetails = async () => {
    setApiLoader(true);
    const res = await getCourseDetailsV2(courseId);
    setCourseDetails(res);
    setApiLoader(false);
  };
  useEffect(() => {
    fetchCourseDetails();
  }, []);

  return (
    <div
      className="color-grey-90 px-3 pt-3"
      style={{ width: '550px', height: '200px', overflowY: 'auto' }}
    >
      {apiLoader && <ALoader />}
      <div className="d-flex flex-column h-100">
        <div className="large-semi-bold">Application no. {applicationId}</div>
        <Row className="semi-bold mt-2">
          <Col md="3">Course ID</Col>
          <Col md="6">Course name</Col>
          <Col md="3">Term</Col>
        </Row>
        <Row className="semi-bold mt-1 flex-grow-1">
          <Col md="3">{courseId}</Col>
          <Col md="6">
            {apiLoader ? 'Loading...' : (courseDetails?.courseName || '-')}
          </Col>
          <Col md="3">{termDispValue || termDispVal}</Col>
        </Row>
        <AButton
          className="btn-left-0 mt-2 align-self-start"
          variant="primary_filled"
          size="xs"
          onClick={() => closeForm()}
        >
          Ok, got it!
        </AButton>
        <div className='pt-3'></div> {/* this div is added to take care of bottom padding when content is overflowing */}
      </div>
    </div>
  );
};
const MarkEligibleCourseDetailsPopup = ({ applicationData }) => {
  const { applicationId } = applicationData || {};
  return applicationId ? (
    <div className="ml-2">
      <APopover
        transformOriginHorizontal='center'
        anchorOriginHorizontal='center'
        buttonComponent={onclick => (
          <IoEyeOutline className="cursor" onClick={onclick} size={18} />
        )}
        menuComponent={closeForm => (
          <CourseDetailsModal
            applicationData={applicationData}
            closeForm={closeForm}
          />
        )}
      />
    </div>
  ) : (
    <></>
  );
};

export default MarkEligibleCourseDetailsPopup;
