import React, { useState } from "react";
import { Table, Button, Input, Row, Col } from "reactstrap";
import { NewRow } from "../../common/utils/methods/commonMethods/utilityMethod";
import { MasterHeaderAuto } from "../../common/commonComponents/masterHeader/masterHeader";
import {
  CustomContainer,
  CustomCard,
  ToExcel,
} from "../../common/commonComponents";

const excelDetails = {};

const Cardheader = (props) => {
  const dataToExport = [];
  return (
    <>
      <h3 className="mb-0 ">EPMR Details</h3>
      <ToExcel
        data={dataToExport}
        detailObj={excelDetails}
        name="EPMR"
        isSaveVisible={true}
      />
    </>
  );
};

const inner_details = [
  [
    { AKS_PSID: "Student ID" },
    { ASK_NAME: "Student Name" },
    { AKS_TERM: "Term" },
    { AKS_CRSCODE: "Course ID" },
  ],
  [
    { AKS_PAYMODE: "Pay Mode" },
    { AKS_AMOUNT: "Amount" },
    { AKS_APPLICATION: "Application ID" },
    { AKS_RECEIPTNUMBER: "Receipt Number" },
  ],
  [
    { AKS_RECEIPTDATE: "Receipt Date" },
    { AKS_CREATEDDATE: "EPMR Date" },
    { AKS_CREATEDBY: "Created By" },
    { CASHIER_OFFICE: "Confirmed By" },
  ],
];

const EPMRTable = ({ data }) => {
  let [isOpen, setIsOpen] = useState({});
  const headerList = [
    { name: "Select All" },
    { name: "EPMR Number" },
    { name: "EPMR Status" },
    { name: "Details" },
    { name: "Action", colSpan: "5" },
  ];

  const listToRender = ["AKS_PMRNO", "ASK_STATUS"];

  const chkCollapse = (id) => {
    setIsOpen({ ...isOpen, [id]: !isOpen?.[id] ?? true });
  };

  return (
    <CustomContainer>
      <CustomCard HeaderElement={<Cardheader />}>
        <div id="questionTable" className="table-responsive-layout">
          <Table className="align-items-center" hover>
            <MasterHeaderAuto headerList={headerList} />
            <tbody>
              {data &&
                data.map((item, index) => (
                  <>
                    <tr>
                      <td className="text-center  white-breakSpace">
                        <Input
                          type="checkbox"
                          id="checkbox2"
                          style={{ position: "relative" }}
                        />
                      </td>
                      {listToRender.map((_el, indx) => (
                        <td className="text-center  white-breakSpace">
                          {item[_el]}
                        </td>
                      ))}
                      <td className="text-center  white-breakSpace">
                        <Button
                          color="info"
                          size="sm"
                          type="button"
                          onClick={() => chkCollapse(`epmr-${index}`)}
                        >
                          EPMR Details
                        </Button>
                      </td>
                      <td
                        className="text-center  white-breakSpace td-w-100"
                        colSpan="5"
                      >
                        <Button color="info" size="sm" type="button">
                          Confirm EPMR
                        </Button>
                        <Button color="info" size="sm" type="button">
                          Cancel EPMR
                        </Button>
                        <Button color="info" size="sm" type="button">
                          Approve
                        </Button>
                        <Button color="info" size="sm" type="button">
                          Reject
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className={"hiddenRow"}
                        colSpan={8}
                      >
                        <div
                          key={`epmr-${index}`}
                          className={
                            isOpen?.[`epmr-${index}`]
                              ? "details details-height-auto"
                              : "details"
                          }
                        >
                          {inner_details.map((detail_item, index) => (
                            <Row className="p-3 text-center  white-breakSpace ml-md-5">
                              {detail_item.map((el, index) => (
                                <Col className="text-left">
                                  <div className="epmr-inner-details-header">
                                    {Object.values(el)[0]}
                                  </div>
                                  <div>{item[Object.keys(el)[0]]}</div>
                                </Col>
                              ))}
                            </Row>
                          ))}
                        </div>
                      </td>
                    </tr>
                  </>
                ))}
            </tbody>
          </Table>
        </div>
      </CustomCard>
    </CustomContainer>
  );
};

export default EPMRTable;
