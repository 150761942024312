export const STUDENT_LIST = 'STUDENT_LIST';
export const STUDENT_LIST_REQUEST = 'STUDENT_LIST_REQUEST' 
export const STUDENT_LIST_SUCCESS = 'STUDENT_LIST_SUCCESS' ;
export const STUDENT_LIST_FAILURE = 'STUDENT_LIST_FAILURE'
export const UPDATE_STUDENT_LIST_DROPDOWNS = 'UPDATE_STUDENT_LIST_DROPDOWNS'
export const STUDENT_LIST_CLEAR = "STUDENT_LIST_CLEAR"
export const START_LOADER = 'START_LOADER'
export const STOP_LOADER = 'STOP_LOADER'
export const REQUEST_DROPDOWNS = 'REQUEST_DROPDOWNS'
export const UPDATE_DROPDOWNS  = 'UPDATE_DROPDOWNS'
