import React, { useContext } from "react";
import ALoader from "views/pages/manage/common/a-loader";

import ListHeader from "./ListHeader";
import SearchInput from "./SearchInput";
import StudentFilterForm from "./StudentFilterForm";
import StudentListDataTable from "./StudentListDataTable";
import RFIDCountCard from "./RFIDCountCard";
import { IssuanceIDCardContext } from "../contextApi";
import PrintableCard from "../printIDCard/PrintableCard";
import AddToFavourite from "../../../homepage/favourites/AddToFavourite";
import { pages } from "../../../common/constant";

const StudentList = () => {
  const {
    dropdown,
    lists,
    isListLoading,
    getList,
    listTotalRecords,
    listCurrentPage,
    setListCurrentPage,
    dropdownLoader,
    isApplicationLoading
  } = useContext(IssuanceIDCardContext);

  const nextButtonHandler = (newPage) => {
    setListCurrentPage(newPage);
    getList(newPage);
  };
  return (
    <div
      className='d-flex flex-column remove-container-top-space'
      style={{ minHeight: 'calc(100vh - 79px)' }}
    >
      <div className='p-3 student-filer-container-printId'>
        {(isListLoading || dropdownLoader || isApplicationLoading) && (
          <ALoader position={'fixed'} />
        )}
        <div className='d-flex align-items-center justify-content-between'>
          <ListHeader />
          <AddToFavourite 
            screenId={pages['idCards']['id']}
            screenName={'Issue ID card'}
          />
        </div>
        <SearchInput />
        <StudentFilterForm />
      </div>

      <div className= {dropdown?.businessAreas?.length>5? "":"card-section-printID"}>
        <div className='m-2'>
          <StudentListDataTable
            rowsData={lists}
            rowCount={listTotalRecords}
            currentPage={listCurrentPage}
            nextButtonHandler={nextButtonHandler}
            isListLoading={isListLoading || dropdownLoader}
          />
        </div>
        <div className='m-2'>
          <RFIDCountCard dropdownLoader={dropdownLoader} />
        </div>
      </div>
    </div>
  );
};

export default StudentList;
