import React, { useContext } from "react";
import { Button } from "reactstrap";
import {
  checkPermission,
  getDispValForReactSelectByOneID,
  NewRow,
} from "../../../common/utils/methods/commonMethods/utilityMethod";
import { RolePermissions } from 'appContext'
import { PermissionContext } from 'appContext'
import { pages } from '../../../common/constant'

const BuildingRow = ({
  data,
  index,
  history,
  isSaveVisible=false,
  deleteRow = () => {},
  editRow = () => {},
  pincodeData = [],
  stateData = [],
  cityData = [],
  countryData = [],
  regionData = [],
  businessData = [],
  setHeaderDataForFloor=()=>{},
  getDisplayVal= () => {}
}) => {

  const itemToBeRenderFromMaster = ["city", "state", "country", "pinCode"];
  const userPermissions = RolePermissions(useContext(PermissionContext), pages['buildingmaster']['id']);
  
  const arrList = [
    null,
    null,
    cityData,
    stateData,
    countryData,
    pincodeData,
    regionData,
    businessData,
    null,
  ];
  const propertyList = [
    null,
    null,
    "text",
    "text",
    "text",
    "text",
    null,
  ];
  const listToBeRendered = [
    "buildingName",
    "address",
    "city",
    "state",
    "country",
    "pinCode",
    "status",
  ];

  const colunmWidths =[1,2,1,1,1,1,1]

  

  const saveHeader = (dt) => {
    
    
    setHeaderDataForFloor({
      "Building Name" : dt.buildingName,
      "Building Address" : dt.address,
      "City" : getDisplayVal(dt.city,cityData)?.text ?? '',
      "State" : getDisplayVal(dt.state,stateData)?.text ?? '',
      "Country" : getDisplayVal(dt.country,countryData)?.text ?? '',
      "Pincode" : getDisplayVal(dt.pinCode,pincodeData)?.text ?? '',
      "Building Status" : dt.status ,
      "Region" : getDisplayVal(dt.region,regionData)?.label ?? '',
      "Business Area" : getDisplayVal(dt.businessArea,businessData)?.label ?? '',
      businessAreaId:  dt?.businessArea,
      regionId: dt.region,
    })
    history.push(`/admin/building/${data.id}/floors`)
  }

  return (
    <NewRow 
        data={data}
        itemToBeRenderFromMaster={itemToBeRenderFromMaster}
        propertyList={propertyList}
        listToBeRendered={listToBeRendered}
        arrList={arrList}
        colunmWidths={colunmWidths}
    >
      {/* <td className="text-center p-2 mx-1">{data?.buildingName}</td>
      <td className="text-center p-2 mx-1" colSpan={2}>
        {data?.address}
      </td>
      <td className="text-center p-2 mx-1">{data?.city}</td>
      <td className="text-center p-2 mx-1">{data?.state}</td>
      <td className="text-center p-2 mx-1">{data?.country}</td>
      <td className="text-center p-2 mx-1">{data?.pinCode}</td>

      <td className="text-center p-2 mx-1">
        {data.status === "ACTIVE" ? (
          <Button color="success" size={"sm"} disabled={true}>
            Active
          </Button>
        ) : (
          <Button color="danger" size={"sm"} disabled={true}>
            Inactive
          </Button>
        )}
      </td> */}
      <td className="text-center p-2 mx-1">
        <span style={{ textAlign: "center" }}>
          <Button
            color={"primary"}
            disabled={isSaveVisible}
            size="sm"
            type="button"
            className={" mx-1" + (isSaveVisible ? " btn-dark" : null)}
            onClick={() => saveHeader(data)}
          >
            Add/View Floor
          </Button>
        </span>
      </td>
      <td className="text-center p-2 mx-1">
        {checkPermission(userPermissions, 'U') && <Button
          color="info"
          size="sm"
          type="button"
          className={" mx-1"}
          onClick={() => {
            editRow(index);
          }}
        >
          <i className="fas fa-edit" />
        </Button>}
        {checkPermission(userPermissions, 'D') && <Button
          color="info"
          size="sm"
          type="button"
          className={" mx-1"}
          onClick={() => deleteRow(index)}
        >
          <i className="fas fa-trash" />
        </Button>}
      </td>
    </NewRow>
  );
};

export default BuildingRow;
