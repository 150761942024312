import React from "react";
import { BiSortAlt2 } from "react-icons/bi";
import NoRowOverlay from "views/pages/manage/masters/holidayMaster/NoRowOverlay.jsx";
import { fetchAllPromisedData } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "../../../common/constant";

export const getPsidApplicationIdObjFromRowId = (rowString) => {
  const rowData = JSON?.parse(rowString);
  return {
    psid: rowData?.psid,
    applicationId: rowData?.applicationId,
  };
};

export const gridComponents = {
  ColumnUnsortedIcon: () => <BiSortAlt2 />,
  NoRowsOverlay: () => <NoRowOverlay />,
};

export const primaryCheckboxStyles = {
  ".MuiCheckbox-root": {
    ":not(.Mui-disabled)": {
      color: "#00B0F5",
    },
  },
};

export const gridStyles = {
  border: "none !important",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  ".MuiDataGrid-columnHeaders": {
    // border: "none",
  },
  ".MuiDataGrid-columnHeaderTitle": {
    fontFamily: "Gilroy-Medium",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
  },
  ".MuiDataGrid-columnHeaderTitleContainer": {
    // borderBottom: "1px solid #E3E3E3",
  },
  ".MuiDataGrid-iconButtonContainer": {
    visibility: "visible",
    float: "right",
    button: {
      marginLeft: "0 !important",
    },
  },
  ".MuiDataGrid-sortIcon": {
    opacity: "inherit !important",
  },
  ...primaryCheckboxStyles,
};

export const issueStockPaginationStyle = {
  borderTop: "1px solid rgba(224, 224, 224, 1)",
  ".MuiTablePagination-selectLabel": {
    position: "absolute",
    left: "4px",
  },
  ".MuiInputBase-root": {
    position: "absolute",
    left: "100px",
  },
  ".MuiTablePagination-select": {
    background: "#5E636614",
    padding: "0px 11px",
  },
  ".MuiTablePagination-spacer": {
    flex: 1,
  },
  ".MuiTablePagination-displayedRows": {
    position: "absolute",
    left: "170px",
  },
};

export const pageDropdownStyles = {
  backgroundImage: `url(
    "data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"
  )`,
  backgroundRepeat: "no-repeat, repeat",
  backgroundPosition: "right 1rem center",
  background: "#5E636614",
  border: "none",
  height: "25px",
  padding: "0px 11px",
  color: "black",
};

export const pendingRfIdFilter = {
  sortingField: "REGISTRATION_DATE",
  batchType: "PRIMARY",
  rfidMappedApplication: false,
};

export const MESSAGE_MATERIAL_SEARCH = "No records found for your search";
export const MAXIMUM_MATERIAL_ALLOWED = 5;
export const MESSAGE_MAXIMUM_MATERIAL_ALLOWED =
  "Maximum five materials selection allowed";

export const autoCompleterRequest = {
  tenant: "PHOENIX",
  category: "MaterialMaster",
  value: "",
  oprId: "test123",
};

export const getChipBgColor = (cnt) =>
  cnt ? (cnt >= 50 ? "#BBE2CE" : "#FFEDC8") : "#F2ADA5";
export const getChipTxtColor = (cnt) =>
  cnt ? (cnt >= 50 ? "#0B442D" : "#705010") : "#B32306";

export const markBarcode = (materialIds, material, barcode, isIssued) => {
  const res = { ...materialIds };
  res[material.materialId] = materialIds?.[material.materialId]?.map((item) =>
    item?.barcode == barcode ? { ...item, isIssued: isIssued } : item
  );

  return res;
};

export const validateBarcodeFromApi = async (businessArea, barcode) => {
  const res = await fetchAllPromisedData(
    `${endpoint.getBarcodeByNumber}?barcode=${barcode}&businessAreaId=${businessArea}`,
    false,
    false
  );
  return res;
};

export const isBarcodedMaterial = (item) =>
  item?.serialNumberProfile?.toLowerCase() !== "na";

export const isGrp1MaterialSelected = (groupMaterials = []) => {
  const grp1Materials = groupMaterials?.filter(
    (item) => item["groupType"] === "GRP1"
  );
  if (grp1Materials[0])
    return grp1Materials[0]["materialNumberList"]?.length > 0;
  return false;
};

export const getGrp2Material = (groupMaterials = []) => {
  const grp2Materials = groupMaterials?.filter(
    (item) => item["groupType"] === "GRP2"
  );
  if (grp2Materials.length) return grp2Materials[0]["materialNumberList"];

  return [];
};
export const onHoldReasonDropdown = [
  {
    label: "ANTHE Student opted for RCC",
    value: "ANTHE Student opted for RCC",
  },
  { label: "Manual Dispatch Done", value: "Manual Dispatch Done" },
  { label: "Others", value: "Others" },
];

export const STUDENT_NOT_ELIGIBLE_MSG =
  "This student is not eligible for issuing any stock. Please ask the student to connect with branch manager.";
export const MATERIAL_ALREADY_ISSUED_MSG =
  "A material from the same group 2 is already issued to this student, details can be viewed in transaction history section.";
