import React, { useState } from "react";
import PageHeader from "./PageHeader";
import IssueStockForm from "./form";
import SapPeriodAndSelectSapPostingDate from "../sapPeriodComponent/SapPeriodAndSelectSapPostingDate";
import { useParams, useHistory } from "react-router-dom";
function StockConsumption() {
  const params = useParams();
  const history = useHistory();
  const [sapPostingDate, setSapPostingDate] = useState(undefined);
  return (
    <div>
      <PageHeader
        isDlp={!!params?.id}
        history={history}
        children={
          <SapPeriodAndSelectSapPostingDate
            sapPostingDate={sapPostingDate}
            setSapPostingDate={(date) => setSapPostingDate(date)}
          />
        }
      />
      <IssueStockForm
        sapPostingDate={sapPostingDate}
        isDlp={!!params?.id}
        academicType={params?.id ? params?.id.toUpperCase() : "RCC"}
      />
    </div>
  );
}

export default StockConsumption;
