import React from 'react'
import { ReactComponent as ChemistryIcon } from '../../../../../assets/img/svg/CourseChemistry.svg'
import { ReactComponent as MathematicsIcon } from '../../../../../assets/img/svg/CourseMathematics.svg'
import { ReactComponent as PhysicsIcon } from '../../../../../assets/img/svg/CoursePhysics.svg'
import { ReactComponent as BiologyIcon } from '../../../../../assets/img/svg/CourseBiology.svg'
import { ReactComponent as MentalAbilityIcon } from '../../../../../assets/img/svg/CourseMentalAbility.svg'
import { ReactComponent as GeographyIcon } from '../../../../../assets/img/svg/CourseGeography.svg'
import { ReactComponent as HistoryIcon } from '../../../../../assets/img/svg/CourseHistory.svg'
import { ReactComponent as EconomicsIcon } from '../../../../../assets/img/svg/CourseEconomics.svg'
import { ReactComponent as PoliticalScienceIcon } from '../../../../../assets/img/svg/CoursePoliticalScience.svg'
import { ReactComponent as BotanyIcon } from '../../../../../assets/img/svg/CourseBotany.svg'
import { ReactComponent as ZoologyIcon } from '../../../../../assets/img/svg/CourseZoology.svg'
import { ReactComponent as EnglishIcon } from '../../../../../assets/img/svg/CourseEnglish.svg'
import { ReactComponent as GeneralKnowledgeIcon } from '../../../../../assets/img/svg/CourseGeneralKnowledge.svg'
import { ReactComponent as SocialScienceIcon } from '../../../../../assets/img/svg/CourseSocialScience.svg'

export const courseCardHeadingStyles = {
  fontSize: '28px',
  fontWeight: 700,
  color: '#0F0F0F'
}
export const listCourseCardIconStyles = {
  width: '36px', height: '34px'
}
export const gridCourseCardIconStyles = {
  width: '52px', height: '48px'
}

export const courseIconMapping = (listCard = 'false') => ({
  "Chemistry": <ChemistryIcon style={listCard ? listCourseCardIconStyles : gridCourseCardIconStyles} />,
  "Mathematics": <MathematicsIcon style={listCard ? listCourseCardIconStyles : gridCourseCardIconStyles} />,
  "Physics": <PhysicsIcon style={listCard ? listCourseCardIconStyles : gridCourseCardIconStyles} />,
  "Biology": <BiologyIcon style={listCard ? listCourseCardIconStyles : gridCourseCardIconStyles} />,
  "Mental Ability": <MentalAbilityIcon style={listCard ? listCourseCardIconStyles : gridCourseCardIconStyles} />,
  "Geography": <GeographyIcon style={listCard ? listCourseCardIconStyles : gridCourseCardIconStyles} />,
  "History": <HistoryIcon style={listCard ? listCourseCardIconStyles : gridCourseCardIconStyles} />,
  "Economics": <EconomicsIcon style={listCard ? listCourseCardIconStyles : gridCourseCardIconStyles} />,
  "Political Science": <PoliticalScienceIcon style={listCard ? listCourseCardIconStyles : gridCourseCardIconStyles} />,
  "Botany": <BotanyIcon style={listCard ? listCourseCardIconStyles : gridCourseCardIconStyles} />,
  "Zoology": <ZoologyIcon style={listCard ? listCourseCardIconStyles : gridCourseCardIconStyles} />,
  "English": <EnglishIcon style={listCard ? listCourseCardIconStyles : gridCourseCardIconStyles} />,
  "General Knowledge": <GeneralKnowledgeIcon style={listCard ? listCourseCardIconStyles : gridCourseCardIconStyles} />,
  "Social Science": <SocialScienceIcon style={listCard ? listCourseCardIconStyles : gridCourseCardIconStyles} />,
})

export function groupBy(objectArray, property) {
  return objectArray.reduce((acc, obj) => {
     const key = obj[property];
     if (!acc[key]) {
        acc[key] = [];
     }
     // Add object to list for given key's value
     acc[key].push(obj);
     return acc;
  }, {});
}
export const MISSING_MESSAGE = 'Please add any test or fill the missing information of tests before publishing this plan.'