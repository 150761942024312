import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Filter from './Filter';
import {
  fetchListHelper,
  getApprovalPayload,
  getDropdowns,
  getSgDropdowns,
  initialFilterForm,
} from './constant';
import ALoader from '../../common/a-loader';
import ListComponent from './ListComponent';
import {
  fetchAllPostPromisedData,
  updateRequest,
} from '../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint, pages } from '../../common/constant';
import { PermissionContext, RolePermissions } from 'appContext';
import { PermisionDenied } from 'views/pages/manage/common/commonComponents';
import { getFilterCount } from '../stockManagement/mastersAndMapping/onBoardingMaterialIdSetup/constant';

const ApproveSupplementaryId = () => {
  const userPermissions = RolePermissions(
    useContext(PermissionContext),
    pages['approveSupplementaryId']['id']
  );

  const [dropdownLoader, setDropdownLoader] = useState(false);
  const [listLoader, setListLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [dropdowns, setDropdowns] = useState({});
  const [sgFilterForm, setSgFilterForm] = useState({
    region: [],
    businessArea: [],
  });
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState([]);
  const [searchInputParam, setSearchInputParam] = useState('psid');
  const [inputFilterPayload, setInputFilterPayload] = useState('');
  const [filterPayload, setFilterPayload] = useState(initialFilterForm);
  const [filterCount, setFilterCount] = useState(0);

  const fetchDropdowns = async cb => {
    setDropdownLoader(true);
    const res = await getSgDropdowns();
    const otherDropdowns = await getDropdowns();
    setDropdowns(otherDropdowns);
    setSgFilterForm(res);
    setDropdownLoader(false);
    cb(0, res);
  };

  const fetchList = async (
    page = 0,
    sgFilter = sgFilterForm,
    reqBody = { [searchInputParam]: inputFilterPayload, ...filterPayload }
  ) => {
    setListLoader(true);
    const res = await fetchListHelper(page, sgFilter, reqBody);
    setRows(res?.rows);
    setTotalRecords(res?.totalRecords);
    setListLoader(false);
  };

  const updateSgFilterHandler = async updatedSgForm => {
    setSgFilterForm(updatedSgForm);
    setPage(0);
    fetchList(0, updatedSgForm);
  };

  const onPageChange = newPage => {
    setPage(newPage);
    fetchList(newPage);
  };

  const searchInputHandler = userInput => {
    const reqBody = { [searchInputParam]: userInput, ...filterPayload };
    setInputFilterPayload(userInput);
    setPage(0);
    fetchList(0, undefined, reqBody);
  };

  const applyFilter = filterForm => {
    setFilterPayload(filterForm);
    setFilterCount(getFilterCount(filterForm));
    const reqBody = updateRequest({
      ...{ [searchInputParam]: inputFilterPayload },
      ...filterForm,
    });
    setPage(0);
    fetchList(0, undefined, reqBody);
  };

  const handleApproval = async (
    students,
    setSelectionModel = () => {},
    status = 'APPROVED'
  ) => {
    setLoader(true);
    const reqBody = {
      supplementaryIdApprovalRequestDtoList: Array.isArray(students)
        ? students?.map(item => getApprovalPayload(item, status))
        : [getApprovalPayload(students, status)],
    };
    const res = await fetchAllPostPromisedData(
      endpoint?.studentSupplementary?.approveSupplementaryId,
      reqBody,
      'post'
    );
    if (res?.code === 200) {
      setPage(0);
      fetchList(0);
      setSelectionModel([]);
    }
    setLoader(false);
  };

  useEffect(() => {
    fetchDropdowns(fetchList);
  }, []);

  return userPermissions ? (
    <div
      className={`min-page-height remove-container-top-space ${styles['root-container']}`}
    >
      {(dropdownLoader || listLoader || loader) && (
        <ALoader position={'fixed'} />
      )}
      <div>Approve supplementary ID</div>
      <div className={`${styles['grid-container']}`}>
        <Filter
          sgFilterForm={sgFilterForm}
          totalRecords={totalRecords}
          updateSgFilterHandler={updateSgFilterHandler}
          searchInputParam={searchInputParam}
          setSearchInputParam={setSearchInputParam}
          searchInputHandler={searchInputHandler}
          filterCount={filterCount}
          filterPayload={filterPayload}
          applyFilter={applyFilter}
          dropdowns={dropdowns}
        />
        <ListComponent
          rows={rows}
          page={page}
          onPageChange={onPageChange}
          totalRecords={totalRecords}
          handleApproval={handleApproval}
          userPermissions={userPermissions}
        />
      </div>
    </div>
  ) : (
    <PermisionDenied />
  );
};

export default ApproveSupplementaryId;
