import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Input,
  Container,
  Button,
  Table,
  Row,
  Form,
  Col,
  Label,
  FormGroup,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import {
  ExternalOrgSearchListHeader,
  OrganizationTableThrow,
} from "../../../common/commonComponents/masterRow/masterRow";
import Loader from "react-loader-spinner";
import Select from "react-select";
import {
  masterServiceBaseUrl,
  failureToast,
  getAPI,
  deleteAPI,
  successToast,
} from "services/http";
var _ = require("lodash");

const OrganizationTable = (props) => {
  const {
    setclassArray,
    classArray,
    fetchClasses,
    setIsUpdated,
    isUpdated,
    state,
  } = props;
  const [externalOrgId, setExternalOrgId] = useState();
  const [associatedId, setAssociatedId] = useState();
  const [organizationTypeDetails, setOrganizationTypeDetails] = useState();
  const [apiLoader, setApiLoader] = useState(false);
  const [organizationType, setOrganizationType] = useState([]);

  useEffect(() => {
    getAPI(masterServiceBaseUrl + "/externalOrgType/getAllExternalOrgType", (data) => {
      let organizationTypeTemp = [];
      data.data.map((item) => {
        organizationTypeTemp.push({
          label: item.externalOrgTypeDispValue,
          value: item.id,
        });
      });
      setOrganizationType(organizationTypeTemp);
    });
  }, []);

  const searchData = async (
    externalOrganizationId,
    associatedBuId,
    organizationType
  ) => {
    try {
      // console.log(
      //   "Search Data",
      //   externalOrganizationId,
      //   associatedBuId,
      //   organizationType
      // );
      let pattern = /^[0-9]+$/;

      if (externalOrganizationId || associatedBuId || organizationType) {
        if (
          !pattern.test(associatedBuId) &&
          !(associatedBuId === "" || associatedBuId === undefined)
        )
          return failureToast("Associated Id must be number");
        setApiLoader(true);
        await fetchClasses(
          masterServiceBaseUrl +
            `/externalOrganizationId/search?externalOrganizationId=${
              externalOrganizationId ? externalOrganizationId : ""
            }&associatedBuId=${
              associatedBuId ? associatedBuId : ""
            }&organizationType=${organizationType ? organizationType : ""}`
        );
        setApiLoader(false);
      } else {
        setclassArray("");
        setApiLoader(false);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const findIndexToRemove = (id) => {
    try {
      let a = state.findIndex((item) => item.id === id);
      state.splice(a, 1);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const deleteOrganizationForm = (id, index) => {
    try {
      // console.log("before array", index);

      if (
        window.confirm(
          "Are you sure you want to delete this External Organization ID ?"
        )
      ) {
        setApiLoader(true);
        deleteAPI(
          masterServiceBaseUrl +
            `/externalOrganizationId/deleteExternalOrganizationId/${id}`,
          (data) => {
            successToast(data["message"]);
            let n = classArray;
            n.splice(index, 1);
            setclassArray(n);
            setIsUpdated(!isUpdated);
            findIndexToRemove(id);
            setApiLoader(false);
          },
          (data) => {
            failureToast(data["message"]);
            setApiLoader(false);
          },
          "Delete"
        );
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Button
                 data-testid='addNewExternalOrganization'
                  color="info"
                  size="sm"
                  type="button"
                  className="floatRight mx-1"
                  onClick={() => props.history.push("/admin/externalOrg/new")}
                >
                  Add New External Org
                </Button>
              </CardHeader>
              <Form>
                <Row className="p-3">
                  <Col sm={12}>
                    <FormGroup row>
                      <Col sm={4} md={3} lg={3} xl={2}>
                        <Label
                          for="organisationId"
                          className="form-control-label"
                        >
                          External Org ID
                        </Label>
                      </Col>
                      <Col sm={6} md={6} xl={4}>
                        <input
                          type="text"
                          maxLength={12}
                          placeholder="External Org ID"
                          className="form-control addresslocationInput"
                          onChange={(e) => {
                            let externalOrganizationId = e.target.value;
                            setExternalOrgId(externalOrganizationId);
                            searchData(
                              externalOrganizationId,
                              associatedId,
                              organizationTypeDetails
                            );
                          }}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="p-3">
                  <Col sm={12}>
                    <FormGroup row>
                      <Col sm={4} md={3} lg={3} xl={2}>
                        <Label
                          for="associatedBU"
                          className="form-control-label"
                        >
                          Associated BU
                        </Label>
                      </Col>
                      <Col sm={6} md={6} xl={4}>
                        <input
                          maxLength={50}
                          onChange={(e) => {
                            let associatedBuId = e.target.value;
                            setAssociatedId(associatedBuId);
                            searchData(
                              externalOrgId,
                              associatedBuId,
                              organizationTypeDetails
                            );
                          }}
                          placeholder="Associated BU"
                          className="form-control addresslocationInput"
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="p-3">
                  <Col sm={12}>
                    <FormGroup row>
                      <Col sm={4} md={3} lg={3} xl={2}>
                        <Label
                          for="organisationType"
                          className="form-control-label"
                        >
                          Organisation Type
                        </Label>
                      </Col>
                      <Col sm={6} md={6} xl={4}>
                        <Select
                          placeholder="Select Organization Type"
                          options={organizationType}
                          onChange={(e) => {
                            let organizationType = e.value;
                            searchData(
                              externalOrgId,
                              associatedId,
                              organizationType
                            );
                            setOrganizationTypeDetails(organizationType);
                          }}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
              {apiLoader ? (
                <div
                  className="mx-auto text-center py-5 my-5"
                  style={{ height: "400px" }}
                >
                  <Loader type="Rings" color="#00BFFF" height={70} width={70} />
                </div>
              ) : (
                <div id="questionTable" className="table-responsive">
                  {classArray.length > 0 ? (
                    <Table className="align-items-center table-flush tableLayout">
                      <ExternalOrgSearchListHeader />
                      <tbody className="list">
                        {classArray.map((item, index) => (
                          <OrganizationTableThrow
                            organizationType={organizationType}
                            index={index}
                            deleteOrganizationForm={deleteOrganizationForm}
                            key={index + "-class"}
                            el={item}
                          />
                        ))}
                      </tbody>
                    </Table>
                  ) : null}
                </div>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default withRouter(OrganizationTable);
