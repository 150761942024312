import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Col, Button, Input } from "reactstrap";
import CustomLoader from "views/pages/manage/common/commonComponents/Loader/loader";
import CustomDatePicker from 'views/pages/manage/common/commonComponents/CustomDatePicker/index';
import { CustomContainer } from "views/pages/manage/common/commonComponents";
import { CustomCard } from "views/pages/manage/common/commonComponents";
import { fetchDetails, getRequiredSign,
} from "../../common/utils/methods/commonMethods/utilityMethod";
import { failureToast } from "services/http";
import { endpoint } from "../../common/constant";
import moment from 'moment';

const Cardheader = (props) => {
  return (
    <>
      <h3 className="mb-0 ">EPMR</h3>
      <Button
        color="info"
        size="sm"
        type="button"
        className={"floatRight"}
        onClick={()=>props.setNewEpmr(true)}
      >
        Add
      </Button>
    </>
  );
};

const Header = (props) => {
  const {
    headerValues,
    setHeaderValues = () => {},
    resetHeader = () => {},
    setNewEpmr = () => {}
  } = props;
  const [groupCodeData, setGroupCode] = useState(null);
  const [companyCodeData, setCompanyCodeData] = useState(null);
  const [regionData, setRegionData] = useState(null);
  const [businessData, setBusinessData] = useState(null);

  useEffect(() => {
    fetchDetails(
      endpoint.groupCode.getAllActive,
      setGroupCode,
      "groupCode",
      "select"
    );
    fetchDetails(
      endpoint.region.getAllActive,
      setRegionData,
      "region",
      "select"
    );
  }, []);

  const search = () => {
    if (!headerValues?.businessGroup?.value) {
      failureToast("Please Select Business Group");
      return;
    }
    if (!headerValues?.companyCode?.value) {
      failureToast("Please Select Comapny Code");
      return;
    }
    if (!headerValues?.region?.value) {
      failureToast("Please Select Region");
      return;
    }
    if (!headerValues?.businessArea?.value) {
      failureToast("Please Select Business Area");
      return;
    }

    //searchAttendanceMachine();
  };

  return (
    <CustomContainer>
      <CustomCard HeaderElement={<Cardheader setNewEpmr={setNewEpmr}/>}>
        {!groupCodeData || !regionData ? (
          <div
            className="mx-auto text-center py-5 my-5"
            style={{ height: "100vh" }}
          >
            <CustomLoader apiLoader={!groupCodeData || !regionData} />
          </div>
        ) : (
          <>
            <div className="form-row mb-3 p-4">
              <Col className="mb-3" md="3">
                <label className="form-control-label">
                  Business Group{getRequiredSign()}
                </label>
                <Select
                  value={headerValues.businessGroup}
                  id="building_header_business_grp"
                  options={groupCodeData}
                  //isDisabled={isView}
                  placeholder="Select Business Group"
                  onChange={(sData) => {
                    setHeaderValues({
                      ...headerValues,
                      businessGroup: sData,
                      companyCode: null,
                      businessArea: null,
                    });
                    setBusinessData(null);
                    setCompanyCodeData(null);
                    //setAttendanceMachineData(null);
                    fetchDetails(
                      `${endpoint.companyCode.getAllActiveByGroupCode}/${sData.value}`,
                      setCompanyCodeData,
                      "companyCode",
                      "select"
                    );
                  }}
                />
              </Col>
              <Col className="mb-3" md="3">
                <label className="form-control-label">
                  Company Code{getRequiredSign()}
                </label>
                <Select
                  value={headerValues.companyCode}
                  id="building_header_company_code"
                  options={companyCodeData}
                  //isDisabled={isView}
                  placeholder={"Select Company Code"}
                  onChange={(sData) => {
                    setHeaderValues({ ...headerValues, companyCode: sData });
                    setBusinessData(null);
                    //setAttendanceMachineData(null);
                    headerValues?.businessGroup?.value &&
                      fetchDetails(
                        `${endpoint.businessArea.getAllActiveByGroupCodeAndComapnyCode}?companyCode=${sData.value}&groupCode=${headerValues?.businessGroup?.value}`,
                        setBusinessData,
                        "businessArea",
                        "select"
                      );
                  }}
                  isLoading={headerValues.businessGroup && !companyCodeData}
                />
              </Col>
              <Col className="mb-3" md="3">
                <label className="form-control-label">
                  Region{getRequiredSign()}
                </label>
                <Select
                  value={headerValues.region}
                  id="building_header_region"
                  options={regionData}
                  //isDisabled={isView}
                  placeholder="Select Region"
                  onChange={(sData) => {
                    setHeaderValues({ ...headerValues, region: sData });
                    //setAttendanceMachineData(null);
                  }}
                />
              </Col>
              <Col className="mb-3" md="3">
                <label className="form-control-label">
                  Business Area{getRequiredSign()}
                </label>
                <Select
                  value={headerValues.businessArea}
                  id="building_header_business_area"
                  options={businessData}
                  //isDisabled={isView}
                  placeholder={"Select Business Area"}
                  onChange={(sData) => {
                    setHeaderValues({ ...headerValues, businessArea: sData });
                    //setAttendanceMachineData(null);
                  }}
                  isLoading={
                    headerValues?.businessGrp?.value &&
                    headerValues?.companyCode?.value &&
                    !businessData
                  }
                />
              </Col>
            </div>
            <div className="form-row mb-3 pl-4 pr-4">
              <Col md="3">
                <label className="form-control-label">
                  EPMR Status{getRequiredSign()}
                </label>
                <Input
                  value={headerValues.epmrStatus}
                  placeholder={`EPMR Status`}
                  type="text"
                  maxLength="10"
                  onChange={(e) =>
                    setHeaderValues({
                      ...headerValues,
                      epmrStatus: e.target.value,
                    })
                  }
                  style={{ height: "39px" }}
                />
              </Col>
              <Col md="3">
                <label className="form-control-label">
                  EPMR Number
                </label>
                <Input
                  value={headerValues.epmrNumber}
                  placeholder={`EPMR Number`}
                  type="text"
                  maxLength="10"
                  onChange={(e) =>
                    setHeaderValues({
                      ...headerValues,
                      epmrNumber: e.target.value,
                    })
                  }
                  style={{ height: "39px" }}
                />
              </Col>
              <Col md="3">
                <label className="form-control-label">
                  From EPMR Date{getRequiredSign()}
                </label>
                <CustomDatePicker 
                  value={headerValues.fromDate? moment(
                  headerValues.fromDate
                  ).toDate():''}
                  index={0}
                  handler={(index,date,keyName) => {
                    setHeaderValues({
                      ...headerValues,
                      fromDate: date,
                    })
                  }}
                  keyName='fromDate'
                  minDate={'1950-01-01'}
                  maxDate={'2099-12-31'}
                  rangeFrom={1950}
                  rangeTo={2099}
                  placeholder="From EPMR Date"
                />
                {/* <Input
                  value={headerValues.fromDate}
                  placeholder={`From EPMR Date`}
                  type="date"
                  maxLength="10"
                  onChange={(e) =>
                    setHeaderValues({
                      ...headerValues,
                      fromDate: e.target.value,
                    })
                  }
                  style={{ height: "39px" }}
                /> */}
              </Col>
              <Col md="3">
                <label className="form-control-label">
                  To EPMR Date{getRequiredSign()}
                </label>
                <CustomDatePicker 
                  value={headerValues.toDate? moment(
                  headerValues.toDate
                  ).toDate():''}
                  index={0}
                  handler={(index,date,keyName) => {
                    setHeaderValues({
                      ...headerValues,
                      toDate: date,
                    })
                  }}
                  keyName='toDate'
                  minDate={'1950-01-01'}
                  maxDate={'2099-12-31'}
                  rangeFrom={1950}
                  rangeTo={2099}
                  placeholder="From EPMR Date"
                />
                {/* <Input
                  value={headerValues.toDate}
                  placeholder={`To EPMR Date`}
                  type="date"
                  maxLength="10"
                  onChange={(e) =>
                    setHeaderValues({ ...headerValues, toDate: e.target.value })
                  }
                  style={{ height: "39px" }}
                /> */}
              </Col>
            </div>
            <div className="form-row justify-content-between mb-3 pl-4 pr-4 pt-4">
              <Button
                color="info"
                size="sm"
                type="button"
                //onClick={resetHeader}
              >
                Reset
              </Button>
              <Button
                color="info"
                size="sm"
                type="button"
                className="ml-3"
                //onClick={search}
              >
                Search
              </Button>
            </div>
          </>
        )}
      </CustomCard>
    </CustomContainer>
  );
};

export default Header;
