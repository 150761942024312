import React, { useContext, useEffect, useState } from 'react';
import closeIcon from 'assets/img/svg/closeSquare.svg';
import fileIcon from 'assets/img/svg/fileIcon.svg';
import pdfIcon from 'assets/img/svg/pdfIcon.svg';
import moment from 'moment';
import DownloadIcon from 'assets/img/svg/downloadIcon.svg';
import { endpoint } from '../../common/constant';
import { UserDetails } from "../../../../../appContext";
import CustomLoader from '../../common/commonComponents/Loader/loader';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import { successToast } from '../../common/utils/methods/toasterFunctions/function';
import { Box, Checkbox, Dialog, Tab, Tabs, Typography } from '@mui/material';
import CustomButton from 'components/CustomButton';
import { getFileUrl } from './common';
import { constants } from "../../common/constant/constants/index";
import { useParams } from 'react-router';
import DocPreview from './docPreview';

const VerificationPopup = ({ openPopOver, handleClose, userPermissions, documentAttached = false, applicationId = null, applicationQuotationDetailsId=null, overrideParams=false }) => {
    const [files, setFiles] = useState([]);
    const [downloading, setDownloading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [processInsID, setProcessInsID] = useState(null);

    const params = useParams();

    const userDetails = useContext(UserDetails);

    const fetchDocuments = async () => {
        setLoading(true);
        try {
            const response = await fetchAllPromisedData(endpoint.studentChargesConcessionDetailsConcession.getByApplicationId+ `/${applicationId}`);
            if (response) {
                const _docs = response.reduce((acc, curr) => {
                    return [...acc, ...curr.concessionDocuments || []]
                }, []);
                setProcessInsID(_docs?.[0]?.processInstanceId || null);
                setFiles(_docs);
                setLoading(false);
            } else {
                setFiles([]);
                setLoading(false);
            }
        } catch (err) {
            setFiles([]);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (documentAttached && applicationId && openPopOver) {
            fetchDocuments();
        } else {
            setFiles([]);
        }
    }, [applicationId, documentAttached, openPopOver]);
    

    const getDownloadKey = async (index) => {
        setDownloading(true)
        const response = await fetchAllPromisedData(`${endpoint.fetchS3Url.getDownloadKey}/${files?.[index]?.processInstanceId}`, true);
        if (response.code === 200) {
            const downloaded = await getFileUrl(response?.data?.keyValue);
            setDownloading(false);
        } else {
            setDownloading(false);
        }
    }

    const handleCheck = (file) => {
        setFiles(docs => {
            return docs.map(doc => doc.id === file.id ? 
                ({
                    ...doc,
                    isVerified: !file.isVerified
                }) : 
                doc
            )
        })
    }

    const onVerify = async (e) => {
        const request = files.map(file => ({
            ...file,
            isVerified: true,
            verifiedBy: userDetails ? `${userDetails.firstName} ${userDetails.lastName}` : "Prateek",
            verifiedOn: new Date()
        }))
        
        setLoading(true);
        try {
            const response = await fetchAllPostPromisedData(`${endpoint.verifyDocuments}?id=${applicationQuotationDetailsId}`, {}, 'post');
            if (response?.code === 200) {
                setLoading(false);
                handleClose(e, true);
                successToast('Documents verified successfully');
            } else {
            }
        } catch (err) {

        }
    }

    const isStagePending = params.stage === constants.APPROVAL_STAGE.PENDING;
    const docsVerified = files.reduce((acc, curr) => acc && curr.isDocumentVerified, true);

    return (
        <Dialog open={openPopOver} onClose={handleClose} maxWidth={false}>
            <Box className='concession-approval-dialog-content' width={'88.2vw'}>
                {loading ? <CustomLoader /> : <>
                    <Box className='approval-dialog-header'>
                        <Typography variant='h6'>{isStagePending ? 'Document verification' : 'Documents'}</Typography>
                        <div onClick={handleClose}><img src={closeIcon} /></div>
                    </Box>
                    {files.length>0 && <Box>
                        <Tabs value={processInsID} onChange={(e, val) => setProcessInsID(val)} variant="scrollable" scrollButtons="auto">
                            {files.map((file) => (<Tab key={file.processInstanceId} label={file.fileName} value={file.processInstanceId} />))}
                        </Tabs>
                        <DocPreview processInsID={processInsID} />
                    </Box>}
                    {((isStagePending || overrideParams) && !docsVerified) && <CustomButton
                        className='approval-dialog-action-button w-25 mt-3'
                        type={'C'}
                        content={'Document review done'}
                        permissionType={'C,A'}
                        permissionSet={userPermissions}
                        onClick={e => onVerify(e)}
                    />}
                </>}
            </Box>
        </Dialog>
    )
}

export default VerificationPopup;