import React, { useState } from "react";
import { useGetBatchList } from "../../issuanceIDcard/customhooks";
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";
import { isFormFieldEmpty } from "../../../common/utils/methods/commonMethods/utilityMethod";
import CustomDatePickerV2 from "../../../common/commonComponents/CustomDatePicker-V2";
import { GrPowerReset } from "react-icons/gr";

import { selectStyles } from "../stockReturn/constant";
import AInput from "../../../common/formFeilds/customInput/AInput";
import AButton from "../../../common/form-fields-mui/AButton";
import { InputLabelStyle } from "../../../common/form-fields-mui/ASelect";
import { InputLabel, Tooltip } from "@mui/material";
import moment from "moment";
import {
  reasonCodeDropdown,
  transactionTypeDropdown,
  statusDropdown,
} from "../stockConsumption/form/helper";
import { useGetCourseIdsForBUAndTerm } from "../customhooks";
import { lastDays } from "./ReturnTabItem";

const styleFlexItem = { flex: "1 1 0" };
export const inputHeight = "41px";

const SearchByOtherDetailsForm = ({
  dropdown,
  form,
  formHandler,
  viewDetailsHandler,
  batchDropdown,
  isConsumptionReturn = false,
  isHideReason = false,
  onResetClearData = () => {},
  academicType = "RCC",
  forReversal = false,
}) => {
  const resetHandler = () => {
    formHandler("", "businessArea");
    formHandler("", "batchId");
    formHandler("", "courseId");
    formHandler("", "term");
    formHandler("", "transactionType");
    formHandler("", "reason");
    formHandler("", "psid");
    formHandler(lastDays(), "issuanceDateFrom");
    formHandler(moment(new Date()).endOf("day"), "issuanceDateTo");
    onResetClearData();
  };

  const [isShowError, setIsShowError] = useState(false);
  const [courseIdDropdown, isLoading] = useGetCourseIdsForBUAndTerm(form);
  return (
    <div className="mt-3">
      <div className="d-flex align-items-end" style={{ gap: "10px" }}>
        <div className="" style={styleFlexItem}>
          <AAutoComplete
            label="Business area*"
            placeholder="Select"
            isMulti={false}
            items={dropdown?.businessAreas || dropdown?.dlpActiveBusinessArea}
            currentValue={form?.businessArea}
            handleChange={(value) => {
              formHandler(value, "businessArea");
              formHandler("", "batchId");
              formHandler("", "courseId");
            }}
            height={inputHeight}
            style={selectStyles}
          />
        </div>
        {isConsumptionReturn && (
          <div className="" style={styleFlexItem}>
            <AAutoComplete
              label={
                academicType === "RCC"
                  ? "Transaction Type"
                  : "Transaction Type*"
              }
              placeholder="Select"
              isMulti={false}
              items={transactionTypeDropdown}
              currentValue={form?.transactionType}
              handleChange={(value) => {
                formHandler(value, "transactionType");
              }}
              style={selectStyles}
              height={inputHeight}
            />
          </div>
        )}

        {isConsumptionReturn && isHideReason && (
          <div className="" style={styleFlexItem}>
            <AAutoComplete
              label="Reason code"
              placeholder="Select"
              isMulti={false}
              items={reasonCodeDropdown}
              currentValue={form?.reason}
              handleChange={(value) => {
                formHandler(value, "reason");
              }}
              style={selectStyles}
              height={inputHeight}
            />
          </div>
        )}
        {isConsumptionReturn && !isHideReason && (
          <div className="" style={styleFlexItem}>
            <AAutoComplete
              label="Status"
              placeholder="Select"
              isMulti={false}
              items={statusDropdown}
              currentValue={form?.status}
              handleChange={(value) => {
                formHandler(value, "status");
              }}
              style={selectStyles}
              height={inputHeight}
            />
          </div>
        )}
        {!isConsumptionReturn && (
          <div className="" style={styleFlexItem}>
            <AAutoComplete
              label="Term*"
              placeholder="Select"
              isMulti={false}
              items={dropdown?.terms || dropdown?.term}
              currentValue={form?.term}
              handleChange={(value) => {
                formHandler(value, "term");
                formHandler("", "batchId");
                formHandler("", "courseId");
              }}
              style={selectStyles}
              height={inputHeight}
            />
          </div>
        )}
        {!isConsumptionReturn && (
          <div className="" style={styleFlexItem}>
            <AAutoComplete
              label="Course ID"
              placeholder="Select"
              isMulti={false}
              items={courseIdDropdown || []}
              currentValue={form?.courseId}
              handleChange={(value) => {
                formHandler(value, "courseId");
                formHandler("", "batchId");
              }}
              height={inputHeight}
              style={selectStyles}
            />
          </div>
        )}
        {academicType === "RCC" && !isConsumptionReturn && (
          <div className="" style={{ ...styleFlexItem, minWidth: "10rem" }}>
            <AAutoComplete
              label="Batch"
              placeholder="Select"
              isMulti={false}
              items={batchDropdown}
              currentValue={form?.batchId}
              handleChange={(value) => {
                formHandler(value, "batchId");
              }}
              isDisabled={
                isFormFieldEmpty(form?.businessArea) ||
                isFormFieldEmpty(form?.term) ||
                isFormFieldEmpty(form?.courseId)
              }
              height={inputHeight}
              style={selectStyles}
            />
          </div>
        )}
        {forReversal && (
          <div className="" style={styleFlexItem}>
            <AAutoComplete
              label="Status"
              placeholder="Select"
              isMulti={false}
              items={statusDropdown}
              currentValue={form?.status}
              handleChange={(value) => {
                formHandler(value, "status");
              }}
              style={selectStyles}
              height={inputHeight}
            />
          </div>
        )}
        {/* </div> */}
        {/* <div className="d-flex" style={{ gap: "10px" }}> */}
        {!isConsumptionReturn && !forReversal && (
          <div className="" style={styleFlexItem}>
            <AInput
              label="PSID"
              style={{ height: inputHeight, borderRadius: "4px" }}
              placeholder="Enter"
              value={form?.psid}
              maxLength={11}
              handler={(value) => {
                formHandler(value, "psid");
                value?.length && value?.length < 11
                  ? setIsShowError(true)
                  : setIsShowError(false);
              }}
            />
            {isShowError && (
              <span className="tiny-semi-bold color-red-40 position-absolute top-100">
                PSID should comprise of 11 digits only
              </span>
            )}
          </div>
        )}
        <div className="" style={styleFlexItem}>
          <InputLabel sx={InputLabelStyle}>
            {forReversal ? "Reversal date" : "Transaction date from"}
          </InputLabel>
          <CustomDatePickerV2
            value={form?.issuanceDateFrom}
            handler={(i, date) => {
              formHandler(date, "issuanceDateFrom");
              formHandler("", "issuanceDateTo");
            }}
            maxDate={new Date()}
          />
        </div>
        <div className="" style={{ ...styleFlexItem, maxWidth: "8rem" }}>
          <InputLabel sx={InputLabelStyle}>
            {forReversal ? "" : "Transaction date to"}
          </InputLabel>
          <CustomDatePickerV2
            value={form?.issuanceDateTo}
            handler={(i, date) =>
              formHandler(moment(date).endOf("day"), "issuanceDateTo")
            }
            minDate={form?.issuanceDateFrom}
            maxDate={new Date()}
          />
        </div>
      </div>
      <div className="" style={{ maxWidth: "180px", marginTop: "1.8rem" }}>
        <div
          className="d-flex align-items-center"
          style={{ marginTop: "4px", minWidth: "115px" }}
        >
          <AButton
            className="button_remove_left_margin"
            variant="primary_filled"
            disabled={
              !isConsumptionReturn
                ? !((form?.businessArea && form?.term) || form?.transactionType)
                : !(
                    form?.businessArea &&
                    (academicType === "RCC" || form?.transactionType)
                  )
            }
            onClick={viewDetailsHandler}
            updatedStyle={{ fontSize: "12px" }}
          >
            {isConsumptionReturn ? "Search transaction" : "View details"}
          </AButton>
          <span
            className="ml-2"
            style={{
              // position: "absolute",
              // top: "53%",
              // right: "-15%",
              minWidth: "17px",
              flex: 0.1,
              cursor: "pointer",
            }}
          >
            <Tooltip title="Reset Details">
              <span>
                <GrPowerReset onClick={resetHandler} />
              </span>
            </Tooltip>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SearchByOtherDetailsForm;
