import React, { useEffect, useState } from 'react'
import AAutoComplete from 'views/pages/manage/common/form-fields-mui/AAutoComplete'
import AButton from 'views/pages/manage/common/form-fields-mui/AButton'
import AInput from 'views/pages/manage/common/formFeilds/customInput/AInput'
import styles from '../styles.module.scss'
import { fetchAllPromisedData } from '../../../common/utils/methods/commonMethods/utilityMethod'
import { endpoint } from '../../../common/constant'
import ALoader from 'views/pages/manage/common/a-loader'
import { Tooltip } from '@mui/material'
import { ReactComponent as Info } from 'assets/img/svg/fi_info.svg';

const formInputStyles = { height: '52px', fontSize: '16px', lineHeight: '19.36px' }

const CreateForm = ({ formData, formHandler, regionData, businessAreaData, fetchBusinessAreas, setOpenForm }) => {

  const [BALoading, setBALoading] = useState(false);
  const fetchBA = async (regions) => {
    setBALoading(true);
    await fetchBusinessAreas(regions);
    setBALoading(false);
  }

  useEffect(() => {
    formHandler(businessAreaData, 'businessAreas');
  }, [businessAreaData])

  const [loader, setLoader] = useState(false);
  const validateDaysPlanName = async () => {
    setLoader(true);
    const res = await fetchAllPromisedData(`${endpoint?.daysPlan?.validateDaysPlanName}/${formData?.daysPlanName}`, true)
    if (res?.code === 200) {
      setOpenForm('LIST')
    }
    setLoader(false);
  }

  return (
    <div className={`${styles?.create_form_card}`}>
      {loader && <ALoader position='fixed' />}
      <div className='heading-2' style={{ textAlign: 'center', marginBottom: '40px' }}>
        Create days plan
      </div>
      <div>
        <div className={`${styles?.form_input_wrapper}`}>
          <AInput
            // label="Name of the days plan"
            label={(
              <div className='d-flex align-items-center'>
                <span>Name of the days plan</span>
                <Tooltip placement='top' title={`Days plan name can contain only '(', ')', '.', '-', '_' special characters `}><Info className='ml-2' /></Tooltip>
              </div>
            )}
            value={formData?.daysPlanName}
            handler={(value) => formHandler(value.replace(/[^0-9a-zA-Z.\-\)\(_ ]/g, ""), 'daysPlanName')}
            style={formInputStyles}
          />
        </div>
        <div className={`${styles?.form_input_wrapper}`}>
          <AAutoComplete
            label="Region"
            currentValue={formData?.regions}
            items={regionData}
            handleChange={(value) => {
              formHandler(value, 'regions');
              formHandler([], 'businessAreas');
              fetchBA(value);
            }}
            isShowCount={true}
            height='52px'
            fontSize='16px'
          />
        </div>
        <div className={`${styles?.form_input_wrapper}`}>
          <AAutoComplete
            label="Business area"
            currentValue={formData?.businessAreas}
            items={businessAreaData}
            handleChange={(value) => formHandler(value, 'businessAreas')}
            isShowCount={true}
            isLoading={BALoading}
            height='52px'
            fontSize='16px'
          />
        </div>

      </div>
      <AButton
        className='button_remove_left_margin'
        variant='primary_filled'
        updatedStyle={{ width: '100%', marginTop: 'auto' }}
        disabled={(loader || !formData?.daysPlanName || !formData?.regions?.length || !formData?.businessAreas?.length)}
        onClick={() => validateDaysPlanName()}
      >
        Continue
      </AButton>
    </div>
  )
}

export default CreateForm