import { Card, CardHeader, Button, Container, Row, Col } from "reactstrap";
import React, { useState, useEffect } from "react";
import ContactHistoryForm from "./contactHistoryForm";
import {
  putpost,
  getAPI,
  masterServiceBaseUrl,
} from "services/http";
import { Accordion } from "../../../common/commonComponents/Accordion/accordion";
import { putpost2 } from "services/http";


import { failureToast, successToast } from "../../../common/utils/methods/toasterFunctions/function";
import { PermisionDenied } from "../../../common/commonComponents";
import CustomButton from "components/CustomButton";

const ContactHistory = (props) => {
  const {
    action,
    userPermissions
  } = props;

  const [isVisible, setIsVisible] = useState(true);
  const [isFetching, setISFetching] = useState(false);
  let [contactArr, setContactArr] = useState([]);
  const [isEditVisible, setEditVisible] = useState(false);
  const [jobTitleData, setJobTitleData] = useState(null);
  const [departmentTypeData, setDepartmentTypeData] = useState(null);
  const [canReset, setCanResst] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const getAllContactHistory = (bId) => {
    setISFetching(true);
    getAPI(
      `${masterServiceBaseUrl}/contactHistory/getContactHistoryByBusinessAreaId/${bId}`,
      (data) => {
        //console.log(data)
        setContactArr(data.data);
        setISFetching(false);
      },
      (data) => {
        failureToast(data["message"]);
        setISFetching(false);
      }
    );
  };

  const addContact = (newData) => {
    //console.log('Values from the db',newData)
    newData["businessAreaId"] = props.formId;
    const obj = {
      ...newData,
      status: newData.status.value,
      departmentTypeId: newData.departmentTypeId.value,
      jobTitleId: newData.jobTitleId.value,
    };
    setISFetching(true);
    putpost(
      `${masterServiceBaseUrl}/contactHistory/createContactHistory`,
      (data) => {
        successToast(data["message"]);
        obj["id"] = data.data.id;
        setContactArr([...contactArr, obj]);
        setCanResst(true);
        setIsVisible(false);
        setISFetching(false);
      },
      (data) => {
        failureToast(data["message"]);
        setISFetching(false);
      },
      obj,
      "post"
    );
  };

  const editContact = (data) => {
    const obj = {
      ...data,
      status: data.status.value,
      departmentTypeId: data.departmentTypeId.value,
      jobTitleId: data.jobTitleId.value,
    };
    setISFetching(true);
    putpost(
      `${masterServiceBaseUrl}/contactHistory/updateContactHistory/${data.id}`,
      (data) => {
        successToast(data["message"]);
        //console.log("Datat from the server",data)
        setEditVisible(false);
        setIsDisabled(true);
        getAllContactHistory(props.formId);

      },
      (data) => {
        failureToast(data["message"]);
        setISFetching(false);
      },
      obj,
      "put"
    );
  };

  const deleteContact = (id) => {
    if (
      window.confirm("Are you sure you want to delete this Contact History?")
    ) {
      setISFetching(true);
      putpost2(
        `${masterServiceBaseUrl}/contactHistory/deleteContactHistory/${id}`,
        (data) => {
          successToast(data["message"]);
          getAllContactHistory(props.formId);
        },
        (data) => {
          failureToast(data["message"]);
          setISFetching(false);
        },
        "Delete"
      );
    }
  };

  const contractHistoryList = contactArr && contactArr.map((item, i) => {
    //console.log("Item cal",item)
    return (
      <Accordion title={item && item.contactId}
        style={{ marginTop: '20px' }}
        removePadding={action === 'new' ? false : true}
      >
        <ContactHistoryForm
          isDisabled={action === 'edit' || action === 'view' ? isDisabled : true}
          data={item}
          isSavedData={true}
          isEditable={true}
          setEditVisible={setEditVisible}
          isEditVisible={isEditVisible}
          editContact={editContact}
          deleteContact={() => deleteContact(item.id)}
          jobTitleData={jobTitleData}
          departmentTypeData={departmentTypeData}
          isFetching={isFetching}
          action={action}
          userPermissions={userPermissions}
        />
      </Accordion>
    );
  });

  const fetchMasterData = async (url, key, method) => {
    getAPI(
      url,
      (data) => {
        let _n = data.data.map((data) => {
          return { value: data["id"], label: data[`${key}DispValue`] };
        });
        method(_n);
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  };

  useEffect(() => {
    fetchMasterData(
      masterServiceBaseUrl + "/departmentType/getAllActiveDepartmentType",
      "departmentType",
      setDepartmentTypeData
    );
    // fetchPhoneType(masterServiceBaseUrl + '/phoneType/getAllActivePhoneType')
    fetchMasterData(
      masterServiceBaseUrl + "/jobTitle/getAllActiveJobTitle",
      "jobTitle",
      setJobTitleData
    );

    if (action === 'edit' || action === 'view') {
      setIsDisabled(true);
      setEditVisible(false);
      setIsVisible(false);
    }


  }, []);

  useEffect(() => {
    // if ( props.data && props.isPrevious ) {
    //   console.log("Datat for step",props.data)
    //   setContactArr([...props.data])
    // }
    getAPI(
      `${masterServiceBaseUrl}/contactHistory/getContactHistoryByBusinessAreaId/${props.formId}`,
      (data) => {
        setContactArr([...data.data])
        setIsVisible(false)
      },
      (data) => {
        failureToast(data['message']);
      }
    );
  }, [props.isPrevious, props.formId])

  const saveData = () => {
    if (!contactArr.length) {
      failureToast('Add Atleast One Conatct history')
      return
    }
    if (props.isNew && !props.isPrevious) {
      props.saveStepsCompleted(3)
      // props.appendAllStepData(contactArr,'thirdForm')

    }
    if (!props.isNew || props.isPrevious) {
      props.updateAllStepData(contactArr, 'thirdForm')
    }
    props.nextStep()
  }

  // console.log(props)
  return (
    userPermissions ?
      <>
        <Container className="mt-4" fluid>
          <Card>
            {(action !== 'edit' && action !== 'view') || !isDisabled ?
              <CardHeader className="border-0">
                {action === 'new' ? <h3 className="mb-0 floatLeft">Contact History</h3> : null}
                {/* <Button
                  disabled={isEditVisible || isVisible}
                  color="info"
                  size="sm"
                  type="button"
                  className={
                    "floatRight mx-1" +
                    (isVisible || isEditVisible ? " btn-dark" : null)
                  }
                  onClick={() => setIsVisible(true)}
                >
                  New Contact History
                </Button> */}
                <CustomButton
                  className={
                    "floatRight mx-1" +
                    (isVisible || isEditVisible ? " btn-dark" : null)
                  }
                  content={"New Contact History"}
                  permissionType={'C'}
                  icon={true}
                  onClick={() => setIsVisible(true)}
                  permissionSet={userPermissions}
                />
              </CardHeader> : null}
            {isVisible && !isEditVisible ? (
              !isDisabled ?
                <ContactHistoryForm
                  isFetching={isFetching}
                  canReset={canReset}
                  setIsVisible={setIsVisible}
                  addContact={addContact}
                  jobTitleData={jobTitleData}
                  //data= {props.data}
                  departmentTypeData={departmentTypeData}
                  isDisabled={isDisabled}
                  setIsDisabled={setIsDisabled}
                  isEditable={false}
                  userPermissions={userPermissions}
                /> : null
            ) : null}
            <div className={"p-4"}>{contractHistoryList}</div>
            {(action !== 'edit' && action !== 'view') ? <Row className="pl-4 pr-4 pb-4">
              <Col md={6}>
                {/* <Button
                  color="primary"
                  type="button"
                  onClick={() => {
                    props.previousStep();
                  }}
                >
                  Previous
                </Button> */}
                <CustomButton
                  className={'mx-1'}
                  content={"Previous"}
                  permissionType={'C'}
                  onClick={() => {
                    props.previousStep();
                  }}
                  permissionSet={userPermissions}
                />
              </Col>
              <Col md={6}>
                {/* <Button
                  color="primary"
                  className={"floatRight ml-auto"}
                  type="button"
                  onClick={() => {
                    saveData();
                  }}
                >
                  Next
                </Button> */}
                <CustomButton
                  className={'mx-1'}
                  content={"Next"}
                  permissionType={'C'}
                  onClick={() => {
                    saveData();
                  }}
                  permissionSet={userPermissions}
                />
              </Col>
              {/* <Col md="10"></Col>
                  <Col md="2">
                    <Button color="primary" className="mt-4" type="button" onClick={saveData}>
                      Save & Next
                    </Button>
                  </Col> */}
            </Row> : <Row>
              {action === 'edit' ?
                <Col sm={12}>
                  {/* <Button color="primary"
                    size="md"
                    type="button"
                    className="floatRight mb-4 mr-4"
                    onClick={() => setIsDisabled(!isDisabled)}
                  >
                    {isDisabled ? 'Enable Form' : 'Disable Form'}
                  </Button> */}
                  <CustomButton
                    className={'mx-1'}
                    content={isDisabled ? 'Enable Form' : 'Disable Form'}
                    permissionType={'U'}
                    icon={true}
                    onClick={() => setIsDisabled(!isDisabled)}
                    permissionSet={userPermissions}
                  />
                </Col> : null}
            </Row>}
          </Card>

        </Container>
      </> : <PermisionDenied />
  );
};

export default ContactHistory;
