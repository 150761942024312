import React from 'react'
import { Col, Modal, ModalBody, Row } from 'reactstrap'
import { ReactComponent as CloseIcon } from "assets/img/svg/CloseIcon.svg";
import { Checkbox, FormControlLabel } from '@mui/material'
import { primaryCheckboxStyles } from '../../../edpOps/issuanceIDcard/printIdCardAndMapRfid/constant';
import AAutoComplete from '../../../common/form-fields-mui/AAutoComplete';
import { constants } from '../../../common/constant';
import CommonInput from "../../../common/formFeilds/input/commonInput";
import moment from 'moment';
import CustomDatePickerV2 from '../../../common/commonComponents/CustomDatePicker-V2';
import AButton from '../../../common/form-fields-mui/AButton';
import { getFilterCount, validateFilterInput } from './constant';

const SearchFilterForm = ({
  dropdowns,
  isOpen,
  onClose,
  filterCount,
  filterForm,
  filterFormHandler = () => { },
  applyFilterHandler = () => { },
  removeFilterHandler = () => { },
  batchDropdownData,
}) => {

  const resetBlockedStatusFilter = () => {
    filterFormHandler([], 'subject');
    filterFormHandler([], 'businessArea');
    filterFormHandler([], 'batch');
  }

  return (
    <Modal size='lg' isOpen={isOpen} toggle={onClose} style={{ width: '410px' }}>
      <ModalBody>
        <div className="mb-2 mt-2 d-flex align-items-center justify-content-between">
          <div className="large-semi-bold color-dark">Filter</div>
          <CloseIcon onClick={onClose} />
        </div>
        <div>
          <div className='d-flex'>
            <FormControlLabel
              sx={{ marginBottom: "0px", ...primaryCheckboxStyles }}
              control={
                <Checkbox
                  onChange={(e) => {
                    filterFormHandler(e?.target?.checked ? 'AVAILABLE' : '', 'status');
                    resetBlockedStatusFilter();
                  }}
                  checked={filterForm?.status === 'AVAILABLE'}
                />
              }
              label={<span className='regular-body'>Available</span>}
            />
            <FormControlLabel
              className='ml-4'
              sx={{ marginBottom: "0px", ...primaryCheckboxStyles }}
              control={
                <Checkbox
                  onChange={(e) => {
                    filterFormHandler(e?.target?.checked ? 'BLOCKED' : '', 'status');
                    resetBlockedStatusFilter();
                  }}
                  checked={filterForm?.status === 'BLOCKED'}
                />
              }
              label={<span className='regular-body'>Blocked</span>}
            />
          </div>

          <div>
            <AAutoComplete
              label='Day'
              currentValue={filterForm?.day}
              items={constants?.DAY}
              handleChange={(value) => filterFormHandler(value, 'day')}
              isShowCount={true}
              isMulti={true}
            />
          </div>

          {/* <Row className="pt-1">
            <Col md={12} className="pb-1"><span className="regular-small">Date Range</span></Col>
            <Col md={6}>
              <CommonInput type="datePickerV2" defaultValue={filterForm?.startDate} onChange={(value) => filterFormHandler(value, 'startDate')} forFilter={true} forTable={true} placeHolder={"From"} />
            </Col>
            <Col md={6}>
              <CommonInput type="datePickerV2" defaultValue={filterForm?.endDate} onChange={(value) => filterFormHandler(value, 'endDate')} forFilter={true} forTable={true} placeHolder={"To"} maxDate={moment(filterForm?.startDate)?.add(30, 'days')} isDisabled={!filterForm.startDate} />
            </Col>
          </Row> */}

          <Row className='mt-2'>
            <Col md='12' className="mb-1">
              <div className="regular-small">Date range</div>
            </Col>
            <Col md='6'>
              <CustomDatePickerV2
                placeHolder="From"
                value={filterForm?.startDate}
                handler={(index, date) => {
                  filterFormHandler(date, 'startDate')
                  filterFormHandler('', 'endDate')
                }}
              />
            </Col>
            <Col md='6'>
              <CustomDatePickerV2
                placeHolder="To"
                value={filterForm?.endDate}
                handler={(index, date) => filterFormHandler(date, 'endDate')}
                minDate={moment(filterForm?.startDate)?.add(1, 'days')}
                maxDate={moment(filterForm?.startDate)?.add(30, 'days')}
                disabled={!filterForm?.startDate}
              />
            </Col>
          </Row>

          <Row className="pt-1">
            <Col md={12} className="pb-1"><span className="regular-small">Time Range</span></Col>
            <Col md={6}>
              <CommonInput type="timePicker" defaultValue={filterForm?.startTime} onChange={(value) => filterFormHandler(moment(value)?.format('HH:mm:ss'), 'startTime')} forFilter={true} forTable={true} placeHolder={"Start Time"} />
            </Col>
            <Col md={6}>
              <CommonInput type="timePicker" defaultValue={filterForm?.endTime} onChange={(value) => filterFormHandler(moment(value)?.format('HH:mm:ss'), 'endTime')} forFilter={true} forTable={true} placeHolder={"End Time"} isDisabled={!filterForm?.startTime} />
            </Col>
          </Row>

          {filterForm?.status === 'BLOCKED' && (
            <>
              <div>
                <AAutoComplete
                  label='Subject'
                  currentValue={filterForm?.subject}
                  items={dropdowns?.subject?.filter(item => item?.label !== 'TimeTable')}
                  handleChange={(value) => filterFormHandler(value, 'subject')}
                  isShowCount={true}
                  isMulti={true}
                />
              </div>
              <div>
                <AAutoComplete
                  label='Branch'
                  currentValue={filterForm?.businessArea}
                  items={dropdowns?.businessArea}
                  handleChange={(value) => filterFormHandler(value, 'businessArea')}
                  isShowCount={true}
                  isMulti={true}
                />
              </div>
              <div>
                <AAutoComplete
                  label='Batch'
                  currentValue={filterForm?.batch}
                  items={batchDropdownData}
                  handleChange={(value) => filterFormHandler(value, 'batch')}
                  isShowCount={true}
                  isMulti={true}
                />
              </div>
            </>
          )}

          <div className='mt-3 d-flex flex-row-reverse justify-content-between'>
            <AButton
              variant='primary_filled'
              className='button_remove_left_margin'
              updatedStyle={{ width: '142px' }}
              onClick={() => { validateFilterInput(filterForm) && onClose(); applyFilterHandler(filterForm); }}
              disabled={(filterForm?.startDate && !filterForm?.endDate) || (filterForm?.startTime && !filterForm?.endTime) || !getFilterCount(filterForm)}
            >
              Apply
            </AButton>
            {filterCount > 0 && (
              <AButton
                variant='outline'
                className='button_remove_left_margin'
                updatedStyle={{ border: "1px solid #CC5F5F", color: '#CC5F5F', width: '142px' }}
                onClick={() => { removeFilterHandler(); }}
              >
                Remove filter
              </AButton>
            )}
          </div>

        </div>
      </ModalBody>
    </Modal>
  )
}

export default SearchFilterForm