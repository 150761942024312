import React, { useContext, useRef, useState } from "react";
import { AGridColDef } from "views/pages/manage/common/data-grid/ADataGridColDef";

import ActionsCellComponent from "./ActionsCellComponent";
import APopover from "views/pages/manage/common/pop-hover/APophover";
import MUIPopover from "views/pages/manage/common/a-popover";

import MainPopover from "views/pages/manage/common/a-popover";

import { useHistory } from "react-router";

import { ReactComponent as EyeIcon } from "assets/img/svg/eye_open.svg";
import { ReactComponent as InfoIcon } from "assets/img/svg/infoIcon.svg";
import { IoEyeOutline } from "react-icons/io5";
import MouseOverPopover from "../../../../academicPlanAndTest/academicTest/PopOver";

import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import AButton from "../../../../common/form-fields-mui/AButton";
import { IssuanceIDCardContext, useGetContext } from "../../contextApi";
import IssuedStockList, {
  CourseDetailsDisplay,
  IssuedMaterialData,
  IssuedMaterialsList,
} from "./IssuedStockList";
import { ButtonLikeDropdown } from "views/pages/manage/academicPlanAndTest/academicTimetable/lectureModule/selectLecture/SelectLecturePopover";
import { MaterialDropdown } from "./SelectMaterialInput";
import {
  MATERIAL_ALREADY_ISSUED_MSG,
  STUDENT_NOT_ELIGIBLE_MSG,
  primaryCheckboxStyles,
} from "../constant";
import BarcodeDialog from "./BarcodeDialog";
import {
  getPsidIssuedStock,
  getMatchingMaterials,
  getCourseDetails,
} from "../../service";
import moment from "moment";
import CustomChip from "views/pages/manage/common/customChip/CustomChip";
import { Card, CardBody } from "reactstrap";
import { getFormattedDate } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { getScreenName } from "../../stockTxns/helper";
import { getCurrentDate } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import MiscCourseIdCellComponent from "../../IssueStockMisc/MiscCourseIdCellComponent";

export const txnIdColumnNameMap = {
  "Branch consumption": "Consumption ID",
  "Branch consumption return": "Consumption return ID",
};

export const statusChip = {
  SUCCESS: { text: "Success", textColor: "##0B442D", bgColor: "#BBE2CE" },
  FAILED: { text: "Failed", textColor: "#B32306", bgColor: "#F9D9D7" },
  PROCESSING: {
    text: "In-processing",
    textColor: "#006A93",
    bgColor: "#CCEFFD",
  },
};

export const getMaterialLabel = (materials = []) => {
  if (materials.length === "") return "-";
  const firstEl =
    (materials && materials[0]?.materialDesc) || materials?.materialDescription;
  if (firstEl) return firstEl;
  // return `${firstEl.substr(0, 15)}${
  //   firstEl?.length > 15 || materials?.length > 1 ? "..." : ""
  // }`;
};

export const getDropdownLabel = (selectedMaterials = []) => {
  if (selectedMaterials.length === 1) return selectedMaterials[0].materialId;
  const firstMaterial = selectedMaterials[0].materialId;
  return `${firstMaterial.substring(0, 8)}... ${
    selectedMaterials.length - 1
  } +more`;
};

export const IssuedColumn = ({ student }) => {
  const { selectedMaterials, issuedStockToStudent } = useContext(
    IssuanceIDCardContext
  );
  const issuedMaterial =
    getPsidIssuedStock(issuedStockToStudent, student?.applicationId) || [];

  // how many materials already issue from selected material
  const allIssuedMaterial = getMatchingMaterials(
    issuedMaterial,
    selectedMaterials
  );
  const matchingCount = allIssuedMaterial.length;

  return (
    <div>
      <div className="ml-2">
        <MUIPopover
          transformOriginHorizontal="center"
          anchorOriginHorizontal="center"
          buttonComponent={(onclick) => (
            <>
              <span className="mr-1">
                {matchingCount}/{selectedMaterials.length || 0} materials
              </span>
              <EyeIcon onClick={onclick} />
            </>
          )}
          menuComponent={(closeForm) => (
            <IssuedStockList
              issuedStockToStudent={allIssuedMaterial}
              selectedMaterials={selectedMaterials}
              matchingCount={matchingCount}
              closeHandler={closeForm}
            />
          )}
        />
      </div>
    </div>
  );
};
const filterTShirtGroupMaterials = (selectedMaterials) => {
  const materialWithoutTShirtGroup = [];
  const materialWithTShirtGroup = [];

  selectedMaterials.forEach((material) => {
    if (material?.isTShirtGroupMaterial) {
      materialWithTShirtGroup.push(material);
    } else {
      materialWithoutTShirtGroup.push(material);
    }
  });
  return [materialWithTShirtGroup, materialWithoutTShirtGroup];
};

export const MaterialToBeIssued = ({ student }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    selectedMaterials,
    materialCheckBoxHandler,
    issuedStockToStudent,
    materialAndBarcodeMapping,
    canReIssueMaterialList,
    tShirtGroupMaterials,
  } = useGetContext();

  const getMaterialCount = (materialId) =>
    materialAndBarcodeMapping?.["materialCount"]?.[materialId] || 0;

  const { applicationId } = student;
  const stocksAlreadyIssued =
    getPsidIssuedStock(issuedStockToStudent, applicationId) || [];
  const selectedMaterial = student?.stock?.length;
  if (!student?.isEligibleForStockIssuance) return "-";

  const [materialWithTShirtGroup, materialWithoutTShirtGroup] =
    filterTShirtGroupMaterials(selectedMaterials);

  return (
    <div>
      <ButtonLikeDropdown
        handleClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        label={
          selectedMaterial > 0 ? getDropdownLabel(student?.stock) : "Select"
        }
        disabled={!student?.isEligibleForStockIssuance}
        isError={student?.isError}
      />

      <APopover
        handleClose={() => setAnchorEl(null)}
        id="main-dropdown"
        anchorEl={anchorEl}
      >
        <div style={{ minWidth: "460px", minHeight: "200px" }}>
          <>
            <MaterialDropdown
              setSelectedMaterials={() => {}}
              options={materialWithoutTShirtGroup}
              isShowCheckBox={true}
              tShirtOptions={materialWithTShirtGroup}
              checkBoxHandler={materialCheckBoxHandler}
              pSid={student}
              stocks={student?.stock || []}
              stocksAlreadyIssued={stocksAlreadyIssued}
              materialAndBarcodeMapping={materialAndBarcodeMapping}
              getMaterialCount={getMaterialCount}
              canReIssueMaterialList={canReIssueMaterialList}
              tShirtGroupMaterials={tShirtGroupMaterials}
            />
          </>
        </div>
      </APopover>
    </div>
  );
};

export const PsidDisplay = ({ student, alreadyIssuedTshirtMaterial }) => {
  const history = useHistory();

  const getTooltipMsg = () =>
    alreadyIssuedTshirtMaterial &&
    alreadyIssuedTshirtMaterial.length &&
    student?.isEligibleForStockIssuance === false
      ? MATERIAL_ALREADY_ISSUED_MSG
      : student?.isEligibleForStockIssuance === false
      ? STUDENT_NOT_ELIGIBLE_MSG
      : student?.psid;

  return (
    <Tooltip title={getTooltipMsg()} placement="top-start">
      <div
        onClick={
          student?.isEligibleForStockIssuance ||
          student?.isDlpMarkStockIssuanceEligible
            ? () =>
                window.open(
                  history.createHref({
                    pathname: `/admin/edp/studentDetails/${student.psid}`,
                  }),
                  "_blank"
                )
            : () => {}
        }
        className={`ellipsis-text-container cursor ${
          student?.isEligibleForStockIssuance ||
          student?.isDlpMarkStockIssuanceEligible
            ? "color-primary"
            : "color-grey-50"
        }`}
        style={student?.isEligibleForStockIssuance ? { cursor: "pointer" } : {}}
      >
        {student?.psid}
      </div>
    </Tooltip>
  );
};

export const ColoredChip = ({ value }) => {
  if (!value) return "";
  const statusChipColor = statusChip[value.toUpperCase()];
  return (
    <CustomChip
      bgColor={statusChipColor?.["bgColor"]}
      textColor={statusChipColor?.["textColor"]}
      text={statusChipColor?.["text"]}
    >
      {value}
    </CustomChip>
  );
};
export const ValueWithTooltip = ({ value, student }) => {
  return (
    <Tooltip title={value} placement="top-start">
      <div
        className={`ellipsis-text-container ${
          student?.isEligibleForStockIssuance ||
          student?.isDlpMarkStockIssuanceEligible
            ? ""
            : "color-grey-50"
        }`}
      >
        {value}
      </div>
    </Tooltip>
  );
};
export const ValueWithOutTooltip = ({ value, student }) => {
  return (
    <div
      className={`ellipsis-text-container ${
        student?.isEligibleForStockIssuance ||
        student?.isDlpMarkStockIssuanceEligible
          ? ""
          : "color-grey-50"
      }`}
    >
      {value}
    </div>
  );
};

export const CheckBoxColumn = ({
  issueStockCheckBoxHandler,
  isChecked,
  student,
  isAll,
  disabled = false,
}) => {
  return (
    <FormControlLabel
      sx={{ margin: "0px", ...primaryCheckboxStyles }}
      control={
        <Checkbox
          onChange={(e) =>
            issueStockCheckBoxHandler(
              e.target.checked ? true : false,
              student,
              isAll
            )
          }
          // sx={{ paddingTop: "0px" }}
          name="emailEnabled"
          checked={isChecked}
          disabled={disabled}
          {...(isAll && { indeterminate: isChecked })}
        />
      }
    />
  );
};

export const ViewEditBarCode = ({ rowData = {}, student }) => {
  // console.log(rowData);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  if (!rowData?.isEligibleForStockIssuance) return "-";
  if (rowData?.stock?.length === 0) return "-";
  if (!rowData?.stock) return "-";
  const filteredBarcodedMaterialsRowData = (rowData?.stock || [])?.filter(
    (item) => item?.serialNumberProfile?.toLowerCase() !== "na"
  );
  if (!filteredBarcodedMaterialsRowData?.length) return "-";
  return (
    <>
      <span
        onClick={() => setIsDialogOpen(true)}
        className="color-primary pointer"
      >
        Edit/View
      </span>
      <BarcodeDialog
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        rowData={rowData}
      />
    </>
  );
};

export const getTableTableHeader = ({ history, issueStockToApplication }) => [
  new AGridColDef("applicationId", "Application no.")
    .setMinWidth(150)
    .renderCellComponent((params) => (
      <Tooltip title={params?.value}>
        <div className={`ellipsis-text-container`}>{params?.value}</div>
      </Tooltip>
    )),
  new AGridColDef("studentName", "Student name")
    .setMinWidth(200)
    .renderCellComponent((params) => (
      <Tooltip title={params?.value}>
        <div className={`ellipsis-text-container`}>{params?.value}</div>
      </Tooltip>
    )),
  new AGridColDef("programAction", "Program plan").setMinWidth(150),
  new AGridColDef("issued", "Material already issued")
    .setMinWidth(200)
    .renderCellComponent((params) => <IssuedColumn />),

  new AGridColDef("meterial", "Materials to be issued", false)
    .setMinWidth(200)
    .renderCellComponent((params) => <MaterialToBeIssued params={params} />),
  new AGridColDef("barcodes", "BarCodes", false),

  new AGridColDef("actions", "Actions", false)
    .setMinWidth(180)
    .columnAlign("right")
    .renderCellComponent((params) => (
      <ActionsCellComponent
        issueStockToApplication={issueStockToApplication}
        params={params}
      />
    )),
];

export const getApplicationsTableHeader = ({ onSelectClick, isMisc }) => {
  const columns = isMisc
    ? [
        new AGridColDef("courseId", "Misc. course ID")
          .setMinWidth(150)
          .renderCellComponent((params) => (
            <MiscCourseIdCellComponent params={params} />
          )),
        new AGridColDef("receiptId", "Receipt no.")
          .setMinWidth(150)
          .renderCellComponent((params) => (
            <Tooltip title={params?.value}>
              <div className={`ellipsis-text-container`}>{params?.value}</div>
            </Tooltip>
          )),
      ]
    : [new AGridColDef("courseId", "Course ID").setMinWidth(150)];
  return [
    new AGridColDef("applicationId", "Application no.")
      .setMinWidth(150)
      .renderCellComponent((params) => (
        <Tooltip title={params?.value}>
          <div className={`ellipsis-text-container`}>{params?.value}</div>
        </Tooltip>
      )),
    new AGridColDef("studentName", "Student name")
      .setMinWidth(150)
      .renderCellComponent((params) => (
        <Tooltip title={params?.value}>
          <div className={`ellipsis-text-container`}>{params?.value}</div>
        </Tooltip>
      )),
    new AGridColDef("programAction", "Program plan").setMinWidth(150),
    ...columns,
    new AGridColDef("actions", "Actions", false)
      .setMinWidth(100)
      .renderCellComponent((params) => (
        <AButton
          onClick={() => onSelectClick(params?.row)}
          size="xs"
          variant="primary_filled"
          className="btn-left-0"
        >
          Select
        </AButton>
      )),
  ];
};
export const getStockTxnTableHeader = ({
  history,
  isConsumptionReturn = false,
  isConsumptionHistory = false,
  selectedTab,
  academicType,
}) => {
  const consumptionFlow = isConsumptionReturn || isConsumptionHistory;
  const screenName = isConsumptionReturn
    ? "returnStock"
    : getScreenName(selectedTab);
  const statusColumn = new AGridColDef("status", "Status")
    .setMinWidth(150)
    .renderCellComponent((params) => {
      const statusChipColor = statusChip[params.value.toUpperCase()];
      return (
        <CustomChip
          bgColor={statusChipColor?.["bgColor"]}
          textColor={statusChipColor?.["textColor"]}
          text={statusChipColor?.["text"]}
        >
          {params.value}
        </CustomChip>
      );
    });

  const columns = [new AGridColDef("termValue", "Term"), statusColumn];
  const consumptionFlowColumn = [
    new AGridColDef("transactionType", "Transaction type")
      .setMinWidth(120)
      .renderCellComponent((params) => (
        <div className={`ellipsis-text-container`}>
          <Tooltip title={params?.value}>
            <div>{params?.value}</div>
          </Tooltip>
        </div>
      )),
  ];
  const rendersColumn = consumptionFlow
    ? isConsumptionReturn
      ? consumptionFlowColumn
      : [...consumptionFlowColumn, statusColumn]
    : columns;

  return [
    new AGridColDef(
      "issueDate",
      selectedTab === "Branch consumption return"
        ? "Return details"
        : "Issue details"
    )
      .setMinWidth(150)
      .renderCellComponent((params) => (
        <div className={`ellipsis-text-container d-flex`}>
          {moment(params.value).format("DD-MMM-YYYY")}
          <IssuedDetailsInfo stock={params.row} />
        </div>
      )),
    new AGridColDef(
      "transactionId",
      isConsumptionReturn
        ? "Consumption ID"
        : txnIdColumnNameMap?.[selectedTab]
        ? txnIdColumnNameMap?.[selectedTab]
        : "Transaction ID"
    )
      .setMinWidth(150)
      .renderCellComponent((params) => (
        <div className={`ellipsis-text-container`}>{params?.value}</div>
      )),
    new AGridColDef("sapDocId", "SAP Doc Number")
      .setMinWidth(150)
      .setValueGetter((params) => params?.value || "-"),
    new AGridColDef("businessAreaValue", "Business Area").setMinWidth(150),
    ...rendersColumn,
    new AGridColDef("actions", "Actions", false)
      .setMinWidth(100)
      .columnAlign("right")
      .renderCellComponent((params) => {
        const row = params?.row;
        return (
          <AButton
            className="button_remove_left_margin"
            size="xs"
            variant="primary_filled"
            onClick={() => {
              window.open(
                history.createHref({
                  pathname: `/admin/edp/stockManagement/stockTxnHistory/${academicType}/${row?.transactionId}/${row?.businessArea}/${row?.businessAreaValue}/${screenName}`,
                }),
                "_blank"
              );
            }}
          >
            View details
          </AButton>
        );
      }),
  ];
};

export const getReversalTableHeader = (history, selectedTab, academicType) => {
  return [
    new AGridColDef("issueDate", "Reversal details")
      .setMinWidth(150)
      .renderCellComponent((params) => (
        <div className={`ellipsis-text-container d-flex`}>
          {moment(params.value).format("DD-MMM-YYYY")}
          <IssuedDetailsInfo stock={params.row} />
        </div>
      )),
    new AGridColDef("transactionId", "Reversal ID")
      .setMinWidth(150)
      .renderCellComponent((params) => (
        <div className={`ellipsis-text-container`}>{params?.value}</div>
      )),
    new AGridColDef("sapDocId", "SAP Doc Number")
      .setMinWidth(150)
      .setValueGetter((params) => params?.value || "-")
      .renderCellComponent((params) => (
        <div className="ellipsis-text-container">{params?.value}</div>
      )),
    new AGridColDef("termValue", "Term"),
    new AGridColDef("status", "Status")
      .setMinWidth(150)
      .renderCellComponent((params) => {
        const statusChipColor = statusChip[params.value.toUpperCase()];
        return (
          <CustomChip
            bgColor={statusChipColor?.["bgColor"]}
            textColor={statusChipColor?.["textColor"]}
            text={statusChipColor?.["text"]}
          >
            {params.value}
          </CustomChip>
        );
      }),
    new AGridColDef("actions", "Actions", false)
      .setMinWidth(100)
      .columnAlign("right")
      .renderCellComponent((params) => {
        const row = params?.row;
        return (
          <AButton
            className="button_remove_left_margin"
            size="xs"
            variant="primary_filled"
            onClick={() => {
              window.open(
                history.createHref({
                  pathname: `/admin/edp/stockManagement/stockTxnHistory/${academicType}/${
                    row?.transactionId
                  }/${row?.businessArea}/${
                    row?.businessAreaValue
                  }/${getScreenName(selectedTab)}`,
                }),
                "_blank"
              );
            }}
          >
            View history
          </AButton>
        );
      }),
  ];
};

export const IssuedDetailsInfo = ({ stock }) => {
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [popOverContent, setPopOverContent] = useState([]);
  const open = Boolean(anchorEl);
  // console.log(stock);
  const handlePopoverOpen = (event, data) => {
    setAnchorEl(event.currentTarget);
    setPopOverContent(data);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopOverContent([]);
  };

  return (
    <div>
      <span
        onMouseEnter={(e) => handlePopoverOpen(e, [])}
        onMouseLeave={handlePopoverClose}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
      >
        <span className="mr-1"></span>
        <InfoIcon />
      </span>
      <MouseOverPopover
        handlePopoverOpen={handlePopoverOpen}
        handlePopoverClose={handlePopoverClose}
        anchorEl={anchorEl}
        popOverContent={popOverContent}
        open={true}
      >
        <Card className="mb-0">
          <CardBody className="mb-0">
            {getFormattedDate(stock?.issueDate)},{" "}
            {moment(stock?.issueDate).format("LT")} by {stock?.createdBy}
          </CardBody>
        </Card>
      </MouseOverPopover>
    </div>
  );
};

export const getTransactionHistory = (academicCareer) => [
  new AGridColDef("psid", "PSID").setMinWidth(250).setFlex(0.5),
  new AGridColDef("studentName", "Name").setMinWidth(250).setFlex(0.5),
  new AGridColDef("applicationId", "Application no.")
    .setMinWidth(150)
    .setFlex(0.5),
  new AGridColDef("courseId", "Course ID").setMinWidth(100).setFlex(0.5),
  new AGridColDef("termValue", "Term").setMinWidth(100).setFlex(0.5),
  ...(academicCareer === "DLP"
    ? [
        new AGridColDef("dispatchId", "Dispatch")
          .setMinWidth(100)
          .setFlex(0.5)
          .setValueGetter((params) => params?.value || "-"),
      ]
    : []),
  new AGridColDef("materialDetails", "Material details")
    .setMinWidth(150)
    .renderCellComponent((params) => (
      <div className="d-flex ellipsis-text-container">
        {/* <div className="ellipsis-text-container flex-grow-1">{params?.value || params?.row}</div> */}
        <div className="ellipsis-text-container flex-grow-1">
          {getMaterialLabel(params?.value || params?.row)}
        </div>
        <MaterialDetails dataRows={params.value} />
      </div>
    )),
];

// material details
export const MaterialDetails = ({ dataRows, isReturned = false }) => {
  return (
    <div>
      <MainPopover
        buttonComponent={(onClick, styles) => (
          <span
            style={{ color: "#008DC4", cursor: "pointer", marginLeft: "10px" }}
            onClick={onClick}
          >
            View detail
          </span>
        )}
        menuComponent={(closeForm) => (
          <>
            <IssuedMaterialData isReturned={isReturned} dataRows={dataRows} />
          </>
        )}
      />
    </div>
  );
};

const getMaterialsAndCount = (issuedMaterialsList, materialsSelected) => {
  // console.log(materialsSelected);
  let count = 0;
  materialsSelected.forEach((element) => {
    if (
      issuedMaterialsList?.some(
        (item) => item?.materialId === element?.materialNumber
      )
    )
      count++;
  });

  return [count, issuedMaterialsList];
};
// material details
export const MaterialAlreadyIssued = ({
  dataRows,
  dispatchSchedular,
  issuedStockToStudent,
}) => {
  const ref = useRef();
  const materialsSelected = dispatchSchedular?.materialDetails || [];
  const [matchingCount, issuedMaterialList] = getMaterialsAndCount(
    issuedStockToStudent,
    materialsSelected
  );
  const closePopOver = () => {
    ref.current.click();
  };
  return (
    <div>
      <MainPopover
        buttonComponent={(onClick, styles) => (
          <span ref={ref} id="eye-icon" onClick={onClick}>
            <span className="mr-2">
              {matchingCount}/{materialsSelected?.length} materials
            </span>{" "}
            <EyeIcon />
          </span>
        )}
        menuComponent={(closeForm) => (
          <>
            {matchingCount == 0 ? (
              <div className="m-2 p-2 font-weight-bolder">
                No materials issued
              </div>
            ) : (
              <IssuedMaterialsList
                matchingCount={`${matchingCount}/${materialsSelected?.length}`}
                issuedStockToStudent={issuedMaterialList}
                dispatchId={dispatchSchedular?.dispatchId}
                closePopOver={closeForm}
              />
            )}
          </>
        )}
      />
    </div>
  );
};

// material details

// application details by course
// material details
export const CourseDetails = ({ courseId, applicationId, ...restProps }) => {
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [popOverContent, setPopOverContent] = useState([]);
  const [courseData, setCourseData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchCourse = async () => {
    if (courseId) {
      setLoading(true);
      const res = await getCourseDetails(courseId);
      if (res?.code === 200 && res?.data) setCourseData(res?.data[0]);
      setLoading(false);
    }
  };
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event, data) => {
    setAnchorEl(event.currentTarget);
    fetchCourse();
    setPopOverContent(data);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopOverContent([]);
  };
  return (
    <div {...restProps}>
      <span
        onMouseEnter={(e) => handlePopoverOpen(e, [])}
        onMouseLeave={handlePopoverClose}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
      >
        <span className="cursor" style={{ marginLeft: "8px" }}>
          <IoEyeOutline size={18} />
        </span>
      </span>
      <MouseOverPopover
        handlePopoverOpen={handlePopoverOpen}
        handlePopoverClose={handlePopoverClose}
        anchorEl={anchorEl}
        popOverContent={popOverContent}
        open={true}
      >
        <CourseDetailsDisplay
          course={courseData}
          applicationId={applicationId}
          isLoading={loading}
        />
      </MouseOverPopover>
    </div>
  );
};
