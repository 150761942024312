/* eslint-disable */
import React, { useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import {
  TreeItem,
  useTreeItem,
  TreeView,
} from "views/pages/manage/common/commonComponents/tree";
import CustomTreeItem from "./CustomTreeItem";
import { MinusSquare, PlusSquare, CloseSquare } from "./SvgIcon";
import { ReactComponent as HorizontalLine } from "../../../../../../assets/img/svg/HorizontalLine.svg";

const StyledTreeItem = styled((props) => (
  <TreeItem ContentComponent={CustomTreeItem} {...props} />
))(({ theme }) => ({
  // [`& .${treeItemClasses.iconContainer}`]: {
  //   "& .close": {
  //     opacity: 0.3,
  //   },
  // },
  // [`& .${treeItemClasses.group}`]: {
  //   marginLeft: 15,
  //   paddingLeft: 18,
  //   borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.4)}`,
  // },
}));


export default function SubjectTreeView({
  subjectChapters,
  setSubjectChapters,
  addHandler,
  expended, 
  setExpended
}) {
  // const [subjectChapters, setSubjectChapters]  = useState({}) ;

  const nodeMutator = (nodeData) => {
    switch (nodeData?.nodeType) {
      case "Chapter":
         
        let temp = { ...subjectChapters };
        temp.tableOfContent.forEach((chapter) => {
          if (chapter.key === nodeData.key) {
            chapter["isAdded"] = true;
            addHandler(nodeData);
          }
        });
        setSubjectChapters(temp);
        break;
      case "Topic":
        let tempData = { ...subjectChapters };
        tempData.tableOfContent.forEach((chapter) => {
          if (chapter.key === nodeData.parentNode) {
            chapter["isAdded"] = true;
            chapter?.children.forEach((topic) => {
              if (topic.key === nodeData.key) {
                topic["isAdded"] = true;
                setExpended([...expended,nodeData.key.toString() ])
                addHandler(nodeData);
              }
            });
          }
        });
        setSubjectChapters(tempData);
        break;
      case "Sub-Topic":
        // find its parent then parent of parent set is added true for all
        let tempSub = { ...subjectChapters };
        tempSub.tableOfContent.forEach((chapter) => {
          if (chapter.key === nodeData.topicParentId) {
            chapter["isAdded"] = true;
            chapter?.children.forEach((topic) => {
              if (topic.key === nodeData.parentNode) {
                topic["isAdded"] = true;
                topic?.children?.forEach((subtopic) => {
                  if (subtopic.key === nodeData.key) {
                    subtopic["isAdded"] = true;
                    addHandler(nodeData);
                  }
                });
              }
            });
          }
        });
        setSubjectChapters(tempSub);
        break;

      default:
        break;
    }
  };
  return (
    <TreeView
      aria-label="customized"
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={<CloseSquare />}
      sx={{ height: "70vh", flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
    >
      {subjectChapters?.tableOfContent?.map((chapter, index) => {
        return (
          <StyledTreeItem
            key={chapter.key}
             nodeId={chapter.key.toString()}
            label={chapter.title}

            onClick={{ data: chapter, mutator: nodeMutator }}
          >
            {chapter.children?.map((topic) => {
              return (
                <StyledTreeItem
                  key={topic.key}
                  nodeId={topic.key}

                  label={topic.title}
                  onClick={{ data: topic, mutator: nodeMutator }}
                >
                  {topic?.children?.map((subtopic) => {
                    return (
                      <StyledTreeItem
                        key={subtopic.key}
                        nodeId={subtopic.key}
                        label={subtopic.title}
                        onClick={{
                          data: {
                            ...subtopic,
                            topicParentId: topic.parentNode,
                          },
                          mutator: nodeMutator,
                        }}
                        icon={<HorizontalLine />}
                      ></StyledTreeItem>
                    );
                  })}
                </StyledTreeItem>
              );
            })}
          </StyledTreeItem>
        );
      })}
    </TreeView>
  );
}
