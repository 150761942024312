import React, { useEffect, useMemo, useState } from 'react'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { getUniqueKey } from '../../../../common/utils/methods/commonMethods/utilityMethod'
import moment from 'moment'
import './index.scss'
// import { Tooltip } from '@mui/material'
import CustomLoader from '../../../../common/commonComponents/Loader/loader'
import { RiFileCopyLine } from 'react-icons/ri'
import { paymentStatus } from '../../studentList'
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { LightTooltip } from '../concessionDetails/prospectus';
import { getBreakUp, createBreakUpData, getConsessionDetails, createBreakUpDataOnUI, parseFloatTillDigit } from './common';
import { IoEyeSharp } from "react-icons/io5";
import { Typography } from '@mui/material';
import { ReactComponent as ViewIcon } from './eye.svg';
import { getMaximumConcession } from '../concessionDetails/common';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});


const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        maxHeight: '700px',
        height: '95vh',
        // left : '60px',
        right: '0px',
        width: '95%',
        maxWidth: 'none',
        borderRadius: '10px 0 0 10px',
        position: 'absolute'
    }
}));

const consessionMap = {
    regFeeConcession: 'Registration Fee',
    adminFeeConcession: 'Admission Kit & Digital Access',
    tutionFeeConcession: 'Tuition Fee',
    techFeeConcession: 'Tech + Exam Fee',
    classFeeConcession: 'Classroom Fee Service',
}

 export const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
        "&::before": {
            // border: '1px solid #dadde9',
            backgroundColor: '#FFFFFF',
        }
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#FFFFFF',
        color: '#262626',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 400,
        zIndex: 999999999999999,
        boxShadow: '0px 0px 12px 0px #0000001F',
        borderRadius: 8
    },
}));

const FeeBreakupDetails = ({ processedData, showBreakupGris, totals, setOpen, open, itemTypeLabel, values, businessArea, term, courses, isFetchingConsession, consession, pendingForApproval, pendingForApprovalPercentage, rATotal }) => {
    let headerList = open ? [
        "Description",
        "Due date",
        "Gross amount",
        "Concession applied",
        "Payable   amount",
        "Taxes",
        "Net Payable",
        "Con. approval pending",
        "Net Payable post approval"
    ] : [
        "Description",
        "Concession applied",
        "Concession approval pending",
    ];
    const [toolTipText, setText] = useState('Copy');

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openPoper, setOpenPoper] = React.useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenPoper(true);
    };


    return <div className={`fee-brakeup-container ${!open ? 'sticky-breakup' : ''}`} style={open ? { overflowY: 'hidden' } : {}}>
        <div className='p-3'>

            <div className='header-section'>
                <div className='d-flex justify-content-between align-items-center'>
                    <div onClick={() => { setOpen(true) }} style={{ width: '100%', padding: '10px' }} className="header">
                        <div className="title-section">
                            <span className='title'>Fee Summary</span>
                            {open ? <span className='hide-btn' onClick={() => { setOpen(false) }}></span> : <span className='hide-btn' onClick={() => { setOpen(true) }}>View details</span>}

                        </div>
                    </div>
                    <div>{open && <span className='close-btn' onClick={() => setOpen(false)}>Close</span>}</div>
                </div>
                {values.masterLoading || values.applicationDetailsLoading || isFetchingConsession ? <CustomLoader /> : open ? <>
                    <div className={`detail-section ${open ? "open" : ""}`}>
                        <div className="details-item">
                            <span className="label pb-1">Term</span>
                            <span className="value">{values.term}</span>
                        </div>
                        <div className="details-item">
                            <span className="label">Course</span>
                            <span className="value">
                                {`${values.courseId} | ${courses.find(i => i.value === values.courseId)?.label}` ?? ''}
                                <Tooltip title={toolTipText}>
                                    <span>
                                        <RiFileCopyLine
                                            style={{ color: "#00B0F5", height: "1.2rem", width: "1.4rem", cursor: "pointer", margin: '.5rem .5rem' }}
                                            onClick={() => {
                                                navigator.clipboard.writeText(values.courseId);
                                                setText("Copied")
                                            }} />
                                    </span>
                                </Tooltip>
                            </span>
                        </div>
                        <div className="details-item">
                            <span className="label pb-1">Business area</span>
                            <span className="value">{businessArea.find(i => i.value === values.businessArea)?.label ?? ''}</span>
                        </div>
                        <div className="details-item">
                            {/* <span className="label pb-1">Security fees status</span>
                            <span style={paymentStatus?.[values?.paymentStatus?.toLowerCase()] ?? {}}>{values?.paymentStatus ?? '-'}</span> */}
                        </div>
                    </div>
                    <hr />
                    <div className="header mt-3">
                        <div className="title-section">
                            <span className='title'>Concession applied</span>
                        </div>
                    </div>
                    <div className={`detail-section ${open ? "open" : ""}`}>
                        {Object.keys(itemTypeLabel)?.map(item => {
                            return <div className="details-item" key={itemTypeLabel[item]}>
                                <span className="label pb-1">{itemTypeLabel[item]}</span>
                                <span className="value">{`${Math.round((consession[item]?.flAmt + consession[item]?.ylAmt + consession[item]?.ulAmt + consession[item]?.oAmt) * 100) / 100}%`}</span>
                            </div>
                        })
                        }
                        <div className="details-item">
                            <span className="label">{`Pending for approval`}</span>
                            <span className="value">{pendingForApprovalPercentage ? `${getMaximumConcession(pendingForApproval)}%` : 0}</span>
                        </div>
                    </div> </> : null}
            </div>
            {open && <div style={{ marginTop: '30px' }}></div>}
            {showBreakupGris && !values.masterLoading && !values.applicationDetailsLoading && !isFetchingConsession && <>
                <table className={'table-section'}>
                    {/* {open && <hr />} */}
                    <thead style={{ display: 'block' }}>
                        <tr className='table-title-wrapper'>
                            {headerList.map((headerName, i) => {
                                return <th key={headerName} colSpan={headerName === 'Description' ? 3 : 1} className={` ${!open ? 'uncolapsed-table-details-th' : ''} ${headerName !== 'Description' && headerName !== 'Due date' ? 'table-title text-right' : 'table-title text-left'} ${headerName === 'Description' ? 'description' : ''} ${headerName === 'Con. approval pending' || headerName === 'Payable amount post approval' ? 'w-130' : ''}`}>{headerName}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody className={open ? 'open-table' : 'table-body'}>
                        {
                            Object.keys(processedData).sort((a,b)=>a-b).map((date, index) => {
                                return <>
                                    {
                                        processedData[date].map((item, i) => {
                                            return <tr className='table-details-wrapper' key={`row-${item['payableAmt']}-${index}-${getUniqueKey()}`}>
                                                <LightTooltip title={item.desc}>
                                                    <td className={!open ? 'table-details uncolapsed-table-details-td' : 'table-details description'}>{item.desc}</td>
                                                </LightTooltip>
                                                {/* <td className={!open ? 'table-details uncolapsed-table-details-td' : 'table-details description'}>{item.desc}</td> */}
                                                {open && <><td className='table-details'>{moment(item.dueDate).format('DD MMM YYYY')}</td>
                                                    <td className='table-details text-right'>₹ {item['baseAmount']?.toFixed(2)}</td></>}
                                                <td className={!open ? 'table-details text-right uncolapsed-table-details-td' : 'table-details text-right'}>{item['consession'] ? `₹ ${item['consession']?.toFixed(2)}` : '-'}</td>
                                                {open && <td className='table-details text-right'>₹ {item['payableAmt']?.toFixed(2)}</td>}
                                                {open && <td className='table-details text-right'>₹ {item['tax']?.toFixed(2)}

                                                    {item['tax'] ? <HtmlTooltip
                                                        placement="bottom"
                                                        arrow
                                                        title={
                                                            <React.Fragment>
                                                                {/* <Typography color="inherit">Tooltip with HTML</Typography>
                                                                <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
                                                                {"It's very engaging. Right?"} */}
                                                                {item?.taxBreakupResDtos.map((tax, i) => {
                                                                    return tax?.taxPercentage || tax?.taxValue ? <div key={i} style={{ padding: '10px' }} className="d-flex flex-row">
                                                                        <div style={{ width: 70 }}>{tax.taxCodeTypeDispVal?.toUpperCase()}</div>
                                                                        <span>{tax?.taxValue ? ` : ₹${tax?.taxValue?.toFixed(2)}` : ` : ₹${((tax?.taxPercentage * item.payableAmt) / 100)?.toFixed(2)}`}</span>
                                                                    </div> : null
                                                                })}
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <span className='pl-1'><ViewIcon style={{ cursor: 'pointer' }} /></span>
                                                    </HtmlTooltip> : null}</td>}
                                                {open && <td className='table-details text-right'>₹ {item['netPayable']?.toFixed(2)}</td>}
                                                <td className={!open ? 'table-details text-right uncolapsed-table-details-td' : 'table-details text-right w-130'}>{item['concApprovalPending'] && item['approvalRequired'] ? `₹ ${item['concApprovalPending']?.toFixed(2)}` : '-'}</td>
                                                {open && <td className='table-details text-right w-130'>{item['netPayablePostApproval'] && item['approvalRequired'] ? `₹ ${item['netPayablePostApproval']?.toFixed(2)}` : item['approvalRequired'] ? '₹ 0.00' : '-'}</td>}
                                            </tr>
                                        })
                                    }
                                    {date !== '00' && <tr className='table-details-wrapper row-active'>
                                        <td className={!open ? 'table-details text-right uncolapsed-table-details-td' : 'table-details description'}>{date.substring(1)}{index == 1 ? 'st' : index == 2 ? 'nd' : index == 3 ? 'rd' : 'th'} Installment Total</td>
                                        {open && <><td className='table-details' />
                                            <td className='table-details text-right'>₹ {totals[date]['totalGross']?.toFixed(2)}</td></>}
                                        <td className={!open ? 'table-details text-right uncolapsed-table-details-td' : 'table-details text-right'}>₹ {parseFloat(totals[date]['conAmt'])?.toFixed(2)}</td>
                                        {open && <td className='table-details text-right'>₹ {totals[date]['totalPayAmt']?.toFixed(2)}</td>}
                                        {open && <td className='table-details text-right'>₹ {parseFloat(totals[date]['totalTax'])?.toFixed(2)}
                                            {totals[date]['totalTax'] ? <HtmlTooltip
                                                placement="bottom"
                                                arrow
                                                title={
                                                    <React.Fragment>
                                                        {['cgst', 'sgst', 'igst', 'utgst'].map((tax, i) => {
                                                            return totals[date][tax] ? <div style={{ padding: '10px' }} className="d-flex flex-row">
                                                                <div style={{ width: 70 }}>{tax.toLocaleUpperCase()}</div>
                                                                <span>{` :  ₹${parseFloat(totals[date][tax])?.toFixed(2)}`}</span>
                                                            </div> : null
                                                        })}
                                                    </React.Fragment>
                                                }
                                            >
                                                <span className='pl-1'><ViewIcon style={{ cursor: 'pointer' }} /></span>
                                            </HtmlTooltip> : null}
                                        </td>}
                                        {open && <td className='table-details text-right'>₹ {totals[date]['netAmt']?.toFixed(2)}</td>}
                                        <td className={!open ? 'table-details text-right uncolapsed-table-details-td' : 'table-details text-right w-130'}>{totals[date]['concApprovalPendingTotal'] && totals?.[date]?.['approvalRequired'] ? `₹ ${totals[date]['concApprovalPendingTotal']?.toFixed(2)}` : '-'}</td>
                                        {open && <td className='table-details text-right w-130'>{totals[date]['netPayablePostApprovalTotal'] && totals?.[date]?.['approvalRequired'] ? `₹ ${totals[date]['netPayablePostApprovalTotal']?.toFixed(2)}` : '-'}</td>}
                                    </tr>}
                                    {open && Object.keys(processedData).length == index + 1 && <tr className='table-row-total-amount'>
                                        <td className='table-details description'>Total Amount</td>
                                        <td className='table-details' />
                                        <td className='table-details text-right' >₹ {parseFloat((Object.keys(totals).reduce((a, b) => a + totals[b]['totalGross'], 0))).toFixed(2)}</td>
                                        <td className='table-details text-right' >₹ {parseFloat((Object.keys(totals).reduce((a, b) => a + totals[b]['conAmt'], 0))).toFixed(2)}</td>
                                        <td className='table-details text-right' >₹ {parseFloat((Object.keys(totals).reduce((a, b) => a + totals[b]['totalPayAmt'], 0))).toFixed(2)}</td>
                                        <td className='table-details text-right' >₹ {parseFloat((Object.keys(totals).reduce((a, b) => a + totals[b]['totalTax'], 0))).toFixed(2)}
                                            {(Object.keys(totals).reduce((a, b) => a + totals[b]['totalTax'], 0)) ? <HtmlTooltip
                                                placement="bottom"
                                                arrow
                                                title={
                                                    <React.Fragment>
                                                        {['cgst', 'sgst', 'igst', 'utgst'].map((tax, i) => {
                                                            return parseFloat((Object.keys(totals).reduce((a, b) => a + totals[b][tax], 0))) ? <div style={{ padding: '10px' }} className="d-flex flex-row">
                                                                <div style={{ width: 70 }}>{tax.toLocaleUpperCase()}</div>
                                                                <span>{` :  ₹${parseFloat((Object.keys(totals).reduce((a, b) => a + totals[b][tax], 0))).toFixed(2)}`}</span>
                                                            </div> : null
                                                        })}
                                                    </React.Fragment>
                                                }
                                            >
                                                <span className='pl-1'><ViewIcon style={{ cursor: 'pointer' }} /></span>
                                            </HtmlTooltip> : null}
                                        </td>
                                        <td className='table-details text-right' >₹ {parseFloat((Object.keys(totals).reduce((a, b) => a + totals[b]['netAmt'], 0))).toFixed(2)}</td>
                                        <td className='table-details text-right w-130'>₹ {!isNaN(Math.round(Object.keys(totals).reduce((a, b) => a + totals[b]['concApprovalPendingTotal'], 0) * 100) / 100) && totals?.[Object.keys(totals)?.[0]]?.approvalRequired ? parseFloat(((Math.round(Object.keys(totals).reduce((a, b) => a + totals[b]['concApprovalPendingTotal'], 0) * 100) / 100))).toFixed(2) : '0'}</td>
                                        <td className='table-details text-right w-130'>₹ {!isNaN(Math.round(Object.keys(totals).reduce((a, b) => a + totals[b]['netPayablePostApprovalTotal'], 0) * 100) / 100) && totals?.[Object.keys(totals)?.[0]]?.approvalRequired ? parseFloat(((Math.round(Object.keys(totals).reduce((a, b) => a + totals[b]['netPayablePostApprovalTotal'], 0) * 100) / 100))).toFixed(2) : '0'}</td>
                                    </tr>}
                                </>
                            })
                        }
                    </tbody>
                    {/* </tbody> */}
                </table>
            </>}</div>


        {showBreakupGris && !values.masterLoading && !values.applicationDetailsLoading && !isFetchingConsession && <>{!open && <div className='closed-total-grid'>
            <div>
                <span>Gross amount</span>
                <span>₹ {Math.round(Object.keys(totals).reduce((a, b) => a + totals[b]['totalGross'], 0) * 100) / 100}</span>
            </div>
            <div>
                <span>Total con. ({`${(((Math.round(Object.keys(totals).reduce((a, b) => a + totals[b]['conAmt'], 0) * 100) / 100) / (Math.round(Object.keys(totals).reduce((a, b) => a + totals[b]['totalGross'], 0) * 100) / 100)) * 100).toFixed(2)}`}%)</span>
                <span>₹ {Math.round(Object.keys(totals).reduce((a, b) => a + totals[b]['conAmt'], 0) * 100) / 100}</span>
            </div>
            <hr />
            <div>
                <span>Total payable amount</span>
                <span>₹ {parseFloatTillDigit(Math.round(Object.keys(totals).reduce((a, b) => a + totals[b]['netAmt'], 0) * 100) / 100,0)}.00</span>
            </div>
        </div>
        }</>}

    </div>
}


const FeeBreakup = ({ values,
    businessArea,
    term,
    courses,
    infoUpdated,
    setinfoUpdated,
    applicationId,
    concessionDetails,
    feeBreakupData,
    itemTypes = { fetching: true, data: [] },
    handleChargesUpdate,
    isChargesFinializes,
    finalBreakupData,
    allowChargesModification,
    pastIdSelected,
    studentCurrentYear,
    paymentUpdated,
    concessioUpdated,
    setPaymentUpdated,
    setConcessionUpdated,
    paymentDetails,
    paidItems,
    cmpr,
    processedData,
    setProcessedData
}) => {
    const [open, setOpen] = useState(false);
    const [fetching, setFetching] = useState(true)
    const [uniqueBreakup, setUniqueBreakupData] = useState([]);
    const [groupedBreakUp, setGroupedBreakup] = useState([]);
    const [breakupData, setBreakupData] = useState({ fetching: true, data: [] });
    // const [processedData, setProcessedData] = useState({});
    const [totals, setTotals] = useState({});

    const [showBreakupGris, setShowBreakupGrid] = useState(false);
    const [consession, setConsession] = useState({})
    const [isFetchingConsession, setFetchingConsession] = useState(true);
    const [totalAmt, setTotalAmt] = useState(0)
    // const [consolidateAmt,setConsolidateAmt] = useState(null);
    const [updateFinalAmt, setUpdateFinalAmt] = useState(true);
    const [pendingForApproval, setPendingForApproval] = useState({});
    const [pendingForApprovalPercentage, setPendingForApprovalPercentage] = useState('-');

    const [concessionAppliedList, setConcessionAppliedList] = useState({})


    const getLocalBreakUp = async () => {
        
        if (Array.isArray(feeBreakupData?.data)) {
            const { groupedData, itemTypeLabelMapping, total } = await getBreakUp(feeBreakupData, itemTypes);
            setTotalAmt(total);
            setGroupedBreakup(groupedData)
            setUniqueBreakupData(itemTypeLabelMapping)
            setBreakupData({ fetching: false, data: feeBreakupData?.data })
            setFetching(false)
        } else {
            setBreakupData({ fetching: false, data: [] })
            setFetching(false)
        }
    }

    const createLocalBreakUpData = async () => {
        const { tempData, totalTempData, percentPendingForApproval } = createBreakUpDataOnUI(groupedBreakUp, consession, pendingForApproval, concessionAppliedList, studentCurrentYear, concessionDetails,
               {paidItems, pastIdSelected,cmpFlag:cmpr});
        setProcessedData({ ...tempData });
        setTotals({ ...totalTempData });
        setPendingForApprovalPercentage(percentPendingForApproval);
        setShowBreakupGrid(true)
    }

    useEffect(() => {
        if(!isChargesFinializes || (isChargesFinializes && allowChargesModification)){
            handleChargesUpdate({ total: totals, data: processedData, componentTotal: totalAmt })
        }
    }, [processedData, totals, totalAmt, isChargesFinializes, allowChargesModification])

    useEffect(()=>{    
        if(consession && !isChargesFinializes || (isChargesFinializes && allowChargesModification)){
            createLocalBreakUpData()
        }
    },[consession,isChargesFinializes, allowChargesModification, paidItems ,pastIdSelected])


    const getLocalConsessionDetails = async (createBreakup = true) => {
        const consessionResponse = concessionDetails;
        if (Array.isArray(consessionResponse)) {
            const { totalPendingConsession, totalConsession, concessionAppliedList } = await getConsessionDetails(consessionResponse, totalAmt, studentCurrentYear);

            setPendingForApproval({ ...totalPendingConsession });
            setConsession({ ...totalConsession });
            setConcessionAppliedList({ ...concessionAppliedList });
            if (!createBreakup) {
                setUniqueBreakupData({
                    "29": "Registration Fee",
                    "30": "Admission Kit & Digital Access",
                    "01": "Tuition Fee",
                    "04": "Tech + Exam Fee",
                    "05": "Classroom Fee Service"
                })
            }
            setFetchingConsession(false)
            // setUpdateFinalAmt(true)

        } else {
            setConsession({
                '29': 0,
                '30': 0,
                '01': 0,
                '04': 0,
                '05': 0
            })
            setFetchingConsession(false);
        }
    }

    // useEffect(() => {
    //     if (!itemTypes.fetching && !values.masterLoading &&
    //         !values.applicationDetailsLoading && !feeBreakupData.fetching && !pastIdSelected && (!isChargesFinializes || (isChargesFinializes && allowChargesModification))) {
    //         getLocalBreakUp();
    //     }
    // }, [itemTypes, values, feeBreakupData, isChargesFinializes, pastIdSelected, allowChargesModification, concessionDetails])

    // useEffect(() => {
    //     if (!groupedBreakUp?.fetching && !pastIdSelected && (!isChargesFinializes || (isChargesFinializes && allowChargesModification))) {
    //         createLocalBreakUpData();
    //     }
    // }, [groupedBreakUp, isChargesFinializes, allowChargesModification, pastIdSelected, concessionDetails])

    // useEffect(() => {
    //     if (applicationId && totalAmt && !pastIdSelected && (!isChargesFinializes || (isChargesFinializes && allowChargesModification))) {
    //         setFetchingConsession(true)
    //         getLocalConsessionDetails();
    //     }
    //     if (isChargesFinializes) {
    //         getLocalConsessionDetails(false);
    //     }
    // }, [applicationId, totalAmt, isChargesFinializes, allowChargesModification, pastIdSelected, concessionDetails])

    useEffect(() => {
        if (!itemTypes.fetching &&
            !values.masterLoading &&
            !values.applicationDetailsLoading &&
            !feeBreakupData.fetching &&
            // !pastIdSelected &&
            (!isChargesFinializes || (isChargesFinializes && allowChargesModification))) {

            getLocalBreakUp();
        }
    }, [itemTypes.fetching, values.masterLoading, values.applicationDetailsLoading, feeBreakupData.fetching, pastIdSelected, isChargesFinializes, allowChargesModification]);

    // useEffect(() => {
    //     if (!groupedBreakUp?.fetching &&
    //         !pastIdSelected &&
    //         (!isChargesFinializes || (isChargesFinializes && allowChargesModification))) {

    //         createLocalBreakUpData();
    //     }
    // }, [groupedBreakUp?.fetching, pastIdSelected, isChargesFinializes, allowChargesModification, concessionDetails]);

    useEffect(() => {
        if ((paymentUpdated || concessioUpdated) && applicationId &&
            totalAmt &&
            // !pastIdSelected &&
            (!isChargesFinializes || (isChargesFinializes && allowChargesModification))) {
            setFetchingConsession(true);
            getLocalConsessionDetails(true);
        }
        if (isChargesFinializes) {
            getLocalConsessionDetails(false);
        }
    }, [paymentUpdated, concessioUpdated, applicationId, totalAmt, pastIdSelected, isChargesFinializes, allowChargesModification, concessionDetails]);

    useEffect(() => {
        
        if ( isChargesFinializes && !itemTypes.fetching && !allowChargesModification && finalBreakupData) {
        
            let tot = {};
            //console.log("final breakup",finalBreakupData)
            const finalBreakupWithdescription = finalBreakupData.studentChargesFeeBreakup.map((fee) => {
                //console.log(fee)
                const elementType = fee?.itemType?.slice(-2);
                tot = {
                    ...tot,
                    [elementType]: (tot?.[elementType] ?? 0) + fee.grossAmount
                }
                return {
                    ...fee,
                    desc: itemTypes?.data.filter(item => item.itemTypeId === fee.itemType)?.[0]?.description ?? '',
                    installmentNo: fee.itemType?.slice(-4, -2)
                }
            })

            const groupedData = finalBreakupWithdescription?.reduce(function (r, a) {
                r[a.installmentNo] = r[a.installmentNo] || [];
                r[a.installmentNo].push(a);
                return r;
            }, Object.create(null));

            let tempData = {};
            let totalTempData = {};
            let totalGross = 0;
            let totalConcession = 0;

            //console.log('====================================');
        console.log(groupedData);
            //console.log('====================================');

            Object.keys(groupedData).map(year => {
                
                const procArray = []
                let conessionTotal = 0;
                let netPayableTotal = 0;
                let payableTotal = 0;
                let grossTotal = 0;
                let taxTotal = 0;
                let concApprovalPendingTotal = 0;
                let netPayablePostApprovalTotal = 0;
                let cgst = 0;
                let sgst = 0;
                let utgst = 0;
                let igst = 0;
                groupedData[year].map(item => {
                    
                    //console.log("taxes", item?.taxes)
                    // const taxPercent = item?.taxes ?? 0;
                    const baseAmount = item?.grossAmount
                    const consessionAmt = item?.concessionApplied ?? '';
                    const payableAmt = item?.payableAmount ?? '';
                    const tax = item?.taxes ?? 0;
                    const netPayable = item?.netPayable ?? 0;
                    const concApprovalPending = item?.concessionApprovalPending ?? 0;
                    const netPayablePostApproval = item?.payableAmountPostApproval ?? 0;
                    const tempObj = {
                        ...item,
                        baseAmount: baseAmount,
                        consession: consessionAmt,
                        payableAmt: payableAmt,
                        netPayable: netPayable,
                        tax: tax,
                        concApprovalPending: concApprovalPending,
                        netPayablePostApproval: netPayablePostApproval,
                        taxBreakupResDtos: [
                            { taxCodeTypeDispVal: 'cgst', taxValue: item?.cgst, },
                            { taxCodeTypeDispVal: 'sgst', taxValue: item?.sgst, },
                            { taxCodeTypeDispVal: 'utgst', taxValue: item?.utgst, },
                            { taxCodeTypeDispVal: 'igst', taxValue: item?.igst }
                        ]

                    }
                    conessionTotal = conessionTotal + consessionAmt;
                    netPayableTotal = netPayableTotal + netPayable;
                    payableTotal = payableTotal + payableAmt;
                    grossTotal = grossTotal + baseAmount;
                    taxTotal = taxTotal + tax;
                    concApprovalPendingTotal = concApprovalPendingTotal + concApprovalPending;
                    netPayablePostApprovalTotal = netPayablePostApprovalTotal + netPayablePostApproval;
                    cgst = Number(cgst) + Number(item?.cgst);
                    sgst = Number(sgst) + Number(item?.sgst);
                    utgst = Number(utgst) + Number(item?.utgst);
                    igst = Number(igst) + Number(item?.igst);
                    procArray.push(tempObj);

                    totalGross = totalGross + baseAmount;
                    totalConcession = totalConcession + concApprovalPending;

                })

                tempData = { ...tempData, [year]: procArray }
                totalTempData = {
                    ...totalTempData,
                    [year]: {
                        totalGross: Math.round(grossTotal * 100) / 100,
                        conAmt: Math.round(conessionTotal * 100) / 100,
                        totalPayAmt: Math.round(payableTotal * 100) / 100,
                        totalTax: Math.round(taxTotal * 100) / 100,
                        netAmt: Math.round(netPayableTotal * 100) / 100,
                        concApprovalPendingTotal: Math.round(concApprovalPendingTotal * 100) / 100,
                        netPayablePostApprovalTotal: Math.round(netPayablePostApprovalTotal * 100) / 100,
                        cgst: parseFloat(cgst.toFixed(2)),
                        sgst: parseFloat(sgst.toFixed(2)),
                        utgst: parseFloat(utgst.toFixed(2)),
                        igst: parseFloat(igst.toFixed(2)),
                    }
                }
            })
            //console.log("tot", tempData)
            setPendingForApprovalPercentage(totalConcession ? ((totalConcession / totalGross) * 100)?.toFixed(2) : '0');
            setProcessedData({ ...tempData });
            setTotals({ ...totalTempData })
            setTotalAmt(tot)
            setShowBreakupGrid(true)
            getLocalConsessionDetails()
        }
    }, [isChargesFinializes, finalBreakupData, itemTypes, allowChargesModification, concessionDetails])

    return (
        <>
            {!open ? <FeeBreakupDetails showBreakupGris={showBreakupGris} processedData={processedData} totals={totals} setOpen={setOpen} open={open} itemTypeLabel={uniqueBreakup} values={values} businessArea={businessArea}
                term={term}
                courses={courses} isFetchingConsession={isFetchingConsession} consession={consession} pendingForApproval={pendingForApproval} pendingForApprovalPercentage={pendingForApprovalPercentage} /> : null}
            <CustomDialog
                fullScreen
                open={open}
                onClose={() => { }}
                TransitionComponent={Transition}
            >
                <FeeBreakupDetails showBreakupGris={showBreakupGris} processedData={processedData} totals={totals} setOpen={setOpen} open={open} itemTypeLabel={uniqueBreakup} values={values} businessArea={businessArea}
                    term={term}
                    courses={courses} isFetchingConsession={isFetchingConsession} consession={consession} pendingForApproval={pendingForApproval} pendingForApprovalPercentage={pendingForApprovalPercentage} />
                {/* </Backdrop> */}
            </CustomDialog>
        </>
    )
}

export default FeeBreakup