import React, {useEffect, useState} from 'react';
import {Card, CardHeader, Input, Container, Button, Table, Row,} from 'reactstrap';
// import "views/pages/manage/addressType/node_modules/react-sliding-pane/dist/react-sliding-pane.css";
import {failureToast} from '../../common/utils/methods/toasterFunctions/function';
import {MasterHeaderAuto} from '../../common/commonComponents/masterHeader/masterHeader';
import {
    fetchDetails,
    getDispValForReactSelectByOneID,
    NewRow
} from '../../common/utils/methods/commonMethods/utilityMethod'
import {
    getAPI,
    masterServiceBaseUrl,
    successToast,
    putpost,
    putpost2,
} from 'services/http';
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import moment from 'moment';
import IsCoreMaster from "../../common/commonComponents/coreMaster";

var _ = require('lodash');
const EpmrStatusTable = (props) => {
    const {
        apiEndPoints,
        serverData,
        setSearch,
        resetSearch,
        isSaveVisible,
        setisSaveVisible,
        action,
        pagination,
        setApiloader,
        setclassArray
    } = props;
    const [tableDataKey, settableDataKey] = useState('tableDataKey');
    const [searchStr, setSearchStr] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [descValue,setDescValue] = useState('');
    const [dispValue,setDispValue] = useState('');
    const [key,setKey] = useState('');
    const addNewClass = () => {
        props.setclassArray([
            {
                editMode: true,
                __status: '__new',
                status: 'ACTIVE',
                epmrStatusDesc: null,
                epmrStatusDispValue: null,
                updatedDate: null,
                effectiveDate: moment().format('DD-MM-YYYY')
            },
            ...props.classArray,
        ]);
    };
    const headerList = [
        {name: 'KEY', isRequired: true},
        {name: 'DESCRIPTION', isRequired: true},
        {name: 'DISPLAY VALUE', isRequired: true},
        {name: 'EFFECTIVE DATE'},
        {name: 'Status'},
        {name: 'Action'},
    ];
    const listToRender = [
        'epmrStatusKey',
        'epmrStatusDesc',
        'epmrStatusDispValue',
        'effectiveDate',
        'status'
    ];
    const saveAll = (cb) => {
        var _tempArray = props.classArray;
        var isValid = true;
        _tempArray.forEach((el, index) => {
            if (el['editMode']) {
                if((!el['epmrStatusKey'] || !el['epmrStatusKey'].trim()) || (!key || !key.trim())){
                    isValid = false;
                    failureToast('Please Enter Key');
                    return;
                }else if ((!el['epmrStatusDesc'] || !el['epmrStatusDesc'].trim()) || (!descValue || !descValue.trim())) {
                    isValid = false;
                    failureToast('Please Enter Description');
                    return;
                } else if ((!el['epmrStatusDispValue'] || !el['epmrStatusDispValue'].trim()) || (!dispValue) || !dispValue.trim()) {
                    isValid = false;
                    failureToast('Please Enter Display Value');
                    return;
                }
                if (!isValid) {
                    return;
                }
                if (_tempArray[index]['__status'] == '__new') {
                    let rowData = _tempArray[index];
                    let tempObject = {
                        // epmrStatusKey : rowData.epmrStatusKey ,
                        epmrStatusKey : key ,
                        // epmrStatusDesc: rowData.epmrStatusDesc?.trim(),
                        epmrStatusDesc: descValue.trim(),
                        // epmrStatusDispValue: rowData.epmrStatusDispValue?.trim(),
                        epmrStatusDispValue: dispValue.trim(),
                        status: rowData.status,
                    }
                    putpost(
                        apiEndPoints.create,
                        (data) => {
                            successToast(data['message']);
                            setisSaveVisible(false);
                            // setUpdateKey(new Date());
                            props.fetchClasses(apiEndPoints.getAll);
                        },
                        (data) => {
                            failureToast(data['message']);
                        },
                        tempObject,
                        'post'
                    );
                } else {
                    let rowData = _tempArray[index];
                    let tempObject = {
                        id: rowData.id,
                        // epmrStatusKey : rowData.epmrStatusKey ,
                        epmrStatusKey : key ,
                        // epmrStatusDesc: rowData.epmrStatusDesc?.trim(),
                        epmrStatusDesc: descValue.trim(),
                        // epmrStatusDispValue: rowData.epmrStatusDispValue?.trim(),
                        epmrStatusDispValue: dispValue.trim(),
                        status: rowData.status,
                    }
                    putpost(
                        `${apiEndPoints.update}/${_tempArray[index].id}`,
                        (data) => {
                            successToast(data['message']);
                            setisSaveVisible(false);
                            // delete _tempArray[index]['editMode']
                            // props.setclassArray(_tempArray)
                            props.fetchClasses(apiEndPoints.getAll);
                            settableDataKey(new Date().getMilliseconds());
                        },
                        (data) => {
                            failureToast(data['message']);
                        },
                        tempObject,
                        'put'
                    );
                }
            } else {
            }
        });
    };
    const newRow = () => {
        if (isSaveVisible) {
            failureToast('Please Delete or Save current changes');
        } else {
            let cb = () => {
                addNewClass();
                setisSaveVisible(true);
                setKey('');
                setDispValue('')
                setDescValue('')
                // settableDataKey(new Date().getMilliseconds())
            };
            var _tempArray = props.classArray;
            _tempArray = _.sortBy(_tempArray, [
                function (o) {
                    return o.value;
                },
            ]);
            props.setclassArray(_tempArray);
            setisSaveVisible(false);
            cb();
        }
    };
    const editRow = (index) => {
        if (isSaveVisible) {
            failureToast('Please Delete or Save current changes');
        } else {
            let cb = () => {
                var _tempArray = props.classArray;
                _tempArray[index]['editMode'] = true;
                setKey(_tempArray[index]['epmrStatusKey']);
                setDescValue(_tempArray[index]['epmrStatusDesc']);
                setDispValue(_tempArray[index]['epmrStatusDispValue']);
                props.setclassArray(_tempArray);
                setisSaveVisible(true);
            };
            var _tempArray = props.classArray;
            _tempArray = _.sortBy(_tempArray, [
                function (o) {
                    return o.value;
                },
            ]);
            cb();
        }
    };
    const deleteRow = (index) => {
        if (props.classArray[index]['__status'] == '__new') {
            let n = props.classArray;
            n.splice(index, 1);
            props.setclassArray(n);
            setisSaveVisible(false);
            settableDataKey(new Date().getMilliseconds());
        } else {
            if (
                window.confirm('Are you sure you want to delete this?')
            ) {
                putpost2(
                    `${apiEndPoints.delete}/${props.classArray[index].id}`,
                    (data) => {
                        successToast(data['message']);
                        if (props.classArray.length == 1) {
                            // props.previousPage();
                            props.fetchClasses(apiEndPoints.getAll);
                        } else {
                            var _tempArray = props.classArray;
                            if (_tempArray[index]['editMode']) {
                                setisSaveVisible(false);
                            }
                            _tempArray.splice(index, 1);
                            props.setclassArray(_tempArray);
                        }
                        settableDataKey(new Date().getMilliseconds());
                        props.fetchClasses(apiEndPoints.getAll);
                    },
                    (data) => {
                        failureToast(data['message']);
                    },
                    'PUT'
                );
            }
        }
    };

    const updateKey = (index, value, key) => {
        var _tempArray = props.classArray;
        _tempArray[index][key] = value;
        setclassArray(_tempArray);
    };
    const filterData = (str) => {
        const newData =
            serverData &&
            serverData.filter((obj) =>
                Object.values(obj).some(
                    (val) =>
                        val && val.toString().toLowerCase().includes(str.toLowerCase())
                )
            );
        setSearch(newData);
    };
    useEffect(() => {
        if (!searchStr || !searchStr.trim()) {
            resetSearch();
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
            filterData(searchStr);
        }
    }, [searchStr]);
    return (
        <>
            <Container className="mt-3" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            {/*<CardHeader className="border-0 d-flex justify-content-between">*/}
                            <CardHeader className="border-0">
                                <h3 className="mb-0  floatLeft">
                                    EPMR Status
                                </h3>
                                {/*<IsCoreMaster master={'operationalZone'} masterDisplayValue={'Operational Zone'}*/}
                                {/*              setApiLoader={setApiloader}/>*/}
                                <Button
                                    disabled={isSaveVisible || isDisabled}
                                    color="info"
                                    size="sm"
                                    type="button"
                                    className={
                                        'floatRight mx-1 ' +
                                        (isSaveVisible || isDisabled ? ' btn-dark' : null)
                                    }
                                    onClick={() => {
                                        newRow();
                                    }}
                                    data-testid="addNewPersonType"
                                >
                                    New EPMR Status
                                </Button>
                            </CardHeader>
                            <div id="questionTable" className="table-responsive">
                                <Table className="align-items-center table-flush tableLayout">
                                    <MasterHeaderAuto headerList={headerList}/>
                                    <tbody className="list" key={tableDataKey}>
                                    {props.classArray
                                        ? props.classArray.map((el, index) =>
                                            !el['editMode'] ? (
                                                //
                                                <>
                                                    <NewRow data={el} listToBeRendered={listToRender} index={index}
                                                            currentPage={pagination.currentPage}
                                                    >
                                                        <td className="text-center ">
                                                            <Button
                                                                color="info"
                                                                size="sm"
                                                                type="button"
                                                                disabled={props.isViewOnly}
                                                                onClick={() => editRow(index)}
                                                            >
                                                                <i className="fas fa-edit"/>
                                                            </Button>
                                                            {/*<Button*/}
                                                            {/*    color="info"*/}
                                                            {/*    size="sm"*/}
                                                            {/*    type="button"*/}
                                                            {/*    disabled={props.isViewOnly}*/}
                                                            {/*    onClick={() => deleteRow(index)}*/}
                                                            {/*    // data-testid="deletebtn"*/}
                                                            {/*>*/}
                                                            {/*    <i className="fas fa-trash"/>*/}
                                                            {/*</Button>*/}
                                                        </td>
                                                    </NewRow>
                                                </>
                                            ) : (
                                                <tr key={index + '-class'}>
                                                    <td className="text-center p-2 mx-1">
                                                        {/*{el.id ? el['operationalZoneKey'] :*/}
                                                            <Input
                                                                maxLength={1}
                                                                disabled={el.id}
                                                                // value={el['epmrStatusKey']}
                                                                value={key}
                                                                data-testid='epmrStatusKey'
                                                                onChange={(e)=>{
                                                                    updateKey(index, e.target.value.toUpperCase(), 'epmrStatusKey');
                                                                    setKey(e.target.value.toUpperCase());
                                                                }}
                                                                placeholder="Key"
                                                                className="tdInput"
                                                            />
                                                        {/*}*/}
                                                        {/*{(((pagination.currentPage - 1) * 10) + index + 1)}*/}
                                                    </td>
                                                    <td className="text-center p-2 mx-1">
                                                        <Input
                                                            maxLength="50"
                                                            // value
                                                            // value={el['epmrStatusDesc']}
                                                            disabled={el.id}
                                                            value={descValue}
                                                            data-testid='epmrStatusDesc'
                                                            onChange={(e) => {
                                                                updateKey(index, e.target.value.replace(/[^a-zA-Z 0-9/_-]/g, ''), 'epmrStatusDesc');
                                                                setDescValue(e.target.value.replace(/[^a-zA-Z 0-9/_-]/g, ''));
                                                            }}
                                                            placeholder="Description value"
                                                            className="tdInput"
                                                        />
                                                    </td>
                                                    <td className="text-center p-2 mx-1">
                                                        <Input
                                                            maxLength="10"
                                                            // value={el['epmrStatusDispValue']}
                                                            value={dispValue}
                                                            disabled={el.id}
                                                            data-testid='epmrStatusDispValue'
                                                            onChange={(e) => {
                                                                updateKey(index, e.target.value.replace(/[^a-zA-Z 0-9/_-]/g, ''), 'epmrStatusDispValue');
                                                                setDispValue( e.target.value.replace(/[^a-zA-Z 0-9/_-]/g, ''));
                                                            }}
                                                            placeholder="Display value"
                                                            className="tdInput"
                                                        />
                                                    </td>
                                                    <td className="text-center p-2 mx-1">

                                                        {el.id ?
                                                            moment(el['effectiveDate']).format('DD-MM-YYYY')
                                                            :
                                                            <Input
                                                                maxLength="50"
                                                                disabled={true}
                                                                defaultValue={el['effectiveDate']}
                                                                data-testid='operationalZoneEffectiveDate'
                                                                placeholder="Effective Date"
                                                                className="tdInput"
                                                            />}
                                                    </td>
                                                    <td className="text-center p-2 mx-1">
                                                        <label className="custom-toggle mx-auto ml-2">
                                                            <input
                                                                checked={
                                                                    el.status === 'ACTIVE' ? true : false
                                                                }
                                                                type="checkbox"
                                                                id={el.id}
                                                                onChange={(e) => {
                                                                    settableDataKey(
                                                                        new Date().getMilliseconds()
                                                                    );
                                                                    let n = 'ACTIVE';
                                                                    if (!e.target.checked) {
                                                                        n = 'INACTIVE';
                                                                    }
                                                                    updateKey(index, n, 'status');
                                                                }}
                                                                data-testid='personTypeStatus'
                                                            />
                                                            <span
                                                                style={{width: '72px'}}
                                                                className="custom-toggle-slider rounded-circle activeToggle"
                                                                data-label-off="Inactive"
                                                                data-label-on="Active"
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="text-center" data-testid="saveConcessionPersonType">
                                                        {isSaveVisible ? (
                                                            <>
                                                                {' '}
                                                                <Button
                                                                    color="info"
                                                                    size="sm"
                                                                    type="button"
                                                                    className="mx-1"
                                                                    onClick={() => {
                                                                        saveAll();
                                                                    }}
                                                                >
                                                                    Save
                                                                </Button>
                                                                <Button
                                                                    color="danger"
                                                                    size="sm"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        let n = props.classArray;
                                                                        if (n[index]['__status'] == '__new') {
                                                                            n.splice(index, 1);
                                                                        } else {
                                                                            n[index]['editMode'] = false
                                                                            props.fetchClasses(apiEndPoints.getAll);
                                                                        }
                                                                        props.setclassArray(n);
                                                                        setisSaveVisible(false);
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <i className="fas fa-times"/>
                                                                </Button>{' '}
                                                            </>
                                                        ) : (
                                                            <Button
                                                                color="info"
                                                                size="sm"
                                                                type="button"
                                                                onClick={() => {
                                                                    deleteRow(index);
                                                                }}
                                                            >
                                                                {' '}
                                                                <i className="fas fa-trash"/>
                                                            </Button>
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        )
                                        : null}
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};
export default EpmrStatusTable;
