export const tabListComponentStyles = {
  backgroundColor: '#FFFFFF',
  '& .MuiTab-root': {
    color: '#757575',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none'
  },
  '& .MuiTab-root.Mui-selected': {
    color: '#00B0F5',
    borderBottom: '3px solid #00B0F5'
  },
  '& .MuiTabs-indicator': {
    display: 'none'
  }
}