import React, { useState, useEffect } from "react";
import BranchAccountTable from './branchAccount';
import Loader from 'react-loader-spinner'
import { masterServiceBaseUrl,failureToast, getAPI } from 'services/http';
import { Card, CardHeader, Container, Row ,Col,Button} from "reactstrap";
import { GetPagination } from '../../../common/pagination';

const CashierOffice = (props) => {const {
  businessUnit,
  nextStep = () => {},
  previousStep = () => {},
  isNew = true,
  formId,
  isViewOnly = false,
  houseBankMastersData,
  action,
  isPrevious,
  appendAllStepData=()=>{},
  saveStepsCompleted=()=>{}
} = props;

  const [isNextDisable, setIsNextDisable] = useState(false);
  const [bankAccountIdKeys, setBankAccountIdKeys] = useState([]);
  const [bankHouseDetails, setBankHouseDetails] = useState([]);
  const [businessUnitType, setBusinessUnitType] = useState([]);
  const [pinelabsPaymentModeIds, setPinelabsPaymentModeIds] = useState([]);
  const [classArray, setclassArray] = useState([])
  const [apiloader, setapiloader] = useState(false)
  const [state, setstate] = useState([]);
  const [ tableData, setTableData ] = useState( [] );
  const [isDisabled,setIsDisabled] = useState( false );
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null
  })
  const pageSize = 10
  const fetchClasses = async (url) => {
    setapiloader(true)
    getAPI(url, (data) => {
      setstate(data['data'])
      data['data'].length > 0 ? setIsNextDisable(true) :  setIsNextDisable(false)
      setTableData(data['data']);
      let n = pagination
      if (data['data'].length > pageSize) {
        n['nextPage'] = n.currentPage + 1
      }
      if (n.currentPage > 1) {
        n.previousPage = n.currentPage - 1
      }
      n['totalPage'] = Math.floor(data['data'].length / pageSize)
      if (data['data'].length % pageSize != 0) {
        n['totalPage'] += 1
      }
      setpagination(n)
      setapiloader(false)
      setclassArray(data['data'].slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || [])
    }, (data) => { failureToast(data['message']) })
  }
  const nextPage = () => {
    setclassArray(state.slice((pagination.currentPage + 1) * (pageSize) - pageSize, (pagination.currentPage + 1) * pageSize))
    let n = pagination
    n['currentPage'] = n['currentPage'] + 1
    n['previousPage'] = n.currentPage
    setpagination(n)
  }
  const firstPage = () => {
    setclassArray(state.slice(1 * (pageSize) - pageSize, 1 * pageSize))
    let n = pagination
    n['currentPage'] = 1
    n['previousPage'] = null
    setpagination(n)
  }
  const lastPage = () => {
    setclassArray(state.slice((pagination.totalPage) * (pageSize) - pageSize, (pagination.totalPage) * pageSize))
    let n = pagination
    n['currentPage'] = n.totalPage
    n['previousPage'] = n.totalPage - 1
    setpagination(n)
  }
  const previousPage = () => {
    if (pagination.currentPage != 1) {
      setclassArray(state.slice((pagination.currentPage - 1) * (pageSize) - pageSize, (pagination.currentPage - 1) * pageSize))
      let n = pagination
      n['currentPage'] = n['currentPage'] - 1
      if (n['currentPage'] == 1) {
        n['previousPage'] = null
      } else {
        n['previousPage'] = n.currentPage
      }
      setpagination(n)
    }
  }

  const fetchHoueBankAccountDetails = async (url) => {
    setapiloader(true)
    getAPI(url, (data) => {
      const BankAccountNumber = data.data.map(
        (data,id) => {          
            return {
              id: id,
              text: data
            };
        }
      );
      setBankHouseDetails( BankAccountNumber );
      setapiloader( false );
    }, ( data ) => { failureToast( data[ 'message' ] ); setapiloader( false ); })
  }

  const fetchBankAccountIdKeys = async (url) => {
    setapiloader(true)
    getAPI(url, (data) => {
      const BankAccountId = data.data.map(
        (data,id) => {          
            return {
              id: data,
              text: data
            };
        }
      );
      setBankAccountIdKeys( BankAccountId );
      setapiloader( false );
    }, ( data ) => { failureToast( data[ 'message' ] ); setapiloader( false ); })
  }

  const fetchBusinessUnitType = async (url) => {
    setapiloader(true)
    getAPI(url, (data) => {
      const businessUnitTypeList = data.data.map(
        data => {
          const {
            id,
            businessUnitTypeDispValue,
            status
          } = data;
          if (status === 'ACTIVE') {
            return {
              id: id,
              text: businessUnitTypeDispValue
            };
          }
        }
      );
      setBusinessUnitType( businessUnitTypeList );
      setapiloader(false)
    }, ( data ) => { failureToast( data[ 'message' ] );setapiloader(false) })
  }


  const fetchPinelabsPaymentModeId = async (url) => {
    setapiloader(true)
    getAPI(url, (data) => {
      const pinelabsPaymentModeIds = data.data.map(
        data => {
          const {
            id,
            pinelabsPaymentModeDisplayValue,
            status
          } = data;
          if (status === 'ACTIVE') {
            return {
              id: id,
              text: pinelabsPaymentModeDisplayValue
            };
          }
        }
      );
      setPinelabsPaymentModeIds( pinelabsPaymentModeIds );
      setapiloader(false)
    }, ( data ) => { setapiloader( false ); failureToast(data['message']) })
  }

  const performPaginationFormData = () => {
   
    setstate( houseBankMastersData );
      setTableData(houseBankMastersData);
      let n = pagination
      if (houseBankMastersData.length > pageSize) {
        n['nextPage'] = n.currentPage + 1
      }
      if (n.currentPage > 1) {
        n.previousPage = n.currentPage - 1
      }
      n['totalPage'] = Math.floor(houseBankMastersData.length / pageSize)
      if (houseBankMastersData.length % pageSize != 0) {
        n['totalPage'] += 1
      }
      setpagination(n)
      setIsNextDisable(true)
      setclassArray(houseBankMastersData.slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || [])
      // console.log('houseBankMastersDatahouseBankMastersData',classArray)
  }

  useEffect( () => {
    if ( (action === 'edit' || action === 'view') && houseBankMastersData ) {
     performPaginationFormData();
      setIsDisabled(true);
    }
    

    fetchHoueBankAccountDetails(masterServiceBaseUrl + '/houseBank/getActiveHouseBank');
    fetchBankAccountIdKeys(masterServiceBaseUrl + `/houseBank/getBankAccountIdValue?groupCode=${props.groupCode}&companyCode=${props.companyCode}`);
    fetchBusinessUnitType(masterServiceBaseUrl + '/businessUnitType/getAllActiveBusinessUnitType');
    fetchPinelabsPaymentModeId( masterServiceBaseUrl + '/pinelabs/payment/modes' );
  }, [])




  useEffect(() => {
    if(props.isPrevious && props.formId){
      setapiloader(true)
      fetchClasses(`${masterServiceBaseUrl}/setupHouseBank/getAllSetupHouseBankByBusinessArea/${props.formId}`)
   }
  }, [isPrevious,props.formId])

  const toNextStep = () => {
    !props.isPrevious && props.isNew  && props.saveStepsCompleted(7)
    nextStep()
  }

  useEffect(()=>{
    return()=>{
      if(state.length){
        !props.isPrevious && props.isNew  && props.saveStepsCompleted(7)
      }      
    }    
  },[state])


  return (
    <>
      {apiloader ? <div className="mx-auto text-center py-5 my-5" style={ { height: "100vh" } }><Loader
        type="Rings"
        color="#00BFFF"
        height={ 70 }
        width={ 70 } /></div> :
        <Container className="mt-3" fluid>
          <Row>
            <div className="col">
              <Card className='p-0 mb-0'>
                { action === 'new' ?
                  <CardHeader className='pl-3'>
                  <h3 className="mb-0 floatLeft">Setup House Bank</h3>
                </CardHeader> :null}
                <BranchAccountTable previousPage={ previousPage }
                  fetchClasses={ fetchClasses } setclassArray={ setclassArray }
                  classArray={ classArray }
                  tableData={ tableData } pagination={ pagination }
                  setstate={ setstate } bankAccountIdKeys={ bankAccountIdKeys }
                  bankHouseDetails={ bankHouseDetails }
                  businessAreaId={ props.businessAreaId }
                  businessUnitType={ businessUnitType }
                  pinelabsPaymentModeIds={ pinelabsPaymentModeIds }
                  setIsNextDisable={ setIsNextDisable }
                  groupCode={ props.groupCode }
                  companyCode={ props.companyCode }
                  setpagination={ setpagination }
                  isDisabled={ isDisabled }
                  setIsDisabled={ setIsDisabled }
                  action={action}
                  />
                { pagination.totalPage > 1 ?
                  <GetPagination fetchClasses={ fetchClasses } pagination={ pagination } previousPage={ previousPage } firstPage={ firstPage }
                    lastPage={ lastPage } nextPage={ nextPage } setpagination={ setpagination } /> : null
                }
              </Card>
              <Card style={ { marginTop: '20px' } }>
                {(action !== 'edit' && action !== 'view') ? <Row>
                  <Col md={ 6 }>
                    <Button
                      color="primary"
                      type="button"
                      onClick={ () => {
                        previousStep();
                      } }
                    >
                      Previous
                      </Button>
                  </Col>
                  <Col md={ 6 }>
                    <Button
                      disabled={ !isNextDisable }
                      color="primary"
                      className={ 'floatRight ml-auto' }
                      type="button"
                      onClick={ () => {
                        toNextStep();
                      } }
                    >
                      Next
                      </Button>
                  </Col>
                </Row>:null}
              </Card>
            </div>
          </Row>
        </Container> }
    </>
  );
}
export default CashierOffice;
