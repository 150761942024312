import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import {
  Card,
  CardHeader,
  Input,
  Container,
  Button,
  Table,
  Row,
} from "reactstrap";
import { useState } from "react";
// import "views/pages/manage/addressType/node_modules/react-sliding-pane/dist/react-sliding-pane.css";
import { failureToast, successToast } from "../../../common/utils/methods/toasterFunctions/function";
import { MasterHeaderAuto } from "../../../common/commonComponents/masterHeader/masterHeader";
import { NewRow, checkPermission } from "../../../common/utils/methods/commonMethods/utilityMethod";
import {
  getAPI,
  masterServiceBaseUrl,
  putpost,
  putpost2,
} from "services/http";
import moment from "moment";
import { ToExcel } from "../../../common/commonComponents";
import { RolePermissions } from 'appContext'
import { PermissionContext } from 'appContext'
import { pages } from "views/pages/manage/common/constant";
import StatusCellComponent from './StatusCellComponent'

const excelDetails = {
  "Business Group": "Business Group",
  "Company Code": "Company Code",
  Zone: "Zone",
  Region: "Region",
  "Business Area": "Business Area",
  "Building Name": "Building Name",
  Address: "Building Address",
  City: "City",
  State: "State",
  Country: "Country",
  Pincode: "Pincode",
  "Floor Name": "Floor Name",
  "Floor Code": "Floor Code",
  "Floor Status": "Floor Status",
  "Room Name": "roomName",
  "Room Code": "roomCode",
  "Room Capacity": "roomCapacity",
  "Room Status": "status",
};

var _ = require("lodash");

const RoomTable = (props) => {
  const {
    serverData,
    setSearch,
    resetSearch,
    isSaveVisible,
    setisSaveVisible,
    action,
    floorMasterId,
    buildingHeraderData,
  } = props;
  const userPermissions = RolePermissions(useContext(PermissionContext), pages['buildingmaster']['id']);

  const [tableDataKey, settableDataKey] = useState("tableDataKey");
  const [updatedKey, setUpdateKey] = useState(new Date());
  const [searchStr, setSearchStr] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: "__new",
        status: "ACTIVE",
        roomCapacity: "",
        examCapacity: "",
        roomCode: "",
        roomName: "",
      },
      ...props.classArray,
    ]);
  };

  const headerList = [
    { name: "Room Name", isRequired: true },
    { name: "Room Code", isRequired: true },
    { name: "Room Capacity", isRequired: true },
    { name: "Status", isRequired: true },
    { name: "Actions" },
  ];

  const listToRender = [
    "roomName",
    "roomCode",
    "roomCapacity",
    "status",
  ];

  const saveAll = (cb) => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if (el["editMode"]) {
        if (!el["roomName"] || !el["roomName"].trim()) {
          isValid = false;
          failureToast("Please Enter Room Name");
          return;
        }
         else if (!el["roomCapacity"]) {
          isValid = false;
          failureToast("Please Enter Class Capacity");
          return;
        }
        if (!isValid) {
          return;
        }
        if (_tempArray[index]["__status"] == "__new") {
          putpost(
            masterServiceBaseUrl + "/roomMaster/createRoomMaster",
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              setUpdateKey(new Date());
              props.fetchClasses(
                `${masterServiceBaseUrl}/roomMaster/getRoomMasterByFloorMasterId/${props.floorMasterId}`
              );
            },
            (data) => {
              failureToast(data["message"]);
            },
            { ..._tempArray[index], floorMasterId: floorMasterId },
            "post"
          );
        } else {
          putpost(
            masterServiceBaseUrl +
              "/roomMaster/updateRoomMaster/" +
              _tempArray[index].id,
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              // delete _tempArray[index]['editMode']
              // props.setclassArray(_tempArray)
              props.fetchClasses(
                `${masterServiceBaseUrl}/roomMaster/getRoomMasterByFloorMasterId/${props.floorMasterId}`
              );
              settableDataKey(new Date().getMilliseconds());
              // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
            },
            (data) => {
              failureToast(data["message"]);
            },
            _tempArray[index],
            "put"
          );
        }
      } else {
      }
    });
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
        // settableDataKey(new Date().getMilliseconds())
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      // console.log(_tempArray);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      cb();
    }
  };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]["editMode"] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };
  const deleteRow = (index) => {
    if (props.classArray[index]["__status"] == "__new") {
      let n = props.classArray;
      n.splice(index, 1);
      props.setclassArray(n);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
    } else {
      if (window.confirm("Are you sure you want to delete this Room?")) {
        putpost2(
          masterServiceBaseUrl +
            "/roomMaster/deleteRoomMaster/" +
            props.classArray[index].id,
          (data) => {
            successToast(data["message"]);
            props.fetchClasses(
              `${masterServiceBaseUrl}/roomMaster/getRoomMasterByFloorMasterId/${props.floorMasterId}`
            );
            settableDataKey(new Date().getMilliseconds());
          },
          (data) => {
            failureToast(data["message"]);
          },
          "PUT"
        );
      }
    }
  };

  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
    setUpdateKey(new Date());
  };

  const dataForExcel = props.serverData?.map((item) => {
    return { ...item, ...buildingHeraderData };
  });

  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0 d-flex justify-content-between">
                <h3 className="mb-0 ">Rooms</h3>
                <div>
                  {!isSaveVisible && props.classArray?.length ? (
                    <ToExcel
                      data={dataForExcel}
                      detailObj={excelDetails}
                      name="Room"
                      isSaveVisible={isSaveVisible}
                    />
                  ) : null}
                  {checkPermission(userPermissions, 'C') && <Button
                    disabled={isSaveVisible || isDisabled}
                    color="info"
                    size="sm"
                    type="button"
                    className={
                      "floatRight" +
                      (isSaveVisible || isDisabled ? " btn-dark" : null)
                    }
                    onClick={() => {
                      newRow();
                    }}
                    data-testid="addNewRoom"
                  >
                    New Room
                  </Button>}
                </div>
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush ">
                  <MasterHeaderAuto headerList={headerList} />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                          !el["editMode"] ? (
                            //
                            <>
                              <NewRow data={el} listToBeRendered={listToRender}>
                                <td className="text-center  white-breakSpace">
                                  {checkPermission(userPermissions, 'U') && <Button
                                    color="info"
                                    size="sm"
                                    type="button"
                                    disabled={props.isViewOnly}
                                    onClick={() => editRow(index)}
                                    // data-testid="editButton"
                                  >
                                    <i className="fas fa-edit" />
                                  </Button>}
                                  {checkPermission(userPermissions, 'D') && <Button
                                    color="info"
                                    size="sm"
                                    type="button"
                                    disabled={props.isViewOnly}
                                    onClick={() => deleteRow(index)}
                                    // data-testid="deletebtn"
                                  >
                                    <i className="fas fa-trash" />
                                  </Button>}
                                </td>
                              </NewRow>
                            </>
                          ) : (
                            <tr key={index + "-class"}>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength="50"
                                  defaultValue={el["roomName"]}
                                  data-testid="roomName"
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value.trim(),
                                      "roomName",
                                      true
                                    );
                                  }}
                                  placeholder="Room Name"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                              
                              </td>

                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength="4"
                                  value={el["roomCapacity"]}
                                  data-testid="roomCapacity"
                                  onKeyPress={() => {
                                    if (isNaN) {
                                      return;
                                    }
                                  }}
                                  onChange={(e) => {
                                    if (isNaN(e.target.value)) {
                                      e.preventDefault();
                                      return;
                                    } else {
                                      updateKey(
                                        index,
                                        e.target.value,
                                        "roomCapacity",
                                        true
                                      );
                                    }
                                  }}
                                  placeholder="Class Capacity"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <StatusCellComponent el={el} index={index} updateKey={updateKey} settableDataKey={settableDataKey} />
                              </td>
                              <td
                                className="text-center"
                                data-testid="saveConcessionRoom"
                              >
                                {" "}
                                <Button
                                  color="info"
                                  size="sm"
                                  type="button"
                                  className="mx-1"
                                  onClick={() => {
                                    saveAll();
                                  }}
                                >
                                  Save
                                </Button>
                                <Button
                                  color="danger"
                                  size="sm"
                                  type="button"
                                  onClick={() => {
                                    let n = props.classArray;
                                    if (n[index]["__status"] == "__new") {
                                      n.splice(index, 1);
                                    } else {
                                      // n[index]['editMode'] = false
                                      props.fetchClasses(
                                        `${masterServiceBaseUrl}/roomMaster/getRoomMasterByFloorMasterId/${props.floorMasterId}`
                                      );
                                    }
                                    props.setclassArray(n);
                                    setisSaveVisible(false);
                                  }}
                                >
                                  {" "}
                                  <i className="fas fa-times" />
                                </Button>{" "}
                              </td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default withRouter(RoomTable);
