import React, { useContext, useState } from "react";
import ALoader from "views/pages/manage/common/a-loader";
import LectureList from "./LectureList";
import HeaderComponent from "./../academicTimetableListView/HeaderComponent";
import LectureSubHeader from "./LectureSubHeader";
import { TimetableContext } from "../contextApi/TimetableContext";

// this is for view and edit timetable
const PlanLecturesV2 = ({
  setOpenForm,
  formData,
  formHandler,
  batchDetails,
  lectures,
  setLectures,
  planData,
  ...props
}) => {
  const OPEN_MODE = props?.mode;
  const [apiLoader, setApiLoader] = useState(false);
  const [isShowPastLecture, setShowPastLecture] = useState(false);
  const {
  
    pastLectures,
    totalPastTestsCount,
    pastTestPage,
    setPastTestPage,
  } = useContext(TimetableContext);
  

  const onLectureUpdate = (lecturesData, index) => {
    setLectures(lecturesData);
  };
  const publishTimetable = () => {
    props.publishTimetable(props.planTests, lectures);
  };

  return (
    <div>
      {apiLoader && <ALoader position={"fixed"} />}
      {/* <HeaderComponent
        formData={planData}
        plansDropdown={props?.plansDropdown}
        formHandler={editFormHandler}
        batchDetails={batchDetails}
        openMode={OPEN_MODE}
        setOpenForm={setOpenForm}
        tests={props?.tests}
        setTests={props?.setTests}
        lectures={lectures}
        setLectures={setLectures}
        fetchNewLectures={props?.fetchNewLectures}
        setIsNeedToPublish={props.setIsNeedToPublish}

      /> */}
      <LectureSubHeader
        openMode={OPEN_MODE}
        setOpenForm={setOpenForm}
        publishTimetable={publishTimetable}
        isNeedToPublish={props?.isNeedToPublish}
        onEditSave={props?.onEditSave}
        lectures={lectures}
        formData={planData}
        publishStatusTimetableHandler={props.publishStatusTimetableHandler}
        batchDetails={batchDetails}
        setLectures={setLectures}
        planSubjects={props?.planSubjects}
        roomsDropdown={props.roomsDropdown}
        subjectFacultyDropdown={props?.subjectFacultyDropdown}
        isShowPastLecture = {isShowPastLecture}
      />

      {!PlanLecturesV2 ? (
        <LectureList
          lectures={lectures}
          updateLecturesArray={onLectureUpdate}
          setOpenForm={setOpenForm}
          roomsDropdown={props.roomsDropdown}
          // testUpdateHandler={testUpdateHandler}
          publishTimetable={props?.publishTimetable}
          openMode={OPEN_MODE}
          currentRoom={formData?.room}
          formData={planData}
          setLectures={setLectures}
          batchDetails={batchDetails}
          {...props}
        />
      ) : (
        <LectureList
          lectures={lectures}
          updateLecturesArray={onLectureUpdate}
          setOpenForm={setOpenForm}
          roomsDropdown={props.roomsDropdown}
          // testUpdateHandler={testUpdateHandler}
          publishTimetable={props?.publishTimetable}
          openMode={OPEN_MODE}
          currentRoom={formData?.room}
          formData={planData}
          setLectures={setLectures}
          batchDetails={batchDetails}
          {...props}
        />
      )}
    </div>
  );
};

export default PlanLecturesV2;
