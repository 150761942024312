import React from "react";
import ActionsCellComponent from "./ActionsCellComponent";

import {
  MaterialToBeIssued,
  getTableTableHeader,
  PsidDisplay,
  ValueWithTooltip,
  IssuedColumn,
  CheckBoxColumn,
  ViewEditBarCode,
  ValueWithOutTooltip,
  CourseDetails,
} from "./TableColumn";
import styles from "../styles.module.scss";
import NoRowOverlay from "../../../../masters/holidayMaster/NoRowOverlay";
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";
import APopover from "views/pages/manage/common/a-popover";
import { ReactComponent as EyeIcon } from "assets/img/svg/eye_open.svg";

import { IssuedMaterialData } from "./IssuedStockList";
import ApplicationIdPopup from "./ApplicationIdPopup";
import {
  generateQuantityDropdown,
  getQuantityDropdownItem,
} from "../../stockReturn/constant";
import { gridSelectStyles } from "../../stockReturn/StockReturnDialog";

export const MaterialDetailsV2 = ({ dataRows, isAllReturn = false }) => {
  if (isAllReturn || !dataRows?.length) return "";
  return (
    <APopover
      buttonComponent={(onClick, styles) => (
        <EyeIcon onClick={isAllReturn ? () => {} : onClick} />
      )}
      menuComponent={(closeForm) => (
        <IssuedMaterialData hideBarcode={true} dataRows={dataRows} />
      )}
    />
  );
};

const TableHeader = ({
  issueStockCheckBoxHandler,
  selectedStudentIds,
  ...rest
}) => {
  return (
    <div
      className={`${styles["custom-table-parent"]} ${styles["header-column"]}`}
    >
      <div>
        <CheckBoxColumn
          {...{
            issueStockCheckBoxHandler,
            isAll: true,
            isChecked: selectedStudentIds?.length > 0,
          }}
        />
      </div>
      <div>PSID</div>
      <div>Application ID</div>
      <div>Student Name</div>
      <div>Program Plan</div>
      <div>Material Already Issued</div>
      <div>Material to be Issued</div>
      <div>Barcode</div>
      <div style={{ marginLeft: "auto" }}>Action</div>
    </div>
  );
};

const TableHeaderReturnStock = ({ forBarcoded = true }) => {
  return (
    <div
      className={`${styles["custom-table-parent-return-stock"]} ${styles["header-column"]}`}
    >
      <div>{`${
        forBarcoded ? "Barcoded" : "Non-barcoded"
      } material details`}</div>
      <div>Issued Quantity</div>
      <div>{`Select ${forBarcoded ? "barcodes" : "return quantity"}`}</div>
      <div>{forBarcoded ? `Return Quantity` : ""}</div>
      <div>UOM</div>
    </div>
  );
};

const IssueStockCustomTable = ({
  students,
  issueStockToApplication,
  issueStockCheckBoxHandler,
  selectedStudentsForIssueStock,
  selectedStudentIds,
  alreadyIssuedTshirtMaterial,
}) => {
  if (students.length === 0) {
    return (
      <>
        <TableHeader {...{ issueStockCheckBoxHandler, selectedStudentIds }} />
        <NoRowOverlay />
      </>
    );
  }
  return (
    <>
      <TableHeader {...{ issueStockCheckBoxHandler, selectedStudentIds }} />
      {students.map((student, index) => {
        return (
          <div
            key={student.applicationId}
            className={`${styles["custom-table-parent"]} ${styles["table-body"]}`}
          >
            <div>
              <CheckBoxColumn
                {...{
                  issueStockCheckBoxHandler,
                  student,
                  isChecked:
                    selectedStudentsForIssueStock[student.applicationId] ||
                    false,
                  disabled: !student?.isEligibleForStockIssuance,
                }}
              />
            </div>
            <div>
              <PsidDisplay
                student={student}
                alreadyIssuedTshirtMaterial={
                  alreadyIssuedTshirtMaterial.find(
                    (item) => item.applicationId === student.applicationId
                  )?.alreadyIssuedTshirtMaterial || []
                }
              />
            </div>

            <div className="d-flex">
              <ValueWithTooltip
                student={student}
                value={student.applicationId}
              />
              <ApplicationIdPopup rowData={student} />
              {/* <CourseDetails
                courseId={student.courseId}
                applicationId={student.applicationId}
              /> */}
            </div>

            <div>
              <ValueWithTooltip student={student} value={student.studentName} />
            </div>

            <div>
              <ValueWithOutTooltip
                student={student}
                value={student.programAction}
              />
            </div>

            <div>
              <IssuedColumn student={student} />
            </div>

            <div>
              <MaterialToBeIssued student={student} />
            </div>

            <div>
              <ViewEditBarCode rowData={student} />
            </div>
            <div style={{ marginLeft: "auto" }}>
              <ActionsCellComponent
                student={student}
                issueStockToApplication={(e) =>
                  issueStockToApplication(e, index)
                }
                selectedStudentIds={selectedStudentIds}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

const getBarCodeDropdown = (material) => {
  // console.log(
  //   material?.barCodes?.filter(
  //     (materialItem) => materialItem?.returnTransactionId !== null
  //   ),
  //   "xxx",
  //   material
  // );
  if (!Array.isArray(material?.barCodes)) return [];
  return material?.barCodes
    ?.filter((material) => !material?.returnTransactionId)
    .map((item) => ({
      label: item?.barcode,
      value: item?.barcode,
      barcode: item?.barcode,
      materialId: material?.materialId,
      materialDescription: material?.materialDescription,
      uom: material?.uom,
      serialNumberProfile: material?.serialNumberProfile,
    }));
};

export const ReturnConsumptionTable = ({
  rows = [],
  form,
  formHandler,
  isAllReturn,
  dataRows = [],
  forBarcoded = true,
}) => {
  const notReturnBarcode = (rows, materialId) => {
    return rows.filter(
      (item) =>
        item?.returnTransactionId === null &&
        +item?.materialNumber === +materialId
    );
  };
  const barcodedMaterialsRows = rows?.filter(
    (item) => item?.serialNumberProfile?.toLowerCase() !== "na"
  );
  const nonBarcodedMaterialsRows = rows?.filter(
    (item) => item?.serialNumberProfile?.toLowerCase() === "na"
  );
  if (
    (forBarcoded ? barcodedMaterialsRows : nonBarcodedMaterialsRows).length ===
    0
  ) {
    return (
      <>
        <TableHeaderReturnStock forBarcoded={forBarcoded} />
        <NoRowOverlay />
      </>
    );
  }
  return (
    <>
      <TableHeaderReturnStock forBarcoded={forBarcoded} />
      {(forBarcoded ? barcodedMaterialsRows : nonBarcodedMaterialsRows).map(
        (material, index) => {
          const avlBarCode = getBarCodeDropdown(material);
          return (
            <div
              key={material.materialId}
              className={`${styles["custom-table-parent-return-stock"]} ${styles["table-body"]}`}
            >
              <div>
                <span className="mr-2">{material?.materialDescription}</span>
                <MaterialDetailsV2
                  isAllReturn={isAllReturn}
                  dataRows={notReturnBarcode(dataRows, material?.materialId)}
                />
              </div>
              <div>{material?.barCodes.length}</div>
              <div>
                {forBarcoded ? (
                  <AAutoComplete
                    placeholder={
                      avlBarCode?.length ? "Select barcode" : "Stock returned"
                    }
                    items={avlBarCode}
                    currentValue={form[material?.materialId] || ""}
                    handleChange={(value) => {
                      formHandler(value, material?.materialId);
                    }}
                    // height="32px"
                    style={gridSelectStyles}
                    isShowCount={true}
                    isDisabled={isAllReturn || !avlBarCode?.length}
                  />
                ) : (
                  <AAutoComplete
                    placeholder={
                      avlBarCode?.length ? "Select quantity" : "Stock returned"
                    }
                    items={generateQuantityDropdown(avlBarCode?.length)}
                    currentValue={getQuantityDropdownItem(
                      form[material?.materialId]?.length ?? ""
                    )}
                    handleChange={(value) => {
                      formHandler(
                        avlBarCode?.slice(0, value?.value),
                        material?.materialId
                      );
                    }}
                    // height="32px"
                    style={gridSelectStyles}
                    isMulti={false}
                    isDisabled={isAllReturn || !avlBarCode?.length}
                  />
                )}
              </div>
              <div>
                {forBarcoded
                  ? isAllReturn
                    ? "-"
                    : form[material?.materialId]?.length || 0
                  : ""}
              </div>

              <div>{material?.uom || "-"}</div>
            </div>
          );
        }
      )}
    </>
  );
};

export default IssueStockCustomTable;
