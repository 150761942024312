import React from "react";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import Select from "react-select"


// const NoOptionsMessage = props => {
//   return (
//     <components.NoOptionsMessage {...props}>
//       <span className="custom-css-class">{"Enter Atleast 3 Words To Search"}</span> 
//     </components.NoOptionsMessage>
//   );
// };

/* Please take refrence from below code for promise resolver 

  const filterOption = (inputValue) => {
      return filteredArray
    };
    
  const promiseOptions = inputValue =>
      new Promise(resolve => {
        setTimeout(() => {
          resolve(filterColors(inputValue));
      }, 1000);
  });
*/

// Required props
/*
onChangeHandler
promiseResolver
defaultOptions (if any)
value
*/

const customStyles = {
  // option: (provided, state) => ({
  //   ...provided,
  //   borderBottom: '1px dotted pink',
  //   color: state.isSelected ? 'red' : 'blue',
  //   padding: 20,
  // }),
  control: (base) => ({
    // none of react-select's styles are passed to <Control />
    ...base,
    width: 300,
    border: '1px solid #00AEEF',
    height: '40px',
    "&:hover": {
        borderColor: "#00AEEF",
        color: '#00AEEF'
    },
    "&:focus": {
      borderColor: "#00AEEF",
      color: '#00AEEF'
  }
  }),
  // singleValue: (provided, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1;
  //   const transition = 'opacity 300ms';

  //   return { ...provided, opacity, transition };
  // }
  multiValue: () => ({
    backgroundColor: 'transparent',
    color: '#00AEEF'
  }),
  singleValue: () => ({
    color: '#00aeef'
  }),
  multiValueRemove: (style, state) => {
    return {
      ...style,
      visibility: 'hidden',
      width: '4px',
      display: 'none'
    };
  },
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? "white" : "white",
    color: '#000'
  })
}

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

// const MultiValue = ({ children, ...props }) => {
//   return props?.selectProps?.value?.length == 1
//     ? (
//       <components.MultiValueContainer {...props}>
//         {children}
//       </components.MultiValueContainer>)
//     : <components.SingleValue {...props}>
//     {"test"}
//   </components.SingleValue>
// }

// const SingleValue = (props) => {
//   return (
//     <components.SingleValue {...props}>
//       {props.children}
//     </components.SingleValue>
//   )
// }

// const MultiValueContainer = ({ children, hasValue, ...props }) => {
//   if (!hasValue) {
//     return (
//       <components.MultiValueContainer {...props}>
//         {children}
//       </components.MultiValueContainer>
//     );
//   }
//   return (
//     <components.MultiValueContainer {...props}>
//       {props?.selectProps?.value?.length > 1 ? `Total Selected IDs : ${props?.selectProps?.value?.length}` : props.children}
//     </components.MultiValueContainer>
//   )
// }

const ValueContainer = ({ children, getValue, ...props }) => {
  var length = getValue().length;
  // console.log(props)
  return (
    <components.ValueContainer {...props}>
        {props?.selectProps?.value.find(option => option.value === "*") ? `All Ids selected` : length == 0 ? `Select Transaction Ids` :`${length} Id${length != 1 ? "s" : ""} selected`}
      {React.cloneElement(children[1])}
    </components.ValueContainer>
  );
};


const CustomAsyncMultiSelect = (props) => {
  const { value = null, defaultOptions, isLoading = false, onMenuScrollToBottom = () => { }, onChangeHandler = () => { }, promiseHandler = () => { }, isDisabled = false, placeholder = "Select..." } = props;
  return (
    <Select
      // loadOptions={promiseHandler}
      components={{ Option, ValueContainer }}
      value={value}
      onChange={onChangeHandler}
      // cacheOptions={true}
      isDisabled={isDisabled}
      placeholder={placeholder}
      isMulti={true}
      styles={customStyles}
      options={defaultOptions}
      isSearchable={false}
      isLoading={isLoading}
      isClearable={true}
      // autoload={true}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      // allowSelectAll={true}
      onMenuScrollToBottom={() => onMenuScrollToBottom()}
    />
  );
};

export default CustomAsyncMultiSelect;
