import {updateData} from '../index';
import {UPDATE_DATA} from "../types";
const globalDataReducer =(state={}, action = updateData)=>{
    switch(action.type){
        case UPDATE_DATA :return{
            ...state,
            data:action.payload
        }
        default:return state
    }
}
export default globalDataReducer;