import React, { useContext, useEffect, useState } from 'react'
import HeaderComponent from './HeaderComponent'
import TabsComponent from './TabsComponent'
import { getFilterCount, getInitialFilterForm, getTabRequest } from '../constant/search-academic-plan'
import { useDispatch, useSelector } from 'react-redux'
import { loadDropdowns } from 'redux/academicPlan/actions'
import { getPlanList } from 'redux/academicPlan/actions'
import { academicPlanListClear } from 'redux/academicPlan/actions'
import { PermissionContext, RolePermissions } from 'appContext'
import { PermisionDenied } from '../../common/commonComponents'
import { endpoint, pages } from '../../common/constant'
import { fetchAllPostPromisedData, updateRequest, updateRequest2 } from '../../common/utils/methods/commonMethods/utilityMethod'
import { successToast } from '../../common/utils/methods/toasterFunctions/function'

const SearchAcademicPlan = () => {

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages['searchAcademicPlan']['id']);

  const dispatch = useDispatch();
  const state = useSelector(state => state?.academicPlan);
  const allActiveBusinessAreas = useSelector(state => state?.academicPlan?.dropdowns?.businessArea);

  const [page, setPage] = useState(0);
  const [currentTabValue, setCurrentTabValue] = useState('All');
  const [filterPayload, setFilterPayload] = useState(getInitialFilterForm());
  const [filterForm, setFilterForm] = useState(getInitialFilterForm());
  const [filterCount, setFilterCount] = useState(0);
  const [activePlanData, setActivePlanData] = useState({});
  const [updateApiLoader, setUpdateApiLoader] = useState(false);
  const [businessAreaData, setBusinessAreaData] = useState([]);

  const inputHandler = (value, inputKey) => {
    // const currentData = { ...filterForm };
    // currentData[inputKey] = value;
    setFilterForm(currentData => {return {...currentData, [inputKey]: value}});
  }
  const onPageChange = (currentPage) => {
    setPage(currentPage);
    dispatch(getPlanList(filterPayload, getTabRequest(currentTabValue, state?.dropdowns?.academicGroup), currentPage, allActiveBusinessAreas));
  }
  const applyNameFilterHandler = () => {
    setPage(0);
    const updatedPayload = filterPayload;
    updatedPayload['name'] = filterForm['name']
    setFilterPayload(updatedPayload);
    dispatch(getPlanList(updatedPayload, getTabRequest(currentTabValue, state?.dropdowns?.academicGroup), 0, allActiveBusinessAreas));
  }
  const clearNameFilterHandler = () => {
    setPage(0);
    const updatedPayload = filterPayload;
    updatedPayload['name'] = ''
    setFilterPayload(updatedPayload);
    dispatch(getPlanList(updatedPayload, getTabRequest(currentTabValue, state?.dropdowns?.academicGroup), 0, allActiveBusinessAreas))
  }
  const applyFilterHandler = () => {
    setPage(0);
    setFilterCount(getFilterCount(filterForm));
    setFilterPayload(filterForm);
    dispatch(getPlanList(filterForm, getTabRequest(currentTabValue, state?.dropdowns?.academicGroup), 0, allActiveBusinessAreas));
  }
  const removeFilterHandler = () => {
    setPage(0);
    setFilterCount(0);
    const resettedFilterForm = { ...getInitialFilterForm(), name: filterForm?.name }
    setFilterForm(resettedFilterForm);
    setFilterPayload(resettedFilterForm);
    dispatch(getPlanList(resettedFilterForm, getTabRequest(currentTabValue, state?.dropdowns?.academicGroup), 0, allActiveBusinessAreas));
  }

  const onTabChange = (tabValue) => {
    // handle tab change api call / redux action here and clear filter form and filter payload
    setPage(0);
    setFilterCount(0);
    setFilterForm(getInitialFilterForm());
    setFilterPayload(getInitialFilterForm());
    setCurrentTabValue(tabValue)
    const tabRequest = getTabRequest(tabValue, state?.dropdowns?.academicGroup)
    // console.log(tabRequest)
    // getList will have 1 parameter for formData default to {}, 1 for tabRequest default to getTabRequest('All', state?.dropdowns?.academicGroup), 1 for pageNumber default to 0
    dispatch(getPlanList(getInitialFilterForm(), tabRequest, 0, allActiveBusinessAreas))
  }

  const fetchInitialData = async (dropdownData) => {
    // console.log(currentTabValue)
    dispatch(getPlanList(filterPayload, getTabRequest(currentTabValue, state?.dropdowns?.academicGroup), 0, dropdownData?.businessArea));
  }

  useEffect(() => {
    // dropdownds call and fetch initial list call with 'All' as initial tab value
    dispatch(loadDropdowns(fetchInitialData));

    // clear list cleanup function
    return () => {
      dispatch(academicPlanListClear())
    }
  }, [])

  const updatePublishStatusHandler = async (displayStatus) => {
    setUpdateApiLoader(true);
    const request = {
      publishStatus: displayStatus === 'publish' ? 'P' : 'U'
    }

    const response = await fetchAllPostPromisedData(`${endpoint.viewAcademicPlan.updatePlan}/${activePlanData?.id}`, request, 'put')
    if(response?.code===200){
      successToast(response?.message)
      setPage(0);
      dispatch(getPlanList(filterPayload, getTabRequest(currentTabValue, state?.dropdowns?.academicGroup), 0, allActiveBusinessAreas))
    }
    setUpdateApiLoader(false);
  }

  const updateBranchesHandler = async (planData, regionBusinessAreaForm) => {
    setUpdateApiLoader(true);
    const request = updateRequest2({
      regions: regionBusinessAreaForm?.region,
      businessAreas: regionBusinessAreaForm?.businessArea
    }, ['regions', 'businessAreas'])
    // console.log(planData);

    const response = await fetchAllPostPromisedData(`${endpoint.viewAcademicPlan.updatePlan}/${planData?.id}`, request, 'put')
    if(response?.code===200){
      successToast(response?.message)
      setPage(0);
      dispatch(getPlanList(filterPayload, getTabRequest(currentTabValue, state?.dropdowns?.academicGroup), 0, allActiveBusinessAreas))
    }

    setUpdateApiLoader(false);
  }

  return (
    userPermissions ? (
      <div className='d-flex flex-column justify-content-between remove-container-top-space' style={{ height: 'calc(100vh - 100px)' }}>
        <HeaderComponent />
        <TabsComponent
          onTabChange={onTabChange}
          filterCount={filterCount}
          filterForm={filterForm}
          inputHandler={inputHandler}
          applyNameFilterHandler={applyNameFilterHandler}
          clearNameFilterHandler={clearNameFilterHandler}
          applyFilterHandler={applyFilterHandler}
          removeFilterHandler={removeFilterHandler}
          page={page}
          onPageChange={onPageChange}
          activePlanData={activePlanData}
          setActivePlanData={setActivePlanData}
          updateApiLoader={updateApiLoader}
          updatePublishStatusHandler={updatePublishStatusHandler}
          updateBranchesHandler={updateBranchesHandler}
          businessAreaData={businessAreaData}
          setBusinessAreaData={setBusinessAreaData}
        />
      </div>
    ) : (
      <PermisionDenied />
    )
  )
}

export default SearchAcademicPlan