import React from 'react';
import { Col, Input } from 'reactstrap';
import moment from 'moment';

const RenderHeader = ({ list }) => {
  return (
    list &&
    list.map((item, index) => {
      return (
        <div className="form-row mb-3" key={index}>
          <Col className="mb-3" md="6">
            <label className="form-control-label">
              {item && item[0]['label']}
            </label>
            <Input
              value={item && item[0]['value']}
              disabled
              style={{ height: '39px' }}
            />
          </Col>
            { item[1] ? <Col className="mb-3" md="6">
                <label className="form-control-label">{item[1]['label']}</label>
                <Input
                    value={item[1]['value']}
                    disabled
                    style={{height: "39px"}}
                />
            </Col> : null}
        </div>
      );
    })
  );
};

const CommonHeader = (props) => {
  const { headerData = [], lastUpdatedDate, countLabel, countValue } = props;
  const rows =
    headerData &&
    headerData.reduce(function (rows, key, index) {
      return (
        (index % 2 == 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) &&
        rows
      );
    }, []);

  return (
    <>
      <RenderHeader list={rows} />
      <div className="form-row mb-3">
        <Col className="mb-3 d-flex flex-row" md="6">
          <span className="form-control-label">Last Updated Date : </span>
          <span>
              <b>&nbsp;{lastUpdatedDate && moment(lastUpdatedDate).format('DD-MM-YYYY')}</b>
          </span>
        </Col>
        <Col className="mb-3 d-felx flex-row" md="6">
          <span className="form-control-label">{countLabel} : </span>
          <span><b> {countValue}</b></span>
        </Col>
      </div>
    </>
  );
};

export default CommonHeader;
