import React from "react";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";

const CustomPaginationWithoutCount = ({
    onPageChange,
    currentPage,
    isLastPage,
    rowsPerPage = 50,
    noOfCurrentPageRecords = 0,
}) => {

    const noRecordsFurther = noOfCurrentPageRecords < rowsPerPage || isLastPage;

    const nextClickHandler = async () => {
        if (!noRecordsFurther) onPageChange(currentPage + 1);
    }

    const prevClickHandler = () => {
        if (currentPage !== 0) onPageChange(currentPage - 1);
    }

    const noOfRecordsElapsed = currentPage * rowsPerPage;
    const currentPageFirstRecordNo = noOfCurrentPageRecords === 0 ? noOfRecordsElapsed : noOfRecordsElapsed + 1;
    const currentPageLastRecordNo = noOfRecordsElapsed + noOfCurrentPageRecords

    return (
        <div className="d-flex my-3 align-items-center justify-content-end">
            <div className="regular-bold color-dark mr-3">{`${currentPageFirstRecordNo} - ${currentPageLastRecordNo}`}</div>
            <FaChevronLeft className={`cursor ${currentPage === 0 ? 'color-grey-50' : 'color-dark'} mr-3`} onClick={prevClickHandler} />
            <FaChevronRight className={`cursor ${noRecordsFurther ? 'color-grey-50' : 'color-dark'} ml-3`} onClick={nextClickHandler} />
        </div>
    );
}

export default CustomPaginationWithoutCount;