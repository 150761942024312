import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

export default function MouseOverPopover({
  popOverContent,
  anchorEl,
  handlePopoverClose,
  handlePopoverOpen,
  children
}) {
  const open = Boolean(anchorEl);

  return (
    <div>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {" "}
        {popOverContent?.map((item) => (
          <Typography key={item?.key || item?.value} sx={{ p: 1 }}>
            {item?.label}
          </Typography>
        ))}
        {children}
      </Popover>
    </div>
  );
}
