import React, { useState, useEffect } from "react";
import { CiFilter } from "react-icons/ci";
import { MdOutlineSimCardDownload } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getBatchList } from "redux/batch/batch-actions";
import AButton from "../../common/form-fields-mui/AButton";
import { CSV_HEADERS, getAdvFilterModal, getAFilterModal } from "../constant/batch-search";
import { BatchFilterForm } from "./BatchFilterForm";
import { isFilterApplied } from "../constant/batch-search";
import BatchSearchInput from "./BatchSearchInput";
import { BatchAdvFilterForm } from "./BatchAdvFilterForm";
import APopover from "../../common/pop-hover/APophover";
import { CircularProgress, Tooltip } from "@mui/material";

// this is for filter button style
const highLightedStyle = {
	border: "1px solid #00B0F5",
	color: "#00B0F5",
};

const NormalStyle = {
	border: "1px solid #E9ECEF",
};
const BatchSearchFilter = ({
	data,
	normalFilterForm,
	setNormalFilterForm,
	advanceFilterForm,
	setAdvanceFilterForm,
	inputHandler,
	removeFilterHandler,
	downloadCSVHandler,
	...props
}) => {

	const downloadCsvLoader = useSelector(state => state.batch.downloadCsvLoader)
	const rowCount = useSelector(state => state.batch.rowCount)
	const MAX_DOWNLOAD_COUNT = 2000;

	const dispatch = useDispatch();
	// const [isShowAdvanceFilter, setAdvanceFilter] = useState(false);
	// const [isShowFilter, setFilter] = useState(false);
	const [isRemoveFilterAdv, setIsRemoveFilterAdv] = useState(0);
	const [isRemoveFilter, setIsRemoveFilter] = useState(0);
	const [anchorFilterEl, setAnchorFilterEl] = useState(null);
	const [anchorAdvFilterEl, setAnchorAdvFilterEl] = useState(null);



	// const [normalFilterForm, setNormalFilterForm] = useState(getAFilterModal());
	// const [advanceFilterForm, setAdvanceFilterForm] = useState(getAFilterModal);

	// const inputNormalHandler = (value, inputName) => {
	// 	const currentData = { ...normalFilterForm };
	// 	currentData[inputName] = value;
	// 	setNormalFilterForm(currentData);
	// };

	const inputAdvanceHandler = (value, inputName) => {
		const currentData = { ...advanceFilterForm };
		currentData[inputName] = value;
		setAdvanceFilterForm(currentData);
	};

	const applyFilterNormal = () => {
		//dispatch(getBatchList(normalFilterForm));
		props.applyFilterHandler()
		setIsRemoveFilter(isFilterApplied(normalFilterForm, ['ids']));
		setAnchorFilterEl(null);


	};

	const applyFilterAdvance = () => {
		// dispatch(getBatchList(advanceFilterForm));
		setIsRemoveFilterAdv(isFilterApplied(advanceFilterForm));
		props.applyFilterHandler()
		setAnchorAdvFilterEl(null);

	};
	const removeFilterNormal = () => {
		setNormalFilterForm({ ...getAFilterModal() });
		//dispatch(getBatchList(getAFilterModal()));
		setIsRemoveFilter(isFilterApplied(getAFilterModal()));
		setAnchorFilterEl(null);
		removeFilterHandler({ ...getAFilterModal(), ...advanceFilterForm })

	};

	const removeFilterAdvance = () => {
		setAdvanceFilterForm(getAdvFilterModal());
		//dispatch(getBatchList(getAFilterModal()));
		setIsRemoveFilterAdv(isFilterApplied(getAdvFilterModal()));
		//props.applyFilterHandler()
		removeFilterHandler({ ...getAdvFilterModal(), ...normalFilterForm })
		setAnchorAdvFilterEl(null);


	};

	const onSearchNameCancel = () => {
		inputHandler('', 'batchName')
		removeFilterHandler({ ...normalFilterForm, ...advanceFilterForm, batchName: "" })
	}

	return (
		<div style={{ display: "flex" }}>
			<BatchSearchInput onCancel={onSearchNameCancel} searchBatchNameHandler={props.searchBatchNameHandler} />
			<AButton
				onClick={(event) => {
					setAnchorFilterEl(event.currentTarget);
				}}
				updatedStyle={
					anchorFilterEl || isRemoveFilter > 0 ? highLightedStyle : NormalStyle
				}
			>
				<CiFilter />
				Filter
				{isRemoveFilter > 0 && <span className="filter-braces">{isRemoveFilter}</span>}
			</AButton>

			<APopover handleClose={() => setAnchorFilterEl(null)} id="info-batch-filter-1" anchorEl={anchorFilterEl}>
				<BatchFilterForm
					formModal={normalFilterForm}
					inputHandler={inputHandler}
					isAdvance={false}
					applyFilterHandler={applyFilterNormal}
					removeFilterHandler={removeFilterNormal}
					isShowRemoveFilter={!!isRemoveFilter}
				/>
			</APopover>

			<AButton
				onClick={(event) => {
					setAnchorAdvFilterEl(event.currentTarget);
				}}
				updatedStyle={
					anchorAdvFilterEl || isRemoveFilterAdv > 0
						? highLightedStyle
						: NormalStyle
				}
			>
				Advance Filter
				{isRemoveFilterAdv > 0 && <span className="filter-braces">{isRemoveFilterAdv}</span>}
			</AButton>
			<APopover
				handleClose={() => setAnchorAdvFilterEl(null)}
				id="info-batch-filter"
				anchorEl={anchorAdvFilterEl}
			>
				<BatchAdvFilterForm
					formModal={advanceFilterForm}
					isAdvance={true}
					inputHandler={inputAdvanceHandler}
					applyFilterHandler={applyFilterAdvance}
					removeFilterHandler={removeFilterAdvance}
					isRemoveFilter={isRemoveFilterAdv}
					isShowRemoveFilter={!!isRemoveFilterAdv}

				/>
			</APopover>

			{rowCount > MAX_DOWNLOAD_COUNT ? (
				<Tooltip title={`Number of batches exceed the download limit of ${MAX_DOWNLOAD_COUNT} records. Use filters including business area(s) to narrow down the results to download`}>
					<AButton>
						<MdOutlineSimCardDownload />
						Download CSV
					</AButton>
				</Tooltip>
			) : (
				<AButton onClick={downloadCSVHandler} disabled={downloadCsvLoader} >
					{downloadCsvLoader ? <CircularProgress color="inherit" size={"12px"} /> : <MdOutlineSimCardDownload />}
					Download CSV
				</AButton>
			)}



		</div>
	);
};

export default BatchSearchFilter;
