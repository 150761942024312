import React from "react";
import { CardBody, Row, Col, Card } from "reactstrap";
import AButton from "../../common/form-fields-mui/AButton";
import { ReactComponent as DeleteIcon } from '../../../../../assets/img/svg/Delete.svg';
import "./style.scss"
import { ASelect } from "../../common/form-fields-mui/ASelect";
import { useSelector } from "react-redux";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper";
const buttonStyle = {
  
    border: "1px solid #00B0F5",
    background: "#00B0F5",
    marginBottom: "10px",
    color: "#FFFFFF",
    padding: "5px 16px",
    marginRight: "1rem",
  
}
const AddMSTeamChMapForm = ({formHandler, form, removeHandler, saveHandler}) => {
   const  dropdowns =   useSelector(state=>state.academicSubMapping.dropdowns)
  return (
    <Card style = {{marginBottom:"5px"}}>
      <CardBody>
        <Row>
          <Col md={4} className ="p-2"> 
          <ASelect
           updatedStyle = {{width:"75%"}}
           currentValue= {form.academicGroupId}
           items={dropdowns.academicGroups}
           inputKey ="academicGroupId"
           handleChange = {formHandler}
           placeholder ="Academic"
           />
            </Col>
          <Col md={4} className ="p-2">
          <ASelect
           updatedStyle = {{width:"75%"}}
           currentValue= {form.subjectId}
           items={dropdowns.subjects}
           inputKey ="subjectId"
           handleChange = {formHandler}
           placeholder ="Subject"
           />
          </Col>
          <Col md={4}  className ="d-flex ms-channel-map p-2 align-items-center">
            <AButton
              updatedStyle={buttonStyle}
              onClick = {saveHandler}
            >
              {form.id?"Update":"Save"}
            </AButton>
            <IconButtonWrapper onClick={removeHandler}><DeleteIcon/></IconButtonWrapper>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default AddMSTeamChMapForm;
