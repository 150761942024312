import React, { useState, useEffect, useContext } from 'react';
import { Accordion } from '../../../common/commonComponents/Accordion/accordion';
import { Container, Card, CardBody, CardHeader, Button, Row, Col, UncontrolledTooltip} from 'reactstrap';
import CourseHeader from './component/courseHeader';
import CourseDetails from './component/courseDetails';
import AssociatedAttribute from './associatedAttribute/associatedAttribute';
import {  getAPI, masterServiceBaseUrl, putpost } from 'services/http';
import { successToast, failureToast } from '../../../common/utils/methods/toasterFunctions/function';
import { useHistory } from 'react-router-dom';
import { fetchAllData, fetchDetails } from '../../../common/utils/methods/commonMethods/utilityMethod';
import IsCoreMaster from '../../../common/commonComponents/coreMaster';
import CommonLogDetails from '../../../common/commonComponents/logDetails';
import CustomLoader from '../../../common/commonComponents/Loader/loader';
import { PermissionContext, RolePermissions } from '../../../../../../appContext';
import { pages } from '../../../common/constant';
import CustomButton from '../../../../../../components/CustomButton';
import {get_request_params} from "../../../../../../services/properties/headers";
import { Dialog } from "../../../../../../views/pages/manage/common/commonComponents";
import CopyToClipboard from "react-copy-to-clipboard";
import  ALoader  from 'views/pages/manage/common/a-loader';

const CourseCatalogForm = props => {
  const [academicGroup, setAcademicGroup] = useState([]);
  const [academicCareer, setAcademicCareer] = useState([]);
  const [subjectArea, setSubjectArea] = useState([]);
  const [courseComponent, setCourseComponent] = useState([]);
  const [allCourseDetails, setAllCourseDetails] = useState([]);
  const [courseDetailMaster, setCourseDetailMaster] = useState([]);
  const [isCourseDetailValid, setIsCourseDetailValid] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [academicGroupDropdown, setAcademicGroupDropDown] = useState([]);
  const [academicCareerDropdown, setAcademicCareerDropDown] = useState([]);
  const [courseAttributeValues, setCourseAttributeValues] = useState([]);
  let history = useHistory();
  const { id, action } = props.match.params;
  const [formId, setFormId] = useState(null);
  const [isNew, setIsNew] = useState(true);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [apiloader, setApiLoader] = useState(false);
  const [idNo, setIdNo] = useState('');
  const [courseAttribute, setCourseAttribute] = useState();
  const [attributeValue, setAttributeValue] = useState();
  const [companyCode, setCompanyCode] = useState('');
  const [groupCode, setGroupCode] = useState('');
  const [userDataList, setUserDataList] = useState([]);
  const [CourseDetailData, setCourseDetailData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [checkCourseDetail, setCheckCourseDetail] = useState(action !== 'new');
  const [courseDetailsForm, setCourseDetailsForm] = useState([
    {
      effectiveDateFrom: '',
      effectiveDateFromState: null,
      effectiveDateTo: '',
      effectiveDateToState: null,
      courseTitle: '',
      courseTitleState: null,
      courseName: '',
      courseNameState: null,
      courseDescription: '',
      courseDescriptionState: null,
      academicGroup: '',
      academicGroupState: null,
      subjectArea: '',
      subjectAreaState: null,
      academicCareer: '',
      academicCareerState: null,
      courseComponent: '',
      courseComponentState: null,
      syncWithWebsite: false,
      franchiseCommissionApplicable: false,
    },
  ]);

  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [courseIdCopied, setCourseIdCopied] = useState(false);
  const [courseId, setCourseId] = useState(null);


  useEffect(() => {
    if (companyCode !== '') {
      fetchAllData(
        masterServiceBaseUrl + '/academicCareer/getAcademicCareerByCompanyCode/' + companyCode,
        setAcademicCareer
      );
      fetchAllData(
        masterServiceBaseUrl + '/academicGroup/getAcademicGroupByCompanyCode/' + companyCode,
        setAcademicGroup
      );
    }
  }, [companyCode]);
  const [attributeValueArray, setAttributeValueArray] = useState([]);

  const [courseHeaderObj, setCourseHeaderObj] = useState({
    businessgrp: '',
    companyCode: '',
    courseId: '',
    courseIdStatus: 'ACTIVE',
    prospectusCode: '',
    createdOn: '',
    createdBy: '',
    updatedOn: '',
    updatedBy: '',
    status: 'ACTIVE',
  });

  const getDetails = (url, stateMethod) => {
    try {
      getAPI(
        url,
        data => {
          stateMethod(data?.data);
        },
        data => {
          failureToast(data['message']);
        }
      );
    } catch (e) {
      //console.log("Error", e);
    }
  };

  const request_params = get_request_params('get');
  useEffect(() => {
    if (action !== 'new') {
      fetchDetails(
        masterServiceBaseUrl + `/academicGroup/getAllAcademicGroup`,
        setAcademicGroupDropDown,
        'academicGroup',
        'reactSelect'
      );
      fetchDetails(
        masterServiceBaseUrl + `/academicCareer/getAllAcademicCareer`,
        setAcademicCareerDropDown,
        'academicCareer',
        'reactSelect'
      );

      getDetails(masterServiceBaseUrl + '/academicGroup/getAllActiveAcademicGroup', setAcademicGroup);

      // fetch(masterServiceBaseUrl + '/academicGroup/getAllActiveAcademicGroup', get_api_headers)
      // 	.then((response) => response.json())
      // 	.then((data) => setAcademicGroup(data.data))
      // 	.catch((err) => console.log(err));
      getDetails(masterServiceBaseUrl + '/academicCareer/getAllActiveAcademicCareer', setAcademicCareer);
      // fetch(masterServiceBaseUrl + '/academicCareer/getAllActiveAcademicCareer',get_api_headers)
      // 	.then((response) => response.json())
      // 	.then((data) => setAcademicCareer(data.data))
      // 	.catch((err) => console.log(err));
    }

    getDetails(masterServiceBaseUrl + '/subjectArea/getAllActiveSubjectArea', setSubjectArea);

    // fetch(masterServiceBaseUrl + '/subjectArea/getAllActiveSubjectArea',get_api_headers)
    // 	.then((response) => response.json())
    // 	.then((data) => setSubjectArea(data.data))
    // 	.catch((err) => console.log(err));

    getDetails(masterServiceBaseUrl + '/courseComponent/getAllActiveCourseComponent', setCourseComponent);

    // fetch(masterServiceBaseUrl + '/courseComponent/getAllActiveCourseComponent', get_api_headers)
    //   .then(response => response.json())
    //   .then(data => setCourseComponent(data.data))
    //   .catch(err => console.log(err));

    if (id && action !== 'new') {
      fetch(masterServiceBaseUrl + `/courseCatalogMapping/getCourseCatalog/${id}`, request_params)
        .then(response => response.json())
        .then(data => {
          setCompanyCode(data.data.courseHeaderMaster[0]['companyCodeId']);
          setGroupCode(data.data.courseHeaderMaster[0]['groupCodeId']);
          setAllCourseDetails(data.data.courseDetailMasterList);
          setCourseDetailData(data.data.courseDetailMasterList);
          let temp = data.data.courseDetailMasterList;
          setCourseDetailMaster(temp);
        })
        .catch(err => console.log(err));
      id &&
        getAPI(
          masterServiceBaseUrl + `/associatedAttribute/getAllAssociatedAttributeByCourseId/${id}`,
          data => {
            setUserDataList(data.data);
          },
          data => {}
        );
    }

    // fetchData(masterServiceBaseUrl + '/courseAttribute/getAllActiveCourseAttribute', setCourseAttribute);

    // fetchData(masterServiceBaseUrl + '/courseAttributeValue/getAllActiveCourseAttributeValue', setAttributeValue);

    getDetails(masterServiceBaseUrl + '/courseAttribute/getAllActiveCourseAttribute', setCourseAttribute);

    getDetails(masterServiceBaseUrl + '/courseAttributeValue/getAllActiveCourseAttributeValue', setAttributeValue);

    // fetch(masterServiceBaseUrl + '/courseAttribute/getAllActiveCourseAttribute', get_api_headers)
    //   .then(response => response.json())
    //   .then(data => {
    //     setCourseAttribute(data.data);
    //   })
    //   .catch(err => console.log(err));

    // fetch(masterServiceBaseUrl + '/courseAttributeValue/getAllActiveCourseAttributeValue', get_api_headers)
    //   .then(response => response.json())
    //   .then(data => {
    //     setAttributeValue(data.data);
    //   })
    //   .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    // console.log(id, action, 'action');
    if (id !== 'new') {
      setFormId(id);
      setIsNew(false);
    }
    if (action === 'view' && id) {
      setFormId(id);
      setIsNew(false);
      setIsViewOnly(true);
    }
    if (action === 'edit' && id) {
      setFormId(id);
      setIsNew(false);
      setIsViewOnly(false);
    }
  }, [id]);

  const saveHandler = () => {
    setAllCourseDetails(courseDetailMaster);
    setIsSubmitClicked(true);
    // console.log('Is New=========>',isNew);
    if (courseHeaderObj.businessgrp === '') {
      failureToast('Please Select Business Group');
      return;
    } else if (courseHeaderObj.companyCode === '') {
      failureToast('Please Select Company Code');
      return;
    } else if (courseHeaderObj.courseIdStatus === '') {
      failureToast('Please Select Course ID Status');
      return;
    } else if (!courseDetailMaster.length > 0 && !CourseDetailData.length > 0) {
      failureToast('Please Fill All the Course Details');
      return;
    }

    if (userDataList.length === 0) {
      return failureToast('Please add Associated Attribute');
    }
    setIsLoading(true)

    if (isNew || action === 'new') {
      // AcademicAndCourseAttrDataMap();
      // CourseHeadobj();
      const obj = {
        // courseCatalogCourseHeaderRequestDTO: data,
        courseCatalogCourseHeaderRequestDTO: {
          groupCodeId: courseHeaderObj.businessgrp.value,
          companyCodeId: courseHeaderObj.companyCode.value,
          status: courseHeaderObj.courseIdStatus,
          prospectusCode: courseHeaderObj.prospectusCode.trim(),
        },
        courseDetailMasterDTO: courseDetailMaster,
        associatedAttributesDTO: userDataList,
      };
      let flag = false;
      obj.associatedAttributesDTO.map(item => {
        if (item.courseAttributeValueId === '' || !item.courseAttributeValueId || (Array.isArray(item.courseAttributeValueId) && item.courseAttributeValueId?.length === 0)) {
          flag = true;
        }
      });
      if (flag) {
        failureToast('Please Select Attribute Value.');
        setIsLoading(false)

      } else {
        putpost(
          
          `${masterServiceBaseUrl}/courseCatalogMapping/createCourseCatalog`,
          data => {
            if (data.data.associatedAttributeList.length > 0) {
             // successToast(data['message'] + ' Course Id : ' + data.data.associatedAttributeList[0].courseId);
              setCourseId(data.data.associatedAttributeList[0].courseId)
            } else {
              successToast(data['message']);
            }
            setCourseHeaderObj({
              businessgrp: '',
              companyCode: '',
              courseId: '',
              courseIdStatus: '',
              prospectusCode: '',
              createdOn: '',
              createdBy: '',
              updatedOn: '',
              updatedBy: '',
              academicCareerId: [],
              courseAttributeId: [],
              courseAttributeValueId: [],
            });
            setIsLoading(false);
            setIdNo(data.data['id']);
            setIsSuccessModalVisible(true)
            //history.push('/admin/courseCatalog');
          },
          data => {
            setIsLoading(false);
            failureToast(data['message']);
          },
          obj,
          'post'
        );
      }
    } else {
      let tempArray = [];
      if (JSON.stringify(courseDetailMaster) !== JSON.stringify(CourseDetailData)) {
        tempArray = [...courseDetailMaster];
      } else {
        tempArray = [...CourseDetailData, ...courseDetailMaster];
      }
      let temp = [];
      userDataList.map(item => {
        if (item.isNew && !item.courseId) {
          temp.push({
            courseAttributeId: parseInt(item.courseAttributeId),
            courseAttributeValueId: parseInt(item.courseAttributeValueId),
          });
        } else {
          temp.push({
            id: item.id ? item.id : null,
            courseAttributeId: parseInt(item.courseAttributeId),
            courseAttributeValueId: parseInt(item.courseAttributeValueId),
          });
        }
      });
      let flag = true;
      courseDetailsForm.map(item => {
        if (item.effectiveFrom === '') {
          failureToast('Please Select Effective from date.');
          flag = false;
        } else if (item.effectiveTo === '') {
          failureToast('Please Select Effective To date.');
          flag = false;
        } else if (item.courseName === '' || item.courseName.trim() === '') {
          failureToast('Please Enter Course Name.');
          flag = false;
        } else if (item.courseTitle === '' || item.courseTitle.trim() === '') {
          failureToast('Please Enter Course Title');
          flag = false;
        } else if (item.courseDescription === '' || item.courseDescription.trim() === '') {
          failureToast('Please Enter Course Description');
          flag = false;
        } else if (item.academicCareerId === '') {
          failureToast('Please Select Academic Career');
          flag = false;
        } else if (item.academicGroupId === '') {
          failureToast('Please Select Academic Group.');
          flag = false;
        }
      });
      let data = {
        groupCodeId: courseHeaderObj?.businessgrp.value ? courseHeaderObj.businessgrp.value : null,
        companyCodeId: courseHeaderObj?.companyCode.value ? courseHeaderObj.companyCode.value : null,
        courseId: courseHeaderObj?.courseId ? courseHeaderObj.courseId : null,
        prospectusCode: courseHeaderObj?.prospectusCode ? courseHeaderObj.prospectusCode : null,
        status: courseHeaderObj?.courseIdStatus ? courseHeaderObj.courseIdStatus : null,
      };

      const updatedData = {
        courseCatalogCourseHeaderRequestDTO: data,
        // courseHeaderMaster: data,
        // courseDetailMasterDTO: courseDetailMaster && courseDetailMaster.length>0 ? courseDetailMaster : CourseDetailData,
        courseDetailMasterDTO: courseDetailMaster,
        associatedAttributesDTO: temp,
      };
      if (flag) {
        putpost(
          `${masterServiceBaseUrl}/courseCatalogMapping/updateCourseCatalog/${formId}`,
          data => {
            successToast(data['message']);
            setCourseHeaderObj({
              businessgrp: '',
              courseIdStatus: '',
              companyCode: '',
            });
            setIdNo(data.data['id']);
            history.push('/admin/courseCatalog');
          },
          data => {
            failureToast(data['message']);
          },
          updatedData,
          'put'
        );
      }
    }
  };

  const fetchData = async (url, stateMethod) => {
    getAPI(
      url,
      data => {
        stateMethod(data);
      },
      data => {
        // failureToast(data['message']);
      }
    );
  };
  const [checkAddNewCourse, setCheckAddNewCourse] = useState(true);
  const [businessData, setBusinessData] = useState([]);
  const [companyCodeData, setCompanyCodeData] = useState([]);

  const permissions = useContext(PermissionContext);

  const userPermissions = RolePermissions(permissions, pages['courseCatalog']['id']);

  useEffect(() => {
    if (action === 'new') {
      if (businessData.length) {
        setIsLoading(false);
      }
    } else if (action === 'edit' || action === 'view') {
      if (businessData.length && companyCodeData.length && academicCareer.length && academicGroupDropdown.length) {
        setIsLoading(false);
      }
    }
  }, [academicGroupDropdown, academicCareer, businessData, companyCodeData]);
  const fetchAllDataFromMaster = (url, stateMethod, name) => {
    getAPI(
      url,
      data => {
        let list = [];
        //this is for dropdown fields only
        let keyName = `${name}DispValue`;

        list = data.data.map(data => {
          // if (data.status === "ACTIVE") {
          return {
            value: data.id,
            label: data[keyName],
            desc: data.courseAttributeDesc,
          };
          // }
        });
        stateMethod(list);
      },
      data => {
        // failureToast(data['message']);
      }
    );
  };

  useEffect(() => {
    fetchAllDataFromMaster(masterServiceBaseUrl + '/groupCode/getAllActiveGroupCode', setBusinessData, 'groupCode');
    fetchAllDataFromMaster(
      masterServiceBaseUrl + '/companyCode/getAllActiveCompanyCode',
      setCompanyCodeData,
      'companyCode'
    );
  }, []);
  const GoBack = () => {
    if (action === 'view' || action === 'edit') {
      window.close();
    } else if (action === 'new') {
      window.history.back();
    }
  };
  // const [isEditing,setIsEditing] = useState(false);
  // const goToEditMode = ()=>{
  // 	let url = window.location.href;
  // 	if(url && url.length){
  // 		url = url.replace('view','edit');
  // 		// window.location.href = url;
  // 			window.open(url,"_self");
  //
  // 	}
  // }
  return (
    <Container className={`mt-3`} fluid>
        {isLoading && <ALoader position = "fixed"/>}
      <Row>
        <Col style={{ marginBottom: '19px', paddingLeft: '30px' }}>
          <h3 className="mb-0 floatLeft" style={{ marginTop: '3px' }}>
            <i className="fa fa-arrow-left" style={{ marginRight: '10px', cursor: 'pointer' }} onClick={GoBack} />
            Course Catalog
          </h3>
          {/*{*/}
          {/*	action === 'view'*/}
          {/*		?*/}
          {/*		<Button className={'floatRight mr-3'} color="info" size="md"*/}
          {/*			 type="button"  onClick={goToEditMode}*/}
          {/*		>*/}
          {/*			{'Edit Details'}*/}
          {/*		</Button>*/}
          {/*		: null*/}
          {/*}*/}
          <div className={'floatRight mr-3'}>
            <IsCoreMaster floatRight={true} master={'courseCatalog'} masterDisplayValue={'Course Catalog'} />
          </div>
        </Col>
      </Row>
      <Accordion title={`Course Header`} key={'Course Header'} isFormOpen={true}>
        <CourseHeader
          companyCodeData={companyCodeData}
          setCompanyCodeData={setCompanyCodeData}
          businessData={businessData}
          setBusinessData={setBusinessData}
          courseDetailsForm={courseDetailsForm}
          setCourseDetailsForm={setCourseDetailsForm}
          checkAddNewCourse={checkAddNewCourse}
          setCheckAddNewCourse={setCheckAddNewCourse}
          CourseDetailData={CourseDetailData}
          action={action}
          setCourseDetailData={setCourseDetailData}
          checkCourseDetail={checkCourseDetail}
          isViewOnly={isViewOnly}
          isNew={isNew}
          formId={formId}
          courseHeaderObj={courseHeaderObj}
          courseDetailMaster={courseDetailMaster}
          isCourseDetailValid={isCourseDetailValid}
          setCourseHeaderObj={setCourseHeaderObj}
          setGroupCode={setGroupCode}
          setCompanyCode={setCompanyCode}
          setIsSubmitClicked={value => setIsSubmitClicked(value)}
          userDataList={userDataList}
          setAllCourseDetails={setAllCourseDetails}
        />
      </Accordion>
      <Accordion title={`Course Details`} key={'Course Details'} isFormOpen={true}>
        <CourseDetails
          courseHeaderObj={courseHeaderObj}
          courseDetailsForm={courseDetailsForm}
          setCourseDetailsForm={setCourseDetailsForm}
          checkAddNewCourse={checkAddNewCourse}
          setCheckAddNewCourse={setCheckAddNewCourse}
          checkCourseDetail={checkCourseDetail}
          setCheckCourseDetail={setCheckCourseDetail}
          academicGroupDropdown={academicGroupDropdown}
          academicCareerDropdown={academicCareerDropdown}
          isNew={isNew}
          isViewOnly={isViewOnly}
          formId={formId}
          action={action}
          academicGroup={academicGroup}
          academicCareer={academicCareer}
          subjectArea={subjectArea}
          courseComponent={courseComponent}
          allCourseDetails={allCourseDetails}
          setAllCourseDetails={setAllCourseDetails}
          isSubmitClicked={isSubmitClicked}
          courseDetailsMaster={courseDetailMaster}
          setCourseDetailMaster={setCourseDetailMaster}
          setIsCourseDetailValid={value => setIsCourseDetailValid(value)}
          setCourseDetailMasterDTO={data => setCourseDetailMaster(data)}
          setIsSubmitClicked={value => {
            setIsSubmitClicked(value);
            // getUpdatedData();
          }}
          permissionSet={userPermissions}
        />
      </Accordion>
      <Accordion title={`Associated Attributes`} key={'Associated Attributes'} isFormOpen={true}>
        <AssociatedAttribute
          attributeValueArray={attributeValueArray}
          setAttributeValueArray={setAttributeValueArray}
          setCourseAttributeValues={setCourseAttributeValues}
          courseAttributeValues={courseAttributeValues}
          courseAttribute={courseAttribute}
          attributeValue={attributeValue}
          companyCode={companyCode}
          groupCode={groupCode}
          userDataList={userDataList}
          setUserDataList={setUserDataList}
          action={action}
          permissionSet={userPermissions}
        />
        {action === 'new' || action === 'edit' ? (
          <Row>
            <Col style={{ marginTop: '25px' }}>
              <CustomButton
                className={
                  'ml-auto floatRight' +
                  (courseHeaderObj.businessgrp === '' ||
                  courseHeaderObj.companyCode === '' ||
                  courseHeaderObj.courseIdStatus === '' ||
                  userDataList.length === 0 ||
                  !checkCourseDetail
                    ? ' btn-dark'
                    : '')
                }
                disabled={
                  courseHeaderObj.businessgrp === '' ||
                  courseHeaderObj.companyCode === '' ||
                  courseHeaderObj.courseIdStatus === '' ||
                  userDataList.length === 0 ||
                  !checkCourseDetail
                }
                content={'Submit'}
                permissionType={'C,U'}
                permissionSet={userPermissions}
                onClick={saveHandler}
              />
            </Col>
          </Row>
        ) : null}
      </Accordion>
      {action !== 'new' ? (
        <Accordion title={`Log Details`} key={'Log Details'} isFormOpen={true}>
          <CommonLogDetails
            logDetails={courseHeaderObj}
            closeHandler={() => {
              history.push('/admin/courseCatalog');
            }}
          />
        </Accordion>
      ) : null}
       <Dialog isModalVisible={isSuccessModalVisible} toggle={() => { }} title="" size="md">
                <Row>
                    <h4 className="text-success alert mt-n4">
                        Request Completed Successfully 
                    </h4>
                </Row>
                <Row className="flex-row">
                    <span className="text-info alert mt-n4 " style={{ width: '100%' }}>
                        {`Course ID`}
                        <CopyToClipboard
                            text={courseId}
                            onCopy={() => setCourseIdCopied(courseId)}
                        >
                            <button
                                className="btn-icon-clipboard"
                                data-clipboard-text={courseId}
                                id="tooltip932383034"
                                type="button"
                            >
                                <div>
                                    <span>{courseId}</span>
                                </div>
                            </button>
                        </CopyToClipboard>
                        <UncontrolledTooltip
                            delay={0}
                            trigger="hover focus"
                            target="tooltip932383034"
                        >
                            {courseIdCopied === courseId
                                ? "Copied"
                                : "Copy To Clipbord"}
                        </UncontrolledTooltip>
                    </span>
                </Row>
                <Row className="justify-content-center flex-row">
                    <Button
                        color="info"
                        size="sm"
                        type="button"
                        className="my-2"
                        onClick={() => {
                            setCourseId(null);
                            setCourseIdCopied(null);
                            props.history.push("/admin/courseCatalog");
                            setIsSuccessModalVisible(false);
                        }}
                    >
                        Close
                    </Button>
                </Row>
            </Dialog>
    </Container>
  );
};

export default CourseCatalogForm;
