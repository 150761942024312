import React, { useState, useEffect, useMemo, useContext } from "react";
import { useParams } from "react-router-dom";
import moment from 'moment';
import { getAPI, putpost, pricingServiceBaseUrl } from "services/http";
import { fetchDetails, isEqual, fetchAllPromisedData } from "../../../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint, constants } from "../../../../common/constant";
import Form from "./form";
import CustomLoader from "views/pages/manage/common/commonComponents/Loader/loader";
import { dateFields, headerFields, totalOfFields } from "./form.json";
import { headerFieldsWithOnlinePackage } from "./formWithOnlinePackage.json";
import { headerFieldsWithoutOnlinePackage } from "./formWithoutOnlinePackage.json";

import { PermissionContext, RolePermissions } from "appContext";
import { PermisionDenied } from "../../../../common/commonComponents";
import { pages } from "../../../../common/constant";
import { failureToast } from "../../../../common/utils/methods/toasterFunctions/function";


const feeFields = {
  totalBaseFee: {
    label: "Total Base Fee",
    // mandatory: true,
  },
  totalTaxAmount: {
    label: "Total Tax Amount",
    // mandatory: true,
  },
  totalCourseFee: {
    label: "Total Course Fee",
    // mandatory: true,
  },
};


const useOnlinePacakge  = true;

const NewCourseSetup = () => {

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["courseFeeInformation"]['id']);

  const { action, id } = useParams();
  const [headerValues, setHeaderValues] = useState({
    businessGrp: "",
    companyCode: "",
    headerStatus: { label: 'ACTIVE', value: 'ACTIVE' },
    courseFeeStatus: "",
    transactionId: "",
    pricingZone: "",
    pricingZoneName: "",
    academicCareer: "",
    academicCareerId: "",
    academicGroup: "",
    academicGroupId: "",
    academicTerm: "",
    courseId: "",
    courseName: "",
    courseType: "",
    courseTypeId: "",
    businessArea: "",
    businessAreaName: "",
    businessAreaType: "",
    associatedSecurityCourseId: "",
    franchiseeCommission: "",
    recordedVideoLectureId: "",
    totalNumberOfInstallment: "",
    onlinePackageId:""

  });

  const [dateValues, setDateValues] = useState({
    totalYears: "",
    courseStartDate: new Date(),
    courseEndDate: "",
    termStartDate: "",
    feeEffectiveDateFrom: new Date(),
    feeEffectiveDateTo: "",
    courseMaxDays: 0,
    courseMinDate: new Date(),
    firstYearStartDate: new Date(),
    secondYearStartDate: "",
    thirdYearStartDate: "",
    fourthYearStartDate: "",
    fifthYearStartDate: "",
    sixthYearStartDate: "",
    seventhYearStartDate: "",
    eightYearStartDate: "",
    firstYearEndDate: "",
    secondYearEndDate: "",
    thirdYearEndDate: "",
    fourthYearEndDate: "",
    fifthYearEndDate: "",
    sixthYearEndDate: "",
    seventhYearEndDate: "",
    eightYearEndDate: "",
  });


  const resetDates = () => {
    setDateValues({
      totalYears: "",
      courseStartDate: moment(new Date()).utcOffset("+05:30").format(),
      courseEndDate: "",
      termStartDate: "",
      feeEffectiveDateFrom: moment(new Date()).utcOffset("+05:30").format(),
      feeEffectiveDateTo: "",
      courseMaxDays: 0,
      courseMinDate: moment(new Date()).utcOffset("+05:30").format(),
      firstYearStartDate: moment(new Date()).utcOffset("+05:30").format(),
      secondYearStartDate: "",
      thirdYearStartDate: "",
      fourthYearStartDate: "",
      fifthYearStartDate: "",
      sixthYearStartDate: "",
      seventhYearStartDate: "",
      eightYearStartDate: "",
      firstYearEndDate: "",
      secondYearEndDate: "",
      thirdYearEndDate: "",
      fourthYearEndDate: "",
      fifthYearEndDate: "",
      sixthYearEndDate: "",
      seventhYearEndDate: "",
      eightYearEndDate: "",
    })
  }


  const [supportingDocs,setSupportingDocs] = useState(null)
  const [feeBreakUpData, setFeeBreakupData] = useState(null);
  const [savedFeeBreakUpData, setSavedFeeBreakupData] = useState(null);

  const [records, setRecords] = useState([]);
  const [allRecords, setAllRecords] = useState([])

  const [totals, setTotals] = useState({
    totalBaseFee: 0,
    totalTax: 0,
    finalTotalAmount: 0,
  });

  const [isCourseIdSecurityCourseId, setCourseIdSecurityCourseId] =
    useState(false);
  const [groupCodeData, setGroupCode] = useState([]);
  const [companyCodeData, setCompanyCode] = useState(null);
  const [status, setStatus] = useState(null);
  const [termData, setTerm] = useState([]);
  const [courseIdData, setCourseId] = useState(null);
  const [businessAreaData, setBusinessArea] = useState(null);
  const [pricingZoneData, setPricingZone] = useState(null);
  const [securityCourseIdData, setSecurityCourseId] = useState(null);
  const [onlinePackageData,setOnlinePackageDate] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  //for supporting document
  const getFilteredListed = (doc) => {
    const mandDoc = [];
    const nonMandDoc = [];
    doc.forEach(element => {
      const formats = element?.documentFormatMaster.map(item => { return item.documentFormatDispValue }) ?? []
      if (element?.documentFormatMaster?.length !== 0) {
        if (element?.documentProcessTag?.mandatory) {
          mandDoc.push({
            // id: element?.documentTypeMaster?.id,
            documentTypeDispValue: element?.documentTypeMaster?.documentTypeDispValue,
            documentTypeDesc: element?.documentTypeMaster?.documentTypeDesc,
            documentFormats: formats.join(),
            documentSize: element?.documentTypeMaster?.documentSize,
            isMandatory: element?.documentProcessTag?.mandatory
          })
        } else {
          nonMandDoc.push({
            label: element?.documentTypeMaster?.documentTypeDispValue,
            value: element?.documentTypeMaster?.id,
            // id: element?.documentTypeMaster?.id,
            documentTypeDesc: element?.documentTypeMaster?.documentTypeDesc,
            documentTypeDispValue: element?.documentTypeMaster?.documentTypeDispValue,
            documentFormats: formats.join(),
            documentSize: element?.documentTypeMaster?.documentSize,
            isMandatory: element?.documentProcessTag?.mandatory
          })
        }
      }
    });
    setRecords(mandDoc)
    setAllRecords(nonMandDoc)
    setIsFetching(false)
  }

  const getDocuments = async () => {
    const docs = await fetchAllPromisedData(`${endpoint.documentProcessTag.getAllDocumentByProcessName}/${constants.PRICING_SERVICE_SUPPORTING_DOC_PROCESS_TAG}`);
    if (Array.isArray(docs)) {
      getFilteredListed(docs);
    } else {
      setRecords([])
      setAllRecords([])
      setIsFetching(false)
    }
  }

  //onLoad useeffect
  useEffect(() => {
    fetchDetails(
      endpoint.groupCode.getAllActive,
      setGroupCode,
      "groupCode",
      "select"
    );
    fetchDetails(
      endpoint.approvalStatus.getAllActive,
      setStatus,
      "approvalStatus",
      "select"
    );
    if(useOnlinePacakge){
      fetchDetails(
        endpoint.onlinePackage.getAll,
        setOnlinePackageDate,
        "onlinePackage",
        "onlinePackage"
      );
    } 
    getDocuments()
  }, []);


  //setValue in case of edit
  const fetchAllData = async (url) => {
    return new Promise((resolve, reject) => {
      getAPI(
        url,
        (data) => {
          resolve(data?.data);
        },
        (data) => {
          failureToast(data["message"]);
          reject({})
        }
      );
    })
  };
// value => 1_2_3,
  const getDropdown  = (value = '', list = [])=>{

    const dropdown = []
       if(value && list){
       const ids =  value.split("_")
       ids.forEach(id=>{
          list.forEach(item=>{
            if (+id === item?.value) dropdown.push(item);
       })
       })
  }
  return dropdown
}
  const setValuesForEdit = async (data) => {
    const p = await Promise.all([
      fetchAllData(`${endpoint.groupCode.getById}/${data.businessGroup}`),
      fetchAllData(`${endpoint.companyCode.getById}/${data.companyCode}`),
      fetchAllData(`${endpoint.course_details.getCourseDetailsByCourseId}/${data.courseId}`),
      fetchAllData(`${endpoint.businessArea.getBusinessUnitType}/${data.businessAreaId}`),
      fetchAllData(`${endpoint.term.getById}/${data.academicTerm}`),
      fetchAllData(`${endpoint.pricing_zone.getById}/${data.pricingZone}`),
    ]);

    setCompanyCode([])
    setCourseId([])
    setPricingZone([])
    setTimeout(5000)

    const businessType = p[3]?.businessUnitTypeResponseDTOS.map(item => item.businessUnitType)
    
    setHeaderValues({
      ...headerValues,
      businessGrp: { value: data?.businessGroup, label: p[0]?.groupCodeDispValue ?? '' },
      companyCode: { value: data?.companyCode, label: p[1]?.companyCodeDispValue ?? '' },
      headerStatus: { label: data?.status, value: data?.status },
      courseFeeStatus: { value: '', label: data?.courseFeeStatus },
      transactionId: data?.transactionId ?? '',
      pricingZone: { value: data?.pricingZone, label: p[5]?.pricingZoneName },
      pricingZoneName: p[5]?.pricingZoneName,
      academicCareer: p[2]?.academicCareer,
      academicCareerId: data?.academicCareer,
      academicGroup: p[2]?.academicGroup,
      academicGroupId: data?.academicGroup,
      academicTerm: { label: p[4].termDispValue, value: data?.academicTerm },
      courseId: { label: data?.courseId, value: data?.courseId },
      courseName: p[2]?.courseName,
      courseType: p[2]?.categoryType,
      courseTypeId: p[2]?.categoryTypeId,
      associatedSecurityCourseId: { label: data.securityCourseId, value: data.securityCourseId },
      franchiseeCommission: data.franchiseeCommission,
      recordedVideoLectureId: data?.recordedVideoLectureId,
      totalNumberOfInstallment: data?.totalNumberOfInstallment,
      onlinePackageId:getDropdown(data?.onlinePackageId, onlinePackageData),
      businessArea: [{ businessAreaId: p[3]?.businessAreaId, businessAreaDispValue: p[3]?.businessArea ?? '', businessAreaDesc: p[3]?.businessAreaDescription ?? '', types: businessType.join(",") }]
    });

    const itemTypeData = await fetchAllData(
      `${endpoint.item_type_sequence.itemTypeSequenceSetupDetailsWithTaxByGroupCodeAndCompanyCode}?groupCodeId=${data?.businessGroup}&companyCodeId=${data?.companyCode}&academicCareerId=${p[2]?.academicCareerId}&termId=${data?.academicTerm}&courseTypeId=${p[2]?.categoryTypeId}&totalNumberOfInstallment=${data?.totalNumberOfInstallment}`
    );

    if (itemTypeData?.length) {
      setFeeBreakupData(itemTypeData)
    } else {
      failureToast("No item Type Sequence setup found")
    }

    const getNumberOfDays = await fetchAllData(
      `${endpoint.setup_rule.getNumberOfDays}?groupCode=${data?.businessGroup}&companyCode=${data?.companyCode}&academicCareer=${p[2]?.academicCareerId}&term=${data?.academicTerm}&courseId=${data?.courseId}`
    );
   
    setDateValues({
      totalYears: data?.totalNumberOfYears,
      courseStartDate: new Date(data?.courseStartDate),
      courseEndDate: new Date(data?.courseEndDate),
      termStartDate: "",
      feeEffectiveDateFrom: new Date(data?.courseStartDate),
      feeEffectiveDateTo: new Date(data?.courseStartDate),
      courseMaxDays: getNumberOfDays?.[0]?.dayCountFromCourseStart ?? 0,
      courseMaxStartDays: getNumberOfDays?.[0]?.dayCountFromTermStart ?? 0,
      courseMinDate: new Date(),
      firstYearStartDate: (data?.firstYearStartDate && new Date(data?.firstYearStartDate)) ?? null,
      firstYearEndDate: (data?.firstYearEndDate && new Date(data?.firstYearEndDate)) ?? null,

      secondYearStartDate: (data?.secondYearStartDate && new Date(data?.secondYearStartDate)) ?? null,
      secondYearEndDate: (data?.secondYearEndDate && new Date(data?.secondYearEndDate)) ?? null,

      thirdYearStartDate: (data?.thirdYearStartDate && new Date(data?.thirdYearStartDate)) ?? null,
      thirdYearEndDate: (data?.thirdYearEndDate && new Date(data?.thirdYearEndDate)) ?? null,

      fourthYearStartDate: (data?.forthYearStartDate && new Date(data?.forthYearStartDate)) ?? null,
      fourthYearEndDate: (data?.forthYearEndDate && new Date(data?.forthYearEndDate)) ?? null,

      fifthYearStartDate: (data?.fifthYearStartDate && new Date(data?.fifthYearStartDate)) ?? null,
      fifthYearEndDate: (data?.fifthYearEndDate && new Date(data?.fifthYearEndDate)) ?? null,

      sixthYearStartDate: "",
      seventhYearStartDate: "",
      eightYearStartDate: "",

      sixthYearEndDate: "",
      seventhYearEndDate: "",
      eightYearEndDate: "",
    })
    setIsFetching(false);
  }

  const getFeeSetupData = () => {
    getAPI(
      `${pricingServiceBaseUrl}/courseFeeManualProcessing/getCourseFeeManualById/${id}`,
      (data) => {
        setValuesForEdit(data.data.courseFeeManualHeaderResponseDTO)
        setSupportingDocs(data.data.courseFeeSupportingDocumentDtoList)
        setSavedFeeBreakupData(data.data?.normalFeeBreakupDTO)
      },
      (data) => {
        failureToast(data["message"]);
        setIsFetching(false);
      }
    );
  };

  //end of edit case

  //on getting id and action
  useEffect(() => {
    if (isEqual(action, 'edit') && id) {
      setIsFetching(true)
      getFeeSetupData()
    }
  }, [action, id, onlinePackageData])

  //for edit security case only
  useEffect(()=>{
    if(isEqual(action, 'edit') && headerValues?.associatedSecurityCourseId?.value, securityCourseIdData?.length){
      const selectedObject = securityCourseIdData?.filter(item=>item.value == headerValues?.associatedSecurityCourseId?.value)
      if(selectedObject?.length){
        setHeaderValues({...headerValues,associatedSecurityCourseId: selectedObject[0]})
      }
    }
  },[action,headerValues?.associatedSecurityCourseId,securityCourseIdData])
  //for edit security case only

  //to get comapny code from group Code
  useMemo(() => {
    if (headerValues?.businessGrp?.value && !isEqual(action, 'edit')) {
      setHeaderValues({
        ...headerValues,
        companyCode: "",
        pricingZone: "",
        pricingZoneName: "",
        academicGroup: "",
        academicCareer: "",
        courseId: "",
        courseName: "",
        courseType: "",
        businessArea: "",
        businessAreaName: "",
        businessAreaType: "",
        academicCareerId: "",
        academicGroupId: "",
        courseTypeId: "",
        academicTerm: "",
        recordedVideoLectureId: [],
        totalNumberOfInstallment: "",
        headerStatus: { label: 'ACTIVE', value: 'ACTIVE' },
      });
      setCompanyCode(null);
      setCourseId(null);
      setBusinessArea(null);
      setPricingZone(null);
      resetDates();
      fetchDetails(
        `${endpoint.companyCode.getAllActiveByGroupCode}/${headerValues.businessGrp.value}`,
        setCompanyCode,
        "companyCode",
        "select"
      );
    }
  }, [headerValues?.businessGrp?.value]);

  //to get courseId and pricing zone by company code
  useMemo(() => {
    if (headerValues?.companyCode?.value) {
      !isEqual(action, 'edit') && fetchDetails(
        `${endpoint.courseCatelogHeader.getByGroupCodeAndCompanyCode}?groupCode=${headerValues.businessGrp.value}&companyCode=${headerValues.companyCode.value}`,
        setCourseId,
        "courseId",
        "courseId"
      );

      !isEqual(action, 'edit') && fetchDetails(
        `${endpoint.pricing_zone.getByGroupCodeAndCompanyCode}?groupId=${headerValues.businessGrp.value}&companyId=${headerValues.companyCode.value}`,
        setPricingZone,
        "pricingZone",
        "pricingZone"
      );
      fetchDetails(
        `${endpoint.courseCatelogHeader.getByGroupCodeAndCompanyCodeAndSecurity
        }?groupCode=${headerValues.businessGrp.value}&companyCode=${headerValues.companyCode.value
        }&security=${true}`,
        setSecurityCourseId,
        "securityCourseId",
        "securityCourseId",
        true
      );
    }
  }, [headerValues?.companyCode?.value]);

  //to get courseName and course category , academic career , academic group from courseID

  //check is course id security course id
  const chkCourseIdIsSecurityCourseId = (courseId) => {
    try {
      const i =
        courseId &&
        securityCourseIdData?.filter((item) => {
          return item.value == courseId;
        });

      setCourseIdSecurityCourseId(i && i?.length ? true : false);
    } catch (e) {
      console.log("error in chk security course id")
    }
  };

  //for academicTerm
  const getTermData = (academicCareerId) => {
    fetchDetails(
      `${endpoint.term.getAllTermMappingOnGroupCodeCompanyCodeAndAcademicCareer}?groupCode=${headerValues?.businessGrp?.value ?? ''}&academicCareer=${academicCareerId ?? ''}&companyCode=${headerValues?.companyCode?.value ?? ''}`,
      setTerm,
      "term",
      "termMapping"
    );
  };
  //end

  //get all infor from backed
  const getCourseIdData = () => {
    getAPI(
      `${endpoint.course_details.getCourseDetailsByCourseId}/${headerValues.courseId.value}`,
      (data) => {
        if (!isEqual(action, 'edit')) {
          const courseDetails = data?.data;
          setHeaderValues({
            ...headerValues,
            courseName: courseDetails?.courseName ?? "Not Found",
            courseType: courseDetails?.categoryType ?? "Not Found",
            academicCareer: courseDetails?.academicCareer ?? "Not Found",
            academicGroup: courseDetails?.academicGroup ?? "Not Found",
            academicCareerId: courseDetails?.academicCareerId ?? null,
            academicGroupId: courseDetails?.academicGroupId ?? null,
            courseTypeId: courseDetails?.categoryTypeId ?? null,
            academicTerm: ""
          });
          getTermData(courseDetails?.academicCareerId);
        } else {
          const courseDetails = data?.data;
          setHeaderValues({
            ...headerValues,
            courseName: courseDetails?.courseName ?? "Not Found",
            courseType: courseDetails?.categoryType ?? "Not Found",
            academicCareer: courseDetails?.academicCareer ?? "Not Found",
            academicGroup: courseDetails?.academicGroup ?? "Not Found",
            academicCareerId: courseDetails?.academicCareerId ?? null,
            academicGroupId: courseDetails?.academicGroupId ?? null,
            courseTypeId: courseDetails?.categoryTypeId ?? null,
          });
        }
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  };

  useMemo(() => {
    // if (!isEqual(action, 'edit')) {
    if (headerValues?.courseId?.value) {
      chkCourseIdIsSecurityCourseId(headerValues?.courseId?.value);
      getCourseIdData();
      if (!isEqual(action, 'edit')) {
        resetDates()
      }
    }
    // else {
    //   setHeaderValues({
    //     ...headerValues,
    //     courseName: "",
    //     courseType: "",
    //     academicCareer: "",
    //     academicGroup: "",
    //     academicCareerId: "",
    //     academicGroupId: "",
    //     courseTypeId: "",
    //   });
    // }
    // }
  }, [headerValues?.courseId?.value]);
  ///ends course id

   //get Term Start date
   const getTermStartDate = (dateData) => {
    getAPI(
      `${endpoint.term.getTermDate}?termId=${headerValues?.academicTerm?.value}&termMappingId=${headerValues?.academicTerm?.termMappingId}`,
      (data) => {
        setDateValues({ ...dateValues,...dateData, termStartDate: data.data.termStartDate, courseMinDate : data.data.termStartDate, courseStartDate:  data.data.termStartDate })
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  }

  //api for geting number of days
  const getNumberOfCourseDays = (totalYear) => {
    getAPI(
      `${endpoint.setup_rule.getNumberOfDays}?groupCode=${headerValues.businessGrp?.value}&companyCode=${headerValues.companyCode?.value}&academicCareer=${headerValues.academicCareerId}&term=${headerValues.academicTerm?.value}&courseId=${headerValues.courseId?.value}`,
      (data) => {
        // setDateValues({ ...dateValues, courseMaxDays: data?.data?.[0]?.dayCountFromCourseStart, totalYears: totalYear })
        const dateData = {
          courseMaxDays: data?.data?.[0]?.dayCountFromCourseStart,
          courseMaxStartDays: data?.data?.[0]?.dayCountFromTermStart,
          totalYears: totalYear
        }
        getTermStartDate(dateData)
      },
      (data) => {
        setDateValues({ ...dateValues, totalYears: totalYear})
        failureToast(data["message"]);
      }
    );
  }

  //calculation for total number of years 
  const getTotalYear = (formData) => {
    putpost(
      endpoint.academic_year.getActiveAcademicCareers,
      (data) => {
        // setDateValues({
        //   ...dateValues,
        //   totalYears: data?.data?.length ?? 0,
        //   //courseMaxDays : 90
        // });
        getNumberOfCourseDays(data?.data?.length ?? 0)
      },
      (data) => {
        failureToast(data["message"]);
      },
      formData,
      "post"
    );
  };

  //for counting total course years
  useMemo(() => {
    if (headerValues.businessGrp?.value && headerValues.companyCode?.value && headerValues?.academicCareerId && headerValues.academicTerm?.value) {

      const formData = {
        companyCodeId: headerValues.companyCode?.value,
        groupCodeId: headerValues.businessGrp?.value,
        termId: headerValues.academicTerm?.value,
        academicCareerId: headerValues?.academicCareerId,
      };

      if(!isEqual(action, 'edit')){
        getTotalYear(formData);
        // 
        resetDates();
      }
     
    }
  }, [headerValues.businessGrp?.value, headerValues.companyCode?.value, headerValues?.academicCareerId, headerValues.academicTerm?.value]);

  //end

  //pricing zone

  const getBusinessType = async (businessData) => {
    const busArea = [];
    if (businessData.length) {
      businessData &&
        businessData.map((item, i) => {
          getAPI(
            `${endpoint.businessArea.getBusinessUnitType}/${item.businessAreaId}`,
            (data) => {
              const details = data?.data;
              let types = [];
              details &&
                details.businessUnitTypeResponseDTOS &&
                details.businessUnitTypeResponseDTOS.map(
                  (element) =>
                    types.push(element.businessUnitType)
                );
              let tempData = { ...item, types: types.join(",") ?? "" };

              busArea.push(tempData);
              if (busArea.length === businessData.length) {
                setHeaderValues({
                  ...headerValues,
                  businessArea: busArea,
                  businessAreaFetching: false,
                  pricingZoneName: headerValues.pricingZone?.name,
                });
              }
            },
            (data) => {
              failureToast(data["message"]);
            }
          );
        });
    } else {
      setHeaderValues({
        ...headerValues,
        businessArea: [],
        businessAreaFetching: false,
        pricingZoneName: headerValues.pricingZone?.name,
      });
    }
  };

  //business area details
  const getBusinessArea = (val) => {
    getAPI(
      `${endpoint.pricingZone_BusinessArea.getBusinessAreaByPricingId}/${val}`,
      (data) => {
        getBusinessType(data.data);
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  };

  useMemo(() => {
    if (headerValues?.pricingZone?.value && !isEqual(action, 'edit')) {
      setHeaderValues({
        ...headerValues,
        businessAreaFetching: true,
      });
      getBusinessArea(headerValues?.pricingZone?.value);
    }
  }, [headerValues?.pricingZone?.value]);
  //end

  //date effective upto
  useMemo(() => {
    const lastYearStartDate = constants.course_fee_year_start_date_objs[dateValues.totalYears];
    if (dateValues?.courseEndDate && ((moment(dateValues.courseEndDate) < moment(dateValues["feeEffectiveDateTo"])) || !dateValues["feeEffectiveDateTo"])) {
      setDateValues({
        ...dateValues,
        feeEffectiveDateTo: dateValues?.courseEndDate,
        secondYearStartDate: "",
        thirdYearStartDate: "",
        fourthYearStartDate: "",
        fifthYearStartDate: "",
        sixthYearStartDate: "",
        seventhYearStartDate: "",
        eightYearStartDate: "",
        firstYearEndDate: "",
        secondYearEndDate: "",
        thirdYearEndDate: "",
        fourthYearEndDate: "",
        fifthYearEndDate: "",
        sixthYearEndDate: "",
        seventhYearEndDate: "",
        eightYearEndDate: ""
      });
    }
    if (dateValues?.courseEndDate && !(moment(dateValues.courseEndDate) < moment(dateValues[lastYearStartDate])) && !isEqual(action, 'edit')) {
      setDateValues({
        ...dateValues,
        feeEffectiveDateTo: dateValues?.courseEndDate,
      });
    }
  }, [dateValues?.courseEndDate]);

  useMemo(() => {
    if (new Date(dateValues?.courseStartDate).getTime() > new Date(dateValues.feeEffectiveDateFrom).getTime()) {
      setDateValues({
        ...dateValues,
        feeEffectiveDateFrom: dateValues?.courseStartDate,
        courseEndDate: "",
        feeEffectiveDateTo: "",
        firstYearStartDate: dateValues?.courseStartDate,
        secondYearStartDate: "",
        thirdYearStartDate: "",
        fourthYearStartDate: "",
        fifthYearStartDate: "",
        sixthYearStartDate: "",
        seventhYearStartDate: "",
        eightYearStartDate: "",
        firstYearEndDate: "",
        secondYearEndDate: "",
        thirdYearEndDate: "",
        fourthYearEndDate: "",
        fifthYearEndDate: "",
        sixthYearEndDate: "",
        seventhYearEndDate: "",
        eightYearEndDate: ""
      })
    }else{
      setDateValues({
        ...dateValues,
        feeEffectiveDateFrom: dateValues?.courseStartDate,
        firstYearStartDate: dateValues?.courseStartDate,
      })
    }
  }, [dateValues?.courseStartDate]);
  //end date effective upto


  //handling fee breakupData

  const setFeeBreak = async () => {
    const itemTypeData = await fetchAllData(
      `${endpoint.item_type_sequence.itemTypeSequenceSetupDetailsWithTaxByGroupCodeAndCompanyCode}?groupCodeId=${headerValues.businessGrp?.value}&companyCodeId=${headerValues.companyCode?.value}&academicCareerId=${headerValues?.academicCareerId}&termId=${headerValues.academicTerm?.value}&courseTypeId=${headerValues?.courseTypeId}&totalNumberOfInstallment=${headerValues.totalNumberOfInstallment}`
    );

    if (itemTypeData?.length) {
      setFeeBreakupData(itemTypeData)
      return true
    } else {
      failureToast("No item Type Sequence setup found")
      return false
    }

  }

  // useMemo(() => {
  //   if (headerValues.businessGrp?.value && headerValues.companyCode?.value && headerValues?.academicCareerId && headerValues.academicTerm?.value && headerValues?.courseTypeId && headerValues.totalNumberOfInstallment) {
  //     setFeeBreak()
  //   }
  // }, [headerValues.businessGrp?.value, headerValues.companyCode?.value, headerValues?.academicCareerId, headerValues.academicTerm?.value, headerValues?.courseTypeId,headerValues.totalNumberOfInstallment]);

  //handling fee breakupData

  return (
    userPermissions ?
      <div className="manual-pricing-setup">
        {isFetching ?
          <div
            className="mx-auto text-center py-5 my-5"
            style={{ height: "100vh" }}
          >
            <CustomLoader apiLoader={isFetching} />
          </div>
          :
          <Form
            headerFields={!useOnlinePacakge ? headerFieldsWithoutOnlinePackage : headerFields}
            headerValues={headerValues}
            setHeaderValues={setHeaderValues}
            dateFields={dateFields}
            dateValues={dateValues}
            setDateValues={setDateValues}
            feeFields={feeFields}
            groupCode={groupCodeData}
            companyCodeData={companyCodeData}
            courseIdData={courseIdData}
            termData={termData}
            businessAreaData={businessAreaData}
            pricingZoneData={pricingZoneData}
            securityCourseIdData={securityCourseIdData}
            isCourseIdSecurityCourseId={isCourseIdSecurityCourseId}
            totals={totals}
            setTotals={setTotals}
            totalOfFields={totalOfFields}
            status={status}
            action={action}
            feeBreakUpData={feeBreakUpData}
            savedFeeBreakUpData={savedFeeBreakUpData}
            id={id}
            userPermissions={userPermissions}
            setFeeBreak={setFeeBreak}

            records={records}
            setRecords={setRecords}
            allRecords={allRecords}
            setAllRecords={setAllRecords}
            supportingDocs={supportingDocs}
            onlinePackageData={onlinePackageData}
            useOnlinePacakge={useOnlinePacakge}
          />
        }
      </div> : <PermisionDenied />
  );
};

export default NewCourseSetup;
