import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { ReactComponent as CloseIcon } from '../../../../../assets/img/svg/CrossIcon.svg'
import ALoader from '../../common/a-loader';
import { endpoint } from '../../common/constant';
import AAutoComplete from '../../common/form-fields-mui/AAutoComplete';
import AButton from '../../common/form-fields-mui/AButton';
import { fetchAllPostPromisedData, fetchAllPromisedData, updateRequest } from '../../common/utils/methods/commonMethods/utilityMethod';
import RegionBusinessAreaCustomChip from '../common/RegionBusinessAreaCustomChip'

const chipContainerStyle = {
  maxHeight: "120px",
  overflowY: "auto",
  display: "flex",
  flexWrap: "wrap",
  gap: "2px",
  marginTop: "3px",
  //  justifyContent:"space-between"
};

const EditBranchesForm = ({ 
  closeForm, 
  planData,
  // regionBusinessAreaForm, 
  // setRegionBusinessAreaForm, 
  // inputHandler, 
  updateHandler 
}) => {

  const [loader, setLoader] = useState(false);
  const [businessAreaRegionMap, setBusinessAreaRegionMap] = useState({});
  const [businessAreas, setBusinessAreas] = useState([]);
  const [regionDropdown, setRegionDropdown] = useState([]);
  const [businessAreaDropdown, setBusinessAreaDropdown] = useState([]);

  const [regionBusinessAreaForm, setRegionBusinessAreaForm] = useState({
    region: planData?.regions?.map(item => ({label: item?.dispValue, value: item?.id})),
    businessArea: planData?.businessAreas?.map(item => ({label: item?.dispValue, value: item?.id})),
  })
  const inputHandler = (value, key) => {
    setRegionBusinessAreaForm(prev => ({ ...prev, [key]: value }))
  }

  const addBA = (regionId) => {
    const addedBA = [];
    const temp = businessAreas?.map(item => {
      if (item?.['regionId']?.[0] === regionId) {
        addedBA.push({ label: item?.businessAreaDispValue, value: item?.id })
        return;
      }
    })
    // console.log(addedBA)
    setRegionBusinessAreaForm(prev => ({ ...prev, businessArea: [...prev?.businessArea, ...addedBA] }))
  }
  const deleteBA = (regionId) => {
    const updatedBA = [];
    const temp = regionBusinessAreaForm?.businessArea?.map(item => {
      if (businessAreaRegionMap?.[item?.label] !== regionId) {
        updatedBA.push(item);
      }
      // if (item?.['regionId'] !== regionId) {
      //   updatedBA.push(item);
      // }
    })
    setRegionBusinessAreaForm(prev => ({ ...prev, businessArea: [...updatedBA] }))
  }
  const deleteHandler = (value, array) => {
    const res = [];
    const temp = array?.map(item => {
      if (item?.value !== value) {
        res.push(item);
      }
    })
    // console.log(temp);
    return res;
  }
  const filterDeletedId = (currentArray, prevArray) => {
    const temp = prevArray.filter((item) => {
      return !currentArray.includes(item);
    })
    // console.log(temp);
    return temp?.[0]?.value;
  }

  const getDropdowns = async () => {
    const businessAreas = await fetchAllPromisedData(endpoint?.businessArea?.getAllActive);
    const regions = await fetchAllPromisedData(endpoint?.region?.getAllActive);

    const tempMap = {};
    const tempMapping = businessAreas?.map(item => {
      tempMap[item?.businessAreaDispValue] = item?.regionId?.[0];
    })
    setBusinessAreaRegionMap(tempMap);

    setBusinessAreas(businessAreas);
    const regionTemp = regions?.map(item => { return { label: item?.['regionDispValue'], value: item?.['id'] } });
    setRegionDropdown(regionTemp);
    // if (setAllDefault) inputHandler(regionTemp, 'region');
    // console.log(businessAreas, regions)
  }

  useEffect(() => {
    getDropdowns();
  }, [])

  const getBusinessAreaByRegions = async () => {
    setLoader(true);
    const res = await fetchAllPostPromisedData(endpoint?.businessArea?.getAllActiveBusinessAreaByRegions, updateRequest({ regionId: regionBusinessAreaForm?.region }), 'post')
    // console.log(res);
    if (res?.code === 200) {
      const tempBA = res?.data?.map(item => { return { label: item?.['businessAreaDispValue'], value: item?.['id'], regionId: item?.['regionId']?.[0] } });
      setBusinessAreaDropdown(tempBA);
    }
    else {
      setBusinessAreaDropdown([]);
    }
    setLoader(false);
  }

  useEffect(() => {
    getBusinessAreaByRegions();
  }, [regionBusinessAreaForm?.region])

  return (
    <Card className='mb-0' style={{ position: 'relative', width: '370px' }}>
      <CardBody className='p-3'>
        {loader && <ALoader />}
        <div className='d-flex justify-content-between align-items-center'>
          <div style={{ fontWeight: 600, fontSize: '20px', color: '#262626' }}>Edit Branches</div>
          <CloseIcon onClick={closeForm} />
        </div>
        <div>
          <Row>
            <Col md="12">
              <AAutoComplete
                label="Region"
                currentValue={regionBusinessAreaForm?.region}
                items={regionDropdown}
                handleChange={(value) => {
                  // console.log(value?.[value?.length - 1]);
                  if (value?.length > regionBusinessAreaForm?.region?.length) {
                    addBA(value?.[value?.length - 1]?.value);
                  }
                  else if (value?.length < regionBusinessAreaForm?.region?.length) {
                    deleteBA(filterDeletedId(value, regionBusinessAreaForm?.region));
                  }
                  inputHandler(value, 'region')
                }}
              />
              <div style={chipContainerStyle}>
                {(regionBusinessAreaForm?.region)?.map(
                  (item) => (
                    <RegionBusinessAreaCustomChip
                      key={item?.value}
                      text={item?.label}
                      variant="outlined"
                      onDelete={() => {
                        inputHandler(
                          deleteHandler(item.value, regionBusinessAreaForm?.region),
                          "region"
                        )
                        deleteBA(item?.value)
                      }}
                    />
                  )
                )}
              </div>
            </Col>
            <Col md={12}>
              <AAutoComplete
                label="Business area"
                currentValue={regionBusinessAreaForm?.businessArea}
                items={businessAreaDropdown}
                handleChange={(value) => inputHandler(value, "businessArea")}
              />
              <div style={chipContainerStyle}>
                {(
                  regionBusinessAreaForm?.businessArea
                )?.map((item) => (
                  <RegionBusinessAreaCustomChip
                    key={item.value}
                    text={item.label}
                    variant="outlined"
                    onDelete={() =>
                      inputHandler(
                        deleteHandler(item.value, regionBusinessAreaForm?.businessArea),
                        "businessArea"
                      )
                    }
                  />
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mt-2">
              <AButton
                className='button_remove_left_margin'
                updatedStyle={{
                  width: "100%",
                  color: "#fff",
                  background: "#00B0F5",
                  margin: "0 !important",
                }}
                onClick={() => {updateHandler(regionBusinessAreaForm); closeForm();}}
              >
                Submit
              </AButton>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  )
}

export default EditBranchesForm