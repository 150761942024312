import React, {useState} from "react";
import CustomChip from "views/pages/manage/common/customChip/CustomChip";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import TreeViewByAdd from "./../treeView/TreeViewByAdd";
import { Card, CardBody } from "reactstrap";
import ALoader from './../../../common/a-loader/index';
import { Divider } from "@mui/material";
import NoLecturesDisplay from "../lectureList/NoLecturesDisplay";
import EditConfirmation from './../lectureList/EditCofirmation';

const AddLectureFrom = ({
  currentLecture,
  onLectureSave,
  setCurrentLecture,
  onCurrentLectureDel,
  expended,
  isLoading,
  deleteHandler,
  ...props
}) => {
   const [openEditCnf, setOpenEditCnf]  = useState(false)
   
 const onSaveLectureHandler = ()=>{
       if(props.isEdit){
        setOpenEditCnf(true)
       }else{
        onLectureSave()
       }
  }
  if (!currentLecture.id_) return "";
  return (
    <Card style={{ flex: "2" }} className="">

      <CardBody className="d-flex" style={{ position: "relative" }}>
        {/* {isLoading && <ALoader />} */}
        <div style={{ flex: "2" }}>
          <div className="d-flex justify-content-between mb-2">
            <h3 className="heading-4 color-dark"> {props.isEdit ? 'Edit' : 'Add'} Lecture {currentLecture?.id_}</h3>
            <div>
              <AButton
                variant='link'
                updatedStyle={{ color: "#DC2804" }}
                onClick={onCurrentLectureDel}
              >
                Close
              </AButton>
              <AButton disabled={!currentLecture?.tableOfContent?.length} variant="primary_filled" onClick={onSaveLectureHandler}>
                Save Lecture
              </AButton>
            </div>
          </div>
          <Divider className='my-3' style={{borderStyle: 'dashed'}} />
          {!currentLecture?.tableOfContent?.length && (
            <NoLecturesDisplay />
          )}
          <TreeViewByAdd
            subjectChapters={currentLecture}
            deleteHandler={deleteHandler}
            expended={expended}
          />
        </div>
      <EditConfirmation
        open ={openEditCnf} 
        setOpen ={setOpenEditCnf}
        onSaveAgree  ={onLectureSave}
        counter ={currentLecture?.id_}
      />
      </CardBody>
    </Card>
  );
};

export default AddLectureFrom;
