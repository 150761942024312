import React, { useContext, useEffect, useState } from 'react'
import { FaUserCircle } from 'react-icons/fa';
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { Button, Card, CardBody, CardHeader, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Row } from 'reactstrap'
import CustomFormDisplayField from 'components/CustomNewFormDisplay';
import { PulseLoader } from 'react-spinners';
import { capitalize, fetchAllPostPromisedData, fetchAllPromisedData, fetchDetails } from '../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint, pages } from '../../common/constant';
import './index.css'
import { useHistory } from 'react-router';
import CommonInput from '../../common/formFeilds/input/commonInput';
import moment from 'moment';
import CustomButton from 'components/CustomButton';
import { ButtonText } from 'variables/Buttons';
import { PermissionContext, RolePermissions } from 'appContext';
import { PermisionDenied } from '../../common/commonComponents';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import PageLoadingAnimation from 'views/pages/manage/common/commonComponents/PageLoadingAnimation';
import { RiFileCopyLine } from 'react-icons/ri';
import { REACT_ICONS_STYLE } from '../constant/batch-search';
import { Tooltip } from '@mui/material';
import { getTimetable } from '../../academicPlanAndTest/academicTimetable/helper';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper';
import {ReactComponent as BackIcon} from "../../../../../assets/img/svg/BackButton.svg";
function BatchViewEdit(props) {
  let history = useHistory();

  const { action, id } = props.match.params;
  const [editMode, setEditMode] = useState(false);
  const [fetchFailed, setFetchFailed] = useState(false);

  // custom route permission to be added here
  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages['searchBatch']['id']);
  const [customRoutePermission, setCustomRoutePermission] = useState(true);

  const [saveApiLoader, setSaveApiLoader] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [values, setValues] = useState({});
  const [editValues, setEditValues] = useState({});

  const [schoolBoardData, setSchoolBoardData] = useState([]);
  const [mediumData, setMediumData] = useState([]);
  const [classTypeData, setClassTypeData] = useState([]);
  const [defaultIds, setDefaultIds] = useState({});

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const [toolTipText, setToolTipText] = useState("Copy")
  const [timetableExist, setTimetableExist] = useState(false);

  const checkTimetable = () => {
    getTimetable(id).then(res => {
      if (res) {
        setTimetableExist(true);
      } else {
        setTimetableExist(false);
      }
    })
  }

  const fetchDetailsCustom = async (url, dispLabel) => {
    const responseData = await fetchAllPromisedData(url, false);
    if (Array.isArray(responseData)) {
      // if(dispLabel=='courseId'){
      //   const result = responseData?.map((o) => {
      //     return {label: o[dispLabel], value: o[dispLabel]}
      //   }) 
      //   return result;
      // }
      if (dispLabel == 'examBoardTypeDispValue') {
        const result = responseData?.map((o) => {
          return { label: `${o[dispLabel]} - ${o['examBoardTypeDesc']}`, value: o['id'] }
        })
        return result;
      }
      // const result = responseData?.map((o) => {
      //   return {label: o[dispLabel], value: o['id']}
      // })
      // // console.log(result)
      // return result;
    }
    else return [];
  }

  const fetchDropDownData = async () => {
    const data = await fetchDetailsCustom(endpoint.examBoardType.getAllActive, 'examBoardTypeDispValue');
    setSchoolBoardData(data);
    fetchDetails(endpoint.classType.getAllActive, setClassTypeData, 'classType', 'select');
    fetchDetails(endpoint.languageMaster.getActiveLanguageMaster, setMediumData, 'language', 'select');
  }

  useEffect(() => {
    if (action === 'edit')
      userPermissions && userPermissions.includes('U') ? setCustomRoutePermission(true) : setCustomRoutePermission(false);
    else
      userPermissions && userPermissions.includes('R') ? setCustomRoutePermission(true) : setCustomRoutePermission(false);
  }, [action])
  useEffect(() => {
    if (action === 'edit') {
      setEditMode(true);
      fetchDropDownData();
    }
    else {
      setEditMode(false);
    }
    setValues({});
    setEditValues({});
    setFetchFailed(false);
    fetchBatchDetails();
    checkTimetable();
  }, [action, id])


  const getSingleIdMapping = async (url, key) => {
    const res = await fetchAllPromisedData(url, true);
    if (res?.code === 200) {
      if (key === 'examBoardTypeDispValue') return `${res?.data[key]} - ${res?.data['examBoardTypeDesc']}`
      else return res?.data[key];
    }
    else {
      setFetchFailed(true);
    }
  }
  const getMultipleIdMappingArray = async (url, key, idArr = []) => {
    const res = await fetchAllPromisedData(url, true);
    if (res?.code === 200) {
      const temp = [];
      res.data.map((el) => {
        idArr.map((e) => {
          if (el.id === e) {
            temp.push(el[key]);
          }
        })
      })
      return temp;
    }
    else setFetchFailed(true);
  }

  const getDisplayValue = (data, key) =>{
    if(Array.isArray(data?.[key])){
        return [data?.[key][0].dispValue]
    }else{
      return  data?.[key].dispValue
    }
  }
  const fetchBatchDetails = async () => {

    setApiLoader(true);
    const p = await Promise?.all([
      fetchAllPromisedData(`${endpoint.viewEditBatch.getBatchByIdV2}/${id}?requireDispValue=true`, true),
      ...(action==='view' ? [fetchDetailsCustom(endpoint.examBoardType.getAllActive, 'examBoardTypeDispValue')] : [])
    ])

    const batchDetails = p?.[0];
    const schoolBoardDisplayValue = p?.[1]?.find(item => item?.value === batchDetails?.data?.schoolBoard?.id)?.label;

    if (batchDetails?.code === 200) {
      const resData = batchDetails?.data;
      // const p = await Promise.all([
      //   // getSingleIdMapping(`${endpoint.classSection.getClassSection}/${resData?.classSection}`, 'classSectionDispValue'),
      //   // getSingleIdMapping(`${endpoint.classType.getClassTypeById}/${resData?.classType}`, 'classTypeDispValue'),
      //   // getSingleIdMapping(`${endpoint.examBoardType.getExamBoardTypeById}/${resData?.schoolBoard}`, 'examBoardTypeDispValue'),
      //   // getSingleIdMapping(`${endpoint.languageMaster.getLanguageMasterById}/${resData?.medium}`, 'languageDispValue'),
      //   // getSingleIdMapping(`${endpoint.academic_career.getById}/${resData?.academicCareer}`, 'academicCareerDispValue'),
      //   // getSingleIdMapping(`${endpoint.academic_group.getById}/${resData?.academicGroup}`, 'academicGroupDispValue'),
      //   // getSingleIdMapping(`${endpoint.academic_year.getById}/${resData?.academicYear}`, 'academicYearDisplayValue'),
      //   // getMultipleIdMappingArray(endpoint.term.getAllTerm, 'termDispValue', resData?.term),
      //   // getMultipleIdMappingArray(endpoint.academicPhase.getAll, 'academicPhaseDisplayValue', resData?.academicPhase),
      //   // // getMultipleIdMappingArray(endpoint.region.getAll, 'regionDispValue', resData?.region),
      //   // getSingleIdMapping(`${endpoint.region.getById}/${resData?.region}`, 'regionDispValue'),
      //   // // getMultipleIdMappingArray(endpoint.businessArea.getAll, 'businessAreaDispValue', resData?.businessArea),
      //   // getSingleIdMapping(`${endpoint.businessArea.getBusinessAreaById}/${resData?.businessArea}`, 'businessAreaDispValue'),
      //   // getMultipleIdMappingArray(endpoint.classes.getAll, 'classDispValue', resData?.batchClass),
      // ]);
      
      setDefaultIds({
        schoolBoard: resData?.schoolBoard?.id,
        medium: resData?.medium?.id,
        classType: resData?.classType?.id
      })
      setEditValues({ ...resData });

      setValues({
        ...resData,
        classSection: getDisplayValue(resData,'classSection' ),
        classType: getDisplayValue(resData,'classType' ),
        // schoolBoard: getDisplayValue(resData,'schoolBoard' ),
        schoolBoard: schoolBoardDisplayValue,
        medium: getDisplayValue(resData,'medium' ),
        academicCareer:getDisplayValue(resData,'academicCareer' ),
        academicGroup: getDisplayValue(resData,'academicGroup' ),
        academicYear: getDisplayValue(resData,'academicYear' ),
        term: getDisplayValue(resData,'term' ),
        academicPhase: getDisplayValue(resData,'academicPhase' ),
        region:getDisplayValue(resData,'region' ),
  
        businessArea: getDisplayValue(resData,'businessArea' ),
        batchClass: getDisplayValue(resData,'batchClass' )
      });
    
      setApiLoader(false);
    }
    else {
      setFetchFailed(true);
      setApiLoader(false);
    }
  }

  const getKeyValObj = (obj, key)=>{
    return ({id:obj[key]?.value, dispValue:obj[key]?.label})
    }

  //saveHandler to be added here when user clicks save button
  const saveHandler = async () => {
    setSaveApiLoader(true);
    // validations here
    if (!editValues?.batchCapacity) {
      failureToast('Please enter the value of Batch Capacity');
      return;
    }
    if (editValues?.batchCapacity < editValues?.currentStrength) {
      failureToast('Batch Capacity can not be less than Current Strength');
      return;
    }
    const updatedSchoolBoard = getKeyValObj(editValues, "schoolBoard");
    const _data = {
      schoolBoard: {...updatedSchoolBoard, dispValue: updatedSchoolBoard?.dispValue?.split(' -')?.[0]}, // this is to send only disp value and separate description of board
      classType: getKeyValObj(editValues, "classType"),
      medium: getKeyValObj(editValues, "medium"),
      isEnabledMsTeamsSync: editValues?.isEnabledMsTeamsSync,
      batchCapacity: editValues?.batchCapacity,
      batchEnrollmentStatus: editValues?.batchEnrollmentStatus,
      batchStatus: editValues?.batchStatus,
      batchStartDate: new Date(editValues?.batchStartDate),
      batchEndDate: new Date(editValues?.batchEndDate),
    }
    // console.log(_data);
    const res = await fetchAllPostPromisedData(`${endpoint.viewEditBatch.update}/${id}`, _data, 'put');

    if (res?.code === 200) {
      successToast(res?.['message']);
      history.push(`/admin/edp/batch/view/${id}`);
    }
    // else {
    //   failureToast(res['message']);
    // }
    setSaveApiLoader(false);
  }

  const getDefaultSelect = (dataArray, defaultValue) => {
    let obj = dataArray.find(o => o.value === defaultValue);
    return obj;
  }
  useEffect(() => {
    const defaultSchoolBoard = getDefaultSelect(schoolBoardData, defaultIds?.schoolBoard);
    const defaultMedium = getDefaultSelect(mediumData, defaultIds?.medium);
    const defaultClassType = getDefaultSelect(classTypeData, defaultIds?.classType);
    setEditValues(prevState => ({ ...prevState, schoolBoard: defaultSchoolBoard, medium: defaultMedium, classType: defaultClassType }));
  }, [schoolBoardData, mediumData, classTypeData, defaultIds]);

  // const createTimetableClickHandler = () => {
  //   getTimetable(id).then(res => {
  //     if (res && res.length > 0) {
  //       if(!view) history.push(`/admin/testScheduling/timetable/edit/${id}`);
  //       else history.push
  //     } else {
  //       history.push(url);
  //     }
  //   })
  // }

  return customRoutePermission ? (
    apiLoader ? (
      <PageLoadingAnimation />
    ) : (
      <Container fluid className="mt-3">
        <Row>
          <div className="col">
            <Card className="mb-2">
              <CardHeader className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <div>
                    <IconButtonWrapper onClick={() => history.goBack()}>
                      <BackIcon/>
                    </IconButtonWrapper>
                  </div>
                  <h3
                    style={{ fontSize: "28px" }}
                    className="card-header-black mb-0"
                  >
                    Batch:{" "}
                    <PulseLoader size="5px" color="gray" loading={apiLoader} />{" "}
                    {values?.batchName}
                  </h3>
                  {/* Button to add here */}
                  <Tooltip title={toolTipText}>
                    <span>
                      <RiFileCopyLine
                        style={{ ...REACT_ICONS_STYLE, marginBottom: "1rem" }}
                        onClick={() => {
                          navigator.clipboard.writeText(values?.batchName);
                          setToolTipText("Copied");
                        }}
                      />
                    </span>
                  </Tooltip>
                </div>
                <div className="floatRight">
                  <Button
                    color="link"
                    style={{ fontSize: "18px" }}
                    onClick={() => {
                      history.push(`/admin/edp/deallocateStudents/${id}`);
                    }}
                  >
                    <FaUserCircle /> View students
                  </Button>
                  {/* time table button to be enabled after time table module release */}
                  {/* <Button color="link" style={{ fontSize: '16px' }}>
                    <MdOutlineTableChart /> View time table
                  </Button> */}
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggleDropdown}
                    direction={"left"}
                  >
                    <DropdownToggle
                      style={{
                        padding: "0",
                        border: "none",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      }}
                    >
                      <IconButtonWrapper>
                        <BiDotsVerticalRounded size={"25px"} color="#00aeef" />
                      </IconButtonWrapper>
                    </DropdownToggle>
                    <DropdownMenu>
                      {/* {disableSecondaryAllocation ? (
                          <DropdownItem className='custom_dropdownItem'>
                            <Tooltip
                              title='Allocation of students in secondary batches is temporarily restricted for users having access of more than one region. This will be allowed for all such users in due course of time'
                            >
                              <div>Allocate Students</div>
                            </Tooltip>
                          </DropdownItem>
                        ) : (
                          <DropdownItem className='custom_dropdownItem' onClick={() => history.push(`/admin/edp/allocateStudents/${id}`)}>Allocate Students</DropdownItem>
                        )} */}
                      <DropdownItem
                        className="custom_dropdownItem"
                        onClick={() =>
                          history.push(`/admin/edp/allocateStudents/${id}`)
                        }
                      >
                        Allocate Students
                      </DropdownItem>
                      {values?.batchType === "SECONDARY" &&
                        values?.batchStatus === "ACTIVE" && (
                          <DropdownItem
                            className="custom_dropdownItem"
                            onClick={() => {
                              timetableExist
                                ? history.push(
                                    `/admin/testScheduling/timetable/edit/${id}`
                                  )
                                : history.push(
                                    `/admin/testScheduling/timetable/create/${id}`
                                  );
                            }}
                          >
                            Create/Edit Timetable
                          </DropdownItem>
                        )}
                      {values?.batchType === "SECONDARY" && timetableExist && (
                        <DropdownItem
                          className="custom_dropdownItem"
                          onClick={() =>
                            history.push(
                              `/admin/testScheduling/timetable/view/${id}`
                            )
                          }
                        >
                          View Timetable
                        </DropdownItem>
                      )}
                      {/* <DropdownItem className='custom_dropdownItem' onClick={() => history.push(`/admin/edp/createBatch/${id}`)}>Copy Batch</DropdownItem> */}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </CardHeader>
              <CardBody>
                <h3
                  className="mb-4"
                  style={{ fontSize: "22px", fontWeight: "bold" }}
                >
                  Batch Details
                </h3>
                <Row>
                  <Col className="displayField" md="4">
                    <FormGroup
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Label className="mb-0 mr-2">Batch Type : </Label>
                      <div style={{ fontWeight: "bold" }}>
                        <PulseLoader
                          size="5px"
                          color="gray"
                          loading={apiLoader}
                        />
                        {capitalize(values?.batchType)}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4" className="displayField">
                    <CustomFormDisplayField
                      label="Term"
                      isFetching={apiLoader}
                      isMultiselectDisplay={true}
                      dropdownHeader={true}
                      data={values?.term}
                    />
                  </Col>
                  <Col md="4" className="displayField">
                    <CustomFormDisplayField
                      label="Class Section"
                      isFetching={apiLoader}
                      text={values?.classSection}
                    />
                  </Col>
                  <Col md="4" className="displayField">
                    {editMode ? (
                      <CommonInput
                        label={"Class Type"}
                        type={"select"}
                        placeHolder={"Select Class Type"}
                        defaultValue={editValues?.classType}
                        data={classTypeData}
                        onChange={(e) =>
                          setEditValues((prevState) => ({
                            ...prevState,
                            classType: e,
                          }))
                        }
                      />
                    ) : (
                      <CustomFormDisplayField
                        label="Class Type"
                        isFetching={apiLoader}
                        text={values?.classType}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="4" className="displayField">
                    {editMode ? (
                      <CommonInput
                        label={"School Board"}
                        type={"select"}
                        placeHolder={"Select School Board"}
                        defaultValue={editValues?.schoolBoard}
                        data={schoolBoardData}
                        onChange={(e) =>
                          setEditValues((prevState) => ({
                            ...prevState,
                            schoolBoard: e,
                          }))
                        }
                      />
                    ) : (
                      <CustomFormDisplayField
                        label="School Board"
                        isFetching={apiLoader}
                        text={values?.schoolBoard}
                      />
                    )}
                  </Col>
                  <Col md="4" className="displayField">
                    {editMode ? (
                      <CommonInput
                        label={"Medium"}
                        type={"select"}
                        placeHolder={"Select Medium"}
                        defaultValue={editValues?.medium}
                        data={mediumData}
                        onChange={(e) =>
                          setEditValues((prevState) => ({
                            ...prevState,
                            medium: e,
                          }))
                        }
                      />
                    ) : (
                      <CustomFormDisplayField
                        label="Medium"
                        isFetching={apiLoader}
                        text={values?.medium}
                      />
                    )}
                  </Col>
                  <Col md="4" className="displayField">
                    <CustomFormDisplayField
                      label="MS-Teams Sequence"
                      isFetching={apiLoader}
                      text={
                        apiLoader ? null : values?.msTeamsGroupSequence ?? "-"
                      }
                    />
                  </Col>
                </Row>
                <hr />

                <h3
                  className="mt-3 mb-4"
                  style={{ fontSize: "22px", fontWeight: "bold" }}
                >
                  Academic Details
                </h3>
                <Row>
                  <Col md="4" className="displayField">
                    <CustomFormDisplayField
                      label="Academic Career"
                      isFetching={apiLoader}
                      text={values?.academicCareer}
                    />
                  </Col>
                  <Col md="4" className="displayField">
                    <CustomFormDisplayField
                      label="Academic Group"
                      isFetching={apiLoader}
                      text={values?.academicGroup}
                    />
                  </Col>
                  <Col md="4" className="displayField">
                    <CustomFormDisplayField
                      label="Course ID"
                      isFetching={apiLoader}
                      isMultiselectDisplay={true}
                      dropdownHeader={true}
                      data={values?.courseId}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="4" className="displayField">
                    <CustomFormDisplayField
                      label="Academic Year"
                      isFetching={apiLoader}
                      text={values?.academicYear}
                    />
                  </Col>
                  <Col md="4" className="displayField">
                    <CustomFormDisplayField
                      label="Academic Phase"
                      isFetching={apiLoader}
                      isMultiselectDisplay={true}
                      dropdownHeader={true}
                      data={values?.academicPhase}
                    />
                  </Col>
                  <Col md="4" className="displayField">
                    <CustomFormDisplayField
                      label="Batch Class"
                      isFetching={apiLoader}
                      isMultiselectDisplay={true}
                      dropdownHeader={true}
                      data={values?.batchClass}
                    />
                  </Col>
                </Row>
                <hr />

                <h3
                  className="mt-3 mb-4"
                  style={{ fontSize: "22px", fontWeight: "bold" }}
                >
                  Branch Details
                </h3>
                <Row>
                  <Col md="4" className="displayField">
                    {/* <CustomFormDisplayField
                      label='Region'
                      isFetching={apiLoader}
                      isMultiselectDisplay={true}
                      dropdownHeader={true}
                      data={values?.region}
                    /> */}
                    <CustomFormDisplayField
                      label="Region"
                      isFetching={apiLoader}
                      text={values?.region}
                    />
                  </Col>
                  <Col md="4" className="displayField">
                    {/* <CustomFormDisplayField
                      label='Business Area'
                      isFetching={apiLoader}
                      isMultiselectDisplay={true}
                      dropdownHeader={true}
                      data={values?.businessArea}
                    /> */}
                    <CustomFormDisplayField
                      label="Business Area"
                      isFetching={apiLoader}
                      text={values?.businessArea}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <h3
                  className="mb-4"
                  style={{ fontSize: "22px", fontWeight: "bold" }}
                >
                  Operational Details
                </h3>
                <Row>
                  <Col md="4" className="displayField">
                    <FormGroup
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Label className="mb-0 mr-2">Batch Status : </Label>
                      {apiLoader ? (
                        <PulseLoader
                          size="5px"
                          color="gray"
                          loading={apiLoader}
                        />
                      ) : (
                        <span>
                          <label className="custom-toggle mx-auto ml-2">
                            <input
                              disabled={!editMode}
                              checked={
                                editValues?.batchStatus === "ACTIVE"
                                  ? true
                                  : false
                              }
                              type="checkbox"
                              id={editValues?.id}
                              onChange={(e) => {
                                let n = "ACTIVE";
                                if (!e.target.checked) {
                                  n = "INACTIVE";
                                }
                                setEditValues((prevState) => ({
                                  ...prevState,
                                  batchStatus: n,
                                }));
                              }}
                            />
                            <span
                              style={{ width: "72px" }}
                              className="custom-toggle-slider rounded-circle activeToggle"
                              data-label-off="Inactive"
                              data-label-on="Active"
                            />
                          </label>
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="4" className="displayField">
                    <FormGroup
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Label className="mb-0 mr-2">Enrollment Status : </Label>
                      {apiLoader ? (
                        <PulseLoader
                          size="5px"
                          color="gray"
                          loading={apiLoader}
                        />
                      ) : editMode ? (
                        <div
                          className="ml-4"
                          onChange={(e) => {
                            setEditValues((prevState) => ({
                              ...prevState,
                              batchEnrollmentStatus: e.target.value,
                            }));
                          }}
                        >
                          <Label className="mr-4" check>
                            <Input
                              type="radio"
                              value="OPEN"
                              name="enrollmentStatus"
                              checked={
                                editValues?.batchEnrollmentStatus === "OPEN"
                              }
                            />
                            {"Open"}
                          </Label>
                          <Label check>
                            <Input
                              type="radio"
                              value="CLOSED"
                              name="enrollmentStatus"
                              checked={
                                editValues?.batchEnrollmentStatus === "CLOSED"
                              }
                            />
                            {"Closed"}
                          </Label>
                        </div>
                      ) : (
                        <div style={{ fontWeight: "bold" }}>
                          {capitalize(values?.batchEnrollmentStatus)}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="4" className="displayField">
                    <FormGroup
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Label className="mb-0 mr-2">Current Strength : </Label>
                      <PulseLoader
                        size="5px"
                        color="gray"
                        loading={apiLoader}
                      />
                      {!apiLoader && (
                        <div style={{ fontWeight: "bold" }}>
                          {values?.currentStrength} students
                          <Button
                            color="link"
                            className="custom_link_button"
                            onClick={() =>
                              history.push(
                                `/admin/edp/deallocateStudents/${id}`
                              )
                            }
                          >
                            View all
                          </Button>
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4" className="displayField">
                    {editMode ? (
                      <CommonInput
                        type={"number"}
                        label={"Batch Capacity"}
                        mandatory={true}
                        defaultValue={editValues?.batchCapacity}
                        onChange={(value) => {
                          setEditValues((prevState) => ({
                            ...prevState,
                            batchCapacity: value,
                          }));
                        }}
                      />
                    ) : (
                      <CustomFormDisplayField
                        label="Batch Capacity"
                        isFetching={apiLoader}
                        text={
                          apiLoader ? null : `${values?.batchCapacity} students`
                        }
                      />
                    )}
                  </Col>
                  <Col md="4" className="displayField">
                    {editMode ? (
                      <CommonInput
                        label={"Start Date"}
                        type={"datePicker"}
                        disabled={
                          new Date(editValues?.batchStartDate) <
                          new Date().setHours(0, 0, 0, 0)
                        }
                        minDate={moment(new Date())}
                        defaultValue={editValues?.batchStartDate}
                        onChange={(date) => {
                          setEditValues((prevState) => ({
                            ...prevState,
                            batchStartDate: date,
                          }));
                        }}
                      />
                    ) : (
                      <CustomFormDisplayField
                        label="Start Date"
                        text={
                          apiLoader
                            ? null
                            : new moment(values?.batchStartDate).format(
                                "DD-MM-YYYY"
                              )
                        }
                        isFetching={apiLoader}
                      />
                    )}
                  </Col>
                  <Col md="4" className="displayField">
                    {editMode ? (
                      <CommonInput
                        label={"End Date"}
                        type={"datePicker"}
                        isDisabled={
                          new Date(editValues?.batchStartDate) <
                            new Date().setHours(0, 0, 0, 0) ||
                          new Date(editValues?.batchEndDate) <
                            new Date().setHours(0, 0, 0, 0)
                        }
                        minDate={moment(
                          new Date(editValues?.batchStartDate)
                        ).add(1, "days")}
                        defaultValue={editValues?.batchEndDate}
                        onChange={(date) => {
                          setEditValues((prevState) => ({
                            ...prevState,
                            batchEndDate: date,
                          }));
                        }}
                      />
                    ) : (
                      <CustomFormDisplayField
                        label="End Date"
                        text={
                          apiLoader
                            ? null
                            : new moment(values?.batchEndDate).format(
                                "DD-MM-YYYY"
                              )
                        }
                        isFetching={apiLoader}
                      />
                    )}
                  </Col>
                  {editMode && (
                    <Col className="displayField" md="4">
                      <FormGroup className="p-4" check>
                        <Label check>
                          <Input
                            disabled={values?.isEnabledMsTeamsSync}
                            type="checkbox"
                            checked={editValues.isEnabledMsTeamsSync}
                            onChange={(e) =>
                              setEditValues((prevState) => ({
                                ...prevState,
                                isEnabledMsTeamsSync: e.target.checked,
                              }))
                            }
                          />{" "}
                          Sync with MS Teams
                        </Label>
                      </FormGroup>
                    </Col>
                  )}
                </Row>
                <Row className={`d-flex pr-3 justify-content-end`}>
                  {!editMode && (
                    <CustomButton
                      type="edit"
                      className={"mr-3"}
                      disabled={apiLoader || fetchFailed}
                      content={ButtonText.VIEWBATCH.EDIT}
                      permissionType={"C,U"}
                      permissionSet={userPermissions} // permissionSet
                      onClick={() =>
                        history.push(`/admin/edp/batch/edit/${id}`)
                      } // taking 2 cases in edit if no studen allocated redirect to create batch
                    />
                  )}
                  {editMode && (
                    <>
                      <CustomButton
                        className={"mr-3"}
                        disabled={saveApiLoader || fetchFailed}
                        content={ButtonText.EDITBATCH.UPDATE}
                        permissionType={"C,U"}
                        permissionSet={userPermissions}
                        onClick={saveHandler}
                      />
                      <CustomButton
                        type="reset"
                        className={"mr-3"}
                        disabled={apiLoader}
                        content={ButtonText.EDITBATCH.CLOSE}
                        permissionType={"R"}
                        permissionSet={userPermissions}
                        onClick={() =>
                          history.push(`/admin/edp/batch/view/${id}`)
                        }
                      />
                    </>
                  )}
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    )
  ) : (
    <PermisionDenied />
  );
}

export default BatchViewEdit