import React, { useState } from "react";
import { withRouter } from "react-router";
import CustomLoader from "../../../common/commonComponents/Loader/loader";
import { Button, Card, Input, Table, CardFooter } from "reactstrap";
import Select2 from "react-select2-wrapper";
import moment from "moment";
import {
  failureToast,
  putpost,
  putpost2,
  successToast,
  examControllerServiceBaseUrl,
} from "../../../../../../services/http";
import { MasterHeaderAuto } from "../../../common/commonComponents/masterHeader/masterHeader";
import CustomDatePicker from "views/pages/manage/common/commonComponents/CustomDatePicker";

import CardHeader from "reactstrap/lib/CardHeader";

var _ = require("lodash");
const DetailsTable = (props) => {
  const {
    examData = [],
    setIsSaveVisible = () => { },
    isSaveVisible = false,
    setClassArray = () => { },
    classArray,
    examHeaderId,
    getData = () => { },
    isFetching,
    setIsFetching = () => { },
    examGrpData = [],
    classData = [],
    examTypeData = [],
    isView = false,
  } = props;
  const [examId, setExamId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [roomNumber, setRoomNumber] = useState(null);
  const addNewClass = () => {
    setClassArray([
      {
        examId: null,
        examStartDate: "",
        examEndDate: "",
        examStartTime: "",
        examEndTime: "",
        roomNumber: "",
        isEdit: false,
        isNew: true,
      },
      ...classArray,
    ]);
  };
  const headerList = [
    { name: "Exam ID", colSpan: "3", isRequired: true },
    { name: "Exam ID Description", colSpan: "3" },
    { name: "Exam Type", colSpan: "3" },
    { name: "Class", colSpan: "3" },
    { name: "Exam Group", colSpan: "3" },
    { name: "Exam Start Date", colSpan: "3", isRequired: true },
    { name: "Exam End Date", colSpan: "3", isRequired: true },
    { name: "Exam Start Time", colSpan: "3", isRequired: true },
    { name: "Exam End Time", colSpan: "3", isRequired: true },
    { name: "Room Number", colSpan: "3" },
    { name: "Action", colSpan: "3" },
  ];

  const newRow = () => {
    let cb = () => {
      addNewClass();
      setIsSaveVisible(true);
    };
    let _tempArray = classArray;
    _tempArray = _.sortBy(_tempArray, [
      function (o) {
        return o.value;
      },
    ]);
    setClassArray(_tempArray);
    setIsSaveVisible(false);
    cb();
  };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      setIsSaveVisible(true);
      let tempData = classArray;
      tempData[index]["isEdit"] = true;
      setExamId(tempData[index]["examId"]);
      setStartDate(tempData[index]["examStartDate"]);
      setEndDate(tempData[index]["examEndDate"]);
      setStartTime(tempData[index]["examStartTime"]);
      setEndTime(tempData[index]["examEndTime"]);
      setRoomNumber(tempData[index]["roomNumber"]);
    }
  };
  const deleteRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let tempData = classArray;
      let id = tempData[index]["id"];

      // id is used when api ready

      if (window.confirm("Are you sure you want to delete this Exam Detail?")) {
        putpost2(
          examControllerServiceBaseUrl + "/examDateDetails/deleteExamDateDetail/" + id,
          (data) => {
            successToast(data.message);
            getData();
            resetFields();
          },
          (data) => {
            failureToast(data.message);
          },
          "Delete"
        );
      }
    }
  };

  const saveRow = (index) => {
    let tempData = classArray;
    let isValid = true;
    if (!examId) {
      failureToast("Please Select Exam ID");
      isValid = false;
    } else if (!startDate) {
      failureToast("Please Select Start Date");
      isValid = false;
    } else if (!endDate) {
      failureToast("Please Select End Date");
      isValid = false;
    } else if (!startTime) {
      failureToast("Please Select Start Time");
      isValid = false;
    } else if (!endTime) {
      failureToast("Please Select End Time");
      isValid = false;
    } else {
      if (tempData[index]["isEdit"]) {
        let tempObject = {
          ...tempData[index],
          examId: examId,
          examStartDate: moment(startDate).format("DD-MM-YYYY"),
          examEndDate: moment(endDate).format("DD-MM-YYYY"),
          examStartTime: startTime,
          examEndTime: endTime,
          roomNumber: roomNumber,
          examHeaderId: examHeaderId,
        };
        tempData[index] = tempObject;
        setClassArray(tempData);
        let id = tempData[index]["id"];
        // id is used when API is ready.....

        //  url ='tempUrl';
        // putPostApi(`${concessionServiceBaseUrl}/addSubCategory`,tempObject,'post');
        putpost(
          `${examControllerServiceBaseUrl}/examDateDetails/updateExamDateDetail//${id}`,
          (data) => {
            getData();
            successToast(data.message);
            setIsSaveVisible(false);
            resetFields();
            // setIsSaveVisible(false);
            // tempData[index]["isEdit"] = false;
            // resetFields();
          },
          (data) => {
            failureToast(data.message);
          },
          tempObject,
          "put"
        );
      } else if (tempData[index]["isNew"]) {
        // putPostApi(`${concessionServiceBaseUrl}/addSubCategory`,tempObject,'post');
        // setIsSaveVisible(false);
        // resetFields()
        let tempObject = {
          examId: examId,
          examStartDate: moment(startDate).format("DD-MM-YYYY"),
          examEndDate: moment(endDate).format("DD-MM-YYYY"),
          examStartTime: startTime,
          examEndTime: endTime,
          roomNumber: roomNumber,
          examHeaderId: examHeaderId,
        };

        putpost(
          `${examControllerServiceBaseUrl}/examDateDetails/createExamDateDetail`,
          (data) => {
            successToast(data.message);
            // tempData.push(tempObject);
            // setClassArray(tempData);
            getData();
            setIsSaveVisible(false);
            // tempData[index]["isNew"] = false;
            resetFields();
          },
          (data) => {
            failureToast(data.message);
          },
          tempObject,
          "post"
        );

        // putPostApi(`${concessionServiceBaseUrl}/addSubCategory`,tempObject,'post');
      }
    }
  };

  const cancelRow = (index) => {
    let tempData = classArray;
    if (tempData[index]["isEdit"]) tempData[index]["isEdit"] = false;
    else if (tempData[index]["isNew"]) tempData.splice(index, 1);
    setClassArray(tempData);
    getData()
    resetFields();
  };

  const resetFields = () => {
    setExamId(null);
    setStartDate(null);
    setEndDate(null);
    setStartTime(null);
    setEndTime(null);
    setRoomNumber(null);
    setIsSaveVisible(false);
  };

  const TDROW = (props) => {
    const { data, index } = props;
    return (
      <tr>
        <td className="text-center p-2 mx-1" colSpan={3}>
          {getOtherValues(data["examId"], "text", examData)}
        </td>
        <td className="text-center p-2 mx-1" colSpan={3}>
          {getOtherValues(data["examId"], "examIdDescription", examData)}
        </td>
        <td className="text-center p-2 mx-1" colSpan={3}>
          {getOtherValues(
            getOtherValues(data["examId"], "examTypeId", examData),
            "text",
            examTypeData
          )}
        </td>
        <td className="text-center p-2 mx-1" colSpan={3}>
          {getOtherValues(
            getOtherValues(data["examId"], "classId", examData),
            "text",
            classData
          )}
        </td>
        <td className="text-center p-2 mx-1" colSpan={3}>
          {getOtherValues(
            getOtherValues(data["examId"], "examGroupId", examData),
            "text",
            examGrpData
          )}
        </td>
        <td className="text-center p-2 mx-1" colSpan={3}>
          {moment(data["examStartDate"]).format("DD-MM-YYYY")}
        </td>
        <td className="text-center p-2 mx-1" colSpan={3}>
          {moment(data["examEndDate"]).format("DD-MM-YYYY")}
        </td>
        <td className="text-center p-2 mx-1" colSpan={3}>
          {`${data["examStartTime"].split(":")[0]}:${data["examStartTime"].split(":")[1]
            }`}
        </td>
        <td className="text-center p-2 mx-1" colSpan={3}>
          {`${data["examEndTime"].split(":")[0]}:${data["examEndTime"].split(":")[1]
            }`}
        </td>
        <td className="text-center p-2 mx-1" colSpan={3}>
          {data["roomNumber"]}
        </td>
        <td className="text-center p-2 mx-1" colSpan={3}>
          <Button
            color={isView ? "secondary" : "info"}
            disabled={isView}
            size="sm"
            type="button"
            className={" mx-1"}
            onClick={() => {
              editRow(index);
            }}
          >
            <i className="fas fa-edit" />
          </Button>
          <Button
            disabled={isView}
            color={isView ? "secondary" : "info"}
            size="sm"
            type="button"
            className={" mx-1"}
            onClick={() => deleteRow(index)}
          >
            <i className="fas fa-trash" />
          </Button>
        </td>
      </tr>
    );
  };

  // const getShortDescription = (id) => {
  //     const i = subCategoryList.filter(item=> {return item.id == id})
  //     return i && i.length ? i[0] : null
  // }

  const getOtherValues = (id, value, list, log) => {
    log && console.log("i m in filter");
    log && console.log(id);
    log && console.log(list);
    const obj = list && list.find((o) => o.id == id);
    return obj ? obj[value] : "";
  };

  // const NewOrEditRow = (props) => {
  //   const { data, isSaveVisible, index } = props;
  //   return (

  //   );
  // };

  return (
    <Card>
      <CardHeader className="d-flex justify-content-between">
        <h3>Exam Details Configuration</h3>
        <Button
          disabled={isSaveVisible || isView}
          color="info"
          size="sm"
          type="button"
          className={
            "floatRight" + (isSaveVisible || isView ? " btn-dark" : null)
          }
          onClick={() => {
            newRow();
          }}
          data-testid="addNewInvoiceStatus"
        >
          New Exam Details
        </Button>
      </CardHeader>
      <div id="questionTable" className="table-responsive p-3">
        <Table className="align-items-center table-flush ">
          <MasterHeaderAuto headerList={headerList} />
          {isFetching || !examGrpData || !examTypeData || !classData ? (
            <div
              className="mx-auto text-center py-5 my-5"
              style={{ height: "100vh" }}
            >
              <CustomLoader apiLoader={isFetching} />
            </div>
          ) : (
            <tbody>
              {classArray.map((data, index) =>
                data["isEdit"] || data["isNew"] ? (
                  <tr key={data["id"] + "-class"}>
                    <td className="text-center p-2" colSpan={3}>
                      <Select2
                        defaultValue={examId}
                        data={examData}
                        id="examID_examConfig"
                        options={{ placeholder: "Select Exam ID" }}
                        onChange={(e) => {
                          setExamId(e.target.value);
                        }}
                      />
                    </td>
                    <td className="text-center p-2 mx-1" colSpan={3}>
                      <Input
                        value={
                          examId &&
                          getOtherValues(examId, "examIdDescription", examData)
                        }
                        disabled={true}
                        style={{ height: "39px" }}
                      />
                    </td>
                    <td className="text-center p-2 mx-1" colSpan={3}>
                      <Input
                        value={getOtherValues(
                          getOtherValues(examId, "examTypeId", examData),
                          "text",
                          examTypeData
                        )}
                        disabled={true}
                        style={{ height: "39px" }}
                      />
                    </td>
                    <td className="text-center p-2 mx-1" colSpan={3}>
                      <Input
                        value={getOtherValues(
                          getOtherValues(examId, "classId", examData),
                          "text",
                          classData
                        )}
                        disabled={true}
                        style={{ height: "39px" }}
                      />
                    </td>
                    <td className="text-center p-2 mx-1" colSpan={3}>
                      <Input
                        value={getOtherValues(
                          getOtherValues(examId, "examGroupId", examData),
                          "text",
                          examGrpData
                        )}
                        disabled={true}
                        style={{ height: "39px" }}
                      />
                    </td>
                    <td className="text-center p-2 mx-1" colSpan={3}>
                      {/* <Input
                        value={startDate}
                        type="date"
                        style={{ height: "39px" }}
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        onChange={(e) => {
                          setStartDate(e.target.value);
                          setEndDate("");
                        }}
                        max={"2099-01-01"}
                      /> */}
                      <CustomDatePicker
                        aria-label="startDate"
                        value={startDate}
                        placeholder="Example:01-01-2020"
                        handler={(i, v, k) => {
                          setStartDate(v);
                          setEndDate("");
                        }}
                        index={index}
                        rangeFrom={1950}
                        rangeTo={2099}
                        keyName='startDate'
                        forTable={true}
                        data-testid="startDate"
                        minDate={new Date()}
                      />
                    </td>
                    <td className="text-center p-2 mx-1" colSpan={3}>
                      {/* <Input
                        value={endDate}
                        type="date"
                        style={{ height: "39px" }}
                        min={startDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        disabled={!startDate}
                        max={"2099-01-01"}
                      /> */}
                      <CustomDatePicker
                        aria-label="endDate"
                        value={endDate}
                        placeholder="Example:01-01-2020"
                        handler={(i, v, k) => {
                          setEndDate(v);
                        }}
                        index={index}
                        rangeFrom={1950}
                        rangeTo={2099}
                        keyName='endDate'
                        forTable={true}
                        data-testid="endDate"
                        minDate={startDate}
                        disabled={!startDate}
                      />
                    </td>
                    <td className="text-center p-2 mx-1" colSpan={3}>
                      <Input
                        value={startTime}
                        type="time"
                        style={{ height: "39px" }}
                        onChange={(e) => {
                          setStartTime(e.target.value);
                          setEndTime("");
                        }}
                      />
                    </td>
                    <td className="text-center p-2 mx-1" colSpan={3}>
                      <Input
                        value={endTime}
                        type="time"
                        style={{ height: "39px" }}
                        onChange={(e) => setEndTime(e.target.value)}
                        disabled={!startTime}
                        min={startTime}
                      />
                    </td>
                    <td className="text-center p-2 mx-1" colSpan={3}>
                      <Input
                        value={roomNumber}
                        style={{ height: "39px" }}
                        maxLength="10"
                        onChange={(e) => setRoomNumber(e.target.value)}
                        id="roomNumber"
                      />
                    </td>
                    <td className="text-center p-2 mx-1">
                      <Button
                        color="info"
                        size="sm"
                        type="button"
                        className="mx-1"
                        onClick={() => saveRow(index)}
                      >
                        Save
                      </Button>
                      <Button
                        color="danger"
                        size="sm"
                        type="button"
                        onClick={() => {
                          cancelRow(index);
                        }}
                      >
                        <i className="fas fa-times" />
                      </Button>
                    </td>
                  </tr>
                ) : (
                  <TDROW
                    key={"TDROW" + index}
                    data={data}
                    index={index}
                    history={props.history}
                  />
                )
              )}
            </tbody>
          )}
        </Table>
      </div>
      <CardFooter className="d-flex justify-content-end">
        <Button
          color="info"
          size="sm"
          type="button"
          onClick={() => {
            props.history.push("/admin/examDateConfig");
          }}
        >
          {" "}
          Close
        </Button>
      </CardFooter>
    </Card>
  );
};

export default withRouter(DetailsTable);
