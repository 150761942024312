export const constants = {
    templates_service_name : {
        pricing : "PRICING",
        exam_id_bulk : "MASTER",
        rfid_master : "MASTER",
        sub_sub_topic_master : "MASTER",
        attendance_machine_master : "MASTER",
        topic_master : "MASTER",
        chapter_master : "MASTER",
        external_organization : "MASTER"
    },
    templates_sub_process_type : {
        rfid_master : "RFID",
        sub_sub_topic_master : "SUBSUBTOPIC",
        attendance_machine_master : "ATTENDENCE",
        topic_master : "TOPIC",
        chapter_master : "CHAPTER",
        external_organization : "EXTERNALORGANISATION"
    },
    course_fee_year_start_date_objs : {
        1 : "firstYearStartDate",
        2 : "secondYearStartDate",
        3 : "thirdYearStartDate",
        4 : "fourthYearStartDate",
        5 : "fifthYearStartDate",
        6 : "sixthYearStartDate",
        7 : "seventhYearStartDate",
        8 : "eightYearStartDate"
    },
    course_fee_year_end_date_objs : {
        1 : "firstYearEndDate",
        2 : "secondYearEndDate",
        3 : "thirdYearEndDate",
        4 : "fourthYearEndDate",
        5 : "fifthYearEndDate",
        6 : "sixthYearEndDate",
        7 : "seventhYearEndDate",
        8 : "eightYearEndDate"
    },
    course_fee_years : ["First", "Second", "Third", "Fourth", "Fifth", "Sixth","Seventh","Eighth"],

    sourceId : 1,

    PRICING_SERVICE_SUPPORTING_DOC_PROCESS_TAG : 'Pricing Service Bulk',

    PRICING_FLAG_FOR_DMS : true,
    FEE_SETUP_RULE_COURSE_ATTRIBUTE_DISP_VALUE : 'crct',

    pagination : {
        currentPage: 1,
        nextPage: null,
        previousPage: null,
        totalPage: null,
    },

    status: [
        {label: 'ACTIVE', value: 'ACTIVE'},
        {label: 'INACTIVE', value: 'INACTIVE'}
    ],
    availablityData: [
        { label: 'MANUAL', value: 'MANUAL' },
        { label: 'AUTO', value: 'AUTO' },
    ],
    
    applicableOnData: [
        { label: 'REGISTRATION_DATE', value: 'REGISTRATION_DATE' },
        { label: 'SYSTEM_DATE', value: 'SYSTEM_DATE' },
    ],

    ConcessionParameter: [
        { label: 'PERCENTAGE', value: 'PERCENTAGE' },
        { label: 'AMOUNT', value: 'AMOUNT' },
    ],
    
    scholarshipCriteriaData: [
        { label: 'RANK', value: 'RANK' },
        { label: 'PERCENTAGE', value: 'PERCENTAGE' },
        { label: 'PERCENTILE', value: 'PERCENTILE' },
        { label: 'GRADES', value: 'GRADES' },
        { label: 'MARKS', value: 'MARKS' },
        { label: 'QUALIFIED', value: 'QUALIFIED' }
    ],

    consessionType : [
        { label: 'Category', value: 'Category' },
        { label: 'Sub-Category', value: 'Sub-Category' },
        { label: 'Sub-Sub-Category', value: 'Sub-Sub-Category' }
    ],

    categoryCode : [
        {label: 'A',value: 'A'},
        {label : 'B', value: 'B'},
        {label : 'C', value: 'C'},
        {label : 'S', value: 'S'}
    ],
    paymentType : [
        {label : 'UPFRONT', value: 'UPFRONT'},
        {label: 'ADVANCE', value: 'ADVANCE'}
    ],

    facultyRole : [
        {label : 'Teaching', value: 'Teaching'},
        {label : 'Non-Teaching', value: 'Non-Teaching'},
        // {label : 'Both', value: 'Both'}
    ],
    APPROVAL_STAGE : {
        PENDING: 'pending',
        APPROVED: 'approved',
        REJECTED: 'reject',
        RECHECK: 'recheck'
    },
    APPROVAL_ACTIONS : {
        APPROVE: 'Approve',
        REJECT: 'Reject',
        RECHECK: 'Recheck'
    },
    STAGES : [
        { label: 'Pending', value: 'pending' },
        { label: 'Approved', value: 'approved' },
        { label: 'Rejected', value: 'reject' },
        { label: 'Recheck', value: 'recheck' },
    ],
    APPROVER_TYPE : {
        L1: "L1",
        L2: "L2",
        L3: "L3"
    },
    APPROVER_TYPES_DATA : [
        {label: "L1 Approval", value: "L1"},
        {label: "L2 Approval", value: "L2"},
        {label: "L3 Approval", value: "L3"},
    ],
    DOCUMENT_VERFICATION_STATUS: {
        PENDING: 'Pending',
        APPROVED: 'Approved'
    },
    DAY : [
        {label: "Monday", value: "Monday"},
        {label: "Tuesday", value: "Tuesday"},
        {label: "Wednesday", value: "Wednesday"},
        {label: "Thursday", value: "Thursday"},
        {label: "Friday", value: "Friday"},
        {label: "Saturday", value: "Saturday"},
        {label: "Sunday", value: "Sunday"}
    ]
} 