import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { ReactComponent as UpArrow } from "../../../../../assets/img/svg/downarrow.svg"
import { Row, Col } from "reactstrap";
import moment from "moment";
// import { makeStyles } from "@mui/material";

const TestDetailsAccordion = ({ 
    testData,
     idx ,
     expandedResultAccordion,
     handleChange
}) => {

    const summaryFields = [
        { field: 'Roll no', beField: 'rollNo' },

    ]
    const card1Fields = [
        // { field: 'AES_SNO', beField: 'aesNo'},
        // { field: 'Test Record Id', beField: 'testRecId'},
        { field: 'Test Date', beField: 'testDate' },
        { field: 'Test Code', beField: 'testCode' },
        { field: 'PSID', beField: 'psid' },
        { field: 'Campus', beField: 'campus' },
        { field: 'Course name', beField: 'courseName' },
    ]

    const card2Fields = [
        { field: 'Total Marks', beField: 'totalMarks' },
        { field: 'Percentage', beField: 'percentage' },
        { field: 'Percentile', beField: 'percentile' },
        { field: 'Class Rank', beField: 'classRank' },
        { field: 'All India Rank', beField: 'airRank' },
        { field: 'Adjusted Flag', beField: 'adjustFlag' },
    ]


    const cardStyles = {
        backgroundColor: '#F5F5F5',
        borderRadius: '8px',
        padding: '12px',
        width: '100%'
    }

    const fieldStyles = {
        fontSize: '14px',
        fontWeight: 400,
        color: '#0F0F0F',

    }

    const valueStyles = {
        ...fieldStyles,
        fontWeight: 600,
    }

    const summaryFieldStyles = {
        fontSize: '12px',
        fontWeight: 400,
        color: '#545454'
    }

    const summaryHeaderStyles = {
        ...valueStyles,
        fontSize: '16px'
    }

    const summaryValueStyles = {
        ...summaryFieldStyles,
        fontWeight: 600,
        marginRight: '10px'
    }

    const accordionStyles = {
        border: '1px solid #CBCBCB',
        borderRadius: '8px',
        marginBottom: '20px',
        '&:before': { height: '0px' },
    }

    const Pair = ({ field, value }) => {
        // console.log('key',key);
        return (
            <Row>
                {<Col style={fieldStyles} md='5'>{field || "-"}</Col>}
                {/* <span> : </span> */}
                <Col style={valueStyles} md='7'>{`: ${value || "-"}`}</Col>
            </Row>
        )
    }
    return (
        <Accordion
         elevation={0}
         sx={accordionStyles}
         expanded={idx===expandedResultAccordion}
         onChange={handleChange(idx)}
         >
            <AccordionSummary
                expandIcon={<UpArrow />}
                aria-controls={`panel${idx}a-content`}
                id={`panel${idx}a-header`}
            >

                <Row>
                    <Col md="12" style={summaryHeaderStyles}>{testData?.courseName || "-"}</Col>
                    <Col md="12">
                        <span style={summaryFieldStyles}>Roll no. :</span>
                        <span style={summaryValueStyles}>{testData?.rollNo || "-"}</span>
                        <span style={summaryFieldStyles}>AES_SNO :</span>
                        <span style={summaryValueStyles}> {testData?.aesSno || "-"}</span>

                        <span style={summaryFieldStyles}>Test Record Id :</span>
                        <span style={summaryValueStyles}>{testData?.testRecId || "-"}</span>
                    </Col>
                </Row>

            </AccordionSummary>
            <AccordionDetails>
                <Row>
                    <Col md="6" >
                        <div style={cardStyles} className='d-flex flex-column'>
                            <div style={{ ...valueStyles, marginBottom: '8px' }}> Personal Details</div>

                            {card1Fields.map(el => {
                                // console.log('el',el.field);
                                return (<Pair key={JSON.stringify(el)} field={el?.field} value={el.beField === 'testDate' ? moment(testData?.testDate).format('DD-MM-YY') : testData[el.beField]} />)
                            })}
                        </div>
                    </Col>
                    <Col md="6" >
                        <div style={cardStyles} className='d-flex flex-column'>
                            <div style={{ ...valueStyles, marginBottom: '8px' }}> Test Results</div>

                            {card2Fields.map(el => {
                                // console.log('el',el.field);
                                const value= testData[el.beField]
                                return (<Pair key={JSON.stringify(el)} field={el?.field} value={["percentage","percentile"].includes(el.beField) ? `${value}%` : value} />)
                            })}
                        </div>

                    </Col>
                </Row>
            </AccordionDetails>
        </Accordion>
    );
}

export default TestDetailsAccordion;