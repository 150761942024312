import React from "react";
import { ReactComponent as QrCodeIcon } from "../../../../../assets/img/svg/QrCodeIcon.svg"
import AButton from "../../common/form-fields-mui/AButton";

const EmptyQrListPlaceholder = ({setAddQrModalOpen, handleAddQr}) => {

    return (
        <div className="d-flex justify-content-center align-items-center w-100 vh-100">

            <div className="d-flex flex-column justify-content-center align-items-center">

                <QrCodeIcon className="empty-list-qrIcon mb-3" />

                <span className="manage-qr-hdr mb-3">Click here to add first QR code</span>

                <AButton
                    className="no-ml-btn"
                    variant="primary_filled"
                    children={'Add QR'}
                    onClick={handleAddQr}
                />

            </div>

        </div>
    );
}

export default EmptyQrListPlaceholder;