import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import FeeSearchApproval from '../Approval';
import { PermissionContext, RolePermissions } from 'appContext';
import { PermisionDenied } from '../../../../../common/commonComponents';
import { pages } from '../../../../../common/constant';

const FeeSearchL1Approval = props => {
  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages['level1Approval']['id']);

  return userPermissions ? (
    <FeeSearchApproval
      userId={10001}
      title="Course Fee Upload Level 1"
      statusTobeEditedForBulk="Submitted"
      statusTobeEditedForManual="Submitted"
      statusToBeView="L1 Approved"
      userPermissions={userPermissions}
      keyName="level1Approval"
    />
  ) : (
    <PermisionDenied />
  );
};

export default withRouter(FeeSearchL1Approval);
