import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import TermSessionTable from './termSessionTable';
import {getAPI,putpost,masterServiceBaseUrl} from 'services/http';
import { successToast, failureToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import CustomLoader from '../../../../common/commonComponents/Loader/loader';
import { PermisionDenied } from '../../../../common/commonComponents';

const TermSession = (props) => {
	const { userPermissions } = props;
	const { isNew = true, termId, initialDate, isViewOnly = false } = props;
	const [classArray, setclassArray] = useState([]);
	const [apiloader, setapiloader] = useState(false);
	const [sessionData, setSessionData] = useState([]);

	// const fetchClasses = async (url) => {
	//   setapiloader(true)
	//   getAPI(url, (data) => {
	//     setstate(data['data'])
	//    setclassArray(data['data'])
	//   }, (data) => { failureToast(data['message']) })
	// }

	const getAllTermSessionMaster = () => {
		setapiloader(true);
		getAPI(
			`${masterServiceBaseUrl}/termSessionMaster/getTermSessionByTermMapping/${termId}`,
			(data) => {
				setclassArray(data['data']);
				setapiloader(false);
			},
			(data) => {
				failureToast(data['message']);
				setapiloader(false);
			}
		);
	};

	const fetchSessionData = () => {
		setapiloader(true);
		getAPI(
			masterServiceBaseUrl + '/session/getAllActiveSession',
			(data) => {
				const list = data.data.map((data) => {
					// if (data.status === "ACTIVE") {
					return {
						value: data.id,
						label: data.sessionDispValue,
					};
					// }
				});
				setSessionData(list);
				setapiloader(false);
			},
			(data) => {
				failureToast(data['message']);
				setapiloader(false);
			}
		);
	};

	useEffect(() => {
		fetchSessionData();
	}, []);

	useEffect(() => {
		if (!isNew) {
			getAllTermSessionMaster();
		}
	}, [isNew]);

	return (
		<>
			{apiloader ? (
				<CustomLoader
					apiLoader={apiloader}
					loaderHeight={'200px'}
					loaderWidth={'100% '}
				/>
			) : (
				<>
					{userPermissions ? (
						<>
							<TermSessionTable
								sessionData={sessionData}
								isViewOnly={isViewOnly}
								setclassArray={setclassArray}
								fetchClasses={getAllTermSessionMaster}
								classArray={classArray}
								termId={termId}
								initialDate={initialDate}
								userPermissions={userPermissions}
							/>
						</>
					) : (
						<PermisionDenied />
					)}
				</>
			)}
		</>
	);
};

export default TermSession;