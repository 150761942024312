import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Card, CardHeader, Row } from 'reactstrap';
import FeeTable from '../feeTable';
import SupportingDocument from '../supportingDocument';
import CustomLoader from '../../../../../common/commonComponents/Loader/loader';
import Select from 'react-select';
import { fetchAllData } from '../../../../../common/utils/methods/commonMethods/utilityMethod';
import {
	getAPI,
	pricingServiceBaseUrl,
	masterServiceBaseUrl,
	putpost
} from 'services/http';
import {
	successToast,
	failureToast,
} from '../../../../../common/utils/methods/toasterFunctions/function';
import CustomButton from 'components/CustomButton';
import { PermissionContext, RolePermissions } from 'appContext';
import { pages } from '../../../../../common/constant';
import { PermisionDenied } from '../../../../../common/commonComponents';
import {
	GetPagination,
} from "../../../../../common/commonComponents/pagination/pagination";
import { SkeletonLoader } from "../../../../../common/commonComponents/SkeletonLoader"
import { fetchAllPromisedData } from '../../../../../common/utils/methods/commonMethods/utilityMethod';

let page = 0;
let totalNumberOfRecords = 0;
let isNextCallPossible = true;

const DocumentDetails = (props) => {

	const pageSize = 10;

	const { pid, action } = props.match.params;

	const [serverData, setServerData] = useState([]);
	const [tableData, setTableData] = useState([])
	const [isFetching, setIsFetching] = useState(true);
	const [cmyCode, setCmyCode] = useState(null);
	const [academicCareer, setAcademicCareer] = useState(null);
	const [term, setTerm] = useState(null);
	const [allCourseData, setAllCourseIdData] = useState(null);
	const [pricingZoneData, setPricingZoneData] = useState(null);
	const [pagination, setpagination] = useState({
		currentPage: 1,
		nextPage: null,
		previousPage: null,
		totalPage: null,
	});

	const getData = () => {
		getAPI(
			`${pricingServiceBaseUrl}/courseFeeProcessingTemp/getCourseFeeTempDataForPID/${pid}/${page}`,
			(data) => {
				setServerData(data.data.courseFeeProcessingApprovalDto);
				setPagination(data.data.courseFeeProcessingApprovalDto);
				isNextCallPossible = data.data.courseFeeProcessingApprovalDto.length == 50 ? true : false;
				setIsFetching(false);
			},
			(data) => {
				failureToast(data['message']);
				setIsFetching(false);
			}
		);
	};

	const permissions = useContext(PermissionContext);

	const userPermissions = RolePermissions(
		permissions,
		pages['processInstanceSearch']['id']
	);

	const setPagination = (data) => {
		let n = pagination;
		if (data.length > pageSize) {
			n["nextPage"] = n.currentPage + 1;
		}
		if (n.currentPage > 1) {
			n.previousPage = n.currentPage - 1;
		}
		n["totalPage"] = Math.floor(data.length / pageSize);
		if (data.length % pageSize != 0) {
			n["totalPage"] += 1;
		}
		setpagination(n);
		setTableData(
			data.slice(
				pagination.currentPage * pageSize - pageSize,
				pagination.currentPage * pageSize
			) || []
		);
		setIsFetching(false);
		// console.log("i m state in pagination" + serverData)
	}

	useEffect(() => {
		//const data = response;
		if (pid) {
			getData();
		}
	}, [pid]);

	useEffect(() => {
		fetchAllData(
			`${masterServiceBaseUrl}/companyCode/getAllCompanyCode`,
			setCmyCode
		);
		fetchAllData(
			`${masterServiceBaseUrl}/academicCareer/getAllAcademicCareer`,
			setAcademicCareer
		);
		fetchAllData(`${masterServiceBaseUrl}/term/getAllTerm`, setTerm);
		fetchAllData(
			`${masterServiceBaseUrl}/courseDetail/getAllCourseDetail`,
			setAllCourseIdData
		);
		fetchAllData(
			`${masterServiceBaseUrl}/pricingZone/getAllPricingZone`,
			setPricingZoneData
		);
	}, []);

	const fetchNext = async () => {
		// alert("i am called")
		if (isNextCallPossible) {
			setIsFetching(true)
			page = page + 1;
			const newData = await fetchAllPromisedData(`${pricingServiceBaseUrl}/courseFeeProcessingTemp/getCourseFeeTempDataForPID/${pid}/${page}`)
			if (newData && newData?.courseFeeProcessingApprovalDto && newData?.courseFeeProcessingApprovalDto?.length > 0) {
				setServerData([...serverData, ...newData.courseFeeProcessingApprovalDto]);
				setPagination([...serverData, ...newData.courseFeeProcessingApprovalDto])
				totalNumberOfRecords = newData?.totalPages * 50 ?? 0
				isNextCallPossible = newData.courseFeeProcessingApprovalDto.length == 50 ? true : false;
			} else {
				isNextCallPossible = false;
			}
		}

		setIsFetching(false)
	}


	return (
		<Container fluid className="mt-4">

			{userPermissions ? (
				<>
					<Card className="mt-4 overflow-auto">
						<CardHeader className="border-0">
							<h3 className="mb-0 floatLeft">Fee Details</h3>
						</CardHeader>
						{/* {serverData !== null ? (
							<>
								<FeeTable
									data={tableData}
									companyCode={cmyCode}
									academicCareer={academicCareer}
									term={term}
									pricingZone={pricingZoneData}
									CourseData={allCourseData}
								/>
							</>
						) : (
							<div
								className="mx-auto text-center py-5 my-5"
								style={{ height: '100vh' }}
							>
								<CustomLoader apiLoader={isFetching} />
							</div>
						)} */}
						{isFetching ? (
							<Card className="mt-4 p-4">
								<SkeletonLoader rows={6} apiLoader={isFetching}/>
							</Card>
						) :
							<>
								<FeeTable
									data={tableData}
									companyCode={cmyCode}
									academicCareer={academicCareer}
									term={term}
									pricingZone={pricingZoneData}
									CourseData={allCourseData}
									isNextCallPossible={isNextCallPossible}
									fetchNext={fetchNext}
									pagination={pagination}
									setpagination={setpagination}
									tableData={tableData}
									setTableData={setTableData}
									isFetching={isFetching}
									state={serverData}
									pageSize={pageSize}
								/>
								{/* {pagination.totalPage > 1 ? (
									<GetPagination
										isSaveVisible={false}
										setclassArray={setTableData}
										pagination={pagination}
										setpagination={setpagination}
										pageSize={pageSize}
										state={serverData}
										isPaginationFromServer={true}
										fetchNext={fetchNext}
										isFetching={isFetching}
										currentPage={tableData}
										totalNumberOfRecords={totalNumberOfRecords}
										isNextCallPossible={isNextCallPossible}
									/>
								) : null} */}
							</>
						}

					</Card>
					<SupportingDocument
						isTitleRequired={true}
						action={"view"}
						pid={pid}
						userPermissions={userPermissions}
					/>


					{action === 'view' && serverData !== null && <Row className="justify-content-end flex-row pr-3">
						<CustomButton
							type="normal"
							// className={'mx-1'}
							content={"Close"}
							// color="info"
							permissionType={'R'}
							onClick={() => props.history.push('/admin/processInstanceSearch')}
							permissionSet={userPermissions}
						/>
					</Row>}

				</>
			) : (
				<PermisionDenied />
			)}


		</Container>
	);
};

export default withRouter(DocumentDetails);
