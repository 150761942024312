import React, { useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Select from 'react-select';
import moment from 'moment';
import { GetPagination } from '../../../../common/pagination';
import CustomDatePicker from '../../../../common/commonComponents/CustomDatePicker/index';
import {failureToast} from '../../../../common/utils/methods/toasterFunctions/function';
import { getDispValForReactSelectByOneID } from '../../../../common/utils/methods/commonMethods/utilityMethod';
import { RedAstric } from '../../../../examService/examIdGeneration/examIdSearchForm';
import CommonInput, {
	customStylesSelect,
	customTheme,
	stylesForFilledSelect
} from "../../../../common/formFeilds/input/commonInput";
import Tooltip from 'components/Tooltip';
import {commonMessages} from "../../../../common/constant";
import styled from "styled-components";
import {ButtonText} from "../../../../../../../variables/Buttons";
import CustomButton from "../../../../../../../components/CustomButton";
import { regex } from '../../../../common/constant';


// export const QuestionSVG =()=> (
// 	<svg version="1.1" viewBox="0 0 512 512" width="16px" height="16px">
// 		<path
// 			fill="currentColor"
// 			d={`M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z`}
// 		/>
// 	</svg>
// )



const StyledDiv = styled.div`
 	marginLeft : 20px !important;
 	display: inline-block !important;
 	paddingTop: 9px !important;
	paddingBottom: 5px !important;
	paddingLeft: 5px !important;
	paddingRight: 5px !important;
	borderRadius :8px !important;
`;
const CourseDetails = (props) => {
	const {
		setCheckCourseDetail,
		academicCareerDropdown,
		academicGroupDropdown,
		setCheckAddNewCourse,
		courseDetailsForm,
		setCourseDetailsForm,
		courseHeaderObj,
		permissionSet
	} = props;
	const [courseId, setCourseId] = useState();
	const [showAddNewDetails, setShowAddNewDetails] = useState(true);
	const [isDisabled, setIsDisabled] = useState(false);
	const [currentFormId, setCurrentFormId] = useState(0);
	const [pagination, setpagination] = useState({
		currentPage: 1,
		nextPage: null,
		previousPage: null,
		totalPage: null,
	});
	const pageSize = 1;
	useEffect(() => {
		let allCourseDetails = props.allCourseDetails;
		if (props.allCourseDetails && props.allCourseDetails.length > 0) {
			const newState = [];
			allCourseDetails.forEach((item) => {
				let newFormObject = {
					id: item.id ? item.id : undefined,
					effectiveDateFrom: item.effectiveFrom,
					effectiveDateFromState: 'valid',
					effectiveDateTo: item.effectiveTo,
					effectiveDateToState: 'valid',
					courseTitle: item.courseTitle,
					courseTitleState: 'valid',
					courseName: item.courseName,
					courseNameState: 'valid',
					courseDescription: item.courseDescription,
					courseDescriptionState: 'valid',
					courseId: item.courseId,
					academicGroup: item.academicGroupId,
					academicGroupState: 'valid',
					subjectArea: item.subjectAreaId,
					subjectAreaState: 'valid',
					academicCareer: item.academicCareerId,
					academicCareerState: 'valid',
					courseComponent: item.courseComponentId,
					courseComponentState: 'valid',
					syncWithWebsite: item.syncWithWebsite,
					franchiseCommissionApplicable : item.franchiseCommissionApplicable,
				};
				newState.push(newFormObject);
			});
			setCourseDetailsForm(newState);
			setCourseId(allCourseDetails[0].courseId);
			//set pagination for existing data
			let n = pagination;

			// n['currentPage'] = 1;
			n['currentPage'] = allCourseDetails.length;
			setCurrentFormId(n['currentPage'] - 1);
			if (newState.length > 1) {
				n['nextPage'] = n.currentPage + 1;
			}
			n['totalPage'] = newState.length;
			setpagination(n);
		} else {
			// or create
			let n = pagination;
			n['currentPage'] = currentFormId;
			if (courseDetailsForm.length > 1) {
				n['nextPage'] = n.currentPage + 1;
			}
			n['totalPage'] = courseDetailsForm.length;
			setpagination(n);
		}
	}, [props.allCourseDetails]);

	useEffect(() => {
		if (props.isSubmitClicked) {
			validateCourseDetailsForm();
			props.setIsSubmitClicked(false);
		}
		if (props.isNew) {
			setShowAddNewDetails(false);
		}
		if (props.action === 'view') {
			setIsDisabled(true);
			setShowAddNewDetails(false);
		} else if (props.action === 'edit') {
			setShowAddNewDetails(true);
		} else {
			setShowAddNewDetails(false);
		}
	}, [props.isSubmitClicked]);

	const fetchClasses = async (url) => {
		let n = pagination;
		if (courseDetailsForm.length > pageSize) {
			n['nextPage'] = n.currentPage + 1;
		}
		if (n.currentPage > 1) {
			n.previousPage = n.currentPage - 1;
		}
		n['totalPage'] = Math.floor(courseDetailsForm.length / pageSize);
		if (courseDetailsForm.length % pageSize != 0) {
			n['totalPage'] += 1;
		}
		setpagination(n);

	};
	const nextPage = () => {
		let n = pagination;
		if (n['currentPage'] !== n['totalPage']) {
			n['previousPage'] = n.currentPage;
			n['nextPage'] = n.currentPage + 1;
			n['currentPage'] = n['currentPage'] + 1;
			setpagination(n);
			setCurrentFormId(n['currentPage'] - 1);
		}
	};
	const firstPage = () => {
		let n = pagination;
		n['currentPage'] = 1;
		setCurrentFormId(n['currentPage'] - 1);
		n['previousPage'] = null;
		setpagination(n);
	};
	const lastPage = () => {
		let n = pagination;
		n['currentPage'] = n.totalPage;
		n['previousPage'] = n.totalPage - 1;
		setCurrentFormId(n['currentPage'] - 1);
		setpagination(n);
	};
	const previousPage = () => {
		if (pagination.currentPage != 1) {
			let n = pagination;
			n['currentPage'] = n['currentPage'] - 1;
			if (n['currentPage'] == 1) {
				n['previousPage'] = null;
			} else {
				n['previousPage'] = n.currentPage;
			}
			setCurrentFormId(n['currentPage'] - 1);
			setpagination(n);
		}
	};
	const [minDate, setMinDate] = useState('');
	const [tempData, setTempData] = useState([]);
	const onChangeFormData = (name, value, stateName) => {
		const newState = [...courseDetailsForm];
		if (newState && newState[currentFormId]) {
			if(name === 'effectiveDateFrom'){
				newState[currentFormId][name] = value;
				if(moment(value).isAfter(moment(newState[currentFormId]['effectiveDateTo']))  && action === 'edit'){
					newState[currentFormId]['effectiveDateTo'] = '';
				}
			} if(name ==='academicGroup'){
				newState[currentFormId][name] = value.value;
			}else {

				newState[currentFormId][name] = value;
			}
			if (newState) {
				if (newState[currentFormId]) {
					if (
						newState[currentFormId]['effectiveDateFrom'] === ''  ||
						newState[currentFormId]['effectiveDateTo'] === '' ||
						newState[currentFormId]['courseTitle'] === '' ||
						newState[currentFormId]['courseTitle'].trim().length === 0 ||
						newState[currentFormId]['courseName'] === '' ||
						newState[currentFormId]['courseName'].trim().length === 0 ||
						newState[currentFormId]['academicCareer'] === '' ||
						newState[currentFormId]['academicGroup'] === '' ||
						newState[currentFormId]['courseComponent'] === '' ||
						newState[currentFormId]['subjectArea'] === '' ||
						newState[currentFormId]['courseDescription'] === '' ||
						newState[currentFormId]['courseDescription'].trim().length === 0
					) {
						setCheckCourseDetail(false);
					}

					else {
						setCheckCourseDetail(true);
					}
				}
			}
		}
		let isValid = true;
		newState.map(
			(item) =>
				(isValid =
					item.effectiveDateFromState === 'valid' &&
					item.effectiveDateToState === 'valid' &&
					item.courseTitleState === 'valid' &&
					item.courseNameState === 'valid' &&
					item.courseDescriptionState === 'valid' &&
					item.academicGroupState === 'valid' &&
					item.academicCareerState === 'valid' &&
					item.subjectAreaState === 'valid' &&
					item.courseComponentState === 'valid')
		);
		onSubmitCourseDetails(newState);
		setCourseDetailsForm(newState);
	};

	const handleNewForm = () => {
		setCheckAddNewCourse(true);
		setCheckCourseDetail(false);
		const newState = [...courseDetailsForm];
		let newFormObject = {
			effectiveDateFrom: null,
			effectiveDateFromState: null,
			effectiveDateTo: null,
			effectiveDateToState: null,
			courseTitle: '',
			courseTitleState: null,
			courseName: '',
			courseNameState: null,
			courseDescription: '',
			courseDescriptionState: null,
			academicGroup: newState[0].academicGroup ? newState[0].academicGroup : '',
			academicGroupState: 'valid',
			subjectArea: newState[0].academicGroup ? newState[0].subjectArea : '',
			subjectAreaState: 'valid',
			academicCareer: newState[0].academicGroup
				? newState[0].academicCareer
				: '',
			academicCareerState: 'valid',
			courseComponent: newState[0].academicGroup
				? newState[0].courseComponent
				: '',
			courseComponentState: 'valid',
			syncWithWebsite: false,
			franchiseCommissionApplicable : false,
		};
		let len = newState.length;
		newFormObject = {
			...newFormObject,
			effectiveDateFrom : newState[len-1].effectiveDateTo && newState[len-1].effectiveDateTo !== '' ? moment(newState[len-1].effectiveDateTo).add(1, 'days').format('YYYY-MM-DD')  : null
		}
		newState.push(newFormObject);
		setCourseDetailsForm(newState);
		setCurrentFormId(newState.length - 1);
		let min = '';
		if (newState.length > 1 && newState[currentFormId - 1]) {
			min = newState[currentFormId - 1]['effectiveDateTo'];
			setMinDate(min);
		}
		setTempData(newState);
		let n = pagination;
		n['totalPage'] = newState.length;
		n['currentPage'] = newState.length;
		setpagination(n);
	};

	const showSelectOptionsAcademicGroup = (data = []) => {
		const dropdownValue =data && data.length && data.map((item) => {
			return {
				value: item.id,
				label: `${item.academicGroupDispValue}-${item.academicGroupDesc}`,
			};
		});
		return dropdownValue;
	};

	const showSelectOptionsAcademicCareer = (data) => {
		const options = data && data.length && data.map((item) => {
			return {
				value: item.id,
				label: `${item.academicCareerDispValue}-${item.academicCareerDesc}`,
			};
		});
		return options;
	};

	const showSelectOptionsSubjectArea = (data) => {
		const options = data.map((item) => {
			return {
				value: item.id,
				label: `${item.subjectAreaDispValue}-${item.subjectAreaDesc}`,
			};
		});
		return options;
	};

	const showSelectOptionsCourseComponent = (data) => {
		const options = data.map((item) => {
			return {
				value: item.id,
				label: `${item.courseComponentDispValue}-${item.courseComponentDesc}`,
			};
		});
		return options;
	};

	//validations
	const checkValidation = (string) => {
		let temp = string.split('');
		var reg_exp = /^[A-Za-z0-9 ]+$/;
		let flag = true;
		temp.map((c) => {
			if (c === '-' || c === '_' || c === ')' || c === '(' || c === '.' || c === '+' || c === '/')
				flag = true;
			else if (!reg_exp.test(c)) {
				flag = false;
			}
		});
		return flag;
	};
	const validateCourseDetailsForm = () => {
		let newState = [...courseDetailsForm];
		if (
			newState &&
			newState[currentFormId] &&
			newState[currentFormId].effectiveDateFrom === ''
			//   ||
			// newState[currentFormId].effectiveDateFrom.trim().length === 0
		) {
			failureToast('Please choose a valid Date.');
			// newState[currentFormId].effectiveDateFromState = 'invalid';
		}
		if (
			newState &&
			newState[currentFormId] &&
			newState[currentFormId].effectiveDateTo === ''
		) {
			failureToast('Please choose a valid Date.');
		}
		else if (
			newState &&
			newState[currentFormId] &&
			newState[currentFormId].courseTitle === ''
		) {
			failureToast('Please choose a valid Course Title.');
		}
		else if (
			newState &&
			newState[currentFormId] &&
			newState[currentFormId].courseName === ''
		) {
			failureToast('Please choose a valid Course Name.');
		}
		else if (
			newState &&
			newState[currentFormId] &&
			newState[currentFormId].courseDescription === ''
		) {
			failureToast('Please choose a valid Course Description.');
		}
		else if (
			newState &&
			newState[currentFormId] &&
			newState[currentFormId].academicGroup === ''
		) {
			failureToast('Please select an Academic Group.');
		}
		else if (
			newState &&
			newState[currentFormId] &&
			newState[currentFormId].subjectArea === ''
		) {
			failureToast('Please select a subject Area.');
		}
		else if (
			newState &&
			newState[currentFormId] &&
			newState[currentFormId].academicCareer === ''
		) {
			failureToast('Please select an Academic Career.');
		}
		else if (
			newState &&
			newState[currentFormId] &&
			newState[currentFormId].courseComponent === ''
		) {
			failureToast('Please select an Course Component.');
		}
		setCourseDetailsForm(newState);
	};

	const onSubmitCourseDetails = (newState) => {
		let isValid = true;
		if (isValid) {
			let data = [];
			newState.forEach((item) => {
				data = [
					...data,
					{
						id: item.id,
						effectiveFrom: item.effectiveDateFrom,
						effectiveTo: item.effectiveDateTo,
						courseName: item.courseName.trim(),
						courseTitle: item.courseTitle.trim(),
						courseDescription: item.courseDescription.trim(),
						courseId: courseId,
						academicGroupId: item.academicGroup,
						academicCareerId: item.academicCareer,
						subjectAreaId: item.subjectArea,
						courseComponentId: item.courseComponent,
						syncWithWebsite: item.syncWithWebsite ? item.syncWithWebsite : false,
						franchiseCommissionApplicable: item.franchiseCommissionApplicable ? item.franchiseCommissionApplicable : false,
					},
				];
			});
			//check if updated
			if (!props.isNew && props.formId) {
				props.setCourseDetailMasterDTO(data);
			} else {
				props.setCourseDetailMasterDTO(data);
			}
		}
	};
	const {
		academicGroup,
		academicCareer,
		subjectArea,
		action,
		courseComponent,
	} = props;

	const tooltipText = {
		courseName : 'Only ( ) . _ + / - special characters are allowed.',
		courseTitle:'Only ( ) . _ + / - special characters are allowed.',
		courseDescription : 'Only ( ) . _ + / - special characters are allowed.'
	};

	const [focus,setFocus] = useState({courseTitle:false,courseName:false,courseDes:false});



	return (
		<div>
			<>
				<Row>
					<Col md="6">
						<label className="form-control-label">
							Effective From <RedAstric />
						</label>
						<CustomDatePicker
							action={action}
							value={(courseDetailsForm  &&
								courseDetailsForm[currentFormId]) ?
								courseDetailsForm[currentFormId].effectiveDateFrom:null}

							// value={courseDetailsForm[currentFormId].effectiveDateFrom}
							minDate={
								currentFormId > 0 &&
								tempData &&
								tempData.length > 1 &&
								tempData[currentFormId - 1]['effectiveDateTo']
									?
										moment(tempData[currentFormId - 1]['effectiveDateTo'])
											.add(1, 'days')
											.toDate()
									: moment().subtract(71, 'years').toDate()
							}
							maxDate={moment().add(78, 'years').toDate()}
							index={currentFormId}
							handler={(currentFormId,value,keyName) => {
								onChangeFormData('effectiveDateFrom',moment(value).format('YYYY-MM-DD'),'effectiveDateFromState');
							}}
							disabled={isDisabled || (action === 'new' && courseHeaderObj.status === '')||action === 'view'}
							keyName='effectiveDateFromState'
							rangeFrom={1950}
							rangeTo={2099}
							placeholder="Example:01-01-2020"
						/>
					</Col>
					<Col md="6">
						<label className="form-control-label">
							Effective To <RedAstric />
						</label>
						<CustomDatePicker
							action={action}
							value={(courseDetailsForm && courseDetailsForm[currentFormId]) ? courseDetailsForm[currentFormId].effectiveDateTo:undefined}
							minDate={
								courseDetailsForm &&
								courseDetailsForm[currentFormId] &&
								moment(courseDetailsForm[currentFormId].effectiveDateFrom).toDate()
							}
							maxDate={moment().add(78, 'years').toDate()}
							index={currentFormId}
							handler={(currentFormId,value,keyName) => {
								onChangeFormData('effectiveDateTo',moment(value).format('YYYY-MM-DD'),'effectiveDateToState');
							}}
							keyName='effectiveDateToState'
							rangeFrom={1950}
							rangeTo={2099}
							placeholder="Example:01-01-2020"
							disabled={action === 'view' || !courseDetailsForm[currentFormId].effectiveDateFrom}
						/>
					</Col>
				</Row>
				<Row className="mt-3">
					<Col md="6">
						<FormGroup row>
							<Col sm={12}>
								<Label className="form-control-label" htmlFor="example3cols2Input">
									Course Title <RedAstric />
									{
										action === 'view'
											?
											null :
											<Tooltip content={tooltipText.courseTitle} placement={'top'}>
												<span style={{marginLeft:'4px',color:'#8898aa'}}  id={'courseTitle'} className={'courseTitle'}>
													<i className="fas fa-info-circle"/>
												</span>
											</Tooltip>
									}
								</Label>
							</Col>
							<Col sm={12}>
								<Input
									type="text"
									autoComplete="off"
									style={(courseDetailsForm[currentFormId].courseTitle && courseDetailsForm[currentFormId].courseTitle !=='' && !(isDisabled || !courseDetailsForm[currentFormId].effectiveDateTo)) && action === 'new' ? {color:'#00AEEF',border:'1px solid #00AEEF',height:'40px'} :{height:'40px'}}
									value={
										courseDetailsForm &&
										courseDetailsForm[currentFormId] &&
										courseDetailsForm[currentFormId].courseTitle
									}
									onFocus={()=>setFocus({...focus,courseTitle:true})}
									onBlur={() => setFocus({...focus, courseTitle: false})}
									name="courseTitle"
									placeholder={'Course Title'}
									disabled={isDisabled || (!courseDetailsForm[currentFormId].effectiveDateTo && action === 'new')}
									onChange={(e) => {
										// if (checkValidation(e.target.value))
											onChangeFormData(
												e.target.name,
												e.target.value,
												'courseTitleState'
											);
									}}
									maxLength={100}
									onKeyPress={(e) => {
										if (
										  !regex.courseInfoRegex.test(
											String.fromCharCode(e.charCode)
										  )
										) {
										  e.preventDefault();
										  return;
										}
									  }}
								/>
								{
									focus.courseTitle ?
										<span style={{marginTop: '3px'}} className={"text-warning field-info float-right"}>
											Add upto {100 - courseDetailsForm[currentFormId].courseTitle.length} characters
										</span>
										:
										null
								}
							</Col>
						</FormGroup>
					</Col>
					<Col md="6">
						<FormGroup row>
							<Col sm={12}>
								<Label className="form-control-label" htmlFor="example3cols2Input">
									Course Name <RedAstric />
									{
										action === 'view'
											?
											null :
											<Tooltip content={tooltipText.courseName} placement={'top'}>
											<span style={{marginLeft: '4px', color: '#8898aa'}} id={'courseName'}
												  className={'courseName'}>
												<i className="fas fa-info-circle"/>
											</span>
											</Tooltip>
									}
								</Label>
							</Col>
							<Col sm={12}>
								<Input
									type="text"
									autocomplete="off"
									style={(courseDetailsForm[currentFormId].courseName && courseDetailsForm[currentFormId].courseName !=='' && !(isDisabled || ( action === 'new' && courseDetailsForm[currentFormId].courseTitle === ''))) && action === 'new' ? {color:'#00AEEF',border:'1px solid #00AEEF',height:'40px'}: {height:'40px'}}
									value={
										courseDetailsForm &&
										courseDetailsForm[currentFormId] &&
										courseDetailsForm[currentFormId].courseName
									}
									placeholder={'Course Name'}
									name="courseName"
									onChange={(e) => {
										// if (checkValidation(e.target.value))
											onChangeFormData(
												e.target.name,
												e.target.value,
												'courseNameState'
											);
									}}
									onKeyPress={(e) => {
										if (
										  !regex.courseInfoRegex.test(
											String.fromCharCode(e.charCode)
										  )
										) {
										  e.preventDefault();
										  return;
										}
									  }}
									maxLength={250}
									onFocus={()=>setFocus({...focus,courseName:true})}
									onBlur={() => setFocus({...focus, courseName: false})}
									disabled={isDisabled || ( action === 'new' && courseDetailsForm[currentFormId].courseTitle === '') || ( action === 'new' && !courseDetailsForm[currentFormId].courseTitle.trim().length)}
								/>
								{
									focus.courseName ?
										<span style={{marginTop: '3px'}} className={"text-warning field-info float-right"}>
											Add upto {250 - courseDetailsForm[currentFormId].courseName.length} characters
										</span>
										:
										null
								}
							</Col>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col md="12">
						<FormGroup row>
							<Col sm={12}>
								<Label className="form-control-label" htmlFor="example3cols2Input">
									Course Description <RedAstric />
									{
										action === 'view'
											?
											null :
											<Tooltip content={tooltipText.courseDescription} placement={'top'}>
												<span style={{marginLeft: '4px', color: '#8898aa'}} id={'courseDesc'}
													  className={'courseDesc'}>
													<i className="fas fa-info-circle"/>
												</span>
											</Tooltip>
									}
								</Label>
							</Col>
							<Col sm={12}>
								<Input
									type="textarea"
									placeholder={'Course Description'}
									value={
										courseDetailsForm &&
										courseDetailsForm[currentFormId] &&
										courseDetailsForm[currentFormId].courseDescription
									}

									style={(courseDetailsForm[currentFormId].courseDescription && courseDetailsForm[currentFormId].courseDescription !=='' && !(isDisabled || ( action === 'new' && !courseDetailsForm[currentFormId].courseName)))  && action === 'new' ? {color:'#00AEEF',border:'1px solid #00AEEF'}: {}}
									name="courseDescription"
									onChange={(e) => {
										// if (checkValidation(e.target.value))
											onChangeFormData(
												e.target.name,
												e.target.value,
												'courseDescriptionState'
											);
									}}
									onKeyPress={(e) => {
										if (
										  !regex.courseInfoRegex.test(
											String.fromCharCode(e.charCode)
										  )
										) {
										  e.preventDefault();
										  return;
										}
									  }}
									maxLength={500}
									onFocus={()=>setFocus({...focus,courseDes:true})}
									onBlur={() => setFocus({...focus, courseDes: false})}
									disabled={isDisabled || ( action === 'new' && !courseDetailsForm[currentFormId].courseName) || ( action === 'new' && !courseDetailsForm[currentFormId].courseName.trim().length)}
								/>
								{
									focus.courseDes ?
										<span className={"text-warning field-info float-right"}>
											Add upto {500 - courseDetailsForm[currentFormId].courseDescription.length} characters
										</span>
										:
										null
								}
							</Col>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col md="6">
								{action === 'new'|| action ==='edit' ? (
										<CommonInput
											label={'Academic Group'}
											placeHolder={'Academic Group'}
											mandatory={true}
											type={ 'select'}
											data={showSelectOptionsAcademicGroup(academicGroup ) || []}
											onChange={(sData)=>{onChangeFormData('academicGroup', sData, 'academicGroupState')}}
											isDisabled={action === 'view' || ((courseDetailsForm[currentFormId].courseDescription === '' || !courseDetailsForm[currentFormId].courseDescription || !courseDetailsForm[currentFormId].courseDescription.trim().length) && action === 'new')}
											defaultValue={{
												label : courseDetailsForm && courseDetailsForm[currentFormId]?.academicGroup === ''
													?
													''
													:
													academicGroup &&
													academicGroup.find((item) => item.id === courseDetailsForm[currentFormId].academicGroup)?.academicGroupDispValue,
											}}
										/>

								) : (
									<CommonInput
										placeHolder={'Academic Group'}
										type={'text'}
										maxLength={500}
										defaultValue={courseDetailsForm &&
										courseDetailsForm[currentFormId] &&
										getDispValForReactSelectByOneID(
											courseDetailsForm[currentFormId].academicGroup,
											'DispValue',
											academicGroupDropdown
										)}
										label={'Academic Group'}
										isDisabled={true}
									/>
								)}
							</Col>
					<Col md="6">
						<FormGroup row>
							<Col  sm={12}>
								<Label className="form-control-label" htmlFor="example3cols2Input">
									Academic Career <RedAstric />
								</Label>
							</Col>
							<Col sm={12}>
								{action === 'new' ? (
									<Select
										name="academicCareer"
										placeholder={'Academic Career'}
										theme={customTheme}
										value={ courseDetailsForm[currentFormId].academicCareer ===
										'' ? '':{
											label:  academicCareer.find((item) =>item.id === courseDetailsForm[currentFormId].academicCareer &&
													  item.id === courseDetailsForm[currentFormId].academicCareer) &&
													  academicCareer.find((item) => item.id === courseDetailsForm[currentFormId].academicCareer &&
													  item.id === courseDetailsForm[currentFormId].academicCareer).academicCareerDispValue,
										}}
										options={showSelectOptionsAcademicCareer(academicCareer) || []}
										onChange={(name, value) => onChangeFormData(value.name, name.value, 'academicCareerState')}
										isDisabled={
											action === 'view' || courseDetailsForm[currentFormId].academicGroup ===  ''
											// props.action === 'edit'
											// 	?
											// 	isDisabled || courseDetailsForm[0].academicCareer
											// 	:
											// 	isDisabled || courseDetailsForm[currentFormId].academicGroup ===  ''
										}
										styles={(courseDetailsForm[currentFormId].academicCareer === ''  || isDisabled) ?customStylesSelect  : stylesForFilledSelect }
									/>
								) : (
									<Input
										type="text"
										value={
											courseDetailsForm &&
											courseDetailsForm[currentFormId] &&
											getDispValForReactSelectByOneID(
												courseDetailsForm[currentFormId].academicCareer,
												'DispValue',
												academicCareerDropdown
											)
										}
										style={{height:'40px'}}
										name="academicCareer"
										maxLength={500}
										disabled={true}
									/>
								)}
							</Col>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col md="6">
						<FormGroup row>
							<Col sm={12}>
								<Label className="form-control-label" htmlFor="example3cols2Input">
									Subject Area <RedAstric />
								</Label>
							</Col>
							<Col sm={12}>
								<Select
									theme={customTheme}
									placeholder="Select Subject Area"
									name="subjectArea"
									value={
										courseDetailsForm[currentFormId].subjectArea === '' ? '' :
										{
										label:
											props.action === 'edit'
												? subjectArea.find(
														(item) =>
															item.id === courseDetailsForm[currentFormId].subjectArea &&
															item.id === courseDetailsForm[currentFormId].subjectArea
												  ) &&
												  subjectArea.find(
														(item) =>
															item.id === courseDetailsForm[currentFormId].subjectArea &&
															item.id === courseDetailsForm[currentFormId].subjectArea
												  ).subjectAreaDispValue
												: courseDetailsForm[currentFormId].subjectArea === ''
												? 'Select Subject Area'
												: subjectArea.find(
														(item) =>
															item.id ===
																courseDetailsForm[currentFormId].subjectArea &&
															item.id ===
																courseDetailsForm[currentFormId].subjectArea
												  ) &&
												  subjectArea.find(
														(item) =>
															item.id ===
																courseDetailsForm[currentFormId].subjectArea &&
															item.id ===
																courseDetailsForm[currentFormId].subjectArea
												  ).subjectAreaDispValue,
									}}
									options={showSelectOptionsSubjectArea(subjectArea) || []}
									onChange={(name, value) => onChangeFormData(value.name, name.value, 'subjectAreaState')}
									styles={((courseDetailsForm[currentFormId].subjectArea === ''  || isDisabled) ) ? customStylesSelect  : stylesForFilledSelect }
									// styles={stylesForFilledSelect }

									isDisabled={
										action === 'view' || courseDetailsForm[currentFormId].academicCareer ===  ''
										// props.action === 'edit'
										// 	? isDisabled || courseDetailsForm[0].subjectArea
										// 	: isDisabled || courseDetailsForm[currentFormId].academicCareer ===  ''
									}
								/>
							</Col>
						</FormGroup>
					</Col>
					<Col md="6">
						<FormGroup row>
							<Col sm={12}>
								<Label className="form-control-label" htmlFor="example3cols2Input">
									Course Component <RedAstric />
								</Label>
							</Col>
							<Col sm={12}>
								<Select
									theme={customTheme}
									placeholder="Select Course Component"
									name="courseComponent"
									styles={(courseDetailsForm[currentFormId].courseComponent === ''  || isDisabled)  ? customStylesSelect  : stylesForFilledSelect }
									// styles={stylesForFilledSelect }
									value={
										courseDetailsForm[currentFormId].courseComponent ===
										'' ? '' :
										{
										label:
											props.action === 'edit'
												? courseDetailsForm.length > 0 &&
												  courseComponent &&
												  courseComponent.find(
														(item) =>
															item.id === courseDetailsForm[currentFormId].courseComponent
												  ) &&
												  courseComponent.find(
														(item) =>
															item.id === courseDetailsForm[currentFormId].courseComponent
												  ).courseComponentDispValue
												: courseDetailsForm[currentFormId].courseComponent ===
												  ''
												? 'Select Course Component'
												: courseComponent &&
												  courseComponent.find(
														(item) =>
															item.id ===
																courseDetailsForm[currentFormId]
																	.courseComponent &&
															item.id ===
																courseDetailsForm[currentFormId].courseComponent
												  ) &&
												  courseComponent.find(
														(item) =>
															item.id ===
																courseDetailsForm[currentFormId]
																	.courseComponent &&
															courseDetailsForm[currentFormId].courseComponent
												  ).courseComponentDispValue,
									}}
									options={showSelectOptionsCourseComponent(courseComponent) || []}
									onChange={(name, value) =>
										onChangeFormData(
											value.name,
											name.value,
											'courseComponentState'
										)
									}
									isDisabled={
										action === 'view' || courseDetailsForm[currentFormId].subjectArea ===  ''
										// props.action === 'edit'
										// 	? isDisabled || courseDetailsForm[0].courseComponent
										// 	: isDisabled || courseDetailsForm[currentFormId].subjectArea === ''
									}
								/>
							</Col>
						</FormGroup>
					</Col>
				</Row>
				<Row style={{marginBottom:'20px'}}>
					<Col style={{display:'flex',justifyContent:'space-between'}} md="6">

						<StyledDiv className="" >
							<h4 className={`mb-0  floatLeft mr-2`}>
								Sync With Website :{' '}
							</h4>
							<>
								<input
									onChange={(e) => {
										onChangeFormData( 'syncWithWebsite',e.target.checked)
									}}
									checked={courseDetailsForm[currentFormId].syncWithWebsite}
									className="react-switch-checkbox"
									id={`SyncWithWebsite`}
									type="checkbox"
								/>
								<label
									style={{background: courseDetailsForm[currentFormId].syncWithWebsite ? '#00AEEF' : '#e9ecef'}}
									className="react-switch-label"
									htmlFor={`SyncWithWebsite`}
								>
									<span className={`react-switch-button`}/>
								</label>
							</>
						</StyledDiv>
						<StyledDiv className="">
							<h4 className={`mb-0  floatLeft mr-2`}>
								Franchise commission Applicable :{' '}
							</h4>
							<input
								onChange={(e) => {onChangeFormData( 'franchiseCommissionApplicable',e.target.checked,)
								}}
								checked={courseDetailsForm[currentFormId].franchiseCommissionApplicable}
								className="react-switch-checkbox"
								id={`FranchiseCommissionApplicable`}
								type="checkbox"
							/>
							<label
								style={{background: courseDetailsForm[currentFormId].franchiseCommissionApplicable ? '#00AEEF' : '#e9ecef'}}
								className="react-switch-label"
								htmlFor={`FranchiseCommissionApplicable`}
							>
								<span className={`react-switch-button`}/>
							</label>
						</StyledDiv>
					</Col>
				</Row>
				{/*<Row style={{marginBottom:'20px'}}>*/}
				{/*	<Col md="6">*/}

				{/*		<StyledDiv className="floatRight" >*/}
				{/*				<h4 className={`mb-0  floatLeft mr-2`}>*/}
				{/*					Sync With Website :{' '}*/}
				{/*				</h4>*/}
				{/*			<>*/}
				{/*				<input*/}
				{/*					onChange={(e) => {*/}
				{/*						onChangeFormData( 'syncWithWebsite',e.target.checked)*/}
				{/*					}}*/}
				{/*					checked={courseDetailsForm[currentFormId].syncWithWebsite}*/}
				{/*					className="react-switch-checkbox"*/}
				{/*					id={`SyncWithWebsite`}*/}
				{/*					type="checkbox"*/}
				{/*				/>*/}
				{/*				<label*/}
				{/*					style={{background: courseDetailsForm[currentFormId].syncWithWebsite ? '#00AEEF' : '#e9ecef'}}*/}
				{/*					className="react-switch-label"*/}
				{/*					htmlFor={`SyncWithWebsite`}*/}
				{/*				>*/}
				{/*					<span className={`react-switch-button`}/>*/}
				{/*				</label>*/}
				{/*			</>*/}
				{/*		</StyledDiv>*/}
				{/*	</Col>*/}
				{/*	<Col md="6">*/}
				{/*		<StyledDiv className="floatRight">*/}
				{/*			<h4 className={`mb-0  floatLeft mr-2`}>*/}
				{/*				Franchise commission Applicable :{' '}*/}
				{/*			</h4>*/}
				{/*			<input*/}
				{/*				onChange={(e) => {onChangeFormData( 'franchiseCommission',e.target.checked,)*/}
				{/*				}}*/}
				{/*				checked={courseDetailsForm[currentFormId].franchiseCommission}*/}
				{/*				className="react-switch-checkbox"*/}
				{/*				id={`FranchiseCommissionApplicable`}*/}
				{/*				type="checkbox"*/}
				{/*			/>*/}
				{/*			<label*/}
				{/*				style={{background: courseDetailsForm[currentFormId].franchiseCommission ? '#00AEEF' : '#e9ecef'}}*/}
				{/*				className="react-switch-label"*/}
				{/*				htmlFor={`FranchiseCommissionApplicable`}*/}
				{/*			>*/}
				{/*				<span className={`react-switch-button`}/>*/}
				{/*			</label>*/}
				{/*		</StyledDiv>*/}

				{/*	</Col>*/}
				{/*</Row>*/}
			</>
			{/*</div>*/}
			{showAddNewDetails && (
				<Row>
					<Col md="12">
						{/*<Button*/}
						{/*	className="btn-info mb-3"*/}
						{/*	style={{ margin: '0 auto', display: 'block' }}*/}
						{/*	onClick={handleNewForm}*/}
						{/*	disabled={*/}
						{/*		props.action === 'new'*/}
						{/*			? false*/}
						{/*			: props.action === 'edit'*/}
						{/*			? courseDetailsForm[currentFormId].courseTitle === '' ||*/}
						{/*			  courseDetailsForm[currentFormId].effectiveDateTo === '' ||*/}
						{/*			  courseDetailsForm[currentFormId].effectiveFrom === '' ||*/}
						{/*			  courseDetailsForm[currentFormId].courseName === '' ||*/}
						{/*			  courseDetailsForm[currentFormId].courseDescription === ''*/}
						{/*			: false*/}
						{/*	}*/}
						{/*>*/}
						{/*	Add New Course Details*/}
						{/*</Button>*/}
						<CustomButton
							disabled={
								props.action === 'new'
									? false
									: props.action === 'edit'
										? courseDetailsForm[currentFormId].courseTitle === '' ||
										courseDetailsForm[currentFormId].effectiveDateTo === '' ||
										courseDetailsForm[currentFormId].effectiveFrom === '' ||
										courseDetailsForm[currentFormId].courseName === '' ||
										courseDetailsForm[currentFormId].courseDescription === ''
										: false
							}
							className={'btn-info mb-3 addNewCourseDetailsButton'}
							content={'Add New Course Details'}
							permissionType={'C'}
							icon={true}
							permissionSet={permissionSet}
							onClick={handleNewForm}
						/>
					</Col>
				</Row>
			)}
			<Row>
				<Col md="12">
					{pagination.totalPage > 1 ? (
						<GetPagination
							fetchClasses={fetchClasses}
							pagination={pagination}
							previousPage={previousPage}
							firstPage={firstPage}
							lastPage={lastPage}
							nextPage={nextPage}
							setpagination={setpagination}
						/>
					) : null}
				</Col>
			</Row>
		</div>
	);
};

export default CourseDetails;
