import React from "react";
import { Card, CardHeader,Input, Container, Button, Table, Row } from "reactstrap";
import { useState } from "react"
// import "views/pages/manage/addressType/node_modules/react-sliding-pane/dist/react-sliding-pane.css";
import { failureToast} from '../../../../common/utils/methods/toasterFunctions/function'
import {TDROW } from "../../../../common/commonComponents/masterRow/masterRow";
import {putpost2,putpost,successToast,masterServiceBaseUrl,baseUrl} from 'services/http'
import { MasterHeaderAuto , Rows} from '../../../../common/commonComponents/masterHeader/masterHeader'
import Select2 from "react-select2-wrapper";

import moment from 'moment';
var _ = require('lodash');
const AddApprovalTable = (props) => {
  const [isSaveVisible, setisSaveVisible] = useState(false)
  const [tableDataKey, settableDataKey] = useState('tableDataKey')
  const addNewClass = () =>{
    props.setclassArray([{ "editMode": true,"__status":"__new","approvalLevelId":null,"employeeId": null,"employeeName": null, 'percentageFrom':null,'percentageTo':null, status: 'ACTIVE'}, ...props.classArray])
  }


  const headersList = [{name:'Aaproval Key Level'},{name:'Employee Id'},{name:'Employee Name'},{name: 'Percentage From'},{name:'Percentage TO'},{name:'Status'},{name:'Update date'},{name:'Action'}]
  const itemsFormMaster = ['approvalLevelId']
  const arraylist = [props.levelData]
  const listToRender = ['approvalLevelId','employeeId','employeeName','percentageFrom','percentageTo','status','updatedOn']

  const saveAll = (cb) =>{
    var _tempArray = props.classArray
    var isValid = true
    _tempArray.forEach((el,index)=>{
      if(el['editMode']){
        if(el['approvalLevelId']==null){
          isValid= false
          failureToast('Please select approval level')
          return
        }else if(el['employeeId']==null || !el['employeeId'].trim()){
          isValid= false
          failureToast('Please enter the employee Id')
          return
        }
        else if(el['employeeName']==null||!el['employeeName'].trim()){
          isValid= false
          failureToast('Please enter the employee name')
          return
        }
        else if(el['percentageFrom']==null){
          isValid= false
          failureToast('Please enter the percentage from')
          return
        }
        else if(el['percentageTo']==null){
          isValid= false
          failureToast('Please enter the percentage to')
          return
        }
        else if(Number(el['percentageFrom']) > Number(el['percentageTo']) ){
          isValid= false
          failureToast('Percentage from should be smaller than Percentage to')
          return
        }
        else if(Number(el['percentageFrom']) > 100 || Number(el['percentageFrom']) < 0 || el['percentageFrom']==='-0'){
          isValid= false
          failureToast('Percentage from should be between 0.00% to 100.00%')
          return
        }
        else if(Number(el['percentageTo']) > 100 || Number(el['percentageTo']) < 0 || el['percentageFrom']==='-0'){
          isValid= false
          failureToast('Percentage to should be between 0.00% to 100.00%')
          return
        }
        else if(el['percentageFrom'].toString().indexOf(".") > -1 && el['percentageFrom'].split(".")[1].length > 2){
          isValid= false
          failureToast('Percentage from can have max 2 decimal points')
          return
        }
        else if(el['percentageTo'].toString().indexOf(".") > -1 && el['percentageTo'].split(".")[1].length > 2){
          isValid= false
          failureToast('Percentage to can have max 2 decimal points')
          return
        }
        if (!isValid) {
          return
        }
        if (_tempArray[index]['__status'] == "__new") {
          _tempArray[index]['concessionApprovalSetupId'] = props.id
          putpost(masterServiceBaseUrl + '/addConcessionApproval/createAddConcessionApproval', (data) => {
            successToast(data['message'])
            setisSaveVisible(false)
            props.fetchClasses(`${masterServiceBaseUrl}/addConcessionApproval/getAddConcessionApprovalByConcessionApprovalSetupId/${props.id}`)
            props.incrementCount()
            
          }, (data) => { failureToast(data['message']) }, _tempArray[index], 'post')
        } else {
          putpost(masterServiceBaseUrl + '/addConcessionApproval/updateAddConcessionApproval/' + _tempArray[index].id, (data) => {
            successToast(data['message'])
            setisSaveVisible(false)
            delete _tempArray[index]['editMode']
            // console.log(_tempArray[index])
            props.setclassArray(_tempArray)
            settableDataKey(new Date().getMilliseconds())
          }, (data) => { failureToast(data['message']) }, _tempArray[index], 'put')
        } 
      }
    })
  }
  const newRow = () => {
    if(isSaveVisible){
      failureToast("Please Delete or Save current changes")
 }
 else{
    let cb = ()=>{
      addNewClass()
      setisSaveVisible(true)
      // settableDataKey(new Date().getMilliseconds())
    }
    var _tempArray = props.classArray
    _tempArray = _.sortBy(_tempArray,[function(o) { return o.value; }])
    props.setclassArray(_tempArray)
    setisSaveVisible(false)
    settableDataKey(new Date().getMilliseconds())
    cb()
  }
  }

  const editRow = (index)=>{
    if(isSaveVisible){
      failureToast("Please Delete or Save current changes")
 }
 else{
    let cb = ()=>{
      var _tempArray = props.classArray
      _tempArray[index]['editMode'] = true
      props.setclassArray(_tempArray)
      setisSaveVisible(true)
    }
    cb()
  }
  }
  const deleteRow = (index) => {
    if(props.classArray[index]["__status"]=="__new"){
      let n=props.classArray
      n.splice(index, 1)
      props.setclassArray(n)
      setisSaveVisible(false)
      settableDataKey(new Date().getMilliseconds())
    }else{
    if(window.confirm('Are you sure you want to delete this Admission Type?')){
      putpost2(masterServiceBaseUrl + '/addConcessionApproval/deleteAddConcessionApproval/' + props.classArray[index].id, (data) => {
        successToast(data['message'])
        if (props.classArray.length == 1) {
          props.previousPage()
          props.fetchClasses(`${masterServiceBaseUrl}/addConcessionApproval/getAddConcessionApprovalByConcessionApprovalSetupId/${props.id}`)
        } else {
          var _tempArray = props.classArray
          if (_tempArray[index]['editMode']) {
            setisSaveVisible(false)
          }
          _tempArray.splice(index, 1)
          props.setclassArray(_tempArray)
        }
        props.decrementCount()
        settableDataKey(new Date().getMilliseconds())
      }, (data) => { failureToast(data['message']) }, 'PUT')
    }
  }
}
  const updateKey = (index,value,key)=>{
    var _tempArray = props.classArray
    _tempArray[index][key] = value
    props.setclassArray(_tempArray)
  }
  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                
                <Button disabled={isSaveVisible} color="info" size="sm" type="button" className={"floatRight mx-1"+(isSaveVisible?" btn-dark":null)} onClick={() => { newRow() }}>Add Approval Level</Button>
                {/* {isSaveVisible?<Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => {saveAll()}}>Save</Button>:null} */}
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout" >
                <MasterHeaderAuto headerList={headersList} />
                  <tbody className="list" key={tableDataKey} >
                    {props.classArray ? props.classArray.map((el, index) => 
                    !el['editMode']?(
                      <>
                          <Rows data={el} functionItemsFromList={itemsFormMaster} listArray={arraylist} listToBeRendered={listToRender}>
                            <td>
                              <Button color="info" size="sm" type="button" onClick={()=>editRow(index)}><i className="fas fa-edit" /></Button>
                              <Button color="info" size="sm" type="button" onClick={()=>deleteRow(index)}><i className="fas fa-trash" /></Button>
                            </td>
                          </Rows>
                        </>
                    ):
                    (<tr key={index+'-class'}>
                      <td className="text-center p-2 mx-1"><Select2 defaultValue={el['approvalLevelId']} data={props.levelData} onChange={(e)=>{updateKey(index,e.target.value,'approvalLevelId',true)}} options={{placeholder: "Select Approval Level"}} className="tdSelect" /></td>
                      <td className="text-center p-2 mx-1"><Input  maxLength={20} defaultValue={el['employeeId']} onChange={(e)=>{updateKey(index,e.target.value,'employeeId')}} placeholder="Employee ID" className="tdInput" /></td>
                      <td className="text-center p-2 mx-1"><Input  maxLength={50} defaultValue={el['employeeName']} onChange={(e)=>{updateKey(index,e.target.value,'employeeName')}} placeholder="Employee Name" className="tdInput" /></td>
                      <td className="text-center p-2 mx-1"><Input  type="number"maxLength={50} defaultValue={el['percentageFrom']} onChange={(e)=>{updateKey(index,e.target.value,'percentageFrom')}} placeholder="Percentage From" className="tdInput" /></td>
                      <td className="text-center p-2 mx-1"><Input  type="number" maxLength={50} defaultValue={el['percentageTo']} onChange={(e)=>{updateKey(index,e.target.value,'percentageTo')}} placeholder="Percentage To" className="tdInput" /></td>
                      <td className="text-center p-2 mx-1">
                      <label className="custom-toggle mx-auto ml-2">
                      <input checked={el.status == "ACTIVE" ? true : false} type="checkbox" id={el.id} onChange={(e) => { settableDataKey(new Date().getMilliseconds());  let n = "ACTIVE"; if (!e.target.checked) { n = "INACTIVE" } updateKey(index, n, 'status') }} />
                          <span style={{width:"72px"}} className="custom-toggle-slider activeToggle rounded-circle" data-label-off="Inactive" data-label-on="Active" />
                          </label>
                      </td>
                      <td className="text-center p-2 mx-1"><Input disabled={true} defaultValue={el["__status"] == "__new" ? null : moment(el['updatedDate']).format('DD-MM-YYYY')}  className="tdInput" /></td>
                      {/* <td className="text-center p-2 mx-1"><Input defaultValue={el['longName']} onChange={(e)=>{updateKey(index,e.target.value,'longName')}} placeholder="Example:School" className="tdInput" /></td>
                      <td className="text-center p-2 mx-1"><Input defaultValue={el['shortName']} onChange={(e)=>{updateKey(index,e.target.value,'shortName')}} placeholder="Example:School" className="tdInput" /></td> */}
                      <td className="text-center">
                      {isSaveVisible ? <> <Button color="info" size="sm" type="button" className="mx-1" onClick={() => { saveAll() }}>Save</Button>
                            <Button color="danger" size="sm" type="button" onClick={() => {
                              let n=props.classArray
                              if(n[index]['__status']=='__new'){
                                n.splice(index,1)
                              }else{
                              // n[index]['editMode']=false
                              props.fetchClasses(`${masterServiceBaseUrl}/addConcessionApproval/getAddConcessionApprovalByConcessionApprovalSetupId/${props.id}`)
                              }
                              props.setclassArray(n)
                              setisSaveVisible(false)
                            }}>  <i className="fas fa-times" /></Button> </>:
                              <Button color="info" size="sm" type="button" onClick={() => {
                                deleteRow(index)
                              }}>  <i className="fas fa-trash" /></Button>}
                      </td>
                    </tr>)
                    ) : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
export default AddApprovalTable;