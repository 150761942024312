// import React, { useState, useEffect } from 'react';
// import PaymentModeTable from './paymentModeTable';
// import { getAPI, masterServiceBaseUrl, failureToast } from 'services/http';
// import Loader from 'react-loader-spinner';
// import { GetPagination } from '../../common/pagination';
// import {
// 	fetchAllData,
// 	fetchDetails,
// } from '../../common/utils/methods/commonMethods/utilityMethod';
// const PaymentMode = () => {
// 	const [classArray, setclassArray] = useState([]);
// 	const [apiloader, setapiloader] = useState(false);
// 	const [state, setstate] = useState([]);
// 	const [pagination, setpagination] = useState({
// 		currentPage: 1,
// 		nextPage: null,
// 		previousPage: null,
// 		totalPage: null,
// 	});
// 	const pageSize = 10;
// 	const fetchClasses = async (url) => {
// 		setapiloader(true);
// 		getAPI(
// 			url,
// 			(data) => {
// 				setstate(data['data']);
// 				let n = pagination;
// 				if (data['data'].length > pageSize) {
// 					n['nextPage'] = n.currentPage + 1;
// 				}
// 				if (n.currentPage > 1) {
// 					n.previousPage = n.currentPage - 1;
// 				}
// 				n['totalPage'] = Math.floor(data['data'].length / pageSize);
// 				if (data['data'].length % pageSize != 0) {
// 					n['totalPage'] += 1;
// 				}
// 				setpagination(n);
// 				setapiloader(false);
// 				console.log(n);
// 				setclassArray(
// 					data['data'].slice(
// 						pagination.currentPage * pageSize - pageSize,
// 						pagination.currentPage * pageSize
// 					) || []
// 				);
// 			},
// 			(data) => {
// 				failureToast(data['message']);
// 			}
// 		);
// 	};
// 	const nextPage = () => {
// 		setclassArray(
// 			state.slice(
// 				(pagination.currentPage + 1) * pageSize - pageSize,
// 				(pagination.currentPage + 1) * pageSize
// 			)
// 		);
// 		let n = pagination;
// 		n['currentPage'] = n['currentPage'] + 1;
// 		n['previousPage'] = n.currentPage;
// 		setpagination(n);
// 	};
// 	const firstPage = () => {
// 		setclassArray(state.slice(1 * pageSize - pageSize, 1 * pageSize));
// 		console.log(pagination);
// 		let n = pagination;
// 		n['currentPage'] = 1;
// 		n['previousPage'] = null;
// 		setpagination(n);
// 	};
// 	const lastPage = () => {
// 		setclassArray(
// 			state.slice(
// 				pagination.totalPage * pageSize - pageSize,
// 				pagination.totalPage * pageSize
// 			)
// 		);
// 		console.log(pagination);
// 		let n = pagination;
// 		n['currentPage'] = n.totalPage;
// 		n['previousPage'] = n.totalPage - 1;
// 		setpagination(n);
// 	};
// 	const previousPage = () => {
// 		if (pagination.currentPage != 1) {
// 			setclassArray(
// 				state.slice(
// 					(pagination.currentPage - 1) * pageSize - pageSize,
// 					(pagination.currentPage - 1) * pageSize
// 				)
// 			);
// 			console.log(
// 				state.slice(
// 					(pagination.currentPage - 1) * pageSize - pageSize,
// 					(pagination.currentPage - 1) * pageSize
// 				)
// 			);
// 			let n = pagination;
// 			n['currentPage'] = n['currentPage'] - 1;
// 			if (n['currentPage'] == 1) {
// 				n['previousPage'] = null;
// 			} else {
// 				n['previousPage'] = n.currentPage;
// 			}
// 			setpagination(n);
// 		}
// 	};
// 	useEffect(() => {
// 		console.log('Updated data-------->', state);
// 	}, [state]);

// 	const [groupCodeData, setGroupCodeData] = useState();
// 	useEffect(() => {
// 		console.log('Group Code----->', groupCodeData);
// 	}, [groupCodeData]);
// 	useEffect(() => {
// 		fetchClasses(masterServiceBaseUrl + '/paymentMode/getAllPaymentMode');
// 		// fetchAllData(masterServiceBaseUrl+'/groupCode/getAllActiveGroupCode',setGroupCodeData)
// 		fetchDetails(
// 			masterServiceBaseUrl + '/groupCode/getAllActiveGroupCode',
// 			setGroupCodeData,
// 			'groupCode',
// 			'reactSelect'
// 		);
// 	}, []);
// 	return (
// 		<>
// 			{apiloader ? (
// 				<div
// 					className="mx-auto text-center py-5 my-5"
// 					style={{ height: '100vh' }}
// 				>
// 					<Loader
// 						type="Rings"
// 						color="#00BFFF"
// 						height={70}
// 						width={70}
// 						timeout={10000}
// 					/>
// 				</div>
// 			) : (
// 				<div></div>
// 			)}
// 			<PaymentModeTable
// 				groupCodeData={groupCodeData}
// 				previousPage={previousPage}
// 				fetchClasses={fetchClasses}
// 				setclassArray={setclassArray}
// 				classArray={classArray}
// 			/>
// 			{pagination.totalPage > 1 ? (
// 				<GetPagination
// 					fetchClasses={fetchClasses}
// 					pagination={pagination}
// 					previousPage={previousPage}
// 					firstPage={firstPage}
// 					lastPage={lastPage}
// 					nextPage={nextPage}
// 					setpagination={setpagination}
// 				/>
// 			) : null}
// 		</>
// 	);
// };
// export default PaymentMode;

import React, { useState, useEffect, useContext } from 'react';
import PaymentModeTable from './paymentModeTable';
import { PermissionContext, RolePermissions } from "../../../../../appContext";
import { constants, endpoint, pages } from "../../common/constant";
import { PermisionDenied } from "../../common/commonComponents";
import CustomLoader from "../../common/commonComponents/Loader/loader";
import { GetPagination } from "../../common/commonComponents/pagination/pagination";
import { fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import { getPaginatedData } from '../../common/utils/methods/commonMethods/paginationMethod';
import {
  fetchDetails
} from '../../common/utils/methods/commonMethods/utilityMethod';


const PaymentMode = () => {
  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(
    permissions,
    pages['paymentMode']['id']
  );

  const [classArray, setclassArray] = useState([]);
  const [apiloader, setapiloader] = useState(false);
  const [state, setstate] = useState([]);
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [pagination, setpagination] = useState(constants.pagination);
  const pageSize = 10;
  const [groupCodeData, setGroupCodeData] = useState([]);

  const fetchClasses = async url => {
    setapiloader(true);
    const syncData = await fetchAllPromisedData(url, true);
    if (syncData?.code === 200) {
      setstate(syncData.data);
      getPaginatedData(syncData.data, pageSize, pagination, setpagination, setapiloader, setclassArray)
    } else {
      setstate([]);
      setclassArray([]);
      setapiloader(true);
    }
  };

  const getAllrecords = (toPreviousPage = false) => {
    if (toPreviousPage) {
      let n = pagination;
      n.currentPage = n.currentPage - 1;
      setpagination(n)
    }
    fetchClasses(endpoint.paymentMode.getAll);
  }

  useEffect(() => {
    getAllrecords();
    fetchDetails(
      endpoint.groupCode.getAllActive,
      setGroupCodeData,
      'groupCode',
      'select'
    );
  }, []);

  return (
    userPermissions ?
      (
        <>
          {apiloader ? (
            <CustomLoader
              apiLoader={apiloader}
              loaderHeight={'200px'}
              loaderWidth={'100% '}
            />
          ) : (
            <>
              <PaymentModeTable
                getAllrecords={getAllrecords}
                setclassArray={setclassArray}
                classArray={classArray}
                permissionSet={userPermissions}
                isSaveVisible={isSaveVisible}
                setisSaveVisible={setisSaveVisible}
                groupCodeData={groupCodeData}
              />
              {pagination.totalPage > 1 ? (
                <GetPagination
                  isSaveVisible={isSaveVisible}
                  fetchClasses={fetchClasses}
                  setclassArray={setclassArray}
                  pagination={pagination}
                  setpagination={setpagination}
                  pageSize={pageSize}
                  state={state}
                />
              )
                : null}
            </>
          )}
        </>)
      :
      <PermisionDenied />
  );
};
export default PaymentMode;

