import React, { useContext, useEffect, useRef, useState } from "react";
import { RolePermissions, PermissionContext } from "appContext";
import styles from "./styles.module.scss";
import { useHistory, useParams } from "react-router";
import { ReactComponent as BackIcon } from "assets/img/svg/BackButton.svg";
import ALoader from "views/pages/manage/common/a-loader";
import SearchFilter from "./SearchFilter";
import ListComponent from "./ListComponent";
import {
  createSuccessMsg,
  deleteSuccessMsg,
  getCreateReqBody,
  getDropdowns,
  getListResponse,
  getSearchReqBody,
  initialFilterForm,
  updateSuccessMsg,
  useGetDlpCourseIdsByBu,
} from "./constant";
import {
  fetchAllPostPromisedData,
  fetchDeletePromisedData,
  getUrlSearchParams,
  updateRequest,
} from "../../../../common/utils/methods/commonMethods/utilityMethod";
import { getFilterCount } from "../../mastersAndMapping/onBoardingMaterialIdSetup/constant";
import { endpoint, pages } from "../../../../common/constant";
import { successToast } from "../../../../common/utils/methods/toasterFunctions/function";
import { PermisionDenied } from "../../../../common/commonComponents";
import UploadDispatchSchedule from "./UploadDispatchSchedule";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx";

const getNameSearchReqBody = (userInput) => ({ dispatchId: userInput });
const DispatchSchedule = () => {
  const history = useHistory();
  const { academicCareer } = useParams();

  const userPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["dlpStockAndInventory"]["id"]
  );

  const listRef = useRef();
  const [dropdownLoader, setDropdownLoader] = useState(false);
  const [dropdown, setDropdown] = useState([]);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [listApiLoader, setListApiLoader] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [nameFilterPayload, setNameFilterPayload] = useState("");
  const [filterPayload, setFilterPayload] = useState(initialFilterForm);
  const [filterCount, setFilterCount] = useState(0);
  const [page, setPage] = useState(0);

  const getCombinedPayload = () => ({
    ...getNameSearchReqBody(nameFilterPayload),
    ...filterPayload,
  });

  const fetchDropdown = async () => {
    setDropdownLoader(true);
    const res = await getDropdowns(academicCareer);
    setDropdown(res);
    fetchList(undefined, undefined, res);
    setDropdownLoader(false);
  };

  const fetchList = async (
    page = 0,
    reqBody = { ...getNameSearchReqBody(""), ...initialFilterForm },
    dropdownData = dropdown
  ) => {
    setListApiLoader(true);
    const updatedReqBody = getSearchReqBody(
      { ...reqBody, term: reqBody?.term?.value ? [reqBody?.term?.value] : "" },
      academicCareer
    );

    const res = await fetchAllPostPromisedData(
      `${endpoint.dispatchSchedule.search}?offset=${page}`,
      updateRequest(updatedReqBody),
      "post"
    );
    if (res?.code === 200) {
      setRowCount(res?.data?.totalRecords);
      setRows(getListResponse(res?.data?.stockDispatchMasterList));
    } else {
      setRowCount(0);
      setRows([]);
    }

    setListApiLoader(false);
  };

  const onPageChange = async (newPage) => {
    setPage(newPage);
    const reqBody = getCombinedPayload();
    await fetchList(newPage, reqBody);
  };

  useEffect(() => {
    fetchDropdown();
  }, []);

  const createHandler = async (rowData) => {
    setApiLoader(true);

    const reqBody = getCreateReqBody(rowData, academicCareer);
    const res = await fetchAllPostPromisedData(
      endpoint?.dispatchSchedule?.create,
      reqBody,
      "post"
    );
    if (res?.code === 200) {
      setPage(0);
      await fetchList(0, getCombinedPayload());
      successToast(createSuccessMsg);
      listRef.current.stopEditing();
    }

    setApiLoader(false);
  };

  const updateHandler = async (rowData) => {
    setApiLoader(true);

    const reqBody = getCreateReqBody(rowData, academicCareer);
    const res = await fetchAllPostPromisedData(
      `${endpoint?.dispatchSchedule?.update}/${rowData?.id}`,
      reqBody,
      "post"
    );
    if (res?.code === 200) {
      await fetchList(page, getCombinedPayload());
      successToast(updateSuccessMsg);
      listRef.current.stopEditing();
    }

    setApiLoader(false);
  };

  const toggleStatusHandler = async (rowData) => {
    await updateHandler({
      ...rowData,
      status: rowData?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
    });
  };

  const deleteHandler = async (rowData) => {
    setApiLoader(true);
    const res = await fetchDeletePromisedData(
      `${endpoint?.dispatchSchedule?.delete}/${rowData?.id}`
    );
    if (res?.code === 200) {
      await fetchList(page, getCombinedPayload());
      successToast(deleteSuccessMsg);
      listRef.current.stopEditing();
    }
    setApiLoader(false);
  };

  const applyFilter = async (filterForm) => {
    listRef.current.stopEditing();
    setFilterPayload(filterForm);
    setFilterCount(getFilterCount(filterForm));
    const reqBody = {
      ...getNameSearchReqBody(nameFilterPayload),
      ...filterForm,
    };

    setPage(0);
    await fetchList(0, reqBody);
  };

  const applyNameFilter = async (userInput) => {
    listRef.current.stopEditing();
    setNameFilterPayload(userInput);
    const reqBody = {
      ...getNameSearchReqBody(userInput),
      ...filterPayload,
    };
    setPage(0);
    await fetchList(0, reqBody);
  };

  return userPermissions ? (
    <div className="remove-container-top-space">
      {(dropdownLoader || listApiLoader || apiLoader) && (
        <ALoader position="fixed" />
      )}
      <div className={`${styles["header-bar"]}`}>
        <IconButtonWrapper onClick={() => history.goBack()}>
          <BackIcon />
        </IconButtonWrapper>
        <div className="ml-3 heading-4 color-dark flex-grow-1">
          Dispatch schedule
        </div>
        <UploadDispatchSchedule />
      </div>
      <div className={`${styles["page-container"]}`}>
        <SearchFilter
          listRef={listRef}
          filterCount={filterCount}
          filterPayload={filterPayload}
          nameFilterPayload={nameFilterPayload}
          dropdown={dropdown}
          applyFilter={applyFilter}
          applyNameFilter={applyNameFilter}
        />
        <ListComponent
          ref={listRef}
          rows={rows}
          rowCount={rowCount}
          dropdown={dropdown}
          loader={listApiLoader || dropdownLoader}
          currentPage={page}
          onPageChange={onPageChange}
          createHandler={createHandler}
          updateHandler={updateHandler}
          toggleStatusHandler={toggleStatusHandler}
          deleteHandler={deleteHandler}
        />
      </div>
    </div>
  ) : (
    <PermisionDenied />
  );
};

export default DispatchSchedule;
