import React from 'react';
import {
	Card,
	CardHeader,
	Input,
	Container,
	Button,
	Table,
	Row,
} from 'reactstrap';
import { useState } from 'react';
import Select2 from 'react-select2-wrapper';
import { failureToast, successToast } from '../../../../common/utils/methods/toasterFunctions/function';
import {
	TaxComponentRow,
	TaxCodeComponent,
} from '../../../../common/commonComponents/masterRow/masterRow';
import { masterServiceBaseUrl, putpost, putpost2 } from 'services/http';
import moment from 'moment';
import CustomButton from 'components/CustomButton';

var _ = require('lodash');

const TaxComponentTable = (props) => {
	const { userPermissions, isSaveVisible, setisSaveVisible=()=>{} } = props;
	const [tableDataKey, settableDataKey] = useState('tableDataKey');
	const addNewClass = () => {
		props.setclassArray([
			{
				editMode: true,
				__status: '__new',
				status: 'ACTIVE',
				isZeroTax: true,
				taxCodeId: null,
				taxCodeTypeId: null,
				taxPercentage: null,
			},
			...props.classArray,
		]);
	};
	const saveAll = (cb) => {
		var _tempArray = props.classArray;
		var isValid = true;
		_tempArray.forEach((el, index) => {
			if (el['editMode']) {
				if (el['taxCodeTypeId'] == null) {
					isValid = false;
					failureToast('Please select Tax Code Type');
					return;
				} else if (el['taxPercentage'] === null && el['isZeroTax'] === false) {
					isValid = false;
					failureToast('Please enter Percentage');
					return;
				} else if (
					Number(el['taxPercentage']) > 101 &&
					el['isZeroTax'] === false
				) {
					isValid = false;
					failureToast('Tax percentage should be between 0.00% to 100.00%');
					return;
				} else if (
					(Number(el['taxPercentage']) < 0 ||
						Number(el['taxPercentage']) === -0) &&
					el['isZeroTax'] === false
				) {
					isValid = false;
					failureToast('Tax percentage should be between 0.00% to 100.00%');
					return;
				} else if (el['taxPercentage'] !== null) {
					if (
						el['taxPercentage'].toString().indexOf('.') > -1 &&
						el['taxPercentage'].split('.')[1].length > 2
					) {
						isValid = false;
						failureToast('Tax percentage can have max 2 decimal points');
						return;
					}
				}
				if (!isValid) {
					return;
				}
				if (_tempArray[index]['__status'] == '__new') {
					_tempArray[index]['taxCodeId'] = props.taxCodeId;
					if (_tempArray[index]['isZeroTax'] === true) {
						_tempArray[index]['taxPercentage'] = 0;
					}
					putpost(
						masterServiceBaseUrl + '/taxComponent/createTaxComponent',
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							props.getTaxCodeData();
							props.fetchClasses(
								`${masterServiceBaseUrl}/taxComponent/getTaxComponentByTaxBreakupId/${props.taxCodeId}`
							);
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'post'
					);
				} else {
					putpost(
						masterServiceBaseUrl +
							'/taxComponent/updateTaxComponent/' +
							_tempArray[index].id,
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							delete _tempArray[index]['editMode'];
							props.setclassArray(_tempArray);
							settableDataKey(new Date().getMilliseconds());
							props.getTaxCodeData();
							// props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'put'
					);
				}
			}
		});
	};
	const newRow = () => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				addNewClass();
				setisSaveVisible(true);
				// settableDataKey(new Date().getMilliseconds())
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			props.setclassArray(_tempArray);
			setisSaveVisible(false);
			cb();
		}
	};
	const editRow = (index) => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				var _tempArray = props.classArray;
				_tempArray[index]['editMode'] = true;
				props.setclassArray(_tempArray);
				setisSaveVisible(true);
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			cb();
		}
	};
	const deleteRow = (index) => {
		if (props.classArray[index]['__status'] == '__new') {
			let n = props.classArray;
			n.splice(index, 1);
			props.setclassArray(n);
			setisSaveVisible(false);
			settableDataKey(new Date().getMilliseconds());
		} else {
			if (
				window.confirm('Are you sure you want to delete this Tax Component?')
			) {
				putpost2(
					masterServiceBaseUrl +
						'/taxComponent/deleteTaxComponent/' +
						props.classArray[index].id,
					(data) => {
						successToast(data['message']);
						props.getTaxCodeData();
						if (props.classArray.length == 1) {
							props.previousPage();
							props.fetchClasses(
								`${masterServiceBaseUrl}/taxComponent/getTaxComponentByTaxBreakupId/${props.taxCodeId}`
							);
						} else {
							var _tempArray = props.classArray;
							if (_tempArray[index]['editMode']) {
								setisSaveVisible(false);
							}
							_tempArray.splice(index, 1);
							props.setclassArray(_tempArray);
						}
						settableDataKey(new Date().getMilliseconds());
					},
					(data) => {
						failureToast(data['message']);
					},
					'PUT'
				);
			}
		}
	};
	const updateKey = (index, value, key) => {
		var _tempArray = props.classArray;
		if (key === 'isZeroTax' && value === true) {
			_tempArray[index]['taxPercentage'] = 0;
		}
		//  else {
		// 	_tempArray[index]['taxPercentage'] =_tempArray[index]['taxPercentage'];
		// }
		_tempArray[index][key] = value;
		setDesc(getNameById(value, props.unSortedList, 'description'));
		setDesc(getNameById(value, props.unSortedList, 'taxCodeTypeDispValue'));
		props.setclassArray(_tempArray);
	};

	const [desc, setDesc] = useState('');
	const [taxDispValue, setTaxDispValue] = useState('');

	const getNameById = (id, list, key) => {
		const item = list.filter((item) => {
			return item.id == id;
		});
		const name = item.length ? item[0][key] : null;
		return name;
	};

	return (
		<>
			<Container className="mt-3" fluid>
				<Row>
					<div className="col">
						<Card>
							<CardHeader className="border-0">
								<CustomButton
									disabled={isSaveVisible}
									className={
										'floatRight mx-1' +
										(isSaveVisible ? ' btn-dark cursor-gray' : null)
									}
									content={'New Tax Components'}
									permissionType={'C'}
									icon={true}
									forTable={true}
									permissionSet={userPermissions}
									onClick={() => {
										newRow();
									}}
								/>
							</CardHeader>
							<div id="questionTable" className="table-responsive">
								<Table className="align-items-center table-flush tableLayout">
									<TaxCodeComponent
										type={'Tax Component'}
										isSaveVisible={isSaveVisible}
										permissionSet={userPermissions}
									/>
									<tbody className="list" key={tableDataKey}>
										{props.classArray
											? props.classArray.map((el, index) =>
													!el['editMode'] ? (
														<TaxComponentRow
															type={'taxComponent'}
															index={index}
															el={el}
															editRow={editRow}
															deleteRow={deleteRow}
															taxCodeData={props.unSortedList}
															isSaveVisible={isSaveVisible}
															permissionSet={userPermissions}
														/>
													) : (
														<tr key={index + '-class'}>
															<td className="text-center p-2 mx-1">
																<Select2
																	defaultValue={el['taxCodeTypeId']}
																	data={props.taxCodeData}
																	onChange={(e) => {
																		updateKey(
																			index,
																			e.target.value,
																			'taxCodeTypeId'
																		);
																	}}
																	options={{
																		placeholder: 'Select Tax Code Type',
																	}}
																	className="tdSelect"
																/>
															</td>
															<td className="text-center p-2 mx-1">
																<Input
																	disabled={true}
																	value={getNameById(
																		el['taxCodeTypeId'],
																		props.unSortedList,
																		'taxCodeTypeDesc'
																	)}
																	className="tdInput"
																/>
															</td>
															<td className="text-center p-2 mx-1">
																<Input
																	disabled={true}
																	value={getNameById(
																		el['taxCodeTypeId'],
																		props.unSortedList,
																		'taxCodeTypeDispValue'
																	)}
																	className="tdInput"
																/>
															</td>
															<td className="text-center p-2 mx-1">
																<label className="custom-toggle mx-auto ml-2">
																	<input
																		checked={
																			el.isZeroTax === true ? true : false
																		}
																		type="checkbox"
																		id={el.id}
																		onChange={(e) => {
																			settableDataKey(
																				new Date().getMilliseconds()
																			);
																			updateKey(
																				index,
																				e.target.checked,
																				'isZeroTax'
																			);
																		}}
																	/>
																	<span
																		style={{ width: '72px' }}
																		className="custom-toggle-slider rounded-circle activeToggle"
																		data-label-off="No"
																		data-label-on="Yes"
																	/>
																</label>
															</td>
															<td className="text-center p-2 mx-1">
																<Input
																	type="number"
																	defaultValue={
																		el['isZeroTax'] === true
																			? 0
																			: el['taxPercentage']
																	}
																	disabled={
																		el.isZeroTax === true ? true : false
																	}
																	onChange={(e) => {
																		updateKey(
																			index,
																			e.target.value,
																			'taxPercentage'
																		);
																	}}
																	placeholder="Percentage"
																	className="tdInput"
																/>
															</td>
															<td className="text-center p-2 mx-1">
																<label className="custom-toggle mx-auto ml-2">
																	<input
																		checked={
																			el.status == 'ACTIVE' ? true : false
																		}
																		type="checkbox"
																		id={el.id}
																		onChange={(e) => {
																			settableDataKey(
																				new Date().getMilliseconds()
																			);
																			
																			let n = 'ACTIVE';
																			if (!e.target.checked) {
																				n = 'INACTIVE';
																			}
																			updateKey(index, n, 'status');
																		}}
																	/>
																	<span
																		style={{ width: '72px' }}
																		className="custom-toggle-slider rounded-circle activeToggle"
																		data-label-off="Inactive"
																		data-label-on="Active"
																	/>
																</label>
															</td>
															<td className="text-center">
																{isSaveVisible ? (
																	<>
																		<CustomButton
																			content={'Save'}
																			permissionType={'C,U'}
																			forTable={true}
																			permissionSet={userPermissions}
																			onClick={() => {
																				saveAll();
																			}}
																		/>
																		<CustomButton
																			permissionType={'cancel'}
																			icon={true}
																			forTable={true}
																			permissionSet={userPermissions}
																			onClick={() => {
																				let n = props.classArray;
																				if (n[index]['__status'] == '__new') {
																					n.splice(index, 1);
																				} else {
																					// n[index]['editMode']=false
																					props.fetchClasses(
																						`${masterServiceBaseUrl}/taxComponent/getTaxComponentByTaxBreakupId/${props.taxCodeId}`
																					);
																				}
																				props.setclassArray(n);
																				setisSaveVisible(false);
																			}}
																		/>
																	</>
																) : (
																	<CustomButton
																		permissionType={'D'}
																		icon={true}
																		forTable={true}
																		permissionSet={userPermissions}
																		onClick={() => deleteRow(index)}
																	/>
																)}
															</td>
														</tr>
													)
											  )
											: null}
									</tbody>
								</Table>
							</div>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};
export default TaxComponentTable;
