import React from 'react'
import { ReactComponent as Info } from '../../../../../../../assets/img/svg/fi_info.svg';

const NoRowOverlay = () => {
  return (
    <div className='d-flex flex-row justify-content-center align-items-center' style={{ height: '50px', backgroundColor: '#F5F5F5', top:'52px' }}>
      <Info className='mr-2'/>
      <span className='regular-small'>No information to display</span>
    </div>
  )
}

export default NoRowOverlay