import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader } from "reactstrap";
import LeftSideFilter from "./LeftSideFilter";
import Filter from "./Filter";
import StudentList from "./StudentList";
import { useHistory } from "react-router";
import { filterFormModal } from "views/pages/manage/unAssigned/studentCharges/common";
import { searchInputFormModal } from "views/pages/manage/edpOps/constant/student-search";
import { useRegionAndBusinessArea } from "views/pages/manage/common/utils/customHooks";
import { dropdownMapping } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { loadDropdowns } from "redux/studentSearch/actions";
import { searchStudentClear } from "redux/studentCharges/actions";
import { getStudentList } from "redux/studentCharges/actions";
import { isFilterApplied } from "views/pages/manage/edpOps/constant/batch-search";
import ALoader from "views/pages/manage/common/a-loader";
import { endpoint } from "views/pages/manage/common/constant";
import { ReactComponent as BackIcon } from "assets/img/svg/BackArrow.svg"

export const emptySearchFields = {
  psid : '',
  applicationId : '',
  opportunityId : '',
  studentName : ''
}

const StudentSearchPage = ({setPopover,rows, setRows,globalFilter,studentSearch,setGlobalFilter}) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const [searchInputForm, setSearchInputForm] = useState(searchInputFormModal);
  const [regionAndBusiness, setRegionAndBusiness] = useRegionAndBusinessArea();
  const [currentPageNumber, setPageNumber] = useState(0);
  const [appliedFilterCount, setAppliedFilterCount] = useState(0);
  const [isLastPage, setIsLastPage]=useState(false);
  const [businessAreaMap, setBusinessAreaMap] = useState({ region: studentSearch?.dropdowns?.region ?? [], businessArea: globalFilter?.businessArea ? globalFilter?.businessArea : studentSearch?.dropdowns?.businessArea ?? [] })
  const [courseIdDropdown, setCourseIdDropdown] = useState([]);
 
  const setFilterForm=(value)=>{
    setGlobalFilter({...value})
  }

  const inputHandler = (value, inputName) => {
    let currentData = { ...globalFilter };
    if (inputName === "businessAreaClear") {
      currentData = { ...globalFilter, businessArea: [] };
      currentData["region"] = value;
      setFilterForm(currentData);
    } else {
      currentData[inputName] = value;
      setFilterForm(currentData);
    }
  };


  const getAllCourseIds = async () => {
    const res = await dropdownMapping(endpoint?.courseCatelogMapping?.getAllActiveCourseIds, 'courseIds');
    setCourseIdDropdown(res);
  }

  useEffect(() => {
    getAllCourseIds();
    setAppliedFilterCount(isFilterApplied(globalFilter))
    dispatch(loadDropdowns((data)=>{
      setRegionAndBusiness(({ region: data?.regions ?? [], businessArea: globalFilter?.businessArea ? globalFilter?.businessArea : studentSearch?.dropdowns?.businessArea ?? []}));
      setBusinessAreaMap({ region: data?.regions ?? [], businessArea: globalFilter?.businessArea ? globalFilter?.businessArea : data?.businessAreas ?? [] })
    }));
    return ()=>dispatch(searchStudentClear())
  }, []);

  const searchHandler = (f) => {
    setPageNumber(0);
    dispatch(getStudentList({ ...f }));
  };

  const nextButtonHandler = (pageNumber) => {
    dispatch(getStudentList({ ...globalFilter, courseId: globalFilter?.courseId?.map(i => i.value) ?? [], term: globalFilter?.term?.map(i => i.value) ?? [], chargesStatus: globalFilter?.chargesStatus?.map(i => i.value) ?? [], paymentStatus: globalFilter?.paymentStatus?.map(i => i.value) ?? []  }, pageNumber, setPageNumber,setIsLastPage));;
  };

  const applyFilterHandler = () => {
    setPageNumber(0);
    dispatch(getStudentList({ ...globalFilter, ...searchInputForm, }, 0));
    setAppliedFilterCount(isFilterApplied(globalFilter))
  };

  const removeFilterHandler = () => {
    setPageNumber(0);
    dispatch(getStudentList({ ...filterFormModal, ...searchInputForm, }, 0));
    setAppliedFilterCount(0)
    setFilterForm(filterFormModal)
  };
 
  const crossInputHandler = () => {
    setPageNumber(0);
    setFilterForm({ ...globalFilter, ...emptySearchFields});
    dispatch(getStudentList({...globalFilter, ...emptySearchFields}, 0));
  };
  const searchInputValueHandler = (name) => {
    setPageNumber(0);
    dispatch(getStudentList({ ...globalFilter}, 0));
  };

  

  
  return (
    <>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          flexFlow: 'column',
          height: 'calc(100vh - 120px)',
        }}
      >
        {studentSearch.loading && <ALoader />}
        <Card className='mx-3 mb-2' style={{ flexGrow: 1 }}>
        <CardHeader className='custom-extra-padding'style={{position:'sticky',top:'44px',zIndex:'5'}}><BackIcon style={{ cursor: 'pointer' }} onClick={()=>{setPopover(false)}} /></CardHeader>
          <CardBody style={{ display: 'flex', flexFlow: 'column' }}>
            <div className='search-match-container'>
              <LeftSideFilter
                searchHandler={searchHandler}
                setFilterForm={setFilterForm}
                businessAreaMap={businessAreaMap}
                setBusinessAreaMap={setBusinessAreaMap}

              />
              <Filter
                filterForm={globalFilter}
                setNormalFilterForm={setFilterForm}
                inputHandler={inputHandler}
                applyFilterHandler={applyFilterHandler}
                setAppliedFilterCount={setAppliedFilterCount}
                appliedFilterCount={appliedFilterCount}
                removeFilterHandler={removeFilterHandler}
                crossInputHandler={crossInputHandler}
                courseIdDropdown={courseIdDropdown}
                setFilterForm={searchInputForm}
                setGlobalFilter={setGlobalFilter}
                searchInputValueHandler={searchInputValueHandler}
                setRows={setRows}
              />
            </div>
            <StudentList
              rowsData={studentSearch.studentList|| []}
              rowCount={studentSearch.rowCount || 0}
              nextButtonHandler={nextButtonHandler}
              currentPage={currentPageNumber}
              businessAreaMap={businessAreaMap}
              isLastPage={isLastPage}
              setRows={setRows}
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default StudentSearchPage