import CustomButton from "components/CustomButton";
import moment from 'moment';
import React, { useState } from "react";
import Select2 from "react-select2-wrapper";
import { Card, CardHeader, Col, Container, Input, Row, Table } from "reactstrap";
import { getAPI, masterServiceBaseUrl, putpost, putpost2 } from 'services/http';
import { MasterHeader, TDROW } from "../../common/commonComponents/masterRow/masterRow";
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';

var _ = require('lodash');

const CityTable = (props) => {
  const { tableData, city, setCity, pagination, setpagination, userPermissions, isSaveVisible, setisSaveVisible = () => { } } = props;
  const [tableDataKey, settableDataKey] = useState('tableDataKey')
  const [stateList, setStateList] = useState([])
  const [districtList, setDistrictList] = useState([])
  const [citySearchValue, setCitySearchValue] = useState('');
  const [countrySearchValue, setCountrySearchValue] = useState('');
  const [stateSearchValue, setStateSearchValue] = useState('');
  const [districtSearchValue, setDistrictSearchValue] = useState('');

  const addNewClass = () => {
    props.setclassArray([{ "editMode": true, "__status": "__new", "stateFetching": false, "districtFetching": false, cityKey: null, 'cityCode': null, 'status': 'ACTIVE', "cityDesc": null, "cityDispValue": null, "effectiveDate": null, "districtId": null, "countryId": null, "stateId": null }, ...props.classArray])
  }
  const saveAll = (cb) => {
    var _tempArray = props.classArray
    var isValid = true
    _tempArray.forEach((el, index) => {
      if (el['editMode']) {
        if (!el['countryId']) {
          isValid = false
          failureToast('Please enter the value of Country')
          return
        }
        else if (!el['stateId']) {
          isValid = false
          failureToast('Please enter the value of State')
          return
        }
        else if (!el['districtId']) {
          isValid = false
          failureToast('Please enter the value of District')
          return
        }
        else if (el['cityKey'] === null || !el['cityKey'].trim()) {
          isValid = false
          failureToast('Please enter the value of City Key')
          return
        } else if (el['cityDesc'] === null || !el['cityDesc'].trim()) {
          isValid = false
          failureToast('Please enter the value of Description')
          return
        }
        else if (el['cityDispValue'] === null || !el['cityDispValue'].trim()) {
          isValid = false
          failureToast('Please enter the value of Display Value')
          return
        }
        else if (el['cityCode'] === null || !el['cityCode'].trim()) {
          isValid = false
          failureToast('Please enter the value of City Code')
          return
        }


        if (!isValid) {
          return
        }
        if (_tempArray[index]['__status'] == "__new") {
          putpost(masterServiceBaseUrl + '/city/createCity', (data) => {
            successToast(data['message'])
            setisSaveVisible(false)
            props.fetchClasses(masterServiceBaseUrl + '/city/getAllCity')
            setStateList([])
            setDistrictList([])
          }, (data) => { failureToast(data['message']) }, _tempArray[index], 'post')
        } else {
          putpost(masterServiceBaseUrl + '/city/updateCity/' + _tempArray[index].id, (data) => {
            successToast(data['message'])
            setisSaveVisible(false)
            delete _tempArray[index]['editMode']
            props.setclassArray(_tempArray)
            settableDataKey(new Date().getMilliseconds())
            props.fetchClasses(masterServiceBaseUrl + '/city/getAllCity')
            setStateList([])
            setDistrictList([])
            // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
          }, (data) => { failureToast(data['message']) }, _tempArray[index], 'put')
        }
      } else {

      }
    })
  }
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes")
    }
    else {
      let cb = () => {
        addNewClass()
        setisSaveVisible(true)
        // settableDataKey(new Date().getMilliseconds())
      }
      var _tempArray = props.classArray
      _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
      props.setclassArray(_tempArray)
      setisSaveVisible(false)
      cb()
    }
  }
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes")
    }
    else {
      let cb = () => {
        var _tempArray = props.classArray
        _tempArray[index]['editMode'] = true
        props.setclassArray(_tempArray)
      }
      var _tempArray = props.classArray
      _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
      cb()
      getData(`${masterServiceBaseUrl}/state/getStateByCountry/${_tempArray[index]['countryId']}`, setStateList, 'state')
      getData(`${masterServiceBaseUrl}/district/getDistrictByState/${_tempArray[index]['stateId']}`, setDistrictList, 'district')
      setisSaveVisible(true)
    }
  }
  const deleteRow = (index) => {
    if (window.confirm('Are you sure you want to delete this row?')) {
      if (props.classArray[index]["__status"] == "__new") {
        let n = props.classArray
        n.splice(index, 1)
        props.setclassArray(n)
        setisSaveVisible(false)
        settableDataKey(new Date().getMilliseconds())
      } else {
        putpost2(masterServiceBaseUrl + '/city/deleteCity/' + props.classArray[index].id, (data) => {
          successToast(data['message'])
          if (props.classArray.length === 1) {
            props.previousPage()
          } else {
            var _tempArray = props.classArray
            if (_tempArray[index]['editMode']) {
              setisSaveVisible(false)
            }
            _tempArray.splice(index, 1)
            props.setclassArray(_tempArray)
          }
          props.refreshList()
          settableDataKey(new Date().getMilliseconds())
        }, (data) => { failureToast(data['message']) }, 'Delete')
      }
    }
  }
  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray
    _tempArray[index][key] = value
    props.setclassArray(_tempArray)

    if (key === 'countryId' && value !== null && value !== '') {
      setStateList([])
      setDistrictList([])
      getData(`${masterServiceBaseUrl}/state/getStateByCountry/${value}`, setStateList, 'state')
    }
    if (key === 'stateId' && value !== null && value !== '') {
      setDistrictList([])
      getData(`${masterServiceBaseUrl}/district/getDistrictByState/${value}`, setDistrictList, 'district')
    }
  }

  const getData = (url, stateMethod, keyword) => {
    getAPI(url,
      (data) => {
        const dropList = data.data.map(item => {
          return { id: item.id, text: item[`${keyword}DispValue`] };
        })
        stateMethod(dropList)
      },
      (data) => {
      }
    )
  }
  const searchPaginationHandler = (filterData, pageSize, n) => {
    if (filterData.length > pageSize) {
      n['nextPage'] = n.currentPage + 1
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1
    }
    n['totalPage'] = Math.floor(filterData.length / pageSize)
    if (filterData.length % pageSize != 0) {
      n['totalPage'] += 1
    }
    setCity(filterData);
    props.setclassArray(filterData.slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || []);
    setpagination(n)
  }

  const filterHandler = _.debounce((cityValue, countryValue, stateValue, districtValue) => {
    let n = pagination;
    let pageSize = 10;
    const filterCountryList = props.countryList.filter(data => data.text.toUpperCase().includes(countryValue.toUpperCase()));
    const filterStateList = props.stateList.filter(data => data.text.toUpperCase().includes(stateValue.toUpperCase()));
    const filterDistrictList = props.districtList.filter(data => data.text.toUpperCase().includes(districtValue.toUpperCase()));
    if (cityValue || districtValue || countryValue || stateValue) {
      if (cityValue && !districtValue && !countryValue && !stateValue) {
        const filterCountry = props.countryList.filter(data => data.text.toUpperCase().includes(cityValue.toUpperCase()));
        const filterState = props.stateList.filter(data => data.text.toUpperCase().includes(cityValue.toUpperCase()));
        const filterDistrict = props.districtList.filter(data => data.text.toUpperCase().includes(cityValue.toUpperCase()));
        const filterData = tableData.filter(data => (
          filterCountry.findIndex(countryCode => countryCode['id'] === data['countryId']) >= 0 ||
          filterState.findIndex(stateCode => stateCode['id'] === data['stateId']) >= 0 ||
          filterDistrict.findIndex(districtCode => districtCode['id'] === data['districtId']) >= 0 ||
          data['cityKey'].toUpperCase().includes(cityValue.toUpperCase()) ||
          data['cityDesc'].toUpperCase().includes(cityValue.toUpperCase()) ||
          data['cityDispValue'].toUpperCase().includes(cityValue.toUpperCase()) ||
          data['cityCode'].toUpperCase().includes(cityValue.toUpperCase()) ||
          data['status'].toUpperCase().includes(cityValue.toUpperCase())
        ));
        searchPaginationHandler(filterData, pageSize, n)
      } else if (cityValue && districtValue && countryValue && stateValue) {
        const filterData = tableData.filter(data => (
          filterCountryList.findIndex(countryCode => countryCode['id'] === data['countryId']) >= 0 &&
          filterStateList.findIndex(stateCode => stateCode['id'] === data['stateId']) >= 0 &&
          filterDistrictList.findIndex(districtCode => districtCode['id'] === data['districtId']) >= 0 &&
          data['cityKey'].toUpperCase().includes(cityValue.toUpperCase())
        ));
        searchPaginationHandler(filterData, pageSize, n)
      } else if (cityValue && !districtValue && countryValue && stateValue) {
        const filterData = tableData.filter(data => (
          filterCountryList.findIndex(countryCode => countryCode['id'] === data['countryId']) >= 0 &&
          filterStateList.findIndex(stateCode => stateCode['id'] === data['stateId']) >= 0 &&
          data['cityKey'].toUpperCase().includes(cityValue.toUpperCase())
        ));
        searchPaginationHandler(filterData, pageSize, n)
      } else if (cityValue && districtValue && countryValue && !stateValue) {
        const filterData = tableData.filter(data => (
          filterCountryList.findIndex(countryCode => countryCode['id'] === data['countryId']) >= 0 &&
          filterDistrictList.findIndex(districtCode => districtCode['id'] === data['districtId']) >= 0 &&
          data['cityKey'].toUpperCase().includes(cityValue.toUpperCase())
        ));
        searchPaginationHandler(filterData, pageSize, n)
      } else if (cityValue && !districtValue && countryValue && !stateValue) {
        const filterData = tableData.filter(data => (
          filterCountryList.findIndex(countryCode => countryCode['id'] === data['countryId']) >= 0 &&
          data['cityKey'].toUpperCase().includes(cityValue.toUpperCase())
        ));
        searchPaginationHandler(filterData, pageSize, n)
      } else if (cityValue && districtValue && !countryValue && stateValue) {
        const filterData = tableData.filter(data => (
          filterStateList.findIndex(stateCode => stateCode['id'] === data['stateId']) >= 0 &&
          filterDistrictList.findIndex(districtCode => districtCode['id'] === data['districtId']) >= 0 &&
          data['cityKey'].toUpperCase().includes(cityValue.toUpperCase())
        ));
        searchPaginationHandler(filterData, pageSize, n)
      } else if (cityValue && !districtValue && !countryValue && stateValue) {
        const filterData = tableData.filter(data => (
          filterStateList.findIndex(stateCode => stateCode['id'] === data['stateId']) >= 0 &&
          data['cityKey'].toUpperCase().includes(cityValue.toUpperCase())
        ));
        searchPaginationHandler(filterData, pageSize, n)
      } else if (cityValue && districtValue && !countryValue && !stateValue) {
        const filterData = tableData.filter(data => (
          filterDistrictList.findIndex(districtCode => districtCode['id'] === data['districtId']) >= 0 &&
          data['cityKey'].toUpperCase().includes(cityValue.toUpperCase())
        ));
        searchPaginationHandler(filterData, pageSize, n)
      } else if (!cityValue && districtValue && countryValue && stateValue) {
        const filterData = tableData.filter(data => (
          filterCountryList.findIndex(countryCode => countryCode['id'] === data['countryId']) >= 0 &&
          filterStateList.findIndex(stateCode => stateCode['id'] === data['stateId']) >= 0 &&
          filterDistrictList.findIndex(districtCode => districtCode['id'] === data['districtId']) >= 0
        ));
        searchPaginationHandler(filterData, pageSize, n)
      } else if (!cityValue && !districtValue && countryValue && stateValue) {
        const filterData = tableData.filter(data => (
          filterCountryList.findIndex(countryCode => countryCode['id'] === data['countryId']) >= 0 &&
          filterStateList.findIndex(stateCode => stateCode['id'] === data['stateId']) >= 0
        ));
        searchPaginationHandler(filterData, pageSize, n)
      } else if (!cityValue && districtValue && countryValue && !stateValue) {
        const filterData = tableData.filter(data => (
          filterCountryList.findIndex(countryCode => countryCode['id'] === data['countryId']) >= 0 &&
          filterDistrictList.findIndex(districtCode => districtCode['id'] === data['districtId']) >= 0
        ));
        searchPaginationHandler(filterData, pageSize, n)
      } else if (!cityValue && !districtValue && countryValue && !stateValue) {
        const filterData = tableData.filter(data => (
          filterCountryList.findIndex(countryCode => countryCode['id'] === data['countryId']) >= 0
        ));
        searchPaginationHandler(filterData, pageSize, n)
      } else if (!cityValue && districtValue && !countryValue && stateValue) {
        const filterData = tableData.filter(data => (
          filterStateList.findIndex(stateCode => stateCode['id'] === data['stateId']) >= 0 &&
          filterDistrictList.findIndex(districtCode => districtCode['id'] === data['districtId']) >= 0
        ));
        searchPaginationHandler(filterData, pageSize, n)
      } else if (!cityValue && !districtValue && !countryValue && stateValue) {
        const filterData = tableData.filter(data => (
          filterStateList.findIndex(stateCode => stateCode['id'] === data['stateId']) >= 0
        ));
        searchPaginationHandler(filterData, pageSize, n)
      } else if (!cityValue && districtValue && !countryValue && !stateValue) {
        const filterData = tableData.filter(data => (
          filterDistrictList.findIndex(districtCode => districtCode['id'] === data['districtId']) >= 0
        ));
        searchPaginationHandler(filterData, pageSize, n)
      }
    } else {
      searchPaginationHandler(tableData, pageSize, n);
    }
  }, 300)

  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - City</h3>
                <div className="floatRight " style={{ display: "flex", alignItems: "center", marginLeft: 'auto' }}>
                  Search : <Input disabled={isSaveVisible} onChange={(e) => { let n = e.target.value.trim(); setCitySearchValue(n); filterHandler(n, countrySearchValue, stateSearchValue, districtSearchValue) }} className="tdInput ml-3 mr-3 customInputWidth" placeholder="Search" />
                  <CustomButton
                    disabled={isSaveVisible}
                    className={'floatRight mx-1' + (isSaveVisible ? ' btn-dark cursor-gray' : null)}
                    content={'New City'}
                    permissionType={'C'}
                    icon={true}
                    onClick={() => { newRow(); }}
                    permissionSet={userPermissions}
                  />
                </div>
              </CardHeader>
              <Row>
                <Col style={{ display: "flex" }} className="mb-3">
                  <Input disabled={isSaveVisible} placeholder="Search Country" onChange={(e) => { let n = e.target.value.trim(); setCountrySearchValue(n); filterHandler(citySearchValue, n, stateSearchValue, districtSearchValue) }} className="tdInput ml-3 customInputWidth" />
                  <Input disabled={isSaveVisible} placeholder="Search State" onChange={(e) => { let n = e.target.value.trim(); setStateSearchValue(n); filterHandler(citySearchValue, countrySearchValue, n, districtSearchValue) }} className="tdInput ml-3 customInputWidth" />
                  <Input disabled={isSaveVisible} placeholder="Search District" onChange={(e) => { let n = e.target.value.trim(); setDistrictSearchValue(n); filterHandler(citySearchValue, countrySearchValue, stateSearchValue, n) }} className="tdInput ml-3 customInputWidth" />
                </Col>
              </Row>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout" >
                  <MasterHeader type={"City"} permissionSet={userPermissions} isSaveVisible={isSaveVisible} />
                  <tbody className="list" key={tableDataKey} >
                    {props.classArray ? props.classArray.map((el, index) =>
                      !el['editMode'] ? (
                        <TDROW type={"city"} index={index} el={el} editRow={editRow} deleteRow={deleteRow} countryList={props.countryList} stateList={props.stateList} districtList={props.districtList} permissionSet={userPermissions} isSaveVisible={isSaveVisible} />
                      ) :
                        (<tr key={index + '-class'}>
                          <td className="text-center p-2 mx-1"><Select2 defaultValue={el['countryId']} data={props.countryList} onChange={(e) => { updateKey(index, e.target.value, 'countryId') }} options={{ placeholder: "Select Country" }} className="tdSelect" /></td>

                          {el['__status'] && el['__status'] === '__new' ?
                            <td className="text-center p-2 mx-1"><Select2 defaultValue={el['stateId']} data={stateList} onChange={(e) => { updateKey(index, e.target.value, 'stateId') }} options={{ placeholder: "Select State" }} className="tdSelect" /></td>
                            : stateList.length ?
                              <td className="text-center p-2 mx-1"><Select2 defaultValue={el['stateId']} data={stateList} onChange={(e) => { updateKey(index, e.target.value, 'stateId') }} options={{ placeholder: "Select State" }} className="tdSelect" /></td>
                              : <td className="text-center p-2 mx-1"><Select2 defaultValue={{}} data={[]} options={{ placeholder: "Select State" }} className="tdSelect" /></td>
                          }

                          {el['__status'] && el['__status'] === '__new' ?
                            <td className="text-center p-2 mx-1"><Select2 defaultValue={el['districtId']} data={districtList} onChange={(e) => { updateKey(index, e.target.value, 'districtId') }} options={{ placeholder: "Select District" }} className="tdSelect" /></td>
                            : districtList.length ?
                              <td className="text-center p-2 mx-1"><Select2 defaultValue={el['districtId']} data={districtList} onChange={(e) => { updateKey(index, e.target.value, 'districtId') }} options={{ placeholder: "Select District" }} className="tdSelect" /></td>
                              : <td className="text-center p-2 mx-1"><Select2 defaultValue={{}} data={[]} options={{ placeholder: "Select District" }} className="tdSelect" /></td>
                          }


                          {/* <td className="text-center p-2 mx-1"><Select2 defaultValue={el['stateId']} data={stateList} onChange={(e)=>{updateKey(index,e.target.value,'stateId')}} options={{placeholder: "Select State",'data-placeholder':"Choose Something"}} className="tdSelect" /> </td>
                      <td className="text-center p-2 mx-1"><Select2 defaultValue={el['districtId']} data={districtList} onChange={(e)=>{updateKey(index,Number(e.target.value),'districtId')}} options={{placeholder: "Select District"}} className="tdSelect" /></td> */}
                          <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['cityKey']} onChange={(e) => { updateKey(index, e.target.value, 'cityKey') }} placeholder="Example:1" className="tdInput" /></td>
                          <td className="text-center p-2 mx-1"><Input disabled={true} defaultValue={el["__status"] == "__new" ? null : moment(el['eff_date']).format('DD-MM-YYYY')} placeholder="Example:01-01-2020" className="tdInput" /></td>
                          <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['cityDesc']} onChange={(e) => { updateKey(index, e.target.value, 'cityDesc') }} placeholder="Description" className="tdInput" /></td>
                          <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['cityDispValue']} onChange={(e) => { updateKey(index, e.target.value, 'cityDispValue') }} placeholder="Display Value" className="tdInput" /></td>
                          <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['cityCode']} onChange={(e) => { updateKey(index, e.target.value, 'cityCode') }} placeholder="City Code" className="tdInput" /></td>
                          {/* <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['cityGstCode']} onChange={(e)=>{updateKey(index,e.target.value,'cityGstCode')}} placeholder="City Code" className="tdInput" /></td> */}
                          <td className="text-center p-2 mx-1">
                            <label className="custom-toggle mx-auto ml-2">
                              <input checked={el.status == "ACTIVE" ? true : false} type="checkbox" id={el.id} onChange={(e) => { settableDataKey(new Date().getMilliseconds()); let n = "ACTIVE"; if (!e.target.checked) { n = "INACTIVE" } updateKey(index, n, 'status') }} />
                              <span style={{ width: "72px" }} className="custom-toggle-slider rounded-circle activeToggle" data-label-off="Inactive" data-label-on="Active" />
                            </label>
                          </td>
                          <td className="text-center">
                            {isSaveVisible ? <>
                              <CustomButton
                                content={'Save'}
                                permissionType={'C,U'}
                                icon={false}
                                forTable={true}
                                onClick={() => { saveAll() }}
                                permissionSet={userPermissions}
                              />
                              <CustomButton
                                content={''}
                                permissionType={'cancel'}
                                icon={true}
                                forTable={true}
                                onClick={() => {
                                  let n = props.classArray
                                  if (n[index]['__status'] == '__new') {
                                    n.splice(index, 1)
                                  } else {
                                    // n[index]['editMode']=false
                                    props.fetchClasses(masterServiceBaseUrl + '/city/getAllCity')
                                  }
                                  props.setclassArray(n)
                                  setisSaveVisible(false)
                                  setStateList([]);
                                  setDistrictList([])
                                }}
                                permissionSet={userPermissions}
                              />
                            </> :
                              <CustomButton
                                content={''}
                                permissionType={'D'}
                                icon={true}
                                forTable={true}
                                onClick={() => {
                                  setStateList([]);
                                  setDistrictList([])
                                  deleteRow(index)
                                }}
                                permissionSet={userPermissions}
                              />
                            }
                          </td>
                        </tr>)
                    ) : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
export default CityTable;