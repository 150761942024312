import React, { useEffect, useState, useMemo } from "react";
import { failureToast } from "../../../../common/utils/methods/toasterFunctions/function";
import { Label, FormGroup, Input, Row, Col, Button } from "reactstrap";
import { masterServiceBaseUrl, getAPI } from "services/http";
import Select from "react-select";

const OrganizationContactDetails = (props) => {
  const {
    formMode,
    setExternalOrganzationObj,
    externalOrganzationObj,
    pincode,
    enableEditMode,
    contactTitle
  } = props;
  const [apiLoader, setApiLoader] = useState(false);
  const [pincodeDisp, setPincodeDisp] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [number, setNumber] = useState(undefined);
  const [emailId, setEmailId] = useState(undefined);
  const [contactTitleDisp, setContactTitleDisp] = useState();
  const [editModeIsOpen, setEditModeIsOpen] = useState(false);
  const [editModeDetails, setEditModeDetails] = useState({
    index: "",
    editMode: false,
    type: "",
  });
  const [isDisabled, setIsDisabled] = useState();

  let patternMobileNumber = new RegExp(
    "^(?:(?:\\+|0{0,2})91(\\s*[\\-]\\s*)?|[0]?)?[6789]\\d{9}$"
  );

  useEffect(() => {

    if (formMode === "new" || enableEditMode) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);

      if (externalOrganzationObj["pincode"]) {
        getDetails(externalOrganzationObj["pincode"]);
      }

      if (externalOrganzationObj["contactTitle"]) {
        getContactTitle(externalOrganzationObj["contactTitle"]);
      }
    }
  }, [formMode, enableEditMode, externalOrganzationObj["pincode"]]);

  const onchangeMobileAndEmailHandler = (value, type) => {
    try {
      switch (type) {
        case "mobileNumber":
          setNumber(value);
          break;
        case "EmailId":
          setEmailId(value);
          break;
        default:
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const onClickAddMobileNumberOrAddEmailId = (type) => {
    try {
      switch (type) {
        case "mobileNumber":
          if (externalOrganzationObj["phoneNumber"].includes(number))
            return failureToast("Please do not enter same number");
          if (number === undefined || number === null || number === "")
            return failureToast("Please Enter your number first ");
          if (!patternMobileNumber.test(number))
            return failureToast("Please Enter a valid Number");
          let mobileTempArr = externalOrganzationObj["phoneNumber"];
          mobileTempArr.push(number);
          setExternalOrganzationObj((preState) => ({
            ...preState,
            phoneNumber: mobileTempArr,
          }));
          setNumber(undefined);
          break;
        case "emailId":
          if (emailId === undefined || emailId === null || emailId === "")
            return failureToast("Please Enter your Email first ");
          if (
            !emailId.match(
              /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
            )
          )
            return failureToast("Please Enter a valid Email");
          let emailTempArr = externalOrganzationObj["email"];
          emailTempArr.push(emailId);
          setExternalOrganzationObj((preState) => ({
            ...preState,
            email: emailTempArr,
          }));
          setEmailId(undefined);
          break;
        default:
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const deleteMobileNumber = (index) => {
    try {
      let tempMobile = props.externalOrganzationObj["phoneNumber"];
      tempMobile.splice(index, 1);
      props.setExternalOrganzationObj((preState) => ({
        ...preState,
        phoneNumber: tempMobile,
      }));
      setEditModeDetails({
        index: "",
        editMode: false,
        type: "",
      });
    } catch (error) {
      console.log("Error", error);
    }
  };

  const deleteEmail = (index) => {
    try {
      let tempEmail = props.externalOrganzationObj["email"];
      tempEmail.splice(index, 1);
      props.setExternalOrganzationObj((preState) => ({
        ...preState,
        email: tempEmail,
      }));
      setEditModeDetails({
        index: "",
        editMode: false,
        type: "",
      });
    } catch (error) {
      console.log("Error", error);
    }
  };

  const editMobileNumber = (index) => {
    try {
      if (number) {
        if (externalOrganzationObj["phoneNumber"].includes(number))
          return failureToast("Please do not enter same number");
        if (number === undefined || number === null || number === "")
          return failureToast("Please Enter your number first ");
        if (!patternMobileNumber.test(number))
          return failureToast("Please Enter a valid Number");
        let mobileObjArr = props.externalOrganzationObj["phoneNumber"];
        mobileObjArr[index] = number;
        props.setExternalOrganzationObj((preState) => ({
          ...preState,
          phoneNumber: mobileObjArr,
        }));
      }

      setEditModeDetails({ index: "", editMode: false, type: "" });
    } catch (error) {
      console.log("Error", error);
    }
  };

  const showButtonMobile = (index, editOption, type) => {
    try {
      return editModeDetails["editMode"] &&
        editModeDetails["index"] === index &&
        editModeDetails["type"] === "mobileType" ? (
        <>
          <Button
          data-testid={`editMobileNumber${index}`}
            color="info"
            size="sm"
            className={`ml-auto`}
            onClick={() => {
              editMobileNumber(index);
            }}
          >
            {" "}
            Save{" "}
          </Button>
          <Button
          data-testid={`deleteMobileNumber${index}`}
            color="warning"
            size="sm"
            className={`ml-auto`}
            onClick={() => {
              setEditModeDetails({
                index: "",
                editMode: false,
                type: "",
              });
              setNumber("");
            }}
          >
            <i className="fas fa-times" />
          </Button>
        </>
      ) : (
        <>
          <Button
          data-testid={`editMobileNumber${index}`}
            color="info"
            size="sm"
            className={`ml-auto`}
            disabled={isDisabled}
            onClick={() => {
              if (editModeDetails["editMode"])
                return failureToast("Please Close first one");
              setEditModeDetails({
                index: index,
                editMode: true,
                type: "mobileType",
              });
              setNumber(externalOrganzationObj["phoneNumber"][index]);
              setEditModeIsOpen(true);
            }}
          >
            <i className="fas fa-edit" />
          </Button>
          <Button
          data-testid={`deleteMobileNumber${index}`}
            disabled={isDisabled}
            color="warning"
            size="sm"
            className={`ml-auto`}
            onClick={() => {
              deleteMobileNumber(index);
            }}
          >
            <i className="fas fa-trash" />
          </Button>
        </>
      );
    } catch (error) {
      console.log("Error", error);
    }
  };

  const editEmailId = (index) => {
    try {
      if (emailId) {
        if (emailId === undefined || emailId === null || emailId === "")
          return failureToast("Please Enter your Email first ");
        if (
          !emailId.match(
            /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
          )
        )
          return failureToast("Please Enter a valid Email");
        let emailObjArr = props.externalOrganzationObj["email"];
        emailObjArr[index] = emailId;
        setExternalOrganzationObj((preState) => ({
          ...preState,
          email: emailObjArr,
        }));
      }

      setEditModeDetails({ index: "", editMode: false, type: "" });
    } catch (error) {
      console.log("Error", error);
    }
  };

  const showButtonEmail = (index, editOption, type) => {
    try {
      return editModeDetails["editMode"] &&
        editModeDetails["index"] === index &&
        editModeDetails["type"] === "emailType" ? (
        <>
          <Button
          data-testid={`editEmailBtn${index}`}
            color="info"
            size="sm"
            className={`ml-auto`}
            onClick={() => {
              editEmailId(index);
            }}
          >
            {" "}
            Save{" "}
          </Button>
          <Button
          data-testid={`deleteEmailBtn${index}`}
            color="warning"
            size="sm"
            className={`ml-auto`}
            onClick={() => {
              setEditModeDetails({
                index: "",
                editMode: false,
                type: "",
              });
            }}
          >
            <i className="fas fa-times" />
          </Button>
        </>
      ) : (
        <>
          <Button
          data-testid={`editEmailBtn${index}`}
            color="info"
            size="sm"
            className={`ml-auto`}
            disabled={isDisabled}
            onClick={() => {
              if (editModeDetails["editMode"])
                return failureToast("Please Close first one");
              setEditModeDetails({
                index: index,
                editMode: true,
                type: "emailType",
              });
              setEmailId(externalOrganzationObj["email"][index]);
              setEditModeIsOpen(true);
            }}
          >
            <i className="fas fa-edit" />
          </Button>
          <Button
          data-testid={`deleteEmailBtn${index}`}
            color="warning"
            size="sm"
            className={`ml-auto`}
            disabled={isDisabled}
            onClick={() => {
              deleteEmail(index);
            }}
          >
            <i className="fas fa-trash" />
          </Button>
        </>
      );
    } catch (error) {
      console.log("Error", error);
    }
  };

  const getPincodeDetails = async (value) => {
    try {
      getAPI(
        `${masterServiceBaseUrl}/country/getCountry/${value.countryId}`,
        (data) => {
          setCountry(data.data.countryDispValue);
          props.setExternalOrganzationObj((preState) => ({
            ...preState,
            country: data.data.id,
          }));
        },
        (data) => {
          failureToast(data["message"]);
          setApiLoader(false);
        }
      );
      getAPI(
        `${masterServiceBaseUrl}/state/getState/${value.stateId}`,
        (data) => {
          setState(data.data.stateDispValue);
          props.setExternalOrganzationObj((preState) => ({
            ...preState,
            state: data.data.id,
          }));
        },
        (data) => {
          failureToast(data["message"]);
          setApiLoader(false);
        }
      );
      getAPI(
        `${masterServiceBaseUrl}/city/getCity/${value.cityId}`,
        (data) => {
          setCity(data.data.cityDispValue);
          props.setExternalOrganzationObj((preState) => ({
            ...preState,
            city: data.data.id,
          }));
          setApiLoader(false);
        },
        (data) => {
          failureToast(data["message"]);
          setApiLoader(false);
        }
      );
    } catch (error) {
      console.log("Error", error);
    }
  };

  const getDetails = async (value) => {
    try {
      setApiLoader(true);
      await getAPI(
        `${masterServiceBaseUrl}/pincode/getPincode/${value}`,
        (data) => {
          setPincodeDisp(data?.data?.pincodeDispValue);
          setExternalOrganzationObj((preState) => ({
            ...preState,
            pincode: data?.data?.id,
          }));
          getPincodeDetails(data.data);
        },
        (data) => {
          setApiLoader(false);
          failureToast(data["message"]);
        }
      );
    } catch (error) {
      console.log("Error", error);
    }
  };

  const getContactTitle = (id) => {
    try {
      getAPI(
        `${masterServiceBaseUrl}/contactTitle/getContactTitle/${id}`,
        (data) => {
          data &&
            data.data &&
            setContactTitleDisp(data.data.contactTitleDispValue);
        },
        (data) => {
          failureToast(data["message"]);
        }
      );
    } catch (error) {
      console.log("Error", error);
    }
  };
  return (
    <div>
      <Row>
        <Col sm={12}>
          <FormGroup row>
            <Col sm={4} md={3} lg={3} xl={2}>
              <Label
                className="form-control-label"
                htmlFor="example3cols2Input"
              >
                Contact Person Name
              </Label>
            </Col>
            <Col sm={8} md={9} xl={10}>
              <input
              data-testid="contactPersonName"
                className="form-control addresslocationInput"
                disabled={isDisabled}
                maxLength={50}
                value={externalOrganzationObj["contactPersonName"]}
                onChange={(value) => {
                  let contactPersonName = value.target.value;
                  setExternalOrganzationObj((preState) => ({
                    ...preState,
                    contactPersonName: contactPersonName,
                  }));
                }}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <FormGroup row>
            <Col sm={4} md={3} lg={3} xl={2}>
              <Label
                className="form-control-label"
                htmlFor="example3cols2Input"
              >
                Contact Title
              </Label>
            </Col>
            <Col sm={8} md={9} xl={10}>
              <Select
                placeholder="Select Contact Title"
                value={{
                  value: externalOrganzationObj["contactTitle"],
                  label: contactTitleDisp,
                }}
                isDisabled={isDisabled}
                options={contactTitle}
                onChange={(value) => {
                  setContactTitleDisp(value.label);
                  setExternalOrganzationObj((preState) => ({
                    ...preState,
                    contactTitle: value.value,
                  }));
                }}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={8} md={9} lg={9} xl={8}>
          <FormGroup row>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Label className="form-control-label" for="organisationId">
                Phone Number
              </Label>
            </Col>
            <Col sm={6} md={8} lg={8} xl={9}>
              <input
              data-testid="phoneNumber"
                maxLength={10}
                name="phoneNumber"
                className="form-control addresslocationInput"
                id="phoneNumber"
                onChange={(value) =>
                  onchangeMobileAndEmailHandler(
                    value.target.value,
                    "mobileNumber"
                  )
                }
                disabled={
                  externalOrganzationObj &&
                  externalOrganzationObj["phoneNumber"] &&
                  externalOrganzationObj["phoneNumber"].length < 2
                    ? isDisabled
                      ? true
                      : false
                    : true
                }
              />
            </Col>
          </FormGroup>
        </Col>

        <Col sm={4} md={3} lg={3} xl={4} className="text-center">
          <FormGroup>
            <Button
            data-testid="addMobileBtn"
              color="info"
              size="sm"
              className={`ml-auto`}
              disabled={
                externalOrganzationObj?.phoneNumber?.length < 2
                  ? isDisabled
                    ? true
                    : false
                  : true
              }
              onClick={() => {
                onClickAddMobileNumberOrAddEmailId("mobileNumber");
                document.getElementById("phoneNumber").value = "";
              }}
            >
              Add
            </Button>
          </FormGroup>
        </Col>
      </Row>
      {externalOrganzationObj?.phoneNumber?.map((item, index) => (
        <>
          <Row>
            <Col
              sm={{ size: 4, offset: 4 }}
              md={{ size: 6, offset: 3 }}
              lg={{ size: 6, offset: 3 }}
              xl={{ size: 6, offset: 2 }}
            >
              <FormGroup>
                <input
                 data-testid={`phoneNumberField${index}`}
                  className="form-control addresslocationInput"
                  maxLength={10}
                  value={
                    editModeDetails["editMode"] &&
                    index == editModeDetails["index"] &&
                    editModeDetails["type"] === "mobileType"
                      ? editModeIsOpen
                        ? number
                        : externalOrganzationObj["phoneNumber"][index]
                      : item
                  }
                  disabled={
                    editModeDetails["editMode"] &&
                    index == editModeDetails["index"] &&
                    editModeDetails["type"] === "mobileType"
                      ? false
                      : true
                  }
                  onChange={(e) => {
                    let tempMobileNumber = e.target.value;
                    setNumber(tempMobileNumber);
                  }}
                />
              </FormGroup>
            </Col>
            <Col sm={4} md={3} lg={3} xl={4} className="text-center">
              <FormGroup>
                {showButtonMobile(index, false, "mobileType")}
              </FormGroup>
            </Col>
          </Row>
        </>
      ))}
      <Row>
        <Col sm={8} md={9} lg={9} xl={8}>
          <FormGroup row>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Label className="form-control-label" for="organisationId">
                Email ID
              </Label>
            </Col>
            <Col sm={6} md={8} lg={8} xl={9}>
              <input
               data-testid="emailId"
                name="emailId"
                className="form-control addresslocationInput"
                id="emailId"
                maxLength={50}
                onChange={(value) =>
                  onchangeMobileAndEmailHandler(value.target.value, "EmailId")
                }
                disabled={
                  externalOrganzationObj?.email?.length < 2
                    ? isDisabled
                      ? true
                      : false
                    : true
                }
              />
            </Col>
          </FormGroup>
        </Col>
        <Col sm={4} md={3} lg={3} xl={4} className="text-center">
          <FormGroup>
            <Button
             data-testid="emailIdAdd"
              color="info"
              size="sm"
              className={`ml-auto`}
              disabled={
                externalOrganzationObj?.email?.length < 2
                  ? isDisabled
                    ? true
                    : false
                  : true
              }
              onClick={() => {
                onClickAddMobileNumberOrAddEmailId("emailId");
                document.getElementById("emailId").value = "";
              }}
            >
              Add
            </Button>
          </FormGroup>
        </Col>
      </Row>
      {externalOrganzationObj?.email?.map((item, index) => (
        <>
          <Row>
            <Col
              sm={{ size: 4, offset: 4 }}
              md={{ size: 6, offset: 3 }}
              lg={{ size: 6, offset: 3 }}
              xl={{ size: 6, offset: 2 }}
            >
              <FormGroup>
                <input
                 data-testid={`emailType${index}`}
                  className="form-control addresslocationInput"
                  defaultValue={item}
                  value={
                    editModeDetails["editMode"] &&
                    index == editModeDetails["index"] &&
                    editModeDetails["type"] === "emailType"
                      ? editModeIsOpen
                        ? emailId
                        : externalOrganzationObj["email"][index]
                      : item
                  }
                  disabled={
                    editModeDetails["editMode"] &&
                    index == editModeDetails["index"] &&
                    editModeDetails["type"] === "emailType"
                      ? false
                      : true
                  }
                  onChange={(e) => {
                    let tempEmail = e.target.value;
                    // let emailObjArr = props.externalOrganzationObj[ 'email' ];
                    // emailObjArr[ index ] = tempEmailNumber;
                    // setExternalOrganzationObj( preState =>( {
                    //   ...preState,
                    //   email:emailObjArr
                    // } ) )
                    setEmailId(tempEmail);
                  }}
                />
              </FormGroup>
            </Col>
            <Col sm={4} md={3} lg={3} xl={4} className="text-center">
              <FormGroup>
                {showButtonEmail(index, false, "emailType")}
              </FormGroup>
            </Col>
          </Row>
        </>
      ))}
      <Row>
        <Col sm={6}>
          <FormGroup row>
            <Col lg={12} xl={4}>
              <Label
                className="form-control-label"
                htmlFor="example3cols2Input"
              >
                Address 1
              </Label>
            </Col>
            <Col lg={12} xl={8}>
              <input
               data-testid="addressLine1"
                maxLength={100}
                className="form-control addresslocationInput"
                disabled={isDisabled}
                value={externalOrganzationObj["addressLine1"]}
                onChange={(value) => {
                  let address1Temp = value.target.value;
                  setExternalOrganzationObj((preState) => ({
                    ...preState,
                    addressLine1: address1Temp,
                  }));
                }}
              />
            </Col>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup row>
            <Col lg={12} xl={4}>
              <Label
                className="form-control-label"
                htmlFor="example3cols2Input"
              >
                City
              </Label>
            </Col>
            <Col lg={12} xl={8}>
              <Input
               data-testid="city"
                className="form-control addresslocationInput"
                disabled={true}
                value={city}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col sm={6}>
          <FormGroup row>
            <Col lg={12} xl={4}>
              <Label
                className="form-control-label"
                htmlFor="example3cols2Input"
              >
                Address 2
              </Label>
            </Col>
            <Col lg={12} xl={8}>
              <input
               data-testid="addressLine2"
                maxLength={100}
                disabled={isDisabled}
                value={externalOrganzationObj["addressLine2"]}
                className="form-control addresslocationInput"
                onChange={(value) => {
                  let address2 = value.target.value;
                  props.setExternalOrganzationObj((preState) => ({
                    ...preState,
                    addressLine2: address2,
                  }));
                }}
              />
            </Col>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup row>
            <Col lg={12} xl={4}>
              <Label
                className="form-control-label"
                htmlFor="example3cols2Input"
              >
                State
              </Label>
            </Col>
            <Col lg={12} xl={8}>
              <Input
               data-testid="state"
                className="form-control addresslocationInput"
                value={state}
                disabled={true}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup row>
            <Col lg={12} xl={4}>
              <Label
                className="form-control-label"
                htmlFor="example3cols2Input"
              >
                Pin Code
              </Label>
            </Col>
            <Col lg={12} xl={8}>
              <Select
                value={{
                  value: externalOrganzationObj["pincode"],
                  label: pincodeDisp,
                }}
                isDisabled={isDisabled}
                options={pincode}
                onChange={(value) => {
                  getDetails(value.value);
                  setExternalOrganzationObj((preState) => ({
                    ...preState,
                    pincode: value.value,
                  }));
                }}
              />
            </Col>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup row>
            <Col lg={12} xl={4}>
              <Label
                className="form-control-label"
                htmlFor="example3cols2Input"
              >
                Country
              </Label>
            </Col>
            <Col lg={12} xl={8}>
              <Input
               data-testid="country"
                value={country}
                className="form-control addresslocationInput"
                disabled={true}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row></Row>
    </div>
  );
};

export default OrganizationContactDetails;
