import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Input, Button, Table, Row } from "reactstrap";
import { failureToast } from "services/http";
import {
  SeatingCapacityThrow,
  SeatingCapacityOrganizationHeader,
} from "../../../../common/commonComponents/masterRow/masterRow";
var _ = require("lodash");

const SeatingCapacityTable = (props) => {
  const {
    setExternalOrganzationObj,
    externalOrganzationObj,
    formMode,
    enableEditMode,
  } = props;
  const [isDisabled, setIsDisabled] = useState();
  const [tableDataKey, settableDataKey] = useState("tableDataKey");
  const [newCapacity, setNewCapacity] = useState(false);
  const [seatingCapacityDetails, setSeatingCapacityDetails] = useState({
    roomNumber: "",
    roomCapacity: "",
    floorNumber: "",
  });

  useEffect(() => {
    formMode === "new" || enableEditMode
      ? setIsDisabled(false)
      : setIsDisabled(true);
  }, [newCapacity, enableEditMode, formMode]);

  useEffect(() => {
    console.log(seatingCapacityDetails);
  }, [seatingCapacityDetails]);
  const saveRecord = () => {
    try {
      let pattern = /^[0-9]+$/;
      if (
        seatingCapacityDetails.roomNumber.trim() === "" ||
        seatingCapacityDetails.roomCapacity.trim() === "" ||
        seatingCapacityDetails.floorNumber.trim() === ""
      )
        return failureToast("Please Enter Seating Details");

      if (!pattern.test(seatingCapacityDetails["roomCapacity"]))
        return failureToast("Please enter valid room capacity ");

      let tempCapacity = props.externalOrganzationObj["seatingCapacity"];
      tempCapacity.push(seatingCapacityDetails);
      setExternalOrganzationObj((preState) => ({
        ...preState,
        seatingCapacity: tempCapacity,
      }));
      setNewCapacity(false);

      setSeatingCapacityDetails({
        roomNumber: "",
        roomCapacity: "",
        floorNumber: "",
      });
    } catch (error) {
      console.log("Error", error);
    }
  };

  const updateRecord = (index) => {
    try {
      let pattern = /^[0-9]+$/;
      if (
        seatingCapacityDetails.roomNumber.trim() === "" ||
        seatingCapacityDetails.roomCapacity.trim() === "" ||
        seatingCapacityDetails.floorNumber.trim() === ""
      )
        return failureToast("Please Enter Seating Details");
      if (!pattern.test(seatingCapacityDetails["roomCapacity"]))
        return failureToast("Please enter valid room capacity ");
      let tempCapacity = props.externalOrganzationObj["seatingCapacity"];
      tempCapacity[index] = seatingCapacityDetails;
      setExternalOrganzationObj((preState) => ({
        ...preState,
        seatingCapacity: tempCapacity,
      }));
      props.setEditMode({
        editMode: false,
        index: "",
      });
      setSeatingCapacityDetails({
        roomNumber: "",
        roomCapacity: "",
        floorNumber: "",
      });
    } catch (error) {
      console.log("Error", error);
    }
  };

  const editModeEnable = (index) => {
    try {
      setSeatingCapacityDetails({
        roomNumber:
          externalOrganzationObj["seatingCapacity"][index]["roomNumber"],
        roomCapacity:
          externalOrganzationObj["seatingCapacity"][index]["roomCapacity"],
        floorNumber:
          externalOrganzationObj["seatingCapacity"][index]["floorNumber"],
      });
      props.setEditMode({
        editMode: true,
        index: index,
      });
    } catch (error) {
      console.log("Error", error);
    }
  };

  const newRow = () => {
    try {
      setNewCapacity(true);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const deleteRow = (index) => {
    try {
      let tempSeatingCapacity = externalOrganzationObj["seatingCapacity"];
      tempSeatingCapacity.splice(index, 1);
      setExternalOrganzationObj((preState) => ({
        ...preState,
        seatingCapacity: tempSeatingCapacity,
      }));
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <Fragment>
      <Row className="pl-3 pb-3">
        {!isDisabled ? (
          <Button
          data-testid="addNewCapacity"
            color="info"
            size="sm"
            type="button"
            className="floatRight mx-1 info"
            onClick={() => {
              newRow();
            }}
          >
            New Capacity
          </Button>
        ) : null}
      </Row>
      <div id="questionTable" style={{ overflow: "auto" }}>
        <Table className="align-items-center table-flush tableLayout">
          <SeatingCapacityOrganizationHeader />
          <tbody className="list" key={tableDataKey}>
            {newCapacity ? (
              <tr>
                <td className="text-left p-2 mx-1">
                  <Input
                  data-testid="roomNumber"
                    maxLength={10}
                    className="tdInput"
                    placeholder="Room Number"
                    onChange={(e) => {
                      let roomNumberTemp = e.target.value;
                      setSeatingCapacityDetails((preState) => ({
                        ...preState,
                        roomNumber: roomNumberTemp,
                      }));
                    }}
                  />
                </td>
                <td className="text-center p-2 mx-1">
                  <Input
                  data-testid="roomCapacity"
                    maxLength={5}
                    className="tdInput"
                    placeholder="Room Capacity"
                    onChange={(e) => {
                      let roomCapacityTemp = e.target.value;
                      setSeatingCapacityDetails((preState) => ({
                        ...preState,
                        roomCapacity: roomCapacityTemp,
                      }));
                    }}
                  />
                </td>
                <td className="text-center p-2 mx-1">
                  <Input
                  data-testid="floorNumber"
                    maxLength={10}
                    className="tdInput"
                    placeholder="Floor Number"
                    onChange={(e) => {
                      let floorNumberTemp = e.target.value;
                      setSeatingCapacityDetails((preState) => ({
                        ...preState,
                        floorNumber: floorNumberTemp,
                      }));
                    }}
                  />
                </td>
                <td className="text-center">
                  <Button
                  data-testid="saveRecord"
                    color="info"
                    size="sm"
                    type="button"
                    className="mx-1"
                    onClick={() => {
                      saveRecord();
                    }}
                  >
                    Save
                  </Button>
                  <Button color="danger" size="sm" type="button">
                    <i
                      className="fas fa-times"
                      onClick={() => setNewCapacity(false)}
                    />
                  </Button>
                </td>
              </tr>
            ) : null}
            {props.externalOrganzationObj
              ? props.externalOrganzationObj?.seatingCapacity?.map(
                  (item, index) =>
                    props.editMode["editMode"] &&
                    props.editMode["index"] == index ? (
                      <tr>
                        <td className="text-left p-2 mx-1">
                          <Input
                          data-testid="updateRoomNumber"
                            className="tdInput"
                            maxLength={10}
                            defaultValue={item["roomNumber"]}
                            onChange={(e) => {
                              let roomNumberTemp = e.target.value;
                              setSeatingCapacityDetails((preState) => ({
                                ...preState,
                                roomNumber: roomNumberTemp,
                              }));
                            }}
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                          data-testid="updateRoomCapacity"
                            className="tdInput"
                            maxLength={5}
                            defaultValue={item["roomCapacity"]}
                            onChange={(e) => {
                              let roomCapacityTemp = e.target.value;
                              setSeatingCapacityDetails((preState) => ({
                                ...preState,
                                roomCapacity: roomCapacityTemp,
                              }));
                            }}
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                          data-testid="updateFloorNumber"
                            className="tdInput"
                            maxLength={10}
                            defaultValue={item["floorNumber"]}
                            onChange={(e) => {
                              let floorNumberTemp = e.target.value;
                              setSeatingCapacityDetails((preState) => ({
                                ...preState,
                                floorNumber: floorNumberTemp,
                              }));
                            }}
                          />
                        </td>
                        <td className="text-center">
                          <Button
                          data-testid="updateRecord"
                            color="info"
                            size="sm"
                            type="button"
                            className="mx-1"
                            onClick={() => updateRecord(index)}
                          >
                            Save
                          </Button>
                          <Button
                            color="danger"
                            size="sm"
                            type="button"
                            onClick={() =>
                              props.setEditMode({
                                editMode: false,
                                index: "",
                              })
                            }
                          >
                            <i className="fas fa-times" />
                          </Button>
                        </td>
                      </tr>
                    ) : (
                      <SeatingCapacityThrow
                        isDisabled={isDisabled}
                        key={index + "-class"}
                        type={"testMode"}
                        index={index}
                        el={item}
                        editModeEnable={editModeEnable}
                        setEditMode={props.setEditMode}
                        editMode={props.editMode}
                        deleteRow={deleteRow}
                      />
                    )
                )
              : null}
          </tbody>
        </Table>
        <Row className="mt-2 pr-3 pt-2 pb-3 mr-2 pl-5">
          {props.externalOrganzationObj?.seatingCapacity?.length < 1 ? (
            <span> Please Add Capacity From Above </span>
          ) : null}
        </Row>
      </div>
    </Fragment>
  );
};
export default SeatingCapacityTable;
