import moment from "moment"
import { endpoint } from "views/pages/manage/common/constant"
import { fetchAllPostPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod"
import { fetchAllPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod"
import { prePopulatedFormField } from "views/pages/manage/edpOps/constant/allocate-students"
import { fetchAllDropdowns } from "views/pages/manage/edpOps/constant/allocate-students"
import { getDownloadFileUrl, updateRequest } from "../../views/pages/manage/edpOps/constant/common"
import { BATCH_DETAILS_FAILURE, BATCH_DETAILS_REQUEST, BATCH_DETAILS_SUCCESS, CLEAR_BATCH_DETAILS_STATE, FETCH_DROPDOWN_FAILURE, FETCH_DROPDOWN_SUCCESS, START_DOWNLOAD_LOADER, STOP_DOWNLOAD_LOADER, STUDENT_LIST_FAILURE, STUDENT_LIST_REQUEST, STUDENT_LIST_SUCCESS } from "./allocate-students-action-types"

export const studentListRequest = () => {
  return {
    type: STUDENT_LIST_REQUEST
  }
}

export const studentListSuccess = (data) => {
  return {
    type: STUDENT_LIST_SUCCESS,
    payload: data?.studentApplicationDetailsSearchResponseDTOList,
    totalServerPages: data?.totalPage,
    rowCount: data?.totalRecords
  }
}

export const studentListFailure = (errMsg) => { 
  return {
    type: STUDENT_LIST_FAILURE,
    payload: errMsg
  }
}

export const batchDetailsRequest = () => {
  return {
    type: BATCH_DETAILS_REQUEST
  }
}

export const batchDetailsSuccess = (batchDetails) => {
  return {
    type: BATCH_DETAILS_SUCCESS,
    payload: batchDetails
  }
}

export const batchDetailsFailure = (errMsg) => {
  return {
    type: BATCH_DETAILS_FAILURE,
    payload: errMsg
  }
}

export const clearBatchDetailsState = () => {
  return {
    type: CLEAR_BATCH_DETAILS_STATE
  }
}

export const fetchDropdownSuccess = (dropdownDataArray) => {
  return {
    type: FETCH_DROPDOWN_SUCCESS,
    payload: dropdownDataArray
  }
}

export const fetchDropdownFailure = (errMsg) => {
  return {
    type: FETCH_DROPDOWN_FAILURE,
    payload: errMsg
  }
}

export const getBatchDetailsById = (id, cb) => {
  return async (dispatch, getState) => {
    dispatch(batchDetailsRequest());

    const response = await fetchAllPromisedData(`${endpoint.viewEditBatch.getBatchById}/${id}`, true)
    // console.log(response)

    if(response?.code===200){
      dispatch(batchDetailsSuccess(response?.data))
      const { term, businessArea, courseId, batchType } = response.data
      const updatePayload = batchType === 'PRIMARY' ? prePopulatedFormField([businessArea], courseId, term) : {}
      cb(updatePayload)
    } else {
      dispatch(batchDetailsFailure(response?.message))
    }
  }
}

export const getStudentList = (data = {}, page = 0) => {
  return async (dispatch, getState) => {
    dispatch(studentListRequest());
    if (data['endDate'] && !data['startDate']) {
      data['startDate'] = (moment(data['endDate']).subtract(1, 'days'))
    }
    if (data['startDate'] && !data['endDate']) {
      data['endDate'] = moment(data['startDate']).add(1, 'days')
    }
    const response = await fetchAllPostPromisedData(`${endpoint.allocateStudents.searchStudentApplications}?offset=${page}`, updateRequest(data), 'post');
    // console.log(response)

    if (response?.code === 200) {
      dispatch(studentListSuccess(response?.data))
    } else {
      dispatch(studentListFailure(response?.message))
    }
  }
}

export const fetchDropdownsAction = () => {
  return async (dispatch) => {
    const response = await fetchAllDropdowns();
    if (response)
      dispatch(fetchDropdownSuccess(response));
    else 
      dispatch(fetchDropdownFailure(response.message))
  };
};

export const startDownloadLoader = () => {
  return {
    type: START_DOWNLOAD_LOADER
  }
}

export const stopDownloadLoader = () => {
  return {
    type: STOP_DOWNLOAD_LOADER
  }
}

export const allocateStudentsDownloadCsv = (reqPayload) => {
  return async (dispatch, getState) => {
    dispatch(startDownloadLoader());
    try {
      const response = await fetchAllPostPromisedData(
        `${endpoint.allocateStudents.downloadCsv}`,
        updateRequest(reqPayload),
        "post"
      );
      if (response) {
        getDownloadFileUrl(response.data?.key)
        dispatch(stopDownloadLoader())

      } else {
        dispatch(stopDownloadLoader())
      }
    } catch (error) {
      dispatch(stopDownloadLoader())
    }

  };
};