import React, { useContext, useState } from 'react'
import { ReactComponent as EditIcon } from '../../../../../../../assets/img/svg/Edit2.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../../assets/img/svg/Delete.svg';
import DeleteDialog from './DeleteDialog';
// import { checkPermission } from '../../common/utils/methods/commonMethods/utilityMethod';
import AButton from 'views/pages/manage/common/form-fields-mui/AButton'
// import { RolePermissions } from 'appContext'
// import { PermissionContext } from 'appContext'
// import { pages } from '../../common/constant'


const ActionsCellComponent = ({ params, handleEditClick, handleDeleteClick, handleSaveClick, deleteHandler, rowForm ,editMode=false, saving=false,isDeleting=false , isActionDisabled =false, userPermissions, disableEdit=false}) => {


  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
  
      !editMode 
      ?(
        <>
      {userPermissions.includes("U")&&
       <div className='d-flex align-items-center'>
         <EditIcon onClick={saving || isDeleting || isActionDisabled || disableEdit ? () =>{} : () => handleEditClick(params)}  style={isActionDisabled || disableEdit ? {cursor: 'not-allowed'} : {cursor:'pointer'}}/>
         {params?.id === isDeleting ? <span style={{ borderRadius: '5px', padding: '5px 7px', marginLeft: '13px', color: '#fff', backgroundColor:'#E6675A'}}><i className="fas fa-spinner fa-spin" aria-hidden="true">{''}</i></span> :<DeleteIcon className='ml-3 '  onClick={saving || isDeleting || isActionDisabled? () =>{} : () => setIsDialogOpen(true)} style={isActionDisabled ? {cursor: 'not-allowed'} : {cursor:'pointer'}}/>}
        </div>
       }
       <DeleteDialog
          open={isDialogOpen}
          setOpen={setIsDialogOpen}
          data={params?.row}
          onAgree={() => handleDeleteClick(params)}
        /> 
        </>
        
    
    ) : (
      <div className='d-flex align-items-center'>
        <AButton
          variant='primary_filled'
          updatedStyle={{ height: '28px', fontWeight: 600, fontSize: '12px', lineHeight: '16px', borderRadius: '4px' }}
          onClick={() => handleSaveClick(params)}
          disabled={!rowForm?.chargeTypeId || !rowForm?.courseId || !rowForm?.quantity || saving || isDeleting}
        >
          {saving === params?.id ? <i className="fas fa-spinner fa-spin" aria-hidden="true">{''}</i> : 'Save'}
        </AButton>
        <DeleteIcon className='ml-3' onClick={saving || isDeleting ? () =>{} : ()=>handleDeleteClick(params)}/>
      </div>
    ))
  
}

export default ActionsCellComponent