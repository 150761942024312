import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Input, Table,} from "reactstrap";
import moment from "moment";
import {masterServiceBaseUrl,putpost, putpost2, successToast,} from "../../../../../../services/http";
import { SetupInstallmentsHeader} from "../../../common/commonComponents/masterRow/masterRow";
import Select2 from "react-select2-wrapper";
import CustomDatePicker from '../../../common/commonComponents/CustomDatePicker/index';
import {failureToast} from "../../../common/utils/methods/toasterFunctions/function";
import { BsFillXSquareFill } from "react-icons/bs";
var _ = require('lodash');

const SetupInstallmentsTable =(props)=>{
    const [itemType , setItemType] = useState();
    const [itemDescription,setItemDescription] = useState();
    const [selectedItemType,setSelectedItemType] = useState();
    const [taxPercent,setTaxPercent] = useState();
    const [baseFee,setBaseFee] = useState();
    const [totalAmount,setTotalAmount] = useState();
    const [taxAmount,setTaxAmount] = useState();
    const [cashAllowed,setCashAllowed] = useState(true);
    const [status,setStatus] = useState('ACTIVE');
    const [itemTypeId,setItemTypeId] = useState()
    const [dueDate,setDueDate] = useState();
    const [isEdit,setIsEdit] = useState(false)
    const editRow = (index)=>{
        try{
            if (props.isSaveVisible) {
                failureToast("Please Delete or Save current changes")
            }
            else {
                let cb = () => {
                    setIsEdit(false);
                    let tempArray = props.setupInstallmentsData;
                    tempArray[index]['isEdit'] = true;
                    // console.log('Data-------->',tempArray[index]);
                    setDueDate(moment(tempArray[index]['dueDate']).format('YYYY-MM-DD'));
                    setItemType(tempArray[index]['itemType']);
                    setSelectedItemType(tempArray[index]['itemType']);
                    setItemDescription(tempArray[index]['itemTypeDescription']);
                    setBaseFee(tempArray[index]['baseFee']);
                    setTaxAmount(tempArray[index]['tax']);
                    setCashAllowed(tempArray[index]['cashAllowed']);
                    setStatus(tempArray[index]['status']);
                    setTotalAmount(tempArray[index]['totalAmount'])
                    // console.log('data--->',baseFee,taxAmount,itemType,dueDate);
                    props.setIsSaveVisible(true)
                    if(props.itemTypeData){
                        props.itemTypeData.map((item)=>{
                            if(item['id'] === tempArray[index]['itemTypeId']){
                                setSelectedItemType(item.itemTypeId);
                                setItemDescription(item.description);
                            }
                        })
                    }
                }
                cb()
            }
        }catch(e){
            console.log('Error in edit row----->',e)
        }

    }

    const cancelRow =(index)=>{
        props.setIsSaveVisible(false);
        let tempArray = props.setupInstallmentsData;
        if(tempArray[index]['isEdit']){
            tempArray[index]['isEdit']=false;
        }else if(tempArray[index]['isNew']){
            tempArray.splice(index,1)
        }
    }
    const addNewRow = () =>{props.setSetupInstallmentsData([{
        courseYearDisValue : null, dueDate:null, itemType:null, itemTypeDescription : null, baseFees:null,
        tax : null, totalAMount: null,cashAllowed:null,status:'ACTIVE',
        updatedDate:moment().format('DD-MM-YYYY'), isNew:true, isEdit:false
        }
        ,...props.setupInstallmentsData])
    }

    const newRow =()=>{
        let cb = () => { addNewRow(); props.setIsSaveVisible(true) }
        setIsEdit(false);
        setDueDate(null);
        setItemType(null);
        setSelectedItemType('');
        setItemDescription(null);
        setBaseFee(null);
        setTaxAmount(null);
        setCashAllowed(true);
        setStatus('ACTIVE');
        setTotalAmount(null);
        let _tempArray = props.setupInstallmentsData;
        _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
        props.setSetupInstallmentsData(_tempArray);
        props.setIsSaveVisible(false)
        cb();
    }
    const deleteRow =(index)=>{
        if (props.isSaveVisible) {
            failureToast("Please Delete or Save current changes");
            return;
        }
        let tempArray = props.setupInstallmentsData;
        let id = tempArray[index]['id']
        if(window.confirm('Are you sure you want to delete this Item Type?')) {
            putpost2(masterServiceBaseUrl + '/setupInstalments/deleteSetupInstalments/' + id, (data) => {
                successToast(data.message);
                props.fetchSetupInstallments(masterServiceBaseUrl+`/setupInstalments/getByCourseFeeYearIdAndCourseYearId?courseFeeYearId=${props.setupDatesId}&courseYearId=${props.courseYearId}`);
            }, (data) => {
                failureToast(data.message);
            }, 'Delete')
        }
    }
    const saveRow=(index)=>{
        try{
            let tempData = props.setupInstallmentsData;
            let isValid = true;
            if(!dueDate){
                failureToast('Please Select Due Date'); isValid=false
            }else if(!selectedItemType){
                failureToast('Please Select Item Type.'); isValid = false;
            }else if(!baseFee){
                failureToast('Please Enter Base Fees.'); isValid = false;
            } else if(!taxAmount){
                failureToast('Please Enter tax Amount.'); isValid = false;
            }else{
                if(new Date(dueDate) < new Date(props.setupDatesData.startDate || new Date(dueDate) > new Date(props.setupDatesData.endDate))){
                    failureToast('Please Select date in between start date and end date');
                    isValid = false;
                }else{
                    if(tempData[index]['isNew']){
                        const tempObject = {
                            "baseFee": parseFloat(baseFee).toFixed(2),
                            "cashAllowed": cashAllowed,
                            "courseFeeYearId": props.setupDatesId,
                            "courseYearId": props.courseYearId,
                            "dueDate": dueDate,
                            "isDeleted": false,
                            "itemTypeId": itemTypeId,
                            "status": status,
                            "tax": taxAmount,
                            "totalAmount": totalAmount
                        }
                        // console.log('temp data--------------->',tempObject);
                        let flag = PostData(masterServiceBaseUrl+'/setupInstalments/createSetupInstalments',tempObject,'post');
                        if(props.isSaveVisible && flag){
                            tempData[index]['isNew'] = false;
                            setBaseFee(null);setDueDate(null);setTaxAmount(null);setTotalAmount(null);
                            setItemType(null);setItemDescription(null);setCashAllowed(true);
                            setStatus('ACTIVE');
                        }
                    }else if(tempData[index]['isEdit']){
                        const tempObject = {
                            "baseFee": parseFloat(baseFee).toFixed(2),
                            "cashAllowed": cashAllowed,
                            "courseFeeYearId": props.setupDatesId,
                            "courseYearId": props.courseYearId,
                            "dueDate": moment(dueDate).format('YYYY-MM-DD'),
                            "isDeleted": false,
                            "itemTypeId": itemTypeId,
                            "status": status,
                            "tax": taxAmount,
                            "totalAmount": totalAmount
                        }
                        let id = tempData[index]['id'];
                        // console.log('object====>',tempObject);
                        let flag = PostData(masterServiceBaseUrl+`/setupInstalments/updateSetupInstalments/${id}`,tempObject,'put')
                        if(props.isSaveVisible && flag){
                            tempData[index]['isEdit'] = false;
                            setBaseFee(null);setDueDate(null);setTaxAmount(null);setTotalAmount(null);
                            setItemType(null);setItemDescription(null);setCashAllowed(true);
                            setStatus('ACTIVE');
                        }
                    }
                }

            }
        }catch(e){
            console.log('Error in save Row------->',e);
        }

    }
    const PostData =(url,dataObject,type)=>{
        putpost(url,(data)=>{
            successToast(data.message);
            props.fetchSetupInstallments(masterServiceBaseUrl+`/setupInstalments/getByCourseFeeYearIdAndCourseYearId?courseFeeYearId=${props.setupDatesId}&courseYearId=${props.courseYearId}`);
            props.setIsSaveVisible(false)
            return true;
        },(data)=>{
            failureToast(data.message);
            // setFlag(false)
            return false;
        },dataObject,type)
    }
    const TDRow =(props)=>{
       let tempItemtype;
       let tempItemDescription;
       if(props.itemTypeData && props.itemTypeData.length > 0){
           props.itemTypeData.map((item)=>{
               if(item['id'] === props.itemTypeId){
                   tempItemtype  = item.itemTypeId;
                   tempItemDescription = item.description;
               }
           })
       }
        return(
            <tr>
                <td className="text-center p-2 mx-1"> {props.year}</td>
                <td className="text-center p-2 mx-1">{moment(props.data.dueDate).format('DD-MM-YYYY')}</td>
                <td className="text-center p-2 mx-1">{tempItemtype}</td>
                <td className="text-center p-2 mx-1">{tempItemDescription}</td>
                <td className="text-center p-2 mx-1">{props.data.baseFee}</td>
                <td className="text-center p-2 mx-1">{props.data.tax}</td>
                <td className="text-center p-2 mx-1">{props.data.totalAmount}</td>
                <td className="text-center p-2 mx-1">
                    {props.data.cashAllowed ? <i className="fas fa-check-square"></i> :<BsFillXSquareFill/> }
                </td>
                <td className="text-center p-2 mx-1">
                    {props.data.status === 'ACTIVE' ? <Button color="success" size={'sm'} disabled={true} >Active</Button> : <Button color="danger" size={'sm'} disabled={true} >Inactive</Button>}
                </td>
                <td className="text-center p-2 mx-1">{moment(props.data.updatedOn).format('DD-MM-YYYY')}</td>
                <td className="text-center p-2 mx-1">
                    <Button color="info" size="sm" type="button" className={" mx-1"}
                            onClick={()=>{editRow(props.index)}}
                    ><i className="fas fa-edit" /></Button>
                    <Button color="info" size="sm" type="button" className={" mx-1"}
                        onClick={()=>deleteRow(props.index)}
                    ><i className="fas fa-trash" /></Button>
                </td>
            </tr>
        )
    }




    useEffect(()=>{
        // console.log('Base fee---->',baseFee)
        if(taxPercent) {
            // console.log('Tax Percent------->',taxPercent);
            let tempTaxAmount = (parseFloat(baseFee) * (taxPercent / 100))
            setTaxAmount(tempTaxAmount.toFixed(2));
        }
    },[baseFee])


    useEffect(()=>{
        if(isEdit){
            let tempTotalAmount = parseFloat(baseFee) + parseFloat(taxAmount);
            setTotalAmount(tempTotalAmount.toFixed(2));
        }
    },[taxAmount])

    useEffect(()=>{
        if(baseFee){
            if(isEdit){
                // console.log('Tax percent------------------>',taxPercent);
                setTaxAmount(parseFloat(baseFee)*(taxPercent/100))
            }
        }
    },[taxPercent])


    useEffect(()=>{
        try{
            setTempState(selectedItemType)
            if(props.itemTypeData){
                props.itemTypeData.map((item)=>{
                    if(item.itemTypeId === selectedItemType){
                        setItemTypeId(item.id);
                        setItemDescription(item.description)
                        setTaxPercent(parseFloat(item.taxPercent))
                    }
                })
            }
        }catch(e){
            console.log('Error in useEffect when filter itemTypeId,description and tax percent.')
        }

    },[selectedItemType])
    const [tempState,setTempState] = useState();

    return(
        <Card style={{margin:'15px 20px'}}>
            <CardBody style={{paddingBottom:0}}>
                <Button disabled={props.isSaveVisible} color="info" size="sm" type="button"  className={"floatRight mx-1"+(props.isSaveVisible ?" btn-dark":null)} onClick={newRow}>Add Item Type</Button><br/><br/>
            </CardBody>
            <div id="questionTable" className="table-responsive" >
                <Table className="align-items-center table-flush ">
                    <SetupInstallmentsHeader  />
                    <tbody className="list" key={'setupInstallmentsTable'}>
                    {
                        props.setupInstallmentsData &&
                        props.setupInstallmentsData.map((item,index)=>
                            (item['isNew'] || item['isEdit']) ?
                                (<tr key={item['id']+'-key'}>
                                    <td className="text-center p-2 mx-1">{props.year}</td>
                                     <td className="text-center p-2 mx-1">
                                        <CustomDatePicker 
                                            value={dueDate ? moment(dueDate).toDate() : null}
                                            minDate={new Date(props.setupDatesData.startDate && props.setupDatesData.startDate.split('-').reverse().join('/'))}
                                            maxDate={new Date(props.setupDatesData.endDate && props.setupDatesData.endDate.split('-').reverse().join('/'))}
                                            placeholder="Example:01-01-2020"
                                            handler={(index,date,keyName) => setDueDate(date)}
                                            index={index}
                                            rangeFrom={1950}
                                            rangeTo={2099}
                                            keyName='courseStartDate'
                                        />
                                         {/* <Input  type={'date'} className="tdInput"
                                                 value={dueDate?dueDate:''}
                                                 min={props.setupDatesData.startDate && props.setupDatesData.startDate.split('-').reverse().join('-')}
                                                 max={props.setupDatesData.endDate && props.setupDatesData.endDate.split('-').reverse().join('-')}
                                                 onChange={(e)=>{
                                                     console.log('Due Date------------------>',e.target.value)
                                                     setDueDate((e.target.value));
                                                 }}
                                         /> */}
                                     </td>
                                     <td className="text-center p-2 mx-1" width="100px">
                                         <Select2
                                             value={tempState}
                                             // defaultValue={selectedItemType}
                                             data={props.itemTypeDropDown}
                                             onBlur={(e)=>{
                                                //  console.log('In Input',e)
                                             }}
                                                  onChange={(e)=>{
                                                      setTempState(e.target.value)
                                                      setIsEdit(true)
                                                      if(e.target.value)
                                                      setSelectedItemType(e.target.value)
                                                      if(props.itemTypeData){
                                                      props.itemTypeData.map((item)=>{
                                                          if(item.itemTypeId === e.target.value){
                                                              setItemTypeId(item.id);
                                                              setItemDescription(item.description)
                                                              setTaxPercent(parseFloat(item.taxPercent))
                                                          }
                                                      })}
                                                  }}
                                         />
                                     </td>
                                     <td className="text-center p-2 mx-1">
                                         <Input  type={'text'} className="tdInput" name={'description'} disabled value={itemDescription?itemDescription:''}/>
                                     </td>
                                     <td className="text-center p-2 mx-1">
                                         <Input type={'number'} className={'tdInput'} name={'baseFees'}
                                                value={baseFee?baseFee:''}
                                                onChange={(e)=>{
                                                    setIsEdit(true);
                                                    if(e.target.value.length>12){setBaseFee(baseFee)}else{setBaseFee(e.target.value)}}}
                                         />
                                     </td>
                                     <td className="text-center p-2 mx-1" width="10%">
                                         <Input type={'number'}
                                                className={'tdInput'}
                                                name={'tax'}
                                                value={taxAmount}
                                                onChange={(e)=>{

                                                    setIsEdit(true)
                                                    setTaxAmount(parseFloat(e.target.value));
                                                }}
                                         />
                                     </td>
                                     <td className="text-center p-2 mx-1">
                                         <Input type={'number'}
                                                disabled
                                                className={'tdInput'}
                                                name={'totalAmount'}
                                                value={ totalAmount ? parseFloat(totalAmount):''}
                                         />
                                     </td>
                                     <td className="text-center p-2 mx-1">
                                         <input style={{height:'20px',width:'20px'}}
                                                type="checkbox"
                                                checked={cashAllowed}
                                                onChange={(e)=> setCashAllowed(!cashAllowed)}
                                         />
                                     </td>
                                     <td className="text-center p-2 mx-1">
                                         <label className="custom-toggle mx-auto ml-2">
                                             <input
                                                 checked={status === 'ACTIVE'}
                                                 type="checkbox"
                                                 id={'status'}
                                                 onChange={(e) => {
                                                     let n = "ACTIVE";
                                                     if (!e.target.checked) {
                                                         n = "INACTIVE"
                                                     }
                                                    setStatus(n)
                                             }} />
                                             <span style={{width:"72px"}} className="custom-toggle-slider rounded-circle activeToggle" data-label-off="Inactive" data-label-on="Active" />
                                         </label>
                                     </td>
                                     <td className="text-center p-2 mx-1" width="6%">
                                         <Input maxLength={50}
                                                value={moment(item['updatedOn']).format('DD-MM-YYYY')}
                                                className="customInput" disabled
                                         />
                                     </td>
                                     <td>
                                         <Button color="info" size="sm" type="button" className="mx-1"
                                                 onClick={()=>saveRow(index)}
                                         >
                                             Save
                                         </Button>
                                         <Button color="danger" size="sm" type="button"
                                                 onClick={()=>{cancelRow(index)}}
                                         >
                                             <i className="fas fa-times" />
                                         </Button>
                                     </td>
                                </tr>
                                )
                                :<TDRow key={'setupInstallmentsRow-'+index} data={item} itemTypeId={item['itemTypeId']} year={props.year} setupInstallmentsData={props.setupInstallmentsData}  index={index} itemTypeData={props.itemTypeData} />
                        )
                    }
                    </tbody>
                </Table>
            </div>
            {/*</CardBody>*/}
        </Card>
    )
}
export default SetupInstallmentsTable;