import { toast } from 'react-toastify';
import { putpost2, getAPI, putpost, uploadpost, deleteAPI, validateToken } from './utils';

/* --------------------------------- BASE_URLS_FOR_DEV_ENVIRONMENTS --------------------------*/
const baseUrl=process.env.REACT_APP_BASE_URL;
const masterServiceBaseUrl = process.env.REACT_APP_MASTER_SERVICE;
const enrollmentServiceBaseUrl = process.env.REACT_APP_ENROLLEMENT_SERVICE;
const concessionServiceBaseUrl = process.env.REACT_APP_CONCESSION_SERVICE;
const sfServiceBaseUrl = process.env.REACT_APP_SF_SERVICE;
const studentServiceBaseUrl = process.env.REACT_APP_STUDENT_SERVICE;
const adminServiceBaseUrl = process.env.REACT_APP_ADMIN_SERVICE;
const examControllerServiceBaseUrl = process.env.REACT_APP_EXAM_SERVICE;
const pricingServiceBaseUrl = process.env.REACT_APP_PRICING_SERVICE;
const documentServiceBaseUrl = process.env.REACT_APP_DOCUEMENT_SERVICE;
const knowYourRoleServiceUrl = process.env.REACT_APP_KYR_SERVICE;
const authServiceUrl = process.env.REACT_APP_AUTH_SERVICE;
const offlineAttendanceServiceUrl = process.env.REACT_APP_OFFLINE_ATTENDANCE_SERVICES;
const timetableServiceUrl = process.env.REACT_APP_TIMETABLE_SERVICES;

const templateBaseurl = process.env.REACT_APP_TEMPLATE_SERVICES;
const templateBaseurl2 = process.env.REACT_APP_TEMPLATE_NOTIFICATION_SERVICES;

const batchServiceBaseUrl = process.env.REACT_APP_BATCH_SERVICE;
const testScheduleServiceBaseUrl = process.env.REACT_APP_TEST_SCHEDULE_SERVICE;
const reportServiceBaseUrl=process.env.REACT_APP_REPORT_SERVICES;

const nodePdfServiceBaseUrl = process.env.REACT_APP_NODE_PDF_SERVICES;
const attendanceServiceUrl = process.env.REACT_APP_ATTENDANCE_SERVICES;
const attendanceAuthToken = process.env.REACT_APP_ATTENDANCE_AUTH_TOKEN;
/* --------------------------------- BASE_URLS_FOR_QA_ENVIRONMENTS --------------------------*/

// const SERVER_1_QA_BASE_URL = process.env.REACT_APP_QA_BASE_UR_SERVICE_1;
// const SERVER_2_QA_BASE_URL = process.env.REACT_APP_QA_BASE_URL_SERVICE_2;

// const masterServiceBaseUrl = `${SERVER_1_QA_BASE_URL}:8586/api/masterServices`;
// const enrollmentServiceBaseUrl = `${SERVER_2_QA_BASE_URL}:8587/api/enrollmentService`;
// const concessionServiceBaseUrl = `${SERVER_1_QA_BASE_URL}:8589/api/concessionService`;
// const sfServiceBaseUrl = `${SERVER_1_QA_BASE_URL}:8590/sf`;
// const studentServiceBaseUrl = `${SERVER_1_QA_BASE_URL}:8591/api/studentService`;
// const adminServiceBaseUrl = `${SERVER_1_QA_BASE_URL}:8594/api/AdminService`;
// const examControllerServiceBaseUrl = `${SERVER_2_QA_BASE_URL}:8593/api/examService`;
// const pricingServiceBaseUrl = `${SERVER_2_QA_BASE_URL}:8592/api/aesl/pricingService`;
// const documentServiceBaseUrl = `${SERVER_2_QA_BASE_URL}:8596/api/documentService`;

// const baseUrlQa = process.env.REACT_APP_QA_BASE_URL_PUBLIC;
// const masterServiceBaseUrl = `${baseUrlQa}:8586/api/masterServices`;
// const enrollmentServiceBaseUrl = `${baseUrlQa}:8587/api/enrollmentService`;
// const concessionServiceBaseUrl = `${baseUrlQa}:8589/api/concessionService`;
// const sfServiceBaseUrl = `${baseUrlQa}:8590/sf`;
// const studentServiceBaseUrl = `${baseUrlQa}:8591/api/studentService`;
// const adminServiceBaseUrl = `${baseUrlQa}:8594/api/AdminService`;
// const examControllerServiceBaseUrl = `${baseUrlQa}:8593/api/examService`;
// const pricingServiceBaseUrl = `${baseUrlQa}:8592/api/aesl/pricingService`;
// const documentServiceBaseUrl = `${SERVER_2_QA_BASE_URL}:8596/api/documentManagement`;

const successToast = msg => {
  toast.info(msg, {
    position: 'bottom-center',
    autoClose: 4500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
const failureToast = msg => {
  toast.warn(msg, {
    position: 'bottom-center',
    autoClose: 4500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export {
  putpost,
  deleteAPI,
  successToast,
  failureToast,
  getAPI,
  validateToken,
  masterServiceBaseUrl,
  putpost2,
  enrollmentServiceBaseUrl,
  concessionServiceBaseUrl,
  sfServiceBaseUrl,
  examControllerServiceBaseUrl,
  studentServiceBaseUrl,
  adminServiceBaseUrl,
  pricingServiceBaseUrl,
  uploadpost,
  documentServiceBaseUrl,
  knowYourRoleServiceUrl,
  offlineAttendanceServiceUrl,
  authServiceUrl,
  templateBaseurl,
  templateBaseurl2,
  batchServiceBaseUrl,
  testScheduleServiceBaseUrl,
  timetableServiceUrl,
  reportServiceBaseUrl,
  baseUrl,
  nodePdfServiceBaseUrl,
  attendanceServiceUrl,
  attendanceAuthToken
};
