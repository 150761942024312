import React, { useEffect, useState } from 'react';
import { IoEyeOutline } from 'react-icons/io5';
import CustomTooltip from '../../../common/customTooltip/CustomTooltip';
import { fetchAllPromisedData } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../../common/constant';

const Details = ({ data }) => {
  const { primaryBatchId, secondaryBatchId } = data || {};

  const [batchName, setBatchName] = useState(''); // is used for single issuance
  const [apiLoader, setApiLoader] = useState(false);
  const fetchBatchName = async () => {
    if (!primaryBatchId && !secondaryBatchId) {
      setBatchName('-');
      return;
    }
    setApiLoader(true);
    const res = await fetchAllPromisedData(
      `${endpoint.viewEditBatch.getBatchById}/${
        primaryBatchId || secondaryBatchId
      }`
    );
    setApiLoader(false);
    setBatchName(res?.batchName || '-');
  };

  useEffect(() => {
    fetchBatchName();
  }, []);

  return (
    <div className='p-2 regular-large'>Batch: {apiLoader ? 'Loading...' : batchName}</div>
  )
};
const BatchNamePopover = ({ rowData }) => {
  return (
    <CustomTooltip
      placement='bottom-start'
      title={
        // <>
          () => <Details data={rowData} />
        // </>
      }
    >
      <div>
        <IoEyeOutline className='pointer' onClick={onclick} size={18} />
      </div>
    </CustomTooltip>
  );
};

export default BatchNamePopover;
