import React from 'react'


const chipStyles = {
  background: 'rgba(50, 147, 111, 0.16)',
  borderRadius: '8px',
  padding: "5px 11px",
  fontSize: '14px',
  fontWeight: '400',
  // display:'inline-block',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const CustomChip = ({ bgColor='#F5F5F5', textColor='#262626', text, style, className='', children, ...restProps }) => {
  return (
    <div 
      className={className}
      style={{
        ...chipStyles,
        color: textColor,
        background: bgColor,
        ...style
      }}
      {...restProps}
    >
      {text ? text : children}
    </div>
  )
}

export default CustomChip