import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import BuildingTable from "./buildingTable";
import CustomLoader from "../../../common/commonComponents/Loader/loader";
import { masterServiceBaseUrl } from "services/http";
import { fetchDetails } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { GetPagination } from "../../../common/commonComponents/pagination/pagination";
import { updateData } from '../../../../../../redux/globalData/globalDataAction';



const res = [
  {
    id: 4,
    buildingName: "build 1",
    address: "street one",
    cityId: 5,
    stateId: 5,
    countryId: 5,
    pincodeId: 5,
    status: "ACTIVE",
  },
];

const Building = (props) => {
  const dispatch = useDispatch();
  //  console.log({props});
  const [classArray, setclassArray] = useState([]);
  const [apiloader, setapiloader] = useState(false);
  const [state, setstate] = useState([]);
  // const [serverData, setServerData] = useState([]);
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });

  const [pincodeData, setPincodeData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [countryData, setCountryData] = useState([]);

  const pageSize = 10;

  // const fetchClasses = async (url) => {
  //   setstate(props.data);
  //   setServerData(props.data);
  //   setPagination(props.data);
  // };

  // const setPagination = (paramsdata) => {
  //   let n = pagination;
  //   if (paramsdata.length > pageSize) {
  //     n["nextPage"] = n.currentPage + 1;
  //   }
  //   if (n.currentPage > 1) {
  //     n.previousPage = n.currentPage - 1;
  //   }
  //   n["totalPage"] = Math.floor(paramsdata.length / pageSize);
  //   if (paramsdata.length % pageSize != 0) {
  //     n["totalPage"] += 1;
  //   }
  //   setpagination(n);
  //   setclassArray(
  //     paramsdata.slice(
  //       pagination.currentPage * pageSize - pageSize,
  //       pagination.currentPage * pageSize
  //     ) || []
  //   );
  //   setapiloader(false);
  // };

  // useEffect(() => {
  //   fetchClasses();
  // }, [props.data]);

  useEffect(() => {
    fetchDetails(
      `${masterServiceBaseUrl}/pincode/getAllActivePincode`,
      setPincodeData,
      "pincode",
      "pincode"
    );
    fetchDetails(
      `${masterServiceBaseUrl}/state/getAllState`,
      setStateData,
      "state",
      "reactSelect"
    );
    fetchDetails(
      `${masterServiceBaseUrl}/city/getAllCity`,
      setCityData,
      "city",
      "reactSelect"
    );
    fetchDetails(
      `${masterServiceBaseUrl}/country/getAllCountry`,
      setCountryData,
      "country",
      "reactSelect"
    );
  }, []);

  const setHeaderDataForFloor = (data) => {
    dispatch(
      updateData({
        // "Business Group" : props.headers?.businessGroup?.label,
        // "Company Code" : props.headers?.companyCode?.label,
        // "Zone" : props.headers?.zone?.label,
        // "Region" : props.headers?.region?.label,
        // "Business Area" : props.headers?.businessArea?.label,
        // businessAreaId: props.headers?.businessArea,
        // // businessGroupId: props.headers?.businessGroup,
        // // companyCodeId: props.headers?.companyCode,
        // regionId: props.headers?.region,
        // zoneId: props.headers?.zone,
        ...data
      }))
  }


  return (
    <>
      {apiloader ? (
        <div
          className="mx-auto text-center py-5 my-5"
          style={{ height: "100vh" }}
        >
          <CustomLoader apiLoader={apiloader} />
        </div>
      ) : (
        <>
          <BuildingTable
            // fetchClasses={props.searchBuilding}
            // setclassArray={setclassArray}
            classArray={props?.data}
            //isSaveVisible={isSaveVisible}
            setisSaveVisible={setisSaveVisible}
            // serverData={serverData}
            pincodeData={pincodeData}
            stateData={stateData}
            cityData={cityData}
            countryData={countryData}
            regionData={props.regionData}
            businessData={props.businessData}
            headers={props.headers}
            // searchBuilding={props.searchBuilding}
            setHeaderDataForFloor={setHeaderDataForFloor}
            fetchBuildingMaster={props?.fetchBuildingMaster}
            getAllApiLoader={props?.getAllApiLoader}
            totalPages={props?.totalPages}
            page={props?.page}
            pageChangeHandler={props?.pageChangeHandler}
          />
          {/* {pagination.totalPage > 1 ? (
            <GetPagination
              isSaveVisible={isSaveVisible}
              // fetchClasses={props.searchBuilding}
              setclassArray={setclassArray}
              pagination={pagination}
              setpagination={setpagination}
              pageSize={pageSize}
              state={state}
            />
          ) : null} */}
        </>
      )}
    </>
  );
};

export default Building;
