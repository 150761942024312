import React, {Fragment, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Container, Form, Input, Row, Table} from "reactstrap";
import CommonInput from "./formFeilds/input/commonInput";
import Select from "react-select";
import {MasterHeaderAuto} from "./commonComponents/masterHeader/masterHeader";
// import {BsClipboard} from "react-icons/all";
import {message} from 'antd'
import MasterServicehelper from 'helpers/master_service';
const CommonComp = (props)=>{
    const [variable,setVariable] = useState('')
    const [variableForInputText,setVariableForInputText] = useState('');
    const [variableForInputText2,setVariableForInputText2] = useState('');
    const [variableForInputText3,setVariableForInputText3] = useState('');
    const [variableForInputNumber,setVariableForInputNumber] = useState('');
    const [variableForSelect2,setVariableForSelect2] = useState(null);
    const [variableForSelect,setVariableForSelect] = useState(null);
    let dataForSelect2 = [{id:'1',text:'1'},{id:'2',text:'2'},];
    let dataForSelect = [
        {value:'test1', label: 'test1',},
        {value:'test2', label: 'test2',}
    ];

    const headerList =[
        {name:"Input Type - Text"},
        {name:"Input Type - Number"},
        {name:"Input Type - Select2"},
        {name:"Input Type - Select"},
    ];
    // const [btntext,setBtntext] = useState('Copy')
    const [isButtonDisabled,setIsButtonDisabled] = useState(false);
    const {getMasterData} = MasterServicehelper;
    const getMasterDataUsingCommonFunctions =()=>{
        // console.log('Method is called');
        const processAttributes = {
            name : 'process_tag',
            type : 'select'
        }
        const academic_institution = {
            name : 'academic_institution',
            type : 'object'
        }


        Promise.all([getMasterData(academic_institution),]).then(allData=>{
            console.log({allData})
        })
    }

    return  (
        // CommonInput
        // type :- reactSelect , number , select , date
        // label :- label of the input field
        // placeHolder :- placeholder of the field
        // defaultValue :- default value for the field
        // onChange :- call back function
        // mandatory :- true if field is mandatory , by default false
        // isDisabled :- true if field need to be disabled
        // restrictSpecialCharacter :- true if special characters need to be restric
        // data :- dropdown value for reactSelect
        // maxLength :- for maximum length of the field
        // forTable :- Boolean (True if input required for table ) By default false


        <Container fluid={true} className="p-4">
            <Card>
                <CardBody>
                    <Form>
                        <Row>
                            <Col>
                                <Button color="info" size="sm" className="floatRight mx-1" disabled={isButtonDisabled}
                                        onClick={()=>{getMasterDataUsingCommonFunctions()}}
                                >
                                    Test
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </Container>
    );
};

export default CommonComp;