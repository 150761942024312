import React from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import '../style.scss'
import moment from 'moment'

export const checkDisabledModuleList = [
  'ISSUANCE',
  'RETURN',
  'HISTORY',
  'BRANCH_CONSUMPTION',
  'BRANCH_CONSUMPTION_RETURN',
];

export const checkDisabledModule = (module, businessAreas, disabledModulesDetails) => {
  const moduleDetails = disabledModulesDetails?.[module] || [];
  const sgBA = businessAreas || [];
  const isDisabled = Boolean(moduleDetails?.length);
  const buFound = moduleDetails?.find(item => item?.restrictedBU?.toLowerCase() === "all") ? true : sgBA?.some((item) => moduleDetails.find(obj => obj?.restrictedBU?.toLowerCase() === item?.label?.toLowerCase()));

  return isDisabled && buFound;
};

export const showDisabledModuleAlert = (disabledModulesDetails) => {
  return Object.values(disabledModulesDetails).some(item => item)
}
const DisabledModulesAlert = ({ disabledModulesDetails }) => {

  const dateString = disabledModulesDetails?.toDate ? moment(disabledModulesDetails?.toDate).format('DD-MMM-YYYY') : '';

  return (
    <div className='disabled-modules-alert'>
      <AiOutlineInfoCircle size={20} />
      <div>Few modules are frozen due to ongoing financial activities!</div>
    </div>
  )
}

export default DisabledModulesAlert