import getProcessType from './masters/process_type/index';
import {endpoint} from "../../views/pages/manage/common/constant";
import {getAPI} from "../../services/utils";
import {failureToast} from "../../views/pages/manage/common/utils/methods/toasterFunctions/function";
import {URLS} from "./properties/requestUrls";




const getRequestUrl = ({name, isAllActive=false})=>{
    try{
        const masterUrls = URLS[name];
        if(isAllActive){
            return masterUrls['getAllActive'] || null;
        }
        return masterUrls['getAll'] || null;
    }catch(error){
        console.error({error})
    }
}

const keysForMasters = {
    "process_tag" : {
        "display_value" : "tagTypeDispValue",
        "id" : "id"
    },
    "academic_institution" : {
        "display_value" : "academicInstitutionDispValue",
        "id" : "id"
    }
}

const getFormattedData = (data=[],type = 'raw',name=null)=>{
    if(type === 'select'){
        if(data && data.length){
            let formattedArray = [];
            data.forEach((item)=>{
                let obj = {
                    label: item[keysForMasters[name]["display_value"]],
                    value: item[keysForMasters[name]["id"]],
                    ...item,
                }
                formattedArray.push(obj);
            });
            return formattedArray;
        }
    } else if( type === 'object'){
        let masterData ={}
        data.forEach((item,index)=>{
            masterData = {
                ...masterData,
                [`${item.id}`] : item[keysForMasters[name]["display_value"]]
            }
        });
        return masterData;
    } else {
        return data;
    }
}

const getMasterData = ({name=null,type='raw'})=>{
    try{
        return new Promise(async(resolve,reject)=>{
            let result = {
                error : false,
                data : []
            };
            if(name){
                let requestUrl = getRequestUrl({name})
                await getAPI(
                    requestUrl,
                    (data) => {
                        result['data'] = getFormattedData(data.data,type,name)
                        resolve(result);
                    },
                    (data) => {
                        reject({error: true, message: data?.message})
                        failureToast(data["message"]);
                    }
                );
            }else{
                console.error({error:true,message:'Master name is undefined'})
            }
        })
    }catch(e){
        console.error({e})
    }
}




export default {
    getProcessType : getProcessType,
    getMasterData : getMasterData
}