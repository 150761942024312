import React from 'react'
import { ReactComponent as ActiveControl } from "../../../../../../assets/img/svg/ActiveControl.svg";
import { ReactComponent as InactiveControl } from "../../../../../../assets/img/svg/InactiveControl.svg";
import { endpoint } from "../../../common/constant";
import AButton from "../../../common/form-fields-mui/AButton";
import { fetchAllPromisedData } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { ReactComponent as DeleteIcon } from "../../../../../../assets/img/svg/Delete.svg";

export const newRow = {
    financeInstitution: '',
    businessArea: { label: '', value: '' },
    merchantId: '',
    key: '',
    salt: '',
    status: 'ACTIVE'
};

export const statusDropdown = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Inactive', value: 'INACTIVE' }
]

export const StatusCellComponent = ({ value, handleChange = () => { } }) => {
    return (
        value === 'ACTIVE'
            ? <ActiveControl onClick={() => handleChange('INACTIVE')} />
            : <InactiveControl onClick={() => handleChange('ACTIVE')} />
    )

}

export const ActionsCellComponent = ({ mode, handleEditClick, handleSaveClick, handleCancelClick, params , isSaveDisabled}) => {
    return (
        mode === 'view'
            ? <AButton
                variant='primary_filled'
                onClick={() => handleEditClick(params)}
                size='xs'
            >
                Edit
            </AButton>
            : <>
                <AButton
                    variant='primary_filled'
                    size='xs'
                    onClick={handleSaveClick}
                    disabled={isSaveDisabled}
                >
                    Save
                </AButton>

                <DeleteIcon
                    className='ml-3 mr-1'
                    onClick={() => handleCancelClick(params)}
                >
                    Cancel
                </DeleteIcon>
            </>
    )

}

export const getAllActiveBusinessArea = async () => {
    try {
        const resp = await fetchAllPromisedData(endpoint.businessArea.getAllActive, true);
        if (resp?.code === 200) {
            return resp?.data?.map(bu => ({ ...bu, label: bu?.businessAreaDispValue, value: bu?.id }))
        }
        return [];
    } catch (err) {
        console.log(err);
    }
}