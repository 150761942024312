import React, { useEffect, useState } from "react";
import AAutoComplete from "./../../../common/form-fields-mui/AAutoComplete";
import CustomDatePickerV2 from "./../../../common/commonComponents/CustomDatePicker-V2/index";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import AButton from "../../../common/form-fields-mui/AButton";
import { ReactComponent as ActiveControl } from "../../../../../../assets/img/svg/ActiveControl.svg";
import { ReactComponent as Upload } from "../../../../../../assets/img/svg/upload.svg";
import { ReactComponent as InactiveControl } from "../../../../../../assets/img/svg/InactiveControl.svg";
import { useFetchSubjectLectureDropdown } from "../hooks";
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import {
  getDuplicateErrorMessage,
  DUPLICATE_LECTURE_ERROR_MESSAGE,
  getUniqueFaculty,
  getFacultyDropdown,
  maxDate31Days,
  yesNoDropdown,
} from "../helper";
import SelectLecturePopover from "./selectLecture/SelectLecturePopover";
import UploadDocumentModal from "./UploadDocumentModal";
import { classTypeDropdown, getSubjectFaculty } from "./AddLectureModalForm";
import { getDayShortName } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
const timepickerStyle = {
  "& .MuiInputBase-root": {
    height: "40px",
    // borderRadius: '8px'
  },
  "& .MuiInputBase-input": {
    padding: "7px 8px",
    fontSize: "12px",
  },
  "& .MuiInputAdornment-root": {
    display: "none",
  },
};

const selectStyles = {
  control: {
    borderRadius: "4px",
    height: "40px",
  },
};

export const saveLectureValidator = (form) => {
  if (form.startTime >= form.endTime) {
    failureToast("Session start time should be less than end time");
    return false;
  }
  if (form?.lecture === "") {
    failureToast("Please select a lecture");
    return false;
  }
  if (form?.faculty === "") {
    failureToast("Please select a faculty");
    return false;
  }
  return true;
};

export const savePTMValidator = (form) => {
  if (!form?.date) {
    failureToast("Please select date");
    return false;
  }

  if (form.startTime >= form.endTime) {
    failureToast("Session start time should be less than end time");
    return false;
  }

  if (!form?.room) {
    failureToast("Please select room");
    return false;
  }

  return true;
};

const buttonStyles = {
  height: "28px",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "16px",
  borderRadius: "4px",
  width: "60px",
};

export const subjectDropdown = (subjects) => {
  if (Array?.isArray(subjects)) {
    return subjects?.map((subject) => ({
      label: subject["subjectName"],
      value: subject["courseId"],
    }));
  }
  return [];
};

const getSubjectMapping = (subjects, subject) => {
  if (subject && subjects) {
    let selectedSubject = subjects.filter((item) => {
      if (item["subjectName"].toLowerCase() === subject.toLowerCase()) {
        return item;
      }
    });
    let finedSubject = subjectDropdown(selectedSubject);
    if (finedSubject?.length > 0) return finedSubject[0];
  }
  return [];
};

const getRoom = (formData, rowData) => {
  if (rowData?.roomName) {
    return { value: rowData?.roomId, label: rowData?.roomName };
  }

  return formData?.room || { value: rowData?.roomId, label: rowData?.roomName };
};

const EditLectureForm = ({
  rowIndex,
  rowData,
  roomDropdown = [],
  rowChangeHandler,
  selectedFaculty,
  ...props
}) => {
  const subjectsDropdown = subjectDropdown(props?.planSubjects);
  const allFacultyDropdown = getUniqueFaculty(
    getFacultyDropdown(props?.subjectFacultyDropdown)
  );

  const [isDisabledMsId, setDisabledMsId] = useState(false);
  const [isDisabledOther, setDisabledOther] = useState(false);
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);

  const [editForm, setEditForm] = useState({
    ...rowData,
    classType: { label: rowData?.classType, value: rowData?.classType },
    subject: getSubjectMapping(props?.planSubjects, rowData?.subject),
    room: getRoom(props?.formData, rowData),
    lecture: { value: rowData?.lectureId, label: rowData?.lectureName },
    faculty: { value: rowData?.facultyId, label: rowData?.facultyName },
    msTeam: rowData?.msTeam,
  });
  const lecturesDropdown = useFetchSubjectLectureDropdown(
    props?.formData?.plan?.value,
    rowData?.duration || props?.formData?.duration?.value,
    editForm?.subject?.label
  );

  const editFormHandler = (value, key) => {
    if (props?.mode === "EDIT" && !rowData.isNew) {
      if (key === "msTeam") {
        let last2Hour = moment(rowData?.lectureStartTime).subtract(
          120,
          "minutes"
        );
        if (new Date(last2Hour) < new Date()) {
          // return failureToast("MS Teams status cannot be changed 2 hours before the lecture start time")
        }
        setDisabledOther(true);
      } else {
        setDisabledMsId(true);
      }
    }

    if (key === "subject") {
      setEditForm((prev) => ({ ...prev, [key]: value }));
      setEditForm((prev) => ({ ...prev, ["lecture"]: "" }));
      setEditForm((prev) => ({ ...prev, ["faculty"]: getSubjectFaculty(selectedFaculty, props?.subjectFacultyDropdown[value?.label], value?.label) }));
      // setEditForm((prev) => ({ ...prev, ['faculty']: '' }));
    } else {
      setEditForm((prev) => ({ ...prev, [key]: value }));
    }
  };

  const saveHandler = () => {
    if (!saveLectureValidator(editForm)) return;
    const existingItem = props.checkLectureDuplicated(
      editForm,
      rowData?.index || rowIndex
    );
    if (existingItem) {
      return failureToast(getDuplicateErrorMessage(existingItem.classType));
    }

    props.updateLecture(
      rowIndex,
      {
        ...editForm,
        classType: editForm?.classType?.value,
        subject: editForm?.subject?.label,
        roomName: editForm?.room?.label,
        roomId: editForm?.room?.value,
        lectureId: editForm?.lecture?.value,
        lectureName: editForm?.lecture?.lectureName || editForm?.lecture?.label,
        facultyName: editForm?.faculty?.label,
        facultyId: editForm?.faculty?.value,
        msTeam: editForm?.msTeam,
        isEdit: false,
        lectureCode:  editForm?.lecture?.lectureCode || editForm?.lectureCode,
        lectureStartTime: moment(
          `${moment(editForm.date).format("DD-MM-YYYY")} ${editForm.startTime}`,
          "DD-MM-YYYY hh:mm"
        ).format(),
        isItemEdited: true,
      },
      true
    );
  };

  const cancelHandler = () => {
    props.updateLecture(rowIndex, { ...rowData, isEdit: false });
  };

  const editUploadFileHandler = (e) => {
    setEditForm((prev) => ({ ...prev, documentS3Key: e?.key }));
  };

  const onDocumentDelete = () =>
    setEditForm((prev) => ({ ...prev, documentS3Key: null }));

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div
        className={`${
          props?.isEnabledMsTeamsSync
            ? "timetable-lecture-details-card-row"
            : "timetable-lecture-details-card-row-without-msteams"
        } my-2`}
      >
        <div>
          <CustomDatePickerV2
            placeHolder="Date"
            value={editForm?.date}
            // minDate={moment('01/01/1999', 'DD/MM/YYYY')}
            handler={(index, date) => editFormHandler(date, "date")}
            height="32px"
            style={selectStyles}
            minDate={moment(new Date())}
            maxDate={maxDate31Days()}
            disabled={isDisabledOther}
          />
        </div>
        <div className="regular-bold">{getDayShortName(editForm?.date)}</div>
        <div>
          <DesktopTimePicker
            ampm={false}
            disabled={isDisabledOther}
            value={
              editForm?.startTime ? moment(editForm?.startTime, "HH:mm") : null
            }
            onChange={(value) =>
              editFormHandler(moment(value)?.format("HH:mm"), "startTime")
            }
            sx={timepickerStyle}
          />
        </div>
        <div>
          <DesktopTimePicker
            ampm={false}
            disabled={isDisabledOther}
            value={
              editForm?.endTime ? moment(editForm?.endTime, "HH:mm") : null
            }
            onChange={(value) =>
              editFormHandler(moment(value)?.format("HH:mm"), "endTime")
            }
            sx={timepickerStyle}
          />
        </div>
        <div>
          <AAutoComplete
            placeholder="Class type"
            // isDisabled={
            //  editForm?.classType?.value === "PTM"||editForm?.classType?.value === "Orientation"  || isDisabledOther
            // } // for now classtype dropdown is disabled
            isDisabled={
              true || isDisabledOther
             }
            style={selectStyles}
            items={classTypeDropdown}
            isMulti={false}
            currentValue={editForm?.classType}
            handleChange={(value) => {
              editFormHandler(value, "classType");
              if (value?.value === "PTM" || value?.value === "Orientation") {
                editFormHandler("", "subject");
                editFormHandler("", "lecture");
                editFormHandler("", "faculty");
                editFormHandler("", "msTeam");
              }
            }}
          />
        </div>
        <div>
          <AAutoComplete
            placeholder="Subject"
            style={selectStyles}
            items={subjectsDropdown}
            isMulti={false}
            currentValue={editForm?.subject}
            isDisabled={
              editForm?.classType?.value === "PTM" ||
              editForm?.classType?.value === "Orientation" ||
              isDisabledOther
            }
            handleChange={(value) => editFormHandler(value, "subject")}
          />
        </div>
        <div>
          <SelectLecturePopover
            {...{
              editForm,
              lecturesDropdown,
              editFormHandler,
              isDisabledOther,
              isDisabled:
                editForm?.classType?.value === "PTM" ||
                editForm?.classType?.value === "Orientation",
            }}
          />
        </div>
        <div>
          {!["PTM", "Orientation"].includes(rowData.timeTableClassType) ? (
            <span
              className="regular-bold"
              style={{ color: "#B0B0B0", cursor: "not-allowed" }}
            >
              View syllabus
            </span>
          ) : (
            <span
              className="regular-bold"
              style={{ color: "#B0B0B0", cursor: "not-allowed" }}
            >
              Download
            </span>
          )}
        </div>
        <div>
          <AAutoComplete
            placeholder="Faculty"
            style={selectStyles}
            items={
              props?.subjectFacultyDropdown?.[editForm?.subject?.label] ||
              allFacultyDropdown
            }
            isMulti={false}
            currentValue={editForm?.faculty}
            isDisabled={isDisabledOther}
            handleChange={(value) => editFormHandler(value, "faculty")}
          />
        </div>
        <div>
          <AAutoComplete
            placeholder="Room"
            style={selectStyles}
            items={roomDropdown}
            isMulti={false}
            currentValue={editForm?.room}
            isDisabled={isDisabledOther}
            handleChange={(value) => editFormHandler(value, "room")}
          />
        </div>
        {props?.isEnabledMsTeamsSync && (
          <div>
            <AAutoComplete
              placeholder="MS Team"
              style={selectStyles}
              items={yesNoDropdown}
              isMulti={false}
              currentValue={editForm?.msTeam}
              isDisabled={isDisabledMsId}
              handleChange={(value) => editFormHandler(value, "msTeam")}
            />
          </div>
        )}
        <div>
          <span>
            {editForm?.status.toLowerCase() === "active" ? (
              <ActiveControl
                onClick={
                  isDisabledOther
                    ? () => {}
                    : () => editFormHandler("INACTIVE", "status")
                }
              />
            ) : (
              <InactiveControl
                onClick={
                  isDisabledOther
                    ? () => {}
                    : () => editFormHandler("ACTIVE", "status")
                }
              />
            )}
            <span
              style={{
                fontSize: "14px",
                marginLeft: "4px",
                color: editForm?.status === "ACTIVE" ? "#0F0F0F" : "#757575",
              }}
            >
              {editForm?.status}
            </span>
          </span>
        </div>
        <div className="d-flex">
          {["PTM", "Orientation"].includes(editForm.timeTableClassType) && (
            <span className="mr-2 cursor">
              <Upload onClick={() => setIsOpenUploadModal(true)} />
            </span>
          )}
          <AButton
            className="button_remove_left_margin"
            variant="primary_filled"
            updatedStyle={buttonStyles}
            onClick={saveHandler}
          >
            Save
          </AButton>
          <AButton
            variant="danger_filled"
            updatedStyle={{
              ...buttonStyles,
              width: "40px",
              padding: 0,
              backgroundColor: "#E7685A",
            }}
            onClick={cancelHandler}
          >
            <i className="fas fa-times" style={{ fontSize: "16px" }} />
          </AButton>
        </div>
      </div>
      {isOpenUploadModal && (
        <UploadDocumentModal
          isOpen={isOpenUploadModal}
          setIsOpen={setIsOpenUploadModal}
          type={rowData.classType}
          getS3Url={editUploadFileHandler}
          onDeleteHandler={onDocumentDelete}
        />
      )}
    </LocalizationProvider>
  );
};

export default EditLectureForm;
