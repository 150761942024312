import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from "react-router-dom"
import { ReactComponent as BackArrow } from "../../../../../../assets/img/svg/BackArrow.svg"
import { Card, CardHeader } from 'reactstrap';
import AButton from '../../../common/form-fields-mui/AButton';
import ListComponent from './ListComponent';
import { fetchAllPostPromisedData } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../../common/constant';
import { getAllActiveBusinessArea } from './common';
import { successToast } from '../../../common/utils/methods/toasterFunctions/function';
import FilterForm from './FilterForm';
import APopover from '../../../common/a-popover';

const PaymentGatewaysListing = () => {

    const history = useHistory();

    const ref = useRef();

    const [allActiveBusinessAreas, setAllActiveBusinessAreas] = useState([]);
    const [paymentGatewayData, setPaymentGatewayData] = useState([]);
    const [loading, setLoading] = useState([]);
    const [paginationInfo, setPaginationInfo] = useState({ currentPage: 0, totalRecords: 0 });
    const [filterForm, setFilterForm] = useState({});

    const getPaymentGatewayConfigs = async (filterObj = {}, pageNumber = 0, activeBUs = allActiveBusinessAreas) => {
        try {
            setLoading(true);
            const resp = await fetchAllPostPromisedData(`${endpoint.paymentGatewayConfig.search}?offset=${pageNumber}`, filterObj, 'post');
            if (resp?.code === 200) {
                const updatedData = resp.data.gatewayConfigDTOList?.map(el => ({ ...el, businessArea: activeBUs?.find(bu => bu.value === el.businessArea) }));
                setPaymentGatewayData(updatedData);
                setPaginationInfo({ totalRecords: resp.data.totalRecords, currentPage: resp.data.currentPage })
                ref.current.stopEditing();
                setLoading(false);
            }
        } catch (err) {
            console.log(err)
        }
    }


    const getFilterCount = () => {

        const { businessArea, status, financeInstitution } = filterForm;
        let count = 0;

        if (businessArea?.length) count++;
        if (!!status) count++;
        if (financeInstitution?.length) count++;

        return count;
    }


    const createUpdateHandler = async (rowForm) => {
        try {
            setLoading(true);
            const reqObj = {
                ...rowForm,
                businessArea: rowForm.businessArea?.id
            }
            let url = endpoint.paymentGatewayConfig.create;
            let method = 'post';

            if (!rowForm?.isNew) {
                url = `${endpoint.paymentGatewayConfig.update}/${reqObj?.id}`;
                method = 'put'
            }
            const resp = await fetchAllPostPromisedData(url, reqObj, method);
            if (resp?.code === 200) {
                getPaymentGatewayConfigs();
                successToast('Request completed successfully!!!')
                ref.current.stopEditing();
            }
        } catch (err) {
            console.log(err);
        }
    }


    const pageChangeHandler = (newPage) => {
        applyFilterHanlder(newPage);
    }

    const handleFilterForm = (value, key) => {
        setFilterForm(prev => ({ ...prev, [key]: value }))
    }

    const applyFilterHanlder = (pageNumber) => {
        const { businessArea, status, financeInstitution } = filterForm;
        const filterObj = {
            ...(businessArea && { businessAreaIds: businessArea?.map(bu => bu.value) }),
            ...(status && { status: [status?.value] }),
            ...(financeInstitution && { financeInstitutions: [financeInstitution] })
        }
        setFilterForm(prev => ({ ...prev, appliedFilterCount: getFilterCount() }))

        getPaymentGatewayConfigs(filterObj, pageNumber);
    }

    const removeFilterHandler = () => {
        getPaymentGatewayConfigs();
        setFilterForm({});
    }

    const init = async () => {
        setLoading(true);
        const allActiveBu = await getAllActiveBusinessArea();
        setAllActiveBusinessAreas(allActiveBu);
        getPaymentGatewayConfigs({}, 0, allActiveBu)
    }

    useEffect(() => {
        init();
    }, []);


    return (
        <>
            <div>
                <BackArrow className="mr-3 mb-1" style={{ cursor: 'pointer' }} onClick={() => history.goBack()} />
                <span className="manage-qr-hdr">Payment gateways configuration</span>
            </div>
            <Card className="m-4 p-2 pt-3 mb-2">
                <div className='d-flex justify-content-between mb-2'>
                    <h3>Branch - Payment gateways</h3>

                    <div>
                        <APopover
                            buttonComponent={openPopover =>
                                <AButton
                                    variant="outline"
                                    onClick={openPopover}
                                    size='xs'
                                >
                                    Filter{filterForm?.appliedFilterCount > 0 ? `(${filterForm?.appliedFilterCount})` : ''}
                                </AButton>
                            }
                            menuComponent={closePopup =>
                                <FilterForm
                                    businessAreaDropdown={allActiveBusinessAreas}
                                    form={filterForm}
                                    applyFilterHandler={applyFilterHanlder}
                                    handleFilterForm={handleFilterForm}
                                    closePopup={closePopup}
                                    removeFilterHandler={removeFilterHandler}
                                    filterCount={getFilterCount()}
                                />}
                        />
                        <AButton
                            variant='primary_filled'
                            onClick={() => ref.current.addNewRow()}
                            size='xs'
                        >
                            Add new account
                        </AButton>
                    </div>
                </div>

                <ListComponent
                    rows={paymentGatewayData}
                    ref={ref}
                    businessAreaDropdown={allActiveBusinessAreas}
                    createUpdateHandler={createUpdateHandler}
                    loading={loading}
                    currentPage={paginationInfo.currentPage}
                    totalRecords={paginationInfo.totalRecords}
                    pageChangeHandler={pageChangeHandler}
                />

            </Card>
        </>
    );
}

export default PaymentGatewaysListing;