import React from "react";
import { withRouter } from "react-router-dom";
import { Card, Table, Button } from "reactstrap";
import { MasterHeaderAuto, Rows } from "../../common/commonComponents/masterHeader/masterHeader";
import { getUniqueKey, openInNewTab } from "../../common/utils/methods/commonMethods/utilityMethod";
import {
  masterServiceBaseUrl,
  examControllerServiceBaseUrl,
  failureToast,
  putpost2,
  successToast,
} from "services/http";

const SearchTable = (props) => {
  const {
    onSearch=()=>{},
    data,
    businessAreaData = [],
    courseIdData = [],
    termData = [],
    academicCareerData = [],
  } = props;

  const headerList = [
    { name: "S.No" },
    { name: "Academic Career" },
    { name: "Business Area" },
    { name: "Term" },
    { name: "Course ID" },
    { name: "Status" },
    { name: "Actions" },
  ];

  const getValue = (id, list) => {
    const obj = list && list.find((o) => o.value == id);
    return obj ? obj.label : "";
  };


  const deleteRow = (id,index) => {
      if (window.confirm("Are you sure you want to delete this Exam?")) {
        putpost2(
          examControllerServiceBaseUrl + "/examDateHeader/deleteExamId/" + id,
          (data) => {
            successToast(data.message);    
            onSearch()        
          },
          (data) => {
            failureToast(data.message);
          },
          "Delete"
        );
      }
    
  };


  // const listToRender = [
  //   "sno",
  //   "academicCareerId",
  //   "businessAreaId",
  //   "termId",
  //   "courseId",
  //   "status",
  // ];

  // const itemToBeRenderFromMaster = [
  //   "academicCareerId",
  //   "businessAreaId",
  //   "termId",
  //   "courseId",
  // ];
  // const mastersList = [
  //   [],
  //   academicCareerData,
  //   businessAreaData,
  //   termData,
  //   courseIdData,
  // ];
  // const propertyList = ["", "label", "label", "label", "label"];  

  return (
    <Card className="mt-4">
      <div className="p-4">
        <Table className="align-items-center table-flush">
          <MasterHeaderAuto headerList={headerList} />
          <tbody className="list">
            {data ? (
              data.map((el, index) => {
                return (
                
                  <tr key={getUniqueKey()}>
                    <td
                      key={getUniqueKey() + index}
                      className="text-center  white-breakSpace"
                    >
                      {index + 1}
                    </td>
                    <td
                      key={getUniqueKey() + index}
                      className="text-center  white-breakSpace"
                    >
                      {getValue(el.academicCareerId, academicCareerData)}
                    </td>
                    <td
                      key={getUniqueKey() + index}
                      className="text-center  white-breakSpace"
                    >
                      {getValue(el.businessAreaId, businessAreaData)}
                    </td>
                    <td
                      key={getUniqueKey() + index}
                      className="text-center  white-breakSpace"
                    >
                      {getValue(el.termId, termData)}
                    </td>
                    <td
                      key={getUniqueKey() + index}
                      className="text-center  white-breakSpace"
                    >
                      {getValue(el.courseId, courseIdData)}
                    </td>
                    <td className="text-center  white-breakSpace">
                      {el["status"] === "INACTIVE" ? (
                        <button
                          disabled={true}
                          type="button"
                          className="btn btn-sm btn-warning"
                        >
                          Inactive
                        </button>
                      ) : (
                        <button
                          type="button"
                          disabled={true}
                          className="btn btn-sm btn-success"
                        >
                          Active
                        </button>
                      )}
                    </td>
                    <td className="text-center  white-breakSpace">
                      <Button color="info" size="sm" type="button" onClick={()=>openInNewTab("/view/"+el.id)}>
                        <i className="fas fa-eye" />
                      </Button>
                      <Button color="info" size="sm" type="button" onClick={()=>openInNewTab("/edit/"+el.id)}>
                        <i className="fas fa-edit" />
                      </Button>
                      {/* <Button color="danger" size="sm" type="button" >
                        <i className="fas fa-trash" onClick={()=>deleteRow(el.id,index)}/>
                      </Button> */}
                    </td>
                  </tr>
                );
              })
            ) : (
              <span>No Record Found</span>
            )}
          </tbody>
        </Table>
      </div>
    </Card>
  );
};

export default withRouter(SearchTable);
