import React, { useState, useEffect, Fragment } from 'react';
import {
	Row,
	Col,
	Container,
	Form,
	FormGroup,
	Label,
	Button,
	Input,
	Card,
	CardHeader,
	CardBody,
} from 'reactstrap';
import Select from 'react-select';
import { getAPI, putpost, masterServiceBaseUrl } from 'services/http';
import moment from 'moment';
import { successToast ,failureToast } from '../../../common/utils/methods/toasterFunctions/function';
import { useHistory } from 'react-router-dom';
import { fetchDetails } from '../../../common/utils/methods/commonMethods/utilityMethod';
import CustomButton from "../../../../../../components/CustomButton";
import CommonInput from "../../../common/formFeilds/input/commonInput";

const CourseCatalogSequence = (props) => {
	let history = useHistory();
	const { nextStep, permissionSet } = props;
	const [groupCode, setGroupCode] = useState();
	const [companyCode, setCompanyCode] = useState();
	const [academicCareer, setAcademicCareer] = useState();
	const [status, setStatus] = useState([
		{ label: 'ACTIVE', value: 'ACTIVE' },
		{ label: 'INACTIVE', value: 'INACTIVE' },
	]);
	const [courseCatalogSequence, setCourseCatalogSequence] = useState({
		groupCodeId: '',
		companyCodeId: '',
		academicCareerId: '',
		effectiveDate: '',
		status: 'ACTIVE',
	});

	useEffect(() => {
		fetchData(
			masterServiceBaseUrl + '/groupCode/getAllActiveGroupCode',
			setGroupCode,
			'groupCode'
		);
		// fetchData( masterServiceBaseUrl + '/companyCode/getAllActiveCompanyCode', setCompanyCode, "companyCode" );
		// fetchData( masterServiceBaseUrl +'/academicCareer/getAllActiveAcademicCareer',setAcademicCareer,"academicCareer");
	}, []);

	const fetchData = async (url, stateMethod, type) => {
		try {
			getAPI(
				url,
				(data) => {
					let temp;
					switch (type) {
						case 'groupCode':
							temp = [];
							data &&
								data.data &&
								data.data.map((item) => {
									temp.push({
										id: item.id,
										value: item.groupCodeDispValue,
										label: item.groupCodeDispValue,
									});
								});
							stateMethod(temp);
							break;
						case 'companyCode':
							temp = [];
							data &&
								data.data &&
								data.data.map((item) => {
									temp.push({
										id: item.id,
										value: item.companyCodeDispValue,
										label: item.companyCodeDispValue,
									});
								});
							stateMethod(temp);
							break;
						case 'academicCareer':
							temp = [];
							data &&
								data.data &&
								data.data.map((item) => {
									temp.push({
										id: item.id,
										value: item.academicCareerDispValue,
										label: item.academicCareerDispValue,
									});
								});
							stateMethod(temp);
							break;
						default:
							break;
					}
				},
				(data) => {
					failureToast(data['message']);
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	const formSubmitHandler = () => {
		try {
			if (courseCatalogSequence['groupCodeId'] === '') {
				return failureToast('Please Select Business Group');
			}else if (courseCatalogSequence['companyCodeId'] === '') {
				return failureToast('Please Select Company Code');
			}else if (courseCatalogSequence['academicCareerId'] === '') {
				return failureToast('Please Select Academic Career');
			}else {
				let payLoad = {
					groupCodeId : courseCatalogSequence?.groupCodeId?.id,
					companyCodeId : courseCatalogSequence?.companyCodeId?.id,
					academicCareerId : courseCatalogSequence?.academicCareerId?.id,
					status: courseCatalogSequence?.status
				}
				putpost(
					masterServiceBaseUrl + '/courseAttributeMapping/createCourseCatalogSequenceSetup',
					(data) => {
						successToast(data['message']);
						history.push(`/admin/CourseCatalogSequence/new/` + data['data']['id'])
					},
					(data) => {
						failureToast(data['message']);
					},
					payLoad,
					'post'
				);
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Fragment>
			<Container fluid={true} className="p-4 ">
				<Card>
					<CardHeader>
						<h2 className="mb-0 floatLeft">Course Catalog Sequence</h2>
					</CardHeader>
					<CardBody>
						<Form>
							<Row>
								<Col md={6}>
									<CommonInput label={'Business Group'}
												 type={'select'}
												 // mandatory={true}
												 id="businessGroup"
												 placeHolder={'Business Code'}
												 defaultValue={courseCatalogSequence.groupCodeId}
												 data={groupCode}
												 onChange={(e) => {
													 setCourseCatalogSequence((preState) => ({...preState, groupCodeId: e, companyCodeId: null, academicCareerId: null}));
													 fetchData(masterServiceBaseUrl + '/companyCode/getCompanyCodeByGroup/' + e.id, setCompanyCode, 'companyCode');

												 }}
									/>
								</Col>
								<Col md={6}>
									<CommonInput label={'Company Code'}
												 type={'select'}
										// mandatory={true}
												 id="companyCode"
												 placeHolder={'Company Code'}
												 defaultValue={courseCatalogSequence.companyCodeId}
												 data={companyCode}
												 onChange={(e) => {
													 setCourseCatalogSequence((preState) => ({...preState, companyCodeId: e, academicCareerId: null}));
													 fetchData(masterServiceBaseUrl + '/academicCareer/getAcademicCareerByCompanyCode/' + e.id, setAcademicCareer, 'academicCareer');
												 }}
									/>
								</Col>
							</Row>
							<Row>
								<Col md={6}>
									<CommonInput label={'Academic Career'}
												 type={'select'}
										// mandatory={true}
												 id="companyCode"
												 placeHolder={'Academic Career'}
												 defaultValue={courseCatalogSequence.academicCareerId}
												 data={academicCareer}
												 onChange={(e) => {
													 setCourseCatalogSequence((preState) => ({...preState, academicCareerId: e,}));
												 }}
									/>

								</Col>
								<Col md={6}>
									<CommonInput label={'Effective Date'}
												 type={'text'}
												 isDisabled={true}
										// mandatory={true}
												 id="effectiveDate"
												 placeHolder={'Effective Date'}
												 defaultValue={moment().format('DD-MMM-YYYY')}
												 onChange={(e) => {
													 // let tempDate = e.target.value;
													 // setCourseCatalogSequence((preState) => ({
														//  ...preState,
														//  effectiveDate: tempDate,
													 // }));
												 }}
									/>
								</Col>
							</Row>
							<Row>
								<Col md={6}>
									<CommonInput label={'Status'}
												 type={'select'}
										// mandatory={true}
												 id="status"
												 placeHolder={'Status'}
												 defaultValue={{
													 value: courseCatalogSequence['status'],
													 label: courseCatalogSequence['status'],
												 }}
												 data={status}
												 oonChange={(e) => {
													 setCourseCatalogSequence((preState) => ({
														 ...preState,
														 status: e.value,
													 }));
												 }}
									/>

								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									{/*<Button*/}
									{/*	data-testId="next"*/}
									{/*	color="info"*/}
									{/*	size="md"*/}
									{/*	className="floatRight mx-1"*/}
									{/*	onClick={() => formSubmitHandler()}*/}
									{/*>*/}
									{/*	Next*/}
									{/*</Button>*/}
									<CustomButton
										data-testId="next"
										className={'floatRight mx-1'}
										content={'Next'}
										permissionType={'C,U'}
										permissionSet={permissionSet}
										onClick={() => formSubmitHandler()}
									/>
									<Button
										data-testId="close"
										color="danger"
										size="md"
										className="floatRight mx-1"
										onClick={() => history.goBack()}
									>
										Close
									</Button>
								</Col>
							</Row>
						</Form>
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default CourseCatalogSequence;
