import { endpoint } from "../manage/common/constant"
import { fetchAllPostPromisedData } from "../manage/common/utils/methods/commonMethods/utilityMethod"


export const generateOtp = async(request) => {
    try{
        return await fetchAllPostPromisedData(`${endpoint.generateOtp}`, request)
    }catch(e){
        console.log(e)
        return {}
    }
} 

export const validateOtp = async(request) => {
    return await fetchAllPostPromisedData(`${endpoint.validateOtp}`, request)
} 

export const resetPasswordWithOtp = async(request) => {
   return await fetchAllPostPromisedData(`${endpoint.resetPasswordWithOtp}`, request)
} 
