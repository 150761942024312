import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { masterServiceBaseUrl, concessionServiceBaseUrl, getAPI,examControllerServiceBaseUrl,failureToast } from "../../../../../../services/http";
import {
  fetchDetails,
  fetchAllData,
  getDispValForReactSelectByOneID,
} from "../../../common/utils/methods/commonMethods/utilityMethod";
import DetailsTable from "./detailTable";
import Container from "reactstrap/lib/Container";
import { GetPagination } from "../../../common/commonComponents/pagination/pagination";

const ExamConfigDetails = (props) => {
 
  const {comapnyCode, academicCareer, term, id, action} = props;
  const [classArray,setclassArray] = useState([])
  const [serverData,setServerData] = useState([])
  // const { action } = props.match.params;
  const [isSaveVisible, setIsSaveVisible] = useState(false);
  const [isFetching,setIsFetching] = useState(true)
  const [examData, setExamData] = useState([]);
  const [examGrpData,setExamGrpData] = useState(null);
  const [classData,setClassData] = useState(null);
  const [examTypeData,setExamTypeData] = useState(null);
  const isLoading = comapnyCode && academicCareer && term ? false : true;

  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const pageSize = 10;

  const setPagination = (paramsdata) => {
    let n = pagination;
    if (paramsdata.length > pageSize) {
      n["nextPage"] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n["totalPage"] = Math.floor(paramsdata.length / pageSize);
    if (paramsdata.length % pageSize != 0) {
      n["totalPage"] += 1;
    }
    setpagination(n);
    setclassArray(
      paramsdata.slice(
        pagination.currentPage * pageSize - pageSize,
        pagination.currentPage * pageSize
      ) || []
    );
    setIsFetching(false);
  };

  const getData = () => {
    getAPI(
      `${examControllerServiceBaseUrl}/examDateDetails/getExamDateDetailByExamHeaderId/${id}`,
      (data) => {
        setPagination(data.data)
        setServerData(data.data)
        // setClassArray(data.data);
        // setIsFetching(false);
      },
      (data) => {
        failureToast(data["message"]);
        setServerData([])
        setPagination([])
        setIsFetching(false);
      }
    );
  };

  useEffect(() => {
    if (id && action) {
      action !== 'new' && getData();
    }
    if(action === 'new'){
      setIsFetching(false)
    }
  }, [id,action]);

  useEffect(() => {
    id && comapnyCode && academicCareer && term && fetchDetails(
      `${examControllerServiceBaseUrl}/exam/findExamIdBycompanyCodeAndAcademicCareerAndTerm?companyCode=${comapnyCode}&academicCareerId=${academicCareer}&termId=${term}`,
      setExamData,
      "examData",
      "examId"
    );
  }, [id,comapnyCode,academicCareer,term]);

  useEffect(() => {
    fetchDetails(
      `${masterServiceBaseUrl}/examGroup/getAllExamGroup`,
      setExamGrpData,
      "examGroup",
      "reactSelect"
    );
    fetchDetails(
      `${masterServiceBaseUrl}/testType/getAllTestType`,
      setExamTypeData,
      "testType",
      "reactSelect"
    );
    fetchDetails(
      `${masterServiceBaseUrl}/class/getAllClass`,
      setClassData,
      "class",
      "reactSelect"
    );
  }, [])

  return (
    <>
      {/* {isLoading ? (
        <div
          className="mx-auto text-center py-5 my-5"
          style={{ height: "100vh" }}
        >
          <CustomLoader apiLoader={isLoading} />
        </div>
      ) : ( */}
        <>
          <DetailsTable
            isSaveVisible={isSaveVisible}
            setIsSaveVisible={setIsSaveVisible}
            classArray={classArray}
            setClassArray={setclassArray}
            examData={examData}
            examHeaderId={id}
            isFetching={isFetching}
            setIsFetching={setIsFetching}
            getData={getData}
            examGrpData={examGrpData}
            classData={classData}
            examTypeData={examTypeData}
            isView = {props.isView}
          />

          {serverData && serverData.length > 10 ? (
          <GetPagination
            // fetchClasses={fetchClasses}
            setclassArray={setclassArray}
            pagination={pagination}
            setpagination={setpagination}
            pageSize={pageSize}
            state={serverData}
          />
        ) : null}
        </>
      {/* )} */}
    </>
  );
};

export default ExamConfigDetails;
