import React from "react";
import {
    Card,
    CardHeader,
    Input,
    Container,
    Table,
    Row,
} from "reactstrap";
import { useState } from "react";
import { failureToast, successToast } from "../../common/utils/methods/toasterFunctions/function";
import { TDROW } from "../../common/commonComponents/masterRow/masterRow";
import moment from "moment";
import { ButtonText } from "variables/Buttons";
import CustomButton from "../../../../../components/CustomButton";
import { endpoint, regex } from "../../common/constant";
import { fetchAllPostPromisedData, fetchDeletePromisedData, getCurrentDate, NewRow } from "../../common/utils/methods/commonMethods/utilityMethod";
import validationRules from './validationRule.json'
import { customValidation } from "../../common/utils/methods/validations/validation";
import { editTableRow, newTableRow } from "../../common/utils/methods/commonMethods/tableMethods";
import { MasterHeaderAuto } from "../../common/commonComponents/masterHeader/masterHeader";
var _ = require("lodash");

const ProjectDetailsTable = (props) => {
    const { permissionSet, isSaveVisible, setisSaveVisible = () => { }, getAllrecords = () => { } } = props;
    const [fetching,setFetching] = useState(false);
    const [tableDataKey, settableDataKey] = useState("tableDataKey");

    const headerList = [
        { name: 'Key', isRequired: true },
        { name: 'Description', isRequired: true },
        { name: 'Display Value', isRequired: true },
        { name: 'Effective Date', isRequired: false },
        { name: 'Multiple roles for One User id', isRequired: false },
        { name: 'User id is Email ID', isRequired: false },
        { name: 'New User Notification', isRequired: false },
        { name: 'Status', isRequired: true },
        { name: 'Action', isRequired: false },
    ]

    const listToRender = [
        "projectKey",
        "projectDesc",
        "projectDispValue",
        "effectiveDate",
        "isMultipleRoles",
        "isUseridEmail",
        "isNewUser",
        "status"
    ]

    const addNewClass = () => {
        props.setclassArray([
            {
                editMode: true,
                __status: "__new",
                projectKey: null,
                status: "ACTIVE",
                projectDesc: null,
                projectDispValue: null,
                effectiveDate: null,
                isMultipleRoles: false,
                isUseridEmail: false,
                isNewUser: false
            },
            ...props.classArray,
        ]);
    };
    const saveAll = async (index) => {
        let _data = props.classArray[index];
        let isValid = true;
        isValid = customValidation(_data, validationRules);

        if (!isValid) {
            return;
        }
        setFetching(true)
        if (_data["__status"] == "__new") {
            const createRecord = await fetchAllPostPromisedData(endpoint.projectDetails.create, _data, 'post')
            if (createRecord?.code === 200) {
                successToast(createRecord["message"]);
                setisSaveVisible(false);
                setFetching(false);
                getAllrecords()
            }
            setFetching(false)
        } else {
            const createRecord = await fetchAllPostPromisedData(`${endpoint.projectDetails.update}/${_data.id}`, _data, 'put')
            if (createRecord?.code === 200) {
                successToast(createRecord["message"]);
                setisSaveVisible(false);
                setFetching(false);
                getAllrecords()
            }
            setFetching(false)
        }
    };
    const newRow = () => {
        if (isSaveVisible) {
            failureToast("Please Delete or Save current changes");
        } else {
            newTableRow(props.classArray, props.setclassArray, setisSaveVisible, addNewClass);
        }
    };
    const editRow = (index) => {
        if (isSaveVisible) {
            failureToast("Please Delete or Save current changes");
        } else {
            editTableRow(index, props.classArray, props.setclassArray, setisSaveVisible);
        }
    };
    const deleteRow = async (index) => {
        if (
            window.confirm("Are you sure you want to delete this Website Sync?")
        ) {
            setFetching(true)
            const deleteRecords = await fetchDeletePromisedData(`${endpoint.projectDetails.delete}/${props.classArray[index].id}`);
            if (deleteRecords?.code === 200) {
                let flag = false;
                if (props.classArray.length == 1) {
                    flag = true;
                }
                setFetching(false)
                getAllrecords(flag);
                settableDataKey(new Date().getMilliseconds());
            }
            setFetching(false)
        }
        // }
    };
    const updateKey = (index, value, key) => {
        var _tempArray = props.classArray;
        _tempArray[index][key] = value;
        props.setclassArray(_tempArray);
    };
    return (
        <>
            <Container className="mt-3" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                <h3 className="mb-0 floatLeft">Master - Project Details</h3>
                                <CustomButton
                                    data-testId="addNewProjectDetails"
                                    disabled={isSaveVisible}
                                    className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
                                    content={ButtonText.ProjectDetails.addNew}
                                    permissionType={'C'}
                                    icon={true}
                                    permissionSet={permissionSet}
                                    onClick={() => newRow()}
                                />
                                {/* {isSaveVisible?<Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => {saveAll()}}>Save</Button>:null} */}
                            </CardHeader>
                            <div id="questionTable" className="table-responsive">
                                <Table className="align-items-center table-flush tableLayout">
                                    {/* <MasterHeader type={"Website Sync"} isSaveVisible={isSaveVisible} permissionSet={permissionSet} /> */}
                                    <MasterHeaderAuto headerList={headerList} isSaveVisible={isSaveVisible} permissionSet={permissionSet} />
                                    <tbody className="list" key={tableDataKey}>
                                        {props.classArray
                                            ? props.classArray.map((el, index) =>
                                                !el["editMode"] ? (
                                                    <NewRow
                                                        data={{ ...el, sno: index + 1 }}
                                                        listToBeRendered={listToRender}
                                                    >
                                                        <td className="text-center p-2 mx-1">
                                                            <CustomButton
                                                                // content={ButtonText.DOCUMENTTYPE.UPDATE}
                                                                permissionType={'U'}
                                                                icon={true}
                                                                forTable={true}
                                                                disabled={isSaveVisible}
                                                                onClick={() => editRow(index)}
                                                                permissionSet={permissionSet}
                                                            />
                                                            <CustomButton
                                                                // content={ButtonText.DOCUMENTTYPE.DELETE}
                                                                permissionType={'D'}
                                                                icon={true}
                                                                forTable={true}
                                                                disabled={isSaveVisible}
                                                                onClick={() => deleteRow(index)}
                                                                permissionSet={permissionSet}
                                                            />
                                                        </td>
                                                    </NewRow>
                                                ) : (
                                                    <tr key={index + "-class"}>
                                                        <td className="text-center p-2 mx-1">
                                                            <Input
                                                                data-testid="projectKey"
                                                                maxLength={3}
                                                                defaultValue={el["projectKey"]}
                                                                onChange={(e) => {
                                                                    const val = e.target.value?.toString()?.toUpperCase() ?? e.target.value;
                                                                    updateKey(
                                                                        index,
                                                                        val,
                                                                        "projectKey"
                                                                    );
                                                                }}
                                                                placeholder="Key"
                                                                className="tdInput"
                                                                onKeyPress={(e) => {
                                                                    if (!regex.alphabets.test(String.fromCharCode(e.charCode))) {
                                                                        e.preventDefault();
                                                                        return;
                                                                    }
                                                                }}
                                                                style={{textTransform : "uppercase"}}
                                                            />
                                                        </td>

                                                        <td className="text-center p-2 mx-1">
                                                            <Input
                                                                data-testid="projectDesc"
                                                                maxLength={50}
                                                                defaultValue={el["projectDesc"]}
                                                                onChange={(e) => {
                                                                    updateKey(
                                                                        index,
                                                                        e.target.value,
                                                                        "projectDesc"
                                                                    );
                                                                }}
                                                                placeholder="Description"
                                                                className="tdInput"
                                                                onKeyPress={(e) => {
                                                                    if (!regex.projectMasterDesc.test(String.fromCharCode(e.charCode))) {
                                                                        e.preventDefault();
                                                                        return;
                                                                    }
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="text-center p-2 mx-1">
                                                            <Input
                                                                data-testid="projectDispValue"
                                                                maxLength={25}
                                                                defaultValue={el["projectDispValue"]}
                                                                onChange={(e) => {
                                                                    updateKey(
                                                                        index,
                                                                        e.target.value,
                                                                        "projectDispValue"
                                                                    );
                                                                }}
                                                                placeholder="Display Value"
                                                                className="tdInput"
                                                                onKeyPress={(e) => {
                                                                    if (!regex.projectMasterDesc.test(String.fromCharCode(e.charCode))) {
                                                                        e.preventDefault();
                                                                        return;
                                                                    }
                                                                }}
                                                            />
                                                        </td>

                                                        <td className="text-center p-2 mx-1">
                                                            <Input
                                                                data-testid="effectiveDate"
                                                                disabled={true}
                                                                defaultValue={
                                                                    el["__status"] == "__new"
                                                                        ? getCurrentDate()
                                                                        : moment(el["effectiveDate"]).format(
                                                                            "DD-MM-YYYY"
                                                                        )
                                                                }
                                                                placeholder="Example:01-01-2020"
                                                                className="tdInput"
                                                            />
                                                        </td>
                                                        <td className="text-center p-2 mx-1">
                                                            <label className="custom-toggle mx-auto ml-2">
                                                                <input
                                                                    data-testid="activeInactiveProjectDetails"
                                                                    checked={
                                                                        el.isMultipleRoles == true ? true : false 
                                                                    }
                                                                    type="checkbox"
                                                                    id={el.id}
                                                                    onChange={(e) => {
                                                                        settableDataKey(
                                                                            new Date().getMilliseconds()
                                                                        );
                                                                        let n = true;
                                                                        if (!e.target.checked) {
                                                                            n = false;
                                                                        }
                                                                        updateKey(index, n, "isMultipleRoles");
                                                                    }}
                                                                />
                                                                <span
                                                                    data-testid="aciveInactiveLabelProjectDetails"
                                                                    style={{ width: "72px" }}
                                                                    className="custom-toggle-slider rounded-circle activeToggle"
                                                                    data-label-off="No"
                                                                    data-label-on="Yes"
                                                                />
                                                            </label>
                                                        </td>
                                                        <td className="text-center p-2 mx-1">
                                                            <label className="custom-toggle mx-auto ml-2">
                                                                <input
                                                                    data-testid="activeInactiveProjectDetails"
                                                                    checked={
                                                                        el.isUseridEmail == true ? true : false 
                                                                    }
                                                                    type="checkbox"
                                                                    id={el.id}
                                                                    onChange={(e) => {
                                                                        settableDataKey(
                                                                            new Date().getMilliseconds()
                                                                        );
                                                                        let n = true;
                                                                        if (!e.target.checked) {
                                                                            n = false;
                                                                        }
                                                                        updateKey(index, n, "isUseridEmail");
                                                                    }}
                                                                />
                                                                <span
                                                                    data-testid="aciveInactiveLabelProjectDetails"
                                                                    style={{ width: "72px" }}
                                                                    className="custom-toggle-slider rounded-circle activeToggle"
                                                                    data-label-off="No"
                                                                    data-label-on="Yes"
                                                                />
                                                            </label>
                                                        </td>
                                                        <td className="text-center p-2 mx-1">
                                                            <label className="custom-toggle mx-auto ml-2">
                                                                <input
                                                                    data-testid="activeInactiveProjectDetails"
                                                                    checked={
                                                                        el.isNewUser == true ? true : false 
                                                                    }
                                                                    type="checkbox"
                                                                    id={el.id}
                                                                    onChange={(e) => {
                                                                        settableDataKey(
                                                                            new Date().getMilliseconds()
                                                                        );
                                                                        let n = true;
                                                                        if (!e.target.checked) {
                                                                            n = false;
                                                                        }
                                                                        updateKey(index, n, "isNewUser");
                                                                    }}
                                                                />
                                                                <span
                                                                    data-testid="aciveInactiveLabelProjectDetails"
                                                                    style={{ width: "72px" }}
                                                                    className="custom-toggle-slider rounded-circle activeToggle"
                                                                    data-label-off="No"
                                                                    data-label-on="Yes"
                                                                />
                                                            </label>
                                                        </td>


                                                        <td className="text-center p-2 mx-1">
                                                            <label className="custom-toggle mx-auto ml-2">
                                                                <input
                                                                    data-testid="activeInactiveProjectDetails"
                                                                    checked={
                                                                        el.status == "ACTIVE" ? true : false
                                                                    }
                                                                    type="checkbox"
                                                                    id={el.id}
                                                                    onChange={(e) => {
                                                                        settableDataKey(
                                                                            new Date().getMilliseconds()
                                                                        );
                                                                        let n = "ACTIVE";
                                                                        if (!e.target.checked) {
                                                                            n = "INACTIVE";
                                                                        }
                                                                        updateKey(index, n, "status");
                                                                    }}
                                                                />
                                                                <span
                                                                    data-testid="aciveInactiveLabelProjectDetails"
                                                                    style={{ width: "72px" }}
                                                                    className="custom-toggle-slider rounded-circle activeToggle"
                                                                    data-label-off="Inactive"
                                                                    data-label-on="Active"
                                                                />
                                                            </label>
                                                        </td>
                                                        <td className="text-center p-2 mx-1">
                                                            {isSaveVisible ? (
                                                                <>
                                                                    <CustomButton
                                                                        data-testid="saveProjectDetails"
                                                                        className={'mx-1'}
                                                                        content={ButtonText.ProjectDetails.save}
                                                                        permissionType={'C,U'}
                                                                        forTable={true}
                                                                        permissionSet={permissionSet}
                                                                        disabled={fetching}
                                                                        onClick={() => { saveAll(index) }}
                                                                    />
                                                                    <CustomButton
                                                                        data-testId="closeProjectDetails"
                                                                        className={'mx-1'}
                                                                        permissionType={'cancel'}
                                                                        forTable={true}
                                                                        icon={true}
                                                                        disabled={fetching}
                                                                        permissionSet={permissionSet}
                                                                        onClick={() => {
                                                                            let n = props.classArray;
                                                                            if (n[index]["__status"] == "__new") {
                                                                                n.splice(index, 1);
                                                                            } else {
                                                                                // n[index]['editMode']=false
                                                                                props.getAllrecords();
                                                                            }
                                                                            // props.setclassArray(n);
                                                                            setisSaveVisible(false);
                                                                        }}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            )
                                            : null}
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};
export default ProjectDetailsTable;
