import { fetchAllPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { masterServiceBaseUrl } from "services/http";

const fetchData = async (url, name) => {
    try {
        const resp = await fetchAllPromisedData(url, true);
        let data = [];
        if (resp?.code === 200) {
            data = resp.data?.filter(el => el.status === 'ACTIVE')?.map(el => ({
                ...el,
                label: el[`${name}`],
                value: el[`${name}`]
            }))
        }
        return data;
    } catch (err) {
        console.log(err);
    }

}

export const fetchLocationData = async () => {
    const dataArr = await Promise.all([
        fetchData(`${masterServiceBaseUrl}/pincode/getAllPincode`, "pincodeGstCode"),
        fetchData(`${masterServiceBaseUrl}/district/getAllDistrict`, "districtDispValue"),
        fetchData(`${masterServiceBaseUrl}/city/getAllCity`, 'cityDispValue'),
        fetchData(`${masterServiceBaseUrl}/state/getAllState`, 'stateDispValue'),
        fetchData(`${masterServiceBaseUrl}/country/getAllCountry`, 'countryDispValue'),
    ])

    return {
        pincodeData: dataArr[0],
        districtData: dataArr[1],
        cityData: dataArr[2],
        stateData: dataArr[3],
        countryData: dataArr[4]
    };

}
