import React, { useState, useEffect } from "react";
import { FormText } from "reactstrap";
import {
  CustomContainer,
  CustomCard,
  ToExcel,
} from "../../common/commonComponents";
import CustomLoader from "../../common/commonComponents/Loader/loader";
import { failureToast, getAPI } from "services/http";
import { GetPagination } from "../../common/commonComponents/pagination/pagination";
import { endpoint, commonMessages } from "../../common/constant";
import { fetchDetails } from "../../common/utils/methods/commonMethods/utilityMethod";
import CommonInput from "../../common/formFeilds/input/commonInput";
import ProceesToPageTable from "./table";
import "./index.scss"

const _ = require("lodash");

const excelDetails = {
  "Process Name": "processName",
  "Page Name": "pageName",
  "Page Description": "pageDescription",
  Navigation: "navigation",
};

const CardHeader = ({
  isSaveVisible = false,
  processId,
  setProcessId = () => {},
  serverData = [],
  newRow = () => {},
  setIsSaveVisible = () => {},
  getDataById = () => {},
  activeProcess =[]
}) => {
  const dataToExport = serverData?.map((item) => {
    return {
      ...item,
      processName : processId?.label
    };
  });

  // const filterOption = (data) => {
  //   return data?.map((data) => {
  //     return Object.assign(
  //       {},
  //       {
  //         label: data?.tagTypeDispValue,
  //         value: data?.id,
  //         desc: `${data?.tagTypeDesc}`,
  //       }
  //     );
  //   });
  // };

  // const promiseOptions = (inputValue) => {
  //   return new Promise((resolve) => {
  //     if (inputValue && inputValue.length < 2) {
  //       return resolve([]);
  //     }
  //     getAPI(
  //       `${endpoint.process_Tag.search}?key=${inputValue}`,
  //       (data) => {
  //         resolve(filterOption(data?.data));
  //       },
  //       (data) => {
  //         failureToast(data["message"]);
  //       }
  //     );
  //   });
  // };

  return (
    <>
      <h3>Process To Page</h3>
      <div className="d-flex flex-row  align-items-center">
        <div style={{ width: "250px" }} className="flex-column process-page">
          {/* <AsyncSelectSearch
            value={processId}
            onChangeHandler={(sData) => {
              setProcessId(sData);
              getDataById(sData.value)
            }}
            promiseHandler={promiseOptions}
            placeholder={"Search Process"}
          /> */}
          <CommonInput 
            defaultValue = {processId}
            onChange={(sData) => {
              setProcessId(sData);
              getDataById(sData.value)
            }}
            data={activeProcess}
            placeholder={"Select Process"}
            type={"select"}
          />
          {!processId?.value ? <span className={"text-danger field-info"} >{commonMessages.pleaseSelectProcessForRecords}</span> : null}
        </div>
        {!isSaveVisible && serverData.length ? (
          <ToExcel
            data={dataToExport}
            detailObj={excelDetails}
            name="Attendance Machines"
            isSaveVisible={isSaveVisible}
          />
        ) : null}
        {/* <Button
          disabled={isSaveVisible}
          color="info"
          size="sm"
          type="button"
          className={
            "floatRight mx-1 ml-2" + (isSaveVisible ? " btn-dark" : null)
          }
          onClick={() => {
            newRow();
            setIsSaveVisible(true);
          }}
        >
          Add New
        </Button> */}
      </div>
    </>
  );
};

const ProcessToPage = () => {
  const [processId, setProcessId] = useState(null);
  const [classArray, setclassArray] = useState([]);
  const [apiloader, setapiloader] = useState(false);
  const [state, setstate] = useState([]);
  const [serverData, setServerData] = useState([]);
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [activeProcess,setActiveProcess] = useState([])
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const pageSize = 10;

  const fetchClasses = async (url) => {
    setapiloader(true);
    getAPI(
      url,
      (data) => {
        setstate(data["data"]);
        setServerData(data["data"]);
        setPagination(data["data"]);
      },
      (data) => {
        failureToast(data["message"]);
        setapiloader(false);
      }
    );
  };

  const setPagination = (paramsdata) => {
    let n = pagination;
    if (paramsdata.length > pageSize) {
      n["nextPage"] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n["totalPage"] = Math.floor(paramsdata.length / pageSize);
    if (paramsdata.length % pageSize != 0) {
      n["totalPage"] += 1;
    }
    setpagination(n);
    setclassArray(
      paramsdata.slice(
        pagination.currentPage * pageSize - pageSize,
        pagination.currentPage * pageSize
      ) || []
    );
    setapiloader(false);
  };

  const getDataById = (id) => {
    if(processId === id) return null
    fetchClasses(`${endpoint.processToPage.getAllByProcessTagId}/${id}`)
  }

  useEffect(() => {
    fetchDetails(
      endpoint.process_Tag.getAllActive,
      setActiveProcess,
      'tagType',
      'select'
    );
  }, [])

  return (
    <>
      <CustomContainer>
        <CustomCard
          HeaderElement={
            <CardHeader processId={processId} setProcessId={setProcessId} getDataById={getDataById} serverData={serverData} activeProcess={activeProcess}/>
          }
        ></CustomCard>
      </CustomContainer>
      <>
        {apiloader ? (
          <div
            className="mx-auto text-center py-5 my-5"
            style={{ height: "100vh" }}
          >
            <CustomLoader apiLoader={apiloader} />
          </div>
        ) : (
          processId && serverData && (
            <>
              <ProceesToPageTable
                fetchClasses={fetchClasses}
                setclassArray={setclassArray}
                classArray={classArray}
                isSaveVisible={isSaveVisible}
                setisSaveVisible={setisSaveVisible}
                serverData={serverData}
                processId={processId?.value}
              />
              {pagination.totalPage > 1 ? (
                <GetPagination
                  isSaveVisible={isSaveVisible}
                  fetchClasses={fetchClasses}
                  setclassArray={setclassArray}
                  pagination={pagination}
                  setpagination={setpagination}
                  pageSize={pageSize}
                  state={state}
                />
              ) : null}
            </>
          )
        )}
      </>
    </>
  );
};

export default ProcessToPage;
