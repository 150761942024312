import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Container, Col, Collapse, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import ItemTypeSequenceSearchForm from "./itemTypeSequenceSearchForm";
import ItemTypeSequenceSearchTable from "./itemTypeSequenceSearchTable";
import { masterServiceBaseUrl, putpost } from "services/http";
import { successToast, failureToast, } from '../../../common/utils/methods/toasterFunctions/function';
import { data } from './data';
import { fetchDetails } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { fetchItemType } from "./itemTypeSequence";
import { GetPagination } from "../../../common/commonComponents/pagination/pagination";
import { PermissionContext, RolePermissions } from "../../../../../../appContext";
import { pages } from "../../../common/constant";
import { PermisionDenied } from "../../../common/commonComponents";
import CustomButton from "../../../../../../components/CustomButton";
import CustomLoader from "../../../common/commonComponents/Loader/loader";
const ItemTypeSequenceSetup = () => {
    let history = useHistory();
    const { label, placeHolder, message, searchHeaderList, button } = require('./properties/index.json');
    const [searchData, setSearchData] = useState({
        groupCodeId: '',
        companyCodeId: '',
        termId: '',
        academicCareerId: '',
        courseTypeId: '',
        status: ''
    });
    const [pagination, setPagination] = useState({
        currentPage: 1,
        nextPage: null,
        previousPage: null,
        totalPage: null,
    });
    const pageSize = 10;

    const permissions = useContext(PermissionContext);

    const userPermissions = RolePermissions(
        permissions,
        pages['itemTypeSequence']['id']
    );

    const [apiLoader, setApiLoader] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [allTableData, setAllTableData] = useState([]);
    const statusData = [
        { text: 'ACTIVE', id: 'ACTIVE' },
        { text: 'INACTIVE', id: 'INACTIVE' },
    ]
    const [groupCode, setGroupCode] = useState([]);
    const [companyCode, setCompanyCode] = useState([]);
    const [term, setTerm] = useState([]);
    const [academicCareer, setAcademicCareer] = useState([]);
    const [courseType, setCourseType] = useState([]);

    const [searching, setSearching] = useState(false);

    const updateSearchData = (key, value) => setSearchData(preState => ({ ...preState, [key]: value }));
    const paginationUpdate = (data) => {
        let n = pagination;
        if (data.length > pageSize) {
            n["nextPage"] = n.currentPage + 1;
        }
        if (n.currentPage > 1) {
            n.previousPage = n.currentPage - 1;
        }
        n["totalPage"] = Math.floor(data.length / pageSize);
        if (data.length % pageSize !== 0) {
            n["totalPage"] += 1;
        }
        setPagination(n);
        setTableData(
            data.slice(
                pagination.currentPage * pageSize - pageSize,
                pagination.currentPage * pageSize
            ) || []
        );
        // setApiLoader(false);
    }
    const searchHandler = () => {
        try {
            setTableData([]);
            if (searchData.academicCareerId === '') {
                failureToast(data.messages.selectAcademicCareer);
            } else if (searchData.courseType === '') {
                failureToast(message.failure.courseType)
            } else {
                setSearching(true)
                putpost(
                    masterServiceBaseUrl + '/itemTypeSequenceSetupHeader/search',
                    (data) => {
                        if (data.data.length === 0) {
                            failureToast(data.messages.dataNotFound);
                            setTableData([])
                        } else {
                            successToast(data.message);
                            setAllTableData([...data.data]);
                            paginationUpdate([...data.data]);
                        }
                        setSearching(false)
                    }, (data) => {
                        failureToast(data.message);
                        setSearching(false)
                    }, searchData, "POST").then(() => {
                    });
            }
        } catch (e) {
            console.error('Error-', e)
        }
    }
    const fetchRequiredData = () => {
        fetchDetails(masterServiceBaseUrl + '/term/getAllActiveTerm', setTerm, 'term', 'reactSelect');
        fetchDetails(masterServiceBaseUrl + `/groupCode/getAllActiveGroupCode`, setGroupCode, 'groupCode', 'reactSelect');
        fetchItemType(masterServiceBaseUrl + '/academicCareer/getAllActiveAcademicCareer', 'academicCareer', setAcademicCareer);
        fetchDetails(masterServiceBaseUrl + '/courseAttributeValue/getCourseAttributeValueByCourseType', setCourseType, 'courseAttributeValue', 'reactSelect');

    }
    const resetHandler = () => {
        setSearchData({
            groupCodeId: '',
            companyCodeId: '',
            termId: '',
            academicCareerId: '',
            courseTypeId: '',
            status: ''
        });
        setTableData([]);
        setPagination({ currentPage: 1, nextPage: null, previousPage: null, totalPage: null, });
    }
    useEffect(() => {
        fetchRequiredData();
    }, [])
    useEffect(() => {
        if (groupCode.length > 0 && academicCareer.length > 0 && courseType.length > 0) {
            setApiLoader(false);
        }
    }, [groupCode, academicCareer, courseType])
    return (
        apiLoader
            ?
            <CustomLoader apiLoader={apiLoader} loaderHeight={'200px'} loaderWidth={'100% '} />
            :
            <Fragment>
                {
                    userPermissions ? (
                        <Container fluid={true} className="p-4">
                            <Card>
                                <CardHeader>
                                    <h2 className="mb-0 floatLeft">{data.heading}</h2>
                                    <CustomButton
                                        className={'floatRight mx-1'}
                                        content={data.button.addNew}
                                        permissionType={'C'}
                                        icon={true}
                                        permissionSet={userPermissions}
                                        onClick={() => history.push(`/admin/itemTypeSequence/new`)}
                                    />
                                </CardHeader>
                                <CardBody>
                                    <ItemTypeSequenceSearchForm
                                        searchData={searchData}
                                        updateSearchData={updateSearchData}
                                        searchHandler={searchHandler}
                                        setSearchData={setSearchData}
                                        statusData={statusData}
                                        setCompanyCode={setCompanyCode}
                                        term={term}
                                        groupCode={groupCode}
                                        companyCode={companyCode}
                                        academicCareer={academicCareer}
                                        courseType={courseType}
                                        label={label}
                                        placeholder={placeHolder}
                                    />
                                    <Row>
                                        <Col md={12}>
                                            <CustomButton
                                                className={'reset-button floatRight mx-1'}
                                                content={data.button.reset}
                                                type={'reset'}
                                                permissionType={'R'}
                                                permissionSet={userPermissions}
                                                onClick={() => resetHandler()}
                                            />
                                            <CustomButton
                                                className={'floatRight mx-1'}
                                                content={data.button.search}
                                                icon={true}
                                                permissionType={'S'}
                                                permissionSet={userPermissions}
                                                onClick={searchHandler}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            {
                                searching ?
                                    <div>
                                        <CustomLoader apiLoader={searching} loaderHeight={'200px'} loaderWidth={'100% '} />
                                    </div>
                                    :
                                    tableData && tableData.length > 0
                                        ?
                                        <>
                                            <ItemTypeSequenceSearchTable
                                                data={tableData}
                                                term={term}
                                                courseType={courseType}
                                                setData={setTableData}
                                                academicCareer={academicCareer}
                                                searchHeaderList={searchHeaderList}
                                                permissionSet={userPermissions}
                                            />

                                            {pagination.totalPage > 1
                                                ?
                                                <GetPagination
                                                    setclassArray={setTableData}
                                                    pagination={pagination}
                                                    setpagination={setPagination}
                                                    pageSize={pageSize}
                                                    state={allTableData}
                                                />
                                                :
                                                null}
                                        </>

                                        : null
                            }
                        </Container>
                    ) : (
                        <PermisionDenied />
                    )
                }
            </Fragment>
    )
}

export default ItemTypeSequenceSetup;