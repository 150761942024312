import React, { useState, useEffect,useMemo } from "react";
import OrganizationTypeTable from './organisationTable';
import { getAPI,failureToast } from 'services/http';
import { GetPagination } from '../../../common/pagination';

const OragnizationTable = () =>
{

  const [classArray, setclassArray] = useState([])
  const [apiloader, setapiloader] = useState(false)
  const [ state, setstate ] = useState( [] )
  const [ isUpdated, setIsUpdated ] = useState( false );
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null
  } )
  

  
  useEffect( () =>
  {
    console.log("Class Array is here ",classArray)
  },[isUpdated])

 

  const pageSize = 10
  const fetchClasses = async (url) => {
    setapiloader(true)
    getAPI(url, (data) => {
      setstate( data[ 'data' ] )
      let n = pagination
      if (data['data'].length > pageSize) {
        n['nextPage'] = n.currentPage + 1
      }
      if (n.currentPage > 1) {
        n.previousPage = n.currentPage - 1
      }
      n['totalPage'] = Math.floor(data['data'].length / pageSize)
      if (data['data'].length % pageSize != 0){
        n['totalPage'] += 1
      }
      setpagination(n)
      setapiloader( false );
      setclassArray(data['data'].slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || [])
    }, (data) => { failureToast(data['message']) })
  }

  
  const nextPage = () => {
    setclassArray(state.slice((pagination.currentPage + 1) * (pageSize) - pageSize, (pagination.currentPage + 1) * pageSize))
    let n = pagination
    n['currentPage'] = n['currentPage'] + 1
    n['previousPage'] = n.currentPage
    setpagination(n)
  }
  const firstPage = () => {
    setclassArray(state.slice(1 * (pageSize) - pageSize, 1 * pageSize))
    let n = pagination
    n['currentPage'] = 1
    n['previousPage'] = null
    setpagination(n)
  }
  const lastPage = () => {
    setclassArray(state.slice((pagination.totalPage) * (pageSize) - pageSize, (pagination.totalPage) * pageSize))
    let n = pagination
    n['currentPage'] = n.totalPage
    n['previousPage'] = n.totalPage-1
    setpagination(n)
  }
  const previousPage = () => {
    if(pagination.currentPage!=1){
    setclassArray(state.slice((pagination.currentPage - 1) * (pageSize) - pageSize, (pagination.currentPage - 1) * pageSize))
    // console.log(state.slice((pagination.currentPage - 1) * (pageSize) - pageSize, (pagination.currentPage - 1) * pageSize))
    let n = pagination
    n['currentPage'] = n['currentPage'] - 1
    if (n['currentPage'] == 1) {
      n['previousPage'] = null
    } else {
      n['previousPage'] = n.currentPage
    }
    setpagination(n)
  }
  }

  return (
    <React.Fragment>
      <OrganizationTypeTable state={ state } setstate={ setstate} setclassArray={ setclassArray } classArray={ classArray } fetchClasses={ fetchClasses } setIsUpdated={ setIsUpdated } isUpdated={ isUpdated}/>
      {pagination.totalPage > 1 ? <GetPagination fetchClasses={fetchClasses} pagination={pagination} previousPage={previousPage} firstPage={firstPage} lastPage={lastPage} nextPage={nextPage} setpagination={setpagination} /> : null}
   
    </React.Fragment>
  );
}

export default OragnizationTable;