import { Controller } from 'react-hook-form';
import {
  Button,
  Table,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  Col,
  InputGroup,
  Row,
  Label,
} from 'reactstrap';
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import Select from 'react-select';
import 'react-sliding-pane/dist/react-sliding-pane.css';
var _ = require('lodash');

const CustSelect = (props) => {
  const [selectedValue, setSelectedValue] = useState(0);
  const getOptionIndex = (x, array) => {
    if(array && array.length>0){
      for (var i = 0; i < array.length; i++) {
        if (array[i]['value'] == x) {
          return array[i];
        }
      }
    }
  };
  return (
    <>
      <div key={props.name}>
        <Controller
          control={props.controlName}
          name={props.name}
          rules={{ required: props.required }}
          defaultValue={props.defaultValue ? props.defaultValue : null}
          render={({ onChange, onBlur, value, name }) => (
            <div>
              <label className="form-control-label">{props.label}</label>
              <Select
                isMulti={props.singleValue ? false : true}
                isDisabled={props.isDisabled || false}
                classNamePrefix={
                  props.error && props.error[props.name]?.type === 'required'
                    ? 'select2-error custom_select'
                    : 'custom_select'
                }
                options={props.data}
                defaultValue={getOptionIndex(props.defaultValue, props.data)}
                onChange={(e) => {
                  if (props.singleValue) {
                    onChange(e.value);
                  } else {
                    let n;
                    if (e) {
                      n = e.map((e) => JSON.stringify(e.value));
                    } else {
                      n = null;
                    }
                    onChange(n);
                  }
                }}
                data-minimum-results-for-search={props.search ? '' : 'Infinity'}
                className="form-control m-0 p-0"
              />
              {props.error
                ? _.get(props.error, props.name + '.type') === 'required' && (
                    <div
                      className="alert-danger-custom m-0 pt-1 px-0"
                      style={{ color: 'red' }}
                    >
                      Required
                    </div>
                  )
                : null}
            </div>
          )}
        />
      </div>
    </>
  );
};
const MultiSelect = (props) => {
  const { setBucketsData } = props;

  const getOptionIndex = (x, array) => {
    var data=[]
    for (var i = 0; i < array.length; i++) {
        if (x!=null && x.includes(array[i]['value'])) {
           data.push(array[i])
        }
    }
    return data
  };
  var errorClass=''
  if(props.error && props.error[props.name]?.type === "required"){
    errorClass='select2-error'
  }
  if(props.isNonEmpty && props.defaultValue){
    errorClass="custom-select-filled-change-border"
  }
  var optionDisabled=(props.optionDisabled!=undefined)?props.optionDisabled:[]
return (<>
  <div key={props.name}>
    <Controller
      control={props.controlName}
      name={props.name}
      rules={{ required: props.required }}
      defaultValue={props.defaultValue ? props.defaultValue : null}
      render={({ onChange, onBlur, value, name }) => (
        <div>
          {props.label ?
              (!props.infoIcon?
              <label className="form-control-label">
                {/* {props.label}
                {props.required && <span className=""> *</span>} */}
                {props.required ? <span>{props.label}<span style={{color:'red'}}>*</span></span> : props.label}
              </label>:
              <label className="form-control-label">
              {/* {props.label }
              {props.required && <span className=""> *</span>} */}
              {props.required ? <span>{props.label}<span style={{color:'red'}}>*</span></span> : props.label}
              <Button onClick={(e)=>{e.preventDefault()}} className='hoverButton' data-tooltip={props.toolTipData} style={{
                'padding': '0 6px',
                'border': '1px solid gray',
                'borderRadius': '8px',
                'box-shadow':'none',
                'margin': '0px 5px 3px',
                'fontSize': '10px'
              }}>i</Button>
            </label>)
              : ""}




          {/* <label className="form-control-label">
            {props.label} 
            {props.required && <span className=""> *</span>}
          </label> */}
          <Select
            isDisabled={props.isDisabled || false}
            isMulti={props.isMulti || false}
            classNamePrefix={(props.error && props.error[props.name]?.type === "required") ?
              "select2-error custom_select" : "custom_select"}
            options={props.data}
            defaultValue={getOptionIndex(props.defaultValue, props.data) || null}
            value={getOptionIndex((value != null? ((value.length>0)?value:null) : []), props.data) || null}
            onChange={(e) => {
              if(setBucketsData){
                let arr = e?.map((ele) => ele.value);
                if(arr) {
                  setBucketsData([...arr])
                } else{
                  setBucketsData([])
                }
              } 
              var data = [];
              if (e != null) {
                e.map((item) => {
                  data.push(item.value)
                })
              }
              if (optionDisabled.length > 0) {
                let difference = []
                if(props.defaultValue!=undefined || props.defaultValue!=null){
                  difference = props.defaultValue.filter(x => !data.includes(x)); // calculates diff
                }
                difference.map((item) => {
                  if (!optionDisabled.includes(item.toString())) {
                    data.push(item)
                  }
                })
              }
              onChange((data.length>0)?data:null);
            }}
            data-minimum-results-for-search="Infinity"
            className={`${props.className} ${"form-control m-0 p-0 autoHeight"}  ${errorClass}`}
          />
         
          {props.error ? props.error[props.name]?.type === "required" &&
            (<div className="alert-danger-custom m-0 pt-1 px-0">{props.error[props.name].message} Required</div>) : null}
        </div>
      )}
    />
  </div>
</>)
}
const ControlledSimpleInput = (props) => {
  return (
    <>
      <Controller
        control={props.controlName}
        name={props.name}
        rules={{
          required: props.required || false,
          min: props.min || null,
          max: props.max || null,
          maxLength: props.maxLength || null,
          minLength: props.minLength || null,
        }}
        defaultValue={props.defaultValue || null}
        render={({ onChange, onBlur, value, name }) => (
          <div>
            <FormGroup className="mb-3" key={props.name}>
              <label
                className="form-control-label"
                htmlFor="exampleFormControlSelect1"
              >
                {props.title}
              </label>
              <InputGroup
                className={classnames('input-group-merge', { focused: false })}
              >
                <Input
                  invalid={
                    props.error &&
                    props.error[props.name]?.type === 'required' &&
                    true
                  }
                  defaultValue={props.defaultValue}
                  placeholder={props.placeholder}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                />
              </InputGroup>
              {props.error
                ? props.error[props.name]?.type === 'required' && (
                    <div className="alert-danger-custom m-0 pt-1 px-0">
                      Required
                    </div>
                  )
                : null}
            </FormGroup>
          </div>
        )}
      />
    </>
  );
};

export { CustSelect, ControlledSimpleInput, MultiSelect };
