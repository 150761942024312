import React , {useState} from "react";
import { Row, Col } from "reactstrap";
import Popover from '@mui/material/Popover';

import TestContentFormData from "./TestContentFormData";
import { ReactComponent as EditIcon } from "../../../../../assets/img/svg/Edit2.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/img/svg/Delete.svg";
import { actionStyle } from "./style";
import MouseOverPopover from './PopOver';
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper";

export const ListItem = ({ item, subject, formModal, ...props }) => {

  const [anchorEl, setAnchorEl] = React.useState(false);
  const [popOverContent, setPopOverContent] =  useState([])
  
  const handlePopoverOpen = (event, data) => {
    setAnchorEl(event.currentTarget);
    setPopOverContent(data)

  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopOverContent([])
  };

  const open = Boolean(anchorEl) ;

  const getDisplayLabel = (elements) => {
    let labels = [];
    elements.map((el) => {
      labels.push(el.label);
    });
    if (labels.length > 2) {
      let value = labels.slice(0, 2).join(",");
      let count = labels.length - 2;
      return `${value} ... ${count}`;
    } else {
      return labels?.join(",");
    }
  };
  const  getMore = (items)=>{
        if(items && items.length>2){
          return " More"
        }
  }

  const updateFinalFormData = (flag = true) => {
    let currentData = { ...formModal };
    currentData[subject][props.currentIndex] = {
      ...currentData[subject][props.currentIndex],
      isEditMode: flag,
    };
    props.setFormModal(currentData);
  };

  const editHandler = () => {
    updateFinalFormData(true);
  };

  const saveAfterEdit = () => {
    updateFinalFormData(false);
  };

  const deleteHandler = () => {
    let currentData = { ...formModal };
    let updatedList = [];

    if (currentData[subject]) {
      updatedList = currentData[subject].filter(
        (el, i) => i !== props.currentIndex
      );
    }
    currentData[subject] = updatedList;
    props.setFormModal(currentData);
  };

  if (item?.isEditMode) {
    return (
      <TestContentFormData
        currentForm={props.currentForm}
        dropDown={props.dropDown}
        formModal={formModal}
        subject={subject}
        setFormModal={props.setFormModal}
        currentIndex={props.currentIndex}
        saveChapterHandler={saveAfterEdit}
      />
    );
  }

  return (
    <Row className="mb-1 global-font-size">
      <Col md={3}>{item?.chapter?.label}</Col>
      <Col md={4}>{getDisplayLabel(item?.topic)}
      <span
          style={{
            color: " #00B0F5",
            cursor: "pointer",
          }}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={(e)=>handlePopoverOpen(e,item?.topic)}
          onMouseLeave={handlePopoverClose}
          
        >
         
         {getMore(item?.topic)}
        </span>
      </Col>
      <Col md={4}>
        {getDisplayLabel(item?.subTopic)}{" "}
        <span
          style={{
            color: " #00B0F5",
            cursor: "pointer",
          }}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={(e)=>handlePopoverOpen(e,item?.subTopic)}
          onMouseLeave={handlePopoverClose}
       
          // onMouseLeave={handlePopoverClose}
        >
         
         {getMore(item?.subTopic)}
        </span>
      </Col>
      <Col md={1} style={actionStyle}>
        <IconButtonWrapper onClick={editHandler}><EditIcon /></IconButtonWrapper>
        <IconButtonWrapper onClick={deleteHandler}><DeleteIcon /></IconButtonWrapper>
      </Col>
      <MouseOverPopover 
        handlePopoverOpen = {handlePopoverOpen}
        handlePopoverClose = {handlePopoverClose}
        anchorEl = {anchorEl}
        popOverContent = {popOverContent}
        open = {open}
      />
     
    </Row>
  );
};
