import { Dialog } from "@mui/material";
import React from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { ReactComponent as CrossIcon } from "../../../../../../assets/img/svg/CrossIcon.svg";
// import { outlineButtonStyle } from "./style";
import AButton from './../../../common/form-fields-mui/AButton';



const ActiveConfirmation = ({
  open = false,
  setOpen,
  onSaveAgree = () => { },
  counter,
  isDeactivate
}) => {
  const handleClose = () => {
    setOpen(false);
    //onCancel();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={{ padding: "1.5rem", width: "500px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "2rem",
            marginBottom: "10px",
            color: "black",
          }}
        >
          <AiOutlineQuestionCircle />
          {/* <MdCancel onClick={handleClose} /> */}
          <CrossIcon onClick={handleClose} />
        </div>


        <div>
          <h4 className="heading-3 color-dark">
            Are you sure you want to {isDeactivate?'deactivate':"activate"} "Lecture {counter}"?
          </h4>

          {isDeactivate ? (
            <span>"Lecture {counter}" will not be visible to any branch </span>
          ) : (
            <span>"Lecture {counter}" will be visible to branches </span>
          )}
        </div>

        <div className="d-flex align-items-center justify-content-between" style={{ marginTop: '28px' }}>
          <AButton
            onClick={() => setOpen(false)}
            className="button_remove_left_margin"
            variant="outline"
            updatedStyle={{ width: '180px' }}
          >
            No
          </AButton>
          {isDeactivate ? (
            <AButton
              onClick={() => { onSaveAgree(); setOpen(false) }}
              className="button_remove_left_margin"
              variant="danger_filled"
              updatedStyle={{ background: "#CC5F5F", width: '180px' }}
            >
              Yes, Deactivate
            </AButton>
          ) : (
            <AButton
              onClick={() => { onSaveAgree(); setOpen(false) }}
              className="button_remove_left_margin"
              variant="primary_filled"
              updatedStyle={{ width: '180px' }}
            >
              Activate
            </AButton>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default ActiveConfirmation;
