import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Card, CardHeader, Col, Row, Table, Button } from 'reactstrap';
import { MasterHeaderAuto } from '../common/commonComponents/masterHeader/masterHeader';
import AddNewModel from '../common/commonComponents/NewRowModal';
import CommonInput from '../common/formFeilds/input/commonInput';
import CustmCheckbox from '../common/formFeilds/customCheckbox';
import { fetchAllPostPromisedData, getUniqueKey, NewRow } from '../common/utils/methods/commonMethods/utilityMethod';
import CustomButton from 'components/CustomButton';
import { fetchAllPromisedData } from '../common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../common/constant';
import { failureToast, successToast } from '../common/utils/methods/toasterFunctions/function';
import CustomLoader from '../common/commonComponents/Loader/loader';
import { ConfirmationDialog } from '../common/commonComponents';
import styled from 'styled-components';
import CustomInput from '../common/formFeilds/input/commonInput';


const Tab = styled.div`
    background-color: ${props => props.active ? "#00aeef" : "#fafafa"};
    border:  ${props => props.active ? "1px solid #00aeef" : "1px solid #a1aeb7 !important"};
    height : 100%;
    padding : 10px 10px 0;
    border-radius: 5px;
    margin-right : 10px;
    cursor: pointer;
`

var _ = require("lodash");

let headerList = [
    { name: "Process Name", colSpan: "2" },
    { name: "Sub Process Name", colSpan: "2" },
    { name: "Create" },
    { name: "Read" },
    { name: "Update" },
    { name: "Delete" },
    { name: "Approve" },
    { name: "Test" },
    { name: 'Action' }
]

const TableRow = ({ item,
    index,
    onChange,
    processTypeData,
    disabled,
    permissions,
    dataObject,
    permissionData,
    isView,
    setDataObjects = () => { },
    setPermissionData = () => { },
    setAnyChanges = () => { }
}) => {

    const [subProcess, setSubProcess] = useState([])
    const [fetchingSubProcess, setFetchingSubProcess] = useState(false)
    const [confirmationDialog, setConfirmationDialog] = useState(false)


    const getSubProcess = async () => {
        if (dataObject?.[item?.processName?.value]) {
            return
        }
        setFetchingSubProcess(true)
        const subprocess = await fetchAllPromisedData(`${endpoint.screen.getById}/${item?.processName?.value}`)
        if (Array.isArray(subprocess)) {
            const tempArr = subprocess.map(sp => {
                return { value: sp.id, label: sp.screenName }
            })

            setSubProcess(tempArr)
            setDataObjects({ ...dataObject, [item?.processName?.value]: tempArr })
            setFetchingSubProcess(false)
        } else {
            setFetchingSubProcess(false)
        }
    }

    useEffect(() => {
        if (item?.processName && item?.processName?.value !== 'undefined') {
            getSubProcess()
        }
    }, [item?.processName])


    const deleteRow = () => {
        let tempData = [...permissionData];
        let tempObj = tempData[index];
        tempObj['action'] = 'DELETE'
        tempData[index] = tempObj;
        setPermissionData(tempData);
        setAnyChanges(true)
        // if(item.processName?.value && item.subprocessName?.value){
        //     const tempArr = [...dataObject[item?.processName?.value],item.subprocessName] 
        //     setDataObjects({ ...dataObject, [item?.processName?.value]: tempArr })
        // }
    }


    const removeRow = () => {
        let tempData = [...permissionData];
        if (item.processName?.value && item.subprocessName?.value) {
            const tempArr = [...dataObject[item?.processName?.value], item.subprocessName]
            setDataObjects({ ...dataObject, [item?.processName?.value]: tempArr })
        }
        tempData.splice(index, 1)
        setPermissionData(tempData);
    }

    return <tr>
        <td className="text-center  white-breakSpace" colSpan={2}>
            <CommonInput
                key={`${index}-${getUniqueKey()}`}
                type="select"
                placeHolder={'Select process'}
                data={processTypeData?.data}
                onChange={(val) => { onChange(index, 'processName', val) }}
                defaultValue={item.processName}
                isDisabled={disabled || !item.isNew}
            />
        </td>
        <td className="text-center  white-breakSpace" colSpan={2}>
            <CommonInput
                type="select"
                placeHolder={'Select sub process'}
                data={dataObject[item?.processName?.value]}
                onChange={(val) => { onChange(index, 'subprocessName', val) }}
                defaultValue={item.subprocessName}
                loading={fetchingSubProcess}
                isDisabled={!item.processName?.value || disabled || !item.isNew}
            />
        </td>
        {permissions.map((el, i) => {
            const isDisable = disabled || !item.processName?.value || !item.subprocessName?.value || el?.name?.toString().toLowerCase() === 'read';
            return <td className="text-center  white-breakSpace" style={{ verticalAlign: 'baseline', paddingTop: '30px' }}>
                <CustmCheckbox onClick={!isDisable ? () => { onChange(index, 'permissions', el.orgIndex + 1) } : () => { }} onChangeHandler={() => { }} defaultChecked={item.permissions?.indexOf(el.orgIndex + 1) > -1} isDisabled={isDisable} />
            </td>
        })}
        {!isView && <td className="text-center  white-breakSpace">
            {item.isNew ?
                <Button color="danger" style={{ padding: '0', width: '30px', height: '30px' }} onClick={removeRow} disabled={disabled}><i className="fas fa-times" /></Button>
                :
                <Button color="danger" style={{ padding: '0', width: '30px', height: '30px' }} onClick={() => setConfirmationDialog(true)} disabled={disabled}><i className="fas fa-trash" /></Button>}
        </td>}
        <ConfirmationDialog isOpen={confirmationDialog} onAgree={() => { deleteRow() }} setIsOpen={setConfirmationDialog} msg="Are you sure to delete the row from Role permissions? This action will remove permission of this screen from the role completely." />
    </tr>
}

const Permission = ({
    userPermissions,
    roleData,
    savedPermission,
    action,
    previewOnly,
    editPermission,
    setEditPermission = () => { },
    editHeader
}) => {

    const history = useHistory();
    const [processTypeData, setProcessTypeData] = useState({ fatching: true, data: [] });
    const [subProcessData, setSubProcessData] = useState({})
    const [permissionData, setPermissionData] = useState([{
        processName: '',
        subprocessName: '',
        permissions: [],
        isNew: true
    }]);
    const [isAddNewModelVisible, setIsAddNewModelVisible] = useState(false);
    const [num, setNum] = useState(1);
    const [submitting, setSubmitting] = useState(false);
    const [fetchingPermission, setFetchingPermission] = useState(true);
    const [permissions, setPermissions] = useState([
        { name: "Create" },
        { name: "Read" },
        { name: "Update" },
        { name: "Delete" },
        { name: "Approve" },
        { name: "Test" }
    ])

    const [dataObjects, setDataObjects] = useState({})
    const [confirmationDialog, setConfirmationDialog] = useState(false)
    const [anyChanges, setAnyChanges] = useState(false)
    const [isFetching, setFetching] = useState(false)
    const [activeTab, setActiveTab] = useState(0);
    const [fetchingRoles, setFetchingRoles] = useState(false);
    const [rolesData, setRolesData] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState({ 1: '', 2: '', 3: '' })
    const [tabConfirmation, setTabConfirmation] = useState(false);
    const [confirmationSucess, setConfirmationSucess] = useState(false)


    const addNewRow = () => {
        if (num > 0 && num <= 20) {
            let newRows = []
            for (let i = 0; i < num; i++) {
                newRows.push({
                    processName: '',
                    subprocessName: '',
                    permissions: [],
                    isNew: true
                });
            }
            let tempArray = [...newRows,...permissionData];
            setPermissionData([...tempArray])
            setIsAddNewModelVisible(false)
            setNum(1)
        } else if (num == 0) {
            failureToast("You can not enter 0 rows.")
        } else {
            failureToast("You can add maximum 20 rows in one go.")
        }
    }

    const getProcessData = async () => {
        const processData = await fetchAllPromisedData(endpoint.process_Tag.getAll);
        if (Array.isArray(processData)) {
            const tempArr = processData?.map(d => { return { label: d.tagTypeDispValue, value: d.id } })
            setProcessTypeData({ fetching: false, data: tempArr })
        } else {
            return { fetching: false, data: [] }
        }
    }

    const getAllPermission = async () => {
        const permission = await fetchAllPromisedData(endpoint.permission.getAllActive);
        if (Array.isArray(permission)) {
            const tempArr = permission?.map((d, i) => { return { name: d.permissionDispValue, sequenceNumber: d.sequenceNumber, orgIndex: i} })
            const sequencedArr = tempArr.sort((a,b) => a.sequenceNumber - b.sequenceNumber);
            if (action === 'view') {
                headerList = [
                    { name: "Process Name", colSpan: "2" },
                    { name: "Sub Process Name", colSpan: "2" },
                    ...sequencedArr,
                ]
            } else {
                headerList = [
                    { name: "Process Name", colSpan: "2" },
                    { name: "Sub Process Name", colSpan: "2" },
                    ...sequencedArr,
                    { name: 'Action' }
                ]
            }
            setPermissions(sequencedArr)
            setFetchingPermission(false)
        } else {
            setFetchingPermission(false)
        }
    }

    const getRolesData = async () => {
        const roles = await fetchAllPromisedData(`${endpoint.getRole.getAllRoleOnProject}/1`);
        if (Array.isArray(roles)) {
            const tempArr = roles.map(d => { return { label: d.roleName, value: d.id } })
            setRolesData(tempArr)
            setFetchingRoles(false)
        }
    }

    useEffect(() => {
        getProcessData();
    }, [])


    useEffect(() => {
        if (activeTab === 1 && !roleData?.length) {
            getRolesData();
            setFetchingRoles(true)
        }
    }, [activeTab])

    useEffect(() => {
        if (action) {
            getAllPermission();
        }
    }, [action])

    const changeHandler = (index, key, value) => {
        let tempData = [...permissionData];
        let tempObj = tempData[index];
        if (key === 'permissions') {
            if (value === 2) {
                return
            }
            if (tempObj['permissions'].indexOf(value) > -1) {
                tempObj['permissions'].splice(tempObj['permissions'].indexOf(value), 1)
            } else {
                if (value !== 2) {
                    tempObj['permissions'].push(value)
                }
            }
        } else if (key === 'subprocessName') {
            let tempSubArr = dataObjects[tempObj?.processName?.value]
            const i = tempSubArr.findIndex(item => item.value == value.value)

            if (i > -1) {
                tempSubArr.splice(i, 1)
            }
            if (tempObj['subprocessName'] !== '') {
                tempSubArr = [...tempSubArr, tempObj?.subprocessName]
            }
            setDataObjects({ ...dataObjects, [tempObj?.processName?.value]: tempSubArr })
            tempObj[key] = value;
            tempObj['permissions'] = [2]
        } else {
            // alert(value)
            if (tempObj['subprocessName'] !== '') {
                let tempSubArr = dataObjects[tempObj?.processName?.value]
                const i = tempSubArr.findIndex(item => item.value == tempObj['subprocessName'].value)

                if (i > -1) {
                    tempSubArr.splice(i, 1)
                }
                if (tempObj['subprocessName'] !== '') {
                    tempSubArr = [...tempSubArr, tempObj?.subprocessName]
                }
                setDataObjects({ ...dataObjects, [tempObj?.processName?.value]: tempSubArr })
            }
            tempObj[key] = value;
            tempObj['subprocessName'] = '';
            tempObj['permissions'] = []
        }

        if (!tempObj['isNew']) {
            tempObj['action'] = 'UPDATE'
        }

        tempData[index] = tempObj;
        setPermissionData(tempData);
        setAnyChanges(true)
    }


    const submitHandler = async () => {
        let valid = true;
        let requestObject = {};
        if (activeTab !== 1) {
            const subProcessIds = []
            permissionData.every((item, ind) => {

                if (subProcessIds.indexOf(item.subprocessName?.value) > -1) {
                    failureToast('Every row should have unique subprocess')
                    valid = false
                    return false
                } else if (!item.processName?.value && (!item?.action || item?.action !== 'DELETE')) {
                    failureToast(`Please select process for row ${ind + 1}`)
                    valid = false
                    return false
                } else if (!item.subprocessName?.value && (!item?.action || item?.action !== 'DELETE')) {
                    failureToast(`Please select sub process name for row ${ind + 1}`)
                    valid = false
                    return false
                } else if (!item.permissions.length && (!item?.action || item?.action !== 'DELETE')) {
                    failureToast(`Please provide atleast one permission for row ${ind + 1}`)
                    valid = false
                    return false
                }
                else {
                    if (!item?.action || item?.action !== 'DELETE') {
                        subProcessIds.push(item.subprocessName?.value)
                    }
                    valid = true
                    return true
                }
            })

            const screenPermissionsList = permissionData.map(item => {
                if (item.isNew) {
                    return {
                        processId: item.processName?.value,
                        screenId: item.subprocessName?.value,
                        permissionsIdList: item.permissions
                    }
                }

            })

            const existingScreenAndPermissionsList = permissionData.map(item => {
                if (!item.isNew && item.action) {
                    return {
                        processId: item.processName?.value,
                        screenId: item.subprocessName?.value,
                        permissionsIdList: item.permissions,
                        action: item?.action
                    }
                }

            })

            requestObject = {
                ...roleData,
                screenPermissionsList: screenPermissionsList.filter(item => item),
                existingScreenAndPermissionsList: existingScreenAndPermissionsList.filter(item => item)
            }
        } else {
            if (!selectedRoles?.[1]?.value) {
                failureToast('Please Select Role Name 1')
                valid = false
                return false
            }
            requestObject = {
                ...roleData,
                fromExistingRoles: Object.keys(selectedRoles).map(item => {
                    if (selectedRoles[item]?.value) {
                        return {
                            roleId: selectedRoles[item]?.value
                        }
                    }
                })?.filter(item => item)
            }
        }

        if (!valid) {
            return
        }
        setSubmitting(true)

        const url = action === 'edit' ? endpoint.roleScreen.update : activeTab === 0 ? endpoint.roleScreen.create : endpoint.roleScreen.merge
        const method = 'post'

        const response = await fetchAllPostPromisedData(url, requestObject, method)
        if (response?.code === 200) {
            setSubmitting(false)
            
            if(activeTab == 0){
                successToast(response['message'])
                history.push('/admin/userRolePage')
            }

            if(activeTab == 1){
                setConfirmationSucess(true)
            }
        } else {
            setSubmitting(false)
            // failureToast(response?.['message']);
        }

    }

    const reset = () => {
        history.push('/admin/userRolePage')
    }


    const getSelectedValue = (list, id) => {
        // console.log(list)
        const matchedValue = list && list.filter(item => item.value === id)
        return matchedValue?.length ? matchedValue?.[0] : ''
    }

    const permissionForEditAndView = async (arr) => {
        const _tempArr = [...arr];
        let idMaps = {}
        const listOfSubProcess = Promise.all(_tempArr.map(async (item, i) => {
            if (item?.processId && !idMaps?.[item?.processId]) {
                idMaps = { ...idMaps, [item?.processId]: i + 1 }
                const subprocess = await fetchAllPromisedData(`${endpoint.screen.getById}/${item?.processId}`);
                let tempArr = []
                if (Array.isArray(subprocess)) {
                    tempArr = subprocess.map(sp => {
                        return { value: sp.id, label: sp.screenName }
                    })
                }
                return tempArr
            } else {
                return []
            }
        }))

        const s = await listOfSubProcess
        let dropdownIds = {}
        let pl = arr.map(item => {
            if (item?.processId) {
                dropdownIds = { ...dropdownIds, [item.processId]: dropdownIds[item?.processId] ? [...dropdownIds[item?.processId], item.screenId] : [item.screenId] }
                return {
                    processName: getSelectedValue(processTypeData?.data, item.processId),
                    subprocessName: getSelectedValue(s?.[idMaps?.[item.processId] - 1] ?? [], item.screenId),
                    permissions: item?.permissionsList ?? [],
                    isNew: false
                }
            }
        })

        let tempDataObjects = {};
        Object.keys(idMaps).forEach(el => {
            tempDataObjects = { ...tempDataObjects, [el]: s?.[idMaps?.[el] - 1]?.filter(item => dropdownIds?.[el]?.indexOf(item.value) == -1) }
        })
        pl = pl.filter(item => item)
        setPermissionData([...pl])
        setDataObjects(tempDataObjects)
        setFetching(false)
    }

    useEffect(() => {
        if ((action === 'view' || action === 'edit') && savedPermission && !processTypeData?.fatching) {
            permissionForEditAndView(savedPermission)
            setFetching(true)
        }
    }, [action, savedPermission, processTypeData])


    const setSelectedRoleValues = (key, val) => {
        const value = val !== null ? val : '';

        if (val) {
            const index = rolesData.findIndex(item => item.value === val.value)
            let tempRoles = [...rolesData];
            if (index > -1) { tempRoles.splice(index, 1) }
            setRolesData([...tempRoles])
        }

        if (key == 1) {
            setSelectedRoles({ ...selectedRoles, [key]: value })
        }

        if (key == 2) {

            if (selectedRoles?.[key]?.value) {
                setRolesData([...rolesData, selectedRoles?.[2]])
            }

            if (!val) {
                if (selectedRoles?.[3]?.value) {
                    setRolesData([...rolesData, selectedRoles?.[3]])
                }
                setSelectedRoles({ ...selectedRoles, [key]: value, 3: '' })
            } else {
                setSelectedRoles({ ...selectedRoles, [key]: value })
            }
        }

        if (key == 3) {
            if (selectedRoles?.[key]?.value) {
                setRolesData([...rolesData, selectedRoles?.[3]])
            }

            setSelectedRoles({ ...selectedRoles, [key]: value })
        }
    }

    const resetTabItems = () => {
        setSelectedRoles({ 1: '', 2: '', 3: '' });
        setPermissionData([{
            processName: '',
            subprocessName: '',
            permissions: [],
            isNew: true
        }])
        setTabConfirmation(false)
    }

    const changeTab = (tabIndex) => {
        if (tabIndex == 1) {
            if (anyChanges) {
                setTabConfirmation(true)
            } else {
                resetTabItems()
                setActiveTab(tabIndex)
            }
        }
        if (tabIndex == 0) {
            if (selectedRoles?.[1]?.value) {
                setTabConfirmation(true)
            } else {
                resetTabItems()
                setActiveTab(tabIndex)
            }
        }
    }

    return (
        fetchingPermission || isFetching ? (
            <div className="col">
                <Card className=" w-100">
                    <div
                        className="mx-auto text-center py-5 my-5 "
                        style={{ height: '100vh' }}
                    >
                        <CustomLoader apiLoader={fetchingPermission || isFetching} />
                    </div>
                </Card>
            </div>
        ) : (
            <>
                <Card className=" w-100 mt-4">
                    <CardHeader>
                        {action === 'new' && <div className='d-flex flex-row permission-tabs'>
                            <Tab onClick={() => changeTab(0)} active={activeTab === 0}><h3 className={activeTab === 0 ? 'tab-active' : 'tab-inactive'}>Role Permissions</h3></Tab>
                            <Tab onClick={() => changeTab(1)} active={activeTab === 1}><h3 className={activeTab === 1 ? 'tab-active' : 'tab-inactive'}>Role Merge</h3></Tab>
                        </div>}
                        {
                            action !== 'new' && <h3>Role Permissions</h3>
                        }
                        <div className='d-flex flex-row-reverse gap-2'>
                        {(!previewOnly && (editPermission || action === 'new')) && <CustomButton
                        content={submitting ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : "Save"}
                        permissionType={'C'}
                        permissionSet={userPermissions}
                        onClick={() => { submitHandler() }}
                    />}
                    {!previewOnly && !editPermission && action !== 'new' &&
                        <CustomButton
                            content={'Edit'}
                            permissionType={'U'}
                            permissionSet={userPermissions}
                            onClick={editHeader ? () => { failureToast("Please save unsaved data first") } : () => { setEditPermission(true) }}
                        />
                    }

                        {!previewOnly && activeTab === 0 && <CustomButton
                            className={'floatRight mx-1'}
                            content={activeTab === 0 ? "Add Row" : "Add Role"}
                            permissionType={'C'}
                            icon={true}
                            onClick={() => setIsAddNewModelVisible(true)}
                            // onClick={()=>{}}
                            permissionSet={userPermissions}
                            disabled={previewOnly || (!editPermission && action !== 'new')}
                        />}
                        </div>
                    </CardHeader>
                    {activeTab === 0 && <div>
                        <Table className="align-items-center tableLayout">
                            <MasterHeaderAuto headerList={headerList} permissionSet={userPermissions} isSaveVisible={false} />
                            <tbody className="list" key={'table-message-template'}>
                                {permissionData.map((el, i) => {
                                    if (!el?.action || el?.action !== 'DELETE') {
                                        return <TableRow
                                            item={el}
                                            index={i}
                                            onChange={changeHandler}
                                            processTypeData={processTypeData}
                                            permissionData={permissionData}
                                            setPermissionData={setPermissionData}
                                            permissions={permissions}
                                            dataObject={dataObjects}
                                            setDataObjects={setDataObjects}
                                            disabled={previewOnly || (!editPermission && action !== 'new')}
                                            setAnyChanges={setAnyChanges}
                                            isView={action === 'view'}
                                        />
                                    }
                                })}
                            </tbody>
                        </Table>
                    </div>}
                    {activeTab === 1 && <div>
                        {fetchingRoles && <div
                            className="mx-auto text-center py-5 my-5 "
                            style={{ height: '100vh' }}
                        >
                            <CustomLoader apiLoader={fetchingRoles} />
                        </div>}

                        <Row className="p-4">
                            {Object.keys(selectedRoles).map(item => {
                                return <Col sm={12} md={3}>
                                    <CustomInput
                                        defaultValue={selectedRoles?.[item] ?? ''}
                                        clearable={Number(item) !== 1}
                                        isDisabled={!selectedRoles[1]?.value && Number(item) !== 1}
                                        label={`Role Name ${item}`}
                                        mandatory={item == 1 ? true : false}
                                        data={rolesData}
                                        type="select"
                                        onChange={(sopt) => { setSelectedRoleValues(item, sopt) }} />
                                </Col>
                            })}
                        </Row>
                    </div>}
                </Card>
                <Row className="justify-content-end mr-2 mb-4">
                    {<CustomButton
                        content={'Close'}
                        type={'close'}
                        permissionType={'R'}
                        permissionSet={userPermissions}
                        onClick={anyChanges || selectedRoles[1]?.value ? () => { setConfirmationDialog(true) } : () => history.push('/admin/userRolePage')}
                    />}
                </Row>
                <AddNewModel num={num} setNum={setNum} isVisible={isAddNewModelVisible} addNewRow={addNewRow} setIsVisible={setIsAddNewModelVisible} onSubmit={addNewRow} />
                <ConfirmationDialog isOpen={confirmationDialog} onAgree={reset} setIsOpen={setConfirmationDialog} />
                <ConfirmationDialog isOpen={tabConfirmation} onAgree={() => { resetTabItems(); setActiveTab(activeTab == 0 ? 1 : 0) }} setIsOpen={setTabConfirmation} />
                <ConfirmationDialog
                    isOpen={confirmationSucess}
                    onAgree={() => { setActiveTab(0); setConfirmationSucess(false);setFetching(true); history.replace(`/admin/role/edit/${roleData.roleId}`) }}
                    onDisagree={() => history.push('/admin/userRolePage')}
                    setIsOpen={setConfirmationSucess}
                    headerMsg={'Success'}
                    msg={'Role Merge is done. Do you wish to further edit the combined permissions?'}
                    popupSymbol={<img src={require("../../../../assets/img/svg/resetSuccess.svg")} className="m-5 p-2" width={'100%'} />}
                />
            </>)
    )
}

export default Permission