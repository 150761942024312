import { regex } from 'views/pages/manage/common/constant';
import { fetchAllPostPromisedData } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../../common/constant';
import { updateRequest } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import moment from 'moment';
import { transactionTypeMap } from '../stockReturn/constant';

export const checkedOptionWidthMap = {
  transactionId: '800px',
  otherDetails: '1100px',
};

export const checkboxOptions = (
  checkedOption,
  handleCheckboxChange = () => {}
) => [
  {
    title: 'Search by Transaction ID',
    onChange: e => {
      e.target.checked && handleCheckboxChange('transactionId');
    },
    checked: checkedOption === 'transactionId',
  },
  {
    title: 'Search by other details',
    onChange: e => {
      e.target.checked && handleCheckboxChange('otherDetails');
    },
    checked: checkedOption === 'otherDetails',
  },
];

export const searchInitialForm = academicCareer => ({
  businessArea: '',
  term: '',
  courseId: '',
  batchId: '',
  issuanceDateFrom: moment().subtract(1, 'month'),
  issuanceDateTo: moment(),
  transactionId: '',
  type: transactionTypeMap['PSID_ISSUANCE'][academicCareer],
  groupBy: 'Transaction',
  status: 'SUCCESS',
});

export const inputMap = {
  transactionId: { label: 'Transaction ID', regex: regex.stockIssueTnxId },
};

export const selectStyles = {
  control: {
    height: '40px',
    borderRadius: '4px',
  },
};

export const fetchStockTransactions = async (page = 0, reqBody) => {
  const updatedRequest = updateRequest({
    ...reqBody,
    issuanceDateTo: reqBody?.issuanceDateTo ? moment(reqBody?.issuanceDateTo)?.endOf('day') : ''
  })
  try {
    const res = await fetchAllPostPromisedData(
      `${endpoint.studentSearch.searchStockTransactions}?offset=${page}`,
      updatedRequest,
      'post',
      false
    );
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const customChipStyles = { height: '28px' };

const getReversalEligibility = data => {
  for(let applicationData of data) {
    for(let stock of applicationData?.materialDetails) {
      if(stock?.returnTransactionId && stock?.returnTransactionId?.includes('R')) {
        return false;
      }
    }
  }

  return true;
}
const getReversalStatus = data => {
  for(let applicationData of data) {
    for(let stock of applicationData?.materialDetails) {
      if(!stock?.returnTransactionId || !stock?.returnTransactionId?.includes('SR')) {
        return false;
      }
    }
  }

  return true;
}

export const restructureResponse = (data, academicCareer) => {
  return {
    academicCareer: academicCareer,
    issueDate: data?.[0]?.issueDate,
    businessArea: data?.[0]?.businessArea,
    businessAreaValue: data?.[0]?.businessAreaValue,
    createdBy: data?.[0]?.createdBy,
    createdOn: data?.[0]?.createdOn,
    sapDocId: data?.[0]?.sapDocId,
    status: data?.[0]?.status,
    plantId: data?.[0]?.materialDetails?.[0]?.plantId,
    costCenter: data?.[0]?.materialDetails?.[0]?.costCenter,
    applicationDetails: data,
    isEligibleForReversal: getReversalEligibility(data),
    isReversed: getReversalStatus(data),
  };
};

export const reversalReasonsDropdown = [
  {label: 'Clerical mistake', value: 'Clerical mistake'},
  {label: 'Wrong GRN', value: 'Wrong GRN'},
  {label: 'Wrong Issuance', value: 'Wrong Issuance'},
  {label: 'Others', value: 'Others'},
]