/* eslint-disable */
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  createLecture,
  IsAddedFlagSetTrue,
  updatedIsAddedFlag,
} from "./helper";
import SubjectTreeView from "./treeView/Test";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import AddLectureFrom from "./addedLecture/index";
import { PermissionContext, RolePermissions } from 'appContext'
import { Card, CardBody } from "reactstrap";
import { setChapterIsAddedFalse, getLectures } from "./helper";
import { capitalize } from "../../common/utils/methods/commonMethods/utilityMethod";
import LectureListView from "./lectureList/index";
import ALoader from "../../common/a-loader";
import NoLecturesDisplay from "./lectureList/NoLecturesDisplay";
import { checkPermission } from '../../common/utils/methods/commonMethods/utilityMethod';
import { pages } from "../../common/constant";


const AddLecture = ({ DATA, currentDuration, ...props }) => {
  const [currentLecture, setCurrentLecture] = useState({});
  const [lectures, setLectures] = useState([]);
  const [fetchingLectures, setFetchingLectures] = useState(false);
  const [subjectChapters, setSubjectChapters] = useState({});
  const [deletedDataTracking, setDeletedDataTracking] = useState([]);
  const [newAddedNodeTrack, newAddedNodeTrackHandler] = useState([]);
  // this is for duplicate check
  const [lectureState,setLectureState] = useState('')


  const [expended, setExpended] = useState([]);
  const { isEdit, setIsEdit } = props;
  const userPermissions = RolePermissions(useContext(PermissionContext), pages['searchAcademicPlan']['id']);

  useEffect(() => {
    setSubjectChapters({ ...updatedIsAddedFlag(DATA) });
  }, [DATA]);

  useEffect(() => {
    fetchLectures();
    props?.fetchCourseSyllabusV2(props?.courseId);
    newAddedNodeTrackHandler([])
    setDeletedDataTracking([])
    setCurrentLecture({})
  }, [currentDuration, DATA.subject]);

  const fetchLectures = () => {
    if (props?.planId && DATA?.subject) {
      setFetchingLectures(true);
      getLectures(props.planId, DATA.subject, currentDuration).then((res) => {
        setLectures(res['lectureAcademicPlanMappingList']);
        setLectureState(res?.lectureState)
        setFetchingLectures(false);
      });
    }
  };
  const deleteHandlerTracker = (data) => {
    setDeletedDataTracking([...deletedDataTracking, data]);
  };
  const getCurrentLecture = () => {
    let id = lectures.length + 1;
    let lecture = {
      id_: id,
      lectureNumber:id,
      tableOfContent: [],
    };
    return lecture;
  };

  const addLectureHandler = () => {
    setCurrentLecture(getCurrentLecture());
  };

  const addHandler = (data) => {
    let copyData = { ...data };
     // store new added node while edit
    if(isEdit)newAddedNodeTrackHandler(prev=>[...prev,copyData]);

    let tempCurrentLec = { ...currentLecture };
    if (!tempCurrentLec.id_) {
      let lecture = getCurrentLecture();
      setCurrentLecture(lecture);
      tempCurrentLec = lecture;
    }
    if (data?.nodeType === "Chapter") {
      tempCurrentLec.tableOfContent = [
        ...tempCurrentLec.tableOfContent,
        IsAddedFlagSetTrue(copyData, setExpended, expended),
      ];
      setCurrentLecture(tempCurrentLec);
    }

    if (data?.nodeType === "Topic") {
      let isParentExist = false;
      for (let tempChapter of tempCurrentLec.tableOfContent) {
        if (tempChapter.key === data.parentNode) {
          isParentExist = true;
          tempChapter.children = [
            ...tempChapter.children,
            { ...IsAddedFlagSetTrue(copyData) },
          ];
            // store new added node while edit

          break;
        }
      }
      if (!isParentExist) {
        let parentNode = {};
        let tempSubjectChapter = { ...subjectChapters };
        // get parent add its child
        for (let tempChapter of tempSubjectChapter.tableOfContent) {
          if (tempChapter.key === data.parentNode) {
            parentNode = { ...tempChapter };
            tempChapter["isAdded"] = true;
            parentNode["children"] = [{ ...IsAddedFlagSetTrue(copyData) }];
            tempCurrentLec.tableOfContent = [
              ...tempCurrentLec.tableOfContent,
              parentNode,
            ];
            break;
          }
        }
        // parent node is added
        setSubjectChapters(tempSubjectChapter);
      }
      setCurrentLecture(tempCurrentLec);
    }

    if (data?.nodeType === "Sub-Topic") {
      let isParentOfExist = false;
      let parentExist = false;
      for (let tempChapter of tempCurrentLec.tableOfContent) {
        if (tempChapter.key === data?.topicParentId) {
          isParentOfExist = true;
          tempChapter?.children?.forEach((topic) => {
            if (topic.key === data.parentNode) {
              parentExist = true;
              topic.children = [...topic.children??[], { ...copyData }];
            }
          });
          break;
        }
      }
      if (!isParentOfExist) {
        let parentNode = {};
        let tempSubjectChapter = { ...subjectChapters };
        // get parent add its child
        for (let tempChapter of tempSubjectChapter.tableOfContent) {
          if (tempChapter.key === data.topicParentId) {
            parentNode = { ...tempChapter };
            tempChapter["isAdded"] = true;
            tempChapter?.children?.forEach((topic) => {
              if (topic.key === data.parentNode) {
                topic["isAdded"] = true;
                parentNode["children"] = [{ ...topic, children: [data] }];
              }
            });
            tempCurrentLec.tableOfContent = [
              ...tempCurrentLec.tableOfContent,
              parentNode,
            ];
            break;
          }
        }
        // parent node is added
        setSubjectChapters(tempSubjectChapter);
      }
      setCurrentLecture(tempCurrentLec);
    }
  };

  const deleteHandler = (nodeData) => {
    if (nodeData.nodeType.toLowerCase() === "chapter") {
      // deleted current chapter from current lecture
      let tempCurrentLec = { ...currentLecture };
      let chapters = tempCurrentLec.tableOfContent.filter(
        (chapter) => chapter.key !== nodeData.key
      );
      tempCurrentLec.tableOfContent = chapters;
      //  Now update isAdded false for this chapter and its children

      let tempSubject = { ...subjectChapters };
      tempSubject?.tableOfContent?.forEach((chapter) => {
        if (chapter.key === nodeData.key) {
          if (isEdit) deleteHandlerTracker(chapter);

          let updatedChapter = setChapterIsAddedFalse(chapter);
          chapter.children = updatedChapter.children;
          chapter["isAdded"] = false;
        }
      });
      // get chapter and update its children
      setCurrentLecture(tempCurrentLec);
      setSubjectChapters(tempSubject);
      // update subject flag
    }

    if (nodeData.nodeType.toLowerCase() === "topic") {
      // deleted current chapter from current lecture
      let tempCurrentLec = { ...currentLecture };
      let chapters = tempCurrentLec.tableOfContent?.map((chapter) => {
        if (chapter.key === nodeData.parentNode) {
          let filteredTopic = chapter.children?.filter(
            (topic) => topic.key !== nodeData.key
          );
          chapter.children = filteredTopic;
        }
        return chapter;
      });
      tempCurrentLec.tableOfContent = chapters;
      //  Now update isAdded false for this chapter and its children
      let tempSubject = { ...subjectChapters };
      tempSubject?.tableOfContent?.forEach((chapter) => {
        if (chapter.key === nodeData.parentNode) {
          let children = chapter.children?.map((topic) => {
            if (topic.key === nodeData.key) {
              topic = setChapterIsAddedFalse(topic);
              if (isEdit) deleteHandlerTracker(topic);
            }
            return topic;
          });
          chapter.children = children;
        }
      });
      // get chapter and update its children
      setCurrentLecture(tempCurrentLec);
      // setSubjectChapters(tempSubject);
      // update subject flag
    }
    if (nodeData.nodeType.toLowerCase() === "sub-topic") {
      // deleted current chapter from current lecture
      let tempCurrentLec = { ...currentLecture };
      let deep_copy = JSON.parse(JSON.stringify(currentLecture));
      let chapters = deep_copy.tableOfContent?.map((chapter) => {
        if (chapter.key === nodeData.topicParentId) {

          let filteredTopic = chapter.children?.map((topic) => {
            let subtopic = topic?.children?.filter((subtopic) => {
              if (subtopic.key === nodeData.key) {
                if (isEdit) deleteHandlerTracker(subtopic);
              }
              return subtopic.key !== nodeData.key;
            });

            topic.children = subtopic;
            return topic;
          });
          chapter.children = filteredTopic;
        }
        return chapter;
      });
      tempCurrentLec.tableOfContent = chapters;
      // Now update isAdded false for this chapter and its children
      let tempSubject = { ...subjectChapters };
      tempSubject?.tableOfContent?.forEach((chapter) => {
        if (chapter.key === nodeData.topicParentId) {
          let children = chapter.children?.map((topic) => {
            if (topic.key === nodeData.parentNode) {
              // topic = setChapterIsAddedFalse(topic);
              let updatedSubtopic = topic?.children?.map((subtopic) => {
                if (subtopic.key === nodeData.key) {
                  subtopic = setChapterIsAddedFalse(subtopic);
                }
                return subtopic;
              });
              topic.children = updatedSubtopic;
            }
            return topic;
          });
          chapter.children = children;
        }
      });
      // get chapter and update its children
      setCurrentLecture(tempCurrentLec);
      // console.log(tempSubject);
      setSubjectChapters(tempSubject);
      // update subject flag
    }
  };

  const onCurrentLectureDel = () => {
    // setSubjectChapters({ ...updatedIsAddedFlag(DATA) });
    setCurrentLecture({});
    setIsEdit(false);
    props?.fetchCourseSyllabusV2(props?.courseId);
    // clear state 
    setDeletedDataTracking([]);
    newAddedNodeTrackHandler([])
  };

  const onSave = () => {
    props.createLecture(
      { ...currentLecture, 
        deletedTableOfContent: deletedDataTracking,
        newAddedTableOfContent:newAddedNodeTrack,
        lectureState
       },
      onCurrentLectureDel,
      fetchLectures,
      
    );
  };

  const toggleChange = (lecture, isActive) => {
    setIsEdit((state) => true);
    props.createLecture(
      { ...lecture, isActive: isActive, lectureState },
      onCurrentLectureDel,
      fetchLectures,
      true
    );
  };

  const onEditHandler = (lecture, count) => {
    setIsEdit(true);
    let tempData = { ...subjectChapters };
    lecture?.tableOfContent?.forEach((item) => {
      tempData?.tableOfContent?.forEach((subLec) => {
        if (item.key === subLec.key) {
          subLec["isAdded"] = true;
          if (item?.children) {
            item.children.forEach((topic) => {
              subLec?.children?.forEach((el) => {
                if (el.key === topic.key) {
                  el["isAdded"] = true;
                  topic?.children?.forEach((subtopic) => {
                    el?.children?.forEach((lastNode) => {
                      if (subtopic.key === lastNode.key) {
                        lastNode["isAdded"] = true;
                      }
                    });
                  });
                }
              });
            });
          }
        }
      });
    });
    setSubjectChapters(tempData);
    setCurrentLecture({ ...lecture, id_: count });
  };
  return (
    <div className="d-flex ml-2 mr-2 mb-2" style={{ alignItems: "flex-start" }}>
      {fetchingLectures && <ALoader position={"fixed"} />}
      <Card className="">
        <CardBody>
          <div className="d-flex justify-content-between mb-4">
            <h3 className="heading-4">{DATA?.subject} syllabus</h3>

          {checkPermission(userPermissions, 'C') && <AButton
              onClick={addLectureHandler}
              disabled={currentLecture.id_}
              variant="primary_filled"
            >
              Add Lecture
            </AButton>
         }

          </div>
          <SubjectTreeView
            subjectChapters={subjectChapters}
            setSubjectChapters={setSubjectChapters}
            addHandler={addHandler}
            setExpended={setExpended}
            expended={expended}
          />
        </CardBody>
      </Card>
      <div className="d-flex flex-column ml-2" style={{ flex: 1 }}>
        <AddLectureFrom
          isEdit={isEdit}
          lectures={lectures}
          currentLecture={currentLecture}
          setCurrentLecture={setCurrentLecture}
          onLectureSave={onSave}
          lectureState ={lectureState}
          expended={expended}
          onCurrentLectureDel={onCurrentLectureDel}
          deleteHandler={deleteHandler}
          isLoading={props?.isLoading}
        />

        {lectures?.map((lecture, index) => (
          <LectureListView
            key={lecture.id || index}
            lectureData={lecture}
            counter={index + 1}
            onEditHandler={onEditHandler}
            toggleChange={toggleChange}
          />
        ))}
        {!Object.entries(currentLecture).length && !lectures?.length && (
          <NoLecturesDisplay listView />
        )}
      </div>
    </div>
  );
};

export default AddLecture;
