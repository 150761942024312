import React from 'react'
import CustomButton from 'components/CustomButton'
import { useHistory } from 'react-router';
import moment from 'moment'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import styled from 'styled-components'
import { getUniqueKey } from '../../common/utils/methods/commonMethods/utilityMethod'

const Preview = ({
    ticketDetails,
    rolesData = [],
    detailsData = {},
    userPermissions,
    isFormSubmiting,
    isView = false,
    securityGroup,
    setIsPreviewEnable = () => { },
    submitHandler = () => { }
}) => {
    const history = useHistory();
    return (
        <Card>
            <CardHeader>
                <h3>Preview</h3>
                <div className='d-flex'>
                    <HorizonalContainer>
                        <Label>Ticket ID : &nbsp;</Label>
                        <Value>{` ${ticketDetails?.ticketId}`}</Value>
                    </HorizonalContainer>
                    <HorizonalContainer>
                        <Label>Ticket Date : &nbsp;</Label>
                        <Value>{` ${(ticketDetails?.ticketDate && moment(ticketDetails?.ticketDate).format('DD-MM-YYYY')) ?? ''}`}</Value>
                    </HorizonalContainer>
                </div>
            </CardHeader>
            <CardBody>
                <div>
                    <Row className="flex-wrap pl-4 ml-1"><h3>Details</h3></Row>
                    <hr />
                    <Row className="flex-wrap pl-4 pt-4 pr-4">
                        {
                            Object.keys(detailsData).map(el => {
                                return <Col md={3} key={getUniqueKey()}>
                                    <VerticalContainer>
                                        <Label>{el}</Label>
                                        <Value>{detailsData[el]}</Value>
                                    </VerticalContainer>
                                </Col>
                            })
                        }

                    </Row>
                </div>

                <div className='mt-4'>
                    <Row className="flex-wrap pl-4 ml-1"><h3>Roles</h3></Row>
                    <hr />
                    <div className='pt-4'>
                        {
                            rolesData.map(el => {
                                return <Row className="flex-wrap pl-4 pt-3 pr-4" key={getUniqueKey()}>
                                    <Col md={3}>
                                        <VerticalContainer>
                                            <Label>Project</Label>
                                            <Value>{el.project}</Value>
                                        </VerticalContainer>
                                    </Col>
                                    <Col md={3}>
                                        <VerticalContainer>
                                            <Label>Primary Role Name</Label>
                                            <Value>{el.roles.join(', ')}</Value>
                                        </VerticalContainer>
                                    </Col>
                                    {el.tempRole && <Col md={3}>
                                        <VerticalContainer>
                                            <Label>Temporary Role Name</Label>
                                            <Value>{el.tempRole}</Value>
                                        </VerticalContainer>
                                    </Col>}
                                </Row>
                            })
                        }

                    </div>
                </div>

                <div className='mt-4'>
                    <Row className="flex-wrap pl-4 ml-1"><h3>Security</h3></Row>
                    <hr />
                    <div className='pt-4'>
                        <Row className="flex-wrap pl-4 pt-3 pr-4" key={getUniqueKey()}>
                            <Col md={3}>
                                <VerticalContainer>
                                    <Label>Security Group Name</Label>
                                    <Value>{securityGroup?.label ?? ''}</Value>
                                </VerticalContainer>
                            </Col>
                        </Row>
                    </div>
                </div>

                <Row className={isView ? "justify-content-end" : "justify-content-center"}>
                    {!isView && <>
                        <CustomButton
                            content={"Edit"}
                            permissionType={'C,U'}
                            permissionSet={userPermissions}
                            onClick={() => setIsPreviewEnable(false)}
                            disabled={isFormSubmiting}
                        />
                        <CustomButton
                            className={'mx-1'}
                            content={isFormSubmiting ? (
                                <i className="fas fa-spinner fa-spin" aria-hidden="true"></i>
                            ) : (
                                "Save"
                            )}
                            permissionType={'C'}
                            onClick={submitHandler}
                            permissionSet={userPermissions}
                            disabled={isFormSubmiting}
                        />
                    </>}
                    {isView &&
                        <CustomButton
                            className={'mx-1'}
                            content={'Close'}
                            permissionType={'cancel'}
                            onClick={() => { history.push('/admin/userProfile') }}
                            // forTable={true}
                            permissionSet={userPermissions}
                        />

                    }
                </Row>

            </CardBody>
        </Card>
    )
}

const HorizonalContainer = styled.div`
    display : flex;
    justify-content : space-between;
    max-width : max-content;
    margin-left : 15px;
`;

const VerticalContainer = styled.div`
    display : flex;
    flex-direction: column;
    justify-content : space-between;
    max-width : max-content;
    margin-bottom : 30px;
`;

const Label = styled.span`
    display: inline-block;
    color: #000;
    font-weight: bolder;
    font-size : 12px;
`;
const Value = styled.span`
    display:inlin-block;
    color: #00aeef;
    font-weight: bolder;
    font-size : 12px;
`



export default Preview