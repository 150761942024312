import React, { useEffect, useState } from 'react';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';
import { getFileUrl } from './common';
import CustomLoader from '../../common/commonComponents/Loader/loader';
import { fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../common/constant';
import { Box } from '@mui/material';
import { LazyPDFViewer } from '../../common/commonComponents';

const DocPreview = ({processInsID, fileUrl = '' , fileType = '', uploadedKey}) => {

    const [url, setUrl] = useState(fileUrl);
    const [loading, setLoading] = useState(false);
    const [docType, setDocType] = useState(fileType);

    const getDownloadKey = async (id) => {
        setLoading(true)
        setUrl('');
        const response = await fetchAllPromisedData(`${endpoint.fetchS3Url.getDownloadKey}/${id}`, true);
        if (response.code === 200) {
            const url = await getFileUrl(response?.data?.keyValue);
            setDocType(response?.data?.docType);
            setUrl(url);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    const getFile = async(id) => {
        setLoading(true)
        try{
            setUrl('');
            const url = await getFileUrl(id);
            setUrl(url);
            setLoading(false);
        }catch(e){
            setLoading(false)
        }
    }

    useEffect(() => {
        if(processInsID) {
            getDownloadKey(processInsID);
        }
    }, [processInsID]);

    useEffect(() => {
        // alert(uploadedKey)
        if(uploadedKey) {
            getFile(uploadedKey);
        }
    }, [uploadedKey]);

  return loading && url === '' && docType === '' ? <CustomLoader loaderHeight={'50vh'} loaderWidth={'1095px'} /> : 
            <Box className="doc-review-preview-frame">
                {(docType === 'jpg' || docType === 'jpeg' || docType === 'png') ? 
                <TransformWrapper>
                {({ zoomIn, zoomOut }) => (
                  <div>
                    <TransformComponent>
                        <img key={url} src={url} loading="lazy" style={{height:'48vh'}}></img>
                    </TransformComponent>
                    <div className='d-flex justify-content-center' style={{backgroundColor:'#808080',gap:'6px', opacity:'0.5'}}>
                        <AiOutlineZoomOut size={20} color={'#fff'} onClick={() => zoomOut()}/>
                        <AiOutlineZoomIn size={20} color={'#fff'} onClick={() => zoomIn()}/>
                    </div>
                  </div>
                )}
                </TransformWrapper> :
                <LazyPDFViewer src={url}/>
                }
            </Box>;
}

export default DocPreview;