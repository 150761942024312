import moment from "moment"
import { endpoint } from "views/pages/manage/common/constant";
import { fetchAllPostPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { fetchAllPromisedData, updateRequest } from "../../views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { FACULTY_ROASTER_LIST_CLEAR, FACULTY_ROASTER_LIST_FAILURE, FACULTY_ROASTER_LIST_REQUEST, FACULTY_ROASTER_LIST_SUCCESS, REQUEST_DROPDOWNS, UPDATE_DROPDOWNS } from "./actionTypes";
import { dropdownMapping } from "views/pages/manage/edpOps/constant/common";
import { getAPI } from 'services/http';
// import { publishStatusDropdownData } from "views/pages/manage/facultyRoasterAndTest/constant/search-faculty-plan";
import axios from 'axios'

export const requestDropdowns = () => {
  return {
    type: REQUEST_DROPDOWNS
  }
}

export const updateDropdowns = (data) => {
  return {
    type: UPDATE_DROPDOWNS,
    dropdowns: data
  }
}

export const getDropdowns = async () => {
  const p = await Promise.all([
    dropdownMapping(endpoint.academic_group.getAllActive, 'academicGroupDispValue'),
    dropdownMapping(endpoint.academic_career.getAllActive, 'academicCareerDispValue'),
    dropdownMapping(endpoint.businessArea.getAllActive, 'businessAreaDispValue'),
    dropdownMapping(endpoint.subject.getAllActive, 'subjectDispValue'),
  ])
  return {
    academicGroup: p[0],
    academicCareer: p[1],
    businessArea: p[2],
    subject: p[3],
    // publishStatus: publishStatusDropdownData
  }
};

export const loadDropdowns = (cb) => {

  return async (dispatch) => {
    dispatch(requestDropdowns())
    const response = await getDropdowns();
    if (response) {
      dispatch(updateDropdowns(response));
      if (cb)
        cb(response)
    }
    else {
      dispatch(updateDropdowns({}))
    }
  };
};

// export const fetchAllBatchesByApplicationId = (applicationId) => {
//   return fetchAllPromisedData(`${endpoint.studentSearch.getBatchesByApplicationId}/${applicationId}`)

// }


// academic plans listing page actions from here
export const facultyRoasterListRequest = () => {
  return {
    type: FACULTY_ROASTER_LIST_REQUEST
  }
}

export const facultyRoasterListSuccess = (data) => {
  return {
    type: FACULTY_ROASTER_LIST_SUCCESS,
    payload: data?.searchFacultyMasterDtoList,
    rowCount: data?.totalRecords,
    totalServerPages: data?.totalPage
  }
}

export const facultyRoasterListFailure = (errMsg) => { 
  return {
    type: FACULTY_ROASTER_LIST_FAILURE,
    payload: errMsg
  }
}

export const facultyRoasterListClear = () => {
  return {
    type: FACULTY_ROASTER_LIST_CLEAR
  }
}

export const facultyRoasterDelete = (id,page,businessArea) => {
  return async (dispatch, getState) => {
    dispatch(facultyRoasterListRequest())
    const response = await fetchAllPostPromisedData(`${endpoint.facultyRoaster.deleteAll}/${id}`, {}, 'delete')
    if(response?.code===200){
      dispatch(getFacultyRoasterList({},page,businessArea))
    }
  }
}


const isFilterApplied = (filters) => {
  let count = 0;
  Object.keys(filters).map(item => {
      if (Array.isArray(filters[item])) {
          if (filters[item].length !== 0) {
              count++;
          }
      }else if(filters[item] !== '' && filters[item] !== null ){
        count ++
      }
  })
  return count;
}

export const getFacultyRoasterList = (filterData = {}, page = 0, businessArea) => {
  return async (dispatch, getState) => {
    dispatch(facultyRoasterListRequest())
    let request = {...updateRequest(filterData)};

    if(!request?.businessArea || !request?.businessArea?.length){
      request = {
        ...request,
        businessArea: businessArea.map(i => i.value)
      };
    }
  
    if(!isFilterApplied(request)){
      request = {
        businessArea: businessArea.map(i => i.value)
      };
    }

    const response = await fetchAllPostPromisedData(`${endpoint.faculty.getFacultyWithRoaster}?offset=${page}`, request, "post")    
    if(response?.code===200){
      dispatch(facultyRoasterListSuccess(response?.data))
    } else {
      dispatch(facultyRoasterListFailure(response?.message))
    }
  }
}