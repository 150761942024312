import React from 'react';
import Style from './popup.module.scss';
import { Modal, ModalBody, Button } from 'reactstrap';
import {ReactComponent as CloseIcon} from "../../../../../../assets/img/svg/CrossIcon.svg"

const ConfirmationDialog = ({ isOpen, onAgree = () => { }, onDisagree = () => {} , setIsOpen = () => { }, msg, headerMsg, popupSymbol, type, headerStyle, iconStyle,buttonStyle, crossIconRequired, headerMsgStyles, nextBtnClasses , msgClass, iconClass}) => {
    return (
        <Modal isOpen={isOpen} className={Style.mode_container} centered zIndex={2000}>
            <ModalBody className={Style.body_content}>
                {(popupSymbol!=="" || crossIconRequired) && <div className='d-flex justify-content-between w-100'>
                {popupSymbol !== "" && <div className={ `${Style.icon} ${iconClass}`} style={iconStyle}>{popupSymbol ?? '?'}</div>}
                {crossIconRequired && <CloseIcon style ={{cursor:"pointer"}} onClick={()=>setIsOpen(false)} />}
                </div>}
                <p className={`${ type === 'CONFIRMSAVE' ? Style.confirmation_message_no_icon :Style.confirmation_message}`} style={headerMsgStyles} >{headerMsg ?? 'Are you sure?'}</p>
                <p className={`${Style.signOut_message} ${msgClass}`}>{msg ?? 'All your changes will be lost'}</p>
                {type === 'info' ?
                    <div className={buttonStyle ? 'w-100' : ''}>
                        <Button className={`${Style.btn_ok} ${nextBtnClasses}`} style={buttonStyle} onClick={() => {setIsOpen(false); onAgree()}}>
                            OK
                        </Button>
                    </div>
                    : type === 'RESET' ?
                        <Button className={`${Style.btn_ok} ${nextBtnClasses}`} onClick={() => onAgree()}>
                            Back to Login
                        </Button>
                        : type === 'CONFIRMSAVE' ?
                        <div>
                            <Button className={`${Style.btn_ok} ${nextBtnClasses}`} onClick={() => onAgree()}>
                                Save
                            </Button>
                            <Button className={Style.btn_no} onClick={() => setIsOpen(false)}>
                                close
                            </Button>
                        </div>
                        : <div>
                            <Button className={`${Style.btn_yes} ${nextBtnClasses}`} onClick={() => onAgree()}>
                                YES 
                            </Button>
                            <Button className={Style.btn_no} onClick={() => {setIsOpen(false); onDisagree();}}>
                                NO
                            </Button>
                        </div>}
            </ModalBody>
        </Modal>
    );
};

export default ConfirmationDialog;