import React from 'react';
import { Input } from 'reactstrap';
import Styles from "./customCheckbox.module.scss"

const CustmCheckbox = (props) => {

    const { defaultChecked, isDisabled, onChangeHandler = () => { }, onClick = () => { }, forTable=false, styles } = props;

    return (
        <div className={Styles.custom__checkbox__block} style={{...forTable ? {alignItems:'center'} : {}, ...styles?.checkboxBlock}}>
            <Input
                type={"checkbox"}
                defaultChecked={defaultChecked}
                className={Styles.custom__checkbox__input}
                disabled={isDisabled}
                onChange={onChangeHandler}
                onClick={onClick}
                checked={defaultChecked}
            />
        </div>
    )
}

export default CustmCheckbox;
