import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Button,
} from "reactstrap";
import Loader from "react-loader-spinner";
import Select from "react-select";
import { getSelectedDropDownValue } from "../../../../common/utils/methods/commonMethods/masterDataForSelect"
import { useParams } from 'react-router-dom'
import { failureToast } from "../../../../common/utils/methods/toasterFunctions/function";
import { PermisionDenied } from "../../../../common/commonComponents";
import CustomButton from "components/CustomButton";

const PhoneForm = (props) => {
  const {
    data,
    isDisabled,
    setEditVisible = () => { },
    isEditable,
    addPhone = () => { },
    editPhone = () => { },
    deletePhone = () => { },
    canReset = false,
    setIsVisible = () => { },
    isEditVisible,
    isFetching = false,
    userPermissions
  } = props;
  const [isDisable, setIsDisable] = React.useState(false);
  const [isFetchingApi, setIsFetchingApi] = React.useState(false);

  const [values, setValues] = React.useState({
    extension: "",
    phone: "",
    phoneDesc: "",
    phoneDispValue: "",
    phoneKey: "",
    phoneTypeId: 0,
    prefix: "",
    referenceId: null,
    referenceType: 'CONTACT_HISTORY',
    status: { label: "ACTIVE", value: "ACTIVE" },
  });

  const { id, action } = useParams();
  const statusList = [
    { label: "ACTIVE", value: "ACTIVE" },
    { label: "INACTIVE", value: "INACTIVE" },
  ];


  useEffect(() => {

    // console.log('data for the phone master', id, action)
    if (data) {
      // console.log(data)
      setValues({ ...data, status: getSelectedDropDownValue(data.status, statusList), phoneTypeId: getSelectedDropDownValue(data.phoneTypeId, props.phoneTypeData) });
      // if ( action === 'edit' || action === 'view' ) {

      setIsDisable(isDisabled);
      // }

      // console.log('isDisable', isDisable, isEditVisible)
    }


  }, [data, props.phoneTypeData]);

  // useEffect(() => {
  //    setIsDisable(isDisabled);
  // }, [])

  const save = () => {
    if (!values.phoneTypeId) {
      failureToast("Please Select Phone Type");
      return;
    }
    if (!values.prefix) {
      failureToast("Please Enter Prefix");
      return;
    }
    if (!values.phone) {
      failureToast("Please Enter Phone Number");
      return;
    }
    if (!values.extension) {
      failureToast("Please Enter Extension");
      return;
    }
    if (!values.phoneKey || !values.phoneKey.trim()) {
      failureToast("Please Select Status");
      return;
    }
    if (!values.phoneDesc || !values.phoneDesc.trim()) {
      failureToast("Please Enter Description");
      return;
    }
    if (!values.phoneDispValue || !values.phoneDispValue) {
      failureToast("Please Enter Phone Display Value");
      return;
    }
    // if (!values.status) {
    //   failureToast("Please Select Status");
    //   return;
    // }

    if (isEditable) {
      editPhone(values);
      // console.log('inside the edit section for ')
    }

    if (!isEditable) {
      addPhone(values);
      // console.log('isnide add new phone number for the record')
    }
  };

  // React.useEffect(() => {
  //   setIsFetchingApi(isFetching)
  // }, [isFetching]);

  const reset = () => {
    setValues({
      extension: "",
      phone: "",
      phoneDesc: "",
      phoneDispValue: "",
      phoneKey: "",
      phoneTypeId: 0,
      prefix: "",
      referenceId: null,
      referenceType: null,
      status: { label: "ACTIVE", value: "ACTIVE" },
    });
  };

  React.useEffect(() => {
    canReset && reset();
  }, [canReset]);

  const canDisableForm = () => {
    setIsVisible(false);
  };

  const chkAnyEditVisisble = () => {
    if (!isEditVisible) {
      setEditVisible(true);
      setIsDisable(false);
    } else {
      window.alert("Please Save the unsaved changes first.");
    }
  };

  return (
    userPermissions ?
      <Container>
        <Card>
          <CardBody>
            {isFetching ? (
              <div
                className="mx-auto text-center py-5 my-5"
                style={{ height: "100vh" }}
              >
                <Loader type="Rings" color="#00BFFF" height={70} width={70} />
              </div>
            ) : (
              <>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols1Input"
                      >
                        Phone Type
                      </label>
                      <Select
                        value={values.phoneTypeId}
                        id="example3cols2Input"
                        placeholder="Select Phone Type"
                        options={props.phoneTypeData}
                        isDisabled={isDisable}
                        onChange={(sData) =>
                          setValues({ ...values, phoneTypeId: sData })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols1Input"
                      >
                        Prefix
                      </label>
                      <input
                        value={values.prefix}
                        className="form-control addresslocationInput"
                        required={true}
                        placeholder="Prefix"
                        type="text"
                        maxLength="3"
                        disabled={isDisable}
                        onChange={(e) => { setValues({ ...values, prefix: e.target.value }) }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols1Input"
                      >
                        Phone Number
                      </label>
                      <input
                        value={values.phone}
                        className="form-control addresslocationInput"
                        required={true}
                        placeholder="Phone Number"
                        type="text"
                        maxLength="10"
                        disabled={isDisable}
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            setValues({ ...values, phone: e.target.value });
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols1Input"
                      >
                        Extention
                      </label>
                      <input
                        value={values.extension}
                        className="form-control addresslocationInput"
                        required={true}
                        placeholder="Extention"
                        type="text"
                        maxLength="3"
                        disabled={isDisable}
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            setValues({ ...values, extension: e.target.value });
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols1Input"
                      >
                        Phone Key
                      </label>
                      <input
                        value={values.phoneKey}
                        className="form-control addresslocationInput"
                        required={true}
                        placeholder="Enter  Key"
                        type="text"
                        maxLength="50"
                        disabled={isDisable}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            phoneKey: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols1Input"
                      >
                        Description
                      </label>
                      <input
                        value={values.phoneDesc}
                        className="form-control addresslocationInput"
                        placeholder="Enter  Description"
                        type="text"
                        maxLength="50"
                        disabled={isDisable}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            phoneDesc: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols1Input"
                      >
                        Display Value
                      </label>
                      <input
                        value={values.phoneDispValue}
                        className="form-control addresslocationInput"
                        required={true}
                        placeholder="Enter Display Value"
                        type="text"
                        maxLength="50"
                        disabled={isDisable}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            phoneDispValue: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols1Input"
                      >
                        Status
                      </label>
                      <Select
                        value={values.status}
                        id="example3cols2Input"
                        placeholder="Select Status"
                        options={statusList}
                        isDisabled={isDisable}
                        onChange={(sData) => setValues({ ...values, status: sData })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {isEditable ? (
                  isDisable ? (
                    action !== 'view' ?
                      <Row className="pl-3 pr-3 justify-content-end">

                        {/* <Button
                  style={{ marginLeft: "0px !important" }}
                  color="primary"
                  type="button"
                  onClick={chkAnyEditVisisble}
                >
                  Edit
                </Button> */}
                        <CustomButton
                          className={'mx-1'}
                          content={"Edit"}
                          permissionType={'U'}
                          onClick={chkAnyEditVisisble}
                          permissionSet={userPermissions}
                        />
                        {/* <Button
                  style={{ marginLeft: "0px !important" }}
                  color="primary"
                  type="button"
                  onClick={deletePhone}
                >
                  Delete
                </Button>{" "} */}
                        <CustomButton
                          className={'mx-1'}
                          content={"Delete"}
                          permissionType={'D'}
                          onClick={deletePhone}
                          permissionSet={userPermissions}
                        />
                      </Row> : null
                  ) : (
                    <Row className="pl-3 pr-3 justify-content-end">
                      {/* <Button
                  style={{ marginLeft: "0px !important" }}
                  color="primary"
                  type="button"
                  onClick={save}
                >
                  Save
                </Button> */}
                      <CustomButton
                        className={'mx-1'}
                        content={"Save"}
                        permissionType={'C'}
                        onClick={save}
                        permissionSet={userPermissions}
                      />
                      {/* <Button
                  color="primary"
                  type="button"
                  onClick={() => {
                    setEditVisible(false);
                    setIsDisable(true);
                  }}
                >
                  Cancel
                </Button> */}
                      <CustomButton
                        className={'mx-1'}
                        content={"Cancel"}
                        permissionType={'cancel'}
                        onClick={() => {
                          setEditVisible(false);
                          setIsDisable(true);
                        }}
                        permissionSet={userPermissions}
                      />
                    </Row>
                  )
                ) : (
                  <Row className="pl-3 pr-3 justify-content-end">
                    {/* <Button
                style={{ marginLeft: "0px !important" }}
                color="primary"
                type="button"
                onClick={save}
              >
                Add
              </Button> */}
                    <CustomButton
                      className={'mx-1'}
                      content={" Add"}
                      permissionType={'C'}
                      onClick={save}
                      permissionSet={userPermissions}
                    />
                    {/* <Button color="primary" type="button" onClick={canDisableForm}>
                Cancel
              </Button> */}
                    <CustomButton
                      className={'mx-1'}
                      content={"Cancel"}
                      permissionType={'cancel'}
                      onClick={canDisableForm}
                      permissionSet={userPermissions}
                    />
                  </Row>
                )}
              </>)}
          </CardBody>
        </Card>
      </Container> : <PermisionDenied />
  );
};

export default PhoneForm;
