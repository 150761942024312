import React from "react";
import { Input, Button, Table, Label, FormGroup } from "reactstrap";
import { useState } from "react";
// import "views/pages/manage/addressType/node_modules/react-sliding-pane/dist/react-sliding-pane.css";
import Select2 from "react-select2-wrapper";
import { RiUploadCloudFill } from "react-icons/ri";
import { MasterHeaderAuto } from "../../common/commonComponents/masterHeader/masterHeader";
import { NewRow } from "../../common/utils/methods/commonMethods/utilityMethod";
import CoreMaster from "../../common/commonComponents/coreMaster";
import DocumentUpload from "./chapterUpload";
import { endpoint } from "../../common/constant";
import {
  putpost2,
  putpost,
  successToast,
  masterServiceBaseUrl,
  failureToast,
} from "services/http";
import moment from "moment";
import {
  CustomCard,
  CustomContainer,
  ToExcel,
  GetTemplate
} from "../../common/commonComponents";

const excelDetails = {
  "S.No": "sno",
  Class: "classMaster",
  Subject: "subjectMaster",
  "Short Description": "shortDescription",
  Description: "description",
  "Display Value": "displayValue",
  "Effective Date": "effectiveDate",
  Status: "status",
};

const HeaderElement = ({
  data,
  newRow,
  isSaveVisible,
  allClassData,
  allSubjectData,
  setModalVisible = () => {},
}) => {
  const getDisplayVal = (id, list) => {
    const i =
      id &&
      list?.filter((item) => {
        return item.id == id;
      });
    return i && i?.length ? i[0] : "";
  };

  const dataToExport = data?.map((item, index) => {
    return {
      ...item,
      sno: index + 1,
      classMaster: getDisplayVal(item.classMasterId, allClassData)?.text ?? "",
      subjectMaster:
        getDisplayVal(item.subjectMasterId, allSubjectData)?.text ?? "",
      effectiveDate: moment(item.effectiveDate).format("DD-MM-YYYY"),
    };
  });

  return (
    <>
      <h3>Master - Chapter</h3>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {" "}
        <CoreMaster
          master={"chapterMaster"}
          masterDisplayValue={"Chapter Master"}
        />
        {!isSaveVisible && (
          <ToExcel
            data={dataToExport}
            detailObj={excelDetails}
            name="Chapter"
            isSaveVisible={isSaveVisible}
          />
        )}
        <GetTemplate url={endpoint.chapterMasterTemplate} isSaveVisible={isSaveVisible}/>
        <Button
          disabled={isSaveVisible}
          color="info"
          size="sm"
          type="button"
          className={
            "export_button floatRight mx-1 my-2 " +
            (isSaveVisible ? " btn-dark" : null)
          }
          onClick={setModalVisible}
        >
          <RiUploadCloudFill className="pr-1" size={18} />
          Upload CSV
        </Button>
        <Button
          disabled={isSaveVisible}
          color="info"
          size="sm"
          type="button"
          className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
          onClick={newRow}
        >
          Add Chapter
        </Button>
      </div>
    </>
  );
};

var _ = require("lodash");

const ChapterMasterTable = (props) => {
  const {
    isSaveVisible,
    setisSaveVisible = () => {},
    activeClassData,
    activeSubjectData,
    allClassData,
    allSubjectData,
    pagination,
    serverData,
  } = props;

  const [tableDataKey, settableDataKey] = useState("tableDataKey");
  const [isNewDocument,setIsNewDocument] = useState(false)
  const [isModalVisible, setModalVisible] = useState(false);

  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: "__new",
        chapterMasterKey: "",
        classMasterId: null,
        subjectMasterId: null,
        shortDescription: "",
        displayValue: "",
        status: "ACTIVE",
        description: "",
      },
      ...props.classArray,
    ]);
  };

  const headerList = [
    { name: "S.No" },
    { name: "Class", isRequired: true },
    { name: "Subject", isRequired: true },
    { name: "Short Description", isRequired: true },
    { name: "Description", isRequired: true },
    { name: "Display Value", isRequired: true },
    { name: "effective Date" },
    { name: "Status", isRequired: true },
    { name: "Actions" },
  ];

  const listToBeRendered = [
    "sno",
    "classMasterId",
    "subjectMasterId",
    "shortDescription",
    "description",
    "displayValue",
    "effectiveDate",
    "status",
  ];

  const itemToBeRenderFromMaster = ["classMasterId", "subjectMasterId"];
  const propertyList = [null, "text", "text", null, null, null, null, null];
  const arrList = [
    null,
    allClassData,
    allSubjectData,
    null,
    null,
    null,
    null,
    null,
  ];

  const saveAll = (cb) => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if (el["editMode"]) {
        if (el["classMasterId"] == null) {
          isValid = false;
          failureToast("Please Select Class");
          return;
        } else if (el["subjectMasterId"] == null) {
          isValid = false;
          failureToast("Please Select Subject");
          return;
        } else if (!el["shortDescription"] || !el["shortDescription"].trim()) {
          isValid = false;
          failureToast("Please Enter Short Description");
          return;
        } else if (!el["description"] || !el["description"].trim()) {
          isValid = false;
          failureToast("Please Enter Description");
          return;
        }
        if (!isValid) {
          return;
        }
        if (_tempArray[index]["__status"] == "__new") {
          putpost(
            endpoint.chapter.create,
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              props.fetchClasses(endpoint.chapter.getAll);
            },
            (data) => {
              failureToast(data["message"]);
            },
            {
              ..._tempArray[index],
              shortDescription:
                _tempArray[index]["shortDescription"].toUpperCase(),
            },
            "post"
          );
        } else {
          const updatedValue = {
            ..._tempArray[index],
            shortDescription:
              _tempArray[index]["shortDescription"].toUpperCase(),
          };
          delete updatedValue.chapterMasterKey;
          putpost(
           `${endpoint.chapter.update}/${_tempArray[index].id}`,
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              props.fetchClasses(endpoint.chapter.getAll);
              settableDataKey(new Date().getMilliseconds());
            },
            (data) => {
              failureToast(data["message"]);
            },
            updatedValue,
            "put"
          );
        }
      }
    });
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
        setIsNewDocument(true)
        // settableDataKey(new Date().getMilliseconds())
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
      cb();
    }
  };
  const getOptionIndex = (x, array) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i]["value"] == x) {
        return array[i];
      }
    }
  };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]["editMode"] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
      };
      cb();
    }
  };
  const deleteRow = (index) => {  };
  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
  };

  return (
    <CustomContainer>
      <CustomCard
        HeaderElement={
          <HeaderElement
            isSaveVisible={isSaveVisible}
            data={serverData}
            allClassData={allClassData}
            allSubjectData={allSubjectData}
            newRow={newRow}
            setModalVisible={() => setModalVisible(true)}
          />
        }
      >
        <div id="questionTable" className="table-responsive">
          <Table className="align-items-center table-flush tableLayout">
            <MasterHeaderAuto headerList={headerList} />
            <tbody className="list" key={tableDataKey}>
              {props.classArray
                ? props.classArray.map((el, index) =>
                    !el["editMode"] ? (
                      <NewRow
                        data={{
                          ...el,
                          sno: isSaveVisible && isNewDocument
                            ? index + (pagination.currentPage - 1) * 10
                            : index + 1 + (pagination.currentPage - 1) * 10,
                        }}
                        listToBeRendered={listToBeRendered}
                        itemToBeRenderFromMaster={itemToBeRenderFromMaster}
                        arrList={arrList}
                        propertyList={propertyList}
                      >
                        <td className="text-center  white-breakSpace">
                          <Button
                            color="info"
                            size="sm"
                            type="button"
                            disabled={props.isViewOnly}
                            onClick={() => editRow(index)}
                            // data-testid="editButton"
                          >
                            <i className="fas fa-edit" />
                          </Button>
                        </td>
                      </NewRow>
                    ) : (
                      <tr key={index + "-class"}>
                        <td className="text-center p-2 mx-1">
                          <Input
                            value={el["__status"] !== "__new" ? index + 1 + (pagination.currentPage - 1) * 10 : ""}
                            placeholder="S.No."
                            className="tdInput"
                            disabled
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Select2
                            defaultValue={el["classMasterId"]}
                            data={activeClassData}
                            onChange={(e) => {
                              updateKey(index, e.target.value, "classMasterId");
                            }}
                            options={{
                              placeholder: "Select Class",
                              disabled: el["__status"] !== "__new",
                            }}
                            className="tdSelect"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Select2
                            defaultValue={el["subjectMasterId"]}
                            data={activeSubjectData}
                            onChange={(e) => {
                              updateKey(
                                index,
                                e.target.value,
                                "subjectMasterId"
                              );
                            }}
                            options={{
                              placeholder: "Select Subject",
                              disabled: el["__status"] !== "__new",
                            }}
                            className="tdSelect"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={20}
                            defaultValue={el["shortDescription"]}
                            onKeyPress={(e) => {
                              if (
                                !/^[A-Za-z0-9]+$/.test(
                                  String.fromCharCode(e.charCode)
                                )
                              ) {
                                e.preventDefault();
                                return;
                              }
                            }}
                            onChange={(e) => {
                              updateKey(
                                index,
                                e.target.value,
                                "shortDescription"
                              );
                            }}
                            placeholder="Short Description"
                            className="tdInput"
                            disabled={el["__status"] !== "__new"}
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={200}
                            defaultValue={el["description"]}
                            onKeyPress={(e) => {
                              if (
                                !/^[a-zA-Z0-9, \&_-]*$/.test(
                                  String.fromCharCode(e.charCode)
                                )
                              ) {
                                e.preventDefault();
                                return;
                              }
                            }}
                            onChange={(e) => {
                              updateKey(
                                index,
                                e.target.value.trim(),
                                "description"
                              );
                            }}
                            placeholder="Description"
                            className="tdInput"
                            disabled={el["__status"] !== "__new"}
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={100}
                            defaultValue={el["displayValue"]}
                            onKeyPress={(e) => {
                              if (
                                !/^[a-zA-Z0-9, \&_-]*$/.test(
                                  String.fromCharCode(e.charCode)
                                )
                              ) {
                                e.preventDefault();
                                return;
                              }
                            }}
                            onChange={(e) => {
                              updateKey(index, e.target.value, "displayValue");
                            }}
                            placeholder="Display Value"
                            className="tdInput"
                            disabled={el["__status"] !== "__new"}
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={50}
                            defaultValue={moment(el["effectiveDate"]).format(
                              "DD-MM-YYYY"
                            )}
                            disabled
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <label className="custom-toggle mx-auto ml-2">
                            <input
                              checked={el.status == "ACTIVE" ? true : false}
                              type="checkbox"
                              id={el.id}
                              onChange={(e) => {
                                settableDataKey(new Date().getMilliseconds());
                                let n = "ACTIVE";
                                if (!e.target.checked) {
                                  n = "INACTIVE";
                                }
                                updateKey(index, n, "status");
                              }}
                            />
                            <span
                              style={{ width: "72px" }}
                              className="custom-toggle-slider rounded-circle activeToggle"
                              data-label-off="Inactive"
                              data-label-on="Active"
                            />
                          </label>
                        </td>
                        <td className="text-center">
                          <>
                            {" "}
                            <Button
                              color="info"
                              size="sm"
                              type="button"
                              className="mx-1"
                              onClick={() => {
                                saveAll();
                              }}
                            >
                              Save
                            </Button>
                            <Button
                              color="danger"
                              size="sm"
                              type="button"
                              onClick={() => {
                                let n = props.classArray;
                                if (n[index]["__status"] == "__new") {
                                  n.splice(index, 1);
                                } else {
                                  // n[index]['editMode']=false
                                  props.fetchClasses(endpoint.chapter.getAll);
                                }
                                props.setclassArray(n);
                                setisSaveVisible(false);
                                setIsNewDocument(false)
                              }}
                            >
                              {" "}
                              <i className="fas fa-times" />
                            </Button>{" "}
                          </>
                        </td>
                      </tr>
                    )
                  )
                : null}
            </tbody>
          </Table>
        </div>
      </CustomCard>
      <DocumentUpload
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        getAllData={() =>
          props.fetchClasses(endpoint.chapter.getAll)
        }
      />
    </CustomContainer>
  );
};
export default ChapterMasterTable;
