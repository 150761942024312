import moment from 'moment';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Col, Row } from 'reactstrap';
import CustomButton from '../../../../../../../components/CustomButton';
import { Accordion, ConfirmationDialog } from '../../../../common/commonComponents';
import CustomLoader from '../../../../common/commonComponents/Loader/loader';
import { constants, endpoint } from '../../../../common/constant';
import { fetchAllPostPromisedData, fetchAllPromisedData, getCookie } from '../../../../common/utils/methods/commonMethods/utilityMethod';
import { failureToast, successToast } from '../../../../common/utils/methods/toasterFunctions/function';
import DiscretionaryConcession from './discretionary';
import ProspectusConcession from './prospectus';
import CommonInput from '../../../../common/formFeilds/input/commonInput';
import { Box, Stack, Typography } from '@mui/material';
import { TiWarning } from 'react-icons/ti';
import { UserDetails } from 'appContext';
import { roundTo2Decimal } from '../../common';
import useConfirm from 'views/pages/manage/common/commonComponents/ConfirmationDialog/confirmationPrompt';
import { canRevoke, getCurrentStatus, getExtraCompValue, getFeeMapping, GetDisplayData, checkForAutoConcession, getLowestNetPayApi, getMaximumConcession, calculateTotalConcessionMax } from './common';
import ErrorDialog from '../../../../common/commonComponents/ConfirmationDialog';
import { ReactComponent as Warning } from "../../../../../../../assets/img/svg/DangerWarning.svg";
import Dropdown from './dropdown';
import APopover from '../../../../common/a-popover';
import ApprovalDialogContent from '../../../concessionApproval/dialogContent';
import { useSelector } from 'react-redux';
import { getSuccessMsg, getUpdatedRow } from '../../../concessionApproval/common';
import { getBusinessAreaQR, updateQuestionStatus } from '../common';
import { getFeeBreakup } from '../finalizeCharges';
// import DepositSlipWrapper from '../templates/depositSlipWrapper';
// import { pdf } from '@react-pdf/renderer';
import { uploadpost } from '../../../../../../../services/utils';
import { getOrdinal } from '../templates/draftSummary';
import { getCourseFeeSummary } from '../templates/mapping';
import { createBreakUpData, getBreakUp, getConsessionDetails, getFileUploadUrls, getKeysForTotal } from '../feeBreakup/common';
import { DiscretionarySupportingDocUploader } from './upload';
import './dropdown.scss';
import ApplyCmprDialog, {AppliedCmprSuccessDialog} from './ApplyCmprDialog';
import MandatoryDocUploadDialog from './MandatoryDocUploadDialog';



const APPROVAL_STAGE = {
    PENDING: 'pending',
    APPROVED: 'approved',
    REJECTED: 'reject',
    RECHECK: 'recheck'
}
const APPROVAL_ACTIONS = {
    APPROVE: 'Approve',
    REJECT: 'Reject',
    RECHECK: 'Recheck'
}

let selectedFee = '';

const FEE_COMPONENTS_MAPPING = {
    "29": { label: 'Registration fees', value: 'REG_FEE', beName: 'regFeeConcession' },
    "30": { label: 'Admission fees', value: 'ADMIN_FEE', beName: 'adminFeeConcession' },
    "01": { label: 'Tuition fees', value: 'TUITION_FEE', beName: 'tutionFeeConcession' },
    "04": { label: 'Tech fees', value: 'TECH_FEE', beName: 'techFeeConcession' },
    "05": { label: 'Class fees', value: 'CLASS_FEE', beName: 'classFeeConcession' },
}


export const FEE_COMPONENTS = [
    { label: 'Registration fees', value: 'REG_FEE', beName: 'regFeeConcession', itemTypeVal: '29', feeName: 'regFee' },
    { label: 'Admission fees', value: 'ADMIN_FEE', beName: 'adminFeeConcession', itemTypeVal: '30', feeName: 'adminFee' },
    { label: 'Tuition fees', value: 'TUITION_FEE', beName: 'tutionFeeConcession', itemTypeVal: '01', feeName: 'tutionFee' },
    { label: 'Class fees', value: 'CLASS_FEE', beName: 'classFeeConcession', itemTypeVal: '05', feeName: 'classFee' },
    { label: 'Tech fees', value: 'TECH_FEE', beName: 'techFeeConcession', itemTypeVal: '04', feeName: 'techFee' },
];

export const CUSTOM_CONCESSION_CODE = 'D';


export const NULL_CUSTOM_OBJ = {
    id: null,
    code: CUSTOM_CONCESSION_CODE,
    feeComp: [],
    scholarshipPercentage: '',
    scholarshipValue: '',
    remarks: '',
    updatedOn: '',
    status: null,
    fromBE: false,
    concessionDocuments: [],
    isDirty: false
};

export const getCategory = (allCategories,subCategoryId, subSubCategoryId, isLumpSumCategory) => {
    let concessionCategory = null;
    let categoryId = null;
    let categoryDetails = null;
    if (subSubCategoryId !== null) {
        allCategories.forEach(c => {
            c.subCategories.forEach(sc => {
                sc.subSubCategory.forEach(ssc => {
                    if (ssc.id === subSubCategoryId) {
                        concessionCategory = { ...ssc, isSubSubCategory: true, isLumpsum: isLumpSumCategory };
                        categoryId = c?.id;
                        categoryDetails = {...c};
                    }
                })
            })
        })
    } else if (subCategoryId) {
        allCategories.forEach(c => {
            c.subCategories.forEach(sc => {
                if (sc.id === subCategoryId) {
                    concessionCategory = { ...sc, isSubSubCategory: false, categoryId: c?.id };
                    categoryId = c?.id;
                    categoryDetails = {...c}
                }
            })
        })
    }

    return [categoryId, concessionCategory, categoryDetails];
}


// const updateSelectedFeeComponent = (feeComp, selectedComp) => {
//     const initailComp = [...feeComp];
//     const extraComp = [...selectedComp];

//     const initailCompIds = new Set(initailComp.map(obj => obj.value));

//     const missingObjects = extraComp.filter(obj => !initailCompIds.has(obj.value));

//     initailComp.push(...missingObjects);
//     return initailComp
// }

const approvalFlowVisible = (config, approverType, roleId, businessAreaId, courseCategoryId, getApprovalDetails = () => { }) => {
    let flag = false;
    if (approverType === constants.APPROVER_TYPE.L1) {
        flag = config?.l1ApprovalRoles.includes(roleId);
    } else if (approverType === constants.APPROVER_TYPE.L2) {
        flag = config?.l2ApprovalRoles.includes(roleId);
    } else if (approverType === constants.APPROVER_TYPE.L3) {
        flag = config?.l3ApprovalRoles.includes(roleId);
    }
    if (flag) {
        getApprovalDetails()
    }

    return flag && config?.businessAreas.includes(businessAreaId) && config?.courseCategories.includes(courseCategoryId)
}

const getApprovalType = (status) => {
    return status === 'Pending' ? 'L1' : status === 'L1_APPROVED' ? 'L2' : 'L3'
}

const ConcessionDetails = (props) => {
    const {
        userPermissions,
        concessionDetails,
        getConcessionDetails,
        applicationDetails,
        paymentDetails,
        concessionLoading,
        isOpen,
        setIsOpen,
        feeBreakupData = [],
        itemTypes = [],
        allCategories,
        setAllCategories,
        getApplicationDetails,
        disableActions,
        // setAutoConcession,
        // autoConcession,
        setDocRequiredForCustomConsession,
        finalizeCharges,
        businessAreas,
        courses,
        getPaymentDetails,
        setPaymentModificationAllowed,
        setAsyncLoader,
        studentCurrentYear,
        selectedQoutationId,
        setApplyAutoConcession,
        setModificationType,
        autoConcession,
        modificationType,
        cmprAllowed,
        setCmprAllowed,
        lowestNetPayable,
        setLowestNetPayable,
        docRequiredForCustomConcession,
        isDefinedConcessionDirty ,
        setDefinedConcessionDirty,
        customSlab,
        setCustomSlab,
        processedData
    } = props;

    const userDetails = useContext(UserDetails);
    const approvalState = useSelector(state => state.concessionApproval);

    const [ConfirmationDialog, confirm] = useConfirm();

    const [loading, setLoading] = useState(concessionLoading);
    const [feeComps, setFeeComps] = useState([]);
    const [prospectusSlabs, setProspectusSlabs] = useState([]);
    // const [customSlab, setCustomSlab] = useState(NULL_CUSTOM_OBJ);

    console.log(processedData, "processedData")

    const [maxConcExceeded, setMaxConcExceeded] = useState(false);
    const [maxConcReached, setMaxConcReached] = useState(false);
    const [maxErrorDialogOpen, setMaxErrorDialogOpen] = useState(false);
    const [compoentTotalPercentages, setComponentTotalPercentages] = useState({
        regFee: 0,
        techFee: 0,
        adminFee: 0,
        tutionFee: 0,
        classFee: 0
    });
    const [selectedItems, setSelectedItems] = useState(null);
    const [allSlabs, setAllSlabs] = useState([]);
    const [rowsCountInEditMode, setRowsCountInEditMode] = useState(0);
    const [fee, setFee] = useState({
        regFee: 0,
        adminFee: 0,
        classFee: 0,
        techFee: 0,
        tutionFee: 0
    });
    const [totalConcession, setTotalConcession] = useState({
        totalDefinedConcessionAmount: 0,
        totalCustomConcessionAmount: 0,
        totalConcessionAppliedAmount: 0,
        totalDefinedConcessionPercentage: 0,
        totalCustomConcessionPercentage: 0,
        totalConcessionAppliedPercentage: 0
    });
    const [categoryCodeData, setCategoryCodeData] = useState([]);
    const [fetching, setFetching] = useState(true);
    const [categoryFeeComps, setCategoryFeeComps] = useState([]);

    const [open, setOpen] = useState({ propspectus: true, custom: true });

    const [customConfig, setCustomConfig] = useState(null);
    const [customConfigObject, setCustomConfigObject] = useState(null);

    const [feeComponentFromCourse, setFeeComponentFromCourse] = useState([]);
    const [feeComponentCopyFromCourse, setFeeComponentCopyFromCourse] = useState([]);

    const [extraComponent, setExtraComponent] = useState([]);
    const [componentInDefined, setComponentInDefined] = useState([]);
    const [componentInCustom, setComponentInCustom] = useState([]);

    const [isCustomEditFormEnable, setCustomEditFormEnable] = useState(false);
    const [isDefinedEditFormEnable, setDefinedEditFormEnable] = useState(false);
    // const [isDefinedConcessionDirty, setDefinedConcessionDirty] = useState(false);

    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [apprvalStatus, setApprovalStatus] = useState(false);

    const [allowModify, setAllowModify] = useState(false);
    const [ConfirmationFeeComponentDialog, confirmFeeComponent] = useConfirm();
    const [ConfirmationModifyDialog, confirmModify] = useConfirm();
    const [approvalResponse, setApprovalResponse] = useState({});
    const [saving, setSaving] = useState(false);

    const [hideDiscardButton, setHideDiscardButton] = useState(false);
    const [applyCmpr, setApplyCmpr] = useState({isOpenDialog: false, isApplicableForCmpr:false});
    const [cmprSuccessDialog, setCmprSuccessDialog] = useState({isOpenDialog: false, appliedCmpr:null});

    const [mandatoryDocDialogOpen, setMandatoryDocDialogOpen] = useState(false);
    
    const [customConcTableFormViewMode, setCustomConcTableFormViewMode] = useState(false)
   
    const getAllConfigs = async () => {
        try {
            const response = await fetchAllPromisedData(
                endpoint.concessionConfigConc.getAll,
                true
            );
            if (response && response.code === 200) {
                const _customConfig = response.data.find(item => item.businessAreas.includes(applicationDetails.businessAreaId) && item.courseCategories.includes(applicationDetails.courseCategoryId));
                setCustomConfig(_customConfig);
                setCustomConfigObject(response.data)
            } else {
                failureToast("Failed to fetch Custom Config Data");
            }
        } catch (error) {
            failureToast("Failed to fetch Custom Config Data");
        }
    }

    const isCustomConcEmpty = () => {
        const {scholarshipPercentage , scholarshipValue , remarks , concessionDocuments} = customSlab;
        return !scholarshipPercentage && !scholarshipValue && !remarks && (concessionDocuments?.length === 0);
    }

    useEffect(() => {
        if (applicationDetails.businessAreaId && applicationDetails.courseCategoryId) {
            getAllConfigs();
        }
    }, [applicationDetails]);

    useEffect(() => {
        const feeObj = {
            regFee: 0,
            adminFee: 0,
            classFee: 0,
            techFee: 0,
            tutionFee: 0
        };
        // payment check will add here if not updated already
        if (processedData && Object.keys(processedData)?.length>0) {
            
            const allValidItems = Object.values(processedData).flat()
            const allItemTypesObj = allValidItems.reduce((acc, curr) => {
                return { ...acc, [curr.itemTypeId]: curr }
            }, {});
            allValidItems.forEach(item => {
                // const type = allValidItems[item.itemType];
                 const type = item

                
                switch (true) {
                    case type?.itemType?.slice(-2) === '29': feeObj.regFee += item.baseAmount;
                        break;
                    case type?.itemType?.slice(-2) === '30': feeObj.adminFee += item.baseAmount;
                        break;
                    case type?.itemType?.slice(-2) === '01': feeObj.tutionFee += item.baseAmount;
                        break;
                    case type?.itemType.slice(-2) === '04': feeObj.techFee += item.baseAmount;
                        break;
                    case type?.itemType?.slice(-2) === '05': feeObj.classFee += item.baseAmount;
                }
            })
            setFee(feeObj);


            //console.log("fee", feeObj)

            const feeComponentFromBreakup = []
            feeBreakupData.forEach(item => {
                const type = item
                if (['29', '30', '01', '04', '05'].includes(item.itemType?.slice(-2))) {
                    feeComponentFromBreakup.push(type.itemType?.slice(-2))
                }
            })

            const FEE_COM_FOR_DROPDOWN = feeComponentFromBreakup.filter((item, index) => feeComponentFromBreakup.indexOf(item) === index)?.map(i => {
                return FEE_COMPONENTS_MAPPING?.[i]
            })

            setFeeComponentFromCourse(FEE_COM_FOR_DROPDOWN);
            setFeeComponentCopyFromCourse(FEE_COM_FOR_DROPDOWN);

        }
    }, [processedData, feeBreakupData]);


    const costCalculation = () => {
        
        const totalFees = Object.values(fee).reduce((acc, curr) => acc + curr, 0);
        let totalDefinedConc = 0;
        let totalCustomConc = 0;
        let componentsPercentage = {};
     
        const componentInDefinedConsession = [];
        /** calculate total defined concession */
        prospectusSlabs.forEach(slab => {

            const { adminFeeConcession, classFeeConcession, regFeeConcession, techFeeConcession, tutionFeeConcession } = slab;

            const extraCompValue = getExtraCompValue(tutionFeeConcession, adminFeeConcession, classFeeConcession, techFeeConcession, regFeeConcession);
            const _adminFeeConcession = adminFeeConcession === 0 && extraComponent.find(item => item === 'ADMIN_FEE') ? extraCompValue : adminFeeConcession;
            const _regFeeConcession = regFeeConcession === 0 && extraComponent.find(item => item === 'REG_FEE') ? extraCompValue : regFeeConcession;
            const _classFeeConcession = classFeeConcession === 0 && extraComponent.find(item => item === 'CLASS_FEE') ? extraCompValue : classFeeConcession;
            const _techFeeConcession = techFeeConcession === 0 && extraComponent.find(item => item === 'TECH_FEE') ? extraCompValue : techFeeConcession;
            const _tutionFeeConcession = tutionFeeConcession === 0 && extraComponent.find(item => item === 'TUITION_FEE') ? extraCompValue : tutionFeeConcession;


            ////console.log(_adminFeeConcession)
            ////console.log(_regFeeConcession)
            ////console.log(_classFeeConcession)
            ////console.log(_techFeeConcession)
            ////console.log(_tutionFeeConcession)

            if (_adminFeeConcession) {
                componentInDefinedConsession.push('adminFee')
            }
            if (_regFeeConcession) {
                componentInDefinedConsession.push('regFee')
            }
            if (_classFeeConcession) {
                componentInDefinedConsession.push('classFee')
            }
            if (_techFeeConcession) {
                componentInDefinedConsession.push('techFee')
            }
            if (_tutionFeeConcession) {
                componentInDefinedConsession.push('tutionFee')
            }

            let total = 0;
            if (slab.concessionParameter === "PERCENTAGE") {
                total = (_adminFeeConcession * fee.adminFee) / 100 +
                    (_regFeeConcession * fee.regFee) / 100 +
                    (_classFeeConcession * fee.classFee) / 100 +
                    (_techFeeConcession * fee.techFee) / 100 +
                    (_tutionFeeConcession * fee.tutionFee) / 100;

                componentsPercentage['adminFee'] = componentsPercentage['adminFee'] ? componentsPercentage['adminFee'] + _adminFeeConcession : _adminFeeConcession;
                componentsPercentage['regFee'] = componentsPercentage['regFee'] ? componentsPercentage['regFee'] + _regFeeConcession : _regFeeConcession;
                componentsPercentage['classFee'] = componentsPercentage['classFee'] ? componentsPercentage['classFee'] + _classFeeConcession : _classFeeConcession;
                componentsPercentage['techFee'] = componentsPercentage['techFee'] ? componentsPercentage['techFee'] + _techFeeConcession : _techFeeConcession;
                componentsPercentage['tutionFee'] = componentsPercentage['tutionFee'] ? componentsPercentage['tutionFee'] + _tutionFeeConcession : _tutionFeeConcession;

            } else if (slab.concessionParameter === "AMOUNT") {
                total = _adminFeeConcession + _regFeeConcession + _classFeeConcession + _techFeeConcession + _tutionFeeConcession;

                componentsPercentage['adminFee'] = componentsPercentage['adminFee'] ? componentsPercentage['adminFee'] + (_adminFeeConcession ? (_adminFeeConcession / fee.adminFee) * 100 : 0) : (_adminFeeConcession ? (_adminFeeConcession / fee.adminFee) * 100 : 0);
                componentsPercentage['regFee'] = componentsPercentage['regFee'] ? componentsPercentage['regFee'] + (_regFeeConcession ? (_regFeeConcession / fee.regFee) * 100 : 0) : (_regFeeConcession ? (_regFeeConcession / fee.regFee) * 100 : 0);
                componentsPercentage['classFee'] = componentsPercentage['classFee'] ? componentsPercentage['classFee'] + (_classFeeConcession ? (_classFeeConcession / fee.classFee) * 100 : 0) : (_classFeeConcession ? (_classFeeConcession / fee.classFee) * 100 : 0);
                componentsPercentage['techFee'] = componentsPercentage['techFee'] ? componentsPercentage['techFee'] + (_techFeeConcession ? (_techFeeConcession / fee.techFee) * 100 : 0) : (_techFeeConcession ? (_techFeeConcession / fee.techFee) * 100 : 0);
                componentsPercentage['tutionFee'] = componentsPercentage['tutionFee'] ? componentsPercentage['tutionFee'] + (_tutionFeeConcession ? (_tutionFeeConcession / fee.tutionFee) * 100 : 0) : (_tutionFeeConcession ? (_tutionFeeConcession / fee.tutionFee) * 100 : 0);
            }
            totalDefinedConc += total;
        });

        /** calculate total custom concession */
        totalCustomConc = +customSlab.scholarshipValue;

        let totalDefinedFee = 0;
        componentInDefinedConsession.forEach(el => {
            totalDefinedFee += +fee[el]
        });

        let totalCustomFee = 0;
        [...new Set([...componentInCustom, ...extraComponent])].forEach(el => {
            const feeName = FEE_COMPONENTS.find(i => i.value === el)?.feeName;
            totalCustomFee += fee[feeName]
            componentsPercentage[feeName] = componentsPercentage[feeName] ? componentsPercentage[feeName] + Number(customSlab.scholarshipPercentage) : Number(customSlab.scholarshipPercentage);
        });

        let totalFee = 0;
        [...new Set([...extraComponent, ...componentInCustom, ...componentInDefined])].forEach(el => {
            // alert(el)
            totalFee = totalFee + fee[FEE_COMPONENTS.find(i => i.value === el)?.feeName]
        });
       
        setTotalConcession(null)
        const totalConcessions = {
            totalDefinedConcessionAmount: (totalDefinedConc)?.toFixed(2),
            totalCustomConcessionAmount: (totalCustomConc)?.toFixed(2),
            totalConcessionAppliedAmount: (totalDefinedConc + totalCustomConc)?.toFixed(2),
            totalDefinedConcessionPercentage: totalDefinedConc && totalDefinedFee ? ((totalDefinedConc * 100) / totalDefinedFee)?.toFixed(2) : 0,
            totalCustomConcessionPercentage: totalCustomFee && totalCustomConc ? ((totalCustomConc * 100) / totalCustomFee)?.toFixed(2) : 0,
            totalConcessionAppliedPercentage: (totalDefinedConc + totalCustomConc) && totalFee ? (((totalDefinedConc + totalCustomConc) * 100) / totalFee)?.toFixed(2) : 0,
        }


        //console.log("componentsPercentage",componentsPercentage)
        //console.log("totalFees",totalFees)

        setTotalConcession({ ...totalConcessions })

        setMaxConcExceeded(roundTo2Decimal(totalDefinedConc + totalCustomConc) > totalFees);
        setMaxConcReached(roundTo2Decimal(totalDefinedConc + totalCustomConc) === totalFees || roundTo2Decimal(totalDefinedConc + totalCustomConc) > totalFees);
        setComponentTotalPercentages(componentsPercentage)
        return totalConcessions
    }

    useEffect(() => {
        costCalculation();
    }, [fee, prospectusSlabs, customSlab, extraComponent, allSlabs]);

    useEffect(() => {
        if (!prospectusSlabs?.length && !customSlab?.scholarshipValue && extraComponent?.length) {
            setExtraComponent([]);
        }
    }, [prospectusSlabs, customSlab])


    let { id, action } = useParams();

    const mapGlobalFeeComponents = (allSlabs) => {
        const feeMap = getFeeMapping(allSlabs);
        const _feeComps = feeComponentFromCourse.filter(f => feeMap[f.beName] === true).map(i => i.value);
        setComponentInDefined(_feeComps);
    }

    const constructProspectusAndCustomSlabs = () => {
        const extraFeeComps = concessionDetails?.[0]?.extraFeeComponents ? concessionDetails?.[0]?.extraFeeComponents?.split(",") : [];
        const _prospectusSlabs = concessionDetails.filter(item => item.concessionType === "DEFINED").map(item => {

            const categoryInfo = getCategory(allCategories, item.concessionSubCategoryId, item.concessionSubSubCategoryId, item?.isLumpSumCategory);

            if (item?.concessionMethodType?.split('_')?.[0] === 'AUTO' && !autoConcession) {
                setApplyAutoConcession(true);
            }

            return {
                id: item.id,
                categoryDetails : categoryInfo[2],
                categoryId: categoryInfo[0],
                category: { ...categoryInfo[1], concessionMethodType: item?.concessionMethodType, concessionDataId: item?.concessionDataId },
                code: categoryCodeData?.filter(i => i.value === item.concessionCategoryCode) ?? [{ label: item.concessionCategoryCode, value: item.concessionCategoryCode }],
                feeComp: feeComps,
                remarks: item.concessionRemarks,
                updatedOn: item.updatedOn,
                scholarshipCriteria: item.scholarshipCriteria,
                scholarshipCriteriaValue: item.scholarshipCriteriaValue,
                concessionSlabId: item.concessionSlabId,
                adminFeeConcession: extraFeeComps.indexOf('ADMIN_FEE') > -1 ? 0 : item.adminFeeConcession,
                regFeeConcession: extraFeeComps.indexOf('REG_FEE') > -1 ? 0 : item.regFeeConcession,
                classFeeConcession: extraFeeComps.indexOf('CLASS_FEE') > -1 ? 0 : item.classFeeConcession,
                techFeeConcession: extraFeeComps.indexOf('TECH_FEE') > -1 ? 0 : item.techFeeConcession,
                tutionFeeConcession: extraFeeComps.indexOf('TUITION_FEE') > -1 ? 0 : item.tutionFeeConcession,
                concessionParameter: item.concessionParameter,
                concessionDocuments: item.concessionDocuments,
                fromBE: true,
                isAutoConcession: item?.isAutoConcession,
                status: item?.concessionApprovalStatus,
                isApproved: item?.isApproved,
                concessionMethodType: item?.concessionMethodType,
                hasExtraFee: extraFeeComps?.length,
                concessionDataId: item?.concessionDataId
            }
        });

        const _customSlab = concessionDetails.find(item => item.concessionType === "CUSTOM");
        const customFeeComp = [];
        if (_customSlab) {
            const { id, concessionCategoryCode, concessionRemarks, updatedOn, concessionDocuments, concessionApprovalStatus, applicationCustomConcessionApprovalDetails, isApproved, createdBy } = _customSlab;
            const feeComp = feeComponentFromCourse.filter(item => _customSlab[item.beName] !== 0);
            const scholarshipPercentage = feeComp.length > 0 ? _customSlab[feeComp[0].beName] : 0;
            const scholarshipValue = feeComp.reduce((acc, curr) => {
                if (curr.value === 'REG_FEE') {
                    if (extraFeeComps.indexOf('REG_FEE') === -1) {
                        customFeeComp.push('REG_FEE')
                    }
                    return acc + (scholarshipPercentage * fee.regFee) / 100;
                } else if (curr.value === 'ADMIN_FEE') {
                    if (extraFeeComps.indexOf('ADMIN_FEE') === -1) {
                        customFeeComp.push('ADMIN_FEE')
                    }
                    return acc + (scholarshipPercentage * fee.adminFee) / 100;
                } else if (curr.value === 'TUITION_FEE') {
                    if (extraFeeComps.indexOf('TUITION_FEE') === -1) {
                        customFeeComp.push('TUITION_FEE')
                    }
                    return acc + (scholarshipPercentage * fee.tutionFee) / 100;
                } else if (curr.value === 'CLASS_FEE') {
                    if (extraFeeComps.indexOf('CLASS_FEE') === -1) {
                        customFeeComp.push('CLASS_FEE')
                    }
                    return acc + (scholarshipPercentage * fee.classFee) / 100;
                } else if (curr.value === 'TECH_FEE') {
                    if (extraFeeComps.indexOf('TECH_FEE') === -1) {
                        customFeeComp.push('TECH_FEE')
                    }
                    return acc + (scholarshipPercentage * fee.techFee) / 100;
                }
            }, 0);
            setCustomSlab({
                id,
                code: concessionCategoryCode,
                feeComp,
                scholarshipPercentage,
                scholarshipValue: scholarshipValue?.toFixed(2),
                remarks: concessionRemarks,
                updatedOn,
                status: concessionApprovalStatus,
                fromBE: true,
                concessionDocuments: concessionDocuments,
                isDirty: false,
                applicationCustomConcessionApprovalDetails: applicationCustomConcessionApprovalDetails,
                isApproved,
                createdBy
            });

            // alert(concessionApprovalStatus)
            // handleCustomFeeComponentInGlobleSelector();
        } else {
            setCustomSlab(NULL_CUSTOM_OBJ);
        }

        const feeMap = getFeeMapping(_prospectusSlabs);
        const _feeComps = feeComponentFromCourse.filter(f => feeMap[f.beName] === true)?.map(i => i.value);
        setComponentInDefined(_feeComps);
        if (_customSlab) {
            setComponentInCustom(customFeeComp);
        } else {
            setComponentInCustom([]);
        }
        setApprovalStatus(getCurrentStatus(_prospectusSlabs?.length ? _prospectusSlabs?.[0]?.status : _customSlab?.concessionApprovalStatus, _prospectusSlabs?.length ? _prospectusSlabs?.[0]?.isApproved : _customSlab?.isApproved));

        setProspectusSlabs(_prospectusSlabs);
        setAllSlabs([]);
        setRowsCountInEditMode(0);
        // costCalculation();
        setExtraComponent(extraFeeComps);
        setHideDiscardButton(false);
    }


    useEffect(() => {
        constructProspectusAndCustomSlabs();
    }, [allCategories, concessionDetails, fee, feeComponentFromCourse]);

    const getAllCategoryCodes = async () => {
        setLoading(true);
        try {
            const response = await fetchAllPromisedData(`${endpoint.categoryCode}/all`);
            if (response) {
                const _codes = response?.filter(el => el.status === 'ACTIVE')?.map(item => ({ label: item.code, value: item.code, frequency: item.frequency, notAllowedCombination: item.notAllowedCategoryCode }));
                setCategoryCodeData(_codes);
                setLoading(false);
            } else {
                failureToast("Failed to fetch category codes!");
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            failureToast("Failed to fetch category codes!");
        }
    }

    useEffect(() => {
        // getClassificationData();
        getAllCategoryCodes();
    }, []);
   
    const getCustomRequest = ()=>{
        return [{
            ...(customSlab.fromBE && { id: customSlab.id }),
            applicationQuotationDetailsId: applicationDetails?.applicationQuotationDetailsId ?? paymentDetails.id,
            applicationId: applicationDetails.applicationId,
            psid: applicationDetails.psid,
            concessionCategoryCode: customSlab.code,
            concessionRemarks: customSlab.remarks,
            concessionSlabId: null,
            scholarshipCriteria: null,
            scholarshipCriteriaValue: null,
            ...(FEE_COMPONENTS.reduce((acc, curr) => {
                return { ...acc, [curr.beName]: [...categoryFeeComps, ...FEE_COMPONENTS.filter(i => extraComponent.includes(i.value))].findIndex(comp => comp.value === curr.value) >= 0 ? +customSlab.scholarshipPercentage : 0 };
            }, {})),
            concessionParameter: "PERCENTAGE",
            concessionType: "CUSTOM",
            requestedBy: userDetails ? `${userDetails.firstName} ${userDetails.lastName}` : "",
            action: customSlab.fromBE ? (customSlab?.action === 'DELETE' ? "DELETE" : "UPDATE") : "CREATE",
            concessionDocuments: customSlab.concessionDocuments?.map(doc => { return { ...doc, applicationQuotationDetailsId: applicationDetails?.applicationQuotationDetailsId ?? paymentDetails.id } }) ?? [],
            concessionApprovalStatus: customSlab?.status,
            isApproved: customSlab?.isApproved,
            isLumpSumCategory: false
        }];
    }

   const getNetPayableAmount = (totals)=>{
    const totalNetAmount = ((Object.keys(totals).reduce((a, b) => a + totals[b]['netAmt'], 0))).toFixed(2)
    const concApprovalPending = !isNaN(Math.round(Object.keys(totals).reduce((a, b) => a + totals[b]['concApprovalPendingTotal'], 0) * 100) / 100) ? parseFloat(((Math.round(Object.keys(totals).reduce((a, b) => a + totals[b]['concApprovalPendingTotal'], 0) * 100) / 100))).toFixed(2) : '0'
    return totalNetAmount - concApprovalPending
   }

   const cmprApplyFlowHandler =  async()=>{
     
       try {
        
        const apiResponse  = await getLowestNetPayApi(applicationDetails)

    if(apiResponse && apiResponse?.data.paymentDone){
      setModificationType("BYPASS_CMPR")
      
      let maxConcessionBE = apiResponse?.data?.maxCurrentDiscount
      const {  total } = await getBreakUp({ data: feeBreakupData }, { data: itemTypes });
      const { totalConsession,  concessionAppliedList ,totalPendingConsession } = await getConsessionDetails([...prospectusSlabs.map(item=>({...item, concessionType:"DEFINED", })), ...getCustomRequest()], total ,studentCurrentYear)
      //const {totalTempData:totals } = createBreakUpData(groupedData, totalConsession, totalPendingConsession, concessionAppliedList, studentCurrentYear, [...prospectusSlabs.map(item=>({...item, concessionType:"DEFINED"})), {...customSlab,concessionType:"CUSTOM" }]);
      const appliedConcessionMax =  getMaximumConcession(totalConsession) ;
      // conc with pending
      const pendingConcessionMax =  calculateTotalConcessionMax(totalConsession, totalPendingConsession) ;
    // check defined conc is max >  maxConcessionBE
      if(appliedConcessionMax > maxConcessionBE){
        setApplyCmpr({isOpenDialog:true, isApplicableForCmpr:true});
      } else if(pendingConcessionMax > maxConcessionBE){
        setApplyCmpr({isOpenDialog:true, isApplicableForCmpr:true, isUnApprovedConcessionMax:true});
      }else{
        //setApplyCmpr({isOpenDialog:true, isApplicableForCmpr:false});
        handleSaveAndNext(null,"BYPASS_CMPR")
      }
        return ;
    }else{
        handleSaveAndNext()
    }
    } catch (error) {
        console.log(error)
    }
   }
    const handleSaveAndNext = async (e, cmpr) => {


        if (!applicationDetails?.applicationQuotationDetailsId && !paymentDetails?.id) {
            failureToast("Please add payment mode first!");
            return
        }
        let isValid = true
        Object.keys(compoentTotalPercentages).forEach(el => {
            //console.log("compoentTotalPercentages[el]",compoentTotalPercentages[el])
            if (compoentTotalPercentages[el] > 100) {
                isValid = false;
            }
        })

        if (maxConcExceeded) {
            isValid = false;
        }

        if (!isValid) {
            setMaxErrorDialogOpen(true);
            return
        }

        if (paymentDetails === null) {
            failureToast("Please add Payment method.");
            return;
        }
        const requestDefined = allSlabs.filter(s => s.concessionType === "DEFINED").map(slab => {
            const {
                action, adminFeeConcession, category, classFeeConcession, code, concessionParameter,
                regFeeConcession, remarks, scholarshipCriteria, scholarshipCriteriaValue,
                techFeeConcession, tutionFeeConcession, concessionType, concessionSlabId, isLumpSumCategory, concessionDocuments
            } = slab;
            const extraCompValue = getExtraCompValue(tutionFeeConcession, adminFeeConcession, classFeeConcession, techFeeConcession, regFeeConcession);
            return {
                ...((slab.action === "UPDATE" || slab.action === "DELETE") && { id: slab.id }),
                applicationQuotationDetailsId: applicationDetails?.applicationQuotationDetailsId ?? paymentDetails.id,
                applicationId: applicationDetails.applicationId,
                psid: applicationDetails.psid,
                concessionCategoryCode: code?.[0].value,
                ...(category?.isSubSubCategory || category?.isLumpsum ? { concessionSubSubCategoryId: category?.id } : { concessionSubCategoryId: category?.id }),
                concessionSlabId,
                concessionRemarks: remarks,
                scholarshipCriteria: scholarshipCriteria,
                scholarshipCriteriaValue: scholarshipCriteriaValue,
                adminFeeConcession: adminFeeConcession ? adminFeeConcession : extraComponent.find(item => item === 'ADMIN_FEE') ? extraCompValue : 0,
                regFeeConcession: regFeeConcession ? regFeeConcession : extraComponent.find(item => item === 'REG_FEE') ? extraCompValue : 0,
                classFeeConcession: classFeeConcession ? classFeeConcession : extraComponent.find(item => item === 'CLASS_FEE') ? extraCompValue : 0,
                techFeeConcession: techFeeConcession ? techFeeConcession : extraComponent.find(item => item === 'TECH_FEE') ? extraCompValue : 0,
                tutionFeeConcession: tutionFeeConcession ? tutionFeeConcession : extraComponent.find(item => item === 'TUITION_FEE') ? extraCompValue : 0,
                concessionParameter,
                concessionType,
                action,
                concessionDocuments: concessionDocuments?.map(doc => { return { ...doc, applicationQuotationDetailsId: applicationDetails?.applicationQuotationDetailsId ?? paymentDetails.id } }) ?? [],
                isLumpSumCategory: category?.isLumpsum ?? false,
                concessionApprovalStatus: slab?.status,
                isApproved: slab?.isApproved,
                concessionMethodType: category?.concessionMethodType ?? null,
                concessionDataId: category?.concessionDataId ?? null,
                concessionName:category?.subSubCategoryDisplayValue || category?.subCategoryDesc
            }
        });

        const isCustomConcessionBlank = customSlab.feeComp.length == 0 && customSlab.scholarshipPercentage === "" && customSlab.scholarshipValue === "" && customSlab?.action !== 'DELETE';

        const requestCustom = [{
            ...(customSlab.fromBE && { id: customSlab.id }),
            applicationQuotationDetailsId: applicationDetails?.applicationQuotationDetailsId ?? paymentDetails.id,
            applicationId: applicationDetails.applicationId,
            psid: applicationDetails.psid,
            concessionCategoryCode: customSlab.code,
            concessionRemarks: customSlab.remarks,
            concessionSlabId: null,
            scholarshipCriteria: null,
            scholarshipCriteriaValue: null,
            ...(FEE_COMPONENTS.reduce((acc, curr) => {
                return { ...acc, [curr.beName]: [...categoryFeeComps, ...FEE_COMPONENTS.filter(i => extraComponent.includes(i.value))].findIndex(comp => comp.value === curr.value) >= 0 ? +customSlab.scholarshipPercentage : 0 };
            }, {})),
            concessionParameter: "PERCENTAGE",
            concessionType: "CUSTOM",
            requestedBy: userDetails ? `${userDetails.firstName} ${userDetails.lastName}` : "",
            action: customSlab.fromBE ? (customSlab?.action === 'DELETE' ? "DELETE" : "UPDATE") : "CREATE",
            concessionDocuments: customSlab.concessionDocuments?.map(doc => { return { ...doc, applicationQuotationDetailsId: applicationDetails?.applicationQuotationDetailsId ?? paymentDetails.id } }) ?? [],
            concessionApprovalStatus: customSlab?.status,
            isApproved: customSlab?.isApproved,
            isLumpSumCategory: false
        }];
        

        const request = {
            isDocumentAttached: isDocumentAttached(),
            applicationQuotationDetailsId: applicationDetails?.applicationQuotationDetailsId ?? paymentDetails.id,
            totalDefinedConcessionAmount: totalConcession.totalDefinedConcessionAmount,
            totalDefinedConcessionPercentage: totalConcession.totalDefinedConcessionPercentage,
            totalCustomConcessionAmount: totalConcession.totalCustomConcessionAmount,
            totalCustomConcessionPercentage: totalConcession.totalCustomConcessionPercentage,
            totalConcessionAppliedAmount: totalConcession.totalConcessionAppliedAmount,
            totalConcessionAppliedPercentage: totalConcession.totalConcessionAppliedPercentage,
            extraFeeComponents: extraComponent,
            cmpr: cmpr?cmpr:modificationType
        };
        try {
   
            setApplyCmpr({isOpenDialog:false,isApplicableForCmpr:false})
            setLoading(true);
            const resp = await fetchAllPostPromisedData(`${endpoint.studentChargesConcessionDetails.post}`,
                { ...request, concessionDetails: [...requestDefined, ...(!isCustomConcessionBlank && customSlab.isDirty ? requestCustom : [])] },
            );
            setLoading(false);
            if (resp && resp.code === 200) {
                successToast("Concession Details saved successfully");
                if(request?.cmpr) setCmprSuccessDialog({isOpenDialog:true,appliedCmpr:request?.cmpr})
                setHideDiscardButton(true);
                getConcessionDetails();
                getApplicationDetails();
                setCmprAllowed(false)
                setModificationType(null)
                setAllowModify(false);
                setLowestNetPayable(null)
                setDefinedConcessionDirty(false);
            } else {
                failureToast("Couldn't save Concession details");
            }
        } catch (err) {
            setLoading(false);
            failureToast("Couldn't save Concession details");
        }

    }

    const openMandatoryDocDialog = () =>{

        if (!customConfig) {
            failureToast(`No custom config found.`);
            return;
        }

        setMandatoryDocDialogOpen(true);

    }

    const handleSendApproval = async () => {
        try {
            setLoading(true);
            const resp = await fetchAllPostPromisedData(`${endpoint.studentChargesConcessionDetails.send}`,
                { applicationQuotationId: applicationDetails?.applicationQuotationDetailsId ?? paymentDetails?.id }, 'put'
            );
            setLoading(false);
            if (resp && resp.code === 200) {
                successToast("Concession Details sent for approval");
                getConcessionDetails();
                getApplicationDetails();
                setMandatoryDocDialogOpen(false);
            } else {
                failureToast("Couldn't sent concession details for approval");
            }
        } catch (err) {
            setLoading(false);
            failureToast("Couldn't sent concession details for approval");
        }
    }

    const defaultViewComp = (
        <>
        <Row>
            <Col md="3">
                <CommonInput
                    type="text"
                    defaultValue={totalConcession?.totalConcessionAppliedPercentage ?? 0}
                    label="Total concession %"
                    readOnly={true}
                    readOnlyComp={<h3>{totalConcession?.totalConcessionAppliedPercentage ?? 0} %</h3>}
                />
            </Col>
            <Col md="3">
                <CommonInput
                    type="text"
                    defaultValue={totalConcession?.totalConcessionAppliedAmount}
                    label="Total concession amount"
                    readOnly={true}
                    readOnlyComp={<h3>₹ {totalConcession?.totalConcessionAppliedAmount}</h3>}
                />
            </Col>
        </Row>
            <GetDisplayData status={prospectusSlabs?.length ? prospectusSlabs?.[0]?.status : customSlab?.status} isApproved={prospectusSlabs?.length ? prospectusSlabs?.[0]?.isApproved : customSlab?.isApproved} />
            </>
    );


    // //console.log(totalConcession?.totalConcessionAppliedPercentage === NaN)

    const getSubCategoryDetails = async (subCategoryId) => {
        try {
            setFetching(true)
            const response = await fetchAllPromisedData(
                `${endpoint.subCategory}/${subCategoryId}`,
                true
            );
            if (response && response.code === 200) {
                const subCategoryComp = response?.data?.feeComponent ?? []
                const COMPONENTS = FEE_COMPONENTS.filter(item => subCategoryComp.indexOf(item.value) > -1) ?? [];
                setDocRequiredForCustomConsession(response?.data?.documentUpload);
                setCategoryFeeComps(COMPONENTS);
                setFetching(false)
            } else {
                failureToast("Failed to fetch Category Data");
                setFetching(false)
            }
            // costCalculation();
        } catch (error) {
            setFetching(false)
            failureToast("Failed to fetch Category Data");
        }
    }



    useEffect(() => {
        if (allCategories?.length) {
            const _category = allCategories.find(item => item.categoryCode === CUSTOM_CONCESSION_CODE);
            // ////console.log(_category)
            const _subCategory = _category?.subCategories[0];
            // ////console.log(_subCategory)
            if (_subCategory) {
                getSubCategoryDetails(_subCategory.id);
            } else {
                setFetching(false);
            }
        }
    }, [allCategories]);

    // const isSaveDisabled = (prospectusSlabs?.find(i => i.isDire) && customSlab?.isDirty === false) || maxConcExceeded === true;

    const getAllUploadedDocuments = () =>{
        let allDocuments = [];
        const slabs = [...prospectusSlabs, customSlab];
        slabs.forEach(slab =>{
            allDocuments = [...allDocuments, ...slab?.concessionDocuments]
        })
        return allDocuments;
    }

    const handleFeeCompChange = (val) => {

        if (!prospectusSlabs?.length && customSlab?.action !== 'CREATE' && !customSlab?.fromBE) {
            setErrorDialogOpen(true);
            return
        }

        if (prospectusSlabs?.length) {
            setDefinedConcessionDirty(true);
        }

        if ((customSlab?.action === 'CREATE' || customSlab?.fromBE) && customSlab?.action !== 'DELETE') {
            setCustomSlab({ ...customSlab, isDirty: true, action: customSlab?.fromBE ? 'UPDATE' : 'CREATE' });
        }

        setExtraComponent([...val]);
        setFeeComps([...feeComps])

        const additionalSlabs = prospectusSlabs
            .filter(row => allSlabs.findIndex(slab => slab.id === row.id) === -1)
            .map(i => ({ ...i, action: i.fromBE ? "UPDATE" : "CREATE", concessionType: "DEFINED" }))
        setAllSlabs(slabs => ([...slabs, ...additionalSlabs]));

        // costCalculation();

    }

    useEffect(() => {
        setPaymentModificationAllowed(customSlab?.isDirty || isDefinedConcessionDirty || rowsCountInEditMode > 0 ? false : true);
    }, [isDefinedConcessionDirty, customSlab?.isDirty, rowsCountInEditMode])


    const handleAutoSwitch = async (flag) => {
        
        if (flag) {
            setAsyncLoader(true)
            await fetchAllPromisedData(`${endpoint.getAutoConcessions}/${applicationDetails?.applicationQuotationDetailsId}`).then(data => {
                const concessionData = data.map(i => {
                    //console.log(i.category ? checkForAutoConcession(i.category, i.autoConcessionType) : null)
                    return i.category !== null ? checkForAutoConcession(i.category, i.autoConcessionType, i?.concessionDataId ?? null) : null
                })?.filter(i => i !== null);

                const alreadyAppliedAutoConcession = prospectusSlabs
                    .filter(i => i.concessionMethodType?.split("_")?.[0] === 'AUTO')
                    .map(i => i.concessionMethodType);

                if (concessionData?.length) {
                    const newAutoConcessions = concessionData.filter(i => alreadyAppliedAutoConcession?.indexOf(i.concessionMethodType) === -1);
                    setProspectusSlabs([...prospectusSlabs, ...newAutoConcessions]);
                    setAllSlabs([...allSlabs, ...newAutoConcessions]);
                    mapGlobalFeeComponents([...prospectusSlabs, ...newAutoConcessions]);
                    if (newAutoConcessions?.length) {
                        setDefinedConcessionDirty(true);
                        
                    }
                    if (concessionData?.length && !newAutoConcessions?.length) {
                        failureToast("Auto concession already applied")
                    }
                        setRowsCountInEditMode(0)
                        setDefinedEditFormEnable(false)
                }
                setApplyAutoConcession(flag);
                setAsyncLoader(false)
                // costCalculation()
            }).catch(e => {
                setAsyncLoader(false)
                //console.log(e)
            })
        } else {
            setApplyAutoConcession(flag)
            setAsyncLoader(false)
        }
    }

    const headerComp = (
        <div className='d-flex justify-content-between w-100 align-items-center'>
            <div className='d-flex' onClick={(e) => e.stopPropagation()}>
                <h3 style={{ color: '#00AEEF' }} className={`mr-auto`}>Concession Status:</h3>
                <CommonInput newToggle={true} type={'toggleV3'} rightSecondaryLabel={'Apply auto-concession'} showToggleLabels={false} dataLabelOn={true} dataLabelOff={false} defaultValue={autoConcession} onChange={(flag) => handleAutoSwitch(flag)} isDisabled={disableActions || apprvalStatus === 'PENDING' || applicationDetails?.chargeStatus === 'VERY' || !paymentDetails?.id || ((apprvalStatus === 'APPROVED' && !allowModify) || (apprvalStatus === 'REJECTED' && !allowModify))} />
                {/* {cmprAllowed ? <CommonInput newToggle={true} type={'toggleV3'} rightSecondaryLabel={'Not bypass CMPR'} showToggleLabels={false} dataLabelOn={'CMPR'} dataLabelOff={'BYPASS_CMPR'} defaultValue={modificationType} onChange={(flag) => setModificationType(flag)} isDisabled={disableActions} /> : null} */}
            </div>
            
            <div className='d-flex justify-content-between' onClick={(e) => e.stopPropagation()}>
            <DiscretionarySupportingDocUploader
             userPermissions={userPermissions}
             viewOnly={true}
             onChange={()=>{}}
             defaultvalue={getAllUploadedDocuments()}
             viewText={true}
             buttonComponent={(clickHandler)=> <div className='dropdown-label mr-2 auto-min-width' onClick={clickHandler}>View All Documents</div>}
            />
                <Dropdown
                    placeHolder="Select fee components"
                    items={feeComponentCopyFromCourse}
                    // defaultValue={feeComps} 
                    extraComp={extraComponent}
                    customComponent={componentInCustom}
                    definedComponent={componentInDefined}
                    disable={(apprvalStatus === 'PENDING' || disableActions || apprvalStatus === 'REJECTED' || apprvalStatus === 'APPROVED') && !allowModify}
                    onChange={(val) => { handleFeeCompChange(val) }}
                    confirmFeeComponent={confirmFeeComponent}
                    setSelectedItems={setSelectedItems}
                    setErrorDialogOpen={setErrorDialogOpen}
                    prospectusSlabs={prospectusSlabs}
                    customSlab={customSlab}
                />

            </div>
        </div>
    )


    const handleRevoke = async () => {
        const canContinue = await confirm();
        if (canContinue === false) return;
        try {
            setLoading(true);
            const resp = await fetchAllPostPromisedData(`${endpoint.studentChargesConcessionDetails.revoke}`,
                { applicationQuotationId: applicationDetails?.applicationQuotationDetailsId, auditRemarks: canContinue }, 'put'
            );
            setLoading(false);
            if (resp && resp.code === 200) {
                successToast("Concession revoked successfully");
                getConcessionDetails();
                getApplicationDetails();
                 getPaymentDetails()
            } else {
                failureToast("Couldn't revoke Concession");
            }
        } catch (err) {
            setLoading(false);
            failureToast("Couldn't revoke Concession");
        }
    }

    // alert(JSON.stringify(apprvalStatus))

    const getApprovalDetails = async () => {

        const response = await fetchAllPostPromisedData(`${endpoint.approvalConcession.getAllApprovalRequests}?offset=0`, { psid: applicationDetails.psid, approvalStatus: `${getApprovalType(prospectusSlabs?.length ? prospectusSlabs?.[0]?.status : customSlab?.status)}_PENDING` }, 'post');
        if (response?.code === 200) {
            setApprovalResponse(response?.data?.approvalSearchResponseDTOS?.[0] ?? {});
            //console.log(response?.data?.approvalSearchResponseDTOS?.[0])
        }
    }

    useEffect(() => {
        // alert(action)
        // alert(applicationDetails?.psid)
        if (action === 'view' && applicationDetails?.psid) {
            getApprovalDetails();
        }
    }, [action, apprvalStatus, applicationDetails])

    const onButtonClick = (e, onClick) => {
        // if (!isDisabled) {
        onClick(e);
        // }
    }

    const handleModify = async () => {
        const canModify = await confirmModify();
        if (canModify === false) return
        setAllowModify(true)
    }

    const getEligibleComponentList = async (qid, returnResult = false) => {
        try {
            const components = await fetchAllPromisedData(`${endpoint.eligibleDepositSlips}/${qid}`, false, false);
            const feeBreak = {
                clientCode: '',
                erpRef: '',
                businessAreasCode: businessAreas?.[0]?.label,
                businessAreasName: businessAreas?.[0]?.desc,
                psid: applicationDetails?.psid ?? '',
                studentName: applicationDetails?.studentName ?? '',
                contactNumber: applicationDetails?.phoneNo ?? '',
                term: applicationDetails?.termValue,
                dueDate: '',
                regFee: 0,
                adminFee: 0,
                securityFee: 0,
                tutionFee: 0,
                techFee: 0,
                classFee: 0,
                name: '',
                course: courses?.[0]?.value,
                businessAreasId: businessAreas?.[0]?.value
            }

            const depositeData = []

            if (components?.code === 200 && components?.data) {
                let eligibilityComponent = components?.data?.eligibleDepositSlipResponseDTOList;
                const dateAndItemMapping = {};

                // security Fee
                if (components?.data?.securityAttributeDepositSlip?.netPayableAmount) {
                    let termInfo = {};
                    if (components?.data?.securityAttributeDepositSlip?.securityFeeTermId) {
                        termInfo = await fetchAllPromisedData(`${endpoint.term.getById}/${components?.data?.securityAttributeDepositSlip?.securityFeeTermId}`)
                    }
                    depositeData.push({ ...feeBreak, course: components?.data?.securityAttributeDepositSlip?.courseId, erpRef: components?.data?.securityAttributeDepositSlip?.id, term: termInfo?.termDispValue ?? applicationDetails?.termValue, clientCode: components?.data?.clientCode, securityFee: components?.data?.securityAttributeDepositSlip?.finalPayable, dispName: 'Security', name: 'Security', id: components?.data?.securityAttributeDepositSlip?.id, dueDate: moment(components?.data?.securityAttributeDepositSlip?.createdOn).format("DD-MMM-YYYY")?.toUpperCase() });
                }

                const lumpsumValues = ['LUMPSUM_First_Year', 'LUMPSUM_Year_On_Year', 'LUMPSUM_Full_Course']
                const isLumpsum = concessionDetails?.find(i => lumpsumValues.includes(i.concessionMethodType));
                const lumpsumYear = isLumpsum?.concessionMethodType === 'LUMPSUM_First_Year' ? 1 : isLumpsum?.concessionMethodType === 'LUMPSUM_Year_On_Year' ? studentCurrentYear : isLumpsum?.concessionMethodType === 'LUMPSUM_Full_Course' ? "*" : null;

                if (isLumpsum && lumpsumYear) {
                    if (lumpsumYear === "*") {
                        const ids = eligibilityComponent.map(comp => comp.itemType);
                        const techFeeAmt = parseFloat(eligibilityComponent.filter(comp => comp.itemType.slice(-2) === '04').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                        const tuitionFeeAmt = parseFloat(eligibilityComponent.filter(comp => comp.itemType.slice(-2) === '01').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                        const classFeeAmt = parseFloat(eligibilityComponent.filter(comp => comp.itemType.slice(-2) === '05').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                        const adminFee = parseFloat(eligibilityComponent.filter(comp => comp.itemType.slice(-2) === '30').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                        const regFee = parseFloat(eligibilityComponent.filter(comp => comp.itemType.slice(-2) === '29').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                        depositeData.push({ ...feeBreak, erpRef: eligibilityComponent?.[0]?.id, clientCode: components?.data?.clientCode, regFee: regFee, adminFee: adminFee, tutionFee: tuitionFeeAmt, techFee: techFeeAmt, classFee: classFeeAmt, dispName: 'Lumpsum', name: 'Lumpsum', dueDate: moment(eligibilityComponent?.[0]?.dueDate).format("DD-MMM-YYYY")?.toUpperCase(), feeBreakupId: [eligibilityComponent?.[0]?.id] });
                        dateAndItemMapping['Lumpsum'] = ids;
                    } else {
                        if (lumpsumYear !== 1) {
                            // Reg fee component logic
                            const regFeeComponent = eligibilityComponent?.filter(comp => comp.itemType.slice(-2) === '29');
                            if (regFeeComponent?.length) {
                                const regFeeAmt = regFeeComponent.reduce((acc, a) => acc + a.netPayable, 0);
                                depositeData.push({ ...feeBreak, erpRef: regFeeComponent?.[0]?.id, clientCode: components?.data?.clientCode, regFee: regFeeAmt, dispName: 'Reg Fee', name: 'Registration', dueDate: moment(regFeeComponent?.[0]?.dueDate).format("DD-MMM-YYYY")?.toUpperCase(), feeBreakupId: [regFeeComponent?.[0]?.id] });
                                dateAndItemMapping['Registration'] = [regFeeComponent?.[0]?.itemType];
                            }


                            // admin fee component logic
                            const adminFeeComponent = eligibilityComponent?.filter(comp => comp.itemType.slice(-2) === '30');
                            if (adminFeeComponent?.length) {
                                const adminFeeAmt = adminFeeComponent.reduce((acc, a) => acc + a.netPayable, 0);
                                depositeData.push({ ...feeBreak, erpRef: adminFeeComponent?.[0]?.id, clientCode: components?.data?.clientCode, adminFee: adminFeeAmt, dispName: 'Adm Kit', name: 'Admission', dueDate: moment(adminFeeComponent?.[0]?.dueDate).format("DD-MMM-YYYY")?.toUpperCase(), feeBreakupId: [adminFeeComponent?.[0]?.id] });
                                dateAndItemMapping['Admission'] = [adminFeeComponent?.[0]?.itemType];
                            }
                        }
                        const year = eligibilityComponent.filter(comp => comp.year === lumpsumYear);
                        if (year?.length) {
                            const ids = year.map(comp => comp.itemType);
                            const techFeeAmt = parseFloat(year.filter(comp => comp.itemType.slice(-2) === '04').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            const tuitionFeeAmt = parseFloat(year.filter(comp => comp.itemType.slice(-2) === '01').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            const classFeeAmt = parseFloat(year.filter(comp => comp.itemType.slice(-2) === '05').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            const adminFee = parseFloat(year.filter(comp => comp.itemType.slice(-2) === '30').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            const regFee = parseFloat(year.filter(comp => comp.itemType.slice(-2) === '29').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            depositeData.push({ ...feeBreak, erpRef: year?.[0]?.id, clientCode: components?.data?.clientCode, regFee: regFee, adminFee: adminFee, tutionFee: tuitionFeeAmt, techFee: techFeeAmt, classFee: classFeeAmt, dispName: 'Lumpsum', name: 'Lumpsum', dueDate: moment(year?.[0]?.dueDate).format("DD-MMM-YYYY")?.toUpperCase(), feeBreakupId: [year?.[0]?.id] });
                            dateAndItemMapping['Lumpsum'] = ids;
                        }

                        const others = eligibilityComponent.filter(comp => comp.year !== lumpsumYear);
                        const groupedData = others && others?.reduce((result, current) => {
                            const dueDate = current.itemType?.slice(-4, -2);
                            if (!result[dueDate]) {
                                result[dueDate] = [];
                            }

                            result[dueDate].push({ ...current });

                            return result;
                        }, {});

                        Object.keys(groupedData).forEach((installmentData, i) => {
                            const techFeeAmt = parseFloat(groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            const tuitionFeeAmt = parseFloat(groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '01').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            const classFeeAmt = parseFloat(groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '05').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            if (techFeeAmt || tuitionFeeAmt || classFeeAmt) {
                                const erpRef = Math.max(...groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(obj => obj.id));
                                const ids = groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(i => i.itemType);
                                const feeBreakupId = groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(i => i.id);
                                depositeData.push({ ...feeBreak, erpRef: erpRef, clientCode: components?.data?.clientCode, dispName: `INST#${ids?.[0]?.slice(-4, -2)}`, name: `${getOrdinal(ids?.[0]?.slice(-4, -2))} Installment`, dueDate: moment(installmentData).format("DD-MMM-YYYY")?.toUpperCase(), tutionFee: tuitionFeeAmt, techFee: techFeeAmt, classFee: classFeeAmt, feeBreakupId: feeBreakupId });
                                dateAndItemMapping[`${getOrdinal(ids?.[0]?.slice(-4, -2))}_Installment`] = ids;
                            }
                        })
                    }

                } else {
                    // Reg fee component logic
                    const regFeeComponent = eligibilityComponent?.filter(comp => comp.itemType.slice(-2) === '29');
                    if (regFeeComponent?.length) {
                        const regFeeAmt = regFeeComponent.reduce((acc, a) => acc + a.netPayable, 0);
                        depositeData.push({ ...feeBreak, erpRef: regFeeComponent?.[0]?.id, clientCode: components?.data?.clientCode, regFee: regFeeAmt, dispName: 'Reg Fee', name: 'Registration', dueDate: moment(regFeeComponent?.[0]?.dueDate).format("DD-MMM-YYYY")?.toUpperCase(), feeBreakupId: [regFeeComponent?.[0]?.id] });
                        dateAndItemMapping['Registration'] = [regFeeComponent?.[0]?.itemType];
                    }


                    // admin fee component logic
                    const adminFeeComponent = eligibilityComponent?.filter(comp => comp.itemType.slice(-2) === '30');
                    if (adminFeeComponent?.length) {
                        const adminFeeAmt = adminFeeComponent.reduce((acc, a) => acc + a.netPayable, 0);
                        depositeData.push({ ...feeBreak, erpRef: adminFeeComponent?.[0]?.id, clientCode: components?.data?.clientCode, adminFee: adminFeeAmt, dispName: 'Adm Kit', name: 'Admission', dueDate: moment(adminFeeComponent?.[0]?.dueDate).format("DD-MMM-YYYY")?.toUpperCase(), feeBreakupId: [adminFeeComponent?.[0]?.id] });
                        dateAndItemMapping['Admission'] = [adminFeeComponent?.[0]?.itemType];
                    }

                    const groupedData = eligibilityComponent && eligibilityComponent?.reduce((result, current) => {
                        const dueDate = current.itemType?.slice(-4, -2);
                        if (!result[dueDate]) {
                            result[dueDate] = [];
                        }

                        result[dueDate].push({ ...current });

                        return result;
                    }, {});

                    Object.keys(groupedData).forEach((installmentData, i) => {
                        const techFeeAmt = parseFloat(groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                        const tuitionFeeAmt = parseFloat(groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '01').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                        const classFeeAmt = parseFloat(groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '05').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                        if (techFeeAmt || tuitionFeeAmt || classFeeAmt) {
                            const erpRef = Math.max(...groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(obj => obj.id));
                            const ids = groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(i => i.itemType);
                            const feeBreakupId = groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(i => i.id);
                            depositeData.push({ ...feeBreak, erpRef: erpRef, clientCode: components?.data?.clientCode, dispName: `INST#${ids?.[0]?.slice(-4, -2)}`, name: `${getOrdinal(ids?.[0]?.slice(-4, -2))} Installment`, dueDate: moment(installmentData).format("DD-MMM-YYYY")?.toUpperCase(), tutionFee: tuitionFeeAmt, techFee: techFeeAmt, classFee: classFeeAmt, feeBreakupId: feeBreakupId });
                            dateAndItemMapping[`${getOrdinal(ids?.[0]?.slice(-4, -2))}_Installment`] = ids;
                        }
                    })
                }

                if (returnResult) {
                    //console.log(depositeData)
                    //console.log(dateAndItemMapping)
                    return { depositeData, dateAndItemMapping };
                } else {
                    const fileNameArr = depositeData.map(i => i.name.split(' ').join('_') + '.pdf');
                    const qrCode = await getBusinessAreaQR(businessAreas?.[0]?.value);
                    getFileUploadUrls(fileNameArr, depositeData, dateAndItemMapping, qrCode ? qrCode : null);
                }

            } else {
                //console.log('failed tp generate deposite slip no component eligible');
                return { depositeData: [], dateAndItemMapping: {} };
                // setLoading(false);
                // setAsyncLoader(false)
            }
        } catch (e) {
            //console.log(e)
            return { depositeData: [], dateAndItemMapping: {} };
            // setLoading(false);
            // setAsyncLoader(false)
        }

    }

    //above code needs to be refactor

    const onActionClick = async (approvalStatus, remarks, row, closeForm) => {
        setSaving(true);
        setAsyncLoader(true);
        const approvalType = getApprovalType(prospectusSlabs?.length ? prospectusSlabs?.[0]?.status : customSlab?.status);
        const payload = [getUpdatedRow(row, approvalType, approvalStatus, remarks, userDetails)];


        try {
            const response = await fetchAllPostPromisedData(endpoint.approval.status, payload, 'put');
            if (response?.code === 200) {
                successToast(getSuccessMsg(approvalStatus));
                closeForm();
                if (response.data?.[0]?.concessionDetailsDtoList?.[0]?.isApproved && applicationDetails?.chargeStatus === 'PAPP') {
                    const { groupedData, itemTypeLabelMapping, total } = await getBreakUp({ data: feeBreakupData }, { data: itemTypes });
                    const { totalConsession, concessionAppliedList } = await getConsessionDetails(response.data?.[0]?.concessionDetailsDtoList, total, studentCurrentYear);
                    const { tempData, totalTempData, percentPendingForApproval } = createBreakUpData(groupedData, totalConsession, {}, concessionAppliedList, studentCurrentYear, response.data?.[0]?.concessionDetailsDtoList);
                    const feeBreakup = getFeeBreakup({ data: tempData, total: totalTempData }, row?.applicationQuotationDetailsId, true)
                    const csummary = getCourseFeeSummary(applicationDetails, { data: tempData, total: totalTempData }, response.data?.[0]?.concessionDetailsDtoList, businessAreas, courses, allCategories, paymentDetails?.loanSchemeMasterId);
                    updateQuestionStatus('APPL', {}, csummary, {}, feeBreakup, null, applicationDetails?.applicationQuotationDetailsId, (data) => { getApplicationDetails(); getPaymentDetails(); closeForm(); }, () => { setAsyncLoader(false); setSaving(false); }, courses, true, (qid) => getEligibleComponentList(qid, true), (...rest) => getFileUploadUrls(...rest, false))
                } else {
                    getConcessionDetails();
                    getApplicationDetails();
                    closeForm();
                    setAsyncLoader(false);
                }

            } else {
                failureToast("Details not saved.");
                setAsyncLoader(false);
            }
            setSaving(false);

        } catch (err) {
            console.log(err)
            setSaving(false);
            setAsyncLoader(false);
            failureToast("Details not saved.");
        }
    }

    const isDocumentAttached = () => {

        let tempConcessionDetails = concessionDetails;
        let allConcessionSlabs = [...allSlabs, customSlab];

        allConcessionSlabs.forEach((slab => {
            if (slab?.action === 'CREATE') tempConcessionDetails.push(slab);
            else if (slab?.action === 'DELETE') tempConcessionDetails = tempConcessionDetails.filter(el => el?.id !== slab?.id);
            else if (slab?.action === 'UPDATE') tempConcessionDetails[tempConcessionDetails.findIndex(el => el?.id === slab?.id)] = slab;
        }))

        let isDocumentAttached = false;

        tempConcessionDetails.forEach(slab => {
            if (slab?.concessionDocuments?.length > 0) isDocumentAttached = true;
        })

        return isDocumentAttached;
    }

    return (
        <Accordion
            isFormOpen={isOpen.concessionsTab}
            title={`Concession`}
            key={'Concession_Details'}
            defaultViewComp={defaultViewComp}
            isEditFormOpen={(open) => {
                open === false ?
                    setIsOpen(state => ({ ...state, concessionsTab: false })) :
                    setIsOpen({
                        detailsTab: false,
                        paymentTab: false,
                        concessionsTab: true,
                        finalTab: false
                    })
            }}
            headerComp={headerComp}
        >
            {loading ?
                <CustomLoader /> :
                <div id="student-charges-concession">
                    <ProspectusConcession
                        userPermissions={userPermissions}
                        feeComps={feeComps}
                        setFeeComps={setFeeComps}
                        applicationDetails={applicationDetails}
                        prospectusSlabs={prospectusSlabs}
                        setProspectusSlabs={setProspectusSlabs}
                        allSlabs={allSlabs}
                        setAllSlabs={setAllSlabs}
                        setRowsCountInEditMode={setRowsCountInEditMode}
                        allCategories={allCategories}
                        open={open.propspectus}
                        setOpen={setOpen}
                        categoryCodeData={categoryCodeData.filter(item => item.value !== CUSTOM_CONCESSION_CODE)}
                        rowsCountInEditMode={rowsCountInEditMode}
                        disableActions={disableActions}
                        // setAutoConcession={setAutoConcession}
                        // autoConcession={autoConcession}
                        customSlab={customSlab}
                        mapGlobalFeeComponents={mapGlobalFeeComponents}
                        extraComponent={extraComponent}
                        setEditFormOpen={setDefinedEditFormEnable}
                        setDefinedConcessionDirty={setDefinedConcessionDirty}
                        freezeAction={["PENDING", "REJECTED", "APPROVED"].includes(apprvalStatus) && !allowModify}
                        paymentDetails={paymentDetails}
                        selectedQoutationId={selectedQoutationId}
                    // setAutoConcession={setAutoConcession}
                    // autoConcession={autoConcession}
                        fee={fee}

                    />
                    <DiscretionaryConcession
                        userPermissions={userPermissions}
                        customSlab={customSlab}
                        setCustomSlab={setCustomSlab}
                        // setAllSlabs={setAllSlabs}
                        regFee={fee.regFee}
                        adminFee={fee.adminFee}
                        classFee={fee.classFee}
                        techFee={fee.techFee}
                        tutionFee={fee.tutionFee}
                        open={open.custom}
                        customConfig={customConfig}
                        setOpen={setOpen}
                        allCategories={allCategories}
                        saveAndSend={handleSaveAndNext}
                        applicationDetails={applicationDetails}
                        isViewOnly={action === 'view'}
                        fetching={fetching}
                        categoryFeeComps={categoryFeeComps}
                        disableActions={disableActions}
                        extraComponent={extraComponent}
                        setEditFormOpen={setCustomEditFormEnable}
                        isCustomEditFormEnable={isCustomEditFormEnable}
                        // handleCustomFeeComponentInGlobleSelector={handleCustomFeeComponentInGlobleSelector}
                        freezeAction={(["PENDING", "REJECTED", "APPROVED"].includes(apprvalStatus) && !allowModify)}
                        // deleteCusFeeCompInGlobleSelector={deleteCusFeeCompInGlobleSelector}
                        feeComps={feeComps}
                        setComponentInCustom={setComponentInCustom}
                        paymentDetails={paymentDetails}
                        selectedQoutationId={selectedQoutationId}
                        viewMode={customConcTableFormViewMode}
                        setViewMode={setCustomConcTableFormViewMode}
                    />
                    {maxConcReached && <Box className='warning-message' sx={{ marginBottom: '15px' }}>
                        <TiWarning color={'#FFF'} size={18} />
                        <Typography color={'#FFF'} fontSize={14}>&nbsp;Note: 100% concession is applied</Typography>
                        {/* <Typography color={'#FFF'} fontWeight={600} fontSize={14}>&nbsp;concession discount</Typography> */}
                    </Box>}

                    <GetDisplayData status={prospectusSlabs?.length ? prospectusSlabs?.[0]?.status : customSlab?.status} isApproved={prospectusSlabs?.length ? prospectusSlabs?.[0]?.isApproved : customSlab?.isApproved} />

                    {
                        action !== 'view' && canRevoke(prospectusSlabs?.length ? prospectusSlabs?.[0]?.status : customSlab?.status, prospectusSlabs?.length ? prospectusSlabs?.[0]?.isApproved : customSlab?.isApproved) ?
                            <CustomButton
                                className={'bg-red-60 mb-3'}
                                content={"Revoke"}
                                permissionType={'C,U'}
                                permissionSet={userPermissions}
                                onClick={handleRevoke}
                                disabled={(Array.isArray(userPermissions) && userPermissions?.findIndex(e => e === 'A') === -1) || disableActions}
                            />
                            : null
                    }
                    {/* <span className='mr-3' /> */}
                    {(isDefinedConcessionDirty || customSlab?.isDirty || allowModify || (!prospectusSlabs?.length && !customSlab?.scholarshipPercentage)) && action !== 'view' ?
                        <CustomButton
                            className="mb-3"
                            content={'Save & next'}
                            permissionType={'C,U'}
                            forTable={false}
                            permissionSet={userPermissions}
                            onClick={applicationDetails?.isAtLeastOnceFinalised?cmprApplyFlowHandler : handleSaveAndNext}
                            disabled={disableActions || isDefinedEditFormEnable || (!isDefinedConcessionDirty && !customSlab?.isDirty) || (isCustomEditFormEnable && ((customSlab?.action !== 'DELETE' && customSlab?.fromBE) || customSlab?.action === 'CREATE')) || (!customConcTableFormViewMode && !isCustomConcEmpty())}
                        /> : null
                    }
                    {action !== 'view' && (prospectusSlabs?.length || customSlab?.scholarshipValue) && (extraComponent?.length || componentInCustom?.length) && ((extraComponent?.length || componentInCustom?.length || componentInDefined?.length) && (apprvalStatus === 'NA' || apprvalStatus === 'PENDING' || apprvalStatus === 'RECHECK')) && !isDefinedConcessionDirty && apprvalStatus !== 'REJECTED' && !customSlab?.isDirty && !canRevoke(prospectusSlabs?.length ? prospectusSlabs?.[0]?.status : customSlab?.status, prospectusSlabs?.length ? prospectusSlabs?.[0]?.isApproved : customSlab?.isApproved) && action !== 'view' ?
                        <CustomButton
                            className="mb-3"
                            content={'Send for approval'}
                            permissionType={'C,U'}
                            forTable={false}
                            permissionSet={userPermissions}
                            // onClick={handleSendApproval}
                            onClick={openMandatoryDocDialog}
                            disabled={disableActions || isDefinedEditFormEnable || (isCustomEditFormEnable && customSlab?.scholarshipValue)}
                        /> : null
                    }
                    {
                        action !== 'view' && (apprvalStatus === 'APPROVED' || apprvalStatus === 'REJECTED') && !customSlab?.isDirty && !isDefinedConcessionDirty && !allowModify ?
                            <CustomButton
                                className="mb-3"
                                content={'Modify'}
                                permissionType={'C,U'}
                                forTable={false}
                                permissionSet={userPermissions}
                                onClick={() => { handleModify() }}
                                disabled={disableActions}
                            /> : null
                    }

                    {!hideDiscardButton && action !=='view' && (['CONC', 'PAPP', 'APPL']?.includes(applicationDetails?.chargeStatus)) &&
                        !canRevoke(prospectusSlabs?.length ? prospectusSlabs?.[0]?.status : customSlab?.status, prospectusSlabs?.length ? prospectusSlabs?.[0]?.isApproved : customSlab?.isApproved) &&
                        ((allSlabs?.length > 0) || (customSlab?.isDirty)) &&
                        <>
                            <span className='ml-2' />
                            <CustomButton
                                className="mb-3"
                                content={'Discard'}
                                permissionType={'C,U'}
                                forTable={false}
                                permissionSet={userPermissions}
                                onClick={() => {
                                    constructProspectusAndCustomSlabs()
                                }}
                            />
                        </>
                    }

                    {Object.keys(approvalResponse)?.length && customConfig && approvalFlowVisible(customConfig, getApprovalType(prospectusSlabs?.length ? prospectusSlabs?.[0]?.status : customSlab?.status), JSON.parse(getCookie('roleId')).id, applicationDetails?.businessAreaId, applicationDetails?.courseCategoryId) && apprvalStatus === 'PENDING' && action === 'view' && (Array.isArray(userPermissions) && userPermissions?.includes('A')) ? <div><Stack height="64px" alignItems="center" sx={{
                        background: '#E5F5FF',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        padding: '0px 10px',
                        borderRadius: '10px'
                    }}>
                        <span className='regular-bolder'>Requested by {`${approvalResponse?.createdBy ?? ''}`}</span>
                        <div>
                            <APopover
                                buttonComponent={(onClick, styles) => <Box
                                    onClick={e => onButtonClick(e, onClick)}
                                >
                                    <CustomButton
                                        className='approval-action-approve-button'
                                        content={"Approve"}
                                        // forTable={true}
                                        permissionType={'A'}
                                        permissionSet={userPermissions}
                                        onClick={() => { }}
                                    // disabled={isDisabled}
                                    /></Box>}
                                menuComponent={(closeForm) => (
                                    <ApprovalDialogContent row={approvalResponse} documentAttached={approvalResponse?.isDocumentAttached} applicationConcessionDetailsId={approvalResponse?.applicationConcessionDetailsId} applicationId={approvalResponse?.applicationId} userPermissions={userPermissions} saving={saving} handleClose={closeForm} onActionClick={(remarks) => { onActionClick(APPROVAL_STAGE.APPROVED, remarks, approvalResponse, closeForm) }} width={324} action={APPROVAL_ACTIONS.APPROVE} applicationQuotationDetailsId={approvalResponse.applicationQuotationDetailsId} overrideParams={true} getApprovalDetails={getApprovalDetails} customConfig={customConfigObject} />
                                )}
                            />
                            <span className='ml-2' />
                            <APopover
                                buttonComponent={(onClick, styles) => <Box
                                    onClick={e => onButtonClick(e, onClick)}
                                >
                                    <CustomButton
                                        className='approval-action-reject-button bg-red-40'
                                        // forTable={true}
                                        type="cancel"
                                        content={'Reject'}
                                        permissionType={'A'}
                                        permissionSet={userPermissions}
                                        onClick={() => { }}
                                    // disabled={isDisabled}
                                    /></Box>}
                                menuComponent={(closeForm) => (
                                    <ApprovalDialogContent row={approvalResponse} userPermissions={userPermissions} handleClose={closeForm} onActionClick={(remarks) => { onActionClick(APPROVAL_STAGE.REJECTED, remarks, approvalResponse, closeForm) }} width={324} action={APPROVAL_ACTIONS.REJECT} saving={saving} overrideParams={true} getApprovalDetails={getApprovalDetails} customConfig={customConfigObject} />
                                )}
                            />
                            <span className='ml-2' />
                            <APopover
                                buttonComponent={(onClick, styles) => (Array.isArray(userPermissions) && userPermissions?.includes('A')) ? <Typography
                                    className='approval-action-recheck-button cursor'
                                    variant="caption"
                                    display="block"
                                    color={'#00B0F5'}
                                    onClick={e => onButtonClick(e, onClick)}
                                >
                                    Recheck
                                </Typography> : null}
                                menuComponent={(closeForm) => (
                                    <ApprovalDialogContent row={approvalResponse} userPermissions={userPermissions} handleClose={closeForm} onActionClick={(remarks) => { onActionClick(APPROVAL_STAGE.RECHECK, remarks, approvalResponse, closeForm) }} width={324} action={APPROVAL_ACTIONS.RECHECK} saving={saving} overrideParams={true} getApprovalDetails={getApprovalDetails} customConfig={customConfigObject} />
                                )}
                            />
                        </div>
                    </Stack> </div> : null}

                </div>
            }
            <ConfirmationDialog
                message={` `} title={'Are sure you want to revoke the charges?'}
                nextButtonText={"Yes, Continue"}
                backButtonText={"No"}
                leftAlign={true}
                isInputVisible={true}
                titleStyles={{ fontSize: '24px', fontWeight: 700, color: '#000' }}
                crossIconRequired={true}
                modalStyles={{ width: '400px' }}
                backButtonStyles={{ borderRadius: '12px', border: '2px solid #00B0F5', color: '#00B0F5', backgroundColor: '#FFFFFF' }}
                nextButtonStyles={{ borderRadius: '12px', border: '2px solid #00B0F5', color: '#00B0F5', backgroundColor: '#FFFFFF' }}
                txtAreaStyles={{ borderRadius: '12px', padding: '8px' }}
                iconStyles={{ width: '48px', height: '48px' }}
                txtAreaContainerWidth={100}
                nextBtnClasses={'outline-btn'}
            />

            <ErrorDialog
                isOpen={errorDialogOpen}
                setIsOpen={setErrorDialogOpen}
                type={'info'}
                msg={''}
                crossIconRequired={true}
                headerMsg={"Please add and save at least 1 concession category to select fee component(s)"}
                popupSymbol={<Warning />}
                onAgree={() => setErrorDialogOpen(false)}  // take to batch search page
                iconStyle={{
                    alignSelf: 'baseline',
                    backgroundColor: '#FFEDC8',
                    borderColor: '#FFEDC8'
                }}
                buttonStyle={{
                    border: '2px solid #008DC4',
                    width: '50%',
                    color: '#008DC4',
                    backgroundColor: 'transparent',
                    marginLeft: '0px!important',
                    borderRadius: '12px'
                }}
                headerMsgStyles={{
                    fontSize: '24px',
                    fontWeight: '700'
                }}
                nextBtnClasses={'no-ml-btn'}
            />

            <ErrorDialog
                isOpen={maxErrorDialogOpen}
                setIsOpen={setMaxErrorDialogOpen}
                type={'info'}
                msg={'Amount entered exceeds the amount allowed in Fee components, please decrease the amount to proceed'}
                headerMsg={"Total concession cannot be more than 100%."}
                popupSymbol={<Warning />}
                onAgree={() => setMaxErrorDialogOpen(false)}  // take to batch search page
                iconStyle={{
                    alignSelf: 'baseline',
                    backgroundColor: '#FFEDC8',
                    borderColor: '#FFEDC8'
                }}
                buttonStyle={{
                    border: '2px solid #008DC4',
                    width: '50%',
                    color: '#000',
                    backgroundColor: 'transparent',
                    marginLeft: '0px'
                }}
                crossIconRequired={true}
            />
            <ConfirmationFeeComponentDialog message={`Do you want to proceed?`} title={`On selecting ${selectedItems} component for applying concession, Approval will be required.`} nextButtonText={"Yes, Continue"} backButtonText={"No"} leftAlign={true} isInputVisible={false} />
            <ConfirmationModifyDialog
                message={'Are you sure you want to Modify the charges?'}
                title={'A new approval request for the modified charges will need to be submitted.'}
                nextButtonText={"Yes, Confirm"}
                backButtonText={"No"}
                leftAlign={true}
                isInputVisible={false}
                crossIconRequired={true}
                backButtonStyles={{ borderRadius: '12px', border: '2px solid #00B0F5', color: '#00B0F5', backgroundColor: '#FFFFFF' }}
                nextButtonStyles={{ borderRadius: '12px', border: '2px solid #00B0F5', color: '#00B0F5', backgroundColor: '#FFFFFF' }}
                nextBtnClasses={'outline-btn'}
                backBtnClasses={'outline-btn'}
            />
            <ApplyCmprDialog  
                closeHandler = {()=>setApplyCmpr({isOpenDialog:false, isApplicableForCmpr:false})} 
                open = {applyCmpr?.isOpenDialog}
                checkboxHandler ={(isApply)=>setModificationType(isApply)}
                saveHandler={handleSaveAndNext}
                disableCheckBox = {!applyCmpr?.isApplicableForCmpr}
                isUnApprovedConcessionMax={applyCmpr?.isUnApprovedConcessionMax}
            />
            <AppliedCmprSuccessDialog
              cmprSuccessDialog = {cmprSuccessDialog}
              setCmprSuccessDialog = {setCmprSuccessDialog}
            />

            <MandatoryDocUploadDialog
            open={mandatoryDocDialogOpen}
            applicationQuotationDetailsId={applicationDetails?.applicationQuotationDetailsId ?? paymentDetails?.id} 
            getConcessionDetails={getConcessionDetails}
            concessionDetails={concessionDetails}
            docRequiredForCustomConcession={docRequiredForCustomConcession}
            allCategories={allCategories}
            customConfig={customConfig}
            totalConcessionAppliedPercentage={totalConcession?.totalConcessionAppliedPercentage}
            handleSendApproval={handleSendApproval}
            loading={loading}
            setMandatoryDocDialogOpen={setMandatoryDocDialogOpen}
            />
        </Accordion>
    )
}

export default ConcessionDetails;