import React from 'react'
import { ReactComponent as Cross } from '../../../../../assets/img/svg/Union.svg';


const chipStyles = (crossIcon) => {
  return  {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4px 11px',
    gridGap: '6px',
    gap: '6px',
    minWidth: crossIcon ? '99px' : '',
    height: '25px',
    background: '#e9ecef',
    border: '1px solid #cccccc',
    borderRadius: '8px',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    margin: '1.5px 1px',
    fontSize: '15px',
  }
}

const RegionBusinessAreaCustomChip = ({ text, onDelete = () => { }, crossIcon = true }) => {
  return (
    <div style={chipStyles(crossIcon)}>
      {text}
      {crossIcon && <Cross onClick = {onDelete}/>}
    </div>
  )
}

export default RegionBusinessAreaCustomChip