import React from "react";
import AButton from "../../../../common/form-fields-mui/AButton";
import SelectMaterialInput from "./SelectMaterialInput";
import { useGetContext } from "../../contextApi";
import { ViewGroupMaterials } from "./ViewGroupMaterials";
const SelectMaterial = ({
  nextHandler,
  checkQtyHandler,
  isMaterialQtyChecked,
  setIsProcessedBtnDisabled,
  isProcessedBtnDisabled,
  isCheckQuantity,
  setIsCheckQuantity,
  isSelectedMaterialFromGp1,
  materialGroups,
}) => {
  const { selectedMaterials } = useGetContext();

  const isProcessedBtnDisable = (allSelectedMaterials = []) => {
    const isValidMaterialExist =
      allSelectedMaterials.filter((material) => material?.qty > 0).length > 0;
    return !(isValidMaterialExist && isProcessedBtnDisabled);
  };

  return (
    <div className="d-flex">
      <div
        style={{ minHeight: "64vh", position: "relative", minWidth: "60%" }}
      >
        <div className="heading-3 color-dark mb-2">
          {" "}
          Select material that you want to issue
        </div>
        <div className="regular-bold color-dark mt-2 mb-1">
          Material number & description
        </div>
        <div className="" style={{ width: "60%" }}>
          <SelectMaterialInput
            isMaterialQtyChecked={isMaterialQtyChecked}
            setIsProcessedBtnDisabled={setIsProcessedBtnDisabled}
            setIsCheckQuantity={setIsCheckQuantity}
          />
        </div>
        <div style={{ position: "absolute", bottom: "20px" }}>
          <AButton
            onClick={checkQtyHandler}
            className="button_remove_left_margin"
            variant="outline"
            disabled={!isCheckQuantity || selectedMaterials.length === 0}
          >
            Check quantity
          </AButton>
          <AButton
            onClick={nextHandler}
            className=""
            variant="primary_filled"
            disabled={isProcessedBtnDisable(selectedMaterials)}
          >
            Proceed to issue stock
          </AButton>
        </div>
      </div>
      {isSelectedMaterialFromGp1 && (
        <ViewGroupMaterials materialGroups={materialGroups} />
      )}
    </div>
  );
};

export default SelectMaterial;
