import React, { useState, useEffect, useContext, useRef } from 'react';
import { TbDatabase } from 'react-icons/tb';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Typography } from '@material-ui/core';
import { ConsessionSlabModal } from './addConsessionModal';
import Modal from '@mui/material/Modal';
import { failureToast, successToast } from '../../../../../pages/manage/common/utils/methods/toasterFunctions/function';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { ConfirmationDialog } from 'views/pages/manage/common/commonComponents';
import { Card, CardHeader, Container } from 'reactstrap';
import { SlabFilter } from './slabFilter';
import { FaTimes, FaTimesCircle } from 'react-icons/fa';
import { PermissionContext, RolePermissions } from "../../../../../../appContext";
import { endpoint, pages } from '../../../common/constant';
import moment from "moment";
import { ConcessionSlabContext } from '../index';
import { IconButton, Tooltip } from '@mui/material';
import { PermisionDenied } from 'views/pages/manage/common/commonComponents';

const AddConcessionSlab = ({
  category,
  subCategory = null,
  subSubCategory = null,
  forSubSubCategory = false
}) => {

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["concessionSlab"]['id']);

  const subCategoryRef = useRef(null);
  const subSubCategoryRef = useRef(null);

  const [slabModalOpen, setSlabModalOpen] = useState(false);
  const handleSlabModalOpen = () => setSlabModalOpen(true);
  const handleSlabModalClose = () => setSlabModalOpen(false);
  
  const [dataLossOpen, setDataLossOpen] = useState(false);

  const {concessionSlabData, setConcessionSlabData} = useContext(ConcessionSlabContext);
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [filters, setFilters] = useState({
    career: [],
    acadGruoup: [],
    courseCategory: [],
    term: [],
    businessArea: [],
    courseId: [],
    courseCategoryId:[],
    effectiveFrom: '',
    expiryDate: '',
    status:''
  })
  const [filterStatus, setFilterStatus] = useState({
    applying: false,
    applied: false
  })

  const applyFilters = async () => {
    if (!filters.career?.length && !filters.acadGruoup?.length && !filters.courseCategory?.length && !filters.term?.length && !filters.effectiveDate && !filters.expiryDate && !filters.status) {
      failureToast("Please Select atlease one Criteria");
      return
    }

    const request = {
      subCategoryId: !subSubCategory?.id ? subCategory?.id : null,
      subSubCategoryId: subSubCategory?.id ?? null,
      academicCareerId: filters.career?.map(i => i.value) ?? [],
      academicGroupId: filters.acadGruoup?.map(i => i.value) ?? [],
      termId: filters.term?.map(i => i.value) ?? [],
      courseId: filters.courseId?.map(i => i.value) ?? [],
      courseCategoryId: filters.courseCategoryId?.map(i => i.value) ?? [],
      businessAreaId: filters.businessArea?.map(i => i.value) ?? [],
      effectiveFrom: filters.effectiveFrom,
      effectiveTo: filters.expiryDate,
      status : filters?.status?.value
    }

    setFilterStatus({ ...filterStatus, applying: true });

    const response = await fetchAllPostPromisedData(endpoint.allConsessionslabs, request, 'post')
    if (response?.code === 200) {
      setFilterStatus({ ...filterStatus, applying: false, applied: true });
      setConcessionSlabData(response.data)
      successToast(response['message']);
      setOpenFilterMenu(false)
    } else {
      setConcessionSlabData([])
      setFilterStatus({ ...filterStatus, applying: false, applied: true });
    }
  }

  const getConcessionDetails = async () => {
    try {
      const response = await fetchAllPromisedData(
        subSubCategory ? `${endpoint.getConcessionSlabBySubSubCategory}/${subSubCategory.id}` :
          `${endpoint.getConcessionSlabBySubCategory}/${subCategory.id}`
      );
      if (response) {
        setConcessionSlabData(response)
      } else {
        failureToast("Failed to fetch Data");
      }
    } catch (error) {
      failureToast("Failed to fetch Data");
    }
  }

  const removeFilterHandler = () => {
    setFilters({
      career: [],
      acadGruoup: [],
      courseCategory: [],
      term: [],
      businessArea: [],
      courseId: [],
      effectiveFrom: '',
      expiryDate: '',
      status: '',
    })

    setFilterStatus({ applying: false, applied: false });
    getConcessionDetails();
    setOpenFilterMenu(false)
  }


  // useEffect(() => {
  //   if((subCategoryRef.current === null && subCategory) || (subCategoryRef.current && subCategory && subCategoryRef.current.id !== subCategory.id) || 
  //   (subSubCategoryRef.current && subSubCategory && subSubCategoryRef.current.id !== subSubCategory.id)) {
  //     getConcessionDetails();
  //   }
  //   subCategoryRef.current = subCategory;
  //   subSubCategoryRef.current = subSubCategory;
  // }, [subSubCategory, subCategory]);

  useEffect(()=>{
    !forSubSubCategory && removeFilterHandler();
  },[subCategory?.id])

  useEffect(()=>{
     forSubSubCategory && removeFilterHandler();
  },[subSubCategory?.id])


  const getAppliedFilterCount = () => {
    let count = 0;
    if (filterStatus.applied) {
      Object.keys(filters).map(item => {
        if (filters[item]?.length || ((item === 'effectiveFrom' || item === 'expiryDate' || item === 'status') && filters[item] !== '')) {
          count = count + 1;
        }
      })
    }
    return count
  }

  return (
    userPermissions ? (
      <>
        {concessionSlabData && <>
          <div onClick={handleSlabModalOpen} style={{ borderTop: '1px solid #B0B0B0', cursor: 'pointer' }} className='d-flex pt-4 mt-1'>
            <div style={{ width: '56px', height: '56px', backgroundColor: '#00B0F5' }} className='d-flex align-items-center justify-content-center rounded-circle'>
              <TbDatabase color='#fff' size={18} />
            </div>
            <div className='d-flex flex-column justify-content-center ml-4 flex-fill'>
              <Typography style={{ fontSize: 18, color: '#0F0F0F' }}>{concessionSlabData.length ? 'Concession slab' : 'Add concession slab'}</Typography>
              <Typography style={{ fontSize: 12, color: '#757575' }}>{concessionSlabData.length ? `Last updated: ${moment(concessionSlabData[0].updatedOn).utcOffset("+05:30").format("DD/MM/YYYY hh:mm A")}` : 'Click here to add concession slab'}</Typography>
            </div>
            <div className='d-flex align-items-center floatRight'><MdKeyboardArrowDown size={20} /></div>
          </div>

          {/* Concession Slab Modal */}
          <Modal
            md={{ width:'100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            id='concessionModalRoot'
            fullWidth="true"
            open={slabModalOpen}
            backdrop="false"
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div>
              <ConsessionSlabModal
                category={category}
                subCategory={subCategory}
                subSubCategory={subSubCategory}
                onClose={(formDirty) => formDirty ? setDataLossOpen(true) : handleSlabModalClose()}
                onSave={getConcessionDetails}
                concessionPayload={concessionSlabData}
                openFilterMenu={openFilterMenu}
                setOpenFilterMenu={setOpenFilterMenu}
                getAppliedFilterCount={getAppliedFilterCount}
                userPermissions={userPermissions}
              />
            </div>
          </Modal>
          {/* Concession Slab Modal */}
          <Modal
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            open={openFilterMenu}
            backdrop="false"
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >

            <Container>
              <Card>
                <CardHeader>
                  <h4>Filter</h4>
                  <Tooltip title="Close"><IconButton onClick={() => setOpenFilterMenu(false)}><FaTimes  /></IconButton></Tooltip>
                </CardHeader>
                <SlabFilter
                  filterStatus={filterStatus}
                  setFilterStatus={setFilterStatus}
                  filters={filters}
                  setFilters={setFilters}
                  isAdvance={false}
                  submitHandler={applyFilters}
                  removeFilterHandler={removeFilterHandler}
                />
              </Card>
            </Container>
          </Modal>
          <ConfirmationDialog
            isOpen={dataLossOpen}
            onAgree={() => {handleSlabModalClose(); setDataLossOpen(false);}}
            setIsOpen={setDataLossOpen}
            headerMsg={'All information will be lost.'}
            msg={'Are you sure you want to leave this page?'}
          />
        </>}
      </>
    ) : (
      <></>
    )
  )
}

export default AddConcessionSlab;