import React from 'react'
import ADataGrid from '../../../../common/data-grid/ADataGrid'
import { AGridColDef } from '../../../../common/data-grid/ADataGridColDef'
import { Tooltip } from '@mui/material'
import NoRowOverlay from '../../../../masters/holidayMaster/NoRowOverlay'
import { gridStyles, gridComponents } from 'views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant.js'
import CustomCopyToClipboard from '../../../../common/ACopyToClipboard/CustomCopyToClipboard'
import { capitalize } from '../../../../common/utils/methods/commonMethods/utilityMethod'
import moment from 'moment'


const ListComponent = ({ loader, rows, currentPage, nextButtonHandler, rowCount }) => {

  const columns = [
    new AGridColDef('materialId', 'Material number', false).setMinWidth(110)
      .renderCellComponent(params => (
        <Tooltip title={params?.value}><div className='ellipsis-text-container'>{params?.value}</div></Tooltip>
      )),
    new AGridColDef('materialDescription', 'Material description', false).setMinWidth(150).setFlex(1.5)
      .renderCellComponent(params => (
        <div className='d-flex ellipsis-text-container'>
          <Tooltip title={params?.value}>
            <div className='ellipsis-text-container'>
              {params?.value}
            </div>
          </Tooltip>
          <CustomCopyToClipboard textToCopy={params?.value} />
        </div>
      )),
    new AGridColDef('serialNumberProfile', 'Type', false).setMinWidth(100).setFlex(0.5).setValueGetter(params => params?.value ==="NA"?'Non-serialized':"Serialized"),
    new AGridColDef('baseUnitOfMeasure', 'UOM', false).setMinWidth(55).setFlex(0.5),
    new AGridColDef('plantId', 'SAP plant ID', false).setMinWidth(110).setFlex(0.5),
    new AGridColDef('materialWeight', 'Weight(in Kgs)', false).setMinWidth(120).setFlex(0.5).setValueGetter(params => params?.value || '-'),
    new AGridColDef('materialStatus', 'SAP status', false).setMinWidth(100).setFlex(0.5).setValueGetter(params => capitalize(params?.value)),
    new AGridColDef('updatedOn', 'Last updated', false).setMinWidth(150)
      .renderCellComponent(params => {
        const text = `${moment(params?.value)?.format('DD-MMM-YYYY')} by ${params?.row?.updatedBy}`
        const tooltipText = `${moment(params?.value)?.format('DD-MMM-YYYY, hh:mmA')} by ${params?.row?.updatedBy}`
        return <Tooltip title={tooltipText}>
          <div className='ellipsis-text-container'>
            {text}
          </div>
        </Tooltip>
      })
  ]

  return (
    <div className='mt-3'>
      <ADataGrid
        removeWrapperContainer
        rows={rows || []}
        columns={columns}
        rowId={(row) => row.id}
        rowHeight={50}
        loading={false}
        rowCount={rowCount}
        nextButtonHandler={nextButtonHandler}
        currentPage={currentPage}
        components={{ ...gridComponents, NoRowsOverlay: () => <NoRowOverlay message={loader ? 'Loading...' : null} /> }}
        sx={{
          '.MuiDataGrid-main': {
            height: `${60 + rows?.length * 50}px`,
            minHeight: `${60 + 50}px`,
            maxHeight: `${60 + 50 * 50}px`
          },
          ...gridStyles
        }}
      />
    </div>
  )
}

export default ListComponent