import React, { useState } from 'react'
import APopover from 'views/pages/manage/common/a-popover';
import AButton from 'views/pages/manage/common/form-fields-mui/AButton';
import AInput from 'views/pages/manage/common/formFeilds/customInput/AInput'
import SearchFilterForm from './SearchFilterForm';
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

const highlightedStyle = {
  border: "1px solid #00B0F5",
  color: "#00B0F5",
};
const normalStyle = {
  border: "1px solid #CCCCCC",
};

const SearchFilter = ({
  dropdowns = {},
  filterCount = 0,
  filterForm = {},
  filterFormHandler = () => { },
  applyFilterHandler = () => { },
  removeFilterHandler = () => { },
  nameFilterInput,
  setNameFilterInput = () => { },
  applyNameFilterHandler = () => { },
  clearNameFilterHandler = () => { },
  batchDropdownData,
}) => {

  const onKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      applyNameFilterHandler()
    }
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const onModalClose = () => setIsModalOpen(false);

  return (
    <div className='d-flex align-items-center'>
      <AInput
        type='text'
        placeholder={'Search'}
        value={nameFilterInput}
        searchIcon
        customSearchIconStyle={{ left: 'unset', right: '0.5rem' }}
        crossIcon
        customCrossIconStyle={{ right: '2rem' }}
        handler={(value) => setNameFilterInput(value)}
        onKeyDown={onKeyDown}
        crossHandler={() => { clearNameFilterHandler() }}
        searchIconClickHandler={applyNameFilterHandler}
        style={{ borderRadius: '4px', height: '28px', ...normalStyle, width: '361px', paddingLeft: '0.5rem', paddingRight: '2.5rem' }}
      />
      <AButton
        onClick={() => setIsModalOpen(true)}
        updatedStyle={{
          fontWeight: "600",
          minWidth: '61px',
          ...(filterCount > 0 ? highlightedStyle : normalStyle)
        }}
      >
        Filter {filterCount > 0 && <>( {filterCount} )</>}
        <MdOutlineKeyboardArrowDown className="ml-2 regular-bold" />
      </AButton>
      <SearchFilterForm
        dropdowns={dropdowns}
        isOpen={isModalOpen}
        onClose={onModalClose}
        filterCount={filterCount}
        filterForm={filterForm}
        filterFormHandler={filterFormHandler}
        applyFilterHandler={applyFilterHandler}
        removeFilterHandler={removeFilterHandler}
        batchDropdownData={batchDropdownData}
      />
    </div>
  )
}

export default SearchFilter