import React, { useState } from "react";
import { Col, Input, Button } from "reactstrap";
import { Tooltip as MaterialTooltip} from "@mui/material" ;
const RenderHeader = ({ list }) => {

  return (
    list &&
    list.map((item, index) => {
      return (
        <div className="form-row mb-3" key={index}>
          <Col className="mb-3" md="4">
            <label className="form-control-label">
              {item && item[0]["label"]}
            </label>
            <Input
              value={item && item[0]["value"]}
              disabled
              style={{ height: "39px" }}
            />
          </Col>
          {item[1] && <Col className="mb-3 ellipsis-text-container" md="4">
            <label className="form-control-label">{item[1]["label"]}</label>
            {item[1].customComponent? 
            item[1].customComponent
             :<Input
              value={item[1]["value"]}
              disabled
              style={{ height: "39px" }}
            />}
          </Col>}
          {item[2] && <Col className="mb-3" md="4">
            <label className="form-control-label">{item[2]["label"]}</label>
            <Input
              value={item[2]["value"]}
              disabled
              style={{ height: "39px" }}
            />
          </Col>}
        </div>
      );
    })
  );
};

const CourseForm = (props) => {
  const { data } = props;

  const rows =
    data &&
    data.reduce(function (rows, key, index) {
      return (
        (index % 3 == 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) &&
        rows
      );
    }, []);

  return <RenderHeader list = {rows}/>;
};

export default CourseForm;
