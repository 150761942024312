import React, { useEffect, useState } from "react";
import { FormGenerator } from "../../../common/commonComponents/formGenerator";
import studentDetails from "../studentForm.json";
import studentEmptyResponse from "../studentDetailsObject.json";
import {
  Accordion,
} from "../../../common/commonComponents/Accordion/accordion";
import { fetchAllPromisedData } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "../../../common/constant";
import CustomLoader from "../../../common/commonComponents/Loader/loader";
import { Row } from "reactstrap";
import { gender, category } from "../constant";

const StudentInformation = ({ studentId }) => {
  const [isFetching, setFetching] = useState(true);
  const [studentResponse, setStudentDetails] = useState({});
  const [studentContactResponse, setStudentContactDetails] = useState({});
  const [studentParentDetails, setStudentParentDetails] = useState({});

  const getStudentDetails = async () => {
    // const response = await fetchAllPromisedData(
    //     `${endpoint.studentDetails.getStudentsInfo}/${studentId}`,
    //     true
    // );
    const response = await fetchAllPromisedData(
      `${endpoint.studentDetails.getStudentPersonalInfo}/${studentId}`,
      true
    );

    if (response.code == 200) {
      const studentMobileNumber = formatMobileNumber(
        response?.data?.studentMobileNumber
      );
      const whatsappNumber = formatMobileNumber(response?.data?.whatsappNumber);
      const primaryMobileNumber = formatMobileNumber(
        response?.data?.primaryMobileNumber
      );
      const studentEmailId = formatEmail(response?.data?.studentEmailId);
      const dob = formatDate(response?.data?.dob);
      const studGender = gender[response?.data?.gender]
        ? gender[response?.data?.gender]
        : response?.data?.gender;
      const studentCategory = category[response?.data?.studentCategory]
        ? category[response?.data?.studentCategory]
        : response?.data?.studentCategory;
      const bloodGroup =
        response?.data?.bloodGroup == "U"
          ? "Unknown"
          : response?.data?.bloodGroup;
      const studentDetails = {
        ...response?.data,
        dob: dob,
        bloodGroup: bloodGroup,
        studentCategory: studentCategory,
        gender: studGender,
        studentMobileNumber: studentMobileNumber,
        whatsappNumber: whatsappNumber,
        primaryMobileNumber: primaryMobileNumber,
        studentEmailId: studentEmailId,
      };
      setStudentDetails(studentDetails);
      setFetching(false);
    } else {
      setFetching(false);
    }
  };
  const getContactDetails = async () => {
    // const response = await fetchAllPromisedData(
    //     `${endpoint.studentDetails.getStudentsInfo}/${studentId}`,
    //     true
    // );
    const response = await fetchAllPromisedData(
      `${endpoint.studentDetails.getStudentContactDetails}/${studentId}`,
      true
    );

    if (response.code == 200) {
      const activeHomeContact =
        response.data?.studentAddressDetailDTOList.filter(item => {
          return item.addressType == "HOME" && item.status == "ACTIVE";
        });
      const sortedAddresses = activeHomeContact.sort(
        (a, b) => new Date(b.effectiveDate) - new Date(a.effectiveDate)
      );
      const contact = sortedAddresses[0];
      const fatherEmailDetail = response?.data?.studentEmailDetailDTOList.find(
        item => item.communicationType == "Email" && item.contactType == "HOME"
      );
      const motherEmailDetail = response?.data?.studentEmailDetailDTOList.find(
        item => item.communicationType == "Email" && item.contactType == "OTHR"
      );
      const fatherPhoneNumberDetail =
        response?.data?.studentPhoneDetailDTOList.find(
          item =>
            (item.communicationType = "Phone" && item.contactType == "HOME")
        );
      const motherMobileNumberDetail =
        response?.data?.studentPhoneDetailDTOList.find(
          item =>
            (item.communicationType = "Phone" && item.contactType == "CELL")
        );
      const studentParentDetails = {
        studentFathersEmailId: formatEmail(fatherEmailDetail?.contactValue),
        studentMothersEmailId: formatEmail(motherEmailDetail?.contactValue),
        studentFathersMobileNumber: formatMobileNumber(
          fatherPhoneNumberDetail?.contactValue
        ),
        studentMothersMobileNumber: formatMobileNumber(
          motherMobileNumberDetail?.contactValue
        ),
      };
      setStudentContactDetails(contact);
      setStudentParentDetails(studentParentDetails);
      setFetching(false);
    } else {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (studentId) {
      getStudentDetails();
      getContactDetails();
    }
  }, [studentId]);

  const formatMobileNumber = phoneNumber => {
    if (phoneNumber && phoneNumber.trim()) {
      return phoneNumber.length >= 4
        ? "+91 " +
            "*".repeat(phoneNumber.length - 4) +
            phoneNumber.substring(phoneNumber.length - 4)
        : "+91" + phoneNumber;
    }
    return null;
  };
  const formatEmail = email => {
    if (email && email.trim()) {
      const atIndex = email.indexOf("@");
      const username = email.slice(0, atIndex);
      const domain = email.slice(atIndex + 1);
      const beforeEmail =
        username.length >= 3
          ? "*".repeat(username.length - 3) + username.slice(-3)
          : username;
      const afterEmail =
        domain.length >= 5
          ? "*".repeat(domain.length - 5) + domain.slice(-5)
          : domain;
      const transformedEmail = beforeEmail + "@" + afterEmail;
      return transformedEmail;
    }
    return null;
  };
  const formatDate = responseDate => {
    const date = new Date(responseDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };
  // const formatGender=(responseGender)=>{
  //   if(responseGender=="M")
  //   return "Male";
  //   if(responseGender=="F")
  //   return "Female";
  //   if(responseGender=="U")
  //   return "Unknown";
  // }
  // const formatCategory=(responseCategory)=>{
  //   if(responseCategory=="GEN")
  //     return "GENERAL";
  //   if(responseCategory=="OTH")
  //     return "OTHERS";
  //   return responseCategory;
  // }
  return isFetching ? (
    <div className="col">
      <CustomLoader apiLoader={isFetching} />
      {/* <Card className=" w-100">
        <div
          className="mx-auto text-center py-5 my-5 "
          // style={{ height: "100vh" }}
        >
        </div>
      </Card> */}
    </div>
  ) : (
    <div className="student-details-info-tab">
      <Accordion title="Student details">
        <Row className="pt-3">
          <FormGenerator
            fields={Object.keys(studentDetails.studentDetail)}
            fieldsObject={studentDetails.studentDetail}
            // values={studentResponse?.studentDetailsWithMasterDTO ?? studentEmptyResponse.studentDetailsDTO}
            values={{
              ...studentEmptyResponse.studentDetailsDTO,
              ...studentResponse,
            }}
            setValues={() => {}}
            dataObjects={{}}
            readOnly={true}
            col={6}
          />
        </Row>
      </Accordion>

      <Accordion title="Contact details">
        <Row className="pt-3">
          <FormGenerator
            fields={Object.keys(studentDetails.contactDetails)}
            fieldsObject={studentDetails.contactDetails}
            // values={studentResponse?.studentContactDetailsWithMasterResponseDTO ?? studentEmptyResponse.studentContactDetailsWithMasterResponseDTO}
            values={{
              ...studentEmptyResponse.studentContactDetailsWithMasterResponseDTO,
              ...studentResponse,
              ...studentContactResponse,
            }}
            setValues={() => {}}
            dataObjects={{}}
            readOnly={true}
            col={6}
          />
        </Row>
      </Accordion>

      <Accordion title="Student’s Parent/Guardian Information">
        <Row className="pt-3">
          <FormGenerator
            fields={Object.keys(studentDetails.otherDetails)}
            fieldsObject={studentDetails.otherDetails}
            // values={studentResponse?.studentDetailsWithMasterDTO ?? studentEmptyResponse.studentDetailsDTO}
            values={{
              ...studentEmptyResponse.studentDetailsDTO,
              ...studentResponse,
              ...studentParentDetails,
            }}
            setValues={() => {}}
            dataObjects={{}}
            readOnly={true}
            col={6}
          />
        </Row>
      </Accordion>

      {/* <Accordion title="Bank details">
        <Row className="pt-3">
          <FormGenerator
            fields={Object.keys(studentDetails.bankDetails)}
            fieldsObject={studentDetails.bankDetails}
            values={
              studentResponse?.studentBankDetailsWithMasterDto ??
              studentEmptyResponse.studentBankDetailsWithMasterDto
            }
            setValues={() => {}}
            dataObjects={{}}
            readOnly={true}
            col={6}
          />
        </Row>
      </Accordion> */}

      {/* <TransparentAccordian title="Log details">

                </TransparentAccordian> */}
      {/* <Accordion title=" Update History" /> */}

      {/* </TransparentAccordian> */}
    </div>
  );
};

export default StudentInformation;
