import React, { useEffect, useState } from 'react'
// import AInput from '../../../../common/formFeilds/customInput/AInput';
import SearchFilterForm from './SearchFilterForm';
import APopover from 'views/pages/manage/common/a-popover';
import AButton from 'views/pages/manage/common/form-fields-mui/AButton';
import { highlightedStyle } from 'views/pages/manage/edpOps/stockManagement/mastersAndMapping/onBoardingMaterialIdSetup/SearchFilter';
import { normalStyle } from 'views/pages/manage/edpOps/stockManagement/mastersAndMapping/onBoardingMaterialIdSetup/SearchFilter';
const SearchFilter = ({
  filterCount,
  filterPayload,
  dropdown,
  applyFilter,
}) => {

  return (
      <APopover
        buttonComponent={(onClick, styles) => (
          <AButton
            className='tiny-semi-bold color-dark'
            onClick={onClick}
            updatedStyle={{ minWidth: '61px', ...(filterCount > 0 ? highlightedStyle : styles) }}
          >
            Filter {filterCount > 0 && <>( {filterCount} )</>}
          </AButton>
        )}
        menuComponent={(closeForm) =>
          <SearchFilterForm
            closeForm={closeForm}
            filterCount={filterCount}
            filterPayload={filterPayload}
            dropdown={dropdown}
            applyFilter={applyFilter}
          />
        }
        maxHeight={'calc(100vh - 250px)'}
      />
  )
}

export default SearchFilter