import React, { useMemo, useState } from "react";
import CustomChip from "views/pages/manage/common/customChip/CustomChip.jsx";
import { RiCheckFill } from "react-icons/ri";

const MIN_INITIAL_RENDER_COUNT = 2;
const ApplicationIdChip = ({ applicationId, checked, onClick, isLinkChip=false }) => {
  return (
    <>
      <CustomChip
        className={`col-applicationId-chip ${checked ? "active" : ""} ${isLinkChip ? 'link-chip' : ''}`}
        onClick={onClick}
      >
        <span>{applicationId}</span>
        {checked && <RiCheckFill className="ml-2" color="#FFF" size={16} />}
      </CustomChip>
    </>
  );
};

const ApplicationIdList = ({
  studentApplicationDetails,
  currIndex,
  onApplicationChange = () => {},
}) => {
  
  const [showAll, setShowAll] = useState(false);
  const [renderApplications, remainCount] = useMemo(() => {
    return [
      (showAll || studentApplicationDetails?.length <= MIN_INITIAL_RENDER_COUNT + 1) ? studentApplicationDetails : studentApplicationDetails?.slice(0, MIN_INITIAL_RENDER_COUNT),
      studentApplicationDetails?.length - MIN_INITIAL_RENDER_COUNT,
    ];
  }, [studentApplicationDetails, showAll]);

  return studentApplicationDetails?.length > 1 ? (
    <div className="applicationId-selector-container mb-4">
      <div className="text-center semi-bold mb-2">Application IDs</div>
      <div className="applicationId-selector">
        {(renderApplications)?.map(
          (item, index) => (
            <ApplicationIdChip
              key={JSON.stringify(item)}
              applicationId={item?.applicationId}
              checked={currIndex === index}
              onClick={() => onApplicationChange(index)}
            />
          )
        )}
        {!showAll && studentApplicationDetails?.length>MIN_INITIAL_RENDER_COUNT+1 && (
          <ApplicationIdChip
            key={JSON.stringify("count")}
            applicationId={`+${remainCount} more`}
            checked={false}
            onClick={() => setShowAll(true)}
            isLinkChip
          />
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ApplicationIdList;
