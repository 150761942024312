import React, { useState, useEffect } from 'react';
import {
  OtherSystemHeader,
  OTHERSYSTEMTHROW,
} from '../../../common/commonComponents/masterRow/masterRow';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Table,
  Input,
  Label,
} from 'reactstrap';
import { GetPagination } from '../../../common/pagination';
import Loader from 'react-loader-spinner';

import {
  getAPI,
  masterServiceBaseUrl,
  successToast,
  failureToast,
  putpost,
  putpost2,
  deleteAPI,
} from 'services/http';
import moment from 'moment';

var _ = require('lodash');

const OtherSystems = (props) => {
  const {
    getDisplayValue,
    action,
    businessUnit,
    otherSystems,
    setOtherSystems,
    isDisabled
  } = props;
  const [isSaveVisible, setIsSaveVisible] = useState();
  const [apiLoader, setApiLoader] = useState(false);
  const [state, setState] = useState([]);
  const [dataChange, setDataChange] = useState(false);
  const [tableDataKey, settableDataKey] = useState('tableDataKey');

  const [pagination, setPagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const pageSize = 10;

  useEffect(() => {
    // fetchClasses(
    //   masterServiceBaseUrl + `/otherSystems/getOtherSystemsByBusinessAreaId/${businessUnit}`
    // );
  }, [businessUnit]);

  const fetchClasses = async (url) => {
    setApiLoader(true);
    getAPI(
      url,
      (data) => {
        setState(data['data']);
        let n = pagination;
        if (data['data'].length > pageSize) {
          n['nextPage'] = n.currentPage + 1;
        }
        if (n.currentPage > 1) {
          n.previousPage = n.currentPage - 1;
        }
        n['totalPage'] = Math.floor(data['data'].length / pageSize);
        if (data['data'].length % pageSize != 0) {
          n['totalPage'] += 1;
        }
        setPagination(n);
        setApiLoader(false);
        setOtherSystems(
          data['data'].slice(
            pagination.currentPage * pageSize - pageSize,
            pagination.currentPage * pageSize
          ) || []
        );
      },
      (data) => {
        failureToast(data['message']);
        setApiLoader(false);
      }
    );
  };

  const nextPage = () => {
    setOtherSystems(
      state.slice(
        (pagination.currentPage + 1) * pageSize - pageSize,
        (pagination.currentPage + 1) * pageSize
      )
    );
    let n = pagination;
    n['currentPage'] = n['currentPage'] + 1;
    n['previousPage'] = n.currentPage;
    setPagination(n);
  };
  const firstPage = () => {
    setOtherSystems(state.slice(1 * pageSize - pageSize, 1 * pageSize));
    let n = pagination;
    n['currentPage'] = 1;
    n['previousPage'] = null;
    setPagination(n);
  };
  const lastPage = () => {
    setOtherSystems(
      state.slice(
        pagination.totalPage * pageSize - pageSize,
        pagination.totalPage * pageSize
      )
    );
    let n = pagination;
    n['currentPage'] = n.totalPage;
    n['previousPage'] = n.totalPage - 1;
    setPagination(n);
  };
  const previousPage = () => {
    if (pagination.currentPage != 1) {
      setOtherSystems(
        state.slice(
          (pagination.currentPage - 1) * pageSize - pageSize,
          (pagination.currentPage - 1) * pageSize
        )
      );
      let n = pagination;
      n['currentPage'] = n['currentPage'] - 1;
      if (n['currentPage'] == 1) {
        n['previousPage'] = null;
      } else {
        n['previousPage'] = n.currentPage;
      }
      setPagination(n);
    }
  };

  const addNewClass = () => {
    try {
      setOtherSystems([
        {
          editMode: true,
          __status: 'new',
          businessAreaId: businessUnit,
          sfSync: 'SUCCESS',
          updatedBy: null,
          createdOn: null,
          effectiveDate: null,
          uploadAgreementUrl: null,
          status: 'ACTIVE',
        },
        ...otherSystems,
      ]);
      setIsSaveVisible(true);
    } catch (e) {
      console.log('Error', e);
    }
  };

  const saveAll = (cb) => {
    try {
      var _tempArray = otherSystems;
      var isValid = true;
      _tempArray.forEach((el, index) => {
        if (el['editMode']) {
          if (el['businessAreaId'] === null) {
            isValid = false;
            failureToast('Business Area ID not found');
            return;
          } else if (el['updatedBy'] === null || !el['updatedBy'].trim()) {
            isValid = false;
            failureToast('Please enter Updated By');
            return;
          }

          if (!isValid) {
            return;
          }
          if (_tempArray[index]['__status'] == 'new') {
            setApiLoader(true);
            putpost(
              masterServiceBaseUrl + '/otherSystems/createOtherSystemscreateOtherSystems',
              (data) => {
                successToast(data['message']);
                settableDataKey(new Date().getMilliseconds());
                // fetchClasses(
                //   masterServiceBaseUrl + '/otherSystems/getAllOtherSystem'
                // );
                fetchClasses(
                  masterServiceBaseUrl +
                    `/otherSystems/getOtherSystemsByBusinessAreaId/${businessUnit}`
                );
                setIsSaveVisible(false);
                setApiLoader(false);
              },
              (data) => {
                failureToast(data['message']);
                setApiLoader(false);
              },
              _tempArray[index],
              'post'
            );

          } else {
            setApiLoader(true);
            putpost(
              masterServiceBaseUrl +
                '/otherSystems/updateOtherSystems/' +
                _tempArray[index].id,
              (data) => {
                successToast(data['message']);
                fetchClasses(
                  masterServiceBaseUrl +
                    `/otherSystems/getOtherSystemsByBusinessAreaId/${businessUnit}`
                );
                setIsSaveVisible(false);
                delete _tempArray[index]['editMode'];
                setOtherSystems(_tempArray);
                settableDataKey(new Date().getMilliseconds());
                setApiLoader(false);
              },
              (data) => {
                failureToast(data['message']);
                setApiLoader(false);
              },
              _tempArray[index],
              'put'
            );
          }
        }
      });
    } catch (e) {
      console.log('Error', e);
    }
  };

  const newRow = () => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        addNewClass();
        setIsSaveVisible(true);
      };
      var _tempArray = otherSystems;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      setOtherSystems(_tempArray);
      setIsSaveVisible(false);
      cb();
    }
  };

  const updateRecord = (index, value, property) => {
    try {
      let _tempArray = otherSystems;
      _tempArray[index][property] = value;
      setOtherSystems(_tempArray);
    } catch (e) {
      console.log('Error', e);
    }
  };

  const closeRecord = (index) => {
    try {
      let n = otherSystems;
      if (n[index]['__status'] == 'new') {
        n.splice(index, 1);
      } else {
        // n[index]['editMode']=false
        fetchClasses(
          masterServiceBaseUrl +
            `/otherSystems/getOtherSystemsByBusinessAreaId/${businessUnit}`
        );
      }
      setOtherSystems(n);
      setIsSaveVisible(false);
    } catch (e) {
      console.log('Error', e);
    }
  };

  const editRecord = (index) => {
    try {
      if (isSaveVisible) {
        failureToast('Please Delete or Save current changes');
      } else {
        let cb = () => {
          var _tempArray = otherSystems;
          _tempArray[index]['editMode'] = true;
          setOtherSystems(_tempArray);
          setIsSaveVisible(true);
        };
        var _tempArray = otherSystems;
        _tempArray = _.sortBy(_tempArray, [
          function (o) {
            return o.value;
          },
        ]);
        cb();
      }
    } catch (e) {
      console.log('Error : ', e);
    }
  };

  const deleteRecord = (index) => {
    try {
      if (otherSystems[index]['__status'] == 'new') {
        let n = otherSystems;
        n.splice(index, 1);
        setOtherSystems(n);
        setIsSaveVisible(false);
        settableDataKey(new Date().getMilliseconds());
      } else {
        if (
          window.confirm(
            'Are you sure you want to delete this Business Area & Plan ?'
          )
        ) {
          putpost2(
            masterServiceBaseUrl +
              '/otherSystems/deleteOtherSystems/' +
              otherSystems[index].id,
            (data) => {
              successToast(data['message']);
              if (otherSystems.length == 1) {
                previousPage();
                // fetchClasses(
                //   masterServiceBaseUrl + '/otherSystems/getAllOtherSystem'
                // );
                fetchClasses(
                  masterServiceBaseUrl +
                    `/businessAreaAndPlan/getBusinessAreaAndPlanByBusinessAreaId/${businessUnit}`
                );
              } else {
                var _tempArray = otherSystems;
                if (_tempArray[index]['editMode']) {
                  setIsSaveVisible(false);
                }
                _tempArray.splice(index, 1);
                setOtherSystems(_tempArray);
              }
              settableDataKey(new Date().getMilliseconds());
            },
            (data) => {
              failureToast(data['message']);
            },
            'Delete'
          );
        }
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  useEffect(() => {
    if(props.isPrevious && props.formId){
      setApiLoader(true)
      fetchClasses(`${masterServiceBaseUrl}/otherSystems/getOtherSystemsByBusinessAreaId/${props.formId}`)
   }
  }, [props.isPrevious,props.formId])

  useEffect(()=>{
    return()=>{
      if(otherSystems.length){
        !props.isPrevious && props.isNew && props.saveStepsCompleted()
      }      
    }    
  },[otherSystems])

  return (
    <Container className="mt-3" fluid>
      <Row>
        <Col className="mb-3">
          {/* {!(action === 'view') ? (
            <Button
              disabled={isSaveVisible}
              color="info"
              size="sm"
              type="button"
              className={
                'floatRight mx-1' + (isSaveVisible ? ' btn-dark' : null)
              }
              onClick={() => {
                newRow();
              }}
            >
              Add Other Systems
            </Button>
          ) : null} */}
        </Col>
      </Row>
      {apiLoader ? (
        <div className="mx-auto text-center" style={{ height: '100px' }}>
          <Loader type="Rings" color="#00BFFF" height={70} width={70} />
        </div>
      ) : (
        <div id="questionTable" className="table-responsive">
          <Table className="align-items-center table-flush">
            {otherSystems.length > 0 ? (
              <OtherSystemHeader action={action} isDisabled={isDisabled} />
            ) : (
              ''
            )}
            <tbody className="list" key={tableDataKey}>
              {otherSystems
                ? otherSystems.map((item, index) =>
                    !item['editMode'] ? (
                      <OTHERSYSTEMTHROW
                        key={index + '_class'}
                        action={action}
                        deleteRecord={deleteRecord}
                        getDisplayValue={getDisplayValue}
                        item={item}
                        editRecord={editRecord}
                        index={index}
                      closeRecord={ closeRecord }
                      isDisabled={isDisabled}
                      />
                    ) : (
                      <tr key={index}>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={50}
                            disabled
                            defaultValue={item['thirdPartySysId']}
                            // placeholder="Description"
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={50}
                            disabled
                            defaultValue={item['responseKey']}
                            // placeholder="Description"
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Label className="custom-toggle mx-auto ml-2">
                            <Input
                              checked={item.sfSync == 'SUCCESS' ? true : false}
                              type="checkbox"
                              onChange={(e) => {
                                let n = 'SUCCESS';
                                if (!e.target.checked) {
                                  n = 'NO';
                                }
                                updateRecord(index, n, 'sfSync');
                                setDataChange(!dataChange);
                              }}
                            />
                            <span
                              style={{ width: '75px' }}
                              className="custom-toggle-slider rounded-circle activeToggle"
                              data-label-off="Unsuccess"
                              data-label-on="Success"
                            />
                          </Label>
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            className="tdInput"
                            defaultValue={item['updatedBy']}
                            maxLength={6}
                            onChange={(e) => {
                              let n = e.target.value;
                              updateRecord(index, n, 'updatedBy');
                            }}
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            disabled={true}
                            defaultValue={
                              item['__status'] == 'new'
                                ? null
                                : moment(item['createdOn']).format('DD-MM-YYYY')
                            }
                            placeholder="Example:01-01-2020"
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            disabled={true}
                            defaultValue={
                              item['__status'] == 'new'
                                ? null
                                : moment(item['effectiveDate']).format(
                                    'DD-MM-YYYY'
                                  )
                            }
                            placeholder="Example:01-01-2020"
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Label className="custom-toggle mx-auto ml-2">
                            <Input
                              checked={item.status == 'ACTIVE' ? true : false}
                              type="checkbox"
                              onChange={(e) => {
                                settableDataKey(new Date().getMilliseconds());
                                let n = 'ACTIVE';
                                if (!e.target.checked) {
                                  n = 'INACTIVE';
                                }
                                updateRecord(index, n, 'status');
                                setDataChange(!dataChange);
                              }}
                            />
                            <span
                              style={{ width: '72px' }}
                              className="custom-toggle-slider rounded-circle activeToggle"
                              data-label-off="Inactive"
                              data-label-on="Active"
                            />
                          </Label>
                        </td>
                        <td className="text-center">
                          {isSaveVisible ? (
                            <>
                              <Button
                                size="sm"
                                color="info"
                                onClick={() => saveAll()}
                              >
                                Save
                              </Button>
                              <Button
                                size="sm"
                                color="danger"
                                onClick={() => closeRecord(index)}
                              >
                                <i className="fas fa-times" />
                              </Button>
                            </>
                          ) : (
                            <Button
                              color="info"
                              size="sm"
                              type="button"
                              onClick={() => {
                                deleteRecord(index);
                              }}
                            >
                              <i className="fas fa-trash" />
                            </Button>
                          )}
                        </td>
                      </tr>
                    )
                  )
                : null}
            </tbody>
          </Table>

          {pagination.totalPage > 1 ? (
            <GetPagination
              fetchClasses={otherSystems}
              pagination={pagination}
              previousPage={previousPage}
              firstPage={firstPage}
              lastPage={lastPage}
              nextPage={nextPage}
              setpagination={setPagination}
            />
          ) : null}
        </div>
      )}
    </Container>
  );
};

export default OtherSystems;
