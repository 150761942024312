import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import Style from './pageNotFound.module.scss';
import { useHistory } from 'react-router';
const PageNotFound = () => {
  const history = useHistory();
  return (
    <Fragment>
      <div className={Style.content}>
        <h2 className={Style.text_shadows}>404</h2>
        <h1 className={Style.page_text}>Page Not Found</h1>
        <Button className="btn-lg" color="info" onClick={() => history.push('/admin/dashboard')}>
          Back to Home
        </Button>
      </div>
    </Fragment>
  );
};

export default PageNotFound;
