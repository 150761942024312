import React from 'react';
import { Card, CardHeader, Input, Container, Table, Row } from 'reactstrap';
import { useState } from 'react';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import { TDROW, MasterHeader } from '../../common/commonComponents/masterRow/masterRow';
import { masterServiceBaseUrl, putpost, putpost2 } from 'services/http';
import moment from 'moment';
import CustomButton from 'components/CustomButton';

var _ = require('lodash');

const TaxCodeTypeTable = props => {
  const { userPermissions, isSaveVisible, setisSaveVisible=()=>{} } = props;
  const [tableDataKey, settableDataKey] = useState('tableDataKey');
  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: '__new',
        taxCodeTypeKey: null,
        taxRegine: 'Y',
        status: 'ACTIVE',
        taxCodeTypeDesc: null,
        taxCodeTypeDispValue: null,
        effectiveDate: null,
      },
      ...props.classArray,
    ]);
  };
  const saveAll = cb => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if (el['editMode']) {
        if (el['taxCodeTypeKey'] === null || !el['taxCodeTypeKey'].trim()) {
          isValid = false;
          failureToast('Please enter the value of Tax Code Type Key');
          return;
        } else if (el['taxCodeTypeDesc'] === null || !el['taxCodeTypeDesc'].trim()) {
          isValid = false;
          failureToast('Please enter the value of Description');
          return;
        } else if (el['taxCodeTypeDispValue'] === null || !el['taxCodeTypeDispValue'].trim()) {
          isValid = false;
          failureToast('Please enter the value of Display Value');
          return;
        }
        if (!isValid) {
          return;
        }
        if (_tempArray[index]['__status'] == '__new') {
          putpost(
            masterServiceBaseUrl + '/taxCodeType/createTaxCodeType',
            data => {
              successToast(data['message']);
              setisSaveVisible(false);
              props.fetchClasses(masterServiceBaseUrl + '/taxCodeType/getAllTaxCodeType');
            },
            data => {
              failureToast(data['message']);
            },
            _tempArray[index],
            'post'
          );
        } else {
          putpost(
            masterServiceBaseUrl + '/taxCodeType/updateTaxCodeType/' + _tempArray[index].id,
            data => {
              successToast(data['message']);
              setisSaveVisible(false);
              delete _tempArray[index]['editMode'];
              props.setclassArray(_tempArray);
              settableDataKey(new Date().getMilliseconds());
              // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
            },
            data => {
              failureToast(data['message']);
            },
            _tempArray[index],
            'put'
          );
        }
      } else {
      }
    });
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
        // settableDataKey(new Date().getMilliseconds())
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      cb();
    }
  };
  const editRow = index => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]['editMode'] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };
  const deleteRow = index => {
    if (props.classArray[index]['__status'] == '__new') {
      let n = props.classArray;
      n.splice(index, 1);
      props.setclassArray(n);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
    } else {
      if (window.confirm('Are you sure you want to delete this Tax Code Type?')) {
        putpost2(
          masterServiceBaseUrl + '/taxCodeType/deleteTaxCodeType/' + props.classArray[index].id,
          data => {
            successToast(data['message']);
            if (props.classArray.length == 1) {
              props.previousPage();
              props.fetchClasses(masterServiceBaseUrl + '/taxCodeType/getAllTaxCodeType');
            } else {
              var _tempArray = props.classArray;
              if (_tempArray[index]['editMode']) {
                setisSaveVisible(false);
              }
              _tempArray.splice(index, 1);
              props.setclassArray(_tempArray);
            }
            settableDataKey(new Date().getMilliseconds());
          },
          data => {
            failureToast(data['message']);
          },
          'Delete'
        );
      }
    }
  };
  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray([..._tempArray]);
  };

  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Tax Code Type</h3>
                <CustomButton
                  disabled={isSaveVisible}
                  className={'floatRight mx-1' + (isSaveVisible ? ' btn-dark cursor-gray' : null)}
                  content={'New Tax Code Type'}
                  permissionType={'C'}
                  icon={true}
                  permissionSet={userPermissions}
                  onClick={() => {
                    newRow();
                  }}
                />
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout">
                  <MasterHeader type={'Tax Code Type'} isSaveVisible={isSaveVisible} permissionSet={userPermissions} />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                          !el['editMode'] ? (
                            <TDROW
                              type={'taxCodeType'}
                              index={index}
                              el={el}
                              editRow={editRow}
                              deleteRow={deleteRow}
                              isSaveVisible={isSaveVisible}
                              permissionSet={userPermissions}
                            />
                          ) : (
                            <tr key={index + '-class'}>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength="50"
                                  defaultValue={el['taxCodeTypeKey']}
                                  onChange={e => {
                                    updateKey(index, e.target.value, 'taxCodeTypeKey');
                                  }}
                                  placeholder="Example:1"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <input
                                  onChange={e => {
                                    const value = e.target.checked ? 'Y' : 'N';
                                    updateKey(index, value, 'taxRegine');
                                  }}
                                  checked={el?.taxRegine === 'Y' ? true : false}
                                  className="react-switch-checkbox"
                                  id={`react-switch-new`}
                                  type="checkbox"
                                />
                                <label
                                  style={{ background: el.taxRegine === 'Y' ? '#00AEEF' : '#e9ecef' }}
                                  className="react-switch-label"
                                  htmlFor={`react-switch-new`}
                                >
                                  <span className={`react-switch-button`} />
                                </label>
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  disabled={true}
                                  defaultValue={
                                    el['__status'] == '__new' ? null : moment(el['eff_date']).format('DD-MM-YYYY')
                                  }
                                  placeholder="Example:01-01-2020"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength="50"
                                  defaultValue={el['taxCodeTypeDesc']}
                                  onChange={e => {
                                    updateKey(index, e.target.value, 'taxCodeTypeDesc');
                                  }}
                                  placeholder="Description"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength="50"
                                  defaultValue={el['taxCodeTypeDispValue']}
                                  onChange={e => {
                                    updateKey(index, e.target.value, 'taxCodeTypeDispValue');
                                  }}
                                  placeholder="Display Value"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <label className="custom-toggle mx-auto ml-2">
                                  <input
                                    checked={el.status == 'ACTIVE' ? true : false}
                                    type="checkbox"
                                    id={el.id}
                                    onChange={e => {
                                      settableDataKey(new Date().getMilliseconds());
                                      let n = 'ACTIVE';
                                      if (!e.target.checked) {
                                        n = 'INACTIVE';
                                      }
                                      updateKey(index, n, 'status');
                                    }}
                                  />
                                  <span
                                    style={{ width: '72px' }}
                                    className="custom-toggle-slider rounded-circle activeToggle"
                                    data-label-off="Inactive"
                                    data-label-on="Active"
                                  />
                                </label>
                              </td>
                              <td className="text-center">
                                {isSaveVisible ? (
                                  <>
                                    <CustomButton
                                      content={'Save'}
                                      permissionType={'C,U'}
                                      forTable={true}
                                      permissionSet={userPermissions}
                                      onClick={() => {
                                        saveAll();
                                      }}
                                    />
                                    <CustomButton
                                      permissionType={'cancel'}
                                      icon={true}
                                      forTable={true}
                                      permissionSet={userPermissions}
                                      onClick={() => {
                                        let n = props.classArray;
                                        if (n[index]['__status'] == '__new') {
                                          n.splice(index, 1);
                                        } else {
                                          props.fetchClasses(masterServiceBaseUrl + '/taxCodeType/getAllTaxCodeType');
                                        }
                                        props.setclassArray(n);
                                        setisSaveVisible(false);
                                      }}
                                    />
                                  </>
                                ) : (
                                  <CustomButton
                                    permissionType={'D'}
                                    icon={true}
                                    forTable={true}
                                    onClick={() => deleteRow(index)}
                                    permissionSet={userPermissions}
                                  />
                                )}
                              </td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default TaxCodeTypeTable;
