import React, { useState } from "react";
import { CiFilter } from "react-icons/ci";
import { useDispatch } from "react-redux";
import AButton from "../../common/form-fields-mui/AButton";
import { RightSideFilterForm } from "./RightSideFilterForm";
import APopover from "../../common/pop-hover/APophover";
// import { CSVLink } from "react-csv";
import SearchInput from './SearchInput';
import { getStudentList } from './../../../../../redux/studentSearch/actions';
import SelectSearchInputParam from "./SelectSearchInputParam";

// this is for filter button style
const highLightedStyle = {
  border: "1px solid #00B0F5",
  color: "#00B0F5",
};

const NormalStyle = {
  border: "1px solid #E9ECEF",
};
const Filter = ({
  data,
  filterForm,
  inputHandler,
  removeFilterHandler,
  crossInputHandler,
  courseIdDropdown,
  searchInputForm,
  searchInputFormHandler,
  ...props
}) => {

  const dispatch = useDispatch();
  const [anchorFilterEl, setAnchorFilterEl] = useState(null);
  const [anchorAdvFilterEl, setAnchorAdvFilterEl] = useState(null);

  const [searchInputParam, setSearchInputParam] = useState('psid');

  let searchParamTitleMap = { psid: 'PSID', studentName: 'student name', applicationId: 'application ID' }

  const resetInputForm = () => {
    searchInputFormHandler('psid', '');
    searchInputFormHandler('studentName', '');
    searchInputFormHandler('applicationId', '');
  }

  const searchBatchNameHandler = (userInput) => {
    resetInputForm();
    searchInputFormHandler(searchInputParam, userInput);
    dispatch(getStudentList({ ...props.regionAndBusiness, ...filterForm, [searchInputParam]: userInput }, 0));
  };

  const onClearSearchInput = () => {
    // setSearchInputParam('psid');
    crossInputHandler();
  };

  const removerFilterWithClose = () => {
    removeFilterHandler()
    setAnchorFilterEl(null)
  }
  const applyFilterHandler = () => {
    props.applyFilterHandler();
    setAnchorFilterEl(null)
  }

  return (
    <div style={{ display: "flex" }}>
      <div className="d-flex flex-column">
        <SearchInput
          onCancel={onClearSearchInput}
          searchInputValueHandler={searchBatchNameHandler}
          placeholder={`Search by ${searchParamTitleMap[searchInputParam]}`}
          // onFocus={(e) => setInputFocused(true)}
          // onBlur={() => setInputFocused(false)}
        />
        <SelectSearchInputParam searchInputParam={searchInputParam} setSearchInputParam={setSearchInputParam} />
      </div>
      <AButton
        onClick={(event) => {
          setAnchorFilterEl(event.currentTarget);
        }}
        updatedStyle={
          anchorFilterEl || props.appliedFilterCount > 0 ? highLightedStyle : NormalStyle
        }
      >
        <CiFilter />
        Filter
        {props.appliedFilterCount > 0 && <span>( {props.appliedFilterCount} )</span>}
      </AButton>

      <APopover
        handleClose={() => setAnchorFilterEl(null)}
        id="info-batch-filter-1"
        anchorEl={anchorFilterEl}
      >
        <RightSideFilterForm
          formModal={filterForm}
          inputHandler={inputHandler}
          applyFilterHandler={applyFilterHandler}
          removeFilterHandler={removerFilterWithClose}
          isShowRemoveFilter={!!props.appliedFilterCount}
          courseIdDropdown={courseIdDropdown}
        />
      </APopover>

      <APopover
        handleClose={() => setAnchorAdvFilterEl(null)}
        id="info-batch-filter"
        anchorEl={anchorAdvFilterEl}
      ></APopover>
      {/* <AButton>
			<MdOutlineSimCardDownload />
			<CSVLink
				data={data}
				headers={CSV_HEADERS}
				filename={`${Date.now()}-${"batches.csv"}`}
			>
				Download CSV
			</CSVLink>
			;
			</AButton> */}
    </div>
  );
};

export default Filter;
