import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useHistory } from 'react-router';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod.js';
import { constants, endpoint } from '../../common/constant';
import { Container } from 'reactstrap';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function.js';
import moment from 'moment';
import { PermissionContext, RolePermissions } from "../../../../../appContext";
import { pages } from '../../common/constant';
import { PermisionDenied } from '../../common/commonComponents';
import './index.scss';
import CustomButton from '../../../../../components/CustomButton/index.js';
import CommonTable from '../facultyMaster/table.js';
import HeaderComponet from './HeaderComponent';
import arrowleft from '../../../../../assets/img/svg/Arrow-left.svg'
import { useDispatch, useSelector } from 'react-redux';
import { compareDates, isFilterApplied, rosterFilter } from './constant/faculty-roaster'
import Filter from './Filter.jsx';
import useConfirm from '../../common/commonComponents/ConfirmationDialog/confirmationPrompt.jsx';
import { getDropdowns, loadDropdowns } from '../../../../../redux/facultyRoaster/actions.js';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx';


// import { TransitionTimeouts } from 'reactstrap/lib/utils';

const getDayValue = (rowValue) => {
    return rowValue.date ? moment(rowValue.date).format('dddd') : '';
}

const EditRoaster = (props) => {

    const permissions = useContext(PermissionContext);
    const userPermissions = RolePermissions(permissions, pages["facultyRoaster"]['id']);
    const history = useHistory();
    const dispatch = useDispatch();

    const { id, action } = props.match.params ?? { id: '', action: '' };
    const facultyRoasterState = useSelector((state) => state.facultyRoaster);
    const { dropdowns, dropdownLoader } = facultyRoasterState;
    const [isSaveVisible,setAddVisible] = useState(true)
    const [ConfirmationDialog, confirm] = useConfirm();

    const [rows, setRows] = useState([]);
    const [facultyDetail, setFacultyDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({
        pageSize: 50,
        page: 0,
        pageCount: 0,
    })

    const [filter,setFilter] = useState({
       ...rosterFilter
    })

    const [pastFilter,setPastFilter] = useState({
        startDate:'',
        endDate:''
    })

    const [appliedFilter,setAppliedFilter] = useState(0)
    const [isPastFilterApplied,setPastFilterApplied] = useState(0)
 
    const getMinDate = (row) => {
        return row?.date ?? new Date(); 
    }


    const getBusinessArea = (fdetails) => {
        try{
            const faculty =  fdetails?.facultyMasterBusinessAreasResponseList ? fdetails : facultyDetail;
            return faculty.facultyMasterBusinessAreasResponseList?.map(i => {return {value: i.businessAreaKey ,label : i.businessAreaValue}})
        }catch(e){
            console.log(e)
            return []
        }
    }


    const columns = [
        { field: 'date', headerName: 'Date', type: 'datePickerV2', placeholder: 'Date', width: 150, mandatory: true, editable: true, disabled: false, sortable: true, disabledInEdit: true, minDate : new Date(), sortingOrder : ['date','startTime','endTime'] , sortOrder:['asc','asc'], dependent: 'frequency', initialValue: 'DOES_NOT_REPEAT'},
        { field: 'day', headerName: 'Day', type: 'text', placeholder: 'Day', width: 100, mandatory: true, editable: true, disabled: true, sortable: true, valueFunction: getDayValue, sortingOrder : ['day','date','startTime','endTime'] , sortOrder:['desc','asc','asc']},
        { field: 'businessArea', headerName: 'Business Area', type: 'singleSelect', placeholder: 'Business Area', width: 200, mandatory: true, editable: false, disabled: false , sortable: true, valueOptionsFunction: getBusinessArea},
        { field: 'startTime', headerName: 'Start time', type: 'timePicker', width: 100, mandatory: true, editable: true, disabled: false,sortable: true, sortingOrder : ['startTime','date','day','endTime'], sortOrder:['desc','asc','asc'] },
        { field: 'endTime', headerName: 'End time', type: 'timePicker', width: 100, mandatory: true, editable: true, disabled: false ,sortable: true, sortingOrder : ['endTime','date','day','startTime'], sortOrder:['desc','asc','asc'] },
        { field: 'frequency', headerName: 'Frequency', type: 'datePickerV4', width: 150, mandatory: true, editable: true, disabled: false, disabledInEdit: true, minDateGetter: getMinDate, sortable: true },
        { field: 'status', headerName: 'Status', type: 'toggle', width: 170, mandatory: true, editable: true, disabled: false , sortable: true},
        // { field: 'academicGroup', headerName: 'Action', type: 'multiSelect', valueOptions: academicGroupData.data, width: 170, mandatory: true, editable: true, disabled: false },
        // { field: 'subject', headerName: 'Subject', type: 'multiSelect', valueOptions: subjectData.data, popoverView: true, width: 110, mandatory: true, editable: true, disabled: false },
    ];



    const createSchemes = (schemesPayload,fdetails) => {

        return schemesPayload.map(scheme => {

            const { id,
                endTime,
                frequency,
                rosterDate,
                rosterDay,
                startTime,
                businessArea,
                status } = scheme;

                // console.log(moment(rosterDate).toDate())

            return {
                id: id,
                date: moment(rosterDate).format('DD/MM/YYYY'),
                rosterDate,
                day: rosterDay,
                startTime : moment(new Date(`${moment(new Date()).format('ddd MMM DD YYYY')} ${startTime}`)).format('hh:mm a'),
                endTime : moment(new Date(`${moment(new Date()).format('ddd MMM DD YYYY')} ${endTime}`)).format('hh:mm a'),
                frequency: frequency,
                status,
                businessArea: getBusinessArea(fdetails)?.filter(i => i.value === businessArea)?.[0] ?? '',
                fromBE: true
            }
        })
    }

    const getRoasterList = async (p) => {
        setLoading(true);

        const f = isPastFilterApplied ? {
            ...pastFilter,
            "facultyMasterId": id, 
            businessArea : dropdowns?.businessArea?.map(i => i.value)
        } : {
            ...filter, status : filter?.status?.value, day : filter?.day?.value ,"facultyMasterId": id, businessArea : dropdowns?.businessArea?.map(i => i.value)
        }

        try {
            const response = await  fetchAllPostPromisedData(`${endpoint?.facultyRoaster.search}/${p}`, f);
            if (response.code === 200) {
                const data = createSchemes(response.data.facultyRosterDTOList);
                setPagination({
                    pageSize: 50,
                    page: p,
                    pageCount: response.data.totalPage,
                })
                setRows(data);
                setLoading(false);
            } else {
                setRows([]);
                failureToast("Failed to fetch Data");
                setLoading(false);
            }
        } catch (error) {
            console.log(error)
            setRows([]);
            setLoading(false);
            failureToast("Failed to fetch Data");
        }
    }

    const getFilteredRoasterList = async (filter,p) => {
        setLoading(true);
        const f  = {
            ...filter,
            businessArea : dropdowns?.businessArea?.map(i => i.value)
        }
        try {
            const response = await fetchAllPostPromisedData(`${endpoint?.facultyRoaster.search}/${p}`, f);
            if (response.code === 200) {
                const data = createSchemes(response.data.facultyRosterDTOList);
                setPagination({
                    pageSize: 50,
                    page: p,
                    pageCount: response.data.totalPage,
                })
                // const sortedData = data.sort(compareDates)
                setRows(data);
                setLoading(false);
            } else {
                setRows([]);
                failureToast("Failed to fetch Data");
                setLoading(false);
            }
        } catch (error) {
            console.log(error)
            setRows([]);
            setLoading(false);
            failureToast("Failed to fetch Data");
        }
    }

    const saveScheme = async (row) => {

        setLoading(true);
        const isRowNew = row.isNewRow ? true : false;

        const startTime = typeof(row.startTime) === 'string'? moment(new Date(`${moment(new Date()).format('ddd MMM DD YYYY')} ${row.startTime}`)).format('HH:mm:ss') : moment(row.startTime).format('HH:mm:ss');
        const endTime = typeof(row.endTime) === 'string'? moment(new Date(`${moment(new Date()).format('ddd MMM DD YYYY')} ${row.endTime}`)).format('HH:mm:ss') : moment(row.endTime).format('HH:mm:ss');

        if (!row.date) {
            failureToast('Please select date');
            setLoading(false);
            return
        }

        if (!row.businessArea?.value) {
            failureToast('Please select business area');
            setLoading(false);
            return
        }

        if (!row.startTime) {
            failureToast('Please select start time');
            setLoading(false);
            return
        }

        if (!row.endTime) {
            failureToast('Please select end time');
            setLoading(false);
            return
        }
        
        if (startTime > endTime) {
            failureToast('Start time cannot be greater than end time');
            setLoading(false);
            return
        }

        if (startTime == endTime) {
            failureToast('Start Time and End Time cannot be same');
            setLoading(false);
            return
        }

        if ((!row.frequency?.option && isRowNew) && !row?.frequency) {
            failureToast('Please select frequency');
            setLoading(false);
            return
        }

        if (row.frequency?.option === 'CUSTOM' && !row.frequency?.dates?.length) {
            failureToast('Please select dates in frequency');
            setLoading(false);
            return
        }

        let request = {
            "facultyMasterId": id,
            "rosterDate": moment(row.date).format("YYYY-MM-DD"),
            "rosterDay": moment(row.date).format('dddd'),
            "startTime": startTime,
            "endTime": endTime,
            "frequency": row.frequency?.option ?? row.frequency,
            "status": row.status,
            "facultyKey": facultyDetail?.facultyId,
            "businessArea": row.businessArea?.value ?? null
        }
        if (row.frequency?.option && row.frequency?.option !== 'DOES_NOT_REPEAT' && isRowNew) {
            request = row.frequency.dates.map(item => {
                return {
                    ...request,
                    rosterDate : row.frequency?.option  === 'CUSTOM' ?  moment(new Date(item)).format('YYYY-MM-DD') : moment(item).format('YYYY-MM-DD'),
                    rosterDay :  row.frequency?.option  === 'CUSTOM' ?  moment(new Date(item)).format('dddd') : moment(item).format('dddd'),
                }
            }) 
        }
       
        const createUrl = row.frequency?.option  === 'DOES_NOT_REPEAT'  || row?.frequency === 'DOES_NOT_REPEAT' ? endpoint.facultyRoaster.create : endpoint.facultyRoaster.createAll;
        const p = new Promise((resolve, reject) => {
            fetchAllPostPromisedData(isRowNew ? createUrl : `${endpoint.facultyRoaster.create}/${row.id}`, request, isRowNew ? 'post' : 'put')
                .then(response => {
                    setLoading(false);
                    if (response && response.code === 200) {
                        successToast(`Roster ${isRowNew ? 'added' : 'updated'} successfully!`);
                        getRoasterList(pagination.page);
                        // getFilteredRoasterList(isPastFilterApplied ? pastFilter : filter, pagination.page);
                        setAddVisible(true)
                    } else {
                        failureToast(response.message)
                        // reject(response.message);
                    }
                })
                .catch(err => { setLoading(false) });
        });
        // return p;
    }

    const deleteScheme = async (row) => {
        setLoading(true);
        const p = new Promise((resolve, reject) => {
            fetchAllPostPromisedData(`${endpoint.facultyRoaster.delete}/${row.id}`, {}, 'delete')
                .then(response => {
                    setLoading(false);
                    if (response && response.code === 200) {
                        successToast('Faculty deleted successfully!');
                        if(rows.length > 1){
                            getRoasterList(pagination.page);
                        }else{
                            getRoasterList(pagination.page);
                        }
                    } else {
                        failureToast(response.message)
                    }
                })
                .catch(err => { setLoading(false); });
        });
        // return p;
    }


    const addNewRow = () => {
        const newObject = {
            date: '',
            day: '',
            startTime: '',
            endTime: '',
            frequency: 'DOES_NOT_REPEAT',
            status: 'ACTIVE',
            isNewRow: true
        };

        setRows([newObject, ...rows]);
        setAddVisible(false)
    }

    const onNewRowCancel = (index) => {
        const _temArr = [...rows]
        _temArr.splice(index, 1)
        setRows([..._temArr])
        setAddVisible(true)
    }

    const pageChangeHandler = async(page) => {
        if(!isSaveVisible){
            const canContinue = await confirm();
            if (canContinue) {
                setAddVisible(true);
                getRoasterList(page)
            } else {
                return
            }
        }else{
            getRoasterList(page)
        }
    }

    const getFacultyDetails = async () => {

        const p = await Promise.all([
            fetchAllPromisedData(`${endpoint?.faculty.getById}/${id}`),
            fetchAllPostPromisedData(`${endpoint?.facultyRoaster.search}/${0}`, {...filter, "facultyMasterId": id, businessArea : dropdowns?.businessArea?.map(i => i.value)})
        ])

        setFacultyDetails(p[0]);
        const data = createSchemes(p[1]?.code === 200 ? p[1]['data']['facultyRosterDTOList'] : [],p[0]);
        // const sortedData = data.sort(compareDates)
        // console.log("sortedData",sortedData)
        setRows(data);
        setPagination({
            ...pagination,
            page: p[1]?.data?.currentPage ?? 0,
            pageCount: p[1]?.data?.totalPage ?? 1,
        })
        setLoading(false);
    }

    useEffect(() => {
        getFacultyDetails();
    }, [id])


    const applyFilter = () => {

        if(filter.startDate && !filter.endDate){
            failureToast('Please provide end date in filter');
            return
        }

        if(filter.startTime && !filter.endTime){
            failureToast('Please provide end time in filter');
            return
        }

        if(filter.startTime && filter.endTime && (filter.startTime > filter.endTime)){
            failureToast('Start time cannot be greater than end time in filter');
            return
        }

        setAppliedFilter(isFilterApplied(filter));
        setPastFilterApplied(0)
        setPastFilter({
            startDate:'',
            endDate:''
        })
        const filterRequest =  {
            ...filter,
            facultyMasterId : id,
            day : filter?.day?.value ?? '',
            onLoad: false,
            status: filter?.status?.value ?? '',
            startDate: filter?.startDate ? moment(filter.startDate).format("YYYY-MM-DD") : '',
            endDate: filter?.endDate ? moment(filter.endDate).format("YYYY-MM-DD") : ''
        }
        getFilteredRoasterList(filterRequest,0)
    }

    const removeFilter = () => {
        setAppliedFilter(0);
        setFilter({...rosterFilter})
        getFilteredRoasterList({...rosterFilter,facultyMasterId : id},0)
    }

    const applyPastFilter = () => {
        setPastFilterApplied(isFilterApplied(pastFilter));
        setAppliedFilter(0)
        setFilter({
            ...rosterFilter
        })
        const filterRequest =  {
            ...rosterFilter,
            ...pastFilter,
            endDate: moment(pastFilter.endDate ? pastFilter.endDate : moment().subtract(1, 'days')).format("YYYY-MM-DD"),
            startDate: pastFilter?.startDate ? moment(pastFilter.startDate).format("YYYY-MM-DD") : '',
            facultyMasterId : id
        }
        if(!pastFilter.endDate){
            setPastFilter({...pastFilter,endDate : moment().subtract(1, 'days')})
        }
        getFilteredRoasterList(filterRequest,0)
    }

    const removePastFilter = () => {
        setPastFilterApplied(0)
        setPastFilter({
            startDate:'',
            endDate:''
        })
        getFilteredRoasterList({...rosterFilter,facultyMasterId : id},0)
    }


    useEffect(()=>{
        if(!dropdowns?.subject || !dropdowns?.academicCareer || !dropdowns?.academicGroup){
            dispatch(loadDropdowns())
        }
    },[dropdowns])


    return (
        userPermissions ? (
            <div className='d-flex flex-column remove-container-top-space' style={{ height: 'calc(100vh - 100px)' }}>

                <HeaderComponet isInformationAvailable={true} facultyDetail={facultyDetail} dropdowns={dropdowns} loading={loading || dropdownLoader} userPermissions={userPermissions}/>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 20, backgroundColor: 'white', marginBottom: 10 }}>
                    <div style={{ display: 'flex', gap: 20, alignItems: 'center', cursor:'pointer' }} onClick={()=>history.goBack()}>
                        <IconButtonWrapper><img src={arrowleft} alt='' width={20} height={20} /></IconButtonWrapper>
                        <div style={{ fontSize: 16, fontWeight: 600 }}>Create/View</div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <Filter 
                            filter = {filter}
                            setFilter = {setFilter}
                            applyFilter={applyFilter}
                            removeFilter={removeFilter}
                            appliedFilter={appliedFilter}
                            pastFilter={pastFilter}
                            setPastFilter={setPastFilter}
                            isPastFilterApplied={isPastFilterApplied}
                            applyPastFilter={applyPastFilter}
                            removePastFilter={removePastFilter}
                            isSaveVisible={isSaveVisible}
                        />
                        <div className='large-semi-bold color-primary cursor' style={{ marginLeft: '12px' }} onClick={() => window.open(history.createHref({ pathname: `admin/facultyRoaster/faculty/${id}/schedule`}))}>
                            Check schedule
                        </div>
                        <CustomButton
                            content={"Add Date"}
                            permissionType={'C'}
                            permissionSet={userPermissions}
                            onClick={addNewRow}
                            disabled={!isSaveVisible}
                        />
                    </div>
                </div>

                <Container fluid>
                    <CommonTable
                        userPermissions={userPermissions}
                        rows={rows}
                        columns={columns}
                        viewOnly={false}
                        loading={loading}
                        pagination={pagination}
                        isSaveVisible={isSaveVisible}
                        setPagination={setPagination}
                        // FilterComp={FilterComp}
                        setAddVisible={setAddVisible}
                        saveScheme={saveScheme}
                        deleteScheme={deleteScheme}
                        onNewRowCancel={onNewRowCancel}
                        pageChangeHandler={pageChangeHandler}
                        deleteConfirmationHeader={'Confirm row delete'}
                        deleteConfimationMsg={'This Faculty/Invigilator roster row will be removed from all upcoming planned time tables of batches across relevant branches. Are you sure you want to proceed?'}
                    />

<ConfirmationDialog />
                </Container></div>) : <PermisionDenied />
    )
}

export default EditRoaster;