import React, { useEffect, useState, useContext } from "react";
import { Card, Table, CardHeader } from "reactstrap";
import { MasterHeaderAuto } from "../../../../common/commonComponents/masterHeader/masterHeader";
import { failureToast } from "../../../../common/utils/methods/toasterFunctions/function";
import { GetPagination } from "views/pages/manage/common/pagination";
import { UserDetails } from 'appContext';
import { getCookie } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";

import CustomButton from "components/CustomButton";
import CommonInput from "../../../../common/formFeilds/input/commonInput";
import moment from "moment";

const CommentBox = ({ approvalRole, comment, isDisabled, userPermissions, approve = () => { }, chkRemark = () => { }, setComment = () => { }, useDetails }) => {
  // const { firstName, lastName } = useDetails;
  const [approveLoading, setApproveLoading] = useState(false);

  return (
    <tr>
      <td className="text-center p-2 mx-1">{approvalRole == 10002 ? 'L2 Approval' : 'L1 Approval'}</td>
      <td className="text-center p-2 mx-1">
        <CommonInput
          placeHolder="Remarks..."
          className="tdSelect"
          defaultValue={comment}
          maxLength={19}
          onChange={(v) => {
            setComment(v);
          }}
          isDisabled={isDisabled}
          forTable={true}
          type="text"
        />
      </td>
      <td className="text-center p-2 mx-1">
        <CustomButton
          className={"text-center  mx-1"}
          content={"Approve"}
          permissionType={'A'}
          color={isDisabled ? "secondary" : "info"} size="sm" type="button"
          onClick={() => approve(1, setApproveLoading)}
          permissionSet={userPermissions}
          disabled={isDisabled || approveLoading}
        />
        <CustomButton
          type="reject"
          className={"text-center  mx-1"}
          disabled={isDisabled}
          content={"Reject"}
          permissionType={'A'}
          color={isDisabled ? "secondary" : "danger"}
          onClick={() => chkRemark(0)}
          permissionSet={userPermissions}
        />
      </td>
      <td className="text-center p-2 mx-1">{`${getCookie('userId')}`}</td>
    </tr>
  )
}

const RemarkArray = ({serverData,approval}) => {

  const [classArray, setclassArray] = useState([])
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const pageSize = 10;

  // const setPagination = (paramsdata) => {
  //   let n = pagination;
  //   if (paramsdata.length > pageSize) {
  //     n["nextPage"] = n.currentPage + 1;
  //   }
  //   if (n.currentPage > 1) {
  //     n.previousPage = n.currentPage - 1;
  //   }
  //   n["totalPage"] = Math.floor(paramsdata.length / pageSize);
  //   if (paramsdata.length % pageSize != 0) {
  //     n["totalPage"] += 1;
  //   }
  //   setpagination(n);
  //   setclassArray(
  //     paramsdata.slice(
  //       pagination.currentPage * pageSize - pageSize,
  //       pagination.currentPage * pageSize
  //     ) || []
  //   );
  // };

  useEffect(() => {
    if (serverData) {
      setclassArray(serverData);
    }
  }, [serverData]);

  return (
    <>
      {serverData && classArray?.map(item => {
        return item.l2Status && approval === 'level2Approval' ? (<><tr>
          <td className="text-center p-2 mx-1">L1 Approval</td>
          <td className="text-center p-2 mx-1">{item.l1Remarks}</td>
          <td className="text-center p-2 mx-1">{item.l1Status}</td>
          <td className="text-center p-2 mx-1">{`${item?.l1Approver ?? 'AESL-A3244'}`}</td>
          <td className="text-center p-2 mx-1">{item.l1StatusDate && moment(item.l1StatusDate).format("DD MMM YYYY hh:mm a")}</td>
        </tr>

          <tr>
            <td className="text-center p-2 mx-1">L2 Approval</td>
            <td className="text-center p-2 mx-1">{item.l2Remarks}</td>
            <td className="text-center p-2 mx-1">{item.l2Status}</td>
            <td className="text-center p-2 mx-1">{`${item?.l2Approver ?? 'AESL-A6079'}`}</td>
            <td className="text-center p-2 mx-1">{item.l2StatusDate && moment(item.l2StatusDate).format("DD MMM YYYY hh:mm a")}</td>
          </tr>
        </>) : item.l1Status ? (<><tr>
          <td className="text-center p-2 mx-1">L1 Approval</td>
          <td className="text-center p-2 mx-1">{item.l1Remarks}</td>
          <td className="text-center p-2 mx-1">{item.l1Status}</td>
          <td className="text-center p-2 mx-1">{`${item?.l1Approver ?? 'AESL-A3244'}`}</td>
          <td className="text-center p-2 mx-1">{item.l1StatusDate && moment(item.l1StatusDate).format("DD MMM YYYY hh:mm a")}</td>
        </tr></>) : <></>
      })}

      {/* {serverData && serverData.length > pageSize ? (
        <GetPagination
          // fetchClasses={fetchClasses}
          setclassArray={setclassArray}
          pagination={pagination}
          setpagination={setpagination}
          pageSize={pageSize}
          state={serverData}
        />
      ) : null} */}
    </>
  )
}


const ApprovalLogs = (props) => {
  const useDetails = useContext(UserDetails);
  const { selectedTId, approve, approvalRole, setComment, comment, isDisabled = false, approval, userPermissions, l1Remarks, l1Status, l2Remarks, l2Status, isManualRecord, action, serverData } = props;
  const headerList = action === "view" ? [
    { name: "Activity" },
    { name: "Comment" },
    { name: "Action" },
    { name: "Action Taken By" },
    { name: "Updated On" },
  ] : [
    { name: "Activity" },
    { name: "Comment Box" },
    { name: "Action" },
    { name: "Action Taken By" },
  ]

  const chkRemark = (flag) => {
    if (!comment || !comment.toString().trim()) {
      failureToast('Please Enter Comment')
      return
    }
    approve(flag)
  }

  return (
    <Card className="mt-4">
      <CardHeader className="d-flex justify-content-between">
        <h3 className="mb-0 ">Approval Logs</h3>
      </CardHeader>
      <div className="p-4 table-responsive">
        <Table className="align-items-center table-flush">
          <MasterHeaderAuto headerList={headerList} />
          <tbody className="list">

            {action === 'view' ? <RemarkArray serverData={serverData} approval={approval} /> : <CommentBox setComment={setComment} approvalRole={approvalRole} comment={comment} isDisabled={isDisabled} userPermissions={userPermissions} approve={approve} chkRemark={chkRemark} useDetails={useDetails}/>}

            {/* {approval && approval === 'level1Approval' && isManualRecord ?
              l1Status ? <ApprovedRecord approver={'10001 Approval'} remark={l1Remarks} status={l1Status} /> : <CommentBox setComment={setComment} approvalRole={approvalRole} comment={comment} isDisabled={isDisabled} userPermissions={userPermissions} approve={approve} chkRemark={chkRemark} /> : null}

            {approval && approval === 'level2Approval' && isManualRecord ?
              l2Status ? <><ApprovedRecord approver={'10001 Approval'} remark={l1Remarks} status={l1Status} /><ApprovedRecord approver={'10002 Approval'} remark={l2Remarks} status={l2Status} /></> : <><ApprovedRecord approver={'10001 Approval'} remark={l1Remarks} status={l1Status} /><CommentBox setComment={setComment} approvalRole={approvalRole} comment={comment} isDisabled={isDisabled} userPermissions={userPermissions} approve={approve} chkRemark={chkRemark} /></> : null}

            {!isManualRecord ? <CommentBox setComment={setComment} approvalRole={approvalRole} comment={comment} isDisabled={isDisabled} userPermissions={userPermissions} approve={approve} chkRemark={chkRemark} /> : null} */}
          </tbody>
        </Table>
      </div>
    </Card>
  );
};

export default ApprovalLogs;
