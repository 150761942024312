import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader, Container, FormGroup, Input, Row, Table } from "reactstrap";
import { failureToast } from "../../common/utils/methods/toasterFunctions/function";
import { masterServiceBaseUrl, putpost, successToast } from "services/http";
import moment from "moment";
import { getDispValForReactSelectByOneID, getRequiredSign } from "../../common/utils/methods/commonMethods/utilityMethod";
import { RedAstric } from "../../examService/examIdGeneration/examIdSearchForm";
import CommonInput from "../../common/formFeilds/input/commonInput";
import CustomTextArea from "../../common/formFeilds/customTextarea";
import { CustomTableRow } from "../../common/commonComponents/TableComponents";
import Tooltip from 'components/Tooltip';
import { BiFilterAlt } from "react-icons/bi";
import { AiFillCloseCircle } from "react-icons/ai";
var _ = require("lodash");

const TDROW = (props) => {
  const { el, index, editRow, processTag } = props;
  return (
    <CustomTableRow key={"ExamGroupNewOrEditRow"}>
      <td className="text-center p-2 mx-1 white-breakSpace" style={{width: "0px"}}>{el["keyId"]}</td>
      <td className="text-center p-2 mx-1 white-breakSpace" style={{wordBreak: "break-all", width: "100px"}}>{getDispValForReactSelectByOneID(el["processTagId"], "DispValue", processTag)}</td>
      <td className="text-center p-2 mx-1 white-breakSpace" style={{wordBreak: "break-all", width: "300px"}}>{el["messageCatalogDescription"]}</td>
      <td className="text-center p-2 mx-1 white-breakSpace" style={{wordBreak: "break-all", width: "200px"}}>{el["messageCatalogDispValue"]}</td>
      <td className="text-center p-2 mx-1 white-breakSpace" style={{wordBreak: "break-all", width: "50px"}}>{moment(el["effectiveDate"]).format("DD-MM-YYYY")}</td>
      <td className="text-center p-2 mx-1 white-breakSpace" style={{wordBreak: "break-all", width: "100px"}}>
        <Button key={"statusButton"} color={el.status === "ACTIVE" ? "success" : "danger"} size={"sm"} disabled={true}>
          {el.status === "ACTIVE" ? "Active" : "Inactive"}
        </Button>
      </td>
      <td className="text-center p-2 mx-1 white-breakSpace" style={{wordBreak: "break-all", width: "50px"}}>
        <Button color="info" size="sm" type="button" className={" mx-1"} onClick={() => editRow(index)}>
          <i className="fas fa-edit" />
        </Button>
        {/*<Button color="info" size="sm" type="button" className={' mx-1'}*/}
        {/*        onClick={() => deleteRow(index)}*/}
        {/*>*/}
        {/*    <i className="fas fa-trash"/>*/}
        {/*</Button>*/}
      </td>
    </CustomTableRow>
  );
};

const FilterColumn = ({ filterFun = () => {}, filterVal, filterMessageCatalog}) => {
  return (
    <div className="filterContainer">
      <FormGroup className="mb-2 me-sm-2 mb-sm-0 filter-input">
        {/* <Label className="me-sm-2" for="exampleEmail">
          Filter
        </Label> */}
        <Input
          // value={filterVal}
          id="sub_sub_topic_search_str"
          name="searchStr"
          placeholder="Search"
          type="email"
          className="filterInput"
          onChange={(e) => filterMessageCatalog(e.target.value)}
        />
        {filterVal && (
          <AiFillCloseCircle
            size={25}
            className="close-icon"
            onClick={() => {
              filterFun("");
            }}
          />
        )}
      </FormGroup>
    </div>
  );
};

const MessageSequenceTable = (props) => {
  const { processTag, filterMessageCatalog } = props;
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [tableDataKey, settableDataKey] = useState("tableDataKey");
  const [isFilterVisible, setFilterVisible] = useState(false);
  const addNewClass = () => {
    setDispValue(null);
    setDescValue(null);
    props.setclassArray([
      {
        editMode: true,
        __status: "__new",
        keyId: null,
        status: "ACTIVE",
        messageCatalogDescription: null,
        messageCatalogDispValue: null,
        effectiveDate: null,
      },
      ...props.classArray,
    ]);
  };

  const saveAll = (cb) => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if (el["editMode"]) {
        if (el["processTagId"] == null) {
          isValid = false;
          failureToast("Please Select Process Tag.");
          return;
        } else if (el['messageCatalogDescription'] == null || (el['messageCatalogDescription'] && !el['messageCatalogDescription'].trim())) {
          isValid = false;
          failureToast("Please enter the value of Description");
          return;
        } else if (el['messageCatalogDispValue'] == null || (el['messageCatalogDispValue'] && !el['messageCatalogDispValue'].trim())) {
          isValid = false;
          failureToast("Please enter the value of Display Value");
          return;
        }
        if (!isValid) {
          return;
        }

        if (_tempArray[index]["__status"] == "__new") {
          putpost(
            masterServiceBaseUrl + "/messageCatalog/createMessageCatalog",
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              props.fetchClasses(masterServiceBaseUrl + "/messageCatalog/getAllMessageCatalog");
            },
            (data) => {
              failureToast(data["message"]);
            },
            _tempArray[index],
            "post"
          );
        } else {
          putpost(
            masterServiceBaseUrl + "/messageCatalog/updateMessageCatalog/" + _tempArray[index].id,
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              delete _tempArray[index]["editMode"];
              props.setclassArray(_tempArray);
              settableDataKey(new Date().getMilliseconds());
              props.fetchClasses(masterServiceBaseUrl + "/messageCatalog/getAllMessageCatalog");
            },
            (data) => {
              failureToast(data["message"]);
            },
            _tempArray[index],
            "put"
          );
        }
      } else {
      }
    });
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
        // settableDataKey(new Date().getMilliseconds())
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      cb();
    }
  };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        setDescValue(_tempArray[index]["messageCatalogDescription"]);
        setDispValue(_tempArray[index]["messageCatalogDispValue"]);
        _tempArray[index]["editMode"] = true;
        // _tempArray[index]['documentTag'] = _tempArray[index]['documentTag'];
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };
  const deleteRow = (index) => {
    if (props.classArray[index]["__status"] == "__new") {
      let n = props.classArray;
      n.splice(index, 1);
      props.setclassArray(n);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
    } else {
      if (window.confirm("Are you sure you want to delete this record?")) {
        // putpost2(examControllerServiceBaseUrl + "/testSequenceMaster/deleteExamTestSequence/" + props.classArray[index].id, (data) => {
        //     successToast(data["message"]);
        //     props.fetchClasses(examControllerServiceBaseUrl + "/testSequenceMaster/getAllExamTestSequence");
        //     if (props.classArray.length == 1) {
        //         // props.previousPage();
        //         // props.fetchClasses(masterServiceBaseUrl + "/processTag/getAllProcessTag");
        //     } else {
        //         var _tempArray = props.classArray;
        //         if (_tempArray[index]["editMode"]) {
        //             setisSaveVisible(false);
        //         }
        //         _tempArray.splice(index, 1);
        //         props.setclassArray(_tempArray);
        //     }
        //     settableDataKey(new Date().getMilliseconds());
        // }, (data) => {failureToast(data["message"]);}, "PUT");
      }
    }
  };
  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
  };
  useEffect(() => {
  }, [props.classArray]);
  const headerList = [
    { name: "TEST SEQUENCE KEY" },
    { name: "EFFECTIVE DATE" },
    { name: "DESCRIPTION" },
    { name: "DISPLAY VALUE" },
    { name: "STATUS" },
    { name: "ACTION" },
  ];
  const [dispValue, setDispValue] = useState(null);
  const [descValue, setDescValue] = useState(null);

  return (
    <React.Fragment>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Message Catalog</h3>
                <Button
                  data-testid="newCourseFee"
                  disabled={isSaveVisible}
                  color="info"
                  size="sm"
                  type="button"
                  className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
                  onClick={() => {
                    newRow();
                  }}
                >
                  <i className="fas fa-plus" />
              &nbsp; New Message Catalog
                </Button>
                {/* {isSaveVisible ? <Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => {saveAll()}}>Save</Button>:null} */}
              </CardHeader>
              <div id="questionTable" className="table-responsive" >
                <Table className="align-items-center table-flush">
                  <thead className="thead-light">
                    <tr>
                      <th className="text-uppercase text-center white-breakSpace">
                        MESSAGE CATALOG KEY
                      </th>
                      <th className="text-uppercase text-center white-breakSpace">
                        PROCESS TAG <RedAstric />
                        <BiFilterAlt
                          size={15}
                          className="float-right filter-icon"
                          onClick={() => setFilterVisible(!isFilterVisible)}
                        />
                      </th>
                      <th className="text-uppercase text-center white-breakSpace" style={{width: '0px'}}>
                        DESCRIPTION <RedAstric />
                        <Tooltip content={"500 alphanumeric text with special characters dot(.) and space are allowed."} placement={'top'} >
                          <span style={{marginLeft: '4px', color: '#8898aa'}} id={'messageDesc'} className={'messageDesc'}>
                            <svg version="1.1" viewBox="0 0 512 512" width="13px" height="13px">
                              <path
                                fill="currentColor"
                                d={`M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z`}
                              />
                            </svg>
                          </span>
                        </Tooltip>
                      </th>
                      <th className="text-uppercase text-center white-breakSpace">
                        DISPLAY VALUE <RedAstric />
                        <Tooltip content={"200 alphanumeric text with special characters dot(.) and space are allowed."} placement={'top'} >
                          <span style={{marginLeft: '4px', color: '#8898aa'}} id={'messageDesc'} className={'messageDesc'}>
                            <svg version="1.1" viewBox="0 0 512 512" width="13px" height="13px">
                              <path
                                fill="currentColor"
                                d={`M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z`}
                              />
                            </svg>
                          </span>
                        </Tooltip>
                      </th>
                      <th className="text-uppercase text-center white-breakSpace">
                        EFFECTIVE DATE
                      </th>
                      <th className="text-uppercase text-center white-breakSpace">
                        STATUS
                      </th>
                      <th className="text-uppercase text-center white-breakSpace">
                        ACTION
                      </th>
                    </tr>
                    {isFilterVisible && (
                    <tr>
                      <th></th>
                      <th
                        style={{ padding: "12px 4px " }}
                        className="text-uppercase text-center white-breakSpace"
                        rowSpan={1}
                        colSpan={1}
                      >
                        <FilterColumn
                          filterFun={props.setSearchStr}
                          filterVal={props.searchStr}
                          filterMessageCatalog={filterMessageCatalog}
                          />
                      </th>
                      <th></th><th></th><th></th><th></th><th></th></tr>
                   )}
                   
                  </thead>
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                          !el["editMode"] ? (
                            <TDROW processTag={processTag} type={"feeApproval"} index={index} el={el} editRow={editRow} deleteRow={deleteRow} />
                          ) : (
                            <tr key={index + "-class"}>
                              <td className="text-center p-2 mx-1">
                                <CommonInput
                                  forTable={true}
                                  type={"text"}
                                  placeHolder={"System Generated"}
                                  defaultValue={el["keyId"]}
                                  isDisabled={true}
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <CommonInput
                                  forTable={true}
                                  type={"reactSelect"}
                                  placeHolder={"Process Tag"}
                                  defaultValue={el["processTagId"]}
                                  data={processTag}
                                  onChange={(value) => {
                                    updateKey(index, value, "processTagId");
                                  }}
                                  isDisabled={false}
                                />
                              </td>

                              <td className="text-center p-2 mx-1">
                                <CustomTextArea
                                  isDisabled={false}
                                  maxLength={500}
                                  minLength={1}
                                  placeHolder={"Description"}
                                  regex={/^[A-Za-z0-9 .]*$/}
                                  regValidation={true}
                                  index={index}
                                  defaultValue={el?.messageCatalogDescription}
                                  keyName={"messageCatalogDescription"}
                                  handler={updateKey}
                                  rows={1}
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <CustomTextArea
                                  isDisabled={false}
                                  maxLength={200}
                                  minLength={1}
                                  placeHolder={"Display Value"}
                                  regex={/^[A-Za-z0-9 .]*$/}
                                  regValidation={true}
                                  index={index}
                                  defaultValue={el?.messageCatalogDispValue}
                                  keyName={"messageCatalogDispValue"}
                                  handler={updateKey}
                                  rows={1}
                                />
                              </td>

                              <td className="text-center p-2 mx-1">
                                <CommonInput
                                  forTable={true}
                                  type={"text"}
                                  placeHolder={moment().format("DD-MM-YYYY")}
                                  defaultValue={el["__status"] === "__new" ? null : moment(el["effectiveDate"]).format("DD-MM-YYYY")}
                                  onChange={(value) => {
                                    updateKey(index, value, "messageCatalogDesc");
                                  }}
                                  isDisabled={true}
                                />
                              </td>

                              <td className="text-center p-2 mx-1">
                                <label className="custom-toggle mx-auto ml-2">
                                  <input
                                    data-testid="activeInactive"
                                    checked={el.status == "ACTIVE" ? true : false}
                                    type="checkbox"
                                    id={el.id}
                                    onChange={(e) => {
                                      settableDataKey(new Date().getMilliseconds());
                                      let n = "ACTIVE";
                                      if (!e.target.checked) {
                                        n = "INACTIVE";
                                      }
                                      updateKey(index, n, "status");
                                    }}
                                  />
                                  <span
                                    style={{ width: "72px" }}
                                    className="custom-toggle-slider rounded-circle activeToggle"
                                    data-label-off="Inactive"
                                    data-label-on="Active"
                                  />
                                </label>
                              </td>
                              <td className="text-center">
                                <Button
                                  data-testid="savefee"
                                  color="info"
                                  size="sm"
                                  type="button"
                                  className="mx-1"
                                  onClick={() => {
                                    saveAll();
                                  }}
                                >
                                  Save
                                </Button>
                                <Button
                                  data-testid="closeApproval"
                                  color="danger"
                                  size="sm"
                                  type="button"
                                  onClick={() => {
                                    let n = props.classArray;
                                    if (n[index]["__status"] == "__new") {
                                      n.splice(index, 1);
                                    } else {
                                      n[index]["editMode"] = false;

                                      // props.fetchClasses(examControllerServiceBaseUrl + '/testSequenceMaster/getAllExamTestSequence')
                                      // props.fetchClasses(masterServiceBaseUrl + "/processTag/getAllProcessTag");
                                    }
                                    props.setclassArray(n);
                                    setisSaveVisible(false);
                                  }}
                                >
                                  <i className="fas fa-times" />
                                </Button>{" "}
                              </td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default MessageSequenceTable;
