import React from 'react'

export const CalendarIcon = ({isActive = false, className, disabled,onClick=()=>{}}) => {
    return (
        <span className={className} style={!disabled ? {backgroundColor: 'transparent'}:{backgroundColor: 'transparent'}} onClick={onClick}>
            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.422 1.563h-1.797V.625a.625.625 0 0 0-1.25 0v.938h-8.75V.625a.625.625 0 0 0-1.25 0v.938H2.578A2.581 2.581 0 0 0 0 4.14v13.28A2.581 2.581 0 0 0 2.578 20h14.844A2.581 2.581 0 0 0 20 17.422V4.14a2.581 2.581 0 0 0-2.578-2.579zM2.578 2.813h1.797v.624a.625.625 0 0 0 1.25 0v-.624h8.75v.624a.625.625 0 0 0 1.25 0v-.624h1.797A1.33 1.33 0 0 1 18.75 4.14v1.484H1.25V4.141a1.33 1.33 0 0 1 1.328-1.329zM17.422 18.75H2.578a1.33 1.33 0 0 1-1.328-1.328V6.875h17.5v10.547a1.33 1.33 0 0 1-1.328 1.328z" fill={isActive ?"#00B0F5":"#a1aeb7"} fillRule="nonzero" />
            </svg>
        </span>
    )
}


