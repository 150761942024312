import React, { useState , useEffect} from 'react';
import { Card, CardHeader, CardBody, Label, FormGroup, Input, Container, Row, Col } from 'reactstrap';
import Select from 'react-select';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import BasicDetailsForm from './businessUnitNewUI';
import IsCoreMaster from 'views/pages/manage/common/commonComponents/coreMaster';
import LocationDetailsForm from './LocationDetailsForm';
import ContactDetailsForm from './ContactDetailsForm';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const BusinessUnitFormTabs = ({
    locationAndContactDetailsData,
    fetchLocationAndContactDetails=()=>{},
    fetchBusinessAreaDetails=()=>{},
    ...props
}) => {

    const [buId, setBuId] = useState(props.formId)

    const [selectedTabValue, setSelectedTabValue] = useState('one');

    const handleTabChange = (event, newValue) => {
        console.log('newValue', newValue);
        if (buId !== null && buId !== undefined)
            setSelectedTabValue(newValue);
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: '#262626',
            },
            secondary: {
                main: '#00AEEF',
            },
        },
    });

    useEffect(()=>{
        fetchLocationAndContactDetails();
        fetchBusinessAreaDetails();
        setBuId(props?.formId);
    },[props?.formId])

    return (
        <>
            <Card className="m-2 w-100">
                {props?.action != 'view' && props?.action != 'edit' ? (
                    <CardHeader>
                        <Row>
                            <Col md="6">
                                <h3 className="mb-0 floatLeft">Business Area</h3>
                                <IsCoreMaster master={'businessArea'} masterDisplayValue={'Business Area'} />
                            </Col>
                            <Col md="6">
                                <div>
                                    <h5>Copy From BU</h5>
                                    {props?.businessAreaList ? (
                                        <Select
                                            value={props.copyBusinessArea}
                                            id="example1cols1Input"
                                            options={props?.businessAreaList}
                                            isDisabled={props?.isPrevious}
                                            onChange={sData => {
                                                // resetValues();
                                                props.setCopyBusinessArea(sData);
                                            }}
                                        //   onChange={ ( sData ) => {
                                        //   // //////console.log(sData.value)
                                        //     setSelectedCode( preState => ( { ...preState, groupCodeId: sData.value } ) );
                                        //   getCompanyCode(sData.value);
                                        //   setValue({ ...value, groupCode: sData });
                                        // }}
                                        />
                                    ) : (
                                        <span>Loading List ...</span>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                ) : null}

                <div style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>
                    <Box sx={{ width: '100%' }}>
                        <ThemeProvider theme={theme}>
                            <Tabs
                                value={selectedTabValue}
                                onChange={handleTabChange}
                                textColor="primary"
                                indicatorColor="secondary"
                                aria-label="businessUnitFormTabs"
                                style={{ textTransform: 'none' }}
                            >
                                <Tab value="one" label="Basic Information" />
                                <Tab value="two" label="Location Details" />
                                <Tab value="three" label="Contact Details" />
                            </Tabs>
                        </ThemeProvider>
                    </Box>
                </div>


                <CardBody>

                    <BasicDetailsForm
                        {...props}
                        selectedTabValue={selectedTabValue}
                        setBuId={setBuId}
                        setSelectedTabValue={setSelectedTabValue}
                    />

                    <LocationDetailsForm
                        selectedTabValue={selectedTabValue}
                        action={props?.action}
                        buId={buId}
                        locationDetails={locationAndContactDetailsData?.data[0]}
                        locationDetailsFetching={locationAndContactDetailsData?.fetching}
                        setSelectedTabValue={setSelectedTabValue}
                        fetchLocationAndContactDetails={fetchLocationAndContactDetails}
                    />

                    <ContactDetailsForm
                        selectedTabValue={selectedTabValue}
                        action={props?.action}
                        buId={buId}
                        contactDetails={locationAndContactDetailsData?.data[1]}
                        contactDetailsFetching={locationAndContactDetailsData?.fetching}
                        fetchLocationAndContactDetails={fetchLocationAndContactDetails}
                    />

                </CardBody>
            </Card>
        </>

    );
}

export default BusinessUnitFormTabs;