import React from 'react';
import { endpoint } from 'views/pages/manage/common/constant';
import { fetchAllPromisedData } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { NEW_APPROVAL_STATUS, STATUS_DATA } from "./constants";
import dummyData from "./auto.json";
import { getUniqueKey } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';

export const getExtraCompValue = (a, b, c, d, e) => {
  if (a !== 0) {
    return a;
  }

  const nonZeroParams = [b, c, d, e].filter(param => param !== 0);

  if (nonZeroParams.length === 0) {
    // All other parameters are zero, return a (which is also zero)
    return a;
  }

  return Math.min(...nonZeroParams);
}

export const canRevoke = (status, isApproved) => {
  return !isApproved && ['Pending', 'L1_APPROVED', 'L2_APPROVED', 'L3_APPROVED'].includes(status) ? true : false;
}

export const canModify = (isApproved) => {
  return isApproved ? true : false;
}

export const getCurrentStatus = (status, isApproved, isCapitaized = false) => {
  //console.log("status",status)
  if (isApproved) {
    return isCapitaized ? 'Approved' : 'APPROVED';
  } else if (['Pending', 'L1_APPROVED', 'L2_APPROVED', 'L3_APPROVED'].includes(status)) {
    return isCapitaized ? 'Pending' : 'PENDING';
  } else if (['L1_RECHECK', 'L2_RECHECK', 'L3_RECHECK'].includes(status)) {
    return isCapitaized ? 'Recheck' : 'RECHECK';
  } else if (['L1_REJECT', 'L2_REJECT', 'L3_REJECT'].includes(status)) {
    return isCapitaized ? 'Rejected' : 'REJECTED'
  } else {
    return 'NA'
  }
}

export const getFeeMapping = (allSlabs) => {
  const feeMap = {
    adminFeeConcession: false,
    classFeeConcession: false,
    regFeeConcession: false,
    techFeeConcession: false,
    tutionFeeConcession: false
  };

  allSlabs.forEach(item => {
    if (item?.action !== 'DELETE' && (item?.fromBE || item?.action === 'CREATE' || item?.action === 'UPDATE')) {
      feeMap.adminFeeConcession = feeMap.adminFeeConcession || item.adminFeeConcession > 0;
      feeMap.classFeeConcession = feeMap.classFeeConcession || item.classFeeConcession > 0;
      feeMap.regFeeConcession = feeMap.regFeeConcession || item.regFeeConcession > 0;
      feeMap.techFeeConcession = feeMap.techFeeConcession || item.techFeeConcession > 0;
      feeMap.tutionFeeConcession = feeMap.tutionFeeConcession || item.tutionFeeConcession > 0;
    }
  });
  return feeMap;
}

export const GetDisplayData = ({ status, isApproved }) => {
  // alert(status)
  const sts = STATUS_DATA[isApproved ? 'Approved' : ['L1_REJECT', 'L2_REJECT', 'L3_REJECT'].includes(status) ? 'Rejected' : ['L1_RECHECK', 'L2_RECHECK', 'L3_RECHECK'].includes(status) ? 'Recheck' : ''];
  // alert(sts)
  return (
    sts !== undefined ? <div className='text-center py-2 mb-3' style={{ backgroundColor: sts?.BCOLOR, color: sts?.COLOR, borderRadius: '6px', fontSize: '14px' }}>
      <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ margin: '0px 6px 3px 0px' }}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.65151 0.961394L13.4974 9.37148C13.604 9.62251 13.6507 9.82663 13.664 10.0387C13.6907 10.5341 13.5174 11.0157 13.1774 11.3863C12.8375 
              11.7556 12.3776 11.9736 11.8776 12H2.11928C1.91265 11.9874 1.70602 11.9405 1.51271 11.8679C0.546211 11.4781 0.0796231 10.3815 0.47289 9.43093L5.35207 0.955449C5.51871 0.657519 5.772 0.400546 
              6.08528 0.235396C6.99179 -0.26732 8.14493 0.0629796 8.65151 0.961394ZM7.57836 6.50382C7.57836 6.82091 7.3184 7.08581 6.99846 7.08581C6.67851 7.08581 6.41189 6.82091 6.41189 6.50382V4.63498C6.41189 
              4.31724 6.67851 4.06026 6.99846 4.06026C7.3184 4.06026 7.57836 4.31724 7.57836 4.63498V6.50382ZM6.99846 9.34505C6.67851 9.34505 6.41189 9.08015 6.41189 8.76373C6.41189 8.44598 6.67851 8.18174 6.99846 
              8.18174C7.3184 8.18174 7.57836 8.44003 7.57836 8.75646C7.57836 9.08015 7.3184 9.34505 6.99846 9.34505Z" fill={sts?.SVG_COLOR} /></svg>
      {sts?.DISPLAY_TEXT}
    </div> : null
  )
}

export const checkForAutoConcession = (category, autoConcessionType, concessionDataId) => {
    const isSubSubCategoryExists = category?.subCategory?.isSubSubCategoryExists;
    const subOrSubsubCategory = isSubSubCategoryExists ? category?.subCategory?.subSubCategory : category?.subCategory;
    const concessionSlab = subOrSubsubCategory?.concessionSlabDto;
    console.log("concessionSlab",concessionSlab)
    return {
      action: "CREATE",
      id: getUniqueKey(),
      categoryId: category?.id,
      category: {...subOrSubsubCategory, concessionMethodType: `AUTO_${autoConcessionType}`, isSubSubCategory: isSubSubCategoryExists ? true : false, concessionDataId: concessionDataId  },
      code: [{ label: category?.categoryCode, value: category?.categoryCode }],
      concessionSubCategoryId: isSubSubCategoryExists ? null : subOrSubsubCategory?.id,
      concessionSubSubCategoryId: isSubSubCategoryExists ? subOrSubsubCategory?.id : null,
      isLumpSumCategory: category?.isLumpSumCategory || null,
      concessionCategoryCode: category?.categoryCode,
      concessionRemarks: null,
      updatedOn: null,
      scholarshipCriteria: subOrSubsubCategory?.scholarshipCriteria,
      scholarshipCriteriaValue: subOrSubsubCategory?.scholarshipCriteria === 'QUALIFIED' ? 0 : (subOrSubsubCategory?.scholarshipCriteriaValue) ?? null,
      concessionSlabId: concessionSlab?.id,
      adminFeeConcession: concessionSlab?.admissionFee || 0,
      classFeeConcession: concessionSlab?.classFee || 0,
      techFeeConcession: concessionSlab?.techFee || 0,
      tutionFeeConcession: concessionSlab?.tuitionFee || 0,
      regFeeConcession: concessionSlab?.regFee || 0,
      concessionParameter: subOrSubsubCategory?.concessionParameter,
      concessionDocuments: [],
      concessionType: "DEFINED",
      isAutoConcession: true,
      concessionMethodType: `AUTO_${autoConcessionType}`,
      concessionDataId: concessionDataId
    };
};

export const getLowestNetPayApi = async (
  {applicationId,
  applicationQuotationDetailsId}
) => {
  return await fetchAllPromisedData(
    `${endpoint.checkModifyAllowed}/${applicationId}?quotationId=${applicationQuotationDetailsId}`,
    true,
    true
  );
};


export const getPaidItemListApi = async ({
  applicationId,
  applicationQuotationDetailsId,
}) => {
  
  if (!applicationId || !applicationQuotationDetailsId) return [];

  return await fetchAllPromisedData(
    `${endpoint.paidItemTypeList}/${applicationId}?quotationId=${applicationQuotationDetailsId}`,
    true,
    true
  );
};

export const formatPaidItemType = (response) => {
  if (!response) return [];
  if (response && response?.code === 200){
    return response?.data?.filter((item) => item?.fullyPaid|| item?.paidAmount>0);
  }

};

export const getPaidItemTypeList = (paidItemTypes)=>paidItemTypes?.map(item=>item['itemType'])||[]

const getTotalConcessionOfEachComponent = (componentMap)=>{
   // key:componentKey, value: total concession
  const componentMapWithConcession = {}
   for(let component in componentMap){
     const {flAmt, ylAmt, ulAmt, oAmt} = componentMap[component]
     componentMapWithConcession[component] = flAmt + ylAmt + ulAmt +  oAmt + 0
   }
   return componentMapWithConcession
}
// sum of pending + nonPending
export const calculateTotalConcessionMax = (componentMap, pendingConcMap)=>{
 const componentMapWithConcession = {}
  for(let component in componentMap){
    const {flAmt, ylAmt, ulAmt, oAmt} = componentMap[component]
    const   { flAmt :flAmtPending, ylAmt:ylAmtPending, ulAmt:ulAmtPending, oAmt:oAmtPending} = pendingConcMap[component]
    componentMapWithConcession[component] = flAmt + ylAmt + ulAmt +  oAmt + flAmtPending + ylAmtPending + ulAmtPending + oAmtPending + 0 
  }
  return Math.max(...Object.values(componentMapWithConcession))
}

export const getMaximumConcession = (componentMap)=>{
  const concessionMap = getTotalConcessionOfEachComponent(componentMap)
  return Math.max(...Object.values(concessionMap))
}

export const regAndAdmissionComp =  ['100000000029', '100000000030']