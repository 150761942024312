import React, { useContext, useEffect, useRef, useState } from 'react';
import ALoader from '../../../../common/a-loader';
import styles from './styles.module.scss';
import { useHistory, useParams } from 'react-router';
import { ReactComponent as BackIcon } from 'assets/img/svg/BackButton.svg';
import AButton from '../../../../common/form-fields-mui/AButton';
import ListComponent from './ListComponent';
import { endpoint, pages } from '../../../../common/constant';
import { PermissionContext, RolePermissions } from 'appContext';
import {
  fetchAllPostPromisedData,
  fetchAllPromisedData,
  fetchDeletePromisedData,
} from '../../../../common/utils/methods/commonMethods/utilityMethod';
import {
  failureToast,
  successToast,
} from '../../../../common/utils/methods/toasterFunctions/function';
import { validateEmail, validatePhoneNumber } from './constant';
import { PermisionDenied } from 'views/pages/manage/common/commonComponents';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'

const CourierVendorMaster = () => {
  const history = useHistory();
  const { academicCareer } = useParams();
  const userPermissions = RolePermissions(
    useContext(PermissionContext),
    pages['dlpStockAndInventory']['id']
  );

  const listRef = useRef();
  const [page, setPage] = useState(0);
  const onPageChange = newPage => setPage(newPage);
  const [rows, setRows] = useState([]);
  const [listLoader, setListLoader] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);

  const fetchList = async () => {
    setListLoader(true);
    const res = await fetchAllPromisedData(
      `${endpoint.courierVendorMaster.getAll}?academicCareer=${academicCareer}`,
      true
    );
    if (res?.code === 200) {
      setRows(res?.data);
      listRef.current.stopEditing();
    } else {
      setRows([]);
    }
    setListLoader(false);
  };

  const createHandler = async rowData => {
    if (!validateEmail(rowData?.emailId)) return;
    if (!validatePhoneNumber(rowData?.contactNumber)) return;
    setApiLoader(true);
    const reqBody = { ...rowData, academicCareer: academicCareer };
    const res = await fetchAllPostPromisedData(
      endpoint.courierVendorMaster.create,
      reqBody,
      'post'
    );
    if (res?.code === 200) {
      successToast(res?.message);
      fetchList();
      setPage(0);
    } else {
      failureToast(res?.message);
    }
    setApiLoader(false);
  };

  const updateHandler = async rowData => {
    if (!validateEmail(rowData?.emailId)) return;
    if (!validatePhoneNumber(rowData?.contactNumber)) return;
    setApiLoader(true);
    const reqBody = rowData;
    const res = await fetchAllPostPromisedData(
      `${endpoint.courierVendorMaster.update}/${rowData?.id}`,
      reqBody,
      'put'
    );
    if (res?.code === 200) {
      successToast(res?.message);
      fetchList();
    } else {
      failureToast(res?.message);
    }
    setApiLoader(false);
  };

  const deleteHandler = async rowData => {
    setApiLoader(true);
    const res = await fetchDeletePromisedData(
      `${endpoint.courierVendorMaster.delete}/${rowData?.id}`
    );
    if (res?.code === 200) {
      successToast(res?.message);
      fetchList();
    } else {
      failureToast(res?.message);
    }
    setApiLoader(false);
  };

  useEffect(() => {
    fetchList();
  }, []);

  return userPermissions ? (
    <div className='remove-container-top-space'>
      {(listLoader || apiLoader) && <ALoader position='fixed' />}
      <div className={`${styles['header-bar']}`}>
        <IconButtonWrapper onClick={() => history.goBack()}>
          <BackIcon />
        </IconButtonWrapper>
        <div className='ml-3 heading-4 color-dark flex-grow-1'>
          Courier vendor master
        </div>
      </div>
      <div className={`${styles['page-container']}`}>
        <div className='d-flex justify-content-end'>
          <AButton
            className=''
            variant='outline'
            size='xs'
            onClick={() => listRef?.current?.addNewRow()}
          >
            Add new
          </AButton>
        </div>
        <ListComponent
          ref={listRef}
          page={page}
          onPageChange={onPageChange}
          rows={rows}
          loader={listLoader}
          createHandler={createHandler}
          updateHandler={updateHandler}
          deleteHandler={deleteHandler}
        />
      </div>
    </div>
  ) : (
    <PermisionDenied />
  );
};

export default CourierVendorMaster;
