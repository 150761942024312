import React, { createContext, useContext, useState } from "react";
import {
  useFetchDigitalLandingPageDropdown,
  useStudentApplicationList,
} from "../customhooks";
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import { markEligibleStudentApi } from "../service";
import { endpoint } from "../../../common/constant";

export const PAGE_STATE = {
  SEARCH_STUDENT: "SEARCH_STUDENT",
  ISSUE_STOCK: "ISSUE_STOCK",
  MARK_ELIGIBLE: "MARK_ELIGIBLE",
};

export const DigitalModuleContext = createContext({
  currentPageState: "SEARCH_STUDENT",
  setCurrentPageState: () => {},
  loading: false,
  setLoading: () => {},
  studentApplications: [],
  setStudents: () => {},
  filterForm: {},
  setFilterForm: () => {},
});

export const useGetDigitalModuleContext = () => {
  return useContext(DigitalModuleContext);
};

export const FILTER__FORM_FIELDS = {
  businessArea: "",
  term: "",
  academicGroup: "",
  courseId: "",
  typeOfCheckbox: "psid",
  userInput: "",
  scheduleDateFrom: "",
  scheduleDateTo: "",
  dispatchScheduleId: "",
};

export const DIGITAL_DEFAULT_BU = "IC006"; // this is to be defined
export const FILTER__FORM_FIELDS_GLOBAL = {
  sortingField: "REGISTRATION_DATE",
  typeOfCheckbox: "psid",
  userInput: "",
};

export const DigitalModuleProvider = ({ children, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("BULK");
  const [openSuccessPopup, setSuccessPopup] = useState(false);
  const [selectedTab, setSelectedTab] = useState("DISPATCH_PENDING");
  const [markedEligibleRequest, setMarkedEligibleRequest] = useState([]);

  const [page, setPage] = useState(0);

  const [filterForm, setFilterForm] = useState({
    ...FILTER__FORM_FIELDS,
  });
  const [dropdown, dropdownLoader] = useFetchDigitalLandingPageDropdown();
  const [currentPageState, setCurrentPageState] = useState(
    PAGE_STATE.SEARCH_STUDENT
  );
  const [selectedStudentsForMarkEligible, setSelectedStudentsForMarkEligible] =
    useState({});

  const [globalFilter, setGlobalFilter] = useState({
    ...FILTER__FORM_FIELDS_GLOBAL,
  });

  // this will fetch the list on the basis of applied filter ;
  const [
    studentApplications,
    isApplicationLoading,
    getApplications,
    totalPage,
    totalRecords,
    resetStudentApplications,
    setApplications,
    issuedStockToStudent,
  ] = useStudentApplicationList(
    dropdown?.businessAreas,
    endpoint?.DLPStudentSearch,
    "dlppsidStockDetailsSearchResponseDTOS"
  );

  const markEligibleHandler = async (
    applicationIdMap,
    isOnHold = false,
    onHoldBulk = { reason: "" }
  ) => {
    const applicationIds = Object.keys(applicationIdMap).filter(
      (item) => applicationIdMap[item]
    );
    if (applicationIds.length === 0)
      return failureToast("Please select a student for mark eligible!");
    const markEligibleList = [];
    setLoading(true);
    const { businessArea, academicGroup, term, courseId } = filterForm;
    studentApplications.forEach((item) => {
      if (
        applicationIds.some(
          (application) => application === item?.applicationId
        )
      ) {
        const {
          applicationId,
          psid,
          studentName,
          programAction,
          paymentDate,
          status = "DISPATCH_PENDING",
          onHoldReason,
        } = item;
        const { dispatchScheduleId } = filterForm;
        markEligibleList.push({
          applicationId,
          studentName,
          psid,
          programAction,
          plantId: businessArea?.sapPlantId,
          businessAreaDispValue: businessArea?.label,
          academicGroup: academicGroup?.value,
          academicGroupDispValue: academicGroup?.label,
          academicCareer: "ICON",
          businessArea: businessArea?.value,
          term: term?.value,
          termDispValue: term?.label,
          courseId: courseId?.label,
          dispatchId: dispatchScheduleId?.label,
          dispatchScheduleId: dispatchScheduleId?.value,
          paymentDate,
          status: onHoldBulk?.reason ? "ON_HOLD" : status,
          onHoldReason: onHoldBulk?.reason ? onHoldBulk?.reason : onHoldReason,
        });
      }
    });
    const response = await markEligibleStudentApi({
      markEligibleStudentList: markEligibleList,
    });
    if (response?.code === 200) {
      !isOnHold && setSuccessPopup(true);
      // setPage(0);
      getApplications(page, { ...filterForm, academicCareer: "DIGITAL" });
      setSelectedStudentsForMarkEligible({});
      setMarkedEligibleRequest(markEligibleList);
    }
    setLoading(false);
  };

  return (
    <DigitalModuleContext.Provider
      value={{
        loading,
        setLoading,
        currentPageState,
        setCurrentPageState,
        filterForm,
        setFilterForm,
        globalFilter,
        setGlobalFilter,
        dropdown,
        dropdownLoader,
        studentApplications,
        setApplications,
        isApplicationLoading,
        page,
        setPage,
        getApplications,
        issuedStockToStudent,
        resetStudentApplications,
        totalPage,
        totalRecords,
        selectedFilter,
        setSelectedFilter,
        markedEligibleRequest,

        selectedStudentsForMarkEligible,
        setSelectedStudentsForMarkEligible,
        selectedTab,
        setSelectedTab,
        markEligibleHandler,
        openSuccessPopup,
        setSuccessPopup,

        ...props,
      }}
    >
      {children}
    </DigitalModuleContext.Provider>
  );
};
