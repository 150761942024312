import React from 'react'
import { ReactComponent as Info } from '../../../../../../assets/img/svg/fi_info.svg';

const NoRowOverlay = () => {
  return (
    <div className='d-flex flex-row justify-content-center align-items-center' style={{ width: '100%', height: '32px', backgroundColor: '#F5F5F5' }}>
      {/* <Folder className='mb-2' /> */}
      <Info className='mr-2'/>
      <span className='regular-small'>No information to display</span>
    </div>
  )
}

export default NoRowOverlay