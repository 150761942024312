import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import AAutoComplete from "./../../common/form-fields-mui/AAutoComplete";
import { Col, Row } from "reactstrap";
import CustomDatePickerV2 from "../../common/commonComponents/CustomDatePicker-V2";
import { ReactComponent as Uparrow } from "../../../../../assets/img/svg/upwardArrow.svg";
import { headingStyle, isBasicFormFilled } from "./style";
import { formatDropdown, modeDropdown } from "./../constant/common";
import moment from "moment";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
} from "@mui/material";
import { InputLabelStyle } from "../../common/form-fields-mui/ASelect";
import { primaryCheckboxStyles } from "views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant";
import { isDatePassed } from "../academicTimetable/helper";
import { TEST_MODE_FOR_9AM } from "./TestForm";
const isTestPlatform = (testMediumType) =>
  testMediumType?.toLowerCase() === "test_platform";
const isTribyte = (testMediumType) =>
  testMediumType?.toLowerCase() === "tribyte";

export const minimumOnlineDate = (mode, startDate) => {
return TEST_MODE_FOR_9AM.includes(mode?.value)
    ? moment(startDate).add(1, "d")
    : startDate;
};

const BasicForm = ({
  basicFormModal,
  basicFormHandler,
  isBasicExpanded,
  setBasicExpanded,
  setIsSecondFormOpen,
  formData,
  isResultDatePassed,
  isTimeTableTest,
}) => {
  const { format, mode, startDate, endDate, resultDate, testMediumType } =
    basicFormModal;
  const onChangeHandler = () => {
    if (isBasicExpanded && isBasicFormFilled(basicFormModal)) {
      //  setIsSecondFormOpen(!isBasicExpanded)
    }
    setBasicExpanded(!isBasicExpanded);
  };
  return (
    <Row className="mb-4">
      <Col md={12}>
        <Accordion expanded={isBasicExpanded} onChange={onChangeHandler}>
          <AccordionSummary
            expandIcon={<Uparrow />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <span style={headingStyle}>1. </span>
          </AccordionSummary>
          <AccordionDetails>
            <Row>
              <Col md={4}>
                <AAutoComplete
                  isMulti={false}
                  currentValue={format}
                  handleChange={(value) => basicFormHandler(value, "format")}
                  label="Format*"
                  items={formatDropdown}
                  isDisabled={isResultDatePassed}
                />
              </Col>
              <Col md={4}>
                <AAutoComplete
                  isMulti={false}
                  currentValue={mode}
                  handleChange={(value) => basicFormHandler(value, "mode")}
                  label="Mode*"
                  items={modeDropdown}
                  isDisabled={isResultDatePassed}
                />
              </Col>
              <Col md={4}>
                <CustomDatePickerV2
                  label="Start date*"
                  placeHolder="Select start date"
                  value={startDate}
                  handler={(index, date) => basicFormHandler(date, "startDate")}
                  isStartDate={true}
                  endDate={endDate}
                  minDate={
                    moment(formData?.startFrom) < Date.now()
                      ? Date.now()
                      : formData?.startFrom || Date.now()
                  }
                  maxDate={formData?.endOn}
                  disabled={mode === ""  ||isResultDatePassed}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <CustomDatePickerV2
                  label="End date*"
                  placeHolder="Select end date"
                  value={endDate}
                  startDate={startDate}
                  handler={(index, date) => basicFormHandler(date, "endDate")}
                  isEndDate={true}
                  minDate={minimumOnlineDate(mode, startDate)}
                  maxDate={formData?.endOn}
                  disabled={startDate === "" || isResultDatePassed}
                />
              </Col>
              <Col md={4}>
                <CustomDatePickerV2
                  value={resultDate}
                  handler={(index, date) =>
                    basicFormHandler(date, "resultDate")
                  }
                  label="Result date*"
                  placeHolder="Select result date"
                  minDate={moment(endDate).add(1, "days")}
                  disabled={endDate === ""}
                />
              </Col>
              {!isTimeTableTest &&
                mode &&
                mode?.value?.toLowerCase() !== "offline" && (
                  <Col md={4}>
                    <div className="color-grey-60">
                      <InputLabel sx={InputLabelStyle}>Test medium</InputLabel>
                      <div
                        className="d-flex align-items-center justify-content-between"
                        style={{ height: "40px" }}
                      >
                        <div>
                          <FormControlLabel
                            disableTypography
                            sx={{
                              marginBottom: "0px",
                              ...primaryCheckboxStyles,
                            }}
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  basicFormHandler(
                                    e?.target?.checked ? "Tribyte" : "",
                                    "testMediumType"
                                  );
                                }}
                                checked={isTribyte(testMediumType)}
                                disabled={isResultDatePassed}
                              />
                            }
                            label={
                              <div
                                className={`regular-body ${
                                  isTribyte(testMediumType)
                                    ? ""
                                    : "color-grey-60"
                                }`}
                              >
                                Tribyte
                              </div>
                            }
                          />
                        </div>
                        <div>
                          <FormControlLabel
                            disableTypography
                            sx={{
                              marginBottom: "0px",
                              ...primaryCheckboxStyles,
                            }}
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  basicFormHandler(
                                    e?.target?.checked ? "Test_Platform" : "",
                                    "testMediumType"
                                  );
                                }}
                                checked={isTestPlatform(testMediumType)}
                                disabled={isResultDatePassed}
                              />
                            }
                            label={
                              <div
                                className={`regular-body ${
                                  isTestPlatform(testMediumType)
                                    ? ""
                                    : "color-grey-60"
                                }`}
                              >
                                Test platform
                              </div>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                )}
            </Row>
          </AccordionDetails>
        </Accordion>
      </Col>
    </Row>
  );
};
export default BasicForm