import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import {
	Container,
	Card,
	Col,
	Input,
	CardHeader,
	Button,
	FormText,
} from 'reactstrap';
import Loader from 'react-loader-spinner';
import {
	getAPI,
	pricingServiceBaseUrl,
	examControllerServiceBaseUrl,
	putpost,
	successToast,
	failureToast,
} from 'services/http';
import style from './bluckUpload.module.scss';

import CustomDragAndDrop from '../../../common/commonComponents/DragAndDrop';
import CustomModel from '../../../common/commonComponents/Model';

const UploadDocument = (props) => {
	const inputRef = useRef(null);
	const [value, setValues] = useState({
		description: '',
		status: { label: '', value: '' },
	});

	const [isUploading, setISUploading] = useState(false);

	const [file, setFile] = useState(null);
	const [fileName, setFileName] = useState('');
	const [progressBarStatus, setProgressBarStatus] = useState(0);
	const [showModel, setModel] = useState(false);
	const [processId, setProcessId] = useState();
	const handleFile = (e) => {
		if (
			e.target.files !== null &&
			e.target.files !== undefined &&
			e.target.files.length
		) {
			console.log(e);
			setFileName(e.target.files[0].name);
			setFile(e.target.files[0]);
		}
	};

	const chkFile = () => {
		if (!value.description || !value.description.trim()) {
			setProgressBarStatus(0);
			failureToast('Please enter description');
			return;
		}
		if (!file) {
			setProgressBarStatus(0);
			failureToast('Please select file');
			return;
		}
		setISUploading(true);
		setProgressBarStatus(10);

		// const url = `${pricingServiceBaseUrl}/validateFile?filename=${file.name}`;
		const url = `http://10.10.2.223:8596/api/documentService/documentManagement/getUploadUrl`;

		const dataToServe = {
			bulk: true,
			fileName: file.name,
			docType: 'Marksheet',
			sourceId: 40,
			keyValue: '13testtest temp00123463_000000000009',
			serviceName: 'STUDENT',
			docType: 'EB',
		};
		putpost(
			url,
			(data) => {
				submitFileToServer(
					data.data.storage,
					data.data.processId,
					data.data.bucket,
					dataToServe
				);
				setProcessId(data.data.processId);
				setProgressBarStatus(20);
			},
			(data) => {
				failureToast(data['message']);
				setISUploading(false);
				setProgressBarStatus(0);
			},
			dataToServe,
			'post'
		);

		// getAPI(
		//   url,
		//   (data) => {
		//     if (data.code === 200) {
		//       checkForLocation();
		//       setProgressBarStatus(25);
		//     }
		//     // submitOnAws()
		//   },
		//   (data) => {

		//     console.log('File is here : ',file)
		//     failureToast(
		//       "Please, modify the file name and upload again as a file with same name is already exist in DB."
		//     );
		//     setISUploading(false);
		//     setProgressBarStatus(0);
		//   }
		// );
	};

	// const checkForLocation = () => {
	//   getAPI(
	//     `${pricingServiceBaseUrl}/checkFileStorageLocation`,
	//     (data) => {
	//       if (data.code === 200 && data.data) {
	//         setProgressBarStatus(70);

	//         // console.log(
	//         //   "================================================================="
	//         // );
	//         // console.log(data.data);
	//         // console.log(file.size);

	//         let fileSize = 0;

	//         if (data.data.sizeIn === "MB") {
	//           fileSize = file.size / 1024 / 1024;
	//         }

	//         if (data.data.sizeIn === "KB") {
	//           fileSize = file.size;
	//         }

	//         if (fileSize > Number(data.data.size)) {
	//           failureToast("File size is less than 2 mb");
	//           setFile(null);
	//           return;
	//         } else {
	//           submitFileToServer(data.data.url);
	//         }
	//       }
	//       // submitOnAws()
	//     },
	//     (data) => {
	//       failureToast(data["message"]);
	//       setISUploading(false);
	//       setProgressBarStatus(0);
	//     }
	//   );
	// };

	const submitFileToServer = (url, processId, bucket, dataToServe) => {
		const fileData = new FormData();
		fileData.append('File', file);
		fileData.append('destinationfolder', bucket);

		const requestOptions = {
			method: 'POST',
			body: fileData,
			Headers: {
				'Content-Type': 'multipart/form-data',
			},
		};
		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				if (data.response) {
					setProgressBarStatus(40);
					sendToServer(data, processId, bucket, dataToServe, url);
				} else {
					successToast(data.message);
					setISUploading(false);
				}
			})
			.catch((err) => {
				failureToast(err.message);
				setISUploading(false);
				setProgressBarStatus(0);
			});
	};

	const sendToServer = (data, processId, bucket, dataToServe2, url) => {
		const payload = {
			bucket: bucket,
			etag: data.response.ETag,
			fileName: dataToServe2.fileName,
			filePath: data.response.Location,
			fileProcessingStatus: 'process',
			key: data.response.Key,
			processInstanceId: processId,
			uploaderId: 'string',
			url: url,
		};
		const dataToServe = {
			bucketName: bucket,
			errorCode: '',
			etag: data.response.ETag,
			processId: processId,
			s3Url: data.response.Location,
			serviceName: 'EXAM',
		};

		putpost(
			`http://10.10.2.223:8596/api/documentService/documentManagement/s3UploadedResponse`,
			// `http://172.31.10.49:8596/api/documentService/documentManagement/s3UploadedResponse`,
			(data) => {
				setProgressBarStatus(80);
				createExamIdBulkUploadDetails(payload);
				successToast(
					`Uploaded Successfully With Process ID : ${data.processInstanceId}`
				);

				// setProcessId(data.processInstanceId);
				// setValues({
				//   description: "",
				//   status: { label: "", value: "" },
				// });
				// setModel(true);
				// setTimeout(() => redirectHandler(), 2500);
			},
			(data) => {
				failureToast(data['message']);
				setISUploading(false);
				setProgressBarStatus(0);
			},
			dataToServe,
			'post'
		);
	};

	const createExamIdBulkUploadDetails = (payload) => {
		putpost(
			`${examControllerServiceBaseUrl}/examIdBulkUpload/createBulkExamIDs`,
			(data) => {
				setProgressBarStatus(100);
				successToast(
					`Uploaded Successfully With Process ID : ${data.processInstanceId}`
				);
				setModel(true);
			},
			(data) => {
				failureToast(data['message']);
				setISUploading(false);
				setProgressBarStatus(0);
			},
			payload,
			'post'
		);
	};

	const redirectHandler = () => {
		props.history.push('/admin/examIDbulkSearch');
	};

	return (
		<>
			<Container className="mt-4">
				{showModel && processId ? (
					<CustomModel
						title={'value'}
						content={`Uploaded Successfully With Process ID : ${processId}`}
						redirectHandler={redirectHandler}
					/>
				) : null}
				<Card>
					<CardHeader
						className=" d-flex justify-content-between"
						style={{ position: 'relative' }}
					>
						<div
							className={style.backButton}
							onClick={() => !isUploading && window.history.back()}
						>
							<i class="fas fa-angle-double-left"></i>
						</div>
						<h3 className={`mb-0 ${style.uploadingHeading}`}>
							Exam ID Bulk Upload
						</h3>
						<div className="d-flex flex-column justify-content-between align-items-center">
							{/* <Button
                disabled={isUploading}
                color="info"
                size="md"
                type="button"
                className={"floatRight"}
                onClick={() =>
                  window.open(
                    `${pricingServiceBaseUrl}/downloadTemplate`,
                    "_blank"
                  )
                }
              >
                Download Template
              </Button> */}
							{/* <div className="feedback">
                <FormText className="text-info">
                  <span className={style.madatoryField}>*</span>Template will be
                  in .csv format
                </FormText>
              </div> */}
						</div>
					</CardHeader>

					<div className="form-row pl-4 pr-4 pb-4 mt-4">
						<Col className="mb-3" xs="12">
							<label className="form-control-label">
								{`Document`}
								<span className={style.madatoryField}>*</span>
							</label>
							<CustomDragAndDrop
								setFile={setFile}
								validFiles={['csv']}
								message={'Please select .csv file only'}
								name={'File'}
								handleFile={handleFile}
								isUploading={isUploading}
								progressBarStatus={progressBarStatus}
								changeIconColor={showModel}
							/>
							{/* <CustomDragAndDrop
                setFile={setFile}
                progressBarStatus={progressBarStatus}
                handleFile={handleFile}
                isUploading={isUploading}
                changeIconColor={showModel}
              /> */}
							<div className="feedback">
								<FormText className="text-info">
									<span className={style.madatoryField}>*</span>Only file in
									.csv format will be accepted
								</FormText>
							</div>
						</Col>
						<Col className="mb-3" xs="12">
							<label className="form-control-label">
								{`Description`} <span className={style.madatoryField}>*</span>
							</label>
							<Input
								disabled={isUploading}
								value={value.description}
								placeholder={`Description`}
								type="text"
								maxLength="50"
								onChange={(e) =>
									setValues({ ...value, description: e.target.value })
								}
								style={{ height: '39px' }}
							/>
						</Col>
					</div>
					<div className="p-4">
						<Button
							color={!file || isUploading ? 'secondary' : 'info'}
							size="md"
							type="button"
							disabled={!file || isUploading}
							className={'floatRight'}
							onClick={chkFile}
						>
							Submit
						</Button>{' '}
					</div>
				</Card>
			</Container>
		</>
	);
};

export default withRouter(UploadDocument);
