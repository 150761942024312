import React, { useState, useEffect, useContext } from 'react';
import { Container, Card, UncontrolledTooltip, Button, Row, Table, CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import TemplateViewModal from 'components/modals/templateModal';
import '../erpTemplate/index.css';
import { templateBaseurl } from 'services/http';
import { PermissionContext, RolePermissions } from 'appContext';
import { endpoint, pages } from '../../common/constant';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import { getUniqueKey } from '../../common/utils/methods/commonMethods/utilityMethod';
import { MasterHeaderAuto } from '../../common/commonComponents/masterHeader/masterHeader';
import { getPaginatedData } from '../../common/utils/methods/commonMethods/paginationMethod';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import { GetPagination } from '../../common/commonComponents/pagination/pagination';
import NotificationTemplateSearchForm from '../erpTemplate/SearchForm';
import CustomLoader from "../../common/commonComponents/Loader/loader";
import { Dialog } from '../../common/commonComponents';
import CustmCheckbox from '../../common/formFeilds/customCheckbox';

let page = 0;
let isNextCallPossible = true;
const emplySearchParams = {
    templateId: '',
    templateName: '',
    channel: '',
    templateCategoryId: ''
}
const emptyPagination = {
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null
}

const headerList = [
    { name: 'TEMPLATE ID' },
    { name: 'TEMPLATE CATEGORY' },
    { name: 'TEMPLATE Name' },
    { name: 'Template' },
    { name: 'Channels' }
]

// const listToBeRendered = [
//   'id',
//   'templateName',
//   'template',
//   'templateChannels',
//   'updatedOn',
//   'status'
// ]

const customeStyle = {
    position: 'absolute',
    backgroundColor: 'white',
    border: '.5px solid grey',
    fontSize: '10px',
    // display:'inline-block',
    maxWidth: '300px',
    width: 'max-content',
    color: 'grey',
    // top:'5px',
    left: '30%'
}

//{ color: 'black', maxWidth: `${maxWidth ? maxWidth : '100px'}`, width: 'auto', margin: 'auto' }

const ToolTip = ({ content, target, left, top }) => {
    return <UncontrolledTooltip delay={0} placement="top" target={target} hideArrow={true} className="custom-tooltip" style={{ ...customeStyle, left: left ? left : customeStyle.left, top: top ?? '0' }}>
        {content}
    </UncontrolledTooltip>
}

const TableRow = ({ rowObject, handleSelectedTemplate = () => { }, isAllSelected, selectedIds, deselectedIds, handleSelect = () => { }, disabled, categoryData=[] }) => {
    return <tr key={`${rowObject['templateName']}-${rowObject?.['id']}-${getUniqueKey()}`}>
        {/* <td key={`${rowObject['templateName']}-${rowObject?.['id']}-${getUniqueKey()}`} className="ellipse-text text-center">
            <CustmCheckbox onClick={() => { handleSelect(rowObject['id']) }} onChangeHandler={() => { }} defaultChecked={(selectedIds.indexOf(rowObject['id']) > -1 || isAllSelected) && (deselectedIds.indexOf(rowObject['id']) == -1) ? true : false} isDisabled={disabled} />
        </td> */}
        <td key={`${rowObject['templateName']}-${rowObject?.['id']}-${getUniqueKey()}`} className="ellipse-text text-center">{rowObject['id']}</td>
        <td key={`${rowObject['templateName']}-${rowObject?.['id']}-${getUniqueKey()}`} className="ellipse-text text-center">{categoryData.filter(i => i.value === rowObject['templateCategoryId'])?.[0]?.['label'] ?? ''}</td>

        <td key={`${rowObject['templateName']}-${rowObject?.['id']}-${getUniqueKey()}`} id={`name-${rowObject?.['id']}`} className="ellipse-text text-center">
            <span style={{ display: 'inline-block' }}>{rowObject['templateName']}
                <ToolTip content={rowObject['templateName']} target={`name-${rowObject?.['id']}`} maxWidth='250px' /></span>
        </td>
        <td key={`${rowObject['templateName']}-${rowObject?.['id']}-${getUniqueKey()}`} className="ellipse-text text-center"><span style={{ cursor: 'pointer' }} onClick={() => handleSelectedTemplate(rowObject)} >{rowObject['template']}</span></td>
        <td key={`${rowObject['templateName']}-${rowObject?.['id']}-${getUniqueKey()}`} className="ellipse-text text-center">
            {rowObject['templateChannels'].map(item => { return item.channelName }).join(', ')}
        </td>
        {/* <td key={`${rowObject['templateName']}-${rowObject?.['id']}-${getUniqueKey()}`} className="ellipse-text text-center">{moment(rowObject['updatedOn']).format('DD-MM-YYYY')}</td>
        <td key={`${rowObject['templateName']}-${rowObject?.['id']}-${getUniqueKey()}`} className="ellipse-text text-center">{rowObject['status']}</td> */}
    </tr>
}

function TemplateListingView(props) {


    const { selectedIds,
        setSelectedIds = () => { },
        isAllSelected,
        setAllSelected = () => { },
        deselectedIds,
        setDeselectedIs = () => { },
        isSaving,
        searchParams,
        setSearchParms = () => { },
        roleId,
        setHavePreviousRecord = () => {},
        havePreviousRecord
    } = props;

    const [loader, setLoader] = useState(false);
    const location = useLocation();
    const pageSize = 10;
    const [serverData, setServerData] = useState([]);
    const [tableData, setTableData] = useState([])
    let [pagination, setpagination] = useState(emptyPagination);
    const [viewTemplate, setViewTemplate] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const permissions = useContext(PermissionContext);
    const userPermissions = RolePermissions(permissions, pages['erpTemplate']['id']);

    const [categoryData, setCategoryData] = useState({ fetching: true, data: [] })

    const getTemplateHandler = async () => {
        try {
            page = 0;
            setLoader(true);
            const templateData = await fetchAllPostPromisedData(`${templateBaseurl}/getAllTemplatesSearch/${page}`, { ...searchParams, channel: searchParams.channel?.value ?? '', templateCategoryId: searchParams.templateCategoryId?.value ?? '', roleId: roleId })
            if (templateData?.code === 200) {
                setHavePreviousRecord({completed: true, haveData : templateData.data?.messageTemplateList?.length ? true : false})
                setServerData(templateData.data?.messageTemplateList);
                getPaginatedData(templateData.data?.messageTemplateList, pageSize, emptyPagination, setpagination, setLoader, setTableData)
            } else {
                setHavePreviousRecord({completed: true, haveData : false})
                setLoader(false);
            }
        } catch (e) {
            console.log('Error', e);
        }
    };

    const handleSelectedTemplate = (rowObject) => {
        setSelectedTemplate(rowObject)
        setViewTemplate(true)
    }

    const selectedTemplateloseHandler = () => {
        setSelectedTemplate(null)
        setViewTemplate(false)
    }

    const fetchNext = async () => {
        setLoader(true);
        page = page + 1;
        const templateData = await fetchAllPostPromisedData(`${templateBaseurl}/getAllTemplatesSearch/${page}`, { ...searchParams, channel: searchParams.channel?.value ?? '', templateCategoryId: searchParams.templateCategoryId?.value ?? '', roleId: roleId })
        if (templateData?.code === 200 && templateData?.data?.messageTemplateList?.length > 0) {
            const updatedData = [...serverData, ...templateData.data?.messageTemplateList]
            setServerData(updatedData);
            getPaginatedData(updatedData, pageSize, pagination, setpagination, setLoader, setTableData)
            isNextCallPossible = templateData?.data?.length == 50 ? true : false;
        } else {
            isNextCallPossible = false;
            setLoader(false);
        }
    }

    const resetSearch = () => {
        setSearchParms(emplySearchParams);
        page = 0;
        isNextCallPossible = true;
        // setUpdaterKey(new Date().getMilliseconds())
    }

    // useEffect(() => {
    //     setLoader(true);
    //     getTemplateHandler();
    // }, []);

    useEffect(() => {
        if (!searchParams.templateId && !searchParams.channel && !searchParams.templateName && !searchParams.templateCategoryId) {
            getTemplateHandler()
        }
    }, [searchParams])

    const handleSelectAll = (e) => {
        if (isAllSelected && !deselectedIds.length) {
            setAllSelected(false)
        } else {
            setSelectedIds([]);
            setDeselectedIs([]);
            setAllSelected(true)
        }
    }

    const handleSelect = (id) => {
        if (isAllSelected) {
            if (deselectedIds.indexOf(id) > -1) {
                const _temp = [...deselectedIds]
                _temp.splice(deselectedIds.indexOf(id), 1);
                setDeselectedIs([..._temp])
            } else {
                setDeselectedIs([...deselectedIds, id])
            }
        } else {
            if (selectedIds.indexOf(id) > -1) {
                const _temp = [...selectedIds]
                _temp.splice(selectedIds.indexOf(id), 1);
                setSelectedIds([..._temp])
            } else {
                setSelectedIds([...selectedIds, id])
            }
        }
    }

    const getAllCategory = async () => {
        const category = await fetchAllPromisedData(endpoint.allCategory, false);
        if (Array.isArray(category)) {
            const tempData = category?.map(c => { return { label: c.templateCategoryType, value: c.id } })
            setCategoryData({ fetching: false, data: tempData })
        } else {
            setCategoryData({ fetching: false, data: [] })
        }
    }

    useEffect(() => {
        getAllCategory();
    }, [])

    useEffect(()=>{
        setLoader(true);
        getTemplateHandler();
    },[roleId])

    return (
        <div className="erpTableTemplateContainer">
            <Container className="mt 1" fluid>
           { havePreviousRecord?.haveData ?<Row>
                    <div className="col">
                        <Card className="overflow-auto">
                            <CardHeader className="flex justify-between">
                                <h3 className="mb-0 floatLeft">Templates Filter</h3>
                            </CardHeader>
                            <NotificationTemplateSearchForm
                                searchParams={searchParams}
                                setSearchParms={setSearchParms}
                                userPermissions={userPermissions}
                                reset={resetSearch}
                                search={getTemplateHandler}
                                isFetching={loader}
                                categoryData={categoryData}
                            />
                        </Card>
                    </div>
                </Row> : null}
                <Row className="mt-n2">
                    {loader ? (
                        <div className="col">
                            <Card className=" w-100">
                                <div
                                    className="mx-auto text-center py-5 my-5 "
                                    style={{ height: '100vh' }}
                                >
                                    <CustomLoader apiLoader={loader} />
                                </div>
                            </Card>
                        </div>
                    ) : havePreviousRecord?.haveData ? (
                        <>

                            <div className="col">
                                <Card className="overflow-auto">
                                    <CardHeader className="border-0">
                                        <h3 className="mb-0 floatLeft">Search Results</h3>
                                    </CardHeader>
                                    <Table className="align-items-center tableLayout">
                                        <MasterHeaderAuto headerList={headerList}  />
                                        <tbody className="list" key={'table-message-template'}>
                                            {tableData.map((el) => {
                                                return <TableRow
                                                    rowObject={el}
                                                    key={getUniqueKey()}
                                                    handleSelectedTemplate={handleSelectedTemplate}
                                                    setSelectedTemplate={setSelectedTemplate}
                                                    selectedIds={selectedIds}
                                                    isAllSelected={isAllSelected}
                                                    deselectedIds={deselectedIds}
                                                    handleSelect={handleSelect}
                                                    disabled={isSaving}
                                                    categoryData={categoryData.data}
                                                />
                                                // </TableRow>
                                            })}
                                        </tbody>
                                    </Table>
                                    {pagination.totalPage > 1 ? (
                                        <GetPagination
                                            isSaveVisible={false}
                                            setclassArray={setTableData}
                                            pagination={pagination}
                                            setpagination={setpagination}
                                            pageSize={pageSize}
                                            state={serverData}
                                            isPaginationFromServer={true}
                                            fetchNext={fetchNext}
                                            isFetching={loader}
                                            currentPage={tableData}
                                            isNextCallPossible={isNextCallPossible}
                                        />
                                    ) : null}
                                </Card>
                            </div>
                        </>
                    ) : null}
                </Row>
            </Container>
            <Dialog
                isModalVisible={viewTemplate}
                title={selectedTemplate?.templateName}
                toggle={selectedTemplateloseHandler}
                size="lg"
                backdrop={true}
                isCloseButtonRequired={true}
            >
                {
                    selectedTemplate?.templateChannels?.filter(item => item?.channelName?.toLowerCase() === 'email')?.[0]?.isHtmlEnabled ?
                        <iframe srcDoc={selectedTemplate?.template} className="popup-preview messageTextArea" id="preview" frameBorder={0} height={500} />
                        :
                        <div
                            id="preview"
                            className="popup-preview messageTextArea"
                            dangerouslySetInnerHTML={{ __html: `<p>${selectedTemplate?.template?.replace(/\\n/g, '<br />')?.replace(/(?:\r\n|\r|\n)/g, '<br>')}</p>` }}
                        />
                }
            </Dialog>
        </div>
    );
}

export default TemplateListingView;
