import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import { Dialog } from "@mui/material";
import { ReactComponent as CrossIcon } from "../../../../../../assets/img/svg/CrossIcon.svg";
import { ReactComponent as Upward } from "../../../../../../assets/img/svg/upwardArrow.svg";
import { ReactComponent as Downward } from "../../../../../../assets/img/svg/downarrow.svg";

import ChangePlanWarning from "./ChangePlanWarning";
import PlanNameDropdownPopup from "./PlanNameDropdownPopup";
import AButton from "./../../../common/form-fields-mui/AButton";

const ChangeAcademicNameDialog = ({
  open = false,
  setOpen,
  formData,
  plansDropdown,
  formHandler,
}) => {
  const [newPlanName, setNewPlanName] = useState({ plan: "" });
  const [openPlanNamePopup, setOpenPlanNamePopup] = useState(false);
  const [isPlanNameChanged, setPlanNameChanged] = useState(false);
  const [isChangePlanClicked, setIsChangePlanClicked] = useState(false);

  const resetData = () => {
    setNewPlanName({ plan: "" });
    setOpenPlanNamePopup(false);
    setPlanNameChanged(false);
  };

  const handleClose = () => {
    setOpen(false);
    resetData();
    setIsChangePlanClicked(false);
  };

  const planChangeHandler = () => {
    setPlanNameChanged(true);
  };

  const changePlanHandler = () => {
    if (!isChangePlanClicked) {
      setIsChangePlanClicked(!isChangePlanClicked);
    } else {
      if (newPlanName?.plan?.label) setOpenPlanNamePopup(true);
    }
  };
  const onSaveAcademicPlan = () => {
    formHandler(newPlanName?.plan, "plan");
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"md"}>
      <div style={{ padding: "1.5rem" }}>
        <div className="d-flex justify-content-between">
          <span className="heading-4 color-dark"> Academic plan</span>
          <CrossIcon onClick={handleClose} />
        </div>
        <div className="mt-4">
          <div>
            <div className="d-flex align-items-center justify-content-between mb-4">
              <div className="d-flex flex-column">
                <span className="regular-bold">
                  {newPlanName?.plan?.label
                    ? "Old academic plan"
                    : "Applied academic plan"}
                </span>
                <span className="heading-4">{formData?.plan?.label}</span>
              </div>
              <div>
                <span
                  className="regular-large-bold color-dark change-plan-dropdown"
                  onClick={changePlanHandler}
                >
                  Change academic plan
                </span>
                <span className="ml-2">
                  {isChangePlanClicked ? <Upward /> : <Downward />}
                </span>
              </div>
            </div>
            {newPlanName?.plan?.label && <Divider></Divider>}
            {isPlanNameChanged && (
              <div className="d-flex flex-column mt-4 mb-4">
                <span className="regular-bold">
                  <span className="new-chip">New</span> applied academic plan
                </span>
                <span className="heading-4">{newPlanName.plan.label}</span>
              </div>
            )}

            {!isPlanNameChanged && isChangePlanClicked && (
              <ChangePlanWarning onAgree={() => setOpenPlanNamePopup(true)} />
            )}

            <PlanNameDropdownPopup
              open={openPlanNamePopup}
              setOpen={setOpenPlanNamePopup}
              plansDropdown={plansDropdown}
              form={newPlanName}
              formHandler={setNewPlanName}
              planChangeHandler={planChangeHandler}
            />

            {isPlanNameChanged && (
              <AButton variant="primary_filled" onClick={onSaveAcademicPlan}>
                Save academic plan
              </AButton>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ChangeAcademicNameDialog;
