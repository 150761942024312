import React, { useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import { ReactComponent as CrossIcon } from '../../../../../../assets/img/svg/CrossIcon.svg';
import AAutoComplete from '../../../../manage/common/form-fields-mui/AAutoComplete';
import CommonInput from '../../../../manage/common/formFeilds/input/commonInput';
import {
  useGetBatchListByTermBUCourseId,
} from '../../issuanceIDcard/customhooks';
import AButton from '../../../../manage/common/form-fields-mui/AButton';
import { useGetCourseIdsForBUAndTerm, useGetDispatchIdsForBuTermAndCourseId } from '../customhooks';
import { fetchAllPostPromisedData, updateRequest } from '../../../../manage/common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../../../manage/common/constant';
import { getDropdownIdsFromSelectArray } from '../../../../manage/common/utils/methods/commonMethods/utilityMethod';
import { failureToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import SelectMaterialElasticSearch from '../mastersAndMapping/onBoardingMaterialIdSetup/SelectMaterialElasticSearch';
import ALoader from 'views/pages/manage/common/a-loader';
import moment from 'moment';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx';


export const titleStyles = {
  fontSize: '20px',
  fontWeight: 600,
  color: '#000',
  // minWidth: '600px'
};

export const labelStyles = {
  fontSize: '14px',
  fontWeight: 600,
  color: '#262626',
};

export const selectStyles = {
  control: { height: '40px', width: '150px', borderRadius: '4px' },
};
const DownloadReportsDialog = ({ dropdown, open, setOpen, forDlp = false }) => {
  const EMPTY_FORM = (defaultFromDate = '', defaultToDate = '') => ({
    reportType: '',
    fromDate: defaultFromDate,
    toDate: defaultToDate,
    businessArea: forDlp
      ? dropdown?.dlpActiveBusinessArea?.find(bu => bu?.label === 'DW005')
      : '',
    term: [],
    courseId: [],
    primaryBatchId: [],
    materials: [],
    dispatchId: [],
  });

  const reportTypes = [
    { label: 'Total Student Reports', value: 'TOTAL_STUDENT_REPORT' },
    { label: 'Stock Issuance Status Report', value: 'STOCK_ISSUANCE_STATUS' },
    { label: 'Stock Issue Report', value: 'STOCK_ISSUE' },
    {
      label: `${forDlp ? 'DLP' : 'RCC'} Stock Return Report`,
      value: 'STOCK_RETURN',
    },
    { label: 'Branch Consumption Report', value: 'STOCK_BRANCH_CONSUMPTION' },
    {
      label: 'Branch Consumption Return Report',
      value: 'STOCK_BRANCH_CONSUMPTION_RETURN',
    },
    forDlp ? { label: 'DLP Ageing Report', value: 'AGEING_REPORT' } : '',
    forDlp ? { label: 'DLP Dispatch Report', value: 'DISPATCH_REPORT' } : '',
  ];

  const [form, setForm] = useState(EMPTY_FORM());
  const [loading, setLoading] = useState(false);

  const formHandler = (value, key) => {
    setForm(prev => ({ ...prev, [key]: value }));
  };

  const [batchDropdown, batchesLoading] = useGetBatchListByTermBUCourseId(
    ...(!forDlp
      ? [
          dropdown?.businessAreas?.length === 1
            ? dropdown?.businessAreas[0]
            : form?.businessArea,
          form?.term,
          form?.courseId,
          true,
          '',
        ]
      : [])
  );

  const [courseIdDropdown, courseIdDropdownLoading] =
    useGetCourseIdsForBUAndTerm({
      businessArea: form?.businessArea,
      term: form?.term,
    });

    const [dispatchIdDropdown, dispatchIdDropdownLoading] =
    useGetDispatchIdsForBuTermAndCourseId({
        businessArea: form?.businessArea,
        term: form?.term,
        courseId: form?.courseId,
    });

  const resetHandler = () => {
    setForm(EMPTY_FORM());
  };

  const downloadReport = async () => {
    const reqObj = updateRequest({
      reportType: `${forDlp ? 'DLP_' : isMaterialsRequired ? 'RCC_' : ''}${
        form?.reportType?.value
      }`,
      businessArea: form?.businessArea?.value,
      term: form?.term || [],
      courseId: Array.isArray(form?.courseId) ? form?.courseId : [form?.courseId],
      primaryBatchId: getDropdownIdsFromSelectArray(form?.primaryBatchId),
      materialIds: getDropdownIdsFromSelectArray(form?.materials),
      fromDate: moment(form?.fromDate).startOf('day'),
      toDate: moment(form?.toDate).endOf('day'),
      dispatchIds: form?.dispatchId
    });
    setLoading(true);
    try {
      const resp = await fetchAllPostPromisedData(
        `${endpoint.downloadStockReport}`,
        reqObj
      );
      if (resp?.code === 200) {
        const templateUrl = await fetchAllPostPromisedData(
          `${endpoint.fetchS3Url.getDownloadUrl}?key=${resp?.data?.key}`,
          {}
        );
        if (templateUrl?.code === 200 && templateUrl?.data) {
          window.open(`${templateUrl?.data?.url}`);
          setOpen(false);
          resetHandler();
        } else {
          failureToast('Something went wrong please try after sometime.');
        }
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };
  const reportNotSelected = !form?.reportType?.value;
  const isDateRangeRequired = [
    'STOCK_ISSUE',
    'STOCK_RETURN',
    'STOCK_BRANCH_CONSUMPTION_RETURN',
    'STOCK_BRANCH_CONSUMPTION',
    'DISPATCH_REPORT'
  ].includes(form?.reportType?.value);
  const isTermCourseIdBatchRequired = ![
    'STOCK_BRANCH_CONSUMPTION_RETURN',
    'STOCK_BRANCH_CONSUMPTION',
  ].includes(form?.reportType?.value);
  const isBatchMandatory = ['STOCK_ISSUE', 'STOCK_ISSUANCE_STATUS']?.includes(
    form?.reportType?.value
  );
  const isMaterialsRequired =
    form?.reportType?.value !== 'TOTAL_STUDENT_REPORT';
  const isPaymentDateRequired = form?.reportType?.value == 'AGEING_REPORT';
  const isDispatchIdRequired =  form?.reportType?.value == 'DISPATCH_REPORT';

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: { maxWidth: "unset", borderRadius: "12px", padding: "20px" },
      }}
      // className="reports-dialog"
    >
      {loading && <ALoader />}
      <div
        style={titleStyles}
        className="d-flex justify-content-between align-items-center"
      >
        <div>Select report details</div>
        <IconButtonWrapper
          onClick={() => {
            setOpen(false);
            resetHandler();
          }}
        >
          <CrossIcon />
        </IconButtonWrapper>
      </div>

      <div>
        <AAutoComplete
          label="Select report type*"
          placeholder="Report Name"
          items={reportTypes}
          labelStyle={labelStyles}
          isMulti={false}
          currentValue={form?.reportType}
          handleChange={(val) => {
            val?.value == "AGEING_REPORT"
              ? setForm({
                  ...EMPTY_FORM(
                    moment().subtract(7, "day"),
                    moment().endOf("day")
                  ),
                  reportType: val,
                })
              : val?.value == "DISPATCH_REPORT"
              ? setForm({
                  ...EMPTY_FORM(
                    moment().subtract(30, "day"),
                    moment().endOf("day")
                  ),
                  reportType: val,
                })
              : setForm({ ...EMPTY_FORM(), reportType: val });
          }}
          style={{ control: { height: "40px", borderRadius: "4px" } }}
        />
      </div>

      <div
        className="d-flex justify-content-between my-3"
        style={{ gap: "15px" }}
      >
        {isDateRangeRequired && (
          <>
            <div style={{ width: "150px" }}>
              <label style={labelStyles}>From*</label>
              <CommonInput
                type="datePicker"
                placeHolder="From"
                onChange={(val) => {
                  formHandler("", "toDate");
                  formHandler(val, "fromDate");
                }}
                defaultValue={form?.fromDate}
                maxDate={moment().startOf("day")}
                /*minDate={
                  isDispatchIdRequired ? moment().subtract(30, "day") : ""
                }*/
                // label={"From"}
              />
            </div>

            <div style={{ width: "150px" }}>
              <label style={labelStyles}>To*</label>
              <CommonInput
                type="datePicker"
                placeHolder="To"
                onChange={(val) => formHandler(val, "toDate")}
                defaultValue={form?.toDate}
                minDate={new moment(form?.fromDate)}
                maxDate={
                  form?.fromDate
                    ? moment.min([
                        new moment(form?.fromDate)?.add(1, "month"),
                        moment().startOf("day"),
                      ])
                    : moment().startOf("day")
                }
              />
            </div>
          </>
        )}
        {forDlp && isPaymentDateRequired && (
          <>
            <div style={{ width: "150px" }}>
              <label style={labelStyles}>Payment date from*</label>
              <CommonInput
                type="datePicker"
                placeHolder="From"
                onChange={(val) => {
                  formHandler(val, "fromDate");
                  formHandler("", "toDate");
                }}
                defaultValue={form?.fromDate}
                maxDate={moment().startOf("day")}
                // label={"From"}
              />
            </div>
            <div style={{ width: "150px" }}>
              <label style={labelStyles}>Payment date to*</label>
              <CommonInput
                type="datePicker"
                placeHolder="To"
                onChange={(val) => formHandler(val, "toDate")}
                defaultValue={form?.toDate}
                minDate={new moment(form?.fromDate)}
                maxDate={moment().endOf("day")}
              />
            </div>
          </>
        )}

        <AAutoComplete
          label="Business Area*"
          items={
            forDlp ? dropdown?.dlpActiveBusinessArea : dropdown?.businessAreas
          }
          labelStyle={labelStyles}
          isMulti={false}
          currentValue={form?.businessArea}
          handleChange={(val) => {
            formHandler([], "courseId");
            formHandler([], "primaryBatchId");
            formHandler(val, "businessArea");
            formHandler([], "dispatchId");
          }}
          style={selectStyles}
        />

        {(isTermCourseIdBatchRequired || reportNotSelected) && (
          <>
            <AAutoComplete
              label="Term*"
              items={forDlp ? dropdown?.term : dropdown?.terms}
              labelStyle={labelStyles}
              // isMulti={(!forDlp && form?.reportType?.value==='STOCK_ISSUE')}
              isMulti={form?.reportType?.value==='DISPATCH_REPORT' ? true : !isDispatchIdRequired}
              isShowCount
              currentValue={form?.term}
              handleChange={(val) => {
                formHandler([], "courseId");
                formHandler([], "primaryBatchId");
                formHandler([], "dispatchId");
                formHandler(Array.isArray(val) ? val : [val], "term");
              }}
              style={selectStyles}
            />

            {!isPaymentDateRequired && (
              <AAutoComplete
                label="Course Id"
                items={courseIdDropdown}
                labelStyle={labelStyles}
                isMulti={!isDispatchIdRequired}
                currentValue={form?.courseId}
                handleChange={(val) => {
                  formHandler([], "primaryBatchId");
                  formHandler(val, "courseId");
                  formHandler([], "dispatchId");
                }}
                style={selectStyles}
                isShowCount={true}
                isLoading={courseIdDropdownLoading}
              />
            )}

            {!forDlp &&
              (form?.reportType?.value === "STOCK_ISSUANCE_STATUS" ? (
                <AAutoComplete
                  label={"Batch*"}
                  labelStyle={labelStyles}
                  isMulti={false}
                  items={batchDropdown}
                  handleChange={(val) => {
                    // if (isBatchMandatory) {
                    //     if (val?.length <= 10) formHandler(val, "primaryBatchId");
                    //     else failureToast("Max 10 batches can be selected for this report type!!")
                    // } else {
                    formHandler([val], "primaryBatchId");
                  }}
                  style={selectStyles}
                  isShowCount={true}
                  currentValue={form?.primaryBatchId}
                  isLoading={batchesLoading}
                />
              ) : (
                <AAutoComplete
                  label={"Batch"}
                  labelStyle={labelStyles}
                  isMulti={true}
                  items={batchDropdown}
                  handleChange={(val) => {
                    // if (isBatchMandatory) {
                    //     if (val?.length <= 10) formHandler(val, "primaryBatchId");
                    //     else failureToast("Max 10 batches can be selected for this report type!!")
                    // } else {
                    formHandler(val, "primaryBatchId");
                  }}
                  style={selectStyles}
                  isShowCount={true}
                  currentValue={form?.primaryBatchId}
                  isLoading={batchesLoading}
                />
              ))}
          </>
        )}

        {!reportNotSelected &&
          isMaterialsRequired &&
          !isPaymentDateRequired &&
          !isDispatchIdRequired &&
          (form?.reportType?.value === "STOCK_ISSUANCE_STATUS" ? (
            <SelectMaterialElasticSearch
              label={"Materials*"}
              labelStyle={labelStyles}
              currentValue={form?.materials}
              handleChange={(value) => {
                formHandler(value, "materials");
              }}
              isMulti={true}
              style={selectStyles}
              dropdownMappingParams={[
                "materialIdAndDescription",
                "materialId",
                "materialDescription",
              ]}
              isDlpSearch={forDlp}
            />
          ) : (
            <SelectMaterialElasticSearch
              label={"Materials"}
              labelStyle={labelStyles}
              currentValue={form?.materials}
              handleChange={(value) => {
                formHandler(value, "materials");
              }}
              isMulti={true}
              style={selectStyles}
              dropdownMappingParams={[
                "materialIdAndDescription",
                "materialId",
                "materialDescription",
              ]}
              isDlpSearch={forDlp}
            />
          ))}
        {isDispatchIdRequired && (
          <AAutoComplete
            label="Dispatch ID"
            currentValue={form?.dispatchId}
            style={selectStyles}
            labelStyle={labelStyles}
            items={dispatchIdDropdown}
            handleChange={(value) => {
              formHandler(value, "dispatchId");
            }}
            isMulti={true}
            isShowCount={true}
            isLoading={dispatchIdDropdownLoading}
          />
        )}
      </div>
      <div>
        <AButton
          children={"Download report"}
          variant="primary_filled"
          className="no-ml-btn"
          disabled={
            reportNotSelected ||
            !form?.businessArea ||
            ((isTermCourseIdBatchRequired ||
              isPaymentDateRequired ||
              isDispatchIdRequired) &&
              ((Array.isArray(form?.term)&&!form?.term.length)||(!Array.isArray(form?.term)&&!form?.term))) ||
            // !forDlp && isBatchMandatory && !form?.primaryBatchId?.length ||
            ((isDateRangeRequired || (isPaymentDateRequired && forDlp)) &&
              (!form?.toDate || !form?.fromDate)) ||
            // isMaterialsRequired && (forDlp && !form?.materials?.length)
            (form?.reportType?.value === "STOCK_ISSUANCE_STATUS" &&
              (!form?.materials?.length ||
                (!forDlp && !form?.primaryBatchId?.length)))
          }
          onClick={downloadReport}
        />

        <AButton children={"Reset"} variant="outline" onClick={resetHandler} />
      </div>
    </Dialog>
  );
};

export default DownloadReportsDialog;
