import React, { useEffect, useState } from 'react';
import { fetchAllPostPromisedData } from '../../../../common/utils/methods/commonMethods/utilityMethod.js';
import { endpoint } from '../../../../common/constant';
import { failureToast, successToast } from '../../../../common/utils/methods/toasterFunctions/function.js';
import { Accordion } from '../../../../common/commonComponents';

import { Box } from '@mui/system';
import CustomButton from '../../../../../../../components/CustomButton/index.js';
import { Row, Col } from 'reactstrap';
import { AiFillWarning } from 'react-icons/ai';
import { getCookie } from '../../../../common/utils/methods/commonMethods/utilityMethod.js';
import { parse2Digit } from '../OtherChargeList/index.js';


const Collections = ({
  userPermissions,
  applicationId,
  isOpen,
  setIsOpen,
  otherCharges,
  psid,
  selectionModel,
  userDetails,
  setOtherCharges,
  setSelectionModel
}) => {

  const [isPending, setPending] = useState(false);
  const [totalAmt, setTotalAmt] = useState(0);
  const [waiveOff, setWaiveOff] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleCollect = async (rowId, status) => {
    const request = {
      "approvalStatus": status,
      approverUserId: getCookie('userId'),
      "approverName": userDetails ? `${userDetails.firstName} ${userDetails.lastName}` : ""
    }

    setLoading(true);

    const response = await fetchAllPostPromisedData(`${endpoint.otherChargesConcession.updateStatus}/${rowId}`, request, 'put');
    if (response?.code === 200) {
      setOtherCharges(prevState => prevState.map(row =>
        row.id === rowId
          ? { ...row, ...request, approvalStatus: status }
          : row
      ));
      setLoading(false);
      setSelectionModel([]);
      successToast(response?.message)

    } else {
      // setSaving(false)
      setLoading(false);
      failureToast(response?.message ?? 'Not able to process request , please try after sometime.')
    }
  }

  const getTotalAmount = () => {
    let amt = 0;
    otherCharges.map(i => {
      if (selectionModel.indexOf(i.id) > -1) {
        amt += i.netPayableAmount
      }
    })
    setTotalAmt(amt)
  }

  const getWaiveOff = () => {
    let amt = 0;
    otherCharges.map(i => {
      if (selectionModel.indexOf(i.id) > -1 && i.approvalStatus !== 'REJECTED') {
        amt += i.waveOffAmount;
        if (i.approvalStatus === 'Pending' || i.approvalStatus === 'PENDING') {
          setPending(true)
        }
      }
    })
    setWaiveOff(amt)
  }

  useEffect(() => {
    if (selectionModel?.length) {
      setPending(false)
      getTotalAmount();
      getWaiveOff();
    } else {
      setTotalAmt(0)
      setWaiveOff(0)
      setPending(false)
    }
  }, [selectionModel, otherCharges])


  return (
    <Accordion
      isFormOpen={isOpen.waive_off}
      title={selectionModel?.length ? `Collection | ${selectionModel?.length} of ${otherCharges?.length} course type` : `Collection`}
      key={'waive_off'}
      isEditFormOpen={(open) => {
        open.tab4 === false ?
          setIsOpen({ ...open, tab4: true }) :
          setIsOpen({
            detailsTab: false,
            tab2: false,
            waive_off: false,
            tab4: false
          })
      }}
    >

      <Box sx={{ width: '100%', height: 'auto', overflow: "auto", background: '#ffffff', padding: '20px 16px', borderRadius: '16px' }}>
        <Row>
          <Col md={3} className="d-flex flex-column">
            <span className='label-text label-color'>Net payable amount</span>
            <span className='egular-large-bold color-dark'>{`₹ ${ parse2Digit(totalAmt)}`}</span>
          </Col>
          <Col md={4} className="d-flex flex-column">
            <span className='label-text label-color'>Waive off</span>
            <span className='egular-large-bold color-dark'>{`₹ ${ parse2Digit(waiveOff)}`}&nbsp;&nbsp;{isPending ? <span style={{ background: '#8270DB', color: '#262626', padding: '4px 11px', borderRadius: '8px' }}>

              Pending For Approval
            </span> : null}</span>
          </Col>
          <Col md={3} className="d-flex flex-column">
            <span className='label-text label-color'>After concession</span>
            <span className='egular-large-bold color-dark'>{`₹ ${ parse2Digit(Number(totalAmt) - Number(waiveOff))}`}</span>
          </Col>
          <Col md={2} className="d-flex flex-column">
            <CustomButton content="Collect now" permissionSet={userPermissions} permissionType={'C,U'} onClick={() => handleCollect(selectionModel[0], 'PAYMENT_IN_PROGRESS')} disabled={isPending || !selectionModel?.length} />
          </Col>
        </Row>
        {isPending ? <div style={{ backgroundColor: '#D29822', width: '100%', borderRadius: '10px', color: '#fff' }} className="d-flex justify-content-center align-items-center p-3 mt-3">
          <AiFillWarning /> <span className='pl-2'>Waive amount approval is pending. You can collect the amount after approval response is closed</span>
        </div> : null}
      </Box>
    </Accordion>
  )

}

export default Collections;
