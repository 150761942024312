const messages = {
	dataNotFound: 'No matching values found.',
	unsavedChanges:
		'This page contains unsaved data. Do you want to continue without saving the changes?',
	closeMessage: 'Are you sure you want to leave this page?',
};

export const data = {
	heading: 'Item Type Sequence Search',
	messages: messages,
};
