import React from 'react';
import { withRouter } from 'react-router-dom';
import {
	Card,
	CardHeader,
	Input,
	Container,
	Button,
	Table,
	Row,
} from 'reactstrap';
import { useState } from 'react';
import Select2 from 'react-select2-wrapper';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import {
	TDROW,
	MasterHeader,
} from '../../common/commonComponents/masterRow/masterRow';
import {
	masterServiceBaseUrl,
	putpost,
	putpost2,
	deleteAPI,
} from 'services/http';
import moment from 'moment';
import IsCoreMaster from '../../common/commonComponents/coreMaster';
import CustomButton from 'components/CustomButton';

var _ = require('lodash');

const CompanyCodeTable = (props) => {
	const { history, userPermissions } = props;
	const [isSaveVisible, setisSaveVisible] = useState(false);
	const [tableDataKey, settableDataKey] = useState('tableDataKey');
	const addNewClass = () => {
		props.setclassArray([
			{
				editMode: true,
				__status: '__new',
				companyCodeKey: null,
				status: 'INACTIVE',
				companyCodeDesc: null,
				companyCodeDispValue: null,
				effectiveDate: null,
				updatedDate: null,
				groupCodeId: null,
			},
			...props.classArray,
		]);
	};
	const saveAll = (cb) => {
		var _tempArray = props.classArray;
		var isValid = true;
		_tempArray.forEach((el, index) => {
			if (el['editMode']) {
				if (el['companyCodeKey'] === null || !el['companyCodeKey'].trim()) {
					isValid = false;
					failureToast('Please enter the value of Country Code Key');
					return;
				} else if (
					el['companyCodeDesc'] === null ||
					!el['companyCodeDesc'].trim()
				) {
					isValid = false;
					failureToast('Please enter the value of Description');
					return;
				} else if (
					el['companyCodeDispValue'] === null ||
					!el['companyCodeDispValue'].trim()
				) {
					isValid = false;
					failureToast('Please enter the value of Display Value');
					return;
				} else if (el['groupCodeId'] === null || !el['groupCodeId']) {
					isValid = false;
					failureToast('Please select the value of Group');
					return;
				}
				if (!isValid) {
					return;
				}
				if (_tempArray[index]['__status'] == '__new') {
					putpost(
						masterServiceBaseUrl + '/companyCode/createCompanyCode',
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							props.fetchClasses(
								masterServiceBaseUrl + '/companyCode/getAllCompanyCode'
							);
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'post'
					);
				} else {
					putpost(
						masterServiceBaseUrl +
							'/companyCode/updateCompanyCode/' +
							_tempArray[index].id,
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							delete _tempArray[index]['editMode'];
							props.setclassArray(_tempArray);
							settableDataKey(new Date().getMilliseconds());
							// props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'put'
					);
				}
			} else {
			}
		});
	};
	const newRow = () => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				addNewClass();
				setisSaveVisible(true);
				// settableDataKey(new Date().getMilliseconds())
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			props.setclassArray(_tempArray);
			setisSaveVisible(false);
			cb();
		}
	};
	const editRow = (index) => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				var _tempArray = props.classArray;
				_tempArray[index]['editMode'] = true;
				props.setclassArray(_tempArray);
				setisSaveVisible(true);
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			cb();
		}
	};
	const deleteRow = (index) => {
		if (props.classArray[index]['__status'] == '__new') {
			let n = props.classArray;
			n.splice(index, 1);
			props.setclassArray(n);
			setisSaveVisible(false);
			settableDataKey(new Date().getMilliseconds());
		} else {
			if (
				window.confirm('Are you sure you want to delete this Company Code?')
			) {
				putpost2(
					masterServiceBaseUrl +
						'/companyCode/deleteCompanyCode/' +
						props.classArray[index].id,
					(data) => {
						successToast(data['message']);
						var _tempArray = props.classArray;
						if (_tempArray[index]['editMode']) {
							setisSaveVisible(false);
						}
						_tempArray.splice(index, 1);
						props.setclassArray(_tempArray);
						settableDataKey(new Date().getMilliseconds());
					},
					(data) => {
						failureToast(data['message']);
					},
					'Delete'
				);
			}
		}
	};
	const updateKey = (index, value, key) => {
		var _tempArray = props.classArray;
		_tempArray[index][key] = value;
		props.setclassArray(_tempArray);
	};

	return (
		<>
			<Container className="mt-3" fluid>
				<Row>
					<div className="col">
						<Card>
							<CardHeader className="border-0">
								<h3 className="mb-0 floatLeft">Master - Company Code</h3>
								<IsCoreMaster
									master={'companyCode'}
									masterDisplayValue={'Company Code'}
								/>
								<CustomButton
									disabled={isSaveVisible}
									className={
										'floatRight mx-1' +
										(isSaveVisible ? ' btn-dark cursor-gray' : null)
									}
									content={'New Company Code'}
									permissionType={'C'}
									icon={true}
									onClick={() => {
										history.push(`/admin/companyCode/New`);
									}}
									permissionSet={userPermissions}
								/>
							</CardHeader>
							<div id="questionTable" className="table-responsive">
								<Table className="align-items-center table-flush tableLayout">
									<MasterHeader
										type={'CompanyCode'}
										isSaveVisible={isSaveVisible}
										permissionSet={userPermissions}
									/>
									<tbody className="list" key={tableDataKey}>
										{props.classArray
											? props.classArray.map((el, index) =>
													!el['editMode'] ? (
														<TDROW
															type={'companyCode'}
															index={index}
															el={el}
															editRow={editRow}
															deleteRow={deleteRow}
															groupCodeList={props.data}
															history={history}
															isSaveVisible={isSaveVisible}
															permissionSet={userPermissions}
														/>
													) : (
														<tr key={index + '-class'}>
															<td className="text-center p-2 mx-1">
																<Select2
																	defaultValue={el['groupCodeId']}
																	data={props.data}
																	onChange={(e) => {
																		updateKey(
																			index,
																			Number(e.target.value),
																			'groupCodeId'
																		);
																	}}
																	options={{ placeholder: 'Select Group' }}
																	className="tdSelect"
																	data-testid="groupCodeId"
																/>
															</td>
															<td className="text-center p-2 mx-1">
																<Input
																	maxLength="50"
																	defaultValue={el['companyCodeKey']}
																	onChange={(e) => {
																		updateKey(
																			index,
																			e.target.value,
																			'companyCodeKey'
																		);
																	}}
																	placeholder="Example:1"
																	className="tdInput"
																	data-testid="companyCodeKey"
																/>
															</td>
															{/* <td className="text-center p-2 mx-1"><Input disabled={true} defaultValue={el["__status"] == "__new" ? null : moment(el['effectiveDate']).format('DD-MM-YYYY')} placeholder="Example:01-01-2020" className="tdInput" /></td> */}
															<td className="text-center p-2 mx-1">
																<Input
																	disabled={true}
																	defaultValue={
																		el['__status'] == '__new'
																			? null
																			: moment(el['updatedDate']).format(
																					'DD-MM-YYYY'
																			  )
																	}
																	placeholder="Example:01-01-2020"
																	className="tdInput"
																	data-testid="updatedDate"
																/>
															</td>
															<td className="text-center p-2 mx-1">
																<Input
																	maxLength="50"
																	defaultValue={el['companyCodeDesc']}
																	onChange={(e) => {
																		updateKey(
																			index,
																			e.target.value,
																			'companyCodeDesc'
																		);
																	}}
																	placeholder="Description"
																	className="tdInput"
																	data-testid="companyCodeDesc"
																/>
															</td>
															<td className="text-center p-2 mx-1">
																<Input
																	maxLength="50"
																	defaultValue={el['companyCodeDispValue']}
																	onChange={(e) => {
																		updateKey(
																			index,
																			e.target.value,
																			'companyCodeDispValue'
																		);
																	}}
																	placeholder="Display Value"
																	className="tdInput"
																	data-testid="companyCodeDispValue"
																/>
															</td>

															<td className="text-center p-2 mx-1">
																<label className="custom-toggle mx-auto ml-2">
																	<input
																		disabled={
																			isSaveVisible &&
																			props.classArray[index][
																				'companyCodeKey'
																			] === null
																				? true
																				: false
																		}
																		checked={
																			el.status == 'ACTIVE' ? true : false
																		}
																		type="checkbox"
																		id={el.id}
																		onChange={(e) => {
																			settableDataKey(
																				new Date().getMilliseconds()
																			);
																			let n = 'ACTIVE';
																			if (!e.target.checked) {
																				n = 'INACTIVE';
																			}
																			updateKey(index, n, 'status');
																		}}
																		data-testid="companyCodeStatus"
																	/>
																	<span
																		style={{ width: '72px' }}
																		className="custom-toggle-slider rounded-circle activeToggle"
																		data-label-off="Inactive"
																		data-label-on="Active"
																	/>
																</label>
															</td>
															<td
																className="text-center"
																colSpan={2}
																data-testid="saveCompanyCode"
															>
																{isSaveVisible ? (
																	<>
																		<CustomButton
																			content={'Save'}
																			permissionType={'C,U'}
																			forTable={true}
																			onClick={() => {
																				saveAll();
																			}}
																			permissionSet={userPermissions}
																		/>
																		<CustomButton
																			permissionType={'cancel'}
																			icon={true}
																			forTable={true}
																			onClick={() => {
																				let n = props.classArray;
																				if (n[index]['__status'] == '__new') {
																					n.splice(index, 1);
																				} else {
																					// n[index]['editMode']=false
																					props.fetchClasses(
																						masterServiceBaseUrl +
																							'/companyCode/getAllCompanyCode'
																					);
																				}
																				props.setclassArray(n);
																				setisSaveVisible(false);
																			}}
																			permissionSet={userPermissions}
																		/>
																	</>
																) : (
																	<CustomButton
																		permissionType={'D'}
																		icon={true}
																		forTable={true}
																		onClick={() => deleteRow(index)}
																		permissionSet={userPermissions}
																	/>
																)}
															</td>
														</tr>
													)
											  )
											: null}
									</tbody>
								</Table>
							</div>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};
export default withRouter(CompanyCodeTable);
