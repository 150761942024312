import React, { useState, useEffect } from "react";
import Select from 'react-select';
import moment from "moment";
import DatePicker from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';
import range from "lodash/range";
import "react-datepicker/dist/react-datepicker.css";
import { customTheme } from "../../formFeilds/input/commonInput";
import styled from 'styled-components';
import { CalendarIcon } from "../../../../../../assets/img/svg/calendarIcon";
import { Checkbox, FormControlLabel, InputLabel } from '@mui/material';
import './index.scss'


const checkboxOptions = {
    'DOES_NOT_REPEAT' : 'Does not repeat',
    'WEEKEND' : 'Weekend',
    'WEEKDAYS' : 'Weekdays',
    'CUSTOM' : 'Custom'
}

const CustomizedDatePicker = styled.div`
    .react-datepicker {
        box-shadow: 0px 4px 32px rgba(61, 70, 112, 0.08);
        padding:20px;
        .react-datepicker__month-container{
            width: 370px;
            background: #F5F5F5;
            border : none !important;
            .react-datepicker__header {
                .react-datepicker__day-names{
                    background: #F5F5F5;
                    padding: 0px 20px !important;
                    border-bottom: 0px solid white !important;
                    .react-datepicker__day-name{
                        color : #757575 !important;
                        font-weight: 400;
                        font-size: 12px;
                        padding: 5px;
                    }
                }
            }
            .react-datepicker__month{
                padding: 20px;
                .react-datepicker__week{
                    .react-datepicker__day{
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        padding: 6px;
                        width:50px;
                        color:#0F0F0F
                    }
                    .react-datepicker__day--disabled {
                        color:#8798ad;
                    }
                    .react-datepicker__day--today {
                        background : #ffffff;
                        border-radius: 100%;
                    }
                }
            }
        }
    } 
`
const CustomInput = styled.input`
    color:red !important;
`

const dateFormat = (e) => {
    try {
        const value = e.target.value;
        const inputValue = value ? value.toString() : "";
        const number = inputValue.replace(/[^0-9]/g, "");
        if (number.length === 4) {
            const numberFormat = number.replace(/(\d{3})/, "($1) ");
            // form.setFieldsValue({phone: numberFormat});
        } else if (number.length === 7) {
            const numberFormat = number.replace(/(\d{3})(\d{3})/, "($1) $2-");
            // form.setFieldsValue({phone: numberFormat});
        } else if (number.length === 10) {
            const numberFormat = number.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
            // form.setFieldsValue({phone: numberFormat});
        }
    } catch (error) {
        console.log("error", error);
    }
}


const CustomDatePickerV3 = (props) => {
    const {
        index,
        value = '',
        keyName = 'datePicker' + moment().format('DD-MM-YYYY: hh:mm:ss'),
        handler = () => { },
        minDate = moment('01-01-1950').toDate(),
        maxDate = moment().add(78, 'years').toDate(),
        disabled = false,
        dataTestId = 'TestDatePicker',
        placeHolder = 'Example 01-Jan-2022',
        rangeFrom = 1950,
        rangeTo = 2099,
        action = 'new',
        isStartDate,
        isEndDate,
        endDate,
        startDate,
        label,
        style,
        headerOptions,
        forFilter=false,
        withportal = false
    } = props;

    const [selectedOption,setSelectedOption] = useState('')

    const datePickerSelect = {
        menu: (styles) => {
            return {
                ...styles,
                width: '130px !important',
                ...style?.menu
            }
        },

        indicatorContainer: (styles) => {
            return {
                ...styles,
                padding: '0px 8px 0px 8px'
            }
        },
        indicatorSeparator: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: 'none'
            }
        },
        valueContainer: (defaultStyles) => {
            return {
                ...defaultStyles,
                fontSize: '14px',
                ...style?.valueContainer
            }
        },
        dropdownIndicator: (defaultStyles) => {
            return {
                ...defaultStyles,
                color: '#515151 !important',
            }

        },
        control: (defaultStyles, state) => {
            return {
                ...defaultStyles,
                borderColor: '#dee2e6',
                height: '40px',
                "&:hover": {
                    borderColor: "#f0f0f0",
                    // color: '#00AEEF'
                },
                border: 0,
                borderRadius: '0',
                // borderRight:'1px solid #f0f0f0',
                boxShadow: '0',
                width: '115px',
                ...style?.control
            }
        },
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                color: '#515151',
            }
        },
        svg: (defaultStyles) => {
            return {
                ...defaultStyles,
                color: 'red'
            }
        },
        container: (defaultStyles) => {
            return {
                ...defaultStyles,
                width: '105px !important',
                minWidth: '105px !important',
                ...style?.container
            }

        }
    }

    const [selectedDate, setSelectedDate] = useState(null);

    const [years, setYears] = useState();

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];
    const monthsDropDown = [
        { label: 'January', value: 'January' },
        { label: 'February', value: 'February' },
        { label: 'March', value: 'March' },
        { label: 'April', value: 'April' },
        { label: 'May', value: 'May' },
        { label: 'June', value: 'June' },
        { label: 'July', value: 'July' },
        { label: 'August', value: 'August' },
        { label: 'September', value: 'September' },
        { label: 'October', value: 'October' },
        { label: 'November', value: 'November' },
        { label: 'December', value: 'December' },
    ]

    useEffect(() => {
        // console.log(props, 'props')
        if (props.value)
            props.value && setSelectedDate(moment(value).toDate());
        else setSelectedDate(null)
        if (rangeFrom && rangeTo) {
            const yearOptions = range(rangeFrom, rangeTo + 1).map(option => {
                return { label: option.toString(), value: option }
            })
            setYears(yearOptions)
        }
    }, [props.value]);
    const buttonStyles = {
        border: 0,
        backgroundColor: "#fff",
        borderRight: '1px solid #f0f0f0',
        padding: '0 10px',
        borderTopLeftRadius: '10px'
    }
    const buttonStyleRight = {
        border: 0,
        backgroundColor: "#fff",
        borderLeft: '1px solid #f0f0f0',
        padding: '0 10px',
        borderTopRightRadius: '10px'
    }
    const CustomHeader = ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
    }) => {
        // console.log('Date-------->', date)
        const onChangeHandler = (dates) => {
            const [start, end] = dates
        }
        const onMenuOpen = () => {
            setTimeout(() => {
                const selectedEl = document.getElementsByClassName("date_year_picker__option--is-selected")[0];
                if (selectedEl) {
                    selectedEl.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
                }
            }, 15);
        };

        return (
            <div style={{ display: "flex", flexDirection:'column' }}>
               <div className="pb-3">
                   {!headerOptions ? 
                   <div className="text-left"><span style={{color:'#0F0F0F',fontSize:'16px'}}>Select date</span></div>
                   :
                    <div style={{borderBottom: 'border: 1px solid #E3E3E3'}} >
                        {
                            Object.keys(checkboxOptions).map(item=>{
                                return  <FormControlLabel control={<Checkbox checked={selectedOption === item} onClick={()=>setSelectedOption(item)} />} label={checkboxOptions[item]} />
                            })
                        }
                    </div> 
                   }
               </div>
               <div className="d-flex flex-row">
                <Select styles={datePickerSelect}
                    id="date_year_picker"
                    classNamePrefix="date_year_picker"
                    theme={customTheme}
                    value={{ value: getYear(date), label: getYear(date).toString() }}
                    onChange={(sData) => changeYear(sData.value)}
                    onMenuOpen={onMenuOpen}
                    options={years.map(option => {
                        if (minDate && option?.value < (new Date(minDate).getFullYear())) {
                            return { ...option, isDisabled: true }
                        }
                        if (maxDate && option?.value > (new Date(maxDate).getFullYear())) {
                            return { ...option, isDisabled: true }
                        }
                        return option
                    })}
                />
                {'  '}
               
                    <Select styles={datePickerSelect}
                        id="date_month_picker"

                        theme={customTheme}
                        value={{ value: months[getMonth(date)], label: months[getMonth(date)] }}
                        onChange={(sData) => changeMonth(months.indexOf(sData.value))}
                        options={monthsDropDown.map((options, i) => {
                            if (getYear(date) === (new Date(minDate).getFullYear())) {
                                if (i < new Date(minDate).getMonth()) {
                                    return { ...options, isDisabled: true }
                                }
                                return options
                            }
                            return options
                        })}
                    />
                </div>
                {/* <button
                    style={buttonStyleRight}
                    onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                >
                    <i className='fas fa-angle-right' />
                </button> */}
            </div>
        )
    }

    const InputLabelStyle = {
        fontFamily: "Gilroy-Regular",
        fontSize: "12px",
        lineHeight: "14px",
        color: "#414141",
        marginTop: "10px"
    };

    const Input = (props) => (
        <div className={`new-calender ${forFilter ? 'filter-calender' : ''}`}>
            <input
                style={{ height: height, ...style?.input }}
                type="text"
                placeholder={placeHolder}
                // style={{ color: 'red' }}
                className={disabled || !value ? '' : 'custom_input'}
                {...props}
            />
            <CalendarIcon isActive={props.value ? true : false} className="calendar-icon" onClick={props.onClick} disabled={disabled} />
        </div>
    )


    // useEffect(() => {
    //     console.log('selectedDate------->', selectedDate);
    // }, [selectedDate])
    const height = props.height || '31px';
    return (
        <>
            {/* {label && <InputLabel sx={InputLabelStyle}>{label}</InputLabel>} */}
            <CustomizedDatePicker>
                <DatePicker
                    formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
                    renderCustomHeader={CustomHeader}
                    selected={selectedDate}
                    onChange={(date) => {
                        setSelectedDate(moment(date).toDate());
                        handler && handler(index, date, keyName);
                    }}
                    customInput={<Input height={height} />}
                    startDate={selectedDate}
                    {...isStartDate ? { startDate: selectedDate } : {}}
                    {...(isStartDate ? { selectsStart: true } : "")}
                    {...isStartDate ? { startDate: startDate } : {}}

                    {...isEndDate ? { startDate: startDate } : {}}
                    {...isEndDate ? { endDate: endDate } : {}}
                    {...(isEndDate ? { selectsEnd: true } : "")}

                    disabledKeyboardNavigation
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown={true}
                    useShortMonthInDropdown
                    key={"InputField" + index}
                    dropdownMode="select"
                    dateFormat="dd-MMM-yyyy"
                    placeholderText={placeHolder}
                    data-testid={dataTestId}
                    disabled={disabled}
                    // minDate={minDate}
                    minDate={moment(minDate).toDate()}
                    maxDate={moment(maxDate).toDate()}

                    onKeyDown={(e) => {
                        e.preventDefault();
                    }}
                    portalId="root-portal-datepicker-v3"
                    withPortal={withportal}
                />
            </CustomizedDatePicker>
        </>
    );
};

export default CustomDatePickerV3;
