import React, { useState } from 'react'
import { Col } from 'reactstrap'

const getDisplayValue = (data,list,all) => {
    const filteredValue = list.filter(item => data.indexOf(+(item.value)) > -1);
    const valOne = filteredValue?.[0]?.label ?? '';
    const valSecond = filteredValue?.[1]?.label ?? '';
    const allData = filteredValue.map(i=> i.label)?.join(", ")
    return all ? allData : `${valOne} ${valSecond ? ',' : ' '} ${valSecond}`
}

export const DetailCard = ({ data, regionData, businessAreaData, roleData, categoryData, deleteCurrent, isDeleting,
    isSaveing, handleEditData }) => {
    
    const [isOpen,setOpen] = useState(false);

    const getRegionFromBusinessArea = () => {
        let regionList = [];
        const selectedBusinessArea = businessAreaData.data.filter(item => data.businessAreas.indexOf(item.value) > -1)
        selectedBusinessArea.map(item=> {
            regionList = [...regionList,...item.regionId]
        })

        const selectedRegions =  regionData.data.filter(item => regionList.indexOf(item.value) > -1)
        return selectedRegions ?? []
    }

    const handleEdit = () => {
        const editalbleVal = {
            "id" : data?.id ?? null,
            "region": getRegionFromBusinessArea(),
            "businessAreas": businessAreaData.data.filter(item => data.businessAreas.indexOf(item.value) > -1),
            "courseCategories": categoryData.data.filter(item => data.courseCategories.indexOf(item.value) > -1),
            "l1ApprovalPercentage": data.l1ApprovalPercentage,
            "l1ApprovalRoles": roleData.data.filter(item => data.l1ApprovalRoles.indexOf(Number(item.value)) > -1),
            "l2ApprovalPercentage": data.l2ApprovalPercentage,
            "l2ApprovalRoles": roleData.data.filter(item => data.l2ApprovalRoles.indexOf(Number(item.value)) > -1),
            "l3ApprovalPercentage": data.l3ApprovalPercentage,
            "l3ApprovalRoles": roleData.data.filter(item => data.l3ApprovalRoles.indexOf(Number(item.value)) > -1),
            "documentUploadPercentCriteria": data.documentUploadPercentCriteria,
            "status": "ACTIVE"
        }

        handleEditData(editalbleVal)
    }

    return (
        <div style={{ background: '#ffffff' }} className=' px-3 py-3 mb-3 rounded'>
            <div className='d-flex justify-content-between pt-2 pb-2'>
                <div className='d-flex'>
                    <div className="regular-large-bold color-dark">{getDisplayValue(data.businessAreas,businessAreaData.data)}<span className='color-primary'>  {data.businessAreas?.length > 2 ? `${data.businessAreas?.length -1} +More`: ''}</span></div>
                    <div className="regular-large-bold color-dark">&nbsp;&nbsp; | &nbsp;&nbsp; {getDisplayValue(data.courseCategories,categoryData.data)} <span className='color-primary'>  {data.courseCategories?.length > 2 ? `${data.courseCategories?.length -1} +More`: ''}</span></div>
                </div>
                <div className='d-flex'>
                    <div className='regular-large-bold  color-red-40 cursor' onClick={isDeleting || isSaveing ? ()=> {} : deleteCurrent}>Delete</div>
                    <div className='regular-large-bold ml-4 color-primary cursor' onClick={isDeleting || isSaveing ? ()=> {} : handleEdit}>Edit</div>
                    <div className='regular-large-bold ml-4 color-primary cursor' onClick={isDeleting || isSaveing ? ()=> {} : ()=>setOpen(!isOpen)}>{isOpen ? `Hide details` : `View details`}</div>
                </div>
            </div>
            {isOpen && <div className='d-flex flex-column pt-4'>
                <div className='approval-setting'>
                    <div className='d-flex justify-content-between pt-2'>
                        <Col md="3" className="d-flex flex-column pl-0">
                            <span className='label-text label-color'>L1 Approval %</span>
                            <span className='regular-large-bold color-dark pt-1'>{data.l1ApprovalPercentage}%</span>
                        </Col>
                        <Col md="4" className="d-flex flex-column pl-0">
                            <span className='label-text label-color'>L1 Roles</span>
                            <span className='regular-large-bold color-dark pt-1 role'>{getDisplayValue(data.l1ApprovalRoles,roleData.data,true)}</span>
                        </Col>
                        <Col md="1" />
                        <Col md="6" />
                        {/* <Col md="5" className="d-flex flex-column pl-0 justify-content-start">
                            <span className='label-text label-color'>L1 user</span>
                            <span className='regular-large-bold color-dark pt-1'></span>
                        </Col> */}
                    </div>
                    <div className='d-flex justify-content-between pt-2'>
                        <Col md="3" className="d-flex flex-column pl-0">
                            <span className='label-text label-color'>L2 approval %</span>
                            <span className='regular-large-bold color-dark pt-1'>{data.l2ApprovalPercentage}%</span>
                        </Col>
                        <Col md="4" className="d-flex flex-column pl-0">
                            <span className='label-text label-color'>L2 Roles</span>
                            <span className='regular-large-bold color-dark pt-1 role'>{getDisplayValue(data.l2ApprovalRoles,roleData.data,true)}</span>
                        </Col>
                        <Col md="1" />
                        <Col md="6" />
                        {/* <Col md="5" className="d-flex flex-column pl-0 justify-content-start">
                            <span className='label-text label-color'>L1 user</span>
                            <span className='regular-large-bold color-dark pt-1'></span>
                        </Col> */}
                    </div>

                    <div className='d-flex justify-content-between pt-2'>
                        <Col md="3" className="d-flex flex-column pl-0">
                            <span className='label-text label-color'>L3 Approval %</span>
                            <span className='regular-large-bold color-dark pt-1'>{data.l3ApprovalPercentage}%</span>
                        </Col>
                        <Col md="4" className="d-flex flex-column pl-0">
                            <span className='label-text label-color'>L3 Roles</span>
                            <span className='regular-large-bold color-dark pt-1 role'>{getDisplayValue(data.l3ApprovalRoles,roleData.data,true)}</span>
                        </Col>
                        <Col md="1" />
                        <Col md="6" />
                        {/* <Col md="5" className="d-flex flex-column pl-0 justify-content-start">
                            <span className='label-text label-color'>L3 user</span>
                            <span className='regular-large-bold color-dark pt-1'></span>
                        </Col> */}
                    </div>
                </div>
                <div className='document-approval mt-2'>
                    <Col md="12" className="d-flex flex-column pl-0">
                        <span className='label-text label-color'>Document upload mandatory above %</span>
                        <span className='regular-large-bold color-dark pt-1'>{data.documentUploadPercentCriteria}%</span>
                    </Col>
                </div>
            </div>}
        </div>
    )
}
