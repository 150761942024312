import { Tooltip } from '@mui/material'
import React, { useContext, useState } from 'react'
import ADataGrid from '../../common/data-grid/ADataGrid'
import { AGridColDef } from '../../common/data-grid/ADataGridColDef'
import { ReactComponent as ActiveControl } from '../../../../../assets/img/svg/ActiveControl.svg';
import { ReactComponent as InactiveControl } from '../../../../../assets/img/svg/InactiveControl.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/img/svg/Edit2.svg'
import { useSelector } from 'react-redux'
import NoRowOverlay from './NoRowOverlay'
import BranchesCellComponent from './BranchesCellComponent'
import { publishStatusMap } from '../constant/search-academic-plan'
import PlanStatusChangeDialog from './PlanStatusChangeDialog'
import { useHistory } from 'react-router'
import { ConfirmationDialog } from '../../common/commonComponents';
import CnfDialogBox from '../createAcademicPlan/CnfPopUp';
import { pages } from '../../common/constant';
import { PermissionContext, RolePermissions } from 'appContext'
import { checkPermission } from '../../common/utils/methods/commonMethods/utilityMethod';
// ConfirmationDialog

const AcademicPlansList = ({ page, onPageChange, planData, setPlanData, updatePublishStatusHandler, updateBranchesHandler }) => {

  const userPermissions = RolePermissions(useContext(PermissionContext), pages['searchAcademicPlan']['id']);
  const createAcademicPlanUserPermissions = RolePermissions(useContext(PermissionContext), pages['createAcademicPlan']['id']);
  const history = useHistory();
  const academicPlanState = useSelector(state => state?.academicPlan)

  const [openDialog, setOpenDialog] = useState(false);

  const [openDuplicateDialog, setOpenDuplicateDialog] = useState(false);
  const [duplicatedPlan, setDuplicatedPlan] = useState("");


  // const [planData, setPlanData] = useState({});

  const statusClickHandler = (data) => {
    setOpenDialog(true);
    setPlanData(data);
  }

  const planNameClickHandler = (e, params) => {
    if (e?.ctrlKey) {
      window.open(history.createHref({ pathname: `/admin/testScheduling/academicPlan/view/${params?.row?.id}` }), '_blank')
    } else {
      history.push(`/admin/testScheduling/academicPlan/view/${params?.row?.id}`)
    }
  }

  const academicPlansListHeader = [
    new AGridColDef('academicPlanName', 'Academic plan').setMinWidth(150)
      .renderCellComponent((params) => (
        <Tooltip title={`${params.value} ${params?.row?.description ? `: ${params?.row?.description}` : ''}`}>
          <span onClick={(e) => planNameClickHandler(e, params)} style={{ color: '#00B0F5', cursor: 'pointer', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.value}</span>
        </Tooltip>
      )),
    new AGridColDef('academicYear', 'Acad year').setMinWidth(100).setValueGetter((params) => params?.value?.dispValue),
    new AGridColDef('academicCareer', 'Acad career').setMinWidth(100).setValueGetter((params) => params?.value?.dispValue),
    new AGridColDef('board', 'Board').setMinWidth(100).setValueGetter((params) => params?.value?.dispValue),
    new AGridColDef('packageExtId', 'CMS pkg.').setMinWidth(150)
      .renderCellComponent((params) => (
        <Tooltip title={params?.value}>
          <span onClick={() => { console.log(params?.value) }} style={{ color: '#00B0F5', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params?.value}</span>
        </Tooltip>
      )),
    new AGridColDef('academicPhase', 'Phase').setMinWidth(100).setValueGetter((params) => params?.value?.dispValue),
    new AGridColDef('classes', 'Class').setMinWidth(100).setValueGetter((params) => params?.value?.dispValue),
    new AGridColDef('classType', 'Class type').setMinWidth(100).setValueGetter((params) => params?.value?.dispValue),
    new AGridColDef('branches', 'Branch(es)').setMinWidth(155)
      .renderCellComponent((params) => <BranchesCellComponent params={params} planData={params?.row} updateHandler={updateBranchesHandler} />),
    new AGridColDef('publishStatus', 'Status').setMinWidth(150)
      .renderCellComponent((params) => (
        <span>
          {checkPermission(userPermissions, 'U') && (params.value === "P" ?
            <ActiveControl onClick={() => statusClickHandler(params?.row)} /> :
            <InactiveControl onClick={() => statusClickHandler(params?.row)} />
          )}
          <span
            style={{
              fontSize: "14px", marginLeft: "4px",
              color: checkPermission(userPermissions, 'U') ? params.value === "P" ? '#0F0F0F' : '#757575' : '#0F0F0F'
            }}
          >
            {publishStatusMap[params?.value]}
          </span>
        </span>
      )),
    ...(createAcademicPlanUserPermissions ? [
      new AGridColDef('Actions', 'Actions', false).setMinWidth(100)
        .renderCellComponent((params) => (
          <span
            onClick={(e) => {
              setDuplicatedPlan({
                planName: params?.row?.academicPlanName,
                planId: params?.row?.id
              })
              setOpenDuplicateDialog(true)
            }}
            style={{ cursor: 'pointer', color: '#00B0F5' }}
          >
            Duplicate
          </span>
        ))
    ] : []),
  ]

  return (
    <>
      <ADataGrid
        rows={academicPlanState?.academicPlanList || []}
        columns={academicPlansListHeader}
        rowId={(row) => row.id}
        loading={false}
        rowCount={academicPlanState?.searchAcademicPlanRowCount}
        currentPage={page}
        nextButtonHandler={onPageChange}
        emptyRowPlaceholder={() => <NoRowOverlay />}
      />
      <PlanStatusChangeDialog
        open={openDialog}
        setOpen={setOpenDialog}
        planData={planData}
        onAgree={updatePublishStatusHandler}
      />
      <CnfDialogBox
        open={openDuplicateDialog}
        planName={duplicatedPlan.planName}
        handleClose={() => setOpenDuplicateDialog(false)}
        onAgree={() => history.push(`/admin/testScheduling/duplicatePlan/${duplicatedPlan.planId}`)
        }
      />
    </>
  )
}

export default AcademicPlansList