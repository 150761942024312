import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import AAutoComplete from "../../common/form-fields-mui/AAutoComplete";
import { ReactComponent as SaveButton } from "../../../../../assets/img/svg/SaveButton.svg";

import { formateIntoDropdown } from "./hooks";
import { saveTestContentSvg, allSubTopicOption } from "./style";

const TestContentFormData = ({
  subject,
  formModal,
  testContentFormHandler,
  addChapterHandler,
  currentIndex,
  ...props
}) => {
  const [topicDropdown, setTopicDropdown] = useState([]);
  const [subTopicDropdown, setSubtopicDropdown] = useState([]);

  useEffect(() => {
    if (props.currentForm?.chapter) {
      const { key } = props.currentForm.chapter;
      if (key === "ALL") {
       // console.log(props.dropDown["allData"]);
        onChangeHandler(
          [{ label: "All", valueL: "", key: "ALL_CHAPTER" }],
          "topic"
        );
        // onChangeHandler([{ label: "All", valueL: "" }], "subTopic");
        return;
      }
      if (key)
        setTopicDropdown(
          formateIntoDropdown(props.dropDown["allData"][key], true)
        );
    }
  }, [props.currentForm.chapter]);

  useEffect(() => {
    let tempSelectedTopic = props.currentForm.topic;
    let tempSubtopic = [];
    onChangeHandler([], "subTopic");

    tempSelectedTopic &&
      tempSelectedTopic.forEach((el) => {
        if (el.children) {
          tempSubtopic.push(...el.children);
        }
      });
    //  this is this subTopic dropdown
    let subTopicTemp = formateIntoDropdown(tempSubtopic);
    if (tempSelectedTopic?.length>topicDropdown?.length && topicDropdown?.length !==0) {
//onChangeHandler(subTopicTemp, "subTopic");
      onChangeHandler([
        allSubTopicOption,
          ...subTopicTemp,
        ], "subTopic")
    }
// if ALL is selected in chapter
    if (
      tempSelectedTopic.length === 1 &&
      tempSelectedTopic[0].key === "ALL_CHAPTER"
    ) {
      onChangeHandler([{ label: "All", valueL: "" ,key:"ALL" }], "subTopic");
    }
    //console.log(tempSelectedTopic, "sub");
    setSubtopicDropdown(subTopicTemp);
  }, [props.currentForm.topic]);

  const isShowSave = (form) => {
    return form.chapter && form.topic.length > 0 && (form.subTopic.length > 0|| subTopicDropdown.length === 0);
  };

  const onChangeHandler = (value, key) => {
    const currentData = { ...formModal[subject][currentIndex] };
    currentData[key] = value;
    //props.setAddTestContentForm(currentData)
    let currentFormModalData = { ...formModal };
    // update particular subject particular data
    currentFormModalData[subject][currentIndex] = currentData;
    props.setFormModal(currentFormModalData);
    if(!(Array.isArray(value) && value.length === 0))
           props.setIsTestDetailsUpdated(true)
  };

  return (
    <Row className ="mb-4">
      <Col md="4">
        <AAutoComplete
          items={
            props.dropDown.chapter?.length > 0
              ? [
                  {
                    label: "All (Chapters, Topics & Sub-topics)",
                    value: "",
                    key: "ALL",
                  },
                  ...props?.dropDown?.chapter,
                ]
              : []
          }
          currentValue={props.currentForm.chapter}
          handleChange={(value) => {
            onChangeHandler(value, "chapter");
            onChangeHandler([], "topic");
            onChangeHandler([], "subTopic");
          }}
          isDisabled={props.isCompleteSyllabus}
          isMulti={false}
          isShowCount={true}
          isAllEnabled = {true}
          label="Chapter*"
          placeholder="Select chapter"
        />
      </Col>
      <Col md="4">
        <AAutoComplete
          label="Topic*"
          placeholder="Select topic"
          currentValue={props.currentForm.topic}
          handleChange={(value) => onChangeHandler(value, "topic")}
          items={
            topicDropdown.length > 0
              ? [
                  {
                    label: "All (Topics & Sub-topics)",
                    value: "*",
                    key: "ALL",
                  },
                  ...topicDropdown,
                ]
              : topicDropdown
          }
          isShowCount={true}
          isAllEnabled = {true}

          isDisabled={props.isCompleteSyllabus}

          //  items = {items}
        />
      </Col>
      <Col md="4" style={{ position: "relative" }}>
        <AAutoComplete
          label="Sub-Topic*"
          placeholder="Select Sub-topic"
          currentValue={props.currentForm.subTopic}
          handleChange={(value) => onChangeHandler(value, "subTopic")}
          items={
            subTopicDropdown.length > 0
              ? [
                allSubTopicOption,
                  ...subTopicDropdown,
                ]
              : subTopicDropdown
          }
          isShowCount={true}
          isAllEnabled = {true}

          isDisabled={props.isCompleteSyllabus}
        />
        {isShowSave(props.currentForm) && !props.isCompleteSyllabus && (
          <SaveButton
            onClick={() => props.saveChapterHandler(subject, props.currentForm)}
            style={saveTestContentSvg}
          />
        )}
      </Col>
    </Row>
  );
};

export default TestContentFormData;
