import React, { useState } from "react";
import { useSelector } from "react-redux";
import APopover from "../../common/pop-hover/APophover";
import LeftSideFilterForm from "../../edpOps/studentSerach/LeftSideFilterForm";
import DisplayRegion from "../../edpOps/batch-serach/DisplayRegion";
import {
  getDisplayValue,
} from "../../edpOps/constant/batch-search";
import EditBranchesCommonComponent from '../../common/branchesCommonComponent/EditBranchesCommonComponent'


const LeftSideFilter = ({ filterForm, inputHandler, searchHandler, setFilterForm, businessAreaMap,
  setBusinessAreaMap }) => {

  const studentDetails = useSelector((state) => state.studentSearch);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleSearch = (data) => {
    const reg = data.region.map(i => i.value);
    const bus = data.businessArea.map(i => i.value);
    const newFilter={...filterForm, region : reg, businessArea: bus}

    setBusinessAreaMap({...data})
    setFilterForm({...filterForm,businessArea : data.businessArea, region : data.region});
    searchHandler(newFilter)
  }

  const getList = () => {

  }

  const getLabel = (count) => count > 1 ? "Students" : "Student";
  return (
    <div style={{ position: "relative", minWidth: "4rem" }}>
      <h4 style={{ color: "black" }}> {studentDetails && studentDetails.rowCount} {getLabel(studentDetails && studentDetails.rowCount)}</h4>
      <div>

        <><DisplayRegion
          items={(
            businessAreaMap.region
          )}
          type="Regions"
        />
          <span>
            |
            <DisplayRegion
              items={(businessAreaMap.businessArea)}
              type="Business Areas"
            />
          </span>
        </>


        {/* <DisplayRegion items={filterForm.businessArea} type="Business Areas" /> */}
        <span
          onClick={handleClick}
          aria-describedby={"demo"}
          style={{
            color: " #00B0F5",
            cursor: "pointer",
          }}
        >
          View/Edit
        </span>
      </div>

      <APopover handleClose={handleClose} id="info-batch" anchorEl={anchorEl} customSx={{overflowY : 'scroll'}}>
        {/* <LeftSideFilterForm
          filterForm={filterForm}
          inputHandler={inputHandler}
          searchHandler={() => {
            searchHandler();
            handleClose();
          }}
          clrBusinessAreaHandler={() => inputHandler([], "businessArea")}
        /> */}
        <EditBranchesCommonComponent
          title={'Branch Details'}
          closeForm= { handleClose }
          updateHandler = {handleSearch}
          businessAreas = {businessAreaMap.businessArea}
          regions = {businessAreaMap.region}
        />
      </APopover>
    </div>
  );
};

export default LeftSideFilter;
