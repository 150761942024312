import React from 'react';
import { ReturnConsumptionTable } from '../selectMeterialAndStock/issueStock/IssueStockCustomTable';
import { Card, CardBody, Row, Col } from 'reactstrap';
import AInput from 'views/pages/manage/common/formFeilds/customInput/AInput';
import AButton from '../../../common/form-fields-mui/AButton';

const tableFormatStyle = {
  border: '1px solid #E3E3E3',
  padding: '10px',
  borderRadius: '8px',
  margin: '10px 0',
};

const inputHeight = '40px';

export const mapMaterials = (list = []) => {
  if (!Array.isArray(list)) return [];
  const materialMap = {};

  list.forEach(material => {
    const key = material?.materialNumber;

    if (materialMap[key]) {
      materialMap[key]['barCodes'] = [
        ...materialMap[key]['barCodes'],
        {
          barcode: material?.barcode,
          returnTransactionId: material?.returnTransactionId,
        },
      ];
    } else {
      const {
        materialNumber,
        materialDesc,
        barcode,
        uom,
        returnTransactionId,
        serialNumberProfile,
      } = material;
      materialMap[key] = {
        materialId: materialNumber,
        materialDescription: materialDesc,
        barCodes: [{ returnTransactionId: returnTransactionId, barcode }],
        uom,
        returnTransactionId: returnTransactionId,
        serialNumberProfile
      };
    }
  });

  return Object.values(materialMap);
};

const SelectMaterialCards = ({ data, ...rest }) => {
  const rowData = mapMaterials(data);
  return (
    <Card className="m-3">
      <CardBody>
        <Row className="ml-0" style={{ justifyContent: 'space-between' }}>
          <span className="heading-4 color-dark">Enter return details </span>
        </Row>
        <div className='d-flex flex-column'>
        <div style={tableFormatStyle}>
          <ReturnConsumptionTable {...rest} dataRows={data} rows={rowData} />
        </div>
        <div style={tableFormatStyle}>
          <ReturnConsumptionTable {...rest} dataRows={data} rows={rowData} forBarcoded={false} />
        </div>
        </div>
        <Row>
          <Col md="4" className="mb-3">
            <div className="heading-3 color-dark">Reason for return*</div>
            <AInput
              value={rest?.reasonForm}
              handler={rest?.reasonFormHandler}
              placeholder="Add remarks"
              style={{ height: inputHeight }}
              disabled={rest?.isAllReturn}
            />
          </Col>
        </Row>
        <AButton
          variant="primary_filled"
          className="button_remove_left_margin"
          disabled={rest?.isAllReturn}
          onClick={rest?.returnStockHandler}
        >
          {rest?.isAllReturn ? 'Stock returned ' : 'Return Stock'}
        </AButton>
      </CardBody>
    </Card>
  );
};

export default SelectMaterialCards;
