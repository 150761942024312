import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Dialog, InputLabel } from "@mui/material";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import { Row, Col } from "reactstrap";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import { categoryType } from "./constant";
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";
import { selectStyles } from "views/pages/manage/masters/holidayMaster/common";
import { SelectorCategory } from "views/pages/manage/unAssigned/scholarshipMaster/scholarshipConfig/categorSelector";
import AInput from "views/pages/manage/common/formFeilds/customInput/AInput";
import { useGridApiContext } from "@mui/x-data-grid";
import { InputLabelStyle } from "views/pages/manage/common/form-fields-mui/AMultiSelect";
const AdditionalCategoryDialog = ({ params, ...restProps }) => {
  const {
    rowFormHandler,
    rowForm,
    allCategories,
    userPermissions,
    isView = false,
    isViewAdditional = false,
  } = restProps;
  const { id, value, field, row } = params;
  const apiRef = useGridApiContext();
  const [isOpen, setIsOpen] = useState(false);
  const [additionalCategory, setAdditionalCategory] = useState({
    cutoff: params?.value?.cutoff,
    categoryType: categoryType.find(
      (c) => c?.value == params?.value?.categoryType
    ),
  });
  const handleSave = async () => {
    await rowFormHandler(
      additionalCategory?.cutoff,
      "additionalCategoryCutoff"
    );
    await rowFormHandler(
      additionalCategory?.categoryType,
      "additionalCategoryType"
    );

    if (rowForm?.additional?.isSubSubCategory) {
      rowFormHandler(rowForm?.additional?.id, "additionalSubSubCategoryId");
      rowFormHandler(
        rowForm?.additional?.subCategoryId,
        "additionalSubCategoryId"
      );
      rowFormHandler(
        rowForm?.additional?.subSubCategoryDisplayValue,
        "additionalSubSubCategoryDispVal"
      );
    } else {
      rowFormHandler(null, "additionalSubSubCategoryId");
      rowFormHandler(rowForm?.additional?.id, "additionalSubCategoryId");
      rowFormHandler(
        rowForm?.additional?.subCategoryDispVal,
        "additionalSubCategoryDispVal"
      );
    }

    apiRef.current.setEditCellValue({
      id: params?.id,
      field: "additionalCategory",
      value: {
        ...value,
        cutoff: additionalCategory?.cutoff,
        categoryType: additionalCategory?.categoryType?.value,
      },
    });
    setIsOpen(false);
  };
  const checkInput = (value) => {
    if (
      rowForm?.categoryType?.value == categoryType[0]?.value ||
      rowForm?.categoryType?.value == categoryType[1]?.value
    ) {
      if (!value || (parseFloat(value) >= 0 && parseFloat(value) <= 100))
        setAdditionalCategory({
          ...additionalCategory,
          cutoff: value
            .replace(/[^0-9.]/g, "")
            .replace(/(\..*)\./g, "$1")
            .replace(/(\.\d{1,2}).*/g, "$1"),
        });
    } else {
      if (!value ||( /^\d+$/.test(value) && value?.length<=7)) {
        setAdditionalCategory({
          ...additionalCategory,
          cutoff: value,
        });
      }
    }
  };
  useEffect(() => {
    apiRef.current.setEditCellValue({
      id: params?.id,
      field: "additionalCategory",
      value: { ...value, cutoff: "" },
    });
    setAdditionalCategory({
      ...additionalCategory,
      cutoff: isView
        ? params?.value?.cutoff
        : rowForm?.additionalCategoryCutoff,
    });
  }, [rowForm?.categoryType]);
  useEffect(() => {
    setAdditionalCategory({
      ...additionalCategory,
      cutoff: isView
        ? params?.value?.cutoff
        : rowForm?.additionalCategoryCutoff,
    });
  }, [isView]);
  useEffect(() => {
    params?.cellMode === "edit" &&
      !rowForm?.isNew &&
      rowFormHandler(value?.category2, "additional");
  }, []);
  const labelStyle = {
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#262626",
    marginTop: "10px",
  };

  return (
    <>
      <div
        className={`${styles["add-additional-category"]} `}
        onClick={() => setIsOpen(true)}
      >
        {!isView &&
          (params?.cellMode === "edit" &&
          (params?.value ||
            rowForm?.additionalCategoryCutoff ||
            rowForm?.additionalCategoryType)
            ? "Edit/View"
            : "Add")}
        {isView && "View"}
      </div>
      <Dialog
        open={isOpen}
        PaperProps={{ sx: { maxWidth: "unset", borderRadius: "12px" } }}
      >
        <div className={`${styles["select-concession-popup"]}`}>
          <div className="d-flex " style={{ justifyContent: "space-between" }}>
            <div className="heading-4 color-dark">Additional Category </div>
            <CrossIcon
              width={24}
              height={24}
              onClick={() => {
                params?.cellMode == "edit" &&
                  setAdditionalCategory({
                    cutoff: params?.value?.cutoff,
                    categoryType: categoryType.find(
                      (c) => c?.value == params?.value?.categoryType
                    ),
                  });
                field == "additionalCategory"
                  ? apiRef.current.setEditCellValue({
                      id,
                      field,
                      value: {
                        ...value,
                        cutoff: params?.value?.cutoff,
                        categoryType: params?.value?.categoryType,
                      },
                    })
                  : apiRef.current.setEditCellValue({
                      id,
                      field,
                      value: value,
                    });
                setIsOpen(false);
              }}
            />
          </div>

          <div className={`${styles["added-concession"]} `}>
            <Row>
              <Col className="md-4">
                <AInput
                label={rowForm?.categoryType?.label
                  ? `Cutoff (${rowForm?.categoryType?.label})`
                  : row?.categoryType?.label
                  ? `Cutoff (${row?.categoryType?.label})`
                  : "Cutoff"}
                  placeholder={
                    rowForm?.categoryType?.label
                      ? `Enter ${rowForm?.categoryType?.label}`
                      : row?.categoryType?.label
                      ? `Enter ${row?.categoryType?.label}`
                      : "Enter previous category type first"
                  }
                  value={additionalCategory?.cutoff}
                  handler={(value) => {
                    checkInput(value);
                  }}
                  style={{
                    paddingRight: "20px",
                    borderRadius: "4px",
                    height: "40px",
                  }}
                  labelStyle={labelStyle}
                  disabled={isView || !rowForm?.categoryType?.label}
                />
              </Col>
              <Col className="md-4">
                <InputLabel sx={{...InputLabelStyle,...labelStyle,}}>Category 2</InputLabel>

                <SelectorCategory
                  id
                  field
                  row
                  allCategories={allCategories}
                  {...params}
                  value={value?.category2}
                  isView={false}
                  isViewAdditional={isViewAdditional}
                  userPermissions={userPermissions}
                  rowFormHandler={rowFormHandler}
                  isAdditionalCategory={true}
                  rowForm={rowForm}
                  additional={rowForm?.additional}
                  cutoffCategoryType={value}
                />
              </Col>
              <Col className="md-4">
                <AAutoComplete
                label='Category Type'
                labelStyle={labelStyle}
                  currentValue={additionalCategory?.categoryType}
                  handleChange={(value) => {
                    setAdditionalCategory({
                      ...additionalCategory,
                      categoryType: value,
                    });
                  }}
                  items={categoryType}
                  isMulti={false}
                  style={selectStyles}
                  isDisabled={isView}
                />
              </Col>
            </Row>
          </div>

          <div className=" d-flex justify-content-end ">
            <AButton
              variant="primary_filled"
              onClick={handleSave}
              disabled={
                !(
                  additionalCategory?.cutoff &&
                  additionalCategory?.categoryType &&
                  (rowForm?.additional || rowForm?.additionalSubCategoryId)
                ) || isViewAdditional
              }
            >
              Next
            </AButton>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AdditionalCategoryDialog;
