import React from 'react'
import { Card, CardBody } from 'reactstrap';

const DisplayPlan = ({ planName }) => {
  return (
    <Card className="mb-0 pb-0 border-bottom">
      <CardBody> <div className='semi-bold heading-4 color-dark'>{planName}</div></CardBody>
    </Card>
  )
}

export default DisplayPlan