import React, { useEffect,useState} from "react";
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";
const SearchInput = ({
    setBuDispVal,
    setBusinessAreaDesc,
    businessAreaDropdown
}) => {
    
    return ( 
        <div style={{width:"40%"}}>
      <AAutoComplete
      placeholder={'Search by business area code or name'}
      isMulti={false}
      items={businessAreaDropdown}
      handleChange={(e)=>{
        console.log('e.value',e.value);
        setBuDispVal(e.value.businessAreaKey)
        setBusinessAreaDesc(e.value.businessAreaDesc)
    }}
    style={{control:{height:'36px'}}}
      >

      </AAutoComplete>
      </div>
     );
}
 
export default SearchInput;