import React from "react";
import { Input, Button, Table, Label, FormGroup } from "reactstrap";
import { useState } from "react";
// import "views/pages/manage/addressType/node_modules/react-sliding-pane/dist/react-sliding-pane.css";
import { failureToast } from "../../common/utils/methods/toasterFunctions/function";
import Select2 from "react-select2-wrapper";
import { RiUploadCloudFill } from "react-icons/ri";
import { MasterHeaderAuto } from "../../common/commonComponents/masterHeader/masterHeader";
import { NewRow } from "../../common/utils/methods/commonMethods/utilityMethod";
import DocumentUpload from "./topicUpload";
import { endpoint } from "../../common/constant";
import {
  putpost2,
  putpost,
  successToast,
  masterServiceBaseUrl,
  baseUrl,
} from "services/http";
import moment from "moment";
import {
  CustomCard,
  CustomContainer,
  ToExcel,
  GetTemplate
} from "../../common/commonComponents";
import CreateButton from "components/FormComponent/Button/New/index";
import UpdateButton from "components/FormComponent/Button/Update/index";
import EditButton from "components/FormComponent/Button/Edit/index";
import { ButtonText } from "variables/Buttons";

const excelDetails = {
  "S.NO.": "sno",
  Subject: "subjectMaster",
  Description: "description",
  "Topic ID": "topicId",
  "Effective Date": "effectiveDate",
  Status: "status",
};

const HeaderElement = ({
  data,
  newRow,
  isSaveVisible,
  allClassData,
  allSubjectData,
  setModalVisible = () => {},
}) => {
  const getDisplayVal = (id, list) => {
    const i =
      id &&
      list?.filter((item) => {
        return item.id == id;
      });
    return i && i?.length ? i[0] : "";
  };

  const dataToExport = data?.map((item, index) => {
    return {
      ...item,
      sno: index + 1,
      subjectMaster:
        getDisplayVal(item.subjectMasterId, allSubjectData)?.text ?? "",
      effectiveDate: moment(item.effectiveDate).format("DD-MM-YYYY"),
    };
  });

  return (
    <>
      <h3>Master - Topic</h3>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {!isSaveVisible && (
          <ToExcel
            data={dataToExport}
            detailObj={excelDetails}
            name="Topic"
            isSaveVisible={isSaveVisible}
          />
        )}
        <GetTemplate url={endpoint.topicMasterTemplate} isSaveVisible={isSaveVisible}/>
        <Button
          disabled={isSaveVisible}
          color="info"
          size="sm"
          type="button"
          className={
            "export_button floatRight mx-1 my-2 " +
            (isSaveVisible ? " btn-dark" : null)
          }
          onClick={setModalVisible}
        >
          <RiUploadCloudFill className="pr-1" size={18} />
          Upload CSV
        </Button>
        <CreateButton 
          text={ButtonText.Topic.addNew}
          disabled={isSaveVisible}
          className={"floatRight mx-1"+(isSaveVisible?" btn-dark":null)} 
          onClick={() => {newRow()}} 
        />
      </div>
    </>
  );
};

var _ = require("lodash");

const TopicMaster = (props) => {
  const {
    isSaveVisible,
    setisSaveVisible = () => {},

    activeSubjectData,

    allSubjectData,
    pagination,
  } = props;

  const [tableDataKey, settableDataKey] = useState("tableDataKey");
  const [isNewDocument,setIsNewDocument] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);

  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: "__new",
        topicMasterKey: "",
        subjectMasterId: null,
        topicId: "",
        status: "ACTIVE",
        description: "",
      },
      ...props.classArray,
    ]);
  };

  const headerList = [
    { name: "S.No" },
    { name: "Subject", isRequired: true },
    { name: "Description", isRequired: true },
    { name: "Topic ID", isRequired: true },
    { name: "Effective Date" },
    { name: "Status", isRequired: true },
    { name: "Actions" },
  ];

  const listToBeRendered = [
    "sno",
    "subjectMasterId",
    "description",
    "topicId",
    "effectiveDate",
    "status",
  ];

  const itemToBeRenderFromMaster = ["subjectMasterId"];
  const propertyList = [null, "text", null, null, null, null, null];
  const arrList = [null, allSubjectData, null, null, null, null, null];

  const saveAll = (cb) => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if (el["editMode"]) {
        if (el["subjectMasterId"] == null) {
          isValid = false;
          failureToast("Please Select Subject");
          return;
        } else if (!el["description"] || !el["description"].trim()) {
          isValid = false;
          failureToast("Please Enter Description");
          return;
        } else if (!el["topicId"] || !el["topicId"].trim()) {
          isValid = false;
          failureToast("Please Enter Topic ID");
          return;
        }
        if (!isValid) {
          return;
        }
        if (_tempArray[index]["__status"] == "__new") {
          putpost(
            endpoint.topic.create,
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              props.fetchClasses(endpoint.topic.getAll)
            },
            (data) => {
              failureToast(data["message"]);
            },
            {
              ..._tempArray[index],
              topicId: _tempArray[index]["topicId"].toUpperCase(),
            },
            "post"
          );
        } else {
          const updatedValues = _tempArray[index];
          delete updatedValues.topicMasterKey;

          putpost(
            `${endpoint.topic.update}/${_tempArray[index].id}`,
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              props.fetchClasses(endpoint.topic.getAll)
              // delete _tempArray[index]["editMode"];
              // console.log(_tempArray[index]);
              // props.setclassArray(_tempArray);
              settableDataKey(new Date().getMilliseconds());
            },
            (data) => {
              failureToast(data["message"]);
            },
            {
              ...updatedValues,
              topicId: updatedValues["topicId"].toUpperCase(),
            },
            "put"
          );
        }
      }
    });
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
        setIsNewDocument(true)
        // settableDataKey(new Date().getMilliseconds())
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
      cb();
    }
  };

  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]["editMode"] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
      };
      cb();
    }
  };
  const deleteRow = (index) => {};

  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
  };

  return (
    <CustomContainer>
      <CustomCard
        HeaderElement={
          <HeaderElement
            isSaveVisible={isSaveVisible}
            data={props.serverData}
            allSubjectData={allSubjectData}
            newRow={newRow}
            setModalVisible={() => setModalVisible(true)}
          />
        }
      >
        <div id="questionTable" className="table-responsive">
          <Table className="align-items-center table-flush tableLayout">
            <MasterHeaderAuto headerList={headerList} />
            <tbody className="list" key={tableDataKey}>
              {props.classArray
                ? props.classArray.map((el, index) =>
                    !el["editMode"] ? (
                      <NewRow
                        data={{
                          ...el,
                          sno: isSaveVisible && isNewDocument ? index + (pagination.currentPage - 1) * 10 : index + 1 + (pagination.currentPage - 1) * 10,
                        }}
                        listToBeRendered={listToBeRendered}
                        itemToBeRenderFromMaster={itemToBeRenderFromMaster}
                        arrList={arrList}
                        propertyList={propertyList}
                      >
                        <td className="text-center  white-breakSpace">
                          <EditButton  disabled={props.isViewOnly} onClick={() => editRow(index)}
                            // data-testid="editButton"
                          />
                        </td>
                      </NewRow>
                    ) : (
                      <tr key={index + "-class"}>
                        <td className="text-center p-2 mx-1">
                          <Input
                            value={el["__status"] !== "__new" ? index + 1 + (pagination.currentPage - 1) * 10 : ""}
                            placeholder="S.No."
                            className="tdInput"
                            disabled
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Select2
                            defaultValue={el["subjectMasterId"]}
                            data={activeSubjectData}
                            onChange={(e) => {
                              updateKey(
                                index,
                                e.target.value,
                                "subjectMasterId"
                              );
                            }}
                            options={{
                              placeholder: "Select Subject",
                              disabled: el["__status"] !== "__new",
                            }}
                            className="tdSelect"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={100}
                            defaultValue={el["description"]}
                            onKeyPress={(e) => {
                              if (
                                !/^[A-Za-z0-9,: \()&_-]*$/.test(
                                  String.fromCharCode(e.charCode)
                                )
                              ) {
                                e.preventDefault();
                                return;
                              }
                            }}
                            onChange={(e) => {
                              updateKey(
                                index,
                                e.target.value.trim(),
                                "description"
                              );
                            }}
                            placeholder="Description"
                            className="tdInput"
                            disabled={el["__status"] !== "__new"}
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            maxLength={15}
                            defaultValue={el["topicId"]}
                            onKeyPress={(e) => {
                              if (
                                !/^[A-Za-z0-9]+$/.test(
                                  String.fromCharCode(e.charCode)
                                )
                              ) {
                                e.preventDefault();
                                return;
                              }
                            }}
                            onChange={(e) => {
                              updateKey(index, e.target.value, "topicId");
                            }}
                            placeholder="Topic ID"
                            className="tdInput"
                            disabled={el["__status"] !== "__new"}
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Input
                            defaultValue={moment(el["effectiveDate"]).format(
                              "DD-MM-YYYY"
                            )}
                            disabled
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <label className="custom-toggle mx-auto ml-2">
                            <input
                              checked={el.status == "ACTIVE" ? true : false}
                              type="checkbox"
                              id={el.id}
                              onChange={(e) => {
                                settableDataKey(new Date().getMilliseconds());
                                let n = "ACTIVE";
                                if (!e.target.checked) {
                                  n = "INACTIVE";
                                }
                                updateKey(index, n, "status");
                              }}
                            />
                            <span
                              style={{ width: "72px" }}
                              className="custom-toggle-slider rounded-circle activeToggle"
                              data-label-off="Inactive"
                              data-label-on="Active"
                            />
                          </label>
                        </td>
                        <td className="text-center">
                          {" "}
                          <UpdateButton text={ButtonText.Topic.save} onClick={() => { saveAll() }} />
                          <Button
                            color="danger"
                            size="sm"
                            type="button"
                            onClick={() => {
                              let n = props.classArray;
                              if (n[index]["__status"] == "__new") {
                                n.splice(index, 1);
                              } else {
                                // n[index]['editMode']=false

                                props.fetchClasses(endpoint.topic.getAll)
                              }
                              props.setclassArray(n);
                              setisSaveVisible(false);
                              setIsNewDocument(false)
                            }}
                          >
                            {" "}
                            <i className="fas fa-times" />
                          </Button>{" "}
                        </td>
                      </tr>
                    )
                  )
                : null}
            </tbody>
          </Table>
        </div>
      </CustomCard>
      <DocumentUpload
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        getAllData={() =>
          props.fetchClasses(endpoint.topic.getAll)
        }
      />
    </CustomContainer>
  );
};
export default TopicMaster;
