import CustomButton from "components/CustomButton";
import moment from "moment";
import React, { useState } from "react";
import {
  Card,
  CardHeader, Container, Input, Row, Table
} from "reactstrap";
import {
  masterServiceBaseUrl, putpost,
  putpost2
} from "services/http";
import { MasterHeader, TDROW } from "../../common/commonComponents/masterRow/masterRow";
import { failureToast, successToast } from "../../common/utils/methods/toasterFunctions/function";
var _ = require("lodash");

const CountryTable = (props) => {
  const {
    setstate,
    state,
    tableData,
    pagination,
    setclassArray,
    setpagination,
    userPermissions,
    isSaveVisible, 
    setisSaveVisible=()=>{}
  } = props;
  const [tableDataKey, settableDataKey] = useState("tableDataKey");
  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: "__new",
        countryKey: null,
        status: "ACTIVE",
        countryDesc: null,
        countryDispValue: null,
        effectiveDate: null,
        countryCode: null,
      },
      ...props.classArray,
    ]);
  };

  const saveAll = (cb) => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if (el["editMode"]) {
        if (el["countryKey"] === null || !el["countryKey"].trim()) {
          isValid = false;
          failureToast("Please enter the value of Country Key");
          return;
        } else if (el["countryDesc"] === null || !el["countryDesc"].trim()) {
          isValid = false;
          failureToast("Please enter the value of Description");
          return;
        } else if (
          el["countryDispValue"] === null ||
          !el["countryDispValue"].trim()
        ) {
          isValid = false;
          failureToast("Please enter the value of Display Value");
          return;
        } else if (el["countryCode"] === null || !el["countryCode"].trim()) {
          isValid = false;
          failureToast("Please enter the value of Country Code");
          return;
        }
        if (!isValid) {
          return;
        }
        if (_tempArray[index]["__status"] == "__new") {
          putpost(
            masterServiceBaseUrl + "/country/createCountry",
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              props.fetchClasses(masterServiceBaseUrl + "/country/getAllCountry");
            },
            (data) => {
              failureToast(data["message"]);
            },
            _tempArray[index],
            "post"
          );
        } else {
          putpost(
            masterServiceBaseUrl + "/country/updateCountry/" + _tempArray[index].id,
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              delete _tempArray[index]["editMode"];
              props.setclassArray(_tempArray);
              settableDataKey(new Date().getMilliseconds());
              // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
            },
            (data) => {
              failureToast(data["message"]);
            },
            _tempArray[index],
            "put"
          );
        }
      } else {
      }
    });
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      cb();
    }
  };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]["editMode"] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };
  const deleteRow = (index) => {
    if (window.confirm("Are you sure you want to delete this Country?")) {
      if (props.classArray[index]["__status"] == "__new") {
        let n = props.classArray;
        n.splice(index, 1);
        props.setclassArray(n);
        setisSaveVisible(false);
        settableDataKey(new Date().getMilliseconds());
      } else {
        putpost2(
          masterServiceBaseUrl + "/country/deleteCountry/" + props.classArray[index].id,
          (data) => {
            successToast(data["message"]);
            if (props.classArray.length === 1) {
              props.previousPage()
            }else{
            var _tempArray = props.classArray;
            if (_tempArray[index]["editMode"]) {
              setisSaveVisible(false);
            }
            _tempArray.splice(index, 1);
            props.setclassArray(_tempArray);
          }
            props.refreshList()
            settableDataKey(new Date().getMilliseconds());
          },
          (data) => {
            failureToast(data["message"]);
          },
          "Delete"
        );
      }
    }
  };
  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
  };

  const searchPaginationHandler = (filterData, pageSize, n) => {
    if (filterData.length > pageSize) {
      n["nextPage"] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n["totalPage"] = Math.floor(filterData.length / pageSize);
    if (filterData.length % pageSize != 0) {
      n["totalPage"] += 1;
    }
    setstate(filterData);
    setclassArray(
      filterData.slice(
        pagination.currentPage * pageSize - pageSize,
        pagination.currentPage * pageSize
      ) || []
    );
    setpagination(n);
  };

  const filterHandler = _.debounce((countryValue) => {
    let n = pagination;
    let pageSize = 10;
    if (countryValue) {
      const filterData = tableData.filter(
        (data) =>
          data["countryKey"]
            .toUpperCase()
            .includes(countryValue.toUpperCase()) ||
          data["countryDesc"]
            .toUpperCase()
            .includes(countryValue.toUpperCase()) ||
          data["countryDispValue"]
            .toUpperCase()
            .includes(countryValue.toUpperCase()) ||
          data["countryCode"]
            .toUpperCase()
            .includes(countryValue.toUpperCase()) ||
          data["status"].toUpperCase().includes(countryValue.toUpperCase())
      );
      searchPaginationHandler(filterData, pageSize, n);
    } else {
      searchPaginationHandler(tableData, pageSize, n);
    }
  }, 300);

  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Country</h3>
                <div
                  className="floatRight "
                  style={{ display: "flex", alignItems: "center", marginLeft: 'auto' }}
                >
                  Search :{" "}
                  <Input
                    className="tdInput ml-3 mr-3"
                    style={{ width: "150px" }}
                    placeholder="Search"
                    onChange={(e) => filterHandler(e.target.value)}
                    disabled={isSaveVisible}
                  />
                  <CustomButton
                    disabled={isSaveVisible}
                    className={'floatRight mx-1' + (isSaveVisible ? ' btn-dark cursor-gray' : null)}
                    content={'New Country'}
                    permissionType={'C'}
                    icon={true}
                    onClick={() => { newRow(); }}
                    permissionSet={userPermissions}
                  />
                </div>
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout">
                  <MasterHeader type={"Country"} permissionSet={userPermissions} isSaveVisible={isSaveVisible} />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                          !el["editMode"] ? (
                            <TDROW
                              type={"country"}
                              index={index}
                              el={el}
                              editRow={editRow}
                              deleteRow={deleteRow}
                            permissionSet={userPermissions}
                            isSaveVisible={isSaveVisible}
                            />
                          ) : (
                            <tr key={index + "-class"}>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength="10"
                                  defaultValue={el["countryKey"]}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      "countryKey"
                                    );
                                  }}
                                  placeholder="Example:1"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  disabled={true}
                                  defaultValue={
                                    el["__status"] == "__new"
                                      ? null
                                      : moment(el["eff_date"]).format(
                                          "DD-MM-YYYY"
                                        )
                                  }
                                  placeholder="Example:01-01-2020"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength="50"
                                  defaultValue={el["countryDesc"]}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      "countryDesc"
                                    );
                                  }}
                                  placeholder="Description"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength="50"
                                  defaultValue={el["countryDispValue"]}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      "countryDispValue"
                                    );
                                  }}
                                  placeholder="Display Value"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength="50"
                                  defaultValue={el["countryCode"]}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      "countryCode"
                                    );
                                  }}
                                  placeholder="Country Code"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <label className="custom-toggle mx-auto ml-2">
                                  <input
                                    checked={
                                      el.status == "ACTIVE" ? true : false
                                    }
                                    type="checkbox"
                                    id={el.id}
                                    onChange={(e) => {
                                      settableDataKey(
                                        new Date().getMilliseconds()
                                      );
                                      let n = "ACTIVE";
                                      if (!e.target.checked) {
                                        n = "INACTIVE";
                                      }
                                      updateKey(index, n, "status");
                                    }}
                                  />
                                  <span
                                    style={{ width: "72px" }}
                                    className="custom-toggle-slider rounded-circle activeToggle"
                                    data-label-off="Inactive"
                                    data-label-on="Active"
                                  />
                                </label>
                              </td>
                              <td className="text-center">
                                {isSaveVisible ? (
                                  <>
                                    <CustomButton
                                      content={'Save'}
                                      permissionType={'C,U'}
                                      icon={false}
                                      forTable={true}
                                      onClick={() => { saveAll() }}
                                      permissionSet={userPermissions}
                                    />
                                    <CustomButton
                                      content={''}
                                      permissionType={'cancel'}
                                      icon={true}
                                      forTable={true}
                                      onClick={() => {
                                        let n = props.classArray;
                                        if (n[index]["__status"] == "__new") {
                                          n.splice(index, 1);
                                        } else {
                                          props.fetchClasses(
                                            masterServiceBaseUrl + "/country/getAllCountry"
                                          );
                                        }
                                        props.setclassArray(n);
                                        setisSaveVisible(false);
                                      }}
                                      permissionSet={userPermissions}
                                    />
                                  </>
                                ) : (
                                    <CustomButton
                                      content={''}
                                      permissionType={'D'}
                                      icon={true}
                                      forTable={true}
                                      onClick={() => deleteRow(index)}
                                      permissionSet={userPermissions}
                                    />
                                )}
                              </td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default CountryTable;
