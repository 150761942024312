import React from 'react';
import {useHistory} from "react-router-dom";
import {SomethingWentWrongDispatch} from "../../contexts/SomethingWentWrong";
import {Error} from './ErrorPage';
const ErrorPage = (props) => {
    const setIsSomethingWentWrong = React.useContext(SomethingWentWrongDispatch);
    let history = useHistory();
    const refreshPage = () => {
        if(props?.match?.params?.isError === 'true'){
            setIsSomethingWentWrong(false);
            history.goBack();
        } else {
            window.location.reload();
        }
    }

    return (
        <Error refreshPage={refreshPage}/>
    );
};

export default ErrorPage;