import React, { useState, useEffect } from "react";
import { Col } from "reactstrap";
import Select from "react-select";
import moment from "moment";
import DocumentUploadTable from "../details/table";
import {
  getRequiredSign,
  fetchDetails,
  fetchAllData,
} from "../../../../../common/utils/methods/commonMethods/utilityMethod";
import {
  getAPI,
  enrollmentServiceBaseUrl,
  documentServiceBaseUrl,
} from "../../../../../../../../services/http";
import { commonMessages } from "views/pages/manage/common/constant";
import CustomLoader from "views/pages/manage/common/commonComponents/Loader/loader";
import { GetPagination } from "../../../../../common/commonComponents/pagination/pagination";
import StudentForm from "./studentForm";
import VendorForm from "./vendorForm";
import { failureToast, successToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';

const objSechma = {
  bucketName: "",
  documentTypeId: "",
  fileName: "",
  fileStatus: "",
  followUpDate: moment(new Date()).add(3, "days"),
  s3Status: "",
  s3Url: "",
  sourceId: "",
  status: "PENDING",
  submissionDate: "",
  processId: null,
  currentStatus: "VALID",
};

const Header = (props) => {
  const { action = "new", pid, aid, action1 = null, headerData } = props;

  const [studentData, setStudentData] = useState({
    studentId: null,
    studentName: "",
    applicationId: null,
    term: "",
    opportunityId: "",
    businessArea: "",
  });
  const {
    personTypeData = [],
    mappedPersonData = [],
    processTypeData = [],
  } = props;
  const [personType, setPersonType] = useState(null);
  const [processType, setProcessType] = useState(null);
  const [mappedProcessTypeData, setMappedProcessTypeData] = useState(null);

  const [docuemntTypes, setDocumentTypes] = useState(null);
  const [mandatoryDocument, setMandatoryDocument] = useState(null);

  const [savedData, setSavedData] = useState(null);
  const [documentUserId, setDocumentUserId] = useState(null);
  //for table
  const [classArray, setclassArray] = useState([]);
  const [userAllData, setUserAllData] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [sourceData, setSourceData] = useState([]);
  const [isSaveVisible, setIsSaveVisible] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [isFirstRecordSaved, setFirstRecordSaved] = useState(false);

  //for table pagination
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const pageSize = 15;

  const setPagination = (paramsdata) => {
    let n = pagination;
    if (paramsdata.length > pageSize) {
      n["nextPage"] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n["totalPage"] = Math.floor(paramsdata.length / pageSize);
    if (paramsdata.length % pageSize != 0) {
      n["totalPage"] += 1;
    }
    setpagination(n);
    setclassArray(
      paramsdata.slice(
        pagination.currentPage * pageSize - pageSize,
        pagination.currentPage * pageSize
      ) || []
    );
    setIsFetching(false);
  };

  //Reset UserData
  const ResetUserData = () => {
    setStudentData({
      studentId: null,
      studentName: "",
      applicationId: null,
      term: "",
      opportunityId: "",
      businessArea: "",
    });
  };

  //for formating request of docuement Type api
  const chkInDocument = (id, list) => {
    const i = list && list.findIndex((item) => item.documentTypeId === id);
    return i;
  };

  const formatDocuementType = (resArr) => {
    let formattedArray = [];
    resArr &&
      resArr.map((item, index) => {
        if (index === 0) {
          formattedArray.push(item);
        } else {
          const isInDocument = chkInDocument(
            item.documentTypeId,
            formattedArray
          );
          if (isInDocument > -1) {
            formattedArray[isInDocument] = {
              ...formattedArray[isInDocument],
              documentFormats: `${formattedArray[isInDocument]["documentFormats"]},${item.documentFormats}`,
            };
          } else {
            formattedArray.push(item);
          }
        }
      });
    return formattedArray;
  };

  //for seperating mandatory and non mandatory document
  const setMandatoryDocumentType = (docuemntTypes) => {
    let mandatoryDoc = [];
    let allDoc = [];
    let nonMandatory = [];

    const transformedDocumentType = formatDocuementType(docuemntTypes);

    transformedDocumentType &&
      transformedDocumentType.forEach((element) => {
        if (element.isMandatory && action !== "edit" && action !== "view") {
          mandatoryDoc.push({
            isNew: false,
            ...objSechma,
            isEdit: false,
            documentType: {
              ...element,
              value: element.documentTypeId,
              label: element.documentTypeDispValue,
            },
            documentTypeId: element.documentTypeId,
          });
        } else if (!element.isMandatory) {
          nonMandatory.push({
            ...element,
            value: element.documentTypeId,
            label: element.documentTypeDispValue,
          });
        }

        allDoc.push({
          ...element,
          value: element.documentTypeId,
          label: element.documentTypeDispValue,
        });
      });
    action !== "edit" && action !== "view" && setUserAllData(mandatoryDoc);
    action !== "edit" && action !== "view" && setPagination(mandatoryDoc);
    action !== "edit" &&
      action !== "view" &&
      setMandatoryDocument(mandatoryDoc);
    setDocuments(allDoc);
    setDocumentTypes(allDoc);
    // debugger
  };

  useEffect(() => {
    if (!(personType?.value && processType?.value)) {
      return;
    }
    // setDocumentTypes(response);
    // (action === "edit" || action === "view") && setRows(response);
    // setMandatoryDocumentType(response);
    getAPI(
      `${documentServiceBaseUrl}/documentType/getDocumentTypeByProcessTypeAndPersonType?processType=${processType?.value}&personType=${personType?.value}`,
      (data) => {
        // setDocumentTypes(data.data);
        // console.log(response);
        if (data?.data) {
          (action === "edit" || action === "view") && callSetRows(data?.data);
          setMandatoryDocumentType(data?.data);
        } else {
          failureToast("No document type found");
          setIsFetching(false);
        }
      },
      (data) => {
        setDocumentTypes(null);
        setIsFetching(false);
        failureToast(data["message"]);
      }
    );
  }, [personType?.value, processType?.value]);

  useEffect(() => {
    fetchDetails(
      `${documentServiceBaseUrl}/DocumentSource/getAllActiveDocumentSource`,
      setSourceData,
      "documentSource",
      "select"
    );
  }, []);

  //filter Data for Edit
  const getData = (list, id, strToCompare) => {
    const i = list.filter((item) => {
      return item[strToCompare] === id;
    });
    return i ? i?.[0] : null;
  };

  //explicit call to setRows
  const callSetRows = (data) => {
    setRows(data, savedData);
  };

  //if edit setClass array
  const setRows = (res, data) => {
    setIsFetching(true);
    const malArr = [];
    const transformedDocumentType = formatDocuementType(res);

    const DataToMap = data ? data : savedData;

    DataToMap &&
      DataToMap.map((item) => {
        const documentType = getData(
          transformedDocumentType,
          item.documentTypeId,
          "documentTypeId"
        );
        documentType &&
          malArr.push({
            ...item,
            documentType: {
              ...documentType,
              value: documentType.documentTypeId,
              label: documentType.documentTypeDispValue,
            },
          });
      });

    setUserAllData(malArr);
    setPagination(malArr);
    setIsFetching(false);
    // debugger
  };

  //formatting data from get api
  const formatData = (data) => {
    if (action === "edit" || action === "view") {
      setSavedData(data?.documentDTOList);
      setRows(documents, data?.documentDTOList);
      setPersonType(getData(personTypeData, data?.personTypeId, "value"));
      setProcessType(getData(processTypeData, data?.processTypeId, "value"));
      setDocumentUserId(data.documentUserTypeDetailsId);

      setStudentData({
        studentId: {
          label: data?.personId,
          value: data?.personId,
        },
        studentName: data?.personName,
        applicationId: {
          label: data?.applicationId,
          value: data?.applicationId,
        },
        term: data?.term,
        opportunityId: data?.opportunityId,
        businessArea: data?.businessArea,
      });
    }
    if (action === "new") {
      setRows(documents, data?.documentDTOList);
      setDocumentUserId(data.documentUserTypeDetailsId);
    }
  };

  // for edit and view
  const getDataByPIdAndAId = (pid, aid) => {
    getAPI(
      `${enrollmentServiceBaseUrl}/userDocumentDetails/getUserDocumentDetails?studentId=${pid}&applicationId=${aid}`,
      (data) => {
        formatData(data.data);
      },
      (data) => {
        failureToast(data["message"]);
        setIsFetching(false);
      }
    );
    // setTimeout(() => formatData(editResponse), 200);
  };

  useEffect(() => {
    if (action === "new") {
      setIsFetching(false);
    }

    if (action === "view" && pid && aid) {
      getDataByPIdAndAId(pid, aid);
    }

    if (action === "edit" && pid && aid) {
      getDataByPIdAndAId(pid, aid);
    }
  }, [action, pid, aid]);

  useEffect(() => {
    if (
      action1 &&
      action === "new" &&
      personTypeData.length > 0 &&
      processTypeData.length > 0 &&
      headerData
    ) {
      personTypeData.map((item, index) => {
        if (item.label.toLowerCase() === "student") {
          setPersonType(item);
        }
      });
      processTypeData.map((item, index) => {
        if (item.label.toLowerCase() === "admission") {
          setProcessType(item);
        }
      });
      setStudentData({
        ...studentData,
        studentId: {
          value: headerData.studentId,
          label: headerData.studentId,
        },
        studentName: headerData.studentName,
        applicationId: {
          value: headerData.applicationId,
          label: headerData.applicationId,
        },
        term: headerData.termId,
        opportunityId: headerData.opportunityId,
        businessArea: headerData.businessAreaId,
      });
      // getDataByPIdAndAId(headerData.studentId,headerData.applicationId);
    }
  }, []);

  useEffect(() => {
    if (personType?.value) {
      fetchAllData(
        `${documentServiceBaseUrl}/DocumentProcessTag/getDistinctProcessType?personTypeId=${personType?.value}`,
        setMappedProcessTypeData
      );
    }
  }, [personType?.value]);

  const getMappedObject = (mappedList, originalList) => {
    const temp = [];
    mappedList &&
      mappedList.forEach((element) => {
        const isInList = getData(originalList, element, "value");
        if (isInList !== null && isInList !== undefined) {
          temp.push(isInList);
        }
      });
    return temp ?? [];
  };

  return (
    <>
      {isFetching ? (
        <div
          className="mx-auto text-center py-5 my-5"
          style={{ height: "100vh" }}
        >
          <CustomLoader apiLoader={isFetching} />
        </div>
      ) : (
        <>
          <div className="form-row justify-content-between p-4">
            <Col md={4} lg={4}>
              <label className="form-control-label">
                Person Type {getRequiredSign()}
              </label>
              <Select
                value={personType}
                id="docuemnt_upload_person_type"
                options={getMappedObject(mappedPersonData, personTypeData)}
                isDisabled={action === "edit" || action === "view" || action1}
                placeholder="Select Person Type"
                onChange={(sData) => {
                  setPersonType(sData);
                  setProcessType(null);
                  setMappedProcessTypeData(null);
                  ResetUserData();
                }}
                isDisabled={
                  isFirstRecordSaved || action === "edit" || action === "view"
                }
                isLoading={!personTypeData || !mappedPersonData}
              />
            </Col>
            <Col md={4} lg={4}>
              <label className="form-control-label">
                Proccess Type {getRequiredSign()}(
                <span className={"text-danger field-info"}>
                  {commonMessages.getProceesTypeByPerson}
                </span>
                )
              </label>
              <Select
                value={processType}
                id="docuemnt_upload_person_type"
                options={getMappedObject(
                  mappedProcessTypeData,
                  processTypeData
                )}
                isDisabled={action === "edit" || action === "view" || action1}
                placeholder="Select Proccess Type"
                onChange={(sData) => {
                  setProcessType(sData);
                  ResetUserData();
                }}
                isDisabled={
                  isFirstRecordSaved || action === "edit" || action === "view"
                }
                isLoading={
                  personType?.value &&
                  (!mappedProcessTypeData || !processTypeData)
                }
              />
            </Col>
          </div>
          <hr />

          <div className="p-4">
            {personType &&
            personType?.value &&
            personType.label.toLowerCase() === "student" &&
            docuemntTypes ? (
              <StudentForm
                action1={action1}
                studentData={studentData}
                setStudentData={setStudentData}
                isViewOnly={
                  action === "view" || action === "edit" ? true : false
                }
                isFirstRecordSaved={isFirstRecordSaved}
              />
            ) : personType &&
              personType?.value &&
              personType.label.toLowerCase() === "vendor" &&
              docuemntTypes ? (
              <VendorForm />
            ) : null}
          </div>
          {docuemntTypes && documents && (
            <>
              <DocumentUploadTable
                action1={action1}
                isSaveVisible={isSaveVisible}
                setIsSaveVisible={setIsSaveVisible}
                classArray={classArray}
                setClassArray={setclassArray}
                // isFetching={isFetching}
                // setIsFetching={setIsFetching}
                documents={documents}
                sourceData={sourceData}
                studentData={studentData}
                documentUserId={documentUserId}
                personType={personType?.value}
                processType={processType?.value}
                isViewOnly={action === "view" ? true : false}
                isEditable={action === "edit" ? true : false}
                setFirstRecordSaved={setFirstRecordSaved}
                mandatoryDocument={mandatoryDocument}
                setMandatoryDocument={setMandatoryDocument}
                getDataByPIdAndAId={getDataByPIdAndAId}
              />
              {pagination.totalPage > 1 ? (
                <GetPagination
                  isSaveVisible={isSaveVisible}
                  setclassArray={setclassArray}
                  pagination={pagination}
                  setpagination={setpagination}
                  pageSize={pageSize}
                  state={userAllData}
                />
              ) : null}
            </>
          )}
        </>
      )}
    </>

    // <Input
    //   value={addressKey}
    //   placeholder={`Address Key`}
    //   type="text"
    //   disabled={isDisabled}
    //   maxLength="10"
    //   onChange={(e) => inputHandler(e, setAddressKey)}
    //   style={{ height: "39px" }}
    // />
  );
};

export default Header;
