import { timetableServiceUrl } from "services/http";

const templateUrl = timetableServiceUrl;

export const timetableServiceEndpoints = {
  attendanceDetails: {
    getAll: `${templateUrl}/studentdetails`,
    save:`${templateUrl}/offline/punch`,
    gateAttendance: `${templateUrl}/external/offline/gate`,
    fetchOfflineAttendanceStatus:`${templateUrl}/external/offline_attendance_status`
  },
};
