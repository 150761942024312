import React, { useEffect, useState } from 'react'
import { Card, CardHeader, Row, Col, CardBody, Container } from 'reactstrap'
import CustomButton from '../../../../../components/CustomButton';
import { endpoint } from '../../common/constant';
import CommonInput from '../../common/formFeilds/input/commonInput';
import { fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';

const TemplateMappingHeader = (props) => {


    const { values, setValues, projectData, destinationTypeData, disableHeader,
        fetching,
        validateProjectAndRole = () => { }, havePreviousRecord, setHavePreviousRecord = () => { } } = props;

    const [roleData, setRoleData] = useState({ fetching: false, data: [] })

    const getRoles = async () => {
        setRoleData({ ...roleData, fetching: true })
        const role = await fetchAllPromisedData(`${endpoint.getRole.getAllRoleOnProject}/${values.project?.value}`)
        if (Array.isArray(role)) {
            const tempArr = role.map(role => {
                return { value: role.id, label: role.roleName }
            })
            setRoleData({ data: tempArr, fetching: false })
        } else {
            setRoleData({ data: [], fetching: false })
        }
    }

    useEffect(() => {
        if (values.project?.value) {
            getRoles()
        }
    }, [values.project])

    return (
        <Container fluid >
            <Card>
                <CardHeader>
                    <h3>Template Mapping Header</h3>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md="3">
                            <CommonInput
                                type={'select'}
                                // forTable={true}
                                id="notification-project"
                                placeHolder={'Select Project'}
                                defaultValue={values.project}
                                data={projectData?.data}
                                onChange={(value) => { setValues({ ...values, project: value, roleId: '' }); setHavePreviousRecord({ completed: false, haveData: false }) }}
                                loading={projectData?.fetching}
                                isDisabled={disableHeader}
                            />
                        </Col>
                        <Col md="3">
                            <CommonInput
                                type={'select'}
                                // forTable={true}
                                id="notification-role"
                                placeHolder={'Select Role'}
                                defaultValue={values.roleId}
                                data={roleData?.data}
                                onChange={(value) => { setValues({ ...values, roleId: value }) }}
                                loading={roleData?.fetching}
                                isDisabled={!values.project?.value || disableHeader}
                            />
                        </Col>
                        {/* <Col md="3">
                            <CommonInput
                                type={'select'}
                                // forTable={true}
                                id="notification-role"
                                placeHolder={'Select Destination Type'}
                                defaultValue={values.destinationType}
                                data={destinationTypeData?.data}
                                onChange={(value) => { setValues({ ...values, destinationType: value }) }}
                                loading={destinationTypeData?.fetching}
                               // isDisabled={!values.project?.value || disableHeader}
                            />
                        </Col> */}
                        <Col md="4" />
                        {/* <Col md="1">
                            {!disableHeader && <CustomButton
                                permissionSet={['C', 'U']}
                                permissionType={'C,U'}
                                content={fetching ? <i className="fas fa-spinner fa-spin" aria-hidden="true" /> : 'Next'}
                                disabled={!values.project?.value || !values.roleId?.value}
                                onClick={validateProjectAndRole}
                            />}
                        </Col> */}
                        <Col md="2">
                            {havePreviousRecord?.completed && !disableHeader && <CustomButton
                                permissionSet={['C', 'U']}
                                permissionType={'C,U'}
                                content={fetching ? <i className="fas fa-spinner fa-spin" aria-hidden="true" /> : havePreviousRecord?.haveData ? 'Update' : 'Create'}
                                disabled={!values.project?.value || !values.roleId?.value}
                                onClick={validateProjectAndRole}
                            />}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Container>
    )
}

export default TemplateMappingHeader