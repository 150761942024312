import React, { useEffect, useState } from 'react';
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  Button,
  Row,
  Col,
} from 'reactstrap';
import { Accordion } from '../../../common/commonComponents/Accordion/accordion';
import { useHistory } from 'react-router-dom';
import { masterServiceBaseUrl, getAPI, failureToast } from 'services/http';

import InvoiceNumberSetting from './invoiceNumberSetting';
import TaxableReceiptNumberSetting from './taxableReceiptNumberSetting';
import TaxableRefundNumberSetting from './taxableRefundNumberSetting';
import NonTaxableReceiptNumberSetting from './nonTaxableReceiptNumberSetting';
import NonTaxableRefundNumberSetting from './nonTaxableRefundNumberSetting';
import ScholarshipTransactionSetup from './scholarshipTransactionSetup';
import './addLastNumberGenerated.scss';

const AddLastNumberGenerated = (props) => {
  let history = useHistory();
  const {
    action,
    previousStep,
    businessUnit,
    businessKey,
    nextStep = () => {},
    isNew = true,
    formId,
    isViewOnly = false,
    lastNumberGeneratedData,
    appendAllStepData,
    isPrevious,
    saveStepsCompleted=()=>{},
    data
  } = props;
  const [businessKeyValue, setBusinessKeyValue] = useState('');
  const [receiptLabel, setReceiptLabel] = useState([]);
  const [invoiceNumberSetting, setInvoiceNumberSetting] = useState([]);
  const [receiptNumberSetting, setReceiptNumberSetting] = useState([]);
  const [refundNumberSetting, setRefundNumberSetting] = useState([]);
  const [nonTaxableReceiptNumberSetting, setNonTaxableReceiptNumberSetting] =useState([]);
  const [nonTaxableRefundNumberSetting, setNonTaxableRefundNumberSetting] =useState([]);
  const [scholarshipTransactionSetup, setScholarshipTransactionSetup] = useState([]);
  const [ isDisabled, setIsDisabled ] = useState(false);
  // let businessKey = 'KK1091';
  // let businessKey = 'KK1001';

  // console.log('businessKey__', businessKey);

  useEffect(() => {
    fetchDetails(
      masterServiceBaseUrl + '/receiptlabel/active',
      setReceiptLabel,
      'receiptLabel'
    );

    let keyCheck = businessKey ? businessKey : '';
    let key = new String(keyCheck);
    let length = key.length;
    let startLen = length - 3;
    let res = key.substring(startLen, length);
    setBusinessKeyValue(res);

    // console.log("Action value is here  :",action)
    if ( action === 'edit' || action === 'view' ) {
      // console.log('inside the section for the edit ',lastNumberGeneratedData)
      setInvoiceNumberSetting( lastNumberGeneratedData[ 'invoiceNumberSetting' ] );
      setReceiptNumberSetting(lastNumberGeneratedData['taxableCourseReceiptNumberSetting'])
      setRefundNumberSetting(lastNumberGeneratedData['taxableCourseRefundNumberSetting'])
      setNonTaxableReceiptNumberSetting(lastNumberGeneratedData['nonTaxableCourseReceiptNumberSetting'])
      setNonTaxableRefundNumberSetting(lastNumberGeneratedData['nonTaxableCourseRefundNumberSetting'])
      setScholarshipTransactionSetup( lastNumberGeneratedData[ 'scholarshipTransactionIDSetup' ] );
      setIsDisabled(true);
    }
    // console.log('Businness_Unit', businessUnit);
  }, []);
  
  useEffect(() => {
    if(data && isPrevious){
      setInvoiceNumberSetting( data && data[ 'invoiceNumberSetting' ] );
      setReceiptNumberSetting(data && data['taxableCourseReceiptNumberSetting'])
      setRefundNumberSetting(data && data['taxableCourseRefundNumberSetting'])
      setNonTaxableReceiptNumberSetting(data && data['nonTaxableCourseReceiptNumberSetting'])
      setNonTaxableRefundNumberSetting(data && data['nonTaxableCourseRefundNumberSetting'])
      setScholarshipTransactionSetup( data && data[ 'scholarshipTransactionIDSetup' ] );
    }
  }, [data,isPrevious])


  const toNextStep = () => {
    // debugger
    !isPrevious && saveStepsCompleted(11)
    appendAllStepData({
      invoiceNumberSetting :invoiceNumberSetting,
      taxableCourseReceiptNumberSetting:receiptNumberSetting,
      taxableCourseRefundNumberSetting:refundNumberSetting,
      nonTaxableCourseReceiptNumberSetting:nonTaxableReceiptNumberSetting,
      nonTaxableCourseRefundNumberSetting:nonTaxableRefundNumberSetting,
      scholarshipTransactionIDSetup:scholarshipTransactionSetup
    
    },'eleventhForm')
    // console.log("i m in next method")
    props.nextStep()
  }




  const fetchDetails = (url, stateMethod, type) => {
    try {
      getAPI(
        url,
        (data) => {
          refactorData(data['data'], stateMethod, type);
        },
        (data) => {
          failureToast(data['message']);
        }
      );
    } catch (e) {
      console.log('Error', e);
    }
  };

  const refactorData = (data, stateMethod, type) => {
    try {
      switch (type) {
        case 'receiptLabel':
          iterateData(
            data,
            'id',
            'receiptLabelDisplayValue',
            'receiptLabelDescription',
            stateMethod
          );
          break;
        default:
          break;
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  const iterateData = (data, id, property, propertyDesc, stateMethod) => {
    try {
      let temp = [];
      data.map((item) => {
        temp.push({
          id: item[id],
          text: item[property],
          desc: item[propertyDesc],
        });
      });
      stateMethod(temp);
    } catch (e) {
      console.log('Error', e);
    }
  };

  // console.log('receiptLabel__', receiptLabel);

  const getDisplayValue = (id, type) => {
    try {
      switch (type) {
        case 'ReceiptLabelDispValue':
          const idIndex = findIndexForDisplay(id, receiptLabel);
          return receiptLabel[idIndex]['text'];
          break;
        default:
          break;
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  const findIndexForDisplay = (id, datalist) => {
    const index = datalist.findIndex((item) => item['id'] == id);
    return index;
  };

  const findDisplayValue = (datalist, id) => {
    try {
      const findIndex = datalist.findIndex((item) => item['id'] == id);
      return findIndex >= 0 ? datalist[findIndex]['text'] : 'NOT FOUND';
    } catch (e) {
      console.log('Error', e);
    }
  };

  const moveToNextStep = () => {
    if (invoiceNumberSetting.length === 0)
      return failureToast('Please fill the Invoice Number Setting');
    if (receiptNumberSetting.length === 0)
      return failureToast(
        'Please fill the Taxable Courses : Receipt Number Setting'
      );
    if (refundNumberSetting.length === 0)
      return failureToast(
        'Please fill the Taxable Courses : Refund Number Setting'
      );
    if (nonTaxableReceiptNumberSetting.length === 0)
      return failureToast(
        'Please fill the Non-Taxable Courses : Receipt Number Setting'
      );
    if (nonTaxableRefundNumberSetting.length === 0)
      return failureToast(
        'Please fill the Non-Taxable Courses : Refund Number Setting'
      );
    if (scholarshipTransactionSetup.length === 0)
      return failureToast('Please fill the Scholarship Transaction ID Setup');
    history.push('/admin/businessUnit');
    // nextStep();
  };

  return (
    <Container className="mt-4" fluid>
        <Card>
          <CardBody>
            <Accordion
              isFormOpen={action === 'new' ? true : false}
            removePadding={ true }
              title={`Invoice Number Setting`}
              key={'invoice_number_setting'}
            >
              <InvoiceNumberSetting
                // businessUnit={518}
              removePadding={ true }
                businessUnit={ businessUnit && businessUnit['businessUnit']}
                businessKeyValue={businessKeyValue}
                invoiceNumberSetting={invoiceNumberSetting}
                setInvoiceNumberSetting={setInvoiceNumberSetting}
                receiptLabel={receiptLabel}
              getDisplayValue={ getDisplayValue }
              isDisabled={ isDisabled }
              action={action}
              isPrevious={isPrevious}
              formId={formId}
              saveStepsCompleted={()=>saveStepsCompleted(11)}
              isNew={isNew}
              />
            </Accordion>
            <Accordion
              isFormOpen={action === 'new' ? true : false}
            removePadding={ true }
              title={`Taxable Courses : Receipt Number Setting`}
              key={'taxable_courses_:_receipt_number_setting'}
            >
              <TaxableReceiptNumberSetting
                // businessUnit={312}
                businessUnit={businessUnit && businessUnit['businessUnit']}
                businessKeyValue={businessKeyValue}
                receiptLabel={receiptLabel}
                receiptNumberSetting={receiptNumberSetting}
                setReceiptNumberSetting={setReceiptNumberSetting}
              getDisplayValue={ getDisplayValue }
              isDisabled={ isDisabled }
              action={action}
              isPrevious={isPrevious}
              formId={formId}
              saveStepsCompleted={()=>saveStepsCompleted(11)}
              isNew={isNew}
              />
            </Accordion>
            <Accordion
              isFormOpen={action === 'new' ? true : false}
            removePadding={ true }
              title={`Taxable Courses : Refund Number Setting`}
              key={'taxable_courses_:_refund_number_setting'}
            >
              <TaxableRefundNumberSetting
                // businessUnit={312}
                businessUnit={businessUnit && businessUnit['businessUnit']}
                businessKeyValue={businessKeyValue}
                receiptLabel={receiptLabel}
                refundNumberSetting={refundNumberSetting}
                setRefundNumberSetting={setRefundNumberSetting}
              getDisplayValue={ getDisplayValue }
              isDisabled={ isDisabled }
              action={action}
              isPrevious={isPrevious}
              formId={formId}
              saveStepsCompleted={()=>saveStepsCompleted(11)}
              isNew={isNew}
              />
            </Accordion>
            <Accordion
              isFormOpen={action === 'new' ? true : false}
            removePadding={ true }
              title={`Non-Taxable Courses : Receipt Number Setting`}
              key={'non-taxable_courses_:_receipt_number_setting'}
            >
              <NonTaxableReceiptNumberSetting
                // businessUnit={359}
                businessUnit={ businessUnit && businessUnit['businessUnit']}
                businessKeyValue={businessKeyValue}
                receiptLabel={receiptLabel}
                nonTaxableReceiptNumberSetting={nonTaxableReceiptNumberSetting}
                setNonTaxableReceiptNumberSetting={
                  setNonTaxableReceiptNumberSetting
                }
              getDisplayValue={ getDisplayValue }
              isDisabled={ isDisabled }
              action={action}
              isPrevious={isPrevious}
              formId={formId}
              saveStepsCompleted={()=>saveStepsCompleted(11)}
              isNew={isNew}
              />
            </Accordion>
            <Accordion
              isFormOpen={action === 'new' ? true : false}
            removePadding={ true }
              title={`Non-Taxable Courses : Refund Number Setting`}
              key={'non-taxable_courses_:_refund_number_setting'}
            >
              <NonTaxableRefundNumberSetting
                // businessUnit={518}
                businessUnit={businessUnit && businessUnit['businessUnit']}
                businessKeyValue={businessKeyValue}
                receiptLabel={receiptLabel}
                nonTaxableRefundNumberSetting={nonTaxableRefundNumberSetting}
                setNonTaxableRefundNumberSetting={
                  setNonTaxableRefundNumberSetting
                }
              getDisplayValue={ getDisplayValue }
              isDisabled={ isDisabled }
              action={action}
              isPrevious={isPrevious}
              formId={formId}
              saveStepsCompleted={()=>saveStepsCompleted(11)}
              isNew={isNew}
              />
            </Accordion>
          <Accordion
            removePadding={ true }
              isFormOpen={action === 'new' ? true : false}
              title={`Scholarship Transaction ID Setup`}
              key={'scholarship_transaction_id_setup'}
            >
              <ScholarshipTransactionSetup
                // businessUnit={518}
                businessUnit={businessUnit && businessUnit['businessUnit']}
                businessKeyValue={businessKeyValue}
                receiptLabel={receiptLabel}
                scholarshipTransactionSetup={scholarshipTransactionSetup}
                setScholarshipTransactionSetup={setScholarshipTransactionSetup}
              getDisplayValue={ getDisplayValue }
              isDisabled={ isDisabled }
              action={action}
              isPrevious={isPrevious}
              formId={formId}
              saveStepsCompleted={()=>saveStepsCompleted(11)}
              isNew={isNew}
              />
            </Accordion>
          </CardBody>
        { (action !== 'edit' && action !== 'view') ?
          <Row>
            <Col>
              <Button
                className="floatLeft ml-4 mb-3"
                color="primary"
                type="button"
                onClick={ () => {
                  previousStep();
                } }
              >
                Previous
              </Button>
              <Button
                color="primary"
                className="floatRight mr-4 mb-3"
                type="button"
                onClick={ () => moveToNextStep() }
              >
                Close
              </Button>
            </Col>
          </Row> : <Row>
            { action === 'edit' ?
              <Col>
              <Button
                color="primary"
                className="floatRight mr-4 mb-3"
                type="button"
                onClick={ () =>setIsDisabled(!isDisabled)}
              >
                {isDisabled ? 'Enabled Form' : 'Disabled Form'}
              </Button>
            </Col>:null}
          </Row> }
        </Card>
    </Container>
  );
};

export default AddLastNumberGenerated;
