import React, { useState } from "react";
import styles from "../styles.module.scss";
import AButton from "../../../../common/form-fields-mui/AButton";
import PrintSlipBtn from "../../dlpStockIssuance/printSlip";
import OnHoldReasonSelector from "views/pages/manage/edpOps/stockManagement/selectMeterialAndStock/issueStock/OnHoldReasonSelector";
const BulkSelectionFooter = ({
  selectionModel,
  bulkIssueStockHandler,
  deSelectAllHandler,
  btnText,
  otherHandler,
  isSecondButton = false,
  data = [],
  selectedStudentsForMarkEligible,
  isOnHold=false
}) => {
  const [isOpen, setOpen]= useState(false)
  return (
    selectionModel?.length > 0 && (
      <div className={`${styles?.bulk_footer}`}>
        <div className="d-flex flex-column">
          <div className="heading-4">
            {selectionModel?.length}{" "}
            {`Student${selectionModel?.length === 1 ? "" : "s"} selected`}
          </div>
          <div className="regular-small">What do you want to do next?</div>
        </div>
        <div className="ml-5 flex-grow-1 d-flex">
          {isSecondButton && (
            <PrintSlipBtn
              data={data}
              variant="primary_filled"
              onClick={otherHandler}
            />
          )}
          <AButton
            size="xs"
            variant="primary_filled"
            onClick={()=>bulkIssueStockHandler()}
          >
            {btnText ? btnText : "Issue stock"}{" "}
          </AButton>
        </div>
        {isOnHold&&<div>
          <AButton
            updatedStyle={{
              color: "#FFFFFF",
              letterSpacing: "0.5px",
              border: '1px solid #FFFFFF',
              background: '#004662'
            }}
            onClick={() => setOpen(true)}
          >
            On hold
          </AButton>
        </div>}
        {isOpen && (
          <OnHoldReasonSelector
            isOpen={isOpen}
            setOpen={setOpen}
            isBulk={true}
            bulkIssueStockHandler={bulkIssueStockHandler}
            selectedStudentsForMarkEligible={selectedStudentsForMarkEligible}
          />
        )}
        <div>
          <AButton
            updatedStyle={{
              color: "#0F0F0F",
              letterSpacing: "0.5px",
            }}
            onClick={deSelectAllHandler}
          >
            Deselect all
          </AButton>
        </div>
      </div>
    )
  );
};

export default BulkSelectionFooter;
