import moment from "moment"
import { endpoint } from "views/pages/manage/common/constant";
import { fetchAllPostPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { fetchAllPromisedData } from "../../views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { START_LOADER, STOP_LOADER, STUDENT_LIST_CLEAR, STUDENT_LIST_FAILURE, STUDENT_LIST_REQUEST, STUDENT_LIST_SUCCESS, UPDATE_STUDENT_LIST_DROPDOWNS } from "./actionTypes";
import { updateRequest, getIdsArray } from 'views/pages/manage/edpOps/constant/common';
import { getBusinessAreaAndRegionDropdowns } from "views/pages/manage/edpOps/constant/common";
import { getAPI } from 'services/http';

export const studentListSuccess = (studentList, rowCount, currentPage) => {
  return {
    studentList,
    rowCount,
    currentPage,
    type: STUDENT_LIST_SUCCESS,
  };
};

export const studentListFailure = () => {
  return {
    type: STUDENT_LIST_FAILURE
  };
};

export const searchStudentClear = () => {
  return {
    type: STUDENT_LIST_CLEAR
  };
};



export const getStudentList = (data = {}, offSet = 0) => {
  return async (dispatch, getState) => {
    dispatch({ type: STUDENT_LIST_REQUEST })

    try {
      // handle if user try to submit empty business area because api send all data in that case
      let request = { ...data }
      if (data.businessArea && data.businessArea.length === 0) {
        request = { ...data, businessArea: getIdsArray(getState().studentSearch.dropdowns.businessAreas) }
      }
      // debugger
      // handle allocationStatus empty case 
      if (request && data.allocationStatus === '') {
        delete request['allocationStatus']
      }
      const response = await fetchAllPostPromisedData(
        `${endpoint.allocateStudents.searchStudentApplications}?offset=${offSet}`,
        { ...updateRequest(request) },
        "post"
      );
      if (response?.code === 200) {
        dispatch(
          studentListSuccess(
            response?.data?.studentApplicationDetailsSearchResponseDTOList,
            response.data.totalRecords,
            response.currentPage
          )
        );
      } else {
        dispatch(studentListFailure())
      }

    } catch (error) {
      dispatch(studentListFailure())

    }

  };
};


export const loadDropdowns = (cb) => {

  return async (dispatch) => {
    dispatch({ type: STUDENT_LIST_REQUEST })
    const response = await getBusinessAreaAndRegionDropdowns();
    if (response) {
      dispatch({ type: UPDATE_STUDENT_LIST_DROPDOWNS, dropdowns: response });
      cb(response)
    }
  };
};

export const fetchAllBatchesByApplicationId = (applicationId) => {
  return fetchAllPromisedData(`${endpoint.studentSearch.getBatchesByApplicationId}/${applicationId}`)

}

export const startLoader = () => {
  return {
    type: START_LOADER
  }
}

export const stopLoader = () => {
  return {
    type: STOP_LOADER
  }
}