import { Dialog } from '@mui/material'
import React from 'react'
import AButton from 'views/pages/manage/common/form-fields-mui/AButton'
import { ReactComponent as CrossIcon } from '../../../../../../assets/img/svg/CrossIcon.svg'
import { ReactComponent as QuestionIcon } from '../../../../../../assets/img/svg/QuestionMarkConfirmationDialog.svg'

const StatusChangeDialog = ({ open, setOpen, rowData, onAgree = () => { } }) => {

  const handleClose = () => setOpen(false);

  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={{ padding: "28px 24px", width: "500px" }}>
        <div className='d-flex justify-content-between align-items-start mb-3'>
          <QuestionIcon color='#414141' />
          <CrossIcon width={32} height={32} onClick={handleClose} />
        </div>

        <>
          <div
            style={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "24px",
              color: "#000000",
              marginBottom: "1rem",
            }}
          >
            {rowData?.status === 'INACTIVE' ? `Are you sure you want to activate this day plan?` : `Are you sure you want to deactivate this day plan?`}
          </div>
          <div
            style={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#414141",
              marginBottom: "28px",
            }}
          >
            {rowData?.status === 'INACTIVE' 
              ? `The available days and time slots will be visible for time table creation in relevant batches.` 
              : `The available days and time slots will not be available for time table creation in relevant batches.`
            }
          </div>
        </>

        <div className='d-flex justify-content-between'>
          <AButton variant='outline' className='button_remove_left_margin' updatedStyle={{ border: '1px solid #414141', color: '#414141', width: '180px' }} onClick={handleClose}>No</AButton>
          {rowData?.status === 'INACTIVE' ? (
            <AButton variant='primary_filled' updatedStyle={{ width: '180px' }} onClick={() => { handleClose(); onAgree(); }}>Yes, Activate</AButton>
          ) : (
            <AButton variant='danger_filled' updatedStyle={{ backgroundColor: '#CC5F5F', width: '180px' }} onClick={() => { handleClose(); onAgree(); }}>Yes, Deactivate</AButton>
          )}
        </div>
      </div>
    </Dialog>
  )
}

export default StatusChangeDialog