import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import ADataGrid from '../../../../common/data-grid/ADataGrid'
import NoRowOverlay from '../../../../masters/holidayMaster/NoRowOverlay'
import { generateRandomId, getFormattedDate, isObjEmpty } from '../../../../common/utils/methods/commonMethods/utilityMethod';
import { gridStyles, gridComponents } from 'views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant.js'
import { failureToast } from '../../../../common/utils/methods/toasterFunctions/function';
import { saveOrDeleteMsg } from '../sapProfitCostCentreMapping/constant';
import { GridRowModes } from '@mui/x-data-grid';
import { newRow } from './constant';
import { AGridColDef } from '../../../../common/data-grid/ADataGridColDef';
import AAutoComplete from '../../../../common/form-fields-mui/AAutoComplete';
import { editCellContainerStyle, selectStyles } from '../onBoardingMaterialIdSetup/constant';
import MultiSelectCellComponentWithSearch from '../../../../common/commonComponents/SearchFilterView/MultiSelectCellComponentWithSearch';
import SelectMaterialElasticSearch from '../onBoardingMaterialIdSetup/SelectMaterialElasticSearch';
import StatusCellComponent from './StatusCellComponent';
import moment from 'moment';
import { IoEyeOutline } from 'react-icons/io5';
import { Tooltip } from '@mui/material';
import ActionsCellComponent from './ActionsCellComponent';
import { CourseDetails } from '../../selectMeterialAndStock/issueStock/TableColumn';


const ListComponent = forwardRef(({
  page,
  onPageChange,
  rows,
  loader,
  dropdown,
  createHandler,
  updateHandler,
  toggleStatusHandler,
  deleteHandler,
}, ref) => {

  const [rowModesModel, setRowModesModel] = useState({});
  const [rowData, setRowData] = useState(rows);
  useEffect(() => setRowData(rows), [rows]);
  const [rowForm, setRowForm] = useState({});
  const rowFormHandler = (value, key) => setRowForm((prev) => ({ ...prev, [key]: value }));

  const startEditing = (id, form) => {
    setRowModesModel((oldModel) => ({ [id]: { mode: GridRowModes.Edit } }));
    setRowForm(form);
  };

  const stopEditing = () => {
    const currForm = { ...rowForm };
    setRowModesModel((oldModel) => ({ [currForm?.id]: { mode: GridRowModes.View, ignoreModifications: true } }));
    setRowForm({});
  };

  const handleEditClick = (params) => {
    if (!isObjEmpty(rowForm)) {
      failureToast(saveOrDeleteMsg);
    } else {
      startEditing(params?.id, params?.row);
    }
  };

  const handleCancelClick = async (params) => {
    if (params?.row?.isNew) {
      setRowData(rows)
      setRowForm({});
    }
    else {
      stopEditing(params?.id)
    }
  }

  const handleSaveClick = async (params) => {
    if (rowForm?.isNew) {
      await createHandler(rowForm)
    } else {
      await updateHandler(rowForm)
    }
  }

  const addNewRow = async () => {
    if (!isObjEmpty(rowForm)) {
      failureToast(saveOrDeleteMsg)
    } else {
      let randomId = generateRandomId();
      let emptyRow = { ...newRow, isNew: true, id: randomId }
      startEditing(randomId, emptyRow);
      setRowData(prev => [
        ...prev?.slice(0, page * 50),
        emptyRow,
        ...prev?.slice(page * 50, prev?.length + 1)
      ])
    }
  }

  useImperativeHandle(ref, () => ({
    addNewRow: addNewRow,
    startEditing: startEditing,
    stopEditing: stopEditing
  }))

  const columns = [
    new AGridColDef('businessAreas', 'Business area', false).setMinWidth(120).editable(true).setFlex(0.5)
      .renderCellComponent(params => <MultiSelectCellComponentWithSearch title={'Business area'} data={params?.value} eyeIcon />)
      .renderEditCellComponent(params => (
        <div style={editCellContainerStyle}>
          <AAutoComplete
            currentValue={rowForm?.businessAreas}
            handleChange={value => rowFormHandler(value, 'businessAreas')}
            items={dropdown?.businessArea}
            isMulti={true}
            isShowCount={true}
            style={selectStyles}
            autoFocus={params?.hasFocus}
          />
        </div>
      )),
    new AGridColDef('courseId', 'Course', false).setMinWidth(120).editable(true).setFlex(0.5)
      .renderCellComponent(params => <div className='d-flex align-items-center'>{params?.value?.label}<CourseDetails className='color-primary' courseId={params?.value?.label} /></div>)
      .renderEditCellComponent(params => (
        <div style={editCellContainerStyle}>
          <AAutoComplete
            currentValue={rowForm?.courseId}
            handleChange={value => rowFormHandler(value, 'courseId')}
            items={dropdown?.courseId}
            isMulti={false}
            style={selectStyles}
            autoFocus={params?.hasFocus}
          />
        </div>
      )),
    new AGridColDef('materials', 'Material details', false).setMinWidth(220).editable(true)
      .renderCellComponent(params => (
        <MultiSelectCellComponentWithSearch title={'Material details'} data={params?.value} eyeIcon width={400} />
      ))
      .renderEditCellComponent(params => (
        <div style={editCellContainerStyle}>
          <SelectMaterialElasticSearch
            currentValue={rowForm?.materials}
            handleChange={value => rowFormHandler(value, 'materials')}
            isMulti={true}
            style={selectStyles}
            dropdownMappingParams={['materialIdAndDescription', 'materialId', 'materialDescription', 'serialNumberProfile']}
          />
        </div>
      )),
    new AGridColDef('status', 'Status', false).setMinWidth(120).setFlex(0.5)
      .renderCellComponent(params => <StatusCellComponent params={params} toggleStatusHandler={toggleStatusHandler} rowForm={rowForm} rowFormHandler={rowFormHandler} />),
    new AGridColDef('updatedOn', 'Updated on/by', false).setMinWidth(150).setFlex(0.5)
      .renderCellComponent(params => params?.value ? (
        <div>
          {moment(params?.value)?.format('DD-MMM-YYYY')}
          <Tooltip title={`${getFormattedDate(params?.value )},
            ${moment(params?.value ).format("LT")} by ${params?.row?.updatedBy}`}><span><IoEyeOutline className='ml-2 pointer' size={18} /></span></Tooltip>
        </div>
      ) : <div>-</div>),
    new AGridColDef('actions', 'Actions', false).setMinWidth(150).columnAlign('right').setFlex(0.5)
      .renderCellComponent(params => (
        <ActionsCellComponent
          params={params}
          handleEditClick={handleEditClick}
          handleCancelClick={handleCancelClick}
          handleSaveClick={handleSaveClick}
          rowForm={rowForm}
          deleteHandler={deleteHandler}
        />
      ))
  ]

  return (
    <div className='mt-3'>
      <ADataGrid
        removeWrapperContainer
        rowModesModel={rowModesModel}
        rows={rowData || []}
        columns={columns}
        rowId={(row) => row?.id}
        rowHeight={50}
        loading={false}
        serverPagination={false}
        currentPage={page}
        nextButtonHandler={onPageChange}
        components={{ ...gridComponents, NoRowsOverlay: () => <NoRowOverlay message={loader ? 'Loading...' : null} /> }}
        sx={{
          ...gridStyles
        }}
        autoHeight
      />
    </div>
  )
})

export default ListComponent