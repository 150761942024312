import React from "react";
import { Card, CardHeader, Input, Container, Button, Table, Row } from "reactstrap";
import { useState } from "react"
import { failureToast } from '../../common/utils/methods/toasterFunctions/function'
import { TDROW, MasterHeader } from "../../common/commonComponents/masterRow/masterRow";
import { masterServiceBaseUrl, successToast, putpost, putpost2, deleteAPI } from 'services/http'
import moment from 'moment';
import CustomButton from "../../../../../components/CustomButton";
import { ButtonText } from "variables/Buttons"
import { customValidation } from "../../common/utils/methods/validations/validation";
import validationRules from './validation.json'
import { fetchAllPostPromisedData, fetchDeletePromisedData, getCurrentDate } from "../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "../../common/constant";
import { editTableRow, newTableRow } from "../../common/utils/methods/commonMethods/tableMethods";
import { MasterHeaderAuto, Rows } from '../../common/commonComponents/masterHeader/masterHeader';

var _ = require('lodash');

const DepartmentTypeTable = (props) => {
  const { permissionSet, isSaveVisible, setisSaveVisible = () => { }, getAllrecords = () => { } } = props;
  const [tableDataKey, settableDataKey] = useState('tableDataKey')
  const addNewClass = () => {
    props.setclassArray([{ "editMode": true, "__status": "__new", "departmentTypeKey": null, 'status': 'ACTIVE', "departmentTypeDesc": null, "departmentTypeDispValue": null, "effectiveDate": null }, ...props.classArray])
  }


  const headerList = [
    { name: 'DEPARTMENT TYPE KEY' },
    { name: 'Effective Date' },
    { name: 'Description' },
    { name: 'Display Value' },
    { name: 'Status' },
    { name: 'Action' },
  ];
  // const saveAll = (cb) =>{
  //   var _tempArray = props.classArray
  //   var isValid = true
  //   _tempArray.forEach((el,index)=>{
  //     if(el['editMode']){
  //       if(el['departmentTypeKey'] === null || !el['departmentTypeKey'].trim()){
  //         isValid= false
  //         failureToast('Please enter the value of Department Type Key')
  //         return
  //       }else if(el['departmentTypeDesc'] === null || !el['departmentTypeDesc'].trim()){
  //         isValid= false
  //         failureToast('Please enter the value of Description')
  //         return
  //       }
  //       else if(el['departmentTypeDispValue'] === null || !el['departmentTypeDispValue'].trim()){
  //           isValid= false
  //           failureToast('Please enter the value of Display Value')
  //           return
  //         }
  //           if(!isValid){
  //             return
  //           }
  //         if (_tempArray[index]['__status'] == "__new") {
  //           putpost(masterServiceBaseUrl + '/departmentType/createDepartmentType', (data) => {
  //             successToast(data['message'])
  //             setisSaveVisible(false)
  //             props.fetchClasses(masterServiceBaseUrl + '/departmentType/getAllDepartmentType')
  //           }, (data) => { failureToast(data['message']) }, _tempArray[index], 'post')
  //         } else {
  //           putpost(masterServiceBaseUrl + '/departmentType/updateDepartmentType/' + _tempArray[index].id, (data) => {
  //             successToast(data['message'])
  //             setisSaveVisible(false)
  //             delete _tempArray[index]['editMode']
  //             props.setclassArray(_tempArray)
  //             settableDataKey(new Date().getMilliseconds())
  //             // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
  //           }, (data) => { failureToast(data['message']) }, _tempArray[index], 'put')
  //         }
  //       } else {

  //       }
  //     })
  // }

  const saveAll = async (index) => {
    let _data = props.classArray[index];
    // let alphanumericSpecialChar = /^[ A-Za-z0-9_@./#&+-]*$/
    let isValid = true;
    isValid = customValidation(_data, validationRules)

    if (!isValid) return;

    if (_data["__status"] == "__new") {
      const createRecord = await fetchAllPostPromisedData(endpoint.departmentType.create, _data, 'post')
      if (createRecord?.code === 200) {
        successToast(createRecord["message"]);
        setisSaveVisible(false);
        getAllrecords()
      }
    } else {
      const updateRecord = await fetchAllPostPromisedData(`${endpoint.departmentType.update}/${_data.id}`, _data, 'put');
      if (updateRecord?.code === 200) {
        successToast(updateRecord["message"]);
        setisSaveVisible(false);
        getAllrecords()
      }
    }
  }

  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      newTableRow(props.classArray, props.setclassArray, setisSaveVisible, addNewClass);
    }
  };

  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      editTableRow(index, props.classArray, props.setclassArray, setisSaveVisible);
    }
  };

  const deleteRow = async (index) => {
    if (window.confirm("Are you sure you want to delete this Department Type?")) {
      const deleteRecord = await fetchDeletePromisedData(`${endpoint.departmentType.delete}/${props.classArray[index].id}`);
      if (deleteRecord?.code === 200) {
        successToast(deleteRecord['message']);
        let flag = false;
        if (props.classArray.length == 1) {
          flag = true;
        }
        getAllrecords(flag);
        settableDataKey(new Date().getMilliseconds());
      }
    }
  };


  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray
    _tempArray[index][key] = value
    props.setclassArray(_tempArray)
  }
  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Department Type</h3>
                {/* <Button disabled={isSaveVisible}  color="info" size="sm" type="button" className={"floatRight mx-1"+(isSaveVisible?" btn-dark":null)} onClick={() => {newRow()}} data-testid="addNewDepartmentType">New Department Type</Button> */}
                {/* {isSaveVisible?<Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => {saveAll()}}>Save</Button>:null} */}
                <CustomButton
                  data-testid="addNewDepartmentType"
                  disabled={isSaveVisible}
                  className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
                  content={ButtonText.DepartmentType.NEW}
                  icon={true}
                  permissionType={'C'}
                  permissionSet={permissionSet}
                  onClick={() => newRow()}
                />
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout" >
                  <MasterHeaderAuto headerList={headerList} permissionSet={permissionSet} isSaveVisible={isSaveVisible} />
                  <tbody className="list" key={tableDataKey} >
                    {props.classArray ? props.classArray.map((el, index) =>
                      !el['editMode'] ? (
                        <TDROW type={"departmentType"} index={index} el={el} editRow={editRow} deleteRow={deleteRow} permissionSet={permissionSet} />
                      ) :
                        (<tr key={index + '-class'}>
                          <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['departmentTypeKey']} onChange={(e) => { updateKey(index, e.target.value, 'departmentTypeKey') }} placeholder="Example:1" className="tdInput" data-testid='departmentTypeKey' /></td>
                          <td className="text-center p-2 mx-1">
                            <Input disabled={true} defaultValue={el["__status"] == "__new" ? getCurrentDate() : moment(el['eff_date']).format('DD-MM-YYYY')} placeholder="Example:01-01-2020" className="tdInput" data-testid='effectiveDate' />
                          </td>
                          <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['departmentTypeDesc']} onChange={(e) => { updateKey(index, e.target.value, 'departmentTypeDesc') }} placeholder="Description" className="tdInput" data-testid='departmentTypeDesc' /></td>
                          <td className="text-center p-2 mx-1"><Input maxLength="50" defaultValue={el['departmentTypeDispValue']} onChange={(e) => { updateKey(index, e.target.value, 'departmentTypeDispValue') }} placeholder="Display Value" className="tdInput" data-testid='departmentTypeDispValue' /></td>
                          <td className="text-center p-2 mx-1">
                            <label className="custom-toggle mx-auto ml-2">
                              <input checked={el.status == "ACTIVE" ? true : false} type="checkbox" id={el.id} onChange={(e) => { settableDataKey(new Date().getMilliseconds()); let n = "ACTIVE"; if (!e.target.checked) { n = "INACTIVE" } updateKey(index, n, 'status') }}
                                data-testid='departmentTypeStatus' />
                              <span style={{ width: "72px" }} className="custom-toggle-slider rounded-circle activeToggle" data-label-off="Inactive" data-label-on="Active" />
                            </label>
                          </td>
                          <td className="text-center">
                            {/* <Button color="info" size="sm" type="button" onClick={() => {
                          deleteRow(index)
                        }}>  <i className="fas fa-trash" /></Button> */}
                            {isSaveVisible ?
                              <>
                                <CustomButton
                                  data-testId="saveDepartmentType"
                                  className={'mx-1'}
                                  content={ButtonText.DepartmentType.UPDATE}
                                  forTable={true}
                                  permissionType={'C,U'}
                                  permissionSet={permissionSet}
                                  onClick={() => { saveAll(index); }}
                                />

                                <CustomButton
                                  data-testId="closedDepartmentType"
                                  className={'mx-1'}
                                  permissionType={'cancel'}
                                  forTable={true}
                                  icon={true}
                                  permissionSet={permissionSet}
                                  onClick={() => {
                                    let n = props.classArray;
                                    if (n[index]["__status"] == "__new") {
                                      n.splice(index, 1);
                                    } else {
                                      // n[index]['editMode']=false
                                      getAllrecords();
                                    }
                                    setisSaveVisible(false);
                                  }}
                                />
                              </>
                              :
                              // <Button color="info" size="sm" type="button" onClick={() => {
                              //   deleteRow(index)
                              // }}>  <i className="fas fa-trash" /></Button>
                              <></>
                            }
                          </td>
                        </tr>)
                    ) : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
export default DepartmentTypeTable;