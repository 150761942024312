import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, Dialog, Tooltip } from '@mui/material';
import { Accordion } from '../commonComponents';
import StudentDetailsCard from './studentDetailsCard';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import ACopyToClipboard from '../ACopyToClipboard/ACopyToClipboard';
import CustomLoader from '../commonComponents/Loader/loader';
import CommonInput from '../formFeilds/input/commonInput';
import { AiOutlineEye } from "react-icons/ai";
import { endpoint } from '../constant';
import { fetchAllPromisedData } from '../utils/methods/commonMethods/utilityMethod';
import { failureToast } from '../utils/methods/toasterFunctions/function';
import CustomButton from '../../../../../components/CustomButton';
import moment from 'moment';
import closeIcon from 'assets/img/svg/closeSquare.svg';
import APopover from '../a-popover';
import { IoEyeOutline } from 'react-icons/io5';

export const CourseDetailsPopupByIdAndName=({courseId, courseName})=>{

  return(
    <APopover
    transformOriginHorizontal='center'
    anchorOriginHorizontal='center'
    highlightedStyle={{color: "#11cdef"}}
    normalStyle={{color: "#11cdef"}}
    
    buttonComponent={(onclick , styles) => (
      <AiOutlineEye style={styles} className="cursor" onClick={onclick} size={16} />
    )}
    menuComponent={closeForm => (   
      <div className='p-2 d-flex' style={{maxWidth:'500px'}}>
        <div className='semi-bold d-flex flex-column mr-3 flex-shrink-0'>
        <div >Course ID</div>
        <div className='mt-2'>{courseId || '-'}</div>
        </div>
      <div className='semi-bold d-flex flex-column'>
      <div >Course name</div>
      <div className='mt-2'>{courseName || '-'}</div>
      </div>
      </div>
    )}
  />
  )

}

const getFormatedDate = (date) => {
  return date ? moment(date).format('DD-MMM-YYYY') : '-'
}

const appCourseNullObj = {
  applicationId: '',
  opportunityId: '',
  applicationDate: '',
  course: { value: '', label: '-' },
  academicCareer: { value: '', label: '-' },
  courseCategory: { value: '', label: '-' },
  term: { value: '', label: '-' },
  businessArea: { value: '', label: '-' },
  registrationPaymentDate: '',
  chargeStatus: '',
  paymentStatus: '',
  baseFees: '',
  totalCourseFees: ''
};
export const paymentStatus = {
  "not paid": { backgroundColor: '#FFEDC8', color: '#705010', padding: '4px 11px', fontSize: '12px', width: 'inherit', borderRadius: '5px' },
  "partially paid": { backgroundColor: '#FFEDC8', color: '#705010', padding: '4px 11px', fontSize: '12px', width: 'inherit', borderRadius: '5px' },
  "fully paid": { backgroundColor: '#FFEDC8', color: '#705010', padding: '4px 11px', fontSize: '12px', width: 'inherit', borderRadius: '5px' },
}
export const chargesStatus = {
  PROV: { backgroundColor: '#FFEDC8', color: '#705010', padding: '4px 11px', fontSize: '12px', borderRadius: '5px' },
  CONC: { backgroundColor: '#CCEFFD', color: '#006A93', padding: '4px 11px', fontSize: '12px', borderRadius: '5px' },
  APPL: { backgroundColor: '#BBE2CE', color: '#0B442D', padding: '4px 11px', fontSize: '12px', borderRadius: '5px' },
  PAPP: { backgroundColor: '#FFEDC8', color: '#705010', padding: '4px 11px', fontSize: '12px', borderRadius: '5px' },
  VERY: { backgroundColor: '#FFEDC8', color: '#705010', padding: '4px 11px', fontSize: '12px', borderRadius: '5px' },
  MODY: { backgroundColor: '#CCEFFD', color: '#006A93', padding: '4px 11px', fontSize: '12px', borderRadius: '5px' },
  CANC: { backgroundColor: '#CCEFFD', color: '#006A93', padding: '4px 11px', fontSize: '12px', borderRadius: '5px' },
}
const BasicDetails = ({ applicationDetails, academicCareer, courseCategory, term, businessAreas, courses, appCourseDetailsLoading, isOpen, setIsOpen, isRequired = true,basicDeatilVerified, handleConfirmVerfyBasicDetail, disableActions,
  pastIdSelected = false,
  chargeStatus }) => {

  const [appCourseDetails, setappCourseDetails] = useState(appCourseNullObj);
  const studentDetails = useMemo(() => ({
    psid: applicationDetails.psid,
    studentName: applicationDetails.studentName,
    phoneNo: applicationDetails.phoneNo,
    email: applicationDetails.email,
    loading: applicationDetails.loading
  }), [applicationDetails]);

  const [isReadOnly, setIsReadOnly] = useState(true);
  const [detailsOpen, setDetailsOpen] = useState(false);

  useEffect(() => {
    if (applicationDetails && academicCareer && courseCategory && term && businessAreas && courses) {
      const vals = {
        applicationId: applicationDetails.applicationId,
        opportunityId: applicationDetails.opportunityId,
        applicationDate: applicationDetails.applicationDate,
        course: courses.find(item => item.value === applicationDetails.courseId) || appCourseNullObj.course,
        academicCareer: applicationDetails?.academicCareerValue ?? (academicCareer.find(item => item.value === applicationDetails.academicCareerId) || appCourseNullObj.academicCareer),
        courseCategory: applicationDetails?.courseCategoryValue ?? (courseCategory.find(item => item.value === applicationDetails.courseCategoryId) || appCourseNullObj.courseCategory),
        term: applicationDetails?.termValue ?? (term.find(item => item.value === applicationDetails.termId) || appCourseNullObj.term),
        businessArea: businessAreas.find(item => item.value === applicationDetails.businessAreaId) || appCourseNullObj.businessArea,
        registrationPaymentDate: applicationDetails.registrationPaymentDate,
        chargeStatus: applicationDetails.chargeStatus,
        paymentStatus: applicationDetails.paymentStatus,
        baseFees: applicationDetails.baseFees?.toFixed(2) ?? '',
        totalCourseFees: applicationDetails.totalCourseFees?.toFixed(2) ?? '',
      };
      setappCourseDetails(vals);
    }
  }, [applicationDetails, academicCareer, courseCategory, term, businessAreas, courses]);

  const onChange = (key, val) => {
    setappCourseDetails({ ...appCourseDetails, [key]: val });
  }

  const defaultViewComp = <Row>
    <Col md="3">
      <CommonInput
        type="number"
        defaultValue={studentDetails.psid}
        label="PSID"
        readOnly={true}
      />
    </Col>
    <Col md="3">
      <CommonInput
        type="text"
        defaultValue={studentDetails.studentName}
        label="Name"
        readOnly={true}
      />
    </Col>
    <Col md="3">
      <CommonInput
        type="select"
        mandatory={true}
        defaultValue={appCourseDetails.course}
        onChange={(val) => { onChange("course", val) }}
        data={courses}
        label="Course ID"
        readOnly={true}
        readOnlyComp={
          <h3 className='tooltip-text'>
            <span className='text-ellipsis'>{`${appCourseDetails.course?.value} | ${appCourseDetails.course?.label}`}</span>
            {/* {appCourseDetails.course &&
              <Tooltip title={`${appCourseDetails.course?.value} | ${appCourseDetails.course?.label}`}>
                <span style={{ cursor: 'pointer' }}>
                  <AiOutlineEye className='ml-1' size={16} color='#11cdef' />
                </span>
              </Tooltip>
            } */}
            <CourseDetailsPopupByIdAndName courseId={appCourseDetails?.course?.value} courseName={appCourseDetails?.course?.label}/>
          </h3>
        }
      />
    </Col>
    <Col md="3">
      <CommonInput
        type="datePicker"
        label="Application date"
        defaultValue={appCourseDetails.applicationDate}
        readOnly={true}
      />
    </Col>
  </Row>;

  return (
    <>
      <Accordion
        isFormOpen={isOpen.detailsTab}
        title={isOpen.detailsTab?`Basic details`:<>Basic details <span style={chargesStatus[pastIdSelected ? chargeStatus : appCourseDetails.chargeStatus] ?? {}}>{pastIdSelected ? chargeStatus : appCourseDetails.chargeStatus ?? '-'}</span></>}
        key={'Basic_Details'}
        defaultViewComp={defaultViewComp}
        isEditFormOpen={(open) => {
          open === false ?
            setIsOpen(state => ({ ...state, detailsTab: false })) :
            setIsOpen({
              detailsTab: true,
              paymentTab: false,
              concessionsTab: false,
              finalTab: false
            })
        }}
      >
        <StudentDetailsCard details={studentDetails} />
        <Card className='application-course-details'>
          <CardHeader>
            <h3>Application & Course details</h3>
          </CardHeader>
          <CardBody>
            {(appCourseDetailsLoading || applicationDetails.loading) ? <CustomLoader /> : <>
              <div className='details-row'>
                <div>
                  <CommonInput
                    type="number"
                    mandatory={true}
                    defaultValue={appCourseDetails.applicationId}
                    onChange={(val) => { onChange("applicationId", val) }}
                    label="Application ID"
                    readOnly={isReadOnly}
                    readOnlyComp={
                      <h3>
                        {appCourseDetails.applicationId}
                        {appCourseDetails.applicationId && <ACopyToClipboard text={appCourseDetails.applicationId} />}
                      </h3>
                    }
                  />
                </div>
                <div>
                  <CommonInput
                    type="number"
                    mandatory={true}
                    defaultValue={appCourseDetails.opportunityId}
                    onChange={(val) => { onChange("opportunityId", val) }}
                    label="Opportunity Id"
                    readOnly={isReadOnly}
                    readOnlyComp={
                      <h3>
                        {appCourseDetails.opportunityId ?? "-"}
                        {appCourseDetails.opportunityId && <ACopyToClipboard text={appCourseDetails.opportunityId} />}
                      </h3>
                    }
                  />
                </div>
                <div>
                  <CommonInput
                    type="datePicker"
                    // mandatory={true}
                    label="Application date"
                    defaultValue={appCourseDetails.applicationDate}
                    onChange={(val) => { onChange("applicationDate", val) }}
                    readOnly={isReadOnly}
                    placeHolder=""
                    readOnlyComp={
                      <h3>
                        {getFormatedDate(appCourseDetails.applicationDate)}
                      </h3>
                    }
                  />
                </div>
                <div className="course-col-w-20">
                  <CommonInput
                    type="select"
                    mandatory={true}
                    defaultValue={appCourseDetails.course}
                    onChange={(val) => { onChange("course", val) }}
                    data={courses}
                    label="Course ID"
                    readOnly={isReadOnly}
                    readOnlyComp={
                      <h3 className='tooltip-text'>
                        <span className='text-ellipsis'>{`${appCourseDetails.course?.value} | ${appCourseDetails.course?.label}`}</span>
                        {/* {appCourseDetails.course &&
                          <Tooltip title={`${appCourseDetails.course?.value} | ${appCourseDetails.course?.label}`}>
                            <span style={{ cursor: 'pointer' }}>
                              <AiOutlineEye className='ml-1' size={16} color='#11cdef' />
                            </span>
                          </Tooltip>
                        } */}
                   <CourseDetailsPopupByIdAndName courseId={appCourseDetails?.course?.value} courseName={appCourseDetails?.course?.label}/>
                      </h3>
                    }
                  />
                </div>
              </div>

              <div className='details-row'>
                <div>
                  <CommonInput
                    type="select"
                    mandatory={true}
                    defaultValue={appCourseDetails.academicCareer}
                    onChange={(val) => { onChange("academicCareer", val) }}
                    data={academicCareer}
                    label="Career"
                    readOnly={isReadOnly}
                  />
                </div>
                <div>
                  <CommonInput
                    type="select"
                    mandatory={true}
                    defaultValue={appCourseDetails.courseCategory}
                    onChange={(val) => { onChange("courseCategory", val) }}
                    data={courseCategory}
                    label="Course Category"
                    readOnly={isReadOnly}
                  />
                </div>
                <div>
                  <CommonInput
                    type="select"
                    mandatory={true}
                    defaultValue={appCourseDetails.term}
                    onChange={(val) => { onChange("term", val) }}
                    data={term}
                    label="Term"
                    readOnly={isReadOnly}
                  />
                </div>
                <div className='course-col-w-15'>
                  <CommonInput
                    type="select"
                    mandatory={true}
                    defaultValue={appCourseDetails.businessArea}
                    onChange={(val) => { onChange("businessArea", val) }}
                    data={businessAreas}
                    label="Business area"
                    readOnly={isReadOnly}
                    readOnlyComp={
                      <h3 className='tooltip-text'>
                        <span className='text-ellipsis'>{appCourseDetails.businessArea.label}</span>
                        {appCourseDetails.businessArea &&
                          <Tooltip title={appCourseDetails?.businessArea?.desc}>
                            <span style={{ cursor: 'pointer' }}>
                              <AiOutlineEye className='ml-1' size={16} color='#11cdef' />
                            </span>
                          </Tooltip>
                        }
                      </h3>
                    }
                  />
                </div>
                <div className='course-col-w-25'>
                  <CommonInput
                    type="datePicker"
                    // mandatory={true}
                    label="Registration payment date"
                    defaultValue={appCourseDetails.registrationPaymentDate}
                    onChange={(val) => { onChange("registrationPaymentDate", val) }}
                    readOnly={isReadOnly}
                    placeHolder=" "
                    readOnlyComp={
                      <h3>
                        {getFormatedDate(appCourseDetails.registrationPaymentDate)}
                      </h3>
                    }
                  />
                </div>
              </div>

              <div className='details-row'>
                <div>
                  <CommonInput
                    type="text"
                    mandatory={true}
                    defaultValue={pastIdSelected ? chargeStatus : appCourseDetails.chargeStatus}
                    onChange={(val) => { onChange("chargeStatus", val) }}
                    label="Charge status"
                    readOnly={isReadOnly}
                    readOnlyComp={<><br /><span style={chargesStatus[appCourseDetails.chargeStatus] ?? {}}>{pastIdSelected ? chargeStatus : appCourseDetails.chargeStatus ?? '-'}</span></>}
                  />
                </div>
                {/* <div>
                  <CommonInput
                    type="text"
                    mandatory={true}
                    defaultValue={appCourseDetails.paymentStatus}
                    onChange={(val) => { onChange("paymentStatus", val) }}
                    label="Payment status"
                    readOnly={isReadOnly}
                    readOnlyComp={<><br /><span style={paymentStatus[appCourseDetails.paymentStatus?.toLowerCase()] ?? {}}>{appCourseDetails.paymentStatus ?? '-'}</span></>}
                  />
                </div> */}
                {isRequired &&
                  <>
                    <div>
                      <CommonInput
                        type="number"
                        mandatory={true}
                        defaultValue={appCourseDetails.baseFees}
                        onChange={(val) => { onChange("baseFees", val) }}
                        label="Course fees(Base Fee)"
                        readOnly={isReadOnly}
                        readOnlyComp={<h3>{appCourseDetails.baseFees ? '₹ ' + appCourseDetails.baseFees : '-'}
                        </h3>}
                      />
                    </div>
                    {/* <div className="course-col-w-20">
                      <CommonInput
                        type="number"
                        mandatory={true}
                        defaultValue={appCourseDetails.totalCourseFees}
                        onChange={(val) => { onChange("totalCourseFees", val) }}
                        label="Total course fees (Inc. taxes)"
                        readOnly={isReadOnly}
                        readOnlyComp={<h3>{appCourseDetails.totalCourseFees ? '₹ ' + appCourseDetails.totalCourseFees : '-'}</h3>}
                      />
                    </div> */}
                  </>
                }
              </div>
              {isRequired &&
                <CustomButton
                  content={"Verify details"}
                  permissionType={'C'}
                  permissionSet={'C'}
                  onClick={() => { setDetailsOpen(true) }}
                  className={'br-8 mb-10 mt-20 vbtn-padding regular-large-bold'}
                  disabled={basicDeatilVerified || disableActions}
              />}
              </>}
          </CardBody>
        </Card>
      </Accordion>
      {<Dialog open={detailsOpen}>
        <Box className='concession-approval-dialog-content approval-details basic-details' width={480}>
          <Box className='approval-dialog-header' style={{ marginBottom: '4px' }}>
            <Typography variant='h5' className='large-bolder color-black'>Verify details</Typography>
            <div onClick={() => setDetailsOpen(false)}><img src={closeIcon} /></div>
          </Box>
          <Typography variant='body1' className='regular-large-bold mb-20 black-grey'>Please confirm given details</Typography>
          <Box>
            <Row>
              <Col md="5">
                <h5 className='regular-small label-color'>PSID</h5>
                <p className='regular-large-bold color-dark'>{studentDetails?.psid}</p>
              </Col>
              <Col md="5">
                <h5 className='regular-small label-color'>Name</h5>
                <p className='regular-large-bold color-dark'>{studentDetails?.studentName === " " || null ? '-' : studentDetails?.studentName}</p>
              </Col>
              <Col md="5">
                <h5 className='regular-small label-color'>Phone number</h5>
                <p className='regular-large-bold color-dark'>{studentDetails?.phoneNo === " " || studentDetails?.phoneNo === null ? '-' : studentDetails?.phoneNo}</p>
              </Col> 
              <Col md="5">
                <h5 className='regular-small label-color'>Email</h5>
                <p className='regular-large-bold color-dark'>{studentDetails?.email === null || studentDetails?.email === " " ? '-' : studentDetails?.email}</p>
              </Col>
              <Col md="5">
                <CustomButton
                  content={"Go back"}
                  permissionType={'C'}
                  permissionSet={'C'}
                  onClick={() => { setDetailsOpen(false) }}
                  className={'br-8 btn-padding goback-button color-primary regular-large-bold button_remove_left_margin'}
                  
                />
              </Col>
              <Col md="5">
                <CustomButton
                  content={"Confirm"}
                  type={'C'}
                  permissionType={'C'}
                  permissionSet={'C'}
                  onClick={() => { setDetailsOpen(false);handleConfirmVerfyBasicDetail(); }}
                  className={'br-8 btn-padding regular-large-bold button_remove_left_margin'}
                />
              </Col>
            </Row>
          </Box>
        </Box>
      </Dialog>}
    </>
  )
}

export default BasicDetails;