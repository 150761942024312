import React from "react";
import { ReactComponent as BackIcon } from "assets/img/svg/BackButton.svg";
import { headerStyle } from "../stockTxns/style";
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'

const ScreenTopHeader = ({ goBackHandler = () => {}, labelText }) => {
  return (
    <div
      className="d-flex heading-4 color-dark align-items-center justify-content-between"
      style={headerStyle}
    >
      <div className="d-flex align-items-center">
        <IconButtonWrapper onClick={goBackHandler}><BackIcon /></IconButtonWrapper>
        <div className="">{labelText}</div>
      </div>
    </div>
  );
};

export default ScreenTopHeader;
