import React from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { ReactComponent as CloseIcon } from "assets/img/svg/CloseIcon.svg";
import DocPreview from "../../../unAssigned/concessionApproval/docPreview";

const ViewDocumentModal = ({ isOpen, setIsOpen }) => {
  const onCloseReset = () => {
    setIsOpen(false);
  };

  return (
      <Modal
        size="lg"
        isOpen={isOpen}
        toggle={onCloseReset}
        style={{ top:"20%"}}
      >
        <ModalBody>
          <CloseIcon onClick={onCloseReset}/>
          <DocPreview fileUrl={isOpen} fileType="pdf" />
        </ModalBody>
      </Modal>
  );
};

export default ViewDocumentModal;
