import React, { useEffect, useState } from "react";
import { ReactComponent as BackIcon } from "assets/img/svg/BackButton.svg";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx";
import TransactionTable from "./TransactionTable";

import styles from "./styles.module.css";
import {
  fetchAllPostPromisedData,
  fetchAllPromisedData,
} from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import ALoader from "views/pages/manage/common/a-loader";
import { endpoint } from "views/pages/manage/common/constant";
import { successToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import moment from "moment";

const BranchReconcilliation = () => {
  const {
    getFailedTransactions,
    editUnfreezeBarcodes,
    getTransactionMaterialDetails,
  } = endpoint.barcodeReconciliation;

  const [isLoading, setIsLoading] = useState(false);

  const [currentPageOnTable, setCurrentPageOnTable] = useState(0); //page
  const [totalRows, setTotalRows] = useState(0); //rowCount
  const [transactionList, setTransactionList] = useState([]); //rows

  useEffect(() => {
    //  fetch list API & store setTransactionList()
    fetchFailedTransactionList();
  }, [currentPageOnTable]);

  const fetchFailedTransactionList = async () => {
    setIsLoading(true);
    const response = await fetchAllPostPromisedData(
      `${getFailedTransactions}?offset=${currentPageOnTable}`,
      {},
      "post",
      true
    );
    if (response?.code === 200) {
      const responseList = response.data.searchReconciliationDataDTOList;
      setTotalRows(response.data.totalRecords);

      //  add sapDocId='' & materialDetailsDTOList=[], convert transactionDate
      const updatedList = responseList.map((item) => ({
        ...item,
        materialDetailsDTOList: [],
        sapDocId: "",
        transactionDate: moment(item.transactionDate).format(
          "YYYY-MM-DD HH:mm"
        ),
      }));
      setTransactionList(updatedList);
    } else {
      setTotalRows(0);
      setTransactionList([]);
    }

    setIsLoading(false);
  };

  const fetchNextPageRows = (pageNum) => {
    setCurrentPageOnTable(pageNum);
  };

  const fetchMaterialsInfo = async (entry) => {
    const { transactionId, businessAreaId } = entry;

    const url = `${getTransactionMaterialDetails}?businessArea=${businessAreaId}&transactionId=${transactionId}`;
    const response = await fetchAllPromisedData(url);

    if (response) {
      const gatherMaterialDetails = response.flatMap(
        (list) => list.materialDetails
      );

      const updatedList = transactionList.map((item) =>
        item.transactionId === entry.transactionId
          ? { ...item, materialDetailsDTOList: gatherMaterialDetails }
          : item
      );

      setTransactionList(updatedList);
    }
  };

  const unfreezeData = (entry) => {
    const { transactionId, businessAreaId } = entry;
    setIsLoading(true);

    const payload = {
      actionType: "UNFREEZE",
      reconciliationDTOList: [
        {
          transactionId,
          businessAreaId,
          sapDocId: null,
        },
      ],
    };

    // call API to unfreeze an entry
    removeFailedTransaction(payload);
  };

  const editSAPdocNum = (entry) => {
    const { transactionId, businessAreaId, sapDocId } = entry;
    setIsLoading(true);
    // call API to edit SAP doc num of an entry
    const payload = {
      actionType: "EDITSAPDOC",
      reconciliationDTOList: [
        {
          transactionId,
          businessAreaId,
          sapDocId,
        },
      ],
    };

    removeFailedTransaction(payload);
  };

  const removeFailedTransaction = async (payload) => {
    const response = await fetchAllPostPromisedData(
      `${editUnfreezeBarcodes}`,
      payload,
      "post",
      true
    );

    setIsLoading(false);

    // if API is successful -> update `transactionList`
    // else display error toast
    if (response?.code === 200) {
      const updatedList = transactionList.filter(
        (item) =>
          item.transactionId !== payload.reconciliationDTOList[0].transactionId
      );

      setTransactionList(updatedList);
      setTotalRows(totalRows - payload.reconciliationDTOList.length);
      successToast(response?.message);
    }
  };

  const handleBackClick = () => {
    window.close();
  };

  return (
    <div>
      {/* header */}
      <div
        className={`d-flex heading-4 color-dark align-items-center justify-content-between ${styles.headerStyle}`}
      >
        <div className="d-flex align-items-center">
          <IconButtonWrapper onClick={handleBackClick}>
            <BackIcon />
          </IconButtonWrapper>
          <div>Barcode Reconciliation </div>
        </div>
      </div>

      {/* container details */}
      <div className={styles.container}>
        {isLoading && <ALoader position={"fixed"} />}

        <div className={styles?.table}>
          <TransactionTable
            transactionList={transactionList}
            unfreezeData={unfreezeData}
            editSAPdocNum={editSAPdocNum}
            totalRows={totalRows}
            currentPageOnTable={currentPageOnTable}
            nextButtonHandler={fetchNextPageRows}
            fetchMaterialsInfo={fetchMaterialsInfo}
          />
        </div>
      </div>
    </div>
  );
};

export default BranchReconcilliation;
