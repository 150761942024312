import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Button, Card, CardBody, Input, Table } from 'reactstrap';
import Select2 from 'react-select2-wrapper';
import moment from 'moment';
import {
	concessionServiceBaseUrl,
	failureToast,
	putpost,
	putpost2,
	successToast,
} from '../../../../../../../services/http';
import { MasterHeaderAuto } from '../../../../common/commonComponents/masterHeader/masterHeader';
import {
	getDispValForReactSelectByOneID,
	NewRow,
} from '../../../../common/utils/methods/commonMethods/utilityMethod';
import CustomDatePicker from '../../../../common/commonComponents/CustomDatePicker/index';

var _ = require('lodash');
const SubCategoryTable = (props) => {
	const {
		scholarshipId,
		subCategoryList,
		subCategory,
		setSubCategory = () => {},
		isSaveVisible,
		setIsSaveVisible = () => {},
		termData,
		getDataAfterId = () => {},
	} = props;
	const [subCategoryKey, setSubCategoryKey] = useState(null);
	const [status, setStatus] = useState('ACTIVE');
	const addNewClass = () => {
		setSubCategory([
			{
				subCategoryMasterId: null,
				status: null,
				updatedDate: moment().format('DD-MM-YYYY'),
				isEdit: false,
				isNew: true,
			},
			...subCategory,
		]);
	};
	const headerList = [
		{ name: 'Sub Category KEy & Description', rowSpan: 2 },
		{ name: 'Short Description' },
		{ name: 'Sub Sub Catory Count' },
		{ name: 'Updated Date' },
		{ name: 'Status' },
		{ name: 'Add Sub Sub Category' },
		{ name: 'Action' },
	];

	const newRow = () => {
		let cb = () => {
			addNewClass();
			setIsSaveVisible(true);
		};
		let _tempArray = subCategory;
		_tempArray = _.sortBy(_tempArray, [
			function (o) {
				return o.value;
			},
		]);
		setSubCategory(_tempArray);
		setIsSaveVisible(false);
		cb();
	};
	const editRow = (index) => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			setIsSaveVisible(true);
			let tempData = subCategory;
			tempData[index]['isEdit'] = true;
			setStatus(tempData[index]['status']);
			setSubCategoryKey(tempData[index]['subCategoryMasterId']);
		}
	};
	const deleteRow = (index) => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let tempData = subCategory;
			let id = tempData[index]['id'];

			// id is used when api ready

			if (window.confirm('Are you sure you want to delete this Term?')) {
				putpost2(
					concessionServiceBaseUrl + '/addSubCategory/' + id,
					(data) => {
						successToast(data.message);
						resetFields();
						getDataAfterId();
					},
					(data) => {
						failureToast(data.message);
					},
					'Delete'
				);
			}
		}
	};

	const saveRow = (index) => {
		let tempData = subCategory;
		let isValid = true;
		if (!subCategoryKey) {
			failureToast('Please Select Sub Category');
			isValid = false;
		} else {
			if (tempData[index]['isEdit']) {
				let tempObject = {
					...tempData[index],
					subCategoryMasterId: subCategoryKey,
					status: status,
				};
				tempData[index] = tempObject;
				setSubCategory(tempData);
				let id = tempData[index]['id'];
				// id is used when API is ready.....

				//  url ='tempUrl';
				// putPostApi(`${concessionServiceBaseUrl}/addSubCategory`,tempObject,'post');
				putpost(
					`${concessionServiceBaseUrl}/addSubCategory/${id}`,
					(data) => {
						successToast(data.message);
						setIsSaveVisible(false);
						resetFields();
						setIsSaveVisible(false);
						tempData[index]['isEdit'] = false;
						resetFields();
						getDataAfterId();
					},
					(data) => {
						failureToast(data.message);
					},
					tempObject,
					'put'
				);
			} else if (tempData[index]['isNew']) {
				// putPostApi(`${concessionServiceBaseUrl}/addSubCategory`,tempObject,'post');
				// setIsSaveVisible(false);
				// resetFields()
				let tempObject = {
					subCategoryMasterId: subCategoryKey,
					scholarshipId: scholarshipId,
					status: status,
					updatedDate: moment().format('DD-MM-YYYY'),
					isEdit: false,
					isNew: false,
				};

				putpost(
					`${concessionServiceBaseUrl}/addSubCategory`,
					(data) => {
						successToast(data.message);
						tempData.push(tempObject);
						setSubCategory(tempData);
						setIsSaveVisible(false);
						tempData[index]['isNew'] = false;
						resetFields();
						getDataAfterId();
					},
					(data) => {
						failureToast(data.message);
					},
					tempObject,
					'post'
				);

				// putPostApi(`${concessionServiceBaseUrl}/addSubCategory`,tempObject,'post');
			}
		}
	};

	const cancelRow = (index) => {
		let tempData = subCategory;
		if (tempData[index]['isEdit']) tempData[index]['isEdit'] = false;
		else if (tempData[index]['isNew']) tempData.splice(index, 1);
		setSubCategory(tempData);
		resetFields();
		getDataAfterId();
	};

	const resetFields = () => {
		setSubCategoryKey(null);
		setStatus('ACTIVE');
		setIsSaveVisible(false);
	};

	const TDROW = (props) => {
		const { data, index } = props;
		return (
			<tr>
				<td className="text-center p-2 mx-1">
					{subCategoryList &&
						getDispValForReactSelectByOneID(
							data['subCategoryMasterId'],
							'DispValue',
							subCategoryList
						)}
				</td>
				<td className="text-center p-2 mx-1">
					{subCategoryList &&
						getDispValForReactSelectByOneID(
							data['subCategoryMasterId'],
							'DescValue',
							subCategoryList
						)}
				</td>
				<td className="text-center p-2 mx-1">
					{data['subSubCategoryCount'] || '00'}
				</td>
				<td className="text-center p-2 mx-1">
					{moment(data['updatedOn']).format('DD-MM-YYYY')}
				</td>
				<td className="text-center p-2 mx-1">
					{data.status === 'ACTIVE' ? (
						<Button color="success" size={'sm'} disabled={true}>
							Active
						</Button>
					) : (
						<Button color="danger" size={'sm'} disabled={true}>
							Inactive
						</Button>
					)}
				</td>
				<td className="text-center p-2 mx-1">
					<span style={{ textAlign: 'center' }}>
						<Button
							color={data['subSubCategoryCount'] ? 'primary' : 'info'}
							disabled={isSaveVisible}
							size="sm"
							type="button"
							className={' mx-1' + (props.isEdit ? ' btn-dark' : null)}
							onClick={() =>
								props.history.push(
									`/admin/scholarship/subCategory/addSubSubCategory/${data['id']}`
								)
							}
						>
							Add Sub Sub Category
						</Button>
					</span>
				</td>
				<td className="text-center p-2 mx-1">
					<Button
						color="info"
						size="sm"
						type="button"
						className={' mx-1'}
						onClick={() => {
							editRow(index);
						}}
					>
						<i className="fas fa-edit" />
					</Button>
					<Button
						color="info"
						size="sm"
						type="button"
						className={' mx-1'}
						onClick={() => deleteRow(index)}
					>
						<i className="fas fa-trash" />
					</Button>
				</td>
			</tr>
		);
	};

	// const getShortDescription = (id) => {
	//     const i = subCategoryList.filter(item=> {return item.id == id})
	//     return i && i.length ? i[0] : null
	// }

	const NewOrEditRow = (props) => {
		const { data, isSaveVisible, index, termData } = props;
		return (
			<tr key={data['id'] + '-class'}>
				<td className="text-center p-2 mx-1">
					{data['isEdit'] ? (
						<Input
							defaultValue={
								subCategoryKey &&
								getDispValForReactSelectByOneID(
									subCategoryKey,
									'DispValue',
									subCategoryList
								)
							}
							disabled
							style={{ height: '38px' }}
						/>
					) : (
						<Select2
							defaultValue={subCategoryKey}
							data={subCategoryList}
							onChange={(e) => {
								setSubCategoryKey(e.target.value);
							}}
							options={{ placeholder: 'Please Select Sub Category' }}
						/>
					)}
				</td>
				<td className="text-center p-2 mx-1">
					{' '}
					<Input
						defaultValue={
							subCategoryKey &&
							getDispValForReactSelectByOneID(
								subCategoryKey,
								'DescValue',
								subCategoryList
							)
						}
						disabled
						style={{ height: '38px' }}
					/>{' '}
				</td>
				<td className="text-center p-2 mx-1">
					{' '}
					{data['subSubCategoryCount'] || '00'}{' '}
				</td>
				<td className="text-center p-2 mx-1">
					{' '}
					<CustomDatePicker 
						disabled={true}
						value={
						data["__status"] == "__new"
							? null
							: moment(data["eff_date"]).toDate()
						}
						placeholder="Example:01-01-2020"
					/>
					{/* <Input
						disabled
						style={{ height: '38px' }}
						defaultValue={moment(data['updatedOn']).format('DD-MM-YYYY')}
					/> */}
					{' '}
				</td>
				<td className="text-center p-2 mx-1">
					<label className="custom-toggle mx-auto ml-2">
						<input
							checked={status === 'ACTIVE'}
							type="checkbox"
							id={'status'}
							onChange={(e) => {
								let n = 'ACTIVE';
								if (!e.target.checked) n = 'INACTIVE';
								setStatus(n);
							}}
						/>
						<span
							style={{ width: '72px' }}
							className="custom-toggle-slider rounded-circle activeToggle"
							data-label-off="Inactive"
							data-label-on="Active"
						/>
					</label>
				</td>
				<td className="text-center p-2 mx-1">
					<Button
						size="sm"
						type="button"
						className={' mx-1' + (isSaveVisible ? ' btn-dark' : null)}
						disabled={isSaveVisible}
					>
						Add Sub Sub Category
					</Button>
				</td>
				<td className="text-center p-2 mx-1">
					<Button
						color="info"
						size="sm"
						type="button"
						className="mx-1"
						onClick={() => saveRow(index)}
					>
						Save
					</Button>
					<Button
						color="danger"
						size="sm"
						type="button"
						onClick={() => {
							cancelRow(index);
						}}
					>
						<i className="fas fa-times" />
					</Button>
				</td>
			</tr>
		);
	};


	return (
		<Card
			key={'SetupBUTable'}
			style={{ marginBottom: '12px', marginLeft: '20px', marginRight: '20px' }}
		>
			<CardBody style={{ paddingBottom: 0 }}>
				<div
					className="floatRight "
					style={{
						display: 'flex',
						alignItems: 'center',
						marginBottom: '10px',
					}}
				>
					Search :
					<Input
						className={'tdInput ml-3 mr-3 customInputWidth floatRight'}
						style={{ display: 'inline' }}
						placeholder={'Search'}
					/>
					<Button
						disabled={props.isSaveVisible}
						color="info"
						size="sm"
						type="button"
						className={
							'floatRight mx-1' + (props.isSaveVisible ? ' btn-dark' : null)
						}
						onClick={newRow}
					>
						Add Sub Category
					</Button>
				</div>
			</CardBody>
			<div id="questionTable" className="table-responsive p-3">
				<Table className="align-items-center table-flush ">
					<MasterHeaderAuto headerList={headerList} />
					<tbody>
						{subCategory.map((data, index) =>
							data['isEdit'] || data['isNew'] ? (
								<NewOrEditRow
									key={'NewOrEditRow' + index}
									data={data}
									isSaveVisible={true}
									termData={termData}
									index={index}
								/>
							) : (
								<TDROW
									key={'TDROW' + index}
									data={data}
									index={index}
									history={props.history}
								/>
							)
						)}
					</tbody>
				</Table>
			</div>
		</Card>
	);
};

export default withRouter(SubCategoryTable);
