import { enrollmentServiceBaseUrl } from "../../../../../../services/http";
import { putpost, uploadpost } from "../../../../../../services/utils";
import { endpoint } from "../../../common/constant";
import {
  fetchAllPostPromisedData,
  getUniqueKey,
} from "../../../common/utils/methods/commonMethods/utilityMethod";
import {
  failureToast,
  successToast,
} from "../../../common/utils/methods/toasterFunctions/function";

export const getFileUrl = async (key) => {
  try {
    const response = await fetchAllPostPromisedData(
      `${endpoint.fetchS3Url.getDownloadUrl}?key=${key}`,
      true
    );
    if (response.code === 200) {
      return response.data.url;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

const dataToServer = {
  bulk: false,
  fileName: null,
  keyValue: `consession_${getUniqueKey()}`,
  serviceName: "STUDENT_PHOTO",
};

export const getFilePath = (name, file, setLoading, cb) => {
  try {
    setLoading(true);
    const formData = {
      ...dataToServer,
      fileName: name,
      docType: "jpeg",
    };

    putpost(
      endpoint.fetchS3Url.getUrl,
      (data) => {
        if (data.code === 200 && data.data) {
          saveDataToStorage(data.data, file, setLoading, name, cb);
        }
      },
      (data) => {
        failureToast(data["message"]);
        setLoading(false);
      },
      formData,
      "post"
    );
  } catch (e) {
    console.log(e);
    setLoading(false);
  }
};
const saveDataToStorage = (res, file, setLoading, name, saveHandler) => {
  try {
    const formData = new FormData();

    formData.append("File", file);
    uploadpost(
      res.storage,
      (data) => {
        saveHandler({
          ...res,
        });
        successToast("Photo uploaded successfully");
        setLoading(false);
      },
      (data) => {
        failureToast("Photo upload failed, please try again after sometime.");
        setLoading(false);
      },
      file,
      file?.type,
      name
    );
  } catch (e) {
    failureToast("Unable to upload Photo, please try after sometime.");
    setLoading(false);
  }
};

export const updateStudentPhotoKey = (psid, photoKey) => {
  const url = `${enrollmentServiceBaseUrl}/studentApplications/updateApplicationsByPsid`;
  return fetchAllPostPromisedData(url, { psid, photoKey });
};
