import React from 'react'
import styles from '../common/facultySchedule.module.scss'

const ScheduleHeaderRow = () => {
  return (
    <div className={`${styles?.schedule_row} ${styles?.header}`}>
      <div>Status</div>
      <div>Time</div>
      <div>Event</div>
      <div>Branch</div>
      <div>Batch</div>
      <div>Action</div>
    </div>
  )
}

export default ScheduleHeaderRow