import { regex } from 'views/pages/manage/common/constant';
import { failureToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';

export const newRow = {
  courierName: '',
  pocName: '',
  contactNumber: '',
  emailId: '',
  website: '',
  trackingLink: '',
  status: 'ACTIVE',
};

export const validateRowForm = ({
  courierName,
  pocName,
  contactNumber,
  emailId,
  website,
  trackingLink,
}) => {
  if (
    !courierName?.trim() ||
    !pocName?.trim() ||
    contactNumber?.trim()?.length<10 ||
    !emailId?.trim() ||
    !website?.trim() ||
    !trackingLink?.trim()
  )
    return false;

  return true;
};

export const validateEmail = emailId => {
  if (regex.email.test(emailId)) return true;
  else {
    failureToast('Enter valid email ID');
    return false;
  }
};

export const validatePhoneNumber = input => {
  if (regex.validIndianPhoneNumber.test(input)) return true;
  else {
    failureToast('Enter valid phone number');
    return false;
  }
};
