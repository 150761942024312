import React from "react";
import { useState } from "react";
import { Card, CardHeader, Input, Button, Table,Row,Col } from "reactstrap";
import Select2 from "react-select2-wrapper";
import { failureToast } from '../../../common/utils/methods/toasterFunctions/function'
import { TDROW, MasterHeaderBankAccount } from "../../../common/commonComponents/masterRow/masterRow";
import { masterServiceBaseUrl, successToast, putpost, putpost2 } from 'services/http'
import moment from 'moment';
import CustomDatePicker from '../../../common/commonComponents/CustomDatePicker/index';

import './index.css'
import { active } from "sortablejs";

var _ = require('lodash');

const BranchAccountTable = (props) => {
    const { setstate, tableData, pagination, setclassArray, setpagination ,setIsDisabled,isDisabled,action} = props;
    const [isSaveVisible, setisSaveVisible] = useState(false);
    const [isUpdateDisabled, setIsUpdateDisabled] = useState(false);
    const [bankAccountNumber, setBankAccountNumber] = useState([]);
    const [debitAccount, setDebitAccount] = useState();
    const [creditAccount, setCreditAccount] = useState();
    const [houseBankId, setHouseBankId] = useState();

    const [tableDataKey, settableDataKey] = useState('tableDataKey');
    const addNewClass = () => {
        setDebitAccount('');
        setCreditAccount('');
        props.setclassArray([{ 
            "editMode": true,
             "__status": "__new",
             'status': 'ACTIVE',
             "effectiveDate": null,}, 
             ...props.classArray])
    }
    const saveAll = (cb) => {
        var _tempArray = props.classArray
        var isValid = true
        _tempArray.forEach((el, index) => {
            if (el['editMode']) {
                if (el['pinelabsPaymentModeId'] == null || !el['pinelabsPaymentModeId'].toString().trim()) {
                    isValid = false
                    failureToast('Please Select the value of Pine Labs Payment Mode Id')
                    return
                }
                else if (el['bankAccountId'] == null || !el['bankAccountId'].toString().trim()) {
                    isValid = false
                    failureToast('Please enter the value of Bank Account ID Key')
                    return
                } else if (el['bankAccountNumber'] == null || !el['bankAccountNumber'].toString().trim()) {
                    isValid = false
                    failureToast('Please enter the value of Bank Account Number')
                    return
                }
                else if (el['businessUnitTypeId'] == null || !el['businessUnitTypeId'].toString().trim()) {
                    isValid = false
                    failureToast('Please select the value of Business Area Type ID')
                    return
                }
                if (!isValid) {
                    return
                }
                let requestBody = {
                    businessAreaId:props.businessAreaId,
                    houseBankId:houseBankId,
                    businessUnitTypeId:_tempArray[index]['businessUnitTypeId'],
                    pinelabsPaymentModeId:_tempArray[index]['pinelabsPaymentModeId'],
                    status:_tempArray[index]['status']
                }
                // console.log(requestBody,'requestBody')
                if (_tempArray[index]['__status'] == "__new") {
                    putpost(masterServiceBaseUrl + '/setupHouseBank/createSetupHouseBank', (data) => {
                        successToast(data['message'])
                        setisSaveVisible(false)
                        setDebitAccount('');
                        setCreditAccount('');   
                        props.fetchClasses(masterServiceBaseUrl + '/setupHouseBank/getAllSetupHouseBankByBusinessArea/'+ props.businessAreaId);         
                    }, (data) => { failureToast(data['message']) }, requestBody, 'post')
                } else {
                    putpost(masterServiceBaseUrl + '/setupHouseBank/updateSetupHouseBank/' + _tempArray[index].id, (data) => {
                        successToast(data['message'])
                        setisSaveVisible(false)
                        setDebitAccount('');
                        setCreditAccount('');            
                        delete _tempArray[index]['editMode']
                        props.setclassArray(_tempArray)
                        // console.log(_tempArray,'_tempArray')
                        settableDataKey(new Date().getMilliseconds());
                    }, (data) => { failureToast(data['message']) }, requestBody, 'put')
                }
            } else {

            }
        })
    }
    const newRow = () => {
        setIsUpdateDisabled(false);
        if (isSaveVisible) {
            failureToast("Please Delete or Save current changes")
        }
        else {
            let cb = () => {
                addNewClass()
                setisSaveVisible(true)
            }
            var _tempArray = props.classArray
            _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
            props.setclassArray(_tempArray)
            setisSaveVisible(false)
            cb()
        }
    }
    const editRow = (index) => {
        if (isSaveVisible) {
            failureToast("Please Delete or Save current changes")
        }
        else {
            let cb = () => {
                const bankAccountNumberData = props.bankHouseDetails.filter(item =>
                    item.text.bankAccountNumber === props.classArray[index].houseBankMaster.bankAccountNumber
                )
                if(bankAccountNumberData.length > 0){
                    bankAccountNumberData[0].text && setHouseBankId(bankAccountNumberData[0].text.id);
                    const bankAccountNumber = bankAccountNumberData.map(
                        data => {
                        const {
                            id,
                            bankAccountNumber,
                        } = data.text;
                            return {
                            id: id,
                            text: bankAccountNumber
                            };
                        }
                    );
                    bankAccountNumber.length > 0 && setBankAccountNumber(bankAccountNumber);
                }
                var _tempArray = props.classArray
                _tempArray[index]['editMode'] = true
                _tempArray[index]['bankAccountNumber'] = props.classArray[index].houseBankMaster && props.classArray[index].houseBankMaster.id;
                _tempArray[index]['bankAccountId'] = props.classArray[index].houseBankMaster && props.classArray[index].houseBankMaster.bankAccountId;
                setDebitAccount(props.classArray[index].houseBankMaster && props.classArray[index].houseBankMaster.accountDr);
                setCreditAccount(props.classArray[index].houseBankMaster && props.classArray[index].houseBankMaster.accountCr);
                props.setclassArray(_tempArray);
                setIsUpdateDisabled(true);
                setisSaveVisible(true)
            }
            var _tempArray = props.classArray
            _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
            cb()
        }
    }
    const deleteRow = (index) => {
        if (props.classArray[index]["__status"] == "__new") {
            let n = props.classArray
            n.splice(index, 1)
            props.setclassArray(n)
            setisSaveVisible(false)
            settableDataKey(new Date().getMilliseconds())
        } else {
            if (window.confirm('Are you sure you want to delete this House Bank?')) {
                putpost2(masterServiceBaseUrl + '/setupHouseBank/deleteSetupHouseBank/' + props.classArray[index].id, (data) => {
                    successToast(data['message'])
                    if (props.classArray.length == 1) {
                        props.previousPage();
                        props.fetchClasses(masterServiceBaseUrl + '/setupHouseBank/getAllSetupHouseBankByBusinessArea/'+ props.businessAreaId);         
                    } else {
                        var _tempArray = props.classArray
                        if (_tempArray[index]['editMode']) {
                            setisSaveVisible(false)
                        }
                        _tempArray.splice(index, 1)
                        props.setclassArray(_tempArray);
                        props.fetchClasses(masterServiceBaseUrl + '/setupHouseBank/getAllSetupHouseBankByBusinessArea/'+ props.businessAreaId);         
                    }
                    settableDataKey(new Date().getMilliseconds())
                }, (data) => { failureToast(data['message']) }, 'PUT')
            }
        }
    }
    const updateKey = (index, value, key) => {
        var _tempArray = props.classArray
        _tempArray[index][key] = value
        // console.log(_tempArray,'_tempArray')
        props.setclassArray(_tempArray)
    }

    const searchPaginationHandler = (filterData, pageSize, n) => {
        if (filterData.length > pageSize) {
            n['nextPage'] = n.currentPage + 1
        }
        if (n.currentPage > 1) {
            n.previousPage = n.currentPage - 1
        }
        n['totalPage'] = Math.floor(filterData.length / pageSize)
        if (filterData.length % pageSize != 0) {
            n['totalPage'] += 1
        }
        setstate(filterData);
        setclassArray(filterData.slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || []);
        setpagination(n)
    }

    const filterHandler = _.debounce((houseBankData) => {
        let n = pagination;
        let pageSize = 10;
        if (houseBankData) {
            const filterData = tableData.filter(data => (
                data.houseBankMaster.bankAccountId.toString().toUpperCase().includes(houseBankData.toUpperCase()) ||
                data.houseBankMaster.bankAccountNumber.toString().toUpperCase().includes(houseBankData.toUpperCase()) ||
                data.houseBankMaster.accountDr.toString().toUpperCase().includes(houseBankData.toUpperCase()) ||
                data.houseBankMaster.accountCr.toString().toUpperCase().includes(houseBankData.toUpperCase()) ||
                data.businessUnitTypeId.toString().toUpperCase().includes(props.businessUnitType.find(item => item.text.toUpperCase().includes(houseBankData.toUpperCase())) && 
                props.businessUnitType.find(item => item.text.toUpperCase().includes(houseBankData.toUpperCase())).id.toString()) ||
                data.pinelabsPaymentModeId.toString().toUpperCase().includes(props.pinelabsPaymentModeIds.find(item => item.text.toUpperCase().includes(houseBankData.toUpperCase())) && 
                props.pinelabsPaymentModeIds.find(item => item.text.toUpperCase().includes(houseBankData.toUpperCase())).id.toString()) ||
                data['updatedOn'].toUpperCase().includes(houseBankData.toUpperCase()) ||
                data['status'].toUpperCase().includes(houseBankData.toUpperCase())
            ));
            searchPaginationHandler(filterData, pageSize, n)
        } else {
            searchPaginationHandler(tableData, pageSize, n);

        }
    }, 300)


    function getbankAccountNumber(index){
        const bankAccountNumberData = props.bankHouseDetails.filter(item =>
            item.text.bankAccountId === index &&
            item.text.companyCodeId === props.companyCode &&
            item.text.groupCodeId === props.groupCode 
        )
        // console.log(bankAccountNumberData,props.companyCode,props.groupCode,'bankAccountNumberData')
        if(bankAccountNumberData.length > 0){
            bankAccountNumberData[0].text && setHouseBankId(bankAccountNumberData[0].text.id);
            const bankAccountNumber = bankAccountNumberData.map(
                data => {
                const {
                    id,
                    bankAccountNumber,
                } = data.text;
                    return {
                    id: id,
                    text: bankAccountNumber
                    };
                }
            );
            bankAccountNumber.length > 0 && setBankAccountNumber(bankAccountNumber);
        }
    }


    function getAccount(index){
        const bankAccountDetail = props.bankHouseDetails.find(item =>
            item.text.id === index 
        )
        if(bankAccountDetail){
            bankAccountDetail.text && setDebitAccount(bankAccountDetail.text.accountDr);
            bankAccountDetail.text && setCreditAccount(bankAccountDetail.text.accountCr);    
        }    
    }
     
    // console.log("inside the last section")

    return (
        <>
            <Card style={{ boxShadow: 'none' }} className='mb-0'>
                <CardHeader className="border-0">
                    <div
                        className="floatRight "
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        Search :{' '}
                        <Input
                            className="tdInput ml-3 mr-3 customInputWidth"
                            placeholder="Search"
                            onChange={(e) => {
                                let n = e.target.value.trim();
                                filterHandler(n);
                            }}
                        />
                        { !isDisabled ?
                            <Button
                            disabled={ isSaveVisible }
                            color="info" size="sm"
                            type="button"
                            className={ "floatRight mx-1" + ( isSaveVisible ? " btn-dark" : null ) }
                            onClick={ () => { newRow() } }
                        >
                            Add New House Bank
                        </Button>:null}
                    </div>
                </CardHeader>
                <div id="questionTable" className="table-responsive" style={{ marginBottom: '3.5rem' }}>
                    <Table className="align-items-center table-flush tableLayout" >
                        <MasterHeaderBankAccount
                            isDisabled={isDisabled}
                            type={"Bank Account ID"}
                            itemType='Bank Account ID' 
                        />
                        <tbody className="list" key={tableDataKey} >
                            { props.classArray ? props.classArray.map( ( el, index ) =>
                                !el[ 'editMode' ] ? (
                                    
                                    <TDROW isDisabled={ isDisabled } type={"BankAccountID"} itemType='BankAccountID' index={index} el={el} editRow={editRow} deleteRow={deleteRow}
                                    businessUnitType={props.businessUnitType} pinelabsPaymentModeIds={props.pinelabsPaymentModeIds}/>
                                ) :
                                    (<tr key={index + '-class'}>
                                        <td className="text-center p-2 mx-1">
                                            <Select2 
                                                defaultValue={el['pinelabsPaymentModeId']} 
                                                data={props.pinelabsPaymentModeIds} 
                                                id='0'
                                                onChange={(e) => {
                                                    updateKey(index,e.target.value, 'pinelabsPaymentModeId');
                                                }} 
                                                options={{ placeholder: "Select pine Labs Payment Mode" }} 
                                                className="tdSelect" />
                                        </td>
                                        <td className="text-center p-2 mx-1">
                                            <Select2 
                                                defaultValue={el['bankAccountId']} 
                                                data={props.bankAccountIdKeys} 
                                                id='1'
                                                onChange={(e) => {
                                                    updateKey(index,e.target.value, 'bankAccountId');
                                                    getbankAccountNumber(e.target.value); 
                                                }} 
                                                disabled={isUpdateDisabled}
                                                options={{ placeholder: "Select Bank Account ID" }} 
                                                className="tdSelect" />
                                        </td>
                                        <td className="text-center p-2 mx-1">
                                            <Select2 
                                                defaultValue={el['bankAccountNumber']} 
                                                data={bankAccountNumber} 
                                                id='2'
                                                onChange={(e) => { 
                                                updateKey(index, Number(e.target.value), 'bankAccountNumber');
                                                getAccount(Number(e.target.value)); 
                                            }} 
                                            disabled={isUpdateDisabled}
                                            options={{ placeholder: "Select Bank Account Number" }} 
                                            className="tdSelect" />
                                        </td>
                                        <td className="text-center p-2 mx-1">
                                           <Input maxLength={50} defaultValue={debitAccount} disabled
                                             placeholder="Debit Account" className="tdInput" />
                                        </td>
                                        <td className="text-center p-2 mx-1">
                                           <Input maxLength={50} defaultValue={creditAccount} disabled
                                             placeholder="Credit Account" className="tdInput" />
                                        </td>
                                        <td className="text-center p-2 mx-1">
                                            <Select2 defaultValue={el['businessUnitTypeId']}  data={props.businessUnitType} id='3'
                                                 onChange={(e) => {updateKey(index, Number(e.target.value), 'businessUnitTypeId') }} 
                                                 options={{ placeholder: "Select Business Area Type" }} className="tdSelect" />
                                        </td>
                                        <td className="text-center p-2 mx-1">
                                        <CustomDatePicker 
                                            disabled={true}
                                            value={
                                                el["__status"] == "__new"
                                                ? null
                                                : moment(el["updatedOn"]).toDate()
                                            }
                                            placeholder="Example:01-01-2020"
                                        />
                                        {/* <Input disabled={true} defaultValue={el["__status"] == "__new" ? null : moment(el['updatedOn']).format('DD-MM-YYYY')} placeholder="Example:01-01-2020" className="tdInput" /> */}
                                        </td>
                                        <td className="text-center p-2 mx-1">
                                            <label className="custom-toggle mx-auto ml-2">
                                                <input
                                                    checked={el.status == "ACTIVE" ? true : false}
                                                    type="checkbox" id={el.id}
                                                    onChange={(e) => {
                                                        settableDataKey(new Date().getMilliseconds());
                                                        let n = "ACTIVE"; if (!e.target.checked) { n = "INACTIVE" }
                                                        updateKey(index, n, 'status')
                                                    }}
                                                />
                                                <span style={{ width: "72px", margin: '0 auto' }} className="custom-toggle-slider rounded-circle activeToggle"
                                                    data-label-off="Inactive" data-label-on="Active" />
                                            </label>
                                        </td>
                                        <td className="text-center">
                                            {isSaveVisible ? <> <Button color="info" size="sm" type="button" className="mx-1" onClick={() => { saveAll() }}>Save</Button>
                                                <Button color="danger" size="sm" type="button" onClick={() => {
                                                    let n = props.classArray
                                                    if (n[index]['__status'] == '__new') {
                                                        n.splice(index, 1)
                                                    } else {
                                                        n[index]['editMode'] = false
                                                        props.fetchClasses(masterServiceBaseUrl + '/setupHouseBank/getAllSetupHouseBankByBusinessArea/'+ props.businessAreaId);         
                                                    }
                                                    props.setclassArray(n)
                                                    setisSaveVisible(false)
                                                }}>  <i className="fas fa-times" /></Button> </> :
                                                <Button color="info" size="sm" type="button" onClick={() => {
                                                    deleteRow(index)
                                                }}>  <i className="fas fa-trash" /></Button>}
                                        </td>
                                    </tr>)
                            ) : null}
                        </tbody>
                    </Table>
                </div>
                { action === 'edit' ?
                    <Row>
                    <Col>
                        <Button  className={ 'floatRight mb-4 mr-4' } color="primary" size="md" type="button" onClick={()=>setIsDisabled(!isDisabled)}>{isDisabled ? 'Enable Form' : 'Disabled Form'}</Button>
                    </Col>
                </Row>:null}
            </Card>
        </>
    )
}
export default BranchAccountTable;