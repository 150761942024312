import React, { useContext, useState } from 'react';
import { ReactComponent as DocumentIcon } from 'assets/img/svg/Document.svg';
import { ReactComponent as ExcellIcon } from 'assets/img/svg/excel.svg'
import { useHistory } from 'react-router';
import { getScreenCards } from 'views/pages/manage/finOps/IssueStock/search/SearchCard';
import { RiFileExcelLine } from 'react-icons/ri';
import { PermissionContext } from 'appContext';
import { pages } from 'views/pages/manage/common/constant';
import { RolePermissions } from 'appContext';


const ModuleCards = ({ stockConsumptionReturnHandler, stockTxnHandler, downloadReportsClickHandler = () => { } }) => {
  const history = useHistory();

  const sdSequencingPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["paymentCollectionMasterSdSequencing"]["id"]
  );
  const posMappingPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["paymentCollectionMasterPosMapping"]["id"]
  );
  const bankMasterPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["paymentCollectionMasterBankMaster"]["id"]
  );
  const branchFunctionalityControlPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["paymentCollectionMasterBfControl"]["id"]
  );

  const cards = [
    posMappingPermissions && {
      heading: 'Pine labs POS Mapping',
      subHeading: 'Mapping of accountant with pine labs device',
      icon: <DocumentIcon />,
      action: () => { 
        history.push('/admin/master/studentFinanceSetup/paymentCollection/posMapping');
      }
    },
    sdSequencingPermissions && {
      heading: 'Security deposit sequencing',
      subHeading: 'Control the security deposit order',
      icon: <DocumentIcon />,
      action: () => {
        history.push('/admin/master/studentFinanceSetup/paymentCollection/sdSequence');
      },
    },
    bankMasterPermissions &&
    {
      heading: 'Bank Master',
      subHeading: 'Create or edit a bank',
      icon: <DocumentIcon />,
      action: () => {
        history.push('/admin/master/studentFinanceSetup/paymentCollection/bankMaster');
       },
    },
    branchFunctionalityControlPermissions && {
      heading: 'Branch functionality control',
      subHeading: 'Enable or disable features for business units',
      icon: <DocumentIcon />,
      action: () => { },
    },


  ];
  return (
    <>
      <div className={`screen-cards-container`}>
        {getScreenCards(cards?.filter((card) => card))}
      </div>

    </>
  );
};

export default ModuleCards;
