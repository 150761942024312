import {
  FACULTY_ROASTER_LIST_CLEAR,
  FACULTY_ROASTER_LIST_FAILURE,
  FACULTY_ROASTER_LIST_REQUEST,
  FACULTY_ROASTER_LIST_SUCCESS,
  REQUEST_DROPDOWNS,
  UPDATE_DROPDOWNS,

} from "./actionTypes";

const defaultState = {
  currentPage: 0,
  dropdowns: {},
  dropdownLoader: false,
  searchFacultyRoasterLoader: false,
  facultyRoasterList: [],
  searchFacultyRoasterRowCount: 0,
  searchFacultyRoasterTotalServerPages: 0,
  searchFacultyRoasterDropdowns: {},
};

export const facultyRoasterReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_DROPDOWNS:
      return {
        ...state,
        dropdowns: action.dropdowns,
        dropdownLoader: false
      };
    case REQUEST_DROPDOWNS:
      return {
        ...state,
        dropdownLoader: true
      }
    case FACULTY_ROASTER_LIST_REQUEST:
      return {
        ...state,
        searchFacultyRoasterLoader: true,
      };
    case FACULTY_ROASTER_LIST_SUCCESS:
      return {
        ...state,
        facultyRoasterList: action.payload,
        searchFacultyRoasterRowCount: action.rowCount,
        searchFacultyRoasterTotalServerPages: action.totalServerPages,
        searchFacultyRoasterLoader: false,
      };
    case FACULTY_ROASTER_LIST_FAILURE:
      return {
        ...state,
        searchFacultyRoasterLoader: false,
        facultyRoasterList: [],
        searchFacultyRoasterRowCount: 0,
        searchFacultyRoasterTotalServerPages: 0,
      };
    case FACULTY_ROASTER_LIST_CLEAR:
      return {
        ...state,
        searchFacultyRoasterLoader: false,
        facultyRoasterList: [],
        searchFacultyRoasterRowCount: 0,
        searchFacultyRoasterTotalServerPages: 0,
      };
    
    default:
      return state;
  }
};
