import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { Card, CardBody } from 'reactstrap'
import {ReactComponent as BackIcon} from '../../../../../assets/img/svg/BackButton.svg'
import AButton from '../../common/form-fields-mui/AButton'
import { ReactComponent as ListViewIcon } from '../../../../../assets/img/svg/ListViewIcon.svg'
import { ReactComponent as TilesViewIcon } from '../../../../../assets/img/svg/TilesViewIcon.svg'
import { useState } from 'react'
import ListView from './ListView'
import GridView from './GridView'
import { pages } from '../../common/constant'
import { PermissionContext, RolePermissions } from 'appContext'
import { checkPermission } from '../../common/utils/methods/commonMethods/utilityMethod'
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper'

const viewIconStyles = (active) => {
  return {
    border: 'none',
    width: '44px',
    height: '44px',
    borderRadius: '8px',
    backgroundColor: active ? '#00B0F5' : '#FFFFFF'
  }
}

const PlanView = ({ planData, publishClickHandler, testCountObj, testCount, courses, subjectLecturesCount }) => {

  const userPermissions = RolePermissions(useContext(PermissionContext), pages['searchAcademicPlan']['id']);
  const history = useHistory();

  const [viewMode, setViewMode] = useState('grid')
  const viewTestClickHandler = (e) => {
    if (e?.ctrlKey) {
      window.open(history.createHref({ pathname: `/admin/testScheduling/academicPlan/view/${planData?.id}/tests` }), '_blank')
    } else {
      history.push({
        pathname: `/admin/testScheduling/academicPlan/view/${planData?.id}/tests`,
        state: {planData: planData}
      })
    }
  }

  return (
    <div>
      <Card className='mb-3'>
        <CardBody className={`py-2 px-3`}>
          <div className='d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center'>
              <div><IconButtonWrapper className='mr-2' onClick={() => history.goBack()}><BackIcon /></IconButtonWrapper></div>
              <h3 className='mb-0' style={{ fontSize: '20px', color :"#0F0F0F" }}>Academic Plan</h3>
            </div>
            <div className='d-flex align-items-center'>
              {planData?.publishStatus==='U' && <AButton variant='primary_filled' onClick={publishClickHandler} disabled={!checkPermission(userPermissions, 'U')}>Publish Plan</AButton>}
              {planData?.publishStatus==='P' && <AButton variant='danger_filled' onClick={publishClickHandler} disabled={!checkPermission(userPermissions, 'U')}>Unpublish Plan</AButton>}
              <AButton updatedStyle={viewIconStyles(viewMode === 'list')} onClick={() => setViewMode('list')}>
                <IconButtonWrapper><ListViewIcon stroke={viewMode === 'list' ? "#FFFFFF" : "#0F0F0F"} /></IconButtonWrapper> 
              </AButton>
              <AButton updatedStyle={viewIconStyles(viewMode === 'grid')} onClick={() => setViewMode('grid')}>
                <IconButtonWrapper> <TilesViewIcon stroke={viewMode === 'grid' ? "#FFFFFF" : "#0F0F0F"} /> </IconButtonWrapper> 
              </AButton>
            </div>
          </div>
        </CardBody>
      </Card>
      {viewMode==='list' && (
        <ListView 
          viewTestClickHandler={viewTestClickHandler} 
          testCountObj={testCountObj} 
          testCount={testCount} 
          courses={courses} 
          subjectLecturesCount = {subjectLecturesCount}
        />
      )}
      {viewMode==='grid' && (
        <GridView 
          viewTestClickHandler={viewTestClickHandler} 
          testCountObj={testCountObj} 
          testCount={testCount} 
          courses={courses} 
          subjectLecturesCount = {subjectLecturesCount}
        />
      )}
    </div>
  )
}

export default PlanView