import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { initialFilterForm } from "./constant";
import AInput from "views/pages/manage/common/formFeilds/customInput/AInput";
import { getFilterCount } from "views/pages/manage/edpOps/stockManagement/mastersAndMapping/onBoardingMaterialIdSetup/constant";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";
import { STATUS_DROPDOWN } from "views/pages/manage/edpOps/constant/batch-search";

const SearchFilterForm = ({
  dropdown,
  closeForm,
  filterCount,
  filterPayload,
  applyFilter = () => {},
}) => {
  const [filterForm, setFilterForm] = useState(filterPayload);
  const filterFormHandler = (value, key) =>
    setFilterForm((prev) => ({ ...prev, [key]: value }));

  useEffect(() => {
    setFilterForm(filterPayload);
  }, [filterPayload]);
  const InputLabelStyle = {
    fontFamily: "Gilroy-Regular",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#414141",
    marginTop: "10px",
    marginBottom: 0,
  };

  return (
    <Card
      className="mb-0 regular-body label-color"
      style={{ width: "330px", borderRadius: "12px" }}
    >
      <CardBody className="d-flex flex-column">
        <div className="mb-1 d-flex align-items-start justify-content-between">
          <div className="regular-large-bold">Filter by</div>
        </div>
        <div className="d-flex flex-column gap-md">
          <AInput
            label="Exam time code"
            placeHolder="Enter"
            value={filterForm?.examTimeCode}
            handler={(value) =>
              value?.length <= 20 && filterFormHandler(value, "examTimeCode")
            }
            labelStyle={InputLabelStyle}
            style={{
              paddingRight: "20px",
              borderRadius: "7px",
            }}
          />

          <AAutoComplete
            label="Test type"
            currentValue={filterForm?.testType}
            items={dropdown?.testType}
            handleChange={(value) => {
              filterFormHandler(value, "testType");
            }}
            isMulti={false}
          />

          <AAutoComplete
            label="Term"
            currentValue={filterForm?.term}
            items={dropdown?.term}
            handleChange={(value) => {
              filterFormHandler(value, "term");
            }}
            isShowCount={true}
            isMulti={true}
          />

          <AAutoComplete
            label="Course category"
            currentValue={filterForm?.courseCategory}
            items={dropdown?.courseCategory}
            handleChange={(value) => {
              filterFormHandler(value, "courseCategory");
            }}
            isShowCount={true}
            isMulti={true}
          />

          <AAutoComplete
            label="Academic group"
            currentValue={filterForm?.academicGroup}
            items={dropdown?.academicGroup}
            handleChange={(value) => {
              filterFormHandler(value, "academicGroup");
            }}
            isShowCount={true}
            isMulti={true}
          />

          <AAutoComplete
            label="Status"
            currentValue={filterForm?.status}
            items={STATUS_DROPDOWN}
            handleChange={(value) => {
              filterFormHandler(value, "status");
            }}
            isMulti={false}
          />
        </div>

        <div className="mt-3 d-flex flex-row-reverse justify-content-between">
          <AButton
            variant="primary_filled"
            className="button_remove_left_margin"
            onClick={() => {
              closeForm();
              applyFilter(filterForm);
            }}
            disabled={!getFilterCount(filterForm)}
          >
            Apply filter
          </AButton>
          {filterCount > 0 && (
            <AButton
              variant="outline"
              className="button_remove_left_margin"
              updatedStyle={{ border: "1px solid #CC5F5F", color: "#CC5F5F" }}
              onClick={() => {
                closeForm();
                applyFilter(initialFilterForm);
              }}
            >
              Remove filter
            </AButton>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default SearchFilterForm;
