import React, { useState } from "react";
import { Dialog } from "@mui/material";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import { ReactComponent as Checked } from "assets/img/svg/fi_check.svg";
import AInput from "../../../../common/formFeilds/customInput/AInput";
import AButton from "../../../../common/form-fields-mui/AButton";
import ALoader from "../../../../common/a-loader";
import { notifyLogistics } from "../../service";
import { successToast } from "../../../../common/utils/methods/toasterFunctions/function";
import { notifyDlpStock } from "../../service";
import { useGetDlpModuleContext } from "../../contextApi/dlpContext";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx";

const SuccessDialog = ({
  handleClose,
  open,
  transactionId = "DLP12009382",
  downloadHandler,
  isLoading,
}) => {
  const { markedEligibleRequest, filterForm } = useGetDlpModuleContext();
  const [loader, setLoader] = useState(false);

  const notifyHandler = async () => {
    setLoader(true);
    const reqObj = {
      dlpStatus: ["LOGISTIC"],
      dispatchNotifyChannel: ["EMAIL"],
      applicationId: markedEligibleRequest?.map((item) => item.applicationId),
      request: markedEligibleRequest?.map((item) => ({
        psid: item?.psid,
        studentName: item?.studentName,
        dispatchId: filterForm?.dispatchScheduleId?.label,
        dispatchScheduleId: filterForm?.dispatchScheduleId?.value,
        applicationId: item?.applicationId,
      })),
    };

    const response = await notifyDlpStock(reqObj);
    if (response?.code === 200) {
      successToast(response?.message);
      handleClose();
    }
    setLoader(false);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={{ padding: "1.5rem", width: "33rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            fontSize: "1.4rem",
            marginBottom: "10px",
            color: "black",
          }}
        >
          {(isLoading || loader) && <ALoader />}
          {/* <span>Transaction ID: {transactionId} </span> */}
          <IconButtonWrapper className="ml-0 mb-1" onClick={handleClose}>
            <CrossIcon />
          </IconButtonWrapper>
        </div>
        <div className="d-flex flex-column gap-md mt-3 justify-content-between align-items-center">
          <Checked />
          <div className=" heading-4 color-dark">
            Eligible to issue stock marked
          </div>
          <div className=" heading-4 color-dark">successfully!</div>
          {/* <AInput
            placeholder="Enter Logistic team email ID"
            style={{ height: "42px", width: "250px" }}
          /> */}
          <div className="mt-1">
            <AButton onClick={notifyHandler} variant="primary_filled">
              Send to logistic team
            </AButton>
          </div>
          {/* <div>Student list sheet has been downloaded successfully  </div> */}
          <div
            style={{ cursor: "pointer" }}
            className="color-primary my-4"
            onClick={downloadHandler}
          >
            Download sheet
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SuccessDialog;
