import React, {  useState, Fragment } from 'react';
import { Row,Col } from 'reactstrap';
import {masterServiceBaseUrl, getAPI,} from 'services/http';
import {failureToast} from '../../../../common/utils/methods/toasterFunctions/function';
import CommonInput from "../../../../common/formFeilds/input/commonInput";

const CourseHeader = (props) => {
	const {
		businessData,
		companyCodeData,
		courseHeaderObj,
		setCourseHeaderObj,
		isNew = true,
		formId,
		action,
	} = props;
	const {label,placeHolder,toolTip} = require('./../properties/index.json');
	const [courseStatusId, setCourseStatusId] = useState([
		{ value: 'ACTIVE', label: 'ACTIVE' },
		{ value: 'INACTIVE', label: 'INACTIVE' },
	]);
	const [showCompanyCode, setShowCompanyCode] = useState([]);
	const [isDisabled, setIsDisabled] = useState(false);

	const fetchCourseHeaderData = () => {
		getAPI(
			`${masterServiceBaseUrl}/courseCatalogCourseHeader/getCourseCatalogCourseHeader/${formId}`,
			(data) => {
				setCourseHeaderObj({
					...courseHeaderObj,
					businessgrp: getSelectedObj(data.data.groupCodeId, businessData),
					companyCode: getSelectedObj(data.data.companyCodeId, companyCodeData),
					courseId: data.data.courseId,
					courseIdStatus: data.data.status,
					prospectusCode: data.data.prospectusCode,
					createdOn: data.data.createdOn,
					createdBy: data.data.createdBy,
					updatedOn: data.data.updatedOn,
					updatedBy: data.data.updatedBy,
				});
				setIsDisabled(true);
			},
			(data) => {
			}
		);
	};

	const getSelectedObj = (id, arr) => {
		return arr.filter((item) => {
			return item.value === id;
		})[0];
	};

	React.useEffect(() => {
		if (!isNew && businessData.length && companyCodeData.length && action !=='new' ) {
			fetchCourseHeaderData();
		}
	}, [isNew, businessData, companyCodeData]);



	const getCompanyCode = (value) => {
		try {
			getAPI(
				masterServiceBaseUrl + `/companyCode/getCompanyCodeByGroup/${value}`,
				(data) => {
					let tempCompanyCode = [];
					data &&
						data.data.map((item) => {
							tempCompanyCode.push({
								value: item.id,
								label: item.companyCodeDispValue,
							});
						});
					setCourseHeaderObj((preState) => ({
						...preState,
						companyCode: '',
					}));
					setShowCompanyCode(tempCompanyCode);
				},
				(data) => {
					setCourseHeaderObj((preState) => ({
						...preState,
						companyCode: '',
					}));
					setShowCompanyCode([]);
					failureToast(data['message']);
				}
			);
		} catch (e) {
			console.log('Error', e);
		}
	};

	const checkValidation = (string) => {
		let temp = string.split('');
		var reg_exp = /^[A-Za-z0-9 ]+$/;
		let flag = true;
		temp.map((c) => {
			if (c === '-' || c === '_' || c === '/') flag = true;
			else if (!reg_exp.test(c)) {
				flag = false;
			}
		});
		return flag;
	};

	return (
		<Fragment>
			<Row>
				<Col md="4">
					<CommonInput
						isDisabled={isDisabled}
						placeHolder={placeHolder.businessGroup}
						mandatory={true}
						type={'select'}
						data={businessData}
						defaultValue={courseHeaderObj.businessgrp}
						label={label.businessGroup}
						onChange={(sData)=>{
							setCourseHeaderObj({ ...courseHeaderObj, businessgrp: sData });
							getCompanyCode(sData.value);
							props.setGroupCode(sData.value);
						}}
					/>
				</Col>
				<Col md="4">
						<CommonInput
							placeHolder={placeHolder.companyCode}
							mandatory={true}
							type={'select'}
							data={showCompanyCode}
							defaultValue={courseHeaderObj.companyCode}
							label={label.companyCode}
							isDisabled={isDisabled || courseHeaderObj.businessgrp === ''}
							onChange={(value)=>{
								setCourseHeaderObj({ ...courseHeaderObj, companyCode: value });
								props.setCompanyCode(value.value);
							}}
						/>
				</Col>
				<Col md="4">
						<CommonInput
							placeHolder={placeHolder.courseId}
							type={'text'}
							defaultValue={courseHeaderObj.courseId}
							label={label.courseId}
							isDisabled={true}
							onChange={(sData) =>
								setCourseHeaderObj({ ...courseHeaderObj, attributeDesc: sData })
							}
						/>
				</Col>
				<Col md="4">
						<CommonInput
  						placeHolder={placeHolder.courseIdStatus}
							mandatory={true}
							type={'select'}
							data={courseStatusId}
							defaultValue={action === 'new'? courseStatusId[0]  :{value: courseHeaderObj.courseIdStatus, label: courseHeaderObj.courseIdStatus,}}
 						   label={label.courseIdStatus}
							isDisabled={action === 'view' || courseHeaderObj.companyCode === ''}
							onChange={(value) => {
								setCourseHeaderObj({
									...courseHeaderObj,
									courseIdStatus: value.value,
									status:value
								});
							}}
						/>
				</Col>
				<Col md="4">
					<CommonInput
						placeHolder={placeHolder.prospectusCode}
						type={'text'}
						maxLength={100}
						defaultValue={courseHeaderObj.prospectusCode}
						label={label.prospectusCode}
						action={action}
						lengthNotifier={true}
						tooltip={action === 'new'}
						tooltipContent={toolTip.prospectusCode}
						isDisabled={isDisabled || courseHeaderObj.companyCode === ''}
						onChange={(value) => {
							let prospectusData = value;
							if (checkValidation(prospectusData))
								setCourseHeaderObj({
									...courseHeaderObj,
									prospectusCode: prospectusData,
								});
							else setCourseHeaderObj(courseHeaderObj);
						}}
					/>
				</Col>
			</Row>
		</Fragment>
	);
};

export default CourseHeader;
