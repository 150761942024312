import React, { useState } from "react";
import AButton from "../../../common/form-fields-mui/AButton";
import { Dialog } from "@mui/material";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import { ReactComponent as QuestionIcon } from "assets/img/svg/QuestionMarkConfirmationDialog.svg";
import CustomCopyToClipboard from "views/pages/manage/common/ACopyToClipboard/CustomCopyToClipboard";
import { NoteDiv } from "./NoteDiv";
import "./style.scss";

const useRollNoPrompt = (list) => {
  const [promise, setPromise] = useState(null);
  const confirm = () =>
    new Promise((resolve, reject) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise.resolve(false);
    handleClose();
  };

  const inactivePrompt = () => (
    <Dialog onClose={handleCancel} open={promise !== null}>
      <div style={{ padding: "28px 24px", width: "536px" }}>
        <div className="d-flex justify-content-between align-items-start mb-3">
          <QuestionIcon color="#414141" />
          <CrossIcon width={32} height={32} onClick={handleCancel} />
        </div>

        <>
          <div
            style={{
              fontWeight: 700,
              fontSize: "22px",
              lineHeight: "24px",
              color: "#000000",
              marginBottom: "1rem",
            }}
          >
            Roll number is not available for {list.length > 1?'given':'this'} student
            {list.length > 1 ? "s" : ""}
          </div>
          <section>
            <header>
              <div class="col-roll-no roll-no-label">PSID</div>
              <div class="col-roll-no roll-no-label">Application ID</div>
              <div class="col-roll-no roll-no-label">Name</div>
            </header>
            {list.map((item) => (
              <div key={item.psid} className="row-roll-no">
                <div className="col-roll-no">
                  {item.psid}
                  <CustomCopyToClipboard textToCopy={item.psid} />
                </div>
                <div className="col-roll-no">
                  {item.applicationId}
                </div>
                <div className="col-roll-no">{item.studentName}</div>
              </div>
            ))}
          </section>

          <div
            style={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
              color: "black",
              marginBottom: "1.5rem",
              fontWeight: "bold",
            }}
          >
            Are you sure you want to print ID card?
          </div>
        </>

        <div className="d-flex justify-content-between mb-4">
          <AButton
            variant="outline"
            className="button_remove_left_margin"
            updatedStyle={{
              border: "1px solid #414141",
              color: "#414141",
              width: "100%",
            }}
            onClick={handleCancel}
          >
            No
          </AButton>
          <AButton
            variant="danger_filled"
            updatedStyle={{ backgroundColor: "#CC5F5F", width: "100%" }}
            onClick={handleConfirm}
          >
            Yes, Print
          </AButton>
        </div>
        <NoteDiv isMultiple ={list.length>1} />
      </div>
    </Dialog>
  );

  return [inactivePrompt, confirm];
};

export default useRollNoPrompt;
