import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  CardHeader,
  Container,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Table,
  Row,
  FormGroup,
} from "reactstrap";
import { MasterHeaderAuto } from "../../../../common/commonComponents/masterHeader/masterHeader";
import TableList from "../../../../common/tableList";
import { useLocation } from "react-router-dom";
import MasterHeader from "./tableHeader";
import NoDataFound from "../../../../common/noDataFound";
import Style from "./tableHeader.module.scss";
import { endpoint } from "../../../../common/constant";
import Select from "react-select";
import {
  failureToast,
  getAPI,
  putpost,
  successToast,
} from "../../../../../../../services/http";

const _ = require("lodash");

const AssignPermission = (props) => {

const {
  processId,
  masterRoleId,
  setPermissionListPage,
  permissionListPage

} = props;

  const fetchData = (url, type) => {
    try {
      getAPI(
        url,
        (data) => {
          dataHandler(data?.data, type);
        },
        (data) => {
          failureToast(data["message"]);
        }
      );
    } catch (err) {
      console.log("Error :", err);
    }
  };



  const dataHandler = (data, type) => {
    try {
      switch (type) {
        case 'PermissionPages':
          setPermissionListPage(data);
          break;
        default:
          break;
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onChangeHandler = (type, index, checked) => {
    try {
      const _tempArr = permissionListPage;
      _tempArr[index][type] = checked;
      setPermissionListPage(_tempArr);
    } catch (e) {
      console.log("Error ", e);
    }
  };



  useEffect(()=>{
    masterRoleId && processId && fetchData(endpoint.role_To_Process.searchByProcessIdAndRoleId+`?roleMasterId=${masterRoleId}&processTagTypeMasterId=${processId}`,'PermissionPages')
  },[masterRoleId,processId])

  return (
    <Fragment>
      <div
        id="questionTable"
        className="table-responsive"
        style={{ overflow: "auto !important" }}
      >
        <Table className="align-items-center table-flush ">
          <MasterHeader />
          <tbody className="list">
            {permissionListPage && permissionListPage.length > 0 ? (
              permissionListPage.map((el, index) => (
                <tr>
                  <td
                    className="text-center  white-breakSpace"
                    style={{ width: "200px" }}
                  >
                    <Label className={`form-control-label`}>
                      {el["pageName"]}
                    </Label>
                  </td>
                  <td
                    className="text-center  white-breakSpace"
                    style={{ width: "200px" }}
                  >
                    <Label className={`form-control-label`}>
                      {el["pageDescription"]}
                    </Label>
                  </td>
                  <td
                    className="text-center  white-breakSpace"
                    style={{ width: "200px" }}
                  >
                    <Label className={`form-control-label`}>
                      {el["navigation"]}
                    </Label>
                  </td>
                  <td className="text-center  white-breakSpace">
                    <div className={Style.customCheckBox}>
                      <Input
                        type={"checkbox"}
                        defaultChecked={el["isAdd"]}
                        className={Style.form_control}
                        onChange={(e) => {
                          onChangeHandler("isAdd", index, e.target.checked);
                        }}
                      />
                    </div>
                  </td>
                  <td className="text-center  white-breakSpace">
                    <div className={Style.customCheckBox}>
                      <Input
                        type={"checkbox"}
                        defaultChecked={el["isUpdate"]}
                        className={Style.form_control}
                        onChange={(e) => {
                          onChangeHandler("isUpdate", index, e.target.checked);
                        }}
                      />
                    </div>
                  </td>
                  <td className="text-center  white-breakSpace">
                    <div className={Style.customCheckBox}>
                      <Input
                        type={"checkbox"}
                        defaultChecked={el["isDelete"]}
                        className={Style.form_control}
                        onChange={(e) => {
                          onChangeHandler("isDelete", index, e.target.checked);
                        }}
                      />
                    </div>
                  </td>
                  <td className="text-center  white-breakSpace">
                    <div className={Style.customCheckBox}>
                      <Input
                        type={"checkbox"}
                        defaultChecked={el["isViewOnly"]}
                        className={Style.form_control}
                        onChange={(e) => {
                          onChangeHandler(
                            "isViewOnly",
                            index,
                            e.target.checked
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="text-center  white-breakSpace">
                    <div className={Style.customCheckBox}>
                      <Input
                        type={"checkbox"}
                        defaultChecked={el["isApprove"]}
                        className={Style.form_control}
                        onChange={(e) => {
                          onChangeHandler("isApprove", index, e.target.checked);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colspan="8">
                  <NoDataFound />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {/*{permissionListPage && permissionListPage.length > 0 ? (*/}
      {/*  <div className={"text-right"}>*/}
      {/*    <Button*/}
      {/*      color={"info"}*/}
      {/*      onClick={() => {*/}
      {/*        let obj = {*/}
      {/*          permissionDetailRequestDtoList: permissionListPage,*/}
      {/*          processTagTypeMasterId: params.get("processId"),*/}
      {/*          roleMasterId: params.get("roleId"),*/}
      {/*          roleToProcessDetailId: params.get("roleToProcessDetailId"),*/}
      {/*        };*/}
      {/*        fetchSearchData(*/}
      {/*          endpoint.role_To_Process.createPermission,*/}
      {/*          obj,*/}
      {/*          "RECORD_CREATED"*/}
      {/*        );*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      Save*/}
      {/*    </Button>*/}
      {/*    <Button color={"info"}>Cancel</Button>*/}
      {/*  </div>*/}
      {/*) : null}*/}
    </Fragment>
  );
};

export default AssignPermission;
