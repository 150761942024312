import {Col, Form, FormGroup, Label, Row} from "reactstrap";
import CommonInput from "../../../common/formFeilds/input/commonInput";
import {fetchDetails, getDispValForReactSelectByOneID} from "../../../common/utils/methods/commonMethods/utilityMethod";
import {masterServiceBaseUrl} from "services/http";
import React from "react";
import moment from "moment";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import {RedAstric} from "../../../examService/examIdGeneration/examIdSearchForm";
import {label} from "./properties";
import {DropDownButton} from "../../../masters/examSyllabus/examSyllabusForm";

const ItemTypeSequenceHeader =(props)=>{
    const {
        businessGroup=[],
        companyCode=[] ,
        term=[],
        academicCareer=[],
        courseTypes=[],
        updateHeaderData,
        setCompanyCode,
        headerData,status=[] ,
        action,
        businessAreaValues,
        allBusinessAreaIds,
        label,
        placeholder,
    } = props;
    let  formattedTime
    if(props.updatedOn){
    const dateObject = new Date(props.updatedOn);
    const hours = String(dateObject.getHours()).padStart(2, '0');
    const minutes = String(dateObject.getMinutes()).padStart(2, '0');
    formattedTime= `${hours}:${minutes}`;
    }
    return (
        <Form>
            <Row>
                <Col md={4}>
                    <CommonInput
                        label={label.businessGroup}
                        mandatory={true}
                        data={businessGroup}
                        type={action === 'new' ? 'reactSelect' : 'text'}
                        placeHolder={placeholder.businessGroup}
                        onChange={(value)=>{ 
                            updateHeaderData('groupCodeId',value);
                            updateHeaderData('companyCodeId',null);
                            updateHeaderData('academicCareerId',null);
                        }}
                        defaultValue={action === 'new' ? headerData["groupCodeId"]: getDispValForReactSelectByOneID(headerData["groupCodeId"], 'DispValue', businessGroup)}
                        isDisabled={action !== 'new'}
                    />
                </Col>
                <Col md={4}>
                    <CommonInput
                        label={label.companyCode}
                        mandatory={true}
                        data={companyCode}
                        type={action === 'new' ? 'reactSelect' : 'text'}
                        onChange={(value)=>{
                            updateHeaderData('companyCodeId',value);
                            updateHeaderData('academicCareerId',null);
                        }}
                        placeHolder={placeholder.companyCode}
                        defaultValue={action === 'new' ? headerData["companyCodeId"]: getDispValForReactSelectByOneID(headerData["companyCodeId"], 'DispValue', companyCode)}
                        isDisabled={action !== 'new'}
                    />
                </Col>
                <Col md={4}>
                    <CommonInput
                        label={label.term}
                        mandatory={true}
                        data={term}
                        type={action === 'new' ? 'reactSelect' : 'text'}
                        placeHolder={placeholder.term}
                        onChange={(value)=>updateHeaderData('termId',value)}
                        defaultValue={action === 'new' ? headerData["termId"]: getDispValForReactSelectByOneID(headerData["termId"], 'DispValue', term)}
                        isDisabled={action !== 'new'}
                    />
                </Col>
                <Col md={4}>
                    <CommonInput
                        label={label.academicCareer}
                        mandatory={true}
                        data={academicCareer}
                        type={action === 'new' ? 'reactSelect' : 'text'}
                        placeHolder={placeholder.academicCareer}
                        onChange={(value)=>{ updateHeaderData('academicCareerId',value);}}
                        defaultValue={action === 'new' ? headerData["academicCareerId"]: getDispValForReactSelectByOneID(headerData["academicCareerId"], 'DispValue', academicCareer)}
                        isDisabled={action !== 'new'}
                    />
                </Col>
                <Col md={4}>
                    <CommonInput
                        label={label.description}
                        type={'text'}
                        placeHolder={placeholder.description}
                        defaultValue={action === 'new' ? headerData["academicCareerDescription"] : (getDispValForReactSelectByOneID(headerData["academicCareerId"],'DescValue',academicCareer))}
                        isDisabled={ true }
                    />
                </Col>
                <Col md={4}>
                    <CommonInput
                        label={label.effectiveDate}
                        type={'text'}
                        placeHolder={placeholder.effectiveDate}
                        defaultValue={action ==='new'?moment().format('DD-MM-YYYY') : (moment(headerData["effectiveDate"]).format('DD-MMM-YYYY'))}
                        isDisabled={true}
                    />
                </Col>
                <Col md={4}>
                    <CommonInput
                        label={label.courseType}
                        mandatory={true}
                        data={courseTypes}
                        type={ action ==='new' ?  'reactSelect' : 'text'}
                        placeHolder={placeholder.courseType}
                        onChange={(value)=>{
                            updateHeaderData('courseType',value);
                        }}
                        defaultValue={ action === 'new'  ? headerData["courseType"] : getDispValForReactSelectByOneID(headerData["courseType"],'DispValue',courseTypes)}
                        isDisabled={action !== 'new'}
                    />
                </Col>
                <Col md={4}>
                    <CommonInput
                        label={label.courseTypeDescription}
                        type={'text'}
                        placeHolder={placeholder.courseTypeDescription}
                        defaultValue={action ==='new' ?  headerData["courseTypeDescription"] : getDispValForReactSelectByOneID(headerData["courseType"],'DescValue',courseTypes)}
                        isDisabled={true}
                    />
                </Col>
                <Col md={4}>
                    <CommonInput
                        label={label.status}
                        mandatory={true}
                        type={action === 'view' ? 'text' : 'reactSelect'}
                        data={status}
                        placeHolder={placeholder.status}
                        onChange={(value)=>updateHeaderData('status',value)}
                        defaultValue={headerData["status"]}
                        isDisabled={action === 'view'}
                    />
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label className="form-control-label">
                            {label.businessArea}<RedAstric />
                        </Label>
                        {action === 'view' ?
                            <DropDownButton direction={'bottom'} data={headerData['selectedBusinessAreaIds']}/>
                            :
                        //     <ReactMultiSelectCheckboxes
                        //     width={'100%'}
                        //     placeholderButtonLabel={placeholder.businessArea}
                        //     options={(action === 'new' || action === 'edit') ? businessAreaValues : headerData['selectedBusinessAreaIds']}
                        //     value={headerData['selectedBusinessAreaIds']}
                        //     onChange={(e) => {
                        //         if (action === 'new' || action === "edit") {
                        //             let tempBusinessAreaId = []
                        //             if (e.length > 0) {
                        //                 updateHeaderData('selectedBusinessAreaIds', e);
                        //                 e.map(item => {
                        //                     if (item.value === '*') {
                        //                         updateHeaderData('selectedBusinessAreaIds', businessAreaValues);
                        //                         tempBusinessAreaId = []
                        //                         tempBusinessAreaId = [...allBusinessAreaIds];
                        //                         return;
                        //                     }
                        //                     tempBusinessAreaId.push(item.value);
                        //                 })
                        //             } else if (e.length === 0) {
                        //                 tempBusinessAreaId = e;
                        //                 updateHeaderData('selectedBusinessAreaIds', []);
                        //             }
                        //             updateHeaderData('businessAreaIds', tempBusinessAreaId);
                        //         }
                        //     }}
                        // />
                            <CommonInput
                            forTable={true}
                            onChange={(e) => {
                                if (action === 'new' || action === "edit") {
                                    let tempBusinessAreaId = []
                                    if (e.length > 0) {
                                        updateHeaderData('isAllSelected', false);
                                        updateHeaderData('selectedBusinessAreaIds', e);
                                        e.map(item => {
                                            if (item.value === '*') {
                                                updateHeaderData('selectedBusinessAreaIds', businessAreaValues);
                                                tempBusinessAreaId = []
                                                tempBusinessAreaId = [...allBusinessAreaIds];
                                                updateHeaderData('isAllSelected', true);
                                                return;
                                            }
                                            tempBusinessAreaId.push(item.value);
                                        })
                                    } else if (e.length === 0) {
                                        tempBusinessAreaId = e;
                                        updateHeaderData('selectedBusinessAreaIds', []);
                                        updateHeaderData('isAllSelected', false);
                                    }
                                    updateHeaderData('businessAreaIds', tempBusinessAreaId);
                                }
                            }}
                            defaultValue={headerData['selectedBusinessAreaIds']}
                            placeHolder={placeholder.businessArea}
                            type={headerData.academicCareerIds?.length === 0 ? 'select' : 'multiSelectWithCheckboxes'}
                            data={businessAreaValues}
                            />
                        }
                    </FormGroup>
                </Col>
                <Col className="mt-4" md="8">
                    <div className="float-right">
                        <span className="d-block "><span className="form-control-label">Last Updated Date : </span><span>{props.updatedOn&&moment(props.updatedOn).format('DD-MM-YYYY') } {props.updatedBy&&formattedTime} </span></span>
                        <span className="d-block "><span className="form-control-label">Last Updated By : </span> <span>{props.updatedBy&&props.updatedBy.trim()} </span></span>
                    </div>
                </Col>
            </Row>
        </Form>
    )
}

export default ItemTypeSequenceHeader;