import {UPDATE_DATA} from "../types";
import {updatePermissions} from "./permissionAction";
const permissionReducer =(state={}, action = updatePermissions)=>{
    switch(action.type){
        case UPDATE_DATA :return{
            ...state,
            ...action.payload
        }
        default:return state
    }
}
export default permissionReducer;