export const newRow = {
    businessArea: { label: '', value: '' },
    userId: '',
    userName: '',
    posId: '',
    imei: '',
    type: { label: '', value: '' },
    status: 'ACTIVE'
}

export const machineTypes = [
    { label: 'Android', value: 'Android' },
    { label: 'Normal', value: 'Normal' },
]