import { endpoint } from "../../common/constant";
import {
  dropdownMapping as dropdownMapping2,
  fetchAllPostPromisedData,
  fetchAllPromisedData,
  updateRequest,
} from "../../common/utils/methods/commonMethods/utilityMethod";
import { dropdownMapping } from "../../edpOps/constant/batch-search";

export const getPrintIdCardDropdowns = async () => {
  const p = await Promise.all([
    dropdownMapping(endpoint.term.getAllActive, "termDispValue"),
    dropdownMapping(
      endpoint.academic_group.getAllActive,
      "academicGroupDispValue"
    ),
    dropdownMapping(
      endpoint.courseCatelogMapping.getAllActiveCourseIds,
      "courseIds"
    ),
    // dropdownMapping(
    //   endpoint.businessArea.getAllActive,
    //   "businessAreaDispValue"
    // ),
    dropdownMapping2(
      endpoint.businessArea.getAllActive,
      "businessAreaDispValue",
      "id",
      "sapPlantId",
      "sapBusinessArea",
      "businessUnitTypeId"
    ),
  ]);
  return {
    terms: p[0],
    academicGroups: p[1],
    courseIds: p[2],
    businessAreas: p[3],
  };
};

export const getBusinessAreaDropdowns = async (businessAreaType = "RCC") => {
  const url =
    businessAreaType === 'RCC'
      ? endpoint.businessArea.getAllActive
      : businessAreaType === 'DLP'
      ? endpoint.businessArea?.getAllDlpActiveBusinessArea
      : endpoint.businessArea.getAllDigitalBusinessArea;

  const p = await Promise.all([
    dropdownMapping2(
      url,
      "businessAreaDispValue",
      "id",
      "sapPlantId",
      "sapBusinessArea",
      "businessAreaDesc"
    ),
  ]);

  return {
    businessAreas: p[0],
  };
};

export const getBatchList = (request) => {
  return fetchAllPostPromisedData(
    `${endpoint.batchList.fetchAllBatches}=${-1}`,
    { ...updateRequest(request) },
    "post"
  );
};

export const getCourseList = (request) => {
  return fetchAllPromisedData(
    `${endpoint.course.getCourseIdsForBuAcadAndTerm}?businessAreaId=${request.businessAreaId.value}&termId=${request.termId.value}&academicGroup=${request?.academicGroup?.value||''}`,
    true
  );
};

export const getStudentsByPtmId = (id) => {
  return fetchAllPromisedData(
    `${endpoint.timetable.getAllPsidByBatchTimeTableId}/${id}`,
    true
  );
};

export const getStudentApplications = (request, pageNo) => {
  return fetchAllPostPromisedData(
    `${endpoint.allocateStudents.searchStudentApplications}?offset=${pageNo}`,
    updateRequest(request),
    "post"
  );
};

export const studentCountWithNoRfId = (businessArea) => {
  return fetchAllPostPromisedData(
    `${endpoint.studentDetails.studentCountWithNoRfId}`,
    updateRequest({ businessArea }),
    "post"
  );
};

export const mapRfidWithApplication = (request) => {
  return fetchAllPostPromisedData(
    endpoint?.idCardIssuance?.mapRfidWithApplication,
    request,
    "post"
  );
};
export const getPrintableData = (list = []) => {
  return fetchAllPostPromisedData(
    `${endpoint.studentDetails.getPrintableData}`,
    { printStudentRfidRequest: list },
    "post"
  );
};

export const toggleRfidStatus = (request) => {
  return fetchAllPostPromisedData(
    endpoint?.idCardIssuance?.rfidStatusChange,
    request,
    "post"
  );
};

export const checkIfRfidExists = (rfid) => {
  return fetchAllPromisedData(
    `${endpoint?.idCardIssuance?.checkIfRfidExists}/${rfid}`,
    true
  );
};
