import React, { useState } from "react";
import ADataGrid from "views/pages/manage/common/data-grid/ADataGrid";
import {
  gridComponents,
  gridStyles,
} from "../edpOps/issuanceIDcard/printIdCardAndMapRfid/constant";
import { CardBody } from "reactstrap";
import { pendingDispatchTableColumn } from "./Helper";
import BulkSelectionFooter from "./BulkSelectionFooter";
import NoRowOverlay from "../masters/holidayMaster/NoRowOverlay";
const ManualAttendanceTable = ({
  rowData = [],
  rowCount = 0,
  nextButtonHandler = () => {},
  currentPage,
  errorFields,
  setErrorFields,
  values,
  setValues,
  data,
  setData,
  serverData,
  setServerData,
  isFetching,
  setIsFetching,
  toggleCollapse,
  filterData,
  setFilterData,
  tableData,
  setTableData,
  fetchFilterData,
  filterSwitch,
  setfilterSwitch,
  isFlow1,
  setIsFlow1,
  fetchFilter,
  globalFilter,
}) => {
  const [studentList, setStudentList] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);

  const getPrintedRows = (selectionModel) => {
    const studData = selectionModel?.map((rowString) => {
      return JSON?.parse(rowString);
    });
    setStudentList(studData);
  };
  return (
    <CardBody>
      <ADataGrid
        removeWrapperContainer
        rows={rowData || []}
        columns={pendingDispatchTableColumn}
        rowId={(row) => JSON?.stringify(row)}
        {...(isFlow1
          ? { serverPagination: false }
          : {
              rowCount: rowCount,
              nextButtonHandler: nextButtonHandler,
              currentPage: currentPage,
            })}
        rowHeight={50}
        height={300}
        loading={false}
        hideFooterSelectedRowCount={true}
        checkboxSelection={true}
        disableSelectionOnClick
        selectionModel={selectionModel}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
          getPrintedRows(newSelectionModel);
        }}
        // isRowSelectable={isRowSelectable}
        components={{
          ...gridComponents,
          NoRowsOverlay: () => <NoRowOverlay />,
        }}
        sx={gridStyles}
        autoHeight
      />
      <BulkSelectionFooter
        errorFields={errorFields}
        setErrorFields={setErrorFields}
        values={values}
        setValues={setValues}
        data={data}
        setData={setData}
        serverData={serverData}
        setServerData={setServerData}
        isFetching={isFetching}
        setIsFetching={setIsFetching}
        toggleCollapse={toggleCollapse}
        filterData={filterData}
        setFilterData={setFilterData}
        tableData={tableData}
        setTableData={setTableData}
        fetchFilterData={fetchFilterData}
        filterSwitch={filterSwitch}
        setfilterSwitch={setfilterSwitch}
        selectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        studentList={studentList}
        setStudentList={setStudentList}
        isFlow1={isFlow1}
        setIsFlow1={setIsFlow1}
        fetchFilter={fetchFilter}
        globalFilter={globalFilter}
      />
    </CardBody>
  );
};

export default ManualAttendanceTable;
