import React, { useState } from "react";
import { Input, Button, Table } from "reactstrap";
import { MasterHeaderAuto } from "../common/commonComponents/masterHeader/masterHeader";
import { endpoint } from '../common/constant';
import validationRules from './validationRule.json';
import CommonInput from "../common/formFeilds/input/commonInput";
import { customValidation, isValidIpAddress } from "../common/utils/methods/validations/validation";
import BooleanStatus from "../common/formFeilds/status/booleanStatus"

import {
  putpost2,
  putpost,
  successToast,
  failureToast
} from "services/http";
import moment from "moment";
import {
  CustomCard,
  CustomContainer
} from "../common/commonComponents";

const HeaderElement = ({
  newRow,
  isSaveVisible,
  searchStr,
  setSearchStr = () => { }
}) => {
  return (
    <>
      <h3>Offline Attendance Setup</h3>
      <div className="d-flex flex-lg-row flex-sm-column align-items-center">
        <div className="mr-4">
          <CommonInput
            forTable={true}
            type="text"
            defaultValue={searchStr}
            placeHolder="Search Location Name"
            onChange={(e) => setSearchStr(e)}
          />
        </div>
        <Button
          disabled={isSaveVisible}
          color="info"
          size="sm"
          type="button"
          className={"floatRight mx-1 md-mt-3 md-floatRight" + (isSaveVisible ? " btn-dark" : null)}
          onClick={newRow}
        >
          Add New
        </Button>
      </div>
    </>
  );
};

var _ = require("lodash");

const OfflineAttendanceSetupTable = (props) => {
  const {
    isSaveVisible,
    setisSaveVisible = () => { },
    pagination,
    classArray,
    searchStr,
    setSearchStr = () => { }
  } = props;

  const [tableDataKey, settableDataKey] = useState("tableDataKey");
  const [isNewDocument, setIsNewDocument] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);

  const getAllData = () => {
    props.fetchClasses();
  }

  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: "__new",
        accessToken: "",
        deletePunchEnabled: true,
        fetchPunchEnabled: true,
        locationCode: "",
        locationName: "",
        machineIp: "",
        machineNo: "",
        machinePort: "",
        machineTypeId: ""
      },
      ...props.classArray,
    ]);
  };

  const headerList = [
    { name: "S.No" },
    { name: "Location Name", isRequired: true },
    { name: "Machine IP", isRequired: true },
    { name: "Location Code", isRequired: true },    
    { name: "Machine N0", isRequired: true },
    { name: "Machine Port", isRequired: true },
    { name: "MachineType Id", isRequired: true },   
    { name: "Fetch Punch Enabled", isRequired: true },
    { name: "Delete Punch Enabled", isRequired: true },
    { name: "AccessToken", isRequired: true },
    { name: "Actions" },
  ];

  const listToBeRendered = [
    "locationName",
    "machineIp",
    "locationCode",
    "machineNo",
    "machinePort",
    "machineTypeId",
    "fetchPunchEnabled",
    "deletePunchEnabled",
    "accessToken"
  ];

  // const colunmWidths = [1, 1, 3, 1, 1, 1, 1]

  // const itemToBeRenderFromMaster = ["subjectMasterId"];
  // const propertyList = [null, "text", null, null, null, null, null];
  // const arrList = [null, allSubjectData, null, null, null, null, null];

  const saveAll = (cb, index) => {
    const _tempArray = classArray;
    let isValid = true;

    isValid = customValidation(_tempArray[index], validationRules);

    if (!isValid) {
      return;
    }

    if (isValid && _tempArray[index].machineIp) {
      isValid = isValidIpAddress(_tempArray[index].machineIp, "Machine IP");
    }

    if (!isValid) {
      return;
    }

    if (isValid && _tempArray[index].machinePort) {
      isValid = Number(_tempArray[index].machinePort) > 65535 ? false : true;
    }

    if (!isValid) {
      failureToast("Machine Port Cannot Be Greater Than 65535")
      return;
    }

    if (_tempArray[index]["__status"] == "__new") {
      putpost(
        endpoint.offlineAttendance.endpoint,
        (data) => {
          successToast(data["message"]);
          setisSaveVisible(false);
          getAllData();
        },
        (data) => {
          failureToast(data["message"]);
        },
        {
          ..._tempArray[index],
          machinePort : Number(_tempArray[index]['machinePort']),
          machineNo : Number(_tempArray[index]['machineNo']),
          machineTypeId : Number(_tempArray[index]['machineTypeId']),
        },
        "post"
      );
    } else {
      const updatedValues = _tempArray[index];
      putpost(
        `${endpoint.offlineAttendance.endpoint}/${_tempArray[index].machineIp}`,
        (data) => {
          successToast(data["message"]);
          setisSaveVisible(false);
          props.fetchClasses(
            endpoint.subSubTopicMaster.getAll
          );
          // delete _tempArray[index]["editMode"];
          // console.log(_tempArray[index]);
          // props.setclassArray(_tempArray);
          settableDataKey(new Date().getMilliseconds());
        },
        (data) => {
          failureToast(data["message"]);
        },
        {
          ...updatedValues,
          machinePort : Number(_tempArray[index]['machinePort']),
          machineNo : Number(_tempArray[index]['machineNo']),
          machineTypeId : Number(_tempArray[index]['machineTypeId']),
        },
        "put"
      );
    }
  }

  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
        setIsNewDocument(true);
        // settableDataKey(new Date().getMilliseconds())
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
      cb();
    }
  };

  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]["editMode"] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
      };
      cb();
    }
  };
  const deleteRow = (index) => {
    if (props.classArray[index]["__status"] == "__new") {
      let n = props.classArray;
      n.splice(index, 1);
      props.setclassArray(n);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
    } else {
      if (
        window.confirm("Are you sure you want to delete this offline attendance source?")
      ) {
        putpost2(
          `${endpoint.offlineAttendance.endpoint}/${props.classArray[index].machineIp}`,
          (data) => {
            successToast(data["message"]);
            getAllData();
            settableDataKey(new Date().getMilliseconds());
          },
          (data) => {
            failureToast(data["message"]);
          },
          "PUT"
        );
      }
    }
  };
  const updateKey = (index, key, value) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
  };

  return (
    <CustomContainer>
      <CustomCard
        HeaderElement={
          <HeaderElement
            isSaveVisible={isSaveVisible}
            newRow={newRow}
            searchStr={searchStr}
            setSearchStr={setSearchStr}
          />
        }
      >
        <div id="questionTable" className="table-responsive-layout no_custom_col_width">
          <Table className="align-items-center table-flush">
            <MasterHeaderAuto headerList={headerList} />
            <tbody className="list" key={tableDataKey}>
              {props.classArray
                ? props.classArray.map((el, index) =>
                  !el["editMode"] ? (
                    <tr
                    // data={{
                    //   ...el,
                    //   sno: isNewDocument
                    //     ? index + (pagination.currentPage - 1) * 10
                    //     : index + 1 + (pagination.currentPage - 1) * 10,
                    // }}
                    // listToBeRendered={listToBeRendered}

                    >
                      <td className="text-center  white-breakSpace">
                        {index + 1 + (pagination.currentPage - 1) * 10}
                      </td>
                      {
                        listToBeRendered.map((item, i) => {
                          if (item === 'deletePunchEnabled' || item === 'fetchPunchEnabled') {
                            return <td className="text-center  white-breakSpace">{
                              el[item] == false ? (
                                <button disabled={true} type="button" className="btn btn-sm btn-warning">
                                  False
                                </button>
                              ) : (
                                <button type="button" disabled={true} className="btn btn-sm btn-success">
                                  True
                                </button>
                              )
                            }</td>
                          } else {
                            return <td className="text-center  white-breakSpace">{el[item]}</td>
                          }
                        })
                      }
                      <td className="text-center  white-breakSpace">
                        <Button
                          color="info"
                          size="sm"
                          type="button"
                          disabled={props.isViewOnly}
                          onClick={() => editRow(index)}
                        // data-testid="editButton"
                        >
                          <i className="fas fa-edit" />
                        </Button>
                        <Button
                          color="danger"
                          size="sm"
                          type="button"
                          disabled={props.isViewOnly}
                          onClick={() => deleteRow(index)}
                        // data-testid="deletebtn"
                        >
                          <i className="fas fa-trash" />
                        </Button>
                      </td>
                    </tr>
                  ) : (
                    <tr key={index + "-class"}>
                      <td className="text-center p-2 mx-1">
                        {index + 1 + (pagination.currentPage - 1) * 10}
                      </td>
                      <td className="text-center p-2 mx-1">
                        <Input
                          type="text"
                          defaultValue={el['locationName']}
                          id="offline_attendance-LocationName"
                          key="offline_attendance-LocationName"
                          maxLength="50"
                          placeHolder="Location Name"
                          forTable={true}
                          onChange={(e) => updateKey(index, 'locationName', e.target.value)}
                        />
                      </td>
                      <td className="text-center">
                        <Input
                          type="text"
                          defaultValue={el['machineIp']}
                          id="offline_attendance-MachineIP"
                          key="offline_attendance-MachineIP"
                          maxLength="15"
                          placeHolder="Machine IP"
                          forTable={true}
                          onChange={(e) => updateKey(index, 'machineIp', e.target.value)}
                          onKeyPress={(e) => {
                            if (!/^[0-9]*\.?[0-9]*$/.test(String.fromCharCode(e.charCode))) {
                              e.preventDefault();
                              return;
                            }
                          }}
                          disabled={el['__status'] == '__new' ? false : true}
                        />
                      </td>
                      <td className="text-center p-2 mx-1">
                        <Input
                          type="text"
                          defaultValue={el['locationCode']}
                          id="offline_attendance-LocationCode"
                          key="offline_attendance-LocationCode"
                          maxLength="50"
                          placeHolder="Location Code"
                          forTable={true}
                          onChange={(e) => updateKey(index, 'locationCode', e.target.value)}
                        />
                      </td>
                      <td className="text-center p-2 mx-1">
                        <Input
                          type="text"
                          defaultValue={el['machineNo']}
                          id="offline_attendance-MachineNo"
                          key="offline_attendance-MachineNo"
                          maxLength="8"
                          onPaste={(e)=> {e.preventDefault(); return }}
                          placeHolder="Machine No"
                          forTable={true}
                          onChange={(e) => updateKey(index, 'machineNo', e.target.value)}
                          onKeyPress={(e) => {
                            if (!/^[0-9.]+$/.test(String.fromCharCode(e.charCode))) {
                              e.preventDefault();
                              return;
                            }
                          }}
                        />
                      </td>
                      <td className="text-center p-2 mx-1">
                        <Input
                          type="text"
                          defaultValue={el['machinePort']}
                          id="offline_attendance-MachinePort"
                          key="offline_attendance-MachinePort"
                          maxLength="5"
                          placeHolder="Machine Port"
                          forTable={true}
                          onPaste={(e)=> {e.preventDefault(); return }}
                          onChange={(e) => updateKey(index, 'machinePort', e.target.value)}
                          onKeyPress={(e) => {
                            if (!/^[0-9.]+$/.test(String.fromCharCode(e.charCode))) {
                              e.preventDefault();
                              return;
                            }
                          }}
                        />
                      </td>
                      <td className="text-center">
                        <Input
                          type="text"
                          defaultValue={el['machineTypeId']}
                          id="offline_attendance-MachineTypeId"
                          key="offline_attendance-MachineTypeId"
                          maxLength="8"
                          placeHolder="Machine Type Id"
                          forTable={true}
                          onPaste={(e)=> {e.preventDefault(); return }}
                          onChange={(e) => updateKey(index, 'machineTypeId', e.target.value)}
                          onKeyPress={(e) => {
                            if (!/^[0-9.]+$/.test(String.fromCharCode(e.charCode))) {
                              e.preventDefault();
                              return;
                            }
                          }}
                        />
                      </td>
                      <td className="text-center p-2 mx-1">
                        <BooleanStatus
                          value={el['fetchPunchEnabled']}
                          onChange={(n) => {
                            updateKey(index, 'fetchPunchEnabled', n);
                            settableDataKey(new Date());
                          }}
                        />
                      </td>
                      <td className="text-center p-2 mx-1">
                        <BooleanStatus
                          value={el['deletePunchEnabled']}
                          onChange={(n) => {
                            updateKey(index, 'deletePunchEnabled', n);
                            settableDataKey(new Date());
                          }}
                        />
                      </td>
                      <td className="text-center p-2 mx-1">
                        <Input
                          type="text"
                          defaultValue={el['accessToken']}
                          id="offline_attendance-AccessToken"
                          key="offline_attendance-AccessToken"
                          maxLength="50"
                          placeHolder="Access Token"
                          forTable={true}
                          onChange={(e) => updateKey(index, 'accessToken', e.target.value)}
                        />
                      </td>

                      <td className="text-center">
                        <Button
                          color="info"
                          size="sm"
                          type="button"
                          className="mx-1"
                          onClick={() => saveAll("", index)}
                        >
                          Save
                        </Button>
                        <Button
                          color="danger"
                          size="sm"
                          type="button"
                          onClick={() => {
                            getAllData()
                            setisSaveVisible(false);
                          }}
                        >
                          {' '}
                          <i className="fas fa-times" />
                        </Button>{' '}
                      </td>
                    </tr>
                  )
                )
                : null}
            </tbody>
          </Table>
        </div>
      </CustomCard>
    </CustomContainer >
  );
}
export default OfflineAttendanceSetupTable;
