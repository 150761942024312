import React from "react";
import { ReactComponent as Active } from "../../../../../../assets/img/svg/Active-warning.svg";
import AButton from "./../../../common/form-fields-mui/AButton";
import { outlineButtonStyle } from "./../../academicTest/style";

const ChangePlanWarning = ({onAgree}) => {
  return (
    <>
      <div className="plan-name-warning">
        <span>
          Your all saved information, date slot, time slot, invigilator and room
          will be lost.
        </span>
        <Active />
      </div>
      <AButton updatedStyle
       ={{...outlineButtonStyle,color:"#00B0F5", fontSize:"16px", marginTop:"20px"}}

       onClick = {onAgree}
       >
        I understand and I want to change the academic plan
      </AButton>
    </>
  );
};

export default ChangePlanWarning;
