import { Checkbox, FormControlLabel } from '@mui/material'
import React from 'react'
import { primaryCheckboxStyles } from 'views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant.js'

const checkBoxStyle = { borderRadius: "6px", padding: '0 5px' };
const formControlLabeStyle = { marginBottom: 0, marginRight: 0, ...primaryCheckboxStyles };

const SelectSearchInputParam = ({ searchInputParam, setSearchInputParam, isHideName = false, width = "260px", disabled = false }) => {
  return (
    <div className='d-flex justify-content-between align-items-center' style={{ width: width, padding: '0.5rem' }}>
      <div>
        <FormControlLabel
          sx={formControlLabeStyle}
          control={
            <Checkbox
              onChange={(e) => e.target.checked && setSearchInputParam("psid")}
              checked={searchInputParam === 'psid'}
              sx={checkBoxStyle}
            />
          }
          disabled={disabled}
          label={<span style={{ fontSize: '12px' }}>PSID</span>}
        />
      </div>
      <div>
        <FormControlLabel
          sx={formControlLabeStyle}
          control={
            <Checkbox
              onChange={(e) => e.target.checked && setSearchInputParam("applicationId")}
              checked={searchInputParam === 'applicationId'}
              sx={checkBoxStyle}
            />
          }
          disabled={disabled}
          label={<span style={{ fontSize: '12px' }}>Application ID</span>}
        />
      </div>
      {!isHideName && <div>
        <FormControlLabel
          sx={formControlLabeStyle}
          control={
            <Checkbox
              onChange={(e) => e.target.checked && setSearchInputParam("studentName")}
              checked={searchInputParam === 'studentName'}
              sx={checkBoxStyle}
            />
          }
          disabled={disabled}
          label={<span style={{ fontSize: '12px' }}>Name</span>}
        />
      </div>}
    </div>
  )
}

export default SelectSearchInputParam