import React from "react";
import { IconButton } from "@mui/material";

const IconButtonWrapper = ({ className, children, sx, ...restProps }) => {
  const customSx = {
    "&:hover:not(:disabled)": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    "&.Mui-disabled": {
      cursor: "not-allowed",
      pointerEvents: "unset",
    },
    svg: {
      cursor: "unset !important",
      margin: "0 !important",
    },
  };
  return (
    <IconButton className={`ml-0 ${className}`} sx={{...customSx, ...sx}} {...restProps}>
      {children}
    </IconButton>
  );
};

export default IconButtonWrapper;
