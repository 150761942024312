import React, { useEffect, useState } from 'react';
import APopover from '../../../common/a-popover';
import { IoEyeOutline } from 'react-icons/io5';
import ALoader from '../../../common/a-loader';
import { getCourseDetailsV2 } from '../service';


const CourseNameModal = ({ rowData, closeForm }) => {
  const { courseId } = rowData || {};
  const [apiLoader, setApiLoader] = useState(false);
  const [courseDetails, setCourseDetails] = useState({});
  const fetchData = async () => {
    setApiLoader(true);
    const res = await getCourseDetailsV2(courseId);
    setCourseDetails(res);
    setApiLoader(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ width: '300px' }} className="p-3">
      {apiLoader && <ALoader />}
      <div className="semi-bold mb-2">Misc. course name</div>
      {apiLoader ? (
        <div className="text-center regular-body color-grey-90">Loading...</div>
      ) : (
        <div className='regular-body color-grey-90'>{courseDetails?.courseName}</div>
      )}
    </div>
  );
};
const MiscCourseIdCellComponent = ({ params }) => {
  const { row } = params || {};
  return row?.courseId ? (
    <div className="d-flex">
      <div>{row?.courseId}</div>
      <div className="ml-2">
        <APopover
          anchorOriginHorizontal="center"
          transformOriginHorizontal='center'
          buttonComponent={onclick => (
            <IoEyeOutline className="pointer" onClick={onclick} size={18} />
          )}
          menuComponent={closeForm => (
            <CourseNameModal rowData={row} closeForm={closeForm} />
          )}
        />
      </div>
    </div>
  ) : (
    <div>-</div>
  );
};

export default MiscCourseIdCellComponent;
