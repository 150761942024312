import React, { useState, useEffect } from "react";
import ProfitMappingTable from './profitCenterMappingTable';
import {getAPI,masterServiceBaseUrl,failureToast} from 'services/http';
import Loader from 'react-loader-spinner';
import { GetPagination } from '../../common/pagination';
import AcademicCareer from "../academicCareer/academicCareer";
const ProfitCenterMapping = () => {
    const [profitDataList, setProfitDataList] = useState([])
    const [apiLoader, setApiLoader] = useState(false)
    const [profitCenterMapping, setProfitCenterMapping] = useState([])
    const [ activeGroupCode, setActiveGroupCode ] = useState( [] );
    const [ activeCompanyCode, setActiveCompanyCode ] = useState( [] );
    const [activeAcademicGroup, setActiveAcademicGroup] = useState([])
    const [ activeAcademicCareer, setActiveAcademicCareer ] = useState( [] );
    const [ activeBusinessUnitType, setActiveBusinessUnitType ] = useState( [] );
    const [sapBranchType,setSapBranchType] = useState( [] );
    const [sapCareerType,setSapCareerType] = useState( [] );
    const [sapAcademicCareer,setSapAcademicCareer] = useState( [] );
    const [sapSegment,setSapSegment] = useState( [] );
    const [pagination, setPagination] = useState({
        currentPage: 1,
        nextPage: null,
        previousPage: null,
        totalPage: null
    })
    const pageSize=10
    const fetchProfitCenterData = async (url) => {
        setApiLoader(true)
        getAPI( url, ( data ) => {
        setProfitDataList( data[ 'data' ] )
        let n = pagination
        if (data['data'].length > pageSize) {
            n['nextPage'] = n.currentPage + 1
        }
        if (n.currentPage > 1) {
            n.previousPage = n.currentPage - 1
        }
        n['totalPage'] =Math.floor( data['data'].length / pageSize)
        if(data['data'].length%pageSize!=0){
            n['totalPage']+=1
        }
        setPagination(n)
        setApiLoader(false)
        setProfitCenterMapping(data['data'].slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || [])
        }, (data) => { failureToast(data['message']) })
    }
    const nextPage = () => {
        setProfitCenterMapping(profitDataList.slice((pagination.currentPage + 1) * (pageSize) - pageSize, (pagination.currentPage + 1) * pageSize))
        let n = pagination
        n['currentPage'] = n['currentPage'] + 1
        n['previousPage'] = n.currentPage
        setPagination(n)
    }
    const firstPage = () => {
        setProfitCenterMapping(profitDataList.slice(1 * (pageSize) - pageSize, 1 * pageSize))
        let n = pagination
        n['currentPage'] = 1
        n['previousPage'] = null
        setPagination(n)
    }
    const lastPage = () => {
        setProfitCenterMapping(profitDataList.slice((pagination.totalPage) * (pageSize) - pageSize, (pagination.totalPage) * pageSize))
        let n = pagination
        n['currentPage'] = n.totalPage
        n['previousPage'] = n.totalPage-1
        setPagination(n)
    }
    const previousPage = () => {
        setProfitCenterMapping(profitDataList.slice((pagination.currentPage-1) * (pageSize) - pageSize, (pagination.currentPage-1) *pageSize  ))
        let n = pagination
        n['currentPage'] = n['currentPage'] -1
        if(n['currentPage']==1){
        n['previousPage'] = null
        }else{
        n['previousPage'] = n.currentPage
        }
        setPagination(n)
    }

    const fetchDropDownDetails = (url, stateMethod, type) => {    
        try {
            getAPI( url, ( data ) => {
                refactorData( data[ 'data' ], stateMethod, type );
            }, ( data ) => { failureToast( data[ 'message' ] ) } )
        } catch ( e ) {
            console.log(e)
        }
    }
  const refactorData = ( data, stateMethod, type ) => {
      try {
            switch ( type ) {
                case "GroupCode":
                    iterateData( data, "id", "groupCodeDispValue", stateMethod );
                    break;
                case "CompanyCode":
                    iterateData( data, "id", "companyCodeDispValue", stateMethod );
                    break;
                case "BusinessUnitType":
                    setDropdownValue( data,"BusinessUnit");                    
                    break;
                case "AcademicCareer":
                    setDropdownValue( data,"AcademicCareer" );                    
                    break;
                case "AcademicGroup":
                    setDropdownValue( data,"AcademicGroup" );                    
                    break;
                default:
                    break;
            }
        } catch ( e ) {
            console.log( "Error", e )
        }
    }

    const setDropdownValue = (data,type) => {
        try {            
            let temp= [];
            switch (type) {
                case "BusinessUnit":
                    data.map( item => {
                        temp.push( {
                            id: item.id,
                            text: item.sapBranchTypeSequence + '-' + item.sapBranchType,
                            sequenceNumber:item['sapBranchTypeSequence']
                        } )
                    } );
                    setSapBranchType(temp);
                    break;
                case "AcademicCareer":
                    let tempCareer = [];
                    let tempAcademic = [];
                    data.map( item => {
                        tempCareer.push( {
                            id: item.id,
                            text: item.sapAcademicTypeSequence + '-' + item.sapAcademicType,
                            displayVal: item.academicCareerDispValue,
                            sequenceNumber:item['sapAcademicCareerSequence']
                        } )
                        tempAcademic.push( {
                            id: item.id,
                            text: item.sapAcademicCareerSequence + '-' + item.sapAcademicCareer,                            
                            sequenceNumber:item['sapAcademicTypeSequence']
                        })
                    } )
                    setSapCareerType(tempCareer);
                    setSapAcademicCareer(tempAcademic);
                    break;
                case "AcademicGroup":
                    data.map( item => {
                        temp.push( {
                            id: item.id,
                            text: item.sapSegmentTypeSequence + '-' + item.sapSegmentType,
                            displayVal: item.academicGroupDispValue,
                            sequenceNumber: item[ 'sapSegmentTypeSequence' ]
                        } )
                    } );
                    setSapSegment(temp)
                    break;
                default:
                    break;
            }
        } catch ( e ) {
            console.log(e)
        }
    }

    const iterateData = ( data, id, property, stateMethod ) => {
        try {
            let temp = [];
            data.map( item => {
                temp.push( {
                    id: item[ id ],
                    text: item[ property ]
                } )
            } )
            stateMethod( temp )
        } catch ( e ) {
            console.log( "Error", e )
        }
    }
    
    const getDisplayValue = ( id, type ) => {
        try {
            switch ( type ) {
                case "GroupCodeID":
                    return findDisplayValue( activeGroupCode, id );
                    break;
                case "CompanyCodeID":
                    return findDisplayValue( activeCompanyCode, id );
                    break;
                case "AcademicCareerID":
                    return findDisplayValueAcademic( sapCareerType, id );
                    break;
                case "AcademicGroupID":
                    return findDisplayValueAcademic( sapSegment, id );
                    break;
                case "BranchType":
                    return findDisplayValue( sapBranchType, id );
                    break;
                case "CareerType":
                    return findDisplayValue( sapCareerType, id );
                    break;
                case "AcademicType":
                    return findDisplayValue( sapAcademicCareer, id );
                    break;
                case "SegmentType":
                    return findDisplayValue( sapSegment, id );
                    break;
                    
                default:
                    break;
            }
        } catch ( e ) {
            console.log( "Error", e )
        }
    }

    const findDisplayValueAcademic = ( datalist, id ) => {
        try {
            const findIndex = datalist.findIndex( item => item[ 'id' ] == id );
             return findIndex >= 0 ? datalist[ findIndex ][ 'displayVal' ] : "NOT FOUND";
        } catch ( e ) {
            console.log( "Error", e )
        }
        
    }

    const findDisplayValue = ( datalist, id ) => {
        try {
            const findIndex = datalist.findIndex( item => item[ 'id' ] == id );
            return findIndex >= 0 ? datalist[ findIndex ][ 'text' ] : "NOT FOUND";
        } catch ( e ) {
            console.log( "Error", e )
        }
        
    }
    useEffect( () => {
        fetchProfitCenterData( masterServiceBaseUrl + '/profitCenterMappingPage/getAllProfitCenterMappingPage' );
        fetchDropDownDetails( masterServiceBaseUrl + '/groupCode/getAllActiveGroupCode', setActiveGroupCode, "GroupCode" );
        fetchDropDownDetails( masterServiceBaseUrl + '/companyCode/getAllActiveCompanyCode', setActiveCompanyCode, "CompanyCode" );
        fetchDropDownDetails( masterServiceBaseUrl + '/businessUnitType/getAllBusinessUnitType', setActiveBusinessUnitType, "BusinessUnitType" );
        fetchDropDownDetails( masterServiceBaseUrl + '/academicCareer/getAllAcademicCareer', setActiveAcademicCareer, "AcademicCareer" );
        fetchDropDownDetails( masterServiceBaseUrl + '/academicGroup/getAllAcademicGroup', setActiveAcademicGroup, "AcademicGroup" );
           
    }, [] );
    
  return (
      <>
     {apiLoader ? <div className="mx-auto text-center py-5 my-5" style={{ height: "100vh" }}><Loader
        type="Rings"
        color="#00BFFF"
        height={70}
        width={70}
        timeout={10000} /></div> : <div></div>}
          <ProfitMappingTable
              profitCenterMapping={ profitCenterMapping } fetchProfitCenterData={ fetchProfitCenterData }
              setProfitCenterMapping={ setProfitCenterMapping } profitDataList={ profitDataList }
              pagination={ pagination } setPagination={ setPagination } getDisplayValue={ getDisplayValue }
              activeGroupCode={ activeGroupCode } sapBranchType={ sapBranchType } sapCareerType={ sapCareerType }
              sapAcademicCareer={sapAcademicCareer} sapSegment={sapSegment}
              />
      {pagination.totalPage>1?<GetPagination fetchClasses={profitDataList} pagination={pagination} previousPage={previousPage} firstPage={firstPage} lastPage={lastPage} nextPage={nextPage} setpagination={setPagination} />:null}
 </>
  );
}

export default ProfitCenterMapping;