import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  Input,
  Container,
  Button,
  Table,
  Row,
  Col,
} from 'reactstrap';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import CustomLoader from '../../../common/commonComponents/Loader/loader';
import { masterServiceBaseUrl, getAPI, failureToast } from 'services/http';

const CommonHeader = (props) => {
  const {
    companyCodeData,
    groupCodeData,
    businessAreaCodeData,
    cashierCodeOfficeData,
    courseIdData,
    termCodeData,
    component,
    paymentSchedule,
    businessAreaID,
    courseDetailID,
  } = props;
  const [apiLoader, setApiLoader] = useState(false);
  const [businessAreaData, setBusinessAreaData] = useState([]);
  const [courseDetailData, setCourseDetailData] = useState([]);
  const [businessGrp, setBusinessGrp] = useState('');
  const [companyCode, setCompanyCode] = useState('');
  const [businessArea, setBusinessArea] = useState('');
  const [businessAreaName, setBusinessAreaName] = useState('');
  const [cashierKey, setCashierKey] = useState('');
  const [cashierDescription, setCashierDescription] = useState('');
  const [courseId, setCourseId] = useState('');
  const [courseName, setCourseName] = useState('');
  const [term, setTerm] = useState('');

  useEffect(() => {
    try {
      if (businessAreaID) {
        fetchDetails(
          '/businessArea/getBusinessArea/',
          setBusinessAreaData,
          businessAreaID
        );
      }
      if (courseDetailID) {
        fetchDetails(
          '/courseDetail/getCourseDetailByCourseId/',
          setCourseDetailData,
          courseDetailID
        );
      }
    } catch (error) {
      console.log('error', error);
    }
  }, [businessAreaID, courseDetailID]);

  // console.log('paymentSchedule__', paymentSchedule);
  // console.log('businessAreaID__', businessAreaID);
  // console.log('courseDetailID__', courseDetailID);
  // console.log('businessAreaData__', businessAreaData);
  // console.log('courseDetailData__', courseDetailData);
  // console.log('courseDetailData__ID', courseDetailData[0].id);

  const getNameById = (id, list, type) => {
    try {
      // console.log('ItemID__', id);
      // console.log('List__', list);
      // console.log('type__', type);
      const item = list.filter((item) => {
        return item && item.id == id;
      });
      // console.log('item__', item);

      let name = '';
      if (type === 'Display') {
        name = item.length ? item[0].text : 'Not Found';
        // console.log('Name__', name);
        return name;
      } else if (type === 'Description') {
        name = item.length ? item[0].desc : 'Not Found';
        // console.log('Name__', name);
        return name;
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  const fetchDetails = (url, stateMethod, id) => {
    try {
      setApiLoader(true);
      getAPI(
        `${masterServiceBaseUrl}${url}${id}`,
        (data) => {
          stateMethod(data.data);
          setApiLoader(false);
        },
        (data) => {
          failureToast(data['message']);
          setApiLoader(false);
        }
      );
    } catch (e) {
      console.log('Error', e);
    }
  };

  useEffect(() => {
    try {
      if (groupCodeData && businessAreaData) {
        setBusinessGrp(
          getNameById(businessAreaData.groupCodeId, groupCodeData, 'Display')
        );
      }
      if (companyCodeData && businessAreaData) {
        setCompanyCode(
          getNameById(
            businessAreaData.companyCodeId,
            companyCodeData,
            'Display'
          )
        );
      }
      if (businessAreaCodeData && businessAreaData) {
        setBusinessArea(
          getNameById(businessAreaData.id, businessAreaCodeData, 'Display')
        );
      }
      if (businessAreaCodeData && businessAreaData) {
        setBusinessAreaName(
          getNameById(businessAreaData.id, businessAreaCodeData, 'Description')
        );
      }
      if (cashierCodeOfficeData && paymentSchedule) {
        setCashierKey(
          getNameById(
            paymentSchedule.cashierOfficeId,
            cashierCodeOfficeData,
            'Display'
          )
        );
      }
      if (cashierCodeOfficeData && paymentSchedule) {
        setCashierDescription(
          getNameById(
            paymentSchedule.cashierOfficeId,
            cashierCodeOfficeData,
            'Description'
          )
        );
      }
      if (courseIdData && courseDetailData && courseDetailData[0]) {
        setCourseId(
          getNameById(courseDetailData[0].id, courseIdData, 'Display')
        );
      }
      if (courseIdData && courseDetailData && courseDetailData[0]) {
        setCourseName(
          getNameById(courseDetailData[0].id, courseIdData, 'Description')
        );
      }
      if (termCodeData && paymentSchedule) {
        setTerm(getNameById(paymentSchedule.termId, termCodeData, 'Display'));
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    businessAreaData,
    courseDetailData,
    groupCodeData,
    companyCodeData,
    businessAreaCodeData,
    cashierCodeOfficeData,
    courseIdData,
    termCodeData,
    paymentSchedule,
  ]);

  return (
    // <Container fluid className={'mt-3'}>
    <>
      {apiLoader ? (
        <div
          className="mx-auto text-center py-5 my-5"
          style={{ height: '100vh' }}
        >
          <CustomLoader
            apiLoader={apiLoader}
            loaderHeight={'200px'}
            loaderWidth={'100% '}
          />
        </div>
      ) : (
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">{props.title}</h3>
              </CardHeader>
            </Card>
            {/* <Card className={'p-3'}>
            <h4>{props.title}</h4>
          </Card> */}
            <Card className={'p-3'}>
              <div className="form-row mb-3">
                <Col className="mb-3" md="6">
                  <label className="form-control-label">Business Group</label>
                  <Input
                    value={businessGrp}
                    type="text"
                    disabled
                    maxLength="50"
                    style={{ height: '39px' }}
                  />
                </Col>
                <Col className="mb-3" md="6">
                  <label className="form-control-label">Company Code</label>
                  <Input
                    value={companyCode}
                    type="text"
                    maxLength="50"
                    disabled
                    style={{ height: '39px' }}
                  />
                  <div className="invalid-feedback"></div>
                </Col>
              </div>
              <div className="form-row mb-3">
                <Col className="mb-3" md="6">
                  <label className="form-control-label">
                    Business Area - Name
                  </label>
                  <Input
                    value={`${businessArea} - ${businessAreaName}`}
                    type="text"
                    maxLength="50"
                    style={{ height: '39px' }}
                    disabled
                  />
                </Col>
                <Col className="mb-3" md="6">
                  <label className="form-control-label">Cashier Office</label>
                  <Input
                    value={`${cashierKey} - ${cashierDescription}`}
                    type="text"
                    maxLength="50"
                    style={{ height: '39px' }}
                    disabled
                  />
                  <div className="invalid-feedback"></div>
                </Col>
              </div>
              <div className="form-row mb-3">
                <Col className="mb-3" md="6">
                  <label className="form-control-label">
                    Course ID - Course Name
                  </label>
                  <Input
                    value={`${courseId} - ${courseName}`}
                    type="text"
                    maxLength="50"
                    style={{ height: '39px' }}
                    disabled
                  />
                </Col>
                <Col className="mb-3" md="6">
                  <label className="form-control-label">Term</label>
                  <Input
                    value={term}
                    type="text"
                    maxLength="50"
                    style={{ height: '39px' }}
                    disabled
                  />
                  <div className="invalid-feedback"></div>
                </Col>
              </div>
              <div className="form-row mb-3">
                <Col className="mb-3" md="6">
                  <label className="form-control-label">
                    Course Start Date
                  </label>
                  <Input
                    value={
                      paymentSchedule &&
                      moment(paymentSchedule.courseStartDate).format(
                        'DD-MM-YYYY'
                      )
                    }
                    type="text"
                    maxLength="50"
                    style={{ height: '39px' }}
                    disabled
                  />
                </Col>
                <Col className="mb-3" md="6">
                  <label className="form-control-label">Course End Date</label>
                  <Input
                    value={
                      paymentSchedule &&
                      moment(paymentSchedule.courseEndDate).format('DD-MM-YYYY')
                    }
                    type="text"
                    maxLength="50"
                    style={{ height: '39px' }}
                    disabled
                  />
                  <div className="invalid-feedback"></div>
                </Col>
              </div>
              <div className="form-row mb-3">
                <Col className="mb-3 d-flex flex-row" md="6">
                  <span className="form-control-label">
                    Last Updated Date :
                  </span>
                  <span>
                    {paymentSchedule &&
                      moment(paymentSchedule.updatedOn).format('DD-MM-YYYY')}
                  </span>
                </Col>
                <Col className="mb-3 d-felx flex-row" md="6">
                  <span className="mr-1 form-control-label">
                    No. of Installments :{' '}
                  </span>
                  <span className="mr-4 pr-5">
                    {paymentSchedule && paymentSchedule.totalCourseFeeCount}
                  </span>
                  <span className="mr-1 form-control-label">Base Fees : </span>
                  <span className="mr-4 pr-5 ">
                    {paymentSchedule && paymentSchedule.totalBaseFee}
                  </span>
                  <span className="mr-1 form-control-label">Total Fees : </span>
                  <span>{paymentSchedule && paymentSchedule.totalAmount}</span>
                </Col>
              </div>
              {/* <div className="form-row mb-3">
        <Button
          color="primary"
          type="button"
          className={`ml-auto`}
          onClick={submitHandler}
        >
          Next
        </Button>
      </div> */}
            </Card>
          </Col>
        </Row>
      )}
      {/* </Container> */}
    </>
  );
};

export default CommonHeader;
