import React, { useState } from "react";
import { ReactComponent as Upload } from "../../../../../../assets/img/svg/upload.svg";
import { ReactComponent as DownloadFile } from "../../../../../../assets/img/svg/downloadIcon.svg";
import { ReactComponent as Delete } from "../../../../../../assets/img/svg/Delete.svg";
import { ReactComponent as PdfIcon } from "../../../../../../assets/img/svg/pdfIcon.svg";
import { ReactComponent as Info } from "../../../../../../assets/img/svg/infoIcon.svg";

import { putpost, uploadpost } from "../../../../../../services/utils";
import { endpoint } from "../../constant";
import {
  failureToast,
  successToast,
} from "../../utils/methods/toasterFunctions/function";
import { getUniqueKey } from "../../utils/methods/commonMethods/utilityMethod";
import { Tooltip } from "@mui/material";
import moment from "moment";
import { openFileInNewWindow } from "views/pages/manage/academicPlanAndTest/academicTimetable/lectureModule/LectureListItem";

const maxFileSize = 2097152; // 2MB mentioned value is in bytes
const DisplayFile = ({ onDownload, name, setFile, deleteFileHandler, isEditAccess }) => {
  return (
    <div className="mb-2 d-flex p-2 align-items-center">
      <PdfIcon className="flex-shrink-0" />
      <div className="ml-2 flex-grow-1 ellipsis-text-container">
        <div className="d-flex ellipsis-text-container">
          <Tooltip title={name}>
            <div className="ellipsis-text-container">{name}</div>
          </Tooltip>
        </div>
        <div className="tiny-semi-bold">
          Uploaded on {moment().format("DD-MMM-YYYY")}
        </div>
      </div>
      <div className="d-flex align-items-center">
        <DownloadFile onClick={onDownload} />
        {isEditAccess && <Delete className="ml-2" onClick={() => deleteFileHandler(null)} />}
      </div>
    </div>
  );
};

const DisplayImportInput = ({ showFile }) => {
  return (
    <>
      <label className="mt-2 mb-1" htmlFor="inputTag">
        <span className="regular-large color-primary">
          Select document to upload
        </span>
        <input
          onChange={(e) => showFile(null, e.target.files[0])}
          type="file"
          hidden
          id="inputTag"
          accept="application/pdf"
        />
        <span className="ml-2">
          <Upload />
        </span>
        <span id="imageName"></span>
      </label>
      <div className="mb-2">
        {" "}
        <Info /> File supported pdf. Max. file size 2MB
      </div>
    </>
  );
};

const UploadForTimetable = ({
  saveHandler = () => {},
  setLoading = () => {},
  deleteFileHandler,
  uploadedFile,
  isEditAccess
}) => {
  const [file, setFile] = useState(uploadedFile);
  const dataToServer = {
    bulk: true,
    fileName: null,
    docType: "pdf",
    keyValue: `consession_${getUniqueKey()}`,
    serviceName: "PHX_TIMETABLE",
  };

  const showFile = (dataFile, targetFile) => {
    try {
      const fileSelected = dataFile || targetFile;
      const fileType = fileSelected?.name?.split(".")?.pop();
      if (fileType?.toLowerCase() !== "pdf") {
        failureToast("Only pdf files are allowed");
        return;
      }
      if (fileSelected.size > maxFileSize) {
        failureToast(
          "The file size exceeds the limit of 2 MB. Please reduce the size of the file to upload successfully"
        );
        return;
      }
      setFile(fileSelected);
      setLoading(true);
      getFilePath(fileSelected.name, fileSelected);
    } catch (e) {
      console.log("Error", e);
      setLoading(false);
    }
  };

  const getFilePath = (name, file) => {
    try {
      setLoading(true);
      const formData = {
        ...dataToServer,
        fileName: name,
        docType: "pdf",
      };

      putpost(
        endpoint.fetchS3Url.getUrl,
        (data) => {
          if (data.code === 200 && data.data) {
            saveDataToStorage(data.data, file);
          }
        },
        (data) => {
          failureToast(data["message"]);
          setLoading(false);
        },
        formData,
        "post"
      );
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  const saveDataToStorage = (res, file) => {
    try {
      const formData = new FormData();

      formData.append("File", file);
      uploadpost(
        res.storage,
        (data) => {
          saveHandler({
            ...res,
          });
          successToast("File uploaded successfully");
          setLoading(false);
        },
        (data) => {
          failureToast("File upload failed, please try again after sometime.");
          setLoading(false);
        },
        file,
        file?.type,
        file?.name
      );
    } catch (e) {
      failureToast("Unable to upload file, please try after sometime.");
      setLoading(false);
    }
  };

  const downLoadFile = () => {
    if (file?.name) {
      const link = document.getElementById("link");
      const objectURL = window.URL.createObjectURL(file);
      console.log(objectURL);
      link.download = file.name; // this name is used when the user downloads the file
      link.href = objectURL;
      window.open(objectURL, "_blank");
    } else {
      openFileInNewWindow(file?.fileUrl);
    }
  };

  return (
    <div>
      {!file && isEditAccess && <DisplayImportInput showFile={showFile} />}
      <a style={{ display: "none" }} id="link" download></a>
      {file && (
        <DisplayFile
          onDownload={downLoadFile}
          isEditAccess  = {isEditAccess}
          name={file.name || file.fileName}
          setFile={setFile}
          deleteFileHandler={() => {
            setFile(null);
            deleteFileHandler();
          }}
        />
      )}
    </div>
  );
};

export default UploadForTimetable;
