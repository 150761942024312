import React, { useState, useEffect } from "react";
import TopicMasterTable from "./topicMasterTable";
import CustomLoader from "../../common/commonComponents/Loader/loader";
import { masterServiceBaseUrl, failureToast, getAPI } from "services/http";
import { fetchDetails } from "../../common/utils/methods/commonMethods/utilityMethod";
import { GetPagination } from "../../common/commonComponents/pagination/pagination";
import { endpoint } from "../../common/constant";

const TopicMaster = (props) => {
  const [classArray, setclassArray] = useState([]);
  const [apiloader, setapiloader] = useState(false);
  const [state, setstate] = useState([]);
  const [serverData, setServerData] = useState(null);
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });

  const [activeClassData, setActiveClassData] = useState([]);
  const [activeSubjectData, setActiveSubjectData] = useState([]);
  const [allClassData, setAllClassData] = useState([]);
  const [allSubjectData, setAllSubjectData] = useState([]);

  const pageSize = 10;

  const fetchClasses = async (url) => {
    getAPI(
      url,
      (data) => {
        setstate(data.data);
        setServerData(data.data);
        setPagination(data.data);
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  };

  const setPagination = (paramsdata) => {
    let n = pagination;
    if (paramsdata.length > pageSize) {
      n["nextPage"] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n["totalPage"] = Math.floor(paramsdata.length / pageSize);
    if (paramsdata.length % pageSize != 0) {
      n["totalPage"] += 1;
    }
    setpagination(n);
    setclassArray(
      paramsdata.slice(
        pagination.currentPage * pageSize - pageSize,
        pagination.currentPage * pageSize
      ) || []
    );
    setapiloader(false);
  };

  useEffect(() => {
    fetchClasses(endpoint.topic.getAll);

    fetchDetails(
      endpoint.subject.getAllActive,
      setActiveSubjectData,
      "subject",
      "reactSelect"
    );
    fetchDetails(
      endpoint.subject.getAll,
      setAllSubjectData,
      "subject",
      "reactSelect"
    );
  }, []);

  return (
    <>
      {!serverData ? (
        <div
          className="mx-auto text-center py-5 my-5"
          style={{ height: "100vh" }}
        >
          <CustomLoader apiLoader={!serverData} />
        </div>
      ) : (
        <>
          <TopicMasterTable
            setclassArray={setclassArray}
            classArray={classArray}
            isSaveVisible={isSaveVisible}
            setisSaveVisible={setisSaveVisible}
            activeClassData={activeClassData}
            activeSubjectData={activeSubjectData}
            allClassData={allClassData}
            allSubjectData={allSubjectData}
            pagination={pagination}
            fetchClasses={fetchClasses}
            serverData={serverData}
          />
          {pagination.totalPage > 1 ? (
            <GetPagination
              isSaveVisible={isSaveVisible}
              fetchClasses={props.searchTopicMaster}
              setclassArray={setclassArray}
              pagination={pagination}
              setpagination={setpagination}
              pageSize={pageSize}
              state={state}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default TopicMaster;
