import React, { useContext, useEffect, useState } from "react";
import ALoader from "views/pages/manage/common/a-loader";
import ListHeader from "../../issuanceIDcard/studentList/ListHeader";
import SearchInput from "./SearchInput";
import StudentFilterForm from "./StudentFilterForm";
import { IssuanceIDCardContext } from "../contextApi";
import SearchCard from "../../../finOps/IssueStock/search/SearchCard";
import StockReturnApplicationSearchDialog from "../stockReturn/StockReturnApplicationSearchDialog";
import StockTransactionsHistoryPopup from "../stockTxns";
import BranchConsumptionReturnPopup from "../consumptionReturn";
import { fetchAllPromisedData } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint, pages } from "../../../common/constant";
import DisabledModulesAlert, {
  checkDisabledModule,
  checkDisabledModuleList,
  showDisabledModuleAlert,
} from "./DisabledModulesAlert";
import { useHistory } from "react-router";
import DownloadReportsDialog from "../downloadReports/DownloadReportsDialog";
import SelectFilterChip from "../dlpStockIssuance/SelectFilterChip";
import SearchStudentPopupMisc from "../IssueStockMisc/SearchStudentPopup";
import AddToFavourite from "../../../homepage/favourites/AddToFavourite";
import StockReversalSearchDialog from "../stockReversal/StockReversalSearchDialog";

const StockIssueSearch = ({ disabled }) => {
  const {
    selectedFilter,
    setSelectedFilter,
    resetFilterForm,
    resetGlobalFilter,
  } = useContext(IssuanceIDCardContext);
  const callback = () => {
    resetFilterForm();
    resetGlobalFilter();
  };
  return (
    <div className="p-3 student-filer-container-printId">
      <div className="d-flex justify-content-between align-items-center">
        <ListHeader text="Search students to issue stocks" />
        <AddToFavourite
          screenId={pages["stockAndInventory"]["id"]}
          screenName={pages["stockAndInventory"]["name"]}
        />
      </div>
      <SelectFilterChip
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        disabled={disabled}
        cb={callback}
      />
      <SearchInput disabled={disabled || selectedFilter !== "SINGLE"} />
      <StudentFilterForm disabled={disabled || selectedFilter !== "BULK"} />
    </div>
  );
};

const StudentList = () => {
  const {
    isListLoading,
    dropdownLoader,
    isApplicationLoading,
    dropdown,
    setCurrentPageState,
    setApplicationMisc,
  } = useContext(IssuanceIDCardContext);

  const [apiLoader, setApiLodaer] = useState(false);
  const history = useHistory();
  const [isStockMiscDialogOpen, setIsStockMiscDialogOpen] = useState(false);
  const [isStockReturnDialogOpen, setIsStockReturnDialogOpen] = useState(false);
  const [isStockReversalDialogOpen, setIsStockReversalDialogOpen] =
    useState(false);
  const [isStockTxnDialogOpen, setIsStockTxnDialogOpen] = useState(false);
  const [
    isStockConsumptionReturnDialogOpen,
    setIsStockConsumptionReturnDialogOpen,
  ] = useState(false);
  const [isDownloadReportsDialogOpen, setIsDownloadReportsDialogOpen] =
    useState(false);

  const [disabledModulesDetails, setDisabledModulesDetails] = useState({});

  const stockReturnClickHandler = () => {
    setIsStockReturnDialogOpen(true);
  };

  const stockReversalClickHandler = () => {
    setIsStockReversalDialogOpen(true);
  };

  const stockTxnHandler = () => {
    setIsStockTxnDialogOpen(true);
  };

  const stockConsumptionHandler = () => {
    window.open(
      history.createHref({
        pathname: `/admin/edp/stockManagement/stockConsumption`,
      }),
      "_blank"
    );
  };

  const openFailedTransationsList = () => {
    window.open(
      history.createHref({
        pathname: `/admin/edp/stockManagement/barcodeReconcilliation`,
      }),
      "_blank"
    );
  };

  const stockConsumptionReturnHandler = () =>
    setIsStockConsumptionReturnDialogOpen(true);

  const issueStockMiscHandler = () => setIsStockMiscDialogOpen(true);

  const downloadReportsClickHandler = () =>
    setIsDownloadReportsDialogOpen(true);

  const getDisabledModules = async () => {
    setApiLodaer(true);
    const res = await fetchAllPromisedData(
      endpoint?.studentSearch?.getDisabledStockModules,
      true
    );
    if (res?.code === 200) {
      const moduleDetails = {};
      for (const module of checkDisabledModuleList) {
        moduleDetails[module] = checkDisabledModule(
          module,
          dropdown?.businessAreas,
          res?.data
        );
      }
      setDisabledModulesDetails(moduleDetails);
    } else {
      setDisabledModulesDetails({});
    }
    setApiLodaer(false);
  };

  useEffect(() => {
    if (dropdown?.businessAreas) {
      getDisabledModules();
    }
  }, [dropdown]);

  return (
    <>
      <div
        className="d-flex flex-column remove-container-top-space"
        style={{ minHeight: "calc(100vh - 79px)" }}
      >
        {(isListLoading ||
          dropdownLoader ||
          isApplicationLoading ||
          apiLoader) && <ALoader position={"fixed"} />}
        {showDisabledModuleAlert(disabledModulesDetails) && (
          <DisabledModulesAlert {...{ disabledModulesDetails }} />
        )}
        <StockIssueSearch disabled={disabledModulesDetails?.["ISSUANCE"]} />

        <div className="" style={{ position: "relative" }}>
          <SearchCard
            disabledModulesDetails={disabledModulesDetails}
            {...{
              stockTxnHandler,
              stockReturnClickHandler,
              stockReversalClickHandler,
              stockConsumptionHandler,
              stockConsumptionReturnHandler,
              downloadReportsClickHandler,
              issueStockMiscHandler,
              openFailedTransationsList,
            }}
          />
        </div>
      </div>
      <SearchStudentPopupMisc
        open={isStockMiscDialogOpen}
        setOpen={setIsStockMiscDialogOpen}
        dropdown={dropdown}
        setCurrentPageState={setCurrentPageState}
        setApplicationMisc={setApplicationMisc}
      />
      <StockReturnApplicationSearchDialog
        open={isStockReturnDialogOpen}
        setOpen={setIsStockReturnDialogOpen}
        dropdown={dropdown}
      />
      <StockTransactionsHistoryPopup
        open={isStockTxnDialogOpen}
        setOpen={setIsStockTxnDialogOpen}
        dropdown={dropdown}
        academicType="RCC"
      />
      <BranchConsumptionReturnPopup
        open={isStockConsumptionReturnDialogOpen}
        setOpen={setIsStockConsumptionReturnDialogOpen}
        dropdown={dropdown}
      />

      <DownloadReportsDialog
        dropdown={dropdown}
        open={isDownloadReportsDialogOpen}
        setOpen={setIsDownloadReportsDialogOpen}
      />

      <StockReversalSearchDialog
        open={isStockReversalDialogOpen}
        setOpen={setIsStockReversalDialogOpen}
        dropdown={dropdown}
        academicCareer="RCC"
      />
    </>
  );
};

export default StudentList;
