import React from 'react'
import styles from '../styles.module.scss'
import AButton from '../../../../common/form-fields-mui/AButton'
import PrintIdCardBtn from '../../printIDCard'
import { getPsidApplicationIdObjFromRowId } from '../constant'

const getPsidAndApplicationIdArray = (selectionModel) => {
  return selectionModel?.map(rowString => {
    return getPsidApplicationIdObjFromRowId(rowString)
  })
}
const getPrintedRows = (selectionModel) => {
  return selectionModel?.map(rowString => {
    return JSON?.parse(rowString);
  })
}

const BulkSelectionFooter = ({ selectionModel, setSelectionModel = () => { } }) => {
  return (
    selectionModel?.length > 0 && (
      <div className={`${styles?.bulk_footer}`}>
        <div className='d-flex flex-column'>
          <div className='heading-4'>{selectionModel?.length} Students selected</div>
          <div className='regular-small'>What do you want to do next?</div>
        </div>
        <div className='ml-5 flex-grow-1'>
          <PrintIdCardBtn
            data={getPsidAndApplicationIdArray(selectionModel)}
            rowData={getPrintedRows(selectionModel)}
            customTiggerButton
            customTriggerButtonComponent={() => (
              <AButton
                variant='primary_filled'
              >
                Preview & Print all ID cards
              </AButton>
            )}
          />
        </div>
        <div>
          <AButton
            updatedStyle={{
              color: '#0F0F0F',
              letterSpacing: '0.5px'
            }}
            onClick={() => setSelectionModel([])}
          >
            Deselect all
          </AButton>
        </div>
      </div>
    )
  )
}

export default BulkSelectionFooter