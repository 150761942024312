import React from 'react'


import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import AButton from "../../common/form-fields-mui/AButton";
import { ASelect } from "../../common/form-fields-mui/ASelect";
import ADatePicker from "../../common/form-fields-mui/ADatePicker";
import AInput from '../../common/formFeilds/customInput/AInput'
// import IconButton from '@material-ui/core/IconButton';
import { AiFillCloseSquare } from 'react-icons/ai';
import consessionObject from './consessionFilter.json';
import './index.css';
import { FormGenerator } from '../../common/commonComponents/formGenerator';
import { constants } from '../../common/constant';



export const modalStyle = {
    position: "absolute" /* Stay in place */,
    zIndex: "75" /* Sit on top */,
    top: "2rem",
    right: "5rem",
    width: "60%" /* Full width */,
    height: "100%" /* Full height */,
};


const buttonStyle = {
    display: "flex", justifyContent: "flex-end"
};

export const ConsessionFilter = (props) => {
    const { submitHandler = () => { },
        filterStatus,
        setFilterStatus = () => { },
        removeFilterHandler,
        isShowRemoveFilter,
        onClose = () => { },
        filters,
        setFilters = () => { },
        categoryCodeData
    } = props;
    return (
        <>
            <Row>
                <FormGenerator
                    fields={Object.keys(consessionObject)}
                    fieldsObject={consessionObject}
                    values={filters}
                    setValues={setFilters}
                    dataObjects={{ statusData: { data: constants.status }, concessionParameterData: { data: constants.ConcessionParameter }, applicabilityModeData: { data: constants.availablityData }, applicabilityOnData: { data: constants.applicableOnData }, criteriaData: { data: constants.scholarshipCriteriaData }, typeData: { data: constants.consessionType }, codeData: {data: categoryCodeData} }}
                // isPreviewEnable={previewOnly}
                // disabled={submitting || recordSaved || nextPage || (!editHeader && action !== 'new')}
                // isEditMode={action === 'edit'}
                />
            </Row>
            <Row>
                <Col md="6" style={buttonStyle}>

                </Col>
                <Col md="6" style={buttonStyle} >
                    {filterStatus.applied && (
                        <AButton
                            onClick={removeFilterHandler}
                            updatedStyle={{
                                border: "1px solid #CC5F5F",
                                borderRadius: "8px",
                                color: "#CC5F5F",
                                margin: ".3rem"

                            }}
                        >
                            Remove Filter
                        </AButton>
                    )}
                    <AButton
                        onClick={submitHandler}
                        updatedStyle={{
                            background: "#00B0F5",
                            border: "1px solid #00B0F5",
                            borderRadius: "8px",
                            color: "#fff",
                            margin: ".3rem"

                        }}
                    >
                        {filterStatus.applying  ? < i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : 'Apply Filter'}
                </AButton>
            </Col>
        </Row>
        </>
    )
};


