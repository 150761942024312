import React from 'react'
import { ReactComponent as Info } from '../../../../../assets/img/svg/fi_info.svg';

const NoRowOverlay = ({ message, style = {} }) => {
  return (
    <div className='d-flex flex-row justify-content-center align-items-center' style={{ height: '50px', backgroundColor: '#F5F5F5', ...style }}>
      <Info className='mr-2'/>
      <span className='regular-small'>{ message || "No information to display"} </span>
    </div>
  )
}

export default NoRowOverlay