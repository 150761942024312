import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import ADataGrid from "views/pages/manage/common/data-grid/ADataGrid";
import { AGridColDef } from "views/pages/manage/common/data-grid/ADataGridColDef";
import styles from "../styles.module.scss";
import { ReactComponent as CheckCircleIcon } from "assets/img/svg/fi-check-circle.svg";
import { Tooltip } from "@mui/material";
import { gridComponents, gridStyles } from "../constant";
import { GridRowModes } from "@mui/x-data-grid";
import {
  checkPermission,
  isObjEmpty,
} from "../../../../common/utils/methods/commonMethods/utilityMethod";
import {
  failureToast,
  successToast,
} from "../../../../common/utils/methods/toasterFunctions/function";
import AInput from "views/pages/manage/common/formFeilds/customInput/AInput";
import ActionsCellComponent from "./ActionsCellComponent";
import { checkIfRfidExists, mapRfidWithApplication } from "../../service";
import NoRowOverlay from "views/pages/manage/masters/holidayMaster/NoRowOverlay.jsx";
import { IssuanceIDCardContext } from "../../contextApi";
import ALoader from "views/pages/manage/common/a-loader";
import useConfirm from "views/pages/manage/common/commonComponents/ConfirmationDialog/confirmationPrompt.jsx";
import { pages } from "../../../../common/constant";
import { PermissionContext, RolePermissions } from "appContext";
import { useHistory } from "react-router";
import BulkSelectionCheckboxes from "../../bulkOperation/BulkSelectionCheckboxes";
import BulkUpload from "../../bulkOperation/BulkUpload";

const getRowId = (row) => row?.applicationId;

const MapRfidTable = forwardRef(
  (
    {
      rowData = [],
      rowCount = 0,
      nextButtonHandler = () => {},
      currentPage,
      hidePagination = false,
      printedData = []
    },
    ref
  ) => {
    const history = useHistory();
    const userPermissions = RolePermissions(
      useContext(PermissionContext),
      pages["idCards"]["id"]
    );
    const [ConfirmationDialog, confirmPromise] = useConfirm();
    const [rfIdMappingScreen, setRfIdMappingScreen] = useState("MANUAL");
    const [tableRows, setTableRows] = useState([]);
    const [filteredRowData, setFilteredRowData] = useState(rowData);
    const [showOnlyNewIssue, setShowOnlyNewIssue] = useState(false); // show new issue is equivalent to pending rfid
    const {
      loading,
      filterForm,
      printedIdCardArray,
      refreshPrintedIdArray,
    } = useContext(IssuanceIDCardContext);

    const refreshPrintedIdCardArrayCallback = (newDataArray) => {
      if (printedIdCardArray?.length > 0) {
        refreshPrintedIdArray(newDataArray);
      }
    };

    const updateRfId = ({ rfid, applicationId }) => {
      // debugger;
      const updateRows = tableRows.map((row) => ({
        ...row,
        ...(row.applicationId === applicationId ? { rfid } : ""),
      }));
      setTableRows(updateRows);
    };

    const [mappingLoader, setMappingLoader] = useState(false);
    const [rowModesModel, setRowModesModel] = useState({});
  const [rowForm, setRowForm] = useState({});
  const rowFormHandler = (value, key) =>
    setRowForm((prev) => ({ ...prev, [key]: value }));

  const startEditing = (id, rowData) => {
    setRowModesModel((oldModel) => ({
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "rfid" },
    }));
    setRowForm(rowData);
  };

  const stopEditing = (id) => {
    const currForm = { ...rowForm };
    setRowModesModel((oldModel) => ({
      [getRowId(currForm)]: {
        mode: GridRowModes.View,
        ignoreModifications: true,
      },
    }));
    setRowForm({});
  };

    const handleEditClick = (params) => {
      if (!isObjEmpty(rowForm)) {
        failureToast("Please save current changes");
      } else {
        startEditing(params?.id, params?.row);
      }
    };

    const checkIfRfidExistsHandler = async (rfid) => {
      const res = await checkIfRfidExists(rfid);
      if (res?.code === 200) {
        if (res?.data?.length > 0) {
          failureToast(
            "This RFID card is already issued to a student, enter a different card no. to proceed"
          );
          return false;
        }
        return true;
      }
      return false;
    };

    const handleSaveClick = async (params) => {
      setMappingLoader(true);

      const isUniqueRfid = await checkIfRfidExistsHandler(rowForm?.rfid);

      if (isUniqueRfid) {
        const request = {
          psid: params?.row?.psid,
          applicationId: params?.row?.applicationId,
          rfid: rowForm?.rfid,
        };
        const res = await mapRfidWithApplication(request);
        if (res?.code === 200) {
          stopEditing(params?.id);
          successToast(res?.message);
          updateRfId(request);
        }
      }

      setMappingLoader(false);
    };

    const confirmationCheck = async () => {
      if (isObjEmpty(rowForm)) {
        return true;
      } else {
        const canContinue = await confirmPromise();
        return canContinue;
      }
    };

    useImperativeHandle(ref, () => ({
      confirmationCheck,
    }));

    useEffect(() => {
      setTableRows(printedIdCardArray?.length > 0 ? printedIdCardArray : rowData);
    }, [printedIdCardArray, rowData]);

    const mapRfidTableHeaders = [
      new AGridColDef("psid", "PSID")
        .setMinWidth(150)
        .renderCellComponent((params) => (
          <Tooltip title={params?.value}>
            <div
              onClick={() =>
                window.open(
                  history.createHref({
                    pathname: `/admin/edp/studentDetails/${params.value}`,
                  }),
                  "_blank"
                )
              }
              className={`ellipsis-text-container pointer color-primary`}
            >
              {params?.value}
            </div>
          </Tooltip>
        )),
      new AGridColDef("applicationId", "Application no.")
        .setMinWidth(150)
        .renderCellComponent((params) => (
          <Tooltip title={params?.value}>
            <div className={`ellipsis-text-container`}>{params?.value}</div>
          </Tooltip>
        )),
      new AGridColDef("studentName", "Student name")
        .setMinWidth(200)
        .renderCellComponent((params) => (
          <Tooltip title={params?.value}>
            <div className={`ellipsis-text-container`}>{params?.value}</div>
          </Tooltip>
        )),
      new AGridColDef("programAction", "Program plan").setMinWidth(150),
      new AGridColDef("issueType", "Issue type").setMinWidth(150),
      new AGridColDef("rfid", "RFID number")
        .setMinWidth(80)
        .editable(true)
        .renderCellComponent((params) => (
          <div className="d-flex align-items-center ellipsis-text-container">
            <Tooltip title={params?.value}>
              <div className="ellipsis-text-container">
                {params?.value ?? "-"}
              </div>
            </Tooltip>
            {params?.value && <CheckCircleIcon className="ml-2" />}
          </div>
        ))
        .renderEditCellComponent((params) => (
          <div className="data-grid-edit-cell-container">
            <AInput
              autoFocus={params?.hasFocus}
              placeholder="Enter RFID no."
              value={rowForm?.rfid}
              handler={(value) =>
                value?.length <= 10 &&
                rowFormHandler(value.replace(/[^0-9]/g, ""), "rfid")
              }
              style={{ borderRadius: "4px", height: "32px" }}
              onKeyDown={(e) => {
                if (
                  (e.key === "Enter" || e.keyCode === 13) &&
                  rowForm?.rfid?.length === 10
                ) {
                  handleSaveClick(params);
                }
              }}
            />
          </div>
        )),
      ...(checkPermission(userPermissions, "U")
        ? [
            new AGridColDef("actions", "Actions", false)
              .setMinWidth(180)
              .columnAlign("right")
              .renderCellComponent((params) => (
                <ActionsCellComponent
                  params={params}
                  handleEditClick={handleEditClick}
                  handleSaveClick={handleSaveClick}
                  rowForm={rowForm}
                  stopEditing={stopEditing}
                />
              )),
          ]
        : []),
    ];

    return (
      <>
        <div className="d-flex flex-column">
          {(loading || mappingLoader) && <ALoader position="fixed" />}
          <div className="heading-4 color-black-60 flex-grow-1">Map RFIDs</div>
          <BulkSelectionCheckboxes
            searchInputParam={rfIdMappingScreen}
            setSearchInputParam={(e) => setRfIdMappingScreen(e)}
          />
          {rfIdMappingScreen === "BULK" && (
            <BulkUpload
              filterForm={filterForm} 
              printedData = {printedData}
              />
          )}
          {rfIdMappingScreen === "MANUAL" && (
            <div className={`${styles?.rfid_table_container}`}>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 large-semi-bold">
                  Manual - Enter RFID number
                </div>
                {/* <FormControlLabel
              disableTypography
              sx={{ margin: '0px', ...primaryCheckboxStyles }}
              control={
                <Checkbox
                  onChange={(e) => {
                    stopEditing();
                    setShowOnlyNewIssue(e?.target?.checked);
                    if (printedIdCardArray?.length === 0) {
                      // staticOnlyNewIssueFilter(e?.target?.checked);
                      // this filtering is handled by useEffect
                      setPendingRfIdPage(0);
                      getPendingRfIds(0, { rfidMappedApplication: e?.target?.checked ? false : null });
                    }
                  }}
                  checked={showOnlyNewIssue}
                />
              }
              label={<div className='regular-body'>Show only pending RFIDs</div>}
            /> */}
              </div>
              <ADataGrid
                rowModesModel={rowModesModel}
                rows={tableRows}
                columns={mapRfidTableHeaders}
                rowId={(row) => getRowId(row)}
                rowCount={
                  printedIdCardArray?.length > 0
                    ? printedIdCardArray?.length
                    : rowCount
                }
                nextButtonHandler={(newPage) =>
                  nextButtonHandler(newPage, showOnlyNewIssue ? false : false)
                }
                currentPage={currentPage}
                rowHeight={50}
                height={100}
                loading={false}
                components={{
                  ...gridComponents,
                  NoRowsOverlay: () => (
                    <NoRowOverlay message="All eligible PSIDs mapped with RFIDs at your branch" />
                  ),
                }}
                sx={{
                  ".MuiDataGrid-main": {
                    height:
                      printedIdCardArray?.length > 0
                        ? `${60 + printedIdCardArray?.length * 50}px`
                        : `${60 + rowData?.length * 50}px`,
                    // height: `${60 + filteredRowData?.length * 50}px`,
                    minHeight: `${60 + 50}px`,
                    maxHeight: `${60 + 50 * 50}px`,
                  },
                  ".MuiDataGrid-footerContainer": {
                    display: hidePagination && "none",
                  },
                  ...gridStyles,
                }}
              />
            </div>
          )}
        </div>
        <ConfirmationDialog />
      </>
    );
  }
);

export default MapRfidTable;
