import React, { useContext, useState } from 'react'
import { ReactComponent as EditIcon } from '../../../../../assets/img/svg/Edit2.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/img/svg/Delete.svg';
import DeleteDialog from './DeleteDialog';
import { checkPermission } from '../../common/utils/methods/commonMethods/utilityMethod';
import AButton from 'views/pages/manage/common/form-fields-mui/AButton'
import { RolePermissions } from 'appContext'
import { PermissionContext } from 'appContext'
import { pages } from '../../common/constant'
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper';


const ActionsCellComponent = ({ params, handleEditClick, handleDeleteClick, handleSaveClick, deleteHandler, rowForm }) => {

  const userPermissions = RolePermissions(useContext(PermissionContext), pages['holidayMaster']['id']);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    params.api.getRowMode(params?.id) === 'view' ? (
      <>
        <div className='d-flex align-items-center'>
          {checkPermission(userPermissions, 'U') && <IconButtonWrapper onClick={() => handleEditClick(params)}><EditIcon /></IconButtonWrapper>}
          {checkPermission(userPermissions, 'D') && <IconButtonWrapper className='ml-2' onClick={() => setIsDialogOpen(true)}><DeleteIcon /></IconButtonWrapper>}
        </div>
        <DeleteDialog
          open={isDialogOpen}
          setOpen={setIsDialogOpen}
          data={params?.row}
          onAgree={() => deleteHandler(params?.row)}
        />
      </>
    ) : (
      <div className='d-flex align-items-center'>
        <AButton
          variant='primary_filled'
          updatedStyle={{ height: '28px', fontWeight: 600, fontSize: '12px', lineHeight: '16px', borderRadius: '4px' }}
          onClick={() => handleSaveClick(params)}
          disabled={!rowForm?.holidayName?.trim() || !rowForm?.holidayType || !rowForm?.holidayDate || !rowForm?.day || !rowForm?.regions?.length || !rowForm?.businessAreas?.length || !rowForm?.states?.length || !rowForm?.city?.length}
        >
          Save
        </AButton>
        <IconButtonWrapper onClick={() => handleDeleteClick(params)} className='ml-2' ><DeleteIcon /></IconButtonWrapper>
      </div>
    )
  )
}

export default ActionsCellComponent
