import React from "react";
import { Table, Card, CardBody, CardHeader, Badge } from "reactstrap";
import TableList from "../../../common/tableList";
import { endpoint } from "views/pages/manage/common/constant";
import NoDataFound from "views/pages/manage/common/noDataFound";

const ExternalOrgTable = ({ searchResult }) => {

  const listDetails = {
    processInstanceId: {
      normal: true,
      master: searchResult,
    },
    description: {
      normal: true,
      master: searchResult,
    },
    updatedOn: {
      normal: true,
      master: searchResult,
      type: "date",
      format: "DD-MM-YYYY",
    },
  };

  return (
    <>
      <Card>
        <CardHeader className=" d-flex justify-content-between">
          <h2>External Organization Details</h2>
        </CardHeader>
        <CardBody className={"p-0"}>
          <div id="questionTable" className="table-responsive" style={{ overflow: "auto", minHeight: "300px" }}>
            <Table className={`align-items-center table-flush`}>
              <thead className={`thead-light `}>
                <tr>
                  <th className={`text-uppercase text-center white-breakSpace column-heading`} rowSpan={"2"}>
                    Process ID
                  </th>
                  <th className={`text-uppercase text-center white-breakSpace column-heading`} rowSpan={"2"}>
                    Description
                  </th>
                  <th className={`text-uppercase text-center white-breakSpace column-heading`} rowSpan={"2"} style={{ width: "200px" }}>
                    Updated on
                  </th>
                  <th className={`text-uppercase text-center white-breakSpace column-heading `} rowSpan={"2"}>
                    File Status
                  </th>
                </tr>
              </thead>
              <tbody className="list">
              {searchResult && searchResult.length > 0 ? (
                searchResult.map((element, index) => (
                  <TableList item={element} listDetails={listDetails} isStatus={false} key={`row + ${index}`}>
                    <td className="text-center" style={{ width: "200px", fontSize: "14px" }}>
                      {element.errorPath ? (
                        <Badge color="danger" href={element.errorPath} pill>
                          Download
                          {/* <i className="fas fa-download" style={{ padding: "1px 7px", fontSize: "12px" }}></i> */}
                        </Badge>
                      ) : (
                        // <a href="">
                        //   <i className="fas fa-download"></i>
                        // </a>
                        <Badge color="success" pill>
                          Success
                        </Badge>
                      )}
                    </td>
                  </TableList>
                ))
                ) : (
                  <tr>
                    <td colSpan="4">
                      <NoDataFound />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ExternalOrgTable;
