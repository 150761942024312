import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import React, { useState } from 'react'
import { ReactComponent as Downarrow } from "assets/img/svg/downarrow.svg";


const AAccordion = ({ initialOpen = false, summary, details, expandDisabled, ...restProps }) => {
  const [expanded, setExpanded] = useState(initialOpen);
  const toggleAccordion = () => !expandDisabled && setExpanded(prev => !prev);

  const AccordionStyles = {
    padding: '16px 20px',
    borderRadius: '12px',
    position: "relative",
    boxShadow: "none",
    "&::before": {
      display: 'none',
    },
    "&:last-of-type": {
      borderRadius: '12px',
    },
    "&.Mui-expanded": { m: 'unset' },
    ".MuiAccordionSummary-root:hover:not(.Mui-disabled)": { cursor: "auto" },
    ".MuiAccordionSummary-root": {
      borderRadius: 'inherit',
      p: 0,
      minHeight: 'unset',
      "&.Mui-expanded": { minHeight: 'unset' },
    },
    ".MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded": {
      margin: 'unset'
    },
    ".MuiAccordionDetails-root": { p: 0, mt: 2 },
  };

  return (
    <Accordion expanded={expanded} sx={AccordionStyles} {...restProps}>
      <AccordionSummary
        expandIcon={<Downarrow onClick={toggleAccordion} />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails>
        {details}
      </AccordionDetails>
    </Accordion>
  )
}

export default AAccordion