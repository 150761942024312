import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Tooltip } from "@mui/material";

import { ReactComponent as BackIcon } from "assets/img/svg/BackButton.svg";
import { ReactComponent as CsvIcon } from "assets/img/svg/excel.svg";
import CustomChip from "views/pages/manage/common/customChip/CustomChip.jsx";
import ConsumptionReturnPage from "../consumptionReturn/ConsumptionReturnPage";
import {
  ColoredChip,
  IssuedDetailsInfo,
} from "../selectMeterialAndStock/issueStock/TableColumn";
import { chipStyle, headerStyle } from "./style";
import CustomCopyToClipboard from "../../../common/ACopyToClipboard/CustomCopyToClipboard";
import {
  fetchTransactionsData,
  getCSVkey,
  searchStockTransactions,
} from "../service";
import { TransactionHistoryTable } from "./StockTransactionTable";
import { getFormattedDate } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import IssueStockSuccessPrompt from "../selectMeterialAndStock/issueStock/IssueStockSuccessPrompt";
import { fetchAllPostPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "views/pages/manage/common/constant";
import { successToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import AButton from "../../../common/form-fields-mui/AButton";
import ALoader from "../../../common/a-loader";
import { getDownloadFileUrl } from "../stockConsumption/form/helper";
import BranchConsumptionHistory from "../consumptionReturn/BranchConsumptionHistory";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx";
import StatusChipWithFailureReason from "./StatusChipWithFailureReason";
import StockReversalHistory from "./StockReversalHistory";
import { transactionTypeMap } from "../stockReturn/constant";

const StockHistory = () => {
  const history = useHistory();
  const param = useParams();
  const { business, id, buValue, type, academicCareer } = param;
  const [transactionDetails, setTransactionDetails] = useState({});
  const [tableRows, setTableRows] = useState([]);
  const [apiLoader, setApiLoader] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [successResponse, setSuccessResponse] = useState({});
  const [failedReason, setFailedReason] = useState(false);
  const forReversal = type === "stockReversal";

  const Header = () => {
    return (
      <div
        className="d-flex heading-4 color-dark align-items-center justify-content-between"
        style={headerStyle}
      >
        <div className="d-flex align-items-center">
          <IconButtonWrapper onClick={() => window.close()}>
            <BackIcon />
          </IconButtonWrapper>
          <div className="">
            {forReversal
              ? `Reversal ID: ${id} | ${buValue}`
              : `Transaction ID: ${id} | ${buValue}`}
          </div>
        </div>
        <div>
          <div className="mr-2 d-flex align-items-center gap-md">
            <CustomChip style={chipStyle} className="body-regular">
              {forReversal ? "Reversal details" : "Issue details:"}{" "}
              {getFormattedDate(transactionDetails?.issueDate)}
              <IssuedDetailsInfo stock={transactionDetails} />
            </CustomChip>
            <CustomChip style={chipStyle} className="body-regular">
              SAP document no.: {transactionDetails?.sapDocId || "-"}
              {transactionDetails?.sapDocId && (
                <CustomCopyToClipboard
                  textToCopy={transactionDetails?.sapDocId}
                />
              )}
            </CustomChip>
            <StatusChipWithFailureReason
              transactionDetails={transactionDetails}
              failedReason={failedReason}
            />
            {/* {console.log(transactionDetails)} */}
            {/* <ColoredChip value={transactionDetails?.status} /> */}
          </div>
        </div>
      </div>
    );
  };

  const fetchData = async () => {
    const res = await searchStockTransactions(
      {
        businessArea: { label: buValue, value: business },
        transactionId: id,
        groupBy: "Transaction",
        type: transactionTypeMap.PSID_ISSUANCE[academicCareer],
      },
      0
    );
    if (res?.data) setTransactionDetails(res?.data?.stockTransactionAudList[0]);
  };

  const fetchTableData = async () => {
    try {
      setApiLoader(true);
      const urlFlag =
        type === "returnStock" ? "&requireAllStockReturnedFlag=true" : "";
      const response = await fetchTransactionsData(business, id, urlFlag);
      setApiLoader(false);
      if (response?.data) {
        setTableRows(response?.data);
        response.data?.length > 0 &&
          setFailedReason(response.data[0]?.errorMessage);
      }
    } catch (error) {
      setApiLoader(false);
    }
  };

  useEffect(() => {
    if (!forReversal && id && business) {
      fetchData();
      fetchTableData();
    }
  }, [id, business]);

  const returnStockHandler = async (
    selectedMaterial,
    reasonOfReturn,
    otherDetails
  ) => {
    if (reasonOfReturn === "" || selectedMaterial.length === 0)
      return failureToast("Please fill all the details to proceed");
    setApiLoader(true);
    const request = {
      businessArea: business,
      businessAreaDispValue: buValue,
      academicCareerDispValue: otherDetails.academicCareerDispValue,
      plantId: otherDetails?.plantId,
      academicGroupDispValue: "SOE",
      transactionType: otherDetails?.transactionType,
      reason: otherDetails?.reason,
      costCenter: otherDetails?.costCenter,
      type: otherDetails?.type,
      sapPostingDate: otherDetails?.sapPostingDate,
      psidStockRequest: [
        {
          psid: otherDetails?.psid,
          applicationId: null,
          remark: reasonOfReturn,
          stock: selectedMaterial,
        },
      ],
    };
    const res = await fetchAllPostPromisedData(
      endpoint?.studentSearch?.psidStockReturn,
      request,
      "post"
    );
    if (res?.code === 200) {
      successToast("Request completed successfully");
      setIsSuccessDialogOpen(true);
      setSuccessResponse(res?.data);
      fetchTableData();
    } else {
      failureToast(
        `${res?.message} Consumption Return ID: ${res?.data?.transactionId} failed. Retry`
      );
    }
    otherDetails.cleanData();
    setApiLoader(false);
  };

  const downloadHandler = async () => {
    try {
      setApiLoader(true);
      const response = await getCSVkey(business, id);
      if (response?.code === 200) {
        getDownloadFileUrl(response?.data.key);
      }
      setApiLoader(false);
    } catch (error) {}
  };

  return (
    <>
      {apiLoader && <ALoader position="fixed" />}
      {type === "returnStock" && (
        <>
          <ConsumptionReturnPage
            data={tableRows.length > 0 ? tableRows[0] : {}}
            returnStockHandlerFinal={returnStockHandler}
            isShowSapPostingDate={true}
            failedReason={failedReason}
          />
          <IssueStockSuccessPrompt
            isOpen={isSuccessDialogOpen}
            setIsOpen={setIsSuccessDialogOpen}
            responseData={successResponse}
            isReturn={true}
            message="Your branch consumption return ID"
          />
        </>
      )}

      {type === "branchConsumption" && (
        <>
          <BranchConsumptionHistory
            data={tableRows.length > 0 ? tableRows[0] : {}}
            returnStockHandlerFinal={returnStockHandler}
            transactionDetails={transactionDetails}
            failedReason={failedReason}
          />
        </>
      )}
      {type === "branchConsumptionReturn" && (
        <>
          <BranchConsumptionHistory
            data={tableRows.length > 0 ? tableRows[0] : {}}
            returnStockHandlerFinal={returnStockHandler}
            transactionDetails={transactionDetails}
            isReturnedBarcode={true}
            failedReason={failedReason}
          />
        </>
      )}

      {type === "txn" && (
        <div>
          <Header />
          <div className="mt-3 d-flex justify-content-end mr-4">
            <AButton onClick={downloadHandler}>
              <Tooltip title="Download the transaction details as csv">
                <span>
                  <CsvIcon /> <span className="ml-1">Download</span>
                </span>
              </Tooltip>
            </AButton>
          </div>
          <div style={{ padding: "0 1rem" }}>
            <TransactionHistoryTable
              rowData={tableRows}
              loader={apiLoader}
              rowCount={tableRows.length}
            />
          </div>
        </div>
      )}

      {forReversal && (
        <>
          <Header />
          <StockReversalHistory
            transactionDetails={transactionDetails}
            setTransactionDetails={setTransactionDetails}
            setFailedReason={setFailedReason}
            downloadHandler={downloadHandler}
          />
        </>
      )}
    </>
  );
};

export default StockHistory;
