import {combineReducers} from "redux";
import { studentBatchReducer } from "./batch/batch-reducer";
import globalDataReducer from './globalData/globalDataReducer';
import LoaderReducer from "./Loader/LoaderReducer";
import { allocateStudentsReducer } from "./allocateStudents/allocate-students-reducer";
import MasterDataReducer from "./masterData/MasterDataReducer";
import permissionReducer from "./permissions/permissionReducer";
import userReducer from "./user/userReducer";
import { deallocateStudentsReducer } from "./deallocateStudents/deallocate-students-reducer";
import {academicSubMappingReducer} from './academic-sub-map/reducer'
import {studentSearchReducer} from './studentSearch/reducer'
import { academicPlanReducer } from './academicPlan/reducer';
import {studentChargesReducer} from './studentCharges/reducer';
import {facultyRoasterReducer} from './facultyRoaster/reducer';
import {otherChargesReducer } from "./otherCharges/reducer";
import {concessionApprovalReducer} from './concessionApproval/reducer';

const rootReducer =combineReducers({
    globalData:globalDataReducer,
    loader:LoaderReducer,
    masterDataReducer: MasterDataReducer,
    permissions:permissionReducer,
    user : userReducer,
    batch:studentBatchReducer,
    allocateStudents: allocateStudentsReducer,
    deallocateStudents: deallocateStudentsReducer,
    academicSubMapping: academicSubMappingReducer,
    studentSearch: studentSearchReducer,
    academicPlan:academicPlanReducer,
    studentCharges: studentChargesReducer,
    otherCharges : otherChargesReducer ,
    facultyRoaster : facultyRoasterReducer,
    concessionApproval: concessionApprovalReducer
})
export default rootReducer;