import React from 'react';
import {
  Card,
  CardHeader,
  Input,
  Container,
  Button,
  Table,
  Row,
} from 'reactstrap';
import { useState } from 'react';
import { failureToast } from '../../common/utils/methods/toasterFunctions/function';
import { TDROW, MasterHeader } from '../../common/commonComponents/masterRow/masterRow';
import {
  masterServiceBaseUrl,
  successToast,
  putpost,
  putpost2,
  deleteAPI,
} from 'services/http';
import moment from 'moment';

var _ = require('lodash');

const ApprovalLevelTable = (props) => {
  const [tableDataKey, settableDataKey] = useState('tableDataKey');
  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: '__new',
        approvalLevelKey: null,
        status: 'ACTIVE',
        approvalLevelDesc: null,
        approvalLevelDispValue: null,
        effectiveDate: null,
      },
      ...props.classArray,
    ]);
  };
  const saveAll = (cb) => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if (el['editMode']) {
        if (el['approvalLevelKey'] === null || !el['approvalLevelKey'].trim()) {
          isValid = false;
          failureToast('Please enter the value of Approval Level Key');
          return;
        } else if (
          el['approvalLevelDesc'] === null ||
          !el['approvalLevelDesc'].trim()
        ) {
          isValid = false;
          failureToast('Please enter the value of Description');
          return;
        } else if (
          el['approvalLevelDispValue'] === null ||
          !el['approvalLevelDispValue'].trim()
        ) {
          isValid = false;
          failureToast('Please enter the value of Display Value');
          return;
        }
        if (!isValid) {
          return;
        }
        if (_tempArray[index]['__status'] == '__new') {
          putpost(
            masterServiceBaseUrl + '/approvalLevel/createApprovalLevel',
            (data) => {
              successToast(data['message']);
              props.setisSaveVisible(false);
              props.fetchClasses(
                masterServiceBaseUrl + '/approvalLevel/getAllApprovalLevel'
              );
            },
            (data) => {
              failureToast(data['message']);
            },
            _tempArray[index],
            'post'
          );
        } else {
          putpost(
            masterServiceBaseUrl +
              '/approvalLevel/updateApprovalLevel/' +
              _tempArray[index].id,
            (data) => {
              successToast(data['message']);
              props.setisSaveVisible(false);
              delete _tempArray[index]['editMode'];
              props.setclassArray(_tempArray);
              settableDataKey(new Date().getMilliseconds());
              // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
            },
            (data) => {
              failureToast(data['message']);
            },
            _tempArray[index],
            'put'
          );
        }
      } else {
      }
    });
  };
  const newRow = () => {
    if (props.isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        addNewClass();
        props.setisSaveVisible(true);
        // settableDataKey(new Date().getMilliseconds())
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      props.setisSaveVisible(false);
      cb();
    }
  };
  const editRow = (index) => {
    if (props.isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]['editMode'] = true;
        props.setclassArray(_tempArray);
        props.setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };
  const deleteRow = (index) => {
    if (props.classArray[index]['__status'] == '__new') {
      let n = props.classArray;
      n.splice(index, 1);
      props.setclassArray(n);
      props.setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
    } else {
      if (
        window.confirm('Are you sure you want to delete this Approval Level?')
      ) {
        putpost2(
          masterServiceBaseUrl +
            '/approvalLevel/deleteApprovalLevel/' +
            props.classArray[index].id,
          (data) => {
            successToast(data['message']);
            if (props.classArray.length == 1) {
              props.previousPage();
              props.fetchClasses(
                masterServiceBaseUrl + '/approvalLevel/getAllApprovalLevel'
              );
            } else {
              var _tempArray = props.classArray;
              if (_tempArray[index]['editMode']) {
                props.setisSaveVisible(false);
              }
              _tempArray.splice(index, 1);
              props.setclassArray(_tempArray);
            }
            settableDataKey(new Date().getMilliseconds());
          },
          (data) => {
            failureToast(data['message']);
          },
          'Delete'
        );
      }
    }
  };
  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
  };
  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Approval Level</h3>
                <Button
                  data-testid="addNewRow"
                  disabled={props.isSaveVisible}
                  color="info"
                  size="sm"
                  type="button"
                  className={
                    'floatRight mx-1' +
                    (props.isSaveVisible ? ' btn-dark' : null)
                  }
                  onClick={() => {
                    newRow();
                  }}
                >
                  New Approval Level
                </Button>
                {/* {isSaveVisible?<Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => {saveAll()}}>Save</Button>:null} */}
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout">
                  <MasterHeader type={'Approval Level'} />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                          !el['editMode'] ? (
                            <TDROW
                              type={'approvalLevel'}
                              index={index}
                              el={el}
                              editRow={editRow}
                              deleteRow={deleteRow}
                            />
                          ) : (
                            <tr key={index + '-class'}>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="approvalLevelKey"
                                  maxLength="50"
                                  defaultValue={el['approvalLevelKey']}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      'approvalLevelKey'
                                    );
                                  }}
                                  placeholder="Example:1"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="effectiveDate"
                                  disabled={true}
                                  defaultValue={
                                    el['__status'] == '__new'
                                      ? null
                                      : moment(el['eff_date']).format(
                                          'DD-MM-YYYY'
                                        )
                                  }
                                  placeholder="Example:01-01-2020"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="approvalLevelDesc"
                                  maxLength="50"
                                  defaultValue={el['approvalLevelDesc']}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      'approvalLevelDesc'
                                    );
                                  }}
                                  placeholder="Description"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="approvalLevelDispValue"
                                  maxLength="50"
                                  defaultValue={el['approvalLevelDispValue']}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      'approvalLevelDispValue'
                                    );
                                  }}
                                  placeholder="Display Value"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <label className="custom-toggle mx-auto ml-2">
                                  <input
                                    data-testid="activeInactiveInput"
                                    checked={
                                      el.status == 'ACTIVE' ? true : false
                                    }
                                    type="checkbox"
                                    id={el.id}
                                    onChange={(e) => {
                                      settableDataKey(
                                        new Date().getMilliseconds()
                                      );
                                      let n = 'ACTIVE';
                                      if (!e.target.checked) {
                                        n = 'INACTIVE';
                                      }
                                      updateKey(index, n, 'status');
                                    }}
                                  />
                                  <span
                                    data-testid="activeInactiveLabel"
                                    style={{ width: '72px' }}
                                    className="custom-toggle-slider rounded-circle activeToggle"
                                    data-label-off="Inactive"
                                    data-label-on="Active"
                                  />
                                </label>
                              </td>
                              <td className="text-center">
                                {props.isSaveVisible ? (
                                  <>
                                    {' '}
                                    <Button
                                      data-testid="saveBtn"
                                      color="info"
                                      size="sm"
                                      type="button"
                                      className="mx-1"
                                      onClick={() => {
                                        saveAll();
                                      }}
                                    >
                                      Save
                                    </Button>
                                    <Button
                                      data-testid="closeBtn"
                                      color="danger"
                                      size="sm"
                                      type="button"
                                      onClick={() => {
                                        let n = props.classArray;
                                        if (n[index]['__status'] == '__new') {
                                          n.splice(index, 1);
                                        } else {
                                          // n[index]['editMode']=false
                                          props.fetchClasses(
                                            masterServiceBaseUrl +
                                              '/approvalLevel/getAllApprovalLevel'
                                          );
                                        }
                                        props.setclassArray(n);
                                        props.setisSaveVisible(false);
                                      }}
                                    >
                                      {' '}
                                      <i className="fas fa-times" />
                                    </Button>{' '}
                                  </>
                                ) : (
                                  <Button
                                    color="info"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                      deleteRow(index);
                                    }}
                                  >
                                    {' '}
                                    <i className="fas fa-trash" />
                                  </Button>
                                )}
                              </td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default ApprovalLevelTable;
