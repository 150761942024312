import { Dialog } from "@mui/material";
import React from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { ReactComponent as CrossIcon } from "../../../../../assets/img/svg/CrossIcon.svg";
import AButton from "../../common/form-fields-mui/AButton";
import { outlineButtonStyle } from "./style";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper";



const SaveFormConfirmationDialog = ({
  open = false,
  setOpen,
  text,
  onSaveAgree = () => {},
  onAddChapter = () => {},
}) => {
  const handleClose = () => {
    setOpen(false);
    //onCancel();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={{ padding: "1.5rem", width: "24rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "2rem",
            marginBottom: "10px",
            color: "black",
          }}
        >
          <AiOutlineQuestionCircle />
          {/* <MdCancel onClick={handleClose} /> */}
          <IconButtonWrapper onClick={handleClose}> <CrossIcon  /></IconButtonWrapper>
        </div>
        <div>
          <div
            style={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "24px",
              color: "#000000",
              marginBottom: ".6rem",
            }}
          >
            Test Contents Missing
          </div>
          <div
            style={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#414141",
              marginBottom: ".6rem",
              padding:"10px"
            }}
          >
            No chapters have been added in the subject {text}. Are you sure you want to proceed?

          </div>
        </div>

        <div className="mt-2 d-flex align-items-center justify-content-between">
          <AButton
            onClick={() => onAddChapter()}
            className="mt-2"
            variant="outline"
            updatedStyle={outlineButtonStyle}
          >
            Add Content
          </AButton>
          <AButton
            onClick={onSaveAgree}
            className="mt-2"
            variant="outline"
            updatedStyle={outlineButtonStyle}
          >
            Save Test
          </AButton>
        </div>
      </div>
    </Dialog>
  );
};

export default SaveFormConfirmationDialog;
