import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useState } from "react";
import { primaryCheckboxStyles } from "../../issuanceIDcard/printIdCardAndMapRfid/constant";
import SearchByTxnForm from "./SearchByTxnForm";
import SearchByOtherDetailsForm from "./SearchByOtherDetailsForm";
import { getLabelForTxnId } from "./helper";

const IssuanceTabItem = ({
  dropdown,
  checkedOption,
  setCheckedOption,
  isConsumptionReturn,
  selectedTab,
  academicType,
  forHistory = true,
  ...props
}) => {
  const handleCheckboxChange = (option) => {
    setCheckedOption(option);
  };

  const checkboxOptions = [
    {
      title: `Search by ${getLabelForTxnId(selectedTab)} ID`,
      onChange: (e) => {
        e.target.checked && handleCheckboxChange("txnId");
      },
      checked: checkedOption === "txnId",
    },
    {
      title: "Search by other details",
      onChange: (e) => {
        e.target.checked && handleCheckboxChange("otherDetails");
      },
      checked: checkedOption === "otherDetails",
    },
  ];

  return (
    <div className="mt-2">
      {checkboxOptions?.map((item, i) => (
        <FormControlLabel
          key={JSON.stringify(item)}
          disableTypography
          className="flex-grow-1"
          sx={{ margin: "0px", ...primaryCheckboxStyles }}
          control={
            <Checkbox
              sx={{ padding: "3px", marginLeft: i !== 0 ? "10px" : "" }}
              onChange={item?.onChange}
              checked={item?.checked}
            />
          }
          label={
            <div
              className={`semi-bold ${
                item?.checked ? "label-color" : "color-grey-60"
              }`}
            >
              {item?.title}
            </div>
          }
        />
      ))}
      {checkedOption === "txnId" ? (
        <SearchByTxnForm
          dropdown={dropdown}
          checkedOption={checkedOption}
          isConsumptionReturn={isConsumptionReturn}
          selectedTab={selectedTab}
          screenName={props?.screenName}
          academicType={academicType}
          forHistory={forHistory}
        />
      ) : (
        <SearchByOtherDetailsForm
          dropdown={dropdown}
          isConsumptionReturn={isConsumptionReturn}
          academicType={academicType}
          {...props}
        />
      )}
    </div>
  );
};

export default IssuanceTabItem;
