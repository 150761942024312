import { Dialog } from '@mui/material'
import React from 'react'
import AButton from 'views/pages/manage/common/form-fields-mui/AButton'
import { ReactComponent as CrossIcon } from 'assets/img/svg/CrossIcon.svg'
import { ReactComponent as QuestionIcon } from 'assets/img/svg/QuestionMarkConfirmationDialog.svg'
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'

const StatusChangeDialog = ({ open, setOpen, rowData, onAgree = () => { } }) => {

  const handleClose = () => setOpen(false);

  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={{ padding: "28px 24px", width: "500px" }}>
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <QuestionIcon color='#414141' />
          <IconButtonWrapper onClick={handleClose}><CrossIcon width={32} height={32} /></IconButtonWrapper>
        </div>

        <div
          style={{
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "24px",
            color: "#000000",
            marginBottom: "1rem",
          }}
        >
          {rowData?.costCenterStatus === 'INACTIVE' ? `Are you sure, you want to active this mapping?` : `Are you sure, you want to inactive this mapping?`}
        </div>

        <div className='d-flex justify-content-between'>
          <AButton variant='outline' className='button_remove_left_margin' updatedStyle={{ border: '1px solid #414141', color: '#414141', width: '180px' }} onClick={handleClose}>No</AButton>
          {rowData?.costCenterStatus === 'INACTIVE' ? (
            <AButton variant='primary_filled' updatedStyle={{ width: '180px' }} onClick={() => { handleClose(); onAgree(); }}>Yes, Active</AButton>
          ) : (
            <AButton variant='danger_filled' updatedStyle={{ backgroundColor: '#CC5F5F', width: '180px' }} onClick={() => { handleClose(); onAgree(); }}>Yes, Inactive</AButton>
          )}
        </div>
      </div>
    </Dialog>
  )
}

export default StatusChangeDialog