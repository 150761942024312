import {Card, CardBody, CardHeader, Collapse} from "reactstrap";
import React from "react";

export const CustomCollapse =(props)=>{
    const {child=null,isOpen=true,header=null,headerCollapse=()=>{},headerCollapsable=false} = props;
    return (
        <Card className="card-plain">
            <CardHeader className='accordionHeader' onClick={headerCollapsable ? headerCollapse:null} role="tab">
                <h3 className="mb-0">{header}</h3>
            </CardHeader>
            <Collapse role="tabpanel" isOpen={isOpen}>
                <CardBody>
                    {child}
                </CardBody>
            </Collapse>
        </Card>
    )
}