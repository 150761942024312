import React, { useEffect, useState, Fragment } from 'react';
import Loader from 'react-loader-spinner';
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  Input,
  Button,
  Label,
  FormGroup,
  Form,
  FormFeedback,
} from 'reactstrap';
import { validate } from '../../common/utils/methods/validations/validation';
import {
  getAPI,
  masterServiceBaseUrl,
  studentServiceBaseUrl,
  putpost,
  successToast,
  failureToast,
} from 'services/http';
import CustomCalendar from './timeTableAttendance';

const SearchData = () => {
  const [psIdData, setPsIdData] = useState();
  const [psId, setPsId] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [searchPsID, setSearchPsId] = useState('');
  const [isMessage, setIsMessage] = useState(false);
  const clientId = process.env.REACT_APP_STUDENT_REQUEST;

  const submitHandler = type => {
    try {
      if (!psId) return failureToast('Please Enter Student ID');
      setIsDisabled(true);
      putpost(
        `${studentServiceBaseUrl}/mstimetable`,
        data => {
          setPsIdData(data['data']['data']);
          setIsDisabled(false);
          if (!data['data']['data']) {
            failureToast('No Record Exist For Student ID');
          }
          if (type === 'searchInside') {
            setPsId(searchPsID);
            setSearchPsId('');
          }
        },
        data => {
          failureToast('Please Enter Valid Student ID');
          setIsDisabled(false);
        },
        { PSID: type === 'searchInside' ? searchPsID : psId },
        'post'
      );
    } catch (e) {
      console.log('Error ::', e);
      setIsDisabled(false);
    }
  };

  const getSearchData = (id, type) => {
    try {
      if (!id) return failureToast('Please Enter Student ID');
      submitHandler('searchInside');
    } catch (e) {
      console.log('Error ::', e);
    }
  };

  return (
    <Container className="mt-3 student_calendar" fluid data-testid="searchData">
      <Card>
        {!psIdData ? (
          <Fragment>
            <CardHeader>
              <h2 className="heading_color">Student Calendar</h2>
            </CardHeader>
            <CardBody>
              <Row className="d-flex align-items-center justify-content-center">
                <Col sm="6">
                  <Form>
                    <FormGroup>
                      <Label className="form-control-label">Enter Student ID</Label>
                      <Input
                        value={psId}
                        disabled={isDisabled}
                        maxLength="12"
                        placeholder="Enter Student ID"
                        name="psid"
                        data-testid="searchPSID"
                        onChange={e => {
                          let value = e.target.value;
                          if (validate('numericValue', value)) {
                            setPsId(value);
                          }
                        }}
                      />
                      <Button
                        disabled={isDisabled}
                        data-testid="submitSearch"
                        onClick={() => submitHandler('searchOutside')}
                        color="info"
                        className="w-100 mt-3"
                      >
                        Submit
                        {isDisabled ? (
                          <Loader className="button-Loader" type="Oval" color="#ffffff" height={30} width={30} />
                        ) : null}
                      </Button>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Fragment>
        ) : (
          <CustomCalendar
            psId={psId}
            psIdData={psIdData}
            isDisabled={isDisabled}
            setSearchPsId={setSearchPsId}
            searchPsID={searchPsID}
            getSearchData={getSearchData}
          />
        )}
      </Card>
    </Container>
  );
};

export default SearchData;
