export const BATCH_LIST = 'BATCH_LIST';
export const BATCH_LIST_REQUEST = 'BATCH_LIST_REQUEST' 
export const BATCH_LIST_SUCCESS = 'BATCH_LIST_SUCCESS' ;
export const BATCH_LIST_FAILURE = 'BATCH_LIST_FAILURE'
export const UPDATE_BATCH_DROPDOWNS = 'UPDATE_BATCH_DROPDOWNS'
export const UPDATE_BATCH_MAIN_DROPDOWNS = 'UPDATE_BATCH_MAIN_DROPDOWNS'
export const UPDATE_BATCH_STATUS_FAILURE = 'UPDATE_BATCH_STATUS_FAILURE'
export const START_DOWNLOAD_LOADER = 'START_DOWNLOAD_LOADER';
export const STOP_DOWNLOAD_LOADER = 'STOP_DOWNLOAD_LOADER';
export const START_LOADER = 'START_LOADER';
export const STOP_LOADER = 'STOP_LOADER';