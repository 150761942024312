import { Dialog } from '@mui/material'
import React from 'react'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { MdCancel } from 'react-icons/md'
import { useSelector } from 'react-redux'
import AButton from '../../common/form-fields-mui/AButton'
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper'

const ConfirmationDialog = ({
  open,
  setOpen,
  id,
  deallocateStudentHandler,
  isMulti,
  selectionModel,
  setSelectionModel = () => { }
}) => {

  const batchDetails = useSelector(state => state.allocateStudents.batchDetails)
  const handleClose = () => setOpen(false)

  const onAction = () => {
    let reqBody = [];
    if (isMulti) reqBody = selectionModel
    else reqBody = [id];
    // console.log(reqBody);
    // handle based on type
    deallocateStudentHandler(reqBody);
    setSelectionModel([]);
    handleClose();
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={{ padding: "1.5rem", width: "400px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "2rem",
            marginBottom: "10px",
            color: "black",
          }}
        >
          <AiOutlineQuestionCircle />
          <IconButtonWrapper onClick={handleClose}>
            <MdCancel color='black' fontSize= '2rem' />
          </IconButtonWrapper>
        </div>
        <div>
          <div
            style={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "24px",
              color: "#000000",
              marginBottom: ".6rem",
            }}
          >
            {"Confirm Deallocate Students"}
          </div>
          <div
            style={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#414141",
              marginBottom: ".6rem",
            }}
          >
            {batchDetails?.batchType === "PRIMARY" && (
              <span>
                Note: Student(s) will be deallocated from respective secondary
                batches as well. Are you sure you want to proceed?
              </span>
            )}
            {batchDetails?.batchType === "SECONDARY" &&
              `Are you sure you want to deallocate ${
                isMulti ? selectionModel.length : 1
              } students from batch ${batchDetails.batchName}?`}
          </div>
        </div>

        <div className="d-flex" style={{ marginTop: "28px" }}>
          <AButton
            className={"button_remove_left_margin"}
            variant="outline"
            updatedStyle={{
              width: "7rem",
              border: "1px solid #414141",
              color: "#414141",
            }}
            onClick={handleClose}
          >
            Go back
          </AButton>
          <AButton
            variant="danger_filled"
            updatedStyle={{
              backgroundColor: "#CC5F5F",
              // color: "#fff",
              // width: "7rem",
            }}
            onClick={onAction}
          >
            Yes, Deallocate
          </AButton>
        </div>
      </div>
    </Dialog>
  );
}

export default ConfirmationDialog