import React, {Fragment, useEffect, useState} from 'react';
import ExcelExportButton from '../../../common/commonComponents/ToExcel'
import {Col, Row, Button, Table, Input} from "reactstrap";
import {MasterHeaderAuto} from "../../../common/commonComponents/masterHeader/masterHeader";
import TableList from "../../../common/tableList";
import Select from "react-select2-wrapper";
import {masterServiceBaseUrl, putpost, putpost2, successToast} from "../../../../../../services/http";
import moment from "moment";
import {failureToast} from "../../../common/utils/methods/toasterFunctions/function";

const _ = require('lodash');
const RfIdTable = (props) => {

  const {
    previousPage,
    state,
    setRfIdArray,
    rfIdArray,
    isSaveVisible,
    setIsSaveVisible,
    searchValue,
    fetchSearchData,
    searchDisplayValue
  } = props
  const [tableDataKey, settableDataKey] = useState('tableDataKey');
  const addNewClass = () => {
    setRfIdArray([
      {
        editMode: true,
        __status: '__new',
        status: 'ACTIVE',
        rfidCardNumber: null,
        rfidVendor: null,
        groupCodeId: searchValue?.groupCodeId,
        companyCodeId: searchValue?.companyCodeId,
        zoneId: searchValue?.zoneId,
        regionId: searchValue?.regionId,
        businessAreaId: searchValue?.businessAreaId,
        academicYearId: searchValue?.academicYearId,
      },
      ...rfIdArray,
    ]);
  };
  const tableHeader = [
    {name: 'RFID Card Number', isRequired: true},
    {name: 'RFID Vendor', isRequired: true},
    {name: 'Status'},
    {name: 'Action'},
  ]

  const listDetails = {
    rfidCardNumber: {
      normal: true
    },
    rfidVendor: {
      normal: true
    },
    // groupCode:{
    //   normal : false,
    //   master: groupCode,
    //   displayValue: 'groupCodeDispValue',
    //   idName: 'groupCode'
    // },
  }

  const editRow = (index) => {
    try {
      if (isSaveVisible) {
        failureToast('Please Delete or Save current changes');
      } else {
        let cb = () => {
          const _tempArray = rfIdArray;
          _tempArray[index]['editMode'] = true;
          setRfIdArray(_tempArray);
          setIsSaveVisible(true);

        };
        cb();
      }
    } catch (e) {
      console.log('Error : ', e)
    }
  }

  const addNewRow = () => {
    try {
      if (isSaveVisible) {
        failureToast('Please Delete or Save current changes');
      } else {
        let cb = () => {
          addNewClass();
          setIsSaveVisible(true);
        };
        let _tempArray = rfIdArray;
        _tempArray = _.sortBy(_tempArray, [
          function (o) {
            return o.value;
          },
        ]);
        setRfIdArray(_tempArray);
        setIsSaveVisible(false);
        cb();
      }
    } catch (e) {
      console.log('Error ', e);
    }
  }
  const deleteRow = (index) => {
    try {
      if (rfIdArray[index]['__status'] === '__new') {
        let n = rfIdArray;
        n.splice(index, 1);
        setRfIdArray(n);
        setIsSaveVisible(false);
        settableDataKey(new Date().getMilliseconds());
      } else {
        if (
          window.confirm(
            'Are you sure you want to delete this RFID Details ?'
          )
        ) {
          putpost2(
            masterServiceBaseUrl +
            '/rfidMaster/deleteRfidMaster/' +
            rfIdArray[index].id,
            (data) => {
              successToast(data['message']);
              if (rfIdArray.length === 1) {
                previousPage();
                fetchSearchData(searchValue);
              } else {
                const _tempArray = rfIdArray;
                if (_tempArray[index]['editMode']) {
                  setIsSaveVisible(false);
                }
                _tempArray.splice(index, 1);
                setRfIdArray(_tempArray);
              }
              settableDataKey(new Date().getMilliseconds());
            },
            (data) => {
              failureToast(data['message']);
            },
            'PUT'
          );
        }
      }
    } catch (e) {
      console.log('Error : ', e)
    }
  }

  const saveAll = () => {
    try {
      const _tempArray = rfIdArray;
      let isValid = true;
      _tempArray.forEach((el, index) => {
        if (el['editMode']) {
          if (!el['rfidCardNumber']) {
            isValid = false;
            failureToast('Please Enter Card Number');
            return;
          } else if (!el['rfidVendor']) {
            isValid = false;
            failureToast('Please Enter Vendor');
            return;
          }
          if (!isValid) {
            return;
          }
          if (_tempArray[index]['__status'] === '__new') {
            putpost(
              masterServiceBaseUrl +
              '/rfidMaster/createRfidMaster',
              (data) => {
                successToast(data['message']);
                setIsSaveVisible(false);
                fetchSearchData(searchValue);
              },
              (data) => {
                failureToast(data['message']);
              },
              _tempArray[index],
              'post'
            );
          } else {
            putpost(
              masterServiceBaseUrl +
              '/rfidMaster/updateRfidMaster/' +
              _tempArray[index].id,
              (data) => {
                successToast(data['message']);
                setIsSaveVisible(false);
                fetchSearchData(searchValue);
                settableDataKey(new Date().getMilliseconds());
              },
              (data) => {
                failureToast(data['message']);
              },
              _tempArray[index],
              'put'
            );
          }
        } else {
        }
      });
    } catch (err) {
      console.log('Error', err);
    }
  }

  const updateKey = (index, value, key) => {
    try {
      const _tempArray = rfIdArray;
      _tempArray[index][key] = value;
      setRfIdArray(_tempArray);
    } catch (e) {
      console.log('Error ', e);
    }
  };


  const excelDetails = {
    'S.NO': 'S.NO',
    'Business Group': 'businessGroup',
    'Company Code': 'CompanyCode',
    'Zone': 'zone',
    Region: 'region',
    'Business Area': 'businessArea',
    'Academic Year': 'academicYear',
    'RFID Card Number': 'cardNumber',
    'RFID Vendor': 'vendor',
    Status: 'Status',
  };

  const exportData = state?.map((item, index) => {
    return {
      ...item,
      'S.NO': index + 1,
      businessGroup: searchDisplayValue?.businessGroup,
      CompanyCode: searchDisplayValue?.companyCode,
      zone: searchDisplayValue?.zone,
      region: searchDisplayValue?.region,
      businessArea: searchDisplayValue?.businessArea,
      academicYear: searchDisplayValue?.academicYear,
      cardNumber: item.rfidCardNumber,
      vendor: item.rfidVendor,
      Status: item.status,
    };
  });

  useEffect(() => {

  }, [])
  return (
    <Fragment>
      <Row className={'mt-6'}>
        <Col className={'d-flex justify-content-end align-items-center col-12 mb-2'}>
          <ExcelExportButton
            data={exportData}
            detailObj={excelDetails}
            name='Attribute Mapping Details'
            className={'mx-3'}
          />
          <Button color={'info'} size={'sm'} onClick={() => addNewRow()} className={'mx-3'}>Add New</Button>
        </Col>
        <Col>
          <div
            id='questionTable'
            className='table-responsive'
            style={{overflow: 'auto'}}
          >
            <Table className='align-items-center table-flush '>
              <MasterHeaderAuto headerList={tableHeader}/>
              <tbody className='list'>
              {rfIdArray
                ? rfIdArray.map((el, index) =>
                  !el['editMode'] ? (
                    <>
                      <TableList
                        item={el}
                        listDetails={listDetails}
                      >
                        <td
                          className='text-center  white-breakSpace'
                          style={{width: '200px'}}
                        >
                          <Button
                            color='info'
                            size='sm'
                            type='button'
                            disabled={props.isViewOnly}
                            onClick={() => editRow(index)}
                          >
                            <i className='fas fa-edit'/>
                          </Button>
                          <Button
                            color='info'
                            size='sm'
                            type='button'
                            className={' mx-1'}
                            onClick={() => deleteRow(index)}
                          >
                            <i className='fas fa-trash'/>
                          </Button>
                        </td>
                      </TableList>
                    </>
                  ) : (
                    <tr key={index + '-class'}>
                      <td>
                        <Input
                          className={'tdInput'}
                          defaultValue={el['rfidCardNumber']}
                          maxLength={50}
                          onChange={(e) => {
                            let value = e.target.value;
                            updateKey(index, value, 'rfidCardNumber');
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          defaultValue={el['rfidVendor']}
                          className={'tdInput'}
                          maxLength={100}
                          onChange={(e) => {
                            let value = e.target.value;
                            updateKey(index, value, 'rfidVendor');
                          }}
                        />
                      </td>
                      <td className='text-center p-2 mx-1'>
                        <label className='custom-toggle mx-auto ml-2'>
                          <input
                            checked={
                              el.status === 'ACTIVE'
                            }
                            type='checkbox'
                            onChange={(e) => {
                              settableDataKey(
                                new Date().getMilliseconds()
                              );
                              let n = 'ACTIVE';
                              if (!e.target.checked) {
                                n = 'INACTIVE';
                              }
                              updateKey(index, n, 'status');
                            }}
                          />
                          <span
                            style={{width: '72px'}}
                            className='custom-toggle-slider rounded-circle activeToggle'
                            data-label-off='Inactive'
                            data-label-on='Active'
                          />
                        </label>
                      </td>
                      <td className='text-center'>
                        {isSaveVisible ? (
                          <>
                            {' '}
                            <Button
                              data-testid='saveRow'
                              color='info'
                              size='sm'
                              type='button'
                              className='mx-1'
                              onClick={() => {
                                saveAll();
                              }}
                            >
                              Save
                            </Button>
                            <Button
                              data-testid='closeRow'
                              color='danger'
                              size='sm'
                              type='button'
                              onClick={() => {
                                let n = rfIdArray;
                                if (n[index]['__status'] === '__new') {
                                  n.splice(index, 1);
                                } else {
                                  setIsSaveVisible(false);
                                }
                                setRfIdArray(n);
                                setIsSaveVisible(false);
                              }}
                            >
                              {' '}
                              <i className='fas fa-times'/>
                            </Button>{' '}
                          </>
                        ) : (
                          <Button
                            data-testid='deleteRow'
                            color='info'
                            size='sm'
                            type='button'
                            onClick={() => {
                              deleteRow(index);
                            }}
                          >
                            {' '}
                            <i className='fas fa-trash'/>
                          </Button>
                        )}
                      </td>
                    </tr>
                  )
                )
                : null}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

export default RfIdTable;
