import React from "react";
import {Col, Row,Form} from "reactstrap";
import CommonInput from "../../../common/formFeilds/input/commonInput";
import { masterServiceBaseUrl} from "services/http";
import {fetchDetails} from "../../../common/utils/methods/commonMethods/utilityMethod";


const FeeSetupRulesSearchForm = (props) =>{
    const {searchData,updateSearchData,setCompanyCode,term ,companyCode,groupCode,academicCareer,label,placeholder} = props;

    return (
        <Form>
            <Row>
                <Col md={3}>
                    <CommonInput label={label.businessGroup}
                                 data={groupCode}
                                 type={'reactSelect'}
                                 mandatory={true}
                                 placeHolder={placeholder.businessGroup}
                                 onChange={(value)=>{
                                     if(value) {
                                         updateSearchData('groupCodeId',value);
                                         setCompanyCode([])
                                         updateSearchData('companyCodeId', null);
                                         updateSearchData('academicCareerId', null);
                                         fetchDetails(masterServiceBaseUrl + `/companyCode/getCompanyCodeByGroup/${value}`, setCompanyCode, 'companyCode', 'reactSelect');
                                     }
                                 }}
                                 defaultValue={searchData["groupCodeId"]}
                                 isDisabled={false}
                    />
                </Col>
                <Col md={3}>
                    <CommonInput label={label.companyCode}
                                 data={companyCode}
                                 type={'reactSelect'}
                                 mandatory={true}
                                 onChange={(value)=>{
                                     if(value){
                                         updateSearchData('companyCodeId', value);
                                         updateSearchData('academicCareerId', null);
                                     }
                                 }}
                                 placeHolder={placeholder.companyCode}
                                 defaultValue={searchData["companyCodeId"]}
                                 isDisabled={false}
                    />
                </Col>
                <Col md={3}>
                    <CommonInput label={label.academicCareer}
                                 data={academicCareer}
                                 type={'reactSelect'}
                                 placeHolder={placeholder.academicCareer}
                                 onChange={(value)=>{
                                     if(value){
                                         updateSearchData('academicCareerId', value);
                                     }
                                 }}
                                 defaultValue={searchData["academicCareerId"]}
                                 isDisabled={false}
                    />
                </Col>
                <Col md={3}>
                    <CommonInput label={label.term}
                                 type={'reactSelect'}
                                 data={term}
                                 placeHolder={placeholder.term}
                                 onChange={(value)=> {
                                     if(value){
                                         updateSearchData('termId', value)
                                     }
                                 }}
                                 defaultValue={searchData["termId"]}
                                 isDisabled={false}
                    />
                </Col>
            </Row>
        </Form>

    );
}
export default FeeSetupRulesSearchForm