import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'
import ADataGrid from '../../../common/data-grid/ADataGrid'
import ActionsCellComponent from './ActionsCellComponent'
import { AGridColDef } from '../../../common/data-grid/ADataGridColDef';
import AAutoComplete from 'views/pages/manage/common/form-fields-mui/AAutoComplete'
import AInput from 'views/pages/manage/common/formFeilds/customInput/AInput'
import AButton from 'views/pages/manage/common/form-fields-mui/AButton'
import { generateRandomId, onKeyPressNumeric, selectStyles } from './common'
import { GridRowModes } from '@mui/x-data-grid';
import { failureToast } from '../../../common/utils/methods/toasterFunctions/function';
import moment from 'moment';
import MultiSelectCellComponentWithSearch from '../../../common/commonComponents/SearchFilterView/MultiSelectCellComponentWithSearch'
import { checkPermission } from '../../../common/utils/methods/commonMethods/utilityMethod';
import { RolePermissions } from 'appContext';
import { PermissionContext } from 'appContext';
import { pages } from '../../../common/constant';

const cellContainerStyle = {
  width: '100%',
  padding: '0 10px'
}

const ListComponent = forwardRef(({ rows, dropdowns, createConfigHandler, updateConfigHandler, deleteConfigHandler }, ref) => {
  const userPermissions = RolePermissions(useContext(PermissionContext), pages['exAakashianConfig']['id']);

  const [isEditOn, setIsEditOn] = useState(false);
  const [rowModesModel, setRowModesModel] = useState({});
  const [form, setForm] = useState({});
  const formHandler = (value, key) => setForm(prev => ({ ...prev, [key]: value }))

  const [rowData, setRowData] = useState(rows);
  useEffect(() => {
    setRowData(rows);
  }, [rows])

  const startEditing = (id, rowData) => {
    setRowModesModel(oldModel => ({ [id]: { mode: GridRowModes.Edit } }))
    setForm(rowData);
    setIsEditOn(true);
  }

  const stopEditing = (id) => {
    setRowModesModel(oldModel => ({ [id]: { mode: GridRowModes.View, ignoreModifications: true } }))
    setForm({})
    setIsEditOn(false);
  }

  const handleEditClick = (params) => {
    if (isEditOn) {
      failureToast('Please save or delete current changes');
    } else {
      startEditing(params?.id, params?.row);
    }
  }

  const handleDeleteClick = (params) => {
    if (params?.row?.isNew) {
      setRowData(rows)
      setIsEditOn(false);
      setForm({});
    }
    else {
      stopEditing(params?.id)
    }
  }

  const handleSaveClick = async (params) => {
    if (form?.isNew) {
      const res = await createConfigHandler(form)
      if (res?.code === 200) {
        stopEditing()
      }
    } else {
      const res = await updateConfigHandler(params?.id, form)
      if (res?.code === 200) {
        stopEditing(params?.id)
      }
    }
  }

  const addNewRow = async () => {
    if (isEditOn) {
      failureToast('Please save or delete current changes')
    } else {
      let randomId = generateRandomId();
      let emptyRow = { isNew: true, id: randomId, academicCareerId: '', termId: '', courseCategoryId: '', minimumCourseValue: '', payableAmount: '', status: '' }
      await startEditing(randomId, emptyRow);
      setRowData(prev => [
        emptyRow,
        ...prev
      ])
    }
  }

  useImperativeHandle(ref, () => ({
    addNewRow: addNewRow,
    startEditing: startEditing,
    stopEditing: stopEditing
  }))

  const columns = [
    new AGridColDef('id', 'ID').setMinWidth(80).setFlex(0.25).editable(false)
      .renderCellComponent(params => (
        <div>{params?.row?.isNew ? '-' : params?.value}</div>
      )),
    new AGridColDef('academicCareerId', 'Career').setMinWidth(100).editable(true)
      .setValueGetter((params) => params?.value?.label)
      .renderEditCellComponent(params => (
        <div style={cellContainerStyle}>
          <AAutoComplete
            currentValue={form?.academicCareerId}
            handleChange={value => formHandler(value, 'academicCareerId')}
            style={selectStyles}
            isMulti={false}
            items={dropdowns?.academicCareer || []}
          />
        </div>
      )),
    new AGridColDef('termId', 'Min. term').setMinWidth(100).editable(true)
      .setValueGetter(params => params?.value?.label)
      .renderEditCellComponent(params => (
        <div style={cellContainerStyle}>
          <AAutoComplete
            currentValue={form?.termId}
            handleChange={value => formHandler(value, 'termId')}
            style={selectStyles}
            isMulti={false}
            items={dropdowns?.term || []}
          />
        </div>
      )),
    new AGridColDef('courseCategoryId', 'Course category', false).setMinWidth(150).editable(true)
      .renderCellComponent(params => <MultiSelectCellComponentWithSearch data={params?.value} title='Course category' />)
      .renderEditCellComponent(params => (
        <div style={cellContainerStyle}>
          <AAutoComplete
            currentValue={form?.courseCategoryId}
            handleChange={value => formHandler(value, 'courseCategoryId')}
            style={selectStyles}
            isShowCount={true}
            items={dropdowns?.courseCategory || []}
          />
        </div>
      )),
    new AGridColDef('minimumCourseValue', 'Min. course value').setMinWidth(150).editable(true)
      .renderCellComponent(params => <div><span className='mr-1'>&#8377;</span>{params?.value}</div>)
      .renderEditCellComponent(params => (
        <div style={cellContainerStyle}>
          <AInput
            placeholder='Enter'
            value={form?.minimumCourseValue}
            handler={value => {
              if (!value || (parseInt(value) >= 0 && parseFloat(value) < 1000000)) {
                // formHandler(value, 'minimumCourseValue')
                formHandler(value.replace(/[^0-9.]/g, "")
                  .replace(/(\..*)\./g, "$1")
                  .replace(/(\.\d{1,3}).*/g, "$1"), 'minimumCourseValue');
              }
            }}
            // onKeyPress={onKeyPressNumeric}
            customIcon={<div style={{ fontSize: '14px' }}>&#8377;</div>}
            customIconStyle={{ left: '8px' }}
            style={{ paddingLeft: '20px', borderRadius: '4px' }}
          />
        </div>
      )),
    new AGridColDef('payableAmount', 'Payable amount').setMinWidth(150).editable(true)
      .renderCellComponent(params => <div>{params?.value}<span className='ml-1'>%</span></div>)
      .renderEditCellComponent(params => (
        <div style={cellContainerStyle}>
          <AInput
            placeholder='Enter'
            value={form?.payableAmount}
            handler={value => {
              if (!value || (parseFloat(value) >= 0 && parseFloat(value) <= 100))
                // formHandler(value, 'payableAmount')
                formHandler(value.replace(/[^0-9.]/g, "")
                  .replace(/(\..*)\./g, "$1")
                  .replace(/(\.\d{1,2}).*/g, "$1"), 'payableAmount');
            }}
            // onKeyPress={onKeyPressNumeric}
            customIcon={<div style={{ fontSize: '14px' }}>%</div>}
            customIconStyle={{ right: '8px' }}
            style={{ paddingRight: '20px', borderRadius: '4px' }}
          />
        </div>
      )),
    new AGridColDef('programStatus', 'Program status', false).setMinWidth(150).editable(true)
      .renderCellComponent(params => <MultiSelectCellComponentWithSearch data={params?.value} title='Program status' />)
      .renderEditCellComponent(params => (
        <div style={cellContainerStyle}>
          <AAutoComplete
            currentValue={form?.programStatus}
            handleChange={value => formHandler(value, 'programStatus')}
            style={selectStyles}
            isShowCount={true}
            items={dropdowns?.programStatus || []}
          />
        </div>
      )),
    new AGridColDef('updatedOn', 'Last update').setMinWidth(150).editable(false)
      .renderCellComponent((params) => <div>{params?.value ? new moment(params?.value)?.format('HH:mm, DD-MMM-YYYY') : '-'}</div>),
    ...((checkPermission(userPermissions, 'U') || checkPermission(userPermissions, 'D')) ? [
      new AGridColDef('Actions', 'Actions', false).setMinWidth(150).columnAlign('right').setFlex(0.5)
        .renderCellComponent((params) => <ActionsCellComponent params={params} handleEditClick={handleEditClick} handleDeleteClick={handleDeleteClick} handleSaveClick={handleSaveClick} deleteConfigHandler={deleteConfigHandler} rowForm={form} />)
    ] : []),
  ];

  return (
    <>
      <ADataGrid
        rowModesModel={rowModesModel}
        rows={rowData || []}
        columns={columns}
        rowId={(row) => row.id}
        loading={false}
        serverPagination={false}
        // emptyRowPlaceholder={() => <NoRowOverlay />}
        height={100}
        rowHeight={50}
        sx={{
          color: '#262626',
          borderTop: 'none !important',
          // '.MuiDataGrid-main': {
          //   height: `${60 + rowData?.length * 50}px`,
          //   minHeight: `${60 + 50}px`,
          //   maxHeight: `${60 + 50 * 50}px`
          // },
          '.MuiDataGrid-columnHeaderTitle': {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
          },
          // '.MuiDataGrid-footerContainer': {
          //   display: 'none'
          // }
        }}
        autoHeight
      />
    </>
  )
})

export default ListComponent