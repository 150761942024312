import React from "react";
import { Row, Col, FormGroup, Input, Label } from "reactstrap";
import moment from "moment";

const LogDetails = (props) => {
  const { glCodeDetails } = props;
  return (
    <Row>
      <Col>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="form-control-label">Created Date</Label>
              <Input
              data-testid='createdOn'
                value={
                  glCodeDetails["createdOn"]
                    ? moment(glCodeDetails["createdOn"]).format("DD-MM-YYYY")
                    : moment().format("DD-MM-YYYY")
                }
                disabled={true}
                className="customInput"
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="form-control-label">Last Updated Date</Label>
              <Input
                data-testid='updatedOn'
                value={
                  glCodeDetails["updatedOn"]
                    ? moment(glCodeDetails["updatedOn"]).format("DD-MM-YYYY")
                    : moment().format("DD-MM-YYYY")
                }
                disabled={true}
                className="customInput"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="form-control-label">Created By</Label>
              <Input
              data-testid='createdBy'
                value={
                  glCodeDetails["createdBy"] ? glCodeDetails["createdBy"] : ""
                }
                disabled={true}
                className="customInput"
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="form-control-label">Last Updated By</Label>
              <Input
              data-testid='updatedBy'
                value={
                  glCodeDetails["updatedBy"] ? glCodeDetails["updatedBy"] : ""
                }
                disabled={true}
                className="customInput"
              />
            </FormGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default LogDetails;
