import { constants } from "../../views/pages/manage/common/constant";
import { ALL_ACTIVE_CONFIG_FAILURE, ALL_ACTIVE_CONFIG_REQUEST, ALL_ACTIVE_CONFIG_SUCCESS, ALL_BUSINESS_AREAS_FAILURE, ALL_BUSINESS_AREAS_REQUEST, ALL_BUSINESS_AREAS_SUCCESS, ALL_COURSE_CATEGORIES_FAILURE, ALL_COURSE_CATEGORIES_REQUEST, ALL_COURSE_CATEGORIES_SUCCESS, ALL_COURSE_IDS_FAILURE, ALL_COURSE_IDS_REQUEST, ALL_COURSE_IDS_SUCCESS, ALL_REGIONS_FAILURE, ALL_REGIONS_REQUEST, ALL_REGIONS_SUCCESS, APPROVAL_REQ_FAILURE, APPROVAL_REQ_REQUEST, APPROVAL_REQ_SUCCESS, CLEAR_APPROVAL_DATA, CLEAR_SELECTED_ROW_IDS, ROLE_ID_FAILURE, ROLE_ID_REQUEST, ROLE_ID_SUCCESS, SET_APPROVER_TYPE, SET_SELECTED_ROW_IDS, DELETE_VERIFICATION_ROW, SET_VERIFICATION_ROWS,OTHER_CHARGES_APPROVAL_REQ_REQUEST,
    OTHER_CHARGES_APPROVAL_REQ_SUCCESS,
    OTHER_CHARGES_APPROVAL_REQ_FAILURE, } from "./actionTypes";
import { getSelectedRowsArr, otherChargesGroup, setGroupedRequests } from "./common";

const initialState = {
    allActiveConfigs: [],
    allActiveConfigsLoading: false,
    allApprovalRequests: [],
    allApprovalRequestsLoading: false,
    approverType: constants.APPROVER_TYPES_DATA[0],
    groupedRequests: {},
    allBusinessAreas: [],
    allRegions: [],
    allCourseCategories: [],
    allCourses: [],
    masterDataLoading: false,
    role: null,
    selectedRowIds: {},
    selectedVerificationRows: [],
    otherChargesRequests: [],
    otherChargesGroupData: {},
    otherChargesPayload: {},
    otherChargesRequestsLoading: false,
    totalRecords : 0,
    currentPage : 0
}

export const concessionApprovalReducer = (state = initialState, action) => {
    switch (action.type) {
        case ALL_ACTIVE_CONFIG_REQUEST:
            return {
                ...state,
                allActiveConfigsLoading: true
            }
        case ALL_ACTIVE_CONFIG_SUCCESS:
            return {
                ...state,
                allActiveConfigsLoading: false,
                allActiveConfigs: action.payload,
                groupedRequests: setGroupedRequests(action.payload, state.allApprovalRequests, state.approverType, state.role?.id)
            }
        case ALL_ACTIVE_CONFIG_FAILURE:
            return {
                ...state,
                allActiveConfigsLoading: false,
                allActiveConfigs: [],
                groupedRequests: []
            }

        case ROLE_ID_REQUEST:
            return {
                ...state,
            }
        case ROLE_ID_SUCCESS:
            return {
                ...state,
                role: action.payload,
                groupedRequests: setGroupedRequests(state.allActiveConfigs, state.allApprovalRequests, state.approverType, action?.payload?.id)
            }
        case ROLE_ID_FAILURE:
            return {
                ...state,
                role: null
            }


        case ALL_BUSINESS_AREAS_REQUEST:
            return {
                ...state,
                masterDataLoading: true
            }
        case ALL_BUSINESS_AREAS_SUCCESS:
            return {
                ...state,
                allBusinessAreas: action.payload,
                masterDataLoading: false
            }
        case ALL_BUSINESS_AREAS_FAILURE:
            return {
                ...state,
                allBusinessAreas: [],
                masterDataLoading: false,
            }

        case ALL_COURSE_CATEGORIES_REQUEST:
            return {
                ...state,
                masterDataLoading: true
            }
        case ALL_COURSE_CATEGORIES_SUCCESS:
            return {
                ...state,
                allCourseCategories: action.payload,
                masterDataLoading: false
            }
        case ALL_COURSE_CATEGORIES_FAILURE:
            return {
                ...state,
                allCourseCategories: [],
                masterDataLoading: false,
            }

        case ALL_REGIONS_REQUEST:
            return {
                ...state,
                masterDataLoading: true
            }
        case ALL_REGIONS_SUCCESS:
            return {
                ...state,
                allRegions: action.payload,
                masterDataLoading: false
            }
        case ALL_REGIONS_FAILURE:
            return {
                ...state,
                allRegions: [],
                masterDataLoading: false,
            }

        case ALL_COURSE_IDS_REQUEST:
            return {
                ...state,
                masterDataLoading: true
            }
        case ALL_COURSE_IDS_SUCCESS:
            return {
                ...state,
                allCourses: action.payload,
                masterDataLoading: false
            }
        case ALL_COURSE_IDS_FAILURE:
            return {
                ...state,
                allCourses: [],
                masterDataLoading: false,
            }


        case APPROVAL_REQ_REQUEST:
            return {
                ...state,
                allApprovalRequestsLoading: true
            }
        case APPROVAL_REQ_SUCCESS:
            return {
                ...state,
                allApprovalRequestsLoading: false,
                allApprovalRequests: action.payload?.approvalSearchResponseDTOS,
                totalRecords: action.payload?.totalRecords,
                currentPage: action.currentPage,
                groupedRequests: setGroupedRequests(state.allActiveConfigs, action.payload.approvalSearchResponseDTOS, state.approverType, state.role?.id),
                selectedVerificationRows: action.payload?.approvalSearchResponseDTOS?.length > 0 ? state.selectedVerificationRows?.map(row => action.payload.approvalSearchResponseDTOS.find(req => req.applicationQuotationDetailsId === row.applicationQuotationDetailsId)) || [] : []
            }
        case APPROVAL_REQ_FAILURE:
            return {
                ...state,
                allApprovalRequestsLoading: false,
                allApprovalRequests: [],
                groupedRequests: [],
                totalRecords: 0,
                currentPage: action.currentPage
            }
        case SET_APPROVER_TYPE:
            return {
                ...state,
                approverType: action.payload
            }
        case SET_SELECTED_ROW_IDS:
            return {
                ...state,
                selectedRowIds: { ...state.selectedRowIds, [action.payload.key]: action.payload.value },
                selectedVerificationRows: getSelectedRowsArr({ ...state.selectedRowIds, [action.payload.key]: action.payload.value }, state.allApprovalRequests)
            }

        case SET_VERIFICATION_ROWS:
            return {
                ...state,
                selectedVerificationRows: getSelectedRowsArr(state.selectedRowIds, state.allApprovalRequests)
            }
        case DELETE_VERIFICATION_ROW:
            return {
                ...state,
                selectedVerificationRows: state.selectedVerificationRows.filter(row => row.applicationQuotationDetailsId !== action.payload.id)
            }
        case CLEAR_SELECTED_ROW_IDS:
            return {
                ...state,
                selectedRowIds: {},
                selectedVerificationRows: []
            }
        case CLEAR_APPROVAL_DATA:
            return initialState;
        case OTHER_CHARGES_APPROVAL_REQ_REQUEST:
            return {
                ...state,
                otherChargesRequestsLoading: true,
            }
        case OTHER_CHARGES_APPROVAL_REQ_SUCCESS:
            return {
                ...state,
                otherChargesRequests: action.payload?.otherChargesDetailsDTOList,
                totalRecords: action.payload?.totalRecords,
                otherChargesRequestsLoading: false,
                otherChargesPayload: action.payload,
                currentPage: action.currentPage,
                otherChargesGroupData: otherChargesGroup(action.payload?.otherChargesDetailsDTOList)
                // allApprovalRequests: action.payload,
                // groupedRequests: setGroupedRequests(state.allActiveConfigs, action.payload, state.approverType, state.role?.id),
                // selectedVerificationRows: action.payload.length > 0 ? state.selectedVerificationRows?.map(row => action.payload.find(req => req.id === row.id)) || [] : []
            }
        case OTHER_CHARGES_APPROVAL_REQ_FAILURE:
            return {
                ...state,
                otherChargesRequestsLoading: false,
                otherChargesRequests: [],
                totalRecords: 0,
                currentPage: action.currentPage,
                otherChargesGroupData: {}
            }
        default:
            return state
    }
}