import {Button} from "reactstrap";
import React,{Fragment} from "react";
const SearchButton =(props)=>{
    const {text} = props;
    return (
        <Fragment>
            <Button type="button" color="info" size="md" {...props}>
                <i className="fas fa-search" /> {'  '}&nbsp;{text}
            </Button>
        </Fragment>
    )
}
export default SearchButton;