import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import BasicForm from "./BasicForm";
import FistLabel from "./FistLabel";
import FormHeader from "./FormHeader";
import FormFooter from "./FormFooter";
import SaveFormConfirmationDialog from "./SaveFormConfirmationDialog";
import {failureToast} from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import {
  basicForm,
  createRequestFormate,
  createTest,
  getAllSelected,
  formDataSet,
  onSaveValidatorTestContent,
  getSyllabusByTestId,
  getFinalModal,
  isBasicFormFilled,
} from "./style";

import TestContent from "./TestContent";
import { useGetCoursesByPackageId, useFetchTestTypes } from "./hooks";
import ALoader from "./../../common/a-loader/index";
import DataLostConfirmation from "./DataLostConfirmation";
import { successToast } from "../../common/utils/methods/toasterFunctions/function";
import { isDatePassed } from "../academicTimetable/helper";
export const TEST_MODE_FOR_9AM = ["Online", "Both"];
const TestForm = ({
  open,
  onClose,
  academicPlanId,
  getTestList = () => { },
  getSavedTest = () => { },
  basicDetails,
  testId,
  packageId,
  planData,
  formData = {},
  isTimeTableTest = false,
  isDisableTestType = false
}) => {
  // console.log(basicDetails);
  const [isSecondFormOpen, setIsSecondFormOpen] = useState(false);
  const [isCompleteSyllabus, setIsCompleteSyllabus] = useState(
    basicDetails?.all || false
  );
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState();
  const [confirmationTxt, setConfirmationTxt] = useState("");

  const [formModal, setFormModal] = useState({ Physics: [formDataSet] });
  const [finalSavedData, setFinalSavedData] = useState({});
  const [currentSelectedTab, setSelectedTab] = useState(0);
  const [courses, setCourses] = useGetCoursesByPackageId(
    planData?.packageId,
    setSelectedTab,
    setFormModal,
    basicDetails
  );
  const [basicFormModal, setBasicModal] = useState(basicDetails || basicForm);
  const FORM_OPEN_MODE_EDIT = basicDetails ? true : false;
  const [isBasicExpanded, setBasicExpanded] = useState(true);
  const [isTestContentExpanded, setIsTestContentExpanded] = useState(true);
  const testTypesDropdown = useFetchTestTypes();
  const [isTestDetailsUpdate, setIsTestDetailsUpdated] = useState(false)
  // this is for active subject tab
  const [value, setValue] = React.useState("1");
  const [dataLostConfirmation, setDataLostConfirmation] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [isResultDatePassed, setIsResultDatePassed] = useState(
    basicDetails &&
      isDatePassed(basicDetails?.startDate) &&
      !isDatePassed(basicDetails?.resultData)
      ? true
      : false
  );
  // console.log({ basicDetails });

  const nextButtonHandler = () => {
    setIsSecondFormOpen(true);
    setBasicExpanded(false);
    fetchSyllabusByTestId();
  };

  const fetchSyllabusByTestId = () => {
    if (testId && FORM_OPEN_MODE_EDIT) {
      setLoading(true);
      getSyllabusByTestId(testId)
        .then((res) => {
          if (res && res.data?.testContent) {
            setFinalSavedData(getFinalModal(res.data.testContent));
            setLoading(false);
          }
        })
        .catch((e) => setLoading(false));
    }
  };

  const basicFormHandler = (value, inputName) => {
    let currentData = { ...basicFormModal };
    if (inputName === "startDate") {
      currentData["resultDate"] = "";
      currentData["endDate"] = "";
    }
    if (inputName === "endDate") {
      currentData["resultDate"] = "";
    }
    if (inputName === 'mode') {
      currentData['testMediumType'] = value?.value?.toLowerCase()!=='offline' ? 'Tribyte' : ''
    }
    currentData[inputName] = value;
    setBasicModal(currentData);
    setIsTestDetailsUpdated(true)
  };

  const testContentFormHandler = (value, subject, input) => {
    const currentData = { ...formModal };
    currentData[subject][input] = value;
    setFormModal(currentData);
  };

  const addCurrentFormToFinal = (currentForm, subject) => {
    if (
      currentForm &&
      currentForm[0]?.chapter !== "" &&
      currentForm[0]?.topic?.length > 0
    ) {
      let tempFinal = { ...finalSavedData };
      if (!tempFinal[subject]) {
        tempFinal[subject] = [...currentForm];
      } else {
        tempFinal[subject] = [...tempFinal[subject], ...currentForm];
      }
      setFinalSavedData(tempFinal);
    }
  };

  const addChapterHandler = (subject) => {
    const currentData = { ...formModal };
    addCurrentFormToFinal(formModal[subject], subject);
    currentData[subject] = [formDataSet];
    if (
      finalSavedData &&
      finalSavedData[subject] &&
      finalSavedData[subject][0] &&
      finalSavedData[subject][0].chapter.key === "ALL"
    ) {
      return;
    }
    setFormModal(currentData);
  };

  const saveChapterHandler = (subject, data) => {
    let temp = { ...finalSavedData };
    if (!temp[subject]) {
      temp[subject] = [{ ...data, isEditMode: false }];
    } else {
      temp = {
        ...temp,
        [subject]: [...temp[subject], { ...data, isEditMode: false }],
      };
    }
    setFormModal({ ...formModal, [subject]: [] });
    setFinalSavedData(temp);
  };
  const dataCleanUp = () => {
    setBasicModal({ ...basicForm });
    setFinalSavedData({});
    setIsSecondFormOpen(false);
    setBasicExpanded(true);
  };
  const onCloseWithCleanUp = () => {
    if (!FORM_OPEN_MODE_EDIT && isBasicFormFilled(basicFormModal)) {
      setDataLostConfirmation(true);
      return;
    } else {
      setBasicModal({ ...basicForm });
      setFinalSavedData({});
      setIsSecondFormOpen(false);
      setBasicExpanded(true);
      onClose();
    }
  };

  const goBackWithDataLost = () => {
    setDataLostConfirmation(false);
    setBasicModal({ ...basicForm });
    setFinalSavedData({});
    setIsSecondFormOpen(false);
    setBasicExpanded(true);
    onClose();
  };
  useEffect(() => {
    completeSyllabus(isCompleteSyllabus);
  }, [isCompleteSyllabus]);

  const completeSyllabus = (isCompleteSyllabus) => {
    let formObject = {};
    if (isCompleteSyllabus) {
      courses.forEach((course) => {
        formObject[course["subjectName"]] = getAllSelected();
      });
      setFormModal(formObject);
      //setFinalSavedData(formObject);
    } else {
      setFinalSavedData({});
      setFormModal({});
    }
  };

  const saveTestFormHandler = () => {
    const subjectWithNoContent = onSaveValidatorTestContent(
      formModal,
      finalSavedData
    );
    // console.log(subjectWithNoContent)
    if (
      subjectWithNoContent &&
      subjectWithNoContent.length !== 0 &&
      !isCompleteSyllabus
    ) {
      setConfirmationTxt(subjectWithNoContent);
      setIsSaveConfirmationOpen(true);
    } else {
      onSaveAgree();
    }
  };

  const onSaveAgree = () => {
    setLoading(true);
    let active = isDisableTestType ? true : undefined
    
  createTest(
      {
        id: testId,
        academicPlanId: basicDetails?.academicPlanId || academicPlanId,
        isTimeTableTest,
        active,
        isAllSyllabusIncluded: isCompleteSyllabus,
        ...createRequestFormate(
          basicFormModal,
          isCompleteSyllabus ? formModal : finalSavedData,
          courses,
          isCompleteSyllabus
        ),
      },
      testId && FORM_OPEN_MODE_EDIT
    )
      .then((res) => {
        if (res) {
          getTestList();
          goBackWithDataLost();
          if (res && res?.message) successToast(res.message);
          getSavedTest(res);
        }
        else{
          failureToast("Please add Chapter,Topic and Sub-Topic in atleast one subject");
        }
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  // handle subject tab selection
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  //   if (!formModal[subjects[newValue - 1]]) {
  //     props.setFormModal({
  //       ...formModal,
  //       [subjects[newValue - 1]]: [addTestFormInput],
  //     });
  //   }
  //   if (!props.finalSavedData[subjects[newValue - 1]]) {
  //     props.setFinalSavedData({
  //       ...props.finalSavedData,
  //       [subjects[newValue - 1]]: [],
  //     });
  //   }
  //   currentSelectedCourse(subjects[newValue - 1]);
  // };

  const addRestChapterHandler = (subjectWithNoContent) => {
    let emptySubjects = subjectWithNoContent.split(",");
    if (emptySubjects && emptySubjects.length > 0 && emptySubjects[0] !== "") {
      let highLightedTab = courses.findIndex(
        (e) => e["subjectName"] === emptySubjects[0]
      );
      setSelectedTab(courses[highLightedTab]);
      // console.log(highLightedTab, courses[highLightedTab], highLightedTab);
      setValue((highLightedTab + 1).toString());
      setIsSaveConfirmationOpen(false);
      setConfirmationTxt("");
      // this line may be needed in future
      //   if (!formModal[subjects[newValue - 1]]) {
      //     props.setFormModal({
      //       ...formModal,
      //       [subjects[newValue - 1]]: [addTestFormInput],
      //     });
      //   }
      //   if (!props.finalSavedData[subjects[newValue - 1]]) {
      //     props.setFinalSavedData({
      //       ...props.finalSavedData,
      //       [subjects[newValue - 1]]: [],
      //     });
      //   }
    }
  };

  return (
    <div>
      <Modal size="xl" isOpen={open} toggle={onCloseWithCleanUp}>
        <ModalBody>
          <FormHeader onClose={onCloseWithCleanUp} />
          <FistLabel
            form={basicFormModal}
            handler={basicFormHandler}
            testTypesDropdown={testTypesDropdown}
            isResultDatePassed={isResultDatePassed}
            isDisableTestType={isDisableTestType}
          />
          <BasicForm
            isBasicExpanded={isBasicExpanded}
            setBasicExpanded={setBasicExpanded}
            basicFormModal={basicFormModal}
            basicFormHandler={basicFormHandler}
            isSecondFormOpen={isSecondFormOpen}
            setIsSecondFormOpen={setIsSecondFormOpen}
            formData={formData}
            isResultDatePassed={isResultDatePassed}
            isTimeTableTest={isTimeTableTest}
          />
          {isSecondFormOpen && (
            <TestContent
              isCompleteSyllabus={isCompleteSyllabus}
              setIsCompleteSyllabus={setIsCompleteSyllabus}
              setSelectedTab={setSelectedTab}
              currentSelectedTab={currentSelectedTab}
              handler={basicFormHandler}
              testContentFormHandler={testContentFormHandler}
              formModal={formModal}
              addChapterHandler={addChapterHandler}
              setFormModal={setFormModal}
              finalSavedData={finalSavedData}
              setFinalSavedData={setFinalSavedData}
              saveChapterHandler={saveChapterHandler}
              isTestContentExpanded={isTestContentExpanded}
              setIsTestContentExpanded={setIsTestContentExpanded}
              value={value}
              setValue={setValue}
              courses={courses}
              setLoading={setLoading}
              setIsTestDetailsUpdated={setIsTestDetailsUpdated}
            />
          )}
          <FormFooter
            isSecondFormOpen={isSecondFormOpen}
            basicForm={basicFormModal}
            nextButtonHandler={nextButtonHandler}
            saveTestFormHandler={saveTestFormHandler}
            isTestDetailsUpdate={isTestDetailsUpdate}
            isResultDatePassed={isResultDatePassed}
            saveOnEdit={onSaveAgree}
          />
          <SaveFormConfirmationDialog
            open={isSaveConfirmationOpen}
            setOpen={setIsSaveConfirmationOpen}
            onSaveAgree={onSaveAgree}
            text={confirmationTxt}
            onAddChapter={() => addRestChapterHandler(confirmationTxt)}
          />
          <DataLostConfirmation
            open={dataLostConfirmation}
            handleClose={() => setDataLostConfirmation(false)}
            goBackWithDataLost={goBackWithDataLost}
          />

          {isLoading && <ALoader />}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default TestForm;
