import React, { useState } from 'react';
import { useGridApiContext } from '@mui/x-data-grid';
import upload from '../../../../../../../../assets/img/svg/upload.svg'
import eyeIcon from '../../../../../../../../assets/img/svg/eyeIcon.svg'
import Uploader from './chargesUpload';

export const SupportingDocUploader = ({params,viewOnly, isDiscretionary=false, onChange , defaultvalue, userPermissions , viewText}) => {
    const { id, value } = params ?? {id : '', value : defaultvalue};
    const apiRef = useGridApiContext();
    const [openPopOver, setOpenPopOver] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isView,setViewOnly] = useState(false)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenPopOver(true);
    };

    const handleClose = () => {
        setOpenPopOver(false)
        setAnchorEl(null);
        setViewOnly(false)
    }

    const handleSave = (handleUploadedFiles) => {
        if(isDiscretionary){
            onChange('concessionDocuments',handleUploadedFiles)
        }else{
            
            apiRef.current.setEditCellValue({ id, field : 'concessionDocuments', value : handleUploadedFiles });
        }
        handleClose();
    }

    return (
        <div className="d-flex justify-content-center w-100">
            {!viewOnly && <span className='p-2'><img onClick={handleClick} src={upload} width={15} height={15} /></span>}
            {value?.length > 0 
            ? viewText
            ? <span className='p-2 text-center' style={{cursor:'pointer', color:'rgb(0, 176, 245)'}} onClick={(event)=>{handleClick(event);setViewOnly(true)}} width={20} height={20}>View</span>
            :<span className='p-2'><img onClick={(event)=>{handleClick(event);setViewOnly(true)}} src={eyeIcon} width={20} height={20} /></span> 
            : viewOnly
            ? " - " 
            : null }
            <Uploader isOpen={openPopOver} handleClose={handleClose} anchorEl={anchorEl} handleSave={handleSave} documents={value ?? []} isView={isView} userPermissions={userPermissions}/>
        </div>
    )
}

export const DiscretionarySupportingDocUploader = ({onChange , viewOnly, defaultvalue, userPermissions, viewText, buttonComponent}) => {
    const [openPopOver, setOpenPopOver] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isView,setViewOnly] = useState(false)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenPopOver(true);
    };

    const handleClose = () => {
        setOpenPopOver(false)
        setAnchorEl(null);
        setViewOnly(false)
    }

    const handleSave = (handleUploadedFiles) => {
        onChange('concessionDocuments',handleUploadedFiles)
        handleClose();
    }

    return (
        <div className='custom-concession-cell'>
            {!viewOnly && <span className='p-2'><img onClick={handleClick} src={upload} width={15} height={15} /></span>}
            {buttonComponent 
            ? buttonComponent((event)=>{handleClick(event);setViewOnly(true)})
            : defaultvalue?.length > 0
            ? viewText 
            ? <span className='p-2 text-center' style={{cursor:'pointer', color:'rgb(0, 176, 245)'}} onClick={(event)=>{handleClick(event);setViewOnly(true)}} width={20} height={20}>View</span>
            : <span className='p-2'><img onClick={(event)=>{handleClick(event);setViewOnly(true)}} src={eyeIcon} width={20} height={20} /></span>
            : viewOnly
            ? " - "
            : null}
            <Uploader isOpen={openPopOver} handleClose={handleClose} anchorEl={anchorEl} handleSave={handleSave} documents={defaultvalue ?? []} isView={isView} userPermissions={userPermissions}/>
        </div>
    )
}