import {
  Box,
  DialogActions,
  DialogContent,
  Slider,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./utils/cropImage";
import AButton from "../form-fields-mui/AButton";
import ALoader from "views/pages/manage/common/a-loader";

const primaryColor = { color: "#00B0F5" };
const btnStyle = {
  height: "40px",
  borderRadius: "8px",
  minWidth: "200px",
  fontSize: "14px",
};

const CropEasy = ({
  photoURL,
  setOpenCrop,
  setPhotoURL,
  setFile,
  handleSubmit,
  loading,
  disableUpload,
  setDisableUpload
}) => {
  const [setAlert, setLoading] = useState();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cropImage = async () => {
    setLoading(true);
    try {
      const { file, url } = await getCroppedImg(
        photoURL,
        croppedAreaPixels,
        rotation
      );
      setPhotoURL(url);
      setFile(file);
      handleSubmit(file);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      <DialogContent
        dividers
        sx={{
          background: "#333",
          position: "relative",
          height: 400,
          width: "442px",
        }}
      >
        {loading && <ALoader />}
        <Cropper
          image={photoURL}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={1}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          onCropChange={setCrop}
          onCropComplete={cropComplete}
        />
      </DialogContent>
      <DialogActions sx={{ flexDirection: "column" }}>
        <Box className="d-flex" sx={{ width: "100%", mb: 1, gap: "20px" }}>
          <Box sx={{ flex: 1 }}>
            <Typography>Zoom: {zoomPercentDisplay(zoom)}</Typography>
            <Slider
              valueLabelDisplay="auto"
              valueLabelFormat={zoomPercentDisplay}
              min={1}
              max={5}
              step={0.1}
              value={zoom}
              onChange={(e, zoom) => setZoom(zoom)}
              size="small"
              sx={primaryColor}
              disabled = {disableUpload}

            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography>Rotation: {rotation + "°"}</Typography>
            <Slider
              valueLabelDisplay="auto"
              min={0}
              max={360}
              value={rotation}
              size="small"
              step={1}
              onChange={(e, rotation) => setRotation(rotation)}
              sx={primaryColor}
              disabled = {disableUpload}
              />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          <AButton
            variant="outline"
            onClick={() => setOpenCrop(false)}
            size="sx"
            className="btn-left-0"
            updatedStyle={btnStyle}
            disabled = {disableUpload}

          >
            Change photo
          </AButton>
          <AButton
            variant="primary_filled"
            onClick={cropImage}
            updatedStyle={btnStyle}
            size="sx"
            className="btn-left-0"
            disabled = {disableUpload}
          >
            Save Photo
          </AButton>
        </Box>
      </DialogActions>
    </>
  );
};

export default CropEasy;

const zoomPercent = (value) => {
  return `${Math.round(value * 100)}%`;
};

const zoomPercentDisplay = (value) => {
  if (value === 1) {
    return "0%";
  } else if (value > 1 && value <= 1.2) {
    return `${Math.round((value - 1) * 100)}%`;
  }
  return `${Math.round((value / 5) * 100)}%`;
};
