import React, { useState } from "react";
import { Tooltip } from "@mui/material";

import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import ADataGrid from "views/pages/manage/common/data-grid/ADataGrid";
import { AGridColDef } from "views/pages/manage/common/data-grid/ADataGridColDef";

import APopover from "views/pages/manage/common/a-popover";
import {
  gridStyles,
  gridComponents,
} from "views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant.js";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import UnfreezeConfirmationPopup from "./UnfreezeConfirmationPopup";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper";

import styles from "./styles.module.css";
import { GridRowModes } from "@mui/x-data-grid";
import { isObjEmpty } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import { saveOrDeleteMsg } from "../mastersAndMapping/sapProfitCostCentreMapping/constant";
import AInput from "views/pages/manage/common/formFeilds/customInput/AInput";

const TransactionTable = ({
  transactionList,
  unfreezeData,
  editSAPdocNum,
  totalRows,
  currentPageOnTable,
  nextButtonHandler,
  fetchMaterialsInfo,
}) => {
  const [rowForm, setRowForm] = useState({});
  const [rowModesModel, setRowModesModel] = useState({});

  const [openConfirmationPopup, setOpenConfirmationPopup] = useState({
    isOpen: false,
    entry: {},
  });

  const columns = [
    new AGridColDef("transactionId", "Transaction ID", false)
      .setMinWidth(120)
      .editable(false)
      .renderCellComponent((params) => <div>{params?.value || "-"}</div>),

    new AGridColDef("description", "Description", false)
      .setMinWidth(150)
      .editable(false)
      .renderCellComponent((params) => (
        <Tooltip title={params?.value}>
          <div className="ellipsis-text-container">{params?.value || "-"}</div>
        </Tooltip>
      )),

    new AGridColDef("plantId", "Plant ID", false)
      .setMinWidth(30)
      .editable(false)
      .renderCellComponent((params) => <div>{params?.value || "-"}</div>),

    new AGridColDef("transactionDate", "Transaction Date", false)
      .setMinWidth(160)
      .editable(false)
      .renderCellComponent((params) => <div>{params?.value || "-"}</div>),

    new AGridColDef("materialDetailsDTOList", "Material Details", false)
      .setMinWidth(150)
      .columnAlign("center")
      .editable(false)
      .renderCellComponent((params) => (
        <div>
          <APopover
            buttonComponent={(onClick) => (
              <h5
                onClick={(e) => {
                  console.log("params.value.length ", params.value.length);

                  if (params.value.length === 0) fetchMaterialsInfo(params.row);
                  onClick(e);
                }}
                className={`color-primary mb-0 ${styles.cursorPointer}`}
              >
                View Details
              </h5>
            )}
            menuComponent={(closeForm) => (
              <div className={`p-3 ${styles.materialMenuWrapper}`}>
                {params.value.length ? (
                  <table className={styles.materialMenu}>
                    <thead>
                      <tr
                        style={{ fontSize: "13px", fontWeight: "700" }}
                        className="pb-6 text-bold"
                      >
                        <td>Material details</td>
                        <td
                          style={{ fontSize: "13px", fontWeight: "700" }}
                          className="pl-4"
                        >
                          Barcode
                        </td>
                      </tr>
                    </thead>

                    <tbody>
                      {params.value.map((item, index) => (
                        <tr key={index} className={styles.materialMenuDesc}>
                          <td>
                            {item.materialNumber} | {item.materialDesc}
                          </td>
                          <td className="pl-4">{item.barcode}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p className="text-sm my-auto">Loading...</p>
                )}
              </div>
            )}
          />
        </div>
      )),

    new AGridColDef("failedReason", "Failed Reason", false)
      .setMinWidth(90)
      .renderCellComponent((params) => (
        <Tooltip title={params?.value}>
          <div className="ellipsis-text-container text-capitalize">
            {params?.value || "-"}
          </div>
        </Tooltip>
      )),

    new AGridColDef("sapDocId", "SAP Doc No.", false)
      .setMinWidth(100)
      .columnAlign("center")
      .editable(true)
      .renderCellComponent((params) => <div>{params?.value || "-"}</div>)
      .renderEditCellComponent((params) => (
        <div className={styles.editSapInput}>
          <AInput
            value={rowForm.sapDocId}
            handler={(value) => handleChange(value, "sapDocId")}
            onKeyDown={(e) => {
              const { value } = e.target;
              if (e.key === "Backspace" && value.length === 1)
                setRowForm({ ...rowForm, sapDocId: "" });
            }}
            regex={new RegExp("^[0-9]+$")}
            maxLength={10}
            style={{ width: "100%" }}
            placeholder="Enter a 10 digit number"
          />
        </div>
      )),

    new AGridColDef("actions", "Actions", false)
      .setMinWidth(270)
      .renderCellComponent((params) => (
        <div className={`d-flex justify-content-between align-items-center`}>
          {params.api.getRowMode(params?.id) !== "view" ? (
            <>
              <AButton
                variant="primary_filled"
                size="xs"
                disabled={rowForm?.sapDocId && rowForm.sapDocId.length !== 10}
                onClick={() => handleSave(params)}
              >
                Save
              </AButton>

              <IconButtonWrapper
                className="ml-2"
                onClick={() => {
                  setRowModesModel((oldModel) => ({
                    [params?.id]: {
                      mode: GridRowModes.View,
                      ignoreModifications: true,
                    },
                  }));
                  setRowForm({});
                }}
              >
                <CrossIcon />
              </IconButtonWrapper>
            </>
          ) : (
            <>
              <AButton
                variant="primary_filled"
                size="xs"
                onClick={() => handleUnfreezeList(params.row)}
              >
                Unfreeze
              </AButton>

              <AButton
                variant="primary_filled"
                size="xs"
                onClick={() => handleEditSapNum(params)}
              >
                Edit SAP Doc No.
              </AButton>
            </>
          )}
        </div>
      )),
  ];

  const handleSave = (params) => {
    const entry = params.row;
    if (entry.sapDocId !== rowForm.sapDocId) editSAPdocNum(rowForm);

    setRowModesModel((oldModel) => ({
      [params?.id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));
    setRowForm({});
  };

  const handleUnfreezeList = (entry) => {
    setOpenConfirmationPopup({ isOpen: true, entry }); // show confirmation popup
  };

  const handleEditSapNum = (params) => {
    if (!isObjEmpty(rowForm)) {
      failureToast(saveOrDeleteMsg);
      return;
    }
    setRowModesModel((oldModel) => ({
      [params.id]: { mode: GridRowModes.Edit },
    }));

    setRowForm(params.row);
  };

  const handleChange = (value, field) => {
    setRowForm({ ...rowForm, [field]: value });
  };

  return (
    <div>
      <ADataGrid
        removeWrapperContainer
        rowModesModel={rowModesModel}
        rows={transactionList || []}
        columns={columns}
        rowId={(row) => row.transactionId}
        rowHeight={50}
        loading={false}
        rowCount={totalRows}
        nextButtonHandler={nextButtonHandler}
        currentPage={currentPageOnTable}
        components={{
          ...gridComponents,
          // NoRowsOverlay: () => (
          //   <NoRowOverlay message={loader ? "Loading..." : null} />
          // ),
        }}
        sx={{
          ".MuiDataGrid-main": {
            height: `${60 + transactionList?.length * 50}px`,
            minHeight: `${60 + 50}px`,
            maxHeight: `${60 + 50 * 50}px`,
          },
          ...gridStyles,
        }}
      />

      {openConfirmationPopup.isOpen && (
        <UnfreezeConfirmationPopup
          openConfirmationPopup={openConfirmationPopup}
          handleSave={() => {
            unfreezeData(openConfirmationPopup.entry);
            setOpenConfirmationPopup({ isOpen: false, entry: {} });
          }}
          handleCloseDialog={() => {
            setOpenConfirmationPopup({ isOpen: false, entry: {} });
          }}
        />
      )}
    </div>
  );
};

export default TransactionTable;
