import React, { useState } from "react";
import ALoader from "../../../common/a-loader";
import { Checkbox, FormControlLabel } from "@mui/material";
import { primaryCheckboxStyles } from "../../issuanceIDcard/printIdCardAndMapRfid/constant";
import AInput from "../../../common/formFeilds/customInput/AInput";
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";
import { inputStyles, selectStyles } from "../stockReturn/constant";
import SearchByOtherDetailsForm, {
  inputHeight,
} from "./SearchByOtherDetailsForm";
import AButton from "../../../common/form-fields-mui/AButton";
import { useHistory } from "react-router";
import { regex } from "../../../common/constant";
import { failureToast } from "../../../common/utils/methods/toasterFunctions/function";
import { getScreenName, messageNoDataFound } from "./helper";
import { updateRequest } from "../../../common/utils/methods/commonMethods/utilityMethod";
import moment from "moment";
import { searchStockTransactions } from "../service";
import StockTransactionTable from "./StockTransactionTable";

const ReversalTabItem = ({
  academicType,
  selectedTab,
  dropdown,
  form,
  formHandler,
  batchDropdown,
  resetForm = () => {},
}) => {
  const history = useHistory();
  const [checkedOption, setCheckedOption] = useState("txnId"); // txnId, otherDetails
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const checkboxOptions = [
    {
      title: `Search by reversal ID`,
      onChange: (e) => {
        e.target.checked && handleCheckboxChange("txnId");
      },
      checked: checkedOption === "txnId",
    },
    {
      title: "Search by other details",
      onChange: (e) => {
        e.target.checked && handleCheckboxChange("otherDetails");
      },
      checked: checkedOption === "otherDetails",
    },
  ];

  const reset = () => {
    setPage(0);
    setRowCount(0);
    setRows([]);
    resetForm();
  };
  const handleCheckboxChange = (option) => {
    setCheckedOption(option);
    reset();
  };

  const handleSearch = async (page = 0) => {
    const reqBody = {
      businessArea: form?.businessArea,
      term: form?.term,
      transactionId: form?.transactionId,
      courseId: form?.courseId,
      batchId: form?.batchId,
      status: form?.status,
      issuanceDateFrom: form?.issuanceDateFrom,
      issuanceDateTo:
        form?.issuanceDateTo && moment(form?.issuanceDateTo).endOf("day"),
      groupBy: "Transaction",
      type: "PSID_REVERSAL",
    };

    try {
      setLoader(true);
      setPage(page);
      const res = await searchStockTransactions(reqBody, page);
      if (res?.data) {
        setRows(res?.data?.stockTransactionAudList);
        setRowCount(res?.data?.totalRecords);
        if (res?.data?.stockTransactionAudList.length === 0) {
          failureToast(messageNoDataFound);
        } else {
          checkedOption === "txnId" &&
            handleRedirect(res?.data?.stockTransactionAudList?.[0]);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const nextPageHandler = (newPage) => {
    handleSearch(newPage);
  };
  const handleRedirect = ({
    transactionId,
    businessArea,
    businessAreaValue,
  }) => {
    window.open(
      history.createHref({
        pathname: `/admin/edp/stockManagement/stockTxnHistory/${academicType}/${transactionId}/${businessArea}/${businessAreaValue}/${getScreenName(
          selectedTab
        )}`,
      }),
      "_blank"
    );
  };

  return (
    <>
      <div className="mt-2">
        {loader && <ALoader position={"fixed"} />}
        {checkboxOptions?.map((item, i) => (
          <FormControlLabel
            key={JSON.stringify(item)}
            disableTypography
            className="flex-grow-1"
            sx={{ margin: "0px", ...primaryCheckboxStyles }}
            control={
              <Checkbox
                sx={{ padding: "3px", marginLeft: i !== 0 ? "10px" : "" }}
                onChange={item?.onChange}
                checked={item?.checked}
              />
            }
            label={
              <div
                className={`semi-bold ${
                  item?.checked ? "label-color" : "color-grey-60"
                }`}
              >
                {item?.title}
              </div>
            }
          />
        ))}
      </div>
      {checkedOption === "txnId" ? (
        <div className="mt-3 d-flex align-items-end" style={{ gap: "28px" }}>
          <div style={{ flex: 0.2, minWidth: "130px" }}>
            <AAutoComplete
              label="Business area*"
              placeholder="Select"
              isMulti={false}
              items={dropdown?.businessAreas}
              currentValue={form?.businessArea}
              handleChange={(value) => {
                formHandler(value, "businessArea");
              }}
              style={selectStyles}
              height={inputHeight}
            />
          </div>
          <div
            className="position-relative flex-grow-1"
            style={{ flex: 0.5, maxWidth: "250px" }}
          >
            <AInput
              label={`Reversal ID *`}
              placeholder={`Enter`}
              regex={regex.stockReversalId}
              style={{
                ...inputStyles,
                ...{ height: inputHeight, borderRadius: "4px" },
              }}
              handler={(value) =>
                formHandler(value.toUpperCase(), "transactionId")
              }
              value={form?.transactionId || ""}
              crossIcon={true}
              crossHandler={() => formHandler("", "transactionId")}
            />
          </div>
          <AButton
            className="ml-0"
            variant="primary_filled"
            disabled={!(form?.businessArea && form?.transactionId)}
            onClick={() => handleSearch()}
          >
            View history
          </AButton>
        </div>
      ) : (
        <SearchByOtherDetailsForm
          dropdown={dropdown}
          form={form}
          formHandler={formHandler}
          batchDropdown={batchDropdown}
          forReversal
          viewDetailsHandler={() => handleSearch()}
          onResetClearData={reset}
        />
      )}

      {checkedOption === "otherDetails" && rowCount > 0 && (
        <StockTransactionTable
          currentPage={page}
          nextButtonHandler={nextPageHandler}
          rowCount={rowCount}
          rowData={rows}
          forReversal
          academicType={academicType}
          selectedTab={selectedTab}
        />
      )}
    </>
  );
};

export default ReversalTabItem;
