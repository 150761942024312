import React from "react";
import { Checkbox, FormControlLabel, InputLabel } from "@mui/material";
import { Col, Row } from "reactstrap";
import { Dialog } from "@mui/material";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import { ReactComponent as Done } from "assets/img/svg/done-bullet.svg";
import { ReactComponent as QuestionIcon } from "assets/img/svg/QuestionMarkConfirmationDialog.svg";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx";
const checkBoxStyle = {
  borderRadius: "6px",
  // padding: 0
};

export const CMPR_FLAG_VALUES = {
  UNAPPROVED_CMPR: "UNAPPROVED_CMPR",
  CMPR: "CMPR",
  BYPASS_CMPR: "BYPASS_CMPR",
};

export const CMPR_DIALOG_MGS = {
  UNAPPROVED_CMPR: "CMPR Applied",
  CMPR: "CMPR Applied",
  BYPASS_CMPR: "CMPR bypassed",
};

const getTypeOfCmpr = (isUnApprovedConcessionMax) =>
  isUnApprovedConcessionMax
    ? CMPR_FLAG_VALUES.UNAPPROVED_CMPR
    : CMPR_FLAG_VALUES.CMPR;

function ApplyCmprDialog({
  open,
  closeHandler,
  checkboxHandler,
  saveHandler,
  disableCheckBox,
  isUnApprovedConcessionMax,
}) {
  const saveHandlerWrapper = async (cmrValue) => {
    await checkboxHandler(cmrValue);
    saveHandler(null,cmrValue);
    closeHandler();
  };

  return (
    <>
      <Dialog onClose={closeHandler} open={open} maxWidth="lg">
        <div style={{ padding: "28px 24px", minWidth: "31rem" }}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <QuestionIcon color="#414141" />
            <IconButtonWrapper onClick={closeHandler}>
              <CrossIcon width={32} height={32} />
            </IconButtonWrapper>
          </div>
          <div
            style={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "24px",
              color: "#000000",
              marginBottom: "1rem",
            }}
          >
            Do you want to apply CMPR on paid items?{" "}
          </div>
          {/* <FormControlLabel
            sx={{ marginBottom: "0px" }}
            control={
              <Checkbox
                onChange={(e) =>
                  checkboxHandler(
                    e.target.checked
                      ? getTypeOfCmpr(isUnApprovedConcessionMax)
                      : CMPR_FLAG_VALUES.BYPASS_CMPR
                  )
                }
                sx={checkBoxStyle}
                disabled={disableCheckBox}
              />
            }
            label={<span style={{ fontSize: "14px" }}>Apply CMPR</span>}
          /> */}
          <span className="regular-body">
            The new concessions will be applied to the paid items as well
          </span>
          <div className="d-flex">
            <AButton
              className=" mt-4 button_remove_left_margin"
              updatedStyle={{
                width: "100%",
                border: "1px solid #414141",
                color: "#414141",
              }}
              size="sm"
              variant="outline"
              onClick={() => {
                saveHandlerWrapper(CMPR_FLAG_VALUES.BYPASS_CMPR);
              }}
            >
              No, Bypass CMPR
            </AButton>
            <AButton
              className=" mt-4"
              updatedStyle={{ width: "100%" }}
              size="sm"
              variant="primary_filled"
              onClick={() => {
                saveHandlerWrapper(getTypeOfCmpr(isUnApprovedConcessionMax));
                closeHandler();
              }}
              disabled = {disableCheckBox}
            >
              Yes, Apply and proceed
            </AButton>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export const AppliedCmprSuccessDialog = ({
  cmprSuccessDialog,
  setCmprSuccessDialog,
}) => {

  const handleClose = () =>
    setCmprSuccessDialog({ isOpenDialog: false, appliedCmpr: null });

  return (
    <Dialog onClose={handleClose} open={cmprSuccessDialog.isOpenDialog}>
      <div style={{ padding: "28px 24px", minWidth: "21rem" }}>
        <span className="cmpr-success-svg">
          <Done />
        </span>
        <p className="text-bold large-bolder  mt-4">
          {CMPR_DIALOG_MGS[cmprSuccessDialog?.appliedCmpr]}
        </p>
        <AButton
          className=" mt-2 button_remove_left_margin cursor"
          variant="primary_filled"
          size="xs"
          onClick = {handleClose}
        >
          Okay
        </AButton>
      </div>
    </Dialog>
  );
};

export default ApplyCmprDialog;
