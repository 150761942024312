import React, { useContext, useEffect } from "react";
import CustomDatePickerV2 from "views/pages/manage/common/commonComponents/CustomDatePicker-V2";
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import "../style.scss";
import { FILTER__FORM_FIELDS_GLOBAL, IssuanceIDCardContext, PAGE_STATE } from "../contextApi";
import { useGetBatchList } from "../customhooks";
import moment from "moment";
const StudentFilterForm = () => {
  const inputHeight = "42px";
  const marginTop = { marginTop: "33px" };
  const { dropdown, filterForm, setFilterForm, getApplications, setPage, setCurrentPageState, setGlobalFilter } = useContext(
    IssuanceIDCardContext
  );
  const batchDropdown = useGetBatchList(
    dropdown?.businessAreas?.length === 1?dropdown?.businessAreas[0] :filterForm?.businessArea,
    filterForm?.term
  );

  const formHandler = (key, value) => {
    setFilterForm(prev => ({ ...prev, [key]: value }))
  }
  const callBack = () => {
    setCurrentPageState(PAGE_STATE.PRINT_ID_CARD);
    setGlobalFilter(FILTER__FORM_FIELDS_GLOBAL)
  }
  const isBatchDisabled  = ()=>{
     if(dropdown?.businessAreas?.length === 1){
       return  dropdown?.businessAreas?.length === 1 && filterForm?.term.length === 0 
     } else{
      return filterForm?.term?.length === 0  || filterForm?.businessArea === ""
     }    
  }
  return (
    <div className="student-search-filer-idCard my-2">
      <div>
        <AAutoComplete
          label="Business Area"
          height={inputHeight}
          placeholder="Business Area"
          isMulti={false}
          items={dropdown?.businessAreas}
          currentValue={dropdown?.businessAreas?.length === 1 ?dropdown?.businessAreas[0]:filterForm?.businessArea}
          isDisabled = {dropdown?.businessAreas?.length === 1}
          handleChange={(value) => { formHandler("businessArea", value) }}
        />
      </div>
      <div>
        <AAutoComplete
          height={inputHeight}
          label="Academic Group"
          placeholder="Academic Group"
          items={dropdown?.academicGroups}
          currentValue={filterForm?.academicGroup}
          isShowCount={true}
          handleChange={(value) => { formHandler("academicGroup", value) }}
        />
      </div>
      <div>
        <AAutoComplete
          label="Term"
          height={inputHeight}
          placeholder="Term"
          items={dropdown?.terms}
          currentValue={filterForm?.term}
          isShowCount={true}
          handleChange={(value) => { formHandler("term", value) }}
        />
      </div>
      <div>
        <AAutoComplete
          height={inputHeight}
          label="Course ID"
          placeholder="Course ID"
          isShowCount={true}
          items={dropdown?.courseIds}
          currentValue={filterForm?.courseId}
          handleChange={(value) => { formHandler("courseId", value) }}
        />
      </div>
      <div>
        <AAutoComplete
          height={inputHeight}
          label="Batch"
          placeholder="Batch"
          items={batchDropdown}
          currentValue={filterForm?.batchIds}
          isShowCount={true}
          handleChange={(value) => { formHandler("batchIds", value) }}
          isDisabled={isBatchDisabled()}
        />
      </div>

      <div>
        <CustomDatePickerV2
          label="Registration date"
          placeHolder="From"
          value={filterForm?.startDate}
          handler={(i, date) => formHandler("startDate", date,)}
          maxDate={new Date()}
        />
      </div>
      <div style={marginTop}>
        <CustomDatePickerV2
          placeHolder="To"
          value={filterForm?.endDate}
          handler={(i, date) => formHandler("endDate", date)}
          maxDate={new Date()}
          minDate={moment(filterForm?.startDate)?.add(1, 'days')}
        />
      </div>
      <div style={marginTop}>
        <AButton
          className="button_remove_left_margin"
          updatedStyle={{ fontSize: "12px" }}
          onClick={() => {
            //reset name filter
            setGlobalFilter(FILTER__FORM_FIELDS_GLOBAL);
            setPage(0);
            getApplications(0, { ...filterForm, ...FILTER__FORM_FIELDS_GLOBAL }, callBack);
          }}
          variant="primary_filled"
        >
          View students
        </AButton>
      </div>
    </div>
  );
};

export default StudentFilterForm;
