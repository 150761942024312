import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom"
import { Button, Card, CardHeader, Col, Container, Input } from "reactstrap";
import Select from "react-select";
import { pricingServiceBaseUrl, getAPI, putpost } from "services/http";
import {
	successToast,
	failureToast,
} from '../../../../../common/utils/methods/toasterFunctions/function';
import SearchTable from "./searchTable";
// import response from "./response.json";
import CustomLoader from "../../../../../common/commonComponents/Loader/loader";
import { fetchAllData, fetchAllPostPromisedData, isEqual } from "../../../../../common/utils/methods/commonMethods/utilityMethod"
import { GetPagination } from "../../../../../common/commonComponents/pagination/pagination";
import { PermisionDenied } from "../../../../../common/commonComponents";
import CustomButton from 'components/CustomButton';
import CommonInput from "../../../../../common/formFeilds/input/commonInput"
import { getPaginatedData } from "views/pages/manage/common/utils/methods/commonMethods/paginationMethod";


const submistionTypeData = [
	{ label: 'Manual', value: 'manual' },
	{ label: 'Bulk', value: 'bulk' },
];

let page=0;
// let totalPages=0;
let isNextCallPossible=true;

const emptyPagination = {
  currentPage: 1,
  nextPage: null,
  previousPage: null,
  totalPage: null
}
export const fetchStatusData = (url, stateMethod) => {
	getAPI(
		url,
		(data) => {
			let temp = [];
			if (data.data.length > 0) {
				data.data.map((item) => {
					temp.push({
						label: item,
						value: item,
					});
				});
			}
			stateMethod(temp);
		},
		(data) => {
			failureToast(data['message']);
		}
	);
};

const FeeSearchApproval = ({
	userId,
	title,
	statusTobeEdited,
	statusToBeView,
	userPermissions,
	keyName,
	statusTobeEditedForBulk,
	statusTobeEditedForManual
}) => {
	const [loader, setLoader] = useState(false);
	const [statusDropdown, setStatusDropdown] = useState([]);
	const [submissionType, setSubmissionType] = useState({
		label: 'Bulk',
		value: 'bulk',
	});
	const [searchParams, setSearchParam] = useState({
		processId: null,
		status: { label: 'Status', value: '' },
		description: '',
		transitionId: null,
	});
	
	const [tableData, setTableData] = useState([]);
	const [serverData, setServerData] = useState(null);
	const pageSize = 10;
	const [pagination, setpagination] = useState(emptyPagination);
	// useEffect(() => {
	// 	if (state && state.length > 50) {
	// 		setServerData(state.slice(0, 50));
	// 		let n = pagination;
	// 		if (state.length > pageSize) {
	// 			n['nextPage'] = n.currentPage + 1;
	// 		}
	// 		if (n.currentPage > 1) {
	// 			n.previousPage = n.currentPage - 1;
	// 		}
	// 		n['totalPage'] = Math.floor(state.length / pageSize);
	// 		if (state.length % pageSize != 0) {
	// 			n['totalPage'] += 1;
	// 		}
	// 		setpagination(n);
	// 	} else {
	// 		console.log('Data from api---------------->', state);
	// 		setServerData(state);
	// 	}
	// }, [state]);
	// const userId = '10001'
	const statusData = [
		{ label: 'Submitted', value: 'Submitted' },
		{ label: 'L1 Approved', value: 'L1Approved' },
		{ label: 'L2 Approved', value: 'L2Approved' },
		// { label: 'Success', value: 'Success' },
	];

	const submitHandler = async () => {
		// if(!searchParams.processId && !searchParams.description){
		//     failureToast("Please provide process ID or Description or Process Status");
		//     return
		// }

		if (
			isEqual(submissionType?.value, 'bulk') &&
			!searchParams.processId &&
			!searchParams.description &&
			!searchParams.status.value
		) {
			failureToast(
				'Please provide process ID or Description or Process Status'
			);
			return;
		}

		if (
			isEqual(submissionType?.value, 'manual') &&
			!searchParams?.transitionId &&
			!searchParams?.status?.value
		) {
			failureToast('Please provide Transaction ID or Transaction Status');
			return;
		}

		const searchData = {
			courseFeeStatus: '',
			userId: userId,
			status: searchParams?.status?.value,
			description: searchParams?.description?.trim(),
		};

		isEqual(submissionType?.value, 'bulk') &&
			(searchData['processInstanceId'] = searchParams?.processId
				?.toString()
				?.trim());
		isEqual(submissionType?.value, 'manual') &&
			(searchData['transactionId'] = searchParams?.transitionId
				?.toString()
				?.trim());
				
		page = 0;
		let url = `${pricingServiceBaseUrl}/courseFeeProcessingTemp/searchLevelWise/${page}`;
		isEqual(submissionType?.value, 'manual') &&
			(url = `${pricingServiceBaseUrl}/courseFeeManualProcessing/searchLevelWiseApprovalForManual/${page}`);


		// setState([]);
		// putpost(
		// 	url,
		// 	(data) => {
		// 		// successToast(data['message']);
		// 		setState(data.data);
		// 		setLoader(false);
		// 	},
		// 	(data) => {
		// 		setState(null);
		// 		failureToast(data['message']);
		// 		setLoader(false);
		// 		// setLoader(false)
		// 	},
		// 	searchData,
		// 	'post'
		// );

		// console.log('srchData-->',searchData)
		setLoader(true);
		const searchRes = await fetchAllPostPromisedData(url, searchData);
		// console.log('srchResponse-->',searchRes)
		if(!isEqual(submissionType?.value, 'manual')){
			if(searchRes?.code===200){
				// totalPages=searchRes.data.totalPages;
				setServerData(searchRes.data.courseFeesProcessingList);
				getPaginatedData(searchRes.data.courseFeesProcessingList, pageSize, emptyPagination, setpagination, setLoader, setTableData);
			} else {
				setLoader(false);
				setServerData([]);
				setTableData([]);
				getPaginatedData([], pageSize, emptyPagination, setpagination, setLoader, setTableData);
				// failureToast(searchRes['message']);
			}
		}
		else {
			if(searchRes?.code===200){
				// totalPages=searchRes.data.totalPages;
				setServerData(searchRes.data.manualApprovalSearchResponseDTO);
				getPaginatedData(searchRes.data.manualApprovalSearchResponseDTO, pageSize, emptyPagination, setpagination, setLoader, setTableData);
			} else {
				setLoader(false);
				setServerData([]);
				setTableData([]);
				getPaginatedData([], pageSize, emptyPagination, setpagination, setLoader, setTableData);
				// failureToast(searchRes['message']);
			}
		}

	};

	const reset = () => {
		setSearchParam({
			processId: '',
			status: { label: '', value: '' },
			description: '',
			transitionId: '',
		});
		setServerData([]);
		setTableData([]);
		getLatestData();
	};

	const getLatestData = async () => {
		// let searchData = { processInstanceId : null, courseFeeStatus : ''}
		let searchData = {
			courseFeeStatus: '',
			status: '',
			userId: userId,
			description: '',
		};

		isEqual(submissionType?.value, 'bulk') &&
			(searchData['processInstanceId'] = null);
		isEqual(submissionType?.value, 'manual') &&
			(searchData['transactionId'] = null);

		

		// putpost(
		// 	url,
		// 	(data) => {
		// 		// successToast(data['message']);
		// 		console.log('Updated Data from api----------->', data.data);
		// 		setState(data.data);
		// 		setLoader(false);
		// 	},
		// 	(data) => {
		// 		setState(null);
		// 		failureToast(data['message']);
		// 		setLoader(false);
		// 	},
		// 	searchData,
		// 	'post'
		// );
		try {
			page=0;
			let url = `${pricingServiceBaseUrl}/courseFeeProcessingTemp/searchLevelWise/${page}`;
			isEqual(submissionType?.value, 'manual') &&
			(url = `${pricingServiceBaseUrl}/courseFeeManualProcessing/searchLevelWiseApprovalForManual/${page}`);
			setLoader(true);
			const approvalRes = await fetchAllPostPromisedData(url, searchData);
			// console.log('firstResponse', approvalRes)
			if(!isEqual(submissionType?.value, 'manual')){
				if(approvalRes?.code===200){
					// totalPages = approvalRes.data.totalPages;
					setServerData(approvalRes.data.courseFeesProcessingList);
					getPaginatedData(approvalRes.data.courseFeesProcessingList, pageSize, emptyPagination, setpagination, setLoader, setTableData);
				} else {
					setLoader(false);
					// failureToast(approvalRes['message']);
				}
			}
			else {
				if(approvalRes?.code===200){
					// totalPages = approvalRes.data.totalPages;
					setServerData(approvalRes.data.manualApprovalSearchResponseDTO);
					getPaginatedData(approvalRes.data.manualApprovalSearchResponseDTO, pageSize, emptyPagination, setpagination, setLoader, setTableData);
				} else {
					setLoader(false);
					// failureToast(approvalRes['message']);
				}
			}
		} 
		catch (e) {
			console.log('Error', e);
		}
	};

	const fetchNext = async () => {
		// isNextCallPossible=false;
		// return;
		page = page + 1;
		const searchData = {
			courseFeeStatus: '',
			userId: userId,
			status: searchParams?.status?.value,
			description: searchParams?.description?.trim(),
		};

		isEqual(submissionType?.value, 'bulk') &&
			(searchData['processInstanceId'] = null);
		isEqual(submissionType?.value, 'manual') &&
			(searchData['transactionId'] = null);

		let url = `${pricingServiceBaseUrl}/courseFeeProcessingTemp/searchLevelWise/${page}`;
		isEqual(submissionType?.value, 'manual') &&
			(url = `${pricingServiceBaseUrl}/courseFeeManualProcessing/searchLevelWiseApprovalForManual/${page}`);
		
		setLoader(true);
		const approvalRes = await fetchAllPostPromisedData(url, searchData);
		// console.log('nextResponse', approvalRes)
		if(!isEqual(submissionType?.value, 'manual')) {
			if(approvalRes?.code===200 && approvalRes?.data?.courseFeesProcessingList?.length>0){
				const updatedServerData = [...serverData, ...approvalRes.data.courseFeesProcessingList];
				setServerData(updatedServerData);
				getPaginatedData(updatedServerData, pageSize, pagination, setpagination, setLoader, setTableData);
				isNextCallPossible = approvalRes?.data?.courseFeesProcessingList?.length == 50 ? true : false;
			} else {
				isNextCallPossible = false;
				setLoader(false);
			}
		}
		else {
			if(approvalRes?.code===200 && approvalRes?.data?.manualApprovalSearchResponseDTO?.length>0){
				const updatedServerData = [...serverData, ...approvalRes.data.manualApprovalSearchResponseDTO];
				setServerData(updatedServerData);
				getPaginatedData(updatedServerData, pageSize, pagination, setpagination, setLoader, setTableData);
				isNextCallPossible = approvalRes?.data?.manualApprovalSearchResponseDTO?.length == 50 ? true : false;
			} else {
				isNextCallPossible = false;
				setLoader(false);
			}
		}
	}

	// useEffect(() => {
	//     setTimeout(getLatestData, 2000)
	//     // getLatestData()
	// }, [])

	useEffect(() => {
		if (searchParams.status === null) {
			setSearchParam({ ...searchParams, status: { label: '', value: '' } });
		}
	}, [searchParams.status]);

	// const [pagination, setpagination] = useState({
	// 	currentPage: 1,
	// 	nextPage: null,
	// 	previousPage: null,
	// 	totalPage: null,
	// });

	// const pageSize = 50;
	// const nextPage = () => {
	// 	setServerData(
	// 		state.slice(
	// 			(pagination.currentPage + 1) * pageSize - pageSize,
	// 			(pagination.currentPage + 1) * pageSize
	// 		)
	// 	);
	// 	let n = pagination;
	// 	n['currentPage'] = n['currentPage'] + 1;
	// 	n['previousPage'] = n.currentPage;
	// 	setpagination(n);
	// };
	// const firstPage = () => {
	// 	setServerData(state.slice(1 * pageSize - pageSize, 1 * pageSize));
	// 	console.log(pagination);
	// 	let n = pagination;
	// 	n['currentPage'] = 1;
	// 	n['previousPage'] = null;
	// 	setpagination(n);
	// };
	// const lastPage = () => {
	// 	setServerData(
	// 		state.slice(
	// 			pagination.totalPage * pageSize - pageSize,
	// 			pagination.totalPage * pageSize
	// 		)
	// 	);
	// 	console.log(pagination);
	// 	let n = pagination;
	// 	n['currentPage'] = n.totalPage;
	// 	n['previousPage'] = n.totalPage - 1;
	// 	setpagination(n);
	// };
	// const previousPage = () => {
	// 	setServerData(
	// 		state.slice(
	// 			(pagination.currentPage - 1) * pageSize - pageSize,
	// 			(pagination.currentPage - 1) * pageSize
	// 		)
	// 	);
	// 	console.log(
	// 		state.slice(
	// 			(pagination.currentPage - 1) * pageSize - pageSize,
	// 			(pagination.currentPage - 1) * pageSize
	// 		)
	// 	);
	// 	let n = pagination;
	// 	n['currentPage'] = n['currentPage'] - 1;
	// 	if (n['currentPage'] == 1) {
	// 		n['previousPage'] = null;
	// 	} else {
	// 		n['previousPage'] = n.currentPage;
	// 	}
	// 	setpagination(n);
	// };

	useEffect(() => {
		if (submissionType?.value) {
			setSearchParam({
				processId: '',
				status: { label: '', value: '' },
				description: '',
				transitionId: '',
			});
			setLoader(true);
			setServerData([]);
			setTimeout(getLatestData, 2000);
		}
	}, [submissionType]);
	return userPermissions ? (
		<Container fluid className="mt-4">
			<Card>
				<CardHeader className=" d-flex justify-content-between">
					<h3 className="mb-0 ">{title}</h3>
				</CardHeader>

				<div className="p-4">
					<div className="form-row">
						<Col className="mb-3" md="2">
							<label className="form-control-label">{`Submission Type`}</label>
							<CommonInput
								type="select"
								defaultValue={submissionType}
								id="feedetailSearch_status"
								// options={statusDropdown}
								data={submistionTypeData}
								// isDisabled={isDisabled}
								placeHolder="Status"
								// isClearable={true}
								onChange={(sData) => setSubmissionType(sData)}
							/>
						</Col>
						{isEqual(submissionType?.value, 'bulk') ? (
							<>
								<Col className="mb-3" md="2">
									<label className="form-control-label">{`Process ID`}</label>
									<CommonInput
										id="feedetailSearch_processID"
										defaultValue={searchParams.processId}
										placeHolder={`Process ID`}
										type="number"
										maxLength="20"
										style={{ height: '39px' }}
										restrictSpecialCharacter={true}
										onChange={(e) =>
											!isNaN(e)
												? setSearchParam({
													...searchParams,
													processId: e,
												})
												: setSearchParam({ ...searchParams })
										}
									/>
								</Col>
								{/* <Col className="mb-3" md="2">
									<label className="form-control-label">{`Process Status`}</label>
									<CommonInput
										type="select"
										defaultValue={searchParams.status}
										id="feedetailSearch_status"
										// options={statusDropdown}
										data={statusData}
										// isDisabled={isDisabled}
										placeHolder="Status"
										// isClearable={true}
										onChange={(sData) =>
											setSearchParam({ ...searchParams, status: sData })
										}
									/>
								</Col> */}
								<Col className="mb-3" md="6">
									<label className="form-control-label">{`Description`}</label>
									<CommonInput
										defaultValue={searchParams.description}
										placeHolder={`Description`}
										id="feedetailSearch_description"
										type="text"
										maxLength="50"
										style={{ height: '39px' }}
										onChange={(e) =>
											setSearchParam({
												...searchParams,
												description: e,
											})
										}
									/>
								</Col>
							</>
						) : (
							<>
								<Col className="mb-3" md="4">
									<label className="form-control-label">{`Transaction ID`}</label>
									<CommonInput
										id="feedetailSearch_transaction_id"
										defaultValue={searchParams.transitionId}
										placeHolder={`Tranaction ID`}
										type="text"
										maxLength="10"
										style={{ height: '39px' }}
										onChange={(e) =>
											!isNaN(e)
												? setSearchParam({
													...searchParams,
													transitionId: e,
												})
												: setSearchParam({ ...searchParams })
										}
									/>
								</Col>
								<Col className="mb-3" md="4">
									<label className="form-control-label">{`Transaction ID Status`}</label>
									<CommonInput
										type="select"
										defaultValue={searchParams.status}
										id="feedetailSearch_status"
										// options={statusDropdown}
										data={statusData}
										// isDisabled={isDisabled}
										placeHolder="Transaction ID Status"
										// isClearable={true}
										onChange={(sData) =>
											setSearchParam({ ...searchParams, status: sData })
										}
									/>
								</Col>
							</>
						)}
					</div>
					<div className="d-flex float-right mt-1">
						{/* <Button
								color="info"
								size="sm"
								type="button"
								className={''}
								onClick={submitHandler}
								disabled={loader}
							>
								Search
							</Button> */}
						<CustomButton
							disabled={loader}
							content={'Search'}
							permissionType={'S'}
							icon={true}
							permissionSet={userPermissions}
							onClick={submitHandler}
						/>
						{/* <Button
							color="info"
							size="sm"
							type="button"
							// disabled={!searchParams.processId && searchParams.status.value === '' && searchParams.description === '' && !searchParams.transitionId}
							onClick={reset}
						>
							Clear All
						</Button> */}
						<CustomButton
							disabled={
								!searchParams.processId &&
								searchParams.status.value === '' &&
								searchParams.description === '' &&
								!searchParams.transitionId
							}
							content={'Clear All'}
							type={'reset'}
							permissionType={'S'}
							permissionSet={userPermissions}
							onClick={() => {
								reset();
							}}
						/>
					</div>
				</div>
			</Card>
			{loader ? (
				<Card className="mt-4">
					<div
						className="mx-auto text-center py-5 my-5"
						style={{ height: '100vh' }}
					>
						<CustomLoader apiLoader={loader} />
					</div>
				</Card>
			) : serverData && tableData ? (
				<SearchTable
					pageSize={pageSize}
					pagination={pagination}
					userId={userId}
					data={tableData}
					searchType={submissionType?.value}
					statusToBeEdited={statusTobeEdited}
					statusToBeView={statusToBeView}
					userPermissions={userPermissions}
					keyName={keyName}
					statusTobeEditedForBulk={statusTobeEditedForBulk}
					statusTobeEditedForManual={statusTobeEditedForManual}
				/>
			) : null}

			{pagination.totalPage > 1 ? (
				<GetPagination
					isSaveVisible={false}
					setclassArray={setTableData}
					pagination={pagination}
					setpagination={setpagination}
					pageSize={pageSize}
					state={serverData}
					isPaginationFromServer={true}
					fetchNext={fetchNext}
					loader={loader}
					currentPage={tableData}
					isNextCallPossible={isNextCallPossible}
				/>
			) : null}
			{/* <SearchTable data={serverData} /> */}
		</Container>
	) : (
		<PermisionDenied />
	);
};

export default withRouter(FeeSearchApproval);
