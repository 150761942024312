// import React, { createContext, useState } from "react";

// export const FILTER__FORM_FIELDS_GLOBAL = {
//   studentName: '',
//   psid: '',
//   applicationId: '',
//   opportunityId: '',
//   courseId: [],
//   paymentStatus: [],
//   chargesStatus: [],
//   term: [],
//   businessArea: [],
//   region: []
// };

// export const StudentListContext = createContext({
//   currentPageState: 'SEARCH',
//   globalFilter: {},
//   setGlobalFilter : () => {}
// });

// export const StudentListProvider = ({ children }) => {

//   // global search 
//   const [globalFilter, setGlobalFilter] = useState({
//     ...FILTER__FORM_FIELDS_GLOBAL
//   });
//   // this is secondary filter ;

//   return (
//     <StudentListContext.Provider
//       value={{
//         globalFilter,
//         setGlobalFilter
//       }}
//     >
//       {children}
//     </StudentListContext.Provider>
//   );
// };


import React, { createContext, useState } from "react";


export const PAGE_STATE = {
  SEARCH_STUDENT: "SEARCH",
  LIST_STUDENT: "LIST"
};

export const StudentListContext = createContext({
  currentPageState: "SEARCH_STUDENT",
  setCurrentPageState: () => { },
  globalFilter: {},
  setGlobalFilter: () => { },
});


export const FILTER__FORM_FIELDS_GLOBAL = {
  studentName: '',
  psid: '',
  applicationId: '',
  opportunityId: '',
  courseId: [],
  paymentStatus: [],
  chargesStatus: [],
  term: [],
  businessArea: [],
  region: [],
  searchField:'psid'
};
export const StudentListProvider = ({ children, ...props }) => {

  // global search 
  const [globalFilter, setGlobalFilter] = useState({
    ...FILTER__FORM_FIELDS_GLOBAL,
  });

  const [currentPageState, setCurrentPageState] = useState(
    PAGE_STATE.SEARCH_STUDENT
  );

  return (
    <StudentListContext.Provider
      value={{
        currentPageState,
        setCurrentPageState,

        globalFilter,
        setGlobalFilter,
        ...props,
      }}
    >
      {children}
    </StudentListContext.Provider>
  );
};
