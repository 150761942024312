import CustomButton from "components/CustomButton";
import moment from 'moment';
import React, { useState } from "react";
import { Card, CardHeader, Container, Input, Row, Table } from "reactstrap";
import { masterServiceBaseUrl, putpost, putpost2 } from 'services/http';
import { MasterHeader, TDROW } from "../../common/commonComponents/masterRow/masterRow";
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import './index.css';

var _ = require('lodash');
  
const ItemTypeClassificationTable = (props) => {
  const { userPermissions, isSaveVisible, setisSaveVisible=()=>{} } = props;
  const [tableDataKey, settableDataKey] = useState('tableDataKey');
  const addNewClass = () =>{
    props.setclassArray([{"editMode":true,"__status":"__new","itemTypeClassificationKey":null,'status':'ACTIVE','itemTypeModeType':"CHARGES","itemTypeClassificationDesc": null,"itemTypeClassificationDispValue": null, "effectiveDate": null },...props.classArray])
  }
  const saveAll = (cb) =>{
    var _tempArray = props.classArray
    var isValid = true
    _tempArray.forEach((el,index)=>{
      if (el['editMode']) {
        if(el['itemTypeClassificationKey']==null||!el['itemTypeClassificationKey'].trim()){
          isValid= false
          failureToast('Please enter the value of Classification Key')
          return
        }else if(el['itemTypeClassificationDesc']==null||!el['itemTypeClassificationDesc'].trim()){
          isValid= false
          failureToast('Please enter the value of Description')
          return
        }
        else if(el['itemTypeClassificationDispValue']==null||!el['itemTypeClassificationDispValue'].trim()){
            isValid= false
            failureToast('Please enter the value of Display Value')
            return
          }
            if(!isValid){
              return
            }
          if (_tempArray[index]['__status'] == "__new") {
            putpost(masterServiceBaseUrl + '/itemTypeClassification/createItemTypeClassification', (data) => {
              successToast(data['message'])
              setisSaveVisible(false)
              props.fetchClasses(masterServiceBaseUrl + '/itemTypeClassification/getAllItemTypeClassification')
            }, (data) => { failureToast(data['message']) }, _tempArray[index], 'post')
          } else {
            putpost(masterServiceBaseUrl + '/itemTypeClassification/updateItemTypeClassification/' + _tempArray[index].id, (data) => {
              successToast(data['message'])
              setisSaveVisible(false)
              delete _tempArray[index]['editMode']
              props.setclassArray(_tempArray)
              settableDataKey(new Date().getMilliseconds())
              // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
            }, (data) => { failureToast(data['message']) }, _tempArray[index], 'put')
          }
        } else {
  
        }
      })
  }
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes")
    }
    else {
      let cb = () => {
        addNewClass()
        setisSaveVisible(true)
        // settableDataKey(new Date().getMilliseconds())
      }
      var _tempArray = props.classArray
      _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
      props.setclassArray(_tempArray)
      setisSaveVisible(false)
      cb()
    }
  }
  const editRow = (index)=>{
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes")
    }
    else {
      let cb = () => {
        var _tempArray = props.classArray
        _tempArray[index]['editMode'] = true
        props.setclassArray(_tempArray)
        setisSaveVisible(true)
      }
      var _tempArray = props.classArray
      _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
      cb()
    }
  }
  const deleteRow = (index) => {
    if (props.classArray[index]["__status"] == "__new") {
      let n = props.classArray
      n.splice(index, 1)
      props.setclassArray(n)
      setisSaveVisible(false)
      settableDataKey(new Date().getMilliseconds())
    } else {
      if (window.confirm('Are you sure you want to delete this Item Type Classification?')) {
        putpost2(masterServiceBaseUrl + '/itemTypeClassification/deleteItemTypeClassification/' + props.classArray[index].id, (data) => {
          successToast(data['message'])
          if (props.classArray.length == 1) {
            props.previousPage()
            props.fetchClasses(masterServiceBaseUrl + '/itemTypeClassification/getAllItemTypeClassification')
          } else {
            var _tempArray = props.classArray
            if (_tempArray[index]['editMode']) {
              setisSaveVisible(false)
            }
            _tempArray.splice(index, 1)
            props.setclassArray(_tempArray)
          }
          settableDataKey(new Date().getMilliseconds())
        }, (data) => { failureToast(data['message']) }, 'PUT')
      }
    }
  }
  const updateKey = (index,value,key)=>{
    var _tempArray = props.classArray
    _tempArray[index][key] = value
    props.setclassArray(_tempArray)
  }
  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Item Type Classification</h3>
                <CustomButton
                  disabled={isSaveVisible}
                  className={'floatRight mx-1' + (isSaveVisible ? ' btn-dark cursor-gray' : null)}
                  content={'New Classification'}
                  permissionType={'C'}
                  icon={true}
                  onClick={() => { newRow(); }}
                  permissionSet={userPermissions}
                />
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout" >
                    <MasterHeader 
                      type={"Classification"} 
                      itemType = 'classification' 
                    permissionSet={userPermissions}
                    isSaveVisible={isSaveVisible} 
                    />
                  <tbody className="list" key={tableDataKey} >
                    {props.classArray ? props.classArray.map((el, index) => 
                    !el['editMode']?(
                        <TDROW type={"itemTypeClassification"} itemType='classification' index={index} el={el} editRow={editRow} deleteRow={deleteRow} permissionSet={userPermissions} isSaveVisible={isSaveVisible} />
                    ):
                    (<tr key={index+'-class'}>
                      <td className="text-center p-2 mx-1"><Input maxLength={50} defaultValue={el['itemTypeClassificationKey']} onChange={(e)=>{updateKey(index,e.target.value,'itemTypeClassificationKey')}} placeholder="Example:1" className="tdInput" /></td>
                      <td className="text-center p-2 mx-1"><Input disabled={true} defaultValue={el["__status"] == "__new" ? null : moment(el['effectiveDate']).format('DD-MM-YYYY')} placeholder="Example:01-01-2020" className="tdInput" /></td>
                      <td className="text-center p-2 mx-1"><Input maxLength={50} defaultValue={el['itemTypeClassificationDesc']} onChange={(e)=>{updateKey(index,e.target.value,'itemTypeClassificationDesc')}} placeholder="Description" className="tdInput" /></td>
                          <td className="text-center p-2 mx-1">
                            <Input
                              maxLength={50}
                              defaultValue={el['itemTypeClassificationDispValue']}
                              onChange={(e) => {
                                updateKey(index, e.target.value, 'itemTypeClassificationDispValue')
                              }}
                              placeholder="Display Value"
                              className="tdInput" /></td>
                      <td className="text-center p-2 mx-1">
                        <label className="custom-toggle mx-auto ml-2">
                        <input 
                            checked={el.itemTypeModeType == 'CHARGES' ? true : false} 
                            type="checkbox" id={el.id} 
                            onChange={(e) => { settableDataKey(new Date().getMilliseconds()); 
                              let n = e.target.checked === true ? 'CHARGES' : "PAYMENT"; 
                            if (!e.target.checked) { n = "PAYMENT" } updateKey(index, n, 'itemTypeModeType') }} 
                            />
                            <span className="custom-toggle-slider rounded-circle activeToggle a" 
                                data-label-off="Payment" data-label-on="Charge" />
                            </label>
                      </td>
                      <td className="text-center p-2 mx-1">
                        <label className="custom-toggle mx-auto ml-2">
                         <input 
                         checked={el.status == "ACTIVE" ? true : false} 
                          type="checkbox" id={el.id} 
                           onChange={(e) => { settableDataKey(new Date().getMilliseconds()); 

                            let n = "ACTIVE"; if (!e.target.checked) { n = "INACTIVE" } 
                            updateKey(index, n, 'status') }} 
                            />
                          <span style={{width:"72px",margin:'0 auto'}} className="custom-toggle-slider rounded-circle activeToggle" 
                            data-label-off="Inactive" data-label-on="Active" />
                          </label>
                      </td>
                      <td className="text-center">
                        {/* <Button color="info" size="sm" type="button" onClick={() => {
                          deleteRow(index)
                        }}>  <i className="fas fa-trash" /></Button> */}
                            {isSaveVisible ? <>
                              <CustomButton
                                content={'Save'}
                                permissionType={'C,U'}
                                icon={false}
                                forTable={true}
                                onClick={() => { saveAll() }}
                                permissionSet={userPermissions}
                              />
                              <CustomButton
                                content={''}
                                permissionType={'cancel'}
                                icon={true}
                                forTable={true}
                                onClick={() => {
                                  let n = props.classArray
                                  if (n[index]['__status'] == '__new') {
                                    n.splice(index, 1)
                                  } else {
                                    // n[index]['editMode']=false
                                    props.fetchClasses(masterServiceBaseUrl + '/itemTypeClassification/getAllItemTypeClassification')
                                  }
                                  props.setclassArray(n)
                                  setisSaveVisible(false)
                                }}
                                permissionSet={userPermissions}
                              />
                            </> :
                              <CustomButton
                                content={''}
                                permissionType={'D'}
                                icon={true}
                                forTable={true}
                                onClick={() => deleteRow(index)}
                                permissionSet={userPermissions}
                              />}
                      </td>
                    </tr>)
                    ) : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
export default ItemTypeClassificationTable;