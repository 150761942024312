import React, { useContext, useState } from 'react'
import { ReactComponent as ActiveControl } from '../../../../../../assets/img/svg/ActiveControl.svg';
import { ReactComponent as InactiveControl } from '../../../../../../assets/img/svg/InactiveControl.svg';
import StatusChangeDialog from './StatusChangeDialog';
import { checkPermission } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { RolePermissions, PermissionContext } from 'appContext';
import { pages } from 'views/pages/manage/common/constant';

const StatusCellComponent = ({ params, togglePlanStatusHandler = () => { } }) => {

  const userPermissions = RolePermissions(useContext(PermissionContext), pages['daysPlan']['id']);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <span>
        {params.value === "ACTIVE" ?
          <ActiveControl onClick={checkPermission(userPermissions, 'U') ? () => setIsDialogOpen(true) : () => { }} /> :
          <InactiveControl onClick={checkPermission(userPermissions, 'U') ? () => setIsDialogOpen(true) : () => { }} />
        }
        <span
          style={{
            fontSize: "14px", marginLeft: "4px",
            color: params.value === "ACTIVE" ? '#0F0F0F' : '#757575'
          }}
        >
          {params?.value}
        </span>
      </span>
      <StatusChangeDialog
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        rowData={params?.row}
        onAgree={() => togglePlanStatusHandler(params?.row)}
      />
    </>
  )
}

export default StatusCellComponent