import React from 'react';
import { FaBell } from 'react-icons/fa';
const BellIcon = ({ active }) => {
  const bellBackground = { background: !active ? '#F5F5F5' : '#05945B' };
  return (
    <div
      className='rounded-circle d-flex align-items-center justify-content-center'
      style={{ ...bellBackground, width: 45, height: 45 }}
    >
      <FaBell />
    </div>
  );
};
export default BellIcon;
