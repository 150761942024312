import React from 'react';
import { statusDropdown } from '../../paymentGatewaysConfiguration/common';
import { Card, Row, Col } from 'reactstrap';
import AAutoComplete from '../../../../common/form-fields-mui/AAutoComplete';
import AButton from '../../../../common/form-fields-mui/AButton';

var _ = require('lodash');


const FilterForm = ({ businessAreaDropdown, applyFilterHandler, form, handleFilterForm, closePopup, removeFilterHandler, filterCount }) => {

    // const isFormEmpty = _.isEmpty(form);

    return (
        <Card style={{ width: '350px' }} className='mb-0 p-3'>
            <div className='d-flex justify-content-between'>
                <h4>Filter</h4>
            </div>
            <Row>
                <Col md={"12"}>
                    <AAutoComplete
                        label='Business Area'
                        currentValue={form?.businessArea}
                        items={businessAreaDropdown}
                        handleChange={val => handleFilterForm(val, 'businessArea')}
                        isShowCount={true}
                    />
                </Col>
                <Col md={'12'}>
                    <AAutoComplete
                        label='Status'
                        items={statusDropdown}
                        currentValue={form?.status}
                        isMulti={false}
                        handleChange={val => handleFilterForm(val, 'status')}
                    />
                </Col>
            </Row>
            <div className='d-flex justify-content-between mt-2'>
                <AButton
                    variant='danger_filled'
                    onClick={() => { removeFilterHandler(); closePopup(); }}
                    size='xs'
                    className='no-ml-btn'
                    disabled={!form?.appliedFilterCount}
                >
                    Remove
                </AButton>

                <AButton
                    variant='primary_filled'
                    onClick={() => {
                        applyFilterHandler();
                        closePopup();
                    }}
                    size='xs'
                    disabled={filterCount === 0}
                >
                    Apply
                </AButton>
            </div>
        </Card>
    );
}

export default FilterForm;