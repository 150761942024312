import React, { createContext, useContext, useEffect, useState } from "react";
import { useStudentApplicationList } from "../customhooks";
import { useFetchDropdown } from "../../issuanceIDcard/customhooks";
import {
  createIssueStockRequest,
  freeMaterialBarcode,
  getBUDetails,
  getFirstAvailableBarcode,
  isValidBarcodeExist,
  issueStockApi,
  updateBarcodeIssued,
} from "../service";
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import { successToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import {
  markBarcode,
  validateBarcodeFromApi,
} from "../selectMeterialAndStock/constant";
import { removeKeysFromObject } from "../../../common/utils/methods/commonMethods/utilityMethod";

export const PAGE_STATE = {
  SEARCH_STUDENT: "SEARCH_STUDENT",
  SELECT_MATERIAL: "SELECT_MATERIAL",
  ISSUE_STOCK: "ISSUE_STOCK",
  MARK_ELIGIBLE: "MARK_ELIGIBLE",
  ISSUE_STOCK_MISC: "ISSUE_STOCK_MISC",
};

export const IssuanceIDCardContext = createContext({
  currentPageState: "SEARCH_STUDENT",
  setCurrentPageState: () => {},
  loading: false,
  setLoading: () => {},
  studentApplications: [],
  setStudents: () => {},
  filterForm: {},
  setFilterForm: () => {},
});

export const useGetContext = () => {
  return useContext(IssuanceIDCardContext);
};

export const FILTER__FORM_FIELDS = {
  businessArea: "",
  sortingField: "REGISTRATION_DATE",
  term: [],
  batchIds: [],
  academicGroup: "",
  courseId: [],
  typeOfCheckbox: "psid",
  userInput: "",
  startDate: "",
  endDate: "",
};

export const FILTER__FORM_FIELDS_GLOBAL = {
  sortingField: "REGISTRATION_DATE",
  typeOfCheckbox: "psid",
  userInput: "",
  allocationStatus: "ALLOCATE",
};

export const IssuanceIDCardProvider = ({ children, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const open = Boolean(anchorEl);

  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [tShirtGroupMaterials, setTShirtGroupMaterials] = useState([]);
  const [canReIssueMaterialList, setCanReIssueMaterialList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("SINGLE");
  const [BuAndAcdGroup, setBuAndAcdGroup] = useState("");
  const [selectedBusinessArea, setSelectedBusinessArea] = useState({});
  const [applicationMisc, setApplicationMisc] = useState({});
  const [alreadyIssuedTshirtMaterial, setAlreadyIssuedTshirtMaterial] =
    useState([]);
  const [materialAndBarcodeMapping, setMaterialAndBarcodeMapping] = useState(
    {}
  );
  // on click check add student id into map it will show checked
  const [selectedStudentsForIssueStock, setSelectedStudentsForIssueStock] =
    useState({});
  const [page, setPage] = useState(0);
  // global search
  const [globalFilter, setGlobalFilter] = useState({
    ...FILTER__FORM_FIELDS_GLOBAL,
  });
  const resetGlobalFilter = () => setGlobalFilter(FILTER__FORM_FIELDS_GLOBAL);
  const [filterForm, setFilterForm] = useState({
    ...FILTER__FORM_FIELDS,
  });
  const resetFilterForm = () => setFilterForm(FILTER__FORM_FIELDS);

  const [dropdown, dropdownLoader] = useFetchDropdown();
  const [currentPageState, setCurrentPageState] = useState(
    PAGE_STATE.SEARCH_STUDENT
  );

  // this will fetch the list on the basis of applied filter ;
  const [
    studentApplications,
    isApplicationLoading,
    getApplications,
    totalPage,
    totalRecords,
    resetStudentApplications,
    setApplications,
    issuedStockToStudent,
  ] = useStudentApplicationList(dropdown?.businessAreas);

  const materialCheckBoxHandler = (checked, rowParam, material) => {
    const currentApplication = rowParam;
    let tempIds = { ...selectedStudentsForIssueStock };

    const tempStudentApplication = [...studentApplications];
    tempStudentApplication.forEach((application) => {
      if (application["applicationId"] === currentApplication.applicationId) {
        let existing = application["stock"] || [];
        if (checked) {
          if (isValidBarcodeExist(materialAndBarcodeMapping, material.id)) {
            const [barcodeObj, index] = getFirstAvailableBarcode(
              materialAndBarcodeMapping,
              material
            );

            const updatedMaterial = {
              ...material,
              ...getBUDetails(selectedBusinessArea),
              barcode: barcodeObj?.barcode,
            };

            application["stock"] = [...existing, updatedMaterial];
            application["isError"] = false;

            // updateBarcodeIssued
            updateBarcodeIssued(
              materialAndBarcodeMapping,
              barcodeObj,
              index,
              setMaterialAndBarcodeMapping,
              material
            );
            tempIds[currentApplication?.applicationId] = checked;
            setSelectedStudentsForIssueStock(tempIds);
          } else {
            return failureToast("Valid barcode not does not exist!");
          }
        } else {
          application["stock"] = existing.filter(
            (stock) => material.id !== stock.id
          );
          // remove it
          freeMaterialBarcode(
            existing.filter((stock) => material.id === stock.id),
            setMaterialAndBarcodeMapping,
            materialAndBarcodeMapping
          );

          //un checked it
          tempIds[currentApplication?.applicationId] =
            application["stock"].length === 0 ? false : true;
          setSelectedStudentsForIssueStock(tempIds);
        }
      }
    });
    setApplications(tempStudentApplication);

    //
  };
  const issueStockToApplication = async (
    params,
    cb = () => {},
    onlyFetchNewBarcode = () => {},
    isBuOwned
  ) => {
    try {
      console.log(filterForm);
      const root = {
        plantId: selectedBusinessArea?.sapPlantId,
        businessAreaDispValue:
          filterForm?.businessArea?.label || BuAndAcdGroup?.businessArea,
        businessArea:
          filterForm?.businessArea?.value || BuAndAcdGroup?.businessAreaId,
        academicGroupDispValue:
          filterForm?.academicGroup?.label || BuAndAcdGroup?.academicGroup,
        academicCareerDispValue: "RCC",
        businessUnitType: isBuOwned ? "Owned" : "Franchise",
        sapPostingDate: filterForm?.sapPostingDate,
      };
      setLoading(true);
      const request = createIssueStockRequest(studentApplications, root);
      const response = await issueStockApi(request);
      if (response && response?.code === 200) {
        successToast(
          `Stock Issued successfully. Your transaction ID is ${response?.data?.transactionId} and SAP document ID ${response?.data?.documentId}  `
        );
        // refresh list
        cb(response?.data);
      } else {
        failureToast(` ${response?.message} \n 
        Transaction ID : ${response?.data.transactionId} failed. Retry.`);
        onlyFetchNewBarcode();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const editBarcodeForStudent = async (
    studentData,
    material,
    newBarcode,
    setDialogData,
    forceChangeBarcodeConfirm
  ) => {
    // to return a msg if it is found else return empty string

    // below const will check if that barcode is available on UI
    const barcodeItemFromMapping = materialAndBarcodeMapping?.materialIds?.[
      material?.materialId
    ]?.find((item) => item?.barcode == newBarcode);
    // console.log(barcodeItemFromMapping, 'barcode found from mapping')
    if (!barcodeItemFromMapping) {
      // validate from backend here

      const res = await validateBarcodeFromApi(
        filterForm?.businessArea?.value || BuAndAcdGroup?.businessAreaId,
        newBarcode
      );

      if (res?.message) return res.message;
      else if (res?.materialId != material?.materialId)
        return "Barcode not found to be available for issuance";
      else {
        // update the barcode for the student and mark current barcode as false
        setMaterialAndBarcodeMapping((prev) => ({
          ...prev,
          materialIds: markBarcode(
            prev?.materialIds,
            material,
            material?.barcode,
            false
          ),
        }));
        setApplications((prev) =>
          prev?.map((student) => {
            const newStudent = { ...student };
            if (student?.applicationId == studentData?.applicationId) {
              newStudent.stock = student.stock.map((item) =>
                item?.barcode == material?.barcode
                  ? { ...item, barcode: newBarcode }
                  : item
              );
            }
            return newStudent;
          })
        );
      }
    }
    // if that barcode is issued on UI
    else if (barcodeItemFromMapping?.isIssued) {
      const studentWithBarcode = studentApplications?.find(
        (student) =>
          Array.isArray(student?.stock) &&
          student?.stock?.find((item) => item?.barcode == newBarcode)
      );
      // console.log(studentWithBarcode, 'student with barcode');
      if (studentWithBarcode?.applicationId != studentData?.applicationId) {
        setDialogData(studentWithBarcode);
        const canContinue = await forceChangeBarcodeConfirm();

        canContinue &&
          setApplications((prev) =>
            prev?.map((student) => {
              const newStudent = { ...student };
              if (
                student?.applicationId != studentData?.applicationId &&
                Array.isArray(student?.stock)
              ) {
                newStudent.stock = student?.stock?.filter(
                  (item) => item?.barcode != newBarcode
                );
                if (!newStudent?.stock?.length) {
                  // console.log(selectedStudentsForIssueStock, 'mark this student as uncheck from table', newStudent);
                  setSelectedStudentsForIssueStock((prev) =>
                    removeKeysFromObject(prev, [student?.applicationId])
                  );
                }
                // console.log(newStudent, 'changing stock for student with barcode')
              } else if (student?.applicationId == studentData?.applicationId) {
                newStudent.stock = student?.stock?.map((item) =>
                  item?.materialId == material?.materialId
                    ? { ...item, barcode: newBarcode }
                    : item
                );
                // console.log(newStudent, 'changing stock info for current student');
                setMaterialAndBarcodeMapping((prev) => ({
                  ...prev,
                  materialIds: markBarcode(
                    prev?.materialIds,
                    material,
                    material?.barcode,
                    false
                  ),
                }));
              }
              return newStudent;
            })
          );
      }
    } else {
      // unassign current barcode and assign new barcode
      setApplications((prev) =>
        prev?.map((student) => {
          if (student?.applicationId == studentData?.applicationId) {
            // console.log('student found', student, studentData);
            return {
              ...student,
              stock: student?.stock?.map((item) =>
                item?.barcode == material?.barcode
                  ? { ...item, barcode: newBarcode }
                  : item
              ),
            };
          } else return student;
        })
      );
      setMaterialAndBarcodeMapping((prev) => ({
        ...prev,
        materialIds: markBarcode(
          prev?.materialIds,
          material,
          material?.barcode,
          false
        ),
      }));
      setMaterialAndBarcodeMapping((prev) => ({
        ...prev,
        materialIds: markBarcode(prev?.materialIds, material, newBarcode, true),
      }));
    }
  };

  const getBusinessUnitType = ({ businessAreaId }) => {
    const result = dropdown.businessAreas?.filter(
      (item) => item.value === businessAreaId
    );
    if (result.length > 0) return result[0]["businessUnitTypeId"];
    return [];
  };
  return (
    <IssuanceIDCardContext.Provider
      value={{
        tShirtGroupMaterials,
        setTShirtGroupMaterials,

        loading,
        setLoading,
        currentPageState,
        setCurrentPageState,
        filterForm,
        setFilterForm,
        resetFilterForm,
        dropdown,
        dropdownLoader,

        studentApplications,
        setApplications,
        isApplicationLoading,
        page,
        setPage,
        getApplications,
        resetStudentApplications,
        totalPage,
        issuedStockToStudent,

        totalRecords,
        globalFilter,
        setGlobalFilter,
        resetGlobalFilter,

        canReIssueMaterialList,
        setCanReIssueMaterialList,
        selectedMaterials,
        setSelectedMaterials,
        materialCheckBoxHandler,
        issueStockToApplication,
        setBuAndAcdGroup,
        BuAndAcdGroup,
        anchorEl,
        setAnchorEl,
        open,

        selectedBusinessArea,
        setSelectedBusinessArea,
        materialAndBarcodeMapping,
        setMaterialAndBarcodeMapping,

        selectedStudentsForIssueStock,
        setSelectedStudentsForIssueStock,
        selectedFilter,
        setSelectedFilter,
        editBarcodeForStudent,
        setApplicationMisc,
        applicationMisc,
        alreadyIssuedTshirtMaterial,
        setAlreadyIssuedTshirtMaterial,
        getBusinessUnitType,
        ...props,
      }}
    >
      {children}
    </IssuanceIDCardContext.Provider>
  );
};
