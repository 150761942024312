import React, { useState } from "react";
import { putpost, masterServiceBaseUrl, failureToast } from "services/http";
import Header from "./header/header";
import AttendanceMachine from "./attendanceMachine/attendanceMachine";
import { endpoint } from '../../common/constant';

const AttendanceMachineMaster = () => {
  const [attendanceMachineData, setAttendanceMachineData] = useState(null);

  const [headers, setHeaders] = useState({
    businessGroup: null,
    companyCode: null,
    zone: null,
    region: null,
    businessArea: null,
  });

  const searchAttendanceMachine = () => {
    const searchData = {
      businessGroupId: headers.businessGroup?.value,
      companyCodeId: headers.companyCode?.value,
      zoneId: headers.zone?.value,
      regionId: headers.region?.value,
      businessAreaId: headers.businessArea?.value,
    };

    putpost(
      endpoint.attendance_machine.search,
      (data) => {
        setAttendanceMachineData(data.data.attendanceMachineMasterDTO);
      },
      (data) => {
        setAttendanceMachineData(null);
        failureToast(data["message"]);
      },
      searchData,
      "post"
    );
  };

  const resetHeader = () => {
    setHeaders({
      businessGroup: null,
      companyCode: null,
      zone: null,
      region: null,
      businessArea: null,
    })
    setAttendanceMachineData(null)
  }


  return (
    <>
      <Header
        headerValues={headers}
        setHeaderValues={setHeaders}
        searchAttendanceMachine={searchAttendanceMachine}
        setAttendanceMachineData={setAttendanceMachineData}
        resetHeader={resetHeader}
      />
      {attendanceMachineData && (
        <AttendanceMachine
          data={attendanceMachineData}
          searchAttendanceMachine={searchAttendanceMachine}
          headers={headers}
        />
      )}
    </>
  );
};

export default AttendanceMachineMaster;
