import React from "react";
import {
	Card,
	CardHeader,
	Input,
	Container,
	Table,
	Row,
} from "reactstrap";
import { useState } from "react";
import { failureToast, successToast } from "../../common/utils/methods/toasterFunctions/function";
import { TDROW } from "../../common/commonComponents/masterRow/masterRow";
import moment from "moment";
import { ButtonText } from "variables/Buttons";
import CustomButton from "../../../../../components/CustomButton";
import { endpoint } from "../../common/constant";
import { fetchAllPostPromisedData, fetchDeletePromisedData, getCurrentDate } from "../../common/utils/methods/commonMethods/utilityMethod";
import { validate } from '../../common/utils/methods/validations/validation';
import { editTableRow, newTableRow } from "../../common/utils/methods/commonMethods/tableMethods";
import { MasterHeaderAuto } from "../../common/commonComponents/masterHeader/masterHeader";
var _ = require("lodash");

const ClassesTable = (props) => {
	const { permissionSet, isSaveVisible, setisSaveVisible = () => { }, getAllrecords = () => { } } = props;
	const [tableDataKey, settableDataKey] = useState("tableDataKey");
	const [classKey, setClassKey] = useState('');
	const [saving,setSaving] = useState(false)

	const headerList = [
		{ name: 'Class KEY', isRequired: true },
		{ name: 'EFFECTIVE DATE' },
		{ name: 'DESCRIPTION', isRequired: true },
		{ name: 'DISPLAY VALUE', isRequired: true },
		{ name: 'STATUS' },
		{ name: 'ACTION' }
	]

	const addNewClass = () => {
		props.setclassArray([
			{
				editMode: true,
				__status: "__new",
				classKey: null,
				classDesc: null,
				eff_date: null,
				status: 'ACTIVE',
				classDispValue: null,
				effectiveDate: null,
			},
			...props.classArray,
		]);
	};
	const saveAll = async (index) => {
		let _data = props.classArray[index];
		let isValid = true;

		if (_data['classKey'] == null || !_data['classKey']) {
			isValid = false;
			failureToast('Please enter the value of Class Key');
			return;
		} else if (_data['classKey'].length < 2) {
			isValid = false;
			failureToast('Please enter Class Key of two digits');
			return;
		} else if (_data['classDesc'] == null || !_data['classDesc'].trim()) {
			isValid = false;
			failureToast('Please enter the value of Description');
			return;
		} else if (
			_data['classDispValue'] == null ||
			!_data['classDispValue'].trim()
		) {
			isValid = false;
			failureToast('Please enter the value of Display Value');
			return;
		}

		if (!isValid) {
			return;
		}
		setSaving(true)
		if (_data["__status"] == "__new") {
			const createRecord = await fetchAllPostPromisedData(endpoint.classes.create, _data, 'post')
			if (createRecord?.code === 200) {
				successToast(createRecord["message"]);
				setisSaveVisible(false);
				getAllrecords()
				setSaving(false)
			}else{
				setSaving(false)
			}
		} else {
			const createRecord = await fetchAllPostPromisedData(`${endpoint.classes.update}/${_data.id}`, _data, 'put')
			if (createRecord?.code === 200) {
				successToast(createRecord["message"]);
				setisSaveVisible(false);
				getAllrecords()
				setSaving(false)
			}else{
				setSaving(false)
			}
		}
	};
	const newRow = () => {
		if (isSaveVisible) {
			failureToast("Please Delete or Save current changes");
		} else {
			newTableRow(props.classArray, props.setclassArray, setisSaveVisible, addNewClass);
		}
	};
	const editRow = (index) => {
		if (isSaveVisible) {
			failureToast("Please Delete or Save current changes");
		} else {
			editTableRow(index, props.classArray, props.setclassArray, setisSaveVisible);
		}
	};
	const deleteRow = async (index) => {
		if (
			window.confirm("Are you sure you want to delete this Class?")
		) {
			setSaving(true)
			const deleteRecords = await fetchDeletePromisedData(`${endpoint.classes.delete}/${props.classArray[index].id}`);
			if (deleteRecords?.code === 200) {
				let flag = false;
				if (props.classArray.length == 1) {
					flag = true;
				}
				getAllrecords(flag);
				settableDataKey(new Date().getMilliseconds());
				setSaving(false)
			}else{
				setSaving(false)
			}
		}
		// }
	};
	const updateKey = (index, value, key) => {
		var _tempArray = props.classArray;
		_tempArray[index][key] = value;
		props.setclassArray(_tempArray);
	};
	return (
		<>
			<Container className="mt-3" fluid>
				<Row>
					<div className="col">
						<Card>
							<CardHeader className="border-0">
								<h3 className="mb-0 floatLeft">Master - Classes</h3>
								<CustomButton
									data-testId="addNewWebsiteSync"
									disabled={isSaveVisible}
									className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
									content={"New Class"}
									permissionType={'C'}
									icon={true}
									permissionSet={permissionSet}
									onClick={() => newRow()}
								/>
								{/* {isSaveVisible?<Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => {saveAll()}}>Save</Button>:null} */}
							</CardHeader>
							<div id="questionTable" className="table-responsive">
								<Table className="align-items-center table-flush tableLayout">
									{/* <MasterHeader type={"Website Sync"} isSaveVisible={isSaveVisible} permissionSet={permissionSet} /> */}
									<MasterHeaderAuto headerList={headerList} isSaveVisible={isSaveVisible} permissionSet={permissionSet} />
									<tbody className="list" key={tableDataKey}>
										{props.classArray
											? props.classArray.map((el, index) =>
												!el["editMode"] ? (
													<TDROW
														type={"class"}
														index={index}
														el={el}
														editRow={editRow}
														deleteRow={deleteRow}
														isSaveVisible={isSaveVisible}
														permissionSet={permissionSet}
													/>
												) : (
													<tr key={index + "-class"}>
														<td className="text-center p-2 mx-1">
															<Input
																data-testid="classKey"
																disabled={el['__status'] ? false : true}
																maxLength={2}
																value={el['classKey']}
																onChange={(e) => {
																	setClassKey(e.target.value);
																	updateKey(
																		index,
																		e.target.value,
																		'classKey'
																	);
																}}
																placeholder="Key"
																className="tdInput"
																onKeyPress={(e) => {
																	if ( !/^[0-9]+$/.test(
																		String.fromCharCode(e.charCode)
																	  )) {
																		e.preventDefault();
																		return;
																	}
																}}
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																data-testid="effectiveDate"
																disabled={true}
																defaultValue={
																	el['__status'] == '__new'
																		? getCurrentDate()
																		: moment(el['effectiveDate']).format(
																			'DD-MM-YYYY'
																		)
																}
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																data-testid="classDesc"
																maxLength={50}
																defaultValue={el['classDesc']}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'classDesc'
																	);
																}}
																placeholder="Description"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																data-testid="classDispValue"
																maxLength={50}
																defaultValue={el['classDispValue']}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'classDispValue'
																	);
																}}
																disabled = {true}
																placeholder="Display Value"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<label className="custom-toggle mx-auto ml-2">
																<input
																	data-testid="activeInactiveInput"
																	checked={
																		el.status == 'ACTIVE' ? true : false
																	}
																	type="checkbox"
																	id={el.id}
																	onChange={(e) => {
																		settableDataKey(
																			new Date().getMilliseconds()
																		);
																		let n = 'ACTIVE';
																		if (!e.target.checked) {
																			n = 'INACTIVE';
																		}
																		updateKey(index, n, 'status');
																	}}
																/>
																<span
																	data-testid="activeInactiveLabel"
																	style={{ width: '72px' }}
																	className="custom-toggle-slider activeToggle"
																	data-label-off="Inactive"
																	data-label-on="Active"
																/>
															</label>
														</td>
														<td className="text-center p-2 mx-1">
															{/* <Button color="info" size="sm" type="button" onClick={() => {
                          deleteRow(index)
                        }}>  <i className="fas fa-trash" /></Button> */}
															{isSaveVisible ? (
																<>
																	{/* <UpdateButton text={ButtonText.WebsiteSync.save} data-testid="saveWebsiteSync" onClick={() => { saveAll(index) }} /> */}
																	<CustomButton
																		data-testid="saveClass"
																		className={'mx-1'}
																		content={"Save"}
																		permissionType={'C,U'}
																		forTable={true}
																		permissionSet={permissionSet}
																		onClick={() => { saveAll(index) }}
																		disabled={saving}
																	/>
																	<CustomButton
																		data-testId="closeClass"
																		className={'mx-1'}
																		permissionType={'cancel'}
																		forTable={true}
																		icon={true}
																		permissionSet={permissionSet}
																		onClick={() => {
																			let n = props.classArray;
																			if (n[index]["__status"] == "__new") {
																				n.splice(index, 1);
																			} else {
																				// n[index]['editMode']=false
																				props.getAllrecords();
																			}
																			// props.setclassArray(n);
																			setisSaveVisible(false);
																		}}
																	/>
																</>
															) : (
																<></>
															)}
														</td>
													</tr>
												)
											)
											: null}
									</tbody>
								</Table>
							</div>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};
export default ClassesTable;

