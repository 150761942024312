import React, { useEffect} from "react";
import { Button, Input } from "reactstrap";
import Select2 from "react-select2-wrapper";
import {
    masterServiceBaseUrl,
    getAPI,
} from "../../../../../../services/http";
import StatusCellComponent from "../room/StatusCellComponent";

const BuildingForm = (props) => {
  const {
    data,
    buildingData,
    setBuildingData = () => {},
    index,
    saveRow = () => {},
    cancelRow = () => {},
    isSaveVisible,
    pincodeData = [],
    stateData = [],
    cityData = [],
    countryData = [],
    getDisplayVal= () => {}
  } = props;


  // const getPincodeInfo = (id) => {
  //   if(id){
  //       getAPI(`${masterServiceBaseUrl}/pincode/getPincodeByCity/${id}`, 
  //       (data) => { 
  //           setTimeout(3000)
  //           setBuildingData({ ...buildingData, stateId : data.data?.[0]?.stateId, pincode : data.data?.[0]?.id, country : data.data?.[0]?.countryId })
  //       },
  //       (data) => { failureToast(data['message']) })
  //   }    
  // }

  useEffect(() => {
     if(buildingData.pincode){
       const pinValues = getDisplayVal(buildingData.pincode,pincodeData)
       pinValues && setBuildingData({ ...buildingData, stateId : pinValues.stateId, city : pinValues.cityId , country : pinValues.countryId })
     }
  }, [buildingData.pincode])

  
  return (
    <tr key={data["id"] + "-class"}>
      <td className="text-center p-2 mx-1">
        <Input
          defaultValue={buildingData.buildingName}
          onChange={(e) =>
            setBuildingData({ ...buildingData, buildingName: e.target.value.trim() })
          }
          placeholder="Building Name"
          id="building_master_building_name"
          style={{ height: "38px" }}
          maxLength="50"
        />
      </td>
      <td className="text-center p-2 mx-1" colSpan="2">
        <Input
          defaultValue={buildingData.address}
          onChange={(e) =>
            setBuildingData({ ...buildingData, address: e.target.value.trim() })
          }
          placeholder="Building Address"
          id="building_master_building_address"
          style={{ height: "38px" }}
          maxLength="50"
        />
      </td>
      <td className="text-center p-2 mx-1">
        <Input
          value={getDisplayVal(buildingData.city,cityData)?.text ?? ''}
          disabled
          id="building_master_building_city"
          style={{ height: "38px" }}
        />
      </td>
      <td className="text-center p-2 mx-1">
        <Input
          value={getDisplayVal(buildingData.stateId,stateData)?.text ?? ''}
          disabled
          id="building_master_building_state"
          style={{ height: "38px" }}
        />
      </td>
      <td className="text-center p-2 mx-1">
        <Input
          value={getDisplayVal(buildingData.country,countryData)?.text ?? ''}
          disabled
          id="building_master_building_country"
          style={{ height: "38px" }}
        />
      </td>
      <td className="text-center p-2 mx-1">
        <Select2
          defaultValue={buildingData.pincode}
          data={pincodeData}
          onChange={(e) => { 
            setBuildingData({ ...buildingData, pincode: e.target.value, stateId : '', city : '', country : '' });            
          }}
          options={{ placeholder: "Please Select Pincode" }}
          id="building_master_building_pincode"
        />
      </td>
      <td className="text-center p-2 mx-1">
        {/* <label className="custom-toggle mx-auto ml-2">
          <input
            checked={buildingData.status === "ACTIVE"}
            type="checkbox"
            id={"status"}
            onChange={(e) => {
              let n = "ACTIVE";
              if (!e.target.checked) n = "INACTIVE";
              setBuildingData({ ...buildingData, status: n });
            }}
          />
          <span
            style={{ width: "72px" }}
            className="custom-toggle-slider rounded-circle activeToggle"
            data-label-off="Inactive"
            data-label-on="Active"
          />
        </label> */}
        <StatusCellComponent el={buildingData} index={index} updateKey={(index, value, key) => setBuildingData({ ...buildingData, status: value })} settableDataKey={() => { }} tableType="building" />
      </td>
      <td className="text-center p-2 mx-1">
        <Button
          size="sm"
          type="button"
          className={" mx-1" + (isSaveVisible ? " btn-dark" : null)}
          disabled={isSaveVisible}
        >
          Add Floor
        </Button>
      </td>
      <td className="text-center p-2 mx-1">
        <Button
          color="info"
          size="sm"
          type="button"
          className="mx-1"
          onClick={() => saveRow(index)}
        >
          Save
        </Button>
        <Button
          color="danger"
          size="sm"
          type="button"
          onClick={() => {
            cancelRow(index);
          }}
        >
          <i className="fas fa-times" />
        </Button>
      </td>
    </tr>
  );
};

export default BuildingForm;
