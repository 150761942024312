import React, { useState } from 'react';
import { Dialog, Tooltip } from '@mui/material';
import AAutoComplete from 'views/pages/manage/common/form-fields-mui/AAutoComplete';
import ALoader from 'views/pages/manage/common/a-loader';
import { ReactComponent as CrossIcon } from 'assets/img/svg/CrossIcon.svg';
import ADataGrid from 'views/pages/manage/common/data-grid/ADataGrid';
import AButton from '../../../common/form-fields-mui/AButton';
import SapPeriodAndSelectSapPostingDate from '../sapPeriodComponent/SapPeriodAndSelectSapPostingDate'
import {
  selectStyle,
  getBarcodeDropdown,
  returnModeDropdown,
  dlpReturnReasonDropdown,
  generateQuantityDropdown,
  getQuantityDropdownItem,
  transactionTypeMap,
} from './constant';
import ATextarea from '../../../common/formFeilds/customInput/ATextarea';
import moment from 'moment';
import { AGridColDef } from '../../../common/data-grid/ADataGridColDef';
import {
  gridStyles,
  gridComponents,
} from '../../issuanceIDcard/printIdCardAndMapRfid/constant';
import NoRowOverlay from '../../../masters/holidayMaster/NoRowOverlay';
import { useHistory } from 'react-router';
import {
  fetchAllPostPromisedData,
  getUrlSearchParams,
} from '../../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../../common/constant';
import {
  failureToast,
  successToast,
} from '../../../common/utils/methods/toasterFunctions/function';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'

export const gridSelectStyles = { control: { borderRadius: '4px' } };

const MaterialGrid = ({ rows, form, formHandler }) => {
  const barcodedMaterials = rows?.filter(item => item?.serialNumberProfile?.toLowerCase() !== 'na');
  const nonBarcodedMaterials = rows?.filter(item => item?.serialNumberProfile?.toLowerCase() === 'na');

  const handleNonBarcodedSelection = (value, params) => {
    const stock = {
      ...form?.stock,
      [params.row.materialNumber]: params?.row?.barcodeDropdown?.slice(0, value),
    };
    formHandler(stock, 'stock');
  };

  const columns = (forBarcoded = true) => [
    new AGridColDef(
      'materialDesc',
      `${forBarcoded ? 'Barcoded' : 'Non-barcoded'} material details`,
      false
    )
      .setFlex(1.5)
      .renderCellComponent(params => (
        <Tooltip title={`${params?.value} | ${params?.row?.materialNumber}`}>
          <div className="ellipsis-text-container">
            {`${params?.value} | ${params?.row?.materialNumber}`}
          </div>
        </Tooltip>
      )),
    new AGridColDef('dummy', 'Issued qty', false)
      .columnAlign('center')
      .setFlex(0.7)
      .setMinWidth(90)
      .setValueGetter(params => `${params?.row?.barcode?.length}`),
    new AGridColDef(
      'barcode',
      `Select return ${forBarcoded ? 'barcode' : 'qty'}`,
      false
    )
      .columnAlign('right')
      .setMinWidth(220)
      .renderCellComponent(params => (
        <div className="w-100">
          {forBarcoded ? (
            <AAutoComplete
              placeholder={params?.row?.barcodeDropdown?.length ? "Select barcode" : 'Stock returned'}
              items={params?.row?.barcodeDropdown}
              currentValue={form?.stock?.[params?.row?.materialNumber] || ''}
              handleChange={value => {
                const stock = {
                  ...form?.stock,
                  [params.row.materialNumber]: value,
                };
                formHandler(stock, 'stock');
              }}
              style={gridSelectStyles}
              isMulti
              isShowCount
              isDisabled={!params?.row?.barcodeDropdown?.length}
            />
          ) : (
            <AAutoComplete
              placeholder={params?.row?.barcodeDropdown?.length ? "Select quantity" : 'Stock returned'}
              items={generateQuantityDropdown(
                params?.row?.barcodeDropdown?.length
              )}
              currentValue={getQuantityDropdownItem(form?.stock?.[params?.row?.materialNumber]?.length ?? '')}
              handleChange={value => {
                handleNonBarcodedSelection(value?.value, params);
              }}
              style={gridSelectStyles}
              isMulti={false}
              isDisabled={!params?.row?.barcodeDropdown?.length}
            />
          )}
        </div>
      )),
  ];

  return (
    <div
      className="d-flex flex-column gap-md overflow-auto"
      style={{ maxHeight: '235px' }}
    >
      {barcodedMaterials?.length > 0 && (
        <ADataGrid
          removeWrapperContainer
          rows={barcodedMaterials}
          columns={columns()}
          rowId={row => JSON.stringify(row)}
          rowHeight={35}
          loading={false}
          serverPagination={false}
          components={{
            ...gridComponents,
            NoRowsOverlay: () => <NoRowOverlay />,
          }}
          hidePagination={rows?.length <= 50 ? true : false}
          sx={gridStyles}
          autoHeight
        />
      )}
      {nonBarcodedMaterials?.length > 0 && (
        <ADataGrid
          removeWrapperContainer
          rows={nonBarcodedMaterials}
          columns={columns(false)}
          rowId={row => JSON.stringify(row)}
          rowHeight={35}
          loading={false}
          serverPagination={false}
          components={{
            ...gridComponents,
            NoRowsOverlay: () => <NoRowOverlay />,
          }}
          hidePagination={rows?.length <= 50 ? true : false}
          sx={gridStyles}
          autoHeight
        />
      )}
    </div>
  );
};

const StockReturnDialog = ({
  open,
  setOpen,
  data,
  dropdown,
  handleSuccess,
}) => {
  const handleClose = () => {
    setOpen(false);
    setForm({});
  };
  const history = useHistory();
  const { academicCareer, businessArea, businessAreaDispValue, plantId } =
    getUrlSearchParams(history?.location?.search);

  const materialRows = Object.values(data?.materialDetails || {})?.map(
    item => ({
      ...item,
      barcodeDropdown: getBarcodeDropdown(item?.barcode, item),
    })
  );

  const [apiLoader, setApiLoader] = useState(false);
  const [form, setForm] = useState({});
  const formHandler = (value, key) =>
    setForm(prev => ({ ...prev, [key]: value }));

  const submitHandler = async () => {
    setApiLoader(true);

    const reqBody = {
      type: transactionTypeMap['PSID_RETURN'][academicCareer],
      academicCareerDispValue: academicCareer,
      plantId: plantId,
      businessArea: businessArea,
      businessAreaDispValue: businessAreaDispValue,
      costCenter: data?.materialDetailsRes?.[0]?.costCenter,
      reason: form?.remark?.value,
      sapPostingDate : form?.sapPostingDate,
      psidStockRequest: [
        {
          psid: data?.psid,
          applicationId: data?.applicationId,
          dispatchScheduleId: data?.dispatchScheduleId,
          remark: form?.customRemark?.trim() || '',
          stock: Object?.values(form?.stock || {})?.reduce((acc, item) => {
            acc = [...acc, ...item];
            return acc;
          }, []),
        },
      ],
    };
    // console.log(reqBody);
    // setApiLoader(false);
    // return;
    if (!reqBody?.psidStockRequest?.[0]?.stock?.length) {
      failureToast('Please fill all the details to proceed');
      setApiLoader(false);
      return;
    }
    const res = await fetchAllPostPromisedData(
      endpoint?.studentSearch?.psidStockReturn,
      reqBody,
      'post'
    );
    if (res?.code === 200) {
      successToast('Request completed successfully');
      handleSuccess(res?.data);
      handleClose();
    } else {
      failureToast(
        `${res?.message} Return ID: ${res?.data?.transactionId} failed. Retry`
      );
      setForm({});
    }
    setApiLoader(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{ sx: { maxWidth: 'unset', borderRadius: '12px' } }}
    >
      <div style={{ padding: '28px 24px', width: '680px' }}>
        {apiLoader && <ALoader />}
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="heading-4 color-black">Stock Return</div>
            <IconButtonWrapper onClick={handleClose}><CrossIcon width={24} height={24} /></IconButtonWrapper>
          </div>
          <div className='w-50'>
            <SapPeriodAndSelectSapPostingDate
              sapPostingDate = {form?.sapPostingDate}
              setSapPostingDate = {(date)=>formHandler(date, 'sapPostingDate')}
            />
          </div>
          <div className="mt-4 color-black large-semi-bold">
            Returning Stocks for {data?.studentName || '-'} |{' '}
            {data?.psid || '-'} | {data?.applicationId || '-'}
          </div>
          <div className="mt-1">
            Issue date {moment(data?.issueDate)?.format('DD-MMM-YYYY')} |
            Transaction ID {data?.transactionId}
          </div>
        </div>

        <div className="mt-4">
          <MaterialGrid
            rows={materialRows}
            form={form}
            formHandler={formHandler}
          />
        </div>

        {academicCareer !== 'RCC' && (
          <div className="mt-4">
            <AAutoComplete
              placeholder="Select return mode"
              isMulti={false}
              items={returnModeDropdown}
              currentValue={form?.transactionType || ''}
              handleChange={value => {
                formHandler(value, 'transactionType');
              }}
              style={selectStyle}
            />
          </div>
        )}

        <div className="mt-4">
          <AAutoComplete
            placeholder="Search a reason"
            isMulti={false}
            items={
              academicCareer === 'RCC'
                ? dropdown?.returnReasons
                : dlpReturnReasonDropdown
            }
            currentValue={form?.remark || ''}
            handleChange={value => {
              formHandler(value, 'remark');
              formHandler('', 'customRemark');
            }}
            style={selectStyle}
          />
        </div>

        <div className="mt-4">
          <ATextarea
            label={`Add Remark`}
            rows={3}
            style={{ fontSize: '16px', height: '80px' }}
            disabled={
              academicCareer === 'RCC' && (!form?.remark || form?.remark?.value !== 'Others')
            }
            value={form?.customRemark}
            onChange={value => formHandler(value, 'customRemark')}
          />
        </div>

        <div className="mt-4">
          <AButton
            className="btn-left-0"
            size="sm"
            variant="primary_filled"
            onClick={() => submitHandler()}
            disabled={
              !form?.remark ||
              (form?.remark?.value === 'Others' && !form?.customRemark?.trim()) ||
              (academicCareer !== 'RCC' && !form?.transactionType)
            }
          >
            Confirm return
          </AButton>
        </div>
      </div>
    </Dialog>
  );
};
export default StockReturnDialog;
