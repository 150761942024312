import React from 'react';
import { withRouter } from 'react-router-dom';
import {
	Card,
	CardHeader,
	Input,
	Container,
	Button,
	Table,
	Row,
} from 'reactstrap';
import { useState } from 'react';
import { failureToast } from '../../../../../common/utils/methods/toasterFunctions/function';
import {
	ACTIONREASONSESSIONTABLE,
	ProgramReasonSessionHeader,
} from '../../../../../common/commonComponents/masterRow/masterRow';
import {
	masterServiceBaseUrl,
	successToast,
	putpost,
	putpost2,
	deleteAPI,
} from 'services/http';
import Select from 'react-select';
import moment from 'moment';

var _ = require('lodash');

const ActionReasonSessionTable = (props) => {
	const [isSaveVisible, setisSaveVisible] = useState(false);
	const [dispValue, setDispValue] = useState('');
	const [tableDataKey, settableDataKey] = useState('tableDataKey');
	const [statusActive, setStatusActive] = useState(false);
	const [programReasonValue, setProgramReasonValue] = useState({
		attributeId: '',
		attributedesc: '',
	});

	const addNewClass = () => {
		props.setclassArray([
			{
				editMode: true,
				__status: '__new',
				actionReasonId: null,
				descriptionValue: null,
				status: 'ACTIVE',
			},
			...props.classArray,
		]);
	};


	const saveAll = (cb) => {
		var _tempArray = props.classArray;
		var isValid = true;
		_tempArray.forEach((el, index) => {
			if (el['editMode']) {
				if (el['actionReasonId'] === null) {
					isValid = false;
					failureToast('Please Select Action Reason Value');
					return;
				} else if (el['status'] === null) {
					isValid = false;
					failureToast('Please select Status');
					return;
				}
				if (!isValid) {
					return;
				}
				if (_tempArray[index]['__status'] == '__new') {
					_tempArray[index]['programActionMappingId'] = props.formId;
					putpost(
						masterServiceBaseUrl +
							`/programActionReasonMapping/createProgramActionReasonMapping`,
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							props.fetchClasses();
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'post'
					);
				} else {
					putpost(
						masterServiceBaseUrl +
							'/programActionReasonMapping/updateProgramActionReasonMapping/' +
							_tempArray[index].id,
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							delete _tempArray[index]['editMode'];
							props.setclassArray(_tempArray);
							settableDataKey(new Date().getMilliseconds());
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'put'
					);
				}
			} else {
			}
		});
	};
	const newRow = () => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				addNewClass();
				setisSaveVisible(true);
				// settableDataKey(new Date().getMilliseconds())
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			props.setclassArray(_tempArray);
			setisSaveVisible(false);
			cb();
		}
	};
	const editRow = (index) => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				var _tempArray = props.classArray;
				_tempArray[index]['editMode'] = true;
				props.setclassArray(_tempArray);
				setisSaveVisible(true);
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			cb();
		}
	};
	const deleteRow = (index) => {
		if (props.classArray[index]['__status'] == '__new') {
			setisSaveVisible(false);
			settableDataKey(new Date().getMilliseconds());
		} else {
			if (
				window.confirm('Are you sure you want to delete this Action Reason?')
			) {
				putpost2(
					masterServiceBaseUrl +
						'/programActionReasonMapping/deleteProgramActionReasonMapping/' +
						props.classArray[index].id,
					(data) => {
						successToast(data['message']);
						if (props.classArray.length == 1) {
							props.fetchClasses();
						} else {
							var _tempArray = props.classArray;
							if (_tempArray[index]['editMode']) {
								setisSaveVisible(false);
							}
							_tempArray.splice(index, 1);
							props.setclassArray(_tempArray);
						}
						settableDataKey(new Date().getMilliseconds());
					},
					(data) => {
						failureToast(data['message']);
					},
					'Delete'
				);
			}
		}
	};
	const updateKey = (index, value, key) => {
		var _tempArray = props.classArray;
		_tempArray[index][key] = value;
		props.setclassArray(_tempArray);
	};

	const getActionReasonValue = (id) => {
		return props.actionReasonData.filter((item) => {
			return item.value === id;
		})[0];
	};

	return (
		<>
			<Card>
				<CardHeader className="border-0">
					<h3 className="mb-0 floatLeft">Action Reason Value Mapping</h3>
					<Button
						disabled={isSaveVisible}
						color="info"
						size="sm"
						type="button"
						className={'floatRight mx-1' + (isSaveVisible ? ' btn-dark' : null)}
						onClick={() => {
							newRow();
						}}
					>
						Add New Action Reason
					</Button>
				</CardHeader>
				<div id="questionTable">
					<Table className="align-items-center table-flush tableLayout">
						<ProgramReasonSessionHeader type={'CourseAttrSession'} />
						<tbody className="list" key={tableDataKey}>
							{props.classArray
								? props.classArray.map((el, index) =>
										!el['editMode'] ? (
											<ACTIONREASONSESSIONTABLE
												type={'testMode'}
												index={index}
												el={el}
												editRow={editRow}
												deleteRow={deleteRow}
												actionReasonData={props.actionReasonData}
											/>
										) : (
											<tr key={index + '-class'}>
												<td className="text-center p-2 mx-1">
													<Select
														options={props.actionReasonData}
														value={getActionReasonValue(el['actionReasonId'])}
														onChange={(data) => {
															setProgramReasonValue({
																attributeId: data.value,
																attributedesc: data.desc,
															});
															updateKey(index, data.value, 'actionReasonId');
														}}
														placeholder="Attribute Value"
														required
													/>
												</td>
												<td className="text-center p-2 mx-1">
													<Input
														type="text"
														value={programReasonValue.attributedesc}
														className="tdInput"
														// value={dispValue}
														placeholder="Description"
														disabled={true}
														onChange={(sData) => setDispValue(sData)}
													/>
												</td>
												<td className="text-center p-2 mx-1">
													<label className="custom-toggle mx-auto ml-2">
														<input
															checked={el.status == 'ACTIVE' ? true : false}
															type="checkbox"
															id={el.id}
															onChange={(e) => {
																settableDataKey(new Date().getMilliseconds());
																setStatusActive(e.target.checked);
																let n = 'ACTIVE';
																if (!e.target.checked) {
																	n = 'INACTIVE';
																}
																updateKey(index, n, 'status');
															}}
														/>
														<span
															style={{ width: '72px' }}
															className="custom-toggle-slider rounded-circle activeToggle"
															data-label-off="Inactive"
															data-label-on="Active"
														/>
													</label>
												</td>
												<td className="text-center">
													{isSaveVisible ? (
														<>
															{' '}
															<Button
																color="info"
																size="sm"
																type="button"
																className="mx-1"
																onClick={() => {
																	saveAll();
																}}
															>
																Save
															</Button>
															<Button
																color="danger"
																size="sm"
																type="button"
																onClick={() => {
																	let n = props.classArray;
																	if (n[index]['__status'] == '__new') {
																		n.splice(index, 1);
																	} else {
																		// n[index]['editMode']=false
																		props.fetchClasses();
																	}
																	props.setclassArray(n);
																	setisSaveVisible(false);
																}}
															>
																{' '}
																<i className="fas fa-times" />
															</Button>{' '}
														</>
													) : (
														<Button
															color="info"
															size="sm"
															type="button"
															onClick={() => {
																deleteRow(index);
															}}
														>
															{' '}
															<i className="fas fa-trash" />
														</Button>
													)}
												</td>
											</tr>
										)
								  )
								: null}
						</tbody>
					</Table>
					<Row className="mt-2 pr-3 pt-2 pb-3 mr-2 pl-5">
						{props.classArray.length < 1 ? (
							<span> Please Add Action Reason From Above </span>
						) : null}
						{props.isNew == true ? (
							<Button
								disabled={!props.classArray.length}
								color="info"
								size="sm"
								type="button"
								className={'floatRight ml-auto'}
								onClick={() => props.history.push('/admin/programActions')}
							>
								Close
							</Button>
						) : null}
					</Row>
				</div>
			</Card>
		</>
	);
};
export default withRouter(ActionReasonSessionTable);
