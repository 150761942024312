import { Tooltip } from '@mui/material'
import React from 'react'
import AInput from 'views/pages/manage/common/formFeilds/customInput/AInput'
import styles from '../styles.module.scss'

const HeaderNameComponent = ({ isEdit, planData, formData, formHandler = () => { } }) => {

  const editInputCustomStyles = { border: 'none', padding: 0, color: '##0F0F0F', fontWeight: 600, fontSize: '20px', lineHeight: '28px' };

  return (
    // isEdit ? (
    //   <div className={`heading-4 color-dark align-self-stretch ${styles?.custom_input}`}>
    //     <AInput
    //       style={editInputCustomStyles}
    //       placeholder='Name'
    //       value={formData?.daysPlanName}
    //       handler={(value) => formHandler(value, 'daysPlanName')}
    //     />
    //   </div>
    // ) : (
      <Tooltip title={planData?.daysPlanName}>
        <div className='heading-4 ellipsis-text-container' style={{ maxWidth: '100%' }}>
          {planData?.daysPlanName}
        </div>
      </Tooltip >
  //   )
  )
}

export default HeaderNameComponent