import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment'
import React, { useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import AButton from '../../../common/form-fields-mui/AButton';
import { failureToast } from '../../../common/utils/methods/toasterFunctions/function';

const timepickerStyle = {
  '& .MuiInputBase-input': {
    height: '20px',
    padding: '10px',
  },
  '& .MuiInputAdornment-root': {
    display: 'none',
  }
}

const TimeSelectionForm = ({ index, testUpdateHandler, testData, minTime, closeForm }) => {

  const [startTime, setStartTime] = useState(testData?.testStartTime);
  const [endTime, setEndTime] = useState(testData?.testEndTime);

  const handleSave = () => {
    if(moment(startTime)?.isSameOrAfter(moment(endTime))){
      failureToast('Start time should be less than end time')
    } else {
      testUpdateHandler(index, startTime, "testStartTime");
      testUpdateHandler(index, endTime, "testEndTime");
      closeForm();
    }
  }
 
  return (
    <Card style={{ marginBottom: 0 }}>
      <CardBody>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <div className='d-flex flex-column'>
            <div>
              <div className='mb-1' style={{ fontSize: '14px', color: '#757575', fontWeight: 400, lineHeight: '16px' }}>Select start time</div>
              <div>
                <DesktopTimePicker
                  ampm={false}
                  value={moment(startTime)}
                  // onChange={(value) => testUpdateHandler(index, value, "testStartTime")}
                  onChange={(value) => setStartTime(value)}
                  sx={timepickerStyle}
                />
              </div>
            </div>
            <div className='mt-3'>
              <div className='mb-1' style={{ fontSize: '14px', color: '#757575', fontWeight: 400, lineHeight: '16px' }}>Select end time</div>
              <div>
                <DesktopTimePicker
                  ampm={false}
                  value={moment(endTime)}
                  // onChange={(value) => testUpdateHandler(index, value, "testEndTime")}
                  onChange={(value) => setEndTime(value)}
                  sx={timepickerStyle}
                  minTime ={moment(testData?.testStartTime)}
                />
              </div>
            </div>
            <div className='mt-3'>
              <AButton
                className='btn-left-0'
                variant='primary_filled'
                updatedStyle={{ height: '40px', width: '100%' }}
                onClick={handleSave}
              >
                Save
              </AButton>
            </div>
          </div>
        </LocalizationProvider>
      </CardBody>
    </Card>
  )
}

export default TimeSelectionForm