import React, { useContext, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import PrintableCard from "./PrintableCard";
import { getPrintableData } from "../service";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import { IssuanceIDCardContext } from "../contextApi";
import useRollNoPrompt from "./useRollNoPrompt";

class ComponentToPrint extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    return <PrintableCard students={this.props?.contents} />;
  }
}

const PrintIdCardBtn = ({
  data = [],
  customTiggerButton = false,
  customTriggerButtonComponent = () => {},
  rowData,
  hideStepperBarPostPrint = false,
}) => {
  const componentRef = useRef();
  const [contents, setContents] = useState([]);
  const [printData, setPrintData] = useState([]);
  const [ConfirmationPrompt, confirmPromise] = useRollNoPrompt(printData);

  const { postPrintHandler, setHideStepperBarRfidPage, setLoading } =
    useContext(IssuanceIDCardContext);

  const loadPrintableContent = async (resolve, reject) => {
    setLoading(true);

    const response = await getPrintableData(data);
    if (response?.code === 200) {
      const rollNoNotExist = response.data?.filter((item) => !item.rollNo);
      setPrintData(rollNoNotExist);
      setContents(response.data);
      if (rollNoNotExist.length > 0) {
        const canContinue = await confirmPromise();
        if (!canContinue) {
          setLoading(false);
          reject();
        }
      }

      // setLoading(false)
      return "";
    }
    setLoading(false);
  };
  return (
    <div>
      <ReactToPrint
        fonts={{ fontWeight: 700 }}
        trigger={() =>
          customTiggerButton ? (
            customTriggerButtonComponent()
          ) : (
            <AButton size="xs" variant="primary_filled">
              Preview and Print
            </AButton>
          )
        }
        content={() => componentRef.current}
        onBeforeGetContent={() => loadPrintableContent()}
        onAfterPrint={
          rowData
            ? () => {
                hideStepperBarPostPrint && setHideStepperBarRfidPage(true);
                postPrintHandler(rowData);
                setLoading(false);
              }
            : () => {setLoading(false)}
        }
      />
      <ConfirmationPrompt />
      <div style={{ display: "none" }}>
        <ComponentToPrint contents={contents} ref={componentRef} />
      </div>
    </div>
  );
};
export default PrintIdCardBtn;
