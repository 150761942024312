import React, { useContext, useState } from 'react'
import { ReactComponent as ActiveControl } from 'assets/img/svg/ActiveControl.svg'
import { ReactComponent as InactiveControl } from 'assets/img/svg/InactiveControl.svg'
import { ReactComponent as ActiveControlDisabled } from 'assets/img/svg/ActiveControlDisabled.svg'
import { capitalize, checkPermission } from '../../../../common/utils/methods/commonMethods/utilityMethod'
import StatusChangeDialog from './StatusChangeDialog'
import { pages } from '../../../../common/constant'
import { PermissionContext, RolePermissions } from 'appContext';

const StatusCellComponent = ({ params, toggleRfidStatusHandler = () => { } }) => {
  const userPermissions = RolePermissions(useContext(PermissionContext), pages['idCards']['id']);

  const isRowSelectable = params?.api?.isRowSelectable(params?.id)
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    params?.value ? (
      <>
        <span>
          {params.value === "ACTIVE" ? (
            isRowSelectable ? (
              <ActiveControl onClick={checkPermission(userPermissions, 'U') ? () => setIsDialogOpen(true) : () => { }} />
            ) : (
              <ActiveControlDisabled />
            )
          ) : (
            <InactiveControl onClick={isRowSelectable && checkPermission(userPermissions, 'U') ? () => setIsDialogOpen(true) : () => { }} />
          )}
          <span
            style={{
              fontSize: "14px", marginLeft: "4px",
              ...(isRowSelectable && { color: params.value === "ACTIVE" ? '#0F0F0F' : '#757575' })
            }}
          >
            {capitalize(params?.value)}
          </span>
        </span>
        <StatusChangeDialog
          open={isDialogOpen}
          setOpen={setIsDialogOpen}
          rowData={params?.row}
          onAgree={() => toggleRfidStatusHandler(params)}
        />
      </>
    ) : (
      <span>-</span>
    )
  )
}

export default StatusCellComponent