import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import React, { useEffect, useState } from 'react'
import AAutoComplete from 'views/pages/manage/common/form-fields-mui/AAutoComplete'
import AInput from 'views/pages/manage/common/formFeilds/customInput/AInput'
import { daysDropdownData, getMomentObjFromTimeString, validateRowData } from '../common'
import styles from '../styles.module.scss'
import { ReactComponent as ActiveControl } from '../../../../../../assets/img/svg/ActiveControl.svg';
import { ReactComponent as InactiveControl } from '../../../../../../assets/img/svg/InactiveControl.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../assets/img/svg/Delete.svg';
import AButton from 'views/pages/manage/common/form-fields-mui/AButton'
import moment from 'moment'
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper'


const timepickerStyle = {
  width: '100%',
  '& .MuiInputBase-root': {
    height: '31px',
    borderRadius: '8px'
  },
  '& .MuiInputBase-input': {
    // height: '31px',
    padding: '7px 8px',
    fontSize: '12px',
  },
  '& .MuiInputAdornment-root': {
    display: 'none',
  }
}

const RowForm = ({
  plans,
  isNewRow,
  rowForm,
  setRowForm = () => { },
  rowFormHandler = () => { },
  setIsRowFormOpen = () => { },
  saveRowHandler = () => { },
  updateRowHandler = () => { },
  subjectDropdown,
}) => {

  const [sessionDisabled, setSessionDisabled] = useState(false);
  useEffect(() => {
    if (rowForm?.sessionName === 'Break') setSessionDisabled(true);
    else setSessionDisabled(false);
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className={`${styles?.list_container} ${styles?.row_form}`}>
        <div>
          <AAutoComplete
            placeholder='Select subject'
            items={subjectDropdown}
            currentValue={
              rowForm?.subject && {
                label: rowForm?.subject,
                value: rowForm?.subject,
              }
            }
            handleChange={value => rowFormHandler(value?.value, 'subject')}
            isMulti={false}
          />
        </div>
        <div>
          <AAutoComplete
            placeholder='Select day'
            items={daysDropdownData}
            currentValue={
              rowForm?.planDay && {
                label: rowForm?.planDay,
                value: rowForm?.planDay,
              }
            }
            handleChange={value => rowFormHandler(value?.value, 'planDay')}
            isMulti={false}
          />
        </div>
        <div>
          <AInput
            placeholder='Session name'
            value={rowForm?.sessionName}
            handler={value => rowFormHandler(value, 'sessionName')}
            style={{ borderColor: '#CCCCCC' }}
            disabled={sessionDisabled}
          />
        </div>
        <div>
          <DesktopTimePicker
            ampm={false}
            value={getMomentObjFromTimeString(rowForm?.startTime)}
            onChange={value =>
              rowFormHandler(
                moment(value).seconds(0).format('HH:mm:ss'),
                'startTime'
              )
            }
            sx={timepickerStyle}
          />
        </div>
        <div>
          <DesktopTimePicker
            ampm={false}
            value={getMomentObjFromTimeString(rowForm?.endTime)}
            onChange={value =>
              rowFormHandler(
                moment(value).seconds(0).format('HH:mm:ss'),
                'endTime'
              )
            }
            sx={timepickerStyle}
          />
        </div>
        <div>
          <span>
            {rowForm?.status === 'ACTIVE' ? (
              <ActiveControl
                onClick={() => rowFormHandler('INACTIVE', 'status')}
              />
            ) : (
              <InactiveControl
                onClick={() => rowFormHandler('ACTIVE', 'status')}
              />
            )}
            <span
              style={{
                fontSize: '14px',
                marginLeft: '4px',
                color: rowForm?.status === 'ACTIVE' ? '#0F0F0F' : '#757575',
              }}
            >
              {rowForm?.status}
            </span>
          </span>
        </div>
        <div className='d-flex align-items-center justify-content-end'>
          <AButton
            className='button_remove_left_margin'
            variant='primary_filled'
            updatedStyle={{
              height: '28px',
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '16px',
              borderRadius: '4px',
            }}
            onClick={() => {
              if (validateRowData(plans, rowForm)) {
                isNewRow ? saveRowHandler(rowForm) : updateRowHandler(rowForm);
              }
            }}
            disabled={
              // !rowForm?.subject?.trim() ||
              !rowForm?.planDay ||
              !rowForm?.sessionName?.trim() ||
              !rowForm?.startTime ||
              !rowForm?.endTime
            }
          >
            Save
          </AButton>
          <IconButtonWrapper
            className='ml-2'
            onClick={() => {
              setRowForm({});
              setIsRowFormOpen(false);
            }}
          >
            <DeleteIcon />
          </IconButtonWrapper>
        </div>
      </div>
    </LocalizationProvider>
  );
}

export default RowForm