import { InputLabel } from "@mui/material";
import React, { useState } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import AInput from "views/pages/manage/common/formFeilds/customInput/AInput";
import CustomDatePickerV2 from "views/pages/manage/common/commonComponents/CustomDatePicker-V2";
import { InputLabelStyle } from "../../../../common/form-fields-mui/ASelect";
import { reasonCodeDropdown, transactionTypeDropdown } from "./helper";
import CustomChip from "views/pages/manage/common/customChip/CustomChip";
import ConfirmationDialog from './ConfirmationDialog'
const inputHeight = "42px";
const errorStyle = {
  color: "#DC2804",
  fontSize: "12px",
  position: "absolute",
};
const EmployDetailsForm = ({
  dropdown,
  sapCostDropdown,
  employeeFormHandler,
  form,
  verifyEmpIdHandler,
  isErrorWhileVerify,
  isEmpIdVerified,
}) => {

  const [open, setOpen] = useState(false)

  return (
    <div>
      <Card className="m-3 border-radius-lg">
        <CardBody>
          <div className="d-flex heading-4 color-dark">
            Employee and Transaction details
          </div>
          <Row className="mb-2 align-items-end">
            <Col md="3" className="pr-0">
              <AAutoComplete
                items={dropdown?.businessAreas}
                isMulti={false}
                label="Business area*"
                height={inputHeight}
                currentValue={form?.businessArea}
                handleChange={value => {
                  employeeFormHandler(value, "businessArea");
                }}
                isDisabled={isEmpIdVerified}
              />
            </Col>
            <Col md="3" className="pr-0" style={{ position: "relative" }}>
              <AInput
                label="Employee ID*"
                disabled={isEmpIdVerified}
                style={{
                  height: inputHeight,
                  borderRadius: "4px",
                  borderColor: isErrorWhileVerify ? "red" : "hsl(0,0%,80%)",
                }}
                placeholder="Example: AESL-A0451"
                value={form?.psid}
                handler={value => {
                  employeeFormHandler(value, "psid");
                }}
              />
              {isErrorWhileVerify && (
                <span style={errorStyle}>Enter valid employee ID</span>
              )}
            </Col>
            <Col md="4" className="pr-0">
              {!isEmpIdVerified && (
                <AButton
                  variant="outline"
                  size="xs"
                  onClick={verifyEmpIdHandler}
                  className="mt-4 ml-0"
                  disabled={form?.psid === "" || form?.businessArea === ""}
                >
                  Verify ID
                </AButton>
              )}
              {isEmpIdVerified && (
                <AButton
                  variant="outline"
                  size="xs"
                  onClick={() => setOpen(true)}
                  className="mt-4 ml-0"
                  disabled={form?.psid === "" || form?.businessArea === ""}
                >
                  Edit
                </AButton>
              )}

              {isEmpIdVerified && (
                <span className="ml-2">
                  <CustomChip
                    bgColor={"#BBE2CE"}
                    textColor={"#0B442D"}
                    text="Verified"
                  />
                </span>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="3" className="pr-0">
              <AAutoComplete
                label="Transaction type*"
                items={transactionTypeDropdown}
                isMulti={false}
                height={inputHeight}
                currentValue={form?.transactionType}
                handleChange={value => {
                  employeeFormHandler(value, "transactionType");
                }}
                isDisabled={!isEmpIdVerified}
              />
            </Col>
            <Col md="3" className="pr-0">
              <AAutoComplete
                items={reasonCodeDropdown}
                height={inputHeight}
                label="Reason*"
                isMulti={false}
                currentValue={form?.reason}
                handleChange={value => {
                  employeeFormHandler(value, "reason");
                }}
                isDisabled={!isEmpIdVerified}
              />
            </Col>
            <Col md="3" className="pr-0">
              <AAutoComplete
                isMulti={false}
                height={inputHeight}
                label="Cost center*"
                className="p-0"
                items={sapCostDropdown}
                currentValue={form?.sapCostCenter}
                handleChange={value => {
                  employeeFormHandler(value, "sapCostCenter");
                }}
                isDisabled={!isEmpIdVerified}
              />
            </Col>
            <Col md="3">
              <InputLabel sx={InputLabelStyle}>Transaction date*</InputLabel>
              <CustomDatePickerV2 value={new Date()} disabled={true} />
            </Col>
          </Row>
          <ConfirmationDialog open={open} setOpen={setOpen} />
        </CardBody>
      </Card>
    </div>
  );
};

export default EmployDetailsForm;
