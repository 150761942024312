import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import FeeSearchApproval from '../Approval';
import { PermissionContext, RolePermissions } from 'appContext';
import { PermisionDenied } from '../../../../../common/commonComponents';
import { pages } from '../../../../../common/constant';

const FeeSearchL2Approval = props => {
  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages['level2Approval']['id']);
  return userPermissions ? (
    <FeeSearchApproval
      userId={10002}
      title="Course Fee Upload Level 2"
      statusTobeEditedForBulk="L1Approved"
      statusTobeEditedForManual="L1Approved"
      statusToBeView="L2 Approved"
      userPermissions={userPermissions}
      keyName="level2Approval"
    />
  ) : (
    <PermisionDenied />
  );
};

export default withRouter(FeeSearchL2Approval);
