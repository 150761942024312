import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardHeader, Button, Table, Container } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { failureToast, successToast } from '../../../../../common/utils/methods/toasterFunctions/function';
import { GetPagination } from '../../../../../common/commonComponents/pagination/pagination';
import { PricingZoneHeader } from '../../../../../common/commonComponents/masterRow/masterRow';
import CustomLoader from '../../../../../common/commonComponents/Loader/loader';
import { getAPI, putpost, putpost2 } from 'services/http';
import CustomButton from 'components/CustomButton';
import { CustomToggle } from 'views/pages/manage/common/formFeilds';
import {
	fetchDetails,
	getDispValForReactSelectByOneID,
} from '../../../../../common/utils/methods/commonMethods/utilityMethod';
import CommonInput from '../../../../../common/formFeilds/input/commonInput';
import { CustomTableRow } from '../../../../../common/commonComponents/TableComponents';
import { endpoint } from 'views/pages/manage/common/constant';
import AddNewModel from '../model/addNewModel';
import CloseModel from '../model/closeModel';

var _ = require('lodash');

const TDROW = (props) => {
	const { item, index, action, businessArea, editRow, deleteRow, userPermissions } = props;

	return (
		<CustomTableRow key={'ItemTypeSequenceRowTDROW' + index}>
			<td className="text-center p-2 mx-1">
				{getDispValForReactSelectByOneID(
					item.businessAreaId,
					'DispValue',
					businessArea
				)}
			</td>
			<td className="text-center p-2 mx-1">
				{getDispValForReactSelectByOneID(
					item.businessAreaId,
					'DescValue',
					businessArea
				)}
			</td>
			<td className="text-center p-2 mx-1">
				{item.status === 'ACTIVE' ? (
					<button
						disabled={true}
						type="button"
						className="btn btn-sm btn-success"
					>
						ACTIVE
					</button>
				) : (
					<button
						type="button"
						disabled={true}
						className="btn btn-sm btn-warning"
					>
						INACTIVE
					</button>
				)}
			</td>
			{action === 'new' || action === 'edit' ? (
				<td className={"text-center p-2 mx-1"}>
					{/* <Button
						color="info"
						size="sm"
						className="mx-1"
						key={index + 'deleteRow'}
						onClick={() => editRow(index, 'edit')}
					>
						<i className="fas fa-edit" />
					</Button> */}
					<CustomButton
						permissionType={'U'}
						icon={true}
						forTable={true}
						key={index + 'deleteRow'}
						onClick={() => editRow(index, 'edit')}
						permissionSet={userPermissions}
					/>
					{/* <Button
						color="info"
						size="sm"
						className=" mx-1"
						onClick={() => deleteRow(index)}
					>
						<i className="fas fa-trash" />
					</Button> */}
					<CustomButton
						permissionType={'D'}
						icon={true}
						forTable={true}
						onClick={() => deleteRow(index)}
						permissionSet={userPermissions}
					/>
				</td>
			) : null}
		</CustomTableRow>
	);
};

const AssociatedBuAreaTable = (props) => {
	const { userPermissions } = props;
	const {
		isNew = true,
		formId,
		businessGroupCode,
		companyCode,
		isViewOnly = false,
	} = props;
	let history = useHistory();
	let postId;
	const { action = '', id = '' } = props.match.params;
	const [num, setNum] = useState(1);
	const [apiLoader, setApiLoader] = useState(true);
	const [state, setState] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [tableDataKey, settableDataKey] = useState('tableDataKey');
	const [statusActive, setStatusActive] = useState(false);
	const [businessArea, setBusinessArea] = useState([]);
	const [isAddNewModelVisible, setIsAddNewModelVisible] = useState(false);
	const [isCloseModelOpen, setIsCloseModelOpen] = useState(false);
	const [isSomeThingEdit, setIsSomeThingEdit] = useState(false);
	const [isEdit, setIsEdit] = useState(false);

	const [pagination, setpagination] = useState({
		currentPage: 1,
		nextPage: null,
		previousPage: null,
		totalPage: null,
	});

	const pageSize = 10;

	const pageFunc = (data, stateMethod) => {
		setState(data);
		let n = pagination;
		if (data.length > pageSize) {
			n['nextPage'] = n.currentPage + 1;
		}
		if (n.currentPage > 1) {
			n.previousPage = n.currentPage - 1;
		}
		n['totalPage'] = Math.floor(data.length / pageSize);
		if (data.length % pageSize != 0) {
			n['totalPage'] += 1;
		}
		setpagination(n);
		stateMethod(
			data.slice(
				pagination.currentPage * pageSize - pageSize,
				pagination.currentPage * pageSize
			) || []
		);
	};

	const addNewClass = (numOfRows) => {
		try {
			if (action === 'edit' && !isSomeThingEdit) {
				setIsSomeThingEdit(true);
			}
			let noOfRows = 10;
			if (num <= noOfRows) {
				let newRows = [];
				let tempObject = {
					isNew: true,
					isEdit: false,
					pricingZoneId: formId,
					businessAreaId: '',
					businessAreaDescription: '',
					status: 'ACTIVE',
				};
				for (let i = 0; i < num; i++) {
					newRows.push(tempObject);
				}
				let tempArray = [...newRows, ...tableData];
				setTableData(tempArray);
				setIsAddNewModelVisible(false);
				setNum(1);
			} else {
				failureToast(`Please Enter values between 1 and ${noOfRows}.`);
				setIsSomeThingEdit(false);
				setNum(1);
			}
		} catch (error) {
			console.error('Error', error);
		}
	};

	const addNewRow = (numOfRows) => {
		let addBulkRows = () => {
			addNewClass(numOfRows);
		};
		let _tempArray = tableData;
		_tempArray = _.sortBy(_tempArray, [
			function (o) {
				return o.value;
			},
		]);
		setTableData(_tempArray);
		setIsSomeThingEdit(false);
		addBulkRows();
	};

	const editRow = (index, type) => {
		try {
			let tempArray = tableData.slice(0);
			if (action === 'edit' && !isSomeThingEdit) {
				setIsSomeThingEdit(true);
			}
			if (type === 'edit') {
				tempArray[index].isEdit = true;
				let tempObject = tempArray[index];
				let BusAreaID = tempObject.businessAreaId;
				if (BusAreaID && businessArea && businessArea.length) {
					let val = getDispValForReactSelectByOneID(
						BusAreaID,
						'DescValue',
						businessArea
					);
					tempObject = {
						...tempObject,
						['BusAreaDescription']: val,
					};
				}
				tempArray[index] = tempObject;
				setTableData(tempArray);
				setIsEdit(true);
			} else if (type === 'save') {
				tempArray[index].isEdit = false;
				setIsEdit(false);
				setTableData(tempArray);
			} else if (type === 'cancel') {
				tempArray[index].isEdit = false;
				setIsEdit(false);
				setTableData([...tempArray]);
			}
		} catch (error) {
			console.error('Error', error);
		}
	};

	const updateTableData = (index, value, key) => {
		try {
			if (action === 'edit' && !isSomeThingEdit) {
				setIsSomeThingEdit(true);
			}
			let tempArray = [...tableData];
			let tempObject = tempArray[index];
			tempObject = { ...tempObject, [key]: value };
			if (key === 'businessAreaId') {
				let val = getDispValForReactSelectByOneID(
					value,
					'DescValue',
					businessArea
				);
				tempObject = {
					...tempObject,
					['BusAreaDescription']: val,
				};
			}
			tempArray[index] = tempObject;
			setTableData(tempArray);
		} catch (error) {
			console.error('Error', error);
		}
	};

	const deleteRow = (index) => {
		try {
			if (tableData[index]['isNew'] === true) {
				let n = tableData;
				n.splice(index, 1);
				setTableData(n);
				settableDataKey(new Date().getMilliseconds());
			} else {
				if (window.confirm('Are you sure want to delete this row?')) {
					putpost2(
						`${endpoint.pricingZone_BusinessArea.delete}/` +
						tableData[index].id,
						(data) => {
							successToast(data['message']);
							let tempArray = tableData;
							if (tempArray[index]['editMode']) {
								setIsSomeThingEdit(false);
							}
							tempArray.splice(index, 1);
							setTableData(tempArray);
							settableDataKey(new Date().getMilliseconds());
						},
						(data) => {
							failureToast(data['message']);
						},
						'Delete'
					);
				}
			}
		} catch (error) {
			console.error('Error', error);
		}
	};

	const onSave = () => {
		try {
			if (tableData.length === 0) {
				failureToast('Please add at least one row.');
			} else {
				let tempTableData = [];
				let tempArray = tableData;
				let flag = true;
				tempArray.map((item, index) => {
					if (flag) {
						if (
							item['businessAreaId'] === null ||
							item['businessAreaId'] === ''
						) {
							failureToast('Please Select Business Area');
							flag = false;
						} else {
							tempTableData.push({
								id: item.id,
								pricingZoneId: item.pricingZoneId,
								businessAreaId: item.businessAreaId,
								status: item.status,
							});
						}
					}
				});
				let dataForApi = {
					pricingZoneBusinessAreaDTO: [...tempTableData],
				};
				if (action === 'new' && flag) {
					let method = 'POST';
					let url = endpoint.pricingZone_BusinessArea.create;
					PostData(url, method, dataForApi);
				} else if (action === 'edit' && flag) {
					let method = 'PUT';
					let url = `${endpoint.pricingZone_BusinessArea.update}/${id}`;
					PostData(url, method, dataForApi);
				}
			}
		} catch (e) {
			console.error('Error---->', e);
		}
	};

	const PostData = (url, requestMethod, dataObject) => {
		try {
			setApiLoader(true);
			putpost(
				url,
				(data) => {
					postId = data?.data?.id;
					successToast(data.message);
					fetchItemTypeData(
						`${endpoint.pricingZone_BusinessArea.getBusinessAreaListByPricingId}/${postId}`,
						true
					);
					setIsSomeThingEdit(false);
				},
				(data) => {
					failureToast(data.message);
					setApiLoader(false);
					setIsSomeThingEdit(false);
				},
				dataObject,
				requestMethod
			);
		} catch (error) {
			console.error('Error', error);
		}
	};

	const fetchItemTypeData = (url, isEdit = false) => {
		try {
			setApiLoader(true);
			getAPI(
				url,
				(data) => {
					if (data.data.pricingZoneBusinessAreaDTO.length) {
						let resData = data.data.pricingZoneBusinessAreaDTO;
						pageFunc(resData, setTableData);
					}
					setApiLoader(false);
					setIsSomeThingEdit(false);

					if (action === 'new' && isEdit) {
						history.push('/admin/pricingZone/view/' + postId);
					}
				},
				(data) => {
					failureToast(data['message']);
					setApiLoader(false);
					setIsSomeThingEdit(false);
				}
			);
		} catch (error) {
			console.error('Error', error);
		}
	};

	const closeHandler = (key, flag = false) => {
		try {
			if (key === '1') {
				setIsCloseModelOpen(true);
			} else if (key === '2') {
				if ((action === 'edit' || action === 'view') && flag) {
					setIsCloseModelOpen(false);
					history.push('/admin/pricingZone');
				} else if (action === 'new' && flag) {
					setIsCloseModelOpen(false);
					history.push('/admin/pricingZone');
				} else {
					setIsCloseModelOpen(false);
				}
			} else if (key === '3') {
				if ((action === 'edit' || action === 'view') && flag) {
					setIsCloseModelOpen(false);
					window.location.reload();
				}
			}
		} catch (error) {
			console.error('Error', error);
		}
	};

	useEffect(() => {
		if (companyCode > 0 && businessGroupCode > 0) {
			fetchDetails(
				`${endpoint.businessArea.getAllActiveByGroupCodeAndComapnyCode}?companyCode=${companyCode}&groupCode=${businessGroupCode}`,
				setBusinessArea,
				'businessArea',
				'reactSelect'
			);
		}
	}, [companyCode && businessGroupCode]);

	useEffect(() => {
		if ((action === 'view' || action === 'edit') && businessArea.length > 0) {
			fetchItemTypeData(
				`${endpoint.pricingZone_BusinessArea.getBusinessAreaListByPricingId}/${id}`
			);
		}
	}, [businessArea]);

	useEffect(() => {
		if (action === 'view' && isSomeThingEdit) {
			setIsSomeThingEdit(false);
		}
		if(action === 'new'){
			setApiLoader(false)
		}
	}, [action]);

	return (
		<>
			{apiLoader ? (
				<Card>
					<CustomLoader
						apiLoader={apiLoader}
						loaderHeight={'200px'}
						loaderWidth={'100% '}
					/>
				</Card>
			) : (
				<div className='pb-4'>
					<Card>
						<CardHeader className="border-0">
							<h3 className="mb-0 floatLeft">Associated Business Area</h3>
							{action !== 'view' ? (
								// <Button
								// 	color="info"
								// 	size="sm"
								// 	className="floatRight mx-1"
								// 	onClick={() => setIsAddNewModelVisible(true)}
								// >
								// 	Add New
								// </Button>
								<CustomButton
									className={"floatRight mx-1"}
									content={'Add New'}
									permissionType={'C,U'}
									// forTable={true}
									icon={true}
									permissionSet={userPermissions}
									onClick={() => setIsAddNewModelVisible(true)}
								/>
							) : null}
						</CardHeader>
						<div
							id="questionTable"
							className="table-responsive"
							style={{ overflow: 'auto' }}
						>
							<Table className="align-items-center table-flush">
								<PricingZoneHeader
									type={'AssociatedBuArea'}
									isViewOnly={isViewOnly}
								/>
								<tbody className="list" key={tableDataKey}>
									{tableData && tableData.length > 0
										? tableData.map((el, index) =>
											el['isEdit'] || el['isNew'] ? (
												<CustomTableRow key={'associatedBuAreaRow' + index}>
													<td className={"text-center p-2 mx-1"}>
														{action === 'new' ||
															(el['isNew'] && action === 'edit') ? (
															<CommonInput
																forTable={true}
																mandatory={true}
																data={businessArea}
																type={'reactSelect'}
																placeHolder={'Business Area'}
																defaultValue={el['businessAreaId']}
																onChange={(value) => {
																	updateTableData(
																		index,
																		value,
																		'businessAreaId'
																	);
																}}
															/>
														) : (
															getDispValForReactSelectByOneID(
																el['businessAreaId'],
																'DispValue',
																businessArea
															)
														)}
													</td>
													<td className={"text-center p-2 mx-1"}>
														<CommonInput
															forTable={true}
															mandatory={true}
															type={'text'}
															placeHolder={'Description'}
															defaultValue={el['BusAreaDescription']}
															isDisabled={true}
														/>
													</td>
													<td className={"text-center p-2 mx-1"}>
														{/* <label className="custom-toggle mx-auto ml-2">
															<input
																checked={el.status == 'ACTIVE' ? true : false}
																type="checkbox"
																id={el.id}
																onChange={(e) => {
																	settableDataKey(new Date().getMilliseconds());
																	let n = 'ACTIVE';
																	if (!e.target.checked) {
																		n = 'INACTIVE';
																	}
																	updateTableData(index, n, 'status');
																}}
															/>
															<span
																style={{ width: '72px' }}
																className="custom-toggle-slider rounded-circle activeToggle"
																data-label-off="Inactive"
																data-label-on="Active"
															/>
														</label> */}
														<CustomToggle
															status={el.status}
															data-testid={"activeInactiveInput"}
															id={el.id}
															toggleOn={"Active"}
															toggleOff={"Inactive"}
															onChangeHandler={(e) => {
																settableDataKey(new Date().getMilliseconds());
																setStatusActive(e.target.checked);
																let n = 'ACTIVE';
																if (!e.target.checked) {
																	n = 'INACTIVE';
																}
																updateTableData(index, n, 'status');
															}}
														/>
													</td>
													<td className={"text-center p-2 mx-1"}>
														{el['isEdit'] ? (
															// <Button
															// 	color="danger"
															// 	size="sm"
															// 	type="button"
															// 	className="floatRight mx-1"
															// 	onClick={() => editRow(index, 'cancel')}
															// >
															// 	<i className="fas fa-times" />
															// </Button>
															<CustomButton
																permissionType={'cancel'}
																forTable={true}
																icon={true}
																className={"floatRight mx-1"}
																permissionSet={userPermissions}
																onClick={() => editRow(index, 'cancel')}
															/>
														) : (
															// <Button
															// 	color="info"
															// 	size="sm"
															// 	className="floatRightmx-1"
															// 	onClick={() => deleteRow(index)}
															// >
															// 	<i className="fas fa-trash" />
															// </Button>
															<CustomButton
																permissionType={'D'}
																forTable={true}
																icon={true}
																className={"text-center p-2 mx-1"}
																permissionSet={userPermissions}
																onClick={() => deleteRow(index)}
															/>
														)
														
														}
													</td>
												</CustomTableRow>
											) : (
												<TDROW
													item={el}
													index={index}
													action={action}
													editRow={editRow}
													deleteRow={deleteRow}
													businessArea={businessArea}
													userPermissions={userPermissions}
												/>
											)
										)
										: null}
								</tbody>
							</Table>
							{pagination.totalPage > 1 ? (
								<GetPagination
									setclassArray={setTableData}
									state={state}
									pagination={pagination}
									setpagination={setpagination}
									pageSize={pageSize}
								/>
							) : null}
						</div>
					</Card>
					<div className="border-0 pb-4 mb-4">
						{/* <Button
						color="info"
						size="sm"
						className="floatRight mx-1"
						onClick={() => {
							closeHandler('1');
						}}
					>
						Close
					</Button> */}
						<CustomButton
							permissionType={'C'}
							// forTable={true}
							type="close"
							content={'Close'}
							className={"floatRight mx-1"}
							permissionSet={userPermissions}
							onClick={() => {
								closeHandler('1');
							}}
						/>
						{action === 'edit' || action === 'new' ? (
							// <Button
							// 	color="info"
							// 	size="sm"
							// 	className="floatRight mx-1"
							// 	onClick={onSave}
							// >
							// 	Save
							// </Button>
							<CustomButton
								permissionType={'C,U'}
								// forTable={true}
								content={'Save'}
								className={"floatRight mx-1"}
								permissionSet={userPermissions}
								onClick={onSave}
							/>
						) : null}

					</div>
				</div>
			)}
			<AddNewModel
				num={num}
				setNum={setNum}
				isVisible={isAddNewModelVisible}
				addNewRow={addNewRow}
				setIsVisible={setIsAddNewModelVisible}
				onSubmit={addNewRow}
			/>
			<CloseModel
				isCloseModelOpen={isCloseModelOpen}
				closeHandler={closeHandler}
				isSomeThingEdit={isSomeThingEdit}
			/>
		</>
	);
};
export default withRouter(AssociatedBuAreaTable);
