import { endpoint, regex } from "../../../common/constant";
const { fetchAllPromisedData, fetchAllPostPromisedData, updateRequest, fetchDeletePromisedData, dropdownMapping } = require("../../../common/utils/methods/commonMethods/utilityMethod")

export const selectStyles = {
  control: {
    borderRadius: '4px'
  }
}

export const onKeyPressNumeric = (e) => {
  if (!regex?.numeric.test(String.fromCharCode(e.charCode))) {
    e.preventDefault();
    return;
  }
}

export const generateRandomId = () => Date.now();

export const getSingleSelectDefault = (id, dataArray) => {
  return dataArray?.find(item => item?.value === id);
}

export const getMultiSelectDefault = (idArray, dataArray) => {
  return idArray?.map(id => dataArray?.find(item => item?.value === id));
}

export const getDropdowns = async () => {
  const p = await Promise.all([
    dropdownMapping(endpoint?.academic_career?.getAllActive, 'academicCareerDispValue', 'id'),
    dropdownMapping(endpoint?.academic_career?.getAll, 'academicCareerDispValue', 'id'),
    dropdownMapping(endpoint?.term?.getAllActive, 'termDispValue', 'id'),
    dropdownMapping(endpoint?.term?.getAllTerm, 'termDispValue', 'id'),
    fetchAllPromisedData(endpoint?.getAllProgramStatus),
    dropdownMapping(endpoint?.courseCategory?.getAllActiveCourseCategory, 'courseAttributeValueDispValue', 'id'),
    dropdownMapping(endpoint?.courseCategory?.getAllCourseCategory, 'courseAttributeValueDispValue', 'id'),
  ])

  return {
    academicCareer: p[0],
    allAcademicCareer: p[1],
    term: p[2],
    allTerm: p[3],
    programStatus: p[4]?.map(item => ({ label: item, value: item })),
    courseCategory: p[5],
    allCourseCategory: p[6],
  }
}

export const createConfig = (request) => fetchAllPostPromisedData(endpoint?.configExAakashian?.create, updateRequest(request), 'post');

export const getAllConfig = () => fetchAllPromisedData(endpoint?.configExAakashian?.getAll, true);

export const updateConfig = (id, request) => fetchAllPostPromisedData(`${endpoint?.configExAakashian?.update}/${id}`, updateRequest(request), 'put');

export const deleteConfig = (id) => fetchDeletePromisedData(`${endpoint?.configExAakashian?.update}/${id}`);