import React, { useContext, useState } from "react";
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import "../style.scss";
import {
  FILTER__FORM_FIELDS_GLOBAL,
  FILTER__FORM_FIELDS,
  IssuanceIDCardContext,
  PAGE_STATE,
} from "../contextApi";
import { useGetBatchListByBuAcadGroupTermCourseId,useGetCourseIdList } from "../../issuanceIDcard/customhooks";
const StudentFilterForm = ({ disabled }) => {
  const inputHeight = "42px";
  const marginTop = { marginTop: "33px" };

  const {
    dropdown,
    filterForm,
    setFilterForm,
    setPage,
    setCurrentPageState,
    setGlobalFilter,
    setSelectedBusinessArea,
  } = useContext(IssuanceIDCardContext);

  const [isError, setIsError] = useState({
    businessArea: null,
    academicGroup: null,
    batchIds: null,
    courseId: null,
    term: null,
  });

  const [isShowError, setShowError] = useState(false);

  const batchDropdown = useGetBatchListByBuAcadGroupTermCourseId(
    // dropdown?.businessAreas?.length === 1
    //   ? dropdown?.businessAreas[0]
    //   : filterForm?.businessArea,
    filterForm?.businessArea,
    filterForm?.academicGroup,
    filterForm?.term,
    filterForm?.courseId
  );

  const courseIdDropdown = useGetCourseIdList(
    // dropdown?.businessAreas?.length === 1
    // ? dropdown?.businessAreas[0]
    // : filterForm?.businessArea,
    filterForm?.businessArea,
    filterForm?.academicGroup,
    filterForm?.term
  );
  const resetButtonHandler = () => {
    setFilterForm(FILTER__FORM_FIELDS);
  }

  const formHandler = (key, value) => {
    setFilterForm((prev) => ({ ...prev, [key]: value }));
    setShowError(false);
    setIsError((prev) => ({ ...prev, [key]: null }));
  };

  const callBack = () => {
    setCurrentPageState(PAGE_STATE.SELECT_MATERIAL);
  };

  const isBatchDisabled = () => {
    // if (dropdown?.businessAreas?.length === 1) {
    //   return (
    //     dropdown?.businessAreas?.length === 1 && (filterForm?.term.length === 0 || !filterForm?.academicGroup || !filterForm?.courseId?.length)
    //   );
    // } else {
      return filterForm?.term.length === 0 || filterForm?.businessArea === "" || !filterForm?.academicGroup || !filterForm?.courseId?.length;
    // }
  };

  const isErrorExist = (formFields) => {
    let isFindError = false;
    for (const key in formFields) {
      if (filterForm[key]?.length === 0) {
        formFields[key] = true;
        isFindError = true;
        setShowError(true);
      } else {
        formFields[key] = null;
      }
    }
    setIsError(formFields);
    return isFindError;
  };
  const nextButtonHandler = () => {
    if (isErrorExist({ ...isError })) return;

    setSelectedBusinessArea(filterForm?.businessArea);
    setGlobalFilter(FILTER__FORM_FIELDS_GLOBAL);
    setPage(0);
    callBack();
  };

  const errorBorder = { border: "1px solid red", color: "red" };

  return (
    <div>
      <div
        className="student-search-filer-stock 
      my-2"
      >
        <div>
          <AAutoComplete
            label="Business area*"
            height={inputHeight}
            placeholder="Business area"
            isMulti={false}
            items={dropdown?.businessAreas}
            currentValue={
              // dropdown?.businessAreas?.length === 1
              //   ? dropdown?.businessAreas[0]
              //   : filterForm?.businessArea
              filterForm?.businessArea
            }
            isDisabled={disabled}
            handleChange={(value) => {
              formHandler("businessArea", value);
              formHandler("batchIds", []);
              formHandler("courseId", []);
            }}
            style={{ control: isError["businessArea"] ? errorBorder : {} }}
          />
        </div>
        <div>
          <AAutoComplete
            height={inputHeight}
            label="Academic group*"
            placeholder="Academic group"
            items={dropdown?.academicGroups}
            currentValue={filterForm?.academicGroup}
            isShowCount={true}
            handleChange={(value) => {
              formHandler("academicGroup", value);
              formHandler("courseId", []);
              formHandler("batchIds", []);
            }}
            isDisabled={disabled}
            isMulti={false}
            style={{ control: isError["academicGroup"] ? errorBorder : {} }}
          />
        </div>
        <div>
          <AAutoComplete
            label="Term*"
            height={inputHeight}
            placeholder="Term"
            items={dropdown?.terms}
            currentValue={filterForm?.term}
            isShowCount={false}
            isMulti={false}
            handleChange={(value) => {
              formHandler("term", [value]);
              formHandler("batchIds", []);
              formHandler("courseId", []);
            }}
            isDisabled={disabled}
            style={{ control: isError["term"] ? errorBorder : {} }}
          />
        </div>
        <div>
          <AAutoComplete
            height={inputHeight}
            label="Course ID*"
            placeholder="Course ID"
            isShowCount
            isMulti
            items={courseIdDropdown}
            currentValue={filterForm?.courseId}
            handleChange={(value) => {
              formHandler("batchIds", [])
              formHandler("courseId", value);
            }}
            isDisabled={!filterForm.businessArea||!filterForm.academicGroup||!filterForm.term.length>0|| disabled}
            style={{ control: isError["courseId"] ? errorBorder : {} }}
          />
        </div>
        <div>
          <AAutoComplete
            height={inputHeight}
            label="Batch*"
            placeholder="Batch"
            items={batchDropdown}
            currentValue={filterForm?.batchIds}
            isShowCount={true}
            handleChange={(value) => {
              formHandler("batchIds", value);
            }}
            style={{ control: isError["batchIds"] ? errorBorder : {} }}
            isDisabled={isBatchDisabled() || disabled}
          />
        </div>
        <div style={marginTop} className="d-flex" >
          <AButton
            className="button_remove_left_margin"
            updatedStyle={{ fontSize: "14px", flex: "1.75" }}
            onClick={nextButtonHandler}
            variant="primary_filled"
            disabled={disabled}
          >
            Next
          </AButton>
          <AButton
            updatedStyle={{ fontSize: "14px", flex: "0.75" }}
            onClick={resetButtonHandler}
            variant="outline"
            disabled={disabled}
          >
            Reset
          </AButton>
        </div>
      </div>
      {isShowError && (
        <span className="error-psid">All fields are required*</span>
      )}
    </div>
  );
};

export default StudentFilterForm;
