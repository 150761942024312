import { useEffect, useState } from "react"
import { fetchAllPromisedData } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from 'views/pages/manage/common/constant';

const useFetchBusinessAreas = () => {
  const [businessAreas, setBusinessAreas] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const res = await fetchAllPromisedData(endpoint.businessArea.getAllActive);
        setBusinessAreas(res);
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false);
      }
    }
    init();
  }, []);

  return [businessAreas, loading];
}

export default useFetchBusinessAreas;