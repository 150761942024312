import React from "react";
import "../style.scss";
import { AddMaterialFormMisc } from "./tableComponents";
import AButton from "../../../../common/form-fields-mui/AButton";
import { Tooltip } from "@mui/material";
import { isBarcodedMaterial } from "../../selectMeterialAndStock/constant";

export const DisplayRowData = ({ row, index, editHandler, deleteHandler, disableAllButtons }) => {
  return (
    <div className="issue-stock-misc">
      <div>{row?.courseId}</div>
      <Tooltip title={row?.courseName}>
        <div className="ellipsis-text-container">{row?.courseName}</div>
      </Tooltip>
      <div>{row?.materialId}</div>
      <Tooltip title={row?.materialDescription}>
        <div className="ellipsis-text-container">{row?.materialDescription}</div>
      </Tooltip>
      <div>{row?.availableCount}</div>
      <div>1</div>
      <Tooltip title={isBarcodedMaterial(row) && row?.barcode}>
        <div className="ellipsis-text-container">{isBarcodedMaterial(row) ? row?.barcode : '-'}</div>
      </Tooltip>
      <div>
        <AButton
          size="xs"
          variant="primary_filled"
          onClick={() => editHandler(index, 'edit')}
          className="btn-left-0"
          disabled={disableAllButtons}
        >
          Edit
        </AButton>
      </div>
    </div>
  );
};

const TableHeader = () => {
  return (
    <div className="header-column issue-stock-misc">
      <div>Misc. course ID</div>
      <div>Course description</div>
      <div>Material number</div>
      <div>Material description</div>
      <div>Available qty.</div>
      <div>Issued qty.</div>
      <div>Barcode</div>
      <div>Action</div>
    </div>
  );
};

const IssueStockMiscFlow = ({
  row,
  setRow,
  loading,
  setLoading,
  businessArea,
  saveRowHandler,
  setError,
  courseName,
  materialDropdown,
  disableAllButtons,
}) => {


  const editHandler = (index) => {
    setRow({ ...row, openForm: true });
  };

 
  const TableRow = ({ row, index, loading, setLoading, setRow, ...props }) => {
    if (row?.openForm)
      return (
        <AddMaterialFormMisc
          loading={loading}
          setLoading={setLoading}
          saveHandler={saveRowHandler}
          indexNo={index}
          row={row}
          businessArea={businessArea}
          isMisc={true}
          setRow={setRow}
          setError = {setError}
          courseName = {courseName}
          materialDropdown ={materialDropdown}
          disableAllButtons={disableAllButtons}
        />
      );

    return (
      <DisplayRowData
        editHandler={editHandler}
        index={index}
        row={row}
        disableAllButtons={disableAllButtons}
      />
    );
  };

  return (
    <div className="overflow-auto">
      <TableHeader />
      <TableRow
        row={row}
        loading={loading}
        setLoading={setLoading}
        setRow={setRow}
        setError = {setError}
      />
    </div>
  );
};

export default IssueStockMiscFlow;
