import React, { useState, useEffect, useContext } from 'react';
import { Container, Card, UncontrolledTooltip, Button, Row, Table, CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import TemplateViewModal from 'components/modals/templateModal';
import './index.css';
import { templateBaseurl, putpost2 } from 'services/http';
import { PermissionContext, RolePermissions } from 'appContext';
import { endpoint, pages } from '../../common/constant';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import { fetchAllPromisedData, getFileUrl, getUniqueKey, isEqual } from '../../common/utils/methods/commonMethods/utilityMethod';
import { MasterHeaderAuto } from '../../common/commonComponents/masterHeader/masterHeader';
import { getPaginatedData } from '../../common/utils/methods/commonMethods/paginationMethod';
import { fetchAllPostPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import { GetPagination } from '../../common/commonComponents/pagination/pagination';
import NotificationTemplateSearchForm from './SearchForm';
import CustomLoader from "../../common/commonComponents/Loader/loader";
import { Dialog } from '../../common/commonComponents';
import NotificationBulkUpload from './bulkNotificationUpload';
import moment from 'moment'

let page = 0;
let isNextCallPossible = true;
const emplySearchParams = {
  templateId: '',
  templateName: '',
  channel: '',
  templateCategoryId: '',
  departmentName:'',
}
const emptyPagination = {
  currentPage: 1,
  nextPage: null,
  previousPage: null,
  totalPage: null
}

const headerList = [
  { name: 'TEMPLATE ID' },
  {name:'Department'},
  { name: 'TEMPLATE Name' },
  { name: 'Template' },
  { name: 'Channels' },
  { name: 'UPDATED ON' },
  { name: 'STATUS' },
  { name: 'ACTION', colSpan: '2' }
]

// const listToBeRendered = [
//   'id',
//   'templateName',
//   'template',
//   'templateChannels',
//   'updatedOn',
//   'status'
// ]

const customeStyle = {
  position: 'absolute',
  backgroundColor: 'white',
  border: '.5px solid grey',
  fontSize: '10px',
  // display:'inline-block',
  maxWidth: '300px',
  width: 'max-content',
  color: 'grey',
  // top:'5px',
  left: '30%'
}

//{ color: 'black', maxWidth: `${maxWidth ? maxWidth : '100px'}`, width: 'auto', margin: 'auto' }

const ToolTip = ({ content, target, left, top }) => {
  return <UncontrolledTooltip delay={0} placement="top" target={target} hideArrow={true} className="custom-tooltip" style={{ ...customeStyle, left: left ? left : customeStyle.left, top: top ?? '0' }}>
    {content}
  </UncontrolledTooltip>
}


function ErpTableTemplate2(props) {
  // const [templateTableData, setTemplateTableData] = useState([]);
  const [templateData, setTemplateData] = useState('');
  const [modalPurpose, setModalPurpose] = useState('');
  const [templateModal, setTemplateModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isAlert, SetIsAlert] = useState(false);
  const [alertMessage, SetAlertMessage] = useState('');
  const [updaterKey, setUpdaterKey] = useState(new Date().getMilliseconds())
  const [searchParams, setSearchParms] = useState(emplySearchParams)
  const location = useLocation();
  const pageSize = 10;
  const [serverData, setServerData] = useState([]);
  const [tableData, setTableData] = useState([])
  let [pagination, setpagination] = useState(emptyPagination);
  const [viewTemplate, setViewTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [bulkNotificationModal, setBulkNotificationModal] = useState(false);

  const [downloadingTemplate, setDownloadingTemplate] = useState(false);
  const [departmentDisplay,setDepartmentDisplay]=useState([]);


  const [categoryData, setCategoryData] = useState({ fetching: true, data: [] })
  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages['erpTemplate']['id']);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const getDepartmentDisplay=async()=>{
    // debugger
    try{
      const res= await fetchAllPromisedData( `${templateBaseurl}/configuration`,false);
        setDepartmentDisplay(res.DEPARTMENTS)
    }catch(e){
      console.log('Error',e);
    }
  }
  const getTemplateHandler = async () => {
    try {
      page = 0;
      setLoader(true);
      const templateData = await fetchAllPostPromisedData(`${templateBaseurl}/getAllTemplatesSearch/${page}`, { ...searchParams, channel: searchParams.channel?.value ?? '', templateCategoryId: searchParams.templateCategoryId?.value ?? '' })
      if (templateData?.code === 200) {
        setServerData(templateData.data?.messageTemplateList);
        getPaginatedData(templateData.data?.messageTemplateList, pageSize, emptyPagination, setpagination, setLoader, setTableData)
      } else {
        setLoader(false);
        // failureToast(templateData['message']);
      }
    } catch (e) {
      console.log('Error', e);
    }
  };
  useEffect(() => {
    setLoader(true);
    getTemplateHandler();
  }, [location]);

  const handleDeleteTemplate = data => {
    putpost2(
      `${templateBaseurl}/deleteTemplate/${data.id}`,
      data => {
        successToast(data['message']);
        getTemplateHandler();
      },
      data => {
        failureToast(data['message']);
      }
    );
  };

  const handleTemplateView = (data, purpose) => {
    setModalPurpose(purpose);
    setTemplateData(data);
    setTemplateModal(true);
  };


  const fetchNext = async () => {
    setLoader(true);
    page = page + 1;
    const templateData = await fetchAllPostPromisedData(`${templateBaseurl}/getAllTemplatesSearch/${page}`, { ...searchParams, channel: searchParams.channel?.value ?? '', templateCategoryId: searchParams.templateCategoryId?.value ?? '' })
    if (templateData?.code === 200 && templateData?.data?.messageTemplateList?.length > 0) {
      const updatedData = [...serverData, ...templateData.data?.messageTemplateList]
      setServerData(updatedData);
      getPaginatedData(updatedData, pageSize, pagination, setpagination, setLoader, setTableData)
      isNextCallPossible = templateData?.data?.length == 50 ? true : false;
    } else {
      isNextCallPossible = false;
      setLoader(false);
    }
  }

  const resetSearch = () => {
    setSearchParms(emplySearchParams);
    page = 0;
    isNextCallPossible = true;
    setUpdaterKey(new Date().getMilliseconds())

  }

  useEffect(() => {
    if (!searchParams.templateId && !searchParams.channel && !searchParams.templateName && !searchParams.templateCategoryId) {
      getTemplateHandler()
    }
  }, [searchParams])

  const handleSelectedTemplate = (rowObject) => {
    setSelectedTemplate(rowObject)
    setViewTemplate(true)
  }

  const selectedTemplateloseHandler = () => {
    setSelectedTemplate(null)
    setViewTemplate(false)
  }

  const getFileUrl = async (key, id) => {
    setDownloadingTemplate(id);
    const templateUrl = await fetchAllPostPromisedData(`${endpoint.fetchS3Url.getDownloadUrl}?key=${key}`, {})
    if (templateUrl?.code === 200 && templateUrl?.data) {
      window.open(`${templateUrl?.data?.url}`)
      setDownloadingTemplate(false);
    } else {
      failureToast("Something went wrong please try after sometime.")
    }
  }


  const getAllCategory = async () => {
    const category = await fetchAllPromisedData(endpoint.allCategory, false);
    if (Array.isArray(category)) {
      const tempData = category?.map(c => { return { label: c.templateCategoryType, value: c.id } })
      setCategoryData({ fetching: false, data: tempData })
    } else {
      setCategoryData({ fetching: false, data: [] })
    }
  }
  const getDisplayText = (text)=>{
    if(text && text.length>12)
    return  text.substring(0, 12) + '...';
    return text ;
  
   }
  useEffect(() => {
    getAllCategory();
    getDepartmentDisplay();
  }, [])
  const TableRow = ({ rowObject, children, handleSelectedTemplate = () => { }}) => {
    const department=departmentDisplay && departmentDisplay.find(i=>i.Key===rowObject['departmentName'])?.Display;
    const channels=rowObject['templateChannels'].map(item => { return item.channelName }).join(', ');
    return <tr key={`${rowObject['templateName']}-${rowObject?.['id']}-${getUniqueKey()}`}>
      {/* {
        listToBeRendered.map(item => {
          return <td key={`${rowObject['templateName']}-${rowObject?.['id']}-${getUniqueKey()}`} className="ellipse-text text-center">
            {
              isEqual(item, 'templateChannels')
                ?
                rowObject[item].map(item => { return item.channelName }).join(', ')
                : 
                isEqual(item,'updatedOn') ? moment(rowObject[item]).format('DD-MM-YYYY')
                : rowObject[item]
            }
          </td>
        })
      }
       */}
      <td key={`${rowObject['templateName']}-${rowObject?.['id']}-${getUniqueKey()}`} className="ellipse-text text-center">{rowObject['id']}</td>
      <td key={`${rowObject['templateName']}-${rowObject?.['id']}-${getUniqueKey()}`} id={`department-${rowObject?.['id']}`} className="text-center">
        <span style={{ display: 'inline-block' }}>{getDisplayText(department)||'--'}
        {(department?.length>12)&& <ToolTip content={department} target={`department-${rowObject?.['id']}`} maxWidth='250px' />}
        </span>
        </td>
      <td key={`${rowObject['templateName']}-${rowObject?.['id']}-${getUniqueKey()}`} id={`name-${rowObject?.['id']}`} className="text-center">
        <span style={{ display: 'inline-block' }}>{getDisplayText(rowObject['templateName'])}
          {(rowObject['templateName'].length>12)&&<ToolTip content={rowObject['templateName']} target={`name-${rowObject?.['id']}`} maxWidth='250px' />}
          </span>
      </td>
      <td key={`${rowObject['templateName']}-${rowObject?.['id']}-${getUniqueKey()}`} className="ellipse-text text-center"><span style={{ cursor: 'pointer' }} onClick={() => handleSelectedTemplate(rowObject)} >{rowObject['template']}</span></td>
      <td key={`${rowObject['templateName']}-${rowObject?.['id']}-${getUniqueKey()}`} id={`channels-${rowObject?.['id']}`} className="text-center">
        <span style={{ display: 'inline-block' }}>{getDisplayText(channels)}
        {(channels?.length>12)&& <ToolTip content={channels} target={`channels-${rowObject?.['id']}`} maxWidth='250px' />}
        </span>
        </td>
      <td key={`${rowObject['templateName']}-${rowObject?.['id']}-${getUniqueKey()}`} className="ellipse-text text-center">{moment(rowObject['updatedOn']).format('DD-MM-YYYY')}</td>
      <td key={`${rowObject['templateName']}-${rowObject?.['id']}-${getUniqueKey()}`} className="ellipse-text text-center">{rowObject['status']}</td>
      {children}
    </tr>
  }



  function actionFormatter(row) {
    const newObject = {};
    row.templateChannels.map(channel => {
      channel.messageTemplateS3List.map(links => {
        newObject[channel.channelName] = {
          ...newObject?.[channel.channelName], [links.destinationTypeMaster.notiDestType]: {
            ...newObject?.[channel.channelName]?.[links.destinationTypeMaster.notiDestType], [links.fileType]: links.templateS3Key
          }
        }
      })
    })
    return (
      <React.Fragment>
        {userPermissions && userPermissions.includes('R') && (
          <>
            <Link to={{ pathname: `create-template`, state: { action: 'view', id: row.id, onlyShowTemplate: true } }}>
              <Button color="primary" size="sm" type="button" id="view">
                <i className="fas fa-eye"></i>
              </Button>
              <ToolTip content={'View'} target="view" left="45%" top="-18px" />

            </Link>
          </>
        )}
        {userPermissions && userPermissions.includes('U') && (
          <>
            <Link to={{ pathname: `create-template`, state: { action: 'edit', id: row.id } }}>
              <Button color="primary" size="sm" type="button" style={{ margin: '0px 10px' }} id="edit">
                <i className="far fa-edit" />
              </Button>
            </Link>
            <ToolTip content={'Edit'} target="edit" left="45%" top="-18px" />
          </>
        )}
        {userPermissions && userPermissions.includes('T') && (
          <>
            <Link to={{ pathname: `create-template`, state: { action: 'test', id: row.id, onlyShowTemplate: true } }}>
              <Button color="primary" size="sm" type="button" style={{ margin: '0px 10px 0px 0px' }} id="test">
                <i className="fas fa-vial"></i>
              </Button>
            </Link>
            <ToolTip content={'Test'} target="test" left="50%" top="-18px" />
          </>
        )}
        {userPermissions && userPermissions.includes('D') && (
          <span>
            <Button
              color="primary"
              size="sm"
              type="button"
              onClick={() => handleTemplateView(row, 'askToDeleteTemplate')}
              id="delete"
            >
              <i className="far fa-trash-alt" />
            </Button>
            <ToolTip content={'Delete'} target="delete" left="65%" top="-18px" />
          </span>
        )}


        {/* this is only commented to merge it to UAT */}
        <UncontrolledDropdown
          className="me-2"
          direction="down"
          disabled={downloadingTemplate}
        >
          <DropdownToggle
            id="download"
            color="primary"
            style={{ padding: '3px 7px', marginLeft: '0px' }}
          >
            {downloadingTemplate && downloadingTemplate == row.id ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : <i className="fas fa-download" />}
            <ToolTip content={'Download'} target="download" left="65%" top="-18px" />

          </DropdownToggle>
          <DropdownMenu>
            {
              Object.keys(newObject).map((cname) => {
                return Object.keys(newObject[cname]).map(dtype => {

                  return <><DropdownItem className="dropdown-item" style={{ marginLeft: '0px !important', marginRight: '12px !important' }} >

                    <div style={{ display: 'flex', cursor: 'default' }}>{cname}( {dtype} ) &nbsp;
                      {
                        Object.keys(newObject[cname][dtype]).map((doc, i) => {
                          return <><div style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => getFileUrl(newObject[cname][dtype][doc], row.id)}>{doc}</div>&nbsp;&nbsp;
                            {i + 1 !== row.templateChannels?.length && <DropdownItem divider />}</>
                        })
                      }
                    </div>
                    {/* <div style={{ textDecoration: 'underline', cursor: 'pointer' }}>{objItem.fileType}</div></div>n onClick={() => getFileUrl(item.s3Key, row.id)} */}
                  </DropdownItem>

                  </>
                })
              })
            }
          </DropdownMenu>
        </UncontrolledDropdown>

      </React.Fragment>
    );
  }

  return (
    <div className="erpTableTemplateContainer">
      {/* <SimpleHeader parentName="Template Details" /> */}
      <TemplateViewModal
        templateModal={templateModal}
        templateData={templateData}
        purpose={modalPurpose}
        sureToDeleteTemplate={value => handleDeleteTemplate(value)}
        setTemplateModal={value => setTemplateModal(value)}
      />
      <Container className="mt 1" fluid>

        {/* <UncontrolledAlert color="info" isOpen={isAlert} toggle={onDismiss} className="alertBar">
          <strong>{alertMessage}!</strong>
        </UncontrolledAlert> */}
        <Row>
          <div className="col">
            <Card className="overflow-auto">
              <CardHeader className="flex justify-between">
                <h3 className="mb-0 floatLeft">Notification Templates</h3>
                <div>
                  {userPermissions && userPermissions.includes('C') && (
                    <Link to={{ pathname: `/admin/create-template`, action: 'create' }}>
                      <Button
                        className="btn add-button-circle"
                        color="primary"
                        size="sm"
                        type="button"
                      >
                        <i className="fa fa-plus" /> New Template
                      </Button>
                    </Link>
                  )}
                  {/* this is only commented to merge it to UAT */}
                  {userPermissions && userPermissions.includes('B') && (
                    <Button
                      className="btn add-button-circle"
                      color="primary"
                      size="sm"
                      type="button"
                      onClick={() => setBulkNotificationModal(true)}
                    >
                      <i className="fa fa-upload" /> Bulk Notification
                    </Button>
                  )}
                </div>
              </CardHeader>
              <NotificationTemplateSearchForm
                searchParams={searchParams}
                setSearchParms={setSearchParms}
                userPermissions={userPermissions}
                reset={resetSearch}
                search={getTemplateHandler}
                isFetching={loader}
                categoryData={categoryData}
              />
            </Card>
          </div>
        </Row>
        <Row className="mt-n2">
          {loader ? (
            <div className="col">
              <Card className=" w-100">
                <div
                  className="mx-auto text-center py-5 my-5 "
                  style={{ height: '100vh' }}
                >
                  <CustomLoader apiLoader={loader} />
                </div>
              </Card>
            </div>
          ) : (
            <>

              <div className="col">
                <Card className="overflow-auto">
                  <CardHeader className="border-0">
                    <h3 className="mb-0 floatLeft">Search Results</h3>
                  </CardHeader>
                  <Table className="align-items-center tableLayout">
                    <MasterHeaderAuto headerList={headerList} />
                    <tbody className="list" key={'table-message-template'}>
                      {tableData.map((el) => {
                        return <TableRow rowObject={el} key={getUniqueKey()} handleSelectedTemplate={handleSelectedTemplate} setSelectedTemplate={setSelectedTemplate}>
                          <td colSpan={2} className="text-center">{actionFormatter(el)}</td>
                        </TableRow>
                      })}
                    </tbody>
                  </Table>
                  {pagination.totalPage > 1 ? (
                    <GetPagination
                      isSaveVisible={false}
                      setclassArray={setTableData}
                      pagination={pagination}
                      setpagination={setpagination}
                      pageSize={pageSize}
                      state={serverData}
                      isPaginationFromServer={true}
                      fetchNext={fetchNext}
                      isFetching={loader}
                      currentPage={tableData}
                      isNextCallPossible={isNextCallPossible}
                    />
                  ) : null}
                </Card>
              </div>
            </>
          )}
        </Row>
      </Container>
      <Dialog
        isModalVisible={viewTemplate}
        title={selectedTemplate?.templateName}
        toggle={selectedTemplateloseHandler}
        size="lg"
        backdrop={true}
        isCloseButtonRequired={true}
      >
        {
          selectedTemplate?.templateChannels?.filter(item => item?.channelName?.toLowerCase() === 'email')?.[0]?.isHtmlEnabled ?
            <iframe srcDoc={selectedTemplate?.template} className="popup-preview messageTextArea" id="preview" frameBorder={0} height={500} />
            :
            <div
              id="preview"
              className="popup-preview messageTextArea"
              dangerouslySetInnerHTML={{ __html: `<p>${selectedTemplate?.template?.replace(/\\n/g, '<br />')?.replace(/(?:\r\n|\r|\n)/g, '<br>')}</p>` }}
            />
        }
      </Dialog>
      <Dialog
        isModalVisible={bulkNotificationModal}
        title={'Bulk Notification'}
        toggle={() => setBulkNotificationModal(false)}
        size="lg"
        backdrop={true}
        isCloseButtonRequired={false}
      >
        <NotificationBulkUpload userPermissions={userPermissions} setOpenBulkUpload={setBulkNotificationModal} />
      </Dialog>
    </div>
  );
}

export default ErpTableTemplate2;
