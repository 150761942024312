import React, { createContext, useState } from "react";
import {
  useDefaultList,
  useFetchDropdown,
  useFetchPendingRfIdList,
  useStudentApplicationList,
} from "../customhooks";
import { lastDays } from "views/pages/manage/academicPlanAndTest/academicTimetable/helper";

export const PAGE_STATE = {
  SEARCH_STUDENT: "SEARCH_STUDENT",
  PRINT_ID_CARD: "PRINT_ID_CARD",
  MAP_RFID: "MAP_RFID",
};

export const IssuanceIDCardContext = createContext({
  currentPageState: "SEARCH_STUDENT",
  setCurrentPageState: () => { },
  loading: false,
  setLoading: () => { },
  studentApplications: [],
  setStudents: () => { },
  filterForm: {},
  setFilterForm: () => { },
});

export const FILTER__FORM_FIELDS = {
  businessArea: "",
  sortingField: "REGISTRATION_DATE",
  term: [],
  batchIds: [],
  academicGroup: [],
  courseId: [],
  typeOfCheckbox: "psid",
  userInput: "",
  startDate: '',
  endDate: '',
  batchType: "PRIMARY",
};

export const FILTER__FORM_FIELDS_GLOBAL = {
  sortingField: "REGISTRATION_DATE",
  typeOfCheckbox: "psid",
  userInput: "",
  batchType: "PRIMARY",
};
export const IssuanceIDCardProvider = ({ children, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [listCurrentPage, setListCurrentPage] = useState(0);
  const [pendingRfIdPage, setPendingRfIdPage] = useState(0);

  // global search 
  const [globalFilter, setGlobalFilter] = useState({
    ...FILTER__FORM_FIELDS_GLOBAL,
  });
  // this is secondary filter ;

  const [filterForm, setFilterForm] = useState({
    ...FILTER__FORM_FIELDS,
  });
  const [dropdown, dropdownLoader] = useFetchDropdown();
  const [currentPageState, setCurrentPageState] = useState(
    PAGE_STATE.SEARCH_STUDENT
  );

  // this will fetch the list on the basis of applied filter ; 
  const [
    studentApplications,
    isApplicationLoading,
    getApplications,
    totalPage,
    totalRecords,
    resetStudentApplications
  ] = useStudentApplicationList(dropdown?.businessAreas);

  // fetch last 31 days data where rfId is not mapped
  const [lists, isListLoading, getList, listTotalPage, listTotalRecords] = useDefaultList(
    { ...FILTER__FORM_FIELDS, startDate: lastDays() },
    dropdown?.businessAreas,
    currentPageState
  );

  // on click of right side notification bar 
  const [
    pendingRfIds,
    getPendingRfIds,
    totalPendingRfIdPages,
    totalPendingRfIs,
  ] = useFetchPendingRfIdList(dropdown?.businessAreas, setLoading);


  const [printedIdCardArray, setPrintedIdCardArray] = useState([]);
  const postPrintHandler = async (rowArray) => {
    setCurrentPageState(PAGE_STATE?.MAP_RFID);
    setPrintedIdCardArray(rowArray);
  };

  const refreshPrintedIdArray = (newDataArray) => {
    setPrintedIdCardArray(prev => prev?.map(item => newDataArray?.find(updatedItem => updatedItem?.applicationId === item?.applicationId)))
  }

  const [hideStepperBarRfidPage, setHideStepperBarRfidPage] = useState(false);

  return (
    <IssuanceIDCardContext.Provider
      value={{
        loading,
        setLoading,
        currentPageState,
        setCurrentPageState,
        filterForm,
        setFilterForm,
        dropdown,
        dropdownLoader,

        studentApplications,
        isApplicationLoading,
        page,
        setPage,
        getApplications,
        resetStudentApplications,
        totalPage,
        totalRecords,
        printedIdCardArray,
        setPrintedIdCardArray,
        postPrintHandler,

        globalFilter,
        setGlobalFilter,

        lists,
        isListLoading,
        getList,
        listTotalPage,
        listTotalRecords,
        listCurrentPage,
        setListCurrentPage,

        pendingRfIds,
        getPendingRfIds,
        totalPendingRfIdPages,
        totalPendingRfIs,
        pendingRfIdPage,
        setPendingRfIdPage,
        hideStepperBarRfidPage,
        setHideStepperBarRfidPage,

        refreshPrintedIdArray,

        ...props,
      }}
    >
      {children}
    </IssuanceIDCardContext.Provider>
  );
};
