import React, { useState } from "react";
import { withRouter } from "react-router";
import { Button, Card, CardBody, Input, Table } from "reactstrap";

import moment from "moment";
import {
  masterServiceBaseUrl,
  failureToast,
  putpost,
  putpost2,
  successToast,
} from "services/http";
import { MasterHeaderAuto } from "../../../common/commonComponents/masterHeader/masterHeader";
import {
  CustomCard,
  CustomContainer,
  ToExcel,
} from "views/pages/manage/common/commonComponents";
import { getObjectById } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint, commonMessages } from '../../../common/constant';
import { customValidation, isValidIpAddress, isValidMacAddress } from '../../../common/utils/methods/validations/validation'

import BuildingForm from "./attendanceMachineForm";
import BuildingRow from "./attendanceMachineRow";
import validationRules from "./validationRule.json"

const excelDetails = {
  "Business Group": "businessGroup",
  "Comapany Code": "companyCode",
  Zone: "zone",
  Region: "region",
  "Business Area": "businessArea",
  "Building Name": "building",
  "RFID Machine ID":"rfidMachineId",
  "Machine Name":"machineName",
  "RFID Machine Vender":"rfidMachineVendor",
  "Machine Type":"machineType",
  "IP Address":"ipAddress",
  "MAC Address":"macAddress",
  "Status": "status"
};

const Cardheader = ({
  isSaveVisible = false,
  newRow = () => {},
  serverData,
  headers,
  getDisplayVal = () => {},
  buildingData =[],
  machineTypeData=[],
  setIsSaveVisible = () => {},
}) => {
  const dataToExport = serverData?.map((item) => {
    return {
      ...item,
      businessArea: headers?.businessArea?.label,
      businessGroup: headers?.businessGroup?.label,
      companyCode: headers?.companyCode?.label,
      region: headers?.region?.label,
      zone: headers?.zone?.label,
      building : getDisplayVal(item.buildingMasterId, buildingData)?.label ?? "",
      machineType : getDisplayVal(item.machineTypeId, machineTypeData)?.label ?? "",
    };
  });

  return (
    <>
      <h3>Attendance Machine Master</h3>
      <div className="d-flex flex-row  justify-content-between">
        {!isSaveVisible || serverData.length ? (
          <ToExcel
            data={dataToExport}
            detailObj={excelDetails}
            name="Attendance Machines"
            isSaveVisible={isSaveVisible}
          />
        ) : null}
        <Button
          disabled={isSaveVisible}
          color="info"
          size="sm"
          type="button"
          className={
            "floatRight mx-1 ml-2" + (isSaveVisible ? " btn-dark" : null)
          }
          onClick={() => {
            newRow();
            setIsSaveVisible(true);
          }}
        >
          Add New
        </Button>
      </div>
    </>
  );
};

var _ = require("lodash");
const AttendanceMachineTable = (props) => {
  const {
    fetchClasses,
    setclassArray,
    classArray,
    serverData,
    isSaveVisible,
    setIsSaveVisible = () => {},
    buildingData=[],
    machineTypeData=[],
    activeBuildingData=[],
    activeMachineTypeData=[]
  } = props;

  const [attendanceMachineData, setAttendanceMachineData] = useState({
    buildingId: "",
    rfidMachineId: "",
    machineName: "",
    rfidMachineVendor: "",
    machineTypeId: "",
    ipAddress: "",
    macAddress: "",
    status: "ACTIVE",
    effectiveDate: ""
  });

  // const [isSaveVisible, setIsSaveVisible] = useState(false);

  const addNewClass = () => {
    setclassArray([
      {
        buildingId: "",
        rfidMachineId: "",
        machineName: "",
        rfidMachineVendor: "",
        machineTypeId: "",
        ipAddress: "",
        macAddress: "",
        status:"ACTIVE",
        isEdit: false,
        isNew: true,
        "__status": "__new"
      },
      ...classArray,
    ]);
  };

  const headerList = [
    { name: "Building", isRequired: true },
    { name: "RFID Machine ID", isRequired: true },
    { name: "Machine Name", isRequired: true },
    { name: "RFID Machine Vender", isRequired: true },
    { name: "Machine Type", isRequired: true },
    { name: "IP Address", isRequired: true },
    { name: "MAC Address", isRequired: true },
    { name: "Effective Start Date" },
    { name: "Status" },
    { name: "Action" },
  ];

  const newRow = () => {
    setIsSaveVisible(true);
    let cb = () => {
      addNewClass();
      setIsSaveVisible(true);
    };
    let _tempArray = classArray;
    _tempArray = _.sortBy(_tempArray, [
      function (o) {
        return o.value;
      },
    ]);
    setclassArray(_tempArray);
    // setIsSaveVisible(false);
    setIsSaveVisible(false);
    cb();
  };

  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast(commonMessages.pleaseDeleteSaveCurrentChnage);
    } else {
      setIsSaveVisible(true);
      let tempData = classArray;
      tempData[index]["isEdit"] = true;
      setAttendanceMachineData({
        buildingId: getDisplayVal(tempData[index]["buildingMasterId"],buildingData),
        rfidMachineId: tempData[index]["rfidMachineId"],
        machineName: tempData[index]["machineName"],
        rfidMachineVendor: tempData[index]["rfidMachineVendor"],
        machineTypeId: getDisplayVal(tempData[index]["machineTypeId"],machineTypeData),
        ipAddress: tempData[index]["ipAddress"],
        macAddress: tempData[index]["macAddress"],
        status: tempData[index]["status"],
        effectiveDate: moment(tempData[index]["effectiveDate"]).format("DD-MM-YYYY")
      });
    }
  };

  const deleteRow = (index) => {
    if (isSaveVisible) {
      failureToast(commonMessages.pleaseDeleteSaveCurrentChnage);
    } else {
      let tempData = classArray;
      let id = tempData[index]["id"];

      // id is used when api ready

      if (window.confirm("Are you sure you want to delete this Attendance Machine?")) {
        putpost2(
          `${endpoint.attendance_machine.delete}/${id}`,
          (data) => {
            successToast(data.message);
            props.fetchClasses();
            resetFields();
          },
          (data) => {
            failureToast(data.message);
          },
          "Delete"
        );
      }
    }
  };

  const saveRow = (index) => {
    let tempData = classArray;
    let isValid = true;
    
    isValid = customValidation(attendanceMachineData,validationRules)
   
    if (isValid && attendanceMachineData.ipAddress) {
      isValid = isValidIpAddress(attendanceMachineData.ipAddress);
    }
    
    if (isValid && attendanceMachineData.macAddress) {
      isValid = isValidIpAddress(attendanceMachineData.ipAddress);;
    } 
    
    if(!isValid){
      return 
    }else {
      if (tempData[index]["isEdit"]) {
        let tempObject = {
          ...tempData[index],
          buildingMasterId: attendanceMachineData.buildingId?.value,
          ipAddress: attendanceMachineData.ipAddress,
          macAddress: attendanceMachineData.macAddress,
          machineName: attendanceMachineData.machineName,
          machineTypeId: attendanceMachineData.machineTypeId?.value,
          rfidMachineId: attendanceMachineData.rfidMachineId,
          rfidMachineVendor: attendanceMachineData.rfidMachineVendor,
          status: attendanceMachineData.status,
        };
        tempData[index] = tempObject;
        let id = tempData[index]["id"];

        putpost(
          `${endpoint.attendance_machine.update}/${id}`,
          (data) => {
            successToast(data.message);
            props.fetchClasses();
            setIsSaveVisible(false);
            resetFields();
          },
          (data) => {
            failureToast(data.message);
          },
          tempObject,
          "put"
        );
      } else if (tempData[index]["isNew"]) {
        // putPostApi(`${concessionServiceBaseUrl}/addSubCategory`,tempObject,'post');
        // setIsSaveVisible(false);
        // resetFields()
        let tempObject = {
          attendanceMachineMasterDTO: {
            buildingMasterId: attendanceMachineData.buildingId?.value,
            ipAddress: attendanceMachineData.ipAddress,
            macAddress: attendanceMachineData.macAddress,
            machineName: attendanceMachineData.machineName,
            machineTypeId: attendanceMachineData.machineTypeId?.value,
            rfidMachineId: attendanceMachineData.rfidMachineId,
            rfidMachineVendor: attendanceMachineData.rfidMachineVendor,
            status: attendanceMachineData.status,
          },
          attendanceMachineHeaderDTO: {
            businessAreaId: props.headers?.businessArea?.value,
            businessGroupId: props.headers?.businessGroup?.value,
            companyCodeId: props.headers?.companyCode?.value,
            regionId: props.headers?.region?.value,
            zoneId: props.headers?.zone?.value,
            status:"ACTIVE"
          },

          isEdit: false,
          isNew: false,
        };

        putpost(
          endpoint.attendance_machine.create,
          (data) => {
            successToast(data.message);
            props.fetchClasses();
            setIsSaveVisible(false);
            resetFields();
          },
          (data) => {
            failureToast(data.message);
          },
          tempObject,
          "post"
        );

        // putPostApi(`${concessionServiceBaseUrl}/addSubCategory`,tempObject,'post');
      }
    }
  };

  const cancelRow = (index) => {
    let tempData = classArray;
    if (tempData[index]["isEdit"]) tempData[index]["isEdit"] = false;
    else if (tempData[index]["isNew"]) tempData.splice(index, 1);
    setclassArray(tempData);
    setIsSaveVisible(false);
    resetFields();
  };

  const resetFields = () => {
    setAttendanceMachineData({
      buildingId: "",
      rfidMachineId: "",
      machineName: "",
      rfidMachineVendor: "",
      machineTypeId: "",
      ipAddress: "",
      macAddress: "",
      status: "ACTIVE",
      effectiveDate: ""
    });
  };

  const getDisplayVal = (id, list) => {
    const i =
      id &&
      list?.filter((item) => {
        return item.id == id;
      });
    return i && i?.length ? i[0] : "";
  };

  return (
    <CustomContainer>
      <CustomCard
        HeaderElement={
          <Cardheader
            isSaveVisible={isSaveVisible}
            setIsSaveVisible={setIsSaveVisible}
            newRow={newRow}
            headers={props.headers}
            serverData={serverData}
            buildingData={buildingData}
            machineTypeData={machineTypeData}
            getDisplayVal={getDisplayVal}
          />
        }
      >
        <div id="questionTable" className="table-responsive-layout p-3">
          <Table className="align-items-center table-flush table-layout">
            <MasterHeaderAuto headerList={headerList} />
            <tbody>
              {classArray.map((data, index) =>
                data["isEdit"] || data["isNew"] ? (
                  <BuildingForm
                    key={"NewOrEditRow" + index}
                    attendanceMachineData={attendanceMachineData}
                    setAttendanceMachineData={setAttendanceMachineData}
                    data={data}
                    index={index}
                    saveRow={saveRow}
                    cancelRow={cancelRow}
                    getDisplayVal={getObjectById}
                    isSaveVisible={isSaveVisible}
                    setIsSaveVisible={setIsSaveVisible}
                    isEditForm={data["__status"] !== "__new" ? true : false}
                    {...props}
                  />
                ) : (
                  <BuildingRow
                    key={"TDROW" + index}
                    data={data}
                    index={index}
                    history={props.history}
                    deleteRow={deleteRow}
                    editRow={editRow}
                    isSaveVisible={isSaveVisible}
                    getDisplayVal={getObjectById}
                    {...props}
                  />
                )
              )}
            </tbody>
          </Table>
        </div>
      </CustomCard>
    </CustomContainer>
  );
};

export default withRouter(AttendanceMachineTable);
