import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import AInput from "views/pages/manage/common/formFeilds/customInput/AInput";
import { ReactComponent as UserIcon } from "assets/img/svg/3-user.svg";
import GateAttendanceDetailCard from "views/pages/manage/gateAttendance/GateAttendanceDetailCard";
import {
  isNumber,
  isAlphabet,
  getStudentApplications,
} from "views/pages/manage/gateAttendance/constant";
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import GateAttendanceStudentList from "views/pages/manage/gateAttendance/Gate AttendanceStudentList";
import ALoader from "views/pages/manage/common/a-loader";
import BusinessAreaSelector from "views/pages/manage/gateAttendance/BuisnessAreaSelector";
import { endpoint } from "views/pages/manage/common/constant";
import { formateResponse } from "./constant";
import { fetchAllPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { TablePagination } from "@mui/material";


const GateAttendance = () => {
  const [dropdownData, setDropdownData] = useState({});
  const [loader, setLoader] = useState(false);
  const [studentList, setStudentList] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [isStudentDetailVisible, setStudentDetailVisible] = useState(false);
  const [studentData, setStudentData] = useState({});
  const [selectedBusinessArea, setSelectedBusinessArea] = useState();
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const history = useHistory();

  useEffect(async () => {
    setLoader(true);
    const data = await getBusinessArea();
    const updatedResponse = await data.map(formateResponse);
    setDropdownData(updatedResponse);
    setLoader(false);
  }, []);

  const getBusinessArea = async () => {
    const data = await fetchAllPromisedData(
      endpoint.businessArea.getAllActive,
      false
    );
    return data;
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      applySearchHandler();
    }
  };
  
  const applySearchHandler = async (page=0) => {
    const request = {
      businessArea: selectedBusinessArea
        ? [selectedBusinessArea?.id]
        : [dropdownData[0]?.id],
      region: [],
      psid: isNumber(searchValue) ? searchValue.trim() : "",
      studentName: isAlphabet(searchValue) ? searchValue.trim() : "",
    };
    if (request?.psid || request?.studentName) {
      setLoader(true);
      const data = await getStudentApplications(request, page);
      if (data?.code === 200)
        setStudentList(
          data?.data?.studentApplicationDetailsSearchResponseDTOList
        );
        if(data?.data?.studentApplicationDetailsSearchResponseDTOList.length==1){
          setStudentData(data?.data?.studentApplicationDetailsSearchResponseDTOList[0])
          setStudentDetailVisible(true)
        }
        if(data?.data?.studentApplicationDetailsSearchResponseDTOList?.length===0)
          failureToast("No matching student found")
        setTotalRecords(data?.data?.totalRecords)
      setLoader(false);
    } else {
      failureToast("Enter valid psid or name");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    applySearchHandler(newPage)
  };
  return (
    <>
      {loader && <ALoader position="fixed" />}
      {!isStudentDetailVisible ? (
        <div className="min-page-height attendance-gate-tab-margin attendance-gate-mobile-margin" >
          <div
          className="w-100"
            style={{
              backgroundColor: "#00B0F5",
              height: "227px",
              padding: "16px",
              borderBottomLeftRadius: !(studentList && studentList.length)
                ? "40px"
                : "",
              borderBottomRightRadius: !(studentList && studentList.length)
                ? "40px"
                : "",
            }}
          >
            <BusinessAreaSelector
              dropdownData={dropdownData}
              selectedBusinessArea={selectedBusinessArea}
              setSelectedBusinessArea={setSelectedBusinessArea}
            />
            <div
              className="mt-5 psid-search"
              style={{
                color: "#E5F6FF",
                fontSize:'20px',
                fontFamily: "Gilroy-Bold",
                fontWeight: "400",
              }}
            >
              Search PSID/ Name
            </div>

            <AInput
              placeholder={"Enter 11 digit “PSID” or “name”"}
              style={{ height: "42px", marginTop: "4px" }}
              value={searchValue}
              handler={(value) => setSearchValue(value)}
              onKeyDown={(e) => {
                onKeyDown(e);
              }}
              crossIcon={true}
              rightSearchIcon={true}
              crossHandler={async () => setSearchValue("")}
              searchIconClickHandler={() => {
                applySearchHandler();
              }}
            />
          </div>
          {studentList && studentList.length ? (
            <div>
              {studentList?.map((data, i) => (
                <div
                className="cursor"
                  onClick={() => {
                    setStudentData(data);
                    setStudentDetailVisible(true);
                  }}
                >
                  <GateAttendanceStudentList
                    key={i}
                    studentInfo={data}
                    index={i}
                  />
                </div>
              ))}
              <TablePagination
                component="div"
                count={totalRecords}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={50}
                rowsPerPageOptions={[50]}
                sx={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
              />
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-center align-items-center mt-7">
                <UserIcon />
              </div>
              <div
                className="mt-3 text-center gilroy-medium color-grey-60"
                style={{
                  fontSize: 12,
                }}
              >
                Student details will be shown here
              </div>
            </>
          )}
        </div>
      ) : (
        <GateAttendanceDetailCard
          studentData={{
            ...studentData,
            businessAreaDesc: selectedBusinessArea?.businessAreaDesc,
          }}
          fetchApplication={getStudentApplications}
          setStudentDetailVisible={setStudentDetailVisible}
        />
      )}
    </>
  );
};
export default GateAttendance;
