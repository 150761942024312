import React from "react";
import { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { MdCancel } from "react-icons/md";

import { getLectureById } from "./../../lecture/helper";
import ALoader from "views/pages/manage/common/a-loader";
import TreeViewByList from "./../../lecture/treeView/TreeViewList";
import { Card,CardBody } from "reactstrap";

const ViewLectureModal = ({ open, setOpen, lectureId, setLectureId }) => {

  const [loading, setLectureLoading] = useState(false);
  const [lecture, setLecture] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setLectureId(null);
  };

  useEffect(() => {
    if (lectureId) fetchLecture(lectureId);
  }, [lectureId]);

  const fetchLecture = (lectureId) => {
    setLectureLoading(true);
    getLectureById(lectureId)
      .then((res) => {
        if (res) setLecture(res);
        setLectureLoading(false);
      })

      .catch((error) => {
        setLectureLoading(false);
      });
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <div
        style={{
          padding: "1.5rem",
          minWidth: "500px",

        }}
      >
        <div
          style={{
           cursor:"pointer",
            fontSize: "1.8rem",
            marginBottom: "10px",
            color: "black",
            position: "absolute",
            top: "0",
            right: "0px",
            zIndex:2
          }}
        >
          <MdCancel onClick={handleClose} />
        </div>
        <Card>
          <CardBody>
            {loading && <ALoader />}
            <TreeViewByList counter={1} subjectChapters={lecture} />
          </CardBody>
        </Card>
      </div>
    </Dialog>
  );
};

export default ViewLectureModal;
