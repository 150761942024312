import React from "react";
import {
  Card,
  CardHeader,
  Input,
  Container,
  Button,
  Table,
  Row,
} from "reactstrap";
import { useState } from "react";
import { failureToast } from "../../common/utils/methods/toasterFunctions/function";
import {
  TDROW,
  MasterHeader,
} from "../../common/commonComponents/masterRow/masterRow";
import {
  masterServiceBaseUrl,
  successToast,
  putpost,
  putpost2,
} from "services/http";
import moment from "moment";
import CustomButton from "../../../../../components/CustomButton";
import { ButtonText } from "variables/Buttons";
import { customValidation } from "../../common/utils/methods/validations/validation";
import validationRules from "./validation.json";
import {
  fetchAllPostPromisedData,
  fetchDeletePromisedData,
  getCurrentDate,
} from "../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "../../common/constant";
import {
  editTableRow,
  newTableRow,
} from "../../common/utils/methods/commonMethods/tableMethods";
import { MasterHeaderAuto } from "../../common/commonComponents/masterHeader/masterHeader";

var _ = require("lodash");

const JobTitleTable = (props) => {
  const {
    permissionSet,
    isSaveVisible,
    setisSaveVisible = () => {},
    getAllrecords = () => {},
  } = props;
  const [tableDataKey, settableDataKey] = useState("tableDataKey");
  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: "__new",
        jobTitleKey: null,
        status: "ACTIVE",
        jobTitleDesc: null,
        jobTitleDispValue: null,
        effectiveDate: null,
      },
      ...props.classArray,
    ]);
  };

  const headerList = [
    { name: "Job Title KEY" },
    { name: "Effective Date" },
    { name: "Description" },
    { name: "Display Value" },
    { name: "Status" },
    { name: "Action" },
  ];

  const saveAll = async (index) => {
    let _data = props.classArray[index];
    // let alphanumericSpecialChar = /^[ A-Za-z0-9_@./#&+-]*$/
    let isValid = true;
    isValid = customValidation(_data, validationRules);

    if (!isValid) return;

    if (_data["__status"] == "__new") {
      const createRecord = await fetchAllPostPromisedData(
        endpoint.jobTitle.create,
        _data,
        "post"
      );
      if (createRecord?.code === 200) {
        successToast(createRecord["message"]);
        setisSaveVisible(false);
        getAllrecords();
      }
    } else {
      const updateRecord = await fetchAllPostPromisedData(
        `${endpoint.jobTitle.update}/${_data.id}`,
        _data,
        "put"
      );
      if (updateRecord?.code === 200) {
        successToast(updateRecord["message"]);
        setisSaveVisible(false);
        getAllrecords();
      }
    }
  };

  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      newTableRow(
        props.classArray,
        props.setclassArray,
        setisSaveVisible,
        addNewClass
      );
    }
  };

  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      editTableRow(
        index,
        props.classArray,
        props.setclassArray,
        setisSaveVisible
      );
    }
  };

  const deleteRow = async (index) => {
    if (window.confirm("Are you sure you want to delete this Job Title?")) {
      const deleteRecord = await fetchDeletePromisedData(
        `${endpoint.jobTitle.delete}/${props.classArray[index].id}`
      );
      if (deleteRecord?.code === 200) {
        successToast(deleteRecord["message"]);
        let flag = false;
        if (props.classArray.length == 1) {
          flag = true;
        }
        getAllrecords(flag);
        settableDataKey(new Date().getMilliseconds());
      }
    }
  };

  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
  };
  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Job Title</h3>
                <CustomButton
                  data-testid="addNewDepartmentType"
                  disabled={isSaveVisible}
                  className={
                    "floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)
                  }
                  content={ButtonText.JobTitle.NEW}
                  icon={true}
                  permissionType={"C"}
                  permissionSet={permissionSet}
                  onClick={() => newRow()}
                />
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout">
                  <MasterHeaderAuto
                    headerList={headerList}
                    permissionSet={permissionSet}
                    isSaveVisible={isSaveVisible}
                  />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                          !el["editMode"] ? (
                            <TDROW
                              type={"jobTitle"}
                              index={index}
                              el={el}
                              editRow={editRow}
                              deleteRow={deleteRow}
                              permissionSet={permissionSet}
                            />
                          ) : (
                            <tr key={index + "-class"}>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength={50}
                                  defaultValue={el["jobTitleKey"]}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      "jobTitleKey"
                                    );
                                  }}
                                  placeholder="Example:1"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  disabled={true}
                                  defaultValue={
                                    el["__status"] == "__new"
                                      ? getCurrentDate()
                                      : moment(el["effectiveDate"]).format(
                                          "DD-MM-YYYY"
                                        )
                                  }
                                  placeholder="Example:01-01-2020"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength={50}
                                  defaultValue={el["jobTitleDesc"]}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      "jobTitleDesc"
                                    );
                                  }}
                                  placeholder="Description"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength={50}
                                  defaultValue={el["jobTitleDispValue"]}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      "jobTitleDispValue"
                                    );
                                  }}
                                  placeholder="Display Value"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <label className="custom-toggle mx-auto ml-2">
                                  <input
                                    checked={
                                      el.status == "ACTIVE" ? true : false
                                    }
                                    type="checkbox"
                                    id={el.id}
                                    onChange={(e) => {
                                      settableDataKey(
                                        new Date().getMilliseconds()
                                      );
                                      let n = "ACTIVE";
                                      if (!e.target.checked) {
                                        n = "INACTIVE";
                                      }
                                      updateKey(index, n, "status");
                                    }}
                                  />
                                  <span
                                    style={{ width: "72px" }}
                                    className="custom-toggle-slider rounded-circle activeToggle"
                                    data-label-off="Inactive"
                                    data-label-on="Active"
                                  />
                                </label>
                              </td>
                              <td className="text-center">
                                {/* <Button color="info" size="sm" type="button" onClick={() => {
                          deleteRow(index)
                        }}>  <i className="fas fa-trash" /></Button> */}
                                {isSaveVisible ? (
                                  <>
                                    <CustomButton
                                      data-testId="saveDepartmentType"
                                      className={"mx-1"}
                                      content={ButtonText.JobTitle.UPDATE}
                                      forTable={true}
                                      permissionType={"C,U"}
                                      permissionSet={permissionSet}
                                      onClick={() => {
                                        saveAll(index);
                                      }}
                                    />

                                    <CustomButton
                                      data-testId="closedDepartmentType"
                                      className={"mx-1"}
                                      permissionType={"cancel"}
                                      forTable={true}
                                      icon={true}
                                      permissionSet={permissionSet}
                                      onClick={() => {
                                        let n = props.classArray;
                                        if (n[index]["__status"] == "__new") {
                                          n.splice(index, 1);
                                        } else {
                                          // n[index]['editMode']=false
                                          getAllrecords();
                                        }
                                        setisSaveVisible(false);
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default JobTitleTable;
