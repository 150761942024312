import React from "react";
import { Row } from "reactstrap";
import { Col } from "reactstrap";
import CustomDatePickerV2 from "./../../common/commonComponents/CustomDatePicker-V2/index";
import  AAutoComplete  from 'views/pages/manage/common/form-fields-mui/AAutoComplete';
import  moment  from 'moment';
import { maxDate31Days } from "./helper";

const DateRangeForm = ({form, formHandler , roomsDropdown=[], daysPlanDropdown = []
}) => {
  return (
    <div className="mb-4 w-100" >
      <Row>
        <Col className="text-center" md={12}>
          <span className="regular-large-bold color-dark mb-2">
            Select date range for timetable
          </span>
        </Col>
      </Row>
      <Row>
          <Col md  ={12}>
          <AAutoComplete
            height="42px"
            currentValue={form?.daysPlan}
            handleChange  = {(value)=>formHandler(value,'daysPlan')}
            fontSize="13px"
            isMulti ={false}
            label="Days plan"
            placeholder="Select days plan"
            items={daysPlanDropdown}
            labelStyle = {{fontWeight:"600" , color: "#262626"}}
            />
          </Col>
        </Row>
      <Row className = "mt-2">
        <Col md={6}>
          <CustomDatePickerV2 
          label="Start from"
           placeHolder=" Start from"
           value = {form.startFrom}
           handler={(index, date) => formHandler(date, "startFrom")}
           inputFontSize = "13px"
           height = "52px"
           minDate = {new moment(new Date())}
           maxDate = {maxDate31Days()}
            />
        </Col>
        <Col md={6}>
          <CustomDatePickerV2 
           label="End From" 
           placeHolder=" Start date"
           value = {form.endOn}
           minDate={form?.startFrom ||new moment(new Date())}
           handler={(index, date) => formHandler(date, "endOn")}
           height = "52px"
           maxDate = {maxDate31Days()}

             />
        </Col>
        </Row>

        <Row>
          <Col md  = {12}>
          <AAutoComplete
            height="42px"
            currentValue={form?.room}
            handleChange  = {(value)=>formHandler(value,'room')}
            fontSize="13px"
            isMulti ={false}
            label="Room"
            placeholder="Select room"
            items={roomsDropdown}
            labelStyle = {{fontWeight:"600" , color: "#262626"}}
            />
          </Col>
        </Row>
    </div>
  );
};

export default DateRangeForm;
