import React, { useState } from "react";
import { Pagination } from "@mui/material";

const ServerPagination = ({ nextHandler , totalPages, currentPage }) => {

  const onChangeHandler = (event, page) => {
    nextHandler(page);
  };

  return (
    <nav aria-label="..." className="d-flex justify-content-end">
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={onChangeHandler}
        variant="outlined"
      />
    </nav>
  );
};

export default ServerPagination;
