import { putpost } from "../../../../../../services/utils";
import { endpoint } from "../../../common/constant";
import { fetchAllPostPromisedData, fetchAllPromisedData, updateRequest } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { failureToast } from "../../../common/utils/methods/toasterFunctions/function";




export const downloadStudentList = async(filter ={},setDownloading =() => {}, pageNo = 0, printedData = []) =>{
    setDownloading(true)
    
    const keyData =   await fetchAllPostPromisedData(`${endpoint.studentSearch.downloadCsvForRfIdMapping}=${pageNo}`, updateRequest(filter))
    const url = keyData && keyData.data.key ;
    if(url){
        putpost(`${endpoint.fetchS3Url.getDownloadUrl}?key=${url}`,
            (data) => {
                 
                if (data.code === 200 && data.data) {
                    window.open(`${data.data.url}`)
                    setDownloading(false)
                } else {
                    setDownloading(false)
                    failureToast("Sorry Something went wrong")
                    // failureToast(data.message)
                }
            },
            (data) => {
                
                setDownloading(false)
                failureToast("Sorry Something went wrong")
            },
            {},
            "post"
        );
    }else{
        
        failureToast(keyData.failureReason ?? "Sorry Something went wrong")
        setDownloading(false)
    }
}

export const getUploadedCsvs = async (setUploadedFiles)=>{
    const response =       await fetchAllPromisedData(endpoint.studentSearch.getUploadedCSVlist) ;
     if(response)
     setUploadedFiles(response)

}

export const openFileInNewWindow = (key)=>{
    putpost(`${endpoint.fetchS3Url.getDownloadUrl}?key=${key}`,
        (data) => {
            if (data.code === 200 && data.data) {
                window.open(`${data.data.url}`)
            } else {
                failureToast("Sorry Something went wrong")
            }
        },
        (data) => {
            failureToast("Sorry Something went wrong")
            // failureToast(data["message"]);
        },
        {},
        "post"
    );
  }


  export const getPrintedApplicationIds = (list = []) => list.map(item=>item?.applicationId)
        

