import React, { useContext, useEffect  } from "react";
import { useHistory } from "react-router";

import StudentList from "./studentList";
import { IssuanceIDCardContext, IssuanceIDCardProvider } from "./contextApi";
import PrintIdCardAndMapRfid from "./printIdCardAndMapRfid/PrintIdCardAndMapRfid";
import { pages } from "../../common/constant";
import { PermissionContext, RolePermissions } from 'appContext';
import { PermisionDenied } from "../../common/commonComponents";
import { PAGE_STATE } from "./contextApi";
import { pendingRfIdFilter } from "./printIdCardAndMapRfid/constant";

const pageStateMapping = {
  SEARCH_STUDENT: <StudentList />,
  PRINT_ID_CARD: <PrintIdCardAndMapRfid initialStep={1} />,
  MAP_RFID: <PrintIdCardAndMapRfid initialStep={2} />
}

const GetPageComponentByState = () => {
  const { currentPageState , setCurrentPageState, getPendingRfIds, dropdown} = useContext(IssuanceIDCardContext);

  const {location} = useHistory();
  const pathName= location.pathname ;
  const isRedirected =   pathName?.includes('mapRfIdCards');

  useEffect(()=>{
    if(isRedirected){
      
      getPendingRfIds(0, pendingRfIdFilter, () =>{});
      setCurrentPageState(PAGE_STATE?.MAP_RFID)
    }
  },[isRedirected, dropdown])



  return (
    pageStateMapping[currentPageState]
  )
}

const IssuanceIDcardPage = () => {

  const userPermissions = RolePermissions(useContext(PermissionContext), pages['idCards']['id']);

  return (
    userPermissions ? (
      <IssuanceIDCardProvider>
        <GetPageComponentByState />
      </IssuanceIDCardProvider>
    ) : (
      <PermisionDenied />
    )
  );
};

export default IssuanceIDcardPage;
