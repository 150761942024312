import React, { useEffect, useState } from 'react'
import APopover from '../../../../common/a-popover'
import AButton from '../../../../common/form-fields-mui/AButton'
import styles from './styles.module.scss'
import { Col, Row } from 'reactstrap'
import { IoMdArrowDropdown } from "react-icons/io";
import { Dialog } from '@mui/material'
import { ReactComponent as CrossIcon } from 'assets/img/svg/CrossIcon.svg';
import SelectMaterialElasticSearch from '../../mastersAndMapping/onBoardingMaterialIdSetup/SelectMaterialElasticSearch'
import AAutoComplete from '../../../../common/form-fields-mui/AAutoComplete'
import { qtyDropdown } from './constant'
import { CustomChipWithDot } from '../../../batch-serach/CustomChip'
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'
import { useParams } from 'react-router'


export const MaterialDetailsViewCellComponent = ({ params }) => {
  return (
    params?.value?.length > 0 ? (
      <div className='d-flex align-items-center ellipsis-text-container'>
        <div className='flex-grow-1 ellipsis-text-container'>{params?.value?.[0]?.materialIdAndDescription}</div>
        <APopover
          buttonComponent={(onClick) => <AButton className='ml-2' variant='link' size='xs' onClick={onClick} >View all</AButton>}
          menuComponent={(closeForm) => (
            <div className={`${styles['material-details-popup']} regular-body`}>
              <Row className={`${styles['table-header']} semi-bold`}>
                <Col sm='8'>Material details</Col>
                <Col sm='2'>Weight</Col>
                <Col sm='2'>Qty</Col>
              </Row>
              {params?.value?.map((item, idx) => (
                <Row key={`${idx}-${item?.materialIdAndDescription}`}>
                  <Col sm='8' className='ellipsis-text-container'>{item?.materialIdAndDescription}</Col>
                  <Col sm='2' className ="text-bold">
                 {item?.materialWeight? `${item.materialWeight} kg`:'-'}
                </Col>                  
                <Col sm='2'>{item?.materialCount}</Col>
                </Row>
              ))}
            </div>
          )}
        />
      </div>
    ) : (
      <div>-</div>
    )
  )
}

const MaterialDetailsCellComponent = ({ params, rowFormHandler, rowForm }) => {
  const { academicCareer } = useParams();

  const getSelectedMaterials = arr => arr?.map(item => ({ label: item?.materialIdAndDescription, value: item?.materialNumber, materialCount: item?.materialCount, materialDescription: item?.materialDescription , materialWeight:item?.materialWeight}));
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMaterials, setSelectedMaterials] = useState(getSelectedMaterials(rowForm?.materials || []));
  useEffect(() => {
    setSelectedMaterials(getSelectedMaterials(rowForm?.materials || []))
  }, [isOpen])
  const selectMaterialsHandler = arr => setSelectedMaterials(arr?.map(item => ({ ...item, materialCount: item?.materialCount || 1 })))
  const qtyChangeHandler = (currentItem, value) => setSelectedMaterials(prev => prev?.map(item => item?.value === currentItem?.value ? { ...item, materialCount: value?.value } : item));
  const removeMaterialHandler = (currentItem) => setSelectedMaterials(prev => prev?.filter(item => item?.value !== currentItem?.value))

  const saveHandler = () => {
    const res = selectedMaterials?.map(item => ({
      materialNumber: item?.value, materialDescription: item?.materialDescription, materialCount:
       item?.materialCount, materialIdAndDescription: item?.label,
       materialWeight: item?.materialWeight
    }))
    rowFormHandler(res, 'materials');
    setIsOpen(false);
  }

  return (
    <>
      <div className={`${styles['select-material-cell']}`} onClick={() => setIsOpen(true)}>
        <div className='regular-small ellipsis-text-container'>
          {rowForm?.materials?.length > 0 ? `${rowForm?.materials?.length} Material(s) selected` : 'Select'}
        </div>
        <IoMdArrowDropdown size={24} />
      </div>
      <Dialog open={isOpen} PaperProps={{ sx: { maxWidth: 'unset', borderRadius: '12px' } }}>
        <div className={`${styles['select-material-popup']}`}>
          <div className='d-flex align-items-center justify-content-between'>
            <div className='heading-4 color-dark'>Add materials</div>
            <IconButtonWrapper onClick={() => setIsOpen(false)}><CrossIcon width={24} height={24} /></IconButtonWrapper>
          </div>
          <SelectMaterialElasticSearch
            label='Material number and description'
            placeholder={'Search material'}
            currentValue={selectedMaterials}
            handleChange={value => selectMaterialsHandler(value)}
            isMulti={true}
            isShowCount={false}
            dropdownMappingParams={['materialIdAndDescription', 'materialId', 'materialDescription', 'materialWeight']}
            style={{ control: { height: '52px', fontSize: '14px' } }}
            isDlpSearch={academicCareer==='DLP'}
            isDigitalSearch={academicCareer==='DIGITAL'}
          />
          <div className={`${styles['added-materials']}`}>
            <Row className={`${styles['table-header']} semi-bold`}>
              <Col sm='8'>Added material</Col>
              <Col sm='2'>Weight</Col>
              <Col sm='2'>Qty</Col>
            </Row>
            {console.log(selectedMaterials)}
            {!selectedMaterials?.length && <div className='text-center regular-small'>No materials added</div>}
            {selectedMaterials?.map((item, idx) => (
              <Row key={`${idx}-${item?.value}`}>
                <Col sm='8' className=''>
                  <CustomChipWithDot text={item?.label} onDelete={() => { removeMaterialHandler(item) }} className='regular-body' />
                </Col>
                <Col sm='2'  className ="text-bold">
                 {item?.materialWeight? `${item.materialWeight} kg`:'-'}
                </Col>
                <Col sm='2'>
                  <AAutoComplete
                    currentValue={{ label: item?.materialCount, value: item?.materialCount }}
                    handleChange={value => qtyChangeHandler(item, value)}
                    items={qtyDropdown}
                    isMulti={false}
                    style={{ control: { borderRadius: '4px' } }}
                  />
                </Col>
              </Row>
            ))}
          </div>

          <div>
            <AButton
              className='btn-left-0'
              variant='primary_filled'
              onClick={saveHandler}
              disabled={!selectedMaterials?.length}
            >
              Save
            </AButton>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default MaterialDetailsCellComponent