import { GridRowModes } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { validateBarcodeFromApi } from "../../selectMeterialAndStock/constant";
import { Dialog } from "@mui/material";
import AButton from "../../../../common/form-fields-mui/AButton";
import SapPeriodAndSelectSapPostingDate from "../../sapPeriodComponent/SapPeriodAndSelectSapPostingDate";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import { ReactComponent as DangerIcon } from "assets/img/svg/WarningRed.svg";

import {
  failureToast,
  successToast,
} from "../../../../common/utils/methods/toasterFunctions/function";
import { isObjEmpty } from "../../../../common/utils/methods/commonMethods/utilityMethod";
import {
  useFetchDispatchMaterialForPsid,
  useGetDispatchSchedular,
} from "../../customhooks";
import ALoader from "../../../../common/a-loader";
import { fetchMaterialBarcodes, issueStockApi } from "../../service";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx";
import AddBarcodeCustomTable from "./AddBarcodeCustomTable";

export const validateBarcode = async (businessArea, newBarcode, material) => {
  // while changing this method please check the impact and make changes at all places where it is used!!
  const res = await validateBarcodeFromApi(businessArea, newBarcode);

  if (res?.message) return res.message;
  else if (res?.materialId != material?.materialId) {
    return "Barcode not found to be available for issuance";
  } else {
    return false;
  }
};

const isOpenInEditMode = (tableRows = []) => {
  console.log(tableRows.some((row) => row?.isSaved));
  return tableRows.some((row) => row?.isSaved);
};

function BarcodeMappingPopup({
  open,
  setOpen,
  applicationData,
  apiLoader,
  setApplicationData,
  refreshTableData,
}) {
  const [isLoading, setLoading] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [sapPostingDate, setSapPostingDate] = useState(undefined);
  const [issuedMaterialList, setIssuedMaterialList] = useState([]);
  const [materialsData, loading] = useFetchDispatchMaterialForPsid(
    applicationData?.businessArea,
    applicationData?.dispatchScheduleId,
    applicationData?.psid
  );
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    if (materialsData?.unIssuedMaterialList?.length > 0)
      createBarcodeTableRows(materialsData?.unIssuedMaterialList);
    if (materialsData?.issuedMaterialList?.length > 0) {
      const alreadyIssuedMaterials = materialsData?.issuedMaterialList?.map(
        (item) => ({
          ...item,
          isIssuedAlready: true,
          barcodeCount: 0,
        })
      );
      console.log(alreadyIssuedMaterials, "alreadyIssuedMaterials");
      setIssuedMaterialList(alreadyIssuedMaterials);
    }
  }, [materialsData]);

  const createBarcodeTableRows = (materialDetails, dispatchId) => {
    if (!materialDetails) return;
    const rows = [];
    const materials = [];
    materialDetails.forEach((item) => {
      const {
        unIssuedquantity: materialCount,
        materialDescription,
        materialNumber,
      } = item;
      for (let index = 0; index < materialCount; index++) {
        rows.push({
          materialCount,
          barcode: "",
          id: rows.length + 1,
          materialDescription,
          materialNumber,
          materialId: materialNumber,
          dispatchId: applicationData?.dispatchScheduleId,
          dispatchSchedule: applicationData?.dispatchId,
          uom: "pc",
        });
        materials.push(materialNumber);
      }
    });
    getMaterialsCount(applicationData?.plantId, materials, rows);
  };

  const getMaterialsCount = async (plantId, materials, rows) => {
    if (!materials?.length > 0) return;
    setLoading(true);
    const response = await fetchMaterialBarcodes(
      plantId,
      removeDuplicateMaterials(materials),
      applicationData?.businessArea
    );
    const materiaCountMap = response?.materialCount || {};
    const materialBarcodeMap = response?.materialIds;
    const updatedRow = rows.map((item) => {
      return {
        ...item,
        barcode: getBarcode(materialBarcodeMap, item),
        barcodeCount: materiaCountMap[item?.materialId] || 0,
        isSaved: true,
      };
    });
    setTableRows(updatedRow);

    setLoading(false);
  };

  const removeDuplicateMaterials = (materials) => {
    return [...new Set(materials)];
  };
  const getBarcode = (materialMap, item) => {
    const isBarcodeExistList = Array.isArray(materialMap[item?.materialId]);
    if (!isBarcodeExistList) return "";
    const firstBarcode = materialMap[item?.materialId].shift();
    if (firstBarcode) return firstBarcode["barcode"];
    return "";
  };
  const handleSaveClick = async (index, barcode, row) => {
    const isValid = await validateBarcode(
      applicationData?.businessArea,
      barcode,
      row
    );
    if (isValid) {
      setErrMsg(isValid);
      return;
    }

    const updatedRow = { ...row, barcode, isSaved: true };
    updateRow(index, updatedRow);
  };

  const updateRow = (index, updatedRow) => {
    const tempRows = [...tableRows];
    tempRows[index] = updatedRow;
    setTableRows(tempRows);
  };
  const closeHandler = () => {
    setTableRows([]);
    setOpen(false);
    setApplicationData({});
  };
  //
  const issueStockHandler = async (studentData, materials) => {
    setLoading(true);
    const {
      applicationId,
      psid,
      businessArea,
      academicGroupDispValue,
      businessAreaDispValue,
      plantId,
    } = studentData;

    const request = {
      plantId: plantId || "DL10",
      businessAreaDispValue,
      businessArea,
      academicGroupDispValue,
      academicCareerDispValue: "DLP",
      type: "DLP_PSID_ISSUANCE",
      businessUnitType: "Owned",
      sapPostingDate,
      psidStockRequest: [
        {
          applicationId,
          psid,
          dispatchScheduleId: applicationData?.dispatchScheduleId,
          dispatchId: applicationData?.dispatchId,
          stock: materials,
        },
      ],
    };
    const response = await issueStockApi(request);
    if (response?.code === 200) {
      successToast(
        `Stock issued successfully. Your transaction ID: ${response?.data?.transactionId} and SAP ID: ${response?.data?.documentId}`
      );
      refreshTableData();
      closeHandler();
    } else {
      failureToast(` ${response?.message} \n 
      Transaction ID : ${response?.data.transactionId} failed. Retry.`);
    }

    setLoading(false);
  };
  const closeHandlerWithReset = () => {
    setTableRows([]);
    setOpen(false);
    setApplicationData({});
  };

  return (
    <>
      <Dialog onClose={closeHandlerWithReset} open={open} maxWidth="lg">
        <div style={{ padding: "28px 24px", minWidth: "59rem" }}>
          {(apiLoader || isLoading || loading) && <ALoader position="fixed" />}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="color-dark heading-4">
              Materials and mapped barcodes
            </div>
            <div className="d-flex">
              <SapPeriodAndSelectSapPostingDate
                sapPostingDate={sapPostingDate}
                setSapPostingDate={(date) => setSapPostingDate(date)}
              />
              <IconButtonWrapper onClick={closeHandler}>
                <CrossIcon width={32} height={32} />
              </IconButtonWrapper>
            </div>
          </div>
          <div
            className="mb-2"
            style={{ border: "1px solid #CBCBCB", borderRadius: "5px" }}
          >
            <AddBarcodeCustomTable
              rows={tableRows}
              setRows={setTableRows}
              saveBarcodeHandler={handleSaveClick}
              issuedMaterialList={issuedMaterialList}
              errMsg={errMsg}
              setErrMgs={setErrMsg}
            />
          </div>
          {errMsg && (
            <div className="mt-2 d-flex align-items-center mb-2">
              <DangerIcon width={24} height={24} className="mr-2" />
              <span className="color-red-60 regular-body">{errMsg}</span>
            </div>
          )}
          <AButton
            size="sm"
            className="button_remove_left_margin"
            variant="primary_filled"
            disabled={tableRows.some((item) => !item?.barcode)}
            onClick={() => issueStockHandler(applicationData, tableRows)}
          >
            Issue Stock
          </AButton>
        </div>
      </Dialog>
    </>
  );
}

export default BarcodeMappingPopup;
