import React, { useRef, useState, useEffect, useContext } from 'react';
import AButton from 'views/pages/manage/common/form-fields-mui/AButton';
import styles from './styles.module.scss';
import ListComponent from './ListComponent';
import {
  getDropdowns,
  restructureGetAllRes,
  getCreateReqBody,
} from './constant';
import { endpoint, pages } from 'views/pages/manage/common/constant';
import { fetchAllPromisedData } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import ALoader from 'views/pages/manage/common/a-loader';
import { ReactComponent as BackIcon } from 'assets/img/svg/BackButton.svg';
import { useHistory } from 'react-router';
import { fetchAllPostPromisedData } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { fetchDeletePromisedData } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { successToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import { failureToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { RolePermissions } from 'appContext';
import { PermissionContext } from 'appContext';
import { PermisionDenied } from 'views/pages/manage/common/commonComponents';
import { checkPermission } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { updateRequest } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
const LeftOutAdmission = () => {
  const listRef = useRef();
  // const [page, setPage] = useState(0);
  const [dropdownLoader, setDropdownLoader] = useState(false);
  const [dropdown, setDropDownData] = useState();
  const [listLoader, setListLoader] = useState();
  const [apiLoader, setApiLoader] = useState();
  const [rows, setRows] = useState();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const userPermissions = RolePermissions(
    useContext(PermissionContext),
    pages['ltaConfig']['id']
  );

  const nextButtonHandler = (newPage) => {
    setCurrentPage(newPage);
    fetchList(newPage);
  };

  const fetchList = async (page=0,dropdownData = dropdown) => {
    setListLoader(true);
    const reqBody =updateRequest({
      'businessAreas':dropdownData?.businessArea
    })
    const res = await fetchAllPostPromisedData(
      `${endpoint.leftOutAdmissionConfig.search}?offset=${page}`,
      reqBody,
      "post"
    );
    if (res?.code === 200) {
      const dataRows = restructureGetAllRes(res?.data, dropdownData);
      setRowCount(res?.data?.totalRecords);
      setRows(dataRows);
      listRef.current.stopEditing();
    } else {
      setRowCount(0);
      setRows([]);
    }
    setListLoader(false);
  };
  const createHandler = async rowData => {
    setApiLoader(true);
    const reqBody = getCreateReqBody(rowData);
    const res = await fetchAllPostPromisedData(
      endpoint.leftOutAdmissionConfig.create,
      reqBody,
      'post'
    );
    if (res?.code === 200) {
      successToast('Saved Successfully');
      fetchList(0);
      setCurrentPage(0);
    } else {
      failureToast(res?.message);
    }
    setApiLoader(false);
  };

  const updateHandler = async rowData => {
    setApiLoader(true);
    const reqBody = getCreateReqBody(rowData);
    const res = await fetchAllPostPromisedData(
      `${endpoint.leftOutAdmissionConfig.update}/${rowData?.id}`,
      reqBody,
      'put'
    );
    if (res?.code === 200) {
      successToast(res?.message);
      fetchList(currentPage);
    } else {
      failureToast(res?.message);
    }
    setApiLoader(false);
  };
  const toggleStatusHandler = async rowData => {
    setApiLoader(true);
    const reqBody = {
      ...getCreateReqBody(rowData),
      status: rowData?.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
    };
    const res = await fetchAllPostPromisedData(
      `${endpoint.leftOutAdmissionConfig.update}/${rowData?.id}`,
      reqBody,
      'put'
    );
    if (res?.code === 200) {
      successToast(res?.message);
      fetchList(currentPage);
    } else {
      failureToast(res?.message);
    }
    setApiLoader(false);
  };
  const deleteHandler = async rowData => {
    setApiLoader(true);
    const res = await fetchDeletePromisedData(
      `${endpoint.leftOutAdmissionConfig.delete}/${rowData?.id}`
    );
    if (res?.code === 200) {
      successToast(res?.message);
      fetchList(currentPage);
    } else {
      failureToast(res?.message);
    }
    setApiLoader(false);
  };

  const getDropdown = async () => {
    setDropdownLoader(true);
    const data = await getDropdowns();
    setDropDownData(data);
    setDropdownLoader(false);
    fetchList(0,data);
  };

  useEffect(() => {
    getDropdown();
  }, []);

  return userPermissions ? (
    <div className={`remove-container-top-space`}>
      {(dropdownLoader || listLoader || apiLoader) && (
        <ALoader position="fixed" />
      )}
      <div className={`${styles['header-bar']}`}>
        <BackIcon onClick={() => history.goBack()} />
        <div className="ml-3 heading-4 color-dark">Left Out Admission</div>
      </div>
      <div className={`${styles['page-container']}`}>
        <div className="d-flex justify-content-between align-items-start">
          <div style={{ color: '#00AEEF' }} className={`flex-grow-1 font-weight-bold`}>
            Configurations <MdKeyboardArrowDown size={26} />
          </div>

          {checkPermission(userPermissions, 'C')&&<AButton
            updatedStyle={{ fontWeight: 600 }}
            onClick={() => listRef?.current?.addNewRow()}
          >
            Add new row
          </AButton>
}
        </div>

        <ListComponent
          ref={listRef}
          rows={rows}
          loader={dropdownLoader || listLoader}
          dropdown={dropdown}
          createHandler={createHandler}
          updateHandler={updateHandler}
          toggleStatusHandler={toggleStatusHandler}
          deleteHandler={deleteHandler}
          nextButtonHandler={nextButtonHandler}
          currentPage={currentPage}
          rowCount={rowCount}
        />

      </div>
    </div>
  ) : (
    <PermisionDenied />
  );
};
export default LeftOutAdmission;
