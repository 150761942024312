import React from "react";
import { masterServiceBaseUrl} from 'services/http';
import { CardFooter, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import "react-sliding-pane/dist/react-sliding-pane.css";
import 'react-toastify/dist/ReactToastify.css';

var _ = require('lodash');

const PaginationNode = (props) => {
  return (
    <>
      <PaginationItem disabled={props.disabled} className={(props.currentPage==1&&props.num=="Next")||(props.num=="Previous"&&(props.totalPage<=props.currentPage && !props.isPaginationFromServer )) || ( (props.num=="Previous" && props.totalPage<=props.currentPage && !props.isNextCallPossible)) || props.isFetching ?"disabled":null}>
        <PaginationLink onClick={(e) =>{props.triggerFun();}}>
          {/*   */}
          <i className={props.icon} />
          <span className="sr-only">{props.num}</span>
        </PaginationLink>
      </PaginationItem>
    </>
  )
}

const PaginationPage = (props) => {
  return (
    <>
      {props.num&&!(props.totalPage<props.num) ? <PaginationItem disabled={props.disabled} className={props.url ? (props.active ? 'active' : null) : (props.active ? 'active disable' : 'disable')}>
        <PaginationLink onClick={props.currentPage !== props.num ? (e) => props.triggerFun() : ()=>{}}>
          {/* onClick={(e) =>{props.triggerFun()}} */}
          {props.num}
        </PaginationLink>
      </PaginationItem> : null}
    </>
  )
}

const GetPagination = (props) => {

  const { isPaginationFromServer, fetchNext = () => {}, currentPage, totalNumberOfRecords, serverTotalPage, serverCurrentPage, isNextCallPossible, isFetching } = props;


  const nextPage = async() => { 
  

    props.setclassArray(
      props.state.slice(
        (props.pagination.currentPage + 1) * props.pageSize - props.pageSize,
        (props.pagination.currentPage + 1) * props.pageSize
      )
    );

    if(props.pagination["currentPage"] === props.pagination.totalPage -1){
      await fetchNext()
    }

    let n = {...props.pagination};
    n["currentPage"] = n["currentPage"] + 1;
    n["previousPage"] = n.currentPage;
    // console.log('Pagination---->',n);
    props.setpagination(n);
  };
  
  const firstPage = () => {
    props.setclassArray(props.state.slice(1 * props.pageSize - props.pageSize, 1 * props.pageSize));
    //console.log(props.pagination);
    let n = {...props.pagination};
    n["currentPage"] = 1;
    n["previousPage"] = null;
    props.setpagination(n);
  };

  const lastPage = async() => {
    props.setclassArray(
      props.state.slice(
        props.pagination.totalPage * props.pageSize - props.pageSize,
        props.pagination.totalPage * props.pageSize
      )
    );
    //console.log(props.pagination);
    let n = {...props.pagination};
    n["currentPage"] = n.totalPage;
    n["previousPage"] = n.totalPage - 1;
    props.setpagination(n);
  };

  const previousPage = () => {
    if (props.pagination.currentPage != 1) {
      props.setclassArray(
        props.state.slice(
          (props.pagination.currentPage - 1) * props.pageSize - props.pageSize,
          (props.pagination.currentPage - 1) * props.pageSize
        )
      );
      let n = {...props.pagination};
      n["currentPage"] = n["currentPage"] - 1;
      if (n["currentPage"] == 1) {
        n["previousPage"] = null;
      } else {
        n["previousPage"] = n.currentPage;
      }
      props.setpagination(n);
    }
  };

  return (
    <>
      {props.pagination ? <CardFooter className="py-4">
        <nav aria-label="...">
          <Pagination  className="props.pagination justify-content-end mb-0" listClassName="justify-content-end mb-0">
           {!isPaginationFromServer && <PaginationNode disabled={props.isSaveVisible} num={'Next'} totalPage={props.pagination.totalPage} currentPage={props.pagination.currentPage} active={true} triggerFun={firstPage} icon={'fa fa-angle-double-left'} />}
            <PaginationNode disabled={props.isSaveVisible} isFetching={isFetching} num={'Next'} totalPage={props.pagination.totalPage} currentPage={props.pagination.currentPage} active={true} triggerFun={previousPage} icon={'fa fa-angle-left'} />
            <PaginationPage disabled={props.isSaveVisible} active={false} totalPage={props.pagination.totalPage} num={parseInt(props.pagination.currentPage-1)} currentPage={props.pagination.currentPage} triggerFun={previousPage} />
            <PaginationPage disabled={props.isSaveVisible} active={true} totalPage={props.pagination.totalPage} num={parseInt(props.pagination.currentPage)} currentPage={props.pagination.currentPage} triggerFun={nextPage} />
            <PaginationPage disabled={props.isSaveVisible} active={false} totalPage={props.pagination.totalPage} num={parseInt(props.pagination.currentPage+1)} currentPage={props.pagination.currentPage} triggerFun={nextPage} />
            <PaginationNode disabled={props.isSaveVisible} isFetching={isFetching} num={'Previous'} current={props.pagination.currentPage} totalPageOnUI = {props.pagination.currentPage} serverCurrentPage={serverCurrentPage} isNextCallPossible={isNextCallPossible} serverTotalPage={serverTotalPage} currentPage={currentPage} state={props.state} totalNumberOfRecords={totalNumberOfRecords} totalPage={props.pagination.totalPage} currentPage={props.pagination.currentPage} triggerFun={nextPage} icon={'fa fa-angle-right'} isPaginationFromServer={isPaginationFromServer}/>
            {!isPaginationFromServer && <PaginationNode disabled={props.isSaveVisible} num={'Previous'} totalPage={props.pagination.totalPage} currentPage={props.pagination.currentPage} triggerFun={lastPage} icon={'fa fa-angle-double-right'} />}
          </Pagination>
        </nav>
      </CardFooter> : null}
    </>
  )
}
export {PaginationNode,PaginationPage,GetPagination}