import React from 'react'
import AButton from '../../common/form-fields-mui/AButton'

const BulkSelectionFooter = ({ selectionModel, setSelectionModel = () => { }, requestSupplementaryIdHandler = () => { } }) => {
  return (
    selectionModel?.length > 0 && (
      <div className={`student-search__bulk-footer`}>
        <div className='d-flex flex-column'>
          <div className='heading-4'>
            {selectionModel?.length} Students selected
          </div>
          <div className='regular-small'>What do you want to do next?</div>
        </div>
        <div className='ml-5 flex-grow-1'>
          <AButton
            className='ml-0'
            variant='primary_filled'
            onClick={requestSupplementaryIdHandler}
          >
            Request for Supplementary ID
          </AButton>
        </div>
        <div>
          <AButton
            updatedStyle={{
              color: '#0F0F0F',
              letterSpacing: '0.5px',
            }}
            onClick={() => setSelectionModel([])}
          >
            Deselect all
          </AButton>
        </div>
      </div>
    )
  );
}

export default BulkSelectionFooter