import React, { useContext, useEffect, useRef, useState } from 'react'
import AButton from 'views/pages/manage/common/form-fields-mui/AButton'
import { getHolidayList, getFilterCount, deleteHoliday, createHoliday, updateHoliday, searchFilterInitialForm, getDropdowns, holidayTypeDropdownData } from './common'
import styles from './styles.module.scss'
import ALoader from 'views/pages/manage/common/a-loader'
import { successToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function'
import { checkPermission, getMultiSelectDefault, getSingleSelectDefault, isObjEmpty } from '../../common/utils/methods/commonMethods/utilityMethod'
import SearchFilter from './SearchFilter'
import ListComponent from './ListComponent'
import { RolePermissions } from 'appContext'
import { PermissionContext } from 'appContext'
import { pages } from '../../common/constant'
import useConfirm from '../../common/commonComponents/ConfirmationDialog/confirmationPrompt.jsx';


const HolidayMaster = () => {
  const userPermissions = RolePermissions(useContext(PermissionContext), pages['holidayMaster']['id']);
  const listRef = useRef();

  const [rows, setRows] = useState([]);
  const [dropdowns, setDropdowns] = useState({});
  const [dropdownLoader, setDropdownLoader] = useState(false);
  const [listApiLoader, setListApiLoader] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [businessAreaData, setBusinessAreaData] = useState([]); // this is used for region dependent filter dropdown

  const [filterPayload, setFilterPayload] = useState(searchFilterInitialForm);
  const [filterForm, setFilterForm] = useState(searchFilterInitialForm);
  const [filterCount, setFilterCount] = useState(0);
  const [editRowForm, setEditRowForm] = useState({});
  const [ConfirmationDialog, confirm] = useConfirm();
  const filterFormHandler = (value, inputKey) => {
    setFilterForm(currentData => ({ ...currentData, [inputKey]: value }));
  }

  const applyFilterHandler = async () => {
    if (isObjEmpty(editRowForm)) {
      setFilterCount(getFilterCount(filterForm));
      setFilterPayload(filterForm);
      holidayListHandler(filterForm);
    }
    else {
      const canContinue = await confirm();
      if (canContinue) {
        setFilterCount(getFilterCount(filterForm));
        setFilterPayload(filterForm);
        holidayListHandler(filterForm);
        listRef.current.stopEditing();
      }
      else {
        return;
      }
    }
  }
  const removeFilterHandler = async () => {

    if (isObjEmpty(editRowForm)) {
      setFilterCount(0);
      const resettedFilterForm = { ...searchFilterInitialForm, holidayNameOrHolidayId: filterForm?.holidayNameOrHolidayId }
      setFilterForm(resettedFilterForm);
      setFilterPayload(resettedFilterForm);
      holidayListHandler(resettedFilterForm, 0);
    }
    else {
      const canContinue = await confirm();
      if (canContinue) {
        setFilterCount(0);
        const resettedFilterForm = { ...searchFilterInitialForm, holidayNameOrHolidayId: filterForm?.holidayNameOrHolidayId }
        setFilterForm(resettedFilterForm);
        setFilterPayload(resettedFilterForm);
        holidayListHandler(resettedFilterForm, 0);
        listRef.current.stopEditing();
      }
      else {
        return;
      }
    }


  }
  const applyNameOrIdFilterHandler = async () => {

    if (isObjEmpty(editRowForm)) {
      const updatedPayload = filterPayload;
      updatedPayload['holidayNameOrHolidayId'] = filterForm['holidayNameOrHolidayId']
      setFilterPayload(updatedPayload);
      holidayListHandler(updatedPayload, 0);
    }
    else {
      const canContinue = await confirm();
      if (canContinue) {
        const updatedPayload = filterPayload;
        updatedPayload['holidayNameOrHolidayId'] = filterForm['holidayNameOrHolidayId']
        setFilterPayload(updatedPayload);
        holidayListHandler(updatedPayload, 0);
        listRef.current.stopEditing();
      }
      else {
        return;
      }
    }
  }
  const clearNameOrIdFilterHandler = async () => {

    if (isObjEmpty(editRowForm)) {
      const updatedPayload = filterPayload;
      updatedPayload['holidayNameOrHolidayId'] = ''
      setFilterPayload(updatedPayload);
      holidayListHandler(updatedPayload, 0);
    }
    else {
      const canContinue = await confirm();
      if (canContinue) {
        const updatedPayload = filterPayload;
        updatedPayload['holidayNameOrHolidayId'] = ''
        setFilterPayload(updatedPayload);
        holidayListHandler(updatedPayload, 0);
        listRef.current.stopEditing();
      }
      else {
        return;
      }
    }
  }

  const holidayListHandler = async (filterPayload) => {
    setListApiLoader(true);

    const updatedPayload = { ...filterPayload }
    if (filterPayload?.businessArea?.length === 0) {
      updatedPayload['businessArea'] = dropdowns?.allBusinessArea
    }
    const res = await getHolidayList(updatedPayload);

    if (res?.code === 200) {
      const updatedRes = res?.data?.searchHolidayMasterDTOList?.map(item => ({
        ...item,
        holidayType: getSingleSelectDefault(item?.holidayType, holidayTypeDropdownData),
        regions: getMultiSelectDefault(item?.regions, dropdowns?.allRegion),
        businessAreas: getMultiSelectDefault(item?.businessAreas, dropdowns?.allBusinessArea),
        states: getMultiSelectDefault(item?.states, dropdowns?.allState),
        city: getMultiSelectDefault(item?.city, dropdowns?.allCity)
      }))

      setRows(updatedRes);
    } else {
      setRows([]);
    }
    setListApiLoader(false);
  }

  const fetchDropdownData = async () => {
    setDropdownLoader(true);
    const res = await getDropdowns();
    await setDropdowns(res);
    setDropdownLoader(false);
  }

  useEffect(() => {
    fetchDropdownData();
  }, [])

  useEffect(() => {
    if (!isObjEmpty(dropdowns)) {
      holidayListHandler(filterPayload);
    }
  }, [dropdowns])


  const createHandler = async (rowData) => {
    setApiLoader(true);
    const res = await createHoliday({ ...rowData, id: null })
    if (res?.code === 200) {
      successToast(res?.message);
      await holidayListHandler(filterPayload);
    }
    setApiLoader(false);
    return res;
  }

  const updateHandler = async (rowData) => {
    setApiLoader(true);
    const request = {
      holidayName: rowData?.holidayName,
      holidayType: rowData?.holidayType,
      holidayDate: rowData?.holidayDate,
      day: rowData?.day,
      regions: rowData?.regions,
      businessAreas: rowData?.businessAreas,
      states: rowData?.states,
      city: rowData?.city
    }
    const res = await updateHoliday(rowData?.id, request);

    if (res?.code === 200) {
      successToast(res?.message);
      await holidayListHandler(filterPayload);
    }
    setApiLoader(false);
    return res;
  }

  const deleteHandler = async (rowData) => {
    setApiLoader(true);
    const res = await deleteHoliday(rowData?.id);
    if (res?.code === 200) {
      // successToast(res?.message);
      successToast("Holiday deleted successfully")
      holidayListHandler(filterPayload);
    }
    setApiLoader(false);
    return res;
  }
  return (
    <div className={`remove-container-top-space ${styles?.main_container}`}>
      <div className='d-flex justify-content-between align-items-center' style={{ padding: '20px 0' }}>
        <div className='heading-4 color-dark'>Holiday master</div>
        {checkPermission(userPermissions, 'C') && <AButton variant='primary_filled' onClick={() => listRef?.current?.addNewRow()}>Add Holiday</AButton>}
      </div>
      <div className={`${styles?.content_container}`}>
        {(apiLoader || listApiLoader || dropdownLoader) && <ALoader />}
        <SearchFilter
          filterCount={filterCount}
          filterForm={filterForm}
          filterFormHandler={filterFormHandler}
          applyNameOrIdFilterHandler={applyNameOrIdFilterHandler}
          clearNameOrIdFilterHandler={clearNameOrIdFilterHandler}
          applyFilterHandler={applyFilterHandler}
          removeFilterHandler={removeFilterHandler}
          dropdowns={dropdowns}
          businessAreaData={businessAreaData}
          setBusinessAreaData={setBusinessAreaData}
        />
        <ListComponent
          ref={listRef}
          rows={rows}
          dropdowns={dropdowns}
          createHandler={createHandler}
          updateHandler={updateHandler}
          deleteHandler={deleteHandler}
          form={editRowForm}
          setForm={setEditRowForm}
        />
        <ConfirmationDialog />
      </div>
    </div>
  )
}

export default HolidayMaster