import React from "react";
import { Card, CardBody } from "reactstrap";
import "./style.scss";


const GetRow = ({ label = "", value = "" }) => {
  return (
    <tr key={`${label}-${value}`} style={{ verticalAlign: 'baseline' }}>
      <td colSpan="3" className="printid-tr" style={{ minWidth: "6rem", fontWeight: 800 }}>
        {label}
      </td>
      <td className="printid-tr printid-value-td"><span className="pr-1">:</span> {value}</td>
    </tr>
  );
};

const PrintableCard = ({ students }) => {
 const getWrappedCourseName = (str ='')=>{
    if(str){
       const len = str.trim().length ;
       return len>60?  `${str.trim().substring(0, 58)}...`:str.trim()
    }
    return str ;
 }
  return (
    <div className="print-container" style={{ margin: "0", padding: "0" }}>
      {students.map((student, index) => {
        return (
          <div key={student?.psid}>
            <div className=" printId-container A4">
              <Card style ={{position:"absolute", top:"12%", left:"0%", width: '320px', height: '321px'}}>
                <CardBody className="d-flex align-items-center flex-column p-2">
                  <div className="d-flex justify-content-between" style={{ marginTop: '51px', marginBottom: '20px', alignItems: "center" }}>
                    <img style={{ height: "120px", width: "120px" }} className="navbar-brand-img" src={student.photoUrl} />
                  </div>

                  <table style={{ borderCollapse: "separate", paddingLeft: '15px', paddingRight: '10px' }} >
                    <tbody>
                      {GetRow({ label: "Name", value: student?.studentName })}
                      {GetRow({ label: "Roll No", value: student?.rollNo })}
                      {GetRow({ label: "Branch/Term", value: `${student.businessArea}/${student?.term}` })}
                      {GetRow({ label: "User ID", value: student?.psid })}
                      {GetRow({ label: "Course Type", value: student?.courseType })}
                      {GetRow({ label: "Valid From", value: student?.validFrom })}
                      {GetRow({ label: "Course Name", value: getWrappedCourseName(student?.courseName) })}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </div>
            {index + 1 !== students.length && <div className="page-break" />}
          </div>
        )
      })}

    </div>
  );
};

export default PrintableCard;
