import React, { useContext, useState } from 'react';
import { RolePermissions, PermissionContext } from 'appContext';
import { pages } from '../../../../common/constant';
import DeleteDialog from './DeleteDialog';
import { checkPermission } from '../../../../common/utils/methods/commonMethods/utilityMethod';
import AButton from '../../../../common/form-fields-mui/AButton';
import { ReactComponent as EditIcon } from 'assets/img/svg/Edit2.svg';
import { ReactComponent as DeleteIcon } from 'assets/img/svg/Delete.svg';
import { ReactComponent as CrossIcon } from 'assets/img/svg/CrossIcon.svg';
import { validateRowForm } from './constant';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'

const ActionsCellComponent = ({
  params,
  handleEditClick,
  handleCancelClick,
  handleSaveClick,
  deleteHandler,
  rowForm,
}) => {
  const userPermissions = RolePermissions(
    useContext(PermissionContext),
    pages['dlpStockAndInventory']['id']
  );

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return params.api.getRowMode(params?.id) === 'view' ? (
    <>
      <div className="d-flex align-items-center">
        {checkPermission(userPermissions, 'U') && (
          <IconButtonWrapper onClick={() => handleEditClick(params)}><EditIcon /></IconButtonWrapper>
        )}
        {checkPermission(userPermissions, 'D') && (
          <IconButtonWrapper className="ml-2" onClick={() => setIsDialogOpen(true)}><DeleteIcon /></IconButtonWrapper>
        )}
      </div>
      <DeleteDialog
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        data={params?.row}
        onAgree={() => deleteHandler(params?.row)}
      />
    </>
  ) : (
    <div className="d-flex align-items-center">
      <AButton
        variant="primary_filled"
        size="xs"
        onClick={() => handleSaveClick(params)}
        disabled={!validateRowForm(rowForm)}
      >
        Save
      </AButton>
      <IconButtonWrapper className="ml-2" onClick={() => handleCancelClick(params)}><CrossIcon /></IconButtonWrapper>
    </div>
  );
};

export default ActionsCellComponent;
