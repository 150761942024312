import React, { useEffect } from 'react';
import {
  Card,
  CardHeader,
  Input,
  Container,
  Button,
  Table,
  Row,
} from 'reactstrap';
import { useState } from 'react';
// import "views/pages/manage/addressType/node_modules/react-sliding-pane/dist/react-sliding-pane.css";
import { failureToast } from '../../common/utils/methods/toasterFunctions/function';
import { MasterHeaderAuto, Rows } from '../../common/commonComponents/masterHeader/masterHeader';
import {
  getAPI,
  masterServiceBaseUrl,
  successToast,
  putpost,
  putpost2,
} from 'services/http';
import moment from 'moment';
import CreateButton from "components/FormComponent/Button/New/index";
import UpdateButton from "components/FormComponent/Button/Update/index";
import EditButton from "components/FormComponent/Button/Edit/index";
import DeleteButton from 'components/FormComponent/Button/Delete';
import { ButtonText } from "variables/Buttons";

var _ = require('lodash');

const VendorTypeTable = (props) => {
  const {
    serverData,
    setSearch,
    resetSearch,
    isSaveVisible,
    setisSaveVisible,
    action,
  } = props;
  const [tableDataKey, settableDataKey] = useState('tableDataKey');
  const [updatedKey, setUpdateKey] = useState(new Date());
  const [searchStr, setSearchStr] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: '__new',
        status: 'ACTIVE',
        vendorTypeDesc: null,
        vendorTypeShortDesc: null,
        vendorTypeDispValue: null,
        updatedDate: null,
      },
      ...props.classArray,
    ]);
  };

  const headerList = [
    { name: 'Vendor Type Key' },
    { name: 'Effective Date' },
    { name: 'Description' },
    { name: 'Display Value' },
    { name: 'Status' },
    { name: 'Action' },
  ];
  const listToRender = [
    'vendorTypeKey',
    'effectiveDate',
    'vendorTypeDesc',
    'vendorTypeDispValue',
    'status',
  ];

  const saveAll = (cb) => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if (el['editMode']) {
        if (!el['vendorTypeKey'] || !el['vendorTypeKey'].trim()) {
          isValid = false;
          failureToast('Please Enter Vendor Type Key');
          return;
        } 
        else if (!el['vendorTypeDesc'] || !el['vendorTypeDesc'].trim()) {
          isValid = false;
          failureToast('Please Enter Description');
          return;
        } 
        // else if (
        //   !el['vendorTypeShortDesc'] ||
        //   !el['vendorTypeShortDesc'].trim()
        // ) {
        //   isValid = false;
        //   failureToast('Please Enter Short Description');
        //   return;
        // } 
        else if (
          !el['vendorTypeDispValue'] ||
          !el['vendorTypeDispValue'].trim()
        ) {
          isValid = false;
          failureToast('Please Enter Display Value');
          return;
        }
        if (!isValid) {
          return;
        }
        if (_tempArray[index]['__status'] == '__new') {
          putpost(
            masterServiceBaseUrl + '/vendorType/createVendorType',
            (data) => {
              successToast(data['message']);
              setisSaveVisible(false);
              setUpdateKey(new Date());
              props.fetchClasses(`${masterServiceBaseUrl}/vendorType/getAllVendorTypes`);
            },
            (data) => {
              failureToast(data['message']);
            },
            _tempArray[index],
            'post'
          );
        } else {
          putpost(
            masterServiceBaseUrl + '/vendorType/updateVendorType/' + _tempArray[index].id,
            (data) => {
              successToast(data['message']);
              setisSaveVisible(false);
              // delete _tempArray[index]['editMode']
              // props.setclassArray(_tempArray)
              props.fetchClasses(`${masterServiceBaseUrl}/vendorType/getAllVendorTypes`);
              settableDataKey(new Date().getMilliseconds());
              // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
            },
            (data) => {
              failureToast(data['message']);
            },
            _tempArray[index],
            'put'
          );
        }
      } else {
      }
    });
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
        // settableDataKey(new Date().getMilliseconds())
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      cb();
    }
  };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]['editMode'] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };
  const deleteRow = (index) => {
    if (props.classArray[index]['__status'] == '__new') {
      let n = props.classArray;
      n.splice(index, 1);
      props.setclassArray(n);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
    } else {
      if (
        window.confirm('Are you sure you want to delete this vendor type?')
      ) {
        putpost2(
          masterServiceBaseUrl +
            '/vendorType/deleteVendorType/' +
            props.classArray[index].id,
          (data) => {
            successToast(data['message']);
            if (props.classArray.length == 1) {
              
              props.fetchClasses(`${masterServiceBaseUrl}/vendorType/getAllVendorTypes`);
            } else {
              var _tempArray = props.classArray;
              if (_tempArray[index]['editMode']) {
                setisSaveVisible(false);
              }
              _tempArray.splice(index, 1);
              props.setclassArray(_tempArray);
            }
            settableDataKey(new Date().getMilliseconds());
            props.fetchClasses(`${masterServiceBaseUrl}/vendorType/getAllVendorTypes`);
          },
          (data) => {
            failureToast(data['message']);
          },
          'PUT'
        );
      }
    }
  };

  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
    setUpdateKey(new Date());
  };

  const filterData = (str) => {
    const newData =
      serverData &&
      serverData.filter((obj) =>
        Object.values(obj).some(
          (val) =>
            val && val.toString().toLowerCase().includes(str.toLowerCase())
        )
      );
    setSearch(newData);
  };

  useEffect(() => {
    if (!searchStr || !searchStr.trim()) {
      resetSearch();
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
      filterData(searchStr);
    }
  }, [searchStr]);

  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0 d-flex justify-content-between">
                <h3 className="mb-0 ">
                  Master - Vendor Type
                </h3>
                <CreateButton 
                  text={ButtonText.VendorType.addNew}
                  disabled={isSaveVisible || isDisabled}
                  className={"floatRight mx-1"+(isSaveVisible?" btn-dark":null)} 
                  onClick={() => {newRow()}} 
                  data-testid="addNewVendorType"
                />                
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout">
                  <MasterHeaderAuto headerList={headerList} />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                          !el['editMode'] ? (
                            //
                            <>
                              <Rows data={el} listToBeRendered={listToRender}>
                                <td className="text-center" >
                                <EditButton  disabled={props.isViewOnly} onClick={() => editRow(index)} 
                                   // data-testid="editButton"
                                />
                                <DeleteButton disabled={props.isViewOnly} onClick={() => deleteRow(index)}
                                    // data-testid="deletebtn"
                                />
                                </td>
                              </Rows>
                            </>
                          ) : (
                            <tr key={index + '-class'}>
                              <td className="text-center p-2 mx-1">
                                <Input
                                 maxLength="50"
                                  data-testid='vendorTypeKey'
                                  placeholder="Vendor Type Key"
                                  className="tdInput"
                                  defaultValue={el['vendorTypeKey']}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      'vendorTypeKey',
                                      true
                                    );
                                  }}
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  defaultValue={moment(el['effectiveDate']).format('DD-MM-YYY')}
                                  disabled
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength="50"
                                  defaultValue={el['vendorTypeDesc']}
                                  data-testid='vendorTypeDesc'
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      'vendorTypeDesc',
                                      true
                                    );
                                  }}
                                  placeholder="Description"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength="50"
                                  defaultValue={el['vendorTypeDispValue']}
                                  data-testid='vendorTypeDispValue'
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      'vendorTypeDispValue',
                                      true
                                    );
                                  }}
                                  placeholder="Display value"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <label className="custom-toggle mx-auto ml-2">
                                  <input
                                    checked={
                                      el.status == 'ACTIVE' ? true : false
                                    }
                                    type="checkbox"
                                    id={el.id}
                                    onChange={(e) => {
                                      settableDataKey(
                                        new Date().getMilliseconds()
                                      );
                                      let n = 'ACTIVE';
                                      if (!e.target.checked) {
                                        n = 'INACTIVE';
                                      }
                                      updateKey(index, n, 'status');
                                    }}
                                    data-testid='vendorTypeStatus'
                                  />
                                  <span
                                    style={{ width: '72px' }}
                                    className="custom-toggle-slider rounded-circle activeToggle"
                                    data-label-off="Inactive"
                                    data-label-on="Active"
                                  />
                                </label>
                              </td>
                              <td className="text-center" data-testid="saveConcessionVendorType">
                                {isSaveVisible ? (
                                  <>
                                    {' '}
                                    <UpdateButton text={ButtonText.VendorType.save} onClick={() => { saveAll() }} />
                                    <Button
                                      color="danger"
                                      size="sm"
                                      type="button"
                                      onClick={() => {
                                        let n = props.classArray;
                                        if (n[index]['__status'] == '__new') {
                                          n.splice(index, 1);
                                        } else {
                                          // n[index]['editMode'] = false
                                          props.fetchClasses(
                                            `${masterServiceBaseUrl}/vendorType/getAllVendorTypes`
                                          );
                                        }
                                        props.setclassArray(n);
                                        setisSaveVisible(false);
                                      }}
                                    >
                                      {' '}
                                      <i className="fas fa-times" />
                                    </Button>{' '}
                                  </>
                                ) : (
                                  <Button
                                    color="info"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                      deleteRow(index);
                                    }}
                                  >
                                    {' '}
                                    <i className="fas fa-trash" />
                                  </Button>
                                )}
                              </td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
              {action === 'edit' ? (
                <Button
                  className={'floatRight ml-auto m-3'}
                  color="primary"
                  size="md"
                  type="button"
                  onClick={() => {
                    setIsDisabled(!isDisabled);
                  }}
                >
                  {isDisabled ? 'Enable Form' : 'Disabled Form'}
                </Button>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default VendorTypeTable;
