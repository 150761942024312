import React from "react";
import MainPopover from "views/pages/manage/common/a-popover";
import { ReactComponent as CsvIcon } from "assets/img/svg/excel.svg";
import { ReactComponent as DownloadIcon } from "assets/img/svg/downloadIcon.svg";
import { Card, CardBody } from "reactstrap";
import AButton from "../../../../common/form-fields-mui/AButton";
import styles from '../styles.module.scss';

const DownloadFiles = ({ downloadHandler }) => {
  const DownloadRowBtn = ({ handler, text, downloadType }) => {
    return (
      <div className="mb-2">
        <div
          className={`${styles['download-row-btn']}`}
          onClick={() => handler(downloadType)}
        >
          <DownloadIcon />
          <span>{text}</span>
        </div>
      </div>
    );
  };
  return (
    <div>
      <MainPopover
        buttonComponent={(onClick, styles) => (
          <AButton
            onClick={onClick}
          >
            <CsvIcon /> <span className="ml-2">Download</span>
          </AButton>
        )}
        menuComponent={(closeForm) => (
          <>
            <Card className = "mb-0">
              <CardBody>
              <DownloadRowBtn
                  text="Students enrolled in batch"
                  downloadType="ALLOCATED_IN_BATCH"
                  handler={downloadHandler}
                />
                <DownloadRowBtn
                  text="Students not enrolled in batch"
                  downloadType="NOT_ALLOCATED_IN_BATCH"
                  handler={downloadHandler}
                />
              </CardBody>
            </Card>
          </>
        )}
      />
    </div>
  );
};

export default DownloadFiles;
