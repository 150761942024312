import React, { useState } from 'react';
import { getAPI } from 'services/http';
import CustomButton from 'components/CustomButton';
import DownloadIcon from 'assets/img/svg/download.svg';
import { fetchAllPostPromisedData } from '../../utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../constant'
import { failureToast } from '../../utils/methods/toasterFunctions/function';
//all three props are mandatory


const DownLoadIcon = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.7369 0.761748H5.08489C3.00489 0.753748 1.30089 2.41075 1.25089 4.49075V15.2277C1.20589 17.3297 2.87389 19.0697 4.97489 19.1147C5.01189 19.1147 5.04889 19.1157 5.08489 19.1147H13.0729C15.1629 19.0407 16.8149 17.3187 16.8029 15.2277V6.03775L11.7369 0.761748Z" stroke="#00B0F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.4746 0.75V3.659C11.4746 5.079 12.6236 6.23 14.0436 6.234H16.7976" stroke="#00B0F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.64258 13.9497V7.90869" stroke="#00B0F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.29688 11.5942L8.64188 13.9492L10.9869 11.5942" stroke="#00B0F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}


const GetTemplate = ({ url, serviceName, isSaveVisible, userPermissions='R',label="Download Template", isText=false, icon = true, outlined=false, className=''}) => {
  const [isFetching, setISFetching] = useState(false);

  const downloadTemplate = async() => {
    setISFetching(true);
    getAPI(
      url,
      data => {
        if (data.code === 200) {
          // window.open(data.data['Template Url']);
          getFileUrl(data.data['Template Url']);
        } else {
          failureToast(data['message']);
          setISFetching(false);
        }
      },
      data => {
        failureToast(data['message']);
        setISFetching(false);
      }
    );
  };

    const getFileUrl = async(key) => {
    const templateUrl = await fetchAllPostPromisedData(`${endpoint.fetchS3Url.getDownloadUrl}?key=${key}`,{})
    if (templateUrl?.code === 200 && templateUrl?.data) {
      window.open(`${templateUrl?.data?.url}`)
      setISFetching(false);
    }else{
      setISFetching(false);
      failureToast("Something went wrong please try after sometime.")
    }
  }

  return (
    isText ? 
    <div className='d-flex flex-row'>
      <span onClick={downloadTemplate} style={{fontSize:16, color: '#00B0F5', cursor:isFetching ? ' not-allowed' :'pointer'}}>Download CSV &nbsp;&nbsp;{isFetching ?  <i className="fas fa-spinner fa-spin" /> : <DownLoadIcon />}</span>
    </div> :
    <CustomButton
      // type="download"
      className={`mr-2 floatRight mx-1 regular-large ${outlined?'outline-btn':''} ${className}`}
      content={
        <>
          {/* {isFetching ? <i className="fas fa-spinner fa-spin mr-1" /> : <i className="fas fa-download" />} */}
          {isFetching ? <i className="fas fa-spinner fa-spin mr-1 " /> : icon ?  <img src={DownloadIcon} /> : ''}
          &nbsp;&nbsp;{label}
        </>
      }
      permissionType={'R'}
      outline={outlined}
      onClick={downloadTemplate}
      permissionSet={userPermissions}
      disabled={isSaveVisible || isFetching}
    />
  );
};

export default GetTemplate;
