import { Dialog } from '@mui/material'
import React from 'react'
import { ReactComponent as CrossIcon } from 'assets/img/svg/CrossIcon.svg';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx';
import AButton from '../../../common/form-fields-mui/AButton';

const ReversalNotAllowedDialog = ({ open, setOpen }) => {
  const handleClose = () => setOpen(false);
  return (
    <Dialog
      open={open}
      PaperProps={{ sx: { maxWidth: 'unset', borderRadius: '12px' } }}
    >
      <div style={{ padding: '28px 24px', width: '750px' }}>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='heading-4 color-black'>
            Stock reversal for this transaction ID is not allowed
          </div>
          <IconButtonWrapper onClick={handleClose}>
            <CrossIcon width={24} height={24} />
          </IconButtonWrapper>
        </div>
        <div className='mt-2 regular-large'>
          Due to multiple transaction ID linked to material details, stock
          reversal is not allowed
        </div>

        <div className='mt-4'>
          <AButton
            className='ml-0'
            variant='primary_filled'
            onClick={handleClose}
          >
            Ok got it!
          </AButton>
        </div>
      </div>
    </Dialog>
  );
};

export default ReversalNotAllowedDialog