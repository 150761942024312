import React from "react";
import { withRouter } from "react-router-dom"
import { CardHeader, Card, Table } from "reactstrap";
import { MasterHeaderAuto } from "../../../../../common/commonComponents/masterHeader/masterHeader";
import { isEqual, openInNewTab, NewRow } from '../../../../../common/utils/methods/commonMethods/utilityMethod';
import CustomButton from "components/CustomButton";


const SearchTable = (props) => {
    const { data, userId, pageSize, pagination, searchType = "bulk", statusTobeEditedForBulk, statusTobeEditedForManual, statusToBeView, userPermissions, keyName } = props;

    const headerList = isEqual(searchType, "bulk") ? [
        { name: "S.No" },
        { name: "Process ID" },
        { name: "Description" },
        { name: "File Name" },
        { name: "Actions" },
    ] : [
        { name: "S.No" },
        { name: "Process ID" },
        { name: "Transaction ID" },
        { name: "Course Id" },
        { name: "Transaction ID Status" },
        { name: "Actions" },
    ];

    const listToRender = isEqual(searchType, "bulk") ? [
        'sno',
        'processInstanceId',
        'description',
    ] : [
        'sno',
        'processInstanceId',
        'transactioId',
        'courseId'
    ];

    return (
        <Card className="mt-4">
            <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Search Results</h3>
            </CardHeader>
            <div className="table-responsive">
                <Table className="align-items-center table-flush">
                    <MasterHeaderAuto headerList={headerList} />
                    <tbody className="list">
                        {data ?
                            data.map((el, index) => {
                                return (
                                    <NewRow data={{ ...el, sno: (((pagination.currentPage - 1) * pageSize) + index + 1) }} listToBeRendered={listToRender}>
                                        {isEqual(searchType, "bulk") &&
                                            <>
                                                <td className="text-center  white-breakSpace">
                                                    <span>{el.fileName}</span>
                                                </td>
                                            </>
                                        }
                                        {isEqual(searchType, "manual") &&
                                            <td className="text-center  white-breakSpace">
                                                {el?.status && el.status.toUpperCase()}
                                            </td>
                                        }
                                        <td className="text-center  white-breakSpace">
                                            <CustomButton
                                                className={'mx-1'}
                                                permissionType={'R'}
                                                icon={true}
                                                forTable={true}
                                                onClick={() => window.open(document.URL + `/coursefee/view/${userId}/${el.processInstanceId}`, '_blank')}
                                                permissionSet={userPermissions}
                                            />
                                            {isEqual(searchType, "bulk") ?
                                                <>
                                                    {
                                                        // isEqual(el.actionStatus, statusTobeEditedForBulk) || isEqual(el.actionStatus, 'L1ApprovedPartial')
                                                        //     ?
                                                        //     <CustomButton
                                                        //         className={'mx-1'}
                                                        //         permissionType={'U'}
                                                        //         icon={true}
                                                        //         forTable={true}
                                                        //         onClick={() => {
                                                        //             props.history.push(`/admin/${keyName}/coursefee/edit/${userId}/${el.processInstanceId}`)
                                                        //         }}
                                                        //         permissionSet={userPermissions}
                                                        //     />
                                                        //     :
                                                        //     null
                                                        userId == 10001 && el?.l1Status && (isEqual(el?.l1Status, 'Submitted') || isEqual(el?.l1Status, 'L1Partial')) ?
                                                            <CustomButton
                                                                className={'mx-1'}
                                                                permissionType={'U'}
                                                                icon={true}
                                                                forTable={true}
                                                                onClick={() => {
                                                                    props.history.push(`/admin/${keyName}/coursefee/edit/${userId}/${el.processInstanceId}`)
                                                                }}
                                                                permissionSet={userPermissions}
                                                            />
                                                            :
                                                            null
                                                    }
                                                    {
                                                        userId == 10002 && el?.l2Status && (isEqual(el?.l2Status, 'L2Partial')) ?
                                                            <CustomButton
                                                                className={'mx-1'}
                                                                permissionType={'U'}
                                                                icon={true}
                                                                forTable={true}
                                                                onClick={() => {
                                                                    props.history.push(`/admin/${keyName}/coursefee/edit/${userId}/${el.processInstanceId}`)
                                                                }}
                                                                permissionSet={userPermissions}
                                                            />
                                                            :
                                                            null


                                                    }
                                                </>
                                                :
                                                <>
                                                    {isEqual(el.status, statusTobeEditedForManual) &&
                                                        <CustomButton
                                                            className={'mx-1'}
                                                            permissionType={'U'}
                                                            icon={true}
                                                            forTable={true}
                                                            onClick={() => {
                                                                props.history.push(`/admin/${keyName}/coursefee/edit/${userId}/${el.processInstanceId}`)
                                                            }}
                                                            permissionSet={userPermissions}
                                                        />
                                                    }
                                                </>
                                            }
                                        </td>
                                    </NewRow>
                                )
                            })
                            : <span>No Record Found</span>}
                    </tbody>
                </Table>
            </div>
        </Card>
    );
};

export default withRouter(SearchTable);
