import React from 'react'
import styles from './styles.module.scss'

const TestSyllabusChapterRow = ({ chapterObject }) => {
  return (
    <div className={styles.test_syallabus_chapter_container}>
      <div className={styles['chapter-node']} style={{ fontWeight: 600, fontSize: '16px', color: '#0F0F0F' }}>
        {chapterObject?.title}
      </div>
      <div>
        {chapterObject?.children?.map((topicObject, index) => {
          return (
            <div key={topicObject?.title} className={styles.test_syallabus_topic_container}>
              <div className={styles['topic-node']} style={{}}>{topicObject?.title}</div>
              <div style={{}}>
                <ul style={{ marginBottom: 0, paddingLeft: '1rem' }}>
                  {topicObject?.children?.map((subtopicObject, index) => {
                    return <li key={subtopicObject?.title}>{subtopicObject?.title}</li>
                  })}
                </ul>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TestSyllabusChapterRow