import React, { useEffect, useMemo, useState } from 'react'
import StockDetailsTabs, { AcademicCareerTabs } from './StockDetailsTabs';
import { getAcademicYears } from './constant';
import TransactionAccordion from './TransactionAccordion';

const StockDetails = () => {
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [activeTab, setActiveTab] = useState('issuance');
  const [academicCareer, setAcademicCareer] = useState('RCC');
  const academicYears = useMemo(getAcademicYears, []);

  useEffect(() => {
    // this collapses the open accordion whenever any of the tab value is changed
    setExpandedIndex(-1);
  }, [activeTab, academicCareer])

  return (
    <div className='d-flex flex-column gap-lg'>
      <AcademicCareerTabs activeTab={academicCareer} setActiveTab={setAcademicCareer} />
      <StockDetailsTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      
      {academicYears.map((year, index) => (
        <TransactionAccordion
          key={year}
          year={year}
          expanded={expandedIndex === index}
          toggle={() => setExpandedIndex(prev => (prev !== index ? index : -1))}
          activeTab={activeTab}
          academicCareer={academicCareer}
        />
      ))}
    </div>
  );
}

export default StockDetails