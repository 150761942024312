import React from "react";
import { Card, CardHeader, Input, Container, Button, Table, Row } from "reactstrap";
import Select from "react-select2-wrapper";
import { useState } from "react";
import { failureToast } from "../../common/utils/methods/toasterFunctions/function";
import { validateAlphaNumeric, validateAlphaNumericWithSpecialChar } from "../../common/utils/methods/validations/validation";
import { MasterHeaderAuto } from "../../common/commonComponents/masterHeader/masterHeader";
import { getUniqueKey, NewRow, findObjectById } from "../../common/utils/methods/commonMethods/utilityMethod";
import { masterServiceBaseUrl, successToast, putpost, putpost2 } from "services/http";
import moment from "moment";
import { CustomUpload, Dialog, GetTemplate } from "../../common/commonComponents";
import Style from "./subTopic.module.scss";
import ExportToExcel from "../../common/commonComponents/ToExcel";
import { endpoint } from "../../common/constant";
import CreateButton from "components/FormComponent/Button/New/index";
import UpdateButton from "components/FormComponent/Button/Update/index";
import EditButton from "components/FormComponent/Button/Edit/index";
import { ButtonText } from "variables/Buttons";

var _ = require("lodash");

const SubTopicTable = (props) => {
  const { isSaveVisible, setisSaveVisible, activeSubject, subject, state } = props;
  const [tableDataKey, settableDataKey] = useState("tableDataKey");
  const [updatedKey, setUpdateKey] = useState(new Date());
  const [isDisabled, setIsDisabled] = useState(false);
  const [description, setDescription] = useState("");
  const [subTopicId, setSubTopicId] = useState("");
  const [isFileValid, setISFileValid] = useState(false);
  const [filename, setFileName] = useState("Drag & Drop to Upload");
  const [file, setFile] = useState(null);
  const [progressBarStatus, setProgressBarStatus] = useState(0);
  const [isUploading, setISUploading] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);

  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: "__new",
        status: "ACTIVE",
        subjectMasterId: null,
        description: null,
        subTopicId: null,
        effectiveDate: moment(),
      },
      ...props.classArray,
    ]);
  };

  const headerList = [
    { name: "S.NO", width: "80px" },
    { name: "Subject", isRequired: true },
    { name: "Description", isRequired: true },
    { name: "SUB Topic ID", isRequired: true },
    { name: "Effective Date", width: "150px" },
    { name: "Status", width: "115px" },
    { name: "Action", width: "140px" },
  ];
  const listToRender = ["id", "subjectMasterId", "description", "subTopicId", "effectiveDate", "status"];

  const arrList = [null, subject, null, null, null];

  const propertyList = [null, "subjectDispValue", null, null, null];

  const itemToBeRenderFromMaster = [null, "subjectMasterId"];

  const saveAll = (cb) => {
    try {
      let _tempArray = props.classArray;
      let isValid = true;
      _tempArray.forEach((el, index) => {
        if (el["editMode"]) {
          if (!el["subjectMasterId"]) {
            isValid = false;
            failureToast("Please Select Subject ");
            return;
          } else if (!el["description"]) {
            isValid = false;
            failureToast("Please Enter Description");
            return;
          } else if (!el["subTopicId"]) {
            isValid = false;
            failureToast("Please Enter Subject Topic ID");
            return;
          }

          if (!isValid) {
            return;
          }
          if (_tempArray[index]["__status"] == "__new") {
            putpost(
              masterServiceBaseUrl + "/subTopicMaster/createSubTopicMaster",
              (data) => {
                successToast(data["message"]);
                setisSaveVisible(false);
                setUpdateKey(new Date());
                props.fetchClasses(`${masterServiceBaseUrl}/subTopicMaster/getAllSubTopicMaster`, "TableData");
              },
              (data) => {
                failureToast(data["message"]);
              },
              _tempArray[index],
              "post"
            );
          } else {
            putpost(
              masterServiceBaseUrl + "/subTopicMaster/updateSubTopicMaster/" + _tempArray[index].id,
              (data) => {
                successToast(data["message"]);
                setisSaveVisible(false);
                props.fetchClasses(`${masterServiceBaseUrl}/subTopicMaster/getAllSubTopicMaster`, "TableData");
                settableDataKey(new Date().getMilliseconds());
              },
              (data) => {
                failureToast(data["message"]);
              },
              _tempArray[index],
              "put"
            );
          }
        } else {
        }
      });
    } catch (e) {
      console.log("Error", e);
    }
  };

  const newRow = () => {
    try {
      if (isSaveVisible) {
        failureToast("Please Delete or Save current changes");
      } else {
        let cb = () => {
          addNewClass();
          setisSaveVisible(true);
        };
        var _tempArray = props.classArray;
        _tempArray = _.sortBy(_tempArray, [
          function (o) {
            return o.value;
          },
        ]);
        props.setclassArray(_tempArray);
        setisSaveVisible(false);
        cb();
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const editRow = (index) => {
    try {
      if (isSaveVisible) {
        failureToast("Please Delete or Save current changes");
      } else {
        let cb = () => {
          var _tempArray = props.classArray;
          setDescription(_tempArray[index].description);
          _tempArray[index]["editMode"] = true;
          props.setclassArray(_tempArray);
          setisSaveVisible(true);
        };
        let _tempArray = props.classArray;
        setSubTopicId(_tempArray[index].subTopicId);
        _tempArray = _.sortBy(_tempArray, [
          function (o) {
            return o.value;
          },
        ]);
        cb();
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const deleteRow = (index) => {
    try {
      if (props.classArray[index]["__status"] == "__new") {
        let n = props.classArray;
        n.splice(index, 1);
        props.setclassArray(n);
        setisSaveVisible(false);
        settableDataKey(new Date().getMilliseconds());
      } else {
        if (window.confirm("Are you sure you want to delete this Sub Topic?")) {
          putpost2(
            masterServiceBaseUrl + "/subTopicMaster/deleteSubTopicMaster/" + props.classArray[index].id,
            (data) => {
              successToast(data["message"]);
              if (props.classArray.length == 1) {
                props.previousPage();
                props.fetchClasses(`${masterServiceBaseUrl}/subTopicMaster/getAllSubTopicMaster`, "TableData");
              } else {
                var _tempArray = props.classArray;
                if (_tempArray[index]["editMode"]) {
                  setisSaveVisible(false);
                }
                _tempArray.splice(index, 1);
                props.setclassArray(_tempArray);
              }
              settableDataKey(new Date().getMilliseconds());
            },
            (data) => {
              failureToast(data["message"]);
            },
            "PUT"
          );
        }
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const updateKey = (index, value, key) => {
    try {
      var _tempArray = props.classArray;
      _tempArray[index][key] = value;
      props.setclassArray(_tempArray);
      setUpdateKey(new Date());
    } catch (e) {
      console.log("Error", e);
    }
  };

  const reset = () => {
    setISFileValid(false);
    setFileName("Drag & Drop to Upload");
    setFile(null);
    setProgressBarStatus(0);
    setISUploading(false);
  };

  const handleFile = (e) => {
    try {
      if (e.name.split(".")[1] === "csv") {
        setFileName(e.name);
        setFile(e);
        setISFileValid(true);
      } else {
        failureToast("Only csv files are allowed");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const dataToServer = {
    bulk: true,
    fileName: "Sub Topic Data",
    docType: "csv",
    keyValue: `subtopic_${getUniqueKey()}`,
    serviceName: "MASTER",
    subProcessType: "SUBSUBTOPIC"
  };

  const excelHandler = (arr, keyName, id, displayValue) => {
    const value = findObjectById(arr, keyName, id);
    return value ? value[displayValue] : "NOT FOUND";
  };

  const excelDetails = {
    Subject: "subject",
    Description: "description",
    "Sub Topic ID": "subTopicId",
    "Effective Date": "effectiveDate",
    Status: "status",
  };

  const exportData = state?.map((element, index) => {
    return {
      ...element,
      subject: excelHandler(subject, "id", element?.subjectMasterId, "subjectDispValue"),
      description: element?.description,
      subTopicId: element?.subTopicId,
      effectiveDate: element?.effectiveDate,
      status: element?.status,
    };
  });

  const saveFileAndReturnData = (data) => {
    putpost(
      `${masterServiceBaseUrl}/subTopicMaster/createBulkSubTopicMaster?csvUrl=${data?.Location}&processInstanceId=${data?.processId}`,
      (data) => {
        setProgressBarStatus(100);
        successToast("File Uploaded Successfully");
        props.fetchClasses(`${masterServiceBaseUrl}/subTopicMaster/getAllSubTopicMaster`, "TableData");
        setModalVisible(!isModalVisible);
        reset();
      },
      (data) => {
        failureToast("Uploading Failed");
      },
      {},
      "post"
    );
  };

  return (
    <>
      <Container className="mt-3" fluid>
        <Dialog
          title="Sub Topic Bulk Upload"
          isModalVisible={isModalVisible}
          toggle={() => {
            setModalVisible(!isModalVisible);
            reset();
          }}
        >
          <CustomUpload
            documentType="Sub Topic"
            isFileValid={isFileValid}
            filename={filename}
            file={file}
            progressBarStatus={progressBarStatus}
            setISFileValid={setISFileValid}
            setFileName={setFileName}
            setFile={setFile}
            setProgressBarStatus={setProgressBarStatus}
            isUploading={isUploading}
            setISUploading={setISUploading}
            handleFile={handleFile}
            dataToServer={dataToServer}
            saveHandler={saveFileAndReturnData}
          />
        </Dialog>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0 d-flex justify-content-between">
                <h3 className="mb-0 ">Sub Topic</h3>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className={"mx-2"}>
                    <ExportToExcel data={exportData} detailObj={excelDetails} name="Sub Topic" />
                  </div>
                  <GetTemplate url={endpoint.subTopicTemplate} />
                  <Button color="info" size="sm" type="button" className={"floatRight"} onClick={() => setModalVisible(true)}>
                    <i className="fas fa-upload" />
                    &nbsp;&nbsp;Upload Template
                  </Button>
                  <CreateButton
                    text={ButtonText.SubTopic.addNew}
                    disabled={isSaveVisible || isDisabled}
                    className={"floatRight" + (isSaveVisible || isDisabled ? " btn-dark" : null)}
                    onClick={() => {newRow()}}
                  />
                </div>
              </CardHeader>
              <Card className={`mb-0 ${Style.table__card}`}>
                <div id="questionTable" className="table-responsive">
                  <Table className={`table align-items-center table-flush tableLayout ${Style.table__block}`}>
                    <MasterHeaderAuto headerList={headerList} />
                    <tbody className="list" key={tableDataKey}>
                      {props.classArray
                        ? props.classArray.map((el, index) =>
                            !el["editMode"] ? (
                              <>
                                <NewRow
                                  data={el}
                                  listToBeRendered={listToRender}
                                  propertyList={propertyList}
                                  itemToBeRenderFromMaster={itemToBeRenderFromMaster}
                                  arrList={arrList}
                                >
                                  <td className="text-center  white-breakSpace">
                                  <EditButton 
                                    disabled={props.isViewOnly}
                                    onClick={() => editRow(index)} 
                                  />
                                    {/* <Button color="info" size="sm" type="button" disabled={props.isViewOnly} onClick={() => editRow(index)}>
                                      <i className="fas fa-edit" />
                                    </Button> */}
                                  </td>
                                </NewRow>
                              </>
                            ) : (
                              <tr key={index + "-class"}>
                                <td className="text-center p-2 mx-1">
                                  <Input disabled defaultValue={el["id"]} placeholder="S.NO" className="tdInput" />
                                </td>
                                <td className="text-center p-2 mx-1">
                                  <Select
                                    defaultValue={el["subjectMasterId"]}
                                    options={{ placeholder: "Select" }}
                                    data={activeSubject}
                                    disabled={el["__status"] ? false : true}
                                    onChange={(e) => {
                                      updateKey(index, e.target.value, "subjectMasterId", true);
                                    }}
                                  />
                                </td>
                                <td className="text-center p-2 mx-1">
                                  <Input
                                    maxLength="150"
                                    value={description}
                                    disabled={el["__status"] ? false : true}
                                    onChange={(e) => {
                                      if (!validateAlphaNumericWithSpecialChar(e.target.value)) {
                                        setDescription(e.target.value);
                                        updateKey(index, e.target.value.trim(), "description", true);
                                      }
                                    }}
                                    placeholder="Description"
                                    className="tdInput"
                                  />
                                </td>
                                <td className="text-center p-2 mx-1">
                                  <Input
                                    maxLength="15"
                                    value={subTopicId}
                                    disabled={el["__status"] ? false : true}
                                    onChange={(e) => {
                                      if (!validateAlphaNumeric(e.target.value)) {
                                        setSubTopicId(e.target.value);
                                        updateKey(index, e.target.value.trim(), "subTopicId", true);
                                      }
                                    }}
                                    placeholder="Sub Topic ID"
                                    className="tdInput"
                                  />
                                </td>
                                <td className="text-center p-2 mx-1">
                                  <Input disabled value={moment(el["effectiveDate"]).format("DD-MM-YYYY")} />
                                </td>
                                <td className="text-center p-2 mx-1">
                                  <label className="custom-toggle mx-auto ml-2">
                                    <input
                                      data-testid="activeInactive"
                                      checked={el.status == "ACTIVE" ? true : false}
                                      type="checkbox"
                                      onChange={(e) => {
                                        settableDataKey(new Date().getMilliseconds());
                                        let n = "ACTIVE";
                                        if (!e.target.checked) {
                                          n = "INACTIVE";
                                        }
                                        updateKey(index, n, "status");
                                      }}
                                    />
                                    <span
                                      style={{ width: "72px" }}
                                      className="custom-toggle-slider rounded-circle activeToggle"
                                      data-label-off="Inactive"
                                      data-label-on="Active"
                                    />
                                  </label>
                                </td>
                                <td className="text-center">
                                  {isSaveVisible ? (
                                    <>
                                      {" "}
                                      <UpdateButton text={ButtonText.SubTopic.save} onClick={() => { saveAll() }} data-testid="saveRow"/>
                                      {/* <Button
                                        data-testid="saveRow"
                                        color="info"
                                        size="sm"
                                        type="button"
                                        className="mx-1"
                                        onClick={() => {
                                          saveAll();
                                        }}
                                      >
                                        Save
                                      </Button> */}
                                      <Button
                                        data-testid="closeRow"
                                        color="danger"
                                        size="sm"
                                        type="button"
                                        onClick={() => {
                                          let n = props.classArray;
                                          if (n[index]["__status"] == "__new") {
                                            n.splice(index, 1);
                                          } else {
                                            props.fetchClasses(`${masterServiceBaseUrl}/subTopicMaster/getAllSubTopicMaster`, "TableData");
                                          }
                                          props.setclassArray(n);
                                          setisSaveVisible(false);
                                          setDescription("");
                                          setSubTopicId("");
                                        }}
                                      >
                                        {" "}
                                        <i className="fas fa-times" />
                                      </Button>{" "}
                                    </>
                                  ) : (
                                    <Button
                                      data-testid="deleteRow"
                                      color="info"
                                      size="sm"
                                      type="button"
                                      onClick={() => {
                                        deleteRow(index);
                                      }}
                                    >
                                      {" "}
                                      <i className="fas fa-trash" />
                                    </Button>
                                  )}
                                </td>
                              </tr>
                            )
                          )
                        : null}
                    </tbody>
                  </Table>
                </div>
              </Card>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default SubTopicTable;
