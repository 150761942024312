import CustomTooltip from 'views/pages/manage/common/customTooltip/CustomTooltip';
import React, { useEffect, useState } from 'react'
import { ReactComponent as CopyIcon } from '../../../../../assets/img/svg/CopyClipboardIcon.svg'

const CustomCopyToClipboard = ({ textToCopy, tooltipTheme='dark' }) => {

  const [tooltipText, setTooltipText] = useState("Copy");
  const [timeoutID, setTimeoutID] = useState(null);

  const resetTooltip = () => setTimeout(() => {
    setTooltipText("Copy")
  }, 5000)

  useEffect(() => {
    return () => {
      clearTimeout(timeoutID);
    }
  }, [timeoutID])

  return (
    <>
      <CustomTooltip title={tooltipText} theme={tooltipTheme}>
        <div style={{ display: 'inline-block', marginLeft: "5px" }}>
          <CopyIcon
            onClick={() => {
              navigator.clipboard.writeText(textToCopy);
              setTooltipText("Copied");
              setTimeoutID(resetTooltip());
            }}
          />
        </div>
      </CustomTooltip>
    </>
  )
}

export default CustomCopyToClipboard