import React, { useContext, useEffect, useMemo, useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";

import { TimetableContext } from "../contextApi/TimetableContext";

import "react-big-calendar/lib/css/react-big-calendar.css";

import { LECTURE_TYPE_ITEMS, getEventColor } from "./constant";

const CalenderViewTimeTable = ({
  lectures,
  setIsAddEventModalOpen,
  formData,
  setFormData,
  handleOpenLectureModal,
  handleOpenPTMModal,
  handleOpenCustomHolidayModal,
}) => {
  const { pastLectures } = useContext(TimetableContext);
  const [updatedLectures, setUpdatedLectures] = useState([]);
  const localizer = momentLocalizer(moment);

  const { components, defaultDate, scrollToTime, views, formats } = useMemo(
    () => ({
      //   views: { week: true, day: true },
      defaultDate: new Date(),
      scrollToTime: new Date(2011, 1, 1, 8),
      formats: {
        eventTimeRangeFormat: () => {
          return "";
        },
        timeGutterFormat: (date, culture, localizer) =>
          localizer.format(date, "h A", culture),
      },
      components: {
        // timeSlotWrapper: CustomTimeslots,
        // toolbar: CustomToolbar,
        week: {
          event: CustomWeekEvent,
          header: CustomHeader,
        },
        day: {
          event: CustomDayEvent,
        },
      },
    }),
    [updatedLectures]
  );

  useEffect(() => {
    const allEvents = [...lectures, ...pastLectures];
    const modifyNewEvents = allEvents.map((event) => {
      let eventStartTime,
        eventEndTime,
        title = LECTURE_TYPE_ITEMS.includes(event.classType)
          ? `${event?.subject?.substring(0, 3)} | ${event.lectureName}`
          : event.classType;

      if (
        event.lectureStartTime &&
        event.classType.toLowerCase() !== "holiday"
      ) {
        eventStartTime = event.lectureStartTime;
        eventEndTime = event.lectureEndTime;
      } else if (event.classType.toLowerCase() === "holiday") {
        const { lectureStartTime, lectureEndTime } = event;

        eventStartTime = moment(lectureStartTime).startOf("day");
        eventEndTime = moment(lectureEndTime).endOf("day");

        title = `Hol | ${event.lectureName}`;
      } else {
        const { date } = event;
        const startTime = moment(event.startTime, "HH:mm");
        const endTime = moment(event.endTime, "HH:mm");

        eventStartTime = moment(date)
          .set("hour", startTime.hour())
          .set("minute", startTime.minute());

        eventEndTime = moment(date)
          .set("hour", endTime.hour())
          .set("minute", endTime.minute());
      }

      return {
        ...event,
        lectureStartTime: new Date(eventStartTime),
        lectureEndTime: new Date(eventEndTime),
        title,
      };
    });

    setUpdatedLectures(modifyNewEvents);
  }, [lectures]);

  const handleSelectEvent = (e) => {
    // edit existing events

    const isSelectedDatePast = moment(e.lectureStartTime).isBefore(
      moment(),
      "minutes"
    );
    if (isSelectedDatePast) return;

    const selectedDate = moment(e.lectureStartTime)
      .set({ hour: 0, minute: 0, second: 0 })
      .format();
    const selectedStartTime = moment(e.lectureStartTime).format("HH:mm");
    const selectedEndTime = moment(e.lectureEndTime).format("HH:mm");

    const {
      id,
      classType,
      subject,
      roomId,
      roomName,
      lectureId,
      facultyId,
      facultyName,
      bachTimeTablePsidMapping,
      msTeam,
    } = e;

    const indexInLecturesArr = lectures.findIndex((item) => item.id === id);

    setFormData({
      ...formData,
      id,
      date: selectedDate,
      startTime: selectedStartTime,
      endTime: selectedEndTime,
      subject,
      room: { label: roomName, value: roomId },
      lecture: lectureId,
      faculty: { name: facultyName, value: facultyId },
      classType,
      selectedIndex: indexInLecturesArr,
      isMSteams: msTeam.value,
      bachTimeTablePsidMapping: bachTimeTablePsidMapping,
    });

    if (["ptm", "orientation"].includes(classType.toLowerCase()))
      handleOpenPTMModal(classType);
    else if (classType.toLowerCase() === "holiday")
      handleOpenCustomHolidayModal(indexInLecturesArr, e);
    else handleOpenLectureModal(classType);
  };

  const handleSelectSlot = (e) => {
    // add new events
    const isSelectedDatePast = moment(e.start).isBefore(moment(), "day");
    const selectedDate = moment(e.start)
      .set({ hour: 0, minute: 0, second: 0 })
      .format();
    const selectedStartTime = moment(e.start).format("HH:mm");
    const selectedEndTime = moment(e.end).format("HH:mm");

    if (selectedStartTime === selectedEndTime || isSelectedDatePast) return;

    setIsAddEventModalOpen(true);
    setFormData({
      ...formData,
      date: selectedDate,
      startTime: selectedStartTime,
      endTime: selectedEndTime,
    });
  };

  return (
    <div>
      {" "}
      <div>
        <Calendar
          localizer={localizer}
          defaultDate={defaultDate}
          defaultView={Views.WEEK}
          //   dayLayoutAlgorithm="no-overlap"
          scrollToTime={scrollToTime}
          views={views}
          formats={formats}
          events={updatedLectures}
          components={components}
          startAccessor="lectureStartTime"
          endAccessor="lectureEndTime"
          style={{ height: 500 }}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
          selectable
          eventPropGetter={(updatedLectures) => {
            const backgroundColor = getEventColor(updatedLectures.classType);
            const color = "#fff";

            return { style: { backgroundColor, color } };
          }}
        />
      </div>
    </div>
  );
};

export default CalenderViewTimeTable;

const CustomHeader = ({ label, date }) => {
  const labels = label.split(" ");
  const isTodayDate = moment(date).isSame(moment(), "day");

  return (
    <div className="d-flex flex-column align-items-center justify-content-between">
      <div className="text-uppercase color-black" style={{ fontSize: "10px" }}>
        {labels[1]}
      </div>
      <div
        style={{
          borderRadius: "50%",
          backgroundColor: isTodayDate ? "#00B0F5" : "#e2e0e096",
          height: "36px",
          width: "36px",
          textAlign: "center",
        }}
      >
        <p
          className="text-bold mb-0"
          style={{ lineHeight: "2.2", color: isTodayDate ? "#fff" : "#000" }}
        >
          {labels[0]}
        </p>
      </div>
    </div>
  );
};

const CustomWeekEvent = ({ event, title }) => {
  const formatTime = (time24hr) => {
    const time = moment(time24hr, "HH:mm");
    return time.format("hh:mm a");
  };

  return (
    <>
      <p className="text-xs text-bold my-0 text-nowrap">{title}</p>
      <p className="mb-0 text-nowrap" style={{ fontSize: "9px" }}>
        {formatTime(event.startTime)} - {formatTime(event.endTime)}
      </p>
    </>
  );
};

const CustomDayEvent = ({ event, title, ...props }) => {
  const formatTime = (time24hr) => {
    const time = moment(time24hr, "HH:mm");
    return time.format("hh:mm a");
  };

  return (
    <>
      <p className="text-xs text-bold my-0">{title}</p>
      <p className="mb-0" style={{ fontSize: "9px" }}>
        {formatTime(event.startTime)} - {formatTime(event.endTime)}
      </p>
    </>
  );
};
