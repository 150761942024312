import React, { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import ADataGrid from "views/pages/manage/common/data-grid/ADataGrid";
import { AGridColDef } from "views/pages/manage/common/data-grid/ADataGridColDef";
import { gridStyles,gridComponents } from "views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import { labelStyle } from "./FormHeader";
import {ReactComponent as CloseIcon} from "../../../../../../../assets/img/svg/CloseIcon.svg"
const SelectAccountModal = (
    {
    accNoDropdownModalData,
    selectHandler,
    open,
    setOpen
    }
) => {
    // useEffect(()=>{
    // console.log('rows',accNoDropdownModalData);
    // },[])
    const headers = [
        new AGridColDef("bankAccount", "Account Number").setWidth(200)
          .renderCellComponent((params) => <span>{params.value || " --"}</span>),

        new AGridColDef("bankNumber", "IFSC").setWidth(200)
          .renderCellComponent((params) => <span>{params.value || " --"}</span>),
        
          new AGridColDef("gl1Account", "GL1 Code").setWidth(200)
          .renderCellComponent((params) => <span>{params.value || " --"}</span>),

          new AGridColDef("gl2Account", "GL2 Code").setWidth(200)
          .renderCellComponent((params) => <span>{params.value || " --"}</span>),

          new AGridColDef("description", "Description").setWidth(200)
          .renderCellComponent((params) => <span>{params.value || " --"}</span>),

        new AGridColDef("id", "Action", false).setWidth(200).columnAlign('right')
          .renderCellComponent((params) => (
            <AButton
              className='btn-left-0'
              size='xs'
              variant='primary_filled'
              onClick={() => selectHandler(params.value)}
            >
              Select
            </AButton>
          )),
      ];
    return ( 
        <Modal size="xl" isOpen={open}>
            <ModalBody>
            <div className="d-flex justify-content-between  mb-4">
    <span style={labelStyle}>Select A/C number</span>{" "}
    <span>
      <CloseIcon style ={{cursor:"pointer"}} onClick={()=>setOpen(false)} />
      </span>
      </div>
        <div
        style={{
          position: 'relative',
          height: '500px',
          border: "1px solid #CBCBCB",
          borderRadius: "12px",
          padding: '20px',
        }}
      >
        {/* {tableLoader && <ALoader />} */}
        <ADataGrid
          removeWrapperContainer={true}
          rows={accNoDropdownModalData}
          columns={headers}
          rowId={(row) => row.id}
          serverPagination={false}
          sx={{
            ...gridStyles,
            '.MuiDataGrid-main': {
              // height: `${60 + list?.length * 50}px`,
              // minHeight: `${60 + 50}px`,
              // maxHeight: getMaxHeightForTable(),
            },
          }}
          rowHeight={50}
          height={300}
          components={{ ...gridComponents }}
        />
      </div>
      </ModalBody>
      </Modal>

     );
}
 
export default SelectAccountModal;