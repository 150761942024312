import React, { useEffect, useState, useContext } from "react";
import ProcessTagTypeTable from './processTagTypeTable';
import Loader from 'react-loader-spinner'
import { masterServiceBaseUrl, getAPI } from 'services/http';
import { GetPagination } from "../../common/commonComponents/pagination/pagination";
import { failureToast, successToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import { PermissionContext, RolePermissions } from "appContext";
import { PermisionDenied } from "../../common/commonComponents";
import { pages } from "../../common/constant";

const ProcessTagType = () => {

    const permissions = useContext(PermissionContext);
    const userPermissions = RolePermissions(permissions, pages["processTagType"]['id']);

    const [classArray, setclassArray] = useState([])
    const [apiloader, setapiloader] = useState(false)
    const [state, setstate] = useState([])
    const [isSaveVisible, setisSaveVisible] = useState(false);
    const [pagination, setpagination] = useState({
        currentPage: 1,
        nextPage: null,
        previousPage: null,
        totalPage: null
    })
    const pageSize = 10
    const fetchClasses = async (url) => {
        setapiloader(true)
        getAPI(url, (data) => {
            setstate(data['data'])
            let n = pagination
            if (data['data'].length > pageSize) {
                n['nextPage'] = n.currentPage + 1
            }
            if (n.currentPage > 1) {
                n.previousPage = n.currentPage - 1
            }
            n['totalPage'] = Math.floor(data['data'].length / pageSize)
            if (data['data'].length % pageSize != 0) {
                n['totalPage'] += 1
            }
            setpagination(n)
            setapiloader(false)
            setclassArray(data['data'].slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || [])
        }, (data) => {
            failureToast(data['message'])
        })
    }

    const previousPage = () => {
        if (pagination.currentPage != 1) {
            setclassArray(state.slice((pagination.currentPage - 1) * (pageSize) - pageSize, (pagination.currentPage - 1) * pageSize))
            let n = pagination
            n['currentPage'] = n['currentPage'] - 1
            if (n['currentPage'] == 1) {
                n['previousPage'] = null
            } else {
                n['previousPage'] = n.currentPage
            }
            setpagination(n)
        }
    }
    useEffect(() => {
        fetchClasses(masterServiceBaseUrl + '/processTag/getAllProcessTag')
    }, [])
    return (
        userPermissions ?
            <React.Fragment>
                {apiloader ? <div className="mx-auto text-center py-5 my-5" style={{ height: "100vh" }}><Loader type="Rings" color="#00BFFF" height={70} width={70} timeout={10000} /></div> : <div></div>}
                <ProcessTagTypeTable
                    previousPage={previousPage}
                    fetchClasses={fetchClasses}
                    setclassArray={setclassArray}
                    classArray={classArray}
                    userPermissions={userPermissions}
                    isSaveVisible={isSaveVisible}
                    setisSaveVisible={setisSaveVisible}
                />
                {
                    pagination.totalPage > 1
                        ?
                        <GetPagination
                            isSaveVisible={isSaveVisible}
                            fetchClasses={fetchClasses}
                            setclassArray={setclassArray}
                            pagination={pagination}
                            setpagination={setpagination}
                            pageSize={pageSize}
                            state={state}
                        />
                        :
                        null
                }
            </React.Fragment> : <PermisionDenied />
    );
}
export default ProcessTagType;
