import React from 'react';
import { useState, useEffect } from "react"
import {Card, CardHeader, Button, Table,} from "reactstrap";
import CommonInput from "../../../common/formFeilds/input/commonInput";
import { MasterHeaderAuto } from 'views/pages/manage/common/commonComponents/masterHeader/masterHeader';
import {masterServiceBaseUrl, failureToast, getAPI, putpost, successToast} from "services/http";
import {DropDownButton, getSelectedIds} from "../examSyllabusForm";
import {StatusButton} from "../../../common/commonComponents/TableComponents";
import * as PropTypes from "prop-types";
import ConfirmationModal from "../Modal/ConfirmationModal";
import NoDataFound from "../../../common/noDataFound";
import ButtonWithLoader from "../../../common/commonComponents/buttonWithLoader";
import {RiFileExcel2Line} from "react-icons/ri";
import styles from '../../../common/commonComponents/ToExcel/exportFile.module.scss';
import fs from 'fs';
import { ExamSyllabusHelpers} from "../utils";
const headerList = [
    { name: "Subject",width:'16%'},
    { name: "Chapter",width:'16%'},
    { name: "Topic",width:'16%'},
    { name: "Sub topic",width:'16%'},
    { name: "Sub Sub topic",width:'16%'},
    { name: "Status",width:'8%'},
    { name: "Action",width:'12%'}
];

function Fragment(props) {
    return null;
}

Fragment.propTypes = {children: PropTypes.node};

// const checkValidation = (tempArray) =>{
//     let flag = true;
//     tempArray.map((item ,index) => {
//         if (flag) {
//             if (item.subject === '') {
//                 failureToast("Please Select Subject in row " + (index + 1));
//                 flag = false;
//                 return;
//             } else if (item.chapterIds.length === 0) {
//                 failureToast('Please select Chapter in row ' + (index + 1));
//                 flag = false;
//                 return;
//             } else if (item.topicIds.length === 0) {
//                 failureToast('Please select Topic in row ' + (index + 1));
//                 flag = false;
//                 return;
//             } else if (item.subTopicIds.length === 0) {
//                 failureToast('Please select Sub Topic in row ' + (index + 1));
//                 flag = false;
//                 return;
//             } else if (item.subSubTopicIds.length === 0) {
//                 failureToast('Please select Sub Sub Topic in row ' + (index + 1));
//                 flag = false;
//                 return;
//             }
//         }
//     })
//     return flag;
// }
const ExamSyllabusTable = (props) => {
    const {
        subject,setExamSyllabus,id, setApiLoader,headerData,
        examSyllabus,fetchDataBasedOnSubjectId,action,setIsCloseModelOpen,setIsAddNewModelVisible,setViewSubject,viewSubject
    } = props;
   
    const [count, setCount] = useState(0);
    const [state, setState] = useState([]);


    const [excelData, setExcelData] = useState([
        { businessArea: "hello", academicYear: "hello", academicPhase: "hello", batch: "hello", course: "hello", exam: "hello", subject: "hello", chapter: "hello", topic: "hello", subTopic: "hello", status: "Active" }
    ])
    const [isSaveVisible, setisSaveVisible] = useState(false)
    const [isDeleteModalOpen ,setIsDeleteModalOpen] =useState({isOpen:false,index:null});
    const [isSomeThingEdit,setIsSomeThingEdit] = useState(false);
   

    // const newRow = () => {
    //     if (isSaveVisible) {
    //         failureToast("Please Delete or Save current changes");
    //     }
    //     else {
    //         let newObject = {
    //             subject : '',
    //             chapter : '',
    //             topic : '',
    //             subTopic : '',
    //             subSubTopic : '',
    //             status : 'Active'
    //         };
    //         let tempArray = [newObject, ...examSyllabus];
    //         setExamSyllabus(tempArray)
    //         setisSaveVisible(true)
    //     }
    // }

    const deleteRow = (key,index,flag = false) => {
        if (key === '1' || key === '3') {
            setIsDeleteModalOpen({isOpen:true , index:index});
        } else if (key === '2') {
            if (action === 'new' && flag) {
                let tempArray = examSyllabus;
                let a = tempArray.splice(index, 1);
                setExamSyllabus([...tempArray]);
                setIsDeleteModalOpen({isOpen:false , index:null});
            }
            else if((action === 'edit' || action === 'view') && flag){
                let tempArray = examSyllabus;
                let a = tempArray.splice(index, 1);
                setExamSyllabus([...tempArray]);
                setIsDeleteModalOpen({isOpen:false , index:null});
            }
            else {
                setIsDeleteModalOpen({isOpen:false , index:null});
            }
        }
    }
    // const editRow = (index) => {
    //     if (isSaveVisible) {
    //         alert("Please Delete or Save current changes")
    //     }
    //     else {
    //         var tempArray = [...examSyllabus];
    //         tempArray[index]['editMode'] = true
    //         setExamSyllabus(tempArray)
    //         setisSaveVisible(true)
    //     }
    // }

    const onChangeHandler = (key,value,index)=>{
        let tempArray = examSyllabus;
        let tempObject = tempArray[index];
        if(key === 'subject'){
            if( tempObject.subject && tempObject.subject.value !== value.value) {
                tempObject = {
                    ...tempObject,
                    [key]: value,
                    chapterIds: [],
                    topicIds: [],
                    subTopicIds: [],
                    subSubTopicIds: []
                };
            }else{
                    tempObject = {
                    ...tempObject,
                    [key]: value,
                };
            }
            tempArray[index] = tempObject;
            setExamSyllabus([...tempArray]);
            fetchDataBasedOnSubjectId(value.value,index,value)
        }else{
            tempObject = {
                ...tempObject,
                [key] : value,
            };
            if(key === 'chapterIds' || key === 'topicIds' || key === 'subTopicIds' || key === 'subSubTopicIds'){
                tempObject = {
                    ...tempObject,
                    ['selected' + key] :getSelectedIds(value)
                }
            }
            tempArray[index] = tempObject;
            setExamSyllabus([...tempArray]);
        }
    }
    const editHandler =(id, index,value)=>{
        fetchDataBasedOnSubjectId(id,index,value)
        let tempArray = examSyllabus;
        let tempObj = tempArray[index];
        setState(tempObj);
        tempObj = {
            ...tempObj,
            isEdit:true
        }
        tempArray[index] = tempObj
        setExamSyllabus([...tempArray])
    }

    const saveRowHandler = (index) =>{
        let tempArray = [...examSyllabus];
        let tempObj = tempArray[index];

        if(tempObj.subject === ''){
            failureToast("Please Select Subject in row " + (index + 1));
        }
        else if(tempObj.chapterIds.length === 0){
            failureToast("Please Select Chapter in row " + (index + 1));
        }
        else if(tempObj.topicIds.length === 0){
            failureToast("Please Select Topic in row " + (index + 1));
        }
        else if(tempObj.subTopicIds.length === 0){
            failureToast("Please Select Sub Topic in row " + (index + 1));
        }
        else if(tempObj.subSubTopicIds.length === 0){
            failureToast("Please Select Sub Sub Topic in row " + (index + 1));
        }
        else{
            tempObj = {
                ...tempObj,
                isLoading :true
            }
            tempArray[index] = tempObj
            setExamSyllabus([...tempArray])
            if(action === 'edit' && !tempObj.isNew && tempObj.isEdit) {
                let id = tempObj.id;
                let payLoad = {
                    id : id,
                    chapterIds : getSelectedIds( tempObj['chapterIds']),
                    examSyllabusHeaderId : headerData.id,
                    status : tempObj.status,
                    subSubTopicIds : getSelectedIds( tempObj['subSubTopicIds']),
                    subTopicIds : getSelectedIds( tempObj['subTopicIds']),
                    subject : tempObj['subject']['value'],
                    topicIds : getSelectedIds( tempObj['topicIds']),
                }
                let url = masterServiceBaseUrl + '/examSyllabusMasterDetail/updateExamSyllabusMasterDetail/'+id;
                putpost(url,
                    (data) =>{
                        successToast(data.message);
                        tempObj = {
                            ...tempObj,
                            isEdit: false,
                            isLoading:false
                        }
                        tempArray[index] = tempObj;
                        setExamSyllabus([...tempArray])
                    },
                    (data) =>{
                        failureToast(data.message);
                    },
                    payLoad, 'PUT').then((res) => {
                    console.log("res....", res);
                });

            }else if(( action === 'edit') && tempObj.isNew){
                let url = masterServiceBaseUrl + `/examSyllabusMasterDetail/createExamSyllabusMasterDetail`;
                let payLoad = {
                    "chapterIds": tempObj['selectedchapterIds'],
                    "examSyllabusHeaderId": headerData.id,
                    "status": tempObj.status,
                    "subSubTopicIds": tempObj['selectedsubSubTopicIds'],
                    "subTopicIds": tempObj['selectedsubTopicIds'],
                    "subject": tempObj['subject']['value'],
                    "topicIds": tempObj['selectedtopicIds'],
                }
                putpost(url,
                    (data) =>{
                        successToast(data.message);
                        tempObj = {
                            ...tempObj,
                            id:data.data?.id,
                            isEdit: false,
                            isNew :false,
                            isLoading:false
                        }
                        tempArray[index] = tempObj;
                        setExamSyllabus([...tempArray])
                    },
                    (data) =>{
                        failureToast(data.message);
                    },
                    payLoad, 'POST').then((res) => {
                    console.log("res....", res);
                });
            }
            // tempArray[index] = tempObj;
            // console.log("Syllabus Rows------->",tempObj)
            // setExamSyllabus([...tempArray])
        }
    }

    useEffect(()=>{
        console.log('Exam Syllabus.........---->',examSyllabus);
    },[examSyllabus])

    const cancelHandler =(index)=>{
            let tempData = examSyllabus;
            let tempObj = tempData[index];
            if (tempObj?.isNew) {
                // tempData = tempObj;
                {if(window.confirm("Are you sure want to delete row!"))
                    tempData.splice(index, 1);
                    setExamSyllabus([...tempData]);
                }
            } else {
                let tempObj = state;
                tempObj = {
                    ...tempObj,
                    isEdit: false
                }
                tempData[index] = tempObj;
                setExamSyllabus([...tempData]);
            }
    }
    const [isExportButtonLoading,setIsExportButtonLoading] = useState(false)
    let xlsx = require('json-as-xlsx')
    const exportHandler = ()=>{
        setIsExportButtonLoading(true)
        let xlsx = require('json-as-xlsx')
        let contentForHeader = [];
        let exportedHeaderData = ExamSyllabusHelpers.getHeaderExportData(headerData,'HeaderDetails');
        let exportedTableData = ExamSyllabusHelpers.getExamSyllabusData(examSyllabus,'Exam Syllabus');
        if(headerData && exportedHeaderData){
            // exportedHeaderData  = {
            //     // sheet: 'Header Details',
            //     ...exportedHeaderData
            // }
        }
        if(examSyllabus && examSyllabus.length){

        }
        let data = [
                // exportedHeaderData,
                exportedTableData
                // sheet: 'Children',
                // columns: [
                //     { label: 'User', value: 'user' }, // Top level data
                //     { label: 'Age', value: row => (row.age + ' years') }, // Run functions
                //     { label: 'Phone', value: 'user.more.phone' }, // Deep props
                // ],
                // content: [
                //     { user: 'Manuel', age: 16, more: { phone: '99999999' } },
                //     { user: 'Ana', age: 17, more: { phone: '87654321' } }
                // ]

        ]

        let settings = {
            fileName: 'ExamSyllabus', // Name of the resulting spreadsheet
            extraLength: 5, // A bigger number means that columns will be wider
            writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
        }
        xlsx(data, settings)
        setIsExportButtonLoading(false)
    }
    return (
        <>
            <Card style={{marginBottom:0}}>
                <CardHeader >
                    <h3 className="mb-0 d-inline">Exam Syllabus</h3>

                    {
                        action === 'new' || action === 'edit' ?
                            <Button
                                disabled={isSaveVisible}
                                color="info" size="sm" type="button"
                                className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
                                onClick={() => setIsAddNewModelVisible(true)}
                                data-testid="addNewExamSyllabus">
                                Add New
                            </Button>
                            :
                            null
                    }
                    <ButtonWithLoader loading={isExportButtonLoading} color="success"  size="sm" type="button" className={`floatRight mx-1 mr-3 ${styles.export_button}`}
                                      disabled={examSyllabus.length ===0}
                                      onClick={exportHandler}
                                      icon={<RiFileExcel2Line/>}
                                      data-testid="addNewExamSyllabus">
                         Export To Excel
                    </ButtonWithLoader>
                </CardHeader>
                {/*<div>*/}
                    <Table className="align-items-center table-flush tableLayout" size="sm">
                        <MasterHeaderAuto headerList={action === 'view' ? headerList.slice(0, 6) : headerList}/>
                        <tbody>
                        {

                            !(examSyllabus && examSyllabus.length > 0 )?
                                <tr>
                                    <td colSpan={action === 'view' ? '6' : '7'}>
                                        <NoDataFound/>
                                    </td>
                                </tr> :
                                examSyllabus.map((item, index) =>
                                <tr className=" " key={'ExamSyllabusRow'+index}>
                                    <td className="p-2 mx-1">
                                        <CommonInput
                                            type={'select'}
                                            menuPlacement={'top'}
                                            forTable={true}
                                            data={subject}
                                            isDisabled={(action === 'view' || (action === 'edit' && !item.isEdit)) || (!item.isNew || !item.isEdit)}
                                            // value={3}
                                            placeHolder={'Subject'}
                                            defaultValue={item.subject}
                                            onChange={value => onChangeHandler('subject', value, index)}
                                        />
                                    </td>
                                    <td className="p-2 mx-1">
                                        {action === 'view'  || !item.isEdit ?
                                            <DropDownButton direction={'up'} data={item.chapterIds}
                                                // direction={'up'}
                                            />
                                            :
                                            <CommonInput
                                                type={'multiSelectWithCheckboxes'}
                                                menuPlacement={'top'}
                                                forTable={true}
                                                data={item.chapterDropDownValues}
                                                placeHolder={'Chapter'}
                                                defaultValue={item.chapterIds }
                                                onChange={value => onChangeHandler('chapterIds', value, index)}
                                            />}
                                    </td>
                                    <td className="p-2 mx-1">
                                        {action === 'view' ||   !item.isEdit ?
                                            <DropDownButton data={item.topicIds} direction={'up'}/>
                                            :
                                            <CommonInput
                                                type={'multiSelectWithCheckboxes'}
                                                menuPlacement={'top'}
                                                forTable={true}
                                                data={item.topicDropDownValues}
                                                placeHolder={'Topic'}
                                                defaultValue={item.topicIds}
                                                onChange={value => onChangeHandler('topicIds', value, index)}
                                            />
                                        }
                                    </td>
                                    <td className="p-2 mx-1">
                                        {action === 'view' || !item.isEdit ?
                                            <DropDownButton data={item.subTopicIds} direction={'up'}/>
                                            :
                                            <CommonInput
                                                type={'multiSelectWithCheckboxes'}
                                                menuPlacement={'top'}
                                                forTable={true}
                                                data={item.subTopicDropDownValues}
                                                placeHolder={'Sub Topic'}
                                                defaultValue={item.subTopicIds}
                                                onChange={value => onChangeHandler('subTopicIds', value, index)}
                                            />
                                        }
                                    </td>
                                    <td className="p-2 mx-1">
                                        {action === 'view' || !item.isEdit ?
                                            <DropDownButton data={item.subSubTopicIds} direction={'up'}/>
                                            :
                                            <CommonInput
                                                type={'multiSelectWithCheckboxes'}
                                                menuPlacement={'top'}
                                                forTable={true}
                                                data={item.subSubTopicDropDownValues}
                                                placeHolder={'Sub Sub Topic'}
                                                defaultValue={item.subSubTopicIds}
                                                onChange={value => onChangeHandler('subSubTopicIds', value, index)}
                                            />
                                        }
                                    </td>
                                    <td className="text-center  p-2 mx-1">
                                        {action === 'view' || !item.isEdit ?
                                            <StatusButton status={item.status}/>
                                            :
                                            <CommonInput
                                                type={'toggle'}
                                                forTable={true}
                                                dataLabelOff={'INACTIVE'}
                                                dataLabelOn={'ACTIVE'}
                                                defaultValue={item.status}
                                                onChange={value => onChangeHandler('status', value, index)}
                                            />
                                        }
                                    </td>
                                    {action === 'view' ? null :
                                        <td className="text-center p-2 mx-1">
                                            {
                                                item.isEdit ?
                                                    <>
                                                       <ButtonWithLoader loading={item.isLoading}  style={{width:'90px'}} color="info" size="sm" type="button"
                                                                 onClick={() => saveRowHandler(index)}>
                                                            {/*<i className="fa fa-check"/> */}
                                                            Save
                                                        </ButtonWithLoader>

                                                        {<Button disabled={item.isLoading} color="danger" size="sm" type="button"
                                                                 onClick={() => cancelHandler(index)}>
                                                            <i className="fas fa-times"/>
                                                        </Button>}
                                                    </>
                                                    :
                                                    <>
                                                        <Button color="info" size="sm" type="button"
                                                                onClick={()=>editHandler(item.subject.value,index,item.subject)}>
                                                            <i className="fas fa-edit"/>
                                                        </Button>
                                                        {/*<Button color="danger" size="sm" type="button"*/}
                                                        {/*        onClick={() => deleteRow('3',index)}>*/}
                                                        {/*    <i className="fas fa-trash"/>*/}
                                                        {/*</Button>*/}
                                                    </>
                                            }
                                        </td>
                                    }
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                {/*</div>*/}
            </Card>
            <ConfirmationModal isDeleteModalOpen={isDeleteModalOpen} deleteHandler={deleteRow} isSomeThingEdit={''}/>
        </>
    );
}

export default ExamSyllabusTable;