import React, { useState,useEffect } from "react";
import {
  Card,
  CardHeader,
  Input,
  Container,
  Button,
  Table,
  Row,
  Col,
} from "reactstrap";
import moment from 'moment'


const CommonHeader = (props) => {
  const {courseAttributeData=[],courseAttributValueData=[], termData=[],academicCareerData=[],data} = props;

  const getNameById = (id, list) => {
    const item = list.filter((item) => {
      return item && item.id === id;
    });
    const name = item.length ? item[0].text : 'Not Found';
    return name;
  };

  useEffect( () => {
    // console.log('props.grpKey',props.grpKey)
  }, [ props ] );

  return (
    <Container fluid className={'mt-3'}>
    <Row>
    <Col>
      <Card className={'p-3 d-flex flex-row align-items-center justify-content-between'}>
        <i className="fa fa-arrow-left pr-3" style={{cursor: 'pointer'}} onClick={props.closeHandler}/>
        <h4>Add Approvals</h4>
      </Card>
      <Card className={'p-3'}>
      <div className="form-row mb-3">
          <Col className="mb-3" md="6">
            <label className="form-control-label">Business Group</label>
            <Input
              value={props.grpKey}
              type="text"
              disabled
              maxLength="50"
              style={{ height: "39px" }}
            />
          </Col>
          <Col className="mb-3" md="6">
            <label className="form-control-label">Company Code</label>
            <Input
              value={props.companyKey}
              type="text"
              maxLength="50"
              disabled
              style={{ height: "39px" }}
            />
            <div className="invalid-feedback"></div>
          </Col>
        </div>
        <div className="form-row mb-3">
          <Col className="mb-3" md="6">
            <label className="form-control-label">Business Area - Name</label>
            <Input
              value={props.areaName}
              type="text"
              disabled
              maxLength="50"
              style={{ height: "39px" }}
            />
          </Col>
          <Col className="mb-3" md="6">
            <label className="form-control-label">Course Attribute Value</label>
            <Input
              value={data && getNameById(data.attributeValueId,courseAttributValueData)}
              type="text"
              maxLength="50"
              disabled
              style={{ height: "39px" }}
            />
            <div className="invalid-feedback"></div>
          </Col>
        </div>
        <div className="form-row mb-3">
          <Col className="mb-3" md="6">
            <label className="form-control-label">Academic Career</label>
            <Input
              value={data && getNameById(data.academicCareerId,academicCareerData)}
              type="text"
              maxLength="50"
              style={{ height: "39px" }}
              disabled
            />
          </Col>
          <Col className="mb-3" md="6">
            <label className="form-control-label">Term</label>
            <Input
              value={data && getNameById(data.termId,termData)}
              type="text"
              maxLength="50"
              style={{ height: "39px" }}
              disabled
            />
            <div className="invalid-feedback"></div>
          </Col>
        </div>
        <div className="form-row mb-3">
          <Col className="mb-3 d-flex flex-row" md="6">
            <span className="form-control-label">Last Updated Date : </span>
            <span>{data && moment(data.updatedOn).format('DD-MM-YYYY')}</span>
          </Col>
          <Col className="mb-3 d-felx flex-row" md="6">
            <span className="form-control-label">{'Total Approval Levels :'} </span>
            <span>{props.totallevel}</span>
          </Col>
        </div>
        {/* <div className="form-row mb-3">
        <Button
          color="primary"
          type="button"
          className={`ml-auto`}
          onClick={submitHandler}
        >
          Next
        </Button>
      </div> */}
      </Card>
    </Col>
    </Row>
    </Container>
  );
};

export default CommonHeader;
