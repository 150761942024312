import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter,ModalHeader } from 'reactstrap';
import  style from './model.index.scss';

const messages = {
    selectAcademicCareer :'Please Select Academic Career.',
    dataNotFound : 'No matching values found.',
    selectBusinessGroup : 'Please Select Business Group',
    selectCompanyCode : 'Please Select Company Code',
    selectTerm : 'Please Select Term',
    selectCourseType : 'Please Select Course Type',
    unsavedChanges : 'This page contains unsaved data. Do you want to continue without saving the changes?',
    closeMessage: 'Are you sure you want to leave this page?',
    deleteMessage: 'Are you sure you want to delete this row?'
}
const data = {
    messages : messages,
}

const ConfirmationModal = ({deleteHandler=()=>{},modelTitle,isDeleteModalOpen=false,isSomeThingEdit=false}) => {

    return (
        <div>
            <Modal isOpen={isDeleteModalOpen.isOpen} className="custom_model" centered  backdrop={'static'}>
                <ModalBody className={style.model_body}>
                    <span style={{marginRight: '10px',fontWeight:'900'}}>{isSomeThingEdit ? data?.messages?.unsavedChanges : data?.messages?.deleteMessage}</span>
                </ModalBody>
                <ModalFooter style={{borderTop:'1px solid #e9ecef', padding:'5px'}} className={style.model_footer}>
                    <Button color="info" onClick={()=>deleteHandler('2',isDeleteModalOpen.index, true)} style={{width:'50px'}} size='sm'>Yes</Button>
                    <Button color="info" onClick={()=>deleteHandler('2',false)} style={{width:'50px'}} size='sm'>No</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ConfirmationModal;