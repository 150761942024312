import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Col, Button } from "reactstrap";
import CustomLoader from "views/pages/manage/common/commonComponents/Loader/loader";
import { RiUploadCloudFill } from "react-icons/ri";
import { CustomContainer, CustomCard, GetTemplate } from "views/pages/manage/common/commonComponents";
import {
  fetchDetails,
  getRequiredSign,
} from "../../../common/utils/methods/commonMethods/utilityMethod";
import CoreMaster from "../../../common/commonComponents/coreMaster";
import { failureToast } from "services/http";
import { endpoint } from "../../../common/constant"
import DocumentUpload from './attendanceMachineUpload'

const Cardheader = ({setModalVisible=()=>{}}) => {
  return (
    <>
      <h3>Attendance Machine Master</h3>
      <div className={"d-flex align-items-center"}>
        <CoreMaster
          master={"attendanceMachineMaster"}
          masterDisplayValue={"MACHINE ATTENDANCE"}
        />
        <GetTemplate url={endpoint.attendanceMasterTemplate} isSaveVisible={false}/>
        <Button
          color={"info"}
          size={"sm"}
          className={"mx-3"}
          onClick={() => setModalVisible(true)}
        >
          <RiUploadCloudFill className="pr-1" size={18} />
          Upload CSV
        </Button>
      </div>
    </>
  );
};

const Header = (props) => {
  const {
    headerValues,
    setHeaderValues = () => {},
    searchAttendanceMachine = () => {},
    setAttendanceMachineData = () => {},
    isView = false,
    resetHeader = () => {},
  } = props;
  const [groupCodeData, setGroupCode] = useState(null);
  const [companyCodeData, setCompanyCodeData] = useState(null);
  const [zoneData, setZoneData] = useState(null);
  const [regionData, setRegionData] = useState(null);
  const [businessData, setBusinessData] = useState(null);
  const [isModalVisible,setModalVisible] = useState(false);

  useEffect(() => {
    fetchDetails(
      endpoint.groupCode.getAllActive,
      setGroupCode,
      "groupCode",
      "select"
    );
    // fetchDetails(
    //   `${masterServiceBaseUrl}/companyCode/getAllActiveCompanyCode`,
    //   setCompanyCodeData,
    //   "companyCode",
    //   "select"
    // );
    fetchDetails(
      endpoint.operationalZone.getAllActive,
      setZoneData,
      "operationalZone",
      "select"
    );
    fetchDetails(
      endpoint.region.getAllActive,
      setRegionData,
      "region",
      "select"
    );
  }, []);

  const search = () => {
    if (!headerValues?.businessGroup?.value) {
      failureToast("Please Select Business Group");
      return;
    }
    if (!headerValues?.companyCode?.value) {
      failureToast("Please Select Comapny Code");
      return;
    }
    if (!headerValues?.zone?.value) {
      failureToast("Please Select Zone");
      return;
    }
    if (!headerValues?.region?.value) {
      failureToast("Please Select Region");
      return;
    }
    if (!headerValues?.businessArea?.value) {
      failureToast("Please Select Business Area");
      return;
    }

    searchAttendanceMachine();
  };

  return (
    <CustomContainer>
      <CustomCard HeaderElement={<Cardheader setModalVisible={setModalVisible}/>}>
        {!groupCodeData || !zoneData || !regionData ? (
          <div
            className="mx-auto text-center py-5 my-5"
            style={{ height: "100vh" }}
          >
            <CustomLoader
              apiLoader={!groupCodeData || !zoneData || !regionData}
            />
          </div>
        ) : (
          <>
            <div className="form-row mb-3 p-4">
              <Col className="mb-3" md="4">
                <label className="form-control-label">
                  Business Group{getRequiredSign()}
                </label>
                <Select
                  value={headerValues.businessGroup}
                  id="building_header_business_grp"
                  options={groupCodeData}
                  isDisabled={isView}
                  placeholder="Select Business Group"
                  onChange={(sData) => {
                    setHeaderValues({
                      ...headerValues,
                      businessGroup: sData,
                      companyCode: null,
                      businessArea: null,
                    });
                    setBusinessData(null);
                    setCompanyCodeData(null);
                    setAttendanceMachineData(null);
                    fetchDetails(
                      `${endpoint.companyCode.getAllActiveByGroupCode}/${sData.value}`,
                      setCompanyCodeData,
                      "companyCode",
                      "select"
                    );
                  }}
                />
              </Col>
              <Col className="mb-3">
                <label className="form-control-label">
                  Company Code{getRequiredSign()}
                </label>
                <Select
                  value={headerValues.companyCode}
                  id="building_header_company_code"
                  options={companyCodeData}
                  isDisabled={isView}
                  placeholder={"Select Company Code"}
                  onChange={(sData) => {
                    setHeaderValues({ ...headerValues, companyCode: sData });
                    setBusinessData(null);
                    setAttendanceMachineData(null);
                    headerValues?.businessGroup?.value &&
                      fetchDetails(
                        `${endpoint.businessArea.getAllActiveByGroupCodeAndComapnyCode}?companyCode=${sData.value}&groupCode=${headerValues?.businessGroup?.value}`,
                        setBusinessData,
                        "businessArea",
                        "select"
                      );
                  }}
                  isLoading={headerValues.businessGroup && !companyCodeData}
                />
              </Col>
              <Col className="mb-3" md="4">
                <label className="form-control-label">
                  Zone{getRequiredSign()}
                </label>
                <Select
                  value={headerValues.zone}
                  id="building_header_zone"
                  options={zoneData}
                  isDisabled={isView}
                  placeholder="Select Zone"
                  onChange={(sData) => {
                    setHeaderValues({ ...headerValues, zone: sData });
                    setAttendanceMachineData(null);
                  }}
                />
              </Col>
            </div>
            <div className="form-row mb-3 pl-4 pr-4">
              <Col className="mb-3" md="4">
                <label className="form-control-label">
                  Region{getRequiredSign()}
                </label>
                <Select
                  value={headerValues.region}
                  id="building_header_region"
                  options={regionData}
                  isDisabled={isView}
                  placeholder="Select Region"
                  onChange={(sData) => {
                    setHeaderValues({ ...headerValues, region: sData });
                    setAttendanceMachineData(null);
                  }}
                />
              </Col>
              <Col className="mb-3" md="4">
                <label className="form-control-label">
                  Business Area{getRequiredSign()}
                </label>
                <Select
                  value={headerValues.businessArea}
                  id="building_header_business_area"
                  options={businessData}
                  isDisabled={isView}
                  placeholder={
                    headerValues?.businessGroup?.value &&
                    headerValues?.companyCode?.value
                      ? "Select Business Area"
                      : "Select  Bussiness Group & Company Code"
                  }
                  onChange={(sData) => {
                    setHeaderValues({ ...headerValues, businessArea: sData });
                    setAttendanceMachineData(null);
                  }}
                  isLoading={
                    headerValues?.businessGrp?.value &&
                    headerValues?.companyCode?.value &&
                    !businessData
                  }
                />
              </Col>
              <Col
                md="4"
                className="d-flex justify-content-end align-items-end"
              >
                {/* <Button
                  color="info"
                  size="sm"
                  type="button"
                  onClick={resetHeader}                 
                >
                  Reset
                </Button> */}
                <Button
                  color="info"
                  size="sm"
                  type="button"
                  className="ml-3"
                  onClick={search}
                >
                  Search
                </Button>
              </Col>
            </div>
          </>
        )}
      </CustomCard>
      <DocumentUpload isModalVisible = {isModalVisible} setModalVisible = {setModalVisible} getAllData={headerValues.businessArea?.value ? searchAttendanceMachine : () => {}}/>
    </CustomContainer>
  );
};

export default Header;
