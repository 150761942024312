export const ButtonText = {
  COMMON: {
    SEARCH : 'Search',
    UPDATE : 'Save',
    RESET : 'Reset'
  },
  CourseCatalog: {
    new: "Add New Course",
    search: "Search",
    reset: "Reset",
  },
  CourseCatalogSequence: {
    new: "Add New Sequence",
    search: "Search",
    reset: "Reset",
  },
  MSGroupSequence: {
    addNew: "Add New",
    search: "Search",
    save: "Save",
  },
  OperationalZone: {
    addNew: "New Operational Zone",
    save: "Save",
  },
  OpportunityType: {
    addNew: "New Opportunity Type",
    save: "Save",
  },
  ParentOccupation: {
    NEW: "New Parent's Occupation",
    UPDATE: "Save",
  },
  PineLabsPaymentMode: {
    addNew: "Add New System",
    save: "Save",
  },
  PermissionDetails: {
    addNew: "Add New",
    save: "Save",
  },
  PersonType: {
    addNew: "New Person Type",
    save: "Save"
  },
  PhoneType: {
    NEW: "New Phone Type",
    UPDATE: "Save"
  },
  Pincode: {
    addNew: "New Pincode",
    save: "Save"
  },
  ProcessTagType: {
    addNew: "New Tag Type",
    save: "Save"
  },
  ProfitCenterMapping: {
    addNew: "Add Profit Center",
    save: "Save"
  },
  ProjectDetails: {
    addNew: "Add New",
    save: "Save"
  },
  ReceiptLabel: {
    addNew: "Add Receipt Label",
    save: "Save"
  },
  Region: {
    addNew: "New Region",
    save: "Save"
  },
  SaleType: {
    NEW: "New Sale Type",
    UPDATE: "Save"
  },
  Session: {
    addNew: "New Session",
    save: "Save"
  },
  State: {
    addNew: "New State",
    save: "Save"
  },
  StudentIdStatus: {
    NEW: "New Student ID Status",
    UPDATE: "Save"
  },
  Subject: {
    addNew: "New Subject",
    save: "Save"
  },
  SubjectArea: {
    addNew: "New Subject Area",
    save: "Save"
  },
  SubSubTopic: {
    addNew: "Add Sub Sub Topic",
    save: "Save"
  },
  SubTopic: {
    addNew: "New Sub Topic",
    save: "Save"
  },
  TaxCodeType: {
    addNew: "New Tax Code Type",
    save: "Save"
  },
  TermMaster: {
    addNew: "New Term",
    save: "Save"
  },
  TestHandledBy: {
    addNew: "New Test Handled By",
    save: "Save"
  },
  TestMethod: {
    addNew: "New Test Method",
    save: "Save"
  },
  TestMode: {
    NEW: "New Test Mode",
    UPDATE: "Save"
  },
  TestSequence: {
    addNew: "New Test Sequence",
    save: "Save"
  },
  TestType: {
    NEW: "New Test Type",
    UPDATE: "Save"
  },
  ThirdPartySystems: {
    addNew: "New Test System",
    save: "Save"
  },
  Topic: {
    addNew: "Add Topic",
    save: "Save"
  },
  VendorType: {
    addNew: "New Vendor Type",
    save: "Save"
  },
  WebsiteSync: {
    addNew: "New Website Sync",
    save: "Save"
  },
  ApplicationIdGeneration : {
    addNew : 'Add New Application ID',
    search : 'Search',
    reset : 'Reset'
  },
  StudentInfo : {
    addNew : 'Add New Student',
    search: 'Search',
    reset: 'Reset Filters'
  },
  BUSINESSAREASEARCH : {
    NEW : 'New Bussiness Area',
    SEARCH : 'Search',
    UPDATE : 'Save',
    RESET : 'Reset'
  },
  DOCUMENTMANAGEMENT : {
    NEW : 'New Document',
    SEARCH : 'Search',
    UPDATE : 'Save',
    RESET : 'Reset'
  },
  BULKUPLOAD : {
    NEW : 'Upload Document',
    SEARCH : 'Search',
    UPDATE : 'Save',
    RESET : 'Reset'
  },
  TEMPLATE : {
    NEW : 'New Template',
    SEARCH : 'Search',
    UPDATE : 'Save',
    RESET : 'Reset'
  },
  DOCUMENTTYPE : {
    NEW : 'New Document Type',
    SEARCH : 'Search',
    UPDATE : 'Save',
    RESET : 'Reset'
  },
  DOCUMENTFORMAT : {
    NEW : 'New Document Format',
    SEARCH : 'Search',
    UPDATE : 'Save',
    RESET : 'Reset'
  },
  DOCUMENTPROCESSTAG : {
    NEW : 'New Document Process Tag',
    SEARCH : 'Search',
    UPDATE : 'Save',
    RESET : 'Reset'
  },
  PROCESSTAGTYPE: {
    NEW : 'New Tag Type',
    SEARCH : 'Search',
    UPDATE : 'Save',
    RESET : 'Reset'
  },
  COURSEFEEINFORMATON : {
    NEW : 'New',
    SEARCH : 'Search',
    UPDATE : 'Save',
    RESET : 'Reset'
  },
  PROCESSINSTANCESEARCH : {
    NEW : 'Upload Template',
    DOWNLOAD : 'Download Template',
    SEARCH : 'Search',
    UPDATE : 'Save',
    RESET : 'Reset'
  },
  UPLOADPAGE: {
    SUBMIT : 'Submit',
    CLOSE : 'Close',
    ADD : 'Add',
    NEXT : 'Next',
  },
  LANGUAGEMASTER: {
    ADD : 'Add New Language'
  },
  MeetingType:{
    NEW : 'New Meeting Type',
    UPDATE : 'Save'
  },
  AddressCategory:{
    NEW : 'New Address Category',
    UPDATE : 'Save'
  },
  AdjustmentFlag:{
    NEW : 'New Adjustment Flag',
    UPDATE : 'Save'
  },
  Gender:{
    NEW : 'New Gender',
    UPDATE : 'Save'
  },
  AddressType:{
    NEW : 'New Address Type',
    UPDATE : 'Save'
  },
  EmailType:{
    NEW : 'New Email Type',
    UPDATE : 'Save'
  },
  AdmissionType:{
    NEW : 'New Admission Type',
    UPDATE : 'Save'
  },
  Category:{
    NEW : 'New Category',
    UPDATE : 'Save'
  },
  BloodGroup:{
    NEW : 'New Blood Group',
    UPDATE : 'Save'
  },
  ExamGroup:{
    NEW : 'New Exam Group',
    UPDATE : 'Save'
  },
  ExamPattern:{
    NEW : 'New Exam Pattern',
    UPDATE : 'Save'
  },
  ExamBoardType:{
    NEW : 'New Exam Board Type',
    UPDATE : 'Save'
  },
  DepartmentType: {
    NEW : 'New Department Type',
    UPDATE : 'Save'
  },
  JobTitle: {
    NEW : 'New Job Title',
    UPDATE : 'Save'
  },
  PaymentMethod: {
    NEW : 'New Payment Method',
    UPDATE : 'Save'
  }, 
  CLASSSECTION: {
    NEW : 'New Class Section',
    SEARCH : 'Search',
    RESET : 'Reset',
    UPDATE: 'Save',
  },
  ProgramAction: {
    save : "Save",
    addNew: "New Program Action"
  },
  ActionReason: {
    save : "Save",
    addNew: "New Action Reason"
  },
  CREATEBATCH: {
    UPDATE: 'Save',
    CLEAR: 'Clear',
    CLOSE: 'Close'
  },
  VIEWBATCH: {
    EDIT: 'Edit'
  },
  EDITBATCH: {
    UPDATE: 'Save',
    CLOSE: 'Close'
  },
  ProgramActionMapping: {
    addNew : "New Program Action Mapping",
    search: "Search",
    clear: "Clear"
  },
};
