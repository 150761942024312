import React from "react";
import { getAPI, putpost, putpost2, masterServiceBaseUrl } from "services/http";
import moment from "moment";
import { Input } from "reactstrap";
import { MasterServiceUrls } from "../../../../../../../variables/masterServiceUrls";
import { failureToast } from "../toasterFunctions/function";
import {
  SomethingWentWrongContext,
  SomethingWentWrongDispatch,
} from "../../../../../../../contexts/SomethingWentWrong";
import { UncontrolledTooltip } from "reactstrap";
import { Checkbox, FormControlLabel } from "@mui/material";

const _ = require("lodash");

let CryptoJS = require("crypto-js");

const isEqual = (firstParam, secondParam) => {
  return firstParam == secondParam;
};

const getUniqueKey = () =>
  new Date().getTime() + Math.floor(100000 + Math.random() * 900000);

const getUniqueElements = (arr, key) => {
  var seen = {};
  return arr.filter(function (item) {
    var k = key(item);
    return seen.hasOwnProperty(k) ? false : (seen[k] = true);
  });
};

const getRequiredSign = () => {
  return <span className="text-danger">*</span>;
};

export const RedAstric = () => <span style={{ color: "red" }}>*</span>;

export const getCurrentDate = () => moment(new Date()).format("DD-MM-YYYY");

const openInNewTab = async (url) => {
  try {
    window.open(`${document.URL}${url}`, "_blank");
  } catch (e) {
    //console.log("Error", e);
  }
};

const returnDropDownObjectById = (id, list) => {
  try {
    //console.log("Called method", id, list);
    const obj = list && list.find((item) => item["value"] === id);
    //console.log("Object", obj);
    return obj;
  } catch (e) {
    //console.log("Error", e);
  }
};

const returnIndexById = (id, list, property) => {
  try {
    const index = list && list.findIndex((item) => id == item[property]);
    return index;
  } catch (e) {
    console.log("Error :: ", e);
  }
};

const getObjectById = (id, list) => {
  try {
    const i = id && list?.filter((item) => item.id == id);
    return i && i?.length ? i[0] : {};
  } catch (e) {
    return {};
  }
};

const getValueBySearch = (id, list, property) => {
  try {
    const index = returnIndexById(id, list, "id");
    if (index >= 0) {
      return list[index][property];
    } else {
      return "Not Found";
    }
  } catch (e) {
    //console.log("Error ::", e);
  }
};

//TODO: USE ONLY FOR TO GET ALL DATA
/**
 *
 * @param {string} url
 * @param {stateMethod} stateMethod
 */
const fetchAllData = (url, stateMethod) => {
  getAPI(
    url,
    (data) => {
      stateMethod(data?.data);
    },
    (data) => {
      failureToast(data["message"]);
    }
  );
};

//TODO: USE ONLY FOR TO GET DATA FOR DROPDOWN or SPECIFIC DATA
/**
 * @param {string} url
 * @param {stateMethod} stateMethod
 * @param {For eg. groupCode, companyCode, academicCareer, businessArea etc.} parentName
 * @param {Choose Any one of them select/reactSelect/specificData} type
 * ? PLEASE DO NOT USE "type === 'specificData'" FOR ANY DROPDOWN IT IS ONLY USE FOR SPECIFIC DATA USE for eg=>
 * ? fetch DispValue/DescValue/KeyValue/NameValue on the basis of ID
 */
const fetchDetails = (
  url,
  stateMethod,
  parentName,
  type,
  isFailureToastNotReqired
) => {
  try {
    getAPI(
      url,
      (data) => {
        iterateData(data["data"], stateMethod, parentName, type);
      },
      (data) => {
        stateMethod([]);
        if (!isFailureToastNotReqired) {
          failureToast(data["message"]);
        }
      }
    );
  } catch (e) {
    //console.log("Error", e);
  }
};
const getDisplayValueKey = (parentName) => {
  let displayName;
  if (parentName === "topic") {
    displayName = "topicId";
  } else if (parentName === "chapter") {
    displayName = "displayValue";
  } else if (parentName === "subSubTopic") {
    displayName = "ssTopicId";
  } else if (parentName === "subTopic") {
    displayName = "subTopicId";
  } else if (parentName === "templateDatatypeMaster") {
    displayName = `${parentName}DispVal`;
  } else {
    displayName = `${parentName}DispValue`;
  }

  return displayName;
};

const iterateData = (data, stateMethod, parentName, type) => {
  try {
    /**
     * ? If you would like to add your own "type" (type === select/reactSelect/specificData) and its corresponding "data key value pair" feel fee to add in elseif conditions to use it with the below func's for DispValue/DescValue on the basis of ID.
     * ? PLEASE DO NOT USE "type === 'specificData'" FOR ANY DROPDOWN IT IS ONLY USE FOR SPECIFIC DATA USE
     * ? Use "type === 'specificData'" when we need to fetch DispValue/DescValue/KeyValue/NameValue on the basis of One ID only
     */

    // let dispName = `${parentName}DispValue`;
    let dispName = getDisplayValueKey(parentName);
    let dispName_2 = `${parentName}DisplayValue`;
    let descName = `${parentName}Desc`;
    let descName_2 = `${parentName}Description`;
    let shortDesc = `${parentName}ShortDesc`;
    let keyName = `${parentName}Key`;
    let name = `${parentName}Name`;
    let courseId = `${parentName}Id`;
    let glCode = `${parentName}Code`;

    let temp = [];

    if (type === "specificData") {
      data.map((item) => {
        temp.push({
          value: item.id, //Parent Id
          label: item[dispName] ? item[dispName] : item[keyName],
          desc: item[descName] ? item[descName] : item[descName_2],
          key: item[keyName],
          name: item[name],
          otherId_1: item.groupCodeId, //Child Id
          otherId_2: item.companyCodeId, //Child Id
          otherId_3: item.academicCareerId, //Child Id
          otherId_4: item.academicGroupId, //Child Id
        });
      });
    } else if (type === "select") {
      if (parentName === "courseDetails") {
        data.map((item) => {
          temp.push({
            value: item.courseId, //Parent Id
            label: `${item["courseId"]} - ${item["courseName"]}`,
            desc: item["courseDescription"],
          });
        });
      }
      if (parentName === "course") {
        data.map((item) => {
          temp.push({
            value: item?.id, //Parent Id
            label: item?.id,
            desc: item["courseDisplayValue"],
          });
        });
      } else {
        data.map((item) => {
          temp.push({
            value: item.id,
            label: item[dispName] ? item[dispName] : item[dispName_2],
            desc: item[descName] ? item[descName] : item[descName_2],
            id: item.id,
          });
        });
      }
    } else if (type === "reactSelect") {
      data.map((item) => {
        temp.push({
          id: item.id,
          text: item[dispName] ? item[dispName] : item[dispName_2],
          desc: item[descName] ? item[descName] : item[descName_2],
        });
      });
    } else if (type === "reactSelKeyDesc") {
      data.map((item) => {
        temp.push({
          id: item.id, //Parent Id
          text: `${item[dispName]} - ${item[descName]}`,
          desc: item[shortDesc] ? item[shortDesc] : item[descName],
        });
      });
    } else if (type === "multiDataForReactSelect") {
      data.map((item) => {
        temp.push({
          id: item.id, //Parent Id
          text: item[dispName],
          desc: item[descName],
          otherId_1: item.groupCodeId, //Child Id
          otherId_2: item.companyCodeId, //Child Id
          otherId_3: item.academicCareerId, //Child Id
          otherId_4: item.academicGroupId, //Child Id
        });
      });
    } else if (type === "courseDetails") {
      data.map((item) => {
        temp.push({
          id: item.courseId, //Parent Id
          text: `${item[courseId]} - ${item[name]}`,
          desc: item[descName_2],
        });
      });
    } else if (type === "category") {
      data.map((item) => {
        temp.push({
          id: item.id, //Parent Id
          text: `${item[dispName]} - ${item[descName]}`,
          desc: item[shortDesc],
        });
      });
    } else if (type === "glCodeKeyDesc") {
      data.map((item) => {
        temp.push({
          id: item.id, //Parent Id
          text: `${item[glCode]} - ${item[descName_2]}`,
          desc: item[descName] ? item[descName] : item[descName_2],
        });
      });
    } else if (type === "courseId") {
      data.map((item) => {
        temp.push({
          label: item.courseId, //Parent Id
          value: item.courseId,
        });
      });
    } else if (type === "examId") {
      data.map((item) => {
        temp.push({
          id: item.id, //Parent Id
          text: item.examId,
          examIdDescription: item.examIdDescription,
          examTypeId: item.examTypeId,
          classId: item.classId,
          examGroupId: item.examGroupId,
        });
      });
    } else if (type === "bankDetails") {
      data.map((item) => {
        temp.push({
          value: item.id,
          label: item.bankName,
        });
      });
    } else if (type === "pincode") {
      data.map((item) => {
        temp.push({
          id: item.id,
          text: item.pincodeDispValue,
          countryId: item.countryId,
          stateId: item.stateId,
          cityId: item.cityId,
        });
      });
    } else if (type === "building") {
      data.map((item) => {
        temp.push({
          value: item.id,
          label: item.buildingName,
          id: item.id,
        });
      });
    } else if (type === "pricingZone") {
      data.map((item) => {
        temp.push({
          value: item.id,
          label: item.pricingZoneName,
          name: item.pricingZoneDesc,
          id: item.id,
        });
      });
    } else if (type === "itemTypeSetupComponent") {
      data.map((item) => {
        temp.push({
          value: item.itemTypeSetupId,
          label: item.itemTypeSetupId,
          tax: item.totalTaxAmount,
          id: item.id,
          itemTypeSequenceDetailId: item.itemTypeSequenceDetailId,
        });
      });
    } else if (type === "termMapping") {
      data.map((item) => {
        temp.push({
          value: item.termId,
          label: item.termDispValue,
          id: item.termId,
          termMappingId: item.termMappingId,
        });
      });
    } else if (type === "securityCourseId") {
      data.map((item) => {
        temp.push({
          value: item.courseId,
          label: `${item.courseId} ${
            item?.courseTitle ? `(${item?.courseTitle?.substring(0, 30)})` : ""
          }`,
        });
      });
    } else if (type === "onlinePackage") {
      data.map((item) => {
        temp.push({
          value: item.id,
          label: item.onlinePackageName,
        });
      });
    } else if (type === "state") {
      data.map((item) => {
        temp.push({
          value: item?.stateDispValue ?? "",
          label: `${item?.stateDispValue} | ${item?.stateKey}` ?? "",
          gstCode: item?.stateGstCode ?? "",
          stateCode: item?.id,
        });
      });
    }
    //console.log("Temp_of_fetchDetails__", temp);
    stateMethod(temp);
  } catch (e) {
    //console.log("Error", e);
  }
};

/**
 * TODO:  getDispValForSpecificDataByOneId,
 *? getDispValForSpecificDataByIdOfId,
 *? getDispValForSelectByOneID,
 *? getDispValForSelectByIdOfId,
 *? getDispValForReactSelectByOneID,
 *? getDispValForReactSelectByIdOfId,
 *? All above methods will only work when you already fetch out the data (data as key for eg *? -> id,label,text,disp,desc,key,name,otherId_1/otherId_2... ) from the "fetchDetails" function (fetchDetails = (url, stateMethod, parentName, type) already added in this component as a func)
 *
 */

/**
 * ? PLEASE DO NOT USE THIS METHOD FOR ANY DROPDOWN IT IS ONLY USE FOR SPECIFIC DATA USE
 * ? Use this method when we need to fetch DispValue/DescValue/KeyValue/NameValue on the basis of One ID only
 * @param {ArrayData id/ParentID} id
 * @param {Choose Any one of them DispValue/DescValue/KeyValue/NameValue} type
 * @param {stateMethod} stateMethod
 * @returns
 */
const getDispValForSpecificDataByOneId = (id, type, stateMethod) => {
  try {
    if (type === "DispValue") {
      const indexID = findIndexForSelectDisplay(id, stateMethod);
      return indexID >= 0 ? stateMethod[indexID]["label"] : "NOT FOUND";
    } else if (type === "DescValue") {
      const indexID = findIndexForSelectDisplay(id, stateMethod);
      return indexID >= 0 ? stateMethod[indexID]["desc"] : "NOT FOUND";
    } else if (type === "KeyValue") {
      const indexID = findIndexForSelectDisplay(id, stateMethod);
      return indexID >= 0 ? stateMethod[indexID]["key"] : "NOT FOUND";
    } else if (type === "NameValue") {
      const indexID = findIndexForSelectDisplay(id, stateMethod);
      return indexID >= 0 ? stateMethod[indexID]["name"] : "NOT FOUND";
    }
  } catch (e) {
    //console.log("Error", e);
  }
};

/**
 * ? Use this method when we have type Specific json and need data with respect to ID of ID
 * ? ONLY FOR TO SHOW DISPLAY VALUE AND DESC VALUE RESPECT TO ID of ID
 * @param {ArrayData id/ParentID} id
 * @param {Choose Any one of them DispValue/DescValue} type
 * @param {Child Id type for eg -> otherId_1/otherId_2/otherId_3/otherId_4} otherIdType
 * @param {Parent Data stateMethod} stateMethod_1
 * @param {Child Data stateMethod} stateMethod_2
 * @returns
 */
const getDispValForSpecificDataByIdOfId = (
  id,
  type,
  otherIdType,
  stateMethod_1,
  stateMethod_2
) => {
  try {
    if (type === "DispValue") {
      if (otherIdType === "otherId_1") {
        const indexID = findIndexForSelectDisplay(id, stateMethod_1);
        const id_2 = stateMethod_1[indexID]["otherId_1"];
        const data = findDispAndDescValueForSelect(id_2, type, stateMethod_2);
        return data;
      } else if (otherIdType === "otherId_2") {
        const indexID = findIndexForSelectDisplay(id, stateMethod_1);
        const id_2 = stateMethod_1[indexID]["otherId_2"];
        const data = findDispAndDescValueForSelect(id_2, type, stateMethod_2);
        return data;
      } else if (otherIdType === "otherId_3") {
        const indexID = findIndexForSelectDisplay(id, stateMethod_1);
        const id_2 = stateMethod_1[indexID]["otherId_3"];
        const data = findDispAndDescValueForSelect(id_2, type, stateMethod_2);
        return data;
      } else if (otherIdType === "otherId_4") {
        const indexID = findIndexForSelectDisplay(id, stateMethod_1);
        const id_2 = stateMethod_1[indexID]["otherId_4"];
        const data = findDispAndDescValueForSelect(id_2, type, stateMethod_2);
        return data;
      }
    } else if (type === "DescValue") {
      if (otherIdType === "otherId_1") {
        const indexID = findIndexForSelectDisplay(id, stateMethod_1);
        const id_2 = stateMethod_1[indexID]["otherId_1"];
        const data = findDispAndDescValueForSelect(id_2, type, stateMethod_2);
        return data;
      } else if (otherIdType === "otherId_2") {
        const indexID = findIndexForSelectDisplay(id, stateMethod_1);
        const id_2 = stateMethod_1[indexID]["otherId_2"];
        const data = findDispAndDescValueForSelect(id_2, type, stateMethod_2);
        return data;
      } else if (otherIdType === "otherId_3") {
        const indexID = findIndexForSelectDisplay(id, stateMethod_1);
        const id_2 = stateMethod_1[indexID]["otherId_3"];
        const data = findDispAndDescValueForSelect(id_2, type, stateMethod_2);
        return data;
      }
    }
  } catch (e) {
    //console.log("Error", e);
  }
};

//* getDispValForSelectByOneID, getDispValForSelectByIdOfId
/**
 * ? Use this method when we have type Select dropdown only for One ID itself
 * ? ONLY FOR TO SHOW DISPLAY VALUE AND DESC VALUE RESPECT TO ID
 * @param {ArrayData id/ParentID} id
 * @param {Choose Any one of them DispValue/DescValue} type
 * @param {stateMethod} stateMethod
 * @returns
 */
const getDispValForSelectByOneID = (id, type, stateMethod) => {
  try {
    if (type === "DispValue") {
      const indexID = findIndexForSelectDisplay(id, stateMethod);
      return indexID >= 0 ? stateMethod[indexID]["label"] : "NOT FOUND";
    } else if (type === "DescValue") {
      const indexID = findIndexForSelectDisplay(id, stateMethod);
      return indexID >= 0 ? stateMethod[indexID]["desc"] : "NOT FOUND";
    }
  } catch (e) {
    //console.log("Error", e);
  }
};

/**
 * ? Use this method when we have type Select dropdown only for ID of ID
 * ? ONLY FOR TO SHOW DISPLAY VALUE AND DESC VALUE RESPECT TO ID of ID
 * @param {ArrayData id/ParentID} id
 * @param {Choose Any one of them DispValue/DescValue} type
 * @param {Child Id type for eg -> otherId_1/otherId_2/otherId_3/otherId_4} otherIdType
 * @param {Parent Data stateMethod} stateMethod_1
 * @param {Child Data stateMethod} stateMethod_2
 * @returns
 */
const getDispValForSelectByIdOfId = (
  id,
  type,
  otherIdType,
  stateMethod_1,
  stateMethod_2
) => {
  try {
    if (type === "DispValue") {
      if (otherIdType === "otherId_1") {
        const indexID = findIndexForSelectDisplay(id, stateMethod_1);
        const id_2 = stateMethod_1[indexID]["otherId_1"];
        const data = findDispAndDescValueForSelect(id_2, type, stateMethod_2);
        return data;
      } else if (otherIdType === "otherId_2") {
        const indexID = findIndexForSelectDisplay(id, stateMethod_1);
        const id_2 = stateMethod_1[indexID]["otherId_2"];
        const data = findDispAndDescValueForSelect(id_2, type, stateMethod_2);
        return data;
      } else if (otherIdType === "otherId_3") {
        const indexID = findIndexForSelectDisplay(id, stateMethod_1);
        const id_2 = stateMethod_1[indexID]["otherId_3"];
        const data = findDispAndDescValueForSelect(id_2, type, stateMethod_2);
        return data;
      } else if (otherIdType === "otherId_4") {
        const indexID = findIndexForSelectDisplay(id, stateMethod_1);
        const id_2 = stateMethod_1[indexID]["otherId_4"];
        const data = findDispAndDescValueForSelect(id_2, type, stateMethod_2);
        return data;
      }
    } else if (type === "DescValue") {
      if (otherIdType === "otherId_1") {
        const indexID = findIndexForSelectDisplay(id, stateMethod_1);
        const id_2 = stateMethod_1[indexID]["otherId_1"];
        const data = findDispAndDescValueForSelect(id_2, type, stateMethod_2);
        return data;
      } else if (otherIdType === "otherId_2") {
        const indexID = findIndexForSelectDisplay(id, stateMethod_1);
        const id_2 = stateMethod_1[indexID]["otherId_2"];
        const data = findDispAndDescValueForSelect(id_2, type, stateMethod_2);
        return data;
      } else if (otherIdType === "otherId_3") {
        const indexID = findIndexForSelectDisplay(id, stateMethod_1);
        const id_2 = stateMethod_1[indexID]["otherId_3"];
        const data = findDispAndDescValueForSelect(id_2, type, stateMethod_2);
        return data;
      }
    }
  } catch (e) {
    //console.log("Error", e);
  }
};

//* getDispValForReactSelectByOneID, getDispValForReactSelectByIdOfId
/**
 * ? Use this method when we have type react Select2 dropdown only for One ID itself
 * ? ONLY FOR TO SHOW DISPLAY VALUE AND DESC VALUE RESPECT TO ID
 * @param {ArrayData id/ParentID} id
 * @param {Choose Any one of them DispValue/DescValue} type
 * @param {stateMethod} stateMethod
 * @returns
 */
const getDispValForReactSelectByOneID = (id, type, stateMethod) => {
  try {
    if (type === "DispValue") {
      const indexID = findIndexForReactSelectDisplay(id, stateMethod);
      return indexID >= 0 ? stateMethod[indexID]["text"] : "NOT FOUND";
    } else if (type === "DescValue") {
      const indexID = findIndexForReactSelectDisplay(id, stateMethod);
      return indexID >= 0 ? stateMethod[indexID]["desc"] : "NOT FOUND";
    }
  } catch (e) {
    //console.log("Error", e);
  }
};

/**
 * ? Use this method when we have type react Select2 dropdown only for ID of ID
 * ? ONLY FOR TO SHOW DISPLAY VALUE AND DESC VALUE RESPECT TO ID of ID
 * @param {ArrayData id/ParentID} id
 * @param {Choose Any one of them DispValue/DescValue} type
 * @param {Child Id type for eg -> otherId_1/otherId_2/otherId_3/otherId_4} otherIdType
 * @param {Parent Data stateMethod} stateMethod_1
 * @param {Child Data stateMethod} stateMethod_2
 * @returns
 */
const getDispValForReactSelectByIdOfId = (
  id,
  type,
  otherIdType,
  stateMethod_1,
  stateMethod_2
) => {
  try {
    if (type === "DispValue") {
      if (otherIdType === "otherId_1") {
        const indexID = findIndexForReactSelectDisplay(id, stateMethod_1);
        const id_2 = stateMethod_1[indexID]["otherId_1"];
        const data = findDispAndDescValueForReactSelect(
          id_2,
          type,
          stateMethod_2
        );
        return data;
      } else if (otherIdType === "otherId_2") {
        const indexID = findIndexForReactSelectDisplay(id, stateMethod_1);
        const id_2 = stateMethod_1[indexID]["otherId_2"];
        const data = findDispAndDescValueForReactSelect(
          id_2,
          type,
          stateMethod_2
        );
        return data;
      } else if (otherIdType === "otherId_3") {
        const indexID = findIndexForReactSelectDisplay(id, stateMethod_1);
        const id_2 = stateMethod_1[indexID]["otherId_3"];
        const data = findDispAndDescValueForReactSelect(
          id_2,
          type,
          stateMethod_2
        );
        return data;
      } else if (otherIdType === "otherId_4") {
        const indexID = findIndexForReactSelectDisplay(id, stateMethod_1);
        const id_2 = stateMethod_1[indexID]["otherId_4"];
        const data = findDispAndDescValueForReactSelect(
          id_2,
          type,
          stateMethod_2
        );
        return data;
      }
    } else if (type === "DescValue") {
      if (otherIdType === "otherId_1") {
        const indexID = findIndexForReactSelectDisplay(id, stateMethod_1);
        const id_2 = stateMethod_1[indexID]["otherId_1"];
        const data = findDispAndDescValueForReactSelect(
          id_2,
          type,
          stateMethod_2
        );
        return data;
      } else if (otherIdType === "otherId_2") {
        const indexID = findIndexForReactSelectDisplay(id, stateMethod_1);
        const id_2 = stateMethod_1[indexID]["otherId_2"];
        const data = findDispAndDescValueForReactSelect(
          id_2,
          type,
          stateMethod_2
        );
        return data;
      } else if (otherIdType === "otherId_3") {
        const indexID = findIndexForReactSelectDisplay(id, stateMethod_1);
        const id_2 = stateMethod_1[indexID]["otherId_3"];
        const data = findDispAndDescValueForReactSelect(
          id_2,
          type,
          stateMethod_2
        );
        return data;
      }
    }
  } catch (e) {
    //console.log("Error", e);
  }
};

const findIndexForSelectDisplay = (id, datalist) => {
  const index = datalist.findIndex((item) => item["value"] == id);
  return index;
};

const findIndexForReactSelectDisplay = (id, datalist) => {
  const index = datalist.findIndex((item) => item["id"] == id);
  return index;
};

const findDispAndDescValueForSelect = (id, type, datalist) => {
  try {
    if (type === "DispValue") {
      const findIndex = datalist.findIndex((item) => item["id"] == id);
      return findIndex >= 0 ? datalist[findIndex]["label"] : "NOT FOUND";
    } else if (type === "DescValue") {
      const findIndex = datalist.findIndex((item) => item["id"] == id);
      return findIndex >= 0 ? datalist[findIndex]["desc"] : "NOT FOUND";
    }
  } catch (e) {
    //console.log("Error", e);
  }
};

const findDispAndDescValueForReactSelect = (id, type, datalist) => {
  try {
    if (type === "DispValue") {
      const findIndex = datalist.findIndex((item) => item["id"] == id);
      return findIndex >= 0 ? datalist[findIndex]["text"] : "NOT FOUND";
    } else if (type === "DescValue") {
      const findIndex = datalist.findIndex((item) => item["id"] == id);
      return findIndex >= 0 ? datalist[findIndex]["desc"] : "NOT FOUND";
    }
  } catch (e) {
    //console.log("Error", e);
  }
};

// const searchInTable =(value, fields, tableData,data,searchedData,setSearchedData,BooleanSearchVariable,setBooleanSearchVariable)=>{
//   let pattern = [value];
//   if(value) {
//       setBooleanSearchVariable(true);
//   } else if(value === ''){
//       //console.log('Empty search field');
//       setBooleanSearchVariable(false);
//       setSearchedData([]);
//   }
//   if(value && value !== ''){
//       let result = [];
//       setSearchedData([]);
//       let options = {caseSensitive: true}
//       result = smartSearch(data,pattern,fields,options);
//       if(result && result.length ===0) setSearchedData([]);
//       let searchedArray = [];
//       if(result && result.length>0){
//           result.map((item)=>{
//               tableData.map((data)=>{
//                   if(data.termId === item.entry.id){
//                       searchedArray.push(data);
//                   }
//               })
//           })
//           if(searchedArray && searchedArray.length>0 && result.length>0){
//               setSearchedData(searchedArray)
//           }
//       }
//   }
// }

const NewRow = (props) => {
  const {
    children,
    data,
    itemToBeRenderFromMaster = [],
    altList = [],
    arrList = [],
    propertyList = [],
    listToBeRendered = [],
    currentPage = null,
    index = null,
    regionMasterData = [],
    colunmWidths = [],
  } = props;
  // //console.log(arrList)

  return (
    <tr>
      {data &&
        listToBeRendered.map((item, i) => {
          
          if (item === "regionId") {
            return (

              
              <td
                className="text-center  white-breakSpace"
                key={i + getUniqueKey()}
              >
                {getDispValForReactSelectByOneID(
                  data[item],
                  "DispValue",
                  regionMasterData
                )
                }
                
              </td>

            );
          }

          if (itemToBeRenderFromMaster.indexOf(item) > -1) {
            return (
              <td
                className="text-center  white-breakSpace"
                key={i + getUniqueKey()}
                colSpan={colunmWidths[i] ?? 1}
              >
                {data[item] !== ""
                  ? getValueBySearch(data[item], arrList[i], propertyList[i])
                  : data?.[altList?.[i]] ?? ""}
              </td>
            );
          } else if (
            item === "effectiveDate" ||
            item === "feeEffectiveUpto" ||
            item === "updatedOn" ||
            item === "startDate" ||
            item === "endDate" ||
            item === "dueDate" ||
            item === "createdOn" ||
            item === "l1StatusDate" ||
            item === "l2StatusDate"
          ) {
            return (
              <td
                className="text-center  white-breakSpace"
                key={i + getUniqueKey()}
              >
                {props.pricing
                  ? data[item]
                    ? moment(data[item])
                        .format("DD-MMM-YYYY HH:mm")
                        .toUpperCase()
                    : null
                  : data[item]
                  ? moment(data[item]).format("DD-MM-YYYY")
                  : null}
              </td>
            );
          } else if (item === "status") {

            return (
              <td className="text-center  white-breakSpace">
                {data["status"] === "INACTIVE" ? (
                  <button
                    disabled={true}
                    type="button"
                    className="btn btn-sm btn-warning cursor-default"
                  >
                    INACTIVE
                  </button>
                ) : data["status"] === "SEPARATED" ? (
                  <button
                    disabled={true}
                    type="button"
                    className="btn btn-sm btn-warning"
                  >
                    SEPARATED
                  </button>
                ) : data["status"] === "ACTIVE" ? (
                  <button
                    type="button"
                    disabled={true}
                    className="btn btn-sm btn-success cursor-default"
                  >
                    ACTIVE
                  </button>
                ) : data["status"] === "PASSWORD EXPIRED" ? (
                  <button
                    type="button"
                    disabled={true}
                    className="btn btn-sm btn-warning"
                  >
                    PASSWORD EXPIRED
                  </button>
                ) : data["status"] === "IDLE ACCOUNT" ? (
                  <button
                    type="button"
                    disabled={true}
                    className="btn btn-sm btn-warning"
                  >
                    IDLE ACCOUNT
                  </button>
                ) : data["status"] === "ACCOUNT LOCKED" ? (
                  <button
                    type="button"
                    disabled={true}
                    className="btn btn-sm btn-warning"
                  >
                    ACCOUNT LOCKED
                  </button>
                ) : (
                  <></>
                )}
              </td>
            );
          } else if (
            item === "sfSync" ||
            item === "isMultipleRoles" ||
            item === "isUseridEmail" ||
            item === "isNewUser"
          ) {
            return (
              <td className="text-center  white-breakSpace">
                {data["sfSync"] === "NO" || !data[item] ? (
                  <button
                    disabled={true}
                    type="button"
                    className="btn btn-sm btn-warning"
                  >
                    NO
                  </button>
                ) : (
                  <button
                    type="button"
                    disabled={true}
                    className="btn btn-sm btn-success"
                  >
                    YES
                  </button>
                )}
              </td>
            );
          } else if (item === "backendStatus") {
            return (
              <td className="text-center  white-breakSpace">
                {data["status"]}
              </td>
            );
          } else if (item === "l1Remarks" || item === "l2Remarks") {
            return (
              <td
                className="text-center  ellipse-text"
                id={`${item}${data["sno"]}`}
              >
                {data[item]}
                {data[item] && (
                  <UncontrolledTooltip
                    placement="top"
                    target={`${item}${data["sno"]}`}
                    hideArrow={true}
                    style={{
                      backgroundColor: "#a1aeb7",
                      color: "black",
                      maxWidth: "300px",
                      width: "auto",
                    }}
                  >
                    {data[item]}
                  </UncontrolledTooltip>
                )}
              </td>
            );
          } else {
            return (
              <td
                className="text-center  white-breakSpace"
                key={i + getUniqueKey()}
                colSpan={colunmWidths[i] ?? 1}
              >
                {/*{data[item]}*/}
                {item === "l1Status"
                  ? !data[item]
                    ? "Submitted"
                    : data[item]
                  : item === "l2Status"
                  ? !data[item]
                    ? "Submitted"
                    : data[item]
                  : (item=="cash"||item=="cheque")?(
                  <FormControlLabel
                    sx={{ marginBottom: "0px" }}
                    control={
                      <Checkbox
                        size="small"
                        name={item}
                        checked={data[item]}
                        disabled={true}
                      />
                    }
                  />):data[item]}
              </td>
                
            );
          }
        })}
      {data ? children : null}
    </tr>
  );
};

const getSearchValueByDiffId = (id, diffId, list, property) => {
  try {
    const index = returnIndexById(id, list, diffId);
    if (index >= 0) {
      return list[index][property];
    } else {
      return "";
    }
  } catch (e) {
    //console.log("Error ::", e);
  }
};
const removeDuplicates = (arr) => {
  const uniqueArray = arr.filter((thing, index) => {
    const _thing = JSON.stringify(thing);
    return (
      index ===
      arr.findIndex((obj) => {
        return JSON.stringify(obj) === _thing;
      })
    );
  });
  return uniqueArray;
};

const findObjectById = (arr, keyName, id) => {
  try {
    const obj = arr?.find((item) => item[keyName] == id);
    return obj;
  } catch (e) {
    console.log("Error", e);
  }
};

const fetchGlobalMasterData = async (listOfMaster = []) => {
  try {
    return new Promise((resolve, reject) => {
      if (listOfMaster && listOfMaster.length > 0) {
        let flag = true;
        listOfMaster.forEach(async (item) => {
          let FilterMaster = MasterServiceUrls.filter(
            (el) => el.name === item.name
          );
          if (FilterMaster.length) {
            let url = "";
            if (item.getAll) {
              url = FilterMaster[0]?.getAll;
            } else {
              url = FilterMaster[0]?.getAllActive;
            }
            await getAPI(
              masterServiceBaseUrl + url,
              (data) => {
                iterateData(
                  data["data"],
                  item.stateMethod,
                  item.name,
                  item.type
                );
              },
              (data) => {
                failureToast(data["message"]);
              }
            );
          } else {
            console.error(item.name + " url not found in MasterURls Array.");
          }
        });
        resolve(true);
      }
    });
  } catch (e) {
    console.error("Error in fetchGlobalMasterData-->", e);
  }
};
const fetchMultiSelectDropDownData1 = (url, stateMethod, displayValueKey) => {
  getAPI(
    url,
    (data) => {
      if (data.data.length) {
        let dropDownValues = [];
        data.data.forEach((item) => {
          dropDownValues.push({
            value: item.id,
            label: item[displayValueKey],
          });
        });
        stateMethod(dropDownValues);
      }
    },
    (data) => {}
  );
};

const fetchMultiSelectDropDownData = (url, stateMethod, name) => {
  getAPI(
    url,
    (data) => {
      if (data.data.length) {
        let tempArray = [];
        data.data.forEach((item) => {
          if (name === "courseId") {
            tempArray.push({
              value: item.courseId,
              label: item.courseId,
            });
          } else if (name === "academicYear" || name === "academicPhase") {
            let displayValueKey = `${name}DisplayValue`;
            tempArray.push({
              value: item.id,
              label: item[displayValueKey],
            });
          } else if (name === "examId") {
            tempArray.push({
              value: item.examId,
              label: item.examId,
              id: item.id,
            });
          } else if (name === "chapter") {
            // console.log('Chapter Data----->', item);
            let displayValueKey = "displayValue";
            tempArray.push({
              value: item.id,
              label: item[displayValueKey],
            });
          } else if (name === "topic") {
            let displayValueKey = "topicId";
            tempArray.push({
              value: item.id,
              label: item[displayValueKey],
            });
          } else {
            let displayValueKey = name + "DispValue";
            tempArray.push({
              value: item.id,
              label: item[displayValueKey],
            });
          }
        });
        stateMethod(tempArray);
      }
    },
    (data) => {}
  );
};

const setCookie = (key, value, days) => {
  // var expires = "";
  // if (days) {
  //   var date = new Date();
  //   date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  //   expires = "; expires=" + date.toUTCString();
  // }
  // document.cookie = name + "=" + (value || "") + expires + "; path=/";
  let encrypted = CryptoJS.AES.encrypt(value, "encryptedjwtstringtoken");
  // console.log('encrypted', encrypted.toString())
  document.cookie = key + "=" + (encrypted || "");
};

const getCookie = (key) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${key}=`);

  if (parts.length === 2) {
    let encryptedToken = parts.pop().split(";").shift();
    let decrypted = CryptoJS.AES.decrypt(
      encryptedToken,
      "encryptedjwtstringtoken"
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
};

const eraseCookie = (key) => {
  document.cookie = key + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

const isPermissionExists = (permissionStr, permissionType) => {
  if (permissionStr) {
    const permissionArr = permissionStr.toString().split(",");
    const permissionExist = permissionArr.indexOf(
      permissionType.toString().toUpperCase()
    );
    if (permissionExist > -1) return true;
  }
  return false;
};

const fetchAllPromisedData = async (
  url,
  getRawObject = false,
  failureToastRequired = true,
  header,
  id=''
) => {
  return new Promise((resolve, reject) => {
    getAPI(
      url,
      (data) => {
        if (getRawObject) {
          resolve(data);
        } else {
          resolve(data?.data);
        }
      },
      (data) => {
        if (failureToastRequired) {
          failureToast(data["message"]);
        }
        reject({});
      },
      header,
      id
    );
  });
};

const fetchAllPostPromisedData = async (url, requestObject, medthod = 'post', failureToastRequired = true, header,id='') => {
  return new Promise((resolve, reject) => {
    putpost(
      url,
      (data) => {
        resolve(data);
      },
      (data) => {
        if (failureToastRequired) {
          if (data["message"] === "Failed to fetch") {
            failureToast("Something went wrong");
          } else {
            failureToast(data["message"]);
          }
        }
        reject({});
      },
      requestObject,
      medthod,
      header,
      id
    );
  });
};

const fetchDeletePromisedData = async (url) => {
  return new Promise((resolve, reject) => {
    putpost2(
      url,
      (data) => {
        resolve(data);
      },
      (data) => {
        failureToast(data["message"]);
        reject({});
      },
      "PUT"
    );
  });
};

const capitalize = (str) => {
  if (!str) return;
  return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
};

// this method is used to update the react-select objects
export const removeSelectAllOption = (selectArray) => {
  const updatedArray = [];
  selectArray.forEach(
    (item) => item?.value !== "*" && updatedArray?.push(item)
  );
  return updatedArray;
};

export const getDropdownIdsFromSelectArray = (items) =>
  items.map((item) => item.value);
export const getDropdownObjFromSelectArray = (items) =>
  items.map((item) => ({ id: item.value, dispValue: item.label }));

export const updateRequest = (request) => {
  let temp = { ...request };
  for (const key in request) {
    if (
      Array.isArray(request[key]) &&
      request[key].length > 0 &&
      request[key][0]?.label
    ) {
      temp[key] = getDropdownIdsFromSelectArray(request[key]);
    } else if (request[key]?.label) {
      temp[key] = request[key]["value"];
    }
  }
  return temp;
};
export const updateRequest2 = (request, keys = []) => {
  let temp = { ...request };
  for (const key in request) {
    if (
      Array.isArray(request[key]) &&
      request[key].length > 0 &&
      request[key][0]?.label
    ) {
      temp[key] = keys.includes(key)
        ? getDropdownObjFromSelectArray(request[key])
        : getDropdownIdsFromSelectArray(request[key]);
    } else if (request[key]?.label) {
      temp[key] = keys.includes(key)
        ? { id: request[key]["value"], dispValue: request[key]["label"] }
        : request[key]["value"];
      temp[`${key}Value`] = request[key]["label"];
    }
  }
  return temp;
};

export const getUrlSearchParams = (searchString) => {
  return Object?.fromEntries(new URLSearchParams(searchString)?.entries());
};

export const getSelectArrayFromApiResArray = (apiResponseArray) => {
  return apiResponseArray?.map((item) => ({
    label: item?.dispValue,
    value: item?.id,
  }));
};

export const checkPermission = (permissionSet = [], permission) => {
  // if any permisson of the array is present in permissionSet then we'll return true
  if (permissionSet && Array.isArray(permission)) {
    for (let p of permission) {
      const isIncluded = permissionSet?.includes(p);
      if (isIncluded) return true;
    }
    return false;
  }
  return permissionSet && permissionSet?.includes(permission);
};

export const isObjEmpty = (obj) => Object?.keys(obj)?.length === 0;

export const dropdownMapping = async (url, dispLabel, idLabel, ...rest) => {
  const res = await fetchAllPromisedData(url, false);
  if (Array.isArray(res)) {
    return res?.map((item) => {
      const restKeys = _.pick(item, ...rest);
      return { label: item?.[dispLabel], value: item?.[idLabel], ...restKeys };
    });
  } else {
    return [];
  }
};
export const autoCompleterDropdownMapping = (
  res,
  dispLabel,
  idLabel,
  ...rest
) => {
  // console.log(dispLabel, idLabel, rest)
  if (Array.isArray(res)) {
    return res?.map((item) => {
      const combinedItem = removeKeysFromObject({ ...item, ...item?.details }, [
        "value",
      ]);
      return {
        label: combinedItem?.[dispLabel],
        value: combinedItem?.[idLabel],
        materialId: combinedItem?.details?.materialId,
        uom: combinedItem?.details?.baseUnitOfMeasure,
        materialDescription: combinedItem?.details?.materialDescription,
        ..._.pick(combinedItem, ...rest),
      };
    });
  } else {
    return [];
  }
};

export const generateRandomId = () => Date.now();

export const getSingleSelectDefault = (id, dataArray) => {
  return dataArray?.find((item) => item?.value === id);
};

export const getMultiSelectDefault = (idArray, dataArray) => {
  const res = idArray
    ?.map((id) => dataArray?.find((item) => item?.value === id))
    ?.filter((item) => item != null);
  return res;
};

export const removeKeysFromObject = (obj, keys = []) => {
  const updatedObj = { ...obj };
  keys.map((item) => {
    delete updatedObj?.[item];
  });
  return updatedObj;
};

export const isFormFieldEmpty = (formField) => !formField?.toString()?.length;
export const getFormattedDate = (date) =>date? moment(date).format("DD-MMM-YYYY"):'-';
export const getFormattedTime = (time) => moment(time).format("hh:mm a");

export const regexValidate = (regex, string) => {
  if (regex) {
    return regex?.test(string);
  }
  return true;
};

export const getFormattedAmount = (number) => {
  return (+number || 0).toLocaleString("en-IN", {
    maximumFractionDigits: 2,
    style: "currency",
    currency: "INR",
  });
};

export const getMomentWithoutTimestamp = (date) =>
  moment(date)?.set({ hours: 0, minutes: 0, second: 0, milliseconds: 0 });
export const getDayShortName = (date) =>
  date ? moment(date)?.format("dddd")?.substring(0, 3)?.toUpperCase() : "-";

export const constructDropdownArray = (list, labelKey, valueKey, ...rest) => {
  return Array.isArray(list)
    ? list.map((item) => ({
        label: item?.[labelKey],
        value: item?.[valueKey],
        ..._.pick(item, ...rest),
      }))
    : [];
};

export {
  openInNewTab,
  returnDropDownObjectById,
  getValueBySearch,
  fetchAllData,
  fetchDetails,
  iterateData,
  getDispValForSpecificDataByOneId,
  getDispValForSpecificDataByIdOfId,
  getDispValForSelectByOneID,
  getDispValForSelectByIdOfId,
  getDispValForReactSelectByOneID,
  getDispValForReactSelectByIdOfId,
  NewRow,
  getSearchValueByDiffId,
  getRequiredSign,
  getUniqueKey,
  removeDuplicates,
  getObjectById,
  findObjectById,
  isEqual,
  fetchGlobalMasterData,
  fetchMultiSelectDropDownData,
  fetchMultiSelectDropDownData1,
  isPermissionExists,
  fetchAllPromisedData,
  setCookie,
  getCookie,
  eraseCookie,
  fetchAllPostPromisedData,
  fetchDeletePromisedData,
  capitalize,
  getUniqueElements,
  // searchInTable
};
