import React from "react";
import { Card, CardHeader, Input, Container, Button, Table, Row } from "reactstrap";
import { useState } from "react";
import { failureToast, successToast } from "../../common/utils/methods/toasterFunctions/function";
import { AdjustmentFlagData, MasterHeader } from "../../common/commonComponents/masterRow/masterRow";
import { masterServiceBaseUrl, putpost, putpost2, deleteAPI } from "services/http";
import moment from "moment";
import { CustomInput } from "../../common/formFeilds";
import CustomButton from "../../../../../components/CustomButton";
import { ButtonText } from "variables/Buttons";
import { customValidation } from "../../common/utils/methods/validations/validation";
import validationRules from './validation.json'
import { fetchAllPostPromisedData, fetchDeletePromisedData, getCurrentDate } from "../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "../../common/constant";
import { editTableRow, newTableRow } from "../../common/utils/methods/commonMethods/tableMethods";

var _ = require("lodash");

const DepartmentTypeTable = (props) => {
  const { permissionSet, isSaveVisible, setisSaveVisible = () => { }, getAllrecords = () => { } } = props;
  const [tableDataKey, settableDataKey] = useState("tableDataKey");

  const addNewClass = () => {
    props.setclassArray([
      { editMode: true, __status: "__new", key: null, status: "ACTIVE", description: null, displayValue: null, effectiveDate: null },
      ...props.classArray,
    ]);
  };
  // const saveAll = (cb) => {
  //   var _tempArray = props.classArray;
  //   var isValid = true;
  //   let char = /^[a-zA-Z]+$/;
  //   let alphanumericSpecialChar = /^[ A-Za-z0-9/_-]*$/;
  //   _tempArray.forEach((el, index) => {
  //     if (el["editMode"]) {
  //       if (el["key"] === null || !el["key"].trim()) {
  //         isValid = false;
  //         failureToast("Please enter the Key.");
  //         return;
  //       } else if (el["description"] === null || !el["description"].trim()) {
  //         isValid = false;
  //         failureToast("Please enter the value of Description");
  //         return;
  //       } else if (!alphanumericSpecialChar.test(el["description"])) {
  //         isValid = false;
  //         failureToast("Please enter the valid value of Description");
  //         return;
  //       } else if (el["displayValue"] === null || !el["displayValue"].trim()) {
  //         isValid = false;
  //         failureToast("Please enter the value of Display Value");
  //         return;
  //       }
  //       // else if (!char.test(el["displayValue"])) {
  //       //   isValid = false;
  //       //   failureToast("Please enter the valid value of Display Value");
  //       //   return;
  //       // }
  //       if (!isValid) {
  //         return;
  //       }
  //       if (_tempArray[index]["__status"] == "__new") {
  //         putpost(
  //           masterServiceBaseUrl + "/adjustmentFlagMaster/createAdjustmentFlagMaster",
  //           (data) => {
  //             successToast(data["message"]);
  //             setisSaveVisible(false);
  //             props.fetchClasses(masterServiceBaseUrl + "/adjustmentFlagMaster/getAllAdjustmentFlagMasters");
  //           },
  //           (data) => {
  //             failureToast(data["message"]);
  //           },
  //           _tempArray[index],
  //           "post"
  //         );
  //       } else {
  //         putpost(
  //           masterServiceBaseUrl + "/adjustmentFlagMaster/updateAdjustmentFlagMaster/" + _tempArray[index].id,
  //           (data) => {
  //             successToast(data["message"]);
  //             setisSaveVisible(false);
  //             delete _tempArray[index]["editMode"];
  //             props.setclassArray(_tempArray);
  //             settableDataKey(new Date().getMilliseconds());
  //             // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
  //           },
  //           (data) => {
  //             failureToast(data["message"]);
  //           },
  //           _tempArray[index],
  //           "put"
  //         );
  //       }
  //     } else {
  //     }
  //   });
  // };
  const saveAll = async (index) => {
    let _data = props.classArray[index];
    let isValid = true;
    let alphanumericSpecialChar = /^[ A-Za-z0-9/_-]*$/;
    // let char = /^[a-zA-Z]+$/;
    isValid = customValidation(_data, validationRules)

    if (!alphanumericSpecialChar.test(_data["description"])) {
      isValid = false;
      failureToast("Please enter the valid value of Description");
      return;
    }
    // else if (!char.test(el["displayValue"])) {
    //   isValid = false;
    //   failureToast("Please enter the valid value of Display Value");
    //   return;
    // }

    if(!isValid) return;

    if (_data["__status"] == "__new"){
      const createRecord = await fetchAllPostPromisedData(endpoint.adjustmentFlag.create, _data, 'post')
      if (createRecord?.code === 200) {
        successToast(createRecord["message"]);
        setisSaveVisible(false);
        getAllrecords()
      }
    } else {
      const updateRecord = await fetchAllPostPromisedData(`${endpoint.adjustmentFlag.update}/${_data.id}`, _data, 'put');
      if (updateRecord?.code === 200) {
        successToast(updateRecord["message"]);
        setisSaveVisible(false);
        getAllrecords()
      }
    }
  }
  // const newRow = () => {
  //   if (isSaveVisible) {
  //     failureToast("Please Delete or Save current changes");
  //   } else {
  //     let cb = () => {
  //       addNewClass();
  //       setisSaveVisible(true);
  //       // settableDataKey(new Date().getMilliseconds())
  //     };
  //     var _tempArray = props.classArray;
  //     _tempArray = _.sortBy(_tempArray, [
  //       function (o) {
  //         return o.value;
  //       },
  //     ]);
  //     props.setclassArray(_tempArray);
  //     setisSaveVisible(false);
  //     cb();
  //   }
  // };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      newTableRow(props.classArray,props.setclassArray,setisSaveVisible,addNewClass);
    }
  };
  // const editRow = (index) => {
  //   if (isSaveVisible) {
  //     failureToast("Please Delete or Save current changes");
  //   } else {
  //     let cb = () => {
  //       var _tempArray = props.classArray;
  //       _tempArray[index]["editMode"] = true;
  //       props.setclassArray(_tempArray);
  //       setisSaveVisible(true);
  //     };
  //     var _tempArray = props.classArray;
  //     _tempArray = _.sortBy(_tempArray, [
  //       function (o) {
  //         return o.value;
  //       },
  //     ]);
  //     cb();
  //   }
  // };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      editTableRow(index,props.classArray,props.setclassArray,setisSaveVisible);
    }
  };
  // const deleteRow = (index) => {
  //   if (props.classArray[index]["__status"] == "__new") {
  //     let n = props.classArray;
  //     n.splice(index, 1);
  //     props.setclassArray(n);
  //     setisSaveVisible(false);
  //     settableDataKey(new Date().getMilliseconds());
  //   } else {
  //     if (window.confirm("Are you sure you want to delete this Department Type?")) {
  //       putpost2(
  //         masterServiceBaseUrl + "/meetingType/deleteMeetingType/" + props.classArray[index].id,
  //         (data) => {
  //           successToast(data["message"]);
  //           if (props.classArray.length == 1) {
  //             props.previousPage();
  //             props.fetchClasses(masterServiceBaseUrl + "/meetingType/getAllMeetingType");
  //           } else {
  //             var _tempArray = props.classArray;
  //             if (_tempArray[index]["editMode"]) {
  //               setisSaveVisible(false);
  //             }
  //             _tempArray.splice(index, 1);
  //             props.setclassArray(_tempArray);
  //           }

  //           settableDataKey(new Date().getMilliseconds());
  //         },
  //         (data) => {
  //           failureToast(data["message"]);
  //         },
  //         "Delete"
  //       );
  //     }
  //   }
  // };
  const deleteRow = async (index) => {
    if (window.confirm("Are you sure you want to delete this Adjustment Flag?")) {
      const deleteRecord = await fetchDeletePromisedData(`${endpoint.adjustmentFlag.delete}/${props.classArray[index].id}`);
      if(deleteRecord?.code === 200){
        successToast(deleteRecord['message']);
        let flag=false;
        if(props.classArray.length==1){
          flag=true;
        }
        getAllrecords(flag);
        settableDataKey(new Date().getMilliseconds());
      }
    }
  };
  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
  };

  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Adjustment Flag</h3>
                {/* <h4 className="mb-0 floatLeft ml-2 mr-2"><label for="coreMaster">Core Master</label></h4> */}
                {/* <input type="checkbox" id="coreMaster" name="coreMaster" value="Yes" ></input> */}
                {/* <Button
                  disabled={isSaveVisible}
                  color="info"
                  size="sm"
                  type="button"
                  className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
                  onClick={() => {
                    newRow();
                  }}
                  data-testid="addNewDepartmentType"
                >
                  <i className="fas fa-plus" />&nbsp; New Adjustment Flag
                </Button> */}
                <CustomButton
                  data-testid="addNewDepartmentType"
                  disabled={isSaveVisible}
                  className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
                  content={ButtonText.AdjustmentFlag.NEW}
                  icon={true}
                  permissionType={'C'}
                  permissionSet={permissionSet}
                  onClick={() => newRow()}
                />
                {/* {isSaveVisible?<Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => {saveAll()}}>Save</Button>:null} */}
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout">
                  <MasterHeader
                    type={"Adjustment Flag"}
                    keyTooltip={'Only single alphabet is allowed.'}
                    descriptionTooltip={'50 alphanumeric text with special characters _ / - and space are allowed.'}
                    displayValueTooltip={'Only 3 alphabets are allowed.'}
                    permissionSet={permissionSet}
                    isSaveVisible={isSaveVisible}
                  />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                        !el["editMode"] ? (
                          <AdjustmentFlagData
                            type={"adjustmentFlag"}
                            index={index}
                            el={el}
                            editRow={editRow}
                            deleteRow={deleteRow}
                            isSaveVisible={isSaveVisible}
                            permissionSet={permissionSet}
                          />
                        ) : (
                          <tr key={index + "-class"}>
                            <td className="text-center p-2 mx-1">
                              <CustomInput
                                defaultValue={el["key"]}
                                maxLength={1}
                                minLength={1}
                                index={index}
                                placeHolder={"Example: A"}
                                regex={/^[A-Za-z]*$/i}
                                regValidation={true}
                                keyName={"key"}
                                handler={updateKey}
                                isUpperCase={true}
                              />

                              {/* <Input
                                  maxLength="1"
                                  defaultValue={el["key"]}
                                  onChange={(e) => {
                                      updateKey(index, e.target.value, "key");
                                  }}
                                  placeholder="Example:1"
                                  className="tdInput"
                                  data-testid="meetingTypeKey"
                                /> */}
                            </td>
                            <td className="text-center p-2 mx-1">
                              <Input
                                disabled={true}
                                defaultValue={el["__status"] == "__new" ? getCurrentDate() : moment(el["effectiveDate"]).format("DD-MM-YYYY")}
                                placeholder="Example:01-01-2020"
                                className="tdInput"
                                data-testid="effectiveDate"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <CustomInput
                                defaultValue={el["description"]}
                                maxLength={50}
                                minLength={1}
                                index={index}
                                placeHolder={"Description"}
                                regex={/^[A-Za-z0-9 _/-]*$/}
                                regValidation={true}
                                keyName={"description"}
                                handler={updateKey}
                              />
                              {/* <Input
                                  maxLength="50"
                                  defaultValue={el["description"]}
                                  onChange={(e) => {
                                    updateKey(index, e.target.value, "description");
                                  }}
                                  placeholder="Description"
                                  className="tdInput"
                                  data-testid="meetingTypeDesc"
                                /> */}
                            </td>
                            <td className="text-center p-2 mx-1">
                              <CustomInput
                                defaultValue={el["displayValue"]}
                                maxLength={3}
                                minLength={1}
                                index={index}
                                placeHolder={"Display Value"}
                                regex={/^[A-Za-z]*$/}
                                regValidation={true}
                                keyName={"displayValue"}
                                handler={updateKey}
                              />

                              {/* <Input
                                  maxLength="3"
                                  defaultValue={el["displayValue"]}
                                  onChange={(e) => {
                                    updateKey(index, e.target.value, "displayValue");
                                  }}
                                  placeholder="Display Value"
                                  className="tdInput"
                                  data-testid="meetingTypeDispValue"
                                /> */}
                            </td>
                            <td className="text-center p-2 mx-1">
                              <label className="custom-toggle mx-auto ml-2">
                                <input
                                  checked={el.status == "ACTIVE" ? true : false}
                                  type="checkbox"
                                  id={el.id}
                                  onChange={(e) => {
                                    settableDataKey(new Date().getMilliseconds());
                                    let n = "ACTIVE";
                                    if (!e.target.checked) {
                                      n = "INACTIVE";
                                    }
                                    updateKey(index, n, "status");
                                  }}
                                  data-testid="departmentTypeStatus"
                                />
                                <span
                                  style={{ width: "72px" }}
                                  className="custom-toggle-slider rounded-circle activeToggle"
                                  data-label-off="Inactive"
                                  data-label-on="Active"
                                />
                              </label>
                            </td>
                            <td className="text-center">
                              {/* <Button color="info" size="sm" type="button" onClick={() => {
                          deleteRow(index)
                        }}>  <i className="fas fa-trash" /></Button> */}
                              {isSaveVisible ? (
                                <>
                                  {/* {" "}
                                    <Button
                                      color="info"
                                      size="sm"
                                      type="button"
                                      className="mx-1"
                                      onClick={() => {
                                        saveAll();
                                      }}
                                      data-testid="saveDepartmentType"
                                    >
                                      Save
                                    </Button> */}
                                  <CustomButton
                                    data-testId="saveDepartmentType"
                                    className={'mx-1'}
                                    content={ButtonText.AdjustmentFlag.UPDATE}
                                    forTable={true}
                                    permissionType={'C,U'}
                                    permissionSet={permissionSet}
                                    onClick={() => { saveAll(index); }}
                                  />
                                  {/* <Button
                                      color="danger"
                                      size="sm"
                                      type="button"
                                      data-testid="closedDepartmentType"
                                      onClick={() => {
                                        let n = props.classArray;
                                        if (n[index]["__status"] == "__new") {
                                          n.splice(index, 1);
                                        } else {
                                          // n[index]['editMode']=false
                                          props.fetchClasses(masterServiceBaseUrl + "/adjustmentFlagMaster/getAllAdjustmentFlagMasters");
                                        }
                                        props.setclassArray(n);
                                        setisSaveVisible(false);
                                      }}
                                    >
                                      {" "}
                                      <i className="fas fa-times" />
                                    </Button> */}
                                  <CustomButton
                                    data-testId="closedDepartmentType"
                                    className={'mx-1'}
                                    permissionType={'cancel'}
                                    forTable={true}
                                    icon={true}
                                    permissionSet={permissionSet}
                                    onClick={() => {
                                      let n = props.classArray;
                                      if (n[index]["__status"] == "__new") {
                                        n.splice(index, 1);
                                      } else {
                                        // n[index]['editMode']=false
                                        getAllrecords();
                                      }
                                      setisSaveVisible(false);
                                    }}
                                  />
                                </>
                              ) : (
                                // <Button
                                //   color="info"
                                //   size="sm"
                                //   type="button"
                                //   onClick={() => {
                                //     deleteRow(index);
                                //   }}
                                // >
                                //   {" "}
                                //   <i className="fas fa-trash" />
                                // </Button>
                                <></>
                              )}
                            </td>
                          </tr>
                        )
                      )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default DepartmentTypeTable;
