import React, { useEffect, useState } from 'react'
import { IoEyeOutline } from 'react-icons/io5';
import APopover from '../../../../common/a-popover';
import { Col, Row } from 'reactstrap';
import AButton from '../../../../common/form-fields-mui/AButton';
import { fetchAllPromisedData } from '../../../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../../../common/constant';

const ApplicationIdPopupModal = ({closeForm, rowData}) => {
  const {applicationId, courseId, primaryBatchId, secondaryBatchId} = rowData || {};
  
  const [batchName, setBatchName] = useState(''); // is used for single issuance
  const [apiLoader, setApiLoader] = useState(false);
  const fetchBatchName = async () => {
    if(!primaryBatchId && !secondaryBatchId) return ''
    setApiLoader(true);
    const res = await fetchAllPromisedData(`${endpoint.viewEditBatch.getBatchById}/${primaryBatchId || secondaryBatchId}`);
    setApiLoader(false);
    setBatchName(res?.batchName || '')
  }

  useEffect(() => {
    fetchBatchName();
  }, [])

  return (
    <div style={{ width: '300px', height: '145px', overflowY: 'auto' }} className="p-3">
      <div className="large-semi-bold color-grey-90 mb-2">{`Application no. ${applicationId}`}</div>
      <Row className="semi-bold color-grey-90 mb-1">
        <Col sm="4">Course ID</Col>
        <Col sm="8">Batch</Col>
      </Row>
      <Row className="regular-body color-grey-90">
        <Col sm="4">{courseId}</Col>
        <Col sm="8">{apiLoader ? 'Loading...' : (batchName || '-')}</Col>
      </Row>
      <AButton className="mt-2 btn-left-0" variant="primary_filled" size="xs" onClick={closeForm}>
        Ok, got it!
      </AButton>
    </div>
  );
}

const ApplicationIdPopup = ({rowData}) => {
  
  return (
    <div className="ml-2">
      <APopover
        buttonComponent={onclick => (
          <IoEyeOutline className="cursor color-grey-90" onClick={onclick} size={18} />
        )}
        menuComponent={closeForm => (
          <ApplicationIdPopupModal closeForm={closeForm} rowData={rowData} />
        )}
        transformOriginHorizontal='center'
      />
    </div>
  );
}

export default ApplicationIdPopup