import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import AButton from "../../common/form-fields-mui/AButton";
import { constants } from '../../common/constant';
import AAutoComplete from '../../common/form-fields-mui/AAutoComplete'
import CommonInput from "../../common/formFeilds/input/commonInput";
import moment from "moment";


export const modalStyle = {
  position: "absolute" /* Stay in place */,
  zIndex: "75" /* Sit on top */,
  top: "2rem",
  right: "5rem",
  width: "60%" /* Full width */,
  height: "100%" /* Full height */,
};

export const RightSideFilterForm = ({
  formModal,
  setFilter,
  applyFilterHandler,
  removeFilterHandler,
  isShowRemoveFilter,
  disabled = false
}) => {
  const columnSize = 12;

  return (
    <div >
      <Card style={{ width: "300px", marginBottom: "0px" }}>
        <CardBody style={{ paddingTop: ".5rem", paddingBottom: ".5rem" }}>
          <h4 style={{ color: "black" }}> Filter </h4>
          <Row>
            <Col md={columnSize}>
              <AAutoComplete
                label='Day'
                currentValue={formModal.day}
                items={constants.DAY}
                handleChange={(value) => setFilter({ ...formModal, day: value })}
                isShowCount={true}
                disabled={disabled}
                isMulti={false}
              />
            </Col>
          </Row>
          <Row className="pt-1">
            <Col md={12} className="pb-1"><span className="regular-small">Date Range</span></Col>
            <Col md={6}>
              <CommonInput type="datePickerV2" defaultValue={formModal.startDate} onChange={(value) => setFilter({ ...formModal, startDate: value, endDate: '' })} forFilter={true} forTable={true} placeHolder={"From"} minDate={new Date()} withportal={true}/>
            </Col>
            {/* <Col md={1} /> */}
            <Col md={6}>
              <CommonInput type="datePickerV2" defaultValue={formModal.endDate} onChange={(value) => setFilter({ ...formModal, endDate: value })} forFilter={true} forTable={true} placeHolder={"To"} minDate={formModal.startDate} withportal={true} isDisabled={!formModal.startDate}/>
            </Col>
          </Row>
          <Row className="pt-1">
            <Col md={12} className="pb-1"><span className="regular-small">Time Range</span></Col>
            <Col md={6}>
              <CommonInput type="timePicker" defaultValue={formModal.startTime} onChange={(value) => setFilter({ ...formModal, startTime: moment(value).format('HH:mm:ss'), endTime: '' })} forFilter={true} forTable={true} placeHolder={"Start Time"} />
            </Col>
            {/* <Col md={1} /> */}
            <Col md={6}>
              <CommonInput type="timePicker" defaultValue={formModal.endTime} onChange={(value) => setFilter({ ...formModal, endTime: moment(value).format('HH:mm:ss') })} forFilter={true} forTable={true} placeHolder={"End Time"} isDisabled={!formModal.startTime} />
            </Col>
          </Row>
          <Row className="pt-1">
            <Col md={columnSize} >
              <AAutoComplete
                label='Status'
                currentValue={formModal.status}
                items={constants.status}
                handleChange={(value) => setFilter({ ...formModal, status: value })}
                isShowCount={true}
                disabled={disabled}
                isMulti={false}
              />
            </Col>
          </Row>
          <Row className="pt-2">
            <Col md="6" className="pl-1">
              {!isShowRemoveFilter && (formModal.day ||
                formModal.startDate ||
                formModal.endDate ||
                formModal.startTime ||
                formModal.endTime ||
                formModal.status) && (
                  <AButton
                    onClick={() => {
                      setFilter({
                        day: '',
                        startDate: '',
                        endDate: '',
                        startTime: '',
                        endTime: '',
                        status: ''
                      })
                    }}
                    updatedStyle={{
                      border: "1px solid #CC5F5F",
                      borderRadius: "8px",
                      color: "#CC5F5F",
                      marginTop: ".3rem",
                      marginRight: ".3rem",
                      marginLeft: "0rem"
                    }}
                  >
                    Remove Filter
                  </AButton>
                )}
              {isShowRemoveFilter && (
                <AButton
                  onClick={removeFilterHandler}
                  updatedStyle={{
                    border: "1px solid #CC5F5F",
                    borderRadius: "8px",
                    color: "#CC5F5F",
                    marginTop: ".3rem",
                    marginRight: ".3rem",
                    marginLeft: "0rem"
                  }}
                >
                  Remove Filter
                </AButton>
              )}
            </Col>
            <Col md="6" style={{ display: "flex", justifyContent: "flex-end" }}>
              <AButton
                onClick={applyFilterHandler}
                updatedStyle={{
                  background: "#00B0F5",
                  border: "1px solid #00B0F5",
                  borderRadius: "8px",
                  color: "#fff",
                  margin: ".3rem"

                }}
                disabled={disabled}
              >
                Apply Filter
              </AButton>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
