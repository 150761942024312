import React from 'react';
import { pages } from '../common/constant';
import AddToFavourite from '../homepage/favourites/AddToFavourite';

const ManualAttendanceListHeader = ({ text }) => {
  return (
    <div className='d-flex align-items-center justify-content-between'>
      <div
        style={{ fontFamily: 'sans-serif' }}
        className='heading-4 mb-2 mt-2 label-color'
      >
        {text || 'Search Students'}
      </div>
      <AddToFavourite screenName='Manual attendance' screenId={pages['attendance']['id']} />
    </div>
  );
};

export default ManualAttendanceListHeader;
