import React, { useState } from "react";
import { TestCardWithCheckBox } from "./TestCardWithCheckBox";
import TestCard from "./TestCard";

const DisplayImportedTests = ({ importedTests, ...props }) => {
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const importCheckBoxHandler = (id) => {
    let ids = [...props?.testIdsToBeImported];
    const index = ids.indexOf(id);
    if (index > -1) {
      ids.splice(index, 1);
      props.setTestIdsToBeImported(ids);
      // 2nd parameter means remove one item only
    } else {
      props.setTestIdsToBeImported((prevIds) => [...prevIds, id]);
    }
  };

  return (
    <>
      {importedTests?.length > 0 &&
        importedTests?.map((test, index) => (
          <div key={test?.id}>
            <div>
              {" "}
              <TestCard
                testData={test}
                index={index}
                expandedIndex={expandedIndex}
                setExpandedIndex={setExpandedIndex}
                isImportedTest={true}
                importTestHandler={(id) => importCheckBoxHandler(id)}
              />
            </div>
          </div>
        ))}
    </>
  );
};

export default DisplayImportedTests;
