import React, { useState, useEffect } from "react";
import { Button, Row } from "reactstrap";
import { getAPI, } from "services/http";
import FeeBreakupTable from "./feeBreakupTable";
import CustomLoader from "../../../../../common/commonComponents/Loader/loader";
import { GetPagination } from "../../../../../common/commonComponents/pagination/pagination";
import { endpoint } from "../../../../../common/constant";
import { Accordion } from "views/pages/manage/common/commonComponents";
import { isEqual } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import dummyArr from "./itemResponse.json";
import CustomButton from "components/CustomButton";
import { Checkbox, FormControlLabel } from "@mui/material";
import { select } from "react-cookies";

var _ = require("lodash");
const FeeBreakup = (props) => {
  const {
    action,
    feeBreakupHandler = () => { },
    fetchingIsBreakupValid,
    setFetchingIsBreakupValid = () => { },
    isDisabled,
    courseStartDate,
    courseEndDate,
    totalYears,
    feeBreakup,
    savedFeeBreakUpData,
    userPermissions,
    courseType
  } = props;

  const [feeBreakupData, setFeeBreakupData] = useState({
    itemType: "",
    dueDate: "",
    baseAmount: "",
    tax: "",
    finalAmount: "",
    itemTypeSequenceDetailId: "",
    year: "",
    installmentNumber: "",
    cash:'',
    cheque:''
  });

  const [classArray, setclassArray] = useState([]);
  const [apiloader, setapiloader] = useState(true);
  const [state, setstate] = useState([]);
  const [serverData, setServerData] = useState([]);
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });

  const [activeItemTypeData, setActiveItemTypeData] = useState(null);
  const [itemTypeData, setItemTypeData] = useState([]);
  const [isExceptionalCourse,setExceptionalCourse]= useState(false)
  const [selectAll,setSelectAll] =useState({cash:false, cheque:false});

  const pageSize = 10;

  const fetchClasses = async (data) => {

    setapiloader(true);
    if (!isEqual(courseType,'MSCR') || isEqual(action,'edit')) {
      setstate(data);
      setServerData(data);
      setclassArray(data);
    } else {
      getItemTypeSelectData(data)
    }

    if(isEqual(action,'edit')){
      getItemTypeSelectData(data)
    }
    setapiloader(false);
  };

  const getItemTypeSelectData = (data) => {
    const tempData = data.map(item => {
      return { value: item.itemTypeSetupId, label: item.itemTypeSetupId, itemTypeSequenceDetailId:  item.itemTypeSequenceDetailId, totalTaxAmount: item.totalTaxAmount }
    })
    setItemTypeData(tempData)
  }

  const getIndex = (itemType) => {
    return feeBreakup.findIndex(item => item.itemTypeSetupId === itemType)
  }

  const setFeeBreakup = () => {

  

    let dueDate = {};
    let baseAmount = {};
    let tax = {};
    let finalAmount = {};
    let year = {};
    let itemTypesId = {};
    let cash ={};
    let cheque={};

    const alteredFeeBreakUp = savedFeeBreakUpData.map((item, i) => {

      const index = getIndex(item.itemType)
      let feeObject = {};
      if (index > -1) {
        feeObject = feeBreakup[index]
      }

      dueDate = { ...dueDate, [i]: new Date(item.dueDate) };
      baseAmount = { ...baseAmount, [i]: item.baseAmount };
      tax = { ...tax, [i]: item.tax };
      finalAmount = { ...finalAmount, [i]: item.finalAmount };
      year = { ...year, [i]: item.year };
      if(isEqual(courseType,'MSCR')){
        itemTypesId = {...itemTypesId, [i]: {label: item.itemType, value: item.itemType }}
      }
      cash={...cash, [i]:item.cash};
      cheque= {...cheque, [i]:item.cheque}

      return { ...item, itemTypeSetupId: item.itemType, totalTaxAmount: feeObject?.totalTaxAmount ?? 0, itemTypeSequenceDetailId: feeObject?.itemTypeSequenceDetailId }
    })




    setFeeBreakupData({
      ...feeBreakupData,
      dueDate: dueDate,
      baseAmount: baseAmount,
      tax: tax,
      finalAmount: finalAmount,
      year: year,
      itemTypesId: itemTypesId,
      cash:cash,
      cheque:cheque
    })

    fetchClasses(alteredFeeBreakUp);
  }

  useState(() => {
      if(isEqual(courseType,'MSCR')){
        setExceptionalCourse(true)
      }
      if (!isEqual(action, 'edit') && feeBreakup) {
        fetchClasses(feeBreakup);
      } else {
        if (isEqual(action, 'edit') && savedFeeBreakUpData && feeBreakup) {
          setFeeBreakup()
        }
      }
  }, [feeBreakup, savedFeeBreakUpData, courseType]);

  const refreshPage = () => { };

  const addNewClass = () => {
    setclassArray([
      ...classArray,
      {
        itemType: "",
        dueDate: "",
        baseAmount: "",
        tax: "",
        finalAmount: "",
        year: "",
        cash:"",
        cheque:"",
        itemTypeSequenceDetailId: "",
        isEdit: false,
        isNew: true,
        __status: "__new",
      },
    ]);
  };

  const removeOptionFromList = (itemType) =>{
    const __tempItemTypeData = [...itemTypeData]
      const index = __tempItemTypeData.findIndex(item => item.value === itemType)
      __tempItemTypeData.splice(index,1)
      setItemTypeData([...__tempItemTypeData])
  }

  const newRow = () => { };
  
  const handleSelectAll = (e) => {

    if (e?.target?.name == 'cashAll') {
      let selectAllCash = {};
      if(e?.target?.checked===true){
      for (let key in feeBreakupData?.cash) {
        if (feeBreakupData?.cash.hasOwnProperty(key)) {
          selectAllCash = { ...selectAllCash, [key]: true }
        }
      }
    }
    else{
      for (let key in feeBreakupData?.cash) {
        if (feeBreakupData?.cash.hasOwnProperty(key)) {
          selectAllCash = { ...selectAllCash, [key]: false }
        }
      }
    }

      setFeeBreakupData((prev)=>({ ...prev, cash: selectAllCash }))
    }
    else{
      let selectAllCheque = {}
      if(e?.target?.checked===true){
      for(let key in feeBreakupData?.cheque){
          if(feeBreakupData?.cheque?.hasOwnProperty(key)){
            selectAllCheque={...selectAllCheque,[key]: true}
          }
      }
    }
    else{
      for(let key in feeBreakupData?.cheque){
        if(feeBreakupData?.cheque?.hasOwnProperty(key)){
          selectAllCheque={...selectAllCheque,[key]: false}
        }
    }
    }
      setFeeBreakupData((prev)=>({ ...prev, cheque: selectAllCheque }))
    }
  }

  useEffect(()=>{
    let cashAll= true;
    let chequeAll = true;
    for(let key in feeBreakupData?.cash){
      if(!feeBreakupData?.cash?.[key]){
       cashAll=false;
       break;
      }
  }
  for(let key in feeBreakupData?.cheque){
    if(!feeBreakupData?.cheque?.[key]){
      chequeAll=false;
      break;
    }
  }
  setSelectAll({cash:cashAll,cheque:chequeAll})
    
    

  },[feeBreakupData?.cash,feeBreakupData?.cheque])
  return (
    <Accordion
      title="Fee Breakup"
      isFormOpen={true}
      rightSideInfo={
        <div className="d-flex">
          <div className="d-flex align-items-center" style={{ marginRight: '42px' }}>
            <div className="mr-2">
              Select / Deselect All
            </div>
            <FormControlLabel
              sx={{ marginBottom: "0px" }}
              control={
                <Checkbox
                  size="small"
                  onChange={(e) => {
                    setSelectAll({ ...selectAll, cash: e?.target?.checked })
                    handleSelectAll(e)
                  }}
                  name="cashAll"
                  checked={selectAll?.cash}
                />
              }
              label='CASH'
              labelPlacement="start"
            />
            <FormControlLabel
              sx={{ marginBottom: "0px" }}
              control={
                <Checkbox
                  size="small"
                  onChange={(e) => {
                    setSelectAll({ ...selectAll, cheque: e?.target?.checked })
                    handleSelectAll(e)
                  }}
                  name="chequeAll"
                  checked={selectAll?.cheque}
                />
              }
              label="CHKL"
              labelPlacement="start"
            />
          </div>
        <CustomButton
          className={'floatRight mx-1 '}
          content={"New"}
          permissionType={'C'}
          icon={true}
          forTable={true}
          onClick={() => addNewClass()}
          permissionSet={userPermissions}
        />
        </div>
      }
    >
      {apiloader ? (
        <div
          className="mx-auto text-center py-5 my-5"
          style={{ height: "100vh" }}
        >
          <CustomLoader apiLoader={apiloader} />
        </div>
      ) : (
        <>
          <FeeBreakupTable
            fetchClasses={refreshPage}
            setclassArray={setclassArray}
            classArray={classArray}
            isSaveVisible={isSaveVisible}
            setIsSaveVisible={setisSaveVisible}
            serverData={serverData}
            itemTypeData={itemTypeData}
            activeItemTypeData={itemTypeData}
            newRow={newRow}
            action={action}
            feeBreakupData={feeBreakupData}
            setFeeBreakupData={setFeeBreakupData}
            isDisabled={isDisabled}
            courseStartDate={courseStartDate}
            courseEndDate={courseEndDate}
            totalYears={totalYears}
            courseType={courseType}
            isExceptionalCourse={isExceptionalCourse}
          />
          {/* {pagination.totalPage > 1 ? (
            <GetPagination
              isSaveVisible={isSaveVisible}
              setclassArray={setclassArray}
              pagination={pagination}
              setpagination={setpagination}
              pageSize={pageSize}
              state={state}
            />
          ) : null} */}
          {!isDisabled && (
            <Row className="justify-content-end pl-4 pr-4 pb-4 mt-4">
              {/* <Button
                color="info"
                size="sm"
                type="button"
                className="mx-1"
                disabled={fetchingIsBreakupValid || (!classArray.length)}
                onClick={() => feeBreakupHandler(classArray, feeBreakupData)}
              >
                {fetchingIsBreakupValid ? (
                  <i className="fas fa-spinner fa-spin" aria-hidden="true"></i>
                ) : (
                  "Preview"
                )}
              </Button> */}
              <CustomButton
                type="normal"
                color="info"
                content={fetchingIsBreakupValid ? (
                  <i className="fas fa-spinner fa-spin" aria-hidden="true"></i>
                ) : (
                  "Preview"
                )}
                permissionType={'C,U'}
                permissionSet={userPermissions}
                onClick={() => feeBreakupHandler(classArray, feeBreakupData)}
                disabled={fetchingIsBreakupValid || (!classArray.length)}
              />
            </Row>
          )}
        </>
      )}
    </Accordion>
  );
};

export default FeeBreakup;
