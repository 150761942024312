import React, { useState } from "react";
import moment from 'moment';
import { Card, CardHeader, Container, Input, Row, Table } from "reactstrap";
import { masterServiceBaseUrl, putpost, putpost2 } from 'services/http';
import { MasterHeader, TDROW } from "../../common/commonComponents/masterRow/masterRow";
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import CustomButton from "components/CustomButton";
var _ = require('lodash');
const ItemTypeCategoryTable = (props) => {
  const { userPermissions, isSaveVisible, setisSaveVisible = () => { } } = props;
  const [tableDataKey, settableDataKey] = useState('tableDataKey')
  const addNewItemTypeCategory = () => {
    props.setitemTypeCategoryArray([{ "__status": "__new", "editMode": true, "itemTypeCategoryKey": null, "itemTypeCategoryDesc": null, 'status': 'ACTIVE', "itemTypeCategoryDispValue": null, "effectiveDate": null }, ...props.itemTypeCategoryArray])
  }
  const saveAll = () => {
    var _tempArray = props.itemTypeCategoryArray
    var isValid = true
    _tempArray.forEach((el, index) => {
      if (el['editMode']) {
        if (el['itemTypeCategoryKey'] == null || !el['itemTypeCategoryKey'].trim()) {
          isValid = false
          failureToast('Please enter the value of Key')
          return
        }
        else if (el['itemTypeCategoryDesc'] == null || !el['itemTypeCategoryDesc'].trim()) {
          isValid = false
          failureToast('Please enter the value of Description')
          return
        } else if (el['itemTypeCategoryDispValue'] == null || !el['itemTypeCategoryDispValue'].trim()) {
          isValid = false
          failureToast('Please enter the value of Display Value')
          return
        }

        if (!isValid) {
          return
        }
        if (_tempArray[index]['__status'] == "__new") {
          putpost(masterServiceBaseUrl + '/itemTypeCategory/createItemTypeCategory', (data) => {
            successToast(data['message'])
            setisSaveVisible(false)
            props.fetchItemTypeCategory(masterServiceBaseUrl + '/itemTypeCategory/getAllItemTypeCategory')
          }, (data) => { failureToast(data['message']) }, _tempArray[index], 'post')
        } else {
          putpost(masterServiceBaseUrl + '/itemTypeCategory/updateItemTypeCategory/' + _tempArray[index].id, (data) => {
            successToast(data['message'])
            setisSaveVisible(false)
            delete _tempArray[index]['editMode']
            props.setitemTypeCategoryArray(_tempArray)
            settableDataKey(new Date().getMilliseconds())
            // props.fetchItemTypeCategory(masterServiceBaseUrl + '/ItemTypeCategory/getAllitemTypeCategory')
          }, (data) => { failureToast(data['message']) }, _tempArray[index], 'put')
        }
      } else {

      }
    })

  }
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes")
    }
    else {
      let cb = () => {
        addNewItemTypeCategory()
        setisSaveVisible(true)
        // settableDataKey(new Date().getMilliseconds())
      }
      var _tempArray = props.itemTypeCategoryArray
      _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
      props.setitemTypeCategoryArray(_tempArray)
      setisSaveVisible(false)
      cb()
    }
  }
  const getOptionIndex = (x, array) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i]['value'] == x) {
        return array[i]
      }
    }
  }
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes")
    }
    else {
      let cb = () => {
        var _tempArray = props.itemTypeCategoryArray
        _tempArray[index]['editMode'] = true
        props.setitemTypeCategoryArray(_tempArray)
        setisSaveVisible(true)
      }
      var _tempArray = props.itemTypeCategoryArray
      _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
      cb()
    }
  }
  const deleteRow = (index) => {
    if (props.itemTypeCategoryArray[index]["__status"] == "__new") {
      let n = props.itemTypeCategoryArray
      n.splice(index, 1)
      props.setitemTypeCategoryArray(n)
      setisSaveVisible(false)
      settableDataKey(new Date().getMilliseconds())
    } else {
      if (window.confirm('Are you sure you want to delete this Item Type Category?')) {
        putpost2(masterServiceBaseUrl + '/itemTypeCategory/deleteItemTypeCategory/' + props.itemTypeCategoryArray[index].id, (data) => {
          successToast(data['message'])
          if (props.itemTypeCategoryArray.length == 1) {
            props.previousPage()
            props.fetchItemTypeCategory(masterServiceBaseUrl + '/itemTypeCategory/getAllItemTypeCategory')
          } else {
            var _tempArray = props.itemTypeCategoryArray
            if (_tempArray[index]['editMode']) {
              setisSaveVisible(false)
            }
            _tempArray.splice(index, 1)
            props.setitemTypeCategoryArray(_tempArray)
          }
          settableDataKey(new Date().getMilliseconds())
        }, (data) => { failureToast(data['message']) }, 'PUT')
      }
    }
  }
  const updateKey = (index, value, key) => {
    var _tempArray = props.itemTypeCategoryArray
    _tempArray[index][key] = value
    props.setitemTypeCategoryArray(_tempArray)
  }
  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Item Type Category</h3>
                <CustomButton
                  disabled={isSaveVisible}
                  className={'floatRight mx-1' + (isSaveVisible ? ' btn-dark cursor-gray' : null)}
                  content={'New Item Type Category'}
                  permissionType={'C'}
                  icon={true}
                  onClick={() => { newRow(); }}
                  permissionSet={userPermissions}
                />
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout" >
                  <MasterHeader type={"Item Type Category"} permissionSet={userPermissions} isSaveVisible={isSaveVisible} />
                  <tbody className="list" key={tableDataKey} >
                    {props.itemTypeCategoryArray ? props.itemTypeCategoryArray.map((el, index) =>
                      !el['editMode'] ? (
                        <TDROW type={"itemTypeCategory"} index={index} el={el} editRow={editRow} deleteRow={deleteRow} permissionSet={userPermissions} isSaveVisible={isSaveVisible} />
                      ) :
                        (<tr key={index + '-itemTypeCategory'}>
                          <td className="text-center p-2 mx-1"><Input maxLength={50} defaultValue={el['itemTypeCategoryKey']} onChange={(e) => { updateKey(index, e.target.value, 'itemTypeCategoryKey') }} placeholder="Example:1" className="tdInput" /></td>
                          <td className="text-center p-2 mx-1"><Input disabled={true} defaultValue={el["__status"] == "__new" ? null : moment(el['effectiveDate']).format('DD-MM-YYYY')} className="tdInput" /></td>
                          <td className="text-center p-2 mx-1"><Input maxLength={50} defaultValue={el['itemTypeCategoryDesc']} onChange={(e) => { updateKey(index, e.target.value, 'itemTypeCategoryDesc') }} placeholder="Example:XII" className="tdInput" /></td>
                          <td className="text-center p-2 mx-1"><Input maxLength={50} defaultValue={el['itemTypeCategoryDispValue']} onChange={(e) => { updateKey(index, e.target.value, 'itemTypeCategoryDispValue') }} placeholder="Example:XII" className="tdInput" /></td>
                          <td className="text-center p-2 mx-1">
                            <label className="custom-toggle mx-auto ml-2">
                              <input checked={el.status == "ACTIVE" ? true : false} type="checkbox" id={el.id} onChange={(e) => { settableDataKey(new Date().getMilliseconds()); let n = "ACTIVE"; if (!e.target.checked) { n = "INACTIVE" } updateKey(index, n, 'status') }} />
                              <span style={{ width: "72px" }} className="custom-toggle-slider activeToggle" data-label-off="Inactive" data-label-on="Active" />
                            </label>
                          </td>
                          <td className="text-center">
                            {isSaveVisible ?
                              <>
                                <CustomButton
                                  content={'Save'}
                                  permissionType={'C,U'}
                                  icon={false}
                                  forTable={true}
                                  onClick={() => { saveAll() }}
                                  permissionSet={userPermissions}
                                />
                                <CustomButton
                                  content={''}
                                  permissionType={'cancel'}
                                  icon={true}
                                  forTable={true}
                                  onClick={() => {
                                    let n = props.itemTypeCategoryArray
                                    if (n[index]['__status'] == '__new') {
                                      n.splice(index, 1)
                                    } else {
                                      props.fetchItemTypeCategory(masterServiceBaseUrl + '/itemTypeCategory/getAllItemTypeCategory')
                                    }
                                    props.setitemTypeCategoryArray(n)
                                    setisSaveVisible(false)
                                  }}
                                  permissionSet={userPermissions}
                                />
                              </> :
                              <CustomButton
                                content={''}
                                permissionType={'D'}
                                icon={true}
                                forTable={true}
                                onClick={() => deleteRow(index)}
                                permissionSet={userPermissions}
                              />
                            }
                          </td>
                        </tr>)
                    ) : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
// .custom-toggle input:checked + .custom-toggle-slider:before {
//   background: #5e72e4;
//   transform: translateX(28px);
// }
export default ItemTypeCategoryTable;