import React, { useContext, useEffect, useState } from 'react'
import ALoader from '../../common/a-loader/index'
import { endpoint, pages } from '../../common/constant'
import { PermissionContext, RolePermissions } from 'appContext'
import { fetchAllPostPromisedData, fetchAllPromisedData, updateRequest } from '../../common/utils/methods/commonMethods/utilityMethod'
import HeaderComponent from '../viewAcademicPlan/HeaderComponent'
import PageLoadingAnimation from 'views/pages/manage/common/commonComponents/PageLoadingAnimation';
import TestsSearchFilter from './TestsSearchFilter'
import TestCard from './TestCard';
import CustomLoader from '../../common/commonComponents/Loader/loader';
import TestForm from '../academicTest/TestForm';
import { fetchDropdowns, getFilterCount, getInitialFilterForm } from '../constant/view-tests'
import EmptyState from './EmptyState'
import DisplayImportedTests from './DisplayImportedTests'
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function'
import DisplayPlan from './DisplayPlan';

const TestListView = (props) => {

  const userPermissions = RolePermissions(useContext(PermissionContext), pages['searchAcademicPlan']['id']);
  const { id } = props.match.params;
  const [planData, setPlanData] = useState({});
  const [apiLoader, setApiLoader] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [filterPayload, setFilterPayload] = useState(getInitialFilterForm());
  const [filterForm, setFilterForm] = useState(getInitialFilterForm());
  const [filterCount, setFilterCount] = useState(0);
  const [testsArray, setTestsArray] = useState([]);
  const [fetchingTests, setFetchingTests] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [dropdowns, setDropdowns] = useState({});
  const [importedTests, setImportedTests] = useState([]);
  const [testIdsToBeImported, setTestIdsToBeImported] = useState([])
  // this state for import test plan dropdown
  const [form, setForm] = useState({})

  const inputHandler = (value, inputKey) => {
    setFilterForm(currentData => { return { ...currentData, [inputKey]: value } });
  }
  const applyNameFilterHandler = () => {
    setPage(0);
    const updatedPayload = filterPayload;
    updatedPayload['testName'] = filterForm['testName']
    setFilterPayload(updatedPayload);
    // dispatch(getPlanList(updatedPayload, getTabRequest(currentTabValue, state?.dropdowns?.academicGroup), 0));
    getTestList(updatedPayload, 0);
  }
  const clearNameFilterHandler = () => {
    setPage(0);
    const updatedPayload = filterPayload;
    updatedPayload['testName'] = ''
    setFilterPayload(updatedPayload);
    // dispatch(getPlanList(updatedPayload, getTabRequest(currentTabValue, state?.dropdowns?.academicGroup), 0))
    getTestList(updatedPayload, 0);
  }
  const applyFilterHandler = () => {
    setPage(0);
    setFilterCount(getFilterCount(filterForm));
    setFilterPayload(filterForm);
    // dispatch(getPlanList(filterForm, getTabRequest(currentTabValue, state?.dropdowns?.academicGroup), 0));
    getTestList(filterForm, 0);
  }
  const removeFilterHandler = () => {
    setPage(0);
    setFilterCount(0);
    const resettedFilterForm = { ...getInitialFilterForm(), name: filterForm?.name }
    setFilterForm(resettedFilterForm);
    setFilterPayload(resettedFilterForm);
    // dispatch(getPlanList(resettedFilterForm, getTabRequest(currentTabValue, state?.dropdowns?.academicGroup), 0));
    getTestList(resettedFilterForm, 0);
  }
  const fetchPlanData = async (id) => {
    setApiLoader(true);
    const response = await fetchAllPromisedData(`${endpoint.viewAcademicPlan.getPlanById}/${id}?requireDispValue=true`, true)
    // console.log(response)
    if (response?.data) {
      setPlanData(response?.data);
    } else {
      setPlanData({});
    }
    const dropdowns = await fetchDropdowns();
    setDropdowns(dropdowns);
    setApiLoader(false);
  }

  const getTestList = async (filterPayload = {}, page = 0) => {
    try {
      setFetchingTests(true);
      const response = await fetchAllPostPromisedData(`${endpoint?.academicPlanTests?.getTestList}?offset=${page}`, updateRequest({ ...filterPayload, academicPlanId: id }), 'post');
      // console.log(response?.data?.searchTestScheduleDtoList);
      if (response?.code === 200) {
        setTestsArray(response?.data?.searchTestScheduleDtoList);
        setTotalPages(response?.data?.totalPage);
      } else {
        setTestsArray([]);
      }
      setExpandedIndex(-1); // to handle collapse on new fetch
      setFetchingTests(false);
    } catch (err) {
      console.log(err);
    }
  }

  const initialiseList = async () => {
    setPage(0);
    setFilterForm(getInitialFilterForm()); // initial form
    setFilterPayload(getInitialFilterForm()); // initial form
    setFilterCount(0);
    await getTestList(getInitialFilterForm(), 0); // pass initial form here
  }

  useEffect(() => {
    if (id) {
      fetchPlanData(id)
      // get initial tests api call here
      getTestList(getInitialFilterForm(), 0);
    }
  }, [id])



  const updateTestHandler = async (updateRequest = {}, testData = {}) => {
    setFetchingTests(true);
    const res = await fetchAllPostPromisedData(endpoint?.academicPlanTests?.updateTest, updateRequest, 'put')
    if (res?.code === 200) {
      setPage(0);
      await getTestList(filterPayload, 0);
    }
    setFetchingTests(false);
  }

  const updateTestStatusHandler = async (displayStatus, testData = {}) => {
    const updateRequest = {
      id: testData?.id,
      active: displayStatus === 'ACTIVE' ? true : false
    }
    await updateTestHandler(updateRequest);
  }

  const updateAttachTestHandler = async (attachTestForm, testData = {}) => {
    const updateRequest = {
      id: testData?.id,
      basicDetails: {
        attachTestId: attachTestForm?.value,
        attachTestShortCode: attachTestForm?.label,
      }
    }
    await updateTestHandler(updateRequest);
  }

  const saveImportedTests = async () => {

    try {
      setFetchingTests(true);
      if (testIdsToBeImported.length === 0) {
        failureToast("Please select at least one test to import");
        setFetchingTests(false);

        return;
      }
      const res = await fetchAllPostPromisedData(endpoint?.academicPlanTests?.importTestSchedule,
        {
          "testScheduleId": testIdsToBeImported,
          "academicPlanId": planData?.id

        })
      if (res?.code === 200) {
        setPage(0);
        successToast(res?.message)
        setImportedTests([]);
        setTestIdsToBeImported([])
        setForm({})
        await getTestList(filterPayload, 0);
      }
      setFetchingTests(false);
    } catch (error) {

    }
  }

  // useEffect(() => {
  //   console.log('form', filterForm);
  //   console.log('payload', filterPayload);
  // }, [filterForm, filterPayload])

  return (
    apiLoader ? (
      <PageLoadingAnimation />
    ) : (
      <div className='remove-container-top-space'>
        <HeaderComponent
          planData={planData}
          fetchPlanData={fetchPlanData}
          setApiLoader={setApiLoader}
          userPermissions={userPermissions}
        />
        <div style={{ position: 'relative' }}>
          {fetchingTests && <ALoader position={'fixed'} />}
          <TestsSearchFilter
            setIsFormOpen={setIsFormOpen}
            filterCount={filterCount}
            filterForm={filterForm}
            inputHandler={inputHandler}
            applyFilterHandler={applyFilterHandler}
            removeFilterHandler={removeFilterHandler}
            applyNameFilterHandler={applyNameFilterHandler}
            clearNameFilterHandler={clearNameFilterHandler}
            dropdowns={dropdowns}
            academicGroup={planData?.academicGroup}
            setImportedTests={setImportedTests}
            setTestIdsToBeImported={setTestIdsToBeImported}
            importedTests={importedTests}
            saveImportedTests={saveImportedTests}
            planId={id}
            testsArray={testsArray}
            form={form}
            setForm={setForm}
          />
          {form?.label && importedTests.length > 0 &&
            <div className='p-2 pb-2'>
              <DisplayPlan planName={form?.label}></DisplayPlan>
            </div>
          }
          <div className='p-2'>
            {importedTests.length > 0 ?
              <DisplayImportedTests
                importedTests={importedTests}
                testIdsToBeImported={testIdsToBeImported}
                setTestIdsToBeImported={setTestIdsToBeImported}
                planData={planData}
                expandedIndex={expandedIndex}
                setExpandedIndex={setExpandedIndex}
                updateTestStatusHandler={updateTestStatusHandler}
                updateAttachTestHandler={updateAttachTestHandler}
              /> :
              testsArray?.length > 0 ? (
                testsArray?.map((test, index) => (
                  <TestCard
                    planData={planData}
                    testData={test}
                    key={test?.id}
                    index={index}
                    expandedIndex={expandedIndex}
                    setExpandedIndex={setExpandedIndex}
                    initialiseList={initialiseList}
                    updateTestStatusHandler={updateTestStatusHandler}
                    updateAttachTestHandler={updateAttachTestHandler}
                    userPermissions={userPermissions}
                  />
                ))
              ) : (
                <>
                  <EmptyState setIsFormOpen={setIsFormOpen} />
                </>
              )}
          </div>
          {/* {fetchingNextTests && <CustomLoader />} */}
          {/* {fetchingNextTests && <div style={{ position: 'relative', padding: '35px 0' }}><ALoader /></div>} */}
        </div>
        {isFormOpen && (
          <TestForm
            planData={planData}
            academicPlanId={planData?.id}
            getTestList={initialiseList}
            open={isFormOpen} onClose={() => setIsFormOpen(false)}
          />
        )}
      </div>
    )
  )
}

export default TestListView