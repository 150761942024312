


import React from 'react'

const ListHeader = () => {
  return (
    <div style={{fontFamily:"sans-serif"}} className='heading-4 mb-2 mt-2 label-color'>Search students</div>
  )
}

export default ListHeader