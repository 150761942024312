import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom"
import { Accordion } from "../../../../../common/commonComponents";
import {
    putpost,
} from "services/http";
import {
    successToast,
    failureToast,
} from '../../../../../common/utils/methods/toasterFunctions/function';
import { endpoint, constants, commonMessages } from "../../../../../common/constant";
// import style from "./bluckUpload.module.scss";
import CustomButton from "components/CustomButton";
import { ButtonText } from "variables/Buttons";
import SupportingDocument from "../../../courseFee/CourseFee/pricingUpload/supportingDoc";
// import { fetchAllPromisedData } from "../../../../../common/utils/methods/commonMethods/utilityMethod";


const newRecord = {
    documentTypeDispValue: "",
    __isNew: true
}

const SupportingDocs = (props) => {

    const {
        userPermissions,
        records,
        setRecords = () => { },
        allRecords,
        setAllRecords = () => { },
        setIsSupportingDocValid = () => {},
        setSupportingDocDto = () => {},
        isPreview,
        isFormOpen
    } = props;

    //stepper states
    const [activeStep, setActiveStep] = useState(0);
    const [description, setDescription] = useState('');
    const [isSupportingDocValid, setSupportingDocValid] = useState(false);
    const [isUploading, setISUploading] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    // const [records, setRecords] = useState([]);
    // const [allRecords, setAllRecords] = useState([])
    const [isSaveVisible, setIsSaveVisible] = useState(false);
    const [finalData, setFinalData] = useState({});



    const newDocument = () => {
        setRecords([...records, newRecord]);
        setIsSaveVisible(true)
    }

    //supporting Document
    const addDataBeforeNext = (key,data) => {
        setSupportingDocDto(data)
        setIsSupportingDocValid(true)
    }

    return (
        <Accordion
            title="Supporting Document"
            isFormOpen={isFormOpen}
            rightSideInfo={
                <CustomButton
                    content={ButtonText.UPLOADPAGE.ADD}
                    // className={'floatRight mx-1' + (!allRecords.length || isSaveVisible ? ' btn-dark' : null)}
                    color={!allRecords.length ? 'danger' : 'info'}
                    permissionType={'C'}
                    icon={true}
                    forTable={true}
                    onClick={newDocument}
                    permissionSet={userPermissions}
                    disabled={isUploading || isSaveVisible || !allRecords.length}
                />
            }
        >
            <SupportingDocument
                userPermissions={userPermissions}
                addDataBeforeNext={addDataBeforeNext}
                goBack={() => { }}
                activeStep={1}
                isInManual={true}
                isPreview={isPreview}
                description={""}
                records={records}
                allRecords={allRecords}
                setRecords={setRecords}
                setAllRecords={setAllRecords}
                isSaveVisible={isSaveVisible}
                setIsSaveVisible={setIsSaveVisible}
                isFetching={isFetching}
                isUploading={isUploading}
                setISUploading={setISUploading}
                setIsConfirmationOpen={() => { }}
            />
        </Accordion>


    );
};

export default withRouter(SupportingDocs);