import React, { useContext, useEffect } from "react";
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import "./style.scss";
import { PAGE_STATE, StudentListContext } from "../contextApi";
import { chargeStatusMapping, chargesStatueDropdown, paymentStatusDropdown } from '../common';
import { useDispatch, useSelector } from "react-redux";
// import { getStudentList } from "../../../../../../redux/studentCharges/actions";
import { failureToast } from "../../../common/utils/methods/toasterFunctions/function";
import { useGetCourseIdsForBUAndTerm } from "../../../edpOps/stockManagement/customhooks";
import { getUpdatedChargeStatus } from "./utils";

const StudentFilterForm = ({ studentContext, getStudentList, storeState }) => {
  const inputHeight = "42px";
  const marginTop = { marginTop: "33px" };
  const dispatch = useDispatch();
  const { globalFilter, setGlobalFilter, setCurrentPageState } = useContext(studentContext);
  const studentCharges = useSelector((state) => state[storeState]);
  // const batchDropdown = useGetBatchList(
  //   dropdown?.businessAreas?.length === 1?dropdown?.businessAreas[0] :filterForm?.businessArea,
  //   filterForm?.term
  // );

  const formHandler = (key, value) => {
    setGlobalFilter(prev => ({ ...prev, [key]: value }))
  }


  const fetchInitialList = () => {
    const filters = { ...globalFilter };
    // delete filters['searchField'];
    const chargesStatusValuesArr = filters.chargesStatus.map(st => st.value);
    delete filters['psid']
    delete filters['applicationId']
    dispatch(
      getStudentList(
        { ...filters , chargesStatus : getUpdatedChargeStatus(chargesStatusValuesArr) },
        0
      )
    );

    setGlobalFilter({ ...filters })
  }

  const setFilterAndCallApi = () => {
    const isFilterApplied = Object.keys(globalFilter).filter(key => !['searchField', 'psid', 'applicationId'].includes(key)).some(key => globalFilter?.[key]?.length);

    if (isFilterApplied) {
      fetchInitialList();
      setCurrentPageState(PAGE_STATE.LIST_STUDENT)
    } else {
      failureToast("Please provide atleast one filter to search")
    }

    // history.push('/admin/studentCharges')
  }

  const [courseIdDropdown, courseIdDropdownLoading] = useGetCourseIdsForBUAndTerm({
    businessArea: globalFilter?.businessArea?.[0],
    term: globalFilter?.term?.[0]
  })


  const styles = {
    display: 'grid',
    gridTemplateColumns: 'repeat(5,minmax(120px, 1fr))',
    gap: '16px',
    alignItems: 'center',
  };

  const applicationStatusDropdown = chargesStatueDropdown.map(item => ({...item , label : `${item.label} (${chargeStatusMapping[item.label]})`}));

  return (
    <div style={styles} className="mt-4 mb-2">
      <div>
        <AAutoComplete
          label="Business Area"
          height={inputHeight}
          placeholder="Business Area"
          isMulti={false}
          // isShowCount={true}
          items={studentCharges?.dropdowns?.businessArea ?? []}
          currentValue={globalFilter?.businessArea}
          handleChange={(value) => { formHandler("businessArea", [value]) }}
        />
      </div>

      <div>
        <AAutoComplete
          label="Term"
          height={inputHeight}
          placeholder="Term"
          // items={dropdown?.terms}
          // isShowCount={true}
          isMulti={false}
          items={studentCharges?.dropdowns?.term ?? []}
          currentValue={globalFilter?.term}
          handleChange={(value) => { formHandler("term", [value]) }}
        />
      </div>

      <div>
        <AAutoComplete
          height={inputHeight}
          label="Course Id"
          placeholder="Course Id"
          isMulti={true}
          isShowCount={true}
          items={courseIdDropdown}
          currentValue={globalFilter?.courseId}
          handleChange={(value) => { formHandler("courseId", value) }}
          selectAllMenuButton={false}
          isLoading={courseIdDropdownLoading}
        />
      </div>
      <div>
        <AAutoComplete
          height={inputHeight}
          label="Charge Status"
          placeholder="Charge Status"
          items={applicationStatusDropdown}
          isShowCount={true}
          currentValue={globalFilter?.chargesStatus}
          style={{menuPortal : {width : '290px'}}}
          handleChange={(value) => { formHandler("chargesStatus", value) }}
        />
      </div>
      {/* <div>
        <AAutoComplete
          height={inputHeight}
          label="Payment Status"
          placeholder="Payment Status"
          items={paymentStatusDropdown}
          isShowCount={true}
          currentValue={globalFilter?.paymentStatus}
          handleChange={(value) => { formHandler("paymentStatus", value) }}
        />
      </div> */}

      <div style={marginTop}>
        <AButton
          className="button_remove_left_margin"
          updatedStyle={{ fontSize: "12px", width: "100%" }}
          onClick={setFilterAndCallApi}
          variant="primary_filled"
        >
          Apply
        </AButton>
      </div>
    </div>
  );
};

export default StudentFilterForm;
