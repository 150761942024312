import React from 'react'
import { Tooltip } from '@mui/material'
import { TbLogout } from 'react-icons/tb';
import { REACT_ICONS_DANGER_STYLE, REACT_ICONS_STYLE } from '../constant/deallocate-students';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FiEye } from 'react-icons/fi';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper';

const LogoutSVG = ({ onClick = () => { } }) => {
  return (
    <svg onClick={onClick} style={{ cursor: 'pointer' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.016 7.38948V6.45648C15.016 4.42148 13.366 2.77148 11.331 2.77148H6.45597C4.42197 2.77148 2.77197 4.42148 2.77197 6.45648V17.5865C2.77197 19.6215 4.42197 21.2715 6.45597 21.2715H11.341C13.37 21.2715 15.016 19.6265 15.016 17.5975V16.6545" stroke="#CC5F5F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21.8094 12.0215H9.76843" stroke="#CC5F5F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.8812 9.10645L21.8092 12.0214L18.8812 14.9374" stroke="#CC5F5F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
}

// const ShowSVG = ({ onClick = () => { } }) => {
//   return (
//     <svg onClick={onClick} style={{cursor: 'pointer'}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//       <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1615 12.0531C15.1615 13.7991 13.7455 15.2141 11.9995 15.2141C10.2535 15.2141 8.8385 13.7991 8.8385 12.0531C8.8385 10.3061 10.2535 8.89111 11.9995 8.89111C13.7455 8.89111 15.1615 10.3061 15.1615 12.0531Z" stroke="#00B0F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
//       <path fill-rule="evenodd" clip-rule="evenodd" d="M11.998 19.355C15.806 19.355 19.289 16.617 21.25 12.053C19.289 7.48898 15.806 4.75098 11.998 4.75098H12.002C8.194 4.75098 4.711 7.48898 2.75 12.053C4.711 16.617 8.194 19.355 12.002 19.355H11.998Z" stroke="#00B0F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
//     </svg>

//   )
// }

const DeallocateStudentActions = ({ row, setOpenDialog, setId, setDialogType = () => { } }) => {
  return (
    <div>
      <Tooltip title="Deallocate Student">
        <span>
          {/* <TbLogout
            style={REACT_ICONS_DANGER_STYLE}
            onClick={(e) => {
              e.stopPropagation();
              setDialogType('deallocate')
              setOpenDialog(true);
              setId(row.applicationId)
            }}
          /> */}
          <IconButtonWrapper
          onClick={(e) => {
            e.stopPropagation();
            setDialogType('deallocate')
            setOpenDialog(true);
            setId(row.applicationId)
          }}
          >
          <LogoutSVG/>
          </IconButtonWrapper>
          
        </span>
      </Tooltip>
      {/* Below options to be added as per further requirements and developments */}
      {/* <Tooltip title="View Student">
        <span>
          <FiEye 
            style={REACT_ICONS_STYLE} 
            onClick={(e) => {
              e.stopPropagation();
              setOpenDialog(true);
              setId(row.applicationId)
            }}
          />
          <ShowSVG 
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </span>
      </Tooltip> */}
      {/* <Tooltip title="More Options">
        <span><BsThreeDotsVertical style={REACT_ICONS_STYLE} /></span>
      </Tooltip> */}
    </div>
  )
}

export default DeallocateStudentActions