import React, { useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import { endpoint } from '../../common/constant'
import AAutoComplete from '../../common/form-fields-mui/AAutoComplete'
import AButton from '../../common/form-fields-mui/AButton'
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod'
import { dropdownMapping, getPlanTests } from '../academicTimetable/helper'
import moment from 'moment'
import { failureToast } from '../../common/utils/methods/toasterFunctions/function'

const ImportTestForm = ({ 
  closeForm,
  academicGroup,
  setImportedTests = ()=>{},
  planId,
  businessAreaIds,
  testsArray,
  form,
 setForm
}) => {
  // const [form, setForm] = useState('');
  const [acdPlanDropdown, setAcdPlanDropdown] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

 const skipCurrentPlan = (plans = [])=>{
     return plans.filter(plan=>plan.value !== parseInt(planId))
 }

 const skipAlreadyImportedTests = (currentTests, importedTests) =>{
    
      const testNotImportedYet = [] ;
     if(Array.isArray(importedTests) && Array.isArray(currentTests)){
         importedTests.forEach(test => {

               currentTests.forEach(testItem =>{
                   if(testItem.id === test.id){
                    test['isAlreadyImported'] = true
                   }
               }) ;

                testNotImportedYet.push(test)
         })
     }
    
      return testNotImportedYet
 
 }
  const getPlanDropdown = async () => {
    setIsFetching(true);
    const response = await fetchAllPostPromisedData(`${endpoint.searchAcademicPlan.getPlanList}?requireDispValue=false&offset=${-1}`,
       {academicGroup:[academicGroup?.id], businessArea:businessAreaIds}, "post")
    
    if(response?.code===200){
        setIsFetching(false);
        setAcdPlanDropdown(
           skipCurrentPlan(dropdownMapping(response?.data?.academicPlanSearchResponseDTOList||[],'academicPlanName'))
        )
    }
    setIsFetching(false);
  }

  useEffect(() => {
    getPlanDropdown();
  }, [])

  const fetchPlanTests = async ()=>{
     try {
      const response = await getPlanTests(
        { plan:form,
          startFrom:new moment(moment().format('MM/DD/YYYY')),
          endOn:undefined
        }
  )
  if(response?.data?.searchTestScheduleDtoList &&response?.data?.searchTestScheduleDtoList.length>0){
  setImportedTests(skipAlreadyImportedTests(testsArray ,response?.data?.searchTestScheduleDtoList));
  closeForm()
  }else{
    failureToast("No test found in the selected Academic Plan. Please select a different academic plan to proceed")
  }
     } catch (error) {
      
     }
  

  }

  return (
    <Card className='mb-0' style={{ width: '370px' }}>
      <CardBody>
        <h4 style={{ fontSize: '20px', fontWeight: 600, color: '#0F0F0F' }}>Import Existing Tests</h4>
        <div>
          <AAutoComplete
            label='Select Academic Plan'
            placeholder='Select Academic Plan'
            currentValue={form}
            items={acdPlanDropdown}
            handleChange={(value) => setForm(value)}
            isMulti={false}
            isLoading={isFetching}
            noOptionsMessage='No Academic Plan found. Please check whether plan(s) are published and you have relevant security group access. To get relevant security group access, please contact productsupport@aesl.in'
          />
          <div className='d-flex justify-content-end mt-3'>
            <AButton 
              variant='outline' 
              updatedStyle={{height: '28px', fontSize: '12px', fontWeight: 400, borderWidth: '1px'}}
              onClick={closeForm}
            >
              Cancel
            </AButton>
            <AButton 
              variant='primary_filled' 
              updatedStyle={{height: '28px', fontSize: '12px', fontWeight: 400}}
             disabled={!form}
             onClick={fetchPlanTests}
            >
              Next
            </AButton>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default ImportTestForm