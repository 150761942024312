import { pricingServiceBaseUrl } from "services/http";

export const pricingServiceEndpoints = {
    manual_processing: {
        validate_header: `${pricingServiceBaseUrl}/courseFeeManualProcessing/validateManualCourseFeeRecord/`,
        create: `${pricingServiceBaseUrl}/courseFeeManualProcessing/createCourseFeeManual/`,
        edit: `${pricingServiceBaseUrl}/courseFeeManualProcessing/updateCourseFeeManual`,
        getSecurityFeeCharges: `${pricingServiceBaseUrl}/courseFeeManualProcessing/getSecurityFeeCharges`
    },
    setup_rule: {
        getNumberOfDays: `${pricingServiceBaseUrl}/getFeeSetupCourseAttributeByGroupCodeCompanyCodeAcademicCareerTermCourse`
    },
    l2_page: {
        getRecordsForL2: `${pricingServiceBaseUrl}/getCourseFeeDataTempWithApprovalDataHavingL1Approved`
    },
    bulk_pricing_processing: {
        uploadInfo: `${pricingServiceBaseUrl}/upload`,
    },
    course: {
        courseIds: `${pricingServiceBaseUrl}/getCourseIdWithL2ApprovedByAcademicTerm`,
        getCourseIdByAcademicTermAndBusinessArea: `${pricingServiceBaseUrl}/getCourseIdByAcademicTermAndBusinessArea`,
        getCourseIdByAcademicTermAndBusinessAreaAndCourseType: `${pricingServiceBaseUrl}/getCourseIdByAcademicTermAndBusinessAreaAndCourseCategory`,
        getCoursePricingDetails: `${pricingServiceBaseUrl}/getCoursePricingDetails`,
        getAllDlpCourseId: `${pricingServiceBaseUrl}/getAllDLPCourseIds`,
        getAllDigitalCourseId: `${pricingServiceBaseUrl}/getAllDigitalCourseIds`,
        getCourseIdsForBuAcadAndTerm: `${pricingServiceBaseUrl}/getLiveCoursesByBuAndTermAndAcademicGroup`,
    },
    pricingBusinessArea: {
        businessAreas: `${pricingServiceBaseUrl}/getBusinessAreaByCourseIdAndAcademicTerm`
    },
    breakup: {
        getAll: `${pricingServiceBaseUrl}/getCourseFeeBreakupByAcademicTermCourseIdBusinessArea`
    },

    download_csv: `${pricingServiceBaseUrl}/getApprovalFileBylevel`,
    features: {
        all: `${pricingServiceBaseUrl}/onlinePackage/features/all`
    },
    onlinePackage: {
        search: `${pricingServiceBaseUrl}/onlinePackage/search`,
        package: `${pricingServiceBaseUrl}/onlinePackage`,
        allCmsPackages: `${pricingServiceBaseUrl}/onlinePackage/search/cmsPackage/all`,
        getAll: `${pricingServiceBaseUrl}/onlinePackage/all`,
        getAllCourse: `${pricingServiceBaseUrl}/onlinePackage/cmsCourses/all`
    },
    getAllTermByCourseId: `${pricingServiceBaseUrl}/getAllTermByCourseId`,
    getCourseIdsForBUAndTerm:`${pricingServiceBaseUrl}/getCourseIdsForBUAndTerm`
}