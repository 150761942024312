import React, { useState } from "react";
import AButton from "../../../../common/form-fields-mui/AButton";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import CustomTooltip from "views/pages/manage/common/customTooltip/CustomTooltip";
import { Dialog } from "@mui/material";
import OnHoldReasonSelector from "views/pages/manage/edpOps/stockManagement/selectMeterialAndStock/issueStock/OnHoldReasonSelector";

const ActionsCellComponent = ({
  student,
  issueStockToApplication = () => {},
  selectedStudentIds,

}) => {

  return (
    <div className="d-flex align-items-center">
      <AButton
        variant="primary_filled"
        updatedStyle={{
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "16px",
          height: "28px",
          borderRadius: "4px",
        }}
        disabled={
          selectedStudentIds.length > 1 || !student?.isEligibleForStockIssuance
        }
        onClick={() => issueStockToApplication(student)}
      >
        Issue stock
      </AButton>
    </div>
  );
};
const OnHoldHandler =({setOpen=()=>{}})=>{
  return(
    <div className="cursor regular-bolder p-2" onClick={()=>{setOpen(true)}}>On hold</div>
  )
}

export const ActionsCellComponentMarkEligible = ({
  student,
  btnHandler = () => {},
  selectedStudentIds,
  index,
  students,
  setApplications
}) => {
  const [isOpen,setOpen]=useState(false);

  const onHoldHandler = (index,value) => {
    let currStudents = [...students];
    currStudents[index]["status"] = "ON_HOLD";
    currStudents[index]["onHoldReason"] = value;
    setApplications(currStudents);
  };
  return (
    <>
      <div className="d-flex align-items-center " style={{ gap: "16px" }}>
        <AButton
          variant="primary_filled"
          updatedStyle={{
            fontSize: "12px",
            fontWeight: 600,
            lineHeight: "16px",
            height: "28px",
            borderRadius: "4px",
          }}
          disabled={
            selectedStudentIds.length > 1 ||
            !student?.isDlpMarkStockIssuanceEligible ||
            student?.isAlreadyMarkedEligible
          }
          onClick={() => btnHandler(student)}
        >
          {student?.isAlreadyMarkedEligible ? "Eligible" : "Mark Eligible"}
        </AButton>

        <CustomTooltip
          showOnClick
          placement="bottom-end"
          title={<OnHoldHandler setOpen={setOpen} />}
        >
          <div className="cursor">
            <IoEllipsisVerticalSharp />
          </div>
        </CustomTooltip>
      </div>
      <OnHoldReasonSelector
        isOpen={isOpen}
        setOpen={setOpen}
        index={index}
        onHoldHaldler={onHoldHandler}
        btnHandler={btnHandler}
        student={student}
        isDisable={
          selectedStudentIds.length > 1 ||
          !student?.isDlpMarkStockIssuanceEligible ||
          student?.isAlreadyMarkedEligible
        }
        isAlreadyMarkedEligible={student?.isAlreadyMarkedEligible}
      />
    </>
  );
};

export default ActionsCellComponent;
