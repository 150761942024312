import React, { useContext, useState } from 'react';
import { ReactComponent as EyeIcon } from '../../../../../../assets/img/svg/eye_open.svg';
import { ReactComponent as EditIcon } from '../../../../../../assets/img/svg/Edit2.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../assets/img/svg/Delete.svg';
import DeleteDialog from './DeleteDialog';
import { useHistory } from 'react-router';
import { checkPermission } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { RolePermissions } from 'appContext';
import { PermissionContext } from 'appContext';
import { pages } from 'views/pages/manage/common/constant';
import LinkWrapper from 'views/pages/manage/common/Link';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper';
const ActionsCellComponent = ({ params, deletePlanHandler = () => {} }) => {
  const userPermissions = RolePermissions(
    useContext(PermissionContext),
    pages['daysPlan']['id']
  );
  const history = useHistory();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <div className="d-flex align-items-center">
        {checkPermission(userPermissions, 'R') && (
          <LinkWrapper url={`/admin/master/daysPlan/view/${params?.row?.id}`}>
            <IconButtonWrapper><EyeIcon width={22} height={16} /></IconButtonWrapper>
          </LinkWrapper>
        )}
        {checkPermission(userPermissions, 'U') && (
          <LinkWrapper
            url={`/admin/master/daysPlan/edit/${params?.row?.id}`}
            className="ml-4"
          >
            <IconButtonWrapper><EditIcon className="" /></IconButtonWrapper>
          </LinkWrapper>
        )}
        {/* {checkPermission(userPermissions, 'D') && (
          <DeleteIcon className="ml-4" onClick={() => setIsDialogOpen(true)} />
        )} */}
      </div>
      <DeleteDialog
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        data={params?.row}
        onAgree={() => deletePlanHandler(params?.row)}
      />
    </>
  );
};

export default ActionsCellComponent;
