import React from 'react'
import APopover from 'views/pages/manage/common/a-popover';
import ViewBranchesCommonComponent from 'views/pages/manage/common/branchesCommonComponent/ViewBranchesCommonComponent';
import { ReactComponent as EyeIcon } from '../../../../../../assets/img/svg/eye_open.svg';
import { ReactComponent as DownArrow } from '../../../../../../assets/img/svg/downarrow.svg';
import { ReactComponent as UpArrow } from '../../../../../../assets/img/svg/upwardArrow.svg';
import EditBranchesCommonComponent from 'views/pages/manage/common/branchesCommonComponent/EditBranchesCommonComponent';


const HeaderBusinessAreaComponent = ({ isEdit, planData, formData, formHandler = () => { } }) => {
  const SHOW_COUNT = 1;
  
  const { regions, businessAreas } = formData;
  let dispLabels = businessAreas?.map(item => item?.label).slice(0, SHOW_COUNT).join(", ");

  const branchesUpdateHandler = (updatedRegionBusinessAreaForm) => {
    const {region, businessArea } = updatedRegionBusinessAreaForm
    // console.log(region, businessArea);
    formHandler(region, 'regions');
    formHandler(businessArea, 'businessAreas');
  }

  return (
    <div className={`heading-4 d-flex`}>
      {dispLabels}
      {businessAreas?.length > SHOW_COUNT && (
        <span className='ml-2'>{`+${businessAreas?.length - SHOW_COUNT} more`}</span>
      )}
      {isEdit ? (
        <APopover
          //styles param of buttonComponent is used to determine if the overlay is open on not
          buttonComponent={(onClick, styles) => <span className='ml-3' onClick={onClick}>{styles ? <UpArrow /> : <DownArrow />}</span>}
          menuComponent={(closeForm) => (
            <EditBranchesCommonComponent 
              regions={regions} 
              businessAreas={businessAreas} 
              closeForm={closeForm} 
              updateHandler={branchesUpdateHandler}
            />
          )}
          highlightedStyle={true}
        />
      ) : (
        <APopover
          buttonComponent={(onClick, styles) => <EyeIcon onClick={onClick} className='ml-3' />}
          menuComponent={(closeForm) => <ViewBranchesCommonComponent regions={planData?.regions} businessAreas={planData?.businessAreas} closeForm={closeForm} />}
        />
      )}
    </div>
  )
}

export default HeaderBusinessAreaComponent