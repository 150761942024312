import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import { ReactComponent as CloudIcon } from "assets/img/svg/CloudArrow.svg";

import { ReactComponent as UserProfileIcon } from "assets/img/svg/CircleUser.svg";
import CropEasy from "../../../common/crop/CropEasy";
import { getFilePath, updateStudentPhotoKey } from "./common";
import "./style.scss";
import { failureToast } from "../../../common/utils/methods/toasterFunctions/function";


const UserPlaceHolder = () => {
  return (
    <div className="user-placeholder">
      <UserProfileIcon />
    </div>
  );
};

const UploadPhotoPlaceHolder = ({ handleChange, disableUpload }) => {

  return (
    <div className="d-flex flex-column">
      <span className="mb-4">Pick an image that shows the face</span>
      <UserPlaceHolder />
      <span className="mb-4 mt-2">File supported: .jpg, .jpeg, .png</span>

      <label htmlFor="profilePhoto">
        <input
          accept="image/*"
          id="profilePhoto"
          type="file"
          style={{ display: "none" }}
          onChange={handleChange}
        />
        <span className="upload-btn">
          Upload photo <CloudIcon className="ml-2"/>
        </span>
      </label>
    </div>
  );
};

const SelectPhotoModal = ({ open, setOpen, fetchApplications, psid }) => {

  const [file, setFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableUpload, setDisableUpload] = useState(false);

  const maxFileSize = 2097152   // 2MB mentioned value is in bytes


  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file.size > maxFileSize) {
      failureToast('Please select a photo of size less than 2 MB')
      return;
    }
    const fileType = file?.name?.split(".")?.pop();
    if (!['png', 'jpeg', 'jpg'].includes(fileType)) {
      failureToast("Only .jpg, .jpeg, .png files are allowed!")
      return;
    }

    if (file) {
      setFile(file);
      setPhotoURL(URL.createObjectURL(file));
      setOpenCrop(true);
    }
  };

  const saveUploadedPhotoKey = async (response) => {
    try {
      if (response?.key) {
        await updateStudentPhotoKey(psid, response?.key);
        await fetchApplications();
      }
    } catch (error) {}
  };

  const handleSubmit = async (file) => {
    setLoading(true);
    try {
      if (file) {
        const imageName = "new" + "." + file?.name?.split(".")?.pop();

        // save the cropped image
        setDisableUpload(true)
        getFilePath(imageName, file, setLoading, saveUploadedPhotoKey);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal size="md" isOpen={open}>
      <ModalBody>
        <div className="d-flex justify-content-between  mb-3">
          <span className="heading-3 color-dark">Add Photo</span>{" "}
          <span>
            <CrossIcon onClick={() => setOpen(false)} />
          </span>
        </div>
        <div>
          {!openCrop ? (
            <UploadPhotoPlaceHolder 
            disableUpload = {disableUpload}
            handleChange={handleChange} />
          ) : (
            <CropEasy
              {...{
                photoURL,
                setOpenCrop,
                setPhotoURL,
                setFile,
                handleSubmit,
                loading,
                disableUpload, 
                setDisableUpload
              }}
            />
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SelectPhotoModal;
