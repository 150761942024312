import React from "react";
import { useHistory } from "react-router";
import { Card, CardBody, Col, Row } from "reactstrap";
import { capitalize, checkPermission } from "../../../common/utils/methods/commonMethods/utilityMethod";
import {
  batchCardInfoLables,
  detailsRowStyles,
  linkButtonStyles,
} from "../../constant/student-details";
import CustomChip from "../CustomChip";

const cardStyles = cardType => {
  return {
    width: "49%",
    borderRadius: "8px",
    backgroundColor: cardType === "current" ? "#EDF7F2" : "#F5F5F5",
    marginBottom: "1rem",
    boxShadow: "none",
  };
};

const BatchDetailsCard = ({
  type = "current",
  batchDetails,
  setIsDialogOpen = () => {},
  setDialogBatchDetails = () => {},
  batchDetailsPermissions,
}) => {
  const history = useHistory();

  const viewBatchClickHandler = e => {
    if (e?.ctrlKey) {
      window.open(
        history.createHref({
          pathname: `/admin/edp/batch/view/${batchDetails?.batchId}`,
        }),
        "_blank"
      );
    } else {
      history.push(`/admin/edp/batch/view/${batchDetails?.batchId}`);
    }
  };

  const deallocateClickHandler = () => {
    setIsDialogOpen(true);
    setDialogBatchDetails(batchDetails);
  };

  return (
    <Card style={cardStyles(type)}>
      <CardBody>
        <div style={{ display: "flex", marginBottom: "20px" }}>
          <CustomChip
            text={capitalize(batchDetails?.batchStatus)}
            bgColor={type === "current" ? "#07794C" : "#FFFFFF"}
            textColor={type === "current" ? "#EDF7F2" : "#262626"}
            style={{ marginRight: "12px" }}
          />
          <CustomChip
            text={`Batch type: ${capitalize(batchDetails?.batchType)}`}
            bgColor={type === "current" ? "#07794C" : "#FFFFFF"}
            textColor={type === "current" ? "#EDF7F2" : "#262626"}
          />
        </div>
        <h4
          style={{ color: "#0F0F0F", fontSize: "20px", marginBottom: "34px" }}
        >
          {batchDetails?.batchName}
        </h4>
        <div>
          {batchCardInfoLables?.[type].map(infoRow => (
            <Row style={detailsRowStyles} key={infoRow["objKey"]}>
              <Col md="7">{infoRow["title"]}</Col>
              <Col md="5" style={{ fontWeight: 600 }}>
                {infoRow?.valueGetter
                  ? (infoRow?.valueGetter(batchDetails) || "-")
                  : (batchDetails?.[infoRow["objKey"]] || "")?.toString()?.trim() || "-"}
              </Col>
            </Row>
          ))}
        </div>
        <div style={{ display: "flex" }}>
          <span
            onClick={viewBatchClickHandler}
            style={{
              ...linkButtonStyles,
              color: "#00B0F5",
              marginRight: "24px",
            }}
          >
            View batch
          </span>
          {checkPermission(batchDetailsPermissions, ['C', 'U']) && type === "current" && (
            <span
              onClick={deallocateClickHandler}
              style={{ ...linkButtonStyles, color: "#E7685A" }}
            >
              Deallocate student
            </span>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default BatchDetailsCard;
