import React from 'react'
import AButton from '../../../../common/form-fields-mui/AButton'
import { ReactComponent as CrossIcon } from 'assets/img/svg/CrossIcon.svg'

const ActionsCellComponent = ({ params, handleEditClick = () => { }, handleSaveClick = () => { }, rowForm, stopEditing }) => {
  return (
    params.api.getRowMode(params?.id) === 'view' ? (
      <div className='d-flex align-items-center'>
        <AButton
          variant='outline'
          size='xs'
          updatedStyle={{ width: '86px' }}
          onClick={() => handleEditClick(params)}
          disabled={!params?.row?.isEligibleProgramActionForAllocation}
        >
          {params?.row?.rfid ? `Edit` : 'Add RFID'}
        </AButton>
      </div>
    ) : (
      <div className='d-flex align-items-center'>
        <AButton
          variant='primary_filled'
          size='xs'
          updatedStyle={{}}
          disabled={!rowForm?.rfid || rowForm?.rfid?.length !== 10}
          onClick={() => handleSaveClick(params)}
        >
          Save
        </AButton>
        <CrossIcon className='ml-2' onClick={() => stopEditing()} />
      </div>
    )
  )
}

export default ActionsCellComponent