import React, { useEffect, useState } from "react";
import LectureRowItem from "./LectureRowItem";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import { Popover } from "@mui/material";
import AInput from "views/pages/manage/common/formFeilds/customInput/AInput";
import { ArrowSvg, boldLevel, inputContainerStyle } from "./helperFile";

export const ButtonLikeDropdown = ({ disabled, handleClick, label, isError=false, style}) => {
  return (
    <AButton
      disabled={disabled}
      className="button_remove_left_margin"
      onClick={handleClick}
      id = "main-dropdown"
      updatedStyle={{ padding: "20px 10px", width: "100%",
         border : isError?'1px solid #DC2804':'1px solid #CFD3D4'
         , ...style 
     }}
    >
      <div className="d-flex justify-content-between align-items-center w-100 ">
        {label || "Select"} <ArrowSvg />
      </div>
    </AButton>
  );
};

const SelectLecturePopover = ({
  lecturesDropdown,
  editForm,
  editFormHandler,
  isDisabledOther,
  isDisabled,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filteredLectureList, setFilteredLectureList] =
    useState(lecturesDropdown);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    setFilteredLectureList(lecturesDropdown);
  }, [lecturesDropdown]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSearchInput("");
    setFilteredLectureList(lecturesDropdown);
  };
  const open = Boolean(anchorEl);
  const id = open ? "popover-lecture" : undefined;

  const selectLectureHandler = (lectureItem) => {
    editFormHandler(lectureItem, "lecture");
    handleClose();
  };

  const filterOnSearch = (searchInputValue) => {
    let data = lecturesDropdown.filter((item) => {
      return item?.label.toLowerCase().includes(searchInputValue.toLowerCase());
    });
    setFilteredLectureList(data);
  };

  const onLectureSearch = (value) => {
    setSearchInput(value);
    filterOnSearch(value);
  };

  return (
    <>
      <ButtonLikeDropdown
        disabled={isDisabled || isDisabledOther}
        className="button_remove_left_margin"
        handleClick={handleClick}
        updatedStyle={{ padding: "20px 10px", width: "100%" }}
        label={editForm?.lecture?.lectureName || editForm?.lecture?.label}
      ></ButtonLikeDropdown>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div
          className="p-2 m-2"
          style={{
            minWidth: "30rem",
            minHeight: "10rem",
            height: "30rem",
            overflow: "scroll",
            width: "49rem",
          }}
        >
          <div style={inputContainerStyle}>
            <AInput
              type="text"
              placeholder={"Search by Lecture no, chapter topic or subtopic"}
              value={searchInput}
              searchIcon
              crossIcon
              handler={(value) => onLectureSearch(value)}
              // onKeyDown={onKeyDown}
              crossHandler={() => {
                onLectureSearch("");
              }}
              // searchIconClickHandler={applyNameFilterHandler}
              style={{
                minWidth: "200px",
                border: "1px solid rgb(233, 236, 239)",
                height: "41px",
              }}
            />
          </div>

          {filteredLectureList.map((lectureItem) => (
            <LectureRowItem
              key={lectureItem?.value}
              {...{ lectureItem, selectLectureHandler }}
            />
          ))}
          <div className="d-flex align-items-center justify-content-center">
            {lecturesDropdown.length > 0 &&
              filteredLectureList.length === 0 && (
                <p className="m-4 p-4" style={boldLevel}>
                  No matching result found
                </p>
              )}
            {lecturesDropdown.length === 0 && (
              <p className="m-4 p-4" style={boldLevel}>
                Lecture not found
              </p>
            )}
          </div>
        </div>
      </Popover>
    </>
  );
};

export default SelectLecturePopover;
