
import React, { useEffect } from "react";
// react library for routing
import { Route, Switch, Redirect } from "react-router-dom";

// core components
import AuthFooter from "components/Footers/AuthFooter.js";

import Login from "../views/pages/login/Login";
import SessionInfo from '../views/pages/manage/sessionExpire'
import { ToastContainer } from "react-toastify";

const Auth = (prop) => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // document.body.classList.add("bg-default");
  }, [])
  return (
    <>
      <div className="main-content">
        <ToastContainer position="bottom-center" />

        {/* <AuthNavbar /> */}
        <Switch>
          <Route path="/auth" render={props => <Login {...props} />} />
          <Route path="/sessionExpire" render={props => <SessionInfo {...props} />} />
          <Redirect from="*" to="/auth" />
        </Switch>
      </div>
      {/* <AuthFooter /> */}
    </>
  );
}

export default Auth;
