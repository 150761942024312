import React from 'react';
// import { pdf } from "@react-pdf/renderer";
import moment from "moment";
import { uploadpost } from "services/utils";
import { endpoint } from "views/pages/manage/common/constant";
import { fetchAllPostPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { fetchAllPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import { successToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
// import { getBusinessAreaQR } from "../common";
// import DepositSlipWrapper from "../templates/depositSlipWrapper";
import { getOrdinal } from "../templates/draftSummary";
import { getPaidItemTypeList, regAndAdmissionComp } from '../concessionDetails/common';
import { CMPR_FLAG_VALUES } from '../concessionDetails/ApplyCmprDialog';

export const getConsessionDetails = async (concessionDetails, totalAmt, studentCurrentYear) => {
    const consessionResponse = concessionDetails;
    // here changing concession mode and making it object

    let totalConsession = {
        '29': { flAmt: 0, ylAmt: 0, ulAmt: 0, oAmt: 0 },
        '30': { flAmt: 0, ylAmt: 0, ulAmt: 0, oAmt: 0 },
        '01': { flAmt: 0, ylAmt: 0, ulAmt: 0, oAmt: 0 },
        '04': { flAmt: 0, ylAmt: 0, ulAmt: 0, oAmt: 0 },
        '05': { flAmt: 0, ylAmt: 0, ulAmt: 0, oAmt: 0 },
    }

    let totalPendingConsession = {
        '29': { flAmt: 0, ylAmt: 0, ulAmt: 0, oAmt: 0 },
        '30': { flAmt: 0, ylAmt: 0, ulAmt: 0, oAmt: 0 },
        '01': { flAmt: 0, ylAmt: 0, ulAmt: 0, oAmt: 0 },
        '04': { flAmt: 0, ylAmt: 0, ulAmt: 0, oAmt: 0 },
        '05': { flAmt: 0, ylAmt: 0, ulAmt: 0, oAmt: 0 },
    }

    let concessionAppliedList = {
        '29': [],   //[{id: , constrain: ''}],
        '30': [],
        '01': [],
        '04': [],
        '05': []
    }

    if (Array.isArray(consessionResponse)) {
        const isApprovalRequired = consessionResponse.find(item => item.concessionType === 'CUSTOM') || concessionDetails?.[0]?.extraFeeComponents !== '' ? true : false;
        const isRejected = ['L1_REJECTED', 'L2_REJECTED', 'L3_REJECTED'].includes(concessionDetails?.[0]?.concessionApprovalStatus);
        const isApproved = concessionDetails?.[0]?.extraFeeComponents !== '' ? concessionDetails?.[0]?.isApproved : consessionResponse.find(item => item.concessionType === 'CUSTOM')?.isApproved;

        consessionResponse.map(item => {
            const lumpsumYear = item?.concessionMethodType === 'LUMPSUM_First_Year' ? 1 : item?.concessionMethodType === 'LUMPSUM_Year_On_Year' ? studentCurrentYear ?? null : item?.concessionMethodType === 'LUMPSUM_Full_Course' ? 0 : null;
            const key = item?.concessionMethodType === 'LUMPSUM_First_Year' ? 'flAmt' : item?.concessionMethodType === 'LUMPSUM_Year_On_Year' ? 'ylAmt' : item?.concessionMethodType === 'LUMPSUM_Full_Course' ? 'ulAmt' : 'oAmt';
            if ((isApprovalRequired && isApproved) || !isApprovalRequired) {
                if (item.concessionParameter === 'PERCENTAGE') {
                    totalConsession = {
                        ...totalConsession,
                        '29': { ...totalConsession['29'], [key]: Number(totalConsession['29']?.[key]) + Number(item.regFeeConcession) },
                        '30': { ...totalConsession['30'], [key]: Number(totalConsession['30']?.[key]) + Number(item.adminFeeConcession)},
                        '01': { ...totalConsession['01'], [key]: Number(totalConsession['01']?.[key]) + Number(item.tutionFeeConcession)},
                        '04': { ...totalConsession['04'], [key]: Number(totalConsession['04']?.[key]) + Number(item.techFeeConcession)},
                        '05': { ...totalConsession['05'], [key]: Number(totalConsession['05']?.[key]) + Number(item.classFeeConcession)},
                    }
                }

                if (item.concessionParameter === 'AMOUNT') {
                    totalConsession = {
                        ...totalConsession,
                        '29': { ...totalConsession['29'], [key]: Number(totalConsession['29']?.[key]) + (Number(item.regFeeConcession) ? (Number(item.regFeeConcession) / (totalAmt?.['29'] ?? 0)) * 100 : 0) },
                        '30': { ...totalConsession['30'], [key]: Number(totalConsession['30']?.[key]) + (Number(item.adminFeeConcession) ? (Number(item.adminFeeConcession) / (totalAmt?.['30'] ?? 0)) * 100 : 0) },
                        '01': { ...totalConsession['01'], [key]: Number(totalConsession['01']?.[key]) + (Number(item.tutionFeeConcession) ? (Number(item.tutionFeeConcession) / (totalAmt?.['01'] ?? 0)) * 100 : 0) },
                        '04': { ...totalConsession['04'], [key]: Number(totalConsession['04']?.[key]) + (Number(item.techFeeConcession) ? (Number(item.techFeeConcession) / (totalAmt?.['04'] ?? 0)) * 100 : 0) },
                        '05': { ...totalConsession['05'], [key]: Number(totalConsession['05']?.[key]) + (Number(item.classFeeConcession) ? (Number(item.classFeeConcession) / (totalAmt?.['05'] ?? 0)) * 100 : 0) },
                    }
                }

                if (item.regFeeConcession) {
                    concessionAppliedList = { ...concessionAppliedList, '29': [...concessionAppliedList['29'], { id: item.id, constrain: lumpsumYear }] }
                }
                if (item.adminFeeConcession) {
                    concessionAppliedList = { ...concessionAppliedList, '30': [...concessionAppliedList['30'], { id: item.id, constrain: lumpsumYear }] }
                }
                if (item.tutionFeeConcession) {
                    concessionAppliedList = { ...concessionAppliedList, '01': [...concessionAppliedList['01'], { id: item.id, constrain: lumpsumYear }] }
                }
                if (item.techFeeConcession) {
                    concessionAppliedList = { ...concessionAppliedList, '04': [...concessionAppliedList['04'], { id: item.id, constrain: lumpsumYear }] }
                }
                if (item.classFeeConcession) {
                    concessionAppliedList = { ...concessionAppliedList, '05': [...concessionAppliedList['05'], { id: item.id, constrain: lumpsumYear }] }
                }
            }

            if (isApprovalRequired && !isRejected && !isApproved) {

                if ((item.concessionType === 'DEFINED')) {
                  
                    const extraFeeArr = item?.extraFeeComponents?.split(',')||[];

                    if (item.concessionParameter === 'PERCENTAGE') {
                        
                        totalPendingConsession = {
                            ...totalPendingConsession,
                            '30': { ...totalPendingConsession['29'], [key]: Number(totalPendingConsession['30']?.[key]) + (extraFeeArr.indexOf('ADMIN_FEE') > -1 ? Number(item.adminFeeConcession) : 0) },
                            '29': { ...totalPendingConsession['30'], [key]: Number(totalPendingConsession['29']?.[key]) + (extraFeeArr.indexOf('REG_FEE') > -1 ? Number(item.regFeeConcession) : 0) },
                            '01': { ...totalPendingConsession['01'], [key]: Number(totalPendingConsession['01']?.[key]) + (extraFeeArr.indexOf('TUITION_FEE') > -1 ? Number(item.tutionFeeConcession) : 0) },
                            '04': { ...totalPendingConsession['04'], [key]: Number(totalPendingConsession['04']?.[key]) + (extraFeeArr.indexOf('TECH_FEE') > -1 ? Number(item.techFeeConcession) : 0) },
                            '05': { ...totalPendingConsession['05'], [key]: Number(totalPendingConsession['05']?.[key]) + (extraFeeArr.indexOf('CLASS_FEE') > -1 ? Number(item.classFeeConcession) : 0) },
                        }

                        totalConsession = {
                            ...totalConsession,
                            '29': { ...totalConsession['29'], [key]: Number(totalConsession['29']?.[key]) + (extraFeeArr.indexOf('REG_FEE') === -1 ? Number(item.regFeeConcession) : 0) },
                            '30': { ...totalConsession['30'], [key]: Number(totalConsession['30']?.[key]) + (extraFeeArr.indexOf('ADMIN_FEE') === -1 ? Number(item.adminFeeConcession) : 0) },
                            '01': { ...totalConsession['01'], [key]: Number(totalConsession['01']?.[key]) + (extraFeeArr.indexOf('TUITION_FEE') === -1 ? Number(item.tutionFeeConcession) : 0) },
                            '04': { ...totalConsession['04'], [key]: Number(totalConsession['04']?.[key]) + (extraFeeArr.indexOf('TECH_FEE') === -1 ? Number(item.techFeeConcession) : 0) },
                            '05': { ...totalConsession['05'], [key]: Number(totalConsession['05']?.[key]) + (extraFeeArr.indexOf('CLASS_FEE') === -1 ? Number(item.classFeeConcession) : 0) },
                        }
                    }

                    if (item.concessionParameter === 'AMOUNT') {
                        totalPendingConsession = {
                            ...totalPendingConsession,
                            '29': { ...totalPendingConsession['29'], [key]: Number(totalPendingConsession['29']?.[key]) + (extraFeeArr.indexOf('REG_FEE') > -1 ? (Number(item.regFeeConcession) ? (Number(item.regFeeConcession) / (totalAmt?.['29'] ?? 0)) * 100 : 0) : 0) },
                            '30': { ...totalPendingConsession['30'], [key]: Number(totalPendingConsession['30']?.[key]) + (extraFeeArr.indexOf('ADMIN_FEE') > -1 ? (Number(item.adminFeeConcession) ? (Number(item.adminFeeConcession) / (totalAmt?.['30'] ?? 0)) * 100 : 0) : 0) },
                            '01': { ...totalPendingConsession['01'], [key]: Number(totalPendingConsession['01']?.[key]) + (extraFeeArr.indexOf('TUITION_FEE') > -1 ? (Number(item.tutionFeeConcession) ? (Number(item.tutionFeeConcession) / (totalAmt?.['01'] ?? 0)) * 100 : 0) : 0) },
                            '04': { ...totalPendingConsession['04'], [key]: Number(totalPendingConsession['04']?.[key]) + (extraFeeArr.indexOf('TECH_FEE') > -1 ? (Number(item.techFeeConcession) ? (Number(item.techFeeConcession) / (totalAmt?.['04'] ?? 0)) * 100 : 0) : 0) },
                            '05': { ...totalPendingConsession['05'], [key]: Number(totalPendingConsession['05']?.[key]) + (extraFeeArr.indexOf('CLASS_FEE') > -1 ? (Number(item.classFeeConcession) ? (Number(item.classFeeConcession) / (totalAmt?.['05'] ?? 0)) * 100 : 0) : 0) },
                        }

                        totalConsession = {
                            ...totalConsession,
                            '29': { ...totalConsession['29'], [key]: Number(totalConsession['29']?.[key]) + (extraFeeArr.indexOf('REG_FEE') === -1 ? (Number(item.regFeeConcession) ? (Number(item.regFeeConcession) / (totalAmt?.['29'] ?? 0)) * 100 : 0) : 0) },
                            '30': { ...totalConsession['30'], [key]: Number(totalConsession['30']?.[key]) + (extraFeeArr.indexOf('ADMIN_FEE') === -1 ? (Number(item.adminFeeConcession) ? (Number(item.adminFeeConcession) / (totalAmt?.['30'] ?? 0)) * 100 : 0) : 0) },
                            '01': { ...totalConsession['01'], [key]: Number(totalConsession['01']?.[key]) + (extraFeeArr.indexOf('TUITION_FEE') === -1 ? (Number(item.tutionFeeConcession) ? (Number(item.tutionFeeConcession) / (totalAmt?.['01'] ?? 0)) * 100 : 0) : 0) },
                            '04': { ...totalConsession['04'], [key]: Number(totalConsession['04']?.[key]) + (extraFeeArr.indexOf('TECH_FEE') === -1 ? (Number(item.techFeeConcession) ? (Number(item.techFeeConcession) / (totalAmt?.['04'] ?? 0)) * 100 : 0) : 0) },
                            '05': { ...totalConsession['05'], [key]: Number(totalConsession['05']?.[key]) + (extraFeeArr.indexOf('CLASS_FEE') === -1 ? (Number(item.classFeeConcession) ? (Number(item.classFeeConcession) / (totalAmt?.['05'] ?? 0)) * 100 : 0) : 0) },
                        }
                    }

                    if (item.regFeeConcession && extraFeeArr.indexOf('REG_FEE') === -1) {
                        concessionAppliedList = { ...concessionAppliedList, '29': [...concessionAppliedList['29'], { id: item.id, constrain: lumpsumYear }] }
                    }
                    if (item.adminFeeConcession && extraFeeArr.indexOf('ADMIN_FEE') === -1) {
                        concessionAppliedList = { ...concessionAppliedList, '30': [...concessionAppliedList['30'], { id: item.id, constrain: lumpsumYear }] }
                    }
                    if (item.tutionFeeConcession && extraFeeArr.indexOf('TUITION_FEE') === -1) {
                        concessionAppliedList = { ...concessionAppliedList, '01': [...concessionAppliedList['01'], { id: item.id, constrain: lumpsumYear }] }
                    }
                    if (item.techFeeConcession && extraFeeArr.indexOf('CLASS_FEE') === -1) {
                        concessionAppliedList = { ...concessionAppliedList, '04': [...concessionAppliedList['04'], { id: item.id, constrain: lumpsumYear }] }
                    }
                    if (item.classFeeConcession && extraFeeArr.indexOf('TECH_FEE') === -1) {
                        concessionAppliedList = { ...concessionAppliedList, '05': [...concessionAppliedList['05'], { id: item.id, constrain: lumpsumYear }] }
                    }
                }

                if (item.concessionType === 'CUSTOM') {
                    if (item.concessionParameter === 'PERCENTAGE') {
                        totalPendingConsession = {
                            ...totalPendingConsession,
                            '29': { ...totalPendingConsession['29'], [key]: Number(totalPendingConsession['29']?.[key]) + Number(item.regFeeConcession) },
                            '30': { ...totalPendingConsession['30'], [key]: Number(totalPendingConsession['30']?.[key]) + Number(item.adminFeeConcession) },
                            '01': { ...totalPendingConsession['01'], [key]: Number(totalPendingConsession['01']?.[key]) + Number(item.tutionFeeConcession) },
                            '04': { ...totalPendingConsession['04'], [key]: Number(totalPendingConsession['04']?.[key]) + Number(item.techFeeConcession) },
                            '05': { ...totalPendingConsession['05'], [key]: Number(totalPendingConsession['05']?.[key]) + Number(item.classFeeConcession) },
                        }
                    }

                    if (item.concessionParameter === 'AMOUNT') {
                        totalPendingConsession = {
                            ...totalPendingConsession,
                            '29': { ...totalPendingConsession['29'], [key]: Number(totalPendingConsession['29']?.[key]) + (Number(item.regFeeConcession) ? (Number(item.regFeeConcession) / (totalAmt?.['29'] ?? 0)) * 100 : 0) },
                            '30': { ...totalPendingConsession['30'], [key]: Number(totalPendingConsession['30']?.[key]) + (Number(item.adminFeeConcession) ? (Number(item.adminFeeConcession) / (totalAmt?.['30'] ?? 0)) * 100 : 0) },
                            '01': { ...totalPendingConsession['01'], [key]: Number(totalPendingConsession['01']?.[key]) + (Number(item.tutionFeeConcession) ? (Number(item.tutionFeeConcession) / (totalAmt?.['01'] ?? 0)) * 100 : 0) },
                            '04': { ...totalPendingConsession['04'], [key]: Number(totalPendingConsession['04']?.[key]) + (Number(item.techFeeConcession) ? (Number(item.techFeeConcession) / (totalAmt?.['04'] ?? 0)) * 100 : 0) },
                            '05': { ...totalPendingConsession['05'], [key]: Number(totalPendingConsession['05']?.[key]) + (Number(item.classFeeConcession) ? (Number(item.classFeeConcession) / (totalAmt?.['05'] ?? 0)) * 100 : 0) },
                        }
                    }
                }

            }
        })
        // alert(JSON.stringify({ ...totalConsession }))

        ////console.log("totalPendingConsession", totalPendingConsession)
        ////console.log("concessionAppliedList", concessionAppliedList)
        ////console.log("totalConsession", totalConsession)

        return {
            totalPendingConsession, totalConsession, concessionAppliedList
        }
    } else {
        return { totalPendingConsession, totalConsession, concessionAppliedList }
    }
}

export const getBreakUp = async (feeBreakupData, itemTypes) => {
    if (Array.isArray(feeBreakupData?.data)) {
        // setBreakupData({ fetching: true, data: [] })
        const uniqueElemets = [];
        let tot = {};

        feeBreakupData.data.forEach(element => {
            const elementType = element?.itemType?.slice(-2);

            tot = {
                ...tot,
                [elementType]: (tot?.[elementType] ?? 0) + element.baseAmount
            }

            if (uniqueElemets.indexOf(elementType) === -1) {
                uniqueElemets.push(elementType)
            }
        });
        // alert(tot)
        // setTotalAmt(tot);
        let itemTypeLabelMapping = {};

        uniqueElemets.map(item => {
            const des = itemTypes.data.find(i => i.itemTypeId?.slice(-2) == item)
            itemTypeLabelMapping = { ...itemTypeLabelMapping, [item]: des?.description?.split("-")?.[0] ?? '' }
        })

        const tempData = feeBreakupData?.data?.map(item => {
            const des = itemTypes.data.find(i => i.itemTypeId === item.itemType);
            return { ...item, desc: des.description, installmentNo: item.itemType?.slice(-4, -2) }
        })

        const groupedData = tempData?.reduce(function (r, a) {
            ////console.log(a)
            r[a.installmentNo] = r[a.installmentNo] || [];
            r[a.installmentNo].push(a);
            return r;
        }, Object.create(null));

        return { groupedData, itemTypeLabelMapping, total: tot }
        // setFetching(false)
    } else {
        return {}
    }
}


 export const parseFloatTillDigit = (amount , tillDigit = 2)=>{
     if(!amount) return 0
     amount= +amount ;
     return parseFloat(+amount.toFixed(tillDigit))
 }

 
 const filterPaidItemType = (allItemTypes = [], paidItemTypes= [])=>{
   return allItemTypes.filter(item => !paidItemTypes.includes(item['itemType']))
 }

export const createBreakUpData = (groupedBreakUp, consession, pendingForApproval = {}, concessionAppliedList, studentCurrentYear, concessionDetails) => {
    let valid = true;
    let tempData = {};
    let totalTempData = {};
    let percentPendingForApproval = 0;
    let totalGross = 0;
    let totalConcession = 0;
    const isApproved = concessionDetails?.[0]?.extraFeeComponents !== '' ? concessionDetails?.[0]?.isApproved : concessionDetails.find(item => item.concessionType === 'CUSTOM')?.isApproved;
    const isApprovalRequired = concessionDetails.find(item => item.concessionType === 'CUSTOM') || concessionDetails?.[0]?.extraFeeComponents !== '' ? true : false;
    const approvalRequired = isApprovalRequired && !isApproved ? true : false;
    //console.log("approvalRequired",approvalRequired)
    const lumpsumValues = ['LUMPSUM_First_Year', 'LUMPSUM_Year_On_Year', 'LUMPSUM_Full_Course']
    const isLumpsum = concessionDetails?.find(i => lumpsumValues.includes(i.concessionMethodType));
    const lumpsumYear = isLumpsum?.concessionMethodType === 'LUMPSUM_First_Year' ? 1 : isLumpsum?.concessionMethodType === 'LUMPSUM_Year_On_Year' ? studentCurrentYear : isLumpsum?.concessionMethodType === 'LUMPSUM_Full_Course' ? "*" : null;
    Object.keys(groupedBreakUp).map(year => {
        const procArray = []
        let conessionTotal = 0;
        let netPayableTotal = 0;
        let payableTotal = 0;
        let grossTotal = 0;
        let taxTotal = 0;
        let concApprovalPendingTotal = 0;
        let netPayablePostApprovalTotal = 0;
        let cgst = 0;
        let sgst = 0;
        let utgst = 0;
        let igst = 0;

        groupedBreakUp[year].map(item => {   
            const component = item.itemType?.slice(-2);
            const taxPercent = item['totalTaxPercentage'];
            const consessionAmt = ((consession?.[component]?.oAmt + (lumpsumYear === '*' ? consession[component]?.ulAmt : 0) + (item?.year === lumpsumYear && item?.year === 1 ? (consession[component]?.flAmt) : 0) + ( item?.year === lumpsumYear && item?.year !== 1 ? consession[component]?.ylAmt : 0)) * item['baseAmount']) / 100;
            const payableAmt = item['baseAmount'] - consessionAmt;
            const tax = (payableAmt * taxPercent) / 100;
            const netPayable = taxPercent ? payableAmt + tax : payableAmt;

            const concApprovalPending = ((pendingForApproval?.[component]?.oAmt + (lumpsumYear === '*' ? pendingForApproval[component]?.ulAmt : 0) + (item?.year === lumpsumYear && item?.year === 1 ? (pendingForApproval[component]?.flAmt) : 0) + ( item?.year === lumpsumYear && item?.year !== 1 ? pendingForApproval[component]?.ylAmt : 0)) * item['baseAmount']) / 100;
            const baseAmtPostApproval = payableAmt - concApprovalPending;
            const  taxPayablePostApproval = (baseAmtPostApproval * taxPercent) / 100;
            const netPayablePostApproval = concApprovalPending === 0 ? 0 : baseAmtPostApproval + taxPayablePostApproval ;

            const gstBreakup = {
                cgst: null,
                sgst: null,
                utgst: null,
                igst: null
            };
            item?.taxBreakupResDtos && item.taxBreakupResDtos.map(i => {
                return gstBreakup[i.taxCodeTypeDispVal.toLowerCase()] = parseFloat(((i.taxPercentage * payableAmt) / 100).toFixed(2))
            })

            const tempObj = {
                ...item,
                consession: parseFloat(consessionAmt.toFixed(4)),
                payableAmt: parseFloat(payableAmt.toFixed(4)),
                netPayable: parseFloat(netPayable.toFixed(4)),
                tax: parseFloat(tax.toFixed(2)),
                concApprovalPending: parseFloat(concApprovalPending.toFixed(4)),
                netPayablePostApproval: parseFloat(netPayablePostApproval.toFixed(4)),
                concessionIds: concessionAppliedList?.[component]?.map(i => i.id && (!i.constrain || i.constrain === item.year) ? i.id : null)?.filter(i => i !== null),
                concessionPer: consession[component]?.oAmt,
                lumpsumPer: (lumpsumYear === '*' ? consession[component]?.ulAmt : 0) + (item?.year === lumpsumYear && item?.year === 1 ? (consession[component]?.flAmt) : 0) + ( item?.year === lumpsumYear && item?.year !== 1 ? consession[component]?.ylAmt : 0),
                approvalRequired:approvalRequired,
                ...gstBreakup
            }
            conessionTotal = conessionTotal + parseFloatTillDigit(consessionAmt, 4);  ;
            netPayableTotal = netPayableTotal + parseFloatTillDigit(netPayable, 4);

            payableTotal = payableTotal + parseFloatTillDigit(payableAmt, 4); ;
            grossTotal = grossTotal + item.baseAmount;
            taxTotal = taxTotal + parseFloatTillDigit(tax, 4);
            concApprovalPendingTotal = concApprovalPendingTotal + concApprovalPending;
            netPayablePostApprovalTotal =  netPayablePostApprovalTotal + netPayablePostApproval;
            cgst = Number(cgst) + Number(gstBreakup?.cgst);
            sgst = Number(sgst) + Number(gstBreakup?.sgst);
            utgst = Number(utgst) + Number(gstBreakup?.utgst);
            igst= Number(igst) + Number(gstBreakup?.igst);
            procArray.push(tempObj);
        })

        tempData = { ...tempData, [year]: procArray }
        totalTempData = {
            ...totalTempData,
            [year]: {
                totalGross: parseFloat(grossTotal.toFixed(4)),
                conAmt: parseFloatTillDigit(conessionTotal,4),
                totalPayAmt: parseFloat(payableTotal.toFixed(4)),
                totalTax: parseFloat(taxTotal.toFixed(4)),
                netAmt: parseFloat(netPayableTotal.toFixed(4)),
                concApprovalPendingTotal: parseFloat(concApprovalPendingTotal.toFixed(2)),
                netPayablePostApprovalTotal: parseFloat(netPayablePostApprovalTotal.toFixed(2)),
                cgst: parseFloat(cgst.toFixed(4)),
                sgst: parseFloat(sgst.toFixed(4)),
                utgst: parseFloat(utgst.toFixed(4)),
                igst: parseFloat(igst.toFixed(4)),
                approvalRequired:approvalRequired
            }
        }
        totalGross = totalGross + grossTotal;
        totalConcession = concApprovalPendingTotal + totalConcession;
    })

     // console.log(tempData, totalTempData, percentPendingForApproval, groupedBreakUp, "debugging")
    percentPendingForApproval = totalConcession ? (((totalConcession) / (totalGross)) * 100)?.toFixed(2) : 0;
    return { tempData, totalTempData, percentPendingForApproval }
}

 
export const createBreakUpDataOnUI = (
     groupedBreakUp, 
     consession, 
     pendingForApproval = {}, 
     concessionAppliedList, 
     studentCurrentYear, 
     concessionDetails, 
     otherParameter) => {

    // if by pass cmpr then skip paid item types
     // paidItems this contain response of BE with object=> (txnId,netPay,paidAmount,itemType)
    const { paidItems, pastIdSelected, cmpFlag} = otherParameter;
    let paidItemList =  getPaidItemTypeList(pastIdSelected?[]:paidItems);

    if(cmpFlag === null) paidItemList = getPaidItemTypeList(pastIdSelected?[]:paidItems)
    if(cmpFlag === CMPR_FLAG_VALUES.CMPR) paidItemList = regAndAdmissionComp;
    // past id selected then show as it is
    if(pastIdSelected) paidItemList =[]
    let valid = true;
    let tempData = {};
    let totalTempData = {};
    let percentPendingForApproval = 0;
    let totalGross = 0;
    let totalConcession = 0;

    const isApproved = concessionDetails?.[0]?.extraFeeComponents !== '' 
        ? concessionDetails?.[0]?.isApproved 
        : concessionDetails.find(item => item.concessionType === 'CUSTOM')?.isApproved;

    const isApprovalRequired = concessionDetails.find(item => item.concessionType === 'CUSTOM') || concessionDetails?.[0]?.extraFeeComponents !== '' 
        ? true 
        : false;

    const approvalRequired = isApprovalRequired && !isApproved ? true : false;

    const lumpsumValues = ['LUMPSUM_First_Year', 'LUMPSUM_Year_On_Year', 'LUMPSUM_Full_Course'];
    const isLumpsum = concessionDetails?.find(i => lumpsumValues.includes(i.concessionMethodType));

    const lumpsumYear = isLumpsum?.concessionMethodType === 'LUMPSUM_First_Year' 
        ? 1 
        : isLumpsum?.concessionMethodType === 'LUMPSUM_Year_On_Year' 
        ? studentCurrentYear 
        : isLumpsum?.concessionMethodType === 'LUMPSUM_Full_Course' 
        ? "*" 
        : null;

    Object.keys(groupedBreakUp).map(year => {
        const procArray = [];
        let conessionTotal = 0;
        let netPayableTotal = 0;
        let payableTotal = 0;
        let grossTotal = 0;
        let taxTotal = 0;
        let concApprovalPendingTotal = 0;
        let netPayablePostApprovalTotal = 0;
        let cgst = 0;
        let sgst = 0;
        let utgst = 0;
        let igst = 0;
        const allItemTypes  =  filterPaidItemType(groupedBreakUp[year], paidItemList)
        allItemTypes.map(item => {
            const component = item.itemType?.slice(-2);
            const taxPercent = item['totalTaxPercentage'];
            const consessionAmt = (
                (consession?.[component]?.oAmt + 
                (lumpsumYear === '*' ? consession[component]?.ulAmt : 0) + 
                (item?.year === lumpsumYear && item?.year === 1 ? consession[component]?.flAmt : 0) + 
                (item?.year === lumpsumYear && item?.year !== 1 ? consession[component]?.ylAmt : 0)) * 
                item['baseAmount']
            ) / 100;

            const payableAmt = item['baseAmount'] - consessionAmt;
            const tax = (payableAmt * taxPercent) / 100;
            const netPayable = taxPercent ? payableAmt + tax : payableAmt;

            const concApprovalPending = (
                (pendingForApproval?.[component]?.oAmt + 
                (lumpsumYear === '*' ? pendingForApproval[component]?.ulAmt : 0) + 
                (item?.year === lumpsumYear && item?.year === 1 ? pendingForApproval[component]?.flAmt : 0) + 
                (item?.year === lumpsumYear && item?.year !== 1 ? pendingForApproval[component]?.ylAmt : 0)) * 
                item['baseAmount']
            ) / 100;

            const baseAmtPostApproval = payableAmt - concApprovalPending;
            const taxPayablePostApproval = (baseAmtPostApproval * taxPercent) / 100;
            const netPayablePostApproval = concApprovalPending === 0 ? 0 : baseAmtPostApproval + taxPayablePostApproval;

            const gstBreakup = {
                cgst: null,
                sgst: null,
                utgst: null,
                igst: null
            };

            item?.taxBreakupResDtos && item.taxBreakupResDtos.map(i => {
                gstBreakup[i.taxCodeTypeDispVal.toLowerCase()] = parseFloat(((i.taxPercentage * payableAmt) / 100).toFixed(2));
            });    
            const tempObj = {
                ...item,
                consession: parseFloat(consessionAmt.toFixed(2)),
                payableAmt: parseFloat(payableAmt.toFixed(2)),
                netPayable: parseFloat(netPayable.toFixed(0)),
                id: getItemTypeTxnId(paidItems,item, cmpFlag),
                   
                tax: parseFloat(tax.toFixed(2)),
                concApprovalPending: parseFloat(concApprovalPending.toFixed(4)),
                netPayablePostApproval: parseFloat(netPayablePostApproval.toFixed(2)),
                concessionIds: concessionAppliedList?.[component]?.map(i => i.id && (!i.constrain || i.constrain === item.year) ? i.id : null)?.filter(i => i !== null),
                concessionPer: consession[component]?.oAmt,
                lumpsumPer: (lumpsumYear === '*' ? consession[component]?.ulAmt : 0) + (item?.year === lumpsumYear && item?.year === 1 ? consession[component]?.flAmt : 0) + (item?.year === lumpsumYear && item?.year !== 1 ? consession[component]?.ylAmt : 0),
                approvalRequired: approvalRequired,
                ...gstBreakup
            };

            conessionTotal += (consessionAmt);
            netPayableTotal += (netPayable);
            payableTotal += payableAmt;
            grossTotal += item.baseAmount;
            taxTotal += tax;
            concApprovalPendingTotal += concApprovalPending;
            netPayablePostApprovalTotal += netPayablePostApproval;
            cgst += Number(gstBreakup?.cgst);
            sgst += Number(gstBreakup?.sgst);
            utgst += Number(gstBreakup.utgst);
            igst += Number(gstBreakup?.igst);
            procArray.push(tempObj);
        });

        tempData = { ...tempData, [year]: procArray };
        totalTempData = {
            ...totalTempData,
            [year]: {
                totalGross: parseFloat(grossTotal.toFixed(2)),
                conAmt: parseFloat(conessionTotal.toFixed(2)),
                totalPayAmt: parseFloat(payableTotal.toFixed(2)),
                totalTax: parseFloat(taxTotal.toFixed(2)),
                netAmt: parseFloat(netPayableTotal.toFixed(0)),
                concApprovalPendingTotal: parseFloat(concApprovalPendingTotal.toFixed(2)),
                netPayablePostApprovalTotal: parseFloat(netPayablePostApprovalTotal.toFixed(2)),
                cgst: parseFloat(cgst.toFixed(2)),
                sgst: parseFloat(sgst.toFixed(2)),
                utgst: parseFloat(utgst.toFixed(2)),
                igst: parseFloat(igst.toFixed(2)),
                approvalRequired: approvalRequired
            }
        };
        totalGross += grossTotal;
        totalConcession += concApprovalPendingTotal;
    });
    
    tempData =  getNonZerosItems(tempData)
    percentPendingForApproval = totalConcession ? (((totalConcession) / (totalGross)) * 100)?.toFixed(2) : 0;
    return { tempData, totalTempData, percentPendingForApproval };
};


const getNonZerosItems = (installmentMap)=>{
   const nonZeroItems = {}
   for(let key in installmentMap){
      if(installmentMap[key]?.length>0)
      nonZeroItems[key] = installmentMap[key]
   }
   return nonZeroItems
}

const getItemTypeTxnId = (paidList, item, cmprFlag)=>{
     if(cmprFlag !== CMPR_FLAG_VALUES.CMPR) return null;
    let currentItem = getItemTypeInPaidList(item ,paidList)
    return currentItem?currentItem?.transactionId :null

}

const  calculateNewFreeBreakUp = (inputObject)=>{
   // tempData => installmentMap 
   const {tempData, percentPendingForApproval, paidItems} = inputObject;
    const updatedMap = {}
    for(let installment in tempData){
        updatedMap[installment] = checkItemEffect(tempData[installment],paidItems)
    }
    console.log(updatedMap)
    return {tempData:updatedMap,totalTempData:calculateTotalByInstallment(updatedMap), percentPendingForApproval}

}

const checkItemEffect = (currentItemList = [], paidItemList =[] )=>{
    /* check each current item if no change in netPayable 
    then skip it else calculate netDifference on the basis of paid netPayable 
    */
   
   let effectedItems = [];
   currentItemList.forEach(currentItemType=>{
     const itemInPaidList = getItemTypeInPaidList(currentItemType, paidItemList);
      if(!itemInPaidList) effectedItems.push(currentItemType);
      if(itemInPaidList){
         const isNetPayableSame = currentItemType['netPayable'] === itemInPaidList['netPayable']
         // if same then then don't include it 
         if(!isNetPayableSame){
            currentItemType['id'] = itemInPaidList['transactionId'];
            effectedItems.push(currentItemType)
         }
      }
   })

  return effectedItems
}
 
const getItemTypeInPaidList =(item, paidItemList = [])=>{
     return  paidItemList.find(i => i.itemType === item.itemType);
}

const calculateTotalByInstallment =(installmentsMap)=>{
    const totalOfEachInstallmentsMap ={};
    for(let installment in  installmentsMap){
        totalOfEachInstallmentsMap[installment] = getSumOfEachItem(installmentsMap[installment])
    }
    return totalOfEachInstallmentsMap
}

const getSumOfEachItem = (itemList = []) => {
    return {
        totalGross: calculateTotalByKey(itemList, 'baseAmount'),
        conAmt: calculateTotalByKey(itemList, 'consession'),
        totalPayAmt: calculateTotalByKey(itemList, 'payableAmt'),
        totalTax: calculateTotalByKey(itemList, 'tax'),
        netAmt: calculateTotalByKey(itemList, 'netPayable'),
        concApprovalPendingTotal: calculateTotalByKey(itemList, 'concApprovalPending'),
        netPayablePostApprovalTotal: calculateTotalByKey(itemList, 'netPayablePostApproval'),
        cgst: calculateTotalByKey(itemList, 'cgst'),
        sgst: calculateTotalByKey(itemList, 'sgst'),
        utgst: calculateTotalByKey(itemList, 'utgst'),
        igst: calculateTotalByKey(itemList, 'igst'),
        approvalRequired: isApprovalRequired(itemList)
    };
};


const calculateTotalByKey = (items, key) =>{
        return items.reduce((total, item) => {
            return total + item[key];
        }, 0);
    
}

const isApprovalRequired = (itemList)=>{
     if(itemList?.length>0) return itemList.at(-1).approvalRequired ;
     return false
}

export const getFileUploadUrls = async (nameArr, depositeSlipData, dateAndItemMapping, qrCode, returnResult = true, applicationQuotationDetailsId, getRequest=false, showSuccesMsg=true, isDataSyncRequired=false) => {
     
    let updatedNameArr = [];

    for (let i = 0; i < depositeSlipData.length; i++) {
        const updatedName = `${depositeSlipData[i]?.name}_${depositeSlipData[i]?.psid}_(${new moment().format('DD-MM-YYYY')}).pdf`;
        updatedNameArr.push(updatedName.split(' ').join('_'));
    }

    const urlsList = await fetchAllPostPromisedData(`${endpoint.bulkUpload}`, { serviceName: 'DEPOSIT_SLIP_UPLOAD', 'files': updatedNameArr });
    ////console.log(urlsList)
    const uploadedKeyAndDateMapping = {};
    const refMapping = {};
    const depositSlipReferenceNumberMap= {}


    const getAllCalls = []
    const labelValueObject = [];
    for (let i = 0; i < depositeSlipData.length; i++) {
        const formData = {
            templateName: 'depositSlip',
            templateData: { ...depositeSlipData[i], qrCode: qrCode , name : `${depositeSlipData[i]?.name}_${depositeSlipData[i]?.psid}_(${new moment().format('DD-MM-YYYY')})`},
            uploadUrl: urlsList?.data?.[i]?.url
        }
        uploadedKeyAndDateMapping[depositeSlipData?.[i]?.name?.split(" ")?.join("_")] = urlsList?.data?.[i]?.key;
        refMapping[depositeSlipData?.[i]?.name?.split(" ")?.join("_")] = depositeSlipData?.[i]?.erpRef;
        depositSlipReferenceNumberMap[depositeSlipData?.[i]?.name?.split(" ")?.join("_")] = depositeSlipData?.[i]?.depositSlipReferenceNumber;
        labelValueObject.push({ label: depositeSlipData?.[i]?.name, value: urlsList?.data?.[i]?.key, feeBreakupId: depositeSlipData?.[i]?.feeBreakupId })
        // getAllCalls.push(fetchAllPostPromisedData('http://localhost:5000/htmlToPdf', formData));
        getAllCalls.push(fetchAllPostPromisedData(endpoint.createPdf, formData));
    }

    await Promise.all(getAllCalls)

    const sFee = depositeSlipData?.find(i => i.name === 'Security');
    const itemAndS3KeyDtoList = [].concat(...Object.keys(uploadedKeyAndDateMapping).map(i => {
        console.log(i)

        return dateAndItemMapping[i]?.map(j => { return { itemType: j, s3Key: uploadedKeyAndDateMapping[i], depositSlipReferenceNumber :depositSlipReferenceNumberMap?.[i] || refMapping?.[i] } }) ?? null
    }));

    const requestPayload = {
        quotationId: applicationQuotationDetailsId,
        itemAndS3KeyDtoList: itemAndS3KeyDtoList.filter(i => i !== null),
        chargesDepositSlipDto: sFee ? {
            "otherChargesId": sFee?.id,
            "depositSlipKey": uploadedKeyAndDateMapping['Security'],
            depositSlipReferenceNumber: sFee?.erpRef
        } : null,
        isDataSyncRequired : isDataSyncRequired
    };

    const res = await fetchAllPostPromisedData(`${endpoint.saveDepositSlip}`, requestPayload, 'PUT');
    if (res?.code === 200) {
        if(showSuccesMsg) successToast("The Deposit slip is successfully generated");
        if (returnResult) {
            return labelValueObject;
        }
        if(getRequest){
            return requestPayload;
        }
    } else {
        if (returnResult) {
            return [];
        }
    }
}

export const getDepositReferenceNumber = (item)=>{
    if(item[0]) return item[0]?.depositSlipReferenceNumber
    return ''
 }

export const getEligibleComponentList = async (qid, businessAreas, courses, applicationDetails, concessionDetails, studentCurrentYear,failureToastRequired=true) => {
    try {
        const components = await fetchAllPromisedData(`${endpoint.eligibleDepositSlips}/${qid}`, false, failureToastRequired);
            const feeBreak = {
                clientCode: '',
                erpRef: '',
                businessAreasCode: businessAreas?.[0]?.label,
                businessAreasName: businessAreas?.[0]?.desc,
                psid: applicationDetails?.psid ?? '',
                studentName: applicationDetails?.studentName ?? '',
                contactNumber: applicationDetails?.phoneNo ?? '',
                term: applicationDetails?.termValue,
                dueDate: '',
                regFee: 0,
                adminFee: 0,
                securityFee: 0,
                tutionFee: 0,
                techFee: 0,
                classFee: 0,
                name: '',
                course: courses?.[0]?.value,
                businessAreasId: businessAreas?.[0]?.value
            }

            const depositeData = []
             
            if (components?.code === 200 && components?.data) {
                let eligibilityComponent = components?.data?.eligibleDepositSlipResponseDTOList;
                const dateAndItemMapping = {};

                // security Fee
                if (components?.data?.securityAttributeDepositSlip?.netPayableAmount) {
                    let termInfo = {};
                    if (components?.data?.securityAttributeDepositSlip?.securityFeeTermId) {
                        termInfo = await fetchAllPromisedData(`${endpoint.term.getById}/${components?.data?.securityAttributeDepositSlip?.securityFeeTermId}`)
                    }
                    depositeData.push({ ...feeBreak, course: components?.data?.securityAttributeDepositSlip?.courseId, 
                        erpRef: components?.data?.securityAttributeDepositSlip?.id,
                         depositSlipReferenceNumber:components?.data?.securityAttributeDepositSlip?.depositSlipReferenceNumber,
                         term: termInfo?.termDispValue ?? applicationDetails?.termValue, clientCode: components?.data?.clientCode, securityFee: components?.data?.securityAttributeDepositSlip?.finalPayable, dispName: 'Security', name: 'Security', id: components?.data?.securityAttributeDepositSlip?.id, dueDate: moment(new Date()).format("DD-MMM-YYYY")?.toUpperCase() });
                }

                const lumpsumValues = ['LUMPSUM_First_Year', 'LUMPSUM_Year_On_Year', 'LUMPSUM_Full_Course']
                const isLumpsum = concessionDetails?.find(i => lumpsumValues.includes(i.concessionMethodType));
                const lumpsumYear = isLumpsum?.concessionMethodType === 'LUMPSUM_First_Year' ? 1 : isLumpsum?.concessionMethodType === 'LUMPSUM_Year_On_Year' ? studentCurrentYear : isLumpsum?.concessionMethodType === 'LUMPSUM_Full_Course' ? "*" : null;
                if (eligibilityComponent?.length) {

                    if (isLumpsum && lumpsumYear) {
                        if (lumpsumYear === "*") {
                            const ids = eligibilityComponent.map(comp => comp.itemType);
                            const techFeeAmt = parseFloat(eligibilityComponent.filter(comp => comp.itemType.slice(-2) === '04').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            const tuitionFeeAmt = parseFloat(eligibilityComponent.filter(comp => comp.itemType.slice(-2) === '01').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            const classFeeAmt = parseFloat(eligibilityComponent.filter(comp => comp.itemType.slice(-2) === '05').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            const adminFee = parseFloat(eligibilityComponent.filter(comp => comp.itemType.slice(-2) === '30').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            const regFee = parseFloat(eligibilityComponent.filter(comp => comp.itemType.slice(-2) === '29').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            depositeData.push({ ...feeBreak, erpRef: eligibilityComponent?.[0]?.id, depositSlipReferenceNumber: getDepositReferenceNumber(eligibilityComponent),
                                 clientCode: components?.data?.clientCode, regFee: regFee, adminFee: adminFee, tutionFee: tuitionFeeAmt, techFee: techFeeAmt, classFee: classFeeAmt, dispName: 'Lumpsum', name: 'Lumpsum', dueDate: 'On Admission', feeBreakupId: [eligibilityComponent?.[0]?.id] });
                            dateAndItemMapping['Lumpsum'] = ids;
                        } else {
                            if (lumpsumYear !== 1) {
                                // Reg fee component logic
                                const regFeeComponent = eligibilityComponent?.filter(comp => comp.itemType.slice(-2) === '29');
                                if (regFeeComponent?.length) {
                                    const regFeeAmt = regFeeComponent.reduce((acc, a) => acc + a.netPayable, 0);
                                    depositeData.push({ ...feeBreak,
                                          erpRef: regFeeComponent?.[0]?.id,
                                          depositSlipReferenceNumber:getDepositReferenceNumber(regFeeComponent),
                                         clientCode: components?.data?.clientCode, regFee: regFeeAmt,
                                          dispName: 'Reg Fee', name: 'Registration', dueDate: 'On Admission', feeBreakupId: [regFeeComponent?.[0]?.id] });
                                    dateAndItemMapping['Registration'] = [regFeeComponent?.[0]?.itemType];
                                }


                                // admin fee component logic
                                const adminFeeComponent = eligibilityComponent?.filter(comp => comp.itemType.slice(-2) === '30');
                                if (adminFeeComponent?.length) {
                                    const adminFeeAmt = adminFeeComponent.reduce((acc, a) => acc + a.netPayable, 0);
                                    depositeData.push({ ...feeBreak, erpRef: adminFeeComponent?.[0]?.id,depositSlipReferenceNumber: getDepositReferenceNumber(adminFeeComponent),
                                         clientCode: components?.data?.clientCode, adminFee: adminFeeAmt, dispName: 'Adm Kit', name: 'Admission', dueDate: 'On Admission', feeBreakupId: [adminFeeComponent?.[0]?.id] });
                                    dateAndItemMapping['Admission'] = [adminFeeComponent?.[0]?.itemType];
                                }
                            }
                            const year = eligibilityComponent.filter(comp => comp.year === lumpsumYear);
                            if (year?.length) {
                                const ids = year.map(comp => comp.itemType);
                                const techFeeAmt = parseFloat(year.filter(comp => comp.itemType.slice(-2) === '04').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                                const tuitionFeeAmt = parseFloat(year.filter(comp => comp.itemType.slice(-2) === '01').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                                const classFeeAmt = parseFloat(year.filter(comp => comp.itemType.slice(-2) === '05').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                                const adminFee = parseFloat(year.filter(comp => comp.itemType.slice(-2) === '30').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                                const regFee = parseFloat(year.filter(comp => comp.itemType.slice(-2) === '29').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                                depositeData.push({ ...feeBreak, erpRef: year?.[0]?.id, 
                                    depositSlipReferenceNumber:getDepositReferenceNumber(year),
            
                                     clientCode: components?.data?.clientCode, regFee: regFee, adminFee: adminFee, tutionFee: tuitionFeeAmt, techFee: techFeeAmt, classFee: classFeeAmt, dispName: 'Lumpsum', name: 'Lumpsum', feeBreakupId: [year?.[0]?.id], dueDate: year === 1 ? 'On Admission' : moment(year?.[0]?.dueDate).format("DD-MMM-YYYY")?.toUpperCase() });
                                dateAndItemMapping['Lumpsum'] = ids;
                            }

                            const others = eligibilityComponent.filter(comp => comp.year !== lumpsumYear);
                            const groupedData = others && others?.reduce((result, current) => {
                                const dueDate = current.itemType?.slice(-4, -2);
                                if (!result[dueDate]) {
                                    result[dueDate] = [];
                                }

                                result[dueDate].push({ ...current });

                                return result;
                            }, {});
                         
                            Object.keys(groupedData).forEach((installmentData, i) => {
                                const techFeeAmt = parseFloat(groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                                const tuitionFeeAmt = parseFloat(groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '01').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                                const classFeeAmt = parseFloat(groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '05').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                                if (techFeeAmt || tuitionFeeAmt || classFeeAmt) {
                                    const erpRef = Math.max(...groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(obj => obj.id));
                                    const depositSlipReferenceNumber = Math.max(...groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(obj => obj.depositSlipReferenceNumber));

                                    const ids = groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(i => i.itemType);
                                    const feeBreakupId = groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(i => i.id);
                                    depositeData.push({ ...feeBreak, erpRef: erpRef, depositSlipReferenceNumber,  clientCode: components?.data?.clientCode, dispName: `INST#${ids?.[0]?.slice(-4, -2)}`, name: `${getOrdinal(ids?.[0]?.slice(-4, -2))} Installment`, dueDate: ids?.[0]?.slice(-4, -2) == '01' ? 'On Admission'  :moment(groupedData[installmentData]?.[0]?.dueDate).format("DD-MMM-YYYY")?.toUpperCase(), tutionFee: tuitionFeeAmt, techFee: techFeeAmt, classFee: classFeeAmt, feeBreakupId: feeBreakupId });
                                    dateAndItemMapping[`${getOrdinal(ids?.[0]?.slice(-4, -2))}_Installment`] = ids;
                                }
                            })
                        }

                    } else {
                        // Reg fee component logic
                        const regFeeComponent = eligibilityComponent?.filter(comp => comp.itemType.slice(-2) === '29');
                        if (regFeeComponent?.length) {
                            const regFeeAmt = regFeeComponent.reduce((acc, a) => acc + a.netPayable, 0);
                            depositeData.push({ ...feeBreak, erpRef: regFeeComponent?.[0]?.id,
                                  depositSlipReferenceNumber:getDepositReferenceNumber(regFeeComponent),
                                 clientCode: components?.data?.clientCode, regFee: regFeeAmt, dispName: 'Reg Fee', name: 'Registration', dueDate: 'On Admission', feeBreakupId: [regFeeComponent?.[0]?.id] });
                            dateAndItemMapping['Registration'] = [regFeeComponent?.[0]?.itemType];
                        }


                        // admin fee component logic
                        const adminFeeComponent = eligibilityComponent?.filter(comp => comp.itemType.slice(-2) === '30');
                        if (adminFeeComponent?.length) {
                            const adminFeeAmt = adminFeeComponent.reduce((acc, a) => acc + a.netPayable, 0);
                            depositeData.push({ ...feeBreak, erpRef: adminFeeComponent?.[0]?.id,
                                  depositSlipReferenceNumber:getDepositReferenceNumber(adminFeeComponent),
                                 clientCode: components?.data?.clientCode, adminFee: adminFeeAmt, dispName: 'Adm Kit', name: 'Admission', dueDate: 'On Admission', feeBreakupId: [adminFeeComponent?.[0]?.id] });
                            dateAndItemMapping['Admission'] = [adminFeeComponent?.[0]?.itemType];
                        }

                        const groupedData = eligibilityComponent && eligibilityComponent?.reduce((result, current) => {
                            const dueDate = current.itemType?.slice(-4, -2);
                            if (!result[dueDate]) {
                                result[dueDate] = [];
                            }

                            result[dueDate].push({ ...current });

                            return result;
                        }, {});

                        Object.keys(groupedData).forEach((installmentData, i) => {
                            console.log("installmentData",groupedData[installmentData]?.[0]?.dueDate)
                            const techFeeAmt = parseFloat(groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            const tuitionFeeAmt = parseFloat(groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '01').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            const classFeeAmt = parseFloat(groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '05').reduce((acc, a) => acc + a.netPayable, 0)?.toFixed(2));
                            if (techFeeAmt || tuitionFeeAmt || classFeeAmt) {
                                const erpRef = Math.max(...groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(obj => obj.id));
                                const depositSlipReferenceNumber = Math.max(...groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(obj => obj.depositSlipReferenceNumber));

                                const ids = groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(i => i.itemType);
                                const feeBreakupId = groupedData[installmentData].filter(comp => comp.itemType.slice(-2) === '04' || comp.itemType.slice(-2) === '01' || comp.itemType.slice(-2) === '05').map(i => i.id);
                                depositeData.push({ ...feeBreak, erpRef: erpRef, depositSlipReferenceNumber, clientCode: components?.data?.clientCode, dispName: `INST#${ids?.[0]?.slice(-4, -2)}`, name: `${getOrdinal(ids?.[0]?.slice(-4, -2))} Installment`, dueDate: ids?.[0]?.slice(-4, -2) === '01' ? 'On Admission'  : moment(groupedData[installmentData]?.[0]?.dueDate).format("DD-MMM-YYYY")?.toUpperCase(), tutionFee: tuitionFeeAmt, techFee: techFeeAmt, classFee: classFeeAmt, feeBreakupId: feeBreakupId });
                                dateAndItemMapping[`${getOrdinal(ids?.[0]?.slice(-4, -2))}_Installment`] = ids;
                            }
                        })
                    }
                }


                    return { depositeData, dateAndItemMapping };


    } else {
        ////console.log('failed tp generate deposite slip no component eligible');
        return { depositeData:[], dateAndItemMapping:{} };
        // setLoading(false);
    }
} catch (e) {
    ////console.log(e)
    return { depositeData:[], dateAndItemMapping:{} };
    // setLoading(false);
}

}