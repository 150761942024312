import React from "react";

const RecordsCounter = ({ count = 0 }) => {
  if (count === 0) return "";

  return (
    <div className="heading-4 color-dark mt-3">{`${count} transaction${
      count === 1 ? "" : "s"
    } found`}</div>
  );
};

export default RecordsCounter;
