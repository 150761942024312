import React, { useState, useEffect } from 'react';
import PaymentScheduleTable from './paymentScheduleTable';
import Loader from 'react-loader-spinner';
import {  masterServiceBaseUrl, failureToast, getAPI } from 'services/http';
import { Card, Container, Row } from 'reactstrap';
import {GetPagination} from "../../common/commonComponents/pagination/pagination";

const PaymentSchedule = (props) => {
  
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [isNextDisable, setIsNextDisable] = useState(false);

  const [isShowViewInstallment, setIsShowViewInstallment] = useState(false);
  const [viewInstallmentId, setViewInstallmentId] = useState('');

  const [businessArea, setBusinessArea] = useState([]);
  const [businessAreaData, setBusinessAreaData] = useState([]);
  const [businessUnitTypeData, setBusinessUnitTypeData] = useState([]);

  const [groupCode, setGroupCode] = useState([]);
  const [companyCode, setCompanyCode] = useState([]);
  const [cashierOffice, setCashierOffice] = useState([]);
  const [cashierOfficeData, setCashierOfficeData] = useState([]);

  const [term, setTerm] = useState([]);
  const [termData, setTermData] = useState([]);
  const [regularCourse, setRegularCourse] = useState([]);
  const [securityDeposit, setSecurityDeposit] = useState([]);

  const [selectedRegularCourse, setSelectedRegularCourse] = useState();
  const [selectedSecurityDeposit, setSelectedSecurityDeposit] = useState();

  const [classArray, setclassArray] = useState([]);
  const [apiloader, setapiloader] = useState(false);
  const [state, setstate] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const pageSize = 10;
  const fetchClasses = async (url) => {
    getAPI(
        url,
        (data) => {
          data['data'].length > 0
              ? setIsNextDisable(true)
              : setIsNextDisable(false);
          setstate(data['data']);
          setTableData(data['data']);
          let n = pagination;
          if (data['data'].length > pageSize) {
            n['nextPage'] = n.currentPage + 1;
          }
          if (n.currentPage > 1) {
            n.previousPage = n.currentPage - 1;
          }
          n['totalPage'] = Math.floor(data['data'].length / pageSize);
          if (data['data'].length % pageSize != 0) {
            n['totalPage'] += 1;
          }
          setpagination(n);
          setclassArray(
              data['data'].slice(
                  pagination.currentPage * pageSize - pageSize,
                  pagination.currentPage * pageSize
              ) || []
          );
        },
        (data) => {
          failureToast(data['message']);
        }
    );
  };
  const previousPage = () => {
    if (pagination.currentPage != 1) {
      setclassArray(
          state.slice(
              (pagination.currentPage - 1) * pageSize - pageSize,
              (pagination.currentPage - 1) * pageSize
          )
      );
      let n = pagination;
      n['currentPage'] = n['currentPage'] - 1;
      if (n['currentPage'] == 1) {
        n['previousPage'] = null;
      } else {
        n['previousPage'] = n.currentPage;
      }
      setpagination(n);
    }
  };

  const fetchBusinessArea = async (url, displayValue, setState) => {
    setapiloader(true);
    getAPI(
      url,
      (data) => {
        const dataArray = data.data.map((data) => {
          const text = data[displayValue];
          const { id, status } = data;
          if (status === 'ACTIVE') {
            return {
              id: id,
              text: text,
            };
          }
        });
        setState(dataArray);
        setapiloader(false);
        setBusinessAreaData(data.data);
      },
      (data) => {
        failureToast(data['message']);
      }
    );
  };

  const fetchCashierOfficeMasterData = async (url, displayValue) => {
    getAPI(
      url,
      (data) => {
        const dataArray = data.data.map((data) => {
          const text = data[displayValue];
          const { id, status } = data;
          if (status === 'ACTIVE') {
            return {
              id: id,
              text: text,
            };
          }
        });
        setCashierOfficeData(dataArray);
      },
      (data) => {
        failureToast(data['message']);
      }
    );
  };

  const fetchCashierOfficeDataCreate  = async (url, displayValue, setState) => {
    getAPI(
      url,
      (data) => {
        const dataArray = data.data.map((data) => {
          const text = data[displayValue];
          const { id, status } = data;
            return {
              id: id,
              text: text,
            };
        });
        setState(dataArray);
      },
      (data) => {
        failureToast(data['message']);
      }
    );
  };

  const fetchData = async (url, displayValue, setState) => {
    getAPI(
      url,
      (data) => {
        const dataArray = data.data.map((data) => {
          const text = data[displayValue];
          const { id, status } = data;
          if (status === 'ACTIVE') {
            return {
              id: id,
              text: text,
            };
          }
        });
        setState(dataArray);
      },
      (data) => {
        failureToast(data['message']);
      }
    );
  };

  const fetchTerm = async (url, displayValue, setState) => {
    getAPI(
        url,
        (data) => {
          const dataArray = data.data.map((data) => {
            const text = data[displayValue];
            const { id, status } = data;
            return {
              id: text,
              text: text,
            };
          });
          setState(dataArray);
        },
        (data) => {
          failureToast(data['message']);
        }
    );
  };

  const fetchBusinessUnitType = async (url, setState) => {
    getAPI(
      url,
      (data) => {
        setState(data.data);
      },
      (data) => {
        failureToast(data['message']);
      }
    );
  };

  const fetchRegularCourse = async (url, displayValue, setState) => {
    getAPI(
        url,
        (data) => {
          const dataArray = data.data.map((data) => {
            const text = data[displayValue];
            const { courseId, status } = data;
            if (status === 'ACTIVE') {
              return {
                id: courseId,
                text: text,
              };
            }
          });
          setState(dataArray);
          setSecurityDeposit(dataArray);
        },
        (data) => {
          failureToast(data['message']);
        }
    );
  };

  const handleFormFilling = (value) => {
    let businessAreaDataObject = businessAreaData.filter(
        (item) => item.id === Number(value)
    );

    let regularCourseData = regularCourse.find(
        (item) =>
            item.companyCodeId === businessAreaDataObject[0] &&
            businessAreaDataObject[0].companyCodeId &&
            item.groupCodeId === businessAreaDataObject[0] &&
            businessAreaDataObject[0].groupCodeId
    );
    setSelectedRegularCourse(regularCourseData && regularCourseData.courseId);
    setSelectedSecurityDeposit(regularCourseData && regularCourseData.courseId);
  };


  const fetchTermData = async (url, setState) => {
    getAPI(
      url,
      (data) => {
        setState(data.data);
      },
      (data) => {
        failureToast(data['message']);
      }
    );
  };

  const effectData = () => {
    try {
      fetchClasses(masterServiceBaseUrl + '/payment/schedule/all');
      fetchBusinessArea(
          masterServiceBaseUrl + '/businessArea/getAllActiveBusinessArea',
          'businessAreaDispValue',
          setBusinessArea,
          setBusinessAreaData
      );
      fetchData(
          masterServiceBaseUrl + '/groupCode/getAllActiveGroupCode',
          'groupCodeDispValue',
          setGroupCode
      );
      fetchData(
          masterServiceBaseUrl + '/companyCode/getAllActiveCompanyCode',
          'companyCodeDispValue',
          setCompanyCode
      );
      fetchCashierOfficeMasterData(
          masterServiceBaseUrl + '/cashierOffice/getActiveCashierOffice',
          'cashierOfficeKey',
      );

      fetchTermData(masterServiceBaseUrl + '/term/getAllActiveTerm',setTermData);

      fetchBusinessUnitType(
          masterServiceBaseUrl + '/businessUnitType/getAllActiveBusinessUnitType',
          setBusinessUnitTypeData
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    effectData();
  }, []);

  const handleTermData = (groupCode, companyCode) => {
    if(groupCode && companyCode){
      fetchTerm(
        masterServiceBaseUrl +
        `/termMapping/getAllTermMappingByGroupCodeAndCompanyCode?groupCode=${groupCode}&companyCode=${companyCode}`,
        'termId',
        setTerm
      );
    fetchRegularCourse(
      masterServiceBaseUrl +
      `/courseCatalogCourseHeader/getCourseCatalogCourseHeaderByGroupCodeAndCompanyCode?groupCode=${groupCode}&companyCode=${companyCode}`,
      'courseId',
      setRegularCourse
    );
    }
  };

  const getCashierOffice = (id) => {
    fetchCashierOfficeDataCreate(
      masterServiceBaseUrl + '/cashierOffice/getCashierOfficeByBusinessAreaId/' + id ,'cashierOfficeKey',setCashierOffice);
  } 
  return (
    <>
      {apiloader ? (
        <div
          className="mx-auto text-center py-5 my-5"
          style={{ height: '100vh' }}
        >
          <Loader
            type="Rings"
            color="#00BFFF"
            height={70}
            width={70}
            timeout={10000}
          />
        </div>
      ) : (
        <div></div>
      )}
      <Container className="mt-3" fluid>
        <Row>
          {!isShowViewInstallment &&
          <div className="col">
            <Card className="p-0 mb-0" style={{ overflowX: 'hidden' }}>
              <PaymentScheduleTable
                previousPage={previousPage}
                fetchClasses={fetchClasses}
                setclassArray={setclassArray}
                classArray={classArray}
                tableData={tableData}
                pagination={pagination}
                setstate={setstate}
                businessArea={businessArea}
                setpagination={setpagination}
                setisSaveVisible={setisSaveVisible}
                isSaveVisible={isSaveVisible}
                cashierOfficeData={cashierOfficeData}
                cashierOffice={cashierOffice}
                groupCode={groupCode}
                companyCode={companyCode}
                setGroupCode={(value) => handleFormFilling(value)}
                businessUnitTypeData={businessUnitTypeData}
                businessAreaData={businessAreaData}
                getTermData={(groupCode, companyCode) =>
                  handleTermData(groupCode, companyCode)
                }
                getCashierOffice={(id) => 
                  getCashierOffice(id)
                }
                term={term}
                termData={termData}
                securityDeposit={securityDeposit}
                regularCourse={regularCourse}
                selectedRegularCourse={selectedRegularCourse}
                selectedSecurityDeposit={selectedSecurityDeposit}
                setIsShowViewInstallment={(id,value) => {setViewInstallmentId(id);setIsShowViewInstallment(value)}}
              />
              {pagination.totalPage > 1 ? (
                <GetPagination
                  isSaveVisible={isSaveVisible}
                  fetchClasses={fetchClasses}
                  setclassArray={setclassArray}
                  pagination={pagination}
                  setpagination={setpagination}
                  pageSize={pageSize}
                  state={state}
                />
              ) : null}
            </Card>
          </div>}
        </Row>
      </Container>
    </>
  );
};
export default PaymentSchedule;
