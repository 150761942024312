import React from "react";
import { useState, useEffect } from "react";
import Select from "react-select";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import {
  putpost,
  getAPI,
  masterServiceBaseUrl,
} from "services/http";

import { failureToast, successToast } from "../../../common/utils/methods/toasterFunctions/function";
import { PermisionDenied } from "../../../common/commonComponents";
import CustomButton from "components/CustomButton";
import "./index.scss";
// core components

const customStyles = {
  control: (base) => ({
    ...base,
    height: 40,
    minHeight: 40,
  }),
};

const LocationAddressHelper = (props) => {
  const {
    countryData = [],
    stateData = [],
    pincodeData = [],
    cityData = [],
    districtData = [],
    statusData = [],
    addressCategoryData = [],
    fetchData,
    savedAddress = [],
    getSavedAddress = () => { },
    bussinessUnitId,
    nextStep = () => { },
    appendAllStepData = () => { },
    updateAllStepData = () => { },
    isPrevious = () => { },
    isNew,
    previousStep = () => { },
    action,
    isDisabled,
    setIsDisabled,
    userPermissions
  } = props;

  const [addressKey, setAddressKey] = useState("");
  const [addressDesc, setAddressDesc] = useState("");
  const [addressDispValue, setAddressDispValue] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine3, setAddressLine3] = useState("");
  const [addressLine4, setAddressLine4] = useState("");
  const [building, setBuilding] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [pincode, setPincode] = useState("");
  const [stateName, setStateName] = useState("");
  const [country, setCountry] = useState("");
  const [status, setStatus] = useState("ACTIVE");
  const [addressCategory, setAddressCategory] = useState("");
  const [allowedProperty, setAllowedProperty] = useState([]);
  const [pinData, setPinData] = useState([])

  useEffect(() => {
    if (savedAddress) {
      setAddressKey(savedAddress["addressKey"]);
      setAddressDesc(savedAddress["addressDesc"]);
      setAddressDispValue(savedAddress["addressDispValue"]);
      setAddressLine1(savedAddress["addressLine1"]);
      setAddressLine2(savedAddress["addressLine2"]);
      setAddressLine3(savedAddress["addressLine3"]);
      setAddressLine4(savedAddress["addressLine4"]);
      setBuilding(savedAddress["building"]);
      setCity(getSelectedValue(savedAddress["cityId"], cityData)[0]);
      setDistrict(getSelectedValue(savedAddress["districtId"], districtData)[0]);
      setPincode(
        getSelectedValue(savedAddress["pincodeId"], pincodeData)[0]
      );
      setStatus(getSelectedValue(savedAddress["status"], statusData)[0]);
      setCountry(
        getSelectedValue(savedAddress["countryId"], countryData)[0]
      );
      setStateName(getSelectedValue(savedAddress["stateId"], stateData)[0]);
      // setAddressCategory(
      //   getSelectedValue(
      //     savedAddress["addressCategoryId"],
      //     addressCategoryData
      //   )[0]
      // );
      // fetchData(savedAddress["addressCategoryId"]);
      addressCategoryHandler(getSelectedValue(
        savedAddress["addressCategoryId"],
        addressCategoryData
      )[0])
    }

  }, [savedAddress, addressCategoryData, districtData, pincodeData, countryData, stateData, cityData]);

  React.useEffect(() => {
    setPinData(pincodeData)
  }, [pincodeData])

  const addressCategoryHandler = (sData) => {
    if (sData && sData.value) {
      getAPI(
        `${masterServiceBaseUrl}/address/getAddressProperty/${sData.value}`,
        (data) => {
          setAddressCategory(sData);
          setAllowedProperty(data.data);
        },
        (data) => {
          setAddressCategory("");
          setAllowedProperty([]);
          failureToast(data["message"]);
        }
      );
    }
  };

  const getSelectedValue = (id, listName) => {
    return listName.filter((item) => {
      return item.value === id;
    });
  };

  const getPincodeByCity = (sdata) => {
    // //////console.log(data)
    if (!sdata || sdata === '') {
      setCity("");
      setPinData(pincodeData)
      return;
    }
    getAPI(
      `${masterServiceBaseUrl}/pincode/getPincodeByCity/${Number(sdata.value)}`,
      (data) => {
        setCity(sdata)
        const list = data.data.map(
          data => {
            return {
              value: data.id,
              label: data['pincodeDispValue']
            };
          }
        );
        setPinData(list)
        setCountry('');
        setStateName('');
        setPincode('')
        setDistrict('')
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  };

  const fetchPincodeById = (data) => {
    if (data === null) {
      return;
    }
    getAPI(
      `${masterServiceBaseUrl}/pincode/getPincode/${Number(data.value)}`,
      (data) => {
        setPincode(getSelectedValue(data.data.id, pincodeData)[0]);
        setCity(getSelectedValue(data.data.cityId, cityData)[0]);
        setCountry(getSelectedValue(data.data.countryId, countryData)[0]);
        setStateName(getSelectedValue(data.data.stateId, stateData)[0]);
        setDistrict(getSelectedValue(data.data.districtId, districtData)[0]);
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  };

  // const editData = (url,formData,i) =>{
  //   putpost(url, (data) => {
  //     successToast(data['message'])
  //     addressList[i] = formData
  //     setAddressList([...addressList])
  //     setSaveingStatus(false)
  //     // //////console.log(formData)
  //    // toggleFormVisibility()
  //   }, (data) => { failureToast(data['message']); setSaveingStatus(false)}, formData, 'put')
  // }

  const isInProperty = (key) => {
    // //////console.log(key)
    const item =
      allowedProperty.length &&
      allowedProperty.filter((item) => {
        return item && item.property.propertyKey === key;
      });
    ////////console.log(item)
    const result = item.length ? true : false;
    return result;
  };

  const saveData = () => {
    //////console.log()
    if (addressKey == "") {
      failureToast("Please Enter Address Key Value");
      return;
    }

    // if (addressDesc == "") {
    //   failureToast("Please Enter Address Description Value");
    //   return;
    // }

    // if (addressDispValue == "") {
    //   failureToast("Please Enter Address Display Value ");
    //   return;
    // }

    if (addressLine1 == "" && isInProperty("ADDRESS_LINE1")) {
      failureToast("Please Enter Address Line1 Value");
      return;
    }

    if (addressLine2 == "" && isInProperty("ADDRESS_LINE2")) {
      failureToast("Please Enter Address Line2 Value");
      return;
    }

    if (addressLine3 == "" && isInProperty("ADDRESS_LINE3")) {
      failureToast("Please Enter Address Line3 Value");
      return;
    }

    if (addressLine4 == "" && isInProperty("ADDRESS_LINE4")) {
      failureToast("Please Enter Address Line4 Value");
      return;
    }

    // if (building == "") {
    //   failureToast("Please Enter Building Value");
    //   return;
    // }

    if (!city || city == "") {
      failureToast("Please Select City Value");
      return;
    }

    if (!district || district == "") {
      failureToast("Please Select District Value");
      return;
    }

    if (!pincode || pincode == "") {
      failureToast("Please Select Pincode Value");
      return;
    }


    if (!stateName || stateName == "") {
      failureToast("Please Select State Value");
      return;
    }


    if (!country || country == "") {
      failureToast("Please Select Country Value");
      return;
    }

    //////console.log('Value is here for the location',country)
    const formData = {
      addressKey: addressKey, //isInProperty('ADDRESS_KEY') ? addressKey :null,
      addressDesc: addressDesc, //isInProperty('ADDRESS_DESC') ? addressDesc :null,
      addressDispValue: addressDispValue, //isInProperty('ADDRESS_DISPLAY_VALUE') ? addressDispValue :null,
      addressReferenceType: "BUSINESS_UNIT",
      referenceId: action === 'edit' ? savedAddress['referenceId'] : bussinessUnitId.businessUnit,
      addressLine1: isInProperty("ADDRESS_LINE1") ? addressLine1 : null,
      addressLine2: isInProperty("ADDRESS_LINE2") ? addressLine2 : null,
      addressLine3: isInProperty("ADDRESS_LINE3") ? addressLine3 : null,
      addressLine4: isInProperty("ADDRESS_LINE4") ? addressLine4 : null,
      cityId: city && city.value, //isInProperty('DISTRICT') ? district :null,
      districtId: district && district.value,
      pincodeId: pincode && pincode.value, //isInProperty('PINCODE') ? pincode :null,
      building: building, //isInProperty('BUILDING') ? building :null,
      stateId: stateName && stateName.value,
      countryId: country && country.value, //isInProperty('COUNTRY') ? country :null,
      addressCategoryId: addressCategory.value,
      status: status.value, //isInProperty('STATUS') ? status :null
    };

    if (isNew && !isPrevious) {
      //console.log( 'Inside update' )

      putpost(
        `${masterServiceBaseUrl}/address/createAddress`,
        (data) => {
          formData['id'] = data.data['id']
          successToast(data["message"]);
          props.saveStepsCompleted()
          nextStep()
        },
        (data) => {
          failureToast(data["message"]);
        },
        formData,
        "post"
      );
    }

    if (!isNew || isPrevious) {
      //console.log('Inside update')
      putpost(
        `${masterServiceBaseUrl}/address/updateAddress/${savedAddress.id}`,
        (data) => {
          formData['id'] = savedAddress.id
          formData['referenceId'] = props.formId
          successToast(data["message"]);
          nextStep()
          if (action === 'edit') {
            setIsDisabled(true);
          }

        },
        (data) => {
          failureToast(data["message"]);
        },
        formData,
        "put"
      );
    }

  };

  return (
    userPermissions ?
      <>
        <Container className="mt-4" fluid>
          <Card className="mb-4">
            <CardHeader>
              <Row>
                <Col md="6">
                  <h3 className="mb-0">*Location Address</h3> <span></span>
                </Col>
                <Col>
                  <FormGroup>
                    <Select
                      value={addressCategory}
                      id="example3cols2Input"
                      placeholder={isDisabled ? '' : 'Select Address Category'}
                      options={addressCategoryData}
                      onChange={addressCategoryHandler}
                      style={customStyles}
                      isDisabled={isDisabled}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {addressCategory ? (
                <>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example2cols1Input"
                        >
                          *Address Key
                        </label>
                        <input
                          value={addressKey}
                          className="form-control addresslocationInput"
                          required={true}
                          placeholder={isDisabled ? '' : 'Address Key'}
                          maxLength="50"
                          type="text"
                          disabled={isDisabled}
                          onChange={(e) => setAddressKey(e.target.value)}
                          style={{ height: "38px" }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols2Input"
                        >
                          *Status
                        </label>
                        <Select
                          // value={addressCategory}
                          //className="form-control"
                          id="example3cols2Input"
                          value={status}
                          options={statusData}
                          onChange={(sData) => setStatus(sData)}
                          isDisabled={isDisabled}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-control-label "
                          htmlFor="example2cols1Input"
                        >
                          Address Description
                        </label>
                        <input
                          value={addressDesc}
                          className="form-control addresslocationInput"
                          required={true}
                          placeholder={isDisabled ? '' : 'Address Description'}
                          maxLength="50"
                          type="text"
                          disabled={isDisabled}
                          onChange={(e) => setAddressDesc(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example2cols1Input"
                        >
                          Address Display Value
                        </label>
                        <input
                          value={addressDispValue}
                          className="form-control addresslocationInput"
                          required={true}
                          placeholder={isDisabled ? '' : 'Address Display Value'}
                          type="text"
                          maxLength="50"
                          disabled={isDisabled}
                          onChange={(e) => setAddressDispValue(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {isInProperty("ADDRESS_LINE1") ? (
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example2cols1Input"
                          >
                            *Address Line 1
                          </label>
                          <input
                            value={addressLine1}
                            className="form-control addresslocationInput"
                            required={true}
                            placeholder={isDisabled ? '' : 'Address Line 1'}
                            type="text"
                            maxLength="50"
                            disabled={isDisabled}
                            onChange={(e) => setAddressLine1(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : null}
                  {isInProperty("ADDRESS_LINE2") ? (
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example2cols1Input"
                          >
                            *Address Line 2
                          </label>
                          <input
                            value={addressLine2}
                            className="form-control addresslocationInput"
                            required={true}
                            placeholder={isDisabled ? '' : 'Address Line 2'}
                            type="text"
                            disabled={isDisabled}
                            maxLength="50"
                            onChange={(e) => setAddressLine2(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : null}
                  {isInProperty("ADDRESS_LINE3") ? (
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example2cols1Input"
                          >
                            *Address Line 3
                          </label>
                          <input
                            value={addressLine3}
                            className="form-control addresslocationInput"
                            required={true}
                            placeholder={isDisabled ? '' : 'Address Line 3'}
                            type="text"
                            disabled={isDisabled}
                            maxLength="50"
                            onChange={(e) => setAddressLine3(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : null}
                  {isInProperty("ADDRESS_LINE4") ? (
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example2cols1Input"
                          >
                            *Address Line 4
                          </label>
                          <input
                            value={addressLine4}
                            className="form-control addresslocationInput"
                            required={true}
                            placeholder={isDisabled ? '' : 'Address Line 4'}
                            type="text"
                            maxLength="50"
                            disabled={isDisabled}
                            onChange={(e) => setAddressLine4(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : null}
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example2cols1Input"
                        >
                          Building
                        </label>
                        <input
                          value={building}
                          className="form-control addresslocationInput"
                          required={true}
                          placeholder={isDisabled ? '' : 'Building'}
                          title="Address Key"
                          type="text"
                          maxLength="50"
                          disabled={isDisabled}
                          onChange={(e) => setBuilding(e.target.value)}
                          // disabled={isDisabled}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols2Input"
                        >
                          *Pincode
                        </label>
                        <Select
                          value={pincode}
                          //className="form-control"
                          id="example3cols2Input"
                          options={pinData}
                          onChange={fetchPincodeById}
                          isDisabled={isDisabled}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols2Input"
                        >
                          *City
                        </label>
                        <Select
                          value={city}
                          //className="form-control"
                          id="example3cols2Input"
                          options={cityData}
                          isClearable={true}
                          onChange={getPincodeByCity}
                          isDisabled={isDisabled}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols2Input"
                        >
                          *District
                        </label>
                        <Select
                          value={district}
                          //className="form-control"
                          id="example3cols2Input"
                          options={districtData}
                          isDisabled={true}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols2Input"
                        >
                          *State
                        </label>
                        <Select
                          value={stateName}
                          //className="form-control"
                          id="example3cols2Input"
                          options={stateData}
                          isDisabled={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols2Input"
                        >
                          *Country
                        </label>
                        <Select
                          value={country}
                          //className="form-control"
                          id="example3cols2Input"
                          options={countryData}
                          isDisabled={true}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>

                    <Col md={6}>
                      {action !== 'view' && action !== 'edit' ?
                        // <Button
                        //     color="primary"
                        //     type="button"
                        //     onClick={() => {
                        //       previousStep();
                        //     }}
                        //   >
                        //       Previous
                        // </Button>
                        <CustomButton
                          className={'mx-1'}
                          content={"Previous"}
                          permissionType={'C'}
                          // icon={true}
                          onClick={() => { previousStep() }}
                          permissionSet={userPermissions}
                        />
                        : null}
                    </Col>
                    {!(action === 'view') ?
                      <Col md={6}>
                        {!isDisabled ?
                          // <Button
                          //   color="primary"
                          //   className={'floatRight ml-auto'}
                          //   type="button"
                          //   onClick={() => {
                          //     saveData();
                          //   }}
                          // >
                          //   {(action === 'view' || action === 'edit') ? 'Update' : 'Save & Next'}
                          // </Button>
                          <CustomButton
                            className={'mx-1'}
                            content={(action === 'view' || action === 'edit') ? 'Update' : 'Save & Next'}
                            permissionType={'U,R'}
                            // icon={true}
                            onClick={() => {
                              saveData();
                            }}
                            permissionSet={userPermissions}
                          />
                          :
                          // <Button color="primary"
                          //   className={'floatRight ml-auto'}
                          //   type="button"
                          //   onClick={() => setIsDisabled(false)}>Enable Form
                          //   </Button>
                          <CustomButton
                            className={'mx-1'}
                            content={"Enable Form"}
                            permissionType={'U'}
                            // icon={true}
                            onClick={() => setIsDisabled(false)}
                            permissionSet={userPermissions}
                          />
                        }
                      </Col> : null}
                    {/* <Col md="10"></Col>
                  <Col md="2">
                    <Button color="primary" className="mt-4" type="button" onClick={saveData}>
                      Save & Next
                    </Button>
                  </Col> */}
                  </Row>{" "}
                </>
              ) : (
                <p>Please Select Address Category from Above Dropdown.</p>
              )}
            </CardBody>
          </Card>
        </Container>
      </> : <PermisionDenied />
  );
};
export default LocationAddressHelper;