import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';
import { Document, Page } from 'react-pdf';

const LazyPDFViewer = ({ src }) => {

    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(null);
    const [scale, setScale] = useState(1);
    const ref = useRef(null);

    const onDocumentLoadSuccess = (params) => {
        setNumPages(params.numPages);
    }

    const onChangeScale = (zoomIn = true) => {
        if(zoomIn) {
            if(scale > 5) return;
            setScale(scale+0.5);
        } else {
            if(scale === 0.5) return;
            setScale(scale-0.5);
        }
    }

    return (
        <Box key={src} style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', overflow: 'scroll' }} ref={ref}>
            {ref?.current &&
                <Document file={src} onLoadSuccess={onDocumentLoadSuccess}>
                    {/* <Page pageNumber={pageNumber} height={ref.current.clientHeight} scale={scale} /> */}
                    {numPages && <>

                        {Array.apply(null, Array(numPages))
                        .map((x, i) => i + 1)
                        .map(page => <Page pageNumber={page} width={ref.current.clientWidth} scale={scale} />)}

                    </>}
                </Document>
            }
            <Box style={{ padding: '5px', background: 'rgba(0,0,0, 0.5)', width: '100%', position: 'sticky', bottom: 0, display: 'flex', justifyContent: 'center' }}>
                <Box style={{display: 'flex', gap: '10px', cursor: 'pointer'}}>
                    <AiOutlineZoomOut size={20} color={'#ffffff'} onClick={() => onChangeScale(false)}/>
                    <AiOutlineZoomIn size={20} color={'#ffffff'} onClick={() => onChangeScale(true)}/>
                </Box>
            </Box>
        </Box>
    );
};
export default LazyPDFViewer;