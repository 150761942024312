import {Button} from "reactstrap";
import React,{Fragment} from "react";
const UpdateButton =(props)=>{
    const {text , permissions={edit:true,view:true}} = props;
    return (
        <Fragment>
            {
                permissions.edit && permissions.view ?
                    <Button type="button" color="info" size="sm" {...props}>
                    {text}
                </Button>
                    :
                    null
            }
        </Fragment>
    )
}
export default UpdateButton;