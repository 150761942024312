import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import AAutoComplete from "../common/form-fields-mui/AAutoComplete";
import AButton from "../common/form-fields-mui/AButton";

export const modalStyle = {
  position: "absolute" /* Stay in place */,
  zIndex: "75" /* Sit on top */,
  top: "2rem",
  right: "5rem",
  width: "60%" /* Full width */,
  height: "100%" /* Full height */,
};

export const RightSideFilterForm = ({
  businessAreaData,
  roleData,
  courseCategoryData,
  filter,
  setFilter,
  getFilterCount,
  removeFilter,
  applyFilter,
  isShowRemoveFilter
}) => {
  const columnSize = 12;


  return (
    <div >
      <Card style={{ width: "280px", marginBottom: "0px" }}>
        <CardBody style={{ paddingTop: ".5rem", paddingBottom: ".5rem" }}>
          <h4 style={{ color: "black" }}> Filter </h4>
          <Row>
            <Col md={columnSize}>
              <AAutoComplete
                label='Business Area'
                currentValue={filter.businessAreas}
                items={businessAreaData.data}
                handleChange={(value) => setFilter({...filter, businessAreas : value })}
                isShowCount={true}
                // disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col md={columnSize}>
              <AAutoComplete
                label='Course category'
                currentValue={filter.courseCategories}
                items={courseCategoryData.data}
                handleChange={(value) => setFilter({...filter, courseCategories : value })}
                isShowCount={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={columnSize}>
            <AAutoComplete
                label='Roles'
                currentValue={filter.roles}
                items={roleData.data}
                handleChange={(value) => setFilter({...filter, roles : value })}
                isShowCount={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md="6" className="pl-0">
              {isShowRemoveFilter && (
                <AButton
                  onClick={removeFilter}
                  updatedStyle={{
                    border: "1px solid #CC5F5F",
                    borderRadius: "8px",
                    color: "#CC5F5F",
                    margin: ".4rem"
                  }}
                >
                  Remove Filter
                </AButton>
              )}
            </Col>
            <Col md="6" style={{ display: "flex", justifyContent: "flex-end" }}>
              <AButton
                onClick={getFilterCount() > 0 ? applyFilter : ()=>{}}
                updatedStyle={{
                  background: "#00B0F5",
                  border: "1px solid #00B0F5",
                  borderRadius: "8px",
                  color: "#fff",
                  margin: ".4rem"

                }}
              >
                Apply Filter
              </AButton>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
