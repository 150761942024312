import React, { useState } from "react";
import { withRouter } from "react-router";
import { Button, Card, CardBody, Input, Table } from "reactstrap";
import moment from "moment";
import {
  masterServiceBaseUrl,
  putpost,
  putpost2,
  successToast,
} from "services/http";
import { MasterHeaderAuto } from "../../../../../common/commonComponents/masterHeader/masterHeader";
import { getObjectById, isEqual } from "../../../../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint, commonMessages } from "../../../../../common/constant";
import { customValidation } from "views/pages/manage/common/utils/methods/validations/validation";
import FeeBreakupForm from "./feeBreakupForm";
import FeeBreakupRow from "./feeBreakupRow";
import validationRules from "./validationRule.json";
import { failureToast } from "../../../../../common/utils/methods/toasterFunctions/function";


// const Cardheader = ({
//   isSaveVisible = false,
//   newRow = () => {},
//   setIsSaveVisible = () => {},
// }) => {
//   return (
//     <>
//       <span />
//       <Button
//         disabled={isSaveVisible}
//         color="info"
//         size="sm"
//         type="button"
//         className={
//           "floatRight mx-1 ml-2" + (isSaveVisible ? " btn-dark" : null)
//         }
//         onClick={() => {
//           newRow();
//           setIsSaveVisible(true);
//         }}
//       >
//         Add New
//       </Button>
//     </>
//   );
// };

var _ = require("lodash");
const FeeBreakupTable = (props) => {
  const {
    action,
    fetchClasses,
    setclassArray,
    classArray,
    serverData,
    isSaveVisible,
    setIsSaveVisible = () => { },
    itemTypeData = [],
    activeItemTypeData = [],
    feeBreakupData,
    setFeeBreakupData = () => { },
    isValidForPreview,
    isExceptionalCourse
  } = props;


  // const headerList = isEqual(action,"new") ? [
  //   { name: "S.No." },
  //   { name: "Fee Component" , colSpan : "3"},
  //   { name: "Due Date" },
  //   { name: "Base Amount" },
  //   { name: "Tax" },
  //   { name: "Final Amount" },
  //   { name: "Year" }
  // ] : [
  //   { name: "S.No." },
  //   { name: "Fee Component" , colSpan : "3"},
  //   { name: "Due Date" },
  //   { name: "Base Amount" },
  //   { name: "Tax" },
  //   { name: "Final Amount" },
  //   { name: "Year" },
  //   { name: "Action" }
  // ]
  const commonHeader = [
    // { name: "S.No." },
    { name: "Fee Component", colSpan: "2" },
    { name: "Installment number" },
    { name: "Due Date" },
    { name: "Base Amount" },
    { name: "Tax" },
    { name: "Final Amount" },
    { name: "CASH" },
    { name: "CHKL" },
    { name: "Year" }
  ]

  const headerList = isExceptionalCourse ?
    [
      ...commonHeader, { name: 'Action' }
    ]
    : [{ name: "S.No." },...commonHeader] 

  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast(commonMessages.pleaseDeleteSaveCurrentChnage);
    } else {
      setIsSaveVisible(true);
      let tempData = classArray;
      tempData[index]["isEdit"] = true;
      setFeeBreakupData({
        itemType: getObjectById(tempData[index]["itemType"], itemTypeData),
        dueDate: tempData[index]["dueDate"],
        baseAmount: tempData[index]["baseAmount"],
        tax: tempData[index]["tax"],
        finalAmount: tempData[index]["finalAmount"],
        year: tempData[index]["year"],
      });
    }
  };

  const deleteRow = (index) => {
    if (isSaveVisible) {
      failureToast(commonMessages.pleaseDeleteSaveCurrentChnage);
    } else {
      let tempData = classArray;
      let id = tempData[index]["id"];

      // id is used when api ready

      if (
        window.confirm(
          "Are you sure you want to delete this Attendance Machine?"
        )
      ) {
        putpost2(
          `${endpoint.attendance_machine.delete}/${id}`,
          (data) => {
            successToast(data.message);
            props.fetchClasses();
            resetFields();
          },
          (data) => {
            failureToast(data.message);
          },
          "Delete"
        );
      }
    }
  };

  const saveRow = (index) => {
    let tempData = classArray;
    let isValid = true;

    isValid = customValidation(feeBreakupData, validationRules);

    if (!isValid) {
      return;
    } else {
      if (tempData[index]["isEdit"]) {
        let tempObject = {
          ...tempData[index],
          isEdit: false,
          itemType: feeBreakupData.itemType?.value,
          dueDate: feeBreakupData.dueDate,
          baseAmount: feeBreakupData.baseAmount,
          finalAmount: feeBreakupData.finalAmount,
          tax: feeBreakupData.tax,
          cash: feeBreakupData?.cash,
          cheque: feeBreakupData?.cheque,
          year: feeBreakupData.year,
        };
        tempData[index] = tempObject;
        setclassArray(tempData);
        setIsSaveVisible(false);
        resetFields()
      } else if (tempData[index]["isNew"]) {
        // putPostApi(`${concessionServiceBaseUrl}/addSubCategory`,tempObject,'post');
        // setIsSaveVisible(false);
        // resetFields()
        let tempObject = {
          isNew: false,
          itemType: feeBreakupData.itemType?.value,
          dueDate: feeBreakupData.dueDate,
          baseAmount: feeBreakupData.baseAmount,
          finalAmount: feeBreakupData.finalAmount,
          tax: feeBreakupData.tax,
          cash:feeBreakupData?.cash,
          cheque:feeBreakupData?.cheque,
          year: feeBreakupData.year,
        };
        tempData[index] = tempObject;
        setclassArray([...tempData]);
        setIsSaveVisible(false);
        resetFields()
        // putPostApi(`${concessionServiceBaseUrl}/addSubCategory`,tempObject,'post');
      }
    }
  };

  const cancelRow = (index) => {
    let tempData = classArray;
    if (tempData[index]["isEdit"]) tempData[index]["isEdit"] = false;
    else if (tempData[index]["isNew"]) tempData.splice(index, 1);
    setclassArray(tempData);
    setIsSaveVisible(false);
    resetFields();
  };

  const resetFields = () => {
    setFeeBreakupData({
      dueDate: "",
      baseAmount: "",
      tax: "",
      finalAmount: "",
      year: "",
    });
  };

  const getObjectById = (id, list) => {
    const i =
      id &&
      list?.filter((item) => {
        return item.itemTypeSetupId == id;
      });
    return i && i?.length ? i[0] : "";
  };

  const setDueDate = (currentIndex, date, currentInstallmentNumber,) => {
    let dateObj = {};
    classArray.forEach((item, i) => {
      if (item.installmentNumber === currentInstallmentNumber) {
        dateObj = { ...dateObj, [i]: date }
      }
    })

    setFeeBreakupData({
      ...feeBreakupData,
      dueDate:
        feeBreakupData.dueDate !== "" || Object.keys(dateObj).length !== 0
          ? { ...feeBreakupData.dueDate, ...dateObj }
          : { [currentIndex]: date },
    })
  }

  const removeRow = (index) => {
    let __tempData = [...classArray];
    const __tempObj = __tempData[index];
    __tempData[index] = {...__tempObj , isDeleted  :  true}
    setclassArray([...__tempData])
  }


  return (
    <div id="questionTable" className="table-responsive-layout no_custom_col_width p-3">
      <Table className="align-items-center table-flush table-layout">
        <MasterHeaderAuto headerList={headerList} />
        <tbody>
          {classArray.map((data, index) =>  {

          return <>

          {!data?.isDeleted ?
             <FeeBreakupForm
              index={index}
              key={"NewOrEditRow" + index}
              feeBreakupData={feeBreakupData}
              setFeeBreakupData={setFeeBreakupData}
              data={{ ...data, sno: index }}
              saveRow={saveRow}
              cancelRow={cancelRow}
              // getDisplayVal={getObjectById}
              isSaveVisible={isSaveVisible}
              setIsSaveVisible={setIsSaveVisible}
              isEditForm={data["__status"] !== "__new" ? true : false}
              setDueDate={setDueDate}
              removeRow={removeRow}
              {...props}
            />
            : null}
            </>
          }
          
           
            // ) : (
            //   <FeeBreakupRow
            //     key={"TDROW" + index}
            //     data={{...data,sno : index}}
            //     index={index}
            //     history={props.history}
            //     deleteRow={deleteRow}
            //     editRow={editRow}
            //     isSaveVisible={isSaveVisible}
            //     // getDisplayVal={getObjectById}
            //     {...props}
            //   />
            // )
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default withRouter(FeeBreakupTable);
