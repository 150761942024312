import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useState } from "react";
import { successToast } from "../../common/utils/methods/toasterFunctions/function";
import PlanLectures from "./lectureModule/PlanLectures";
import ALoader from "views/pages/manage/common/a-loader";
import ReviewAndPublish from "./editViewTimetable/ReviewAndPublish";
import TimetableTestList from "./academicTimetableListView/TimetableTestList";
import TimetableForm from "./TimetableForm";
import "./timetable.style.scss";

import {
  useFetchBatch,
  useFetchPlansByBatch,
  useFetchPlanSubjects,
  useFetchRooms,
  useFetchFacultyDropdown,
  useFetchDaysPlanDropdown,
  useFetchPlanTest,
  useFetchAutoPopulatedLectures,
} from "./hooks";

import {
  FORM_FIELD,
  getTimetableTestRequest,
  createTimetable,
  getLectureRequest,
  getSelectedFaculty,
  isTestCompleted,
} from "./helper";
import HeaderComponent from "./academicTimetableListView/HeaderComponent";

const TimeTable = () => {
  const param = useParams();
  const history = useHistory();
  //value will be for this TIMETABLE or ACADEMIC_TEST or ACADEMIC_LECTURE or REVIEW_PUBLISH
  const [openedForm, setOpenForm] = useState("TIMETABLE");
  const [form, setForm] = useState(FORM_FIELD);
  const [tests, setTests] = useState([]);
  const [planTests, setPlanTests] = useState([]);
  const [lectures, setLectures] = useState([]);
  const [loading, setLoading] = useState(false);

  // fetching dropdowns
  const batchDetails = useFetchBatch(param?.id, setLoading);
  const plansDropdown = useFetchPlansByBatch(batchDetails);
  const roomsDropdown = useFetchRooms(batchDetails?.businessArea?.id);
  const subjectFacultyDropdown = useFetchFacultyDropdown(
    batchDetails?.businessArea?.id
  );
  const daysPlanDropdown = useFetchDaysPlanDropdown(
    batchDetails?.businessArea?.id
  );

  const [planSubjects, subjectForm] = useFetchPlanSubjects(
    form?.plan?.packageId
  );

  //getting academic plan tests by end and start date change
  const [planData, setPlanDetails] = useFetchPlanTest(
    form,
    "NEW",
    param?.batchId,
    setTests
  );

  // autoPopulate the lectures
  useFetchAutoPopulatedLectures({
    form,
    businessAreaId: batchDetails?.businessArea?.id,
    batchId: param?.id,
    subjectForm,
    setLectures,
    isSyncWithMsTeam: batchDetails?.isEnabledMsTeamsSync,
  });
  // this is for timetable form
  const [currentStep, setCurrentStep] = useState(1);

  const formHandler = (value, formKey) => {
    setForm((currentData) => {
      return { ...currentData, [formKey]: value };
    });
  };

  // update test details handler
  const testUpdateHandler = (index, value, formKey) => {
    let tempTest = [...tests];
    tempTest[index][formKey] = value;
    tempTest[index]["isComplete"] = isTestCompleted(tempTest[index]);
    setTests(tempTest);
  };

  const publishTimetable = (tests, lectures) => {
    const request = {
      batchId: batchDetails?.id,
      academicPlanId: form?.plan?.value,
      startDate: form?.startFrom,
      endDate: form?.endOn,
      duration: form?.duration?.value,
      roomId: form?.room?.value,
      testTimeTable: getTimetableTestRequest(tests),
      lectureTimeTable: {
        lectureDetails: getLectureRequest(
          lectures,
          form?.room?.value,
          form?.duration?.value
        ),
        duration: form?.duration?.value,
        selectedFaculty: JSON.stringify(getSelectedFaculty(subjectForm, form)),
        daysPlanId: form?.daysPlan.value,
      },
    };
    // return ;
    createTimetable(request).then((res) => {
      if (res?.code === 200) {
        successToast(res?.message);
        history.push(`/admin/testScheduling/timetable/view/${param?.id}`);
      }
    });
  };

  return (
    <div
      className="remove-container-top-space"
      style={{ position: "relative" }}
    >
      {loading && <ALoader />}
      {openedForm !== "TIMETABLE" && (
        <HeaderComponent
          formData={form}
          subjectForm={subjectForm}
          plansDropdown={plansDropdown}
          formHandler={formHandler}
          batchDetails={batchDetails}
          openMode="NEW"
          tests={tests}
          setTests={setTests}
          subjectFacultyDropdown={subjectFacultyDropdown}
        />
      )}

      {openedForm === "TIMETABLE" && (
        <TimetableForm
          param={param}
          form={form}
          formHandler={formHandler}
          setOpenForm={setOpenForm}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          batchDetails={batchDetails}
          plansDropdown={plansDropdown}
          roomsDropdown={roomsDropdown}
          planSubjects={planSubjects}
          subjectFacultyDropdown={subjectFacultyDropdown}
          daysPlanDropdown={daysPlanDropdown}
          subjectForm={subjectForm}
        />
      )}

      {openedForm === "ACADEMIC_TEST" && (
        <TimetableTestList
          formData={form}
          tests={tests}
          setTests={setTests}
          formHandler={formHandler}
          setOpenForm={setOpenForm}
          plansDropdown={plansDropdown}
          roomsDropdown={roomsDropdown}
          publishTimetable={publishTimetable}
          batchDetails={batchDetails}
          setPlanTests={setPlanTests}
          testUpdateHandler={testUpdateHandler}
          openMode="NEW"
        />
      )}

      {openedForm === "ACADEMIC_LECTURE" && (
        <PlanLectures
          formData={form}
          formHandler={formHandler}
          setOpenForm={setOpenForm}
          plansDropdown={plansDropdown}
          roomsDropdown={roomsDropdown}
          planSubjects={planSubjects}
          publishTimetable={publishTimetable}
          batchDetails={batchDetails}
          businessAreaId={batchDetails?.businessArea?.id}
          subjectForm={subjectForm}
          batchId={param?.id}
          planTests={planTests}
          lectures={lectures}
          setLectures={setLectures}
          openMode="NEW"
          subjectFacultyDropdown={subjectFacultyDropdown}
          isEnabledMsTeamsSync={batchDetails?.isEnabledMsTeamsSync}
        />
      )}

      {openedForm === "REVIEW_PUBLISH" && (
        <ReviewAndPublish
          tests={tests}
          setTests={setTests}
          lectures={lectures}
          planData={form}
          setOpenForm={setOpenForm}
          publishTimetable={publishTimetable}
          publishStatusTimetableHandler={publishTimetable}
          batchDetails={batchDetails}
          batchId={param?.id}
          mode="NEW"
          onEditSave={() => {}}
          currentRoom={form?.room}
          isEnabledMsTeamsSync={batchDetails?.isEnabledMsTeamsSync}
          roomsDropdown={roomsDropdown}
          subjectFacultyDropdown={subjectFacultyDropdown}
          planSubjects={planSubjects}
          setLectures={setLectures}
        />
      )}
    </div>
  );
};

export default TimeTable;
