import React, { useState, useEffect } from "react";
import Select from "react-select";

const ReactSelectDropdown = ({ options, placeHolder, id, index, keyName, dropdownHandler, disabled }) => {
  // console.log("Options", options);
  const [value, setValue] = useState(null);

  const handler = () => {
    try {
      const obj = options.find((item) => item.value == id);
      // console.log("object value is here", obj, value);
      setValue(obj ? obj : "");
    } catch (e) {
      console.log("Error", e);
    }
  };

  useEffect(() => {
    // console.log("share id ", id);
    handler();
  }, [id]);

  return (
    <Select
      isDisabled={disabled}
      options={options}
      placeholder={placeHolder}
      value={value}
      onChange={(e) => {
        setValue(e);
        dropdownHandler(index, e.value, keyName);
        // console.log(index, keyName, e.value);
      }}
    />
  );
};

export default ReactSelectDropdown;
