import React, { useRef } from 'react';
import StockDetailsQuickActions from './stockDetails/StockDetailsQuickActions';
import BatchDetailsQuickActions from './batchDetails/BatchDetailsQuickActions';
import StudentInformationQuickActions from './studentInformation/StudentInformationQuickActions';

const QuickActions = ({
  activeTab,
  studentDetails,
  fetchStudentDetails = () => {},
  fetchApplications = () => {},
  setApiLoader = () => {},
  activeApplicationDetails,
}) => {
  // add other tab's actions component and map them here
  const actionsMap = {
    // studentInformation: (
    //   <StudentInformationQuickActions activeApplicationDetails={activeApplicationDetails} fetchApplications={fetchApplications} />
    // ),
    batchDetails: (
      <BatchDetailsQuickActions
        studentDetails={studentDetails}
        fetchStudentDetails={fetchStudentDetails}
        fetchApplications={fetchApplications}
        setApiLoader={setApiLoader}
        activeApplicationDetails={activeApplicationDetails}
      />
    ),
    stockDetails: (
      <StockDetailsQuickActions
        activeApplicationDetails={activeApplicationDetails}
      />
    ),
  };

  return (
    <div>
      {actionsMap[activeTab] && (
        <h3 className='mb-3' style={{ fontSize: '20px' }}>
          Quick action
        </h3>
      )}
      <div>
        {actionsMap[activeTab] || null}
      </div>
    </div>
  );
};

export default QuickActions;
