import { Popover } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import APopover from "../../common/pop-hover/APophover";
import {
  businessAreaDropdowns,
  getBusinessAreas,
  getDisplayValue,
} from "../constant/batch-search";
import BatchDetailForm from "./BatchDetailsForm";
import DisplayRegion from "./DisplayRegion";

const BatchInfo = ({ filterForm, inputHandler, searchHandler }) => {
  const batch = useSelector((state) => state.batch);
  const businessAreaDropdown = batch?.dropdowns?.businessAreas||[]
  const regions = batch?.dropdowns?.regionData||[]

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

 ;
  

  
  const getLabel = (count)=> count>1?"Batches":"Batch"
  return (
    <div style={{ position: "relative", minWidth: "4rem" }}>
      <h4 style={{color:"black"}}> {batch && batch.rowCount} {getLabel(batch && batch.rowCount)}</h4>
      <div>
        <DisplayRegion
          items={(
            filterForm.region
          )}
          type="Regions"
        />
        <span>
          |{" "}
          <DisplayRegion
            items={(filterForm.businessArea )}
            type="Business Areas"
          />
        </span>
        <span
          onClick={handleClick}
          aria-describedby={"demo"}
          style={{
          color: " #00B0F5",
          cursor:"pointer",
         
        }}
        >
          View/Edit
        </span>
      </div>
      <APopover handleClose={handleClose} id="info-batch" anchorEl={anchorEl}>
        <BatchDetailForm
          dropdown={batch.dropdowns}
          filterForm={filterForm}
          inputHandler={inputHandler}
          searchHandler={() => {
            searchHandler();
            handleClose();
          }}
        />{" "}
      </APopover>
    </div>
  );
};

export default BatchInfo;
