import React from 'react';
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import Select2 from "react-select2-wrapper";
import {RedAstric} from "../../../../examService/examIdGeneration/examIdSearchForm";
import CommonInput from "../../../../common/formFeilds/input/commonInput";
import {enrollmentServiceBaseUrl, getAPI} from "../../../../../../../services/http";
import ButtonWithLoader from "../../../../common/commonComponents/buttonWithLoader";
import ResetButton from "../../../../../../../components/FormComponent/Button/Reset";
import {ButtonText} from "variables/Buttons";
import CustomButton from "components/CustomButton";

const ApplicationIdSearchForm = (props) => {
    const {searchHandler,resetHandler,searchData,UpdateSearchData,businessArea,studentIds,studentIdsFromApplicationTable,applicationIds,
        opportunityIds,setData,isLoading,ButtonText,label,placeholder,permissionSet
    } = props;
    const status = [{id:'ACTIVE',text:'ACTIVE'},{id:'INACTIVE',text:'INACTIVE'}]
    const filterOption = (data) => {
        return data?.map((data) => {
            return Object.assign(
                {},
                {
                    label: data?.studentId,
                    value: data?.studentId,
                    studentName: `${data?.studentFirstName} ${data?.studentLastName}`,
                }
            );
        });
    };
    const promiseOptions = (inputValue) => {
        return new Promise((resolve) => {
            if (inputValue && inputValue.length < 10) {
                return resolve([]);
            }
            getAPI(
                `${enrollmentServiceBaseUrl}/studentDetails/getAllStudentBySmartSearch/${inputValue}`,
                (data) => {
                    resolve(filterOption(data?.data));
                },
                (data) => {
                    // failureToast(data["message"]);
                }
            );
        });
    };

    return (
        <Form>
            <Row>
                <Col md={6}>
                        <CommonInput label={label.studentId} placeHolder={placeholder.studentId} defaultValue={searchData.studentId}
                                     type={'smartSearchSelect'}
                                     onChange={(data)=>{UpdateSearchData('studentId', data);}}
                                     promiseHandler={promiseOptions}
                        />
                </Col>
                <Col md={6}>
                    <CommonInput label={label.studentName} placeHolder={placeholder.studentName} defaultValue={searchData['studentName']}
                                 type={'text'}
                                 isDisabled={true}
                    />
                </Col>
                <Col md={6}>
                        <CommonInput label={label.opportunityId} placeHolder={placeholder.opportunityId} defaultValue={searchData['sfOpportunityId']}
                                     type={'reactSelect'}
                                     data={opportunityIds}
                                     onChange={(value)=>{UpdateSearchData('sfOpportunityId', value)}}
                        />
                </Col>
                <Col md={6}>
                        <CommonInput label={label.applicationId} placeHolder={placeholder.applicationId} defaultValue={searchData['applicationId']}
                                     type={'reactSelect'}
                                     data={applicationIds}
                                     onChange={(value)=>{UpdateSearchData('applicationId', value)}}
                        />
                </Col>
                <Col md={6}>
                        <CommonInput label={label.businessArea} placeHolder={placeholder.businessArea} defaultValue={searchData['businessArea']}
                                     type={'reactSelect'}
                                     data={businessArea}
                                     onChange={(value)=>{UpdateSearchData('businessArea', value)}}
                        />
                </Col>
                <Col md={6}>
                    <CommonInput label={label.admissionStatus} placeHolder={placeholder.admissionStatus} defaultValue={searchData['status']}
                                 type={'reactSelect'}
                                 data={status}
                                 onChange={(value)=>{UpdateSearchData('status', value)}}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    {/* <ResetButton
                        className={"floatRight mx-1" +((!selectedAcademicCareer && !selectedCompanyCode && !selectedGroupCode) ? ' btn-dark' : null)}
                          text={ButtonText.ApplicationIdGeneration.reset}  className={"floatRight mx-1 reset-button"} onClick={ ()=>resetHandler()} />
                       Reset
                    </ResetButton> */}
                    <CustomButton 
                        className={ "floatRight mx-1 reset-button" }
                        content={ButtonText.ApplicationIdGeneration.reset}
                        type={'reset'}
                        permissionType={'S'}
                        permissionSet={permissionSet}
                        onClick={ ()=>resetHandler()}
                    />
                    {/* <ButtonWithLoader loading={isLoading} color="info" size="md" className="floatRight mx-1" onClick={ ()=>searchHandler()}>
                        <i className="fas fa-search" /> {'  '}&nbsp;{ButtonText.ApplicationIdGeneration.search}
                    </ButtonWithLoader> */}
                    <CustomButton 
                        className={ "floatRight mx-1" }
                        content={ButtonText.ApplicationIdGeneration.search}
                        icon={true}
                        permissionType={'S'}
                        permissionSet={permissionSet}
                        onClick={ ()=>searchHandler()}
                    />
                </Col>
            </Row>
        </Form>
    )
}

export default ApplicationIdSearchForm
