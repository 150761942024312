import React from 'react'
import { useHistory } from 'react-router'
import AButton from '../../common/form-fields-mui/AButton'
import styles from './viewAcademicPlan.module.css'
import APopover from '../../common/a-popover/index'
import ViewBranches from '../common/ViewBranches'
import DisplayDetailCard from '../common/DisplayDetailCard'
import EditBranchesForm from './EditBranchesForm'
import { checkPermission, fetchAllPostPromisedData, updateRequest, updateRequest2 } from '../../common/utils/methods/commonMethods/utilityMethod'
import { endpoint } from '../../common/constant'
import { successToast } from '../../common/utils/methods/toasterFunctions/function'
import ViewBranchesCommonComponent from '../../common/branchesCommonComponent/ViewBranchesCommonComponent'
import EditBranchesCommonComponent from '../../common/branchesCommonComponent/EditBranchesCommonComponent'

const textStyles = { fontSize: '14px', fontWeight: '400', marginBottom: '4px' }
const editBranchesButtonStyles = { fontSize: '12px', fontWeight: 400, borderWidth: '1px', height: '20px', padding: '2px 8px', borderRadius: '4px' }

const fieldsData = [
  { title: 'Academic Year', field: 'academicYear' },
  { title: 'Academic Career', field: 'academicCareer' },
  { title: 'Stream', field: 'academicGroup' },
  { title: 'Class', field: 'classes' },
  { title: 'Board', field: 'board' },
  { title: 'Academic Phase', field: 'academicPhase' },
  { title: 'Class Type', field: 'classType' },
]

const HeaderComponent = ({ planData, fetchPlanData, setApiLoader, showViewAllPlanButton = true, userPermissions = [] }) => {

  // const [regionBusinessAreaForm, setRegionBusinessAreaForm] = useState({
  //   region: [],
  //   businessArea: [],
  // })
  // const inputHandler = (value, key) => {
  //   setRegionBusinessAreaForm(prev => ({ ...prev, [key]: value }))
  // }

  const history = useHistory();

  const buttonClickHandler = (e) => {
    if (e?.ctrlKey) {
      window.open(history.createHref({ pathname: `/admin/testScheduling/searchAcademicPlan` }), '_blank')
    } else {
      history.push(`/admin/testScheduling/searchAcademicPlan`)
    }
  }

  // useEffect(() => {
  //   inputHandler(planData?.regions?.map(item => ({label: item?.dispValue, value: item?.id})), 'region');
  //   inputHandler(planData?.businessAreas?.map(item => ({label: item?.dispValue, value: item?.id})), 'businessArea');
  // }, [planData])

  const updateHandler = async (regionBusinessAreaForm) => {
    setApiLoader(true);
    const request = updateRequest2({
      regions: regionBusinessAreaForm?.region,
      businessAreas: regionBusinessAreaForm?.businessArea
    }, ['regions', 'businessAreas'])
    const res = await fetchAllPostPromisedData(`${endpoint?.viewAcademicPlan?.updatePlan}/${planData?.id}`, request, 'put')
    if (res?.code === 200) {
      successToast(res?.message);
      fetchPlanData(planData?.id);
    }
    setApiLoader(false);
  }

  const regionDisplay = () => {
    return (
      <>
        <span style={{ margin: '0 5px' }}>|</span>
        {(planData?.regions?.slice(0, 2).map(o => o.dispValue))?.join(", ")}
      </>
    )
  }

  const baDisplay = () => {
    return (
      <>
        <span style={{ margin: '0 5px' }}>|</span>
        {(planData?.businessAreas?.slice(0, 2).map(o => o.dispValue))?.join(", ")}
        {planData?.businessAreas?.length > 2 ? (
          // <APopover
          //   buttonComponent={(onClick, styles) => <span onClick={onClick} style={{ margin: '0 5px', cursor: 'pointer', color: '#00B0F5' }}>{planData?.businessAreas?.length > 2 && (`+${planData?.businessAreas?.length - 2} more`)}</span>}
          //   menuComponent={() => <ViewBranches regions={planData?.regions} businessAreas={planData?.businessAreas} />}
          //   maxHeight={'450px'}
          //   width='390px'
          // />
          <APopover 
            buttonComponent={(onClick, styles) => <span onClick={onClick} style={{ margin: '0 5px', cursor: 'pointer', color: '#00B0F5' }}>{planData?.businessAreas?.length > 2 && (`+${planData?.businessAreas?.length - 2} more`)}</span>}
            menuComponent={(closeForm) => <ViewBranchesCommonComponent regions={planData?.regions} businessAreas={planData?.businessAreas} closeForm={closeForm} />}
          />
        ) : (
          ''
        )}
        {/* <span style={{margin: '0 5px', cursor: 'pointer'}}>{'edit branches'}</span> */}
      </>
    )
  }

  return (
    <div className={styles.header_container}>
      <div className={styles.title_button_wrapper}>
        <div>
          <div style={textStyles}>All Academic Plans / Academic Plan</div>
          <div style={textStyles}>
            <span>{planData?.academicPlanName}</span>
            <span>{regionDisplay()}</span>
            <span>{baDisplay()}</span>
            {checkPermission(userPermissions, 'U') && <APopover
              buttonComponent={(onClick, styles) => <AButton variant='outline' className='ml-2' updatedStyle={editBranchesButtonStyles} onClick={onClick}>Edit branches</AButton>}
              menuComponent={closeForm => (
                // <EditBranchesForm
                //   closeForm={closeForm}
                //   planData={planData}
                //   // regionBusinessAreaForm={regionBusinessAreaForm}
                //   // setRegionBusinessAreaForm={setRegionBusinessAreaForm}
                //   // inputHandler={inputHandler}
                //   updateHandler={updateHandler}
                // />
                <EditBranchesCommonComponent 
                  closeForm={closeForm}
                  regions={planData?.regions?.map(item => ({ label: item?.dispValue, value: item?.id }))}
                  businessAreas={planData?.businessAreas?.map(item => ({ label: item?.dispValue, value: item?.id }))}
                  updateHandler={updateHandler}
                />
              )}
            />}
          </div>
        </div>
        {showViewAllPlanButton && (
          <div>
            <AButton
              variant='outline'
              onClick={buttonClickHandler}
            >
              View All Academic Plans
            </AButton>
          </div>
        )}
      </div>
      <div className={`mt-4 ${styles.display_details_container}`}>
        {fieldsData.map(item => <DisplayDetailCard key={item?.field} title={item?.title} data={planData[item?.field]?.dispValue || '-'} />)}
      </div>
    </div>
  )
}

export default HeaderComponent