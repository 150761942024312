import React from 'react'
import { labelStyle } from './style'
import { ReactComponent as CloseIcon } from "../../../../../assets/img/svg/CloseIcon.svg";
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper';

const FormHeader = ({onClose}) => {
  return (
    <div className="d-flex justify-content-between  mb-4">
      <span style={labelStyle}> Add Test</span>{" "}
      <span>
        <IconButtonWrapper onClick={onClose}>
          <CloseIcon />
        </IconButtonWrapper>
      </span>
    </div>
  );
}

export default FormHeader