import React from "react";
import { withRouter } from "react-router-dom";
import { Card, Table, Button } from "reactstrap";
import {
  MasterHeaderAuto,
  Rows,
} from "../../../common/commonComponents/masterHeader/masterHeader";
import { openInNewTab } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { PermisionDenied } from "../../../common/commonComponents";
import CustomButton from "components/CustomButton";

const SearchTable = (props) => {
  const { data, userPermissions } = props;

  const headerList = [
    { name: "S.No" },
    { name: "Process ID" },
    { name: "Description" },
    { name: "File Name" },
    { name: "Status" },
    { name: "Error" },
    // { name: "Actions" },
  ];

  const listToRender = [
    "sno",
    "processId",
    "description",
    "fileName",
    "backendStatus",
    "error",
  ];
  return (
    userPermissions ?
      <Card className="mt-4">
        <div className="p-4">
          <Table className="align-items-center  tableLayout">
            <MasterHeaderAuto headerList={headerList} />
            <tbody className="list">
              {data ? (
                data.map((el, index) => {
                  return (
                    <Rows
                      data={{ ...el, sno: index + 1 }}
                      listToBeRendered={listToRender}
                    >
                      {/* <td className="white-breakSpace d-flex ">
                        <div className="offset-3"> */}
                          {/* <Button
                          color="info"
                          size="sm"
                          type="button"
                        //   onClick={() =>
                        //     openInNewTab(`/view/${el.processInstanceId}`)
                        //   }
                        >
                          <i className="fas fa-eye" />
                        </Button>
                        <Button
                          color="info"
                          size="sm"
                          type="button"
                        //   onClick={() =>
                        //     props.history.push(`/admin/processInstanceSearch/edit/${el.processInstanceId}`)}
                        >
                          <i className="fas fa-edit" />
                        </Button> */}
                          {/* <CustomButton
                            className={'mx-1'}
                            forTable={true}
                            permissionType={'R'}
                            icon={true}
                            onClick={() => openInNewTab(`/view/${el.processId}`)}
                            permissionSet={userPermissions}
                          />

                          <CustomButton
                            forTable={true}
                            className={'mx-1'}
                            permissionType={'U'}
                            icon={true}
                            onClick={() => openInNewTab(`/edit/${el.processId}`)}
                            permissionSet={userPermissions}
                          />
                        </div> */}
                      {/* </td> */}
                    </Rows>
                  );
                })
              ) : (
                <span>No Record Found</span>
              )}
            </tbody>
          </Table>
        </div>
      </Card> : <PermisionDenied />
  );
};

export default withRouter(SearchTable);
