import React, { useState } from 'react';
import { ReactComponent as Refresh } from 'assets/img/svg/Group 29.svg';
import { getScreenCards } from '../../../finOps/IssueStock/search/SearchCard';
import { useHistory } from 'react-router';
import { ReactComponent as DocumentIcon } from 'assets/img/svg/Document.svg';
import StockReturnApplicationSearchDialog from '../stockReturn/StockReturnApplicationSearchDialog';
import { useGetDlpModuleContext } from '../contextApi/dlpContext';
import { getUpdatedDropdown } from '../stockReturn/constant';
import StockReversalSearchDialog from '../stockReversal/StockReversalSearchDialog';

const ModuleCards = ({ stockConsumptionReturnHandler, stockTxnHandler, downloadReportsClickHandler = () => { } }) => {
  const history = useHistory();
  const { dropdown } = useGetDlpModuleContext();

  const [isStockReturnDialogOpen, setIsStockReturnDialogOpen] = useState(false);
  const [isStockReversalDialogOpen, setIsStockReversalDialogOpen] = useState(false);
  const stockReturnClickHandler = () => {
    setIsStockReturnDialogOpen(true);
  };
  const stockReversalClickHandler = () => {
    setIsStockReversalDialogOpen(true);
  };

  const cards = [
    {
      heading: 'Dispatch schedule',
      subHeading: 'View or edit dispatch schedule',
      icon: <DocumentIcon />,
      action: () => {
        history.push('/admin/edp/dlpStockManagement/dispatchSchedule/DLP');
      },
    },
    {
      heading: 'Stock issuance, dispatch & hold',
      subHeading: 'Update dispatch information',
      icon: <DocumentIcon />,
      action: () => {
        history.push(
          '/admin/edp/dlpStockManagement/IssueStockAndUpdateDispatch'
        );
      },
    },
    {
      heading: 'Branch consumption',
      subHeading: 'Issue stock to employee',
      icon: <DocumentIcon />,
      action: () => {
        history.push('/admin/edp/dlpStockManagement/StockConsumption/dlp');
      },
    },
    {
      heading: 'Branch consumption return',
      subHeading: 'Return the stock issued to employee',
      icon: <DocumentIcon />,
      action: stockConsumptionReturnHandler,
    },
    {
      heading: 'Stock return',
      subHeading: 'Return stock that has been issued',
      icon: <Refresh />,
      action: stockReturnClickHandler,
    },
    {
      heading: 'Transaction history',
      subHeading: 'Issue stock to employee',
      icon: <DocumentIcon />,
      action: stockTxnHandler,
    },
    {
      heading: 'Courier vendor master',
      subHeading: 'Click here to view and edit Courier vendors ',
      icon: <DocumentIcon />,
      action: () => {
        history.push('/admin/edp/dlpStockManagement/courierVendorMaster/DLP');
      },
    },
    {
      heading: "Download reports",
      subHeading: "Ageing report, Current stock availability etc.",
      icon: <Refresh />,
      action: downloadReportsClickHandler,
    },
    {
      heading: "Stock reversal",
      subHeading: "Reverse the stock issued by branch",
      icon: <Refresh />,
      action: stockReversalClickHandler,
    },

  ];
  return (
    <>
      <div className={`screen-cards-container`}>{getScreenCards(cards)}</div>
      <StockReturnApplicationSearchDialog
        open={isStockReturnDialogOpen}
        setOpen={setIsStockReturnDialogOpen}
        dropdown={getUpdatedDropdown(dropdown)} // this method structures dlp dropdown object to match rcc dropdown object
        academicCareer="DLP"
      />
      <StockReversalSearchDialog
        open={isStockReversalDialogOpen}
        setOpen={setIsStockReversalDialogOpen}
        dropdown={getUpdatedDropdown(dropdown)} // this method structures dlp dropdown object to match rcc dropdown object
        academicCareer="DLP"
      />
    </>
  );
};

export default ModuleCards;
