import React, { useEffect, useState } from "react";
import { ReactComponent as EyeIcon } from "assets/img/svg/eye_open.svg";
import { fetchDispatchSchedule } from "../../customhooks";
import MouseOverPopover from "../../../../academicPlanAndTest/academicTest/PopOver";
import ALoader from "../../../../common/a-loader";
import AButton from "../../../../common/form-fields-mui/AButton";
import APopover from "../../../../common/a-popover";

export const DispatchScheduleDetail = ({
  dispatchScheduleId,
  dispatchId,
  closeHandler,
}) => {
  const [dispatchSchedularData, setDispatchSchedularData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDispatchSchedule(
      dispatchScheduleId,
      setDispatchSchedularData,
      setLoading
    );
  }, []);

  return (
    <div className="p-4">
      {loading && <ALoader position="fixed" />}
      <div>
        <div className="heading-3 color-dark">{dispatchId}</div>
        <ul className="pl-4">
          {dispatchSchedularData?.materialDetails?.map((item) => (
            <li key={item.materialNumber} className="mb-1 global-font-size">
              {item.materialNumber} | {item?.materialDescription} |
              {item?.materialCount} Qty
            </li>
          ))}
        </ul>
        <AButton onClick = {()=>closeHandler()} size="xs" variant="primary_filled">
          Ok, got it
        </AButton>
      </div>
    </div>
  );
};
export const ViewSchedularDetails = ({ dispatchScheduleId, dispatchId }) => {
  return dispatchScheduleId ? (
    <div className="ml-2">
      <APopover
        transformOriginHorizontal="center"
        anchorOriginHorizontal="center"
        buttonComponent={(onclick) => (
          <span
            className="cursor mr-2 primary-color text-bold cursor"
            onClick={onclick}
            size={18}
          >
            View
          </span>
        )}
        menuComponent={(closeForm) => (
          <DispatchScheduleDetail
            dispatchScheduleId={dispatchScheduleId}
            closeHandler={closeForm}
            dispatchId={dispatchId}
          />
        )}
      />
    </div>
  ) : (
    <></>
  );
};
