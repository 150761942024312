import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { Header, LectureListItem } from "../lectureModule/LectureListItem";
import ReviewTestCard from "./ReviewTestCard";
import ReviewSearchFilter from "./ReviewSearchFilter";
import AddLectureModalForm from "../lectureModule/AddLectureModalForm";
import AddPTMModalForm from "../lectureModule/AddPTMModalForm";
import AddCustomHolidayModal from "../lectureModule/AddCustomHolidayModal";
import {
  classTypeFilter,
  combinedViewInitialFilterForm,
  dateRangeFilter,
  getFilterCount,
  timeRangeFilter,
} from "./constant";
import NoRowOverlay from "../../../masters/holidayMaster/NoRowOverlay";
import CalenderViewTimeTable from "./CalenderViewTimeTable";
import AddEventModal from "../lectureModule/AddEventModal";

const ReviewListComponent = ({
  tests,
  setTests,
  lectures,
  isEnabledMsTeamsSync,
  setIsNeedToPublish,
  ...props
}) => {
  const [combinedArray, setCombinedArray] = useState([]);
  const [filteredArray, setFilteredArray] = useState([]);
  const [filterCount, setFilterCount] = useState(0);
  const [isPTMFormOpen, setIsPTMFormOpen] = useState(false);
  const [ptmRowData, setPtmRowData] = useState(false);
  const [formType, setFormType] = useState("PTM"); // t
  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);
  const [isLectureFormOpen, setIsLectureFormOpen] = useState(false);
  const [editedRow, setEditedRow] = useState(false);
  const [customHoliday, setOpenCustomHolidayModal] = useState(false);
  const [formData, setFormData] = useState(props?.formData);
  // const [openMode, setOpenMode] = useState(props?.mode || "EDIT");

  useEffect(() => {
    let combinedArrayTmp = [
      ...lectures.map((lecture, index) => ({ ...lecture, index })),
      ...tests.map((test, index) => ({ ...test, date: test?.testDate, index })),
    ].sort((a, b) => new Date(a["date"]) - new Date(b["date"]));
    setCombinedArray(combinedArrayTmp);
    // setFilteredArray(combinedArrayTmp)
    applyFilterHandler(filterForm, combinedArrayTmp);
  }, [lectures, tests]);

  // useEffect(()=>{
  //   getEvents(showPastEvents)
  // },[showPastEvents,filteredArray])

  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [expandedSyllabusId, setExpandedSyllabusId] = useState(-1);
  const [open, setOpen] = useState(false);
  const [lectureId, setLectureId] = useState(null);
  const [isCalenderView, setCalenderView] = useState(false);

  const deleteLectureHandler = (rowIndex) => {
    let filteredList = filteredArray?.filter(
      (item, index) => rowIndex !== index
    );

    setFilteredArray(filteredList);
    let deletedItemIndex = filteredArray[rowIndex]["index"];
    let filteredLecture = lectures.filter((x, i) => deletedItemIndex !== i);
    props.setLectures(filteredLecture);
    if (
      props.onEditSave &&
      props?.mode === "EDIT" &&
      filteredArray[rowIndex]?.id
    ) {
      props.onEditSave(
        props?.skipLectureWithOutId(filteredLecture),
        true,
        true
      );
    }
  };

  // this is for lecture edit
  const rowChangeHandler = (rowIndex, updatedRowObject, isSave) => {
    let updatedRows = [...filteredArray];
    updatedRows[rowIndex] = updatedRowObject;

    setFilteredArray(updatedRows);
    updateLectures(updatedRowObject["index"], updatedRowObject, isSave);
  };

  const updateLectures = (lectureIndex, updatedData, isSave) => {
    let tmpLectures = [...lectures];
    tmpLectures[lectureIndex] = updatedData;
    props.setLectures(tmpLectures);

    if (props.onEditSave && isSave && !updatedData?.isNew && updatedData?.id) {
      props.onEditSave(props?.skipLectureWithOutId(tmpLectures), true);
    }
  };

  const updateTests = (textIndex, updatedData) => {
    let tmpTests = [...tests];
    tmpTests[textIndex] = updatedData;
    setTests(tmpTests);
    if (setIsNeedToPublish) {
      setIsNeedToPublish(true);
    }
  };

  const testUpdateHandler = (index, value, formKey) => {
    let tempTest = [...filteredArray];
    tempTest[index][formKey] = value;
    setFilteredArray(tempTest);
    updateTests(tempTest[index]["index"], tempTest[index]);
  };

  const viewLectureHandler = (lectureId) => {
    setOpen(true);
    setLectureId(lectureId);
  };

  const [filterForm, setFilterForm] = useState(combinedViewInitialFilterForm);
  const filterInputHandler = (value, key) =>
    setFilterForm((prev) => ({ ...prev, [key]: value }));

  // const resetForm = () => {setFilterForm(combinedViewInitialFilterForm); removeFilterHandler();}

  const resetDependentFields = (classTypeValue) => {
    setFilterForm((prev) => ({
      ...combinedViewInitialFilterForm,
      startDate: prev?.startDate,
      endDate: prev?.endDate,
      startTime: prev?.startTime,
      endTime: prev?.endTime,
      classType: classTypeValue,
    }));
  };

  const removeFilterHandler = () => {
    setFilterCount(0);
    setFilterForm(combinedViewInitialFilterForm);
    setFilteredArray(combinedArray);
  };
  const applyFilterHandler = (form, updatedCombinedArray) => {
    // console.log('filter form', form)
    setFilterCount(getFilterCount(form));
    const res = [];

    const arr = updatedCombinedArray || combinedArray;
    arr.map((item) => {
      if (
        dateRangeFilter(form?.startDate, form?.endDate, item) &&
        timeRangeFilter(form?.startTime, form?.endTime, item) &&
        classTypeFilter(form, item)
      ) {
        res.push(item);
      }
    });

    setFilteredArray(res);
  };
  // const getEvents=(showPastEvents)=>{
  //   if(showPastEvents) setEventsToShow(filteredArray);
  //   else setEventsToShow(filteredArray.filter(event=>{
  //     if(event?.testName){
  //       // console.log('event',event);
  //       // console.log('isTestPassed',!isTestPassed(event));
  //       return !isTestPassed(event)
  //     }
  //     else return !isLecturePassed(event?.lectureEndTime);
  //   }))
  // }

  const handleOpenLectureModal = (type) => {
    setIsAddEventModalOpen(false);
    setIsLectureFormOpen(true);
    setFormType(type);
  };

  const handleOpenPTMModal = (type, data, rowIndex) => {
    setFormType(type);
    setIsAddEventModalOpen(false);
    setIsPTMFormOpen(true);
    if (data) setPtmRowData({ ...data, mode: "EDIT", rowIndex });
  };

  const handleOpenCustomHolidayModal = (indexInLecturesArr = -1, e = null) => {
    setFormType("Holiday");
    setIsAddEventModalOpen(false);
    setOpenCustomHolidayModal(true);

    if (indexInLecturesArr !== -1 && e !== null) {
      const { id, date, lectureStartTime, lectureEndTime, lectureName } = e;

      setEditedRow({
        id,
        lectureDate: date,
        lectureStartTime: moment(lectureStartTime).startOf("day"),
        lectureEndTime: moment(lectureEndTime).endOf("day"),
        lectureName,
        rowIndex: indexInLecturesArr,
        mode: "EDIT",
      });
    } else
      setEditedRow({
        lectureDate: formData.date,
        lectureStartTime: moment(formData.date).startOf("day"),
        lectureEndTime: moment(formData.date).endOf("day"),
        lectureName: "",
        mode: "EDIT",
      });
  };

  const handleOpenHolidayModal = (data, rowIndex) => {
    setFormType("Holiday");
    setOpenCustomHolidayModal(true);
    setEditedRow({
      ...data,
      mode: "EDIT",
      rowIndex,
    });
  };

  const resetEditFormFields = () => {
    setFormData({
      ...formData,
      date: "",
      startTime: "",
      endTime: "",
      classType: "",
      subject: "",
      faculty: "",
      lecture: "",
      room: "",
      id: null,
      selectedIndex: -1,
      isMSteams: isEnabledMsTeamsSync,
      bachTimeTablePsidMapping: null,
    });
  };

  return (
    <div>
      <div
        className="d-flex justify-content-end ml-2"
        style={{ padding: "10px 20px" }}
      >
        <ReviewSearchFilter
          filterForm={filterForm}
          filterInputHandler={filterInputHandler}
          resetDependentFields={resetDependentFields}
          applyFilterHandler={applyFilterHandler}
          removeFilterHandler={removeFilterHandler}
          roomsDropdown={props?.roomsDropdown}
          isCalenderView={isCalenderView}
          setCalenderView={setCalenderView}
          filterCount={filterCount}
          lectures={lectures}
          {...props}
        />
        {/* <AButton
          onClick={() => {
            setShowPastEvents(prev => !prev);
          }}
          updatedStyle={{
            border: showPastEvents ? "1px solid #00B0F5" : "1px solid #E9ECEF",
            color: "#0F0F0F",
            fontWeight: "600",
          }}
        >
          {showPastEvents ? 'Hide Past Events' : ' Show Past Events'}
        </AButton> */}
      </div>

      {isCalenderView ? (
        <CalenderViewTimeTable
          lectures={lectures}
          setIsAddEventModalOpen={setIsAddEventModalOpen}
          formData={formData}
          setFormData={setFormData}
          handleOpenLectureModal={handleOpenLectureModal}
          handleOpenPTMModal={handleOpenPTMModal}
          handleOpenCustomHolidayModal={handleOpenCustomHolidayModal}
        />
      ) : (
        <Card className="m-2 mt-0" style={{ overflow: "auto" }}>
          <CardBody
            className="p-2"
            style={{ minWidth: "100%", width: "fit-content" }}
          >
            <Header
              isEnabledMsTeamsSync={isEnabledMsTeamsSync}
              showActions={true}
            />
            {filteredArray?.length === 0 && (
              // <div className='mt-3 d-flex align-items-center justify-content-center '>No data found</div>
              <NoRowOverlay />
            )}

            {filteredArray?.map((item, idx) =>
              item?.testName ? (
                <ReviewTestCard
                  key={`${item?.testScheduleId || item?.id} ${idx}`}
                  index={idx}
                  expandedSyllabusId={expandedSyllabusId}
                  setExpandedSyllabusId={setExpandedSyllabusId}
                  testData={item}
                  planData={formData}
                  mode={props?.mode}
                  roomsDropdown={props?.roomsDropdown}
                  testUpdateHandler={testUpdateHandler}
                />
              ) : (
                <LectureListItem
                  key={`${item?.lectureName} ${item?.id} ${idx}`}
                  rowData={item}
                  rowIndex={idx}
                  rowChangeHandler={rowChangeHandler}
                  deleteHandler={deleteLectureHandler}
                  currentRoom={props?.currentRoom}
                  viewLectureHandler={viewLectureHandler}
                  expandedIndex={expandedIndex}
                  setExpandedIndex={setExpandedIndex}
                  isEnabledMsTeamsSync={isEnabledMsTeamsSync}
                  roomDropdown={props?.roomsDropdown}
                  showActions={true}
                  lectures={lectures}
                  handleOpenPTMModal={handleOpenPTMModal}
                  handleOpenHolidayModal={handleOpenHolidayModal}
                  {...props}
                />
              )
            )}
          </CardBody>
        </Card>
      )}

      <AddEventModal
        isOpen={isAddEventModalOpen}
        onClose={() => setIsAddEventModalOpen(false)}
        handleOpenLectureModal={handleOpenLectureModal}
        handleOpenPTMModal={handleOpenPTMModal}
        handleOpenCustomHolidayModal={handleOpenCustomHolidayModal}
      />

      <AddLectureModalForm
        isOpen={isLectureFormOpen}
        onClose={() => {
          setIsLectureFormOpen(false);
          resetEditFormFields();
        }}
        batchDetails={props?.batchDetails}
        planSubjects={props?.planSubjects}
        roomsDropdown={props?.roomsDropdown}
        subjectFacultyDropdown={props.subjectFacultyDropdown}
        formData={formData}
        setLectures={props?.setLectures}
        updateLectures={updateLectures}
        saveOnEdit={props.onEditSave}
        setIsNeedToPublish={setIsNeedToPublish}
        openMode={"EDIT"}
        isEnabledMsTeamsSync={props?.isEnabledMsTeamsSync}
        lectures={lectures}
        formType={formType}
      />

      {isPTMFormOpen && (
        <AddPTMModalForm
          type={formType}
          isOpen={isPTMFormOpen}
          onClose={() => {
            setIsPTMFormOpen(false);
            setPtmRowData(false);
            resetEditFormFields();
          }}
          batchDetails={props?.batchDetails}
          roomsDropdown={props?.roomsDropdown}
          setLectures={props?.setLectures}
          updateLectures={updateLectures}
          setIsNeedToPublish={setIsNeedToPublish}
          openMode={"EDIT"}
          isEnabledMsTeamsSync={isEnabledMsTeamsSync}
          lectures={lectures}
          subjectFacultyDropdown={props.subjectFacultyDropdown}
          ptmRowData={ptmRowData}
          saveOnEdit={props.onEditSave}
          formData={formData}
        />
      )}
      {customHoliday && (
        <AddCustomHolidayModal
          batchDetails={props?.batchDetails}
          isOpen={customHoliday}
          lectures={lectures}
          setLectures={props?.setLectures}
          setIsNeedToPublish={setIsNeedToPublish}
          onClose={() => {
            setIsAddEventModalOpen(false);
            setOpenCustomHolidayModal(false);
            setEditedRow(false);
            resetEditFormFields();
          }}
          editedRow={editedRow}
          saveOnEdit={props.onEditSave}
        />
      )}
    </div>
  );
};

export default ReviewListComponent;
