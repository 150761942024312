import React, { useState, useEffect, Children, Fragment } from 'react';
import { Container, Card, CardBody, CardHeader, Input } from 'reactstrap';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendarCustomization.scss';
import ShowDetails from './showDetails';
import Loader from 'react-loader-spinner';
import { validate } from '../../common/utils/methods/validations/validation';
const localizer = momentLocalizer(moment);

const CalendarView = (props) => {
	const {
		psIdData,
		isDisabled,
		searchPsID,
		setSearchPsId,
		getSearchData,
		psId,
	} = props;
	const [eventsList, setEventsList] = useState([]);
	const [showUserData, setShowUserData] = useState();
	const [colorChange, setColorChange] = useState({ selectedDate: new Date() });

	useEffect(() => {
		try {
			let temp = [];
			psIdData &&
				psIdData.map((item) => {
					temp.push({
						title: item['subject'],
						start: new Date(
							moment(
								item['startDate'] + ' ' + item['startTime'],
								'DD-MM-YYYY HH:mm:ss'
							)
						),
						end: new Date(
							moment(
								item['endDate'] + ' ' + item['endTime'],
								'DD-MM-YYYY HH:mm:ss'
							)
						),
						allDay: false,
					});
				});
			setEventsList(temp);
			showData(moment().format('DD-MM-YYYY'));
		} catch (e) {
			console.log('Error :: ', e);
		}
	}, [psIdData]);

	const showData = (date) => {
		try {
			let temp = [];
			psIdData &&
				psIdData.map((item) => {
					if (
						moment(date, 'DD-MM-YYYY').isSame(
							moment(item['startDate'], 'DD-MM-YYYY')
						)
					) {
						temp.push(item);
					}
				});
			setShowUserData(temp);
		} catch (e) {
			console.log('Error :: ', e);
		}
	};

	const selectedDate = (slotInfo) => {
		try {
			setColorChange({ selectedDate: new Date(slotInfo.start) });
			showData(moment(slotInfo.start).format('DD-MM-YYYY'));
		} catch (e) {
			console.log('Error ::', e);
		}
	};

	const ColoredDateCellWrapper = ({ value, range, children }) => {
		try {
			let valueis = moment(value).format('DD-MM-YYYY');
			let selDate = moment(colorChange.selectedDate).format('DD-MM-YYYY');
			let cellStyle = React.cloneElement(Children.only(children), {
				style: {
					...children.style,
					backgroundColor: valueis === selDate ? '#00d0f1' : '',
					fontWeight: valueis === selDate ? '900' : '',
				},
			});
			return cellStyle;
		} catch (e) {
			console.log('Error ::', e);
		}
	};

	const changeEventColor = (event, start, end, isSelected) => {
		try {
			const startDate = moment(start).format('DD-MM-YYYY hh:mm:ss A');
			const endDate = moment(end).format('DD-MM-YYYY hh:mm:ss A');
			const eventStartDate = moment(event['start']).format(
				'DD-MM-YYYY hh:mm:ss A'
			);
			const eventEndDate = moment(event['end']).format('DD-MM-YYYY hh:mm:ss A');

			let backgroundColor;

			if (
				moment(eventStartDate, ['DD-MM-YYYY hh:mm:ss A']).isBefore(moment()) &&
				moment(eventEndDate, ['DD-MM-YYYY hh:mm:ss A']).isBefore(moment())
			) {
				backgroundColor = '#585858';
			} else if (
				moment(eventStartDate, ['DD-MM-YYYY hh:mm:ss A']).isAfter(moment())
			) {
				backgroundColor = '#3E74D2';
			} else {
				backgroundColor = '#4BA461';
			}

			let style = {
				backgroundColor: backgroundColor,
				// borderRadius: '0px',
				opacity: 0.8,
				color: '#ffffff',
				// border: '0px',
				// display: 'block'
			};
			return {
				style: style,
			};
		} catch (e) {
			console.log('Error ::', e);
		}
	};

	return (
		<Container className="student_calendar_container" fluid>
			<Card className="student_calendar_card">
				<CardHeader className="fixedHeader">
					<div className="header_search">
						<Input
							data-testid="studentId"
							value={searchPsID}
							disabled={isDisabled}
							maxLength="12"
							placeholder="Enter Student ID"
							onChange={(e) => {
								let value = e.target.value;
								if (validate('numericValue', value)) {
									setSearchPsId(value);
								}
							}}
						/>
						<span
							data-testid="search_icon"
							className="search_icon"
							onClick={() => {
								getSearchData(searchPsID, 'searchInside');
							}}
						>
							{!isDisabled ? (
								<i className="fas fa-search"></i>
							) : (
								<Loader type="Oval" color="#ffffff" height={25} width={25} />
							)}
						</span>
					</div>
					<div className="right_heading">
						<div>
							<h4>Student ID : </h4> <span data-testid="psid">{psId}</span>
						</div>
					</div>
				</CardHeader>
				<CardBody>
					<div>
						<Calendar
							data-testid="calendarData"
							selectable={true}
							className="customCalendar"
							localizer={localizer}
							events={eventsList}
							onSelectSlot={selectedDate}
							components={{ dateCellWrapper: ColoredDateCellWrapper }}
							eventPropGetter={changeEventColor}
							startAccessor="start"
							endAccessor="end"
						/>
					</div>
					{showUserData &&
						showUserData.length > 0 &&
						showUserData.map((item) => <ShowDetails item={item} />)}
				</CardBody>
			</Card>
		</Container>
	);
};

export default CalendarView;
