import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import React from 'react'

const CustomDesktopTimePicker = ({ value = null, onChange = () => { }, sx = {}, ...rest }) => {
  const timepickerStyle = {
    width: '100%',
    '& .MuiInputBase-root': {
      // height: '31px',
      padding: '0px',
      '.Mui-disabled': {
        border: 'none',
      }
    },
    '& .MuiInputBase': {
      borderRadius: '8px',
    },
    '& .MuiInputBase-input': {
      fontSize: '12px !important',
      fontWeight: '400 !important',
      lineHeight: '16px !important',
      height: '31px',
      padding: '0 10px',
    },
    '& .MuiInputAdornment-root': {
      display: 'none',
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopTimePicker
        ampm={false}
        value={value}
        onChange={(value) => onChange(value)}
        sx={{ ...timepickerStyle, ...sx }}
        {...rest}
      />
    </LocalizationProvider>
  )
}

export default CustomDesktopTimePicker