import React, { useState, useEffect } from 'react'
import {
    Form,
    Input,
    Col,
    Button
} from 'reactstrap';
import Select from "react-select";
import { masterServiceBaseUrl, getAPI, putpost, putpost2 } from 'services/http'
import { failureToast, successToast } from '../../../common/utils/methods/toasterFunctions/function';


const CompanyCodeAddForm = (props) => {
    
    const {nextStep=()=>{}} = props;

    const [grpCode, setGrpCode] = useState('')
    const [key, setKey] = useState('')
    const [desc, setDesc] = useState('')
    const [displayValue, setDisplayValue] = useState('')
    const [grpData,setGrpData] = useState([])

    const fetchGrpCode = async () =>{
        getAPI(masterServiceBaseUrl+'/groupCode/getAllActiveGroupCode', (data) => {
          const grpData = data.data.map(
            data => {
              const {
                id,
                groupCodeDispValue,
                status
              } = data;
            return {
                value: id,
                label: groupCodeDispValue
            };
        }
        );    
          setGrpData(grpData)         
        }, (data) => { failureToast(data['message']) })
      }

    useEffect(() => {
        fetchGrpCode()
    }, [])

    const saveData = (formData) => {
        putpost(`${masterServiceBaseUrl}/companyCode/createCompanyCode`, (data) => {
            successToast(data['message'])
            nextStep(data.data.id)
        }, (data) => { failureToast(data['message'])}, formData, 'post')
    }


    const submitHandler=()=>{
        if(grpCode===''){
            failureToast('Please Select Group Code')
            return 
        }
        if(key.trim()===''){
            failureToast('Please enter Company code Key')
            return 
        }
        if(desc.trim()===''){
            failureToast('Please Enter Description')
            return 
        }
        if(displayValue.trim()===''){
            failureToast('Please Enter Diaplay Value')
            return 
        }

        const formData = {
            editMode:true,
            __status:"__new",
            companyCodeKey:key,
            status:"INACTIVE",
            companyCodeDesc:desc,
            companyCodeDispValue:displayValue,
            "effectiveDate":null,
            groupCodeId:Number(grpCode.value)
        }
        saveData(formData)
    }

    return (
        <>
            <div className="form-row mb-3">
                <Col className="mb-3" md="6">
                    <label className="form-control-label" >
                        GROUP CODE
                    </label>
                    <Select
                        value={grpCode}
                        id="example3cols2Input"
                        options={grpData}
                        // isDisabled={isDisabled}
                        onChange={(sData) => setGrpCode(sData)}
                     />
                </Col>
                <Col className="mb-3" md="6">
                    <label className="form-control-label" >
                        COMPANY CODE KEY
                    </label>
                    <Input
                        value={key}
                        placeholder={`Comapny Code Key`}
                        type="text"
                        maxLength="10"
                        onChange={e => setKey(e.target.value)}
                        style={{height:'39px'}}
                    />
                    <div className="invalid-feedback"></div>
                </Col>
            </div>
            <div className="form-row mb-3">
                <Col className="mb-3" md="6">
                    <label className="form-control-label" >
                        DESCRIPTION
                    </label>
                    <Input
                        value={desc}
                        placeholder={`Description`}
                        type="text"
                        maxLength="50"
                        onChange={e => setDesc(e.target.value)}
                        style={{height:'39px'}}
                    />
                </Col>
                <Col className="mb-3" md="6">
                    <label className="form-control-label" >
                        DISPLAY VALUE
                    </label>
                    <Input
                        value={displayValue}
                        placeholder={`Display Value`}
                        type="text"
                        maxLength="50"
                        onChange={e => setDisplayValue(e.target.value)}
                        style={{height:'39px'}}
                    />
                    <div className="invalid-feedback"></div>
                </Col>
            </div>
            <div className="form-row mb-3">
                <Button
                    color="primary"
                    type="button"
                    className={`ml-auto`}
                    onClick={submitHandler}
                >
                    Next
                </Button>
            </div>
        </>
    )
}

export default CompanyCodeAddForm
