
import React, { useEffect, useState } from "react";
import { Row, Col, UncontrolledTooltip, Container, CardHeader, Card, CardBody, Table } from 'reactstrap';
import CopyToClipboard from "react-copy-to-clipboard";
import {
    putpost,
} from "services/http";
// import { PermissionContext, RolePermissions } from "appContext";
// import { pages, permissionType } from "../../../../../common/constant";
import { Dialog, CustomUpload } from "../../../common/commonComponents";
import {
    failureToast, successToast
} from '../../../common/utils/methods/toasterFunctions/function';
import { fetchAllPromisedData, getUniqueKey } from "../../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint, constants, commonMessages, regex } from "../../../common/constant";
import { MasterHeaderAuto, Rows } from "../../../common/commonComponents/masterHeader/masterHeader";
// import CommonInput from "views/pages/manage/common/formFeilds/input/commonInput";
// import CustomTextArea from "views/pages/manage/common/formFeilds/customTextarea";
// import style from "./bluckUpload.module.scss";
import { SkeletonLoader } from "views/pages/manage/common/commonComponents/SkeletonLoader";
import CustomDownload from "../../../common/commonComponents/CustomDownload";
import { TablePagination } from '@mui/material';


const headerList = [
    { name: "Process ID" },
    { name: "File Name" },
    { name: "Status" },
    { name: "Error" },
];

const listToRender = [
    'pid',
    'fileName'
];


const ConsessionBulkUpload = (props) => {


    const { description,
        isModalVisible = false,
        setModalVisible = () => { },
        getAllData = () => { },
        userPermissions,
        goBack = () => { },
        // isUploading, 
        // setISUploading = () => { }, 
        finalData,
        setIsConfirmationOpen = () => { },
        setOpenBulkUpload = () => { }
    } = props;

    const [isFileValid, setISFileValid] = useState(false);
    const [filename, setFileName] = useState("");
    const [file, setFile] = useState(null);
    const [progressBarStatus, setProgressBarStatus] = useState(0);
    // const [description, setDescription] = useState('');
    const [isUploading, setISUploading] = useState(false);

    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
    const [processIdCopied, setProcessIdCopied] = useState(false);
    const [processId, setProcessId] = useState(null);


    const [data, setData] = useState([])
    const [isFetching, setIsFetching] = useState(false)

    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    const dataToServer = {
        bulk: true,
        fileName: null,
        docType: "csv",
        keyValue: `consession_${getUniqueKey()}`,
        serviceName: constants.templates_service_name.pricing,
        sourceId: constants.sourceId
    };

    const handleFile = (e) => {
        try {
            if (e.name.split(".")[1] === "csv") {
                if (e.name.length <= 100) {
                    let value = e.name.split(".")[0];

                    if (regex.alphaNumericWithSpaceAndUnderscore.test(value)) {
                        setFileName(value);
                        setFile(e);
                        setISFileValid(true);
                    } else {
                        failureToast("Only these special characters are allowed in file name: Space and Underscore ")
                        setFileName("");
                        setFile(null);
                        setISFileValid(false);
                    }
                } else {
                    failureToast("File name should be less than 100 characters.")
                    setFileName("");
                    setFile(null);
                    setISFileValid(false);
                }
            } else {
                failureToast("Only csv files are allowed")
                setFileName("");
                setFile(null);
                setISFileValid(false);
            }
        } catch (e) {
            console.log(e)
        }
    };

    const saveFileAndReturnData = (data) => {

        const request = {
            "processId": data.processId,
            "status": "ACTIVE",
            "extId": "12123",
            "documentType": "csv",
            "signedUrl": "",
            "isProcess": true,
            "serviceName": "CONCESSION",
            "processTagTypeId": 15
        }

        // const obj = {
        //     courseFeesProcessingDTO: { ...formData, description: finalData.description },
        //     courseFeeSupportingDocumentDtos: finalData.supportingDocument ?? []
        // }


        // setTimeout(()=>{
        //     console.log("obj",obj)
        //     setProgressBarStatus(100);
        // },2000)

        const url = constants.PRICING_FLAG_FOR_DMS ? `${endpoint.pricingUploadInfo.uploadInfo}` : `${endpoint.bulk_pricing_processing.uploadInfo}`

        putpost(
            endpoint.fetchS3Url.commonUploadDms,
            (res) => {
                if (res.code === 200 && res.data) {
                    setProgressBarStatus(100);
                    getAllData()
                    // setDescription('')
                    setProcessId(data.processId)
                    setIsSuccessModalVisible(true);
                    // successToast("File Uploaded Successfully")
                    setOpenBulkUpload(false)
                    successToast("File Uploaded Successfully")
                }
            },
            (res) => {
                setISUploading(false);
                setProgressBarStatus(0);
                setOpenBulkUpload(false)
                failureToast(res["message"]);
            },
            request,
            "post"
        );
    };

    const toggler = () => {
        setModalVisible(!isModalVisible);
        reset();
    }

    const reset = () => {
        setISFileValid(false);
        setFileName("Drag & Drop to Upload");
        setFile(null);
        setProgressBarStatus(0);
        setISUploading(false);
    };

    const getData = async (page) => {
        setIsFetching(true)
        const newData = await fetchAllPromisedData(`${endpoint.getAllConcessionUploadDetails}?offset=${page}`, true)
        if (newData.code === 200) {
            setData(newData.data?.concessionUploadDetailsDtoList);
            setTotalRecords(newData?.data?.totalRecords)
        }
        setIsFetching(false)
    }

    useEffect(() => {
        getData(0);
    }, [])

    const handlePageChange = (e, p) => {
        getData(+p);
        setCurrentPage(+p);
    }

    return (
        <Container>
            <Card className="mb-2">
                <CardHeader>
                    Bulk Slab Upload
                </CardHeader>
                <CardBody style={{
                    height: '80vh',
                    overflowY: 'auto'
                }}>
                    <CustomUpload
                        // documentTitle="Upload Supporting Document"
                        isFileValid={isFileValid}
                        filename={filename}
                        file={file}
                        progressBarStatus={progressBarStatus}
                        setISFileValid={setISFileValid}
                        setFileName={setFileName}
                        setFile={setFile}
                        setProgressBarStatus={setProgressBarStatus}
                        isUploading={isUploading}
                        setISUploading={setISUploading}
                        handleFile={handleFile}
                        dataToServer={dataToServer}
                        saveHandler={saveFileAndReturnData}
                        userPermissions={userPermissions}
                        goBack={() => setOpenBulkUpload(false)}
                        documentTitle={'Upload Slab Template'}
                        description={description}
                        confirmationRequired={true}
                        confimationMsg={"Your previous slabs can be over-written due to this upload. Do you want to continue ?"}
                    />


                    <div className="mt-4">
                        {isFetching ? (
                            <Card className="mt-4 p-4">
                                <SkeletonLoader rows={6} apiLoader={isFetching} />
                            </Card>
                        )
                            :
                            data.length ? <div>
                                <Table className="align-items-center  tableLayout">
                                    <MasterHeaderAuto headerList={headerList} permissionSet={userPermissions} />
                                    <tbody className="list">
                                        {data ?
                                            data.map((el, index) => {
                                                return (
                                                    <Rows data={{ ...el }} listToBeRendered={listToRender} >
                                                        <td className="text-center  white-breakSpace">
                                                            {el?.status && el.status.toUpperCase()}
                                                        </td>
                                                        <td className="text-center  white-breakSpace">
                                                            {el.status === 'FAILED' || el.status === 'PARTIAL_SUCCESS' ? <CustomDownload
                                                                downloadKey={el.processedFileKey}
                                                                permissionSet={userPermissions}
                                                                isIcon={true}
                                                            /> : null}
                                                        </td>
                                                    </Rows>
                                                )
                                            })
                                            : <span>No Record Found</span>}
                                    </tbody>
                                </Table>
                                <div className="d-flex justify-content-end">
                                    <TablePagination
                                        component="div"
                                        count={totalRecords}
                                        page={currentPage}
                                        onPageChange={handlePageChange}
                                        rowsPerPage={50}
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                display: 'none',
                                            },
                                            '& .MuiTablePagination-selectLabel': {
                                                display: 'none'
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                                : null}
                    </div>

                </CardBody>
            </Card>
        </Container>
    );
};

export default ConsessionBulkUpload;