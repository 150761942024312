import React, {useState} from 'react';
import {Button, Col, Modal, ModalBody, ModalFooter, Row} from 'reactstrap';
import  style from '../../../../common/commonComponents/Model/model.module.scss';
import CommonInput from "../../../../common/formFeilds/input/commonInput";

const AddNewModel = (props) => {
    const {
        isVisible,
        setIsVisible,
        addNewRow,
        num,
        setNum
    } = props;
    const [modal, setModal] = useState(false);
    const toggle = () => {
        setModal(!modal);
    }
    return (
        <div>
            <Modal isOpen={isVisible} toggle={toggle} className="custom_model" centered  backdrop={'static'}>
                <ModalBody className={style.model_body}>
                    <span style={{marginRight: '10px'}}>
                        Add New Row
                    </span>
                    <div className='container-fluid' style={{marginTop: '20px'}}>
                        <Row>
                            <Col md={12}>
                                <CommonInput forTable={true} type={'number'} placeHolder={'Enter Number of Rows'} defaultValue={num} maxLength={2} onChange={(value) => setNum(value)} isDisabled={false}/>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
                <ModalFooter className={style.model_footer}>
                    <Button color="info" style={{width:'50px'}} size='sm' onClick={addNewRow}>Add</Button>
                    <Button color="danger" style={{width:'55px'}} size='sm' onClick={() =>setIsVisible(false)}>{'Cancel'} </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default AddNewModel;