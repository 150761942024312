import React, { useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import RegionBusinessAreaCustomChip from '../../academicPlanAndTest/common/RegionBusinessAreaCustomChip';
import { ReactComponent as CloseIcon } from '../../../../../assets/img/svg/CrossIcon.svg'
import { chipContainerStyle } from './EditBranchesCommonComponent';
import AInput from '../formFeilds/customInput/AInput';
import { fetchAllPromisedData } from '../utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../constant';
import CustomLoader from '../commonComponents/Loader/loader';


// const chipContainerStyle = {
//   maxHeight: "120px",
//   overflowY: "auto",
//   display: "flex",
//   flexWrap: "wrap",
//   gap: "2px",
//   marginTop: "3px",
// };

// regions and businessAreas arrays passed to component need to be array of objects of type {dispValue: 'abc', id: 123}
const ViewBranchesBasedOnBusinessArea = ({ businessAreas, closeForm = () => { } }) => {

    const [userInput, setUserInput] = useState('');
    const [allRegions, setAllRegions] = useState([]);
    const [selectedBA,setSelectedBA] = useState([]);
    const [loading, setLoading] = useState(false);

    const filteredBusinessAreas = selectedBA?.filter(item => (item?.dispValue?.toLowerCase()?.includes(userInput?.toLowerCase())))
    const filteredRegions = allRegions?.filter(item => (item?.dispValue?.toLowerCase()?.includes(userInput?.toLowerCase())));




    const regionList = (rd,bd) => {
        let rl = [];
        businessAreas.map(i => {
            const matched = bd.find(area => area.id === i.id);
            if(matched?.regionId){
                rl = Array.from(new Set(rl.concat(matched.regionId)))
            }
        })

        const region = rl.map(i => {
            const reg = rd.find(r => r.id === i);
            return {dispValue : reg.regionDispValue, id : reg.id}
        })

        const bu = businessAreas.map(i => {return {dispValue : i?.businessAreaDispValue ?? i?.dispValue, id : i.id}})

        setAllRegions(region)
        setSelectedBA(bu)
        // const regionData = data.map(r => rl.indexOf(r.id) > -1).map(i => { return { id: i.id, dispValue: i.regionDispValue } })
        // setAllRegions(regionData)
        setLoading(false)
    }

    const getAllRegionByBusinessArea = async() => {
        setLoading(true);
        const res = await Promise.all([
            await fetchAllPromisedData(endpoint?.region?.getAll, true),
            await fetchAllPromisedData(endpoint?.businessArea?.getAll, true)
          ])
        // console.log(res);
        if (res?.[0]?.code === 200 && res?.[1]?.code === 200 ) {
            regionList(res?.[0].data,res?.[1].data);
        }
        else {
            setAllRegions([]);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (Array.isArray(businessAreas) && businessAreas?.length) {
            getAllRegionByBusinessArea()
        }
    }, [businessAreas])

    return (
        <Card className='mb-0' style={{ position: 'relative', width: '370px' }}>
            {loading ?
                <CustomLoader
                    apiLoader={true}
                    loaderHeight={'200px'}
                    loaderWidth={'100% '}
                /> :
                <CardBody>
                    <div className='d-flex justify-content-between align-items-start mb-3'>
                        <div className='heading-4' style={{ color: '#414141' }}>Branch details</div>
                        <CloseIcon onClick={closeForm} />
                    </div>
                    <div className={`mb-2`}>
                        <AInput
                            className=''
                            value={userInput}
                            handler={(value) => setUserInput(value)}
                            crossIcon={false}
                            searchIcon={true}
                            searchIconClickHandler={() => { }}
                            placeholder={'Search'}
                            style={{ borderRadius: '8px', padding: '8px 16px', height: '45px', borderColor: '#CCCCCC', fontSize: '16px', paddingLeft: '16px', paddingRight: '36px' }}
                            customSearchIconStyle={{ right: '16px', left: 'unset' }}
                        />
                    </div>
                    {filteredRegions?.length > 0 && (
                        <div className='mb-3'>
                            <div>Region</div>
                            <div style={chipContainerStyle}>
                                {filteredRegions?.map((item) => (
                                    <RegionBusinessAreaCustomChip
                                        key={item.id}
                                        text={item.dispValue}
                                        crossIcon={false}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                    {filteredBusinessAreas?.length > 0 && (
                        <div>
                            <div>Business Area</div>
                            <div style={chipContainerStyle}>
                                {filteredBusinessAreas?.map((item) => (
                                    <RegionBusinessAreaCustomChip
                                        key={item.id}
                                        text={item.dispValue}
                                        crossIcon={false}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                    {!filteredRegions?.length && !filteredBusinessAreas?.length && (
                        <div style={{ color: '#CCCCCC', fontSize: '14px' }}>No results found</div>
                    )}
                </CardBody>
            }
        </Card>
    )
}
export default ViewBranchesBasedOnBusinessArea