import React, { useContext, useState } from 'react'
import styles from '../styles.module.scss'
import ADataGrid from 'views/pages/manage/common/data-grid/ADataGrid'
import { AGridColDef } from 'views/pages/manage/common/data-grid/ADataGridColDef'
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material'
import { gridComponents, gridStyles, isValidPhotoKey, primaryCheckboxStyles } from '../constant'
import ActionsCellComponent from './ActionsCellComponent'
import BulkSelectionFooter from './BulkSelectionFooter'
import { IssuanceIDCardContext } from '../../contextApi'
import StatusCellComponent from './StatusCellComponent'
import ALoader from "views/pages/manage/common/a-loader";
import { toggleRfidStatus } from '../../service'
import { successToast } from '../../../../common/utils/methods/toasterFunctions/function'
import { useHistory } from 'react-router'
import PhotoCellComponent from './PhotoCellComponent'

const PrintIdCardTable = ({ rowData = [], rowCount = 0, nextButtonHandler = () => { }, currentPage }) => {
  const history = useHistory();

  const [selectionModel, setSelectionModel] = useState([]);
  const isRowSelectable = (params) => (params?.row?.isEligibleProgramActionForAllocation && isValidPhotoKey(params?.row?.photoKey))

  const { getApplications, isApplicationLoading, page, setPage, filterForm, globalFilter } = useContext(IssuanceIDCardContext);
  const [toggleLoader, setToggleLoader] = useState(false);
  const toggleRfidStatusHandler = async (params) => {
    setToggleLoader(true);
    const request = {
      psid: params?.row?.psid,
      applicationId: params?.row?.applicationId,
      rfidStatusToChange: params?.row?.rfidStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
    }
    const res = await toggleRfidStatus(request);
    if (res?.code === 200) {
      successToast(res?.message);
      await getApplications(page, { ...filterForm, ...globalFilter, rfidMappedApplication: showOnlyNewIssue ? false : null });
    }
    setToggleLoader(false);
  }

  // const [filteredRowData, setFilteredRowData] = useState(rowData);
  const [showOnlyNewIssue, setShowOnlyNewIssue] = useState(false);
  // useEffect(() => {
  //   showOnlyNewIssue ? setFilteredRowData(rowData?.filter(item => !item?.rfid)) : setFilteredRowData(rowData)
  // }, [rowData, showOnlyNewIssue])

  const printIdCardListHeader = [
    new AGridColDef('psid', 'PSID').setMinWidth(150)
      .renderCellComponent(params => (
        <Tooltip title={params?.value}>
          <div
            onClick={isRowSelectable(params) ? () => window.open(history.createHref({ pathname: `/admin/edp/studentDetails/${params.value}` }), '_blank') : () => { }}
            className={`ellipsis-text-container ${isRowSelectable(params) ? 'color-primary' : 'color-grey-50'}`}
            style={isRowSelectable(params) ? { cursor: 'pointer' } : {}}
          >
            {params?.value}
          </div>
        </Tooltip>
      )),
    new AGridColDef('applicationId', 'Application no.').setMinWidth(150)
      .renderCellComponent(params => <Tooltip title={params?.value}><div className={`ellipsis-text-container`}>{params?.value}</div></Tooltip>),
    new AGridColDef('studentName', 'Student name').setMinWidth(200)
      .renderCellComponent(params => <Tooltip title={params?.value}><div className={`ellipsis-text-container`}>{params?.value}</div></Tooltip>),
    new AGridColDef('programAction', 'Program plan').setMinWidth(150),
    new AGridColDef('photoKey', 'Photo', false).setMinWidth(100).setFlex(0.3)
      .renderCellComponent(params => <PhotoCellComponent params={params} />),
    new AGridColDef('issueType', 'Issue type').setMinWidth(150),
    new AGridColDef('rfidStatus', 'Old RFID status', false).setMinWidth(150)
      .renderCellComponent(params => <StatusCellComponent params={params} toggleRfidStatusHandler={toggleRfidStatusHandler} />),
    new AGridColDef('actions', 'Actions', false).setMinWidth(180).columnAlign('right')
      .renderCellComponent(params => <ActionsCellComponent params={params} />),
  ]

  return (
    <>
      <div className='d-flex flex-column'>
        {(isApplicationLoading || toggleLoader) && <ALoader position='fixed' />}
        <div className='d-flex align-items-center justify-content-between mb-2'>
          <div className='heading-4 color-black-60 flex-grow-1'>Print ID card</div>
          <FormControlLabel
            disableTypography
            sx={{ marginBottom: "0px", ...primaryCheckboxStyles }}
            control={
              <Checkbox
                onChange={(e) => {
                  setShowOnlyNewIssue(e?.target?.checked);
                  setPage(0);
                  getApplications(0, {...filterForm, ...globalFilter, rfidMappedApplication: e?.target?.checked ? false : null});
                }}
                checked={showOnlyNewIssue}
                disabled={selectionModel?.length>1}
              />
            }
            label={<div className='semi-bold'>Show only new issue</div>}
          />
        </div>
        <div>
          <ADataGrid
            rows={rowData || []}
            columns={printIdCardListHeader}
            rowId={(row) => JSON?.stringify(row)}
            rowCount={rowCount}
            nextButtonHandler={newPage => nextButtonHandler(newPage, showOnlyNewIssue ? false : null)}
            currentPage={currentPage}
            rowHeight={50}
            height={100}
            loading={false}
            getRowClassName={(params) => isRowSelectable(params) ? '' : styles?.disabled_row}
            components={gridComponents}
            sx={{
              '.MuiDataGrid-main': {
                height: `${60 + rowData?.length * 50}px`,
                minHeight: `${60 + 50}px`,
                maxHeight: `${60 + 50 * 50}px`
              },
              ...gridStyles
            }}
            hideFooterSelectedRowCount={true}
            checkboxSelection={true}
            disableSelectionOnClick
            selectionModel={selectionModel}
            onSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel)}
            isRowSelectable={isRowSelectable}
          />
        </div>
      </div>
      <BulkSelectionFooter
        selectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
      />
    </>
  )
}

export default PrintIdCardTable