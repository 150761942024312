import React, { useEffect, useState, useContext, useMemo } from 'react';
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { Stack } from '@mui/material';
import { PermissionContext, RolePermissions } from "../../../../../appContext";
import { pages } from '../../common/constant';
import { ConfirmationDialog, PermisionDenied } from '../../common/commonComponents';
import './index.scss';
import CustomButton from '../../../../../components/CustomButton/index.js';
import { BiSort } from 'react-icons/bi';
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';
import CommonInput from '../../common/formFeilds/input/commonInput.js';
import CustomLoader from '../../common/commonComponents/Loader/loader.js';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import APopover from '../../common/pop-hover/APophover.jsx';
import { AiOutlineEye } from "react-icons/ai";
import SearchFilterView from "./searchFilterPopover";
import deleteIcon from '../../../../../assets/img/svg/Delete.svg';
import editIcon from '../../../../../assets/img/svg/Edit2.svg'
import moment from 'moment';
import { RiFileCopyLine } from 'react-icons/ri'
import { Backdrop, Tooltip } from '@mui/material'
// import Tooltip from 'components/Tooltip';
import { UncontrolledTooltip } from 'reactstrap';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper';



// import { TransitionTimeouts } from 'reactstrap/lib/utils';

const _ = require("lodash");

const GetLabel = ({ col, data }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [toolTipText, setText] = useState('Copy');


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (col.type === 'multiSelect') {
        return data?.[col?.field]?.length > 1 ?
            <div className='d-flex'>
                <span style={{ whiteSpace: 'nowrap' }}> {`${data?.[col?.field]?.[0]?.label}, +${data?.[col?.field]?.length - 1}`}</span>
                {col?.popoverView ? <span
                    onClick={handleClick}
                    aria-describedby={"demo"}
                    style={{
                        color: " #00B0F5",
                        cursor: "pointer",
                    }}
                >
                    <AiOutlineEye />
                </span> : null}
                {col?.popoverView ? <APopover handleClose={handleClose} anchorEl={anchorEl}>
                    <SearchFilterView options={col.field === 'businessArea' ? [{ label: 'Region', options: data?.['regionForEdit'] }, { label: 'Branch', options: data?.[col?.field] }] : data?.[col?.field]} label={`${data?.['facultyName']} | ${col.headerName}`} />
                </APopover> : null}
            </div>

            : `${data?.[col?.field]?.[0]?.label ?? ''}`
    } else if (col.type === 'singleSelect') {
        return data?.[col?.field]?.label ?? ''
    } else if (col?.isCopyIconRequired) {
        return <div className='d-flex align-items-center'>
            <span style={{
                display: 'block',
                width: '100px',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}>
                {data?.[col?.field]}
            </span>
            {data?.[col?.field] ?
                <><Tooltip title={'text'}>
                    <RiFileCopyLine
                        id={`tool-${data?.['facultyId']}`}
                        style={{ color: "#00B0F5", height: "1rem", width: "1.2rem", cursor: "pointer", margin: '.5rem .5rem' }}
                        onClick={() => {
                            navigator.clipboard.writeText(data?.[col?.field]);
                            setText("Copied")
                        }} />
                </Tooltip>
                    {/* <UncontrolledTooltip
                        delay={0}
                        trigger="hover focus"
                        target={`tool-${data?.['facultyId']}`}
                        hideArrow={true} style={{ maxWidth: 'max-content' }}
                    >
                        {toolTipText}
                    </UncontrolledTooltip> */}
                </>
                : null}</div>
    } else {
        return data?.[col?.field] ?? ''
    }
}



const typeMap = {
    text: 'text',
    number: 'number',
    singleSelect: 'select',
    multiSelect: 'multiSelectWithCheckboxes',
    datePickerV2: 'datePickerV2',
    toggle: 'toggleV2',
    timePicker: 'timePicker',
    datePickerV4: 'datePickerV4'
}

const EditableRow = ({ columns, rowData, colSpan, onCancel, saveScheme, wariningRequired }) => {

    const [value, setValues] = useState({});
    const [isFormLoaded, setFormLoaded] = useState(false);

    useEffect(() => {
        if (rowData.isEditable) {
            let newValues = {};
            columns.map(col => {
                if (col.field === 'date') {
                    newValues[col.field] = rowData?.['rosterDate'] ?? ''
                } else {
                    newValues[col.field] = rowData?.[col.field] ?? ''
                }
            })
            setValues({ ...newValues })
            setTimeout(() => {
                setFormLoaded(true)
            }, 700)
        }

        if (rowData.isNewRow) {
            let newValues = {};
            columns.map(col => {
                if (col.type === 'toggle') {
                    newValues[col.field] = 'Active'
                }
                if (col.field === 'frequency') {
                    newValues[col.field] = rowData[col.field]
                }
                if (col.field === 'businessArea' && col.type === 'singleSelect' && col?.valueOptionsFunction()?.length === 1) {
                    newValues[col.field] = col?.valueOptionsFunction()?.[0] ?? ''
                }
            })
            setValues({ ...newValues })
        }
    }, [rowData])

    useEffect(() => {
        if (value?.region && isFormLoaded) {
            setValues({ ...value, businessArea: [] })
        }
    }, [value?.region])

    return (
        <>
            <tr className='editing-starting-grid'>
                {columns.map((col) => {
                    return <td>
                        <CommonInput
                            type={typeMap[col.type]}
                            defaultValue={col.valueFunction ? col.valueFunction(value) : value?.[col.field] ?? ''}
                            onChange={(val) => {col?.dependent ? setValues({ ...value, [col.field]: val, [col?.dependent]: col?.initialValue ?? '' }) : setValues({ ...value, [col.field]: val }) }}
                            data={col?.valueOptionsFunction ? col?.valueOptionsFunction(value) ?? [] : col.valueOptions}
                            forTable={true}
                            placeHolder={col.placeholder}
                            isDisabled={col.disabled || (rowData.isEditable && col?.disabledInEdit)}
                            regEx={col?.regEx}
                            minDate={col.minDateGetter ? col.minDateGetter(value) : col.minDate ? col.minDate : ''}
                            maxLength={col?.maxLength}
                        // {...col}
                        />
                    </td>
                })}
                <td style={{ display: 'flex', alignItems: 'center', paddingTop: '18px' }}>
                    <CustomButton
                        content={"Save"}
                        forTable={true}
                        permissionType={'C'}
                        permissionSet={'U,C,R,D'}
                        onClick={() => { saveScheme({ ...value, id: rowData?.id ?? '', isNewRow: rowData?.isNewRow ?? false }) }}
                    />
                    <CustomButton
                        icon={true}
                        forTable={true}
                        type="cancel"
                        // content={useDeleteIconForCancel ? Delete : ''}
                        permissionType={'cancel'}
                        permissionSet={'U,C,R,D'}
                        onClick={onCancel}
                    />
                </td>

            </tr>
            <tr className='table-header-row editing-ending-grid'>
                <td colSpan={colSpan}>{rowData.isEditable && wariningRequired ? <Stack height="32px" alignItems="center" justifyContent="center" sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '16p',
                    color: '#757575',
                    background: '#FFF6E3'
                }}>
                    <AiOutlineExclamationCircle /> <span className='pl-2'>Changing these details will impact the already published time tables across branches. Please proceed carefully after reviewing tiem table of this faculty.</span>
                </Stack> : null}</td>
            </tr>
        </>
    )
}

const NoRowsOverlay = ({ colSpan }) => {
    return <tr><td colSpan={colSpan}><Stack height="32px" alignItems="center" justifyContent="center" sx={{
        display: 'flex',
        flexDirection: 'row',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '16p',
        color: '#757575',
        background: '#F5F5F5'
    }}>
        <AiOutlineExclamationCircle /> <span className='pl-2'>No information to display</span>
    </Stack></td></tr>
}

const CommonTable = ({
    userPermissions,
    rows,
    columns,
    viewOnly,
    loading,
    pagination,
    setPagination,
    FilterComp,
    saveScheme,
    deleteScheme,
    onNewRowCancel,
    pageChangeHandler,
    deleteConfirmationHeader,
    deleteConfimationMsg,
    wariningRequired = false,
    isSaveVisible = true,
    setAddVisible = () => { }
}) => {

    const { page, pageCount } = pagination;
    const [data, setData] = useState([]);
    const [orignalData, setOrignalData] = useState([]);

    const [sorting, setSorting] = useState({
        dataSorted: false,
        sortOrder: null,
        sortedColumn: null
    })

    const getTotalColumns = () => viewOnly ? columns.length : columns.length + 1;
    const getRowsCount = () => rows.length;
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);

    const getDate = (datestring) => {
        const parts = datestring.split('/');
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1; // Month is zero-based in JavaScript Date objects
        const year = parseInt(parts[2], 10);

        const date = new Date(year, month, day);
        return date;
    }

    const generateIterateeFunctions = (columns) => {
        return columns.map((column) => {
            if (column === 'date') {
                return (obj) => getDate(obj.date)
            } else if (column === 'day') {
                const dayOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
                return (obj) => dayOrder.indexOf(obj.day);
            }else if(column === 'businessArea'){
                return (obj) => obj?.businessArea?.label
            }else if(column === 'startTime'){
                return (obj) => moment(new Date(`${moment(new Date()).format('ddd MMM DD YYYY')} ${obj.startTime}`)).format('HH:mm:ss')
            }else if(column === 'endTime'){
                return (obj) =>  moment(new Date(`${moment(new Date()).format('ddd MMM DD YYYY')} ${obj.endTime}`)).format('HH:mm:ss')
            }
            return (obj) => obj[column];
        });
    };


    const onSort = (columnName, SO, SCO) => {
        // let SCOs = (SO?.length && [...Array(SO.length - 1)].map(so => 'asc')) ?? [];
        let SCOs = SCO ? SCO : []

        if (columnName === sorting.sortedColumn) {
            if (sorting.dataSorted && sorting.sortOrder == 0) {
                let _tempData = _.orderBy(data, SO ? generateIterateeFunctions(SO) : generateIterateeFunctions([columnName]), ['desc', ...SCOs]);
                setSorting({
                    dataSorted: true,
                    sortOrder: 1,
                    sortedColumn: columnName
                })
                setData([..._tempData])

            } else if (sorting.dataSorted && sorting.sortOrder == 1) {
                setSorting({
                    dataSorted: false,
                    sortOrder: null,
                    sortedColumn: null
                })
                setData([...orignalData])

            } else {
                let _tempData = _.orderBy(data, SO ? generateIterateeFunctions(SO) : generateIterateeFunctions([columnName]), ['asc', ...SCOs]);
                setSorting({
                    dataSorted: true,
                    sortOrder: 0,
                    sortedColumn: columnName
                })
                setData([..._tempData])
            }

        } else {
            let _tempData = _.orderBy(data, SO ? generateIterateeFunctions(SO) : generateIterateeFunctions([columnName]), ['asc', ...SCOs]);
           setSorting({
                dataSorted: true,
                sortOrder: 0,
                sortedColumn: columnName
            })
            setData([..._tempData])
        }
    }

    const onEditClick = (index) => {
        const _temArr = [...data];
        _temArr[index] = { ..._temArr[index], isEditable: true }
        setData([..._temArr])
        setAddVisible(false)
    }

    const onCancel = (index) => {
        const _temArr = [...data];
       if (_temArr[index]?.isNewRow) {
            onNewRowCancel(index)
        } else {
            _temArr[index] = { ..._temArr[index], isEditable: false }
            setData([..._temArr])
        }
        setAddVisible(true)
    }

    const nextPageHandler = () => {
        // setPagination({ ...pagination, page: page + 1 })
        pageChangeHandler(Number(page) + 1);
    }

    const previousPageHandler = () => {
        // setPagination({ ...pagination, page: page - 1 })
        pageChangeHandler(Number(page) - 1);
    }

    const goToPage = (p) => {
        // setPagination({ ...pagination, page: page })
        pageChangeHandler(Number(p));
    }

    const options = useMemo(() => [...Array(pageCount).keys()].map(item => ({ label: item + 1, value: item })), [pageCount]);

    useEffect(() => {
        if(sorting.dataSorted){
            const __temp = [...rows];
            const anyNewRow = __temp.findIndex(i => i.isNewRow);
            let newRow = {};
            if(anyNewRow > -1){
                newRow = __temp.splice(anyNewRow,1);
            }

            const sortedColumnDef = columns.filter(c => c.field === sorting.sortedColumn);
            const SO = sortedColumnDef?.sortingOrder ?? [sorting.sortedColumn];
            const sortingWay = sorting.sortOrder ? 'desc' : 'asc';
            const SCOs = sortedColumnDef?.sortOrder ?? [];

            const sortedData = _.orderBy(__temp, generateIterateeFunctions(SO) , [sortingWay, ...SCOs]);
            if(anyNewRow > -1 && newRow?.length){
                setData([newRow?.[0],...sortedData]);
            }else{
                setData([...sortedData]);
            }
        }else{
            setData([...rows]);
        }
        setOrignalData([...rows])
    }, [rows])

    const handleDeletePrompt = (item) => {
        setCurrentItem(item);
        setOpenDeleteDialog(true);
    }

    const handleCloseDeletePrompt = (bool) => {
        setOpenDeleteDialog(bool);
        setCurrentItem(null);
    }

    const onAgreeDelete = () => {
        deleteScheme(currentItem);
        handleCloseDeletePrompt(false);
    }

    useEffect(() => {
        if(loading){
            window.scrollTo(0, 0)
            document.body.style.overflow = "hidden";
        }else{
            document.body.style.overflow = "scroll";
        }
      }, [loading])

    return (
        <>
            {loading && <div className='d-flex align-items-center justify-content-center' style={{ position: 'absolute', top: 0, left: 0, bottom: 0, width: '100vw', height: '85vh', zIndex: 10, background: 'white', opacity: 0.9 }}>
                <CustomLoader
                    apiLoader={true}
                    loaderHeight={'100%'}
                    loaderWidth={'100%'}
                />
            </div>}
            <div style={{ width: '100%', backgroundColor: 'white', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                {FilterComp}
            </div>
            <div style={loading ? {overflow:'hidden'} :{overflow:'overlay'}}>
                <table className='table-content'>
                    <thead>
                        <tr className='table-header-row row-bottom-border'>
                            {
                                columns.map(header => {
                                    return <th>
                                        <div style={{ whiteSpace: 'nowrap', display: 'flex', overflow: 'hidden' }} className="header-col">
                                            <div className='col-header' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: header.sortable ? 95 : '100%' }}>{header.headerName}</div>
                                            {header.sortable && <div className='sort-icon' onClick={!isSaveVisible ? () => {} :() => { onSort(header.field, header?.sortingOrder, header?.sortOrder) }} style={{cursor: !isSaveVisible ? 'not-allowed' : 'pointer'}}>
                                                {sorting.sortedColumn === header.field && sorting.sortOrder == 1 ? <AiOutlineArrowUp /> : sorting.sortedColumn === header.field && sorting.sortOrder == 0 ? <AiOutlineArrowDown /> : <BiSort />}
                                            </div>}
                                        </div>

                                    </th>
                                })
                            }
                            {!viewOnly && <th>Actions</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {data.length ? data.map((item, i) => {
                            return item.isEditable || item.isNewRow ?
                                <EditableRow rowData={item} columns={columns} colSpan={getTotalColumns()} onCancel={() => onCancel(i)} saveScheme={saveScheme} wariningRequired={wariningRequired} /> :
                                <tr className='table-data-row'>
                                    {columns.map((col) => {
                                        return <td>
                                            <GetLabel col={col} data={item} />
                                        </td>
                                    })}
                                    <td style={{ display: 'flex' }}>
                                        <CustomButton
                                            icon={false}
                                            className="btn-transparent p-0 btn-left-0"
                                            content={<IconButtonWrapper><img src={editIcon} /></IconButtonWrapper>}
                                            forTable={true}
                                            permissionType={'U'}
                                            permissionSet={userPermissions}
                                            onClick={() => { onEditClick(i) }}
                                            disabled={!isSaveVisible}
                                        />
                                        <CustomButton
                                            icon={false}
                                            content={<IconButtonWrapper><img src={deleteIcon} /></IconButtonWrapper>}
                                            forTable={true}
                                            className="btn-transparent p-0"
                                            permissionType={'D'}
                                            permissionSet={userPermissions}
                                            onClick={() => { handleDeletePrompt(item) }}
                                            disabled={!isSaveVisible}
                                        />
                                    </td>
                                </tr>
                        }) : <NoRowsOverlay colSpan={getTotalColumns()} />}


                    </tbody>
                    <tfoot>
                        {getRowsCount() ? <tr style={{ borderTop: '1px solid #E3E3E3', height: 50 }}>
                            <td colSpan={getTotalColumns()}><Stack height="32px" alignItems="center" justifyContent="center" sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                fontWeight: '400',
                                fontSize: '12px',
                                lineHeight: '16p',
                                color: '#757575',
                                justifyContent: 'space-between',
                                margin: '0px 15px'
                            }}>
                                <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                                    {/* <div style={{ fontSize: '14px' }}>1-10 of 230 items</div> */}
                                </div>
                                <div className='d-flex justify-content-end'>
                                    <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                                        {getRowsCount() ? <select
                                            value={page}
                                            onChange={(e) => {
                                                goToPage(e.target.value)
                                            }}
                                            style={{ borderColor: '#EDEDED', borderRadius: 10, backgroundColor: '#EDEDED', padding: '2px 8px', fontSize: '14px' }}
                                            disabled={loading}
                                        >
                                            {options.map(option => {
                                                return <option value={option.value}>{option.label}</option>
                                            })}
                                        </select> : null}
                                        <div style={{ fontSize: '14px' }}>{`of ${pageCount} pages`}</div>
                                    </div>
                                    <div style={{ cursor: 'pointer' }}>
                                        <MdKeyboardArrowLeft size={24} color={Number(page) + 1 > 1 ? '#000' : '#EDEDED'} onClick={Number(page) + 1 > 1 || loading ? () => {
                                            // setPagination({ ...pagination, page: page - 1 < 0 ? 0 : page - 1 })
                                            previousPageHandler()
                                        } : () => { }} />
                                        <MdKeyboardArrowRight className='ml-2' size={24} color={Number(page) + 1 < pageCount ? '#000' : '#EDEDED'} onClick={Number(page) + 1 < pageCount || loading ? () => {
                                            // setPagination({ ...pagination, page: page + 1 > pageCount - 1 ? pageCount - 1 : page + 1 })
                                            nextPageHandler()
                                        } : () => { }} />
                                    </div>
                                </div>
                            </Stack></td></tr> : null}</tfoot>
                </table>
            </div>
            <ConfirmationDialog
                isOpen={openDeleteDialog}
                onAgree={onAgreeDelete}
                setIsOpen={(bool) => handleCloseDeletePrompt(bool)}
                headerMsg={deleteConfirmationHeader ? deleteConfirmationHeader : 'Confirm row delete'}
                msg={deleteConfimationMsg ? deleteConfimationMsg : 'Are you sure you want to delete the selected row?'}
            />
        </>
    )
}

export default CommonTable;