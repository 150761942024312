import React from "react"
const PaymentCollectionHeader = ({text=''}) => {
    return (
        <div className='d-flex align-items-center justify-content-between'>
            <div
                style={{ fontFamily: 'sans-serif' }}
                className='heading-4 mb-2 mt-2 label-color'
            >
                {text || 'Search students to collect payment'}
            </div>
        </div>
    )
}
export default PaymentCollectionHeader