import React from "react";
import { useState } from "react";
import { Card, CardHeader, Input, Button, Table } from "reactstrap";
import { failureToast } from '../../common/utils/methods/toasterFunctions/function';
import { PAYMODEPINELABROW, MasterHeaderPineLabPaymode } from "../../common/commonComponents/masterRow/masterRow";
import { masterServiceBaseUrl, successToast, putpost, putpost2 } from 'services/http'
import moment from 'moment';
import './index.css';
import CreateButton from "components/FormComponent/Button/New/index";
import UpdateButton from "components/FormComponent/Button/Update/index";
import DeleteButton from "components/FormComponent/Button/Delete/index";
import { ButtonText } from "variables/Buttons";
import CustomButton from "../../../../../components/CustomButton";

var _ = require('lodash');

const PaymodePinelabTable = (props) => {
    const { permissionSet, setstate, tableData, pagination, setclassArray, setpagination } = props;
    const [isSaveVisible, setisSaveVisible] = useState(false)
    const [tableDataKey, settableDataKey] = useState('tableDataKey');
    const addNewClass = () => {
        props.setclassArray([{ "editMode": true, "__status": "__new",'status': 'ACTIVE',"effectiveDate": null,}, ...props.classArray])
    }
    const saveAll = (cb) => {
        var _tempArray = props.classArray
        var isValid = true
        _tempArray.forEach((el, index) => {
            if (el['editMode']) {
                if (el['pinelabsPaymentModeKey'] == null || !el['pinelabsPaymentModeKey'].trim()) {
                    isValid = false
                    failureToast('Please enter the value of Pine Labs Payment Mode Key')
                    return
                } else if (el['pinelabsPaymentModeDescription'] == null || !el['pinelabsPaymentModeDescription'].trim()) {
                    isValid = false
                    failureToast('Please enter the value of Description')
                    return
                }
                else if (el['pinelabsPaymentModeDisplayValue'] == null || !el['pinelabsPaymentModeDisplayValue'].trim()) {
                    isValid = false
                    failureToast('Please select the value of Pine Labs Payment Mode Display Value')
                    return
                }
                if (!isValid) {
                    return
                }
                if (_tempArray[index]['__status'] == "__new") {
                    putpost(masterServiceBaseUrl + '/pinelabs/payment/modes', (data) => {
                        successToast(data['message'])
                        setisSaveVisible(false)
                        props.fetchClasses(masterServiceBaseUrl + '/pinelabs/payment/modes')
                    }, (data) => { failureToast(data['message']) }, _tempArray[index], 'post')
                } else {
                    putpost(masterServiceBaseUrl + '/pinelabs/payment/modes/' + _tempArray[index].id, (data) => {
                        successToast(data['message'])
                        setisSaveVisible(false)
                        delete _tempArray[index]['editMode']
                        props.setclassArray(_tempArray)
                        settableDataKey(new Date().getMilliseconds())
                    }, (data) => { failureToast(data['message']) }, _tempArray[index], 'put')
                }
            } else {

            }
        })
    }
    const newRow = () => {
        if (isSaveVisible) {
            failureToast("Please Delete or Save current changes")
        }
        else {
            let cb = () => {
                addNewClass()
                setisSaveVisible(true)
            }
            var _tempArray = props.classArray
            _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
            props.setclassArray(_tempArray)
            setisSaveVisible(false)
            cb()
        }
    }
    const editRow = (index) => {
        if (isSaveVisible) {
            failureToast("Please Delete or Save current changes")
        }
        else {
            let cb = () => {
                var _tempArray = props.classArray
                _tempArray[index]['editMode'] = true
                props.setclassArray(_tempArray)
                setisSaveVisible(true)
            }
            var _tempArray = props.classArray
            _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
            cb()
        }
    }
    const deleteRow = (index) => {
        if (props.classArray[index]["__status"] == "__new") {
            let n = props.classArray
            n.splice(index, 1)
            props.setclassArray(n)
            setisSaveVisible(false)
            settableDataKey(new Date().getMilliseconds())
        } else {
            if (window.confirm('Are you sure you want to delete this Payment Mode?')) {
                putpost2(masterServiceBaseUrl + '/pinelabs/payment/modes/' + props.classArray[index].id, (data) => {
                    successToast(data['message'])
                    if (props.classArray.length == 1) {
                        props.previousPage()
                        props.fetchClasses(masterServiceBaseUrl + '/pinelabs/payment/modes')
                    } else {
                        var _tempArray = props.classArray
                        props.fetchClasses(masterServiceBaseUrl + '/pinelabs/payment/modes')
                        if (_tempArray[index]['editMode']) {
                            setisSaveVisible(false)
                        }
                        _tempArray.splice(index, 1)
                        props.fetchClasses(masterServiceBaseUrl + '/pinelabs/payment/modes')
                        props.setclassArray(_tempArray)
                    }
                    settableDataKey(new Date().getMilliseconds())
                }, (data) => { failureToast(data['message']) }, 'PUT')
            }
        }
    }
    const updateKey = (index, value, key) => {
        var _tempArray = props.classArray
        _tempArray[index][key] = value
        props.setclassArray(_tempArray)
    }

    const searchPaginationHandler = (filterData, pageSize, n) => {
        if (filterData.length > pageSize) {
            n['nextPage'] = n.currentPage + 1
        }
        if (n.currentPage > 1) {
            n.previousPage = n.currentPage - 1
        }
        n['totalPage'] = Math.floor(filterData.length / pageSize)
        if (filterData.length % pageSize != 0) {
            n['totalPage'] += 1
        }
        setstate(filterData);
        setclassArray(filterData.slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || []);
        setpagination(n)
    }

    const filterHandler = _.debounce((cashierOfficeData) => {
        let n = pagination;
        let pageSize = 10;
        if (cashierOfficeData) {
            const filterData = tableData.filter(data => (
                data.cashierOfficeKey.toUpperCase().includes(cashierOfficeData.toUpperCase()) ||
                data.description.toUpperCase().includes(cashierOfficeData.toUpperCase()) ||
                data.currencyId.toString().toUpperCase()
                    .includes(props.currency.find(item => item.text.toUpperCase().includes(cashierOfficeData.toUpperCase())) && 
                    props.currency.find(item => item.text.toUpperCase().includes(cashierOfficeData.toUpperCase())).id.toString()) ||
                
                data.businessAreaId.toString().toUpperCase()
                    .includes(props.businessArea.find(item => item.text.toUpperCase().includes(cashierOfficeData.toUpperCase())) && 
                    props.businessArea.find(item => item.text.toUpperCase().includes(cashierOfficeData.toUpperCase())).id.toString()) ||
                data['updatedOn'].toUpperCase().includes(cashierOfficeData.toUpperCase()) ||
                data['status'].toUpperCase().includes(cashierOfficeData.toUpperCase())
            ));
            searchPaginationHandler(filterData, pageSize, n)
        } else {
            searchPaginationHandler(tableData, pageSize, n);
        }
    }, 300)

    return (
        <>
            <Card style={{ boxShadow: 'none' }} className='mb-0'>
                <CardHeader className="border-0">
                    <h3 className="mb-0 floatLeft">Pine Labs Payment Mode</h3>
                    {/* <CreateButton
                        text={ButtonText.PineLabsPaymentMode.addNew}
                        disabled={isSaveVisible}
                        className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)} 
                        onClick={() => { newRow() }}
                    /> */}
                    <CustomButton 
                        disabled={isSaveVisible}
                        className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
                        content={ButtonText.PineLabsPaymentMode.addNew}
                        icon={true}
                        permissionType={'C'}
                        permissionSet={permissionSet}
                        onClick={() => { newRow() }}
                    />
                   {/* <Button disabled={isSaveVisible} color="info" size="sm" type="button" className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)} onClick={() => { newRow() }}>Add New System</Button> */}
                </CardHeader>
                <div id="questionTable" className="table-responsive" style={{ marginBottom: '3.5rem' }}>
                    <Table className="align-items-center table-flush tableLayout" >
                        <MasterHeaderPineLabPaymode
                            type={"PINELABS PAYMENT MODE"}
                        />
                        <tbody className="list" key={tableDataKey} >
                            {props.classArray ? props.classArray.map((el, index) =>
                                !el['editMode'] ? (
                                    <PAYMODEPINELABROW 
                                        type={"pineLabsPaymode"}  
                                        index={index} 
                                        el={el} 
                                        editRow={editRow} 
                                        deleteRow={deleteRow} 
                                        currency = {props.currency} 
                                        businessArea = {props.businessArea}
                                        isSaveVisible={isSaveVisible}
                                        permissionSet={permissionSet}
                                    />
                                ) :
                                    (<tr key={index + '-class'}>
                                        <td className="text-center p-2 mx-1"><Input maxLength={50} defaultValue={el['pinelabsPaymentModeKey']}
                                            onChange={(e) => { updateKey(index, e.target.value, 'pinelabsPaymentModeKey') }} placeholder="Example:1" className="tdInput" />
                                        </td>
                                        <td className="text-center p-2 mx-1">
                                            <Input maxLength={50} defaultValue={el["__status"] == "__new" ? null : moment(el['effectiveDate']).format('DD-MM-YYYY')}
                                            disabled
                                            onChange={(e) => { updateKey(index, e.target.value, 'effectiveDate') }}
                                            placeholder="Example:01-01-2020" className="tdInput" 
                                            /> 
                                        </td>
                                        <td className="text-center p-2 mx-1" ><Input maxLength={50} defaultValue={el['pinelabsPaymentModeDescription']} 
                                            onChange={(e) => { updateKey(index, e.target.value, 'pinelabsPaymentModeDescription') }} placeholder="Description" className="tdInput" />
                                        </td>
                                        <td className="text-center p-2 mx-1"><Input maxLength={50} defaultValue={el['pinelabsPaymentModeDisplayValue']}
                                            onChange={(e) => { updateKey(index, e.target.value, 'pinelabsPaymentModeDisplayValue') }} placeholder="Display Value" className="tdInput" />
                                        </td>
                                        <td className="text-center p-2 mx-1">
                                            <label className="custom-toggle mx-auto ml-2">
                                                <input
                                                    checked={el.status == "ACTIVE" ? true : false}
                                                    type="checkbox" id={el.id}
                                                    onChange={(e) => {
                                                        settableDataKey(new Date().getMilliseconds());
                                                        let n = "ACTIVE"; if (!e.target.checked) { n = "INACTIVE" }
                                                        updateKey(index, n, 'status')
                                                    }}
                                                />
                                                <span style={{ width: "72px", margin: '0 auto' }} className="custom-toggle-slider rounded-circle activeToggle"
                                                    data-label-off="Inactive" data-label-on="Active" />
                                            </label>
                                        </td>
                                        <td className="text-center">
                                            {isSaveVisible ? (
                                            <> 
                                                {/* <UpdateButton text={ButtonText.PineLabsPaymentMode.save} onClick={() => { saveAll() }}/> */}
                                                <CustomButton 
                                                    className={'mx-1'}
                                                    content={ButtonText.PineLabsPaymentMode.save}
                                                    permissionType={'C,U'}
                                                    forTable={true}
                                                    permissionSet={permissionSet}
                                                    onClick={() => saveAll()}
                                                />
                                                {/* <Button color="info" size="sm" type="button" className="mx-1" onClick={() => { saveAll() }}>Save</Button> */}
                                                    {/* <Button color="danger" size="sm" type="button" onClick={() => {
                                                        let n = props.classArray
                                                        if (n[index]['__status'] == '__new') {
                                                            n.splice(index, 1)
                                                        } else {
                                                            n[index]['editMode'] = false
                                                            props.fetchClasses(masterServiceBaseUrl + '/pinelabs/payment/modes')
                                                        }
                                                        props.setclassArray(n)
                                                        setisSaveVisible(false)
                                                    }}>  <i className="fas fa-times" /></Button> */}
                                                <CustomButton 
                                                    className={'mx-1'}
                                                    forTable={true}
                                                    icon={true}
                                                    permissionType={'cancel'}
                                                    permissionSet={permissionSet}
                                                    onClick={() => {
                                                        let n = props.classArray
                                                        if (n[index]['__status'] == '__new') {
                                                            n.splice(index, 1)
                                                        } else {
                                                            n[index]['editMode'] = false
                                                            props.fetchClasses(masterServiceBaseUrl + '/pinelabs/payment/modes')
                                                        }
                                                        props.setclassArray(n)
                                                        setisSaveVisible(false)
                                                    }}
                                                />
                                            </> 
                                            ) : (
                                                // <Button color="info" size="sm" type="button" onClick={() => {
                                                //     deleteRow(index)
                                                // }}>  <i className="fas fa-trash" /></Button>
                                                <CustomButton 
                                                    content={''}
                                                    permissionType={'D'}
                                                    icon={true}
                                                    forTable={true}
                                                    onClick={() => deleteRow(index)}
                                                    permissionSet={permissionSet}
                                                />
                                            )}
                                        </td>
                                    </tr>)
                            ) : null}
                        </tbody>
                    </Table>
                </div>
            </Card>
        </>
    )
}
export default PaymodePinelabTable;