import React, { useContext, useEffect, useState } from "react";
import { Button, Card, CardBody } from "reactstrap";
import CustomChip from "views/pages/manage/common/customChip/CustomChip";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import { ReactComponent as BackIcon } from "../../../../../../assets/img/svg/BackButton.svg";
import EmptyTestState from "./EmptyTestState";
import TestCard from "./TestCard";
import { useHistory } from "react-router";
import { fetchAllPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "views/pages/manage/common/constant";
import GetFromProgressBar from "./../lectureModule/GetFormProgressBar";
import TestForm from "../../academicTest/TestForm";
import AddTestTimetableCnf from "./AddTestTimetableCnf";
import { useGetPlanByPlanId } from "../hooks";
import { fetchAllPostPromisedData } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { successToast } from "services/http";
import { TimetableContext } from "../contextApi/TimetableContext";
import TestUpdatedDetails from "../editViewTimetable/TestUpdatedDetails";
import { isTestCompleted, isTestPassed } from "../helper";
import { TablePagination } from "@mui/material";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper";

const ListComponent = ({
  setOpenForm,
  testsArray,
  roomsDropdown,
  testUpdateHandler,
  isReadyToPublish,
  ...props
}) => {
  const [allTestCount, setAllTestCount] = useState(0);
  const [isAddTest, setIsTest] = useState(false);
  const [isAddTestCnf, setIsTestCnf] = useState(false);

  const planData = useGetPlanByPlanId(props?.formData?.plan?.value);
  const {
    isResetEnabled,
    pastTests,
    totalPastTestsCount,
    pastTestPage,
    setPastTestPage,
    missedTests
  } = useContext(TimetableContext);

  const [showPastTests, setShowPastTests] = useState(false);
  const [expandedPastIndex, setPastExpandedIndex] = useState(-1);
  const [expandedMissedIndex, setMissedExpandedIndex] = useState(-1);


  // const [testsToShow, setTestsToShow]=useState([]);

  const updateAttachTestHandler = async (attachTestForm, testData = {}) => {
    const updateRequest = {
      id: testData?.testScheduleId,
      basicDetails: {
        attachTestId: attachTestForm?.value,
        attachTestShortCode: attachTestForm?.label,
      },
    };
    const res = await fetchAllPostPromisedData(
      endpoint?.academicPlanTests?.updateTest,
      updateRequest,
      "put"
    );
    if (res && res?.data) successToast("Request completed successfully");
    const updatedTests = [...testsArray];

    const idx = updatedTests?.findIndex(
      (test) => test?.testScheduleId === testData?.testScheduleId
    );
    updatedTests[idx] = {
      ...updatedTests[idx],
      attachTestId: res?.data?.attachTestId,
      attachTestShortCode: res?.data?.attachTestShortCode,
    };

    props.setTests(updatedTests);
  };

  const getSavedTest = (newTest) => {
    if (newTest?.data) {
      // add this test into current tests list
      props.setTests([
        ...testsArray,
        {
          ...newTest?.data,
          testScheduleId: newTest?.data?.id,
          conductTest: true,
          id: "",
          isTimeTableEvent: true,
          isNew: true,
          isComplete: false,
          room: "",
          testDate: "",
          testStartTime: "",
          testEndTime: "",
          onlineTestDate: "",
          onlineTestEndDate: "",
        },
      ]);
    }
  };
  // const getTests=(showPastTests)=>{
  //   // console.log('testsToShow',testsToShow);
  //   if(showPastTests) setTestsToShow(testsArray);
  //   else setTestsToShow(testsArray.filter(test=>!testPassed(test)))
  // }
  const testPassed = (test) => {
    // const prm=(test?.testStartTime) ? test?.testStartTime : test?.testDate
    return isTestPassed(test);
  };

  // const getMessage=()=>{
  //   if(testsArray?.length===0) return "No Tests scheduled for this batch"
  //   return "No upcoming tests scheduled for this batch. Try searching tests scheduled in the past"
  // }

  const deleteTimetableTest = (index) => {
    if (window.confirm("Are you sure you want to delete?")) {
      let updatedTests = testsArray.filter((test, i) => index !== i);
      let selectedTest = testsArray[index];
      props.setTests(updatedTests);
      if (
        props?.openMode === "EDIT" &&
        !selectedTest?.isNew &&
        selectedTest?.id
      ) {
        props.onEdit(updatedTests, true, true, selectedTest?.id);
      }
    }
  };

  const getAllTestCountOfAcademicPlan = async () => {
    if (props?.formData?.plan?.value) {
      const res = await fetchAllPromisedData(
        `${endpoint?.academicPlanTests?.getAllTestScheduleByAcademicPlanId}/${props?.formData?.plan?.value}`,
        true
      );
      if (res?.code === 200) {
        setAllTestCount(res?.data?.length);
      }
    }
  };
  useEffect(() => {
    getAllTestCountOfAcademicPlan();
  }, [props?.formData, props?.openMode]);

  // useEffect(()=>{
  //   // console.log('useEffect 1st time running')
  //   getTests(showPastTests);
  // },[showPastTests,testsArray])

  const [expandedIndex, setExpandedIndex] = useState(-1);
  const history = useHistory();
  return (
    <div>
      <Card className="mb-2">
        <CardBody style={{ padding: "14px 20px" }}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <IconButtonWrapper
                onClick={() => {
                  if (props?.openMode === "NEW") {
                    setOpenForm("TIMETABLE");
                  } else {
                    if (isResetEnabled) {
                      setOpenForm("TIMETABLE_FORM");
                      return;
                    }
                    history.goBack();
                  }
                }}
              >
                <BackIcon />
              </IconButtonWrapper>

              <div
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  lineHeight: "28px",
                  color: "#0F0F0F",
                }}
              >
                Timetable
              </div>
              <CustomChip
                text={`${
                  props?.openMode === "NEW" ? "0" : testsArray?.length
                }/${allTestCount} Tests added`}
                textColor="#FFFFFF"
                bgColor={"#572AC8"}
                style={{ marginLeft: "24px" }}
              />
            </div>
            <GetFromProgressBar
              clickHandler={setOpenForm}
              mode={props?.openMode}
              step={1}
              isReadyToPublish={isReadyToPublish}
              disabledNext={!isReadyToPublish}
              isLectureListEmpty={props?.lectures?.length === 0}
            />
            <div className="d-flex align-items-center">
              {/* {props?.openMode === "EDIT" && (
                <AButton
                  variant="primary_filled"
                  onClick={() => props.onEdit(props?.formData, testsArray)}
                >
                  Save
                </AButton>
              )} */}
              {(props?.openMode === "EDIT" || props?.openMode === "NEW") && (
                <AButton
                  variant="primary_filled"
                  disabled={!isReadyToPublish}
                  onClick={() => setOpenForm("ACADEMIC_LECTURE")}
                >
                  Save & Next
                </AButton>
              )}
              {props?.openMode === "VIEW" && (
                <AButton
                  variant="primary_filled"
                  onClick={() =>
                    history.push(
                      `/admin/testScheduling/timetable/edit/${props?.batchId}`
                    )
                  }
                >
                  Edit Test
                </AButton>
              )}
              {props?.openMode === "VIEW" && (
                <AButton
                  variant="primary_filled"
                  onClick={() => setOpenForm("ACADEMIC_LECTURE")}
                >
                  Next
                </AButton>
              )}

              {/* {props?.openMode === "NEW" && (
                <AButton
                  variant="primary_filled"
                  disabled={!isReadyToPublish}
                  onClick={() => setOpenForm("ACADEMIC_LECTURE")}
                >
                  {props?.openMode === "NEW" ? "Save &" : ""} Next{" "}
                </AButton>
              )} */}
              {/* {props?.openMode === "EDIT" &&
                (props?.formData?.publishStatus === "P" ? (
                  <AButton
                    variant="danger_filled"
                    // disabled={!isReadyToPublish}
                    onClick={() =>
                      props.publishTimetableHandler(props?.formData, "U")
                    }
                  >
                    {`Unpublish timetable`}
                  </AButton>
                ) : (
                  <AButton
                    variant="primary_filled"
                    disabled={!isReadyToPublish}
                    onClick={() =>
                      props.publishTimetableHandler(props?.formData, "P")
                    }
                  >
                    {`Publish timetable`}
                  </AButton>
                ))} */}
              {/* <AButton
                variant="primary_filled"
                onClick={() => setOpenForm("ACADEMIC_LECTURE")}
              >
                {" "}
              </AButton> */}
            </div>
          </div>
        </CardBody>
      </Card>
      <div
        className="d-flex justify-content-end ml-2"
        style={{ padding: "10px 20px" }}
      >
        {props?.mode !== "VIEW" && (
          <AButton
            onClick={() => setIsTestCnf(true)}
            updatedStyle={{
              border: "1px solid #E9ECEF",
              color: "#0F0F0F",
              fontWeight: "600",
            }}
          >
            Add Test
          </AButton>
        )}
        <AButton
          onClick={() => {
            // console.log('testsArray',testsArray)
            setShowPastTests((prev) => !prev);
          }}
          updatedStyle={{
            border: showPastTests ? "1px solid #00B0F5" : "1px solid #E9ECEF",
            color: "#0F0F0F",
            fontWeight: "600",
          }}
        >
          {showPastTests ? "Hide Past Tests" : " Show Past Tests"}
        </AButton>
      </div>

      <TestUpdatedDetails tests={testsArray} mode={props?.openMode} />

      {isAddTest && (
        <TestForm
          planData={props?.formData?.plan}
          formData={props?.formData}
          academicPlanId={props?.formData?.plan?.value}
          open={isAddTest}
          onClose={() => setIsTest(false)}
          getSavedTest={getSavedTest}
          isTimeTableTest={true}
        />
      )}
      <AddTestTimetableCnf
        batchName={props?.batchName}
        open={isAddTestCnf}
        handleClose={() => setIsTestCnf(false)}
        openAddTest={() => {
          setIsTest(true);
          setIsTestCnf(false);
          setShowPastTests(false);
        }}
      />
      {showPastTests &&
        !pastTestPage &&
        missedTests?.map((test, index) => (
          <TestCard
            planData={planData}
            timeTableEndOn={props?.formData.endOn}
            testData={test}
            key={test?.id || index}
            index={index}
            expandedIndex={expandedMissedIndex}
            setExpandedIndex={setMissedExpandedIndex}
            roomsDropdown={roomsDropdown}
            testUpdateHandler={testUpdateHandler}
            mode={props?.openMode}
            deleteTimetableTest={deleteTimetableTest}
            updateAttachTestHandler={updateAttachTestHandler}
            testPassed={true}
            isMissedTest = {true}
          />
        ))}
      {!showPastTests ? (
        <div className="p-2">
          {testsArray?.length > 0 ? (
            testsArray?.map((test, index) => (
              <TestCard
                planData={planData}
                timeTableEndOn={props?.formData.endOn}
                testData={test}
                key={test?.id || index}
                index={index}
                expandedIndex={expandedIndex}
                setExpandedIndex={setExpandedIndex}
                roomsDropdown={roomsDropdown}
                testUpdateHandler={testUpdateHandler}
                mode={props?.openMode}
                deleteTimetableTest={deleteTimetableTest}
                // initialiseList={initialiseList}
                // updateTestStatusHandler={updateTestStatusHandler}
                updateAttachTestHandler={updateAttachTestHandler}
                testPassed={testPassed(test)}
              />
            ))
          ) : (
            <>
              <EmptyTestState />
            </>
          )}
        </div>
      ) : (
        <div>
          {pastTests?.length > 0 ? (
            pastTests?.map((test, index) => (
              <TestCard
                planData={planData}
                timeTableEndOn={props?.formData.endOn}
                testData={test}
                key={test?.id || index}
                index={index}
                expandedIndex={expandedPastIndex}
                setExpandedIndex={setPastExpandedIndex}
                roomsDropdown={roomsDropdown}
                testUpdateHandler={testUpdateHandler}
                mode={props?.openMode}
                deleteTimetableTest={deleteTimetableTest}
                updateAttachTestHandler={updateAttachTestHandler}
                testPassed={testPassed(test)}
              />
            ))
          ) : (
            <>
              <EmptyTestState isPastTest={true} />
            </>
          )}
          {pastTests?.length > 0 && (
            <TablePagination
              component={"div"}
              page={pastTestPage}
              onPageChange={(e, newPage) => setPastTestPage(newPage)}
              count={totalPastTestsCount}
              rowsPerPage={50}
              rowsPerPageOptions={[50]}
              sx={{
                // position: 'sticky', left: 0, right: 0,
                borderTop: "1px solid rgba(224, 224, 224, 1)",
                ".MuiTablePagination-displayedRows": {
                  display: "none",
                },
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ListComponent;
