import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
	Container,
	Card,
	CardHeader,
	Input,
	Row,
	Col,
	Button,
} from 'reactstrap';
import Select from 'react-select';
import {
	pricingServiceBaseUrl,
	examControllerServiceBaseUrl,
	putpost,
	successToast,
	failureToast,
} from 'services/http';
import SearchTable from './searchTable';
import CustomLoader from '../../common/commonComponents/Loader/loader';
import { fetchAllData } from '../../common/utils/methods/commonMethods/utilityMethod';
import CoreMaster from "../../common/commonComponents/coreMaster";
import {endpoint} from "../../common/constant";
import {RiDownloadCloudFill} from "react-icons/ri";
import { Dialog } from "../../common/commonComponents";

const FeeSearch = (props) => {
	const [serverData, setServerData] = useState(null);
	const [isFetching, setIsFetching] = useState(false);
	const [searchParams, setSearchParam] = useState({
		processId: '',
		status: null,
		description: '',
	});
	const [isModalVisible, setModalVisible] = useState(false);

	const statusData = [
		{ label: 'UPLOADED', value: 'uploaded' },
		{ label: 'PROCESSING', value: 'processing' },
		{ label: 'SUCCESS', value: 'success' },
		{ label: 'FAILED', value: 'failed' },
	];

	const submitHandler = () => {
		if (
			!searchParams.processId &&
			!searchParams.processId.trim() &&
			!searchParams.description &&
			!searchParams.description.trim() &&
			!searchParams.status?.value
		) {
			failureToast('Please provide atleast one search parameter from above');
			return;
		}

		setIsFetching(true);
		const searchData = {
			description: searchParams.description,
			processInstanceId: searchParams.processId || null,
			status: searchParams?.status?.value,
		};
		putpost(
			`${pricingServiceBaseUrl}/search`,
			(data) => {
				successToast(data['message']);
				setServerData(data.data);
				setIsFetching(false);
			},
			(data) => {
				setServerData([]);
				failureToast(data['message']);
				setIsFetching(false);
			},
			searchData,
			'post'
		);
	};

	const reset = () => {
		setSearchParam({
			processId: '',
			status: null,
			description: '',
		});
		setServerData(null);
		getLatestedData();
	};

	const getLatestedData = () => {
		fetchAllData(
			`${pricingServiceBaseUrl}/getAllCourseFeeDetail`,
			setServerData
		);
	}

	// useEffect(() => {
	// 	// getLatestedData();
	// }, []);

	// useEffect(() => {
	//   if(searchParams.status===null){
	//     setSearchParam({ ...searchParams, status: { label: "", value: "" } })
	//   }
	// }, [searchParams.status])



	return (
		<Container fluid className="mt-4">
			<Dialog
				title="External Org Bulk"
				isModalVisible={isModalVisible}
				toggle={() => {
					setModalVisible(!isModalVisible);
					reset();
				}}
			/>
			<Card>
				<CardHeader className=" d-flex justify-content-between">
					<h3 className="mb-0 ">Exam ID Upload</h3>
					<div className="d-flex flex-row justify-content-between align-items-center">
						<Button
							color="info"
							size="sm"
							type="button"
							className={'floatRight'}
							onClick={() => setModalVisible(true)}
						>
							Upload External Org ID
						</Button>
						<Button
							color="info"
							size="sm"
							type="button"
							className={'floatRight'}
							// onClick={() =>
							// 	window.open(
							// 		`${examControllerServiceBaseUrl}/downloadTemplate`,
							// 		'_blank'
							// 	)
							// }
						>
							Download Template
						</Button>

						<Button
							color="info"
							size="sm"
							type="button"
							className={'floatRight'}
							onClick={() => props.history.push('/admin/examIDbulkSearch/new')}
						>
							Upload Template
						</Button>
					</div>
				</CardHeader>

				<div className="p-4">
					<div className="form-row">
						<Col className="mb-3" md="3">
							<label className="form-control-label">{`Process ID`}</label>
							<Input
								id="search_processID"
								value={searchParams.processId}
								placeholder={`Process ID`}
								type="text"
								maxLength="6"
								style={{ height: '39px' }}
								onChange={(e) =>
									!isNaN(e.target.value)
										? setSearchParam({
												...searchParams,
												processId: e.target.value,
										  })
										: setSearchParam({ ...searchParams })
								}
							/>
						</Col>
						<Col className="mb-3" md="3">
							<label className="form-control-label">{`Status`}</label>
							<Select
								value={searchParams.status}
								id="search_status"
								options={statusData}
								// isDisabled={isDisabled}
								placeholder="Status"
								onChange={(sData) =>
									setSearchParam({ ...searchParams, status: sData })
								}
							/>
						</Col>
						<Col className="mb-3" md="6">
							<label className="form-control-label">{`Description`}</label>
							<Input
								value={searchParams.description}
								placeholder={`Description`}
								id="search_description"
								type="text"
								maxLength="50"
								style={{ height: '39px' }}
								onChange={(e) =>
									setSearchParam({
										...searchParams,
										description: e.target.value,
									})
								}
							/>
						</Col>
					</div>
					<Row>
						<Col md="5" style={{ 'margin-left': '360px' }}>
							<Button
								className="btn-vimeo btn-icon w-100"
								color="info"
								type="button"
								onClick={() => {
									submitHandler();
								}}
							>
								<span className="btn-inner--icon mr-1">
									<i className="fas fa-search" />
								</span>
								<span className="btn-inner--text">Search</span>
							</Button>
						</Col>
						<Col md="3" style={{ 'margin-right': 'auto' }}>
							<Button
								className="btn"
								color="secondary"
								type="button"
								//   style={{ background: 'gray' }}
								onClick={reset}
							>
								<span className="btn-inner--text">Clear All</span>
							</Button>
						</Col>
					</Row>
				</div>
			</Card>
			{/* {isFetching || !serverData ? (
				<Card className="mt-4">
					<div
						className="mx-auto text-center py-5 my-5"
						style={{ height: '100vh' }}
					>
						<CustomLoader apiLoader={isFetching} />
					</div>
				</Card>
			) : serverData ? (
				<SearchTable data={serverData} />
			) : null} */}
			{/* <SearchTable data={serverData} /> */}
		</Container>
	);
};

export default withRouter(FeeSearch);
