import React, { useContext, useState } from "react";
import AInput from "views/pages/manage/common/formFeilds/customInput/AInput";
import SelectSearchInputParam from "../../studentSerach/SelectSearchInputParam";
import {
  FILTER__FORM_FIELDS,
  FILTER__FORM_FIELDS_GLOBAL,
  IssuanceIDCardContext,
  PAGE_STATE,
} from "../contextApi";
import "../style.scss";
import StudentApplicationsPrompt from "./StudentApplicationsPrompt";
import { failureToast } from "../../../common/utils/methods/toasterFunctions/function";
import { checkCourseEndDatePassed } from '../constant';
import { regex } from "../../../common/constant";

const notEligibleErrMsg = 'Application is not eligible for stock issuance';
const SearchInput = ({ disabled }) => {
  const {
    globalFilter,
    setGlobalFilter,
    getApplications,
    setCurrentPageState,
    setPage,
    resetStudentApplications,
    setFilterForm,
    setBuAndAcdGroup,
    setSelectedBusinessArea,
    dropdown,
  } = useContext(IssuanceIDCardContext);
  // 11 digit psid error
  const [isLengthError, setIsLengthError] = useState(null);
  const [isOpenPrompt, setIsOpenPrompt] = useState(false);
  const [applications, setApplications] = useState([]);
  const [dialogLoader, setDialogLoader] = useState(false);
  const lengthMap = { psid: 11, applicationId: 8 };

  const callBack = async (applications) => {
    if (applications.length === 0) {
      failureToast("The entered student ID is either invalid or ineligible for stock issuance. Kindly ensure that this ID is enrolled in a batch against the required BU.");
      return;
    }

    if (globalFilter?.typeOfCheckbox === "psid") {
      setApplications(applications);
      setIsOpenPrompt(true);
      return;
    }
    if(globalFilter?.typeOfCheckbox === "applicationId") {
      if(applications?.some(rowData => !rowData?.isEligibleForStockIssuance)) {
        failureToast(notEligibleErrMsg);
        return;
      }
      const isCourseEndDatePassed = await checkCourseEndDatePassed(applications?.[0]);
      if(isCourseEndDatePassed) return;
      setBuAndAcdGroup({
        academicGroup: applications?.[0]?.academicGroupDispValue,
        businessArea: applications?.[0]?.businessAreaDispVal,
        businessAreaId:applications?.[0]?.businessArea,
        termDispValue: applications?.[0]?.termDispVal,
        term: applications?.[0]?.term,
        courseId: applications?.[0]?.courseId,
      });
      setSelectedBusinessArea(
        dropdown?.businessAreas?.find((item) => item?.value === applications?.[0]?.businessArea)
      );
    }
    setCurrentPageState(PAGE_STATE.SELECT_MATERIAL);
  };

  const cb = (applications) => {
    setApplications([]);
    setIsOpenPrompt(false);
    setCurrentPageState(PAGE_STATE.SELECT_MATERIAL);
    // StudentFilterForm(FILTER__FORM_FIELDS);
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      if (
        globalFilter?.userInput.trim().length !==
        lengthMap[globalFilter?.typeOfCheckbox]
      ) {
        setIsLengthError(true);
        return;
      }
      applyFilterHandler();
    }
  };

  const applyFilterHandler = () => {
    if (
      globalFilter?.userInput.trim().length !==
      lengthMap[globalFilter?.typeOfCheckbox]
    ) {
      setIsLengthError(true);
      return;
    }
    setFilterForm(FILTER__FORM_FIELDS);
    setPage(0);
    getApplications(
      0,
      { ...FILTER__FORM_FIELDS, ...globalFilter, materialIds: [] },
      callBack
    );
  };

  let searchParamTitleMap = { psid: "PSID", applicationId: "application ID" };

  const onStudentSelection = async (row) => {
    if(!row?.isEligibleForStockIssuance) {
      failureToast(notEligibleErrMsg);
      return;
    }
    setDialogLoader(true);
    const isCourseEndDatePassed = await checkCourseEndDatePassed(row);
    setDialogLoader(false);
    if(isCourseEndDatePassed) return;
    setGlobalFilter((prev) => ({ ...prev, applicationId: row.applicationId }));
    getApplications(
      0,
      {
        ...FILTER__FORM_FIELDS,
        ...globalFilter,
        applicationId: row.applicationId,
        materialIds: [],
      },
      cb
    );
    setBuAndAcdGroup({
      academicGroup: row?.academicGroupDispValue,
      businessArea: row?.businessAreaDispVal,
      businessAreaId:row?.businessArea,
      termDispValue: row?.termDispVal,
      courseId: row?.courseId,
      term: row?.term
    });
    setSelectedBusinessArea(
      dropdown?.businessAreas?.find((item) => item?.value === row?.businessArea)
    );
  };

  return (
    <div className="mb-2">
      <div>
        <SelectSearchInputParam
          searchInputParam={globalFilter?.typeOfCheckbox}
          setSearchInputParam={(value) =>
            setGlobalFilter((prev) => ({ ...prev, typeOfCheckbox: value }))
          }
          isHideName={true}
          width={"180px"}
          disabled={disabled}
        />
      </div>
      <div style={{ width: "40%" }}>
        <AInput
          placeholder={`Search by ${
            searchParamTitleMap[globalFilter?.typeOfCheckbox]
          }`}
          style={{
            height: "42px",
            border: (globalFilter?.userInput && isLengthError) ? "1px solid red" : "none",
          }}
          value={globalFilter?.userInput}
          handler={(value) => {
            setGlobalFilter((prev) => ({ ...prev, userInput: value }));
            setIsLengthError(null);
          }}
          onKeyDown={(e) => onKeyDown(e)}
          crossIcon={true}
          rightSearchIcon={true}
          crossHandler={async () => {
            setGlobalFilter((prev) => ({ ...FILTER__FORM_FIELDS_GLOBAL }));
            setPage(0);
            resetStudentApplications();
          }}
          searchIconClickHandler={() => {
            applyFilterHandler();
          }}
          // type="number"
          regex={regex.numeric}
          disabled={disabled}
        />
        {(globalFilter?.userInput && isLengthError) && (
          <span className="error-psid">
            Please enter valid {lengthMap[globalFilter?.typeOfCheckbox]} - digit{" "}
            {searchParamTitleMap[globalFilter?.typeOfCheckbox]}
          </span>
        )}
      </div>
      {isOpenPrompt && (
        <StudentApplicationsPrompt
          isOpen={isOpenPrompt}
          setIsOpen={setIsOpenPrompt}
          list={applications}
          onSelectClick={onStudentSelection}
          loader={dialogLoader}
        />
      )}
    </div>
  );
};

export default SearchInput;
