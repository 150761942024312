import { useGridApiContext } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { Card, CardBody, Modal, Row, Col } from "reactstrap";
import ConsessionTreeClassification from "./treeView";
import { IoMdArrowDropdown } from "react-icons/io";
import styles from "./styles.module.scss";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";

export const SelectorCategory = (params) => {
  const {
    id,
    value,
    field,
    row,
    allCategories,
    userPermissions,
    rowFormHandler,
    isAdditionalCategory = false,
    rowForm,
    isView = false,
    isViewAdditional = false,
    additional = null,
    cutoffCategoryType = {},
  } = params;
  const apiRef = useGridApiContext();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    if (value) {
      setSelected(value);
    } else {
      setSelected(null);
    }
  }, [value]);
  useEffect(() => {
    if (additional) {
      setSelected(additional);
    }
  }, [additional]);

  const onSubCategoryClick = (c, sc, sname) => {
    if (sc.subSubCategory.length === 0) {
      setSelected({
        ...sc,
        isSubSubCategory: false,
        concessionMethodType: sname ? sname : "",
      });
    }
  };

  const onSubSubCategoryClick = (c, sc, ssc) => {
    setSelected({ ...ssc, isSubSubCategory: true });
  };

  const handleSave = async () => {
    if (!isAdditionalCategory) {
      if (selected?.isSubSubCategory) {
        rowFormHandler(selected?.id, "subSubCategoryId");
        rowFormHandler(selected?.subCategoryId, "subCategoryId");
        rowFormHandler(
          selected?.subSubCategoryDisplayValue,
          "subSubCategoryDispVal"
        );
      } else {
        rowFormHandler(selected?.id, "subCategoryId");
        rowFormHandler(null, "subSubCategoryId");
        rowFormHandler(selected?.subCategoryDispVal, "subCategoryDispVal");
      }
    } else {
      rowFormHandler(selected, "additional");
    }
    field == "additionalCategory"
      ? apiRef.current.setEditCellValue({
          id,
          field,
          value: { ...cutoffCategoryType, category2: selected },
          debounceMs: 200,
        })
      : apiRef.current.setEditCellValue({
          id,
          field,
          value: value,
        });
    setOpen(false);
  };

  const onClose = () => {
    setSelected(value);
    field == "additionalCategory"
      ? apiRef.current.setEditCellValue({
          id,
          field,
          value: { ...cutoffCategoryType, category2: value },
          debounceMs: 200,
        })
      : apiRef.current.setEditCellValue({
          id,
          field,
          value: value,
        });
    setOpen(false);
  };

  return (
    <>
      {!isView &&
        (isAdditionalCategory ? (
          <div
            className={
              !isViewAdditional
                ? `${styles["select-concession-cell"]}`
                : `${styles["select-concession-cell"]} ${styles["disable-background"]}`
            }
            onClick={() => {
              isViewAdditional ? setOpen(false) : setOpen(true);
            }}
          >
            <div className="regular-small ellipsis-text-container">
              {params.value?.subSubCategoryDisplayValue ??
                params.value?.subCategoryDispVal ??
                params.value?.displayVal ??
                selected?.subSubCategoryDisplayValue ??
                selected?.subCategoryDispVal ??
                rowForm?.additionalSubSubCategoryDispVal ??
                rowForm?.additionalSubCategoryDispVal ??
                "Select"}
            </div>
            <IoMdArrowDropdown size={24} />
          </div>
        ) : (
          <div
            className={`${styles["add-additional-category"]} `}
            style={{ fontWeight: "bolder" }}
            onClick={() => {
              setOpen(true);
            }}
          >
            {selected ? "Edit/View" : "Select"}
          </div>
        ))}
      {isView && (
        <div
          className={`${styles["add-additional-category"]} j`}
          onClick={() => setOpen(true)}
        >
          {"View"}
        </div>
      )}
      <Modal isOpen={open} centered={true} zIndex={1700}>
        <Card className="mb-0">
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mb-3 px-2">
              <h2 style={{ color: "#000000" }}>
                {!isAdditionalCategory
                  ? "Select category 1"
                  : "Select category 2"}
              </h2>
              <IoClose
                size={20}
                style={{ cursor: "pointer" }}
                onClick={onClose}
              />
            </div>
            <div className="mb-3">
              <ConsessionTreeClassification
                classificationData={allCategories}
                fetching={false}
                isFilterApplied={false}
                onCategoryClick={(c) => {}}
                onSubCategoryClick={onSubCategoryClick}
                onSubSubCategoryClick={onSubSubCategoryClick}
                isView={isView}
                showSelectBtn={true}
                showSelectBtnForConcessionOnly={true}
                selected={selected}
              />
            </div>
            <div>
              {!isView && (
                <AButton
                  className="btn-left-0"
                  updatedStyle={{ width: "100%", paddingTop: "17px 16px" }}
                  variant="outline"
                  onClick={selected ? handleSave : () => {}}
                >
                  Done
                </AButton>
              )}
            </div>
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};
