import React from 'react'
import { CiFilter } from 'react-icons/ci'
import APopover from '../../common/a-popover';
import AButton from '../../common/form-fields-mui/AButton';
import AInput from '../../common/formFeilds/customInput/AInput';
import AcademicPlansFilterForm from './AcademicPlansFilterForm'

const highlightedStyle = {
  border: "1px solid #00B0F5",
  color: "#00B0F5",
};
const normalStyle = {
  border: "1px solid #E9ECEF",
};

const AcademicPlansSearchFilter = ({ 
  filterCount, 
  filterForm, 
  inputHandler, 
  applyNameFilterHandler, 
  clearNameFilterHandler, 
  applyFilterHandler, 
  removeFilterHandler,
  businessAreaData,
  setBusinessAreaData
}) => {

  const onKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      applyNameFilterHandler()
    }
  }

  return (
    <div className='d-flex align-items-start'>
      <AInput
        type='text'
        placeholder={'Search here'}
        value={filterForm?.name}
        searchIcon
        crossIcon
        handler={(value) => inputHandler(value, 'name')}
        onKeyDown={onKeyDown}
        crossHandler={() => { inputHandler('', 'name'); clearNameFilterHandler(); }}
        searchIconClickHandler={applyNameFilterHandler}
        style={{ width: '200px', border: '1px solid rgb(233, 236, 239)' }}
      />
      <APopover
        buttonComponent={(onClick, styles) => <AButton onClick={onClick} updatedStyle={filterCount > 0 ? highlightedStyle : styles}><CiFilter />Filter {filterCount > 0 && <>( {filterCount} )</>}</AButton>}
        menuComponent={(closeFilterForm) =>
          <AcademicPlansFilterForm 
            closeFilterForm={closeFilterForm} 
            filterForm={filterForm} 
            inputHandler={inputHandler} 
            applyFilterHandler={applyFilterHandler}
            removeFilterHandler={removeFilterHandler}
            filterCount={filterCount}
            businessAreaData={businessAreaData}
            setBusinessAreaData={setBusinessAreaData}
          />
        }

        maxHeight={'calc(100% - 150px)'}
        highlightedStyle={highlightedStyle}
        normalStyle={normalStyle}

      />
    </div>
  )
}

export default AcademicPlansSearchFilter