import React from "react";
import { boldLevel } from "./helperFile";
const style = {
    cursor:"pointer",
    display:"flex",
    

}

const LectureRowItem = ({ lectureItem, selectLectureHandler }) => {
  return (
    <div style ={style} className="m-1 p-1" onClick={() => selectLectureHandler(lectureItem)}>
      <span style ={boldLevel}>{lectureItem?.lectureName}</span> - <span className="ml-1">{lectureItem?.lecturePreview}</span>
    </div>
  );
};

export default LectureRowItem;
