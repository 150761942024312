import React, { useContext } from 'react'
import { PermissionContext, RolePermissions } from '../../../../../../appContext';
import { pages } from '../../../common/constant';
import { PermisionDenied } from '../../../common/commonComponents';
import PaymentGatewaysListing from './PaymentGatewaysListing';

const PaymentGatewaysConfigLandingPage = () => {

    const userPermissions = RolePermissions(useContext(PermissionContext), pages['paymentGatewayConfiguration']['id']);

    return (
        userPermissions
            ? <PaymentGatewaysListing />
            : <PermisionDenied />

    );
}

export default PaymentGatewaysConfigLandingPage;