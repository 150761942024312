import React from 'react'
import AButton from '../../../../common/form-fields-mui/AButton'
import { ReactComponent as CrossIcon } from 'assets/img/svg/CrossIcon.svg'
import { ReactComponent as CrossIconDisabled } from 'assets/img/svg/CrossIconDisabled.svg'
import PrintIdCardBtn from '../../printIDCard'
import { getPsidApplicationIdObjFromRowId } from '../constant'

const ActionsCellComponent = ({ params }) => {
  return (
    <div className='d-flex align-items-center'>
      <PrintIdCardBtn
        data={[getPsidApplicationIdObjFromRowId(params?.id)]}
        rowData={[params?.row]}
        customTiggerButton
        customTriggerButtonComponent={() => (
          <AButton
            variant='primary_filled'
            updatedStyle={{ fontSize: '12px', fontWeight: 600, lineHeight: '16px', height: '28px', borderRadius: '4px' }}
            disabled={!params?.api?.isRowSelectable(params?.id)}
            onClick={() => { }}
          >
            Preview & Print
          </AButton>
        )}
      />
      {/* {params?.api?.isRowSelectable(params?.id) ? (
        <CrossIcon className='ml-2' onClick={() => { }} />
      ) : (
        <CrossIconDisabled className='ml-2' />
      )} */}
    </div>
  )
}

export default ActionsCellComponent