import React, { useEffect, useState, useContext } from 'react'
import { Button, Card, Col, FormGroup, Input, Label, Navbar, NavbarBrand, Row } from 'reactstrap';
// import { getCookie, setCookie, eraseCookie } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import OtpInput from 'react-otp-input';
import BrandLogo1 from '../../../assets/img/brand/aakashlogo.png';
import BrandLogo2 from '../../../assets/img/brand/aakashlogo@2x.png';
import BrandLogo3 from '../../../assets/img/brand/aakashlogo@3x.png';
// import Banner from '../../../assets/img/brand/login_banner.svg';
// import { setUserLogedOut } from '../manage/common/services';
import Styles from './login.module.scss';
import CloseEye from 'assets/img/svg/eye_open.svg';
import CloseOpen from 'assets/img/svg/eye_close.svg';
import Eye from 'assets/img/svg/eye.svg';
import { failureToast, successToast } from '../../../views/pages/manage/common/utils/methods/toasterFunctions/function';
import Container from 'reactstrap/lib/Container';
import { commonMessages, regex } from '../manage/common/constant'
import { putpost } from 'services/utils';
import { endpoint } from '../manage/common/constant';
import { ConfirmationDialog } from '../manage/common/commonComponents'
import { generateOtp } from './otpScreen';
import Banner from '../../../assets/img/brand/login_banner.svg';
import ResetIcon from '../../../assets/img/icons/common/reset-icon-wo-bg.png';
import { SomethingWentWrongDispatch } from 'contexts/SomethingWentWrong';
import { Error } from 'components/ErrorPage/ErrorPage';
import { UserDetails } from 'appContext';
import { eraseCookie } from '../manage/common/utils/methods/commonMethods/utilityMethod';

const ResetPassword = ({ userId, oldPassword, setForgotPassword = () => { }, alreadyLoggedIn = false,
  setPasswordExpired = () => { }, isFirstTimeLogin, forgotPassword, isPasswordExpired, setPasswordResetScreen = () => { }, setFirstTimeLogin = () => { }, setUserCredentials = () => { } }) => {

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isError, setisError] = useState(false)
  const [error, setError] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [resetSuccess, setResetSuccess] = useState(false);
  const [userIdError, setUserIdError] = useState(false);
  const [otp, setOtp] = useState(false);
  const [isVpnError, setVpnError] = useState(false);

  const [otpsent, setOtpSent] = useState(false);
  const [otpError, setOtpError] = useState(false);

  const [user_Id, setUserId] = useState('')
  const [fetching, setFetching] = useState(false)
  const [counter, setCounter] = React.useState(60);

  const [showMessage, setShowMessage] = useState(true);
  const setIsSomethingWentWrong = React.useContext(SomethingWentWrongDispatch);
  const userDetails = useContext(UserDetails);

  const logout = () => {
    eraseCookie('token');
    eraseCookie('lastKnowPath');
  };


  const handleResetPassword = async () => {

    if (password !== confirmPassword) {
      setisError(true)
      setError('Password mismatch')
      return
    }
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%*+_><.|;:/\\/?-])[A-Za-z\d#$@!%*+_></.|;:/\\/?-]{8,12}$/.test(password)) {
      setisError(true)
      setError(commonMessages.passwordRule)
      return
    }

    if (!otp) {
      setOtpError(true);
      return
    }

    setLoading(true)

    const formData = {
      "userId": userId,
      "password": password,
      "clientId": "PHX",
      "confirmPassword": confirmPassword,
      "otpNum": Number(otp)
    }

    putpost(endpoint.resetPasswordWithOtp,
      (data) => {
        setLoading(false)
        if (data?.code === 200) {
          setResetSuccess(true)
          if(alreadyLoggedIn) {
            logout();
          }
        } else {
          failureToast(data['message']);
        }
      },
      (data) => {
        setLoading(false)
        failureToast(data['message']);
      },
      formData,
      'post'
    );
  }


  const resetAll = () => {
    setUserCredentials({});
    setFirstTimeLogin(false);
    setResetSuccess(false);
    setPassword('')
    setError('')
    setisError(false)
    setLoading(false)
    setConfirmPassword('')
    setForgotPassword()
    setPasswordExpired()
  }

  const sendOtp = async () => {
    if (password !== confirmPassword) {
      setisError(true)
      setError('Password mismatch')
      return
    }
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%*+_><.|;:/\\/?-])[A-Za-z\d#$@!%*+_></.|;:/\\/?-]{8,12}$/.test(password)) {
      setisError(true)
      setError(commonMessages.passwordRule)
      return
    }
    setFetching(true)
    const req = {
      "purpose": "RESET_PASSWORD",
      "userId": userId,
      "clientId": "PHX",
    }

    setOtp('')
    const isOtpSent = await generateOtp(req);

    if (isOtpSent && isOtpSent?.code == 200) {
      setFetching(false)
      setOtpSent(true)
      setCounter(60)
      successToast(isOtpSent?.data ?? 'OTP sent Sucessfully')
    } else {
      setFetching(false)
    }
  }

  //logic for vpn
  useEffect(() => {
    setTimeout(() => {
      if (fetching) {
        setShowMessage(false);
      }
    }, 60000);
  }, [fetching]);

  useEffect(() => {
    setTimeout(() => {
      if (isLoading) {
        setShowMessage(false);
      }
    }, 6000);
  }, [isLoading]);

  useEffect(() => {
    if (!showMessage && (isLoading || fetching)) {
      setVpnError(true)
      setFetching(false)
      setLoading(false)
      setShowMessage(true)
    }
  }, [showMessage])
  ///end logic for vpn


  React.useEffect(() => {
    if (otpsent && counter > 0) {
      const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [counter, otpsent]);


  const resetVpn = () => {
    setConfirmPassword('');
    setPassword('');
    setOtp('');
    setOtpSent(false)
    setFetching(false)
    setLoading(false)
    setVpnError(false)
  }

  return (<>
    {isVpnError ?
      <Error refreshPage={() => resetVpn()} /> :
      <div className={Styles.login__page__container}>
        <div className="text-center">
          <Row className="justify-content-center" style={{ margin: '0' }}>
            {alreadyLoggedIn ?
              <Col xs={12} sm={12} md={6} lg={6} style={{ padding: 0, margin: 0 }}>
                <Card className={Styles.resetpw__page__left}>
                  <div>
                    <Row style={{ margin: '0' }}>
                      <img src={ResetIcon} alt="" className={Styles.resetpw__banner__image} />
                    </Row>
                    <Row className={Styles.resetpw__banner__content}>
                      {userDetails && 
                      <h1 className={Styles.resetpw__banner__heading}>Hello, {userDetails.firstName} {userDetails.lastName}
                      <br />
                          <span className={Styles.resetpw__banner__span}>{userDetails.email}</span>
                      </h1>}
                    </Row>
                  </div>
                </Card>
              </Col> :
              <Col xs={12} sm={12} md={6} lg={6} style={{ padding: 0, margin: 0, backgroundColor: '#00b0f5' }}>
                <Card className={Styles.login__page__left}>
                  <div>
                    <Row className={Styles.login__banner__content} style={{ margin: '0' }}>
                      <h1 className={Styles.login__banner__heading}>
                        Welcome to the Phoenix - ERP 2.0
                        <br />
                        <span className={Styles.login__banner__span}>Login to explore new experience.</span>
                      </h1>
                    </Row>
                    <Row style={{ margin: '0' }}>
                      <img src={Banner} alt="" className={Styles.login__banner__image} />
                    </Row>
                  </div>
                </Card>
              </Col>
            }
            <Col xs={12} sm={12} md={6} lg={6} style={{ padding: '0', margin: '0', backgroundColor: '#ffffff' }}>
              {/* <div className={Styles.form_container}> */}
              <div className={Styles.login__page__right}>
                {!alreadyLoggedIn && <div className={Styles.logo__brand__aakash__block}>
                  <img
                    src={BrandLogo1}
                    srcSet={`${BrandLogo2}, ${BrandLogo3}`}
                    className={Styles.brand__logo__aakash}
                    alt="Brand"
                  />
                </div>}
                <div className={`${Styles.form__control} mt-5`}>
                  <div className={Styles.welcome__header__block}>
                    {
                      isPasswordExpired && <h2>Your Password has been expired.</h2>
                    }
                    <h2 className={Styles.reset__header}>
                      Reset Password
                    </h2>
                    {userId || user_Id && <span className={`pt-n5 ${Styles.user_id}`}>{`User ID : ${userId}`}</span>}
                    <div className={`mt-3 d-flex justify-content-between ${Styles.w_80}`}>
                      <div className={Styles.completed_step} />
                      <div className={otpsent ? Styles.completed_step : Styles.incomplete_step} />
                    </div>
                    <div className={`${Styles.login__input__info} ${Styles.login__input__note} text-dark d-flex flex-row`}>{commonMessages.passwordRule}</div>
                  </div>
                  <div className="mt-4">
                    <FormGroup>
                      {/* {userIdError ? (
                  <div className={Styles.login__input__error}><span>Please enter a correct user id.</span></div>
                ) : null} */}

                      <Label className={`${Styles.login__input__label} form-control-label fw-bold mt-4`}>
                        New Password
                      </Label>
                      <div
                        className={Styles.login__input__box}
                        style={isError ? { borderColor: '#f5365c' } : { borderColor: '#a1aeb7' }}
                      >
                        <Input
                          disabled={isLoading}
                          type={showPassword ? 'text' : 'password'}
                          onChange={e => setPassword(e.target.value)}
                          maxLength={100}
                          className={`${Styles.login__text__box} ${isError ? Styles.error : ''}`}
                          invalid={isError}
                          valid={!isError}
                          placeholder={'New Password'}
                          required
                          onFocus={() => { setisError(false); setError('') }}
                        />
                        <span className={Styles.login__input__icons}>
                          <Button
                            onClick={() => setShowPassword(!showPassword)}
                            className={Styles.login__input__eye__button}
                            style={showPassword ? { color: '#00b0f5' } : { color: '#a1aeb7' }}
                          >
                            <img src={showPassword ? CloseOpen : password ? CloseEye : Eye} />
                          </Button>
                        </span>
                      </div>

                      <Label className={`${Styles.login__input__label} form-control-label fw-bold mt-4`}>
                        Confirm Password
                      </Label>
                      <div
                        className={Styles.login__input__box}
                        style={isError ? { borderColor: '#f5365c' } : { borderColor: '#a1aeb7' }}
                      >
                        <Input
                          disabled={isLoading}
                          type={showConfirmPassword ? 'text' : 'password'}
                          onChange={e => setConfirmPassword(e.target.value)}
                          maxLength={100}
                          className={`${Styles.login__text__box} ${isError ? Styles.error : ''}`}
                          invalid={isError}
                          valid={!isError}
                          placeholder={'Confirm Password'}
                          required
                          onFocus={() => { setisError(false); setError('') }}
                        />
                        <span className={Styles.login__input__icons}>
                          <Button
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            className={Styles.login__input__eye__button}
                            style={showConfirmPassword ? { color: '#00b0f5' } : { color: '#a1aeb7' }}
                          >
                            <img src={showConfirmPassword ? CloseOpen : confirmPassword ? CloseEye : Eye} />
                          </Button>
                        </span>
                      </div>

                      {isError && (
                        <div className={Styles.login__input__error}>{error}</div>
                      )}

                      {!otpsent && <Button
                        className={Styles.login__button}
                        disabled={isLoading || fetching}
                        onClick={e => {
                          // handleResetPassword()
                          sendOtp()
                          // handleAuthentication();
                          // document.body.classList.remove("bg-default");
                          // prop.history.push('/admin')
                        }}
                      >
                        {!fetching ? (
                          <span>Generate OTP</span>
                        ) : (
                          <i className="fas fa-spinner fa-spin" aria-hidden="true"></i>
                        )}
                      </Button>}

                      {
                        otpsent && <>
                          <div>
                            <Label className={`${Styles.login__input__label} form-control-label fw-bold mt-4`}>
                              OTP
                            </Label>
                            <OtpInput
                              value={otp}
                              onChange={setOtp}
                              numInputs={6}
                              separator={<span>   </span>}
                              inputStyle={{
                                width: '50px',
                                borderRadius: '10px',
                                height: '50px',
                                marginRight: '15px'
                              }}
                              isDisabled={isLoading}
                              errorStyle={{ borderColor: 'red' }}
                              hasErrored={otpError}
                              onFocus={() => setOtpError(false)}
                              isInputNum={true}
                            />

                          </div>
                          {otpError && (
                            <div className={`${Styles.login__input__left__error} mt-1`}>{"Please enter otp"}</div>
                          )}
                          <Button
                            className={`${Styles.login__button} BTNbgChange`}
                            disabled={isLoading || fetching || counter == 0}
                            onClick={e => {
                              handleResetPassword()
                              // handleAuthentication();
                              // document.body.classList.remove("bg-default");
                              // prop.history.push('/admin')
                            }}
                          >
                            {!isLoading ? (
                              <span>Reset Password</span>
                            ) : (
                              <i className="fas fa-spinner fa-spin" aria-hidden="true"></i>
                            )}
                          </Button>

                          {counter !== 0 && <div className='mt-2 text-center'>
                            <Label className={`${Styles.login__input__label} form-control-label fw-bold mt-2 ${Styles.resendText}`}>Resend OTP in {counter} Seconds</Label>
                          </div>}
                          {counter == 0 && <div className={`mt-3 text-center text-underline ${Styles.resendBtn}`} onClick={counter == 0 ? () => sendOtp() : null}>
                            <span>{fetching && <i className="fas fa-spinner fa-spin" aria-hidden="true"></i>}Resend OTP</span>
                          </div>}
                        </>
                      }
                    </FormGroup>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </Col>
          </Row>
        </div>
      </div>}
    {/* <Col md={6} sm={0} /> */}
    <ConfirmationDialog
      isOpen={resetSuccess}
      onAgree={resetAll}
      type="RESET"
      msg="Tap on Back To Login button to log in using your new password"
      headerMsg="Your Password has been reset"
      popupSymbol={<img src={require("../../../assets/img/svg/resetSuccess.svg")} className="m-5" width={'100%'} />} />
  </>
  )
}

export default ResetPassword
