import React, { useContext, useState } from 'react';
import { Card, CardHeader, Input, Container, Button, Table, Row, Col } from 'reactstrap';
import { ToExcel } from 'views/pages/manage/common/commonComponents';
import { failureToast, successToast } from '../../../common/utils/methods/toasterFunctions/function';
import { useHistory } from 'react-router-dom';
import { MasterHeaderAuto, Rows } from '../../../common/commonComponents/masterHeader/masterHeader';
import CommonInput from '../../../common/formFeilds/input/commonInput';
import CustomLoader from '../../../common/commonComponents/Loader/loader';
// import {CustomButton} from '../../../../../../components/FormComponent/Button';
import CustomButton from 'components/CustomButton';
import { putpost, putpost2 } from 'services/http';
import { endpoint } from 'views/pages/manage/common/constant';
import { GetPagination } from '../../../common/commonComponents/pagination/pagination';
import { RolePermissions, PermissionContext } from 'appContext';
import { pages } from 'views/pages/manage/common/constant';
import { PermisionDenied } from 'views/pages/manage/common/commonComponents';
import { openInNewTab } from '../../../common/utils/methods/commonMethods/utilityMethod';

const PriceZoneSearch = props => {
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [classArray, setclassArray] = useState([]);
  const [serverData, setServerData] = useState([]);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const pageSize = 10;
  const Permission = useContext(PermissionContext);
  const userPermissions = RolePermissions(Permission, pages['pricingZone']['id']);

  const [searchValue, setSearchValue] = useState({
    pricingZoneName: '',
    pricingZoneDesc: '',
    businessArea: '',
  });

  const headerList = [
    { name: 'Pricing Zone Name' },
    { name: 'Pricing Zone Description' },
    { name: 'Status' },
    { name: 'Action' },
  ];

  const listToRender = ['pricingZoneName', 'pricingZoneDesc', 'status'];

  const excelDetails = {
    'Pricing Zone Name': 'pricingZoneName',
    'Pricing Zone Description': 'pricingZoneDesc',
    Status: 'status',
    'Effective Date Area': 'effectiveDate',
  };

  const dataToExport = serverData?.map(item => {
    return {
      ...item,
      pricingZoneName: item.pricingZoneName,
      pricingZoneDesc: item.pricingZoneDesc,
      status: item.status,
      effectiveDate: item.effectiveDate,
    };
  });

  const submitHandler = () => {
    if (
      !searchValue.pricingZoneName &&
      !searchValue.pricingZoneName.trim() &&
      !searchValue.pricingZoneDesc &&
      !searchValue.pricingZoneDesc.trim() &&
      !searchValue.businessArea &&
      !searchValue.businessArea.trim()
    ) {
      failureToast('Please input some value for search parameters');
      return;
    }
    onSearch();
  };

  const onSearch = obj => {
    setIsLoading(true);
    setResult(null);
    const searchData = obj ?? {
      pricingZone: searchValue.pricingZoneName ? searchValue.pricingZoneName : null,
      discription: searchValue.pricingZoneDesc ? searchValue.pricingZoneDesc : null,
      bussenessArea: searchValue.businessArea ? searchValue.businessArea : null,
    };
    putpost(
      endpoint.pricing_zone.search,
      data => {
        fetchData(data['data']);
        setIsLoading(false);
      },
      data => {
        setServerData([]);
        failureToast(data['message']);
        setIsLoading(false);
      },
      searchData,
      'post'
    );
  };

  const fetchData = async data => {
    setResult(data);
    setServerData(data);
    let n = pagination;
    if (data.length > pageSize) {
      n['nextPage'] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n['totalPage'] = Math.floor(data.length / pageSize);
    if (data.length % pageSize != 0) {
      n['totalPage'] += 1;
    }
    setpagination(n);
    setIsLoading(false);
    setclassArray(data.slice(pagination.currentPage * pageSize - pageSize, pagination.currentPage * pageSize) || []);
  };

  const deleteRow = id => {
    if (window.confirm('Are you sure you want to delete this pricing Zone?')) {
      putpost2(
        `${endpoint.pricing_zone.delete}/${id}`,
        data => {
          successToast(data['message']);
          if (searchValue.pricingZone || searchValue.pricingZoneDesc || searchValue.businessArea) {
            onSearch();
          }
        },
        data => {
          failureToast(data['message']);
        },
        'Delete'
      );
    }
  };

  const reset = () => {
    setSearchValue({
      pricingZoneName: '',
      pricingZoneDesc: '',
      businessArea: '',
    });
    setResult(null);
    setServerData([]);
    setclassArray([]);
  };

  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0 d-flex justify-content-between">
                <h3 className="mb-0 ">Pricing Zone Search</h3>
                {/* <CustomButton
									className={'floatRight mx-1 '}
									onClick={() => history.push(`/admin/PricingZone/new`)}
									btnType={'new'}
									text={'Add New Zone'}
								/> */}
                <CustomButton
                  className={'floatRight mx-1'}
                  content={'Add New Zone'}
                  permissionType={'C'}
                  icon={true}
                  permissionSet={userPermissions}
                  onClick={() => history.push(`/admin/PricingZone/new`)}
                />
              </CardHeader>
              <hr />
              <Row className="p-4">
                <Col className="mb-3">
                  <CommonInput
                    type={'text'}
                    defaultValue={searchValue.pricingZoneName}
                    label={'Pricing Zone Name'}
                    placeHolder={'Pricing Zone Name'}
                    onChange={val =>
                      setSearchValue({
                        ...searchValue,
                        pricingZoneName: val,
                      })
                    }
                  />
                </Col>
                <Col className="mb-3">
                  <CommonInput
                    type={'text'}
                    defaultValue={searchValue.pricingZoneDesc}
                    label={'Pricing Zone Description'}
                    placeHolder={'Pricing Zone Description'}
                    onChange={val =>
                      setSearchValue({
                        ...searchValue,
                        pricingZoneDesc: val,
                      })
                    }
                  />
                </Col>
                <Col className="mb-3">
                  <CommonInput
                    type={'text'}
                    defaultValue={searchValue.businessArea}
                    label={'Business Area'}
                    placeHolder={'Business Area'}
                    onChange={val =>
                      setSearchValue({
                        ...searchValue,
                        businessArea: val,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row className={'justify-content-end pr-5 pb-4'}>
                {/* <CustomButton
									className={'floatRight mx-1 '}
									loading={isLoading}
									onClick={() => submitHandler()}
									btnType={'Search'}
									text={'Search'}
								/> */}
                <CustomButton
                  className={'floatRight mx-1'}
                  content={'Search'}
                  permissionType={'S'}
                  icon={true}
                  permissionSet={userPermissions}
                  onClick={() => submitHandler()}
                />
                {/* <Button size="md" type="button" className="btn" onClick={reset}>
									<span className="btn-inner--text">Clear All</span>
								</Button> */}
                <CustomButton
                  type="reset"
                  className={'floatRight mx-1'}
                  content={'Clear All'}
                  permissionType={'R'}
                  permissionSet={userPermissions}
                  icon={true}
                  onClick={reset}
                />
              </Row>
            </Card>

            {isLoading ? (
              <Card className="mt-4">
                <CustomLoader apiLoader={isLoading} />
              </Card>
            ) : classArray.length > 0 ? (
              <Card className="mt-4">
                <CardHeader className="d-flex justify-content-between align-items-center">
                  <h3>Pricing Zone</h3>
                  <div className="d-flex flex-row  justify-content-between">
                    {serverData.length ? (
                      <ToExcel
                        data={dataToExport}
                        detailObj={excelDetails}
                        name="Pricing Zone"
                        filename="Pricing Zone"
                      />
                    ) : null}
                  </div>
                </CardHeader>

                <div id="questionTable" className="table-responsive">
                  {result ? (
                    <Table className="align-items-center table-flush ">
                      <MasterHeaderAuto headerList={headerList} />
                      <tbody className="list">
                        {classArray &&
                          classArray.length > 0 &&
                          classArray.map(el => {
                            return (
                              <Rows data={el} listToBeRendered={listToRender} key={el.id}>
                                <td className="text-center  white-breakSpace">
                                  {/* <Button
																		color="info"
																		size="sm"
																		type="button"
																		onClick={() =>
																			openInNewTab(`/view/${el.id}`)
																		}
																	>
																		<i className="fas fa-eye" />
																	</Button> */}
																	<CustomButton
																		permissionType={'R'}
																		icon={true}
																		forTable={true}
																		permissionSet={userPermissions}
																		onClick={() =>
																			openInNewTab(`/view/${el.id}`)
																		}
																	/>
																	{/* <Button
																		color="info"
																		size="sm"
																		type="button"
																		onClick={() =>
																			openInNewTab(`/edit/${el.id}`)
																		}
																	>
																		<i className="fas fa-edit" />
																	</Button> */}
																	<CustomButton
																		permissionType={'U'}
																		icon={true}
																		forTable={true}
																		permissionSet={userPermissions}
																		onClick={() =>
																			openInNewTab(`/edit/${el.id}`)
																		}
																	/>
																	{/* <Button
																		color="info"
																		size="sm"
																		type="button"
																		onClick={() => deleteRow(el.id)}
																	>
																		<i className="fas fa-trash" />
																	</Button> */}
																	<CustomButton
																		permissionType={'D'}
																		icon={true}
																		forTable={true}
																		permissionSet={userPermissions}
																		onClick={() => deleteRow(el.id)}
																	/>
																</td>
															</Rows>
														);
													})}
											</tbody>
										</Table>
									) : null}
									<div>
										{pagination.totalPage > 1 ? (
											<GetPagination
												setclassArray={setclassArray}
												pagination={pagination}
												setpagination={setpagination}
												pageSize={pageSize}
												state={result}
											/>
										) : null}
									</div>
								</div>
							</Card>
						) : null}
					</div>
				</Row>
			</Container>
		</>
	);
};
export default PriceZoneSearch;
