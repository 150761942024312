import React from 'react';
import CustomButton from "./customButton";
import CustomToggle from "./customToggle";
import ActionButton from "./actionButton";
import moment from "moment";
const TableList = ({listDetails, item,actionHandler,index, loaderDelete, actionExist, permissionSet}) => {

  const getDisplayValue = (masterArray, property, id) => {
    try {
      const value  = masterArray.find(item => item.id == id);
      return value ? value[property] : '';
    } catch (err) {
      console.log('Error :', err);
    }
  }

  const typeHandler = (details,item) => {
    try{
      switch (details.type){
        case 'DATE':
         return  details.format ? moment(item.effectiveDate).format('DD-MM-YYYY') :  item.effectiveDate;
          break;
        case 'BUTTON':
          return <CustomButton details={details} rowDetails={item}/>
        case 'TOGGLE':
          return <CustomToggle  details={details} rowDetails={item}/>
        case 'ACTION' :
          return <ActionButton actionExist={actionExist} key={'actionButton'+item.id} details={details} rowDetails={item} actionHandler={actionHandler} index={index} loaderDelete={loaderDelete} permissionSet={permissionSet}/>
        default:
          break;
      }

    }catch (e) {
      console.log('Error',e);
    }
  }

  // console.log(permissionSet)

  return (
    <tr key={'tableRecords'+index}>
      {
        Object.keys(listDetails).map((el, index) => {
          if (el == 'action') {
            if((permissionSet?.includes('U') || permissionSet?.includes('D')) || (permissionSet?.includes('C') && actionExist)){
              return <td className="text-center  white-breakSpace" key={'tableList' + index}>
              {
                listDetails[el].normal ? item[el] : listDetails[el].hasOwnProperty('type') ? typeHandler(listDetails[el], item) : getDisplayValue(listDetails[el].master, listDetails[el].displayValue, item[listDetails[el].idName])
              }
            </td>
            }else{
              return null
            }
          } else {
            return <td className="text-center  white-breakSpace" key={'tableList' + index}>
              {
                listDetails[el].normal ? item[el] : listDetails[el].hasOwnProperty('type') ? typeHandler(listDetails[el], item) : getDisplayValue(listDetails[el].master, listDetails[el].displayValue, item[listDetails[el].idName])
              }
            </td>
          }

        })
      }
    </tr>
  )
}

export default TableList;
