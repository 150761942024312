import React, { useContext, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import SearchFilter from './SearchFilter';
import ListComponent from './ListComponent';
import { fetchAllPostPromisedData, updateRequest } from '../../../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint, pages } from '../../../../common/constant';
import ALoader from '../../../../common/a-loader';
import { RolePermissions, PermissionContext } from 'appContext';
import { PermisionDenied } from '../../../../common/commonComponents';
import { ReactComponent as BackIcon } from "assets/img/svg/BackButton.svg";
import { useHistory } from 'react-router';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'
import Filter from './Filter';
const initialFilterForm = {
  materialId: "",
  status: "",
  plantId: "",
  type: "",
};

const getNameSearchReqBody = (userInput) => ({ materialId: userInput });
const MaterialMaster = () => {

  const history = useHistory();
  const userPermissions = RolePermissions(useContext(PermissionContext), pages['materialMaster']['id']);

  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [listApiLoader, setListApiLoader] = useState(false);
  const [nameFilterPayload, setNameFilterPayload] = useState('');
  const [filterForm, setFilterForm] = useState(initialFilterForm)

  // this same method can be used for page change
  const fetchList = async (page = 0, reqBody = getNameSearchReqBody('')) => {
    setListApiLoader(true);

    const res = await fetchAllPostPromisedData(`${endpoint?.materialMaster?.searchMaterialMaster}?offset=${page}`, reqBody, 'post')
    if (res?.code === 200) {
      setRowCount(res?.data?.totalRecords);
      setRows(res?.data?.materialMasterList);
    } else {
      setRowCount(0);
      setRows([]);
    }

    setListApiLoader(false);
  }
  const filterFormHandler = (value, key) => setFilterForm(prev => ({ ...prev, [key]: value }));

  const nextButtonHandler = async (newPage) => {
    setPage(newPage);
    //const reqBody = getNameSearchReqBody(nameFilterPayload);
    //await fetchList(newPage, reqBody);
    await fetchList(newPage, {...updateRequest(filterForm), materialId:nameFilterPayload});

  }

  useEffect(() => {
    fetchList();
  }, [])

  const applyNameFilter = async (userInput) => {
    const reqBody = getNameSearchReqBody(userInput);
    setNameFilterPayload(userInput);
    setPage(0);
    await fetchList(0, {...updateRequest(filterForm), ...reqBody});
  }

 const applyFilterHandler = async (filterForm) => {
  setPage(0);
  console.log(nameFilterPayload)
  setFilterForm(filterForm)
  await fetchList(0, {...updateRequest(filterForm), materialId:nameFilterPayload});
 }

 const removeFilterHandler = async (filterForm) => {
  setPage(0);
  setFilterForm(initialFilterForm)
  await fetchList(0, {...(initialFilterForm),materialId:nameFilterPayload});
 }


  return (
    userPermissions ? (
      <div className={`remove-container-top-space ${styles.page_container}`}>
        {listApiLoader && <ALoader position='fixed' />}
        <div className={`${styles.table_card}`}>
          <div className='d-flex align-items-center justify-content-between'>
            <div className='heading-4 color-dark d-flex align-items-center'>
              <IconButtonWrapper onClick={() => history.goBack()}><BackIcon /></IconButtonWrapper>
              <div className='ml-2'>Material master</div>
            </div>
            <div className='d-flex'> 
            <SearchFilter
              nameFilterPayload={nameFilterPayload}
              applyNameFilter={applyNameFilter}
            />
            <Filter
             filterFormHandler = {filterFormHandler}
             filterForm={filterForm}
             applyFilter={applyFilterHandler}
             removeFilterHandler = {removeFilterHandler}
            />
            </div>
         
          </div>
          <ListComponent
            loader={listApiLoader}
            rows={rows}
            currentPage={page}
            nextButtonHandler={nextButtonHandler}
            rowCount={rowCount}
          />
        </div>
      </div>
    ) : (
      <PermisionDenied />
    )
  )
}

export default MaterialMaster