import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import Loader from '../loader';
import EditButton from "components/FormComponent/Button/Edit/index";
import CustomButton from 'components/CustomButton';

const ActionButton = ({ details, rowDetails, actionHandler, index, loaderDelete, actionExist, permissionSet }) => {

  return (
    <Fragment>
      {
        details.button.map((item, actionIndex) => (

          // <EditButton 
          //   color={item.color}
          //   key={'actionButton'+actionIndex}
          //   onClick={() => actionHandler(item.actionType, index)}
          //   disabled={actionExist}
          //   text={loaderDelete?.loader === true && loaderDelete?.index === index && loaderDelete?.type === item.actionType ?
          //     <Loader
          //       loaderHeight={20}
          //       loaderWidth={20}
          //       color={'#ffffff'}
          //     /> :
          //     <i className={item.icon}/>
          //   }
          // />

          // <Button
          //   color={item.color}
          //   type={'button'}
          //   size={'sm'}
          //   key={'actionButton'+actionIndex}
          //   onClick={() => actionHandler(item.actionType, index)}
          //   disabled={actionExist}
          // >
          //   {loaderDelete?.loader === true && loaderDelete?.index === index && loaderDelete?.type === item.actionType ?
          //     <Loader
          //       loaderHeight={20}
          //       loaderWidth={20}
          //       color={'#ffffff'}
          //     /> :
          //     <i className={item.icon}/>
          //   }
          // </Button>
          <CustomButton
            color={item.color}
            key={'actionButton' + actionIndex}
            className={'mx-1'}
            content={loaderDelete?.loader === true && loaderDelete?.index === index && loaderDelete?.type === item.actionType ?
              <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : ''
              // <i className={item.icon} />
            }
            icon={true}
            permissionType={item.permisionRequired}
            forTable={true}
            permissionSet={permissionSet}
            onClick={() => actionHandler(item.actionType, index)}
            disabled={actionExist}
          />
        )
        )
      }
    </Fragment>
  )
}

export default ActionButton;
