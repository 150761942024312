export const regex = {
  ipAddress:
    /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  macAddress: /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/,

  fileWithoutSpecialCharacter: /[ `!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?~]/,
  alphaNumericWithSpaceAndUnderscore: /^[A-Za-z0-9 _]*$/,
  alphaNumeric: /^[a-zA-Z0-9]*$/,
  numeric: /^[0-9]*$/,
  numericDecimal: /(^$|^\d+\.?\d*$)/,
  pricingUploadDescription : /^[A-Za-z0-9 _:/()-]*$/,
  alphabets: /^[a-zA-Z]*$/,
  regionAllowedSpecialChars: /^[a-zA-Z0-9/\-_]*$/,
  projectMasterDesc: /^[a-zA-Z0-9-_ ]*$/,
  roleName: /^[a-zA-Z0-9_]*$/,
	courseInfoRegex: /^[a-zA-Z0-9 /\-_)(+./)]*$/,
  roleDescription:/^[A-Za-z0-9 _?()]*$/,
  alphaNumericWithdashAndUnderscore: /^[a-zA-Z0-9-_]*$/,
  alphabetsWithDot: /^[a-zA-Z. ]*$/,
  email:/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
  securityGroup: /^[A-Za-z0-9 _/-]*$/,
  ticketDescription : /^[A-Za-z0-9 _()#-]*$/,
  alphabetsWithSpace : /^[a-zA-Z ]*$/,
  capsWithNumeric : /^[A-Z0-9]*$/,
  stockIssueTnxId : /(^$|^([Ss]{1})[0-9]*$)/,
  stockIssueConsumptionId : /(^$|^([Cc]{1})[0-9]*$)/,
  stockReturnId : /(^$|^([Rr]{1})[0-9]*$)/,
  stockConsumptionReturnId: /(^$|^([BbRr]{1,2})[0-9]*$)/,
  stockReversalId: /(^$|^([SsRr]{1,2})[0-9]*$)/,
  numericPlusPhoneNumber: /(^$|^[\d+]{0,13})$/,
  validIndianPhoneNumber: /^(\+91[\-\s]?)?[0]?(91)?[789]{1}\d{9}$/,
  percentage: /^(\d{0,2}(\.\d{0,2})?|100(\.0{0,2})?)$/,
};
