import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Card, CardBody } from "reactstrap";
import AcademicDetailsCard from "./AcademicDetailsCard";
import { PermissionContext, RolePermissions } from "appContext";
import { endpoint, pages } from "../../common/constant";
import { PermisionDenied } from "../../common/commonComponents";
import { fetchAllPromisedData } from "../../common/utils/methods/commonMethods/utilityMethod";
import "./index.scss";
import StudentInformation from "./studentInformation/studentInformation";
import PaymentSummary from "./paymentSummary";
import BatchDetails from "./batchDetails/BatchDetails";
import Results from "./Results";
import ALoader from "views/pages/manage/common/a-loader/index.jsx";
import StockDetails from "./stockDetails/StockDetails";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper";
import { ReactComponent as BackIcon } from "../../../../../assets/img/svg/BackButton.svg";

const Tab = ({ isActive, onClick, children }) => (
  <div className={isActive ? "active" : ""} onClick={onClick}>
    {children}
  </div>
);

const StudentDetails = () => {
  const studentDashboardPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["studentDashboard"]["id"]
  );
  const batchDetailsPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["studentDashboardBatchDetails"]["id"]
  );
  const studentInformationPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["studentDashboardStudentInfo"]["id"]
  );
  const resultsPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["studentDashboardResults"]["id"]
  );

  const stockDetailsPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["studentDashboardStockDetails"]["id"]
  );
  const paymentSummaryPermission = RolePermissions(
    useContext(PermissionContext),
    pages["studentDashboardPaymentDetails"]["id"]
  );
  

  const history = useHistory();
  const { psid } = useParams();

  const [fetchApplicationsLoader, setFetchApplicationsLoader] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [studentApplicationDetails, setStudentApplicationDetails] = useState(
    []
  );
  const [studentDetails, setStudentDetails] = useState({});
  const [currentApplicationDetails, setCurrentApplicationDetails] =
    useState(null);

  const [activeTab, setActiveTab] = useState(
    (batchDetailsPermissions && "batchDetails") ||
      (studentInformationPermissions && "studentInformation") ||
      (resultsPermissions && "results") ||
      (stockDetailsPermissions && "stockDetails") ||
      ""
  );

  const fetchApplications = async () => {
    setFetchApplicationsLoader(true);
    const applicationDetailsResponse = await fetchAllPromisedData(
      `${endpoint.studentDetails.getAllApplicationByPsid}/${psid}`,
      true
    );

    if (applicationDetailsResponse?.code === 200) {
      setStudentApplicationDetails(applicationDetailsResponse?.data);
    } else setStudentApplicationDetails([]);
    setFetchApplicationsLoader(false);
  };

  const fetchStudentDetails = async () => {
    const studentDetailsResponse = await fetchAllPromisedData(
      `${endpoint?.studentDetails?.getStudentByPsid}/${psid}`,
      true
    );
    if (studentDetailsResponse?.code === 200) {
      setStudentDetails(studentDetailsResponse?.data);
    }
  };

  const fetchData = () => {
    fetchApplications();
    fetchStudentDetails();
  };

  useEffect(() => {
    fetchData();
  }, [psid]);

  useEffect(() => {
    setCurrentApplicationDetails(studentApplicationDetails?.[0]);
  }, [studentApplicationDetails]);

  const renderActiveTab = () => {
    switch (activeTab) {
      case "studentInformation":
        return <StudentInformation studentId={psid} />;
      case "batchDetails":
        return <BatchDetails />;
      case "results":
        return <Results />;
      case "stockDetails":
        return <StockDetails />;
      case "paymentSummary":
        return (
          <PaymentSummary
            currentApplicationDetails={currentApplicationDetails}
          />
        );
      default:
        return null; // or a default component or message
    }
  };

  const tabs = [
    {
      permission: studentInformationPermissions,
      label: "Student information",
      key: "studentInformation",
    },
    {
      permission: batchDetailsPermissions,
      label: "Batch details",
      key: "batchDetails",
    },
    {
      permission: resultsPermissions,
      label: "Results",
      key: "results",
    },
    {
      permission: stockDetailsPermissions,
      label: "Stock details",
      key: "stockDetails",
    },
    {
      permission: paymentSummaryPermission,
      label: "Payment summary",
      key: "paymentSummary",
    },
  ];

  return studentDashboardPermissions ? (
    <div className="mx-3 d-flex flex-column" style={{ position: "relative" }}>
      {apiLoader && <ALoader position="fixed" />}
      <div className="mb-2" style={{ fontSize: "16px" }}>
        Student details/
        {(studentApplicationDetails &&
          studentApplicationDetails[0]?.studentName) ||
          "-"}
      </div>
      <Card className="mb-3">
        <CardBody>
          <div className="d-flex align-items-center">
            <IconButtonWrapper
              className="mr-2"
              onClick={() => history.goBack()}
            >
              <BackIcon />
            </IconButtonWrapper>
            <h3 className="mb-0" style={{ fontSize: "20px" }}>
              Student details search
            </h3>
          </div>
        </CardBody>
      </Card>
      <div className="d-flex">
        <div
          className="align-self-start"
          style={{
            marginRight: "1%",
            width: "30%",
          }}
        >
          <AcademicDetailsCard
            activeTab={activeTab}
            psid={psid}
            studentApplicationDetails={studentApplicationDetails}
            studentDetails={studentDetails}
            setApiLoader={setApiLoader}
            loader={fetchApplicationsLoader}
            photoKey={studentApplicationDetails?.[0]?.photoKey}
            fetchApplications={fetchApplications}
            fetchStudentDetails={fetchStudentDetails}
            setCurrentApplicationDetails={setCurrentApplicationDetails}
          />
        </div>
        <div style={{ flex: 1 }}>
          {batchDetailsPermissions ||
          studentInformationPermissions ||
          resultsPermissions ||
          stockDetailsPermissions ? (
            <Card>
              <CardBody className="d-flex flex-column">
                <div className="heading-4 student-dashboard-tabs gap-xl">
                  {tabs.map(
                    ({ permission, label, key }) =>
                      permission && (
                        <Tab
                          key={key}
                          isActive={activeTab === key}
                          onClick={() => setActiveTab(key)}
                        >
                          {label}
                        </Tab>
                      )
                  )}
                </div>

                {renderActiveTab()}
              </CardBody>
            </Card>
          ) : (
            <PermisionDenied
              msg={"You don't have permission to access this section."}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <PermisionDenied />
  );
};

export default StudentDetails;
