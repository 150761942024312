/* eslint-disable */
import React, { useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import {
  TreeItem,
  TreeView,
} from "views/pages/manage/common/commonComponents/tree";

import { MinusSquare, PlusSquare, CloseSquare } from "./SvgIcon";
import { ReactComponent as HorizontalLine } from "../../../../../../assets/img/svg/HorizontalLine.svg";
import CustomTreeItemList from "./CustomTreeItemList";

const StyledTreeItem = styled((props) => (
  <TreeItem ContentComponent={CustomTreeItemList} {...props} />
))(({ theme }) => ({
  // [`& .${treeItemClasses.iconContainer}`]: {
  //   "& .close": {
  //     opacity: 0.3,
  //   },
  // },
  // [`& .${treeItemClasses.group}`]: {
  //   marginLeft: 15,
  //   paddingLeft: 18,
  //   borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.4)}`,
  // }, [`& .${treeItemClasses.content}`]: {
  //   marginBottom: 8,
  // },
}));

export default function TreeViewByList({
  subjectChapters,
  setSubjectChapters,
  addHandler,
  expended,
  deleteHandler
}) {
  // const [subjectChapters, setSubjectChapters]  = useState({}) ;
  // const nodeMutator = (nodeData) => {
  //   debugger;
  //   switch (nodeData?.nodeType) {
  //     case "Chapter":
  //       let temp = { ...subjectChapters };
  //       temp.tableOfContent.forEach((chapter) => {
  //         if (chapter.key === nodeData.key) {
  //           debugger;
  //           chapter["isAdded"] = true;
  //           addHandler(nodeData);
  //         }
  //       });
  //       console.log(temp);
  //       setSubjectChapters(temp);
  //       break;
  //     case "Topic":
  //       break;

  //     default:
  //       break;
  //   }
  // };
  return (
    <TreeView
      aria-label="customized"
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={<CloseSquare />}
      // expanded = {expended}

      sx={{
        flexGrow: 1,
        // maxWidth: 400,
        overflowY: "auto",

      }}
    >
      {subjectChapters?.tableOfContent?.map((chapter, index) => {
        return (
          <StyledTreeItem
            key={chapter.key}
            nodeId={chapter.key.toString()}
            label={chapter.title}
            onClick={{ data: chapter, mutator: deleteHandler }}
          >
            {chapter?.children?.map((topic) => {
              return (
                <StyledTreeItem
                  key={topic.key}
                  nodeId={topic.key.toString()}
                  label={topic.title}
                  onClick={{ data: topic, mutator: deleteHandler }}
                >
                  {topic?.children?.map((subtopic) => {
                    return (
                      <StyledTreeItem
                        key={subtopic.key}
                        nodeId={subtopic.key}
                        label={subtopic.title}
                        onClick={{
                          data: {
                            ...subtopic,
                            topicParentId: topic.parentNode,
                          },
                          mutator: deleteHandler,
                        }} icon={<HorizontalLine />}
                      ></StyledTreeItem>
                    );
                  })}
                </StyledTreeItem>
              );
            })}
          </StyledTreeItem>
        );
      })}
    </TreeView>
  );
}
