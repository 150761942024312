import React from 'react'
import CustomChip from '../../common/customChip/CustomChip';
export const durationByTime = {
  60:"1hr",
  75: "1hr 15mins.",
  90: "1hr 30mins.",
  105: "1hr 45mins.",
  120: "2hrs"
}
const ShowLectureCount = ({ lectureDuration, viewMode = 'grid' }) => {

  if (!lectureDuration) {
    return (
      <CustomChip
        bgColor={'#F5F5F5'}
        textColor={'#262626'}
        text='No lectures added'
        style={{ fontSize: '12px', ...(viewMode === 'grid' && { marginBottom: '1rem' }) }}
      />
    )
  }
  let durations = Object.keys(lectureDuration);

 

  return (
    durations.map((duration) => {
      return (
        <CustomChip
          key={duration}
          bgColor={'#BBE2CE'}
          textColor={'#0B442D'}
          text={`${lectureDuration[duration].length} Lec - ${durationByTime[duration]}`}
          style={{ fontSize: '12px', marginRight: "5px", ...(viewMode === 'grid' && { marginBottom: '1rem' }) }}
        />
      )
    })

  )
}

export default ShowLectureCount