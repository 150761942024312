import React, {useState, useEffect} from 'react'
import {SomethingWentWrongDispatch} from "../../../../../../contexts/SomethingWentWrong";
import styles from './index.module.scss'

export const SkeletonLoader = (props) => {
  const { apiLoader, loaderHeight, loaderWidth, rows } = props;
  const [showMessage, setShowMessage] = useState(true);
  const setIsSomethingWentWrong = React.useContext(SomethingWentWrongDispatch);

  useEffect(() => {
    setTimeout(() => {
      if (apiLoader) {
        setShowMessage(false);
      }
    }, 60000);
  }, []);

  useEffect(() => {
    // console.log("Show message value: ",showMessage);
    if(!showMessage){
      // history.push('/admin/errorPage');
      setIsSomethingWentWrong(true);
    }
  },[showMessage])

  return (
    [...Array(rows)].map((_,i)=>{
        return <span className={`${styles.skeleton_loader} mt-3`}/>
    })
  )
}
