import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import styles from "./styles.module.scss";
import PrintIdCardTable from "./printIdCard/PrintIdCardTable";
import StepperBar from "./StepperBar";
import { ReactComponent as BackIcon } from "assets/img/svg/BackButton.svg";
import MapRfidTable from "./mapRfid/MapRfidTable";
import ALoader from "views/pages/manage/common/a-loader";
import { IssuanceIDCardContext, PAGE_STATE } from "../contextApi";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper";
const PrintIdCardAndMapRfid = ({ initialStep }) => {
  const [step, setStep] = useState(initialStep);
  useEffect(() => {
    setStep(initialStep);
  }, [initialStep]);
  useEffect(() => {
    step === 1 && setCurrentPageState(PAGE_STATE?.PRINT_ID_CARD);
    step === 2 && setCurrentPageState(PAGE_STATE?.MAP_RFID);
  }, [step]);

  const {
    setCurrentPageState,
    studentApplications,
    getApplications,
    totalRecords,
    filterForm,
    globalFilter,
    page,
    setPage,
    printedIdCardArray,
    setPrintedIdCardArray,
    pendingRfIds,
    getPendingRfIds,
    totalPendingRfIs,
    pendingRfIdPage,
    setPendingRfIdPage,
    hideStepperBarRfidPage,
    setHideStepperBarRfidPage,
    loading,
  } = useContext(IssuanceIDCardContext);
  const {location} = useHistory();
  const history = useHistory();
  const pathName= location.pathname ;
  const isRedirected =   pathName?.includes('mapRfIdCards');

  const nextButtonHandler = (newPage, rfidMapped = null) => {
    setPage(newPage);
    getApplications(newPage, {
      ...filterForm,
      ...globalFilter,
      rfidMappedApplication: rfidMapped,
    });
    setPrintedIdCardArray([]);
    // this is to reset the printIdCardArray since the paginated data will be updated and we won't have existing printedIdCaradArray data
  };

  // in case of print and map id no next handler required
  const nextButtonHandlerForRfid = (newPage, rfidMapped = null) => {
    setPendingRfIdPage(newPage);
    getPendingRfIds(newPage, { rfidMappedApplication: rfidMapped });
  };

  const mapRfidRef = useRef();
  const goBack = async () => {
    const canContinue = await mapRfidRef?.current?.confirmationCheck();
    if (step === 1 || (step === 2 && canContinue)) {
      setCurrentPageState(PAGE_STATE?.SEARCH_STUDENT);
      setPrintedIdCardArray([]);
      setHideStepperBarRfidPage(false);
    }
  };

  return (
    <>
      <div className={`remove-container-top-space ${styles?.top_bar}`}>
       <div className="mr-2">
       <IconButtonWrapper  onClick={isRedirected?()=>history.push('/admin/dashboard'):() => goBack()}><BackIcon   /></IconButtonWrapper>
        </div> 
        <div className="heading-4 color-black-60">
          {printedIdCardArray?.length === 0 && hideStepperBarRfidPage
            ? `Map pending RFIDs`
            : `Print & issue ID card`}
        </div>
      </div>
      <div className={`${styles?.main_container} color-grey-90`}>
        {!hideStepperBarRfidPage && (
          <StepperBar
            step={step}
            setStep={setStep}
            confirmationCheck={() => mapRfidRef?.current?.confirmationCheck()}
          />
        )}
        <div style={{ position: "relative", top: "200px" }}>
          {loading && <ALoader position  = "fixed" />}
        </div>

        <div className={`${styles?.content_container}`}>
          {step === 1 && (
            <PrintIdCardTable
              rowData={studentApplications}
              rowCount={totalRecords}
              currentPage={page}
              nextButtonHandler={nextButtonHandler}
            />
          )}
          {step === 2 && (
            <MapRfidTable
              ref={mapRfidRef}
              rowData={
                printedIdCardArray?.length > 0
                  ? printedIdCardArray
                  : pendingRfIds
              }
              rowCount={totalPendingRfIs}
              currentPage={pendingRfIdPage}
              nextButtonHandler={nextButtonHandlerForRfid}
              hidePagination={printedIdCardArray?.length > 0}
              printedData={printedIdCardArray}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PrintIdCardAndMapRfid;
