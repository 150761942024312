import { RolePermissions } from 'appContext'
import { PermissionContext } from 'appContext'
import React, { useContext, useEffect, useState } from 'react'
import { BiArrowBack } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Card, CardBody } from 'reactstrap'
import { allocateStudentsDownloadCsv } from 'redux/allocateStudents/allocate-students-action'
import { fetchDropdownsAction, getBatchDetailsById, getStudentList, clearBatchDetailsState } from 'redux/allocateStudents/allocate-students-action'
import ALoader from '../../common/a-loader'
import { PermisionDenied } from '../../common/commonComponents'
import { endpoint, pages } from '../../common/constant'
import { fetchAllPostPromisedData, removeKeysFromObject } from '../../common/utils/methods/commonMethods/utilityMethod'
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function'
import { prePopulatedFormField, viewStudentsButtonStyles } from '../constant/allocate-students'
import { getFilterCount, getFilterPayload } from '../constant/allocate-students'
import { BACK_ICON_STYLE } from '../constant/common'
import AllocateStudentSearchFilter from './AllocateStudentSearchFilter'
import BatchInfo from './BatchInfo'
import StudentList from './StudentList'
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper'
import {ReactComponent as BackIcon} from "../../../../../assets/img/svg/BackButton.svg"
const AllocateStudents = (props) => {

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages['searchBatch']['id']);

  const allocateStudentsState = useSelector(state => state.allocateStudents);
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = props.match.params;
  const [page, setPage] = useState(0);
  const [payloadData, setPayloadData] = useState(getFilterPayload(id));
  const [formData, setFormData] = useState(getFilterPayload(id));
  const [prePopulatedForm, setPrePopulatedForm] = useState(prePopulatedFormField())

  const [filterCount, setFilterCount] = useState(0);
  // const [eligibleOnly, setEligibleOnly] = useState(false);
  const [allocateLoader, setAllocateLoader] = useState(false);

  const handleEligibleOnlyFilter = (isEligible) => {
    setPage(0);
    let reqPayload = { ...payloadData, filterEligibleProgramAction: isEligible };
    setPayloadData(reqPayload);
    dispatch(getStudentList({ ...reqPayload, ...prePopulatedForm }));
  }

  const onPageChange = (currentPage) => {
    setPage(currentPage);
    dispatch(getStudentList({ ...payloadData, ...prePopulatedForm }, currentPage));
  }

  const inputHandler = (value, inputKey) => {
    setFormData(prev => ({ ...prev, [inputKey]: value }));
  }

  const clearNameFilterHandler = () => {
    setPage(0);
    const updatedPayload = { ...removeKeysFromObject(payloadData, ['psid', 'applicationId', 'studentName']) };
    setPayloadData(updatedPayload);
    dispatch(getStudentList({ ...updatedPayload, ...prePopulatedForm }))
  }

  const applyFilterHandler = () => {
    setPage(0);
    // setEligibleOnly(false);
    setFilterCount(getFilterCount(formData, allocateStudentsState.batchDetails.batchType));
    const updatedPayload = { ...payloadData, ...formData }
    setPayloadData(updatedPayload);
    dispatch(getStudentList({ ...updatedPayload, ...prePopulatedForm }));
  }
  const applyNameFilterHandler = (inputForm) => {
    setPage(0);
    const updatedPayload = { ...removeKeysFromObject(payloadData, ['psid', 'studentName', 'applicationId']), ...inputForm }
    setPayloadData(updatedPayload);
    dispatch(getStudentList({ ...updatedPayload, ...prePopulatedForm }));
  }

  const removeFilter = () => {

    setPage(0);
    setFilterCount(0);
    const updatedPayload = { ...payloadData, ...getFilterPayload(id) };
    setFormData(getFilterPayload(id));
    setPayloadData(updatedPayload);
    dispatch(getStudentList({ ...updatedPayload, ...prePopulatedForm }));
  }
  const dispatchSearchListWithFilter = (updatePayload) => {
    dispatch(getStudentList({ ...payloadData, ...updatePayload }));
    setPrePopulatedForm(updatePayload)

  }

  const allocateStudentHandler = async (applicationIds) => {
    setAllocateLoader(true);
    const reqBody = {
      applicationIds: applicationIds,
      batchId: id,
      action: 'ALLOCATE'
    }

    const response = await fetchAllPostPromisedData(endpoint.allocateStudents.allocateDeallocate, reqBody, 'post');

    if (response?.code === 200) {
      if (response?.data?.length) {
        failureToast(response.message);
      }
      else successToast(response.message);
      setAllocateLoader(false);
      setPage(0);
      dispatch(getBatchDetailsById(id, dispatchSearchListWithFilter));
    } else {
      setAllocateLoader(false);
    }
  }

  useEffect(() => {
    dispatch(fetchDropdownsAction());
    dispatch(getBatchDetailsById(id, dispatchSearchListWithFilter));

    return () => {
      dispatch(clearBatchDetailsState());
    }
  }, []);

  const downloadCsvHandler = () => {
    dispatch(allocateStudentsDownloadCsv({ ...payloadData, ...prePopulatedForm }))
  }

  // useEffect(() => {
  //   const { term, businessArea, courseId, batchType } = allocateStudentsState.batchDetails
  //   const updatePayload = batchType === 'PRIMARY' ? prePopulatedFormField([businessArea], courseId, term) : {}
  //   setPrePopulatedForm(updatePayload)
  //   if(batchType)
  //      dispatch(getStudentList({ ...payloadData, ...updatePayload }));
  // }, [allocateStudentsState.batchDetails])
  return (
    userPermissions ? (
      <div style={{ position: 'relative', display: 'flex', flexFlow: 'column', height: 'calc(100vh - 120px)' }}>
        {(allocateStudentsState.batchDetailsLoading || allocateStudentsState.studentListLoading || allocateLoader) && <ALoader />}
        <div className='mb-2' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h3 className='ml-3 mb-0' style={{ fontSize: '16px' }}>{allocateStudentsState.batchDetails.batchName} / Allocate Students</h3>
          <button
            className='mr-3 py-2 px-3'
            style={viewStudentsButtonStyles}
            onClick={() => history.push(`/admin/edp/deallocateStudents/${id}`)}
          >
            View Allocated Students
          </button>
        </div>
        <Card className='mx-3 mb-2' style={{ flexGrow: 1 }}>
          <CardBody style={{ display: 'flex', flexFlow: 'column' }}>
            <div className='d-flex'>
              <div className='mr-2'><IconButtonWrapper  onClick={() => history.goBack()}><BackIcon  /></IconButtonWrapper> </div>
              <div style={{ display: "flex", justifyContent: "space-between", flex: 1, position: "relative" }}>
                <BatchInfo batchDetails={allocateStudentsState.batchDetails} />
                <AllocateStudentSearchFilter
                  batchDetails={allocateStudentsState.batchDetails}
                  data={allocateStudentsState.studentList}
                  handleEligibleOnlyFilter={handleEligibleOnlyFilter}
                  inputHandler={inputHandler}
                  applyFilterHandler={applyFilterHandler}
                  removeFilter={removeFilter}
                  form={formData}
                  filterCount={filterCount}
                  // eligibleOnly={eligibleOnly}
                  eligibleOnly={payloadData?.filterEligibleProgramAction}
                  clearNameFilterHandler={clearNameFilterHandler}
                  applyNameFilterHandler={applyNameFilterHandler}
                  downloadCsvHandler={downloadCsvHandler}
                />
              </div>
            </div>
            <StudentList
              rowsData={allocateStudentsState.studentList || []}
              rowCount={allocateStudentsState?.studentListRowCount}
              page={page}
              onPageChange={onPageChange}
              allocateStudentHandler={allocateStudentHandler}
              availableForSelection={allocateStudentsState?.batchDetails?.batchCapacity - allocateStudentsState?.batchDetails?.currentStrength}
              batchName={allocateStudentsState?.batchDetails?.batchName}
            />
          </CardBody>
        </Card>
      </div>
    ) : <PermisionDenied />
  )
}

export default AllocateStudents