import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { Button, Row, Table, Input, Card } from 'reactstrap';
import {
	CatalogAssociatedAttributeHeader,
} from '../../../../common/commonComponents/masterRow/masterRow';
import Select2 from 'react-select2-wrapper';
import { masterServiceBaseUrl, getAPI } from 'services/http';
import {
	failureToast,
	successToast,
} from '../../../../common/utils/methods/toasterFunctions/function';
import CustomLoader from '../../../../common/commonComponents/Loader/loader';
import { fetchDetails } from '../../../../common/utils/methods/commonMethods/utilityMethod';
import CommonInput from "../../../../common/formFeilds/input/commonInput";
import {ButtonText} from "../../../../../../../variables/Buttons";
import CustomButton from "../../../../../../../components/CustomButton";
const AssociatedAttribute = (props) => {
	const {
		courseAttributeValues,
		setCourseAttributeValues,
		setAttributeValueArray,
		attributeValue,
		courseAttribute,
		companyCode,
		groupCode,
		userDataList,
		setUserDataList,
		action,
		permissionSet
	} = props;
	const [apiLoader, setApiLoader] = useState(true);
	const [dataExist, setDataExist] = useState([]);
	const [courseAttributeList, setCourseAttributeList] = useState([]);
	const [courseAttributeValue, setCourseAttributeValue] = useState([]);
	const [courseAttributeDisp, setCourseAttributeDisp] = useState('');
	const [courseAttributeValueDisp, setCourseAttributeValueDisp] = useState('');
	const [courseAttributeDesc, setCourseAttributeDesc] = useState('');
	const [courseAttributeValueDesc, setCourseAttributeValueDesc] = useState('');
	const [dataSubmit, setDataSubmit] = useState(false);
	const [userSubmitData, setUserSubmitData] = useState([]);
	const [visible, setVisible] = useState(false);
	const [newCourseAttribute, setNewCourseAttributes] = useState([]);
	const [attributeDispValue, setAttributeDispValue] = useState([]);
	const [dropdownForAttributeValue, setDropDownForAttributeValue] = useState([]);
	const [check, setCheck] = useState(true);

	const [rowCounts,setRowsCount] = useState({})


	useEffect(() => {
		if (courseAttributeList && courseAttributeList.length > 0) {
			if (courseAttributeValues && courseAttributeValues.length > 0) {
				let tempArray = [];
				courseAttributeList.map((item) => {
					courseAttributeValues.map((id) => {
						if (item.id === id) {
							tempArray.push(item);
						}
					});
				});
				setNewCourseAttributes(tempArray);
			}
		}
		// console.log({courseAttributeList,courseAttributeValues})
	}, [courseAttributeList, courseAttributeValues]);

	useEffect(() => {
		try {
			let temp = [];
			courseAttribute &&
				courseAttribute.map((item) => {
					temp.push({
						id: item.id,
						text: item.courseAttributeDispValue,
						desc: item.courseAttributeDesc,
					});
					setCourseAttributeList(temp);
				});
		} catch (error) {
			console.log(error);
		}
	}, [courseAttribute]);

	useEffect(() => {
		fetchDetails(
			masterServiceBaseUrl + `/courseAttributeValue/getAllActiveCourseAttributeValue`,
			setDropDownForAttributeValue,
			'courseAttributeValue',
			'reactSelect'
		);
	}, []);


	useEffect(() => {
		try {
			groupCode &&
				companyCode &&
				action === 'new' &&
				getAPI(
					masterServiceBaseUrl +
						`/courseAttributeMapping/getCourseAttributeMappingForCourseCatelogHeader?groupCode=${groupCode}&companyCode=${companyCode}`,
					(data) => {
						setDataExist(data.data.flat(2));
						let tempArray = data.data.flat(2) || [];
						let temp =  tempArray.filter((item) => item['addCourseCatalog'] === true);
						setUserDataList([...userSubmitData, ...temp]);
						if ( data.data && data.data.length > 0 && dropdownForAttributeValue && dropdownForAttributeValue.length > 0) {
							let temp = data.data;
							temp.map((item, index) => {
								let tempArray2 = [];
								if (item.courseAttributeValueId && item.courseAttributeValueId.length > 0) {
									item.courseAttributeValueId.map((id) => {
										dropdownForAttributeValue.map((item2) => {
											if (item2.id === id) {
												let updated = {...item2,text:`${item2.text}-${item2.desc}`}
												tempArray2.push(updated);											}
										});
										temp[index]['dropdown'] = tempArray2;
										temp[index]['dataFromApi'] = true;
										temp[index]['courseAttributeValueId'] = '';
									});
								}
							});
							let tempArray = temp.filter((item) => item.addCourseCatalog === true);
							setUserDataList([...userSubmitData, ...tempArray]);
							setCheck(false);
						}
						setAttributeDispValue([]);
					},
					(data) => {
						setUserDataList(userSubmitData);
						setApiLoader(false);
					}
				);
			if (groupCode && companyCode) {
				getAPI(
					masterServiceBaseUrl +
						`/courseAttributeMapping/getCourseAttributeMappingByGroupCodeAndCompanyCode?groupCode=${groupCode}&companyCode=${companyCode}`,
					(data) => {
						let tempArray = [];
						let rowObject = {};
						if (data.data.length > 0) {
							data.data.map((item, index) => {
								if(item[0] && item[0].courseAttributeId) {
									tempArray.push(item[0].courseAttributeId);
									rowObject = {...rowObject,[item[0].courseAttributeId]:item[0].numberOfRowsAllowed}
								}
							});
							// alert(JSON.stringify(rowObject))
							setCourseAttributeValues(tempArray);
							setRowsCount(rowObject)
							// console.log('CourseAttributeValues---->',tempArray);
						}
					},
					(data) => {
						failureToast(data['message'])
					}
				);
			}
		} catch (e) {
			console.log(e);
		}
	}, [groupCode, companyCode]);
	useEffect(() => {
		if (dropdownForAttributeValue.length > 0) {
			setApiLoader(false);
		}
	}, [dropdownForAttributeValue]);
	const updateData = (index, key, value) => {
		let temp = userDataList.slice();
		temp[index][key] = value;
		temp[index]['isValid'] = true;
		setUserDataList(temp);
	};

	const [selectedId, setSelectedId] = useState(null);
	const submitHandler = (index) => {
		try {
			let flag = false;
			let temp = [...userDataList];
			// temp.map((item, index2) => {
			// 	if (index2 !== index) {
			// 		if (item.courseAttributeId === parseInt(courseAttributeDisp)) {
			// 			flag = true;
			// 		}
			// 	}
			// });
			let _tempArray = [...userDataList];
			let __tempArray = [...userSubmitData];

			let attributeCount = [];
			let newAttributeCount = [];

			if (_tempArray[index]['__status'] === 'new') {
				attributeCount = temp?.filter(item=>item.courseAttributeId == parseInt(courseAttributeDisp));
				newAttributeCount = __tempArray?.filter(item=>item.courseAttributeId == parseInt(courseAttributeDisp));
			}

			if(attributeCount.length > rowCounts[courseAttributeDisp] || newAttributeCount.length > rowCounts[courseAttributeDisp] || attributeCount.length == rowCounts[courseAttributeDisp] ||  newAttributeCount.length == rowCounts[courseAttributeDisp]){
				failureToast('Same Course Attribute is not allowed more then number of Rows Allowed in Course Catalog Mapping.');
				return
			}

			// __tempArray.map((item, index2) => {
			// 	if (index2 !== index) {
			// 		if (
			// 			parseInt(item.courseAttributeId) === parseInt(courseAttributeDisp)
			// 		) {
			// 			flag = true;
			// 		}
			// 	}
			// });

			// if (flag) {
			// 	failureToast('Please Select Unique Course Attribute.');
			// 	return;
			// }
			_tempArray[index]['courseAttributeId'] = parseInt(courseAttributeDisp);
			_tempArray[index]['courseAttributeValueId'] = parseInt(courseAttributeValueDisp);
			__tempArray[index]['courseAttributeId'] = parseInt(courseAttributeDisp);
			__tempArray[index]['courseAttributeValueId'] = parseInt(courseAttributeValueDisp);
			if (!__tempArray[index]['isNew']) {
				__tempArray[index]['isNew'] = true;
				_tempArray[index]['isNew'] = true;
			}
			if (!_tempArray[index]['courseAttributeId'])
				return failureToast('Please Select Course Attribute');
			if (!_tempArray[index]['courseAttributeValueId'])
				return failureToast('Please Select Course Attribute Value');
			if (_tempArray) {
				const Index = _tempArray.findIndex((item) => {
					// successToast('Data is Saved.');
					return (
						parseInt(courseAttributeDisp) === item['courseAttributeId'] &&
						parseInt(courseAttributeValueDisp) === item['courseAttributeValueId'] &&
						!(item['__status'] === 'new') &&
						!item['editMode']
					);
				});
				if (Index >= 0 && !(_tempArray[index]['status'] === 'new'))
					return failureToast('Combination already exist');
				if (Index >= 0 && action === 'edit')
					return failureToast('Combination already exist');

				successToast('Data is Saved.');
			}
			setCourseAttributeDisp('');
			setCourseAttributeDesc('');
			setCourseAttributeValueDisp('');
			setCourseAttributeValueDesc('');
			setCourseAttributeValue([]);
			if (_tempArray[index]['__status'] === 'new') {
				let a = [...attributeDispValue];
				let newArray = [
					...getDisplayvalue(
						_tempArray[index]['courseAttributeId'],
						'courseAttributeDescValue'
					),
				].concat(a);
				setAttributeDispValue(newArray);
				delete _tempArray[index]['__status'];
				delete __tempArray[index]['__status'];
			}
			delete _tempArray[index]['editMode'];
			delete __tempArray[index]['editMode'];
			setUserDataList(_tempArray);
			setUserSubmitData(__tempArray);
			setVisible(false);
		} catch (error) {
			console.log(error);
		}
	};

	const addNewRow = () => {
		try {
			if (visible)
				return failureToast('Please close or save the existing record');
			let temp = [
				{
					courseAttributeId: null,
					courseAttributeValueId: null,
					editMode: true,
					__status: 'new',
				},
				...userDataList,
			];
			let __temp = [
				{
					courseAttributeId: null,
					courseAttributeValueId: null,
					editMode: true,
					__status: 'new',
				},
				...userSubmitData,
			];
			setUserSubmitData(__temp);
			let a = [...attributeDispValue];
			let newArray = [''].concat(a);
			setAttributeDispValue(newArray);
			setUserDataList(temp);
			setCourseAttributeValue([]);
			setVisible(true);
		} catch (error) {
			console.log(error);
		}
	};

	const deleteRow = (index) => {
		try {
			if (visible)
				return failureToast('Please close or save the existing record');
			const temp = [...userSubmitData];
			temp.splice(index, 1);
			setUserSubmitData(temp);
			const __temp = [...userDataList];
			__temp.splice(index, 1);
			setUserDataList(__temp);
			setDataSubmit(!dataSubmit);
		} catch (error) {
			console.log(error);
		}
	};

	const editData = (index) => {
		try {
			if (visible)
				return failureToast('Please close or save the existing record');
			let __temp = userDataList;
			__temp[index]['editMode'] = true;
			setUserSubmitData(__temp);
			setCourseAttributeDisp(__temp[index]['courseAttributeId']);
			getDisplayValueForDisplay(__temp[index]['courseAttributeId'], 'courseAttributeDesc');
			setCourseAttributeValueDisp(__temp[index]['courseAttributeValueId']);
			getDisplayValueForDisplay(__temp[index]['courseAttributeValueId'], 'courseAttributeDescValue');
			setUserSubmitData(__temp);
			setDataSubmit(!dataSubmit);
			getCourseAttributeValue(__temp[index]['courseAttributeId']);
			setVisible(true);
		} catch (error) {
			console.log(error);
		}
	};
	const getCourseAttributeValue = (id) => {
		try {
			id &&
				companyCode &&
				groupCode &&
				getAPI(
					masterServiceBaseUrl +
						`/courseAttributeValueMapping/getCourseAttributeValueByCourseAttributeIdAndGroupCodeIdAndCompanyCodeId/?groupCodeid=${groupCode}&companyCodeId=${companyCode}&courseAttributeId=${id}`,
					(data) => {
						let temp = [];
						setCourseAttributeDisp(id);
						data &&
							data.data &&
							data.data.map((item) => {
								let flag = true;
								temp.map((item2) => {
									if (item2.id === item.courseAttributeValueId) {
										flag = false;
									}
								});
								if (item.status === 'ACTIVE' && !item.isDeleted) {
									temp.push({
										id: item.courseAttributeValueId,
										text: getDisplayvalue(item.courseAttributeValueId, 'courseAttributeDispValue'),
									});
								}
							});
						setCourseAttributeValue(temp);
						getDisplayValueForDisplay(id, 'courseAttributeDesc');
					},
					(data) => {

					}
				);
		} catch (e) {
			console.log(e);
		}
	};

	const getDisplayValueForDisplay = (id, type) => {
		try {
			switch (type) {
				case 'courseAttributeDesc':
					const CourseDescID =
						courseAttribute &&
						courseAttribute.findIndex((item) => {
							return item.id == id;
						});
					return CourseDescID >= 0
						? setCourseAttributeDesc(
								courseAttribute[CourseDescID]['courseAttributeDesc']
						  )
						: '';
					break;
				case 'courseAttributeDescValue':
					setCourseAttributeValueDisp(id);
					const CourseValueDescID =
						attributeValue &&
						attributeValue.findIndex((item) => {
							return item.id == id;
						});
					return CourseValueDescID >= 0
						? setCourseAttributeValueDesc(
								attributeValue[CourseValueDescID]['courseAttributeValueDesc']
						  )
						: 'NOT FOUND';
					break;
				default:
					break;
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getDisplayvalue = (id, type) => {
		try {
			switch (type) {
				case 'courseAttributeDisp':
					const Index = courseAttribute && Array.isArray(courseAttribute) && courseAttribute.findIndex((item) => {return item.id == id;});
					return ( Index >= 0 ) ? courseAttribute[Index]?.courseAttributeDispValue : 'NOT FOUND';
					break;
				case 'courseAttributeDesc':
					const CourseDescID = courseAttribute && Array.isArray(courseAttribute) && courseAttribute.findIndex((item) => {return item.id == id;});
					return (CourseDescID >= 0 ) ? courseAttribute[CourseDescID]?.courseAttributeDesc : 'NOT FOUND';
					break;
				case 'courseAttributeDescValue':
					const CourseValueDescID = attributeValue && Array.isArray(attributeValue) && attributeValue.findIndex((item) => {return item.id == id;});
					return ( CourseValueDescID ) >= 0 ? attributeValue[CourseValueDescID]?.courseAttributeValueDesc : 'NOT FOUND';
					break;
				case 'courseAttributeDispValue':
					const CourseValueDispID = attributeValue && Array.isArray(attributeValue) && attributeValue.findIndex((item) => {return item.id == id;});
					return ( CourseValueDispID ) >= 0 ? attributeValue[CourseValueDispID]?.courseAttributeValueDispValue : 'NOT FOUND';
					break;
				default:
					break;
			}
		} catch (error) {
			console.log(error);
		}
	};

	const updateUserData = (index, id, type) => {
		try {
			let __temp = userDataList;
			if (!__temp[index]['__status'] === 'new') {
				__temp[index][type] = id;
				setUserSubmitData(__temp);
			}
			if (type === 'courseAttributeId') {
				setCourseAttributeDisp(id);
				getDisplayValueForDisplay(id, 'courseAttributeDesc');
				getCourseAttributeValue(id);
			}
			if (type === 'courseAttributeValueId') {
				setCourseAttributeValueDisp(id);
				getDisplayValueForDisplay(id, 'courseAttributeDescValue');
			}
		} catch (error) {
			console.log(error);
		}
	};
	const hideRow = (index) => {
		try {
			let _tempArray = userDataList;
			if (userDataList[index]['__status'] === 'new') {
				let __temp = userDataList;
				__temp.splice(0, 1);
				setUserSubmitData(__temp);
			}
			if (_tempArray && _tempArray[index]) delete _tempArray[index]['editMode'];
			setUserSubmitData(_tempArray);
			setDataSubmit(!dataSubmit);
			setCourseAttributeDisp('');
			setCourseAttributeDesc('');
			setCourseAttributeValueDisp('');
			setCourseAttributeValueDesc('');
			setVisible(false);
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		if (userDataList && userDataList.length > 0) {
			let temp = [];
			userDataList.map((item, index) => {
				temp[index] = null;
			});
			setAttributeValueArray(temp);
		}
	}, [userDataList]);
	return (
		<Fragment>
			<Row className="border-0 floatRight mx-1 mb-4 ">
				{!(action === 'view') ? (
					<CustomButton
						disabled={visible}
						content={'Add'}
						permissionType={'C'}
						forTable={true}
						icon={true}
						permissionSet={permissionSet}
						onClick={() => addNewRow()}
					/>
				) : null}
			</Row>
			<div id="questionTable" className="table-responsive">
				<Table className="align-items-center table-flush" style={{ minWidth: '1000px' }}>
					<CatalogAssociatedAttributeHeader action={action} permissionSet={permissionSet} />
					<tbody className="list">
						{
							apiLoader && groupCode && companyCode && dropdownForAttributeValue.length
								?
								<Card style={{ margin: '12px 20px' }}>
									<CustomLoader apiLoader={apiLoader} loaderHeight={'200px'} loaderWidth={'100% '}/>
								</Card>
								:
								( userDataList && userDataList.map((item, index) => {
								{
									return item['editMode'] ? (
										<tr>
											<td className="text-center p-2 mx-1">
												<Select2
													id="courseId"
													disabled={!!(item['addCourseCatalog'] || !(item['__status'] === 'new'))}
													value={courseAttributeDisp}
													data={newCourseAttribute}
													onChange={(e) => {
														let selectedValue = e.target.value;
														selectedValue &&
															updateUserData(index, selectedValue, 'courseAttributeId');
													}}
													options={{ placeholder: 'Select Course Attribute' }}
													className="tdSelect"
												/>
											</td>
											<td className="text-center p-2 mx-1">
												<CommonInput isDisabled={true} defaultValue={courseAttributeDesc} placeholder={'Course Description'} forTable={true}/>
											</td>
											<td className="text-center p-2 mx-1">
												<Select2
													id="courseIdValue"
													value={courseAttributeValueDisp}
													data={courseAttributeValue}
													onChange={(e) => {updateUserData(index, e.target.value, 'courseAttributeValueId');}}
													disabled={getDisplayvalue(item.courseAttributeId, 'courseAttributeDisp') === 'CRTY'}
													options={{ placeholder: 'Select Attribute Value' }}
													className="tdSelect"
												/>
											</td>
											<td className="text-center p-2 mx-1">
												<Input disabled={true} value={courseAttributeValueDesc} placeholder="Attribute Description" className="tdInput"/>
											</td>
											<td className="text-center">
												<CustomButton
													className={"mx-1"}
													content={'Save'}
													permissionType={'C,U'}
													forTable={true}
													permissionSet={permissionSet}
													onClick={() => submitHandler(index)}
												/>
												<CustomButton
													permissionType={'cancel'}
													forTable={true}
													icon={true}
													permissionSet={permissionSet}
													onClick={() => hideRow(index)}
												/>
											</td>
										</tr>
									) : (
										<tr key={index}>
											<td className="text-center white-breakSpace" style={{ wordBreak: 'break-all' }}>
												{getDisplayvalue(item.courseAttributeId, 'courseAttributeDisp')}
											</td>
											<td className="text-center  white-breakSpace" style={{ wordBreak: 'break-all' }}>
												{getDisplayvalue(item.courseAttributeId, 'courseAttributeDesc')}
											</td>
											<td className="text-center  white-breakSpace" style={{ wordBreak: 'break-all' }}>
												{item.dataFromApi && !check ? (
													<Select2
														options={{ placeholder: 'Attribute Value' }}
														data={item.dropdown}
														defaultValue={item.courseAttributeValueId}
														onChange={(e) => {
															if (e.target.value) {
																if (e.target.value !== item.courseAttributeValueId) {
																	updateData(
																		index,
																		'courseAttributeValueId',
																		e.target.value
																	);
																	setSelectedId(e.target.value);
																	let a = [...attributeDispValue];
																	a[index] = getDisplayvalue(e.target.value, 'courseAttributeDescValue');
																	setAttributeDispValue(a);
																}
															}
														}}
													/>
												)
												:
												(getDisplayvalue(item.courseAttributeValueId, 'courseAttributeDispValue'))}
											</td>
											{/* <td className="text-center  white-breakSpace" style={{ wordBreak: 'break-all' }}>
												{
													item.dataFromApi && !check
													? selectedId
														?
															item.courseAttributeValueId === ''
																?
																  attributeDispValue[index]
																: getDisplayvalue(item.courseAttributeValueId, 'courseAttributeDescValue')
														:
														''
													: getDisplayvalue(item.courseAttributeValueId, 'courseAttributeDescValue')
												}
											</td> */}
											{!(action === 'view') ? (
												item.dataFromApi || !(permissionSet && (permissionSet.includes('U')||permissionSet.includes('C')))  ? null : (
													<td className="text-center  white-breakSpace">
														{
															!((getDisplayvalue(item.courseAttributeId, 'courseAttributeDisp')) === 'CRTY') ?
																// <Button color="info" size="sm" type="button"
																// 		onClick={() => editData(index)}>
																// 	<i className="fas fa-edit"/>
																// </Button>
																<CustomButton
																	permissionType={'U'}
																	forTable={true}
																	icon={true}
																	permissionSet={permissionSet}
																	onClick={() => editData(index)}
																/>
																: null
														}
														{
															item.isNew && !item.courseId
															?
															<Button color="info" size="sm" type="button" onClick={() => deleteRow(index)}>
																<i className="fas fa-trash" />
															</Button>
															:
															''
														}
													</td>
												)
											) : null}
										</tr>
									);
								}
							})
						)}
					</tbody>
				</Table>
			</div>
		</Fragment>
	);
};

export default AssociatedAttribute;
