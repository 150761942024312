export const MasterServiceUrls = [
    {
        name:'groupCode',
        getAllActive:'/groupCode/getAllActiveGroupCode',
        getAll:'/groupCode/getAllGroupCode'
    },
    {
        name:'academicCareer',
        getAllActive:'/academicCareer/getAllActiveAcademicCareer',
        getAll:'/academicCareer/getAllAcademicCareer'
    },
    {
        name:'operationalZone',
        getAllActive:'/operationalZone/getAllActiveOperationalZone',
        getAll:'/operationalZone/getAllOperationalZone'
    },
    {
        name:'region',
        getAllActive:'/region/getAllActiveRegion',
        getAll:'/region/getAllRegion'
    },
];