import React, { useState } from 'react'
import { RiFileCopyLine } from 'react-icons/ri'
import { REACT_ICONS_STYLE } from '../constant/batch-search';
import Tooltip from '@mui/material/Tooltip';
import ShowBatchName from './ShowBatchName'
const getDisplayText = (text)=>{
      if(text && text.length>8)
      return  text.substring(0, 8) + '...';
      return text + '...';

}
const MsID = ({id, copyUrl}) => {
    const [toolTipText ,setText] = useState('Copy') ;
    if(!id){
        return (<span>---</span>);
    }
  return (
    <div>
        <Tooltip title={id}><span>{getDisplayText(id)}</span></Tooltip>
        <Tooltip title = {toolTipText}>

        <span>
                <RiFileCopyLine
                style={ REACT_ICONS_STYLE}
                onClick={()=>{navigator.clipboard.writeText(copyUrl||id) ;
                    setText("Copied") 
                 }} />
       
         </span>
        </Tooltip>

    </div>
  )
}

export default MsID