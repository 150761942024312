import React from "react";
import { Dialog, Tooltip } from "@mui/material";
import { ReactComponent as CrossIcon } from "../../../../../../assets/img/svg/CrossIcon.svg";
import { ReactComponent as Bulb } from "../../../../../../assets/img/svg/Bulb.svg";

import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import { durationByTime } from "../../viewAcademicPlan/ShowLectureCount";
import {useHistory} from "react-router-dom";
import { getSelectedFaculty } from "../helper";
import IconButtonWrapper from "views/pages/manage/common/form-fields-mui/IconButtonWrapper";

const tipsDivStyle = {
  padding: "12px 16px 12px 16px",
  borderRadius: "8px",
  gap: "10px",
  background: "#F0ECFC",
};

const CreateRowItem = ({
  label = "",
  value = "",
  action = "",
  hideBtn = false,
  onClick = ()=>{}
}) => {
  return (
    <div className="d-flex align-items-center justify-content-between" key ={label}>
      <div>
        <div className="labe-text color-grey-60">{label}</div>
        <div className="heading-3" style={{ width: "214px" }}>
          <Tooltip title={value}>
            <div className="ellipsis-text-container">{value}</div>
          </Tooltip>
        </div>
      </div>
      {!hideBtn && <AButton 
        onClick ={()=>onClick(action)}
        updatedStyle ={{height:"36px", fontSize:"12px"}}
         variant="outline"
        >View details
      </AButton>}
    </div>
  );
};

const getSelectedFacultyLabel = (subjectName, facultyId, allFaculty) => {
  if (allFaculty[subjectName]) {
    return allFaculty[subjectName].filter(
      (item) => item?.value === facultyId
    )[0]?.label;
  } else {
    return "Not found";
  }
};

const GetFacultyRow = (selectedSubjects, allFaculty, openInNewWindow) => {
  const result = [];
  if (selectedSubjects)
    for (let subjectName in selectedSubjects) {
      result.push(
        CreateRowItem({
          label: subjectName,
          action :`admin/facultyRoaster/faculty/${selectedSubjects[subjectName]}`,
          onClick:openInNewWindow,
          value: getSelectedFacultyLabel(
            subjectName,
            selectedSubjects[subjectName],
            allFaculty
          ),
        })
      );
    }
  return result;
};



const ViewTimetableMasterCnf = ({
  handleClose,
  open,
  timetable,
  subjectFacultyDropdown,
  subjectForm
}) => {

  let selectedFaculty =
    timetable?.selectedFaculty && JSON.parse(timetable?.selectedFaculty);
    
  if(!selectedFaculty && subjectForm){
    selectedFaculty = getSelectedFaculty(subjectForm, timetable)
  }
    const history  = useHistory()

  const openInNewWindow = (url) => {
      window.open(
        history.createHref({
          pathname: url,
        }),
        "_blank"
      );
    };

  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={{ padding: "1.5rem", width: "30rem" }}>
        <div className="d-flex justify-content-between mb-4 align-items-center">
          <div>
            <div className="heading-3 color-dark">
              Masters used in this timetable
            </div>
          </div>

          <div>
            <IconButtonWrapper onClick={handleClose}>
              <CrossIcon />
            </IconButtonWrapper>
          </div>
        </div>

        <div className="d-flex flex-column" style={{ gap: "20px" }}>
          {CreateRowItem({
            label: "Academic plan",
            value: timetable?.plan?.label,
            action: `admin/testScheduling/academicPlan/view/${timetable?.plan?.value}`,
            onClick: openInNewWindow,
          })}
          {CreateRowItem({
            label: "Days plan",
            value: timetable?.daysPlan?.label,
            action: `admin/master/daysPlan/view/${timetable?.daysPlan?.value}`,
            onClick: openInNewWindow,
          })}

          {GetFacultyRow(
            selectedFaculty,
            subjectFacultyDropdown,
            openInNewWindow
          )}

          {CreateRowItem({
            label: "Lecture duration",
            value: durationByTime[timetable?.duration?.value],
            hideBtn: true,
          })}

          {CreateRowItem({
            label: "Room",
            value: timetable?.room?.label,
            hideBtn: true,
          })}
          <div style={tipsDivStyle} className="border-purple d-flex mt-2">
            <div className="d-flex">
              <Bulb /> <span className="semi-bold color-dark">Tips:</span>
            </div>
            <div>
              To change these master values. Try Resetting the timetable
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ViewTimetableMasterCnf;
