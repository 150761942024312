import moment from "moment";

export const combinedViewInitialFilterForm = {
  startDate: "",
  endDate: "",
  startTime: "",
  endTime: "",
  classType: "",
  subject: "",
  lecture: "",
  faculty: "",
  room: "",
  status: "",
  isMandatory: "",
  testType: "",
  format: "",
  mode: "",
  testNumber: "",
};

const momentObjectFromTime = (timeString) =>
  timeString ? moment(timeString, "HH:mm") : null;
const getTimeString = (date) => moment(date)?.format("HH:mm");

export const getFilterCount = (form) => {
  let cnt = 0;
  for (let item in form) {
    // console.log(item);
    if (item !== "startDate" && item !== "startTime" && form?.[item] !== "") {
      cnt++;
    }
  }

  return cnt;
};

export const dateRangeFilter = (startDate, endDate, item) => {
  if (!startDate && !endDate) return true; // no need to filter

  if (!endDate) endDate = moment();
  // lecture -- date
  if (
    item?.date &&
    moment(item?.date)
      ?.set({ hour: 0, minute: 0, second: 0 })
      ?.isSameOrAfter(moment(startDate)) &&
    moment(item?.date)
      ?.set({ hour: 0, minute: 0, second: 0 })
      ?.isSameOrBefore(moment(endDate))
  )
    return true;

  // test -- testDate, onlineTestDate, onlineTestEndDate
  if (
    item?.testDate &&
    moment(item?.testDate)?.isSameOrAfter(moment(startDate)) &&
    moment(item?.testDate)?.isSameOrBefore(moment(endDate))
  )
    return true;

  return false;
};

export const timeRangeFilter = (startTime, endTime, item) => {
  if (!startTime && !endTime) return true; // no need to filter

  if (item?.testScheduleId) {
    if (item?.mode?.toLowerCase() === "offline") {
      if (
        momentObjectFromTime(getTimeString(item?.testStartTime))?.isSameOrAfter(
          momentObjectFromTime(startTime)
        ) &&
        momentObjectFromTime(
          getTimeString(item?.testStartTime)
        )?.isSameOrBefore(momentObjectFromTime(endTime))
      )
        return true;
    } else {
      return true; // no need to filter if mode is online or both
    }
  }

  if (!endTime) endTime = moment()?.format("HH:mm"); // this is incase user has not selected endTime
  // lectures are only eligible itemsgetTimeString(item?.lectureStartTime)
  if (
    momentObjectFromTime(getTimeString(item?.lectureStartTime))?.isSameOrAfter(
      momentObjectFromTime(startTime)
    ) &&
    momentObjectFromTime(getTimeString(item?.lectureStartTime))?.isSameOrBefore(
      momentObjectFromTime(endTime)
    )
  )
    return true;

  return false;
};

export const LECTURE_TYPE_ITEMS = ["LECTURE", "DOUBT CLASS", "EXTRA CLASS"];
export const classTypeFilter = (form, item) => {
  if (!form?.classType) return true;

  if (form?.classType?.value === "TEST" && item?.testScheduleId)
    return testItemFilter(form, item);

  if (form?.classType?.value === "LECTURE" && item?.lectureId)
    return lectureItemFilter(form, item);
  if (
    form?.classType?.value === "EXTRA CLASS" &&
    item?.classType === "EXTRA CLASS"
  )
    return lectureItemFilter(form, item);
  if (
    form?.classType?.value === "DOUBT CLASS" &&
    item?.classType === "DOUBT CLASS"
  )
    return lectureItemFilter(form, item);

  if (form?.classType?.value === "PTM" && item?.classType === "PTM")
    return ptmItemFilter(form, item);

  if (
    form?.classType?.value === "Orientation" &&
    item?.classType === "Orientation"
  )
    return ptmItemFilter(form, item);

  return false;
};

// test filters here
export const testItemFilter = (form, item) => {
  if (
    mandatoryFilter(form?.isMandatory, item) &&
    testTypeFilter(form?.testType, item) &&
    formatFilter(form?.format, item) &&
    modeFilter(form?.mode, item) &&
    testNumberFilter(form?.testNumber, item)
  )
    return true;
  return false;
};
const mandatoryFilter = (isMandatory, item) => {
  if (isMandatory === "") return true;
  if (isMandatory === item?.isMandatory) return true;
  return false;
};
const testTypeFilter = (testType, item) => {
  if (!testType) return true;
  if (item?.testType === testType?.label) return true;
  return false;
};
const formatFilter = (format, item) => {
  if (!format) return true;
  if (item?.format === format?.value) return true;
  return false;
};
const modeFilter = (mode, item) => {
  if (!mode) return true;
  if (item?.testMode === mode?.value) return true;
  return false;
};
const testNumberFilter = (testNumber, item) => {
  if (!testNumber) return true;
  if (item?.testName?.toUpperCase() === testNumber?.toUpperCase()) return true;
  return false;
};

// lecture filters here
const lectureItemFilter = (form, item) => {
  if (
    subjectFilter(form?.subject, item) &&
    lectureFilter(form?.lecture, item) &&
    facultyFilter(form?.faculty, item) &&
    roomFilter(form?.room, item) &&
    statusFilter(form?.status, item)
  )
    return true;
  return false;
};

const subjectFilter = (subject, item) => {
  if (!subject) return true;
  if (subject?.label?.toUpperCase() === item?.subject?.toUpperCase())
    return true;
  return false;
};
const lectureFilter = (lecture, item) => {
  if (!lecture) return true;
  if (lecture?.value * 1 === item?.lectureId) return true;
  return false;
};
const facultyFilter = (faculty, item) => {
  if (!faculty) return true;
  if (faculty?.value * 1 == item?.facultyId) return true;
  return false;
};
const roomFilter = (room, item) => {
  if (!room) return true;
  if (room?.value * 1 === item?.roomResponse?.id) return true;
  return false;
};
const statusFilter = (status, item) => {
  if (!status) return true;
  if (status?.value === item?.status) return true;
  return false;
};

// PTM filters here
export const ptmItemFilter = (form, item) => {
  if (
    facultyFilter(form?.faculty, item) &&
    roomFilter(form?.room, item) &&
    statusFilter(form?.status, item)
  )
    return true;
  return false;
};

export const getEventColor = (event) => {
  const eventType = event.toUpperCase();

  if (LECTURE_TYPE_ITEMS.includes(eventType)) return "#00B0F5";
  else if (eventType === "TEST") return "#45239A";
  else if (["PTM", "ORIENTATION"].includes(eventType)) return "#D29822";

  return "#05945B";
};
