import React, { useState } from 'react';
import InputField from './inputField';
import ReactSelect from './reactSelect';
import ReactSelect2 from './reactSelect2';
import StatusToggle from './statusToggle';
import Loader from '../loader';
import { Button } from 'reactstrap';
import { putpost } from '../../../../../../../services/http';
import { failureToast, successToast } from '../../../utils/methods/toasterFunctions/function';
import { customValidation } from '../../../utils/methods/validations/validation';
import UpdateButton from "components/FormComponent/Button/Update/index"

const TableEditField = (props) => {
	const {
		fieldDetails,
		rowIndex,
		tableData,
		setTableData,
		apiDetails,
		setActionExist,
		fetchDetails,
		validationRules,
		saveButtonText,
		permissionSet,
		actionExist
	} = props;
	const [saveClicked, setSaveClicked] = useState(false);
	// console.log('Field details', tableData);
	const fieldsHandler = (item, index) => {
		// console.log('item', item);
		try {
			switch (item.type) {
				case 'text':
					return (
						<InputField
							key={'Input' + index}
							item={item}
							tableData={tableData}
							index={rowIndex}
							setTableData={setTableData}
							saveClicked={saveClicked}
						/>
					);
					break;
				case 'react_select':
					return (
						<ReactSelect
							key={'Input' + index}
							item={item}
							tableData={tableData}
							index={rowIndex}
							setTableData={setTableData}
							saveClicked={saveClicked}
						/>
					);
					break;
				case 'react_select_2':
					return (
						<ReactSelect2
							key={'Input' + index}
							item={item}
							tableData={tableData}
							index={rowIndex}
							setTableData={setTableData}
							saveClicked={saveClicked}
						/>
					);
					break;
				case 'Status':
					return (
						<StatusToggle
							key={'Input' + index}
							item={item}
							tableData={tableData}
							index={rowIndex}
							setTableData={setTableData}
							saveClicked={saveClicked}
						/>
					);
					return;
				default:
					break;
			}
		} catch (e) {
			console.log('Error', e);
		}
	};

	const saveHandler = (index) => {
		try {
			// console.log(index);
			const _tempArray = tableData;
			const tempArrayIndexObj = _tempArray[index];
			let isValid = true;

			// console.log('Temp_array_section_for_the_save', _tempArray);
			// console.log('Temp_array_editMode', _tempArray[index]['editMode']);
			// console.log('Temp_array_IndexObject', tempArrayIndexObj);
			// console.log('validationRules__', validationRules);

			isValid = customValidation(tempArrayIndexObj, validationRules);

			if (!isValid) {
				return;
			}

			setSaveClicked(true);
			if (_tempArray[index]['__status'] === '__new') {
				putpost(
					apiDetails.create,
					(data) => {
						successToast(data['message']);
						setSaveClicked(false);
						delete _tempArray[index]['editMode'];
						delete _tempArray[index]['__status'];
						setTableData(_tempArray);
						setActionExist(false);
						fetchDetails(apiDetails.getAll);
					},
					(data) => {
						failureToast(data['message']);
						setSaveClicked(false);
					},
					_tempArray[index],
					'post'
				);
			} else {
				putpost(
					apiDetails.update + '/' + _tempArray[index].id,
					(data) => {
						successToast(data['message']);
						delete _tempArray[index]['editMode'];
						setTableData(_tempArray);
						// console.log('_tem', _tempArray);
						setActionExist(false);
						setSaveClicked(false);
						fetchDetails(apiDetails.getAll);
					},
					(data) => {
						failureToast(data['message']);
						setSaveClicked(false);
					},
					_tempArray[index],
					'put'
				);
			}
		} catch (e) {
			console.log('Error', e);
		}
	};

	const closeHandler = (index) => {
		try {
			const _tempArr = tableData;
			if (_tempArr[index]['__status'] === '__new') {
				_tempArr.splice(index, 1);
			} else {
				delete _tempArr[index]['editMode'];
			}
			setTableData(_tempArr);
			fetchDetails(apiDetails.getAll);
			setActionExist(false);
		} catch (e) {
			console.log('Error', e);
		}
	};

	// console.log("fields",(permissionSet?.includes('U') || permissionSet?.includes('D')) || (permissionSet?.includes('C') && actionExist))

	return (
		<tr key={'formFields'}>
			{fieldDetails.map((item, index) => (
				<td className={'text-center p-2 mx-1'} key={'tableRow' + index}>
					{fieldsHandler(item, index)}
				</td>
			))}
			{((permissionSet?.includes('U') || permissionSet?.includes('D')) || (permissionSet?.includes('C') && actionExist)) &&
				<td
					className={
						'text-center p-2 mx-1 d-flex justify-content-center align-items-center'
					}
				>
					<UpdateButton
						text={saveClicked ? (<Loader loaderHeight={'20'} loaderWidth={'20'} color={'#ffffff'} />) : (<span>{saveButtonText}</span>)}
						disabled={saveClicked}
						onClick={() => saveHandler(rowIndex)}
					/>
					<Button
						color="danger"
						size="sm"
						type="button"
						disabled={saveClicked}
						onClick={() => closeHandler(rowIndex)}
					>
						{' '}
						<i className="fas fa-times" />
					</Button>
				</td>
			}
		</tr>
	);
};

export default TableEditField;
