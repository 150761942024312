import React, { useEffect, useState } from 'react'
import styles from '../styles.module.scss'
import AAccordion from 'views/pages/manage/common/a-accordion/AAccordion'
import { fetchAllPromisedData, getFormattedAmount } from '../../../common/utils/methods/commonMethods/utilityMethod'
import ADataGrid from 'views/pages/manage/common/data-grid/ADataGrid'
import NoRowOverlay from 'views/pages/manage/masters/holidayMaster/NoRowOverlay'
import { gridStyles, gridComponents } from 'views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant.js'
import { AGridColDef } from 'views/pages/manage/common/data-grid/ADataGridColDef'
import CustomLoader from '../../../common/commonComponents/Loader/loader'
import { endpoint } from '../../../common/constant'
import { getFeeSummaryRow, getTotalUpfrontPayment } from './constant'
import { Tooltip } from '@mui/material'

const getComputedValues = (doInformation, prevTotal, amountTotal) => {
  const upfrontPayment = getTotalUpfrontPayment(doInformation);
  const netLoanAmount = doInformation?.netLoanAmount;

  const remainUpfront = prevTotal > upfrontPayment ? 0 : (upfrontPayment - prevTotal).toFixed(2);
  const remainLoan = !remainUpfront ? (prevTotal - upfrontPayment) > netLoanAmount ? 0 : netLoanAmount - (prevTotal - upfrontPayment) : netLoanAmount;
  // if remain loan is also 0 then amount total payable amount is greater than upfront+loan amount;

  const loanAmount = (() => {
    if (amountTotal > remainUpfront) {
      return remainLoan < (amountTotal - remainUpfront).toFixed(2) ? remainLoan : (amountTotal - remainUpfront).toFixed(2);
    } else {
      return 0;
    }
  })();

  const eligibility = (() => {
    
    if(!amountTotal) {
      return '-'
    }
    
    if (remainUpfront && !loanAmount) { // consumed only from upfront
      return 'To be paid immediately';
    } else if (remainUpfront && loanAmount) { // consumed from upfront and loan both
      return 'To be paid partially';
    } else if (!remainUpfront && loanAmount && loanAmount==amountTotal) { // consumed only from loan
      return 'Covered in EMI';
    } else if (!remainUpfront && loanAmount && amountTotal!=loanAmount) { // consumed from loan and remaining amount to be paid apart from upfront payment
      return 'To be paid partially';
    } else { // consumed from amount to be paid apart from upfront
      return 'To be paid by parent on due date';
    }
  })();

  return {
    loanAmount: loanAmount,
    eligibility: eligibility,
  }
}

const Details = ({ loader, rows, doInformation }) => {

  const columns = [
    new AGridColDef('description', 'Description', false).setMinWidth(100).setFlex(1.5)
      .renderCellComponent(params => (
        <Tooltip title={params?.value}><div className='ellipsis-text-container'>{params?.value}</div></Tooltip>
      )),
    new AGridColDef('baseFee', 'Base fee', false).setMinWidth(100).columnAlign('right').setValueFormatter(params => getFormattedAmount(params?.value)),
    new AGridColDef('concessionApplied', 'Concession applied', false).setMinWidth(100).columnAlign('right').setValueFormatter(params => getFormattedAmount(params?.value)),
    new AGridColDef('taxableAmount', 'Taxable amount', false).setMinWidth(100).columnAlign('right').setValueFormatter(params => getFormattedAmount(params?.value)),
    new AGridColDef('totalTaxes', 'Total taxes', false).setMinWidth(100).columnAlign('right').setValueFormatter(params => getFormattedAmount(params?.value)),
    new AGridColDef('amountTotal', 'Amount total', false).setMinWidth(100).columnAlign('right').setValueFormatter(params => getFormattedAmount(params?.value)),
    new AGridColDef('loanAmount', 'Loan amount', false).setMinWidth(100).columnAlign('right').renderCellComponent(params => <div>{getFormattedAmount(getComputedValues(doInformation, params?.row?.prevTotal, params?.row?.amountTotal)?.loanAmount)}</div>),
    new AGridColDef('eligibility', 'Eligibility', false).setMinWidth(100).columnAlign('right').setFlex(1.5)
      .renderCellComponent(params => (
        <Tooltip title={getComputedValues(doInformation, params?.row?.prevTotal, params?.row?.amountTotal)?.eligibility}>
          <div className='color-red-60 ellipsis-text-container'>{getComputedValues(doInformation, params?.row?.prevTotal, params?.row?.amountTotal)?.eligibility}</div>
        </Tooltip>
      )),
  ]

  return loader ? <CustomLoader /> : (
    <div className='label-color'>
      <ADataGrid
        removeWrapperContainer
        rows={rows || []}
        columns={columns}
        rowId={(row) => JSON.stringify(row)}
        rowHeight={50}
        loading={false}
        serverPagination={false}
        components={{ ...gridComponents, NoRowsOverlay: () => <NoRowOverlay /> }}
        headerHeight={120}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
          },
          ...gridStyles
        }}
        hidePagination
        autoHeight
      />
    </div>
  )
}

const StudentFeeSummary = (props) => {
  const { applicationQuotationDetailsId, mode, doInformation } = props;

  const [apiLoader, setApiLoader] = useState(false);
  const [itemTypeData, setItemTypeData] = useState([]);
  const [rows, setRows] = useState([]);

  const Summary = (
    <div className='heading-4 color-dark'>Student charge and fee summary</div>
  )

  const fetchItemTypeData = async () => {
    setApiLoader(true);
    const res = await fetchAllPromisedData(endpoint.item_type_setup.getAll, true);
    if (res?.code === 200) {
      setItemTypeData(res?.data);
      getFeeBreakup(res?.data);
    } else {
      setItemTypeData([]);
    }
    setApiLoader(false);
  }

  const getFeeBreakup = async (itemTypes = itemTypeData) => {
    setApiLoader(true);
    const res = await fetchAllPromisedData(`${endpoint.savedBreakup}/${applicationQuotationDetailsId}`, true);
    if (res?.code === 200) {
      let prevTotal = 0;
      const structuredRes = res?.data?.studentChargesFeeBreakup?.map(item => {
        const row = getFeeSummaryRow(item, itemTypes, prevTotal)
        prevTotal += row?.amountTotal;
        return row;
      });
      setRows(structuredRes);
    } else {
      setRows([]);
    }
    setApiLoader(false);
  }

  useEffect(() => {
    fetchItemTypeData();
  }, [])


  return (
    <AAccordion
      // initialOpen
      expandDisabled={mode === 'edit'}
      summary={Summary}
      details={
        <Details {...props} loader={apiLoader} rows={rows} doInformation={doInformation} />
      }
    />
  )
}

export default StudentFeeSummary