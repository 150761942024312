import React, { useContext } from "react";
import { ReactComponent as OneDarkBullet } from "../../../../../../assets/img/svg/1-dark-bullet.svg";
import { ReactComponent as TwoFadeBullet } from "../../../../../../assets/img/svg/2-fade-bullet.svg";
import { ReactComponent as TwoDarkBullet } from "../../../../../../assets/img/svg/2-dark-bullet.svg";
import { ReactComponent as ThreeFadeBullet } from "../../../../../../assets/img/svg/3-fade-bullet.svg";
import { ReactComponent as ThreeDarkBullet } from "../../../../../../assets/img/svg/3-dark-bullet.svg";
import { ReactComponent as DoneBullet } from "../../../../../../assets/img/svg/done-bullet.svg";
import { ReactComponent as Stroke } from "../../../../../../assets/img/svg/Stroke.svg";
import { ReactComponent as StrokeDark } from "../../../../../../assets/img/svg/StrokeDark.svg";
import "../../../academicPlanAndTest/createAcademicPlan/style.scss";
import { TimetableContext } from "../contextApi/TimetableContext";

const style = {
  position: "relative",
  minHeight: "60px",
  display: "inline-block",
  minWidth: "64px",
};

const reviewStyle = { ...style, minWidth: "90px" };

const textStyle = {
  position: "absolute",
  left: "-25px",
  bottom: "2px",
  fontSize: "12px",
  fontWeight: "bold",
};

const handIcon = {
  cursor: "pointer",
};
const GetFromProgressBar = ({
  step,
  setStep,
  clickHandler = () => {},
  mode = "NEW",
  isReadyToPublish,
  isLectureListEmpty,
  disabledNext,
}) => {
  const { isResetEnabled } = useContext(TimetableContext);
  switch (step) {
    case 1:
      return (
        <div className="mt-1">
          <span style={style}>
            {mode === "NEW" || isResetEnabled ? (
              <OneDarkBullet />
            ) : (
              <DoneBullet />
            )}
            <Stroke />
            <span className="bottom-label active-label">Test details</span>
          </span>
          <span style={style}>
            {mode === "NEW" || isResetEnabled ? (
              <TwoFadeBullet />
            ) : (
              <DoneBullet onClick={ !isReadyToPublish ?()=>{}:() => clickHandler("ACADEMIC_LECTURE")} />
            )}
            <span className="bottom-label">Lecture details</span>
            {mode !== "VIEW" && <Stroke />}
          </span>
          {mode !== "VIEW" && (
            <span style={reviewStyle}>
              <ThreeFadeBullet
                onClick={() =>
                  mode === "NEW" || isResetEnabled ? null :  !isReadyToPublish ? ()=>{}:clickHandler("REVIEW_PUBLISH")
                }
                style={mode === "NEW" || isResetEnabled ? {} : handIcon}
              />
              <span className="bottom-label">Review and publish</span>
            </span>
          )}
        </div>
      );
    case 2:
      return (
        <div className="mt-1">
          <span style={style}>
            <DoneBullet
              onClick={() => clickHandler("TIMETABLE")}
              style={handIcon}
            />
            <StrokeDark />
            <span className="bottom-label">Test details</span>
          </span>
          <span style={style}>
            {mode === "NEW" || isResetEnabled ? (
              <TwoDarkBullet />
            ) : (
              <DoneBullet onClick={() => clickHandler("ACADEMIC_LECTURE")} />
            )}
            <span className="bottom-label active-label">Lecture details</span>
            {mode !== "VIEW" && <Stroke />}
          </span>
          {mode !== "VIEW" && (
            <span style={reviewStyle}>
              <ThreeFadeBullet
                onClick={() =>
                  mode === "NEW" || isResetEnabled
                    ? null
                    : clickHandler("REVIEW_PUBLISH")
                }
                style={mode === "NEW" || isResetEnabled ? {} : handIcon}
              />
              <span className="bottom-label">Review and publish</span>
            </span>
          )}
        </div>
      );
    case 3:
      return (
        <div className="mt-1">
          <span style={style}>
            <DoneBullet
              onClick={() => clickHandler("TIMETABLE")}
              style={handIcon}
            />
            <StrokeDark />
            <span className="bottom-label">Test details</span>
          </span>
          <span style={style}>
            {mode === "NEW" ? (
              <DoneBullet onClick={() => clickHandler("ACADEMIC_LECTURE")} />
            ) : (
              <DoneBullet onClick={() => clickHandler("ACADEMIC_LECTURE")} />
            )}
            <StrokeDark />
            <span className="bottom-label">Lecture details</span>
          </span>
          <span style={reviewStyle}>
            <ThreeDarkBullet onClick={() => clickHandler("REVIEW_PUBLISH")} />
            <span className="bottom-label active-label">
              Review and publish
            </span>
          </span>
        </div>
      );

    default:
      break;
  }
};

export default GetFromProgressBar;
