import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { GridRowModes } from "@mui/x-data-grid";
import { newRow } from "./constant";
import { AGridColDef } from "views/pages/manage/common/data-grid/ADataGridColDef";
import ADataGrid from "views/pages/manage/common/data-grid/ADataGrid";
import { editCellContainerStyle } from "views/pages/manage/edpOps/stockManagement/mastersAndMapping/onBoardingMaterialIdSetup/constant";
import { IoEyeOutline } from "react-icons/io5";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { saveOrDeleteMsg } from "views/pages/manage/edpOps/stockManagement/mastersAndMapping/sapProfitCostCentreMapping/constant";
import { gridStyles } from "views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant";
import { gridComponents } from "views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant";
import { selectStyles } from "views/pages/manage/masters/holidayMaster/common";
import MultiSelectCellComponentWithSearch from "views/pages/manage/common/commonComponents/SearchFilterView/MultiSelectCellComponentWithSearch";
import { isObjEmpty } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { generateRandomId } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import AAutoComplete from "views/pages/manage/common/form-fields-mui/AAutoComplete";
import ActionsCellComponent from "./ActionCellComponent";
import moment from "moment";
import { Tooltip } from "@mui/material";
import NoRowOverlay from "views/pages/manage/masters/holidayMaster/NoRowOverlay";
import { getFormattedDate } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
//import StatusCellComponent from "./StatusCellComponent";
import { failureToast } from "views/pages/manage/common/utils/methods/toasterFunctions/function";
import AInput from "views/pages/manage/common/formFeilds/customInput/AInput";
import StatusCellComponent from "./StatusCellComponent";
import CustomChip from "views/pages/manage/common/customChip/CustomChip";
const ListComponent = forwardRef(
  (
    {
      currentPage = 0,
      rowCount,
      nextButtonHandler,
      dropdown,
      rows,
      loader,
      createHandler,
      updateHandler,
      isNew,
      setIsNew,
      toggleStatusHandler,
    },
    ref
  ) => {
    const [rowModesModel, setRowModesModel] = useState({});

    const [rowData, setRowData] = useState(rows);
    useEffect(() => setRowData(rows), [rows]);
    const [rowForm, setRowForm] = useState({});
    const rowFormHandler = (value, key) =>
      setRowForm((prev) => ({ ...prev, [key]: value }));
    const startEditing = (id, form) => {
      setRowModesModel((oldModel) => ({ [id]: { mode: GridRowModes.Edit } }));
      setRowForm(form);
    };

    const stopEditing = () => {
      const currForm = { ...rowForm };
      setRowModesModel((oldModel) => ({
        [currForm?.id]: { mode: GridRowModes.View, ignoreModifications: true },
      }));
      setRowForm({});
    };

    const handleEditClick = (params) => {
      if (!isObjEmpty(rowForm)) {
        failureToast(saveOrDeleteMsg);
      } else {
        startEditing(params?.id, params?.row);
      }
    };

    const handleCancelClick = async (params) => {
      if (params?.row?.isNew) {
        setRowData(rows);
        setRowForm({});
        setIsNew(false)
      } else {
        stopEditing(params?.id);
      }
    };

    const handleSaveClick = async (params) => {
      if (rowForm?.isNew) {
        await createHandler(rowForm);
        setIsNew(false)
      } else {
        await updateHandler(rowForm);
      }
    };

    const addNewRow = async () => {
      if (!isObjEmpty(rowForm)) {
        failureToast(saveOrDeleteMsg);
      } else {
        setIsNew(true)
        let randomId = generateRandomId();
        let emptyRow = { ...newRow, isNew: true, id: randomId };
        startEditing(randomId, emptyRow);
        setRowData((prev) => [emptyRow, ...prev]);
        // setRowData(prev => [
        //   ...prev?.slice(0, page * 50),
        //   emptyRow,
        //   ...prev?.slice(page * 50, prev?.length + 1),
        // ]);
      }
    };

    useImperativeHandle(ref, () => ({
      addNewRow: addNewRow,
      startEditing: startEditing,
      stopEditing: stopEditing,
    }));

    // const formatValue = (val) => {
    //   const arr = val.split(" ");
    //   return `${arr[0]} ${arr[1]}`;
    // };
    const columns = [
      new AGridColDef("businessGroup", "Business Group", false)
        .setMinWidth(110)
        .editable(isNew)
        .setFlex(0.5)
        .renderCellComponent((params) => (
          params?.value ? <div><Tooltip title={params?.value}>
            <div className={`ellipsis-text-container`}>{params?.value}</div>
          </Tooltip></div> : <div>-</div>
        ))
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            <AAutoComplete
              currentValue={rowForm?.businessGroup}
              handleChange={(value) => rowFormHandler(value, "businessGroup")}
              items={dropdown?.businessGroup}
              isMulti={false}
              style={selectStyles}
              autoFocus={params?.hasFocus}
            />
          </div>
        )),

      new AGridColDef("companyCode", "Company Code", false)
        .setMinWidth(120)
        .editable(isNew)
        .setFlex(0.5)
        .renderCellComponent((params) => (
          params?.value ? <div><Tooltip title={params?.value}>
            <div className={`ellipsis-text-container`}>{params?.value}</div>
          </Tooltip></div> : <div>-</div>
        ))
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            <AAutoComplete
              currentValue={rowForm?.companyCode}
              handleChange={(value) => rowFormHandler(value, "companyCode")}
              items={dropdown?.companyCode}
              isMulti={false}
              style={selectStyles}
              autoFocus={params?.hasFocus}
            />
          </div>
        )),
      new AGridColDef("academicCareer", "Academic Career", true)
        .setMinWidth(110)
        .editable(isNew)
        .setFlex(0.5)
        .renderCellComponent((params) => (
          params?.value ? <div><Tooltip title={params?.value}>
            <div className={`ellipsis-text-container`}>{params?.value}</div>
          </Tooltip></div> : <div>-</div>
        ))
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            <AAutoComplete
              currentValue={rowForm?.academicCareer}
              handleChange={(value) => rowFormHandler(value, "academicCareer")}
              items={dropdown?.academicCareer}
              isMulti={false}
              style={selectStyles}
              autoFocus={params?.hasFocus}
            />
          </div>
        )),
      new AGridColDef("itemType", "Min Item Type", true)
        .setMinWidth(130)
        .editable(true)
        .setFlex(0.5)
        .renderCellComponent((params) => (
          params?.value ? <div><Tooltip title={params?.value}>
            <div className={`ellipsis-text-container`}>{params?.value}</div>
          </Tooltip></div> : <div>-</div>
        ))
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            <AInput
              value={rowForm.itemType}
              handler={(value) => rowFormHandler(value, "itemType")}
              maxLength="50"
              placeholder="Enter Item type"
              onKeyPress={(e) => {
                if (
                  !/^[0-9]*$/.test(
                    String.fromCharCode(e.charCode)
                  )
                ) {
                  e.preventDefault();
                  return;
                }
              }}
              style={{ height: "38px", borderRadius: "5px" }}
            />
          </div>
        )),
      new AGridColDef("mode", "Mode", false)
        .setMinWidth(110)
        .editable(isNew)
        .setFlex(0.5)
        .renderCellComponent((params) => (
          params?.value ? <div><Tooltip title={params?.value}>
            <div className={`ellipsis-text-container`}>{params?.value}</div>
          </Tooltip></div> : <div>-</div>
        ))
        .renderEditCellComponent((params) => (
          <div style={editCellContainerStyle}>
            <AAutoComplete
              currentValue={rowForm?.mode}
              handleChange={(value) => rowFormHandler(value, "mode")}
              items={dropdown?.mode}
              isMulti={false}
              style={selectStyles}
              autoFocus={params?.hasFocus}
            />
          </div>
        )),
      new AGridColDef("status", "Status", false)
        .setMinWidth(100)
        .setFlex(0.5)
        .editable(true)
        .renderCellComponent((params) => (
          <CustomChip
            text={params?.value}
            textColor={params?.value == 'ACTIVE' ? '#07794C' : '#DC2804'}
            bgColor={params?.value == 'ACTIVE' ? '#E6F4ED' : '#FCEEED'}
          // style={{ fontSize: 12,fontWeight:'400px', fontFamily: 'Gilroy-SemiBold' }}
          />
        ))
        .renderEditCellComponent((params) => (<StatusCellComponent
          params={params}
          toggleStatusHandler={toggleStatusHandler}
          rowForm={rowForm}
          rowFormHandler={rowFormHandler}
        />))
      ,
      new AGridColDef("createdBy", "Created By", false)
        .setMinWidth(120)
        .setFlex(0.5)
        .renderCellComponent((params) => (
          params?.value ? <div><Tooltip title={params?.value}>
            <div className={`ellipsis-text-container`}>{params?.value}</div>
          </Tooltip></div> : <div>-</div>
        ))
      ,

      new AGridColDef("createdOn", "Created on", false)
        .setMinWidth(130)
        .setFlex(0.5)
        .renderCellComponent((params) =>
          params?.value ? (
            <div>
              {moment(params?.value)?.format("DD-MMM-YYYY")}
              <Tooltip
                title={`${getFormattedDate(params?.value)},
                    ${moment(params?.value).format("LT")}`}
              >
                <span>
                  <IoMdInformationCircleOutline
                    className="ml-2 pointer"
                    size={18}
                  />
                </span>
              </Tooltip>
            </div>
          ) : (
            <div>-</div>
          )
        ),
      new AGridColDef("updatedBy", "Updated By", false)
        .setMinWidth(120)
        .editable(true)
        .setFlex(0.5)
        .renderCellComponent((params) => (
          params?.value ? <div><Tooltip title={params?.value}>
            <div className={`ellipsis-text-container`}>{params?.value}</div>
          </Tooltip></div> : <div>-</div>
        ))
      ,
      new AGridColDef("actions", "Actions", false)
        .setMinWidth(130)
        .columnAlign("right")
        .setFlex(0.5)
        .renderCellComponent((params) => (
          <>
            <ActionsCellComponent
              params={params}
              handleEditClick={handleEditClick}
              handleCancelClick={handleCancelClick}
              handleSaveClick={handleSaveClick}
              rowForm={rowForm}
            // deleteHandler={deleteHandler}
            />
          </>
        )
        ),
    ];
    return (
      <div className="mt-3">
        <ADataGrid
          removeWrapperContainer
          rowModesModel={rowModesModel}
          rows={rowData || []}
          columns={columns}
          rowId={(row) => row?.id}
          rowHeight={50}
          rowCount={rowCount}
          loading={false}
          currentPage={currentPage}
          nextButtonHandler={nextButtonHandler}
          components={{
            ...gridComponents,
            NoRowsOverlay: () => (
              <NoRowOverlay message={loader ? "Loading..." : null} />
            ),
          }}
          sx={{
            ...gridStyles,
          }}
          autoHeight
          hidePagination={rowCount <= 50}
        />
      </div>
    );
  }
);
export default ListComponent;
