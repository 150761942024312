import { GridRowModes } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { getViewBarcodeColumns } from "./helper";
import CustomChip from "views/pages/manage/common/customChip/CustomChip.jsx";
import { gridStyles } from "../../selectMeterialAndStock/constant";
import { Dialog } from "@mui/material";
import ADataGrid from "../../../../common/data-grid/ADataGrid";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'
import { getFormattedDate } from "../../../../common/utils/methods/commonMethods/utilityMethod";
import { useGetDispatchSchedular } from "../../customhooks";
import ALoader from "../../../../common/a-loader";
import { fetchTransactionsData, issueStockApi } from "../../service";
import { chipStyle } from "../../stockTxns/style";
import CustomCopyToClipboard from "../../../../common/ACopyToClipboard/CustomCopyToClipboard";
import {
  ColoredChip,
  IssuedDetailsInfo,
} from "../../selectMeterialAndStock/issueStock/TableColumn";

function ViewBarcodePopup({ open, setOpen, applicationData, apiLoader }) {
  const [isLoading, setLoading] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState({});
  const [schedularData, isFetchingData] = useGetDispatchSchedular(
    applicationData?.dispatchScheduleId
  );

  useEffect(() => {
    if (applicationData?.transactionId) fetchTableData(schedularData);
  }, [applicationData?.transactionId]);

  const fetchTableData = async () => {
    try {
      setLoading(true);
      const { businessArea, transactionId } = applicationData;
      const response = await fetchTransactionsData(businessArea, transactionId);
      if (response?.data) setTransactionDetails(response?.data[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog onClose={setOpen} open={open} maxWidth="lg">
        <div style={{ padding: "28px 24px", minWidth: "52rem" }}>
          {(isFetchingData || apiLoader || isLoading) && (
            <ALoader position="fixed" />
          )}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="color-dark heading-4">
              Materials and mapped barcodes
            </div>
            <IconButtonWrapper onClick={setOpen}><CrossIcon width={32} height={32} /></IconButtonWrapper>
          </div>
          <div className="mr-2 gap-md d-flex">
            <CustomChip style={chipStyle} className="body-regular">
              Transaction ID: : {transactionDetails?.transactionId}
            </CustomChip>
            <CustomChip style={chipStyle} className="body-regular">
              Issue details: {getFormattedDate(transactionDetails?.issueDate)}{" "}
              <IssuedDetailsInfo stock={transactionDetails} />
            </CustomChip>
            <CustomChip style={chipStyle} className="body-regular">
              SAP document no.: {transactionDetails?.sapDocId}
              <CustomCopyToClipboard
                textToCopy={transactionDetails?.sapDocId}
              />
            </CustomChip>
            <ColoredChip value={transactionDetails?.status} />
          </div>
          <div
            className="m-2"
            style={{ border: "1px solid #CBCBCB", borderRadius: "8px" }}
          >
            <ADataGrid
              removeWrapperContainer
              rows={transactionDetails.materialDetails || []}
              columns={getViewBarcodeColumns(applicationData?.dispatchId)}
              rowId={(row) => row.barcode}
              rowHeight={50}
              loading={false}
              serverPagination={false}
              autoHeight
              hidePagination
              sx={gridStyles}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default ViewBarcodePopup;
