import React, { useContext } from "react";
import CustomTable from "../../common/commonComponents/customTable";
import { endpoint } from "../../common/constant";
import moment from "moment";
import validationRules from "./validationRule.json";
import { ButtonText } from "variables/Buttons";
import { PermissionContext, RolePermissions } from "../../../../../appContext";
import { pages } from "../../common/constant";
import { PermisionDenied } from "../../common/commonComponents";

const LanguageMaster = () => {
  const permissions = useContext(PermissionContext);

  const userPermissions = RolePermissions(
    permissions,
    pages['languageMaster']['id']
  );

  const tableDetails = {
    headerDetails: [
      { name: "Key", required: true },
      { name: "Description", required: true },
      { name: "Display Value", required: true },
      { name: "Effective Date" },
      { name: "Status" },
      { name: "Action" },
    ],
    apiDetails: {
      create: endpoint.languageMaster.create,
      update: endpoint.languageMaster.update,
      delete: endpoint.languageMaster.delete,
      getAll: endpoint.languageMaster.getAll,
    },
    listDetails: {
      languageKey: {
        normal: true,
      },
      languageDesc: {
        normal: true,
      },
      languageDispValue: {
        normal: true,
      },
      effectiveDate: {
        normal: false,
        type: "DATE",
        format: "DD-MM-YYYY",
      },
      status: {
        normal: false,
        type: "TOGGLE",
      },
      action: {
        type: "ACTION",
        button: [
          {
            // icon: "fas fa-edit",
            color: "info",
            actionType: "EDIT",
            permisionRequired : 'U'
          },
            {
              // icon: "fas fa-trash",
              color: "info",
              actionType: "DELETE",
              permisionRequired : 'D'
            },
        ],
      },
    },
    fieldDetails: [
      {
        type: "text",
        minLength: "1",
        maxLength: "1",
        regx: /^[A-Za-z]*$/i,
        regxValidation: true,
        placeHolder: "Key",
        propertyName: "languageKey",
      },
      {
        type: "text",
        minLength: "1",
        maxLength: "25",
        regx: /^[A-Za-z]*$/i,
        regxValidation: true,
        placeHolder: "Description",
        propertyName: "languageDesc",
        // isFilter: true,
      },
      {
        type: "text",
        minLength: "1",
        maxLength: "25",
        regx: /^[A-Za-z]*$/i,
        regxValidation: true,
        placeHolder: "Display Value",
        propertyName: "languageDispValue",
        disabledOnEdit: true,
      },
      {
        type: "text",
        placeHolder: "Effective Date",
        propertyName: "effectiveDate",
        isDisabled: true,
        defaultValue: moment().format("DD-MM-YYYY"),
        format: "DD-MM-YYYY",
      },
      {
        type: "Status",
        propertyName: "status",
      },
    ],
    isSearch: false,
    isNew: true,
  };

  const TableHeader = () => {
    return (
      <div className={"d-flex justify-content-between"}>
        <h3 className="mb-0 floatLeft">Language Master</h3>
      </div>
    );
  };

  return  userPermissions ?  
    <CustomTable 
      tableHeader={TableHeader} 
      tableDetails={tableDetails} 
      validationRules={validationRules} 
      saveButtonText={'Save'} 
      permissionSet={userPermissions}
      addButtonText={ButtonText.LANGUAGEMASTER.ADD}
    /> 
    : <PermisionDenied />;
};

export default LanguageMaster;
