import React, { useEffect, useState } from 'react'
import { fetchTransactionsData, searchStockTransactions } from '../service';
import { useParams } from 'react-router';
import ALoader from '../../../common/a-loader';
import styles from './styles.module.scss';
import AButton from '../../../common/form-fields-mui/AButton';
import { Tooltip } from '@mui/material';
import { ReactComponent as CsvIcon } from "assets/img/svg/excel.svg";
import ADataGrid from '../../../common/data-grid/ADataGrid';
import { gridComponents, gridStyles } from '../../issuanceIDcard/printIdCardAndMapRfid/constant';
import { getTransactionHistory } from '../selectMeterialAndStock/issueStock/TableColumn';

const StockReversalHistory = ({
  transactionDetails,
  setTransactionDetails = () => {},
  setFailedReason = () => {},
  downloadHandler,
}) => {
  const { business, id, buValue, type, academicCareer } = useParams();
  const [transactionDetailsLoader, setTransactionDetailsLoader] =
    useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [rows, setRows] = useState([]);

  const fetchTransactionDetails = async () => {
    setTransactionDetailsLoader(true);
    const res = await searchStockTransactions(
      {
        businessArea: { label: buValue, value: business },
        transactionId: id,
        groupBy: 'Transaction',
        type: 'PSID_REVERSAL',
      },
      0
    );
    if (res?.data) setTransactionDetails(res?.data?.stockTransactionAudList[0]);
    setTransactionDetailsLoader(false);
  };
  const fetchTableData = async () => {
    try {
      setApiLoader(true);
      const response = await fetchTransactionsData(business, id, '');
      setApiLoader(false);
      if (response?.data) {
        setRows(response?.data);
        response.data?.length > 0 &&
          setFailedReason(response.data[0]?.errorMessage);
      }
    } catch (error) {
      setApiLoader(false);
    }
  };

  useEffect(() => {
    fetchTransactionDetails();
    fetchTableData();
  }, []);
  return (
    <div>
      {(transactionDetailsLoader || apiLoader) && (
        <ALoader position={'fixed'} />
      )}
      <div className={styles['reversal-container']}>
        <div className='d-flex flex-row-reverse'>
          <AButton onClick={downloadHandler}>
            <Tooltip title='Download the transaction details as csv'>
              <span>
                <CsvIcon /> <span className='ml-1'>Download</span>
              </span>
            </Tooltip>
          </AButton>
        </div>
        <div className='mt-2'>
          <ADataGrid
            rows={rows || []}
            columns={getTransactionHistory(academicCareer)}
            rowId={row => JSON?.stringify(row)}
            serverPagination={false}
            rowHeight={50}
            height={100}
            loading={false}
            hideFooterSelectedRowCount={true}
            components={gridComponents}
            sx={gridStyles}
            autoHeight
          />
        </div>
      </div>
    </div>
  );
};

export default StockReversalHistory