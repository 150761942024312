import React from "react";

import { useHistory } from "react-router";
import { AGridColDef } from "views/pages/manage/common/data-grid/ADataGridColDef";
import ADataGrid from "views/pages/manage/common/data-grid/ADataGrid";
import { Card } from "@material-ui/core";
import { CardBody } from "reactstrap";
import StudentListTableHeader from "./StudentListTableHeader";
import NoRowOverlay from 'views/pages/manage/masters/holidayMaster/NoRowOverlay.jsx'
import PrintIdCardBtn from "../printIDCard";
import { gridComponents, gridStyles, isValidPhotoKey } from "../printIdCardAndMapRfid/constant";
import { Tooltip } from "@mui/material";
import AButton from '../../../common/form-fields-mui/AButton'
import PhotoCellComponent from "../printIdCardAndMapRfid/printIdCard/PhotoCellComponent";

const StudentListDataTable = ({
  rowsData,
  rowCount,
  nextButtonHandler,
  currentPage,
  isListLoading
}) => {
  const history = useHistory();

  const ListHeader = [
    new AGridColDef("psid", "PSID")
      .setWidth(200)
      .renderCellComponent((params) => (
        <Tooltip title={params?.value}>
          <div
            onClick={() => window.open(history.createHref({ pathname: `/admin/edp/studentDetails/${params.value}` }), '_blank')}
            className={`ellipsis-text-container pointer color-primary`}
          >
            {params?.value}
          </div>
        </Tooltip>
      )),
    new AGridColDef("applicationId", "Application No.")
      .setWidth(200)
      .renderCellComponent((params) => (
        <span>
          {params.value}
        </span>
      ))
    ,
    new AGridColDef("studentName", "Name")
      .setWidth(200)
      .renderCellComponent((params) => (
        <span>{params.value}</span>
      )),

    new AGridColDef("programAction", "Program")
      .setWidth(200)
      .renderCellComponent((params) => (
        <span>{params.value}</span>
      )),
    new AGridColDef('photoKey', 'Photo', false).setMinWidth(100).setFlex(0.3)
      .renderCellComponent(params => <PhotoCellComponent params={params} />),

    new AGridColDef("Actions", "Actions", false).columnAlign('right').renderCellComponent((params) => (
      <PrintIdCardBtn
        data={[{ psid: params.row?.psid, applicationId: params?.row?.applicationId }]}
        rowData={[params?.row]}
        hideStepperBarPostPrint
        customTiggerButton
        customTriggerButtonComponent={() => <AButton size="xs" variant="primary_filled" disabled={!params?.row?.isEligibleProgramActionForAllocation || !isValidPhotoKey(params?.row?.photoKey)}>Preview and Print</AButton>}
      />
    )),
  ];

  return (
    <Card className="h-100">
      <CardBody className='d-flex flex-column h-100'>
        <StudentListTableHeader />
        <ADataGrid
          rows={rowsData || []}
          columns={ListHeader}
          rowId={(row) => row.applicationId}
          loading={false}
          rowCount={rowCount || 0}
          nextButtonHandler={nextButtonHandler}
          currentPage={currentPage}
          // sortColumnName="psid"
          components={{ ...gridComponents, NoRowsOverlay: () => <NoRowOverlay message={isListLoading ? 'Loading...' : "No New admissions found. Search students to proceed"} /> }}
          sx={gridStyles}
        />
      </CardBody>
    </Card>
  );
};

export default StudentListDataTable;
