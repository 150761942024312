import React from 'react'
import APopover from 'views/pages/manage/common/a-popover';
import AButton from 'views/pages/manage/common/form-fields-mui/AButton';
import AInput from 'views/pages/manage/common/formFeilds/customInput/AInput'
import DaysPlanSearchFilterForm from './DaysPlanSearchFilterForm';


const highlightedStyle = {
  border: "1px solid #00B0F5",
  color: "#00B0F5",
};
const normalStyle = {
  border: "1px solid #CCCCCC",
};
const DaysPlanSearchFilter = ({
  filterCount = 0,
  filterForm,
  filterFormHandler,
  applyNameFilterHandler,
  clearNameFilterHandler,
  applyFilterHandler,
  removeFilterHandler,
  regionData,
  businessAreaData,
  setBusinessAreaData,
}) => {

  const onKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      applyNameFilterHandler()
    }
  }

  return (
    <div className='d-flex justify-content-between align-items-center'>
      <div className='heading-4 color-dark'>Days plan</div>
      <div className='d-flex align-items-center'>
        <AInput
          type='text'
          placeholder={'Search here'}
          value={filterForm?.daysPlanName}
          searchIcon
          crossIcon
          handler={(value) => filterFormHandler(value, 'daysPlanName')}
          onKeyDown={onKeyDown}
          crossHandler={() => { filterFormHandler('', 'daysPlanName'); clearNameFilterHandler(); }}
          searchIconClickHandler={applyNameFilterHandler}
          style={{ borderRadius: '4px', height: '28px', ...normalStyle }}
        />
        <APopover
          buttonComponent={(onClick, styles) => (
            <AButton
              onClick={onClick}
              updatedStyle={{ minWidth: '61px', height: '28px', ...(filterCount > 0 ? highlightedStyle : styles) }}
            >
              Filter {filterCount > 0 && <>( {filterCount} )</>}
            </AButton>
          )}
          menuComponent={(closeForm) =>
            <DaysPlanSearchFilterForm
              closeForm={closeForm}
              filterCount={filterCount}
              filterForm={filterForm}
              filterFormHandler={filterFormHandler}
              applyFilterHandler={applyFilterHandler}
              removeFilterHandler={removeFilterHandler}
              regionData={regionData}
              businessAreaData={businessAreaData}
              setBusinessAreaData={setBusinessAreaData}
            />
          }
          maxHeight={'calc(100% - 200px)'}
          highlightedStyle={highlightedStyle}
          normalStyle={normalStyle}
        />
      </div>
    </div>
  )
}

export default DaysPlanSearchFilter