import React, { useCallback, useEffect, useRef, useState } from "react";
import Select, { components } from 'react-select';
import { FormGroup, InputLabel } from "@mui/material";
import { AAutoCompleteCustomStyles, AAutoCompleteMenuList, AAutoCompleteMultiValue, AAutoCompleteMultiValueWithCount, AAutoCompleteOption, AAutoCompleteDropdownIndicator } from "../../../../common/form-fields-mui/AAutoComplete";
import { InputLabelStyle } from "../../../../common/form-fields-mui/ASelect";
import { autoCompleterDropdownMapping, fetchAllPostPromisedData } from "../../../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "../../../../common/constant";
import { autoCompleterReqBody } from './constant';
const _ = require("lodash");

const isValidUserInput = value => value?.length > 3;

const Input = (props) => <components.Input {...props} isHidden={false} />;

const SelectMaterialElasticSearch = ({
  label = "",
  isMulti = true,
  // items, // this is of no use as we'll fetch options from API call
  currentValue,
  handleChange = () => { },
  isShowCount = isMulti,
  placeholder,
  isLoading = false,
  isDisabled = false,
  menuPlacement = "auto",
  height = '31px',
  fontSize = '12px',
  style,
  customStylesUpdate,
  isAllEnabled = false,
  labelStyle = {},
  selectAllMenuButton = isAllEnabled ? false : true,
  noOptionsMessage = "No options",
  autoFocus = false,
  dropdownMappingParams = ['materialIdAndDescription', 'id', 'materialId', 'baseUnitOfMeasure', 'materialDescription'],
  dropdownMappingAdditionalParams = ['serialNumberProfile'], // use this if extra info keys are needed other than abovr mentioned keys in dropdown entity
  isDlpSearch,
  isDigitalSearch,
  ...props
}) => {

  const selectRef = useRef();
  const customStyles = AAutoCompleteCustomStyles(style, height, fontSize, isMulti, selectAllMenuButton);
  const [defaultOptions, setDefaultOptions] = useState(currentValue ? isMulti ? currentValue : [currentValue] : []);

  const [userInput, setUserInput] = useState('');
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [options, setOptions] = useState(defaultOptions);

  useEffect(() => {
    !isMulti && setOptions(currentValue ? isMulti ? currentValue : [currentValue] : [])
    setDefaultOptions(currentValue ? isMulti ? currentValue : [currentValue] : [])
    !isMulti && setUserInput(currentValue?.label);
  }, [currentValue])

  const fetchOptions = async (inputValue) => {
    const res = await fetchAllPostPromisedData(
      `${endpoint.autoComplete.search}`,
      { ...autoCompleterReqBody(isDlpSearch, isDigitalSearch), value: inputValue },
      "put"
    )
    return autoCompleterDropdownMapping(res, ...dropdownMappingParams, ...dropdownMappingAdditionalParams);
  }

  const setOptionsAsync = async (inputValue) => {
    setOptionsLoading(true);
    const res = await fetchOptions(inputValue);
    setOptions(res);
    setOptionsLoading(false);
  }

  const onInputChange = (value) => {
    if (value) {
      if (isValidUserInput(value)) {
        setOptionsAsync(value);
      } else {
        setOptions(defaultOptions);
      }
    }
  }

  const debouncedInputChangeHandler = useCallback(
    _.debounce(onInputChange, 300),
    [defaultOptions]
  );

  const onMenuClose = useCallback(() => {
    setUserInput(isMulti ? '' : defaultOptions?.[0]?.label);
    setOptions(defaultOptions);
  }, [defaultOptions])

  const onFocus = () => {
    currentValue && selectRef.current.select.inputRef.select();
  };

  return (
    <div>
      <FormGroup>
        {label && (
          <InputLabel sx={{ ...InputLabelStyle, ...labelStyle }}>
            {label}
          </InputLabel>
        )}
        <Select
          ref={selectRef}
          isDisabled={isDisabled || false}
          placeholder={placeholder || "Select"}
          styles={{ ...customStyles, ...customStylesUpdate }}
          value={currentValue}
          isMulti={isMulti}
          isLoading={isLoading || optionsLoading}
          hideSelectedOptions={false}
          cacheOptions
          options={options}
          onChange={handleChange}
          inputValue={userInput}
          onInputChange={(value, action) => { 
            if(action?.action==='input-change') {
              setUserInput(value); debouncedInputChangeHandler(value);
            }
          }}
          onMenuClose={() => {
            onMenuClose();
          }}
          closeMenuOnSelect={!isMulti}
          components={{
            Option: AAutoCompleteOption,
            MenuList: AAutoCompleteMenuList,
            ...{ MultiValue: isShowCount ? AAutoCompleteMultiValueWithCount : AAutoCompleteMultiValue },
            DropdownIndicator: null,
            Input,
          }}
          isClearable={!isMulti}
          menuPlacement={menuPlacement}
          menuPortalTarget={document.body}
          menuShouldScrollIntoView={false}
          selectAllMenuButton={selectAllMenuButton}
          noOptionsMessage={() => isValidUserInput(userInput) ? noOptionsMessage : 'Type at least 4 digit to search'}
          autoFocus={autoFocus}
          onFocus={onFocus}
          controlShouldRenderValue={isMulti}
        />
      </FormGroup>
    </div>
  )
}

export default SelectMaterialElasticSearch