import { Checkbox, FormControlLabel } from '@mui/material'
import React from 'react'

const checkBoxStyle = { borderRadius: "6px", padding: '0 5px' };
const formControlLabeStyle = {marginBottom: 0, marginRight: 0 };

const BulkSelectionCheckboxes = ({ searchInputParam, setSearchInputParam }) => {
  return (
    <div className='d-flex justify-content-between align-items-center' style={{ width: '340px', padding: '0.5rem' }}>
      <div>
        <FormControlLabel
          sx={formControlLabeStyle}
          control={
            <Checkbox
              onChange={(e) => e.target.checked && setSearchInputParam("MANUAL")}
              checked={searchInputParam === 'MANUAL'}
              sx={checkBoxStyle}
            />
          }
          label={<span style={{ fontSize: '12px' }}>Manual - Enter RFID number</span>}
        />
      </div>
      <div>
        <FormControlLabel
          sx={formControlLabeStyle}
          control={
            <Checkbox
              onChange={(e) => e.target.checked && setSearchInputParam("BULK")}
              checked={searchInputParam === 'BULK'}
              sx={checkBoxStyle}
            />
          }
          label={<span style={{ fontSize: '12px' }}>Bulk upload .csv</span>}
        />
      </div>
    </div>
  )
}

export default BulkSelectionCheckboxes