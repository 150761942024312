import moment from "moment";
import React, { useState, useRef, useEffect } from "react";
import DatePicker, { Calendar } from "react-multi-date-picker";
import CustmCheckbox from '../../../common/formFeilds/customCheckbox'
import "./index.css";


const getAllDates = (startDate, endDate) => {
  const now = moment(startDate), dates = [];
  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format('MM-DD-YYYY'));
    now.add(1, 'days');
  }
  return dates;
}


const DontHaveFocus = (ref, cb) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const CustomDatePickerV4 = (props) => {

  const { value,
    disabled,
    minDate = new Date(), onChange = () => {} } = props;

  const wrapperRef = useRef(null);
  const [dateValues, setDateValues] = useState('');
  const [selectedOption, setSelectedOption] = useState('DOES_NOT_REPEAT')
  const [showCalendar, setShowCalendar] = useState(false);
  const [cursorOverCalendar, setCursorOverCalendar] = useState(false);
  const weekDays = ["S", "M", "T", "W", "T", "F", "S"];

  const cb = () => {
    setShowCalendar(false);
    setCursorOverCalendar(false);
  }

  const onWeekendSelection = () => {
    const dateArr = getAllDates(minDate, moment(minDate).add(30, 'days').toDate());
    // console.log("dateArr",dateArr)
    const weekendDates = dateArr.filter(date => moment(date,'MM-DD-YYYY').format('dddd') === 'Sunday' || moment(date,'MM-DD-YYYY').format('dddd') === 'Saturday');

    // console.log("weekendDates",weekendDates)
    const dateObject = weekendDates.map(item => moment(item,'MM-DD-YYYY').toDate())
    setDateValues([...dateObject])
    onChange({option: 'WEEKEND', dates : dateObject})
  }

  const onWeekDaySelection = () => {
    const dateArr = getAllDates(minDate, moment(minDate).add(30, 'days').toDate());
    const weekDates = dateArr.filter(date => moment(date,'MM-DD-YYYY').format('dddd') !== 'Sunday' && moment(date,'MM-DD-YYYY').format('dddd') !== 'Saturday');
    const dateObject = weekDates.map(item => moment(item,'MM-DD-YYYY').toDate())
    setDateValues([...dateObject])
    onChange({option: 'WEEKDAYS', dates : dateObject})
  }

  const handleSelectedDate = (dates) => {
    // if(selectedOption !== 'DOES_NOT_REPEAT'){
      setDateValues(dates)
      if (selectedOption === 'WEEKEND') {
        onChange({option: 'CUSTOM', dates : dates})
        setSelectedOption("CUSTOM")
      }else if(selectedOption === 'WEEKDAYS') {
        onChange({option: 'CUSTOM', dates : dates})
        setSelectedOption("CUSTOM")
      } else if (dates.length > 0) {
        onChange({option: 'CUSTOM', dates : dates})
        setSelectedOption("CUSTOM")
      } else {
        setSelectedOption("DOES_NOT_REPEAT")
      }
    // }
  }

  DontHaveFocus(wrapperRef, cb);

  useEffect(()=>{
    if(value && typeof(value) === 'object'){
      setSelectedOption(value?.option ?? 'DOES_NOT_REPEAT');
      setDateValues((value?.dates && [...value.dates]) ?? [])
    }else if(value && typeof(value) === 'string'){
      setSelectedOption(value);
      setDateValues([])
    }
  },[value])

  return (
    <DatePicker
      className="custom-cnatainer-dtp-style"
      weekDays={weekDays}
      format="MM/DD/YYYY HH:mm:ss"
      arrow={false}
      multiple
      ref={wrapperRef}
      render={() => (
        <>
          <input
            onFocus={() => {
              setShowCalendar(true);
            }}
            value={selectedOption}
            onChange={()=>{}}
            className="custom-datepicker-input"
            type="text"
            disabled={disabled}
          />
          {showCalendar || cursorOverCalendar ? (
            <div className="custom-calender-wrapper" onMouseEnter={() => setCursorOverCalendar(true)}>
              <div className="custom-header">
                <div className="header-checkbox-wrapper">
                  {/* <input className="header-checkbox" type="checkbox" onClick={() => { setSelectedOption('DOES_NOT_REPEAT'); setDateValues([]) }} checked={selectedOption === 'DOES_NOT_REPEAT'} /> */}
                  <CustmCheckbox onClick={() => { setSelectedOption('DOES_NOT_REPEAT'); setDateValues([]);onChange({option: 'DOES_NOT_REPEAT', dates : []}) }}  defaultChecked={selectedOption === 'DOES_NOT_REPEAT'}  />
                  <span>Does not repeat</span>
                </div>
                <div className="header-checkbox-wrapper">
                  {/* <input className="header-checkbox" type="checkbox" onClick={()=>{setSelectedOption('WEEKDAYS'); onWeekDaySelection()}} checked={selectedOption === 'WEEKDAYS'}/> */}
                  <CustmCheckbox onClick={() => { setSelectedOption('WEEKDAYS'); onWeekDaySelection()}}  defaultChecked={selectedOption === 'WEEKDAYS'}  />
                  <span>Weekdays</span>
                </div>
                <div className="header-checkbox-wrapper">
                  <CustmCheckbox  onClick={() => { setSelectedOption('WEEKEND'); onWeekendSelection() }}   defaultChecked={selectedOption === 'WEEKEND'}  />
                  <span>Weekends</span>
                </div>
                <div className="header-checkbox-wrapper">
                  {/* <input className="header-checkbox" type="checkbox" onClick={() => setSelectedOption('CUSTOM')} checked={selectedOption === 'CUSTOM'} /> */}
                  <CustmCheckbox onClick={() => {setSelectedOption('CUSTOM');setDateValues([]);onChange({option: 'CUSTOM', dates : []})}}  defaultChecked={selectedOption === 'CUSTOM'}   />
                  <span>Select custom dates</span>
                </div>
              </div>
              <Calendar
                mapDays={({
                  date,
                  today,
                  selectedDate,
                  currentMonth,
                  isSameDate,
                }) => {

                  // console.log(selectedDate)

                  let props = {};

                  props.style = {
                    borderRadius: "50px",
                    backgroundColor:
                      date.month.index === selectedDate ? "#ccc" : "",
                  };

                  if (isSameDate(date, today)) props.style.color = "#000";
                  if (isSameDate(date, today))
                    props.style.backgroundColor = "white"; //"#00aeef";
                  if (isSameDate(date, selectedDate))
                    props.style = {
                      ...props.style,
                      color: "#FFFFFF",
                      backgroundColor: "red",
                      fontWeight: "bold",
                    };

                  return props;
                }}
                minDate={moment(minDate).toDate()}
                value={dateValues}
                multiple
                weekDays={weekDays}
                style={{ borderRadius: 0, boxShadow: "none" }}
                onChange={(dates) => handleSelectedDate(dates)}
              >
                {" "}
                <button
                  onClick={() => {
                    setShowCalendar(false);
                    setCursorOverCalendar(false);
                    onChange({option: selectedOption, dates : dateValues})
                  }}
                  className="save-btn"
                >
                  Save
                </button>
              </Calendar>
            </div>
          ) : null}
        </>
      )}
    // plugins={
    //   [
    //     // <TimePicker position="bottom" />,
    //     // <DatePanel markFocused />
    //   ]
    // }
    />
    // </div>
  );
};

export default CustomDatePickerV4;