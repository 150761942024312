import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import moment from "moment";
import { AiFillEdit } from 'react-icons/ai';
import { RiArrowDownSLine } from 'react-icons/ri';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import "./index.scss";

export const Accordion = (props) => {
  const {
    children,
    title,
    remove = () => { },
    isEditFormOpen = () => { },
    effectiveDate,
    updatedDate,
    rightSideInfo,
    disabled = false,
    label,
    rightSideButton,
    defaultViewComp = null,
    headerComp,
    titleColor = "#00AEEF"
  } = props;
  const [isOpen, setOpen] = useState(false);
  const [removePadding, setRemovePadding] = useState(false);
  const handleToggle = () => {
    !isOpen && !disabled ? isEditFormOpen(true) : isEditFormOpen(false);
    !disabled && setOpen(!isOpen);
  };
  useEffect(() => {
    if (props.removePadding) {
      setRemovePadding(true);
    }
  }, []);

  useEffect(() => {
    if (props.isFormOpen || props.isFormOpen === false) {
      setOpen(props.isFormOpen);
    }
  }, [props.isFormOpen]);
  return (
    <Card
      className={
        isOpen
          ? `custom-accordion isopen`
          : disabled
            ? `custom-accordion disabled`
            : `custom-accordion`
      }
    >
      <CardHeader onClick={handleToggle} style={{ borderRadius: isOpen ? '0' : '16px', cursor: 'pointer' }} className={isOpen ? `custom-card-header d-flex accordionHeader isopen` : `custom-card-header d-flex accordionHeader`}>
        {headerComp && isOpen ?
          headerComp
         :
          label ? (
            <label className="form-control-label">{label}</label>
          ) : (
            <h3 style={{ color: titleColor }} className={`mr-auto`}>{title}</h3>
          )}
        <div className={"ml-sm-auto d-flex flex-row align-items-center"}>
          {effectiveDate ? (
            <span className={`dateSpan`}>
              UPDATED DATE : {moment(updatedDate).format("YYYY-MM-DD")}
            </span>
          ) : null}
          {rightSideInfo ? (
            <span className={`dateSpan`}>{rightSideInfo}</span>
          ) : null}
          {rightSideButton && isOpen ? rightSideButton : null}
          <span onClick={handleToggle} style={{ cursor: 'pointer' }}>
            {
              isOpen ?
                <MdKeyboardArrowUp size={26} />
                : <MdKeyboardArrowDown size={26} />

            }
          </span>
        </div>
      </CardHeader>
      {defaultViewComp && !isOpen &&
        <CardBody className='accordianCard-default-view'>
          {defaultViewComp}
        </CardBody>
      }
      <CardBody
        className={isOpen ? `accordionCardbody isopen` : `accordionCardbody`}
        style={{ padding: `${removePadding ? "0" : ""}` }}
      >
        {children}
      </CardBody>
    </Card>
  );
};


export const TransparentAccordian = (props) => {
  const { children, title } = props;

  const [isOpen, setOpen] = useState(true);

  return (
    <div className="acc-container">
      <div className="header-wrapper">
        <div className="title-wrapper">
          <span className='title'>{title}</span>
          <span className="edit-button">Edit <AiFillEdit size={16} /></span>
        </div>
        <RiArrowDownSLine size={32} className="dropdown-btn" onClick={() => { setOpen(!isOpen) }} />
      </div>
      <div className={isOpen ? 'body-visible' : 'body-hidden'}>
        {children}
      </div>
    </div>
  )
}