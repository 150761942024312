import React from "react";
import moment from "moment";
import { AGridColDef } from "../../../common/data-grid/ADataGridColDef";
import { IoEyeOutline } from "react-icons/io5";
import BatchNamePopover from "./BatchNamePopover";
import CustomTooltip from "../../../common/customTooltip/CustomTooltip";
import MaterialDetailsCellComponent from "./MaterialDetailsCellComponent";

const SHOW_YEAR_LIMIT = 5; // use this const to change max no. of years to be shown

export const getAcademicYears = () => {
  let currentYearStart =
    moment().month() + 1 <= 3 ? moment().year() - 1 : moment().year();

  const res = [];
  for (let i = 0; i < SHOW_YEAR_LIMIT; i++) {
    res.push(`${currentYearStart}-${currentYearStart + 1}`);
    currentYearStart--;
  }
  return res;
};

export const activeTabPropertyMapping = {
  issueDate: {
    issuance: "Issue date",
    return: "Return date",
  },
  transactionId: {
    issuance: "Transaction ID",
    return: "Return ID",
  },
  materialDetails: {
    issuance: "Material issued",
    return: "Material returned",
  },
  type: {
    RCC: {
      issuance: "PSID_ISSUANCE",
      return: "PSID_RETURN",
    },
    DLP: {
      issuance: "DLP_PSID_ISSUANCE",
      return: "DLP_PSID_RETURN",
    },
    DIGITAL: {
      issuance: "DIGITAL_PSID_ISSUANCE",
      return: "DIGITAL_PSID_RETURN",
    },
  },
};

export const getColumns = (activeTab, academicCareer) => {
  return [
    new AGridColDef("applicationId", "Application ID", false)
      .setMinWidth(120)
      .renderCellComponent((params) => (
        <div className="d-flex align-items-center gap-md">
          <div>{params?.value}</div>
          <BatchNamePopover rowData={params?.row} />
        </div>
      )),
    new AGridColDef("courseId", "Course ID", false)
      .setMinWidth(90)
      .setFlex(0.8),
    new AGridColDef(
      "issueDate",
      activeTabPropertyMapping["issueDate"][activeTab],
      false
    )
      .setMinWidth(100)
      .setValueGetter((params) =>
        params?.value ? moment(params?.value)?.format("DD-MMM-YYYY") : "-"
      )
      .renderCellComponent((params) => (
        <CustomTooltip title={params?.value}>
          <div className="ellipsis-text-container">{params?.value}</div>
        </CustomTooltip>
      )),
    ...(["DLP", "DIGITAL"].includes(academicCareer)
      ? [
          new AGridColDef("dispatchId", "Dispatch ID", false)
            .setMinWidth(120)
            .setValueGetter((params) => params?.value || "-"),
        ]
      : []),
    new AGridColDef(
      "transactionId",
      activeTabPropertyMapping["transactionId"][activeTab],
      false
    )
      .setMinWidth(130)
      .setValueGetter((params) => params?.value || "-")
      .renderCellComponent((params) => (
        <div className="w-100 d-flex align-items-center gap-md ellipsis-text-container">
          <CustomTooltip title={params?.value}>
            <div className="ellipsis-text-container">{params?.value}</div>
          </CustomTooltip>
          {params?.row?.sapDocId && (
            <CustomTooltip
              title={
                <div className="p-2 regular-large">
                  SAP document no.: {params?.row?.sapDocId}
                </div>
              }
            >
              <div className="h-100 my-auto">
                <IoEyeOutline className="pointer" size={18} />
              </div>
            </CustomTooltip>
          )}
        </div>
      )),
    new AGridColDef(
      "materialDetails",
      activeTabPropertyMapping["materialDetails"][activeTab],
      false
    )
      .setMinWidth(120)
      .setFlex(1.5)
      .setValueGetter((params) => params?.value || "-")
      .renderCellComponent((params) => (
        <MaterialDetailsCellComponent rowData={params?.row} />
      )),
  ];
};

export const getPayload = (psid, year, activeTab, academicCareer) => {
  const startDate = moment("01-04", "DD-MM")
    .year(year?.split("-")?.[0])
    .startOf("day");
  const endDate = moment("31-03", "DD-MM")
    .year(year?.split("-")?.[1])
    .endOf("day");

  return {
    psid: psid,
    issuanceDateFrom: startDate,
    issuanceDateTo: endDate,
    groupBy: "Transaction",
    type: activeTabPropertyMapping["type"][academicCareer][activeTab],
    status: "SUCCESS",
  };
};

export const getPsidMaterialData = (data, psid) => {
  const materialDetails = [];
  for (let item of data) {
    if (item?.psid === psid) {
      materialDetails.push(...item?.materialDetails);
    }
  }
  return materialDetails;
};
