import React, { useEffect, useState } from 'react';
import {
	masterServiceBaseUrl,
	getAPI,
	putpost,
} from 'services/http';
import { successToast, failureToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import { Card, CardBody, FormGroup, Row, Col } from 'reactstrap';
import {
	RedAstric,
	fetchDetails,
} from '../../../../common/utils/methods/commonMethods/utilityMethod';
import Select from 'react-select';
import CustomLoader from '../../../../common/commonComponents/Loader/loader';
import CustomButton from 'components/CustomButton';

export const TermMapping = (props) => {
	const {
		nextStep = () => {},
		isNew = true,
		termId,
		isViewOnly = false,
		userPermissions,
	} = props;

	const [companyCodeData, setCompanyCodeData] = useState([]);
	const [showCompanyCode, setShowCompanyCode] = useState([]);
	const [termData, setTermData] = useState([]);
	const [academicData, setAcademicData] = useState([]);
	const [showAcademicCareer, setShowAcademicCareer] = useState([]);
	const [businessData, setBusinessData] = useState([]);
	const [apiloader, setApiLoader] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);

	const [value, setValues] = useState({
		businessgrp: '',
		academicCareer: '',
		comapanyCode: '',
		term: '',
	});

	const fetchTermMappingnData = () => {
		setApiLoader(true);
		getAPI(
			`${masterServiceBaseUrl}/termMapping/getTermMapping/${termId}`,
			(data) => {
				setValues({
					...value,
					businessgrp: getSelectedObj(data.data.groupCodeId, businessData),
					academicCareer: getSelectedObj(
						data.data.academicCareerId,
						academicData
					),
					comapanyCode: getSelectedObj(
						data.data.companyCodeId,
						companyCodeData
					),
					term: getSelectedObj(data.data.termId, termData),
				});
				setIsDisabled(true);
				setApiLoader(false);
			},
			(data) => {
				failureToast(data['message']);
				setApiLoader(false);
			}
		);
	};

	const getSelectedObj = (id, arr) => {
		return arr.filter((item) => {
			return item.value === id;
		})[0];
	};

	React.useEffect(() => {
		if (
			!isNew &&
			businessData.length &&
			termData.length &&
			companyCodeData.length &&
			academicData.length
		) {
			fetchTermMappingnData();
		}
	}, [isNew, academicData, businessData, termData, companyCodeData]);

	const fetchData = (url, stateMethod, name) => {
		getAPI(
			url,
			(data) => {
				let list = [];
				//this is for dropdown fields only
				let keyName = `${name}DispValue`;

				list = data.data.map((data) => {
					// if (data.status === "ACTIVE") {
					return {
						value: data.id,
						label: data[keyName],
					};
					// }
				});
				stateMethod(list);
			},
			(data) => {
				failureToast(data['message']);
			}
		);
	};

	useEffect(() => {
		fetchData(
			masterServiceBaseUrl + '/companyCode/getAllActiveCompanyCode',
			setCompanyCodeData,
			'companyCode'
		);
		fetchData(
			masterServiceBaseUrl + '/groupCode/getAllActiveGroupCode',
			setBusinessData,
			'groupCode'
		);
		fetchData(
			masterServiceBaseUrl + '/academicCareer/getAllActiveAcademicCareer',
			setAcademicData,
			'academicCareer'
		);
		fetchData(
			masterServiceBaseUrl + '/term/getAllActiveTerm',
			setTermData,
			'term'
		);
	}, []);

	const saveHandler = () => {
		if (value.businessgrp === '') {
			failureToast('Please Select Business Group');
			return;
		} else if (value.comapanyCode === '') {
			failureToast('Please Select Company Code');
			return;
		} else if (value.academicCareer === '') {
			failureToast('Please Select Academic Career');
			return;
		} else if (value.term === '') {
			failureToast('Please Select Term');
			return;
		}

		setApiLoader(true);

		const obj = {
			groupCodeId: value.businessgrp.value,
			academicCareerId: value.academicCareer.value,
			companyCodeId: value.comapanyCode.value,
			termId: value.term.value,
		};

		if (isNew) {
			putpost(
				`${masterServiceBaseUrl}/termMapping/createTermMapping`,
				(data) => {
					successToast(data['message']);
					setValues({
						businessgrp: '',
						academicCareer: '',
						comapanyCode: '',
						term: '',
					});
					setApiLoader(false);
					nextStep(data.data.id);
				},
				(data) => {
					setApiLoader(false);
					failureToast(data['message']);
				},
				obj,
				'post'
			);
		}

		if (!isNew) {
			putpost(
				`${masterServiceBaseUrl}/termMapping/updateTermMapping/${termId}`,
				(data) => {
					successToast(data['message']);
					fetchTermMappingnData();
				},
				(data) => {
					setApiLoader(false);
					failureToast(data['message']);
				},
				obj,
				'put'
			);
		}
	};

	const getDataByDependentID = (value, key) => {
		try {
			if (key === 'comapanyCode') {
				setShowCompanyCode([]);
				fetchDetails(
					masterServiceBaseUrl + `/companyCode/getCompanyCodeByGroup/${value}`,
					setShowCompanyCode,
					'companyCode',
					'select'
				);
			} else if (key === 'academicCareer') {
				setShowAcademicCareer([]);
				fetchDetails(
					masterServiceBaseUrl +
						`/academicCareer/getAcademicCareerByCompanyCode/${value}`,
					setShowAcademicCareer,
					'academicCareer',
					'select'
				);
			}
		} catch (e) {
			console.log('Error', e);
		}
	};

	const discard = () => {
		fetchTermMappingnData();
		setIsDisabled(true);
	};

	return (
		<Card className="mb-4">
			<CardBody>
				{apiloader ? (
					<CustomLoader
						apiLoader={apiloader}
						loaderHeight={'200px'}
						loaderWidth={'100% '}
					/>
				) : (
					<>
						<Row>
							<Col md="6">
								<FormGroup>
									<label
										className="form-control-label"
										htmlFor="example3cols2Input"
									>
										Business Group <RedAstric />
									</label>
									<Select
										aria-label="Business Group"
										value={value.businessgrp}
										id="example3cols2Input"
										className="businessGroupText"
										options={businessData}
										isDisabled={isDisabled}
										onChange={(sData) => {
											getDataByDependentID(sData.value, 'comapanyCode');
											setValues({
												...value,
												businessgrp: sData,
												comapanyCode: '',
												academicCareer: ''
											});
										}}
									/>
								</FormGroup>
							</Col>
							<Col md="6">
								<FormGroup>
									<label
										className="form-control-label"
										htmlFor="example3cols2Input"
									>
										Company Code <RedAstric />
									</label>
									<Select
										aria-label="Company Code"
										value={value.comapanyCode}
										id="example3cols2Input"
										options={showCompanyCode}
										isDisabled={isDisabled}
										onChange={(sData) => {
											getDataByDependentID(sData.value, 'academicCareer');
											setValues({
												...value,
												comapanyCode: sData,
												academicCareer: '',
											});
										}}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col md="6">
								<FormGroup>
									<label
										className="form-control-label"
										htmlFor="example3cols2Input"
									>
										Academic Career <RedAstric />
									</label>
									<Select
										aria-label="Academic Career"
										value={value.academicCareer}
										id="example3cols2Input"
										options={showAcademicCareer}
										isDisabled={isDisabled}
										onChange={(sData) =>
											setValues({ ...value, academicCareer: sData })
										}
									/>
								</FormGroup>
							</Col>
							<Col md="6">
								<FormGroup>
									<label
										className="form-control-label"
										htmlFor="example3cols2Input"
									>
										Term <RedAstric />
									</label>
									<Select
										aria-label="Term"
										value={value.term}
										id="example3cols2Input"
										options={termData}
										isDisabled={isDisabled}
										onChange={(sData) => setValues({ ...value, term: sData })}
									/>
								</FormGroup>
							</Col>
						</Row>
						{isNew ? (
							<Row className={`d-flex pr-3 justify-content-end`}>
								<CustomButton
									className={`ml-auto`}
									content={'Next'}
									permissionType={'C,U'}
									forTable={true}
									permissionSet={userPermissions}
									onClick={() => {
										saveHandler();
									}}
								/>
							</Row>
						) : !isNew && !isDisabled ? (
							<>
								<Row className={`d-flex pr-3 justify-content-end`}>
									<CustomButton
										className={`mr-3`}
										content={'Save'}
										permissionType={'C,U'}
										forTable={true}
										onClick={() => {
											saveHandler();
										}}
										permissionSet={userPermissions}
									/>
									<CustomButton
										className={`mr-3`}
										content={'Cancel'}
										permissionType={'cancel'}
										forTable={true}
										permissionSet={userPermissions}
										onClick={() => {
											discard();
										}}
									/>
								</Row>
							</>
						) : !isViewOnly ? (
							<Row className={`d-flex pr-3 justify-content-end`}>
								<CustomButton
									className={`ml-auto`}
									content={'Edit'}
									permissionType={'U'}
									forTable={true}
									permissionSet={userPermissions}
									onClick={() => {
										setIsDisabled(false);
									}}
								/>
							</Row>
						) : null}
					</>
				)}
			</CardBody>
		</Card>
	);
};
