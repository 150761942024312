import React from 'react';
import Style from './userdetails.module.scss';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import Logout from 'assets/img/svg/logout.svg';

const UserDetails = ({ useDetails, setIsLogout }) => {
  const { firstName, lastName, email } = useDetails;
  return (
    <UncontrolledPopover
      trigger={'hover'}
      placement="bottom"
      target="UncontrolledPopover"
      className={Style.logout_container}
    >
      <PopoverBody>
        <div className={Style.logout_container}>
          <span className={Style.logout_icon}>{`${firstName && firstName.substring(0, 1).toUpperCase()}${lastName && lastName
            .substring(0, 1)
            .toUpperCase()}`}</span>
          <p className={Style.logout_name}>{firstName + ' ' + lastName}</p>
          <p className={Style.logout_email}>{email}</p>
          <div className={Style.logout_image} onClick={() => setIsLogout(true)}>
            <img src={Logout} />
            <p>Logout</p>
          </div>
        </div>
      </PopoverBody>
    </UncontrolledPopover>
  );
};

export default UserDetails;
