import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import { studentCountWithNoRfId } from "../service";
import { IssuanceIDCardContext, PAGE_STATE } from "../contextApi";
import ALoader from "views/pages/manage/common/a-loader";
import { pendingRfIdFilter } from '../constant'
import { ReactComponent as TickIcon } from 'assets/img/svg/ic_tick.svg'
const RFIDCountCard = ({ dropdownLoader }) => {
  const [idCount, setIdCount] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const {dropdown, setCurrentPageState, getPendingRfIds, loading, setPrintedIdCardArray, hideStepperBarRfidPage, setHideStepperBarRfidPage } = useContext(
    IssuanceIDCardContext
  );
  useEffect(() => {
    const getRFidCount = async (businessAreas) => {
      setIsLoading(true);
      
      const response = await studentCountWithNoRfId(businessAreas);
      setIdCount(response?.data);
      setIsLoading(false);
    };
    
    if(dropdown?.businessAreas?.length > 0)
           getRFidCount(dropdown?.businessAreas);
  }, [dropdown]);

  const mapRfIdHandler = () => {
    setPrintedIdCardArray([]);
    setHideStepperBarRfidPage(true);
    getPendingRfIds(0, pendingRfIdFilter, () => setCurrentPageState(PAGE_STATE?.MAP_RFID));
  };
  if(dropdown?.businessAreas?.length>5)return <div></div>
  return (
    <div>
      <Card>
        {(isLoading) && <ALoader />}
        {loading && <ALoader position='fixed' />}
        <CardBody
          style={{ gap: "12px" }}
          className="d-flex flex-column align-items-center"
        >
          {!(isLoading) && (idCount > 0 ? (
            <>
              <span
                className="heading-2 rfid-counter-printId"
                style={{ fontSize: "24px" }}
              >
                {idCount}
              </span>
              <div className="heading-3 color-dark" style={{ textAlign: "center" }}>
                Map RFIDs with students
              </div>
              <div
                style={{ textAlign: "center" }}
                className="regular-small color-grey-60"
              >
                No. of Students for whom ID card is yet to be mapped
              </div>

              <AButton
                onClick={mapRfIdHandler}
                variant="primary_filled"
              >
                Map pending RFIDs
              </AButton>
            </>
          ) : (
            <>
              <span className="rfid-counter-0">
                <TickIcon />
              </span>
              <div style={{ textAlign: 'center', color:"black" }} className="heading-4">
                All RFIDs mapped with students
              </div>
            </>
          ))}
        </CardBody>
      </Card>
    </div>
  );
};

export default RFIDCountCard;
