import { documentServiceBaseUrl } from "services/http";

const templateUrl = `${documentServiceBaseUrl}/documentMapping/downloadTemplateWithValidations`;

export const documentServiceEndpoints = {
  templateUpload: {
    getAll: `${documentServiceBaseUrl}/documentMapping/getAllDocumentTemplate`,
    getAllActive: `${documentServiceBaseUrl}/documentMapping/getAllActiveDocumentTemplate`,
    create: `${documentServiceBaseUrl}/documentMapping/createDocumentTemplate`,
    update: `${documentServiceBaseUrl}/documentMapping/updateDocumentTemplate`,
    delete: `${documentServiceBaseUrl}/documentMapping/getAllDocumentTemplate`,
    downloadTemplate: `${documentServiceBaseUrl}/documentMapping/downloadTemplate`,
    getById : `${documentServiceBaseUrl}/documentMapping/getDocumentTemplatebyId`,
    downloadRfIdsCsv : `${documentServiceBaseUrl}/documentMapping/downloadTemplateWithValidations?documentTypeDisplayValue=Enrollment%20RFID&serviceType=ENROLMENT_BULK_RFID`,
    downloadDispatchScheduleCsv:`${documentServiceBaseUrl}/documentMapping/downloadTemplateWithValidations?documentTypeDisplayValue=Enrollment%20DispatchSchedule&serviceType=BULK_DISPATCH_SCHEDULE`

  },

  fetchS3Url: {
    getUrl: `${documentServiceBaseUrl}/documentManagement/getUploadUrl`,
    updateToServer: `${documentServiceBaseUrl}/documentManagement/s3Uploaded`,
    getDownloadUrl: `${documentServiceBaseUrl}/documentUpload/s3DownloadUrlCommon`,
    commonUploadDms:  `${documentServiceBaseUrl}/documentManagement/s3UploadedCommon`,
    getDownloadKey: `${documentServiceBaseUrl}/documentManagement/getDocumentDetailsbyPID`
  },

  pricingUploadInfo: {
    uploadInfo:  `${documentServiceBaseUrl}/documentManagement/s3CsvSupportDocUploadResponse`
  },
  templateDataTypeMaster :{
    getAllActive : `${documentServiceBaseUrl}/templateDataTypeMaster/getAllActiveTemplateDataTypeMaster`
  },
  documentFormat :{
    getAllActive : `${documentServiceBaseUrl}/documentFormat/getActiveDocumentFormats`
  },
  templateMetaData :{
    create : `${documentServiceBaseUrl}/documentTemplateMetaData/createDocumentTemplateMetaData`
  },

  documentProcessTag: {
    getAllDocumentByProcessName :  `${documentServiceBaseUrl}/DocumentProcessTag/getDocumentProcessTagByProcessTypeDispValue`,
  },
  //sub sub topic master
  subSubTopicMasterTemplate: `${templateUrl}?documentTypeDisplayValue=SubSubTopic%20Bulk&serviceType=MASTER_SUBSUBTOPIC`,
  attendanceMasterTemplate: `${templateUrl}?documentTypeDisplayValue=Attendence%20Machine%20Master&serviceType=MASTER_ATTENDENCE`,
  pricingServiceTemplate: `${templateUrl}?documentTypeDisplayValue=Pricing%20Service%20Bulk&serviceType=PRICING`,
  rfidMasterTemplate: `${templateUrl}?documentTypeDisplayValue=RFID%20Master%20Bulk&serviceType=MASTER_RFID`,
  examServiceTemplate: `${templateUrl}/documentMapping/downloadTemplate?documentTypeDisplayValue=Exam%20Id%20Bulk&serviceType=EXAM`,
  topicMasterTemplate: `${templateUrl}?documentTypeDisplayValue=Topic%20Master&serviceType=MASTER_TOPIC`,
  chapterMasterTemplate: `${templateUrl}?documentTypeDisplayValue=Chapter%20Master&serviceType=MASTER_CHAPTER`,
  externalOrganizationTemplate: `${templateUrl}?documentTypeDisplayValue=External%20Organization&serviceType=MASTER_EXTERNALORGANISATION`,
  subTopicTemplate: `${templateUrl}?documentTypeDisplayValue=SubTopic%20Bulk&serviceType=MASTER_SUBTOPIC`,
  externalTestTemplate: `${templateUrl}?documentTypeDisplayValue=External%20Test%20Bulk&serviceType=MASTER_EXTERNALTEST`,
  lessonPlanTemplate: `${templateUrl}?documentTypeDisplayValue=Lesson%20Plan%20Bulk&serviceType=MASTER_LESSONPLAN`,



  employeeDetailsTemplate :  `${documentServiceBaseUrl}/documentMapping/downloadTemplateWithValidations?documentTypeDisplayValue=New%20Employee%20upload&serviceType=AUTH`,
  getDocMapping: `${documentServiceBaseUrl}/documentMapping/genrateDocumentTemplateJsonByProcessTag`,

  newEmployeeTemplate : `${documentServiceBaseUrl}/documentMapping/downloadTemplateWithValidations?documentTypeDisplayValue=New%20Employee%20upload&serviceType=AUTH_EMP_NEW`,
  oldEmployeeTemplate : `${documentServiceBaseUrl}/documentMapping/downloadTemplateWithValidations?documentTypeDisplayValue=Employee%20Exit%20upload&serviceType=AUTH_EMP_EXIT`,
  bulkUpload : `${documentServiceBaseUrl}/documentManagement/getBulkUploadUrl`
};
