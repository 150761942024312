import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap';
import CommonInput from "views/pages/manage/common/formFeilds/input/commonInput";
import CustomButton from '../../../../../components/CustomButton';
import { endpoint } from '../../common/constant';
import Status from '../../common/formFeilds/status';
import BooleanStatus from '../../common/formFeilds/status/booleanStatus';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import TempRole from './temRole';


const ProjectRow = ({
    rowData,
    projectDetails,
    index,
    projectData,
    setProjectDetails,
    isPreviewEnable,
    userDetails,
    isVendor,
    isEditMode,
    userPermissions,
    action,
    setRoleAndProjectObject = () => { },
    roleAndProjectObject
}) => {

    // const [formValue,setFormValue] = useState({
    //    ...rowData
    // })

    const [roleData, setRoleData] = useState([])
    const [fetchingRole, setFetchingRole] = useState(false)
    const [tempRoleModal, setTempRoleModalOpen] = useState(false)
    const [resetTempRole,setRestTempRole] = useState(false);

    const getRoles = async () => {
        setFetchingRole(true)
        const role = await fetchAllPromisedData(`${endpoint.getRole.getAllRoleOnProject}/${rowData.project?.value}`)
        if (Array.isArray(role)) {
            const tempArr = role.map(role => {
                return { value: role.id, label: role.roleName, delegationDays: role.defaultDelegationDays }
            })
            if (rowData.project?.isMultipleRoles && tempArr?.length > 0) {
                setRoleData([{ label: 'ALL', value: '*' }, ...tempArr])
                setRoleAndProjectObject({ ...roleAndProjectObject, [rowData.project?.label]: tempArr })
            } else {
                setRoleData(tempArr)
            }
            setFetchingRole(false)
        } else {
            setFetchingRole(false)
        }
    }

    useEffect(() => {
        if (rowData.project?.value && rowData.project?.value !== 'undefined') {
            getRoles()
        }
    }, [rowData?.project])

    const handleValueChange = (key, value) => {
        const tempData = [...projectDetails];
        const tempObj = tempData[index];
        if (key === 'project') {
            // getRoles()
            tempObj['role'] = [];
            delete tempObj['tempRole']
        }
        if (key === 'role') {
            if (rowData?.project?.isMultipleRoles) {
                tempObj[key] = value
            } else {
                tempObj[key] = [value]
            }
            delete tempObj['tempRole']
        } else {
            tempObj[key] = value
        }
        tempData[index] = tempObj;
        setProjectDetails([...tempData])

    }

    const removeRow = (index) => {
        const tempData = [...projectDetails];
        tempData.splice(index, 1)
        setProjectDetails([...tempData])
    }

    const getUserId = () => {
        if (!rowData?.project?.value) return ''

        if (isVendor) {
            return rowData?.project?.isUseridEmail ? userDetails?.email : userDetails?.employeeId
        }

        if (!isVendor) {
            return rowData?.project?.isUseridEmail ? userDetails?.employeeId?.email : userDetails?.employeeId?.value
        }
    }

    return (
        <>
            <tr>
                <td className="text-center white-breakSpace" style={{ width: '210px' }}>
                    <CommonInput
                        type={'select'}
                        forTable={true}
                        id="user-profile-project"
                        placeHolder={'Select Project'}
                        defaultValue={rowData.project}
                        data={projectData}
                        onChange={(value) => {
                            handleValueChange('project', value);
                            setRestTempRole(true);
                        }}
                        isDisabled={isPreviewEnable || rowData.isNotDeleteAble}
                    />
                </td>
                <td className="text-center white-breakSpace" style={{ width: '300px' }}>
                    <CommonInput
                        type={rowData?.project?.isMultipleRoles ? 'multiSelectWithCheckboxes' : 'select'}
                        forTable={true}
                        id="user-profile-role"
                        placeHolder={'Select Role'}
                        defaultValue={rowData.role}
                        data={roleData}
                        onChange={(value) => {
                            handleValueChange('role', value);
                        }}
                        isLoading={fetchingRole}
                        isDisabled={fetchingRole || !rowData.project?.value || isPreviewEnable}
                    />
                </td>
                <td className="text-center white-breakSpace" style={{ width: '210px' }}>
                    <CommonInput
                        type="text"
                        forTable={true}
                        defaultValue={getUserId()}
                        placeHolder="User ID"
                        id="user-profile-user-id"
                        style={{ height: "38px" }}
                        // onChange={(e)=>{handleValueChange('userId',e)}}
                        isDisabled={true}
                    />
                </td>
                <td className="text-center white-breakSpace">
                    <Status value={rowData.status} onChange={(n) => { handleValueChange('status', n) }} disabled={isPreviewEnable} />
                </td>
                <td className="text-center white-breakSpace">
                    <Status value={rowData.project?.isNewUser ? 'ACTIVE' : 'INACTIVE'} disabled={true} />
                </td>
                <td className="text-center white-breakSpace">
                    <Status value={rowData.project?.isUseridEmail ? 'ACTIVE' : 'INACTIVE'} disabled={true} />
                </td>
                <td className="text-center white-breakSpace">
                    {((action === 'edit' || (action === 'view' && rowData?.tempRoleDto))  && rowData?.project?.projectKey === 'PHX') && <CustomButton onClick={() => setTempRoleModalOpen(true)} disabled={!rowData.project?.value} content={'Temp Role'}
                        permissionType={'U'}
                        permissionSet={userPermissions}
                        forTable={true}
                        isOnlyPreview={action === 'view'}
                    />}
                    {!rowData.isNotDeleteAble && <Button color="danger" style={{ padding: '0', width: '30px', height: '30px' }} onClick={() => removeRow(index)} disabled={isPreviewEnable || rowData.isNotDeleteAble}><i className="fas fa-times" /></Button>}
                </td>
            </tr>
            <TempRole 
                handleValueChange={handleValueChange} 
                action={action} 
                tempRoleModal={tempRoleModal} 
                userPermissions={userPermissions} 
                rowData={rowData} 
                roleData={roleData} 
                setTempRoleModalOpen={setTempRoleModalOpen} 
                isPreviewEnable={isPreviewEnable} 
                resetTempRole={resetTempRole}
                setRestTempRole={setRestTempRole}
            />
        </>
    )
}

export default ProjectRow