import React, { useState, useEffect, useRef } from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap';
import { ReactComponent as BackArrow } from "../../../../../../../assets/img/svg/BackArrow.svg"
import SearchInput from '../../../onlinePackage/SearchInput';
import AButton from '../../../../common/form-fields-mui/AButton';
import AToggleButtonGroup from '../../../../../../../components/AToggleButtonGroup/AToggleButtonGroup';
import ListComponent from './ListComponent';
import { bankTypes, getAllActiveCountries, statusFilterOptions } from './common';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../../../common/utils/methods/commonMethods/utilityMethod';
import { endpoint } from '../../../../common/constant';
import { successToast } from '../../../../common/utils/methods/toasterFunctions/function';
import { useHistory } from 'react-router';


const BankListing = () => {

  const ref = useRef();
  const history = useHistory();

  const [allActiveCountries, setAllActiveCountries] = useState([]);
  const [bankListingData, setBankListingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterObj, setFilterObj] = useState({});
  const [paginationInfo, setPaginationInfo] = useState({ currentPage: 0, totalRecords: 0 });

  const getAllBankSetups = async (pageNumber = 0, filterObj = {}, activeCountries = allActiveCountries) => {
    try {
      setLoading(true);

      const resp = await fetchAllPostPromisedData(`${endpoint.bankMaster.search}?offset=${pageNumber}`, filterObj, 'post');
      if (resp?.code === 200) {
        const updatedData = resp.data?.bankMasterList?.map(el => ({
          ...el,
          country: activeCountries?.find(co => co.countryCode === el.countryCodeVal),
          bankType: bankTypes?.find(type => type.value === el?.type)
        }));
        setBankListingData(updatedData);
        setPaginationInfo({ totalRecords: resp.data.totalRecords, currentPage: resp.data.currentPage })
        ref.current.stopEditing();
      }
      setLoading(false);
    } catch (err) {
      console.log(err)
      setLoading(false);
    }
  }

  const createUpdateHandler = async (rowForm) => {
    try {
      setLoading(true);
      const reqObj = {
        ...rowForm,
        countryCodeVal: rowForm?.country?.countryCode,
        type: rowForm?.bankType?.value
      }
      delete reqObj?.country;
      delete reqObj?.bankType;
      delete reqObj?.isNew;

      let url = endpoint.bankMaster.create;
      let method = 'post';

      if (!rowForm?.isNew) {
        url = `${endpoint.bankMaster.update}/${reqObj?.id}`;
        method = 'put'
      }
      const resp = await fetchAllPostPromisedData(url, reqObj, method);
      if (resp?.code === 200) {
        getAllBankSetups(0, filterObj);
        successToast('Request completed successfully!!!')
        ref.current.stopEditing();
      }
    } catch (err) {
      console.log(err);
    }
  }


  const pageChangeHandler = (newPage) => {
    getAllBankSetups(newPage, filterObj);
  }

  const searchHandler = (inputVal) => {
    const newFilterObj = { ...filterObj, bankName: inputVal };
    if (!inputVal) delete newFilterObj?.bankName;
    getAllBankSetups(0, newFilterObj);
    setFilterObj(newFilterObj);
  }

  const cancelSearchHandler = () => {
    const newFilterObj = { ...filterObj };
    delete newFilterObj?.bankName;
    getAllBankSetups(0, newFilterObj);
    setFilterObj(newFilterObj);
  }

  const statusFilterChangeHandler = (status) => {
    let newFilterObj;
    if (status === 'ALL') newFilterObj = { bankName: filterObj?.bankName }
    else newFilterObj = { ...filterObj, status: status }

    getAllBankSetups(0, newFilterObj);
    setFilterObj(newFilterObj);
  }

  const init = async () => {
    setLoading(true);
    const allActiveCountries = await getAllActiveCountries();
    setAllActiveCountries(allActiveCountries);
    getAllBankSetups(0, {}, allActiveCountries);
  }

  useEffect(() => {
    init();
  }, []);


  return (
    <Card className="mx-2">
      <div className="d-flex justify-content-between my-3 mx-2" >
        <div className='d-flex justify-content-between'>
          <BackArrow className='color-black mr-2 cursor' onClick={()=>history.goBack()}/>
          <h4 className='color-black'>Bank Master</h4>
        </div>

        <div className='d-flex justify-content-between' style={{ gap: '10px' }}>
          <SearchInput
            inputStyles={{ border: "1px solid #000" }}
            placeholder={'Search by Bank name'}
            searchInputValueHandler={searchHandler}
            onCancel={cancelSearchHandler}
          />
          <AToggleButtonGroup
            options={statusFilterOptions}
            defaultSelected={'ALL'}
            allowDeselect={false}
            changeHandler={statusFilterChangeHandler}
          />
          <AButton
            variant='primary_filled'
            size='xs'
            className='no-ml-btn'
            onClick={() => ref.current.addNewRow()}
          >+ Add new bank</AButton>
        </div>

      </div>
      <ListComponent
        rows={bankListingData}
        ref={ref}
        countryDropdown={allActiveCountries}
        createUpdateHandler={createUpdateHandler}
        loading={loading}
        currentPage={paginationInfo.currentPage}
        totalRecords={paginationInfo.totalRecords}
        pageChangeHandler={pageChangeHandler}
      />
    </Card>
  );
}

export default BankListing;