import React, {Fragment, useContext, useEffect, useState} from "react";
import {Button, Card, CardBody, CardHeader,Container,Table, Col, Collapse, Row,Form} from "reactstrap";
import {useHistory} from "react-router-dom";
import CommonInput from "../../../common/formFeilds/input/commonInput";
import AddNewModel from "./model/addNewModel";
import {MasterHeaderAuto} from "../../../common/commonComponents/masterHeader/masterHeader";
import {getAPI, masterServiceBaseUrl, putpost} from "services/http";
import {successToast, failureToast,} from '../../../common/utils/methods/toasterFunctions/function';
import {fetchDetails, getDispValForReactSelectByOneID} from "../../../common/utils/methods/commonMethods/utilityMethod";
import moment from "moment";
import ItemTypeSequenceHeader from "./itemTypeSequenceHeader";
import CustomLoader from "../../../common/commonComponents/Loader/loader";
import {GetPagination} from "../../../common/commonComponents/pagination/pagination";
import {CustomTableRow} from "../../../common/commonComponents/TableComponents";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import CloseModel from "./model/closeModel";
import {button, headerList, message, placeHolder} from "./properties";
import {DropDownButton} from "../../../masters/examSyllabus/examSyllabusForm";
import IsCoreMaster from "../../../common/commonComponents/coreMaster";
import {PermissionContext, RolePermissions} from "../../../../../../appContext";
import {pages} from "../../../common/constant";
import CustomButton from "../../../../../../components/CustomButton";
import {PermisionDenied} from "../../../common/commonComponents";
const TDROW =(props)=>{
    const {item,index,action,itemType,editRow,taxCodeId,permissionSet} = props;
    //console.log('Item--------->',item)
    return (
        <CustomTableRow key={'ItemTypeSequenceRowTDROW'+index}>
            <td className="text-center p-2 mx-1">
                {item.sequenceNumber}
            </td>
            <td className="text-center p-2 mx-1">
                {item.installmentNumber}
            </td>
            <td className="text-center p-2 mx-1">
                {getDispValForReactSelectByOneID(item.itemTypeSetupId,'DispValue',itemType) }
            </td>
            <td className="text-center p-2 mx-1">
                {getDispValForReactSelectByOneID(item.itemTypeSetupId,'DescValue',itemType) }
            </td>
            <td className="text-center p-2 mx-1">
                {item.percentageForTotalAmount}
            </td>
            <td className="p-2 mx-1">
                <DropDownButton direction={'bottom'} data={item['selectedTaxCodeId']}/>
                {/*<ReactMultiSelectCheckboxes width={'100%'} placeholderButtonLabel={'Tax Code'} options={item['selectedTaxCodeId']} value={item['selectedTaxCodeId']}/>*/}
            </td>
            <td className="text-center p-2 mx-1">
                {item.totalTaxAmount}
            </td>
            {
                action === 'edit' && (permissionSet && (permissionSet.includes('U') || permissionSet.includes('C')))
                ?
                <td className="text-center p-2 mx-1">
                    {/*<Button color="info" size="sm" className="mx-1" key={index + 'deleteRow'} onClick={() =>editRow(index)}>*/}
                    {/*    <i className="fas fa-edit"/>*/}
                    {/*</Button>*/}
                    <CustomButton
                        className={'mx-1'}
                        permissionType={'U'}
                        icon={true}
                        forTable={true}
                        onClick={() => editRow(index)}
                        permissionSet={permissionSet}
                    />
                </td>
                :
                null
            }
        </CustomTableRow>
    )
}
export const fetchItemType = (url,type,setMethod)=>{
    getAPI(
        url,
        (data) => {
            if(type==='itemType'){
                if (data.data.length > 0) {
                    let tempArray = []
                    data.data.map(item => {
                        tempArray.push({
                            id: item.id,
                            text: item.itemTypeId,
                            desc: item.description,
                        })
                    })
                    setMethod(tempArray);
                }
            }else if(type ==='academicCareer'){
                if (data.data.length > 0) {
                    let tempArray = []
                    data.data.map(item => {
                        tempArray.push({
                            id: item.id,
                            text: item.academicCareerDispValue,
                            desc: item.academicCareerDesc,
                        })
                    })
                    setMethod(tempArray);
                }
            }
        },
        (data) => {
            failureToast(data["message"]);
        }
    );
}



const ItemTypeSequence = (props)=>{
    let history = useHistory();
    const {action='',id=''} = props.match.params;
    const {label,placeHolder,headerTitle,message,headerList,button} = require('./properties/index.json');
    const [isAddNewModelVisible,setIsAddNewModelVisible] = useState(false);
    const [num,setNum] = useState(1);
    const [tableData,setTableData] = useState([]);
    const [headerData,setHeaderData] = useState({
        groupCodeId:'',
        companyCodeId:'',
        academicCareerId:'',
        academicCareerDescription:'',
        termId:'',
        status:'ACTIVE',
        effectiveDate:moment().format('DD-MM-YYYY'),
        courseTypeDescription:'',
        courseType:'',
        businessAreaIds:[],
        isAllSelected: false,
    })
    const status = [
        {id:'ACTIVE',text:'ACTIVE'},
        {id:'INACTIVE',text:'INACTIVE'},
    ]
    const [updatedBy,setUpdatedBy]=useState();
    const [updatedOn,setUpdatedOn]=useState();
    const [taxCodeId,setTaxCodeId] = useState([]);
    const [allTaxCodeIds,setAllTaxCodeIds] = useState([]);
    const [isCloseModelOpen,setIsCloseModelOpen] = useState(false);
    const [isSomeThingEdit,setIsSomeThingEdit] = useState(false);
    const [itemType,setItemType] = useState([]);
    const [businessGroup,setBusinessGroup] = useState([]);
    const [companyCode,setCompanyCode] = useState([]);
    const [academicCareer,setAcademicCareer] = useState([])
    const [term,setTerm] = useState([]);
    const [courseTypes,setCourseTypes] = useState([]);
    const [businessAreaValues,setBusinessAreaValues] = useState([]);
    const [allBusinessAreaIds,setAllBusinessAreaIds] = useState([]);
    const [apiLoader,setApiLoader] = useState(true);
    const [tableDataShow,setTableDataShow] = useState([]);
    const [pagination, setpagination] = useState({
        currentPage: 1,
        nextPage: null,
        previousPage: null,
        totalPage: null,
    });

    const [isSaving,setIsSaveing] = useState(false)

    const pageSize = 10;

    const permissions = useContext(PermissionContext);

    const userPermissions = RolePermissions(
        permissions,
        pages['itemTypeSequence']['id']
    );

    const addNewRow = (numOfRows)=>{
        if(num<=10){
            let newRows = []
            let tempObject = {
                isNew :true,
                isEdit:false,
                sequenceNumber:'',
                installmentNumber:'',
                itemTypeSetupId:'',
                itemTypeDescription:'',
                percentageForTotalAmount:'',
                totalTaxAmount:'',
                selectedTaxCodeIds:[],
            }
            for(let i=0;i<num;i++){
                newRows.push(tempObject);
            }
            let tempArray = [...tableDataShow, ...newRows];
            let tempPagination = {...pagination};
            //console.log('Pagination----->',pagination)
            let n = {
                currentPage: 1,
                nextPage: null,
                previousPage: null,
                totalPage: null,
            }
            if(tempArray.length > pageSize ){
                n['totalPage'] = parseInt(tempArray.length / pageSize + 1);
                if(tableData.length === pageSize ) {
                    n.currentPage = n['totalPage'];
                }
                setpagination(n);
                let indexValue = (n.totalPage - 1) * pageSize;
                if(tableData.length < pageSize){
                    setTableData(tempArray.slice((tempPagination.currentPage-1) * 10,pageSize))
                }
                else
                setTableData(tempArray.slice(indexValue));
            } else {
                setTableData(tempArray.slice(0,pageSize));
            }
            setTableDataShow(tempArray);
            // setTableData(tempArray.slice(0,pageSize));
            setIsAddNewModelVisible(false)
            setNum(1);
            if(!isSomeThingEdit){
                setIsSomeThingEdit(true);
            }
        }else{
            failureToast('Please Enter values between 1 and 10.')
        }
    }
    const deleteRow =(index)=>{
        if(window.confirm('Are you sure want to delete this row?')){
            //console.log("Table data show------------->",tableDataShow)
            let tempArray1 = [...tableData];
            let tempArray = [...tableDataShow];
            let fixIndex = index + ((pagination.currentPage -1)*pageSize)
            tempArray1.splice(index,1);
            if (tempArray[fixIndex]['isNew']) {
                //console.log("check.......**");
                tempArray.splice(fixIndex, 1);
            }
            if(tempArray.length > pageSize){
                let n ={...pagination};
                //console.log("check--->");
                setTableData(tempArray.slice((n.currentPage-1) * pageSize, (n.currentPage) * pageSize));
                setTableDataShow(tempArray);
                setpagination(n);
                //console.log("updated table data--->",tableData);
            }
            else if(tempArray.length<=pageSize){
                let n = {...pagination};
                n.currentPage = 1;
                n.totalPage = 1;
                setTableData(tempArray.slice((n.currentPage-1) * pageSize, (n.currentPage) * pageSize));
                setTableDataShow(tempArray);
                setpagination(n);
            }
            else{
                let n = {...pagination};
                n.currentPage = 1;
                n.totalPage = 1;
                setTableData([...tempArray1]);
                setTableDataShow([...tempArray])
                setpagination(n);
            }
        }
    }
    const updateTableData = (index,key,value)=>{
        try {
            if (!isSomeThingEdit) {
                setIsSomeThingEdit(true);
            }
            let tempArray = [...tableData];
            let tempObject = tempArray[index];
            let tempArr = [...tableDataShow];
            // let tempObj = tempArr[index + ((pagination.currentPage-1)*10)]

            if ( key === 'taxCodeId' ) {
                let selectedTaxCodeId = [];
                if (value && value.length > 0) {
                    let sum = 0;
                    let flag = false;
                    value.map(item => {
                        if (item.value === '*') {
                            selectedTaxCodeId = allTaxCodeIds;
                            flag = true;
                            return;
                        }
                        if (item.value !== '*' && !flag) {
                            sum += parseFloat(item.taxValue)
                            selectedTaxCodeId.push(item.value);
                        }
                    })
                    if (flag) {
                        sum = 0;
                        tempObject = {
                            ...tempObject,
                            selectedTaxCodeId: taxCodeId
                        }
                        taxCodeId.map(item => {
                            if (item.value !== '*') {
                                sum += parseFloat(item.taxValue);
                            }
                        })
                    } else {
                        tempObject = {
                            ...tempObject,
                            selectedTaxCodeId: value
                        }
                    }
                    tempObject = {
                        ...tempObject,
                        totalTaxAmount: isNaN(sum) || !sum ? 0 : sum ,
                        taxCode: selectedTaxCodeId.join(",")
                    }
                } else if (value && value.length === 0) {
                    tempObject = {
                        ...tempObject,
                        totalTaxAmount: '',
                        taxCode: [],
                        selectedTaxCodeId: []
                    }
                }
            }
            else if (key === 'itemTypeSetupId') {
                let flag = false;
                tableDataShow.forEach(item=>{
                    if(parseInt(item.itemTypeSetupId) === parseInt(value) ){
                        flag = true;
                        return
                    }
                })
                if(!flag){
                    tempObject = {...tempObject, [key]: value}
                    itemType.map(item => {
                        if (parseInt(item.id) === parseInt(value)) {
                            tempObject = {...tempObject, ['description']: item.desc}
                        }
                    })
                }else if(flag){
                    tempObject = {...tempObject,[key]:'', ['description']: ''}
                    failureToast('Please select Unique Item Type')
                }
            }else {
                tempObject = {...tempObject, [key]: value}
            }
            tempArr[index + ((pagination.currentPage-1)*10)] = tempObject;
            tempArray[index] = tempObject;
            setTableDataShow(tempArr);
            setTableData(tempArray);
        } catch(e){
            console.error('Error-',e);
        }
    }
    const updateHeaderData = (key,value)=>{
        if(!isSomeThingEdit){
            setIsSomeThingEdit(true);
        }
        if(key ==='academicCareerId'){
            if(academicCareer.length>0){
                let desc;
                academicCareer.map((item)=>{
                    if(parseInt(item.id) === parseInt(value)){
                        desc = item.desc;
                    }
                })
                setHeaderData(preState => ({...preState, [key]:value,['academicCareerDescription']:desc}) );
            }
        }else if(key === 'courseType'){
            if(courseTypes.length > 0){
                let desc;
                courseTypes.map((item)=>{
                    if(parseInt(item.id) === parseInt(value)){
                        desc = item.desc;
                    }
                })
                setHeaderData(preState => ({...preState, [key]:value,['courseTypeDescription']:desc}) );
            }
        }else if (key === 'groupCodeId'){
            setHeaderData(preState => ({...preState, [key]: value}));
            if(action ==='new'){
                fetchDetails(masterServiceBaseUrl + `/companyCode/getCompanyCodeByGroup/${value}`, setCompanyCode, 'companyCode', 'reactSelect');
            }
        }
        else{
            setHeaderData(preState => ({...preState, [key]: value}));
        }
    }
    const onSave = () =>{
        try {
            setIsSaveing(true)
            if (headerData['groupCodeId'] === '') {
                failureToast(message.failure.businessGroup);
                setIsSaveing(false)
            } else if (headerData['companyCodeId'] === '') {
                failureToast(message.failure.companyCode);
                setIsSaveing(false)
            } else if (headerData['termId'] === '') {
                failureToast(message.failure.term);
                setIsSaveing(false)
            } else if (headerData['academicCareerId'] === '') {
                failureToast(message.failure.academicCareer);
                setIsSaveing(false)
            } else if (headerData['courseType'] === '') {
                failureToast(message.failure.courseType);
                setIsSaveing(false)
            } else if (headerData.businessAreaIds.length < 1) {
                failureToast(message.failure.businessArea);
                setIsSaveing(false)
            } else if (tableData.length === 0) {
                failureToast(message.failure.saveOneRow);
                setIsSaveing(false)
            } else {
                let tempHeaderData = {
                    academicCareerId: headerData.academicCareerId,
                    companyCodeId: headerData.companyCodeId,
                    courseType: headerData.courseType,
                    groupCodeId: headerData.groupCodeId,
                    status: headerData.status,
                    termId: headerData.termId,
                    businessAreaIds: headerData.businessAreaIds,
                    isAllSelected: headerData.isAllSelected
                }
                if(action === 'edit'){
                    tempHeaderData = {
                        ...tempHeaderData,
                        id: id
                    }
                }
                let tempTableData = [];
                let tempArray = tableDataShow;
                let flag = true;
                tempArray.map((item, index) => {
                    if (flag) {
                        if (item.installmentNumber === '') {
                            failureToast(message.failure.installmentNumber + (index + 1));
                            flag = false;
                        } else if (item.itemTypeSetupId === '') {
                            failureToast(message.failure.itemType + (index + 1));
                            flag = false;
                        } else if (item.percentageForTotalAmount === '') {
                            failureToast( message.failure.totalAmount + (index + 1));
                            flag = false;
                        } else if (item.taxCode === '') {
                            failureToast(message.failure.taxCode);
                            flag = false;
                        } else {
                            tempTableData.push({
                                id: item.id || null,
                                installmentNumber: item.installmentNumber,
                                itemTypeSequenceSetupHeaderId: (item.isNew && action === 'edit') ? id : item.itemTypeSequenceSetupHeaderId,
                                itemTypeSetupId: item.itemTypeSetupId,
                                percentageForTotalAmount: item.percentageForTotalAmount,
                                sequenceNumber: item.sequenceNumber,
                                taxCode: item.taxCode,
                                status: "ACTIVE"
                            })
                        }
                    }
                })
                let dataForApi = {
                    itemTypeSequenceSetupHeaderDTO: tempHeaderData,
                    itemTypeSequenceSetupDetailDTOList: [...tempTableData]
                }
                if(!flag){
                    setIsSaveing(false)
                }
                if (action === 'new' && flag) {
                    let method = 'POST';
                    let url = masterServiceBaseUrl + '/itemTypeSequenceSetup/createItemTypeSequenceSetup';
                    PostData(url, method, dataForApi);
                } else if (action === 'edit' && flag) {
                    let id = headerData && headerData.id;
                    let method = 'PUT';
                    // //console.log('Data For APi------->',dataForApi);
                    let url = masterServiceBaseUrl + '/itemTypeSequenceSetup/updateItemTypeSequenceSetup/' + id;
                    PostData(url, method, dataForApi);
                }
            }
        } catch(e){
            console.error('Error-',e);
        }
    }
    const PostData = (url,requestMethod,dataObject)=>{
        putpost(url,(data)=>{
            let id = data?.data?.itemTypeSequenceSetupHeader?.id
            if(action ==='edit'){
                successToast(data.message);
                fetchItemTypeData(`${masterServiceBaseUrl}/itemTypeSequenceSetup/getItemTypeSequenceSetup/${id}`, true);
                setIsSaveing(false)
            }else if((action === 'new')){
                successToast(data.message);
                setIsSaveing(false)
                history.push('/admin/itemTypeSequence/view/'+id);
            }
        },(data)=>{
            failureToast(data.message);
            setIsSaveing(false)
        },dataObject,requestMethod).then(()=>{});
    }
    const fetchItemTypeData =(url , isEdit=false)=>{
        getAPI(
            url,
            (data) => {
                if(data.data.itemTypeSequenceSetupDetails){
                    setTableDataShow(data.data.itemTypeSequenceSetupDetails)
                    if(data.data.itemTypeSequenceSetupDetails.length > pageSize){
                        let tempTableData = data.data['itemTypeSequenceSetupDetails'];
                        let newTableData = [...tempTableData];
                        tempTableData.map((item,index)=>{
                            let taxCode = item.taxCode.split(',');
                            let tempSelectedTaxCode = [];
                            if(taxCodeId.length > 0){
                                taxCode.map(taxId=>{
                                    taxCodeId.map(el=>{
                                        if(parseInt(el.value) === parseInt(taxId)){
                                            tempSelectedTaxCode.push(el);
                                        }
                                    })
                                })
                                newTableData[index]['selectedTaxCodeId'] = tempSelectedTaxCode;
                            }
                        })
                        //console.log('Table Data------>',newTableData);
                        setTableData(newTableData.slice(0,pageSize));
                        setTableDataShow(newTableData);
                        let n = pagination;
                        n.currentPage = 1
                        n.totalPage = parseInt(data.data.itemTypeSequenceSetupDetails.length / pageSize) + (data.data.itemTypeSequenceSetupDetails.length % pageSize ? 1 : 0);
                        setpagination(n);
                    }else{
                        let tempTableData = data.data['itemTypeSequenceSetupDetails'];
                        let newTableData = [...tempTableData];
                        tempTableData.map((item,index)=>{
                            let taxCode = item.taxCode.split(',');
                            let tempSelectedTaxCode = [];
                            if(taxCodeId.length > 0){
                                taxCode.map(taxId=>{
                                    taxCodeId.map(el=>{
                                        if(parseInt(el.value) === parseInt(taxId)){
                                            tempSelectedTaxCode.push(el);
                                        }
                                    })
                                })
                                //console.log("taxCode",taxCode)
                                //console.log(taxCodeId?.length == taxCode?.length)
                                //console.log(taxCodeId)
                                if(taxCodeId?.length - 1 == taxCode?.length){
                                    tempSelectedTaxCode.push({label:'All',value:'*'});
                                }
                                newTableData[index]['selectedTaxCodeId'] = tempSelectedTaxCode;
                            }
                        })
                        setTableData(newTableData);
                        //console.log('TableData----->',newTableData)
                        setTableDataShow(newTableData);
                    }
                }

                if(data.data.itemTypeSequenceSetupHeader){
                    setUpdatedBy(data?.data?.itemTypeSequenceSetupHeader?.updatedBy);
                    setUpdatedOn(data?.data?.itemTypeSequenceSetupHeader?.updatedOn)
                    let businessAreaIds = data?.data?.itemTypeSequenceSetupHeader?.businessAreaIds;
                    let tempObject = data?.data?.itemTypeSequenceSetupHeader;
                    let selectedBusinessAreaIds = [];
                    businessAreaIds.map(id=>{
                        businessAreaValues.map(item=>{
                            if(parseInt(item?.value) === parseInt(id)){
                                selectedBusinessAreaIds.push(item);
                            }
                        })
                    })
                    if(data.data.itemTypeSequenceSetupHeader?.isAllSelected){
                        selectedBusinessAreaIds.push({label:'All',value:'*'});
                    }
                    tempObject = {
                        ...tempObject,
                        selectedBusinessAreaIds : selectedBusinessAreaIds,
                    }
                    setHeaderData(tempObject);
                }
                setApiLoader(false);
                if(action === 'edit' && isEdit){
                    history.push('/admin/itemTypeSequence/view/'+id);
                }

            },
            (data) => {
                failureToast(data['message']);
            }
        );
    }
    const fetchMultiSelectDropDown = (url,type,setMethod)=>{
        getAPI(
            url,
            (data) => {
                let tempArray = [];
                let tempAllIds = [];
                tempArray.push({label:'All',value:'*'})
                if(type === 'businessArea'){
                    if (data.data.length > 0) {
                        data.data.map(item => {
                            tempAllIds.push(item.id);
                            tempArray.push({
                                label: item.businessAreaDispValue,
                                value: item.id
                            })
                        })
                        setBusinessAreaValues(tempArray);
                        setAllBusinessAreaIds(tempAllIds);
                    }
                }else if(type === 'taxCodeId'){
                    let allIds = [];
                    if(data?.data?.length > 0){
                        data.data.map(item=>{
                            tempArray.push({
                                label : item.taxCodeIdDispValue,
                                value : item.id,
                                taxValue : parseFloat(item.totalTaxValue),
                            })
                            allIds.push(item.id);
                        })
                        setMethod(tempArray)
                        setAllTaxCodeIds(allIds);
                    }
                }
            },
            (data) => {
                failureToast(data["message"]);
            }
        );
    }
    const fetchRequiredData = ()=>{
        if(action !=='new'){
            fetchDetails(masterServiceBaseUrl + `/companyCode/getAllCompanyCode`, setCompanyCode, 'companyCode', 'reactSelect');
        }
        fetchMultiSelectDropDown(masterServiceBaseUrl + '/businessArea/getAllActiveBusinessArea','businessArea',setBusinessAreaValues);
        fetchItemType(masterServiceBaseUrl +'/itemTypeSetup/getActiveItemTypeSetup','itemType' , setItemType);
        fetchMultiSelectDropDown(masterServiceBaseUrl +'/taxCodeId/getAllActiveTaxCodeId','taxCodeId' , setTaxCodeId);
        fetchDetails(masterServiceBaseUrl +'/term/getAllActiveTerm', setTerm,'term','reactSelect');
        fetchDetails(masterServiceBaseUrl +'/courseAttributeValue/getCourseAttributeValueByCourseType', setCourseTypes,'courseAttributeValue','reactSelect');
        fetchDetails(masterServiceBaseUrl + `/groupCode/getAllActiveGroupCode`, setBusinessGroup, 'groupCode', 'reactSelect');
        fetchItemType(masterServiceBaseUrl +'/academicCareer/getAllActiveAcademicCareer','academicCareer', setAcademicCareer);
    }
    const editRow = (index)=>{
        let tempArray = tableData.slice(0);
        tempArray[index].isEdit = true;
        let tempObject = tempArray[index];
        let itemTypeId = tempObject.itemTypeSetupId;
        if (itemTypeId && itemType && itemType.length) {
            itemType.map((item) => {
                if (parseInt(item.id) === itemTypeId) {
                    tempObject = { ...tempObject, ['description']: item.desc };
                }
            });
        }
        tempArray[index] = tempObject;
        setTableData(tempArray);
        if(action === 'edit' && !isSomeThingEdit){
            setIsSomeThingEdit(true);
        }
    }
    const closeHandler = (key,flag = false)=>{
        if(key === '1'){
            setIsCloseModelOpen(true);
        } else if(key === '2'){
            if ((action === 'edit' || action === 'view') && flag) {
                setIsCloseModelOpen(false);
                window.close();
            } else if(action === 'new' && flag ) {
                setIsCloseModelOpen(false);
                history.push('/admin/itemTypeSequence');
            } else {
                setIsCloseModelOpen(false);
            }
        }
    }

    // Use effect to fetch the required Data
    useEffect(()=>{
        fetchRequiredData();
    },[]);

    useEffect(() => {
        if((action ==='view' || action ==='edit') && businessAreaValues.length >0 && taxCodeId.length>0){
            fetchItemTypeData(`${masterServiceBaseUrl}/itemTypeSequenceSetup/getItemTypeSequenceSetup/${id}`);
        }
    },[businessAreaValues,taxCodeId])

    useEffect(()=>{
        if(action ==='view' && isSomeThingEdit){
            setIsSomeThingEdit(false);
        }
    },[action])
    useEffect(() => {
        if(action === 'new' && businessGroup.length > 0 && academicCareer.length > 0){
            setApiLoader(false);
        }
    },[businessGroup, academicCareer])

    const GoBack =()=>{
        if (action === 'view' || action ==='edit') {
            window.close();
        } else if(action === 'new') {
            window.history.back();
        }
    }
    const redirectToEditPage = () => {
        let URL = window.location.href;
        if (URL && URL.length) {
            URL = URL.replace('view', 'edit');
            // window.location.href = url;
            window.open(URL, "_self");
        }
    }
    //console.log({userPermissions})
    return (
        userPermissions ?
        apiLoader
        ?
            <CustomLoader apiLoader={apiLoader} loaderHeight={'200px'} loaderWidth={'100% '}/>
        :
            <Fragment>
                <Container fluid className="mt-3">
                    {/*<Row>*/}
                    {/*    <Col style={{marginBottom: '19px', paddingLeft: '30px'}}>*/}
                    {/*        <h3 className="mb-0 floatLeft" style={{marginTop: '3px'}}>*/}
                    {/*            <i className="fa fa-arrow-left" style={{marginRight: '10px', cursor: 'pointer'}}*/}
                    {/*               onClick={GoBack}/>*/}
                    {/*            Item Type Sequence*/}
                    {/*        </h3>*/}
                    {/*        {*/}
                    {/*            action === 'view'*/}
                    {/*                ?*/}
                    {/*                <Button className={'floatRight mr-3'} color="info" size="md"*/}
                    {/*                        type="button" onClick={redirectToEditPage}*/}
                    {/*                >*/}
                    {/*                    {'Edit Details'}*/}
                    {/*                </Button>*/}
                    {/*                : null*/}
                    {/*        }*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Card>
                        <CardHeader>
                            <h2 className="mb-0 floatLeft">
                                {headerTitle}
                            </h2>
                        </CardHeader>
                        <CardBody>
                            <ItemTypeSequenceHeader allBusinessAreaIds={allBusinessAreaIds} businessAreaValues={businessAreaValues} action={action} businessGroup={businessGroup} companyCode={companyCode} term={term} academicCareer={academicCareer} courseTypes={courseTypes} updateHeaderData={updateHeaderData} setCompanyCode={setCompanyCode} headerData={headerData} status={status} label={label} placeholder={placeHolder} updatedBy={updatedBy} updatedOn={updatedOn} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader className="border-0">
                            {
                                action ==='new' ||action ==='edit' ?
                                    // <Button color="info" size="sm" className="floatRight mx-1"
                                    //      onClick={() => setIsAddNewModelVisible(true)}>
                                    //     {button.add}
                                    // </Button>
                                    <CustomButton
                                        className={'floatRight mx-1'}
                                        content={button.add}
                                        permissionType={'C'}
                                        icon={true}
                                        onClick={() => setIsAddNewModelVisible(true)}
                                        permissionSet={userPermissions}
                                    />
                            :
                                    null
                            }
                        </CardHeader>
                            <div id="questionTable" >
                                <Table className="align-items-center table-flush">
                                    <MasterHeaderAuto permissionSet={userPermissions} headerList={ action === 'view' || !(userPermissions.includes('U') || userPermissions.includes('C'))? headerList.slice(0,headerList.length - 1) :headerList} />
                                    <tbody className="list" key={'itemTypeSequenceTableBody'}>
                                    {
                                        tableData && tableData.length >0
                                            ?
                                            tableData.map( (item,index)=>
                                            item.isNew || item.isEdit
                                                ?
                                                <CustomTableRow key={'ItemTypeSequenceRow'+index}>
                                                    <td className="text-center p-2 mx-1">
                                                        <CommonInput
                                                            forTable={true}
                                                            mandatory={true}
                                                            type={'text'}
                                                            maxlength={2}
                                                            placeHolder={placeHolder.sNo}
                                                            defaultValue={item['sequenceNumber']}
                                                            isDisabled={true}
                                                        />
                                                    </td>
                                                    <td className="text-center p-2 mx-1">
                                                        <CommonInput
                                                            forTable={true}
                                                            mandatory={true}
                                                            type={'number'}
                                                            maxlength={3}
                                                            placeHolder={placeHolder.installmentNumber}
                                                            onChange={(value) => {
                                                                if(value.length <=3)updateTableData(index, 'installmentNumber', value)
                                                            }}
                                                            defaultValue={item['installmentNumber']}
                                                            isDisabled={false}
                                                        />
                                                    </td>
                                                    <td className="text-center p-2 mx-1">
                                                        <CommonInput
                                                            forTable={true}
                                                            data={itemType}
                                                            mandatory={true}
                                                            type={'reactSelect'}
                                                            placeHolder={placeHolder.itemType}
                                                            defaultValue={item["itemTypeSetupId"]}
                                                            onChange={(value) => {updateTableData(index , 'itemTypeSetupId', value)}}
                                                            isDisabled={false}
                                                        />
                                                    </td>
                                                    <td className="text-center p-2 mx-1">
                                                        <CommonInput forTable={true} mandatory={true} type={'text'} placeHolder={placeHolder.description} defaultValue={item['description']} isDisabled={true}/>
                                                    </td>
                                                    <td className="text-center p-2 mx-1">
                                                        <CommonInput
                                                            forTable={true}
                                                            mandatory={true}
                                                            type={'number'}
                                                            maxlength={3}
                                                            placeHolder={placeHolder.totalAmount}
                                                            defaultValue={item['percentageForTotalAmount']}
                                                            onChange={(value) => {
                                                                if(value.length <=3)
                                                                    updateTableData(index, 'percentageForTotalAmount', value)
                                                            }}
                                                            isDisabled={false}
                                                        />
                                                    </td>
                                                    <td className=" p-2 mx-1">
                                                        {/*<ReactMultiSelectCheckboxes*/}
                                                        {/*//     width={'100%'}*/}
                                                        {/*//     placeholderButtonLabel={placeHolder.taxCode}*/}
                                                        {/*//     options={taxCodeId}*/}
                                                        {/*//     value={item['selectedTaxCodeId']}*/}
                                                        {/*//     onChange={(e) => {*/}
                                                        {/*//         updateTableData(index,'taxCodeId',e);*/}
                                                        {/*//     }}*/}
                                                        {/*// />*/}

                                                        <CommonInput
                                                            forTable={true}
                                                            onChange={(value) => updateTableData(index,'taxCodeId',value)}
                                                            defaultValue={item['selectedTaxCodeId']}
                                                            placeHolder={'Tax Code'}
                                                            type={headerData.academicCareerIds?.length === 0 ? 'select' : 'multiSelectWithCheckboxes'}
                                                            data={taxCodeId}
                                                        />
                                                    </td>
                                                    <td className="text-center p-2 mx-1">
                                                        <CommonInput forTable={true} type={'text'} maxlength={3} placeHolder={placeHolder.totalTax} defaultValue={item['totalTaxAmount']} isDisabled={true}/>
                                                    </td>
                                                    <td className="text-center p-2 mx-1">
                                                        {
                                                            action === 'new'||(action === 'edit'&&item.isNew)
                                                            ?
                                                            <Button color="danger" size="sm" className="floatRight mx-1" key={index + 'deleteRow'} onClick={() => deleteRow(index)}>
                                                                <i className="fas fa-trash"/>
                                                            </Button>
                                                            : null
                                                        }
                                                    </td>
                                                </CustomTableRow>
                                                :
                                                <TDROW itemType={itemType} item={item} index={index} action={action} editRow={editRow} taxCodeId={taxCodeId} permissionSet={userPermissions}/>
                                            )
                                            :
                                            null
                                    }
                                    </tbody>
                                </Table>
                                {
                                    pagination.totalPage > 1
                                    ?
                                    <GetPagination setclassArray={setTableData} pagination={pagination} setpagination={setpagination} pageSize={pageSize} state={tableDataShow}/>
                                    :
                                    null
                                }
                            </div>
                            <div>
                            <CardHeader className="border-0 floatRight">
                                {
                                    action ==='edit' || action === 'new'
                                    ?
                                    // <Button color="info" size="md" className="floatRight mx-1" onClick={onSave}>
                                    //     {button.save}
                                    // </Button>
                                        <div className="floatRight">
                                            <CustomButton
                                                className={'floatRight mx-1'}
                                                content={button.save}
                                                permissionType={'C,U'}
                                                forTable={true}
                                                onClick={onSave}
                                                permissionSet={userPermissions}
                                                disabled={isSaving}
                                            />
                                        </div>
                                    :
                                    null
                                }
                                <CustomButton
                                    className={'floatRight mx-1'}
                                    type="cancel"
                                    content={"Close"}
                                    permissionType={'cancel'}
                                    permissionSet={userPermissions}
                                    disabled={false}
                                    forTable={true}
                                    onClick={() => { closeHandler('1') }}
                                />
                            </CardHeader>
                            </div>
                    </Card>
                    <AddNewModel num={num} setNum={setNum} isVisible={isAddNewModelVisible} addNewRow={addNewRow} setIsVisible={setIsAddNewModelVisible} onSubmit={addNewRow} />
                    <CloseModel isCloseModelOpen={isCloseModelOpen} closeHandler={closeHandler} isSomeThingEdit={isSomeThingEdit} />
                </Container>
            </Fragment>
            :
            <PermisionDenied />
    )
}

export default ItemTypeSequence;