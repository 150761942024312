import React from "react";
import { useGetDlpModuleContext } from "../contextApi/dlpContext";

const btnStyle = {
  width: "91px",
  height: "36px",
  padding: "8px, 11px, 8px, 11px",
  borderRadius: "8px",
  gap: "10px",
  background: "#FFFFFF",
  fontSize: "14px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  fontWeight: "650",
  color: "#333",
  cursor: "pointer",
};
const activeTabStyle = {
  background: "#00B0F5",
  color: "#FFFFFF",
};
const disabledStyle = {
  background: "#EDEDED",
  color: "#333",
  cursor: "default",
};

const SelectFilterChip = ({
  selectedFilter,
  setSelectedFilter,
  disabled = false,
  isHideSingle = false,
  cb = () => {}
}) => {
  // const { selectedFilter, setSelectedFilter } = useGetDlpModuleContext();
  return (
    <div className="d-flex gap-md my-3">
      {!isHideSingle && (
        <div
          style={{
            ...btnStyle,
            ...(selectedFilter === 'SINGLE' ? activeTabStyle : {}),
            ...(disabled ? disabledStyle : {}),
          }}
          onClick={() => {
            setSelectedFilter('SINGLE');
            cb();
          }}
        >
          Single Issue
        </div>
      )}
      <div
        style={{
          ...btnStyle,
          ...(selectedFilter === 'BULK' ? activeTabStyle : {}),
          ...(disabled ? disabledStyle : {}),
        }}
        onClick={() => {
          setSelectedFilter('BULK');
          cb();
        }}
      >
        Bulk Issue
      </div>
    </div>
  );
};

export default SelectFilterChip;
