import React from 'react';
import {
	Card,
	CardHeader,
	Input,
	Container,
	Button,
	Table,
	Row,
} from 'reactstrap';
import { useState } from 'react';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import {
	TDROW,
	MasterHeader,
} from '../../common/commonComponents/masterRow/masterRow';
import {
	masterServiceBaseUrl,
	putpost,
	putpost2,
} from 'services/http';
import moment from 'moment';
import Select2 from 'react-select2-wrapper';
import IsCoreMaster from '../../common/commonComponents/coreMaster';
import CustomButton from 'components/CustomButton';

var _ = require('lodash');

const AcademicGroupTable = (props) => {
	const { userPermissions, isSaveVisible, setisSaveVisible = () => { } } = props;
	const { companyCodeData, allCompanyCodeData } = props;
	const [tableDataKey, settableDataKey] = useState('tableDataKey');
	const [isDisabledField, setIsDisabledField] = useState(false);

	const addNewClass = () => {
		props.setclassArray([
			{
				editMode: true,
				__status: '__new',
				academicGroupKey: null,
				status: 'ACTIVE',
				academicGroupDesc: null,
				academicGroupDispValue: null,
				sapSegmentTypeSequence: null,
				sapSegmentType: null,
				effectiveDate: null,
				academicGroupCategoryCode: null,
				companyCodeMasterId: null,
			},
			...props.classArray,
		]);
	};
	const saveAll = (cb) => {
		var _tempArray = props.classArray;
		var isValid = true;
		_tempArray.forEach((el, index) => {
			if (el['editMode']) {
				if (el['companyCodeMasterId'] === null) {
					isValid = false;
					failureToast('Please Select Company Code.');
					return;
				} else if (
					el['academicGroupKey'] === null ||
					!el['academicGroupKey'].trim()
				) {
					isValid = false;
					failureToast('Please enter the value of Academic Group Key');
					return;
				} else if (
					el['academicGroupDesc'] === null ||
					!el['academicGroupDesc'].trim()
				) {
					isValid = false;
					failureToast('Please enter the value of Description');
					return;
				} else if (
					el['academicGroupDispValue'] === null ||
					!el['academicGroupDispValue'].trim()
				) {
					isValid = false;
					failureToast('Please enter the value of Display Value');
					return;
				} else if (
					el['sapSegmentTypeSequence'] === null ||
					!el['sapSegmentTypeSequence'].trim()
				) {
					isValid = false;
					failureToast('Please enter the value of SAP Segment Sequence');
					return;
				} else if (
					el['academicGroupCategoryCode'] === null ||
					!el['academicGroupCategoryCode'].trim()
				) {
					isValid = false;
					failureToast('Please enter the value of Category Code.');
					return;
				} else if (
					el['sapSegmentType'] === null ||
					!el['sapSegmentType'].trim()
				) {
					isValid = false;
					failureToast('Please enter the value of SAP Segment Type');
					return;
				}
				if (!isValid) {
					return;
				}
				if (_tempArray[index]['__status'] === '__new') {
					putpost(
						masterServiceBaseUrl + '/academicGroup/createAcademicGroup',
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							props.fetchClasses(
								masterServiceBaseUrl + '/academicGroup/getAllAcademicGroup'
							);
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'post'
					);
				} else {
					putpost(
						masterServiceBaseUrl +
						'/academicGroup/updateAcademicGroup/' +
						_tempArray[index].id,
						(data) => {
							successToast(data['message']);
							setisSaveVisible(false);
							delete _tempArray[index]['editMode'];
							props.setclassArray(_tempArray);
							setIsDisabledField(false);
							settableDataKey(new Date().getMilliseconds());
							props.fetchClasses(
								masterServiceBaseUrl + '/academicGroup/getAllAcademicGroup'
							);
							// props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
						},
						(data) => {
							failureToast(data['message']);
						},
						_tempArray[index],
						'put'
					);
				}
			}
		});
	};
	const newRow = () => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				addNewClass();
				setisSaveVisible(true);
				// settableDataKey(new Date().getMilliseconds())
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			
			props.setclassArray(_tempArray);
			setisSaveVisible(false);
			cb();
		}
	};
	const editRow = (index) => {
		if (isSaveVisible) {
			failureToast('Please Delete or Save current changes');
		} else {
			let cb = () => {
				var _tempArray = props.classArray;
				_tempArray[index]['editMode'] = true;
				props.setclassArray(_tempArray);
				setisSaveVisible(true);
				setIsDisabledField(true);
			};
			var _tempArray = props.classArray;
			_tempArray = _.sortBy(_tempArray, [
				function (o) {
					return o.value;
				},
			]);
			cb();
		}
	};
	const deleteRow = (index) => {
		if (props.classArray[index]['__status'] === '__new') {
			let n = props.classArray;
			n.splice(index, 1);
			props.setclassArray(n);
			setisSaveVisible(false);
			settableDataKey(new Date().getMilliseconds());
		} else {
			if (
				window.confirm('Are you sure you want to delete this Academic Group?')
			) {
				putpost2(
					masterServiceBaseUrl +
					'/academicGroup/deleteAcademicGroup/' +
					props.classArray[index].id,
					(data) => {
						successToast(data['message']);
						if (props.classArray.length === 1) {
							props.previousPage();
							props.fetchClasses(
								masterServiceBaseUrl + '/academicGroup/getAllAcademicGroup'
							);
						} else {
							var _tempArray = props.classArray;
							if (_tempArray[index]['editMode']) {
								setisSaveVisible(false);
							}
							_tempArray.splice(index, 1);
							props.setclassArray(_tempArray);
						}

						settableDataKey(new Date().getMilliseconds());
					},
					(data) => {
						failureToast(data['message']);
					},
					'Delete'
				);
			}
		}
	};
	const updateKey = (index, value, key) => {
		var _tempArray = props.classArray;
		_tempArray[index][key] = value;
		props.setclassArray(_tempArray);
	};

	const validate = (value, type) => {
		try {
			let pattern_1 = /^[0-9]*$/;

			if (type === 'sapSequence') {
				if (!pattern_1.test(value)) {
					return failureToast('SAP Sequence contains only Numeric value');
				} else {
					return true;
				}
			}
		} catch (error) {
			console.log('Error', error);
		}
	};

	return (
		<>
			<Container className="mt-3" fluid>
				<Row>
					<div className="col">
						<Card>
							<CardHeader className="border-0">
								<h3 className="mb-0 floatLeft">Master - Academic Group</h3>
								<IsCoreMaster
									master={'academicGroup'}
									masterDisplayValue={'Academic Group'}
								/>
								<CustomButton
									disabled={isSaveVisible}
									className={
										'floatRight mx-1' +
										(isSaveVisible ? ' btn-dark cursor-gray' : null)
									}
									content={'New Academic Group'}
									permissionType={'C'}
									icon={true}
									onClick={() => {
										newRow();
									}}
									permissionSet={userPermissions}
								/>
							</CardHeader>
							<div id="questionTable" className="table-responsive">
								<Table className="align-items-center table-flush tableLayout">
									<MasterHeader
										type={'Academic Group'}
										isSaveVisible={isSaveVisible}
										permissionSet={userPermissions}
									/>
									<tbody className="list" key={tableDataKey}>
										{props.classArray
											? props.classArray.map((el, index) =>
												!el['editMode'] ? (
													<TDROW
														type={'academicGroup'}
														allCompanyCodeData={allCompanyCodeData}
														index={index}
														el={el}
														editRow={editRow}
														deleteRow={deleteRow}
														isSaveVisible={isSaveVisible}
														permissionSet={userPermissions}
													/>
												) : (
													<tr key={index + '-class'}>
														<td className="text-center p-2 mx-1">
															<Select2
																options={{
																	placeholder: ' Select Company Code',
																}}
																data={companyCodeData}
																defaultValue={el['companyCodeMasterId']}
																onChange={(e) =>
																	updateKey(
																		index,
																		e.target.value,
																		'companyCodeMasterId'
																	)
																}
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="50"
																defaultValue={el['academicGroupKey']}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'academicGroupKey'
																	);
																}}
																placeholder="Example:1"
																className="tdInput"
															/>
														</td>

														<td className="text-center p-2 mx-1">
															<Input
																disabled={true}
																defaultValue={
																	el['__status'] === '__new'
																		? null
																		: moment(el['eff_date']).format(
																			'DD-MM-YYYY'
																		)
																}
																placeholder="Example:01-01-2020"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="50"
																defaultValue={el['academicGroupDesc']}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'academicGroupDesc'
																	);
																}}
																placeholder="Description"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="50"
																defaultValue={el['academicGroupDispValue']}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'academicGroupDispValue'
																	);
																}}
																placeholder="Display Value"
																className="tdInput"
																disabled ={!!el.id}
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="5"
																defaultValue={el['sapSegmentTypeSequence']}
																disabled={isDisabledField}
																onChange={(e) => {
																	let n = e.target.value;
																	if (validate(n, 'sapSequence')) {
																		updateKey(
																			index,
																			n,
																			'sapSegmentTypeSequence'
																		);
																	}
																}}
																placeholder="SAP Segment Sequence"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="1"
																defaultValue={el['academicGroupCategoryCode']}
																// disabled={isDisabledField}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'academicGroupCategoryCode'
																	);
																}}
																placeholder="Category Code"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<Input
																maxLength="50"
																defaultValue={el['sapSegmentType']}
																// disabled={isDisabledField}
																onChange={(e) => {
																	updateKey(
																		index,
																		e.target.value,
																		'sapSegmentType'
																	);
																}}
																placeholder="SAP Segment Type"
																className="tdInput"
															/>
														</td>
														<td className="text-center p-2 mx-1">
															<label className="custom-toggle mx-auto ml-2">
																<input
																	checked={
																		el.status === 'ACTIVE' ? true : false
																	}
																	type="checkbox"
																	id={el.id}
																	onChange={(e) => {
																		settableDataKey(
																			new Date().getMilliseconds()
																		);
																		
																		let n = 'ACTIVE';
																		if (!e.target.checked) {
																			n = 'INACTIVE';
																		}
																		updateKey(index, n, 'status');
																	}}
																/>
																<span
																	style={{ width: '72px' }}
																	className="custom-toggle-slider rounded-circle activeToggle"
																	data-label-off="Inactive"
																	data-label-on="Active"
																/>
															</label>
														</td>
														<td className="text-center">
															{/* <Button color="info" size="sm" type="button" onClick={() => {
                          deleteRow(index)
                        }}>  <i className="fas fa-trash" /></Button> */}
															{isSaveVisible ? (
																<>
																	<CustomButton
																		content={'Save'}
																		permissionType={'C,U'}
																		forTable={true}
																		onClick={() => {
																			saveAll();
																		}}
																		permissionSet={userPermissions}
																	/>
																	<CustomButton
																		permissionType={'cancel'}
																		icon={true}
																		forTable={true}
																		onClick={() => {
																			let n = props.classArray;
																			if (n[index]['__status'] === '__new') {
																				n.splice(index, 1);
																			} else {
																				// n[index]['editMode']=false
																				props.fetchClasses(
																					masterServiceBaseUrl +
																					'/academicGroup/getAllAcademicGroup'
																				);
																			}
																			props.setclassArray(n);
																			setisSaveVisible(false);
																			setIsDisabledField(false);
																		}}
																		permissionSet={userPermissions}
																	/>
																</>
															) : (
																<CustomButton
																	permissionType={'D'}
																	icon={true}
																	forTable={true}
																	onClick={() => deleteRow(index)}
																	permissionSet={userPermissions}
																/>
															)}
														</td>
													</tr>
												)
											)
											: null}
									</tbody>
								</Table>
							</div>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};
export default AcademicGroupTable;
