import React  from 'react';
import {Button, FormGroup, Input, Label, Row,Col} from "reactstrap";
import Select2 from "react-select2-wrapper";
import {
    fetchDetails,
    getDispValForReactSelectByOneID
} from "../../../../common/utils/methods/commonMethods/utilityMethod";
import {enrollmentServiceBaseUrl, getAPI, masterServiceBaseUrl} from "../../../../../../../services/http";
import moment from "moment";
import {RedAstric} from "../../../../examService/examIdGeneration/examIdSearchForm";
import CommonInput from "../../../../common/formFeilds/input/commonInput";
const StudentDetailsForm =(props)=>{
    const {action,formData,UpdateFormData,studentIds,setIsCourseDetailOpen,setIsExamDetailOpen,setCourseIds,
        opportunityType,setGridNumber,setBusinessArea,
        businessGroup,
        setCompanyCode,
        companyCode,
        nextHandler,label,placeholder,button
    } = props;
    const filterOption = (data) => {
        return data?.map((data) => {
            return Object.assign(
                {},
                {
                    label: data?.studentId,
                    value: data?.studentId,
                    studentName: `${data?.studentFirstName} ${data?.studentLastName}`,
                }
            );
        });
    };
    const promiseOptions = (inputValue) => {
        return new Promise((resolve) => {
            if (inputValue && inputValue.length < 10) {
                return resolve([]);
            }
            getAPI(
                `${enrollmentServiceBaseUrl}/studentDetails/getAllStudentBySmartSearch/${inputValue}`,
                (data) => {
                    resolve(filterOption(data?.data));
                },
                (data) => {
                    // failureToast(data["message"]);
                }
            );
        });
    };
    return(
        <div className='container-fluid'>
            <Row>
                <Col md={3}>
                    {/*<FormGroup>*/}
                    {/*    <Label for="studentId" className="form-control-label">Student ID <RedAstric/></Label>*/}
                    {/*    {*/}
                    {/*            <Select2 options={{placeholder: 'Student ID',}}*/}
                    {/*                     data={studentIds}*/}
                    {/*                     disabled={action !=='new'}*/}
                    {/*                     defaultValue={formData['studentId']}*/}
                    {/*                     onChange={(e) => {*/}
                    {/*                         if(e.target.value) {*/}
                    {/*                             UpdateFormData(1, 'studentId', e.target.value);*/}
                    {/*                             studentIds.map((item)=>{*/}
                    {/*                                 if(item.id ===e.target.value){*/}
                    {/*                                     UpdateFormData(1,'studentName',item.desc)*/}
                    {/*                                 }*/}
                    {/*                             })*/}
                    {/*                         }*/}
                    {/*                     }}*/}
                    {/*            />*/}
                    {/*    }*/}
                    {/*</FormGroup>*/}
                        <CommonInput label={label.studentId} placeHolder={placeholder.studentId}
                                     defaultValue={formData.studentId}
                                     mandatory={true}
                                     type={ action === 'new' ?  'smartSearchSelect' : 'text'}
                                     onChange={(data)=>{
                                         if(action ==='new'){
                                             UpdateFormData(1, 'studentId', data);
                                         }
                                     }}
                                     isDisabled={ action !== 'new' }
                                     promiseHandler={promiseOptions}
                        />
                </Col>
                <Col md={3}>
                    <CommonInput label={label.studentName} placeHolder={placeholder.studentName} defaultValue={formData.studentName} type={ 'text'} isDisabled={ true }/>
                </Col>
                <Col md={3}>

                    <CommonInput
                        mandatory={true}
                        data={businessGroup}
                        label={label.businessGroup}
                        placeHolder={placeholder.businessGroup}
                        defaultValue={action === 'new' ? formData.businessGroup : getDispValForReactSelectByOneID(formData['businessGroup'] ,'DispValue',businessGroup)}
                        type={action === 'new' ? 'reactSelect' : 'text'}
                        onChange={(value)=> UpdateFormData(1,'businessGroup',parseInt(value))}
                        isDisabled={ action !== 'new' }
                    />


                    {/*<FormGroup>*/}
                    {/*    <Label for="studentId" className="form-control-label">Business Group <RedAstric/></Label>*/}
                    {/*    {*/}
                    {/*        <Select2 options={{placeholder: 'Business Group',}}*/}
                    {/*                 data={businessGroup}*/}
                    {/*                 disabled={action !== 'new'}*/}
                    {/*                defaultValue={formData['businessGroup']}*/}
                    {/*                 onChange={(e) => {*/}
                    {/*                     if(e.target.value) {*/}
                    {/*                         UpdateFormData(1,'businessGroup',parseInt(e.target.value));*/}
                    {/*                         setCompanyCode([]);*/}
                    {/*                         setBusinessArea([]);*/}
                    {/*                         if(action ==='new'){*/}
                    {/*                             fetchDetails(masterServiceBaseUrl + `/companyCode/getCompanyCodeByGroup/` + e.target.value, setCompanyCode, 'companyCode', 'reactSelect');*/}
                    {/*                         }*/}
                    {/*                     }*/}
                    {/*                 }}*/}
                    {/*        />*/}
                    {/*    }*/}
                    {/*</FormGroup>*/}
                </Col>
                <Col md={3}>
                    {/*<FormGroup>*/}
                    {/*    <Label for="studentId" className="form-control-label">Company Code <RedAstric/></Label>*/}
                    {/*    {*/}
                    {/*        <Select2 options={{placeholder: 'Company Code',}}*/}
                    {/*            data={companyCode}*/}
                    {/*                 disabled={action !== 'new'}*/}
                    {/*            defaultValue={formData['companyCode']}*/}
                    {/*                 onChange={(e) => {*/}
                    {/*                     if(e.target.value) {*/}
                    {/*                         UpdateFormData(1, 'companyCode', parseInt(e.target.value))*/}
                    {/*                         setBusinessArea([]);*/}
                    {/*                         if(action ==='new'){*/}
                    {/*                             fetchDetails(masterServiceBaseUrl + `/businessArea/getBusinessAreaByCompanyGroupCode?companyCode=${e.target.value}&groupCode=${formData['businessGroup']}`, setBusinessArea, 'businessArea', 'reactSelect');*/}
                    {/*                         }if(formData['businessGroup']){*/}
                    {/*                             // fetchData(masterServiceBaseUrl + `/courseCatalogCourseHeader/getCourseCatalogCourseHeaderByGroupCodeAndCompanyCode?groupCode=${formData['businessGroup']}&companyCode=${e.target.value}`,setCourseIds,'courseId')*/}
                    {/*                         }*/}
                    {/*                     }*/}
                    {/*                 }}*/}
                    {/*        />*/}
                    {/*    }*/}
                    {/*</FormGroup>*/}
                    <CommonInput
                        mandatory={true}
                        data={companyCode}
                        label={label.companyCode}
                        placeHolder={placeholder.companyCode}
                        defaultValue={action === 'new' ? formData.companyCode : getDispValForReactSelectByOneID(formData['companyCode'] ,'DispValue',companyCode)}
                        type={action === 'new' ? 'reactSelect' : 'text'}
                        onChange={(value)=>{
                            if(value) {
                                UpdateFormData(1, 'companyCode', parseInt(value))
                            }
                        }}
                        isDisabled={ action !== 'new' }
                    />
                </Col>

                <Col md={3}>
                    <CommonInput
                        type={ 'text'}
                        mandatory={true}
                        label={label.opportunityId}
                        isDisabled={ action !== 'new' }
                        placeHolder={placeholder.opportunityId}
                        defaultValue={formData.opportunityId}
                        onChange={(value) => UpdateFormData(1, 'opportunityId', value.replace(/[^\w\s]/gi, "").replaceAll(/\s/g,''))}
                    />
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.opportunityType}<RedAstric/></Label>
                        {
                            <Select2 options={{placeholder: placeholder.opportunityType,}} data={opportunityType} disabled={action !== 'new'}
                                     defaultValue={formData['opportunityType']}
                                     onChange={(e) => {
                                            if(e.target.value) {
                                                 UpdateFormData(1, 'opportunityType', parseInt(e.target.value))
                                                 setGridNumber(null)
                                                 setIsCourseDetailOpen(false);
                                                 setIsExamDetailOpen(false)
                                                 if(opportunityType){
                                                     opportunityType.map((item)=> {
                                                         if ( parseInt( item.id ) === parseInt( e.target.value ) && item.text.toLowerCase() ==='exam') {
                                                             setGridNumber(2)
                                                         }
                                                         if(item.text.toLowerCase() ==='course' && parseInt(item.id) === parseInt( e.target.value )){
                                                             setGridNumber(1)
                                                         }
                                                     })
                                                 }
                                            }
                                     }}
                            />
                        }
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <CommonInput label={label.applicationId} placeHolder={placeholder.applicationId} defaultValue={formData.applicationId} type={'text'} isDisabled={ true }/>
                </Col>
                <Col md={3}>
                    <CommonInput label={label.applicationDate} placeHolder={placeholder.applicationDate} type={'text'} isDisabled={ true }
                        defaultValue={action === 'new' ? moment().format('DD/MM/YYYY') : moment(formData.applicationDate).format('DD/MM/YYYY')}

                    />
                </Col>
            </Row>
            {
                action ==='new'
                    ?
                     <Row>
                        <Col xs={12}>
                            <Button color="info" size="sm" className="floatRight mx-1" onClick={()=>nextHandler()}>
                                {button.next}
                            </Button>
                        </Col>
                    </Row>
                    :
                    null
            }
        </div>
    )
}
export default StudentDetailsForm;
