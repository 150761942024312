import React, { useState } from "react";
import GetAddForm from "./GetAddForm";
import { CardBody, Card } from "reactstrap";
import { useHistory } from "react-router";

const AddRoasterForm = ({
  dropdown,
  setSelectedFaculty,
  selectedFaculty
}) => {

  const history = useHistory();

  return (
    <div className="m-3">
      <Card className="m-1" style={{ maxWidth: "400px" }}>
        <CardBody
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="a-h2 pb-2">Create Faculty Roster</div>

          <div style={{ width: '100%', height: 300, display: 'flex',flexDirection: 'column',justifyContent: 'space-between'}}>
            <GetAddForm
              dropdown={dropdown}
              setSelectedFaculty={setSelectedFaculty}
            />
            {selectedFaculty &&
              <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F5F5F5', borderRadius: 8, gap: '8px', padding: 16, marginTop: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div style={{ fontSize: 14, fontWeight: 400, color: '#0F0F0F' }}>Subject</div>
                  <div style={{ fontSize: 14, fontWeight: 600, color: '#0F0F0F',textOverflow: 'ellipsis', overflow: 'hidden',whiteSpace: 'nowrap', paddingLeft: '69px'}}>{selectedFaculty?.subject?.join(', ') ?? ''}</div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div style={{ fontSize: 14, fontWeight: 400, color: '#0F0F0F', width:'50%' }}>Business area</div>
                  <div style={{ fontSize: 14, fontWeight: 600, color: '#0F0F0F',textOverflow: 'ellipsis', overflow: 'hidden',whiteSpace: 'nowrap', paddingLeft: '69px'}}>{selectedFaculty?.facultyMasterBusinessAreasResponseList?.map(ba => ba.businessAreaValue).join(', ')}</div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div style={{ fontSize: 14, fontWeight: 400, color: '#0F0F0F' }}>Academic career</div>
                  <div style={{ fontSize: 14, fontWeight: 600, color: '#0F0F0F',textOverflow: 'ellipsis', overflow: 'hidden',whiteSpace: 'nowrap', paddingLeft: '69px'}}>{dropdown.academicCareer.filter(sub => selectedFaculty.academicCareer.indexOf(sub.value) > -1).map(filtedSub => filtedSub.label).join(', ')}</div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div style={{ fontSize: 14, fontWeight: 400, color: '#0F0F0F' }}>Academic group</div>
                  <div style={{ fontSize: 14, fontWeight: 600, color: '#0F0F0F',textOverflow: 'ellipsis', overflow: 'hidden',whiteSpace: 'nowrap', paddingLeft: '69px'}}>{dropdown.academicGroup.filter(sub => selectedFaculty.academicGroup.indexOf(sub.value) > -1).map(filtedSub => filtedSub.label).join(', ')}</div>
                </div>
              </div>
            }
            <div>
              <button
                onClick={()=>{ history.push(`/admin/facultyRoaster/faculty/${selectedFaculty.id}`)}}
                className=" mt-4 ml-0  w-100  add-plan-button"
                // disabled={validateForm(currentStep, form)}
                disabled={!selectedFaculty?.id}
              >
                Save & Continue
              </button>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default AddRoasterForm;
