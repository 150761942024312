import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import ADataGrid from '../../../../common/data-grid/ADataGrid'
import NoRowOverlay from '../../../../masters/holidayMaster/NoRowOverlay'
import { gridStyles, gridComponents } from 'views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant.js'
import { AGridColDef } from '../../../../common/data-grid/ADataGridColDef'
import { generateRandomId, isObjEmpty } from '../../../../common/utils/methods/commonMethods/utilityMethod'
import { failureToast } from '../../../../common/utils/methods/toasterFunctions/function'
import { GridRowModes } from '@mui/x-data-grid'
import { newRow, saveOrDeleteMsg } from './constant'
import { Tooltip } from '@material-ui/core'
import ActionsCellComponent from './ActionsCellComponent'
import { cellContainerStyle } from '../../../../masters/holidayMaster/ListComponent'
import StatusCellComponent from './StatusCellComponent'
import AAutoComplete from '../../../../common/form-fields-mui/AAutoComplete'
import { inputStyles, selectStyles } from '../../../../masters/holidayMaster/common'
import AInput from '../../../../common/formFeilds/customInput/AInput'


const ListComponent = forwardRef(({ currentPage, nextButtonHandler, rowCount, dropdowns, loader, rows, createHandler, updateHandler, toggleStatusHandler, deleteHandler }, ref) => {

  const [rowModesModel, setRowModesModel] = useState({});
  const [rowData, setRowData] = useState(rows);
  useEffect(() => {
    setRowData(rows)
  }, [rows])
  const [rowForm, setRowForm] = useState({});
  const rowFormHandler = (value, key) => setRowForm((prev) => ({ ...prev, [key]: value, fieldToFocus: "controlArea" }));

  const startEditing = (id, form) => {
    setRowModesModel((oldModel) => ({ [id]: { mode: GridRowModes.Edit } }));
    setRowForm(form);
  };

  const stopEditing = () => {
    const currForm = { ...rowForm };
    setRowModesModel((oldModel) => ({ [currForm?.id]: { mode: GridRowModes.View, ignoreModifications: true } }));
    setRowForm({});
  };

  const handleEditClick = (params) => {
    if (!isObjEmpty(rowForm)) {
      failureToast(saveOrDeleteMsg);
    } else {
      startEditing(params?.id, params?.row);
    }
  };

  const handleCancelClick = async (params) => {
    if (params?.row?.isNew) {
      setRowData(rows)
      setRowForm({});
    }
    else {
      stopEditing()
    }
  }

  const handleSaveClick = async (params) => {
    if (rowForm?.isNew) {
      await createHandler(rowForm);
    } else {
      await updateHandler(rowForm)
    }
  }

  const addNewRow = async () => {
    if (!isObjEmpty(rowForm)) {
      failureToast(saveOrDeleteMsg)
    } else {
      let randomId = generateRandomId();
      let emptyRow = { ...newRow, isNew: true, id: randomId }
      startEditing(randomId, emptyRow);
      setRowData(prev => [
        emptyRow,
        ...prev
      ])
    }
  }

  useImperativeHandle(ref, () => ({
    addNewRow: addNewRow,
    startEditing: startEditing,
    stopEditing: stopEditing
  }))

  const columns = [
    new AGridColDef('controllingArea', 'SAP controlling area', false).setMinWidth(100),
    new AGridColDef('companyCode', 'SAP company code', false).setMinWidth(100),
    new AGridColDef('profitCenter', 'SAP profit centre', false).editable(true).setMinWidth(100)
      .renderCellComponent(params => <Tooltip title={params?.value?.label}><div className='ellipsis-text-container'>{params?.value?.label}</div></Tooltip>)
      .renderEditCellComponent(params => (
        <div style={cellContainerStyle}>
          <AAutoComplete
            currentValue={rowForm?.profitCenter}
            handleChange={value => {
              rowFormHandler(value, 'profitCenter');
              rowFormHandler(value?.description, 'profitCenterDescription');
              rowFormHandler(value?.academicCareer, 'academicCareer');
              rowFormHandler(value?.academicGroup, 'academicGroup');
            }}
            items={dropdowns?.profitCenter}
            isMulti={false}
            style={{ ...selectStyles, menuPortal: { minWidth: '100px' } }}
          />
        </div>
      )),
    new AGridColDef('profitCenterDescription', 'SAP profit centre desc.', false).editable(true).setMinWidth(120)
      .renderCellComponent(params => <Tooltip title={params?.value}><div className='ellipsis-text-container'>{params?.value}</div></Tooltip>)
      .renderEditCellComponent(params => (
        <div style={cellContainerStyle}>
          <AInput
            placeholder=''
            value={rowForm?.profitCenterDescription}
            handler={(value) => rowFormHandler(value, 'profitCenterDescription')}
            disabled
            style={inputStyles}
          />
        </div>
      )),
    new AGridColDef('academicCareer', 'Academic career', false).editable(true).setMinWidth(100)
      .renderEditCellComponent(params => (
        <div style={cellContainerStyle}>
          <AInput
            placeholder=''
            value={rowForm?.academicCareer}
            handler={(value) => rowFormHandler(value, 'academicCareer')}
            disabled
            style={inputStyles}
          />
        </div>
      )),
    new AGridColDef('academicGroup', 'Academic group', false).editable(true).setMinWidth(100).setValueGetter(params => params?.value?.trim() || '-')
      .renderEditCellComponent(params => (
        <div style={cellContainerStyle}>
          <AInput
            placeholder=''
            value={rowForm?.academicGroup}
            handler={(value) => rowFormHandler(value, 'academicGroup')}
            disabled
            style={inputStyles}
          />
        </div>
      )),
    new AGridColDef('businessArea', 'SAP bus. area', false).editable(true).setMinWidth(100).setValueGetter(params => params?.value?.label)
      .renderEditCellComponent(params => (
        <div style={cellContainerStyle}>
          <AAutoComplete
            currentValue={rowForm?.businessArea}
            handleChange={value => {
              rowFormHandler(value, 'businessArea');
            }}
            items={dropdowns?.sapBusinessArea}
            isMulti={false}
            style={selectStyles}
          />
        </div>
      )),
    new AGridColDef('costCenter', 'SAP cost centre', false).editable(true).setMinWidth(110)
      .renderCellComponent(params => <Tooltip title={params?.value}><div className='ellipsis-text-container'>{params?.value}</div></Tooltip>)
      .renderEditCellComponent(params => (
        <div style={cellContainerStyle}>
          <AInput
            placeholder='Enter'
            value={rowForm?.costCenter}
            handler={(value) => rowFormHandler(value, 'costCenter')}
            style={inputStyles}
          />
        </div>
      )),
    new AGridColDef('costCenterDescription', 'SAP cost centre desc.', false).editable(true).setMinWidth(120)
      .renderCellComponent(params => <Tooltip title={params?.value}><div className='ellipsis-text-container'>{params?.value}</div></Tooltip>)
      .renderEditCellComponent(params => (
        <div style={cellContainerStyle}>
          <AInput
            placeholder='Enter'
            value={rowForm?.costCenterDescription}
            handler={(value) => rowFormHandler(value, 'costCenterDescription')}
            style={inputStyles}
          />
        </div>
      )),
    new AGridColDef('serviceMaterial', 'SAP service material', false).editable(true).setMinWidth(100)
      .renderCellComponent(params => <Tooltip title={params?.value}><div className='ellipsis-text-container'>{params?.value?.trim() || '-'}</div></Tooltip>)
      .renderEditCellComponent(params => (
        <div style={cellContainerStyle}>
          <AInput
            placeholder='Enter'
            value={rowForm?.serviceMaterial}
            handler={(value) => rowFormHandler(value, 'serviceMaterial')}
            style={inputStyles}
          />
        </div>
      )),
    new AGridColDef('serviceMaterialDescription', 'SAP service material desc.', false).editable(true).setMinWidth(120)
      .renderCellComponent(params => <Tooltip title={params?.value}><div className='ellipsis-text-container'>{params?.value?.trim() || '-'}</div></Tooltip>)
      .renderEditCellComponent(params => (
        <div style={cellContainerStyle}>
          <AInput
            placeholder='Enter'
            value={rowForm?.serviceMaterialDescription}
            handler={(value) => rowFormHandler(value, 'serviceMaterialDescription')}
            style={inputStyles}
          />
        </div>
      )),
    new AGridColDef('currencyCode', 'Currency code', false).setMinWidth(90),
    new AGridColDef('costCenterStatus', 'Cost centre status', false).setMinWidth(120)
      .renderCellComponent(params => <StatusCellComponent params={params} toggleStatusHandler={toggleStatusHandler} rowForm={rowForm} rowFormHandler={rowFormHandler} />),
    new AGridColDef('Actions', 'Actions', false).columnAlign('right').setMinWidth(130).setFlex(0.7)
      .renderCellComponent(params => (
        <ActionsCellComponent
          params={params}
          rowForm={rowForm}
          handleCancelClick={handleCancelClick}
          handleEditClick={handleEditClick}
          handleSaveClick={handleSaveClick}
          deleteHandler={deleteHandler}
        />
      )),
  ]

  return (
    <div className='mt-3'>
      <ADataGrid
        removeWrapperContainer
        rowModesModel={rowModesModel}
        rows={rowData || []}
        columns={columns}
        rowId={(row) => row.id}
        rowHeight={50}
        loading={false}
        rowCount={rowCount}
        nextButtonHandler={nextButtonHandler}
        currentPage={currentPage}
        components={{ ...gridComponents, NoRowsOverlay: () => <NoRowOverlay message={loader ? 'Loading...' : null} /> }}
        headerHeight={120}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "break-spaces",
          },
          ...gridStyles
        }}
        autoHeight
      />
    </div>
  )
})

export default ListComponent