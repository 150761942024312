import React, { useState } from "react";
import { Dialog } from "@mui/material";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import ApplicationsTable from "./ApplicationsTable";
import ALoader from "../../../common/a-loader";
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'

const StudentApplicationsPrompt = ({
  list,
  isOpen,
  setIsOpen,
  onSelectClick,
  isMisc,
  loader,
}) => {
  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <Dialog onClose={handleCancel} open={isOpen} maxWidth="lx">
      <div style={{ padding: "28px 24px", width: "960px" }}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div
            style={{
              fontWeight: 700,
              fontSize: "22px",
              lineHeight: "24px",
              color: "#000000",
              marginBottom: "1rem",
            }}
          >
            Select one application ID
          </div>
          <IconButtonWrapper onClick={handleCancel}><CrossIcon width={32} height={32} /></IconButtonWrapper>
        </div>
        {loader && <ALoader />}
        <ApplicationsTable isMisc={isMisc} onSelectClick={onSelectClick} rowData={list} />
      </div>
    </Dialog>
  );
};

export default StudentApplicationsPrompt;
