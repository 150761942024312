import React from 'react';
import {
  Card,
  CardHeader,
  Input,
  Container,
  Button,
  Table,
  Row,
  Col,
} from 'reactstrap';
import { useState } from 'react';
import Select2 from 'react-select2-wrapper';
import { failureToast } from '../../common/utils/methods/toasterFunctions/function';
import {
  PROFITCENTERMAPPINGTHROW,
  ProfitHeader,
} from '../../common/commonComponents/masterRow/masterRow';
import {
  getAPI,
  masterServiceBaseUrl,
  successToast,
  putpost,
  putpost2,
  deleteAPI,
} from 'services/http';
import moment from 'moment';
import CreateButton from "components/FormComponent/Button/New/index";
import UpdateButton from "components/FormComponent/Button/Update/index";
import { ButtonText } from "variables/Buttons";

var _ = require('lodash');

const ProfitCenterMappingTable = (props) => {
  const {
    profitCenterMapping,
    setProfitCenterMapping,
    fetchProfitCenterData,
    activeGroupCode,
    sapBranchType,
    sapCareerType,
    sapAcademicCareer,
    sapSegment,
    profitDataList,
    pagination,
    setPagination,
    getDisplayValue,
  } = props;
  const [isSaveVisible, setIsSaveVisible] = useState(false);
  const [tableDataKey, settableDataKey] = useState('tableDataKey');
  const [companyCode, setCompanyCode] = useState([]);
  const [sapAcademicCareerDisp, setSapAcademicCareerDisp] = useState('');
  const [academicDisp, setAcademicDisp] = useState('');
  const [careerDisp, setCareerDisp] = useState('');
  const [profitMappingSequence, setProfitMappingSequence] = useState('');

  const addNewClass = () => {
    try {
      setProfitCenterMapping([
        {
          editMode: true,
          __status: '__new',
          groupCodeID: null,
          companyCodeId: null,
          sapBranchType: null,
          sapCareerType: null,
          sapAcademicCareer: null,
          sapSegment: null,
          businessUnitTypeId: null,
          sequenceNumber: null,
          sapProfitCenterId: null,
          academicCareerId: null,
          academicGroupId: null,
          description: null,
          shortDescription: null,
          status: 'ACTIVE',
        },
        ...profitCenterMapping,
      ]);
    } catch (e) {
      console.log('error', e);
    }
  };
  const saveAll = (index) => {
    try {
      const _tempArray = profitCenterMapping;
      let isValid = true;
      _tempArray.forEach((el, index) => {
        if (el['editMode']) {
          if (!el['groupCodeID']) {
            isValid = false;
            failureToast('Please Select the Group Code.');
            return;
          } else if (!el['companyCodeId']) {
            isValid = false;
            failureToast('Please Select the Company Code.');
            return;
          } else if (!el['sapBranchType']) {
            isValid = false;
            failureToast('Please Select Sap Branch Type');
            return;
          } else if (!el['sapCareerType']) {
            isValid = false;
            failureToast('Please Select Sap Career Type');
            return;
          } else if (!el['sequenceNumber']) {
            isValid = false;
            failureToast('Please Enter Sequence Number');
            return;
          } else if (!el['sapProfitCenterId']) {
            isValid = false;
            failureToast('Please Enter Sap Profit Center ID');
            return;
          } else if (!el['academicCareerId']) {
            isValid = false;
            failureToast('Please Enter Academic Career');
            return;
          } else if (!el['academicGroupId']) {
            isValid = false;
            failureToast('Please Enter Academic Group');
            return;
          } else if (!el['description']) {
            isValid = false;
            failureToast('Please Enter Description');
            return;
          } else if (!el['shortDescription']) {
            isValid = false;
            failureToast('Please Enter Short Description');
            return;
          }
          if (!isValid) {
            return;
          }
          if (_tempArray[index]['__status'] == '__new') {
            putpost(
              masterServiceBaseUrl +
                '/profitCenterMappingPage/createProfitCenterMappingPage',
              (data) => {
                successToast(data['message']);
                setIsSaveVisible(false);
                fetchProfitCenterData(
                  masterServiceBaseUrl +
                    '/profitCenterMappingPage/getAllProfitCenterMappingPage'
                );
                setAcademicDisp('');
                setCareerDisp('');
                setSapAcademicCareerDisp('');
                setProfitMappingSequence('');
              },
              (data) => {
                failureToast(data['message']);
              },
              _tempArray[index],
              'post'
            );
          } else {
            putpost(
              masterServiceBaseUrl +
                '/profitCenterMappingPage/updateProfitCenterMappingPage/' +
                _tempArray[index].id,
              (data) => {
                successToast(data['message']);
                setIsSaveVisible(false);
                fetchProfitCenterData(
                  masterServiceBaseUrl +
                    '/profitCenterMappingPage/getAllProfitCenterMappingPage'
                );
                setAcademicDisp('');
                setCareerDisp('');
                setSapAcademicCareerDisp('');
                setProfitMappingSequence('');
              },
              (data) => {
                failureToast(data['message']);
              },
              _tempArray[index],
              'put'
            );
          }
        }
      });
    } catch (e) {
      console.log('error', e);
    }
  };

  const newRow = () => {
    try {
      if (isSaveVisible) {
        failureToast('Please Delete or Save current changes');
      } else {
        let cb = () => {
          addNewClass();
          setIsSaveVisible(true);
        };
        let _tempArray = profitCenterMapping;
        _tempArray = _.sortBy(_tempArray, [
          function (o) {
            return o.value;
          },
        ]);
        setProfitCenterMapping(_tempArray);
        setIsSaveVisible(false);
        cb();
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const editRow = (index) => {
    try {
      if (isSaveVisible) {
        failureToast('Please Delete or Save current changes');
      } else {
        let cb = () => {
          var _tempArray = profitCenterMapping;
          _tempArray[index]['editMode'] = true;
          _tempArray[index]['sapBranchType'] =
            _tempArray[index]['businessUnitTypeId'];
          _tempArray[index]['sapCareerType'] =
            _tempArray[index]['academicCareerId'];
          _tempArray[index]['sapAcademicCareer'] =
            _tempArray[index]['academicCareerId'];
          _tempArray[index]['sapSegment'] =
            _tempArray[index]['academicGroupId'];
          getCompanyCode(_tempArray[index]['groupCodeID']);
          const indexAcademic = findDisplayValue(
            sapCareerType,
            _tempArray[index]['academicCareerId']
          );
          setSapAcademicCareerDisp(sapCareerType[indexAcademic]['displayVal']);
          setCareerDisp(
            getDisplayValue(
              _tempArray[index]['academicCareerId'],
              'AcademicCareerID'
            )
          );
          setAcademicDisp(
            getDisplayValue(
              _tempArray[index]['academicGroupId'],
              'AcademicGroupID'
            )
          );
          setProfitMappingSequence(_tempArray[index]['academicGroupId']);
          setProfitCenterMapping(_tempArray);
          setIsSaveVisible(true);
        };
        let _tempArray = profitCenterMapping;
        _tempArray = _.sortBy(_tempArray, [
          function (o) {
            return o.value;
          },
        ]);
        cb();
      }
    } catch (e) {
      console.log('error', e);
    }
  };
  const deleteRow = (index) => {
    try {
      if (profitCenterMapping[index]['__status'] == '__new') {
        let n = profitCenterMapping;
        n.splice(index, 1);
        setProfitCenterMapping(n);
        setIsSaveVisible(false);
        settableDataKey(new Date().getMilliseconds());
      } else {
        if (
          window.confirm(
            'Are you sure you want to delete this Profit Center Mapping ?'
          )
        ) {
          putpost2(
            masterServiceBaseUrl +
              '/profitCenterMappingPage/deleteProfitCenterMappingPage/' +
              profitCenterMapping[index].id,
            (data) => {
              successToast(data['message']);
              var _tempArray = profitCenterMapping;
              if (_tempArray[index]['editMode']) {
                setIsSaveVisible(false);
              }
              _tempArray.splice(index, 1);
              setProfitCenterMapping(_tempArray);
              settableDataKey(new Date().getMilliseconds());
            },
            (data) => {
              failureToast(data['message']);
            },
            'Delete'
          );
        }
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const updateKey = (index, value, key) => {
    try {
      let _tempArray = profitCenterMapping;
      _tempArray[index][key] = value;
      if (
        _tempArray[index]['sapBranchType'] &&
        _tempArray[index]['sapCareerType'] &&
        _tempArray[index]['sapSegment'] &&
        _tempArray[index]['sapAcademicCareer']
      ) {
        const branchIndex = findDisplayValue(
          sapBranchType,
          _tempArray[index]['sapBranchType']
        );
        const branchSequence = sapBranchType[branchIndex]['sequenceNumber'];
        const sapCareerTypeIndex = findDisplayValue(
          sapCareerType,
          _tempArray[index]['sapCareerType']
        );
        const careerTypeSequence =
          sapCareerType[sapCareerTypeIndex]['sequenceNumber'];
        const sapSegmentIndex = findDisplayValue(
          sapSegment,
          _tempArray[index]['sapSegment']
        );
        const segmentSequence = sapSegment[sapSegmentIndex]['sequenceNumber'];
        const sapAcademicCareerIndex = findDisplayValue(
          sapAcademicCareer,
          _tempArray[index]['sapAcademicCareer']
        );
        const academicCareerSequence =
          sapAcademicCareer[sapAcademicCareerIndex]['sequenceNumber'];
        let seq =
          branchSequence +
          careerTypeSequence +
          academicCareerSequence +
          segmentSequence;
        _tempArray[index]['sapProfitCenterId'] = seq;
        setProfitMappingSequence(seq);
      }
      if (key === 'sapBranchType') {
        _tempArray[index]['businessUnitTypeId'] = value;
      }
      if (key === 'sapCareerType') {
        const indexSap = findDisplayValue(sapCareerType, value);
        _tempArray[index]['sapAcademicCareer'] = sapCareerType[indexSap]['id'];
        _tempArray[index]['academicCareerId'] = sapCareerType[indexSap]['id'];
        setSapAcademicCareerDisp(sapAcademicCareer[indexSap]['text']);
        setCareerDisp(sapCareerType[indexSap]['displayVal']);
      }
      if (key === 'sapSegment') {
        const indexSegment = findDisplayValue(sapSegment, value);
        if (indexSegment >= 0) {
          _tempArray[index]['academicGroupId'] = sapSegment[indexSegment]['id'];
          setAcademicDisp(sapSegment[indexSegment]['displayVal']);
        }
      }
      setProfitCenterMapping(_tempArray);
    } catch (e) {
      console.log('error', e);
    }
  };

  const findDisplayValue = (datalist, id) => {
    try {
      const findIndex = datalist.findIndex((item) => item['id'] == id);
      return findIndex;
    } catch (e) {
      console.log('Error', e);
    }
  };

  const getCompanyCode = (value) => {
    try {
      getAPI(
        masterServiceBaseUrl + `/companyCode/getCompanyCodeByGroup/${value}`,
        (data) => {
          let tempCompanyCode = [];
          data &&
            data.data.map((item) => {
              tempCompanyCode.push({
                id: item.id,
                text: item.companyCodeDispValue,
              });
            });
          setCompanyCode(tempCompanyCode);
        },
        (data) => {
          setCompanyCode([]);
          failureToast(data['message']);
        }
      );
    } catch (e) {
      console.log('Error', e);
    }
  };

  const searchData = _.debounce((value) => {
    try {
      if (value.length > 0) {
        fetchProfitCenterData(
          masterServiceBaseUrl +
            `/profitCenterMappingPage/searchProfitCenterMappingPage/${value}`
        );
      } else {
        fetchProfitCenterData(
          masterServiceBaseUrl + '/profitCenterMappingPage/getAllProfitCenterMappingPage'
        );
      }
    } catch (e) {
      console.log('error', e);
    }
  }, 300);

  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Profit Center Mapping Page</h3>
                <div
                  className="floatRight "
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  Search :{' '}
                  <Input
                    className="tdInput ml-3 mr-3 customInputWidth"
                    placeholder="Search"
                    onChange={(e) => {
                      let n = e.target.value.trim();
                      searchData(n);
                    }}
                  />
                  <CreateButton
                    text={ButtonText.ProfitCenterMapping.addNew}
                    data-testid="addNewRow"
                    disabled={isSaveVisible}
                    className={
                      'floatRight mx-1' + (isSaveVisible ? ' btn-dark' : null)
                    }
                    onClick={() => {
                      newRow();
                    }}
                  />
                </div>
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush ">
                  <ProfitHeader />
                  <tbody className="list" key={tableDataKey}>
                    {profitCenterMapping
                      ? profitCenterMapping.map((el, index) =>
                          !el['editMode'] ? (
                            <PROFITCENTERMAPPINGTHROW
                              index={index}
                              item={el}
                              editRow={editRow}
                              deleteRow={deleteRow}
                              getDisplayValue={getDisplayValue}
                            />
                          ) : (
                            <tr key={index + '-class'}>
                              <td className="text-center p-2 mx-1">
                                <Select2
                                  data-testid="groupCodeId"
                                  data={activeGroupCode}
                                  value={el['groupCodeID']}
                                  onChange={(e) => {
                                    let n = e.target.value;
                                    updateKey(index, n, 'groupCodeID');
                                    n && getCompanyCode(n);
                                  }}
                                  options={{ placeholder: 'Select Group Code' }}
                                  className="tdSelect"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Select2
                                  data-testid="companyCodeId"
                                  data={companyCode}
                                  value={el['companyCodeId']}
                                  onChange={(e) => {
                                    let n = e.target.value;
                                    updateKey(index, n, 'companyCodeId');
                                  }}
                                  options={{
                                    placeholder: 'Select Company Code',
                                  }}
                                  className="tdSelect"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Select2
                                  data-testid="branchType"
                                  data={sapBranchType}
                                  value={el['sapBranchType']}
                                  onChange={(e) => {
                                    let n = e.target.value;
                                    updateKey(index, n, 'sapBranchType');
                                  }}
                                  options={{
                                    placeholder: 'Select Branch Type',
                                  }}
                                  className="tdSelect"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Select2
                                  data-testid="careerType"
                                  data={sapCareerType}
                                  value={el['sapCareerType']}
                                  onChange={(e) => {
                                    let n = e.target.value;
                                    updateKey(index, n, 'sapCareerType');
                                  }}
                                  options={{
                                    placeholder: 'Select Career Type',
                                  }}
                                  className="tdSelect"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="academicType"
                                  disabled={true}
                                  value={sapAcademicCareerDisp}
                                  className="tdSelect"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Select2
                                  data-testid="segmentType"
                                  data={sapSegment}
                                  value={el['sapSegment']}
                                  onChange={(e) => {
                                    let n = e.target.value;
                                    updateKey(index, n, 'sapSegment');
                                  }}
                                  options={{ placeholder: 'Select Segment' }}
                                  className="tdSelect"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="sequenceNumber"
                                  maxLength={5}
                                  defaultValue={el['sequenceNumber']}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      'sequenceNumber'
                                    );
                                  }}
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="sapProfitCenterId"
                                  value={profitMappingSequence}
                                  disabled={true}
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="AcademicCareerId"
                                  value={careerDisp}
                                  disabled={true}
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="AcademicGroupId"
                                  value={academicDisp}
                                  disabled={true}
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="effectiveDate"
                                  value={moment().format('DD-MM-YYYY')}
                                  disabled={true}
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="description"
                                  maxLength={50}
                                  defaultValue={el['description']}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      'description'
                                    );
                                  }}
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="shortDescription"
                                  maxLength={50}
                                  defaultValue={el['shortDescription']}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      'shortDescription'
                                    );
                                  }}
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <label className="custom-toggle mx-auto ml-2">
                                  <input
                                    data-testid="activeInactiveInput"
                                    checked={
                                      el.status == 'ACTIVE' ? true : false
                                    }
                                    type="checkbox"
                                    id={el.id}
                                    onChange={(e) => {
                                      settableDataKey(
                                        new Date().getMilliseconds()
                                      );
                                      let n = 'ACTIVE';
                                      if (!e.target.checked) {
                                        n = 'INACTIVE';
                                      }
                                      updateKey(index, n, 'status');
                                    }}
                                  />
                                  <span
                                    data-testid="activeInactiveLabel"
                                    style={{ width: '72px' }}
                                    className="custom-toggle-slider rounded-circle activeToggle"
                                    data-label-off="Inactive"
                                    data-label-on="Active"
                                  />
                                </label>
                              </td>
                              <td className="text-center">
                                {isSaveVisible ? (
                                  <>
                                    {' '}
                                    <UpdateButton text={ButtonText.ProfitCenterMapping.save} onClick={() => {saveAll(index);}}/>
                                    <Button
                                      data-testid="closeBtn"
                                      color="danger"
                                      size="sm"
                                      type="button"
                                      onClick={() => {
                                        let n = profitCenterMapping;
                                        if (n[index]['__status'] == '__new') {
                                          n.splice(index, 1);
                                        } else {
                                          fetchProfitCenterData(
                                            masterServiceBaseUrl +
                                              '/profitCenterMappingPage/getAllActiveProfitCenterMappingPage'
                                          );
                                        }
                                        setProfitCenterMapping(n);
                                        setAcademicDisp('');
                                        setCareerDisp('');
                                        setSapAcademicCareerDisp('');
                                        setProfitMappingSequence('');
                                        setIsSaveVisible(false);
                                      }}
                                    >
                                      {' '}
                                      <i className="fas fa-times" />
                                    </Button>{' '}
                                  </>
                                ) : null}
                              </td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default ProfitCenterMappingTable;
