import React, { useEffect, useState } from 'react';
import {
	Container,
	Card,
	CardBody,
	CardHeader,
	Button,
	Row,
	Col,
} from 'reactstrap';
import {
	masterServiceBaseUrl,
	concessionServiceBaseUrl,
	getAPI,
	failureToast,
} from 'services/http';
import CustomLoader from '../../../../common/commonComponents/Loader/loader';
import {
	fetchAllData,
	fetchDetails,
	getDispValForReactSelectByOneID,
} from '../../../../common/utils/methods/commonMethods/utilityMethod';
import { BsFillForwardFill } from 'react-icons/bs';
import { updateData } from '../../../../../../../redux';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CommonHeader from '../commonHeader';
import SubSubCategory from './subSubCategoryMapping';
import './subSubCategoryMapping.scss';

const SubSubCategoryMapping = (props) => {
	const { id } = props.match.params;
	const dispatch = useDispatch();
	const { action, isNew = true, formId, isViewOnly = false } = props;
	const [apiLoader, setApiLoader] = useState(false);
	const [subCategoryData, setSubCategoryData] = useState([]);
	const [subSubCategoryData, setSubSubCategoryData] = useState([]);
	const [subCategoryList, setSubCategoryList] = useState([]);
	const [subSubCategory, setSubSubCategory] = useState([]);

	useEffect(() => {
		getSubCategoryData();
	}, [id]);

	useEffect(() => {
		fetchDetails(
			masterServiceBaseUrl + '/subSubCategory/getAllActiveSubSubCategory',
			setSubSubCategoryData,
			'subSubCategory',
			'category'
		);
		fetchDetails(
			`${masterServiceBaseUrl}/subCategory/getAllActiveSubCategory`,
			setSubCategoryList,
			'subCategory',
			'category'
		);
	}, []);

	const getSubCategoryData = async () => {
		try {
			setApiLoader(true);
			getAPI(
				`${concessionServiceBaseUrl}/addSubCategory/${id}`,
				(data) => {
					setSubCategoryData(data.data);
					setApiLoader(false);
				},
				(data) => {
					failureToast(data['message']);
					setApiLoader(false);
				}
			);
		} catch (e) {
			console.log('Error', e);
		}
	};

	const headerData = [
		{
			label: 'Business Group',
			value: props.headerData && props.headerData.businessGroup,
		},
		{
			label: 'Company Code',
			value: props.headerData && props.headerData.companyCode,
		},
		{
			label: 'Academic Career',
			value: props.headerData && props.headerData.academicCareer,
		},
		{
			label: 'Academic Group',
			value: props.headerData && props.headerData.academicGroup,
		},
		{
			label: 'Category Code - Description',
			value: `${props.headerData && props.headerData.categoryCode} - ${
				props.headerData && props.headerData.description
			}`,
		},
		{
			label: 'Sub Category Code - Description',
			value: `${
				subCategoryData &&
				subCategoryData.subCategoryMasterId &&
				getDispValForReactSelectByOneID(
					subCategoryData.subCategoryMasterId,
					'DispValue',
					subCategoryList
				)
			} - ${
				subCategoryData &&
				subCategoryData.subCategoryMasterId &&
				getDispValForReactSelectByOneID(
					subCategoryData.subCategoryMasterId,
					'DescValue',
					subCategoryList
				)
			}`,
		},
	];

	useEffect(() => {
		if (
			subCategoryData &&
			subCategoryData.subCategoryMasterId &&
			subCategoryList
		) {
			dispatch(
				updateData({
					...props.headerData,
					subCategoryCode: getDispValForReactSelectByOneID(
						subCategoryData.subCategoryMasterId,
						'DispValue',
						subCategoryList
					),
					subCategoryDesc: getDispValForReactSelectByOneID(
						subCategoryData.subCategoryMasterId,
						'DescValue',
						subCategoryList
					),
				})
			);
		}
	}, [subCategoryData, subCategoryData.subCategoryMasterId, subCategoryList]);


	return (
		<>
			<Container className="mt-4" fluid>
				<Card>
					<CardHeader>
						<h2 className="mb-0 floatLeft">
							Scholarship Master &nbsp;
							{<BsFillForwardFill style={{ fontSize: '30px' }} />}&nbsp;
							<span
								onClick={() => props.history.goBack()}
								style={{ cursor: 'pointer', color: '#11CDEF' }}
							>
								Add Sub Category
							</span>
							&nbsp;
							{<BsFillForwardFill style={{ fontSize: '30px' }} />}&nbsp; Add Sub
							Sub Category
						</h2>
					</CardHeader>
				</Card>
				{apiLoader ? (
					<div
						className="mx-auto text-center py-5 my-5"
						style={{ height: '100vh' }}
					>
						<CustomLoader
							apiLoader={apiLoader}
							loaderHeight={'200px'}
							loaderWidth={'100%'}
						/>
					</div>
				) : (
					<div>
						<Card>
							<CardBody>
								<CommonHeader
									headerData={headerData}
									lastUpdatedDate={
										subCategoryData && subCategoryData['updatedOn']
									}
									countLabel={'Total Active Sub Sub Category Count '}
									countValue={
										(subCategoryData &&
											subCategoryData['subSubCategoryCount']) ||
										'00'
									}
								/>
							</CardBody>
						</Card>
						<Card>
							<CardBody>
								<SubSubCategory
									subCategoryID={id}
									subSubCategoryData={subSubCategoryData}
									subSubCategory={subSubCategory}
									setSubSubCategory={setSubSubCategory}
									getSubCategoryData={getSubCategoryData}
								/>
							</CardBody>
						</Card>
					</div>
				)}
			</Container>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		headerData: state.globalData.data,
	};
};

export default connect(mapStateToProps)(SubSubCategoryMapping);
