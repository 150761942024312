import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { ReactComponent as CloseIcon } from "../../../../../../assets/img/svg/CloseIcon.svg";
import AButton from "../../../common/form-fields-mui/AButton";

const AddEventModal = ({
  isOpen,
  onClose,
  handleOpenLectureModal,
  handleOpenPTMModal = () => {},
  handleOpenCustomHolidayModal = () => {},
}) => {
  const buttonStyles = {};

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={onClose}
      style={{ width: "510px" }}
    >
      <ModalBody>
        <div className="d-flex justify-content-between align-items-start mb-4">
          <div className="heading-3 label-color">Add event</div>
          <CloseIcon onClick={() => onClose()} />
        </div>
        <div className="semi-bold color-dark mb-3">
          Select an event you want to add
        </div>
        <div className="d-flex flex-wrap">
          <AButton
            variant="outline"
            className="btn-left-0 mr-3 mb-3"
            onClick={() => handleOpenLectureModal("Lecture")}
          >
            Lecture
          </AButton>
          <AButton
            variant="outline"
            className="btn-left-0 mr-3 mb-3"
            onClick={() => handleOpenPTMModal("PTM")}
          >
            Parent Connect - PTM
          </AButton>
          <AButton
            variant="outline"
            className="btn-left-0 mr-3 mb-3"
            onClick={() => handleOpenPTMModal("Orientation")}
          >
            Parent Connect - Orientation
          </AButton>
          <AButton
            variant="outline"
            className="btn-left-0 mr-3 mb-3"
            onClick={() => handleOpenLectureModal("Doubt class")}
          >
            Doubt class
          </AButton>
          <AButton
            variant="outline"
            className="btn-left-0 mr-3 mb-3"
            onClick={() => handleOpenLectureModal("Extra class")}
          >
            Extra class
          </AButton>
          <AButton
            variant="outline"
            className="btn-left-0 mr-3 mb-3"
            onClick={handleOpenCustomHolidayModal}
          >
            Custom holiday
          </AButton>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddEventModal;
