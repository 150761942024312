import React, { useState, useRef, useEffect, useMemo } from 'react'
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { IoClose } from 'react-icons/io5';

import CommonInput from '../../../common/formFeilds/input/commonInput';
import CustomButton from '../../../../../../components/CustomButton';
import MuiTable from '../../../common/commonComponents/MuiTable';
import { failureToast, successToast } from '../../../../../pages/manage/common/utils/methods/toasterFunctions/function';
import { endpoint } from '../../../common/constant';
import { CiFilter } from 'react-icons/ci';
import { Badge, IconButton, Modal, Tooltip } from '@mui/material';
import { fetchAllPostPromisedData, fetchAllPromisedData } from '../../../common/utils/methods/commonMethods/utilityMethod';
import ConsessionBulkUpload from './consessionBulkUpload';
import { GetTemplate } from '../../../common/commonComponents';
import FieldHandler from './fieldhandler';
import moment from 'moment';


const UploadIcon = () => <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7369 0.761748H5.08489C3.00489 0.753748 1.30089 2.41075 1.25089 4.49075V15.2277C1.20589 17.3297 2.87389 19.0697 4.97489 19.1147C5.01189 19.1147 5.04889 19.1157 5.08489 19.1147H13.0729C15.1629 19.0407 16.8149 17.3187 16.8029 15.2277V6.03775L11.7369 0.761748Z" stroke="#00B0F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11.4746 0.75V3.659C11.4746 5.079 12.6236 6.23 14.0436 6.234H16.7976" stroke="#00B0F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8.64062 7.90869V13.9497" stroke="#00B0F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.9869 10.2642L8.64188 7.90918L6.29688 10.2642" stroke="#00B0F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>


const getFormatedDate = (date) => {
    return date ? moment(date).format('DD-MM-YYYY') : ''
}

const feeColumns = [
    { field: 'regFee', beName: "REG_FEE", headerName: 'Reg. Fee', type: 'number', allowDecimalvalue: true, minWidth: 140, mandatory: true, editable: true, disabled: false, flex: 1 },
    { field: 'admissionFee', beName: "ADMISSION_FEE", headerName: 'Admission Fee', type: 'number', allowDecimalvalue: true, mandatory: true, minWidth: 140, editable: true, disabled: false, flex: 1 },
    { field: 'tuitionFee', beName: "TUITION_FEE", headerName: 'Tuition Fee', minWidth: 140, type: 'number', allowDecimalvalue: true, mandatory: true, editable: true, disabled: false, flex: 1 },
    { field: 'techFee', beName: "TECH_FEE", headerName: 'Tech & Exam Fee', minWidth: 140, type: 'number', allowDecimalvalue: true, mandatory: true, editable: true, disabled: false, flex: 1 },
    { field: 'classFee', beName: "CLASS_FEE", headerName: 'Classroom Service Fee', minWidth: 140, type: 'number', allowDecimalvalue: true, mandatory: true, editable: true, disabled: false, flex: 1 },
];

const rangeColumns = [
    { field: 'rangeFrom', headerName: 'Range From', type: 'number', allowDecimalvalue: true, minWidth: 160, mandatory: true, editable: true, disabled: false, flex: 1 },
    { field: 'rangeTo', headerName: 'Range To', type: 'number', allowDecimalvalue: true, minWidth: 140, mandatory: true, editable: true, disabled: false, flex: 1 },
]

const hidableColumns = [
    {
        field: 'academicCareerId', headerName: 'Acad Career', type: 'multiSelect',
        valueOptions: [],
        width: 160, editable: true, disabled: false,
        affects: ['businessAreaId'],
    },
    {
        field: 'academicGroupId', headerName: 'Acad Group', type: 'multiSelect',
        valueOptions: [],
        width: 150, editable: true, disabled: false,
    },
    {
        field: 'termId', headerName: 'Acad Term', width: 150, type: 'multiSelect',
        valueOptions: [],
        editable: true, disabled: false,
    },
    {
        field: 'courseCategoryId', headerName: 'Course Category', type: 'multiSelect',
        valueOptions: [],
        width: 180, editable: true, disabled: false,
        affects: ['courseId']
    },
    {
        field: 'businessAreaId', headerName: 'Business Area', type: 'businessArea',
        valueOptions: [],
        width: 190, editable: true, disabled: false,
    },
    {
        field: 'courseId', headerName: 'Course ID', width: 160, type: 'multiSelect',
        valueOptions: [],
        editable: true, disabled: false,
    },
    {
        field: 'remarks', headerName: 'Remarks', width: 130, type: 'singleSelect',
        valueOptions: [
            { label: 'Passed', value: 'Passed' },
            { label: 'Qualified', value: 'Qualified' },
            { label: 'Failed', value: 'Failed' },
            { label: 'Not Qualified', value: 'Not Qualified' }
        ],
        editable: true, disabled: false,
    },
    {
        field: 'description', headerName: 'Description', width: 150, type: 'text', editable: true, disabled: false, restrictSpecialCharacter : false
    },
]

const columns = [
    { field: 'studentLimit', headerName: 'Student Limit', minWidth: 160, type: 'number', editable: true, disabled: false, flex: 1 , initialValue : null , maxLength : 5},
    {
        field: 'effectiveFrom', headerName: 'Start Date', type: 'date', mandatory: true,
        minWidth: 160, editable: true, disabled: false, formatter: getFormatedDate, flex: 1
    },
    {
        field: 'effectiveTo', headerName: 'End Date', type: 'date', mandatory: true,
        minWidth: 160, editable: true, disabled: false, formatter: getFormatedDate, flex: 1
    },
    {
        field : 'pserpId', headerName: 'PSERP ID', type : 'text', mandatory : true, 
        minWidth : 160, editable : true , disabled : false, restrictSpecialCharacter : false,  flex : 1
    },
    { field: 'status', headerName: 'Status', minWidth: 160, type: 'toggle', mandatory: false, editable: true, disabled: false,flex:1,
        dataLabelOn: "ACTIVE", dataLabelOff: "INACTIVE" , initialValue : "ACTIVE"},
];

const isValid = (val) => {
    if (val === null || val === undefined || val === '') { return false; }
    return true;
}

const  skipValidation = currRow => {
    return currRow?.fromBE && currRow?.status === 'INACTIVE'
}


const validationRules = [
    {
        field: 'rangeFrom', message: 'Range From is required!',
        validateFn: (row) => {
            return row.hasOwnProperty('rangeFrom') ? isValid(row.rangeFrom) : true;
        }
    },
    {
        field: 'rangeTo', message: 'Range To is required!',
        validateFn: (row) => {
            return row.hasOwnProperty('rangeTo') ? isValid(row.rangeTo) : true;
        }
    },
    {
        field: 'rangeTo', message: 'Range To should be greater than/equal to Range From!',
        validateFn: (row) => {
            return row.hasOwnProperty('rangeTo') ? +row.rangeFrom <= +row.rangeTo : true;
        }
    },
    {
        field: 'regFee', message: 'Reg Fee Concession is required!',
        validateFn: (row) => {
            return row.hasOwnProperty('regFee') ? isValid(row.regFee) : true;
        }
    },
    {
        field: 'admissionFee', message: 'Admission Fee Concession is required!',
        validateFn: (row) => {
            return row.hasOwnProperty('admissionFee') ? isValid(row.admissionFee) : true;
        }
    },
    {
        field: 'tuitionFee', message: 'Tution Fee Concession is required!',
        validateFn: (row) => {
            return row.hasOwnProperty('tuitionFee') ? isValid(row.tuitionFee) : true;
        }
    },
    {
        field: 'classFee', message: 'Class Fee Concession is required!',
        validateFn: (row) => {
            return row.hasOwnProperty('classFee') ? isValid(row.classFee) : true;
        }
    },
    {
        field: 'techFee', message: 'Tech Fee Concession is required!',
        validateFn: (row) => {
            return row.hasOwnProperty('techFee') ? isValid(row.techFee) : true;
        }
    },
    {
        field: 'effectiveFrom', message: 'Start Date is required!',
        validateFn: (row) => {
            return skipValidation(row) || isValid(row.effectiveFrom);
        }
    },
    {
        field: 'effectiveFrom', message: 'Start Date cannot be past date!',
        validateFn: (row) => {
            return skipValidation(row) || new Date(row.effectiveFrom).getTime() >= new Date().setHours(0, 0, 0, 0);
        }
    },
    {
        field: 'effectiveTo', message: 'End Date is required!',
        validateFn: (row) => {
            return skipValidation(row) || isValid(row.effectiveTo);
        }
    },
    {
        field: 'effectiveTo', message: 'End Date should be greater than Start Date!',
        validateFn: (row) => {
            return skipValidation(row) || new Date(row.effectiveTo).getTime() >= new Date(row.effectiveFrom).getTime();
        }
    },
    {
        field: 'effectiveTo', message: 'End Date cannot be past date!',
        validateFn: (row) => {
            return skipValidation(row) || new Date(row.effectiveTo).getTime() >= new Date().setHours(0, 0, 0, 0);
        }
    },
];

const feeFields = ['regFee', 'admissionFee', 'tuitionFee', 'classFee', 'techFee'];

const rangeFields = ['rangeFrom', 'rangeTo'];

export const ConsessionSlabModal = ({
    category,
    subCategory = null,
    subSubCategory = null,
    onClose,
    onSave,
    concessionPayload,
    getAppliedFilterCount = () => { },
    setOpenFilterMenu = () => { },
    userPermissions,
}) => {
    const [slabs, setSlabs] = useState([]);
    const slabRef = useRef([]);
    const [loading, setLoading] = useState(false);
    const [cols, setCols] = useState(() => {
        const feeComponent = subSubCategory ? subSubCategory.feeComponent.map(item => item.value) : subCategory.feeComponent.map(item => item.value);
        const feeFieldsToShow = feeColumns.filter(item => feeComponent.includes(item.beName));

        const sr = subSubCategory?.scholarshipRange.value ?? subCategory.scholarshipRange.value;
        const rangeFieldsToShow = sr === 'QUALIFIED' ? [] : rangeColumns;

        const updatedCols = [...rangeFieldsToShow, ...feeFieldsToShow, ...columns];
        return updatedCols;
    });
    const [initailCols, setInitialCols] = useState(null);

    const [academicCareerIds, setAcademicCareerIds] = useState(null);
    const [academicGroupIds, setAcademicGroupIds] = useState(null);
    const [courseAttributeIds, setCourseAttributeIds] = useState(null);
    const [termIds, setTermIds] = useState(null);
    const [businessAreaIds, setBusinessAreaIds] = useState(null);
    const [courseIds, setCourseIds] = useState(null);
    const [openBulkUpload, setOpenBulkUpload] = useState(false);
    const [deletedRows, setDeletedRows] = useState([]);
    const [editedRows, setEditedRows] = useState([]);

    const isFormDirty = useRef(false);
    const [rowsCountInEditMode, setRowsCountInEditMode] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleSaveClick = (newCols) => {
        if (!initailCols) {
            setInitialCols(cols)
            const tempCols = [...cols, ...newCols];
            for (let i = 0; i < DYNAMIC_FORM_FIELDS.length; i++) {
                const currCol = tempCols.find(col => col.field === DYNAMIC_FORM_FIELDS[i].field);
                if (currCol) {
                    currCol.valueOptions = DYNAMIC_FORM_FIELDS[i].state;
                }
            }
            setCols([...tempCols]);
        } else {
            const tempCols = [...initailCols, ...newCols];
            for (let i = 0; i < DYNAMIC_FORM_FIELDS.length; i++) {
                const currCol = tempCols.find(col => col.field === DYNAMIC_FORM_FIELDS[i].field);
                if (currCol) {
                    currCol.valueOptions = DYNAMIC_FORM_FIELDS[i].state;
                }
            }
            setCols([...tempCols]);
        }
    }

    const DYNAMIC_FORM_FIELDS = [
        { field: 'academicCareerId', name: 'academicCareer', state: academicCareerIds, stateMethod: setAcademicCareerIds, url: endpoint.academic_career.getAllActive },
        { field: 'academicGroupId', name: "academicGroup", state: academicGroupIds, stateMethod: setAcademicGroupIds, url: endpoint.academic_group.getAllActive },
        { field: 'courseCategoryId', name: "courseAttributeValue", state: courseAttributeIds, stateMethod: setCourseAttributeIds, url: endpoint?.courseCategory?.getAllActiveCourseCategory },
        { field: 'termId', name: "term", stateMethod: setTermIds, state: termIds, url: endpoint.term.getAllActive },
        { field: 'businessAreaId', name: "businessArea", state: businessAreaIds, stateMethod: setBusinessAreaIds, url: endpoint.businessArea.getByAcademicCareerList },
        { field: 'courseId', name: "courseId", state: courseIds, stateMethod: setCourseIds, url: endpoint.course_details.courseIdByCourseAttribute },
    ];

    const isConcessionParameterPercent = useMemo(() => {
        const cp = subSubCategory?.consessionParameter.value ?? subCategory.consessionParameter.value;
        return cp === 'PERCENTAGE';
    }, [subSubCategory, subCategory]);

    const rangeMaxVal = useMemo(() => {
        const sr = subSubCategory?.scholarshipRange.value ?? subCategory.scholarshipRange.value;
        switch (sr) {
            case 'PERCENTAGE':
            case 'PERCENTILE': return 100;
            case 'RANK': return 9999999;
            case 'MARKS':
            case 'GRADES': return 9999;
            default:
                return 9999999;
        }
    }, [subSubCategory, subCategory]);

    const minDate = useMemo(() => {
        return subSubCategory?.effectiveDate ?? subCategory.effectiveDate;
    }, [subSubCategory, subCategory]);

    const maxDate = useMemo(() => {
        return subSubCategory?.expiryDate ?? subCategory.expiryDate;
    }, [subSubCategory, subCategory]);

    useEffect(() => {
        (function init() {
            const getAllCalls = [];
            for (let i = 0; i < DYNAMIC_FORM_FIELDS.length; i++) {
                const { url, name, field, stateMethod } = DYNAMIC_FORM_FIELDS[i];
                getAllCalls.push(fetchAllData(url, name, field, stateMethod));
            }
            setLoading(true);
            Promise.all(getAllCalls).then(() => setLoading(false)).catch(err => console.log(err));
        })();
    }, []);

    useEffect(() => {
        if ((subCategory || subSubCategory) && concessionPayload && academicCareerIds && academicGroupIds && courseAttributeIds && termIds && businessAreaIds && courseIds) {
            const updatedCols = [...cols];
            for (let i = 0; i < DYNAMIC_FORM_FIELDS.length; i++) {
                const currCol = updatedCols.find(col => col.field === DYNAMIC_FORM_FIELDS[i].field);
                if (currCol) {
                    currCol.valueOptions = DYNAMIC_FORM_FIELDS[i].state;
                }
            }
            updatedCols.forEach(col => {
                if (feeFields.indexOf(col.field) > -1 && isConcessionParameterPercent) {
                    col.maxValue = 100;
                } else {
                    col.maxValue = 999999999;
                }
                if (rangeFields.indexOf(col.field) > -1) {
                    col.maxValue = rangeMaxVal;
                }
                if (col.field === 'effectiveFrom' || col.field === 'effectiveTo') {
                    col.minDate = new Date(minDate) > new Date().setHours(0, 0, 0, 0) ? new Date(minDate) : new Date().setHours(0, 0, 0, 0);
                    col.maxDate = new Date(minDate).getTime()===new Date(maxDate).getTime() ? new Date(maxDate) : new Date(maxDate).setHours(0, 0, -1);
                }

                if (col.field === 'businessAreaId' || col.field === 'courseId') {
                    col.onFocus = onFocus;
                }
            })

            setCols(updatedCols);

            const slabs = createSlabs(concessionPayload, updatedCols);
            setSlabs(slabs);

            setDeletedRows([]);
            setEditedRows([]);
            setRowsCountInEditMode(0);
            isFormDirty.current = false;
        }
    }, [concessionPayload, academicCareerIds, academicGroupIds, courseAttributeIds, termIds, businessAreaIds, courseIds, subCategory, subSubCategory]);

    useEffect(() => {
        slabRef.current = slabs;
    }, [slabs]);

    async function fetchAllData(url, name, field /** field will be removed later */, stateMethod) {
        try {
            let response;
            if (field === 'businessAreaId' || field == 'courseId') {
                const res = await fetchAllPostPromisedData(url, { id: [0] });
                response = res.data;
            } else {
                response = await fetchAllPromisedData(url);
            }
            if (response) {
                const list = response.map(item => {
                    if (field === "courseId") {
                        return {
                            // For courseId, BE should send same response as other APIs
                            label: item.courseId,
                            value: +item.courseId
                        }
                    } else if (field === "businessAreaId") {
                        return {
                            ...item,
                            label: item[`${name}DispValue`],
                            value: item.id
                        }
                    } else {
                        return {
                            label: item[`${name}DispValue`],
                            value: item.id
                        }
                    }
                })
                stateMethod(list);
            } else {
                failureToast("Could not fetch data!");
            }
        } catch (err) {
            failureToast("Could not fetch data!");
        }
    }

    async function onFocus(row, col) {

        let payloadArr = [];
        let url = '';
        let name = '';
        let field = '';

        if (col.field === 'businessAreaId') {
            field = 'businessAreaId';
            name = 'businessArea';
            payloadArr = row.academicCareerId;
            url = endpoint.businessArea.getByAcademicCareerList;
        } else if (col.field === 'courseId') {
            field = 'courseId';
            name = 'courseId';
            payloadArr = row.courseCategoryId;
            url = endpoint.course_details.courseIdByCourseAttribute;
        }

        const canCallAPI = url && name && field;

        if (!canCallAPI) {
            return;
        }

        let updatedCols = cols.map(c => c.field === col.field ? { ...c, disabled: false, loading: true } : c);
        setCols(updatedCols);

        try {
            const arr = payloadArr.map(item => item.value);
            const isAllSelected = arr.length === 0 || arr[0] === "*";
            const res = await fetchAllPostPromisedData(url, { id: isAllSelected ? 0 : arr });
            if (res && res.code === 200) {
                const list = res.data.map(item => {
                    if (field === "courseId") {
                        return {
                            // For courseId, BE should send same response as other APIs
                            label: item.courseId,
                            value: +item.courseId
                        }
                    } else {
                        return {
                            label: item[`${name}DispValue`],
                            value: item.id
                        }
                    }
                })
                updatedCols = updatedCols.map(c => c.field === col.field ? { ...c, valueOptions: list, disabled: false, loading: false } : c);
            } else {
                failureToast("Could not fetch data!");
            }
        } catch (err) {
            failureToast("Could not fetch data!");
        }
        setCols(updatedCols);
    }

    function createSlabs(concessionPayload, updatedCols) {
        function createValues(arr, superArr, allOptionRequired = true) {
            const values = [];
            if (arr.length === 0 || arr[0] === 0) return allOptionRequired ? [{ label: "All", value: "*" }, ...superArr] : [...superArr];
            arr.forEach(item => {
                const el = superArr.find(data => data.value === item);
                if (el) values.push(el);
            })
            return values;
        }

        const slabRows = concessionPayload.map(slab => {
            const { id, rangeFrom, rangeTo, regFee, admissionFee, tuitionFee, techFee, classFee,
                studentLimit, academicCareerId, academicGroupId, termId, courseId, businessAreaId,
                courseCategoryId, effectiveFrom, effectiveTo, remarks, description, scholarshipCounter, pserpId ,status } = slab;

            const careerIds = createValues(academicCareerId, academicCareerIds);
            const courseCategoryIds = createValues(courseCategoryId, courseAttributeIds);

            return {
                id,
                ...(updatedCols.find(item => item.field === 'rangeFrom') && { rangeFrom: rangeFrom }),
                ...(updatedCols.find(item => item.field === 'rangeTo') && { rangeTo: rangeTo }),
                ...(updatedCols.find(item => item.field === 'regFee') && { regFee: regFee }),
                ...(updatedCols.find(item => item.field === 'admissionFee') && { admissionFee: admissionFee }),
                ...(updatedCols.find(item => item.field === 'tuitionFee') && { tuitionFee: tuitionFee }),
                ...(updatedCols.find(item => item.field === 'techFee') && { techFee: techFee }),
                ...(updatedCols.find(item => item.field === 'classFee') && { classFee: classFee }),
                studentLimit,
                academicCareerId: careerIds,
                academicGroupId: createValues(academicGroupId, academicGroupIds),
                termId: createValues(termId, termIds),
                courseId: createValues(courseId, courseIds),
                businessAreaId: createValues(businessAreaId, businessAreaIds, false),
                courseCategoryId: courseCategoryIds,
                remarks: [{ label: remarks, value: remarks }],
                description,
                effectiveFrom,
                effectiveTo,
                fromBE: true,
                deleted: false,
                scholarshipCounter: scholarshipCounter,
                pserpId : pserpId,
                status : status
            }
        });

        return slabRows;
    }

    function onSlabChange(rows) {
        setSlabs(rows);
    }

    function getRequestPayload(data) {
        function createValues(arr) {
            if (arr.length === 0 || arr[0].value === "*") {
                return [0];
            }
            return arr.map(item => item.value);
        }

        const payload = data.map(slab => {
            const { id, rangeFrom, rangeTo, regFee, admissionFee, tuitionFee, techFee, classFee,
                studentLimit, academicCareerId, academicGroupId, termId, courseId, businessAreaId,
                courseCategoryId, effectiveFrom, effectiveTo, remarks, description, isNew, deleted, scholarshipCounter, pserpId, status } = slab;

            return {
                ...(slab.hasOwnProperty('fromBE') && { id: id }),
                action: deleted ? "DELETE" : slab.hasOwnProperty('fromBE') ? "UPDATE" : "CREATE",
                rangeFrom: +rangeFrom,
                rangeTo: +rangeTo,
                regFee: +regFee,
                admissionFee: +admissionFee,
                tuitionFee: +tuitionFee,
                techFee: +techFee,
                classFee: +classFee,
                studentLimit: +studentLimit || null ,
                academicCareerId: createValues(academicCareerId),
                academicGroupId: createValues(academicGroupId),
                termId: createValues(termId),
                courseId: createValues(courseId),
                businessAreaId: createValues(businessAreaId),
                courseCategoryId: createValues(courseCategoryId),
                remarks: remarks?.[0]?.value || "",
                description,
                effectiveFrom,
                effectiveTo,
                ...(subSubCategory ? { subSubCategoryId: subSubCategory?.id } : { subCategoryId: subCategory.id }),
                // status: "ACTIVE",
                scholarshipCounter: slab.hasOwnProperty('fromBE') ? scholarshipCounter : 0,
                pserpId : pserpId,
                status : status
            }
        });

        return payload;
    }

    const hasOverlappingRows = (rows) => {
        let flag = false
        const ranges = [];
        rows.forEach(row => {
            ranges.forEach(range => {
                if (!(+row.rangeTo < range.min || +row.rangeFrom > range.max)) {
                    flag = true;
                }
            })
            ranges.push({ min: +row.rangeFrom, max: +row.rangeTo });
        })

        return flag;
    }

    const hasMasterOverlapping = (rows, keysToCheck) => {
        const rowCombinations = new Set();
    
        for (const obj of rows) {
            const combinations = keysToCheck.map(key => {
                if (obj.hasOwnProperty(key) && Array.isArray(obj[key])) {
                    const sortedValues = obj[key].map(item => item.value).sort();
                    return sortedValues.join(',');
                }
                return '';
            });
    
            const combination = combinations.join('|');
    
            if (rowCombinations.has(combination)) {
                return true; // Duplicate combination found
            }
    
            rowCombinations.add(combination);
        }
    
        return false; // No duplicate combinations found
    }

    async function handleSave() {

        // if (hasOverlappingRows(slabs) === true && (subSubCategory?.scholarshipRange?.value !== 'QUALIFIED' && subCategory.scholarshipRange?.value !== 'QUALIFIED')) {
        //     failureToast("Overlapping range values exist in slabs!");
        //     return;
        // }

        // if (hasMasterOverlapping(slabs, ['academicCareerId', 'academicGroupId', 'businessAreaId', 'courseCategoryId', 'courseId', 'termId']) && (subSubCategory?.scholarshipRange?.value === 'QUALIFIED' || subCategory.scholarshipRange?.value === 'QUALIFIED')) {
        //     failureToast("Overlapping master values exist in slabs!");
        //     return;
        // }

        setLoading(true);
        const rowsToDelete = getRequestPayload(deletedRows);
        const rowsToUpdate = getRequestPayload(editedRows);

        const request = [...rowsToDelete, ...rowsToUpdate];

        try {
            const response = await fetchAllPostPromisedData(
                `${endpoint.concessionSlab}`,
                request,
            );
            setLoading(false);
            if (response && response.code === 200) {
                isFormDirty.current = false;
                onSave();
                onClose(false);
                successToast("Concession slab has been updated successfully!");
            } else {
                failureToast("Details not saved. Please retry again");
            }
        } catch (error) {
            setLoading(false);
            failureToast("Details not saved. Please retry again");
        }
    }

    function onAddRewRow() {
        setRowsCountInEditMode(count => count + 1);

        const updatedCols = cols.map(c => ({ ...c, disabled: false }));

        for (let i = 0; i < DYNAMIC_FORM_FIELDS.length; i++) {
            const currCol = updatedCols.find(col => col.field === DYNAMIC_FORM_FIELDS[i].field);
            if (currCol) {
                currCol.valueOptions = DYNAMIC_FORM_FIELDS[i].state;
            }
        }

        setCols(updatedCols);
    }


    const openBulkUploadScreen = () => {
        setOpenBulkUpload(true)
    }


    const handleExport = () => {
        //api/concessionService/concession-slab/exportToCsv

    }

    const asyncSaveRow = (currentRow) => {
        const modifiedRow = { ...currentRow };

        DYNAMIC_FORM_FIELDS.forEach(item => {
            if (!modifiedRow?.[item.field] || modifiedRow?.[item.field].length === 0) {
                modifiedRow[item.field] = [{ label: "All", value: "*" }, ...item.state];
            }
        })

        setRowsCountInEditMode(count => count - 1);
        setEditedRows(prevEditedRows => {
            const rows = prevEditedRows.filter(row => row.id !== modifiedRow.id);
            return [...rows, modifiedRow];
        });
        return Promise.resolve(modifiedRow);
    }

    const onRowDelete = (deletedRow) => {
        if (editedRows.findIndex(row => row.id === deletedRow.id) > -1) {
            setEditedRows(prevEditedRows => prevEditedRows.filter(row => row.id !== deletedRow.id));
        } else {
            setDeletedRows(rows => ([...rows, { ...deletedRow, deleted: true }]));
        }
    };

    const headerComp = (
        <Row className="flex-grow-1 justify-content-between">
            <Col md="3">
                <CommonInput
                    type="text"
                    label="Scholarship range/Criteria"
                    defaultValue={subSubCategory?.scholarshipRange.value ?? subCategory.scholarshipRange.value}
                    isDisabled
                />
            </Col>
            <Col md="3">
                <CommonInput
                    type="text"
                    label="Consession Parameter"
                    defaultValue={subSubCategory?.consessionParameter.value ?? subCategory.consessionParameter.value}
                    isDisabled
                />
            </Col>
            {/* <Col md="1" /> */}
            {console.log(subSubCategory, "subSubCategory")}
            <Col md="6" className="d-flex align-items-center justify-content-end">
                {!loading ? <GetTemplate
                    url={`${endpoint.exportConsessionSlab}/${subSubCategory?.subSubCategoryId ? `${subSubCategory?.id}` : `${subCategory?.id}`}`}
                    outlined={false}
                    icon={false}
                    className={'p-1 text-center'}
                /> : null}

                {!loading && <CustomButton
                    className={'ml-2  p-1'}
                    content={<span onClick={openBulkUploadScreen} style={{ fontSize: 16, cursor: 'pointer', fontWeight: 400 }}>Upload Slabs</span>}
                    // outline
                    // forTable={true}
                    permissionType={'R'}
                    permissionSet={userPermissions}
                    onClick={openBulkUploadScreen}
                />}
            </Col>
        </Row>);

    const headerMiscellaneousRightComp = (
        <>

            <CustomButton
                className={'regular-large  p-1'}
                content={<span>Add Fields</span>
                }
                // outline
                onClick={(e) => handleClick(e)}
                permissionType={'R'}
                permissionSet={userPermissions}
                disabled={rowsCountInEditMode > 0}
            />
            
            <Badge badgeContent={getAppliedFilterCount()} color="primary">
            <CustomButton
                className={'regular-large  p-1'}
                content={<span>Filter</span>
                }
                // outline
                // forTable={true}
                permissionType={'R'}
                permissionSet={userPermissions}
                onClick={() => setOpenFilterMenu(true)}
            />
            </Badge>

        </>
    )

    return (
        <>
            <Card className="add-concession-modal" style={{ width: "100%", marginBottom: "0px" }}>
                <CardBody style={{ height: "auto", maxHeight: '90vh', overflow: 'scroll', paddingBottom: "65px" }}>
                    <Row className="d-flex justify-content-between align-items-center pl-3 pr-3 mb-3">
                        <div>
                            <h2 style={{ fontWeight: 700 }}>Concession Slab</h2>
                            <h4>{`
                            ${category?.categoryCode ? category?.categoryCode + " / " : ''}
                            ${category?.categoryDispVal ?? ''}
                            ${subCategory?.subCategoryTitle ? " / " + subCategory?.subCategoryTitle : ''}
                            ${subCategory?.subCategoryDispVal ? " / " + subCategory?.subCategoryDispVal : ''}
                            ${subSubCategory?.subSubCategoryTitle ? " / " + subSubCategory?.subSubCategoryTitle : ''}
                        `}</h4>
                        </div>
                        <Tooltip title="Close" placement='top-start'>
                            <IconButton onClick={() => onClose(isFormDirty.current)}>
                                <IoClose size={24} style={{ cursor: "pointer" }} />
                            </IconButton>
                        </Tooltip>
                    </Row>

                    <MuiTable
                        rows={slabs}
                        columns={cols}
                        onRowChange={onSlabChange}
                        loading={loading}
                        headerComp={headerComp}
                        onCellValueChange={() => isFormDirty.current = true}
                        onEditClick={() => setRowsCountInEditMode(count => count + 1)}
                        onCancelClick={() => setRowsCountInEditMode(count => count - 1)}
                        onAddRewRow={onAddRewRow}
                        onRowDelete={onRowDelete}
                        userPermissions={userPermissions}
                        asyncSaveRow={asyncSaveRow}
                        validationList={validationRules}
                        minHeight={rowsCountInEditMode > 0 ? 350  : 90}
                        maxHeight={320}
                        disableVirtualization={true}
                        headerMiscellaneousRightComp={headerMiscellaneousRightComp}
                        multiselectWithportal={true}
                        newRowHeaderClassName={'p-1'}
                        outlined={false}
                    />
                    <Row className={'m-0 px-4 py-1 fixed-bottom w-100 bg-white d-flex justify-content-between'} style={{ boxShadow: '0px 4px 32px rgba(61, 70, 112, 0.08)' }}>
                        <CustomButton
                            content={"Save Concession slab"}
                            permissionType={'C'}
                            permissionSet={userPermissions}
                            onClick={() => handleSave()}
                            disabled={loading || rowsCountInEditMode !== 0 || deletedRows.length + editedRows.length === 0}
                        />
                        {(concessionPayload[0]?.updatedBy && concessionPayload[0]?.updatedOn) &&
                            <h5 className='mr-2 mt-3'>Last updated by {concessionPayload[0]?.updatedBy} at {moment(concessionPayload[0]?.updatedOn).format("hh:mm a")} on {moment(concessionPayload[0]?.updatedOn).format('DD/MM/YYYY')}.</h5>
                        }
                    </Row>
                </CardBody>
            </Card>
            <Modal open={openBulkUpload}
                backdrop="false"
                sx={{ top: '3%' }}
            >
                <ConsessionBulkUpload userPermissions={"C,R,U,D,A"} setOpenBulkUpload={setOpenBulkUpload} />
            </Modal>
            <FieldHandler anchorEl={anchorEl} setAnchorEl={setAnchorEl} hidableColumns={hidableColumns} onSaveClick={handleSaveClick} />
        </>

    )
};
