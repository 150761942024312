import React, { useState } from "react";
import { Checkbox, Dialog, FormControlLabel } from "@mui/material";
import { ReactComponent as CrossIcon } from "assets/img/svg/CrossIcon.svg";
import ALoader from "views/pages/manage/common/a-loader";
import { primaryCheckboxStyles } from "../../issuanceIDcard/printIdCardAndMapRfid/constant";
import AButton from "../../../common/form-fields-mui/AButton";
import { inputMap, inputStyles } from "../stockReturn/constant";
import AInput from "../../../common/formFeilds/customInput/AInput";
import StudentApplicationsPrompt from "../studentList/StudentApplicationsPrompt";
import { PAGE_STATE } from "../contextApi";
import { failureToast } from "../../../common/utils/methods/toasterFunctions/function";
import { getPaymentInfoForPsid } from "./service";
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'

const SearchStudentPopupMisc = ({
  open,
  setOpen,
  dropdown,
  setCurrentPageState,
  setApplicationMisc,
}) => {
  const [isOpenPrompt, setIsOpenPrompt] = useState(false);
  const applicationSearchInitialForm = {
    psid: "",
    applicationId: "",
  };
  const [checkedOption, setCheckedOption] = useState("psid"); // psid, applicationId, studentName, transactionId
  const [form, setForm] = useState(applicationSearchInitialForm);
  const [inputErrFlag, setInputErrFlag] = useState(false);
  const [listLoader, setListLoader] = useState(false);
  const [rows, setRows] = useState([]);
  // const [rowCount, setRowCount] = useState(0);
  const getPlantId = (businessArea) => {
    return dropdown?.businessAreas?.find(item => +item?.value=== +businessArea)?.sapPlantId;
  }
  const onSelectHandler = (application) => {
    setApplicationMisc({...application, sapPlantId: getPlantId(application?.businessArea)});
    setCurrentPageState(PAGE_STATE.ISSUE_STOCK_MISC);
  };

  const handleCheckboxChange = (option) => {
    setInputErrFlag(false);
    setCheckedOption(option);
    setForm(applicationSearchInitialForm); // reset the form
  };

  const handleClose = () => {
    setOpen(false);
    setForm(applicationSearchInitialForm);
    setCheckedOption("psid");
  };

  const formHandler = (value, key) =>
    setForm((prev) => ({ ...prev, [key]: value }));

  const checkboxOptions = [
    {
      title: "Search by PSID",
      onChange: (e) => {
        e.target.checked && handleCheckboxChange("psid");
      },
      checked: checkedOption === "psid",
    },
    // {
    //   title: "Search by Application ID",
    //   onChange: (e) => {
    //     e.target.checked && handleCheckboxChange("applicationId");
    //   },
    //   checked: checkedOption === "applicationId",
    //   disabled: true,
    // },
  ];

  const fetchList = async () => {
    setListLoader(true);
    const reqBody = {
      ...form, 
      businessAreaIdList: dropdown?.businessAreas?.map((item) => item?.value),
    }
    const res = await getPaymentInfoForPsid(reqBody);
    if (res?.code === 200) {
      setRows(res?.data?.applicationPaymentList || []);
      if(res?.data?.applicationPaymentList?.length) {
        setIsOpenPrompt(true);
      } else {
        failureToast('No records found');
      }
    } else {
      setRows([]);
    }
    setListLoader(false);
    return res;
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{ sx: { maxWidth: "unset", borderRadius: "12px" } }}
      >
        {listLoader && <ALoader />}
        <div style={{ padding: "28px 24px", width: "765px" }}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="heading-4 color-black">Search student</div>
            <IconButtonWrapper onClick={handleClose}><CrossIcon width={24} height={24} /></IconButtonWrapper>
          </div>

          <div className="d-flex mt-4" style={{ gap: "8px" }}>
            {checkboxOptions?.map((item) => (
              <FormControlLabel
                key={JSON.stringify(item)}
                disableTypography
                sx={{ margin: "0px", ...primaryCheckboxStyles }}
                control={
                  <Checkbox
                    sx={{ padding: "3px" }}
                    onChange={item?.onChange}
                    checked={item?.checked}
                    disabled={item?.disabled}
                  />
                }
                label={
                  <div
                    className={`semi-bold ${
                      item?.checked ? "label-color" : "color-grey-60"
                    }`}
                  >
                    {item?.title}
                  </div>
                }
              />
            ))}
          </div>

          <div className="mt-3">
            <div className="d-flex" style={{ gap: "28px" }}>
              <div className="flex-grow-1 position-relative">
                <AInput
                  label={`${inputMap?.[checkedOption].label}*`}
                  placeholder={
                    inputMap?.[checkedOption]?.placeholder || `Enter`
                  }
                  style={inputStyles}
                  value={form?.[checkedOption]}
                  regex={inputMap?.[checkedOption]?.regex}
                  handler={(value) => {
                    if (inputMap?.[checkedOption].validLength) {
                      value?.length &&
                      value?.length < inputMap[checkedOption].validLength
                        ? setInputErrFlag(true)
                        : setInputErrFlag(false);
                      value?.length <= inputMap[checkedOption].validLength &&
                        formHandler(value?.toUpperCase(), checkedOption);
                    } else {
                      formHandler(value?.toUpperCase(), checkedOption);
                    }
                  }}
                  crossIcon={true}
                  crossHandler={() => formHandler("", checkedOption)}
                />
                {inputErrFlag && (
                  <span className="tiny-semi-bold color-red-40 position-absolute top-100">
                    {inputMap?.[checkedOption]?.errMsg || "Error"}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="mt-4">
            <AButton
              className="btn-left-0"
              variant="primary_filled"
              onClick={fetchList}
              disabled={form?.[checkedOption] === ""}
            >
              Search
            </AButton>
          </div>
        </div>
      </Dialog>

      {isOpenPrompt && (
        <StudentApplicationsPrompt
          isOpen={isOpenPrompt}
          setIsOpen={setIsOpenPrompt}
          list={rows}
          isMisc={true}
          onSelectClick={onSelectHandler}
        />
      )}
    </>
  );
};

export default SearchStudentPopupMisc;
