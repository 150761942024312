import React from "react";
import DisplayBatchDetails from "./DisplayBatchDetails";
import AAutoComplete from "./../../common/form-fields-mui/AAutoComplete";
import DateRangeForm from "./DateRangeForm";
import FormBySubjects from "./FormBySubjects";
import { lectureDurationDropdown } from "./helper";

const GetFrom = ({
  step,
  plansDropdown,
  roomsDropdown,
  batchDetails,
  form,
  formHandler,
  planSubjects,
  subjectFacultyDropdown,
  ...props
}) => {
  switch (step) {
    case 1:
      return <DisplayBatchDetails batchDetails={batchDetails} />;

    case 2:
      return (
        <div className="w-100">
          <AAutoComplete
            height="40px"
            currentValue={form?.plan}
            handleChange={(value) => formHandler(value, "plan")}
            fontSize="12px"
            isMulti={false}
            label="Academic plan"
            placeholder="Enter academic plan"
            items={plansDropdown}
            labelStyle={{ fontWeight: "600", color: "#262626" }}
            noOptionsMessage=" No Academic Plan found for this batch. Please contact corporate EDP team to see academic plans linked with this batch"
          />
          <AAutoComplete
            height="40px"
            currentValue={form?.duration}
            handleChange={(value) => formHandler(value, "duration")}
            fontSize="12px"
            isMulti={false}
            label="Lecture duration"
            placeholder="Select lecture duration"
            items={lectureDurationDropdown}
            labelStyle={{ fontWeight: "600", color: "#262626" }}
          />
          {planSubjects && (
            <FormBySubjects
              subjects={planSubjects}
              formHandler={formHandler}
              form={form}
              subjectFacultyDropdown={subjectFacultyDropdown}
            />
          )}
        </div>
      );

    case 3:
      return (
        <DateRangeForm
          form={form}
          formHandler={formHandler}
          roomsDropdown={roomsDropdown}
          daysPlanDropdown={props?.daysPlanDropdown}
        />
      );
    default:
      break;
  }
  return <div>GetFrom</div>;
};

export default GetFrom;
