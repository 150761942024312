import React, { useEffect, useState } from "react";
import { Card, CardHeader, Input, Container, Button, Table, Row } from "reactstrap";
// import "views/pages/manage/addressType/node_modules/react-sliding-pane/dist/react-sliding-pane.css";
import { failureToast, successToast} from "../../common/utils/methods/toasterFunctions/function";
import { MasterHeaderAuto } from "../../common/commonComponents/masterHeader/masterHeader";
import { fetchDetails, getDispValForReactSelectByOneID, NewRow } from "../../common/utils/methods/commonMethods/utilityMethod";
import { getAPI, masterServiceBaseUrl, putpost, putpost2 } from "services/http";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import moment from "moment";
import IsCoreMaster from "../../common/commonComponents/coreMaster";
// import CreateButton from "components/FormComponent/Button/New/index";
// import EditButton from "components/FormComponent/Button/Edit/index";
// import UpdateButton from "components/FormComponent/Button/Update/index";
import CustomButton from "components/CustomButton";
import { ButtonText } from "variables/Buttons";
import { CustomToggle } from "../../common/formFeilds";

var _ = require("lodash");
const OperationalZoneTable = (props) => {
  const { userPermissions } = props;
  const { serverData, setSearch, resetSearch, isSaveVisible, setisSaveVisible, action, pagination, setApiloader } = props;
  const [tableDataKey, settableDataKey] = useState("tableDataKey");
  const [updatedKey, setUpdateKey] = useState(new Date());
  const [searchStr, setSearchStr] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [regionDropDownData, setRegionDropDownData] = useState([]);
  const fetchRegionData = (url) => {
    getAPI(
      url,
      (data) => {
        let tempArray = [];
        if (data.data.length > 0) {
          data.data.map((item) => {
            tempArray.push({
              label: item.regionDispValue,
              value: item.id,
            });
          });
          setRegionDropDownData(tempArray);
        }
      },
      (data) => {
        failureToast(data["message"]);
      }
    );
  };
  const [regionMasterData, setRegionMasterData] = useState([]);
  useEffect(() => {
    fetchRegionData(masterServiceBaseUrl + "/region/getAllActiveRegion");
    fetchDetails(masterServiceBaseUrl + `/region/getAllRegion`, setRegionMasterData, "region", "reactSelect");
    // fetchAllData(getApiUrl,setState)
  }, []);
  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: "__new",
        status: "ACTIVE",
        operationalZoneDesc: null,
        operationalZoneDispValue: null,
        updatedDate: null,
        // isCoreMaster: true,
        regionId: [],
        effectiveDate: moment().format("DD-MM-YYYY"),
      },
      ...props.classArray,
    ]);
  };
  const headerList = [
    { name: "S. No." },
    { name: "Effective Date" },
    { name: "Region", isRequired: true },
    { name: "Description", isRequired: true },
    { name: "Display Value", isRequired: true },
    // {name: 'Core Master'},
    { name: "Status" },
    { name: "Action" },
  ];
  const listToRender = [
    "operationalZoneKey",
    "effectiveDate",
    "regionId",
    "operationalZoneDesc",
    "operationalZoneDispValue",
    // 'isCoreMaster',
    "status",
  ];
  const saveAll = (cb) => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if (el["editMode"]) {
        if (!el.id) {
          if (el.regionId.length < 1) {
            isValid = false;
            failureToast("Please Select Region");
            return;
          }
        }
        if (!el["operationalZoneDesc"] || !el["operationalZoneDesc"].trim()) {
          isValid = false;
          failureToast("Please Enter Description");
          return;
        } else if (!el["operationalZoneDispValue"] || !el["operationalZoneDispValue"].trim()) {
          isValid = false;
          failureToast("Please Enter Display Value");
          return;
        }
        if (!isValid) {
          return;
        }
        if (_tempArray[index]["__status"] == "__new") {
          let rowData = _tempArray[index];
          let tempObject = {
            regionId: rowData.regionId,
            operationalZoneDesc: rowData.operationalZoneDesc.trim(),
            operationalZoneDispValue: rowData.operationalZoneDispValue.trim(),
            status: rowData.status,
            // isCoreMaster: rowData.isCoreMaster
          };
          putpost(
            masterServiceBaseUrl + "/operationalZone/createOperationalZone",
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              setUpdateKey(new Date());
              props.fetchClasses(`${masterServiceBaseUrl}/operationalZone/getAllOperationalZone`);
            },
            (data) => {
              failureToast(data["message"]);
            },
            tempObject,
            "post"
          );
        } else {
          let rowData = _tempArray[index];
          let tempObject = {
            id: rowData.id,
            regionId: [rowData.regionId],
            operationalZoneDesc: rowData.operationalZoneDesc.trim(),
            operationalZoneDispValue: rowData.operationalZoneDispValue.trim(),
            status: rowData.status,
            isCoreMaster: rowData.isCoreMaster,
          };
          putpost(
            masterServiceBaseUrl + "/operationalZone/updateOperationalZone/" + _tempArray[index].id,
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              // delete _tempArray[index]['editMode']
              // props.setclassArray(_tempArray)
              props.fetchClasses(`${masterServiceBaseUrl}/operationalZone/getAllOperationalZone`);
              settableDataKey(new Date().getMilliseconds());
            },
            (data) => {
              failureToast(data["message"]);
            },
            tempObject,
            "put"
          );
        }
      } else {
      }
    });
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
        // settableDataKey(new Date().getMilliseconds())
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      cb();
    }
  };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]["editMode"] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };
  const deleteRow = (index) => {
    if (props.classArray[index]["__status"] == "__new") {
      let n = props.classArray;
      n.splice(index, 1);
      props.setclassArray(n);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
    } else {
      if (window.confirm("Are you sure you want to delete this person type?")) {
        putpost2(
          masterServiceBaseUrl + "/operationalZone/deleteOperationalZone/" + props.classArray[index].id,
          (data) => {
            successToast(data["message"]);
            if (props.classArray.length == 1) {
              // props.previousPage();
              props.fetchClasses(`${masterServiceBaseUrl}/operationalZone/getAllOperationalZone`);
            } else {
              var _tempArray = props.classArray;
              if (_tempArray[index]["editMode"]) {
                setisSaveVisible(false);
              }
              _tempArray.splice(index, 1);
              props.setclassArray(_tempArray);
            }
            settableDataKey(new Date().getMilliseconds());
            props.fetchClasses(`${masterServiceBaseUrl}/operationalZone/getAllOperationalZone`);
          },
          (data) => {
            failureToast(data["message"]);
          },
          "PUT"
        );
      }
    }
  };

  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
    setUpdateKey(new Date());
  };
  const filterData = (str) => {
    const newData = serverData && serverData.filter((obj) => Object.values(obj).some((val) => val && val.toString().toLowerCase().includes(str.toLowerCase())));
    setSearch(newData);
  };
  useEffect(() => {
    if (!searchStr || !searchStr.trim()) {
      resetSearch();
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
      filterData(searchStr);
    }
  }, [searchStr]);
  const [regionValue, setRegionValue] = useState([]);
  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              {/*<CardHeader className="border-0 d-flex justify-content-between">*/}
              <CardHeader className="border-0">
                <h3 className="mb-0  floatLeft">Operational Zone</h3>
                <IsCoreMaster master={"operationalZone"} masterDisplayValue={"Operational Zone"} setApiLoader={setApiloader} />
                {/* <CreateButton
                  disabled={isSaveVisible || isDisabled}
                  className={"floatRight mx-1 " + (isSaveVisible || isDisabled ? " btn-dark" : null)}
                  onClick={() => {
                    newRow();
                  }}
                  data-testid="addNewPersonType"
                  text={ButtonText.OperationalZone.addNew}
                /> */}
                <CustomButton
                  disabled={isSaveVisible || isDisabled}
                  icon={true}
                  data-testid="addNewPersonType"
                  className={"floatRight mx-1 " + (isSaveVisible || isDisabled ? " btn-dark" : null)}
                  content={ButtonText.OperationalZone.addNew}
                  permissionType={'C,U'}
                  permissionSet={userPermissions}
                  onClick={() => {
                    newRow();
                  }}
                />
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout">
                  <MasterHeaderAuto headerList={headerList} permissionSet={userPermissions} isSaveVisible={isSaveVisible}/>
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                          !el["editMode"] ? (
                            //
                            <>
                              <NewRow data={el} listToBeRendered={listToRender} index={index} currentPage={pagination.currentPage} regionMasterData={regionMasterData}>
                                <td className="text-center ">
                                  {/* <EditButton disabled={props.isViewOnly} onClick={() => editRow(index)} /> */}
                                  <CustomButton
																		permissionType={'U'}
																		icon={true}
																		forTable={true}
                                    disabled={props.isViewOnly}
																		permissionSet={userPermissions}
																		onClick={() => editRow(index)}
																	/>
                                  {/*<Button*/}
                                  {/*    color="info"*/}
                                  {/*    size="sm"*/}
                                  {/*    type="button"*/}
                                  {/*    disabled={props.isViewOnly}*/}
                                  {/*    onClick={() => deleteRow(index)}*/}
                                  {/*    // data-testid="deletebtn"*/}
                                  {/*>*/}
                                  {/*    <i className="fas fa-trash"/>*/}
                                  {/*</Button> */}
																	<CustomButton
																		permissionType={'D'}
																		icon={true}
																		forTable={true}
                                    disabled={props.isViewOnly}
																		permissionSet={userPermissions}
																		onClick={() => deleteRow(index)}
																	/>
                                </td>
                              </NewRow>
                            </>
                          ) : (
                            <tr key={index + "-class"}>
                              <td className="text-center p-2 mx-1">
                                {el.id ? el["operationalZoneKey"] : <Input disabled={true} defaultValue={el["operationalZoneKey"]} data-testid="operationalZoneKey" placeholder="S. No" className="tdInput" />}
                                {/*{(((pagination.currentPage - 1) * 10) + index + 1)}*/}
                              </td>
                              <td className="text-center p-2 mx-1">{el.id ? moment(el["effectiveDate"]).format("DD-MM-YYYY") : <Input maxLength="50" disabled={true} defaultValue={el["effectiveDate"]} data-testid="operationalZoneEffectiveDate" placeholder="Effective Date" className="tdInput" />}</td>
                              <td className="text-center p-2 mx-1">
                                {el.id ? (
                                  getDispValForReactSelectByOneID(el["regionId"], "DispValue", regionMasterData)
                                ) : (
                                  <ReactMultiSelectCheckboxes
                                    placeholderButtonLabel={"Region"}
                                    options={regionDropDownData}
                                    value={regionValue}
                                    onChange={(e) => {
                                      setRegionValue(e);
                                      let tempArray = [];
                                      if (e.length > 0) {
                                        e.map((item) => {
                                          tempArray.push(item.value);
                                        });
                                      }
                                      updateKey(index, tempArray, "regionId");
                                    }}
                                  />
                                )}
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength="30"
                                  // value
                                  value={el["operationalZoneDesc"]}
                                  data-testid="operationalZoneDesc"
                                  onChange={(e) => {
                                    updateKey(index, e.target.value.replace(/[^a-zA-Z 0-9._-]/g, ""), "operationalZoneDesc", true);
                                  }}
                                  placeholder="Description value"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength="30"
                                  value={el["operationalZoneDispValue"]}
                                  data-testid="operationalZoneDispValue"
                                  onChange={(e) => {
                                    updateKey(index, e.target.value.replace(/[^a-zA-Z 0-9._-]/g, ""), "operationalZoneDispValue", true);
                                  }}
                                  placeholder="Display value"
                                  className="tdInput"
                                />
                              </td>
                              {/*<td className="text-center p-2 mx-1"*/}
                              {/*    style={{display: 'flex', justifyContent: 'center'}}>*/}
                              {/*    <Input*/}
                              {/*        defaultChecked={el['isCoreMaster']}*/}
                              {/*        onChange={(value) => {*/}
                              {/*            updateKey(index, value.target.checked, 'isCoreMaster',)*/}
                              {/*        }}*/}
                              {/*        type="checkBox"*/}
                              {/*        style={{*/}
                              {/*            width: '20px',*/}
                              {/*            height: '20px',*/}
                              {/*        }}*/}
                              {/*    />*/}
                              {/*</td>*/}
                              <td className="text-center p-2 mx-1">
                                <label className="custom-toggle mx-auto ml-2">
                                  {/* <input
                                    checked={el.status == "ACTIVE" ? true : false}
                                    type="checkbox"
                                    id={el.id}
                                    onChange={(e) => {
                                      settableDataKey(new Date().getMilliseconds());
                                      console.log(e.target.checked);
                                      let n = "ACTIVE";
                                      if (!e.target.checked) {
                                        n = "INACTIVE";
                                      }
                                      updateKey(index, n, "status");
                                    }}
                                    data-testid="personTypeStatus"
                                  /> */}
                                  {/* <span style={{ width: "72px" }} className="custom-toggle-slider rounded-circle activeToggle" data-label-off="Inactive" data-label-on="Active" /> */}
                                  <CustomToggle
                                    status={el.status}
                                    data-testid="activeInactiveInput"
                                    id={el.id}
                                    toggleOn={"Active"}
                                    toggleOff={"Inactive"}
                                    onChangeHandler={(e) => {
                                      settableDataKey(new Date().getMilliseconds());
                                      let n = "ACTIVE";
                                      if (!e.target.checked) { n = "INACTIVE"; }
                                      updateKey(index, n, "status");
                                    }}
                                  />
                                </label>
                              </td>
                              <td className="text-center" data-testid="saveConcessionPersonType">
                                {isSaveVisible ? (
                                  <>
                                    {" "}
                                    {/* <UpdateButton
                                      text={ButtonText.OperationalZone.save}
                                      onClick={() => {
                                        saveAll();
                                      }}
                                    /> */}
                                    <CustomButton
																			content={ButtonText.OperationalZone.save}
																			permissionType={'C,U'}
																			forTable={true}
																			permissionSet={userPermissions}
																			onClick={() => {
                                        saveAll();
                                      }}
																		/>
                                    {/* <Button
                                      color="info"
                                      size="sm"
                                      type="button"
                                      className="mx-1"
                                      onClick={() => {
                                        saveAll();
                                      }}
                                    >
                                      Save
                                    </Button> */}
                                    {/* <Button
                                      color="danger"
                                      size="sm"
                                      type="button"
                                      onClick={() => {
                                        let n = props.classArray;
                                        if (n[index]["__status"] == "__new") {
                                          n.splice(index, 1);
                                        } else {
                                          // n[index]['editMode'] = false
                                          props.fetchClasses(`${masterServiceBaseUrl}/operationalZone/getAllOperationalZone`);
                                        }
                                        props.setclassArray(n);
                                        setisSaveVisible(false);
                                      }}
                                    >
                                      {" "}
                                      <i className="fas fa-times" />
                                    </Button>{" "} */}
                                    <CustomButton
																			permissionType={'cancel'}
																			icon={true}
																			forTable={true}
																			// permissionSet={userPermissions}
																			onClick={() => {
                                        let n = props.classArray;
                                        if (n[index]["__status"] == "__new") {
                                          n.splice(index, 1);
                                        } else {
                                          // n[index]['editMode'] = false
                                          props.fetchClasses(`${masterServiceBaseUrl}/operationalZone/getAllOperationalZone`);
                                        }
                                        props.setclassArray(n);
                                        setisSaveVisible(false);
                                      }}
																		/>
                                  </>
                                ) : (
                                  // <Button
                                  //   color="info"
                                  //   size="sm"
                                  //   type="button"
                                  //   onClick={() => {
                                  //     deleteRow(index);
                                  //   }}
                                  // >
                                  //   {" "}
                                  //   <i className="fas fa-trash" />
                                  // </Button>
                                  <CustomButton
																		permissionType={'D'}
																		icon={true}
																		forTable={true}
																		// permissionSet={userPermissions}
																		onClick={() => deleteRow(index)}
																	/>
                                )}
                              </td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default OperationalZoneTable;
