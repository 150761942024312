import React, { useContext, useState } from "react";
import { ReactComponent as LiftSideImage } from "../../../../../assets/img/svg/academic-bg.svg";
import AddPlanForm from "./AddPlanForm";
import "../../common/utils/scss/Acommon-style.scss";
import "./style.scss";
import { useEffect } from "react";
import { loadDropdowns } from "redux/academicPlan/actions";
import { useSelector, useDispatch } from "react-redux";
import { getBusinessAreas } from "views/pages/manage/edpOps/constant/batch-search";
import {
  fetchAllPostPromisedData,
  fetchAllPromisedData,
  updateRequest,
  updateRequest2,
} from "../../common/utils/methods/commonMethods/utilityMethod";
import { getClassesByAcademicGroupId } from "../constant/create-academic-plan";
import PageLoadingAnimation from "views/pages/manage/common/commonComponents/PageLoadingAnimation";
import { endpoint, pages } from "../../common/constant";
import { successToast } from "../../common/utils/methods/toasterFunctions/function";
import { useHistory, useParams } from "react-router";
import { PermissionContext, RolePermissions } from "appContext";
import { PermisionDenied } from "../../common/commonComponents";
import { getCoursesByPackageId } from "../academicTest/style";
import { getSubjectsDifference, useFetchCoursesByPackageId } from "./hook";
import CMSSubjectWarning from "./CMSSubjectWarning";
import CnfDialogBox from "./CnfPopUp";
import ALoader from "../../common/a-loader";

const CreateAcademicPlan = (props) => {
  const permissions = useContext(PermissionContext);
  const param = useParams();
  const userPermissions = (permissions, pages["createAcademicPlan"]["id"]);

  const history = useHistory();
  const [form, setForm] = useState({
    academicGroup: "",
    classes: "",
    board: "",
    academicYear: "",
    academicCareer: "",
    academicPhase: "",
    classType: "",
    description: "",
    regions: [],
    businessAreas: [],
    packageId: "",
    packageExtId: "",
  });
  const academicReduxState = useSelector((state) => state.academicPlan);
  const { dropdowns } = academicReduxState;
  const [classDropdown, setClassDropdown] = useState([]);
  const [businessAreaDropdown, setBusinessAreaDropdown] = useState([]);
  const [apiLoader, setApiLoader] = useState(false);

  const [cmsPackageDropdown, setCmsPackageDropdown] = useState([]);
  const [cmsPackageLoader, setCmsPackageLoader] = useState(false);
  const [planData, setPlanData] = useState("");
  const subjects = useFetchCoursesByPackageId(form?.packageId?.value);
  const [duplicatedPlanSubjects, setDuplicatedPlanSubjects] = useState([]);
  const [isOpenCmsSubjectCnf, setOpenCmsSubjectCnf] = useState(false);
  const [cmsSubjectMismatch, setCmsSubjectMissMatch] = useState({});
  const [isDuplicatePlanExist, setIsDuplicatePlanExist] = useState(false);
  const [planExistApiLoader, setPlanExitApiLoader] = useState(false);

  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);

  const nextHandler = () => {
    if (currentStep === 1) {
      if (validateSubjectsMismatch()) setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const transformIntoForm = (planDetails) => {
    try {
      let data = {
        ...planDetails,
        packageId: {
          value: planDetails?.packageId,
          label: planDetails?.packageExtId,
        },
      };
      if (data) {
        for (let key in data) {
          if (typeof data[key] === "object") {
            if (!Array.isArray(data[key])) {
              data[key] = {
                ...data[key],
                label: data[key] ? data[key]["dispValue"] : "",
                value: data[key] ? data[key]["id"] : "",
              };
            } else {
              for (let keyOfKey in data[key]) {
                data[key][keyOfKey] = {
                  ...data[key][keyOfKey],
                  value: data[key][keyOfKey] ? data[key][keyOfKey]["id"] : "",
                  label: data[key][keyOfKey]
                    ? data[key][keyOfKey]["dispValue"]
                    : "",
                };
              }
            }
          }
        }

        data = {
          ...data,
          packageId: {
            value: planDetails?.packageId,
            label: planDetails?.packageExtId,
          },
        };

        return data;
      }
    } catch (error) {
      return {
        ...planDetails,
        packageId: {
          value: planDetails?.packageId,
          label: planDetails?.packageExtId,
        },
      };
    }
  };

  const validateSubjectsMismatch = () => {
    let result = getSubjectsDifference(duplicatedPlanSubjects, subjects);

    if (result.subjects.length !== 0 && param?.id) {
      setCmsSubjectMissMatch(result);
      setOpenCmsSubjectCnf(true);
      return false;
    }

    return true;
  };

  useEffect(() => {
    const fetchPlanData = async (id) => {
      setApiLoader(true);
      const response = await fetchAllPromisedData(
        `${endpoint.viewAcademicPlan.getPlanById}/${id}?requireDispValue=true`,
        true
      );
      // console.log(response)

      if (response?.data) {
        setForm(transformIntoForm(response?.data));
        const coursesResponse = await getCoursesByPackageId(
          response?.data?.packageId
        );
        setDuplicatedPlanSubjects(
          coursesResponse?.data?.packages?.[0]?.courses
        );
      } else {
        setPlanData({});
      }
      setApiLoader(false);
    };
    if (param?.id) fetchPlanData(param?.id);
  }, [param?.id]);

  const formInputHandler = (value, inputName) => {
    // let currentData = { ...form };
    // currentData[inputName] = value;
    // setForm(currentData);
    setForm((currentData) => {
      return { ...currentData, [inputName]: value };
    });
  };

  const fetchData = async () => {
    setApiLoader(true);
    await dispatch(loadDropdowns());
    setApiLoader(false);
  };

  const getCmsPackageDropdownMapping = (dataArray) => {
    const res = dataArray?.map((item) => {
      return {
        label: item?.packageExtId,
        value: item?.packageId,
        extId: item?.packageExtId,
      };
    });
    // console.log(res);
    return res;
  };

  const fetchCmsPackage = async () => {
    setCmsPackageLoader(true);

    const request = {
      streamId: form?.academicGroup?.value,
      classId: form?.classes?.value,
      boardId: form?.board?.value,
      academicYearId: form?.academicYear?.value,
    };
    // console.log(request)
    const res = await fetchAllPostPromisedData(
      endpoint?.academicPlanTests?.getPackagesByCourseOrPackageId,
      request,
      "post"
    );
    // console.log(res);
    if (res?.code === 200) {
      setCmsPackageDropdown(getCmsPackageDropdownMapping(res?.data?.packages));
    } else {
      setCmsPackageDropdown([]);
    }
    setCmsPackageLoader(false);
  };

  useEffect(() => {
    fetchData();
    /* for pre-populating set this form like
     this object which comes from backen104d or create this object on click clone or copy button 
     */
    // setForm(({ "academicGroup": 1, "class": 10, "academicBoard": 15, "academicYear": 4, "academicCareer": 2, "academicPhase": 3, "classType": 2, "description": "test", "region": [{ label: "Delhi-NCR2", "value": 2 }, { label: "DELHI/NCR", "value": 3 }, { "label": "Central", "value": 4 }], "businessArea": [{ label: "SE003", "value": 257 }, { "label": "SE004", "value": 258 }, { "label": "JP072", "value": 135 }] }))
  }, []);

  // handling steam=>class

  useEffect(() => {
    if (form.academicGroup)
      getClassesByAcademicGroupId(form.academicGroup?.value).then((res) =>
        setClassDropdown(res)
      );
  }, [form.academicGroup]);

  // handling region and business area
  useEffect(() => {
    if (form?.regions?.length > 0)
      getBusinessAreas(form?.regions).then((res) =>
        setBusinessAreaDropdown(res)
      );
  }, [form.regions]);

  // this is to get packages array
  useEffect(() => {
    if (
      form?.academicGroup &&
      form?.classes &&
      form?.board &&
      form?.academicYear
    ) {
      fetchCmsPackage();
    }
  }, [form?.academicGroup, form?.classes, form?.board, form?.academicYear]);

  useEffect(() => {
    if (cmsPackageDropdown?.length > 0 && cmsPackageDropdown?.length === 1) {
      formInputHandler(cmsPackageDropdown?.[0], "packageId");
    }
  }, [cmsPackageDropdown]);

  const checkPlanExist = async (request) => {
    setPlanExitApiLoader(true);
    const response = await fetchAllPostPromisedData(
      endpoint?.createAcademicPlan?.isPlanExist,
      request,
      "post"
    );
    if (response?.code === 200) {
      // successToast(response?.message);

      if (
        response &&
        response?.data &&
        response?.data.isAcademicPlanAlreadyExist
      ) {
        setIsDuplicatePlanExist(true);
      } else createPlanRequest();
      setPlanExitApiLoader(false);
    }
  };
  const saveAcademicPlanHandler = async () => {
    const request = {
      ...updateRequest2(form, ["regions", "businessAreas"]),
      duplicatedAcademicPlanId: param?.id,
      packageExtId: form?.packageId?.label,
    };

    checkPlanExist(request);
    // console.log('final req', request)
  };
  const createPlanRequest = async () => {
    const request = {
      ...updateRequest2(form, ["regions", "businessAreas"]),
      duplicatedAcademicPlanId: param?.id,
      packageExtId: form?.packageId?.label,
    };
    setApiLoader(true);
    const response = await fetchAllPostPromisedData(
      endpoint?.createAcademicPlan?.createPlan,
      request,
      "post"
    );

    if (response?.code === 200) {
      successToast(response?.message);
      setApiLoader(false);
      history.push(
        `/admin/testScheduling/academicPlan/view/${response?.data?.id}`
      );
    }
    setApiLoader(false);
  };

  const doNotDuplicateHandler = () => {
    setOpenCmsSubjectCnf(false);
    history.goBack();
  };

  return userPermissions ? (
    apiLoader ? (
      <PageLoadingAnimation />
    ) : (
      // <div className="remove-container-top-space">
      <div className="remove-container-top-space academic-plan-container">
        {planExistApiLoader && <ALoader position="fixed" />}
        <LiftSideImage />
        <AddPlanForm
          formHandler={formInputHandler}
          dropdown={dropdowns || {}}
          classDropdown={classDropdown}
          businessAreaDropdown={businessAreaDropdown}
          form={form}
          saveAcademicPlanHandler={saveAcademicPlanHandler}
          cmsPackageDropdown={cmsPackageDropdown}
          cmsPackageLoader={cmsPackageLoader}
          isDuplicate={!!param?.id}
          validateSubjectsMismatch={validateSubjectsMismatch}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          nextHandler={nextHandler}
        />
        <CMSSubjectWarning
          open={isOpenCmsSubjectCnf}
          setOpen={setOpenCmsSubjectCnf}
          onAgree={() => setCurrentStep((prev) => prev + 1)}
          cmsSubjectMismatch={cmsSubjectMismatch}
          doNotDuplicateHandler={doNotDuplicateHandler}
        />
        <CnfDialogBox
          open={isDuplicatePlanExist}
          handleClose={() => setIsDuplicatePlanExist(false)}
          onAgree={createPlanRequest}
          metaData={{
            agreeBtnTxt: "Proceed",
            cancelBtnTxt: "Cancel",
            headerMsg:
              "Academic Plan with the selected parameters already exists. Are you sure you want to proceed?",
            helperTxt: "",
          }}
          isDuplicatePlan={false}
        />
      </div>
      // </div>
    )
  ) : (
    <PermisionDenied />
  );
};

export default CreateAcademicPlan;
