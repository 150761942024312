import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import ActionReasonSessionTable from './actionReasonTable';
import {
  masterServiceBaseUrl,
  failureToast,
  getAPI,
  putpost,
  putpost2,
  successToast,
} from 'services/http';
import Select from 'react-select';
import Loader from 'react-loader-spinner';

const AttributeRow = (props) => {
  const { isNew = true, formId, initialDate } = props;
  const [classArray, setclassArray] = useState([]);
  const [apiloader, setApiLoader] = useState(false);
  const [actionReasonData, setActionReasonData] = useState([]);

  // const fetchClasses = async (url) => {
  //   setapiloader(true)
  //   getAPI(url, (data) => {
  //     setstate(data['data'])
  //    setclassArray(data['data'])
  //   }, (data) => { failureToast(data['message']) })
  // }

  const fetchActionReasonData = () => {
    setApiLoader(true);
    getAPI(
      masterServiceBaseUrl + '/actionReason/getAllActiveActionReason',
      (data) => {
        const list = data.data.map((data) => {
          // if (data.status === "ACTIVE") {
          return {
            value: data.id,
            label: data.actionReasonDispValue,
            desc: data.actionReasonDesc,
          };
          // }
        });
        setActionReasonData(list);
        setApiLoader(false);
      },
      (data) => {
        failureToast(data['message']);
        setApiLoader(false);
      }
    );
  };
  const getAllActionReasonMaster = () => {
    setApiLoader(true);
    getAPI(
      `${masterServiceBaseUrl}/programActionReasonMapping/getProgramActionReasonMappingByProgramActionMapping/${formId}`,
      (data) => {
        setclassArray(data['data']);
        setApiLoader(false);
      },
      (data) => {
        failureToast(data['message']);
        setApiLoader(false);
      }
    );
  };

  useEffect(() => {
    fetchActionReasonData();
  }, []);

  useEffect(() => {
    if (!isNew) {
      getAllActionReasonMaster();
    }
  }, [isNew]);

  return (
    <>
      {apiloader ? (
        <div
          className="mx-auto text-center py-5 my-5"
          style={{ height: '100vh' }}
        >
          <Loader
            type="Rings"
            color="#00BFFF"
            height={70}
            width={70}
            timeout={10000}
          />
        </div>
      ) : (
        <div></div>
      )}
      <ActionReasonSessionTable
        actionReasonData={actionReasonData}
        setclassArray={setclassArray}
        fetchClasses={getAllActionReasonMaster}
        classArray={classArray}
        formId={formId}
        isNew={isNew}
        initialDate={initialDate}
      />
    </>
  );
};
export default AttributeRow;
