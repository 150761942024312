import React, {useEffect, useState} from "react";
import {Button, Card, CardHeader, Container, Input, Row, Table,} from "reactstrap";
import {failureToast} from "../../common/utils/methods/toasterFunctions/function";
// import {TDROW} from "../commonComponent/masterRow";
import {masterServiceBaseUrl, examControllerServiceBaseUrl, putpost, putpost2, successToast} from "services/http";
import moment from "moment";
import {MasterHeaderAuto} from "../../common/commonComponents/masterHeader/masterHeader";
import {getRequiredSign} from "../../common/utils/methods/commonMethods/utilityMethod";
import {RedAstric} from "../../examService/examIdGeneration/examIdSearchForm";
import CreateButton from "components/FormComponent/Button/New/index";
import UpdateButton from "components/FormComponent/Button/Update/index";
import EditButton from "components/FormComponent/Button/Edit/index";
import { ButtonText } from "variables/Buttons";

var _ = require("lodash");

const TDROW = (props) => {
    const {el, index,editRow,deleteRow} = props;
    return (
        <tr key={"ExamGroupNewOrEditRow"}>
            <td className="text-center p-2 mx-1">{el['testSequenceKey']}</td>
            <td className="text-center p-2 mx-1">{moment(el['effectiveDate']).format('DD-MM-YYYY')}</td>
            <td className="text-center p-2 mx-1">{el['testSequenceDesc']}</td>
            <td className="text-center p-2 mx-1">{el['testSequenceDispValue']}</td>
            <td className="text-center p-2 mx-1"><Button key={"statusButton"}
                                                         color={el.status === 'ACTIVE' ? "success" : "danger"}
                                                         size={'sm'}
                                                         disabled={true}>{el.status === 'ACTIVE' ? "Active" : "Inactive"}</Button>
            </td>
            <td className="text-center p-2 mx-1">
                <EditButton onClick={() => editRow(index)} />
                {/* <Button color="info" size="sm" type="button" className={' mx-1'}
                        onClick={() => editRow(index)}
                >
                    <i className="fas fa-edit"/>
                </Button> */}
                {/*<Button color="info" size="sm" type="button" className={' mx-1'}*/}
                {/*        onClick={() => deleteRow(index)}*/}
                {/*>*/}
                {/*    <i className="fas fa-trash"/>*/}
                {/*</Button>*/}
            </td>
        </tr>
    )
}
const TestSequenceTable = (props) => {
    const [isSaveVisible, setisSaveVisible] = useState(false);
    const [tableDataKey, settableDataKey] = useState("tableDataKey");
    const addNewClass = () => {
        props.setclassArray([
            {
                editMode: true,
                __status: "__new",
                testSequenceKey: null,
                status: "ACTIVE",
                testSequenceDesc: null,
                testSequenceDispValue: null,
                effectiveDate: null,
            },
            ...props.classArray,
        ]);
    };
    const saveAll = (cb) => {
        var _tempArray = props.classArray;
        var isValid = true;
        _tempArray.forEach((el, index) => {
            if (el["editMode"]) {
                // if (el["testSequenceKey"] == null ||(el["testSequenceKey"] &&  !el["testSequenceKey"].trim())) {
                //     isValid = false;
                //     failureToast("Please enter the value of Test Sequence Key");
                //     return;
                // } else
                    if (el["testSequenceDesc"] == null || (el["testSequenceDesc"] && !el["testSequenceDesc"].trim())) {
                    isValid = false;
                    failureToast("Please enter the value of Description");
                    return;
                } else if (el["testSequenceDispValue"] == null || (el["testSequenceDispValue"] && !el["testSequenceDispValue"].trim())) {
                    isValid = false;
                    failureToast("Please enter the value of Display Value");
                    return;
                }
                // else if (el["documentTag"] == null || (el["documentTag"] && !el["documentTag"].trim())) {
                //     isValid = false;
                //     failureToast("Please enter the value of Document Tag");
                //     return;
                // }
                if (!isValid) {
                    return;
                }
                if (_tempArray[index]["__status"] == "__new") {
                    putpost(examControllerServiceBaseUrl + "/testSequenceMaster/createExamTestSequence", (data) => {
                        successToast(data["message"]);
                        setisSaveVisible(false);
                        props.fetchClasses(examControllerServiceBaseUrl + "/testSequenceMaster/getAllExamTestSequence");
                    }, (data) => {
                        failureToast(data["message"]);
                    }, _tempArray[index], "post");
                } else {
                    putpost(examControllerServiceBaseUrl + "/testSequenceMaster/updateExamTestSequence/" + _tempArray[index].id, (data) => {
                            successToast(data["message"]);
                            setisSaveVisible(false);
                            delete _tempArray[index]["editMode"];
                            props.setclassArray(_tempArray);
                            settableDataKey(new Date().getMilliseconds());
                            props.fetchClasses(examControllerServiceBaseUrl + "/testSequenceMaster/getAllExamTestSequence");
                        }, (data) => {failureToast(data["message"]);}, _tempArray[index], "put"
                    );
                }
            } else {
            }
        });
    };
    const newRow = () => {
        if (isSaveVisible) {
            failureToast("Please Delete or Save current changes");
        } else {
            let cb = () => {
                addNewClass();
                setisSaveVisible(true);
                // settableDataKey(new Date().getMilliseconds())
            };
            var _tempArray = props.classArray;
            _tempArray = _.sortBy(_tempArray, [
                function (o) {
                    return o.value;
                },
            ]);
            props.setclassArray(_tempArray);
            setisSaveVisible(false);
            cb();
        }
    };
    const editRow = (index) => {
        if (isSaveVisible) {
            failureToast("Please Delete or Save current changes");
        } else {
            let cb = () => {
                var _tempArray = props.classArray;

                _tempArray[index]["editMode"] = true;
                // _tempArray[index]['documentTag'] = _tempArray[index]['documentTag'];
                props.setclassArray(_tempArray);
                setisSaveVisible(true);
            };
            var _tempArray = props.classArray;
            _tempArray = _.sortBy(_tempArray, [
                function (o) {
                    return o.value;
                },
            ]);
            cb();
        }
    };
    const deleteRow = (index) => {
        if (props.classArray[index]["__status"] == "__new") {
            let n = props.classArray;
            n.splice(index, 1);
            props.setclassArray(n);
            setisSaveVisible(false);
            settableDataKey(new Date().getMilliseconds());
        } else {
            if (window.confirm("Are you sure you want to delete this record?")) {
                putpost2(examControllerServiceBaseUrl + "/testSequenceMaster/deleteExamTestSequence/" + props.classArray[index].id, (data) => {
                    successToast(data["message"]);
                    props.fetchClasses(examControllerServiceBaseUrl + "/testSequenceMaster/getAllExamTestSequence");
                    if (props.classArray.length == 1) {
                        // props.previousPage();
                        // props.fetchClasses(masterServiceBaseUrl + "/processTag/getAllProcessTag");
                    } else {
                        var _tempArray = props.classArray;
                        if (_tempArray[index]["editMode"]) {
                            setisSaveVisible(false);
                        }
                        _tempArray.splice(index, 1);
                        props.setclassArray(_tempArray);
                    }
                    settableDataKey(new Date().getMilliseconds());
                }, (data) => {failureToast(data["message"]);}, "PUT");
            }
        }
    };
    const updateKey = (index, value, key) => {
        var _tempArray = props.classArray;
        _tempArray[index][key] = value;
        props.setclassArray(_tempArray);
    };
    useEffect(() => {
    },[props.classArray])
    const headerList = [{name: "TEST SEQUENCE KEY"}, {name: "EFFECTIVE DATE"}, {name: "DESCRIPTION"}, {name: "DISPLAY VALUE"}, {name: "STATUS"}, {name: "ACTION"}]
    return (
        <React.Fragment>
            <Container className="mt-3" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                <h3 className="mb-0 floatLeft">Master - Test Sequence</h3>
                                <CreateButton
                                    text={ButtonText.TestSequence.addNew}
                                    disabled={isSaveVisible}
                                    className={"floatRight mx-1"+(isSaveVisible?" btn-dark":null)} 
                                    onClick={() => {newRow()}}
                                    data-testid="newCourseFee"
                                />
                                {/* <Button data-testid="newCourseFee" disabled={isSaveVisible} color="info" size="sm" type="button" className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
                                        onClick={() => {newRow();}}
                                >
                                    New Test Sequence
                                </Button> */}
                                {/* {isSaveVisible ? <Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => {saveAll()}}>Save</Button>:null} */}
                            </CardHeader>
                            <div id="questionTable" className="table-responsive">
                                <Table className="align-items-center table-flush">
                                    {/*<MasterHeader type={"Course Fee Approval"} />*/}
                                    <thead className="thead-light">
                                    <tr>
                                        <th style={{ padding: "12px 4px " }} className="text-uppercase text-center white-breakSpace">
                                            TEST SEQUENCE KEY
                                        </th>
                                        <th style={{ padding: "12px 4px " }} className="text-uppercase text-center white-breakSpace">
                                            EFFECTIVE DATE
                                        </th>
                                        <th style={{ padding: "12px 4px " }} className="text-uppercase text-center white-breakSpace">
                                            DESCRIPTION <RedAstric/>
                                        </th>
                                        <th style={{ padding: "12px 4px " }} className="text-uppercase text-center white-breakSpace">
                                            DISPLAY VALUE <RedAstric/>
                                        </th>
                                        <th style={{ padding: "12px 4px " }} className="text-uppercase text-center white-breakSpace">
                                            STATUS
                                        </th>
                                        <th style={{ padding: "12px 4px " }} className="text-uppercase text-center white-breakSpace">
                                            ACTION
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="list" key={tableDataKey}>
                                    {props.classArray
                                        ? props.classArray.map((el, index) =>
                                            !el["editMode"] ?
                                                (
                                                    <TDROW type={"feeApproval"} index={index} el={el} editRow={editRow} deleteRow={deleteRow}/>
                                                )
                                                :
                                                (<tr key={index + "-class"}>
                                                        <td className="text-center p-2 mx-1">
                                                            <Input data-testid="feeApprovalKey" maxLength={50} defaultValue={el["testSequenceKey"]} placeholder="System Generated" className="tdInput" disabled={true}
                                                                   onChange={(e) => {
                                                                       updateKey(index, e.target.value, "testSequenceKey")
                                                                   }}
                                                            />
                                                        </td>
                                                        <td className="text-center p-2 mx-1">
                                                            <Input data-testid="effectiveDate" disabled={true} defaultValue={el["__status"] == "__new" ? null : moment(el["effectiveDate"]).format("DD-MM-YYYY")} placeholder="Example:01-01-2020" className="tdInput"/>
                                                        </td>
                                                        <td className="text-center p-2 mx-1">
                                                            <Input data-testid="feeApprovalDesc" maxLength={50} defaultValue={el["testSequenceDesc"]}  placeholder="Description" className="tdInput"
                                                                   onChange={(e) => {
                                                                       updateKey(index, e.target.value, "testSequenceDesc");
                                                                   }}
                                                            />
                                                        </td>
                                                        <td className="text-center p-2 mx-1">
                                                            <Input data-testid="feeApprovalDispValue" maxLength={50} defaultValue={el["testSequenceDispValue"]} placeholder="Display Value" className="tdInput"
                                                                   onChange={(e) => {
                                                                       updateKey(index, e.target.value, "testSequenceDispValue");
                                                                   }}
                                                            />
                                                        </td>
                                                        {/*<td className="text-center p-2 mx-1">*/}
                                                        {/*    <Input data-testid="documentTag" maxLength={50} defaultValue={el["documentTag"]} placeholder="Document Tag" className="tdInput"*/}
                                                        {/*           onChange={(e) => {*/}
                                                        {/*               updateKey(index, e.target.value, "documentTag");*/}
                                                        {/*           }}*/}
                                                        {/*    />*/}
                                                        {/*</td>*/}
                                                        <td className="text-center p-2 mx-1">
                                                            <label className="custom-toggle mx-auto ml-2">
                                                                <input data-testid="activeInactive" checked={el.status == "ACTIVE" ? true : false} type="checkbox" id={el.id}
                                                                       onChange={(e) => {
                                                                           settableDataKey(new Date().getMilliseconds());
                                                                           let n = "ACTIVE";
                                                                           if (!e.target.checked) {n = "INACTIVE";}
                                                                           updateKey(index, n, "status");
                                                                       }}
                                                                />
                                                                <span style={{width: "72px"}} className="custom-toggle-slider rounded-circle activeToggle" data-label-off="Inactive" data-label-on="Active"/>
                                                            </label>
                                                        </td>
                                                        <td className="text-center">
                                                            {/* <Button color="info" size="sm" type="button" onClick={() => {
                          deleteRow(index)
                        }}>  <i className="fas fa-trash" /></Button> */}
                                                            {isSaveVisible ? (
                                                                <>
                                                                    {" "}
                                                                    <UpdateButton text={ButtonText.TestSequence.save} onClick={() => { saveAll() }} data-testid="savefee"/>
                                                                    {/* <Button data-testid="savefee" color="info" size="sm" type="button" className="mx-1"
                                                                            onClick={() => {
                                                                                saveAll();
                                                                            }}
                                                                    >
                                                                        Save
                                                                    </Button> */}
                                                                    <Button data-testid="closeApproval" color="danger" size="sm" type="button"
                                                                            onClick={() => {
                                                                                let n = props.classArray;
                                                                                if (n[index]["__status"] == "__new") {
                                                                                    n.splice(index, 1);
                                                                                } else {
                                                                                    // n[index]['editMode']=false

                                                                                    props.fetchClasses(examControllerServiceBaseUrl + '/testSequenceMaster/getAllExamTestSequence')
                                                                                    // props.fetchClasses(masterServiceBaseUrl + "/processTag/getAllProcessTag");
                                                                                }
                                                                                props.setclassArray(n);
                                                                                setisSaveVisible(false);
                                                                            }}
                                                                    >
                                                                        {" "}
                                                                        <i className="fas fa-times"/>
                                                                    </Button>{" "}
                                                                </>
                                                            ) : (
                                                                <Button data-testid="deletefee" color="info" size="sm" type="button"
                                                                        onClick={() => {
                                                                            deleteRow(index);
                                                                        }}
                                                                >
                                                                    {" "}
                                                                    <i className="fas fa-trash"/>
                                                                </Button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                        )
                                        : null}
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div>
                </Row>
            </Container>
        </React.Fragment>
    );
};
export default TestSequenceTable;
