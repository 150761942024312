import React, { useState, useEffect } from "react";
import CashierOfficeTable from './viewInstallment';
import Loader from 'react-loader-spinner'
import { masterServiceBaseUrl, failureToast, getAPI } from 'services/http';
import { Card, Container, Row ,Col,Button} from "reactstrap";
// import { GetPagination } from '../../../commonComponent/pagination';
import { PaginationNode, PaginationPage, GetPagination } from '../../../common/commonComponents/pagination/pagination'
import axios from 'axios'

const ViewInstallment = (props) => {
  const { id }  = props.match.params;
  const {
  businessUnit,
  nextStep = () => {},
  previousStep = () => {},
  isNew = true,
  formId,
  isViewOnly = false,
} = props;


  const fileName = "myfile"; // here enter filename for your excel file
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [isNextDisable, setIsNextDisable] = useState(false);

  const [classArray, setclassArray] = useState([]);
  const [courseYearData, setCourseYearData] = useState([]);
  const [paymentScheduleData, setPaymentScheduleData] = useState({})
  const [termData, setTermData] = useState([]);
  const [cashierOffice, setCashierOffice] = useState([]);
  const [businessGroup, setBusinessGroup] = useState([]);
  const [comapnyCodeData, setComapnyCodeData] = useState([]);
  const [courseDetails, setCourseDetails] = useState([]);

  const [businessArea, setBusinessArea] = useState([]);


  const [apiloader, setapiloader] = useState(false)
  const [state, setstate] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null
  })
  const pageSize = 10
  const fetchClasses = async (url) => {
    // setapiloader(true)
    getAPI(url, (data) => {
      data['data'].length > 0 ? setIsNextDisable(true) :  setIsNextDisable(false)
      setstate(data['data'])
      setTableData(data['data']);
      let n = pagination
      if (data['data'].length > pageSize) {
        n['nextPage'] = n.currentPage + 1
      }
      if (n.currentPage > 1) {
        n.previousPage = n.currentPage - 1
      }
      n['totalPage'] = Math.floor(data['data'].length / pageSize)
      if (data['data'].length % pageSize != 0) {
        n['totalPage'] += 1
      }
      setpagination(n)
      // setapiloader(false)
      setclassArray(data['data'].slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || [])
    }, (data) => { failureToast(data['message']) })
  }
  const previousPage = () => {
    if (pagination.currentPage != 1) {
      setclassArray(state.slice((pagination.currentPage - 1) * (pageSize) - pageSize, (pagination.currentPage - 1) * pageSize))
      let n = pagination
      n['currentPage'] = n['currentPage'] - 1
      if (n['currentPage'] == 1) {
        n['previousPage'] = null
      } else {
        n['previousPage'] = n.currentPage
      }
      setpagination(n)
    }
  }
  const fetchBusinessArea = async (url) => {
    setapiloader(true);
    getAPI(
      url,
      (data) => {
        setBusinessArea(data.data);
        data.data.length > 0 && setapiloader(false);
      },
      (data) => {
        failureToast(data['message']);
      }
    );
  };

  const fetchData = async (url, setState) => {
    // setapiloader(true);
    getAPI(
      url,
      (data) => {
        setState(data.data);
        // data.data.length > 0 && setapiloader(false);
      },
      (data) => {
        failureToast(data['message']);
      }
    );
  };

  useEffect(() => {
    fetchBusinessArea(
      masterServiceBaseUrl + '/businessArea/getAllActiveBusinessArea',
      'businessAreaDispValue'
    );
    fetchClasses(masterServiceBaseUrl + '/setupInstalments/searchSetupInstalments/'+ id);
    fetchData(masterServiceBaseUrl + '/payment/schedule/'+ id,setPaymentScheduleData);
    fetchData(masterServiceBaseUrl + '/courseYear/getAllCourseYear',setCourseYearData);
    fetchData(masterServiceBaseUrl + '/groupCode/getAllActiveGroupCode',setBusinessGroup);
    fetchData(masterServiceBaseUrl + '/companyCode/getAllActiveCompanyCode',setComapnyCodeData);
    fetchData(masterServiceBaseUrl + '/term/getAllActiveTerm',setTermData);
    fetchData(masterServiceBaseUrl + '/cashierOffice/getActiveCashierOffice',setCashierOffice);
    fetchData(masterServiceBaseUrl + '/courseDetail/getAllCourseDetail',setCourseDetails);
  }, [])

  return (
    <>
      {apiloader ? 
      <div className="mx-auto text-center py-5 my-5" style={{ height: "100vh" }}><Loader
        type="Rings"
        color="#00BFFF"
        height={70}
        width={70}
        timeout={10000} /></div> : <div></div>
      }
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card className='p-0 mb-0'>
                <CashierOfficeTable 
                    apiData={classArray} 
                    fileName={fileName}
                    previousPage={previousPage} 
                    fetchClasses={fetchClasses} 
                    setclassArray={setclassArray} 
                    classArray={classArray}
                    tableData={tableData} 
                    pagination={pagination} 
                    courseYearData={courseYearData}
                    paymentScheduleData={paymentScheduleData}

                    setstate={setstate} 
                    businessArea={businessArea}
                    businessGroup={businessGroup}
                    comapnyCodeData={comapnyCodeData}
                    termData={termData} 
                    cashierOffice={cashierOffice} 
                    courseDetails={courseDetails}
                    setpagination={setpagination} 
                    setisSaveVisible={setisSaveVisible}
                    isSaveVisible={isSaveVisible}
                  />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
export default ViewInstallment;
