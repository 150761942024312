import { useEffect, useState } from "react";
import {
  dropdownMapping,
  getBatch,
  getPlanListModel,
  getPlanTests,
  getRooms,
  getTimetable,
  updateTestData,
  updateTimetableTests,
  updateTimetableLectures,
  getFaculty,
  getSubjectsFacultyDropdown,
  autoPopulateLectures,
  getDaysPlan,
  dropdownMappingV2,
  getAutoPopulateRequest,
  removeBreaksFromEnd,
  isCallAutoPopulateApi,
  dropdownRoomMapping,
  getTestsThatNotExist,
  getPastTests,
  getLabelWithDescription,
  getMissedTests,
  isDatePassed,
} from "./helper";
import { getPlanListForDropdown } from "redux/academicPlan/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getCoursesByPackageId } from "../academicTest/style";
import { getLectures } from "./../lecture/helper";
import {
  fetchAllPostPromisedData,
  fetchAllPromisedData,
} from "../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint } from "../../common/constant";
import { updateMsTeam } from "./lectureModule/PlanLectures";
import moment from "moment";
import {
  getStudentApplications,
  getStudentsByPtmId,
} from "../../edpOps/issuanceIDcard/service";

const sortLectures = (lecturesArr) => {
  if (Array.isArray(lecturesArr) && lecturesArr?.length > 1) {
    return lecturesArr?.sort((a, b) => new Date(a?.date) - new Date(b?.date));
  }
  return lecturesArr;
};

export const useFetchPlansByBatch = (batchDetails) => {
  const plansDropdown = useSelector(
    (state) => state.academicPlan.academicPlanList || []
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (batchDetails?.batchName)
      dispatch(getPlanListForDropdown(getPlanListModel(batchDetails)));
  }, [batchDetails]);

  return dropdownMapping(plansDropdown, "academicPlanName", true);
};

const getEndDate = (date) => {
  // is future date
  let isAfter = new Date(date) > new Date();

  if (isAfter) return new moment(date).endOf("day");
  if (moment(date).isBefore(new Date())) return new moment(date).endOf("day");

  return new moment(new Date()).endOf("day");
};

export const filterPastTest = (tests = []) => {
  console.log(
    tests.filter(
      (test) => !isDatePassed(test?.endDate || test?.testScheduleEndDate)
    )
  );
  return tests.filter(
    (test) => !isDatePassed(test?.endDate || test?.testScheduleEndDate)
  );
};
// if end date is today then skip the online test
const filterOnlineTest = (tests) => {
  return tests.filter((test) => {
    if (test.mode.toLowerCase() === "offline") return true;
    if (new Date(test.endDate) > new Date()) return true;
    return false;
  });
};

export const useFetchAutoPopulatedLectures = ({
  form,
  businessAreaId,
  batchId,
  subjectForm,
  setLectures,
  isSyncWithMsTeam,
}) => {
  const requestForAutoPopulate = getAutoPopulateRequest(
    form,
    businessAreaId,
    batchId,
    subjectForm
  );

  useEffect(() => {
    if (isCallAutoPopulateApi(form, businessAreaId, batchId))
      autoPopulateLectures(requestForAutoPopulate).then((res) => {
        setLectures(
          removeBreaksFromEnd(updateMsTeam(res?.data, isSyncWithMsTeam))
        );
      });
  }, [form, businessAreaId, subjectForm, batchId]);
};
export const useFetchPlanTest = (formData, formOpenMode, batchId, setTests) => {
  // const [tests, setTests] = useState([]);
  const [planDetails, setPlanDetails] = useState({});
  const { plan, startFrom, endOn } = formData;

  useEffect(() => {
    if (formOpenMode === "NEW" && startFrom && endOn && plan) {
      getPlanTests(formData).then((res) => {
        setTests(updateTestData(res?.data?.searchTestScheduleDtoList || []));
      });
    }
  }, [formData?.plan?.value, batchId, startFrom, endOn, formOpenMode]);

  return [planDetails, setPlanDetails];
};

const createPlanDataObject = (response, firstLecture, roomsDropdown) => {
  return {
    timeTableState: response.timeTableState,
    timetableId: response?.id,
    publishStatus: response?.publishStatus,
    startFrom: response.startDate,
    endOn: response.endDate,
    plan: {
      value: response.academicPlanId,
      label: getLabelWithDescription(
        response.academicPlanName,
        response?.academicPlanDescription
      ),
      packageId: response?.packageId,
    },
    room: {
      value:
        response?.room ||
        (roomsDropdown?.length > 0 && roomsDropdown[0]?.value),
      label:
        response?.roomDisplayValue ||
        (roomsDropdown?.length > 0 && roomsDropdown[0]?.label),
    },
    duration: {
      value: response?.duration,
      // label: firstLecture?.roomResponse?.disValue,
    },
    selectedFaculty: response?.selectedFaculty,
    daysPlan: {
      value: response?.daysPlanId,
      label: response?.daysPlanName,
    },
  };
};
const filterBreakRows = (lectures) =>
  lectures.filter((item) => item.lectureId > 0);

export const useFetchPublishedTimetable = (
  batchId,
  setLoading = () => {},
  mode,
  roomsDropdown
) => {
  const [tests, setTests] = useState([]);
  const [planDetails, setPlanDetails] = useState({});
  const [lectures, setLectures] = useState([]);

  useEffect(() => {
    if (batchId) {
      setLoading(true);
      // fetch published timetable for a batch ;
      fetchPublishedTimetable(batchId);
      setLoading(false);
    }
  }, [batchId, roomsDropdown]);

  const fetchPublishedTimetable = async (
    batchId,
    lecturesFromAutoPopulate = []
  ) => {
    try {
      const response = await getTimetable(batchId);
      const pastTestResponse = await getPastTests(batchId);
      let pastTests = [];
      if (pastTestResponse && pastTestResponse?.code === 200)
        pastTests = pastTestResponse?.data?.batchTimeTableResponse;
      if (response) {
        const firstLecture = filterBreakRows(response?.lectureTimeTable)[0];
        const planDetailsObj = createPlanDataObject(
          response,
          firstLecture,
          roomsDropdown
        );
        // call only if mode is edit ;
        // const onlyPlanTest = getOnlyPlanTests(response);
        const acdPlanTestsResponse = await getPlanTests({
          ...planDetailsObj,
          // endOn: getEndDate(new moment(planDetailsObj?.endOn).endOf("day")),
          endOn: getEndDate(planDetailsObj?.endOn),
          //startFrom: new moment(moment().format("MM/DD/YYYY")),
          startFrom: response?.startDate,
        });
        const testsInAcdPlan = filterOnlineTest(
          filterPastTest(
            updateTestData(
              acdPlanTestsResponse?.data?.searchTestScheduleDtoList || []
            )
          )
        );

        // if tests count in acdPlan is greater then  timetable tests count
        let testsThatNotExist = getTestsThatNotExist(
          testsInAcdPlan,
          response.testTimeTable,
          pastTests
        );

        setTests((tests) => updateTimetableTests(response.testTimeTable));
        setTests((tests) => [...testsThatNotExist, ...tests]);
        setLectures(
          sortLectures(
            updateTimetableLectures([
              ...response.lectureTimeTable,
              ...lecturesFromAutoPopulate,
            ])
          )
        );
        setPlanDetails(planDetailsObj);
      }
    } catch (error) {
      console.log("error while calling published timetable api", error);
    }
  };

  return [
    tests,
    setTests,
    lectures,
    setLectures,
    planDetails,
    setPlanDetails,
    fetchPublishedTimetable,
  ];
};

export const useFetchBatch = (batchId, setLoading = () => {}) => {
  const [batchDetails, setBatchDetails] = useState({});

  useEffect(() => {
    if (batchId) {
      setLoading(true);
      getBatch(batchId)
        .then((res) => {
          setBatchDetails(res);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [batchId]);

  return batchDetails;
};

export const useFetchPlanSubjects = (planPackageId) => {
  const [subjects, setSubjects] = useState([]);
  const [subjectsFormField, setSubjectsFormField] = useState({});

  useEffect(() => {
    if (planPackageId) {
      getCoursesByPackageId(planPackageId).then((res) => {
        let courses = res?.data?.packages?.[0]?.courses;
        if (courses) {
          setSubjects(res?.data?.packages?.[0]?.courses);
          let formField = courses.reduce((acc, item) => {
            acc[item["subjectName"]] = "";
            return acc;
          }, {});
          setSubjectsFormField(formField);
        }
      });
    }
  }, [planPackageId]);

  return [subjects, subjectsFormField];
};

export const useFetchRooms = (businessAreaId) => {
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    if (businessAreaId)
      getRooms(businessAreaId).then((res) => {
        setRooms(dropdownRoomMapping(res || [], "roomName"));
      });
  }, [businessAreaId]);

  return rooms;
};

export const useFetchFacultyDropdown = (businessAreaId) => {
  const [subjectFaculty, setSubjectFaculty] = useState([]);

  useEffect(() => {
    if (businessAreaId)
      getFaculty(businessAreaId).then((res) => {
        setSubjectFaculty(getSubjectsFacultyDropdown(res || []));
      });
  }, [businessAreaId]);

  return subjectFaculty;
};

export const useFetchDaysPlanDropdown = (businessAreaId) => {
  const [daysPlan, setDaysPlan] = useState([]);

  useEffect(() => {
    if (businessAreaId)
      getDaysPlan(businessAreaId).then((res) => {
        if (res?.data)
          setDaysPlan(
            dropdownMapping(
              res?.data?.daysPlanMasterSearchResponseDTOList,
              "daysPlanName"
            )
          );
      });
  }, [businessAreaId]);

  return daysPlan;
};

export const addLectureText = (lectures) =>
  lectures.map((lecture) => ({
    ...lecture,
    label: `Lecture ${lecture.label}`,
  }));

export const useFetchSubjectLectureDropdown = (
  academicPlanId,
  duration,
  subject
) => {
  const [lecturesDropdown, setLectureDropdown] = useState([]);
  useEffect(() => {
    if (academicPlanId && duration && subject)
      getLectures(academicPlanId, subject, duration).then((res) => {
        if (res)
          setLectureDropdown(
            addLectureText(dropdownMappingV2(res['lectureAcademicPlanMappingList'], "lectureNumber"))
          );
      });
  }, [subject]);

  return lecturesDropdown;
};

export const useGetAllLecturesCount = (planId, duration) => {
  const [allLectureCount, setAllLectureCount] = useState(0);

  useEffect(() => {
    if (duration && planId)
      getLectures(planId, null, duration).then((res) => {
        setAllLectureCount(res['lectureAcademicPlanMappingList']?.length);
      });
  }, [planId, duration]);

  return allLectureCount;
};
//
export const useGetPlanLecturesByDuration = (planId, duration) => {
  const [lectures, setLectures] = useState([]);

  useEffect(() => {
    if (duration && planId)
      getLectures(planId, null, duration).then((res) => {
        setLectures(res);
      });
  }, [planId, duration]);

  return lectures;
};

export const useGetPlanByPlanId = (planId) => {
  const [planData, setPlanData] = useState({});
  const fetchPlanData = async () => {
    const res = await fetchAllPromisedData(
      `${endpoint?.viewAcademicPlan?.getPlanById}/${planId}?requireDispValue=false`,
      true
    );
    if (res?.code === 200) {
      setPlanData(res?.data);
    } else {
      setPlanData({});
    }
  };

  useEffect(() => {
    if (planId) fetchPlanData();
  }, [planId]);

  return planData;
};

export const useGetPastEvent = (batchId, offset = 0, eventName = "test") => {
  const [pastEventData, setPastEvent] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const fetchEventData = async () => {
    try {
      const url =
        eventName === "test"
          ? `${endpoint?.timetable?.getPastTest}/${batchId}?offSet=${offset}&requireDispValue=true`
          : `${endpoint?.timetable?.getPastLecture}/${batchId}?offSet=${offset}&requireDispValue=true`;

      setLoading(true);
      const res = await fetchAllPostPromisedData(url, true);
      if (res?.code === 200) {
        if (eventName === "test") {
          setPastEvent(updateTimetableTests(res?.data?.batchTimeTableResponse));
        } else {
          setPastEvent(
            updateTimetableLectures(res?.data?.batchTimeTableResponse)
          );
        }
        setTotalPage(res?.data?.totalPage);
        setTotalRecords(res?.data?.totalRecords);
        setLoading(false);
      } else {
        setPastEvent({});
        setLoading(false);
        setTotalPage(0);
        setTotalRecords(0);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (batchId) fetchEventData();
  }, [offset]);

  return [pastEventData, isLoading, totalPage, totalRecords];
};

const formateResponse = (item) => ({
  psid: item.psid,
  value: `${item.studentName} | ${item.psid} | ${item.applicationId}`,
  studentName: item.studentName,
  applicationId: item.applicationId,
  id: item?.id,
});

export const useFetchAllocatedStudent = (batchId) => {
  const [dropdown, setDropdown] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const request = {
        batchId,
        allocationStatus: "ALLOCATE",
      };
      const response = await getStudentApplications(request, -1);
      if (response?.code === 200) {
        const updatedResponse =
          response.data.studentApplicationDetailsSearchResponseDTOList.map(
            formateResponse
          );
        setDropdown(updatedResponse);
        setLoading(false);
      } else {
        setDropdown([]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (batchId) fetchData();
  }, [batchId]);

  return [dropdown, isLoading];
};
// fetch student mapped into PTM by psid
export const useFetchMappedStudent = (ptmId) => {
  const [dropdown, setDropdown] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getStudentsByPtmId(ptmId);
      if (response?.code === 200) {
        setDropdown(response.data?.map(formateResponse));
        setLoading(false);
      } else {
        setDropdown([]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ptmId) fetchData();
  }, [ptmId]);

  return [dropdown, isLoading];
};

export const useGetMissedTests = (batchId) => {
  const [missedTests, setMissedTests] = useState([]);

  const fetchEventData = async () => {
    try {
      const res = await getMissedTests(batchId);
      if (res?.code === 200) {
        setMissedTests(updateTimetableTests(res?.data?.testTimeTable));
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (batchId) fetchEventData();
  }, [batchId]);

  return missedTests;
};
