import React, {Fragment, useEffect, useState} from 'react';
import {Button, Col, Row} from "reactstrap";

const RelatedDocument =(props)=>{

    const {action,studentId,uploadDocument,documentData,button
    } = props;


    return(
        <div className='container-fluid'>
            <Row>
                <Col md={3}> </Col>
                <Col md={3}>
                    <Button color="info" size="sm" className="mx-1" style={{width:'100%'}} disabled={!documentData}
                            onClick={()=>uploadDocument('view')}
                    >
                        {button.view}
                    </Button>
                </Col>
                <Col md={3}>
                    <Button color="info" size="sm"
                            disabled={action ==='view' && !studentId }
                            className="mx-1" style={{width:'100%'}}
                            onClick={()=>uploadDocument('edit')}
                    >
                        {button.upload}
                    </Button>
                </Col>
                <Col md={3}> </Col>
            </Row>
        </div>
    )
}
export default RelatedDocument;
