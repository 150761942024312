import React from "react";
import {
  Card,
  CardHeader,
  Input,
  Container,
  Button,
  Table,
  Row,
} from "reactstrap";
import { useState } from "react";
import Select2 from "react-select2-wrapper";
import { CustomUpload, Dialog, GetTemplate, ToExcel } from '../../common/commonComponents';
import { failureToast } from "../../common/utils/methods/toasterFunctions/function";
import { LESSONPLAN, MasterHeaderLessonPlan } from "../../common/commonComponents/masterRow/masterRow";
import CustomDatePicker from '../../common/commonComponents/CustomDatePicker/index';
import { masterServiceBaseUrl, successToast, putpost, putpost2 } from "services/http";
import moment from "moment";
import './lessonPlan.css'
import { endpoint } from "../../common/constant";
import { getUniqueKey } from "../../common/utils/methods/commonMethods/utilityMethod";
var _ = require("lodash");

const LessonPlanTable = (props) => {
  const {
    groupCodeData,
    companyCodeData,
    zoneData,
    regionData,
    businessArea,
    academicCareer,
    academicGroup,
    course,
    term,
    academicYear,
    academicPhase,
    board,
    subject,
  } = props;
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [tableDataKey, settableDataKey] = useState("tableDataKey");
  const [validationDate, setValidationDate] = useState(null);
  // const [isModalVisible, setModalVisible] = useState(false);
  // const [isFileValid, setISFileValid] = useState(false);
  // const [filename, setFileName] = useState("Drag & Drop to Upload");
  // const [file, setFile] = useState(null);
  // const [progressBarStatus, setProgressBarStatus] = useState(0);
  // const [isUploading, setISUploading] = useState(false);

  const addNewClass = () => {
    props.setclassArray([
      {
        "editMode": true,
        "__status": "__new",
        "chapterId": null,
        "topicId": null,
        "subTopicId": null,
        "subSubTopicId": null,
        "noOfHours": null,
        "sequence": null,
        "meetingTypeId": null,
        "periodTypeId": null,
        "hoPlanStartDate": null,
        "hoPlanEndDate": null,
        "status": "ACTIVE",
      }, ...props.classArray])
  }

  const saveAll = () => {
    var _tempArray = props.classArray
    var isValid = true
    _tempArray.forEach((el, index) => {
      
      if (el['editMode']) {
        if (el['chapterId'] == null) {
          isValid = false
          failureToast('Please enter the value of Chapter')
          return
        }
        else if (el['topicId'] == null ) {
          isValid = false
          failureToast('Please enter the value of Topic')
          return
        }
        else if (el['subTopicId'] == null ) {
          isValid = false
          failureToast('Please enter the value of Sub Topic')
          return
        }
        else if (el['subSubTopicId'] == null ) {
          isValid = false
          failureToast('Please enter the value of Sub Sub Topic')
          return
        }
        else if (el['noOfHours'] == null ) {
          isValid = false
          failureToast('Please enter the value of No Of Hours')
          return
        }
        else if (!String(el['noOfHours']).match(/([1-9][0-9]*)|0/)) {
          isValid = false
          failureToast('Please enter numeric value of No Of Hours')
          return
        }
        else if (el['sequence'] == null) {
          isValid = false
          failureToast('Please enter the value of Sequence')
          return
        }
        else if (!String(el['sequence']).match(/([1-9][0-9]*)|0/)) {
          isValid = false
          failureToast('Please enter numeric value of Sequence')
          return
        }
        else if (el['meetingTypeId'] == null ) {
          isValid = false
          failureToast('Please enter the value of Meeting Type')
          return
        }
        else if (el['periodTypeId'] == null ) {
          isValid = false
          failureToast('Please enter the value of Period Type')
          return
        }
        else if (el['hoPlanStartDate'] == null ) {
          isValid = false
          failureToast('Please enter the value of Ho Plan Start Date')
          return
        }
        else if (el['hoPlanEndDate'] == null ) {
          isValid = false
          failureToast('Please enter the value of Ho Plan End Date')
          return
        }
        else if (!moment(el['hoPlanEndDate']).isSameOrAfter(el['hoPlanStartDate'])) {
          isValid = false
          failureToast('Please enter the correct value of Ho Plan End Date')
          return
        }
        else if (el['status'] == null ) {
          isValid = false
          failureToast('Please enter the value of Status')
          return
        }

        if (!isValid) {
          return
        }
        if (_tempArray[index]['__status'] == "__new") {
          const body={
            ..._tempArray[index],
            groupCodeId: props.headers.businessGroup?.value,
            companyCodeId: props.headers.companyCode?.value,
            zoneId: props.headers.zone?.value,
            regionId: props.headers.region?.value,
            businessAreaId: props.headers.businessArea?.value,
            academicCareerId:props.headers.academicCareer?.value,
            academicGroupId:props.headers.academicGroup?.value,
            courseId:props.headers.course?.value,
            termId:props.headers.term?.value,
            academicYearId:props.headers.academicYear?.value,
            academicPhaseId:props.headers.academicPhase?.value,
            boardId:props.headers.board?.value,
            subjectId:props.headers.subject?.value
         }
          putpost(masterServiceBaseUrl + '/lessonPlanMaster/createLessonPlanMaster', (data) => {
            successToast(data['message'])
            props.searchLessonPlan();
            setisSaveVisible(false)
          }, (data) => { failureToast(data['message']) }, body, 'post')
        } else {
          putpost(masterServiceBaseUrl + '/lessonPlanMaster/updateLessonPlanMaster/' + _tempArray[index].id, (data) => {
            successToast(data['message'])
            setisSaveVisible(false)
            delete _tempArray[index]['editMode']
            props.setclassArray(_tempArray)
            settableDataKey(new Date().getMilliseconds())
          }, (data) => { failureToast(data['message']) }, _tempArray[index], 'put')
        }
      } else {

      }
    })
  }

  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]["editMode"] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };
  const deleteRow = (index) => {
    if (props.classArray[index]["__status"] == "__new") {
      let n = props.classArray;
      n.splice(index, 1);
      props.setclassArray(n);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
    } else {
      if (window.confirm("Are you sure you want to delete this Lesson Plan?")) {
        putpost2(
          masterServiceBaseUrl + "/lessonPlanMaster/deleteLessonPlanMaster/" + props.classArray[index].id,
          (data) => {
            props.searchLessonPlan();
            successToast(data["message"]);
            if (props.classArray.length == 1) {
              props.previousPage();
            } else {
              var _tempArray = props.classArray;
              if (_tempArray[index]["editMode"]) {
                setisSaveVisible(false);
              }
              _tempArray.splice(index, 1);
              props.setclassArray(_tempArray);
            }
            settableDataKey(new Date().getMilliseconds());
          },
          (data) => {
            failureToast(data["message"]);
          },
          "PUT"
        );
      }
    }
  };
  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    if (key === 'hoPlanStartDate') {
			setValidationDate(value);
		}
    props.setclassArray(_tempArray);
  };


  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes")
    }
    else {
      let cb = () => {
        addNewClass()
        setisSaveVisible(true)
      }
      var _tempArray = props.classArray
      _tempArray = _.sortBy(_tempArray, [function (o) { return o.value; }])
      props.setclassArray(_tempArray)
      setisSaveVisible(false)
      cb()
    }
  }

  const excelDetails = {
    "Business Group":"businessGroup",
    "Company Code":"companyCode",
    "Zone":"zone",
    "Region":"region",
    "Business Area":"businessArea",
    "Academic Career":"academicCareer",
    "Academic Group":"academicGroup",
    "Course":"course",
    "Term":"term",
    "Academic Year":"academicYear",
    "Academic Phase":"academicPhase",
    "Board":"board",
    "Subject":"subject",    
    "Chapter": "chapter",
    "Topic": "topic",
    "Sub Topic": "subTopic",
    "Sub Sub Topic": "subSubTopic",
    "No. OF Hours": "noOfHours",
    "Sequence": "sequence",
    "Meeting Type": "meeting",
    "Test Mode": "testMode",
    "HO Plan Start Date": "HOPlanStartDate",
    "HO Plan End Date": "HOPlanEndDate",
    "Status": "status",
  };

  const getNameById = (id, list) => {
    const item = list && list.filter((item) => {
      return item && item.id === id;
    });
    const name = item && item.length ? item[0].text : '';
    return name;
  };

  const getLabelById = (id, list) => {
    const item = list && list.filter((item) => {
      return item && item.id === id;
    });
    const name = item && item.length ? item[0].label : '';
    return name;
  };

  const getLabelByValue = (value, list) => {
    const item = list && list.filter((item) => {
      return item && item.value === value;
    });
    const name = item && item.length ? item[0].label : '';
    return name;
  };

  const dataToExport = props.excelData?.map((item) => {
    return {
      businessGroup:getLabelById(props.headers.businessGroup.value,groupCodeData),
      companyCode:getLabelById(props.headers.companyCode.value,companyCodeData),
      zone:getLabelById(props.headers.zone.value,zoneData),
      region: getLabelById(props.headers.region.value,regionData),
      businessArea: getLabelById(props.headers.businessArea.value,businessArea),
      academicCareer: getLabelById(props.headers.academicCareer.value,academicCareer),
      academicGroup: getLabelById(props.headers.academicGroup.value,academicGroup),
      course: getLabelById(props.headers.course.value,course),
      term: getLabelById(props.headers.term.value,term),
      academicYear: getLabelByValue(props.headers.academicYear.value,academicYear),
      academicPhase: getLabelById(props.headers.academicPhase.value,academicPhase),
      board: getLabelById(props.headers.board.value,board),
      subject: getLabelById(props.headers.subject.value,subject),
      chapter: getNameById(item.chapterId, props.chapterData),
      topic: getNameById(item.topicId, props.topicData),
      subTopic: getNameById(item.subTopicId, props.subTopicData),
      subSubTopic: getNameById(item.subSubTopicId, props.subSubTopicData),
      noOfHours: item.noOfHours,
      sequence: item.sequence,
      meeting: getNameById(item.meetingTypeId, props.meetingData),
      testMode: getNameById(item.periodTypeId, props.testMode),
      HOPlanStartDate: moment(item.hoPlanStartDate).format("DD-MM-YYYY"),
      HOPlanEndDate: moment(item.hoPlanEndDate).format("DD-MM-YYYY"),
      status: item.status
    };
  });

  // const handleFile = (e) => {
  //   try {
  //     if (e.name.split(".")[1] === "csv") {
  //       setFileName(e.name);
  //       setFile(e);
  //       setISFileValid(true);
  //     } else {
  //       failureToast("Only csv files are allowed");
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // const dataToServer = {
  //   bulk: true,
  //   fileName: "Lesson Plan Data",
  //   docType: "csv",
  //   keyValue: `lessonplan_${getUniqueKey()}`,
  //   serviceName: "MASTER",
  //   subProcessType: "LESSONPLAN"
  // };

  // const saveFileAndReturnData = (data) => {
  //   console.log("success data", data)
  //   putpost(
  //     `${masterServiceBaseUrl}/lessonPlanMaster/createBulkLessonPlanMaster?csvUrl=${data?.Location}&processInstanceId=${data?.processId}`,
  //     (data) => {
  //       setProgressBarStatus(100);
  //       successToast("File Uploaded Successfully");
  //       setModalVisible(!isModalVisible);
  //       reset();
  //     },
  //     (data) => {
  //       failureToast("Uploading Failed");
  //     },
  //     {},
  //     "post"
  //   );
  // };

  // const reset = () => {
  //   setISFileValid(false);
  //   setFileName("Drag & Drop to Upload");
  //   setFile(null);
  //   setProgressBarStatus(0);
  //   setISUploading(false);
  // };

  return (
    <>
      <Container className="mt-3" fluid>
      {/* <Dialog
          title="Lesson Plan Bulk Upload"
          isModalVisible={isModalVisible}
          toggle={() => {
            setModalVisible(!isModalVisible);
            reset();
          }}
        >
          <CustomUpload
            documentType="Lesson Plan"
            isFileValid={isFileValid}
            filename={filename}
            file={file}
            progressBarStatus={progressBarStatus}
            setISFileValid={setISFileValid}
            setFileName={setFileName}
            setFile={setFile}
            setProgressBarStatus={setProgressBarStatus}
            isUploading={isUploading}
            setISUploading={setISUploading}
            handleFile={handleFile}
            dataToServer={dataToServer}
            saveHandler={saveFileAndReturnData}
          />
        </Dialog> */}
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0 d-flex justify-content-between">
                <h3 className="mb-0 mr-2 floatLeft">Lesson Plan Master</h3>
                <div className="d-flex flex-row justify-content-between align-items-center">
                {/* {!isSaveVisible && props.classArray?.length ? */}
                  <div className={"mx-1"}>
                    <ToExcel
                      data={dataToExport}
                      detailObj={excelDetails}
                      name="Item Type Setup"
                      isSaveVisible={isSaveVisible}
                    /> 
                  </div>
                  {/* : null} */}
                      {/* <GetTemplate url={endpoint.lessonPlanTemplate} className={'mx-2'}/>
                      <Button color="info" size="sm" type="button" className={"floatRight mx-2"} onClick={() => setModalVisible(true)} >
                        <i className="fas fa-upload" />&nbsp;&nbsp;Upload CSV
                      </Button> */}
                      <Button disabled={isSaveVisible} color="info" size="sm" type="button" className={"floatRight " + (isSaveVisible ? " btn-dark" : null)}
                       onClick={() => { newRow() }}>
                      <i className="fas fa-plus" />&nbsp;&nbsp;Add New Lesson Plan</Button>
                  </div>
              </CardHeader>
              <div className="table-responsive">
                <Table className="table tableLessonPlan align-items-center table-flush tableLayout">
                  <MasterHeaderLessonPlan />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                        !el["editMode"] ? (
                          <LESSONPLAN
                            key={index}
                            type={"lessonPlan"}
                            index={index}
                            el={el}
                            editRow={editRow}
                            deleteRow={deleteRow}
                            chapterData={props.chapterData}
                            topicData={props.topicData}
                            subTopicData={props.subTopicData}
                            subSubTopicData={props.subSubTopicData}
                            meetingData={props.meetingData}
                            testMode={props.testMode}
                          />
                        ) : (
                          <tr key={index + "-class"}>
                            <td className="text-center p-2 mx-1">
                              <Select2
                                defaultValue={el['chapterId']}
                                data={props.chapterData}
                                onChange={(e) => { updateKey(index, Number(e.target.value), 'chapterId') }}
                                options={{ placeholder: "Select" }}
                                className="tdSelect"
                              />

                            </td>
                            <td className="text-center p-2 mx-1">
                              <Select2
                                defaultValue={el['topicId']}
                                data={props.topicData}
                                onChange={(e) => { updateKey(index, Number(e.target.value), 'topicId') }}
                                options={{ placeholder: "Select" }}
                                className="tdSelect"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <Select2
                                defaultValue={el['subTopicId']}
                                data={props.subTopicData}
                                onChange={(e) => { updateKey(index, Number(e.target.value), 'subTopicId') }}
                                options={{ placeholder: "Select Sub" }}
                                className="tdSelect"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <Select2
                                defaultValue={el['subSubTopicId']}
                                data={props.subSubTopicData}
                                onChange={(e) => { updateKey(index, Number(e.target.value), 'subSubTopicId') }}
                                options={{ placeholder: "Select" }}
                                className="tdSelect"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <Input
                                maxLength={4}
                                defaultValue={el["noOfHours"]}
                                onKeyPress={(e) => {
                                  if (
                                    !/([1-9][0-9]*)|0/.test(
                                      String.fromCharCode(e.charCode)
                                    )
                                  ) {
                                    e.preventDefault();
                                    return;
                                  }
                                }}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    e.target.value,
                                    "noOfHours"
                                  );
                                }}
                                className="tdInput"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <Input
                                maxLength={4}
                                defaultValue={el["sequence"]}
                                onKeyPress={(e) => {
                                  if (
                                    !/([1-9][0-9]*)|0/.test(
                                      String.fromCharCode(e.charCode)
                                    )
                                  ) {
                                    e.preventDefault();
                                    return;
                                  }
                                }}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    e.target.value,
                                    "sequence"
                                  );
                                }}
                                className="tdInput"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <Select2
                                defaultValue={el['meetingTypeId']}
                                data={props.meetingData}
                                onChange={(e) => { updateKey(index, Number(e.target.value), 'meetingTypeId') }}
                                options={{ placeholder: "Select" }}
                                className="tdSelect"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <Select2
                                defaultValue={el['periodTypeId']}
                                data={props.testMode}
                                onChange={(e) => { updateKey(index, Number(e.target.value), 'periodTypeId') }}
                                options={{ placeholder: "Select" }}
                                className="tdSelect"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <CustomDatePicker 
                                  value={
                                    el['__status'] == '__new'
                                      ? null
                                      : moment(el['hoPlanStartDate']).toDate()
                                  }
                                  index={index}
                                  handler={updateKey}
                                  rangeFrom={1950}
                                  rangeTo={2099}
                                  keyName='hoPlanStartDate'
                                  placeholder="Example:01-01-2020"
                              />
                              {/* <Input type="date"
                                placeholder="Example:01-01-2020"
                                defaultValue={el["__status"] == "__new" ? null : el['hoPlanStartDate']}
                                onKeyDown={(e) => e.preventDefault()}
                                onChange={(e) => {updateKey(index, e.target.value, 'hoPlanStartDate')}}
                                className="form-control"
                              /> */}
                            </td>
                            <td className="text-center p-2 mx-1">
                              <CustomDatePicker 
                                    disabled={!el.hoPlanStartDate}
                                    value={
                                      el['__status'] == '__new'
                                        ? null
                                        : moment(el['hoPlanEndDate']).toDate()
                                    }
                                    index={index}
                                    handler={updateKey}
                                    rangeFrom={1950}
                                    rangeTo={2099}
                                    minDate={moment(validationDate).toDate()}
                                    keyName='hoPlanEndDate'
                                    placeholder="Example:01-01-2020"
                                />
                              {/* <Input type="date"
                                placeholder="Example:01-01-2020"
                                defaultValue={el["__status"] == "__new" ? null : el['hoPlanEndDate']}
                                onChange={(e) => {updateKey(index, e.target.value, 'hoPlanEndDate')}}
                                disabled={!el.hoPlanStartDate}
                                min={moment(validationDate).format(
                                  'YYYY-MM-DD'
                                )}
                                onKeyDown={(e) => e.preventDefault()}
                                className="form-control"
                              /> */}
                            </td>
                            <td className="text-center p-2 mx-1">
                              <label className="custom-toggle mx-auto ml-2">
                                <input
                                  data-testid='activeInactive'
                                  checked={
                                    el.status == "ACTIVE" ? true : false
                                  }
                                  type="checkbox"
                                  id={el.id}
                                  onChange={(e) => {
                                    settableDataKey(
                                      new Date().getMilliseconds()
                                    );
                                    let n = "ACTIVE";
                                    if (!e.target.checked) {
                                      n = "INACTIVE";
                                    }
                                    updateKey(index, n, "status");
                                  }}
                                />
                                <span
                                  style={{ width: "72px" }}
                                  className="custom-toggle-slider rounded-circle activeToggle"
                                  data-label-off="Inactive"
                                  data-label-on="Active"
                                />
                              </label>
                            </td>
                            <td className="text-center">
                              {isSaveVisible ? (
                                <>
                                  {" "}
                                  <Button
                                    data-testid='savebtn'
                                    color="info"
                                    size="sm"
                                    type="button"
                                    className="mx-1"
                                    onClick={() => {
                                      saveAll();
                                    }}
                                  >
                                    Save
                                  </Button>
                                  <Button
                                    data-testid='close'
                                    color="danger"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                      let n = props.classArray;
                                      if (n[index]["__status"] == "__new") {
                                        n.splice(index, 1);
                                      } else {
                                        props.searchLessonPlan();
                                      }
                                      props.setclassArray(n);
                                      setisSaveVisible(false);
                                    }}
                                  >
                                    {" "}
                                    <i className="fas fa-times" />
                                  </Button>{" "}
                                </>
                              ) : (
                                <Button
                                  data-testid='deleteRow'
                                  color="info"
                                  size="sm"
                                  type="button"
                                  onClick={() => {
                                    deleteRow(index);
                                  }}
                                >
                                  {" "}
                                  <i className="fas fa-trash" />
                                </Button>
                              )}
                            </td>
                          </tr>
                        )
                      )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default LessonPlanTable;
