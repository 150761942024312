import React from 'react';
import Style from './noDataFound.module.scss';

const NoDataFound = () => {
  return (
    <div className={Style.No_Data_Found}>
      <i className="fas fa-database"></i>
      <label className={Style.No_Data_Found_Label}>No Record Found</label>
  </div>
  )
}
export default NoDataFound;
