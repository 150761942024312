import React, { useState, useEffect } from "react";
import OfflineAttendanceSetupTable from './offlineAttendanceSetupTable';
import { fetchDetails } from "../common/utils/methods/commonMethods/utilityMethod";
import { getAPI, failureToast } from "services/http";
import { endpoint } from "../common/constant";
import { CustomContainer, CustomCard } from '../common/commonComponents';
import CustomLoader from "../common/commonComponents/Loader/loader";
import { GetPagination } from "../common/commonComponents/pagination/pagination";
const _ = require("lodash");

const OfflineAttendanceSetup = () => {
  const [classArray, setclassArray] = useState([]);
  const [apiloader, setapiloader] = useState(false);
  const [state, setstate] = useState([]);
  const [serverData, setServerData] = useState([]);
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [searchStr, setSearchStr] = useState("");

  const [pagePagination, setPagePagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });

  const pageSize = 10;

  const fetchClasses = (url) => {
    getAPI(
      url,
      (data) => {
        setstate(data.data);
        setServerData(data.data);
        setPagination(data.data);
      },
      (data) => {
        failureToast(data["message"]);
        setapiloader(false);
      }
    );
  };

  const setPagination = (paramsdata) => {
    let n = pagePagination;
    if (searchStr !== "") {
      n.currentPage = 1;
    }
    if (paramsdata.length > pageSize) {
      n["nextPage"] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n["totalPage"] = Math.floor(paramsdata.length / pageSize);
    if (paramsdata.length % pageSize != 0) {
      n["totalPage"] += 1;
    }
    setclassArray(
      paramsdata.slice(
        pagePagination.currentPage * pageSize - pageSize,
        pagePagination.currentPage * pageSize
      ) || []
    );
    setPagePagination(n);
    setapiloader(false);
  };

  // useEffect(() => {
  //   fetchClasses(endpoint.offlineAttendance.getAll)
  // }, []);

  const searchBranchName = () => {
    try {
      if (!searchStr) {
        setapiloader(true);
        fetchClasses(endpoint.offlineAttendance.getAll);
      }
      if (searchStr) {
        fetchClasses(`${endpoint.offlineAttendance.getAll}?locationName=${searchStr}`);
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  useEffect(() => {
    searchBranchName()
  }, [searchStr]);

  return (
    <CustomContainer>
      <>
        {apiloader ? (
          <div
            className="mx-auto text-center py-5 my-5"
            style={{ height: "100vh" }}
          >
            <CustomLoader apiLoader={apiloader} />
          </div>
        ) : (
          <>
            <OfflineAttendanceSetupTable
              setclassArray={setclassArray}
              classArray={classArray}
              isSaveVisible={isSaveVisible}
              setisSaveVisible={setisSaveVisible}
              pagination={pagePagination}
              fetchClasses={searchBranchName}
              serverData={serverData}
              setSearchStr={setSearchStr}
              searchStr={searchStr}

            />
            {pagePagination.totalPage > 1 ? (
              <GetPagination
                isSaveVisible={isSaveVisible}
                setclassArray={setclassArray}
                pagination={pagePagination}
                setpagination={setPagePagination}
                pageSize={pageSize}
                state={state}
              />
            ) : null}
          </>
        )}
      </>
    </CustomContainer>
  )
}

export default OfflineAttendanceSetup;
