import React, {Fragment, useEffect, useState, useCallback} from 'react';
import {endpoint} from "../../common/constant";
import {Card, CardBody, CardHeader, Container, FormGroup, Label, Col, Row, Button, Input} from "reactstrap";
import ProcessDetails from './processDetails';
import Select from 'react-select';
import {failureToast, getAPI, masterServiceBaseUrl, putpost, successToast} from "../../../../../services/http";
import {GetPagination} from "../../common/commonComponents/pagination/pagination";

const _ = require('lodash');

const RoleToProcess = () => {
  const [masterRoleId, setMasterRoleId] = useState();
  const [roleToProcess, setRoleToProcess] = useState([]);
  const [roleToProcessArr, setRoleToProcessArr] = useState([]);
  const [processTag, setProcessTag] = useState([]);
  const [activeProcessTag, setActiveProcessTag] = useState([]);
  const [state, setState] = useState([]);
  const [isSaveVisible, setIsSaveVisible] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const pageSize = 10;

  const fetchRoleToProcessData = (url, type) => {
    try {
      getAPI(
        url,
        (data) => {
          dataHandler(data?.data, type)
        },
        (data) => {
          failureToast(data['message']);
        }
      );
    } catch (err) {
      console.log('Error :', err)
    }
  };

  const fetchSearchData = _.debounce((url, obj, type) => {
    putpost(url,
      (data) => {
        dataHandler(data?.data, type)
      },
      (data) => {
        failureToast(data['message']);
      },
      obj,
      'post'
    );
  }, 300);

  const dataHandler = (data, type) => {
    try {
      switch (type) {
        case 'SEARCH_RESULT':
          setState(data);
          setInitialPagination(data?.roleToProcessDetails);
          break;
        case 'ROLE_NAME':
          const roleTemp = [];
          data.forEach(item => {
            roleTemp.push({
              label: item?.roleName,
              value: item?.id,
              desc: item?.tagTypeDesc
            })
          });
          setRoleToProcess(roleTemp);
          break;
        case 'PROCESS_TAG':
          setProcessTag(data);
          const processTagTemp = [];
          data.forEach((item) => {
            if (item.status.toUpperCase() === 'ACTIVE') {
              processTagTemp.push({
                value: item?.id,
                label: item?.tagTypeDispValue
              })
            }
          });
          setActiveProcessTag(processTagTemp);
          break;
        default:
          break;
      }
    } catch (e) {
      console.log(e)
    }
  }
  const setInitialPagination = (data) => {
    let n = pagination;
    if (data.length > pageSize) {
      n['nextPage'] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n['totalPage'] = Math.floor(data.length / pageSize);
    if (data.length % pageSize != 0) {
      n['totalPage'] += 1;
    }
    setPagination(n);
    setRoleToProcessArr(
      data.slice(
        pagination.currentPage * pageSize - pageSize,
        pagination.currentPage * pageSize
      ) || []
    );
  };

  const previousPage = () => {
    if (pagination.currentPage != 1) {
      setRoleToProcessArr(
        state.slice(
          (pagination.currentPage - 1) * pageSize - pageSize,
          (pagination.currentPage - 1) * pageSize
        )
      );
      let n = pagination;
      n['currentPage'] = n['currentPage'] - 1;
      if (n['currentPage'] == 1) {
        n['previousPage'] = null;
      } else {
        n['previousPage'] = n.currentPage;
      }
      setPagination(n);
    }
  };
  useEffect(() => {

    fetchRoleToProcessData(endpoint.process_Tag.getAll, 'PROCESS_TAG')
  }, [])

  return (
    <Fragment>
      <Container className={'mt-5'} fluid>
        <Card>
          <CardHeader>
            <div className={'d-flex justify-content-between'}>
              <h2>Role to process</h2>
            </div>
          </CardHeader>
          <CardBody className={'p-0'}>
            <Row className={'p-4'}>
              <Col sm={8}>
                <FormGroup>
                  <Label className="form-control-label">
                    Role Name
                  </Label>
                  <Select
                    id="roleProcess"
                    options={roleToProcess}
                    placeholder="Enter Role to Search"
                    onInputChange={(e) => {
                      e.length <= 2 && setRoleToProcess([]);
                      e.length > 2 && fetchSearchData(endpoint.role_Name.search, {roleName: e}, 'ROLE_NAME');
                    }}
                    onChange={(e) => {
                      fetchRoleToProcessData(`${endpoint.role_To_Process.searchByRoleMasterId + "?roleMasterId=" + e.value}`, 'SEARCH_RESULT');
                      setMasterRoleId(e.value);
                    }}
                  />
                </FormGroup>
              </Col>
              {/*<Col sm={4} className={'d-flex align-items-center'}>*/}
              {/*  <Button color={'info'}>Search</Button>*/}
              {/*</Col>*/}
            </Row>
          </CardBody>
        </Card>
        {masterRoleId ?
          <Fragment>
            <ProcessDetails
              roleToProcessArr={roleToProcessArr}
              setRoleToProcessArr={setRoleToProcessArr}
              processTagArr={processTag}
              activeProcessTag={activeProcessTag}
              fetchSearchData={fetchSearchData}
              masterRoleId={masterRoleId}
              previousPage={previousPage}
              setIsSaveVisible={setIsSaveVisible}
              isSaveVisible={isSaveVisible}
              fetchRoleToProcessData={fetchRoleToProcessData}
            />
            {pagination.totalPage > 1 ? (
              <GetPagination
                isSaveVisible={isSaveVisible}
                setclassArray={setRoleToProcessArr}
                pagination={pagination}
                setpagination={setPagination}
                pageSize={pageSize}
                state={state}
              />
            ) : null}
          </Fragment> : null}
      </Container>
    </Fragment>
  )
}

export default RoleToProcess;
