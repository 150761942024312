import React from 'react';
import { useState } from 'react';
import { Card, CardHeader, Input, Button, Table } from 'reactstrap';
import Select2 from 'react-select2-wrapper';
import { failureToast } from '../../common/utils/methods/toasterFunctions/function';
import {
  masterServiceBaseUrl,
  successToast,
  putpost,
  putpost2,
} from 'services/http';
import CustomDatePicker from '../../common/commonComponents/CustomDatePicker/index';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { MasterHeaderAuto, Rows } from '../../common/commonComponents/masterHeader/masterHeader';

import './index.css';

var _ = require('lodash');

const PaymentScheduleTable = (props) => {
  let history = useHistory();
  const {
    setstate,
    tableData,
    pagination,
    setclassArray,
    setpagination,
    cashierOfficeData,
  } = props;

  const [groupCode, setGroupCode] = useState('');
  const [isShowPercentage, setIsShowPercentage] = useState(false);

  const [tableDataKey, settableDataKey] = useState('tableDataKey');
  const headersList = [
    { name: 'BUSINESS AREA' },
    { name: 'GROUP CODE' },
    { name: 'COMPANY CODE' },
    { name: 'CASHIER OFFICE' },
    { name: 'TERM' },
    { name: 'REGULAR COURSE' },
    { name: 'SECURITY DEPOSIT' },
    { name: 'PERCENTAGE' },
    { name: 'EFFECTIVE DATE' },
    { name: 'COURSE START DATE' },
    { name: 'COURSE END DATE' },
    { name: 'TOTAL AMOUNT' },
    { name: 'TRANSACTION ID' },
    { name: 'SF SYNC STATUS' },
    { name: 'WEB SYNC STATUS' },
    { name: 'STATUS' },
    { name: 'SETUP DATES' },
    { name: 'VIEW INSTALLMENTS' },
    { name: 'DISPATCH SCHEDULE' },
    { name: 'CONTROL PAY MODE' },
    { name: 'ACTION' },
  ];
  const itemsFormMaster = [
    'businessAreaId',
    'groupCodeFromBusinessArea',
    'companyCodeFromBusinessArea',
    'cashierOfficeId',
  ];
  const arraylist = [
    props.businessArea,
    props.groupCode,
    props.companyCode,
    props.cashierOfficeData,
    props.term,
    props.regularCourse,
    props.securityDeposit,
  ];
  const listToRender = [
    'businessAreaId',
    'groupCodeFromBusinessArea',
    'companyCodeFromBusinessArea',
    'cashierOfficeId',
    'termId',
    'courseIdRegular',
    'courseIdSecurity',
    'percentage',
    'effectiveDate',
    'courseStartDate',
    'courseEndDate',
  ];

  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: '__new',
        status: 'ACTIVE',
        effectiveDate: null,
        courseDuration: 2,
        transactionId: '12',
      },
      ...props.classArray,
    ]);
  };
  const saveAll = (cb) => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if ( el[ 'editMode' ] ) {
        const testDesimal = /^\s*-?\d+(\.\d{1,2})?\s*$/;
        if (el['percentage'] && testDesimal.test(el['percentage']) === false) {
          isValid = false;
          failureToast(
            'Please enter value of percentage upto two decimal places'
          );
          return;
        } else {
          if (_tempArray[index]['__status'] == '__new') {
            putpost(
              masterServiceBaseUrl + '/payment/schedule',
              (data) => {
                successToast(data['message']);
                props.setisSaveVisible(false);
                props.fetchClasses(masterServiceBaseUrl + '/payment/schedule/all');
              },
              (data) => {
                failureToast(data['message']);
              },
              _tempArray[index],
              'post'
            );
          } else {
            putpost(
              masterServiceBaseUrl + '/payment/schedule/' + _tempArray[index].id,
              (data) => {
                successToast(data['message']);
                props.setisSaveVisible(false);
                delete _tempArray[index]['editMode'];
                props.setclassArray(_tempArray);
                settableDataKey(new Date().getMilliseconds());
              },
              (data) => {
                failureToast(data['message']);
              },
              _tempArray[index],
              'put'
            );
          }
        }
      }
    });
  };
  const newRow = () => {
    if (props.isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        addNewClass();
        props.setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      props.setisSaveVisible(false);
      cb();
    }
  };
  const editRow = (index) => {
    if (props.isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        // console.log(props.cashierOffice,'props.cashierOffice');
        props.getTermData(
          props.businessAreaData.find(
            (item) => item.id === props.classArray[index].businessAreaId
          ) &&
            props.businessAreaData.find(
              (item) => item.id === props.classArray[index].businessAreaId
            ).groupCodeId,
          props.businessAreaData.find(
            (item) => item.id === props.classArray[index].businessAreaId
          ) &&
            props.businessAreaData.find(
              (item) => item.id === props.classArray[index].businessAreaId
            ).companyCodeId
        );
        props.getCashierOffice(props.classArray[index]['businessAreaId']);
        var _tempArray = props.classArray;
        _tempArray[index]['editMode'] = true;
        props.setclassArray(_tempArray);
        props.setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };
  const deleteRow = (index) => {
    if (props.classArray[index]['__status'] == '__new') {
      let n = props.classArray;
      n.splice(index, 1);
      props.setclassArray(n);
      props.setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
    } else {
      if (
        window.confirm('Are you sure you want to delete this Payment Schedule?')
      ) {
        putpost2(
          masterServiceBaseUrl + '/payment/schedule/' + props.classArray[index].id,
          (data) => {
            successToast(data['message']);
            if (props.classArray.length == 1) {
              props.previousPage();
              props.fetchClasses(masterServiceBaseUrl + '/payment/schedule/all');
            } else {
              var _tempArray = props.classArray;
              props.fetchClasses(masterServiceBaseUrl + '/payment/schedule/all');
              if (_tempArray[index]['editMode']) {
                props.setisSaveVisible(false);
              }
              _tempArray.splice(index, 1);
              props.fetchClasses(masterServiceBaseUrl + '/payment/schedule/all');
              props.setclassArray(_tempArray);
            }
            settableDataKey(new Date().getMilliseconds());
          },
          (data) => {
            failureToast(data['message']);
          },
          'PUT'
        );
      }
    }
  };
  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    if(key==='courseStartDate'){
      _tempArray[index]['courseEndDate'] = null;
    }
    props.setclassArray(_tempArray);
    settableDataKey(new Date().getMilliseconds())
  };

  const searchPaginationHandler = (filterData, pageSize, n) => {
    if (filterData.length > pageSize) {
      n['nextPage'] = n.currentPage + 1;
    }
    if (n.currentPage > 1) {
      n.previousPage = n.currentPage - 1;
    }
    n['totalPage'] = Math.floor(filterData.length / pageSize);
    if (filterData.length % pageSize != 0) {
      n['totalPage'] += 1;
    }
    setstate(filterData);
    setclassArray(
      filterData.slice(
        pagination.currentPage * pageSize - pageSize,
        pagination.currentPage * pageSize
      ) || []
    );
    setpagination(n);
  };

  const setGroupCompanyCode = (value) => {
    setGroupCode(
      props.businessAreaData.find((item) => item.id === Number(value)) &&
        props.businessAreaData.find((item) => item.id === Number(value))
          .groupCodeId
    );
    cashierOfficeData.filter((item) => item.businessAreaId === Number(value));
    props.getTermData(
      props.businessAreaData.find((item) => item.id === Number(value)) &&
        props.businessAreaData.find((item) => item.id === Number(value))
          .groupCodeId,
      props.businessAreaData.find((item) => item.id === Number(value)) &&
        props.businessAreaData.find((item) => item.id === Number(value))
          .companyCodeId
    );

    let businessUnitTypeId =
      props.businessAreaData.find((item) => item.id === Number(value)) &&
      props.businessAreaData.find((item) => item.id === Number(value))
        .businessUnitTypeId;

    let franchiseeId =
      props.businessUnitTypeData.find(
        (item) => item.businessUnitTypeKey === 'FRANCHISEE'
      ) &&
      props.businessUnitTypeData.find(
        (item) => item.businessUnitTypeKey === 'FRANCHISEE'
      ).id;

    let percentageShow =
      businessUnitTypeId &&
      businessUnitTypeId.length > 0 &&
      businessUnitTypeId.some((item) => {
        return item === franchiseeId;
      });

    if (percentageShow) {
      setIsShowPercentage(true);
    } else {
      setIsShowPercentage(false);
    }
  };

  const getTermDisplayValue = (value) => {
    const termDataDisplay = props.term.map((item) => ({
      id: item.id,
      text:
        props.termData.find((itemTerm) => itemTerm.id === item.id) &&
        props.termData.find((itemTerm) => itemTerm.id === item.id)
          .termDispValue,
    }));

    return termDataDisplay;
  };

  const filterHandlerBusinessArea = _.debounce((paymentScheduleData) => {
     const businessAreaData = tableData.map(item => ({
        id:item.businessAreaId,
        text:props.businessArea.find(data => data.id === item.businessAreaId) &&
        props.businessArea.find(data => data.id === item.businessAreaId).text
     }))     

    let n = pagination;
    let pageSize = 10;
    if (paymentScheduleData) {
      const filterDataArray = businessAreaData.filter((data) => data.text
       .toString()
       .toUpperCase()
       .includes(paymentScheduleData.toUpperCase()
     ))

     const filterData = tableData.filter(array => filterDataArray.some(filter => filter.id === array.businessAreaId));

      searchPaginationHandler(filterData, pageSize, n);
    } else {
      searchPaginationHandler(tableData, pageSize, n);
    }
  }, 300);

  const filterHandlerTerm = _.debounce((paymentScheduleData) => {
    const termData = tableData.map(item => ({
      id:item.termId,
      text:props.termData.find(data => data.id === item.termId) &&
      props.termData.find(data => data.id === item.termId).termDispValue
    }))

    let n = pagination;
    let pageSize = 10;
    if (paymentScheduleData) {
      const filterDataArray = termData.filter((data) => data.text
       .toString()
       .toUpperCase()
       .includes(paymentScheduleData.toUpperCase()
     ))

     const filterData = tableData.filter(array => filterDataArray.some(filter => filter.id === array.termId));
      searchPaginationHandler(filterData, pageSize, n);
    } else {
      searchPaginationHandler(tableData, pageSize, n);
    }
  }, 300);

  const filterHandlerCourseId = _.debounce((paymentScheduleData) => {
    let n = pagination;
    let pageSize = 10;
    if (paymentScheduleData) {
      const filterData = tableData.filter(
        (data) =>
          data['courseIdRegular'] &&
          data['courseIdRegular']
            .toString()
            .toUpperCase()
            .includes(paymentScheduleData.toUpperCase())
      );
      searchPaginationHandler(filterData, pageSize, n);
    } else {
      searchPaginationHandler(tableData, pageSize, n);
    }
  }, 300);

  const filterHandler = _.debounce((paymentScheduleData) => {
    let n = pagination;
    let pageSize = 10;

    const businessAreaData = tableData.map(item => ({
      id:item.businessAreaId,
      text:props.businessArea.find(data => data.id === item.businessAreaId) &&
      props.businessArea.find(data => data.id === item.businessAreaId).text
   })); 

   const termData = tableData.map(item => ({
    id:item.termId,
    text:props.termData.find(data => data.id === item.termId) &&
    props.termData.find(data => data.id === item.termId).termDispValue
  }));

    if (paymentScheduleData) {
      const filterDataArray = businessAreaData.filter((data) => data.text
       .toString()
       .toUpperCase()
       .includes(paymentScheduleData.toUpperCase()
     ))

     const filterDataArrayTerm = termData.filter((data) => data.text
       .toString()
       .toUpperCase()
       .includes(paymentScheduleData.toUpperCase()
     ));
      const filterData = tableData.filter(
        (data) =>
           filterDataArray.some(filter => filter.id === data.businessAreaId) ||
          (props.businessAreaData.find(
            (item) => item.id === data.businessAreaId
          ) &&
            props.businessAreaData.find(
              (item) => item.id === data.businessAreaId
            ).companyCode &&
            props.businessAreaData
              .find((item) => item.id === data.businessAreaId)
              .companyCode.toString()
              .toUpperCase()
              .includes(
                props.companyCode.find((item) =>
                  item.text
                    .toUpperCase()
                    .includes(paymentScheduleData.toUpperCase())
                ) &&
                  props.groupCode
                    .find((item) =>
                      item.text
                        .toUpperCase()
                        .includes(paymentScheduleData.toUpperCase())
                    )
                    .id.toString()
              )) ||
          data.businessAreaId
            .toString()
            .toUpperCase()
            .includes(
              props.businessArea.find((item) =>
                item.text
                  .toUpperCase()
                  .includes(paymentScheduleData.toUpperCase())
              ) &&
                props.businessArea
                  .find((item) =>
                    item.text
                      .toUpperCase()
                      .includes(paymentScheduleData.toUpperCase())
                  )
                  .id.toString()
            ) ||
          (filterDataArrayTerm.some(filter => filter.id === data.termId)) ||
          (data['courseIdRegular'] &&
            data['courseIdRegular']
              .toString()
              .toUpperCase()
              .includes(paymentScheduleData.toUpperCase())) ||
          (data['courseIdSecurity'] &&
            data['courseIdSecurity']
              .toString()
              .toUpperCase()
              .includes(paymentScheduleData.toUpperCase())) ||
          (data['percentage'] &&
            data['percentage']
              .toString()
              .toUpperCase()
              .includes(paymentScheduleData.toUpperCase())) ||
          data['effectiveDate']
            .toUpperCase()
            .includes(paymentScheduleData.toUpperCase()) ||
          data['courseStartDate']
            .toUpperCase()
            .includes(paymentScheduleData.toUpperCase()) ||
          data['courseEndDate']
            .toUpperCase()
            .includes(paymentScheduleData.toUpperCase()) ||
          (data['totalAmount'] &&
            data['totalAmount']
              .toString()
              .toUpperCase()
              .includes(paymentScheduleData.toUpperCase())) ||
          data['transactionId']
            .toString()
            .toUpperCase()
            .includes(paymentScheduleData.toUpperCase()) ||
          data['status']
            .toUpperCase()
            .includes(paymentScheduleData.toUpperCase())
      );
      searchPaginationHandler(filterData, pageSize, n);
    } else {
      searchPaginationHandler(tableData, pageSize, n);
    }
  }, 300);

  // console.log(props.cashierOffice,'cashierOffice')
  return (
    <>
      <Card style={{ boxShadow: 'none' }} className="mb-0">
        <CardHeader className="border-0">
          <h3 className="mb-0 floatLeft">Payment Schedule</h3>
          <div
            className="floatLeft"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Input
              className="tdInput ml-3  customInputWidth"
              placeholder="Search BUS Area"
              onChange={(e) => {
                let n = e.target.value.trim();
                filterHandlerBusinessArea(n);
              }}
            />
            <Input
              className="tdInput ml-3  customInputWidth"
              placeholder="Search Term"
              onChange={(e) => {
                let n = e.target.value.trim();
                filterHandlerTerm(n);
              }}
            />
            <Input
              className="tdInput ml-3  customInputWidth"
              placeholder="Search Course ID"
              onChange={(e) => {
                let n = e.target.value.trim();
                filterHandlerCourseId(n);
              }}
            />
          </div>
          <div
            className="floatRight "
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Search :{' '}
            <Input
              className="tdInput ml-3 mr-3 customInputWidth"
              placeholder="Search"
              onChange={(e) => {
                let n = e.target.value.trim();
                filterHandler(n);
              }}
            />
            <Button
              disabled={props.isSaveVisible}
              color="info"
              size="sm"
              type="button"
              className={
                'floatRight mx-1' + (props.isSaveVisible ? ' btn-dark' : null)
              }
              onClick={() => {
                newRow();
              }}
            >
              Add New Payment Schedule
            </Button>
          </div>
        </CardHeader>
        <div
          id="questionTable"
          className="table-responsive"
          style={{ marginBottom: '3.5rem', overflowX: 'auto' }}
        >
          <Table className="align-items-center table-flush">
            <MasterHeaderAuto headerList={headersList} />
            <tbody className="list" key={tableDataKey}>
              {props.classArray
                ? props.classArray.map((el, index) =>
                    !el['editMode'] ? (
                      <>
                        <Rows
                          data={el}
                          functionItemsFromList={itemsFormMaster}
                          listArray={arraylist}
                          businessAreaData={props.businessAreaData}
                          listToBeRendered={listToRender}
                          sfSyncStatus={el.sfSyncStatus}
                          webSyncStatus={el.webSyncStatus}
                          termData={props.termData}
                        >
                          <td className="text-center p-2 mx-1">
                            <div>{el['totalAmount']}</div>
                          </td>
                          <td className="text-center p-2 mx-1">
                            <div>{el['transactionId']}</div>
                          </td>
                          <td className="text-center">
                            {el.sfSyncStatus ? (
                              <Button color="success" size="sm" type="button">
                                Success
                              </Button>
                            ) : (
                              <Button color="warning" size="sm" type="button">
                                Warning
                              </Button>
                            )}
                          </td>
                          <td className="text-center">
                            {el.webSyncStatus ? (
                              <Button color="success" size="sm" type="button">
                                Success
                              </Button>
                            ) : (
                              <Button color="warning" size="sm" type="button">
                                Warning
                              </Button>
                            )}
                          </td>
                          <td className="text-center">
                            {el.status === 'ACTIVE' ? (
                              <Button color="success" size="sm" type="button">
                                ACTIVE
                              </Button>
                            ) : (
                              <Button color="warning" size="sm" type="button">
                                INACTIVE
                              </Button>
                            )}
                          </td>
                          <td>
                            <Button
                              color="info"
                              size="sm"
                              type="button"
                              className={'floatRight mx-1'}
                              onClick={() =>
                                history.push(
                                  `/admin/paymentSchedule/setupDates/${el['id']}`
                                )
                              }
                            >
                              <i className="fas fa-edit" /> Setup Dates
                            </Button>
                          </td>
                          <td>
                            <Button
                              color="info"
                              size="sm"
                              type="button"
                              className={'floatRight mx-1'}
                              onClick={() =>
                                history.push(
                                  `/admin/paymentSchedule/viewInstallment/${el['id']}`
                                )
                              }
                            >
                              <i className="fas fa-edit" /> View Installments
                            </Button>
                          </td>
                          <td>
                            <Button
                              color="info"
                              size="sm"
                              type="button"
                              className={'floatRight mx-1'}
                            >
                              <i className="fas fa-edit" />
                              Dispatch Schedule
                            </Button>
                          </td>
                          <td>
                            <Button
                              onClick={() =>
                                history.push(
                                  `/admin/paymentSchedule/controlPaymodesFunctionalities/${el['id']}`
                                )
                              }
                              color="info"
                              //   color={
                              //     el['ABC'] ? 'info' : 'primary'
                              //   }
                              size="sm"
                              type="button"
                              className={'floatRight mx-1'}
                            >
                              <i className="fas fa-edit" />
                              Control Pay Mode
                            </Button>
                          </td>
                          <td>
                            <Button
                              color="info"
                              size="sm"
                              type="button"
                              onClick={() => editRow(index)}
                            >
                              <i className="fas fa-edit" />
                            </Button>
                            <Button
                              color="info"
                              size="sm"
                              type="button"
                              onClick={() => deleteRow(index)}
                            >
                              <i className="fas fa-trash" />
                            </Button>
                          </td>
                        </Rows>
                      </>
                    ) : (
                      <tr key={index + '-class'}>
                        <td className="text-center p-2 mx-1">
                          <Select2
                            defaultValue={el['businessAreaId']}
                            data={props.businessArea}
                            onChange={(e) => {
                              updateKey(
                                index,
                                Number(e.target.value),
                                'businessAreaId'
                              );
                              setGroupCompanyCode(e.target.value);
                              props.getCashierOffice(e.target.value);
                            }}
                            options={{ placeholder: 'Select Business Area' }}
                            className="tdSelect"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Select2
                            data={props.groupCode}
                            defaultValue={
                              groupCode
                                ? groupCode
                                : props.businessAreaData.find(
                                    (item) => item.id === el['businessAreaId']
                                  ) &&
                                  props.businessAreaData.find(
                                    (item) => item.id === el['businessAreaId']
                                  ).groupCodeId
                            }
                            disabled
                            onChange={(e) => {
                              updateKey(
                                index,
                                Number(e.target.value),
                                'groupCode'
                              );
                            }}
                            options={{ placeholder: 'Group code' }}
                            className="tdSelect"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Select2
                            data={props.companyCode}
                            defaultValue={
                              props.businessAreaData.find(
                                (item) => item.id === el['businessAreaId']
                              ) &&
                              props.businessAreaData.find(
                                (item) => item.id === el['businessAreaId']
                              ).companyCodeId
                            }
                            disabled
                            onChange={(e) => {
                              updateKey(
                                index,
                                Number(e.target.value),
                                'companyCode'
                              );
                            }}
                            options={{ placeholder: 'Company code' }}
                            className="tdSelect"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Select2
                            data={props.cashierOffice}
                            defaultValue={el['cashierOfficeId']}
                            onChange={(e) => {
                              props.cashierOffice.length > 0 &&
                                updateKey(
                                  index,
                                  Number(e.target.value),
                                  'cashierOfficeId'
                                );
                            }}
                            options={{ placeholder: 'Cashier Office' }}
                            className="tdSelect"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Select2
                            defaultValue={el['termId']}
                            data={getTermDisplayValue()}
                            onChange={(e) => {
                              updateKey(
                                index,
                                Number(e.target.value),
                                'termId'
                              );
                            }}
                            options={{ placeholder: 'Term' }}
                            className="tdSelect"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Select2
                            defaultValue={el['courseIdRegular']}
                            data={props.regularCourse}
                            onChange={(e) => {
                              props.regularCourse.length > 0 &&
                                updateKey(
                                  index,
                                  Number(e.target.value),
                                  'courseIdRegular'
                                );
                            }}
                            options={{ placeholder: 'Regular Course' }}
                            className="tdSelect"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <Select2
                            defaultValue={el['courseIdSecurity']}
                            data={props.securityDeposit}
                            onChange={(e) => {
                              props.securityDeposit.length > 0 &&
                                updateKey(
                                  index,
                                  Number(e.target.value),
                                  'courseIdSecurity'
                                );
                            }}
                            options={{ placeholder: 'Security Deposit' }}
                            className="tdSelect"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          {(isShowPercentage || el['percentage']) && (
                            <Input
                              defaultValue={el['percentage']}
                              onChange={(e) => {
                                updateKey(index, e.target.value, 'percentage');
                              }}
                              placeholder=""
                              className="tdInput"
                            />
                          )}
                        </td>
                        <td className="text-center p-2 mx-1">
                          {/* <CustomDatePicker 
                              disabled={true}
                              value={
                                el['__status'] == '__new'
                                  ? null
                                  : moment(el['effectiveDate']).toDate()
                              }
                              index={index}
                              handler={updateKey}
                              keyName='effectiveDate'
                              placeholder="Example:01-01-2020"
                          /> */}
                          <Input
                            disabled={true}
                            defaultValue={
                              el['__status'] == '__new'
                                ? null
                                : moment(el['effectiveDate']).format(
                                    'DD-MM-YYYY'
                                  )
                            }
                            placeholder="Example:01-01-2020"
                            className="tdInput"
                          />
                        </td>
                        <td className="text-center p-2 mx-1">
                          <CustomDatePicker 
                                disabled={el['__status'] == '__new' ? false : true}
                                value={el['courseStartDate']}
                                minDate={moment().subtract(21, 'days').subtract(5, 'months').subtract(31, 'years').toDate()}
                                maxDate={moment()
                                  .add(6, 'months')
                                  .add(78, 'years')
                                  .toDate()}
                                placeholder="Example:01-01-2020"
                                handler={updateKey}
                                index={index}
                                rangeFrom={1950}
                                rangeTo={2099}
                                keyName='courseStartDate'
                                forTable={true}
                            />
                          {/* <Input
                            type="date"
                            disabled={el['__status'] == '__new' ? false : true}
                            min={moment().subtract(21, 'days').subtract(5, 'months').subtract(31, 'years').format("YYYY-MM-DD")}
                            max={moment()
                              .add(6, 'months')
                              .add(78, 'years')
                              .format('YYYY-MM-DD')}
                            defaultValue={
                              el['__status'] == '__new'
                                ? null
                                : moment(el['courseStartDate']).format(
                                    'YYYY-MM-DD'
                                  )
                            }
                            onChange={(e) => {
                              updateKey(
                                index,
                                e.target.value,
                                'courseStartDate'
                              );
                            }}
                            id="example-date-input"
                          /> */}
                        </td>
                        <td className="text-center p-2 mx-1">
                          <CustomDatePicker 
                                disabled={el['__status'] == '__new' && el['courseStartDate'] ? false : true}
                                value={el['courseEndDate']}
                                min={moment().subtract(21, 'days').subtract(5, 'months').subtract(31, 'years').toDate()}
                                max={moment()
                                  .add(6, 'months')
                                  .add(78, 'years')
                                  .toDate()}
                                placeholder="Example:01-01-2020"
                                handler={updateKey}
                                index={index}
                                rangeFrom={1950}
                                rangeTo={2099}
                                keyName='courseEndDate'
                                forTable={true}
                                minDate={el['courseStartDate']}
                            />
                          {/* <Input
                            type="date"
                            disabled={el['__status'] == '__new' ? false : true}
                            min={moment().subtract(21, 'days').subtract(5, 'months').subtract(31, 'years').format("YYYY-MM-DD")}
                            max={moment()
                              .add(6, 'months')
                              .add(78, 'years')
                              .format('YYYY-MM-DD')}
                            defaultValue={
                              el['__status'] == '__new'
                                ? null
                                : moment(el['courseEndDate']).format(
                                    'YYYY-MM-DD'
                                  )
                            }
                            onChange={(e) => {
                              updateKey(index, e.target.value, 'courseEndDate');
                            }}
                            id="example-date-input"
                          /> */}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="text-center p-2 mx-1">
                          <label className="custom-toggle mx-auto ml-2">
                            <input
                              checked={el.status == 'ACTIVE' ? true : false}
                              type="checkbox"
                              id={el.id}
                              onChange={(e) => {
                                settableDataKey(new Date().getMilliseconds());
                                let n = 'ACTIVE';
                                if (!e.target.checked) {
                                  n = 'INACTIVE';
                                }
                                updateKey(index, n, 'status');
                              }}
                            />
                            <span
                              style={{ width: '72px', margin: '0 auto' }}
                              className="custom-toggle-slider rounded-circle activeToggle"
                              data-label-off="Inactive"
                              data-label-on="Active"
                            />
                          </label>
                        </td>
                        <td>
                          <Button
                            color="info"
                            size="sm"
                            type="button"
                            className={'floatRight mx-1 btn-dark'}
                          >
                            <i className="fas fa-edit" /> Setup Dates
                          </Button>
                        </td>
                        <td>
                          <Button
                            color="info"
                            size="sm"
                            type="button"
                            className={'floatRight mx-1 btn-dark'}
                          >
                            <i className="fas fa-edit" /> View Installments
                          </Button>
                        </td>
                        <td>
                          <Button
                            color="info"
                            size="sm"
                            type="button"
                            className={'floatRight mx-1 btn-dark'}
                          >
                            <i className="fas fa-edit" />
                            Dispatch Schedule
                          </Button>
                        </td>
                        <td>
                          <Button
                            color="info"
                            size="sm"
                            type="button"
                            className={'floatRight mx-1 btn-dark'}
                          >
                            <i className="fas fa-edit" />
                            Control Pay Mode
                          </Button>
                        </td>
                        <td className="text-center">
                          {props.isSaveVisible ? (
                            <>
                              {' '}
                              <Button
                                color="info"
                                size="sm"
                                type="button"
                                className="mx-1"
                                onClick={() => {
                                  saveAll();
                                }}
                              >
                                Save
                              </Button>
                              <Button
                                color="danger"
                                size="sm"
                                type="button"
                                onClick={() => {
                                  let n = props.classArray;
                                  if (n[index]['__status'] == '__new') {
                                    n.splice(index, 1);
                                  } else {
                                    n[index]['editMode'] = false;
                                    props.fetchClasses(
                                      masterServiceBaseUrl + '/payment/schedule/all'
                                    );
                                  }
                                  props.setclassArray(n);
                                  props.setisSaveVisible(false);
                                }}
                              >
                                {' '}
                                <i className="fas fa-times" />
                              </Button>{' '}
                            </>
                          ) : (
                            <Button
                              color="info"
                              size="sm"
                              type="button"
                              onClick={() => {
                                deleteRow(index);
                              }}
                            >
                              {' '}
                              <i className="fas fa-trash" />
                            </Button>
                          )}
                        </td>
                      </tr>
                    )
                  )
                : null}
            </tbody>
          </Table>
        </div>
      </Card>
    </>
  );
};
export default PaymentScheduleTable;
