import React from "react";
import Style from "./tableHeader.module.scss";

const TableHeader = () => {
  return (
    <thead className={`thead-light ${Style.table__header}`}>
      <tr>
        <th className={`text-uppercase text-center white-breakSpace column-heading ${Style.table_th_padding}`} rowSpan={"2"}>
          Student ID
        </th>
        <th className={`text-uppercase text-center white-breakSpace column-heading ${Style.table_th_padding}`} rowSpan={"2"}>
          Student Name
        </th>
        <th className={`text-uppercase text-center white-breakSpace column-heading ${Style.table_th_padding}`} rowSpan={"2"}>
          Exam Board
        </th>
        <th className={`text-uppercase text-center white-breakSpace column-heading ${Style.table_th_padding}`} rowSpan={"2"}>
          Exam Year
        </th>
        <th className={`text-uppercase text-center white-breakSpace column-heading ${Style.table_th_padding}`} rowSpan={"2"}>
          Exam Date
        </th>
        <th className={`text-uppercase text-center white-breakSpace column-heading ${Style.table_th_padding}`} rowSpan={"2"}>
          Exam Roll Number
        </th>
        <th className={`text-uppercase text-center white-breakSpace column-heading ${Style.table_th_padding}`} rowSpan={"2"}>
          Category
        </th>
        <th className={`text-uppercase text-center white-breakSpace column-heading ${Style.table_th_padding}`} rowSpan={"2"}>
          Total Score
        </th>
        <th className={`text-uppercase text-center white-breakSpace column-heading ${Style.table_th_padding}`} rowSpan={"2"}>
          AIR
        </th>
        <th className={`text-uppercase text-center white-breakSpace column-heading ${Style.table_th_padding}`} rowSpan={"2"}>
          State Rank
        </th>
        <th className={`text-uppercase text-center white-breakSpace column-heading ${Style.table_th_padding}`} rowSpan={"2"}>
          Category Rank
        </th>
        <th className={`text-uppercase text-center white-breakSpace column-heading ${Style.table_th_padding}`} rowSpan={"2"}>
          State Category Rank
        </th>
        <th className={`text-uppercase text-center white-breakSpace column-heading ${Style.table_th_padding}`} rowSpan={"2"}>
          Effective Date
        </th>
        <th className={`text-uppercase text-center white-breakSpace column-heading ${Style.table_th_padding}`} rowSpan={"2"}>
          Action
        </th>
      </tr>
    </thead>
  );
};

export default TableHeader;
