import React, { useContext, useState } from "react";
import { ReactComponent as Refresh } from "assets/img/svg/Group 29.svg";
import { useHistory } from "react-router";
import { ReactComponent as DocumentIcon } from "assets/img/svg/Document.svg";
import StockReturnApplicationSearchDialog from "../../stockReturn/StockReturnApplicationSearchDialog";
import StockReversalSearchDialog from "../../stockReversal/StockReversalSearchDialog";
import { useGetDigitalModuleContext } from "../../contextApi/digitalContext";
import { getScreenCards } from "views/pages/manage/finOps/IssueStock/search/SearchCard.jsx";
import { getUpdatedDigitalDropdown } from "../../stockReturn/constant";
import {
  PermissionContext,
  RolePermissions,
} from "../../../../../../../appContext";
import { pages } from "../../../../common/constant";

const ModuleCards = ({
  stockConsumptionReturnHandler,
  stockTxnHandler,
  downloadReportsClickHandler = () => {},
}) => {
  const history = useHistory();
  const { dropdown } = useGetDigitalModuleContext();

  const dispatchSchedulePermissions = RolePermissions(
    useContext(PermissionContext),
    pages["digitalDispatchSchedule"]["id"]
  );
  const stockIssueDispatchPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["digitalStockIssueDispatch"]["id"]
  );
  const branchConsumptionPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["digitalBranchConsumption"]["id"]
  );
  const branchConsumptionReturnPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["digitalBranchConsumptionReturn"]["id"]
  );
  const stockReturnPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["digitalStockReturn"]["id"]
  );
  const transactionHistoryPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["digitalTransactionHistory"]["id"]
  );
  const courierVendorPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["digitalCourierVendor"]["id"]
  );
  const downloadReportsPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["digitalDownloadReports"]["id"]
  );
  const stockReversalPermissions = RolePermissions(
    useContext(PermissionContext),
    pages["digitalStockReversal"]["id"]
  );

  const [isStockReturnDialogOpen, setIsStockReturnDialogOpen] = useState(false);
  const [isStockReversalDialogOpen, setIsStockReversalDialogOpen] =
    useState(false);
  const stockReturnClickHandler = () => {
    setIsStockReturnDialogOpen(true);
  };
  const stockReversalClickHandler = () => {
    setIsStockReversalDialogOpen(true);
  };

  const cards = [
    dispatchSchedulePermissions && {
      heading: "Dispatch schedule",
      subHeading: "View or edit dispatch schedule",
      icon: <DocumentIcon />,
      action: () => {
        history.push(
          "/admin/edp/digitalStockManagement/dispatchSchedule/DIGITAL"
        );
      },
    },
    stockIssueDispatchPermissions && {
      heading: "Stock issuance, dispatch & hold",
      subHeading: "Update dispatch information",
      icon: <DocumentIcon />,
      action: () => {
        history.push(
          "/admin/edp/digitalStockManagement/IssueStockAndUpdateDispatch"
        );
      },
    },
    branchConsumptionPermissions && {
      heading: "Branch consumption",
      subHeading: "Issue stock to employee",
      icon: <DocumentIcon />,
      action: () => {
        history.push(
          "/admin/edp/digitalStockManagement/StockConsumption/DIGITAL"
        );
      },
    },
    branchConsumptionReturnPermissions && {
      heading: "Branch consumption return",
      subHeading: "Return the stock issued to employee",
      icon: <DocumentIcon />,
      action: stockConsumptionReturnHandler,
    },
    stockReturnPermissions && {
      heading: "Stock return",
      subHeading: "Return stock that has been issued",
      icon: <Refresh />,
      action: stockReturnClickHandler,
    },
    transactionHistoryPermissions && {
      heading: "Transaction history",
      subHeading: "Issue stock to employee",
      icon: <DocumentIcon />,
      action: stockTxnHandler,
    },
    courierVendorPermissions && {
      heading: "Courier vendor master",
      subHeading: "Click here to view and edit Courier vendors ",
      icon: <DocumentIcon />,
      action: () => {
        history.push(
          `/admin/edp/digitalStockManagement/courierVendorMaster/DIGITAL`
        );
      },
    },
    downloadReportsPermissions && {
      heading: "Download reports",
      subHeading: "Ageing report, Current stock availability etc.",
      icon: <Refresh />,
      action: downloadReportsClickHandler,
    },
    stockReversalPermissions && {
      heading: "Stock reversal",
      subHeading: "Reverse the stock issued by branch",
      icon: <Refresh />,
      action: stockReversalClickHandler,
    },
  ];
  return (
    <>
      <div className={`screen-cards-container`}>
        {getScreenCards(cards?.filter((card) => card))}
      </div>
      <StockReturnApplicationSearchDialog
        open={isStockReturnDialogOpen}
        setOpen={setIsStockReturnDialogOpen}
        dropdown={getUpdatedDigitalDropdown(dropdown)} // this method structures digital dropdown object to match rcc dropdown object
        academicCareer="DIGITAL"
      />
      <StockReversalSearchDialog
        open={isStockReversalDialogOpen}
        setOpen={setIsStockReversalDialogOpen}
        dropdown={getUpdatedDigitalDropdown(dropdown)} // this method structures digital dropdown object to match rcc dropdown object
        academicCareer="DIGITAL"
      />
    </>
  );
};

export default ModuleCards;
