import moment from "moment";
import { failureToast } from "../../../common/utils/methods/toasterFunctions/function";

export const getDateKey = (startTime) => moment(startTime)?.format('DD-MM-YYYY')
export const resturctureFacultyScheduleData = (data) => {
  const res = {};
  const temp = data?.map(el =>
    res[getDateKey(el?.startTime)] = [...(res[getDateKey(el?.startTime)] ? res[getDateKey(el?.startTime)] : []), el]
  )
  return res;
}

export const getBatchDropdown = (scheduleData = []) => {
  const dropdown = [];
  // extra check is applied to avoid duplicate entries in batch dropdown
  const temp = scheduleData?.map(item => item?.batchName && !dropdown?.find(obj => obj?.value===item?.batchName) && dropdown?.push({ label: item?.batchName, value: item?.batchName }))
  
  return dropdown;
}

export const initialFilterForm = {
  status: '',
  day: [], // multiselect
  startDate: '',
  endDate: '',
  startTime: '',
  endTime: '',
  subject: [], // multiselect
  businessArea: [], // multiselect
  batch: [], // multiselect
}

export const getFilterCount = filterForm => {
  let cnt = 0;

  for (let key in filterForm) {
    if (key !== 'endTime' && key !== 'endDate' && filterForm[key]?.toString().length) cnt++;
  }

  return cnt;
}

const getTimeString = (dateObj) => moment(dateObj)?.format('HH:mm:ss')
const getMomentObjFromTimeString = timeString => timeString ? moment(timeString, 'HH:mm:ss') : null;

export const validateFilterInput = (form) => {
  if (moment(form?.startTime, 'HH:mm:ss')?.isSameOrAfter(moment(form?.endTime, 'HH:mm:ss'))) {
    failureToast('Start time should be less than end time');
    return false;
  }
  return true;
}

export const dayFilter = (days, item) => {
  if (!days || !days?.length) return true;
  if (days?.find(dayItem => dayItem?.value === moment(item?.startTime)?.format('dddd'))) return true;
  return false;
}

export const timeRangeFilter = (startTime, endTime, item) => {
  if (!startTime && !endTime) return true; // no need to filter
  if (getMomentObjFromTimeString(getTimeString(item?.startTime))?.isSameOrAfter(getMomentObjFromTimeString(startTime))
    && getMomentObjFromTimeString(getTimeString(item?.startTime))?.isSameOrBefore(getMomentObjFromTimeString(endTime))
  ) return true;

  return false;
}

export const statusFilter = (form, item) => {
  if (!form?.status) return true;
  if (form?.status?.toUpperCase() === 'AVAILABLE' && item?.status?.toUpperCase() === 'AVAILABLE') return true; // no additional filters hence return true
  if (form?.status?.toUpperCase() === 'BLOCKED' && item?.status?.toUpperCase() === 'BLOCKED') return subjectFilter(form?.subject, item) && batchFilter(form?.batch, item); // return result of dependent filters

  return false;
}

const subjectFilter = (subjects, item) => {
  if (!subjects || !subjects?.length) return true;
  if (subjects?.find(subject => subject?.label?.toLowerCase() === item?.eventName?.split(' |')?.[0]?.toLowerCase())) return true;

  return false;
}

const batchFilter = (batches, item) => {
  if (!batches || !batches?.length) return true;
  if (batches?.find(batch => batch?.value === item?.batchName)) return true;

  return false;
}