import React from 'react'
import { SlLogin } from 'react-icons/sl'
import AButton from '../../common/form-fields-mui/AButton'
import { useSelector } from 'react-redux'
import { allocationNotAllowedTooltip, getBatchCreationTimeDiff } from '../constant/allocate-students'
import { Tooltip } from '@mui/material'

const containerStyles = {
  display: 'flex',
  width: '100%',
  paddingTop: '15px',
  justifyContent: 'space-between',
  alignItems: 'center'
}

const textStyles = {
  fontSize: '12px',
  marginBottom: '0'
}

const MultiSelectFooter = ({ selectionModel, setSelectionModel = () => { }, setIsMultiAllocate, setOpenDialog }) => {

  const createdOn = useSelector(state => state?.allocateStudents?.batchDetails?.createdOn);

  return (
    <div style={containerStyles}>
      <div className='d-flex' style={{ alignItems: 'center' }}>
        <div>
          <h4 className='mb-0'>{selectionModel.length} Students selected</h4>
          <p style={textStyles}>What do you want to do next?</p>
        </div>
        {getBatchCreationTimeDiff(createdOn) > 5 ? (
          <AButton
            updatedStyle={{
              background: "#00B0F5",
              border: "1px solid #00B0F5",
              borderRadius: "12px",
              color: "#fff",
            }}
            onClick={() => {
              setIsMultiAllocate(true)
              setOpenDialog(true);
            }}
          >
            Allocate all <SlLogin className='ml-2' />
          </AButton>
        ) : (
          <Tooltip title={allocationNotAllowedTooltip}>
            <AButton
              updatedStyle={{
                background: "#00B0F5",
                border: "1px solid #00B0F5",
                borderRadius: "12px",
                color: "#fff",
              }}
            >
              Allocate all <SlLogin className='ml-2' />
            </AButton>
          </Tooltip>
        )}
      </div>
      <div>
        <AButton
          onClick={() => setSelectionModel([])}
        >
          Deselect all
        </AButton>
      </div>
    </div>
  )
}

export default MultiSelectFooter