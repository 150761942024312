import React, { useEffect, useState } from 'react';
import CountCard from './CountCard';
import styles from '../homepage.module.scss';
import { endpoint } from 'views/pages/manage/common/constant';
import { fetchAllPostPromisedData } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import ALoader from 'views/pages/manage/common/a-loader';
import { EDP_SUB_CLASSIFICATIONS, checkEdpCards, getTotalCountBySubClassification, restructureCountResponse } from '../constant';

const HomePageCountCards = ({
  businessAreas,
  fetchingBusinessAreas,
  screenIds,
}) => {

  const [loading, setLoading] = useState(false);
  const [countData, setCountData] = useState({});

  const fetchCount = async () => {
    const reqBody = {
      type: 'COUNT',
      screenIds: screenIds,
      businessAreaIds: businessAreas?.map(item => item?.id),
    };
    setLoading(true);
    const res = await fetchAllPostPromisedData(endpoint?.notification, reqBody);
    if (res?.code === 200) {
      setCountData(restructureCountResponse(res?.data, businessAreas))
    }
    setLoading(false);
  }

  useEffect(() => {
    if(!fetchingBusinessAreas && businessAreas?.length>0) {
      fetchCount();
    }
  }, [businessAreas, fetchingBusinessAreas])

  return (
    <>
      {loading ? (
        <ALoader position={'fixed'} />
      ) : (
        <>
          {checkEdpCards(countData) && (
            <div
              className={`${styles['homepage__card']} d-flex flex-column gap-lg`}
            >
              <div className='large-bold color-grey-90'>EDP</div>
              <div className='d-flex gap-lg'>
                {EDP_SUB_CLASSIFICATIONS?.map(item => (
                  <CountCard
                    key={item?.subClassification}
                    count={getTotalCountBySubClassification(
                      countData[item?.subClassification] || []
                    )}
                    description={item?.desc}
                    redirectPathname={item?.redirectPathname}
                    countData={countData?.[item?.subClassification]}
                    requireBuSelection={item?.requireBuSelection}
                  />
                ))}
              </div>
            </div>
          )}
          {/* <div className='d-flex align-items-cemter gap-lg'>
            <div
              className={`${styles['homepage__card']} d-flex flex-column gap-lg flex-grow-1`}
            >
              <div className='large-bold color-grey-90'>Stock</div>
              <div className='d-flex gap-lg'>
                <CountCard
                  count='5'
                  description='Secondary batches for which time table is yet to be created'
                />
                <CountCard
                  count='15'
                  description='Students to be allocated in Secondary batch'
                />
              </div>
            </div>
            <div
              className={`${styles['homepage__card']} d-flex flex-column gap-lg`}
            >
              <div className='large-bold color-grey-90'>Finance</div>
              <div className='d-flex gap-lg'>
                <CountCard
                  count='5'
                  description='Secondary batches for which time table is yet to be created'
                />
                <CountCard
                  count='15'
                  description='Students to be allocated in Secondary batch'
                />
              </div>
            </div>
          </div> */}
        </>
      )}
    </>
  );
};
export default HomePageCountCards;
