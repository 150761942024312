import React, { useState, useEffect, Fragment } from "react";
import { Card, CardHeader, CardBody, Container, Button, Table, Col, Row, Input } from "reactstrap";
import { CourseDetailsTableHeader, COURSETABLETHROW } from "../../../common/commonComponents/masterRow/masterRow";
import { getAPI, putpost, putpost2, successToast, failureToast, masterServiceBaseUrl, baseUrl } from 'services/http';
import Select2 from 'react-select2-wrapper';
import { Accordion } from '../../../common/commonComponents/Accordion/accordion';
import moment from 'moment';
import LogDetails from './LogDetails';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';

const CourseCatalogSetupTable = (props) => {

  let history = useHistory();
  const { classArray, firstPage, getCourseDetails, state, setstate, setclassArray, performPagination, pagination, courseId } = props;
  const [courseAttributeValueList, setCourseAttributeValueList] = useState([]);
  const [isSaveVisible, setisSaveVisible] = useState(false);
  const [courseAttribute, setCourseAttribute] = useState([]);
  const [courseAttributeValueData, setCourseAttributeValueData] = useState([]);
  const [courseAttributeDesc, setCourseAttributeDesc] = useState();
  const [tableDataKey, settableDataKey] = useState('tableDataKey')
  const [apiLoader, setApiLoader] = useState(false);
  const [minimum, setMinimum] = useState('');
  const [courseID, setCourseID] = useState('');
  const [editModeEnable, setEditModeEnable] = useState({
    index: null,
    page: null,
    mode: false
  })
  const [isOpen, setOpen] = useState(true)

  const handleToggle = () => {
    setOpen(!isOpen)
  }

  useEffect(() => {
    fetchData(masterServiceBaseUrl + '/courseAttribute/getAllActiveCourseAttribute', setCourseAttribute, "setCourseAttribute");
    fetchData(masterServiceBaseUrl + '/courseAttributeValue/getAllActiveCourseAttributeValue', setCourseAttributeValueList, "setCourseAttributeValueList");
  }, [])

  const fetchData = async (url, stateMethod, type) => {
    try {
      getAPI(url, (data) => {
        let temp;
        switch (type) {
          case "setCourseAttribute":
            temp = [];
            data && data.data && data.data.map((item) => {
              temp.push({
                id: item.id,
                text: item.courseAttributeDispValue,
                desc: item.courseAttributeDesc
              })
            })
            stateMethod(temp)
            break;
          case "setCourseAttributeValueList":
            temp = [];
            data && data.data && data.data.map((item) => {
              temp.push({
                id: item.id,
                value: item.courseAttributeValueDispValue,
                label: item.courseAttributeValueDispValue
              })
            })
            stateMethod(temp)
            break;
          default:
            break;
        }
      }, (data) => { failureToast(data['message']) })
    } catch (error) {
      console.log(error);
    }
  }

  const editRow = (index) => {
    try {
      let _tempArray = classArray
      _tempArray[index]['editMode'] = true
      setclassArray(_tempArray)
      setEditModeEnable({
        index: index,
        page: pagination.currentPage,
        mode: true
      })

      const Index =
        courseAttribute &&
        courseAttribute.findIndex((item) => {
          return item.id == _tempArray[index]['courseAttributeId'];
        });
      setCourseAttributeDesc(Index >= 0 ? courseAttribute[Index]['desc'] : '');
      setCourseID(classArray[index]['courseIdSequence']);
      setMinimum(classArray[index]['minimumNumber']);
    } catch (error) {
      console.log(error);
    }
  }

  const deleteRow = (id) => {
    try {
      if (window.confirm('Are you sure you want to delete this course detail?')) {
        setApiLoader(true)
        putpost2(masterServiceBaseUrl + `/courseCatalogSequenceDetail/deleteCourseCatalogSequenceDetail/${id}`, (data) => {
          successToast(data['message'])
          getCourseDetails(courseId)
          setApiLoader(false)
        }, (data) => { failureToast(data['message']); setApiLoader(false) }, 'PUT')
      }
    } catch (error) {
      console.log(error);
    }
  }

  const newRow = () => {
    try {
      setclassArray([{ "courseAttributeId": null, "__status": "new", "courseIdSequence": null, "courseAttributeValueId": null, "courseCatalogSequenceSetupId": courseId, "minimumNumber": null, "status": "ACTIVE", "editMode": true }, ...classArray]);
      setEditModeEnable({
        index: 0,
        page: 1,
        mode: true
      })
    } catch (error) {
      console.log(error);
    }
  }

  const updateCourseDetails = (index, value, type) => {
    try {
      let temp = classArray;
      temp[index][type] = value;
      if (type === 'courseIdSequence') {
        temp[index]['minimumNumber'] = value;
      }
      settableDataKey(new Date().getMilliseconds())
      setclassArray(temp);
    } catch (error) {
      console.log(error);
    }
  }

  const saveRow = (index) => {
    try {
      let patternMin = new RegExp("^[0-9]*$");
      let isValid = true
      let _tempArray = props.classArray;
      _tempArray.forEach((el, index) => {
        if (el['editMode']) {
          if (
            el['courseAttributeId'] === null ||
            !el['courseAttributeId']
          ) {
            isValid = false;
            failureToast('Please select course attribute');
            return;
          } else if (
            el['courseIdSequence'] === null || el['courseIdSequence'].trim().length === 0
          ) {
            isValid = false;
            failureToast('Please enter the Course ID');
            return;
          } else if (
            !patternMin.test(el['courseIdSequence'] || el['courseIdSequence'].trim().length === 0)
          ) {
            isValid = false;
            failureToast('Course ID should be a number');
            return;
          } else if (
            el['courseIdSequence'].length > 3
          ) {
            isValid = false;
            failureToast('Course ID not be greater than 3');
            return;
          } else if (
            el['courseAttributeValueId'] === null ||
            !el['courseAttributeValueId']
          ) {
            isValid = false;
            failureToast('Please select attribute course value');
            return;
          } else if (
            el['minimumNumber'] === null || el['minimumNumber'].length === 0
          ) {
            isValid = false;
            failureToast('Please enter the minimum Value');
            return;
          } else if (
            !patternMin.test(el['minimumNumber']) || el['minimumNumber'].length === 0) {
            isValid = false;
            failureToast('Please enter the valid minimum number');
            return;
          } else if (
            el['minimumNumber'].length < 3 || el['minimumNumber'].length === 0) {
            isValid = false;
            failureToast('Minimum number length should be greater than 3');
            return;
          } else if (el['minimumNumber'].length > 7) {
            isValid = false;
            failureToast('Minimum number length should not be greater than 7');
            return;
          }
          else if (el['courseIdSequence'] && el['minimumNumber'] && el['minimumNumber'].toString().substring(0, el['courseIdSequence'].toString().length) !== el['courseIdSequence'].toString()) {
            failureToast('Minimum Number Should Be Started From Sequence ID.');
            isValid = false;
          }
          if (!isValid) {
            return;
          }
          if (_tempArray[index]['__status'] == 'new') {
            setApiLoader(true)
            putpost(
              masterServiceBaseUrl + '/courseCatalogSequenceDetail/createCourseCatalogSequenceDetail',
              (data) => {
                successToast(data['message']);
                getCourseDetails(courseId);
                setEditModeEnable({
                  index: null,
                  page: null,
                  mode: false
                });
                performPagination();
                setApiLoader(false);
                setCourseAttributeDesc('');
                setCourseID('')
                setMinimum('');
              }, (data) => {
                failureToast(data['message']);

                setApiLoader(false)
              },
              _tempArray[index],
              'post'
            );
          } else {
            setApiLoader(true)
            putpost(
              masterServiceBaseUrl +
              '/courseCatalogSequenceDetail/updateCourseCatalogSequenceDetail/' +
              _tempArray[index].id,
              (data) => {
                successToast(data['message']);
                setisSaveVisible(false);
                delete _tempArray[index]['editMode'];
                setclassArray(_tempArray);
                settableDataKey(new Date().getMilliseconds());
                setEditModeEnable({
                  index: null,
                  page: null,
                  mode: false
                })
                setApiLoader(false)
                performPagination();
                setCourseID('')
                setMinimum('');
              },
              (data) => {
                failureToast(data['message']); setApiLoader(false);
                performPagination();
              }, _tempArray[index], 'put');
          }
        } else {
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  const closeEditMode = (index) => {
    try {
      let n = props.classArray
      if (n[index]['__status'] === 'new') {
        n.splice(index, 1)
      } else {
        getCourseDetails(courseId);
      }
      setCourseID('')
      setMinimum('');
      setclassArray(n)
      setEditModeEnable({ index: null, page: null, mode: false })
    } catch (error) {
      console.log(error);
    }
  }

  const getDisplayvalue = (id) => {
    try {
      const Index =
        courseAttributeValueList &&
        courseAttributeValueList.findIndex((item) => {
          return item.id == id;
        });
      return Index >= 0 ? courseAttributeValueList[Index]['label'] : '';
    } catch (error) {
      console.log(error);
    }
  }

  const getCourseAttributeValue = (id) => {
    try {
      id && getAPI(masterServiceBaseUrl + `/courseAttributeValueMapping/getCourseAttributeValueByCourseAttributeId/${id}`, (data) => {
        let temp = [];
        data && data.data && data.data.map((item) => {
          if (item.status === 'ACTIVE') {
            temp.push({
              id: item.courseAttributeValueId,
              text: getDisplayvalue(item.courseAttributeValueId)
            })
          }
        })
        setCourseAttributeValueData(temp);
        const Index =
          courseAttribute &&
          courseAttribute.findIndex((item) => {
            return item.id == id;
          });
        let val = Index >= 0 ? courseAttribute[Index]['desc'] : 'NOT FOUND';

        setCourseAttributeDesc(val);

      }, (data) => { })
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    // if(courseAttribute.length > 0){
    // console.log('Course Attribute list-------->', courseAttribute)
    // }
  }, [courseAttribute])


  const validate = (type, value) => {
    const mobileReg = /^[0-9\b]+$/;

    if (type === "number") {
      if (value === "" || mobileReg.test(value)) {
        return true;
      }
    }
  }

  return (
    <Fragment>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Course Details</h3>
                <Button id="buttonToggler" disabled={editModeEnable.mode} color="info" size="sm" type="button" className={"floatRight mx-1" + (editModeEnable.mode ? " btn-dark" : null)} onClick={() => { newRow() }}>Add New Course</Button>
              </CardHeader>
              {apiLoader ? <div className="mx-auto text-center py-5 my-5" style={{ height: "100vh" }}><Loader
                type="Rings"
                color="#00BFFF"
                height={70}
                width={70}
                timeout={10000}
              /></div> :
                <div id="questionTable" className="table-responsive">
                  <Table className="align-items-center table-flush " >
                    <CourseDetailsTableHeader />
                    <tbody className="list" key={tableDataKey} >
                      {classArray.map((item, index) =>
                        editModeEnable.index === index && editModeEnable.mode && pagination.currentPage == editModeEnable.page ? (
                          <tr key={index + "class"}>
                            <td className="text-center p-2 mx-1"><Select2 defaultValue={item.courseAttributeId} data={courseAttribute} className="tdSelect" options={{ placeholder: "Select Course Attribute" }}
                              onChange={(e) => {
                                let courseIdSelected = e.target.value;
                                updateCourseDetails(index, courseIdSelected, "courseAttributeId");
                                getCourseAttributeValue(courseIdSelected);
                              }} /></td>
                            <td className="text-center p-2 mx-1"><Input Value={courseAttributeDesc} disabled={true} placeholder="Attribute Description" className="tdSelect" /></td>
                            <td className="text-center p-2 mx-1"><Select2 defaultValue={item.courseAttributeValueId} data={courseAttributeValueData} className="tdSelect" options={{ placeholder: "Select Course Attribute Value " }} onChange={(e) => { updateCourseDetails(index, e.target.value, "courseAttributeValueId"); }} /></td>
                            <td className="text-center p-2 mx-1"><Input maxLength={3} value={item.courseIdSequenceseID} placeholder="Course ID" className="tdInput" onChange={(e) => { if (validate("number", e.target.value)) { setCourseID(e.target.value); updateCourseDetails(index, e.target.value, "courseIdSequence") } }} /></td>
                            <td className="text-center p-2 mx-1"><Input value={item.minimumNumber} maxLength={7} placeholder="Enter Minimum Number" className="tdInput" onChange={(e) => { if (validate("number", e.target.value)) { setMinimum(e.target.value); updateCourseDetails(index, e.target.value, "minimumNumber") } }} disabled={!item.courseIdSequenceseID} /></td>
                            <td className="text-center p-2 mx-1"><Input defaultValue={item.maximumNumber} disabled={true} placeholder="Enter Maximum Number" className="tdInput" onChange={(e) => { updateCourseDetails(index, e.target.value, "maximumNumber") }} /></td>
                            <td className="text-center p-2 mx-1">
                              <label className="custom-toggle mx-auto ml-2">
                                <input checked={item.status === "ACTIVE" ? true : false} type="checkbox" onChange={(e) => { settableDataKey(new Date().getMilliseconds()); e.target.checked ? updateCourseDetails(index, "ACTIVE", 'status') : updateCourseDetails(index, "INACTIVE", 'status') }} />
                                <span style={{ width: "72px" }} className="custom-toggle-slider rounded-circle activeToggle" data-label-off="Inactive" data-label-on="Active" />
                              </label>
                            </td>
                            <td className="text-center">
                              <Button color="info" size="sm" type="button" className="mx-1" onClick={() => saveRow(index)} >Save</Button>
                              <Button color="danger" size="sm" type="button" onClick={() => { closeEditMode(index); }}><i className="fas fa-times" /></Button>
                            </td>
                          </tr>) : (
                          <COURSETABLETHROW item={item} index={index} editRow={editRow} deleteRow={deleteRow} courseAttributeValueList={courseAttributeValueList} courseAttribute={courseAttribute} />
                        ))}
                    </tbody>
                  </Table>
                </div>}
            </Card>
            <Accordion
              title={`Log Details`}
              key={"log_details"}
              isFormOpen={true}
            >
              <LogDetails
                updatedOn={moment(new Date()).format("YYYY-MM-DD")}
                createdOn={moment(new Date()).format("YYYY-MM-DD")}
              />
            </Accordion>
            <Row className="mr-2" style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button id="buttonToggler" color="info" size="sm" type="button" onClick={() => history.push('/admin/classes')}>close</Button>
            </Row>
          </div>
        </Row>
      </Container>
    </Fragment>
  )
}
export default CourseCatalogSetupTable;