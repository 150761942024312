/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Build
import ProgramActionFilter from "views/pages/manage/applicationInformation/applicationIdSetup/programActionMapping/searchIndex";
import TermForm from "views/pages/manage/applicationInformation/applicationIdSetup/termForm/termForm";
import OrganizationType from "views/pages/manage/applicationInformation/externalOrganizationId/externalOrg/organisation";
import BusinessUnitSearch from "views/pages/manage/businessArea/businessAreaSearch";
import PaymentsMethod from "views/pages/manage/businessArea/paymentMethod/paymentMethod";
import PaymentSchedule from "views/pages/manage/businessArea/paymentSchedule/index";
import CourseAttributes from "views/pages/manage/courseInformation/courseService/courseAttributes/courseAttributes";
import CourseCatalogSearch from "views/pages/manage/courseInformation/courseService/courseCatalogForm/index";
import PriceZoneSearch from "views/pages/manage/courseInformation/pricingService/priceZoneSearch";
// import ExamIdGeneration from 'views/pages/manage/examIdGeneration/examIdGeneration';
// import ExamIdGenerationForm from 'views/pages/manage/examIdGeneration/examIdGenerationForm';
import AcademicCareer from "views/pages/manage/masters/academicCareer/academicCareer";
import AcademicGroup from "views/pages/manage/masters/academicGroup/academicGroup";
import AcademicInstitution from "views/pages/manage/masters/academicInstitution/academicInstitution";
import AcademicYear from "views/pages/manage/masters/academicYear/academicYear";
import AccessCode from "views/pages/manage/masters/accessCode/accessCode";
import AccountEntryType from "views/pages/manage/masters/accountingEntryType/accountEntryType";
import AccountType from "views/pages/manage/masters/accountType/accountType";
import ActionReason from "views/pages/manage/masters/actionReason/actionReason";
import AddressCategory from "views/pages/manage/masters/addressCategory/addressCategory";
import AddressType from "views/pages/manage/masters/addressType/addressType";
import AdjustmentFlag from "views/pages/manage/masters/adjustmentFlagMaster/adjustmentFlag";
import AdmissionAgentType from "views/pages/manage/masters/admissionAgentType/admissionAgentType";
import AdmissionType from "views/pages/manage/masters/admissionType/admissionType";
import ApprovalLevel from "views/pages/manage/masters/approvalLevel/approvalLevel";
import AttendanceDeviceType from "views/pages/manage/masters/attendanceDeviceType";
import AttendanceMachineMaster from "views/pages/manage/masters/attendanceMachineMaster";
import BloodGroup from "views/pages/manage/masters/bloodGroup/bloodGroup";
import BuildingMaster from "views/pages/manage/masters/buildingMaster";
import BusinessType from "views/pages/manage/masters/businessType/businessType";
import BusinessUnitType from "views/pages/manage/masters/businessUnitType/businessUnitType";
import Category from "views/pages/manage/masters/category/category";
import City from "views/pages/manage/masters/city/city";
import Classes from "views/pages/manage/masters/classes/classes";
import CompanyCode from "views/pages/manage/masters/companyCode/companyCode";
import ContactTitle from "views/pages/manage/masters/contactTitle/contactTitle";
import Country from "views/pages/manage/masters/country/country";
import CourseAttribute from "views/pages/manage/masters/courseAttribute/courseAttribute";
import CourseAttributeValue from "views/pages/manage/masters/courseAttributeValue/courseAttributeValue";
import CourseComponent from "views/pages/manage/masters/courseComponent/courseComponent";
import Currency from "views/pages/manage/masters/currency/currency";
import DayMaster from "views/pages/manage/masters/dayMaster/dayMaster";
import DepartmentType from "views/pages/manage/masters/departmentType/departmentType";
import District from "views/pages/manage/masters/district/district";
import DocumentProcessTagSearch from "views/pages/manage/masters/documentProcessTagSearch/documentProcessTagSearch";
import DocumentSource from "views/pages/manage/masters/documentSource/documentSource";
import DocumentType from "views/pages/manage/masters/documentType/documentType";
import EmailType from "views/pages/manage/masters/emailType/emailType";
import ExamBoardType from "views/pages/manage/masters/examBoardType/examBoardType";
import ExamPattern from "views/pages/manage/masters/examPattern/examPattern";
import ExamSyllabus from "views/pages/manage/masters/examSyllabus/index";
import ExternalOrgType from "views/pages/manage/masters/externalOrgType/externalOrgType";
import OsFollowStatus from "views/pages/manage/masters/followUp/followUp";
// import BussinessUnit from 'views/pages/manage/businessUnit/businessUnit';
import Gender from "views/pages/manage/masters/gender/gender";
import GroupCode from "views/pages/manage/masters/groupCode/groupCode ";
import GstinCategory from "views/pages/manage/masters/gstinCategory/gstinCategory";
import InvoiceStatus from "views/pages/manage/masters/invoiceStatus/invoiceStatus";
import ItemTypeCategory from "views/pages/manage/masters/itemTypeCategory/itemTypeCategory";
import ItemTypeClassification from "views/pages/manage/masters/itemTypeClassification/itemTypeClassification";
import ItemTypeElement from "views/pages/manage/masters/itemTypeElement/itemTypeElement";
import ItemTypeSetUp from "views/pages/manage/masters/itemTypeSetUp/setUp";
import JobTitle from "views/pages/manage/masters/jobTitle/jobTitle";
import LessonPlan from "views/pages/manage/masters/lessonPlan/lessonPlan";
import MachineType from "views/pages/manage/masters/machineType/machineType";
import MeetingType from "views/pages/manage/masters/meetingType/meetingType";
import MsGroupSequence from "views/pages/manage/masters/msTeam";
import OpportunityType from "views/pages/manage/masters/opportunityType/opportunity";
import ParentOccupation from "views/pages/manage/masters/parentOccupation/parentOccupation";
import PaymentMethod from "views/pages/manage/masters/paymentMethod/paymentMethod";
import PaymentModePinelab from "views/pages/manage/masters/payModePinelab";
import PhoneType from "views/pages/manage/masters/phoneType/phoneType";
import Pincode from "views/pages/manage/masters/pincode/pincode";
import ProfitMapping from "views/pages/manage/masters/profitCenteMappingPage";
import ProgramAction from "views/pages/manage/masters/programAction/programAction";
import ReceiptLabel from "views/pages/manage/masters/receiptLabel/receiptLabel";
import Region from "views/pages/manage/masters/region/region";
import RfIdMaster from "views/pages/manage/masters/rfId";
import SaleType from "views/pages/manage/masters/saleType/saleType";
import Session from "views/pages/manage/masters/session/session";
import State from "views/pages/manage/masters/state/state";
import StudentIdStatus from "views/pages/manage/masters/studentIdStatus/studentIdStatus";
import Subject from "views/pages/manage/masters/subject/subject";
import SubjectArea from "views/pages/manage/masters/subjectArea/subjectArea";
import SubSubTopic from "views/pages/manage/masters/subSubTopicMaster";
import TaxCodeType from "views/pages/manage/masters/taxCodeType/taxCodeType";
import Term from "views/pages/manage/masters/term/term";
import TestHandledBy from "views/pages/manage/masters/testHandledBy/testHandledBy";
import TestMethod from "views/pages/manage/masters/testMethod/testMethod";
import TestMode from "views/pages/manage/masters/testMode/testMode";
import TestType from "views/pages/manage/masters/testType/testType";
import ThirdPartySystems from "views/pages/manage/masters/thirdPartySystems/thirdPartySystems";
import WebsiteSync from "views/pages/manage/masters/websiteSync/websiteSync";
//offline attendance setup
import OfflineAttendanceSetup from "views/pages/manage/offlineAttendanceSetup";
import Services from "views/pages/manage/studentServices/ServicesView";
import TimeTableAndAttendance from "views/pages/manage/studentServices/timeTableAndAttendance";
import EPMR from "views/pages/manage/transactions/epmr";
import StudentFilter from "views/pages/manage/transactions/studentAdmission/studentInfo/studentInfoSearchPage";
import TemplateUploadForm from "views/pages/manage/transactions/templateUploadManager";
import SearchBank from "views/pages/manage/unAssigned/bank/searchBank";
import GlCode from "views/pages/manage/unAssigned/glCode";
import SearchHouseBank from "views/pages/manage/unAssigned/houseBank";
import PaymentMode from "views/pages/manage/unAssigned/paymentMode/paymentMode";
import ScholarshipMaster from "views/pages/manage/unAssigned/scholarshipMaster/index";
import ConcessionCode from "views/pages/manage/unAssigned/concessionCode/index";
import TaxCodeId from "views/pages/manage/unAssigned/taxCodeId/taxCodeId";
import ProcessToPage from "views/pages/manage/userSecurity/processToPage";
import DefineUserRoleSearchPage from "views/pages/manage/userSecurity/userRole/defineUserRoleNew/userRoleSearchPage/index";
import TestComponent from "./layouts/testComponent";
import ConfigRefresh from "./views/pages/manage/admin/configRefresh";
import ExternalOrgBulkUpload from "./views/pages/manage/applicationInformation/externalOrganizationId/bulkUpload/extOrgBulkUpload";
import CourseCatelogSequenceSearch from "./views/pages/manage/courseInformation/courseService/courseCatelogSequenceSearch";
import FeeSearch from "./views/pages/manage/courseInformation/pricingService/courseFee/CourseFeeSearch";
import FeeSearchL1Approval from "./views/pages/manage/courseInformation/pricingService/courseFee/CourseFeeSearch/l1Approval";
import FeeSearchL2Approval from "./views/pages/manage/courseInformation/pricingService/courseFee/CourseFeeSearch/l2Approval";
import PricingFeeInfoSearch from "./views/pages/manage/courseInformation/pricingService/courseFeeInfoSearch";
import FeeSetupRuleSearch from "./views/pages/manage/courseInformation/pricingService/feeSetupRule";
import ItemTypeSequenceSetup from "./views/pages/manage/courseInformation/pricingService/itemTypeSequenceSetup";
import ExamDate from "./views/pages/manage/examService/examConfig";
import ExamIdSearch from "./views/pages/manage/examService/examIdGeneration/examIdSearch";
import ExamIDbulkSearch from "./views/pages/manage/examService/ExamID_BulkSearch/index";
import HomePage from "./views/pages/manage/homepage";
import AcademicPhase from "./views/pages/manage/masters/academicPhase";
import AcademicPhaseMapping from "./views/pages/manage/masters/academicPhaseMapping";
import ApprovalStatus from "./views/pages/manage/masters/approvalStatus";
import CalculatorTypeMaster from "./views/pages/manage/masters/calculatorType";
import CashAwardType from "./views/pages/manage/masters/cashAwardType/cashAwardType";
import ChapterMaster from "./views/pages/manage/masters/chapterMaster";
import ConcessionSubCategory from "./views/pages/manage/masters/concessionSubCategory/concessionSubCategory";
import ConcessionSubSubCategory from "./views/pages/manage/masters/concessionSubSubCategory/concessionSubSubCategory";
import DemoTestTable from "./views/pages/manage/masters/demoTableTest";
import DocumentFormat from "./views/pages/manage/masters/documentFormat/documentFormat";
import DocumentStatus from "./views/pages/manage/masters/documentStatus/documentStatus";
import EpmrStatus from "./views/pages/manage/masters/epmrStatus/epmrStatus";
import ExamGroup from "./views/pages/manage/masters/examGroup";
import ExternalTestResult from "./views/pages/manage/masters/externalTestResult";
import LanguageMaster from "./views/pages/manage/masters/languageMaster";
import MessageCatalog from "./views/pages/manage/masters/messageCatalog";
import OperationalZoneMaster from "./views/pages/manage/masters/operationalZoneMaster/operationalZone";
import PermissionDetails from "./views/pages/manage/masters/permissionDetails";
import PersonType from "./views/pages/manage/masters/personType/personType";
import ProcessTagType from "./views/pages/manage/masters/processTagType/processTagType";
import ProjectDetails from "./views/pages/manage/masters/projectDetails";
import SubTopicMaster from "./views/pages/manage/masters/subTopic";
import TestSequence from "./views/pages/manage/masters/testSequence";
import TopicMaster from "./views/pages/manage/masters/topicMaster";
import VendorType from "./views/pages/manage/masters/vendorType/vendorType";
import InternalTestResultSearch from "./views/pages/manage/transactions/Curriculum/internalTestResult";
import DocumentBulkUpload from "./views/pages/manage/transactions/documentManagement/bulkUpload";
import DocumentManagementSearch from "./views/pages/manage/transactions/documentManagement/documentUploadManagement";
import FeeCalculator from "./views/pages/manage/transactions/feeCalculator";
import ApplicationIdSearch from "./views/pages/manage/transactions/studentAdmission/applicationIdGeneration/applicationIdSearch";
import RoleToProcess from "./views/pages/manage/userSecurity/roleToProcess";
import ErpTableTemplate from "./views/pages/manage/TemplateService/erpTemplate";
import MessageTemplateForm from "./views/pages/manage/TemplateService/messageTemplateForm";
import ClassSection from "views/pages/manage/edpOps/classSection/classSection";
import EmployeeDataUpload from "views/pages/manage/employeeManagement/uploadEmployRecord";
import CreateProfile from "views/pages/manage/employeeManagement/userProfile";
import CreateBatch from "views/pages/manage/edpOps/createBatch/createBatch";
import PrimaryBatchSequence from "views/pages/manage/edpOps/primaryBatchSequence/primaryBatchSequence";
import ProfileSearchPage from "./views/pages/manage/employeeManagement/userProfile/searchPage";
import RoleSearchPage from "./views/pages/manage/role/searchPage";
import SecurityGroupSearch from "./views/pages/manage/userSecurity/securityGroup/searchPage";
import BatchPage from "views/pages/manage/edpOps/batch-serach/BatchPage";
import ResetPasswordSelf from "views/pages/manage/masters/resetPasswordSelf";
import MsTeamChannelSubMapping from "./views/pages/manage/masters/ms-team-channel-mapp";
import StudentSearchPage from "./views/pages/manage/edpOps/studentSerach/index";
import SearchAcademicPlan from "views/pages/manage/academicPlanAndTest/searchAcademicPlan/SearchAcademicPlan";
import CreateAcademicPlan from "views/pages/manage/academicPlanAndTest/createAcademicPlan/CreateAcademicPlan";
import TemplateMapping from "views/pages/manage/TemplateService/templateMapping";
// import HomePage from "views/pages/manage/homepage";
import LoanSchemeSetup from 'views/pages/manage/unAssigned/loanSchemeSetup';
import StudentCharges from 'views/pages/manage/unAssigned/studentCharges';

import FacultyMaster from './views/pages/manage/facultyMasterRoaste/facultyMaster';
import ManualAttendanceIndex from 'views/pages/manage/student/ManualAttendanceIndex';
import GateAttendance from 'views/pages/manage/gateAttendance/GateAttendance';
import FacultyRoaster from './views/pages/manage/facultyMasterRoaste/facultyRoaster';
// import EditRoaster from './views/pages/manage/facultyMasterRoaste/facultyRoaster/EditRoaster'
// import LoanSchemeSetup from "views/pages/manage/unAssigned/loanSchemeSetup";
// import StudentChargesList from "views/pages/manage/unAssigned/studentCharges";
// import FacultyMaster from "./views/pages/manage/facultyMasterRoaste/facultyMaster";
// import ManualAttendance from "views/pages/manage/student/ManualAttendance";
// import FacultyRoaster from "./views/pages/manage/facultyMasterRoaste/facultyRoaster";
// import EditRoaster from "./views/pages/manage/facultyMasterRoaste/facultyRoaster/EditRoaster";

// import BuildingMaster2 from 'views/pages/manage/unAssigned/buildingMaster/index';
import SearchDaysPlan from './views/pages/manage/masters/daysPlanMaster/searchDaysPlan/SearchDaysPlan';
import ConcessionWorkflow from './views/pages/manage/concessionWorkflow';
import OnlinePackage from 'views/pages/manage/masters/onlinePackage';
import ConcessionApproval from 'views/pages/manage/unAssigned/concessionApproval';
import HolidayMaster from 'views/pages/manage/masters/holidayMaster/HolidayMaster'
// import OtherChargesList from 'views/pages/manage/unAssigned/otherCharges';
import StudentOtherCharges from "views/pages/manage/unAssigned/otherCharges/StudentOtherCharges";
import Report from 'views/pages/manage/reports/Report';
import ReportCategory from 'views/pages/manage/reports/ReportCategory';
import PaymentManagement from 'views/pages/manage/financeManagement/paymentManagement';
import IssuanceIDcardPage from 'views/pages/manage/edpOps/issuanceIDcard/index';
import DepositSlip from 'views/pages/manage/unAssigned/depositSlip'
import StockIssuancePage from 'views/pages/manage/edpOps/stockManagement'
import MastersAndMappingLandingPage from './views/pages/manage/edpOps/stockManagement/mastersAndMapping/MastersAndMappingLandingPage';
import BankAccount from "views/pages/manage/masters/studentFinancials/paymentManagement/setUpBankAccount";
import DlpIssueStockManagementPage from 'views/pages/manage/edpOps/stockManagement/dlpStockIssuance/index.jsx';
import ApproveSupplementaryId from "./views/pages/manage/edpOps/approveSupplementaryId/ApproveSupplementaryId";
import DigitalStockManagement from "./views/pages/manage/edpOps/stockManagement/digitalStockManagement/DigitalStockManagement";
import PaymentCollection from "views/pages/manage/masters/studentFinancials/paymentCollection/PaymentCollection";
import PaymentCollectionSetup from "views/pages/manage/masters/studentFinancials/paymentCollectionSetup";


const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: HomePage,
    layout: "/admin",
    screenId: "dashboard",
    icon: "fas fa-home",
  },
  {
    collapse: true,
    name: "Admin",
    icon: "fas fa-user-shield",
    state: "adminConfigRefresh",
    views: [
      {
        path: "/testScreen",
        name: "Test Screen",
        component: TestComponent,
        layout: "/admin",
        screenId: "testScreen",
      },
      {
        path: "/configRefresh",
        name: "Config Refresh",
        icon: "fas fa-sync",
        miniName: "CR",
        component: ConfigRefresh,
        layout: "/admin",
      },
      {
        path: "/jobTile",
        name: "Test Table",
        icon: "fas fa-sync",
        miniName: "Table",
        component: DemoTestTable,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Masters",
    icon: "fab fa-medium",
    state: "masterCollapse",
    views: [
      {
        path: "/websiteSync",
        name: "Website Sync",
        miniName: "WS",
        component: WebsiteSync,
        layout: "/admin",
      },
      {
        path: "/examSyllabus",
        name: "Exam Syllabus",
        miniName: "ESM",
        component: ExamSyllabus,
        layout: "/admin",
      },
      {
        path: "/academicInstitution",
        name: "Academic Institution",
        miniName: "AI",
        component: AcademicInstitution,
        layout: "/admin",
      },
      {
        path: "/addressCategory",
        name: "Address Category",
        miniName: "AC",
        component: AddressCategory,
        layout: "/admin",
      },
      {
        path: "/houseBank",
        name: "House Bank",
        miniName: "HB",
        component: SearchHouseBank,
        layout: "/admin",
      },
      {
        path: "/taxCodeId",
        name: "Tax Code ID",
        miniName: "TCI",
        component: TaxCodeId,
        layout: "/admin",
      },
      {
        path: "/approvalLevel",
        name: "Approval Level",
        miniName: "AL",
        component: ApprovalLevel,
        layout: "/admin",
      },
      {
        path: "/profitCenter",
        name: "Profit Center Mapping",
        miniName: "ACM  ",
        component: ProfitMapping,
        layout: "/admin",
      },
      {
        path: "/thirdPartySystems",
        name: "Third Party Systems",
        miniName: "TPS",
        component: ThirdPartySystems,
        layout: "/admin",
      },
      {
        path: "/buildingmaster",
        name: "Building Master",
        miniName: "B",
        component: BuildingMaster,
        layout: "/admin",
      },
      {
        path: "/concessionSubCategory",
        name: "Concession SubCategory",
        miniName: "CSM",
        component: ConcessionSubCategory,
        layout: "/admin",
      },
      {
        path: "/concessionSubSubCategory",
        name: "Concession Sub Sub Category",
        miniName: "CSSM",
        component: ConcessionSubSubCategory,
        layout: "/admin",
      },
      {
        path: "/invoiceStatus",
        name: "Invoice Status",
        miniName: "IS",
        component: InvoiceStatus,
        layout: "/admin",
      },
      {
        path: "/AccountingEntryType",
        name: "Accounting Entry Type",
        miniName: "AET",
        component: AccountEntryType,
        layout: "/admin",
      },
      {
        path: "/AccountingType",
        name: "Accounting Type",
        miniName: "AT",
        component: AccountType,
        layout: "/admin",
      },
      {
        path: "/glCode",
        name: "GLCode",
        component: GlCode,
        layout: "/admin",
      },
      {
        path: "/searchBank",
        name: "Bank Code",
        component: SearchBank,
        layout: "/admin",
      },
      {
        path: "/meetingType",
        name: "Meeting Type",
        miniName: "MT",
        component: MeetingType,
        layout: "/admin",
      },
      {
        path: "/adjustmentFlag",
        name: "Adjustment Flag",
        miniName: "AF",
        component: AdjustmentFlag,
        layout: "/admin",
      },
      {
        path: "/msGroupSequence",
        name: "MS Group Sequence",
        miniName: "MS Group Sequence",
        component: MsGroupSequence,
        layout: "/admin",
      },

      // {
      // 	path: '/documentUpload',
      // 	name: 'Document Upload',
      // 	component: UploadProcess,
      // 	layout: '/admin',
      // },
      // 	name: 'Contact Title',
      // 	miniName: 'CT',
      // 	component: ContactTitle,
      // 	layout: '/admin',
      // },
      // {
      //   path: '/testSequence',
      //   component: TestSequence,
      //   layout: '/admin',
      // },
      // {
      //   path: '/processInstance',
      //   name: 'Process Instance ID',
      //   component: FeeSearch,
      //   layout: '/admin',
      // },

      // {
      //   path: '/documentProcessTag',
      //   name: 'Document Process Tag',
      //   component: DocumentProcessTag,
      //   layout: '/admin',
      // },

      {
        collapse: true,
        name: "Core Master",
        state: "coreMasterCollapse",
        views: [
          {
            path: "/approvalStatus",
            name: "Approval Status",
            component: ApprovalStatus,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Student Finance Setup",
        state: "studentFinanceSetupCollapse",
        views: [
          {
            collapse: true,
            name: "Concession Masters",
            state: "studentConcessionMasterCollapse",
            views:[
              {
                path: "/consession",
                name: "Concession Category Masters",
                miniName: "S",
                component: ScholarshipMaster,
                layout: "/admin",
              },
              {
                path: "/consessionCode",
                name: "Concession Code Master",
                miniName: "CC",
                component: ConcessionCode,
                layout: "/admin",
              },
              {
                path: "/concessionWorkflow",
                name: "Custom concession config",
                component: ConcessionWorkflow,
                layout: "/admin",
              },
              
            ]
            
          },
          {
            collapse: true,
            name: "Payments Setup",
            state: "studentFinancialsCollapse",
            views: [
              {
                path: "/paymentMode",
                name: "Payment Mode",
                miniName: "PM",
                component: PaymentMode,
                layout: "/admin",
              },
              {
                path: "/paymentCollectionMaster",
                name: "Payment Collection",
                miniName: "Payment Collection",
                component: PaymentCollectionSetup,
                layout: "/admin",
              },
              {
                path: "/loanSchemeSetup",
                name: "Loan Scheme Setup",
                miniName: "Loan Scheme Setup",
                component: LoanSchemeSetup,
                layout: "/admin",
              },
    
              {
                collapse: true,
                name: "Payment Management",
                state: "paymentManagementCollapse",
                views: [
                  {
                    path: "/bankAccount",
                    name: "Set-up Bank Account",
                    miniName: "BA",
                    component: BankAccount,
                    layout: "/admin",
                  },
                ],
              },
            ],
          },

          // {
          //   path: "/depositSlip",
          //   name: "Deposit slip",
          //   component: DepositSlip,
          //   layout: "/admin",
          // },
        ],
      },

    ],
  },
  {
    collapse: true,
    name: "Finance management",
    icon: "fas fa-briefcase",
    state: "financeManagementHeader",
    views: [
      {
        path: "/paymentManagement",
        name: "Payment management",
        component: PaymentManagement,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Business Area",
    icon: "fas fa-briefcase",
    state: "businessUnitCollapse",
    views: [
      {
        path: "/businessUnit",
        name: "Business Area",
        component: BusinessUnitSearch,
        layout: "/admin",
      },
      {
        path: "/paymentsMethod",
        name: "Payments Method",
        component: PaymentsMethod,
        layout: "/admin",
      },
      {
        path: "/paymentModePinelab",
        name: "Payment Mode Pinelab",
        miniName: "PM",
        component: PaymentModePinelab,
        layout: "/admin",
      },
      {
        path: "/paymentSchedule",
        name: "Payment Schedule",
        miniName: "PM",
        component: PaymentSchedule,
        layout: "/admin",
      },
    ],
  },

  {
    collapse: true,
    name: "Manage",
    icon: "fas fa-folder",
    state: "manageCollapse",
    views: [
      // {
      //   collapse: true,
      //   name: 'GL Code',
      //   state: 'clCodeCollapse',
      //   views: [
      //     {
      //       path: '/glCode',
      //       name: 'GLCode',
      //       component: GlCode,
      //       layout: '/admin',
      //     },
      //   ],
      // },
      // {
      //   collapse: true,
      //   name: 'Pricing Zone',
      //   state: 'pZoneCollapse',
      //   views: [
      //     {
      //       path: '/pricingZoneForm',
      //       name: 'Pricing Zone',
      //       miniName: 'PZ',
      //       component: NewPricingZone,
      //       layout: '/admin',
      //     },
      //   ],
      // },
      {
        collapse: true,
        name: "Curriculum Management",
        state: "curriculumManagement",
        views: [
          {
            path: "/extTestResult",
            name: "External Test Result",
            miniName: "E",
            component: ExternalTestResult,
            layout: "/admin",
          },
          {
            path: "/attendance",
            name: "Add RFID",
            miniName: "A",
            component: RfIdMaster,
            layout: "/admin",
          },
          {
            path: "/subject",
            name: "Subject",
            miniName: "S",
            component: Subject,
            layout: "/admin",
          },
          {
            path: "/session",
            name: "Session",
            miniName: "S",
            component: Session,
            layout: "/admin",
          },
          {
            path: "/academicYear",
            name: "Academic Year",
            component: AcademicYear,
            layout: "/admin",
          },
          {
            path: "/dayMaster",
            name: "Day",
            component: DayMaster,
            layout: "/admin",
          },
          // {
          // 	path: '/operationalZone',
          // 	name: 'Operational Zone',
          // 	component: OperationalZone,
          // 	layout: '/admin',
          // },
          {
            path: "/operationalZone",
            name: "Operational Zone",
            component: OperationalZoneMaster,
            layout: "/admin",
          },
          {
            path: "/building",
            name: "Building",
            component: BuildingMaster,
            layout: "/admin",
          },
          {
            path: "/academicPhase",
            name: "Academic Phase",
            component: AcademicPhase,
            layout: "/admin",
          },
          {
            path: "/academicPhaseMapping",
            name: "Academic Phase Mapping",
            component: AcademicPhaseMapping,
            layout: "/admin",
          },
          {
            path: "/chapters",
            name: "Chapters",
            component: ChapterMaster,
            layout: "/admin",
          },
          {
            path: "/topics",
            name: "Topics",
            component: TopicMaster,
            layout: "/admin",
          },
          {
            path: "/subTopics",
            name: "Sub Topic",
            component: SubTopicMaster,
            layout: "/admin",
          },
          {
            path: "/subSubTopic",
            name: "Sub Sub Topic",
            component: SubSubTopic,
            layout: "/admin",
          },
          {
            path: "/attendanceDeviceType",
            name: "Attendance",
            component: AttendanceDeviceType,
            layout: "/admin",
          },
          {
            path: "/attendanceMachine",
            name: "Attendance Machine",
            component: AttendanceMachineMaster,
            layout: "/admin",
          },
          {
            path: "/lessonPlan",
            name: "Lesson Plan",
            miniName: "LP",
            component: LessonPlan,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Student Information",
        state: "studentInformationCollapse",
        views: [
          {
            collapse: true,
            name: "Student ID Set up Values",
            state: "studentIdSetCollapse",
            views: [
              {
                path: "/gender",
                name: "Gender",
                component: Gender,
                layout: "/admin",
              },
              {
                path: "/parentOccupation",
                name: "Parent Occupation",
                component: ParentOccupation,
                layout: "/admin",
              },
              {
                path: "/phonetype",
                name: "Phone Type",
                component: PhoneType,
                layout: "/admin",
              },
              {
                path: "/addresstype",
                name: "Address type",
                component: AddressType,
                layout: "/admin",
              },
              {
                path: "/emailType",
                name: "Email Type",
                component: EmailType,
                layout: "/admin",
              },
              {
                path: "/academicCareer",
                name: "Academic Career",
                component: AcademicCareer,
                layout: "/admin",
              },
              // {
              // 	path: '/academicYear',
              // 	name: 'Academic Year',
              // 	component: AcademicYear,
              // 	layout: '/admin',
              // },
              {
                path: "/academicGroup",
                name: "Academic Group",
                component: AcademicGroup,
                layout: "/admin",
              },

              {
                path: "/term",
                name: "Term",
                component: Term,
                layout: "/admin",
              },
              {
                path: "/admission",
                name: "Admission Type",
                component: AdmissionType,
                layout: "/admin",
              },
              {
                path: "/category",
                name: "Category",
                component: Category,
                layout: "/admin",
              },
              {
                path: "/studentIdStatus",
                name: "Student ID Status",
                component: StudentIdStatus,
                layout: "/admin",
              },
              {
                path: "/bloodGroup",
                name: "Blood group",
                component: BloodGroup,
                layout: "/admin",
              },
              {
                path: "/saletype",
                name: "Sale Type",
                miniName: "ST",
                component: SaleType,
                layout: "/admin",
              },
            ],
          },
        ],
      },
      {
        collapse: true,
        name: "Course Information",
        state: "courseInformationCollapse",
        views: [
          {
            collapse: true,
            name: "Course Service",
            state: "courseServiceCollapse",
            views: [
              {
                path: "/courseAttribute",
                name: "Course Attribute",
                component: CourseAttribute,
                layout: "/admin",
              },
              {
                path: "/courseAttributeValue",
                name: "Course Attribute Value",
                component: CourseAttributeValue,
                layout: "/admin",
              },
              {
                path: "/courseAttributesMapping",
                name: "Course Attributes Mapping",
                component: CourseAttributes,
                layout: "/admin",
              },
              // {
              //   path: '/courseCatalog',
              //   name: 'Course Catalog',
              //   component: CourseCatalog,
              //   layout: '/admin',
              // },
              {
                path: "/courseComponent",
                name: "Course Component",
                component: CourseComponent,
                layout: "/admin",
              },
              {
                path: "/subjectArea",
                name: "Subject Area",
                component: SubjectArea,
                layout: "/admin",
              },
              {
                path: "/CourseCatalogSequence",
                name: "Course Catalog Sequence",
                miniName: "CCS",
                component: CourseCatelogSequenceSearch,
                layout: "/admin",
              },
              {
                path: "/courseCatalog",
                name: "Course Catalog",
                miniName: "CCS",
                component: CourseCatalogSearch,
                layout: "/admin",
                screenId: "courseCatalog",
              },
            ],
          },
          {
            collapse: true,
            name: "Pricing Service",
            state: "ratingServiceCollapse",
            views: [
              {
                path: "/pricingZone",
                name: "Pricing Zone",
                miniName: "PZS",
                component: PriceZoneSearch,
                layout: "/admin",
              },
              {
                path: "/courseFeeInformation",
                name: "Course Fee Information",
                component: PricingFeeInfoSearch,
                layout: "/admin",
              },
              {
                path: "/itemTypeSequence",
                name: "Item Type Sequence",
                component: ItemTypeSequenceSetup,
                layout: "/admin",
              },
              {
                path: "/feeSetupRules",
                name: "Fee Setup Rules",
                component: FeeSetupRuleSearch,
                layout: "/admin",
              },
              {
                collapse: true,
                name: "Course Fee",
                state: "courseFeeCollapse",
                views: [
                  {
                    path: "/processInstanceSearch",
                    name: "Course Fee Upload",
                    component: FeeSearch,
                    layout: "/admin",
                  },
                  {
                    path: "/level1Approval",
                    name: "Level 1 Approval",
                    component: FeeSearchL1Approval,
                    layout: "/admin",
                  },
                  {
                    path: "/level2Approval",
                    name: "Level 2 Approval",
                    component: FeeSearchL2Approval,
                    layout: "/admin",
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        collapse: true,
        name: "Application Information",
        state: "applicationInformationCollapse",
        views: [
          {
            collapse: true,
            name: "Application ID set Up values",
            state: "applicationIdSetollapse",
            views: [
              {
                path: "/admissionAgentType",
                name: "Admission Agent Type",
                component: AdmissionAgentType,
                layout: "/admin",
              },
              // {
              // 	path: '/saletype',
              // 	name: 'Sale Type',
              // 	component: SaleType,
              // 	layout: '/admin',
              // },
              {
                path: "/classes",
                name: "Classes",
                component: Classes,
                layout: "/admin",
                screenId: "classes",
              },
              {
                path: "/programAction",
                name: "Program Action",
                component: ProgramAction,
                layout: "/admin",
                screenId: "programAction",
              },
              {
                path: "/actionReason",
                name: "Action Reason",
                component: ActionReason,
                layout: "/admin",
                screenId: "actionReason",
              },
              {
                path: "/programActions",
                name: "Program Action Mapping",
                component: ProgramActionFilter,
                layout: "/admin",
              },
              // {
              //   path: '/documentType',
              //   name: 'Document Type',
              //   component: DocumentType,
              //   layout: '/admin',
              // },
              {
                path: "/termForm",
                name: "Term Table",
                miniName: "TT",
                component: TermForm,
                layout: "/admin",
              },
              {
                path: "/opportunityType",
                name: "Opportunity Type",
                miniName: "OT",
                component: OpportunityType,
                layout: "/admin",
              },
              // {
              //   path: '/processTagType',
              //   name: 'Process Tag Type',
              //   miniName: 'CFA',
              //   component: ProcessTagType,
              //   layout: '/admin',
              // },
              {
                path: "/gstinCategory",
                name: "GSTIN Category",
                miniName: "GC",
                component: GstinCategory,
                layout: "/admin",
              },
            ],
          },

          {
            collapse: true,
            name: "External Organization Id",
            state: "externalOrganizationCollapse",
            views: [
              // {
              //   path: '/externalOrganizationId',
              //   name: 'External Organization ID',
              //   component: ExternalOrganizationId,
              //   layout: '/admin',
              // },
              {
                path: "/externalOrgType",
                name: "External Org Type",
                component: ExternalOrgType,
                layout: "/admin",
              },
              {
                path: "/externalOrgBulkUpload",
                name: "Upload Ext Org",
                component: ExternalOrgBulkUpload,
                layout: "/admin",
              },
              {
                path: "/contactTitle",
                name: "Contact Title",
                miniName: "CT",
                component: ContactTitle,
                layout: "/admin",
              },
              {
                path: "/organization",
                name: "Add/Maintain External Org ID",
                miniName: "O",
                component: OrganizationType,
                layout: "/admin",
              },
            ],
          },
          // {
          // 	path: '/organization',
          // 	name: 'Add/Maintain External Organization ID',
          // 	miniName: 'O',
          // 	component: OrganizationType,
          // 	layout: '/admin',
          // },
          // {
          //   path: '/applicationIdGeneration',
          //   name: 'Application Id Generation',
          //   miniName: 'O',
          //   // component: ApplicationIdGeneration,
          //   component: ApplicationIdSearch,
          //   layout: '/admin',
          // },
        ],
      },
      {
        collapse: true,
        name: "Exam Service",
        state: "examServicesCollapse",
        views: [
          {
            collapse: true,
            name: "Exam ID Set up Values",
            state: "ExamIdSetollapse",
            views: [
              {
                path: "/examGroup",
                name: "Exam Group",
                component: ExamGroup,
                layout: "/admin",
              },
              {
                path: "/examPattern",
                name: "Exam Pattern",
                component: ExamPattern,
                layout: "/admin",
              },
              {
                path: "/examIDbulkSearch",
                name: "Exam ID Bulk Search",
                component: ExamIDbulkSearch,
                layout: "/admin",
              },
              {
                path: "/examIdGenerationSearch",
                name: "Exam ID Generation Form",
                component: ExamIdSearch,
                layout: "/admin",
              },
              // {
              //   path: '/examIdGeneration',
              //   name: 'Exam ID Generation',
              //   component: ExamIdGeneration,
              //   layout: '/admin',
              // },
              {
                path: "/examBoardType",
                name: "Exam Board Type",
                component: ExamBoardType,
                layout: "/admin",
              },
              {
                path: "/testType",
                name: "Test Type",
                component: TestType,
                layout: "/admin",
              },
              {
                path: "/testMode",
                name: "Test Mode",
                component: TestMode,
                layout: "/admin",
              },
              {
                path: "/testMethod",
                name: "Test Method",
                component: TestMethod,
                layout: "/admin",
              },
              {
                path: "/testHandledBy",
                name: "Test Handled By",
                miniName: "THB",
                component: TestHandledBy,
                layout: "/admin",
              },
              {
                path: "/examDateConfig",
                name: "Exam Date configuration",
                component: ExamDate,
                layout: "/admin",
              },
              // {
              //   path: '/examGroup',
              //   name: 'Exam Group',
              //   component: ExamGroup,
              //   layout: '/admin',
              // },

              // {
              //   path: '/businessUnit',
              //   name: 'Business Area',
              //   miniName: 'BU',
              //   component: BusinessUnitSearch,
              //   layout: '/admin',
              // },
              {
                path: "/testSequence",
                name: "Test Sequence",
                component: TestSequence,
                layout: "/admin",
              },
            ],
          },
        ],
      },
      {
        collapse: true,
        name: "EPMR",
        state: "epmr",
        views: [
          {
            path: "/epmrStatus",
            name: "EPMR Status",
            component: EpmrStatus,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Student Batches",
        state: "studentBatchesCollapse",
        views: [
          {
            collapse: true,
            name: "Batch Masters",
            state: "batchMastersCollapse",
            views: [
              {
                path: "/edp/classSection",
                name: "Class Section Master",
                miniName: "CSM",
                component: ClassSection,
                layout: "/admin",
              },
              {
                path: "/edp/primaryBatchSequence",
                name: "Primary Batch Sequence",
                miniName: "PBS",
                component: PrimaryBatchSequence,
                layout: "/admin",
              },
              {
                path: "/edp/msTeamsChannelsSubjectMaster",
                name: "MS Teams Channels Subject Master",
                miniName: "MSTCSM",
                component: MsTeamChannelSubMapping,
                layout: "/admin",
              },
            ],
          },
          {
            collapse: true,
            name: "Batch Management",
            state: "batchManagementCollapse",
            views: [
              {
                path: "/edp/searchBatch",
                name: "Search Batch",
                miniName: "SB",
                component: BatchPage,
                layout: "/admin",
              },
              {
                path: "/edp/createBatch",
                name: "Create Batch",
                miniName: "CB",
                component: CreateBatch,
                layout: "/admin",
              },
              {
                path: "/edp/searchStudent",
                name: "Student’s Batch Details",
                miniName: "SBD",
                component: StudentSearchPage,
                layout: "/admin",
              },
              {
                path: "/edp/approveSupplementaryId",
                name: "Approve supplementary ID",
                miniName: "ASI",
                component: ApproveSupplementaryId,
                layout: "/admin",
              },
            ],
          },
          {
            path: "/edp/idCards",
            name: "ID Cards",
            component: IssuanceIDcardPage,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Academic Scheduling",
        state: "academicSchedulingCollapse",
        views: [
          {
            path: "/testScheduling/searchAcademicPlan",
            name: "Search Academic Plan",
            mini: "SAP",
            component: SearchAcademicPlan,
            layout: "/admin",
          },
          {
            path: "/testScheduling/createAcademicPlan",
            name: "Create Academic Plan",
            mini: "CAP",
            component: CreateAcademicPlan,
            layout: "/admin",
          },
          {
            collapse: true,
            name: "Timetable Master",
            state: "timetableMasterCollapse",
            views: [
              {
                path: "/master/daysPlan",
                name: "Days Plan",
                component: SearchDaysPlan,
                layout: "/admin",
              },
              {
                path: "/master/holidayMaster",
                name: "Holiday Master",
                component: HolidayMaster,
                layout: "/admin",
              },
              {
                path: "/faculty",
                name: "Faculty Master",
                component: FacultyMaster,
                layout: "/admin",
              },
              {
                path: "/facultyRoaster",
                name: "Faculty Roster",
                component: FacultyRoaster,
                layout: "/admin",
              },
            ],
          },
        ],
      },
      {
        collapse: true,
        name: "Student Financial",
        state: "studentFinancialCollapse",
        views: [
          {
            collapse: true,
            name: "SF Master",
            state: "sfMasterCollapse",
            views: [
              {
                path: "/receiptLabel",
                name: "Receipt Label",
                miniName: "RL",
                component: ReceiptLabel,
                layout: "/admin",
              },
              {
                path: "/companyCode",
                name: "Company Code",
                component: CompanyCode,
                layout: "/admin",
              },
              {
                path: "/paymentMethod",
                name: "Payment Method",
                component: PaymentMethod,
                layout: "/admin",
              },
              {
                path: "/itemTypeSetup",
                name: "Item Type Setup",
                component: ItemTypeSetUp,
                layout: "/admin",
              },
              {
                path: "/typeClassification",
                name: "Item Type Classification",
                component: ItemTypeClassification,
                layout: "/admin",
              },
              {
                path: "/itemTypeElement",
                name: "Item Type Element",
                component: ItemTypeElement,
                layout: "/admin",
              },
              {
                path: "/itemTypeCategory",
                name: "Item Type Category",
                component: ItemTypeCategory,
                layout: "/admin",
              },
              {
                path: "/businessUnitType",
                name: "Business Unit Type",
                component: BusinessUnitType,
                layout: "/admin",
              },
              {
                path: "/businessType",
                name: "Business Type",
                component: BusinessType,
                layout: "/admin",
              },
              {
                path: "/region",
                name: "Region",
                component: Region,
                layout: "/admin",
              },
              {
                path: "/accessCode",
                name: "Access Code",
                component: AccessCode,
                layout: "/admin",
              },
              {
                path: "/taxCodeType",
                name: "Tax Code Type",
                component: TaxCodeType,
                layout: "/admin",
              },
              {
                path: "/groupCode",
                name: "Group Code",
                component: GroupCode,
                layout: "/admin",
              },
              {
                path: "/machineType",
                name: "Machine Type",
                component: MachineType,
                layout: "/admin",
              },
              {
                path: "/country",
                name: "Country",
                component: Country,
                layout: "/admin",
              },
              {
                path: "/state",
                name: "State",
                component: State,
                layout: "/admin",
              },
              {
                path: "/district",
                name: "District",
                component: District,
                layout: "/admin",
              },
              {
                path: "/city",
                name: "City",
                component: City,
                layout: "/admin",
              },
              {
                path: "/pincode",
                name: "Pincode",
                component: Pincode,
                layout: "/admin",
              },
              {
                path: "/currency",
                name: "Currency",
                component: Currency,
                layout: "/admin",
              },
              {
                path: "/calculatorType",
                name: "Calculator Type",
                component: CalculatorTypeMaster,
                layout: "/admin",
              },
            ],
          },
        ],
      },
      {
        collapse: true,
        name: "Document Management",
        state: "documentManagementCollapse",
        views: [
          {
            path: "/documentStatus",
            name: "Document Status",
            component: DocumentStatus,
            layout: "/admin",
          },
          {
            path: "/personType",
            name: "Master-Person Type",
            component: PersonType,
            layout: "/admin",
          },
          {
            path: "/documentProcessTag",
            name: "Document Process Tag",
            component: DocumentProcessTagSearch,
            layout: "/admin",
          },
          {
            path: "/documentFormat",
            name: "Document Format",
            component: DocumentFormat,
            layout: "/admin",
          },
          {
            path: "/documentType",
            name: "Document Type",
            component: DocumentType,
            layout: "/admin",
          },
          {
            path: "/documentSource",
            name: "Document Source",
            component: DocumentSource,
            layout: "/admin",
          },
          {
            path: "/processTagType",
            name: "Process Tag Type",
            miniName: "CFA",
            component: ProcessTagType,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Vendor Service",
        state: "vendorserviceCollapse",
        views: [
          {
            path: "/cashAwardType",
            name: "Cash Award Type",
            component: CashAwardType,
            layout: "/admin",
          },
          {
            path: "/vendorType",
            name: "Vendor Type",
            component: VendorType,
            layout: "/admin",
          },
        ],
      },
      {
        path: "/messageCatalog",
        name: "Message Catalog",
        component: MessageCatalog,
        layout: "/admin",
      },
      {
        collapse: true,
        name: "Recovery Service",
        state: "recoveryserviceCollapse",
        views: [
          {
            path: "/osFollowStatus",
            name: "OS Follow Status",
            component: OsFollowStatus,
            layout: "/admin",
          },
        ],
      },
      {
        path: "/template",
        name: "Templates",
        component: TemplateUploadForm,
        layout: "/admin",
      },
      {
        path: "/onlinePackage",
        name: "Online Package",
        component: OnlinePackage,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Student Services",
    icon: "fas fa-user-graduate",
    state: "studentServices",
    views: [
      {
        collapse: true,
        name: "Time Table and Attendance",
        state: "timeTableAndAttendance",
        icon: "fas fa-calendar-alt",
        views: [
          {
            path: "/timeTable",
            name: "Student Calendar",
            miniName: "C",
            component: TimeTableAndAttendance,
            layout: "/admin",
          },
          {
            path: "/services",
            name: "Services Request",
            miniName: "SR",
            component: Services,
            layout: "/admin",
          },
        ],
      },
    ],
  },
  {
    collapse: true,
    name: "User Security",
    icon: "fas fa-shield-alt",
    state: "userSecurity",
    views: [
      // {
      // 	path: '/securityGroup',
      // 	name: 'Security Group',
      // 	component: SecurityGroupSearch,
      // 	layout: '/admin',
      // },
      {
        path: "/mapProcessToPage",
        name: "Process To Page",
        component: ProcessToPage,
        layout: "/admin",
      },
      {
        path: "/roleToProcess",
        name: "Role To Process",
        component: RoleToProcess,
        layout: "/admin",
      },
      // {
      // 	path: '/roles',
      // 	name: 'Roles',
      // 	component: RoleSearchPage,
      // 	layout: '/admin',
      // },
      // {
      // 	path: '/roleToProcess',
      // 	name: 'Role To Process',
      // 	component: RoleToProcess,
      // 	layout: '/admin',
      // },
    ],
  },
  {
    collapse: true,
    name: "User Profile",
    icon: "fas fa-user",
    state: "userManagement",
    views: [
      {
        collapse: true,
        name: "Manage",
        state: "userManagementManage",
        // icon: 'fas fa-calendar-alt',
        views: [
          {
            path: "/jobTitle",
            name: "Job Title",
            miniName: "JT",
            component: JobTitle,
            layout: "/admin",
          },
          {
            path: "/departmentType",
            name: "Department Type",
            miniName: "DT",
            component: DepartmentType,
            layout: "/admin",
          },
          {
            path: "/projectDetails",
            name: "Project Details",
            component: ProjectDetails,
            layout: "/admin",
          },
          {
            path: "/permissionDetails",
            name: "Permission Details",
            miniName: "PD",
            component: PermissionDetails,
            layout: "/admin",
          },
          {
            path: "/userRolePage",
            name: "Roles",
            miniName: "UR",
            component: RoleSearchPage,
            layout: "/admin",
          },
          {
            path: "/securityGroup",
            name: "Security Group",
            component: SecurityGroupSearch,
            layout: "/admin",
          },
          {
            path: "/languageMaster",
            name: "Language Master",
            miniName: "LM",
            component: LanguageMaster,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Operation",
        state: "userManagementOperation",
        views: [
          {
            name: "Upload Employee Data",
            path: "/employeeManagement",
            component: EmployeeDataUpload,
            layout: "/admin",
          },
          {
            path: "/userProfile",
            name: "Users and Employees",
            component: ProfileSearchPage,
            layout: "/admin",
          },
        ],
      },
      {
        path: "/resetPassword",
        name: "Reset Password",
        component: ResetPasswordSelf,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Notification",
    icon: "ni ni-shop text-primary",
    state: "dashboardsCollapse",
    views: [
      {
        path: "/erpTemplate",
        name: "Notification Templates",
        miniName: "V",
        component: ErpTableTemplate,
        layout: "/admin",
      },
      {
        path: "/templateMapping",
        name: "Notification Templates Mapping",
        miniName: "TM",
        component: TemplateMapping,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Offline Attendance Setup",
    icon: "fas fa-clipboard",
    state: "Offline Attendance Setup",
    views: [
      {
        name: "Offline Attendance Setup",
        path: "/offlineAttendanceSetup",
        component: OfflineAttendanceSetup,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Operations",
    icon: "fas fa-tasks",
    state: "operationsCollapse",
    views: [
      {
        collapse: true,
        name: "Curriculum",
        state: "curriculum",
        icon: "fas fa-school",
        views: [
          {
            path: "/internalTestResult",
            name: "Internal Test Result",
            miniName: "FC",
            component: InternalTestResultSearch,
            layout: "/admin",
          },
        ],
      },
      {
        path: "/feeCalculator",
        name: "Fee Calculator",
        miniName: "FC",
        component: FeeCalculator,
        layout: "/admin",
        icon: "fas fa-calculator",
      },
      {
        collapse: true,
        name: "Student Financials",
        state: "studentfinanceHeader",
        icon: "fas fa-briefcase",
        views: [
          {
            path: "/paymentCollection",
            name: "Payment Collection",
            component: PaymentCollection,
            layout: "/admin",
          },
          {
            path: "/studentCharges",
            name: "Apply charges",
            component: StudentCharges,
            layout: "/admin",
          },
          {
            path: "/studentOtherCharges",
            name: "Apply other charges",
            component: StudentOtherCharges,
            layout: "/admin",
          },
          {
            path: "/concessionApproval",
            name: "Concession Approval",
            component: ConcessionApproval,
            layout: "/admin",
          },
        ],
      },
      {
				collapse: true,
				name: 'Stock & Inventory Management',
				state: 'stockInventoryManagement',
        icon: "fas fa-layer-group",
				views: [
					{
						path: '/edp/stockInventoryMaster',
						name: 'Masters & Mapping',
						miniName: 'MM',
						component: MastersAndMappingLandingPage,
						layout: '/admin'
					},
					{
            name: "RCC | Stock & Inventory",
            component: StockIssuancePage,
            miniName: "SI",
            path: "/edp/stockManagement",
            layout: "/admin",
          },
          {
            name: "DLP | Stock & Inventory",
            component: DlpIssueStockManagementPage,
            miniName: "DSI",
            path: "/edp/dlpStockManagement",
            layout: "/admin",
          },
          {
            name: "DIGITAL | Stock & Inventory",
            component: DigitalStockManagement,
            miniName: "DSM",
            path: "/edp/digitalStockManagement",
            layout: "/admin",
          },
				]
			},
      {
        collapse: true,
        name: "Student Admission",
        state: "studentAdmissionCollapse",
        icon: "fas fa-school",
        views: [
          {
            path: "/studentInfo",
            name: "Student Info",
            miniName: "SIF",
            component: StudentFilter,
            layout: "/admin",
          },
          {
            path: "/applicationIdGeneration",
            name: "Application ID Generation",
            miniName: "O",
            // component: ApplicationIdGeneration,
            component: ApplicationIdSearch,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Document Management",
        state: "Document Management",
        icon: "fas fa-file-alt",
        views: [
          {
            path: "/documentManagementSearch",
            name: "Document Management",
            miniName: "O",
            component: DocumentManagementSearch,
            layout: "/admin",
          },
          {
            path: "/bulkUpload",
            name: "Bulk Upload",
            miniName: "BU",
            component: DocumentBulkUpload,
            layout: "/admin",
          },
        ],
      },
      {
        path: "/epmr",
        name: "EPMR",
        component: EPMR,
        layout: "/admin",
        icon: "fas fa-file-alt",
      },
      {
        collapse: true,
        name: "Attendance",
        state: "attendanceCollapse",
        icon: "fas fa-users",
        views:[
          {
            path: "/student/attendance",
            name: "Manual Attendance",
            component: ManualAttendanceIndex,
            layout: "/admin",
            screenId: "/student/attendance",
          },
          {
            path: "/student/gateAttendance",
            name: "Gate Attendance",
            component: GateAttendance,
            layout: "/admin",
          }
        ]
      }
    ],
  },
  {
    path: "/reports",
    name: "Reports",
    miniName: "Rp",
    component: ReportCategory,
    layout: "/admin",
    icon: "fas fa-file",
  },
];

export default routes;