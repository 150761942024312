import moment from "moment";
import { endpoint } from "../../common/constant";
import { constants } from "../../common/constant/constants/index";
import { fetchAllPostPromisedData } from "../../common/utils/methods/commonMethods/utilityMethod";

const getApproverNames = (row, approvalType, approverName) => {
    switch (approvalType) {
        case constants.APPROVER_TYPE.L1: return {
            l1ApproverName: approverName
        }
        case constants.APPROVER_TYPE.L2: return {
            l1ApproverName: row.l1ApproverName,
            l2ApproverName: approverName
        }
        case constants.APPROVER_TYPE.L3: return {
            l1ApproverName: row.l1ApproverName,
            l2ApproverName: row.l2ApproverName,
            l3ApproverName: approverName
        }
        default: return {
            l1ApproverName: row.l1ApproverName,
            l2ApproverName: row.l2ApproverName,
            l3ApproverName: row.l3ApproverName
        }
    }
}

export const getUpdatedRow = (row, approvalType, approvalStat, remarks, userDetails) => {
    const approvalStatus = approvalStat.charAt(0).toUpperCase() + approvalStat.slice(1);
    const approverName = userDetails ? `${userDetails.firstName} ${userDetails.lastName}` : "";
    const approverID = userDetails?.employeeId || "";
    return {
        // id: row.id,
        // l1ApprovalStatus: approvalType === constants.APPROVER_TYPE.L1 ? approvalStatus : row.l1ApprovalStatus,
        // l1Remark: approvalType === constants.APPROVER_TYPE.L1 ? remarks : row.l1Remark,
        // l2ApprovalStatus: approvalType === constants.APPROVER_TYPE.L2 ? approvalStatus : row.l2ApprovalStatus,
        // l2Remark: approvalType === constants.APPROVER_TYPE.L2 ? remarks : row.l2Remark,
        // l3ApprovalStatus: approvalType === constants.APPROVER_TYPE.L3 ? approvalStatus : row.l3ApprovalStatus,
        // l3Remark: approvalType === constants.APPROVER_TYPE.L3 ? remarks : row.l3Remark,
        // ...getApproverNames(row, approvalType, approverName),
        // ...(approvalType === constants.APPROVER_TYPE.L1 && {l1StatusUpdatedOn: new Date()}),
        // ...(approvalType === constants.APPROVER_TYPE.L2 && {l2StatusUpdatedOn: new Date()}),
        // ...(approvalType === constants.APPROVER_TYPE.L3 && {l3StatusUpdatedOn: new Date()}),

        // ...(approvalType === constants.APPROVER_TYPE.L1 && {l1ApprovarId: approverID}),
        // ...(approvalType === constants.APPROVER_TYPE.L2 && {l2ApprovarId: approverID}),
        // ...(approvalType === constants.APPROVER_TYPE.L3 && {l3ApprovarId: approverID}),
        "quotationDetailsId": row.applicationQuotationDetailsId,
        "approvalStatus": `${approvalType}_${approvalStat?.toString()?.toUpperCase()}`,
        "approverRemark": remarks
    };
}

export const getRequestPayload = (remarks, approvalState, approvalStatus, userDetails) => {
    const payloadRows = [];
    const approvalType = approvalState.approverType?.value;
    Object.keys(approvalState.selectedRowIds).forEach(key => {
        const allRows = approvalState.groupedRequests[key];
        const selectedIds = approvalState.selectedRowIds[key];

        selectedIds.forEach(id => {
            const row = allRows.find(item => item.id === id);
            if (row) {
                payloadRows.push(getUpdatedRow(row, approvalType, approvalStatus, remarks, userDetails));
            }
        })
    })

    return payloadRows;
}

export const getUserRemarks = (row, approvalType, stage) => {
    const cashier = {
        header: 'Cashier',
        name: row?.createdByName || "",
        remarks: row?.concessionRemarks || "",
        date: row?.createdOn || "",
        userId: row?.createdBy || ""
    };
    const l1approver = {
        header: 'L1 approver',
        name: row?.l1ApproverName || "",
        remarks: row?.l1Remark || "",
        date: row?.l1StatusUpdatedOn || "",
        userId: row?.l1ApprovarId || ""
    };
    const l2approver = {
        header: 'L2 approver',
        name: row?.l2ApproverName || "",
        remarks: row?.l2Remark || "",
        date: row?.l2StatusUpdatedOn || "",
        userId: row?.l2ApprovarId || ""
    };
    const l3approver = {
        header: 'L3 approver',
        name: row?.l3ApproverName || "",
        remarks: row?.l3Remark || "",
        date: row?.l3StatusUpdatedOn || "",
        userId: row?.l3ApprovarId || ""
    };
    switch (approvalType) {
        case constants.APPROVER_TYPE.L1: return stage === constants.APPROVAL_STAGE.PENDING ? [cashier] : [cashier, l1approver];
        case constants.APPROVER_TYPE.L2: return stage === constants.APPROVAL_STAGE.PENDING ? [cashier, l1approver] : [cashier, l1approver, l2approver];
        case constants.APPROVER_TYPE.L3: return stage === constants.APPROVAL_STAGE.PENDING ? [cashier, l1approver, l2approver] : [cashier, l1approver, l2approver, l3approver];
        default: return [cashier];
    }
}

export const getApprovedBy = (row, approvalType) => {
    switch (approvalType) {
        case constants.APPROVER_TYPE.L1: return `${getFormatedDate(row?.approverStatusUpdatedOn || "")} by ${row?.approverId || ""}`;
        case constants.APPROVER_TYPE.L2: return `${getFormatedDate(row?.approverStatusUpdatedOn || "")} by ${row?.approverId || ""}`;
        case constants.APPROVER_TYPE.L3: return `${getFormatedDate(row?.approverStatusUpdatedOn || "")} by ${row?.approverId || ""}`;
        default: return ``;
    }
}

export const getFormatedDate = (date, timeStamp) => {
    return date ? timeStamp ? moment(date).format('DD-MMM-YYYY hh:mm A') : moment(date).format('DD-MMM-YYYY') : ''
}

export const getFileUrl = async (key) => {
    try {
        const response = await fetchAllPostPromisedData(`${endpoint.fetchS3Url.getDownloadUrl}?key=${key}`, true);
        if (response.code === 200) {
            // window.open(`${response.data.url}`)
            return response.data.url;
        } else {
            // setErrorMsg(`Unable to download file, please try after sometime`)
            return false;
        }
    } catch (err) {
        return false;
    }
}

export const getSuccessMsg = (approvalStatus) => {
    switch (approvalStatus) {
        case constants.APPROVAL_STAGE.APPROVED: return `Concessions are approved Successfully`;
        case constants.APPROVAL_STAGE.REJECTED: return `Concessions rejected Successfully and notified to Branch`;
        case constants.APPROVAL_STAGE.RECHECK: return `Concessions recheck Successfully and notified to Branch`;
        default: return `Detail saved successfully`;
    }
}

export const getDocsStatus = (row, allActiveConfigs, overrideParams) => {
    if (row) {
        const customConfig = allActiveConfigs.find(item => item.businessAreas.includes(row.applicationBusinessArea) && item.courseCategories.includes(row.courseCategoryId));
        if ((customConfig || overrideParams) && row.totalConcessionAppliedPercentage >= customConfig.documentUploadPercentCriteria) {
            return !row.isDocumentVerified ? "Pending" : "Approved";
        } else {
            return 'NOT_REQ';
        }
    }
    return null;
}