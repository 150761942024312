import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { BsFillForwardFill } from 'react-icons/bs';
import SetupBuAndCourseTable from './setupBuAndCourseTable';
import {
	masterServiceBaseUrl,
	concessionServiceBaseUrl,
	failureToast,
	getAPI,
} from '../../../../../../../services/http';
import CommonHeader from '../commonHeader';
import { updateData } from '../../../../../../../redux';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
	fetchDetails,
	getDispValForReactSelectByOneID,
} from '../../../../common/utils/methods/commonMethods/utilityMethod';
import CustomLoader from '../../../../common/commonComponents/Loader/loader';
import { GetPagination } from '../../../../common/pagination';
const SetupCourseSecurity = (props) => {
	const dispatch = useDispatch();
	const { id } = props.match.params;
	const [pagination, setpagination] = useState({
		currentPage: 1,
		nextPage: null,
		previousPage: null,
		totalPage: null,
	});
	const [apiLoader, setApiLoader] = useState(true);
	const [isSaveVisible, setIsSaveVisible] = useState(false);
	const [termData, setTermData] = useState([]);
	const [setupBuData, setSetupBuData] = useState([]);
	const [subSubCategoryData, setSubSubCategoryData] = useState();
	const [subsubCategoryMasterData, setSubSubCategoryMasterData] = useState();
	const pageSize = 10;
	const headerData = [
		{
			label: 'Business Group',
			value: props.headerData && props.headerData.businessGroup,
		},
		{
			label: 'Company Code',
			value: props.headerData && props.headerData.companyCode,
		},
		{
			label: 'Academic Career',
			value: props.headerData && props.headerData.academicCareer,
		},
		{
			label: 'Academic Group',
			value: props.headerData && props.headerData.academicGroup,
		},
		{
			label: 'Category CD - Description',
			value: props.headerData
				? props.headerData.categoryCode + ' - ' + props.headerData.description
				: 'Not Found',
		},
		{
			label: 'Sub Category CD - Description',
			value: props.headerData
				? props.headerData.subCategoryCode +
				  ' - ' +
				  props.headerData.subCategoryDesc
				: 'Not Found',
		},
		{
			label: 'Sub Sub Category - Description',
			value: `${
				subSubCategoryData &&
				subSubCategoryData.subSubCategoryMasterId &&
				getDispValForReactSelectByOneID(
					subSubCategoryData.subSubCategoryMasterId,
					'DispValue',
					subsubCategoryMasterData
				)
			} - ${
				subSubCategoryData &&
				subSubCategoryData.subSubCategoryMasterId &&
				getDispValForReactSelectByOneID(
					subSubCategoryData.subSubCategoryMasterId,
					'DescValue',
					subsubCategoryMasterData
				)
			}`,
		},
	];
	const nextPage = () => {
		setSetupBuData(
			setupBuData.slice(
				(pagination.currentPage + 1) * pageSize - pageSize,
				(pagination.currentPage + 1) * pageSize
			)
		);
		let n = pagination;
		n['currentPage'] = n['currentPage'] + 1;
		n['previousPage'] = n.currentPage;
		setpagination(n);
	};
	const previousPage = () => {
		setSetupBuData(
			setupBuData.slice(
				(pagination.currentPage - 1) * pageSize - pageSize,
				(pagination.currentPage - 1) * pageSize
			)
		);
		let n = pagination;
		n['currentPage'] = n['currentPage'] - 1;
		if (n['currentPage'] === 1) {
			n['previousPage'] = null;
		} else {
			n['previousPage'] = n.currentPage;
		}
		setpagination(n);
	};
	const lastPage = () => {
		setApiLoader(true);
		setSetupBuData(
			setupBuData.slice(
				pagination.currentPagetotalPage * pageSize - pageSize,
				pagination.totalPage * pageSize
			)
		);
		let n = pagination;
		n['currentPage'] = n.totalPage;
		n['previousPage'] = n.totalPage - 1;
		setpagination(n);
		setApiLoader(false);
	};

	const firstPage = () => {
		setApiLoader(true);
		setSetupBuData(setupBuData.slice(1 * pageSize - pageSize, 1 * pageSize));
		let n = pagination;
		n['currentPage'] = 1;
		n['previousPage'] = null;
		setpagination(n);
		setApiLoader(false);
	};
	const fetchData = (url, setMethod) => {
		getAPI(
			url,
			(data) => {
				if (data.data && data.data.length > 0) {
					let tempArray = [];
					data.data.map((item) => {
						tempArray.push({
							id: item.id,
							termId: item.termId,
							status: item.status,
							allCourses: item.allCourses,
							courseCount: item.courseCount,
							isEdit: false,
							isNew: false,
							updatedOn: item.updatedOn,
						});
					});
					setMethod(tempArray);
				} else {
					setMethod([]);
				}
			},
			(data) => {
				failureToast(data['message']);
			}
		);
	};

	useEffect(() => {
		fetchData(
			concessionServiceBaseUrl + '/buAndCourseSecurity/subSubCategory/' + id,
			setSetupBuData
		);
		fetchDetails(
			masterServiceBaseUrl + '/term/getAllActiveTerm',
			setTermData,
			'term',
			'reactSelect'
		);
		// fetchAllData(concessionServiceBaseUrl + '/addSubSubCategory/' + id, setSubSubCategoryData);
		// fetchAllData(
		//   masterServiceBaseUrl + '/subSubCategory/getSubSubCategory/' + id,
		//   setSubSubCategoryMasterData
		// );
		fetchDetails(
			masterServiceBaseUrl + '/subSubCategory/getAllActiveSubSubCategory',
			setSubSubCategoryMasterData,
			'subSubCategory',
			'category'
		);
	}, []);

	useEffect(() => {
		if (subSubCategoryData && subSubCategoryData.subSubCategoryMasterId) {
			dispatch(
				updateData({
					...props.headerData,
					subSubCategoryDispValue: getDispValForReactSelectByOneID(
						subSubCategoryData.subSubCategoryMasterId,
						'DispValue',
						subsubCategoryMasterData
					),
					subSubCategoryShortDesc: getDispValForReactSelectByOneID(
						subSubCategoryData.subSubCategoryMasterId,
						'DescValue',
						subsubCategoryMasterData
					),
				})
			);
		}
	}, [subSubCategoryData]);

	useEffect(() => {
		if (setupBuData) {
			setApiLoader(false);
		}
	});
	return apiLoader ? (
		<>
			<div>
				<CustomLoader
					apiLoader={apiLoader}
					loaderHeight={'200px'}
					loaderWidth={'100% '}
				/>
			</div>
		</>
	) : (
		<>
			<Card
				style={{
					marginBottom: '12px',
					marginLeft: '20px',
					marginRight: '20px',
					marginTop: '20px',
				}}
			>
				<CardHeader>
					<h2 className="mb-0 floatLeft">
						Scholarship Master &nbsp;
						{<BsFillForwardFill style={{ fontSize: '30px' }} />}
						&nbsp; Add Sub Category&nbsp;
						{<BsFillForwardFill style={{ fontSize: '30px' }} />}
						&nbsp;
						<span
							onClick={() => props.history.goBack()}
							style={{ cursor: 'pointer', color: '#11CDEF' }}
						>
							Add Sub Sub Category
						</span>
						&nbsp;
						{<BsFillForwardFill style={{ fontSize: '30px' }} />}&nbsp; Setup
						Course Security
					</h2>
				</CardHeader>
			</Card>
			<Card
				style={{
					marginBottom: '12px',
					marginLeft: '20px',
					marginRight: '20px',
				}}
			>
				<CardBody>
					<CommonHeader
						headerData={headerData}
						lastUpdatedDate={
							subSubCategoryData && subSubCategoryData['updatedOn']
								? subSubCategoryData['updatedOn']
								: 'Not Found'
						}
						countLabel={'Total Active Course Security Count'}
						countValue={
							subSubCategoryData &&
							subSubCategoryData['buAndCourseSecurityCount']
								? subSubCategoryData['buAndCourseSecurityCount']
								: '0'
						}
					/>
				</CardBody>
			</Card>
			<SetupBuAndCourseTable
				subsubCategoryId={id}
				isSaveVisible={isSaveVisible}
				setIsSaveVisible={setIsSaveVisible}
				setupBuData={setupBuData}
				setSetupBuData={setSetupBuData}
				termData={termData}
				fetchData={fetchData}
				setSubSubCategoryData={setSubSubCategoryData}
			/>
			{pagination.totalPage > 1 ? (
				<GetPagination
					pagination={pagination}
					previousPage={previousPage}
					firstPage={firstPage}
					lastPage={lastPage}
					nextPage={nextPage}
				/>
			) : null}
			}
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		headerData: state.globalData.data,
	};
};

export default connect(mapStateToProps)(SetupCourseSecurity);
