import React, {useState, useEffect} from 'react';
import {Row, Col, Form, FormGroup, Label, Button} from 'reactstrap';
import Select2 from 'react-select2-wrapper';
import {fetchDetails} from '../../../common/utils/methods/commonMethods/utilityMethod';
import {masterServiceBaseUrl} from 'services/http';
import {ButtonText} from "../../../../../../variables/Buttons";
import CustomButton from "../../../../../../components/CustomButton";
import CommonInput from "../../../common/formFeilds/input/commonInput";

const CourseCatalogSequenceSearchForm = (props) => {
    const {
        groupCode,
        companyCode,
        academicCareer,
        setGroupCode,
        setCompanyCode,
        setAcademicCareer,
        selectedGroupCode,
        setSelectedGroupCode,
        selectedCompanyCode,
        setSelectedCompanyCode,
        selectedAcademicCareer,
        setSelectedAcademicCareer,
        selectedStatus,
        setSelectedStatus,
        searchHandler,
        setCourseCatalogData,
        permissionSet
    } = props;
    const status = [
        {id: 'ACTIVE', text: 'ACTIVE', desc: 'ACTIVE'},
        {id: 'INACTIVE', text: 'INACTIVE', desc: 'INACTIVE'},
    ];
    useEffect(() => {
        if (selectedGroupCode) {
            fetchDetails(
                masterServiceBaseUrl + '/companyCode/getCompanyCodeByGroup/' + selectedGroupCode,
                setCompanyCode,
                'companyCode',
                'reactSelect'
            );
        } else {
            setCompanyCode(null);
            setAcademicCareer(null);
        }
    }, [selectedGroupCode]);
    const resetFields = () => {
        setCourseCatalogData([]);
        setSelectedGroupCode(null);
        setSelectedCompanyCode(null);
        setSelectedAcademicCareer(null);
        setSelectedStatus('ACTIVE');
    };
    const SearchForm ={
        businessGroup :{
            label : 'Business Group',
            type : 'reactSelect',
            mandatory : true,
            placeHolder : 'Select Group Code',
            defaultValue :selectedGroupCode ,
            onChange : (value) => { 
                setSelectedGroupCode(value);
                setSelectedCompanyCode(null);
                setSelectedAcademicCareer(null);
            },
            data :groupCode,
            'data-testid' :'course_catalog_sequence_business_group'
        },
        companyCode : {
            label : 'Company Code',
            type : 'reactSelect',
            mandatory : true,
            placeHolder : 'Select Company Code',
            defaultValue :selectedCompanyCode ,
            onChange : (value) => {
                if (value) {
                    setSelectedCompanyCode(value);
                    setSelectedAcademicCareer(null);
                    setAcademicCareer(null);
                    fetchDetails(masterServiceBaseUrl + '/academicCareer/getAcademicCareerByCompanyCode/' + value, setAcademicCareer, 'academicCareer', 'reactSelect');
                }
            },
            data : companyCode || [],
            'data-testid' :'course_catalog_sequence_company_code'
        },
        academicCareer : {
            label : 'Academic Career',
            type : 'reactSelect',
            mandatory : true,
            placeHolder : 'Select Academic Career',
            defaultValue :selectedAcademicCareer ,
            onChange : (value) => { setSelectedAcademicCareer(value); },
            data :academicCareer || [],
            'data-testid' :'course_catalog_sequence_academic_career',
        },
        status : {
            label : 'Status',
            type : 'reactSelect',
            mandatory : true,
            placeHolder : 'Select Status',
            defaultValue :selectedStatus ,
            onChange : (value) => { setSelectedStatus(value); },
            data :status,
            'data-testid' :'course_catalog_sequence_status'
        }
    }
    return (
        <>
            <Form>
                <Row>
                    <Col md={6}>
                        <CommonInput {...SearchForm.businessGroup}/>
                    </Col>
                    <Col md={6}>
                        <CommonInput {...SearchForm.companyCode}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <CommonInput {...SearchForm.academicCareer}/>
                    </Col>
                    <Col md={6}>
                        <CommonInput {...SearchForm.status} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <CustomButton
                            data-testid="resetBtn"
                            disabled={ !selectedAcademicCareer && !selectedCompanyCode && !selectedGroupCode }
                            type="reset"
                            className={'floatRight mx-1 reset-button'}
                            content={ButtonText.CourseCatalogSequence.reset}
                            permissionType={'R'}
                            icon={true}
                            onClick={() => resetFields()}
                            permissionSet={permissionSet}
                        />
                        <CustomButton
                            data-testid="searchBtn"
                            className={'floatRight mx-1'}
                            content={ButtonText.CourseCatalogSequence.search}
                            permissionType={'S'}
                            icon={true}
                            onClick={() => searchHandler()}
                            permissionSet={permissionSet}
                        />
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default CourseCatalogSequenceSearchForm;
