import React from 'react';
import { regex } from '../../../constant';
import {failureToast} from "../toasterFunctions/function";

const numericValue = /^[0-9\b]*$/;
const alphaNumericValue = /^[A-Za-z0-9]*$/;
const alphaNumericWithSpecial = /^[a-zA-z0-9._-\s]*$/;
const alphaNumericWithSpecialChar = /^[A-Za-z0-9&,():_-\s]*$/;

const validate = (type, value) => {
	try {
		if (type === 'numericValue') {
			if (value === '' || numericValue.test(value)) {
				return true;
			}
		}
		if (type === 'alphaNumericValue') {
			if (!alphaNumericValue.test(value)) {
				return failureToast('It contains only Alpha Numeric value');
			} else {
				return true;
			}
		}
	} catch (error) {
		console.log('Error', error);
	}
};

const numericValueCheck = (value) => {
	var isValid = true;
	if (!numericValue.test(value)) {
		isValid = false;
		failureToast('It contains only Numeric value');
		return;
	}
};

const customValidation = (state, validationList) => {
	return validationList.every((element) => {
		if (element.type === 'string')
			return isRequiredForString(state[element.key], element.message);
		if (element.type === 'id')
			return isRequiredForId(
				state[element.key],
				element.message,
				element?.isObject
			);
		if(element.type === 'array'){
			return isEmptyArray(state[element.key], element.message);
		}
	});
};

/** Validates nested form */
const customValidation2 = (state, validationList) => {
	return validationList.every((element) => {
		if(!element.isRequired) return true;
		if(element.dependencies) {
			return customValidation2(state[element.key], element.dependencies);
		}
		else {
			if (element.type === 'string')
			return isRequiredForString(state[element.key], element.message) && hasMinLength(state, element);
			if (element.type === 'id')
				return isRequiredForId(
					state[element.key],
					element.message,
					element?.isObject
				);
			if(element.type === 'array'){
				return isEmptyArray(state[element.key], element.message);
			}
		}
	});
};

const hasMinLength = (state, element) => {
	if (element.minLength) {
		const isValid = state[element.key].length >= element.minLength;
		if(!isValid) {
			failureToast(element.message);
			return false;
		}
		return true
	}
	return true;
};

const isEmptyArray = (arr, message) => {
	if(!arr){
		failureToast(message);
		return false;
	}
	if(!arr.length){
		failureToast(message);
		return false;
	}
	return true;
}
const isRequiredForString = (value, message) => {
	if (value === null || value === '' || (value && value.toString().trim() === '')) {
		failureToast(message);
		return false;
	}
	if (!value) {
		failureToast(message);
		return false;
	}
	return true;
};

const isRequiredForId = (value, message, isObject) => {
	if (isObject) {
		if (!value?.value) {
			failureToast(message);
			return false;
		}
	} else {
		if (!value) {
			failureToast(message);
			return false;
		}
	}
	return true;
};

const isValidIpAddress = (value,msg) => {
	if (!regex.ipAddress.test(value)) {
		!msg && failureToast('Please Enter Valid IP Address');
		msg && failureToast(`Please Enter Valid ${msg}`);
		return false;
	}
	return true;
};

const isValidMacAddress = (value) => {
	if (!regex.macAddress.test(value)) {
		failureToast('Please Enter Valid Mac Address');
		return false;
	}
	return true;
};

const removeSpecialCharacters = (str) => {
	return str.replace(/[^a-zA-Z0-9]/g, '');
};

const validateAlphaNumericWithSpecial = (value) => {
	if (!alphaNumericWithSpecial.test(value)) {
		return true;
	}
};

const validateAlphaNumericWithSpecialChar = (value) => {
	if (!alphaNumericWithSpecialChar.test(value)) {
		return true;
	}
};

const validateAlphaNumeric = (value) => {
	if (!alphaNumericValue.test(value)) {
		return true;
	}
};

const validateFileName = (value) => {
	if (regex.fileWithoutSpecialCharacter.test(value)) {
		return false;
	}
	return true;
};

export {
	validate,
	numericValueCheck,
	removeSpecialCharacters,
	validateAlphaNumericWithSpecial,
	validateAlphaNumericWithSpecialChar,
	validateAlphaNumeric,
	customValidation,
	customValidation2,
	isValidIpAddress,
	isValidMacAddress,
	validateFileName,
};
