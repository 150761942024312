import React, { useEffect, useRef, useState } from 'react';
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Label, Row } from 'reactstrap';
import { PulseLoader } from 'react-spinners'
import './index.css'

function CustomFormDisplayField(props) {
  const {label, text, isMultiselectDisplay=false, data=[], isFetching=false, dropdownHeader = false} = props;

  
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  
  // for handling Ellipsis
  const overflowContainer = useRef(0); 
  const [overflow, setOverflow] = useState(false);


  const isEllipsisActive = (e) => {
    return e.current.offsetHeight < e.current.scrollHeight || e.current.offsetWidth < e.current.scrollWidth;
  }
  
  useEffect(() => {
    setOverflow(isEllipsisActive(overflowContainer))
    // console.log('overflow',isEllipsisActive(overflowContainer))
  }, [overflowContainer, data]);

  return (
    isMultiselectDisplay ? (
      <div style={{...props.style}}>
        <FormGroup>
          {label &&
            <Label className=''  >
              {label}
            </Label>
          }
          <div className='content' style={{display: 'flex', width: '100%'}}>
            <PulseLoader size='5px' color='gray' loading={isFetching} />
            <div
              className='text_box_multiselect'
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                height: '100%'
              }}
              ref={overflowContainer}
            >
              {data.join(', ')}
            </div>
            {overflow && 
              <div style={{ display: 'inline-block' }}>
                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} direction={'left'} className='ml-2' style={{}}>
                  <DropdownToggle className='dropdown_toggle custom_dropdown_toggle' color='link'>
                    View all
                  </DropdownToggle>
                  <DropdownMenu className='dropdown_menu custom_dropdown_menu'>
                    {/* dropdown items here */}
                    {dropdownHeader && <DropdownItem header>Total {data.length} results</DropdownItem>}
                    <Row className='px-3'>
                      {data && data.map((el, index) => {
                        return <Col md='6' className='mb-1' key={`${index}_${el}`}>{el}</Col>
                      })}
                    </Row>
                  </DropdownMenu>
                </Dropdown>
              </div>
            }
          </div>
        </FormGroup>
      </div>
    ) : (
      <div style={{...props.style}}>
        <FormGroup>
          {label &&
            <Label className='' >
              {label}
            </Label>
          }
          <div className='text_box'>
            <PulseLoader size='5px' color='gray' loading={isFetching} />{text}
          </div>
        </FormGroup>
      </div>
    )
  )
}

export default CustomFormDisplayField