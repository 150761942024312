import React, { useContext, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

// import ALoader from "views/pages/manage/common/a-loader";
import ListHeader from './ListHeader';
import SearchInput from './SearchInput';
import StudentFilterForm from './StudentFilterForm';
import './style.scss';
import rightBlueIcon from 'assets/rightArrowBlue.svg';
import { Studenttiles } from "../common";
// import { getStudentList, loadDropdowns } from "../../../../../../redux/studentCharges/actions";
import ALoader from "../../../common/a-loader";
import { FILTER__FORM_FIELDS_GLOBAL, PAGE_STATE, StudentListContext } from "../contextApi";
import { useHistory } from "react-router";
import AddToFavourite from '../../../homepage/favourites/AddToFavourite';
import { pages } from "views/pages/manage/common/constant";
import { getUpdatedChargeStatus } from "./utils";

const favouritePropMap = {
  'studentCharges': {
    screenName: pages['studentCharges']['name'],
    screenId: pages['studentCharges']['id'],
  },
  'otherCharges': {
    screenName: pages['studentOtherCharges']['name'],
    screenId: pages['studentOtherCharges']['id'],
  }
}

const StudentWidgetAndFilters = ({studentContext, storeState, loadDropdowns, getStudentList, showStudentTiles=true}) => {
  const { globalFilter, setGlobalFilter, setCurrentPageState } = useContext(studentContext);

  const history = useHistory();
  const dispatch = useDispatch();
  const studentCharges = useSelector((state) => state[storeState]);


  useEffect(() => {
    dispatch(loadDropdowns())
  }, [])

  const fetchInitialList = (obj) => {
    dispatch(
      getStudentList(
        { ...obj },
        0
      )
    );
  }

  const setFilterAndCallApi = (key, value) => {
    if(value){
      setGlobalFilter({...FILTER__FORM_FIELDS_GLOBAL, [key]: [{ label: value, value: value }] });
      fetchInitialList({...FILTER__FORM_FIELDS_GLOBAL, [key]: getUpdatedChargeStatus([value]) });
      setCurrentPageState(PAGE_STATE.LIST_STUDENT)
    }
    // history.push('/admin/studentCharges')
  }


  return (
    <div
      className="d-flex flex-column remove-container-top-space"
      style={{ minHeight: "calc(100vh - 79px)" }}
    >
      <div className="p-3 student-filer-container-printId">
        {(studentCharges?.dropdownLoader) && (
          <ALoader position={"fixed"} />
        )}
        <div className="d-flex align-items-center justify-content-between">
          <ListHeader />
          {favouritePropMap[storeState] && <AddToFavourite {...favouritePropMap[storeState]} />}
        </div>
        <SearchInput 
        studentContext={studentContext}
        getStudentList={getStudentList}
        />
        <StudentFilterForm 
        studentContext={studentContext}
        getStudentList={getStudentList}
        storeState={storeState}
        />
      </div>
      {showStudentTiles && <div className="m-3">
        <Row style={{ rowGap: '25px' }}>
          {Studenttiles.map(item => {
            return <Col md={4}>
              <div className="student-charges-filter-card-items">
                <div className="d-flex justify-content-between align-items-center card-title-section">
                  <div>
                      <h2>{item?.title ?? ''}</h2>
                      <p className="">{item?.subtitle ?? ''}</p>
                  </div>
                 {/* {studentCharges?.dropdowns.count?.[item.countKey] || studentCharges?.dropdowns.count?.[item.countKey] === 0 ?  <span style={{padding:'9px 16px',borderRadius:'15px',backgroundColor:'#262626',size:'14px',fontWeight:700,color: '#fff'}}>{studentCharges?.dropdowns.count?.[item.countKey]}</span> : null} */}
                </div>
                <div className="view-list cursor" onClick={() => setFilterAndCallApi(item.searchKey, item.searchVal)}>Click here view list <img src={rightBlueIcon} width='24px' height='24px' /></div>
              </div>
            </Col>
          })}
        </Row>
      </div>}
    </div>
  );
};

export default StudentWidgetAndFilters;
