import { Box, Dialog, FormControlLabel, Tab, Tabs, Typography } from '@mui/material';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { PermisionDenied } from '../../common/commonComponents';
import './index.scss';
import { PermissionContext, RolePermissions, UserDetails } from "../../../../../appContext";
import { endpoint, pages } from '../../common/constant';
import { useHistory, useLocation } from 'react-router';
import ApprovalBranch from './approvalBranch';
import CustomButton from '../../../../../components/CustomButton';
import ApprovalDialogContent from './dialogContent';
import CommonInput from '../../common/formFeilds/input/commonInput';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { clearApprovalData, clearSelectedRowsIds, getAllActiveConfigs, getAllActiveCourseCategory, getAllActiveCourseIds, getAllActiveRegions, getAllBusinessAreas, getApprovalRequests, getOtherChargesForApproval, setApproverType, setRole, setSelectedRowIds, setVerificationRows } from '../../../../../redux/concessionApproval/actions';
import { constants } from "../../common/constant/constants/index";
import CustomLoader from '../../common/commonComponents/Loader/loader';
import { getRequestPayload, getSuccessMsg } from './common';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import { fetchAllPostPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
// import SearchInput from '../studentCharges/SearchInput';
import SearchInput from './SearchInput';
import ApprovalFilter from './approvalFilter';
import DocReviewPopup from './docReviewPopup';
import { Checkbox } from 'antd';
import OtherchargesApprovalBranch from './otherChargesApprovalBranch';
import CustomPagination from './pagination'
import ALoader from '../../common/a-loader';
import { MdOutlineFileDownload } from "react-icons/md"
import NoApprovalRequestsOverlay from './NoApprovalRequestsOverlay';
import { getClassificationData } from '../studentCharges/studentChargesDetails';
import CustomPaginationWithoutCount from '../../../../../components/CustomPaginationWithoutCount/CustomPaginationWithoutCount';

const ConcessionApproval = props => {

    const { APPROVAL_ACTIONS, APPROVER_TYPE, APPROVER_TYPES_DATA, STAGES, APPROVAL_STAGE, DOCUMENT_VERFICATION_STATUS } = constants;

    const permissions = useContext(PermissionContext);
    const userDetails = useContext(UserDetails);

    const userPermissions = RolePermissions(permissions, pages["concessionApproval"]['id']);
    const location = useLocation();
    const locationData = location?.state;
    const approvalState = useSelector(state => state.concessionApproval);
    const dispatch = useDispatch();
    const history = useHistory();

    const [value, setValue] = useState(APPROVAL_STAGE.PENDING);
    const [open, setOpen] = useState(false);
    const [bulkAction, setBulkAction] = useState('');
    const [appliedFilters, setAppliedFilters] = useState({});
    const [isOtherChargesListing, setOtherChargesListing] = useState(false);
    const [appliedPsid, setAppliedPsid] = useState('');

    const [allCategories, setAllCategories] = useState([]);

    const filterRef = useRef(null);

    const handleChange = (event, newValue) => {
        history.push(`/admin/concessionApproval/${newValue}`);
    };

    const { stage } = props.match.params;

    // const [currentPage, setCurrentPage] = useState(0);
    const totalRecords = approvalState?.totalRecords ?? 0;
    const currentPage = approvalState?.currentPage ?? 0;// Replace this with your actual total number of records
    const recordsPerPage = 50; // Change this to your desired number of records per page
    const totalPages = Math.ceil(totalRecords / recordsPerPage);
    const [approving, setApproving] = useState(false);

    useEffect(() => {
        dispatch((clearSelectedRowsIds()));
        dispatch(setRole(permissions.roleType));
        dispatch(getAllActiveCourseCategory());
        dispatch(getAllActiveRegions());
        // dispatch(getAllActiveCourseIds());
        dispatch(getAllActiveConfigs());
        !approvalState.allBusinessAreas?.length && dispatch(getAllBusinessAreas());

        if (locationData?.psid && locationData?.status && locationData?.roleId) {

            dispatch((clearSelectedRowsIds()));
            dispatch(getApprovalRequests({
                approvalStatus: `${locationData.roleId}_${locationData.status?.toString()?.toUpperCase()}`,
                psid: locationData.psid,
                businessAreaValue: appliedFilters?.businessAreaValue?.length ? appliedFilters?.businessAreaValue : approvalState.allBusinessAreas.map(i => i.label)
            }, 0));
            // setCurrentPage(0);
        }
        getClassificationData(setAllCategories);
    }, []);


    useEffect(() => {
        return () => {
            dispatch(clearApprovalData());
        }
    }, [])

    useEffect(() => {
        if (stage) {
            // setCurrentPage(0);
            setValue(stage);
        } else {
            // setCurrentPage(0);
            history.push(`/admin/concessionApproval/${APPROVAL_STAGE.PENDING}`);
        }
    }, [stage]);

    useEffect(() => {
        setAppliedPsid('');
        if (approvalState.allBusinessAreas?.length > 0) {
            if (isOtherChargesListing && value) {
                dispatch(getOtherChargesForApproval(
                    {
                        ...appliedFilters,
                        "psid": "",
                        "approvalStatus": value,
                        // "requestedOnTo": "",
                        // "requestedOnFrom": "",
                        "businessArea": appliedFilters?.businessArea?.length ? appliedFilters?.businessArea : approvalState.allBusinessAreas.map(i => i.label),
                        // "chargeType": []
                    }, 0
                ))
            } else if (approvalState.approverType && value && !locationData) {
                dispatch((clearSelectedRowsIds()));
                dispatch(getApprovalRequests(
                    {
                        ...appliedFilters,
                        approvalStatus: `${approvalState.approverType.value}_${value.toString()?.toUpperCase()}`,
                        businessAreaValue: appliedFilters?.businessAreaValue?.length ? appliedFilters?.businessAreaValue : approvalState.allBusinessAreas.map(i => i.label)
                    },

                    0));
            }
        }
        // setCurrentPage(0);
    }, [isOtherChargesListing, value, approvalState.allBusinessAreas, approvalState.approverType]);

    // useEffect(() => {
    //     if (locationData?.psid && locationData?.status && locationData?.roleId) {
    //         dispatch((clearSelectedRowsIds()));
    //         dispatch(getApprovalRequests({
    //             roleId: locationData.roleId,
    //             status: locationData.status,
    //             psid: locationData.psid
    //         }));
    //     }
    // }, [locationData])

    const searchByPsid = (psid) => {
        setAppliedPsid(psid);
        if (isOtherChargesListing && value) {
            dispatch(getOtherChargesForApproval({
                ...appliedFilters,
                "psid": psid,
                "approvalStatus": stage,
                "businessArea": appliedFilters?.businessArea?.length ? appliedFilters?.businessArea : approvalState.allBusinessAreas.map(i => i.label)
            }, 0));
            // setCurrentPage(0);
        } else if (value && approvalState.approverType) {
            dispatch((clearSelectedRowsIds()));
            dispatch(getApprovalRequests({
                ...appliedFilters,
                // roleId: approvalState.approverType.value,
                // status: value,
                approvalStatus: `${approvalState.approverType.value}_${value.toString()?.toUpperCase()}`,
                psid,
                businessAreaValue: appliedFilters?.businessAreaValue?.length ? appliedFilters?.businessAreaValue : approvalState.allBusinessAreas.map(i => i.label)
            }, 0));
            // setCurrentPage(0);
        }
    }

    const crossInputHandler = () => {
        setAppliedPsid('');
        if (isOtherChargesListing && value) {
            dispatch(getOtherChargesForApproval({
                ...appliedFilters,
                "psid": "",
                "approvalStatus": stage,
                "businessArea": appliedFilters?.businessArea?.length ? appliedFilters?.businessArea : approvalState.allBusinessAreas.map(i => i.label)
            }, 0));
        } else if (value && approvalState.approverType) {
            dispatch((clearSelectedRowsIds()));
            dispatch(getApprovalRequests({
                ...appliedFilters,
                // roleId: approvalState.approverType.value,
                // status: value,
                approvalStatus: `${approvalState.approverType.value}_${value.toString()?.toUpperCase()}`,
                businessAreaValue: appliedFilters?.businessAreaValue?.length ? appliedFilters?.businessAreaValue : approvalState.allBusinessAreas.map(i => i.label)
            }, 0));
        }
    }

    const handleActionClick = async (remarks) => {
        const approvalStatus = bulkAction === constants.APPROVAL_ACTIONS.APPROVE ?
            constants.APPROVAL_STAGE.APPROVED :
            bulkAction === constants.APPROVAL_ACTIONS.REJECT ?
                constants.APPROVAL_STAGE.REJECTED :
                constants.APPROVAL_STAGE.RECHECK;
        const payload = getRequestPayload(remarks, approvalState, approvalStatus, userDetails);

        try {
            const response = await fetchAllPostPromisedData(endpoint.approval.status, payload, 'put');
            if (response?.code === 200) {
                successToast(getSuccessMsg(approvalStatus));
                dispatch((clearSelectedRowsIds()));
                dispatch(getApprovalRequests({
                    // roleId: approvalState.approverType.value,
                    // status: value,
                    ...appliedFilters,
                    approvalStatus: `${approvalState.approverType.value}_${value.toString()?.toUpperCase()}`,
                    businessAreaValue: appliedFilters?.businessAreaValue?.length ? appliedFilters?.businessAreaValue : approvalState.allBusinessAreas.map(i => i.label),
                }, 0));
                setOpen(false);
            } else {
                failureToast("Details not saved.");
            }
        } catch (err) {
            failureToast("Details not saved.");
        }
    }

    const onSelectAll = (allRowSelected) => {
        if (allRowSelected) {
            dispatch((clearSelectedRowsIds()));
        } else {
            Object.keys(approvalState.groupedRequests).forEach(businessAreaId => {
                const ids = approvalState.groupedRequests[businessAreaId].map(item => item.applicationQuotationDetailsId);
                dispatch(setSelectedRowIds(businessAreaId, ids));
            })
        }
    }

    const getTitle = (stage, totalRows) => {
        switch (stage) {
            case APPROVAL_STAGE.PENDING: return `${totalRows} request${totalRows === 1 ? '' : 's'} ${stage}`;
            case APPROVAL_STAGE.APPROVED: return `Approved concessions`;
            case APPROVAL_STAGE.REJECTED: return `Rejected concessions`;
            case APPROVAL_STAGE.RECHECK: return `Concessions sent for recheck`;
            default: return ``;
        }
    }

    const otherChargesPageHandler = (p) => {
        // setCurrentPage(p)
        dispatch(getOtherChargesForApproval({ ...appliedFilters, approvalStatus: stage, businessArea: appliedFilters?.businessArea?.length ? appliedFilters?.businessArea : approvalState.allBusinessAreas.map(i => i.label), }, p))
    }

    const chargesPageHandler = (p) => {
        // setCurrentPage(p)
        dispatch(getApprovalRequests({ ...appliedFilters, approvalStatus: `${approvalState.approverType.value}_${value.toString()?.toUpperCase()}`, businessAreaValue: appliedFilters?.businessAreaValue?.length ? appliedFilters?.businessAreaValue : approvalState.allBusinessAreas.map(i => i.label), }, p))
    }

    const handleExport = async () => {

        const reqObj = isOtherChargesListing
            ? {
                ...appliedFilters,
                psid: appliedPsid,
                approvalStatus: value,
                businessArea: appliedFilters?.businessArea?.length ? appliedFilters?.businessArea : approvalState.allBusinessAreas.map(i => i.label)
            }
            : {
                ...appliedFilters,
                ...(appliedPsid && { psid: appliedPsid }),
                approvalStatus: `${approvalState.approverType.value}_${value.toString()?.toUpperCase()}`,
                businessAreaValue: appliedFilters?.businessAreaValue?.length ? appliedFilters?.businessAreaValue : approvalState.allBusinessAreas.map(i => i.label)
            }

        const url = endpoint.downloadApprovalRequests[isOtherChargesListing ? 'downloadOtherChargesRequests' : 'downloadChargesRequests'];

        try {
            const resp = await fetchAllPostPromisedData(url, reqObj);
            if (resp?.code === 200) {
                const templateUrl = await fetchAllPostPromisedData(`${endpoint.fetchS3Url.getDownloadUrl}?key=${resp?.data?.key}`, {})
                if (templateUrl?.code === 200 && templateUrl?.data) {
                    window.open(`${templateUrl?.data?.url}`)
                } else {
                    failureToast("Something went wrong please try after sometime.")
                }
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const selectedRowsCount = Object.values(approvalState?.selectedRowIds || {}).reduce((acc, curr) => acc + curr.length, 0);
    const loading = approvalState.allActiveConfigsLoading && approvalState.allApprovalRequestsLoading;
    const totalRows = Object.values(approvalState.groupedRequests).reduce((acc, curr) => acc + curr.length, 0);
    const otherChargesTotalRows = Object.values(approvalState.otherChargesGroupData).reduce((acc, curr) => acc + curr.length, 0);
    const allRowSelected = totalRows !== 0 && totalRows === Object.values(approvalState.selectedRowIds).reduce((acc, curr) => acc + curr.length, 0);
    // const verificationPending = bulkAction === APPROVAL_ACTIONS.APPROVE && approvalState.selectedVerificationRows?.some(req => req.documentVerificationStatus === DOCUMENT_VERFICATION_STATUS.PENDING) || [];

    return (
        userPermissions ? (
            (approvalState.masterDataLoading || approvalState.allActiveConfigsLoading) ? <CustomLoader /> : (
                <>
                    {approving ? <ALoader position={"fixed"} /> : null}

                    <Box className='concession-approval-screen'>
                        <Box className='concession-approval-tabs'>
                            <Tabs value={value} onChange={handleChange} aria-label="concession approval tabs" centered>
                                {STAGES.map((tab) => {
                                    return isOtherChargesListing && tab.label === 'Recheck' ? null : <Tab key={tab.value} label={tab.label} value={tab.value} />
                                })}
                            </Tabs>
                        </Box>
                        <Box className='concession-approval-content'>
                            <Box className='concession-approval-content-header'>
                                {!isOtherChargesListing ? <Typography variant="h6">
                                    {getTitle(stage, totalRows)}
                                </Typography> : <div></div>}
                                <Box className='concession-approval-filter-container'>
                                    {stage !== 'recheck' ? <Box className='approver-type'>
                                        <CommonInput
                                            type="select"
                                            defaultValue={isOtherChargesListing ? { label: 'Other Charges', value: 'OTHERCHARGES' } : { label: 'Charges', value: 'CHARGES' }}
                                            data={[{ label: 'Charges', value: 'CHARGES' }, { label: 'Other Charges', value: 'OTHERCHARGES' }]}
                                            placeHolder=""
                                            forTable={true}
                                            onChange={(charge) => {
                                                filterRef.current.resetFilters();
                                                setAppliedFilters({});
                                                charge.value === 'OTHERCHARGES' ? setOtherChargesListing(true) : setOtherChargesListing(false)
                                            }}
                                        />
                                    </Box> : null}
                                    {!isOtherChargesListing ? <Box className='approver-type'>
                                        <CommonInput
                                            type="select"
                                            defaultValue={approvalState.approverType}
                                            data={APPROVER_TYPES_DATA}
                                            placeHolder=""
                                            forTable={true}
                                            onChange={(type) => dispatch(setApproverType(type))}
                                        />
                                    </Box> : null}
                                    <SearchInput
                                        onCancel={crossInputHandler}
                                        searchInputValueHandler={searchByPsid}
                                        placeholder="Enter the 11 digit PSID number"
                                        errorMsg={true}
                                    />
                                    <ApprovalFilter userPermissions={userPermissions} stage={stage} setAppliedFilters={setAppliedFilters} isOtherChargesListing={isOtherChargesListing} appliedFilters={appliedFilters} setAppliedPsid={setAppliedPsid} ref={filterRef}/>

                                    <CustomButton
                                        className=''
                                        content={<span><MdOutlineFileDownload size={16} style={{ marginBottom: '2px' }} /> Export</span>}
                                        permissionType={'C'}
                                        permissionSet={userPermissions}
                                        forTable={true}
                                        outline
                                        onClick={handleExport}
                                    />

                                    {!isOtherChargesListing ? <CustomButton
                                        className=''
                                        content={allRowSelected ? "Unselect All" : "Select All"}
                                        permissionType={'C'}
                                        permissionSet={userPermissions}
                                        forTable={true}
                                        outline
                                        onClick={() => onSelectAll(allRowSelected)}
                                        disabled={totalRows === 0 || value !== APPROVAL_STAGE.PENDING}
                                    /> : null}
                                </Box>
                            </Box>

                            {( !isOtherChargesListing ? approvalState.allApprovalRequestsLoading : approvalState.otherChargesRequestsLoading) && <ALoader position={"fixed"} />}

                            {(isOtherChargesListing ? (otherChargesTotalRows === 0 && !approvalState.otherChargesRequestsLoading) : (totalRows === 0 && !approvalState.allApprovalRequestsLoading))
                                ? <NoApprovalRequestsOverlay />
                                : <>
                                    {!isOtherChargesListing ?
                                        <>{Object.keys(approvalState.groupedRequests).map((businessAreaId) => {
                                            return (
                                                <ApprovalBranch
                                                    key={businessAreaId}
                                                    userDetails={userDetails}
                                                    stage={value}
                                                    businessAreaId={businessAreaId}
                                                    rowsData={approvalState.groupedRequests[businessAreaId]}
                                                    loading={loading}
                                                    userPermissions={userPermissions}
                                                    appliedFilters={appliedFilters}
                                                    appliedPsid={appliedPsid}
                                                    setApproving={setApproving}
                                                    allCategories={allCategories}
                                                />
                                            )
                                        })}
                                        </>
                                        :
                                        <>
                                            {Object.keys(approvalState?.otherChargesGroupData ?? {}).map((businessArea) => {
                                                return (
                                                    <OtherchargesApprovalBranch
                                                        key={businessArea}
                                                        userDetails={userDetails}
                                                        stage={value}
                                                        businessAreaId={businessArea}
                                                        rowsData={approvalState.otherChargesGroupData[businessArea]}
                                                        loading={loading}
                                                        userPermissions={userPermissions}
                                                        appliedFilters={appliedFilters}
                                                        currentPage={currentPage}
                                                    // setCurrentPage={setCurrentPage}
                                                    />
                                                )
                                            })}
                                        </>
                                    }


                                    {
                                        isOtherChargesListing && !approvalState.otherChargesRequestsLoading && totalPages > 1 && Object.keys(approvalState.otherChargesGroupData).length > 0 ? <CustomPagination
                                        currentPage={currentPage}
                                        totalPages={totalRecords}
                                        onPageChange={otherChargesPageHandler}
                                        /> : null
                                    }
                                    {
                                        !isOtherChargesListing && !approvalState.allApprovalRequestsLoading && Object.keys(approvalState.groupedRequests).length > 0 ?
                                            <CustomPaginationWithoutCount
                                                currentPage={currentPage}
                                                noOfCurrentPageRecords={totalRows}
                                                onPageChange={chargesPageHandler}
                                            /> : null
                                    }
                                </>
                            }
                        </Box>
                        {selectedRowsCount > 1 && <Box className='concession-approval-bulk-footer'>
                            <Box className='d-flex'>
                                <Box>
                                    <Typography variant="h6" color={'#0F0F0F'}>
                                        {`${selectedRowsCount} concession selected`}
                                    </Typography>
                                    <Typography variant="caption" display="block" color={'#757575'}>
                                        What do you want to do next?
                                    </Typography>
                                </Box>
                                <Box className='approval-action-buttons'>
                                    <CustomButton
                                        className='approval-action-approve-button'
                                        content={"Approve"}
                                        permissionType={'A'}
                                        permissionSet={userPermissions}
                                        onClick={() => { setOpen(true); setBulkAction(APPROVAL_ACTIONS.APPROVE); dispatch(setVerificationRows()); }}
                                    />
                                    <CustomButton
                                        className='approval-action-reject-button'
                                        type="cancel"
                                        content={'Reject'}
                                        permissionType={'A'}
                                        permissionSet={userPermissions}
                                        onClick={() => { setOpen(true); setBulkAction(APPROVAL_ACTIONS.REJECT) }}
                                    />
                                    {userPermissions?.includes('A') ? <Typography
                                        className='approval-action-recheck-button'
                                        variant="body1"
                                        display="block"
                                        color={'#00B0F5'}
                                        onClick={() => { setOpen(true); setBulkAction(APPROVAL_ACTIONS.RECHECK) }}
                                    >
                                        Recheck
                                    </Typography> : null }
                                </Box>
                            </Box>
                            <CustomButton
                                className={'unselect-all-button'}
                                content={"Unselect All"}
                                permissionType={'C'}
                                permissionSet={userPermissions}
                                forTable={true}
                                outline
                                co
                                onClick={() => onSelectAll(true)}
                            // disabled={totalRows === 0 || value !== APPROVAL_STAGE.PENDING}
                            />
                        </Box>}
                        <Dialog open={open && bulkAction !== APPROVAL_ACTIONS.APPROVE} onClose={() => setOpen(false)}>
                            <ApprovalDialogContent isBulk={true} userPermissions={userPermissions} handleClose={() => setOpen(false)} onActionClick={handleActionClick} action={bulkAction} />
                        </Dialog>
                        <DocReviewPopup requests={approvalState.selectedVerificationRows} openPopOver={open && bulkAction === APPROVAL_ACTIONS.APPROVE} handleClose={() => setOpen(false)} userPermissions={userPermissions} appliedFilters={appliedFilters} setApproving={setApproving} />
                    </Box>
                </>
            )
        ) : (
            <PermisionDenied />
        )
    );
}

export default ConcessionApproval;