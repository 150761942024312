import React, { useContext, useState } from "react";
import ADataGrid from "../../common/data-grid/ADataGrid";
import { AGridColDef } from "../../common/data-grid/ADataGridColDef";
import { useHistory } from "react-router";
import EmptyListPlaceholder from "../../edpOps/studentSerach/EmptyListPlaceholder";
import { Tooltip } from "@mui/material";
// import ViewBatchDetailsDialog from "./ViewBatchDetailsDialog";
import { CourseDetailsPopupById, EyeIcon, chargeStatusMapping } from "./common";
import moment from "moment";
import { checkPermission, openInNewTab } from "../../common/utils/methods/commonMethods/utilityMethod";
import { pages } from "../../common/constant";
import { PermissionContext, RolePermissions } from 'appContext';
import CustomPaginationWithoutCount from "components/CustomPaginationWithoutCount/CustomPaginationWithoutCount";
// import StudentWidgetAndFilters from "./studentFiltersandwidget"
import { ConfirmationDialog } from "../../common/commonComponents";
import { IoInformation } from "react-icons/io5";
import { transformChargeStatus } from "./studentChargesDetails/common";

const commonStyle = {
  fontSize: '12px',
  color: '#414141',
  display: 'flex'
}

const headerStyle = {
  //styleName: Body/Semibold;
  fontFamily: 'Inter-Bold',
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0em',
  textSlign: 'left'

}

export const paymentStatus = {
  "not paid": { backgroundColor: '#FFEDC8', color: '#705010', padding: '4px 11px', fontSize: '12px', width: 'inherit', borderRadius: '5px' },
  "partially paid": { backgroundColor: '#FFEDC8', color: '#705010', padding: '4px 11px', fontSize: '12px', width: 'inherit', borderRadius: '5px' },
  "fully paid": { backgroundColor: '#FFEDC8', color: '#705010', padding: '4px 11px', fontSize: '12px', width: 'inherit', borderRadius: '5px' },
}
export const chargesStatus = {
  VERY: { backgroundColor: '#FFEDC8', color: '#705010', padding: '4px 11px', fontSize: '12px', borderRadius: '5px' },
  PROV: { backgroundColor: '#FFEDC8', color: '#705010', padding: '4px 11px', fontSize: '12px', borderRadius: '5px' },
  CONC: { backgroundColor: '#CCEFFD', color: '#006A93', padding: '4px 11px', fontSize: '12px', borderRadius: '5px' },
  APPL: { backgroundColor: '#BBE2CE', color: '#0B442D', padding: '4px 11px', fontSize: '12px', borderRadius: '5px' },
  MODY: { backgroundColor: '#CCEFFD', color: '#006A93', padding: '4px 11px', fontSize: '12px', borderRadius: '5px' },
  CANC: { backgroundColor: '#f5f5f5', color: '#6b6b6b', padding: '4px 11px', fontSize: '12px', borderRadius: '5px' },
  PAPP: { backgroundColor: '#CCEFFD', color: '#006A93', padding: '4px 11px', fontSize: '12px', borderRadius: '5px' },
}

export const programActionStyles = {
  APPL: { backgroundColor: '#BBE2CE', color: '#0B442D', padding: '4px 11px', fontSize: '12px', borderRadius: '5px' },
  MATR: { backgroundColor: '#CCEFFD', color: '#006A93', padding: '4px 11px', fontSize: '12px', borderRadius: '5px' },
  DISC: { backgroundColor: '#FFEDC8', color: '#705010', padding: '4px 11px', fontSize: '12px', borderRadius: '5px' },
}

const defaulStatustStyles = { backgroundColor: '#FFEDC8', color: '#705010', padding: '4px 11px', fontSize: '12px', borderRadius: '5px' };

const StudentList = ({
  rowsData,
  rowCount,
  nextButtonHandler,
  currentPage,
  isLastPage,
  courseIdDropdown
}) => {
  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["studentCharges"]['id']);

  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);
  const [openedPsid, setOpenedPsid] = useState("");
  const [transferApplicationDialogOpen, setTransferApplicationDialogOpen] = useState(false);



  const BatchListHeader = [
    new AGridColDef("psid", "PSID").renderCellComponent((params) => (
      <span
        onClick={() => window.open(history.createHref({ pathname: `/admin/edp/studentDetails/${params.value}` }), '_blank')}
        style={{ color: "#00B0F5", fontSize: "12px", cursor: "pointer" }}
      >
        {params.value}
      </span>
    )),
    new AGridColDef("applicationId", "App. ID").renderCellComponent((params =>
      <span style={commonStyle}>{params.value}</span>
    )),
    new AGridColDef("studentName", "Student name")
      .renderCellComponent(params => <Tooltip title={params?.value}><div className='ellipsis-text-container'>{params?.value ?? '-'}</div></Tooltip>)
    ,
    new AGridColDef("studentFatherName", "F name")
      .renderCellComponent(params => <Tooltip title={params?.value}><div className='ellipsis-text-container'>{params?.value ?? '-'}</div></Tooltip>),
    new AGridColDef("studentMotherName", "M name").
      renderCellComponent(params => <Tooltip title={params?.value}><div className='ellipsis-text-container'>{params?.value ?? '-'}</div></Tooltip>),
    new AGridColDef("businessAreaDispVal", "BU").renderCellComponent((params =>
      <span style={commonStyle}>{params.value}</span>
    )),
    new AGridColDef("programAction", "Program Action").renderCellComponent((params =>
      <span style={programActionStyles?.[params.value] ?? defaulStatustStyles}>{params.value}</span>
    )),
    new AGridColDef("courseId", "Course ID").renderCellComponent(params =>
      (
      <div style={commonStyle}><span>{params.value}</span>&nbsp;
        <span><CourseDetailsPopupById courseId={params.value} /></span>
      </div>
    )),
    new AGridColDef("term", "Term").renderCellComponent((params =>
      <span style={commonStyle}>{params.value?.dispValue}</span>
    )),
    new AGridColDef("applicationDate", "App. date").renderCellComponent((params =>
      <span style={commonStyle}>{params.value ? moment(params.value).format("DD-MMM-YYYY") : '-'}</span>
    )),
    // new AGridColDef("updatedOn", "Updated on").renderCellComponent((params =>
    //   <Tooltip title={`By ${params.row?.updatedBy} at ${moment(params.value).format('hh:mm a ')}`}>
    //   <span style={commonStyle}>{params.value ? moment(params.value).format("DD-MMM-YYYY") : '-'}</span>
    //   </Tooltip>
    // )),
    new AGridColDef("chargesStatus", "Charge status").renderCellComponent((params =>
      {
        const chargeStatus = transformChargeStatus(params.value);
      return <Tooltip title={chargeStatusMapping[chargeStatus] ?? '-'}>
      <span style={chargesStatus?.[chargeStatus] ?? {}}>{chargeStatus ?? '-'}</span>
      </Tooltip>
      }
    )),
    // new AGridColDef("paymentStatus", "Payment status").renderCellComponent((params =>
    //   <span style={paymentStatus?.[params.value?.toLowerCase()] ?? {}}>{params.value ?? '-'}</span>
    // )),
    new AGridColDef("Actions", "Action", false)
      .setWidth(200)
      .renderCellComponent((params) => {
        return !params?.row?.applicationQuotationDetailsId ? (
          checkPermission(userPermissions, 'C') && <Tooltip title="Add">
            <span
              onClick={() => {
                if (params?.row?.transferApplicationId !== null) setTransferApplicationDialogOpen(true)
                else openInNewTab('/details/' + params.row.applicationId + "/add")
              }}
              style={{
                color: " #00B0F5",
                cursor: "pointer",
              }}
            >
              Add +
            </span>
          </Tooltip>
        ) : (
          <>
            {checkPermission(userPermissions, 'U') && <Tooltip title="Edit">
              <span
                onClick={() => {
                  if (params?.row?.transferApplicationId !== null) setTransferApplicationDialogOpen(true)
                  else openInNewTab('/details/' + params.row.applicationId + "/edit")
                }}
                style={{
                  color: " #00B0F5",
                  cursor: "pointer",
                }}
              >
                Edit
              </span>
            </Tooltip>}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Tooltip title="View">
              <span
                onClick={() => openInNewTab('/details/' + params.row.applicationId + "/view")}
                style={{
                  color: " #00B0F5",
                  cursor: "pointer",
                }}
              >
                <EyeIcon color={"#00B0F5"} />
              </span>
            </Tooltip>
          </>
        )
      }),
  ];

  return (
    <>

      {/* <StudentWidgetAndFilters /> */}

      <ADataGrid
        rows={rowsData}
        columns={BatchListHeader}
        rowId={(row) => row.applicationId}
        loading={rowsData.length === 0}
        // rowCount={rowCount}
        // nextButtonHandler={nextButtonHandler}
        // currentPage={currentPage}
        emptyRowPlaceholder={() => <EmptyListPlaceholder />}
        sortColumnName="psid"
        hidePagination={true}
      />
      <CustomPaginationWithoutCount
        currentPage={currentPage}
        onPageChange={nextButtonHandler}
        isLastPage={isLastPage}
        noOfCurrentPageRecords={rowsData.length}
      />
      <ConfirmationDialog
        type="info"
        msg={"This is a Transfer in/out Application, Please continue the Charge application on the PSERP."}
        setIsOpen={setTransferApplicationDialogOpen}
        isOpen={transferApplicationDialogOpen}
        onAgree={() => setTransferApplicationDialogOpen(false)}
        headerMsg={"Info"}
        popupSymbol={<IoInformation />}
        crossIconRequired={true}
      />
    </>
  );
};

export default StudentList;
