import React, { useRef } from "react";
import CustomChip from "views/pages/manage/common/customChip/CustomChip";
import ADataGrid from "views/pages/manage/common/data-grid/ADataGrid";
import AButton from "views/pages/manage/common/form-fields-mui/AButton";
import { AGridColDef } from "views/pages/manage/common/data-grid/ADataGridColDef";
import { gridStyles, gridComponents } from "../printIdCardAndMapRfid/constant";
import moment from "moment";
import { openFileInNewWindow } from "./service";
import ALoader from "views/pages/manage/common/a-loader";
import { Box } from "@mui/material";

const statusChip = {
  SUCCESS: { text: "Completed", textColor: "##0B442D", bgColor: "#BBE2CE" },
  FAILED: { text: "Failed", textColor: "#B32306", bgColor: "#F9D9D7" },
  PROCESSING: { text: "In-processing", textColor: "#006A93", bgColor: "#CCEFFD" },
};

const Table = ({ list, fetchList = () => { }, tableLoader = false }) => {
  const headers = [
    new AGridColDef("createdOn", "Upload date").setWidth(200)
      .renderCellComponent((params) => (
        <span>{moment(params.value).format("DD/MM/yyyy")}</span>
      )),
    new AGridColDef("processInstanceId", "Process Id").setWidth(200)
      .renderCellComponent((params) => <span>{params.value || " --"}</span>),
    new AGridColDef("status", "Status").setWidth(200)
      .renderCellComponent((params) => {
        const statusChipColor = statusChip[params.value];
        return (
          <CustomChip
            bgColor={statusChipColor?.["bgColor"]}
            textColor={statusChipColor?.["textColor"]}
            text={statusChipColor?.["text"]}
          >
            {params.value}
          </CustomChip>
        );
      }),
    new AGridColDef("errorKey", "Action", false).setWidth(200).columnAlign('right')
      .renderCellComponent((params) => (
        <AButton
          className='btn-left-0'
          size='xs'
          variant='primary_filled'
          onClick={() => openFileInNewWindow(params.value)}
        >
          Download
        </AButton>
      )),
  ];

  return (
    <div className="flex-grow-1 d-flex flex-column">
      <div
        style={{
          fontSize: "16px",
          fontWeight: "600",
          lineHeight: "24px",
          paddingBottom: "10px",
        }}
      >
        File upload history <AButton onClick={fetchList}>Refresh List</AButton>
      </div>
      <div
        style={{
          position: 'relative',
          height: 'calc(100% - 114px)',
          border: "1px solid #CBCBCB",
          borderRadius: "12px",
          padding: '20px',
        }}
      >
        {tableLoader && <ALoader />}
        <ADataGrid
          removeWrapperContainer={true}
          rows={list}
          columns={headers}
          rowId={(row) => row.id}
          serverPagination={false}
          sx={{
            ...gridStyles,
            '.MuiDataGrid-main': {
              // height: `${60 + list?.length * 50}px`,
              // minHeight: `${60 + 50}px`,
              // maxHeight: getMaxHeightForTable(),
            },
            ".MuiDataGrid-footerContainer": {
              display: "none",
            },
          }}
          rowHeight={50}
          height={100}
          components={{ ...gridComponents }}
        />
      </div>
    </div>
  );
};

export default Table;
