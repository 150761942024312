import React, { useState, useEffect, useContext } from 'react';

import { Accordion } from '../../../../common/commonComponents';
import { GridRowModes } from "@mui/x-data-grid";
import '../index.scss';

import { Tooltip } from '@material-ui/core';
import ActionsCellComponent from './ActionsCellComponent';
import AAutoComplete from 'views/pages/manage/common/form-fields-mui/AAutoComplete';
import { dummyDropDown, dummyDropDown2, selectStyles, options } from './common';
import ADataGrid from 'views/pages/manage/common/data-grid/ADataGrid';
import NoRowOverlay from './NoRowOverlay';
import AInput from 'views/pages/manage/common/formFeilds/customInput/AInput';
import { failureToast } from 'views/pages/manage/common/utils/methods/toasterFunctions/function';
import { isObjEmpty } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { fetchAllPostPromisedData } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod';
import { endpoint, pages } from '../../../../common/constant';
import { fetchAllPromisedData, fetchDeletePromisedData } from '../../../../common/utils/methods/commonMethods/utilityMethod';
import { successToast } from '../../../../common/utils/methods/toasterFunctions/function';
import { EyeIcon } from '../../../studentCharges/common';
import { Dialog } from '@mui/material';
import CustomButton from '../../../../../../../components/CustomButton';
import CustomChip from '../../../../common/customChip/CustomChip';
import TaxTooltip from './TaxTooltip';
import { PermissionContext, RolePermissions } from '../../../../../../../appContext';


export const parse2Digit = (value)=>{
  return Number(value).toFixed(2)
}

const commonStyle = {
  fontSize: '12px',
  color: '#414141',
  display: 'flex'
}

const approvalStatusChipInfo = {
  "APPLIED": { bgColor: '#1D7AFC', text: 'Applied' },
  "PENDING": { bgColor: '#8270DB', text: 'Pending for Approval' },
  "APPROVED": { bgColor: '#22A06B', text: 'Approved'},
  "PAYMENT_IN_PROGRESS": { bgColor: '#FEA362', text: 'Payment In Progress' },
  "PAID": { bgColor: '#22A06B', text: 'Paid' },
  "REJECTED": { bgColor: '#F9D9D7', text: 'Rejected', textColor: '#B32306' },
  "Cancelled": { bgColor: '#F9D9D7', text: 'Cancelled', textColor: '#B32306' },
}



const OtherChargeList = ({ isOpen, setIsOpen, courseAttributeData, term, businessArea, applicationId, psid, otherCharges,
  setOtherCharges, selectionModel,
  setSelectionModel, updatingStatus, courseInfomation, userDetails, courseId, termDisplayVal, businessAreaDisplayVal }) => {

  const [checkboxdata, setCheckboxdata] = useState({});


  const [editRowsModel, setEditRowsModel] = useState({});

  const [rowData, setRowData] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [form, setForm] = useState({});
  const [saving, setSaving] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [courseIds, setCourseIds] = useState({ fetching: false, data: [] });
  const [selectedRow, setSelectedRow] = useState(null)
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [currentWaiveOff, setCurrentWaiveOff] = useState(null);
  const [sendingForApproval, setSendingForApproval] = useState(false);
  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["studentOtherCharges"]['id']);

  const formHandler = (value, key) => {
    if (key === 'chargeTypeId') {
      setForm(prev => ({ ...prev, [key]: value.value, quantity: 1 }))
    } else {
      setForm(prev => ({ ...prev, [key]: value.value }))
    }
  }

  const startEditing = (id, rowData) => {
    setRowModesModel(oldModel => ({ [id]: { mode: GridRowModes.Edit } }))
  }
  const stopEditing = (id) => {
    setRowModesModel(oldModel => ({ [id]: { mode: GridRowModes.View, ignoreModifications: true } }))
    setForm({})
  }

  const handleClose = () => {
    setSelectedRow(null);
    setForm({});
    setCurrentWaiveOff(null)
    setDialogOpen(false);
  }


  const disableAction = (status) => {
    return ['PAID', 'PAYMENT_IN_PROGRESS', 'APPROVED', "Cancelled"]?.includes(status);
  }



  const handleAddClick = (e) => {
    e.stopPropagation()
    if (!isObjEmpty(form)) {
      failureToast('Please save or delete current changes')
    }
    else {
      const newId = Math.max(...rowData.map(row => row.id)) + 1;

      const newRow = {
        "id": newId,
        "chargeTypeId": '',
        "courseId": '',
        "quantity": 1,
        "basePrice": '',
        "taxes": '',
        "netPayableAmount": '',
        "psid": '',
        "applicationId": '',
        "waveOffAmount": '',
        "finalPayable": '',
        "paymentStatus": '',
        isNew: true
      };


      setOtherCharges([newRow, ...rowData]);


      startEditing(newId, newRow);
      setForm(newRow);
      setCourseIds({ fetching: false, data: [] })

    }
  };
  const handleSaveClick = async (params) => {

    if (!form.basePrice) {
      failureToast("Please select course with valid fee breakup")
      return
    }

    setSaving(params.id)
    const request = {
      "chargeTypeId": form.chargeTypeId,
      "courseId": form.courseId,
      "quantity": form.quantity,
      "basePrice": form.basePrice,
      "taxes": form.taxes,
      "netPayableAmount": form.netPayableAmount,
      "psid": psid,
      "applicationId": applicationId,
      "waveOffAmount": 0,
      "finalPayable": form.netPayableAmount,
      termId: form?.termId ?? null,
      cgst: form.cgst,
      sgst: form.sgst,
      utgst: form.utgst,
      igst: form.igst,
      itemType: form.itemType,
      "paymentStatus": "Not_Applicable",
      "approvalStatus": "APPLIED"
    }

    let method = 'post';
    let url = endpoint.otherChargesConcession.create;

    if (!form.isNew) {
      method = 'put';
      url = `${endpoint.otherChargesConcession.create}/${form.id}`
    }

    const response = await fetchAllPostPromisedData(url, request, method);
    if (response?.code === 200) {
      setOtherCharges(prevState => prevState.map((row, index) =>
        row.id === params.id
          ? { ...row, ...request, id: response?.data.id, seq: index + 1, isNew: false }
          : { ...row, seq: index + 1 }
      ));
      stopEditing(params.id);
      setForm({});
      setSaving(false)
      successToast(response?.message)
    } else {
      setSaving(false)
      failureToast(response?.message ?? 'Not able to save the record , please try after sometime.')
    }
  }


  const handleApplyWaivOff = async (waiveoff) => {
    setSendingForApproval(true);
    const request = {
      ...form,
      "psid": psid,
      "applicationId": applicationId,
      "waveOffAmount": Number(waiveoff),
      "finalPayable": (form.netPayableAmount - Number(waiveoff)).toFixed(2),
      "paymentStatus": "Not_Applicable",
      // "approvalStatus": courseAttributeData.find(option => option.value === form?.chargeTypeId)?.courseAttributeValueKey === 'MSCR' ? "APPLIED" : "PENDING",
      "approvalStatus": "PENDING",
      cashierName: userDetails ? `${userDetails.firstName} ${userDetails.lastName}` : "",
      cashierRequestedOn: new Date(),
      cashierUserId: userDetails?.employeeId || "",
    }

    const response = await fetchAllPostPromisedData(`${endpoint.otherChargesConcession.create}/${form.id}`, request, 'put');
    if (response?.code === 200) {
      setOtherCharges(prevState => prevState.map(row =>
        row.id === form.id
          ? { ...row, ...request }
          : row
      ));
      handleClose();
      successToast(response?.message)
      setSendingForApproval(false);
    } else {
      // setSaving(false)
      setSendingForApproval(false);
      failureToast(response?.message ?? 'Not able to process request , please try after sometime.')
    }
  }

  const handleEditClick = (params) => {
    if (!isObjEmpty(form)) {
      failureToast('Please save or delete current changes')
    }
    else {
      const courseType = courseAttributeData?.find(item => item?.value === params?.row?.chargeTypeId);
      courseTypeChangeHandler(courseType , false);

      startEditing(params.row.id, params.row);
      setForm(params.row);
    }
  }

  const handleDeleteClick = async (params) => {
    if (params.cellMode === "edit") {
      stopEditing(params?.id)
      setForm({});
      if (params?.row?.isNew && !params?.row?.chargeTypeId) {
        setRowData([...rowData.filter(row => row.id !== params?.id)]);
        setOtherCharges([...otherCharges.filter(row => row.id !== params?.id)]);
        setCheckboxdata(prevState => {
          const newState = { ...prevState };
          delete newState[params?.id];
          return newState;
        });
      }
    } else {
      setDeleting(params?.id);
      const res = await fetchDeletePromisedData(`${endpoint.otherChargesConcession.create}/${params?.id}`);
      if (res?.code === 200) {
        let _temp = [...rowData];
        const index = _temp.findIndex(i => i.id === params.id);
        if (index || index === 0) {
          _temp.splice(index, 1);
        }

        const reorderedCharges = _temp.map((item, index) => { return { ...item, seq: index + 1, } })
        setOtherCharges([...reorderedCharges]);
        setDeleting(false);
        successToast(res?.message ?? "Record deleted successfully.")
      } else {
        setDeleting(false);
        failureToast(res?.message ?? "Unable to delete record, please try after sometime.")
      }
    }
  }

  const cellContainerStyle = {
    width: '100%',
    // padding: '8px 12px'
  }

  // const getCourseIds = async () => {
  //   setForm(prev => ({ chargeTypeId: prev?.chargeTypeId, quantity: 1, isNew: prev?.isNew }))
  //   setCourseIds({ fetching: true, data: [] })
  //   const request = {
  //     "academicTermIds": [
  //       term
  //     ],
  //     "businessAreaIds": [
  //       businessArea
  //     ],
  //     "courseCategoryIds": [
  //       form?.chargeTypeId
  //     ]
  //   }
  //   const res = await fetchAllPostPromisedData(`${endpoint.course.getCourseIdByAcademicTermAndBusinessAreaAndCourseType}`, request, 'post');
  //   if (res?.code === 200) {
  //     const ids = res.data.map(i => { return { label: i, value: i } })
  //     setCourseIds({ fetching: false, data: ids })
  //     if (!ids?.length) {
  //       failureToast("No course found for selected course type.")
  //       return
  //     }
  //   } else {
  //     failureToast("No course found for selected course type")
  //     setCourseIds({ fetching: false, data: [] })
  //   }
  // }

  // const getFeeBreakup = async () => {
  //   const breakup = await fetchAllPromisedData(`${endpoint.breakup.getAll}/${term}/${form.courseId}/${businessArea}`, true);
  //   // const breakup = await fetchAllPromisedData(`${endpoint.breakup.getAll}/6/713992/11`, true);
  //   if (breakup?.code === 200) {
  //     if (!breakup?.data.length) {
  //       failureToast("Please select course with valid fee breakup.")
  //       return
  //     }
  //     let baseFee = 0;
  //     let tax = 0;
  //     let netPayableAmount = 0;

  //     breakup.data.map(i => {
  //       baseFee += i.baseAmount;
  //       tax += i.tax;
  //       netPayableAmount += i.finalAmount
  //     })

  //     setForm({
  //       ...form, taxes: tax, basePrice: baseFee,
  //       netPayableAmount: netPayableAmount
  //     })
  //   }
  // }

  // const fetchSecurityFeeCharges = async () => {
  //   try {
  //     const resp = await fetchAllPromisedData(`${endpoint?.manual_processing?.getSecurityFeeCharges}?courseId=${courseId}&termDisplayVal=${termDisplayVal}&businessAreaDisplayVal=${businessAreaDisplayVal}`, true);
  //     if (resp?.code === 200) {
  //       setCourseIds({ fetching: true, data: [] })
  //       const courseId = { label: resp?.data?.courseId, value: resp?.data?.courseId };
  //       setCourseIds({ fetching: false, data: [courseId] });
  //       setForm(form => ({
  //         ...form,
  //         courseId: courseId.value,
  //         taxes: resp?.data?.tax,
  //         basePrice: resp?.data?.baseAmount,
  //         netPayableAmount: resp?.data?.finalAmount,
  //         securityFeeTermId: resp?.data?.academicTerm
  //       }))

  //     }
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }


  // useEffect(() => {
  //   if (courseAttributeData.find(el => el?.value === form?.chargeTypeId)?.label === 'SECURITY') {
  //     getFeeBreakup(0);
  //   } else if (form?.chargeTypeId) {
  //     getCourseIds();
  //   }
  // }, [form?.chargeTypeId])

  // useEffect(() => {
  //   if (form?.courseId && (courseAttributeData.find(el => el?.value === form?.chargeTypeId)?.label !== 'SECURITY')) {
  //     getFeeBreakup()
  //   }
  // }, [form.courseId])


  
  
  const getUpdatedTermDispVal = (termDisplayVal) => {
    const first2Digits = termDisplayVal?.slice(0,2);
    return `${first2Digits}${+first2Digits + 1}`
    
  }

  const courseTypeChangeHandler = async (courseType , resetCourseIds = true) => {
    setForm(prev => ({...prev , ...(resetCourseIds && ({courseId : ''}))}));
    resetCourseIds && setCourseIds({ fetching: true, data: [] })
    formHandler(courseType, 'chargeTypeId');
    const resp = await fetchAllPromisedData(`${endpoint.getChargesWithFeeBreakupAndGstDetails}/${applicationId}?courseId=${courseId}&termDisplayVal=${termDisplayVal}&businessAreaId=${businessArea}&courseCategoryId=${courseType.label === 'SECURITY' ? 0 : courseType.value}`, true);
    if (resp?.code === 200) {
      setCourseIds({
        fetching: false, data: resp?.data?.map(item => ({
          ...item,
          label: `${item.courseId}(${item.courseDescription})`,
          value: item.courseId
        }))
      })
    }
  }

  const courseChangeHandler = (course) => {
    formHandler(course, 'courseId');

    const itemType = course?.feeComponent[0]?.itemType;

    const baseAmount = course?.feeComponent[0]?.baseAmount;
    const totalTaxPercentage = course?.feeComponent[0]?.totalTaxPercentage;
    let tax = +((baseAmount * totalTaxPercentage) / 100).toFixed(4);
    let finalAmount = +(baseAmount + tax).toFixed(4);

    let cgst = 0;
    let sgst = 0;
    let utgst = 0;
    let igst = 0;

    const taxBreakup = course?.feeComponent[0]?.taxBreakupResDtos ?? []

    taxBreakup.forEach(j => {
      switch (j.taxCodeTypeDispVal) {
        case 'CGST':
          cgst += +((baseAmount * j.taxPercentage) / 100).toFixed(4);
          break;
        case 'SGST':
          sgst += +((baseAmount * j.taxPercentage) / 100).toFixed(4);
          break;
        case 'UTGST':
          utgst += +((baseAmount * j.taxPercentage) / 100).toFixed(4);
          break;
        case 'IGST':
          igst += +((baseAmount * j.taxPercentage) / 100).toFixed(4);
          break;
      }
    })

    setForm(prev => ({
      ...prev,
      taxes: tax,
      basePrice: baseAmount,
      netPayableAmount: finalAmount,
      cgst: cgst,
      sgst: sgst,
      utgst: utgst,
      igst: igst,
      itemType: itemType,
      termId:course?.academicTerm
    }))
  }




  useEffect(() => {
    setRowData(otherCharges)
  }, [otherCharges])

  const columns = [
    // { field: 'seq', headerName: 'Seq', type: 'text', width: 40, mandatory: false, editable: false, disabled: true, sortable: false, },
    {
      field: 'chargeTypeId',
      headerName: 'Course Type',
      // flex: 1,
      minWidth: 170,
      editable: true,
      renderCell: (params) => {
        const currentOption = courseAttributeData.find(option => option.value === params.value) || "";
        return <div style={commonStyle} className="w-100">
          <div className='w-75 overflow-hidden'>{currentOption?.label ?? ''}</div>&nbsp;
          <Tooltip title={currentOption?.label ?? ''}>
            <div style={{ cursor: 'pointer' }}>
              {/* <AiOutlineEye className='ml-1' size={16} color='#11cdef' /> */}
              <EyeIcon color={'#00B0F5'} />
            </div>
          </Tooltip>
        </div>
      },
      renderEditCell: (params) => {
        const currentOption = courseAttributeData.find(option => option.value === form?.chargeTypeId) || "";
        return (
          <div style={cellContainerStyle}>
            <AAutoComplete
              currentValue={currentOption}

              items={courseAttributeData}
              placeholder='Course Type'

              handleChange={value => courseTypeChangeHandler(value)}
              isMulti={false}
              style={selectStyles}
              // isDisabled={!params.row.isNew}
            />

          </div>
        );
      }
    },
    {
      field: 'courseId',
      headerName: 'Course',
      // flex: 1,
      minWidth : 100,
      renderCell: (params) => {
        const courseName = courseInfomation.find(i => i.value == params.value)?.name ?? '';
        return (
          <Tooltip title={courseName || ''}>
            <div style={{ cursor: 'pointer' }}>
              {/* <AiOutlineEye className='ml-1' size={16} color='#11cdef' /> */}
              <div>{params.value}</div>
            </div>
          </Tooltip>
        )
      },
      editable: true,
      renderEditCell: (params) => {
        const currentOption = form?.courseId ? { label: form?.courseId, value: form?.courseId } : '';
        // const courseOptions = courseIds.data.map(item => ({ label: `${item.courseId}(${courseInfomation.find(i => i.value == item.courseId)?.name ?? ''})`, value: item.courseId }));
        return (
          <div style={cellContainerStyle}>
            {/* {console.log('courseOptions',courseOptions)} */}
            <AAutoComplete
              currentValue={currentOption}
              items={courseIds.data}
              placeholder="Course"
              handleChange={value => courseChangeHandler(value)}
              isMulti={false}
              style={{ ...selectStyles, menuPortal: { width: '200px' } }}
              loading={courseIds.fetching}
              // isDisabled={!params.row.isNew}
            />
          </div>
        )
      }
    },
    {
      field: 'quantity',
      headerName: 'Qty',
      // flex: 1,
      maxWidth: 50,
      editable: false,
      renderCell: (params) => (<div>{params.value}</div>),
      renderEditCell: (params) => {

        const chargeType = courseAttributeData.find(option => option.value === form?.chargeTypeId)?.label || "";
        return (
          <div style={cellContainerStyle}>
            <AInput
              value={form?.quantity}
              handler={(val) => setForm({ ...form, quantity: val })}
              onKeyPress={(e) => {
                if (!/^[0-9]+$/.test(String.fromCharCode(e.charCode))) {
                  e.preventDefault();
                  return;
                }
              }}
              maxLength={2}
              disabled={!form.chargeTypeId || chargeType?.toString()?.toLowerCase() === 'exam' || chargeType?.toString()?.toLowerCase() === 'exam fee' || chargeType?.toString()?.toLowerCase() === 'security'}
            />
          </div>
        );
      }
    },
    {
      field: 'basePrice',
      headerName: 'Base Price',
      // flex: 1,
      minWidth : 100,
      editable: true,
      renderCell: (params) => (<div>₹{parse2Digit(params.value)}</div>),
      renderEditCell: (params) => {
        return (
          <div style={cellContainerStyle}>
            <AInput
              value={form?.basePrice ? '₹' + form?.basePrice : ''}
              placeholder={'₹ Amount'}
              disabled={true}
            // style={inputStyles}
            />
          </div>
        )
      }
    },
    {
      field: 'taxes',
      headerName: 'Taxes',
      // flex: 1,
      minWidth : 150,
      editable: true,
      renderCell: (params) => (<div> ₹{ parse2Digit(params.value) } <TaxTooltip tax = {params?.value} otherChargesRow={params.row} /></div>),
      renderEditCell: (params) => {
        return (
          <div style={cellContainerStyle}>
            <AInput
              value={form?.taxes || form?.taxes === 0 ? '₹' + form?.taxes : ''}
              placeholder={'₹ Amount'}
              disabled={true}
            // style={inputStyles}
            />
          </div>)
      }
    },
    {
      field: 'waveOffAmount',
      headerName: 'Concession',
      // flex: 1,
      minWidth : 100,
      editable: true,
      renderCell: (params) => (<div>₹{ params?.row?.approvalStatus === 'APPROVED' ? parse2Digit(params.value) : 0}</div>),
      renderEditCell: (params) => {
        const isApproved = params?.row?.approvalStatus === 'APPROVED';
        const value = isApproved ? form?.waiveOff : 0;
        return (
          <div style={cellContainerStyle}>
            <AInput
              value={'₹' + value }
              placeholder={'₹ Amount'}
              disabled={true}
            // style={inputStyles}
            />
          </div>)
      }
    },
    {
      field: 'netPayableAmount',
      headerName: 'Net Payable Amount',
      // flex: 1,
      minWidth : 100,
      editable: true,
      renderCell: (params) => (<div>₹{params?.row?.approvalStatus === 'APPROVED' ? parse2Digit(params?.row?.finalPayable) : parse2Digit(params?.value)}</div>),
      renderEditCell: (params) => {
        const isApproved = params?.row?.approvalStatus === 'APPROVED';
        const value = isApproved ? form?.finalPayable : form?.netPayableAmount
        return (
          <div style={cellContainerStyle}>
            <AInput
              value={value ? '₹' + value : ''}
              placeholder={'₹ Amount'}
              disabled={true}
            // style={inputStyles}
            />
          </div>)
      }
    },
    {
      field: 'waiveoff',
      headerName: 'Waive off',
      // flex: 1,
      minWidth : 100,
      editable: true,
      renderCell: (params) => {
        const isDisabled = disableAction(params.row.approvalStatus);
        return (
          <Tooltip title="Apply">
            <span
              onClick={isDisabled ? () => { } : () => { setSelectedRow(rowData.find(i => i.id === params.row.id)); setForm(rowData?.find(i => i.id === params.row.id)); setCurrentWaiveOff(rowData.find(i => i.id === params.row.id)?.waveOffAmount ?? ''); setDialogOpen(true) }}
              style={{
                color: isDisabled ? "#AFAFAF" : "#00B0F5",
                cursor: isDisabled ? "not-allowed" : "pointer",
              }}
            >
              Apply
            </span>
          </Tooltip>
        )
      },
      renderEditCell: (params) => {
        return (
          <Tooltip title="Apply">
            <span
              style={{
                color: "#AFAFAF",
                cursor: 'not-allowed',
                opacity: 0.5
              }}
            >
              Apply
            </span>
          </Tooltip>
        )
      }
    },
    {
      field: 'approvalStatus',
      headerName: 'Status',
      // flex: 1,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: (params) => {
        return (
          <CustomChip {...approvalStatusChipInfo[params.value]} />
        )
      }
    },

    {
      field: 'Actions',
      headerName: 'Actions',
      minWidth: 130,
      // flex: 1,
      editable: true,
      columnalign: 'right',
      // setFlex: 0.5,

      renderCell: (params => {
        const isActionDisabled = disableAction(params.row.approvalStatus)
        return <ActionsCellComponent
          params={params}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
          saving={saving}
          isDeleting={isDeleting}
          userPermissions = {userPermissions}
          // handleSaveClick={handleSaveClick} 
          // deleteHandler={deleteHandler} 
          // rowForm={form} 
          isActionDisabled={isActionDisabled}
          disableEdit = {params?.row?.approvalStatus === 'PENDING'}
        />
      }),
      renderEditCell: (params => (
        <ActionsCellComponent
          params={params}
          editMode={true}
          // handleEditClick={handleEditClick} 
          handleDeleteClick={handleDeleteClick}
          handleSaveClick={handleSaveClick}
          // deleteHandler={deleteHandler} 
          rowForm={form}
          saving={saving}
          isDeleting={isDeleting}
        />
      )),
    }]

  return (

    <Accordion
      title={`Other Charges`}
      key={'other_Charges'}

      rightSideButton={
       userPermissions.includes("C") && <span style={{ padding: '4px 15px', backgroundColor: '#ffffff', border: '1px solid #CCCCCC', borderRadius: '5px', cursor: 'pointer', fontSize: '12px' }} onClick={handleAddClick} disabled={saving || !!updatingStatus}>
          Add New
        </span>
      }
      isEditFormOpen={(open) => {
        open.tab2 === false ?
          setIsOpen(state => ({ ...state, tab2: true })) :
          setIsOpen({
            detailsTab: false,
            tab2: false,
            waive_off: false,
            tab4: false
          })
      }}

    >
      <div style={{ height: 'auto', width: '100%' }}>
        <ADataGrid
          rowModesModel={rowModesModel}
          rows={rowData || []}
          columns={columns}
          rowId={(row) => row.id}
          rowHeight={50}
          height={100}
          loading={false}
          serverPagination={false}
          emptyRowPlaceholder={() => <NoRowOverlay />}

          selectionModel={selectionModel}
          onSelectionModelChange={(newSelectionModel) => {
            newSelectionModel?.length <= 1 && setSelectionModel(newSelectionModel)
          }}
          checkboxSelection={true}
          disableSelectionOnClick
          isRowSelectable={(params) => (!params.row?.isNew && !['PAID', 'PAYMENT_IN_PROGRESS']?.includes(params.row?.approvalStatus))}
          sx={{
            borderTop: 'none !important',
            '.MuiDataGrid-root': {
              backgroundColor: 'red', // Change the background color of the DataGrid
              borderTop: 'none !important'
            },
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '20px',
              padding: "10px 0px !important",
            },
            '.MuiDataGrid-main': {
              height: `${100 + rowData?.length * 50}px`,
              minHeight: `${60 + 50}px`,
              maxHeight: `${60 + 50 * 50}px`,
              backgroundColor: '#ffffff',
              padding: "18px 0px !important",
              borderRadius: '16px'
            },
            '.MuiDataGrid-cell--editing': {
              boxShadow: 'none !important',
              padding: '0 10px !important', // Add padding as per your needs
            },
            '.MuiDataGrid-footerContainer': {
              display: 'hidden',
            },
            '.MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':{
              display: 'none'
            },

            overflowX : 'scroll'
            // '.MuiDataGrid-columnHeaders':{
            //   minHeight:'52px !important',
            //   maxHeight:'52px !important'
            // }
          }}
        />
      </div>
      <Dialog
        maxWidth={'md'}
        fullWidth
        open={isDialogOpen}
        onClose={handleClose}
      >
        <div className='p-3'>
          <div className='d-flex justify-content-between pb-2'>
            <h3 className='heading-4'>Apply Waive off</h3>
            <span className='p-2 cursor' onClick={handleClose}>X</span>
          </div>
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <thead>
              <tr>
                <th className="regular-bolder" style={{ borderBottom: "1px solid #E3E3E3", padding: "10px", textAlign: "left" }}>Charge Type</th>
                <th className="regular-bolder" style={{ borderBottom: "1px solid #E3E3E3", padding: "10px", textAlign: "right" }}>Net payable</th>
                <th className="regular-bolder" style={{ borderBottom: "1px solid #E3E3E3", padding: "10px", textAlign: "left" }}>Waive off amount</th>
                <th className="regular-bolder" style={{ borderBottom: "1px solid #E3E3E3", padding: "10px", textAlign: "right" }}>Final amount</th>
                <th className="regular-bolder" style={{ borderBottom: "1px solid #E3E3E3", padding: "10px", textAlign: "right" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='regular-bold p-2' style={{ padding: "10px" }}>
                  <div style={commonStyle}>{courseAttributeData.find(option => option.value === form?.chargeTypeId)?.label ?? ''}&nbsp;
                    <Tooltip title={courseAttributeData.find(option => option.value === form?.chargeTypeId)?.label ?? ''}>
                      <div style={{ cursor: 'pointer' }}>
                        {/* <AiOutlineEye className='ml-1' size={16} color='#11cdef' /> */}
                        <EyeIcon />
                      </div>
                    </Tooltip>
                  </div>
                </td>
                <td className='regular-bold p-2' style={{ padding: "10px", textAlign: 'right' }}>
                  ₹ {form.netPayableAmount}
                </td>
                <td className='regular-bold p-2' style={{ padding: "10px" }}>
                  <AInput
                    value={currentWaiveOff}
                    placeholder={'Amount'}
                    handler={(val) => (Number(form.netPayableAmount) - val >= 0) && setCurrentWaiveOff(val)}
                  />
                </td>
                <td className='regular-bold p-2' style={{ padding: "10px", textAlign: 'right' }}>
                  ₹ {currentWaiveOff ? (Number(form.netPayableAmount) - currentWaiveOff).toFixed(2) : form?.netPayableAmount ? (form?.netPayableAmount)?.toFixed(2) : 0}
                </td>
                <td className='regular-bold p-2 d-flex justify-content-end' style={{ padding: "10px", textAlign: 'left' }}>
                  <CustomButton content={sendingForApproval ? <i className="fas fa-spinner fa-spin" aria-hidden="true">{''}</i> : "Send for approval"} permissionType={'C,U,A'} permissionSet={'C,U,A'} onClick={() => { handleApplyWaivOff(currentWaiveOff) }} disabled={form.approvalStatus === 'PENDING'} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Dialog>
    </Accordion>
  )

}


export default OtherChargeList;