import React from "react";
import AButton from "../../../common/form-fields-mui/AButton";
import { ReactComponent as DownloadIcon } from "../../../../../../assets/img/svg/download.svg";
import { ReactComponent as DownloadBold } from "../../../../../../assets/img/svg/DownloadBold.svg";

import { downloadStudentList } from "./service";

const headerComponentStyle = {
  background: "#F5F5F5",
  gap:"10px",
  alignItems:"center",
  display:"flex",
  borderRadius:"5px"
};

const DownloadCsv = ({ filterForm, setLoading, page , printedData}) => {
  return (
    <div className='mb-2 p-2' style={headerComponentStyle}>
         <DownloadBold/>
         <div><div className='heading-3 color-dark'>Download list of all students</div>
         <div>You will get .csv file of RFID printed students in this session</div></div>
        <AButton           
        updatedStyle={{ background: "#00B0F5", color: "#fff"  , padding :"20px"}}
        className='btn-left-0 mt-2' onClick={()=>downloadStudentList(filterForm, setLoading, page, printedData)}> <span className="mr-1">Download original .csv</span> <DownloadIcon/>  </AButton>
    </div>
  );
};

export default DownloadCsv;
