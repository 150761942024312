import React, { useState, useEffect } from "react";
import LessonPlan from "./lessonPlanTable";
import Header from "./header/header";
import Loader from "react-loader-spinner";
import { putpost, masterServiceBaseUrl, failureToast, getAPI } from "services/http";
import { GetPagination } from "../../common/pagination";
import { useSelector, useDispatch } from "react-redux";
import { updateData } from "../../../../../redux/globalData/globalDataAction";

const Subject = () => {
  const dispatch = useDispatch();
  const LessonPlanHeaderData = useSelector((state) => state.globalData.data);
  const [classArray, setclassArray] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const [apiloader, setapiloader] = useState(false);
  const [state, setstate] = useState([]);
  const [chapterData, setChapterData] = useState(null);
  const [topicData, setTopicData] = useState(null);
  const [subTopicData, setSubTopicData] = useState(null);
  const [subSubTopicData, setSubSubTopicData] = useState(null);
  const [meetingData, setMeetingData] = useState(null);
  const [testMode, setTestMode] = useState(null);

  const [groupCodeData, setGroupCode] = useState(null);
  const [companyCodeData, setCompanyCodeData] = useState(null);
  const [zoneData, setZoneData] = useState(null);
  const [regionData, setRegionData] = useState(null);
  const [businessArea, setBusinessArea] = useState(null);
  const [academicCareer, setAcademicCareer] = useState(null);
  const [academicGroup, setAcademicGroup] = useState(null);
  const [course, setCourse] = useState(null);
  const [term, setTerm] = useState(null);
  const [academicYear, setAcademicYear] = useState(null);
  const [academicPhase, setAcademicPhase] = useState(null);
  const [board, setBoard] = useState(null);
  const [subject, setSubject] = useState(null);

  const pageSize = 10;
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null,
  });
  const fetchClasses = async (data) => {
    if (data) {
      setstate(data["data"]);
      let n = pagination;
      if (data["data"].length > pageSize) {
        n["nextPage"] = n.currentPage + 1;
      }
      if (n.currentPage > 1) {
        n.previousPage = n.currentPage - 1;
      }
      n["totalPage"] = Math.floor(data["data"].length / pageSize);
      if (data["data"].length % pageSize != 0) {
        n["totalPage"] += 1;
      }
      setpagination(n);
      setapiloader(false);
      setExcelData(data.data);
      setclassArray(data["data"].slice(pagination.currentPage * pageSize - pageSize, pagination.currentPage * pageSize) || []);
    } else {
      setclassArray([]);
    }
  };
  const nextPage = () => {
    setclassArray(state.slice((pagination.currentPage + 1) * pageSize - pageSize, (pagination.currentPage + 1) * pageSize));
    let n = pagination;
    n["currentPage"] = n["currentPage"] + 1;
    n["previousPage"] = n.currentPage;
    setpagination(n);
  };
  const firstPage = () => {
    setclassArray(state.slice(1 * pageSize - pageSize, 1 * pageSize));
    let n = pagination;
    n["currentPage"] = 1;
    n["previousPage"] = null;
    setpagination(n);
  };
  const lastPage = () => {
    setclassArray(state.slice(pagination.totalPage * pageSize - pageSize, pagination.totalPage * pageSize));
    let n = pagination;
    n["currentPage"] = n.totalPage;
    n["previousPage"] = n.totalPage - 1;
    setpagination(n);
  };
  const previousPage = () => {
    if (pagination.currentPage != 1) {
      setclassArray(state.slice((pagination.currentPage - 1) * pageSize - pageSize, (pagination.currentPage - 1) * pageSize));
      let n = pagination;
      n["currentPage"] = n["currentPage"] - 1;
      if (n["currentPage"] == 1) {
        n["previousPage"] = null;
      } else {
        n["previousPage"] = n.currentPage;
      }
      setpagination(n);
    }
  };

  const [headers, setHeaders] = useState({
    businessGroup: null,
    companyCode: null,
    zone: null,
    region: null,
    businessArea: null,
    academicCareer: null,
    academicGroup: null,
    course: null,
    term: null,
    academicYear: null,
    academicPhase: null,
    board: null,
    subject: null,
  });

  const searchLessonPlan = (obj) => {
    const searchData = obj ?? {
      groupCodeId: headers.businessGroup?.value,
      companyCodeId: headers.companyCode?.value,
      zoneId: headers.zone?.value,
      regionId: headers.region?.value,
      businessAreaId: headers.businessArea?.value,
      academicCareerId: headers.academicCareer?.value,
      academicGroupId: headers.academicGroup?.value,
      courseId: headers.course?.value,
      termId: headers.term?.value,
      academicYearId: headers.academicYear?.value,
      academicPhaseId: headers.academicPhase?.value,
      boardId: headers.board?.value,
      subjectId: headers.subject?.value,
    };
    putpost(
      `${masterServiceBaseUrl}/lessonPlanMaster/searchLessonPlanMaster`,
      (data) => {
        fetchClasses(data);
      },
      (data) => {
        fetchClasses(null);
        failureToast(data["message"]);
      },
      searchData,
      "post"
    );
  };

  const resetHeader = () => {
    setHeaders({
      businessGroup: null,
      companyCode: null,
      zone: null,
      region: null,
      businessArea: null,
      academicCareer: null,
      academicGroup: null,
      course: null,
      term: null,
      academicYear: null,
      academicPhase: null,
      board: null,
      subject: null,
    });
    fetchClasses(null);
  };

  const removeDataFromStorage = () => {
    dispatch(updateData(null));
  };

  useEffect(() => {
    if (LessonPlanHeaderData?.businessAreaId?.value) {
      setHeaders({
        groupCodeId: LessonPlanHeaderData.businessGroup?.value,
        companyCodeId: LessonPlanHeaderData.companyCode?.value,
        zoneId: LessonPlanHeaderData.zone?.value,
        regionId: LessonPlanHeaderData.region?.value,
        businessAreaId: LessonPlanHeaderData.businessArea?.value,
        academicCareerId: LessonPlanHeaderData.academicCareer?.value,
        academicGroupId: LessonPlanHeaderData.academicGroup?.value,
        courseId: LessonPlanHeaderData.course?.value,
        termId: LessonPlanHeaderData.term?.value,
        academicYearId: LessonPlanHeaderData.academicYear?.value,
        academicPhaseId: LessonPlanHeaderData.academicPhase?.value,
        boardId: LessonPlanHeaderData.board?.value,
        subjectId: LessonPlanHeaderData.subject?.value,
      });

      const oldObj = {
        businessGroup: LessonPlanHeaderData?.businessGroupId?.value,
        companyCode: LessonPlanHeaderData?.companyCodeId.value,
        zone: LessonPlanHeaderData?.zoneId?.value,
        region: LessonPlanHeaderData?.regionId?.value,
        businessArea: LessonPlanHeaderData?.businessAreaId?.value,
      };
      searchLessonPlan(oldObj);
      removeDataFromStorage();
    }
  }, [LessonPlanHeaderData?.businessAreaId]);

  useEffect(() => {
    try {
      getAPI(masterServiceBaseUrl + "/chapterMaster/getAllActiveChapterMaster", (data) => {
        let Temp = [];
        data.data.map((item) => {
          Temp.push({
            id: item.id,
            text: item.displayValue,
          });
        });
        setChapterData(Temp);
      });

      getAPI(masterServiceBaseUrl + "/topicMaster/getAllActiveTopicMaster", (data) => {
        let Temp = [];
        data.data.map((item) => {
          Temp.push({
            id: item.id,
            text: item.description,
          });
        });
        setTopicData(Temp);
      });

      getAPI(masterServiceBaseUrl + "/subTopicMaster/getAllActiveSubTopicMaster", (data) => {
        let Temp = [];
        data.data.map((item) => {
          Temp.push({
            id: item.id,
            text: item.subTopicId,
          });
        });
        setSubTopicData(Temp);
      });

      getAPI(masterServiceBaseUrl + "/subSubTopicMaster/getAllActiveSubSubTopic", (data) => {
        let Temp = [];
        data.data.map((item) => {
          Temp.push({
            id: item.id,
            text: item.ssTopicId,
          });
        });
        setSubSubTopicData(Temp);
      });

      getAPI(masterServiceBaseUrl + "/meetingType/getAllMeetingType", (data) => {
        let Temp = [];
        data.data.map((item) => {
          Temp.push({
            id: item.id,
            text: item.meetingTypeDispValue,
          });
        });
        setMeetingData(Temp);
      });

      getAPI(masterServiceBaseUrl + "/testMode/getAllTestMode", (data) => {
        let Temp = [];
        data.data.map((item) => {
          Temp.push({
            id: item.id,
            text: item.testModeDispValue,
          });
        });
        setTestMode(Temp);
      });
    } catch (error) {
      console.log("Error", error);
    }
  }, []);

  return (
    <>
      <Header
        headerValues={headers}
        setHeaderValues={setHeaders}
        searchLessonPlan={searchLessonPlan}
        resetHeader={resetHeader}
        groupCodeData={groupCodeData}
        setGroupCode={setGroupCode}
        companyCodeData={companyCodeData}
        setCompanyCodeData={setCompanyCodeData}
        zoneData={zoneData}
        setZoneData={setZoneData}
        regionData={regionData}
        setRegionData={setRegionData}
        businessArea={businessArea}
        setBusinessArea={setBusinessArea}
        academicCareer={academicCareer}
        setAcademicCareer={setAcademicCareer}
        academicGroup={academicGroup}
        setAcademicGroup={setAcademicGroup}
        course={course}
        setCourse={setCourse}
        term={term}
        setTerm={setTerm}
        academicYear={academicYear}
        setAcademicYear={setAcademicYear}
        academicPhase={academicPhase}
        setAcademicPhase={setAcademicPhase}
        board={board}
        setBoard={setBoard}
        subject={subject}
        setSubject={setSubject}
      />
      {apiloader ? (
        <div className="mx-auto text-center py-5 my-5" style={{ height: "100vh" }}>
          <Loader type="Rings" color="#00BFFF" height={70} width={70} timeout={10000} />
        </div>
      ) : (
        <div></div>
      )}
      {classArray && (
        <LessonPlan
          headers={headers}
          chapterData={chapterData}
          topicData={topicData}
          subTopicData={subTopicData}
          subSubTopicData={subSubTopicData}
          meetingData={meetingData}
          testMode={testMode}
          searchLessonPlan={searchLessonPlan}
          previousPage={previousPage}
          fetchClasses={fetchClasses}
          setclassArray={setclassArray}
          classArray={classArray}
          excelData={excelData}
          groupCodeData={groupCodeData}
          companyCodeData={companyCodeData}
          zoneData={zoneData}
          regionData={regionData}
          businessArea={businessArea}
          academicCareer={academicCareer}
          academicGroup={academicGroup}
          course={course}
          term={term}
          academicYear={academicYear}
          academicPhase={academicPhase}
          board={board}
          subject={subject}
        />
      )}

      {pagination.totalPage > 1 ? (
        <GetPagination
          fetchClasses={fetchClasses}
          pagination={pagination}
          previousPage={previousPage}
          firstPage={firstPage}
          lastPage={lastPage}
          nextPage={nextPage}
          setpagination={setpagination}
        />
      ) : null}
    </>
  );
};

export default Subject;
