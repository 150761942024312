import React from 'react';
import {
  Card,
  CardHeader,
  Input,
  Container,
  Button,
  Table,
  Row,
} from 'reactstrap';
import { useState } from 'react';
import { failureToast } from '../../common/utils/methods/toasterFunctions/function';
import { TDROW, MasterHeader } from '../../common/commonComponents/masterRow/masterRow';
import { masterServiceBaseUrl, successToast, putpost, putpost2 } from 'services/http';
import moment from 'moment';
import CreateButton from "components/FormComponent/Button/New/index";
import UpdateButton from "components/FormComponent/Button/Update/index";
import { ButtonText } from "variables/Buttons";
import { endpoint } from "../../common/constant";
import { fetchAllPostPromisedData, fetchDeletePromisedData, getCurrentDate } from "../../common/utils/methods/commonMethods/utilityMethod";
import validationRules from './validation.json'
import { customValidation } from "../../common/utils/methods/validations/validation";
import { editTableRow, newTableRow } from "../../common/utils/methods/commonMethods/tableMethods";
import { MasterHeaderAuto } from "../../common/commonComponents/masterHeader/masterHeader";
import CustomButton from "../../../../../components/CustomButton";


var _ = require('lodash');


const headerList = [
  {name: 'Program Action KEY', isRequired: true},
  {name: 'EFFECTIVE DATE'},
  {name: 'DESCRIPTION', isRequired: true},
  {name: 'DISPLAY VALUE', isRequired: true},
  {name: 'STATUS'},
  {name: 'ACTION'}
]

const ProgramActionTable = (props) => {
  const { permissionSet, isSaveVisible, setisSaveVisible = () => { }, getAllrecords = () => { } } = props;
  const [tableDataKey, settableDataKey] = useState('tableDataKey');
  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: '__new',
        programActionKey: null,
        status: 'ACTIVE',
        programActionDesc: null,
        programActionDispValue: null,
        effectiveDate: null,
      },
      ...props.classArray,
    ]);
  };

  const saveAll = async (index) => {
    let _data = props.classArray[index];
    let isValid = true;
    isValid = customValidation(_data, validationRules);

    if (!isValid) {
      return;
    }
    if (_data["__status"] == "__new") {
      const createRecord = await fetchAllPostPromisedData(endpoint.programAction.create, _data, 'post')
      if (createRecord?.code === 200) {
        successToast(createRecord["message"]);
        setisSaveVisible(false);
        getAllrecords()
      }
    } else {
      const createRecord = await fetchAllPostPromisedData(`${endpoint.programAction.update}/${_data.id}`, _data, 'put')
      if (createRecord?.code === 200) {
        successToast(createRecord["message"]);
        setisSaveVisible(false);
        getAllrecords()
      }
    }
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      newTableRow(props.classArray, props.setclassArray, setisSaveVisible, addNewClass);
    }
  };
  const editRow = (index) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      editTableRow(index, props.classArray, props.setclassArray, setisSaveVisible);
    }
  };
  const deleteRow = async (index) => {
    if (
      window.confirm("Are you sure you want to delete this Program Action?")
    ) {
      const deleteRecords = await fetchDeletePromisedData(`${endpoint.programAction.delete}/${props.classArray[index].id}`);
      if (deleteRecords?.code === 200) {
        let flag = false;
        if (props.classArray.length == 1) {
          flag = true;
        }
        getAllrecords(flag);
        settableDataKey(new Date().getMilliseconds());
      }
    }
    // }
  };
  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);
  };
  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Program Action</h3>
                <CustomButton
                  data-testId="addNewProgramAction"
                  disabled={isSaveVisible}
                  className={"floatRight mx-1" + (isSaveVisible ? " btn-dark" : null)}
                  content={ButtonText.ProgramAction.addNew}
                  permissionType={'C'}
                  icon={true}
                  permissionSet={permissionSet}
                  onClick={() => newRow()}
                />
                {/* {isSaveVisible?<Button color="info" size="sm" type="button" className="floatRight mx-1" onClick={() => {saveAll()}}>Save</Button>:null} */}
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush tableLayout">
                <MasterHeaderAuto headerList={headerList} isSaveVisible={isSaveVisible} permissionSet={permissionSet} />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                        !el['editMode'] ? (
                          <TDROW
                            type={'programAction'}
                            index={index}
                            el={el}
                            editRow={editRow}
                            deleteRow={deleteRow}
                            isSaveVisible={isSaveVisible} 
                            permissionSet={permissionSet}
                          />
                        ) : (
                          <tr key={index + '-class'}>
                            <td className="text-center p-2 mx-1">
                              <Input
                                data-testid="programActionKey"
                                maxLength={50}
                                defaultValue={el['programActionKey']}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    e.target.value,
                                    'programActionKey'
                                  );
                                }}
                                placeholder="Program Action Key"
                                className="tdInput"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <Input
                                data-testid="effectiveDate"
                                disabled={true}
                                defaultValue={
                                  el['__status'] == '__new'
                                    ?  moment(new Date()).format(
                                      'DD-MM-YYYY'
                                    )
                                    : moment(el['effectiveDate']).format(
                                      'DD-MM-YYYY'
                                    )
                                }
                                placeholder="Example:01-01-2020"
                                className="tdInput"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <Input
                                data-testid="programActionDesc"
                                maxLength={50}
                                defaultValue={el['programActionDesc']}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    e.target.value,
                                    'programActionDesc'
                                  );
                                }}
                                placeholder="Description"
                                className="tdInput"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <Input
                                data-testid="programActionDispValue"
                                maxLength={50}
                                defaultValue={el['programActionDispValue']}
                                onChange={(e) => {
                                  updateKey(
                                    index,
                                    e.target.value,
                                    'programActionDispValue'
                                  );
                                }}
                                placeholder="Display Value"
                                className="tdInput"
                              />
                            </td>
                            <td className="text-center p-2 mx-1">
                              <label className="custom-toggle mx-auto ml-2">
                                <input
                                  data-testid="activeInactiveInput"
                                  checked={
                                    el.status == 'ACTIVE' ? true : false
                                  }
                                  type="checkbox"
                                  id={el.id}
                                  onChange={(e) => {
                                    settableDataKey(
                                      new Date().getMilliseconds()
                                    );
                                    let n = 'ACTIVE';
                                    if (!e.target.checked) {
                                      n = 'INACTIVE';
                                    }
                                    updateKey(index, n, 'status');
                                  }}
                                />
                                <span
                                  data-testid="activeInactiveLabel"
                                  style={{ width: '72px' }}
                                  className="custom-toggle-slider rounded-circle activeToggle"
                                  data-label-off="Inactive"
                                  data-label-on="Active"
                                />
                              </label>
                            </td>
                            <td className="text-center">
                              {/* <Button color="info" size="sm" type="button" onClick={() => {
                          deleteRow(index)
                        }}>  <i className="fas fa-trash" /></Button> */}
                              {isSaveVisible ? (
                                <>
                                  {/* <UpdateButton text={ButtonText.ProgramAction.save} data-testid="saveProgramAction" onClick={() => { saveAll(index) }} /> */}
                                  <CustomButton
                                    data-testid="saveProgramAction"
                                    className={'mx-1'}
                                    content={ButtonText.ProgramAction.save}
                                    permissionType={'C,U'}
                                    forTable={true}
                                    permissionSet={permissionSet}
                                    onClick={() => { saveAll(index) }}
                                  />
                                  <CustomButton
                                    data-testId="closeProgramAction"
                                    className={'mx-1'}
                                    permissionType={'cancel'}
                                    forTable={true}
                                    icon={true}
                                    permissionSet={permissionSet}
                                    onClick={() => {
                                      let n = props.classArray;
                                      if (n[index]["__status"] == "__new") {
                                        n.splice(index, 1);
                                      } else {
                                        // n[index]['editMode']=false
                                        props.getAllrecords();
                                      }
                                      // props.setclassArray(n);
                                      setisSaveVisible(false);
                                    }}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </td>
                          </tr>
                        )
                      )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default ProgramActionTable;
