import React, {useEffect} from 'react';
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import Select2 from "react-select2-wrapper";
import {
    RedAstric
} from "../../../../common/utils/methods/commonMethods/utilityMethod";
import moment from 'moment';
import CommonInput from "../../../../common/formFeilds/input/commonInput";

const GstDetails = (props) => {
    const {
        action, formData, UpdateFormData, studentId, isEdit,
        gst,label,placeholder
    } = props;

    return (
        <div className='container-fluid'>
            <Row>
                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">{label.gstCategory}</Label>
                        {
                            // action !== 'view' ?
                            <Select2 options={{placeholder: placeholder.gstCategory,}}
                                     data={gst}
                                     disabled={(action === 'view' && !studentId) || (!isEdit && action === 'view' && studentId !== null)}
                                     defaultValue={formData['admissionGSTCategory']}
                                     onChange={(e) => {
                                         if (e.target.value) {
                                             UpdateFormData(5, 'admissionGSTCategory', e.target.value);
                                         }
                                     }}
                            />
                            // :''
                            // <Input disabled={true} className="tdSelect" value={ formData.groupCode ? getDispValForReactSelectByOneID(formData['groupCode'],'DispValue',groupCode) :'Not Found'}/>
                        }
                    </FormGroup>
                </Col>

                <Col md={3}>
                    <FormGroup>
                        <Label for="studentId" className="form-control-label">
                            {label.gstEffectiveDate}
                            {
                                formData['admissionGSTCategory'] === '' ?
                                    null :
                                    <RedAstric/>
                            }
                        </Label>
                        <input type="date" name="gstEffectiveDate" className="form-control"
                               value={moment(formData['gstEffectiveDate']).format('YYYY-MM-DD')}
                               onKeyDown={(e) => e.preventDefault()}
                               onChange={(e) => {
                                   UpdateFormData(5, 'gstEffectiveDate', e.target.value)
                               }}
                               disabled={formData['admissionGSTCategory'] === '' || (action === 'view' && !studentId) || (!isEdit && action === 'view' && studentId !== null)}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <CommonInput label={label.gstSponserName} placeHolder={placeholder.gstSponserName}
                                 defaultValue={formData['admissionGSTSponsorName']}
                                 type={'text'}
                                 restrictSpecialCharacter={true}
                                 allowedSomeSpecialCharacters={true}
                                 regEx={/[^0-9a-zA-Z ./]/g}
                                 maxLength={100}
                                 mandatory={!(formData['admissionGSTCategory'] === '')}
                                 isDisabled={formData['admissionGSTCategory'] === '' || (action === 'view' && !studentId) || (!isEdit && action === 'view' && studentId !== null)}
                                 onChange={(value) => {
                                     UpdateFormData(5, 'admissionGSTSponsorName', value);
                                 }}
                    />

                    {/*<FormGroup>*/}
                    {/*  <Label for="studentId" className="form-control-label">*/}
                    {/*    GST Sponser Name*/}
                    {/*    {*/}
                    {/*      formData['admissionGSTCategory'] === '' ?*/}
                    {/*        null :*/}
                    {/*        <RedAstric/>*/}
                    {/*    }*/}
                    {/*  </Label>*/}
                    {/*  <Input className="tdSelect" value={formData['admissionGSTSponsorName']} placeholder="GST Sponser Name"*/}
                    {/*         onChange={(e) => {*/}
                    {/*           if (e.target.value.length <= 100) {*/}
                    {/*             UpdateFormData(5, 'admissionGSTSponsorName', e.target.value.replace(/[^a-zA-Z0-9./]/g, ""));*/}
                    {/*           }*/}
                    {/*         }}*/}
                    {/*         disabled={formData['admissionGSTCategory'] === '' || (action === 'view' && !studentId) || (!isEdit && action === 'view' && studentId !== null)}*/}
                    {/*  />*/}
                    {/*</FormGroup>*/}
                </Col>
                <Col md={3}>
                    <CommonInput label={label.gstIn} placeHolder={placeholder.gstIn}
                                 defaultValue={formData['admissionGSTIN']}
                                 type={'text'}
                                 restrictSpecialCharacter={true}
                                 maxLength={15}
                                 mandatory={!(formData['admissionGSTCategory'] === '')}
                                 isDisabled={formData['admissionGSTCategory'] === '' || (action === 'view' && !studentId) || (!isEdit && action === 'view' && studentId !== null)}
                                 onChange={(value) => {
                                     UpdateFormData(5, 'admissionGSTIN', value);
                                 }}
                    />
                    {/*<FormGroup>*/}
                    {/*    <Label for="studentId" className="form-control-label">*/}
                    {/*        GSTIN*/}
                    {/*        {*/}
                    {/*            formData['admissionGSTCategory'] === '' ?*/}
                    {/*                null :*/}
                    {/*                <RedAstric/>*/}
                    {/*        }*/}
                    {/*    </Label>*/}
                    {/*    <Input*/}
                    {/*        disabled={formData['admissionGSTCategory'] === '' || (action === 'view' && !studentId) || (!isEdit && action === 'view' && studentId !== null)}*/}
                    {/*        className="tdSelect" value={formData['admissionGSTIN']} placeholder="GSTIN"*/}
                    {/*        maxLength={15}*/}
                    {/*        onChange={(e) => {*/}
                    {/*            UpdateFormData(5, 'admissionGSTIN', e.target.value.replace(/[^a-zA-Z0-9]/g, ""))*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*</FormGroup>*/}
                </Col>
            </Row>
        </div>
    )
}
export default GstDetails;
