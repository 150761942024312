import React from "react";

export const ArrowSvg = () => {
  return (
    <svg
      stroke="currentColor"
      fill="rgba(0, 0, 0, 0.54)"
      strokeWidth="0"
      viewBox="0 0 512 512"
      height="24px"
      width="24px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M128 192l128 128 128-128z"></path>
    </svg>
  );
};

export const inputContainerStyle = {
  position: "sticky",
  top: "1px",
};

export const boldLevel = {
  fontWeight: "700",
  fontSize: "14px",
  fontFamily:"sans-serif",
  display :"inlineBlock",
  minWidth:"79px"
}