import React, { Fragment } from "react";
import Select, { components } from "react-select";
import { FormGroup, InputLabel } from "@mui/material";
import { InputLabelStyle } from "./ASelect";
import AButton from "./AButton";

export const AAutoCompleteMenuList = ({ children, ...props }) => {
  const selectedItems =
    children?.length > 0 &&
    (children?.filter((item) => item?.props?.isSelected) || []); // || [] has been added to hadle exceptions
  const remainingItems =
    children?.length > 0 &&
    (children?.filter((item) => !item?.props?.isSelected) || []);
  const childrenToRender =
    children?.length > 0 ? [...selectedItems, ...remainingItems] : children;
  return (
    <Fragment>
      <components.MenuList {...props}>
        {props?.selectProps?.isMulti &&
          props?.selectProps?.selectAllMenuButton &&
          !props?.selectProps?.inputValue &&
          props?.options?.length > 0 && (
            <div className="d-flex align-items-center justify-content-between px-2 py-1">
              <AButton
                className="button_remove_left_margin"
                variant="link"
                updatedStyle={{
                  height: "20px",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
                disabled={props?.getValue()?.length === props?.options?.length}
                onClick={() => props?.setValue(props?.options)}
              >
                Select all
              </AButton>
              <AButton
                className="button_remove_left_margin"
                variant="link"
                updatedStyle={{
                  height: "20px",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
                disabled={props?.getValue()?.length === 0}
                onClick={() => props?.setValue([])}
              >
                Deselect all
              </AButton>
            </div>
          )}
        {childrenToRender}
      </components.MenuList>
    </Fragment>
  );
};

export const AAutoCompleteCustomStyles = (
  style,
  height,
  fontSize,
  isMulti,
  selectAllMenuButton
) => ({
  container: (baseStyles) => ({
    ...baseStyles,
    width: "100%",
    ...style?.container,
  }),
  control: (baseStyles, { isDisabled }) => ({
    ...baseStyles,
    // padding: "7px 8px",
    maxHeight: "15rem",
    minHeight: "20px",
    height: height,
    fontSize: fontSize,
    background: isDisabled ? "#EDEDED" : "#FFFFFF",
    borderRadius: "8px",
    width: "100%",
    boxShadow: "none", // to remove focus outline
    "&:hover, &:focus, &:focus-within": {
      borderColor: "#00aeef",
    },
    ...style?.control,
  }),

  menuPortal: (baseStyles) => ({
    ...baseStyles,
    zIndex: 9999,
    fontSize: "12px",
    ...(isMulti && selectAllMenuButton ? { minWidth: "200px" } : {}),
    ...style?.menuPortal,
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    padding: "1px 8px",
    width: "100%",
    flexWrap: "nowrap",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "rgba(0, 0, 0, 0.87)",
    ...style?.valueContainer,
  }),
  indicatorSeparator: (baseStyles) => ({
    display: "hidden",
  }),
  dropdownIndicator: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
    paddingRight: "3px",
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state?.isSelected
      ? "rgba(25, 118, 210, 0.12)"
      : state.isFocused
      ? "rgba(25, 118, 210, 0.041)"
      : "#FFF",
    color: "black",
    padding: "5px 0.5rem",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      ...(state?.isFocused && {
        backgroundColor: state?.isSelected
          ? "rgba(25, 118, 210, 0.12)"
          : "rgba(25, 118, 210, 0.041)",
      }),
    },
    cursor: "pointer",
    fontSize: "14px",
    wordBreak: "break-all",
  }),
  menuList: (styles) => {
    return {
      ...styles,
      "::-webkit-scrollbar": {
        width: "10px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    };
  },
  noOptionsMessage: (baseStyles) => ({
    ...baseStyles,
    textAlign: "start",
  }),
});

export const AAutoCompleteOption = (props) => {
  return (
    <components.Option {...props}>
      {props?.selectProps?.isMulti && (
        <input
          style={{ marginRight: "5px" }}
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
      )}
      {props.children}
    </components.Option>
  );
};

export const AAutoCompleteMultiValue = ({ index, getValue, ...props }) => {
  return (
    !index &&
    (props?.selectProps?.inputValue ? " " : props?.selectProps?.placeholder)
  );
};

export const AAutoCompleteMultiValueWithCount = ({
  index,
  getValue,
  ...props
}) => {
  const values = props?.selectProps?.value?.map((o) => o.label);
  // if (values.length > 1) {
  //   let value = values.slice(0, 1).join(", ");
  //   let count = values.length - 1;
  //   return !index && `${value} ... ${count} More`;
  // } else {
  //   return !index && values?.join(", ");
  // }
  return !index && `${values?.length} selected`;
};

export const AAutoCompleteDropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        stroke="currentColor"
        fill="rgba(0, 0, 0, 0.54)"
        strokeWidth="0"
        viewBox="0 0 512 512"
        height="24px"
        width="24px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M128 192l128 128 128-128z"></path>
      </svg>
    </components.DropdownIndicator>
  );
};

const AAutoComplete = ({
  label = "",
  isMulti = true,
  items,
  currentValue,
  handleChange = () => {},
  isShowCount,
  placeholder,
  isLoading = false,
  isDisabled = false,
  menuPlacement = "auto",
  height = "31px",
  fontSize = "12px",
  style,
  customStylesUpdate,
  isAllEnabled = false,
  labelStyle = {},
  selectAllMenuButton = isAllEnabled ? false : true,
  noOptionsMessage = "No options",
  autoFocus = false,
  ...props
}) => {
  const customStyles = AAutoCompleteCustomStyles(
    style,
    height,
    fontSize,
    isMulti,
    selectAllMenuButton
  );

  function customOnChange(value, event) {
    if (!Array.isArray(value)) {
      handleChange(value);
      return;
    }

    if (event?.action === "select-option" && event?.option?.value === "*") {
      handleChange(this.options);
    } else if (
      event?.action === "deselect-option" &&
      event?.option?.value === "*"
    ) {
      handleChange([]);
    } else if (event?.action === "deselect-option") {
      handleChange(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1 && isAllEnabled) {
      handleChange(this.options);
    } else {
      handleChange(value);
    }
  }

  return (
    <div>
      <FormGroup>
        {label && (
          <InputLabel sx={{ ...InputLabelStyle, ...labelStyle }}>
            {label}
          </InputLabel>
        )}
        <Select
          isDisabled={isDisabled || false}
          placeholder={placeholder || "Select"}
          styles={{ ...customStyles, ...customStylesUpdate }}
          value={currentValue}
          isMulti={isMulti}
          isLoading={isLoading}
          hideSelectedOptions={false}
          cacheOptions
          options={items}
          onChange={customOnChange}
          closeMenuOnSelect={!isMulti}
          components={{
            Option: AAutoCompleteOption,
            MenuList: AAutoCompleteMenuList,
            ...{
              MultiValue: isShowCount
                ? AAutoCompleteMultiValueWithCount
                : AAutoCompleteMultiValue,
            },
            DropdownIndicator: AAutoCompleteDropdownIndicator,
          }}
          isClearable={false}
          menuPlacement={menuPlacement}
          menuPortalTarget={document.body}
          menuShouldScrollIntoView={false}
          selectAllMenuButton={selectAllMenuButton}
          noOptionsMessage={() => noOptionsMessage}
          autoFocus={autoFocus}
        />
      </FormGroup>
    </div>
  );
};

export default AAutoComplete;
