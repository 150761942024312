import React, {Fragment, useState} from 'react';

const StatusToggle = (props) => {

  const {item,index,tableData,setTableData} = props;
  const [changes,setChanges] = useState(false);
  return (
    <Fragment>
        <label className="custom-toggle mx-auto ml-2">
          <input
            checked={  tableData[index].status === 'ACTIVE' ? true : false }
            type="checkbox"
            onChange={(e) => {
              let temp = tableData;
              // console.log(temp[index])
              setChanges(!changes);
              // console.log(e.target.checked);
              let n = 'ACTIVE';
              if (!e.target.checked) {
                n = 'INACTIVE';
              }
              // console.log(n)
              temp[index].status = n;
              setTableData(temp)
            }}
          />
          <span
            style={{width: '72px'}}
            className="custom-toggle-slider rounded-circle activeToggle"
            data-label-off="Inactive"
            data-label-on="Active"
          />
        </label>
    </Fragment>
  )
}

export default  StatusToggle;
