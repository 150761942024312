import React, { useEffect } from "react";
import {
  Card,
  CardHeader,
  Input,
  Container,
  Button,
  Table,
  Row,
  Label,
} from "reactstrap";
import { useState } from "react";
import { failureToast, successToast } from "../../common/utils/methods/toasterFunctions/function";
import {
  masterServiceBaseUrl,
  putpost,
  putpost2,
  getAPI,
} from "services/http";
import moment from "moment";
import { MasterHeaderAuto } from "../../common/commonComponents/masterHeader/masterHeader";
import Select from "react-select2-wrapper";
import {
  NewRow,
  getObjectById,
} from "../../common/utils/methods/commonMethods/utilityMethod";
import { ToExcel } from '../../common/commonComponents'
import "./index.scss";
import CustomButton from "../../../../../components/CustomButton";


const excelDetails = {
  "Group Group": "groupCode",
  "Comapany Code": "companyCode",
  "Classification" : "classification",
  "Inst.No." : "instNo",
  "Element" : "element",
  "Item Type" : "itemTypeId",
  "Type" : "type",
  "Updated On" : "updatedon",
  "Description" : "description",
  "Short Description" : "shortDescription",
  "Tax Code" : "taxCode",
  "Status" : "status",
  "SF Sync" : "sfSync",
};


var _ = require("lodash");

const ClassesTable = (props) => {
  const {
    sortData = () => {},
    isSaveVisible,
    setisSaveVisible = () => {},
    serverData,
    searchStr,
    setSearchStr,
    resetSearch,
    setSearch,
    tableData,
    permissionSet
  } = props;

  const [tableDataKey, settableDataKey] = useState("tableDataKey");
  const [itemType, setItemType] = useState("");
  const [typeDisp, setTypeDisp] = useState("");
  const [index, setIndex] = useState();
  const [companyCode, setCompanyCode] = useState([]);
  const [searchData, setSearchData] = useState("");

  const addNewClass = () => {
    props.setclassArray([
      {
        __status: "__new",
        editMode: true,
        groupCodeId: null,
        companyCodeId: null,
        itemTypeClassificationId: null,
        itemTypeCategoryId: null,
        itemTypeElementId: null,
        itemTypeId: null,
        type: null,
        updatedOn: moment().format("DD-MM-YYYY"),
        description: null,
        shortDescription: null,
        taxCode: null,
        sfSync: "NO",
        status: "ACTIVE",
      },
      ...props.classArray,
    ]);
  };

  const headerList = [
    { name: "GROUP CODE" },
    { name: "COMPANY CODE" },
    { name: "CLASSIFICATION", isSortable: true, sortFun: sortData, keyName : "classification", isSaveVisible : isSaveVisible },
    { name: "INST.NO.", isSortable: true, sortFun: sortData, keyName : "instNo", isSaveVisible : isSaveVisible },
    { name: "ELEMENT", isSortable: true, sortFun: sortData, keyName : "element", isSaveVisible : isSaveVisible },
    { name: "ITEM TYPE", isSortable: true, sortFun: sortData, keyName : "itemTypeId", isSaveVisible : isSaveVisible },
    { name: "TYPE", isSortable: true, sortFun: sortData, keyName : "type", isSaveVisible : isSaveVisible },
    { name: "UPDATE DATE" },
    { name: "DESCRIPTION" },
    { name: "SHORT DESCR" },
    { name: "HSN/SAC TAX CODE" },
    { name: "STATUS" },
    { name: "SF SYNC" },
    { name: "SETUP INV A/C ENTRY" },
    { name: "ACTION" },
  ];

  const listToBeRendered = [
    "groupCode",
    "companyCode",
    "classification",
    "instNo",
    "element",
    "itemTypeId",
    "type",
    "updatedon",
    "description",
    "shortDescription",
    "taxCode",
    "status",
    "sfSync",
  ];

  const saveAll = () => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
      if (el["editMode"]) {
        if (el["groupCodeId"] == null) {
          isValid = false;
          failureToast("Please select group code");
          return;
        } else if (el["companyCodeId"] == null) {
          isValid = false;
          failureToast("Please select company code");
          return;
        } else if (el["itemTypeClassificationId"] == null) {
          isValid = false;
          failureToast("Please select classification");
          return;
        } else if (el["itemTypeCategoryId"] == null) {
          isValid = false;
          failureToast("Please select INST.NO.");
          return;
        } else if (el["itemTypeElementId"] == null) {
          isValid = false;
          failureToast("Please select Element");
          return;
        } else if (el["description"] == null || !el["description"].trim()) {
          isValid = false;
          failureToast("Please enter the  description");
          return;
        } else if (
          el["shortDescription"] == null ||
          !el["shortDescription"].trim()
        ) {
          isValid = false;
          failureToast("Please enter the short description");
          return;
        } else if (el["taxCode"] == null || !el["taxCode"].trim()) {
          isValid = false;
          failureToast("Please enter the HSN/SAC Tax Code");
          return;
        }

        if (!isValid) {
          return;
        }
        if (_tempArray[index]["__status"] == "__new") {
          putpost(
            masterServiceBaseUrl + "/itemTypeSetup/createItemTypeSetup",
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              props.fetchClasses(
                masterServiceBaseUrl + "/itemTypeSetup/getActiveItemTypeSetup"
              );
            },
            (data) => {
              failureToast(data["message"]);
            },
            {
              companyCodeId: el["companyCodeId"],
              description: el["description"],
              editMode: true,
              groupCodeId: el["groupCodeId"],
              itemTypeCategoryId: el["itemTypeCategoryId"],
              itemTypeClassificationId: el["itemTypeClassificationId"],
              itemTypeElementId: el["itemTypeElementId"],
              itemTypeId: el["itemTypeId"],
              sfSync: el["sfSync"],
              shortDescription: el["shortDescription"],
              status: el["status"],
              taxCode: el["taxCode"],
              type: el["type"],
            },
            "post"
          );
        } else {
          putpost(
            masterServiceBaseUrl +
              "/itemTypeSetup/updateItemTypeSetup/" +
              _tempArray[index].id,
            (data) => {
              successToast(data["message"]);
              setisSaveVisible(false);
              delete _tempArray[index]["editMode"];
              props.setclassArray(_tempArray);
              settableDataKey(new Date().getMilliseconds());
              props.fetchClasses(
                masterServiceBaseUrl + "/itemTypeSetup/getActiveItemTypeSetup"
              );
            },
            (data) => {
              failureToast(data["message"]);
            },
            _tempArray[index],
            "put"
          );
        }
      }
    });
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      setIndex(0);
      cb();
    }
  };

  const editRow = (index, groupId) => {
    if (isSaveVisible) {
      failureToast("Please Delete or Save current changes");
    } else {
      let cb = () => {
        getCompanyCode(groupId);
        var _tempArray = props.classArray;
        _tempArray[index]["editMode"] = true;
        props.setclassArray(_tempArray);
        setisSaveVisible(true);
        setIndex(index);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };
  const deleteRow = (index) => {
    if (props.classArray[index]["__status"] == "__new") {
      let n = props.classArray;
      n.splice(index, 1);
      props.setclassArray(n);
      setisSaveVisible(false);
      settableDataKey(new Date().getMilliseconds());
    } else {
      if (
        window.confirm("Are you sure you want to delete this item type setup?")
      ) {
        putpost2(
          masterServiceBaseUrl +
            "/itemTypeSetup/deleteItemTypeSetup/" +
            props.classArray[index].id,
          (data) => {
            successToast(data["message"]);

            props.fetchClasses(
              masterServiceBaseUrl + "/itemTypeSetup/getActiveItemTypeSetup"
            );

            settableDataKey(new Date().getMilliseconds());
          },
          (data) => {
            failureToast(data["message"]);
          },
          "PUT"
        );
      }
    }
  };
  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    if (!(_tempArray[index]["itemTypeClassificationId"] === null)) {
      _tempArray[index]["type"] = typeDisp;
    }
    if (
      !(_tempArray[index]["itemTypeClassificationId"] === null) &&
      !(_tempArray[index]["itemTypeCategoryId"] === null) &&
      !(_tempArray[index]["itemTypeElementId"] === null)
    ) {
      let classificationVal =
        getObjectById(
          _tempArray[index]["itemTypeClassificationId"],
          props.classificationType
        )["text"] ?? "";
      let categoryVal =
        getObjectById(
          _tempArray[index]["itemTypeCategoryId"],
          props.categoryType
        )["text"] ?? "";
      let elementVal =
        getObjectById(
          _tempArray[index]["itemTypeElementId"],
          props.elementType
        )["text"] ?? "";
      let valueTemp = classificationVal + categoryVal + elementVal;
      _tempArray[index]["itemTypeId"] = valueTemp;
      setItemType(valueTemp);
    }
    props.setclassArray(_tempArray);
  };

  const getTypeValueClassification = (value) => {
    const typeValue =
      getObjectById(value, props.classificationType)["text"] ?? "";
    var _tempArray = props.classArray;
    _tempArray[index]["type"] = typeValue;
    props.setclassArray(_tempArray);
    setTypeDisp(typeValue);
  };

  const getCompanyCode = (value) => {
    getAPI(
      masterServiceBaseUrl + `/companyCode/getCompanyCodeByGroup/${value}`,
      (data) => {
        let tempCompanyCode = [];
        data &&
          data.data.map((item) => {
            tempCompanyCode.push({
              id: item.id,
              text: item.companyCodeDispValue,
            });
          });
        setCompanyCode(tempCompanyCode);
      },
      (data) => {
        setCompanyCode([]);
        failureToast(data["message"]);
      }
    );
  };


  const filterData = (str) => {
    try{
      const newData =
      serverData &&
      serverData.filter((obj) =>
        Object.values(obj).some(
          (val) =>
            val && val.toString().toLowerCase().includes(str.toString().toLowerCase())
        )
      );
    setSearch(newData);
    }catch(e){

    }
  };

  useEffect(() => {
    if (serverData && (!searchStr || !searchStr.trim())) {
      resetSearch();
      // setisSaveVisible(false);
    } 
    
    if(serverData && searchStr){
      // setisSaveVisible(true);
      filterData(searchStr.trim());
    }
  }, [searchStr]);


  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Item Type Setup</h3>
                <div
                  className="floatRight "
                  style={{ display: "flex", alignItems: "center", marginLeft: 'auto' }}
                >
                  Search :{" "}
                  <Input
                    value={searchStr}
                    className="tdInput ml-3 mr-3 customInputWidth"
                    placeholder="Search"
                    onChange={(e) =>setSearchStr(e.target.value)}
                    disabled={isSaveVisible}
                  />
                   {!isSaveVisible && tableData.length  ? <ToExcel data={tableData} detailObj={excelDetails} name="Item Type Setup" isSaveVisible={isSaveVisible}/> : null}
                  {/*<Button*/}
                  {/*  disabled={isSaveVisible || searchStr}*/}
                  {/*  color="info"*/}
                  {/*  size="sm"*/}
                  {/*  type="button"*/}
                  {/*  className={*/}
                  {/*    "floatRight mx-1" +*/}
                  {/*    (isSaveVisible || searchStr ? " btn-dark cursor-gray" : null)*/}
                  {/*  }*/}
                  {/*  onClick={() => {*/}
                  {/*    newRow();*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  New Item Type Setup*/}
                  {/*</Button>*/}
                  <CustomButton
                      disabled={isSaveVisible || searchStr}
                      className={"floatRight mx-1" + (isSaveVisible || searchStr ? " btn-dark cursor-gray" : null)}
                      content={'New Item Type Setup'}
                      permissionType={'C'}
                      icon={true}
                      permissionSet={permissionSet}
                      onClick={() => newRow()}
                  />
                </div>
              </CardHeader>
              <div
                id="questionTable"
                className="table-responsive item-type-setup"
              >
                <Table className="align-items-center ">
                  <MasterHeaderAuto headerList={permissionSet.includes('U')|| permissionSet.includes('D') || isSaveVisible ? headerList :headerList.slice(0,14)} permissionSet={permissionSet} isSaveVisible={isSaveVisible}/>
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) => 
                          !el["editMode"] ? (
                            // <TDROWITEMTYPESETUP
                            //   classificationTypeList={classificationTypeList}
                            //   categoryTypeList={categoryTypeList}
                            //   elementTypeList={elementTypeList}
                            //   getDisplayValue={getDisplayValue}
                            //   companyCodeList={companyCodeList}
                            //   groupCodeList={groupCodeList}
                            //   type={'class'}
                            //   index={index}
                            //   el={el}
                            //   editRow={editRow}
                            //   deleteRow={deleteRow}
                            // />
                            <NewRow
                              listToBeRendered={listToBeRendered}
                              data={el}
                            >
                              <td className="text-center ">
                                <Button color="info" size="sm" type="button">
                                  <i className="fas fa-edit" />
                                  Add Invoice A/C Entry
                                </Button>
                                {/*<CustomButton*/}
                                {/*    permissionType={'U'}*/}
                                {/*    content={'Add Invoice A/C Entry'}*/}
                                {/*    forTable={true}*/}
                                {/*    icon={true}*/}
                                {/*    permissionSet={permissionSet}*/}
                                {/*    onClick={() => {''}}*/}
                                {/*/>*/}
                              </td>
                              {
                                permissionSet && (permissionSet.includes('U') || permissionSet.includes('D')||isSaveVisible) ?
                                <td className="text-center ">
                                {/*<Button*/}
                                {/*  color="info"*/}
                                {/*  size="sm"*/}
                                {/*  type="button"*/}
                                {/*  onClick={() => {*/}
                                {/*    editRow(index, el.groupCodeId);*/}
                                {/*  }}*/}
                                {/*>*/}
                                {/*  {" "}*/}
                                {/*  <i className="fas fa-edit" />*/}
                                {/*</Button>*/}
                                <CustomButton
                                    permissionType={'U'}
                                    forTable={true}
                                    icon={true}
                                    permissionSet={permissionSet}
                                    onClick={() => {
                                      editRow(index, el.groupCodeId);
                                    }}
                                />
                                {/*<Button*/}
                                {/*  color="info"*/}
                                {/*  size="sm"*/}
                                {/*  type="button"*/}
                                {/*  onClick={() => {*/}
                                {/*    deleteRow(index);*/}
                                {/*  }}*/}
                                {/*>*/}
                                {/*  {" "}*/}
                                {/*  <i className="fas fa-trash" />*/}
                                {/*</Button>*/}
                                <CustomButton
                                    permissionType={'D'}
                                    forTable={true}
                                    icon={true}
                                    permissionSet={permissionSet}
                                    onClick={() => {
                                      deleteRow(index);
                                    }}
                                />
                              </td> : null}
                            </NewRow>
                          ) : (
                            <>
                            <tr key={index + "-class"}>
                              <td className="text-center p-2 mx-1">
                                <Select
                                  defaultValue={el.groupCodeId}
                                  data={props.groupCode}
                                  onChange={(e) => {
                                    getCompanyCode(e.target.value);
                                    updateKey(
                                      index,
                                      e.target.value,
                                      "groupCodeId"
                                    );
                                  }}
                                  options={{ placeholder: "Select Group Code" }}
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Select
                                  defaultValue={el.companyCodeId}
                                  data={companyCode}
                                  className="tdSelect"
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      "companyCodeId"
                                    );
                                  }}
                                  // className="tdSelect"
                                  options={{
                                    placeholder: "Select Company Code",
                                  }}
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Select
                                  defaultValue={el.itemTypeClassificationId}
                                  data={props.classificationType}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      "itemTypeClassificationId"
                                    );
                                    getTypeValueClassification(e.target.value);
                                  }}
                                  className="tdSelect"
                                  options={{
                                    placeholder: "Select Classification",
                                  }}
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Select
                                  defaultValue={el.itemTypeCategoryId}
                                  data={props.categoryType}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      "itemTypeCategoryId"
                                    );
                                  }}
                                  className="tdSelect"
                                  options={{ placeholder: "Select INST.NO" }}
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Select
                                  defaultValue={el.itemTypeElementId}
                                  data={props.elementType}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      "itemTypeElementId"
                                    );
                                  }}
                                  className="tdSelect"
                                  options={{ placeholder: "Select Element" }}
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                {/* <Label className="white-breakSpace customLabel">
                                  {el.itemTypeId}{" "}
                                </Label> */}
                                <Input
                                  disabled={true}
                                  value={el.itemTypeId}
                                  className="tdInput"
                                  style={{ wordBreak: "break-all" }}
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  disabled={true}
                                  value={el.type}
                                  className="tdInput"
                                  style={{ wordBreak: "break-all" }}
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  disabled={true}
                                  value={el["updatedOn"]}
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength={50}
                                  defaultValue={el["description"]}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      "description"
                                    );
                                  }}
                                  className="tdInput"
                                  placeholder="Description"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength={20}
                                  defaultValue={el["shortDescription"]}
                                  onChange={(e) => {
                                    updateKey(
                                      index,
                                      e.target.value,
                                      "shortDescription"
                                    );
                                  }}
                                  className="tdInput"
                                  placeholder="Short Description"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  maxLength={10}
                                  defaultValue={el["taxCode"]}
                                  onChange={(e) => {
                                    updateKey(index, e.target.value, "taxCode");
                                  }}
                                  className="tdInput"
                                  placeholder="Enter Tax Code"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <label className="custom-toggle mx-auto ml-2">
                                  <input
                                    checked={
                                      el.status == "ACTIVE" ? true : false
                                    }
                                    type="checkbox"
                                    id={el.id}
                                    onChange={(e) => {
                                      settableDataKey(
                                        new Date().getMilliseconds()
                                      );
                                      let n = "ACTIVE";
                                      if (!e.target.checked) {
                                        n = "INACTIVE";
                                      }
                                      updateKey(index, n, "status");
                                    }}
                                  />
                                  <span
                                    style={{ width: "72px" }}
                                    className="custom-toggle-slider rounded-circle activeToggle"
                                    data-label-off="Inactive"
                                    data-label-on="Active"
                                  />
                                </label>
                              </td>
                              <td className="text-center p-2 mx-1">
                                <label className="custom-toggle mx-auto ml-2">
                                  <input
                                    checked={el.sfSync == "NO" ? false : true}
                                    type="checkbox"
                                  />
                                  <span
                                    style={{ width: "72px" }}
                                    className="custom-toggle-slider activeToggle"
                                    data-label-off="NO"
                                    data-label-on="YES"
                                  />
                                </label>
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Button color="info" size="sm" type="button">
                                  <i className="fas fa-edit" />
                                  Add Invoice A/C Entry
                                </Button>
                              </td>
                              <td className="text-center">
                                {isSaveVisible ? (
                                  <>
                                    {/*<Button*/}
                                    {/*  color="info"*/}
                                    {/*  size="sm"*/}
                                    {/*  type="button"*/}
                                    {/*  className="mx-1"*/}
                                    {/*  onClick={() => {*/}
                                    {/*    saveAll();*/}
                                    {/*  }}*/}
                                    {/*>*/}
                                    {/*  Save*/}
                                    {/*</Button>*/}
                                    <CustomButton
                                        content={'Save'}
                                        className={"mx-1"}
                                        permissionType={'C,U'}
                                        forTable={true}
                                        permissionSet={permissionSet}
                                        onClick={() => {saveAll();}}
                                    />
                                    {/*<Button*/}
                                    {/*  color="danger"*/}
                                    {/*  size="sm"*/}
                                    {/*  type="button"*/}
                                    {/*  onClick={() => {*/}
                                    {/*    let n = props.classArray;*/}
                                    {/*    if (n[index]["__status"] == "__new") {*/}
                                    {/*      n.splice(index, 1);*/}
                                    {/*    } else {*/}
                                    {/*      props.fetchClasses(*/}
                                    {/*        masterServiceBaseUrl +*/}
                                    {/*          "/itemTypeSetup/getActiveItemTypeSetup"*/}
                                    {/*      );*/}
                                    {/*    }*/}
                                    {/*    props.setclassArray(n);*/}
                                    {/*    setisSaveVisible(false);*/}
                                    {/*  }}*/}
                                    {/*>*/}
                                    {/*  {" "}*/}
                                    {/*  <i className="fas fa-times" />*/}
                                    {/*</Button>*/}
                                    <CustomButton
                                        permissionType={'cancel'}
                                        forTable={true}
                                        icon={true}
                                        permissionSet={permissionSet}
                                        onClick={() => {
                                          let n = props.classArray;
                                          if (n[index]["__status"] == "__new") {
                                            n.splice(index, 1);
                                          } else {
                                            props.fetchClasses(
                                                masterServiceBaseUrl +
                                                "/itemTypeSetup/getActiveItemTypeSetup"
                                            );
                                          }
                                          props.setclassArray(n);
                                          setisSaveVisible(false);
                                        }}
                                    />
                                  </>
                                ) : (
                                  <Button
                                    color="info"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                      deleteRow(index);
                                    }}
                                  >
                                    {" "}
                                    <i className="fas fa-trash" />
                                  </Button>
                                )}
                              </td>
                            </tr></>
                          )
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ClassesTable;
