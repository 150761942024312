import React, { useState } from "react";
import AAutoComplete from "./../../common/form-fields-mui/AAutoComplete";
import { Row, Col } from "reactstrap";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

const FistLabel = ({  form, handler, testTypesDropdown, isResultDatePassed, isDisableTestType }) => {
  
  const {testType, isMandatory} = form

  return (
    <Row className="mb-4">
      <Col md={4}>
        <AAutoComplete 
         currentValue={testType}
         handleChange = {(value)=>handler(value,"testType")}
         isMulti = {false}
         isDisabled ={isResultDatePassed||isDisableTestType}
         items={testTypesDropdown}></AAutoComplete>
         
      </Col>
      <Col md={4}>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={isMandatory}
              onChange={() => handler(!isMandatory, "isMandatory")}
              sx={{ "& .MuiSvgIcon-root": { fontSize: "20px" } }}
              disabled  ={isResultDatePassed||isDisableTestType}
            />
          }
          label="Mandatory test"
        />
      </Col>
    </Row>
  );
};

export default FistLabel;
