import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import ADataGrid from '../../../../common/data-grid/ADataGrid';
import NoRowOverlay from '../../../../masters/holidayMaster/NoRowOverlay';
import {
  generateRandomId,
  getFormattedDate,
  isObjEmpty,
} from '../../../../common/utils/methods/commonMethods/utilityMethod';
import {
  gridStyles,
  gridComponents,
} from 'views/pages/manage/edpOps/issuanceIDcard/printIdCardAndMapRfid/constant.js';
import { failureToast } from '../../../../common/utils/methods/toasterFunctions/function';
import { GridRowModes } from '@mui/x-data-grid';
import { newRow } from './constant';
import { AGridColDef } from '../../../../common/data-grid/ADataGridColDef';
import moment from 'moment';
import { IoEyeOutline } from 'react-icons/io5';
import { Tooltip } from '@mui/material';
import {
  editCellContainerStyle,
  inputStyles,
} from '../../mastersAndMapping/onBoardingMaterialIdSetup/constant';
import { saveOrDeleteMsg } from '../../mastersAndMapping/sapProfitCostCentreMapping/constant';
import AInput from '../../../../common/formFeilds/customInput/AInput';
import ActionsCellComponent from './ActionsCellComponent'
import { regex } from 'views/pages/manage/common/constant';

const ListComponent = forwardRef(
  (
    {
      page,
      onPageChange,
      rows,
      loader,
      createHandler,
      updateHandler,
      deleteHandler,
    },
    ref
  ) => {
    const [rowModesModel, setRowModesModel] = useState({});
    const [rowData, setRowData] = useState(rows);
    useEffect(() => setRowData(rows), [rows]);
    const [rowForm, setRowForm] = useState({});
    const rowFormHandler = (value, key) =>
      setRowForm(prev => ({ ...prev, [key]: value }));

    const startEditing = (id, form) => {
      setRowModesModel(oldModel => ({ [id]: { mode: GridRowModes.Edit } }));
      setRowForm(form);
    };

    const stopEditing = () => {
      const currForm = { ...rowForm };
      setRowModesModel(oldModel => ({ [currForm?.id]: { mode: GridRowModes.View, ignoreModifications: true } }));
      setRowForm({});
    };

    const handleEditClick = params => {
      if (!isObjEmpty(rowForm)) {
        failureToast(saveOrDeleteMsg);
      } else {
        startEditing(params?.id, params?.row);
      }
    };

    const handleCancelClick = async params => {
      if (params?.row?.isNew) {
        setRowData(rows);
        setRowForm({});
      } else {
        stopEditing(params?.id);
      }
    };

    const handleSaveClick = async params => {
      if (rowForm?.isNew) {
        await createHandler(rowForm);
      } else {
        await updateHandler(rowForm);
      }
    };

    const addNewRow = async () => {
      if (!isObjEmpty(rowForm)) {
        failureToast(saveOrDeleteMsg);
      } else {
        let randomId = generateRandomId();
        let emptyRow = { ...newRow, isNew: true, id: randomId };
        startEditing(randomId, emptyRow);
        setRowData(prev => [
          ...prev?.slice(0, page * 50),
          emptyRow,
          ...prev?.slice(page * 50, prev?.length + 1),
        ]);
      }
    };

    useImperativeHandle(ref, () => ({
      addNewRow: addNewRow,
      startEditing: startEditing,
      stopEditing: stopEditing,
    }));

    const columns = [
      new AGridColDef('courierName', 'Courier name')
        .setMinWidth(130)
        .editable(true)
        .setFlex(0.5)
        .renderCellComponent(params => (
          <Tooltip title={params?.value}>
            <div className="ellipsis-text-container">{params?.value}</div>
          </Tooltip>
        ))
        .renderEditCellComponent(params => (
          <div style={editCellContainerStyle}>
            <AInput
              placeholder="Courier name"
              value={rowForm?.courierName}
              handler={value =>
                value?.length <= 70 && rowFormHandler(value, 'courierName')
              }
              style={inputStyles}
            />
          </div>
        )),
      new AGridColDef('pocName', 'POC name')
        .setMinWidth(120)
        .editable(true)
        .setFlex(0.5)
        .renderCellComponent(params => (
          <Tooltip title={params?.value}>
            <div className="ellipsis-text-container">{params?.value}</div>
          </Tooltip>
        ))
        .renderEditCellComponent(params => (
          <div style={editCellContainerStyle}>
            <AInput
              placeholder="Name"
              value={rowForm?.pocName}
              handler={value =>
                value?.length <= 50 && rowFormHandler(value, 'pocName')
              }
              style={inputStyles}
            />
          </div>
        )),
      new AGridColDef('contactNumber', 'Contact number')
        .setMinWidth(130)
        .editable(true)
        .setFlex(0.5)
        .renderCellComponent(params => (
          <Tooltip title={params?.value}>
            <div className="ellipsis-text-container">{params?.value}</div>
          </Tooltip>
        ))
        .renderEditCellComponent(params => (
          <div style={editCellContainerStyle}>
            <AInput
              placeholder="Number"
              value={rowForm?.contactNumber}
              regex={regex.numericPlusPhoneNumber}
              handler={value => rowFormHandler(value, 'contactNumber')}
              style={inputStyles}
            />
          </div>
        )),
      new AGridColDef('emailId', 'Mail ID')
        .setMinWidth(120)
        .editable(true)
        .setFlex(0.5)
        .renderCellComponent(params => (
          <Tooltip title={params?.value}>
            <div className="ellipsis-text-container">{params?.value}</div>
          </Tooltip>
        ))
        .renderEditCellComponent(params => (
          <div style={editCellContainerStyle}>
            <AInput
              placeholder="Mail ID"
              value={rowForm?.emailId}
              handler={value => rowFormHandler(value, 'emailId')}
              style={inputStyles}
            />
          </div>
        )),
      new AGridColDef('website', 'Website', false)
        .setMinWidth(140)
        .editable(true)
        .setFlex(0.5)
        .renderCellComponent(params => (
          <div className="d-flex align-items-center ellipsis-text-container">
            <Tooltip title={params?.value}>
              <div className="ellipsis-text-container">{params?.value}</div>
            </Tooltip>
            <Tooltip title="Open link">
              <span
                className="d-flex"
                onClick={() => window.open(params?.value, '_blank')}
              >
                <IoEyeOutline className="ml-1 pointer" size={18} />
              </span>
            </Tooltip>
          </div>
        ))
        .renderEditCellComponent(params => (
          <div style={editCellContainerStyle}>
            <AInput
              placeholder="Website"
              value={rowForm?.website}
              handler={value =>
                value?.length <= 100 && rowFormHandler(value, 'website')
              }
              style={inputStyles}
            />
          </div>
        )),
      new AGridColDef('trackingLink', 'Tracking link', false)
        .setMinWidth(140)
        .editable(true)
        .setFlex(0.5)
        .renderCellComponent(params => (
          <div className="d-flex align-items-center ellipsis-text-container">
            <Tooltip title={params?.value}>
              <div className="ellipsis-text-container">{params?.value}</div>
            </Tooltip>
            <Tooltip title="Open link">
              <span
                className="d-flex"
                onClick={() => window.open(params?.value, '_blank')}
              >
                <IoEyeOutline className="ml-1 pointer" size={18} />
              </span>
            </Tooltip>
          </div>
        ))
        .renderEditCellComponent(params => (
          <div style={editCellContainerStyle}>
            <AInput
              placeholder="Tracking link"
              value={rowForm?.trackingLink}
              handler={value =>
                value?.length <= 100 && rowFormHandler(value, 'trackingLink')
              }
              style={inputStyles}
            />
          </div>
        )),
      new AGridColDef('updatedOn', 'Updated on/by')
        .setMinWidth(150)
        .setFlex(0.3)
        .renderCellComponent(params =>
          params?.value ? (
            <div className="d-flex align-items-center">
              <div>{getFormattedDate(params?.value)}</div>
              <Tooltip
                title={`${getFormattedDate(params?.value)}, ${moment(
                  params?.value
                ).format('LT')} by ${params?.row?.updatedBy}`}
              >
                <span className="d-flex">
                  <IoEyeOutline className="ml-2 pointer" size={18} />
                </span>
              </Tooltip>
            </div>
          ) : (
            <div>-</div>
          )
        ),
      new AGridColDef('actions', 'Actions', false)
        .setMinWidth(135)
        .columnAlign('right')
        .setFlex(0.2)
        .renderCellComponent(params => (
          <ActionsCellComponent
            params={params}
            handleEditClick={handleEditClick}
            handleCancelClick={handleCancelClick}
            handleSaveClick={handleSaveClick}
            rowForm={rowForm}
            deleteHandler={deleteHandler}
          />
        )),
    ];

    return (
      <div className="mt-3">
        <ADataGrid
          removeWrapperContainer
          rowModesModel={rowModesModel}
          rows={rowData || []}
          columns={columns}
          rowId={row => row?.id}
          rowHeight={50}
          loading={false}
          serverPagination={false}
          currentPage={page}
          nextButtonHandler={onPageChange}
          components={{
            ...gridComponents,
            NoRowsOverlay: () => (
              <NoRowOverlay message={loader ? 'Loading...' : null} />
            ),
          }}
          sx={{
            ...gridStyles,
          }}
          autoHeight
          hidePagination={rowData?.length <= 50}
        />
      </div>
    );
  }
);

export default ListComponent;