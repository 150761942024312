import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router';
import searchForm from './form.json'
import { FormGenerator } from '../common/commonComponents/formGenerator';
import { fetchAllPostPromisedData, fetchAllPromisedData, NewRow, openInNewTab } from '../common/utils/methods/commonMethods/utilityMethod';
import { Card, CardHeader, Container, Row, Table } from 'reactstrap';
import CustomButton from '../../../../components/CustomButton';
import { PermissionContext, RolePermissions } from "appContext";
import { constants, endpoint, pages } from "../common/constant";
import { getPaginatedData } from '../common/utils/methods/commonMethods/paginationMethod';
import { failureToast } from '../common/utils/methods/toasterFunctions/function';
import CustomLoader from "../common/commonComponents/Loader/loader";
import { MasterHeaderAuto } from '../common/commonComponents/masterHeader/masterHeader';
import { GetPagination } from '../common/commonComponents/pagination/pagination';
import { getAPI } from 'services/http';


let page = 0;
const pageSize = 10;
const headerList = [
  { name: 'ID' },
  { name: 'Role Name' },
  { name: 'Role Description' },
  { name: 'Project' },
  { name: 'Status' },
  { name: 'Action' }
]

const listToRender = [
  'roleId',
  'roleName',
  'roleDescription',
  'projectId',
  'status'
]

const itemToBeRenderFromMaster = [
  "projectId"
];
const propertyList = [
  "",
  "",
  "",
  "label",
  "",
  "",
];

let isNextCallPossible = true;


const RoleSearchPage  = () => {

  const history = useHistory();

  const permissions = useContext(PermissionContext);
  const userPermissions = RolePermissions(permissions, pages["userRolePage"]['id']);


  const [values, setValues] = useState({
    roleDescription:'',
    roleName:'',
    projectId:''
  })
  const [project, setProject] = useState([]);
  const [isFetching, setIsFetching] = useState(false)
  let [pagination, setpagination] = useState(constants.pagination);
  const [tableData, setTableData] = useState([]);
  const [serverData, setServerData] = useState(null);
	const [savedSearchParams,setSavedSeachParams] = useState(null);
  const [isPaginationFromServer, setIsPaginationFromServer] = useState(true);

  const mastersList = [null,null,null,project?.data,null,null];


  const getInitailData = async () => {
    const project = await fetchAllPromisedData(endpoint.projectDetails.getAllProject);

    if (Array.isArray(project)) {
      const tempArr = project.map(d => { return { label: d.projectDispValue, value: d.id, id: d.id } })
      setProject({ data: tempArr })
    }
  }

  const fetchClasses = async (url) => {
		setIsFetching(true);
		getAPI(
			url,
			(data) => {
        const roles = data?.data?.roleMasterSearchResponseDTO.map(item => item);
				setServerData(roles);
				getPaginatedData(roles, pageSize, constants.pagination, setpagination, setIsFetching, setTableData);
			},
			(data) => {
				failureToast(data['message']);
			}
		);
	};

  useEffect(() => {
    getInitailData();
    fetchClasses(endpoint.role_Name.getAllRole + "/0");
  }, [])


  const searchHandler = async () => {
    try {

      if (!values.roleDescription.trim() && !values.roleName && !values.projectId?.value ) {
        failureToast("Select atleast one search criteria")
        return
      }

      setIsFetching(true);
      page = 0;
      const searchData = await fetchAllPostPromisedData(endpoint.getRole.getRoles, { ...values,roleName: values.roleName.toString().toUpperCase() ,roleDescription: values.roleDescription?.toString().trim(), projectId: values.projectId?.value })
      if (searchData?.code === 200) {
        setServerData(searchData.data);
        setIsPaginationFromServer(false);
        getPaginatedData(searchData.data, pageSize, constants.pagination, setpagination, setIsFetching, setTableData);
        setSavedSeachParams({ ...values,roleName: values.roleName.toString().toUpperCase() ,roleDescription: values.roleDescription?.toString().trim(), projectId: values.projectId?.value })
      } else {
        setServerData([]);
        getPaginatedData([], pageSize, constants.pagination, setpagination, setIsFetching, setTableData);
        setSavedSeachParams(null);
        setIsPaginationFromServer(true);
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  const reset = () => {
    setValues({
      roleDescription:'',
      roleName:'',
      project:''
    })
    fetchClasses(endpoint.role_Name.getAllRole + "/0");
    setSavedSeachParams(null);
    setIsPaginationFromServer(true);
  }

  const fetchNext = async () => {
			if(savedSearchParams === null) {
        setIsFetching(true);
        page = page + 1;
        const resp = await fetchAllPromisedData(`${endpoint.role_Name.getAllRole}/${page}`)
        if (resp?.roleMasterSearchResponseDTO && resp?.roleMasterSearchResponseDTO?.length > 0) {
          const data = resp?.roleMasterSearchResponseDTO.map(item => ({...item, roleId: item.id}));
          const updatedData = [...serverData, ...data];
          setServerData(updatedData);
          getPaginatedData(updatedData, pageSize, pagination, setpagination, setIsFetching, setTableData);
          isNextCallPossible = data.length == 50 ? true : false;
        } else {
          isNextCallPossible = false;
        }
        setIsFetching(false);
      }
	}

  return (
    <Container fluid>
      <Card>
        <CardHeader>
          <h3>User Role Search</h3>
          <CustomButton
            className={'floatRight mx-1 '}
            content={"Add New User Role"}
            permissionType={'C'}
            icon={true}
            onClick={() => history.push("/admin/role/new")}
            // onClick={()=>{}}
            permissionSet={userPermissions}
          />
        </CardHeader>
        <Row className="pl-4 pr-4 pb-2 pt-2">
          <FormGenerator
            fields={Object.keys(searchForm.search)}
            fieldsObject={searchForm.search}
            values={values}
            setValues={setValues}
            dataObjects={{
              project: project
            }}
          // isPreviewEnable={isPreviewEnable}
          // disabled={isTicketDetailValid}
          />
        </Row>
        <Row className="justify-content-end pr-5 pb-4">

          <CustomButton
            disabled={isFetching}
            content={isFetching ? <i className="fas fa-spinner fa-spin" aria-hidden="true"></i> : 'Search'}
            permissionType={'S'}
            icon={true}
            permissionSet={userPermissions}
            onClick={searchHandler}
          />
          <CustomButton
            disabled={isFetching}
            content={'Reset'}
            type={'reset'}
            permissionType={'R'}
            permissionSet={userPermissions}
            onClick={reset}
          />

        </Row>
      </Card>

      <Row className="mt-n2">
        {isFetching ? (
          <div className="col">
            <Card className=" w-100">
              <div
                className="mx-auto text-center py-5 my-5 "
                style={{ height: '100vh' }}
              >
                <CustomLoader apiLoader={isFetching} />
              </div>
            </Card>
          </div>
        ) : (
          <>

            {serverData && <div className="col">
              <Card className="overflow-auto">
                <CardHeader className="border-0">
                  <h3 className="mb-0 floatLeft">Search Results</h3>
                </CardHeader>
                <Table className="align-items-center tableLayout">
                  <MasterHeaderAuto headerList={headerList} permissionSet={userPermissions} isSaveVisible={false} />
                  <tbody className="list" key={'table-message-template'}>
                    {tableData.map((el) => {
                      return <NewRow
                        data={{ ...el }}
                        itemToBeRenderFromMaster={itemToBeRenderFromMaster}
                        arrList={mastersList}
                        propertyList={propertyList}
                        listToBeRendered={listToRender}
                      >
                        <td className="text-center  white-breakSpace">

                          <CustomButton
                            permissionType={'R'}
                            icon={true}
                            forTable={true}
                            permissionSet={userPermissions}
                            // onClick={() => openInNewTab(`admin/role/view/${el.roleId}`)}
                            // onClick={() => { history.push(`/admin/userProfile/viewProfile/${el.employeeId}`) }}
                            onClick={()=>{window.open(`${document.URL.split('#')[0]}#/admin/role/view/${el.roleId}`,'_Blank')}}
                          />

                          <CustomButton
                            permissionType={'U'}
                            icon={true}
                            forTable={true}
                            permissionSet={userPermissions}
                            onClick={() => history.push(`/admin/role/edit/${el.roleId}`)}
                            //onClick={()=>{}}
                            // onClick={() => { history.push(`/admin/userProfile/edit/${el.employeeId}`) }}
                          />

                        </td>
                      </NewRow>

                    })}
                  </tbody>
                </Table>
                {pagination.totalPage > 1 ? (
                  <GetPagination
                    isSaveVisible={false}
                    setclassArray={setTableData}
                    pagination={pagination}
                    setpagination={setpagination}
                    pageSize={pageSize}
                    state={serverData}
                    isPaginationFromServer={isPaginationFromServer}
                    isFetching={isFetching}
                    fetchNext={fetchNext}
                  />
                ) : null}
              </Card>
            </div>}
          </>
        )}
      </Row>
    </Container>
  );
}

export default RoleSearchPage 