import { Dialog } from '@mui/material';
import React from 'react';
import { ReactComponent as CrossIcon } from 'assets/img/svg/CrossIcon.svg';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx';
import { MdArrowForwardIos } from 'react-icons/md';

const BUSelectorDialog = ({ open, setOpen, data = [], onAgree = () => {} }) => {
  const handleClose = () => setOpen(false);

  return (
    <Dialog
      // onClose={handleClose}
      open={open}
      PaperProps={{ sx: { borderRadius: '8px' } }}
    >
      <div style={{ padding: '28px 24px', width: '330px' }}>
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <div className='heading-4 label-color'>Select BU</div>
          <IconButtonWrapper onClick={handleClose}>
            <CrossIcon width={32} height={32} />
          </IconButtonWrapper>
        </div>
        <div className='d-flex flex-column gap-md'>
          {data.slice(0, 5)?.map(item => (
            <div
              className='d-flex align-items-center justify-content-between cursor'
              key={item?.businessAreaValue}
              onClick={() => onAgree(item)}
            >
              <div className='regular-body'>{item?.businessAreaValue}</div>
              <div className='semi-bold'>
                {item?.totalCount} <MdArrowForwardIos className='ml-2' />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Dialog>
  );
};

export default BUSelectorDialog;
