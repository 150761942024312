import React, { createContext, useState } from "react";
import { endpoint } from "views/pages/manage/common/constant";
import { updateRequest } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";
import { dropdownMapping } from "views/pages/manage/common/utils/methods/commonMethods/utilityMethod";


  export const getDropdowns = async () => {
    const dropdown = await Promise.all([
      dropdownMapping(endpoint.businessArea.getAllActive, "businessAreaDispValue", "id"),
      dropdownMapping(endpoint?.term?.getAllActive, "termDispValue", "id"),
    ]);
    return {
      businessArea:dropdown[0],
      term: dropdown[1],
    };
  };
export const NUMBER_REGEX=!/^[0-9]*$/

export const StudentListContext = createContext({
  currentPageState: "SEARCH_STUDENT",
  setCurrentPageState: () => { },
  globalFilter: {},
  setGlobalFilter: () => { },
});


export const FILTER__FORM_FIELDS_GLOBAL={
  studentName: '',
  psid: '',
  applicationId: '',
  opportunityId: '',
  courseId: [],
  paymentStatus: [],
  chargesStatus: [],
  term: [],
  businessArea: [],
  region: [],
  searchField:'psid',
  chargesStatus:[]
}



