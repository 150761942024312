import React from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardHeader, Input, Container, Button, Table, Row } from 'reactstrap';
import { useState } from 'react';
import Select2 from 'react-select2-wrapper';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import { TDROW, TaxCodeIdMasterHeader } from '../../common/commonComponents/masterRow/masterRow';
import { getAPI, masterServiceBaseUrl, putpost, putpost2, deleteAPI } from 'services/http';
import moment from 'moment';
import CustomButton from 'components/CustomButton';
import CustomDatePickerV2 from 'views/pages/manage/common/commonComponents/CustomDatePicker-V2';

var _ = require('lodash');

const TaxCodeIdTable = props => {
  const { userPermissions, isSaveVisible, setisSaveVisible=()=>{} } = props;
  const [tableDataKey, settableDataKey] = useState('tableDataKey');
  const [companyCode, setCompanyCode] = useState([]);
  const [stateList, setStateList] = useState([]);
  React.useEffect(() => {
    setStateList([]);
  }, []);
  const addNewClass = () => {
    props.setclassArray([
      {
        editMode: true,
        __status: '__new',
        taxCodeIdKey: null,
        status: 'ACTIVE',
        taxCodeIdDesc: null,
        taxCodeIdDispValue: null,
        effectiveDate: new Date(),
        taxCodeTypeId: null,
        groupCodeId: null,
        companyCodeId: null,
      },
      ...props.classArray,
    ]);
  };
  const saveAll = cb => {
    var _tempArray = props.classArray;
    var isValid = true;
    _tempArray.forEach((el, index) => {
    
      if (el['editMode']) {
        if (el['taxCodeIdKey'] === null || !el['taxCodeIdKey'].trim()) {
          isValid = false;
          failureToast('Please enter the value of Tax Code ID Key');
          return;
        } else if (!el['groupCodeId']) {
          isValid = false;
          failureToast('Please select the value of Business Group');
          return;
        } else if (!el['companyCodeId']) {
          isValid = false;
          failureToast('Please select the value of Comapny Code');
          return;
        } else if (!el['taxCodeTypeId']) {
          isValid = false;
          failureToast('Please select the value of Tax Code Type');
          return;
        } else if (el['taxCodeIdDesc'] === null || !el['taxCodeIdDesc'].trim()) {
          isValid = false;
          failureToast('Please enter the value of Description');
          return;
        } else if (el['taxCodeIdDispValue'] === null || !el['taxCodeIdDispValue'].trim()) {
          isValid = false;
          failureToast('Please enter Display Value');
          return;
        }
        if (!isValid) {
          return;
        }
        if (_tempArray[index]['__status'] == '__new') {
          putpost(
            masterServiceBaseUrl + '/taxCodeId/createTaxCodeId',
            data => {
              successToast(data['message']);
              setisSaveVisible(false);
              props.fetchClasses(masterServiceBaseUrl + '/taxCodeId/getAllTaxCodeId');
              setStateList([]);
            },
            data => {
              failureToast(data['message']);
            },
            {..._tempArray[index],effectiveDate: moment(el.effectiveDate).format('YYYY-MM-DD')},
            'post'
          );
        } else {
          putpost(
           
            masterServiceBaseUrl + '/taxCodeId/updateTaxCodeId/' + _tempArray[index].id,
            data => {
              successToast(data['message']);
              setisSaveVisible(false);
              props.fetchClasses(masterServiceBaseUrl + '/taxCodeId/getAllTaxCodeId');
              setStateList([]);
              // props.fetchClasses(masterServiceBaseUrl + '/class/getAllClass')
            },
            data => {
              failureToast(data['message']);
            },
            {..._tempArray[index],effectiveDate: moment(el.effectiveDate).format('YYYY-MM-DD')},
            'put'
          );
        }
      } else {
      }
    });
  };
  const newRow = () => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        addNewClass();
        setisSaveVisible(true);
        // settableDataKey(new Date().getMilliseconds())
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      props.setclassArray(_tempArray);
      setisSaveVisible(false);
      cb();
    }
  };
  const editRow = index => {
    if (isSaveVisible) {
      failureToast('Please Delete or Save current changes');
    } else {
      let cb = () => {
        var _tempArray = props.classArray;
        _tempArray[index]['editMode'] = true;
        props.setclassArray(_tempArray);
        getCompanyCode(_tempArray[index].groupCodeId);
        // getData(
        //   `${masterServiceBaseUrl}/state/getStateByCountry/${_tempArray[index]['countryId']}`,
        //   setStateList,
        //   'state'
        // );
        setisSaveVisible(true);
      };
      var _tempArray = props.classArray;
      _tempArray = _.sortBy(_tempArray, [
        function (o) {
          return o.value;
        },
      ]);
      cb();
    }
  };
  const deleteRow = index => {
    if (window.confirm('Are you sure you want to delete this Tax Code ID?')) {
      if (props.classArray[index]['__status'] == '__new') {
        let n = props.classArray;
        n.splice(index, 1);
        props.setclassArray(n);
        setisSaveVisible(false);
        settableDataKey(new Date().getMilliseconds());
      } else {
        putpost2(
          masterServiceBaseUrl + '/taxCodeId/deleteTaxCodeId/' + props.classArray[index].id,
          data => {
            successToast(data['message']);
            var _tempArray = props.classArray;
            if (_tempArray[index]['editMode']) {
              setisSaveVisible(false);
            }
            _tempArray.splice(index, 1);
            props.setclassArray(_tempArray);
            settableDataKey(new Date().getMilliseconds());
          },
          data => {
            failureToast(data['message']);
          },
          'Delete'
        );
      }
    }
  };
  const updateKey = (index, value, key) => {
    var _tempArray = props.classArray;
    _tempArray[index][key] = value;
    props.setclassArray(_tempArray);

    if (key === 'groupCodeId' && value !== null && value !== '') {
      setCompanyCode([]);
      getCompanyCode(value);
    }
  };

  const getData = (url, stateMethod, keyword) => {
    getAPI(
      url,
      data => {
        const dropList = data.data.map(item => {
          return { id: item.id, text: item[`${keyword}DispValue`] };
        });
        stateMethod(dropList);
      },
      data => {
        // failureToast(data['message']);
      }
    );
  };

  const getCompanyCode = value => {
    getAPI(
      masterServiceBaseUrl + `/companyCode/getCompanyCodeByGroup/${value}`,
      data => {
        let tempCompanyCode = [];
        data &&
          data.data.map(item => {
            tempCompanyCode.push({
              id: item.id,
              text: item.companyCodeDispValue,
            });
          });
        setCompanyCode(tempCompanyCode);
      },
      data => {
        setCompanyCode([]);
        failureToast(data['message']);
      }
    );
  };

  return (
    <>
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 floatLeft">Master - Tax Code ID</h3>
                <CustomButton
                  disabled={isSaveVisible}
                  className={'floatRight mx-1' + (isSaveVisible ? ' btn-dark cursor-gray' : null)}
                  content={'New Tax Code ID'}
                  permissionType={'C'}
                  icon={true}
                  onClick={() => {
                    newRow();
                  }}
                  permissionSet={userPermissions}
                />
              </CardHeader>
              <div id="questionTable" className="table-responsive">
                <Table className="align-items-center table-flush ">
                  <TaxCodeIdMasterHeader
                    type={'TaxCodeId'}
                    isSaveVisible={isSaveVisible}
                    permissionSet={userPermissions}
                  />
                  <tbody className="list" key={tableDataKey}>
                    {props.classArray
                      ? props.classArray.map((el, index) =>
                          !el['editMode'] ? (
                            <TDROW
                              type={'taxCodeId'}
                              index={index}
                              el={el}
                              editRow={editRow}
                              deleteRow={deleteRow}
                              taxCodeType={props.taxCodeType}
                              groupCodeList={props.grpData}
                              companyCodeData={props.companyCodeData}
                              history={props.history}
                              isSaveVisible={isSaveVisible}
                              permissionSet={userPermissions}
                            />
                          ) : (
                            <tr key={index + '-class'}>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="taxCodeIdKey"
                                  maxLength="50"
                                  defaultValue={el['taxCodeIdKey']}
                                  onChange={e => {
                                    updateKey(index, e.target.value, 'taxCodeIdKey');
                                  }}
                                  placeholder="Example:1"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Select2
                                  data-testid="groupCodeId"
                                  defaultValue={el['groupCodeId']}
                                  data={props.grpData}
                                  onChange={e => {
                                    updateKey(index, e.target.value, 'groupCodeId', true);
                                  }}
                                  options={{
                                    placeholder: 'Select Business Group',
                                  }}
                                  className="tdSelect"
                                />
                              </td>
                              {/* <td className="text-center p-2 mx-1">
                        <Select2 defaultValue={el['companyCodeId']} data={props.companyCode} onChange={(e)=>{updateKey(index,e.target.value,'companyCodeId',true)}} options={{placeholder: "Select Company Code"}} className="tdSelect" />
                      </td> */}
                              {el['__status'] && el['__status'] === '__new' ? (
                                <td className="text-center p-2 mx-1">
                                  <Select2
                                    data-testid="companyCodeId"
                                    defaultValue={el['companyCodeId']}
                                    data={companyCode}
                                    onChange={e => {
                                      updateKey(index, e.target.value, 'companyCodeId');
                                    }}
                                    options={{
                                      placeholder: 'Select Company Code',
                                    }}
                                    className="tdSelect"
                                  />
                                </td>
                              ) : companyCode.length ? (
                                <td className="text-center p-2 mx-1">
                                  <Select2
                                    data-testid="companyCodeId"
                                    defaultValue={el['companyCodeId']}
                                    data={companyCode}
                                    onChange={e => {
                                      updateKey(index, e.target.value, 'companyCodeId');
                                    }}
                                    options={{
                                      placeholder: 'Select Company Code',
                                    }}
                                    className="tdSelect"
                                  />
                                </td>
                              ) : (
                                <td className="text-center p-2 mx-1">
                                  <Select2
                                    data-testid="companyCodeId"
                                    defaultValue={{}}
                                    data={[]}
                                    options={{
                                      placeholder: 'Select Company Code',
                                    }}
                                    className="tdSelect"
                                  />
                                </td>
                              )}
                              <td className="text-center p-2 mx-1">
                                <Select2
                                  data-testid="taxCodeTypeId"
                                  defaultValue={el['taxCodeTypeId']}
                                  data={props.taxCodeType}
                                  onChange={e => {
                                    updateKey(index, e.target.value, 'taxCodeTypeId', true);
                                  }}
                                  options={{
                                    placeholder: 'Select Tax Code Type',
                                  }}
                                  className="tdSelect"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                {/* <Input
                                  data-testid="effectiveDate" 
                                  disabled={false} 
                                  defaultValue={
                                    el['__status'] == '__new' ? null : moment(el['effectiveDate']).format('DD-MM-YYYY')
                                  }
                                  onChange={e => {
                                    updateKey(index,  moment(e.target.value,"DD MM YYYY").format("DD MM YYYY hh:mm:ss"), 'effectiveDate', true);
                                  }}
                                  placeholder="Example:01-01-2020"
                                  className="tdInput"
                                /> */}
                                <CustomDatePickerV2
                                         value={el["effectiveDate"]}
                                          handler={(i, date) =>
                                            updateKey(index, date, 'effectiveDate', true)
                                          }
                                         
                                 />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="taxCodeIdDesc"
                                  maxLength="50"
                                  defaultValue={el['taxCodeIdDesc']}
                                  onChange={e => {
                                    updateKey(index, e.target.value, 'taxCodeIdDesc');
                                  }}
                                  placeholder="Description"
                                  className="tdInput"
                                />
                              </td>
                              <td className="text-center p-2 mx-1">
                                <Input
                                  data-testid="taxCodeIdDispValue"
                                  maxLength="50"
                                  defaultValue={el['taxCodeIdDispValue']}
                                  onChange={e => {
                                    updateKey(index, e.target.value, 'taxCodeIdDispValue');
                                  }}
                                  placeholder="Display Value"
                                  className="tdInput"
                                />
                              </td>
                              <td>
                                <Button
                                  disabled={isSaveVisible}
                                  color="info"
                                  size="sm"
                                  type="button"
                                  className={'' + (isSaveVisible ? ' btn-dark' : null)}
                                >
                                  Add Tax Breakup
                                </Button>
                              </td>
                              {/* <td>
                                <Button
                                  disabled={isSaveVisible}
                                  color="info"
                                  size="sm"
                                  type="button"
                                  className={'' + (isSaveVisible ? ' btn-dark' : null)}
                                >
                                  Add Item Types
                                </Button>
                              </td> */}
                              <td className="text-center p-2 mx-1">
                                <label className="custom-toggle mx-auto ml-2">
                                  <input
                                    data-testid="activeInactiveInput"
                                    checked={el.status == 'ACTIVE' ? true : false}
                                    type="checkbox"
                                    id={el.id}
                                    onChange={e => {
                                      settableDataKey(new Date().getMilliseconds());
                                      let n = 'ACTIVE';
                                      if (!e.target.checked) {
                                        n = 'INACTIVE';
                                      }
                                      updateKey(index, n, 'status');
                                    }}
                                  />
                                  <span
                                    data-testid="activeInactiveLabel"
                                    style={{ width: '72px' }}
                                    className="custom-toggle-slider rounded-circle activeToggle"
                                    data-label-off="Inactive"
                                    data-label-on="Active"
                                  />
                                </label>
                              </td>
                              <td className="text-center">
                                {/* <Button color="info" size="sm" type="button" onClick={() => {
                          deleteRow(index)
                        }}>  <i className="fas fa-trash" /></Button> */}
                                {isSaveVisible ? (
                                  <>
                                    {' '}
                                    <Button
                                      data-testid="saveBtn"
                                      color="info"
                                      size="sm"
                                      type="button"
                                      className="mx-1"
                                      onClick={() => {
                                        saveAll();
                                      }}
                                    >
                                      Save
                                    </Button>
                                    <Button
                                      data-testid="closeBtn"
                                      color="danger"
                                      size="sm"
                                      type="button"
                                      onClick={() => {
                                        let n = props.classArray;
                                        if (n[index]['__status'] == '__new') {
                                          n.splice(index, 1);
                                        } else {
                                          // n[index]['editMode']=false
                                          props.fetchClasses(masterServiceBaseUrl + '/taxCodeId/getAllTaxCodeId');
                                        }
                                        setStateList([]);
                                        props.setclassArray(n);
                                        setisSaveVisible(false);
                                      }}
                                    >
                                      {' '}
                                      <i className="fas fa-times" />
                                    </Button>{' '}
                                  </>
                                ) : (
                                  <Button
                                    color="info"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                      setStateList([]);
                                      deleteRow(index);
                                    }}
                                  >
                                    {' '}
                                    <i className="fas fa-trash" />
                                  </Button>
                                )}
                              </td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default withRouter(TaxCodeIdTable);
