
import React, { useContext, useState } from "react";
import { Row, Col, UncontrolledTooltip, Button } from 'reactstrap';
import CopyToClipboard from "react-copy-to-clipboard";
import {
    putpost,
} from "services/http";
import { PermissionContext, RolePermissions } from "appContext";
import { pages, permissionType } from "../../../../../common/constant";
import { Dialog, CustomUpload } from "../../../../../common/commonComponents";
import {
    failureToast,
} from '../../../../../common/utils/methods/toasterFunctions/function';
import { getUniqueKey } from "../../../../../common/utils/methods/commonMethods/utilityMethod";
import { endpoint, constants, commonMessages, regex } from "../../../../../common/constant";



const RfIdUpload = (props) => {


    const { description, isModalVisible = false, setModalVisible = () => { }, getAllData = () => { }, goBack = () => { }, isUploading, setISUploading = () => { }, finalData, setIsConfirmationOpen = () => { } } = props;
    const permissions = useContext(PermissionContext);
    const userPermissions = RolePermissions(permissions, pages["idCards"]['id']);
    const [isFileValid, setISFileValid] = useState(false);
    const [filename, setFileName] = useState("");
    const [file, setFile] = useState(null);
    const [progressBarStatus, setProgressBarStatus] = useState(0);

    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
    const [processIdCopied, setProcessIdCopied] = useState(false);
    const [processId, setProcessId] = useState(null);
    const dataToServer = {
        bulk: true,
        fileName: null,
        docType: "csv",
        keyValue: `enrolment_rfid_${getUniqueKey()}`,
        serviceName: 'ENROLMENT_BULK_RFID',
        sourceId: constants.sourceId
    };

    const handleFile = (e) => {
        try { 
            
            if (e.name.split(".")[1] === "csv") {
                if (e.name.length <= 100) {
                    let value = e.name.split(".")[0];

                    if (1) {
                        setFileName(value);
                        setFile(e);
                        setISFileValid(true);
                    } else {
                        failureToast("Only these special characters are allowed in file name: Space and Underscore ")
                        setFileName("");
                        setFile(null);
                        setISFileValid(false);
                    }
                } else {
                    failureToast("File name should be less than 100 characters.")
                    setFileName("");
                    setFile(null);
                    setISFileValid(false);
                }
            } else {
                failureToast("Only csv files are allowed")
                setFileName("");
                setFile(null);
                setISFileValid(false);
            }
        } catch (e) {
            console.log(e)
        }
    };

    const saveFileAndReturnData = (data) => {

        const request = {
            "courseFeesProcessingDTO": {
                "key": data.key,
                "fileName": filename,
                "status": "ACTIVE",
                "processInstanceId": Number(data.processId),
            },
            "courseFeeSupportingDocumentDtos": null,
            "isProcess": true,
            "serviceName": "ENROLMENT_BULK_RFID",
            "processId": data.processId
        }

     
        const url = constants.PRICING_FLAG_FOR_DMS ? `${endpoint.pricingUploadInfo.uploadInfo}` : `${endpoint.bulk_pricing_processing.uploadInfo}`

        putpost(
            endpoint.fetchS3Url.updateToServer,
            (res) => {
                if (res.code === 200 && res.data) {
                    setProgressBarStatus(100);
                    getAllData()
                    // setDescription('')
                    // successToast("File Uploaded Successfully")
                    setProcessId(data.processId)
                    setIsSuccessModalVisible(true);
                    props.fetchNewList()
                    setISUploading(false);

                }
            },
            (res) => {
                setISUploading(false);
                setProgressBarStatus(0);
                failureToast(res["message"]);
            },
            request,
            "post"
        );
    };

    const toggler = () => {
        setModalVisible(!isModalVisible);
        reset();
    }

    const reset = () => {
        setISFileValid(false);
        setFileName("Drag & Drop to Upload");
        setFile(null);
        setProgressBarStatus(0);
        setISUploading(false);
        setISUploading(false)
    };

    return (
        <>
            <CustomUpload
                // documentTitle="Upload Supporting Document"
                isFileValid={isFileValid}
                filename={filename}
                file={file}
                progressBarStatus={progressBarStatus}
                setISFileValid={setISFileValid}
                setFileName={setFileName}
                setFile={setFile}
                setProgressBarStatus={setProgressBarStatus}
                isUploading={isUploading}
                setISUploading={setISUploading}
                handleFile={handleFile}
                dataToServer={dataToServer}
                saveHandler={saveFileAndReturnData}
                userPermissions={userPermissions}
                goBack={() => setIsConfirmationOpen(true)}
                documentTitle={'Upload ID card template'}
                description={description}
                showCloseButton={false}
            />

            <Dialog isModalVisible={isSuccessModalVisible} toggle={() => { }} title="" size="md">
                <Row>
                    <h4 className="text-success alert mt-n4">
                        {commonMessages.pricingUploadSuccessfullMessage}
                    </h4>
                </Row>
                <Row className="flex-row">
                    <span className="text-info alert mt-n4 " style={{ width: '100%' }}>
                        {`Process ID`}
                        <CopyToClipboard
                            text={processId}
                            onCopy={() => setProcessIdCopied(processId)}
                        >
                            <button
                                className="btn-icon-clipboard"
                                data-clipboard-text={processId}
                                id="tooltip932383034"
                                type="button"
                            >
                                <div>
                                    <span>{processId}</span>
                                </div>
                            </button>
                        </CopyToClipboard>
                        <UncontrolledTooltip
                            delay={0}
                            trigger="hover focus"
                            target="tooltip932383034"
                        >
                            {processIdCopied === processId
                                ? "Copied"
                                : "Copy To Clipbord"}
                        </UncontrolledTooltip>
                    </span>
                </Row>
                <Row className="justify-content-center flex-row">
                    <Button
                        color="info"
                        size="sm"
                        type="button"
                        className="my-2"
                        onClick={() => {
                            setProcessId(null);
                            setProcessIdCopied(null);
                            setIsSuccessModalVisible(false);
                            setFileName("")
                            setFile(null)
                            setISFileValid(false)
                        }}
                    >
                        Close
                    </Button>
                </Row>
            </Dialog>

        </>
    );
};

export default RfIdUpload;