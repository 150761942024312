import React from "react";
import { Table } from 'reactstrap'
import {CustomTableRow} from "../../../../common/commonComponents/TableComponents";
import {getDispValForReactSelectByOneID} from "../../../../common/utils/methods/commonMethods/utilityMethod";
import moment from "moment";
import Loader from "react-loader-spinner";

const TableRow = (props)=>{
  const {item,businessArea,termData,programAction} = props;

  return(
      <CustomTableRow onClick={()=>window.open(`${document.URL}/${item.applicationId}/view`, '_blank')} cursor={'pointer'} >
        <td className="text-center p-2 mx-1">{item.applicationId ? item.applicationId : 'Not Found'}</td>
        <td className="text-center p-2 mx-1">{getDispValForReactSelectByOneID(item.businessArea ,'DispValue',businessArea)}</td>
        {/*<td className="text-center p-2 mx-1">{item.businessAreaId ? item.businessAreaId : 'Not Found'}</td>*/}
        <td className="text-center p-2 mx-1">{getDispValForReactSelectByOneID(item.term ,'DispValue',termData)}</td>
        <td className="text-center p-2 mx-1">{item.courseId ? item.courseId : 'Not Found'}</td>
        {/*<td className="text-center p-2 mx-1">{item.programAction }</td>*/}
        <td className="text-center p-2 mx-1">{getDispValForReactSelectByOneID(item.programAction ,'DispValue',programAction) }</td>
        <td className="text-center p-2 mx-1">{item.effectiveDate ? moment(item.effectiveDate).format('DD-MM-YYYY') : 'Not Found'}</td>
        <td className="text-center p-2 mx-1">{item.opportunityId ? item.opportunityId : 'Not Found'}</td>
      </CustomTableRow>
  )
}
const ApplicationDetails = (props) => {
  const {applicationIdData =[],businessArea,termData,programAction} = props;
  return (

      applicationIdData && applicationIdData.length>0 && businessArea.length>0 && termData.length>0 ?
    (<Table className="align-items-center table-flush ">
      <thead className="thead-light">
        <tr>
          <th className="text-center">APPLICATION ID</th>
          <th className="text-center">BUSINESS AREA</th>
          <th className="text-center">TERM</th>
          <th className="text-center">COURSE</th>
          <th className="text-center">PROGRAM ACTION</th>
          <th className="text-center">APPLICATION DATE</th>
          <th className="text-center">OPPORTUNITY ID</th>
        </tr>
      </thead>

      <tbody className="list" key={'courseCatalogSequenceSearch'}>
        {
          applicationIdData && applicationIdData.length > 0
              ?
              applicationIdData.map((item,index)=>
                <TableRow programAction={programAction} businessArea={businessArea} item={item} index={index} termData={termData}/>
              )
              :
              null
        }
      </tbody>

    </Table>)
          :
          <div
              className="mx-auto text-center py-5 my-5"
              style={{ height: "100%" }}
          >
              <Loader
                  type="Rings"
                  color="#00BFFF"
                  height={100}
                  width={100}
                  timeout={10000}
              />
          </div>
  );
};

export default ApplicationDetails;
