import React from "react";
import './index.css'
// reactstrap components
import {
  Button,
  Modal,
} from "reactstrap";

class TemplateModal extends React.Component {
  state = {
    exampleModal: false,
    templateModalMessage:'',
    id: null
  };

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.templateModal !== prevProps.templateModal) {
        this.setState({
            exampleModal:this.props.templateModal,
            templateModalMessage:this.props.templateModalMessage,
            id: this.props.testSuccessId
        });
    }
  }

  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.exampleModal}
        >
          <div className="modal-header">
            <div className='testMessage'>
                {this.props.color === 'success' &&
                    <span className="alert-inner--icon">
                        <i className="ni ni-like-2" 
                         style={this.props.color === 'success'?{color:'green'}:{color:'red'}} />
                    </span>}
                {this.props.color === 'danger' &&
                    <span className="alert-inner--icon">
                        <i className="fas fa-exclamation-triangle"  
                         style={this.props.color === 'success'?{color:'green'}:{color:'red'}}/>
                    </span>}{" "}
                <strong 
                    style={this.props.color === 'success'?{color:'green'}:{color:'red'}}>{
                    this.state.templateModalMessage}!
                </strong>
                {
                  this.state.id && 
                  <div className="justify-content-between mt-3">
                    <strong>ID : </strong>
                    <span className="test_id"> {this.state.id}</span>
                  </div>
                }
            </div>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => {this.setState({exampleModal:false});this.props.setTemplateModal(false)}}
            >
              close
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default TemplateModal;