import React, {Fragment, useEffect, useState, useContext} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Collapse, Container, Row} from "reactstrap";
import {useHistory} from "react-router-dom";
import ApplicationIdSearchForm from "./applicationIdSearchForm";
import ApplicationIdTable from "./applicationIdTabel";
import {
    masterServiceBaseUrl,
    enrollmentServiceBaseUrl,
    putpost,
    getAPI
} from "../../../../../../../services/http";
import {fetchDetails} from "../../../../common/utils/methods/commonMethods/utilityMethod";
import CustomLoader from "../../../../common/commonComponents/Loader/loader";
import {GetPagination} from "../../../../common/pagination";
import {fetchData} from "../applicationIdFunctions";
import Loader from "react-loader";
import {DarkBackground} from "../index";
import CreateButton from "../../../../../../../components/FormComponent/Button/New";
import {ButtonText} from "variables/Buttons";
import { PermissionContext, RolePermissions } from "appContext";
import CustomButton from "components/CustomButton";
import { pages } from "../../../../common/constant";
import { failureToast, successToast} from "../../../../common/utils/methods/toasterFunctions/function";


const ApplicationIdSearch =(props)=>{

    const permissions = useContext(PermissionContext);
    const userPermissions = RolePermissions(permissions, pages["applicationIDGeneration"]['id']);

    let history = useHistory();
    const {label,placeHolder,searchHeaderTitle,message,button,searchHeaderList,header} = require('../properties/index.json');
    const [isSearchData,setIsSearchData] = useState(false);
    const [searchData,setSearchData] = useState({
        studentId:'',
        studentName:'',
        sfOpportunityId:'',
        applicationId:'',
        businessArea:null,
        status:'ACTIVE'
    })
    const [businessArea,setBusinesArea] = useState([]);
    const [data,setData] = useState([])
    const [searchResult,setSearchResult] = useState([])
    const [loader,setLoader] = useState(false);
    // const [studentIds,setStudentIds] = useState([]);

    const [apiLoader, setApiLoader] = useState(true);
    const [isLoading,SetIsLoading] =useState(false);
    const [studentIdsFromApplicationTable,setStudentIdsFromApplicationTable] = useState([]);
    const [applicationIds,setApplicationIds] = useState([]);
    const [opportunityIds,setOpportunityIds] = useState([]);
    const [pagination, setpagination] = useState({
        currentPage: 1,
        nextPage: null,
        previousPage: null,
        totalPage: null
    });
    const pageSize=10;

    const fetchApplicationIds = async (url) =>{
        return new Promise((resolve,reject)=>{
            getAPI(
                url,
                (data)=>{
                    if(data.data?.length > 0){
                        let tempArray = [];
                        let tempOppID = [];
                        data.data.map(item=>{
                            tempArray.push({
                                text: item.applicationId,
                                id: item.applicationId,
                            });
                            tempOppID.push({
                                text : item.opportunityId,
                                id : item.opportunityId
                            })
                        });
                        setApplicationIds(tempArray);
                        setOpportunityIds(tempOppID);
                        resolve(true);
                    }
                },
                (data)=>{
                    failureToast(data.message);
                    resolve(true);
                }
            )
        })
    }

    const UpdateSearchData = async (key, value) => {
        try{
            if (key === 'studentId') {
                setSearchData(preState => ({
                    ...preState,
                    studentName : value.studentName,
                    [key] : value,
                    sfOpportunityId:'',
                    applicationId:'',
                    businessArea:null,
                    status:'ACTIVE'
                }));
                setLoader(true);
                let studentId = value.value;
                let url = `${enrollmentServiceBaseUrl}/studentApplication/getStudentApplicationByStudentId/${studentId}`;
                let flag = await fetchApplicationIds(url);
                if(flag){
                    setLoader(false);
                }
            } else {
                setSearchData(preState => ({...preState, [key]: value}))
            }
        }catch( e ){
            console.error('Error In Update Search Data--->',e);
        }
    }

    const searchHandler =()=>{
        if(searchData.studentId ==='' ){
            failureToast(message.failure.studentId);
        }else{
            let tempObj = {
                applicationId:searchData.applicationId,
                businessArea:searchData.businessArea,
                studentId:searchData.studentId.value,
                status:'ACTIVE',
                opportunityId:searchData.sfOpportunityId
            }
            SetIsLoading(true);
            putpost(enrollmentServiceBaseUrl+'/studentApplication/searchStudentApplication',(data)=>{
                if(data.data.length === 0){
                    failureToast(message.failure.noMatchFound);
                    setIsSearchData(false);
                }else {
                    successToast(data.message);
                    setIsSearchData(true);
                    SetIsLoading(false);
                }
                setData(data.data);
            },(data)=>{
                failureToast(message.failure.noMatchFound);
            },tempObj,"POST");
        }
    }
    const resetHandler =()=>{
        setIsSearchData(false);
        setSearchData({
            studentId:'',
            studentName:'',
            sfOpportunityId:'',
            applicationId:'',
            businessAreaId:'',
            status:'ACTIVE'
        })
        setpagination({currentPage: 1, nextPage: null, previousPage: null, totalPage: null})
    }

    useEffect(()=>{
        fetchDetails(masterServiceBaseUrl+'/businessArea/getAllActiveBusinessArea',setBusinesArea, 'businessArea', 'reactSelect');
    },[]);

    // useEffect to runs when required data is fetched for search page and make Loader false if data is fetched.
    useEffect(() => {
        if(businessArea && businessArea.length > 0 ){
            setApiLoader(false)
        }
    },[businessArea]);

    const nextPage = () => {
        setSearchResult(data.slice((pagination.currentPage + 1) * (pageSize) - pageSize, (pagination.currentPage + 1) * pageSize))
        let n = pagination
        n['currentPage'] = n['currentPage'] + 1
        n['previousPage'] = n.currentPage
        setpagination(n)
    }
    const firstPage = () => {
        setSearchResult(data.slice(1 * (pageSize) - pageSize, 1 * pageSize))
        let n = pagination
        n['currentPage'] = 1
        n['previousPage'] = null
        setpagination(n)
    }
    const lastPage = () => {
        setSearchResult(data.slice((pagination.totalPage) * (pageSize) - pageSize, (pagination.totalPage) * pageSize))
        let n = pagination
        n['currentPage'] = n.totalPage
        n['previousPage'] = n.totalPage-1
        setpagination(n)
    }

    const previousPage = () => {
        if(pagination.currentPage!=1){
            setSearchResult(data.slice((pagination.currentPage-1) * (pageSize) - pageSize, (pagination.currentPage-1) *pageSize  ))
            let n = pagination ;
            n['currentPage'] = n['currentPage'] -1 ;
            if(n['currentPage']==1){
                n['previousPage'] = null
            }else{
                n['previousPage'] = n.currentPage ;
            }
            setpagination(n)
        }
    }


    // function to update the pagination
    const updatePagination =(data)=>{
        let n = pagination
        if (data.length > pageSize) {
            n['nextPage'] = n.currentPage + 1
        }
        if (n.currentPage > 1) {
            n.previousPage = n.currentPage - 1
        }
        n['totalPage'] =Math.floor( data.length / pageSize)
        if(data.length % pageSize !== 0){
            n['totalPage']+=1
        }
        setpagination(n)
        if(data.length > 10) {
            setSearchResult(data.slice(0, 10));
        }else{
            setSearchResult(data)
        }
    }

    // useEffect runs when data is fetched from search api and is used to update the pagination
    useEffect(() => {
        if (data && data.length > 0) {
            updatePagination(data);
        }
    }, [data])

    return(
        apiLoader
            ?
            <div className="mx-auto text-center py-5 my-5" style={{ height: "100vh" }}>
                <CustomLoader apiLoader={apiLoader}/>
            </div>
            :
            <Fragment>
                <DarkBackground disappear={loader}>
                    <Loader loaded={false} lines={10} length={20} width={10} radius={25} corners={1} rotate={0} direction={1} color="#11cdef" speed={0.6} trail={60} shadow={false} hwaccel={false} className="spinner" zIndex={2e9} top="50%" left="50%" scale={0.7} loadedClassName="loadedContent"/>
                </DarkBackground>
                <Container fluid={true} className="p-4">
                    <Card>
                        <CardHeader>
                            <h2 className="mb-0 floatLeft">{searchHeaderTitle}</h2>
                            {/* <CreateButton permissions={{write:true,read:true}} className={'floatRight mx-1 '} onClick={() => history.push(`/admin/applicationIdGeneration/new`)}
                                          text={ButtonText.ApplicationIdGeneration.addNew}
                            /> */}
                            <CustomButton 
                                className={ "floatRight mx-1" }
                                content={ButtonText.ApplicationIdGeneration.addNew}
                                icon={true}
                                permissionType={'C'}
                                permissionSet={userPermissions}
                                onClick={() => history.push(`/admin/applicationIdGeneration/new`)}
                            />
                        </CardHeader>
                        <CardBody>
                            <ApplicationIdSearchForm searchData={searchData}
                                                     setData={setData}
                                                     applicationIds={applicationIds}
                                                     opportunityIds={opportunityIds}
                                                     studentIdsFromApplicationTable={studentIdsFromApplicationTable}
                                                     UpdateSearchData={UpdateSearchData}
                                                     searchHandler={searchHandler}
                                                     resetHandler={resetHandler}
                                                     businessArea={businessArea}
                                                     isLoading={isLoading}
                                                     ButtonText={ButtonText}
                                                     label={label}
                                                     placeholder={placeHolder}
                                                     permissionSet={userPermissions}
                            />
                        </CardBody>
                    </Card>
                    {
                        isSearchData
                            ?
                            <Card>
                                <ApplicationIdTable businessArea={businessArea} studentName={searchData.studentName} data={searchResult} pagination={pagination} searchHeaderList={searchHeaderList} permissionSet={userPermissions}/>
                            </Card>
                            :
                            null
                    }
                    {pagination.totalPage>1 ?
                        <GetPagination
                            pagination={pagination}
                            previousPage={previousPage}
                            firstPage={firstPage}
                            lastPage={lastPage}
                            nextPage={nextPage}
                        />
                        :null}
                </Container>
            </Fragment>
    )
}

export default ApplicationIdSearch;
