import React, { useContext, useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import styles from '../styles.module.scss'
import { ReactComponent as BackIcon } from '../../../../../../assets/img/svg/BackButton.svg'
import { useHistory, useParams } from 'react-router'
import AButton from 'views/pages/manage/common/form-fields-mui/AButton'
import PlanList from './PlanList'
import RowForm from './RowForm'
import { checkPermission } from 'views/pages/manage/common/utils/methods/commonMethods/utilityMethod'
import { RolePermissions, PermissionContext } from 'appContext'
import { pages } from 'views/pages/manage/common/constant'
import ChangesLostConfirmationDialog from './ChangesLostConfirmationDialog'

const ListComponent = ({
  plans,
  addNewRowHandler = () => { },
  editRowHandler = () => { },
  deleteRowHandler = () => { },
  createPlanHanlder = () => { },
  setCreateOpenForm = () => { },
  isRowFormOpen,
  setIsRowFormOpen = () => { },
  isNewRow,
  rowForm,
  setRowForm = () => { },
  rowFormHandler = () => { },
  saveRowHandler = () => { },
  updateRowHandler = () => { },
  subjectDropdown,
}) => {

  const userPermissions = RolePermissions(useContext(PermissionContext), pages['daysPlan']['id']);
  const history = useHistory();
  const { action, daysPlanId } = useParams();

  const [changesLostDialogOpen, setChangesLostDialogOpen] = useState(false);

  return (
    <>
      <div className={`${styles?.view_plan_list_main_container}`}>
        <Card style={{ borderRadius: 0, marginBottom: '20px' }}>
          <CardBody className={`d-flex justify-content-between align-items-center`} style={{ height: '72px', padding: '14px 20px' }}>
            <div className='d-flex align-items-center'>
              {/* <div><BackIcon onClick={() => action === 'create' ? setCreateOpenForm('CREATE') : isRowFormOpen ? setChangesLostDialogOpen(true) : history?.goBack()} /></div> */}
              {action === 'create' && <div><BackIcon onClick={() => setCreateOpenForm('CREATE')} /></div>}
              <h3 className='mb-0 heading-4' style={{ fontSize: '20px', color: "#0F0F0F" }}>Create days plan</h3>
            </div>
            <div className='d-flex align-items-center'>
              {(checkPermission(userPermissions, 'C') || checkPermission(userPermissions, 'U')) && action !== 'view' && (
                <AButton
                  variant='primary_filled'
                  onClick={() => { addNewRowHandler() }}
                >
                  Add new session
                </AButton>
              )}
              {checkPermission(userPermissions, 'C') && action === 'create' && (
                <AButton variant='primary_filled' onClick={() => createPlanHanlder()}>
                  Save plan
                </AButton>
              )}
            </div>
          </CardBody>
        </Card>
        {isRowFormOpen && (
          <RowForm
            plans={plans}
            isNewRow={isNewRow}
            setIsRowFormOpen={setIsRowFormOpen}
            rowForm={rowForm}
            setRowForm={setRowForm}
            rowFormHandler={rowFormHandler}
            saveRowHandler={saveRowHandler}
            updateRowHandler={updateRowHandler}
            subjectDropdown={subjectDropdown}
          />
        )}
        <div className={`d-flex`} style={{ flex: 1, padding: '0 20px' }}>
          <div className={`${styles?.data_grid_container} ${styles?.list_container} ${styles?.list_container_grid_remove_top_border}`}>
            <PlanList
              plans={plans}
              editRowHandler={editRowHandler}
              deleteRowHandler={deleteRowHandler}
              updateRowHandler={updateRowHandler}
            />
          </div>
        </div>
      </div>
      {/* <ChangesLostConfirmationDialog
        open={changesLostDialogOpen}
        setOpen={setChangesLostDialogOpen}
        onAgree={() => history?.goBack()}
      /> */}
    </>
  )
}

export default ListComponent