import React, { useState, useEffect } from "react";
import Select from "react-select";
import { clone } from "lodash";

function isIncludingString(string, option) {
    let result = false;
    if (
      !string ||
      option.label.toLowerCase().includes(string.toLowerCase()) ||
      option.value.toString().toLowerCase().includes(string.toLowerCase())
    ) {
      result = true;
    }
    return result;
}

const ReactSelectAll = (props) => {
    const [searchField, setSearchField] = useState(null);
    const [values, setValues] = useState([]);

    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions([{ label: "Select all", value: "all" }, ...props.options]);
    }, [props.options]);
  
    const onValueChange = (opt, { option }) => {
      let newOpts = opt;
      let string = searchField;
  
      if (option && option.value === "all") {
        let filteredOptions = clone(options);
  
        filteredOptions = filteredOptions.filter(
          (filteredOption) =>
            isIncludingString(string, filteredOption) &&
            !newOpts.includes(filteredOption)
        );
  
        string = null;
        newOpts = newOpts
          .concat(filteredOptions)
          .filter((newOpt) => newOpt.value !== "all");
      }
      setSearchField(string);
      setValues(newOpts);
      props.onChange(newOpts);
    };
    const onInputChange = (string, { action }) => {
      if (action === "input-change") {
        setSearchField(string);
      }
    };
    const filterOption = ({ label, value }, string) => {
      if (value === "all") {
        return true;
      } else if (string) {
        return (
          label.toLowerCase().includes(string.toLowerCase()) ||
          value.toString().toLowerCase().includes(string.toLowerCase())
        );
      } else {
        return true;
      }
    };
  
    return (
        <Select
          isMulti
          filterOption={filterOption}
          onInputChange={onInputChange}
          styles={props.styles}
          onChange={onValueChange}
          options={options}
          value={values}
          placeholder={props.placeholder}
        />
    );
  };

  export default ReactSelectAll;