import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'
import { endpoint, pages } from 'views/pages/manage/common/constant';
import ALoader from 'views/pages/manage/common/a-loader';
import { RolePermissions, PermissionContext } from 'appContext';
import { PermisionDenied } from 'views/pages/manage/common/commonComponents';
import { ReactComponent as BackIcon } from "assets/img/svg/BackButton.svg";
import { useHistory } from 'react-router';
import AButton from 'views/pages/manage/common/form-fields-mui/AButton.jsx'
import ListComponent from './ListComponent';
import { fetchAllPostPromisedData, fetchAllPromisedData, fetchDeletePromisedData } from '../../../../common/utils/methods/commonMethods/utilityMethod';
import { createSuccessMsg, getCreateReqBody, getDropdowns, restructureGetAllRes } from './constant';
import { failureToast, successToast } from '../../../../common/utils/methods/toasterFunctions/function';
import IconButtonWrapper from 'views/pages/manage/common/form-fields-mui/IconButtonWrapper.jsx'

const MiscCourseMaterialMapping = () => {

  const history = useHistory();
  const userPermissions = RolePermissions(useContext(PermissionContext), pages['miscCourseMaterialMapping']['id']);
  const listRef = useRef();
  const [page, setPage] = useState(0);
  const onPageChange = newPage => setPage(newPage);
  const [rows, setRows] = useState([]);
  const [listLoader, setListLoader] = useState(false);
  const [dropdown, setDropdown] = useState({});
  const [dropdownLoader, setDropdownLoader] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  
  const init = async () => {
    setDropdownLoader(true);
    const dropdownRes = await getDropdowns();
    setDropdown(dropdownRes);
    setDropdownLoader(false);
    fetchList(dropdownRes);
  }

  const fetchList = async (dropdownData = dropdown) => {
    setListLoader(true);
    const res = await fetchAllPromisedData(endpoint.miscCourseMaterialMapping.getAll, true);
    if (res?.code === 200) {
      const dataRows = restructureGetAllRes(res?.data, dropdownData);
      setRows(dataRows);
      listRef.current.stopEditing();
    } else {
      setRows([]);
    }
    setListLoader(false);
  }

  const createHandler = async (rowData) => {
    setApiLoader(true);
    const reqBody = getCreateReqBody(rowData);
    const res = await fetchAllPostPromisedData(endpoint.miscCourseMaterialMapping.create, reqBody, 'post');
    if (res?.code === 200) {
      successToast(createSuccessMsg);
      fetchList();
      setPage(0);
    } else {
      // failureToast(res?.message);
    }
    setApiLoader(false);
  }

  const updateHandler = async (rowData) => {
    setApiLoader(true);
    const reqBody = getCreateReqBody(rowData);
    const res = await fetchAllPostPromisedData(`${endpoint.miscCourseMaterialMapping.update}/${rowData?.id}`, reqBody, 'put');
    if (res?.code === 200) { 
      successToast(res?.message);
      fetchList();
    } else {
      // failureToast(res?.message);
    }
    setApiLoader(false);
  }
  const toggleStatusHandler = async (rowData) => {
    setApiLoader(true);
    const reqBody = { ...getCreateReqBody(rowData), status: rowData?.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' };
    const res = await fetchAllPostPromisedData(`${endpoint.miscCourseMaterialMapping.update}/${rowData?.id}`, reqBody, 'put');
    if (res?.code === 200) {
      successToast(res?.message);
      fetchList();
    } else {
      // failureToast(res?.message);
    }
    setApiLoader(false);
  }

  const deleteHandler = async (rowData) => {
    setApiLoader(true);
    const res = await fetchDeletePromisedData(`${endpoint.miscCourseMaterialMapping.delete}/${rowData?.id}`);
    if(res?.code === 200) {
      successToast(res?.message);
      fetchList();
    } else {
      failureToast(res?.message);
    }
    setApiLoader(false);
  }

  useEffect(() => {
    init();
  }, [])

  return (
    userPermissions ? (
      <div className={`remove-container-top-space`}>
        {(dropdownLoader || listLoader || apiLoader) && <ALoader position='fixed' />}
        <div className={`${styles['header-bar']}`}>
          <IconButtonWrapper onClick={() => history.goBack()}><BackIcon /></IconButtonWrapper>
          <div className='ml-3 heading-4 color-dark'>
            Misc. course ID item mapping
          </div>
        </div>
        <div className={`${styles['page-container']}`}>
          <div className='d-flex flex-row-reverse'>
            <AButton
              variant='outline'
              size='xs'
              onClick={() => listRef?.current?.addNewRow()}
            >
              Add new
            </AButton>
          </div>
          <ListComponent
            ref={listRef}
            page={page}
            onPageChange={onPageChange}
            rows={rows}
            loader={dropdownLoader || listLoader}
            dropdown={dropdown}
            createHandler={createHandler}
            updateHandler={updateHandler}
            toggleStatusHandler={toggleStatusHandler}
            deleteHandler={deleteHandler}
          />
        </div>
      </div>
    ) : (
      <PermisionDenied />
    )
  )
}

export default MiscCourseMaterialMapping