import React,{useState,useEffect} from 'react';
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  Input,
  Button,
  Label,
  FormGroup,
    Form,
} from 'reactstrap';
import moment from 'moment';

const UserDetails = ( props ) => {

    const { item } = props;
    const classStartDate = new Date( moment( item[ 'startDate' ] + ' ' + item[ 'startTime' ], 'DD-MM-YYYY HH:mm:ss' ) );
    const classEndDate = new Date( moment( item[ 'endDate' ] + ' ' + item[ 'endTime' ], 'DD-MM-YYYY HH:mm:ss' ) );

    let attendanceDuration;
    let hours;
    let mins;
    let sec;

    const attendanceArr = item[ 'attendanceDuration' ].split( ' ' );

    if (attendanceArr.length === 3) {       
        hours = attendanceArr[ 0 ].toUpperCase().split( 'H' )[ 0 ].length > 1 ?
            attendanceArr[ 0 ].toUpperCase().split( 'H' )[ 0 ] :
            '0' + attendanceArr[ 0 ].toUpperCase().split( 'H' )[ 0 ];
        mins = attendanceArr[ 1 ].toUpperCase().split( 'M' )[ 0 ].length > 1 ?
            attendanceArr[ 1 ].toUpperCase().split( 'M' )[ 0 ] :
            '0' + attendanceArr[ 1 ].toUpperCase().split( 'M' )[ 0 ];
        sec = attendanceArr[ 2 ].toUpperCase().split( 'S' )[ 0 ].length > 1 ?
            attendanceArr[ 2 ].toUpperCase().split( 'S' )[ 0 ] : '0' +
            attendanceArr[ 2 ].toUpperCase().split( 'S' )[ 0 ];
        
        attendanceDuration =  hours + ':' + mins + ':' + sec;

    } else if (attendanceArr.length === 2) {
        mins = attendanceArr[ 0 ].toUpperCase().split( 'M' )[ 0 ].length > 1 ?
            attendanceArr[ 0 ].toUpperCase().split( 'M' )[ 0 ] :
            '0' + attendanceArr[ 0 ].toUpperCase().split( 'M' )[ 0 ];
        sec = attendanceArr[ 1 ].toUpperCase().split( 'S' )[ 0 ].length > 1 ?
            attendanceArr[ 1 ].toUpperCase().split( 'S' )[ 0 ] : '0' +
            attendanceArr[ 1 ].toUpperCase().split( 'S' )[ 0 ];
        
        attendanceDuration ='00' + ':' + mins + ':' + sec;

    } else {
       
        sec = attendanceArr[ 0 ].toUpperCase().split( 'S' )[ 0 ].length > 1 ?
            attendanceArr[ 0 ].toUpperCase().split( 'S' )[ 0 ] : '0' +
            attendanceArr[ 0 ].toUpperCase().split( 'S' )[ 0 ];
        
        attendanceDuration ='00' + ':' + '00' + ':' + sec;

    }
    
    const getBorderColor = () => {
        if ( moment( classStartDate ).isSameOrBefore( moment() ) ) {
            if ( item[ 'present' ] ) {
                return 'green';
            } else {
                return 'red';
            }
        } else {
           return '#00d0f1';
        }
    }


    return (
        <Card className='overflow-hidden p-3 card-border-left'>
            <div data-testid='student_calendar_top' className='student_calendar_top' style={{backgroundColor:`${getBorderColor()}`}}></div>
            <div data-testid='student_calendar_left' className='student_calendar_left' style={ { backgroundColor: `${ getBorderColor() }`}}></div>
            <div data-testid='student_calendar_bottom' className='student_calendar_bottom' style={{backgroundColor:`${ getBorderColor() }`}}></div>
            <Row>
                 <Col sm='12' md='6'  className='p-2'>
                    <Row>                        
                        <Col  xs='6'>
                            <Label className="form-control-label">{'Attendance Status'}</Label>
                        </Col>
                        <Col  xs='6'>
                            <span  className='data-font-size'>
                                {
                                  moment(classStartDate).isSameOrBefore(moment())?
                                    item[ 'present' ] ?
                                        <span
                                        data-testid={'present'}
                                        style={ {
                                            color: 'green',
                                            fontWeight: 'bolder'
                                        } }>
                                        <i class="fas fa-check-circle" ></i>
                                        {' Present'}
                                        </span>
                                        : 
                                        <span
                                        data-testid={'absent'}
                                            style={ {
                                                color: 'red',
                                                fontWeight: 'bolder'
                                            } }>
                                            <i class="far fa-times-circle" ></i>
                                            {' Absent'}
                                        </span>:
                                        <span
                                        data-testid={'notYetStarted'}
                                            style={ {
                                                color: '#888888',
                                                fontWeight: 'bolder'
                                            } }>                                            
                                            {'Not Started Yet'}
                                        </span>
                                }
                            </span>
                        </Col>                        
                    </Row>                
                </Col>
                <Col sm='12' md='6' className='p-2'>
                    <Row>                        
                        <Col xs='6'>
                            <Label className="form-control-label" data-testid={'attendanceDurationLabel'}>{'Attendance Duration'}</Label>
                        </Col>
                        <Col  xs='6'>
                            <span  className='data-font-size' data-testid={'attendanceDuration'}>{ attendanceDuration}</span>
                        </Col>                        
                    </Row>
                </Col>               
            </Row>
            <Row>
                <Col sm='12' md='6' className='p-2'>
                    <Row>                        
                        <Col xs='6'>
                            <Label className="form-control-label">{'Class Start Date'}</Label>
                        </Col>
                        <Col  xs='6'>
                            <span  className='data-font-size' data-testid='classStartDate'>{ item['startDate'] }</span>
                        </Col>                        
                    </Row>
                </Col>
                <Col sm='12' md='6'  className='p-2'>
                    <Row>                        
                        <Col  xs='6'>
                            <Label className="form-control-label">{'Class End Date'}</Label>
                        </Col>
                        <Col  xs='6'>
                            <span  className='data-font-size' data-testid={'classEndDate'}>{ item['endDate'] }</span>
                        </Col>                        
                    </Row>                
                </Col>
            </Row>
            <Row>
                <Col sm='12' md='6' className='p-2'>
                    <Row>                        
                        <Col xs='6'>
                            <Label className="form-control-label">{'Class Time'}</Label>
                        </Col>
                        <Col  xs='6'>
                            <span  className='data-font-size' data-testid={'classTime'}>{ moment(classStartDate).format('hh:mm A') + ' - ' +  moment(classEndDate).format('hh:mm A')}</span>
                        </Col>                        
                    </Row>
                </Col>
                <Col sm='12' md='6' className='p-2'>
                    <Row>                        
                        <Col  xs='6'>
                            <Label className="form-control-label">{'Room Number'}</Label>
                        </Col>
                        <Col  xs='6'>
                            <span  className='data-font-size' data-testid={'roomNumber'}>{ item['RoomNumber']}</span>
                        </Col>                        
                    </Row>                
                </Col>
              
            </Row>
            <Row>
                <Col  sm='12' md='6' className='p-2'>
                    <Row>                        
                        <Col xs='6'>
                            <Label className="form-control-label">{'Subject'}</Label>
                        </Col>
                        <Col xs='6'>
                            <span  className='data-font-size' data-testid={'subject'}>{ item['subject']}</span>
                        </Col>                        
                    </Row>
                </Col>
                <Col sm='12' md='6' className='p-2'>
                    <Row>                        
                        <Col xs='6'>
                            <Label className="form-control-label">{'Teacher Code & Name'}</Label>
                        </Col>
                        <Col xs='6'>
                            <span  className='data-font-size' data-testid='teacherCodeAndName'>{ item['teacherName']}</span>
                        </Col>                        
                    </Row>                
                </Col>
            </Row>
            <Row>
                <Col sm='12' md='6' className='p-2'>
                <Row>                        
                    <Col xs='6'>
                        <Label className="form-control-label">{'Course ID'}</Label>
                    </Col>
                    <Col  xs='6'>
                        <span  className='data-font-size'  data-testid={'courseId'}>{ item['courseId'] }</span>
                    </Col>                        
                </Row>
                </Col>
                <Col sm='12' md='6' className='p-2'>
                    <Row>                        
                        <Col xs='6'>
                            <Label className="form-control-label">{'Class Section'}</Label>
                        </Col>
                        <Col xs='6'>
                            <span  className='data-font-size' data-testid="classSection">{ item['ClassSection'] }</span>
                        </Col>                        
                    </Row>
                </Col>               
            </Row>                         
            <Row>
                { item[ 'RoomNumber'].toUpperCase() === 'MS TEAMS'?
                    <Col xs='12' className='text-right'>
                    <a href="https://www.google.com/" target='_blank' style={ { fontSize: '30px', color:'#3498db'}} data-testid='joinClassIcon'><i class="fas fa-camera"></i></a>
                </Col>:null}
            </Row>      
        </Card>
    )
}

export default UserDetails;