import React, { useState, useEffect } from "react";
import { Card, Table, CardHeader, Button } from "reactstrap";
import { MasterHeaderAuto } from "../../../../common/commonComponents/masterHeader/masterHeader";
import { NewRow, openInNewTab } from "../../../../common/utils/methods/commonMethods/utilityMethod";
import { GetPagination } from "../../../../common/commonComponents/pagination/pagination";
import CustomLoader from '../../../../common/commonComponents/Loader/loader'
import CustomButton from "components/CustomButton";

const FeeTable = (props) => {
  const {
    data = [],
    companyCode = [],
    academicCareer = [],
    term = [],
    pricingZone = [],
    CourseData = [],
    approvalRole,
    isManual = false,
    userPermissions,
    fetchNext = () => { },
    totalNumberOfRecords,
    state,
    setpagination,
    tableData,
    setTableData,
    pagination,
    pageSize,
    isFetching,
    serverTotalPage,
    serverCurrentPage,
    isNextCallPossible
  } = props;

  const headerList =
    approvalRole === "L1"
      ? [
        { name: "Company Code" },
        { name: "Transaction ID" },
        { name: "Pricing Zone" },
        { name: "Term" },
        { name: "Course ID" },
        { name: "Career" },
        { name: "Total Number of years" },
        { name: "Status" },
        { name: "L1 Approved By"},
        { name: "Last updated date" },
        { name: "Last Updated by" },
        // { name: "Action" },
      ]
      : approvalRole === "L2"
        ? [
          { name: "Company Code" },
          { name: "Transaction ID"},
          { name: "Pricing Zone" },
          { name: "Term" },
          { name: "Course ID" },
          { name: "Career" },
          { name: "Total Number of years" },
          { name: "Status" },
          { name: "Submitted By" },
          { name: "Submission Date" },
          { name: "L1 Approved By" },
          { name: "L1 Approval Date" },
          { name: "L2 Approved By"},
          { name: "L2 Approval Date"},
          // { name: "Last updated date" },
          // { name: "Last Updated by" },
          // { name: "Action" },
        ]
        : [
          { name: "Company Code" },
          { name: "Transaction ID" },
          { name: "Pricing Zone" },
          { name: "Term" },
          { name: "Course ID" },
          { name: "Career" },
          { name: "Total Number of years" },
          { name: "Status" },
          { name: "L1 Remarks"},
          { name: "L2 Remarks"},
          { name: "Last updated date" },
          { name: "Last Updated by" },
          // { name: "Action" },
        ];

  const listToRender =
    approvalRole === "L1"
      ? [
        "companyCode",
        "transactionId",
        "pricingZone",
        "academicTerm",
        "courseId",
        "academicCareer",
        "totalNumberOfYears",
        "courseFeeStatus",
        "l1ActionBy",
        "updatedOn",
        "updatedBy",
      ]
      : approvalRole === "L2"
        ? [
          "companyCode",
          "transactionId",
          "pricingZone",
          "academicTerm",
          "courseId",
          "academicCareer",
          "totalNumberOfYears",
          "courseFeeStatus",
          "createdBy",
          "createdOn",
          "l1ActionBy",
          "l1StatusDate",
          "l2ActionBy",
          "l2StatusDate",
          // "updatedOn",
          // "updatedBy",
        ]
        : [
          "companyCode",
          "transactionId",
          "pricingZone",
          "academicTerm",
          "courseId",
          "academicCareer",
          "totalNumberOfYears",
          "courseFeeStatus",
          "l1Remarks",
          "l2Remarks",
          "updatedOn",
          "updatedBy",
        ];

  const itemToBeRenderFromMaster = [
    "companyCode",
    "pricingZone",
    "academicTerm",
    "academicCareer",
  ];
  const mastersList = [companyCode, [], pricingZone, term, [], academicCareer];
  const propertyList = [
    "companyCodeDispValue",
    "",
    "pricingZoneName",
    "termDispValue",
    "",
    "academicCareerDispValue",
  ];

  // useEffect(() => {
  //   if (data) {
  //     setPagination(data);
  //   }
  // }, [data]);

  isManual && headerList.push({ name: "Action" })

  return (
    <>
      <div id="questionTable">
        <Table className="align-items-center table-flush tableLayout feetable" style={{minWidth: '1400px'}}>
          <MasterHeaderAuto headerList={headerList} />
          <tbody className="list">
            {tableData && tableData.length ? (
              tableData.map((el, index) => {
                return (
                  <NewRow
                    data={{ ...el, sno: index + 1 }}
                    itemToBeRenderFromMaster={itemToBeRenderFromMaster}
                    arrList={mastersList}
                    propertyList={propertyList}
                    listToBeRendered={listToRender}
                  >
                    {isManual && (
                      <td>
                        {
                          <Button
                            color="info"
                            size="sm"
                            type="button"
                            onClick={() =>
                              window.open(
                                `/#/admin/courseFeeInformation/view/${el.id}`,
                                '_blank'
                              )
                            }
                          >
                            <i className="fas fa-eye" />
                          </Button>
                        }
                      </td>
                    )}
                  </NewRow>
                );
              })
            ) : data === null ? (
              <tr className="mx-auto text-center py-5 my-5">
                <td rowSpan={10} colSpan={10}>
                  <CustomLoader apiLoader={data === null} />
                </td>
              </tr>
            ) : (
              <tr className="text-center  white-breakSpace pt-5" key="no_data">
                <td rowSpan={10} colSpan={3}>
                  <span>No Records Found</span>
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {state && state.length > 10 ? (
          <GetPagination
            isSaveVisible={false}
            setclassArray={setTableData}
            pagination={pagination}
            setpagination={setpagination}
            pageSize={pageSize}
            state={state}
            isPaginationFromServer={true}
            fetchNext={fetchNext}
            isFetching={isFetching}
            currentPage={tableData}
            totalNumberOfRecords={totalNumberOfRecords}
            serverCurrentPage={serverCurrentPage}
            serverTotalPage={serverTotalPage}
            isNextCallPossible={isNextCallPossible}
          />
        ) : null}
      </div>
    </>
  );
};
export default FeeTable;
