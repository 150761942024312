import moment from "moment"
import { endpoint } from "../../common/constant"
import { fetchAllPromisedData } from "../../common/utils/methods/commonMethods/utilityMethod"

export const ALLOCATE_STUDENTS_TABLE_HEADERS = {
  PSID: "PSID",
  APPLICATION_NO: "Application No.",
  STUDENT_NAME: "Student Name",
  REGISTRATION_DATE: "Registration Date",
  TERM: "Term",
  COURSE: "Course",
  CLASS: "Class",
  PROGRAM_ACTION: "Program Action",
}

export const viewStudentsButtonStyles = {
  background: "#00B0F5",
  color: "#fff",
  outline: 'none',
  border: 'none',
  borderRadius: '12px',
  fontSize: '14px'
}

export const getFilterPayload = (id) => {
  return {
    // studentName: "",
    // psid: "",
    // applicationId: "",
    // programAction: [], not needed as now filterEligibleProgramAction flag is sent in request
    courseId: [],
    businessArea: [],
    startDate: "",
    endDate: "",
    term: [],
    batchId: id,
    filterEligibleProgramAction: false
  }

}
export const prePopulatedFormField = (businessArea, courseId, term) => {
  return {
    courseId: courseId ? courseId : [],
    businessArea: businessArea ? businessArea : [],
    term: term ? term : []
  }
}



export const dropdownMapping = async (url, displayLabel, idLabel) => {
  const responseData = await fetchAllPromisedData(url, false);
  if (displayLabel==='courseIds'){
    const result = responseData.map((o) => {
      return {label: o, value: o}
    }) 
    return result;
  }
  if (Array.isArray(responseData)) {
    const result = responseData?.map((o) => {
      return { label: o[displayLabel], value: o[idLabel] }
    })
    return result;
  }
  else return [];
}

export const fetchAllDropdowns = async () => {
  const p = await Promise.all([
    dropdownMapping(endpoint.businessArea.getAllActive, 'businessAreaDispValue', 'id'),
    dropdownMapping(endpoint.businessArea.getAll, 'businessAreaDispValue', 'id'),
    dropdownMapping(endpoint.term.getAllActive, 'termDispValue', 'id'),
    dropdownMapping(endpoint.term.getAllTerm, 'termDispValue', 'id'),
    dropdownMapping(endpoint.academicPhase.getAll, 'academicPhaseDisplayValue', 'id'),
    dropdownMapping(endpoint.courseCatelogMapping.getAllActiveCourseIds, 'courseIds')
  ])

  return {
    businessArea: {
      allActive: p[0],
      all: p[1],
    },
    term: {
      allActive: p[2],
      all: p[3],
    },
    academicPhase: {
      all: p[4]
    },
    courseId: {
      allActive: p[5]
    }
  }
}

const formInfo = {
  SECONDARY: {
    // psid: 'string',
    // applicationId: 'string',
    businessArea: 'Array',
    term: 'Array',
    courseId: 'Array',
    startDate: 'string',
    endDate: 'string'
  },
  PRIMARY: {
    // psid: 'string',
    // applicationId: 'string',
    programAction: 'Array',
    startDate: 'string',
    endDate: 'string'
  }
}

export const getFilterCount = (dataSet, batchType) => {
  let cnt = 0;
  for (let item in dataSet) {
    if (formInfo[batchType][item] === 'string') {
      if (dataSet[item]) cnt++;
    }
    if (formInfo[batchType][item] === 'Array') {
      if (dataSet[item].length) cnt++;
    }
  }
  // console.log(cnt);
  return cnt;
}

export const getLabels = (dropdownArray, idArray, isMulti = false) => {
  let result = [];
  if (isMulti) {
    result = idArray && idArray.map((id) => dropdownArray?.find((el) => el.value == id)?.label)
  }
  else result = dropdownArray?.find((el) => el.value == idArray)?.label;
  return result;
}

export const ALLOCATE_STUDENT_LIST_CSV_HEADERS = [
  { label: 'PSID', key: 'psid' },
  { label: 'Application No.', key: 'applicationId' },
  { label: 'Student Name', key: 'studentName' },
  { label: 'Registration Date', key: 'registrationDate' },
  { label: 'Course', key: 'courseId' },
  { label: 'Class', key: 'classes' },
  { label: 'Program Action', key: 'programAction' }
]

export const getBatchCreationTimeDiff = (createdOn) => {
  return moment().diff(moment(createdOn), 'minutes');
}
export const allocationNotAllowedTooltip = 'Students can be allocated in this batch 5 minutes after the batch creation'