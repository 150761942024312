import React from 'react'


const chipStyles = {
  background: 'rgba(50, 147, 111, 0.16)',
  borderRadius: '8px',
  padding: "4px 11px",
  fontSize: '12px',
  fontWeight: '400',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const CustomChip = ({ bgColor, textColor, text, style }) => {
  return (
    <div style={{
      ...chipStyles,
      color: textColor,
      background: bgColor,
      ...style
    }}>
      {text}
    </div>
  )
}

export default CustomChip