import React, { useState, useEffect,useMemo } from "react";
import CourseDetailsTable from './courseDetailsTable';
import { getAPI, putpost, masterServiceBaseUrl, successToast, failureToast } from 'services/http';
import { GetPagination } from '../../../common/pagination';
const CourseDetails = (props) =>{
  const [classArray, setclassArray] = useState([])
  const [apiloader, setapiloader] = useState(false)
  const [ state, setstate ] = useState( [] )
  const [pagination, setpagination] = useState({
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPage: null
  } )
  
  const pageSize = 10
  const performPagination = () => {
    setapiloader(true)
    let n = pagination;     
      n['totalPage'] = Math.floor(state.length / pageSize)
      if (state.length % pageSize != 0){
        n['totalPage'] += 1
      }
      setpagination(n)
      setapiloader( false );
      setclassArray(state.slice(pagination.currentPage * (pageSize) - pageSize, pagination.currentPage * pageSize) || [])
  }
  
  const nextPage = () => {
    setclassArray(state.slice((pagination.currentPage + 1) * (pageSize) - pageSize, (pagination.currentPage + 1) * pageSize))
    let n = pagination
    n['currentPage'] = n['currentPage'] + 1
    n['previousPage'] = n.currentPage
    setpagination(n)
  }
  const firstPage = () =>{
    setclassArray(state.slice(1 * (pageSize) - pageSize, 1 * pageSize))
    let n = pagination
    n['currentPage'] = 1
    n['previousPage'] = null
    setpagination(n)
  }
  const lastPage = () => {
    setclassArray(state.slice((pagination.totalPage) * (pageSize) - pageSize, (pagination.totalPage) * pageSize))
    let n = pagination
    n['currentPage'] = n.totalPage
    n['previousPage'] = n.totalPage-1
    setpagination(n)
  }
  const previousPage = () => {
    if(pagination.currentPage!=1){
    setclassArray(state.slice((pagination.currentPage - 1) * (pageSize) - pageSize, (pagination.currentPage - 1) * pageSize))
    let n = pagination
    n['currentPage'] = n['currentPage'] - 1
    if (n['currentPage'] == 1) {
      n['previousPage'] = null
    } else {
      n['previousPage'] = n.currentPage
    }
    setpagination(n)
  } }
    
  const getCourseDetails = (id) => {
    getAPI(  masterServiceBaseUrl +`/courseCatalogSequenceDetail/getCourseCatalogSequenceDetailByCourseCatalogSetupId/${id}`, ( data ) => {        
    data && data.data && setstate( data.data );        
    }, (data) => { failureToast(data['message']) })
  }
    useEffect( () =>{
       performPagination();        
    }, [ state ] ) 
  
  return (
    <React.Fragment>
      <CourseDetailsTable state={ state } firstPage={ firstPage } setstate={ setstate } setclassArray={ setclassArray } classArray={ classArray } pagination={ pagination } performPagination={ performPagination } getCourseDetails={ getCourseDetails} courseId={ props.courseId}/>
      {pagination.totalPage > 1 ? <GetPagination  pagination={pagination} previousPage={previousPage} firstPage={firstPage} lastPage={lastPage} nextPage={nextPage} setpagination={setpagination} /> : null}
    </React.Fragment>
  );
}

export default CourseDetails;