import React from 'react'
import styles from '../common/facultySchedule.module.scss'
import CustomChip from 'views/pages/manage/common/customChip/CustomChip.jsx'
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { useHistory } from 'react-router';
import CustomCopyToClipboard from 'views/pages/manage/common/ACopyToClipboard/CustomCopyToClipboard'

const statusChipStyles = { fontSize: '12px', width: '85px', height: '24px' };
const ScheduleContentRow = ({ rowData }) => {

  const history = useHistory();

  return (
    <div className={`${styles?.schedule_row}`}>
      <div>
        {rowData?.status === 'BLOCKED'
          ? <CustomChip text='Blocked' textColor='#262626' bgColor='#E2E2E2' style={statusChipStyles} />
          : <CustomChip text='Available' textColor='#FFFFFF' bgColor='#07794C' style={statusChipStyles} />
        }
      </div>
      <div>
        <Tooltip title={`${moment(rowData?.startTime).format('hh:mm a')} - ${moment(rowData?.endTime).format('hh:mm a')}`}>
          <span>{`${moment(rowData?.startTime).format('hh:mm a')} - ${moment(rowData?.endTime).format('hh:mm a')}`}</span>
        </Tooltip>
      </div>
      <div className='d-flex'>
        <Tooltip title={rowData?.eventName}>
          <div className='ellipsis-text-container'>
            {rowData?.eventName || 'NA'}
          </div>
        </Tooltip>
        {rowData?.test && <CustomCopyToClipboard textToCopy={rowData?.eventName?.split('| ')?.[1]} />}
      </div>
      <div>
        {rowData?.businessArea || 'NA'}
      </div>
      <div>
        <Tooltip title={rowData?.batchName}>
          <span>{rowData?.batchName || 'NA'}</span>
        </Tooltip>
      </div>
      <div className={`tiny-semi-bold`}>
        {rowData?.batchId ? (
          <Tooltip title='View in Batch Timetable'>
            <span
              onClick={() => rowData?.batchId && window.open(history.createHref({ pathname: `admin/testScheduling/timetable/view/${rowData?.batchId}` }))}
              className={`${rowData?.batchId ? 'cursor color-primary' : 'cursor-not-allowed color-grey-50'}`}
            >
              View in Batch Timetable
            </span>
          </Tooltip>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default ScheduleContentRow