import React, { useEffect, useState } from 'react'
import { Container, Row } from 'reactstrap'
import { endpoint } from '../../common/constant';
import { fetchAllPromisedData } from '../../common/utils/methods/commonMethods/utilityMethod';
import TemplateMappingHeader from './templateMappingHeader';
import TemplateListing from './templateList';
import CustomButton from '../../../../../components/CustomButton';
import { putpost } from 'services/http';
import { failureToast, successToast } from '../../common/utils/methods/toasterFunctions/function';
import TemplateListingView from './viewList';

const emplySearchParams = {
  templateId: '',
  templateName: '',
  channel: '',
  templateCategoryId: ''
}

const TemplateMapping = () => {
  const [values, setValues] = useState({
    project: '',
    roleId: '',
    destinationType: ''
  });

  const [projectData, setProjectData] = useState({ fetching: true, data: [] })

  const [selectedIds, setSelectedIds] = useState([]);
  const [isAllSelected, setAllSelected] = useState(false);
  const [deselectedIds, setDeselectedIs] = useState([]);
  const [isSaving, setSaving] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [disableHeader, setDisableHeader] = useState(false);
  const [searchParams, setSearchParms] = useState(emplySearchParams);
  const [havePreviousRecord, setHavePreviousRecord] = useState({completed: false, haveData : false});
  const [destinationTypeData,setDestinationTypeData] = useState({ fetching: true, data: [] })


  const getProject = async () => {
    const project = await fetchAllPromisedData(endpoint.projectDetails.getAllActiveProject, false);
    if (Array.isArray(project)) {
      const tempData = project?.map(p => { return { label: p.projectDispValue, value: p.id } })
      setProjectData({ fetching: false, data: tempData })
    } else {
      setProjectData({ fetching: false, data: [] })
    }
  }

  const getDestinationType = async() => {
    const destinationType = await fetchAllPromisedData(endpoint.destinationType.getAll, false);
    if (Array.isArray(destinationType)) {
      const tempData = destinationType?.map(p => { return { label: p.notiDestType, value: p.id } })
      setDestinationTypeData({ fetching: false, data: tempData })
    } else {
      setDestinationTypeData({ fetching: false, data: [] })
    }
  }


  const validateProjectAndRole = async () => {
    setFetching(true)
    const templates = await fetchAllPromisedData(`${endpoint.getTemplatesByRole}/${values.roleId?.value}`, true);
    if (templates?.code === 200) {
      if (templates.data?.[0]?.templateId === 'ALL') {
        setAllSelected(true)
      } else {
        const tempArr = templates.data.map(item => Number(item.templateId));
        setSelectedIds(tempArr)
      }
      setFetching(true);
      setDisableHeader(true);
    } else {
      setFetching(true)
    }
  }

  const reset = () => {
    setValues({
      project: '',
      roleId: ''
    })
    setSelectedIds([]);
    setAllSelected(false);
    setDeselectedIs([]);
    setSaving(false);
    setFetching(false);
    setDisableHeader(false);
    setHavePreviousRecord({completed: false, haveData : false})
    setDisableHeader(false)
  }


  const handleMapping = () => {

    if (!isAllSelected && !selectedIds?.length) {
      failureToast("Please select atleast one template.")
      return
    }

    const request = {
      "roleId": values?.roleId?.value,
      // "": values?.
      "selectedTemplates": selectedIds,
      "deselectedTemplates": deselectedIds,
      "searchFilter": {...searchParams,channel: searchParams.channel?.value ??'', templateCategoryId: searchParams.templateCategoryId?.value??''},
      "isAllSelected": isAllSelected
    }

    putpost(
      endpoint.updateMapping,
      (data) => {
        if (data?.code == 200) {
          successToast(data.message);
          reset();
        }
      },
      (data) => {
        failureToast(data['message']);
      },
      request,
      'post'
    );
  }

  // useEffect(()=>{
  //   if(values.roleId?.value){
  //     validateProjectAndRole();
  //   }
  // },[values.roleId])


  useEffect(() => {
    getProject();
    getDestinationType();
  }, [])
  return (
    <>
      <TemplateMappingHeader
        values={values}
        setValues={setValues}
        projectData={projectData}
        disableHeader={disableHeader}
        fetching={fetching}
        validateProjectAndRole={validateProjectAndRole}
        havePreviousRecord={havePreviousRecord}
        destinationTypeData={destinationTypeData}
        setHavePreviousRecord={setHavePreviousRecord}
      />
      {!disableHeader && values.roleId?.value && <TemplateListingView
        roleId={values.roleId?.value}
        searchParams={searchParams}
        setSearchParms={setSearchParms}
        setHavePreviousRecord={setHavePreviousRecord}
        havePreviousRecord={havePreviousRecord}
       
      />}

      {disableHeader && <> <TemplateListing
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        isAllSelected={isAllSelected}
        setAllSelected={setAllSelected}
        deselectedIds={deselectedIds}
        setDeselectedIs={setDeselectedIs}
        isSaving={isSaving}
        searchParams={searchParams}
        setSearchParms={setSearchParms}
      />
        <Container fluid>
          <Row className="d-flex justify-content-end mr-3">
            <CustomButton
              permissionSet={['C', 'U']}
              permissionType={'C,U'}
              content={'Reset'}
              onClick={reset}
            />
            <CustomButton
              permissionSet={['C', 'U']}
              permissionType={'C,U'}
              content={isSaving ? <i className="fas fa-spinner fa-spin" aria-hidden="true" /> : 'Save'}
              onClick={handleMapping}
            />
          </Row>
        </Container>
      </>
      }
    </>
  )
}

export default TemplateMapping