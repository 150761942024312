import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { FormGroup } from "@mui/material";
import { fontWeight } from '@material-ui/system';

export const InputStyle = {
  padding: "7px 8px",
  height: "31px",
  fontSize: "12px",
  background: "#FFFFFF",
  borderRadius: "8px",
  width: "100%",
  maxHeight: "15rem",
};

export const InputLabelStyle = {
  fontFamily: "Gilroy-Regular",
  fontSize: "14px",
  lineHeight: "14px",
  color: "black",
  marginTop: "10px",
  fontWeight: "500"

};

const getByLabelText = (selected, dropdownsValues) => {
  const labels = [];
  dropdownsValues.map((item) => {
    if (selected.findIndex((select) => select === item.value) >= 0) {
      labels.push(item.label);
    }
  });

  return labels.join(", ");
};
const AMultiSelect = ({
  handleChange,
  inputKey,
  items,
  currentValue,
  label,
  placeholder,
  disabled,
  selectAllRequired = false
}) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
      sx: {
        '& .MuiMenuItem-root': {
          paddingY: '2px',
        }
      }
    },
  };


  const handleInternalChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      const allVals = items.map(i => i.value);
      handleChange(currentValue.length === items.length ? [] : allVals, inputKey);
      return;
    }
    handleChange(value, inputKey)
  }

  return (
    <div>
      {" "}
      <FormGroup>
        {label && <InputLabel sx={InputLabelStyle}>{label}</InputLabel>}
        <Select
          sx={InputStyle}
          multiple
          displayEmpty
          value={currentValue}
          onChange={(e) => handleInternalChange(e)}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <div>Select</div>;
            }

            return getByLabelText(selected, items);
          }}
          MenuProps={MenuProps}
          disabled={disabled}
        >
          {selectAllRequired && <MenuItem value="all">
              <Checkbox
                checked={items.length > 0 && currentValue.length === items.length}
                indeterminate={currentValue.length > 0 && currentValue.length < items.length}
              />
            <ListItemText sx={{
                '& .MuiTypography-root': {
                  fontSize: '12px'
                }
              }} primary="Select All" />
          </MenuItem>}
          {items.map((variant) => (
            <MenuItem key={variant.value} value={variant.value}>
              <Checkbox
                checked={
                  currentValue.findIndex((item) => item === variant.value) >= 0
                }
                sx={{ paddingY: 0 }}
              />
              <ListItemText sx={{
                '& .MuiTypography-root': {
                  fontSize: '12px'
                }
              }} primary={variant.label} />
            </MenuItem>
          ))}
        </Select>
      </FormGroup>
    </div>
  );
};

export default AMultiSelect;
