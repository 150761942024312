import React from 'react'
import AButton from '../../../../common/form-fields-mui/AButton'
import APopover from '../../../../common/a-popover';
import SearchFilterForm from './SearchFilterForm';


const highlightedStyle = {
  border: "1px solid #00B0F5",
  color: "#00B0F5",
};

const SearchFilter = ({
  dropdowns,
  filterCount,
  filterPayload,
  applyFilter,
}) => {

  return (
    <APopover
      buttonComponent={(onClick, styles) => (
        <AButton
          className='tiny-semi-bold color-dark'
          onClick={onClick}
          updatedStyle={{ minWidth: '61px', ...(filterCount > 0 ? highlightedStyle : styles) }}
        >
          Filter {filterCount > 0 && <>( {filterCount} )</>}
        </AButton>
      )}
      menuComponent={(closeForm) =>
        <SearchFilterForm
          dropdowns={dropdowns}
          closeForm={closeForm}
          filterCount={filterCount}
          filterPayload={filterPayload}
          applyFilter={applyFilter}
        />
      }
    />
  )
}

export default SearchFilter