import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { primaryCheckboxStyles } from "../../issuanceIDcard/printIdCardAndMapRfid/constant";
import SearchByTxnForm from "./SearchByTxnForm";
import SearchByOtherDetailsForm from "./SearchByOtherDetailsForm";
import { regex } from "views/pages/manage/common/constant";
import { fetchTransactionsData, searchStockTransactions } from "../service";
import RecordsCounter from "./RecordsCounter";
import { StockReturnTable } from "./StockTransactionTable";
import ALoader from "../../../common/a-loader";
import { useGetBatchListV2 } from "../../issuanceIDcard/customhooks";
import moment from "moment";
import { failureToast } from "../../../common/utils/methods/toasterFunctions/function";
import { messageNoDataFound } from "./helper";
import { transactionTypeMap } from "../stockReturn/constant";

export const lastDays = (
  numberOfDays = 31,
  date = new Date().setHours(0, 0, 0, 0)
) => {
  return moment(date).subtract(numberOfDays, "days");
};

const formFields = (academicType) => ({
  businessArea: "",
  transactionId: "",
  psid: "",
  term: "",
  courseId: "",
  batchIds: "",
  issuanceDateFrom: lastDays(),
  issuanceDateTo: moment(new Date()).endOf("day"),
  groupBy: "applicationId",
  type: transactionTypeMap.PSID_RETURN[academicType],
});

const ReturnTabItem = ({
  dropdown,
  checkedOption,
  setCheckedOption,
  isConsumptionReturn,
  selectedTab,
  academicType = "RCC",
  ...props
}) => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [loader, setApiLoader] = useState(false);
  const [form, setForm] = useState(formFields(academicType));
  const [batchDropdown, isLoading] = useGetBatchListV2(
    academicType !== "RCC" ? null : form?.businessArea,
    form?.term,
    form?.courseId,
    academicType !== "RCC" ? "PRIMARY" : ""
  );

  useEffect(() => {
    setForm(formFields(academicType));
    setRows([]);
    setRowCount(0);
    setCheckedOption("txnId");
  }, [selectedTab]);

  useEffect(() => {
    setForm(formFields(academicType));
    setRows([]);
    setRowCount(0);
  }, [checkedOption]);

  const handleCheckboxChange = (option) => {
    setCheckedOption(option);
  };

  const nextButtonHandler = () => {};
  const viewDetailsHandler = async (form) => {
    try {
      setApiLoader(true);
      const { businessArea, txnId } = form;
      const response = await fetchTransactionsData(
        businessArea?.value,
        txnId,
        `&type=${transactionTypeMap.PSID_RETURN[academicType]}`
      );
      setApiLoader(false);
      if (response?.data?.length === 0) failureToast(messageNoDataFound);
      if (response?.data) setRows(response?.data);
    } catch (error) {
      setApiLoader(false);
    }
  };

  const formHandler = (value, key) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const viewDetailsHandlerForOther = async (request = {}) => {
    fetchTxnList(0, { ...request, applicationId: request?.txnId, txnId: "" });
  };

  const fetchTxnList = async (pageNo, request) => {
    try {
      setApiLoader(true);
      setPage(pageNo);
      const res = await searchStockTransactions(
        { ...form, ...request },
        pageNo
      );
      if (res?.data) {
        if (res?.data?.stockTransactionAudList?.length === 0)
          failureToast(messageNoDataFound);
        setRows(res?.data?.stockTransactionAudList);
        setRowCount(res?.data?.totalRecords);
      }
      setApiLoader(false);
    } catch (error) {
      setApiLoader(false);
    }
  };

  const checkboxOptions = [
    {
      title: `Search by Return ID`,
      onChange: (e) => {
        e.target.checked && handleCheckboxChange("txnId");
      },
      checked: checkedOption === "txnId",
    },
    {
      title: "Search by other details",
      onChange: (e) => {
        e.target.checked && handleCheckboxChange("otherDetails");
      },
      checked: checkedOption === "otherDetails",
    },
    {
      title: "Search by Application ID",
      onChange: (e) => {
        e.target.checked && handleCheckboxChange("applicationID");
      },
      checked: checkedOption === "applicationID",
    },
  ];

  return (
    <div className="mt-2">
      {loader && <ALoader position={"fixed"} />}
      {checkboxOptions?.map((item, i) => (
        <FormControlLabel
          key={JSON.stringify(item)}
          disableTypography
          className="flex-grow-1"
          sx={{ margin: "0px", ...primaryCheckboxStyles }}
          control={
            <Checkbox
              sx={{ padding: "3px", marginLeft: i !== 0 ? "10px" : "" }}
              onChange={item?.onChange}
              checked={item?.checked}
            />
          }
          label={
            <div
              className={`semi-bold ${
                item?.checked ? "label-color" : "color-grey-60"
              }`}
            >
              {item?.title}
            </div>
          }
        />
      ))}
      {checkedOption === "txnId" || checkedOption === "applicationID" ? (
        <SearchByTxnForm
          dropdown={dropdown}
          checkedOption={checkedOption}
          isConsumptionReturn={isConsumptionReturn}
          selectedTab={selectedTab}
          clickHandler={
            checkedOption === "txnId"
              ? viewDetailsHandler
              : viewDetailsHandlerForOther
          }
        />
      ) : (
        <SearchByOtherDetailsForm
          {...props}
          dropdown={dropdown}
          isConsumptionReturn={isConsumptionReturn}
          form={form}
          formHandler={formHandler}
          viewDetailsHandler={() => viewDetailsHandlerForOther({})}
          batchDropdown={batchDropdown}
        />
      )}

      <RecordsCounter count={rows.length} />
      {rows.length > 0 && (
        <StockReturnTable
          rowData={rows}
          rowCount={rowCount}
          isOtherDetail={checkedOption !== "txnId"}
          nextButtonHandler={nextButtonHandler}
          academicType={academicType}
        />
      )}
    </div>
  );
};

export default ReturnTabItem;
